import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Button} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';

import {INTEGRATION_LOCK_STYLES} from '../../../constants/integration/entityLocks';
import {integrationTrackingStatusModal} from '../../../constants/itemNames';
import {setItem} from '../../../actions/itemActions';


const IntegrationTrackingStatusInvestigateCell = (props) => {
  const {
    trackingId,
    entityLocks,
    entityLockForDisplay,
    actions
  } = props;

  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const payload = {entityLocks: entityLocks};
    if (trackingId) payload.trackingId = trackingId;

    actions.setItem(payload, integrationTrackingStatusModal);
  };

  const SVGComponent = INTEGRATION_LOCK_STYLES[entityLockForDisplay.lock_reason].svgComponent;

  return (
    <div className='tracking-status-warning-and-button'>
      <div>
        <SVGComponent fillColor={INTEGRATION_LOCK_STYLES[entityLockForDisplay.lock_reason].fillColor} />
      </div>

      <div>
        <Button variant='primary'
                onClick={onClick}
        >
          <div className='tracking-id-warning-and-text'>
            <div>{I18n.t('integration.transactions.trackingIdStatuses.investigateButton')}</div>
          </div>
        </Button>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  const actions = {
    setItem
  };

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(null, mapDispatchToProps)(IntegrationTrackingStatusInvestigateCell);
