import React from 'react';
import PropTypes from 'prop-types';
import ModalWrapper from '../common/ModalWrapper';
import LabelRowsReport from './LabelRowsReport';

class LabelInformationPackage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.onToggleDetails = this.onToggleDetails.bind(this);
    this.state = {
      showDetails: false,
    };
  }

  componentWillReceiveProps(nextProps){
    if(JSON.stringify(nextProps.selectedLabel) === JSON.stringify(nextProps.label)){
      this.setState({showDetails: nextProps.showLabelDetails});
    }
  }

  onToggleDetails(){
    this.setState({showDetails: !this.state.showDetails});
    this.props.onToggleShowLabelDetails();
  }

  render(){
    return (<div>
      <ModalWrapper
        showModal={this.state.showDetails}
        onHide={this.onToggleDetails} title='labelDetails'
        label={this.props.label}
        Component={LabelRowsReport}
        version={2}
        headerClass='bg-light-gray'
        dialogClassName='modal-xl'
        offset={{x: 1, y: 1}}
        okayButton={{show: true}}
      />
    </div>);
  }

}

LabelInformationPackage.propTypes = {
  label: PropTypes.object.isRequired,
};

export default LabelInformationPackage;
