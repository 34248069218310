import * as types from '../constants/actionTypes';

export function toggleMainNavMenu() {
  return {type: types.TOGGLE_MAIN_NAV_MENU};
}

export function closeMainNavMenu() {
  return {type: types.CLOSE_MAIN_NAV_MENU};
}

export function toggleUserOptsMenu() {
  return {type: types.TOGGLE_USER_OPTS_MENU};
}

export function openUserOptsMenu() {
  return {type: types.OPEN_USER_OPTS_MENU};
}

export function toggleFacilityMenu() {
  return {type: types.TOGGLE_FACILITY_MENU};
}

export function toggleCustDetails() {
  return {type: types.TOGGLE_CUST_DETAILS};
}

export function closeCustDetails() {
  return {type: types.CLOSE_CUST_DETAILS};
}

export function toggleLimitsDetails() {
  return {type: types.TOGGLE_LIMITS_DETAILS};
}

export function toggleCartDetails() {
  return {type: types.TOGGLE_CART_DETAILS};
}

export function closeCartDetails() {
  return {type: types.CLOSE_CART_DETAILS};
}

export function toggleAppBar() {
  return {type: types.TOGGLE_APP_BAR};
}
