import decode from 'jwt-decode';
import cachableNames from '../constants/cachableNames';
// import {setBugsnagUser} from '../util/bugsnag';
/*
 * This code was copied from a Dan Abramov Redux video:
 * https://egghead.io/lessons/javascript-redux-persisting-the-state-to-the-local-storage
 */

export const loadState = () => {
  try {
    const Authorization = localStorage.getItem('Authorization');
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    const objectState = JSON.parse(serializedState);
    objectState.headers.Authorization = Authorization;
    const cache = JSON.parse(localStorage.getItem('cache'));
    const state = {...objectState, ...cache};
    // if (state.user && state.facility) {
    //   setBugsnagUser({user: state.user, facility: state.facility});
    // }
    return state;
  } catch (error) {
    console.error(error);// eslint-disable-line no-console
    return undefined;
  }
};

export const loadAuth = () => {
  try {
    return localStorage.getItem('Authorization');
  } catch(error) {
    console.error(error);// eslint-disable-line no-console
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    //No reason to set empty state
    if (!state.headers.Authorization) {
      return;
    }
    const serializedState = JSON.stringify({
      headers: {
        Authorization: state.headers.Authorization
      },
      user: state.user,
      facilities: state.facilities,
      facility: state.facility,
      timezone: state.timezone,
      availableApps: state.availableApps,
      customer: state.customer,
      order: state.order,
      i18n: state.i18n,
      modules: state.modules,
      constants: state.constants,
      currentFacilityModules: state.currentFacilityModules
    });

    const storedAuthorization = localStorage.getItem('Authorization');
    if (!storedAuthorization || storedAuthorization === 'null' || storedAuthorization === 'undefined') {
      localStorage.setItem('Authorization', state.headers.Authorization);
    } else if (decode(storedAuthorization).exp < decode(state.headers.Authorization).exp) {
      localStorage.setItem('Authorization', state.headers.Authorization);
    }
    localStorage.setItem('state', serializedState);

    const cache = {meta:{}};
    cachableNames.map(name => {
      if(state[name] && state.meta[name]){
        cache[name] = state[name];
        cache.meta[name] = state.meta[name];
      }
    });
    const serializedCache = JSON.stringify(cache);
    localStorage.setItem('cache', serializedCache);

  } catch (error) {
    console.error(error);// eslint-disable-line no-console
    return undefined;
  }
};
