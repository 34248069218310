import {I18n} from 'react-redux-i18n';
import { requiredFieldValidation} from '../../common/form/redux-form/validations';

export const validate = (values, props) => {
  const {integrationState: {isBiotrack}, splitPackageLimitation} = props;
  const errors = {};
  errors.event_date = requiredFieldValidation(values.event_date, 'inventory.splitPackage.form.modificationDate');
  errors.new_packages = [];

  const totalNumberSplits = values.new_packages.reduce((acc, item) =>
    acc + parseInt(item.number_of_splits), 0
  );

  if(values.new_packages && values.new_packages.length !== 0){
    const trackingIds = [];
    errors.new_packages = values.new_packages.map(package_line => {
      const {state_integration_tracking_id, transacted_qty, number_of_splits, inventory_location_id, destination_partner_id,
        destination_partner_facility_id, is_test_package} = package_line;
      let trackingIdError = requiredFieldValidation(state_integration_tracking_id, 'inventory.splitPackage.form.trackingID');
      if (!trackingIdError && trackingIds.some(trackingId => trackingId === state_integration_tracking_id)) {
        trackingIdError = I18n.t('inventory.splitPackage.form.uniqueTrackingId');
      }
      if (state_integration_tracking_id) {
        trackingIds.push(state_integration_tracking_id);
      }

      const number_of_splits_entry = totalNumberSplits > splitPackageLimitation ?
        I18n.t('inventory.form.limitationForSplitPackage', {limit: splitPackageLimitation}) :
        requiredFieldValidation(number_of_splits, 'inventory.splitPackage.form.numberOfSplits');

      return {
        transacted_qty: requiredFieldValidation(transacted_qty, 'inventory.splitPackage.form.qtyUse'),
        number_of_splits: number_of_splits_entry,
        inventory_location_id: requiredFieldValidation(inventory_location_id, 'inventory.splitPackage.form.location'),
        state_integration_tracking_id: trackingIdError,
        destination_partner_id: requiredFieldValidation(!isBiotrack || !is_test_package || destination_partner_id),
        destination_partner_facility_id: requiredFieldValidation(!isBiotrack || !is_test_package || destination_partner_facility_id),
      };
    });
  }

  const total_qty = values.new_packages.reduce((acc, item) =>
    acc + (parseFloat(item.transacted_qty || 0) * parseFloat(item.number_of_splits)), 0
  ).toFixed(2);
  
  errors.new_packages = (values.qty_available - total_qty < 0)
    ? errors.new_packages.map((item) => {
      return {
        ...item,
        transacted_qty: I18n.t('inventory.splitPackage.form.qtyAvailableLessThanQuantityToUse')
      };}) : errors.new_packages;

  const {
    complianceSettings: {inv_packages_require_tracking_id_message, inv_packages_require_tracking_id},
  } = props;

  if (inv_packages_require_tracking_id) {
    if (props.failedTrackingIdValidation) {
      errors._error = inv_packages_require_tracking_id_message;
    }
  }

  return errors;
};

export default validate;
