import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { goBack, push } from 'react-router-redux';
import { change, reset, arrayRemoveAll, arrayPush } from 'redux-form';
import map from 'lodash.map';
import { I18n } from 'react-redux-i18n';
import get from 'lodash.get';

import FormWrapper from '../../common/form/FormWrapper';
import InProgressOverlay from '../../common/InProgressOverlay';
import {
  pricingOptions,
  onlineAvailabilityOptions,
  potencyList,
  terpeneList,
  solvents,
  activationTimes,
  dilutions
} from '../common/data';
import WrappedProductForm from '../common/ProductForm';
import { addMessage } from '../../../actions/systemActions';
import { unsetItem, setItem } from '../../../actions/itemActions';
import { unsetData } from '../../../actions/dataActions';
import { getIntegrationCategoriesData } from '../../../actions/integrationActions';
import * as apiActions from '../../../actions/apiActions';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import dominance from '../../../constants/strainDominance';
import * as messageTypes from '../../../constants/messageTypes';
import { isMetrcIntegrator, getMetrcCategoryFromOrgSubcategoryId } from '../../../selectors/integration/metrcSelectors';
import { getIntegrationState } from '../../../selectors/integration/integrationSelectors';
import { PRODUCT_FORM } from '../../../constants/forms';
import * as UOMS from '../../../constants/uoms';
import { getPricingWeightsForDisplaying, getProductTypeOptions } from '../../../selectors/productsSelector';
import {
  getModifyProductInitialValues,
  getCategoryId,
  getFormSubcategories,
  getProductPricingGroups,
  getSaveProductPayload,
  subCategoryMapErrors,
  getUomOptions,
  getStrains,
  isPrepackWithInventory,
  isStrainRequired,
  getRequiredFieldsFromSubCategory
} from '../../../selectors/forms/productFormSelectors';
import { getRetailFacilityOptions } from '../../../selectors/facilitiesSelectors';
import { getFacilitiesOptions } from '../../../selectors/accountingSelectors';
import { isSeedPackagingAllowed } from '../../../selectors/forms/prepackWeightsFacilitySelectors';
import ModalWrapper from '../../common/ModalWrapper';
import { getIsConnectsActive } from '../../../selectors/connectsSelectors';
import { isFacilityGroupMaster } from '../../../selectors/facilityGroupsSharingSelectors';
import { getFacilityState } from '../../../selectors/facility/getCurrentFacility';

export class ModifyProductPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubcategoryChange = this.onSubcategoryChange.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.onDismissLoader = this.onDismissLoader.bind(this);
    this.onConfirmSharedModal = this.onConfirmSharedModal.bind(this);
    this.onNavigate = this.onNavigate.bind(this);
    this.state = {
      ready: 0, // When ready === 2 we know all promises are complete AND that the product has loaded with details; important for pricing
      imageUrl: '/images/leaf.jpg',
      uploading: false,
      saving: false,
      strainLocked: false,
      showSharedNotification: false,
      showSharedModal: false,
      isWaInventoryMapped: false
    };
  }

  componentWillMount() {
    this.props.actions.unsetItem(itemNames.image);
    this.props.actions.unsetItem(itemNames.product);
    this.props.actions.unsetItem(itemNames.detailedProduct); // Ugly hack to get product in since product has been used to hold initialValues for some crazy reason
    this.props.actions.unsetData(dataNames.inventoryItems);
    this.props.actions.unsetData(dataNames.childItemMasters);
    this.props.actions.unsetData(dataNames.facilityStrains); // moved from willUnmount - was throwing a lifecycle error
    const { id } = this.props.params;
    Promise.all([
      this.props.actions.getItem(
        `/api/item_masters/${id}`,
        itemNames.product,
        undefined,
        { detailed: 1, edit_mode: 1 },
        (itemMaster) => {
          this.props.actions.setItem(itemMaster, itemNames.detailedProduct); // More ugly hack all to keep us from rendering too soon
          if (itemMaster.inventory_attributes && itemMaster.inventory_attributes.is_prepack) {
            this.props.actions.getUnpaginatedData(
              '/api/item_masters/children/items',
              dataNames.childItemMasters,
              undefined,
              { ids: [itemMaster.id], is_waste: 0, include_inactive_items: true }
            );
          }
        }
      ),
      this.props.actions.getUnpaginatedData(
        '/api/pricing_classes',
        dataNames.pricingClasses,
        { failed: 'retail.pricingClass.get.failed' },
        { active: 1 }
      ),
      this.props.actions.getUnpaginatedData('/api/brands', dataNames.brands),
      this.props.actions.getUnpaginatedData('/api/strains/by_organization', dataNames.organizationStrains),
      this.props.actions.getUnpaginatedData('/api/strains/by_facility', dataNames.facilityStrains),
      this.props.actions.getUnpaginatedData('/api/uoms/inventory', dataNames.uoms),
      this.props.actions
        .getUnpaginatedData('/api/categories?is_only_active=1', dataNames.categories)
        .then(() => this.props.actions.getIntegrationCategoriesData()),
      this.props.actions.getUnpaginatedData('/api/partners', dataNames.partners, undefined, {
        purchase_from: 1,
        exclude_internal_duplicates: 1
      }),
      this.props.actions.getUnpaginatedData('/api/tags', dataNames.tags),
      this.props.actions.getUnpaginatedData('/api/pricing_groups', dataNames.pricingGroups, null, { detailed: 1 }),
      this.props.actions.getUnpaginatedData('/api/consumer_groups', dataNames.customerGroups, {
        failed: 'customers.getGroups.failed'
      }),
      this.props.actions.getUnpaginatedData('/api/pricing_weights', dataNames.pricingWeights),
      this.props.actions.getUnpaginatedData('/api/facility_groups_sharings', dataNames.sharedFacilityGroups, {
        failed: 'failedToGetFacilityGroups'
      }),
      this.props.actions.getItem('/api/organizations/current_organization', itemNames.currentOrganization),
      this.props.actions.getUnpaginatedData('/api/integration-mapping', dataNames.integrationMapping, null, {
        key: 'product_identifier'
      })
    ])
      .then(() => {
        this.setState({ ready: this.state.ready + 1 });
        if (this.props.product.strain_id === null) return true;
        if (this.productStrainIsInStrains()) return true;

        // Get org strains and lock
        this.props.actions.getUnpaginatedData('/api/strains/by_organization', dataNames.facilityStrains).then(() => {
          this.setState({ strainLocked: true });
          if (!this.productStrainIsInStrains())
            this.props.actions.addMessage(messageTypes.error, 'products.getStrain.failed');
        });
      })
      .then(() => {
        //Check Washington integration inventory mapping
        if (this.props.integrationState.isWaLeaf) {
          this.props.actions
            .getUnpaginatedData('/api/leaf/get_wa_inventory_mapping/' + id, dataNames.getWaInventoryMapping)
            .then(() => {
              if (get(this.props, 'waInventoryMapping.0.id')) {
                this.setState({ isWaInventoryMapped: true });
              }
            });
        }
      })
      .catch(() => this.setState({ ready: this.state.ready + 1 }));
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps[itemNames.detailedProduct].name) return false;
    this.setState({ ready: this.state.ready + 1 }); // Hacky way to keep the form from initializing too soon.
  }

  productStrainIsInStrains() {
    const { product, strains } = this.props;
    return strains.some((strain) => strain.id === product.strain_id);
  }

  onDismissLoader() {
    this.setState({ saving: false });
  }

  onConfirmSharedModal() {
    const { change } = this.props.actions;
    const { product } = this.props;
    this.setState({ showSharedModal: false });
    change(PRODUCT_FORM, 'subcategory_id', product.subcategory_id);
  }

  onSubmit(formData) {
    this.setState({ saving: true });
    let afterSubmit = () => this.setState({ saving: false });
    switch (formData.afterSubmit) {
    case 'reset':
      afterSubmit = () => {
        this.props.actions.reset('product') && this.resetForm();
        this.setState({ saving: false });
        if (this.state.showSharedNotification)
          this.props.actions.addMessage(messageTypes.warning, 'products.form.sharedProductOn');
      };
      break;
    case 'redirect':
      afterSubmit = () => {
        this.props.actions.goBack();
        this.setState({ saving: false });
        if (this.state.showSharedNotification)
          this.props.actions.addMessage(messageTypes.warning, 'products.form.sharedProductOn');
      };
      break;
    case 'nothing':
    default:
      break;
    }
    const { id } = this.props.product;

    const data = getSaveProductPayload(formData, 'modify', this.props.categories, this.props.subcategories);
    if (this.props.integrationState.isMdMetrc || this.props.integrationState.isOrMetrc)
      data['configuration_validated'] = 1; // Because some of metrcMD is breaking fundamental validation
    return this.props.actions
      .putItem(
        `/api/item_masters/${id}`,
        data,
        itemNames.product,
        { success: 'product.modify.success', failed: 'product.modify.failed' },
        null,
        afterSubmit
      )
      .catch((error) => this.setState({ saving: false }));
  }

  buildSubcategoryList(categories) {
    const result = {};
    map(categories, ({ subcategories }) => {
      map(subcategories, (subcategory) => {
        result[subcategory.id] = subcategory;
      });
    });

    return result;
  }

  onSubcategoryChange(id, prevId) {
    // TODO: Move to middleware
    const { categories, getMetrcCategoryFromOrgSubcategoryId } = this.props;
    const { change } = this.props.actions;
    const metrcSubCategory = getMetrcCategoryFromOrgSubcategoryId({ id });
    const requiredFields = getRequiredFieldsFromSubCategory(metrcSubCategory);
    const subcategories = this.buildSubcategoryList(categories);

    if (!this.props.product.has_items) {
      change(PRODUCT_FORM, 'default_uom', '');
      change(PRODUCT_FORM, 'uom_type', '');
      change(PRODUCT_FORM, 'sales_attributes.pricing_type', '');
    }
    if (requiredFields.uom_type && this.props.uoms.length == 1) {
      const value = requiredFields.uom_type === 'weight' ? UOMS.GR : UOMS.EA;
      const selectedOption = this.props.allUoms.find((uom) => uom.uom_code === value);
      change(PRODUCT_FORM, 'default_uom', value);
      change(PRODUCT_FORM, 'uom_type', (selectedOption && selectedOption.uom_type) || '');
    }
    change(PRODUCT_FORM, 'required_fields', requiredFields);
    const subcategory = subcategories[id] || {};
    const prevSubcategory = subcategories[prevId] || {};
    if (!prevSubcategory.is_shared && subcategory.is_shared) {
      this.setState({ showSharedNotification: true });
    } else if (prevSubcategory.is_shared && !subcategory.is_shared) {
      this.setState({ showSharedModal: true });
    }
  }

  onNavigate(path) {
    this.props.actions.push(path);
  }

  resetForm() {
    this.props.actions.unsetItem(itemNames.image);
  }

  render() {
    const {
      pricingWeights,
      strains,
      dominance,
      uoms,
      allUoms,
      partners,
      categories,
      pricingGroups,
      tags,
      product,
      hasMetrcSettings,
      productTypeOptions,
      subcategories,
      facilityOptions,
      retailFacilityOptions,
      subCategoryMapErrors,
      isCanada,
      isSeedPackagingAllowed,
      isPrepackWithInventory,
      pricingClasses,
      brands,
      isMasterFacility,
      weedmapsCategoryMappings,
      isolocityCategoryMappings,
      isConnectsActive,
      facilityState,
      facility,
      waInventoryMapping,
      strainRequired,
      metrcCategoryMappings,
      metrcCategories
    } = this.props;

    const { saving, isWaInventoryMapped, strainLocked } = this.state;

    if (product.dominance !== undefined && product.dominance !== '') {
      if (this.props.dominance) {
        const selectedDom = this.props.dominance.find((dom) => product.dominance === dom.value);
        if (selectedDom) {
          product.dominance_id = selectedDom.id;
        }
      }
    }

    if (this.state.ready < 2) return null;

    return (
      <FormWrapper
        className='create-product-page'
        title={'products.modify.title'}
        goBack={this.props.actions.goBack}
        formName={PRODUCT_FORM}
        shouldWarnDirty={true}
      >
        <InProgressOverlay
          isActive={saving}
          message='products.form.savingProduct'
          onDismiss={this.onDismissLoader}
          showOk={false}
          showLoader={true}
          translate={true}
        />
        <WrappedProductForm
          form={PRODUCT_FORM}
          initialValues={product}
          solventOptions={solvents}
          dilutionOptions={dilutions}
          categoryOptions={categories}
          brands={brands}
          strainOptions={strains}
          dominanceOptions={dominance}
          vendorOptions={partners}
          pricingOptions={pricingOptions}
          pricingClasses={pricingClasses}
          activationTimeOptions={activationTimes}
          onlineAvailabilityOptions={onlineAvailabilityOptions}
          uomOptions={uoms}
          allUoms={allUoms}
          pricingGroupOptions={pricingGroups}
          pricingWeights={pricingWeights}
          strainRequired={strainRequired}
          tagOptions={tags}
          potencyList={potencyList}
          terpeneList={terpeneList}
          enableReinitialize={true}
          isModify={true}
          onSubmit={this.onSubmit}
          onSubcategoryChange={this.onSubcategoryChange}
          resetForm={this.resetForm}
          strainLocked={strainLocked}
          productTypeOptions={productTypeOptions}
          hasMetrc={hasMetrcSettings}
          isCanada={isCanada}
          isSeedPackagingAllowed={isSeedPackagingAllowed}
          subcategories={subcategories}
          facilityOptions={facilityOptions}
          retailFacilityOptions={retailFacilityOptions}
          subCategoryMapErrors={subCategoryMapErrors}
          isPrepackWithInventory={isPrepackWithInventory}
          isConnectsActive={isConnectsActive}
          isMasterFacility={isMasterFacility}
          weedmapsCategoryMappings={weedmapsCategoryMappings}
          isolocityCategoryMappings={isolocityCategoryMappings}
          facilityState={facilityState}
          facility={facility}
          categories={categories}
          metrcCategories={metrcCategories}
          metrcCategoryMappings={metrcCategoryMappings}
          onNavigate={this.onNavigate}
          isWaInventoryMapped={isWaInventoryMapped}
          waInventoryMapping={waInventoryMapping}
        />
        <ModalWrapper
          Component={false}
          version={2}
          onHide={() => this.setState({ showSharedModal: false })}
          showModal={this.state.showSharedModal}
          title='categoryManagement.sharedProducts'
          okayButton={{ show: true, text: 'Confirm', variant: 'default' }}
          cancelButton={{ show: true, text: 'Close', variant: 'default', onClick: this.onConfirmSharedModal }}
        >
          <div>{I18n.t('products.form.sharedProductOff')}</div>
        </ModalWrapper>
      </FormWrapper>
    );
  }
}

ModifyProductPage.propTypes = {
  pricingWeights: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    unsetItem: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    getDataByPost: PropTypes.func.isRequired,
    putItem: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    addMessage: PropTypes.func.isRequired
  }).isRequired,
  product: PropTypes.object.isRequired,
  strains: PropTypes.array.isRequired,
  uoms: PropTypes.array.isRequired,
  allUoms: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  partners: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  pricingGroups: PropTypes.array.isRequired,
  params: PropTypes.object.isRequired,
  dominance: PropTypes.array,
  hasMetrcSettings: PropTypes.bool.isRequired,
  isCanada: PropTypes.bool.isRequired,
  isSeedPackagingAllowed: PropTypes.bool.isRequired,
  getMetrcCategoryFromOrgSubcategoryId: PropTypes.func.isRequired,
  productTypeOptions: PropTypes.array,
  subcategories: PropTypes.array,
  facilityOptions: PropTypes.array.isRequired,
  retailFacilityOptions: PropTypes.array.isRequired,
  integrationState: PropTypes.object,
  isPrepackWithInventory: PropTypes.bool,
  brands: PropTypes.array.isRequired,
  isMasterFacility: PropTypes.bool.isRequired,
  detailedProduct: PropTypes.object,
  facilityState: PropTypes.string,
  facility: PropTypes.object,
  metrcCategories: PropTypes.array,
  metrcCategoryMappings: PropTypes.array,
  strainRequired: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  const { tags, partners, categories } = state;
  const product = getModifyProductInitialValues(state);
  const categoryId = getCategoryId(state);
  const pricingWeights = getPricingWeightsForDisplaying(state);
  const strains = getStrains(state);
  const subcategories = getFormSubcategories(state);
  const integrationState = getIntegrationState(state);
  const weedmapsCategoryMappings = state[dataNames.weedmapsCategoryMappings];
  const isConnectsActive = getIsConnectsActive(state, { moduleKey: 'CONNECTS' });
  const isolocityCategoryMappings = state[dataNames.isolocityCategoryMappings];

  return {
    brands: state[dataNames.brands],
    product: product,
    detailedProduct: state[itemNames.detailedProduct],
    pricingWeights,
    pricingClasses: state[dataNames.pricingClasses],
    pricingGroups: getProductPricingGroups(state),
    tags,
    partners,
    categoryId,
    categories,
    subcategories,
    allUoms: state.uoms,
    uoms: getUomOptions(state),
    strains,
    dominance,
    weedmapsCategoryMappings,
    isolocityCategoryMappings,
    hasMetrcSettings: isMetrcIntegrator(state),
    getMetrcCategoryFromOrgSubcategoryId: (subcategory) => getMetrcCategoryFromOrgSubcategoryId(state, subcategory),
    productTypeOptions: getProductTypeOptions(state),
    facilityOptions: getFacilitiesOptions(state),
    retailFacilityOptions: getRetailFacilityOptions(state),
    subCategoryMapErrors: subCategoryMapErrors(state),
    isCanada: integrationState.isCanada,
    isSeedPackagingAllowed: isSeedPackagingAllowed(state),
    metrcCategories: state[dataNames.metrcCategories],
    metrcCategoryMappings: state[dataNames.metrcCategoryMappings],
    integrationState,
    isPrepackWithInventory: isPrepackWithInventory(state),
    isConnectsActive: isConnectsActive,
    isMasterFacility: isFacilityGroupMaster(state),
    facilityState: getFacilityState(state),
    facility: state[itemNames.facility],
    waInventoryMapping: state[dataNames.getWaInventoryMapping],
    strainRequired: isStrainRequired(state)
  };
}

function mapDispatchToProps(dispatch) {
  const collectedActions = {
    goBack,
    reset,
    change,
    unsetItem,
    setItem,
    addMessage,
    unsetData,
    arrayRemoveAll,
    arrayPush,
    getIntegrationCategoriesData,
    push
  };
  return {
    actions: bindActionCreators(Object.assign({}, apiActions, collectedActions), dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModifyProductPage);
