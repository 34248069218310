import {createSelector} from 'reselect';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import moment from 'moment';
import * as dataNames from '../constants/dataNames';
import * as itemNames from '../constants/itemNames';
import {paymentsAvailable} from '../constants/salesSettings';
import {
  in_store,
  delivery,
  pickup,
  curbside,
  getOrderFulfillmentOptions
} from '../constants/orderFulfillmentMethods';
import {getIntegrationState} from './integration/integrationSelectors';
import {getFacilityState} from './facility/getCurrentFacility';
import {getRegisters} from './registersSelectors';
import {isFeatureEnabled} from './featureToggles';

export const getCsrOptions = (state) => {
  const budTenders = get(state, itemNames.budTendersObject, {});
  return Object.keys(budTenders).map( id => {
    return {
      text: budTenders[id],
      value: id
    };
  });
};

export const getFulfillmentOptions = (state) => {
  return getOrderFulfillmentOptions().filter( method => {
    return [in_store,delivery,pickup,curbside].indexOf(method.value) != -1;
  });
};

export const getOrderTypeOptions = (state) => {
  const orderTypeOptions = [
    {
      text: I18n.t('retail.transactionReport.orderTypes.sales'),
      value: 'sales'
    },
    {
      text: I18n.t('retail.transactionReport.orderTypes.refunds'),
      value: 'refund'
    }
  ];
  return orderTypeOptions;
};

export const getInitialValues = (state) => {
  return {
    csrs: [],
    fulfillment_methods: [],
    order_types: [],
    patient_types: [],
    payment_methods: [],
    registers: [],
    report_date: moment(),
  };
};

export const getPatientTypeOptions = createSelector(
  [getIntegrationState,getFacilityState],
  (integrationState, facilityState) => {
    const patientTypeOptions = [
      {
        text: I18n.t('retail.transactionReport.patientTypes.recreational'),
        value: 'recreational'
      },
      {
        text: I18n.t('retail.transactionReport.patientTypes.medical'),
        value: 'medical'
      }
    ];

    // for CA facilities, add MMIC patient type
    if(facilityState.toLowerCase() === 'ca' || integrationState.isCaMetrc){
      patientTypeOptions.push(
        {
          text: I18n.t('retail.transactionReport.patientTypes.mmic'),
          value: 'mmic'
        }
      );
    }

    // for OK facilities, add Disabled Vet patient type
    if(facilityState.toLowerCase() === 'ok'){
      patientTypeOptions.push(
        {
          text: I18n.t('taxes.form.disabledVeterans'),
          value: 'disabled_veteran'
        }
      );
    }

    return patientTypeOptions;
  }
);

export const getPaymentOptions = state => paymentsAvailable.reduce((filtered, paymentAvailable) => {
  if (
    (paymentAvailable.value === 'aeropay' && !isFeatureEnabled(state)('feature_aeropay')) ||
    (paymentAvailable.value === 'aeropayintegrated' && !isFeatureEnabled(state)('feature_aeropay_integration')) ||
    (paymentAvailable.value === 'alleaves' && !isFeatureEnabled(state)('feature_alleaves_payment_integration')) ||
    (paymentAvailable.value === 'hypur' && !isFeatureEnabled(state)('feature_hypur')) ||
    (paymentAvailable.value === 'posabit' && !isFeatureEnabled(state)('feature_posabit_payment_integration'))
  ) {
    return filtered;
  }
  filtered.push({
    text: I18n.t(paymentAvailable.i18n),
    value: paymentAvailable.value,
  });
  return filtered;
}, []);

export const getRegisterOptions = createSelector( [getRegisters], registers => {
  return registers.map( register => {
    return {
      text: register.name,
      value: register.id
    };
  });
});

export const getTransactions = (state) => get(state, dataNames.transactionReport, []);

export const getTransactionReport = createSelector(
  [getTransactions, getRegisters, getPatientTypeOptions, getOrderTypeOptions, getFulfillmentOptions],
  (transactionReport, registers, patientTypeOptions, orderTypeOptions, fulfillmentOptions) => {

    const parseFloatFields = [ // Fields that come from server as strings with commas
      'cash_amount', 'credit_amount', 'debit_amount', 'gift_amount', 'check_amount', 'total_amount',
      'tax_amount', 'discount_amount', 'change_amount', 'subtotal_amount', 'aeropay_amount',
    ];

    if(!transactionReport.length) return [];

    // format report values for display
    const transactions = transactionReport.map( t => {
      // convert register ids to names:
      const register = registers.find( r => r.id == get(t,'register_id',0) );
      t.register_name = register ? register.name : '';

      // format patient type
      const patientType = patientTypeOptions.find( p => p.value == get(t,'consumer_type',null) );
      t.consumer_type = patientType ? patientType.text : t.consumer_type;

      // format order type
      const orderType = orderTypeOptions.find( o => o.value == get(t,'order_type',null) );

      t.order_type = orderType ? orderType.text : t.order_type;

      // format fulfillment method
      const fulfillmentType = fulfillmentOptions.find( f => f.value == get(t,'fulfillment_method',null) );
      t.fulfillment_method = fulfillmentType ? fulfillmentType.text : t.fulfillment_method;

      parseFloatFields.forEach((field) => {
        const value = String(get(t, field, '0.00')).replace(/[^\d\.\-]/g, ''); // Inbound data is a string with comma separators - get rid of commas
        const convertedValue = parseFloat(value); // now parse
        t[field] = isNaN(convertedValue) ? 0 : convertedValue; // handle invalid conversions
      });

      return t;
    });

    return transactions;
  }
);

export const getTransactionTotals = createSelector( [getTransactions], (transactionReport) => {
  // compute report totals
  const columnTotal = (column,txns) => {
    return txns.reduce( (acc,row) => {
      // strip commas if amount is a string:
      const curr = (get(row,column,'') + '').replace(/,/g,'');
      const value = parseFloat(curr);
      return acc += isNaN(value) ? 0 : value; // prevent isNaN from making an appearance.
    }, 0.00 );
  };

  const totals = {
    cash_amount: columnTotal('cash_amount', transactionReport),
    credit_amount: columnTotal('credit_amount', transactionReport),
    debit_amount: columnTotal('debit_amount', transactionReport),
    gift_amount: columnTotal('gift_amount', transactionReport),
    check_amount: columnTotal('check_amount', transactionReport),
    posabit_amount: columnTotal('posabit_amount', transactionReport),
    alleaves_amount: columnTotal('alleaves_amount', transactionReport),
    mmap_amount: columnTotal('mmap_amount', transactionReport),
    total_amount: columnTotal('total_amount', transactionReport),
    tax_amount: columnTotal('tax_amount', transactionReport),
    discount_amount_coupons: columnTotal('discount_amount_coupons', transactionReport),
    discount_amount_manual: columnTotal('discount_amount_manual', transactionReport),
    discount_amount_redemption: columnTotal('discount_amount_redemption', transactionReport),
    discount_amount_rewards: columnTotal('discount_amount_rewards', transactionReport),
    discount_amount: columnTotal('discount_amount', transactionReport),
    change_amount: columnTotal('change_amount', transactionReport),
    aeropay_amount: columnTotal('aeropay_amount', transactionReport),
    other_amount: columnTotal('other_amount', transactionReport),
    subtotal_amount: columnTotal('subtotal_amount', transactionReport),
  };

  return [totals];
});
