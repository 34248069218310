import * as p from './permissions';
/***
 *  Maps settingKey on tables used to persist preferences to one or more permissions where the first true permission wins
 *  settingKey not present in this list is used with manage_ as a prefix to test for permission to access row selection
 *  An empty array is deemed as everyone has permission.  A missing settingKey is deemed as no one has permission.
 */
const keyToPermissionsMap = { //eslint-disable-line
  'retail-physicians': [p.manage_customers],
  strains: [p.manage_facility_strains, p.manage_organization_strains],
  notifications: [p.manage_alerts],
  'api-transactions': [p.manage_state_integrations],
  'integration-tracking': [p.manage_state_integrations],
  'integration-sales-export': [p.manage_state_integrations],
  'third-party-integrations': [p.manage_third_party_integrations],
  ingredients: [p.manage_ingredients],
  partners: [p.manage_partners],
  'retail-supplies-listing': [],
  'retail-coupons': [p.manage_coupons],
  'retail-customer-groups': [p.manage_customers],
  'retail-pricing-groups': [p.manage_pricing_groups],
  'retail-pricing-classes': [],
  'retail-pricing-multipliers': [],
  'retail-product-lists': [],
  'feeding-schedules': [p.manage_feeding_schedules],
  registers: [p.manage_registers],
  'test-results-averages': [p.manage_testing],
  'test-results': [p.manage_testing],
  drivers: [],
  users: [p.manage_users],
  'harvests-history': [p.manage_harvests],
  harvests: [p.manage_harvests],
  'ei-inventory': [p.manage_inventory],
  'finished-products': [p.manage_inventory],
  'finished-products-ingredients': [p.manage_inventory],
  'ei-inventory-ingredients': [p.manage_ingredients],
  'plants-history': [p.manage_plants],
  plants: [p.manage_plants],
  'tax-profiles': [p.manage_taxes],
  taxes: [p.manage_taxes],
  products: [p.manage_products],
  vehicles: [],
  'inventory-receipts': [p.manage_taxes],
  'packages-history': [p.manage_inventory],
  patients: [p.manage_customers],
  'ei-processing-types': [p.manage_inventory],
  'ei-active-processing': [p.manage_inventory],
  'ei-active-infusions': [p.manage_inventory],
  'ei-assemblies': [p.manage_inventory],
  'ei-preferred-mixes': [p.manage_inventory],
  equipment: [p.manage_inventory],
  'sales-orders': [p.manage_inventory],
  'purchase-orders': [p.manage_inventory],
  transfers: [p.manage_inventory],
  mass_modify_products_search: [],
  'shared-facility-groups': [],
  modalities: [p.manage_cultivation_compliance],
  'delivery-orders': [],
  'courier-delivery-orders': [],
  'quarantined-inventory': [p.manage_quarantined_inventory],
  'inventory-sync': [],
  'inventory-sync-results': [],
  'inventory-sync-packages': [],
  'packaging-jobs': [],
};

export default keyToPermissionsMap;
