import * as dataNames from '../../constants/dataNames';
import * as apiActions from '../apiActions';

export const save = (payload) => {
  return apiActions.postItem(
    `/api/ohmetrc/item_categories/minimum_days_supply`,
    payload,
    null,
    {
      success: 'retail.minimumDaysSupply.successfullySaved',
    }
  );
};

export const fetch = () => {
  return apiActions.getData(
    '/api/ohmetrc/item_categories',
    dataNames.ohMetrcCategories
  );
};

export const update = (payload) => (dispatch) => {
  return dispatch(save(payload));
};
