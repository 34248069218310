import React from 'react';
import PropTypes from 'prop-types';

import SelectControl from './SelectControl';
import ReduxBootstrapInput from './ReduxBootstrapInput';

const SelectInput = ({label, size, input, meta, children, isRequired, options, ...props }) => {
  return (
    <ReduxBootstrapInput Control={SelectControl} options={options}
                         label={label} size={size}
                         input={input} meta={meta}
                         name={input.name} isRequired={isRequired}
                         {...props} >
      {children}
    </ReduxBootstrapInput>
  );
};

SelectInput.propTypes = {
  label: PropTypes.string,
  size: PropTypes.oneOf(['lg', 'sm', 'large', 'small']),
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func
  }).isRequired,
  meta: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
        PropTypes.array.isRequired
      ]),
      disabled: PropTypes.bool
    })
  ).isRequired,
  children: PropTypes.node,
  isRequired: PropTypes.bool
};

export default SelectInput;
