import React from 'react';
import PropTypes from 'prop-types';
import {formatClientDate} from '../../../../util/dateHelpers';

const DateColumn = ({date, altDate, altDateFormat, ...props}) => (
  (<span {...props}>
    {
      altDate
        ? altDateFormat ? formatClientDate(altDate) : altDate
        : formatClientDate(date)
    }
  </span>)
);

DateColumn.propTypes = {
  altDateFormat: PropTypes.bool,
  altDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ])
};

export default DateColumn;
