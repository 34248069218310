import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field, FieldArray} from 'redux-form';
import {Button, ButtonToolbar, Row, Col} from 'react-bootstrap';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import TextAreaInput from '../../common/form/TextAreaInput';
import CheckBoxInput from '../../common/form/CheckBoxInput';
import SelectedPlants from './SelectedPlants';
import InternationalDateTimePickerInput from '../../common/form/InternationalDateTimePickerInput';


const DestroyPlantForm = ({selectedPlants, use_overall, reasons, handleSubmit, pristine, reset, submitting, change, trackPlantsAsGroups,requiresAdditionalDataForMetrc, metrcWasteMethods, metrcWasteReasons}) => {

  // check the requiresAdditionalDataForMetrcPlantDestroy selector for a list of metrc states that get this different list
  const destroyReasons = requiresAdditionalDataForMetrc ? metrcWasteReasons : reasons;

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col xs={12}>
          <Field name='use_overall' style={{width:'18px'}}
             onChange={(event) => {
               change('use_overall', event.target.checked);
             }}
             className='inline-checkbox'
             component={CheckBoxInput}
             props={{label: I18n.t('harvest.form.useOverallValues'), groupClassName: 'inline-checkbox'}}
             />
          <FieldArray name='plants' component={SelectedPlants}
            props={{plants: selectedPlants, use_overall, change, trackPlantsAsGroups}}/>
        </Col>
      </Row>
      <Row>
        <Col className='form-fields-col' xs={8} md={3} lg={2}>
          <Field
            name='notes' component={ReactSelectInput}
            props={{
              options: destroyReasons,
              label: I18n.t('plants.form.destroyReason'),
              isRequired: true
            }} />
        </Col>
        {requiresAdditionalDataForMetrc &&
          <React.Fragment>
            <Col className='form-fields-col' xs={8} md={3} lg={2}>
              <Field
                name='destroy_method' component={ReactSelectInput}
                props={{
                  options: metrcWasteMethods,
                  label: I18n.t('plants.form.destroyMethod'),
                  isRequired: true
                }}/>
            </Col>
            <Col className='form-fields-col' xs={8} md={3} lg={2}>
              <Field
                name='mixed_materials' component={TextAreaInput}
                props={{
                  label: I18n.t('plants.form.includedMaterials'),
                  placeholder: I18n.t('plants.form.includedMaterialsPlaceholder'),
                  isRequired: true,
                  rows: 2,
                }}/>
            </Col>
          </React.Fragment>
        }

        <Col className='form-fields-col' xs={8} md={3} lg={2}>
          <InternationalDateTimePickerInput
            name='destroyed_at'
            props={{
              label: I18n.t('plants.form.destructionDate'),
              isRequired: true,
              enableTodayButton: true,
            }} />
        </Col>
      </Row>
      <Row>
        <Col className='form-fields-col add-note-section' xs={10} md={8} lg={6}>
          <Field name='plant_notes' component={TextAreaInput} props={{
            label: I18n.t('packages.form.notes'),
            rows: 2,
            isRequired: requiresAdditionalDataForMetrc,
          }}/>
        </Col>
      </Row>
      <ButtonToolbar className='float-right'>
        <Button type='reset'
                onClick={reset}
                disabled={submitting || pristine}>
          {I18n.t('reset')}
        </Button>
        <Button variant='primary'
                type='submit'>{I18n.t('save')}</Button>
      </ButtonToolbar>
    </form>
  );
};

DestroyPlantForm.propTypes = {
  selectedPlants: PropTypes.array.isRequired,
  use_overall: PropTypes.bool,
  reasons: PropTypes.array.isRequired,
  reset: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  trackPlantsAsGroups: PropTypes.bool,
  requiresAdditionalDataForMetrc: PropTypes.bool,
  metrcWasteMethods: PropTypes.array,
  metrcWasteReasons: PropTypes.array,
};

export default DestroyPlantForm;
