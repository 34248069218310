/**
 * Item was created:
 *  - Production
 *  - Packaging job
 *  - Harvest
 * @type {string}
 */
export const create = 'CREATE';
/**
 * Item was changed manually
 * @type {string}
 */
export const manual = 'MANUAL';
/**
 * Quick move using existing item
 * @type {string}
 */
export const merge = 'MERGE';
/**
 * Item was used in production
 * @type {string}
 */
export const mfgProduction = 'MFG_PRODUCTION';
/**
 * Item master was changed
 * @type {string}
 */
export const newItemMaster = 'NEW_ITEM_MASTER';
/**
 * New level was set to item(ingredients only)
 * @type {string}
 */
export const newLevel = 'NEW_LEVEL';
/**
 * No type was specified (probably actual for old records only)
 * @type {string}
 */
export const none = 'NONE';
/**
 * Package was splitted
 * @type {string}
 */
export const packageSplit = 'PACKAGE_SPLIT';
/**
 * Packaging job complete
 * @type {string}
 */
export const packaging = 'PACKAGING';
/**
 * Packaging job complete
 * @type {string}
 */
export const packagingEach = 'PACKAGING_EACH';
/**
 * Inventory receipt
 * @type {string}
 */
export const receipt = 'RECEIPT';
/**
 * Reconciliation
 * @type {string}
 */
export const reconcile = 'RECONCILE';
/**
 * Restock
 * @type {string}
 */
export const restock = 'RESTOCK';
/**
 * POS
 * @type {string}
 */
export const sale = 'SALE';
/**
 * Quick move
 * @type {string}
 */
export const split = 'SPLIT';
/**
 * Sales order transfer
 * @type {string}
 */
export const transfer = 'TRANSFER';
/**
 * Feeding schedule applied
 * @type {string}
 */
export const feedingSchedule = 'FEEDING_SCHEDULE';
/**
 * Transfer cancelled
 * @type {string}
 */
export const cancelTransfer = 'TRANSFER_ROLL_BACK';
/**
 * Plant was created from inventory
 * @type {string}
 */
export const createPlant = 'BIOTRACK_PLANT_CREATE';
/**
 * Clone of shared item was created
 */
export const sharedItemClone = 'SHARED_ITEM_CLONE';
/**
 * Replicated transaction from shared item
 */
export const sharedItemTransactionReplication = 'SHARED_ITEM_TRANSACTION_REPLICATION';
/**
 * Tag was created from package
 * @type {string}
 */
export const tagUpdate = 'tag_update';