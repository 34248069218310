import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { Table, Card } from 'react-bootstrap';
import ContentConcealer from '../../../common/concealers/ContentConcealer';

const PlantDestroyedDetails = ({ model, trackPlantsAsGroup }) => {
  const { destruction } = model;
  return (
    <Card>
      <Table striped className='plant-details'>
        <thead>
          <tr>
            <th>{I18n.t('plantHistory.field')}</th>
            <th>{I18n.t('plantHistory.value')}</th>
          </tr>
        </thead>
        <tbody>
          <ContentConcealer show={Boolean(destruction && destruction.destroyed_weight)}>
            <tr>
              <td>{I18n.t('plantHistory.plant.destroyedWeight')}</td>
              <td>
                {destruction && destruction.destroyed_weight} {destruction && destruction.uom}
              </td>
            </tr>
          </ContentConcealer>
          <ContentConcealer show={trackPlantsAsGroup}>
            <tr>
              <td>{I18n.t('plantHistory.plant.qtyPlantsDestroyed')}</td>
              <td>{destruction && destruction.plants_destroyed ? destruction.plants_destroyed : model.qty}</td>
            </tr>
          </ContentConcealer>
          <ContentConcealer show={Boolean(model.destruction_reason)}>
            <tr>
              <td>{I18n.t('plantHistory.plant.destroyReason')}</td>
              <td>{model.destruction_reason}</td>
            </tr>
          </ContentConcealer>
        </tbody>
      </Table>
    </Card>
  );
};

PlantDestroyedDetails.propTypes = {
  model: PropTypes.object.isRequired,
  trackPlantsAsGroup: PropTypes.bool.isRequired,
};

export default PlantDestroyedDetails;
