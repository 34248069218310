import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {FaCheck} from 'react-icons/fa';


const LabelTypeBox = (props) => {

  const {type, onSelectLabelType, currentLabelType} = props;

  return (<div style={{height:'120px', border:'1px solid #ccc', padding:'0', cursor: 'pointer'}} className='col-md-4' onClick={() => onSelectLabelType(type)}>
      <div style={{backgroundColor: '#444', color: '#ccc', padding: '5px'}}>{currentLabelType === type ? <FaCheck style={{marginRight: '5px'}}/> : null} {I18n.t(`labels.${type}`)}</div>
      <div className='text-muted' style={{fontSize: '12px', marginTop: '8px', padding: '0 15px'}}>
        {I18n.t(`labels.${type}_text`)}
        {
          type === 'image'
            ? null
            : type === 'zpl'
              ? <span className='text-danger'>{I18n.t(`labels.${type}_text_note`)}</span>
              : <strong>{I18n.t('labels.both_text_note')}</strong>
        }

      </div>
    </div>);
};

LabelTypeBox.propTypes = {
  type: PropTypes.string.isRequired,
  onSelectLabelType: PropTypes.func.isRequired,
  currentLabelType: PropTypes.string.isRequired,
};

export default LabelTypeBox;
