import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {FieldArray, Field} from 'redux-form';
import {Row, Col} from 'react-bootstrap';
import VendorsFieldArray from './VendorsFieldArray';
import AccordionPanel from '../../common/AccordionPanel';
import NumericInput from '../../common/form/NumericInput';
import {UOM_VALUE_PRECISION} from '../../../constants/uoms';

const PurchasingInfoPanel = ({vendorOptions, values, activeUom, uom}) =>  {
  return (
      <AccordionPanel title='products.form.purchasingInfo' className= 'form-panel purchasing-info'>
        <Row >
          <Col md={4}>
            <Field name='purchasing_attributes.par_level' component={NumericInput} props={{
              label: I18n.t('products.form.parLevel')
            }}/>
          </Col>
          <Col md={4}>
            <Field name='purchasing_attributes.auto_reorder_qty' component={NumericInput} props={{
              label: I18n.t('products.form.autoReorderQuantity')
            }}/>
          </Col>
          <Col md={4}>
            <Field name='purchasing_attributes.buffer' component={NumericInput} props={{
              fractionPartSize: activeUom === 'discrete' ? 0 : UOM_VALUE_PRECISION,
              type: 'text',
              allowNegativeNumber:false,
              label: I18n.t('products.form.apiBuffer')
            }}/>
          </Col>
        </Row>
        <FieldArray name='vendors' component={VendorsFieldArray} props={{vendorOptions, values, activeUom, uom}}/>
      </AccordionPanel>
  );
};

PurchasingInfoPanel.propTypes = {
  vendorOptions: PropTypes.array.isRequired,
  values: PropTypes.array,
  activeUom: PropTypes.string,
  uom: PropTypes.string,
};

export default PurchasingInfoPanel;
