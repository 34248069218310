import React from 'react';
import PropTypes from 'prop-types';
import round from 'lodash.round';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {push} from 'react-router-redux';
//import {Button} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';

import {unsetData} from '../../actions/dataActions';
import * as apiActions from '../../actions/apiActions';
import * as itemNames from '../../constants/itemNames';
import * as dataNames from '../../constants/dataNames';

import ModalWrapper from '../../components/common/ModalWrapper';
import ZipCodes from './common/ZipCodes';
import TablePageWrapper from '../common/grid/TablePageWrapper';
import PageTitle from '../common/PageTitle';
import InternationalDecimalStatic from '../common/form/InternationalDecimalStatic';

export class TaxPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);

    const taxFilters = [];
    const sizePerPage = 50;
    const selectedTaxes = [];
    const filteredTaxes = [];
    const columns = [
      {name: 'taxes.name',
        dataId:'tax_name',
        dataSort: true,
        hidden: false},
      {name: 'taxes.rate',
        dataId: 'amount',
        dataSort: true,
        formatter: (cell, row) => (<InternationalDecimalStatic suffix={'%'} fractionPartSize={3}>{cell}</InternationalDecimalStatic>),
        hidden: false},
      {name: 'taxes.tax_type',
        dataId: 'tax_type_code',
        dataSort: true,
        formatter: (cell, row) => (I18n.t(`taxes.tax_type_code.${cell.toLowerCase()}`)),
        hidden: true},
      {name: 'taxes.categories',
        dataId:'categoryString',
        dataSort: true,
        hidden: false},
      /*{name: 'taxes.zipCodes',
        dataId:'zipCodeString',
        csvFormatter: (cell) => { return I18n.t('taxes.view'); },
        formatter: (cell, row) => (<Button variant='link' onClick={e => this.revealZipCodes(e, row)}>{I18n.t('taxes.view')}</Button>),
        dataSort: true,
        hidden: false},*/
    ];
    this.state = {
      columns,
      taxFilters,
      sizePerPage,
      selectedTaxes,
      filteredTaxes,
      showZipCodesModal: false,
      taxToView: {}
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.setTab = this.setTab.bind(this);
    this.revealZipCodes = this.revealZipCodes.bind(this);
    this.hideZipCodes = this.hideZipCodes.bind(this);
  }

  componentWillMount(){
    const status = this.props.route.path.indexOf('inactive') !== -1 ? 0 : 1;
    this.props.actions.getUnpaginatedData('/api/categories', dataNames.categories);
    this.props.actions.getUnpaginatedData(`/api/taxes?detailed=1&active=${status}`, dataNames.taxes);
  }

  componentWillReceiveProps(nextProps) {
    const {columns} = this.state;
    if (nextProps.route.path !== this.props.route.path){
      this.setState({columns: columns});
      this.handleSelect('clear');
      this.props.actions.unsetData(dataNames.taxes);
      const status = nextProps.route.path.indexOf('inactive') !== -1 ? 0 : 1;
      this.props.actions.getUnpaginatedData(`/api/taxes?detailed=1&active=${status}`, dataNames.taxes);
    }
  }

  hideZipCodes() {
    this.setState({showZipCodesModal: false});
  }

  revealZipCodes(event, row) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({showZipCodesModal: true, taxToView: row});
  }


  handleSelect (isSelected, rows) {
    if(rows) {
      const ids = rows.map(row => row.id);
      if(isSelected){
        this.setState({selectedTaxes: ids});
        this.props.actions.getItem(`/api/taxes/${ids[0]}`, itemNames.tax);
      }else {
        this.setState({selectedTaxes: []});
      }
    }
    else {
      this.setState({selectedTaxes: []});
    }
  }

  setTab(activeTab) {
    this.setState({activeTab});
    this.props.actions.push(`/taxes/rules/${activeTab}`);
  }

  render () {
    const {taxes, route, dataTotalSize} = this.props;
    const {columns, taxToView} = this.state;
    const tabs = [
      {id: 'activeTaxesTab', eventKey: 'active', title: 'nav.activeRates', actions:[
          {id: 'createTax', path: '/taxes/rules/create' , text: 'users.actions.createTax' , glyph: 'plus-sign', requireSelect: false},
          {id: 'modifyTax', path: `/taxes/rules/${this.state.selectedTaxes[0]}` , text: 'users.actions.modifyTax' , glyph: 'th-list', requireSelect: true}
      ]},
      {id: 'inactiveTaxesTab', eventKey: 'inactive', title: 'nav.inactiveRates', actions:[
        {id: 'createTax', path: '/taxes/rules/create' , text: 'users.actions.createTax' , glyph: 'plus-sign', requireSelect: false},
        {id: 'modifyTax', path: `/taxes/rules/${this.state.selectedTaxes[0]}` , text: 'users.actions.modifyTax' , glyph: 'th-list', requireSelect: true}
      ]}
    ];
    const activeTab = route.path.indexOf('inactive') !== -1 ? 'inactive' : 'active';

    return (
      <div className='taxes table-page'>
        <PageTitle primaryText={I18n.t('taxes.title')}/>
        <TablePageWrapper
          ref={this.ref}
          settingKey='taxes'
          keyField='id'
          data = {taxes}
          tabs = {tabs}
          columns = {columns}
          activeTab = {activeTab}
          hideScanSearch={true}
          switchTab = {this.setTab}
          selectedRows = {this.state.selectedTaxes}
          handleFilter = {this.handleFilter}
          handleSelect = {this.handleSelect}
          dataTotalSize = {dataTotalSize}
          search = {true}
          multiColumnSearch = {true}
          columnFilter = {false}
          sizePerPageList = {[5,10,20,50,100]}
          hideExport={true}
          bstProps={{
            options: {
              onSearchChange: searchText => {
                if(!searchText) {
                  this.setState({selectedTaxes: []});
                }
              }
            },
            selectRow: {
              mode: 'radio',
              selected: this.state.selectedTaxes,
              onSelect: (row, isSelected) => this.handleSelect(isSelected, [row])
            }
          }}
        />
        <ModalWrapper
          Component={ZipCodes}
          title={'taxes.zipCodes'}
          onHide={this.hideZipCodes}
          showModal={this.state.showZipCodesModal}
          taxToView={taxToView}
        />
      </div>
    );
  }
}

TaxPage.propTypes = {
  taxes: PropTypes.array.isRequired,
  selectedTaxes: PropTypes.array.isRequired,
  dataTotalSize: PropTypes.number,
  actions: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  columnVisibility: PropTypes.array,
};

// TaxPage.contextTypes = {
//   router: React.PropTypes.object.isRequired
// };

function mapStateToProps(state) {
  const {taxes, selectedTaxes, categories} = state;
  return {
    taxes: taxes.map((tax) => {
      return Object.assign({}, tax, {
        amount: tax.amount && round(parseFloat(tax.amount) * 100, 3),
        zipCodeString: (tax.zipcodes && tax.zipcodes.length) ? tax.zipcodes.join(', ') : 'All',
        categoryString: (tax.item_category_ids && tax.item_category_ids.length) ? tax.item_category_ids.map((cat_id) => {
          const seletedCat = categories.find(cat => {
            return cat.id === cat_id;
          });
          return seletedCat ? seletedCat.name : '';
        }).join(', ') : 'None',
      });
    }),
    selectedTaxes: selectedTaxes.map((tax) => (tax.id))
  };

}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {push, unsetData});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (TaxPage);
