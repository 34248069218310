import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';

import ComboboxInput from '../ComboboxInput';

const mergePropsWithDefaults = props => {
  const defaultProps = ReduxMetrcIdField.defaultProps.props;
  return Object.assign({}, defaultProps, props);
};

const ReduxMetrcIdField = ({name, props, ...remainingProps}) => {
  const mergedProps = mergePropsWithDefaults(props);

  return (
    <Field
      name={name}
      component={ComboboxInput}
      props={mergedProps}
      // ComboboxInput may result in a string or an object.
      normalize={value => typeof value === 'object' ? value[mergedProps.valueKey] : value}
      {...remainingProps}
    />
  );
};

ReduxMetrcIdField.propTypes = {
  name: PropTypes.string.isRequired,
  props: PropTypes.shape({
    options: PropTypes.array.isRequired,
    label: PropTypes.string,
    textKey: PropTypes.string,
    valueKey: PropTypes.string,
    isRequired: PropTypes.bool,
    filter: PropTypes.oneOf([false, 'contains', 'startsWith', 'endsWith']),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    disabled: PropTypes.bool
  }).isRequired,
};

ReduxMetrcIdField.defaultProps = {
  props: {
    label: I18n.t('common.form.trackingId'),
    textKey: 'tag',
    valueKey: 'tag',
    isRequired: true,
    filter: false,
  }
};

export default ReduxMetrcIdField;
