/* eslint-disable import/no-named-as-default */
import get from 'lodash.get';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {push} from 'react-router-redux';
import {bindActionCreators} from 'redux';
import {change,submit,touch} from 'redux-form';
import {getUnpaginatedData,postItem,getItem} from '../../../../actions/apiActions';
import {setItem} from '../../../../actions/itemActions';
import * as consumerTypes from '../../../../constants/consumerTypes';
import * as dataNames from '../../../../constants/dataNames';
import * as itemNames from '../../../../constants/itemNames';
import {ANONYMOUS_CUSTOMER_FORM} from '../../../../constants/forms';
import {getSalesAgeLimit,getAnonymousCustomerTypes} from '../../../../selectors/salesSettingsSelectors';
import getInitialValues from '../../../../selectors/forms/anonymousOrdersFormSelectors';
import AnonymousOrderButton from './AnonymousOrderButton';
import AnonymousCustomerFormWrapper from './AnonymousCustomerFormWrapper';
import ModalWrapper from '../../../common/ModalWrapper';
import ScanInputModal from '../../../scan-input/ScanInputModal';

/**
 * Renders 'Anonymous Order' button and supporting modals/forms.
 * Handles modal state management, form validation and submission.
 */
export class AnonymousOrderBundle extends React.PureComponent {

  constructor(props, context) {
    super(props, context);

    [ 'showFormModal',
      'hideFormModal',
      'showScanModal',
      'hideScanModal',
      'onFastTrack',
      'onStartOrder',
      'onScanId',
      'onScanComplete',
      'onSubmit',
    ].forEach((m) => this[m] = this[m].bind(this));

    this.state = {
      showFormModal: false,
      showScanModal: false,
      fastTrack: false
    };
  }

  // fetch referral sources from api on mount
  componentWillMount() {
    this.props.actions.getUnpaginatedData(
      `/api/referral_sources`,
      dataNames.referralSources,
      {failed: I18n.t('customers.getReferralSources.failed')}
    );
  }

  // hide customer data modal
  hideFormModal() {
    this.setState({showFormModal: false});
  }

  // display customer data modal
  showFormModal() {
    this.setState({showFormModal: true});
  }

  // hide license scan modal
  hideScanModal() {
    this.setState({showScanModal: false});
  }

  // display license scan modal
  showScanModal(scanType) {
    this.setState({showScanModal: true, scanType});
  }

  // 'Fast Track' button handler
  onFastTrack() {
    this.setState({fastTrack: true});
    this.props.actions.setItem({},itemNames.order);
    this.props.actions.setItem({},itemNames.customer);
    this.props.actions.submit(ANONYMOUS_CUSTOMER_FORM);
  }

  // 'Start Order' button handler
  onStartOrder() {
    this.setState({fastTrack: false});
    this.props.actions.setItem({},itemNames.order);
    this.props.actions.setItem({},itemNames.customer);
    this.props.actions.submit(ANONYMOUS_CUSTOMER_FORM);
  }

  // 'Scan ID' button handler
  onScanId() {
    this.showScanModal();
  }

  // customer form submission handler
  onSubmit(formValues) {
    const refSource = formValues.referral_source;

    const payload = Object.assign({},{
      consumer_id: null,
      consumer_name: [formValues.first_name,formValues.last_name].join(' '),
      consumer_birth_date: formValues.birth_date ? moment(formValues.birth_date).format('YYYY-MM-DD') : null,
      consumer_referral_source: typeof refSource === 'object' ? refSource.name : refSource,
      consumer_gender: formValues.gender,
      consumer_postal_code: formValues.postal_code,
      consumer_type: formValues.type,
      consumer_identification_method: formValues.identification_method,
    });
    const messages = {success: 'orders.create.success', failed: null};
    this.props.actions.postItem(
      '/api/orders',
      payload,
      itemNames.order,
      messages,
      {},
      (order) => {
        if(this.state.fastTrack){
          this.props.actions.push('/cart');
        } else {
          this.props.actions.push('/product-menu/' + order.id);
        }
      }
    );
  }

  // drivers license scan handler
  onScanComplete(parsedData) {
    const firstName   = get(parsedData,'name.first',null);
    const lastName    = get(parsedData,'name.last',null);
    const birthDate   = moment(get(parsedData,'birthday',null),'YYYYMMDD');
    const expirationDate = moment(get(parsedData,'expiration_date',null),'YYYYMMDD');
    const postalCode  = get(parsedData,'postal_code',null);
    const gender      = get(parsedData,'sex','').toLowerCase();

    this.props.actions.change(ANONYMOUS_CUSTOMER_FORM,'first_name',firstName);
    this.props.actions.change(ANONYMOUS_CUSTOMER_FORM,'last_name',lastName);
    this.props.actions.change(ANONYMOUS_CUSTOMER_FORM,'birth_date',birthDate);
    this.props.actions.change(ANONYMOUS_CUSTOMER_FORM,'expiration_date',expirationDate);
    this.props.actions.change(ANONYMOUS_CUSTOMER_FORM,'postal_code',postalCode);
    this.props.actions.change(ANONYMOUS_CUSTOMER_FORM,'gender',gender);
    this.props.actions.touch(ANONYMOUS_CUSTOMER_FORM,'birth_date');
    this.props.actions.touch(ANONYMOUS_CUSTOMER_FORM,'expiration_date');
    this.setState({ showScanModal: false });
  }

  // render primary component
  render() {
    return (
      <div className='anonymous-order'>
        <AnonymousOrderButton onClick={this.showFormModal} />

        <ModalWrapper
          className='anonymous-customer-form-modal'
          version={2}
          showModal={this.state.showFormModal}
          onHide={this.hideFormModal}
          title={I18n.t('orders.anonymous.formTitle')}
          cancelButton={{show: false}}
          okayButton={{show: false}}
          keyboard={true}
          headerClass='bg-white'
          widthClass='modal-sm'
          >

          <AnonymousCustomerFormWrapper
            ageLimit={this.props.ageLimit}
            customerTypes={this.props.typeOptions}
            referralSources={this.props.referralSources}
            genders={this.props.genderOptions}
            onFastTrack={this.onFastTrack}
            onStartOrder={this.onStartOrder}
            onScanId={this.onScanId}
            onSubmit={this.onSubmit}
            initialValues={this.props.initialValues}
            />

        </ModalWrapper>

        <ScanInputModal
          hideModal={this.hideScanModal}
          showModal={this.state.showScanModal}
          scanType={this.state.scanType}
          onScanComplete={this.onScanComplete}
        />
      </div>
    );
  }
}

AnonymousOrderBundle.propTypes = {
  actions: PropTypes.shape({
    getUnpaginatedData: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }),
  genderOptions: PropTypes.array.isRequired,
  typeOptions: PropTypes.array.isRequired
};

AnonymousOrderBundle.defaultProps = {
  actions: {
    change: () => {},
    getUnpaginatedData: () => {},
    push: () => {},
  },
  genderOptions: [
    { text: I18n.t('common.form.female'),
      value: 'female' },
    { text: I18n.t('common.form.male'),
      value: 'male' },
    { text: I18n.t('common.form.unspecified'),
      value: 'unspecified' }
  ],
  typeOptions: [
    { text: I18n.t('customers.create.medical'),
      value: consumerTypes.MEDICAL },
    { text: I18n.t('customers.create.recreational'),
      value: consumerTypes.RECREATIONAL },
  ],
};

function mapStateToProps(state) {
  return {
    referralSources: get(state,'referralSources',[]),
    orderId: get(state,'order.id',null),
    ageLimit: getSalesAgeLimit(state),
    typeOptions: getAnonymousCustomerTypes(state),
    salesSettings: state[itemNames.salesComplianceSettings],
    initialValues: getInitialValues(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      change,
      getUnpaginatedData,
      postItem,
      push,
      setItem,
      getItem,
      submit,
      touch,
    }, dispatch),
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(AnonymousOrderBundle);
