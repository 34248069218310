import {createSelector} from 'reselect';
import * as itemNames from '../constants/itemNames';
import {getDetailedCustomers} from './customersSelectors';
import {getCurePatientRaw, getCureCaregiverRaw} from './integration/cureApiSelectors';
import {getCustomerCaregivers} from './customerSelectors';
import {PA_MED_CAREGIVER} from '../constants/idTypes';

export const getOraclePatientRaw = state => state[itemNames.oraclePatient];
export const getOracleCaregiverRaw = state => state[itemNames.oracleCaregiver];

function mergeConsumer(patient, consumers) {
  if (patient.consumer) {
    const consumer = consumers.find(c => c.id === patient.consumer.id) || {};
    const mergedConsumer = {
      ...patient,
      consumer: {
        ...patient.consumer,
        ...consumer,
      },
    };
    if(consumer.queue_id){ patient.queue_id = consumer.queue_id; }
    if(consumer.order_id){ patient.order_id = consumer.order_id; }
    return mergedConsumer;
  }
  return patient;
}

const mergeConsumerSelector = patientSelector => createSelector(
  [patientSelector, getDetailedCustomers],
  mergeConsumer
);

export const getCurePatient = mergeConsumerSelector(getCurePatientRaw);
export const getCureCaregiver = mergeConsumerSelector(getCureCaregiverRaw);
export const getOraclePatient = mergeConsumerSelector(getOraclePatientRaw);

export const getOracleCaregiver = createSelector(
  [getOracleCaregiverRaw, getDetailedCustomers, getCustomerCaregivers],
  (oracleCaregiver, consumers, customerCaregivers) => {

    const customerCaregiver = customerCaregivers.find(customerCaregiver => customerCaregiver.state_integration_tracking_id === oracleCaregiver.caregiver_id);
    if (customerCaregiver) oracleCaregiver.id = customerCaregiver.id;

    if (oracleCaregiver.patients && oracleCaregiver.patients.length) {
      return {
        ...oracleCaregiver,
        patients: oracleCaregiver.patients.map(patient => mergeConsumer(patient, consumers)),
      };
    }
    return oracleCaregiver;
  }
);

export const getNormalizedOracleData = ({registry_id, card_number, ...formData}) => ({
  ...formData,
  //convert such registry_id/card_number 111 to the full string 0000000111
  registry_id: '0'.repeat(Math.max(0, 10 - registry_id.length)) + registry_id,
  card_number: '0'.repeat(Math.max(0, 10 - card_number.length)) + card_number,
  card_type: (formData.license_type === PA_MED_CAREGIVER) ? 'caregiver' : 'patient'
});
