import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack} from 'react-router-redux';
import {formValueSelector} from 'redux-form';
import {START_PROCESSING_FORM} from '../../../../constants/forms';
import * as dataNames from '../../../../constants/dataNames';
import * as itemNames from '../../../../constants/itemNames';
import * as messageTypes from '../../../../constants/messageTypes';
import * as productionRunTypes from '../../../../constants/productionRunTypes';
import {
  ensureGetUnpaginatedData,
  getUnpaginatedData,
  postData,
  getDataBatchByPost,
  getDataByPost,
  getItem
} from '../../../../actions/apiActions';
import {addMessage} from '../../../../actions/systemActions';
import {unsetItem} from '../../../../actions/itemActions';
import {unsetData} from '../../../../actions/dataActions';
import {getOptionErrorMessage} from '../../common/productionRunMessages';
import {getSelectedProducts} from '../../../../selectors/productsSelectors';
import {
  getItemOptions,
  getProcessingItemOptions,
  getStartProcessingPayload,
  getStartProcessingInitialValues,
  getFilteredProcessingTypes
} from '../../../../selectors/processingSelectors';
import {getCurrentFacilityUserOptions} from '../../../../selectors/usersSelectors';
import {roundQtyCurried} from '../../../../selectors/uomsSelectors';
import InProgressOverlay from '../../../common/InProgressOverlay';
import FormWrapper from '../../../common/form/FormWrapper';
import StartProcessingFormWrapper from './StartProcessingFormWrapper';
import {
  isAllowNegativeInventory,
  getInventoryComplianceSettings
} from '../../../../selectors/complianceSettingsSelectors';
import NegativeInventoryAlert from '../../../common/negative-inventory/NegativeInventoryAlert';
import showNegativeAlert from '../../../common/negative-inventory/showNegativeAlert';
import * as statuses from '../../../../constants/statuses';
import {isFeatureEnabled} from '../../../../selectors/featureToggles';
import {isLeafPaConfigPackClosedLoopFacility} from '../../../../selectors/facilitiesSelectors';

const selector = formValueSelector(START_PROCESSING_FORM);

export class StartProcessingPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.loadTestResults = this.loadTestResults.bind(this);
    this.setNegativeConfirmationState = this.setNegativeConfirmationState.bind(this);

    this.state = {
      ready: false,
      submitting: false,
      dataLoaded: false,
      showNegativeInvConfirmation: {
        show: false,
        onHide: null,
        onConfirm: null,
      },
    };
  }

  componentWillMount() {
    this.props.actions.getItem('/api/compliance_settings', itemNames.complianceSettings)
      .then(compliance => this.loadData(compliance))
      .catch(() => this.loadData());
  }

  setNegativeConfirmationState(state) {
    this.setState({
      showNegativeInvConfirmation: state
    });
  }

  loadData(compliance) {
    const {
      actions: {ensureGetUnpaginatedData, getUnpaginatedData, getDataByPost, goBack, addMessage},
      selectedProducts
    } = this.props;
    this.props.actions.unsetData(dataNames.inventoryItems); // seems to be using previous inventoryItems in selector - attempt to solve
    const promises = [
      getUnpaginatedData('/api/equipment', dataNames.equipmentItems, {failed: 'equipment.get.failed'}),
      getUnpaginatedData('/api/processing_types', dataNames.processingTypes, {failed: 'ei.processingTypes.get.failed'}, {detailed: 1}),
      getUnpaginatedData('/api/partners', dataNames.partners, {failed: 'partners.get.failed'}),
      getUnpaginatedData('/api/users/current_facility', dataNames.currentFacilityUsers, {failed: 'packaging.getUsers.failed'}),
      ensureGetUnpaginatedData('/api/phases', dataNames.phases, {failed: 'phases.get.failed'}),
      getUnpaginatedData('/api/strains/by_organization', dataNames.organizationStrains, {failed: 'strains.get.failed'}, {with_trashed: 1}),
      getUnpaginatedData('/api/subcategories', dataNames.subcategories, { failed: 'categories.get.failed' }, { detailed: 1 })
    ];

    const allowedIds = [];

    promises.push(
      getUnpaginatedData(
        '/api/production_runs',
        dataNames.productionRuns,
        {failed: 'productionRuns.get.failed'},
        {detailed: 1, status: statuses.open}
      ).then(() => {
        if (selectedProducts.length) {
          const {initialValues: {productionRunPackageCodes}} = this.props;

          selectedProducts.map(product => {
            if (productionRunPackageCodes.includes(product.package_code)) {
              addMessage(
                messageTypes.warning,
                ['cart.getScannedItem.notFound', {packageId: product.package_code}]
              );
            } else {
              allowedIds.push(product.item_id);
            }
          });

          return Promise.all([
            getDataByPost('/api/items/multiple', {
              ids: allowedIds,
              detailed: 1
            }, dataNames.inventoryItems, {failed: 'products.get.failed'}, {detailed: 1, testResults: 1})
              .then(() => {
                const {items} = this.props;
                const ids = items.map(item => item.item_master_id);
                return getDataByPost(
                  '/api/item_masters/multiple',
                  {ids},
                  dataNames.itemMasters,
                  {failed: 'ei.processingTypes.form.failed'},
                  {detailed: 1}
                );
              })
              .then(() => {
                const {allowNegativeInventory, items, initialValues, actions: {addMessage}} = this.props;

                items
                  .filter(item => !initialValues.inputs.some(input => input.item_id === item.item_id))
                  .forEach(item => {
                    const error = getOptionErrorMessage(item, productionRunTypes.processing, undefined, allowNegativeInventory);
                    addMessage(messageTypes.error, [`ei.searchItem.${error}`, {packageId: item.package_code}]);
                  });
                this.setState({dataLoaded: true});
              }),
            getDataByPost(
              '/api/item_availability/multiple',
              {ids: allowedIds}, dataNames.itemsAvailability,
              {failed: 'itemsAvailability.get.failed'}
            ).then((results) => {
              if (!results) return true;
              if (!this.props.allowNegativeInventory) return true;
              const negativeItem = results.find(r => r.qty_onhand_base <= r.qty_reserved_base);
              return showNegativeAlert(!!negativeItem, this.setNegativeConfirmationState).catch(goBack);
            }),

            getUnpaginatedData(
              '/api/item_reservations',
              dataNames.hardReservations,
              {failed: 'reservations.failed'},
              {in_item_ids: allowedIds}
            )
          ]);
        }
      })
    );

    Promise
      .all(promises)
      .then(() => this.setState({ready: true}))
      .catch(() => this.setState({ready: true}));
  }

  loadTestResults(packageIds) {
    this.setState({dataLoaded: false});
    const filter = '(' +
      packageIds.map(id => `package_id:${id}`).join(' OR ') +
      ') AND is_latest_lab_result:1 AND is_active_package:1';
    const payload = {filter, query: 'matchall', start: 0, size: 10000};
    return this.props.actions.getDataBatchByPost(
      '/api/search/lab_results',
      payload,
      dataNames.testResults,
      {failed: 'cultivation.testResults.get.failed'},
      null,
      () => {
        this.setState({dataLoaded: true});
      }
    );
  }

  onSubmit(fromData) {
    const {roundQty} = this.props;
    this.setState({submitting: true});
    return this.props.actions
      .postData(
        '/api/processing_jobs',
        getStartProcessingPayload(fromData, this.props.timezone, roundQty),
        dataNames.processingJobs,
        {success: 'ei.processing.start.success', failed: 'ei.processing.start.failed'}
      )
      .then(() => {
        this.setState({submitting: false});
        this.props.actions.goBack();
      })
      .catch(() => this.setState({submitting: false}));
  }

  clearSearch() {
    return this.props.actions.unsetItem(itemNames.checkoutScan);
  }

  render() {
    const {
      employees,
      equipments,
      processingTypes,
      itemOptions,
      inputs,
      initialValues,
      allowNegativeInventory,
      actions: {addMessage},
      complianceSettings,
      isPaRemediationLabelsEnabled
    } = this.props;
    const {ready, submitting, showNegativeInvConfirmation} = this.state;
    const message = `common.form.${submitting ? 'saving' : 'loadingData'}`;
    return (
      <FormWrapper title='ei.inventory.actions.startProcessing' className='start-processing-page'
                   goBack={this.props.actions.goBack}>
        <InProgressOverlay isActive={!ready || submitting} translate={true} message={message}/>
        <NegativeInventoryAlert confirmationState={showNegativeInvConfirmation}/>
        {!ready ? null : (
          <StartProcessingFormWrapper
            form={START_PROCESSING_FORM}
            initialValues={initialValues}
            onSubmit={this.onSubmit}
            itemOptions={itemOptions}
            addMessage={addMessage}
            loadTestResults={this.loadTestResults}
            clearSearch={this.clearSearch}
            inputs={inputs}
            employees={employees}
            equipments={equipments}
            processingTypes={processingTypes}
            ready={ready}
            allowNegativeInventory={allowNegativeInventory}
            complianceSettings={complianceSettings}
            isPaRemediationLabelsEnabled={isPaRemediationLabelsEnabled}
          />
      )}
      </FormWrapper>
    );
  }
}

StartProcessingPage.propTypes = {
  actions: PropTypes.shape({
    addMessage: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    ensureGetUnpaginatedData: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    getDataByPost: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired,
  }).isRequired,
  selectedProducts: PropTypes.array.isRequired,
  itemOptions: PropTypes.array.isRequired,
  employees: PropTypes.array.isRequired,
  equipments: PropTypes.array.isRequired,
  processingTypes: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  inputs: PropTypes.array,
  initialValues: PropTypes.object,
  timezone: PropTypes.string.isRequired,
  isPaRemediationLabelsEnabled: PropTypes.bool.isRequired
};

function mapDispatchToProps(dispatch) {
  const actions = {
    addMessage,
    goBack,
    ensureGetUnpaginatedData,
    getUnpaginatedData,
    getDataBatchByPost,
    postData,
    getDataByPost,
    unsetItem,
    unsetData,
    getItem
  };
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps(state) {
  const inputs = selector(state, 'inputs');

  const selectedProducts = getSelectedProducts(state);
  const packageIds = selectedProducts.reduce(
    (acc, product) => {
      acc.indexOf(product.package_id) > -1 || acc.push(product.package_id);
      return acc;
    },
    []
  );

  return {
    inputs,
    initialValues: getStartProcessingInitialValues(state),
    employees: getCurrentFacilityUserOptions(state),
    equipments: state[dataNames.equipmentItems],
    processingTypes: getFilteredProcessingTypes(state, inputs), //state[dataNames.processingTypes],
    items: getItemOptions(state),
    itemOptions: getProcessingItemOptions(state, inputs),
    timezone: state.timezone,
    packageIds,
    selectedProducts,
    allowNegativeInventory: isAllowNegativeInventory(state),
    complianceSettings: getInventoryComplianceSettings(state),
    roundQty: roundQtyCurried(state),
    isPaRemediationLabelsEnabled: isLeafPaConfigPackClosedLoopFacility(state) && isFeatureEnabled(state)('feature_pa_hb_1024_remediation_labels'),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StartProcessingPage);
