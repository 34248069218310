/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {goBack} from 'react-router-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {getUnpaginatedData} from '../../../../actions/apiActions';
import flattenLocations from '../../../../util/flattenLocations';
import * as dataNames from '../../../../constants/dataNames';
import CreateSuppliesForm from './CreateSuppliesForm';

export class CreateSuppliesPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    this.props.actions.getUnpaginatedData('/api/partners', dataNames.partners, undefined, {purchase_from: 1});
    this.props.actions.getUnpaginatedData('/api/location_hierarchy', dataNames.locations, {failed: 'locations.getLocations.failed'});
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.actions.goBack();
  }

  render() {
    const {partners, locations} = this.props;
    return (
      <div className='supplies-page'>
        <h1>{I18n.t('retail.title')}{I18n.t('retail.supplies.title')}</h1>
        <CreateSuppliesForm handleSubmit={this.handleSubmit} partners={partners} storageLocations={locations}/>
      </div>
    );
  }
}

CreateSuppliesPage.propTypes = {
  partners: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired
  }).isRequired
};

function mapStateToProps(state) {
  return {
    partners: state.partners,
    locations: [...flattenLocations(state.locations)]
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {...{}, ...{getUnpaginatedData}, ...{goBack}};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateSuppliesPage);
