import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';
import {push} from 'react-router-redux';
import * as types from '../constants/actionTypes';
import {handleCureApiValidationErrors} from '../selectors/integration/cureApiSelectors';
import {getOrder} from '../selectors/orderSelectors';
import * as apiActions from './apiActions';
import {getIntegrationState} from '../selectors/integration/integrationSelectors';
import {getSalesLimitError} from '../selectors/salesSettingsSelectors';
import {addMessage} from './systemActions';
import * as messageTypes from '../constants/messageTypes';

export function selectCoupon(couponId) {
  return {type: types.SET_COUPON, couponId};
}

export function addAppliedCoupon(couponId) {
  return {type: types.ADD_APPLIED_COUPON, couponId};
}

export function setCheckout(checkout) {
  return {type: types.SET_CHECKOUT, checkout};
}

export function setManualDiscount(manualDiscount) {
  return {type: types.SET_MANUAL_DISCOUNT, manualDiscount};
}

export function setTaxRate(taxRate) {
  return {type: types.SET_TAX_RATE, taxRate};
}

export function setItems(items) {
  return {type: types.SET_ITEMS, items};
}

export function setItemPricingWeightId(itemId, pricingWeightId) {
  return {type: types.SET_ITEM_PRICING_WEIGHT_ID, itemId, pricingWeightId};
}

export function setItemPackageId(itemId, packageId) {
  return {type: types.SET_ITEM_PACKAGE_ID, itemId, packageId};
}

export function setItemQuantity(itemId, quantity) {
  return {type: types.SET_ITEM_QUANTITY, itemId, quantity};
}

export function setCredit(credit) {
  return {type: types.SET_CREDIT, credit};
}

export function setCheck(check) {
  return {type: types.SET_CHECK, check};
}

export function setCash(cash) {
  return {type: types.SET_CASH, cash};
}

export function setDebit(debit) {
  return {type: types.SET_DEBIT, debit};
}

export function setGiftCard(giftCard) {
  return {type: types.SET_GIFT_CARD, giftCard};
}

export function closeAssignDriver() {
  return {type: types.CLOSE_ASSIGN_DRIVER};
}

export function openAssignDriver() {
  return {type: types.OPEN_ASSIGN_DRIVER};
}

export function setStoreCredit(storeCredit) {
  return {type: types.SET_STORE_CREDIT, storeCredit};
}

export function resetCart() {
  return {type: types.RESET_CART};
}

export function toggleCheckout() {
  return (dispatch, getState) => {
    const checkout = !getState().cart.checkout;
    return dispatch(setCheckout(checkout));
  };
}

export function applyCoupon() {
  return (dispatch, getState) => {
    const couponId = getState().cart.coupon;
    dispatch(selectCoupon(null));
    return dispatch(addAppliedCoupon(couponId));
  };
}

export function removeCoupon(couponId) {
  return {type: types.REMOVE_COUPON, couponId};
}

export function removeItem(itemId) {
  return {type: types.REMOVE_ITEM, itemId};
}

export function addItem(item) {
  return {type: types.ADD_ITEM, item};
}

// @TODO: Remove setTemporaryCart, openAssignDriver and closeAssignDriver after cart can get order type and patient tax info from redux.
export function setTemporaryCart(item) {
  return {type: types.SET_ITEM, name: 'temporaryCart', item};
}

export const validateSalesLimit = (page, loaders) => (dispatch, getState) => {
  const state = getState();
  const order = getOrder(state);
  const integratorState = getIntegrationState(state);
  const pages = {
    payment: `/orders/${order.id}/payment`,
    pickup: `/orders/${order.id}/printing`,
    delivery: `/orders/${order.id}/delivery`,
    pickup_queue: `/queue/pickup`,
    delivery_queue: `/queue/delivery`
  };

  const cureValidationActions = ['payment', 'delivery'];
  if (cureValidationActions.indexOf(page) !== -1 && integratorState.isCure) {
    loaders.cureLoader();
    dispatch(apiActions.postItem('/api/cureapi/validate_order', {order_id: order.id}, null, {}, null, null, {silenceErrors: true}))
      .then(() => {
        loaders.hideLoader();
        dispatch(push(pages[page]));
      }).catch((e) => {
        loaders.hideLoader();
        const handleError = (type, message, localization) => dispatch(addMessage(type, message, localization));
        handleCureApiValidationErrors(e, handleError);
      });
    return true;
  } else if (integratorState.isMiMetrc || integratorState.isDcMetrc || integratorState.isMdMetrc) {
    loaders.metrMiLoader();
    dispatch(apiActions.postItem(`/api/orders/${order.id}/validate_order_by_sales_limit`, null, null, {
      failedHandler: false,
      showBEValidationMessages: false
    }, null, null, {silenceErrors: true}))
      .then(() => {
        loaders.hideLoader();
        dispatch(push(pages[page]));
      }).catch((e) => {
        loaders.hideLoader();
        const error = getSalesLimitError(e);
        if (error) {
          dispatch(addMessage(messageTypes.error, error.message));
        }
      });
    return true;
  }
  dispatch(push(pages[page]));
};

export const handleErrors = (errors) => {
  const errorMessage = get(errors, 'response.data.errors.MESSAGE', false);
  const errorValidationMessage = get(errors, 'response.data.errors.VALIDATION.ORDER_ERROR', false);
  const errorOrderCheckValidationMessage = get(errors, 'response.data.errors.VALIDATION.order_id.0', false);

  if (errorMessage || errorOrderCheckValidationMessage || (errorValidationMessage[0] && errorValidationMessage[0] !== 'sales_restriction_weight_exceeded')) {
    return {
      message: errorMessage || errorOrderCheckValidationMessage || errorValidationMessage[0],
      translate: false,
    };
  }

  if (errorValidationMessage[0] === 'sales_restriction_weight_exceeded') {
    return {
      message: 'orders.purchasedAmounts.limitExceeded',
      translate: false,
    };
  }

  const inventoryLimitError = get(errors, 'response.data.errors.VALIDATION.item_master_id.0', false);
  const productMaxLimitError = get(errors, 'response.data.errors.VALIDATION.max_limits.0', false);
  const gateErrors = get(errors, ['response', 'data', 'errors', 'VALIDATION', 'order_check_order.item_master_id', '0'], false);

  if (inventoryLimitError || productMaxLimitError || gateErrors) {
    return {
      message: inventoryLimitError || productMaxLimitError || gateErrors,
      translate: false,
    };
  }

  if (!errors.response) {
    return {message: I18n.t('productMenu.networkError')};
  }
  const response = errors.response.data;
  if (response.errors && response.errors.item_master_id) {
    return {message: response.errors.item_master_id.pop()};
  }
  return getSalesLimitError(errors);
};
