//import {I18n} from 'react-redux-i18n';
import {EMAIL_REG_EXP} from '../../../common/form/validationRegExs';

function validate(originalValues, props) {

  const values = Object.assign({}, originalValues);
  const errors = {};

  errors.existing_partner_id = values.existing_partner_id === undefined || values.existing_partner_id === null
    ? 'A partner or the option to create a new one must be selected.'
    : undefined;

  const facilitiesSetToNotConnect = values.facilities.reduce((acc, facility) => {
    if(!isNaN(facility.existing_facility_id) && facility.existing_facility_id < 0){
      acc += 1;
    }
    return acc;
  }, 0);

  errors.facilities = values.facilities.map((facility) => {
    const noFacilitiesAreConnected = () => {
      return facilitiesSetToNotConnect === values.facilities.length;
    };

    const facilityValueIsNotSet = () => {
      return isNaN(parseInt(facility.existing_facility_id));
    };

    return {
      existing_facility_id: noFacilitiesAreConnected()
        ? 'At least one facility must be connected.'
        : facilityValueIsNotSet()
          ? 'You must select a value for the facility connection.'
          : undefined
    };
  });

  if(props.emailIsRequired){
    if(!values.requested_marketing_email || values.requested_marketing_email === null ||  (typeof values.requested_marketing_email === 'string' && values.requested_marketing_email.trim() === '')){
      errors.requested_marketing_email = 'Email is required if you have requested communication.';
    } else {
      if (!EMAIL_REG_EXP.test(values.requested_marketing_email)) {
        errors.requested_marketing_email = 'Email format is invalid.';
      }
    }
  }

  errors.partner_type = !values.partner_type || !values.partner_type.length && !isNaN(parseInt(values.existing_partner_id)) ? 'Partner Type must be selected.' : undefined;

  errors.payment_terms = !values.payment_terms ? 'Payment Terms must be selected' : undefined;

  return errors;
}

export default validate;
