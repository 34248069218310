import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';

const SelectControl = ({options, value, valueKey, textKey, defaultOption, ...props}) => {
  return (
    <FormControl
      {...props}
      as='select'
      value={value || undefined}>
      {defaultOption && <option value=''>{defaultOption}</option>}
      {options.map((option, index) => {
        return (
          <option key={index} value={option[valueKey]}>
            {option[textKey]}
          </option>
        );
      })
    }
    </FormControl>
  );
};

SelectControl.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
    PropTypes.array.isRequired
  ]),
  valueKey: PropTypes.string.isRequired,
  textKey: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  defaultOption: PropTypes.string
};
SelectControl.defaultProps = {
  value: '',
  valueKey: 'value',
  textKey: 'text'
};
export default SelectControl;
