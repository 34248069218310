import React from 'react';
import PropTypes from 'prop-types';
import {reduxForm, FieldArray} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import SubmitSection from '../../../common/form/SubmitSection';
import SuppliesFieldArray from './SuppliesFieldArray';

const initialValues = {
  cultivationSupplies: [{}]
};

export const CreateSuppliesForm = (props) => {
  const {handleSubmit, reset, partners, storageLocations} = props;
  const submitSettings = {
    actionSettings: {
      reset: {
        text: I18n.t('common.actions.reset'),
        action: reset,
        style: 'default'
      },
      return: {
        text: I18n.t('retail.supplies.create.returnSupplies'),
        action: reset
      },
      submit: {
        text: I18n.t('retail.supplies.create.addSupplies'),
        action: handleSubmit
      }
    },
    align: 'right'
  };
  return (
    <form>
      <FieldArray name='cultivationSupplies' component={SuppliesFieldArray} partners={partners} storageLocations={storageLocations}/>
      <SubmitSection settings={submitSettings}/>
    </form>
  );
};

CreateSuppliesForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  partners: PropTypes.array.isRequired,
  storageLocations: PropTypes.array.isRequired
};

export default reduxForm({
  form: 'cultivationSuppliesForm',
  initialValues
})(CreateSuppliesForm);
