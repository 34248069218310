import {createSelector} from 'reselect';
import * as itemNames from '../../constants/itemNames';

export const getCreditCardSettingsRaw = state => state[itemNames.creditCardsSettings];
const getProcessor = settings => settings.payment_credit_card_processor && settings.payment_credit_card_processor.value;

export const getCreditCardProcessor = createSelector(
  [getCreditCardSettingsRaw],
  getProcessor
);

export const isCreditCardPaymentEnabled = createSelector(
  [getCreditCardProcessor],
  processor => processor && processor !== 'none'
);

export const getCreditCardSettings = createSelector(
  [getCreditCardSettingsRaw],
  (settings) => {
    const processor = getProcessor(settings);
    let details = null;
    if (processor === 'authnet') {
      details = settings.payment_cc_authnet_settings && settings.payment_cc_authnet_settings.value;
    } else if (processor === 'nmi') {
      details = settings.payment_cc_nmi_settings && settings.payment_cc_nmi_settings.value;
    }

    return {
      processor,
      details,
    };
  }
);

export const getIsMonerisProcessor = createSelector(
  [getCreditCardProcessor],
  (processor) => processor === 'moneris'
);
