import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Alert} from 'react-bootstrap';
import {FaCreditCard} from 'react-icons/fa';
import ScanInputModal from '../../scan-input/ScanInputModal';
import CardPayment from './CardPayment';

class CardPaymentComponent extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {showScanModal: false};
    this.showScamModal = this.showScamModal.bind(this);
    this.hideScanModal = this.hideScanModal.bind(this);
    this.onScanComplete = this.onScanComplete.bind(this);
  }

  showScamModal() {
    this.setState({showScanModal: true});
  }

  hideScanModal() {
    this.setState({showScanModal: false});
  }

  onScanComplete(value) {
    this.props.onScanComplete(value) && this.hideScanModal();
  }

  render() {
    const {amount, paymentDisabled, isOverride} = this.props;
    const {showScanModal} = this.state;
    return (
      <div>
        <CardPayment
          amount={amount}
          isOverride={isOverride}
          paymentDisabled={paymentDisabled}
          showScamModal={this.showScamModal}
        />
        <ScanInputModal
          showModal={showScanModal}
          hideModal={this.hideScanModal}
          onScanComplete={this.onScanComplete}
          skipParsing={true}
          scanType='swipe'
          bodyMessage={
            <Alert variant='info'>
              <FaCreditCard/>
              <span className='padding-left'>
                {I18n.t('cart.payment.card.paymentNote', {amount: Number(amount || 0).toFixed(2)})}
              </span>
            </Alert>
          }
        />
      </div>
    );
  }
}

CardPaymentComponent.propTypes = {
  amount: PropTypes.number,
  isOverride: PropTypes.bool,
  paymentDisabled: PropTypes.bool
};

export default CardPaymentComponent;
