import { createSelector } from 'reselect';
import get from 'lodash.get';
import * as itemNames from '../../constants/itemNames';

const getFacility = (state) => state[itemNames.facility];

export const isUtah = (facility) => {
  return get(facility, 'configuration_packs', [])
    .some((configPack) => configPack.includes('utah'));
};

export const isUtahSelector = createSelector([getFacility], (facility) => {
  return isUtah(facility);
});

export const isNotUtahSelector = createSelector([getFacility], (facility) => {
  return !isUtah(facility);
});
