import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { Row, Col, Alert } from 'react-bootstrap';
import {FaExclamationTriangle} from 'react-icons/fa'; //eslint-disable-line
import SubmitSection from '../../common/form/SubmitSection';
import { validate } from './validate';
import { PLANT_SPLIT_FORM } from '../../../constants/forms';
import { convertDbDateTimeToFormInputDateTime } from '../../../util/dateHelpers';
import NewPlants from './NewPlants';

export const PlantSplitForm = (props) => {
  const { submitting, pristine, handleSubmit, splitPlant, sections, newQty, error, trackPlantsAsGroups } = props;
  const settings = {
    actionSettings: {
      saveAndStay: {
        type: 'button',
        disabled: submitting || pristine,
        submitting,
        text:
          props.afterSubmit === 'print' ? I18n.t('common.form.printLabels') : I18n.t('common.form.saveAndPrintLabels'),
        action: (e) => {
          e.target.blur();
          if (props.afterSubmit !== 'print') {
            props.change('afterSubmit', 'print');
            setTimeout(() => {
              handleSubmit();
            }, 10);
          } else {
            props.onPrintLabels();
          }
        }
      },
      submit: {
        disabled: submitting || pristine || props.afterSubmit === 'print',
        submitting,
        text: I18n.t('common.form.save')
      }
    }
  };

  const location = sections.find((section) => section.id === parseInt(splitPlant.section_id));

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div>
        <h4>{I18n.t(trackPlantsAsGroups ? 'plants.split.sourceGroupTitle' : 'plants.split.sourceTitle')}</h4>
      </div>
      <div>
        <table className='table table-sm table-bordered'>
          <thead>
            <tr>
              <th style={{ width: '25%' }}>
                {I18n.t(trackPlantsAsGroups ? 'plants.split.plantGroupId' : 'plants.split.plantId')}
              </th>
              <th style={{ width: '25%' }}>{I18n.t('plants.split.qty')}</th>
              <th style={{ width: '25%' }}>{I18n.t('plants.split.plantingDate')}</th>
              <th style={{ width: '25%' }}>{I18n.t('plants.split.storageLocation')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{splitPlant.plant_id}</td>
              <td>{splitPlant.qty}</td>
              <td>{convertDbDateTimeToFormInputDateTime(splitPlant.planting_date)}</td>
              <td>{location && location.name ? location.name : 'Not Available'}</td>
            </tr>
            <tr>
              <td style={{ fontWeight: 'bold', textAlign: 'right' }}>{I18n.t('plants.split.remainingQty')}</td>
              <td>{newQty}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <Row>
          <Col md={12}>
            <h4>{I18n.t(trackPlantsAsGroups ? 'plants.split.newPlantsGroupTitle' : 'plants.split.newPlantsTitle')}</h4>
          </Col>
        </Row>
      </div>
      {error ? (
        <Row>
          <Col xs={12}>
            <Alert variant='danger'>
              <FaExclamationTriangle />
              <span className='padding-left'>{error}</span>
            </Alert>
          </Col>
        </Row>
      ) : null}
      <div>
        <table className='table table-sm table-striped table-bordered'>
          <thead>
            <tr>
              <th style={{ width: '50px' }}>{I18n.t('plants.split.delete')}</th>
              <th style={{ width: '50px' }}>{I18n.t('plants.split.ord')}</th>
              <th style={{ width: '25%' }}>{I18n.t('plants.split.newPlantQty')}</th>
              <th style={{ width: '25%' }}>{I18n.t('plants.split.numberOfGroups')}</th>
              <th style={{ width: '50%' }}>{I18n.t('plants.split.location')}</th>
              <th style={{ width: '50px' }}>{I18n.t('plants.split.add')}</th>
            </tr>
          </thead>
          <FieldArray name='splits' component={NewPlants} sections={sections} />
        </table>
      </div>
      <SubmitSection settings={settings} />
    </form>
  );
};

PlantSplitForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  change: PropTypes.func,
  splitPlant: PropTypes.object.isRequired,
  sections: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onPrintLabels: PropTypes.func.isRequired,
  newQty: PropTypes.number,
  actions: PropTypes.object,
  splits: PropTypes.array,
  afterSubmit: PropTypes.string,
  error: PropTypes.string,
  trackPlantsAsGroups: PropTypes.bool
};

const form = reduxForm({
  form: PLANT_SPLIT_FORM,
  enableReinitialize: true,
  validate
})(PlantSplitForm);

function mapStateToProps(state) {
  const selector = formValueSelector(PLANT_SPLIT_FORM);
  return {
    newQty: selector(state, 'newQty'),
    afterSubmit: selector(state, 'afterSubmit')
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, {});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form);
