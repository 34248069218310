import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Card, Col, Row} from 'react-bootstrap';
import ItemDetailsTable from './ItemDetailsTable';

const ItemCreatedDetails = ({item}) => {
  return (
    <Card>
      <Row>
        <Col xs={6}>
          <h3>{I18n.t('itemTransactions.item.itemDetails')}</h3>
          <ItemDetailsTable item={item}/>
        </Col>
      </Row>
    </Card>
  );
};

ItemCreatedDetails.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ItemCreatedDetails;
