import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';

import {registerFormAsOpen, unregisterFormAsOpen} from '../../../actions/forms/openFormActions';
import {isFeatureEnabled} from '../../../selectors/featureToggles';


class DirtyFormWrapper extends React.Component {

  componentDidMount() {
    const {dirtyFormFeatureIsEnabled, formName, registerFormAsOpen, location: {pathname}} = this.props;

    if (dirtyFormFeatureIsEnabled && formName) {
      registerFormAsOpen(formName, pathname);
    }
  }

  componentWillUnmount() {
    const {dirtyFormFeatureIsEnabled, formName, unregisterFormAsOpen, location: {pathname}} = this.props;

    if (dirtyFormFeatureIsEnabled && formName) {
      unregisterFormAsOpen(formName, pathname);
    }
  }

  render() {
    return this.props.children;
  }

}

DirtyFormWrapper.propTypes = {
  formName: PropTypes.string.isRequired,
  dirtyFormFeatureIsEnabled: PropTypes.bool,
  registerFormAsOpen: PropTypes.func,
  unregisterFormAsOpen: PropTypes.func,
  location: PropTypes.string,
  children: PropTypes.element
};

const mapStateToProps = (state) => ({
  dirtyFormFeatureIsEnabled: isFeatureEnabled(state)('feature_unsaved_form_changes_warning')
});

const mapDispatchToProps = {
  registerFormAsOpen,
  unregisterFormAsOpen
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DirtyFormWrapper));
