// eslint-disable-next-line
export const deliveryMethodOptions = [
  {
    i18n: 'deliveryOrders.form.courier',
    value: 'courier'
  },
  {
    i18n: 'deliveryOrders.form.inhouse',
    value: 'in_house'
  },
];
