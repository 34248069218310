import React, { useState } from 'react';
import {I18n} from 'react-redux-i18n';
import {Field, formValueSelector, getFormValues, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Button, ButtonToolbar, Col, Row} from 'react-bootstrap';
import {FaChevronDown, FaChevronRight} from 'react-icons/fa';
import {PRICING_MULTIPLIER_FORM} from '../../../constants/forms';
import validate from './validate';
import PricingMultiplierProducts from './PricingMultiplierProducts';
import PricingMultiplierPrices from './PricingMultiplierPrices';
import TextInput from '../../common/form/TextInput';
import PricingMultiplierPrice from './PricingMultiplierPrice';
import PricingMultiplierSingleUnitPrice from './PricingMultiplierSingleUnitPrice';
import InlineCheckBox from '../../common/form/InlineCheckBox';

const PricingMultiplierForm = (props) => {
  const {
    getFormValue,
    formValues,
    change,
    onSelectedItemMasterChange,
    handleSubmit,
    pricingMultiplierId,
    categories,
    vendors,
    medicatedWeights,
    selectedItemMasterIds,
    submitting,
    valid,
  } = props;

  const [isInstructionsExpanded, setIsInstructionsExpanded] = useState(false);
  const [isProductSelectorExpanded, setIsProductSelectorExpanded] = useState(!pricingMultiplierId); // Show default collapses if modify (i.e. pricingMultiplierId exists)

  const toggleInstructions = () => {
    setIsInstructionsExpanded(!isInstructionsExpanded);
  };

  const toggleProductSelector = () => {
    setIsProductSelectorExpanded(!isProductSelectorExpanded);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Row style={{marginLeft: '6px', marginRight: '6px'}}>
        <Col md={12} style={{paddingTop: '10px', border: '1px solid #ccc'}}>
          <h4 onClick={toggleInstructions}>
            {isInstructionsExpanded
              ? <FaChevronDown />
              : <FaChevronRight />
            }
            <span style={{textDecoration: 'underline'}}>{I18n.t('retail.pricingMultiplier.form.info.instructions')}</span>
          </h4>
          {isInstructionsExpanded &&
            <Row>
              <Col sm={6}>
                <p>{I18n.t('retail.pricingMultiplier.form.info.productsNote1')}</p>
                <p>{I18n.t('retail.pricingMultiplier.form.info.productsNote2')}</p>
                <p>{I18n.t('retail.pricingMultiplier.form.info.productsNote3')}</p>
                <p>{I18n.t('retail.pricingMultiplier.form.info.productsNote4')}</p>
                <p>{I18n.t('retail.pricingMultiplier.form.info.singleUnit')}</p>
              </Col>
              <Col sm={6}>
                <div className='alert alert-warning'>
                  {I18n.t('retail.pricingMultiplier.form.info.example.intro')}<br/><br/>
                  {/* The fields below are used as example only*/}
                  <PricingMultiplierSingleUnitPrice
                    fieldPrefix={'pricing_tiers.example.tiers.0'}
                    taxRate={20}
                    getFormValue={getFormValue}
                    change={change}
                    selected={true}  // selected is only used for the example
                    value={20}
                    disabled={true}
                  />
                  <h4 style={{textAlign: 'center'}}>{I18n.t('retail.pricingMultiplier.form.basePrice')}</h4>
                  <PricingMultiplierPrice
                    showHeader={false}
                    name={'pricing_tiers.example.tiers.1.price'}
                    quantity={2}
                    taxRate={20}
                    change={change}
                    disabled={true}
                    removeButton={null}
                  />
                  <PricingMultiplierPrice
                    showHeader={false}
                    name={'pricing_tiers.example.tiers.2.price'}
                    quantity={3}
                    taxRate={20}
                    change={change}
                    disabled={true}
                    value={50}
                    removeButton={null}
                  />
                  <PricingMultiplierPrice
                    showHeader={false}
                    name={'pricing_tiers.example.tiers.3.price'}
                    quantity={4}
                    taxRate={20}
                    change={change}
                    disabled={true}
                    value={60}
                    removeButton={null}
                  />
                  <p>
                    <b>{I18n.t('retail.pricingMultiplier.form.info.example.example', {count: 1})}</b>
                    <br/>
                    {I18n.t('retail.pricingMultiplier.form.info.example.quantitySold', {quantity: 5})}
                    <br/>
                    {I18n.t('retail.pricingMultiplier.form.info.example.multiplier', {multiplier: 4, total: '$72'})}
                    {I18n.t('retail.pricingMultiplier.form.info.example.each', {each: '$18'})}
                    <br/>
                    {I18n.t('retail.pricingMultiplier.form.info.example.remainder', {remainder: '5th', cost: '$24'})}
                    <br/>
                    {I18n.t('retail.pricingMultiplier.form.info.example.totalCost', {cost: '$96.00'})}
                    {I18n.t('retail.pricingMultiplier.form.info.example.each', {each: '$19.20'})}
                  </p>
                  <p>
                    <b>{I18n.t('retail.pricingMultiplier.form.info.example.example', {count: 2})}</b>
                    <br/>
                    {I18n.t('retail.pricingMultiplier.form.info.example.quantitySold', {quantity: 7})}
                    <br/>
                    {I18n.t('retail.pricingMultiplier.form.info.example.multiplier', {multiplier: 4, total: '$72'})}
                    {I18n.t('retail.pricingMultiplier.form.info.example.each', {each: '$18'})}
                    <br/>
                    {I18n.t('retail.pricingMultiplier.form.info.example.multiplier', {multiplier: 3, total: '$60'})}
                    {I18n.t('retail.pricingMultiplier.form.info.example.each', {each: '$20'})}
                    <br/>
                    {I18n.t('retail.pricingMultiplier.form.info.example.totalCost', {cost: '$132.00'})}
                    {I18n.t('retail.pricingMultiplier.form.info.example.each', {each: '$18.86'})}
                  </p>
                </div>
              </Col>
            </Row>
          }
        </Col>
      </Row>
      <Row>
        <Col md={12} style={{padding: '20px'}}>
          <Field name='name' component={TextInput} props={{
            label: I18n.t('retail.pricingMultiplier.form.name'),
            isRequired: true
          }}/>
          <Field name='mix_and_match' component={InlineCheckBox} props={{
            label: I18n.t('retail.pricingMultiplier.form.mixAndMatch'),
          }}/>
          <div style={{marginTop: '6px', paddingBottom: '10px', paddingTop: '10px', border: '1px solid #ccc'}}>
            <span onClick={toggleProductSelector} style={{marginLeft: '15px', fontSize: '18px'}}>
              {isProductSelectorExpanded
                ? <FaChevronDown/>
                : <FaChevronRight/>
              }
              <span style={{textDecoration: 'underline'}}> {I18n.t('retail.pricingMultiplier.form.products')}</span>
            </span>
            {isProductSelectorExpanded &&
              <PricingMultiplierProducts
                change={change}
                getFormValue={getFormValue}
                formValues={formValues}
                onSelectedItemMasterChange={onSelectedItemMasterChange}
                pricingMultiplierId={pricingMultiplierId}
                categories={categories}
                vendors={vendors}
                medicatedWeights={medicatedWeights}
                selectedItemMasterIds={selectedItemMasterIds}
                hideFormLabel={true}
              />
            }
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12} style={{padding: '20px'}}>
          <PricingMultiplierPrices
            change={change}
            getFormValue={getFormValue}
            formValues={formValues}
            pricingMultiplierId={pricingMultiplierId}
          />
        </Col>
      </Row>
      <Row className='submit-section'>
        <Col xs={12}>
          <div className='button-wrapper'>
            <ButtonToolbar className='float-right'>
              <Button variant='primary' type='submit' onClick={() => change('afterSubmit', 'redirect')} disabled={!valid || submitting}>
                {I18n.t('common.form.saveAndReturn')}
              </Button>
              <Button variant='primary' type='submit' onClick={() => change('afterSubmit', '')} disabled={!valid || submitting}>
                {I18n.t('common.form.save')}
              </Button>
            </ButtonToolbar>
          </div>
        </Col>
      </Row>
    </form>
  );
};

PricingMultiplierForm.propTypes = {
  getFormValue: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  onSelectedItemMasterChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pricingMultiplierId: PropTypes.string,
  categories: PropTypes.array.isRequired,
  vendors: PropTypes.array.isRequired,
  medicatedWeights: PropTypes.array.isRequired,
  selectedItemMasterIds: PropTypes.array.isRequired,
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired
};

function mapStateToProps(state, ownProps) {
  const selector = formValueSelector(PRICING_MULTIPLIER_FORM);
  const getFormValue = (...names) => selector(state, ...names);
  return {
    formValues: getFormValues(PRICING_MULTIPLIER_FORM)(state) || ownProps.initialValues,
    getFormValue
  };
}

const ReduxPricingMultiplierForm = reduxForm({
  form: PRICING_MULTIPLIER_FORM,
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(PricingMultiplierForm);

export default connect(mapStateToProps)(ReduxPricingMultiplierForm);

