import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import round from 'lodash.round';
import {Col, Row} from 'react-bootstrap';
import {Field, formValueSelector} from 'redux-form';
import * as dataNames from '../../../../constants/dataNames';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import TextInput from '../../../common/form/TextInput';
import {needTestResults} from '../../../../selectors/transfersSelectors';
import {getIntegrationState} from '../../../../selectors/integration/integrationSelectors';
import * as labResults from '../../../../selectors/lab-results';
import * as UOMS from '../../../../constants/uoms';

export class PrePackPackageSelection extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {itemMaster: null, packageLocations: []};
    this.onItemMasterChange = this.onItemMasterChange.bind(this);
  }

  componentWillMount() {
    this.initPackages(this.props);
  }

  componentWillReceiveProps(newProps) {
    this.initPackages(newProps);
  }

  initPackages(props){
    if(!this.state.itemMaster) this.setState({itemMaster: props.initialItemMaster});
    const itemMaster = this.state.itemMaster ? this.state.itemMaster : props.initialItemMaster;
    const {package_code} = this.props.getFormValues();
    if(!package_code) return false;
    this.setPackageLocations(package_code, itemMaster, props);
  }

  onItemMasterChange(itemMasterId) {
    const {fieldName, change, childItemMasters, line} = this.props;
    const editableLinePrice = this.props.editableLinePrice;

    let unitPrice = 0;

    const childItemMaster = (itemMasterId && childItemMasters && childItemMasters.length)
      ? childItemMasters.reduce((previous, current) => current.id === itemMasterId ? current : previous, {})
      : {prepack_fulfillment_units: 0};

    const itemMasterUnits = parseInt(childItemMaster.prepack_fulfillment_units);

    if(!editableLinePrice) { // not editable use the first price in the list (price / units) * units for this measure
      const firstSubItem = (line.source_sales_order_subitems.length) ? line.source_sales_order_subitems[0] : false;
      unitPrice = (firstSubItem)
        ? round((parseFloat(firstSubItem.unit_price) / parseInt(firstSubItem.prepack_fulfillment_units)) * itemMasterUnits, 2)
        : 0;
    } else { // if editable we use the line item unitPrice
      unitPrice = this.props.unitPrice * (itemMasterUnits / 100);
    }

    change(`${fieldName}.unit_price`, unitPrice.toFixed(2));
    change(`${fieldName}.package_code`, '');
    change(`${fieldName}.inventory_item_id`, '');
    if (itemMasterId && childItemMasters && childItemMasters.length) {
      const childItemMaster = childItemMasters.reduce((previous, current) => current.id === itemMasterId ? current : previous, {});
      if (childItemMaster) {
        change(`${fieldName}.prepack_fulfillment_units`, childItemMaster.prepack_fulfillment_units);
        this.setState({
          itemMaster: false,
          packageLocations: []
        }, () => {
          this.initPackages(Object.assign({}, this.props, {initialItemMaster: childItemMaster}));
        });

      }
    }
  }

  setPackageLocations(packageCode, itemMaster, props = false) {
    if(!props) props = this.props;
    const {locations} = props;
    if (itemMaster && itemMaster.items && itemMaster.items.length && locations && locations.length) {
      const packageLocations = itemMaster.items.filter(pkg => pkg.package_code === packageCode).map(pkg => {
        return {
          value: pkg.inventory_location_id,
          text: pkg.location_name
        };
      });
      this.setState({packageLocations});
    }
  }

  inventoryLinePackages() {
    const items = get(this, 'state.itemMaster.items', []);
    // remove duplicated package_codes
    const packageCodes = items.map(pkg => pkg.package_code);
    const packageCodesWithoutDuplicates = [...new Set(packageCodes)];
    return packageCodesWithoutDuplicates.map(package_code => ({package_code}));
  }

  render () {
    const {fieldName, change, isApplyStorageLocation, childItemMasters, line} = this.props;
    const inventoryLine = (this.props.fields !== undefined) ? this.props.fields.get(this.props.index) : {};
    const inventoryLinePackages = this.inventoryLinePackages();

    //Here we are going to limit our available package selections to those that have not already been chosen.
    const unselectedPackages = inventoryLinePackages.filter(
      (p) => {
        return (p.package_code === inventoryLine.package_code || !this.props.selectedPackageCodes.includes(p.package_code)) ;
      }
    );

    const isExistingInventoryLine = () => {
      return inventoryLine.inventory_transaction_id !== undefined;
    };

    const showBackOrder = () => {
      if (!isExistingInventoryLine() && unselectedPackages.length > 0) return false;
      if (!isExistingInventoryLine() && unselectedPackages.length === 0) return true;
      return false;
    };

    const isPackageCodeValid = () => {
      if (typeof this.props.packageCode !== 'string') return false;
      return (this.props.packageCode.trim() !== '');
    };

    // NOTE: This is subtly different than the one used in PackageSelection component.
    //@TODO: Simplify this code... it gets the job done but is over complicated
    const getLocationsByPackageCode = () => {
      if (this.state.itemMaster === null || !this.state.packageLocations || !this.state.packageLocations.length) return [];
      const packs = this.state.itemMaster.items.filter((item) => item.package_code === this.props.packageCode);
      const packages = this.state.itemMaster.items;
      return this.state.packageLocations
        .filter((l) => {
          if(!isPackageCodeValid()) return true; // Show all locations if not selected yet
          return packages
            .filter((p) => p.package_code === this.props.packageCode)
            .reduce((acc, p) => {
              if(acc) return acc;
              return (p.inventory_location_id === l.value);
            }, false);
        })
        .map((l) => {
          if (!isPackageCodeValid()) return l;
          const pack = packs.find((p) => p.inventory_location_id === l.value);
          return {
            value: `${pack.id}_${l.value}`,
            text: l && l.text,
          };
        });
    };

    const isComplete = this.props.isComplete;
    const locations = getLocationsByPackageCode();

    return (
      <Col xs={12}>
        {(!showBackOrder())
            ? null
            : (
              <Row className='prepack-package-selection'>
                <Col xs={12} className='text-danger' style={{margin: '0px 0px 12px 0px'}}>
                 {I18n.t('salesOrders.form.inventoryUnavailable')}
                </Col>
              </Row>
            )
          }
        <Row className='prepack-package-selection'>
          <Col xs={6} md={3}>
           <Field
              name={`${fieldName}.item_master_id`}
              component={ReactSelectInput}
              disabled={isApplyStorageLocation || isComplete}
              props={{
                label: `${I18n.t(`uoms.${get(line, 'itemMaster.default_uom', UOMS.GR)}.word`)}s`,
                options: childItemMasters,
                textKey: 'prepack_name',
                valueKey: 'id',
                onChange: value => {
                  change(`${fieldName}.item_master_id`, value);
                  this.onItemMasterChange(value);
                }
              }}
            />
          </Col>
          <Col xs={6} md={9}>
            <Row>
              <Col xs={6} md={4}>
                <Field
                  name={`${fieldName}.package_code`}
                  component={ReactSelectInput}
                  disabled={isApplyStorageLocation || isComplete}
                  props={{
                    label: I18n.t('cultivation.transfers.form.packageId'),
                    options: unselectedPackages,
                    textKey: 'package_code',
                    valueKey: 'package_code',
                  }}
                />
              </Col>
              <Col xs={6} md={4}>
                <Field
                  name={`${fieldName}.hybrid_location_id`}
                  component={ReactSelectInput}
                  disabled={isApplyStorageLocation || isComplete || !isPackageCodeValid()}
                  props={{
                    label: I18n.t('cultivation.transfers.form.storageLocation'),
                    options: locations,
                    placeholder: (locations.length > 1 && isPackageCodeValid()) ? 'Has Multiple Locations' : '',
                    textKey: 'text',
                    valueKey: 'value',
                  }}
                />
              </Col>
              <Col xs={6} md={4}>
                <Field
                  name={`${fieldName}.qty_available`}
                  component={TextInput}
                  props={{
                    label: I18n.t('cultivation.transfers.form.qtyAvailable'),
                    readOnly: true
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    );
  }
}

PrePackPackageSelection.propTypes = {
  unitPrice: PropTypes.number,
  editableLinePrice: PropTypes.bool,
  fields: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  getFormValues: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  isApplyStorageLocation: PropTypes.bool,
  locations: PropTypes.array,
  selectedPackageCodes: PropTypes.array,
  childItemMasters: PropTypes.array.isRequired,
  isEditMode: PropTypes.bool,
  initialItemMaster: PropTypes.object,
  line: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  isComplete: PropTypes.bool.isRequired,
  transferId: PropTypes.string,
  needTestResults: PropTypes.bool,
};

PrePackPackageSelection.defaultProps = {
  isEditMode: false
};


function mapStateToProps(state, ownProps){
  const selector = formValueSelector(ownProps.formName);
  const salesOrders = state[dataNames.salesOrders];
  return {
    packageCode: selector(state, `${ownProps.fieldName}.package_code`),
    meta: selector(state, `${ownProps.fieldName.split('.').shift()}.meta`), // get line meta
    inventoryItemsTestResults: labResults.getList(state),
    needTestResults: needTestResults(state),
    salesOrder: salesOrders.length > 0 ? salesOrders[0] : {},
    integrationState: getIntegrationState(state),
  };
}

export default connect(mapStateToProps)(PrePackPackageSelection);
