import React from 'react';
import PropTypes from 'prop-types';
import { reset } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { goBack, push } from 'react-router-redux';
import * as itemNames from '../../../../constants/itemNames';
import FormWrapper from '../../../common/form/FormWrapper';
import CreatePricingClassFormWrapper from './CreatePricingClassFormWrapper';
import getPayloadForPricingClasses from '../common/getPayloadForPricingClasses';
import { getCreatePricingClassInitialValues } from '../../../../selectors/pricingClassSelectors';

import * as dataNames from '../../../../constants/dataNames';
import * as apiActions from '../../../../actions/apiActions';

export class CreatePricingClassPage extends React.PureComponent {
  constructor (props) {
    super(props);

    this.redirect = this.redirect.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.props.actions.getUnpaginatedData('/api/consumer_groups', dataNames.customerGroups, { failed: 'retail.customerGroupListing.loadGroupsFailed' });
  }

  redirect () {
    this.props.actions.push('/retail/pricing-classes/active');
  }

  onSubmit (formValues) {
    const payload = getPayloadForPricingClasses(formValues);

    const responses = {
      success: 'retail.pricingClass.create.success',
      failed: 'retail.pricingClass.create.failed',
    };

    this.props.actions.postItem('/api/pricing_classes', payload, itemNames.pricingClass, responses, {}, () => {
      if (formValues.afterSubmit === 'redirect' ) {
        this.redirect();
      } else {
        this.props.actions.reset('createPricingClassForm');
      }
    });
  }

  render () {
    const { initialValues } = this.props;

    return (
      <FormWrapper className='pricing-class-form' title='retail.pricingClass.create.title' goBack={this.redirect}>
        <CreatePricingClassFormWrapper
          onSubmit={this.onSubmit}
          initialValues={initialValues}
        />
      </FormWrapper>
    );
  }
}

CreatePricingClassPage.propTypes = {
  actions: PropTypes.shape({
    reset: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }),
  initialValues: PropTypes.object,
};

function mapStateToProps (state) {
  const initialValues = getCreatePricingClassInitialValues(state);
  
  return {
    initialValues
  };
}

function mapDispatchToProps (dispatch) {
  const actions = Object.assign({}, apiActions, {goBack, reset, push});

  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePricingClassPage);