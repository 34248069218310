import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {bindActionCreators} from 'redux';
import {push} from 'react-router-redux';
import InternationalDateStatic from '../../common/form/InternationalDateStatic';
//import Barcode from 'react-barcode';
import {getUnpaginatedData, putData, getFile} from '../../../actions/apiActions';
import {clearSelectedData} from '../../../actions/selectedDataActions';
import {handleSingleSelectedRow, handleSelectedRow} from '../../../actions/helpers/selectedDataHelper';
import {getToday, getYesterday, convertFormInputDateToDbDate} from '../../../util/dateHelpers';
import * as dataNames  from '../../../constants/dataNames';
import * as permissions  from '../../../constants/permissions';
import {getCouponListingData} from '../../../selectors/couponsSelectors';
import TablePageWrapper from '../../common/grid/TablePageWrapper';
import SingleActionColumn from '../../common/grid/columns/SingleActionColumn';
import PageTitle from '../../common/PageTitle';
import {unsetData} from '../../../actions/dataActions';
import {addMessage} from '../../../actions/systemActions';
import * as messageTypes from '../../../constants/messageTypes';

const allowedTabs = ['active', 'inactive'];

export class CouponListingPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.switchTab = this.switchTab.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.modifyCoupon = this.modifyCoupon.bind(this);
    this.printCoupon = this.printCoupon.bind(this);
    this.activateCoupon = this.activateCoupon.bind(this);
    this.inactivateCoupon = this.inactivateCoupon.bind(this);
    this.downloadCouponsPdf = this.downloadCouponsPdf.bind(this);

    const commonColumns = [
      {
        name: I18n.t('retail.coupons.table.name'),
        dataId: 'name',
        hidden: false,
        width: '120px',
      },
      // { // Commented out 01/15/2018 per Product at same time Rewards went up without the column
      //   name: I18n.t('retail.coupons.table.code'),
      //   dataId: 'coupon_sku',
      //   hidden: false,
      //   width: '120px',
      //   csvFormatter: (cell) => { return cell; },
      //   formatter: (cell) => cell ? <Barcode value={cell}/> : '',
      // },
      {
        name: I18n.t('retail.coupons.table.amount'),
        dataId: 'amount',
        hidden: false,
        width: '120px',
      },
      {
        name: I18n.t('retail.coupons.table.expirationDate'),
        dataId: 'date_available_to',
        formatter: (cell, row) => cell ?  <InternationalDateStatic>{cell}</InternationalDateStatic> : '-',
        hidden: false,
        width: '120px',
      },
      {
        name: I18n.t('retail.coupons.table.redemption'),
        dataId: 'redemption',
        hidden: false,
        dataSort: false,
        width: '120px',
      },
    ];
    const activeColumns = [
      {
        dataSort: false,
        permissions: [permissions.manage_coupons, permissions.manage_facility],
        csvFormatter: (cell) => I18n.t('common.edit'),
        formatter: SingleActionColumn({
          permissions: [permissions.manage_coupons, permissions.manage_facility],
          action: this.modifyCoupon,
          label: 'common.edit'
        }),
        width: '35px',
      },
      {
        dataSort: false,
        permissions: [permissions.manage_facility, permissions.manage_coupons],
        csvFormatter: (cell) => { return I18n.t('retail.coupons.actions.inactivate'); },
        formatter: SingleActionColumn({
          permissions: [permissions.manage_facility, permissions.manage_coupons],
          action: this.inactivateCoupon,
          label: 'retail.coupons.actions.inactivate'
        }),
        width: '35px',
      }
    ];
    const inactiveColumns = [
      {
        dataSort: false,
        permissions: [permissions.manage_facility, permissions.manage_coupons],
        csvFormatter: (cell) => { return I18n.t('retail.coupons.actions.activate'); },
        formatter: SingleActionColumn({
          permissions: [permissions.manage_facility, permissions.manage_coupons],
          action: this.activateCoupon,
          label: 'retail.coupons.actions.activate'
        }),
        width: '120px',
      }
    ];
    const tabs = [
      {id: 'activeCouponsTab', eventKey: 'active', title: 'retail.coupons.nav.active', actions: [
        {id: 'createCoupon', path:'/retail/coupons/create', text: 'retail.coupons.actions.create' , glyph: 'plus-sign', requireSelect: false},
        {id: 'downloadCouponsPdf', func: this.downloadCouponsPdf, text: 'retail.coupons.actions.generatePdf' , glyph: 'floppy-disk', requireSelect: true},
      ]},
      {id: 'inactiveCouponsTab', eventKey: 'inactive', title: 'retail.coupons.nav.inactive', actions: []},
    ];
    this.state = {commonColumns, activeColumns, inactiveColumns, tabs};
  }

  componentWillMount() {
    this.props.actions.getUnpaginatedData('/api/coupons', dataNames.coupons, {failed: 'retail.coupons.get.failed'});
    this.switchTab(this.props.params.active);
  }

  componentWillUnmount() {
    this.props.actions.unsetData(dataNames.coupons);
  }

  switchTab(tab) {
    this.props.actions.clearSelectedData(dataNames.coupons);
    this.props.actions.push(`/retail/coupons${allowedTabs.indexOf(tab) > -1 ? `/${tab}` : ''}`);
    const isActiveTab = tab !== 'inactive';
    this.setState((prevState) => ({
      columns: prevState.commonColumns.concat(prevState[isActiveTab ? 'activeColumns' : 'inactiveColumns'])
    }));
  }

  handleSelect(row) {
    // This handles "add"/"remove" strings passed in, for bulk select/deselection
    if (typeof row === 'string') {
      this.props.actions.handleSingleSelectedRow(this.props.coupons, dataNames.coupons, row === 'add');
      return;
    }

    const {selectedCouponIds} = this.props;
    this.props.actions.handleSelectedRow([row], dataNames.coupons, !selectedCouponIds.includes(row.id));
  }

  modifyCoupon(event, coupon) {
    event.preventDefault();
    event.stopPropagation();
    this.props.actions.push(`/retail/coupons/modify/${coupon.id}`);
  }

  printCoupon() {}

  downloadCouponsPdf() {
    const {selectedCouponIds, actions} = this.props;

    if (selectedCouponIds.length > 50) {
      actions.addMessage(messageTypes.warning, 'retail.coupons.warnNumberSelectedCoupons');
      return;
    }

    const payload = {ids: selectedCouponIds};
    actions.getFile('/api/coupons/print_selected', 'coupons.pdf', {failed: 'notifications.download.failed'}, payload);
  }

  activateCoupon(event, coupon) {
    this.modifyCoupon(event, coupon);
  }

  inactivateCoupon(event, row) {
    event.preventDefault();
    event.stopPropagation();
    const payload = {
      date_available_to: convertFormInputDateToDbDate(getYesterday(), this.timezone)
    };
    this.props.actions.putData(
      `/api/coupons/${row.id}`,
      payload,
      dataNames.coupons,
      {success: 'retail.coupons.inactivate.success', failed: 'retail.coupons.inactivate.failed'}
    );
  }

  render() {
    const {tabs, columns} = this.state;
    const {coupons, selectedCouponIds} = this.props;
    const activeTab = this.props.params.active === 'inactive' ? this.props.params.active : 'active';
    return (
      <div className='coupon-listing'>
        <PageTitle primaryText={I18n.t('retail.title')} secondaryText={I18n.t('retail.coupons.title')}/>
        <div><b>{I18n.t('retail.coupons.warnNumberSelectedCoupons')}</b></div>
        <TablePageWrapper
          ref={this.ref}
          settingKey='retail-coupons'
          tabs={tabs}
          activeTab={activeTab}
          switchTab={this.switchTab}
          columns={columns}
          data={coupons}
          selectedRows={selectedCouponIds}
          handleSelect={this.handleSelect}
          hideExport={true}
          hideScanSearch={true}
          external={false}
          bstProps={{
            selectRow: {
              selected: {selectedCouponIds},
              onSelect: this.handleSelect
            },
          }}
        />
      </div>
    );
  }
}

CouponListingPage.propTypes = {
  actions: PropTypes.shape({
    getUnpaginatedData: PropTypes.func.isRequired,
    putData: PropTypes.func.isRequired,
    handleComplexSelectRow: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    clearSelectedData: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired
  }).isRequired,
  params: PropTypes.shape({
    active: PropTypes.oneOf(allowedTabs)
  }).isRequired,
  coupons: PropTypes.array.isRequired,
  selectedCouponIds: PropTypes.array.isRequired,
};

function mapStateToProps(state, ownProps) {
  const coupons = getCouponListingData(state, {...ownProps, today: getToday(state.timezone)});
  const {selectedCoupons} = state;
  return {
    coupons,
    selectedCouponIds: selectedCoupons.map(c => c.id)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {getUnpaginatedData, putData, getFile, handleSingleSelectedRow, handleSelectedRow, push, clearSelectedData, unsetData, addMessage};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CouponListingPage);
