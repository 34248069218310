import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reset, formValueSelector } from 'redux-form';
import { goBack, push } from 'react-router-redux';
import { I18n } from 'react-redux-i18n';
import get from 'lodash.get';
import * as apiActions from '../../../actions/apiActions';
import * as apiItemActions from '../../../actions/itemActions';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import { getIngredientForModify, getIngredientPayload } from '../../../selectors/ingredientsSelectors';
import { getSortedIngredientsCategoriesWithTypes } from '../../../selectors/ingredientCategoriesSelectors';

import FormWrapper from '../../common/form/FormWrapper';
import { getFlattenedLocations } from '../../../selectors/locationsSelectors';
import IngredientsFormWrapper from '../common/IngredientsFormWrapper';
import InProgressOverlay from '../../common/InProgressOverlay';
import getUomOptionsForSelect from '../../../selectors/forms/ingredientFormSelectors'; //eslint-disable-line

const formName = 'modifyIngredient';
const selector = formValueSelector(formName);

export class ModifyIngredientsFormPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      processing: true,
      processingMessage: ''
    };
    this.endProcessing = this.endProcessing.bind(this);
    this.startProcessing = this.startProcessing.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount() {
    const id = this.props.params.id || '';
    this.props.actions.unsetItem(itemNames.ingredient);
    const promises = [
      this.props.actions.getItem(`/api/ingredient_items/${id}`, itemNames.ingredient, null, { detailed: 1 }),
      this.props.actions.getUnpaginatedData('/api/ingredient_categories', dataNames.ingredientCategories),
      this.props.actions.getUnpaginatedData('/api/partners', dataNames.partners, undefined, { purchase_from: 1 }),
      this.props.actions.getUnpaginatedData('/api/location_hierarchy', dataNames.locations, {
        failed: 'locations.getLocations.failed'
      }),
      this.props.actions.getUnpaginatedData('/api/uoms', dataNames.uoms)
    ];
    Promise.all(promises)
      .then(() => {
        this.endProcessing();
      })
      .catch(() => {
        this.endProcessing();
      });
  }

  startProcessing(processingMessage = '') {
    this.setState({
      processing: true,
      processingMessage
    });
  }

  endProcessing() {
    this.setState({
      processing: false,
      processingMessage: ''
    });
  }

  onSubmit(formData) {
    this.startProcessing();
    const {
      ingredient: { id }
    } = this.props;
    return this.props.actions
      .putData(
        `/api/ingredient_items/${id}`,
        getIngredientPayload(formData, this.props[dataNames.uoms]),
        dataNames.ingredients,
        { success: 'ingredients.modify.success', failed: 'ingredients.modify.failed' },
        null,
        () => {
          const ingredientCategoryId = formData.ingredient_category_id;

          if (ingredientCategoryId > 0) {
            this.props.actions.push('/ingredients/active/' + ingredientCategoryId);
          } else {
            this.props.actions.goBack();
          }

          this.endProcessing();
        }
      )
      .catch(() => {
        this.endProcessing();
      });
  }

  render() {
    const { ingredient, partners, ingredientCategories, locations, uoms } = this.props;
    return (
      <FormWrapper title={'ingredients.editIngredient'} goBack={this.props.actions.goBack}>
        <InProgressOverlay
          isActive={this.state.processing}
          message={this.state.processingMessage}
          showOk={false}
          showLoader={true}
          translate={true}
        />
        <IngredientsFormWrapper
          form={formName}
          initialValues={ingredient}
          ingredientCategories={ingredientCategories}
          suppliers={partners}
          locations={locations}
          uoms={uoms}
          onSubmit={this.onSubmit}
          submitButtonText={I18n.t('common.form.saveIngredient')}
        />
      </FormWrapper>
    );
  }
}

ModifyIngredientsFormPage.propTypes = {
  actions: PropTypes.shape({
    getUnpaginatedData: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    putData: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired
  }).isRequired,
  params: PropTypes.shape({
    id: PropTypes.string
  }),
  ingredient: PropTypes.object.isRequired,
  partners: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  ingredientCategories: PropTypes.array.isRequired,
  uoms: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  const getFormValue = (...names) => selector(state, ...names);
  const { partners } = state;
  const ingredient = getIngredientForModify(state);
  return {
    ingredient,
    getFormValue,
    partners,
    ingredientCategories: getSortedIngredientsCategoriesWithTypes(state),
    locations: getFlattenedLocations(state),
    uoms: getUomOptionsForSelect(state, { types: [get(ingredient, 'uom_type', 'weight')]})
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, apiItemActions, { goBack, reset, push });
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModifyIngredientsFormPage);
