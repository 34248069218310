import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Table, Card} from 'react-bootstrap';
import ContentConcealer from '../../../common/concealers/ContentConcealer';

const PlantWasteReportedDetails = ({model}) => {
  const {total_waste_recorded, harvest_batch, total_waste_weight} = model;
  const total_before_harvested = total_waste_weight - parseFloat(total_waste_recorded || 0);
  return (
    <Card>
      <Table striped className='plant-details'>
        <thead>
        <tr>
          <th>{I18n.t('plantHistory.field')}</th>
          <th>{I18n.t('plantHistory.value')}</th>
        </tr>
        </thead>
        <tbody>
        <ContentConcealer show={Boolean(harvest_batch && total_waste_recorded && harvest_batch.uom)}>
          <tr>
            <td>{I18n.t('plantHistory.plant.amountWaste')}</td>
            <td>{I18n.t('plantHistory.plant.amountWasteRecord', {from: total_before_harvested, to: total_waste_weight, uom: harvest_batch && harvest_batch.uom})}</td>
          </tr>
        </ContentConcealer>
        </tbody>
      </Table>
    </Card>
  );
};

PlantWasteReportedDetails.propTypes = {
  model: PropTypes.object.isRequired,
};

export default PlantWasteReportedDetails;
