import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import TextInput from '../TextInput';
import { getPhoneShouldIncludeCountryCode } from '../../../../selectors/InternationalOperationsSelectors';
import {formatInternationalPhone, formatPhoneAU, formatPhoneUS, normalizePhone} from '../../../../util/formatHelper';
import {getCurrentFacilityCountry} from '../../../../selectors/facilitiesSelectors';

class InternationalReduxPhoneInput extends React.PureComponent {
  constructor(props, context){
    super(props, context);
    this.validate = this.validate.bind(this);
  }

  validate(value) {
    const {props, includeCountryCode, format} = this.props;

    if (props.isRequired && !value) {
      return I18n.t('common.form.isRequired', {fieldName: props.label});
    }

    if (value && includeCountryCode) {
      value = format(value);
      const phoneRegex = /^[+][0-9]{1,3}[-]{1}[0-9]{2,3}[-]{1}[0-9]{2,3}[-]{1}[0-9]{4}$/;

      if (!phoneRegex.test(value)) {
        return I18n.t('common.form.invalidPhone', {fieldName: props.label});
      }
    }

    return false;
  }

  render(){
    const {name, format, placeholder, normalize, ...props} = this.props;

    return (
      <Field
        name={name}
        component={TextInput}
        normalize={normalize}
        format={format}
        validate={this.validate}
        props={{
          placeholder,
          ...props.props,
        }}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  const countryCode = ownProps.countryCode ? ownProps.countryCode : getCurrentFacilityCountry(state);
  const format = getPhoneShouldIncludeCountryCode(state) ? formatInternationalPhone : (['AU', 'NZ'].indexOf(countryCode) > -1 ? formatPhoneAU : formatPhoneUS);

  let placeholder = ['AU', 'NZ'].indexOf(countryCode) > -1 ? '(11) 2222-3333' : '(111) 222-3333';
  let normalize = normalizePhone;
  if (getPhoneShouldIncludeCountryCode(state)) {
    placeholder = '+1-222-333-4444';
    normalize = null;
  }

  return {
    format,
    placeholder,
    normalize,
    includeCountryCode: getPhoneShouldIncludeCountryCode(state)
  };
}

InternationalReduxPhoneInput.defaultProps = {
  validate: [],
};

InternationalReduxPhoneInput.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  countryCode: PropTypes.string,
  format: PropTypes.func.isRequired,
  normalize: PropTypes.func,
  validate: PropTypes.array,
  props: PropTypes.object,
};

export default connect(mapStateToProps)(InternationalReduxPhoneInput);
