import React from 'react';
//import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Col, Row} from 'react-bootstrap';
//import { bindActionCreators } from 'redux';
import PartnerDataSummary from '../components/PartnerDataSummary/PartnerDataSummary'; //eslint-disable-line
import PartnerTransactions from './PartnerTransactions'; //eslint-disable-line

export class PartnerDetails extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      organizationExists: false,
      facilitiesExist: false,
      uploading: false
    };
  }

  componentWillMount() {}

  render() {
    const props = this.props;
    const showElements = {viewInventory: this.props.activeTab === 'inNetwork', mapProducts: this.props.activeTab === 'inNetwork', edit: true};
    return (
      <div style={{border: '1px solid #ccc', padding: '10px'}}>
        <Row>
          <Col xs={12} sm={5}>
            <PartnerDataSummary {...props} showElements={showElements} />
          </Col>
          <Col xs={12} sm={7} style={{fontSize: 'smaller'}}>
            <PartnerTransactions style={{float: 'left'}} {...props} />
          </Col>
        </Row>
      </div>
    );
  }
}

PartnerDetails.propTypes = {};


function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps) (PartnerDetails);
