import get from 'lodash.get';

export const getMatchingCategory = (key, hash) => {
  return get(hash, key);
};

export const getDisplayName = (key, hash, name) => {
  const matchingCategory = getMatchingCategory(key, hash);
  const displayName = get(matchingCategory, 'display_name', name);
  return displayName === null ? name : displayName;
};

export const canEditDisplayName = (key, hash) => {
  const matchingCategory = getMatchingCategory(key, hash);
  // Reverse logic: if no match or no display name then return true.
  return !get(matchingCategory, 'display_name', false);
};

export const canAddSubCategories = (key, hash) => {
  const matchingCategory = getMatchingCategory(key, hash);
  return get(matchingCategory, 'subcategories_are_extendable', true);
};

export const categorizationIsHidden = (key, hash) => {
  const matchingCategory = getMatchingCategory(key, hash);
  return get(matchingCategory, 'is_hidden', false);
};


