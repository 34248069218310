import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack, push} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import {FaCheck, FaCloudUploadAlt} from 'react-icons/fa';
import {Tab, Tabs} from 'react-bootstrap';
import labelsConfig from '../common/labels.configuration';
import {getData, getItem, postItem, putItem} from '../../../actions/apiActions';
import {setData, unsetData} from '../../../actions/dataActions';
import {setItem, unsetItem} from '../../../actions/itemActions';
import {customLabelsForTag, finishedProducts, labelsConfiguration} from '../../../constants/dataNames';
import {labelTag, sourceLabel} from '../../../constants/itemNames';
import FormWrapper from '../../common/form/FormWrapper';
import StatusLegend from '../common/StatusLegend';
import Editor from './components/Editor';
import InProgressOverlay from '../../common/InProgressOverlay';
import SourcesTable from './components/SourcesTable';
import PlaceholdersList from './components/PlaceholdersList';
import PrinterModal from '../../printer/PrinterModal'; //eslint-disable-line import/no-named-as-default
import DefaultSamplesByDpi from './components/DefaultSamplesByDpi';

class LabelsCustomEditor extends React.PureComponent{

  constructor(props, context) {

    super(props, context);
    this.goBack = this.goBack.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
    this.onTestTabChange = this.onTestTabChange.bind(this);

    this.getLabelForTab = this.getLabelForTab.bind(this);
    this.getLabelForDefaultTab = this.getLabelForDefaultTab.bind(this);

    this.getStatusForTab = this.getStatusForTab.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.isSaved = this.isSaved.bind(this);
    this.onSave = this.onSave.bind(this);
    this.hideLoader = this.hideLoader.bind(this);

    this.preventDefault = this.preventDefault.bind(this);
    this.onSelectActiveSource = this.onSelectActiveSource.bind(this);
    this.sourceIsSelected = this.sourceIsSelected.bind(this);

    this.hidePrinterModal = this.hidePrinterModal.bind(this);
    this.getLabelUrl = this.getLabelUrl.bind(this);
    this.loadPrinterModal = this.loadPrinterModal.bind(this);

    this.onDefaultsTabChange = this.onDefaultsTabChange.bind(this);

    this.state = {
      showLoader: false,
      message: false,

      activeTab: 'dpi_203',
      labels: false,
      activeTestTab: 'sources',
      activeDefaultTab: 'dpi_203',
      activeSource: false,

      // Printer Modal
      showPrinter: false,
      url: '',
      payload: {},
      params: {},
      httpAction: 'get'
    };

  }

  componentWillMount(){
    this.setState({showLoader: true, message: 'Loading Saved Templates'});
    this.props.actions.unsetData(customLabelsForTag); // removes custom labels previously selected from redux
    this.props.actions.unsetItem(sourceLabel); // removes previously selected source from redux
    this.props.actions.setData(labelsConfig, labelsConfiguration);
    this.props.actions.getData(`/api/labels/custom_labels/tag/${this.props[labelTag].tag}`, customLabelsForTag)
      .then(this.hideLoader)
      .catch(this.hideLoader);
    // Until we add other labels or friendlify these serve for both patient and package labels.
    this.props.actions.getData('/api/lots/finished_products_for_cult', finishedProducts, {}, {});
  }

  componentWillReceiveProps(newProps){
    if(newProps[customLabelsForTag] !== undefined && !this.state.labels && newProps[customLabelsForTag].length){
      const labels = newProps[customLabelsForTag].map((label) => {
        label.resolution = label.label_dpi ? label.label_dpi.dpi : 203;
        label.code = label.code_detail ? label.code_detail.code : '';
        label.saved = true;
        return label;
      });
      this.setState({labels: labels});
    }
  }

  goBack(){
    this.props.actions.goBack();
  }

  hideLoader(){
    this.setState({showLoader: false});
  }

  preventDefault(event){
    if(event){
      event.stopPropagation();
      event.preventDefault();
    }
  }

  // Helpers

  getLabelForTab(resolution){

    const defaultLabel = {
      is_active: 0,
      resolution: resolution,
      code: '',
      saved: false
    };

    if(!this.state.labels) return defaultLabel;

    const label = this.state.labels.find( (label) => {
      return (label.resolution === resolution && label.id !== undefined);
    });

    if(label) return label;

    return defaultLabel;

  }

  getLabelForDefaultTab(resolution){

    if(!this.state.labels) return false;

    return this.state.labels.find((label) => {
      return (label.resolution === resolution && label.id === undefined);
    });

  }

  getStatusForTab(resolution){

    const label = this.getLabelForTab(resolution);

    if(label.code === '') return -1;

    return label.is_active;

  }

  isSaved(resolution){

    const label = this.getLabelForTab(resolution);

    if(label.code === '' && label.id === undefined) return '';

    return (label.saved) ? <FaCheck className={'success'}/> : <FaCloudUploadAlt className={'danger'}/>;

  }

  sourceIsSelected(){

    if(this.props[sourceLabel] !== undefined){
      return (Object.keys(this.props[sourceLabel]).length > 0);
    }
    return false;

  }

  // Testing/Printing

  hidePrinterModal(){
    this.setState({showPrinter: false});
  }

  getLabelUrl(id){
    return `/api/labels/generate/${this.props[labelTag].tag}/for/${id}`;
  }

  loadPrinterModal(label, forceDefault){

    const params = {
      resolution: label.resolution,
      ignoreIsActive: 1
    };

    if(forceDefault) params.forceDefault = 1;

    params.mockOrder = 1;

    const url = this.getLabelUrl(this.state.activeSource.item_id);

    const newState = {
      showPrinter: true,
      url: url,
      params: params
    };

    this.setState(newState);

  }

  // Events

  onFieldChange(label, field, value){

    const labels = this.state.labels.filter( (label) => label);

    const changeLabel = labels.find((l) => {
      return label.resolution === l.resolution && l.id !== undefined;
    });

    if(changeLabel) {
      changeLabel[field] = value;
      if(field !== 'saved') {
        changeLabel.saved = false;
      }
    } else {
      label[field] = value;
      if(label.id === undefined) label.id = 0;
      labels.push(label);
    }

    this.setState({labels: labels});

  }

  onTabChange(event, tab){
    this.preventDefault(event);
    this.setState({activeTab: tab});
  }

  onTestTabChange(event, tab){
    this.preventDefault(event);
    this.setState({activeTestTab: tab});
  }

  onDefaultsTabChange(event, tab){
    this.preventDefault(event);
    this.setState({activeDefaultTab: tab});
  }

  onSelectActiveSource(event, item){
    this.preventDefault(event);
    this.setState({activeSource: item, activeTestTab: 'placeholders'});
    //const url = `/api/labels/generate/${this.props[labelTag].tag}/for/${item.item_id}`;
    // This id will change depending on the label
    const url = this.getLabelUrl(item.item_id) + '?mockOrder=1';
    this.props.actions.getItem(url, sourceLabel, {}, {});
  }

  onSave(label, testAfterSave){

    const payload = Object.assign({}, label, {tag: this.props[labelTag].tag});

    if(label.id === undefined || label.id === 0){
      this.setState({showLoader: true, message: 'Saving Label Template...'});
      this.props.actions.postItem('/api/labels/custom_labels', payload, null, {}, {}, (data) => {
        this.onFieldChange(label, 'saved', true); // Use existing form toggle to handle this
        this.onFieldChange(label, 'id', data.id); // Use existing form toggle to handle this
        if(testAfterSave) this.loadPrinterModal(label, false); // do not force default
      }).then(this.hideLoader).catch(this.hideLoader);
      return true;
    }
    this.setState({showLoader: true, message: 'Updating Label Template...'});
    this.props.actions.putItem(`/api/labels/custom_labels/${label.id}`, payload, null, {}, {}, () => {
      this.onFieldChange(label, 'saved', true); // Use existing form toggle to handle this
      if(testAfterSave) this.loadPrinterModal(label, false); // do not force default
    }).then(this.hideLoader).catch(this.hideLoader);

  }

  render(){

    const title = I18n.t('labels.customLabelsHeader') + this.props[labelTag].name + ' ' + this.props[labelTag].width + ' x ' + this.props[labelTag].height;

    return (
      <FormWrapper title={title} goBack={this.goBack}>
        <InProgressOverlay isActive={this.state.showLoader} message={this.state.message} translate={false}/>
        <PrinterModal
          hidePrinter={this.hidePrinterModal}
          showPrinter={this.state.showPrinter}
          labelUrl={this.state.url}
          params={this.state.params}
          httpAction={this.state.httpAction} />
      <div>
        <div className='row' style={{margin: '10px 0px 20px 0px'}}>
          <div className='col-md-6'>
            <StatusLegend />
          </div>
          <div className='col-md-6'>
            <div>On each tab for a custom label resolution, the following icons indicate:</div>
            <div><FaCheck className='success'/> Custom Label Is Saved.</div>
            <div><FaCloudUploadAlt className='danger'/> Custom Label Has Been Changed And Needs Saving.</div>
          </div>
        </div>

        <div>
          <div className='row' style={{padding: '0px 0 20px 0px'}}>

            <div className='col-md-6'>

              <Tabs defaultActiveKey='dpi_203' id='custom-label-editor-tabs'>

                <Tab eventKey={'dpi_203'} title={I18n.t('labels.dpi_203')}>
                  <Editor
                    resolution={203}
                    sourceIsSelected={this.sourceIsSelected}
                    onSave={this.onSave}
                    getLabel={this.getLabelForTab}
                    onFieldChange={this.onFieldChange} />
                </Tab>

                <Tab eventKey={'dpi_300'} title={I18n.t('labels.dpi_300')}>
                  <Editor
                    resolution={300}
                    sourceIsSelected={this.sourceIsSelected}
                    onSave={this.onSave}
                    getLabel={this.getLabelForTab}
                    onFieldChange={this.onFieldChange} />
                </Tab>

                <Tab eventKey={'dpi_600'} title={I18n.t('labels.dpi_600')}>
                  <Editor
                    resolution={600}
                    sourceIsSelected={this.sourceIsSelected}
                    onSave={this.onSave}
                    getLabel={this.getLabelForTab}
                    onFieldChange={this.onFieldChange} />
                </Tab>

                <Tab eventKey={'default'} title={I18n.t('labels.defaultLabel')}>
                  <DefaultSamplesByDpi
                    loadPrinterModal={this.loadPrinterModal}
                    sourceIsSelected={this.sourceIsSelected}
                    onSave={this.onSave}
                    getLabel={this.getLabelForDefaultTab}
                    onFieldChange={this.onFieldChange}
                    onDefaultsTabChange={this.onDefaultsTabChange}
                    activeDefaultTab={this.state.activeDefaultTab} />
                </Tab>

              </Tabs>

            </div>
            <div className='col-md-6'>

              <Tabs defaultActiveKey='sources' id='label-editor-sourcestabs'>
                <Tab eventKey={'sources'} title={'Sources'}>
                  <SourcesTable
                    items={this.props[finishedProducts]}
                    onSelectActiveSource={this.onSelectActiveSource} />
                </Tab>
                <Tab eventKey={'placeholders'} title={'Place Holders'}>
                  <PlaceholdersList
                    activeSource={this.state.activeSource}
                    prefix={false}
                    label={this.props[sourceLabel]}/>
                </Tab>
                <Tab eventKey={'help'} title={'Place Holders Help'}>
                  <div style={{marginTop: '16px'}}>
                    <p>
                      Building your own custom labels does not need to be difficult.  First, start with a ZPL
                      editor instead of trying to create it by hand.  It will save you countless hours of effort.
                    </p>
                    <p>
                      Understanding what you can and cannot do in ZPL will go a long way toward heading off mistakes.
                    </p>
                    <p>
                      ZPL is a mark up language specifically for printers.  It is an old language and not very friendly
                      to work with.  What you need to know is that it doesn't work like HTML or word processing.
                    </p>
                    <p>
                      Text does not wrap.  It just runs off the label never to be seen again.  So as you lay out
                      your label, remember that width does matter and you are far better off to have an imperfect
                      looking label that does it's job than one missing half of a code.
                    </p>
                    <p>
                      There are some exceptions to this.  We use custom wrapping algorithms for things like ingredients,
                      health claim statements, mandatory statements, and pesticides.  For best results, place
                      these after any barcode you might specify.  We recommend this <strong>strongly</strong> because
                      if the statements are overly long they will push the bar code off the label.
                    </p>
                    <p>
                      For barcodes never, ever try to code your own.  We test on multiple pieces of equipment
                      and in various states of damage to get the best results possible.  Use the placeholder BARCODE
                      in your label to indicate where it should be inserted.  But do NOT try to do your own.  It might
                      work in some cases... but almost certainly not in all.
                    </p>
                    <hr />
                    <p>
                      So now that you know what to do and not to do... how do you build the label.  There are two
                      kinds of information on your label.
                    </p>
                    <p>
                      Static Data are the field identifiers.  For example, Product Name.  It will not change.  You
                      simply put that information on to the label where you want.
                    </p>
                    <p>
                      Dynamic Data are the items that will change from label to label.  In the above case, product
                      name.  So how do you do that.  Use the fields from the Place Holders tab.  They are italicized
                      and on the left side of the list.  The right side is the matching sample data.
                    </p>
                    <p>
                      For product name, for example, you would use "item_master.name" without the quotes and with the period.
                      <em>The period is critical.  These give us a reference to where to find the data.  If you skip it
                        you will get nothing at all.</em>  So, to include your product name on your label, use:
                    </p>
                    <span>
                      <pre>
                        Product Name: [item_master.name]
                      </pre>
                    </span>
                    <p>
                      Start simple.  Then as you gain experience add fields.  It will take time to get the results you
                      want... but you only have to do this once.  And only if you want.  Our default labels are designed
                      to comply with regulatory authorities.
                    </p>
                    <span>
                      <pre>
                        ^XA^MNA,0<br />
                        ^CF0,25<br />
                        ^FO20,10^FDDate Created: [createdAt]^FS<br />
                        ^FO20,35^FD[weightOrCount]: [weight_value]^FS<br />
                        ^FO20,60^FDBatch: [batch_id]^FS<br />
                        ^FO20,85^FDLot ID: [lot_id]^FS<br />
                        ^FO20,110^FDPackage ID: [package_id]^FS<br />
                        ^FO20,135^FDProduct: [item_name]^FS<br />
                        ^BY1,1,60^FO20,160^BCN,45,N,N^FD[package_id]^FS<br />
                        ^XZ<br />
                      </pre>
                    </span>
                  </div>
                </Tab>
              </Tabs>

            </div>
          </div>

        </div>


      </div>
      </FormWrapper>
    );

  }

}

LabelsCustomEditor.propTypes = {
  [labelTag]: PropTypes.object.isRequired,
  [customLabelsForTag]: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  labelsConfiguration: PropTypes.array.isRequired
};

function mapStateToProps(state) {

  return {
    labelsConfiguration: state.labelsConfiguration,
    [labelTag]: state[labelTag],
    [customLabelsForTag]: state[customLabelsForTag],
    [finishedProducts]: state[finishedProducts],
    [sourceLabel]: state[sourceLabel]
  };

}

function mapDispatchToProps (dispatch) {
  const actions = {setData, setItem, goBack, push, getData, unsetData, postItem, putItem, getItem, unsetItem};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LabelsCustomEditor);
