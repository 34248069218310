// I would imagine this will be added to

export const roundFloat = (number, decimals = 2) => {

  number = parseFloat(number);

  if(isNaN(number)) return 0;

  const factor = Math.pow(10, decimals);
  // Why Math.floor?! That's always rounding down. Is this on purspose? Side effects of fixing this?
  return Math.floor((number * factor)) / factor;

};

export const safeNumberConversion = (value, defaultValue = 0) => {
  const numericValue = Number.parseFloat(value);
  return Number.isNaN(numericValue) ? defaultValue : numericValue;
};

export const safeIntegerConversion = (value, defaultValue = 0) => {
  const numericValue = Number.parseInt(value);
  return Number.isNaN(numericValue) ? defaultValue : numericValue;
};

export const quickHash = (hashable) => {
  let hash = 0, i, chr;
  if (typeof hashable === 'object'){
    hashable = JSON.stringify(hashable);
  }
  if (hashable.length === 0) return hash;
  for (i = 0; i < hashable.length; i++) {
    chr   = hashable.charCodeAt(i);
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export const saveDistribution = (total, count, decimals) => {
  if (total === undefined) {
    total = 0;
  }

  const even = (roundFloat((total / count), decimals));
  const odd = (total - even * (count - 1));

  return {
    even: Number(safeNumberConversion(even).toFixed(decimals)),
    odd: Number(safeNumberConversion(odd).toFixed(decimals))
  };
};

export function getFractional(num) {
  // Check for NaNs or Infinities
  if (!isFinite(num)) {
    return 0;
  }

  // Faster than parsing strings
  // http://jsperf.com/count-decimals/2
  let count = 0;
  while (num % 1 !== 0) {
    num *= 10;
    count++;
  }
  return count;
}
