import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {push, replace} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import {CancelToken} from 'axios';
import get from 'lodash.get';
import union from 'lodash.union';
import map from 'lodash.map';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import {
  getDataBatchByPost,
  getItem,
  getSearchData,
  getUnpaginatedData,
  getPaginatedData,
  postData,
  postItem,
  putData,
  putItem,
  getData,
  getDataByPost,
} from '../../../actions/apiActions';

import {getCatalogItemMastersWithPrice, getCatalogItemMasterTypeById} from '../../../selectors/itemMastersSelectors';
import { addOrUpdateSingleProductInCart} from '../../../actions/forms/pointOfSaleActions';
import {getIntegrationState} from '../../../selectors/integration/integrationSelectors';
import ProductsHeader from '../../header/ProductsHeader';
import * as menuActions from '../../../actions/menuActions';
import * as cartActions from '../../../actions/cartActions';
import ProductGrid from './ProductGrid';
import {
  areCureProductFiltersEmpty,
  getAllowedSubcategories,
  getFormOptionsBySubcategory
} from '../../../selectors/integration/cureApiSelectors';
import {getOrderWithProductMappings} from '../../../selectors/ordersSelectors';
import {getNonMedicatedSubcategoryIds} from '../../../selectors/subcategoriesSelectors';
import CureApiProductFilter from './cure-api-product-filter/CureApiProductFilter';
import {isReadyStatus} from '../../../selectors/orderSelectors';
import {ohMetrcSubcategoriesForSale} from '../../../selectors/integration/metrcSelectors';
import {isAllowNegativeInventory} from '../../../selectors/complianceSettingsSelectors';
import showNegativeAlert from '../../common/negative-inventory/showNegativeAlert';
import NegativeInventoryAlert from '../../common/negative-inventory/NegativeInventoryAlert';
import {unsetData, setData} from '../../../actions/dataActions';
import {isFeatureEnabled} from '../../../selectors/featureToggles';


export class ProductMenuPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.state = {
      searchText: '',
      sortBy: 'name',
      sortDirection: 'asc',
      ready: true,
      itemsPerPage: 20,
      activePage: 1,
      showNegativeInvConfirmation: {
        show: false,
        onHide: null,
        onConfirm: null,
      },
      itemMastersAllowedToGoNegative: [],
      addToCartEnabled: true,
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getProducts = this.getProducts.bind(this);
    this.switchPage = this.switchPage.bind(this);
    this.clearToken = this.clearToken.bind(this);
    this.ensureToken = this.ensureToken.bind(this);
    this.cancelToken = this.cancelToken.bind(this);
    this.setNegativeConfirmationState = this.setNegativeConfirmationState.bind(this);
  }

  componentDidMount() {
    const {params: {orderId}, integrationState: {isOhMetrc}, currentOrder} = this.props; //eslint-disable-line

    if (!orderId) {
      const redirectUrl = (currentOrder && currentOrder.id) ? '/product-menu/' + currentOrder.id : '/';
      this.props.actions.replace(redirectUrl);
      return false;
    }

    const promises = [
      this.props.actions.getUnpaginatedData('/api/categories', dataNames.categories),
      this.props.actions.getItem(`/api/orders/details/${orderId}`, itemNames.order, {failed: 'orders.get.failed', failedHandler: this.props.handleCartErrors }, {}, undefined, {debounce: true}),
      this.props.actions.getUnpaginatedData('/api/subcategories', dataNames.subcategories, {failed: 'categories.get.failed'}, undefined, undefined, {debounce: true}),
      this.props.actions.getUnpaginatedData('/api/pricing_weights', dataNames.pricingWeights, undefined, undefined, undefined, {debounce: true}),
      this.props.actions.getItem('/api/compliance_settings',itemNames.complianceSettings),
    ];

    if (isOhMetrc) {
      promises.push(this.props.actions.getUnpaginatedData('/api/ohmetrc/item_categories', dataNames.ohMetrcCategories));
      promises.push(this.props.actions.getUnpaginatedData('/api/ohmetrc/item_categories/mapping', dataNames.ohMetrcCategoryMappings));
    }

    Promise.all(promises)
      .then(() => this.getProducts())
      .then(() => this.setState({ready: true}))
      .catch(() => this.setState({ready: true}));
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.allowedSubcategories) !== JSON.stringify(this.props.allowedSubcategories)) {
      this.getProducts();
    }
  }

  componentWillUnmount() {
    this.props.actions.closeCartDetails();
    this.props.actions.closeCustDetails();
    this.props.actions.unsetData(dataNames.priceLists);
    // this.cancelTokens('search');
  }

  ensureToken() {
    const oldToken = this.state.cancelTokens;
    oldToken && oldToken.cancel('Concurrency request');
    const newToken = CancelToken.source();
    this.setState({cancelTokens: newToken});
    return newToken;
  }

  clearToken() {
    this.setState({cancelTokens: null});
  }

  cancelToken() {
    const token = this.state.cancelTokens;
    token && token.cancel('Cancel request');
    this.clearToken();
  }

  getProducts() {
    const {integrationState: {isLeaf, isCure, isBiotrack, isOhMetrc}, customer, allowedSubcategories} = this.props;
    const {sortBy, sortDirection, itemsPerPage, activePage, searchText} = this.state;
    const cancelToken = this.ensureToken('search');

    const getPricingByItemMasterId = (itemMasters) => {
      const payload = {consumer_type: customer.type, items: itemMasters};
      this.props.actions.postData('/api/pricing_classes/item_catalog_prices', payload, dataNames.priceLists);
    };

    const params = {
      with_images: 0,
      having_lab_results_only: Number(isLeaf || isBiotrack),
      page: activePage,
      sort: `${sortBy}:${sortDirection}`,
      per_page: itemsPerPage,
      search_name: searchText,
      consumer_type: customer.type,
      include_pricing: 0
    };

    // Add product_type filtering based on retail_type
    if (this.props.newProductMasterIsEnabled) {
      // If co-located facility (i.e. both isMedicalFacility and isRecreationalFacility) there is no need to filter
      if (this.props.isMedicalFacility && !this.props.isRecreationalFacility) {
        params.is_medical_product_type = 1;
      }
      if (!this.props.isMedicalFacility && this.props.isRecreationalFacility) {
        params.is_recreational_product_type = 1;
      }
    }

    if (isCure || isOhMetrc) {
      params.in_subcategories = allowedSubcategories.length > 0 ? allowedSubcategories : [0];
    }

    return this.props.actions.getPaginatedData('/api/catalog', dataNames.catalog, {failed: 'productMenu.catalog.get.failed'}, params,
      (data) => {
        this.clearToken('search');
        const ids = Object.keys(data).map(id => data[id].primary_product_image_file_id).filter(Boolean).filter(v => v != null);
        if (ids.length) {
          this.props.actions.getDataBatchByPost('/api/images/multiple', {ids}, dataNames.images);
        }
        const pricingItemMasters = map(data, itemMaster => ({
          id: itemMaster.id,
          pricing_type: itemMaster.default_uom === 'EA' ? 'unit' : 'weight'
        }));
        getPricingByItemMasterId(pricingItemMasters);
      }, cancelToken.token
    );
  }

  handleSearch(event) {
    this.setState({searchText: event.target.value, activePage: 1}, this.getProducts);
  }

  handleSort(event) {
    const [sortBy, sortDirection] = event.target.value.split(' ');
    this.setState({sortBy, sortDirection, activePage: 1}, this.getProducts);
  }

  /**
   * Submit a single update for the order.
   * @param formValues
   */
  onSubmit(formValues) {
    this.setState({addToCartEnabled: false});
    this.props.actions.addOrUpdateSingleProductInCart(formValues)
      .then(() => {
        if(formValues.fastTrack){
          this.props.actions.push(`/cart`);
        }
      })
      .catch((availabilityResults) => {
        const itemMasterId = get(availabilityResults, '0.item_master_id', 0);
        showNegativeAlert(true, this.setNegativeConfirmationState)
          .then(() => {
            // Keep track of those approved so we don't nag the user.  Persist only for page for the moment.
            const itemMasterIds = this.props.itemMasterIds.concat([itemMasterId]);
            this.props.actions.setData(itemMasterIds, dataNames.itemMasterIds);
            setTimeout(() => {
              this.onSubmit(formValues);
            }, 100);
          })
          .catch(() => {}); // do nothing
      })
      .finally(() => {
        this.setState({addToCartEnabled: true});
      });
  }

  switchPage(pageNumber) {
    this.setState({activePage: pageNumber}, this.getProducts);
  }

  setNegativeConfirmationState(state) {
    this.setState({
      showNegativeInvConfirmation: state
    });
  }

  render() {
    const {getCatalogItemMasterTypeById, currentOrder, customer, subcategoryFormOptions, integrationState, itemMasters, isReadyStatus, orderPackagingWorkflow, newProductMasterIsEnabled} = this.props;
    const {searchText, sortBy, sortDirection, ready, itemsPerPage, activePage, showNegativeInvConfirmation, addToCartEnabled} = this.state;
    const pageWidth = (this.props.custDetailsOpen || this.props.cartDetailsOpen) ? 8 : 12;

    return (
      <div className='product-menu'>
        <ProductsHeader
          props={this.props}
          context={this.context}
          showFastTrack={true}
          showProfile={!!get(customer, 'id', false)}
          orderProducts={currentOrder.products}
          showCart={true}
        />
        <h1>{I18n.t('productMenu.productMenu')}</h1>
        <CureApiProductFilter customer={customer}/>
        {(ready) && (
          <Row>
            <Col xs={pageWidth}>
              <ProductGrid
                pageWidth={pageWidth}
                itemMasters={itemMasters}
                handleSearch={this.handleSearch}
                handleSort={this.handleSort}
                searchString={searchText}
                sort={sortBy + ' ' + sortDirection}
                onSubmit={this.onSubmit}
                getCatalogItemMasterTypeById={getCatalogItemMasterTypeById}
                isCure={integrationState.isCure}
                subcategoryFormOptions={subcategoryFormOptions}
                itemsPerPage={itemsPerPage}
                activePage={activePage}
                switchPage={this.switchPage}
                sortBy={sortBy}
                sortDirection={sortDirection}
                dataName={dataNames.catalog}
                disablingAddProduct={orderPackagingWorkflow && isReadyStatus}
                newProductMasterIsEnabled={newProductMasterIsEnabled}
                addToCartEnabled={addToCartEnabled}
              />
            </Col>
          </Row>
        )}
        <NegativeInventoryAlert confirmationState={showNegativeInvConfirmation}/>
      </div>
    );
  }
}

ProductMenuPage.propTypes = {
  actions: PropTypes.object.isRequired,
  itemMasters: PropTypes.array.isRequired,
  currentOrder: PropTypes.object,
  custDetailsOpen: PropTypes.bool.isRequired,
  cartDetailsOpen: PropTypes.bool.isRequired,
  customer: PropTypes.object.isRequired,
  customerStat: PropTypes.object.isRequired,
  customerRecentHistory: PropTypes.object.isRequired,
  params: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  productFilter: PropTypes.string,
  getCatalogItemMasterTypeById: PropTypes.func.isRequired,
  integrationState: PropTypes.object.isRequired,
  subcategoryFormOptions: PropTypes.object.isRequired,
  page: PropTypes.object,
  isReadyStatus: PropTypes.bool,
  allowNegativeInventory: PropTypes.bool,
  orderPackagingWorkflow: PropTypes.bool,
  newProductMasterIsEnabled: PropTypes.bool.isRequired,
  isMedicalFacility: PropTypes.bool.isRequired,
  isRecreationalFacility: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const {customer, customerStat, customerRecentHistory, salesComplianceSettings: {order_packaging_workflow}, facility} = state;
  const {custDetailsOpen, cartDetailsOpen} = state.menus;
  const itemMasters = getCatalogItemMastersWithPrice(state, {customer, mode: 'customer'});
  const integrationState = getIntegrationState(state);
  const {isCure, isOhMetrc} = integrationState;
  const cureSubcategoryIds = getAllowedSubcategories(state, {customer, mode: 'customer'});
  const areCureFiltersEmpty = isCure && areCureProductFiltersEmpty(state, {customer, mode: 'customer'});
  const cureNonMedicatedSubcategoryIds = isCure && !areCureFiltersEmpty ? [] : getNonMedicatedSubcategoryIds(state);

  let allowedSubcategories = null;
  if (isCure) {
    allowedSubcategories = union(cureSubcategoryIds, cureNonMedicatedSubcategoryIds);
  }
  if (isOhMetrc) {
    allowedSubcategories = union(ohMetrcSubcategoriesForSale(state), getNonMedicatedSubcategoryIds(state));
  }

  return {
    itemMasters,
    currentOrder: getOrderWithProductMappings(state),
    allowedSubcategories,
    custDetailsOpen,
    cartDetailsOpen,
    customer,
    customerStat,
    customerRecentHistory,
    getCatalogItemMasterTypeById: itemMaster => getCatalogItemMasterTypeById(state, itemMaster),
    integrationState,
    allowNegativeInventory: isAllowNegativeInventory(state),
    subcategoryFormOptions: getFormOptionsBySubcategory(state, {customer, mode: 'customer'}),
    orderPackagingWorkflow: order_packaging_workflow && order_packaging_workflow.value ? true : false, // fight against warnings
    isReadyStatus: isReadyStatus(state),
    handleCartErrors: cartActions.handleErrors,
    currentFacility: facility,
    itemMasterIds: state[dataNames.itemMasterIds],
    newProductMasterIsEnabled: isFeatureEnabled(state)('feature_new_product_master'),
    isMedicalFacility: !!(get(state.facility, 'retail_type') === 'medical' || get(state.facility, 'retail_type') === 'co_located_common_inventory'),
    isRecreationalFacility: !!(get(state.facility, 'retail_type') === 'recreational' || get(state.facility, 'retail_type') === 'co_located_common_inventory'),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...menuActions,
    getDataBatchByPost,
    getUnpaginatedData,
    getItem,
    postItem,
    push,
    replace,
    putItem,
    postData,
    putData,
    getSearchData,
    getPaginatedData,
    getData,
    unsetData,
    setData,
    getDataByPost,
    addOrUpdateSingleProductInCart,
  };
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductMenuPage);
