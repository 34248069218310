import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {ButtonGroup, DropdownButton, Dropdown, Button} from 'react-bootstrap';
import {FaCheck, FaFileExport, FaTimes} from 'react-icons/fa';

const TableControls = ({setVisibleColumns, setPageSize, pageSize, pageSizeList, toggleScanSearch, toggleScanIdSearch,  hideExport, scanSearchEnabled, columns, toggleHistory, historyEnabled, exportCSV, hidePagination}) => {

  // Allow icon click and prevent bubble to parent
  const toggleVisibleColumn = (e, index) => {
    e.target.id = index;
    e.preventDefault();
    e.stopPropagation();
    e.target.blur();
    setVisibleColumns(e);
  };

  return (
    <ButtonGroup className='float-right'>
      {toggleScanSearch ? <Button variant='primary' onClick={toggleScanSearch} active={scanSearchEnabled} > {I18n.t('common.actions.scanSearch')}</Button> : null}
      {toggleScanIdSearch ? <Button variant='primary' onClick={toggleScanIdSearch('scan')} > {I18n.t('common.actions.scanId')}</Button> : null}
      {toggleHistory ? <Button variant='primary' disabled onClick={toggleHistory} active={historyEnabled} >{I18n.t('common.actions.history')}</Button> : null}
      {hidePagination
        ? null
        : <DropdownButton variant='primary'
                          id='pagination-options'
                          title={I18n.t('common.actions.pagination', {num: pageSize})}
                          onSelect={setPageSize}>
          {pageSizeList.map(size =>
            <Dropdown.Item id={`size-${size}`} key={`size-${size}`} eventKey={size}>{size}</Dropdown.Item>
          )}

        </DropdownButton>
      }
      {hideExport || !exportCSV ? '' :
        <DropdownButton variant='primary' title={I18n.t('common.actions.export')} id='panel-export'>
          <Dropdown.Item id='exportCSV' onClick={exportCSV}><FaFileExport/> {I18n.t('common.actions.exportCSV')}</Dropdown.Item>
        </DropdownButton>
      }
      <DropdownButton variant='primary' title={I18n.t('common.actions.columnVisibility')} id='panel-columns'>
        {columns.map((col, index) => col.name ?
          <Dropdown.Item id={index} key={index} onClick={setVisibleColumns} >
            {
              col.hidden
                ? <FaTimes style={{marginRight: '5px'}} onClick={(e) => toggleVisibleColumn(e, index)}/>
                : <FaCheck style={{marginRight: '5px'}} onClick={(e) => toggleVisibleColumn(e, index)}/>
            }
            {I18n.t(col.name)}
          </Dropdown.Item> : null
        )}
      </DropdownButton>
    </ButtonGroup>
  );
};

TableControls.propTypes = {
  setPageSize: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  pageSizeList: PropTypes.array.isRequired,
  toggleScanSearch: PropTypes.func,
  toggleScanIdSearch: PropTypes.func,
  toggleHistory: PropTypes.func,
  scanSearchEnabled: PropTypes.bool,
  historyEnabled: PropTypes.bool,
  hideExport: PropTypes.bool,
  exportCSV: PropTypes.func,
  setVisibleColumns: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  hidePagination: PropTypes.bool,
};

export default TableControls;
