import React from 'react';
import PropTypes from 'prop-types';
import {FormGroup, FormControl, Form, Button} from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import MessageBlock from '../../common/MessageBlock';
import {guid} from '../../../actions/helpers/guid';
import * as messageTypes from '../../../constants/messageTypes';


const LoginForm = ({user, onChange, onSubmit, onDismiss, errors, touched}) => {
  const fields = errors ? [...errors.fields] : [];
  const messages = errors && errors.messages && errors.messages.length ? errors.messages.map(message => ({id: guid(), messageType: messageTypes.error, message})) : [];

  return (
    <form onSubmit={onSubmit} autoComplete='off'>
        <FormGroup controlId='name'
          validationState={fields.indexOf('name') === -1 ? undefined : 'error'}>
          <Form.Label>
            {I18n.t('user.Username')}
          </Form.Label>
          <FormControl
            type='text'
            maxLength={255}
            required
            value={user.name}
            onChange={onChange}
            autoComplete='off'
          />
          <FormControl.Feedback />
        </FormGroup>
        <FormGroup controlId='password'
          validationState={fields.indexOf('password') === -1 ? undefined : 'error'}>
          <Form.Label>
            {I18n.t('user.Password')}
          </Form.Label>
          <FormControl
            type='password'
            required
            maxLength={255}
            value={user.password}
            onChange={onChange}
            autoComplete='new-password'
          />
          <FormControl.Feedback />
        </FormGroup>
        <FormGroup>
          <Button type='submit' variant='primary' block disabled={touched}>
            {I18n.t('user.LogIntoAccount')}
          </Button>
        </FormGroup>
        <MessageBlock messages={messages} style='danger' dismiss={onDismiss} timeout={5000} />
    </form>
  );
};

LoginForm.propTypes = {
  user: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.bool
};

export default LoginForm;
