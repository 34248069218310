import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {push} from 'react-router-redux';
import {getPaginatedData, postData, getData} from '../../../actions/apiActions';
import {handleComplexSelectRow} from '../../../actions/helpers/selectedDataHelper';
import {getTotalResults} from '../../../selectors/paginationSelectors';
import {getTabs, getLabResultColumns} from './tabDefinitions';
import ReleaseQuarantineForm from './ReleaseQuarantineForm';
import * as dataNames from '../../../constants/dataNames';
import PageTitle from '../../common/PageTitle';
import TablePageWrapper from '../../common/grid/TablePageWrapper';
import SimpleTable from '../../common/grid/SimpleTable';
import ModalWrapper from '../../common/ModalWrapper';

class ManageQuarantinedInventoryPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.handleSelect = this.handleSelect.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleReleaseQuarantine = this.handleReleaseQuarantine.bind(this);
    this.fetchLabResultsByPackage = this.fetchLabResultsByPackage.bind(this);
    this.hideLabResultsByPackageModal = this.hideLabResultsByPackageModal.bind(this);
    this.showReleaseQuarantineConfirmation = this.showReleaseQuarantineConfirmation.bind(this);
    this.hideReleaseQuarantineConfirmation = this.hideReleaseQuarantineConfirmation.bind(this);

    this.ref = React.createRef();

    const tabs = getTabs(this);

    // Static for now.
    const activeTab = tabs.find(tab => tab.eventKey === 'onHold');

    this.state = {
      tabs,
      activeTab,
      showReleaseQuarantineConfirmation: false,
      showLabResultsByPackageModal: false
    };
  }

  componentWillReceiveProps (nextProps) {
    const {selectedQuarantinedInventory} = nextProps;

    // If the admin has removed all packages from the confirmation modal, hide the modal.
    if (this.state.showReleaseQuarantineConfirmation && selectedQuarantinedInventory.length === 0) {
      this.hideReleaseQuarantineConfirmation();
    }
  }

  handleSelect(action, rows) {
    this.props.actions.handleComplexSelectRow(rows, dataNames.quarantinedInventory, action);
  }

  handleSearch (sort, search, per_page, start, filter) {
    this.handleSelect('clear');

    const params = {
      search: search || undefined,
      per_page,
      page: (start ? start / per_page : 0) + 1
    };

    this.props.actions.getPaginatedData('/api/closed_loops/quarantined_inventory', dataNames.quarantinedInventory, undefined, params);
  }

  handleReleaseQuarantine () {
    const {selectedQuarantinedInventory} = this.props;

    const payload = {
      ids: selectedQuarantinedInventory.map(p => p.id),
      on_hold: 0
    };

    const messages = {
      success: 'closedLoop.admin.releaseQuarantineSuccess'
    };

    this.props.actions.postData('/api/closed_loops/quarantined_inventory/update_many', payload, undefined, messages, undefined, () => {
      this.ref.current.wrappedInstance.ref.current.callExternalSearch();
    });
  }

  fetchLabResultsByPackage (id) {
    this.props.actions.getData(`/api/closed_loops/lab_results/by_package/${id}`, dataNames.labResults, undefined, undefined, () => {
      this.setState({
        showLabResultsByPackageModal: true
      });
    });
  }

  showReleaseQuarantineConfirmation () {
    this.setState({
      showReleaseQuarantineConfirmation: true
    });
  }

  hideReleaseQuarantineConfirmation () {
    this.setState({
      showReleaseQuarantineConfirmation: false
    });
  }

  hideLabResultsByPackageModal () {
    this.setState({
      showLabResultsByPackageModal: false
    });
  }

  render () {
    const {dataTotalSize, quarantinedInventory, selectedQuarantinedInventory, labResults} = this.props;

    return (
      <div>
        <PageTitle primaryText={I18n.t('closedLoop.admin.quarantinedInventory')}  />
        <TablePageWrapper
          ref={this.ref}
          settingKey='quarantined-inventory'
          dataState='loaded'
          className='quarantined-inventory'
          activeTab={this.state.activeTab.eventKey}
          data={quarantinedInventory}
          columns={this.state.activeTab.columns}
          tabs={this.state.tabs}
          handleSelect={this.handleSelect}
          externalSearch={this.handleSearch}
          selectedRows={selectedQuarantinedInventory && selectedQuarantinedInventory.map(p => p.id)}
          dataTotalSize={dataTotalSize}
          switchTab={() => {}}
          hideSearch={false}
          hideScanSearch={true}
          hideExport={true}
          useAutoSuggest={false}
          external={true}
          showSelectedCount={true}
        />
        <ModalWrapper
          title={I18n.t('closedLoop.admin.releaseQuarantine')}
          dialogClassName='modal-md'
          showModal={this.state.showReleaseQuarantineConfirmation}
          onHide={this.hideReleaseQuarantineConfirmation}
          cancelButton={{
            show: true
          }}
          okayButton={{
            show: true,
            text: I18n.t('closedLoop.admin.releaseQuarantine'),
            onClick: this.handleReleaseQuarantine
          }}
          version={2}
          Component={ReleaseQuarantineForm}
          onRemovePackage={this.handleSelect}
          initialValues={{
            packages: selectedQuarantinedInventory
          }}
        />
        <ModalWrapper
          title={I18n.t('closedLoop.admin.testResults')}
          showModal={this.state.showLabResultsByPackageModal}
          onHide={this.hideLabResultsByPackageModal}
          Component={SimpleTable}
          bordered={false}
          compact={true}
          data={labResults}
          columns={getLabResultColumns(this)}
        />
      </div>
    );
  }
}

ManageQuarantinedInventoryPage.propTypes = {
  actions: PropTypes.shape({
    getPaginatedData: PropTypes.func.isRequired,
    handleComplexSelectRow: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    getData: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }),
  quarantinedInventory: PropTypes.array.isRequired,
  selectedQuarantinedInventory: PropTypes.array.isRequired,
  dataTotalSize: PropTypes.number.isRequired,
  labResults: PropTypes.array.isRequired,
};

function mapStateToProps (state) {
  const selectedQuarantinedInventory = state[dataNames.selectedQuarantinedInventory] || [];

  return {
    selectedQuarantinedInventory,
    quarantinedInventory: state[dataNames.quarantinedInventory],
    dataTotalSize: getTotalResults(state, {name: dataNames.quarantinedInventory}),
    labResults: state[dataNames.labResults]
  };
}

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({getPaginatedData, handleComplexSelectRow, postData, getData, push}, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageQuarantinedInventoryPage);
