import React from 'react';
import PropTypes from 'prop-types';
import {Field, FieldArray} from 'redux-form';
import {Button, Col, Row, Image} from 'react-bootstrap';
import {FaMinus, FaPlus} from 'react-icons/fa';
import {I18n} from 'react-redux-i18n';
import InternationalReduxPhoneInput from '../../common/form/redux-form/InternationalReduxPhoneInput';
import TextInput from '../../common/form/TextInput';
import InternationalDateTimePickerInput from '../../common/form/InternationalDateTimePickerInput';
import FileInput from '../../common/form/FileInput';
import CaregiverAddresses from './CaregiverAddresses';
import * as applicationModes from '../../../constants/applicationModes';
import {normalizeMarijuanaId, formatMarijuanaId} from '../common/utils';
import ContentConcealer from '../../common/concealers/ContentConcealer';
import MarijuanaIdFieldWithMask from '../common/MarijuanaIdFieldWithMask';
import * as itemNames from '../../../constants/itemNames';

export const NewCaregiverFormGroup = (props) => {
  const {arrayPush, fields: {get, push, remove, map}, change, applicationMode, isPaLeaf, isOhMetrc} = props;
  const isMetrcMd = applicationMode === applicationModes.metrcMd;
  const metrcMdProps = {};
  if(isMetrcMd){
    metrcMdProps.normalize = normalizeMarijuanaId;
    metrcMdProps.format = formatMarijuanaId;
  }

  return (
    <Col md={12} className='caregiver-information'>
      {map((fieldNamePrefix, index) => {
        const caregiver = get(index);
        return (
          <React.Fragment key={index}>
            <Row key={`fieldNamePrefix-${index}`}>
              <Col md={9}>
                <Row>
                    <Col md={12}>
                      <h4>{I18n.t('customers.create.caregiverNumber', {number: index + 1})}</h4>
                    </Col>
                  </Row>
                <Row>
                    <Col md={4}>
                      {(caregiver.id) && (
                        <Field name={`${fieldNamePrefix}.id`} component={TextInput} props={{type: 'hidden'}}/>
                      )}
                      <Field name={`${fieldNamePrefix}.first_name`} component={TextInput} props={{
                        label: I18n.t('customers.create.firstName'),
                        placeholder: I18n.t('customers.placeholders.enterFirstName')
                      }}/>
                    </Col>
                    <Col md={1}>
                      <Field name={`${fieldNamePrefix}.middle_name`} component={TextInput} props={{
                        maxLength: 1,
                        label: I18n.t('customers.create.middleName')
                      }}/>
                    </Col>
                    <Col md={4}>
                      <Field name={`${fieldNamePrefix}.last_name`} component={TextInput} props={{
                        label: I18n.t('customers.create.lastName'),
                        placeholder: I18n.t('customers.placeholders.enterLastName')
                      }}/>
                    </Col>
                    <Col md={3}>
                      <InternationalDateTimePickerInput
                        name={`${fieldNamePrefix}.birth_date`}
                        props={{
                          label: I18n.t('customers.create.dateOfBirth')
                        }}/>
                    </Col>
                  </Row>
                <Row>
                  <Col md={4}>
                      <InternationalReduxPhoneInput name={`${fieldNamePrefix}.phone`} props={{
                        label: I18n.t('customers.create.phone'),
                      }}/>
                    </Col>
                  <Col md={4}>
                      <Field name={`${fieldNamePrefix}.email`} component={TextInput} props={{
                        label: I18n.t('customers.create.email'),
                        placeholder: I18n.t('customers.placeholders.emailAddress')
                      }}/>
                    </Col>
                  <Col xs={4}>
                      <Field
                        name={`${fieldNamePrefix}.state_integration_tracking_id`}
                        {...metrcMdProps}
                        component={TextInput}
                        props={{
                          label: I18n.t('customers.create.stateCaregiverId'),
                          placeholder: isMetrcMd ? 'xxxx-xxxx-xxxx-xxxx' : ''
                        }}
                      />
                    </Col>
                </Row>
                  <ContentConcealer show={isPaLeaf || isOhMetrc}>
                    <Row>
                        <Col xs={4}>
                          {isOhMetrc
                            ? <MarijuanaIdFieldWithMask
                              fieldName={`${fieldNamePrefix}.medical_id`}
                              label={I18n.t('customers.create.medicalId')}
                              conditionalProps={{}}
                            />
                            : <Field
                              name={`${fieldNamePrefix}.medical_id`}
                              component={TextInput}
                              props={{
                                label: I18n.t('customers.create.medicalId'),
                                placeholder: I18n.t('customers.create.medicalId')
                              }}
                            />}
                        </Col>
                      </Row>
                  </ContentConcealer>
                <FieldArray
                  name={`${fieldNamePrefix}.addresses`}
                  component={CaregiverAddresses}
                  props={{
                    arrayPush,
                    change
                  }}
                />
              </Col>
              <Col style={{display:'none'}} md={3}>
                  <Row className='image-info-block'>
                    <Col md={4}>
                      <Image thumbnail/>
                    </Col>
                    <Col md={8}>
                      <h4>{I18n.t('customers.create.caregiverPhoto')}</h4>
                      <p>{I18n.t('userAccounts.Profile Image Description')}</p>
                      <p>{I18n.t('userAccounts.Profile Image Limits')}</p>
                      <Field
                        name={`${fieldNamePrefix}.patientPhoto`}
                        itemName={itemNames.image}
                        component={FileInput}
                        props={{
                          btnContent: I18n.t('common.form.uploadFile'),
                          btnProps: {
                            className: 'btn btn-default'
                          }
                        }}/>
                    </Col>
                  </Row>
                </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className='add-button-wrapper float-right'>
                  <span>{I18n.t('customers.create.removeCaregiver', {number: index + 1})}</span>
                  <Button
                    variant='danger'
                    size='sm'
                    onClick={() => {
                      remove(index);
                      // Add the ID of the removed caregiver to the "delete_caregiver_ids" array, so that they may be removed from the database
                      if (caregiver.id) {
                        arrayPush('delete_caregiver_ids', caregiver.id);
                      }
                    }}
                  >
                    <FaMinus/>
                  </Button>
                </div>
              </Col>
            </Row>
          </React.Fragment>
        );
      })}

      <Row>
        <Col md={12}>
          <div className='add-button-wrapper float-right'>
            <span>{I18n.t('customers.create.addCaregiver')}</span>
            <Button
              variant='primary'
              size='sm'
              onClick={() => push({
                id: undefined,
                addresses: []
              })}
            >
              <FaPlus/>
            </Button>
          </div>
        </Col>
      </Row>
    </Col>
  );
};

NewCaregiverFormGroup.propTypes = {
  fields: PropTypes.shape({
    get: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    map: PropTypes.func.isRequired,
    length: PropTypes.number.isRequired
  }),
  change: PropTypes.func.isRequired,
  arrayPush: PropTypes.func.isRequired,
  applicationMode: PropTypes.string.isRequired,
  isPaLeaf: PropTypes.bool.isRequired,
  isOhMetrc: PropTypes.bool.isRequired
};

export default NewCaregiverFormGroup;
