import React from 'react';
import PropTypes from 'prop-types';

import AccordionPanel from '../../common/AccordionPanel';
import MeasurementList from './MeasurementList';

const TerpeneInfoPanel = ({terpeneList}) => {
  return (
    <AccordionPanel title='products.form.terpineInfo' className='form-panel terpine-info'>
    <MeasurementList measurements={terpeneList} translationPrefix='cultivation.testingCompliance.terpeneProfile' />
    </AccordionPanel>
  );
};

TerpeneInfoPanel.propTypes = {
  terpeneList: PropTypes.array.isRequired
};

export default TerpeneInfoPanel;
