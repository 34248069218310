/* eslint-disable import/prefer-default-export*/
import {getData, getUnpaginatedData, getItem} from './apiActions';
import {setData} from './dataActions';
import * as dataNames from '../constants/dataNames';
import * as itemNames from '../constants/itemNames';

import {getFacilityType} from '../selectors/facilitiesSelectors';
import {getIntegrationState} from '../selectors/integration/integrationSelectors';
import {isNonMedicallyCompliantCategories} from '../selectors/integration/leafSelectors';
import {getInventoryById} from './inventory';

export function fetchMetrcTrackingIdsForSelectInput(params = {}, next = () => {}) {

  const parameters = Object.assign({}, {type: 'package', page: 1, per_page: 100, is_inactive: 0}, params);

  return (dispatch, getState) => {
    return dispatch(getData(
      '/api/metrc/tracking_tags',
      dataNames.trackingIds,
      {failed: 'tracking.getTrackingIds.failed'},
      parameters,
      data => {
        dispatch(setData(data, dataNames.trackingIds));
        next(data);
      }
    ));
  };
}

// Loads integration data which is combined with categories in some selectors
export const getIntegrationCategoriesData = () => {
  return (dispatch, getState) => new Promise((resolve) => {
    const getIntegrationSettings = () => {
      dispatch(getItem('/api/integration-settings', itemNames.integrationSettings, {failed: 'stateIntegratorSettings.get.failed'}))
        .then(() => {
          getMappings();
        });
    };
    // Get mappings based on integration if any
    const getMappings = () => {
      const actions = {
        getData,
        getUnpaginatedData,
        getItem
      };

      const integrationState = getIntegrationState(getState());
      const facility_type = getFacilityType(getState());


      const messages = {
        categories: {failed: 'categories.getMetrc.failed'},
        mappings: {failed: 'categories.getMetrcMappings.failed'}
      };
      const dataSources = {
        isWeedmaps: {
          categories: {url: '/api/weedmaps/categories', dataName: dataNames.weedmapsCategories, method: false},
          mappings: {url: '/api/weedmaps/item_categories/mapping', dataName: dataNames.weedmapsCategoryMappings, method: false},
        },
        isMetrc: {
          categories: {url: '/api/metrc/item_categories', dataName: dataNames.metrcCategories, method: false},
          mappings: {url: '/api/metrc/item_categories/mapping', dataName: dataNames.metrcCategoryMappings, method: false},
          uoms: {url: '/api/metrc/uoms', dataName: dataNames.metrcUoms, method: false}
        },
        isOhMetrc: {
          categories: {url: '/api/ohmetrc/item_categories', dataName: dataNames.ohMetrcCategories, method: false},
          mappings: {url: '/api/ohmetrc/item_categories/mapping', dataName: dataNames.ohMetrcCategoryMappings, method: false}
        },
        isLeaf: {
          categories: {url: '/api/leaf/categories', dataName: dataNames.leafCategories, method: false},
          mappings: {url: '/api/leaf/categories/mapping', dataName: dataNames.leafCategoryMappings, method: false}
        },
        isCure: {
          categories: {url: '/api/cureapi/categories/settings', dataName: itemNames.cureCategorySettings, method: 'getItem'},
          mappings: {url: '/api/cureapi/categories/mapping', dataName: dataNames.cureCategoryMappings, method: false}
        },
        isCanada: {
          mappings: {url: '/api/categories/canada', dataName: dataNames.reportingMapping, method: false}
        },
        isOklahoma: {
          mappings: {url: '/api/categories/oklahoma', dataName: dataNames.reportingMapping, method: false}
        },
        isBiotrack: {
          categories: {url: '/api/biotrack/categories', dataName: dataNames.biotrackCategories, method: false},
          mappings: {url: '/api/biotrack/categories/mapping', dataName: dataNames.biotrackCategoryMappings, method: false},
        },
        isApisicpa: {
          categories: {url: '/api/apisicpa/categories', dataName: dataNames.apisicpaCategories, method: false},
          mappings: {url: '/api/apisicpa/categories/mapping', dataName: dataNames.apisicpaCategoryMappings, method: false},
        },
        isLeafly: {
          categories: {url: '/api/leafly/item_categories', dataName: dataNames.leaflyCategories, method: false},
          mappings: {url: '/api/leafly/item_categories/mapping', dataName: dataNames.leaflyCategoryMappings, method: false}
        },
        isIsolocity: {
          categories: {url: '/api/isolocity/item_categories', dataName: dataNames.isolocityCategories, method: false},
          mappings: {url: '/api/isolocity/item_categories/mapping', dataName: dataNames.isolocityCategoryMappings, method: false}
        },
        isCca: {
          categories: {
            url: `/api/cca/categories?facility_type=${facility_type}`,
            dataName: dataNames.ccaCategories,
            method: false,
          },
          mappings: {
            url: '/api/cca/categories/mappings',
            dataName: dataNames.ccaCategoryMappings,
            method: false,
          }
        },
      };

      Object.keys(dataSources).forEach((key) => {
        if(integrationState[key]){
          Object.keys(dataSources[key]).forEach((call) => {
            const data = dataSources[key][call];
            dispatch(actions[!data.method ? 'getUnpaginatedData' : data.method](data.url, data.dataName, messages[call]));
          });
        }
      });
      resolve();
    };
    getIntegrationSettings();
  });
};

/**
 * Update medically compliant status by item id
 * @param id
 * @returns {Promise}
 */
export const updateMedicallyCompliantStatusById = (id) => (dispatch) => {
  return dispatch(getItem(`/api/leaf/import_medically_compliant_status/item/${id}`));
};

/**
 * Check is allowed quick move by medically compliant status
 * @param item
 * @returns {Promise}
 */
export const isAllowedQuickMoveByMedicallyCompliantStatus = (item) => (dispatch) => {
  const checkMedicallyComplaintStatus = (data) => {
    if(data.medically_compliant_status === 'pending' && !isNonMedicallyCompliantCategories(data)) {
      return Promise.reject('cultivation.finishedProduct.form.quickmovePendingMedicallyCompliant');
    }

    return Promise.resolve(item);
  };

  return dispatch(updateMedicallyCompliantStatusById(item.id))
    .then(() => dispatch(getInventoryById(item.id))
      .then(checkMedicallyComplaintStatus));
};
