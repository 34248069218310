const yesNo = [
  {text: 'cultivation.complianceSettings.yes', value: 1},
  {text: 'cultivation.complianceSettings.no', value: 0}
];

const fontSize = [
  {text: 'cultivation.complianceSettings.fontSizeUnit', value: 8},
  {text: 'cultivation.complianceSettings.fontSizeUnit', value: 9},
  {text: 'cultivation.complianceSettings.fontSizeUnit', value: 10},
  {text: 'cultivation.complianceSettings.fontSizeUnit', value: 11},
  {text: 'cultivation.complianceSettings.fontSizeUnit', value: 12},
  {text: 'cultivation.complianceSettings.fontSizeUnit', value: 13},
  {text: 'cultivation.complianceSettings.fontSizeUnit', value: 14},
  {text: 'cultivation.complianceSettings.fontSizeUnit', value: 15},
  {text: 'cultivation.complianceSettings.fontSizeUnit', value: 16}
];


module.exports = [
  {
    type        : 'package',
    setting_key : 'label_cult_package_compliance_settings',
    scope       : 'facility',
    name        : 'Package Label',
    tags :[
      {tag: 'inv_package_label_small', width: 2, height: 1, dpi_203: 1, dpi_300: 0, dpi_600: 1, name: 'Package Label Small'},
      {tag: 'inv_package_label_large', width: 2, height: 1, dpi_203: 1, dpi_300: 0, dpi_600: -1, name: 'Package Label Large'},
    ],
    configurable: true,
    fields      : [
      {
        name   : 'minimum_font_size',
        value  : 10,
        text   : 'cultivation.labelSettings.minFontSize',
        options: fontSize
      },
      {
        name   : 'require_health_and_benefits_claims',
        value  : 1,
        text   : 'cultivation.labelSettings.requireHealthAndBenefitsClaims',
        options: yesNo
      },
      {
        name   : 'require_cultivation_facility_license',
        value  : 1,
        text   : 'cultivation.labelSettings.requireCultivationFacilityLicense',
        options: yesNo
      },
      {
        name   : 'require_harvest_batch',
        value  : 1,
        text   : 'cultivation.labelSettings.requireHarvestBatchNumber',
        options: yesNo
      },
      {
        name   : 'require_net_weight',
        value  : 1,
        text   : 'cultivation.labelSettings.requireNetWeight',
        options: yesNo
      },
      {
        name   : 'require_growth_weight',
        value  : 1,
        text   : 'cultivation.labelSettings.requireGrossWeight',
        options: yesNo
      },
      {
        name   : 'require_lot_number',
        value  : 1,
        text   : 'cultivation.labelSettings.requireLotNumber',
        options: yesNo
      },
      {
        name   : 'require_list_of_non_organic',
        value  : 1,
        text   : 'cultivation.labelSettings.requireListOfNonOrganicPesticides',
        options: yesNo
      },
      {
        name   : 'require_cannabinoid',
        value  : 1,
        text   : 'cultivation.labelSettings.requireCannabinoidPotency',
        options: yesNo
      },
      {
        name   : 'require_terpene',
        value  : 1,
        text   : 'cultivation.labelSettings.requireTerpene',
        options: yesNo
      },
      {
        name   : 'require_mandated_statements',
        value  : 1,
        text   : 'cultivation.labelSettings.requireMandatedStatements',
        options: yesNo
      },
      {
        name   : 'require_date_of_sale',
        value  : 1,
        text   : 'cultivation.labelSettings.requireDateOfSale',
        options: yesNo
      },
      {
        name   : 'require_nutritional_facts',
        value  : 1,
        text   : 'cultivation.labelSettings.requireNutritionalFacts',
        options: yesNo
      },
      {
        name   : 'require_store_license',
        value  : 1,
        text   : 'cultivation.labelSettings.requireRetailStoreLicense',
        options: yesNo
      },
      {
        name   : 'require_patient_registry_number',
        value  : 1,
        text   : 'cultivation.labelSettings.requirePatientRegistryNumber',
        options: yesNo
      },
      {
        name   : 'require_facility_name',
        value  : 1,
        text   : 'cultivation.labelSettings.requireFacilityName',
        options: yesNo
      },
      {
        name   : 'require_facility_address',
        value  : 1,
        text   : 'cultivation.labelSettings.requireFacilityAddress',
        options: yesNo
      },
      {
        name   : 'require_facility_phone',
        value  : 1,
        text   : 'cultivation.labelSettings.requireFacilityPhone',
        options: yesNo
      }
    ]
  },
  {
    type        : 'patient',
    setting_key : 'label_cult_patient_compliance_settings',
    scope       : 'facility',
    name        : 'Patient Label',
    tags :[
      {tag: 'patient_label_small', width: 2, height: 1, dpi_203: 1, dpi_300: 0, dpi_600: -1, name: 'Small Patient Label'},
      {tag: 'patient_label_large', width: 2, height: 1, dpi_203: 1, dpi_300: 0, dpi_600: 1, name: 'Large Patient Label'},
    ],
    configurable: true,
    fields      : [
      {
        name   : 'minimum_font_size',
        value  : 10,
        text   : 'cultivation.labelSettings.minFontSize',
        options: fontSize
      },
      {
        name   : 'require_health_and_benefits_claims',
        value  : 1,
        text   : 'cultivation.labelSettings.requireHealthAndBenefitsClaims',
        options: yesNo
      },
      {
        name   : 'require_cultivation_facility_license',
        value  : 1,
        text   : 'cultivation.labelSettings.requireCultivationFacilityLicense',
        options: yesNo
      },
      {
        name   : 'require_harvest_batch',
        value  : 1,
        text   : 'cultivation.labelSettings.requireHarvestBatchNumber',
        options: yesNo
      },
      {
        name   : 'require_net_weight',
        value  : 1,
        text   : 'cultivation.labelSettings.requireNetWeight',
        options: yesNo
      },
      {
        name   : 'require_growth_weight',
        value  : 1,
        text   : 'cultivation.labelSettings.requireGrossWeight',
        options: yesNo
      },
      {
        name   : 'require_lot_number',
        value  : 1,
        text   : 'cultivation.labelSettings.requireLotNumber',
        options: yesNo
      },
      {
        name   : 'require_list_of_non_organic',
        value  : 1,
        text   : 'cultivation.labelSettings.requireListOfNonOrganicPesticides',
        options: yesNo
      },
      {
        name   : 'require_cannabinoid',
        value  : 1,
        text   : 'cultivation.labelSettings.requireCannabinoidPotency',
        options: yesNo
      },
      {
        name   : 'require_terpene',
        value  : 1,
        text   : 'cultivation.labelSettings.requireTerpene',
        options: yesNo
      },
      {
        name   : 'require_mandated_statements',
        value  : 1,
        text   : 'cultivation.labelSettings.requireMandatedStatements',
        options: yesNo
      },
      {
        name   : 'require_date_of_sale',
        value  : 1,
        text   : 'cultivation.labelSettings.requireDateOfSale',
        options: yesNo
      },
      {
        name   : 'require_nutritional_facts',
        value  : 1,
        text   : 'cultivation.labelSettings.requireNutritionalFacts',
        options: yesNo
      },
      {
        name   : 'require_store_license',
        value  : 1,
        text   : 'cultivation.labelSettings.requireRetailStoreLicense',
        options: yesNo
      },
      {
        name   : 'require_patient_registry_number',
        value  : 1,
        text   : 'cultivation.labelSettings.requirePatientRegistryNumber',
        options: yesNo
      },
      {
        name   : 'require_physician_name',
        value  : 1,
        text   : 'cultivation.labelSettings.requirePhysicianName',
        options: yesNo
      },
      {
        name   : 'require_physician_license',
        value  : 1,
        text   : 'cultivation.labelSettings.requirePhysicianLicense',
        options: yesNo
      },
      {
        name   : 'require_physician_diagnosis',
        value  : 1,
        text   : 'cultivation.labelSettings.requirePhysicianDiagnosis',
        options: yesNo
      }
    ]
  }
];

