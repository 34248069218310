import {createSelector} from 'reselect';
import orderBy from 'lodash.orderby';
import * as dataNames from '../constants/dataNames';
import getSelectedDataName from '../constants/helpers/getSelectedDataName';

const orderStrains = strains => orderBy(strains, 'strain_name', 'asc');
const selectedFacilityStrains = getSelectedDataName(dataNames.facilityStrains);

export const getFacilityStrains = state => state[dataNames.facilityStrains];
export const getOrderedFacilityStrains = createSelector([getFacilityStrains], orderStrains);
export const getSelectedFacilityStrains = state => state[selectedFacilityStrains];
export const getSelectedFacilityStrainIds = createSelector([getSelectedFacilityStrains], strains => strains.map(s => s.id));
const getIntegationMapping = state => state[dataNames.integrationMapping];

export const getFacilityStrainsTableData = createSelector(
  [getOrderedFacilityStrains, getIntegationMapping],
  (strains, integrationMapping) => {
    const fields = ['veg_days', 'prop_days', 'flower_days'];
    return strains.map((strain) => {
      const extraFields = fields.reduce(
        (acc, field) => ({
          ...acc,
          [field]: strain && strain.cultivation && strain.cultivation[field] || '',
        }),
        {}
      );
      const tracking = integrationMapping.find(entity => Number.parseInt(entity.internal_identifier) === strain.id);
      return {
        ...strain,
        ...extraFields,
        tracking_id: tracking ? tracking.external_identifier : ''
      };
    });
  }
);

export const addAdditionalStrainToList = (strains = [], additionalStrain) => {
  return (!additionalStrain.id || strains.find((strain) => additionalStrain.id === strain.id)) ? strains : orderStrains([...strains, additionalStrain]);
};
