import React from 'react';
import omit from 'lodash.omit';
import {pick} from 'lodash';
import get from 'lodash.get';
import { CancelToken } from 'axios';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import * as messageTypes from '../../../constants/messageTypes';
import { getModulesState } from '../../../selectors/modulesSelectors';
import { getTabs } from './serviceFirstTabDefinitions';
import {getInventorySolrReworkQueryAndFilter, validateWaSplit} from '../../../selectors/inventoryItemsSelectors';
import { isInvPackageTrackingNotInCompliance } from '../../../selectors/complianceSettingsSelectors';
import { getSearchResultIdConstraintsFromSearchResult } from './helpers';

class ServiceFirstAbstractInventoryPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.ref = React.createRef();

    this.trClassName = this.trClassName.bind(this);

    this.lastQuery = '';
    this.searchInFlight = false;
    this.lotIds = [];
    this.itemIds = [];
    this.itemIdsFromLots = [];
    this.activeTabEventKey = false;
  }

  componentWillUnmount() {
    this.cancelTokens('search', 'labResult');
    this.props.actions.unsetListingSource();
  }

  loadTabs() {
    const { state } = this.props;
    const { hasManufacturing } = getModulesState(state);

    const promises = [
      this.props.actions.getItem('/api/compliance_settings', itemNames.complianceSettings),
      this.props.actions.getItem(
        '/api/cultivation/settings',
        itemNames.wasteCompliance,
        { failed: 'cultivation.wasteCompliance.get.failed' },
        {
          ids: ['cult_waste_destruction_compliance_settings', 'inv_track_waste_packages', 'inv_track_waste_location_id']
        }
      )
    ];

    if (hasManufacturing) {
      promises.push(
        this.props.actions.ensureGetUnpaginatedData('/api/phases', dataNames.phases, { failed: 'phases.get.failed' })
      );
    }

    return Promise.all(promises)
      .then(this.setTabs)
      .then(this.setTabsLoaded);
  }

  setTabs() {
    return new Promise((resolve) => {
      const tabs = getTabs(this);
      this.setState({ tabs }, () => resolve());
    });
  }

  setTabsLoaded() {
    return new Promise((resolve) => {
      this.setState({ tabsLoaded: true }, () => resolve());
    });
  }

  refreshActiveTab () {
    return new Promise(resolve => {
      const activeTab = this.state.tabs.find(tab => {
        return tab.eventKey === this.state.activeTab.eventKey;
      });

      this.setState({
        activeTab,
      }, () => {
        resolve();
      });
    });
  }

  ensureToken(name) {
    const oldToken = this.state.cancelTokens[name];
    oldToken && oldToken.cancel('Concurrency request');
    const newToken = CancelToken.source();
    this.setState({
      cancelTokens: {
        ...this.state.cancelTokens,
        [name]: newToken
      }
    });
    return newToken;
  }

  clearTokens(...names) {
    this.setState({ cancelTokens: omit(this.state.cancelTokens, names) });
  }

  cancelTokens(...names) {
    const tokens = pick(this.state.cancelTokens, names);
    tokens && Object.keys(tokens).forEach((key) => tokens[key].cancel('Cancel request'));
    this.clearTokens(...names);
  }

  splitPackage() {
    const {
      integrationState: { isWaLeaf },
      selectedProducts,
      actions: { addMessage },
      complianceSettings: { inv_packages_require_tracking_id_message, inv_packages_require_tracking_id }
    } = this.props;

    if (inv_packages_require_tracking_id) {
      const somePackagesDontHaveTrackingId = selectedProducts.some((item) => {
        return isInvPackageTrackingNotInCompliance(item);
      });

      if (somePackagesDontHaveTrackingId) {
        addMessage(messageTypes.error, inv_packages_require_tracking_id_message, true);
        return false;
      }
    }

    if (isWaLeaf) {
      if (!validateWaSplit(selectedProducts, isWaLeaf)) {
        const packageIds = selectedProducts
          .map((item) => {
            if (item.medically_compliant_status === 'pending') {
              return item.package_code;
            }
          })
          .filter(Boolean);
        if (packageIds.length) {
          addMessage(messageTypes.error, ['inventory.splitPackage.pendingSplitError', { packageIds }]);
          return false;
        }
      }
    }
    this.props.actions.push(`/split_package`);
    return true;
  }

  addTestResult() {
    const {
      selectedProducts,
      complianceSettings: { inv_packages_require_tracking_id_message, inv_packages_require_tracking_id, inv_test_results_retest_limit },
      actions: { addMessage, canRetestPackages }
    } = this.props;

    canRetestPackages(selectedProducts, inv_test_results_retest_limit, () => {
      if (inv_packages_require_tracking_id) {
        const somePackagesDontHaveTrackinId = selectedProducts.some((item) => {
          return isInvPackageTrackingNotInCompliance(item);
        });

        if (somePackagesDontHaveTrackinId) {
          addMessage(messageTypes.error, inv_packages_require_tracking_id_message, true);
          return false;
        }
      }

      this.props.actions.push(`/test-results/add`);
    });
  }

  releaseAllReservations() {
    this.setState({ showReleaseReservationsConfirmModal: true });
  }

  releaseAllReservationsConfirmed() {
    this.props.actions.deleteItem('/api/item_reservations/all', 'none', {
      success: 'reservations.clearSuccess',
      fail: 'plants.actions.updateSearchFail'
    });
    this.props.actions.deleteItem('/api/product_reservations/all', 'none', {
      success: 'reservations.clearSuccess',
      fail: 'plants.actions.updateSearchFail'
    });
    this.setState({ showReleaseReservationsConfirmModal: false });
  }

  updateSearch() {
    this.props.actions.postItem('/api/search/update', { core: 'inventory' }, null, {
      success: 'plants.actions.updateSearchSuccess',
      fail: 'plants.actions.updateSearchFail'
    });
    this.setState({ reindexing: true });
  }

  dataUpdated() {
    this.setState({ reindexing: false });
  }

  reload(unsetData) {
    unsetData && this.props.actions.unsetData(dataNames.finishedProducts);
    this.props.actions.clearSelectedData(dataNames.products);
    this.hideActivateModal();
    this.hidePrinter();
  }

  setActiveTab(activeTab, cb = () => {}) {
    this.setState({ activeTab }, cb);
  }

  filter(tabEventKey, unset = true) {
    if (unset) {
      this.unsetSearchData();
    }

    const activeTab = this.state.tabs.find((tab) => tab.eventKey === tabEventKey);
    if (activeTab) {
      if (this.ref.current) {
        this.ref.current.wrappedInstance.filter(activeTab.filter);
      }
    }
    this.reload(true);
  }

  onSearchChange(query) {
    if (!query) {
      this.props.actions.clearSelectedData(dataNames.products);
    }
  }

  handleSelect(action, rows) {
    this.props.actions.handleComplexSelectRow(rows, dataNames.products, action);
  }

  unsetSearchData() {
    this.props.actions.unsetData(dataNames.products);
    this.props.actions.unsetData(dataNames.childProducts);
  }

  hideActivateModal() {
    this.setState({ showActivateModal: false });
  }

  showReservationsModal(selectedPackage) {
    const lotNumber = get(selectedPackage, 'lot_number', null);
    this.setState({
      showReservationsModal: true,
      packageIdForReservationsModal: get(selectedPackage, 'package_id'),
      packageMetaData: {
        item_master_name: get(selectedPackage, 'name', get(selectedPackage, 'item_name')), // Comes from name
        item_master_id: get(selectedPackage, 'item_master_id'), // Comes from name
        package_id: get(selectedPackage, 'package_code', '')
          .trim()
          .toUpperCase(),
        batch_id: typeof lotNumber === 'string' ? lotNumber.trim().toUpperCase() : '',
        location_name: get(selectedPackage, 'location_name') // Comes from location name
      }
    });
  }

  hideReservationsModal() {
    this.setState({ showReservationsModal: false });
  }

  showTransferModal() {
    this.setState({
      showTransferModal: true
    });
  }

  hideTransferModal() {
    this.setState({
      showTransferModal: false
    });
  }

  trClassName(useEntityLocks, row) {
    return useEntityLocks && row.is_locked ? 'entity-locked-table-row' : null;
  }

  getTableData () {
    const {inventoryByLots, isListingSourceSolr} = this.props;
    return this.getDataByListingSourceAndState(isListingSourceSolr, [], inventoryByLots);
  }

  getTableDataSize () {
    const {dataTotalSize, isListingSourceSolr} = this.props;
    return this.getDataByListingSourceAndState(isListingSourceSolr, 0, dataTotalSize);
  }

  getDataByListingSourceAndState(isListingSourceSolr, defaultValue, serviceFirstValue){
    return isListingSourceSolr // search in progress
      ? defaultValue
      : this.searchInFlight // search in flight, when done use serviceFirstValue
        ? defaultValue
        : serviceFirstValue;
  }


  /*******************************************************
   * Primary Data Fetch and Search Methods
   *******************************************************/

  /**
   * Triggered by page load, page change, sort change, or search.
   * Search calls solr and hands off lots required to service first code.
   * All other calls go straight to service first code.
   * @param sort
   * @param query
   * @param size
   * @param start
   * @param filter
   * @returns {*}
   */
  handleSearch (sort = 'created_at desc', query = '', size, start, filter) {
    const {activeTab} = this.state;
    if (!filter || !activeTab || !this.props.pageSize) {
      return;
    }

    if(this.activeTabEventKey !== activeTab.eventKey){
      this.searchInFlight = false;
      this.lotIds = [];
      this.itemIds = [];
      this.activeTabEventKey = activeTab.eventKey;
      this.lastQuery = ''; // So that we re run search on a new tab.
    }

    if(this.searchInFlight){
      return;
    }

    const isSearch = Boolean(query && query !== this.lastQuery);
    if (!isSearch) {
      const useStart = !query && this.lastQuery ? 0 : start;
      if(!query){
        this.lotIds = [];
        this.itemIds = [];
        this.lastQuery = '';
      }
      return this.handleListing(useStart, size, sort);
    }

    this.lastQuery = query;
    this.searchInFlight = true;
    this.props.actions.setListingSourceSolr()
      .then(this.setTabs)
      .then(this.refreshActiveTab)
      .then(() => {
        // Search returns key data (lot id, item id) we collect, then request with pagination from the back end services.
        const arbitraryHighSizeLimit = 20000;

        // TODO: for solr rework - new inventory - bulding filter and query for reworked solr search
        const filter = get(this.state, 'activeTab.filter') || {};
        const {updatedFilter, updatedQueryString} = getInventorySolrReworkQueryAndFilter(query, filter, this.props.solrCoreName == 'new_inventory');

        const params = {
          sort: sort === '' ? 'created_at desc' : sort,
          query: updatedQueryString,
          size: arbitraryHighSizeLimit,
          start: 0, // always zero since search now just hands off to service first
          filter: updatedFilter,
        };

        const cancelToken = this.ensureToken('search');
        const config = {
          errorHandler: {
            message: 'cultivation.finishedProduct.table.solrErrorLimitedSearch',
            action: this.props.actions.setSolrErrorMessage,
            clearOnSuccess: true,
          }
        };

        this.props.actions.unsetData(dataNames.products);
        this.props.actions.unsetData(dataNames.childProducts);

        // console.log('SFAP@handleSearch', params, this.props.solrCoreName);
        // TODO: for solr rework - new inventory - switching solr search endpoint by feature toggle
        this.setDataState('searching')
          .then(() => {
            const url = '/api/search/inventory';
            this.props.actions.getSearchData(url, dataNames.searchResults, null, params, null, cancelToken.token, config)
              .then((items) => {
                this.searchInFlight = false;
                if(!items.length){
                  this.setDataState('loaded');
                  return false;
                }
                const {lotIds, itemIds} = getSearchResultIdConstraintsFromSearchResult(items);
                this.lotIds = lotIds;
                this.itemIds = itemIds;
                this.handleListing(params.start, params.size, params.sort);
              })
              .catch(() => {
                // Failover to service first full data set.
                this.searchInFlight = false;
                this.handleListing(start, params.size, sort);
              });
          });


      });
  }

  /**
   * Service First request for lots and then other dependencies
   * @param start
   * @param size
   * @param sort
   * @returns {*}
   */
  handleListing (start, size, sort) {
    const getItemIdsFromLots = (lots) => {
      return lots.reduce((acc, lot) => {
        const itemIds = get(lot, 'item_ids', '').split(',').map((id) => parseInt(id));
        if (itemIds.length) {
          acc = acc.concat(itemIds);
        }
        return acc;
      }, []);
    };

    this.setDataState('loading')
      .then(() => {
        return this.props.actions.setListingSourceService()
          .then(this.setTabs)
          .then(this.refreshActiveTab)
          .then(() => {
            const baseFilter = this.state.activeTab.filter || {};
            const lotIdsFilter = this.lotIds.length ? {in_lot_ids: this.lotIds} : {};
            const itemIdsFilter = this.itemIds.length ? {in_item_ids: this.itemIds} : {};
            const filter = Object.assign({}, baseFilter, lotIdsFilter, itemIdsFilter);
            const maxSize = 50;
            const useSize = parseInt(size) > maxSize ? this.props.pageSize : size;

            const params = {
              ...filter,
              per_page: useSize,
              page: (start ? start / useSize : 0) + 1,
              sort: sort ? sort.replace(/\s/, ':') : undefined,
              paginate: 1,
            };

            this.props.actions.getDataByPost('/api/inventory_lots', params, dataNames.products)
              .then((lots) => {
                this.clearTokens('search');
                this.itemIdsFromLots = getItemIdsFromLots(lots);
                if (!this.itemIdsFromLots.length) {
                  this.setDataState('loaded');
                }
                const lotNumbers = lots.map((lot) => lot.lot_number);
                this.getInventoryItems(this.itemIdsFromLots);
                if (this.props.isColombia && this.props.isGrow) {
                  this.fetchHarvestBatches(lotNumbers);
                  this.fetchCupos();
                }
              });
          });
      });
  }

  /**
   * Gets the child items based on the passed in ids.
   * Supports reloading for leaf pa and biotrack where they reload the items after importing lab results.
   * @param ids
   * @param type - either itemId or reload which gets itemIds from state
   */
  getInventoryItems (ids, type = 'itemId') {
    if (type === 'reload') { // Triggered when leaf pa lab results are pulled fresh - was fetchChildren
      ids = this.itemIdsFromLots;
    }
    const {getDataByPost} = this.props.actions;
    const getInventoryLocks = (ids) => new Promise((resolve) => {
      const handler = () => resolve();
      // We're using a post here to prevent a 414 URI Too Long error. This could happen when the user
      // shows many items that each have a lot of splits
      getDataByPost('/api/inventory_locks', {
        active: 1,
        entity_type: 'item',
        in_entity_ids: ids,
        select_columns: ['id', 'active', 'entity_id', 'lock_source', 'lock_reason', 'released_at', 'created_at', 'updated_at', 'manually_released']
      }, dataNames.inventoryLocks, undefined)
        .then(handler)
        .catch(handler);
    });

    const getIntegrationMapping = (ids) => new Promise((resolve) => {
      const handler = () => resolve();
      getDataByPost('/api/integration-mapping/multiple', {
        mapping_key: 'inv_item',
        ids: ids
      }, dataNames.integrationMappings)
        .then(handler)
        .catch(handler);
    });

    const params = {
      detailed: 1,
      ids,
    };

    getDataByPost('/api/items/multiple', params, dataNames.childProducts)
      .then((items) => {
        this.setDataState('loaded');
        getIntegrationMapping(ids)
          .then(() => {
            getInventoryLocks(ids)
              .then(() => {
                this.getLabResultsBackend(items);
              });
          });
      });
  }

  fetchHarvestBatches (lotNumbers) {
    if (lotNumbers.length) { // lotNumbers are batch names
      this.props.actions.getData('/api/harvest_batches', dataNames.harvests, null, {in_batch_names: lotNumbers});
    }
  }

  fetchCupos () {
    this.props.actions.getData('/api/cupos', dataNames.cupos, null);
  }

  getLabResultsBackend (items) {
    const {getDataByPost} = this.props.actions;

    //Get only unique and non-null package ids
    const allPackageIds = items.map((item) => {
      const packageId = get(item, 'package_id', 0);
      return packageId ? packageId : 0;
    });
    const uniquePackageIds = [...new Set(allPackageIds)];
    if (uniquePackageIds && uniquePackageIds.length > 0) {
      const params = {
        reference_type: 'package',
        reference_ids: uniquePackageIds,
        select_columns: ['testing_id'],
      };
      getDataByPost('/api/lab_results_references/by_reference_ids?latest_only=1', params, dataNames.testResults);
    }
  }

  getLabResults (ids, token) {
    if (ids.length) {
      const query = 'matchall';
      const size = 10000;
      const sort = 'testing_date desc';
      const filter =
        ids.reduce((acc, id) => {
          return acc ? acc + ` OR ${id}` : `package_id: (${id}`;
        }, '') + `) AND is_latest_lab_result: 1`;
      const params = {query, size, sort, filter};
      this.props.actions.getDataByPost(
        '/api/search/lab_results',
        params,
        dataNames.testResults,
        null,
        null,
        () => {
          this.clearTokens('labResult');
        },
        token.token
      );
    }
  }

  getItemDestructions (items) {
    const {
      integrationState: {isBiotrack},
      actions: {getDataBatchByPost}
    } = this.props;
    const item_ids = items.filter((item) => item.is_destroyed).map((item) => item.id);
    if (isBiotrack && item_ids.length && getDataBatchByPost) {
      getDataBatchByPost('/api/biotrack/item_destructions', {item_ids}, dataNames.biotrackItemDestructions, {
        failed: 'destructions.get.failed'
      });
    }
  }

  setDataState(state){
    return new Promise((resolve) => {
      this.setState({
        dataState: state
      }, () => {
        resolve();
      });
    });
  }
}

export default ServiceFirstAbstractInventoryPage;
