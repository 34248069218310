import {createSelector} from 'reselect';
import get from 'lodash.get';
import * as itemNames from '../constants/itemNames';
import {getFeatureTogglesByName} from './featureToggles';

export const getCoreSettings = state => {
  return state && state[itemNames.coreSettings] || {};
};

export const getCoreInternationalSettings = state => {
  return state && state[itemNames.internationalSettings] || {};
};

export const getSettingsHash = state => state[itemNames.settingsHash];

const getAllUseEntityLocks = state => state[itemNames.useEntityLocks];

export const getLabEditRequiresManageLabTesting = createSelector([getCoreSettings], (coreSettings) => {
  return get(coreSettings, 'inv_lab_edit_requires_manage_lab_testing.value', false);
});


export const getAllowStateIntegrationSetting = createSelector([getSettingsHash], (settingsHash) => {
  return get(settingsHash, 'core_allow_state_integrations.value', false);
});

export const getIsHempFacility = createSelector([getSettingsHash], (settingsHash) => {
  return get(settingsHash, 'core_is_hemp_facility.value', false);
});

export const getIsGlobalIdEnabled = createSelector([getSettingsHash], (settingsHash) => {
  return get(settingsHash, 'core_global_id_generation_enabled.value', false);
});

export const getLabApplyProfilesSetting = createSelector([getCoreSettings], (coreSettings) => {
  return Boolean(get(coreSettings, 'inv_lab_apply_profiles.value', false));
});

export const isInternationalSettingsEnabled = createSelector([getFeatureTogglesByName], (featureToggles) => {
  return get(featureToggles, 'feature_spain_parity.is_enabled', false);
});

export const getInternationalSettingInitialValues = createSelector([getCoreInternationalSettings], (internationalSettings) => {
  return {
    currency_symbol: get(internationalSettings, 'core_currency_symbol.value', '').toString(),
    numerical_separator: get(internationalSettings, 'core_numerical_separator.value', '').toString(),
    time_format: get(internationalSettings, 'core_time_format.value', '').toString(),
    date_format: get(internationalSettings, 'core_date_format.value', '').toString(),
    phone_number_include_country_code: get(internationalSettings, 'core_phone_number_include_country_code.value', '').toString(),
    default_language: get(internationalSettings, 'core_default_language.value', '').toString(),
  };
});

export const getUseEntityLocksForItems = createSelector(
  [getAllUseEntityLocks], allUseEntityLocks => {
    return allUseEntityLocks && allUseEntityLocks['items'];
  }
);

export const getUseEntityLocksForHarvestBatches = createSelector(
  [getAllUseEntityLocks], allUseEntityLocks => {
    return allUseEntityLocks && allUseEntityLocks['harvest_batches'];
  }
);

export const getUseEntityLocksForPlants = createSelector(
  [getAllUseEntityLocks], allUseEntityLocks => {
    return allUseEntityLocks && allUseEntityLocks['plants'];
  }
);

