import {createSelector} from 'reselect';
import get from 'lodash.get';
import {getSettings} from '../../salesSettingsSelectors';

const getRulesCategories = createSelector([getSettings], (settings) => {
  //Leaving the language for backward compatibility, but we ideally can use the values in the settings themselves
  const languageMap = {
    USABLE_MMJ: 'Usable Marijuana',
    SOLID_CANNABINOID: 'Solid Cannabinoid Product',
    LIQUID_CANNABINOID: 'Liquid Cannabinoid Product',
    CONCENTRATE: 'Cannabinoid Extract-Concentrate',
    PLANTS: 'Immature Plants',
    SEEDS: 'Seeds'
  };

  const categories = (settings && settings.order_sales_limit_categories)
    ? settings.order_sales_limit_categories
    : {};
  return categories.value && !Array.isArray(categories.value) && Object.keys(categories.value).length
    ? Object.keys(categories.value).reduce((acc, key) => {
      acc.push({
        value: key,
        text: get(languageMap, key, categories.value[key])
      });
      return acc;
    }, [])
    : [];
});

export default getRulesCategories;
