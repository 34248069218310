import {requiredFieldValidation} from '../../common/form/redux-form/validations';

export const validate = (formValues, props) => {
  const errors = {};

  errors.delivery_method = requiredFieldValidation(formValues.delivery_method, 'deliveryOrders.form.deliveryMethod');
  errors.delivery_status = requiredFieldValidation(formValues.delivery_status, 'deliveryOrders.form.deliveryStatus');

  if (formValues.delivery_method === 'courier') {
    errors.partner_facility_id = requiredFieldValidation(formValues.partner_facility_id, 'deliveryOrders.form.courier');
  }

  return errors;
};

export default validate;
