import {createSelector} from 'reselect';
import {I18n} from 'react-redux-i18n';
import orderBy from 'lodash.orderby';
import sortBy from 'lodash.sortby';
import get from 'lodash.get';
import * as facilityTypes from '../constants/facilityTypes';
import {validAppsForRegulatorFacilityType} from '../constants/apps';
import * as dataNames from '../constants/dataNames';
import * as itemNames from '../constants/itemNames';
import {getApplicationMode} from './applicationModesSelectors';
import {getOrgIntegrationSettings} from './integration/commonSelectors';
import {getIntegrationState} from './integration/integrationSelectors';

const facilities = (state) => state.facilities;
export const getFacilities = createSelector(facilities, facilities => {
  return orderBy(facilities, facility => facility.name, 'asc');
});
export const getDetailedFacilities = state => state.detailedFacilities;
export const getFacilityGroups = state => state[dataNames.facilityGroups];
export const getCurrentFacilityModules = state => state[dataNames.currentFacilityModules];
export const getModules = state => state[dataNames.modules];
export const getFacility = state => state[itemNames.facility];

const getPrimaryFacilityId = (_, props) => props.primary_facility_id;
const getLinks = (_, props) => props.hasOwnProperty('links') ? props.links : [];

export const getFacilityByPrimaryId = createSelector(
  [getPrimaryFacilityId, getFacilities],
  (facilityId, facilities) => facilities.reduce(
    (previous, current) => {
      return current.id === facilityId ? current : previous;
    }, null
  )
);

export const getDetailedFacilitiesSortedByName = createSelector(
  getDetailedFacilities, facilities => sortBy(facilities, 'name')
);

export const getDetailedFacilitiesWithIntegration = createSelector(
  [getDetailedFacilitiesSortedByName, getOrgIntegrationSettings],
  (facilities, orgIntegrationSettings) => facilities.map((facility) => {
    const integrationSettings = orgIntegrationSettings[facility.id] || {};
    return {
      ...facility,
      integrationState: getIntegrationState({
        [itemNames.integrationSettings]: integrationSettings,
      }),
    };
  })
);

export const getDispensaries = createSelector(
  getFacilities, facilities => (facilities || []).filter(facility => facility.type === facilityTypes.dispensary)
);

export const getRetailFacilityOptions = createSelector(
  [getDispensaries],
  facilities => facilities.map(facility => ({text: facility.name, value: facility.id}))
);

export const getActiveFacility = state => state && state.facility || {};
export const getActiveFacilityId = state => state && state.facility && state.facility.id;
export const getFacilitySwitch = state => state.facilitySwitch;

export const isFacilitySwitchInProgress = createSelector(
  [getFacilitySwitch],
  (facilitySwitch) => {
    return facilitySwitch.inProgress;
  }
);

export const getPartnerFacilities = state => state.partnerFacilities;

// const checkFacilityType = (link, facilityType) => link.facilityTypes === undefined || link.facilityTypes.indexOf(facilityType) > -1;
const checkApplicationMode = (link, applicationMode) => link.applicationModes === undefined || link.applicationModes.indexOf(applicationMode) > -1;

export const getFacilitySetupLinks = createSelector(
  [getActiveFacility, getApplicationMode, getLinks],
  (facility, applicationMode, links) => {
    const filteredLinks = links.filter((link) => {
      if(!isRegulatorFacility(facility)){
        return checkApplicationMode(link, applicationMode);
      }
      return isLinkValidForRegulatorFacility(link);
    });
    return orderBy(filteredLinks, link => I18n.t(link.title), 'asc');
  }
);

export const isRegulatorFacilitySelector = createSelector(getFacility, (facility) => {
  return isRegulatorFacility(facility);
});

export const isNotRegulatorFacilitySelector = createSelector(getFacility, (facility) => {
  return !isRegulatorFacility(facility);
});

export const isRegulatorFacility = (facility) => {
  return get(facility, 'type') === facilityTypes.regulatorAccess;
};

export const isLinkValidForRegulatorFacility = (link) => {
  return link.link ? link.link.indexOf('users') !== -1 || link.link.indexOf('organization/create') !== -1 : false;
};

export const isAppValidForRegulatorFacility = (app, facility) => {
  if (!isRegulatorFacility(facility)) {
    return true; // If not regulator then app is valid as far as this test is concerned.
  }
  const id = app.id;
  return validAppsForRegulatorFacilityType.indexOf(id) !== -1;
};

export const getFacilityTitle = createSelector(
  [getActiveFacility],
  (facility) => {
    switch (facility.id) {
    case 1:
      return I18n.t('cultivation.header');
    case 2:
      return I18n.t('ei.title');
    case 3:
      return I18n.t('retail.title');
    }
  }
);

export const isActiveFacilityGrow = createSelector(
  [getActiveFacility],
  facility => facility.type === facilityTypes.grow
);

export const isActiveFacilityDispensary = createSelector(
  [getActiveFacility],
  facility => facility.type === facilityTypes.dispensary
);

/**
 * Now we are trying to make decision based on enabled modules
 * @see ./modulesSelectors.js
 * @deprecated since 1.3.5
 */
export const isActiveFacilityManufacturing = createSelector(
  [getActiveFacility],
  facility => facility.type === facilityTypes.manufacturing
);

export const isActiveFacilityCourierService = createSelector(
  [getActiveFacility],
  facility => facility.type === facilityTypes.courier_service
);

export const isActiveFacilityResearchUniversity = createSelector(
  [getActiveFacility],
  facility => facility.type === facilityTypes.researchUniversity
);

export const isActiveFacilityLab = createSelector(
  [getActiveFacility],
  facility => facility.type === facilityTypes.lab
);

export const getFacilityGroupsWithMemberDetails = createSelector([getFacilityGroups, getDetailedFacilities], (facilityGroups, facilities) => {
  return facilityGroups.map((facilityGroup) => {
    return Object.assign({}, facilityGroup, {
      members: facilityGroup.members.map((member) => {
        return facilities.find((facility) => facility.id === member.facility_id);
      })
    });
  });
});

export const getFacilitiesForDropdown = createSelector([getActiveFacility, getFacilities], (facility, facilities) => {
  return facilities.map((iteratedFacility, index) => {
    const facilityAddress = [
      iteratedFacility.street_address_1,
      iteratedFacility.street_address_2,
      iteratedFacility.city,
      iteratedFacility.province_code,
      iteratedFacility.postal_code,
    ].filter(x => x).join(', ');
    const isChecked = (iteratedFacility.id == facility.id);
    return {name: iteratedFacility.name, facilityAddress, isChecked, facility: iteratedFacility};
  });
});

export const getFacilityType = createSelector([getActiveFacility], (facility) => facility.type);

export const getFacilitiesWithTypeEqualToCurrentFacility = createSelector(
  [getDetailedFacilities, getFacilityType], (facilities, type) => facilities.filter((f) => f.type === type)
);

export const getCurrentFacilityId = createSelector([getActiveFacility],facility => facility.id);
export const getCurrentFacilityName = createSelector([getActiveFacility],facility => facility.name);

export const hasCanadianFacilities = createSelector(
  [getFacilities], facilities => facilities.some(facility => facility.country_code === 'CA')
);

export const hasOklahomaFacilities = createSelector(
  [getFacilities], facilities => facilities.some(facility => facility.province_code === 'OK')
);

export const getCurrentFacilityCountry = createSelector([getActiveFacility], (facility) => facility.country_code || 'US');

export const getActiveFacilityModules = createSelector([getCurrentFacilityModules], (modules) => (Array.isArray(modules) ? modules : []).filter(m => m.enabled));

export const getModuleKey = (_, props) => props && props.moduleKey ? props.moduleKey : '';

export const facilityHasModule = createSelector(
  [getActiveFacilityModules, getModules, getModuleKey],
  (facilityModules, modules, moduleKey) => {
    return facilityModules.reduce((acc, facilityModule) => {
      if(acc) return acc;
      const module = modules && Array.isArray(modules) && modules.find((module) => module.id === facilityModule.module_id);
      if(module && get(module, 'module_key') === moduleKey){
        acc = true;
      }
      return acc;
    }, false);
  });

/**
 * This selector returns a method which should be called with module key as param.
 */
export const getFacilityHasModule = createSelector(
  [getActiveFacilityModules, getModules],
  (facilityModules, modules) => {
    return (module_key) => {
      const module = modules && Array.isArray(modules) && modules.find(module => module.module_key === module_key);
      return module && facilityModules.some(facility_module => facility_module.module_id === module.id);
    };
  }
);

export const isActiveFacilityClosedLoopAdmin = createSelector(
  [getActiveFacility, getFacilityHasModule],
  (facility, hasModule) => facility.type === facilityTypes.regulatorAccess && hasModule('CLOSED_LOOP_REGULATOR_ACCESS')
);

export const isLeafPaConfigPackClosedLoopFacility = createSelector(
  [getActiveFacility],
  (facility) => facility.has_leaf_pa_config_pack_and_closed_loop || false
);

export const isLeafUtahConfigPackClosedLoopFacility = createSelector(
  [getActiveFacility],
  (facility) => facility.has_leaf_utah_config_pack_and_closed_loop || false
);

export const isLeafUtahRegulatorFacility = createSelector(
  [getActiveFacility],
  (facility) => {
    return (isRegulatorFacility(facility) && facility.province_code === 'UT') || false;
  }
);
