import {set} from 'lodash';
import get from 'lodash.get';
import * as dataNames from '../constants/dataNames';
import * as itemNames from '../constants/itemNames';
import {getIntegrationState} from '../selectors/integration/integrationSelectors';
import {getPurchaseOrder, isLeafReturn} from '../selectors/purchaseOrdersSelectors';
import { getSearchData, getUnpaginatedData, postItem, getPaginatedData, getItem, getData, postData } from './apiActions';
import { unsetData, setData, unionData } from './dataActions';
import {setItem} from './itemActions';

export const getPurchaseOrderItemMastersForPartner = (partnerId, myDataNames) => {

  return (dispatch, getState) => new Promise((resolve) => {

    const dnForItemMasters = dataNames[myDataNames.itemMasters]; //eslint-disable-line
    const dnForChildItemMasters = dataNames[myDataNames.childItemMasters]; //eslint-disable-line
    const state = getState();
    const integration = getIntegrationState(state);
    const isReturn = isLeafReturn(state);

    /***
     * Just a function to get from redux so we're consistent
     * @returns {*}
     */
    const getItemMastersFromStore = () => {
      const state = getState(); // Need most recent - can't just use state from entry point of action
      return state[dnForItemMasters];
    };

    /***
     * Gets all child items masters for the loadedItemMasters.  Currently is always handed ONLY the item masters
     * that are already on a purchase order.  This is NOT called for all loaded item masters.  Create loads on demand from the
     * getChildItem masters action below.
     * @returns {boolean}
     */
    const getChildItemMasters = (loadedItemMasters = false) => {
      if(!loadedItemMasters) {
        loadedItemMasters = getItemMastersFromStore();
      }

      const prepackIds = loadedItemMasters.reduce((acc, itemMaster) => {
        const isPrepack = get(itemMaster, 'is_prepack', get(itemMaster, 'inventory_attributes.is_prepack', 0));
        return isPrepack
          ? acc.concat([get(itemMaster, 'inventory_attributes.item_master_id', get(itemMaster, 'id'))])
          : acc;
      }, []);

      if(!prepackIds.length) {
        resolve();
        return false;
      }
      const url = '/api/item_masters/children/items';
      const messages = {failed: 'purchaseOrders.form.failed'};
      dispatch(getUnpaginatedData(url, dnForChildItemMasters, messages, {ids: prepackIds, active: 1}))
        .then(() => {
          resolve();
        });
    };

    /***
     * Check purchase order item master ids against loaded item master ids and back fill if needed.
     * Whether backfilling is necessary or not, load the child item masters for any prepacks.
     * @returns {boolean}
     */
    const ensureAllItemMastersLoadedForPurchaseOrder = (loadedItemMasters = false) => {
      if(!loadedItemMasters) {
        loadedItemMasters = getItemMastersFromStore();
      }
      const getPurchaseOrderItemMastersByIds = (itemMasterIds, loadedItemMasters = false) => {
        if(!loadedItemMasters){
          loadedItemMasters = getItemMastersFromStore();
        }
        return loadedItemMasters.filter((itemMaster) => itemMasterIds.indexOf(itemMaster.id) !== -1);
      };

      const poItemMasterIds = (state[itemNames.purchaseOrder].lines || []).map((line) => line.item_master_id);
      const poItemMasterIdsMissing = poItemMasterIds.filter((id) => {
        const itemMaster = loadedItemMasters.find((itemMaster) => itemMaster.id === id);
        return !itemMaster;
      });

      if(!poItemMasterIdsMissing.length) {
        const itemMasters = getPurchaseOrderItemMastersByIds(poItemMasterIds, loadedItemMasters);
        return getChildItemMasters(itemMasters); // get children for just those on po
      }

      const url = '/api/item_masters/multiple';
      const data = {ids: poItemMasterIdsMissing};
      dispatch(postData(url, data, 'noOp', null, {detailed: 1}))
        .then((purchaseOrderItemMasters) => {
          dispatch(unionData(purchaseOrderItemMasters, dataNames.itemMasters));
          dispatch(unionData(purchaseOrderItemMasters, dnForItemMasters, 'id'))
            .then(() => {
              getChildItemMasters(getPurchaseOrderItemMastersByIds(poItemMasterIds));
            });
        });
    };

    /***
     * Loads all item masters from solr.  Modifies shape to be somewhat consistent with those from service.  Major issue is these
     * DO NOT include vendor which is needed for the create purchase order action.  This is handled on select of an item master within the purchaseOrderform
     * middleware.
     */
    const getAllItemMasters = () => {
      const wasteSubCategoryId = 74;
      const params = {sort: 'name asc, display_name asc', query: 'matchall', size: '100000', start: '0', filter: `item_master_parent_id:0 AND is_draft:0 AND is_ingredient:0 AND !subcategory_id:${wasteSubCategoryId} AND active:1`};
      dispatch(getSearchData('/api/search/item_masters', 'noOp', null, params))
        .then((itemMasters) => {
          const mappedItemMasters = itemMasters.map((itemMaster) => {
            return Object.assign({}, itemMaster, {item_master_id: itemMaster.id}, {
              inventory_attributes: {
                lot_tracked: itemMaster.lot_tracked,
                is_prepack: itemMaster.is_prepack,
                item_master_parent_id: itemMaster.item_master_parent_id,
                prepack_weight_id: itemMaster.prepack_weight_id
              }
            });
          });
          dispatch(setData(mappedItemMasters, dnForItemMasters));
          dispatch(setData(mappedItemMasters, dataNames.itemMasters)); // Nuts but necessary for now... refactor this out
          ensureAllItemMastersLoadedForPurchaseOrder(mappedItemMasters); // This then gets child item masters for those in the po already
        });
    };

    //// MAIN

    if(integration.isPaLeaf && isReturn){
      getAllItemMasters();
    } else {
      const unsetDataNames = [dataNames.vendorItemMasters, dataNames.vendorChildItemMasters, dataNames.itemMasters, dataNames.childItemMasters];
      unsetDataNames.forEach((dn) => unsetData(dn));
      dispatch(getAllPurchasedItemMastersByPartner(partnerId, [dnForItemMasters, dataNames.itemMasters]))
        .then((itemMasters) => {
          ensureAllItemMastersLoadedForPurchaseOrder(itemMasters);
        });
    }

  });

};

/***
 * Gets po lineage which contains the downward lineage to children.
 * Follows the parent_id path upward to get purchase order antecedents.
 * @param purchaseOrderId
 * @returns {function(*=, *): Promise}
 */
export const getPurchaseOrderHistoryData = (purchaseOrderId) => {

  return (dispatch, getState) => new Promise((resolve, reject) => {

    dispatch(getItem(`/api/purchase_orders/${purchaseOrderId}/lineage`, itemNames.purchaseOrderLineage, {failed: 'orders.get.fail'}))
      .then((purchaseOrderLineage) => {

        const getChildIds = (purchaseOrder) => { // Recursively get child ids from each children array
          if (purchaseOrder.children && Array.isArray(purchaseOrder.children) && purchaseOrder.children.length) {
            return purchaseOrder.children.reduce((acc, child) => {
              return acc.concat({id: child.id, created_at: child.created_at, child: true}).concat(getChildIds(child));
            }, []);
          }
          return [];
        };

        const getParentIds = (purchaseOrder) => {
          if(purchaseOrder.parent){
            return [{id: purchaseOrder.parent.id, created_at: purchaseOrder.parent.created_at, child: false}].concat(getParentIds(purchaseOrder.parent));
          }
          return [];
        };

        const purchaseOrderIds = [{
          id: purchaseOrderLineage.id,
          created_at: purchaseOrderLineage.created_at,
          child: null,
        }].concat(getChildIds(purchaseOrderLineage)).concat(getParentIds(purchaseOrderLineage));


        const returnData = {
          purchaseOrder: false,
          itemMasterIds: [],
        };

        const loadItemMasters = () => {
          const url = '/api/item_masters/multiple';
          const data = {ids: returnData.itemMasterIds};
          dispatch(postData(url, data, dataNames.itemMasters, null, {detailed: 1}))
            .then((purchaseOrderItemMasters) => {
              const prepackIds = purchaseOrderItemMasters.reduce((acc, itemMaster) => {
                const isPrepack = get(itemMaster, 'is_prepack', get(itemMaster, 'inventory_attributes.is_prepack', 0));
                return isPrepack
                  ? acc.concat([get(itemMaster, 'inventory_attributes.item_master_id', get(itemMaster, 'id'))])
                  : acc;
              }, []);
              if(prepackIds.length) {
                const url = '/api/item_masters/children/items';
                const messages = {failed: 'purchaseOrders.form.failed'};
                dispatch(getUnpaginatedData(url, dataNames.childItemMasters, messages, {ids: prepackIds}));
              }
            });
        };

        const loadPurchaseOrder = (purchaseOrderId, isLastPurchaseOrder = false, isFirstPurchaseOrder = false) => {
          const storeName = isFirstPurchaseOrder ? itemNames.purchaseOrder : null;
          dispatch(getItem(`/api/purchase_orders/${purchaseOrderId}`, storeName, {failed: 'orders.get.fail'}))
            .then((purchaseOrder) => {
              if(purchaseOrder.id === parseInt(purchaseOrderId)){
                returnData.purchaseOrder = purchaseOrder;
              }
              dispatch(unionData([purchaseOrder], dataNames.purchaseOrders, 'id'));
              if(purchaseOrder.lines && Array.isArray(purchaseOrder.lines)){
                returnData.itemMasterIds = returnData.itemMasterIds.concat(purchaseOrder.lines.map((line) => line.item_master_id));
                if(isLastPurchaseOrder){
                  dispatch(getData(`/api/purchase_orders/${purchaseOrderId}/events`, 'noOp'))
                    .then((purchaseOrderEvents) => {
                      dispatch(unionData([{id: purchaseOrderId, events: purchaseOrderEvents}], dataNames.purchaseOrderEvents, 'id'));
                      const itemMasterIds = purchaseOrderEvents.reduce((acc, event) => {
                        let model;
                        try {
                          model = JSON.parse(event.model);
                        } catch(e) {
                          model = {};
                        }
                        return acc.concat((model.lines || []).map((line) => line.item_master_id));
                      }, []);
                      returnData.itemMasterIds = returnData.itemMasterIds.concat(itemMasterIds);
                      loadItemMasters();
                      resolve(returnData);
                    });
                }
              }
            });
        };

        // Iterates to get parents
        purchaseOrderIds.forEach((purchaseOrderId, index) => {
          loadPurchaseOrder(purchaseOrderId.id, index === purchaseOrderIds.length - 1, index === 0);
        });

      });

  });

};

/***
 * Gets all item masters for a given partner in calls of 100 item masters or less each iterating and unioning into redux store.
 * @param partnerId
 * @param stores
 * @returns {function(*=): Promise}
 */
export const getAllPurchasedItemMastersByPartner = (partnerId, stores = [], keepStore = false) => {

  return (dispatch) => new Promise((resolve) => {

    const getPurchasedItemMastersByPartnerInPages = (partnerId, page = 1) => {

      // In some instances, we want to keep the previous itemMasters already in the store.
      if(page === 1 && !keepStore){ // Change of partner...
        stores.forEach((store) => {
          dispatch(unsetData(store));
        });
      }

      const getParams = (page) => {
        // simple is new param to keep details as simple as possible - only read in combination with detailed and at this point only in purchasing context
        const params = {page, detailed: 1, simple: 1};
        if(partnerId) params.vendor_id = partnerId;
        return params;
      };

      const messages = {failed: 'purchaseOrders.form.failed'};
      const url = `/api/item_masters/purchasing`;
      dispatch(getPaginatedData(url, null, messages, getParams(page), null, null, {resolveRaw: true}))
        .then((response) => {
          const useChained = false; // In case we find production needs them chained - but it shouldn't
          let allItemMasters = response.data.data;
          let callsComplete = 1; // we've already completed 1 call
          stores.forEach((store) => {
            dispatch(unionData(allItemMasters, store));
          });
          // Get data by pages from service all at once
          if(!useChained){
            if(response.data.last_page === 1) {
              resolve(allItemMasters);
              return false;
            }
            for(let n = 2; n < response.data.last_page + 1; n++){
              dispatch(getPaginatedData(url, null, messages, getParams(n), null, null))
                .then((itemMasters) => {
                  stores.forEach((store) => {
                    dispatch(unionData(itemMasters, store));
                  });
                  allItemMasters = allItemMasters.concat(itemMasters);
                  callsComplete++; // async count to make sure we don't fire follow on until everything is loaded
                  if(callsComplete >= response.data.last_page){
                    resolve(allItemMasters);
                  }
                });
            }
          } else { // Chain pages so only one request runs at a time
            if (response.data.current_page < response.data.last_page) {
              getPurchasedItemMastersByPartnerInPages(partnerId, page + 1);
            } else {
              resolve(response);
            }
          }
        });
    };

    getPurchasedItemMastersByPartnerInPages(partnerId);

  });

};

/**
 * Method updates a Purchase Order in Redux store
 * @param values
 * @returns {Function}
 */
export const updatePurchaseOrder = (values) => (dispatch, getState) => {
  const purchaseOrder = getPurchaseOrder(getState());

  return dispatch(setItem({
    ...purchaseOrder,
    ...values,
  }, itemNames.purchaseOrder));
};


/**
 * Method updates a specific Line in Purchase Order in Redux state
 * @param index - and ordering key of line
 * @param lineValues - updating values
 * @returns {Function}
 */
export const updatePurchaseOrderLine = (index, lineValues) => (dispatch, getState) => {
  const purchaseOrder = getPurchaseOrder(getState());
  const lines = get(purchaseOrder, 'lines', []);

  set(lines, index, {
    ...get(lines, index, {}),
    ...lineValues,
  });

  return dispatch(updatePurchaseOrder({
    ...purchaseOrder,
    lines,
  }));
};

export const getChildItemMasters = (itemMasterId, dnForItemMasters = false, dnForChildItemMasters = false) => {
  return (dispatch, getState) => new Promise((resolve) => {
    if(!dnForItemMasters) dnForItemMasters = dataNames.itemMasters;
    if(!dnForChildItemMasters) dnForChildItemMasters = dataNames.childItemMasters;
    const itemMaster = getState()[dnForItemMasters].find((itemMaster) => itemMaster.id === itemMasterId);
    if(!itemMaster || !get(itemMaster, 'is_prepack', get(itemMaster, 'inventory_attributes.is_prepack', 0))) {
      resolve(false);
      return false;
    }
    const url = '/api/item_masters/children/items';
    const messages = {failed: 'purchaseOrders.fetch.childItemMasters.failed'};
    dispatch(getUnpaginatedData(url, null, messages, {ids: [itemMaster.id], active: 1}))
      .then((childItemMasters) => {
        dispatch(unionData(childItemMasters, dnForChildItemMasters))
          .then(() => {
            resolve();
          });
      });
  });
};

export const isReceivablePO = (id) => {
  return (dispatch) => new Promise((resolve, reject) => {
    return dispatch(postItem(`/api/purchase_orders/${id}/can_receive`, {}, 'noOp', {failedHandler: () => {}}))
      .then(() => resolve())
      .catch(() => reject());
  });
};

export default getPurchaseOrderItemMastersForPartner;
