import isEmpty from 'lodash.isempty';
import * as itemNames from '../constants/itemNames';
import * as dataNames from '../constants/dataNames';
import {getCurrentUser} from '../selectors/usersSelectors';
import {getConstants} from '../selectors/constantsSelectors';
import {getItem, getUnpaginatedData} from '../actions/apiActions';
import {fetchUseEntityLocks} from './core/settingsActions';
import {hasStateIntegrator} from '../selectors/integrationSelectors';

export const loadConstants = () => (dispatch, getState) => {
  const state = getState();
  const user = getCurrentUser(state);
  if (!isEmpty(user)) {
    if (isEmpty(getConstants(state))) {
      dispatch(getItem('/api/settings/constants', itemNames.constants));
    }
  }
};

export const loadNotifications = () => (dispatch, getState) => {
  const state = getState();
  const user = getCurrentUser(state);
  if (!isEmpty(user)) {
    dispatch(getUnpaginatedData(`/api/notifications/active/user/${user.id}`, dataNames.notifications));
  }
};

// This only fires on page refresh, and not on login because the facility hasn't been selected yet, which IS the
// intended behavior. fetchUseEntityLocks() is also called on facility selection in the facilities middleware
export const loadUseEntityLocks = () => (dispatch, getState) => {
  const state = getState();
  const user = getCurrentUser(state);
  if (!isEmpty(user)) {
    if (hasStateIntegrator(state)) {
      dispatch(fetchUseEntityLocks());
    }
  }
};
