import {I18n} from 'react-redux-i18n';
import {set} from 'lodash';
import get from 'lodash.get';
import moment from 'moment';
import {requiredFieldValidation} from '../common/form/redux-form/validations';
import {findInventoryItem, inventoryHasEnoughQty} from '../../selectors/ordersSelectors';
import {formatClientDate} from '../../util/dateHelpers';

const getPackageCode = (inventoryItem) => (inventoryItem && inventoryItem.package_code) ? inventoryItem.package_code : 'selected package';
const getQtyErrorText = (inventoryItem) => I18n.t('cart.items.qty_exceeded_for_package', {packageCode: getPackageCode(inventoryItem)});
const getExpiredItemErrorText = (expDate) => I18n.t('cart.items.expiration_date_passed_for_package', {expDate: expDate});

export const validateCartForm = (values, props) => {
  const {inventoryItems, negativeInventoryAllowed, blockExpiredProductAction, isOhMetrc, integrationState, testResults, itemMasters} = props;
  const {isIsolocity, hasIntegrator} = integrationState;
  const errors = {products: []};

  if (!values.products) {
    return errors;
  }

  if(isOhMetrc) {
    values.products.forEach((product, index) => {
      const product_days_supply = (product.days_supply || product.days_supply === 0) ? '' + product.days_supply : product.days_supply;
      const days_supply = requiredFieldValidation(product_days_supply, 'cart.form.daysSupply');
      const partial_fill_indicator = requiredFieldValidation(product.partial_fill_indicator, 'cart.form.partialFillIndicator');
      set(errors, `products[${index}].partial_fill_indicator`, partial_fill_indicator);
      set(errors, `products[${index}].days_supply`, days_supply);
    });
  }

  // Isolocity takes effect only if there is no state integration
  if (isIsolocity && !hasIntegrator) {
    const isolocity_status_in_progress = get(props.constants, `integration.isolocity.statuses.in_progress`);
    const isolocity_status_passed = get(props.constants, `integration.isolocity.statuses.passed`);

    values.products.forEach((product, index) => {
      if (product.addItem && product.addItem.item_id) {
        const item_master = itemMasters && Object.values(itemMasters).find(im => im.id === product.item_master_id);
        const is_medicated = item_master && item_master.is_medicated;
        if (is_medicated) {
          // Search for the Lab result and it's Isolocity status
          const itemResults = testResults.find(i => i.item_id === product.addItem.item_id);
          const isolocityStatus = get(itemResults, 'lab_result.isolocity_status');
          let errorText;
          if (!isolocityStatus) {
            errorText = I18n.t('cart.isolocity.noLabResults');
          } else if (isolocityStatus === isolocity_status_in_progress) {
            errorText = I18n.t('cart.isolocity.pendingLabResults');
          } else if (isolocityStatus !== isolocity_status_passed) {
            errorText = I18n.t('cart.isolocity.failedLabResults');
          }
          if (errorText) {
            set(errors, `products[${index}].addItem.item_id`, errorText);
          }
        }
      }
    });
  }

  // Check if selected packages has enough quantity:
  if (!negativeInventoryAllowed) {
    values.products.forEach((product, index) => {
      if (product.addItem && product.addItem.item_id) {
        const inventoryItem = findInventoryItem(product.addItem.item_id, inventoryItems);
        const hasEnoughQty = inventoryHasEnoughQty(inventoryItem, product.addItem.qty);
        if (!hasEnoughQty) {
          set(errors, `products[${index}].addItem.qty`, getQtyErrorText(inventoryItem));
        }
      }
    });
  }

  // If inventory compliance setting is set to 'block' set error for package if expired
  if (blockExpiredProductAction === 'block') {
    validateExpirationDate(errors, values.products, inventoryItems);
  }

  if (isOhMetrc) {
    errors.refill_number = requiredFieldValidation(values.refill_number, 'cart.form.refillNumber');
  }

  return errors;
};

export const warnCartForm = (values, props) => {
  const {inventoryItems, blockExpiredProductAction} = props;
  const warnings = {products: []};

  if (!values.products) {
    return warnings;
  }

  // If inventory compliance setting is set to 'warn' set warning for package if expired
  if (blockExpiredProductAction === 'warn') {
    validateExpirationDate(warnings, values.products, inventoryItems);
  }
  return warnings;
};

const validateExpirationDate = (validationMessages, products, inventoryItems) => {
  products.forEach((product, index) => {
    if (product.addItem && product.addItem.item_id) {
      const inventoryItem = findInventoryItem(product.addItem.item_id, inventoryItems);
      const package_expires_at = get(inventoryItem, 'package_expires_at');
      if (package_expires_at && package_expires_at <= moment().format('YYYY-MM-DD')) {
        set(validationMessages, `products[${index}].addItem.item_id`, getExpiredItemErrorText(formatClientDate(package_expires_at)));
      }
    }
  });

};

export default validateCartForm;

