import React from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect'
import {Field, FormSection} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';

import * as p from '../../../../constants/permissions';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import TextInput from '../../../common/form/TextInput';
import PasswordInput from '../../../common/form/PasswordInput';
import PermissionWrapper from '../../../common/PermissionWrapper';
import InlineCheckBox from '../../../common/form/InlineCheckBox';
import { userIsMJPAdmin } from '../../../../selectors/usersSelectors'
import { isFeatureEnabled } from '../../../../selectors/featureToggles';

const yesNoOptions = [
  {text: I18n.t('cultivation.complianceSettings.yes'), value: '1'},
  {text: I18n.t('cultivation.complianceSettings.no'), value: '0'},
];
const barcodeSources = [
  {text: 'State Integration Tracking ID', value: 'state_integration_tracking_id'},
  {text: 'Platform generated package code', value: 'platform_generated_package_code'}
];

const BiotrackIntegratorSettings = (props) => {
  const {integrator, integratorName, biotrackStates, userIsMJPAdmin, isProductMasterToggled} = props;

  const override = integrator.value && integrator.value.override_environment_url || false;
  return (
    <Col xs={12}>
      <h3>{I18n.t('stateIntegrators.form.biotrackConfiguration')}</h3>
      <FormSection name={`${integratorName}.value`}>
        <Row>
          <Col sm={6} md={4} lg={3}>
            <Field name='username' component={TextInput} props={{
              label: I18n.t('stateIntegrators.form.username'),
              isRequired: true,
            }}/>
          </Col>
          <Col sm={6} md={4} lg={3}>
            <Field name='password' component={PasswordInput} props={{
              label: I18n.t('stateIntegrators.form.password'),
              isRequired: true,
            }}/>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={4} lg={3}>
            <Field name='state' component={ReactSelectInput} props={{
              label: I18n.t('stateIntegrators.form.state'),
              isRequired: true,
              options: biotrackStates,
            }}/>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={4} lg={3}>
            <Field name='ubi' component={TextInput} props={{
              label: I18n.t('stateIntegrators.form.ubi'),
              isRequired: true,
            }}/>
          </Col>
          <Col sm={6} md={4} lg={3}>
            <Field name='location_number' component={TextInput} props={{
              label: I18n.t('stateIntegrators.form.locationNumber'),
              isRequired: true,
            }}/>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={4} lg={3}>
            <Field name='require_patient_card_lookup' component={ReactSelectInput} props={{
              label: I18n.t('stateIntegrators.form.requirePatientCardLookup'),
              isRequired: true,
              options: yesNoOptions,
              disabled: !userIsMJPAdmin,
            }}/>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={4} lg={3}>
            <Field name='is_training_mode' component={ReactSelectInput} props={{
              label: I18n.t('stateIntegrators.form.trainingMode'),
              isRequired: true,
              options: yesNoOptions,
            }}/>
          </Col>
          <Col sm={6} md={4} lg={3}>
            <Field name='setup_mode' component={ReactSelectInput} props={{
              label: I18n.t('stateIntegrators.form.setupMode'),
              isRequired: true,
              options: [
                {text: 'Yes', value: true},
                {text: 'No', value: false},
              ]
            }}/>
          </Col>
        </Row>
        <PermissionWrapper
          permissions={[p.super_admin_only]}
          alternateComponent={override ?
            <Row>
              <Col sm={6} md={4} lg={3}>
                <Field name='url' component={TextInput} props={{
                  label: I18n.t('stateIntegrators.form.url'),
                  disabled: true
                }}/>
              </Col>
            </Row>
            : null
        }>
          <Row>
            <Col sm={8} md={6} lg={4}>
              <Field name='url' component={TextInput} props={{
                label: I18n.t('stateIntegrators.form.url'),
                disabled: !override,
                isRequired: override,
              }}/>
            </Col>
            <Col sm={4} md={2} lg={2}>
              <Field name='override_environment_url' component={InlineCheckBox} props={{
                label: I18n.t('stateIntegrators.form.overrideEnvironmentUrl'),
              }}/>
            </Col>
          </Row>
          <Row>
            <Col sm={6} md={4} lg={3}>
              <Field name='is_fifteen_day_window' component={InlineCheckBox} props={{
                label: I18n.t('stateIntegrators.form.fifteenDayWindow'),
                disabled: true
              }}/>
            </Col>
          </Row>
          {(isProductMasterToggled) ? <Row>
            <Col sm={6} md={4} lg={3}>
              <Field name='package_barcode_source' component={ReactSelectInput} props={{
                options: barcodeSources,
                label: I18n.t('stateIntegrators.form.barcodeSource'),
              }}/>
            </Col>
          </Row> : null}
        </PermissionWrapper>
      </FormSection>
    </Col>
  );
};

BiotrackIntegratorSettings.propTypes = {
  integrator: PropTypes.object.isRequired,
  integratorName: PropTypes.string.isRequired,
  biotrackStates: PropTypes.array.isRequired,
  userIsMJPAdmin: PropTypes.bool.isRequired,
  isProductMasterToggled: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    userIsMJPAdmin: userIsMJPAdmin(state, 'super_admin_only'),
    isProductMasterToggled: isFeatureEnabled(state)('feature_new_product_master'),
  }
}

export default connect(mapStateToProps)(BiotrackIntegratorSettings);
