import axios from 'axios';
import moment from 'moment-timezone';
import ReactGA from 'react-ga';
import {I18n} from 'react-redux-i18n';
import {startSpinner, setToken} from './systemActions';
import * as types from '../constants/actionTypes';
import {logout} from './userActions';
import Config from '../managers/config';

const {get, post} = axios;

export function getFacilitiesSuccess (facilities) {
  return {type: types.GET_FACILITIES_SUCCESS, facilities};
}

export function getFacilitiesFailed (error) {
  return {type: types.GET_FACILITIES_FAILED, error};
}

export function updateFacility (facility) {
  return {type: types.UPDATE_FACILITY, facility};
}

export function updateFacilityOrganization (organization) {
  return {type: types.UPDATE_FACILITY_ORGANIZATION, organization};
}

export function setFacilitySwitch(inProgress, forPeripheralData = false) {
  const message = I18n.t(`facility.${forPeripheralData ? 'loadingPeripheralData' : 'loadingFacility'}`);
  return {type: types.FACILITY_SWITCH_IN_PROGRESS, payload: {inProgress, message}};
}

/**
 * Action creator for a successful Facility load. The ancillary parameter determines whether
 * the action is being dispatched for ancillary browser tabs that are open in addition
 * to the browser tab the user is active working in.
 *
 * The ancillary parameter is a temporary measure until a full audit of Facility loading
 * and switching takes place and better solutions are devised (a massive undertaking)
 *
 * @param payload
 * @param ancillary
 * @return {{payload, type: string}}
 */
export function setFacilitySuccess (payload, ancillary) {
  return {type: types.SET_FACILITY_SUCCESS, payload, ancillary};
}

export function setFacilityFailed (error) {
  return {type: types.SET_FACILITY_FAILED, error};
}

export function setFacilitySubmissionStatus (status) {
  return { type: types.SET_FACILITY_SUBMISSION_STATUS, status };
}


export function setTimezone(timezone) {
  timezone = timezone !== undefined && timezone !== null ? timezone : moment.tz.guess();
  return {type: types.SET_TIMEZONE, timezone};
}

export function setFacilityOrganization(id, errorMessage) {
  return (dispatch, getState) => {
    const {headers} = getState();

    return new Promise((resolve,reject) => {
      if(!id){
        dispatch(setFacilityFailed({messages:[errorMessage]}));
        reject(true);
      }else{
        get(`/api/organizations/${id}`, {headers})
          .then(response => {
            dispatch(updateFacilityOrganization(response.data));
            resolve(true);
          }).catch(error => {
            if (error.response && error.response.data && error.response.data.messages) {
              dispatch(setFacilityFailed(error.response.data));
            }else if (error.response && error.response.data && error.response.data.errors) {
              const message = error.response.data.errors;
              dispatch(setFacilityFailed({messages:[message]}));
            } else {
              dispatch(setFacilityFailed({messages:[error]}));
            }
            reject(true);
          });
      }
    });
  };
}

export function setFacility(facility) {
  if (facility && Config.setting('ga.enabled')) {
    ReactGA.set({dimension3: facility.id});
    ReactGA.set({dimension4: facility.key});
  }

  return (dispatch, getState) => {
    dispatch(startSpinner());
    dispatch(setFacilitySwitch(true));

    const {headers} = getState();
    return post('/api/auth/set_facility/', facility, {headers})
      .then(response => {
        dispatch(setFacilitySwitch(false));
        const token = response.data.new_token;
        const isValidToken = response.data.token_is_valid;
        if (token && isValidToken) {
          const payload = {
            facility,
            isValidToken
          };
          dispatch(setToken(token));
          dispatch(setFacilitySuccess(payload));
        }
        else {
          dispatch(setFacilityFailed({messages:['error.invalidTokenFromServer']}));
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          dispatch(logout());
        } else if (error.response && error.response.data && error.response.data.messages) {
          dispatch(setFacilityFailed(error.response.data));
        } else if (error.response && error.response.data && error.response.data.errors) {
          dispatch(setFacilityFailed({messages: Object.values(error.response.data.errors)}));
        } else {
          dispatch(setFacilityFailed({messages:[error]}));
        }

        dispatch(setFacilitySwitch(false));
      });
  };
}

export function locationChangeWithoutFacility() {
  return {type: types.LOCATION_CHANGE_WITHOUT_FACILITY};
}
