import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Button, Col, Row} from 'react-bootstrap';

import ReactSelectInput from '../../../common/form/ReactSelectInput';
import TextInput from '../../../common/form/TextInput';
import NumericInput from '../../../common/form/NumericInput';
import AddItem from '../../../common/form/AddItem';
import EquipmentImage from './EquipmentImage';
import ReduxTagField from '../../../common/form/redux-form/ReduxTagField';
import InternationalCurrencyInput from '../../../common/form/InternationalCurrencyInput';
import InternationalDecimalInput from '../../../common/form/InternationalDecimalInput';

const EquipmentItemsFieldArray = (props) => {
  const {fields: {push, map, pop, length}, vendorOptions, uomOptions, onAddVendor,
    equipmentTypes, images, addImageRef, uploading, changeUploadStatus, change, setImage, serviceTypes, modifyMode} = props;
  return (
    <div className='harvest-package'>
      {map((equipment, index) => {
        return (
          <div className='add-equipment-wrapper' key={index}>
            <Row>
              <Col md={12}>
                <h3>{I18n.t('equipment.form.equipmentProfile')}</h3>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Field name={`${equipment}.equipType`} component={ReactSelectInput} props={{
                  label: I18n.t('equipment.form.equipmentType'),
                  options: equipmentTypes,
                  isRequired: true,
                  textKey: 'name',
                  valueKey: 'code'
                }}/>
              </Col>
              <Col md={4} className='vendor-field'>
                <Field name={`${equipment}.vendor`} component={ReactSelectInput} props={{
                  label: I18n.t('equipment.form.vendor'),
                  options: vendorOptions,
                  textKey: 'name',
                  valueKey: 'id',
                  isRequired: true,
                  placeholder: I18n.t('equipment.form.placeholderVendor')
                }}/>
                <Button size='sm' variant='link' className='label-link' onClick={onAddVendor}>
                  {I18n.t('equipment.form.addVendor')}
                </Button>
              </Col>
              <Col md={4}>
                <Field name={`${equipment}.equipModel`} component={TextInput} props={{
                  label: I18n.t('equipment.form.modelName'),
                  isRequired: true,
                  placeholder: I18n.t('equipment.form.placeholderModelName')
                }}/>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <Field
                  name={`${equipment}.lifeExpectancy`}
                  component={NumericInput}
                  props={{
                    label: I18n.t('equipment.form.lifeExpectancy'),
                    fractionPartSize: 0,
                    placeholder: I18n.t('equipment.form.placeholderLifeExpectancy')
                  }}/>
              </Col>
              <Col md={2}>
                <Field name={`${equipment}.uomEquipProfile`} component={ReactSelectInput} props={{
                  label: I18n.t('equipment.form.uom'),
                  options: uomOptions,
                  textKey: 'uom_code',
                  valueKey: 'uom_code',
                  placeholder: I18n.t('equipment.form.placeholderUomEquipProfile')
                }}/>
              </Col>
              <Col md={2}>
                <InternationalCurrencyInput name={`${equipment}.price`}
                  props={{
                    fractionPartSize: 2,
                    label: I18n.t('equipment.form.price'),
                    placeholder: I18n.t('equipment.form.price'),
                  }}
                />
              </Col>
              <Col md={2} className='transparent-addon-right'>
                <InternationalDecimalInput
                  fractionPartSize={0}
                  name={`${equipment}.energyUsage`}
                  props={{
                    label: I18n.t('equipment.form.energyUsage'),
                    rightAddon: <span>{I18n.t('equipment.form.kwh')}</span>
                  }}/>
              </Col>
              <Col md={4}>
                <InternationalDecimalInput
                  name={`${equipment}.hoursPerDay`}
                  props={{
                    label: I18n.t('equipment.form.hoursPerDay'),
                  }}/>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <h3>{I18n.t('equipment.form.maintenance')}</h3>
              </Col>
            </Row>
            <Row className='harvest-package'>
              <Col md={8} key={index}>
                  <Row>
                    <Col md={5}>
                      <ReduxTagField name={`${equipment}.serviceType`} props={{
                        options: serviceTypes,
                        label: I18n.t('equipment.form.serviceType'),
                        textKey: 'tag_name',
                        valueKey: 'tag_name',
                        placeholder: I18n.t('equipment.form.placeholderServiceType')
                      }}/>
                    </Col>
                    <Col md={4}>
                      <Field
                        name={`${equipment}.frequency`}
                        component={NumericInput}
                        props={{
                          label: I18n.t('equipment.form.frequency'),
                          fractionPartSize: 0
                        }}/>
                    </Col>
                    <Col md={3}>
                      <Field name={`${equipment}.uomMaintenance`} component={ReactSelectInput} props={{
                        label: I18n.t('equipment.form.uom'),
                        options: uomOptions,
                        textKey: 'uom_code',
                        valueKey: 'uom_code',
                        placeholder: I18n.t('equipment.form.placeholderUomEquipProfile')
                      }}/>
                    </Col>
                  </Row>
                </Col>
              <Col md={3}>
                <EquipmentImage
                    uploading={uploading}
                    images={images}
                    changeUploadStatus={changeUploadStatus}
                    change={change}
                    addImageRef={addImageRef}
                    setImage={setImage}
                    index={index}
                    name={`${equipment}.image_id`}
                />
              </Col>
            </Row>
          </div>
        );
      })}
      {modifyMode ? null :
      <Row>
        <AddItem
          length={length}
          textMode={'left'}
          alignMode={'right'}
          text={'equipment.form.addEquipment'}
          addAction={() => push({serviceType: []})}
          removeAction={() => {
            setImage('', length - 1);
            pop();
          }}
        />
      </Row>}
    </div>
  );
};

EquipmentItemsFieldArray.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    pop: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    length: PropTypes.number.isRequired
  }).isRequired,
  vendorOptions: PropTypes.array.isRequired,
  uomOptions: PropTypes.array.isRequired,
  serviceTypes: PropTypes.array.isRequired,
  onAddVendor: PropTypes.func.isRequired,
  equipmentTypes: PropTypes.array.isRequired,
  images: PropTypes.array.isRequired,
  uploading: PropTypes.bool.isRequired,
  addImageRef: PropTypes.func.isRequired,
  changeUploadStatus: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  setImage: PropTypes.func.isRequired,
  modifyMode: PropTypes.bool.isRequired,
};

export default EquipmentItemsFieldArray;
