import React from 'react';
import PropTypes from 'prop-types';
import {Field, FieldArray} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Col, FormGroup, Row } from 'react-bootstrap';
import find from 'lodash.find';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import TextInput from '../../common/form/TextInput';
import ContentConcealer from '../../common/concealers/ContentConcealer';
import DriverInformation from './DriverInformation';

const changeVehicle = (value, vehicles, change) => {
  const vehicle = find(vehicles, {id: value});
  change('vehicle_id', value);
  change('vehicle_make', vehicle ? vehicle.make : null);
  change('vehicle_model', vehicle ? vehicle.model : null);
  change('vehicle_license_plate', vehicle ? vehicle.license_number : null);
};

export const DriverAndVehicleInformation = (props) => {
  const {drivers, vehicles, change, createDriver, isReadOnly, isDriverCompany, integrationState} = props;

  const {isPaLeaf, isBiotrack} = integrationState;
  return (
    <FormGroup className='driver-info'>
      <h3>{I18n.t('cultivation.transfers.form.driverInfo')}</h3>
      <FieldArray name='drivers' component={DriverInformation} props={{
        drivers,
        isDriverCompany,
        createDriver,
        isReadOnly,
        integrationState,
      }} />
      <ContentConcealer show={!isDriverCompany}>
        <div>
          <h3>{I18n.t('cultivation.transfers.form.vehicleInfo')}</h3>
          <Row>
            <Col xs={6} sm={4} md={3}>
              <Field name='vehicle_id'
                component={ReactSelectInput}
                props={{
                  label: I18n.t('cultivation.transfers.form.description'),
                  options: vehicles,
                  valueKey: 'id',
                  textKey: 'nickname',
                  onChange: value => changeVehicle(value, vehicles, change),
                  disabled: isReadOnly,
                  isRequired: isPaLeaf || isBiotrack,
                }}
              />
            </Col>
            <Col xs={6} sm={4} md={3}>
              <Field
                name='vehicle_make'
                component={TextInput}
                props={{
                  label: I18n.t('cultivation.transfers.form.make'),
                  disabled: true
                }}
              />
            </Col>
            <Col xs={6} sm={4} md={3}>
              <Field name='vehicle_model'
                component={TextInput}
                props={{
                  label: I18n.t('cultivation.transfers.form.model'),
                  disabled: true
                }}
              />
            </Col>
            <Col xs={6} sm={4} md={3}>
              <Field name='vehicle_license_plate'
                component={TextInput}
                props={{
                  label: I18n.t('cultivation.transfers.form.licensePlate'),
                  disabled: true
                }}
              />
            </Col>
          </Row>
        </div>
      </ContentConcealer>

    </FormGroup>
  );
};

DriverAndVehicleInformation.propTypes = {
  drivers: PropTypes.array.isRequired,
  vehicles: PropTypes.array.isRequired,
  change: PropTypes.func.isRequired,
  createDriver: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
  isDriverCompany: PropTypes.bool.isRequired,
  integrationState: PropTypes.object.isRequired,
};

export default DriverAndVehicleInformation;
