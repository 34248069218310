import * as apiActions from '../apiActions';
import * as dataNames from '../../constants/dataNames';

/**
 * Return
 * @param id
 * @param params
 * @returns {function(*): *}
 */
export const fetchMetrcItems = (id, params = {}) => (dispatch) => {
  return dispatch(apiActions.getSearchData(`/api/metrc/items`, dataNames.metrcProducts, null, params));
};

/**
 * Return
 * @param id
 * @param params
 * @returns {function(*): *}
 */
export const fetchMetrcProductsMapping = (id, params = {}) => (dispatch) => {
  return dispatch(apiActions.getSearchData(`/api/metrc/item_product/mapping`, dataNames.metrcProductsMapping, null, params));
};

/**
 *
 * @param id
 * @param params
 * @returns {function(*): *}
 */
export const fetchMetrcCategoryMapping = (id, params = {}) => (dispatch) => {
  return dispatch(apiActions.getUnpaginatedData('/api/metrc/item_categories/mapping', dataNames.metrcCategoryMappings, null, params));
};

/**
 *
 * @param payload
 * @returns {Function}
 */
export const saveProductManagmentPapping = (payload) => {
  return apiActions.postItem('/api/metrc/item_product/mapping',
    payload,
    dataNames.metrcProductsMapping,
    {success: 'METRCProductManagement.messages.successSave', failed: 'METRCProductManagement.messages.failedSave'}
  );
};

/**
 * Uses on Inventory Receipt for determine if packages are valid for next using
 * @param internal_id = purchase_order_id
 * @returns {*}
 */
export const fetchMetrcTransferPackages = (internal_id) => {
  return apiActions.getUnpaginatedData(`/api/metrc/transfer/${internal_id}/packages`, dataNames.metrcTransferPackages);
};

export default {
  fetchMetrcItems,
};