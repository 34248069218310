import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';
import {connect} from 'react-redux';

import {in_store} from '../../../constants/orderFulfillmentMethods';
import {requiredFieldValidation} from '../../common/form/redux-form/validations';
import StartOrderForm from './StartOrderForm';
import {isFeatureEnabled} from '../../../selectors/featureToggles';
import {isLeafPaConfigPackClosedLoopFacility} from '../../../selectors/facilitiesSelectors';

const validate = (values) => ({
  fulfillment_method_value: requiredFieldValidation(values.fulfillment_method),
});

const StartOrderReduxForm = reduxForm({
  validate,
  enableReinitialize: true
})(StartOrderForm);

function mapStateToProps(state, ownProps) {
  return {
    form: `customer-order-${ownProps.customerId}`,
    initialValues: {
      consumer_id: ownProps.customerId,
      fulfillment_method: in_store,
      is_submitted: 0,
      caregiver_id: ownProps.caregiver && ownProps.caregiver.id
    },
    isMmuEnabled: isLeafPaConfigPackClosedLoopFacility(state) && isFeatureEnabled(state)('feature_leaf_pa_mmu_limits'),
  };
}

const StartOrderFormWrapper = connect(mapStateToProps)(StartOrderReduxForm);

StartOrderFormWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  customerId: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]),
};

export default StartOrderFormWrapper;
