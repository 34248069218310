/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import url from 'url';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {goBack, push} from 'react-router-redux';
import {change, formValueSelector} from 'redux-form';
import moment from 'moment-timezone';

import reduxMetrcIdAvailability, {setUnavailableTrackingIdFieldArrayErrors} from '../../common/form/redux-form/reduxMetrcIdAvailability';
import InProgressOverlay from '../../common/InProgressOverlay';
import FormWrapper from '../../common/form/FormWrapper';
import SplitPackageFormWrapper from './SplitPackageFormWrapper';
import {hasPackagesTags} from '../../../selectors/integrationSelectors';

import {
  getSelectedProduct,
  getSplitPackageInitialValues,
  getSplitPackageSourceUomType,
  getSplitPackageLocations,
  isQASampleSplitDisabled
} from '../../../selectors/assemblyJobsSelectors';
import {getIntegrationState} from '../../../selectors/integration/integrationSelectors';
import {getPartnerFacilities, getPartners} from '../../../selectors/partnersSelectors';
import {unsetData} from '../../../actions/dataActions';
import {fetchMetrcTrackingIdsForSelectInput} from '../../../actions/integrationActions';
import {clearSelectedData, setSelectedData} from '../../../actions/selectedDataActions';
import {addMessage} from '../../../actions/systemActions';
import * as apiActions from '../../../actions/apiActions';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import {convertFormInputDateToDbDate, formatDBDate} from '../../../util/dateHelpers';
import PrinterModal from '../../printer/PrinterModal';
import {isItemProcessable} from '../../../selectors/inventoryItemsSelectors';
import {getActiveFacility} from '../../../selectors/facilitiesSelectors';
import { getFoundPackages } from '../../../selectors/productsSelectors';
import {dispensary} from '../../../constants/facilityTypes';
import {SPLIT_PACKAGE_FORM} from '../../../constants/forms';
import * as messageTypes from '../../../constants/messageTypes';
import {getInventoryComplianceSettings, isInvPackageTrackingNotInCompliance} from '../../../selectors/complianceSettingsSelectors';
import {getUseEntityLocksForItems} from '../../../selectors/coreSettingsSelectors';
import {getConstantsByKey} from '../../../selectors/constantsSelectors';


const formName = SPLIT_PACKAGE_FORM;
const selector = formValueSelector(formName);

export class SplitPackagePage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSearchComplete = this.onSearchComplete.bind(this);
    this.setSplitItem = this.setSplitItem.bind(this);
    this.changePackageLocation = this.changePackageLocation.bind(this);
    this.clearPage = this.clearPage.bind(this);
    this.hidePrinter = this.hidePrinter.bind(this);
    this.doPrint = this.doPrint.bind(this);
    this.getLabResults = this.getLabResults.bind(this);
    this.state = {
      ready: false,
      printerModalShow: false,
      submitting: false,
      showPackageIdScan: true,
      showPackageLocation: false,
      showPackageInfo: false,
      packageItems: [],
      packageId: null,
      printerUrl: '/api/labels/generate/inv_package_tag_all/for/many',
      printerPayload: {},
      printerHttpAction: 'POST',
      printProcess: false,
      sourceUomType: false, // set on search to override selected packages selector which is null in search case
      failedTrackingIdValidation: false
    };
  }

  componentWillMount() {
    const {facility, actions} = this.props;
    const {getUnpaginatedData,getItem} = actions;
    const locations_filter = (facility && facility.type === dispensary) ? {} : {is_sales_location: 0};
    const removeLoader = () => this.setState({loading: false});
    const afterSettingsLoaded = () => {
      const promises = [];
      const {integrationState: {isMetrc, isBiotrack, isIlBiotrack}, selectedProduct} = this.props;
      if (isMetrc) {
        promises.push(this.props.actions.fetchMetrcTrackingIdsForSelectInput());
      }
      if (isBiotrack) {
        if (isIlBiotrack && selectedProduct && selectedProduct.package_id) {
          promises.push(this.getPackageSamples(selectedProduct.package_id));
        }
      }
      return Promise.all(promises);
    };

    this.setState({
      loading: true,
      submitting: false,
    });

    // Check the parameters passed, if we find a package_code already
    // we can skip past the code entry process and submit a lookup on load
    // and try to retrieve the package specified
    const query = this.props.location ? this.props.location.query : {};
    const { package_code } = query;
    if(package_code) {
      this.findPackage(package_code).then((data) => {
        const item = data.find(item => item.package_code == package_code);
        const { packages } = this.props;
        if (item) {
          this.onSearchComplete(item.id, packages);
        }

        // Failed to find package by code, should probably throw a message to user about the error
        // likely if this happens the solr index isn't up to date
      });
    }

    const promises = [
      getUnpaginatedData('/api/users/current_facility', dataNames.currentFacilityUsers, {failed: 'packaging.getUsers.failed'}, {active: 1}),
      getUnpaginatedData('/api/partners', dataNames.partners, {failed: 'partners.get.failed'}, {exclude_internal_duplicates: 1}),
      getUnpaginatedData('/api/partner_facilities', dataNames.partnerFacilities),
      getUnpaginatedData('/api/location_hierarchy', dataNames.locations, {failed: 'locations.get.failed'}, locations_filter),
      getItem('/api/integration-settings', itemNames.integrationSettings, {failed: 'stateIntegratorSettings.get.failed'}).then(afterSettingsLoaded)
    ];

    Promise
      .all(promises)
      .then(removeLoader)
      .catch(removeLoader);
  }

  componentWillUnmount() {
    this.props.actions.unsetData(dataNames.inventoryItems);
  }

  getPackageSamples(id) {
    return this.props.actions.getData('/api/items',dataNames.inventoryItems, undefined, {is_test_package: 1, parent_package_id: id, detailed : 1});
  }

  findPackage(value) {
    this.props.actions.unsetData(dataNames.products);
    const {useEntityLocks = false, filterEntityLockedItems = false, warnEntityLockedItems = false} = this.props;

    if (!useEntityLocks || !filterEntityLockedItems) {
      return this.props.actions.getData('/api/items', dataNames.products, undefined, {package_code: value, detailed : 1});
    }

    const lockedItems = [];
    const filteredItems = [];

    return this.props.actions.getData('/api/items', dataNames.products, undefined, {
      package_code: value,
      detailed: 1,
    }).then((items) => {
      items.forEach((item) => {
        item.is_locked ? lockedItems.push(item) : filteredItems.push(item);
      });
      if (lockedItems.length && warnEntityLockedItems) {
        this.props.actions(lockedItems);
      }
      return this.props.actions.setData(filteredItems, dataNames.products);
    }).then(() => {
      return {unavailableItems: lockedItems, suppressNotFoundMessage: warnEntityLockedItems};
    });
  }

  getLabResults(ids) {
    if (ids.length) {
      const query = 'matchall';
      const size = 10000;
      const sort = 'testing_date desc';
      const filter = ids.reduce((acc, id) => {
        return acc ? acc + ` OR ${id}` : `package_id: (${id}`;
      }, '') + `) AND is_latest_lab_result: 1`;
      const params = {query, size, sort, filter};
      return this.props.actions.getDataByPost('/api/search/lab_results', params, dataNames.testResults);
    } else {
      return Promise.resolve();
    }
  }

  onSearchComplete(packageId, data) {
    const {integrationState: {isLeaf, isBiotrack}} = this.props;
    const items = data.reduce(
      (acc, singlePackage) => acc.concat(singlePackage.items.filter(item => isItemProcessable(item, isLeaf))),
      []
    );
    this.getLabResults(items.map(item => item.package_id)).then(() => {
      if (isBiotrack) {
        const {testResults} = this.props;
        if (testResults.filter(lab_res => lab_res.status === undefined).length) {
          this.props.actions.addMessage(messageTypes.error, ['inventory.splitPackage.pendingLabResultsError', {packageId}]);
          return false;
        }
      }

      this.setState({packageItems: items, showPackageLocation: (items.length > 1), packageId, sourceUomType: items.length ? items[0].uom_type : false}, () => {
        if (items.length === 1) {
          this.setSplitItem(items[0].item_id);
        }
      });
    });

    // Verify that the new scanned item is fit to be split according to compliance settings and feed this as a prop to the form
    if (this.props.complianceSettings.inv_packages_require_tracking_id && Array.isArray(this.props.scannedPackages) && this.props.scannedPackages.length ) {
      const failedTrackingIdValidation = this.props.scannedPackages.some((p) => {
        return isInvPackageTrackingNotInCompliance(p);
      });
      this.setState({failedTrackingIdValidation});
    }
  }

  setSplitItem(id) {
    const items = this.state.packageItems;
    const splitItem = items.find((item) => item.item_id === id);
    if (splitItem) {
      this.props.actions.setSelectedData([splitItem], dataNames.products);
      this.setState({showPackageIdScan: false, showPackageInfo: true});
      this.getPackageSamples(splitItem.package_id);
    }
  }

  clearPage() {
    this.props.actions.clearSelectedData(dataNames.products);
    this.setState({
      ready: false,
      submitting: false,
      showPackageIdScan: true,
      showPackageLocation: false,
      showPackageInfo: false,
      packageItems: [],
      packageId: null,
    });
  }

  onSubmit(formData, type, reset) {
    this.setState({submitting: true});
    const {validateTrackingIdsAvailability} = this.props;
    const newPackages = [];

    formData.new_packages.forEach((new_package) => {
      const {number_of_splits, ...newPackage} = new_package;

      const packagePayload = {
        ...newPackage,
        is_trade_sample: get(newPackage, 'is_trade_sample') || false,
        tag_requested: newPackage.tag_requested === 1 ? 1 : 0,
        finished_at: newPackage.finished && newPackage.finished_at ? moment().format('YYYY-MM-DD HH:mm:ss') : undefined,
        phase_id:
        formData.phase_id,
        package_created_at: formatDBDate(newPackage.package_created_at), // DO NOT NEED TIMEZONE
        package_expires_at: formatDBDate(newPackage.package_expires_at), // DO NOT NEED TIMEZONE
        packaged_at: convertFormInputDateToDbDate(newPackage.packaged_at, this.props.timezone), // DO NOT NEED TIMEZONE
      };

      for(let i = 0; i < number_of_splits; i++){
        newPackages.push(packagePayload);
      }
    });

    const payload = {
      source_item_id: formData.source_item_id,
      new_packages: newPackages,
      event_date: convertFormInputDateToDbDate(formData.event_date, this.props.timezone)
    };

    return validateTrackingIdsAvailability(payload.new_packages, setUnavailableTrackingIdFieldArrayErrors('new_packages')).then(() => {
      this.props.actions.postData('/api/packages/split',
        payload,
        null,
        {
          success: 'cultivation.finishedProduct.form.splitSuccess',
          failed: 'cultivation.finishedProduct.form.splitFailed'
        },
        null,
        (data) => {
          const labResultsId = this.props.labResultsId;
          const references = data.length && labResultsId ? data.map(item => {
            return {
              lab_results_id: labResultsId,
              reference_id: item.id,
              reference_type: 'package'
            };
          }) : [];

          if (references.length) {
            this.props.actions.postItem(`/api/lab_results_references`, references, null, {
              success: 'packaging.references.success',
              failed: 'packaging.references.failed'
            }, {}, () => {
              this.props.actions.postItem('/api/search/update', {core: 'inventory'}, null, {
                success: 'plants.actions.updateSearchSuccess',
                fail: 'plants.actions.updateSearchFail'
              });
            });
          }

          this.setState({submitting: false});

          switch (type) {
          case 'save': {
            // if we have a redirect param in the url we will use this for where
            // we should send people after a successful save
            const query = this.props.location ? this.props.location.query : {};
            let { redirect } = query;

            // Modify the redirect url to pass along the new package_code that was created
            // from the split package, so the receiving page can handle that and make adjustments
            // where needed
            const item = data[0] || null;
            if (item && redirect) {
              const redirect_obj = url.parse(redirect, true);
              redirect_obj.query['package_code'] = item.package_code;
              delete redirect_obj['search'];
              redirect = url.format(redirect_obj);
            }

            if (redirect) {
              this.props.actions.push(redirect);
            } else {
              this.props.actions.goBack();
            }
            break;
          }
          case 'save_and_split_other': {
            this.clearPage();
            reset();
            break;
          }
          case 'save_and_print': {
            const ids = data.map(item => item.new_item_id);
            this.setState({printProcess: true});
            this.doPrint(ids);
            break;
          }
          }
        }
      ).catch(e => {
        this.setState({submitting: false});
      });
    });
  }

  hidePrinter() {
    this.setState({printerModalShow: false});
  }

  doPrint(ids) {
    const newState = {printerModalShow: true};
    if (ids) {
      newState.printerPayload = {ids};
    }
    this.setState(newState);
  }

  changePackageLocation(id) {
    this.setSplitItem(id);
  }

  render() {
    const sourceUomType = this.state.sourceUomType ? this.state.sourceUomType : this.props.sourceUomType;
    const {loading, submitting, showPackageIdScan, showPackageLocation, showPackageInfo, packageItems, printProcess} = this.state;
    const message = `common.form.${submitting ? 'saving' : 'loadingData'}`;
    const {
      locations, metrcTrackingTags, integrationState, partners, partnerFacilities, getFormValue, initialValues,
      currentFacilityUsers, isQASampleDisabled, testSampleIndex, hasPackagesTags, useEntityLocks,
      splitPackageLimitation
    } = this.props;
    const selectedPackage = !!initialValues.package_id;
    return (
      <div>
        <FormWrapper title='inventory.splitPackage.title' className='split-package-page' goBack={this.props.actions.goBack}>
          <InProgressOverlay isActive={loading || submitting} translate={true} message={message}/>
          <PrinterModal
            httpAction={this.state.printerHttpAction}
            labelUrl={this.state.printerUrl}
            showPrinter={this.state.printerModalShow}
            payload={this.state.printerPayload}
            hidePrinter={this.hidePrinter}/>
          <SplitPackageFormWrapper
            onSubmit={this.onSubmit}
            locations={locations}
            initialValues={initialValues}
            metrcTrackingTags={metrcTrackingTags}
            integrationState={integrationState}
            partners={partners}
            partnerFacilities={partnerFacilities}
            onSearchComplete={this.onSearchComplete}
            showPackageIdScan={showPackageIdScan}
            showPackageLocation={showPackageLocation}
            showPackageInfo={selectedPackage || showPackageInfo}
            packageItems={packageItems}
            form={formName}
            getFormValue={getFormValue}
            changePackageLocation={this.changePackageLocation}
            printProcess={printProcess}
            doPrint={this.doPrint}
            isQASampleDisabled={isQASampleDisabled}
            testSampleIndex={testSampleIndex}
            currentFacilityUsers={currentFacilityUsers}
            sourceUomType={sourceUomType}
            hasPackagesTags={hasPackagesTags}
            complianceSettings={this.props.complianceSettings}
            failedTrackingIdValidation={this.state.failedTrackingIdValidation ? true : false}
            useEntityLocks={useEntityLocks}
            splitPackageLimitation={splitPackageLimitation}
          />
        </FormWrapper>
      </div>
    );
  }
}

SplitPackagePage.propTypes = {
  actions: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    clearSelectedData: PropTypes.func.isRequired,
    fetchMetrcTrackingIdsForSelectInput: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired,
  }),
  initialValues: PropTypes.object,
  locations: PropTypes.array.isRequired,
  metrcTrackingTags: PropTypes.array.isRequired,
  partners: PropTypes.array.isRequired,
  partnerFacilities: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
  getFormValue: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
  validateTrackingIdsAvailability: PropTypes.func.isRequired,
  currentFacilityUsers: PropTypes.array,
  sourceUomType: PropTypes.string,
  isQASampleDisabled: PropTypes.bool,
  selectedProduct: PropTypes.object,
  testSampleIndex: PropTypes.number,
  labResults: PropTypes.array,
  hasPackagesTags : PropTypes.bool,
  complianceSettings: PropTypes.object,
  scannedPackages: PropTypes.array,
};

function mapStateToProps(state, props) {
  const {timezone, testResults} = state;
  const getFormValue = (...names) => selector(state, ...names);
  const new_packages = selector(state, 'new_packages') || [];
  const testSampleIndex = new_packages.findIndex(p => p.is_test_package);
  const initialValues = getSplitPackageInitialValues(state);
  const sourceUomType = getSplitPackageSourceUomType(state);
  const locations = getSplitPackageLocations(state);

  // If a quantity is passed to the page, we can forward this on as a default value for new
  // package we are attempting to create with this split
  const query = props.location ? props.location.query : {};
  const { quantity } = query;
  if (quantity) {
    const { new_packages } = initialValues;
    new_packages.forEach((item) => {
      item['transacted_qty'] = quantity;
    });
  }

  return {
    sourceUomType,
    locations,
    metrcTrackingTags: state.trackingIds,
    partners: getPartners(state),
    partnerFacilities: getPartnerFacilities(state),
    labResultsId: initialValues['lab_results_id'],
    initialValues,
    getFormValue,
    timezone,
    currentFacilityUsers: state[dataNames.currentFacilityUsers].map(user => {
      return Object.assign({}, user, {
        fullName: `${user.first_name} ${user.last_name}`
      });
    }).sort((a,b) => (a.first_name.localeCompare(b.first_name))),
    facility: getActiveFacility(state),
    integrationState: getIntegrationState(state),
    isQASampleDisabled: isQASampleSplitDisabled(state),
    selectedProduct: getSelectedProduct(state),
    scannedPackages: state[dataNames.products],
    testSampleIndex,
    testResults,
    hasPackagesTags: hasPackagesTags(state),
    complianceSettings: getInventoryComplianceSettings(state),
    packages: getFoundPackages(state),
    useEntityLocks: getUseEntityLocksForItems(state),
    splitPackageLimitation: getConstantsByKey(state, 'inv.settings.split_package_limitation'),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...apiActions,
    goBack,
    push,
    clearSelectedData,
    addMessage,
    change,
    fetchMetrcTrackingIdsForSelectInput,
    setSelectedData,
    unsetData,
  };
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default reduxMetrcIdAvailability(connect(mapStateToProps, mapDispatchToProps)(SplitPackagePage));
