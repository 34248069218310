/* eslint-disable import/no-named-as-default */
import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Button, Row, Col} from 'react-bootstrap';
import DatePickerInput from '../../../common/form/DatePickerInput';
import ComboboxInput from '../../../common/form/ComboboxInput';
import PostalCodeField from '../../../common/form/redux-form/PostalCodeField';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import TextInput from '../../../common/form/TextInput';
import CustomerIdMethodField from '../../../customers/create-edit/CustomerIdMethodField';

export class AnonymousCustomerForm extends React.PureComponent {

  // touch all invalid fields to prompt error displays
  componentDidUpdate (prevProps) {
    if (this.props.valid || this.props.pristine) { return; }
    this.props.touch(...Object.keys(this.props.formErrors));
  }

  render() {
    return (
      <form className='anonymous-customer-form' onSubmit={this.props.handleSubmit}>
        <Row>
          <Col xs={12}>
            <Field name='first_name' component={TextInput} props={{
              label: I18n.t('customers.create.firstName'),
              placeholder: I18n.t('customers.placeholders.firstName'),
              isRequired: false
            }} />
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <Field name='last_name' component={TextInput} props={{
              label: I18n.t('customers.create.lastName'),
              placeholder: I18n.t('customers.placeholders.lastName'),
              isRequired: false
            }} />
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <Field name='gender' component={ReactSelectInput} props={{
              label: I18n.t('customers.create.gender'),
              options: this.props.genders,
              placeholder: I18n.t('common.form.select'),
              isRequired: false
            }} />
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <Field name='birth_date' component={DatePickerInput} props={{
              label: I18n.t('customers.create.dateOfBirth'),
              isRequired: this.props.requireBirthdateForAnonymousOrders
            }} />
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <PostalCodeField name='postal_code' countryCode={this.props.currentFacility.country_code} />
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <Field name='type' component={ReactSelectInput} props={{
              label: I18n.t('customers.create.type'),
              options: this.props.customerTypes,
              placeholder: I18n.t('common.form.select'),
              isRequired: true
            }} />
          </Col>
        </Row>

        {
          !this.props.allowRecWithExpiredDriversLicense && this.props.customerType === 'recreational'
            ? (<Row>
              <Col xs={12}>
                <div className='text-muted' style={{fontSize: 'smaller', padding: '5px 0'}}>{I18n.t('customers.create.presentDriverLicense')}</div>
              </Col>
              <Col xs={12}>
                <Field name='expiration_date' component={DatePickerInput} props={{
                  label: I18n.t('customers.create.driversLicenseExpirationDate'),
                  isRequired: true
                }} />
              </Col>
            </Row>)
            : null
        }

        <Row>
          <Col xs={12}>
            <Field name='referral_source' component={ComboboxInput} props={{
              label: I18n.t('customers.create.referralSource'),
              options: this.props.referralSources,
              textKey: 'name',
              valueKey: 'id',
              placeholder: I18n.t('common.form.select')
            }} />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {<CustomerIdMethodField name='identification_method'/>}
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <div className='float-left'>
              <Button
                variant='default'
                onClick={this.props.onScanId}
                className='scan-id'
                >
                {I18n.t('common.actions.scanId')}
              </Button>
            </div>
            <div className='float-right'>
              <Button
                onClick={this.props.onFastTrack}
                variant='primary'
                className='fast-track'
                disabled={this.props.submitting || !this.props.valid}
                >
                {I18n.t('orders.anonymous.fastTrack')}
              </Button>

              <Button
                onClick={this.props.onStartOrder}
                variant='primary'
                className='start-order'
                disabled={this.props.submitting || !this.props.valid}
                >
                {I18n.t('orders.anonymous.startOrder')}
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    );
  }
}

AnonymousCustomerForm.propTypes = {
  customerTypes: PropTypes.array.isRequired,
  referralSources: PropTypes.array.isRequired,
  genders: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onFastTrack: PropTypes.func.isRequired,
  onStartOrder: PropTypes.func.isRequired,
  onScanId: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  valid: PropTypes.bool,
  customerType: PropTypes.string,
  allowRecWithExpiredDriversLicense: PropTypes.bool,
};

export default AnonymousCustomerForm;
