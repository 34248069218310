/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {goBack} from 'react-router-redux';
import get from 'lodash.get';
import FormWrapper from '../../../../src/components/common/form/FormWrapper';
import InternationalOperationsFormWrapper from './InternationalOperationsFormWrapper';
import {getInternationalSettingInitialValues} from '../../../selectors/coreSettingsSelectors';
import * as itemNames from '../../../constants/itemNames';
import { getItem, postItem } from '../../../actions/apiActions';
import { setItem } from '../../../actions/itemActions';
import {INTERNATIONAL_SETTINGS} from '../../../constants/forms';
import WillRender from '../../common/concealers/WillRender';

export class InternationalOperationsPage extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      mounted: false
    };
  }

  componentWillMount(){
    this.props.actions
      .getItem('/api/settings/values/by_key', itemNames.internationalSettings, null, { ids: [
        'core_currency_symbol',
        'core_numerical_separator',
        'core_time_format',
        'core_date_format',
        'core_phone_number_include_country_code',
        'core_default_language',
      ]},
      () => {this.setState({ mounted: true});}
    );
  }

  onSubmit(formValues, dispatch) {

    const payload = {
      settings: [
        {setting_key: 'core_currency_symbol', value:  get(formValues, 'currency_symbol'), scope: 'facility', scope_id: this.props.facility.id},
        {setting_key: 'core_numerical_separator', value:  get(formValues, 'numerical_separator'), scope: 'facility', scope_id: this.props.facility.id},
        {setting_key: 'core_time_format', value:  get(formValues, 'time_format'), scope: 'facility', scope_id: this.props.facility.id},
        {setting_key: 'core_date_format', value:  get(formValues, 'date_format'), scope: 'facility', scope_id: this.props.facility.id},
        {setting_key: 'core_phone_number_include_country_code', value:  get(formValues, 'phone_number_include_country_code'), scope: 'facility', scope_id: this.props.facility.id},
        {setting_key: 'core_default_language', value:  get(formValues, 'default_language'), scope: 'facility', scope_id: this.props.facility.id}
      ]
    };

    this.props.actions
      .postItem('/api/settings/values/update_many', payload, null, {})
      .then(() => {
        const internationalSettings = {
          core_currency_symbol: {value: get(formValues, 'currency_symbol')},
          core_numerical_separator: {value: get(formValues, 'numerical_separator')},
          core_time_format: {value: get(formValues, 'time_format')},
          core_date_format: {value: get(formValues, 'date_format')},
          core_phone_number_include_country_code: {value: get(formValues, 'phone_number_include_country_code')},
          core_default_language: {value: get(formValues, 'default_language')},
        };
        this.props.actions.setItem(internationalSettings, itemNames.internationalSettings)
          .then(() => {
            this.props.actions.goBack();
          });
      });
  }

  render() {
    return (
      <FormWrapper title={'internationalSettings.title'} goBack={this.props.actions.goBack}>
        <WillRender ifTrue={this.state.mounted}>
          <InternationalOperationsFormWrapper
            form={INTERNATIONAL_SETTINGS}
            onSubmit={this.onSubmit}
            initialValues={this.props.internationalSettingInitialValues}
          />
        </WillRender>
      </FormWrapper>);
  }
}

InternationalOperationsPage.propTypes = {
  actions: PropTypes.object.isRequired,
  facility: PropTypes.shape({
    id: PropTypes.number.isRequired
  }),
  internationalSettingInitialValues: PropTypes.object
};

function mapStateToProps(state, ownProps){
  const {facility} = state;
  return {
    internationalSettingInitialValues: getInternationalSettingInitialValues(state),
    facility,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    goBack,
    getItem,
    postItem,
    setItem,
  };
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}


export default connect(mapStateToProps, mapDispatchToProps) (InternationalOperationsPage);
