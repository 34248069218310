import {LOCATION_CHANGE} from 'react-router-redux';
import {toastr} from 'react-redux-toastr';
import { I18n } from 'react-redux-i18n';

import * as actionTypes from '../constants/actionTypes';
import * as messageTypes from '../constants/messageTypes';
import {addMessage} from '../actions/systemActions';


export const systemMessages = store => next => action => {
  const result = next(action);
  if (action.type === LOCATION_CHANGE) {
    toastr.removeByType('error');
  }
  return result;
};

export const reduxFormSubmitErrors = store => next => action => {
  const result = next(action);
  if(action.type === actionTypes.REDUX_FORM_SUBMIT_FAILED){
    setTimeout(() => {
      const elements = document.querySelectorAll('[validationstate="error"]');
      const errorMessages = Array.from(elements).map(element => element.innerText);
      const extractedParts = errorMessages.map(message => {
        const parts = message.split('\n');
        return parts.length > 0 ? parts[0] : ''; // Return an empty string if no parts
      });

      if(elements.length){
        const formattedErrorMessages = extractedParts.join(' | ');
        const errorKey = 'systemMessages.generalErrorAlert';
        const errorString = I18n.t(errorKey, {count: elements.length, errors: formattedErrorMessages});
        store.dispatch(addMessage(messageTypes.error, errorString, true));
        const fixedNavBarHeight = 50;
        const arbitraryPadding = 20;
        const additionalOffset = fixedNavBarHeight + arbitraryPadding;
        const top = (elements[0].getBoundingClientRect().top - document.body.getBoundingClientRect().top) - additionalOffset;
        window.scrollTo(0, top);
      }
    }, 1); // 1 ms to allow other processes to run before this so has-error class is present in dom
  }
  return result;
};
