import PropTypes from 'prop-types';
import {reduxForm, formValueSelector} from 'redux-form';
import {connect} from 'react-redux';
import PurchaseOrderForm from '../common/PurchaseOrderForm';
import {validatePurchaseOrderForm as validate} from '../common/validate';
import { getValidFacilityTypesForSupplyChain } from '../../../../selectors/partnersSelectors';
import {getIsPurchaseOrderMedicated} from '../../../../selectors/purchaseOrdersSelectors';

const PurchaseOrderFormWrapper = reduxForm({
  validate,
  enableReinitialize: true,
})(PurchaseOrderForm);

PurchaseOrderFormWrapper.propTypes = {
  form: PropTypes.string.isRequired,
  doSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  partners: PropTypes.array.isRequired,
  partnerFacilities: PropTypes.array.isRequired,
  paymentTerms: PropTypes.array.isRequired,
  paymentTypes: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  sharedLocations: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  isReturn: PropTypes.bool.isRequired,
  itemMasters: PropTypes.array.isRequired,
  lines: PropTypes.array,
  partnerId: PropTypes.number,
  balance: PropTypes.number,
  total: PropTypes.number,
  oddMoney: PropTypes.number,
  isEditMode: PropTypes.bool,
  received: PropTypes.number,
  paymentStatus: PropTypes.string,
  integrationState: PropTypes.object.isRequired,
  forceMedicatedMapping: PropTypes.bool,
  isMappingRequiredByPlatform: PropTypes.bool,
  hasMedicatedLines: PropTypes.bool,
  facilityTypes: PropTypes.array,
  categories: PropTypes.array.isRequired,
};

export default connect((state, props) => {
  const formSelector = formValueSelector(props.form);
  const selectedFacilityId = parseInt(formSelector(state, 'vendor_facility_id'));
  const selectedFacility = props.partnerFacilities.find((facility) => facility.id === selectedFacilityId);
  const hasMedicatedLines = getIsPurchaseOrderMedicated(state, props);
  const inputs = {
    formName: props.form,
    facilityIdField: 'vendor_facility_id',
    partnerFacilities: props.partnerFacilities,
    activeFacilityType: props.activeFacilityType,
  };
  return {
    selectedFacility,
    hasMedicatedLines,
    facilityTypes: getValidFacilityTypesForSupplyChain(state, inputs),
  };
})(PurchaseOrderFormWrapper);
