import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';


export const validate = (values, props) => {

  const orderedProducts    = get(props, 'purchaseOrderInfo.lines', []);
  const errors = {};

  /**
   * 00)  Make sure the purchase order has the same number of products as the transfer
   */
  if (props.purchaseOrderInfo && values.purchase_order_id) {

    // Unique products on transfer
    const uniqueItemMasterNames = props.incomingTransferProductsOptions.reduce((uniqueItemMasterNames, productOption) => {
      if (!uniqueItemMasterNames.includes(productOption.item_master_name)) {
        return uniqueItemMasterNames.concat(productOption.item_master_name);
      }
      return uniqueItemMasterNames;
    }, []);

    // Unique products on purchase order
    const ids = orderedProducts.reduce((ids, line) => {
      //Handle pre-packs
      if (line && line.subitems && line.subitems.length) {
        return line.subitems.reduce(
          (ids, subitem) => (subitem && subitem.qty > 0 ? ids.concat(subitem.item_master_id) : ids),
          ids
        );
      }
      return ids.concat(line.item_master_id);
    }, []);

    // Show error if different number of products on transfer then on purchase order
    if (uniqueItemMasterNames.length !== ids.length) {
      const transferCountMessage = I18n.t('supplyChainMapping.form.transferCount', {count: uniqueItemMasterNames.length});
      const purchaseOrderCountMessage = I18n.t('supplyChainMapping.form.purchaseOrderCount', {count: ids.length});
      errors._error = I18n.t('supplyChainMapping.form.incorrectNumberOfProducts', {transferCountMessage: transferCountMessage, purchaseOrderCountMessage: purchaseOrderCountMessage});
    }

  } else {
    // Clear this error if no purchase order selected
    errors._error = '';
  }

  /**
   * 0)   Make sure all line are mapped, and then keep only the mapped lines to proceed with the rest of the validation
   */
  // Further validate only the lines that are mapped,
  if (orderedProducts.some((ordered) => {
    const incomingProductMap  = get(values, `product_map_${ordered.line_id}`, []);
    return incomingProductMap.length <= 0;
  }) ) {

    // General Form Error - Don't let this error overwrite the error set in the product order numbers validation above
    if (!errors._error) {
      errors._error = I18n.t('supplyChainMapping.form.allTheProductsHaveToBeMapped');
    }
  }


  orderedProducts.forEach(ordered => {
    const incomingProductMap = get(values, `product_map_${ordered.line_id}`, []);
    errors[`product_map_${ordered.line_id}`] = incomingProductMap.map((incoming, index) => {

      /**
       * 1)   If the inventory selected has a different category than the product, do not allow the page to be submitted.
       *      Highlight the Incoming Product’s Inventory Name field in red and display the message underneath: “Categories must match.
       *      Your product category is [X]. Partner category is [X].“
       */
      // note to the delinter: these == were meant to be ==
      if (incoming.line_id == ordered.line_id && incoming.item_master_category_id != ordered.item_master_category_id) {
        return {
          item_master_name : I18n.t('supplyChainMapping.form.errorCategoriesDontMatch', {
            internalCategory: ordered.item_master_category_name,
            incomingCategory: incoming.item_master_category_name
          })
        };
      }

      /**
       * 2)  If the inventory selected has a different pre-pack setting than the product, do not allow the page to be submitted.
       *     Highlight the Incoming Product’s Inventory Name field in red and display the message underneath: “Pre-packs must match.
       *     Your product [is a pre-pack/is not a pre-pack]. Partner category [is a pre-pack/is not a pre-pack].
       */
      // note to the delinter: these == were meant to be ==
      else if (incoming.line_id == ordered.line_id && !!incoming.is_prepack != !!ordered.is_prepack) {
        const incomingPrepack = incoming.is_prepack ? I18n.t('supplyChainMapping.form.isAPrepack') : I18n.t('supplyChainMapping.form.isNotAPrepack');
        const internalPrepack = ordered.is_prepack ? I18n.t('supplyChainMapping.form.isAPrepack') : I18n.t('supplyChainMapping.form.isNotAPrepack');

        return {
          item_master_name : I18n.t('supplyChainMapping.form.errorPrepacksDontMatch', {internalPrepack: internalPrepack, incomingPrepack: incomingPrepack})
        };
      }


      /**
       * 3)  If the inventory selected has a different UOM than the product, do not allow the page to be submitted.
       *     Display the message underneath: “UOMs must match.
       *     Your product UOM is [GR/EA]. Partner UOM is [GR/EA].
       */
      // note to the delinter: these == were meant to be ==
      else if (incoming.line_id == ordered.line_id && incoming.uom_display != ordered.uom_display) {
        return {
          item_master_name : I18n.t('supplyChainMapping.form.uomMismatchError', {internalUom: ordered.uom_display, partnerUom: incoming.uom_display})
        };
      }



      else {
        return {
          item_master_name : undefined
        };
      }
    });
  });


  /**
   * 4) If the sum of the selected inventory’s quantity is less than the product’s quantity, do not allow the page to be submitted.
   *    Highlight the Purchase Order Product’s Quantity field in red and display the message underneath: “Quantity not fulfilled."
   */
  orderedProducts.forEach(ordered => {

    // Do not add error for overall qty if we already have an error for some of selected packages
    const lineErrors = Array.isArray(errors[`product_map_${ordered.line_id}`]) ? errors[`product_map_${ordered.line_id}`] : [];
    const alreadyHasError = lineErrors.some(packageError => packageError.item_master_name);
    if (alreadyHasError) {
      return;
    }

    const incomingProductMap = get(values, `product_map_${ordered.line_id}`, []);
    const totalIncomingQty = incomingProductMap.reduce((total, incoming) => {
      // Using Math.round as when we do not it will occasionally return greater than 2 decimal places, causing a "quantity not fulfilled" error
      return (Math.round((total + incoming.qty_display) * 100) / 100);
    }, 0);

    // Check quantity only of the product is mapped
    if ( incomingProductMap.length > 0 &&  totalIncomingQty < ordered.qty_display ) {
      if (!errors[`product_map_${ordered.line_id}`]) {
        errors[`product_map_${ordered.line_id}`] = {};
      }
      errors[`product_map_${ordered.line_id}`]['_error'] = I18n.t('supplyChainMapping.form.errorQuantityNotFulfilled');
    }
  });

  return errors;
};


export default validate;
