import React from 'react';
import PropTypes from 'prop-types';
//import {I18n} from 'react-redux-i18n';
import PlaceholdersList from '../custom/components/PlaceholdersList';

const DataKeysList = (props) => {

  return (
    <div className='row' style={{marginTop: '12px'}}>
      <div className='col-md-12'>
        <div className='col-md-12' style={{marginBottom: '12px'}}>
          Data Keys are what import the dynamic data for your label.  As such, setting the correct data keys
          is critically important.  In all cases below, use the full dot notated version.  For example,
          product name can be found as simply item_name or can also be found as item_master.item_name.  To use
          the first, simply enter item_name as the data key.  To use the second, you would use item_master.item_name.
        </div>
        <div className='col-md-12' style={{height: '400px', overflow: 'auto'}}>
          <PlaceholdersList label={props.label} activeSource={true} />
        </div>
        <div className='col-md-12'>
          <hr />
          <button
            onClick={(event) => {
              props.onHide();
            }}
            className='btn btn-danger'
          >
            Cancel
          </button>
          <button
            onClick={(event) => {
              props.onSaveModalData();
            }}
            className='btn btn-primary float-right'
          >
            Print
          </button>
        </div>
      </div>
    </div>
  );
};

DataKeysList.propTypes = {
  definitions: PropTypes.array.isRequired,
  currentDefinitionIndex: PropTypes.number.isRequired,
  onSelectNamedDefinition: PropTypes.func,
  onSaveModalData: PropTypes.func.isRequired,
};

export default DataKeysList;
