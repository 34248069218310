import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {bindActionCreators} from 'redux';
import * as dataNames from '../../../constants/dataNames';
import {getData} from '../../../actions/apiActions';
import {convertFromBase} from '../../../util/uomHelpers';
import Pagination from '../../orders/customer/components/Pagination';

class ReceiptDetail extends React.Component {
  constructor (props, context) {
    super(props, context);

    this.state = {
      receipt: {},
      loadingData: false,

      page: 1,
      first: 1,
      last: 0,
      perPage: 5,
      numRecords:0,
    };

    this.onPageChange = this.onPageChange.bind(this);
  }

  componentWillMount() {
    //handle case when receipt details are open automatically
    if (this.props.loadData) {
      this.loadReceiptData();
    }
  }

  componentWillReceiveProps(nextProps) {
    if(!nextProps.loadData) return true; // Don't load unless true
    this.loadReceiptData();
  }

  setPageState(nextProps, pageNumber){
    const numRecords = (this.state.receipt) ? this.state.receipt.length : nextProps.data.length;
    const curPage = (pageNumber === undefined) ? this.state.page : pageNumber;
    const first = (curPage === 1)
      ? 1
      : ((curPage - 1) * this.state.perPage) + 1;

    let last = (curPage === 1)
      ? (numRecords < this.state.perPage)
        ? numRecords
        : this.state.perPage
      : this.state.perPage * (curPage);

    if (last > numRecords) last = numRecords;

    this.setState({
      page: curPage,
      numRecords,
      first,
      last
    });

  }

  onPageChange(event, page){
    event.stopPropagation();
    event.preventDefault();
    const pageNumber = (page.number === undefined) ? page : page.number;
    this.setPageState(this.props, pageNumber);
    this.setState({loadingData: false});
    this.loadReceiptData();
  }

  loadReceiptData() {
    if (!this.state.loadingData) {
      this.setState({loadingData: true});
    } else {
      return true;
    }
    if(Object.keys(this.state.receipt).length > 0) return true; // Don't load already loaded data

    const receiptId = this.props.receiptId;
    const messages = {failed: 'common.failed'};
    const url = `/api/closed_loops/inventory_receipts/${receiptId}`;
    const setInLocalState = (property, data) => {
      this.setState({[property]: data});
    };

    this.props.actions.getData(url, dataNames.closedLoopRegulatedReceipt, messages, {}, (receipt) => {
      setInLocalState('receipt', receipt);
      this.setPageState({data: receipt});
    });
  }

  render () {
    const {page, first, last, perPage, numRecords, receipt} = this.state;
    return (
      <React.Fragment>
        <table className='table table-bordered table-striped product-detail-table'>
          <thead>
            <tr>
              <th style={{width: '20%'}}>{I18n.t('products.form.itemId')}</th>
              <th style={{width: '30%'}}>{I18n.t('products.form.itemNumber')}</th>
              <th style={{width: '30%'}}>{I18n.t('products.form.itemName')}</th>
              <th style={{width: '20%'}}>{I18n.t('products.form.quantity')}</th>
            </tr>
          </thead>
          <tbody>
            {(Object.keys(receipt).length > 0) &&
              receipt.map((item, i) => (i + 1 >= first && i < last
                ? <tr key={i}>
                    <td>{item.item_master_id}</td>
                    <td>{item.item_number}</td>
                    <td>{item.name}</td>
                    <td>{convertFromBase(item.qty_base, item.uom_display)} {item.uom_display}</td>
                  </tr>
                : null)
              )
            }
          </tbody>
        </table>
        {(Object.keys(receipt).length > 0) &&
          <Pagination
            perPage={perPage}
            onPageChange={this.onPageChange}
            first={first}
            last={last}
            numRecords={numRecords}
            page={page}/>
        }
      </React.Fragment>
    );
  }
}

ReceiptDetail.propTypes = {
  receiptId: PropTypes.number.isRequired,
  loadData: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ getData }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptDetail);
