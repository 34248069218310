import React from 'react';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import ComboboxInput from '../../common/form/ComboboxInput';

const CustomerIdMethodField = ({name}) => {
  return (
    <Field name={name || 'customer.identification_method'} component={ComboboxInput}
           props={{
             maxLength: 50,
             options: [
               I18n.t('customers.create.stateIssuedId'),
               I18n.t('customers.create.doctorRecommendation'),
               I18n.t('customers.create.other'),
             ],
             label: I18n.t('customers.create.identificationMethod'),
             placeholder: I18n.t('customers.placeholders.identificationMethod'),
             isRequired: false
           }}/>
  );
}

export default CustomerIdMethodField;
