import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';

import MmapPaymentForm from './MmapPaymentForm';
import validate from './validate';

const MmapPaymentReduxForm = reduxForm({
  validate
})(MmapPaymentForm);

MmapPaymentReduxForm.propTypes = {
  form: PropTypes.string.isRequired,
  payments: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
};

export default MmapPaymentReduxForm;
