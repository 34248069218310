import React from 'react';
import PropTypes from 'prop-types';
import {FieldArray, Field} from 'redux-form';
import { Button, ButtonToolbar, Col, Row, Image } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import FileInput from '../../common/form/FileInput';
import TextInput from '../../common/form/TextInput';
import CheckBoxInput from '../../common/form/CheckBoxInput';
import ExpiredPasswordBlock from './ExpiredPasswordBlock';
import WillRender from '../../common/concealers/WillRender';
import ApiKeys from './ApiKeys';


import {MAX_UPLOAD_SIZE_DISPLAY, FILE_TYPES, COMMON_PUBLIC_IMAGE} from '../../../constants/fileUploads';
import OrganizationFacilitiesFieldArray from './OrganizationFacilitiesFieldArray';
import * as itemNames from '../../../constants/itemNames';

const CreateOrganizationForm = (props) => {
  const {
    handleSubmit, submitting, pristine, organization, organizationDetails, facilities, imageUrl, integrationState,
    changeUploadStatus, uploading, addImageRef, change, retailFacilityTypes, expirePasswordsSettings, enabledPALeafIntegration,
    facilitiesWithWaLeaf, formSelector, valid, touch, renderFlags
  } = props;

  return (
    <div className='facilities-form'>

      <form onSubmit={handleSubmit}>
        <div className='form-container'>
          <div className='organization-form'>
            <Row>
              <Col md={6}>
                <h4>{I18n.t('organizations.createOrganization.organization')}</h4>
                <Row>
                  <Col md={8}>
                    <Field name='name' component={TextInput} props={{
                      label: I18n.t('common.form.name'),
                      value: organization,
                      disabled: true
                    }}/>
                  </Col>
                  <WillRender ifTrue={integrationState.isPaLeaf}>
                    <Col md={8}>
                      <Field name='registry_id' component={TextInput} props={{
                        label: I18n.t('organizations.createOrganization.registryId'),
                        disabled: true
                      }}/>
                    </Col>
                  </WillRender>
                </Row>
                <ExpiredPasswordBlock
                  enabledPALeafIntegration={enabledPALeafIntegration}
                  expirePasswordsSettings={expirePasswordsSettings}
                />
                <WillRender ifTrue={renderFlags.canEnablePublicProfile || false}>
                  <Row>
                    <Col md={8}>
                      <Field name='public_profile_enabled' component={CheckBoxInput} props={{
                        label: I18n.t('organizations.createOrganization.public_profile_enabled') + ' (' + organizationDetails.organization_code + ')',
                        groupClassName: 'inline-checkbox'
                      }}/>
                    </Col>
                  </Row>
                </WillRender>
              </Col>
              <Col md={6}>
                <h4>{I18n.t('organizations.createOrganization.logo')}</h4>
                <Row>
                  <Col md={6}>
                    <Image thumbnail src={imageUrl}/>
                  </Col>
                  <Col md={6}>
                    <p>{I18n.t('common.form.sizeLimit', {limit: MAX_UPLOAD_SIZE_DISPLAY})}</p>
                    <p>{I18n.t('common.form.typeLimit', {extensions: FILE_TYPES.COMMON_PUBLIC_IMAGE.extensions})}</p>
                      <Field
                        name='image'
                        itemName={itemNames.image}
                        component={FileInput}
                        props={{
                          ...FILE_TYPES[COMMON_PUBLIC_IMAGE],
                          disabled: uploading.image_id,
                          btnContent: uploading.image_id ? I18n.t('products.form.uploading') : I18n.t('products.form.uploadImage'),
                          handleRef: addImageRef,
                          onUploadStart: () => changeUploadStatus('image_id', true),
                          onChangeCb: (image) => {
                            change('image_id', image.id);
                            changeUploadStatus(false);
                          },
                          btnProps: {
                            className: 'btn btn-default'
                          }
                        }}/>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col md={12}>
                    <ApiKeys {...props} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        <hr />
        <FieldArray
          name='facilities' component={OrganizationFacilitiesFieldArray}
          props={{
            facilities,
            pristine,
            valid,
            submitting,
            handleSubmit,
            change,
            retailFacilityTypes,
            facilitiesWithWaLeaf,
            formSelector,
            touch,
          }}
        />
        <Row>
          <Col md={12} className='add-item'>
            <ButtonToolbar className='float-right'>
              <Button
                type='submit'
                disabled={submitting || pristine}
                variant='primary'>
                {I18n.t('organization.createOrg.save')}
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>
      </form>
   </div>
  );
};

CreateOrganizationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  imageUrl: PropTypes.string,
  organization: PropTypes.string,
  organizationDetails: PropTypes.object,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  facilities: PropTypes.array.isRequired,
  addImageRef: PropTypes.func.isRequired,
  changeUploadStatus: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired,
  retailFacilityTypes: PropTypes.array.isRequired,
  expirePasswordsSettings: PropTypes.object.isRequired,
  enabledPALeafIntegration: PropTypes.bool.isRequired,
  facilitiesWithWaLeaf: PropTypes.array.isRequired,
  formSelector: PropTypes.func.isRequired,
  valid: PropTypes.bool,
  touch: PropTypes.func.isRequired,
  renderFlags: PropTypes.object.isRequired,
  integrationState: PropTypes.object.isRequired,
};

export default CreateOrganizationForm;
