import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { push } from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import {FaCheck} from 'react-icons/fa';
import get from 'lodash.get';
import moment from 'moment';
import TablePageWrapper from '../common/grid/TablePageWrapper';
import {getUnpaginatedData} from '../../actions/apiActions';
import * as dataNames from '../../constants/dataNames';
import {getClosedLoopFacilitiesForListing} from '../../selectors/closedLoopsSelectors';

class FacilitiesListingPage extends React.Component {

  componentDidMount() {
    this.props.actions.getUnpaginatedData('/api/closed_loops/facilities_administering', dataNames.closedLoopFacilities, null, {type: 'grow'});
  }

  columns() {
    const {push} = this.props.actions;

    return [
      {
        name: 'closedLoop.facility.name',
        dataId: 'name'
      },
      {
        name: 'closedLoop.facility.programDefinedName',
        dataId: 'program_defined_name'
      },
      {
        name: 'closedLoop.facility.stateLicenceNumber',
        dataId: 'state_license_number'
      },
      {
        name: 'closedLoop.facility.key',
        dataId: 'facility_key'
      },
      {
        name: 'closedLoop.facility.code',
        dataId: 'facility_code'
      },
      {
        name: 'closedLoop.facility.type',
        dataId: 'type',
        formatter: (cell) => {
          if (cell) {
            return cell.charAt(0).toUpperCase() + cell.slice(1);
          }
          return '';
        }
      },
      {
        name: 'closedLoop.facility.allowInitialInventory',
        dataId: 'allow_initial_inventory',
        formatter: (cell, row) => {
          if (cell) {
            // Check date
            const date = moment(get(row, 'initialInventoryByDate'));
            const now = moment();
            return (now <= date ? <FaCheck style={{ color: '#6AA84F' }}/> : '');
          }
          return '';
        }
      },
      {
        name: 'closedLoop.facility.initialInventoryByDate',
        dataId: 'initialInventoryByDate'
      },
      {
        name: 'closedLoop.facility.settings',
        dataId: 'settings',
        formatter: (cell, row) => {
          const route = `/closed-loop/facilities/${row.id}`;
          return <Button onClick={() => push(route)}>Edit</Button>;
        }
      }
    ];
  }

  render() {
    const {facilities} = this.props;

    return (
      <React.Fragment>
        <h1>{I18n.t('closedLoop.facility.listingPageTitle')}</h1>

        <TablePageWrapper
          settingKey='closed-loop-facilities'
          columns={this.columns()}
          data={facilities}
          hideSearch={true}
          bstProps={{pagination: false}}
          actions={[]}
          hideScanSearch={true}
          hidePagination={true}
          hideExport={true}
        />
      </React.Fragment>
    );
  }
}

FacilitiesListingPage.propTypes = {
  facilities: PropTypes.array,
  push: PropTypes.func,
  actions: PropTypes.array
};

const mapStateToProps = (state) => ({
  facilities: getClosedLoopFacilitiesForListing(state),
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({getUnpaginatedData, push}, dispatch)
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(FacilitiesListingPage);
