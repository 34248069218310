import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import round from 'lodash.round';
import InternationalCurrencyStatic from '../../../common/form/InternationalCurrencyStatic';

const TotalRows = (props) => {
  const {order, taxes} = props;
  const getLineTaxes = () => { //eslint-disable-line
    const lineTaxes = order.product_taxes.reduce((acc, item) => {
      const productTax = acc.find((el) => el.tax_id === item.tax_id);
      if(!productTax) {
        const {tax_id} = item;
        const tax = taxes.find((el) => el.id === item.tax_id);
        if(tax) acc.push({tax_id, amount: parseFloat(item.amount), tax_rate: tax.amount * 100, tax_name: tax.tax_name});
        return acc;
      }

      return acc.map((el) => {
        if(el.tax_id === item.tax_id) return {...el, amount: round(parseFloat(el.amount) + parseFloat(item.amount), 2)};
        return el;
      });


    }, []);
    if(!lineTaxes.length) return <div>{(0).toFixed(2)}</div>;
    return lineTaxes.map((orderTax, index) => {
      return (
        <div key={`tax-${index}`} style={{textAlign: 'right'}}>
          <span style={{whiteSpace: 'initial'}}>{I18n.t('cart.totals.taxDetailed', {name: orderTax.tax_name, rate: orderTax.tax_rate})}</span>{' '}
          <span className='text-right'><InternationalCurrencyStatic>{orderTax.amount}</InternationalCurrencyStatic></span>
        </div>
      );
    });
  };

  return (
    <thead>
      {order.product_taxes &&
          <tr>
            <th colSpan='3' className={'text-right'}>{I18n.t('cultivation.salesOrders.orderHistory.subTotal')}</th>
            <td className={'text-right'}><InternationalCurrencyStatic>{order.order_total - order.tax_total}</InternationalCurrencyStatic></td>
            <td/>
          </tr>
      }
      {order.product_taxes &&
          <tr>
            <th colSpan='3' className={'text-right'}>{I18n.t('cultivation.salesOrders.orderHistory.taxes')}</th>
            <td className={'text-right'}>{getLineTaxes()}</td>
            <td/>
          </tr>
      }
      <tr>
        <th colSpan='3' className={'text-right'}>{I18n.t('cultivation.salesOrders.orderHistory.total')}</th>
        <td className={'text-right'}><InternationalCurrencyStatic>{parseFloat(order.order_total)}</InternationalCurrencyStatic></td>
        <td/>
      </tr>
    </thead>
  );

};

TotalRows.propTypes = {
  taxes: PropTypes.array.isRequired,
  order: PropTypes.object.isRequired,
};

export default TotalRows;
