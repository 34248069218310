import React from 'react';
import PropTypes from 'prop-types';
import {FieldArray} from 'redux-form';

import AccordionPanel from '../../common/AccordionPanel';
import Package from './Package';

const Lot = ({lots, fields:{map}, change, getFormValue}) => {
  return (
    <div className='form-panel lots'>
      {map((lot, lotIndex) => {
        return (
          <div key={`lot-${lotIndex}`}>
            <AccordionPanel
              title='containers.form.lotTitle'
              titleParams={{id: lots[lotIndex].lot_number}}
              defaultExpanded={lotIndex < 1}
            >
              <FieldArray
                name={`${lot}.packages`}
                component={Package}
                packages={lots[lotIndex].packages}
                change={change}
                getFormValue={getFormValue}
              />
            </AccordionPanel>
          </div>
        );
      })}
    </div>
  );
};

Lot.propTypes = {
  lots: PropTypes.array.isRequired,
  getFormValue: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired
  }).isRequired
};

export default Lot;
