import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { GR } from '../../../constants/uoms';
import InternationalCurrencyStatic from '../../common/form/InternationalCurrencyStatic';

const ProductPrice = ({ itemMaster, isDetailPage = false }) => {
  const uom = I18n.t(`uoms.${itemMaster.default_uom ? itemMaster.default_uom : GR}.abbrev`);
  return (
    <div>
      <Row>
        <Col xs={12}>
          <label>{I18n.t('productMenu.price')}</label>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {itemMaster.pricing_type === 'unit' ? (
            <h4 style={isDetailPage ? {} : {fontSize: '14px', paddingTop: '8px'}}>
              <InternationalCurrencyStatic>{itemMaster.displayPrice}</InternationalCurrencyStatic>
              <span>/{uom}</span>
            </h4>
          ) : (
            <OverlayTrigger
              placement='top'
              overlay={<Tooltip id='pricingMessage'>{I18n.t('productMenu.pricingMessage')}</Tooltip>}
            >
              <h4 style={isDetailPage ? {} : {fontSize: '14px', paddingTop: '8px'}}>
                <InternationalCurrencyStatic>{itemMaster.displayPrice}</InternationalCurrencyStatic>
                <span>/{uom}</span>
              </h4>
            </OverlayTrigger>
          )}
        </Col>
      </Row>
    </div>
  );
};

ProductPrice.propTypes = {
  itemMaster: PropTypes.object.isRequired,
  isDetailPage: PropTypes.bool
};

export default ProductPrice;
