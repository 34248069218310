/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import FormWrapper from '../../common/form/FormWrapper';
import * as dataNames from '../../../constants/dataNames';
import {getUnpaginatedData, postItem} from '../../../actions/apiActions';
import {
getAllowedFacilitiesForSelecting,
getCreateSharedFacilityGroupInitialValues
} from '../../../selectors/facilityGroupsSharingSelectors';
import SharedFacilityGroupFormWrapper from './common/SharedFacilityFormWrapper';
import {CREATE_FACILITY_GROUP_FORM} from '../../../constants/forms';
import * as itemNames from '../../../constants/itemNames';

const initialValues = getCreateSharedFacilityGroupInitialValues();

export class CreateSharedFacilityGroupPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount() {
    this.props.actions.getUnpaginatedData('/api/facilities', dataNames.facilities);
    this.props.actions.getUnpaginatedData('/api/facility_groups_sharings', dataNames.sharedFacilityGroups, {failed: 'failedToGetFacilityGroups'});
  }

  onSubmit(formValues) {
    const payload = {
      name: formValues.name,
      facility_id: formValues.masterFacilityId,
      facilities: formValues.facilities.map(facility => ({facility_id: facility.id})),
    };
    this.props.actions.postItem(
      '/api/facility_groups_sharings',
      payload,
      itemNames.infusion,
      {
        success: 'common.success',
        fail: 'common.failed'
      },
      null,
      this.props.actions.goBack
    );
  }

  render() {
    const {facilities} = this.props;
    return (
      <FormWrapper title={'facilityGroupsSharing.create.createFacilityGroup'} goBack={this.props.actions.goBack}>
        <h5>{I18n.t('facilityGroupsSharing.create.info')}</h5>
        <SharedFacilityGroupFormWrapper
          onSubmit={this.onSubmit}
          facilities={facilities}
          isModifyForm={false}
          initialValues={initialValues}
          form={CREATE_FACILITY_GROUP_FORM}
        />
      </FormWrapper>
    );
  }
}

CreateSharedFacilityGroupPage.propTypes = {
  actions: PropTypes.shape({
    postItem: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired
  }),
  facilities: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  return {
    facilities: getAllowedFacilitiesForSelecting(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({getUnpaginatedData, postItem, goBack}, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (CreateSharedFacilityGroupPage);
