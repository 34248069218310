import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Row} from 'react-bootstrap';
import dominance from '../../../constants/strainDominance';

const PreviewField = (props) => {
  const {field} = props;
  const potentialValue = (field.name === 'dominance')
    ? dominance.find((dom) => dom.value === field.value)
    : field.value;

  const value = field.name === 'dominance'
    ? typeof potentialValue === 'object' && potentialValue.text
      ? potentialValue.text
      : field.value
    : field.value;

  return (
    <div>
      <Row>
        <label>{I18n.t(field.title, {...field.title_attr})}</label>
      </Row>
      <Row>
        {value}
      </Row>
    </div>
  );
};

PreviewField.propTypes = {
  field: PropTypes.object.isRequired
};

export default PreviewField;
