import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import TextInput from '../TextInput';
import {validatePostalCode, countryCodes, getPostalCodeLabel} from '../validatePostalCode';
import {getCurrentFacilityCountry} from '../../../../selectors/facilitiesSelectors';
import {getUsZipPlus4Setting} from '../../../../selectors/settingsSelectors';

class PostalCodeField extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.state = countryCodes;
    this.validate = this.validate.bind(this);
  }

  validate(value) {
    const {countryCode, useUsZipPlus4, canUseUsZipPlusFour} = this.props;
    const willUseUsZipPlus4 = useUsZipPlus4 && canUseUsZipPlusFour;
    return validatePostalCode(value, countryCode, willUseUsZipPlus4);
  }

  render() {
    const {show, name, countryCode, disabled, isRequired} = this.props;
    const label = I18n.t(getPostalCodeLabel(countryCode));
    return show ? (
          <Field
            name={name}
            component={TextInput}
            validate={this.validate}
            props={{
              disabled: disabled,
              label,
              isRequired
            }}/>
    ) : null;
  }
}

PostalCodeField.propTypes = {
  name: PropTypes.string.isRequired,
  show: PropTypes.bool,
  countryCode: PropTypes.string,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  useUsZipPlusFour: PropTypes.bool,
  canUseUsZipPlusFour: PropTypes.bool,
};

PostalCodeField.defaultProps = {
  show: true
};

function mapStateToProps(state, ownProps) {
  const countryCode = ownProps.countryCode ? ownProps.countryCode : getCurrentFacilityCountry(state);
  return {
    countryCode,
    useUsZipPlus4: getUsZipPlus4Setting(state),
  };
}

export default connect(mapStateToProps) (PostalCodeField);
