import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import * as UOMS from '../../../constants/uoms';
import {WEIGHT} from '../../../constants/uomTypes';
import DisplayQty from '../../common/DisplayQty';
import {
  convertFromBase,
  convertToBase,
  convertWeightFromAndTo,
  possibleToConvert
} from '../../../util/uomHelpers';
import {getUrlParam} from '../../../util/routeHelper';
import WillRender from '../../common/concealers/WillRender';

const { UOM_VALUE_PRECISION } = UOMS;

class UomTable extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.onUomClick = this.onUomClick.bind(this);
    this.state = {
      selectedUom: null,
      showMicrogramsColumn: false,
    };
  }

  componentWillMount(){
    this.setState({
      selectedUom: this.props.type === WEIGHT ? UOMS.GR : UOMS.L,
      showMicrogramsColumn: getUrlParam('micrograms'),
    });
  }

  onUomClick(uom){
    this.setState({selectedUom: uom});
  }

  /**
   * Very similar to convertWeightFromAndTo in uom helpers.  This drops decimal precision from the value between
   * converting to base and then back to the target uom to simulate the data at rest in the database as micrograms
   * without decimals.
   * @param weight
   * @param fromUom
   * @param toUom
   * @param precision
   * @returns {*}
   */
  convertWeightThroughMicrograms(weight, fromUom, toUom, precision = UOM_VALUE_PRECISION){
    if (!possibleToConvert(fromUom, toUom)) {
      throw new Error(`Conversion Error: ${fromUom} can not be converted to ${toUom}`);
    }
    const inputBase = convertToBase(weight, fromUom);
    const convertedWeight = convertFromBase(parseInt(inputBase), toUom);
    return precision === -1 ? convertedWeight : Number(convertedWeight.toFixed(precision));
  }

  render() {
    if(!this.state.selectedUom) { // Don't show until we have the uom type and therefor a starting uom.
      return null;
    }
    const {uoms, type, sampleNumber} = this.props;
    const useNumber = sampleNumber !== null ? sampleNumber : 1;
    const width = this.state.showMicrogramsColumn ? 20 : 25;
    return (
      <div>
          <h4><strong>{I18n.t(`uoms.subTitles.${type}`)}</strong></h4>
          <table className='table table-striped table-bordered' style={{width: '100%'}}>
            <thead>
            <tr>
              <th style={{width: `${this.state.showMicrogramsColumn ? 100 - (width * 3) : 100 - (width * 2)}%`}}>{I18n.t(`uoms.subTitles.unitsOfMeasure`)}</th>
              <th style={{width: `${width}%`}}>{I18n.t(`uoms.subTitles.inMicrograms`)}</th>
              <th style={{width: `${width}%`}}>
                {I18n.t(`uoms.subTitles.sampleConversion`, {uom: I18n.t(`uoms.${this.state.selectedUom}.${useNumber === 1 ? 'word' : 'wordPlural'}`), number: useNumber})}
              </th>
              <WillRender ifTrue={this.state.showMicrogramsColumn}>
                <th style={{width: `${width}%`}}>
                  Through Micrograms
                </th>
              </WillRender>
            </tr>
            </thead>
            <tbody>
            {uoms.map((uom, index) => {
              return (
                <tr key={index}>
                  <td>
                    <a href style={{cursor: 'pointer'}} onClick={() => this.onUomClick(uom.uom_code)}>{I18n.t(`uoms.${uom.uom_code}.word`) + ' (' + I18n.t(`uoms.${uom.uom_code}.abbrev`) + ')'}</a>
                  </td>
                  <td>
                    {uom.ratio}
                  </td>
                  <td>
                    <DisplayQty qty={convertWeightFromAndTo(useNumber, this.state.selectedUom, uom.uom_code)} uom={uom.uom_code} /> {I18n.t(`uoms.${uom.uom_code}.abbrev`)}
                  </td>
                  <WillRender ifTrue={this.state.showMicrogramsColumn}>
                    <td>
                      <DisplayQty qty={this.convertWeightThroughMicrograms(useNumber, this.state.selectedUom, uom.uom_code)} uom={uom.uom_code} /> {I18n.t(`uoms.${uom.uom_code}.abbrev`)}
                    </td>
                  </WillRender>
                </tr>
              );
            })}
            </tbody>
          </table>
      </div>
    );
  }
}

UomTable.propTypes = {
  uoms: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
  sampleNumber: PropTypes.number,
};

export default UomTable;
