import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import {Col, Row,} from 'react-bootstrap';
import get from 'lodash.get';
import FieldArrayIncrement from '../../../common/form/FieldArrayIncrement';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import NumericInput from '../../../common/form/NumericInput';
import CheckBoxInput from '../../../common/form/CheckBoxInput';

const TestResultsFieldArray = ({options, source, value_type, canBeUsedForMedWeight, formValues, fields, change}) => {
  // Always show at least one field
  if (fields.length === 0) {
    fields.push({});
  }

  // construct options
  const optionsObject = options.map(option => ({name: I18n.t(option.name), id: option.key}));
  const selectedOptions = get(formValues, fields.name, []);

  // Filter out already selected options, except when used in this field
  const getFilteredOptions = (fieldIndex) => {
    return optionsObject.filter(option => !selectedOptions.some((selectedOption, index) => selectedOption.name === option.id && index !== fieldIndex));
  };

  const uomOptions = [{id: 'percentage', name: '%'}, {id: 'mg_g', name: 'MG/GR'}];

  // Conversion when uom field is changed
  // (Converted) value_per_unit is returned, so that it can be used to calculate the Medicated Net Weight
  const convertValue = (field, from, to) => {
    if (!from) {
      return get(formValues, `${field}_per_unit`);
    }

    // Get value
    const value = get(formValues, field);
    // If there is no value to convert return
    if (!value) {
      return get(formValues, `${field}_per_unit`);
    }

    // Determine conversion factor
    let multiplier = 1;
    if (from === 'percentage' && to === 'mg_g') multiplier = 10;
    if (from === 'mg_g' && to === 'percentage') multiplier = 0.1;

    // Perform conversion with a precision of up to 3 decimals and strip trailing zeros or return empty string
    const convertedValue = value ? Number((value * multiplier).toFixed(3)) : '';
    change(field, convertedValue);

    // If in 'serving' mode we need to recalculate the per_unit value as well
    let convertedValue_per_unit;
    if (value_type === 'serving' && get(formValues, 'servings_per_container')) {
      convertedValue_per_unit = Number((convertedValue * get(formValues, 'servings_per_container')).toFixed(3));
      change(`${field}_per_unit`, convertedValue_per_unit);
    }
    // Return the convertedValue_per_unit, so we can use that to recalculate the Medicated Net Weight
    return convertedValue_per_unit;
  };

  // helper to check preconditions for recalculating Medicated Net Weight
  const checkPreconditions = () => {
    // Preconditions for being able to calculate Medicated Net Weight
    return !get(formValues, 'servings_per_container', 0) || source !== 'item_master' || value_type !== 'serving';
  };

  // Helper to get potency_test_results to be used for recalculating Medicated Net Weight
  const getFilteredPotencyTestResults = (index) => {
    const potency_test_results = get(formValues, 'potency_test_results', []);
    // Filter out any test_results that don't have the 'use_for_med_weight' checkbox and the entry for the current index
    // (i.e. that value is not yet updated in potency_test_results, so we need to use the passed in value)
    return potency_test_results.filter((test_result, idx) => {
      return get(test_result, 'use_for_med_weight', false) && idx !== index;
    });
  };

  // Helper to get calculated_medicated_net_weight_in_mg for filtered_potency_test_results
  const getCalculatedMedicatedNetWeightInMg = (filtered_potency_test_results) => {
    let calculated_medicated_net_weight_in_mg = 0;
    filtered_potency_test_results.forEach((potency_test_result) => {
      const value_per_unit = get(potency_test_result, 'value_per_unit');
      const uom = get(formValues, 'servings_uom');
      if (value_per_unit && uom) {
        const multiplier = uom === 'mg' ? 1 : 1000;
        calculated_medicated_net_weight_in_mg += value_per_unit * multiplier;
      }
    });
    return calculated_medicated_net_weight_in_mg;
  };

  // Helper to update medicated_net_weight_field
  const setMedicatedNetWeightField = (calculated_medicated_net_weight_in_mg) => {
    // Only set Medicated Net Weight if we found actual values
    if (calculated_medicated_net_weight_in_mg > 0) {
      const calculated_medicated_net_weight_in_g = calculated_medicated_net_weight_in_mg / 1000;
      const medicated_weight_uom = get(formValues, 'medicated_weight_uom');
      if (!medicated_weight_uom) {
        change('medicated_weight_uom', 'MG');
      }
      change('medicated_weight', medicated_weight_uom === 'GR'
        ? parseFloat(Number(calculated_medicated_net_weight_in_g).toFixed(3))
        : parseFloat(Number(calculated_medicated_net_weight_in_mg).toFixed(3))
      );
    }
  };

  // Recalculate Medicated Net Weight on change of value or value_per_unit
  const recalculateMedicatedNetWeight = (index, value_per_unit, uom) => {
    // Preconditions for being able to calculate Medicated Net Weight
    if (checkPreconditions()) {
      return;
    }

    const filtered_potency_test_results = getFilteredPotencyTestResults(index);
    let calculated_medicated_net_weight_in_mg = getCalculatedMedicatedNetWeightInMg(filtered_potency_test_results);

    // Check if the 'use_for_med_weight' is checked for the current index
    if (get(formValues, `potency_test_results.${index}.use_for_med_weight`, false)) {
      const _uom = get(formValues, `potency_test_results.${index}.uom`, uom);
      if (value_per_unit && _uom) {
        const multiplier = _uom === 'mg' ? 1 : 1000;
        calculated_medicated_net_weight_in_mg += value_per_unit * multiplier;
      }
    }

    setMedicatedNetWeightField(calculated_medicated_net_weight_in_mg);
  };

  // Recalculate Medicated Net Weight on change of 'use_for_med_weight' checkbox
  const updateMedicatedNetWeight = (index, isChecked) => {
    // Preconditions for being able to calculate Medicated Net Weight
    if (checkPreconditions()) {
      return;
    }

    const filtered_potency_test_results = getFilteredPotencyTestResults(index);
    let calculated_medicated_net_weight_in_mg = getCalculatedMedicatedNetWeightInMg(filtered_potency_test_results);

    // If checkbox is checked add value_per_unit for current index
    if (isChecked) {
      const value_per_unit = get(formValues, `potency_test_results.${index}.value_per_unit`);
      const uom = get(formValues, 'servings_uom');
      if (value_per_unit && uom) {
        const multiplier = uom === 'mg' ? 1 : 1000;
        calculated_medicated_net_weight_in_mg += value_per_unit * multiplier;
      }
    }

    setMedicatedNetWeightField(calculated_medicated_net_weight_in_mg);
  };

  const servings_per_container = get(formValues, 'servings_per_container', '');
  const is_serving_mode = value_type === 'serving';

  return (
    <div>
      <Row>
        <Col sm={4}>
          <FieldArrayIncrement fields={fields} showDecrement={true} minLength={1} maxLength={optionsObject.length} label={fields.length === 1 ? 'products.form.addTestResult' : 'products.form.addOrRemoveTestResult'} /><br/>
        </Col>
        <Col sm={8} style={{textAlign: 'right'}}>
          {canBeUsedForMedWeight && is_serving_mode && source === 'item_master' &&
            <span style={{verticalAlign: 'text-bottom'}}>&nbsp;<br/>{I18n.t('products.form.useForMedicatedNetWeightInfo')}</span>
          }
        </Col>
      </Row>
      <Row>
        {is_serving_mode && source === 'item_master' &&
          <Col sm={1} style={{paddingRight: '6px'}}>
            {canBeUsedForMedWeight
              ? <span style={{fontWeight: 'bold'}}>{I18n.t('products.form.useForMedicatedNetWeight')}</span>
              : ''
            }
          </Col>
        }
        <Col sm={is_serving_mode ? 2 : 4} style={{paddingRight: '6px'}}/>
        <Col sm={is_serving_mode ? 2 : 4} style={{paddingRight: '6px'}}>
          {is_serving_mode
            ? <span style={{fontWeight: 'bold'}}>{I18n.t('products.form.productPerServing')}</span>
            : ''
          }
        </Col>
        {is_serving_mode
          ? <React.Fragment>
              <Col sm={3} style={{textAlign: 'center', paddingRight: '6px'}}>
                <span style={{fontWeight: 'bold'}}>{I18n.t('products.form.productServingsPerPack')}</span>
              </Col>
              <Col sm={2} style={{paddingRight: '6px'}}>
                <span style={{fontWeight: 'bold'}}>{I18n.t('products.form.PerPack')}</span>
              </Col>
            </React.Fragment>
          : null
        }
        <Col sm={is_serving_mode ? 2 : 4} style={{paddingRight: '6px'}}>
          <span style={{fontWeight: 'bold'}}>{I18n.t('products.form.uom')}</span>
        </Col>
      </Row>
      {fields.map((fieldName, fieldIndex) => (
        <Row key={fieldIndex}>
          {is_serving_mode && source === 'item_master' &&
            <Col sm={1} style={{paddingRight: '6px'}}>
              {canBeUsedForMedWeight &&
                <Field name={`${fieldName}.use_for_med_weight`}
                  component={CheckBoxInput}
                  props={{
                    label: '',
                    groupClassName: 'inline-checkbox',
                    onChange: (e) => {
                      updateMedicatedNetWeight(fieldIndex, e.target.checked);
                      change(`${fieldName}.use_for_med_weight`, !!e.target.checked);
                    }
                  }}
                />
              }
            </Col>
          }
          <Col sm={is_serving_mode ? 2 : 4} style={{paddingRight: '6px'}}>
            {/* What measurement */}
            <Field name={`${fieldName}.name`} component={ReactSelectInput} props={{
              options: getFilteredOptions(fieldIndex),
              textKey: 'name',
              valueKey: 'id',
            }}/>
          </Col>
          <Col sm={is_serving_mode ? 2 : 4} style={{paddingRight: '6px'}}>
            {/* Measurement value. Percentage or MG/G value if value_type = 'percentage'. Per serving value if value_type = 'serving' */}
            <Field name={`${fieldName}.value`} component={NumericInput} props={{
              fractionPartSize: 3,
              value: source === 'item_master' ? get(formValues, `${fieldName}.value`, '') : '',
              disabled: source === 'package',
              onChange: (value) => {
                if (value_type === 'serving' && servings_per_container) {
                  // Calculate value_per_unit
                  const value_per_unit = value ? Number((value * servings_per_container).toFixed(3)) : '';
                  recalculateMedicatedNetWeight(fieldIndex, value_per_unit);
                  change(`${fieldName}.value_per_unit`, value_per_unit);
                }
                change(`${fieldName}.value`, value);
              }
            }}/>
          </Col>
          {is_serving_mode
            ? <React.Fragment>
                <Col sm={1} style={{textAlign: 'left', verticalAlign: 'middle', paddingRight: '6px'}}>x</Col>
                <Col sm={1} style={{textAlign: 'center', verticalAlign: 'middle', paddingRight: '6px'}}>{servings_per_container}</Col>
                <Col sm={1} style={{textAlign: 'right', verticalAlign: 'middle', paddingRight: '6px'}}>=</Col>
                <Col sm={2}>
                  {/* Measurement value per unit. Only if value_type = 'serving' */}
                  <Field name={`${fieldName}.value_per_unit`} component={NumericInput} props={{
                    fractionPartSize: 3,
                    value: source === 'item_master' ? get(formValues, `${fieldName}.value_per_unit`, '') : '',
                    disabled: source === 'package',
                    onChange: (value_per_unit) => {
                      if (value_type === 'serving' && servings_per_container) {
                        // Calculate value
                        const value = value_per_unit ? Number((value_per_unit / servings_per_container).toFixed(3)) : '';
                        recalculateMedicatedNetWeight(fieldIndex, value_per_unit);
                        change(`${fieldName}.value`, value);
                      }
                      change(`${fieldName}.value_per_unit`, value_per_unit);
                    }
                  }}/>
                </Col>
              </React.Fragment>
            : null
          }
          <Col sm={is_serving_mode ? 2 : 4} style={{paddingRight: '6px'}}>
            {/* UOM for measurement type */}
            {is_serving_mode
              ? get(formValues, 'servings_uom', 'mg') === 'mg'
                ? I18n.t('uoms.MG.abbrev')
                : I18n.t('uoms.GR.abbrev')
              : (<Field name={`${fieldName}.uom`} component={ReactSelectInput} props={{
                options: uomOptions,
                textKey: 'name',
                valueKey: 'id',
                onChange: (uom) => {
                  const value_per_unit = convertValue(`${fieldName}.value`, get(formValues, `${fieldName}.uom`), uom);
                  if (value_per_unit) {
                    recalculateMedicatedNetWeight(fieldIndex, value_per_unit, uom);
                  }
                  change(`${fieldName}.uom`, uom);
                }
              }}/>)
            }
          </Col>
        </Row>
      ))}
    </div>
  );
};

TestResultsFieldArray.propTypes = {
  options: PropTypes.array.isRequired,
  source: PropTypes.string.isRequired,
  value_type: PropTypes.string.isRequired,
  canBeUsedForMedWeight: PropTypes.bool,
  formValues: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
};

export default TestResultsFieldArray;
