import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Row, Table, Card} from 'react-bootstrap';
import ContentConcealer from '../../../common/concealers/ContentConcealer';
import {getTranslationLabel} from '../../../../util/translationHelpers';

const getLabel = getTranslationLabel('batchHistory.model.');


const HarvestBatchReactivatedDetails = ({model, integrationState}) => {
  return (
    <Card>
      <Row>
        <Col xs={6}>
          <h3>{I18n.t('batchHistory.model.harvestBatchDetails')}</h3>
          <Table striped className='item-details'>
            <thead>
            <tr>
              <th>{I18n.t('itemTransactions.field')}</th>
              <th>{I18n.t('itemTransactions.value')}</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>{I18n.t('batchHistory.model.location')}</td>
              <td>{model.location_name}</td>
            </tr>
            <tr>
              <td>{I18n.t('batchHistory.model.plantCount')}</td>
              <td>{model.plants_count}</td>
            </tr>
            <tr>
              <td>{getLabel('currentWeight', integrationState)}</td>
              <td>{model.current_weight} {model.uom}</td>
            </tr>
            <ContentConcealer show={Boolean(integrationState.isWaLeaf && model.current_other_material_weight)}>
              <tr>
                <td>{getLabel('currentOtherMaterialWeight', integrationState)}</td>
                <td>{model.current_other_material_weight} {model.uom}</td>
              </tr>
            </ContentConcealer>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Card>
  );
};

HarvestBatchReactivatedDetails.propTypes = {
  model: PropTypes.object.isRequired,
  integrationState: PropTypes.object.isRequired,
};

export default HarvestBatchReactivatedDetails;
