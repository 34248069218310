/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, getFormSyncErrors } from 'redux-form';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Col, Row, Card } from 'react-bootstrap';

import * as statuses from '../../../../constants/statuses';
import InternationalDateTimePickerInput from '../../../common/form/InternationalDateTimePickerInput';
import MultiselectInput from '../../../common/form/MultiselectInput';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import TextInput from '../../../common/form/TextInput';
import NumericInput from '../../../common/form/NumericInput';
import InfusionInputsFieldArray from '../common/InfusionInputsFieldArray';
import InfusionOutputsFieldArray from './InfusionOutputsFieldArray';
import IngredientsFieldArray from '../../processing/complete/IngredientsFieldArray';
import NotesFieldArray from '../../common/NotesFieldArray';
import SubmitSection from '../../../common/form/SubmitSection';
import * as p from '../../../../constants/permissions';
import { COMPLETE_INFUSION_FORM } from '../../../../constants/forms';

export class CompleteInfusionForm extends React.PureComponent {
  // touch all invalid fields to prompt error displays
  componentDidUpdate() {
    if (this.props.valid || !this.props.formErrors) {
      return;
    }
    if (this.props.formErrors.inputs && Array.isArray(this.props.formErrors.inputs)) {
      const inputKeys = this.props.formErrors.inputs
        .map((el, index) => {
          return Object.keys(el).map((key) => `inputs.${index}.${key}`);
        })
        .flat();
      this.props.touch(...inputKeys);
    }
  }

  render() {
    const {
      form,
      notes,
      reset,
      change,
      phases,
      status,
      loading,
      pristine,
      equipment,
      employees,
      locations,
      submitting,
      trackingIds,
      ingredients,
      hasPackagesTags,
      showPrinter,
      handleSubmit,
      outputItemIds,
      initialValues,
      productionRuns,
      ingredientsCost,
      isManufacturing,
      userPermissions,
      integrationState,
      waInventoryTypes,
      ingredientOptions,
      infusionCanBeCompleted,
      ingredientsStandardCost,
      isLabelEnabledForNoCompletedProcess,
      isPaRemediationLabelsEnabled
    } = this.props;

    const { isMdMetrc } = integrationState;
    const isCompleted = status === statuses.completed;
    const submitSettings = {};
    if (isCompleted) {
      submitSettings.actionSettings = {
        print: {
          text: I18n.t(`common.actions.printLabel${outputItemIds.length > 1 ? 's' : ''}`),
          action: showPrinter
        }
      };
    } else {
      submitSettings.actionSettings = {
        saveDraftAndPrint: {
          text: I18n.t('common.actions.saveDraftAndPrint'),
          type: 'submit',
          submitting,
          pristine,
          disabled: !isLabelEnabledForNoCompletedProcess,
          action: () => change('submitDraft', true)
        },
        reset: {
          text: I18n.t('common.form.reset'),
          action: reset,
          style: 'default',
          pristine
        },
        saveAndPrint: {
          text: I18n.t('common.actions.saveAndPrint'),
          type: 'submit',
          submitting,
          invalid: false,
          pristine,
          disabled: !infusionCanBeCompleted,
          display:
            [p.print_labels].filter((x) => (userPermissions[x] ? userPermissions[x] : null)).length > 0 ? '' : 'hide',
          action: () => {
            change('afterSubmit', 'print');
            change('submitDraft', false);
          }
        },
        submit: {
          text: I18n.t('common.form.save'),
          submitting,
          invalid: false,
          pristine,
          disabled: !infusionCanBeCompleted,
          action: () => {
            change('afterSubmit', '');
            change('submitDraft', false);
          }
        }
      };

      if (!isLabelEnabledForNoCompletedProcess) {
        delete submitSettings.actionSettings.saveDraftAndPrint;
      }
    }

    return (
      <form className='complete-infusion-form' onSubmit={handleSubmit} noValidate={true}>
        <Row>
          <Col xs={6} sm={4} md={3}>
            <Field
              name='assemblyName'
              component={TextInput}
              props={{
                label: I18n.t('ei.infusions.form.assemblyName'),
                disabled: true
              }}
            />
          </Col>
        </Row>

        <h4 className='section-title'>{I18n.t('ei.processing.form.processedPackages')}</h4>
        <FieldArray
          name='inputs'
          component={InfusionInputsFieldArray}
          props={{
            loading,
            hasPackagesTags,
            isCompleteMode: true,
            isCompleted,
            infusionCanBeCompleted: infusionCanBeCompleted,
            isPaRemediationLabelsEnabled
          }}
        />

        <h4 className='section-title'>{I18n.t('ei.processing.form.extractionTime')}</h4>
        <Row>
          <Col xs={6} md={3}>
            <InternationalDateTimePickerInput
              name='expected_completion_time'
              props={{
                label: I18n.t('ei.processing.form.expectedCompletionTime'),
                timeFormat: true,
                disabled: true,
                inputProps: {
                  disabled: true
                },
                enableTodayButton: false
              }}
            />
          </Col>
          <Col xs={6} md={3}>
            <InternationalDateTimePickerInput
              name='actual_completion_time'
              props={{
                label: I18n.t('ei.processing.form.completionTime'),
                timeFormat: true,
                isRequired: true,
                disabled: isCompleted,
                inputProps: {
                  disabled: isCompleted
                },
                enableTodayButton: false
              }}
            />
          </Col>
          {!isMdMetrc ? (
            <Col xs={6} md={3}>
              <Field
                name='employees'
                component={MultiselectInput}
                props={{
                  label: I18n.t('ei.processing.form.employees'),
                  options: employees,
                  textKey: 'displayName',
                  valueKey: 'id',
                  isRequired: true,
                  disabled: isCompleted
                }}
              />
            </Col>
          ) : (
            <Col xs={6} md={3}>
              <Field
                name='employee'
                component={ReactSelectInput}
                props={{
                  label: I18n.t('ei.processing.form.employee'),
                  options: employees,
                  textKey: 'displayName',
                  valueKey: 'id',
                  isRequired: true,
                  disabled: isCompleted
                }}
              />
            </Col>
          )}
          <Col xs={6} md={3}>
            <Field
              name='equipments'
              component={MultiselectInput}
              props={{
                label: I18n.t('ei.processing.form.assignEquipment'),
                options: equipment,
                textKey: 'model_name',
                valueKey: 'id',
                isRequired: true,
                disabled: isCompleted
              }}
            />
          </Col>
        </Row>

        <h4 className='section-title' />
        <FieldArray
          name='outputs'
          component={InfusionOutputsFieldArray}
          locations={locations}
          productionRuns={productionRuns}
          integrationState={integrationState}
          isManufacturing={isManufacturing}
          trackingIds={trackingIds}
          phases={phases}
          isCompleted={isCompleted}
          initialValues={initialValues}
          waInventoryTypes={waInventoryTypes}
          isLabelEnabledForNoCompletedProcess={isLabelEnabledForNoCompletedProcess}
          hasPackagesTags={hasPackagesTags}
        />

        <h4 className='section-title'>{I18n.t('ei.processing.form.actualIngredientsUsed')}</h4>
        <FieldArray
          name='ingredients'
          component={IngredientsFieldArray}
          change={change}
          ingredients={ingredients}
          ingredientsCost={ingredientsCost}
          ingredientOptions={ingredientOptions}
          ingredientsStandardCost={ingredientsStandardCost}
          isCompleted={isCompleted}
        />

        {integrationState.isBiotrack && (
          <Row>
            <Col xs={12}>
              <h4 className='section-title'>{I18n.t('ei.processing.form.reportWaste')}</h4>
              <Card body>
                <Row>
                  <Col xs={3}>
                    <Field
                      name='waste'
                      component={NumericInput}
                      props={{
                        label: I18n.t('ei.processing.form.reportAutoCalculatedWaste'),
                        rightAddon: 'GR',
                        disabled: true,
                        groupClassName: 'transparent-addon-right'
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        )}
        <FieldArray name='notes' formName={form} component={NotesFieldArray} notes={notes} change={change} />
        <SubmitSection settings={submitSettings} />
      </form>
    );
  }
}

CompleteInfusionForm.propTypes = {
  form: PropTypes.string,
  formErrors: PropTypes.object.isRequired,
  notes: PropTypes.array.isRequired,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  loading: PropTypes.bool,
  reset: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  phases: PropTypes.array.isRequired,
  productionRuns: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
  isManufacturing: PropTypes.bool,
  trackingIds: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  employees: PropTypes.array.isRequired,
  equipment: PropTypes.array.isRequired,
  ingredients: PropTypes.array.isRequired,
  ingredientOptions: PropTypes.object.isRequired,
  ingredientsCost: PropTypes.number,
  ingredientsStandardCost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  status: PropTypes.string,
  outputItemIds: PropTypes.array.isRequired,
  showPrinter: PropTypes.func.isRequired,
  infusionCanBeCompleted: PropTypes.bool.isRequired,
  userPermissions: PropTypes.oneOfType([PropTypes.array.isRequired, PropTypes.object.isRequired]),
  initialValues: PropTypes.object,
  waInventoryTypes: PropTypes.array,
  isPaRemediationLabelsEnabled: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    formErrors: getFormSyncErrors(COMPLETE_INFUSION_FORM)(state)
  };
}

export default connect(mapStateToProps)(CompleteInfusionForm);
