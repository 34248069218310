export const formName = 'rewardSettingsForm';

export const formDefaults =  {
  disableDaysPointsExpire: undefined,
  defaultDaysPointsExpire: 9999,
  defaultPointsWorth: 1,
};

export const orderTypes = [
  {name: 'In Store', id: 'in_store'},
  {name: 'Pickup', id: 'pickup'},
  {name: 'Delivery', id: 'delivery'},
];

