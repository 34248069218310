import get from 'lodash.get';
import * as dataNames from '../constants/dataNames';
import * as itemNames from '../constants/itemNames';
import {oklahomaSubcategories,finishedFlower} from '../constants/subcategoryGroups';
import {setData} from '../actions/dataActions';
import {getIntegrationState} from '../selectors/integration/integrationSelectors';

export const categoriesMiddleware = store => next => action => { //eslint-disable-line
  const result = next(action);
  const state = store.getState();

  const updateCategories = (dataName) => {
    const integrationState = getIntegrationState(state);
    const {isPaLeaf, isUtah} = integrationState;
    const facility = state[itemNames.facility];
    const province_code = get(facility, 'province_code', '');
    const isOklahoma = province_code && province_code.toLowerCase() === 'ok';

    //@TODO: Refactor category exclusions to use settings instead of all these conditionals
    const baseCategories = state[dataName].map((c) => c);
    if(!isUtah) { // Utah filters at back end and uses some of these categories.
      if (!isOklahoma) {
        const categories = baseCategories.map((category) => {
          category.subcategories = (category.subcategories || []).filter((sub) => {
            return oklahomaSubcategories.indexOf(sub.subcategory_code) === -1;
          });
          return category;
        });
        store.dispatch(setData(categories, dataName));
      }

      //@TODO: Refactor category inclusions and exclusions to use settings instead of all these conditionals
      // Show this FINISHED FLOWER category for leaf facilities and sunset sites
      // (not integrated but they have the leaf pa config pack)
      const sunsetLeafFacility = get(facility, 'has_leaf_pa_config_pack_and_closed_loop', false);
      if (!isPaLeaf && !sunsetLeafFacility) {
        const categories = baseCategories.map((category) => {
          category.subcategories = (category.subcategories || []).filter((sub) => {
            return finishedFlower.indexOf(sub.subcategory_code) === -1;
          });
          return category;
        });
        store.dispatch(setData(categories, dataName));
      }
    }
  };

  if(action.name === dataNames.categories){
    updateCategories(dataNames.categories);
  }
  if(action.name === dataNames.categoriesData){
    updateCategories(dataNames.categoriesData);
  }
  return result;
};
