import get from 'lodash.get';
import {isFloat, isInteger, requiredFieldValidation} from '../common/form/redux-form/validations';

export const validate = (values, props) => {
  const errors = {};

  if(values.setBuffer){
    errors['setBuffer'] = isInteger(get(values,'setBuffer'),'Must be an integer');
  }
  if(values.inactiveAfter){
    errors['inactiveAfter'] = isInteger(get(values,'inactiveAfter'),'Must be an integer');
  }


  errors['order_recreational_sales_age_limit'] = isInteger(
    get(values,'order_recreational_sales_age_limit'),
    'Must be an integer'
  );

  if (get(props, 'registeredFields.order_recreational_sales_age_limit.name')) {
    errors['order_recreational_sales_age_limit'] = requiredFieldValidation(values.order_recreational_sales_age_limit, 'retail.salesSettings.recreationalAgeLimit');
  }

  if(values.order_allow_anonymous){
    if(!get(values,'order_anonymous_customer_types',[]).length){
      errors['order_anonymous_customer_types'] = 'Required when Allow Anonymous Orders is set';
    }
    if(get(values,'order_birthdate_for_anonymous_orders', null) == null){
      errors['order_birthdate_for_anonymous_orders'] = 'Required when Allow Anonymous Orders is set';
    }
  }

  if (values.order_auto_printing_is_enabled === '1' && values.order_auto_printing_labels) {
    // Value of Default Label should be one of active labels:
    if (!values.order_auto_printing_default_label || !props.labelTags || !props.labelTags.some || !props.labelTags.some(tag => tag.tag === values.order_auto_printing_default_label)) {
      errors['order_auto_printing_default_label'] = requiredFieldValidation(undefined, 'retail.salesSettings.patientDefaultLabel');
    }
  }

  // Commented out are still present but no longer possible to clear so should never be invalid
  const validateEquivalence = {
    //select: 'Weight Measure Is Required',
    weight: 'Equivalent Weight Is Required',
    flower: 'Flower Weight Is Required'
  };

  const validateRules = {
    qty: 'Limit Quantity Is Required',
    //uom: 'Unit of Measure Is Required',
    //limit_using: 'Weight Field Is Required'
  };


  const patientTypes = ['medical', 'recreational'];
  patientTypes.forEach((patientType) => {
    // Validate limits for both patient types under equivalence or categories
    const limitMethod = get(values, `${patientType}_limit_method`);
    if(limitMethod === 'disabled') return false;
    const ruleSet = limitMethod === 'equivalency' ? validateEquivalence : validateRules;
    const testFields = Object.keys(ruleSet);
    Object.keys(values).forEach((field) => { // Iterates all values
      const simpleFieldName = field.substring(field.indexOf('_') + 1, field.lastIndexOf(limitMethod === 'equivalency' ? '_' : '__'));
      if(field.indexOf(patientType) === -1 || testFields.indexOf(simpleFieldName) === -1) return false; // constrain to ruleset
      errors[field] = isFloat(get(values, field), ruleSet[simpleFieldName]);
    });

    if (!props.integrationState.isBiotrack) {
      // Validate restrictSalesGrams (float equivalence), restrictSalesLimit (int always), restrictSalesType (string always)
      if (limitMethod === 'equivalency') {
        errors[`${patientType}_restrictSalesGrams`] = isFloat(get(values, `${patientType}_restrictSalesGrams`), 'Gram restriction is required');
      }
      errors[`${patientType}_restrictSalesLimit`] =  (parseInt(get(values, `${patientType}_restrictSalesLimit`), 10) === 0)
        ? 'Interval period is required'
        : isInteger(get(values, `${patientType}_restrictSalesLimit`), 'Interval period is required');
      errors[`${patientType}_restrictSalesType`] = (typeof get(values, `${patientType}_restrictSalesType`) !== 'string') ? 'Time measure is required' : undefined;
    }
  });

  // if auto-expire patients is set, require 'grace period' days
  if(+values.autoExpired){
    errors['setBuffer'] = requiredFieldValidation(values.setBuffer,'retail.salesSettings.setBufferLabel');
  }
  return errors;
};

export default validate;
