import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import isempty from 'lodash.isempty';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import ModalWrapper from '../ModalWrapper';
import IntegrationEntityLockSupportMessage from './IntegrationEntityLockSupportMessage';
import {setItem, unsetItem} from '../../../actions/itemActions';
import {integrationTrackingStatusModal} from '../../../constants/itemNames';
import {getIntegrationState} from '../../../selectors/integration/integrationSelectors';


class IntegrationTrackingStatusModal extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.unsetMessages = this.unsetMessages.bind(this);
  }

  componentDidMount() {
    this.props.actions.setItem({}, integrationTrackingStatusModal);
  }

  componentWillUnmount() {
    this.unsetMessages();
  }

  unsetMessages() {
    this.props.actions.unsetItem(integrationTrackingStatusModal);
  }

  render() {
    const {
      integrationTrackingStatusModal,
      integrationTrackingStatusModal: {entityLocks = [], trackingId},
      integrationState,
      entityLockRemedies,
      updateTrackingId,
      handleRevertSplit,
    } = this.props;

    const translations = 'integration.transactions.trackingIdStatuses';
    const modalTitle = trackingId ?
      I18n.t(`${translations}.modalTitleWithTrackingId`, {trackingId}) :
      I18n.t(`${translations}.modalTitle`);

    return (
      <ModalWrapper
        title={modalTitle}
        translate={false}
        version={2}
        widthClass='modal-sm'
        showModal={!isempty(integrationTrackingStatusModal)}
        onHide={this.unsetMessages}
      >
        <React.Fragment>
          {
            entityLocks.map(entityLock => {
              const remedies = get(entityLockRemedies, entityLock.lock_reason, []);
              return (<React.Fragment>
                <p>{entityLock.description}</p>
                <IntegrationEntityLockSupportMessage
                  lockReason={entityLock.lock_reason}
                  createdAt={entityLock.created_at}
                  integrationState={integrationState}
                  updateTrackingId={updateTrackingId}
                  handleRevertSplit={handleRevertSplit}
                  itemId={entityLock.entity_id}
                />
                <hr />
                {
                  remedies.map((Component) => <Component entityLock={entityLock} />)
                }
              </React.Fragment>);
            })
          }
        </React.Fragment>
      </ModalWrapper>
    );
  }
}

IntegrationTrackingStatusModal.propTypes = {
  integrationTrackingStatusModal: PropTypes.object,
  integrationState: PropTypes.object,
  entityLockRemedies: PropTypes.object,
  actions: PropTypes.object,
  updateTrackingId: PropTypes.func,
  handleRevertSplit: PropTypes.func,
};

const mapStateToProps = (state) => ({
  integrationTrackingStatusModal: state.integrationTrackingStatusModal,
  integrationState: getIntegrationState(state),
});


const mapDispatchToProps = (dispatch) => {
  const actions = {
    setItem,
    unsetItem
  };

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export const ConnectedIntegrationTrackingStatusModal = connect(mapStateToProps, mapDispatchToProps)(IntegrationTrackingStatusModal);
export default IntegrationTrackingStatusModal;
