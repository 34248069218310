import { combineReducers } from 'redux';

import coupons from './couponsReducer';
import appliedCoupons from './appliedCouponsReducer';
import coupon from './couponReducer';
import checkout from './checkoutReducer';
import items from './itemsReducer';
import pricingWeights from './pricingWeightsReducer';
import packageIds from './packageIdsReducer';
import taxRate from './taxRateReducer';
import manualDiscount from './manualDiscountReducer';
import payment from './paymentReducer';
import currentOrder from './currentOrderReducer';
import assignDriverOpen from './assignDriverOpen';
import {RESET_CART} from '../../constants/actionTypes';
import initialState from '../../constants/initialState';

const combinedReducer = combineReducers({
  coupons,
  appliedCoupons,
  coupon,
  checkout,
  items,
  pricingWeights,
  packageIds,
  taxRate,
  manualDiscount,
  payment,
  currentOrder,
  assignDriverOpen
});

const cartReducer = (state, action) => {
  if (action.type === RESET_CART) {
    state = Object.assign({}, initialState.cart);
  }
  return combinedReducer(state, action);
};

export default cartReducer;
