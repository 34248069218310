import React from 'react';
import PropTypes from 'prop-types';

class IndeterminateCheckbox extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.handleMouseDown = this.handleMouseDown.bind(this);
  }

  componentDidMount() {
    this.updateIndeterminate(this.props.indeterminate);
    this.updateChecked(this.props.checked);
    this.updateChecked(this.props.checked);
  }

  componentWillReceiveProps(nextProps) {
    this.updateIndeterminate(nextProps.indeterminate);
    this.updateChecked(nextProps.checked);
  }

  updateIndeterminate(value) {
    this.refs.input.indeterminate = Boolean(value);
  }

  updateChecked(value) {
    this.refs.input.checked = Boolean(value);
  }

  handleMouseDown(event) {
    if (this.props.stopPropagation) {
      event.stopPropagation();
    }
    this.props.onChange(!event.target.checked);
  }

  render() {
    const {checked, className, disabled} = this.props;
    return (
      <input
        ref='input'
        type='checkbox'
        className={className}
        checked={checked}
        disabled={disabled ? 'disabled' : ''}
        onMouseDown={this.handleMouseDown}
      />
    );
  }
}

IndeterminateCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  indeterminate: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  stopPropagation: PropTypes.bool,
  disabled: PropTypes.bool,
};

IndeterminateCheckbox.defaultProps = {
  stopPropagation: true,
  disabled: false,
};

export default IndeterminateCheckbox;
