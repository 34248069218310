import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { goBack } from 'react-router-redux';
import { formValueSelector, change } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import * as dataNames from '../../../../constants/dataNames';
import * as messageTypes from '../../../../constants/messageTypes';
import {
  getUnpaginatedData,
  ensureGetUnpaginatedData,
  postItem,
  getItem,
  getFile
} from '../../../../actions/apiActions';
import { addMessage } from '../../../../actions/systemActions';

import CreateProcessingTypeFormWrapper from './CreateProcessingTypeFormWrapper';
import FormWrapper from '../../../common/form/FormWrapper';
import InProgressOverlay from '../../../common/InProgressOverlay';
import {
  getCreateProcessingTypeInitialValues,
  getCreatePayload,
  getPossibleUoms,
  getValidInputAndOutputJurisdictionCategories,
} from '../../../../selectors/processingTypesSelectors';
import { getSortedIngredients } from '../../../../selectors/ingredientsSelectors';
import { getIntegrationState } from '../../../../selectors/integration/integrationSelectors';
import {PROCESSING_TYPE_FORM} from '../../../../constants/forms';
import { isFeatureEnabled } from '../../../../selectors/featureToggles';

const formName = PROCESSING_TYPE_FORM;

export class CreateProcessingTypesPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      saving: false,
      itemsWithCost: [],
      ready: false,
      isTouch: { subcategories_input: false, subcategories_output: false }
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.getPossibleUom = this.getPossibleUom.bind(this);
    this.calculateTotalCost = this.calculateTotalCost.bind(this);
    this.onChangeSubcategories = this.onChangeSubcategories.bind(this);
  }

  componentWillMount() {
    const { getUnpaginatedData, ensureGetUnpaginatedData } = this.props.actions;
    const promises = [
      ensureGetUnpaginatedData('/api/phases', dataNames.phases, { failed: 'phases.get.failed' }),
      ensureGetUnpaginatedData('/api/categories', dataNames.categories, { failed: 'categories.get.failed' }),
      getUnpaginatedData(
        '/api/subcategories',
        dataNames.subcategories,
        { failed: 'categories.get.failed' },
        { detailed: 1 }
      ),
      getUnpaginatedData('/api/equipment', dataNames.equipmentItems, { failed: 'equipment.get.failed' }, { active: 1 }),
      getUnpaginatedData('/api/uoms', dataNames.uoms, { failed: 'uoms.get.failed' }),
      getUnpaginatedData(
        '/api/ingredient_items/by_facility',
        dataNames.ingredients,
        { failed: 'ei.processingTypes.form.failed' },
        { active: 1, detailed: 1 }
      )
    ];
    Promise.all(promises)
      .then(() => this.setState({ ready: true }))
      .catch(() => this.setState({ ready: true }));
  }

  onChangeSubcategories(name) {
    const touchedFields = this.state.isTouch;
    touchedFields[name] = true;
    this.setState(touchedFields);
  }

  calculateTotalCost() {
    const materials = this.props.getFormValue('materials') || [];
    const total = materials.reduce((acc, material) => {
      acc += parseFloat(material.cost_per_line);
      return acc;
    }, 0);
    return total;
  }

  getPossibleUom(defaultUom) {
    return getPossibleUoms(defaultUom, this.props.uoms);
  }

  onSubmit(formData) {
    this.setState({ saving: true });
    const afterSubmit = this.props.getFormValue('afterSubmit');
    return this.props.actions
      .postItem(
        '/api/processing_types',
        getCreatePayload(formData),
        null,
        { failed: 'ei.processingTypes.create.fail', success: 'ei.processingTypes.create.success' },
        {},
        (processingType) => {
          if (afterSubmit === 'print') {
            this.props.actions.addMessage(messageTypes.success, 'file.upload.start');
            this.props.actions.getFile(`/api/processing_types/${processingType.id}/print`, 'process_type.pdf', {
              failed: 'ei.processingTypes.listing.failedPrint'
            });
          }
          this.props.actions.goBack();
          this.setState({ saving: false });
        }
      )
      .catch(() => this.setState({ saving: false }));
  }

  render() {
    const {
      getFormValue, initialValues, phases, equipmentItems, uoms, ingredients, integrationState,
      validInputAndOutputJurisdictionCategories, featureUtQaChangeRequestEnabled
    } = this.props;
    const { saving, ready, isTouch } = this.state;
    const overlayMessage = I18n.t(`ei.processingTypes.${saving ? 'saving' : 'loading'}`);
    return (
      <FormWrapper
        title={'ei.processingTypes.create.title'}
        goBack={this.props.actions.goBack}
        className='create-processing-type'
      >
        <InProgressOverlay isActive={saving || !ready} message={overlayMessage} />
        <CreateProcessingTypeFormWrapper
          form={formName}
          saving={this.state.saving}
          onSubmit={this.onSubmit}
          initialValues={initialValues}
          keepDirtyOnReinitialize={true}
          enableReinitialize={true}
          phases={phases}
          equipmentItems={equipmentItems}
          uoms={uoms}
          ingredients={ingredients}
          getPossibleUom={this.getPossibleUom}
          calculateTotalCost={this.calculateTotalCost}
          isTouch={isTouch}
          onChangeSubcategories={this.onChangeSubcategories}
          getFormValue={getFormValue}
          integrationState={integrationState}
          validInputAndOutputJurisdictionCategories={validInputAndOutputJurisdictionCategories}
          featureUtQaChangeRequestEnabled={featureUtQaChangeRequestEnabled}
        />
      </FormWrapper>
    );
  }
}

CreateProcessingTypesPage.propTypes = {
  actions: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    ensureGetUnpaginatedData: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    getFile: PropTypes.func.isRequired,
    addMessage: PropTypes.func.isRequired
  }),
  getFormValue: PropTypes.func.isRequired,
  phases: PropTypes.array.isRequired,
  initialValues: PropTypes.object,
  equipmentItems: PropTypes.array.isRequired,
  uoms: PropTypes.array.isRequired,
  ingredients: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
  validInputAndOutputJurisdictionCategories: PropTypes.object.isRequired,
  featureUtQaChangeRequestEnabled: PropTypes.bool,
};

const selector = formValueSelector(formName);

function mapStateToProps(state) {
  const { phases, equipmentItems, uoms } = state;
  return {
    phases,
    equipmentItems,
    uoms,
    ingredients: getSortedIngredients(state),
    getFormValue: (name) => selector(state, name),
    initialValues: getCreateProcessingTypeInitialValues(state),
    integrationState: getIntegrationState(state),
    validInputAndOutputJurisdictionCategories: getValidInputAndOutputJurisdictionCategories(state),
    featureUtQaChangeRequestEnabled: isFeatureEnabled(state)('feature_ut_qa_change_request'),
  };
}
function mapDispatchToProps(dispatch) {
  const actions = {
    goBack,
    getUnpaginatedData,
    ensureGetUnpaginatedData,
    postItem,
    getItem,
    getFile,
    addMessage,
    change
  };
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateProcessingTypesPage);
