import React from 'react';
import PropTypes from 'prop-types';
import {Field, FieldArray, reduxForm} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import { Button, Col, Row } from 'react-bootstrap';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import {INTERNAL_TRANSFER_FORM} from '../../../constants/forms';
import Transfer from './Transfer';
import validate from './validate';

const InternalTransferForm = (props) => {

  const {facility, partnerFacilities, selectedPartnerFacilityId, settings} = props;
  const enabledFacilities = settings.filter((facility) => facility.enabled).map((facility) => facility.facility_id);
  const facilities = partnerFacilities.filter((f) => {
    return get(f, 'partner.is_internal_partner', false);
  });

  return (
    <form onSubmit={props.handleSubmit} noValidate={true}>
      <div style={{padding: '24px 0'}}>
        <Row>
          <Col md={4}>
            <Field name='facility_id'
                   component={ReactSelectInput}
                   props={{
                     label: 'Sender Facility',
                     defaultOption: I18n.t('common.form.selectPlaceholder'),
                     options: facilities,
                     textKey: 'facility_name',
                     valueKey: 'facility_id',
                     isRequired: false,
                     disabled: true
                   }}/>
          </Col>
          <Col md={4}>
            <Field name='partner_facility_id'
                   component={ReactSelectInput}
                   props={{
                     label: 'Partner Facility',
                     defaultOption: I18n.t('common.form.selectPlaceholder'),
                     options: facilities.filter((f) => f.facility_id !== facility.id && enabledFacilities.indexOf(f.facility_id) !== -1),
                     textKey: 'facility_name',
                     valueKey: 'id',
                     isRequired: true,
                     disabled: false,
                     placeholder: 'Select a Facility',
                     onChange: (value) => {
                       props.change('storage_location_id', null);
                       props.getPartnerStorageLocations(value);
                       props.change('partner_facility_id', value);
                     }
                   }}/>
          </Col>
          <Col md={4}>
            <Field name='storage_location_id'
                   component={ReactSelectInput}
                   props={{
                     label: 'Storage Location',
                     defaultOption: I18n.t('common.form.selectPlaceholder'),
                     options: props.locations || [],
                     textKey: 'text',
                     valueKey: 'value',
                     isRequired: true,
                     disabled: !selectedPartnerFacilityId,
                     placeholder: 'Select A Location',
                   }}/>
          </Col>
        </Row>
      </div>
      <table className='table-striped table-bordered table'>
        <thead>
        <tr>
          <th style={{width: '40%'}}>{I18n.t('internalTransfers.columnHeaderItem')}</th>
          <th style={{width: '20%'}}>{I18n.t('internalTransfers.columnHeaderAmount')}</th>
          <th style={{width: '20%'}}>{I18n.t('internalTransfers.columnHeaderAmountSend')}</th>
          <th style={{width: '20%'}}>{I18n.t('internalTransfers.columnHeaderRemove')}</th>
        </tr>
        </thead>
        <FieldArray
          name='transfers'
          component={Transfer}
          selectedProducts={props.selectedProducts}
          handleSelect={props.handleSelect}
          actions={props.actions}
          isIngredient={props.isIngredient}
          allowNegativeInventory={props.allowNegativeInventory}
        />
      </table>
      <div style={{textAlign: 'right'}}>
        <Button style={{float: 'right', marginLeft: '8px'}} variant='info' type='submit'>Schedule Transfer</Button>
        <Button
          style={{float: 'right', marginLeft: '8px'}}
          variant='info'
          type='button'
          onClick={() => {
            props.change('doNotClose', 1);
            setTimeout(() => {
              props.handleSubmit();
              props.change('doNotClose', 0);
            }, 10);

          }}
        >
          Schedule Transfer & Start Another
        </Button>
        <Button style={{float: 'right', marginLeft: '8px'}} variant='default' type='button' onClick={() => props.onCancel()}>Cancel</Button>
        <Button
          style={{float: 'right', marginLeft: '8px'}}
          type='reset'
          onClick={() => props.reset()}
        >
          Reset
        </Button>
        <div style={{clear: 'both'}} />
      </div>
    </form>
  );
};

InternalTransferForm.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  selectedProducts: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
  facility: PropTypes.object.isRequired,
  partnerFacilities: PropTypes.array.isRequired,
  getPartnerStorageLocations: PropTypes.func.isRequired,
  locations: PropTypes.array.isRequired,
  change: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  reset: PropTypes.func,
  isIngredient: PropTypes.bool,
  selectedPartnerFacilityId: PropTypes.string,
  settings: PropTypes.object.isRequired,
  allowNegativeInventory: PropTypes.bool,
};

const initializeReduxForm = {
  form: INTERNAL_TRANSFER_FORM,
  validate,
  enableReinitialize: false,
};

const WrappedComponent = reduxForm(initializeReduxForm)(InternalTransferForm);

export default WrappedComponent;
