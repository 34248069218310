import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import { connect } from 'react-redux';
import {Row, Col, Alert} from 'react-bootstrap';
import {FaExclamationTriangle} from 'react-icons/fa';
import {Field, formValueSelector} from 'redux-form';
import get from 'lodash.get';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import BulkLineItemWrapper from '../../common/line-items/BulkLineItemWrapper';
import UnitLineItem from '../../common/line-items/UnitLineItem';
import PrePackLineItem from '../../common/line-items/PrePackLineItem';
import AddItem from '../../../common/form/AddItem';
import {purchaseOrderForm} from '../../../../constants/formNames';
import WillRender from '../../../common/concealers/WillRender';

export const OrderLines = (props) => {
  const {
    fields,
    change,
    itemMasters,
    storageLocations,
    sharedLocations,
    partnerId,
    lines,
    isActive,
    transferRecord,
    uoms,
    showMedicatedStatusMessage,
    showNoPartnerProductsMessage,
    usedItemMasterIds,
    isInitialMode,
    meta,
    transferLinesLimit,
    metrcIsImported,
    isReturn,
    categories,
    isEditMode
  } = props;

  const {map, push, pop, length} = fields;

  const removeFromPo = (index) => {
    if (!metrcIsImported) {
      fields.remove(index);
    }
  };

  // If there are more than xx itemMaster require to select a category first, before showing item masters dropdown options
  const requireCategory = itemMasters.length > 2000 && isReturn;

  const changeCategory = (id, index) => {
    change('category_id', id);
    return lines[index].category_id = id;
  };
  

  const filterOutUsedItemMasters = (itemMaster, selectedItemMasterId) => {
    if(itemMaster.id === selectedItemMasterId) return true;
    return usedItemMasterIds.indexOf(itemMaster.id) === -1;
  };

  const getParentPurchaseOrderLineClass = (item) => {
    if(item.showEditors || item.showEditors === undefined) return '';
    const classMap = {
      added: 'bg-info',
      deleted: 'bg-danger',
      amended: 'bg-info',
      unchanged: ''
    };
    return classMap[get(item, 'lineStatus', 'unchanged')];
  };

  /***
   * Text describes what happened in partner process to yield this PO result for a line item.
   * @param item
   * @returns {string}
   */
  const getContextText = (item) => {
    const textMap = {
      added: `This line item was added${item.editSource === 'self' ? '.' : ' by your partner.'}`,
      deleted: `This line item was deleted${item.editSource === 'self' ? '.' : ' by your partner.'}`,
      unchanged: `This line item was left unchanged${item.editSource === 'self' ? '.' : ' by your partner.'}`,
      amended: `This line item was amended from the item above${item.editSource === 'self' ? '.' : ' by your partner.'}.`,
    };
    return textMap[item.lineStatus] ? textMap[item.lineStatus] : '';
  };

  /***
   * Returns style suitable for the context of the text being presented.  eg. deleted returns red.
   * @param type
   * @returns {string}
   */
  const getContextClass = (type) => {
    const textClass = {
      added: 'text-info',
      unchanged: 'text-muted',
      deleted: 'text-danger',
      amended: 'text-info'
    };
    return textClass[type] ? textClass[type] : '';
  };

  const checkIfItemIsLotTracked = (item) => {
    if(item.itemMaster) {
      return !item.itemMaster.inventory_attributes.lot_tracked;
    }
  };

  return (
    <Row>
      <Col xs={12}>
        {map((line, index) => {
          const item = lines && lines[index] || {};
          const uom = get(item, 'itemMaster.default_uom');
          const filteredLocations = get(item, 'itemMaster.is_shared_item') ? sharedLocations : storageLocations;
          const numberOfLinesIsNotValid = meta && meta.error && transferLinesLimit && index >= transferLinesLimit;
          const showEditors = item.showEditors === undefined || item.showEditors;
          const lineLength = lines ? lines.length : null;
          const rowStyle = ['amended', 'deleted', 'unchanged', 'added'].indexOf(item.lineStatus) !== -1
            ? Object.assign({}, {border: '1px solid #ccc', paddingTop: '3px !important'}, !showEditors && ['added', 'amended'].indexOf(item.lineStatus) !== -1 ? {} : {marginBottom: '12px'})
            : {};
          return (
            <Row key={index} className={getParentPurchaseOrderLineClass(item)} style={rowStyle}>
              <div style={{height: '8px'}} />
              {
                ['added', 'unchanged', 'deleted', 'amended'].indexOf(item.lineStatus) !== -1
                  ? item.lineStatus === 'amended' && !showEditors ? null : <div className={getContextClass(item.lineStatus)} style={{fontSize: 'smaller', textAlign: 'center'}}>{getContextText(item)}</div>
                  : null
              }
              {
                !showEditors
                  ? (<Col lg={2}>
                    <label>{I18n.t('purchaseOrders.form.item')}</label>
                    <div>{item.name}</div>
                    </Col>)
                  : (<Col lg={4}>
                    {itemMasters.length === 0 && !partnerId && !isInitialMode
                      ? (<div>{I18n.t('purchaseOrders.form.noProductsNoPartnerSelected')}</div>)
                      : showMedicatedStatusMessage
                        ? (<div>{I18n.t('purchaseOrders.form.partnerHasMedicatedAndNonMedicated')}</div>)
                        : showNoPartnerProductsMessage
                          ? (<div>{I18n.t('purchaseOrders.form.noProductsNoPartnerProducts')}</div>)
                          : (<div>
                            {requireCategory && index + 1 == lineLength && !item.item_master_id &&
                            <Field name={`category_id`} 
                            component={ReactSelectInput}
                            props={{
                              label: I18n.t('purchaseOrders.form.category'),
                              options: categories,
                              textKey: 'name',
                              valueKey: 'id',
                              onChange: (id) => {changeCategory(id, index);}
                            }}/>
                            }
                            {requireCategory && !lines[index].category_id && (!item || (!isEditMode || !lines[index].item_master_id)) &&
                            <div style={{ color: 'red' }}>Select a category first</div>
                            }
                            <Field
                              name={`${line}.item_master_id`}
                              component={ReactSelectInput}
                              props={{
                                label: I18n.t('purchaseOrders.form.item'),
                                options: 
                                requireCategory ?
                                (item.id) // If saved, options include line product even if not active, otherwise exclude those not active
                                  ? itemMasters.filter((im) => 
                                  filterOutUsedItemMasters(im, item.item_master_id)).filter((itemMaster) => 
                                  itemMaster.active || itemMaster.id === item.item_master_id || item.category_id == lines[index].category_id)
                                  : itemMasters.filter((im) => filterOutUsedItemMasters(im, item.item_master_id)).filter((item) => item.active && item.category_id == lines[index].category_id) :
                                  (item.id) // If saved, options include line product even if not active, otherwise exclude those not active
                                  ? itemMasters.filter((im) => 
                                  filterOutUsedItemMasters(im, item.item_master_id)).filter((itemMaster) => 
                                  itemMaster.active || itemMaster.id === item.item_master_id)
                                  : itemMasters.filter((im) => filterOutUsedItemMasters(im, item.item_master_id)).filter((item) => item.active),
                                textKey: 'name',
                                valueKey: 'item_master_id',
                                isRequired: true,
                                disabled: !isActive || metrcIsImported || requireCategory && !lines[index].category_id
                              }}
                            />
                            <div style={{marginBottom: '12px'}} className='text-danger'>
                              <WillRender ifTrue = {checkIfItemIsLotTracked(item)}>{I18n.t('purchaseOrders.form.notLotTracked')}</WillRender>
                            </div>
                            <Field
                              name={`${line}.expected_storage_location_id`}
                              component={ReactSelectInput}
                              props={{
                                label: I18n.t('purchaseOrders.form.storageLocation'),
                                options: (item.itemMaster && item.itemMaster.inventory_attributes && item.itemMaster.inventory_attributes.is_ingredient)
                                  ? filteredLocations.filter((location) => !location.is_sales_location)
                                  : filteredLocations,
                                disabled: !isActive
                              }}
                            />
                          </div>)
                    }
                  </Col>)
              }
              {numberOfLinesIsNotValid ?
                <Row>
                  <Col md={11}>
                    <Alert variant='danger'>
                      <FaExclamationTriangle />
                      <span className='padding-left has-error'>{meta.error}</span>
                    </Alert>
                  </Col>
                </Row> : null}
              {!numberOfLinesIsNotValid && item.lineType === 'bulk' && (partnerId !== null || isInitialMode) ?
                <BulkLineItemWrapper
                  removeFromPo={removeFromPo}
                  index={index}
                  transferRecord={transferRecord}
                  change={change}
                  line={line}
                  lineItem={item}
                  parentPurchaseOrderLineItem={lines.find((line) => line.isParentPurchaseOrderLine && line.item_master_id === item.item_master_id)}
                  isActive={isActive}
                  uom={uom}
                  uoms={uoms}
                  itemMaster={item.itemMaster}
                  metrcIsImported={metrcIsImported}/> : null}
              {!numberOfLinesIsNotValid && item.lineType === 'unit' && (partnerId !== null || isInitialMode) ?
                <UnitLineItem
                  removeFromPo={removeFromPo}
                  index={index}
                  transferRecord={transferRecord}
                  change={change}
                  line={line}
                  lineItem={item}
                  isActive={isActive}
                  itemMaster={item.itemMaster}
                  uom={uom}
                  metrcIsImported={!!metrcIsImported}/> : null}
              {!numberOfLinesIsNotValid && item.lineType === 'prepack' && (partnerId !== null || isInitialMode) ?
                <PrePackLineItem
                  removeFromPo={removeFromPo}
                  index={index}
                  line={line}
                  item={item}
                  change={change}
                  showIdColumn={false}
                  isActive={isActive}
                  lineItem={item}
                  uom={uom}
                  transferRecord={transferRecord}
                  metrcIsImported={metrcIsImported}/> : null}
              {
                item.lineStatus && item.lineStatus !== null
                  ? null
                  : (<Col xs={12}><hr/></Col>)
              }
            </Row>
          );
        })}
        {metrcIsImported || !isActive
          ? null
          :
          <AddItem
            length={length}
            textMode='left'
            alignMode='right'
            addAction={() => {push({editableLinePrice: false}); change('category_id', null);}}
            removeAction={() => pop()}
            text={I18n.t('purchaseOrders.form.addItem')}/>
        }
        {lines ? <p style={{ color: 'red' }}>{I18n.t('purchaseOrders.warning',{size:lines.length, items:lines.length > 1 ? 'items' : 'item', articulo:lines.length > 1 ? 'articulos' : 'articulo'})}</p> : ''}


    </Col>
  </Row>);
};

OrderLines.propTypes = {
  partnerId: PropTypes.number,
  itemMasters: PropTypes.array.isRequired,
  storageLocations: PropTypes.array.isRequired,
  sharedLocations: PropTypes.array.isRequired,
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    pop: PropTypes.func.isRequired,
    get: PropTypes.func.isRequired,
    getAll: PropTypes.func.isRequired,
    length: PropTypes.number.isRequired,
  }),
  lines: PropTypes.array,
  change: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  transferRecord: PropTypes.bool,
  uoms: PropTypes.array.isRequired,
  showMedicatedStatusMessage: PropTypes.bool,
  showNoPartnerProductsMessage: PropTypes.bool,
  isInitialMode: PropTypes.bool,
  usedItemMasterIds: PropTypes.array,
  meta: PropTypes.object.isRequired,
  transferLinesLimit: PropTypes.number,
  modifiedLinesByItemMaster: PropTypes.array,
  metrcIsImported: PropTypes.bool,
  categories: PropTypes.array.isRequired,
  isReturn: PropTypes.bool,
  isEditMode: PropTypes.bool,
};

OrderLines.defaultProps = {
  isActive: true,
  showMedicatedStatusMessage: false,
  showNoPartnerProductsMessage: false,
  modifiedLinesByItemMaster: [],
};

const selector = formValueSelector(purchaseOrderForm);
function mapStateToProps(state){
  const lines = selector(state, 'lines');
  return {
    usedItemMasterIds: Array.isArray(lines) ? lines.filter((line) => !line.isParentPurchaseOrderLine).map((line) => line.item_master_id) : [],
  };
}

export default connect(mapStateToProps)(OrderLines);
