import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {deleteItem} from '../../../../actions/apiActions';
import ImageFieldComponent from './ImageFieldComponent';

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({deleteItem}, dispatch),
  };
}

const ImageField = connect(null, mapDispatchToProps)(ImageFieldComponent);

ImageField.propTypes = {
  name: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
  ]),
  fileValue: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  change: PropTypes.func.isRequired,
  imageType: PropTypes.shape({
    name: PropTypes.string.isRequired,
    fileType: PropTypes.string.isRequired,
    thumbnail: PropTypes.string,
    limit: PropTypes.string,
  }).isRequired,
  thumbnail: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
};

export default ImageField;
