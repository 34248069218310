import React from 'react';
import PropTypes from 'prop-types';

import {I18n} from 'react-redux-i18n';
import {Field, reduxForm} from 'redux-form';
import {Modal, Button, Row, Col} from 'react-bootstrap';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import MmuLimit from './MmuLimit';

const validate = (values) => {
  const errors = {};
  if(isNaN(parseInt(values.visit_reason_id))) errors.visit_reason_id = 'Please select a visit reason.';
  return errors;
};

export const CustomerCheckInModal = (props) => {

  const {showModal, close, reset, submitting, valid, handleSubmit, checkIn, budTenders, visitReasons, disabled, caregiver, caregivers, customer, isMmuEnabled} = props;

  const closeAndReset = () => {
    close();
    reset();
  };

  const csrs = budTenders.map((csr) => {
    csr.id = parseInt(csr.id);
    return csr;
  });

  const handleSubmitClick = (formValues) => {
    checkIn(formValues);
    closeAndReset();
  };

  return (
    <Modal show={showModal} onHide={closeAndReset} className='check-in-modal'>
      <Modal.Header closeButton>
        <h3>{I18n.t('customers.table.checkIn')}</h3>
      </Modal.Header>
      <Modal.Body>
        <form>
          {isMmuEnabled &&
            <React.Fragment>
              <MmuLimit
                customerId={customer.id}
              />
              <br/>
            </React.Fragment>
          }
          <Row>
            <Col md={6}>
              <Field
                name='preferred_csr'
                component={ReactSelectInput}
                props={{
                  placeholder: I18n.t('common.form.select'),
                  label: I18n.t('customers.table.preferredBudTender'),
                  options: csrs,
                  textKey: 'name',
                  valueKey: 'id'
                }}/>
            </Col>
            <Col md={6}>
              <Field
                name='visit_reason_id'
                component={ReactSelectInput}
                props={{
                  placeholder: I18n.t('common.form.select'),
                  label: I18n.t('customers.table.visitReason'),
                  options: visitReasons,
                  textKey: 'name',
                  valueKey: 'id'
                }}/>
            </Col>
            {
              caregivers.length === 0 || caregiver !== undefined
              ? null
                : (<Col md={6}>
                  <Field
                    name='caregiver_id'
                    component={ReactSelectInput}
                    props={{
                      placeholder: I18n.t('common.form.select'),
                      label: I18n.t('customers.table.caregivers'),
                      options: caregivers,
                      textKey: 'full_name',
                      valueKey: 'id'
                    }}>
                    <div className='text-muted' style={{fontSize: 'smaller'}}>{I18n.t('customers.table.caregiversNote')}</div>
                  </Field>
                </Col>)
            }
          </Row>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Row>
          <Button
            disabled={submitting || !valid || disabled}
            onClick={handleSubmit(handleSubmitClick)}
            className='save-button'
            variant='primary'>
              {I18n.t('common.form.save')}
            </Button>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

CustomerCheckInModal.propTypes = {
  customer: PropTypes.object,
  budTenders: PropTypes.array.isRequired,
  showModal: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  checkIn: PropTypes.func.isRequired,
  visitReasons: PropTypes.array,
  disabled: PropTypes.bool,
  caregiver: PropTypes.object,
  caregivers: PropTypes.array.isRequired,
  isMmuEnabled: PropTypes.bool
};

export default reduxForm({
  form: 'check-in-patient',
  validate,
  enableReinitialize: true,
})(CustomerCheckInModal);
