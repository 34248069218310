import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Button, Col, FormGroup, Row } from 'react-bootstrap';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import TextInput from '../../common/form/TextInput';
import InternationalReduxPhoneInput from '../../common/form/redux-form/InternationalReduxPhoneInput';

export const DriverAndVehicleInformation = (props) => {
  const {drivers, vehicles, createDriver} = props;

  return (
    <FormGroup className='driver-info'>
      <h3>{I18n.t('generateManifest.form.driverInfo')}</h3>
      <Row>
        <Col xs={6} sm={4} md={3}>
          <Field
            name={`driver_id`}
            component={ReactSelectInput}
            props={{
              label: I18n.t('generateManifest.form.name'),
              options: drivers,
              valueKey: 'id',
              textKey: 'full_name'
            }}
          >
            <Button
              variant='link'
              className='label-link'
              onClick={createDriver}>
              {I18n.t('generateManifest.form.createDriver')}
            </Button>
          </Field>
        </Col>
        <Col xs={6} sm={4} md={3}>
          <Field
            name={`state_license_number`}
            component={TextInput}
            props={{
              label: I18n.t('generateManifest.form.stateLicense'),
              disabled: true
            }}
          />
        </Col>
        <Col xs={6} sm={4} md={3}>
          <Field
            name={`license_number`}
            component={TextInput}
            props={{
              label: I18n.t('generateManifest.form.driverLicense'),
              disabled: true
            }}
          />
        </Col>
        <Col xs={6} sm={4} md={3}>
          <InternationalReduxPhoneInput
            name={`phone_number`}
            props={{
              label: I18n.t('generateManifest.form.phoneNumber'),
              disabled: true
            }}
          />
        </Col>
      </Row>

      <div>
        <h3>{I18n.t('generateManifest.form.vehicleInfo')}</h3>
        <Row>
          <Col xs={6} sm={4} md={3}>
            <Field name='vehicle_id'
              component={ReactSelectInput}
              props={{
                label: I18n.t('generateManifest.form.description'),
                options: vehicles,
                valueKey: 'id',
                textKey: 'nickname'
              }}
            />
          </Col>
          <Col xs={6} sm={4} md={3}>
            <Field
              name='vehicle_make'
              component={TextInput}
              props={{
                label: I18n.t('cultivation.transfers.form.make'),
                disabled: true
              }}
            />
          </Col>
          <Col xs={6} sm={4} md={3}>
            <Field name='vehicle_model'
              component={TextInput}
              props={{
                label: I18n.t('cultivation.transfers.form.model'),
                disabled: true
              }}
            />
          </Col>
          <Col xs={6} sm={4} md={3}>
            <Field name='vehicle_license_plate'
              component={TextInput}
              props={{
                label: I18n.t('cultivation.transfers.form.licensePlate'),
                disabled: true
              }}
            />
          </Col>
        </Row>
      </div>
    </FormGroup>
  );
};

DriverAndVehicleInformation.propTypes = {
  drivers: PropTypes.array.isRequired,
  vehicles: PropTypes.array.isRequired,
  createDriver: PropTypes.func.isRequired
};

export default DriverAndVehicleInformation;
