import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import uniqBy from 'lodash.uniqby';
import {Col, Row, Button, Alert} from 'react-bootstrap';
import {FaExclamationTriangle, FaTrash} from 'react-icons/fa';
import TextInput from '../../common/form/TextInput';
import AddItem from '../../common/form/AddItem';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import AccordionPanel from '../../common/AccordionPanel';
import ContentConcealer from '../../common/concealers/ContentConcealer';
import {DEA_NUMBER, STATE} from '../../../constants/physicianLicenseTypes';

const PhysicianLicensesFieldArray = (props) => {
  const {fields, statuses, inactiveStatuses, activeLicenseIndex, integrationState: {isOhMetrc}, licenses, loading, meta} = props;
  const licenseTypes = [
    {text: I18n.t('physicians.form.deaNumberType'), value: DEA_NUMBER},
    {text: I18n.t('physicians.form.stateType'), value: STATE},
  ];
  const existLicenseTypes = uniqBy(licenses, 'license_type').map(item => item.license_type);
  let allowedLicenseTypes = licenseTypes;
  return (
    <AccordionPanel title={'physicians.form.physicianLicenses'}>
      {!loading && meta && meta.error ?
        <Row>
          <Col xs={12}>
            <Alert variant='danger'>
              <FaExclamationTriangle/>
              <span className='padding-left'>{meta.error}</span>
            </Alert>
          </Col>
        </Row>
        :
        null
      }
      {fields.map((fieldName, fieldIndex) => {
        const statusOptions = activeLicenseIndex === -1 || activeLicenseIndex === fieldIndex || isOhMetrc ? statuses : inactiveStatuses;
        if (isOhMetrc) {
          const currentValue = licenses[fieldIndex];
          allowedLicenseTypes = licenseTypes.filter(
            item => !existLicenseTypes.includes(item.value) || currentValue.license_type === item.value
          );
        }
        return (
          <div key={fieldName}>
            <Row>
              <ContentConcealer show={Boolean(isOhMetrc)}>
                <Col xs={5} sm={4} md={3}>
                  <Field name={`${fieldName}.license_type`} component={ReactSelectInput} props={{
                    label: I18n.t('physicians.form.licenseType'),
                    placeholder: I18n.t('physicians.form.licenseTypePlaceholder'),
                    options: allowedLicenseTypes,
                    isRequired: true,
                  }}/>
                </Col>
              </ContentConcealer>
              <Col xs={5} sm={4} md={3}>
                <Field name={`${fieldName}.license_number`} component={TextInput} props={{
                  label: I18n.t('physicians.form.license'),
                  placeholder: I18n.t('physicians.form.licensePlaceholder'),
                  isRequired: true,
                }}/>
              </Col>
              <Col xs={4} sm={3} md={2}>
                <Field name={`${fieldName}.active`} component={ReactSelectInput} props={{
                  label: I18n.t('physicians.form.status'),
                  options: statusOptions,
                  placeholder: I18n.t('common.form.select'),
                  isRequired: true,
                }}/>
              </Col>
              <Col xs={2} style={{paddingTop: '32px'}}>
                <Button size='sm' variant='danger' onClick={() => fields.remove(fieldIndex)} disabled={fields.length < 2}>
                  <FaTrash/>
                </Button>
              </Col>
            </Row>
          </div>
        );
      })}
      <Row>
        <AddItem
          addAction={() => fields.push({})}
          alignMode='right'
          text='physicians.form.addLicense'
          addDisabled={isOhMetrc && existLicenseTypes.length === licenseTypes.length}
        />
      </Row>
    </AccordionPanel>
  );
};

PhysicianLicensesFieldArray.propTypes = {
  fields: PropTypes.shape({
    length: PropTypes.number.isRequired,
    get: PropTypes.func.isRequired,
    map: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  statuses: PropTypes.array.isRequired,
  inactiveStatuses: PropTypes.array.isRequired,
  activeLicenseIndex: PropTypes.number.isRequired,
  licenses: PropTypes.array,
  integrationState: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  meta: PropTypes.object
};

export default PhysicianLicensesFieldArray;
