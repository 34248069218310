import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {goBack, push} from 'react-router-redux';
import get from 'lodash.get';
import {Tabs, Tab, Alert} from 'react-bootstrap';
import PageTitle from '../../../common/PageTitle';
import {getDataByPost, getUnpaginatedData} from '../../../../actions/apiActions';
import {unsetData} from '../../../../actions/dataActions';
import * as dataNames from '../../../../constants/dataNames';
import{getProductSalesReport} from '../../../../selectors/productSalesReportSelectors';
import FormWrapper from '../../../common/form/FormWrapper';
import TablePageWrapper from '../../../common/grid/TablePageWrapper';
import ProductSalesSearchForm from './ProductSalesSearchForm';
import {productSalesReportTabs, productSalesReportColumns} from './tableDefinitions';
import getSalesReportTabs from '../common/tabDefinitions';
import {getEnabledTabs} from '../../../../actions/reportActions';

export class ProductSalesReportPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.submitSearch = this.submitSearch.bind(this);
    this.switchTab = this.switchTab.bind(this);
    this.state = {
      tabs: [],
      activeTab: 'productSalesReport'
    };
  }

  componentWillMount() {
    const {actions: {unsetData}} = this.props;
    unsetData(dataNames.productSalesReport);
    //Item Masters
    this.props.actions.getUnpaginatedData('/api/item_masters', dataNames.sellableItemMasters, {}, {is_sales_item: 1, exclude_files: 1, select_columns: ['name', 'id']});
    // Categories
    this.props.actions.getUnpaginatedData('/api/categories', dataNames.categories);
    //Subcategories
    this.props.actions.getUnpaginatedData('/api/subcategories', dataNames.subcategories);

    this.setState({tabs: this.props.actions.getEnabledTabs(getSalesReportTabs)});
  }

  // Search form submit handler
  submitSearch(formValues) {

    const reportParams = {
      report_date: formValues.report_date.format('YYYY-MM-DD'),
      order_number: get(formValues,'order_number', null),
      item_master_id: get(formValues,'product', null),
      category_id: get(formValues,'category_id', null),
      subcategory_id: get(formValues,'subcategory_id', null),
    };

    this.props.actions.getDataByPost(
      '/api/orders/product_sales_report',
      reportParams,
      dataNames.productSalesReport,
      {failed: I18n.t('retail.productSalesReport.failed')}
    );
  }

  switchTab(activeTab) {
    const tab = this.state.tabs.find(tab => tab.eventKey === activeTab) || this.state.tabs[0];
    this.setState({activeTab});
    this.props.actions.push(tab.path);
  }

  render() {
    return (
      <div>
        <PageTitle primaryText={I18n.t('retail.productSalesReport.title')}/>
        <Tabs id='filterTabs' activeKey={this.state.activeTab} onSelect={this.switchTab}>
          {this.state.tabs.map((tab, index) =>
            <Tab key={index} eventKey={tab.eventKey} title={I18n.t(tab.title)} />
          )}
        </Tabs>
        <br />
        <FormWrapper
          className='transaction-report-form'
          goBack={this.props.actions.goBack}
          titleDescription={I18n.t('retail.productSalesReport.title')}
        >
          <Alert variant='warning'>
            Starting 2/14/22, Product Sales Report will only be available for previous 90 days. Please use Analytics module
            for Orders older than 90 days.
          </Alert>
          <ProductSalesSearchForm submitSearch={this.submitSearch} />
        </FormWrapper>
        <div className='hoverable-overflow'>
          <TablePageWrapper
            activeTab='default'
            bstProps={{
              defaultSortName: 'order_id',
              defaultSortOrder: 'asc'
            }}
            columns={productSalesReportColumns}
            data={this.props.productSalesReport}
            dataTotalSize={this.props.productSalesReport.length}
            external={false}
            hideExport={false}
            hideScanSearch={true}
            hideSearch={true}
            defaultHiddenColumns={[
              'tax_total', 'consumer_number', 'product_count',
              'completed_date_by_facility_timezone', 'completed_time_by_facility_timezone'
            ]}
            ref={this.ref}
            settingKey='productSalesReport'
            tabs={productSalesReportTabs}
          />
        </div>
      </div>
    );
  }
}

ProductSalesReportPage.propTypes = {
  actions: PropTypes.shape({
    getUnpaginatedData: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    getEnabledTabs: PropTypes.func,
  }),
  transactionReport: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  return {
    productSalesReport: getProductSalesReport(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {getDataByPost, getUnpaginatedData, goBack, push, unsetData, getEnabledTabs};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductSalesReportPage);
