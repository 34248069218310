import {change, formValueSelector} from 'redux-form';
import find from 'lodash.find';
import get from 'lodash.get';
import {INVENTORY_RECEIPT_FORM} from '../../constants/forms';
import { isChangeAction, isBlurAction, isArrayPush } from './utils';
import getFormArrayIndexFromString from '../../util/formHelpers';
import * as dataNames from '../../constants/dataNames';
import {roundFloat} from '../../util/mathHelpers';
import {convertFromBase} from '../../util/uomHelpers';
import {buildCompleteInternationalPhoneNumber}  from '../../util/formatHelper';
import {getPhoneShouldIncludeCountryCode} from '../../selectors/InternationalOperationsSelectors';
import * as itemNames from '../../constants/itemNames';


const inventoryReceiptForm = store => next => action => {
  const {meta, payload} = action;
  const result = next(action);

  if(!meta || meta && meta.form !== INVENTORY_RECEIPT_FORM){
    return result;
  }

  const selector = formValueSelector(INVENTORY_RECEIPT_FORM);
  const state = store.getState();

  // Don't run this middleware when in reassign mode
  if (state[itemNames.reassignPackages] && get(state[itemNames.reassignPackages], 'enable' ,false)) {
    return result;
  }

  if(isChangeAction(action, [meta.form]) && meta.field.indexOf('.line_item_price') !== -1 && payload){
    const lineItemPrice = parseFloat(payload);
    const lineIndex = getFormArrayIndexFromString(meta.field);
    const getFieldName = getFieldNameFunction(meta, 'line_item_price');
    const setUpdateLine = () => store.dispatch(change(meta.form, getFieldName('update_purchase_order_line'), 1));
    const editableLineItemPrice = selector(state, getFieldName('editableLinePrice'));
    let unitPrice = selector(state, getFieldName('unit_price'));
    if(editableLineItemPrice){
      const quantity = selector(state, getFieldName('qty'));
      unitPrice = lineItemPrice / quantity;
      store.dispatch(change(meta.form, getFieldName('unit_price'), unitPrice));
    }
    const lineKey = meta.field.split('.')[0];
    const line = selector(state, lineKey);
    updatePrepacks(meta, line, lineIndex, unitPrice, store);
    setUpdateLine();
  }

  if(isChangeAction(action, [meta.form]) && meta.field.indexOf('.ordered_qty') !== -1 && payload){
    const getFieldName = getFieldNameFunction(meta, 'ordered_qty');
    store.dispatch(change(meta.form, getFieldName('update_purchase_order_line'), 1));
  }

  if(isChangeAction(action, [meta.form]) && meta.field.indexOf('inventory') !== -1 && meta.field.indexOf('.item_master_id') !== -1 && payload){
    const getFieldName = getFieldNameFunction(meta, 'item_master_id');
    const temp = meta.field.split('.');
    const linePrefix = temp[0];
    const unitPriceKey = `${linePrefix}.unit_price`;
    const unitPrice = parseFloat(selector(state, unitPriceKey));
    const quantity = selector(state, getFieldName('qty'));
    const {itemMastersHash, prepackWeightsHash} = getPrepackData(state);
    const itemMasterId = parseInt(payload);
    const itemMaster = itemMastersHash[itemMasterId];
    const prepackWeight = prepackWeightsHash[get(itemMaster, 'inventory_attributes.prepack_weight_id', 0)];
    const unitCost = unitPrice * prepackWeight.calculatedWeight;
    store.dispatch(change(meta.form, getFieldName('unit_cost'), unitCost));
    store.dispatch(change(meta.form, getFieldName('name'), get(itemMaster, 'name', '')));
    const quantityMeta = Object.assign({}, meta, {field: getFieldName('qty')});
    handleInventoryQuantityChange(quantityMeta, quantity, store);
  }

  if(isChangeAction(action, [INVENTORY_RECEIPT_FORM]) && meta.field === 'driver.id'){
    const state = store.getState();
    const drivers = state[dataNames.drivers];
    const driver = find(drivers, {id: payload});
    const default_vehicle_id = get(driver, 'default_vehicle_id', null);

    const includeCountryCode = getPhoneShouldIncludeCountryCode(state);

    store.dispatch(change(meta.form, 'driver.state_compliance_number', driver ? driver.state_compliance_number : null));
    store.dispatch(change(meta.form, 'driver.drivers_license_number', driver ? driver.drivers_license_number : null));
    store.dispatch(change(meta.form, 'driver.phone_number', driver && driver.driver_phones && driver.driver_phones[0] ?
      buildCompleteInternationalPhoneNumber(driver.driver_phones[0].number, driver.driver_phones[0].phone_country_code, includeCountryCode) : null));

    const vehicles = state[dataNames.vehicles];
    const vehicle = find(vehicles, {id: default_vehicle_id});
    store.dispatch(change(meta.form, 'vehicle.id', default_vehicle_id));
    store.dispatch(change(meta.form, 'vehicle.make', vehicle ? vehicle.make : null));
    store.dispatch(change(meta.form, 'vehicle.model', vehicle ? vehicle.model : null));
    store.dispatch(change(meta.form, 'vehicle.license_number', vehicle ? vehicle.license_number : null));
  }

  // Implemented specifically for ingredients to step around the editable field total calculation.  One tiny step to refactor... yay!
  if(isChangeAction(action, [INVENTORY_RECEIPT_FORM]) && meta.field.endsWith('.unit_price') ) {
    const state = store.getState();
    const selector = formValueSelector(INVENTORY_RECEIPT_FORM);

    const isEditingLineItemPrice = selector(state, meta.field.replace('unit_price', 'editableLinePrice'));
    const quantity = parseFloat(selector(state, meta.field.replace('unit_price', 'qty')));
    const unitPrice = parseFloat(payload);

    if (!isEditingLineItemPrice) { // If the line item price was the source of this change lets not then over write it.  :-)
      const newLineItemPrice = quantity * unitPrice;
      store.dispatch(change(meta.form, meta.field.replace('unit_price', 'line_item_price'), roundFloat(newLineItemPrice)));
    }
  }

  // REFACTOR/RESTRUCTURE - This blur handler being in the middleware suggests some major issues, onBlur handlers
  // should be set onto a field itself and the handler should be defined inside the component implementing that field
  // this was really difficult to track down.  If our design has forced our hand into this as a solution, it should be
  // a red flag that something is wrong and maybe changing direction and designing things differently is in order.  In all
  // my years I've never had to bind a field specific handler into a global registry
  if(isBlurAction(action, [INVENTORY_RECEIPT_FORM]) && meta.field.endsWith('.unit_price') ) {
    const state = store.getState();
    const selector = formValueSelector(INVENTORY_RECEIPT_FORM);
    const temp = meta.field.split('.');
    const prefix = temp.shift();
    const unitPrice = parseFloat(selector(state, `${prefix}.unit_price`) || '0.00');

    store.dispatch(change(meta.form, `${prefix}.unit_price`, unitPrice.toFixed(2)));
  }

  // Handles line item quantity
  if (isChangeAction(action, [INVENTORY_RECEIPT_FORM]) && meta.field.endsWith('.qty') && meta.field.indexOf('inventory') === -1) {
    const temp = meta.field.split('.');
    const prefix = temp.shift();
    const state = store.getState();
    const selector = formValueSelector(INVENTORY_RECEIPT_FORM);
    const unitPrice = parseFloat(selector(state, `${prefix}.unit_price`));
    const quantity = parseFloat(payload);
    const newLineItemPrice = quantity * unitPrice;
    store.dispatch(change(meta.form, `${prefix}.line_item_price`, newLineItemPrice.toFixed(2)));
  }

  // Handles inventory level quantity
  if (isChangeAction(action, [INVENTORY_RECEIPT_FORM]) && meta.field.endsWith('.qty') && meta.field.indexOf('inventory') !== -1) {
    handleInventoryQuantityChange(meta, payload, store);
  }

  if(isArrayPush(action, [INVENTORY_RECEIPT_FORM]) && meta.field.indexOf('inventory') !== -1){
    const temp = meta.field.split('.');
    const prefix = temp[0];
    const setField = `${prefix}.qty`;
    const selector = formValueSelector(INVENTORY_RECEIPT_FORM);
    const state = store.getState();
    const inventory = selector(state, meta.field);
    const quantitySum = (Array.isArray(inventory) ? inventory : []).reduce((acc, item, index) => {
      const qty = get(item, 'qty', 0);
      const quantity = parseFloat(qty);
      acc += isNaN(quantity) ? 0 : quantity;
      return acc;
    }, 0) + parseFloat(get(action, 'payload.qty', 0));
    store.dispatch(change(meta.form, setField, quantitySum));
  }

  if(isChangeAction(action, [INVENTORY_RECEIPT_FORM]) && meta.field === 'default_storage_location') {
    const state = store.getState();
    const selector = formValueSelector(INVENTORY_RECEIPT_FORM);
    const value = selector(state, 'default_storage_location');

    const purchaseOrder = get(state, 'purchaseOrder', {lines: []});
    purchaseOrder.lines.forEach((line, lineIndex) => {
      // Each PO line can receive multiple inventories
      const inventoryItems = selector(state, `lines[${lineIndex}].inventory`);
      // Handle all inventory items created
      inventoryItems.forEach((inventory, itemIndex) => {
        store.dispatch(change(meta.form, `lines[${lineIndex}].inventory[${itemIndex}].storage_location_id`, value));

        // Handle prepacks
        inventory.prepack_inventory_rows && inventory.prepack_inventory_rows.forEach((prepack, ppIndex) => {
          store.dispatch(change(meta.form, `lines[${lineIndex}].inventory[${itemIndex}].prepack_inventory_rows[${ppIndex}].storage_location_id`, value));
        });
      });
    });
  }

  return result;
};


const updatePrepacks = (meta, line, lineIndex, unitPrice, store) => {
  const state = store.getState();
  const isPrepack = get(line, 'itemMaster.inventory_attributes.is_prepack', false);
  if(!isPrepack) return false;
  const {itemMastersHash, prepackWeightsHash} = getPrepackData(state);
  get(line, 'inventory', []).forEach((inventoryLine, inventoryIndex) => {
    get(inventoryLine, 'prepack_inventory_rows', []).forEach((row, rowIndex) => {
      const itemMaster = itemMastersHash[get(row, 'item_master_id', 0)];
      const prepackWeight = prepackWeightsHash[get(itemMaster, 'inventory_attributes.prepack_weight_id', 0)];
      const rowUnitPrice = unitPrice * get(prepackWeight, 'calculatedWeight', 0);
      const fieldKey = `lines[${lineIndex}].inventory[${inventoryIndex}].prepack_inventory_rows[${rowIndex}].unit_cost`;
      store.dispatch(change(meta.form, fieldKey, rowUnitPrice));
    });
  });
  return true;
};


const handleInventoryQuantityChange = (meta, payload, store) => {
  const temp = meta.field.split('.');
  const linePrefix = temp[0];
  const newQuantity = parseFloat(payload);
  const isPrepackQuantity = meta.field.indexOf('prepack_inventory_rows') !== -1;
  const indexPrefix = isPrepackQuantity ? 'prepack_inventory_rows' : 'inventory';
  const newQuantityIndex = getFormArrayIndexFromString(temp[1], indexPrefix);
  const setField = `${linePrefix}.qty`;
  const state = store.getState();
  const selector = formValueSelector(INVENTORY_RECEIPT_FORM);
  const inventory = selector(state, `${linePrefix}.inventory`);

  const isMatchingIndex = (index, type) => {
    return type === 'inventory'
      ? !isPrepackQuantity && index === newQuantityIndex
      : isPrepackQuantity && index === newQuantityIndex;
  };

  const {itemMastersHash, prepackWeightsHash} = getPrepackData(state);

  const calculatePrepackQuantity = (item, index) => {
    const quantity = parseFloat(isMatchingIndex(index, 'prepack_inventory_rows') ? newQuantity : get(item, 'qty', 0));
    const itemMaster = itemMastersHash[get(item, 'item_master_id', 0)];
    const prepackWeight = prepackWeightsHash[get(itemMaster, 'inventory_attributes.prepack_weight_id', 0)];
    return quantity * prepackWeight.calculatedWeight;
  };

  const sum = (Array.isArray(inventory) ? inventory : []).reduce((acc, item, index) => {
    const quantity = !isPrepackQuantity
      ? parseFloat(isMatchingIndex(index) ? newQuantity : get(item, 'qty', 0))
      : get(item, 'prepack_inventory_rows', []).reduce((acc, item, index) => {
        acc += calculatePrepackQuantity(item, index);
        return acc;
      }, 0);
    acc += isNaN(quantity) ? 0 : quantity;
    return acc;
  }, 0);
  store.dispatch(change(meta.form, setField, sum));
};

export const getFieldNameFunction = (meta, initialFieldName) => {
  return (fieldName) => {
    return meta.field.replace(initialFieldName, fieldName);
  };
};

export const getPrepackData = (state, itemMasterTargets = [dataNames.itemMasters, dataNames.childItemMasters], prepackWeightTargets = [dataNames.prepackWeights]) => {
  const normalizeItemMaster = (itemMaster) => {
    if(get(itemMaster, 'inventory_attributes', false)){
      return itemMaster;
    }
    return Object.assign({}, itemMaster, {
      inventory_attributes: {
        is_prepack: get(itemMaster, 'is_prepack', 0),
        prepack_weight_id: get(itemMaster, 'prepack_weight_id', 0)
      }
    });
  };

  const itemMastersHash = itemMasterTargets.reduce((acc, target) => {
    return acc.concat(state[target] ? state[target] : []);
  }, [])
    .reduce((acc, itemMaster) => {
      const id = get(itemMaster, 'id', 0);
      if(!acc[id]){
        acc[id] = normalizeItemMaster(itemMaster);
      }
      return acc;
    }, {});
  const prepackWeightsHash = prepackWeightTargets.reduce((acc, target) => {
    return acc.concat(state[target] ? state[target] : []);
  }, [])
    .reduce((acc, prepackWeight) => {
      const id = get(prepackWeight, 'prepack_weight_id', 0);
      if(!acc[id]){
        prepackWeight.calculatedWeight = convertFromBase(prepackWeight.weight_base, prepackWeight.uom);
        acc[id] = prepackWeight;
      }
      return acc;
    }, {});
  return {itemMastersHash, prepackWeightsHash};
};

export default inventoryReceiptForm;
