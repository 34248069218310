import {createSelector} from 'reselect';
import {formValueSelector} from 'redux-form';
import orderBy from 'lodash.orderby';
import get from 'lodash.get';
import {MANAGE_USER_ROLES} from '../../constants/forms';
import * as dataNames from '../../constants/dataNames';
import {getOrderedPermissions, getOrderedRoles} from '../userRolesSelector';
import {getIntegrationState} from '../integration/integrationSelectors';
import {isFeatureEnabled} from '../../selectors/featureToggles';
import { getFacility } from '../facilitiesSelectors';
import { isGeoPermByStateExcluded, isGeoPermByCountryExcluded } from '../../util/permissionHelpers';

const getModules = state => state[dataNames.modules];
const selector = formValueSelector(MANAGE_USER_ROLES);
export const getManageUserRolesFormField = field => state => selector(state, field);
const getFormRoleName = getManageUserRolesFormField('role_name');
export const getManageUserRolesFormRoles = getManageUserRolesFormField('roles');


export const isAddRoleDisabled = createSelector(
  [getFormRoleName],
  (role_name) => !role_name
);

export const getAllPermissionsByModule = createSelector(
  [getModules, getOrderedPermissions],
  (modules, permissions) => modules.reduce(
    (acc, module) => ({
      ...acc,
      [module.id]: {
        module,
        permissions: permissions.filter(permission => permission.module_id === module.id)
      },
    }),
    {}
  )
);

export const getFilteredPermissionsByModule = createSelector(
  [getModules, getOrderedPermissions, getIntegrationState],
  (modules, permissions, integrationState) => {
    const {isHemp, isPaLeaf} = integrationState;

    return modules.reduce(
      (acc, module) => ({
        ...acc,
        [module.id]: {
          module,
          permissions: permissions.filter(permission => {
            if (permission.module_id !== module.id) return false;

            const permissionKey = permission.permission_key;

            // Exclude the permission for these rule sets
            if (
              (!isHemp && permissionKey === 'manage_plant_batch_testing') ||
              (!isPaLeaf && permissionKey === 'manage_pennsylvania_tax_notifications')
            ) return false;

            return true;
          })
        },
      }),
      {}
    );
  }
);

export const getPushedRolePayload = createSelector(
  [getFormRoleName, getAllPermissionsByModule],
  (role_name, permissionsByModule) => {
    return {
      role_name,
      is_default_role: false,
      modules: Object.keys(permissionsByModule).map((moduleId) => {
        const {module, permissions} = permissionsByModule[moduleId];
        return {
          ...module,
          permissions: permissions.map(({id, permission_name}) => ({
            id,
            permission_name,
            enabled: false,
          })),
        };
      }),
    };
  }
);


export const getManageUserRolesInitialValues = createSelector(
  [getOrderedRoles, getFilteredPermissionsByModule, isFeatureEnabled, getFacility],
  (orderedRoles, permissionsByModule, isFeatureEnabled, facility ) => {

    const roles = orderedRoles.map((role) => {
      const isInCultivation = role.role_name.indexOf('Cultivation:') !== -1;
      const modules = Object.keys(permissionsByModule).filter((moduleId) => permissionsByModule[moduleId].permissions.length > 0).map((moduleId) => {
        const {module, permissions} = permissionsByModule[moduleId];

        const usePermissions = permissions.filter((permission) => {
          const permissionKey = get(permission, 'permission_key', '');
          // If not in a cultivation role remove 'manage_plant_batch_testing' (if it hasn't already been removed
          // in getFilteredPermissionsByModule based on integrations)
          if (!isInCultivation && module.module_key === 'CULTIVATION' && permissionKey === 'manage_plant_batch_testing') {
            return false;
          }

          // Not in Oklahoma - don't get OK perms
          if(isGeoPermByStateExcluded(facility, 'ok', permissionKey)){
            return false;
          }

          // Not in Canada - no Canada perms for you
          if(isGeoPermByCountryExcluded(facility, 'ca', permissionKey)){
            return false;
          }

          // If the feature is not enabled, don't show delivery permisions
          if (!isFeatureEnabled('feature_courier_licensees') && permissionKey === 'manage_retail_delivery_orders') {
            return false;
          }

          if (!isFeatureEnabled('feature_manage_merchant_accounts') && permissionKey === 'manage_merchant_accounts') {
            return false;
          }

          return true;
        });

        return {
          ...module,
          permissions: usePermissions.map(({id, permission_name}) => ({
            id,
            permission_name,
            enabled: role.permissions.indexOf(id) > -1
          }))
        };
      });

      return {
        ...role,
        modules: orderBy(modules, 'module_name', 'asc'),
      };
    }).filter(role => {
      const hasNotEmptyModules = Object.keys(permissionsByModule).filter((moduleId) => permissionsByModule[moduleId].permissions.length > 0).length > 0;
      return hasNotEmptyModules;
    });

    return { roles };
  }
);

export const getManageUserRolesPayload = (values, initialValues) => {
  const roles = values.roles.map(({id, role_name, is_default_role, modules}) => {
    const rolePayload = {
     //module_ids: modules.map(m => m.id),
      permission_ids: modules.reduce((permissions, module) => permissions.concat(module.permissions.filter(p => p.enabled).map(p => p.id)), []),
    };
    if (id) {
      rolePayload.id = id;
    }
    if (role_name) {
      rolePayload.role_name = role_name;
    }

    return rolePayload;
  });
  const delete_roles = initialValues.roles.filter(role => !role.is_default_role && !values.roles.some(r => r.id === role.id)).map(r => r.id);

  return {
    roles,
    delete_roles,
  };
};
