import {I18n} from 'react-redux-i18n';

export const validate = (values) => {
  const errors = {};
  if (!values.inventory_location_id) {
    errors.inventory_location_id = I18n.t('packaging.form.selectStorageLocation');
  }
  if (!values.user_ids || !values.user_ids.length) {
    errors.user_ids = I18n.t('packaging.form.selectEmployee');
  }
  if (!values.packaging_time) {
    errors.packaging_time = I18n.t('packaging.form.enterPackagingTime');
  }
  return errors;
};

export default validate;