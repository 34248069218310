import createHarvestPackages from './createHarvestPackagesReducer';
import createPlantForm from './createPlantFormReducer';
import rewardSettingsForm from './rewardSettingsReducer';
import setupStorageLocations from './setupStorageLocationsReducer';

export default {
  createHarvestPackages,
  createPlantForm,
  rewardSettingsForm,
  setupStorageLocations
};
