import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Card, Image} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import {PERSON} from '../../../constants/imageUrls';

import AccordionPanel from '../../common/AccordionPanel';
import CustomerDetail from '../common/CustomerDetail';
// eslint-disable-next-line import/no-named-as-default
import CustomerActionButtons from './CustomerActionButtons';

const CustomerPanel = ({consumer, ready, timezone, onSelectCustomer,
  budTenders, selectedCustomers}) => {

  const groups = consumer.groups.filter(group => group.active);
  return (<Card className='customer-panel clearfix' body>
      <Row>
        <Col xs={12} sm={4} md={3} lg={2}>
          <Image src={consumer.src || PERSON} width={'100%'}/>
        </Col>
        <Col xs={12} sm={8} md={9} lg={10}>
          <Row>
            <Col xs={12} sm={4} md={3} lg={2}>
              <label>{I18n.t('consumers.checkin.name')}</label><br/>
              {consumer.first_name} {consumer.last_name}
            </Col>
            {consumer.goes_by ?
              <Col xs={12} sm={4} md={3} lg={2}>
                <label>{I18n.t('consumers.checkin.goesBy')}</label><br/>
                {consumer.goes_by}
              </Col>
            : null}
            {<ConsumerType consumerType={consumer.type}/>}
            <Col xs={12} sm={4} md={2} lg={1}>
              <label>{I18n.t('consumers.checkin.code')}</label><br/>
              {consumer.consumer_code}
            </Col>
            <Col xs={12} sm={4} md={3} lg={2}>
              <label>{I18n.t('consumers.checkin.phoneNumber')}</label><br/>
              {consumer.default_phone_number}
            </Col>
            <Col xs={12} sm={4} md={3} lg={2}>
              <label>{I18n.t('consumers.checkin.email')}</label><br/>
              {consumer.email_address}
            </Col>
            {consumer.drivers_license_number && <Col xs={12} sm={4} md={2} lg={2}>
              <label>{I18n.t('consumers.checkin.driversLicense')}</label><br/>
              {consumer.drivers_license_number}
            </Col>}
            {consumer.temp_license_number && <Col xs={12} sm={4} md={2} lg={2}>
              <label>{I18n.t('consumers.checkin.temporaryId')}</label><br/>
              {consumer.temp_license_number}
            </Col>}
            {consumer.med_license_number && <Col xs={12} sm={4} md={2} lg={2}>
              <label>{I18n.t('consumers.checkin.medicalId')}</label><br/>
              {consumer.med_license_number}
            </Col>}
            {groups && groups.length ? <Col xs={12} sm={4} md={2} lg={2}>
            <label>{I18n.t('consumers.checkin.groups')}</label><br/>
              {groups.map(group => <div key={group.id}>{group.group_name}<br/></div>)}
            </Col> : null}
          </Row>
        {ready && <Row>
          <Col xs={12} md={6}>
            <label>{I18n.t('consumers.checkin.actions')}</label><br/>
            <CustomerActionButtons consumer={consumer} budTenders = {budTenders} />
          </Col>
        </Row>}
        </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <hr />
            <AccordionPanel
              className='show-cursor'
              title='customers.checkin.details'
              defaultExpanded={false}
              onChangeEvent={(status) => {
                if(status) onSelectCustomer(consumer.id);
              }}
            >
              <div className={'customer-page'} >
                <CustomerDetail
                  timezone={timezone}
                  customerId={consumer.id}
                  loadCustomerData={selectedCustomers.indexOf(consumer.id) !== -1}
                />
              </div>
            </AccordionPanel>
          </Col>
        </Row>
      </Card>
    );
};

CustomerPanel.propTypes = {
  consumer: PropTypes.object.isRequired,
  startOrder: PropTypes.func.isRequired,
  checkIn: PropTypes.func.isRequired,
  modifyOrder: PropTypes.func.isRequired,
  fastTrack: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  ready: PropTypes.bool.isRequired,
  timezone: PropTypes.string.isRequired,
  goToQueue: PropTypes.func.isRequired,
  onSelectCustomer: PropTypes.func.isRequired,
  budTenders: PropTypes.array,
  selectedCustomers: PropTypes.array.isRequired,
};

export const consumerTypes = {
  recreational: {
    text: 'REC',
    color: '#82B2EC',
  },
  medical: {
    text: 'MED',
    color: '#CC4E00',
  }
};

// eslint-disable-next-line react/prop-types,react/no-multi-comp
const ConsumerType = ({ consumerType }) => {
  const style = {
    color: consumerTypes[consumerType]['color'],
    fontSize: '30px',
  };

  return (
    <Col xs={12} sm={4} md={3} lg={2}>
      <label>Customer Type</label><br/>
      <span style={style}>{consumerTypes[consumerType]['text']}</span>
    </Col>
  );

};

export default CustomerPanel;
