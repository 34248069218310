import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import InternationalReduxPhoneInput from '../../common/form/redux-form/InternationalReduxPhoneInput';
import TextInput from '../../common/form/TextInput';
import MultiselectInput from '../../common/form/MultiselectInput';

const Contact = ({contact, contactData, partnerFacilities}) => {

  return (
    <div className='form-panel contact-main-info'>
      <Row>
        <Col md={4}>
          {(contactData.id) && (
            <div className='hidden'>
              <Field
                name={`${contact}.id`}
                component={TextInput}
                props={{
                  type: 'hidden',
                }}
              />
            </div>
          )}
          <Field
            name={`${contact}.first_name`}
            component={TextInput}
            props={{
              label: I18n.t('partners.createPartner.firstName'),
              placeholder: I18n.t('partners.createPartner.firstName'),
              isRequired: true,
            }}
          />
        </Col>
        <Col md={4}>
          <Field
            name={`${contact}.last_name`}
            component={TextInput}
            props={{
              label: I18n.t('partners.createPartner.lastName'),
              placeholder: I18n.t('partners.createPartner.lastName'),
              isRequired: true,
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <InternationalReduxPhoneInput
            name={`${contact}.phone_number`}
            props={{
              label: I18n.t('partners.createPartner.phone')
            }}
          />
        </Col>
        <Col md={4}>
          <Field
            name={`${contact}.email_address`}
            component={TextInput}
            props={{
              label: I18n.t('common.form.email'),
              placeholder: I18n.t('common.form.email')
            }}
          />
        </Col>
        <Col md={4}>
          <Field
            name={`${contact}.facilities`}
            component={MultiselectInput}
            props={{
              label: I18n.t('partners.createPartner.facilities'),
              options: partnerFacilities,
              valueKey: 'id',
              textKey: 'facility_name',
              isRequired: true,
            }}
          />
        </Col>
      </Row>
    </div>
  );
};


Contact.propTypes = {
  contact: PropTypes.string.isRequired,
  contactData: PropTypes.object,
  partnerFacilities: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired
};

export default Contact;
