import {createSelector} from 'reselect';
import moment from 'moment';

import {isBulkType} from '../util/uomHelpers';
import * as itemNames from '../constants/itemNames';

const getPurchaseOrder = (state) => state.purchaseOrder;

const getInventoryReceipt = (state) => state.inventoryReceipt;

const getPartnerFacilities = (state) => state.partnerFacilities;

const getItemMasters = (state) => state.itemMasters;

const getChildItemMasters = (state) => state.childItemMasters;

export const getReassignMode = (state) =>  state[itemNames.reassignPackages];

export const getPurchaseOrderSources = createSelector([getPurchaseOrder, getPartnerFacilities],
  (purchaseOrder, partnerFacilities) => {
    const partnerFacility = partnerFacilities.find(pf => pf.id === purchaseOrder.vendor_facility_id);
    if(partnerFacility) {
      return {partner_facility_id: purchaseOrder.vendor_facility_id,
        partner_license_number: partnerFacility.partner.business_license,
        partner_dba: partnerFacility.legal_name
      };
    }
  });


export const getPurchaseOrderLines = createSelector([getPurchaseOrder, getItemMasters, getChildItemMasters],
  (purchaseOrder, itemMasters, childItemMasters) => {
    const lines = [];
    if(purchaseOrder && purchaseOrder.lines){
      purchaseOrder.lines.map(line => {
        const itemMaster = itemMasters.find(im => im.id === line.item_master_id);
        let line_type = '';
        let uom = 'GR';
        if(itemMaster){
          if(itemMaster.inventory_attributes && itemMaster.inventory_attributes.is_prepack && !itemMaster.item_master_parent_id){
            line_type = 'prepack';
          }else if(isBulkType(itemMaster.uom_type)){
            line_type = 'bulk';
          }else if (itemMaster.uom_type === 'discrete') {
            line_type = 'unit';
            uom = 'EA';
          }
        }
        const subitems = line.subitems.length ? line.subitems.map(subitem => {
          const subItemMaster = childItemMasters.length ?
            childItemMasters.find(im => im.id === subitem.item_master_id) : {};
          return {
            id: subitem.id,
            item_master_id: subitem.item_master_id,
            purchase_order_line_id: subitem.purchase_order_line_id,
            prepack_weight_id: subItemMaster.prepack_weight_id,
            qty: subitem.qty,
            unit_cost: parseFloat(subitem.unit_price).toFixed(2),
          };
        }) : [];
        lines.push({
          id: line.id,
          purchase_order_id: line.purchase_order_id,
          item_master_id: line.item_master_id,
          // container_type_id: line.container_type_id,
          // container_count: line.container_count,
          transfer_uom: line.uom_display  || uom,
          unit_price: parseFloat(line.unit_price).toFixed(2),
          line_item_price: parseFloat(line.line_item_price).toFixed(2),
          source_purchase_order_id: line.purchase_order_id,
          source_purchase_order_line_id: line.id,
          source_purchase_order_subitems: subitems,
          // subitems,
          ordered_qty: line.qty,
          storage_location_id: line.expected_storage_location_id,
          //qty:line.qty,
          qty: 0,
          ordered_uom: line.uom_display,
          name: itemMaster ? itemMaster.name : '',
          default_cost: itemMaster ? parseFloat(itemMaster.default_cost).toFixed(2) : '0.00',
          default_uom: itemMaster ? itemMaster.default_uom : 'GR',
          unit_weight: itemMaster ? itemMaster.unit_weight : '0.00',
          unit_weight_uom: itemMaster ? itemMaster.unit_weight_uom : 'GR',
          uom_type: itemMaster ? itemMaster.uom_type : '',
          line_type,
          uom
        });
      });
    }
    return lines;
  });

export const getPurchaseOrdersTotals = createSelector([getPurchaseOrder], purchaseOrder => {
  return {
    date_ordered: purchaseOrder.date_ordered,
    excise_tax_due: parseFloat(purchaseOrder.taxes),
    partner_discount:  parseFloat(purchaseOrder.discount_percent),
    transfer_fee: parseFloat(purchaseOrder.transfer_fee),
    destination_total:  parseFloat(purchaseOrder.order_total),
  };
});

export const getInventoryReceiptLines = createSelector([getPurchaseOrder, getInventoryReceipt, getItemMasters, getChildItemMasters],
  (purchaseOrderObject, inventoryReceiptObject, itemMasters, childItemMasters) => {

    const purchaseOrder = (Object.keys(purchaseOrderObject).length > 0) ? purchaseOrderObject : false;
    const inventoryReceipt = (Object.keys(inventoryReceiptObject).length > 0) ? inventoryReceiptObject : false;

    if(!purchaseOrder && !inventoryReceipt) return [];

    const linesSource = (purchaseOrder && !inventoryReceipt)
      ? Object.assign({}, purchaseOrder)
        : Object.assign({}, inventoryReceipt);

    if(linesSource.lines === undefined) return [];

    const getLineTypeAndUom = (line) => {

      const itemMaster = itemMasters.find( (itemMaster) => itemMaster.id === line.item_master_id);

      let line_type = 'bulk';
      let uom = 'EA';

      if(itemMaster){
        if(itemMaster.inventory_attributes && itemMaster.inventory_attributes.is_prepack && !itemMaster.item_master_parent_id){
          line_type = 'prepack';
          uom = 'GR';
        } else if(itemMaster.uom_type === 'weight'){
          line_type = 'bulk';
          uom = 'GR';
        }else if (itemMaster.uom_type === 'discrete') {
          line_type = 'unit';
          uom = 'EA';
        }
      }

      return {line_type: line_type, uom: uom, itemMaster};

    };

    const getSubItems = (line) => {

      if(line.subitems === undefined) return [];

      return line.subitems.length > 0
        ? line.subitems.map(subitem => {
          const subItemMaster = childItemMasters.length
            ? childItemMasters.find(im => im.id === subitem.item_master_id) : {};
          return {
            id: subitem.id,
            item_master_id: subitem.item_master_id,
            purchase_order_line_id: subitem.purchase_order_line_id,
            prepack_weight_id: subItemMaster.prepack_weight_id,
            qty: subitem.qty,
            unit_cost: parseFloat(subitem.unit_price).toFixed(2),
          };
        })
        : [];

    };

    return linesSource.lines.map( (line, index) => {

      const {line_type, uom, itemMaster} = getLineTypeAndUom(line);

      const subitems = getSubItems(line);

      return {
        id: line.id,
        purchase_order_id: inventoryReceipt ? line.source_id : line.purchase_order_id,
        item_master_id: line.item_master_id,
        // container_type_id: line.container_type_id,
        // container_count: line.container_count,
        transfer_uom: line.uom_display  || uom,
        po_unit_price: (purchaseOrder) ? parseFloat(purchaseOrder.lines[index].unit_price).toFixed(2) : 0,
        unit_price: (inventoryReceipt) ? parseFloat(line.unit_price).toFixed(2) : parseFloat(purchaseOrder.lines[index].unit_price).toFixed(2),
        po_line_item_price: (purchaseOrder) ? parseFloat(purchaseOrder.lines[index].line_item_price).toFixed(2) : (0).toFixed(2),
        line_item_price: parseFloat(line.line_item_price).toFixed(2),
        subitems: subitems,
        inventory: (inventoryReceipt) ? line.inventory : [],
        ordered_qty: (purchaseOrder) ? purchaseOrder.lines[index].qty : 0,
        qty: (inventoryReceipt) ? line.qty : 0,
        storage_location_id: (inventoryReceipt) ? line.storage_location_id : purchaseOrder.lines[index].expected_storage_location_id,
        ordered_uom: (purchaseOrder) ? purchaseOrder.lines[index].uom_display : line.uom_display,
        name: itemMaster ? itemMaster.name : '',
        default_cost: itemMaster ? parseFloat(itemMaster.default_cost).toFixed(2) : '0.00',
        default_uom: itemMaster ? itemMaster.default_uom : 'GR', // was 'GR'
        unit_weight: itemMaster ? itemMaster.unit_weight : '0.00',
        unit_weight_uom: itemMaster ? itemMaster.unit_weight_uom : uom, // was 'GR'
        uom_type: itemMaster ? itemMaster.uom_type : 'kablooey', // was empty
        line_type,
        uom
      };
    });

  });

export const getInventoryReceiptData = createSelector(
  [getPurchaseOrder, getInventoryReceipt],
  (purchaseOrderObject, inventoryReceiptObject) => {

    const purchaseOrder = (Object.keys(purchaseOrderObject).length > 0) ? purchaseOrderObject : false;
    const inventoryReceipt = (Object.keys(inventoryReceiptObject).length > 0) ? inventoryReceiptObject : false;

    if(!purchaseOrder && !inventoryReceipt) return {};

    return {
      date_received: (inventoryReceipt)
        ? moment(inventoryReceipt.dateReceived)
        : purchaseOrder
          ? purchaseOrder.estimated_date
            ? moment(purchaseOrder.estimated_date)
              : moment(new Date())
            : moment(new Date()),
      receipt_number: (inventoryReceipt) ? inventoryReceipt.receipt_number : null,
      source_type: (inventoryReceipt) ? inventoryReceipt.source_type : 'purchase_order',
      receipt_type: (inventoryReceipt) ? inventoryReceipt.source_type : 'purchase_order',
      source_id: (inventoryReceipt) ? inventoryReceipt.source_id : purchaseOrder.id,
      status: (inventoryReceipt) ? inventoryReceipt.status : 'open',
      received_by_user: inventoryReceipt ? inventoryReceipt.received_by_user : 1,
      estimated_departure_time: inventoryReceipt ? inventoryReceipt.estimated_departure_time : null,
      estimated_arrival_time: inventoryReceipt ? inventoryReceipt.estimated_arrival_time : purchaseOrder.time_expected,
      driver_type: inventoryReceipt ? inventoryReceipt.driver_type : '',
      driver_id: inventoryReceipt ? inventoryReceipt.driver_id : null,
      vehicle_id: inventoryReceipt ? inventoryReceipt.vehicle_id : null
    };

  });
