import qs from 'querystring';
import { getData, getUnpaginatedData } from '../apiActions';
import * as dataNames from '../../constants/dataNames';
import { getIntegrationState } from '../../selectors/integration/integrationSelectors';
import { unsetData } from '../dataActions';

/**
 * Returns list of appropriate modalities
 * @param strain_id
 * @param params
 * @returns {function(*): *}
 */
export const fetchModalitiesByStrainId = (strain_id, params = {}) => (dispatch) => {
  return dispatch(
    getData(`/api/cupos/modalities?strain_id=${strain_id}&${qs.stringify(params)}`, dataNames.cupo_numbers)
  );
};

export const fetchCupos = () => (dispatch, getState) => {
  const { isColombia } = getIntegrationState(getState());

  if (isColombia) {
    return dispatch(getUnpaginatedData(`/api/cupos`, dataNames.cupo_numbers));
  }

  return null;
};

/**
 * Clear from Redux state cupo numbers
 * @returns {function(*): *}
 */
export const clearCupoNumbers = () => (dispatch) => {
  return dispatch(unsetData(dataNames.cupo_numbers));
};
