import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {arrayPush, arrayRemoveAll, change} from 'redux-form';
import {bindActionCreators} from 'redux';
import {goBack, push} from 'react-router-redux';
import get from 'lodash.get';
import * as dataNames from '../../../../constants/dataNames';
import * as itemNames from '../../../../constants/itemNames';
import * as mappingSelectors from '../../../../selectors/PlatformSupplyChainMappingSelectors';
import {getPartnerFacilities} from '../../../../selectors/partnersSelectors';
import {addMessage, stopSpinner} from '../../../../actions/systemActions';
import {
  getItem,
  getUnpaginatedData,
  getData,
  getSearchData,
  postData,
  postItem,
  putItem,
  getDataByPost
} from '../../../../actions/apiActions';
import {unsetData} from '../../../../actions/dataActions';
import {setItem, unsetItem} from '../../../../actions/itemActions';
import FormWrapper from '../../../common/form/FormWrapper';
import PlatformSupplyChainMappingForm from './PlatformSupplyChainMappingForm'; //eslint-disable-line
import {getMetrcFilteredCategories, getSubCategoryOptions} from '../../../../selectors/categorySelectors';
import {getIntegrationState} from '../../../../selectors/integration/integrationSelectors';
import {onSubmit, onIncomingTransferChange, onUnreceivedPurchaseOrderChange, onAutoGeneratePurchaseOrderChange} from '../SupplyChainMappingHelpers';
import {isActiveFacilityLab, isLeafPaConfigPackClosedLoopFacility} from '../../../../selectors/facilitiesSelectors';
import {isFeatureEnabled} from '../../../../selectors/featureToggles';
import {getOrganizationPrepackWeights} from '../../../../selectors/prepackWeightSelectors';

export class PlatformSupplyChainMappingPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.title = `supplyChainMapping.title.${props.params.mode}`;
  }

  componentDidMount() {
    // Redirect back to supply-chain menu if mapping is not required on current facility
    this.props.actions.getItem('/api/supply/settings', itemNames.supplySettings, null, null, (data) => {
      const setting = data.supply_medicated_receipt_requires_mapping;
      if (!setting || !setting.value) {
        this.props.actions.push('/supply-chain');
      }
    });

    this.props.actions.getUnpaginatedData('/api/categories', dataNames.categories);
    this.props.actions.getUnpaginatedData('/api/partner_facilities', dataNames.partnerFacilities, {failed:'partnerFacilities.get.failed'});
    this.props.actions.getUnpaginatedData('/api/subcategories', dataNames.subcategories);
    this.props.actions.getUnpaginatedData('/api/transfers/incoming', dataNames.platformIncomingTransfers, {failed: 'supplyChainMapping.integratedTransfers.get.failed'});
    this.props.actions.getUnpaginatedData('/api/partners', dataNames.partners, undefined, {purchase_from: 1, exclude_internal_duplicates: 1});
    this.props.actions.getUnpaginatedData('/api/prepack_weights', dataNames.prepackWeights);
  }

  componentWillUnmount() {
    this.props.actions.unsetItem(dataNames.platformIncomingTransfers);
    this.props.actions.unsetItem(dataNames.platformPurchaseOrders);
    this.props.actions.unsetData(dataNames.itemMasters);
    this.props.actions.unsetData(dataNames.prepackWeights);
    this.props.actions.unsetItem(itemNames.platformIncomingTransferDetails);
    this.props.actions.unsetItem(itemNames.platformPurchaseOrderDetails);
  }

  render() {
    const {
      purchaseOrderInfo,
      incomingTransferOptions,
      purchaseOrderOptions,
      incomingTransferProductsOptions,
      selectedIncomingTransfer,
      platformIncomingTransferDetails,
      selectedPurchaseOrder,
      formErrors,
      canGenerateAutomaticPO,
      compatibleItemMasters,
      itemMasterMappings,
      formValues,
      partnerFacilities,
      isActiveFacilityLab
    } = this.props;

    return (
      <FormWrapper title={this.title} goBack={this.props.actions.goBack}>
        <PlatformSupplyChainMappingForm
          initialValues={{auto_generate_po : false}}
          purchaseOrderInfo={purchaseOrderInfo}
          onSubmit={(formValues) => {onSubmit(formValues, {...this.props});}}
          incomingTransferOptions={incomingTransferOptions}
          purchaseOrderOptions={purchaseOrderOptions}
          onUnreceivedPurchaseOrderChange={(newValue, callback) => {onUnreceivedPurchaseOrderChange(newValue,{...this.props}, callback);}}
          onAutoGeneratePurchaseOrderChange={() => {onAutoGeneratePurchaseOrderChange(platformIncomingTransferDetails, partnerFacilities, isActiveFacilityLab, {...this.props});}}
          onIncomingTransferChange={(newValue) => {
            // When a transfer is selected, the potential matching item masters are being loaded.
            // If a Purchase Order is selected from the drop down before this is completed, it will error out.
            // Lock the Purchase Order drop down while the transfer is being loaded and open it up again, after it has been completely loaded.
            this.setState({transferIsBeingLoaded: true});
            onIncomingTransferChange(newValue, {...this.props}, () => {
              this.setState({transferIsBeingLoaded: false});
              this.props.actions.stopSpinner();
            });}
          }
          incomingTransferProductsOptions={Array.isArray(incomingTransferProductsOptions) ? incomingTransferProductsOptions : []}
          selectedIncomingTransfer={selectedIncomingTransfer}
          selectedPurchaseOrder={selectedPurchaseOrder}
          formErrors={formErrors}
          canGenerateAutomaticPO={canGenerateAutomaticPO}
          formValues={formValues}
          compatibleItemMasters={compatibleItemMasters}
          itemMasterMappings={itemMasterMappings}
          isActiveFacilityLab={isActiveFacilityLab}
          transferIsBeingLoaded={get(this.state, 'transferIsBeingLoaded', false)}
        />
      </FormWrapper>
    );
  }
}

PlatformSupplyChainMappingPage.propTypes = {
  actions: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    getData: PropTypes.func.isRequired,
    getDataByPost: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired,
    setItem: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    arrayRemoveAll: PropTypes.func.isRequired,
    addMessage: PropTypes.func.isRequired
  }).isRequired,
  incomingTransferOptions: PropTypes.array.isRequired,
  purchaseOrderOptions: PropTypes.array.isRequired,
  selectedIncomingTransfer: PropTypes.number,
  selectedPurchaseOrder: PropTypes.number,
  incomingTransferProducts: PropTypes.object,
  purchaseOrderInfo: PropTypes.object,
  syncErrors: PropTypes.object,
  canGenerateAutomaticPO: PropTypes.bool,
  compatibleItemMasters: PropTypes.object.isRequired,
  itemMasterMappings: PropTypes.array.isRequired,
  formValues: PropTypes.object,
  categories: PropTypes.array.isRequired,
  subcategories: PropTypes.array,
  partners: PropTypes.array.isRequired,
  integrationState: PropTypes.object,
  partnerFacilities: PropTypes.array.isRequired,
  prepackWeights: PropTypes.array.isRequired
};

function mapStateToProps(state) {

  const formValues = mappingSelectors.formValuesSelector(state) || {};
  const formErrors = mappingSelectors.errorSelector(state);
  const subcategories = getSubCategoryOptions(state);
  const categories = getMetrcFilteredCategories(state);
  const integrationState = getIntegrationState(state);
  const {partners} = state;

  return {
    timezone: mappingSelectors.getTimeZone(state),
    purchaseOrderInfo: mappingSelectors.purchaseOrderInfo(state),
    incomingTransferOptions: mappingSelectors.getPlatformIncomingTransfersOptions(state),
    selectedIncomingTransfer: mappingSelectors.formSelector (state, 'incoming_transfer_id'),
    purchaseOrderOptions: mappingSelectors.getPlatformPurchaseOrdersOptions(state),
    selectedPurchaseOrder: mappingSelectors.formSelector (state, 'purchase_order_id'),
    incomingTransferProductsOptions: mappingSelectors.getPlatformIncomingTransfersProductsOptions(state),
    platformIncomingTransferDetails: mappingSelectors.getPlatformIncomingTransferDetails(state),
    canGenerateAutomaticPO: !!mappingSelectors.canGenerateAutomaticPO(state),
    compatibleItemMasters: mappingSelectors.getCompatibleItemMasters(state) || {},
    itemMasterMappings: mappingSelectors.getItemMasterMappings(state) || {},
    partnerFacilities: getPartnerFacilities(state),
    isActiveFacilityLab: isActiveFacilityLab(state),
    featureLeafPaSupplyChainAutoMap: isFeatureEnabled(state)('feature_leaf_pa_supply_chain_auto_map'),
    isLeafPaConfigPackClosedLoopFacility: isLeafPaConfigPackClosedLoopFacility(state),
    isAutoPoGenerationForFacilitiesFeatureEnabled: isFeatureEnabled(state)('feature_auto_po_generation_for_facilities'),
    prepackWeights: getOrganizationPrepackWeights(state),
    categories,
    subcategories,
    partners,
    formErrors,
    formValues,
    integrationState
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    arrayPush,
    getUnpaginatedData,
    change,
    getData,
    getDataByPost,
    getSearchData,
    postItem,
    postData,
    putItem,
    goBack,
    push,
    setItem,
    getItem,
    unsetData,
    unsetItem,
    addMessage,
    arrayRemoveAll,
    stopSpinner
  };

  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PlatformSupplyChainMappingPage);
