import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Row, Col, Button} from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';

import TextInput from '../../common/form/TextInput';

const ReleaseQuarantinePackagesFieldArray = ({ fields, onRemovePackage }) => {
  const removePackage = (e, index) => {
    e.preventDefault();
    onRemovePackage('remove', [fields.get(index)]);
    fields.remove(index);
  };

  return (
    <div>
      {
        fields.map((name, index) => (
          <Row key={index}>
            <Col sm={3}>
              <Field
                name={`${name}.name`}
                component={TextInput}
                props={{
                  disabled: true,
                  label: I18n.t('closedLoop.admin.product')
                }}
              />
            </Col>
            <Col sm={3}>
              <Field
                name={`${name}.package_code`}
                component={TextInput}
                props={{
                  disabled: true,
                  label: I18n.t('finishedProduct.form.packageId')
                }}
              />
            </Col>
            <Col sm={3}>
              <Field
                name={`${name}.state_integration_tracking_id`}
                component={TextInput}
                props={{
                  disabled: true,
                  label: I18n.t('common.form.trackingId')
                }}
              />
            </Col>
            <Col sm={1}>
              <Button
                key={index}
                type='danger'
                style={{marginTop: '30px'}}
                onClick={(event) => removePackage(event, index)}>
                <FaTrash />
              </Button>
            </Col>
          </Row>
        ))
      }
    </div>
  );
};

ReleaseQuarantinePackagesFieldArray.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    length: PropTypes.number.isRequired
  }).isRequired,
  onRemovePackage: PropTypes.func.isRequired
};

export default ReleaseQuarantinePackagesFieldArray;