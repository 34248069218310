import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {formValueSelector, reset} from 'redux-form';
import {postItem} from '../../../../actions/apiActions';
import {getOrder} from '../../../../selectors/ordersSelectors';
import {getOrderManualDiscounts, getOrderProductOptions} from '../../../../selectors/orders/discountsSelectors';
import ManualDiscountComponent from  './ManualDiscountComponent';


const form = 'manualDiscount';
const selector = formValueSelector(form);

function mapStateToProps(state) {
  return {
    form,
    order: getOrder(state),
    orderProductOptions: getOrderProductOptions(state),
    discounts: getOrderManualDiscounts(state),
    appliesTo: selector(state, 'coupon_applies_to'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({reset, postItem}, dispatch),
  };
}

const ManualDiscount = connect(mapStateToProps, mapDispatchToProps)(ManualDiscountComponent);

ManualDiscount.propTypes = {
  showLoader: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired,
};

export default ManualDiscount;
