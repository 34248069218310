import {
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  CLEAR_LOGIN_MESSAGE,
  USER_VALIDATE_SUCCESS,
  USER_VALIDATE_IN_PROGRESS,
  UPDATE_USER,
  USER_VALIDATE_FAILED,
  SET_RETAIL_LOCATION,
  SET_RETAIL_REGISTER,
  UNSET_USER,
  LOGOUT_SUCCESS,
  SET_USER_PERMISSIONS,
  SEARCH_USER_ROLES
} from '../constants/actionTypes';

import initialState from '../constants/initialState';

export default function userReducer(state = initialState.user, action) {
  const { errors, user, payload, organizations } = action;
  switch (action.type) {
  case USER_VALIDATE_SUCCESS:
    return Object.assign({}, state, { authenticated: true, authenticating: false, errors });
  case LOGIN_SUCCESS:
    return Object.assign({}, user, { authenticated: true, authenticating: false, errors, userOrganizations: organizations });
  case USER_VALIDATE_FAILED:
  case LOGOUT_SUCCESS:
    return Object.assign({}, state, { loggingOut: true, authenticated: false, authenticating: false });
  case LOGIN_FAILED:
    return Object.assign({}, state, { authenticated: false, authenticating: false, errors });
  case CLEAR_LOGIN_MESSAGE:
    return Object.assign({}, state, { errors: undefined });
  case USER_VALIDATE_IN_PROGRESS:
    return Object.assign({}, state, { authenticating: true, errors });
  case SET_RETAIL_LOCATION:
    return Object.assign({}, state, { currentLocation: action.location });
  case SET_RETAIL_REGISTER:
    return Object.assign({}, state, { currentRegister: action.register });
  case UNSET_USER:
    return Object.assign({}, initialState.user);
  case SET_USER_PERMISSIONS: {
    return Object.assign({}, state, { permissions: payload });
  }
  case UPDATE_USER:
      //now update only first name and lst name
    return Object.assign({}, state, { lastName: payload.last_name, firstName: payload.first_name });
  case SEARCH_USER_ROLES:
    return Object.assign({}, state, { userRolesSearchStr: action.searchStr });
  default:
    return state;
  }
}
