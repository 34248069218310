import React from 'react';
import PropTypes from 'prop-types';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {FaExclamationTriangle, FaInfoCircle} from 'react-icons/fa';

const HoverTooltip = (props) => {
  const {id, text, position, type} = props;

  return (
    <span className={type === 'danger' ? 'text-danger' : type === 'info' ? 'info' : ''}>
      <OverlayTrigger delay={100} placement={position} overlay={<Tooltip id={id}>{text}</Tooltip>}>
        {type === 'info' ? <FaInfoCircle/> : <FaExclamationTriangle/>}
      </OverlayTrigger>
    </span>);
};

HoverTooltip.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  text: PropTypes.string.isRequired,
  position: PropTypes.string,
  type: PropTypes.string
};

HoverTooltip.defaultProps = {
  position: 'top',
  type: 'danger'
};

export default HoverTooltip;
