import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {goBack} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import {change} from 'redux-form';
import get from 'lodash.get';
import FormWrapper from '../../common/form/FormWrapper';
import {getItem, getUnpaginatedData, postItem, putItem, postData, putData} from '../../../actions/apiActions';
import {unsetItem} from '../../../actions/itemActions';
import {setData} from '../../../actions/dataActions';
import {getImageUrl} from '../../../util/images';
import {LEAF} from '../../../constants/imageUrls';
import * as itemNames from '../../../constants/itemNames';
import * as dataNames from '../../../constants/dataNames';
import {addItem as addItemAction} from '../../../actions/cartActions';
import {getGroupPricingWeights} from '../../../selectors/cartSelectors';
import {categoryById} from '../../../selectors/productsSelectors';
import {getItemMasterType, getCatalogItemMasterWithPriceSelector} from '../../../selectors/itemMastersSelectors';
import {getStrainWithProductDominance} from '../../../selectors/strainsSelectors';
import ProductDetail from './ProductDetail'; // eslint-disable-line import/no-named-as-default
import ProductsHeader from '../../header/ProductsHeader';
import {isCureIntegrator, getFormOptionsBySubcategory} from '../../../selectors/integration/cureApiSelectors';
import {getOrderWithProductMappings} from '../../../selectors/ordersSelectors';
import { getSalesLimitError } from '../../../selectors/salesSettingsSelectors';
import ModalWrapper from '../../common/ModalWrapper';
import {isAllowNegativeInventory} from '../../../selectors/complianceSettingsSelectors';
import showNegativeAlert from '../../common/negative-inventory/showNegativeAlert';
import { getIntegrationState } from '../../../selectors/integration/integrationSelectors';
import { addOrUpdateSingleProductInCart} from '../../../actions/forms/pointOfSaleActions';

export class ProductDetailPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.redirect = this.redirect.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleError = this.handleError.bind(this);
    this.setNegativeConfirmationState = this.setNegativeConfirmationState.bind(this);
    this.showConfirmationPopup = this.showConfirmationPopup.bind(this);
    this.renderConfirmModal = this.renderConfirmModal.bind(this);
    this.state = {
      ready: false,
      showNegativeInvConfirmation: {},
      forceUpdate: false
    };
  }

  componentWillMount(){
    const id = this.props.params.id;
    this.props.actions.unsetItem(itemNames.itemMaster);
    this.props.actions.getUnpaginatedData('/api/categories', dataNames.categories, {failed: I18n.t('categories.get.failed')});
    Promise.all([
      this.props.actions.getItem(`/api/item_masters/${id}`,
        itemNames.itemMaster,
        {failed: I18n.t('products.get.failed')},
        {detailed: 1},
        data => {
          if (data.strain_id) {
            this.props.actions.getItem(`/api/strains/${data.strain_id}`, itemNames.strain, {failed: I18n.t('strains.getStrain.failed')});
          }
        }
      ),
    ])
      .then(() => this.setState({ready: true}))
      .catch(() => this.setState({ready: true}));
    this.props.actions.getUnpaginatedData('/api/pricing_weights', dataNames.pricingWeights);
  }

  handleError(errors) {
    if (!errors.response) {
      return {message: I18n.t('productMenu.networkError')};
    }
    const response = errors.response.data;
    if (response.errors && response.errors.item_master_id) {
      return {message: response.errors.item_master_id.pop()};
    }
    return getSalesLimitError(errors);
  }

  /**
   * Submit a single update for the order.
   * @param formValues
   */
  onSubmit(formValues) {
    this.props.actions.addOrUpdateSingleProductInCart(formValues)
      .then(() => {
        if(formValues.fastTrack){
          this.props.actions.push(`/cart`);
        }
      })
      .catch((availabilityResults) => {
        const itemMasterId = get(availabilityResults, '0.item_master_id', 0);
        showNegativeAlert(true, this.setNegativeConfirmationState)
          .then(() => {
            // Keep track of those approved so we don't nag the user.  Persist only for page for the moment.
            const itemMasterIds = this.props.itemMasterIds.concat([itemMasterId]);
            this.props.actions.setData(itemMasterIds, dataNames.itemMasterIds);
            setTimeout(() => {
              this.onSubmit(formValues);
            }, 100);
          })
          .catch(() => {}); // do nothing
      });
  }

  setNegativeConfirmationState(state) {
    this.setState({
      showNegativeInvConfirmation: state
    });
  }

  redirect () {
    this.props.actions.goBack();
  }

  showConfirmationPopup(formValues) {
    const hidePopup = (cb) => () => {
      this.setState({
        showNegativeInvConfirmation: { show: false },
      });
      cb(formValues);
    };
    this.setState({
      showNegativeInvConfirmation: {
        show: true,
        onConfirm: hidePopup(this.onSubmit),
        onHide: hidePopup(() => {this.setState({forceUpdate: false});}),
      },
      forceUpdate: true
    });
  }

  renderConfirmModal() {
    const {showNegativeInvConfirmation} = this.state;

    const okayButtonProps = {
      show: true,
      onClick: showNegativeInvConfirmation.onConfirm,
      text: I18n.t('general.yes')
    };

    const cancelButtonProps = {
      show: true,
      onClick: showNegativeInvConfirmation.onHide,
      text: I18n.t('general.no')
    };

    return (
      <ModalWrapper
        Component={false}
        title={I18n.t('inventory.negativeInventoryAlert')}
        headerClass='bg-info-dark'
        onHide={showNegativeInvConfirmation.onHide}
        showModal={showNegativeInvConfirmation.show}
        okayButton={okayButtonProps}
        cancelButton={cancelButtonProps}
        dialogClassName='modal-sm'
        version={2}
      >
        <p>{I18n.t('inventory.confirmToGoNegative')}</p>
      </ModalWrapper>
    );
  }

  render () {
    const {itemMaster, itemMasterType, category, strain, initialValues, isCure, formOptions} = this.props;
    const categoryName = get(category, 'name', ' ');
    const {ready} = this.state;
    return (<div className='product-details'>
      <ProductsHeader
        props={this.props}
        context={this.context}
      />
      {
        ready &&
        <FormWrapper title={categoryName} goBack={this.redirect}>
          <ProductDetail
            strain={strain}
            itemMaster={itemMaster}
            itemMasterType={itemMasterType}
            onSubmit={this.onSubmit}
            initialValues={initialValues}
            enableReinitialize={true}
            isCure={isCure}
            formOptions={formOptions}
          />
        </FormWrapper>
      }
      {this.renderConfirmModal()}
    </div>);
  }
}

ProductDetailPage.propTypes = {
  actions: PropTypes.object.isRequired,
  itemMaster: PropTypes.object.isRequired,
  itemMasterType: PropTypes.string.isRequired,
  category: PropTypes.object.isRequired,
  strain: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  currentOrder: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  isCure: PropTypes.bool,
  formOptions: PropTypes.array.isRequired,
  inventoryItems: PropTypes.array.isRequired,
  isAllowNegativeInventory: PropTypes.bool.isRequired
};

function mapStateToProps(state){
  const {itemMaster, customer, inventoryItems} = state;
  const category = itemMaster.category_id ? categoryById(state, itemMaster) : {};
  itemMaster.src = getImageUrl(itemMaster && itemMaster.primary_image_file, '400x400', LEAF);
  const itemMasterType = getItemMasterType(state) || '';
  const subcategoryFormOptions = getFormOptionsBySubcategory(state, {customer, mode: 'customer'});
  const formOptions = subcategoryFormOptions[itemMaster.subcategory_id] || [];
  const isCure = isCureIntegrator(state);
  const initialValues = {
    item_master_id: itemMaster.id,
    quantity: '',
    uom: '',
    item_master_uom: itemMaster.default_uom,
    default_uom: itemMaster.default_uom,
    itemMasterType,
    isCure,
    form_id: formOptions.length === 1 && formOptions[0].value || null,
  };

  return {
    itemMaster: getCatalogItemMasterWithPriceSelector(state, {customer}),
    itemMasterType,
    strain: getStrainWithProductDominance(state),
    category,
    initialValues,
    currentOrder: getOrderWithProductMappings(state),
    getProductPricingWeights: getGroupPricingWeights.bind(null, state.cart),
    isCure,
    formOptions,
    inventoryItems,
    isAllowNegativeInventory: isAllowNegativeInventory(state),
    itemMasterIds: state[dataNames.itemMasterIds],
    integrationState: getIntegrationState(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    addItem: addItemAction,
    goBack,
    getItem,
    getUnpaginatedData,
    postItem,
    putItem,
    unsetItem,
    postData,
    putData,
    change,
    setData,
    addOrUpdateSingleProductInCart,
  };
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (ProductDetailPage);
