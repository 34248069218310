import {change} from 'redux-form';
import {START_INFUSION_FORM} from '../../constants/forms';
import {isArrayPush, isArrayRemove} from './utils';
import {getIntegrationState} from '../../selectors/integration/integrationSelectors';


const startInfusionForm = store => next => action => {
  const result = next(action);
  const {meta} = action;
  if (isArrayPush(action, [START_INFUSION_FORM], 'inputs') || isArrayRemove(action, [START_INFUSION_FORM], 'inputs')) {
    const state = store.getState();
    const {isBiotrack} = getIntegrationState(state);
    if (isBiotrack) {
      store.dispatch(change(meta.form, 'assembly_id', null));
    }
  }

  return result;
};

export default startInfusionForm;
