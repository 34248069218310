/* eslint-disable import/prefer-default-export */
import get from 'lodash.get';
import {getItem, getSearchData, getUnpaginatedData, postData, getDataByPost} from './apiActions';
import {setData} from './dataActions';
import {addMessage} from './systemActions';
import * as itemNames from '../constants/itemNames';
import * as dataNames from '../constants/dataNames';
import * as messageTypes from '../constants/messageTypes';
import { convertFromBase } from '../util/uomHelpers';

export function getItemMasterPackageId(itemMasterId, packageIndex) {
  const path = `/api/item_masters/${itemMasterId}/generate_id`;
  return getItem(path, itemNames.itemMasterPackageId, null, null, () => {}, item => ({
    packageId: item,
    packageIndex
  }));
}

/**
 * Load from server item masters
 */
export const fetchItemMasters = (ids, params = {}) => (dispatch) => {
  return dispatch(postData('/api/item_masters/multiple', { ids }, dataNames.itemMasters, null, params));
};

export const fetchChildrenItemMasters = (payload) => (dispatch) => (
  dispatch(getUnpaginatedData('/api/item_masters/children/items', dataNames.childItemMasters, null, payload))
);

export const fetchItemMastersFromSolr = (params = {}) => (dispatch) => (
  dispatch(getSearchData('/api/search/item_masters', dataNames.products, null, params))
);

/***
 * Gets item masters by id and then puts it into redux in the specified store.  Prevents duplicate calls.
 * @param id
 * @param dataName
 * @returns {Function}
 */
export const fetchItemMasterAndAddToRedux = (id, dataName) => (dispatch, getState) => {
  const itemMasters = getState()[dataName];
  const itemMaster = itemMasters.find((itemMaster) => itemMaster.id === id);
  if(itemMaster){
    return true;
  }
  return dispatch(getItem(`/api/item_masters/${id}`)).then((itemMaster) => {
    itemMasters.push(itemMaster);
    dispatch(setData(itemMasters, dataName));
    return true;
  });
};


export const getItemMasterChildren = (itemMasterIds) => (dispatch, getState) => {

  const itemMasters = getState()[dataNames.itemMasters].filter(() => true);

  const getChildren = (itemMasterIds) => {
    if(!Array.isArray(itemMasterIds)){
      itemMasterIds = [itemMasterIds];
    }
    const filter = itemMasterIds.reduce((acc, itemMasterId) => {
      acc.push(`item_master_parent_id:${itemMasterId}`);
      return acc;
    }, []).join(' OR ');
    const params = {
      sort: 'name asc, display_name asc',
      query: 'matchall',
      size: '100000',
      start: '0',
      filter: `(${filter})`,
      fields: [
        'id',
        'name',
        'items',
        'display_name',
        'is_medicated',
        'category_id',
        'lot_tracked',
        'is_prepack',
        'item_master_parent_id',
        'prepack_weight_id',
        'is_sales_item',
        'subcategory_code',
        'uom_type',
        'active',
      ],
    };
    return dispatch(getSearchData('/api/search/item_masters', null , null, params,
        (data) => {
          if(data.length === 0) return dispatch(addMessage(messageTypes.error, 'products.get.failed'));
          const newItemMasterChildren = data.map((master) => {
            master.inventory_attributes = {
              lot_tracked: master.lot_tracked,
              is_prepack: master.is_prepack,
              item_master_parent_id: master.item_master_parent_id,
              prepack_weight_id: master.prepack_weight_id
            };
            return master;
          }).filter((child) => child.active);
          dispatch(setData(getState()[dataNames.itemMasters].filter(() => true).concat(newItemMasterChildren), dataNames.itemMasters));
          return newItemMasterChildren;
        }));
  };

  if(itemMasterIds.length === 1) {
    const children = itemMasters.filter((child) => child.item_master_parent_id === itemMasterIds[0]);
    if (children.length) {
      return children;
    }
  }

  return getChildren(itemMasterIds);
};

/**
 * Call server for availability.
 * @TODO: Handle multiples so it can be used for the cart drawer and extract to an action when you use it there.
 * @param itemMasterIds
 * @param requestedQuantities
 * @returns {Promise}
 */

export const getItemMastersAvailability = (itemMasterIds, requestedQuantities = []) => (dispatch) => {
  return new Promise((resolve, reject) => {
    // Can get item masters from state, just a note.
    dispatch(getDataByPost('/api/item_master_availability/multiple', {ids: itemMasterIds, include_item_reservations: true}, 'noOp'))
      .then((results) => {
        const normalizedResults = results.map((result) => {
          const uom = get(result, 'uom_display', 'GR');
          const itemMasterId = get(result, 'item_master_id', 0);
          const requestedQuantity = requestedQuantities.find((rq) => {
            return rq.item_master_id === itemMasterId;
          });
          const fromBaseOnHand = convertFromBase(get(result, 'qty_onhand_base', 0), uom);
          const fromBaseReserved = convertFromBase(get(result, 'qty_reserved_base', 0), uom);
          const currentRequestAmount = parseFloat(get(requestedQuantity, 'quantity', 0));
          const quantityRemaining = (fromBaseOnHand - (fromBaseReserved + currentRequestAmount));
          const isAvailable = quantityRemaining >= 0;
          return Object.assign(result, {
            fromBaseOnHand,
            fromBaseReserved,
            currentRequestAmount,
            quantityRemaining,
            isAvailable,
          });
        });
        resolve(normalizedResults);
      });
  });
};
