/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {push, go} from 'react-router-redux';
import {submit} from 'redux-form';
import {Tab, Tabs} from 'react-bootstrap';
import get from 'lodash.get';

import * as dataNames from '../../constants/dataNames';
import * as apiActions from '../../actions/apiActions';
import {getIntegrationCategoriesData} from '../../actions/integrationActions';
import {unsetData} from '../../actions/dataActions';
import {getIntegrationState} from '../../selectors/integration/integrationSelectors';
import {getBiotrackCategories} from '../../selectors/integration/biotrackCategoriesSelectors';
import {getApisicpaCategories} from '../../selectors/integration/apisicpaCategoriesSelectors';
import {
  getCategoryManagementCategories,
  getLeaflySubCategories,
  getLeafSubCategories,
  getWeedmapsCategories,
  getIsolocitySubCategories,
  getCCACategories,
} from '../../selectors/categorySelectors';
import getSalesSettings from '../../util/salesSettings';
import getRulesCategories from '../../selectors/compliance/sales/getRulesCategories';
import isRetailFacility from '../../selectors/facility/isRetailFacility';
import InProgressOverlay from '../common/InProgressOverlay';
import CategoryManagementForm from './CategoryManagementForm';
import ModalWrapper from '../common/ModalWrapper';
import ProductsListing from './ProductsListing';
import {getCureFormOptions, getCureOrderTypeOptions} from '../../selectors/integration/cureApiSelectors';
import {getGroupedMetrcCategories, isOARRSReportingEnabled} from '../../selectors/integration/metrcSelectors';
import {isCanadaFacility} from '../../selectors/facility/getFacilityCountry';
import {hasSalesRulesEnabled, salesRulesCategoriesArePreset} from '../../selectors/salesSettingsSelectors';
import { getCategorySettings, getSubCategorySettings } from '../../selectors/settingsSelectors';
import {getUrlParam} from '../../util/routeHelper';
import {CATEGORY_MANAGEMENT_FORM} from '../../constants/forms';
import {isFacilityGroupMaster} from '../../selectors/facilityGroupsSharingSelectors';
import * as messageTypes from '../../constants/messageTypes';
import {addMessage} from '../../actions/systemActions';

export class CategoryManagementPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showModal: false,
      showSharedModal: false,
      subCategory: {},
      loading: true,
      loadingProducts: false,
      progressMessage: I18n.t('inProgressOverlay.message'),
      sharedList: {
        toInactivate: [],
        toActivate: []
      },
      goBackAfterSubmit: props.location && props.location.state ? props.location.state.goBackAfterSubmit : false,
      goBackDepth: 1,
      redirectionStarted: false
    };

    this.salesSettings = getSalesSettings();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.lengthBan = this.lengthBan.bind(this);
    this.renderTab = this.renderTab.bind(this);
    this.renderTabs = this.renderTabs.bind(this);
    this.setCategory = this.setCategory.bind(this);
    this.onConfirmShared = this.onConfirmShared.bind(this);
    this.getActiveIntegrators = this.getActiveIntegrators.bind(this);
    this.onSaveCompleted = this.onSaveCompleted.bind(this);
    this.onShowConfirmSharedModal = this.onShowConfirmSharedModal.bind(this);
    this.changeSharedList = this.changeSharedList.bind(this);
    this.showLoadingOverlay = this.showLoadingOverlay.bind(this);
    this.hideLoadingOverlay = this.hideLoadingOverlay.bind(this);
  }

  componentWillMount() {
    const { actions, params: {integration} } = this.props;

    this.salesSettings.getSettings(actions.getItem, true);

    this.showLoadingOverlay();

    Promise
      .all(
      [
        this.loadCategories(integration, false),
        actions.getUnpaginatedData('/api/facility_groups_sharings', dataNames.sharedFacilityGroups, {failed: 'common.failed'}, {only_master: 1})
      ]
      ).finally(this.hideLoadingOverlay);
  }

  componentWillReceiveProps(newProps) {
    const { params } = this.props;
    const newIntegration = newProps.params.integration;
    const currentIntegration = params.integration;

    if (newIntegration && newIntegration !== currentIntegration && !newProps.isCanadaFacility) {
      this.setState({goBackDepth: this.state.goBackDepth + 1});
      this.loadCategories(newIntegration, true);
    }
  }

  showLoadingOverlay(message) {
    this.setState({loading: true, progressMessage: typeof message !== 'string' ? I18n.t('inProgressOverlay.message') : message});
  }

  hideLoadingOverlay() {
    this.setState({loading: false});
  }

  loadCategories(integration, showLoadingOverlay) {
    const { actions } = this.props;

    if (showLoadingOverlay === true) this.showLoadingOverlay();

    return actions.getUnpaginatedData(
      '/api/categories',
      dataNames.categoriesData,
      {
        failed: 'categories.get.failed'
      },
      {
        include_product_counts: 1,
        integration: integration ? integration : 0,
        is_only_active: 1,
      }
    )
      .then(actions.getIntegrationCategoriesData)
      .finally(
        showLoadingOverlay ? this.hideLoadingOverlay : () => {}
      );
  }

  componentWillUnmount() {
    this.props.actions.unsetData(dataNames.categoriesData);
    this.props.actions.unsetData(dataNames.products);
  }

  onConfirmShared() {
    this.setState({showSharedModal: false});
    this.props.actions.submit();
  }

  onShowConfirmSharedModal() {
    const {sharedList: {toInactivate}} = this.state;
    if(toInactivate.length){
      this.setState({showSharedModal: true});
    } else {
      this.props.actions.submit();
    }
  }

  changeSharedList(subCatId, checked) {
    const {sharedList} = this.state;
    if(checked && sharedList.toActivate.indexOf(subCatId) === -1){
      if(sharedList.toInactivate.indexOf(subCatId) === -1){
        sharedList.toInactivate = sharedList.toInactivate.filter(el => el !== subCatId);
      }
      sharedList.toActivate.push(subCatId);
    }
    if(!checked && sharedList.toInactivate.indexOf(subCatId) === -1) {
      if(sharedList.toActivate.indexOf(subCatId) === -1){
        sharedList.toActivate = sharedList.toActivate.filter(el => el !== subCatId);
      }
      sharedList.toInactivate.push(subCatId);
    }
    this.setState({sharedList});
  }

  processFormValuesCategories(formValues){
    const mappedCategories = [];
    const integration = this.props.params.integration;
    const leaflyMappedCategories = [];
    const ohmetrcMappedCategories = [];
    const weedmapsMappedCategories = [];
    const isolocityMappedCategories = [];
    const ccaMappedCategories = [];

    const {isWeedmaps, isLeafly, isOhMetrc, isIsolocity, isCca} = this.props.integrationState;

    const currentIntegrationTab = integration && `is${integration[0].toUpperCase()}${integration.slice(1)}`;

    const cats = formValues.categoriesData.map(category => {
      const subCats = category.subcategories.map(subcategory => {

        const mappedCategory = {
          subcategory_id: subcategory.id, // can be undefined if new
          subcategory_code: subcategory.subcategory_code // used to lookup id after category save if id undefined
        };

        // Mapping categories for a Third Party Integrators
        if (subcategory.leafly_category_id && isLeafly) {
          leaflyMappedCategories.push({
            ...mappedCategory,
            leafly_category_id: subcategory.leafly_category_id,
          });
        }
        if (subcategory.weedmaps_category_id && isWeedmaps) {
          weedmapsMappedCategories.push({
            ...mappedCategory,
            weedmaps_category_id: subcategory.weedmaps_category_id,
          });
        }
        if (subcategory.ohmetrc_category_id && isOhMetrc) {
          ohmetrcMappedCategories.push({
            ...mappedCategory,
            ohmetrc_category_id: subcategory.ohmetrc_category_id,
          });
        }
        if (subcategory.specification_id && isIsolocity) {
          isolocityMappedCategories.push({
            ...mappedCategory,
            specification_id: subcategory.specification_id,
          });
        }
        if (subcategory.cca_category_id && isCca) {
          ccaMappedCategories.push({
            ...mappedCategory,
            cca_category_id: subcategory.cca_category_id,
          });
        }

        // Mapping categories for a Main State Integrators
        if (subcategory.metrc_item_category_id && currentIntegrationTab === 'isMetrc') {
          mappedCategory.metrc_item_category_id = subcategory.metrc_item_category_id;
          mappedCategories.push(mappedCategory);

        } else if (subcategory.leaf_category_id && currentIntegrationTab === 'isLeaf') {
          mappedCategory.leaf_category_id = subcategory.leaf_category_id;
          mappedCategories.push(mappedCategory);

        } else if (subcategory.biotrack_category_id && currentIntegrationTab === 'isBiotrack') {
          mappedCategory.biotrack_category_id = subcategory.biotrack_category_id;
          mappedCategories.push(mappedCategory);

        } else if (subcategory.apisicpa_category_id && currentIntegrationTab === 'isApisicpa') {
          mappedCategory.apisicpa_category_id = subcategory.apisicpa_category_id;
          mappedCategories.push(mappedCategory);

        } else if (subcategory.cure_form_ids && subcategory.cure_form_ids.length && subcategory.cure_order_type_id && currentIntegrationTab === 'isCure') {
          subcategory.cure_form_ids.forEach(orderForm => {
            mappedCategories.push({
              ...mappedCategory,
              form_id: orderForm.value || orderForm,
              order_type_id: subcategory.cure_order_type_id,
            });
          });
        }

        return {
          id: subcategory.id,
          name: subcategory.name,
          display_name: subcategory.display_name,
          subcategory_code: subcategory.subcategory_code,
          active: subcategory.active * 1,
          sales_limit_category_key: subcategory.sales_limit_category_key,
          canada_category_id: subcategory.canada_category_id,
          oklahoma_category_id: get(subcategory, 'oklahoma_category_id'),
          is_shared: Number(subcategory.is_shared)
        };
      });
      return {
        id: category.id,
        display_name: category.name,
        subcategories: subCats,
      };
    });

    return {
      cats,
      mappedCategories,
      leaflyMappedCategories,
      ohmetrcMappedCategories,
      weedmapsMappedCategories,
      isolocityMappedCategories,
      ccaMappedCategories
    };
  }

  mergeCategories(formValues, categories){
    return formValues.categoriesData = formValues.categoriesData.map((cat, catIndex) => {
      cat.subcategories = cat.subcategories.map(subCat => {
        if(!subCat.id){
          const updatedSubcats = get(categories, `${catIndex}.subcategories`, []);
          const updatedSubcat = updatedSubcats.find(item => item.name === subCat.name);
          subCat.id = updatedSubcat.id;
        }
        return subCat;
      });
      return cat;
    });
  }

  onSaveCompleted(){
    const { actions, params: {integration} } = this.props;
    const { goBackDepth, goBackAfterSubmit, sharedList: {toActivate}} = this.state;

    if(toActivate.length) {
      actions.addMessage(messageTypes.success, 'categoryManagement.sharedMessage');
    }
    if (goBackAfterSubmit) {
      this.hideLoadingOverlay();
      return actions.go(-goBackDepth);
    }

    window.scrollTo(0, 0);
    this.loadCategories(integration, true);
  }

  handleSubmit(formValues) {
    // Current State integration
    const integration = this.props.params.integration;
    const isIntegration = integration && `is${integration[0].toUpperCase()}${integration.slice(1)}`;
    const currentIntegrationTab = integration && `is${integration[0].toUpperCase()}${integration.slice(1)}`;

    const {sharedList: {toActivate}} = this.state;

    this.showLoadingOverlay(I18n.t('common.form.saving'));

    // Variables for third party integrations
    const {isOhMetrc, isWeedmaps, isLeafly, isIsolocity, isCca} = this.props.integrationState;
    const {cats} = this.processFormValuesCategories(formValues);

    const categoriesPromise = this.props.actions.postItem('/api/categories',
      {categories:cats},
      dataNames.categoriesData,
      {success:'categories.update.success', failed: 'categories.update.failed'},
      null,
      (categories) => {
        formValues.categoriesData = this.mergeCategories(formValues, categories);
        const processFormCategories = this.processFormValuesCategories(formValues);
        let { mappedCategories } =  processFormCategories;
        const { leaflyMappedCategories, ohmetrcMappedCategories, weedmapsMappedCategories, isolocityMappedCategories, ccaMappedCategories } = processFormCategories;
        const haveAnUndefinedMappedId = mappedCategories.find((category) => !category.subcategory_id);

        if(haveAnUndefinedMappedId) {
          const subCategoriesLookup = categories.reduce((acc, category) => { //eslint-disable-line
            acc = acc.concat(category.subcategories);
            return acc;
          }, []);
          mappedCategories = mappedCategories.map((category) => { // Assign id
            if (!category.subcategory_id) {
              category.subcategory_id = subCategoriesLookup.find((sub) => sub.subcategory_code === category.subcategory_code).id;
            }
            return category;
          });
        }

        const promises = [];

        if (currentIntegrationTab === 'isMetrc' && mappedCategories.length) {
          promises.push(
            this.props.actions.postItem('/api/metrc/item_categories/mapping',
              {subcategories: mappedCategories},
              undefined,
              {failed: 'updateMetrcMappings.update.failed'})
          );
        }
        if (currentIntegrationTab === 'isLeaf' && mappedCategories.length) {
          promises.push(
            this.props.actions.postItem('/api/leaf/categories/mapping',
              {subcategories: mappedCategories},
              undefined,
              {failed: 'updateLeafMappings.update.failed'})
          );
        }
        if (currentIntegrationTab === 'isCure' && mappedCategories.length) {
          promises.push(
            this.props.actions.postData(
              '/api/cureapi/categories/mapping',
              {subcategories: mappedCategories},
              dataNames.cureCategoryMappings,
              {failed: 'updateCureMappings.update.failed'})
          );
        }
        if (currentIntegrationTab === 'isApisicpa' && mappedCategories.length) {
          promises.push(
            this.props.actions.postData(
              '/api/apisicpa/categories/mapping',
              {subcategories: mappedCategories},
              dataNames.apisicpaCategoryMappings,
              {failed: 'apisicpaMappings.update.failed'},
              null,
              () => {
                if(toActivate.length) this.props.actions.addMessage(messageTypes.success, 'categoryManagement.sharedConfirmation');
              })
          );
        } else if (isIntegration === 'isApisicpa') {
          this.props.actions.postData(
            '/api/apisicpa/categories/mapping',
            {subcategories: mappedCategories},
            dataNames.apisicpaCategoryMappings,
            {failed: 'apisicpaMappings.update.failed'},
            null,
            () => {
              this.onSaveCompleted();
            }
          ).catch(this.hideLoadingOverlay);
        } else if (isIntegration === 'isCanada') {
          this.onSaveCompleted();
        }
        if (isWeedmaps && weedmapsMappedCategories.length) {
          promises.push(
            this.props.actions.postItem(
              '/api/weedmaps/item_categories/mapping',
              {subcategories: weedmapsMappedCategories},
              undefined,
              {failed: 'updateWeemapsMappings.update.failed'})
          );
        }
        if (isCca && ccaMappedCategories.length) {
          promises.push(
            this.props.actions.postItem(
              '/api/cca/categories/mappings',
              {subcategories: ccaMappedCategories},
              undefined,
              {failed: 'updateCcaMappings.update.failed'})
          );
        }
        if (isOhMetrc && ohmetrcMappedCategories.length) {
          promises.push(
            this.props.actions
              .postItem('/api/ohmetrc/item_categories/mapping',
                {subcategories: ohmetrcMappedCategories},
                null,
                {failed: 'updateMetrcMappings.update.failed'}
              )
          );
        }
        if (isLeafly && leaflyMappedCategories.length) {
          promises.push(
            this.props.actions.postItem(
              '/api/leafly/item_categories/mapping',
              {subcategories: leaflyMappedCategories},
              undefined,
              {failed: 'updateLeaflyMappings.update.failed'})
          );
        }

        if (isIsolocity && isolocityMappedCategories.length) {
          promises.push(
            this.props.actions.postItem(
              '/api/isolocity/item_categories/mapping',
              {subcategories: isolocityMappedCategories},
              undefined,
              {failed: 'updateLeaflyMappings.update.failed'})
          );
        }

        if (promises.length > 0) {
          this.showLoadingOverlay();
          Promise.all(promises)
            .then(() => this.onSaveCompleted())
            .catch(this.hideLoadingOverlay);
        } else {
          this.onSaveCompleted();
        }
      }
    );
    categoriesPromise.catch(this.hideLoadingOverlay);
  }

  lengthBan(e, field, change){
    const value = e.target.value;
    if( !(value.length > 25)){
      change(field, value);
    }
  }

  setCategory(subCategory) {
    this.props.actions.unsetData(dataNames.products);
    this.setState({subCategory: subCategory, showModal: true, loadingProducts: true});

    const search_params = {
      fields: 'id, name, active',
      sort: 'name asc',
      query: 'matchall',
      size: '100000',
      start: '0',
      filter: 'subcategory_id:' + subCategory.id + ' AND item_master_parent_id:0 AND is_draft:0'
    };
    this.props.actions.getSearchData('/api/search/item_masters', dataNames.products, null, search_params)
      .then(() => this.setState({loadingProducts: false}));
  }

  renderTabs() {
    const { integrationState, params } = this.props;
    const integrationNames = {
      isMetrc: I18n.t('categoryManagement.metrcCategory'),
      isApisicpa: I18n.t('categoryManagement.apisicpaCategory'),
      isLeaf:  I18n.t('categoryManagement.leafCategory'),
      isLeafly: I18n.t('categoryManagement.leaflyCategory'),
      isWeedmaps: I18n.t('categoryManagement.weedmapsCategory'),
      isCanada: I18n.t('categoryManagement.reportingMapping'),
      isCure: 'Cure',
      isIsolocity: I18n.t('categoryManagement.isolocityCategory'),
      isCca: I18n.t('categoryManagement.ccaCategories'),
    };

    let activeKey;
    const tabs = [];
    const addTab = (integration) => {
      if (!integrationState[integration]) {
        return false;
      }

      const tabKey = integration.slice(2).toLowerCase();
      if (integration.toLowerCase() === `is${params.integration}`) {
        activeKey = tabKey;
      }

      tabs.push({
        title: integrationNames[integration],
        eventKey: tabKey,
      });
    };

    Object
      .keys(integrationNames)
      .forEach(addTab);

    const switchTab = (val) => {
      if(parseInt(this.props.salesRulesMapping) === 1){
        val += '?srm=1';
      }
      this.props.actions.push(`/category/management/${val}`);
    };

    // Redirect to first integration tab if we have tabs
    if(!params.integration && (tabs.length > 0 )) {
      switchTab(tabs[0].eventKey);
    }

    return (
      <Tabs id='filterTabs' activeKey={activeKey} onSelect={switchTab}>
        {tabs.map(this.renderTab)}
      </Tabs>
    );
  }

  renderTab(tab) {
    return (
      <Tab
        key={tab.eventKey}
        eventKey={tab.eventKey}
        title={tab.title}/>
    );
  }

  getActiveIntegrators() {
    const { params, integrationState } = this.props;
    const findHandler = (value) => value.toLowerCase() === `is${params.integration}`;
    const integrators = {
      isMetrc: false,
      isBiotrack: false,
      isApisicpa: false,
      isLeaf: false,
      isLeafly: false,
      isCure: false,
      isCanada: false,
      isWeedmaps: false,
      isIsolocity: false
    };

    const activeIntegrator = Object
      .keys(integrationState)
      .find(findHandler);

    integrators[activeIntegrator] = true;

    return integrators;
  }

  render() {
    const {showModal, showSharedModal, loadingProducts} = this.state;
    const {
      categoriesData, products, metrcCategories, leafCategories, rulesCategories, isRetailFacility, cureFormOptions,
      cureOrderTypeOptions, isCanadaFacility, reportingMapping, hasSalesRulesEnabled, salesRulesCategoriesArePreset, biotrackCategories, apisicpaCategories,
      leaflyCategories, isMasterFacility, weedmapsCategories, integrationState, isolocityCategories,
      ccaCategories, categorySettings, subCategorySettings
    } = this.props;

    const integration = this.props.params.integration;
    const isIntegration = integration && `is${integration[0].toUpperCase()}${integration.slice(1)}`;

    if (isIntegration && !integrationState[isIntegration]) {
      // Chosen integration isn't active go to normal management page
      this.props.actions.push('/category/management');
    }

    return (
      <div className='category-management-page'>
        <InProgressOverlay isActive={this.state.loading} message={this.state.progressMessage}/>
        <h1>{I18n.t('categoryManagement.title')}</h1>
        {this.renderTabs()}
        {
          categoriesData.length
            ? (<CategoryManagementForm
              categoriesData={categoriesData}
              setCategory={this.setCategory}
              onShowConfirmSharedModal={this.onShowConfirmSharedModal}
              onSubmit={this.handleSubmit}
              lengthBan={this.lengthBan}
              activeIntegrations={this.getActiveIntegrators()}
              metrcCategories={metrcCategories}
              biotrackCategories={biotrackCategories}
              apisicpaCategories={apisicpaCategories}
              leafCategories={leafCategories}
              leaflyCategories={leaflyCategories}
              rulesCategories={rulesCategories}
              isRetailFacility={isRetailFacility}
              cureFormOptions={cureFormOptions}
              cureOrderTypeOptions={cureOrderTypeOptions}
              isCanadaFacility={isCanadaFacility}
              reportingMapping={reportingMapping}
              hasSalesRulesEnabled={hasSalesRulesEnabled}
              salesRulesCategoriesArePreset={salesRulesCategoriesArePreset}
              isMasterFacility={isMasterFacility}
              changeSharedList={this.changeSharedList}
              weedmapsCategories={weedmapsCategories}
              integrationState={integrationState}
              isolocityCategories={isolocityCategories}
              ccaCategories={ccaCategories}
              categorySettings={categorySettings}
              subCategorySettings={subCategorySettings}
            />)
            : null
        }
        <ModalWrapper
          Component={ProductsListing}
          onHide={() => this.setState({showModal: false})}
          showModal={showModal}
          title='categories.products.products'
          subTitle={`In Sub-Category: ${this.state.subCategory.name}`}
          products={products}
          isLoading={loadingProducts}
          version={2}
          headerClass={'bg-light-gray'}
          okayButton={{show: true, text: 'Close', variant: 'default'}}
          cancelButton={{show: false}}
        />
        <ModalWrapper
          Component={false}
          version={2}
          onHide={() => this.setState({showSharedModal: false})}
          showModal={showSharedModal}
          title='categoryManagement.sharedProducts'
          okayButton={{show: true, text: 'Confirm', variant: 'default', onClick: this.onConfirmShared}}
          cancelButton={{show: true, text: 'Close', variant: 'default'}}
        >
          <div>{I18n.t('categoryManagement.sharedConfirmation')}</div>
        </ModalWrapper>
      </div>
    );
  }
}

CategoryManagementPage.defaultProps = {
  params: {
    integration: null,
  }
};

CategoryManagementPage.propTypes = {
  actions: PropTypes.object.isRequired,
  categoriesData: PropTypes.array.isRequired,
  subCategory: PropTypes.object,
  metrcCategories: PropTypes.object.isRequired,
  leafCategories: PropTypes.array.isRequired,
  leaflyCategories: PropTypes.array.isRequired,
  biotrackCategories: PropTypes.array.isRequired,
  apisicpaCategories: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
  rulesCategories: PropTypes.array.isRequired,
  isRetailFacility: PropTypes.bool.isRequired,
  cureFormOptions: PropTypes.array.isRequired,
  cureOrderTypeOptions: PropTypes.array.isRequired,
  isCanadaFacility: PropTypes.bool.isRequired,
  reportingMapping: PropTypes.array.isRequired,
  weedmapsCategories: PropTypes.array,
  hasSalesRulesEnabled: PropTypes.bool,
  salesRulesCategoriesArePreset: PropTypes.bool,
  isFacilityGroupMaster: PropTypes.bool,
  isMasterFacility: PropTypes.bool,
  params: PropTypes.oneOfType([PropTypes.array, PropTypes.shape({
    integration: PropTypes.string
  })]),
  isolocityCategories: PropTypes.array,
  ccaCategories: PropTypes.array,
  categorySettings: PropTypes.object,
  subCategorySettings: PropTypes.object,
};

function mapStateToProps(state) {
  const salesRulesMapping = getUrlParam('srm');
  const isCanadianFacility = isCanadaFacility(state);
  const integrationState = getIntegrationState(state);
  return {
    salesRulesMapping,
    subCategory: state.subCategory,
    products: state[dataNames.products] ? state[dataNames.products] : [],
    categoriesData: getCategoryManagementCategories(state),
    integrationState,
    biotrackCategories: getBiotrackCategories(state),
    apisicpaCategories: getApisicpaCategories(state),
    leafCategories: getLeafSubCategories(state),
    leaflyCategories: getLeaflySubCategories(state),
    metrcCategories: getGroupedMetrcCategories(state),
    rulesCategories: getRulesCategories(state),
    isRetailFacility: isRetailFacility(state),
    cureFormOptions: getCureFormOptions(state),
    cureOrderTypeOptions: getCureOrderTypeOptions(state),
    isCanadaFacility: isCanadianFacility ?  isCanadianFacility : false,
    reportingMapping: state[dataNames.reportingMapping],
    hasSalesRulesEnabled: ( (hasSalesRulesEnabled(state) || parseInt(salesRulesMapping) === 1) && !isCanadianFacility),
    salesRulesCategoriesArePreset: (salesRulesCategoriesArePreset(state) && hasSalesRulesEnabled(state)),
    isMasterFacility: isFacilityGroupMaster(state),
    weedmapsCategories: getWeedmapsCategories(state),
    isOARRSReportingEnabled: isOARRSReportingEnabled(state),
    isolocityCategories: getIsolocitySubCategories(state),
    ccaCategories: getCCACategories(state),
    categorySettings: getCategorySettings(state),
    subCategorySettings: getSubCategorySettings(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...apiActions,
      go,
      push,
      submit: () => dispatch(submit(CATEGORY_MANAGEMENT_FORM)),
      unsetData,
      addMessage,
      getIntegrationCategoriesData,
    }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryManagementPage);
