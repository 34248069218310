/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { push } from 'react-router-redux';

import PageTitle from '../../../common/PageTitle';
import RewardsForm from './RewardsForm';
import * as dataNames from '../../../../constants/dataNames';
import {getTableData} from '../../../../actions/retailGroupListingActions';
import {createReward} from '../../../../actions/rewardsActions';
import {getUnpaginatedData} from '../../../../actions/apiActions';

export class RewardsPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    this.props.actions.getTableData(dataNames.customerGroupData);
    this.props.actions.getUnpaginatedData('/api/partner_facilities', dataNames.partnerFacilities);
  }

  handleSubmit(event, formData) {
    event.preventDefault();
    this.props.actions.createReward(formData)
      .then(() => this.props.actions.push('/'));
  }

  render() {
    const {customerGroupData, partnerFacilities} = this.props;
    const allProducts = [
      {for_selected_product: 'Product 1', id: 1},
      {for_selected_product: 'Product 2', id: 2},
      {for_selected_product: 'Product 3', id: 3},
      {for_selected_product: 'Product 4', id: 4}
    ];
    return (
      <div className='rewards-page'>
        <PageTitle primaryText={I18n.t('retail.title')} secondaryText={I18n.t('retail.rewards.title')}/>
        <RewardsForm handleSubmit={this.handleSubmit}
                     customerGroupData={customerGroupData}
                     partnerFacilities={partnerFacilities}
                     allProducts={allProducts}
        />
      </div>
    );
  }
}

// RewardsPage.contextTypes = {
//   router: React.PropTypes.object.isRequired
// };

RewardsPage.propTypes = {
  customerGroupData: PropTypes.array.isRequired,
  partnerFacilities: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    createReward: PropTypes.func.isRequired,
    getTableData: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }).isRequired
};

function mapStateToProps(state) {
  return {
    customerGroupData: state.customerGroupData,
    partnerFacilities: state.partnerFacilities
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({createReward, getTableData, getUnpaginatedData, push}, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RewardsPage);
