import PropTypes from 'prop-types';
import { reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

import validate from '../common/validate';
import PricingClassForm from '../common/PricingClassForm';

import * as dataNames from '../../../../constants/dataNames';
import * as itemNames from '../../../../constants/itemNames';

const form = 'modifyPricingClassForm';
const getFormValue = formValueSelector(form);

const PricingClassReduxForm = reduxForm({
  form,
  validate,
  enableReinitialize: true
})(PricingClassForm);

function mapStateToProps(state) {
  const currentFacility = state[itemNames.facility];
  
  return {
    showSaveAndReturn: false,
    getFormValue: name => getFormValue(state, name),
    facilities: state[dataNames.facilities].filter(facility => facility.type === currentFacility.type),
  };
}

const ModifyPricingClassFormWrapper = connect(mapStateToProps)(PricingClassReduxForm);

ModifyPricingClassFormWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  customerGroups: PropTypes.array.isRequired,
  initialValues: PropTypes.object,
};

export default ModifyPricingClassFormWrapper;
