import difference from 'lodash.difference';
import union from 'lodash.union';
import * as types from '../../constants/actionTypes';
import initialState from '../../constants/initialState';

export default function appliedCouponsReducer (state = initialState.cart.appliedCoupons, action){
  const {couponId} = action;
  switch (action.type) {
  case types.ADD_APPLIED_COUPON:
    return union(state, [couponId]);
  case types.REMOVE_COUPON:
    return difference(state, [couponId]);
  default:
    return state;
  }
}
