import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import {FaMinus, FaPlus} from 'react-icons/fa';
import {I18n} from 'react-redux-i18n';
import { Field } from 'redux-form';
import NumericInput from '../../../common/form/NumericInput';
import ReactSelectInput from '../../../common/form/ReactSelectInput';

export const NewSplitHarvestBatches = (props) => {
  const {locations} = props;
  return (<tbody>
  {
    props.fields.map((fieldPrefix, index) => {
      return (<tr key={index} className='form-group-no-margin'>
        <td
          style={Object.assign({}, {textAlign: 'center', verticalAlign: 'middle', fontWeight: 'bold'}, props.fields.length > 1 ? {cursor: 'pointer'} : {})}
          onClick={(e) => {
            e.target.blur();
            if(props.fields.length > 1) props.fields.remove(index);
          }}
        >
          <FaMinus
            className={props.fields.length > 1 ? 'text-danger' : 'text-muted'}
          />
        </td>
        <td style={{textAlign: 'center', verticalAlign: 'middle'}}>{index + 1}</td>
        <td>
          <Field
            name={`${fieldPrefix}.weight`}
            component={NumericInput}
          />
        </td>
        <td>
          <Field
            name={`${fieldPrefix}.location`}
            component={ReactSelectInput}
            props={{
              options: locations,
              placeholder: I18n.t('common.form.selectPlaceholder'),
            }}
          />
        </td>
        <td
          style={{textAlign: 'center', verticalAlign: 'middle', cursor: 'pointer'}}
        >
          <Button
            variant='primary'
            type='button'
            onClick={(e) => {
              e.target.blur();
              props.fields.push({weight: 0, location: null});
            }}
          >
            <FaPlus />
          </Button>
        </td>
      </tr>);
    })
  }
  </tbody>);
};

NewSplitHarvestBatches.propTypes = {
  fields: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
};

export default NewSplitHarvestBatches;


