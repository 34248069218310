
import * as types from '../../constants/actionTypes';
import initialState from '../../constants/initialState';

export default function itemReducerFactory (itemName = '') {

  return function itemReducer (state = state = initialState[itemName], action){
    const {name, payload} = action;
    const item = payload ? payload.data : [];
    if(name !== itemName) {
      return state;
    }
    switch (action.type) {
    case types.SET_ITEM:
      return payload;
    case types.SET_ITEM_VALUE:
      return Object.assign({}, state, {[action.key]: payload});
    case types.GET_ITEM_SUCCESS:
    case types.ADD_ITEM_SUCCESS:
    case types.EDIT_ITEM_SUCCESS:
      return item;
    case types.REMOVE_ITEM_SUCCESS:
    case types.UNSET_ITEM:
      return initialState[itemName];
    case types.GET_ITEM_FAILED:
    case types.ADD_ITEM_FAILED:
    case types.EDIT_ITEM_FAILED:
    default:
      return state;
    }
  };

}
