import React from 'react';
import PropTypes from 'prop-types';
import MetrcMiLimitsRow from './MetrcMiLimitsRow';
import LimitRow from './LimitRow';
import MetrcLimitsRow from './MetrcLimitsRow';

const LimitsTableFooter = ({integrationState: {isBiotrack, isMiMetrc, isMdMetrc}, areConsumerLimitsLoading, getTotalLimit, limit,
                             getDisplayRulesPropertySum, consumer, getStyle, customerLimits, customerId, multiViews, isAnonymousOrder, hasMetrcSalesLimits}) => {
  switch (true) {
  case (isMiMetrc && !!customerId):
    return (<MetrcMiLimitsRow
      consumer={consumer}
      areConsumerLimitsLoading={areConsumerLimitsLoading}
      getStyle={getStyle}
      getDisplayRulesPropertySum={getDisplayRulesPropertySum}
      customerLimits={customerLimits}
    />);
  case (hasMetrcSalesLimits && !!customerId):
    return (<MetrcLimitsRow
      consumer={consumer}
      areConsumerLimitsLoading={areConsumerLimitsLoading}
      getStyle={getStyle}
      multiViews={multiViews}
      getDisplayRulesPropertySum={getDisplayRulesPropertySum}
      customerLimits={customerLimits}/>);
  default:
    return (<LimitRow
      getDisplayRulesPropertySum={getDisplayRulesPropertySum}
      getTotalLimit={getTotalLimit}
      limit={limit}
      areConsumerLimitsLoading={areConsumerLimitsLoading}
      getStyle={getStyle}
      isBiotrack={isBiotrack}
      isAnonymousOrder={isAnonymousOrder}
      isMdMetrc={isMdMetrc}
    />);
  }
};


LimitsTableFooter.propTypes = {
  integrationState: PropTypes.object.isRequired,
  areConsumerLimitsLoading: PropTypes.func.isRequired,
  getTotalLimit: PropTypes.func.isRequired,
  limit: PropTypes.object.isRequired,
  getDisplayRulesPropertySum: PropTypes.func.isRequired,
  consumer: PropTypes.object,
  getStyle: PropTypes.func.isRequired,
  customerLimits: PropTypes.object.isRequired,
  customerId: PropTypes.number,
  multiViews: PropTypes.bool,
  isAnonymousOrder: PropTypes.bool,
  hasMetrcSalesLimits: PropTypes.bool,
};

export default LimitsTableFooter;
