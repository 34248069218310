import React from 'react';
import PropTypes from 'prop-types';
import {Field, FormSection} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import TextInput from '../../../common/form/TextInput';
import PermissionWrapper from '../../../common/PermissionWrapper';
import * as p from '../../../../constants/permissions';
import InlineCheckBox from '../../../common/form/InlineCheckBox';

const UtahIntegratorSettings = ({integratorName, features}) => {

  return (
    <Col xs={12}>
      <h3>{I18n.t('stateIntegrators.form.utahConfiguration')}</h3>
      <FormSection name={`${integratorName}.value`}>
        <React.Fragment>
          <Row>
            <Col sm={6} md={4} lg={3}>
              <Field name='client_id' component={TextInput} props={{
                label: I18n.t('stateIntegrators.form.client_id'),
                isRequired: true,
              }}/>
            </Col>
          </Row>
          <Row>
            <Col sm={6} md={4} lg={3}>
              <Field name='client_secret' component={TextInput} props={{
                label: I18n.t('stateIntegrators.form.client_secret'),
                isRequired: true,
              }}/>
            </Col>
          </Row>
          <Row>
            <Col sm={6} md={4} lg={3}>
              <Field name='source_system' component={TextInput} props={{
                label: I18n.t('stateIntegrators.form.sourceSystem'),
                isRequired: true,
              }}/>
            </Col>
          </Row>
          <Row>
            <Col sm={6} md={4} lg={3}>
              <Field name='account_name' component={TextInput} props={{
                label: I18n.t('stateIntegrators.form.accountName'),
                isRequired: true,
              }}/>
            </Col>
          </Row>
          <Row>
            <Col sm={6} md={4} lg={3}>
              <Field name='facility' component={TextInput} props={{
                label: I18n.t('stateIntegrators.form.facility'),
                isRequired: true,
              }}/>
            </Col>
          </Row>
        </React.Fragment>
        <PermissionWrapper permissions={[p.super_admin_only]}>
          <Row>
            <Col sm={3} md={4} lg={2}>
              <Field name='use_test_api' component={InlineCheckBox} props={{
                label: I18n.t('stateIntegrators.form.useTestApi'),
              }}/>
            </Col>
          </Row>
        </PermissionWrapper>
      </FormSection>
    </Col>
  );
};

UtahIntegratorSettings.propTypes = {
  integratorName: PropTypes.string.isRequired,
  features: PropTypes.object.isRequired,
};

export default UtahIntegratorSettings;

