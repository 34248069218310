import get from 'lodash.get';
import {set} from 'lodash';

/*
  Gets a local storage item decoded as an object
*/
export const getLocalStorage = (itemPath) => {
  const pathParts = itemPath.split('.');
  const rootKey = pathParts[0];
  const childrenKeys = pathParts.slice(1);

  const rootItemString = localStorage.getItem(rootKey);
  const rootObject = rootItemString && JSON.parse(rootItemString) || {} ;
  if (childrenKeys.length === 0) {
    return rootObject;
  }

  return get(rootObject, childrenKeys);
};

/*
  Sets a value in a JSON encoded local storage object.
*/
export const setLocalStorage = (itemPath, value) => {
  const pathParts = itemPath.split('.');
  const rootKey = pathParts[0];
  const childrenKeys = pathParts.slice(1);

  const rootObject = getLocalStorage(rootKey);
  set(rootObject, childrenKeys, value);

  localStorage.setItem(rootKey, JSON.stringify(rootObject));
};
