import {I18n} from 'react-redux-i18n';
import {requiredFieldValidation} from '../../common/form/redux-form/validations';

export default function validate(values) {
  return {
    roles: (values.roles || []).map(role => {
      const hasPermission = role.modules.some(module => module.permissions.length > 0);
      return {
        role_name: requiredFieldValidation(role.role_name),
        modules: {
          _error: hasPermission ? null : I18n.t('userRoles.noPermissionsError'),
        },
      };
    })
  };
}
