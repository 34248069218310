import * as types from '../../constants/actionTypes';
import initialState from '../../constants/initialState';

export default function assignDriverOpenReducer (state = initialState.cart.assignDriverOpen, action){
  switch (action.type) {
  case types.CLOSE_ASSIGN_DRIVER:
    return false;
  case types.OPEN_ASSIGN_DRIVER:
    return true;
  default:
    return state;
  }
}
