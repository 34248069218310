import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push, goBack } from 'react-router-redux';
import get from 'lodash.get';
import * as apiActions from '../../../actions/apiActions';
import * as itemNames from '../../../constants/itemNames';
import * as dataNames from '../../../constants/dataNames';
import FormWrapper from '../../common/form/FormWrapper';
import getPayload from '../../../selectors/forms/plantSplitFormSelector';
import LabelPrinter from '../../print-services/labels/LabelPrinter'; //eslint-disable-line
import PlantSplitForm from './PlantSplitForm'; //eslint-disable-line

export class PlantSplitPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.onHideLabelPrinter = this.onHideLabelPrinter.bind(this);
    this.onPrintLabels = this.onPrintLabels.bind(this);
    this.state = {
      payload: {}, // for labels
      showLabelPrinter: false
    };
  }

  componentDidMount() {
    this.props.actions.getItem(`/api/plants/${this.props.params.id}`, itemNames.splitPlant);
    this.props.actions.getUnpaginatedData('/api/cultivation/sections', dataNames.sections);
    this.props.actions.getItem(
      '/api/settings/plants',
      itemNames.complianceSettings,
      { failed: 'cultivation.complianceSettings.failed' },
      { ids: ['cult_track_plants_as_groups'] }
    );
  }

  onSubmit(values) {
    this.props.actions.postItem(`/api/plants/${this.props.params.id}/split`, getPayload(values)).then((data) => {
      if (values.afterSubmit !== 'print') return this.props.actions.goBack();
      this.setState({
        payload: { ids: data.map((item) => item.id) },
        showLabelPrinter: true
      });
    });
  }

  onPrintLabels() {
    this.setState({
      showLabelPrinter: true
    });
  }

  onHideLabelPrinter() {
    this.setState({ showLabelPrinter: false });
  }

  render() {
    const { splitPlant, initialValues, sections, complianceSettings } = this.props;
    return (
      <FormWrapper title={I18n.t('plants.split.title')} goBack={(event) => this.props.actions.goBack()}>
        <LabelPrinter
          showModal={this.state.showLabelPrinter}
          onHide={this.onHideLabelPrinter}
          labelTag='cult_plant_tag'
          payload={this.state.payload}
        />
        <PlantSplitForm
          splitPlant={splitPlant}
          initialValues={initialValues}
          sections={sections}
          onPrintLabels={this.onPrintLabels}
          onSubmit={this.onSubmit}
          trackPlantsAsGroups={get(complianceSettings, 'cult_track_plants_as_groups.value', false)}
        />
      </FormWrapper>
    );
  }
}

PlantSplitPage.propTypes = {
  initialValues: PropTypes.object
};

function mapStateToProps(state) {
  const splitPlant = state[itemNames.splitPlant];
  const initialValues = Object.assign({}, splitPlant, {
    newQty: splitPlant.qty,
    splits: [{ qty: 1, section_id: null, number_of_groups: 1 }]
  });
  return {
    initialValues,
    sections: state[dataNames.sections],
    splitPlant: splitPlant,
    complianceSettings: state[itemNames.complianceSettings]
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, { push, goBack });
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlantSplitPage);
