import React from 'react';
import { I18n } from 'react-redux-i18n';
import {Field} from 'redux-form';
import {Link} from 'react-router';
import {Row, Col} from 'react-bootstrap';
import {FaExclamationTriangle} from 'react-icons/fa';
import PropTypes from 'prop-types';
import AccordionPanel from '../../common/AccordionPanel';
import dominance from '../../../constants/strainDominance';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import ReduxTagField from '../../common/form/redux-form/ReduxTagField';
import ComboboxInput from '../../common/form/ComboboxInput';
import HoverTooltip from '../../common/HoverTooltip';

const GeneralInformationSection = (props) => {
  const {
    subcategories, strains, phenotypes, commonOptions, tagsActions, tags, uoms, showCategoryError, showUomError, showInventoryError,
    change, fieldActions, integrationState, canChangeSubcategory, brands, isConnectsActive, isStrainRequired, isStrainDisabled,
  } = props;

  const uomErrorText = (showUomError ? I18n.t('products.massModify.errors.diff_uoms', {field: I18n.t('products.form.inventoryUom')}) : '')
    + (showInventoryError ? I18n.t('products.massModify.errors.inventory_exist', {field: I18n.t('products.form.inventoryUom')}) : '');

  return (
    <AccordionPanel title={'products.massModify.generalInformationTitle'}>
      <span id={'general_section'}/>
      <Row>
        <Col md={3}>
          <Field name='subcategory_id' component={ReactSelectInput} props={{
            label: I18n.t('products.form.itemSubcategory'),
            options: integrationState.isWaLeaf ? subcategories.filter((sub) => sub.subcategory_code !== 'OIL') : subcategories,
            textKey: 'name',
            valueKey: 'id',
            disabled: showCategoryError || !canChangeSubcategory,
            rightAddon: showCategoryError ? <HoverTooltip text={I18n.t('products.massModify.errors.diff_categories_error', {field: I18n.t('products.form.itemSubcategory')})} id={'subcategory_id_tooltip'}/> : null
          }}/>
          {(integrationState.isLeafly || integrationState.isMetrc || integrationState.isWeedmaps || integrationState.isIsolocity) && (
            <div className='alert alert-warning'>
              <p>
                <small>
                  <FaExclamationTriangle style={{marginRight: '5px'}}/>
                  {I18n.t('products.form.subcategoryWarning')}
                  <br />
                  <Link to='/category/management'>{I18n.t('products.form.categoryManagementLink')}</Link>
                </small>
              </p>
            </div>
          )}
        </Col>
        <Col md={3}>
          <Field name='strain_id' component={ReactSelectInput} props={{
            label: I18n.t('products.form.strainName'),
            options: strains,
            textKey: 'strain_name',
            valueKey: 'id',
            isRequired: (isStrainRequired && !isStrainDisabled),
            disabled: isStrainDisabled,
            rightAddon: isStrainDisabled ? <HoverTooltip text={I18n.t('products.massModify.errors.diff_field_error', {
              field: I18n.t('products.form.strainName'),
              label: I18n.t('common.strains'),
            })} id={'strain_id_tooltip'}/> : null
          }}/>
        </Col>
        <Col sm={3}>
          <Field name='phenotype_id' component={ReactSelectInput} props={{
            label: I18n.t('products.form.phenotype'),
            options: phenotypes,
            textKey: 'name',
            valueKey: 'id'
          }}/>
        </Col>
        <Col sm={3}>
          <Field name='dominance' component={ReactSelectInput} props={{
            label: I18n.t('products.form.dominanceName'),
            options: dominance,
            textKey: 'text',
            valueKey: 'value',
          }}/>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <Field name='is_purchasing_item' component={ReactSelectInput} props={{
            label: I18n.t('products.form.purchaseInventory'),
            options: commonOptions,
          }}/>
        </Col>
        {isConnectsActive &&
          <Col md={3} />
        }
        <Col md={3}>
          <Field name='tags_action' component={ReactSelectInput} props={{
            label: I18n.t('products.form.tags'),
            options: tagsActions,
            onChange: (value) => {
              if (value === 'clear') {
                change(`tags`, []);
              }
              change(`tags_action`, value);
            },
          }}/>
        </Col>
      </Row>

      <Row>
        <Col md={3}>
          <Field name='is_manufactured_item' component={ReactSelectInput} props={{
            label: I18n.t('products.form.produceInventory'),
            options: commonOptions
          }}/>
        </Col>
        {isConnectsActive &&
          <Col md={3}>
              <Field name='available_to_connects' component={ReactSelectInput} props={{
                label: I18n.t('products.form.availableToConnects'),
                options: commonOptions
              }}/>
          </Col>
        }
        <Col md={6}>
          <ReduxTagField name='tags' props={{
            options: tags,
            textKey: 'tag_name',
            valueKey: 'tag_name',
            disabled: fieldActions.tags_action === 'clear'
          }}/>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <Field name='default_uom' component={ReactSelectInput} props={{
            label: I18n.t('products.form.inventoryUom'),
            options: uoms,
            textKey: 'uom_code',
            valueKey: 'uom_code',
            disabled: showUomError || showInventoryError,
            rightAddon: showUomError || showInventoryError ? <HoverTooltip text={uomErrorText} id={'default_uom_tooltip'}/> : null
          }}/>
        </Col>
        <Col md={3}>
          <Field name='active' component={ReactSelectInput} props={{
            label: I18n.t('products.form.active'),
            options: commonOptions,
          }}/>
        </Col>
      </Row>
      <Row>
        <Col md={3} className='field-width-100'>
          <Field name='brand' component={ComboboxInput} props={{
            label: 'Item Brand',
            placeholder: 'Select an Item Brand or create a new Brand',
            options: brands,
            textKey: 'name',
            valueKey: 'id',
          }}/>
        </Col>
      </Row>
    </AccordionPanel>
  );
};

GeneralInformationSection.propTypes = {
  subcategories: PropTypes.array.isRequired,
  strains: PropTypes.array.isRequired,
  phenotypes: PropTypes.array.isRequired,
  commonOptions: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
  tagsActions: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  uoms: PropTypes.array.isRequired,
  showCategoryError: PropTypes.bool.isRequired,
  showUomError: PropTypes.bool.isRequired,
  showInventoryError: PropTypes.bool.isRequired,
  fieldActions: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  canChangeSubcategory: PropTypes.bool.isRequired,
  brands: PropTypes.array,
  isConnectsActive: PropTypes.bool.isRequired,
  isStrainRequired: PropTypes.bool.isRequired,
  isStrainDisabled: PropTypes.bool.isRequired,
};

export default GeneralInformationSection;
