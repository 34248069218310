import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import {Field} from 'redux-form';

import ReduxButton from '../../common/form/redux-form/ReduxButton';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import TextInput from '../../common/form/TextInput';

const LotsFieldArray = (props) => {

  const {fields: {map, length}, partnerFacilities, lots} = props;
  return (
    length ? <div className='group packages'>
      <hr/>
      <h4>{I18n.t('packages.form.lotInformation')}</h4>
      <h5>{I18n.t('packages.form.lotMessage')}</h5>
        {map((lotName, lotIndex) => {
          const lot = lots[lotIndex];
          return (
            <Row key={lotName}>
              <Col xs={6} sm={4} md={2}>
                <Field name={`${lotName}.lot_number`} component={TextInput} props={{
                  label: I18n.t('packages.form.lot'),
                  disabled: true
                }}/>
              </Col>
              <Col xs={6} sm={3}>
                <Field name={`${lotName}.partner_facility_id`}
                  component={ReactSelectInput}
                  props={{
                    textKey: 'facility_name',
                    valueKey: 'id',
                    options:partnerFacilities,
                    label: I18n.t('packaging.form.sourceFacility'),
                    disabled: Boolean(!lot || lot.disabled || lot.submitted)
                  }} />
               </Col>
               <Col xs={6} sm={3}>
                  <ReduxButton
                    name={`${lotName}.submitted`}
                    variant={lot && (lot.submitted || lot.disabled) ? 'success' : 'warning'}
                    className={'btn-inline'}
                    props={{
                      value: lot ? lot.id : 0,
                      disabled: Boolean(!lot || lot.disabled || lot.submitted || !lot.partner_facility_id)
                    }}>
                    {lot && (lot.submitted || lot.disabled) ? I18n.t('packaging.form.saved') : I18n.t('packaging.form.saveLot')}
                  </ReduxButton>

               </Col>
            </Row>
          );
        })}
      </div> : <div/>
  );
};

LotsFieldArray.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
  }),
  partnerFacilities: PropTypes.array,
  lots: PropTypes.array.isRequired
};

export default LotsFieldArray;
