import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import {FaExclamationTriangle} from 'react-icons/fa';
import TexInput from '../../common/form/TextInput';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import InternationalDateTimePickerInput from '../../common/form/InternationalDateTimePickerInput';
import SubmitSection from '../../common/form/SubmitSection';
import PackageSearch from '../../common/scan-searches/PackageSearch';
import SplitPackageFieldArray from './SplitPackageFieldArray';
import InternationalQuantityByUomInput from '../../common/form/InternationalQuantityByUomInput';

const SplitPackageForm = (props) => {
  const {
    error,
    reset,
    handleSubmit,
    invalid,
    submitting,
    pristine,
    locations,
    metrcTrackingTags,
    integrationState,
    partners,
    partnerFacilities,
    onSearchComplete,
    showPackageLocation,
    showPackageIdScan,
    showPackageInfo,
    packageItems,
    changePackageLocation,
    onSubmit,
    printProcess,
    doPrint,
    currentFacilityUsers,
    sourceUomType,
    isQASampleDisabled,
    testSampleIndex,
    initialValues,
    hasPackagesTags,
    useEntityLocks
  } = props;

  const submitSectionSettings = {
    actionSettings: printProcess ? {
      savePrint: {
        invalid,
        submitting,
        text: I18n.t('common.actions.printLabel'),
        action: () => doPrint()
      }
    } : {
      reset: {
        pristine,
        text: I18n.t('common.actions.reset'),
        style: 'default',
        action: reset
      },
      savePrint: {
        invalid,
        submitting,
        text: I18n.t('common.actions.saveAndPrint'),
        action: handleSubmit(formValues => onSubmit(formValues, 'save_and_print', reset)),
      },
      submit: {
        invalid,
        submitting,
        text: I18n.t('common.actions.save'),
        action: handleSubmit(formValues => onSubmit(formValues, 'save', reset)),
      },
      saveAndOther: {
        invalid,
        submitting,
        text: I18n.t('common.actions.saveAndSplitOther'),
        action: handleSubmit(formValues => onSubmit(formValues, 'save_and_split_other', reset)),
      },
    },
    align: 'right'
  };

  const itemMaster = initialValues.itemMaster;
  const uom = itemMaster.uom;

  return (
    <form className='split-package-form' onSubmit={handleSubmit}>
      {error
          ? <div className='alert alert-danger'>
              <div>
                <FaExclamationTriangle style={{marginRight: '5px'}}/>
                {error}
              </div>
            </div>
        : null
      }

      {showPackageInfo ? null :
        <Row>
          {showPackageIdScan ?
          <Col md={4}>
            <PackageSearch
              label= {I18n.t('inventory.splitPackage.form.packageID')}
              onSearchComplete={onSearchComplete}
              useEntityLocks={useEntityLocks}
              filterEntityLockedItems={true}
              warnEntityLockedItems={true}
            />
          </Col>
            : null}
          {showPackageLocation ?
          <Col xs={3} sm={3} md={3}>
            <Field
              name='package_location'
              component={ReactSelectInput}
              props={{
                label: I18n.t('inventory.splitPackage.form.location'),
                options: packageItems,
                textKey: 'location_name',
                valueKey: 'item_id',
                onChange: changePackageLocation
              }}
            />
          </Col> : null}
        </Row>
      }
      {showPackageInfo ?
      <div>
      <Row>
        <Col xs={2} sm={2} md={2}>
          <Field
            name='package_id'
            component={TexInput}
            props={{
              label: I18n.t('inventory.splitPackage.form.packageID'),
              disabled: true
            }}
          />
        </Col>
        <Col xs={2} sm={2} md={2}>
          <Field
            name='current_location'
            component={TexInput}
            props={{
              label: I18n.t('inventory.splitPackage.form.currentLocation'),
              disabled: true
            }}
          />
        </Col>
        <Col xs={2} sm={2} md={2}>
          <Field
            name='item_master'
            component={TexInput}
            props={{
              label: I18n.t('inventory.splitPackage.form.productName'),
              disabled: true
            }}
          />
        </Col>
        <Col xs={2} sm={2} md={2}>
          <InternationalQuantityByUomInput
            name='qty'
            label={I18n.t('inventory.splitPackage.form.totalQuantity')}
            disabled={true}
            uom={uom}
          />
        </Col>
        <Col xs={2} sm={2} md={2}>
          <InternationalQuantityByUomInput
            name='qty_available'
            label={I18n.t('inventory.splitPackage.form.quantityAvailable')}
            disabled={true}
            uom={uom}
          />
        </Col>
        <Col xs={2} sm={2} md={2}>
          <InternationalQuantityByUomInput
            name='remainder'
            label={I18n.t('inventory.splitPackage.form.remainder')}
            disabled={true}
            uom={uom}
          />
        </Col>
      </Row>
      <FieldArray
        name='new_packages'
        component={SplitPackageFieldArray}
        locations={locations}
        metrcTrackingTags={metrcTrackingTags}
        integrationState={integrationState}
        partners={partners}
        partnerFacilities={partnerFacilities}
        currentFacilityUsers={currentFacilityUsers}
        sourceUomType={sourceUomType}
        isQASampleDisabled={isQASampleDisabled}
        testSampleIndex={testSampleIndex}
        itemMaster={itemMaster}
        hasPackagesTags={hasPackagesTags}

      />
      <Row>
        <Col md={{span: 3, offset: 8}}>
          <InternationalDateTimePickerInput
            name='event_date'
            props={{
              label: I18n.t('inventory.splitPackage.form.modificationDate'),
              enableTodayButton: true,
              isRequired: true
            }}
          />
        </Col>
      </Row>
      <SubmitSection
        settings={submitSectionSettings}
      />
      </div>
      : null}
    </form>
  );
};

SplitPackageForm.propTypes = {
  inputs: PropTypes.array,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  integrationState: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  metrcTrackingTags: PropTypes.array.isRequired,
  partners: PropTypes.array.isRequired,
  partnerFacilities: PropTypes.array.isRequired,
  initialValues: PropTypes.object,
  onSearchComplete: PropTypes.func.isRequired,
  showPackageLocation: PropTypes.bool.isRequired,
  showPackageIdScan: PropTypes.bool.isRequired,
  showPackageInfo: PropTypes.bool.isRequired,
  packageItems: PropTypes.array.isRequired,
  changePackageLocation: PropTypes.func.isRequired,
  printProcess: PropTypes.bool.isRequired,
  doPrint: PropTypes.func.isRequired,
  currentFacilityUsers: PropTypes.array.isRequired,
  sourceUomType: PropTypes.string,
  isQASampleDisabled: PropTypes.bool,
  testSampleIndex: PropTypes.number,
  failedTrackingIdValidation:  PropTypes.bool,
  hasPackagesTags: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  useEntityLocks: PropTypes.bool
};

export default SplitPackageForm;
