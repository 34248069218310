import {change} from 'redux-form';
import {PACKAGE_PLANTS_FORM} from '../../constants/forms';
import {isFormChangeOnField} from './utils';
import {REDUX_FORM_CHANGE} from '../../constants/actionTypes';
import {getSelectedItemMaster} from '../../selectors/forms/packagePlantsFormSelectors';
import packagableUnmedicatedSubcategories from '../../constants/packageableUnmedicatedSubcategories';


const packagePlantsForm = store => next => action => {
  const result = next(action);

  if (action.type !== REDUX_FORM_CHANGE || action.meta.form !== PACKAGE_PLANTS_FORM) return result;

  const state = store.getState();
  const {meta} = action;

  if (isFormChangeOnField(action, [PACKAGE_PLANTS_FORM], 'item_master_id')) {
    const selectedItemMaster = getSelectedItemMaster(state);

    const isPackagableUnmedicatedItem = packagableUnmedicatedSubcategories.includes(selectedItemMaster.subcategory_code);

    if (!isPackagableUnmedicatedItem) {
      store.dispatch(change(meta.form, 'qty', null));
    }

    store.dispatch(change(meta.form, 'deactivate_plants', !isPackagableUnmedicatedItem));
  }

  return result;
};

export default packagePlantsForm;
