import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';

import ManualDiscountForm from './ManualDiscountForm';
import validate from './validate';

const initialValues = {
  item_master_ids: [],
};

const ManualDiscountReduxForm = reduxForm({
  validate,
  initialValues,
})(ManualDiscountForm);

ManualDiscountReduxForm.propTypes = {
  form: PropTypes.string.isRequired,
  orderProductOptions: PropTypes.array.isRequired,
  discounts: PropTypes.array.isRequired,
  appliesTo: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
};

export default ManualDiscountReduxForm;
