import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PageTitle from '../../common/PageTitle';
import { getFile } from '../../../actions/apiActions';

export class PublicTransfersPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    const uuid = get(this.props, 'params.uuid');

    this.state = {
      uuid: uuid,
      loading_status: I18n.t(`supplyChain.publicManifestLoading`) + '...'
    };
  }

  componentWillMount() {
    if (!this.state.uuid) {
      this.setLoadingStatus(I18n.t(`supplyChain.publicManifestFailure`));
    } else {
      this.props.actions
        .getFile(
          `/api/transfers/print_public_manifest/${this.state.uuid}`,
          'manifest.pdf',
          { failed: 'cultivation.transfers.printInvoiceFailed' },
          {}
        )
        .then(() => {
          this.setLoadingStatus(I18n.t(`supplyChain.publicManifestSuccess`));
        })
        .catch(() => {
          this.setLoadingStatus(I18n.t(`supplyChain.publicManifestFailure`));
        });
    }
  }

  setLoadingStatus(newText) {
    this.setState({ loading_status: newText });
  }

  render() {
    return (
      <div>
        <PageTitle primaryText={I18n.t('supplyChain.publicManifest')} />

        <div id='loading_message'>{this.state.loading_status}</div>
      </div>
    );
  }
}

PublicTransfersPage.propTypes = {
  actions: PropTypes.object.isRequired,
  uuid: PropTypes.string
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ getFile }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicTransfersPage);
