import * as types from '../constants/actionTypes';
import initialState from '../constants/initialState';

export default function facilitiesReducer (state = initialState.facilities, action){
  const {facilities} = action;
  switch (action.type) {
  case types.LOGIN_SUCCESS:
  case types.GET_FACILITIES_SUCCESS:
    return [...facilities];
  case types.GET_FACILITIES_FAILED:
    return state;
  default:
    return state;
  }
}
