import { change, getFormValues, formValueSelector } from 'redux-form';
import get from 'lodash.get';
import * as types from '../../constants/actionTypes';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import * as colombiaActions from '../../actions/integrations/colombia';
import { CREATE_PLANT_FORM, MODIFY_PLANT_FORM, HARVEST_PLANTS_FORM } from '../../constants/forms';
import { addMessage } from '../../actions/systemActions';
import { getSearchData, postItem, getDataByPost } from '../../actions/apiActions';
import { setData } from '../../actions/dataActions';
import { setItem } from '../../actions/itemActions';
import { getPhenotypesByStrain, getPhenotypeById } from '../../selectors/strainsSelectors';
import { isFormChangeOnField } from './utils';
import { convertFromBase } from '../../util/uomHelpers';
import { getInventoryItems } from '../../selectors/processingSelectors';
import { getIntegrationState } from '../../selectors/integration/integrationSelectors';
import { isAddPlantsToExistingBatchFeatureEnabled } from '../../selectors/featureToggles';
import {getUseEntityLocksForItems} from '../../selectors/coreSettingsSelectors';

const plantForm = (store) => (next) => (action) => {
  const result = next(action);
  const { meta, type, payload } = action;
  const state = store.getState();
  const { isWaLeaf, isColombia } = getIntegrationState(state);

  /*** CREATE PLANT ****/
  if (meta && meta.form === CREATE_PLANT_FORM) {
    if (type === types.REDUX_FORM_CHANGE) {
      const values = getFormValues(CREATE_PLANT_FORM)(state);
      const addPlantsToExistingBatchFeatureEnabled = isAddPlantsToExistingBatchFeatureEnabled(state)();

      if (meta.field === 'strain_id' && payload) {
        const phenotypes = getPhenotypesByStrain(store.getState(), { strain_id: payload });
        store.dispatch(setData(phenotypes, dataNames.phenotypes));
        if (!isWaLeaf) {
          const params = {
            query: 'matchall',
            filter: `strain_id:${payload} AND is_harvested:0 AND is_destroyed:0 AND is_packaged:0 `,
            fields: ['id', 'plant_id', 'matriarch_plant_name', 'state_integration_tracking_id', 'code', 'is_mother'],
            sort: 'plant_id asc',
            size: 100000
          };
          store.dispatch(getSearchData('/api/search/plants', dataNames.motherPlants, null, params));
        }

        if (isColombia) {
          store.dispatch(colombiaActions.fetchModalitiesByStrainId(payload, { with_relations: ['cupo'] }));
        }

        if (addPlantsToExistingBatchFeatureEnabled) {
          store.dispatch(
            getSearchData(
              isWaLeaf ? '/api/leaf/batches' : '/api/batches',
              dataNames.batches,
              null,
              {
                strain_id: values.strain_id,
                active: 1
              },
              (result) => {
                if (!result.length) {
                  store.dispatch(change(meta.form, 'isEnabledChooseBatches', false));
                }
              }
            )
          );
        }
      } else if (addPlantsToExistingBatchFeatureEnabled && meta.field === 'strain_id' && !values.strain_id) {
        store.dispatch(change(meta.form, 'isEnabledChooseBatches', false));
      }

      if (meta.field === 'phenotype_id' && payload) {
        store.dispatch(setItem(itemNames.phenotype, getPhenotypeById(store.getState(), { phenotype_id: payload })));
      }

      if (addPlantsToExistingBatchFeatureEnabled && meta.field === 'isEnabledChooseBatches') {
        if (!values.strain_id || !values.isEnabledChooseBatches) {
          store.dispatch(change(meta.form, 'batch_name', ''));
        }
      }
    }
  }
  if (
    isFormChangeOnField(action, [CREATE_PLANT_FORM], 'strain_id') ||
    isFormChangeOnField(action, [CREATE_PLANT_FORM], 'starting_type')
  ) {
    store.dispatch(change(meta.form, 'source_item_id', ''));
  }
  if (isFormChangeOnField(action, [CREATE_PLANT_FORM], 'source_item_id')) {
    const state = store.getState();
    const items = getInventoryItems(state);
    const item = items.find((item) => item.id === payload);
    const qtyReserved = item && item.qty_base_reserved ? convertFromBase(item.qty_base_reserved, item.uom_display_reserved) : 0;
    const qty = item ? item.qty - qtyReserved : '';
    store.dispatch(change(meta.form, 'source_item_qty', qty));
  }

  /*** MODIFY PLANT ****/
  const setPlantRangeDefaults = (formValues) => {
    const plants = store.getState()[dataNames.plants];
    formValues.selected_batch_name.forEach((batch) => {
      const batchPlants = plants.filter((plant) => plant.batch_name === batch.batch_name).sort((a, b) => a.id > b.id);
      const fieldNames = {
        start: `plant_range_start${batch.batch_name}`,
        end: `plant_range_end${batch.batch_name}`
      };
      if (!formValues[fieldNames.start]) {
        const plantId = Array.isArray(batchPlants) && batchPlants.length ? batchPlants[0].plant_id : '';
        store.dispatch(change(MODIFY_PLANT_FORM, fieldNames.start, plantId));
      }
      if (!formValues[fieldNames.end]) {
        const plantId =
          Array.isArray(batchPlants) && batchPlants.length ? batchPlants[batchPlants.length - 1].plant_id : '';
        store.dispatch(change(MODIFY_PLANT_FORM, fieldNames.end, plantId));
      }
    });
  };

  if (meta && meta.form === MODIFY_PLANT_FORM) {
    if (type === types.REDUX_FORM_CHANGE) {
      if (meta.field === 'selected_batch_name') {
        const formValues = getFormValues(MODIFY_PLANT_FORM)(store.getState());
        setPlantRangeDefaults(formValues);
      }
    }
  }

  if (isFormChangeOnField(action, [MODIFY_PLANT_FORM, HARVEST_PLANTS_FORM], 'num_plants')) {
    const selector = formValueSelector(meta.form);
    const state = store.getState();
    const maxPlants = parseInt(selector(state, 'max_plants'));
    const minPlants = maxPlants !== 0 ? 1 : 0;
    const numPlants = parseInt(payload);
    let calculateTotalPlants = true;

    if (numPlants > maxPlants) {
      store.dispatch(change(meta.form, 'num_plants', maxPlants));
      calculateTotalPlants = false;
    }

    if (numPlants < minPlants) {
      store.dispatch(change(meta.form, 'num_plants', minPlants));
      calculateTotalPlants = false;
    }

    if (calculateTotalPlants && meta.form === HARVEST_PLANTS_FORM) {
      const arr = get(state, 'selectedPlants');
      const selectedPlants = (Array.isArray(arr) ? arr : []).filter((plant, index) => {
        return index < numPlants;
      });
      const firstPlant = selectedPlants.length > 0 ? selectedPlants[0] : false;
      const multiplier = firstPlant ? get(firstPlant, 'qty', 1) : 1;
      const totalPlants = selectedPlants.length * multiplier;
      store.dispatch(change(meta.form, 'total_plants', totalPlants));
    }
  }

  if (action.name === 'plants' && type === 'GET_DATA_SUCCESS') {
    // Plants sometimes not available until after above runs so this traps that case
    const formValues = getFormValues(MODIFY_PLANT_FORM)(store.getState());
    if (formValues && Array.isArray(formValues.selected_batch_name) && formValues.selected_batch_name.length) {
      setPlantRangeDefaults(formValues);
    }
  }

  if (action.type === types.PLANT_PHASE_LIMIT_EXCEEDED) {
    store.dispatch(addMessage('warning', action.payload.message));
  }

  // Make an api request to generate a unique plant batch name on Plant Modify Page
  if (
    isFormChangeOnField(action, [MODIFY_PLANT_FORM], 'strain_id') ||
    isFormChangeOnField(action, [MODIFY_PLANT_FORM], 'modification_date') ||
    isFormChangeOnField(action, [MODIFY_PLANT_FORM], 'location_id')
  ) {
    const selector = formValueSelector(meta.form);
    const modificationDate = selector(state, 'modification_date') || '';
    let strainId = selector(state, 'strain_id') || '';

    /**
     * generate_batch_name requires a strain_id, but it's possible that the plant batch is being split with only a
     * location change. In this case we'll just grab the id of the first selected plant.
     * This doesn't affect anything else besides being able to generate the batch_name
     */
    if (!strainId) {
      const formValues = getFormValues(MODIFY_PLANT_FORM)(store.getState());
      if (formValues && formValues.selectedPlants.length) {
        strainId = get(formValues, 'selectedPlants.0.strain_id', '');
      }
    }

    if (modificationDate && strainId) {
      store.dispatch(postItem('/api/batches/generate_batch_name', {
        strain_id: strainId,
        planting_date: modificationDate.format(),
        force_new: true,
      })).then((response) => {
        store.dispatch(change(meta.form, 'batch_name', response.batch_name));
      });
    }
  }

  /*** DEFERRED PLANT CREATE/MODIFY COMPLETE ***/
  if (action.type === types.DEFERRED_PLANT_ACTION_COMPLETE) {
    const deferredPlantAction = Object.assign(
      {},
      store.getState()[itemNames.deferredPlantAction],
      { complete: true },
      action.payload
    );
    store.dispatch(setItem(deferredPlantAction, itemNames.deferredPlantAction));
  }

  if (action.type === types.LOAD_PLANT_SOURCE_INVENTORY_ITEMS) {
    const {itemMasters} = action;

    if (itemMasters) {
      const params = { //eslint-disable-line
        ids: itemMasters.map(itemMaster => itemMaster.id),
      };

      if (getUseEntityLocksForItems(store.getState())) {
        params.is_locked = 0;
      }

      const request = getDataByPost('/api/items/by_item_master_ids', params, dataNames.inventoryItems, {
        failed: 'products.get.failed'
      });

      store.dispatch(request);
    }
  }

  return result;
};

export default plantForm;
