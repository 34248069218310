import {change} from 'redux-form';
import {COMPLETE_EACH_PACKAGES_FORM} from '../../constants/forms';
import {isFormChangeOnField} from './utils';
import {getFormPackage} from '../../selectors/forms/completeEachPackagesFormSelectors';
import {getItemMasterPackageId} from '../../actions/itemMasterActions';


const completeSeedPackages = store => next => action => {
  const result = next(action);
  const {payload, meta} = action;

  if (isFormChangeOnField(action, [COMPLETE_EACH_PACKAGES_FORM], 'qty', true) && payload) {
    const regex = /packages\[(\d+)\]\.qty/;
    const match = regex.exec(action.meta.field);
    const packageIndex = match && match[1];
    const state = store.getState();
    const pkg = getFormPackage(state, packageIndex);
    const pkgCodePath = `packages[${packageIndex}].package_code`;
    if (pkg && !pkg.package_code) {
      store.dispatch(change(meta.form, pkgCodePath, ' '));
      store.dispatch(getItemMasterPackageId(pkg.item_master_id, packageIndex))
        .then(({packageId}) => {
          store.dispatch(change(meta.form, pkgCodePath, packageId));
        });
    }
  }

  return result;
};

export default completeSeedPackages;
