import React from 'react';
import PropTypes from 'prop-types';

import SalesSectionPreview from './SalesSectionPreview';
import CommonSectionPreview from './CommonSectionPreview';

const PreviewForm = (props) => {
  const {formData, handleEdit} = props;

  return formData.map((section, index) => (
    section.type === 'sales'
      ? <SalesSectionPreview key={index} handleEdit={handleEdit} sectionData={section}/>
      : <CommonSectionPreview key={index} handleEdit={handleEdit} sectionData={section}/>
  ));
};

PreviewForm.propTypes = {
  formData: PropTypes.array.isRequired,
  handleEdit: PropTypes.func.isRequired
};

export default PreviewForm;