import {I18n} from 'react-redux-i18n';
import {set} from 'lodash';
import get from 'lodash.get';
import find from 'lodash.find';
import foreach from 'lodash.foreach';
import {requiredFieldValidation} from '../../common/form/redux-form/validations';


const validate = (values, {totals, integrationState, ...ownProps}) => {
  const errors = {packages: []};
  const {isPaLeaf, isBiotrack, isMetrc} = integrationState;
  if (values.packages && values.packages.length) {
    const {remainingWeight, remainingOtherMaterialWeight} = totals;
    if (remainingWeight < 0) {
      errors.waste = I18n.t('harvestPackages.form.remainingWeightNegative');
    }
    if (remainingOtherMaterialWeight < 0) {
      errors.other_material_waste = I18n.t('harvestPackages.form.remainingWeightNegative');
    }

    if (isMetrc && values.waste && values.waste > 0) {
      errors.waste_type = requiredFieldValidation(values.waste_type, 'Harvest Waste Type');
    }

    values.packages.map((pkg, index) => {
      const packageErrors = {};
      packageErrors.item_master_id = !pkg.is_test_package && requiredFieldValidation(pkg.item_master_id, 'harvestPackages.form.product');
      packageErrors.inventory_location_id = requiredFieldValidation(pkg.inventory_location_id, 'harvestPackages.form.location');
      packageErrors.state_integration_tracking_id = requiredFieldValidation(pkg.state_integration_tracking_id, 'common.form.trackingId');
      packageErrors.weight_of_product = requiredFieldValidation(pkg.weight_of_product, 'harvestPackages.form.lotWeight');
      packageErrors.gross_weight = requiredFieldValidation(pkg.gross_weight, 'harvestPackages.form.grossWeight');
      packageErrors.material_type = requiredFieldValidation(pkg.material_type, 'harvestPackages.form.leafWa.material_type');
      packageErrors.integration_type = requiredFieldValidation(pkg.integration_type, 'harvestPackages.form.leafWa.lotType');
      if (pkg.weight_of_product && !parseFloat(pkg.weight_of_product)) {
        packageErrors.weight_of_product = I18n.t('harvestPackages.form.enterNetWeight');
      }
      else if (pkg.gross_weight && !parseFloat(pkg.gross_weight)) {
        packageErrors.gross_weight = I18n.t('harvestPackages.form.enterGrossWeight');
      }
      else if (pkg.weight_of_product && pkg.gross_weight && parseFloat(pkg.weight_of_product) > parseFloat(pkg.gross_weight)) {
        packageErrors.weight_of_product = I18n.t('harvestPackages.form.grossGreaterThanNet');
      }

      if (pkg.integration_type === 'other_material' && remainingOtherMaterialWeight < 0) {
        packageErrors.weight_of_product = I18n.t('harvestPackages.form.remainingWeightNegative');
      } else if (pkg.integration_type !== 'other_material' && remainingWeight < 0) {
        packageErrors.weight_of_product = I18n.t('harvestPackages.form.remainingWeightNegative');
      }
      if (isPaLeaf) {
        packageErrors.created_by_user_id = requiredFieldValidation(pkg.created_by_user_id, 'harvestPackages.form.leafPa.createdUser');
      }

      errors.packages[index] = packageErrors;
    });

    isBiotrack && validateFlowerLotEnough(values.packages, errors, ownProps.harvestTypeOptions);
  }

  return errors;
};


/**
 * Function which is used by Biotrack only
 * @param packages
 * @param errors
 * @param harvestTypes
 */
const validateFlowerLotEnough = (packages, errors, harvestTypes) => {
  const flowerLots = packages.filter(p => p.integration_type === find(harvestTypes, {code: 'flower_lot'}).value).length;
  const otherMaterial = packages.filter(p => p.integration_type === find(harvestTypes, {code: 'other_material_lot'}).value).length;
  const showError = otherMaterial - flowerLots;

  if (showError > 0) {
    for(let i = 0; i < showError; i++) {
      addMessageForPackageType(packages, errors, harvestTypes);
    }
  }
};

/**
 * Adding a message for specific package
 * @param errors
 * @param position
 * @param key
 * @param message
 * @returns {Object}
 */
const addMessage = (errors, position, key, message) => {
  return set(errors, `packages[${position}][${key}]`, message);
};

/**
 * Adding a message for Package Type field
 * @param errors
 * @param packages
 * @param harvestTypes
 * @returns {Object}
 */
const addMessageForPackageType = (packages, errors, harvestTypes) => {
  let index = 0;
  foreach(packages, ({ integration_type }, key) => {
    if (integration_type === find(harvestTypes, {code: 'other_material_lot'}).value && !get(errors, `packages[${key}].integration_type`)) {
      index = key;
    }
  });

  return addMessage(errors, index, 'integration_type', I18n.t('harvestPackages.form.otherMaterialPackagesCountExceedsFlowerPackages'));
};

export default validate;
