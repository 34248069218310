/* eslint-disable import/no-named-as-default*/
import {connect} from 'react-redux';
import {reduxForm, formValueSelector, getFormSyncErrors} from 'redux-form';
import CreateLocationForm from '../common/locations/create/CreateLocationForm';
import {SETUP_STORAGE_LOCATIONS_FORM} from '../../constants/forms';
import validate from './validate';
import {isWaLeaf} from '../../selectors/integrationSelectors';

const form = SETUP_STORAGE_LOCATIONS_FORM;

const CreateStorageLocationFormWrapper = reduxForm({
  form,
  enableReinitialize: true,
  validate
})(CreateLocationForm);

const selector = formValueSelector(form);

function mapStateToProps(state, props) {
  const defaults = selector(state, 'defaults') || props.initialValues.defaults;
  const locations = selector(state, 'child_locations') || props.initialValues.child_locations;
  return {
    printLabel: props.printLabel,
    defaults,
    locations,
    type: 'storage',
    showSquareFootage: false,
    showBuildingType: false,
    formErrors: getFormSyncErrors(SETUP_STORAGE_LOCATIONS_FORM)(state),
    isWaLeaf: isWaLeaf(state)
  };
}

export default connect(mapStateToProps)(CreateStorageLocationFormWrapper);
