import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {Field} from 'redux-form';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import CheckBoxInput from '../../common/form/CheckBoxInput';
import ReactSelectInput from '../../common/form/ReactSelectInput';

const yesNo = [
  {text: I18n.t('cultivation.complianceSettings.yes'), value: 1},
  {text: I18n.t('cultivation.complianceSettings.no'), value: 0}
];

const FacilitySetting = ({fields, isDisabled}) => (
  <div className='internal-transfer-settings-form'>
    <Row>
      <Col md={{span:2, offset:3}}>
        {I18n.t('internalTransfersSettings.requirePayment')}<br/>
        <span className='text-muted'>{I18n.t('internalTransfersSettings.helpTextPayment')}</span>
      </Col>
      <Col md={2}>
        {I18n.t('internalTransfersSettings.requireInventoryReceipt')}<br/>
        <span className='text-muted'>{I18n.t('internalTransfersSettings.helpTextIR')}</span>
      </Col>
    </Row>
    {fields.map((name, index) => {
      const currentField = fields.get(index);
      return (<Row key={index}>
          <Col md={3}>
            <Field name={`${name}.checked`} component={CheckBoxInput} props={{
              groupClassName: 'inline-checkbox',
              label: currentField.name,
              value: currentField.id,
              disabled: Boolean(currentField.restricted),
            }}/>
          </Col>
          <Col md={2}>
            <Field name={`${name}.require_payment`} component={ReactSelectInput} props={{
              options: yesNo,
              disabled: isDisabled(name)
            }}/>
          </Col>
          <Col md={2}>
            <Field name={`${name}.require_ir`} component={ReactSelectInput} props={{
              options: yesNo,
              disabled: isDisabled(name)
            }}/>
          </Col>
        </Row>
      );
    })}
  </div>
);

FacilitySetting.propTypes = {
  fields: PropTypes.object.isRequired,
  isDisabled: PropTypes.func.isRequired
};

export default FacilitySetting;
