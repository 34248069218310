/* eslint-disable import/namespace */
import {createSelector} from 'reselect';
import * as dataNames from '../constants/dataNames';
import * as itemNames from '../constants/itemNames';

const getDataName = (_, props) => (props.dataName) ? props.dataName : false; //eslint-disable-line
const getItemName = (_, props) => (props.itemName) ? props.itemName : false; //eslint-disable-line

const getCollectionData = (state, props) => props.dataName ? state[props.dataName] : [];
const getItemData = (state, props) => props.itemName ? state[props.itemName] : {};

const getCollectionsData = (state, props) => {
  if(!props.dataNames) return {};
  const names = props.dataNames.split(',');
  return names.reduce((acc, name) => {
    acc[name] = state[dataNames[name]] ? state[dataNames[name]] : [];
    return acc;
  }, {});
};

const getItemsData = (state, props) => {
  if(!props.itemNames) return {};
  const names = props.itemNames.split(',');
  return names.reduce((acc, name) => {
    //acc[name] = state[itemNames[name]] ? state[itemNames[namme]] : {};
    acc[name] = state[itemNames[name]] || {}; // is this valid?
    return acc;
  }, {});
};

export const getCollection = createSelector([getCollectionData], (data) => {
  return data;
});

export const getItem = createSelector([getItemData], (item) => {
  return item;
});

export const getCollections = createSelector([getCollectionsData], (data) => {
  return data;
});

export const getItems = createSelector([getItemsData], (data) => {
  return data;
});
