import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {ButtonToolbar} from 'react-bootstrap';
import PermissionButton from '../common/PermissionButton';
import {getItem, getDataByPost} from '../../actions/apiActions';
import * as p from '../../constants/permissions';
import {isWaLeafIntegrator} from '../../selectors/integration/leafSelectors';

class MedicallyCompliantStatus extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.state = {isFetching: false, showResults: false};
    this.toggleResults = this.toggleResults.bind(this);
    this.fetchMedicallyCompliantStatus = this.fetchMedicallyCompliantStatus.bind(this);
  }

  fetchMedicallyCompliantStatus() {
    this.setState({isFetching: true});
    const {actions: {getItem}, item} = this.props;
    return getItem(`/api/leaf/import_medically_compliant_status/item/${item.id}`)
      .then(() => this.setState({isFetching: false}))
      .catch(() => this.setState({isFetching: false}));
  }

  toggleResults() {
    this.setState({showResults: !this.state.showResults});
  }

  render() {
    const {isWaLeaf, item} = this.props;
    const {isFetching} = this.state;
    const seekingMedicalDesignation = (
      <div className='flex-col flex-25 action-col'
           title={I18n.t('harvestPackages.form.leafWa.seekingMedicalDesignation')}>
        <label>{I18n.t('harvestPackages.form.leafWa.seekingMed')}</label><br/>
        <span>{item.medically_compliant ? I18n.t('general.yes') : I18n.t('general.no')}</span>
      </div>
    );
    const medicallyCompliantStatus = item.medically_compliant_status;
    if (isFetching) {
      return (
        <div className='layout-row flex'>
          {seekingMedicalDesignation}
          <div className='flex-col flex-40 action-col'>
            <label>{I18n.t('cultivation.finishedProduct.medicallyCompliantStatus')}</label><br/>
            {I18n.t('cultivation.testResults.fetching')}
          </div>
        </div>
      );
    }
    return (
      <div className='layout-row flex-col'>
        {seekingMedicalDesignation}
        <div className='flex-col flex-40 action-col'>
          <label><span>{`${I18n.t('cultivation.finishedProduct.medicallyCompliantStatus')}: `} </span></label><br/>
          <span>{medicallyCompliantStatus ? I18n.t(`general.${medicallyCompliantStatus}`) : I18n.t('general.no')}</span>
          <ButtonToolbar>
            {isWaLeaf && item && item.external_identifier ?
              <PermissionButton
                permissions={[p.manage_testing]}
                props={{
                  onClick: this.fetchMedicallyCompliantStatus,
                  bsSize: 'sm',
                  variant: 'primary'
                }}
              >
                {I18n.t('cultivation.finishedProduct.importMedicallyCompliantStatus')}
              </PermissionButton>
              : null
            }
          </ButtonToolbar>
        </div>
      </div>
    );
  }
}

MedicallyCompliantStatus.propTypes = {
  item: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  isWaLeaf: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    isWaLeaf: isWaLeafIntegrator(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {getItem, getDataByPost};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicallyCompliantStatus);
