import React from 'react';
import PropTypes from 'prop-types';
import {DropdownButton, Dropdown} from 'react-bootstrap';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
//import * as ExcelJS from 'exceljs';
import Papa from 'papaparse';
import {saveAs} from 'file-saver';
import {error, info} from '../../../constants/messageTypes';
import * as itemNames from '../../../constants/itemNames';
import FileInput from '../../common/form/FileInput';
import {FILE_TYPES} from '../../../constants/fileUploads';
import WillRender from '../../common/concealers/WillRender';

const ExcelJS = require('exceljs/dist/exceljs');

// Read only fields shouldn't be imported. They are only included in the downloaded template if 'includeValues' is true.
// Wildcards are supported at beginning and/or end of the field name
const readOnlyFields = ['for_partner_facility_id', 'testing_id', 'package_code', 'product_name', 'completion_status', 'references*', 'test_type'];

// These categories have both a _percent and a _mg_g field
const double_field_categories = ['cannabinoid_potency', 'terpene_profile'];

class TestResultsTemplate extends React.PureComponent {
  constructor(props) {
    super(props);

    this.processTemplate = this.processTemplate.bind(this);
  }

  isReadOnlyField(key) {
    const match = readOnlyFields.find(readOnlyField => {
      const wildCardAtBeginning = readOnlyField.charAt(0) === '*';
      const wildCardAtEnd = readOnlyField.charAt(readOnlyField.length - 1) === '*';
      readOnlyField = readOnlyField.replace(/\*/g,'');
      if (wildCardAtBeginning && wildCardAtEnd) {
        return key.includes(readOnlyField);
      }
      if (wildCardAtBeginning) {
        return key.endsWith(readOnlyField);
      }
      if (wildCardAtEnd) {
        return key.startsWith(readOnlyField);
      }
      return key === readOnlyField;
    });
    return !!match;
  }

  downloadTemplate(format) {
    const labResultDimensions = this.props.labResultDimensions;
    const testResult = this.props.testResult;
    const fileName = testResult
      ? (get(testResult, 'references[0].product_name') + ' ' + get(testResult, 'references[0].package_code')).trim()
      : 'Lab results template';

    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Akerna - MJ Freeway';
    workbook.lastModifiedBy = 'Akerna - MJ Freeway';
    workbook.created = new Date();
    workbook.modified = workbook.created;
    const worksheet = workbook.addWorksheet(fileName);
    worksheet.columns = [
      { header: 'Category', key: 'category', width: 20 },
      { header: 'Name', key: 'name', width: 32 },
      { header: 'Key', key: 'key', width: 40 },
      { header: 'Value', key: 'value', width: 8 }
    ];
    worksheet.getRow(1).font = { bold: true };
    worksheet.autoFilter = 'A1:D1';
    // Add any general rows
    // Include these fields only if test result available
    if (testResult) {
      worksheet.addRow({category: 'General', name: 'Testing ID', key: 'testing_id', value: testResult && get(testResult, 'testing_id')});
      worksheet.addRow({category: 'General', name: 'Status', key: 'status', value: testResult && get(testResult, 'status')});
      worksheet.addRow({category: 'General', name: 'Partner', key: 'partner', value: testResult && get(testResult, 'partner_name', get(testResult, 'for_partner_name'))});  // The facility uses 'partner_name', the lab uses for_partner_name
      worksheet.addRow({category: 'General', name: 'Product Name', key: 'product_name', value: testResult && get(testResult, 'references[0].product_name')});
      worksheet.addRow({category: 'General', name: 'Package Code', key: 'package_code', value: testResult && get(testResult, 'references[0].package_code')});
    }
    worksheet.addRow({category: 'General', name: 'Testing Date', key: 'testing_date', value: testResult && get(testResult, 'testing_date')});
    worksheet.addRow({category: 'General', name: 'Test Type', key: 'test_type', value: testResult && get(testResult, 'test_type')});
    worksheet.addRow({category: 'General', name: 'Stability Testing Date', key: 'stability_testing_date', value: testResult && get(testResult, 'stability_testing_date')});
    // Loop through labResultDimensions
    const categories = Object.keys(labResultDimensions);
    categories.forEach((category, index) => {
      const categoryDimensions = labResultDimensions[category];
      categoryDimensions.forEach((dimension, index) => {
        // If this is a double field category create rows for both _percent and _mg_g
        if (double_field_categories.includes(category)) {
          worksheet.addRow({
            category: I18n.t(`'cultivation.testResults.categories.${category}`),
            name: get(dimension, 'display_name', '').trim() + ' (%)',
            key: get(dimension, 'key') + '_percent',
            value: testResult && get(testResult, get(dimension, 'key') + '_percent')
          });
          worksheet.addRow({
            category: I18n.t(`'cultivation.testResults.categories.${category}`),
            name: get(dimension, 'display_name', '').trim() + ' (mg/g)',
            key: get(dimension, 'key') + '_mg_g',
            value: testResult && get(testResult, get(dimension, 'key') + '_mg_g')
          });
        } else {
          worksheet.addRow({
            category: I18n.t(`'cultivation.testResults.categories.${category}`),
            name: get(dimension, 'display_name', '').trim(),
            key: get(dimension, 'key'),
            value: testResult && get(testResult, get(dimension, 'key'))
          });
        }
      });
    });
    if (format === 'excel') {
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/xlsx' });
        saveAs(blob, `${fileName.replace(/ /g,'_')}.xlsx`);
      });
    }
    if (format === 'csv') {
      workbook.csv.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'text/csv' });
        saveAs(blob, `${fileName.replace(/ /g,'_')}.csv`);
      });
    }
  }

  processTemplate(file) {
    const labResultDimensions = this.props.labResultDimensions;
    const workbook = new ExcelJS.Workbook();
    const importedKeys = [];

    const convertValue = (value, key, convert_to) => {
      const multiplier = convert_to === 'mg_g' ? 10 : 0.1;
      // Perform conversion with a precision of up to 6 decimals and strip trailing zeros or return empty string
      const convertedValue = value ? Number((value * multiplier).toFixed(6)) : '';
      this.props.change(`${key}_${convert_to}`, convertedValue);
    };

    const isDoubleFieldKey = (key) => {
      let isDoubleFieldKey = false;
      // Double field keys always end with '_percent' or '_mg_g' (only check if we have a value)
      if (key.endsWith('_percent') || key.endsWith('_mg_g')) {
        const categoriesDimensions = Object.values(labResultDimensions);
        categoriesDimensions.forEach((categoryDimensions, index) => {
          const suffix = key.endsWith('_percent') ? '_percent' : '_mg_g';
          const key_without_suffix = key.substring(0, key.lastIndexOf(suffix));
          // Get definition
          const definition = categoryDimensions.find((dimension) => dimension.key === key_without_suffix);
          if (definition) {
            isDoubleFieldKey = true;
          }
        });
      }
      return isDoubleFieldKey;
    };

    const processRow = (key, value = '') => {
      if (!this.isReadOnlyField(key)) {
        /*
         * Double Field values need to be treated a bit differently as their values should change depending on the other value
         * Only clear the value if it hasn't been set by the other value.
         *
         * Example 1
         * input:
         *   d8_thc_percent: 5
         *   d8_thc_mg_g: 60
         * result:
         *   d8_thc_percent: 5
         *   d8_thc_mg_g: 60
         * explanation:
         *  d8_thc_mg_g is written after d8_thc_percent and has a value so d8_thc_percent is overwrittern
         *
         * Example 2
         * input:
         *   d8_thc_percent: 5
         *   d8_thc_mg_g:
         * result:
         *   d8_thc_percent: 5
         *   d8_thc_mg_g: 50
         * explanation:
         *   d8_thc_mg_g is not cleared because it was populated when d8_thc_percent was loaded
         */
        if (isDoubleFieldKey(key)) {
          // Clear the field if no value and not imported as result of the companion field being loaded
          if (!value && !importedKeys.includes(key)) {
            this.props.change(key, value);
            return;
          }
          // If a value exists, change both (1) key field and (2) companion field
          if (value) {
            // 1. import key field
            importedKeys.push(key);
            this.props.change(key, value);
            // 2. convert and import companion field
            const suffix = key.endsWith('_percent') ? '_percent' : '_mg_g';
            const key_without_suffix = key.substring(0, key.lastIndexOf(suffix));
            const convert_to = key.endsWith('_percent') ? 'mg_g' : 'percent';
            const convert_to_key = key_without_suffix + '_' + convert_to;
            importedKeys.push(convert_to_key);
            convertValue(value, key_without_suffix, convert_to);
          }
          return;
        }
        // Not a double field value
        importedKeys.push(key);
        this.props.change(key, value);
      }
    };

    const processWorksheet = (worksheet) => {
      // Validate worksheet (Check if a key and value columns exist)
      const headerRow = worksheet.getRow(1);
      const keyColumn = Object.keys(headerRow.values).find(key => headerRow.values[key].toLowerCase().trim() === 'key');
      const valueColumn = Object.keys(headerRow.values).find(key => headerRow.values[key].toLowerCase().trim() === 'value');
      if(keyColumn === undefined || valueColumn === undefined) {
        this.props.addMessage(error, ['cultivation.testResults.table.templateUpload.error.missingKeyValueColumns']);
        return;
      }

      // Set headers
      worksheet.columns = headerRow.values.map((value) => {
        return { header: value, key: value.toLowerCase().trim() };
      });

      const keyValues = worksheet.getColumn('key').values;
      const valueValues = worksheet.getColumn('value').values;

      // Loop over rows. Skip first (header) row
      for (let i = 1; i < worksheet.actualRowCount; i++) {
        processRow(keyValues[i + 1], valueValues[i + 1]); // keyValues and valueValues start a with index 1 so add one to get the correct key/value
      }

      this.props.addMessage(info, ['cultivation.testResults.table.templateUpload.success']);
    };

    if (file.name.toLowerCase().endsWith('.xlsx')) {
      try {
        const buffer = file.arrayBuffer();
        workbook.xlsx.load(buffer)
          .then(() => {
            processWorksheet(workbook.getWorksheet(1));
          });
      }
      catch (e) {
        this.props.addMessage(error, ['cultivation.testResults.table.templateUpload.error.invalidFile']);
      }
    }

    if (file.name.toLowerCase().endsWith('.csv')) {
      try {
        // We have to use Papaparse to import CSV. ExcelJS doesn't offer a stream reader for CSV for some reason
        Papa.parse(file, {
          header: false,
          skipEmptyLines: true,
          complete: (results) => {
            const ws = workbook.addWorksheet(file.name.replace(/\.[^/.]+$/, '')); // filename minus extension
            ws.addRows(results.data);
            processWorksheet(ws);
          },
        });
      }
      catch (e) {
        this.props.addMessage(error, ['cultivation.testResults.table.templateUpload.error.invalidFile']);
      }
    }
  }

  render() {
    const {show, downloadOnly} = this.props;

    return (
      <WillRender ifTrue={show}>
        {/* Template download */}
        <label>{downloadOnly
          ? I18n.t('cultivation.testResults.table.resultsDownload.title')
          : I18n.t('cultivation.testResults.table.templateDownload.title')}
        </label><br/>
        <DropdownButton
          style={{marginBottom: '12px'}}
          variant='primary'
          type='button'
          title={downloadOnly
            ? I18n.t('cultivation.testResults.table.resultsDownload.action')
            : I18n.t('cultivation.testResults.table.templateDownload.action')}
        >
          <Dropdown.Item id={'Excel'} key={'excel'} eventKey={'excel'} onClick={() => this.downloadTemplate('excel')}>{I18n.t('cultivation.testResults.table.templateDownload.excel')}</Dropdown.Item>
          <Dropdown.Item id={'CSV'} key={'csv'} eventKey={'csv'} onClick={() => this.downloadTemplate('csv')}>{I18n.t('cultivation.testResults.table.templateDownload.csv')}</Dropdown.Item>
        </DropdownButton>

        {/* Template upload */}
        <WillRender ifTrue={!downloadOnly}>
          <Field
            name='lab_results_import'
            itemName={itemNames.labResultTemplate}
            component={FileInput}
            props={{
              ...FILE_TYPES['LAB_RESULTS_IMPORT_FILE'],
              skipupload: 1,
              label: I18n.t('cultivation.testResults.table.templateUpload.title'),
              btnContent: I18n.t('cultivation.testResults.table.templateUpload.action'),
              onChangeCb: (f) => {
                this.processTemplate(f);
              },
              btnProps: {
                className: 'btn btn-primary'
              }
            }}
          />
          <p>{I18n.t('cultivation.testResults.table.templateUpload.ext')}</p>
        </WillRender>
      </WillRender>
    );
  }
}

TestResultsTemplate.propTypes = {
  testResult: PropTypes.object,
  getFormValue: PropTypes.func,
  change: PropTypes.func,
  addMessage: PropTypes.func,
  show: PropTypes.bool
};

export default TestResultsTemplate;
