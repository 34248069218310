import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Row, Table, Card} from 'react-bootstrap';

const HarvestBatchSplit = ({model}) => {
  return (
    <Card>
      <Row>
        <Col xs={6}>
          <h3>{I18n.t('batchHistory.model.harvestBatchSplitDetails')}</h3>
          <Table striped className='item-details'>
            <thead>
            <tr>
              <th>{I18n.t('itemTransactions.field')}</th>
              <th>{I18n.t('itemTransactions.value')}</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>{I18n.t('batchHistory.model.batchName')}</td>
              <td>{model.batch_name}</td>
            </tr>
            <tr>
              <td>{I18n.t('batchHistory.model.currentWeight')}</td>
              <td>{model.current_weight} {model.uom}</td>
            </tr>
            <tr>
              <td>{I18n.t('batchHistory.model.newBatchName')}</td>
              <td>
                {
                  model.data && model.data.new_batch && model.data.new_batch.batch_name
                    ? model.data.new_batch.batch_name
                    : ''
                }</td>
            </tr>
            <tr>
              <td>{I18n.t('batchHistory.model.newBatchCurrentWeight')}</td>
              <td>{
                model.data && model.data.new_batch && model.data.new_batch.current_weight
                  ? model.data.new_batch.current_weight
                  : ''
              } {model.uom}</td>
            </tr>
            </tbody>
          </Table>
          <div className='text-muted' style={{fontSize: 'smaller', marginTop: '12px'}}>
            {I18n.t('batchHistory.model.previousRecordOnBatchSplit')}
          </div>
        </Col>
      </Row>
    </Card>
  );
};

HarvestBatchSplit.propTypes = {
  model: PropTypes.object.isRequired,
};

export default HarvestBatchSplit;
