import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack} from 'react-router-redux';
import {reset, formValueSelector} from 'redux-form';
import sortby from 'lodash.sortby';
import uniqby from 'lodash.uniqby';

import * as apiActions from '../../../../actions/apiActions';
import * as dataNames from '../../../../constants/dataNames';
import {
  getCreatePricingGroupInitialValues,
  getCategoriesForPricingGroups
} from '../../../../selectors/pricingGroupsSelectors';
import getPayloadForPricingGroups from '../common/getPayloadForPricingGroups';
import {getPricingWeights} from '../../../../selectors/pricingWeightsSelectors';
import FormWrapper from '../../../common/form/FormWrapper';
import CreatePricingGroupFormWrapper from './CreatePricingGroupFormWrapper';
import {getUomByCategoryId} from '../../../../selectors/uomsSelectors';
import {CREATE_PRICING_GROUP_FORM} from '../../../../constants/forms';
import {getIntegrationState} from '../../../../selectors/integration/integrationSelectors';

export class CreatePricingGroupPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      pricingClasses: []
    };
    this.onPricingWeightAdded = this.onPricingWeightAdded.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.redirect = this.redirect.bind(this);
    this.onCategoryChange = this.onCategoryChange.bind(this);
  }

  componentWillMount() {
    this.props.actions.getUnpaginatedData('/api/pricing_weights', dataNames.pricingWeights, {failed: 'cultivation.pricingWeights.get.failed'});
    this.props.actions.getUnpaginatedData('/api/categories', dataNames.categories, {failed: 'categories.get.failed'});
    this.props.actions.getUnpaginatedData('/api/pricing_classes', null, {failed: 'retail.pricingClasses.get.failed'}, null, (data) => {
      // Sort and filter
      const sorted = sortby(data, (value) => value.name.toLowerCase()).filter(pricing_class => pricing_class.active === 1);
      this.setState({pricingClasses: sorted});
    });
    this.props.actions.getUnpaginatedData('/api/taxes', dataNames.taxes, {failed: 'taxes.get.failed'}, {detailed: 1});
    this.props.actions.getUnpaginatedData('/api/tax_profiles', dataNames.taxProfiles, {failed: 'taxes.get.failed'}, {
      detailed: 1,
      active: 1
    });
    this.props.actions.getUnpaginatedData('/api/uoms/converted', dataNames.uoms);
  }

  onPricingWeightAdded(pricingWeight, callback) {
    return this.props.actions.postData(
      '/api/pricing_weights',
      pricingWeight,
      dataNames.pricingWeights,
      {success: 'cultivation.pricingWeights.post.success', failed: 'cultivation.pricingWeights.post.failed'},
      {},
      callback
    );
  }

  onSubmit(formValues) {
    const payload = getPayloadForPricingGroups(formValues);
    this.props.actions.postItem(
      `/api/pricing_groups`,
      payload,
      null,
      {success: 'retail.pricingGroup.create.success', failed: 'retail.pricingGroup.create.failed'},
      {},
      () => {
        if (formValues.afterSubmit === 'redirect') {
          this.redirect();
        } else {
          this.props.actions.reset('createPricingGroupForm');
        }
      }
    );
  }

  redirect() {
    this.props.actions.goBack();
  }

  onCategoryChange(event, id) {
    const {taxProfiles} = this.props;

    if (taxProfiles.length === 0) {
      return;
    }

    if (!id) {
      this.setState({taxProfiles: taxProfiles});
      return;
    }

    const data = [];

    taxProfiles.forEach(taxProfile => {
      taxProfile.taxes.forEach(taxMaster => {
        taxMaster.tax.categories.forEach(category => {
          if (category.item_category_id === id) {
            data.push(taxProfile);
          }
        });
      });
    });

    this.setState({
      taxProfiles: uniqby(data, 'tax_profile_name')
    });
  }

  render() {
    const {categories, pricingWeights, initialValues, taxProfiles, uoms, integrationState} = this.props;
    const {pricingClasses} = this.state;
    const filteredTaxProfiles = this.state.taxProfiles ? this.state.taxProfiles : taxProfiles;

    return (
      <FormWrapper className='coupon-setup' title='retail.pricingGroup.form.createTitle' goBack={this.redirect}>
        <CreatePricingGroupFormWrapper
          uoms={uoms}
          onSubmit={this.onSubmit}
          onPricingWeightAdded={this.onPricingWeightAdded}
          categories={categories}
          taxProfiles={filteredTaxProfiles}
          integrationState={integrationState}
          onCategoryChange={this.onCategoryChange}
          pricingClasses={pricingClasses}
          pricingWeights={pricingWeights}
          initialValues={initialValues}
        />
      </FormWrapper>
    );
  }
}

CreatePricingGroupPage.propTypes = {
  actions: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
  }).isRequired,
  uoms: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  taxProfiles: PropTypes.array.isRequired,
  pricingClasses: PropTypes.array.isRequired,
  pricingWeights: PropTypes.array.isRequired,
  initialValues: PropTypes.object,
};

function mapStateToProps(state) {
  const selector = formValueSelector(CREATE_PRICING_GROUP_FORM);
  const category_id = selector(state, 'category_id');

  return {
    uoms: getUomByCategoryId(state, {category_id}),
    categories: getCategoriesForPricingGroups(state),
    taxProfiles: state[dataNames.taxProfiles],
    initialValues: getCreatePricingGroupInitialValues(state),
    pricingClasses: state[dataNames.pricingClasses],
    pricingWeights: getPricingWeights(state),
    integrationState: getIntegrationState(state)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {goBack, reset});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePricingGroupPage);
