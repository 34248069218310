import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import TextInput from '../../common/form/TextInput';
import {evaluatePredicate} from '../../../util/callbackHelpers';
import {isCaregiversHaveMedicalId} from './validate';
import MarijuanaIdFieldWithMask from '../common/MarijuanaIdFieldWithMask';

const MarijuanaIdField = ({conditionalProps, label, isMetrcMd, type, isPaLeaf, caregiverInfo, isOhMetrc, isUtah, fieldName, isFieldRequired, paOraclePatientSyncFeature}) => (
  isOhMetrc
    ? <MarijuanaIdFieldWithMask
      fieldName={`${fieldName}.identification_number`}
      label={label}
      conditionalProps={conditionalProps}
      isRequired={true}
    />
    : <Field
      name={`${fieldName}.identification_number`}
      component={TextInput}
      {...conditionalProps}
      props={{
        label: label,
        placeholder: isMetrcMd && type === 'med' ? 'xxxx-xxxx-xxxx-xxxx' : '',
        isRequired: evaluatePredicate(isFieldRequired, type) && !(isPaLeaf && isCaregiversHaveMedicalId(caregiverInfo)),
        readOnly: type === 'med' && ((paOraclePatientSyncFeature && isPaLeaf) || isUtah),
      }}/>
);

MarijuanaIdField.propTypes = {
  conditionalProps: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  isMetrcMd: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  isPaLeaf: PropTypes.bool.isRequired,
  caregiverInfo: PropTypes.array,
  isOhMetrc: PropTypes.bool.isRequired,
  fieldName: PropTypes.string.isRequired,
  isFieldRequired: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
  ]),
};

export default MarijuanaIdField;
