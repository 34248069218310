import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Button, ButtonGroup, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'react-bootstrap';
import SelectControl from '../common/form/SelectControl';
import BootstrapFormGroup from '../common/form/BootstrapFormGroup';
import * as p from '../../constants/permissions';
import PermissionButton from '../common/PermissionButton';

const AssignDriver = ({show, close, taxRate, setTaxRate, fillOrder}) => {

  const handleAddressChange = (event) => {
    setTaxRate(parseFloat(event.target.value));
  };

  return (
    <Modal show={show} id='assign-driver' onEnter={() => window.scrollTo(0, 0)}>
        <ModalHeader closeButton onHide={close}>
          <h3>{I18n.t('cart.assignDriver.assignDriver')}</h3>
        </ModalHeader>

        <ModalBody>
          <Row>
            <Col xs={7}>
              <BootstrapFormGroup
                id='address'
                label={I18n.t('cart.assignDriver.selectPatientAddress')}
                Control={SelectControl}
                controlProps={{
                  options: [
                    {value: .05,text:''},
                    {value: .1,text:'1234 W. 32nd Ave. Sacremento, CA 95824'},
                    {value: .15,text:'1111 Pinebluff Ave. Suisin City, CA 94534'},
                    {value: .2,text:'9999 Beachfront Dr. Moon Bay, CA 94019'}
                  ],
                  value: taxRate,
                  onChange: handleAddressChange
                }}/>
            </Col>
          </Row>
          <hr/>
          <Row>
            <Col xs={7}>
              <BootstrapFormGroup
                id='driver'
                label={I18n.t('cart.assignDriver.selectDriver')}
                Control={SelectControl}
                controlProps={{
                  options: [
                    {value:'',text:''},
                    {value:1,text:'Kyle Busch'},
                    {value:2,text:'Dale Earnhardt Jr.'},
                    {value:3,text:'Jeff Gordon'}
                  ]
                }}/>
            </Col>
          </Row>
          <Row>
            <Col xs={7} offset={5}>
              <ButtonGroup className='print-buttons float-right'>
                <PermissionButton permissions={[p.print_labels]}
                  props={{variant: 'info'}}>
                  {I18n.t('cart.assignDriver.printReceipt')}
                </PermissionButton>
                <PermissionButton permissions={[p.print_labels]}
                  props={{variant: 'info'}}>
                  {I18n.t('cart.assignDriver.printManifest')}
                </PermissionButton>
              </ButtonGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button variant='primary' onClick={fillOrder}>Save changes</Button>
        </ModalFooter>

    </Modal>
  );
};

AssignDriver.propTypes = {
  show: PropTypes.bool,
  close: PropTypes.func.isRequired,
  taxRate: PropTypes.number.isRequired,
  setTaxRate: PropTypes.func.isRequired,
  fillOrder: PropTypes.func.isRequired
};


export default AssignDriver;
