import PropTypes from 'prop-types';
import {reduxForm} from  'redux-form';
import CureApiProductFilterForm from './CureApiProductFilterForm';


const initialValues = {
  order_type_id: null,
  form_ids: [],
};

const CureApiProductFilterReduxForm = reduxForm({initialValues})(CureApiProductFilterForm);

CureApiProductFilterReduxForm.propTypes = {
  form: PropTypes.string.isRequired,
  orderTypeOptions: PropTypes.array.isRequired,
  formOptions: PropTypes.array.isRequired,
};

export default CureApiProductFilterReduxForm;
