import {reduxForm} from 'redux-form';
import {connect} from 'react-redux';

import {INTERNAL_TRANSFERS_SETTINGS_FORM} from '../../../constants/forms';
import InternalTransfersSettingsForm from './InternalTrnasfersSettingsForm';
import validate from './validate';
import {isDisabled} from '../../../selectors/forms/internalTransfersSettingsFormSelector';

const InternalTransfersSettingsReduxForm = reduxForm({
  form: INTERNAL_TRANSFERS_SETTINGS_FORM,
  validate
})(InternalTransfersSettingsForm);

function mapStateToProps(state) {
  return {isDisabled: key => isDisabled(state, key)};
}

const InternalTransfersSettingsFormWrapper = connect(mapStateToProps)(InternalTransfersSettingsReduxForm);

export default InternalTransfersSettingsFormWrapper;
