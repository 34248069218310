export const assemblies = 'assemblies';
export const adjustmentReasons = 'adjustmentReasons';
export const autoSuggestions = 'autoSuggestions';
export const brands = 'brands';
export const batches = 'batches';
export const costings = 'costings';
export const customers = 'customers';
export const caregivers = 'caregivers';
export const consumers = 'consumers';
export const courierPartners = 'courierPartners';
export const activeConsumers = 'activeConsumers';
export const clinics = 'clinics';
export const feedingSchedules = 'feedingSchedules';
export const appliedFeedingSchedules = 'appliedFeedingSchedules';
export const locations = 'locations';
export const partnerLocations = 'partnerLocations';
export const partnerFacilityPrepacks = 'partnerFacilityPrepacks';
export const inventoryLocations = 'inventoryLocations';
export const customerGroups = 'customerGroups';
export const customerOrders = 'customerOrders';
export const customerFavorites = 'customerFavorites';
export const customerHistory = 'customerHistory';
export const deliveryOrders = 'deliveryOrders';
export const courierDeliveryOrders = 'courierDeliveryOrders';
export const searchCustomer = 'searchCustomer';
export const referralSources = 'referralSources';
export const pkgs = 'packages';
export const plants = 'plants';
export const products = 'products';
export const productStatements = 'productStatements';
export const selectedProducts = 'selectedProducts';
export const childProducts = 'childProducts';
export const searchResults = 'searchResults';
export const transfers = 'transfers';
export const transfersActive = 'transfersActive';
export const integratedTransfers = 'integratedTransfers';
export const itemMasterMappings = 'itemMasterMappings';
export const internalOrders = 'internalOrders';
export const incomingTransfers = 'incomingTransfers';
export const platformIncomingTransfers = 'platformIncomingTransfers';
export const platformPurchaseOrders = 'platformPurchaseOrders';
export const updatedInternalTransfers = 'updatedInternalTransfers';
export const stages = 'stages';
export const globalStrains = 'globalStrains';
export const organizationStrains = 'organizationStrains';
export const facilityStrains = 'facilityStrains';
export const uoms = 'uoms';
export const waste = 'waste';
export const orders = 'orders';
export const orderProducts = 'orderProducts';
export const availableApps = 'availableApps';
export const harvests = 'harvests';
export const harvestBatch = 'harvestBatch';
export const modules = 'modules';
export const roles = 'roles';
export const roleTypes = 'roleTypes';
export const permissions = 'permissions';
export const users = 'users';
export const pagers = 'pagers';
export const partners = 'partners';
export const partnerFacilities = 'partnerFacilities';
export const partnerIntegrations = 'partnerIntegrations';
export const partnerConnectsRequests = 'partnerConnectsRequests';
export const connectsPartners = 'connectsParners';
export const connectsPartnerItemMasters = 'connectsPartnerItemMasters';
export const partnerGroups = 'partnerGroups';
export const paymentTerms = 'paymentTerms';
export const registers = 'registers';
export const salesRegisters = 'salesRegisters';
export const vaultRegisters = 'vaultRegisters';
export const registerTransactions = 'registerTransactions';
export const inventoryAudits = 'inventoryAudits';
export const inventorySyncDiscrepantPackages = 'inventorySyncDiscrepantPackages';
export const inventoryItems = 'inventoryItems';
export const inventoryItemsTestResults = 'inventoryItemsTestResults';
export const lots = 'lots';
export const itemMasters = 'itemMasters';
export const itemMasterIds = 'itemMasterIds';
export const itemMastersWithPricing = 'itemMastersWithPricing';
export const itemMastersWeighted = 'itemMastersWeighted';
export const medicatedWeights = 'medicatedWeights';
export const itemMasterChildren = 'itemMasterChildren';
export const itemMasterParent = 'itemMasterParent';
export const sellableItemMasters = 'sellableItemMasters';
export const vendorItemMasters = 'vendorItemMasters';
export const vendorChildItemMasters = 'vendorChildItemMasters';
export const packageIds = 'packageIds';
export const packageCodes = 'packageCodes';
export const motherPlants = 'motherPlants';
export const trackingIds = 'trackingIds';
export const trackingIdsBegin = 'trackingIdsBegin';
export const ingredients = 'ingredients';
export const ingredientCategories = 'ingredientCategories';
export const suppliers = 'suppliers';
export const suppliers2 = 'suppliers2';
export const currentFacilityUsers = 'currentFacilityUsers';
export const minimumDaysSupply = 'minimumDaysSupply';
export const taxes = 'taxes';
export const taxProfiles = 'taxProfiles';
export const buildings = 'buildings';
export const sections = 'sections';
export const locationSections = 'locationSections';
export const facilities = 'facilities'; //todo need to remove this constant and to replace all the usages in code once we have a separate reducer for facilities
export const detailedFacilities = 'detailedFacilities';
export const facilityGroups = 'facilityGroups';
export const organizations = 'organizations';
export const publicOrganizations = 'public_organizations';
export const packagingJobs = 'packagingJobs';
export const integrationAdjustmentReasons = 'integrationAdjustmentReasons';
export const categories = 'categories';
export const subcategories = 'subcategories';
export const biotrackCategories = 'biotrackCategories';
export const apisicpaCategories = 'apisicpaCategories';
export const receiptCategories = 'receiptCategories';
export const leafCategories = 'leafCategories';
export const metrcCategories = 'metrcCategories';
export const ohMetrcCategories = 'ohMetrcCategories';
export const metrcTrackingTags = 'metrcTrackingTags';
export const biotrackCategoryMappings = 'biotrackCategoryMappings';
export const apisicpaCategoryMappings = 'apisicpaCategoryMappings';
export const leafCategoryMappings = 'leafCategoryMappings';
export const metrcCategoryMappings = 'metrcCategoryMappings';
export const ohMetrcCategoryMappings = 'ohMetrcCategoryMappings';
export const cureCategoryMappings = 'cureCategoryMappings';
export const curePatients = 'curePatients';
export const cureOrderProducts = 'cureOrderProducts';
export const salesExportData = 'salesExportData';
export const pricingGroups = 'pricingGroups';
export const pricingClasses = 'pricingClasses';
export const pricingMultipliers = 'pricingMultipliers';
export const pricingMultiplierDisabledItems = 'pricingMultiplierDisabledItems';
export const priceLists = 'priceLists';
export const biotrackPatientCards = 'biotrackPatientCards';
export const biotrackItemDestructions = 'biotrackItemDestructions';
export const pricingWeights = 'pricingWeights';
export const prepackWeights = 'prepackWeights';
export const prepackFacilityWeights = 'prepackFacilityWeights';
export const productMasterPricingWeights = 'productMasterPricingWeights';
export const usedPrepackWeights = 'usedPrepackWeights';
export const usedFacilityPrepackWeights = 'usedFacilityPrepackWeights';
export const tags = 'tags';
export const salesOrders = 'salesOrders';
export const purchaseOrders = 'purchaseOrders';
export const purchaseOrdersActive = 'purchaseOrdersActive';
export const purchaseOrdersInActive = 'purchaseOrdersInActive';
export const selectedPurchaseOrders = 'selectedPurchaseOrders';
export const poAndSoTransactions = 'poAndSoTransactions';
export const inventoryReceipts = 'inventoryReceipts';
export const categoriesData = 'categoriesData';
export const labelRequirements = 'labelRequirements';
export const preferredMixes = 'preferredMixes';
export const rewards = 'rewards';
export const rewardsInOrder = 'rewardsInOrder';
export const rewardPointsAdjustments = 'rewardPointsAdjustments';
export const pricingGroupData = 'pricingGroupData';
export const pricingMultiplierData = 'pricingMultiplierData';
export const pricingClassData = 'pricingClassData';
export const containers = 'containers';
export const paymentTypes = 'paymentTypes';
export const customerGroupData = 'customerGroupData';
export const closingReport = 'closingReport';
export const drivers = 'drivers';
export const vehicles = 'vehicles';
export const payments = 'payments';
export const coupons = 'coupons';
export const couponsInOrder = 'couponsInOrder';
export const customersQueue = 'customersQueue';
export const customerStats = 'customerStats';
export const customerRecentHistories = 'customerRecentHistories';
export const childItemMasters = 'childItemMasters';
export const items = 'items';
export const itemsAvailability = 'itemsAvailability';
export const itemsAvailabilitySearch = 'itemsAvailability';
export const inventoryItemsMapping = 'inventoryItemsMapping';
export const closingDenominations = 'closingDenominations';
export const registerClosings = 'registerClosings';
export const newItems = 'newItems';
export const salesLocations = 'salesLocations';
export const labelsConfiguration = 'labelsConfiguration';
export const storageLocations = 'storageLocations';
export const cultivationLocations = 'cultivationLocations';
export const equipmentItems = 'equipmentItems';
export const equipmentTypes = 'equipmentTypes';
export const finishedProducts = 'finishedProducts';
export const averageTestResults = 'averageTestResults';
export const testResults = 'testResults';
export const testResultGroups = 'testResultGroups';
export const finalWeightCalls = 'finalWeightCalls';
export const phenotypes = 'phenotypes';
export const customLabels = 'customLabels';
export const labelTags = 'labelTags';
export const customLabelsForTag = 'customLabelsForTag';
export const printServers = 'printServers';
export const remotePrinters = 'remotePrinters';
export const localPrinters = 'localPrinters';
export const loadedLabels = 'labels';
export const printJobs = 'printJobs';
export const printJobsLog = 'printJobsLog';
export const zones = 'zones';
export const rooms = 'rooms';
export const images = 'images';
export const phases = 'phases';
export const messages = 'messages';
export const processingTypes = 'processingTypes';
export const processingJobs = 'processingJobs';
export const productLists = 'productLists';
export const productListData = 'productListData';
export const assemblyJobs = 'assemblyJobs';
export const complianceLimits = 'complianceLimits';
export const orderLimit = 'orderLimit';
export const itemPhases = 'itemPhases';
export const ingredientItems = 'ingredientItems';
export const productionRuns = 'productionRuns';
export const plantHistory = 'plantHistory';
export const harvestBatchHistory = 'harvestBatchHistory';
export const integratedLocationNames = 'integratedLocationNames';
export const packageEvents = 'packageEvents';
export const itemEvents = 'itemEvents';
export const purchaseOrderEvents = 'purchaseOrderEvents';
export const salesOrderEvents = 'salesOrderEvents';
export const transactions = 'transactions';
export const apiTransactionEntities = 'apiTransactionEntities';
export const transactionsEndPoints = 'transactionsEndPoints';
export const transactionsIntegrators = 'transactionsIntegrators';
export const correctiveActions = 'correctiveActions';
export const restockTransactions = 'restockTransactions';
export const quickbooksAccounts = 'quickbooksAccounts';
export const quickbooksAccountsTypes = 'quickbooksAccountsTypes';
export const quickbooksTransactionTypes = 'quickbooksTransactionTypes';
export const quickbooksAccountsOrganizations = 'quickbooksAccountsOrganizations';
export const accountingSettingsFields = 'accountingSettingsFields';
export const accountingAccountTypes = 'accountingAccountTypes';
export const accountingSettings = 'accountingSettings';
export const accountingExportPayload = 'accountingExportPayload';
export const qualifyingConditions = 'qualifyingConditions';
export const retailFacilityTypes = 'retailFacilityTypes';
export const visitReasons = 'visitReasons';
export const plantStats = 'plantStats';
export const itemTransactions = 'itemTransactions';
export const packageTests = 'packageTests';
export const integrationMapping = 'integrationMapping';
export const integrationMappings = 'integrationMappings';
export const notifications = 'notifications';
export const provinces = 'provinces';
export const countries = 'countries';
export const catalog = 'catalog';
export const purposes = 'purposes';
export const reportingMapping = 'reportingMapping';
export const physicians = 'physicians';
export const sampleTypes = 'sampleTypes';
export const productSampleTypes = 'productSampleTypes';
export const reservations = 'reservations';
export const hardReservations = 'hardReservations';
export const softReservations = 'softReservations';
export const currentFacilityModules = 'currentFacilityModules';
export const sharedFacilityGroups = 'sharedFacilityGroups';
export const taxDocumentsRecords = 'taxDocumentsRecords';
export const orderImportHistory = 'orderImportHistory';
export const thirdPartyIntegrations = 'thirdPartyIntegrations';
export const thirdPartyIntegrationData = 'thirdPartyIntegrationData';
export const onfleetTeams = 'onfleetTeams';
export const onfleetWorkers = 'onfleetWorkers';
export const itemMastersNames = 'itemMastersNames';
export const filteredProducts = 'filteredProducts';
export const massModifyFormData = 'massModifyFormData';
export const pricingClassesWithProduct = 'pricingClassesWithProduct';
export const pricingGroupsWithProduct = 'pricingGroupsWithProduct';
export const leaflyCategories = 'leaflyCategories';
export const leaflyCategoryMappings = 'leaflyCategoryMappings';
export const itemMasterHistory = 'itemMasterHistory';
export const massModifyProductsFiltered = 'massModifyProductsFiltered';
export const harvestWasteTypes = 'harvestWasteTypes';
export const inventoryTypes = 'inventoryTypes';
export const weedmapsCategories = 'weedmapsCategories';
export const weedmapsCategoryMappings = 'weedmapsCategoryMappings';
export const integrationCategoryMappings = 'integrationCategoryMappings';
export const integrationSynchronizableModules = 'integrationSynchronizableModules';
export const transactionReport = 'transactionReport';
export const inventoryReport = 'inventoryReport';
export const productSalesReport = 'productSalesReport';
export const metrcSalesReport = 'metrcSalesReport';
export const cashOnHandReport = 'cashOnHandReport';
export const inventoryTransactionsReport = 'inventoryTransactionsReport';
export const inactiveItemMasters = 'inactiveItemMasters';
export const returnedItems = 'returnedItems';
export const printToScreenQueue = 'printToScreenQueue';
export const wastePackages = 'wastePackages';
export const getWaInventoryMapping = 'getWaInventoryMapping';
export const leafWaHarvestTypes = 'leafWaHarvestTypes';
export const waInfusionInventoryTypes = 'waInfusionInventoryTypes';
export const waProcessingInventoryTypes = 'waProcessingInventoryTypes';
export const waPackagingInventoryTypes = 'waPackagingInventoryTypes';
export const isolocityCategories = 'isolocityCategories';
export const isolocityLabResults = 'isolocityLabResults';
export const inheritedLabResults = 'inheritedLabResults';
export const isolocityCategoryMappings = 'isolocityCategoryMappings';
export const hypurCheckedInUsers = 'hypurCheckedInUsers';
export const availableFacilitiesIds = 'availableFacilitiesIds';
export const metrcPlantsWasteMethods = 'metrcPlantsWasteMethods';
export const metrcPlantsWasteReasons = 'metrcPlantsWasteReasons';
export const metrcProducts = 'metrcProducts';
export const metrcProductsMapping = 'integrationProductsMapping';
export const metrcVendorProductMappings = 'metrcVendorProductMappings';
export const metrcTransferPackages = 'metrcTransferPackages';
export const metrcTransferTypes = 'metrcTransferTypes';
export const metrcUoms = 'metrcUoms';
export const metrcLocationTypes = 'metrcLocationTypes';
export const closedLoops = 'closedLoops';
export const closedLoopFacilities = 'closedLoopFacilities';
export const closedLoopRegulatedReceipts = 'closedLoopRegulatedReceipts';
export const closedLoopRegulatedReceipt = 'closedLoopRegulatedReceipt';
export const selectedReceiptId = 'selectedReceiptId';
export const closedLoopRegulatedUsers = 'closedLoopRegulatedUsers';
export const salesReportPatientData = 'salesReportPatientData';
export const salesReportSalesData = 'salesReportSalesData';
export const modalities = 'modalities';
export const cupos = 'cupos';
export const cupo_numbers = 'cupo_numbers';
export const featureToggles = 'featureToggles';
export const testedPlants = 'testedPlants';
export const testedPackages = 'testedPackages';
export const ccaCategories = 'ccaCategories';
export const ccaCategoryMappings = 'ccaCategoryMappings';
export const csvReports = 'csvReports';
export const analyticsReports = 'analyticsReports';
export const reportsForExport = 'reportsForExport';
export const domoCustomReports = 'domoCustomReports';
export const openForms = 'openForms';
export const metrcSalesCustomerTypes = 'metrcSalesCustomerTypes';
export const inventoryLocks = 'inventoryLocks';
export const entityLocks = 'entityLocks';
export const quarantinedInventory = 'quarantinedInventory';
export const selectedQuarantinedInventory = 'selectedQuarantinedInventory';
export const labResults = 'labResults';
export const merchantConfigurationMerchants = 'merchantConfigurationMerchants';
export const merchantConfigurationProfiles = 'merchantConfigurationProfiles';
export const employeeTitles = 'employeeTitles';
export const selectedCategories = 'selectedCategories';
export const aLaCartAvailableForOrg = 'aLaCartAvailableForOrg';
export const mmuOrders = 'mmuOrders';
export const has_leaf_pa_config_pack_and_closed_loop = 'has_leaf_pa_config_pack_and_closed_loop';
export const has_leaf_utah_config_pack_and_closed_loop = 'has_leaf_utah_config_pack_and_closed_loop';
export const vendor_facility_type = 'vendor_facility_type';
