import {createSelector, createStructuredSelector} from 'reselect';
import {formValueSelector} from 'redux-form';
import {PACKAGE_PLANTS_FORM} from '../../constants/forms';
import {BT_MATURE_PLANT} from '../../constants/integration/biotrack/biotrackInventoryTypes';
import {getApplicablePlatformSubcategoryIds} from '../integration/biotrackCategoriesSelectors';
import {getIntegrationState} from '../integration/integrationSelectors';
import {getSelectedPlants} from '../plantsSelectors';
import {getTimezone} from '../timezoneSelectors';
import {getItemMasters} from '../itemMastersSelectors';
import {getFlattenedStorageLocations} from '../locationsSelectors';
import {getTrackingIds} from '../trackingIdsSelectors';


const selector = formValueSelector(PACKAGE_PLANTS_FORM);
const getItemMasterId = state => selector(state, 'item_master_id') || 0;
const getLotNumber = state => selector(state, 'lot_number') || '';
const getComplianceSettings = state => state.complianceSettings;
const isTradeSample = state => selector(state, 'is_trade_sample') == true;
const isDonation = state => selector(state, 'is_donation') == true;

const getApplicableSubcategoryIds = getApplicablePlatformSubcategoryIds([BT_MATURE_PLANT]);

const getSubcategoryIds = createSelector(
  [getIntegrationState, getApplicableSubcategoryIds],
  ({isBiotrack}, subcategoryIds) => {
    if (isBiotrack) {
      return subcategoryIds;
    }
    return [];
  }
);

const getHasMotherPlants = createSelector([getSelectedPlants], selectedPlants => selectedPlants.some(plant => !! plant.is_mother));

export const getSelectedItemMaster = createSelector(
  [getItemMasters, getItemMasterId],
  (itemMasters, selectedItemMasterId) => {
    return itemMasters.find((itemMaster) => itemMaster.id === selectedItemMasterId);
  }
);

export const getPackagePlantsPageProps = createStructuredSelector({
  timezone: getTimezone,
  existingItemMasters: getItemMasters,
  locations: getFlattenedStorageLocations,
  selectedPlants: getSelectedPlants,
  itemMasterId: getItemMasterId,
  lotNumber: getLotNumber,
  integrationState: getIntegrationState,
  trackingIds: getTrackingIds,
  subcategoryIds: getSubcategoryIds,
  complianceSettings: getComplianceSettings,
  hasMotherPlants: getHasMotherPlants,
  isTradeSample: isTradeSample,
  isDonation: isDonation,
  selectedItemMaster: getSelectedItemMaster
});

export default getPackagePlantsPageProps;
