import React from 'react';
import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';
import { Row, Col, Button } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import InternationalDateTimePickerInput from '../../common/form/InternationalDateTimePickerInput';
import * as formNames from '../../../constants/forms';
import {isMoment} from '../../../util/dateHelpers';
import {dateRangeConstraints} from './helpers';

const validate = (values) => {
  return Object.keys(values).reduce((acc, key) => {
    const date = values[key];
    acc[key] = !isMoment(date) || !date.isValid() ? I18n.t('cultivation.testResults.validation.dateIsRequired') : undefined;
    if(!acc[key]){
      const result = dateRangeConstraints(key, values[key], values);
      if(!result){
        acc[key] = I18n.t('cultivation.testResults.validation.dateOutOfRange');
      }
    }
    return acc;
  }, {});
};

/***
 * Test Results Form which is embedded in the test results page further down.
 * @param props
 * @returns {*}
 * @constructor
 */
const TestResultsExportForm = (props) => {
  const {handleSubmit} = props;
  return (<div style={{marginTop: '18px'}}>
    <form onSubmit={handleSubmit}>
      <Row>
        <Col md={3}>
          <InternationalDateTimePickerInput
            name='testing_date_from'
            isValidDate={props.startDateIsValid}
            props={{
              label: I18n.t('reportsFromThisDate'),
              isRequired: true,
              timeFormat: false,
            }}
          />
        </Col>
        <Col md={3}>
          <InternationalDateTimePickerInput
            name='testing_date_to'
            isValidDate={props.endDateIsValid}
            props={{
              label: I18n.t('reportsToThisDate'),
              isRequired: true,
              timeFormat: false,
            }}
          />
        </Col>
        <Col md={3}>
          <div className='form-group'>
            <label className='control-label'>&nbsp;</label>
            <Button type='submit' variant='primary' className='btn-block'>Get CSV</Button>
          </div>
        </Col>
      </Row>
    </form>
  </div>);
};

TestResultsExportForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  startDateIsValid: PropTypes.func.isRequired,
  endDateIsValid: PropTypes.func.isRequired,
};

const TestResultsExportFormRedux = reduxForm({
  form: formNames.TEST_RESULTS_EXPORT_FORM,
  validate,
})(TestResultsExportForm);

export default TestResultsExportFormRedux;
