import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {postTokenLogin} from '../../../actions/userActions';
import LoginPage  from './LoginPage'; //eslint-disable-line


export class TokenLoginPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
  }

  componentWillMount() {

    this.props.actions.postTokenLogin(this.props.params.token);
  }


  render () {
    return <LoginPage/>;
  }
}

TokenLoginPage.propTypes = {
  actions: PropTypes.object.isRequired,
  params: PropTypes.shape({
    token: PropTypes.string
  })
};

function mapStateToProps(state){
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({postTokenLogin}, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (TokenLoginPage);
