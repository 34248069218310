import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack, push} from 'react-router-redux';
import {Alert, Tab, Tabs} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import * as apiActions from '../../../../actions/apiActions';
import * as dataNames from '../../../../constants/dataNames';
import getSalesReportTabs from '../common/tabDefinitions';
import ClosingReportForm from './ClosingReportForm';  // eslint-disable-line import/no-named-as-default
import TabbedFormWrapper from '../../../common/form/TabbedFormWrapper';
import * as dataActions from '../../../../actions/dataActions';
import * as itemNames from '../../../../constants/itemNames';
import {getRegisterClosingSettings} from '../../../../selectors/registerClosingSelectors';
import {addMessage} from '../../../../actions/systemActions';
import * as messageTypes from '../../../../constants/messageTypes';
import {dataUpdateSetAvailable, getDataUpdateAvailable} from '../../../../selectors/dataUpdateSelectors';
import {getEnabledTabs} from '../../../../actions/reportActions';

export class ClosingReportPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tabs: [
        {
          eventKey: 'open',
          title: 'accounting.open',
          route: '/retail/sales-report/closing-report/open',
        },
        {
          eventKey: 'closed',
          title: 'accounting.closed',
          route: '/retail/sales-report/closing-report/closed',
        }
      ],
      salesReportTabs: [],
      activeTab: null,
      isOpenTab: null,
      isWarnings: false,
      isLoading: true,
      date: new Date(),
      activeSalesReportTab: 'closingReport',
    };

    this.closeRegister = this.closeRegister.bind(this);
    this.openRegister = this.openRegister.bind(this);
    this.openAllRegisters = this.openAllRegisters.bind(this);
    this.verifyCount = this.verifyCount.bind(this);
    this.dateChange = this.dateChange.bind(this);
    this.getClosingReport = this.getClosingReport.bind(this);
    this.switchTab = this.switchTab.bind(this);
    this.switchSalesReportTab = this.switchSalesReportTab.bind(this);
    this.setActiveTabAndFetch = this.setActiveTabAndFetch.bind(this);
  }

  componentWillMount() {
    this.props.actions.getItem('/api/order_settings/sales_limit', itemNames.salesComplianceSettings);
    this.setState({salesReportTabs: this.props.actions.getEnabledTabs(getSalesReportTabs)});
  }

  componentWillReceiveProps(nextProps){
    if (
      nextProps.status !== this.state.activeTab ||
      dataUpdateSetAvailable(nextProps.dataUpdateAvailable, this.props.dataUpdateAvailable)
    ) {
      this.setActiveTabAndFetch(nextProps.status);
    }
  }

  switchTab(eventKey) {
    const tab = this.state.tabs.find(tab => tab.eventKey === eventKey) || this.state.tabs[0];
    this.props.actions.push(tab.route);
  }

  switchSalesReportTab(selection) {
    const activeSalesReportTab = selection;
    const tab = this.state.salesReportTabs.find(tab => tab.eventKey === activeSalesReportTab) || this.state.salesReportTabs[0];
    this.setState({activeSalesReportTab});
    this.props.actions.push(tab.path);
  }

  setActiveTabAndFetch(activeTab) {
    this.setState(
      {
        activeTab,
        isOpenTab: activeTab === 'open'
      },
      () => {
        this.getClosingReport(new Date());
      });
  }

  getDataForOpenAllRegisters()
  {
    const registerIds = this.props.closingReport.map(register => register.id);

    return {
      ids: registerIds,
      is_closed: 0,
    };
  }

  openAllRegisters() {
    const data = this.getDataForOpenAllRegisters();

    this.props.actions.postData(
      '/api/registers/update_many',
      data,
      dataNames.registerClosings,
      {
        success: 'retail.closingTill.successPost',
        failed: 'retail.closingTill.failPost'
      },
      null,
      () => {
        this.getClosingReport(new Date());
      }
    );
  }

  openRegister(id) {
    if (id) {
      const {putItem} = this.props.actions;
      const data = {
        is_closed: 0
      };

      putItem(`/api/registers/${id}`,
        data,
        itemNames.register,
        {
          success: 'registers.open.success',
          failed: 'registers.open.fail'
        },
        null,
        () => {
          this.getClosingReport(new Date());
        }
      );
    }
  }

  closeRegister(id) {
    const data = {
      register_id: id
    };

    this.props.actions.postData(
      '/api/closings/register',
      data,
      null,
      {
        success: 'retail.closingReport.closeRegister.success',
        failed: 'retail.closingReport.closeRegister.failed'
      },
      null,
      () => {
        this.getClosingReport(new Date());
      }
    );
  }

  verifyCount(closing_id, type) {
    this.props.actions.push(`/retail/register-reconciliation/${type}/${closing_id}`);
  }

  dateChange(e) {
    if (moment.isMoment(e)) {
      e.endOf('day');

      this.setState({
        date: e.endOf('day')
      });

      this.getClosingReport(e.endOf('day'));
    }
  }

  getClosingReport(data) {
    const {isOpenTab} = this.state;
    this.props.actions.unsetData(dataNames.closingReport);
    const formattedDate = moment(data).format('YYYY-MM-DD');

    this.setState({
      isLoading: true,
    });

    this.props.actions.getUnpaginatedData(
      '/api/closing_report',
      dataNames.closingReport,
      null,
      {
        date: formattedDate,
        is_closed: Number(!isOpenTab)
      },
      (data) => {
        const state = {
          isLoading: false
        };

        if (data.warnings && data.warnings.CLOSING_REPORT_ERROR) {
          addMessage(messageTypes.success, data.warnings.CLOSING_REPORT_ERROR);
          state.isWarnings = true;
        } else {
          state.isWarnings = false;
        }

        this.setState(state);
      }
    );
  }

  render() {
    const {closingReport, registerClosingSettings} = this.props;
    const {activeTab, isOpenTab, isWarnings, isLoading, date} = this.state;
    const closingOpeningAllowed = registerClosingSettings && registerClosingSettings.is_allowed;

    return (
      <React.Fragment>
        {closingOpeningAllowed &&
        <Tabs id='filterTabs' activeKey={activeTab} onSelect={this.switchTab}>
          {this.state.tabs.map((tab, index) =>
            <Tab key={index} eventKey={tab.eventKey} title={I18n.t(tab.title)}/>
          )}
        </Tabs>
        }
        <TabbedFormWrapper title={I18n.t('retail.closingReport.title')} tabs={this.state.salesReportTabs} activeTab={this.state.activeSalesReportTab} switchTab={this.switchSalesReportTab}>
          <Alert variant='warning'>
            Starting 2/14/22, Closing Report will only be available for previous 90 days. Please use Analytics module
            for Orders older than 90 days.
          </Alert>
          {isLoading
            ? null
            : <ClosingReportForm
              dateChange={this.dateChange}
              data={closingReport}
              isOpenTab={isOpenTab}
              isWarnings={isWarnings}
              closeRegister={this.closeRegister}
              openRegister={this.openRegister}
              openAllRegisters={this.openAllRegisters}
              registerClosingSettings={registerClosingSettings}
              verifyCount={this.verifyCount}
              initialValues={{date: date}}
            />
          }
        </TabbedFormWrapper>
      </React.Fragment>

    );
  }
}

ClosingReportPage.propTypes = {
  actions: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    getEnabledTabs: PropTypes.func,
  }).isRequired,
  closingReport: PropTypes.array,
  registerClosingSettings: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({},
    apiActions,
    dataActions,
    {goBack, push, getEnabledTabs}
  );
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps(state, ownProps) {
  const status = ownProps.params && ownProps.params.status === 'closed' ? 'closed' : 'open';
  const registerClosingSettings = getRegisterClosingSettings(state);
  const dataUpdateAvailable = [getDataUpdateAvailable(state, {name: dataNames.registers})];

  return {
    registerClosingSettings,
    closingReport: state[dataNames.closingReport],
    status,
    dataUpdateAvailable,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClosingReportPage);
