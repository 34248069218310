import { reduxForm } from 'redux-form';
import CreateInfusionForm from './StartInfusionForm';
import { validate } from './validate';


const StartInfusionFormWrapper = reduxForm({
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(CreateInfusionForm);


export default StartInfusionFormWrapper;
