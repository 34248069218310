/* eslint-disable import/prefer-default-export*/
import get from 'lodash.get';
import * as actionTypes from '../constants/actionTypes';
import * as itemNames from '../constants/itemNames';
import * as dataNames from '../constants/dataNames';
import * as dataActions from '../actions/dataActions';

/**
 * Remove any completed reward adjustments for customer when adding to store
 */
export const cleanRewardAdjustments = store => next => action => {
  const result = next(action);
  if(action.type === actionTypes.GET_ITEM_SUCCESS && action.name === itemNames.customer){
    const customerId = get(action,'payload.data.id',null);
    const rewardAdjustments = store.getState()[dataNames.rewardPointsAdjustments];
    const cleanedAdjustments = rewardAdjustments.filter( (adj) => {
      if(adj.customerId === customerId && adj.status === 'complete'){
        return false;
      }
      return true;
    });
    store.dispatch(dataActions.setData(cleanedAdjustments, dataNames.rewardPointsAdjustments));
  }

  return result;
};
