import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {bindActionCreators} from 'redux';
import { Col, Row, Button } from 'react-bootstrap';
import Select from 'react-select';

import BootstrapFormGroup from '../../../common/form/BootstrapFormGroup';
import * as apiActions from '../../../../actions/apiActions';

const validator = (values) => {
  const errors = {};

  if(!values.partner_facility_id)
    errors.partner_facility_id = I18n.t('common.form.isRequired', {fieldName: I18n.t('cultivation.supplyChain.forms.mapMetrcProducts.fields.partner_facility_id')});

  if(!values.item_master_id)
    errors.item_master_id =  I18n.t('common.form.isRequired', {fieldName: I18n.t('cultivation.supplyChain.forms.mapMetrcProducts.fields.item_master_id')});

  return errors;
};

/*
* FUNCTION - INIT - SCOPE - NOTE
* function = () => {} style creates an automatic binding to the class it's defined inside otherwise manual binding is required
* I.E. {e => this.onItemUpdated(e)} or {this.onItemUpdated.bind(this)} or in the contructor this.onItemUpdated = this.onItemUpdated.bind(this)
*/
class MetrcVendorProductMappingForm extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.state = {submitting: false, invalid: false, errors: [], products: this.props.productDefaults};
  }

  onSubmit(e) {
    // Stop the submit default behavior from occuring
    e.preventDefault();

    // Extract the properties we want to use local variables by name for shorter references
    const { onUpdate, actions, item } = this.props;

    if(!this.validate()) {
      return;
    }
    this.setState({submitting: true});

    // Fire the API action which automatically dispatches to the reducers, triggering both
    // state updates on the item state and on the message state,
    // then fire then onUpdate event that is passable via properties of the component, allowing
    // the parent class to receive notification of the save being successful
    actions.putItem('/api/metrc/vendor_product_mappings/' + item.id, item, 'metrcVendorProductMapping', {
      failed: 'Failed to Save the Mapping',
      success: 'Mapping was Saved Successfully'
    }).then((item) => {
      onUpdate(item);
      this.setState({submitting: false});
    }).catch(error => {
      this.setState({submitting: false});
    });
  }

  validate() {
    const { item } = this.props;
    const errors = validator(item);
    const valid = Object.keys(errors).length ? false : true;

    this.setState({errors: errors});
    this.setState({invalid: !valid});

    return valid;
  }

  onItemMasterChange(item_master) {
    this.props.item.item_master_id = item_master ? item_master.id : null;
    this.setState({updated: new Date()});
    this.validate();
  }

  onFacilityChange(facility) {
    this.props.item.partner_facility_id = facility ? facility.id : null;
    this.setState({updated: new Date()});
    this.validate();
  }

  onItemMasterFocus() {
    this.getProducts();
  }

  // Used to retrieve the data for the product dropdown based
  // on the mappings: partner_facility_id, uom and strain
  getProducts() {
    const { item, partnerFacilities } = this.props;

    const facility_id = item.partner_facility_id;
    const facility = partnerFacilities.find((facility, index) => {
      return facility.id === facility_id;
    });

    const params = {
      vendor_id: facility.partner_id,
      default_uom: item.default_uom,
      strain_name: item.metrc_product_strain
    };
    return this.props.actions.getUnpaginatedData(
      '/api/item_masters/search',
      'itemMasters',
      {failed: 'Cannot retrieve Item Masters'},
      params
    ).then( (products) => {
      this.setState({products: products});
    });
  }

  render() {
    const { item, partnerFacilities } = this.props;
    const { submitting, invalid, errors, products } = this.state;
    return (
      <form onSubmit={this.onSubmit.bind(this)}>
        <Row>
          <Col md={2}>
            <label className='visible-sm visible-xs'>{I18n.t('cultivation.supplyChain.forms.mapMetrcProducts.fields.metrc_partner_license')}</label>
            <input style={{marginBottom: '5px'}} className='form-control' readOnly value={item.metrc_partner_license} />
          </Col>
          <Col md={2}>
            <label className='visible-sm visible-xs'>{I18n.t('cultivation.supplyChain.forms.mapMetrcProducts.fields.metrc_product_name')}</label>
            <input style={{marginBottom: '5px'}} className='form-control' readOnly value={item.metrc_product_name} />
          </Col>
          <Col md={1}>
            <label className='visible-sm visible-xs'>{I18n.t('cultivation.supplyChain.forms.mapMetrcProducts.fields.metrc_product_uom')}</label>
            <input style={{marginBottom: '5px'}} className='form-control' readOnly value={item.metrc_product_uom} />
          </Col>
          <Col md={2}>
            <label className='visible-sm visible-xs'>{I18n.t('cultivation.supplyChain.forms.mapMetrcProducts.fields.metrc_product_strain')}</label>
            <input style={{marginBottom: '5px'}} className='form-control' readOnly value={item.metrc_product_strain} />
          </Col>
          <Col md={2}>
            <label className='visible-sm visible-xs'>{I18n.t('cultivation.supplyChain.forms.mapMetrcProducts.fields.partner_facility_id')}</label>
            <BootstrapFormGroup
              name='partner_facility_id'
              id={'facility_id_' + item.id}
              Control={Select}
              validationState={errors.partner_facility_id ? 'error' : null}
              error={errors.partner_facility_id || null}
              controlProps={{
                options: partnerFacilities,
                isRequired: true,
                labelKey: 'facility_name',
                valueKey: 'id',
                onChange: this.onFacilityChange.bind(this),
                value: item.partner_facility_id,
                style: {marginBottom: '5px'}
              }} />
          </Col>
          <Col md={2}>
            {item.partner_facility_id &&
              <div>
                <label className='visible-sm visible-xs'>{I18n.t('cultivation.supplyChain.forms.mapMetrcProducts.fields.item_master_id')}</label>
                <BootstrapFormGroup
                  name='item_master_id'
                  id={'item_master_' + item.id}
                  Control={Select}
                  validationState={errors.item_master_id ? 'error' : null}
                  error={errors.item_master_id || null}
                  controlProps={{
                    options: products,
                    isRequired: true,
                    labelKey: 'name',
                    valueKey: 'id',
                    onChange: this.onItemMasterChange.bind(this),
                    onFocus: this.onItemMasterFocus.bind(this),
                    value: item.item_master_id,
                    style: {marginBottom: '5px'}
                  }} />
              </div>
            }
          </Col>
          <Col md={1}>
            <Button variant='primary' style={{marginBottom: '5px'}}
              type='submit'
              disabled={submitting || invalid}>
              Save
          </Button>
          </Col>
        </Row>
      </form>
    );
  }
}

MetrcVendorProductMappingForm.propTypes = {
  partnerFacilities: PropTypes.array.isRequired,
  productDefaults: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions);
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MetrcVendorProductMappingForm);
