import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Col} from 'react-bootstrap';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import ReactSelectInput from '../ReactSelectInput';
import {getStateByCountry} from '../../../../util/countryStateHelper';
import ContentConcealer from '../../concealers/ContentConcealer';
import {ensureGetUnpaginatedData} from '../../../../actions/apiActions';
import * as dataNames from '../../../../constants/dataNames';

class CountryField extends React.PureComponent {

  constructor(props, context) {

    super(props, context);
    const currentCountry = props.currentCountry;
    const filterCountry = currentCountry || 'US';
    this.state = {currentCountry, filterCountry, states: getStateByCountry(filterCountry, this.props.provinces)};
  }

  componentWillMount() {
    this.props.actions.ensureGetUnpaginatedData('/api/countries', dataNames.countries);
    this.props.actions.ensureGetUnpaginatedData('/api/provinces', dataNames.provinces);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.provinces.length && !this.props.provinces.length) {
      this.setState({states: getStateByCountry(this.state.filterCountry, nextProps.provinces)});
    }

    if (nextProps.currentCountry !== this.props.currentCountry && nextProps.provinces.length && nextProps.currentCountry) {
      this.props.change(this.props.stateFieldName, '');
      this.setState({states: getStateByCountry(nextProps.currentCountry,  nextProps.provinces), currentCountry: nextProps.currentCountry});
    }
  }

  render() {
    const {name, stateFieldName, countryFieldSize, stateFieldSize, stateProps, countries, disabled, isRequired} = this.props;
    const {states, currentCountry} = this.state;
    return (
      <React.Fragment>
        <Col md={countryFieldSize}>
          <Field name={name} component={ReactSelectInput} props={{
            label: I18n.t('common.form.country'),
            placeholder: I18n.t('common.form.select'),
            options: countries,
            textKey: 'name',
            valueKey: 'country_code',
            value: currentCountry,
            disabled: disabled,
            isRequired
          }}/>
        </Col>
        <ContentConcealer show={currentCountry === 'US' || currentCountry === 'AU' || !currentCountry}>
          <Col md={stateFieldSize}>
            <Field name={stateFieldName} component={ReactSelectInput} props={{
              label: I18n.t('common.form.state'),
              options: states,
              textKey: 'province_code',
              valueKey: 'province_code',
              placeholder: I18n.t('common.form.select'),
              disabled: disabled,
              ...stateProps
            }}/>
          </Col>
        </ContentConcealer>
        <ContentConcealer show={['CA', 'ZA', 'ES', 'IT', 'LS', 'TH'].includes(currentCountry)}>
          <Col md={stateFieldSize}>
            <Field name={stateFieldName} component={ReactSelectInput} props={{
              label: I18n.t('common.form.province'),
              options: states,
              textKey: 'province_code',
              valueKey: 'province_code',
              placeholder: I18n.t('common.form.select'),
              disabled: disabled,
              ...stateProps,
              ...((currentCountry === 'ZA' || currentCountry === 'TH') && {isRequired: false})
            }}/>
          </Col>
        </ContentConcealer>
        <ContentConcealer show={currentCountry === 'NZ'}>
          <Col md={stateFieldSize}>
            <Field name={stateFieldName} component={ReactSelectInput} props={{
              label: I18n.t('common.form.region'),
              options: states,
              textKey: 'province_code',
              valueKey: 'province_code',
              placeholder: I18n.t('common.form.select'),
              disabled: disabled,
              ...stateProps
            }}/>
          </Col>
        </ContentConcealer>
        <ContentConcealer show={currentCountry === 'UY' || currentCountry === 'CO'}>
          <Col md={stateFieldSize}>
            <Field name={stateFieldName} component={ReactSelectInput} props={{
              label: I18n.t('common.form.department'),
              options: states,
              textKey: 'province_code',
              valueKey: 'province_code',
              placeholder: I18n.t('common.form.select'),
              disabled: disabled,
              ...stateProps,
              isRequired: false
            }}/>
          </Col>
        </ContentConcealer>
      </React.Fragment>
    );
  }
}

CountryField.propTypes = {
  name: PropTypes.string.isRequired,
  stateFieldName: PropTypes.string.isRequired,
  countryFieldSize: PropTypes.number.isRequired,
  stateFieldSize: PropTypes.number.isRequired,
  currentCountry: PropTypes.string,
  stateProps: PropTypes.object,
  actions: PropTypes.shape({
    ensureGetUnpaginatedData: PropTypes.func.isRequired
  }),
  countries: PropTypes.array.isRequired,
  provinces: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ensureGetUnpaginatedData}, dispatch)
  };
}

function mapStateToProps(state, {currentCountry: currentCountryProp, change, name}) {
  const {countries, provinces} = state;
  const currentCountry = currentCountryProp ? currentCountryProp : ' ';
  if(!currentCountryProp && change) change(name, currentCountry);
  return {
    countries,
    provinces,
    currentCountry
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (CountryField);
