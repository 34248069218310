import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';

const validate = (values, {allowNegativeInventory}) => {
  const errors = {
    transfers: []
  };

  if(!values.partner_facility_id){
    errors.partner_facility_id = I18n.t('internalTransfers.form.errors.noPartnerFacility');
  }

  if(!values.storage_location_id){
    errors.storage_location_id = I18n.t('internalTransfers.form.errors.noStorageLocation');
  }

  values.transfers.forEach((transfer, index) => {
    let err = undefined;
    const transferQuantityValue = parseFloat(get(transfer, 'transfer_quantity', 0));
    const transferQuantity = !isNaN(transferQuantityValue) ? transferQuantityValue : 0;
    if (!allowNegativeInventory && transferQuantity > (transfer.qty - transfer.qty_reserved)) {
      err = I18n.t('internalTransfers.form.errors.overAmount');
    }
    if(!transferQuantity){
      err = I18n.t('internalTransfers.form.errors.noAmount');
    }
    if(get(transfer, 'prepackNotAvailable')){
      err = I18n.t('internalTransfers.form.errors.prepackNotAvailable');
    }

    if(get(transfer, 'on_hold')) {
      err = I18n.t('internalTransfers.form.errors.packageIsOnHold');
      errors.onHold = err;
    }
    errors.transfers[index] = {transfer_quantity: err};
  });
  return errors;
};

export default validate;
