import {createSelector, createStructuredSelector} from 'reselect';
import get from 'lodash.get';
import orderBy from 'lodash.orderby';
import * as dataNames from '../constants/dataNames';
import * as itemNames from '../constants/itemNames';
import getSelectedDataName from '../constants/helpers/getSelectedDataName';

export const getPhysician = state => state[itemNames.physician];
export const getPhysicians = state => state[dataNames.physicians];
export const getSelectedPhysicians = state => state[getSelectedDataName(dataNames.physicians)];
export const getSelectedPhysicianIds = createSelector(getSelectedPhysicians, physicians => physicians.map(p => p.id));

const getStatus = (_, props) => props && props.params && props.params.status;
const getActiveTab = createSelector([getStatus], status => status === 'inactive' ? status : 'active');
const getTableData = createSelector(
  [getPhysicians, getActiveTab],
  (physicians, activeTab) => physicians.filter(physician => physician.active === (activeTab === 'inactive' ? 0 : 1))
);

export const getPhysiciansPageProps = createStructuredSelector({
  activeTab: getActiveTab,
  data: getTableData,
  selectedRows: getSelectedPhysicianIds,
});

const getPhysicianAddress = (physician) => (physician && physician.physician_addresses || []).find(address => address.active === 1 && address.primary === 1);
const getDisplayAddress = (address) => {
  if (!address) {
    return '';
  }
  const zipCode = [address.province_code, address.postal_code].filter(Boolean).join(' ');
  return [address.street_address_1, address.city, zipCode, address.country_code].filter(Boolean).join(', ');
};

const getDisplayLicense = (license) => {
  return get(license, 'license_number', '');
};

// If the physician has a license with a matching license type and is active return that license
// otherwise just return the license that is active
const getPhysicianActiveLicenseByType = (physician, license_type) => {
  const licenses = get(physician, 'physician_licenses', []);
  let licenseFound = {};
  for (const license of licenses) {
    if (!license.active) {
      continue;
    }
    if (license.license_type && license.license_type === license_type) {
      return license;
    } else if (!license.license_type) {
      licenseFound = license;
    }
  }

  return licenseFound;
};

const getActivePhysicians = createSelector(
  [getPhysicians],
  (physicians) => physicians
    .filter(physician => physician && physician.active === 1)
    .map(physician => {
      const display_name = [physician.first_name, physician.middle_name, physician.last_name].filter(Boolean).join(' ');
      const display_address = getDisplayAddress(getPhysicianAddress(physician));
      const display_license = getDisplayLicense(getPhysicianActiveLicenseByType(physician, 'STATE'));
      const display_dea_number = getDisplayLicense(getPhysicianActiveLicenseByType(physician, 'DEA_NUMBER'));
      const display_clinic = physician.physician_clinic && physician.physician_clinic.name || '';

      return {
        ...physician,
        display_name,
        display_address,
        display_license,
        display_dea_number,
        display_clinic,
      };
    })
);

export const getOrderedPhysicians = createSelector(
  [getActivePhysicians],
  physicians => orderBy(physicians, 'display_name', 'asc')
);
