import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {
  Col,
  Row
} from 'react-bootstrap';

import SelectInput from '../common/form/SelectInput';
import TextAreaInput from '../common/form/TextAreaInput';
import InternationalCurrencyInput from '../common/form/InternationalCurrencyInput';

const TransactionFields = ({partners, reasons}) => {

  return (
    <div className='transaction-fields'>
      <Row className='form-fields-row'>
        <Col className='form-fields-col' xs={8} md={3} lg={2}>
          <InternationalCurrencyInput
            name='transaction.transacted_amount'
            props={{
              label: I18n.t('registers.form.quantity'),
              fractionPartSize:2,
              allowNegativeNumber:true,
              min:-10000000000,
              step: 0.01
            }}/>
        </Col>
        <Col className='form-fields-col' xs={8} md={3} lg={2}>
          <Field
            name='transaction.reason_code' component={SelectInput}
            props={{
              label: I18n.t('registers.form.reason'),
              options: reasons,
              textKey: 'text',
              valueKey: 'value',
            }}/>
        </Col>
        <Col xs={8} md={3} lg={2}>
          <Field name='transaction.partner_paid_id' component={SelectInput} props={{
            label: I18n.t('registers.form.payee'),
            options: partners,
            textKey: 'name',
            valueKey: 'id'
          }}/>
        </Col>
      </Row>
      <Row className='form-fields-row currency'>
        <Col className='form-fields-col' xs={12} md={6} lg={4}>
          <Field
            name='transaction.notes' component={TextAreaInput}
            props={{
              label: I18n.t('registers.form.notes')
            }}/>
        </Col>
      </Row>
    </div>
  );
};

TransactionFields.propTypes = {
  partners: PropTypes.array.isRequired,
  reasons: PropTypes.array.isRequired
};

export default TransactionFields;
