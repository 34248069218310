import {createSelector} from 'reselect';
import keyBy from 'lodash.keyby';

import * as dataNames from '../constants/dataNames';

export const getPurposes = state => state[dataNames.purposes];

export const getPurposesHashMap = createSelector([getPurposes], purposes => keyBy(purposes, 'code'));

export const getPurposeByKey = (purposes, key) => {
  const purpose = purposes.find(purpose => purpose.code === key);
  return purpose ? purpose.name : '';
};
