import union from 'lodash.union';
import {I18n} from 'react-redux-i18n';
import {createSelector} from 'reselect';

import * as itemNames from '../constants/itemNames';

export const getOrderSettings = state => state[itemNames.orderSettings];
const getOrderProp = (_, props) => props.order;

const boolToString = v => String(Number(v));

export const getModifyAutoPriceInitialValues = createSelector(
  getOrderSettings,
  orderSettings => {
    if (!Object.keys(orderSettings).length) {
      return;
    }
    return {
      order_autoprice_allow: boolToString(orderSettings.order_autoprice_allow.value),
      order_autoprice_limit_to_pricing_groups: boolToString(orderSettings.order_autoprice_limit_to_pricing_groups.value),
      order_autoprice_limit_pricing_weights: boolToString(orderSettings.order_autoprice_limit_pricing_weights.value)
    };
  }
);

/*
  All payment types allowed by the Compliance setting should be enabled for changes + Payment types used in original payment (even if they are not enabled now)
 */
export const getPaymentModificationPaymentTypes = createSelector(
  [getOrderSettings, getOrderProp], (settings, order) => {
    if (settings && settings.order_select_payment_options && settings.order_select_payment_options.value
      && Array.isArray(settings.order_select_payment_options.value.options)) {
      const settingsOptions =  settings.order_select_payment_options.value.options;
      const orderOptions = order && Array.isArray(order.payments)
        ? order.payments.map(payment => payment.payment_type)
        : [];
      const options = union(settingsOptions, orderOptions).sort();
      return options.map(option => ({text: I18n.t(`cultivation.orderHistory.table.${option}`), value: option}));
    }
    return [];
  }
);