import React from 'react';
import {I18n} from 'react-redux-i18n';
import {Field, formValueSelector, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Button, ButtonToolbar, Col, Row} from 'react-bootstrap';
import {PRODUCT_LIST_FORM} from '../../../constants/forms';
import validate from './validate';
import TextInput from '../../common/form/TextInput';
import TextAreaInput from '../../common/form/TextAreaInput';
import DualListProductSelector from '../../common/DualListProductSelector';

const ProductListForm = (props) => {
  const {
    defaultSearchParams,
    change,
    submitting,
    valid,
    handleSubmit,
    initialValues,
    getFormValue,
    categories,
    vendors,
    selectedItemMasterIds,
    onSelectedItemMasterChange,
    isModify,
  } = props;

  // We can't use the pristine flag to determine if the form has changes (because we added a few control fields), so we check if any of the persisted fields are changed
  const productIds = getFormValue('products') ? getFormValue('products').map((product) => product.item_master_id) : [];
  const pristine =
    initialValues.name === getFormValue('name') &&
    initialValues.description === getFormValue('description') &&
    productIds.sort().join(',') === selectedItemMasterIds.sort().join(',');

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col md={4}>
          <Field name='name' component={TextInput} props={{
            label: I18n.t('retail.productList.form.name'),
            isRequired: true
          }}/>
        </Col>
      </Row>
      <Row>
        <Col md={12} >
          <Field
            component={TextAreaInput}
            name='description'
            style={{width:'100%', height: '200px'}}
            props={{
              label: I18n.t('retail.productList.form.description')
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12} >
          <DualListProductSelector
            change={change}
            getFormValue={getFormValue}
            defaultSearchParams={defaultSearchParams}
            onSelectedItemMasterChange={onSelectedItemMasterChange}
            selectedItemMasterIds={selectedItemMasterIds}
            categories={categories}
            vendors={vendors}
            formLabel={I18n.t('retail.productList.form.products')}
            isMandatory={true}
          />
        </Col>
      </Row>
      <Row className='submit-section' style={{marginTop: '20px'}}>
        <Col xs={12}>
          <div className='button-wrapper'>
            <ButtonToolbar className='float-right'>
              <Button variant='primary' type='submit' onClick={() => change('afterSubmit', 'redirect')}
                      disabled={!valid || pristine || submitting}>
                {I18n.t('common.form.saveAndReturn')}
              </Button>
              {isModify && <Button variant='primary' type='submit' onClick={() => change('afterSubmit', '')}
                      disabled={!valid || pristine || submitting}>
                {I18n.t('common.form.save')}
              </Button>}
            </ButtonToolbar>
          </div>
        </Col>
      </Row>
    </form>
  );
};

ProductListForm.propTypes = {
  defaultSearchParams: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  getFormValue: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  vendors: PropTypes.array.isRequired,
  selectedItemMasterIds: PropTypes.array.isRequired,
  onSelectedItemMasterChange: PropTypes.func.isRequired,
  isModify: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const selector = formValueSelector(PRODUCT_LIST_FORM);
  const getFormValue = (...names) => selector(state, ...names);
  return {
    getFormValue
  };
}

const ReduxProductListForm = reduxForm({
  form: PRODUCT_LIST_FORM,
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ProductListForm);

export default connect(mapStateToProps)(ReduxProductListForm);
