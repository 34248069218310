import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack, push} from 'react-router-redux';

import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import validate from './validate';
import {unsetData} from '../../../actions/dataActions';
import {setItem, unsetItem} from '../../../actions/itemActions';
import {handleSingleSelectedRow} from '../../../actions/helpers/selectedDataHelper';
import * as apiActions from '../../../actions/apiActions';
import {getIntegratedTransfersOptions, getMappingFormInitialValues, getInternalOrdersOptions, getItemMastersOptions,
  hasValidProductCounts} from '../../../selectors/supplyChainMappingSelectors';
import FormWrapper from '../../common/form/FormWrapper';
import SupplyChainMappingForm from './SupplyChainMappingForm'; // eslint-disable-line import/no-named-as-default
import {getIntegrationState} from '../../../selectors/integration/integrationSelectors';
import {getBiotrackUOMByInventoryType} from '../../../selectors/integration/biotrackSelectors';

export class SupplyChainMappingPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.title = `supplyChainMapping.title.${props.params.mode}`;

    this.fetchIntegratedTransfer = this.fetchIntegratedTransfer.bind(this);
    this.fetchIntegratedTransfers = this.fetchIntegratedTransfers.bind(this);
    this.fetchInternalOrders = this.fetchInternalOrders.bind(this);
    this.fetchInternalOrder = this.fetchInternalOrder.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount() {
    this.props.actions.unsetItem(itemNames.integratedTransfer);
    this.props.actions.unsetItem(itemNames.internalOrder);
    this.props.actions.unsetData(dataNames.itemMasters);
    this.fetchIntegratedTransfers();
    this.fetchInternalOrders();
  }

  componentWillUnmount() {
    this.props.actions.unsetItem(itemNames.integratedTransfer);
    this.props.actions.unsetItem(itemNames.internalOrder);
    this.props.actions.unsetData(dataNames.itemMasters);
  }

  fetchIntegratedTransfer(id) {
    this.props.actions.unsetItem(itemNames.integratedTransfer);
    if (id) {
      this.props.actions.getItem(
        `/api/integrated_transfers/incoming/${id}`,
        itemNames.integratedTransfer,
        {failed: 'supplyChainMapping.integratedTransfer.get.failed'}
      );
    }
  }

  fetchIntegratedTransfers() {
    this.props.actions.getUnpaginatedData(
      '/api/integrated_transfers/incoming',
      dataNames.integratedTransfers,
      {failed: 'supplyChainMapping.integratedTransfers.get.failed'}
    );
  }

  fetchInternalOrder(id) {
    this.props.actions.unsetItem(itemNames.internalOrder);
    this.props.actions.unsetData(dataNames.itemMasters);
    if (id) {
      const {params: {mode}} = this.props;
      const endpoint = mode === 'incoming' ? 'purchase_orders' : 'sales_orders';
      this.props.actions.getItem(
        `/api/${endpoint}/${id}`,
        itemNames.internalOrder,
        {failed: 'supplyChainMapping.internalOrder.get.failed'},
        {detailed: 1, include_linked_transfer: 1}
      ).then(() => {
        const {internalOrder, params: {mode}, isLeaf} = this.props;
        const dataName = mode === 'incoming' ? dataNames.purchaseOrders : dataNames.salesOrders;
        this.props.actions.handleSingleSelectedRow([internalOrder], dataName, true);
        const ids = (internalOrder && Array.isArray(internalOrder.lines) ? internalOrder.lines : []).reduce(
          (ids, line) => {
            //Handle pre-pack subitems for LEAF
            if (isLeaf && line && line.subitems && line.subitems.length) {
              return line.subitems.reduce(
                (ids, subitem) => subitem && subitem.qty > 0 ? ids.concat(subitem.item_master_id) : ids,
                ids
              );
            }

            return ids.concat(line.item_master_id);
          },
          []
        );
        if (ids.length) {
          this.props.actions.postData(
            '/api/item_masters/multiple',
            {
              ids,
              select_columns: [
                'id',
                'name',
                'default_uom',
              ]
            },
            dataNames.itemMasters,
            {failed: 'supplyChainMapping.itemMasters.get.failed'},
            {detailed: 1}
          );
        }
      });
    }
  }

  fetchInternalOrders() {
    const {params: {mode}, hasMetrcSettings} = this.props;
    const endpoint = mode === 'incoming' ? 'purchase_orders' : 'sales_orders';
    //Load medicated POs only for metrc integration
    const filters = mode === 'incoming' && hasMetrcSettings ? {received: 0, contains_medicated: 1} : null;
    this.props.actions.getUnpaginatedData(
      `/api/${endpoint}/active`,
      dataNames.internalOrders,
      {failed: 'supplyChainMapping.internalOrders.get.failed'},
      filters
    );
  }

  onSubmit(formValues) {
    const {params: {mode}} = this.props;
    const route = mode === 'incoming' ? `/inventory-receipts/create?po_num=${formValues.receipt_id}` : `/transfers/create?so_num=${formValues.receipt_id}`;
    this.props.actions.setItem(formValues, itemNames.supplyChainMapping);
    this.props.actions.push(route);
  }

  render(){
    const {integratedTransfersOptions, internalOrdersOptions, itemMasterOptions, initialValues, hasValidProductCounts,
      internalOrder, integrationState, uomByInventoryType} = this.props;

    return (
      <FormWrapper title={this.title} goBack={this.props.actions.goBack}>
        <SupplyChainMappingForm
          initialValues={initialValues}
          enableReinitialize={true}
          keepDirtyOnReinitialize={true}
          onSubmit={this.onSubmit}
          validate={validate}
          hasValidProductCounts={hasValidProductCounts}
          integrationState={integrationState}
          integratedTransfersOptions={integratedTransfersOptions}
          internalOrdersOptions={internalOrdersOptions}
          itemMasterOptions={itemMasterOptions}
          fetchIntegratedTransfer={this.fetchIntegratedTransfer}
          fetchInternalOrder={this.fetchInternalOrder}
          internalOrder={internalOrder}
          uomByInventoryType={uomByInventoryType}
        />
      </FormWrapper>
    );
  }
}

SupplyChainMappingPage.propTypes = {
  actions: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    setItem: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    handleSingleSelectedRow: PropTypes.func.isRequired
  }).isRequired,
  params: PropTypes.shape({
    mode: PropTypes.oneOf(['incoming', 'outgoing']).isRequired
  }).isRequired,
  integrationState: PropTypes.object.isRequired,
  integratedTransfersOptions: PropTypes.array.isRequired,
  internalOrder: PropTypes.object.isRequired,
  internalOrdersOptions: PropTypes.array.isRequired,
  itemMasterOptions: PropTypes.array.isRequired,
  initialValues: PropTypes.object.isRequired,
  hasValidProductCounts: PropTypes.bool.isRequired,
  hasMetrcSettings: PropTypes.bool.isRequired,
  isLeaf: PropTypes.bool,
  uomByInventoryType: PropTypes.object,
};

function mapStateToProps(state) {
  const {isLeaf, isMetrc: hasMetrcSettings, isBiotrack} = getIntegrationState(state);
  let uomByInventoryType;
  if (isBiotrack) uomByInventoryType = getBiotrackUOMByInventoryType(state);
  return {
    internalOrder: state.internalOrder,
    integrationState: getIntegrationState(state),
    integratedTransfersOptions: getIntegratedTransfersOptions(state),
    internalOrdersOptions: getInternalOrdersOptions(state),
    itemMasterOptions: getItemMastersOptions(state),
    initialValues: getMappingFormInitialValues(state),
    hasValidProductCounts: hasValidProductCounts(state),
    uomByInventoryType,
    hasMetrcSettings,
    isLeaf,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({},
    apiActions, {goBack, push, setItem, unsetData, unsetItem, handleSingleSelectedRow}
  );
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplyChainMappingPage);
