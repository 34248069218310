import {createSelector} from 'reselect';
import {getActiveFacility} from '../facilitiesSelectors';
import {getCurrentUser} from '../usersSelectors';
import {getCustomerGroups} from '../customerGroupsSelectors';

const getPermissionKey = (_, props) => props.permissionKey;

export const hasAdjustPermission = createSelector([getCurrentUser, getActiveFacility], (user, facility) => {
  return hasPermission(user, facility, 'adjust_reward_points');
});

export const hasViewPermission = createSelector([getCurrentUser, getActiveFacility], (user, facility) => {
  return hasPermission(user, facility, 'view_reward_points');
});

export const hasPermission = (user, facility, permissionKey) => {
  if(!facility || !facility.key) return false;
  return Boolean(user && user.permissions
    && user.permissions[facility.key]
    && user.permissions[facility.key].permissions
    && user.permissions[facility.key].permissions[permissionKey]);
};

export const userHasPermission = createSelector([getCurrentUser, getActiveFacility, getPermissionKey], (user, facility, permissionKey) => {
  if(!facility || !facility.key) return false;
  return Boolean(user && user.permissions
    && user.permissions[facility.key]
    && user.permissions[facility.key].permissions
    && user.permissions[facility.key].permissions[permissionKey]);
});

export const getRewardAssignableCustomerGroups = createSelector([getCustomerGroups], (allCustomerGroups) => {
  return allCustomerGroups.filter((group) => {
    return !group.is_rewards_program_group;
  });
});

export default userHasPermission;
