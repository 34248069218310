import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Row, Table, Card} from 'react-bootstrap';
import ContentConcealer from '../../../common/concealers/ContentConcealer';
import {getTranslationLabel} from '../../../../util/translationHelpers';

const getLabel = getTranslationLabel('batchHistory.model.');

const HarvestBatchCreatedDetails = ({model, integrationState}) => {

  return (
    <Card>
      <Row>
        <Col xs={6}>
          <h3>{I18n.t('batchHistory.model.harvestBatchDetails')}</h3>
          {
            !model.parent_batch_name
              ? null
              : <div>
              This harvest batch is the child of the parent batch shown below and was created by a split action on that batch.
            </div>
          }
          <Table striped className='item-details'>
            <thead>
            <tr>
              <th>{I18n.t('itemTransactions.field')}</th>
              <th>{I18n.t('itemTransactions.value')}</th>
            </tr>
            </thead>
            <tbody>
            {
              !model.parent_batch_name
                ? null
                : <tr>
                <td>{I18n.t('batchHistory.model.parentBatchName')}</td>
                <td>{model.parent_batch_name}</td>
              </tr>
            }
            <tr>
              <td>{I18n.t('batchHistory.model.batchName')}</td>
              <td>{model.batch_name}</td>
            </tr>
            <tr>
              <td>{I18n.t('batchHistory.model.strain')}</td>
              <td>{model.strain_name}</td>
            </tr>
            <tr>
              <td>{I18n.t('batchHistory.model.location')}</td>
              <td>{model.location_name}</td>
            </tr>
            <ContentConcealer show={Boolean(model.plants_count)}>
              <tr>
                <td>{I18n.t('batchHistory.model.plantCount')}</td>
                <td>{model.plants_count}</td>
              </tr>
            </ContentConcealer>
            <tr>
              <td>{getLabel('wetWeight', integrationState)}</td>
              <td>{model.wet_weight_harvest} {model.uom}</td>
            </tr>
            <ContentConcealer show={Boolean(integrationState.isWaLeaf && model.wet_other_material_weight)}>
              <tr>
                <td>{getLabel('wetOtherMaterialWeight', integrationState)}</td>
                <td>{model.wet_other_material_weight} {model.uom}</td>
              </tr>
            </ContentConcealer>
            <tr>
              <td>{I18n.t('batchHistory.model.pestResistance')}</td>
              <td>{model.rating_avg_pest_resistance}</td>
            </tr>
            <tr>
              <td>{I18n.t('batchHistory.model.mold')}</td>
              <td>{model.rating_avg_mold}</td>
            </tr>
            <tr>
              <td>{I18n.t('batchHistory.model.powderyMildew')}</td>
              <td>{model.rating_avg_mildew}</td>
            </tr>
            <tr>
              <td>{I18n.t('batchHistory.model.budRot')}</td>
              <td>{model.rating_avg_bud_rot}</td>
            </tr>
            <tr>
              <td>{I18n.t('batchHistory.model.stretching')}</td>
              <td>{model.rating_avg_stretching}</td>
            </tr>
            <tr>
              <td>{I18n.t('batchHistory.model.airyBuds')}</td>
              <td>{model.rating_avg_airy_buds}</td>
            </tr>
            <ContentConcealer show={Boolean(model.plants_waste)}>
              <tr>
                <td>{getLabel('wasteUponCreation', integrationState)}</td>
                <td>{Number(model.plants_waste).toFixed(2)} {model.uom}</td>
              </tr>
            </ContentConcealer>
            <ContentConcealer show={Boolean(model.plants_other_material_waste && integrationState.isWaLeaf)}>
              <tr>
                <td>{getLabel('otherMaterialWasteUponCreation', integrationState)}</td>
                <td>{Number(model.plants_other_material_waste).toFixed(2)} {model.uom}</td>
              </tr>
            </ContentConcealer>
            <ContentConcealer show={Boolean(model.external_id)}>
              <tr>
                <td>{I18n.t('harvestPackages.form.trackingId')}</td>
                <td>{model.external_id}</td>
              </tr>
            </ContentConcealer>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Card>
  );
};

HarvestBatchCreatedDetails.propTypes = {
  model: PropTypes.object.isRequired,
  integrationState: PropTypes.object.isRequired,
};

export default HarvestBatchCreatedDetails;
