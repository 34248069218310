import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field, reduxForm} from 'redux-form';
import {Modal, Row, Col, Alert} from 'react-bootstrap';
import ReactSelectInput from '../common/form/ReactSelectInput';
import TextAreaInput from '../common/form/TextAreaInput';
import TextInput from '../common/form/TextInput';
import SubmitSection from '../common/form/SubmitSection';
import {INVENTORY_SYNC_MJP_MODAL} from '../../constants/forms';

const InventorySyncMjpModal = (props) => {

  const {
    submitting, pristine, reset, handleSubmit, initialValues, integrator,
    showModal, hideModal, adjustMjpLevel, adjustmentReasons, errorType
  } = props;

  const settings = {
    actionSettings: {
      submit: {
        action: handleSubmit(adjustMjpLevel),
        text: I18n.t('inventory.sync.actions.completePackageSync'),
        pristine,
        submitting,
        style: 'primary'
      },
      reset: {
        action: reset,
        text: I18n.t('common.form.reset'),
        style: 'default',
        pristine
      },
    }
  };

  if(Object.keys(initialValues).length === 0){
    return <span/>;
  }

  return (
    <Modal show={showModal} className='inventory-sync-confirmation' onHide={hideModal}>

      <Modal.Header className='inventory-sync-modal-header-mjp text-center' closeButton>
        <h2>{I18n.t('inventory.sync.adjustMjp', {integrator})}</h2>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit(adjustMjpLevel)}>
          <Row>
            <Col className='text-center'>
              <h2>{`${(initialValues.transacted_qty < 0 ? '' : '+')}${initialValues.transacted_qty} ${initialValues.uom}`}</h2>
              <small className='text-muted'>
                {I18n.t('inventory.sync.message.text', {
                  action: (initialValues.transacted_qty < 0 ? I18n.t('inventory.sync.message.reduce') : I18n.t('inventory.sync.message.increase')),
                  target: I18n.t('inventory.sync.message.mjp'),
                  quantity: Math.abs(initialValues.transacted_qty) + ' ' + initialValues.uom}
                )}
              </small>
            </Col>
          </Row>
          {errorType !== undefined && <Alert variant='danger'>{I18n.t(`inventory.sync.error.${errorType}`)}</Alert>}
          <Row>
            <Col sm={2}>
              <Field
                name='transacted_qty'
                component={TextInput}
                type='hidden'
                props={{
                  disabled: true,
                }}
              />
              <Field
                name='item_id'
                component={TextInput}
                type='hidden'
                props={{
                  disabled: true,
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Field
                name='adjustment_reason'
                component={ReactSelectInput}
                props={{
                  label: I18n.t(`inventory.sync.table.columns.adjustment_reason`) + ':',
                  isRequired: true,
                  options: adjustmentReasons,
                  textKey: 'name',
                  valueKey: 'code',
                }}
              />
            </Col>
            <Col sm={6}>
              <Field
                name='notes'
                component={TextAreaInput}
                style={{width:'100%', height: '200px'}}
                props={{
                  label: I18n.t(`inventory.sync.table.columns.adjustment_notes`) + ':',
                  placeholder: I18n.t(`inventory.sync.table.columns.adjustment_notes_placeholder`)
                }}
              />
            </Col>
          </Row>
        </form>
      </Modal.Body>

      <Modal.Footer>
        <Row>
          <Col>
            <SubmitSection settings={settings} />
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

InventorySyncMjpModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  adjustmentReasons: PropTypes.array.isRequired,
  adjustMjpLevel: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  errorType: PropTypes.string,
  integrator: PropTypes.string,
};


export default reduxForm({
  form: INVENTORY_SYNC_MJP_MODAL, // a unique identifier for this form
})(InventorySyncMjpModal);
