import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Button} from 'react-bootstrap';

/**
 * Anonymous Order modal launch button
 */
const AnonymousOrderButton = ({onClick}) => {
  return (
    <Button
      variant='primary'
      onClick={onClick}
      style={{marginBottom: '8px'}}
      >
      {I18n.t('orders.anonymous.anonymousOrder')}
    </Button>
  );
};

AnonymousOrderButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default AnonymousOrderButton;
