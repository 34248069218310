import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { getItem, getUnpaginatedData } from '../../../actions/apiActions';
import { unsetItem } from '../../../actions/itemActions';
import { unionData } from '../../../actions/dataActions';
import * as dataNames from '../../../constants/dataNames';
import {getSupplyChainTransactions} from '../../../selectors/connectsSelectors';
import SimplePagination from '../../common/SimplePagination';

export class PartnerTransactions extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      partner: false,
      loadedCustomerData: false,
      pagination: {
        activePage: 3,
        pages: 10,
        perPage: 5,
      }

    };
    this.loadPartnerData = this.loadPartnerData.bind(this);
    this.onPageSelect = this.onPageSelect.bind(this);
    this.navigate = this.navigate.bind(this);
  }

  componentWillMount(){
    this.loadPartnerData(this.props);
  }

  componentWillReceiveProps(nextProps){
    this.loadPartnerData(nextProps);
    const pages = nextProps.transactions[this.props.partner.facility_id] ? nextProps.transactions[this.props.partner.facility_id].length / this.state.pagination.perPage : 0;
    if(pages !== this.state.pagination.pages){
      const pagination = Object.assign({}, this.state.pagination, {pages});
      this.setState({pagination});
    }
  }

  loadPartnerData(props){
    if(!props.loadData || this.state.loadedCustomerData) return true;
    this.setState({loadedCustomerData: true}, () => {
      this.props.actions.getUnpaginatedData(`/api/purchase_orders/active`, 'noOp', null, {vendor_facility_id: this.props.partner.facility_id})
        .then((purchaseOrders) => {
          this.props.actions.getUnpaginatedData(`/api/sales_orders/active`, 'noOp', null, {partner_facility_id: this.props.partner.facility_id})
            .then((salesOrders) => {
              const keyField = 'type_id';
              const poArray = purchaseOrders.map((po) => {
                po[keyField] = `po_${po.id}`;
                return po;
              });
              const soArray = salesOrders.map((so) => {
                so[keyField] = `so_${so.id}`;
                return so;
              });
              this.props.actions.unionData([].concat(poArray, soArray), dataNames.poAndSoTransactions, keyField);
            });
        });
    });
  }

  navigate(destination){
    const partnerId = this.props.partner.id.split(':').shift();
    const map = {
      inventory: `/partners/${partnerId}/inventory`,
      map_products: `/partners/${partnerId}/product_mapping`,
      modify: `/partners/modify/${partnerId}`,
    };
    this.props.actions.push(map[destination]);
  }

  onPageSelect(nextPage){
    this.setState({pagination: Object.assign({}, this.state.pagination, {activePage: nextPage})});
  }

  render() {
    return (
      <div>
        <h4>
          Transactions With Partner
        </h4>
        <div style={{height: '8px', clear: 'both'}} />
        <table className='table table-sm'>
          <thead>
            <tr>
              <th>Type</th>
              <th>Number</th>
              <th>Date</th>
              <th style={{width: '10%', textAlign: 'right'}}>Value</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
          {
            this.props.transactions[193]
              ? this.props.transactions[193].map((order, index) => {
                return (<tr key={index}>
                  <td>{order.type}</td>
                  <td>{order.number}</td>
                  <td>{order.date_ordered}</td>
                  <td style={{textAlign: 'right'}}>{order.total}</td>
                  <td>Details</td>
                </tr>);
              })
              : <tr><td colSpan={5}>No Active Transactions Found {this.props.partner.facility_id}</td></tr>

          }

          </tbody>
        </table>
        <SimplePagination
          bsSize='medium'
          items={this.state.pagination.pages}
          activePage={this.state.pagination.activePage}
          onSelect={this.onPageSelect}
          maxButtons={5}
          first={true}
          last={true}
          next={true}
          prev={true}
        />
      </div>
    );
  }
}

PartnerTransactions.propTypes = {
  loadData: PropTypes.bool.isRequired,
  activeTab: PropTypes.string.isRequired,
  partner: PropTypes.object.isRequired,
};


function mapStateToProps(state) {
  return {
    transactions: getSupplyChainTransactions(state),
    poAndSoTransactions: state[dataNames.poAndSoTransactions],
  };
}

function mapDispatchToProps(dispatch) {
  const actions = bindActionCreators({unsetItem, getItem, getUnpaginatedData, unionData, push}, dispatch);
  return {
    actions
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (PartnerTransactions);
