import round from 'lodash.round';
import {I18n} from 'react-redux-i18n';
import {requiredFieldValidation} from '../../../common/form/redux-form/validations';

const getPaymentsTotal = payments => payments.reduce(
  (total, payment) => {     
    let amount = parseFloat(payment.amount);
    amount = amount < 0 ? 0 : amount;
    return amount ? round(total + amount, 2) : total;
  }, 0);

const validatePayments = (values, props) => {
  const errors = {payments: []};
  if (Array.isArray(values.payments)) {
    const paymentsTotal = getPaymentsTotal(values.payments);
    const orderTotal = (props.order && props.order.order_total) || 0;
    const notEnoughPayment = orderTotal > paymentsTotal;
    values.payments.forEach(payment => {
      const paymentErrors = {
        amount: notEnoughPayment
          ? I18n.t('cultivation.orderHistory.table.notEnoughPayment', {orderTotal})
          : requiredFieldValidation(payment.amount),
        payment_type: requiredFieldValidation(payment.payment_type),
        register_id: requiredFieldValidation(payment.register_id)
      };
      errors.payments.push(paymentErrors);
    });
  }
  return errors;
};

export default validatePayments;