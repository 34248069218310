import {createSelector} from 'reselect';

const getPageSize = (_, props) => props.size;
const getMeta = (state, props) => state.meta[props.name];

export const getPaginationFromSearch = createSelector(
  [getMeta, getPageSize],
  (search, size) => {
    const pagination = {last_page: null, current_page: null};
    if (search && size) {
      const {found, start} = search;
      pagination.last_page = Math.ceil(found / size);
      pagination.current_page = Math.floor(start / size) + 1;
    }
    return pagination;
  }
);

export const getTotalResults = createSelector(
  [getMeta], (meta) =>
    meta && meta.pagination ? meta.pagination.total : 0
);

export default getPaginationFromSearch;
