import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import moment from 'moment-timezone';
import { I18n } from 'react-redux-i18n';

const IntegrationTrackingStatusModalTimer = ({ createdAt, itemId, handleRevertSplit }) => {
  const [currentTime, setCurrentTime] = useState(moment());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const createdAtMoment = moment.utc(createdAt).local();
  const timeDifference = currentTime.diff(createdAtMoment, 'seconds');
  const hours = Math.floor(timeDifference / 3600) % 24;
  const minutes = Math.floor(timeDifference / 60) % 60;
  const seconds = timeDifference % 60;
  const isButtonClickable = timeDifference >= 600;
  const countdownSeconds = 600 - timeDifference;
  const countdownMinutes = Math.floor(countdownSeconds / 60);
  const countdownSec = Math.floor(countdownSeconds % 60);

  const packageCreatedMessage = I18n.t('integration.transactions.trackingIdStatuses.pendingIntegrator.packageCreated', { hours, minutes, seconds });

  const buttonTitle = isButtonClickable
    ? I18n.t('integration.transactions.trackingIdStatuses.pendingIntegrator.revertSplit')
    : I18n.t('integration.transactions.trackingIdStatuses.pendingIntegrator.revertSplitCountdown', { minutes: countdownMinutes, seconds: countdownSec < 10 ? `0${countdownSec}` : countdownSec });

  return (
    <div>
      <div>{packageCreatedMessage}</div>
      <Button disabled={!isButtonClickable} onClick={() => handleRevertSplit(itemId)}>{buttonTitle}</Button>
    </div>
  );
};

export default IntegrationTrackingStatusModalTimer;
