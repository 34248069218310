import moment from 'moment';

export default [
  {
    name: 'ordersImport.viewTable.saleDateAndTime',
    dataId:'saleDateAndTime',
    hidden: false,
    formatter: cell => {
      if (cell) {
        return moment(cell).format('YYYY-MM-DD hh:mm A');
      }
      return cell;
    }
  },
  {
    name: 'ordersImport.viewTable.orderNumber',
    dataId:'orderNumber',
    hidden: false,
  },
  {
    name: 'ordersImport.viewTable.customerNumber',
    dataId:'customerNumber',
    hidden: false,
  },
  {
    name: 'ordersImport.viewTable.driverLicense',
    dataId:'driverLicense',
    hidden: false,
  },
  {
    name: 'ordersImport.viewTable.medicalId',
    dataId:'medicalId',
    hidden: false,
  },
  {
    name: 'ordersImport.viewTable.totalOrderPrice',
    dataId:'totalOrderPrice',
    hidden: false,
    formatter: cell => '$' + cell,
  },
  {
    name: 'ordersImport.viewTable.salesAssociate',
    dataId:'salesAssociate',
    hidden: false,
  },

];
