/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack} from 'react-router-redux';
import omit from 'lodash.omit';
import get from 'lodash.get';
import * as apiActions from '../../actions/apiActions';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import FormWrapper from '../common/form/FormWrapper';
import ReduxCreateRegisterForm from './ReduxCreateRegisterForm';
import {unsetItem} from '../../actions/itemActions';
import isRetailFacility from '../../selectors/facility/isRetailFacility';
import {setUserRetailRegister} from '../../actions/userActions';
import ModalWrapper from '../common/ModalWrapper';
import {doNothing} from '../../util/callbackHelpers';
import {getAlleavesRegisterIds, isAlleavesIntegrator, isPosabitIntegrator} from '../../selectors/integration/thirdPartyIntegrationSelectors';
import {getCurrentUser} from '../../selectors/usersSelectors';
import {isFeatureEnabled} from '../../selectors/featureToggles';
import * as messageTypes from '../../constants/messageTypes';
import {addMessage} from '../../actions/systemActions';

export class CreateRegisterPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.verifyTerminalId = this.verifyTerminalId.bind(this);
    this.setShowIsDefaultFlag = this.setShowIsDefaultFlag.bind(this);
    this.renderConfirmModal = this.renderConfirmModal.bind(this);
    this.showConfirmationPopup = this.showConfirmationPopup.bind(this);
    this.validateDefaultRegister = this.validateDefaultRegister.bind(this);
    this.handlePosabitClick = this.handlePosabitClick.bind(this);

    this.state = {
      show_is_default: false,
      showConfirmation: {}
    };
  }

  componentDidMount() {
    this.props.actions.getUnpaginatedData(
      '/api/registers',
      dataNames.registers,
      {failed: 'registers.getRegisters.fail'},
      {detailed:1, active:1});
  }

  componentWillUnmount() {
    this.props.actions.unsetItem(itemNames.register);
  }

  handlePosabitClick() {
    const {user} = this.props;
    const payload = {
      type: 'posabit_referral',
      entity_type: 'user',
      action: 'User click POSaBIT referral link.',
      entity_id: user.id
    };

    this.props.actions.postItem('/api/audit_logs',
      payload).then(() => {
        window.open('https://www.posabit.com', '_blank');
      }
    ).catch(() => {});
  }

  onSubmit(formData, dispatch) {
    const register = Object.assign({}, omit(formData, 'collects_from_ids'), {
      collects_from_ids: formData.collects_from_ids.map(register => register.id)
    });

    this.validateDefaultRegister(register)
      .then(async () => {
        try {
          if (get(register, 'terminal_id')) {
            await this.verifyTerminalId(register.terminal_id);
          }
          this.props.actions.postItem('/api/registers', register, itemNames.register, {success: 'registers.create.success', failed: 'registers.create.fail'})
            .then((response) => register.is_default_for_pos && this.props.actions.setUserRetailRegister(response.id))
            .then(this.props.actions.goBack)
            .catch(doNothing);
        } catch (err) {
          doNothing();
        }
      });
  }

  verifyTerminalId(terminalId) {
    return new Promise((resolve, reject) => {
      this.props.actions.getItem('/api/posabit/terminal_status', null, {failed: 'registers.posabit_terminal_id_invalid'}, {terminal_id: terminalId})
        .then((response) => {
          if (get(response, 'status') === 'online' || get(response, 'status') === 'offline') {
            resolve();
          }
          else {
            this.props.actions.addMessage(messageTypes.error, 'registers.posabit_terminal_id_invalid');
            reject();
          }
        })
        .catch((err) => {
          reject();
        });
    });
  }

  setShowIsDefaultFlag(event) {
    this.setState({show_is_default: (event.target.value === 'sales')});
  }

  renderConfirmModal() {
    const { showConfirmation } = this.state;

    const okayButtonProps = {
      show: true,
      onClick: showConfirmation.onConfirm,
      text: I18n.t('general.yes')
    };

    const cancelButtonProps = {
      show: true,
      onClick: showConfirmation.onHide,
      text: I18n.t('general.no')
    };

    return (
      <ModalWrapper
        Component={false}
        title={I18n.t('registers.confirmationModal.switchRegisterTitle')}
        headerClass='bg-info-dark'
        onHide={showConfirmation.onHide}
        showModal={showConfirmation.show}
        okayButton={okayButtonProps}
        cancelButton={cancelButtonProps}
        dialogClassName='modal-sm'
        version={2}
      >
        <p>{I18n.t('registers.confirmationModal.switchRegisterBody')}</p>
      </ModalWrapper>
    );
  }

  showConfirmationPopup(resolve, reject) {
    const hidePopup = (cb) => () => {
      this.setState({
        showConfirmation: { show: false },
      });
      cb();
    };
    this.setState({
      showConfirmation: {
        show: true,
        onConfirm: hidePopup(resolve),
        onHide: hidePopup(reject),
      }
    });
  }

  validateDefaultRegister(payload) {
    const {currentRegister} = this.props;
    return new Promise((resolve, reject) => {
      const next = () => resolve();
      if (!payload.is_default_for_pos || !currentRegister) {
        return next();
      }
      return this.showConfirmationPopup(next, reject);
    });
  }

  render() {
    const {registers, isRetailFacility, isPosabitIntegrator, isPosabitIntegrationEnabled, isAlleavesIntegrator, isAlleavesIntegrationEnabled, alleavesRegisterIds} = this.props;
    const {goBack} = this.props.actions;
    const {show_is_default} = this.state;
    const initialValues = {
      collects_from_ids: [],
      alert_above: '',
      alert_below: ''
    };
    const statuses = [
      {text: I18n.t('common.form.selectPlaceholder'), value: ''},
      {text: I18n.t('partners.createPartner.Active'), value: 1},
      {text: I18n.t('partners.createPartner.Inactive'), value: 0}
    ];
    const registerTypes = [
      {value: '', text: I18n.t('common.form.selectPlaceholder')},
      {value: 'ap_ar', text: I18n.t('registers.form.accountsPayableReceivable')},
      {value: 'vault', text: I18n.t('registers.form.vault')},
      ...(isRetailFacility ? [{value: 'sales', text: I18n.t('registers.form.salesTransactions')}] : []),
    ];

    return (
      <div>
        <FormWrapper title={'registers.create.title'} goBack={goBack}>
          <ReduxCreateRegisterForm
            initialValues={initialValues}
            registers={registers}
            registerTypes={registerTypes}
            statuses={statuses}
            onSubmit={this.onSubmit}
            setShowIsDefaultFlag={this.setShowIsDefaultFlag}
            handlePosabitClick={this.handlePosabitClick}
            showIsDefault={show_is_default}
            isPosabitIntegrator={isPosabitIntegrator}
            isPosabitIntegrationEnabled={isPosabitIntegrationEnabled}
            isAlleavesIntegrator={isAlleavesIntegrator}
            isAlleavesIntegrationEnabled={isAlleavesIntegrationEnabled}
            alleavesRegisterIds={alleavesRegisterIds}
          />
        </FormWrapper>
        {this.renderConfirmModal()}
      </div>);
  }
}

CreateRegisterPage.propTypes = {
  actions: PropTypes.object.isRequired,
  registers: PropTypes.array.isRequired,
  isRetailFacility: PropTypes.bool,
  currentRegister: PropTypes.number,
  isPosabitIntegrator: PropTypes.bool,
  isPosabitIntegrationEnabled: PropTypes.bool.isRequired,
  isAlleavesIntegrationEnabled: PropTypes.bool.isRequired,
  isAlleavesIntegrator: PropTypes.bool,
  alleavesRegisterIds: PropTypes.array,
};

function mapStateToProps(state, ownProps) {
  const {registers, user: {currentRegister}} = state;
  return {
    registers,
    isRetailFacility: isRetailFacility(state),
    currentRegister,
    user: getCurrentUser(state),
    isPosabitIntegrator: isPosabitIntegrator(state),
    isPosabitIntegrationEnabled: isFeatureEnabled(state)('feature_posabit_payment_integration'),
    isAlleavesIntegrator: isAlleavesIntegrator(state),
    isAlleavesIntegrationEnabled: isFeatureEnabled(state)('feature_alleaves_payment_integration'),
    alleavesRegisterIds: getAlleavesRegisterIds(state)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {goBack, addMessage, unsetItem, setUserRetailRegister});
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateRegisterPage);
