import {change, formValueSelector} from 'redux-form';
import get from 'lodash.get';
import {COMPLETE_PROCESSING_FORM} from '../../constants/forms';
import {BT_CANNABIS_MIX} from '../../constants/integration/biotrack/biotrackInventoryTypes';
import {isFormChangeOnField} from './utils';
import {getIntegrationState} from '../../selectors/integration/integrationSelectors';
import {getOutputItemMaster} from '../../selectors/forms/completeProcessingFormSelectors';
import {getItemMasterPackageId} from '../../actions/itemMasterActions';
import {getIsLabelEnabledForNoCompletedProcess} from '../../selectors/complianceSettingsSelectors';
import * as UOMS from '../../constants/uoms';

const completeProcessingForm = store => next => action => {
  const result = next(action);
  const {meta, payload} = action;
  if (isFormChangeOnField(action, [COMPLETE_PROCESSING_FORM], 'item_master_id', true)) {
    const selector = formValueSelector(meta.form);
    const regex = /outputs\[(\d+)]\.item_master_id/;
    const match = regex.exec(action.meta.field);
    const index = match && match[1];
    const key = meta.field.split('.').shift();

    const state = store.getState();
    const product = getOutputItemMaster(state, {index});
    const uom = get(product, 'default_uom', null);

    store.dispatch(change(meta.form, `${key}.uom`, uom));
    store.dispatch(change(meta.form, `${key}.qty`, null));
    if(uom && product) {
      // These are weight fields so EA should use GR to start unless already set
      const useUom = uom === UOMS.EA ? UOMS.GR : uom;
      const setUomIfNotSet = (field) => {
        const uom = selector(state, field);
        if(!uom || typeof uom === 'string' && uom.trim() === ''){
          store.dispatch(change(meta.form, field, useUom));
        }
      };
      setUomIfNotSet(`${key}.usable_mmj_weight_uom`);
      setUomIfNotSet(`${key}.concentrate_weight_uom`);
    }

    if(!product){ // Reset if product deselected
      store.dispatch(change(meta.form, `${key}.usable_mmj_weight_uom`, UOMS.GR));
      store.dispatch(change(meta.form, `${key}.concentrate_weight_uom`, UOMS.GR));
    }

    if (getIsLabelEnabledForNoCompletedProcess(state)) {
      if (payload) {
        store.dispatch(getItemMasterPackageId(payload, key))
          .then(({packageId}) => store.dispatch(change(meta.form, `${key}.package_code`, packageId)));
      } else {
        store.dispatch(change(meta.form, `${key}.package_code`, ''));
      }
    }
  }

  if (isFormChangeOnField(action, [COMPLETE_PROCESSING_FORM], 'integration_type', true)) {

    const state = store.getState();
    const {isBiotrack} = getIntegrationState(state);

    if (isBiotrack) {
      if (payload === BT_CANNABIS_MIX) {
        store.dispatch(change(meta.form, 'is_cannabis_mix', true));
        store.dispatch(change(meta.form, 'waste', ''));
      } else {
        store.dispatch(change(meta.form, 'is_cannabis_mix', false));
      }
    }
  }

  return result;
};

export default completeProcessingForm;
