/* eslint-disable import/prefer-default-export*/
import {LOCATION_CHANGE, goBack, replace} from 'react-router-redux';
import {closeCartDetails, openUserOptsMenu} from '../actions/menuActions';
import {addMessage} from '../actions/systemActions';
import {putItem, getItem} from '../actions/apiActions';
import {setItem} from '../actions/itemActions';
import * as actionTypes from '../constants/actionTypes';
import * as dataNames from '../constants/dataNames';
import * as itemNames from '../constants/itemNames';
import {userNeedsRegistersLocations} from '../selectors/usersSelectors';
import {getOrder, isOrderFulfilled} from '../selectors/ordersSelectors';
import *  as p from '../constants/permissions';

export const checkCartDrawer = store => next => action => {
  const result = next(action);
  if(action.type === LOCATION_CHANGE){
    //Cart should always be closed on location change
    store.dispatch(closeCartDetails());
  }
  return result;
};

export const checkRegisterLocation = store => next => action => {
  const result = next(action);
  if (action.type === LOCATION_CHANGE) {
    const {payload} = action;
    const state = store.getState();
    if(payload.pathname.startsWith('/queue') || payload.pathname.startsWith('/product-menu') || payload.pathname.startsWith('/cart')){
      if(userNeedsRegistersLocations(state, {permissions: [p.view_registers]})) {
        store.dispatch(goBack());
        store.dispatch(openUserOptsMenu());
        if(state.salesLocations && !state.user.currentLocation){
          store.dispatch(addMessage('warning', ['error.selectLocations']));
        }
        if(state.salesRegisters && !state.user.currentRegister){
          store.dispatch(addMessage('warning', ['error.selectRegisters']));
        }
      }
    } else if(payload.pathname.endsWith('/payment')) {
      const id = parseInt(payload.pathname.replace(/\D/g, ''));
      const order = getOrder(state);
      const orderId = order && order.id ? order.id : 0;
      if (!orderId || !id || id !== orderId) {
        store.dispatch(addMessage('warning', ['error.unableToTakePayment']));
        return store.dispatch(replace('/'));
      } else {
        // Customers queue includes an order derived fulfillment_status from the BE
        const customersQueue = state[dataNames.customersQueue];
        const orderFromQueue = Array.isArray(customersQueue) ? customersQueue.find((q) => q.order_id === orderId) : false;
        const orderIsBeingPaid = () => {
          return (order && orderFromQueue && order.id === orderFromQueue.id && order.payments && order.payments.length);
        };

        if(!orderIsBeingPaid()) { // this might just be a temp change because some of the packaging workflow isn't in place yet?
          if ((orderFromQueue && orderFromQueue.fulfillment_status !== 'assigned' && !isOrderFulfilled(state)) || (!orderFromQueue && !isOrderFulfilled(state))) {
            store.dispatch(addMessage('warning', ['error.orderNeedsFulfilled']));
            return store.dispatch(replace('/cart'));
          }
        }
      }
    }
  }

  return result;
};

/***
 * Set the fulfillment_status flag to assigned or pending based on the result of isOrderFulfilled selector.
 * Run on Get/Put actions related to store.  Passes a mutated state object to use the call data directly
 * in the isOrderFulfilled selector - otherwise the setTimeout leg would have to be used.
 * @param store
 */
export const setOrderFulfillmentStatus = store => next => action => {

  const canGetStateFromOrder = (order) => {
    if (!order || !Array.isArray(order.products)) return false;
    if (typeof order !== 'object') return false;
    if (order.id === undefined) return false;
    return true;
  };

  const canUpdateOrder = (order) => {
    return (!order.locked);
  };

  const getFulfillmentState = (data) => {
    if(data) {
      setTimeout(() => {
        const state = Object.assign({}, store.getState(), {order: data}); // This is probably an anti-pattern
        const order = state[itemNames.order];
        if(!canGetStateFromOrder(order)) return false;
        if(order.fulfillment_method === 'delivery' && !order.delivery_address_id) return false;
        const s = isOrderFulfilled(state) ? 'assigned' : 'pending';
        if ((order.fulfillment_status !== 'ready' || s === 'assigned') && s !== order.fulfillment_status && canUpdateOrder(order)) {
          store.dispatch(getItem('/api/queue/consumers_count', itemNames.orderTypeCounts, {
            failed: 'header.orderTypeCounts.failed'
          }));
        }
        return true;
      }, 1000);
    } else { // occurs on cancel
      setTimeout(() => {
        const state = store.getState();
        const order = state[itemNames.order];
        if(!canGetStateFromOrder(order)) return false;
        const s = isOrderFulfilled(state) ? 'assigned' : 'pending';
        if ((order.fulfillment_status !== 'ready' || s === 'assigned') && s !== order.fulfillment_status && canUpdateOrder(order)) {
          const updatedOrder = Object.assign({}, order, {fulfillment_status: s});
          store.dispatch(setItem(updatedOrder, itemNames.order));
          store.dispatch(putItem(`/api/orders/${order.id}`, {fulfillment_status: s}, null, null, {}, () => {
            store.dispatch(getItem('/api/queue/consumers_count', itemNames.orderTypeCounts, {
              failed: 'header.orderTypeCounts.failed'
            }));
          }));
        }
      }, 1000);

    }

  };

  const handleActionTypes = [actionTypes.GET_ITEM_SUCCESS, actionTypes.EDIT_ITEM_SUCCESS, actionTypes.ADD_ITEM_SUCCESS];

  if(action.name === itemNames.order && handleActionTypes.indexOf(action.type) !== -1) {
    const data = (action.type === actionTypes.GET_ITEM_SUCCESS)
      ? action.payload.data
      : action.type === actionTypes.EDIT_ITEM_SUCCESS
        ? action.payload.data
          ? action.payload.data
          : false
        : false;
    
    getFulfillmentState(data);

  }

  return next(action);

};

/***
 * Traps error responses from updating an order quantity or adding a product where compliance throws an error
 * and then populates orderComplianceError which is consumed by customerLimitSelectors when building rules display.
 * Is specific to rules for oregon rec.  Does not apply for any others at present (1/10/2018).
 * @param store
 */
export const dispatchSalesComplianceError = store => next => action => {

  const handleActionTypes = [actionTypes.ADD_ITEM_FAILED, actionTypes.EDIT_ITEM_FAILED, actionTypes.ADD_ITEM_SUCCESS, actionTypes.EDIT_ITEM_SUCCESS];
  const handleStore = [itemNames.order];

  if(handleStore.indexOf(action.name) !== -1 && handleActionTypes.indexOf(action.type) !== -1){
    const dispatchPayload = (action.type.indexOf('FAILED') !== -1) ? action.payload : {};
    store.dispatch(setItem(dispatchPayload, itemNames.orderComplianceError));
  }

  return next(action);

};
