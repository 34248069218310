import first from 'lodash.first';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { bindActionCreators } from 'redux';

import { Button } from 'react-bootstrap';

import * as dataNames from '../../../constants/dataNames';
import * as statuses from '../../../constants/statuses';
import { getUnpaginatedData, postData } from '../../../actions/apiActions';
import { removeData } from '../../../actions/dataActions';
import { setSelectedData, clearSelectedData } from '../../../actions/selectedDataActions';
import { getActiveAssemblyJobs } from '../../../selectors/assemblyJobsSelectors';

import ModalWrapper from '../../common/ModalWrapper';

import PageTitle from '../../common/PageTitle';
import TablePageWrapper from '../../common/grid/TablePageWrapper';
import DetailsPanel from './common/DetailsPanel';

import InternationalDateStatic from '../../common/form/InternationalDateStatic';

export class ActiveInfusionsPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);

    const selectedActiveInfusion = [];
    this.ref = React.createRef();
    const columns = [
      {
        name: 'ei.activeProcessing.table.id',
        dataId: 'id',
        hidden: true,
        width: '60px'
      },
      {
        name: 'ei.activeProcessing.table.name',
        dataId: 'name',
        hidden: false,
        width: '60px'
      },
      {
        name: 'ei.activeProcessing.table.dateStarted',
        dataId: 'start_time',
        hidden: false,
        width: '60px',
        formatter: (cell) => <InternationalDateStatic>{cell}</InternationalDateStatic>,
      },
      {
        name: 'ei.activeProcessing.table.lotID',
        dataId: 'inputs',
        hidden: false,
        dataAlign: 'center',
        width: '60px',
        /* eslint-disable react/no-multi-comp */
        formatter: (cell, row) => {
          return row.lotList && row.lotList.length ? (
            <Button variant='primary' bsSize='xsmall' onClick={event => this.openViewsModal(event, row.lotList, I18n.t('ei.activeProcessing.viewLots'))}>
              {I18n.t('ie.activeProcessing.view')}
            </Button>
          ) : '-';
        }
      },
      {
        dataId: 'lotIdRow',
        hidden: true,
      },
      {
        name: 'ei.activeProcessing.table.expectedCompletion',
        dataId: 'expected_completion_time',
        hidden: false,
        width: '50px',
        /* eslint-disable react/no-multi-comp */
        formatter: (cell) => <InternationalDateStatic>{cell}</InternationalDateStatic>
      },
      {
        name: 'ei.activeInfusions.table.assemblyName',
        dataId: 'process_type',
        hidden: false,
        width: '50px'
      },
      {
        name: 'ei.activeProcessing.table.employees',
        dataId: 'employees',
        hidden: false,
        width: '50px',
        formatter: (cell, row) => { //eslint-disable-line react/no-multi-comp
          return row.employeeList && row.employeeList.length ? (
            <Button variant='primary' bsSize='xsmall' onClick={event => this.openViewsModal(event, row.employeeList, I18n.t('ei.activeProcessing.viewEmployees'))}>
              {I18n.t('ie.activeProcessing.view')}
            </Button>
          ) : '-';
        }
      }
    ];

    this.state = { columns, selectedActiveInfusion, showViewsModal: false, viewData: [], viewTitle: '' };

    this.handleSelect = this.handleSelect.bind(this);
    this.cancelInfusion = this.cancelInfusion.bind(this);
    this.openViewsModal = this.openViewsModal.bind(this);
  }

  componentWillMount() {
    const { actions: { getUnpaginatedData } } = this.props;

    Promise.all([
      getUnpaginatedData('/api/assembly_jobs', dataNames.assemblyJobs, { failed: 'ei.activeInfusions.failedLoadAssemblyJobs' }, { detailed: 1, status: statuses.open }),
      getUnpaginatedData('/api/users/current_facility', dataNames.currentFacilityUsers, { failed: 'ei.activeProcessing.failedLoadUsers' }),
      getUnpaginatedData('/api/assemblies', dataNames.assemblies),
    ]);
  }

  handleSelect(id, isSelected, row) {
    this.setState({ selectedActiveInfusion: isSelected ? [id] : [] });
    const {clearSelectedData, setSelectedData} = this.props.actions;
    if (isSelected) {
      setSelectedData(row.inputs.map(i => ({...i, id: i.item_id})), dataNames.products);
    } else {
      clearSelectedData(dataNames.products);
    }
  }

  openViewsModal(event, row, title) {
    event.stopPropagation();

    this.setState({ showViewsModal: true, viewData: row, viewTitle: title });
  }

  cancelInfusion(event) {
    const selectedId = first(this.state.selectedActiveInfusion);

    this.props.actions.postData(
      `/api/assembly_jobs/${selectedId}/cancel`,
      {},
      dataNames.assemblyJobs,
      { success: 'ei.activeInfusions.form.cancelSuccess', failed: 'ei.activeInfusions.form.cancelFail' },
      null,
      (value) => {
        this.setState({ selectedActiveInfusion: [] });
        this.props.actions.removeData([value], dataNames.assemblyJobs, 'id');
      }
    );
  }

  render() {
    const { activeInfusionsList } = this.props;
    const { columns, showViewsModal, viewData, viewTitle } = this.state;
    const selectedId = first(this.state.selectedActiveInfusion) || 0;
    const actions = [
      { id: 'completeInfusion', path: `/ei/infusions/complete/${selectedId}`, text: 'ei.activeInfusions.actions.completeProcessing', requireSelect: true },
      { id: 'modifyLot', path: '/packages/modify', text: 'ei.activeProcessing.actions.modifyLot', requireSelect: true },
      { id: 'cancelInfusion', path: `/ei/infusions/complete/${selectedId}`, func: this.cancelInfusion, text: 'ei.activeInfusions.actions.cancelProcessing', variant: 'danger', requireSelect: true },
    ];

    return (
      <div className='active-infusions-page'>
        <PageTitle primaryText={I18n.t('ei.activeInfusions.title')} />
        <ModalWrapper
          Component={DetailsPanel}
          onHide={() => this.setState({ showViewsModal: false })}
          showModal={showViewsModal}
          title={viewTitle}
          data={viewData}
        />
        <TablePageWrapper
          ref={this.ref}
          settingKey='ei-active-infusions'
          columns={columns}
          data={activeInfusionsList}
          handleSelect={this.handleSelect}
          selectedRows={this.state.selectedActiveInfusion}
          actions={actions}
          hideScanSearch={true}
          hideExport={true}
          showHistory={true}
          className='active-infusions-table'
          bstProps={{
            selectRow: {
              mode: 'radio',
              clickToSelect: true,
              selected: this.state.selectedActiveInfusion,
              onSelect: (row, isSelected) => this.handleSelect(row.id, isSelected, row)
            },
            options: {
              hideSizePerPage: true,
              onRowClick: row => { }
            },
            expandableRow: () => false
          }}
        />
      </div>
    );
  }
}

ActiveInfusionsPage.propTypes = {
  activeInfusionsList: PropTypes.array,
  timezone: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    getUnpaginatedData: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    removeData: PropTypes.func.isRequired,
    setSelectedData: PropTypes.func.isRequired,
    clearSelectedData: PropTypes.func.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    activeInfusionsList: getActiveAssemblyJobs(state),
    timezone: state.timezone
  };
}

function mapDispatchToProps(dispatch) {
  const actions = { getUnpaginatedData, postData, removeData, setSelectedData, clearSelectedData };
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActiveInfusionsPage);
