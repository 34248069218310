import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {formValueSelector} from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {push,goBack} from 'react-router-redux';
import * as apiActions from '../../../../../actions/apiActions';
import {getUserValid} from '../../../../../actions/userActions';
import {setItem, unsetItem} from '../../../../../actions/itemActions';
import * as itemNames from '../../../../../constants/itemNames';
import * as dataNames from '../../../../../constants/dataNames';
import {getPrintServerPayload} from '../../../../../selectors/labelsSelectors';
import {getPrintServerInitialValues} from '../../../../../selectors/forms/printServerFormSelectors';
import {PRINT_SERVER_FORM} from '../../../../../constants/forms';
import {importLocalPrinters} from '../../../../../actions/printerActions';
import Form from './Form'; //eslint-disable-line
import ModalWrapper from '../../../../common/ModalWrapper';

export class PrintServerEditor extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.onImportPrinters = this.onImportPrinters.bind(this);
    this.onReleaseMachine = this.onReleaseMachine.bind(this);
    this.onDeletePrintServer = this.onDeletePrintServer.bind(this);
    this.onDeleteAllPrintServers = this.onDeleteAllPrintServers.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onAction = this.onAction.bind(this);
    this.getPayload = this.getPayload.bind(this);
    this.state = {showModal: false, modalText: '', action: false};
  }

  componentWillMount(){
    if(Object.keys(this.props.complianceSettings).length === 0){
      this.props.actions.getItem('/api/labels/compliance_settings', itemNames.labelsCompliance);
    }
  }

  onAction(actionType){
    const map = {
      release: 'onReleaseMachine',
      del: 'onDeletePrintServer',
      delAll: 'onDeleteAllPrintServers',
      setLocal: 'onSetMachineLocal',
    };
    this[map[actionType]]();
  }

  getPayload(values, action = false){
    return new Promise((resolve, reject) => {
      this.props.actions.getItem('/api/labels/compliance_settings') // Always update against fresh data
        .then((labelsCompliance) => {
          resolve(getPrintServerPayload(values, labelsCompliance, action));
        });
    });
  }

  onReleaseMachine(){
    this.setState({showModal: true, action: 'release', modalText: 'Are you certain you want to release this machine from the Printer Server it is bound to?  Click Okay to proceed, or anything else to skip.'});
  }

  onDeletePrintServer(id){
    this.setState({showModal: true, action: 'delete', modalText: 'Are you certain you want to delete the Printer Server this machine is bound to?  Click Okay to proceed, or anything else to skip.'});
  }

  onDeleteAllPrintServers(){
    this.setState({showModal: true, action: 'deleteAll', modalText: 'Are you certain you want to delete all print servers.  This will shut down all network printing until reset.'});
  }

  onSetMachineLocal(){
    this.setState({showModal: true, action: 'setLocal', modalText: 'Are you certain you want to set this machine as the manager of this Print Server?'});
  }

  setLocalPrintServer(printServer){
    const interval = 180000; // 3 minutes
    const keepAlive = !printServer.active
      ? undefined
      : setInterval(() => { // Keeps auth alive, prevents timeout from displaying
        this.props.actions.getUserValid();
      }, interval);
    if(!printServer.active) { // Turn off any already running keep alive
      if(this.props.localPrintServer && this.props.localPrintServer.keepAlive){
        clearInterval(this.props.localPrintServer.keepAlive);
      }
    }
    const newPrintServer = Object.assign({}, printServer, {keepAlive});
    this.props.actions.setItem(newPrintServer, itemNames.localPrintServer);
  }

  clearLocalPrintServer(){
    if(this.props.localPrintServer && this.props.localPrintServer.keepAlive){
      clearInterval(this.props.localPrintServer.keepAlive);
    }
    this.props.actions.unsetItem(itemNames.localPrintServer);
  }

  onHide(object){
    // includes event and buttonClicked which is either okay or cancel
    this.setState({showModal: false});
    if(object.buttonClicked === 'cancel') return false;

    if(this.state.action === 'release'){
      this.clearLocalPrintServer();
    }

    if(this.state.action === 'setLocal'){
      this.setLocalPrintServer(this.props.initialValues);
    }

    if(this.state.action === 'delete'){
      //const payload = getPrintServerPayload(this.props.localPrintServer, Object.assign({}, this.props.complianceSettings), true);
      this.getPayload(this.props.localPrintServer, true)
        .then((payload) => {
          this.props.actions.postItem('/api/labels/compliance_settings', payload, null, null, null, () => {
            this.clearLocalPrintServer();
            this.props.actions.goBack();
          });
        });
    }
    if(this.state.action === 'deleteAll'){
      const payload = getPrintServerPayload(this.props.initialValues, Object.assign({}, this.props.complianceSettings));
      payload.settings[0].value.print_servers = [];
      this.props.actions.postItem('/api/labels/compliance_settings', payload, null, null, null, () => {
        this.clearLocalPrintServer();
        this.props.actions.goBack();
      });
    }

  }

  onSubmit(values){
    this.setLocalPrintServer(values);

    const savePrintServer = (payload) => {
      const selectedPrinter = Object.assign({}, this.props.selectedPrinter);
      if(values.active){ // If set active, then we are listing, set that on selectedPrinter which is really a collection of flags
        const server = get(payload, 'settings[0].value.print_servers').find((server) => server.name === values.name);
        selectedPrinter.listeningPrintServerId = server.id;
        this.props.actions.setItem(selectedPrinter, itemNames.selectedPrinter);
      } else {
        if(this.props.selectedPrinter.listeningPrintServerId === values.id){ // Clear on not active and was locally active
          delete(selectedPrinter.listeningPrintServerId);
          this.props.actions.setItem(selectedPrinter, itemNames.selectedPrinter);
        }
      }
      this.props.actions.postItem('/api/labels/compliance_settings', payload, null, null, null, () => {
        this.props.actions.goBack();
      });
    };

    this.getPayload(values, false)
      .then((payload) => {
        savePrintServer(payload);
      });

    //const payload = getPrintServerPayload(values, Object.assign({}, this.props.complianceSettings));

  }

  onImportPrinters(){
    this.props.actions.importLocalPrinters();
  }

  render () {
    return (<div>
      <div>
        <h4>Print Server Editor</h4>
      </div>
      <ModalWrapper
        showModal={this.state.showModal}
        onHide={this.onHide}
        title='Please Confirm This Action!'
        version={2}
        headerClass='bg-danger-dark'
        okayButton={{show: true}}
        cancelButton={{show: true}}
        widthClass='modal-sm'
      >
        {this.state.modalText}
        <div style={{clear: 'both'}} />
      </ModalWrapper>
      <Form
        initialValues={this.props.initialValues}
        printers={this.props.printers}
        serverId={this.props.serverId}
        serverActive={this.props.serverActive}
        onSubmit={this.onSubmit}
        selectedPrinter={this.props.selectedPrinter}
        localPrintServer={this.props.localPrintServer}
        onImportPrinters={this.onImportPrinters}
        onAction={this.onAction}
        localPrinters={this.props.localPrinters}
      />
    </div>);
  }
}

PrintServerEditor.propTypes = {
  initialValues: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
  const selector = formValueSelector(PRINT_SERVER_FORM);
  const initialValues = getPrintServerInitialValues(state, ownProps);
  const serverActive = initialValues.active;
  return {
    initialValues,
    printers: selector(state, 'printers'),
    serverId: selector(state, 'id'),
    serverActive,
    selectedPrinter: state[itemNames.selectedPrinter],
    qzTray: state[itemNames.qzTray],
    localPrinters: state[dataNames.localPrinters],
    complianceSettings: state[itemNames.labelsCompliance],
    localPrintServer: state[itemNames.localPrintServer],
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {push, goBack, importLocalPrinters, setItem, unsetItem, getUserValid});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (PrintServerEditor);


