import React from 'react';
import PropTypes from 'prop-types';
import {Field, reduxForm} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Button, Col, Row} from 'react-bootstrap';

import DatePickerInput from '../../../common/form/DatePickerInput';

const PurchasedAmountsForm = ({handleSubmit, invalid, submitting, pristine}) => {

  return (
    <form onSubmit={handleSubmit} className='leaf-pa-purchased-amounts-form'>
      <Row>
        <Col md={4}>
          <Field
            name='date_from'
            component={DatePickerInput}
            props={{
              label: I18n.t('common.form.startDate')
            }}/>
        </Col>
        <Col md={4}>
          <Field
            name='date_to'
            component={DatePickerInput}
            props={{
              label: I18n.t('common.form.endDate')
            }}/>
        </Col>
        <Col md={4}>
          <Button
            type='submit'
            variant='primary'
            className='submit-button'
            disabled={submitting || invalid || pristine}
          >
            {I18n.t('common.form.update')}
          </Button>
        </Col>
      </Row>
    </form>
  );
};

PurchasedAmountsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
};

const PurchasedAmountsFormWrapper = reduxForm({})(PurchasedAmountsForm);

export default PurchasedAmountsFormWrapper;
