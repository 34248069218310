import map from 'lodash.map';
import {createSelector} from 'reselect';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import {
  getCategoryById,
  getSubCategoriesByCategoryId, isCategoryCartridgesPens,
  isCategoryConcentrate,
  isCategoryFlower, isCategoryHarvest, isCategoryInfusedEdible, isCategoryInfusedNonEdible, isCategoryMarijuana,
  isCategoryNonMedicated,
  isCategoryPlants, isSubCategoryClones,
  isSubCategoryMature,
  isSubCategorySeeds,
  isSubCategoryTissue, isSubCategoryTissueCulture, isSubCategoryWaste
} from './categorySelectors';
import {getIntegrationState} from './integration/integrationSelectors';
import {uomFlowerConcentrate, uomTypes, VOLUME, WEIGHT} from '../constants/uomTypes';
import * as UOMS from '../constants/uoms';
import { getDecimalPrecisionForUom, sortByRatio, sortByRatioAndType } from '../util/uomHelpers';
import {safeNumberConversion} from '../util/mathHelpers';

export const getUoms = (state) => sortByRatioAndType(state.uoms);
const getUomCode = (_, props) => get(props, 'uom_code', UOMS.GR);
const getUomId = (_, props) => props.id;
const getUomType = (_, props) => props.uom_type;
const getUomsType = (_, props) => props.types;
const getUom = (_, props) => get(props, 'uom', UOMS.GR);
export const roundQty = state => roundQtyCurried(state);
export const displayQty = state => displayQtyCurried(state);

export const getUomTypeByCode = createSelector([getUoms, getUomCode], (uoms, code) => {
  const uom = uoms.find(uom => uom.uom_code === code);
  return uom ? uom.uom_type : null;
});
export const getUomTypeById = createSelector([getUoms, getUomId], (uoms, id) => {
  const uom = uoms.find(uom => uom.id === id);
  return uom ? uom.uom_type : null;
});

export const getUomsTypeByType = createSelector([getUoms, getUomType], (uoms, type) => {
  return uoms.filter((uom) => uom.uom_type === type);
});

export const getUomByCode = createSelector([getUoms, getUomCode], (uoms, code) => {
  return uoms.reduce((acc, uom) => {
    return uom.uom_code === code ? uom : acc;
  }, {});
});

export const getSelectableUomsByTypes = createSelector([getUoms, getUomsType], (uoms, types) => {
  const filteredUoms = sortByRatio(uoms.filter((uom) => types.includes(uom.uom_type)), 'desc');
  const uomTranslations = I18n.t('uoms');

  return filteredUoms.map(
    uom => ({code: uom.uom_code, name: uomTranslations[uom.uom_code].word})
  );
});

export const getUomByCodes = createSelector([getUoms, getUomCode], (uoms, codes) => {
  return uoms.filter(uom => codes.indexOf(uom.uom_code) !== -1);
});


export const getPossibleUoms = (defaultUom, uoms) => {
  const defaultUomData = uoms.find(uom => uom.uom_code === defaultUom);
  const possibleUoms = defaultUomData ? uoms.filter(uom => uom.uom_type === defaultUomData.uom_type
    && parseFloat(uom.conversion_ratio) <= parseFloat(defaultUomData.conversion_ratio)) : [];

  return sortByRatio(possibleUoms);
};

/**
 * Returns a list of possible options. Used on ReactSelectInput
 * @param defaultUom
 * @param uoms
 * @returns {*}
 */
export const getPossibleUomsOptions = (defaultUom, uoms) => map(getPossibleUoms(defaultUom, uoms), (uom) => ({
  text: uom.uom_code,
  value: uom.uom_code,
}));

export const getUomInitialValues = (state) => {
  return {
    weight: getUomByCodes(state, {uom_code: ['OZ', 'LB']}),
    volume: getUomByCodes(state, {uom_code: ['FLOZ', 'PT', 'QT', 'GAL']})
  };
};

/**
 * Works only for non-integrated facility.
 * This is due to the lack of a selected subcategory
 *
 * @param state
 * @param category_id
 * @param integrationState
 * @returns {[]}
 */
export const getUomByCategoryId = createSelector(
  [getUoms, getCategoryById, getSubCategoriesByCategoryId, getIntegrationState],
  (initialUoms, category, subcategories, integrationState) => {
    let uoms = [];

    subcategories.forEach(subcategory => {
      const subcategoryUoms = prepareUomOptions(
        initialUoms,
        category,
        subcategory.id,
        integrationState,
        [],
        [],
        []
      );

      const tempUom = uoms.concat(subcategoryUoms);
      uoms = [...new Set(tempUom)];
    });

    return uoms.map((uom) => {
      uom.text = I18n.t(`uoms.${uom.uom_code}.word`);
      return uom;
    });
  });

// NOTE, this is similar to prepareUomOptions in productFormSelectors.js. Consider refactor.
export const prepareUomOptions = (uomOptions, sc, subcategoryId, modes, mc, mcm, muomOptions) => {
  if (!sc) {
    return [];
  }

  if (modes.isMetrc && subcategoryId && mc && mcm) {
    const subCategoryMapping = mcm.find((map) => subcategoryId === map.subcategory_id);
    if (subCategoryMapping) {
      const metrcCategory = mc.find(category => category.id === subCategoryMapping.metrc_item_category_id);
      if (muomOptions && (isCategoryFlower(sc) || isCategoryConcentrate(sc))) {
        return muomOptions.filter((uom) => uom.uom_type === metrcCategory.uom_type);
      }
      if (metrcCategory && metrcCategory.uom_type === 'weight') {
        return uomOptions.filter(uom => uom.uom_code === UOMS.GR);
      } else if (metrcCategory && metrcCategory.uom_type === 'discrete') {
        return uomOptions.filter(uom => uom.uom_type === uomTypes.DISCRETE);
      }
    }
  }

  const ssc = (sc.subcategories && sc.subcategories.find((sc) => sc.id === subcategoryId)) || {};
  if (isCategoryNonMedicated(sc)) {
    return uomOptions.filter((uom) => ['weight', 'discrete', 'volume'].indexOf(uom.uom_type) != -1);
  }

  if (isCategoryPlants(sc) && isSubCategorySeeds(ssc)) {
    if (modes.isWaLeaf) {
      return uomOptions.filter((uom) => uom.uom_code === UOMS.EA);
    } else {
      //Allow GR/EA for Seeds
      return uomOptions.filter((uom) => uom.uom_code === UOMS.GR || uom.uom_type === uomTypes.DISCRETE);
    }
  }

  if (
    isCategoryPlants(sc) &&
    (isSubCategoryMature(ssc) || isSubCategoryTissue(ssc) || isSubCategoryTissueCulture(ssc))
  ) {
    return uomOptions.filter((uom) => uom.uom_code === UOMS.EA);
  }

  if (isCategoryPlants(sc) && isSubCategoryWaste(ssc)) {
    //Allow GR only for Waste
    return uomOptions.filter((uom) => uom.uom_code === UOMS.GR);
  }

  if (isCategoryFlower(sc) || isCategoryConcentrate(sc)) {
    switch (true) {
    case modes.isNormal:
      if(!modes.isHemp) {
        return uomOptions.filter((uom) => uom.uom_code === UOMS.GR);
      }

      return isCategoryFlower(sc)
        ? uomOptions.filter((uom) => uomFlowerConcentrate.none.default.indexOf(uom.uom_code) > -1)
        : uomOptions.filter((uom) => uom.uom_type !== 'discrete' && uom.uom_code !== UOMS.TBSP && uom.uom_code !== UOMS.TSP);
    default:
      return uomOptions.filter((uom) => uom.uom_code === UOMS.GR);
    }
  }

  if (isCategoryHarvest(sc) || isCategoryMarijuana(sc)) {
    return uomOptions.filter((uom) => uom.uom_code === UOMS.GR);
  }

  if (
    isCategoryCartridgesPens(sc) ||
    isCategoryInfusedEdible(sc) ||
    isCategoryInfusedNonEdible(sc) ||
    (isCategoryPlants(sc) && isSubCategoryClones(ssc))
  ) {
    return uomOptions.filter((uom) => uom.uom_type === uomTypes.DISCRETE);
  }

  return [];
};

/**
 * Determine precision given a uom and the integration state
 *
 * @param uom
 * @param integrationState
 * @returns Number
 */
export const getUomPrecision = (uom, integrationState) => {
  const standardPrecision = uom ? getDecimalPrecisionForUom(uom) : UOMS.UOM_VALUE_PRECISION;

  return standardPrecision === 0
    ? standardPrecision // 0
    : integrationState.isHemp
      ? standardPrecision
      : UOMS.UOM_VALUE_PRECISION_NON_HEMP;
};

/**
 * Selects the precision for a given uom and integration state
 *
 * @returns Number
 */
export const getUomInputPrecision = createSelector(
  [getIntegrationState, getUom], (integrationState, uom) => {
    return getUomPrecision(uom, integrationState);
  });

/**
 * Selector that creates a function to use for rounding quantities. Use this for purposes that are beyond
 * simple display (calculations, sending to server, etc)
 *
 * @returns function
 */
export const roundQtyCurried = createSelector(
  [getIntegrationState], (integrationState) => {
    return (qty, uom) => {
      const precision = getUomPrecision(uom, integrationState);

      return Number(safeNumberConversion(qty).toFixed(precision));
    };
  });

/**
 * Selector that creates a function to use for displaying quantities. Use this for simple display purposes only
 *
 * @returns function
 */
export const displayQtyCurried = roundQtyCurried;


/**
 * Selector that creates a function to use for displaying quantities with the translated uom appended
 *
 * @returns function
 */
export const displayQtyAndUomCurried = createSelector(
  [getIntegrationState, displayQty], (integrationState, displayQty) => {
    return (qty, uom) => {
      const qtyDisplayValue = displayQty(qty, uom);

      return `${qtyDisplayValue} ${I18n.t(`uoms.${uom}.abbrev`)}`;
    };
  });

const getUomsByType = (uoms, type) => {
  return uoms.filter((uom) => uom.uom_type === type).map((uom) => {
    const uomRatio = parseInt(uom.conversion_ratio); // BE has conversion ratios as four decimals but we don't use them anywhere so don't show them.
    return Object.assign({}, uom, {type: uom.uom_type, ratio: uomRatio, conversion_ratio: uomRatio, uom: uom.uom_code});
  });
};

export const getWeightUoms = createSelector([getUoms], (beUoms) => {
  const uoms = getUomsByType(beUoms, WEIGHT);
  return sortByRatioAndType(uoms, [WEIGHT], 'desc');
});

export const getVolumeUoms = createSelector([getUoms], (beUoms) => {
  const uoms = getUomsByType(beUoms, VOLUME);
  return sortByRatioAndType(uoms, [VOLUME], 'desc');
});
