import React from 'react';
import PropTypes from 'prop-types';
import {FormControl} from 'react-bootstrap';

import ReduxBootstrapInput from './ReduxBootstrapInput';

const PasswordInput = ({ label, size, input, meta, children, rightAddon, leftAddon, isRequired, ...props }) => {
  return (
    <ReduxBootstrapInput Control={FormControl}
                         label={label} size={size}
                         input={input} meta={meta}
                         name={input.name} isRequired={isRequired}
                         rightAddon={rightAddon} leftAddon={leftAddon}
                         type='password' {...props} >
      {children}
    </ReduxBootstrapInput>
  );
};

PasswordInput.propTypes = {
  label: PropTypes.string,
  size: PropTypes.oneOf(['lg', 'sm', 'large', 'small']),
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  name: PropTypes.string,
  children: PropTypes.node,
  isRequired: PropTypes.bool,
  leftAddon: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.node]),
  rightAddon: PropTypes.object
};

export default PasswordInput;
