import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { Field } from 'redux-form';
import { Button, Col, Row } from 'react-bootstrap';
import SimplePagination from '../../common/SimplePagination';
import AccordionPanel from '../../common/AccordionPanel';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import NumericInput from '../../common/form/NumericInput';
import TextInput from '../../common/form/TextInput';
import ReduxMetrcIdField from '../../common/form/redux-form/ReduxMetrcIdField';
import AddNoteSection from '../common/AddNoteSection';
import { addAdditionalStrainToList } from '../../../selectors/facilityStrainsSelectors';
import CheckBoxInput from '../../common/form/InlineCheckBox';
import ContentConcealer from '../../common/concealers/ContentConcealer';
import ModalityField from '../../integration/colombia/ModalityField';
import CupoNumberField from '../../integration/colombia/CupoNumberField';

const IndividualPlantInformation = (props) => {
  const {
    page,
    fields,
    change,
    actions,
    strains,
    formName,
    switchPage,
    trackingIds,
    saveOnePlant,
    currentStage,
    selectedPlants,
    integrationState: { isMetrc, isBiotrack, isWaLeaf, isColombia },
    complianceSettings,
    showInvPlants,
    hasPlantsTags,
    currentStageCode,
    isUtahGlobalIdGenerationFeatureEnabled,
    initialValues
  } = props;

  const onStrainChange = (value, index) => {
    change(`selectedPlants[${index}].strain_id`, value);

    if (!value) {
      return false;
    }

    actions
      .postItem('/api/batches/generate_batch_name', {
        strain_id: value,
        planting_date: fields.get(index).planting_date
      })
      .then((response) => {
        //since we are now changing all the plants in the batch we will keep the name after the batch the same so it does not generate duplicates
        const plantIdSplit = initialValues.selectedPlants[index].plant_id.split('.');
        const newName = response.batch_name + '.' + plantIdSplit[1];
        change(`selectedPlants[${index}].plant_id`, newName);
        change(`selectedPlants[${index}].batch_name`, response.batch_name);
        change(`selectedPlants[${index}].phenotype_id`, null);
      });
  };

  const onPhenotypeChange = (value, index, strain_id) => {
    change(`selectedPlants[${index}].phenotype_id`, value);

    actions
      .postItem('/api/batches/generate_batch_name', {
        strain_id: strain_id,
        phenotype_id: value,
        planting_date: fields.get(index).planting_date
      })
      .then((response) => {
        //since we are now changing all the plants in the batch we will keep the name after the batch the same so it does not generate duplicates
        const plantIdSplit = initialValues.selectedPlants[index].plant_id.split('.');
        const newName = response.batch_name + '.' + plantIdSplit[1];
        change(`selectedPlants[${index}].plant_id`, newName);
        change(`selectedPlants[${index}].batch_name`, response.batch_name);
      });
  };

  const showQtyField = complianceSettings.cult_track_plants_as_groups
    ? complianceSettings.cult_track_plants_as_groups.value
    : false;
  const trackPlantsAsGroup =
    complianceSettings.cult_track_plants_as_groups && complianceSettings.cult_track_plants_as_groups.value;
  const locations = [];
  const batches = [];
  selectedPlants.forEach((plant) => {
    if (locations.length < 4) {
      if (locations.length === 3) {
        locations.push('etc.');
      } else if (locations.indexOf(plant.location_name) === -1) {
        locations.push(plant.location_name);
      }
    }
    if (batches.length < 4) {
      if (batches.length === 3) {
        batches.push('etc.');
      } else if (batches.indexOf(plant.batch_name) === -1) {
        batches.push(plant.batch_name);
      }
    }
  });

  const locationTitle = locations.join(', ');
  const batchTitle = batches.join(', ');

  return (
    <ContentConcealer show={showInvPlants}>
      <AccordionPanel
        title={'plants.modify.individualPlantsTitle'}
        titleParams={{ locationTitle }}
        rightTitle={'plants.modify.individualPlantsRightTitle'}
        rightTitleParams={{ batchTitle }}
      >
        {page.items.map((plant, currIndex) => {
          const currentStrain = {
            id: plant.strain_id,
            strain_name: plant.strain_name,
            strain_code: plant.strain_code || '',
            disabled: true
          };
          const strainList = addAdditionalStrainToList(strains, currentStrain);
          const index = currIndex + (page.start - 1);

          const strain_id =
            selectedPlants.length && selectedPlants[index] ? parseInt(selectedPlants[index].strain_id) : null;
          const selectedStrain = strain_id ? strains.find((strain) => strain.id === strain_id) : null;
          const phenotypes = selectedStrain ? selectedStrain.phenotypes : [];

          return (
            <React.Fragment>
              <Row key={index} className='form-fields-row'>
                <Col className='form-fields-col' xs={6} md={3} lg={2}>
                  <Field
                    name={`selectedPlants[${index}].plant_id`}
                    component={TextInput}
                    readOnly
                    props={{
                      label: trackPlantsAsGroup ? I18n.t('plants.modify.plantGroupId') : I18n.t('plants.modify.plantId')
                    }}
                  />
                </Col>

                <Col className='form-fields-col' xs={8} md={3} lg={2}>
                  <Field
                    name={`selectedPlants[${index}].batch_name`}
                    component={TextInput}
                    readOnly
                    props={{
                      label: I18n.t('plants.modify.newBatchName')
                    }}
                  />
                </Col>

                {!isBiotrack && (
                  <Col className='form-fields-col' xs={8} md={3} lg={2}>
                    <Field
                      name={`selectedPlants[${index}].waste_weight`}
                      component={NumericInput}
                      props={{
                        rightAddon: <span>G</span>,
                        label: I18n.t('plants.modify.reportWaste'),
                        max: 99999,
                        min: 0,
                        placeholder: I18n.t('plants.form.quantityPlaceholder')
                      }}
                    />
                  </Col>
                )}

                <Col className='form-fields-col' xs={8} md={2} lg={2}>
                  <Field
                    name={`selectedPlants[${index}].strain_id`}
                    component={ReactSelectInput}
                    props={{
                      //value: selectedPlants ? parseInt(page.items[index].strain_id) : null,
                      label: I18n.t('plants.modify.changeStrain'),
                      options: strainList,
                      textKey: 'strain_name',
                      valueKey: 'id',
                      placeholder: I18n.t('common.form.selectPlaceholder'),
                      onChange: (value) => onStrainChange(value, index),
                      disabled: isWaLeaf || (isColombia && plant.cupo_id && plant.modality)
                    }}
                  />
                  <p style={{ color: 'red' }}>{I18n.t('plants.modify.warningStrain')}</p>
                </Col>

                <CupoNumberField
                  formName={formName}
                  name={`selectedPlants[${index}].cupo_id`}
                  disabled={true}
                  LayoutComponent={({ children }) => (
                    <Col xs={6} md={3}>
                      {children}
                    </Col>
                  )}
                />

                <ModalityField
                  formName={formName}
                  name={`selectedPlants[${index}].modality`}
                  cupo_id={selectedPlants[index] && selectedPlants[index].cupo_id}
                  strain_id={selectedPlants[index] && selectedPlants[index].strain_id}
                  disabled={true}
                  LayoutComponent={({ children }) => (
                    <Col className='form-fields-col' xs={8} md={2} lg={2}>
                      {children}
                    </Col>
                  )}
                />

                {showQtyField && (
                  <Col className='form-fields-col' xs={4} md={2} lg={1}>
                    <Field
                      name={`selectedPlants[${index}].qty`}
                      component={NumericInput}
                      props={{
                        label: I18n.t('plants.modify.quantity'),
                        max: 9999999,
                        min: 0,
                        placeholder: I18n.t('plants.form.quantityPlaceholder')
                      }}
                    />
                  </Col>
                )}

                <Col className='form-fields-col' xs={6} md={3} style={{ marginTop: '22px' }}>
                  <Field
                    name={`selectedPlants[${index}].is_mother`}
                    component={CheckBoxInput}
                    props={{
                      label: I18n.t('plants.form.isMother')
                    }}
                  />
                </Col>

                {isWaLeaf ? (
                  <Col className='form-fields-col' xs={6} md={4} lg={2}>
                    <Field
                      name={`selectedPlants[${index}].mother_plant_name`}
                      component={TextInput}
                      readOnly
                      props={{
                        label: I18n.t('plants.modify.motherPlantId')
                      }}
                    />
                  </Col>
                ) : null}

                {isMetrc && currentStage !== 2 ? (
                  <Col className='form-fields-col' xs={8} md={4} lg={3} xl={2}>
                    <ReduxMetrcIdField
                      name={`selectedPlants[${index}].state_integration_tracking_id`}
                      props={{
                        options: trackingIds,
                        isRequired: false
                      }}
                    />
                  </Col>
                ) : null}


                {!isUtahGlobalIdGenerationFeatureEnabled && (
                  (hasPlantsTags && (currentStageCode === 'flower' || currentStageCode === 'veg')) ||
                  (hasPlantsTags && currentStageCode === undefined && (currentStage === 1 || currentStage === 3))
                ) ? (
                  <Col xs={6} md={3}>
                    <Field
                      name={`selectedPlants[${index}].tag_requested`}
                      component={ReactSelectInput}
                      props={{
                        label: I18n.t('plants.modify.needsTag'),
                        options: [{ text: I18n.t('common.yes'), value: 1 }, { text: I18n.t('common.no'), value: 0 }],
                        textKey: 'text',
                        valueKey: 'value',
                        placeholder: I18n.t('common.form.noChange')
                      }}
                    />
                  </Col>
                ) : null}

                <Col xs={6}>
                  <AddNoteSection name={`selectedPlants[${index}].notes`} />
                </Col>

                <Col className='form-fields-col' xs={6} md={4} lg={4}>
                  <Field
                    name={`selectedPlants[${index}].phenotype_id`}
                    component={ReactSelectInput}
                    props={{
                      //value: selectedPlants ? parseInt(page.items[index].phenotype_id) : null,
                      label: I18n.t('plants.modify.changePhenotype'),
                      options: phenotypes,
                      textKey: 'name',
                      valueKey: 'id',
                      placeholder: I18n.t('common.form.selectPlaceholder'),
                      onChange: (value) => onPhenotypeChange(value, index, strain_id)
                    }}
                  />
                  <p style={{ color: 'red' }}>{I18n.t('plants.modify.warningPheno')}</p>
                </Col>
              </Row>
              <Row>
                <Col className='form-fields-col last-column' xs={12} md={12} lg={12}>
                  <Button
                    onClick={() => {
                      const sendData = fields.get(index);
                      return saveOnePlant(
                        { ...sendData, default_strain_id: currentStrain.id },
                        `selectedPlants[${index}].state_integration_tracking_id`,
                        index
                      );
                    }}
                    className='save-individual-plant-button btn-sm btn-primary float-right'
                  >
                    {I18n.t('common.form.save')}
                  </Button>
                </Col>
              </Row>
            </React.Fragment>
          );
        })}
        {page.pagesCount > 0 && (
          <div className='flex layout-row layout-align-space-between-center page-info'>
            <span>{I18n.t('grid.pagination', { start: page.start, to: page.end, total: page.total })}</span>
            {page.pagesCount > 1 && (
              <SimplePagination
                bsSize='medium'
                items={page.pagesCount}
                activePage={page.activePage}
                onSelect={switchPage}
                maxButtons={5}
                first={true}
                last={true}
                next={true}
                prev={true}
              />
            )}
          </div>
        )}
      </AccordionPanel>
    </ContentConcealer>
  );
};

IndividualPlantInformation.propTypes = {
  actions: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  initialPlants: PropTypes.array.isRequired,
  selectedPlants: PropTypes.array.isRequired,
  strains: PropTypes.array.isRequired,
  saveOnePlant: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  switchPage: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
  page: PropTypes.shape({
    activePage: PropTypes.number,
    items: PropTypes.array.isRequired,
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
    pagesCount: PropTypes.number.isRequired
  }),
  trackingIds: PropTypes.array.isRequired,
  currentStage: PropTypes.number,
  integrationState: PropTypes.object.isRequired,
  complianceSettings: PropTypes.object.isRequired,
  showInvPlants: PropTypes.bool.isRequired,
  hasPlantsTags: PropTypes.bool,
  currentStageCode: PropTypes.string,
  isUtahGlobalIdGenerationFeatureEnabled: PropTypes.bool,
  initialValues: PropTypes.array.isRequired
};

export default IndividualPlantInformation;
