import get from 'lodash.get';
import axios from 'axios';
import { loadTranslations, setLocale } from 'react-redux-i18n';
import { getSelectedUser } from '../../selectors/usersSelectors';
import { getIntegrationState } from '../../selectors/integration/integrationSelectors';
import { addMessage } from '../../actions/systemActions';
import * as dataNames from '../../constants/dataNames';
import * as apiActions from '../apiActions';
import * as messageTypes from '../../constants/messageTypes';
import * as types from '../../constants/actionTypes';
import initialState from '../../constants/initialState';

const {post} = axios;

function loadTranslationsFailed (error) {
  return {type: types.LOAD_TRANSLATIONS_FAILED, message: 'error.loadTranslations'};
}

const saveUser = (id, payload) => {
  return apiActions.putData(`/api/users/${id}`, payload, dataNames.currentFacilityUsers, {
    success: 'user.modify.successUpdateUser',
    failed: 'user.modify.failUpdateUser'
  });
};

export const prepareCreatePayloadData = (payload, props) => {
  return (dispatch, getState) => {
    const state = getState();
    const { isBiotrack } = getIntegrationState(state);
    const { initialValues } = props;

    const userToSubmit = Object.assign(
      {},
      {
        name: payload.name,
        authentication_key: payload.authentication_key,
        user_type: payload.user_type,
        card_swipe_id: payload.card_swipe_id,
        email: payload.email,
        state_id: payload.state_id,
        state_integration_id: payload.state_integration_id,
        first_name: payload.first_name,
        middle_name: payload.middle_name,
        last_name: payload.last_name,
        language: payload.language,
        birth_date: payload.birth_date,
        hire_date: payload.hire_date,
        termination_date: payload.termination_date,
        vehicle_make: payload.vehicle_make,
        vehicle_model: payload.vehicle_model,
        vehicle_license: payload.vehicle_license,
        status: 1,
        active: payload.active,
        addresses: [
          {
            street_address_1: payload.address,
            city: payload.city,
            province_code: payload.state,
            postal_code: payload.zip_code,
            country_code: payload.country_code
          }
        ],
        employee_title: payload.employee_title,
        ids: [
          { type: 'Employee ID', identification_number: payload.employee_id, active: 1 },
          { type: 'Compliance ID', identification_number: payload.state_id, active: 1 }
        ],

        user_sso_id: {
          secondary_id: payload.sso_id,
          idp_name : get(initialValues, 'sso_id_record.idp_name', null),
        },

        facilities: payload.facilityRoles
          .filter((facility) => facility.role_ids.length || !payload.active)
          .map((facility) => ({
            id: facility.facility_id,
            roles: payload.user_type !== 'affiliated_only'
              ? facility.role_ids.map((role) => role.value)
              : []
          })),
        phone_numbers: payload.phones.map((phone) => ({ number: phone.number, phone_country_code: phone.phone_country_code, active: 1 })),
        image_file_id: payload.image_file_id,
        integration_ids: payload.integration_ids
      }
    );

    if (isBiotrack) {
      userToSubmit.state_integration_id = payload.state_integration_id;
    }

    return userToSubmit;
  };
};

export const prepareModifyPayloadData = (payload, props) => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedUser = getSelectedUser(state);
    const { isBiotrack } = getIntegrationState(state);

    const { initialValues } = props;

    // const {selectedUser} = this.props.state;
    let employeeId = selectedUser.ids ? selectedUser.ids.find((payload) => payload.type === 'Employee ID') : null;
    if (!employeeId) {
      employeeId = { type: 'Employee ID', identification_number: '', active: 1 };
    }
    let complianceId = selectedUser.ids ? selectedUser.ids.find((payload) => payload.type === 'Compliance ID') : null;
    if (!complianceId) {
      complianceId = { type: 'Compliance ID', identification_number: '', active: 1 };
    }
    const address =
      selectedUser.addresses && selectedUser.addresses.length > 0
        ? selectedUser.addresses[0]
        : {
          street_address_1: '',
          city: '',
          province_code: '',
          postal_code: ''
        };
    const phones = [];
    payload.phones.forEach((phone, index) => {
      if (typeof selectedUser.phone[index] === 'undefined') {
        phones.push({ number: phone.value, active: 1 });
      } else {
        phones.push(Object.assign(selectedUser.phone[index], { number: phone.number,phone_country_code: phone.phone_country_code }));
      }
    });

    const userToSubmit = Object.assign(
      {},
      {
        id: selectedUser.id,
        authentication_key: payload.authentication_key,
        password: payload.password,
        user_type: payload.user_type,
        card_swipe_id: payload.card_swipe_id,
        email: payload.email,
        state_id: payload.state_id,
        state_integration_id: payload.state_integration_id,
        first_name: payload.first_name,
        middle_name: payload.middle_name,
        last_name: payload.last_name,
        language: payload.language,
        birth_date: payload.birth_date,
        hire_date: payload.hire_date,
        termination_date: payload.termination_date,
        vehicle_make: payload.vehicle_make,
        vehicle_model: payload.vehicle_model,
        vehicle_license: payload.vehicle_license,
        active: payload.active,
        addresses: [
          Object.assign(address, {
            street_address_1: payload.address,
            city: payload.city,
            province_code: payload.state,
            country_code: payload.country_code,
            postal_code: payload.zip_code
          })
        ],
        employee_title: payload.employee_title,
        ids: [
          Object.assign(employeeId, { identification_number: payload.employee_id }),
          Object.assign(complianceId, { identification_number: payload.state_id })
        ],
        // Facilities need to have role_ids assigned, except when the user is not active or user_type is affiliated_only.
        // In those case store the facility without any role_ids
        facilities: payload.facilityRoles
          .filter((facility) => facility.role_ids.length || !payload.active || payload.user_type === 'affiliated_only')
          .map((facility) => ({
            id: facility.facility_id,
            // Only set user roles if user_type is not 'affiliated_only' (i.e. clear any roles if user_type is affiliated_user
            roles: payload.user_type !== 'affiliated_only'
              ? facility.role_ids.map((role) => (role.hasOwnProperty('value') ? role.value : role))
              : []
          })),
        phone_numbers: phones,
        image_file_id: payload.image_file_id,
        integration_ids: payload.integration_ids
      }
    );

    if (isBiotrack) {
      userToSubmit.state_integration_id = payload.state_integration_id;
    }

    // SSO Identity
    if (get(initialValues, 'sso_enabled', false) && !get(initialValues, 'sso_user_has_primary_id', false)) {
      if (get(initialValues, 'sso_id_record.id', false)) {
        Object.assign(userToSubmit, {
          user_sso_id: {
            id: get(initialValues, 'sso_id_record.id', false),
            secondary_id: get(payload, 'sso_id', null),
            idp_name : get(initialValues, 'sso_id_record.idp_name', null),
          }
        });
      } else {
        Object.assign(userToSubmit, {
          user_sso_id: {
            idp_name : get(initialValues, 'sso_id_record.idp_name', null),
            secondary_id: get(payload, 'sso_id', null)
          }
        });
      }
    }

    if (payload.name !== selectedUser.name) {
      Object.assign(userToSubmit, { name: payload.name });
    }

    return userToSubmit;
  };
};

export const createUser = (payload) => {
  return apiActions.postData(
    '/api/users',
    payload,
    dataNames.currentFacilityUsers,
    { failed: 'user.create.fail' },
    undefined,
    (user) => {
      if (user && user.meta && user.meta.email_not_sent) {
        addMessage(messageTypes.success, 'user.create.success');
        addMessage(messageTypes.warning, 'user.create.emailNotSent');
      } else {
        addMessage(messageTypes.success, 'user.create.successWithEmail');
      }
    }
  );
};

export const create = (payload, props) => (dispatch) => {
  const preparedPayload = dispatch(prepareCreatePayloadData(payload, props));

  return dispatch(createUser(preparedPayload));
};

export const update = (payload, props) => (dispatch) => {
  const preparedPayload = dispatch(prepareModifyPayloadData(payload, props));

  return dispatch(saveUser(preparedPayload.id, preparedPayload));
};

export const onResetPassword = (user_id) => {
  return apiActions.postItem(
    '/api/password/admin_reset_email',
    {
      user_id: user_id,
      mode: 'reset',
      set_random_password: 1
    },
    dataNames.currentFacilityUsers,
    { success: 'user.modify.successResetPassword', failed: 'user.modify.failedResetPassword' }
  );
};

export function getTranslations(requestedLanguage, force = false) {
  return (dispatch, getState) => {
    const {headers, i18n, user} = getState();

    //lets save this on the backend for next time this user logs in
    if (user && user['id'] && requestedLanguage != user['language']) {
      const payload = {
        id: user['id'],
        language: requestedLanguage,
      };

      dispatch(saveUser(user['id'], payload));
    }

    if(i18n.translations[requestedLanguage] && !force){
      dispatch(setLocale(requestedLanguage));
    }else{
      return post('/api/i18n', {languages:[requestedLanguage]}, {headers})
        .then(response => {
          // Merge so we don't have to maintain same localization in two places
          const uiTranslations = initialState.i18n.translations[requestedLanguage]
            ? initialState.i18n.translations[requestedLanguage]
            : initialState.i18n.translations['en'];
          const translations = {
            [requestedLanguage]: Object.assign({}, uiTranslations, response.data[requestedLanguage])
          };
          dispatch(loadTranslations(translations));
          dispatch(setLocale(requestedLanguage));

        }).catch(error => {
          dispatch(loadTranslationsFailed(error));
        });
    }
  };
}
