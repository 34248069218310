/* eslint-disable import/prefer-default-export */
import {addDataSuccess, setData} from './dataActions';
import {rewards, rewardPointsAdjustments} from '../constants/dataNames';

export const createReward = (reward) => (dispatch, getState) => {
  return Promise
    .resolve(reward)
    .then(result => dispatch(addDataSuccess(result, rewards, 'retail.rewards.rewardCreated')));
};

export const setAdjustmentInRedux = (points, customerId) => {
  return (dispatch, getState) => {
    const adjustments = getState()[rewardPointsAdjustments];
    adjustments.push({
      points,
      customerId
    });
    dispatch(setData(adjustments, rewardPointsAdjustments));
    return true;
  };
};
