import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack} from 'react-router-redux';
import {fetchMetrcTrackingIdsForSelectInput} from '../../../actions/integrationActions';
import {getUnpaginatedData, getDataByPost, postData} from '../../../actions/apiActions';
import * as dataNames from '../../../constants/dataNames';
import {
  getPackageMotherPlantsPageProps,
  getPackageMotherPlantsPayload
} from '../../../selectors/forms/packageMotherPlantsFormSelectors';
import InProgressOverlay from '../../common/InProgressOverlay';
import FormWrapper from '../../common/form/FormWrapper';
import PackageMotherPlantsFormWrapper from './PackageMotherPlantsFormWrapper';


export class PackageMotherPlantsPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {loading: true, submitting: false};
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount() {
    const {actions: {getUnpaginatedData, getDataByPost, fetchMetrcTrackingIdsForSelectInput}, integrationState: {isBiotrack, isCaMetrc}, useItemMasterServiceFirst} = this.props;
    const promises = [
      getUnpaginatedData('/api/location_hierarchy', dataNames.locations, {failed: 'locations.get.failed'}, {is_sales_location: 0}),
    ];
    let categoriesPromise = Promise.resolve();

    if (isBiotrack) {
      categoriesPromise = Promise.all([
        getUnpaginatedData('/api/biotrack/categories', dataNames.biotrackCategories),
        getUnpaginatedData('/api/biotrack/categories/mapping', dataNames.biotrackCategoryMappings),
      ]);
    } else if (isCaMetrc) {
      promises.push(fetchMetrcTrackingIdsForSelectInput());
    }

    promises.push(
      categoriesPromise.then(() => {
        if (useItemMasterServiceFirst) {
          return this.getItemMastersFromService();
        }

        const {selectedStrainIds} = this.props;
        const strainFilter = `AND (${selectedStrainIds.map(v => `strain_id:${v}`).join(' OR ')})`;
        const searchParams = {
          filter: `active:1 AND is_draft:0 AND is_medicated:1 AND category_code: "PLANTS" AND -subcategory_code: "WASTE" AND is_ingredient:0 AND prepack_weight_id:0 ${strainFilter}`,
          query: '',
          size: 10000,
          sort: 'name asc, display_name asc',
          fields: ['id', 'strain_id', 'category_id', 'subcategory_id', 'name', 'default_uom'],
          start: 0,
        };
        return getDataByPost('/api/search/item_masters', searchParams, dataNames.itemMasters, {failed: 'products.get.failed'});
      })
    );

    Promise
      .all(promises)
      .then(() => this.setState({loading: false}))
      .catch(() => this.setState({loading: false}));
  }

  getItemMastersFromService() {
    const {selectedStrainIds} = this.props;
    const params = {
      select_columns: ['id', 'strain_id', 'category_id','category_code', 'subcategory_id', 'name', 'default_uom'],
      active: 1,
      is_medicated: 1,
      is_ingredient: 0,
      is_prepack: 0,
      in_strain_ids: selectedStrainIds,
      category_code: 'PLANTS',
      not_in_subcategory_codes: ['WASTE'],
      sort: ['name:asc', 'display_name:asc'],
    }
    return this.props.actions.getUnpaginatedData(
      '/api/item_masters/search',
      dataNames.itemMasters,
      {failed: 'products.get.failed'},
      params
    );
  }

  onSubmit(formData) {
    const payload = getPackageMotherPlantsPayload(formData);
    this.setState({submitting: true});
    return this.props.actions
      .postData(
        '/api/plants/package_mother_plants',
        payload,
        null,
        {success: 'plants.packageMothers.success', failed: 'plants.packageMothers.failed'}
      )
      .then(() => this.props.actions.goBack())
      .catch(() => this.setState({submitting: false}));
  }

  render() {
    const {itemMasters, locations, initialValues, trackingTags, integrationState} = this.props;
    const {loading, submitting} = this.state;
    const message = `common.form.${submitting ? 'saving' : 'loadingData'}`;

    return (
      <FormWrapper title='plants.packageMothers.title' goBack={this.props.actions.goBack}>
        <InProgressOverlay isActive={loading || submitting} translate={true} message={message}/>
        <PackageMotherPlantsFormWrapper
          onSubmit={this.onSubmit}
          initialValues={initialValues}
          itemMasters={itemMasters}
          locations={locations}
          trackingTags={trackingTags}
          integrationState={integrationState}
        />
      </FormWrapper>
    );
  }
}

PackageMotherPlantsPage.propTypes = {
  actions: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    getDataByPost: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    fetchMetrcTrackingIdsForSelectInput: PropTypes.func.isRequired,
  }).isRequired,
  integrationState: PropTypes.object.isRequired,
  selectedStrainIds: PropTypes.array.isRequired,
  subcategoryIds: PropTypes.array.isRequired,
  itemMasters: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  initialValues: PropTypes.object,
  trackingTags: PropTypes.array,
};


function mapDispatchToProps(dispatch) {
  const actions = {goBack, getUnpaginatedData, getDataByPost, postData, fetchMetrcTrackingIdsForSelectInput};

  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(getPackageMotherPlantsPageProps, mapDispatchToProps)(PackageMotherPlantsPage);
