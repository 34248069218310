import {reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import CheckinLeafPAForm from './CheckinLeafPAForm';
import validate from './validate';

const CheckinLeafPAFormWrapper = reduxForm({
  validate,
  touchOnChange: true,
})(CheckinLeafPAForm);

CheckinLeafPAFormWrapper.propTypes = {
  form: PropTypes.string.isRequired,
  showScanModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CheckinLeafPAFormWrapper;
