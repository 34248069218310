import React from 'react';
import InternationalCurrencyStatic from '../../../common/form/InternationalCurrencyStatic';
import InternationalDateTimeStatic from '../../../common/form/InternationalDateTimeStatic';


const defaultCsvFormatter = (cell) => cell || '';

const registerClosingActivityReportColumns = [
  {
    name: 'retail.registerClosingActivityReport.columns.register_id',
    dataId: 'register_id',
    dataSort: false
  },
  {
    name: 'retail.registerClosingActivityReport.columns.openTime',
    dataId: 'openTime',
    dataSort: false,
    csvFormatter: (cell) => cell || '',
    formatter: (cell) => <InternationalDateTimeStatic
      adjustForClientTimezone={true}>{cell}</InternationalDateTimeStatic>,
  },
  {
    name: 'retail.registerClosingActivityReport.columns.closeTime',
    dataId: 'closeTime',
    dataSort: false,
    csvFormatter: (cell) => cell || '',
    formatter: (cell) => <InternationalDateTimeStatic
      adjustForClientTimezone={true}>{cell}</InternationalDateTimeStatic>,
  },
  {
    name: 'retail.registerClosingActivityReport.columns.opening_balance',
    dataId: 'opening_balance',
    dataSort: false,
    formatter: (cell, row) => <InternationalCurrencyStatic>{cell}</InternationalCurrencyStatic>,
    csvFormatter: defaultCsvFormatter
  },
  {
    name: 'retail.registerClosingActivityReport.columns.balance_before_closing',
    dataId: 'balance_before_closing',
    dataSort: false,
    formatter: (cell, row) => <InternationalCurrencyStatic>{cell}</InternationalCurrencyStatic>,
    csvFormatter: defaultCsvFormatter
  },
  {
    name: 'retail.registerClosingActivityReport.columns.reset_balance_to',
    dataId: 'reset_balance_to',
    dataSort: false,
    formatter: (cell, row) => <InternationalCurrencyStatic>{cell}</InternationalCurrencyStatic>,
    csvFormatter: defaultCsvFormatter
  },
  {
    name: 'retail.registerClosingActivityReport.columns.drop',
    dataId: 'drop',
    dataSort: false,
    formatter: (cell, row) => <InternationalCurrencyStatic>{cell}</InternationalCurrencyStatic>,
    csvFormatter: defaultCsvFormatter
  },
  {
    name: 'retail.registerClosingActivityReport.columns.overShort',
    dataId: 'overShort',
    dataSort: false,
    formatter: (cell, row) => <InternationalCurrencyStatic>{cell}</InternationalCurrencyStatic>,
    csvFormatter: defaultCsvFormatter
  }
];

export default registerClosingActivityReportColumns;
