import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {Col, Row} from 'react-bootstrap';

import RouteButton from '../../common/RouteButton';
import RoutePermissionWrapper from '../../common/RoutePermissionWrapper';
import PermissionWrapper from '../../common/PermissionWrapper';
import * as p from '../../../constants/permissions';
import {getActiveFacility, getFacilitySetupLinks} from '../../../selectors/facilitiesSelectors';
import {advancedSetupLinks} from './links';

const props = {links: advancedSetupLinks};

class AdvancedSettingsPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {fade: []};
    this.isVisible = this.isVisible.bind(this);
    this.fadeIn = this.fadeIn.bind(this);
    this.fadeOut = this.fadeOut.bind(this);
  }

  isVisible(key) {
    return this.state.fade.indexOf(key) > -1;
  }

  fadeIn(key) {
    this.setState(state => ({fade: state.fade.concat(key)}));
  }

  fadeOut(key) {
    this.setState(state => ({fade: state.fade.filter(item => item !== key)}));
  }

  render() {
    const {facility, links} = this.props;

    const items = links.filter(link => link.title !== 'facility.setup.compliance.title');
    const compliance = links.filter(link => link.title === 'facility.setup.compliance.title');
    return (
      <div className='setup-page'>
        <h1>{I18n.t('facility.setup.advancedSettingsTitle', {facility: facility.name})}</h1>
        <Row>
          {items.map(item => (
            <RoutePermissionWrapper key={item.title} path={item.link} accessibility={item.accessibility}>
              <Col xs={12} md={6} lg={6} xl={4} >
              {item.categories ?
                <Row key={item.title}>
                  <Col xs={12}>
                    <Row>
                      <Col xs={12} sm={12} md={12}>
                        <h3>{I18n.t(item.title)}</h3>
                      </Col>
                    </Row>
                  </Col>
                </Row> :
                <Row>
                  <Col xs={12} sm={12} md={12}>
                    <RouteButton path={item.link} props={{block:true}}>
                      <h3>{I18n.t(item.title)}</h3>
                    </RouteButton>
                  </Col>
                </Row>
              }
            </Col>
            </RoutePermissionWrapper>
          ))}
        </Row>
        <PermissionWrapper permissions={[p.manage_compliance_settings, p.manage_cultivation_compliance, p.manage_retail_compliance, p.manage_manufacturing_compliance]}>
        <Row>

          {compliance.map(item => (
            <Col xs={12} md={12} lg={12} key={item.title}>
                  {item.categories ?
                    <Row key={item.title}>
                    <Col xs={12}>
                      <Row>
                        <Col xs={12} sm={12} md={12}>
                            <h3>{I18n.t(item.title)}</h3>
                        </Col>
                      </Row>
                      <Row in={this.isVisible(item.title).toString()}>
                          {item.categories && item.categories.map(category => (
                            <RoutePermissionWrapper key={category.title} path={category.link} accessibility={category.accessibility}>
                              <Col xs={12} md={6} lg={6} xl={4} >
                              <RouteButton path={category.link} props={{block:true}}>
                                <h3>{category.title && I18n.t(category.title)}</h3>
                              </RouteButton>
                            </Col>
                          </RoutePermissionWrapper>
                          ))}
                      </Row>
                    </Col>
                  </Row> : null}
            </Col>
          ))}

        </Row>
        </PermissionWrapper>
      </div>
    );
  }
}

AdvancedSettingsPage.propTypes = {
  facility: PropTypes.object.isRequired,
  links: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    facility: getActiveFacility(state),
    links: getFacilitySetupLinks(state, props),
  };
}

export default connect(mapStateToProps)(AdvancedSettingsPage);
