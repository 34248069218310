import React from 'react';
import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';


const TrackingIds = (ids) => {
  return (
    <div>
      <label>{I18n.t('stateIntegrators.integrationPage.trackingIds')}</label>
      <div className='tracking-ids-container'>
        {get(ids, 'ids', []).map((id, index) => (
          <div key={index}>{id.leaf_global_id}</div>
        ))}
      </div>
    </div>
  );
};
export default TrackingIds;
