import React from 'react';
import PropTypes from 'prop-types';
import {Table} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import {ratingOptions, extendedRatingOptions} from '../../../constants/ratingOptions';
import InternationalQuantityByUomInput from '../../common/form/InternationalQuantityByUomInput';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import {getTranslationLabel} from '../../../util/translationHelpers';

const getLabel = getTranslationLabel('harvest.form.');

const IndividualPlantInformation = (props) => {
  const {fields, selectedPlants, use_overall, tracking, allowDistributedWeights, integrationState, trackPlantsAsGroups, uom} = props;
  const {isWaLeaf} = integrationState;
  const {isMetrc} = integrationState;
  const quantityFieldCommonProps = {
    max: 999999,
    min: 0,
    uom,
    placeholder: I18n.t('plants.form.quantityPlaceholder')
  };

  return (
    <Table className='harvest-plants-table' striped hover>
      <thead>
      <tr>
        <th>
          {I18n.t(trackPlantsAsGroups ? 'harvest.form.plantGroupId' : 'harvest.form.plantId')}
        </th>
        {isMetrc && (!allowDistributedWeights || use_overall) ? <th>
          {I18n.t('harvest.form.metrcId')}
        </th> : null}
        {tracking ? <th>
          {I18n.t('harvest.form.additionalTracking')}
        </th> : null}
        <th className='align-center total-wet-weight'>
          {getLabel('totalWetWeight', integrationState)}
        </th>
        {isWaLeaf ? <th className='align-center wet-weight'>
          {getLabel('totalWetOtherMaterialWeight', integrationState)}
        </th> : null}
        <th className='align-center waste'>
          {getLabel('waste', integrationState)}
        </th>
        {isWaLeaf ? <th className='align-center waste'>
          {getLabel('otherMaterialWaste', integrationState)}
        </th> : null}
        <th className='align-center wet-weight'>
          {getLabel('remainingWetWeight', integrationState)}
        </th>
        {isWaLeaf ? <th className='align-center wet-weight'>
          {getLabel('remainingWetOtherMaterialWeight', integrationState)}
        </th> : null}
        <th className='align-center rating-cell'>
          {I18n.t('harvest.form.pestResistance')}
        </th>
        <th className='align-center rating-cell'>
          {I18n.t('harvest.form.Mold')}
        </th>
        <th className='align-center rating-cell'>
          {I18n.t('harvest.form.powderyMildew')}
        </th>
        <th className='align-center rating-cell'>
          {I18n.t('harvest.form.budRot')}
        </th>
        <th className='align-center rating-cell'>
          {I18n.t('harvest.form.stretching')}
        </th>
        <th className='align-center rating-cell'>
          {I18n.t('harvest.form.airyBuds')}
        </th>
        <th className='align-center rating-cell'>
          {I18n.t('harvest.form.totalQuality')}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr className='overall-values plant-row' hidden={!allowDistributedWeights || use_overall}>
        <td>
          <label>{I18n.t('plants.form.overallValues')}</label>
        </td>
        {tracking ? <td/> : null}
        <td className='align-center wet-weight'>
          <InternationalQuantityByUomInput
            {...quantityFieldCommonProps}
            name={`wet_weight_harvest`}
          />
        </td>
        {isWaLeaf ? <td className='align-center wet-weight'>
          <InternationalQuantityByUomInput
            {...quantityFieldCommonProps}
            name={`wet_other_material_weight`}
          />
        </td> : null}
        <td className='align-center waste'>
          <InternationalQuantityByUomInput
            {...quantityFieldCommonProps}
            name={`total_waste_recorded`}
          />
        </td>
        {isWaLeaf ? <td className='align-center waste'>
          <InternationalQuantityByUomInput
            {...quantityFieldCommonProps}
            name={`total_other_material_waste`}
          />
        </td> : null}
        <td className='align-center wet-weight'>
          <InternationalQuantityByUomInput
            {...quantityFieldCommonProps}
            name={`remaining_wet_weight_harvest`}
            readOnly={true}
          />
        </td>
        {isWaLeaf ? <td className='align-center wet-weight'>
          <InternationalQuantityByUomInput
            {...quantityFieldCommonProps}
            name={`remaining_wet_other_material_weight`}
            readOnly={true}
          />
        </td> : null}
        <td className='align-center'>
          <Field name={`rating_avg_pest_resistance`}
                 component={ReactSelectInput}
                 props={{
                   options: ratingOptions,
                 }}
          />
        </td>
        <td className='align-center'>
          <Field name={`rating_avg_mold`}
                 component={ReactSelectInput}
                 props={{
                   options: ratingOptions,
                 }}
          />
        </td>
        <td className='align-center'>
          <Field name={`rating_avg_mildew`}
                 component={ReactSelectInput}
                 props={{
                   options: ratingOptions,
                 }}
          />
        </td>
        <td className='align-center'>
          <Field name={`rating_avg_bud_rot`}
                 component={ReactSelectInput}
                 props={{
                   options: ratingOptions,
                 }}
          />
        </td>
        <td className='align-center'>
          <Field name={`rating_avg_stretching`}
                 component={ReactSelectInput}
                 props={{
                   options: ratingOptions,
                 }}
          />
        </td>
        <td className='align-center'>
          <Field name={`rating_avg_airy_buds`}
                 component={ReactSelectInput}
                 props={{
                   options: ratingOptions,
                 }}
          />
        </td>
        <td className='align-center'>
          <Field name={`rating_avg_total_quality`}
                 component={ReactSelectInput}
                 props={{
                   options: extendedRatingOptions,
                 }}
          />
        </td>
      </tr>

      {(use_overall || !allowDistributedWeights) ? fields.map((plant, index) => {
        const selectedPlant = selectedPlants[index];
        const {plant_id, tracking_id, state_integration_tracking_id} = selectedPlant ? selectedPlant : {plant_id: '', tracking_id: '', state_integration_tracking_id: ''};

        return (<tr key={index} className='plant-row'>
          <td>{plant_id}</td>
          {isMetrc ? <td title={state_integration_tracking_id}>{state_integration_tracking_id?'...'+state_integration_tracking_id.substr(state_integration_tracking_id.length - 5):null}</td> : null}
          {tracking ? <td>{tracking_id}</td> : null}
          <td className='align-center wet-weight'>
            <InternationalQuantityByUomInput
              {...quantityFieldCommonProps}
              name={`${plant}.wet_weight_harvest`}
            />
          </td>
          {isWaLeaf ? <td className='align-center wet-weight'>
            <InternationalQuantityByUomInput
              {...quantityFieldCommonProps}
              name={`${plant}.wet_other_material_weight`}
            />
          </td> : null}
          <td className='align-center waste'>
            <InternationalQuantityByUomInput
              {...quantityFieldCommonProps}
              name={`${plant}.total_waste_recorded`}
            />
          </td>
          {isWaLeaf ? <td className='align-center waste'>
            <InternationalQuantityByUomInput
              {...quantityFieldCommonProps}
              name={`${plant}.total_other_material_waste`}
            />
          </td> : null}
          <td className='align-center wet-weight'>
            <InternationalQuantityByUomInput
              {...quantityFieldCommonProps}
              name={`${plant}.remaining_wet_weight_harvest`}
              readOnly={true}
            />
          </td>
          {isWaLeaf ? <td className='align-center wet-weight'>
            <InternationalQuantityByUomInput
              {...quantityFieldCommonProps}
              name={`${plant}.remaining_wet_other_material_weight`}
              readOnly={true}
            />
          </td> : null}
          <td className='align-center'>
            <Field name={`${plant}.rating_avg_pest_resistance`}
                   component={ReactSelectInput}
                   props={{
                     options: ratingOptions,
                   }}
            />
          </td>
          <td className='align-center'>
            <Field name={`${plant}.rating_avg_mold`}
                   component={ReactSelectInput}
                   props={{
                     options: ratingOptions,
                   }}
            />
          </td>
          <td className='align-center'>
            <Field name={`${plant}.rating_avg_mildew`}
                   component={ReactSelectInput}
                   props={{
                     options: ratingOptions,
                   }}
            />
          </td>
          <td className='align-center'>
            <Field name={`${plant}.rating_avg_bud_rot`}
                   component={ReactSelectInput}
                   props={{
                     options: ratingOptions,
                   }}
            />
          </td>
          <td className='align-center'>
            <Field name={`${plant}.rating_avg_stretching`}
                   component={ReactSelectInput}
                   props={{
                     options: ratingOptions,
                   }}
            />
          </td>
          <td className='align-center'>
            <Field name={`${plant}.rating_avg_airy_buds`}
                   component={ReactSelectInput}
                   props={{
                     options: ratingOptions,
                   }}
            />
          </td>
          <td className='align-center'>
            <Field name={`${plant}.rating_avg_total_quality`}
                   component={ReactSelectInput}
                   props={{
                     options: extendedRatingOptions,
                   }}
            />
          </td>
        </tr>);
      }) : null}
      </tbody>
    </Table>
  );
};

IndividualPlantInformation.propTypes = {
  use_overall: PropTypes.bool,
  fields: PropTypes.object.isRequired,
  selectedPlants: PropTypes.array.isRequired,
  change: PropTypes.func.isRequired,
  tracking: PropTypes.bool.isRequired,
  integrationState: PropTypes.object.isRequired,
  allowDistributedWeights: PropTypes.bool,
  remaining_wet_weight_harvest: PropTypes.number,
  remaining_wet_other_material_weight: PropTypes.number,
  trackPlantsAsGroups: PropTypes.bool,
  selectableUoms: PropTypes.array,
  uom: PropTypes.string.isRequired
};

export default IndividualPlantInformation;
