import {createSelector} from 'reselect';
import moment from 'moment';
import isEmpty from 'lodash.isempty';
import get from 'lodash.get';
import {getImageUrl} from '../util/images';
import {PERSON} from '../constants/imageUrls';
import * as itemNames from '../constants/itemNames';
import * as dataNames from '../constants/dataNames';
import * as applicationModes from '../constants/applicationModes';

import {getActiveFacility, getDetailedFacilitiesSortedByName} from './facilitiesSelectors';
import {hasOrgFacilityPALeaf} from './integrationSettingsSelectors';
import {getApplicationMode} from './applicationModesSelectors';
import {buildCompleteInternationalPhoneNumber} from '../util/formatHelper';
import {getInternationalDefaultLanguage, getPhoneShouldIncludeCountryCode} from './InternationalOperationsSelectors';

export const getSelectedUser = (state) => state[itemNames.selectedUser];

export const getCurrentFacilityUsers = (state) => state[dataNames.currentFacilityUsers];
export const getCurrentUser = (state) => state.user;
const getLocations = (state) => state.salesLocations;
export const getCurrentLocation = (state) => state.user.currentLocation;
const getSalesRegisters = (state) => state.salesRegisters;
export const getCurrentRegister = (state) => state.user.currentRegister;
const getCurrentTimezone = (state) => state.timezone;
const getImage = (state) => state[itemNames.image];
const getImages = (state) => state[dataNames.images];
const getRoles = (state) => state[dataNames.roles];
const getProfileImage = (state) => state[itemNames.profileImage];
const getIntegrationSettings = (state) => state[itemNames.integrationSettings];
export const getEmployeeTitles = (state) => state[dataNames.employeeTitles];

const getExpirePasswordsSettings = (state) => state[itemNames.expirePasswordsSettings];

const getActive = (_, props) => props.active;
const getUserId = (_, props) => props.user_id;
export const getPermissions = (_, props) => props.permissions;

export const getCurrentFacilityUserOptions = createSelector(
  [getCurrentFacilityUsers],
  (users) =>
    users
      .filter((user) => user.active)
      .map((user) => ({
        ...user,
        displayName: `${user.first_name} ${user.last_name}`
      }))
);

/***
 * Went back to using long test instead of get(users, keyString) because some facilities have dots in the facility.key
 */
export const getCurrentUserPermissions = createSelector(
  [getCurrentUser, getActiveFacility],
  (user, facility) => {
    return facility &&
      facility.key &&
      user &&
      user.permissions &&
      user.permissions[facility.key] &&
      user.permissions[facility.key].permissions
      ? user.permissions[facility.key].permissions
      : {};
  }
);

export const userHasPermission = createSelector(
  [getCurrentUser, getCurrentUserPermissions, getPermissions],
  (user, userPermissions, permissions) => {
    if(get(user, 'isMasterUser')) {
      return true;
    } else if (permissions && permissions.length) {
      const matchedPermissions = permissions.filter((x) =>
        userPermissions && userPermissions[x] ? userPermissions[x] : null
      );
      return matchedPermissions.length > 0;
    } else {
      return true;
    }
  }
);

/**
 * This is set on Mt Olympus on the edit user page
 */
export const userIsMJPAdmin = createSelector(
  [getCurrentUser],
  (user) => {
    return get(user, 'isMasterUser');
  }
);

/**
 * Returns a FUNCTION which accepts a permission string or array of permission strings
 * and returns true if any of the permissions passed in
 * are present in the users permissions.
 */
export const getUserHasOneOfPermissionsFunction = createSelector(
  [getCurrentUser, getCurrentUserPermissions],
  (user, perms) => {
    return (permissions) => {
      if (!Array.isArray(permissions)) {
        permissions = [permissions];
      }
      return permissions.filter((perm) => {
        return perms && perms[perm] ? perms[perm] : false;
      }).length > 0;
    };
  });


/***
 * Factory creates a distinct function for the given targetPermission or an array of targetPermissions where the user must have one.
 * Usage:  Call function outside of components (before) so it doesn't generate lots of functions.  Pass in the target permission and name
 * the return value.  eg const canManageInternalTransfers = getUserHasPermissionFunc('manage_internal_transfers');
 * Then use in map state to props as canManageInternalTransfers(state);
 * @param targetPermission
 */
export const getUserHasPermissionFunc = (targetPermission) =>
  createSelector(
    [getCurrentUser, getCurrentUserPermissions],
    (user, userPermissions) => {
      if (user.isMasterUser) {
        return true;
      }
      if (typeof targetPermission === 'string') {
        return Boolean(userPermissions && userPermissions[targetPermission]);
      }
      // If not string must be array
      return Boolean(targetPermission.filter((permission) => userPermissions && userPermissions[permission]));
    }
  );

/***
 * Convenience function for self documentation leveraging the getUserHasPermissionFunc.
 * @param targetPermissions
 */
// export const getUserHasOneOfThesePermissions = (targetPermissions) => {
//   return getUserHasPermissionFunc(targetPermissions);
// };

export const userCanSelectRegistersLocations = createSelector(
  [getActiveFacility, getCurrentUser, userHasPermission],
  (facility, user, hasPermission) => {
    return user.authenticated && !isEmpty(facility) && facility.type === 'dispensary' && hasPermission;
  }
);

export const userNeedsRegistersLocations = createSelector(
  [userCanSelectRegistersLocations, getLocations, getCurrentLocation, getSalesRegisters, getCurrentRegister],
  (selectable, locations, currentLocation, registers, currentRegister) => {
    return Boolean(
      selectable &&
        ((locations && locations.length) || registers) &&
        !((currentLocation || currentLocation === 0) && (currentRegister || currentRegister === 0))
    );
  }
);

export const getCurrentFacilityUserById = createSelector(
  getUserId,
  getCurrentFacilityUsers,
  (userId, users) =>
    users.reduce((previous, current) => {
      return current.id === userId ? current : previous;
    }, null)
);


const getCurrentUserFacilitiesConfigurationPacks = createSelector(
  [getCurrentUser],
  (user) => {
    return Object.keys(get(user, 'permissions', [])).map( facilitKey => {
      return user.permissions[facilitKey].configuration_packs;
    });
  }
);


// This selector checks if one of the user's facilities has a Utah config pack.
export const isSsoEnabled = createSelector(
  [getIntegrationSettings, getCurrentUserFacilitiesConfigurationPacks],
  (integrationSettings, arraysOfConfigPacks) => {
    return arraysOfConfigPacks.some(configPacks => configPacks.some(configPack => configPack.includes('utah')));
  }
);

export const getCreateUserInitialValues = createSelector(
  [getActiveFacility, getApplicationMode, getDetailedFacilitiesSortedByName, isSsoEnabled, getInternationalDefaultLanguage],
  (activeFacility, applicationMode, facilities, ssoEnabled, defaultLanguage) => {
    const isLeafPa = applicationMode === applicationModes.leafPA;

    const sso_user_has_primary_id = false;
    const sso_id = null;
    const sso_id_record = ssoEnabled ?  {idp_name : 'utah'} : {};

    return {
      phones: [{ value: '' }],
      facilityRoles: facilities.map((facility) => ({ facility_id: facility.id, facility: facility, role_ids: [] })),
      tooltip_settings: false,
      is_create: true,
      language:defaultLanguage,
      is_pa_facility: isLeafPa,
      active: isLeafPa ? 0 : 1,
      authority_integration_id: false,
      sso_enabled: ssoEnabled,
      sso_id,
      sso_id_record,
      sso_user_has_primary_id,
      country_code: ' ',
      user_type: 'platform_user'
    };
  }
);

export const getModifyUserInitialValues = createSelector(
  [
    getSelectedUser,
    getDetailedFacilitiesSortedByName,
    getCurrentTimezone,
    getImage,
    getRoles,
    getApplicationMode,
    getIntegrationSettings,
    isSsoEnabled,
    getPhoneShouldIncludeCountryCode
  ],
  (selectedUser, facilities, timezone, image, detailedRoles, applicationMode, integrationSettings, ssoEnabled, includeCountryCode) => {
    if (!selectedUser.id) {
      return null;
    }
    const isLeafPa = applicationMode === applicationModes.leafPA;
    const employeeId =
      selectedUser.ids && selectedUser.ids.length
        ? selectedUser.ids.find((id) => id.type === 'Employee ID')
        : { identification_number: '' };
    const complianceId =
      selectedUser.ids && selectedUser.ids.length
        ? selectedUser.ids.find((id) => id.type === 'Compliance ID')
        : { identification_number: '' };
    const address =
      selectedUser.addresses && selectedUser.addresses.length > 0
        ? selectedUser.addresses[0]
        : {
          street_address_1: '',
          city: '',
          province_code: '',
          postal_code: '',
          country_code: ''
        };

    const facilityRoles = facilities.map((facility) => {
      const facilityModuleIds =
        facility && facility.facility_modules ? facility.facility_modules.map((module) => module.module_id) : [];

      const role_ids = selectedUser.roles
        ? selectedUser.roles
          .filter((role) => {
            const detailedRole = detailedRoles.find((dr) => dr.id === role.id);
            const intersectionOfIds = detailedRole
                ? facilityModuleIds.filter((id) => detailedRole.module_ids.indexOf(id) !== -1)
                : [];
            return role.facility === facility.id && intersectionOfIds.length > 0;
          })
          .map((role) => role.id)
        : [];

      const integrationForFacility = selectedUser.integration_ids.filter(
        (integration) => integration.facility_id === facility.id
      );
      return {
        facility_id: facility.id,
        facility,
        role_ids,
        leaf_access_level: integrationForFacility.length ? integrationForFacility[0].access_level : 'none'
      };
    });

    // Find an return active state integration record or false
    const integration_id = selectedUser.integration_ids.reduce((acc, record) => {
      if (record.type === 'leaf_state_integration_id' && record.active) acc = record;
      return acc;
    }, false);

    let sso_user_has_primary_id = false;
    let sso_id = null;
    let sso_id_record = {};

    if (ssoEnabled) {
      // SSO identity
      // Select only the user_sso_id record for the utah IDP
      const sso_ids = get(selectedUser, 'sso_ids', []).filter(id => id.idp_name === 'utah');
      sso_id_record = sso_ids.length ? sso_ids[0] : {idp_name : 'utah'};

      //  Get the best Id: If we don't have a primary_id, then we allow to edit and save the secondary_id.
      // if we do have a primary_id, we just display it and do not allow to edit it.
      sso_id = get(sso_id_record, 'secondary_id', null);
      sso_user_has_primary_id = !!get(sso_id_record, 'primary_id', null);
    }

    return {
      name: selectedUser.name,
      password: selectedUser.password,
      user_type: selectedUser.user_type,
      card_swipe_id: selectedUser.card_swipe_id,
      email: selectedUser.email,
      confirm_email: selectedUser.email,
      state_id: complianceId ? complianceId.identification_number : '',
      employee_id: employeeId ? employeeId.identification_number : '',
      authentication_key: selectedUser.authentication_key,
      first_name: selectedUser.first_name,
      middle_name: selectedUser.middle_name,
      last_name: selectedUser.last_name,
      birth_date:
        selectedUser.birth_date && selectedUser.birth_date !== '0000-00-00 00:00:00'
          ? moment(selectedUser.birth_date)
          : '',
      address: address.street_address_1,
      city: address.city,
      state: address.province_code,
      country_code: address.country_code,
      zip_code: address.postal_code,
      phones: selectedUser.phone ? selectedUser.phone.map((phone) => ({ value: buildCompleteInternationalPhoneNumber(phone.number, phone.phone_country_code, includeCountryCode) })) : [],
      language: selectedUser.language,
      facilityRoles: facilityRoles,
      hire_date:
        selectedUser.hire_date && selectedUser.hire_date !== '0000-00-00 00:00:00'
          ? moment(selectedUser.hire_date)
          : '',
      termination_date:
        selectedUser.termination_date && selectedUser.termination_date !== '0000-00-00 00:00:00'
          ? moment(selectedUser.termination_date)
          : '',
      vehicle_make: selectedUser.vehicle_make,
      vehicle_model: selectedUser.vehicle_model,
      vehicle_license: selectedUser.vehicle_license,
      image_file_id: (image && image.id) || selectedUser.image_file_id,
      image_file: selectedUser.image_file && selectedUser.image_file.id ? selectedUser.image_file : image,
      tooltip_settings: false,
      active: selectedUser.active,
      approval_status: selectedUser.approval_status,
      state_integration_id: selectedUser.state_integration_id, // Populated manually or by authority depending on integration
      // For leaf Pa if leaf disables the user, this value sets false, which is used to control ability to toggle status in UI
      authority_integration_id: isLeafPa ? integration_id : selectedUser.state_integration_id,
      sso_enabled: ssoEnabled,
      sso_id,
      sso_id_record,
      sso_user_has_primary_id,
      employee_title: selectedUser.employee_title_id
    };
  }
);

export const getUsersForList = createSelector(
  [getCurrentFacilityUsers, getImages, getActive, getPhoneShouldIncludeCountryCode],
  (users, images, active, includeCountryCode) => {
    if (users && images) {
      return users
        .filter((user) => user.active === active)
        .map((user) => {
          const primaryPhone = user.phone && user.phone.length ? user.phone[0] : { number: '' };
          const comlianceId = Array.isArray(user.ids) ? user.ids.find((id) => id.type === 'Compliance ID') : false;
          const roleNames = Array.isArray(user.roles)
            ? user.roles.map((role) => {
              return role.role_name;
            })
            : [];
          const image = Array.isArray(images) ? images.find((image) => image.id === user.image_file_id) : false;
          const user_image = image ? getImageUrl(image, '50x50', PERSON) : null;
          return {
            ...user,
            user_image,
            full_name: user.first_name + ' ' + user.last_name,
            phone_number: buildCompleteInternationalPhoneNumber(primaryPhone.number, primaryPhone.phone_country_code, includeCountryCode),
            roles: roleNames.join(', '),
            license_number: comlianceId && comlianceId.identification_number,
            expiration_date: comlianceId && comlianceId.expired_at
          };
        });
    }
  }
);

export const getCoreUserPasswordExpiration = createSelector(
  [getExpirePasswordsSettings, hasOrgFacilityPALeaf],
  (settings, hasOrgFacilityPALeaf) => {
    let expirationPeriod = hasOrgFacilityPALeaf ? 30 : 0;
    let expirationReminderPeriod = 0;

    if (settings.core_user_password_expiration_period && !hasOrgFacilityPALeaf) {
      expirationPeriod = settings.core_user_password_expiration_period.value || 0;
    }

    if (
      settings.core_user_password_expiration_reminder_period &&
      settings.core_user_password_expiration_reminder_period.value
    ) {
      expirationReminderPeriod = settings.core_user_password_expiration_reminder_period.value.length
        ? settings.core_user_password_expiration_reminder_period.value[0]['days']
        : 0;
    }

    return {
      expiration_period: parseInt(expirationPeriod),
      expiration_reminder_period: parseInt(expirationReminderPeriod)
    };
  }
);

export const getProfileImageUrl = createSelector(
  [getProfileImage],
  (profileImage) => {
    const defaultProfileUrl = '/images/user-avatar.png';
    return getImageUrl(profileImage, '50x50', defaultProfileUrl);
  }
);
