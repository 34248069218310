import values from 'lodash.values';
import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';
import {i18nReducer} from 'react-redux-i18n';
import {reducer as formReducer} from 'redux-form';
import {reducer as toastrReducer} from 'react-redux-toastr';
import initialState from '../constants/initialState';
import {LOGOUT_SUCCESS} from '../constants/actionTypes';
import * as dataNames from '../constants/dataNames';
import * as itemNames from '../constants/itemNames';
import dataReducerFactory from './factories/dataReducerFactory';
import itemReducerFactory from './factories/itemReducerFactory';
import selectedDataReducerFactory from './factories/selectedDataReducerFactory';
import user from './userReducer';
import pagination from './paginationReducer';
import selectedBatches from './selectedBatchesReducer';
import menus from './menusReducer';
import facility from './facilityReducer';
import facilitySwitch from './facilitySwitchReducer';
import facilities from './facilitiesReducer';
import messages from './systemMessageReducer';
import headers from './apiHeaderReducer';
import spinner from './spinnerReducer';
import customerFilters from './customerFiltersReducer';
import productFilter from './productFilterReducer';
import cart from './cart';
import system from './systemReducer';
import timezone from './timezoneReducer';
import selectedPackages from './selectedPackagesReducer';
import currentPlantForm from './currentPlantFormReducer';
import selectedPackageInfo from './selectedPackageInfoReducer';
import getSelectedDataName from '../constants/helpers/getSelectedDataName';
import dataUpdated from './dataUpdateReducer';
import qzTray from './printerReducers';
import meta from './apiMetaDataReducer';
import forms from './forms';
import productLookup from './productLookupReducer';
import openFormReducer from './forms/openFormReducer';

//Factory Reducers
const items = {};
values(itemNames).map(name => items[name] = itemReducerFactory(name));
const data = {};
values(dataNames).map(name => data[name] = dataReducerFactory(name));
const selectedData = {};
values(dataNames).map(name => {
  const selectedDataName = getSelectedDataName(name);
  return selectedData[selectedDataName] = selectedDataReducerFactory(selectedDataName);
});

const combinedReducer = combineReducers(Object.assign({}, items, data, selectedData, {
  user,
  selectedBatches,
  selectedPackages,
  facility,
  facilitySwitch,
  facilities,
  menus,
  messages,
  headers,
  spinner,
  pagination,
  customerFilters,
  productFilter,
  qzTray,
  cart,
  selectedPackageInfo,
  system,
  currentPlantForm,
  meta,
  timezone,
  dataUpdated,
  [dataNames.openForms]: openFormReducer,
  routing: routerReducer,
  i18n: i18nReducer,
  form: formReducer.plugin(forms),
  toastr: toastrReducer,
  productLookup,
  env: function env (state = {}) {
    return state;
  }
}));

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    state = Object.assign({}, initialState, {i18n: state.i18n, env: state.env});
  }
  return combinedReducer(state, action);
};

export default rootReducer;
