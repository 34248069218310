import React from 'react';
import {reduxForm, formValueSelector, Field} from 'redux-form';
import {connect} from 'react-redux';
import {Button, Col, Row} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import {goBack} from 'react-router-redux';

import ReactSelectInput from '../../common/form/ReactSelectInput';
import TextInput from '../../common/form/TextInput';
import CheckBoxInput from '../../common/form/CheckBoxInput';

import {MERCHANT_PROFILE} from '../../../constants/forms';
import * as itemNames from '../../../constants/itemNames';
import * as dataNames from '../../../constants/dataNames';
import {getItem, postItem, putItem, getUnpaginatedData} from '../../../actions/apiActions';
import {unsetItem} from '../../../actions/itemActions';
import {unsetData} from '../../../actions/dataActions';
import {
  getMerchantProfileInitialValues,
  getMerchantsForSelection
} from '../../../selectors/merchantConfigurationSelectors';
import {getCurrentFacilityId} from '../../../selectors/facilitiesSelectors';


class MerchantProfilePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      companyLoading: false,
      merchantsLoading: false,
      merchantProfileLoading: false
    };

    this.setLoadingState = this.setLoadingState.bind(this);
  }

  componentDidMount() {
    const {params: {id}, getItem, getUnpaginatedData, facility_id} = this.props;

    this.setCompanyLoadingState(true);
    this.setMerchantsLoadingState(true);

    getItem('/api/payment/companies/find', itemNames.merchantConfigurationCompany, null, {reference: facility_id})
      .then(() => this.setCompanyLoadingState(false));
    getUnpaginatedData('/api/payment/merchant/search', dataNames.merchantConfigurationMerchants)
      .then(() => this.setMerchantsLoadingState(false));

    if (id) {
      this.setMerchantProfileLoadingState(true);
      getItem('/api/payment/merchant_profiles/find', itemNames.merchantProfile, null, {id})
        .then(() => this.setMerchantProfileLoadingState(false));
    }
  }

  componentWillUnmount() {
    const {unsetItem, unsetData} = this.props;

    unsetItem(itemNames.merchantProfile);
    unsetData(dataNames.merchantConfigurationMerchants);
  }

  setCompanyLoadingState(value) {
    this.setLoadingState('companyLoading', value);
  }

  setMerchantsLoadingState(value) {
    this.setLoadingState('merchantsLoading', value);
  }

  setMerchantProfileLoadingState(value) {
    this.setLoadingState('merchantProfileLoading', value);
  }

  setLoadingState(key, value) {
    this.setState({[key]: value});
  }

  /**
   * bonus function, not currently used but could be handy if we
   * change up loading state(s) and/or rendering
   *
   * @returns {JSX.Element|boolean}
   */
  loading() {
    const {merchantsLoading, merchantProfileLoading} = this.state;
    const loadingRows = [];

    if (!merchantsLoading && !merchantProfileLoading) {
      return false;
    }

    merchantsLoading && loadingRows.push('Loading Merchants for Selection...');
    merchantProfileLoading && loadingRows.push('Loading Merchant Profile to Modify...');

    return (
      <div>
        {loadingRows.map(row => <div>{row}</div>)}
      </div>
    );
  }

  isModifying() {
    const {params: {id}} = this.props;

    return Boolean(id);
  }

  render() {
    const {handleSubmit, merchants, id, initialValues} = this.props;
    const {companyLoading, merchantsLoading, merchantProfileLoading} = this.state;
    const isDefault = initialValues ? initialValues['default'] : false;

    return (
      <React.Fragment>
        <h1>{I18n.t('facility.setup.merchantProfile.header')}</h1>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col sm={3}>
              <Field name='merchant_key'
                     label={I18n.t('facility.setup.merchantProfile.form.merchantKey')}
                     component={ReactSelectInput}
                     options={merchants}
                     disabled={merchantsLoading}
                     placeholder={merchantsLoading ? I18n.t('facility.setup.merchantProfile.form.merchant_loading') : ''}
              />
            </Col>
          </Row>
          {companyLoading || merchantProfileLoading ?
            <div>{I18n.t('facility.setup.merchantProfile.loading')}</div> :
            <React.Fragment>
              <Row>
                <Col sm={3}>
                  <Field name='name'
                         label={I18n.t('facility.setup.merchantProfile.form.name')}
                         component={TextInput}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  <Field name='account_number'
                         label={I18n.t('facility.setup.merchantProfile.form.accountNumber')}
                         component={TextInput}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  <Field name='username'
                         label={I18n.t('facility.setup.merchantProfile.form.username')}
                         component={TextInput}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  <Field name='password'
                         label={I18n.t('facility.setup.merchantProfile.form.password')}
                         component={TextInput}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  <Field name='default'
                         label={I18n.t('facility.setup.merchantProfile.form.default')}
                         component={CheckBoxInput}
                         groupClassName='inline-checkbox'
                         disabled={(id && isDefault) ? true : false}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  <Field name='sandbox'
                         label={I18n.t('facility.setup.merchantProfile.form.sandbox')}
                         component={CheckBoxInput}
                         groupClassName='inline-checkbox'
                  />
                </Col>
              </Row>
              <Button type='submit' className='btn btn-primary'>
                {I18n.t('facility.setup.merchantProfile.form.submit')}
              </Button>
            </React.Fragment>
          }
        </form>
      </React.Fragment>
    );
  }
}


const submit = (values, dispatch, {postItem, putItem, goBack}) => {
  const [action, url] = values.id ?
    [putItem, `/api/payment/merchant_profiles/${values.id}`] :
    [postItem, '/api/payment/merchant_profiles'];

  action(url, values)
    .then(goBack);
};

const ReduxFormPage = reduxForm({
  form: MERCHANT_PROFILE,
  onSubmit: submit,
  enableReinitialize: true
})(MerchantProfilePage);

const valueSelector = formValueSelector(MERCHANT_PROFILE);

const mapStateToProps = (state) => ({
  initialValues: getMerchantProfileInitialValues(state),
  facility_id: getCurrentFacilityId(state),
  merchants: getMerchantsForSelection(state),
  id: valueSelector(state, 'id')
});

const mapDispatchToProps = ({
  getItem, unsetItem, postItem, putItem, getUnpaginatedData, unsetData, goBack
});

export default connect(mapStateToProps, mapDispatchToProps)(ReduxFormPage);



