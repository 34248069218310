import {I18n} from 'react-redux-i18n';
import {createSelector} from 'reselect';
import get from 'lodash.get';
import * as dataNames from '../constants/dataNames';
import {getFeedingSchedulesWithIngredients} from './feedingSchedulesSelectors';
import {getFilteredLSections} from './locationsSelectors';
import {getPlant, getPlantExternalId, trackPlantsAsGroup} from './plantsSelectors';
import {getTimezone} from './timezoneSelectors';
import {getCurrentFacilityUsers} from './usersSelectors';
import {isLeafIntegrator} from './integration/leafSelectors';
import {getDestroyReasonOptions} from './forms/destroyPlantsFormSelectors';
import {getStateIntegrator} from './integrationSelectors';

const getStages = state => state[dataNames.stages];
const getPartners = state => state[dataNames.partners];

export const getPlantHistoryRaw = state => state[dataNames.plantHistory];

export const getPlantHistory = createSelector(
  [getPlantHistoryRaw, getTimezone, getCurrentFacilityUsers],
  (history, timezone, users) => history.map((historyItem) => {
    const user = users.find(user => user.id === historyItem.user_id);
    const model = historyItem.model ? JSON.parse(historyItem.model) : null;

    return {
      ...historyItem,
      created_at: historyItem.created_at,
      event_date: historyItem.event_date,
      model,
      user_name: user ? `${user.first_name} ${user.last_name}` : historyItem.user_name
    };
  })
);

export const getFeedingScheduleIdsByPlantHistory = createSelector(
  [getPlantHistory],
  history => history.reduce(
    (acc, item) => {
      if (item.model) {
        const id = item.event_type === 'feeding_schedule' ? item.model.id : item.model.feeding_schedule_id;
        if (id && acc.indexOf(id) === -1) {
          acc.push(id);
        }
      }
      return acc;
    },
    []
  )
);

export const getPlantHistoryTableData = createSelector(
  [getPlant, getPlantHistory, getFilteredLSections, getFeedingSchedulesWithIngredients, getStages, getPlantExternalId, isLeafIntegrator, trackPlantsAsGroup, getDestroyReasonOptions, getStateIntegrator, getPartners],
  (plant, plantHistory, sections, schedules, stages, external_id, isLeaf, showQty, destroyReasons, stateIntegrator, partners) => plantHistory.reduce(
    (acc, historyItem) => {
      if (historyItem.message) {
        historyItem.message = fixPlantHistoryMessage(historyItem.message);
      }

      if (historyItem.event_type === 'waste_reported') {
        const model = {...acc.model || {}};
        const total_waste = (acc.model && acc.model.total_waste_weight) ? acc.model.total_waste_weight : 0;
        const waste_weight_total = historyItem.model && historyItem.model.waste_weight || 0;
        const plant_count = historyItem.model && historyItem.model.source_ids && historyItem.model.source_ids.length || 1;
        const waste_weight = waste_weight_total / plant_count;
        model.total_waste_weight = waste_weight + total_waste;
        acc.model = model;
        const item = historyItem.model ? {...historyItem, model: {...historyItem.model, waste_weight}} : historyItem;
        acc.history.push(item);
        return acc;
      }
      if (historyItem.event_type === 'feeding_schedule') {
        //Feeding schedule applied. Just push the item as is in order to not break the delta
        if (historyItem.model) {
          const schedule = schedules.find(schedule => schedule.id === historyItem.model.id) || {};
          const model = {...historyItem.model, ...schedule};
          acc.history.push({...historyItem, model});
        } else {
          acc.history.push(historyItem);
        }
        return acc;
      }
      if (historyItem.model) {
        const model = {...historyItem.model};
        const section = sections.find(section => section.id === model.section_id);
        const feedingSchedule = schedules.find(schedule => schedule.id === model.feeding_schedule_id);
        const schedule = model.feeding_schedule || feedingSchedule;
        const stage = stages.find(stage => stage.id === model.stage_id);
        const {strain, planting_batch} = model;
        const phenotype = strain && strain.phenotypes && strain.phenotypes.find(phenotype => phenotype.id === model.phenotype_id);
        const destroyReason = destroyReasons.find(reason => model.destruction && reason.value === model.destruction.notes);
        if (model.new_plant) {
          const new_plant_section = sections.find(section => section.id === model.new_plant.section_id);
          model.new_plant_section_name = new_plant_section && new_plant_section.name || (acc.model && model.new_plant.section_id === acc.model.new_plant.section_id && acc.model.new_plant_section_name);
        }

        //Some records go without all details included but we have to transfer data across them in order to display delta correctly
        model.batch_name = planting_batch && planting_batch.batch_name || (acc.model && model.planting_batch_id === acc.model.planting_batch_id && acc.model.batch_name) || (plant.planting_batch && plant.planting_batch.batch_name);
        model.section_name = section && section.name || (acc.model && model.section_id === acc.model.section_id && acc.model.section_name);
        model.feeding_schedule_name = schedule && schedule.schedule_name || (acc.model && model.feeding_schedule_id === acc.model.feeding_schedule_id && acc.model.schedule_name);
        model.feedingSchedule = feedingSchedule;
        model.stage_name = stage && stage.stage_name || (acc.model && model.stage_id === acc.model.stage_id && acc.model.stage_name);
        model.strain_name = strain && strain.strain_name || (acc.model && acc.model.strain_id === model.strain_id && acc.model.strain_name);
        model.phenotype_name = phenotype && phenotype.name || (acc.model && acc.model.phenotype_id === model.phenotype_id && acc.model.phenotype_name);
        model.total_waste_weight = (acc.model && acc.model.total_waste_weight) ? acc.model.total_waste_weight : 0;
        model.mother_plant_id = model.mother_plant ? model.mother_plant.plant_id : model.mother_plant_id;
        model.external_id = isLeaf && external_id; //Take care for leaf only
        model.qty = showQty ? model.qty : false;
        model.destruction_reason = destroyReason ? destroyReason.text : model.destruction && model.destruction.notes;
        model.testing_id = get(model, 'lab_results.testing_id', '');
        model.testing_date = get(model, 'lab_results.testing_date', '');
        model.lab_partner_id = get(model, 'lab_results.lab_partner_id', '');
        model.partner_name = (partners.find((partner) => partner.id === model.lab_partner_id) || {name: ''}).name;

        const delta = acc.model ? calculateDelta(acc.model, model, stateIntegrator) : null;
        acc.history.push({...historyItem, model, delta});
        acc.model = model;
      } else {
        acc.history.push(historyItem);
      }
      return acc;
    },
    {history: [], model: null}
  ).history
);

const fixPlantHistoryMessage = message => {
  const patternPlantedIn = new RegExp(/Planted in \w+/);
  const patternHarvestedInto = new RegExp(/Harvested into Harvest Batch \(\w+\)/);
  const patternWasteReported = new RegExp(/Reported Waste\/Waste Package Created/);

  if (patternPlantedIn.test(message)) {
    return 'Plant Created';
  } else if (patternHarvestedInto.test(message)) {
    return message.replace('(', '').replace(')', '');
  } else if (patternWasteReported.test(message)) {
    return I18n.t('plants.waste.wasteReportedPlantModified');
  }
  return message;
};

const deltaConfig = [
  {
    name: 'plantId',
    get: model => model.plant_id,
  },
  {
    name: 'harvestBatch',
    get: model => model.planting_batch_id,
    getViewValue: (model) => model.batch_name,
  },
  {
    name: 'strain',
    get: model => model.strain_id,
    getViewValue: model => model.strain_name,
  },
  {
    name: 'phenotype',
    get: model => model.phenotype_id,
    getViewValue: model => model.phenotype_name,
  },
  {
    name: 'section',
    get: model => model.section_id,
    getViewValue: model => model.section_name,
  },
  {
    name: 'stage',
    get: model => model.stage_id,
    getViewValue: model => model.stage_name,
  },
  {
    name: 'feedingSchedule',
    get: model => model.feeding_schedule_id,
    getViewValue: model => model.feeding_schedule_name,
  },
  {
    name: 'trackingId',
    get: model => model.state_integration_tracking_id,
  },
  {
    name: 'qty',
    get: model => model.qty
  }
];

const integratorsDeltaConfig = {
  biotrack: [
    {
      name: 'sourceID',
      get: model => model.source_item_package_code
    }
  ]
};

function getValue(model, fieldConfig) {
  if (typeof fieldConfig.get === 'function') {
    return fieldConfig.get(model);
  }
  return model[fieldConfig.name];
}

function isValueChanged(prevValue, currentValue, fieldConfig) {
  if (typeof fieldConfig.compare === 'function') {
    return fieldConfig.compare(prevValue, currentValue);
  }
  return prevValue !== currentValue;
}

function getViewValue(model, fieldConfig) {
  if (typeof fieldConfig.getViewValue === 'function') {
    return fieldConfig.getViewValue(model) || 'null';
  }
  return getValue(model, fieldConfig) || 'null';
}

function getDeltaConfig(stateIntegrator){
  const integratorDeltaConfig = stateIntegrator && integratorsDeltaConfig[stateIntegrator] || [];
  return [...deltaConfig, ...integratorDeltaConfig] || [];
}

function calculateDelta(prevState, currentState, stateIntegrator) {
  return getDeltaConfig(stateIntegrator).reduce(
    (acc, fieldConfig) => {
      const prevValue = getValue(prevState, fieldConfig);
      const currentValue = getValue(currentState, fieldConfig);
      if (isValueChanged(prevValue, currentValue, fieldConfig)) {
        acc.push({
          name: fieldConfig.name,
          prev: getViewValue(prevState, fieldConfig),
          current: getViewValue(currentState, fieldConfig),
        });
      }
      return acc;
    },
    []
  );
}
