import {createSelector} from 'reselect';
import * as dataNames from '../../constants/dataNames';

/**
 * List of inventories from redux, at least uses on CreateSalesOrderPage
 * @see CreateSalesOrderPage.js
 * @param state
 * @returns {*|Array}
 */
export const getInventoryList = (state) => (
  state[dataNames.inventoryItems] || []
);

/**
 * Returning a list of inventories filtered by criteria
 * @type {Reselect.Selector<any, function(*, *): *> | Reselect.Selector<any, any>}
 * @example
 * connect(
 *  () => ({
 *    getInventoryBy: getInventoryBy(state)
 *  })
 * )(Component)
 *
 * ...
 *
 * const inventories = getInventoryBy('item_master_id', item_master.id);
 */
export const getInventoryBy = createSelector(
  [getInventoryList],
  (inventories) => (fieldName, value) => inventories.filter(item => item[fieldName] === value)
);