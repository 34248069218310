import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {Row, Col} from 'react-bootstrap';
import * as dataNames from '../../../../../../constants/dataNames';
import SubmitSection from '../../../../../common/form/SubmitSection';
import RewardsSettingsPanel from './RewardSettingsPanel';


const RewardSettingsForm  = (props) => {

  const {invalid, submitting, pristine, handleSubmit, onSaveAndStay, customerGroups} = props;

  const settings = {
    actionSettings: {
      saveAndStay: {
        submitting,
        invalid,
        disabled: invalid || submitting || pristine,
        action: () => {
          onSaveAndStay();
          handleSubmit();
        },
        text: I18n.t('common.form.saveAndStay')
      },
      submit: {
        submitting,
        invalid,
        pristine,
        text: I18n.t('common.form.save')
      }
    }
  };

  return(
    <form onSubmit={props.handleSubmit} noValidate={true}>
      <Row className='no-gutters'>
      {
        props.settings.map((group, index) => {
          const md = (group.group === 'expandable') ? 12 : 6;
          return (<Col md={md} key={index}>
            <RewardsSettingsPanel key={index} group={group.group} settings={group.settings} change={props.change} formSelector={props.formSelector} disabled={props.disabled} customerGroups={customerGroups} />
          </Col>);
        })
      }
      <SubmitSection settings={settings}/>
      </Row>
    </form>
  );
};

RewardSettingsForm.propTypes = {
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  customerGroups: PropTypes.array.isRequired,
  settings: PropTypes.array.isRequired,
  formSelector: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  onSaveAndStay: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    products: state[dataNames.products],
    categories: state[dataNames.categories],
    vendors: state[dataNames.partners],
  };
}

export default connect(mapStateToProps)(RewardSettingsForm);
