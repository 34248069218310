/* eslint-disable import/no-mutable-exports*/
import round from 'lodash.round';
import {connect} from 'react-redux';
import {reduxForm, formValueSelector} from 'redux-form';
import validate from './validate';
import ModifyHarvestBatchForm from './ModifyHarvestBatchForm';
import {MODIFY_HARVEST_BATCH_FORM} from '../../../constants/forms';

export const ModifyHarvestBatchFormWrapper = reduxForm({
  form: MODIFY_HARVEST_BATCH_FORM,
  validate}
)(ModifyHarvestBatchForm);

const selector = formValueSelector(MODIFY_HARVEST_BATCH_FORM);
export default connect(state => {
  let originalWeight = selector(state, 'wet_weight_harvest');
  let originalWaste = selector(state, 'total_waste_recorded');
  let reportWaste = selector(state, 'reportWaste');
  let newWeight = selector(state, 'newWeight');
  const currentWeight = selector(state, 'current_weight');
  const finalWeight = selector(state, 'finalWeight');
  const hasFinalWeight = selector(state, 'hasFinalWeight');

  originalWeight = originalWeight ? parseFloat(originalWeight) : 0;
  originalWaste = originalWaste ? parseFloat(originalWaste) : 0;
  reportWaste = reportWaste ? parseFloat(reportWaste) : 0;
  newWeight = newWeight ? parseFloat(newWeight) : 0;

  const moistureLoss = originalWeight
    ? round(100 * (1 - ((originalWaste + reportWaste + newWeight) / originalWeight)), 2)
    : '';
  const increasedWeight = round((currentWeight - reportWaste) - newWeight, 4) < 0;
  return {
    newWeight,
    moistureLoss: moistureLoss > 0 ? (moistureLoss === 100 ? 0 : moistureLoss) : 0,
    finalWeight,
    hasFinalWeight,
    increasedWeight
  };
})(ModifyHarvestBatchFormWrapper);
