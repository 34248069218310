/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {push} from 'react-router-redux';

import {deleteData, getUnpaginatedData} from '../../actions/apiActions';
import {clearSelectedDataAction} from '../../actions/selectedDataActions';
import * as dataNames from '../../constants/dataNames';
import { getVehicles } from '../../selectors/vehiclesSelectors';
import SingleActionColumn from '../common/grid/columns/SingleActionColumn';

import PageTitle from '../common/PageTitle';
import TablePageWrapper from '../common/grid/TablePageWrapper';

export class VehicleListingPage extends React.PureComponent{
  constructor(props){
    super(props);
    const columns = [
      {
        name: I18n.t('cultivation.vehicles.table.nickname'),
        dataId: 'nickname',
        width:'250px'
      },
      {
        name: I18n.t('cultivation.vehicles.table.make'),
        dataId: 'make',
        width:'250px'
      },
      {
        name: I18n.t('cultivation.vehicles.table.model'),
        dataId: 'model',
        width:'250px'
      },
      {
        name: I18n.t('cultivation.vehicles.table.plateNumber'),
        dataId: 'license_number',
        width:'250px'
      },
      {
        name: I18n.t('cultivation.vehicles.table.year'),
        dataId: 'year',
        width:'5%'
      },
      {
        name: I18n.t('cultivation.vehicles.table.insurance'),
        dataId: 'insurance_company',
        width:'250px'
      },
      {
        name: I18n.t('cultivation.vehicles.table.phone'),
        dataId: 'insurance_phone_number',
        width:'250px'
      },
      {
        hidden: false,
        dataSort: false,
        csvFormatter: (cell) => { return I18n.t('cultivation.vehicles.actions.delete'); },
        formatter: SingleActionColumn({
          label: 'cultivation.vehicles.actions.delete',
          action: (e, row) => this.deleteVehicle(e, row)
        }),
        columnClassName: 'actions-column',
        width: '170px'
      }
    ];
    this.editVehicle = this.editVehicle.bind(this);
    this.addVehicle = this.addVehicle.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.state = {
      columns,
      selectedVehicles: []
    };
  }

  componentWillMount() {
    this.props.actions.getUnpaginatedData('/api/vehicles', dataNames.vehicles);
  }

  handleSelect (isSelected, rows) {
    const ids = rows.map(row => row.id);
    if(isSelected){
      this.setState({selectedVehicles: ids});
    }else {
      this.setState({selectedVehicles: []});
    }
  }

  addVehicle(){
    this.props.actions.push('/vehicles/create');
  }

  editVehicle(){
    this.props.actions.push(`/vehicles/modify/${this.state.selectedVehicles[0]}`);
  }

  deleteVehicle(event, row){
    event.stopPropagation();
    this.props.actions.deleteData(
      `/api/vehicles`,
      row.id,
      dataNames.vehicles,
      {
        success: 'cultivation.vehicles.delete.success',
        fail: 'cultivation.vehicles.delete.fail',
      },
      null,
      () => {
        this.props.actions.clearSelectedDataAction(dataNames.vehicles);
      }
    );
  }

  render(){
    const {vehicles} = this.props;
    const {columns} = this.state;
    const actions = [
      {
        id: 'addVehicle',
        func: this.addVehicle ,
        text: 'cultivation.vehicles.actions.add',
        requireSelect: false,
        glyph: 'plus',
      },
      {
        id: 'editVehicle',
        func: this.editVehicle,
        text: 'cultivation.vehicles.actions.edit',
        requireSelect: true,
        glyph: 'edit',
      }
    ];

    return(
      <div>
        <PageTitle primaryText={''} secondaryText = {I18n.t('cultivation.vehicles.listingTitle')}/>

        <TablePageWrapper
          ref = 'tablePage'
          settingKey='vehicles'
          columns = {columns}
          actions = {actions}
          data = {vehicles}
          handleSelect = {this.handleSelect}
          selectedRows = {this.state.selectedVehicles}
          hideExport = {true}
          hideScanSearch = {true}
          external={false}
          bstProps={{
            ignoreSinglePage: true,
            selectRow: {
              mode: 'radio',
              clickToSelect: false,
              selected: this.state.selectedVehicles,
              onSelect: (row, isSelected) => this.handleSelect(isSelected, [row])
            },
          }}
        />
      </div>

    );
  }
}

VehicleListingPage.propTypes = {
  actions: PropTypes.shape({
    getUnpaginatedData: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    deleteData: PropTypes.func.isRequired,
    clearSelectedDataAction: PropTypes.func.isRequired
  }),
  vehicles: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  const vehicles = getVehicles(state);

  return{
    vehicles
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {getUnpaginatedData, deleteData, clearSelectedDataAction, push};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (VehicleListingPage);
