import React from 'react';
import PropTypes from 'prop-types';
import difference from 'lodash.difference';
import {I18n} from 'react-redux-i18n';
import {Table} from 'react-bootstrap';
import * as dataNames from '../../../../../constants/dataNames';

function getAmountText(orderWeight) {
  return orderWeight > 0 ? `${orderWeight} GR` : 'N/A';
}

function getAmountClassName(orderWeight) {
  return  orderWeight > 0 ? 'text-success' : 'text-muted';
}


class BiotrackPurchasableAmountComponent extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.loadStats = this.loadStats.bind(this);
  }

  componentDidMount() {
    const {isBiotrack, customer} = this.props;
    if (isBiotrack && customer && customer.id) {
      this.loadStats();
    }
  }

  componentWillMount() {
    this.getItemMasters();
    this.props.componentWillMount(this.props);
  }

  componentWillUnmount() {
    this.props.componentWillUnmount();
  }

  getItemMasters(ids = this.props.itemMasterIds) {
    if(!ids.length){ return; }
    const payload = {
      query: 'matchall',
      filter: '(' + ids.map(id => `id:${id}`).join(' OR ') + ')',
      start: 0,
      size: 10000,
    };
    this.props.actions.getDataBatchByPost('/api/search/item_masters', payload, dataNames.products, {failed: 'products.get.failed'});
  }

  loadStats(consumer_id = this.props.customer.id) {
    return this.props.actions.getDataBatch(
      `/api/biotrack/sales/card_info/${consumer_id}`,
      dataNames.biotrackPatientCards,
      {failed: 'biotrackPurchasableAmount.get.failed'},
      null,
      () => {},
      null,
      'consumer_id'
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isBiotrack) {
      if (nextProps.customer.id !== this.props.customer.id) {
        this.loadStats(nextProps.customer.id);
      }
      if (nextProps.showOrderData && nextProps.itemMasterIds !== this.props.itemMasterIds) {
        const diff = difference(nextProps.itemMasterIds, this.props.itemMasterIds);
        if (diff.length > 0) {
          this.getItemMasters(diff);
        }
      }

      this.props.componentWillReceiveProps(this.props, nextProps);
    }
  }

  render() {
    const {showHeader, cartLimit, orderWeight, isBiotrack, className, showOrderData} = this.props;
    if (!isBiotrack) {
      return null;
    }

    return (
      <div className={`cure-purchased-amounts ${className || ''}`}>
        {showHeader ? <h4>{I18n.t('biotrackPurchasableAmount.title')}</h4> : null}
        <Table bordered={true} stripped={true} size='sm' className='text-muted'>
          <thead>
          <tr>
            <th title={I18n.t('biotrackPurchasableAmount.limit')}>
              {I18n.t('biotrackPurchasableAmount.limit')}
            </th>
            {showOrderData ?
              <th title={I18n.t('curePurchasableAmount.inOrder')}>
                {I18n.t('curePurchasableAmount.inOrder')}
              </th>
              : null
            }
          </tr>
          </thead>
          <tbody>
          <tr>
            <td title={cartLimit}>{cartLimit ? `${cartLimit} GR` : null}</td>
            {showOrderData ?
              <td
                title={getAmountText(orderWeight)}
                className={getAmountClassName(orderWeight)}>
                {getAmountText(orderWeight)}
              </td>
              : null
            }
          </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}

BiotrackPurchasableAmountComponent.propTypes = {
  actions: PropTypes.shape({
    getDataBatch: PropTypes.func.isRequired,
    getDataBatchByPost: PropTypes.func.isRequired,
  }).isRequired,
  customer: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  isBiotrack: PropTypes.bool.isRequired,
  showHeader: PropTypes.bool.isRequired,
  showOrderData: PropTypes.bool.isRequired,
  itemMasterIds: PropTypes.array.isRequired,
  cartLimit: PropTypes.number,
  orderWeight: PropTypes.number,
  className: PropTypes.string,
};

export default BiotrackPurchasableAmountComponent;
