import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Col, Alert} from 'react-bootstrap';
import {FaInfoCircle} from 'react-icons/fa';
import {connect} from 'react-redux';
import {getCurrentFacilityCountry} from '../../../selectors/facilitiesSelectors';
import TextInput from '../../common/form/TextInput';
import {validatePostalCode} from '../../common/form/validatePostalCode';
import {getPostalCodeLabel} from '../../../util/labelHelpers';

// TODO: This could  be factored out into a generic "MultiplePostalCodes fields" or folded into the "PostalCodeField" and having multiple postals as an option
class PostalCodeField extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.validate = this.validate.bind(this);
  }

  validate(value) {
    const {countryCode} = this.props;
    let error;
    const postalCodes = value ? (value + '').split(',').map( p => p.trim() ) : [];

    postalCodes.forEach((p) => {
      error = validatePostalCode(p, countryCode);
    });
    return error;
  }

  render() {
    const {show, name, countryCode} = this.props;
    const labelTranslationKey = getPostalCodeLabel(countryCode);
    const label = I18n.t(labelTranslationKey);
    const labelPlural = I18n.t(labelTranslationKey + '_plural');
    return show ? (
      <div>
        <Col className='form-fields-col' xs={6} md={4} lg={3}>
          <Field
            name={`${name}.zipcodes`}
            component={TextInput}
            validate={this.validate}
            props={{
              label: I18n.t('taxes.form.applicableDeliveryZipcodes', {label: labelPlural})
            }}/>
        </Col>
        <Col xs={12} lg={6}>
          <Alert variant='info'>
            <FaInfoCircle/>
            <span className='padding-left'>{I18n.t('taxes.form.zipcodeNotes', {label, labelPlural})}</span>
          </Alert>
        </Col>
      </div>
    ) : null;
  }
}

PostalCodeField.propTypes = {
  countryCode: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  show: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {
  const countryCode = ownProps.countryCode ? ownProps.countryCode : getCurrentFacilityCountry(state);
  return {
    countryCode,
    name: ownProps.name,
    show: ownProps.show,
  };
}

PostalCodeField.defaultProps = {
  show: true,
};


export default connect(mapStateToProps) (PostalCodeField);
