import {GET_ITEM_SUCCESS} from '../../constants/actionTypes';

const createHarvestPackages = (state, action) => {   // <----- 'crateHarvestPackages' is name of form given to reduxForm()
  // Set the package code for a package on the Create Harvest Package page when the package ID is retrieved from backend web service.
  if (!(action.type === GET_ITEM_SUCCESS && action.name === 'itemMasterPackageId' && state && state.values && state.values.packages)) {
    return state;
  }
  const {packageIndex, packageId} = action.payload.data;
  const newState = Object.assign({}, state);
  newState.values.packages[packageIndex].package_code = packageId;
  newState.fields.packages[packageIndex].package_code = {visited: true, touched: true};
  return newState;
};

export default createHarvestPackages;
