import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {OverlayTrigger, Popover, ButtonToolbar} from 'react-bootstrap';
import {FaInfoCircle} from 'react-icons/fa';
import moment from 'moment-timezone';
import get from 'lodash.get';
import cln from 'classnames';
import SimpleTable from '../common/grid/SimpleTable';
import * as itemNames from '../../constants/itemNames';
import ModalWrapper from '../common/ModalWrapper';
import RouteButton from '../common/RouteButton';
import PermissionButton from '../common/PermissionButton';
import {getItem, getDataByPost, getUnpaginatedData} from '../../actions/apiActions';
import {getTestResultsByReferenceId, getActiveTestResult, getTestResultDetails} from '../../selectors/testResultsSelectors';
import {getAllStages} from '../../selectors/plantsSelectors';
import {formatClientDate, formatInternationalInputDate} from '../../util/dateHelpers';
import * as p from '../../constants/permissions';
import {isBiotrackIntegrator} from '../../selectors/integration/biotrackSelectors';
import {getIntegrationState} from '../../selectors/integration/integrationSelectors';
import {getConstants} from '../../selectors/constantsSelectors';
import {isLeafPaConfigPackClosedLoopFacility} from '../../selectors/facilitiesSelectors';
import {isFeatureEnabled} from '../../selectors/featureToggles';
import {getTimezone} from '../../selectors/timezoneSelectors';


export class TestResults extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    const isIntegrationSoure = get(props, 'testResults[0].state_integration_source');
    const {plantStages} = props;

    const columns = [{ // Status always present
      name: I18n.t('cultivation.testResults.status'),
      dataId: 'status',
      hidden: false,
      dataSort: true,
      formatter: (cell) => cell ? cell : I18n.t('cultivation.testResults.noStatus')
    },
      { // Testing ID always present
        name: I18n.t('cultivation.testResults.form.testingId'),
        dataId: 'testing_id',
        hidden: false,
        dataSort: true
      }
    ].concat(
      props.integrationState.isWaLeaf && isIntegrationSoure  // Include eligible flags if Leaf WA only
        ? [{
          name: I18n.t('cultivation.testResults.retestEligible'),
          dataId: 'retest_eligible',
          hidden: !props.integrationState.isWaLeaf,
          dataSort: true,
          formatter: (cell) => !isNaN(parseInt(cell)) ? parseInt(cell) === 1 ? 'Yes' : 'No' : 'No'
        },
          {
            name: I18n.t('cultivation.testResults.extractionEligible'),
            dataId: 'extraction_eligible',
            hidden: !props.integrationState.isWaLeaf,
            dataSort: true,
            formatter: (cell) => !isNaN(parseInt(cell)) ? parseInt(cell) === 1 ? 'Yes' : 'No' : 'No'
          }]
        : [],
      props.isPlant  // Only include phase Id for plants
        ? [
          {
            name: I18n.t('cultivation.phaseId'),
            dataId: 'plant_phase_id',
            hidden: false,
            dataSort: true,
            formatter: (plant_phase_id) => {
              return get(plantStages.filter(stage => stage.id === plant_phase_id), '0.stage_name');
            }
          }
        ]
        : [],
      [ // Include balance of columns
        {
          name: I18n.t('cultivation.testResults.partnerName'),
          dataId: 'partner_name',
          hidden: false,
          dataSort: true,
        },
        {
          name: I18n.t('cultivation.testResults.productName'),
          dataId: 'product_name',
          hidden: false,
          dataSort: true,
        },
        {
          name: I18n.t('cultivation.testResults.packageCode'),
          dataId: 'package_code',
          hidden: false,
          dataSort: true,
        },
        {
          name: I18n.t('cultivation.testResults.lotNumber'),
          dataId: 'lot_number',
          hidden: false,
          dataSort: true,
        },
        {
          name: I18n.t('cultivation.testResults.testingDate'),
          dataId: 'testing_date',
          hidden: false,
          dataSort: true,
          formatter: (val) => {
            return formatInternationalInputDate(formatClientDate(moment.utc(val).tz(props.timezone)));
          }
        },
      ],
      props.isLeafPaConfigPackClosedLoopFacility && props.featurePaLabEnhancements
        ? [{
          name: I18n.t('cultivation.testResults.testType'),
          dataId: 'test_type',
          hidden: false,
          dataSort: true,
          // Capitalize first letter of test type or default to 'Standard' if no test type defined
          formatter: (val) => val ? val.charAt(0).toUpperCase() + val.slice(1) : 'Standard'
        }]
        : [],
      props.integrationState.isIsolocity
        ? [{
          name: I18n.t('inventory.table.isolocityStatus'),
          dataId: 'isolocity_status',
          hidden: false,
          dataSort: true,
          formatter: (val) => this.labStatusIsolocity(val, true)
        }]
        : [],
      [{
        name: I18n.t('cultivation.testResults.goToResults'),
        dataId: 'lab_results_id',
        hidden: false,
        dataSort: true,
        formatter: (lab_results_id) => (
          <RouteButton props={{bsSize: 'sm'}} path={`/test-results/view/${lab_results_id}`}>
            {I18n.t('cultivation.testResults.show')}
          </RouteButton>)
      }]
    );
    this.state = {isFetching: false, showResults: false, columns};
    this.toggleResults = this.toggleResults.bind(this);
    this.setLeafClass = this.setLeafClass.bind(this);
    //this.fetchLabResults = this.fetchLabResults.bind(this);
    this.fetchBiotrackLabResults = this.fetchBiotrackLabResults.bind(this);
  }

  componentWillMount() {

    const {getItem} = this.props.actions;
    const lab_results_id = get(this.props.item, 'lab_results_id');
    const promises = [
      lab_results_id && getItem(`/api/lab_results/${lab_results_id}`, itemNames.testResult, {failed: 'cultivation.testResults.get.failed'})
    ];

    Promise
      .all(promises)
      .then(() => this.setState({loading: false}))
      .catch(() => this.setState({loading: false}));
  }

  setLeafClass(row) {
    if (row.state_integration_source === 'leaf') {
      return 'integrated';
    }
    return '';
  }

  // fetchLabResults() {
  //   this.setState({isFetching: true});
  //   const {actions: {getItem}, item} = this.props;
  //   return getItem(`/api/leaf/import_lab_results/item/${item.id}`)
  //     .then(() => {
  //       this.setState({isFetching: false});
  //       this.props.fetchChildrenAndLabResults([], 'reload');
  //     })
  //     .catch(() => this.setState({isFetching: false}));
  // }

  fetchBiotrackLabResults() {
    this.setState({isFetching: true});
    const {actions: {getDataByPost}, item} = this.props;

    return getDataByPost('/api/biotrack/import_lab_results', {
      barcode_ids: [item.external_identifier],
    })
      .then(() => {
        this.setState({isFetching: false});
        this.props.fetchChildrenAndLabResults([], 'reload');
      })
      .catch(() => this.setState({isFetching: false}));
  }

  toggleResults() {
    this.setState({showResults: !this.state.showResults});
  }

  labStatus(status) {
    if(status){
      let style = '';
      switch (status.toLowerCase()) {
      case 'passed':
        style = 'success';
        break;
      case 'failed':
      case 'quarantined':
        style = 'danger';
        break;
      default:
        style = 'info';
      }
      if(status.toLowerCase() === 'quarantined'){
        return (<OverlayTrigger trigger='hover' placement='right' overlay={
            <Popover id='popover-trigger-focus'>
              <span>{I18n.t('inventory.table.quarantineMessage')}</span>
            </Popover>}>
          <span>{`${I18n.t('inventory.table.testResults')}:`} <span className={style}>{status} <FaInfoCircle /></span></span>
        </OverlayTrigger>);
      }else{
        return <span>{`${I18n.t('inventory.table.testResults')}: `}<span className={style}>{status}</span></span>;
      }
    }
    return I18n.t('inventory.table.testResults');
  }

  labStatusIsolocity(val, withoutLabel = false) {
    const {constants, testResults} = this.props;

    if(testResults.length){
      if(!val) val = get(constants, `integration.isolocity.statuses.in_progress`);

      const className = cln({
        info: val === get(constants, `integration.isolocity.statuses.in_progress`),
        danger: val === get(constants, `integration.isolocity.statuses.failed`),
        success: val === get(constants, `integration.isolocity.statuses.passed`),
      });

      const isolocityStatusRow = <span className={className}>{val}</span>;

      return (
        <label>
          {withoutLabel
            ? isolocityStatusRow
            : (
              <span>{`${I18n.t('inventory.table.isolocityStatus')}: `}
                {isolocityStatusRow}
              </span>
            )
          }
        </label>);
    }
  }
  render () {
    const {
      item,
      //isLeaf,
      isBiotrack,
      isIsolocity,
      testResults,
      testResultDetailsName,
      activeTestResultStatus,
      activeTestResultCompletionStatus,
      activeIsolocityTestResultStatus,
    } = this.props;
    const {isFetching, showResults} = this.state;
    const displayShowTestResultsButton = testResults.length && activeTestResultCompletionStatus !== 'in_progress';
    const testStatusText = (activeTestResultCompletionStatus === 'in_progress' ? I18n.t('cultivation.testResults.testResultsInProgress') : I18n.t('cultivation.testResults.noTestResults'));

    if (testResults[0]) {
      testResults[0].partner_name = (testResults[0].partner_name ? testResults[0].partner_name : testResultDetailsName);
    }

    if (isFetching) {
      return (
        <div>
          <label>{I18n.t('inventory.table.testResults')}</label><br/>
          {I18n.t('cultivation.testResults.fetching')}
        </div>
      );
    }
    return (
      <div>
        {testResults.length ?
          <ModalWrapper
            showModal={showResults}
            onHide={this.toggleResults}
            title={'inventory.table.testResults'}
            Component={SimpleTable}
            data={testResults}
            columns={this.state.columns}
            bordered={false}
            compact={true}
            trClassName={this.setLeafClass}
          />
          : <div/>
        }
        <label>{this.labStatus(activeTestResultStatus)}</label><br/>
        {isIsolocity && this.labStatusIsolocity(activeIsolocityTestResultStatus)}
        <ButtonToolbar>
          {displayShowTestResultsButton ?
            <PermissionButton
              permissions={[p.view_testing, p.manage_testing]}
              props={{
                onClick: this.toggleResults,
                size: 'sm',
              }}
            >
              {I18n.t('cultivation.testResults.showResults')}
            </PermissionButton>
            : <div className='padding-left'>{testStatusText}</div>
          }
          {/*{isLeaf && item && item.external_identifier ?*/}
          {/*  <PermissionButton*/}
          {/*    permissions={[p.manage_testing]}*/}
          {/*    props={{*/}
          {/*      onClick: this.fetchLabResults,*/}
          {/*      bsSize: 'sm',*/}
          {/*      variant: 'primary'*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    {I18n.t('cultivation.testResults.importTests')}*/}
          {/*  </PermissionButton>*/}
          {/*  : null*/}
          {/*}*/}
          {isBiotrack && item && item.external_identifier ?
            <PermissionButton
              permissions={[p.manage_testing]}
              props={{
                onClick: this.fetchBiotrackLabResults,
                size: 'sm',
                variant: 'primary'
              }}
            >
              {I18n.t('cultivation.testResults.importTests')}
            </PermissionButton>
            : null
          }
        </ButtonToolbar>
      </div>
    );
  }
}

TestResults.propTypes = {
  item: PropTypes.object.isRequired,
  //isLeaf: PropTypes.bool,
  actions: PropTypes.object.isRequired,
  constants: PropTypes.object.isRequired,
  isBiotrack: PropTypes.bool,
  testResults: PropTypes.array,
  testResultDetailsName: PropTypes.string,
  activeTestResultStatus: PropTypes.string,
  activeTestResultCompletionStatus: PropTypes.string,
  fetchChildrenAndLabResults: PropTypes.func,
  activeIsolocityTestResultStatus: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
  const testResults = getTestResultsByReferenceId(state, {item: ownProps.item});
  const testResultDetailsName = getTestResultDetails(state, ownProps).for_partner_name;
  const activeTestResult = getActiveTestResult(state, {item: ownProps.item}) || {};
  const activeTestResultStatus = get(activeTestResult, 'status', '');
  const activeTestResultCompletionStatus = get(activeTestResult, 'completion_status', '');
  const integrationState = getIntegrationState(state);
  const plantStages = getAllStages(state);
  const activeIsolocityTestResultStatus = integrationState.isIsolocity
    ? get(activeTestResult, 'isolocity_status', 'in progress')
    : null;
  const constants = getConstants(state);

  return {
    ...ownProps,
    //isLeaf: isLeafIntegrator(state),
    constants,
    isBiotrack: isBiotrackIntegrator(state),
    isIsolocity: integrationState.isIsolocity,
    testResults,
    testResultDetailsName: testResultDetailsName,
    integrationState,
    activeTestResultStatus,
    activeTestResultCompletionStatus,
    activeIsolocityTestResultStatus,
    plantStages,
    isLeafPaConfigPackClosedLoopFacility: isLeafPaConfigPackClosedLoopFacility(state),
    featurePaLabEnhancements: isFeatureEnabled(state)('feature_pa_hb_1024_lab_enhancements'),
    timezone: getTimezone(state)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {getItem, getDataByPost, getUnpaginatedData};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TestResults);
