import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';
import * as itemNames from '../../../constants/itemNames';
import * as dataNames from '../../../constants/dataNames';
import CureApiOrderSelector from './CureApiOrderSelector';
import CureApiPurchasableAmountsTable from './CureApiPurchasableAmountsTable';

class CureApiPurchasableAmountComponent extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.state = {
      activeGroup: 0,
      purchasableAmounts: [],
      completedOrderHandled: false,
    };
    this.loadStats = this.loadStats.bind(this);
    this.onGroupChange = this.onGroupChange.bind(this);
    this.getOrderProducts = this.getOrderProducts.bind(this);
    this.getCategoriesMapping = this.getCategoriesMapping.bind(this);
  }

  componentWillMount() {
    const {isCure, customer, curePatient, actions: {getItem}, showOrderData, orderId} = this.props;
    if (isCure) {
      getItem('/api/cureapi/categories/settings', itemNames.cureCategorySettings, {failed: 'categories.getCure.failed'});
      !curePatient && customer && customer.id && this.loadStats();
      if (showOrderData) {
        Promise.all([
          this.getCategoriesMapping(),
          this.getOrderProducts(orderId),
        ]);
      }
    }
  }

  getCategoriesMapping() {
    return this.props.actions.getUnpaginatedData(
        '/api/cureapi/categories/mapping',
        dataNames.cureCategoryMappings,
        {failed: 'categories.getCureMappings.failed'}
    );
  }

  getOrderProducts(orderId) {
    return this.props.actions.getUnpaginatedData(
        `/api/cureapi/order_products/${orderId}`,
        dataNames.cureOrderProducts,
        {failed: 'cureOrderProducts.get.failed'}
    );
  }

  isOrderComplete(order = {}){
    let isOrderComplete = false;
    if(order.order_status === 'completed' && !this.state.completedOrderHandled){
      this.setState({completedOrderHandled: true});
      isOrderComplete = true;
    }
    return isOrderComplete;
  }

  shouldLoadStats(props){
    return props.isCure && !props.curePatient && props.customer.id && props.customer.id !== this.props.customer.id;
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.orderId !== nextProps.orderId) {
      if (nextProps.isCure) {
        this.props.actions.getItem('/api/cureapi/categories/settings', itemNames.cureCategorySettings, {failed: 'categories.getCure.failed'});

        if (!nextProps.curePatient && get(nextProps, 'customer.id')) {
          this.loadStats();
        }

        if (nextProps.showOrderData) {
          Promise.all([
            this.getCategoriesMapping(),
            this.getOrderProducts(nextProps.orderId),
          ]);
        }
      }
    }

    if(this.shouldLoadStats(nextProps) || (nextProps.isCure && this.isOrderComplete(nextProps.order))){
      this.loadStats(nextProps.customer.id, nextProps.customer.state_integration_tracking_id, nextProps);
    }
    const groups = this.props.groups[this.state.activeGroup] || {};
    if(JSON.stringify(groups.purchasableAmounts) !== JSON.stringify(this.state.purchasableAmounts)){
      this.setState({purchasableAmounts: groups.purchasableAmounts || []});
    }
  }

  loadStats(consumer_id = this.props.customer.id, patient_number = this.props.customer.state_integration_tracking_id, props = false) {
    if(!consumer_id) return false;
    this.onGroupChange(0);
    if(!props) props = this.props;
    const params = this.isOrderComplete(props.order) ? {clear_cache: 1} : {};
    return this.props.actions
      .getDataBatchByPost(
        `/api/cureapi/patient_details`,
        {consumer_id, patient_number},
        dataNames.curePatients,
        {failed: 'curePurchasableAmount.get.failed'},
        params,
        () => {
          const groups = this.props.groups[this.state.activeGroup] || {};
          this.setState({purchasableAmounts: groups.purchasableAmounts || []});
        },
        null,
        'consumer.id'
      );
  }

  onGroupChange(activeGroup) {
    this.setState({activeGroup});
  }

  render() {
    const {groups, isCure, showHeader, className, showOrderData, compliance} = this.props;
    const {activeGroup} = this.state;
    if (!isCure) {
      return null;
    }
    const group = groups[activeGroup] || {};
    const groupCount = groups.length;

    return (
      <div className={`cure-purchased-amounts ${className || ''}`}>
        {showHeader ? <h4>{I18n.t('curePurchasableAmount.title')}</h4> : null}
        {groupCount > 1 ?
          <CureApiOrderSelector
            activeGroup={activeGroup}
            groupCount={groupCount}
            onGroupChange={this.onGroupChange}
            orders={group.orders || []}
          />
          :
          null
        }

        <CureApiPurchasableAmountsTable
          purchasableAmounts={this.state.purchasableAmounts}
          showOrderData={showOrderData && activeGroup === 0}
          compliance={compliance}
        />
      </div>
    );
  }
}

CureApiPurchasableAmountComponent.propTypes = {
  actions: PropTypes.shape({
    getItem: PropTypes.func.isRequired,
    getDataBatchByPost: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
  }).isRequired,
  customer: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  orderId: PropTypes.number,
  groups: PropTypes.array.isRequired,
  isCure: PropTypes.bool.isRequired,
  showHeader: PropTypes.bool.isRequired,
  showOrderData: PropTypes.bool,
  className: PropTypes.string,
  compliance: PropTypes.object.isRequired,
};

export default CureApiPurchasableAmountComponent;
