import debounce from 'lodash.debounce';
import * as actionTypes from '../../constants/actionTypes';
import * as apiActions from '../../actions/apiActions';
import * as dataNames from '../../constants/dataNames';
import * as dataActions from '../../actions/dataActions';

const adjustRewardPoints = store => next => action => {
  const result = next(action);

  const doAdjustment = () => {
    const adjustments = store.getState()[dataNames.rewardPointsAdjustments].map((a) => a);
    const length = adjustments.length; // We'll use this to update the array after processing
    const byCustomer = adjustments.reduce((acc, adjustment) => {
      if(adjustment.status) return acc; // Skip those already sent
      if(!acc[adjustment.customerId]) {
        acc[adjustment.customerId] = 0;
      }
      acc[adjustment.customerId] += parseInt(adjustment.points);
      return acc;
    }, {});

    let callsComplete = 0;
    const callsRequired = Object.keys(byCustomer).length;
    if(callsRequired) {
      store.dispatch(dataActions.setData(adjustments.map((a) => {
        if (!a.status) a.status = 'inflight';
        return a;
      }), dataNames.rewardPointsAdjustments));
      Object.keys(byCustomer).forEach((customerId) => {
        const url = `/api/customers/${customerId}/rewards/adjust`;
        store.dispatch(apiActions.postItem(url, {points: byCustomer[customerId]}))
          .then(() => {
            callsComplete++;
            if (callsComplete === callsRequired) {
              const currentAdjustments = store.getState()[dataNames.rewardPointsAdjustments].map((adjustment, index) => {
                if (index < length) adjustment.status = 'complete';
                return adjustment;
              });
              store.dispatch(dataActions.setData(currentAdjustments, dataNames.rewardPointsAdjustments));
            }
          }).catch(() => { // Remove inflight adjustments by customerId
            const currentAdjustments = store.getState()[dataNames.rewardPointsAdjustments].filter((adjustment) => {
              return parseInt(adjustment.customerId) !== parseInt(customerId) || (parseInt(adjustment.customerId) === parseInt(customerId) && adjustment.status !== 'inflight');
            });
            store.dispatch(dataActions.setData(currentAdjustments, dataNames.rewardPointsAdjustments));
          });
      });
    }
  };

  const debouncedAdjustment = debounce(doAdjustment, 1000);

  // Handle if comes through unbatched
  if (action.type === actionTypes.SET_DATA && action.name === dataNames.rewardPointsAdjustments){
    debouncedAdjustment();
  }
  // Handle if comes thropugh batched
  if (action.meta && action.meta.batch && action.payload.find((p) => p.name === dataNames.rewardPointsAdjustments && p.type === actionTypes.SET_DATA)){
    debouncedAdjustment();
  }
  return result;
};

export default adjustRewardPoints;

