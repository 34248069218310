import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';

import TextInput from '../../../../common/form/TextInput';
import ReactSelectInput from '../../../../common/form/ReactSelectInput';


const activeOptions = [
  { text: 'Yes', value: true },
  { text: 'No', value: false },
];

const BaseFormFields = ({integrator}) => {
  return (
    <Row>
      <Col sm={6} md={4} lg={4}>
        <Field name='api_key' component={TextInput} props={{
          label: I18n.t(`facility.setup.thirdPartyIntegration.${integrator}.${integrator == 'aeropayintegrated' ? 'merchantId' : 'apiKey'}`),
          isRequired: true
        }} />
      </Col>
      {integrator == 'aeropayintegrated' ? <Col sm={6} md={4} lg={4}>
        <Field name='merchant_location_uuid' component={TextInput} props={{
          label: I18n.t(`facility.setup.thirdPartyIntegration.aeropayintegrated.merchantLocationUuid`),
          isRequired: true
        }} />
      </Col>: null}
      <Col sm={6} md={4} lg={4}>
        <Field name='active' component={ReactSelectInput} props={{
          label: I18n.t('common.active'),
          isRequired: true,
          options: activeOptions,
        }} />
      </Col>
    </Row>
  );
};

export default BaseFormFields;
