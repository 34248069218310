import {createSelector} from 'reselect';
import orderBy from 'lodash.orderby';
import * as dataNames from '../constants/dataNames';
import {getGlobalStrains} from './globalStrainsSelectors';

const orderStrains = strains => orderBy(strains, 'strain_name', 'asc');

export const getOrganizationStrains = state => state[dataNames.organizationStrains];
export const getOrderedOrganizationStrains = createSelector([getOrganizationStrains], orderStrains);

//Strains available for current organization only
export const getOrganizationLevelStrains = createSelector(
  [getOrderedOrganizationStrains],
  strains => strains.reduce(
    (acc, orgStrain) => {
      if (!orgStrain.global_strain_id) {
        acc.push({
          organization_strain_id: orgStrain.id,
          strain_name: orgStrain.strain_name,
        });
      }
      return acc;
    },
    []
  )
);

//Available strains for current organization
export const getAvailableOrganizationStrains = createSelector(
  [getGlobalStrains, getOrganizationLevelStrains],
  (globalStrains, organizationStrains) => {
    return orderStrains(globalStrains.concat(organizationStrains));
  }
);
