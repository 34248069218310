import React from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import {Field, FieldArray} from 'redux-form';
import {Col, Row} from 'react-bootstrap';
import AccordionPanel from '../../../common/AccordionPanel';
import TextAreaInput from '../../../common/form/TextAreaInput';
import TextInput from '../../../common/form/TextInput';
import ImageField from '../../../common/form/files/ImageField';
import {product} from '../../../../constants/imageTypes';
import AlternateImages from '../../../products/common/AlternateImages';
import InlineCheckBox from '../../../common/form/InlineCheckBox';

const MenuPanel = (props) => {

  const {integrationState, formValues, change} = props;

  return (
    <React.Fragment>
      <AccordionPanel  title='products.form.menuOptions'>
        <Row>
          <Col sm={4}>
            <Field
              name='exclude_product_from_menu'
              component={InlineCheckBox}
              label={I18n.t('products.form.productExcludeFromMenu')}
              props={{
                checked: formValues && formValues.exclude_product_from_menu !== undefined ? formValues.exclude_product_from_menu : false,
                groupClassName: 'inline-checkbox',
                onChange: (event) => {
                  change('exclude_product_from_menu', event.target.checked);
                },
              }}
            />
            <ImageField
              imageType={product}
              name='primary_product_image_file_id'
              fileName='primary_image_file'
              change={change}
              value={formValues && formValues.primary_product_image_file_id ? formValues.primary_product_image_file_id : null}
              fileValue={formValues && formValues.primary_image_file ? formValues.primary_image_file : null}
            />
            <FieldArray
              name='product_images'
              component={AlternateImages}
              images={formValues && formValues.product_images ? formValues.product_images : null}
              change={change}
            />
          </Col>
          <Col sm={4}>
            <Field
              name='description'
              component={TextAreaInput}
              props={{
                label: I18n.t('products.form.productDescription'),
                isRequired: integrationState.isApisicpa || integrationState.isMetrc
              }}
            />
          </Col>
          <Col sm={4} className='field-width-100'>
            <Field
              name='display_name'
              component={TextInput}
              props={{
                label: I18n.t('products.form.displayName'),
                placeholder: I18n.t('products.form.displayNamePlaceholder')
              }}
            />
          </Col>
        </Row>
      </AccordionPanel>
    </React.Fragment>
  );
};

MenuPanel.propTypes = {
  integrationState: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps)(MenuPanel);
