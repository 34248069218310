import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { Field } from 'redux-form';

import NumericInput from '../../common/form/NumericInput';
import ReactSelectInput from '../../common/form/ReactSelectInput';

const PlantComplianceOnhandSettingsBlock = ({ trackPlantsAsGroup, options, fieldNamePrefix, disabled }) => (
  <React.Fragment>
    <tr>
      <td>
        <strong>{I18n.t('cultivation.complianceSettings.rulePlantsOnHandSlug')} - </strong>
        {I18n.t('cultivation.complianceSettings.rulePlantsOnHand')}
      </td>
      <td>
        <Field
          name={fieldNamePrefix + 'plants_on_hand'}
          component={NumericInput}
          props={{ fractionPartSize: 0, min: 0 }}
          disabled={trackPlantsAsGroup || disabled}
        />
      </td>
    </tr>
    <tr>
      <td>
        <strong>{I18n.t('cultivation.complianceSettings.ruleImmaturePlantsSlug')} - </strong>
        {I18n.t('cultivation.complianceSettings.ruleImmaturePlants')}
      </td>
      <td>
        <Field
          name={fieldNamePrefix + 'plants_in_veg'}
          component={NumericInput}
          props={{ fractionPartSize: 0, min: 0 }}
          disabled={trackPlantsAsGroup || disabled}
        />
      </td>
    </tr>
    <tr>
      <td>
        <strong>{I18n.t('cultivation.complianceSettings.ruleMaturePlantsSlug')} - </strong>
        {I18n.t('cultivation.complianceSettings.ruleMaturePlants')}
      </td>
      <td>
        <Field
          name={fieldNamePrefix + 'plants_in_flower'}
          component={NumericInput}
          props={{ fractionPartSize: 0, min: 0 }}
          disabled={trackPlantsAsGroup || disabled}
        />
      </td>
    </tr>
    <tr>
      <td style={{ borderBottom: '4px double #ccc' }}>
        <strong>{I18n.t('cultivation.complianceSettings.ruleSystemActionSlug')} - </strong>
        {I18n.t('cultivation.complianceSettings.ruleSystemAction')}
      </td>
      <td style={{ borderBottom: '4px double #ccc' }}>
        <Field
          name={fieldNamePrefix + 'action_type'}
          clearable={false}
          component={ReactSelectInput}
          props={{ options }}
        />
      </td>
    </tr>
  </React.Fragment>
);

PlantComplianceOnhandSettingsBlock.propTypes = {
  options: PropTypes.array.isRequired,
  trackPlantsAsGroup: PropTypes.bool.isRequired,
  fieldNamePrefix: PropTypes.string,
  disabled: PropTypes.bool
};

PlantComplianceOnhandSettingsBlock.defaultProps = {
  fieldNamePrefix: '',
  disabled: false
};

export default PlantComplianceOnhandSettingsBlock;
