import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import { connect } from 'react-redux';
import {Col, Row} from 'react-bootstrap';
import {Field, formValueSelector} from 'redux-form';
import {isFeatureEnabled} from '../../../selectors/featureToggles';
import {CREATE_HARVEST_PACKAGES} from '../../../constants/forms';
import * as  p from '../../../constants/permissions';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import TextInput from '../../common/form/TextInput';
import InlineCheckBox from '../../common/form/InlineCheckBox';
import AddItem from '../../common/form/AddItem';
import PermissionWrapper from '../../common/PermissionWrapper';
import MetrcConcealer from '../../common/concealers/MetrcConcealer';
import ReduxMetrcIdField from '../../common/form/redux-form/ReduxMetrcIdField';
import InternationalDateTimePickerInput from '../../common/form/InternationalDateTimePickerInput';
import InternationalQuantityByUomInput from '../../common/form/InternationalQuantityByUomInput';
import PackagePurposeField from '../../common/form/redux-form/PackagePurposeField';
import ContentConcealer from '../../common/concealers/ContentConcealer';

const getProductOptions = (itemMasters, row, integrationState, onlyRegulars = false) => {

  if (onlyRegulars) {
    return itemMasters['regular'];
  }

  if (integrationState.isWaLeaf) {
    const type = row && row.integration_type;
    return itemMasters[type] || [];
  }
  const isTest = row && row.is_test_package;
  if (integrationState.isPaLeaf) {
    return itemMasters['flower'];
  }
  return itemMasters[isTest ? 'test' : 'regular'];
};

const HarvestPackages = (props) => {

  const {
    hasPackagesTags,
    locations,
    itemMasters,
    requiresTracking,
    fields,
    ratings,
    harvestBatch,
    integrationState,
    trackingIds,
    totalQuality,
    packages,
    change,
    users,
    testPackageStatus,
    harvestTypeOptions,
    materialTypeOptions,
    designatedInventoryAsTestPackageEnabled,
    selector,
    isMetrcStemHoldingsToggled,
    isMetrcCaDonationsToggled,
    isUtahGlobalIdGenerationFeatureEnabled,
  } = props;
  const { length, map, push, pop, get } = fields;
  const { isMetrc, isCaMetrc, isWaLeaf, isPaLeaf, isBiotrack, isCanada } = integrationState;

  return (
    <Col xs={12} className='harvest-packages'>
      {testPackageStatus.pendingTestResults && (
        <Row>
          <Col xs={12} className='alert alert-danger' style={{ padding: '5px' }}>
            {I18n.t('harvest.createLots.pendingTestResults')}
          </Col>
        </Row>
      )}
      {map((pkg, index) => {
        const item = packages[index];
        let productOptions = getProductOptions(
          itemMasters,
          item,
          integrationState,
          harvestBatch,
          designatedInventoryAsTestPackageEnabled
        );
        if (!get(item, 'allow_other_strains')) {
          productOptions = productOptions.filter((el) => el.strain_id === harvestBatch.strain_id);
        } else {
          productOptions = productOptions.sort((a, b) => a.name.localeCompare(b.name));
        }
        const filteredHarvestTypeOptions = harvestTypeOptions
          ? harvestTypeOptions.filter((option) =>
              isWaLeaf && get(item, 'material_type') === 'other_material' ? option.value === 'other_material' : true
            )
          : '';

        const isDisabledMedical = get(index)['integration_type'] === 'other_material';

        const uom = selector(`${pkg}.uom`);

        return (
          <Row key={index}>
            <Col sm={12}>
              <h4>{I18n.t('harvestPackages.form.lot') + ` ${index + 1}`}</h4>
              <ContentConcealer show={isBiotrack}>
                <Row>
                  <Col xs={4}>
                    <Field
                      name={`${pkg}.allow_other_strains`}
                      component={InlineCheckBox}
                      props={{
                        label: I18n.t('harvest.createLots.createNewInventoryItem'),
                        groupClassName: 'inline-checkbox'
                      }}
                    />
                  </Col>
                </Row>
              </ContentConcealer>
              <Row>
                <ContentConcealer
                  show={
                    !testPackageStatus.checkboxHidden &&
                    (designatedInventoryAsTestPackageEnabled || isMetrc || isPaLeaf)
                  }
                >
                  <Col xs={4} md={2} className='checkbox-col' style={{ paddingTop: testPackageStatus.checkboxDisabled ? '0' : '25px' }}>
                    {testPackageStatus.checkboxDisabled &&
                    testPackageStatus.isTestPackage === 1 &&
                    !testPackageStatus.hasSample &&
                    !testPackageStatus.hasTestResult ? (
                      <div>{I18n.t('harvest.createLots.requiredNotPresent')}</div>
                    ) : null}
                    {testPackageStatus.checkboxDisabled &&
                    testPackageStatus.isTestPackage === 1 &&
                    testPackageStatus.hasSample &&
                    testPackageStatus.hasTestResult ? (
                      <div>{I18n.t('harvest.createLots.requiredFailed')}</div>
                    ) : null}
                    {testPackageStatus.checkboxDisabled &&
                    testPackageStatus.isTestPackage === 0 &&
                    testPackageStatus.hasTestResult ? (
                      <div>{I18n.t('harvest.createLots.passed')}</div>
                    ) : null}
                    <Field name={`${pkg}.is_test_package`}
                      component={InlineCheckBox}
                      disabled={testPackageStatus.checkboxDisabled}
                      props={{
                        label: I18n.t('inventory.splitPackage.form.testPackage'),
                        onChange: (event) => {
                          const {
                            target: { checked }
                          } = event;
                          change(`${pkg}.is_test_package`, checked);
                          change(`${pkg}.item_master_id`, '');
                        }
                      }}
                    />
                  </Col>
                </ContentConcealer>
                {isWaLeaf && (
                  <Col xs={6} md={3} lg={2}>
                    <Field
                      name={`${pkg}.material_type`}
                      component={ReactSelectInput}
                      props={{
                        label: I18n.t('harvestPackages.form.leafWa.material_type'),
                        options: materialTypeOptions,
                        isRequired: true
                      }}
                    />
                  </Col>
                )}
                {(isWaLeaf || isBiotrack) && (
                  <Col xs={6} md={3} lg={2}>
                    <Field
                      name={`${pkg}.integration_type`}
                      component={ReactSelectInput}
                      props={{
                        label: I18n.t('harvestPackages.form.leafWa.lotType'),
                        options: filteredHarvestTypeOptions,
                        isRequired: true,
                        onChange: (value) => {
                          change(`${pkg}.integration_type`, value);
                          change(`${pkg}.item_master_id`, '');
                          value === 'other_material' && isWaLeaf && change(`${pkg}.medically_compliant`, '');
                        }
                      }}
                    />
                  </Col>
                )}
                <ContentConcealer show={isWaLeaf}>
                  <Row>
                    <Col xs={6} md={3} lg={2}>
                      <Field
                        name={`${pkg}.medically_compliant`}
                        component={InlineCheckBox}
                        props={{
                          label: I18n.t('harvestPackages.form.leafWa.seekingMedicalDesignation'),
                          groupClassName: 'inline-checkbox seeking-medical-designation',
                          disabled: isDisabledMedical
                        }}
                      />
                    </Col>
                  </Row>
                </ContentConcealer>
                <Col xs={6} md={3} lg={2}>
                  <Field
                    name={`${pkg}.item_master_id`}
                    component={ReactSelectInput}
                    props={{
                      name: `${pkg}.item_master_id`,
                      label: I18n.t('harvestPackages.form.product'),
                      options: productOptions,
                      valueKey: 'id',
                      textKey: 'name',
                      isRequired: true,
                      onChange: (value) => {
                        change(`${pkg}.item_master_id`, value);
                        const product = productOptions && productOptions.find((item_master => item_master.id === value));
                        const uom = product ? product.default_uom : 'GR';
                        change(`${pkg}.uom`, uom);
                      }
                    }}
                    />
                </Col>
                <Col xs={8} md={3} lg={2}>
                  <Field
                    name={`${pkg}.inventory_location_id`}
                    component={ReactSelectInput}
                    props={{
                      name: `${pkg}.inventory_location_id`,
                      label: I18n.t('harvestPackages.form.location'),
                      options: locations,
                      isRequired: true
                    }}
                  />
                </Col>
                <PermissionWrapper permissions={[p.rate_products]}>
                  <Col xs={8} md={3} lg={2}>
                    <Field
                      name={`${pkg}.rating_avg_total_quality`}
                      component={ReactSelectInput}
                      props={{
                        name: `${pkg}.rating_avg_total_quality`,
                        label: I18n.t('harvestPackages.form.lotQualityRating'),
                        options: ratings
                      }}
                    />
                  </Col>
                </PermissionWrapper>
                {isMetrc && (
                  <Col xs={8} md={2} lg={3}>
                    <ReduxMetrcIdField name={`${pkg}.state_integration_tracking_id`} props={{ options: trackingIds }} />
                  </Col>
                )}
                <Col md={2}>
                  <PackagePurposeField name={`${pkg}.purpose`} />
                </Col>
                <MetrcConcealer>
                  <Col md={2}>
                    <Field
                      name={`${pkg}.is_produced`}
                      component={ReactSelectInput}
                      props={{
                        label: I18n.t('metrc.form.isProductionBatch'),
                        options: [{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }]
                      }}
                    />
                  </Col>
                  {
                    isMetrcStemHoldingsToggled && (
                      <Col md={2}>
                        <Field
                          name={`${pkg}.is_trade_sample`}
                          component={ReactSelectInput}
                          class={'trade-sample'}
                          props={{
                            label: I18n.t('metrc.form.tradeSample'),
                            options: [{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }],
                            groupClassName: 'trade-sample'
                          }}
                        />
                      </Col>
                    )
                  }
                  {
                    (isMetrcCaDonationsToggled && isCaMetrc) && (
                      <Col md={2}>
                        <Field
                          name={`${pkg}.is_donation`}
                          component={ReactSelectInput}
                          props={{
                            label: I18n.t('metrc.form.donation'),
                            options: [{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }],
                          }}
                        />
                      </Col>
                    )
                  }
                </MetrcConcealer>
              </Row>
              <Row>
                <Col xs={8} md={2} lg={2}>
                  <Field
                    name={`${pkg}.package_code`}
                    component={TextInput}
                    props={{
                      name: `${pkg}.package_code`,
                      label: I18n.t('harvestPackages.form.lotId'),
                      disabled: false
                    }}/>
                </Col>
                <Col xs={8} md={3} lg={2}>
                  <InternationalQuantityByUomInput
                    name={`${pkg}.weight_of_product`}
                    label={I18n.t('harvestPackages.form.lotWeight')}
                    isRequired={true}
                    readOnly={false}
                    uom={uom}
                    />
                </Col>

                <Col className='form-fields-col' xs={8} md={3} lg={2}>
                  <InternationalQuantityByUomInput
                    name={`${pkg}.gross_weight`}
                    label={I18n.t('harvestPackages.form.grossWeight')}
                    isRequired={true}
                    uom={uom}
                    />
                </Col>
                {isPaLeaf ? (
                  <Col xs={6} md={3} lg={2}>
                    <Field
                      name={`${pkg}.created_by_user_id`}
                      component={ReactSelectInput}
                      props={{
                        label: I18n.t('harvestPackages.form.leafPa.createdUser'),
                        options: users,
                        isRequired: true,
                        valueKey: 'id',
                        textKey: 'name'
                      }}
                    />
                  </Col>
                ) : null}
              </Row>
              <Row>
                <Col xs={2}>
                  <InternationalDateTimePickerInput
                    name={`${pkg}.packaged_at`}
                    props={{
                      label: I18n.t('packaging.form.packagedAt'),
                      timeFormat: false
                    }}
                  />
                </Col>
                <Col xs={2}>
                  <InternationalDateTimePickerInput
                    name={`${pkg}.package_created_at`}
                    props={{
                      label: I18n.t('packaging.form.packageCreatedAt'),
                      timeFormat: false
                    }}
                  />
                </Col>
                <Col xs={2}>
                  <InternationalDateTimePickerInput
                    name={`${pkg}.package_expires_at`}
                    props={{
                      label: I18n.t('packaging.form.packageExpiresAt'),
                      timeFormat: false,
                      isRequired: false,
                      enableTodayButton: false
                    }}
                  />
                </Col>
                {!isUtahGlobalIdGenerationFeatureEnabled && hasPackagesTags ? (
                  <Col xs={8} md={3} lg={2}>
                    <Field
                      name={`${pkg}.tag_requested`}
                      component={ReactSelectInput}
                      props={{
                        label: I18n.t('plants.modify.needsTag'),
                        options: [{ text: I18n.t('common.yes'), value: 1 }, { text: I18n.t('common.no'), value: 0 }],
                        textKey: 'text',
                        valueKey: 'value',
                        placeholder: I18n.t('common.common.no')
                      }}
                    />
                  </Col>
                ) : null}
                {isCanada ? (
                  <Col md={3}>
                    <Field
                      name={`${pkg}.finished`}
                      component={InlineCheckBox}
                      props={{
                        label: I18n.t('common.form.finishedInventoryCheckbox')
                      }}
                    />
                  </Col>
                ) : null}
              </Row>
              <Row>
                {requiresTracking ? (
                  <Col xs={8} md={3} lg={2}>
                    <Field
                      name={`${pkg}.tracking_id`}
                      component={TextInput}
                      props={{
                        label: I18n.t('harvestPackages.form.trackingId')
                      }}
                    />
                  </Col>
                ) : null}
              </Row>
            </Col>
          </Row>
        );
      })}
      <AddItem
        length={length}
        addDisabled={testPackageStatus.isTestPackage === 1}
        addAction={() => {
          const lastPackage = get(length - 1);
          const fields = ['packaged_at', 'package_created_at', 'package_expires_at'];
          const newPackage = { uom: 'GR', rating_avg_total_quality: totalQuality };
          fields.forEach((field) => {
            newPackage[field] = lastPackage[field];
          });
          newPackage['tag_requested'] = 1;
          push(newPackage);
        }}
        removeAction={pop}
        text={I18n.t('harvestPackages.form.addNewLot')}
      />
    </Col>
  );
};

HarvestPackages.propTypes = {
  locations: PropTypes.array.isRequired,
  itemMasters: PropTypes.shape({
    regular: PropTypes.array.isRequired,
    test: PropTypes.array.isRequired,
    flower: PropTypes.array.isRequired,
    other_material: PropTypes.array.isRequired
  }).isRequired,
  requiresTracking: PropTypes.bool,
  fields: PropTypes.shape({
    length: PropTypes.number.isRequired,
    push: PropTypes.func.isRequired,
    pop: PropTypes.func.isRequired,
    map: PropTypes.func.isRequired
  }),
  ratings: PropTypes.array,
  totalQuality: PropTypes.number,
  harvestBatch: PropTypes.object,
  integrationState: PropTypes.object.isRequired,
  trackingIds: PropTypes.array.isRequired,
  packages: PropTypes.array.isRequired,
  harvestTypeOptions: PropTypes.array,
  materialTypeOptions: PropTypes.array,
  change: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  testPackageStatus: PropTypes.object.isRequired,
  hasPackagesTags: PropTypes.bool,
  formData: PropTypes.object,
  selector: PropTypes.func.isRequired,
  designatedInventoryAsTestPackageEnabled: PropTypes.bool.isRequired,
  isMetrcStemHoldingsToggled: PropTypes.bool.isRequired,
  isMetrcCaDonationsToggled: PropTypes.bool.isRequired,
  isUtahGlobalIdGenerationFeatureEnabled: PropTypes.bool.isRequired,
};


function mapStateToProps(state){
  const formSelector = formValueSelector(CREATE_HARVEST_PACKAGES);
  return {
    isMetrcStemHoldingsToggled: isFeatureEnabled(state)('feature_metrc_stem_holdings'),
    isMetrcCaDonationsToggled: isFeatureEnabled(state)('feature_metrc_ca_donations'),
    isUtahGlobalIdGenerationFeatureEnabled: isFeatureEnabled(state)('feature_utah_global_id_generation'),
    selector: (name) => {
      return formSelector(state, name);
    },
  };
}

export default connect(mapStateToProps)(HarvestPackages);
