import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { I18n } from 'react-redux-i18n';
import { Button } from 'react-bootstrap';

import { getItem, getUnpaginatedData, getDataByPost } from '../../../actions/apiActions';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import {
  getFeedingSchedulesItemMasterIds
} from '../../../selectors/feedingSchedulesSelectors';
import { getPlant, getFormattedPlantCost, getPlantExternalId } from '../../../selectors/plantsSelectors';
import {
  getFeedingScheduleIdsByPlantHistory,
  getPlantHistoryTableData
} from '../../../selectors/plantHistorySelectors';
import { getStrain } from '../../../selectors/strainsSelectors';
import ModalWrapper from '../../common/ModalWrapper';
import TablePageWrapper from '../../common/grid/TablePageWrapper';
import InProgressOverlay from '../../common/InProgressOverlay';
import PlantCreatedDetails from './details/PlantCreatedDetails';
import PlantSplitDetails from './details/PlantSplitDetails';
import PlantUpdatedDetails from './details/PlantUpdatedDetails';
import PlantPackagedDetails from './details/PlantPackagedDetails';
import PlantHarvestedDetails from './details/PlantHarvestedDetails';
import PlantInactivatedDetails from './details/PlantInactivatedDetails';
import PlantTaggingHistory from './details/PlantTaggingHistory';
import PlantDestroyedDetails from './details/PlantDestroyedDetails';
import WasteReportedDetails from './details/WasteReportedDetails';
import PlantQualityRatingDetails from './details/PlantQualityRatingDetails';
import PlantWasteReportedDetails from './details/PlantWasteReportedDetails';
import PlantHistoryNotes from './PlantHistoryNotes';
import FeedingScheduleDetails from './details/FeedingScheduleDetails';
import { isLeafIntegrator } from '../../../selectors/integration/leafSelectors';
import InternationalDateTimeStatic from '../../common/form/InternationalDateTimeStatic';
import { sortByAdditionalFieldIfEqual } from '../../../util/dateHelpers';
import LabResultDetails from '../../packages/history/details/LabResultDetails';

export class PlantHistoryPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      ready: false,
      showNotesModal: false,
      displayedNotes: [],
      historyBySearch: null
    };

    this.openNotesModal = this.openNotesModal.bind(this);
    this.expandableRow = this.expandableRow.bind(this);
    this.expandComponent = this.expandComponent.bind(this);
    this.handleCustomSearch = this.handleCustomSearch.bind(this);
    this.ref = React.createRef();
    this.columns = [
      {
        name: 'common.action',
        dataId: 'message',
        width: '120px',
        dataAlign: 'left'
      },
      {
        name: 'common.employee',
        dataId: 'user_name',
        width: '90px'
      },

      {
        name: 'common.actionDate',
        dataId: 'event_date',
        width: '70px',
        /* eslint-disable react/no-multi-comp */
        formatter: (cell) => <InternationalDateTimeStatic displayTime={true} useSystemDate={false}>{cell}</InternationalDateTimeStatic>,
        sortFunc: sortByAdditionalFieldIfEqual('event_date', 'id')
      },
      {
        name: 'common.entryDate',
        dataId: 'created_at',
        width: '70px',
        /* eslint-disable react/no-multi-comp */
        formatter: (cell) => <InternationalDateTimeStatic displayTime={false} useSystemDate={false}>{cell}</InternationalDateTimeStatic>,
        sortFunc: sortByAdditionalFieldIfEqual('created_at', 'id')
      },
      {
        name: 'common.notes',
        dataId: 'notes',
        formatter: (cell, row) => { //eslint-disable-line
          return (
            <Button
              variant='primary'
              size='sm'
              disabled={!(row.notes && row.notes.length)}
              onClick={(event) => this.openNotesModal(event, row.notes)}
            >
              {I18n.t('customers.table.view')}
            </Button>
          );
        },
        width: '50px',
        dataSort: false
      }
    ];
  }

  componentWillMount() {
    const id = this.props.params.id || '';
    const promises = [
      this.props.actions.getItem(
        `/api/plants/${id}`,
        itemNames.plant,
        { failed: I18n.t('plants.getPlant.failed') },
        { detailed: 1 },
        (plant) => {
          if (plant.strain_id) {
            this.props.actions.getItem(`/api/strains/${plant.strain_id}`, itemNames.strain, {
              failed: I18n.t('strains.getStrain.failed')
            });
          }
        }
      ),
      this.props.actions
        .getUnpaginatedData(`/api/plants/${id}/log`, dataNames.plantHistory, {
          failed: I18n.t('cultivation.getHistory.failed')
        })
        .then(() => {
          const { feedingScheduleIds } = this.props;
          if (feedingScheduleIds.length) {
            return this.props.actions.getDataByPost(
              '/api/schedules/multiple',
              { ids: feedingScheduleIds },
              dataNames.feedingSchedules
            );
          }
        })
        .then(() => {
          const { itemMasterIds, feedingScheduleIds } = this.props;
          if (feedingScheduleIds.length && itemMasterIds.length) {
            return this.props.actions.getDataByPost(
              '/api/item_masters/multiple',
              { ids: itemMasterIds },
              dataNames.itemMasters
            );
          }
        }),
      this.props.actions.getUnpaginatedData('/api/users/current_facility', dataNames.currentFacilityUsers),
      this.props.actions.getUnpaginatedData('/api/cultivation/sections', dataNames.sections),
      this.props.actions.getUnpaginatedData(`/api/partners`, dataNames.partners, {failed: 'partners.get.failed'}),
      this.props.actions.getUnpaginatedData('/api/stages', dataNames.stages),
      this.props.actions.getItem('/api/settings/plants', itemNames.complianceSettings, null, {
        ids: ['cult_track_plants_as_groups']
      }),
      this.props.actions.getItem(`/api/plants/${id}/cost`, itemNames.plantCost, {
        failed: I18n.t('cultivation.getPlantCost.failed')
      })

    ];
    // mapping check should be no need anymore
    // if (this.props.isLeaf) {
    //   promises.push(this.props.actions.getItem(`/api/integration-mapping/plants/${id}`, itemNames.plantMapping));
    // }
    Promise.all(promises)
      .then(() => this.setState({ ready: true }))
      .catch(() => this.setState({ ready: true }));
  }

  openNotesModal(event, displayedNotes) {
    event.stopPropagation();
    this.setState({ showNotesModal: true, displayedNotes });
  }

  expandableRow(row) {
    return Boolean(row.model);
  }

  expandComponent(row) {
    switch (row.event_type) {
    case 'planted':
    case 'plant_reactivated':
      return <PlantCreatedDetails plant={row.model} trackPlantsAsGroup={this.props.trackPlantsAsGroup} />;
    case 'plant_split':
      return <PlantSplitDetails model={row.model} />;
    case 'plant_updated':
      if (!row.delta) {
        return null;
      }
      return <PlantUpdatedDetails delta={row.delta} model={row.model} />;
    case 'plant_packaged':
      return <PlantPackagedDetails model={row.model} />;
    case 'harvested':
      return <PlantHarvestedDetails model={row.model} />;
    case 'plant_deactivated':
      return <PlantInactivatedDetails model={row.model} />;
    case 'destroyed':
      return <PlantDestroyedDetails model={row.model} trackPlantsAsGroup={this.props.trackPlantsAsGroup} />;
    case 'feeding_schedule':
      return <FeedingScheduleDetails model={row.model} />;
    case 'waste_reported':
      return <WasteReportedDetails model={row.model} />;
    case 'quality_rating_harvest':
      return <PlantQualityRatingDetails model={row.model} />;
    case 'waste_reported_harvest':
      return <PlantWasteReportedDetails model={row.model} />;
    case 'tag_update':
      return <PlantTaggingHistory message={row.message} model={row.model} />;
    case 'LAB_RESULT_ASSIGNED':
    case 'LAB_RESULT_UNASSIGNED':
      return <LabResultDetails model={row.model} eventType={row.event_type} isPlant={true}/>;
    default:
      return null;
    }
  }

  handleCustomSearch(value) {
    // TODO: refactor custom search code below and move to general helper

    const table = this.ref.current.wrappedInstance;
    const historyBySearch = [];

    if (value && value.trim()) {
      for (const item of this.props.history) {
        if (item.model) {
          const searchByKeys = (keys) =>
            keys.some((key) => {
              let it = null;

              if (~key.indexOf('.') && !~key.indexOf(' ')) {
                key.split('.').forEach((k, i) => {
                  it = i === 0 ? item.model[k] : it[k];
                });
              } else if (!~key.indexOf('.') && ~key.indexOf(' ')) {
                it = key
                  .split(' ')
                  .map((k) => item.model[k])
                  .join(' ');
              } else if (~key.indexOf('.') && ~key.indexOf(' ')) {
                let itt = null;
                it = [];
                key.split(' ').map((k) => {
                  k.split('.').forEach((kk, i) => {
                    itt = i === 0 ? item.model[kk] : itt[kk];
                  });
                  it.push(itt);
                });
                it = it.join(' ');
              } else {
                it = item.model[key];
              }

              return it && it.indexOf && ~it.indexOf(value);
            });

          const searchIngredients = () =>
            item.model &&
            item.model.feedingSchedule &&
            item.model.feedingSchedule.ingredients &&
            item.model.feedingSchedule.ingredients.some(
              (ing) => ing.item_master && ~ing.item_master.name.indexOf(value)
            );

          const searchByDelta = () =>
            item.delta &&
            item.delta.some(
              (it) =>
                (it['current'] && it['current'].indexOf && ~it['current'].indexOf(value)) ||
                (it['prev'] && it['prev'].indexOf && ~it['prev'].indexOf(value))
            );

          let result = false;

          switch (item.event_type) {
          case 'planted':
          case 'plant_reactivated':
            result =
                searchByKeys([
                  'plant_id',
                  'batch_name',
                  'type',
                  'strain_name',
                  'phenotype_name',
                  'section_name',
                  'stage_name',
                  'feeding_schedule_name',
                  'mother_plant_id',
                  'state_integration_tracking_id',
                  'source_item_package_code'
                ]) || searchIngredients();

            break;
          case 'plant_split':
          case 'plant_updated':
            result = searchByKeys([]) || searchIngredients() || searchByDelta();
            break;
          case 'plant_packaged':
            result = searchByKeys([
              'lot.lot_number',
              'item.package_code',
              'item.location_name',
              'item.item_name',
              'item.state_integration_tracking_id'
            ]);
            break;
          case 'harvested':
            result = searchByKeys([
              'harvest_batch.batch_name',
              'wet_weight_harvest',
              'harvest_batch_plant.rating_avg_pest_resistance',
              'harvest_batch_plant.rating_avg_mold',
              'harvest_batch_plant.rating_avg_mildew',
              'harvest_batch_plant.rating_avg_bud_rot',
              'harvest_batch_plant.rating_avg_stretching',
              'harvest_batch_plant.rating_avg_airy_buds',
              'tracking_id'
            ]);
            break;
          case 'plant_deactivated':
            break;
          case 'destroyed':
            result = searchByKeys(['destruction.destroyed_weight destruction.uom', 'destruction.notes']);
            break;
          case 'feeding_schedule':
            result = searchByKeys(['schedule_name']) || searchIngredients();
            break;
          case 'waste_reported':
            result = searchByKeys(['waste_weight uom']);
            break;
          case 'quality_rating_harvest':
            result = searchByKeys(['harvest_batch_plant.rating_avg_total_quality']);
            break;
          case 'waste_reported_harvest':
            result = searchByKeys(['harvest_batch.uom']);
            break;
          }

          if (result) {
            historyBySearch.push(item);
          }
        }
      }
    }

    if (historyBySearch.length) {
      this.setState({ historyBySearch: [].concat(historyBySearch) });
    } else {
      this.setState({ historyBySearch: null });
      table.handleSearch(value);
    }
  }

  render() {
    const { plant, strain, history, sumCostAppliedFeedingSchedules, externalId } = this.props;
    const historyBySearch = this.state.historyBySearch || history;

    return (
      <div className='history-page plant-history-page'>
        {this.state.ready ? '' : <InProgressOverlay isActive={true} />}
        <ModalWrapper
          Component={PlantHistoryNotes}
          onHide={() => this.setState({ showNotesModal: false })}
          showModal={this.state.showNotesModal}
          title='history.notes'
          notes={this.state.displayedNotes}
        />
        <div className='plant-history-page-plant-info'>
          {this.props.trackPlantsAsGroup ? (
            <p>
              {I18n.t('plants.plantGroupId')}: {plant.plant_id || ''}
            </p>
          ) : (
            <p>
              {I18n.t('plants.plantId')}: {plant.plant_id || ''}
            </p>
          )}

          <p>
            {I18n.t('plants.strainName')}: {strain.strain_name || ''}
          </p>
          {plant.feeding_schedule ? (
            <p>
              {I18n.t('plants.feedingScheduleName')}: {plant.feeding_schedule.schedule_name}
            </p>
          ) : null}
          <p>
            {I18n.t('plants.cumulativeCost')}: {sumCostAppliedFeedingSchedules || '0.00'}
          </p>
          {externalId ? (
            <p>
              {I18n.t('harvestPackages.form.trackingId')}: {externalId}
            </p>
          ) : null}
        </div>
        <TablePageWrapper
          ref={this.ref}
          settingKey='plants-history'
          columns={this.columns}
          data={historyBySearch}
          selectedRows={[]}
          handleSelect={() => {}}
          handleCustomSearch={this.handleCustomSearch}
          bstProps={{
            selectRow: {
              clickToSelect: false,
              hideSelectColumn: true,
              clickToExpand: true
            },
            expandableRow: this.expandableRow,
            expandComponent: this.expandComponent,
            options: {
              defaultSortName: 'event_date',
              defaultSortOrder: 'desc'
            }
          }}
        />
      </div>
    );
  }
}

PlantHistoryPage.propTypes = {
  plant: PropTypes.object.isRequired,
  strain: PropTypes.object.isRequired,
  history: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    getItem: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    getDataByPost: PropTypes.func.isRequired
  }).isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  feedingScheduleIds: PropTypes.array.isRequired,
  itemMasterIds: PropTypes.array.isRequired,
  timezone: PropTypes.string.isRequired,
  ingredientItemMasters: PropTypes.array,
  sumCostAppliedFeedingSchedules: PropTypes.string,
  externalId: PropTypes.string,
  isLeaf: PropTypes.bool,
  complianceSettings: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  const { complianceSettings } = state;
  const { cult_track_plants_as_groups } = complianceSettings;

  return {
    plant: getPlant(state),
    strain: getStrain(state),
    history: getPlantHistoryTableData(state),
    feedingScheduleIds: getFeedingScheduleIdsByPlantHistory(state),
    itemMasterIds: getFeedingSchedulesItemMasterIds(state),
    timezone: state.timezone,
    ingredientItemMasters: state[dataNames.itemMasters],
    sumCostAppliedFeedingSchedules: getFormattedPlantCost(state),
    isLeaf: isLeafIntegrator(state),
    externalId: getPlantExternalId(state),
    complianceSettings: state.complianceSettings,
    trackPlantsAsGroup: cult_track_plants_as_groups && cult_track_plants_as_groups.value
  };
}

function mapDispatchToProps(dispatch) {
  const actions = { getItem, getUnpaginatedData, getDataByPost };
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlantHistoryPage);
