import React from 'react';
import PropTypes from 'prop-types';

import FieldErrorBoundry from '../FieldErrorBoundry';
import BootstrapFormGroup from './BootstrapFormGroup';
import {getValidationState, getValidationError} from './validationState';

const ReduxBootstrapInput = ({
  groupClassName,
  name,
  meta,
  input,
  label,
  customLabel,
  leftAddon,
  rightAddon,
  size,
  children,
  Control,
  dropdownButton,
  isRequired,
  helpTextBelow,
  ...props
}) => {
  const validationError = getValidationError(meta);
  const validationState = getValidationState(meta);
  return (
    <FieldErrorBoundry key={name}>
      <BootstrapFormGroup
        id={name}
        name={name}
        meta={meta}
        label={label}
        customLabel={customLabel}
        size={size}
        className={groupClassName}
        validationState={validationState}
        error={validationError}
        warning={meta.warning}
        helpTextBelow={helpTextBelow}
        Control={Control}
        dropdownButton={dropdownButton}
        isRequired={isRequired}
        leftAddon={leftAddon}
        rightAddon={rightAddon}
        // The input has been included in full and not just spread (...input) anymore so the Control
        // component has full access to the redux-form props and custom props of the same name. Without this,
        // the spread "input" doesn't work with things like custom onChange handlers, as the redux-form
        // equivalent props are overridden and the Control component loses access.
        controlProps={{...input, input, ...props, dirty: meta.dirty, onDragStart: () => {}}} >
        {children}
      </BootstrapFormGroup>
    </FieldErrorBoundry>
  );
};

ReduxBootstrapInput.propTypes = {
  groupClassName: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.oneOf(['lg', 'sm', 'large', 'small']),
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  name: PropTypes.string,
  children: PropTypes.node,
  Control: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.node
  ]).isRequired,
  isRequired: PropTypes.bool,
  leftAddon: PropTypes.node,
  rightAddon: PropTypes.node,
  dropdownButton: PropTypes.node,
  customLabel: PropTypes.object,
  helpTextBelow: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default ReduxBootstrapInput;
