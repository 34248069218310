import {createSelector} from 'reselect';
import orderBy from 'lodash.orderby';

import * as itemNames from '../constants/itemNames';
import {getPartnerFacilities} from './partnersSelectors';
import {getActiveFacility} from './facilitiesSelectors';
import {getItemMasters} from './itemMastersSelectors';

export const getLotLineage = state => state[itemNames.lotLineage];

const getItemPartnerName = (item, partners) => {
  if (item.lot_number && item.partner_facility_source === 'import' && !item.partner_facility_id) {
    return '';
  }
  const partner = partners.find(partner => partner.id === item.partner_facility_id);
  return partner && partner.facility_name || '';
};

function mapParents(lot, activeFacility, partners, itemMasters) {
  const vendor_name = getItemPartnerName(lot, partners);
  return {
    ...lot,
    vendor_name,
    packages: (lot.packages || []).map((pkg) => {
      const itemMaster = itemMasters.find(itemMaster => itemMaster.id === pkg.item_master_id);
      return {
        ...pkg,
        product_name: itemMaster && itemMaster.name || '',
      };
    }),
    parents: orderBy((lot.parents || []).map(parent => mapParents(parent, activeFacility, partners, itemMasters)), l => l.parents.length, 'desc'),
  };
}

export const getLotLineageFormatted = createSelector(
  [getLotLineage, getActiveFacility, getPartnerFacilities, getItemMasters],
  mapParents
);

/**
 * Get unique item master ids by lot packages
 * @param lot object
 * @param itemMasterIds array
 * @return array
 */
const getLotItemMasterIds = (lot, itemMasterIds) => (lot.packages || []).reduce(
  (acc, pkg) => {
    if (acc.indexOf(pkg.item_master_id) === -1) {
      return [...acc, pkg.item_master_id];
    }
    return acc;
  },
  itemMasterIds
);

/**
 * Recursively go over the lot lineage to collect unique item master ids
 * @param lotLineage object
 * @param itemMasterIds array
 * @return array
 */
const getLineageItemMasterIds = (lotLineage, itemMasterIds = []) => (lotLineage.parents || []).reduce(
  (acc, parent) => getLineageItemMasterIds(parent, acc),
  getLotItemMasterIds(lotLineage, itemMasterIds)
);

export const getItemMasterIdsByLotLineage = createSelector(
  [getLotLineage],
  getLineageItemMasterIds
);
