/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { I18n } from 'react-redux-i18n';
import { goBack } from 'react-router-redux';
import { isCanadaFacility, isColombiaFacility } from '../../selectors/facility/getFacilityCountry';
import { getIntegrationState } from '../../selectors/integration/integrationSelectors';
import FormWrapper from '../common/form/FormWrapper';
import ModifyStrainsFormWrapper from './ModifyStrainsFormWrapper';
import * as apiActions from '../../actions/apiActions';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import { clearSelectedData } from '../../actions/selectedDataActions';
import { unsetData } from '../../actions/dataActions';

export class ModifyStrainsPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.redirect = this.redirect.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount() {
    this.props.actions.getItem('/api/integration-settings', itemNames.integrationSettings, {
      failed: 'stateIntegratorSettings.get.failed'
    });
  }

  redirect() {
    this.props.actions.goBack();
  }

  onSubmit(formData, dispatch) {
    this.props.actions.putData(
      '/api/strains/update/facility',
      formData.selectedStrains,
      dataNames.facilityStrains,
      { success: 'strains.edit.success', failed: 'strains.edit.fail' },
      null,
      () => {
        this.props.actions.clearSelectedData(dataNames.facilityStrains);
        this.props.actions.unsetData(dataNames.facilityStrains);
        this.props.actions.goBack();
      }
    );
  }

  render() {
    return (
      <FormWrapper
        className='modify-strains-page'
        integrationState
        title={I18n.t('strains.edit.header')}
        goBack={this.redirect}
      >
        <ModifyStrainsFormWrapper
          isCanada={this.props.isCanada}
          isColombia={this.props.isColombia}
          onSubmit={this.onSubmit}
          integrationState={this.props.integrationState}
        />
      </FormWrapper>
    );
  }
}

ModifyStrainsPage.propTypes = {
  actions: PropTypes.object.isRequired,
  integrationState: PropTypes.object.isRequired
};

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, { goBack, clearSelectedData, unsetData });
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    isCanada: isCanadaFacility(state),
    isColombia: isColombiaFacility(state),
    integrationState: getIntegrationState(state)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModifyStrainsPage);
