import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { I18n } from 'react-redux-i18n';
import get from 'lodash.get';
import * as actions from '../../actions/facilityActions';

import Facilities from './Facilities';

export class FacilitiesPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.handleFacilityClick = this.handleFacilityClick.bind(this);
  }

  handleFacilityClick(facility) {
    // If this is a Utah facility and we are not currently on a leafdatasystem url, we have to redirect to login page
    if (
      !window.location.hostname.includes('leafdatasystems') && this.props.ssoEnabled &&
      get(facility, 'configuration_packs', []).some((configPack) => configPack.includes('utah'))
    ) {
      window.location.replace(`${this.props.utahUrl}/facilities`);
      return;
    } else if (
      window.location.hostname.includes('leafdatasystems') && this.props.ssoEnabled &&
      !get(facility, 'configuration_packs', []).some((configPack) => configPack.includes('utah'))
    ) {
      window.location.replace(`${this.props.platformUrl}/facilities`);
      return;
    }

    this.props.actions.setFacility(facility);
  }

  render() {
    return (
      <div>
        <h1>{I18n.t('facilities.choose')}</h1>
        <Facilities facilities={this.props.facilities} handleFacilityClick={this.handleFacilityClick} />
      </div>
    );
  }
}

FacilitiesPage.propTypes = {
  actions: PropTypes.object.isRequired,
  facilities: PropTypes.array.isRequired,
  utahUrl: PropTypes.string,
  platformUrl: PropTypes.string,
  ssoEnabled: PropTypes.bool
};

function mapStateToProps(state, ownProps) {
  return {
    facilities: state.facilities,
    utahUrl: window.location.hostname.includes('pre') ? state.env.utahUrlPrerelease : state.env.utahUrl,
    platformUrl: state.env.platformUrl,
    ssoEnabled: state.env.ssoEnabled
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FacilitiesPage);
