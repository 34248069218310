import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, formValueSelector} from 'redux-form';
import get from 'lodash.get';

import ReconciliationForm from './ReconciliationForm';
import MetrcReconciliationForm from './MetrcReconciliationForm';
import validate from './validate';
import {EXCLUDE_METRC_RECONCILIATION_TABS} from '../../../constants/integration/metrc/metrcReconciliation';

const form = 'reconciliation';
const selector = formValueSelector(form);

const Component = (props) => {
  // TODO: in case still need MetrcReconciliationForm, it can be removed after confirm
  const {integrationState, activeTabEventKey} = props;
  const activeTab = props.tableData.tabs.find(tab => tab.id === Number(props.activeTab));
  const metrcTabs = EXCLUDE_METRC_RECONCILIATION_TABS.includes(get(activeTab, 'category_code'));
  
  const ResultForm = (integrationState.isMetrc && !metrcTabs && activeTabEventKey!=0 ) ? MetrcReconciliationForm : ReconciliationForm;
  return <ResultForm {...props} />;
};

Component.propTypes = {
  integrationState: PropTypes.object,
  tableData: PropTypes.object,
  activeTab: PropTypes.number,
};

const ReconciliationReduxForm = reduxForm({
  validate,
  form,
  enableReinitialize: true,
})(Component);

function mapStateToProps(state, ownProps) {
  const {categories, adjustment_reason, integration_adjustment_reason} = selector(state, 'categories', 'adjustment_reason', 'integration_adjustment_reason');
  const {isMetrc} = ownProps.integrationState || {};
  const adjustAllEnabled = (categories || []).some( category => {
    return category.items.some( item => {
      const hasDiscrepancy = Number.parseFloat(item.discrepancy) !== 0;
      const hasMetrcAdjustmentReason = integration_adjustment_reason || item.integration_adjustment_reason;
      if(hasDiscrepancy) {
        if(isMetrc && !hasMetrcAdjustmentReason){
          return false;
        }
        return true;
      }
      return false;
    });
  });

  return {
    getFormValue: name => selector(state, name),
    adjustment_reason,
    integration_adjustment_reason,
    adjustAllEnabled,
    itemsLookup: categories && categories.length ? categories[0].items : [],
  };
}

const ReconciliationFormWrapper = connect(mapStateToProps)(ReconciliationReduxForm);

ReconciliationFormWrapper.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onAdjust: PropTypes.func.isRequired,
  onRedirect: PropTypes.func.isRequired,
  adjustmentReasons: PropTypes.array.isRequired,
  integrationAdjustmentReasons: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
};

export default ReconciliationFormWrapper;
