/* eslint-disable import/default */
import throttle from 'lodash.throttle';
import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import ReactGA from 'react-ga';
import {Router, browserHistory} from 'react-router';
import {syncHistoryWithStore} from 'react-router-redux';
import {syncTranslationWithStore} from 'react-redux-i18n';
import LogRocket from 'logrocket';
import 'react-select/dist/react-select.css';
import './styles/bootstrap.scss';
import routes from './routes/';
import configureStore from './store/configureStore';
import {loadState, saveState/*, loadAuth*/} from './store/localStorage';
import {getHealthCheck, setToken} from './actions/systemActions';
import {loadConstants, loadNotifications, loadUseEntityLocks} from './actions/initActions';
import {setFacilitySuccess} from './actions/facilityActions';
import {logout,setUser} from './actions/userActions';
import initialState from './constants/initialState';
import removeChildWorkaround from './util/removeChildWorkaround';
// import {getErrorBoundary, notifyBugsnag, registerBugsnagClient} from './util/bugsnag';
import './styles/styles.scss'; // Yep, that's right. You can import SASS/CSS files too! Webpack will run the associated loader and plug this into the page.
import './styles/react-bootstrap-table.min.css';
import Config from './managers/config';
import {AuthRequest} from './managers/request';

let props = document.getElementById('app-props').textContent;
props = props.replace('<![CDATA[', '').replace(']]>', '');
const server = JSON.parse(props);
//Use below if enabling rum sdk
//const {ga, rumConfig, serverState} = server;
const {ga, serverState} = server;
const{log_rocket_enabled} = serverState.env;
// const bsClient = registerBugsnagClient(bsConfig);

/* DISABLED UNTIL NEEDED OR FULL DEPRECATION
//Datadog Real User Monitoring
const { datadogRum } = require('@datadog/browser-rum');

//TODO Validate required Variables
//const requiredVariables = ['DD_RUM_TOKEN', 'DD_SERVICE', 'DD_ENV', 'APP_VERSION', 'RUM_APP_ID' ]; // Add other required variables

//const missingVariables = requiredVariables.filter(variable => !Config.setting('rumConfig')[variable]);

//if (missingVariables.length === 0) {
Config.add('rumConfig', rumConfig);
datadogRum.init({
  applicationId: Config.setting('rumConfig.applicationId'),
  clientToken: Config.setting('rumConfig.clientToken'),
  site: Config.setting('rumConfig.site'),
  service: Config.setting('rumConfig.service'),
  env: Config.setting('rumConfig.env'),
  version: Config.setting('rumConfig.version'),
  sessionSampleRate: Config.setting('rumConfig.sessionSampleRate'),
  sessionReplaySampleRate: Config.setting('rumConfig.sessionReplaySampleRate'),
  trackUserInteractions: Config.setting('rumConfig.trackUserInteractions'),
  trackResources: Config.setting('rumConfig.track.Resources'),
  trackLongTasks: Config.setting('rumConfig.trackLongTasks'),
  defaultPrivacyLevel: Config.setting('rumConfig.defaultPrivacyLevel')
});
console.log(`DD RUM HOPEFULLY INITIALIZED - ${Config.setting('rumConfig')}`);
//} else {
//  console.log(`Skipping initialization. Missing environment variables: ${missingVariables.join(', ')}`);
//}
*/

Config.add('ga', ga);
if (Config.setting('ga.enabled') === 'true') {
  ReactGA.initialize(Config.setting('ga.tracking_id'), {debug: (Config.setting('ga.debug') === 'true')});
}

try{
  require('./favicon.ico'); // Tell webpack to load favicon.ico

  const storedState = loadState();
  const configState = Object.assign(
    {},
    initialState,
    storedState ? storedState : {},
    serverState
  );
  const store = configureStore(configState, browserHistory);
  store.subscribe(throttle(() => {
    if(!document.hidden){
      saveState(store.getState());
    }
  }, 1000));
  store.dispatch(getHealthCheck());
  setTimeout(() => {
    store.dispatch(loadConstants());
    store.dispatch(loadNotifications());
    store.dispatch(loadUseEntityLocks());
  }, 100);

  AuthRequest.setToken((request) => {
    const {headers: {Authorization}} = store.getState();
    request.headers['Authorization'] = Authorization;
    return request;
  });

  const TokenExchange = (response) => {
    const request_auth = response.config.headers.Authorization;
    const response_auth = response.headers.authorization;

    if (response_auth && request_auth != response_auth) {
      store.dispatch(setToken(response_auth));
    }
    return response;
  };

  AuthRequest.swapToken(TokenExchange, (error) => {
    const response = error.response || null;
    if (response) {
      TokenExchange(response);
    }
    return AuthRequest.catchErrors(error);
  });

  // Workaround of React DOM errors
  removeChildWorkaround();

  // storage event handlers keep app state synced across multiple windows
  window.addEventListener('storage',
    (event) => {

      // auth token update
      if(event.key === 'Authorization') {
        const auth = event.newValue;
        if(auth){
          store.dispatch(setToken(auth));
        }else{
          store.dispatch(logout());
        }
      }

      // facility change
      if(event.key === 'facility'){
        const facility = event.newValue;
        if(facility){
          store.dispatch(setFacilitySuccess(JSON.parse(facility), true));
        }
      }

      // user change
      if(event.key === 'login'){
        const login = event.newValue;
        if(login){
          store.dispatch(setUser(JSON.parse(login)));
        }
      }
    },
    false
  );

  const loginCheck = localStorage.getItem('lc');
  const loginCheckIsAuthorized = localStorage.getItem('Authorization');
  const currentTime = + new Date();
  if(loginCheckIsAuthorized && currentTime - loginCheck > 10000) {
    store.dispatch(logout());
  }
  setInterval(() => {
    localStorage.setItem('lc', + new Date());
  }, 5000);

  const history = syncHistoryWithStore(browserHistory, store);
  syncTranslationWithStore(store);

  history.listen(location => {
    if (Config.setting('ga.enabled')) {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    }
  });

  // Remove the loading message which otherwise continues to display warning of browser compatibility issue.
  const el = document.getElementById('defaultLoadingMessage');
  if(el !== null) el.parentNode.removeChild(el);
  let ErrorBoundary;
  // if (bsClient) {
  //   ErrorBoundary = getErrorBoundary();
  // }

  if (ErrorBoundary) {
    render(
      <ErrorBoundary>
        <Provider store={store}>
            <Router history={history} routes={routes} />
        </Provider>
      </ErrorBoundary>
    , document.getElementById('app')
    );
  } else {
    render(
      <Provider store={store}>
          <Router history={history} routes={routes} />
      </Provider>, document.getElementById('app')
    );
  }

  if (log_rocket_enabled) {
    LogRocket.init('dv1wzc/mjp');
  }


  // if (module.hot) {
  //   module.hot.accept();
  // }
} catch(e) {
  // notifyBugsnag(e);
  console.log(e); // eslint-disable-line
}
