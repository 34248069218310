import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import orderBy from 'lodash.orderby';
import * as itemNames from '../../../constants/itemNames';
import {getItem, postItem} from '../../../actions/apiActions';
import {setData} from '../../../actions/dataActions';
import {getLabelComplianceInitialValues} from '../../../selectors/labelsSelectors';
import InProgressOverlay from '../../common/InProgressOverlay';
import SettingsFormWrapper from './components/SettingsFormWrapper';

class LabelsSettingPage extends React.PureComponent {

  constructor(props, context) {

    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.redirect = this.redirect.bind(this);
    this.hideLoader = this.hideLoader.bind(this);

    this.state = {
      showLoader: false,
      message: false,
      keyToTagMap: {
        package_label_2x4: 'inv_package_tag_2x4',
        package_label_large: 'inv_package_tag_large',
        package_label_small: 'inv_package_tag_small',
        patient_label_2x4: 'patient_label_2x4',
        patient_label_large: 'patient_label',
        patient_label_small: 'patient_label_small'
      },
    };

  }

  componentWillMount(){
    this.loadCompliance();
  }

  loadCompliance(){
    this.setState({showLoader: true, message: I18n.t('cultivation.complianceSettings.loadingSettings')});
    this.props.actions.getItem('/api/labels/compliance_settings', itemNames.labelsCompliance)
      .then(this.hideLoader)
      .catch(this.hideLoader);
  }

  hideLoader(){
    this.setState({showLoader: false});
  }

  // Parses initial values into panels by type for presentation but is still a single form.
  getPanels(){

    let panels = [];
    if(this.props.initialValues.fields !== undefined) {
      panels = Object.keys(this.props.initialValues.fields).reduce((acc, field) => {
        const f = this.props.initialValues.fields[field];
        if(f.valid_for.indexOf(this.props.tag) === -1 && f.valid_for.indexOf('all') === -1) return acc;
        let currentPanel = acc.find((p) => p.type === f.group);
        if (!currentPanel) {
          currentPanel = {type: f.group, settings: []};
          acc.push(currentPanel);
        }
        const def = {name: field, text: `cultivation.labelSettings.${field}`};
        def.optionsString = (field === 'minimum_font_size') ? 'fontSize' : 'yesNo';
        currentPanel.settings.push(def);
        return acc;
      }, []);
      panels = orderBy(panels, 'type');
      panels = panels.map(panel => {
        panel.settings = orderBy(panel.settings, 'text');
        return panel;
      });
    }
    return panels;

  }

  onSubmit(formValues) {
    let values;

    if(formValues.saveAndStay){ // Cherry picks fields from the form if a specific panel is saved
      const panels = this.getPanels();
      values = panels.reduce((acc, panel) => {
        if(panel.type !== formValues.saveAndStay) return acc;
        acc = panel.settings.reduce((acc, setting) => {
          acc[setting.name] = formValues[setting.name];
          return acc;
        }, this.props.staticInitialValues.package);
        return acc;
      }, this.props.staticInitialValues.package);
    } else { // Normal save all
      values = Object.assign({}, formValues);
    }

    const labels = ['package_label_large', 'package_label_small', 'package_label_2x4', 'patient_label_large', 'patient_label_small', 'production_inv_package_tag_label', 'patient_label_2x4', 'afterSubmit'];
    labels.forEach((label) => {
      delete(values[label]);
    });

    this.setState({showLoader: true, message: I18n.t('cultivation.complianceSettings.savingSettings')});
    const payload = {
      settings: [
        {
          setting_key: this.props.label.compliance_settings_key,
          scope: 'facility',
          value: [values],
          target: this.props.tag
        }
      ]
    };
    this.props.actions.postItem('/api/labels/compliance_settings', payload, null, {}, {}, () => {
      this.hideLoader();
      if(formValues.afterSubmit !== 'stay' && !formValues.saveAndStay){
        this.redirect();
        return false;
      }
      this.loadCompliance();
    });

  }

  redirect() {
    this.props.actions.goBack();
  }

  render() {

    const panels = this.getPanels();

    return (
      <div>
        <InProgressOverlay message={this.state.message} isActive={this.state.showLoader} />
        <SettingsFormWrapper
          onSubmit={this.onSubmit}
          panels={panels}
          enableReinitialize={true}
          initialValues={this.props.initialValues.package}
        />
      </div>
    );
  }
}

LabelsSettingPage.propTypes = {
  initialValues: PropTypes.object.isRequired,
  complianceSettings: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    setData: PropTypes.func.isRequired
  }),
  tag: PropTypes.string.isRequired,
  label: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    complianceSettings: state[itemNames.labelsCompliance],
    initialValues: getLabelComplianceInitialValues(state),
    staticInitialValues: getLabelComplianceInitialValues(state)
  };
}

function mapDispatchToProps (dispatch) {
  const actions = {goBack, getItem, postItem, setData};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LabelsSettingPage);
