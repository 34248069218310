import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import {Field, FormSection} from 'redux-form';

import CheckBoxInput from '../common/form/CheckBoxInput';
import TextInput from '../common/form/TextInput';
import ReactSelectInput from '../common/form/ReactSelectInput';
import SubmitSection from '../common/form/SubmitSection';

const CreditCardsSettingsForm = ({pristine, invalid, submitting, reset, handleSubmit, creditCardProcessors, processor}) => {
  const settings = {
    actionSettings: {
      reset: {
        text: I18n.t('common.form.reset'),
        action: reset,
        style: 'default',
      },
      submit: {
        submitting,
        invalid,
        pristine,
        style: 'primary',
        text: I18n.t('common.form.save'),
      },
    },
  };

  return (
    <form onSubmit={handleSubmit} noValidate={true}>
      <Row>
        <Col xs={6} sm={5} md={4} lg={3}>
          <Field name='payment_credit_card_processor.value' component={ReactSelectInput} props={{
            isRequired: true,
            label: I18n.t('creditCardsSettings.form.creditCardProcessor'),
            options: creditCardProcessors,
          }}/>
        </Col>
      </Row>

      {processor === 'authnet' ?
        <FormSection name='payment_cc_authnet_settings.value'>
          <Row>
            <Col xs={6} sm={5} md={4} lg={3}>
              <Field name='api_login' component={TextInput} props={{
                label: I18n.t('creditCardsSettings.form.apiLogin'),
                isRequired: true,
              }}/>
            </Col>
          </Row>
          <Row>
            <Col xs={6} sm={5} md={4} lg={3}>
              <Field name='transaction_key' component={TextInput} props={{
                label: I18n.t('creditCardsSettings.form.transactionKey'),
                isRequired: true,
              }}/>
            </Col>
          </Row>
          <Row>
            <Col xs={6} sm={5} md={4} lg={3}>
              <Field name='is_live_mode' component={CheckBoxInput} props={{
                label: I18n.t('creditCardsSettings.form.liveMode'),
                groupClassName:'inline-checkbox',
              }}/>
            </Col>
          </Row>
        </FormSection>
        : null
      }

      {processor === 'nmi' ?
        <FormSection name='payment_cc_nmi_settings.value'>
          <Row>
            <Col xs={6} sm={5} md={4} lg={3}>
              <Field name='url' component={TextInput} props={{
                label: I18n.t('creditCardsSettings.form.url'),
                isRequired: true,
              }}/>
            </Col>
          </Row>
          <Row>
            <Col xs={6} sm={5} md={4} lg={3}>
              <Field name='username' component={TextInput} props={{
                label: I18n.t('creditCardsSettings.form.username'),
                isRequired: true,
              }}/>
            </Col>
          </Row>
          <Row>
            <Col xs={6} sm={5} md={4} lg={3}>
              <Field name='password' component={TextInput} props={{
                label: I18n.t('creditCardsSettings.form.password'),
                isRequired: true,
              }}/>
            </Col>
          </Row>
        </FormSection>
        : null
      }

      {processor === 'moneris' ?
        <FormSection name='payment_cc_moneris_settings.value'>
          <Row>
            <Col xs={6} sm={5} md={4} lg={3}>
              <Field name='store_id' component={TextInput} props={{
                label: I18n.t('creditCardsSettings.form.store_id'),
                isRequired: true,
              }}/>
            </Col>
          </Row>
          <Row>
            <Col xs={6} sm={5} md={4} lg={3}>
              <Field name='api_token' component={TextInput} props={{
                label: I18n.t('creditCardsSettings.form.api_token'),
                isRequired: true,
              }}/>
            </Col>
          </Row>
        </FormSection>
        : null
      }

      <SubmitSection settings={settings}/>
    </form>
  );
};

CreditCardsSettingsForm.propTypes = {
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  creditCardProcessors: PropTypes.array.isRequired,
  processor: PropTypes.string,
};

export default CreditCardsSettingsForm;
