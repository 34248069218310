import {change, getFormValues, arrayRemove, arrayInsert} from 'redux-form';
import get from 'lodash.get';
import {HARVEST_PLANTS_FORM} from '../../constants/forms';
import {roundQtyCurried} from '../../selectors/uomsSelectors';
import * as uoms from '../../constants/uoms';
import {getFormArrayIndexFromString, isChangeAction, isChangeActionOnField} from './utils';

const harvestPlantsMiddleware = store => next => action => {
  const result = next(action);

  /**
   * Handle case where uom is changed on harvest form to ounces we need to reduce precision of the inputs.
   */
  if(isChangeAction(action, [HARVEST_PLANTS_FORM])){
    const {payload, meta} = action;
    const uom = payload;
    if(meta.field === 'uom' && uom === uoms.OZ){
      const roundQuantity = roundQtyCurried(store.getState());
      const formValues = getFormValues(meta.form)(store.getState());
      const plants = get(formValues, 'plants', []);
      const precisionFields = ['wet_weight_harvest', 'total_waste_recorded', 'wet_other_material_weight', 'total_other_material_waste'];
      plants.forEach((plant, index) => {
        precisionFields.forEach((field) => {
          if(get(plant, field)) {
            const formField = `plants`;
            plant[field] = roundQuantity(get(plant, field, 0), uom);
            store.dispatch(arrayRemove(meta.form, formField, index));
            store.dispatch(arrayInsert(meta.form, formField, index, plant));
          }
        });
      });
      precisionFields.forEach((field) => {
        if(formValues[field] !== undefined){
          const weight = roundQuantity(get(formValues, field, 0), uom);
          store.dispatch(change(meta.form, field, weight));
        }
      });
    }

    handleWeightAndWasteChanges(
      action, store, meta,
      ['wet_weight_harvest', 'total_waste_recorded'],
      'remaining_wet_weight_harvest'
    );

    handleWeightAndWasteChanges(
      action, store, meta,
      ['wet_other_material_weight', 'total_other_material_waste'],
      'remaining_wet_other_material_weight'
    );

  }
  return result;
};

/**
 * Calculates derived field value based on whether fields of interest are changed. Handle both individual plants and bulk updates
 *
 * @param action
 * @param store
 * @param meta
 * @param fieldsOfInterest
 * @param changeField
 */
const handleWeightAndWasteChanges = (action, store, meta, fieldsOfInterest, changeField) => {
  let isPertinentChange = false;

  for (const field of fieldsOfInterest) {
    if (isChangeActionOnField(action, [HARVEST_PLANTS_FORM], field)) {
      isPertinentChange = true;
      break;
    }
  }

  if (!isPertinentChange) return;

  const state = store.getState();
  const formValues = getFormValues(meta.form)(state);
  const use_overall = get(formValues, 'use_overall');
  let currentValuesObject;

  // Use Individual Values (some plants)
  if (use_overall) {
    const plantIndex = getFormArrayIndexFromString(meta.field);
    const plants = get(formValues, 'plants', []);

    currentValuesObject = plants[plantIndex];
    changeField = `plants[${plantIndex}].${changeField}`;
  } else {
    currentValuesObject = formValues;
  }

  const wetWeight = get(currentValuesObject, fieldsOfInterest[0],0);
  const totalWaste = get(currentValuesObject, fieldsOfInterest[1],0);
  const remainingWeight = wetWeight - totalWaste;

  store.dispatch(
    change(HARVEST_PLANTS_FORM, changeField, remainingWeight)
  );
};

export default harvestPlantsMiddleware;
