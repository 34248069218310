import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack} from 'react-router-redux';
import {formValueSelector} from 'redux-form';
import * as itemNames from '../../constants/itemNames';
import {getCreditCardProcessorsOptions} from '../../selectors/credit-cards/creditCardProcessorsSelectors';
import {getItem, postItem} from '../../actions/apiActions';
import FormWrapper from '../common/form/FormWrapper';
import CreditCardsSettingsFormWrapper from './CreditCardsSettingsFormWrapper';

const form = 'creditCardsSettings';
const selector = formValueSelector(form);

export class CreditCardSettingsPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount() {
    this.props.actions.getItem('/api/credit_cards/credit_card_processors', itemNames.creditCardProcessors);
    this.props.actions.getItem('/api/credit_cards/settings', itemNames.creditCardsSettings);
  }

  onSubmit(formData) {
    const payload = {
      settings: Object.keys(formData).map(setting => ({
        setting_key: setting,
        scope: 'facility',
        value: formData[setting].value,
      }))
    };
    this.props.actions.postItem('/api/credit_cards/settings', payload, itemNames.creditCardsSettings, {success: 'creditCardsSettings.save.success', failed: 'creditCardsSettings.save.failed'})
      .then(this.props.actions.goBack);
  }

  render() {
    const {creditCardProcessors, creditCardsSettings, processor} = this.props;
    return (
      <FormWrapper goBack={this.props.actions.goBack} title='creditCardsSettings.title'>
        <CreditCardsSettingsFormWrapper
          form={form}
          initialValues={creditCardsSettings}
          onSubmit={this.onSubmit}
          creditCardProcessors={creditCardProcessors}
          processor={processor}
        />
      </FormWrapper>
    );
  }
}

CreditCardSettingsPage.propTypes = {
  creditCardProcessors: PropTypes.array.isRequired,
  creditCardsSettings: PropTypes.object.isRequired,
  processor: PropTypes.string,
  actions: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    creditCardProcessors: getCreditCardProcessorsOptions(state),
    creditCardsSettings: state[itemNames.creditCardsSettings],
    processor: selector(state, 'payment_credit_card_processor.value'),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    goBack, getItem, postItem,
  };
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardSettingsPage);
