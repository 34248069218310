import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Badge, Nav, Navbar, Image, Row, Col, Collapse} from 'react-bootstrap';
import {FaBars} from 'react-icons/fa';
import {push} from 'react-router-redux';
import {Link} from 'react-router';
import * as ReactDOM from 'react-dom';
import { isFeatureEnabled } from '../../selectors/featureToggles';
import {getActiveFacilityId, isFacilitySwitchInProgress} from '../../selectors/facilitiesSelectors';
import {getUnreadNotificationsCount} from '../../selectors/notificationsSelectors';
import {getCurrentUserPermissions, getCurrentUser, getProfileImageUrl} from '../../selectors/usersSelectors';
import {isCanadaFacility} from '../../selectors/facility/getFacilityCountry';
import * as apiActions from '../../actions/apiActions';
import * as userActions from '../../actions/userActions';
import * as menuActions from '../../actions/menuActions';
import * as systemActions from '../../actions/systemActions';
import * as itemNames from '../../constants/itemNames';
import {getTranslations} from '../../actions/forms/userAccountActions';
import UserOptsMenu from './UserOptsMenu';
import FacilityMenu from './FacilityMenu';
import PatientProfileLoader from './PatientProfileLoader';
import CartDrawer from '../cart/CartDrawer';

class Header extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.state = {imageLoaded: false};
    this.getAppContainer = this.getAppContainer.bind(this);
    this.getUserContainer = this.getUserContainer.bind(this);
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
  }

  componentWillMount() {
    // If integrationSettings are not known, download them to determine whether the Integration link should be displayed.
    const {facility, integrationSettings} = this.props;
    if (facility.id && !integrationSettings.integration_state_integrator) {
      this.props.actions.getItem(
        '/api/integration-settings',
        itemNames.integrationSettings,
        {failed: 'stateIntegrators.settings.get.failed'}
      );
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick, true);
  }

  handleDocumentClick(e) {
    const {userOptsMenuOpen} = this.props.menus;
    const {toggleUserOptsMenu} = this.props.actions;
    const closeDrawer = (container, action) => {
      const domNode = ReactDOM.findDOMNode(container);
      if (domNode && !domNode.contains(e.target)) action();
    };

    if(userOptsMenuOpen){
      closeDrawer(this.userContainer, toggleUserOptsMenu);
    }

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.facility.organizationId && (nextProps.facility.organizationId !== this.props.facility.organizationId || !this.state.imageLoaded)) {
      this.setState({imageLoaded: true}, () => {
        if (this.props.user.image_file_id) {
          this.props.actions.getItem(`/api/images/${this.props.user.image_file_id}`, itemNames.profileImage);
        }
      });
    }
  }

  getAppContainer(ref) {
    this.appContainer = ref;
  }

  getUserContainer(ref) {
    this.userContainer = ref;
  }

  render() {
    const {userOptsMenuOpen, custDetailsOpen, cartDetailsOpen} = this.props.menus;
    const {
      notifications, facilities, environment, userPermission, facility, user, image, activeFacilityId,
      isFacilitySwitchInProgress
    } = this.props;
    const {toggleMainNavMenu, toggleUserOptsMenu, getTranslations, toggleCartDetails,
      logout} = this.props.actions;
    const loadRetailComponents = facility && facility.type && facility.type === 'dispensary';
    return (
      <Navbar variant='dark' expanded='true' fixed='top' className='header'>
        <Row>
          <Col xs={{span: 5}} sm={{span: 5}} md={{span: 5}}>
            {activeFacilityId && !isFacilitySwitchInProgress &&
              <Nav variant='pills' className='float-left navbar-left'>
                <Nav.Item className='header-anchor main-nav-button' onClick={toggleMainNavMenu}>
                  <Navbar>
                    <Navbar.Brand className='hamburger-menu'>
                      <FaBars />
                    </Navbar.Brand>
                  </Navbar>
                </Nav.Item>
              </Nav>
            }

            {environment ?
              <div className='environment-indicator'>
                <b className={`${environment}`}>{environment}</b>
              </div> : null
            }
          </Col>
          <Col xs={{span: 2}} sm={{span: 2}} md={{span: 2}} className='header-anchor'>
            <Link to='/'>
              <div className={'header-logo' + (this.props.isAkernaRebrandingDemo ? ' is-akerna' : '')} />
            </Link>
          </Col>
          <Col xs={{span: 5}} sm={{span: 5}} md={{span: 5}} ref={this.getUserContainer}>
            <Nav variant='pills' className='float-right navbar-right'>
              {facilities.length > 0 ? <FacilityMenu>
                {this.props.children}
              </FacilityMenu> : null}
              <Nav.Item className='header-anchor user-opts-button' onClick={toggleUserOptsMenu}>
                <Image className='user-avatar' src={image} />
                {notifications ? <Badge className='img-circle'>{notifications}</Badge> : null}
              </Nav.Item>
            </Nav>
            <Collapse in={userOptsMenuOpen} dimension={'width'} className='user-opts-container'>
              <div>
                <UserOptsMenu
                  clearMeta={this.props.actions.clearMeta}
                  notifications = {notifications}
                  translateEnglish={() => getTranslations(this.props.isCanadaFacility ? 'enCA' : 'en' , true)}
                  translateSpanish={() => getTranslations('es' , true)}
                  translateFrench={() => getTranslations('fr' , true)}
                  translateItalian={() => getTranslations('it' , true)}
                  handleLogout={logout}
                  handleCloseClick={toggleUserOptsMenu}
                  userPermission={userPermission}
                  user={user}
                />
              </div>
            </Collapse>

            {/*//TODO:: Unify these but don't want to mess with positioning; in 0.7.2 hotfix*/}
            {/* Was loading regardless of facility and in some rare cases before facility was selected */}
            {
              loadRetailComponents
                ? <Collapse in={custDetailsOpen}  dimension={'width'} className='customer-container'>
                  <div>
                    <PatientProfileLoader />
                  </div>
                </Collapse>
                : null
            }
            {
              loadRetailComponents
                ? <Collapse in={cartDetailsOpen}  dimension={'width'} className='cart-container'>
                  <div>
                    <CartDrawer
                      onClose={toggleCartDetails}
                    />
                  </div>
                </Collapse>
                : null
            }
          </Col>
        </Row>
      </Navbar>
    );
  }
}

Header.propTypes = {
  actions: PropTypes.object.isRequired,
  menus: PropTypes.object.isRequired,
  customer: PropTypes.object,
  user: PropTypes.object,
  facilities: PropTypes.array,
  facility: PropTypes.object.isRequired,
  notifications: PropTypes.number,
  children: PropTypes.element,
  integrationSettings: PropTypes.object.isRequired,
  environment: PropTypes.string,
  userPermission: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  image: PropTypes.string,
  isCanadaFacility: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  notifications: getUnreadNotificationsCount(state),
  environment: state.system.environment,
  menus: state.menus,
  customer: state.customer,
  facilities: state.facilities,
  activeFacilityId: getActiveFacilityId(state),
  isFacilitySwitchInProgress: isFacilitySwitchInProgress(state),
  facility: state.facility,
  integrationSettings: state.integrationSettings,
  userPermission: getCurrentUserPermissions(state),
  user: getCurrentUser(state),
  image: getProfileImageUrl(state),
  isCanadaFacility: isCanadaFacility(state),
  isAkernaRebrandingDemo: isFeatureEnabled(state)('feature_akerna_rebranding_demo'),
});

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, menuActions, systemActions, userActions, {push, getTranslations});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (Header);
