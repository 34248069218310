import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack} from 'react-router-redux';
import get from 'lodash.get';
import {Button, Card, Col, FormControl, InputGroup, Row} from 'react-bootstrap';
import {FaArrowAltCircleDown, FaArrowAltCircleUp, FaBarcode, FaTrash} from 'react-icons/fa'; // eslint-disable-line import/no-named-as-default
import {getItem, postItem, putItem, deleteItem, postData} from '../../../actions/apiActions';
import {setData} from '../../../actions/dataActions';
import {unsetItem} from '../../../actions/itemActions';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import InProgressOverlay from '../../common/InProgressOverlay';
import NamedDefinitions from './NamedDefinitions';
import LabelProperties from './LabelProperties';
import RowFormatters from './RowFormatters';
import ModalWrapper from '../../common/ModalWrapper';
import NewLabelDefinition from './NewLabelDefinition';
import PropertyEditor from './PropertyEditor';
import RowFormatEditor from './RowFormatEditor';
import AddLabelRow from './AddLabelRow';
import PreviewLabel from './PreviewLabel';
import DataKeysList from './DataKeysList';
import {getInternationalNumberFormat} from '../../../selectors/InternationalOperationsSelectors';
import PrinterModal from '../../printer/PrinterModal'; // eslint-disable-line import/no-named-as-default


class LabelEditor extends React.PureComponent {

  constructor(props, context) {

    super(props, context);
    this.redirect = this.redirect.bind(this);
    this.hideLoader = this.hideLoader.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.onSaveModalData = this.onSaveModalData.bind(this);
    this.onChangeModalData = this.onChangeModalData.bind(this);
    this.onSelectNamedDefinition = this.onSelectNamedDefinition.bind(this);
    this.onMoveRow = this.onMoveRow.bind(this);
    this.onDeleteLabel = this.onDeleteLabel.bind(this);
    this.onConfirmDeleteLabel = this.onConfirmDeleteLabel.bind(this);
    this.onDeleteRow = this.onDeleteRow.bind(this);
    this.onLookupCode = this.onLookupCode.bind(this);
    this.onCodeChange = this.onCodeChange.bind(this);
    this.onHidePrinter = this.onHidePrinter.bind(this);
    this.onShowPrinter = this.onShowPrinter.bind(this);
    this.getLabelUrl = this.getLabelUrl.bind(this);
    this.getScanHint = this.getScanHint.bind(this);
    this.isProductionLabel = this.isProductionLabel.bind(this);

    this.state = {
      showLoader: false,
      message: '',
      showOk: false,
      showLoadingIcon: true,
      keyToTagMap: {
        package_label_2x4: 'inv_package_tag_2x4',
        package_label_large: 'inv_package_tag_large',
        package_label_small: 'inv_package_tag_small',
        package_label_solo: 'inv_package_tag_solo',
        inv_package_tag_verbose: 'inv_package_tag_verbose',
        patient_label_2x4: 'patient_label_2x4',
        patient_label_large: 'patient_label',
        patient_label_small: 'patient_label_small',
        plant_label_small: 'cult_plant_tag_small',
        plant_label_large: 'cult_plant_tag_large',
        plant_label_solo: 'cult_plant_tag_solo',
        harvestBatchPlantLabel: 'harvestBatchPlantLabel',
        harvest_batch_plant_label: 'harvest_batch_plant_label',
        production_inv_package_tag_label: 'production_inv_package_tag_label',
      },
      definitions: [],
      currentDefinitionIndex: -1,
      currentRowIndex: -1,
      showModal: false,
      modalTitle: 'Loading',
      modalNoTranslation: '',
      component: NamedDefinitions,
      activeModalComponent: false,
      modalData: {},
      unsavedChanges: false,
      confirmDelete: false,
      lookupCode: '',
      lookedUpCodes: [],
      reloadLabel: true,

      showPrinter: false,
      labelUrl: '',

    };

  }

  componentWillMount(){
    this.addFormatsToState();
    this.getDefinitions();
  }

  getDefinitions(){
    const tag = this.state.keyToTagMap[this.props.tag];
    this.props.actions.getItem(`/api/labels/definition/${tag}`, null, {}, {}, (data) => {
      const currentDefinitionIndex = data.reduce((acc, def, index) => {
        if(acc > -1) return acc;
        if(def.name === 'Default') acc = index;
        return acc;
      }, -1);

      // Flattening nested definition lines for the moment for customization.
      const flattened = data.map((d) => {
        d.definition = d.definition.reduce((acc, def) => {
          // Prevents editor from being broken by "box" setting for colorado test results
          // Image version will introduce for all fields but is much work for not much gain right now otherwise.
          if(def.value === 'testResults'){
            if(def.outputFormat && def.outputFormat.box){
              delete(def.outputFormat.box);
            }
          }
          if(!Array.isArray(def)) {
            acc.push(def);
          } else {
            def.forEach((dd) => {
              acc.push(dd);
            });
          }
          return acc;
        }, []);
        return d;
      });
      this.setState({definitions: flattened, currentDefinitionIndex});
    });
  }

  addFormatsToState(){

    const formatSettings = {
      align: 'Align',
      bold: 'Bold',
      fontSize: 'Font Size',
      left: 'Left Offset',
      rtop: 'Relative Top',
      atop: 'Absolute Top',
      barcode: 'Bar Code',
      casing: 'Letter Casing',
      dateDisplay: 'Date',
      display: 'Display',
      nowrap: 'No Wrap',
      ignoreMaximum: 'Ignore Code Protection',
      hideLabel: 'Hide Label',
      replaceLabel: 'Replace Label',
      replaceValue: 'Replace Value',
      anchorBottom: 'Anchor Bottom',
      noCodeProtect: 'No Code Protection',
      width: 'Width',
      rotateLabel: 'rotateLabel',
      convertToYesNo: 'Yes or No',
      barcodeHeight: 'Bar Code Height',
      c128WidthScale: 'C128 Horizontal Scale'
    };

    const formatValues = {
      align: {type: 'output', values: [
        {text: 'Left', value: 'Left'},
        {text: 'Center', value: 'center'},
        {text: 'Right', value: 'right'}
      ]},
      convertToYesNo: {type: 'format', values: [
        {text: 'Enabled', value: 'yesNo'},
        {text: 'Disabled', value: 'noYesNo'}
      ]},
      bold: {type: 'output', values: 1},
      left: {type: 'output', values: 'inches'},
      rtop: {type: 'output', values: 'inches'},
      atop: {type: 'output', values: 'inches'},
      fontSize: {type: 'output', values: 'number'},
      barcode: {type: 'output', values: [
        {text: 'Bar Code', value: 'bar'},
        {text: 'QR Code', value: 'qrcode'},
        {text: 'S C128', value: 'c128'}
      ]},
      casing: {type: 'format', values: [
        {text: 'Proper Case', value: 'ucwords'},
        {text: 'Upper Case', value: 'upper'},
        {text: 'Lower Case', value: 'lower'}
      ]},
      dateDisplay: {type: 'format', values: [
        {text: 'Date Only', value: 'date'},
        {text: 'Date Time', value: 'datetime'},
        {text: 'DD/MM/YYYY', value: 'internationalDate'}
      ]},
      display: {type: 'output', values: [
        //{text: 'Always', value: 'always'},
        {text: 'Never', value: 'never'},
        {text: 'If Present', value: 'whenPresent'},
      ]},
      nowrap: {type: 'output', values: 1},
      ignoreMaximum: {type: 'output', values: 1},
      hideLabel: {type: 'output', values: 1},
      replaceLabel: {type: 'output', values: 'string'},
      replaceValue: {type: 'output', values: 'string'},
      anchorBottom: {type: 'output', values: 1},
      noCodeProtect: {type: 'output', values: 1},
      width: {type: 'output'},
      barcodeHeight: {type: 'output'},
      c128WidthScale: {type: 'output', values: [
        {text: '1', value: 1.5},
        {text: '2', value: 2},
        {text: '3', value: 2.5},
        {text: '4', value: 3},
        {text: '5', value: 3.5},
        {text: '6', value: 4},
        {text: '7', value: 4.5},
        {text: '8', value: 5},
        {text: '9', value: 5.5},
        {text: '10', value: 6},
        {text: '11', value: 6.5},
        {text: '12', value: 7},
        {text: '13', value: 7.5},
        {text: '14', value: 8},
        {text: '15', value: 8.5}
      ]}
    };

    this.setState({formatSettings, formatValues});

  }

  // Utility

  getCurrentDefinition(returnObject = false){
    if(!returnObject) {
      return this.state.definitions[this.state.currentDefinitionIndex] !== undefined
        ? this.state.definitions[this.state.currentDefinitionIndex]
        : false;
    }
    const definitions = this.state.definitions.map(def => def);
    const currentDefinition = definitions[this.state.currentDefinitionIndex];
    return {definitions, currentDefinition};
  }

  // Label Row Actions

  onMoveRow(direction, rowIndex){

    const {definitions, currentDefinition} = this.getCurrentDefinition(true);
    const rows = currentDefinition.definition.map((def) => def);

    const newIndex = (direction === 'up') ? rowIndex - 1 : rowIndex + 1;
    rows.splice(newIndex, 0, rows.splice(rowIndex, 1)[0]);

    currentDefinition.definition = rows;
    this.setState({definitions, unsavedChanges: true});
  }

  onDeleteRow(rowIndex){
    const {definitions, currentDefinition} = this.getCurrentDefinition(true);
    const rows = currentDefinition.definition.map((def) => def);
    rows.splice(rowIndex, 1);
    currentDefinition.definition = rows;
    this.setState({definitions, unsavedChanges: true});
  }

  // Formatting

  getFormatsForLabelRow(rowIndex){

    const props = this.state;
    props.rowIndex = rowIndex;

    const currentDefinition = this.state.definitions[this.state.currentDefinitionIndex] !== undefined
      ? this.state.definitions[this.state.currentDefinitionIndex]
      : false;

    const getFormatValueText = (key) => {

      if(props.formatValues[key] === undefined) return currentDefinition.definition[props.rowIndex].outputFormat[key];
      if(props.formatValues[key].values === undefined) return currentDefinition.definition[props.rowIndex].outputFormat[key];
      if(!Array.isArray(props.formatValues[key].values)) return currentDefinition.definition[props.rowIndex].outputFormat[key];

      if(currentDefinition.definition[props.rowIndex].outputFormat === undefined) return key;
      const value = props.formatValues[key].values.find((value) => {
        return (value.value === currentDefinition.definition[props.rowIndex].outputFormat[key]);
      });

      if(value) return value.text;
      return currentDefinition.definition[props.rowIndex].outputFormat[key];

    };

    const getFormatLabel = (key) => {
      if(props.formatSettings[key] !== undefined) return props.formatSettings[key];
      const labelAndValue = Object.keys(props.formatValues).reduce((acc, fkey) => {
        if(Object.keys(acc).length !== 0) return acc;
        if(Array.isArray(props.formatValues[fkey].values)){
          const option = props.formatValues[fkey].values.find((option) => option.value === key);
          if(option){
            acc.text = getFormatLabel(fkey);
            acc.value = getFormatValueText(fkey, key);
            acc.type = fkey;
            acc.rawValue = key;
          }
        }
        return acc;
      }, {});
      if(Object.keys(labelAndValue).length !== 0) return labelAndValue;
      return key;
    };

    const getRawFormatValue = (key) => {
      return currentDefinition.definition[props.rowIndex].outputFormat[key];
    };

    const outputFormat = currentDefinition.definition.length === 0
      ? []
      : currentDefinition.definition[props.rowIndex].outputFormat !== undefined
        ? Object.keys(currentDefinition.definition[props.rowIndex].outputFormat).reduce((acc, key) => {
          const formatLabel = (props.formatSettings[key] !== undefined) ? props.formatSettings[key] : key;
          const formatValue = getFormatValueText(key);
          acc.push({text: formatLabel, value: formatValue, type: key, rawValue: getRawFormatValue(key)});
          return acc;
        }, [])
        : [];

    const convertFormat = (format) => {
      const fields = format.split(',');
      return fields.reduce((acc, field) => {
        const result = getFormatLabel(field);
        if(Object.keys(result).length > 0){
          acc.push(result);
        } else {
          acc.push({text: getFormatLabel(field), value: 1});
        }
        return acc;
      }, []);
    };

    const format = !currentDefinition
      ? []
      : currentDefinition.definition[props.rowIndex].format !== undefined
        ? convertFormat(currentDefinition.definition[props.rowIndex].format)
        : [];

    return outputFormat.concat(format);

  }

  // Modal Methods and Component Methods for them

  showModal(type, rowIndex){

    let modalData = {};
    let fieldAndValue = '';
    const currentDefinition = this.getCurrentDefinition(false);
    if(rowIndex !== undefined && currentDefinition){
      fieldAndValue = `${(rowIndex + 1)}: ${currentDefinition.definition[rowIndex].field}:${currentDefinition.definition[rowIndex].value}`;
    }
    const components = {
      named: {
        component: NewLabelDefinition,
        title: 'Define A New Label Based On Another',
        noTranslation: '',
        activeComponent: 'newNamed'
      },
      properties: {
        component: PropertyEditor,
        title: `Edit Label Properties For "${currentDefinition.name}"`,
        noTranslation: '',
        activeComponent: 'properties'
      },
      formats: {
        component: RowFormatEditor,
        title: `Edit Row Format In "${currentDefinition.name}" Label | Row `,
        noTranslation: fieldAndValue,
        activeComponent: 'formats'
      },
      addrow: {
        component: AddLabelRow,
        title: `Add A Row To "${currentDefinition.name}" Label`,
        noTranslation: '',
        activeComponent: 'addrow'
      },
      previewLabel: {
        component: PreviewLabel,
        title: `Previewing Label: ${currentDefinition.name}`,
        noTranslation: '',
        activeComponent: 'previewLabel'
      },
      dataKeys: {
        component: DataKeysList,
        title: 'Data Keys',
        noTranslation: '',
        activeComponent: 'dataKeys'
      },
      showPrinter: {
        activeComponent: 'showPrinter'
      }
    };

    if(type === 'showPrinter'){
      setTimeout(() => {
        this.onShowPrinter();
      },10);
      return true;
    }

    if(type === 'properties'){
      modalData = Object.assign({}, {rotateLabel: 0}, this.state.definitions[this.state.currentDefinitionIndex]);
    }
    if(type === 'formats'){
      modalData = this.getFormatsForLabelRow(rowIndex).reduce((acc, format) => {
        acc[format.type] = format.rawValue;
        return acc;
      }, {});
    }
    if(type === 'addrow'){
      modalData = {field: '', value: ''};
    }

    this.setState({
      showModal: true,
      component: components[type].component,
      modalTitle: components[type].title,
      noTranslation: components[type].noTranslation,
      activeModalComponent: components[type].activeComponent,
      modalData: modalData,
      currentRowIndex: rowIndex
    });
  }

  hideModal(){
    this.setState({showModal: false, confirmDelete: false});
  }

  addNamedDefinition(){
    const newDefinition = Object.assign({}, this.state.definitions.filter((def, index) => index === this.state.modalData.based_on).pop());
    newDefinition.name = this.state.modalData.name;
    this.setState({definitions: this.state.definitions.concat(newDefinition), currentDefinitionIndex: this.state.definitions.length}, () => {
      this.saveCurrentDefinition(true);
    });
  }

  updateLabelProperties(){
    const definitions = this.state.definitions.map(def => def);
    const currentDefinition = definitions[this.state.currentDefinitionIndex];
    for(const prop in this.state.modalData){
      currentDefinition[prop] = this.state.modalData[prop];
    }
    this.setState({definitions}, () => {
      this.saveCurrentDefinition(false);
    });
  }

  addRowToCurrentDefinition(){
    const definitions = this.state.definitions.map(def => def);
    const currentDefinition = definitions[this.state.currentDefinitionIndex];
    currentDefinition.definition.push(this.state.modalData);
    this.setState({definitions, reload: true}, () => {
      this.saveCurrentDefinition(false);
    });
  }

  updateFormats(){
    const outputFormats = {};
    const formats = [];
    for(const prop in this.state.modalData){
      // Width is being mutated to size here to safely align QR codes without collateral damage.  If we just switch to
      // width on the BE then we may suddenly resize lots of QR codes.
      const type = prop !== 'size' ? this.state.formatValues[prop].type : this.state.formatValues.width.type;
      if(type === 'output'){
        outputFormats[prop] = this.state.modalData[prop];
      } else {
        formats.push(this.state.modalData[prop]);
      }
    }
    const definitions = this.state.definitions.map(def => def);
    const currentDefinition = definitions[this.state.currentDefinitionIndex];
    currentDefinition.definition[this.state.currentRowIndex].outputFormat = outputFormats;
    if(formats.length === 0){
      delete(currentDefinition.definition[this.state.currentRowIndex].format);
    } else {
      currentDefinition.definition[this.state.currentRowIndex].format = formats.join(',');
    }
    this.setState({definitions}, () => {
      this.saveCurrentDefinition(false);
    });
  }

  onSaveModalData(){
    if(this.state.activeModalComponent === 'newNamed'){
      this.addNamedDefinition();
    }
    if(this.state.activeModalComponent === 'properties'){
      this.updateLabelProperties();
    }
    if(this.state.activeModalComponent === 'formats'){
      this.updateFormats();
    }
    if(this.state.activeModalComponent === 'addrow'){
      this.addRowToCurrentDefinition();
    }
    this.setState({showModal: false});
  }

  onChangeModalData(field, value){

    const isEmpty = (value) => {
      if(value === undefined) return true;
      if(typeof value === 'string'){
        if(value.trim() === '') return true;
      }
      return false;
    };

    const margins = ['top', 'right', 'bottom', 'left'];
    const data = Object.assign({}, this.state.modalData);
    if(margins.indexOf(field) !== -1 && data.margins !== undefined){
      data.margins[field] = value;
    } else {
      data[field] = value;
    }
    if(isEmpty(value) && (this.state.activeModalComponent === 'properties' || this.state.activeModalComponent === 'addrow')) data[field] = '';
    if(isEmpty(value) && this.state.activeModalComponent === 'formats') delete(data[field]);

    this.setState({modalData: data});
  }

  // Basic Actions

  onSelectNamedDefinition(index){
    if(this.state.reload === true){
      this.setState({currentDefinitionIndex: index, reload: false}, () => {
        this.getDefinitions();
      });
    }else{
      this.setState({currentDefinitionIndex: index});
    }
  }

  getLabelData(id, next, backgroundLoad = false) {

    if(!backgroundLoad) this.showLoader(`Loading Label Data For: ${this.state.lookupCode}`);
    this.props.actions.unsetItem(itemNames.sourceLabel);
    const url = this.getLabelUrl(id);
    this.props.actions.getItem(url, itemNames.sourceLabel, {failed: I18n.t('common.errorPlantLabelLoad')}, {}, (response) => {
      if (response.emptyMessage) {
        this.showLoader(`Invalid Job Name” "${this.state.lookupCode}"`, false, true);
        return;
      }
      this.setState({showLoader: false, reloadLabel: false});
      if(next) this.showModal(next);
    }).catch(this.hideLoader);
  }

  getLabelUrl(id){
    const currentDefinition = this.getCurrentDefinition();
    const tag = currentDefinition.name.toLowerCase() === 'default'
      ? this.state.keyToTagMap[this.props.tag]
      : `${this.state.keyToTagMap[this.props.tag]}_${currentDefinition.id}`;
    const url = `/api/labels/generate/${tag}/for/${id}?mockOrder=1`;
    this.setState({labelUrl: url});
    return url;
  }

  getPackageLabelData(next){
    this.showLoader('Getting Package Data...', true, false);
    this.props.actions.getItem('/api/packages/by_code', null, {}, {package_codes: [this.state.lookupCode.trim()]}, (data) => {
      if(data.length > 0){
        const ids = data.map((d) => d.id);
        this.setState({showLoader: true, message: 'Getting Inventory Item Data'});
        this.props.actions.postData('/api/items/by_package_ids', {ids}, dataNames.itemMasters, {}, {}, (data) => {
          if(data.length > 0) {
            this.getLabelUrl(data[0].id);
            this.getLabelData(data[0].id, next);
            return true;
          }
          this.showLoader(`No Items Found For Package Code: ${this.state.lookupCode}`, false, true);
        });
        return true;
      }
      this.showLoader(`Package Code "${this.state.lookupCode}" not found!`, false, true);
    });
  }

  getHarvestLabelData(next){
    this.props.actions.getItem('/api/harvests', null, {}, {batch_name: this.state.lookupCode.trim()}, (data) => {
      if(!data.length) return this.showLoader(`Harvest Code "${this.state.lookupCode}" not found!`, false, true);
      this.getLabelUrl(data[0].id);
      this.getLabelData(data[0].id, next);
      return true;
    });
  }

  getPlantLabelData(next){
    if(this.state.lookupCode.trim() === '') {
      this.showLoader('Plant ID is required.', false, true);
      return false;
    }
    this.getLabelUrl(this.state.lookupCode.trim());
    this.getLabelData(this.state.lookupCode.trim(), next);
  }

  getProductionLabelData(next){
    this.showLoader('Getting Production Run Data...', true, false);
    if (this.state.lookupCode.trim() === '') {
      this.showLoader(I18n.t('common.isRequired'), false, true);
      return false;
    }
    this.getLabelUrl(this.state.lookupCode.trim());
    this.getLabelData(this.state.lookupCode.trim(), next);
  }

  onLookupCode(next){
    switch(true){
      case this.isProductionLabel(): //eslint-disable-line
        this.getProductionLabelData(next);  //eslint-disable-line
        break;  //eslint-disable-line
      case this.isPatientLabel(): //eslint-disable-line indent
      case this.isPackageLabel(): //eslint-disable-line indent
        this.getPackageLabelData(next);  //eslint-disable-line indent
        break;  //eslint-disable-line indent
      case this.isHarvestLabel(): //eslint-disable-line
        this.getHarvestLabelData(next);  //eslint-disable-line
        break;  //eslint-disable-line
      default:  //eslint-disable-line indent
        this.getPlantLabelData(next); //eslint-disable-line indent
        break;  //eslint-disable-line indent
    }


  }

  isPackageLabel(){
    return this.props.tag.indexOf('package') !== -1;
  }

  isPatientLabel(){
    return this.props.tag.indexOf('patient') !== -1;
  }

  isPlantLabel(){
    return this.props.tag.indexOf('plant') !== -1;
  }

  isHarvestLabel(){
    return this.props.tag.indexOf('harvest') !== -1;
  }

  isProductionLabel() {
    return this.props.tag.indexOf('production') !== -1;
  }

  onCodeChange(code){
    this.setState({lookupCode: code});
  }

  hideLoader(){
    this.setState({showLoader: false});
  }

  showLoader(message, showLoadingIcon = true, showOk = false, moreKeys = {}){
    const loaderState = {showLoader: true, message: message, showLoadingIcon, showOk};
    const newState = Object.assign({}, loaderState, moreKeys);
    this.setState(newState);
  }

  onDeleteLabel(){
    this.setState({confirmDelete: true});
  }

  onConfirmDeleteLabel(){
    const payload = this.getCurrentDefinition();
    this.props.actions.deleteItem(`/api/labels/custom_labels/${payload.id}`, null, {}, {}, () => {
      this.getDefinitions();
      this.hideModal();
    });
  }

  saveCurrentDefinition(){
    this.setState({reloadLabel: true});
    const payload = Object.assign({}, this.getCurrentDefinition());
    if(this.state.modalData.based_on){
      delete(payload.id);
    }
    // Update width to size if outputFormat includes barcode: qrcode - BE issue solved by this
    payload.definition = payload.definition.map((configLine) => {
      const barCodeIsQrCode = get(configLine, 'outputFormat.barcode', false);
      if(barCodeIsQrCode){
        configLine.outputFormat.size = get(configLine, 'outputFormat.width', 0.25);
      }
      return configLine;
    });
    if(payload.id === undefined) {
      this.props.actions.postItem('/api/labels/custom_labels', payload, null, {}, {}, (data) => {
        const definitions = this.state.definitions.map(def => def);
        const currentDefinition = definitions[this.state.currentDefinitionIndex];
        currentDefinition['id'] = data['id'];
        this.setState({definitions, unsavedChanges: false, modalData: {}});
      }).then(this.hideLoader).catch(this.hideLoader);
    } else {
      this.props.actions.putItem(`/api/labels/custom_labels/${payload.id}`, payload, null, {}, {}, () => {
        this.setState({unsavedChanges: false});
      });
    }
    return true;
  }

  redirect() {
    this.props.actions.goBack();
  }

  onHidePrinter(){
    this.setState({showPrinter: false});
  }

  onShowPrinter(){
    this.setState({showPrinter: true});
  }

  getScanHint() {
    switch (true){
    case this.isPlantLabel():
      return 'Please enter Plant ID to load the label.';
    case this.isProductionLabel(): //eslint-disable-line
      return 'Please enter Name Processing/Infusion job';
    default:
      return 'Package Code';
    }
  }

  render() {
    const currentDefinition = (this.state.definitions[this.state.currentDefinitionIndex] !== undefined)
      ? this.state.definitions[this.state.currentDefinitionIndex]
      : {definition: []};

    const isDefault = this.state.definitions.find((def, index) => def.name === 'Default' && index === this.state.currentDefinitionIndex);

    return (
      <React.Fragment>
        <ModalWrapper
          title={this.state.modalTitle}
          noTranslationTitle={this.state.noTranslation}
          onHide={() => this.hideModal()}
          showModal={this.state.showModal}
          Component={this.state.component}
          definitions={this.state.definitions}
          modalData={this.state.modalData}
          onChangeModalData={this.onChangeModalData}
          currentDefinitionIndex={this.state.currentDefinitionIndex}
          onSaveModalData={this.onSaveModalData}
          formatSettings={this.state.formatSettings}
          formatValues={this.state.formatValues}
          confirmDelete={this.state.confirmDelete}
          onDeleteLabel={this.onDeleteLabel}
          onConfirmDeleteLabel={this.onConfirmDeleteLabel}
          labelData={this.props.labelData}
          activeSource={true}
          internationalNumberFormat={this.props.internationalNumberFormat}
          label={this.props.labelData}
        />
        <InProgressOverlay
          onDismiss={this.hideLoader}
          isActive={this.state.showLoader}
          message={this.state.message}
          translate={false}
          showOk={this.state.showOk}
          showLoader={this.state.showLoadingIcon}
        />
        <PrinterModal hidePrinter={this.onHidePrinter} showPrinter={this.state.showPrinter} labelUrl={this.state.labelUrl} httpAction='GET' />
        <Row>
          <Col md={3}>
            <NamedDefinitions
              onSelectNamedDefinition={this.onSelectNamedDefinition}
              onAdd={this.showModal}
              definitions={this.state.definitions}
              currentDefinitionIndex={this.state.currentDefinitionIndex}
            />
            <LabelProperties onEdit={this.showModal} definitions={this.state.definitions} currentDefinitionIndex={this.state.currentDefinitionIndex} />
          </Col>
          <Col md={9}>
            <Card>
              <Card.Header>
                <div className='float-left' style={{position:'relative', top: '9px'}}>Label Editor</div>
                  {isDefault
                    ? null
                    : <Button
                        size='sm'
                        style={{position: 'relative', borderRadius: '2px !important'}} className='btn btn-primary btn-sm float-right'
                        onClick={(event) => {
                          event.target.blur();
                          this.showModal('addrow');
                        }}>
                      Add Row
                    </Button>
                  }
              </Card.Header>
              <Card.Body>
                  {!this.state.unsavedChanges
                    ? null
                    : <div className='alert alert-danger' style={{padding: '12px'}}>
                        You have unsaved sorting changes. Changing formatting or properties will automatically save these changes. Or click save at the bottom of this page.
                      </div>
                  }

                  <InputGroup style={{width:'600px', marginBottom: '12px'}}>
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <FaBarcode/>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl type='text'
                      name='scanField'
                      placeholder={this.getScanHint()}
                      onChange={(event) => {
                        this.onCodeChange(event.target.value);
                      }}
                      value={this.state.lookupCode}
                    />
                    <InputGroup.Append>
                      <Button
                        size={'sm'}
                        onClick={(event) => {
                          this.onLookupCode('dataKeys');
                          event.target.blur();
                        }}
                        className='btn'>View Data Keys</Button>
                    </InputGroup.Append>
                    <InputGroup.Append>
                      <Button
                        size={'sm'}
                        onClick={(event) => {
                          //this.onLookupCode('previewLabel');
                          //this.onShowPrinter();
                          this.onLookupCode('showPrinter');
                          event.target.blur();
                        }}
                        className='btn'>View / Print Label</Button>
                    </InputGroup.Append>
                  </InputGroup>

                  <table className='table table-striped table-bordered'>
                    <thead>
                      <tr>
                        <th style={{width: '80px'}}>Move</th>
                        <th>Field Label</th>
                        <th>Data Key</th>
                        <th>Format</th>
                        <th style={{width: '40px'}} />
                      </tr>
                    </thead>
                    <tbody>
                    {currentDefinition.definition.map((def, index) => {
                      if (Array.isArray(def)){
                        return def.map((d, index) => {
                          return (
                            <tr key={index + 1000}>
                              <td>&nbsp;</td>
                              <td>
                                <div>
                                  {d.field}
                                </div>
                              </td>
                              <td>{d.value}</td>
                              <td>
                                <RowFormatters
                                  onEdit={this.showModal}
                                  formatSettings={this.state.formatSettings}
                                  formatValues={this.state.formatValues}
                                  definitions={this.state.definitions}
                                  currentDefinitionIndex={this.state.currentDefinitionIndex}
                                  rowIndex={index} />
                              </td>
                            </tr>
                          );
                        });
                      }
                      return (
                        <tr key={index}>
                          <td>
                            {
                              index === 0 || isDefault
                                ? null
                                : (
                                  <a className='float-left' onClick={(event) => {
                                    event.stopPropagation();
                                    event.preventDefault();
                                    this.onMoveRow('up', index);
                                    event.target.blur();
                                  }}><FaArrowAltCircleUp style={{fontSize: '16px'}}/></a>
                                )
                            }
                            {index === currentDefinition.definition.length - 1 || isDefault
                              ? null
                              : <a className='float-right'
                                   onClick={(event) => {
                                     event.stopPropagation();
                                     event.preventDefault();
                                     this.onMoveRow('down', index);
                                     event.target.blur();
                                   }}>
                                <FaArrowAltCircleDown style={{fontSize: '16px'}}/>
                              </a>
                            }
                          </td>
                          <td>
                            <div>
                              {def.field}
                            </div>
                          </td>
                          <td>{def.value}</td>
                          <td>
                            <RowFormatters
                              onEdit={this.showModal}
                              formatSettings={this.state.formatSettings}
                              formatValues={this.state.formatValues}
                              definitions={this.state.definitions}
                              currentDefinitionIndex={this.state.currentDefinitionIndex}
                              rowIndex={index} />
                          </td>
                          <td>
                            {isDefault
                              ? null
                              : <FaTrash className='remove-button'
                                style={{fontSize: '16px', color: 'red', cursor: 'pointer'}}
                                onClick={() => this.onDeleteRow(index)}
                                title={'Remove'} />
                            }
                          </td>
                        </tr>
                      );
                    })}
                    </tbody>
                  </table>

                  {!this.state.unsavedChanges
                    ? null
                    : <Button onClick={() => {this.saveCurrentDefinition();}} className='btn btn-primary'>Save Sorting</Button>
                  }
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

LabelEditor.defaultProps = {
  labels: [],
  initialValues: {},
  complianceSettings: {},
};

LabelEditor.propTypes = {
  initialValues: PropTypes.object,
  complianceSettings: PropTypes.object,
  labels: PropTypes.array,
  actions: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    setData: PropTypes.func.isRequired,
    putItem: PropTypes.func.isRequired,
    deleteItem: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired,
  }),
  tag: PropTypes.string.isRequired,
  label: PropTypes.object,
  labelData: PropTypes.object.isRequired,
  internationalNumberFormat: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    labelData: state.sourceLabel,
    internationalNumberFormat: getInternationalNumberFormat(state),
  };
}

function mapDispatchToProps (dispatch) {
  const actions = {goBack, getItem, postItem, setData, putItem, deleteItem, postData, unsetItem};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LabelEditor);
