import {createSelector} from 'reselect';
import * as itemNames from '../../constants/itemNames';
import * as dataNames from '../../constants/dataNames';
import {getStringSimilarityRating} from '../../util/dataHelpers';

const getSelectedOrganization = (state) => state[itemNames.organization] ? state[itemNames.organization] : false;
const getPartnerOrganizations = (state) => state[dataNames.organizations] ? state[dataNames.organizations] : [];
const getPartnerFacilities = (state) => state[dataNames.partnerFacilities] ? state[dataNames.partnerFacilities] : [];
const getConnectsRequest = (state) => state[itemNames.connectsRequest] ? state[itemNames.connectsRequest] : false;
const getConnectsTargetOrganization = (state) => state[itemNames.connectsTargetOrganization] ? state[itemNames.connectsTargetOrganization] : false;

/***
 * Ensure what's in redux belongs to this request/target combination
 * @returns {boolean}
 */
export const getIsRequestResponse = createSelector(
  [getSelectedOrganization, getConnectsRequest, getConnectsTargetOrganization], (selectedOrg, connectsRequest, targetOrganization) => {
    if(!connectsRequest.target_organization_code || !targetOrganization.organization_code) return false;
    return connectsRequest.target_organization_code === targetOrganization.organization_code
      && connectsRequest.requesting_organization_code === selectedOrg.organization_code;
  });

export const getInitialValues = createSelector(
  [getSelectedOrganization, getPartnerOrganizations, getPartnerFacilities, getConnectsRequest, getConnectsTargetOrganization, getIsRequestResponse],
  (selectedOrg, partnerOrgs, partnerFacilities, connectsRequest, targetOrganization, isRequestResponse) => {

    const partnerOrgsRanked = partnerOrgs.map((partner) => {
      return Object.assign({}, partner, {rating: !selectedOrg || !selectedOrg.name ? 0 : getStringSimilarityRating(selectedOrg.name, partner.name)});
    }).sort((a,b) => b.rating - a.rating);

    const getMostSimilarFacility = (facilityName) => {
      return partnerFacilities.reduce((acc, facility) => {
        const rating = getStringSimilarityRating(facilityName, facility.facility_name);
        if(rating > acc.rating) {
          acc = Object.assign({}, facility, {rating});
        }
        return acc;
      }, {rating: 0});
    };

    const mostSimilarByNameIds = [];
    const selectedOrgFacilities = selectedOrg && selectedOrg.facilities
      ? selectedOrg.facilities.map((facility) => {
        const mostSimilarByName = getMostSimilarFacility(facility.name);
        if(mostSimilarByName && mostSimilarByNameIds.indexOf(mostSimilarByName.id) === -1){
          mostSimilarByNameIds.push(mostSimilarByName.id);
          return Object.assign({}, facility, {existing_facility_id: mostSimilarByName ? mostSimilarByName.id : 0});
        }
        return facility;
      })
      : [];

    const getRequestFieldValue = (field, defaultValue) => {
      if(!isRequestResponse) return defaultValue;
      return connectsRequest[field] !== undefined ? connectsRequest[field] : defaultValue;
    };

    const partnerType = getRequestFieldValue('partner_type', false);

    const baseValues = {
      existing_partner_id: (partnerOrgsRanked.length && partnerOrgsRanked[0].rating > .50) ? partnerOrgsRanked[0].id : 0,
      partner_type: partnerType ? partnerType : ['purchase_from', 'sell_to', 'is_lab'].filter((key) => {
        return partnerOrgsRanked.length && partnerOrgsRanked[0][key];
      }),
      facilities: selectedOrgFacilities,
      requested_marketing_email: getRequestFieldValue('requested_marketing_email', null),
      requested_place_orders: getRequestFieldValue('requested_place_orders', false),
      requested_view_inventory: getRequestFieldValue('requested_view_inventory', false),
      requested_inherit_new_products: getRequestFieldValue('requested_inherit_new_products', false),
      requested_inherit_product_images: getRequestFieldValue('requested_inherit_product_images', false),
      requested_inherit_product_pricing: getRequestFieldValue('requested_inherit_product_pricing', false),
      requested_receive_communication: getRequestFieldValue('requested_receive_communication', false),
      payment_terms: getRequestFieldValue('payment_terms', null),
    };

    return isRequestResponse
      ? Object.assign({}, baseValues, {
        allowed_place_orders: getRequestFieldValue('requested_place_orders', false) ? 1 : 0,
        allowed_view_inventory: getRequestFieldValue('requested_view_inventory', false) ? 1 : 0,
        allowed_inherit_new_products: getRequestFieldValue('requested_inherit_new_products', false) ? 1 : 0,
        allowed_inherit_product_images: getRequestFieldValue('requested_inherit_product_images', false) ? 1 : 0,
        allowed_inherit_product_pricing: getRequestFieldValue('requested_inherit_product_pricing', false) ? 1 : 0,
        allowed_receive_communication: getRequestFieldValue('requested_receive_communication', false) ? 1 : 0,
      })
      : baseValues;

  });
