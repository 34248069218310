import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {I18n} from 'react-redux-i18n';
import {goBack} from 'react-router-redux';

export class PremiumContent extends React.PureComponent {

  render() {
    const {goBack} = this.props.actions;
    return (
      <div>
        <h1>{I18n.t('common.premiumContent.title')}</h1>
        <a onClick={goBack}>{I18n.t('common.premiumContent.text')}</a>
      </div>
    );
  }
}

PremiumContent.propTypes = {
  actions: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired
};

function mapStateToProps(state){
  return {
    state
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {goBack};
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PremiumContent);
