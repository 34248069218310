/* eslint-disable import/prefer-default-export*/
import {push} from 'react-router-redux';

import {addMessage} from '../actions/systemActions';
import * as actionTypes from '../constants/actionTypes';

export const loginFailure = store => next => action => {
  const result = next(action);
  if(action.type === actionTypes.LOGIN_FAILED) {
    // If login fails due to expired password, let the user know and send them to the change password page.
    if (action.errors && action.errors.errors && action.errors.errors.USER_LOGIN_EXPIRED) {
      store.dispatch(push('/change-password'));
      store.dispatch(addMessage('error', 'user.password.passwordHasExpired'));
    }
  }
  return result;
};
