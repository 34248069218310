import {createSelector} from 'reselect';
import * as dataNames from '../constants/dataNames';
import * as itemNames from '../constants/itemNames';
import {getTimezone} from './timezoneSelectors';
import {
  convertDbDateTimeToFormInputDateTimeWithSeconds,
} from '../util/dateHelpers';

const getTransactions = state => state[dataNames.transactions];
const getTransactionDetails = state => state[itemNames.transaction];

function formatTransaction(transaction, timezone){
  let response_body = transaction && transaction.response;
  let request_body = transaction && transaction.request_parameters;
  if (response_body) {
    try {
      response_body = JSON.parse(response_body);
    }
    catch (error) {} // eslint-disable-line no-empty
  }
  if (request_body) {
    try {
      request_body = JSON.parse(request_body);
    }
    catch (error) {} // eslint-disable-line no-empty
  }
  return {
    id: transaction && transaction.id,
    code: transaction && transaction.response_code,
    success: transaction.success,
    date: transaction && convertDbDateTimeToFormInputDateTimeWithSeconds(transaction.created_at, timezone),
    api_call : transaction && transaction.request_type && transaction.end_point && `${transaction.end_point} - ${transaction.request_type}`,
    response: transaction && transaction.response_code && transaction.success && transaction.success === 1 ? 'Successful' : `Failed - ${transaction.response_code}`,
    request: transaction && transaction.request,
    response_body,
    request_body
  };
}


export const getTransactionsForDisplay = createSelector(
  [getTransactions, getTimezone],
  (transactions, timezone) => (transactions || []).map(transaction => formatTransaction(transaction, timezone))
);

export const getTransactionDetailsForDisplay = createSelector(
  [getTransactionDetails, getTimezone],
  formatTransaction
);
