import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Row, Col} from 'react-bootstrap';
import {Field, FieldArray} from 'redux-form';
import SubmitSection from '../../../common/form/SubmitSection';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import MultiSelectInput from '../../../common/form/MultiselectInput';
import TextInput from '../../../common/form/TextInput';
import ConnectsPartnerData from '../../components/PartnerDataSummary/ConnectsPartnerData';

const ConnectsRequestForm  = (props) => {

  const {submitting} = props;

  const settings = {
    actionSettings: {
      submit: {
        submitting,
        text: I18n.t('common.form.save')
      }
    }
  };

  const partnerTypeOptions = [
    {text: 'Purchase From', value: 'purchase_from'},
    {text: 'Sell To', value: 'sell_to'},
    {text: 'Lab', value: 'is_lab'},
  ];

  const paymentOptions = [
    {text: 'C.O.D.', value: 'cod'},
    {text: 'Net 7', value: 'net_7'},
    {text: 'Net 15', value: 'net_15'},
    {text: 'Net 30', value: 'net_30'}
  ];

  const onButtonClick = (field, status, e) => {
    e.currentTarget.blur();
    return props.change(`allowed_${field}`, status);
  };

  const getButtonStyle = (field, testValue, activeClass) => {
    return (props.allowedValues[`allowed_${field}`] === testValue)
      ? `btn btn-sm btn-${activeClass}`
      : 'btn btn-sm';
  };

  const getRequestValue = (field) => {
    return props.requestedValues[`requested_${field}`];
  };

  const getButtons = (field, includeLabelSpacing = false) => { //eslint-disable-line
    return (<Col style={{paddingBottom: '2px'}} xs={12} sm={2} md={2} className={props.isRequestResponse ? '' : 'hide'}>
      {includeLabelSpacing ? <label style={{width: '100%'}}>&nbsp;</label> : null}
      {
        getRequestValue(field)
          ? (<React.Fragment>
            <button type='button' className={getButtonStyle(field, 1, 'success')} onClick={(e) => onButtonClick(field, 1, e)}>Approve</button>
            <button type='button' className={getButtonStyle(field, 0, 'danger')} onClick={(e) => onButtonClick(field, 0, e)}>Decline</button>
            </React.Fragment>)
          : null
      }
    </Col>);
  };

  const prefix = 'requested_';

  return(
    <form onSubmit={props.handleSubmit} noValidate={true}>
      <div>
        <div>
          <b>Connects Request {props.isRequestResponse ? 'From' : 'To'} Organization: {props.organization.name}</b>
        </div>
        <Row>
          <Col xs={12} sm={{span: 6, offset: 3}} md={{span: 4, offset: 4}}>
            <Field
              name='existing_partner_id'
              component={ReactSelectInput}
              props={{
                label: 'Select Existing Partner or Create A New One',
                options: [].concat([{id: 0, name: 'Create A New Partner'}], props.organizations.sort((a,b) => a.name.localeCompare(b.name))),
                textKey: 'name',
                valueKey: 'id'
              }}
            />
            <div style={{clear: 'both'}} />
            <div className='text-muted' style={{fontSize: 'smaller'}}>
              We have searched your existing partners and if a match was found we have pre-selected it for you.  If the selected option reads 'Create A New Partner' we were unable to find a close enough match to feel that our guess was valid.  If you have done
              business with the organization you are linking with, please select them from the list.
            </div>
          </Col>
        </Row>

      </div>
      <div>
        <FieldArray
          component={ConnectsPartnerData}
          name='facilities'
          props={{
            partnerId: props.partnerId,
            facilities: props.facilities,
            partnerFacilities: props.partnerFacilities,
            organizations: props.organizations,
            mappedPartnerFacilityIds: props.mappedPartnerFacilityIds,
          }}
        />
      </div>
      <div>
      </div>
      <hr style={{border:'4px double #cecece'}} />
      <Row>
        <Col xs={12} sm={4} smOffset={3} md={4} offset={4}>
          <Field
            name='partner_type'
            component={MultiSelectInput}
            props={{
              label: 'Partner Type',
              placeholder: 'Select A Partner Type',
              options: partnerTypeOptions,
              disabled: props.partnerId !== 0,
            }}
          />
          <div style={{clear: 'both'}} />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={{span: 6, offset: 3}} md={{span: 4, offset: 4}}>
          <Field
            name='payment_terms'
            component={ReactSelectInput}
            props={{
              label: 'Payment Terms',
              placeholder: 'Select A Payment Term Preference',
              options: paymentOptions,
            }}
          />
          <div style={{clear: 'both'}} />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={{span: 6, offset: 3}} md={{span: 4, offset: 4}}style={{fontSize: 'smaller', fontWeight: props.atLeastOneOptionChecked ? 'normal' : 'bold', marginBottom: '12px'}} className={props.atLeastOneOptionChecked ? 'text-muted' : 'text-danger'}>
          At least one of the following options must be checked.
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={{span: 6, offset: 3}} md={{span: 4, offset: 4}}>
          <label style={{fontWeight: 'normal'}}>
            <Field
              name={`${prefix}place_orders`}
              component='input'
              style={{marginRight: '8px', marginTop: '5px'}}
              type='checkbox'
              disabled={props.isRequestResponse}
            />
            {I18n.t(`${prefix}place_orders`)}
          </label>
          <div style={{clear: 'both'}} />
        </Col>
        {getButtons('place_orders', false)}
      </Row>
      <Row>
        <Col xs={12} sm={{span: 6, offset: 3}} md={{span: 4, offset: 4}}>
          <label style={{fontWeight: 'normal'}}>
            <Field
              name={`${prefix}view_inventory`}
              component='input'
              style={{marginRight: '8px', marginTop: '5px'}}
              type='checkbox'
              disabled={props.isRequestResponse}
            />
            {I18n.t(`${prefix}view_inventory`)}
          </label>
          <div style={{clear: 'both'}} />
        </Col>
        {getButtons('view_inventory', false)}
      </Row>
      <Row>
        <Col xs={12} sm={6} smOffset={3} md={4} offset={4}>
          <label style={{fontWeight: 'normal'}}>
            <Field
              name={`${prefix}inherit_product_images`}
              component='input'
              style={{marginRight: '8px', marginTop: '5px'}}
              type='checkbox'
              disabled={props.isRequestResponse}
            />
            {I18n.t(`${prefix}inherit_product_images`)}
          </label>
          <div style={{clear: 'both'}} />
        </Col>
        {getButtons('inherit_product_images', false)}
      </Row>
      <Row>
        <Col xs={12} sm={6} smOffset={3} md={4} offset={4}>
          <label style={{fontWeight: 'normal'}}>
            <Field
              name={`${prefix}inherit_product_pricing`}
              component='input'
              style={{marginRight: '8px', marginTop: '5px', color: 'green'}}
              type='checkbox'
              disabled={props.isRequestResponse}
            />
            {I18n.t(`${prefix}inherit_product_pricing`)}
          </label>
          <div style={{clear: 'both'}} />
        </Col>
        {getButtons('inherit_product_pricing', false)}
      </Row>
      <Row>
        <Col xs={12} sm={6} smOffset={3} md={4} offset={4}>
          <label style={{fontWeight: 'normal'}}>
            <Field
              name={`${prefix}receive_communication`}
              component='input'
              style={{marginRight: '8px', marginTop: '5px'}}
              type='checkbox'
              disabled={props.isRequestResponse}
            />
            {I18n.t(`${prefix}receive_communication`)}
          </label>
          <div style={{clear: 'both', height: '12px'}} />
        </Col>
        {getButtons('receive_communication', false)}
      </Row>
      <Row>
        <Col xs={12} sm={6} smOffset={3} md={4} offset={4}>
          <Field
            name={`${prefix}marketing_email`}
            component={TextInput}
            props={{
              required: props.emailIsRequired,
              label: I18n.t(`${prefix}marketing_email`),
              placeholder: I18n.t(`${prefix}marketing_email_hint`),
            }}
          />
          <div style={{clear: 'both'}} />

        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6} smOffset={3} md={4} offset={4}>
          <SubmitSection settings={settings}/>
        </Col>
      </Row>
    </form>
  );
};

ConnectsRequestForm.propTypes = {
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,

  organization: PropTypes.object.isRequired,
  organizations: PropTypes.array.isRequired,
  partnerId: PropTypes.number.isRequired,
  facilities: PropTypes.array.isRequired,
  partnerFacilities: PropTypes.array.isRequired,
  mappedPartnerFacilityIds: PropTypes.array.isRequired,
  isRequestResponse: PropTypes.bool.isRequired,
  requestedValues: PropTypes.array,
  atLeastOneOptionChecked: PropTypes.bool,
  emailIsRequired: PropTypes.bool,
};

export default ConnectsRequestForm;
