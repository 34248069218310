import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import * as applicationModes from '../../../../constants/applicationModes';
import * as itemNames from '../../../../constants/itemNames';
import * as apiActions from '../../../../actions/apiActions';
import {setItem} from '../../../../actions/itemActions';
import * as menuActions from '../../../../actions/menuActions';
import {getOrderWithCategoryWeights} from '../../../../selectors/orderSelectors';
import getRulesCategories from '../../../../selectors/compliance/sales/getRulesCategories';
import {getApplicationMode} from '../../../../selectors/applicationModesSelectors';
import PlatformEquivalencyDisplay from './components/PlatformEquivalencyDisplay';
import PlatformRulesDisplay from './components/PlatformRulesDisplay';
import PlatformMmuEquivalencyDisplay from './components/PlatformMmuEquivalencyDisplay';
import {isCureIntegrator} from '../../../../selectors/integration/cureApiSelectors';
import {getFacilitySalesSettingsForCustomer} from '../../../../selectors/customersSelectors';
import {isLeafPaConfigPackClosedLoopFacility} from '../../../../selectors/facilitiesSelectors';
import {isFeatureEnabled} from '../../../../selectors/featureToggles';

class OrderCategoryDisplay extends React.Component {

  constructor(props, context) {

    super(props, context);
    [
      'getConsumerId',
      'getConsumerLimits',
      'getHeaderStyle',
      'getIntegrationSettings',
      'onToggleSummary',
      'renderEquivalence',
      'renderRules',
      'showComponent',
    ].forEach( m => this[m] = this[m].bind(this) );

    this.state = {
      showRows: false,
      customerLimits: {}
    };
  }


  /**
   * Fetch categories and settings on mount
   */
  componentWillMount() {

    if(Object.keys(this.props.integrationSettings).length) {
      this.getIntegrationSettings();
    }
    this.getConsumerLimits();
    if(this.props.showRows || this.props.reduxShowRows){
      this.setState({showRows: true});
    }
  }

  shouldComponentUpdate() {
    if(this.props.selectedConsumerId !== this.props.consumerId && this.props.multiViews){
      return false;
    }
    return true;
  }

  /**
   * Find customer id in multiple possible locations.  If
   * not found returns 0 so that call to getconsumerlimits
   * for anonymous orders will proceed.
   * @return {int}
   */
  getConsumerId(){
    if(this.props.consumerId){
      return this.props.consumerId;
    }
  }

  /**
   * Update customerLimits from API
   */
  getConsumerLimits() {
    const consumerId = this.getConsumerId();
    const url = `/api/consumer_orders/${consumerId}/limits`;
    return this.props.actions.getItem(url, itemNames.customerLimits, null, null, (customerLimits) => this.setState({customerLimits}));
  }

  /**
   * Update integrationSettings from API
   */
  getIntegrationSettings() {
    return this.props.actions.getItem(
      '/api/integration-settings',
      itemNames.integrationSettings,
      {failed: 'stateIntegratorSettings.get.failed'}
    );
  }

  /**
   * Click handler for summary table
   */
  onToggleSummary(event = false){
    this.setState({showRows: !this.state.showRows});
    this.props.actions.toggleLimitsDetails();
    if(event) {
      event.preventDefault();
      event.stopPropagation();
      event.target.blur();
    }
  }

  /**
   * CSS for child display components
   * @return {object}
   */
  getHeaderStyle() {
    return {color: '#337ab7', cursor: 'pointer'};
  }

  /**
   * Display/hide determination for component
   * @return {boolean}
   */
  showComponent() {

    const {applicationMode, salesCompliance, isCure, isMmuEnabled} = this.props;

    //Do not show for LEAF PA (unless MMUs are enabled)
    if (!isMmuEnabled && applicationMode === applicationModes.leafPA) return false;

    //Do not show for CureAPI
    if (isCure) return false;

    // Do not show if disabled by setting:
    if(get(salesCompliance,'order_sales_limit_method.value','') === 'disabled'){
      return false;
    }

    //Do not show if we don't have compliance settings
    if(isEmpty(salesCompliance)) return false;

    return true;
  }



  /**
   * Render PlatformEquivalence limits component
   * @return {React.Element}
   */
  renderEquivalence() {
    return (
      <PlatformEquivalencyDisplay
        {...this.props}
        customerId={this.getConsumerId()}
        onToggleSummary={this.onToggleSummary}
        headerStyle={this.getHeaderStyle()}
        showRows={this.state.showRows}
        customerLimits={this.state.customerLimits}
        stats={this.state.customerLimits}
      />
    );
  }

  renderMmuEquivalence() {
    if (!this.props.isMmuEnabled) {
      return null;
    }

    const salesLimitsByConsumerType = get(this.props, 'salesCompliance.order_sales_limits_by_consumer_type.value');
    const consumerType = get(this.props, 'order.consumer_type');
    if (!salesLimitsByConsumerType || !consumerType) {
      return null;
    }

    const salesLimits = salesLimitsByConsumerType[consumerType];

    return (
      <PlatformMmuEquivalencyDisplay
        {...this.props}
        salesLimits={salesLimits}
        customerLimits={this.props.customerLimits}
      />
    );
  }

  /**
   * Render PlatformRules limits component
   * @return {React.Element}
   */
  renderRules() {

    if (this.state.customerLimits) {
      return (
        <PlatformRulesDisplay
          {...this.props}
          onToggleSummary={this.onToggleSummary}
          headerStyle={this.getHeaderStyle()}
          showRows={this.state.showRows}
          customerLimits={this.state.customerLimits}
          useLocalStateCustomerLimits={true}
        />
      );
    } else {
      return null;
    }
  }

  /**
   * Render primary component
   * @return {React.Element}
   */

  render() {

    if(!this.showComponent()) return null;
    const limitMethod = get(this.props,'salesCompliance.order_sales_limit_method.value','');
    return (limitMethod === 'equivalency')
      ? this.renderEquivalence()
      : (limitMethod === 'mmus')
        ? this.renderMmuEquivalence()
        : this.renderRules();
  }
}

OrderCategoryDisplay.propTypes = {
  orderWithCategoryWeights: PropTypes.object,
  categoriesData: PropTypes.array,
  rulesCategories: PropTypes.array,
  rules: PropTypes.object,
  integrationSettings: PropTypes.object,
  applicationMode: PropTypes.string,
  consumer: PropTypes.object,
  isCure: PropTypes.bool,
  orderComplianceError: PropTypes.object,
  selectedConsumerId: PropTypes.number,
  customerLimits: PropTypes.object,
  facility: PropTypes.object,
  salesCompliance: PropTypes.object,
  patientLimits: PropTypes.object,
  showRows: PropTypes.bool,
  reduxShowRows: PropTypes.bool,
  consumerId: PropTypes.number,
  actions: PropTypes.object,
  order: PropTypes.object,
  multiViews: PropTypes.bool,
  displayCategories: PropTypes.array,
  isMmuEnabled: PropTypes.bool.isRequired
};

function mapStateToProps(state, ownProps) {

  const rules = isEmpty(state.salesComplianceSettings)
    ? state.salesComplianceSettings.order_sales_limit_categories
    : {};

  return {
    orderWithCategoryWeights: getOrderWithCategoryWeights(state),
    integrationSettings: state[itemNames.integrationSettings],
    patientLimits: state[itemNames.metrcPatientLimits],
    salesCompliance: getFacilitySalesSettingsForCustomer(state),
    facility: state[itemNames.facility],
    customerLimits: state[itemNames.customerLimits],
    selectedConsumerId: state[itemNames.selectedConsumerId],
    order: state[itemNames.order],
    categoriesData: ownProps.displayCategories,
    rulesCategories: getRulesCategories(state),
    applicationMode: getApplicationMode(state),
    reduxShowRows: state.menus.limitsDetailsOpen,
    rules,
    isCure: isCureIntegrator(state),
    orderComplianceError: state[itemNames.orderComplianceError],
    customerId: state.customerId,
    isMmuEnabled: isLeafPaConfigPackClosedLoopFacility(state) && isFeatureEnabled(state)('feature_leaf_pa_mmu_limits'),
  };

}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, menuActions, {setItem});
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderCategoryDisplay);
