
//
//  SEE: https://www.sparkfun.com/datasheets/Components/General/Driver%20board.pdf
//

const escposCommands = {

  // Feed control sequences
  CTL_LF: 			[ '\x0A' ],						// Print and line feed
  CTL_FF: 			[ '\x0C' ],						// Form feed
  CTL_CR: 			[ '\x0D' ],						// Carriage return
  CTL_HT: 			[ '\x09' ],						// Horizontal tab
  CTL_VT: 			[ '\x0B' ],						// Vertical tab

  // Printer hardware
  HW_INIT: 			[ '\x1B', '\x40' ],					// Clear data in buffer and reset modes
  HW_STANDARD: 	[ '\x1B', '\x53' ],					// Standard Mode - no real effect
  HW_PAGE: 	    [ '\x1B', '\x4C' ],					// Page Mode - avoid - locks things up more often than not
  HW_SELECT: 		[ '\x1B', '\x3D', '\x01'],			// Printer select
  HW_RESET: 		[ '\x1B', '\x3F', '\x0A', '\x00' ],		// Reset printer hardware

  // Cash Drawer
  CD_KICK_2: 			[ 0x1b, 0x70, 0x00 ],			// Sends a pulse to pin 2 []
  CD_KICK_5: 			[ 0x1b, 0x70, 0x01 ],			// Sends a pulse to pin 5 []

  // Page Cuts
  PAPER_FULL_CUT: 	[ '\x1B', '\x64', '\x00' ],			// Full cut paper
  PAPER_PART_CUT: 	[ '\x1B', '\x64', '\x01' ],			// Partial cut paper
  // Text format
  TXT_NORMAL: 		[ 0x1b, 0x21, 0x00 ],			// Normal text
  TXT_2HEIGHT: 		[ 0x1b, 0x21, 0x10 ],			// Double height text
  TXT_2WIDTH: 		[ 0x1b, 0x21, 0x20 ],			// Double width text
  TXT_UNDERL_OFF: 	[ 0x1b, 0x2d, 0x00 ],			// Underline font OFF
  TXT_UNDERL_ON: 		[ 0x1b, 0x2d, 0x01 ],			// Underline font 1-dot ON
  TXT_UNDERL2_ON: 	[ 0x1b, 0x2d, 0x02 ],			// Underline font 2-dot ON
  TXT_BOLD_OFF: 		[ 0x1b, 0x45, 0x00 ],			// Bold font OFF
  TXT_BOLD_ON: 		[ 0x1b, 0x45, 0x01 ],			// Bold font ON
  TXT_FONT_A: 		[ 0x1b, 0x4d, 0x00 ],			// Font type A
  TXT_FONT_B: 		[ 0x1b, 0x4d, 0x01 ],			// Font type B

  // Here for reference but they do not appear to work on rev 1.15 so mocked them
  TXT_ALIGN_LT: 		[ '\x1B','\x1D', '\x61', '\x00' ],
  TXT_ALIGN_CT: 		[ '\x1B', '\x61', '\x31' ],
  TXT_ALIGN_RT: 		[ '\x1B','\x1D', '\x61', '\x02' ],

  // Barcode format
  BARCODE_TXT_OFF: 	[ 0x1d, 0x48, 0x00 ],			// HRI barcode chars OFF
  BARCODE_TXT_ABV: 	[ 0x1d, 0x48, 0x01 ],			// HRI barcode chars above
  BARCODE_TXT_BLW: 	[ 0x1d, 0x48, 0x02 ],			// HRI barcode chars below
  BARCODE_TXT_BTH: 	[ 0x1d, 0x48, 0x03 ],			// HRI barcode chars both above and below
  BARCODE_FONT_A: 	[ 0x1d, 0x66, 0x00 ],			// Font type A for HRI barcode chars
  BARCODE_FONT_B: 	[ 0x1d, 0x66, 0x01 ],			// Font type B for HRI barcode chars
  BARCODE_HEIGHT: 	[ 0x1d, 0x68, 0x64 ],			// Barcode Height [1-255]
  BARCODE_WIDTH: 		[ 0x1d, 0x77, 0x03 ],			// Barcode Width  [2-6]
  BARCODE_UPC_A: 		[ 0x1d, 0x6b, 0x00 ],			// Barcode type UPC-A
  BARCODE_UPC_E: 		[ 0x1d, 0x6b, 0x01 ],			// Barcode type UPC-E
  BARCODE_EAN13: 		[ 0x1d, 0x6b, 0x02 ],			// Barcode type EAN13
  BARCODE_EAN8: 		[ 0x1d, 0x6b, 0x03 ],			// Barcode type EAN8
  BARCODE_CODE39: 	[ 0x1d, 0x6b, 0x04 ],			// Barcode type CODE39
  BARCODE_ITF: 		[ 0x1d, 0x6b, 0x05 ],			// Barcode type ITF
  BARCODE_NW7: 		[ 0x1d, 0x6b, 0x06 ],			// Barcode type NW7

  // Image format
  S_RASTER_N: 		[ 0x1d, 0x76, 0x30, 0x00 ], 	// Set raster image normal size
  S_RASTER_2W: 		[ 0x1d, 0x76, 0x30, 0x01 ], 	// Set raster image double width
  S_RASTER_2H: 		[ 0x1d, 0x76, 0x30, 0x02 ], 	// Set raster image double height
  S_RASTER_Q: 		[ 0x1d, 0x76, 0x30, 0x03 ],	 	// Set raster image quadruple

  [1]: '\x01',
  [2]: '\x02',
  [3]: '\x03',
  [4]: '\x04',
  [5]: '\x05',
  [6]: '\x06',
  [7]: '\x07',
  [8]: '\x08',
  [9]: '\x09',
  [10]: '\x0a',
  [11]: '\x0b',
  [12]: '\x0c',
  [13]: '\x0d',
  [14]: '\x0e',
  [15]: '\x0f',
  [16]: '\x10',
  [17]: '\x11',
  [18]: '\x12',
  [19]: '\x13',
  [20]: '\x14'

};

export default escposCommands;
