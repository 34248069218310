import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';
import {Col, FormGroup, Row } from 'react-bootstrap';

export const OrderListing = (props) => {
  const {orders} = props;

  return (
    <FormGroup className='driver-info'>
      <h3>{I18n.t('generateManifest.form.orders')}</h3>
      <Row>
        <Col xs={6} sm={4} md={3}>
          <label>{I18n.t('generateManifest.form.orderNumber')}</label>
        </Col>
        <Col xs={6} sm={4} md={3}>
          <label>{I18n.t('generateManifest.form.dateOrdered')}</label>
        </Col>
        <Col xs={6} sm={4} md={3}>
          <label>{I18n.t('generateManifest.form.dateExpected')}</label>
        </Col>
      </Row>
      {
        orders.map((order, index) => {
          return (<Row key={index}>
            <Col xs={6} sm={4} md={3}>
              <label>{get(order, 'name')}</label>
            </Col>
            <Col xs={6} sm={4} md={3}>
              <label>{get(order, 'created_at')}</label>
            </Col>
            <Col xs={6} sm={4} md={3}>
              <label>{get(order, 'created_at')}</label>
            </Col>
          </Row>
          );
        })
      }
    </FormGroup>
  );
};

OrderListing.propTypes = {
  orders: PropTypes.array.isRequired
};

export default OrderListing;
