import { CHECK_DEV_ENVIRONMENT, SET_INITIAL_VERSION, SET_CURRENT_VERSION } from '../constants/actionTypes';
import initialState from '../constants/initialState';

export default function systemReducer (state = initialState.system, action){
  const {payload} = action;

  switch (action.type) {
  case CHECK_DEV_ENVIRONMENT:
    return Object.assign({}, state, {environment: payload});
  case SET_INITIAL_VERSION:
    return Object.assign({}, state, {initialVersion: payload});
  case SET_CURRENT_VERSION:
    return Object.assign({}, state, {currentVersion: payload});
  default:
    return state;
  }
}
