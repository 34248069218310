import React from 'react';
import PropTypes from 'prop-types';
import {Col} from 'react-bootstrap';

import PreviewField from './PreviewField';

const PreviewFields = (props) => {
  const {fields} = props;
  return fields.map((field, index) => (
    <Col md={3} className='field-data' key={index}>
      <PreviewField field={field}/>
    </Col>
  ));
};

PreviewFields.propTypes = {
  fields: PropTypes.array.isRequired
};

export default PreviewFields;