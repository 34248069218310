import React from 'react';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import DatePickerInput from '../../common/form/DatePickerInput';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import PackagePurposeField from '../../common/form/redux-form/PackagePurposeField';
import MultiselectInput from '../../common/form/MultiselectInput';

const PackagingJobDetailPanel = (props) => {
  const {locations, currentFacilityUsers, disabled} = props;

  return (
    <Row>
      <Col xs={12} sm={6} md={6}>
        <Field name='estimated_completion_at' component={DatePickerInput} props={{
          label: I18n.t('packaging.form.estimatedCompletionTime'),
          timeFormat: true,
          isRequired: true,
          inputProps: {disabled: disabled},
        }}/>
      </Col>
      <Col xs={12} sm={6} md={6}>
        <Field name='completed_at' component={DatePickerInput} props={{
          label: I18n.t('packaging.form.actualCompletionTime'),
          timeFormat: true,
          isRequired: true,
          inputProps: {disabled: disabled},
        }}/>
      </Col>
      <Col xs={12} sm={6} md={6}>
        <Field name='package_created_at' component={DatePickerInput} props={{
          label: I18n.t('packaging.form.packageCreatedAt'),
          timeFormat: false,
          inputProps: {disabled: disabled},
        }}/>
      </Col>
      <Col xs={12} sm={6} md={6}>
        <Field name='package_expires_at' component={DatePickerInput} props={{
          label: I18n.t('packaging.form.packageExpiresAt'),
          timeFormat: false,
          isRequired: false,
          inputProps: {disabled: disabled},
        }}/>
      </Col>
      <Col xs={12} sm={6} md={6}>
        <Field
          name='packaging_location_id'
          component={ReactSelectInput}
          props={{
            label: I18n.t('packages.form.storageLocation'),
            options: locations,
            placeholder: I18n.t('common.form.selectPlaceholder'),
            isRequired: true,
            disabled: disabled
          }}
        />
      </Col>
      <Col xs={12} sm={6} md={6}>
        <Field
          name='user_ids' component={MultiselectInput}
          props={{
            label: I18n.t('packaging.form.assignEmployees'),
            options: currentFacilityUsers,
            placeholder: I18n.t('common.form.selectPlaceholder'),
            isRequired: true,
            valueKey: 'id',
            textKey: 'displayName',
            disabled: disabled
          }} />
      </Col>
      <Col xs={12} sm={6} md={6}>
        <PackagePurposeField name='purpose' disabled={disabled}/>
      </Col>
      <Col xs={12} sm={6} md={6}>
        <Field name={`is_trade_sample`} component={ReactSelectInput}
               props={{
                 label: I18n.t('metrc.form.tradeSample'),
                 disabled: disabled,
                 options: [
                   {text: 'Yes', value: 1},
                   {text: 'No', value: 0}
                 ]
               }}
        />
      </Col>
    </Row>
  );
}

PackagingJobDetailPanel.propTypes = {
};
export default PackagingJobDetailPanel;
