import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import {Button, Col, Row} from 'react-bootstrap';
import get from 'lodash.get';

import ReactSelectInput from '../../../common/form/ReactSelectInput';
import MultiselectInput from '../../../common/form/MultiselectInput';
import InternationalCurrencyInput from '../../../common/form/InternationalCurrencyInput';
import {COUPON_LIMIT} from '../../../../constants/coupons';

const appliesToOptions = [
  {text: I18n.t('cart.manualDiscount.items'), value: 'line'},
  {text: I18n.t('cart.manualDiscount.order'), value: 'order'},
];

const ManualDiscountForm = ({invalid, submitting, pristine, handleSubmit, orderProductOptions, appliesTo, discounts, order}) => {
  const hasDiscounts = Boolean(discounts && discounts.length > 0);
  const submitDisabled = hasDiscounts || invalid || pristine || submitting || get(order, 'coupons', []).length >= COUPON_LIMIT;
  return (
    <Col xs={12} className='manual-discount-form'>
      <h4>{I18n.t('cart.manualDiscount.title')}</h4>
      <Row>
        <Col xs={12} md={6}>
          <InternationalCurrencyInput
            name='discount_amount'
            props={{
              label: I18n.t('cart.discount.dollarAmount'),
              disabled: hasDiscounts
            }}/>
        </Col>
        <Col xs={12} md={6}>
          <Field name='coupon_applies_to' component={ReactSelectInput} props={{
            label: I18n.t('cart.manualDiscount.appliesTo'),
            options: appliesToOptions,
            disabled: hasDiscounts,
          }}/>
        </Col>
        {appliesTo === 'line' ?
          <Col xs={12}>
            <Field name='item_master_ids' component={MultiselectInput} props={{
              label: I18n.t('cart.manualDiscount.items'),
              options: orderProductOptions,
              disabled: hasDiscounts,
            }}/>
          </Col>
          : null
        }
        <Col xs={12}>
          <Button variant='primary' className='float-right' onClick={handleSubmit} disabled={submitDisabled}>
            {I18n.t('cart.discount.addDiscount')}
          </Button>
        </Col>
      </Row>
    </Col>
  );
};

ManualDiscountForm.propTypes = {
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  orderProductOptions: PropTypes.array.isRequired,
  discounts: PropTypes.array.isRequired,
  appliesTo: PropTypes.string,
  order: PropTypes.object.isRequired,
};

export default ManualDiscountForm;
