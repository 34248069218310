import React from 'react';
import PropTypes from 'prop-types';
import RestockItem from './RestockItem';

const RestockItems = (props) => {

  const productId = 'id';
  const inventoryId = 'item_id';

  const {integrationState, refundOrder} = props;
  const disableItemRemoval = integrationState && integrationState.isMetrc && refundOrder && refundOrder.products && refundOrder.products.length > 0;

  const getAggregatedRestockList = () => {

    if(props.queue.length === 0) return props.queue;

    const iterateProducts = props.queue.map( (product) => { return product; });
    const products = iterateProducts.reduce( (acc, product) => {

      const productAndItem = acc.find( (p) => {
        return p[productId] === product[productId] && p[inventoryId] === product[inventoryId];
      });

      if(productAndItem){
        productAndItem.quantity += product.quantity;
      } else {
        const p = Object.assign({}, product, {mutate: true});
        acc.push(p);
      }

      return acc;

    }, []);

    return products;


  };

  const groupedRestockedList = getAggregatedRestockList();

  return (
    <tbody>
    {
      groupedRestockedList.length === 0
        ? (<tr><td colSpan={3} style={{textAlign: 'center'}}>No Active Restocks</td></tr>)
          : groupedRestockedList.map( (product, index) => { // eslint-disable-line indent
              return (<RestockItem key={index} onRemoveItemFromRestocking={props.onRemoveItemFromRestocking} data={product} disableItemRemoval={disableItemRemoval}/>); // eslint-disable-line indent
            }) // eslint-disable-line indent
    }
    </tbody>
  );

};

RestockItems.propTypes = {
  onRemoveItemFromRestocking: PropTypes.func.isRequired,
  queue: PropTypes.array.isRequired,
  onDeleteRestockItem: PropTypes.func.isRequired,
  integrationState: PropTypes.object.isRequired,
  refundOrder: PropTypes.func.isRequired,
};

export default RestockItems;
