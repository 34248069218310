import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {Row, Col} from 'react-bootstrap';
import {FaDollarSign} from 'react-icons/fa';
import {I18n} from 'react-redux-i18n';
import moment from 'moment-timezone';
import TextInput from '../../common/form/TextInput';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import NumericInput from '../../common/form/NumericInput';
import CurrencyInput from '../../common/form/CurrencyInput';
import DatePickerInput from '../../common/form/DatePickerInput';
import SubmitSection from '../../common/form/SubmitSection';

export const ReceiveTrackingIdsForm = (props) => {
  const {change, generatedEndingId:{endingId}, handleSubmit, reset, submitting, invalid, preventEnterDefault} = props;
  const setToday = event => {
    event.stopPropagation();
    event.preventDefault();
    change('received_at', moment.tz('UTC').startOf('day'));
  };
  const settings = {
    actionSettings: {
      reset: {
        text: I18n.t('common.form.reset'),
        action: reset,
        style: 'default'
      },
      submit: {
        text: I18n.t('common.form.save'),
        submitting,
        invalid,
      }
    }
  };
  const trackingIdTypes = [
    {text: I18n.t('tracking.receiveTrackingIds.form.packageType'), value: 'package'},
    {text: I18n.t('tracking.receiveTrackingIds.form.plantType'), value: 'plant'}
  ];
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col md={6}>
          <Field
            name='qty_received'
            component={NumericInput}
            props={{
              label: I18n.t('tracking.receiveTrackingIds.form.quantityReceived'),
              isRequired: true
            }}/>
        </Col>
        <Col xs={6}>
          <Field
            name='cost'
            component={CurrencyInput}
            props={{
              label: I18n.t('tracking.receiveTrackingIds.form.cost'),
              leftAddon: (<FaDollarSign/>),
              isRequired: true
            }}/>
        </Col>
        <Col md={7}>
          <Field
            name='type'
            component={ReactSelectInput}
            props={{
              label: I18n.t('tracking.receiveTrackingIds.form.type'),
              options: trackingIdTypes,
              textKey: 'text',
              valueKey: 'value',
              isRequired: true
            }}/>
        </Col>
        <Col md={7}>
          <Field
            name='starting_tag'
            component={TextInput}
            props={{
              label: I18n.t('tracking.receiveTrackingIds.form.beginningId'),
              isRequired: true
            }}
            onKeyPress={preventEnterDefault}
            normalize={value => value.toUpperCase()}
          />
        </Col>
        <Col md={7}>
          <Field
            name='ending_tag'
            component={TextInput}
            props={{
              label: I18n.t('tracking.receiveTrackingIds.form.endingId'),
              value: endingId,
              disabled: true
            }}/>
        </Col>
        <Col md={8}>
          <div className='date-received-block'>
            <Field
              name='received_at'
              component={DatePickerInput}
              props={{
                label: I18n.t('tracking.receiveTrackingIds.form.dateReceived'),
                rightAddon: <span className='set-today-button' onClick={setToday}>{I18n.t('tracking.receiveTrackingIds.form.today')}</span>,
                closeOnSelect: true,
                isRequired: true
              }}/>
          </div>
        </Col>
      </Row>
      <SubmitSection settings={settings}/>
    </form>
  );
};

ReceiveTrackingIdsForm.propTypes = {
  change: PropTypes.func.isRequired,
  generatedEndingId: PropTypes.shape({
    endingId: PropTypes.string
  }),
  reset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  preventEnterDefault: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool
};

export default ReceiveTrackingIdsForm;
