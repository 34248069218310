import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Alert, Button, Col, Row} from 'react-bootstrap';
import {FaExclamationTriangle, FaMinus} from 'react-icons/fa';
import {Field} from 'redux-form';
import get from 'lodash.get';
import * as messageTypes from '../../../constants/messageTypes';
import PackageSearch from '../../common/scan-searches/PackageSearch';
import GetPlantGroupsById from './GetPlantGroupsById';
import TextInput from '../../common/form/TextInput';
import {isMedicated} from '../../../selectors/itemMastersSelectors';
import NumericInput from '../../common/form/NumericInput';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import {TERPENE_PROFILE_DEFAULT_DIMENSIONS, TOTAL_TERPENE_KEY} from '../../../constants/testResults';

export const headerTexts = {
  packages: [
    'cultivation.testResults.form.batchName',
    'cultivation.testResults.form.packageId',
    'cultivation.testResults.form.productName',
  ],
  plants: [
    'cultivation.testResults.form.batchName',
    'cultivation.testResults.form.plantGroupId',
  ]
};

export const searchLabel = {
  packages: 'cultivation.testResults.form.searchPackage',
  plants: 'cultivation.testResults.form.searchPlantGroup',
};

export const multipleReferencesWarning = {
  packages: 'cultivation.testResults.form.packages',
  plants: 'cultivation.testResults.form.plantGroups'
};

export const ReferencesFieldArray = ({fields, meta, packageOptions, clearSearch, addMessage, loading, inheritFromItemMaster, currentValues, product, isEditingDisabled, handleFirstPackageChange, isPlants, disallowTestResultCreation, complianceSettings, canRetestPackages, change, perServingModeAvailable, isPerServingMode, labResultDimensions}) => {

  const referenceTypeKey = () => isPlants ? 'plants' : 'packages';

  const test_results_value_type = get(currentValues, 'test_results_value_type', 'percentage');
  const servings_per_container = get(currentValues, 'servings_per_container', '');
  const servings_uom = get(currentValues, 'servings_uom', 'mg');
  const servingUoms = [{id: 'mg', name: 'MG'}, {id: 'gr', name: 'GR'}];

  const recalculateMggValues = () => {

    const cannabinoid_potency_dimensions = [...get(labResultDimensions, 'cannabinoid_potency', [])];
    const terpene_profile_dimensions = [...TERPENE_PROFILE_DEFAULT_DIMENSIONS, {key: TOTAL_TERPENE_KEY}];
    const dimensions = [...cannabinoid_potency_dimensions, ...terpene_profile_dimensions];
    dimensions.forEach(dimension => {
      const key = dimension.key;
      const percent_value =  get(currentValues, `${key}_percent`); // per servings value
      const mg_g_value =  get(currentValues, `${key}_mg_g`); // per pack value
      if (percent_value) {
        change(`${key}_mg_g`, Number((percent_value * 10).toFixed(3)));
      }
      if (mg_g_value && !percent_value) {
        change(`${key}_percent`, Number((mg_g_value * 0.1).toFixed(3)));
      }
    });
  };


  const recalculatePerPackValues = (servings_per_container) => {
    if (!servings_per_container) {
      return;
    }

    const cannabinoid_potency_dimensions = [...get(labResultDimensions, 'cannabinoid_potency', [])];
    const terpene_profile_dimensions = [...TERPENE_PROFILE_DEFAULT_DIMENSIONS, {key: TOTAL_TERPENE_KEY}];
    const dimensions = [...cannabinoid_potency_dimensions, ...terpene_profile_dimensions];
    dimensions.forEach(dimension => {
      const key = dimension.key;
      const percent_value =  get(currentValues, `${key}_percent`); // per servings value
      const mg_g_value =  get(currentValues, `${key}_mg_g`); // per pack value
      if (percent_value) {
        change(`${key}_mg_g`, Number((percent_value * servings_per_container).toFixed(3)));
      }
      if (mg_g_value && !percent_value) {
        change(`${key}_percent`, Number((mg_g_value / servings_per_container).toFixed(3)));
      }
    });
  };

  return (
    <div>
      {meta && meta.error && !loading ?
        <Row>
          <Col xs={12}>
            <Alert variant='danger'>
              <FaExclamationTriangle />
              <span className='padding-left'>{meta.error}</span>
            </Alert>
          </Col>
        </Row> :
        <React.Fragment>
          {(fields.length > 1) &&
            <Alert variant='info'>
              <FaExclamationTriangle />
              <span id='multipleLabResultReferencesAlert' className='padding-left'>
                {I18n.t(
                  'cultivation.testResults.form.multipleReferencesWarning',
                  {referenceType: I18n.t(multipleReferencesWarning[referenceTypeKey()]).toLowerCase()})
                }
              </span>
            </Alert>
          }
          {perServingModeAvailable &&
          <Row>
            <Col xs={12}>
              <div style={{marginTop: '10px'}}>
                <span style={{fontWeight: 'bold'}}>{I18n.t('products.form.productTestResultsType.testResultUnits')}</span><br/>
                 <Button
                  type='button'
                  variant={test_results_value_type === 'percentage' ? 'primary' : null}
                  onClick={() => {
                    recalculateMggValues();
                    change('test_results_value_type', 'percentage');
                  }}
                >
                  {I18n.t('products.form.productTestResultsType.percentage_mgg')}
                </Button>
                &nbsp;{I18n.t('products.form.or')}&nbsp;
                <Button
                  type='button'
                  variant={test_results_value_type === 'serving' ? 'primary' : null}
                  onClick={() => {
                    recalculatePerPackValues(servings_per_container);
                    change('test_results_value_type', 'serving');
                  }}
                >
                  {I18n.t('products.form.productTestResultsType.serving')}
                </Button>
              </div>
              {test_results_value_type === 'serving' &&
                <div style={{marginTop: '10px'}}>
                  <Field
                    name='servings_per_container'
                    component={NumericInput}
                    style={{width: '25%'}}
                    props={{
                      label: I18n.t('products.form.productServingsPerPack'),
                      fractionPartSize: 0,
                      value: test_results_value_type === 'serving' ? servings_per_container : '',
                      min: 1,
                      // Recalculate all 'Per Pack' (_mg_g) fields in TestResultsFieldsArray
                      onChange: (servings_per_container) => {
                        recalculatePerPackValues(servings_per_container);
                        change('servings_per_container', servings_per_container);
                      },
                      isRequired: isPerServingMode,
                      disabled: !!(product && get(product , 'test_results_value_type') && get(product, 'servings_per_container'))
                    }}
                  />
                  <Field
                    name='servings_uom'
                    component={ReactSelectInput}
                    style={{width: '25%'}}
                    props={{
                      label: I18n.t('products.form.uom'),
                      placeholder: I18n.t('common.form.uomPlaceholder'),
                      options: servingUoms,
                      textKey: 'name',
                      valueKey: 'id',
                      value: test_results_value_type === 'serving' ? servings_uom : '',
                      isRequired: isPerServingMode,
                      disabled: !!(product && get(product , 'test_results_value_type') && get(product, 'servings_per_container'))
                    }}
                  />
                </div>
              }
            </Col>
          </Row>
          }
          <table className='inputs-table' style={{marginTop: '10px'}}>
            <thead>
            {
              isPlants
                ? (<tr>
                  <th style={{width: '40%'}}>{I18n.t(headerTexts.plants[0])}</th>
                  <th style={{width: '40%'}}>{I18n.t(headerTexts.plants[1])}</th>
                  <th style={{width: '20%'}} />
                </tr>)
                : (<tr>
                  <th style={{width: '25%'}}>{I18n.t(headerTexts.packages[0])}</th>
                  <th style={{width: '25%'}}>{I18n.t(headerTexts.packages[1])}</th>
                  <th style={{width: '25%'}}>{I18n.t(headerTexts.packages[2])}</th>
                  <th style={{width: '25%'}} />
                </tr>)
            }
            </thead>
            <tbody>
            {fields.map((referenceName, referenceIndex) => {
              return (
                <tr key={referenceName}>
                  <td>
                    <Field name={`${referenceName}.lot_number`} component={TextInput} props={{
                      disabled: true,
                    }}/>
                  </td>
                  <td>
                    <Field name={`${referenceName}.package_code`} component={TextInput} props={{
                      disabled: true,
                    }}/>
                  </td>
                  {
                    isPlants
                      ? null
                      : (<td>
                        <Field name={`${referenceName}.product_name`} component={TextInput} props={{
                          disabled: true,
                        }}/>
                      </td>)
                  }
                  <td className='remove-button'>
                    <Button size='sm' variant='primary' onClick={() => fields.remove(referenceIndex)} disabled={isEditingDisabled}>
                      <FaMinus/>
                    </Button>
                  </td>
                </tr>
              );
            })}
            </tbody>
          </table>
        </React.Fragment>
      }
      <Row>
        <Col xs={6} sm={4} md={3}>
          <label id='search-label'>{I18n.t(searchLabel[referenceTypeKey()])}</label>
          {
            isPlants
              ? (<GetPlantGroupsById
                disabled={isEditingDisabled}
                onSearchComplete={(searchTerm, options) => {
                  const foundPlants = packageOptions; // legacy naming so renaming for clarity.
                  const newPackage = foundPlants.find((plant) => plant.package_code === searchTerm); // Package code because this has been mutated for tests.

                  if(newPackage){
                    fields.push({
                      ...newPackage,
                      plant_phase_id: newPackage.stage_id,
                      reference_is_active: 1,
                      reference_name: newPackage.package_code
                    });

                    clearSearch();
                  }
                  if(!foundPlants.length && options.length){
                    addMessage(messageTypes.warning, [`ei.searchItem.errorAlreadySelected`, {packageId: searchTerm}]);
                  }
                }}
              />)
              : (<PackageSearch
                disabled={isEditingDisabled}
                onSearchComplete={(packageId, options) => {
                  if (packageOptions.length === 1) {
                    if (!isMedicated(packageOptions[0].items[0]) || disallowTestResultCreation(packageOptions[0].items)) {
                      addMessage(messageTypes.warning, [`cultivation.testResults.form.invalidPackage`, {package_code: packageOptions[0].package_code}]);
                    } else {
                      canRetestPackages(packageOptions, complianceSettings.inv_test_results_retest_limit, () => {
                        const newPackage = {
                          reference_id: packageOptions[0].package_id,
                          reference_type: 'package',
                          package_code: packageOptions[0].package_code,
                          lot_number: packageOptions[0].lot_number,
                          product_name: packageOptions[0].product_name,
                          state_integration_tracking_id: packageOptions[0].items[0].state_integration_tracking_id,
                          tag_requested: packageOptions[0].items[0].tag_requested,
                          category_id: packageOptions[0].items[0].category_id,
                          reference_name: packageOptions[0].package_code,
                          reference_is_active: 1,
                          item_master_id: packageOptions[0].items[0].item_master_id,
                        };
                        fields.push(newPackage);

                        // See if we can inherit (some) test results from the ItemMaster
                        if (typeof inheritFromItemMaster === 'function') {
                          // Build list of item_master_ids
                          // 1. Get item_master_ids already added.
                          const item_master_ids = get(currentValues, 'references', []).map(testedPackage => testedPackage.item_master_id);
                          // 2. Add item_master_id for newly added package)
                          item_master_ids.push(packageOptions[0].items[0].item_master_id);
                          // Make list unique
                          const unique_item_master_ids = item_master_ids.filter((item_master_id, index, array) => array.indexOf(item_master_id) === index);
                          // Call inheritFromItemMaster logic
                          inheritFromItemMaster(unique_item_master_ids);
                        }

                        if (fields.length === 0) {
                          handleFirstPackageChange(newPackage);
                        }
                      });
                    }

                    clearSearch();
                  } else if (packageOptions.length === 0 && options.length > 0) {
                    addMessage(messageTypes.warning, [`ei.searchItem.errorAlreadySelected`, {packageId}]);
                  }

                }}
              />)
          }
        </Col>
      </Row>
    </div>
  );
};

ReferencesFieldArray.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
  }),
  packageOptions: PropTypes.array.isRequired,
  clearSearch: PropTypes.func.isRequired,
  addMessage: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  isEditingDisabled: PropTypes.bool,
  mode: PropTypes.string,
  product: PropTypes.object,
  handleFirstPackageChange: PropTypes.func.isRequired,
  disallowTestResultCreation: PropTypes.func.isRequired,
  isPlants: PropTypes.bool.isRequired,
  complianceSettings: PropTypes.object.isRequired,
  canRetestPackages: PropTypes.func,
  inheritFromItemMaster: PropTypes.func,
  currentValues: PropTypes.object,
  change: PropTypes.func.isRequired,
  perServingModeAvailable: PropTypes.bool,
  isPerServingMode: PropTypes.bool.isRequired,
  labResultDimensions: PropTypes.object
};

export default ReferencesFieldArray;
