import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import { bindActionCreators } from 'redux';
import get from 'lodash.get';
import {Form, FormGroup, ListGroup, ListGroupItem} from 'react-bootstrap';
import {FaCheck} from 'react-icons/fa';
import SelectControl from '../../common/form/SelectControl';
import ModalWrapper from '../../common/ModalWrapper';
import * as dataNames from '../../../constants/dataNames';
import {getProductType} from '../../../selectors/itemMastersSelectors';
import {getUnpaginatedData, getSearchData} from '../../../actions/apiActions';
import {
  hideProductLookup,
  selectProductLookup,
  confirmProductLookup,
  setProductLookupCategory,
  setProductLookupSubcategory,
  searchProductLookup
} from '../../../actions/productLookupActions';
import getFacilityPrepackItemMasters from '../../../selectors/forms/ProductLookupFormSelector';
import {isCanadaFacility} from '../../../selectors/facility/getFacilityCountry';
import {getIntegrationState} from '../../../selectors/integration/integrationSelectors';
import {changeItemMaster} from '../../../actions/packageActions';
import { uomTypes } from '../../../constants/uomTypes';

/**
 * ItemMaster selection modal browsable by category + sub-category.
 */
class ProductLookup extends React.PureComponent {

  constructor(props) {
    super(props);
    this.setCategory = this.setCategory.bind(this);
    this.setSubCategory = this.setSubCategory.bind(this);
    this.searchItemMasters = this.searchItemMasters.bind(this);
    this.selectItemMaster = this.selectItemMaster.bind(this);
    this.confirmSelection = this.confirmSelection.bind(this);
    this.state = {
      itemMasters: [],
      loading: false
    };
  }

  /**
   * Populate option sets for categories, subcategories if missing
   */
  componentWillMount() {
    if(!get(this.props,'categories.length',false)){
      this.props.actions.getUnpaginatedData(
        '/api/categories',
        dataNames.categories,
        {failed: 'categories.get.failed'},
        undefined,
        undefined,
        {debounce: true}
      );
    }
    this.props.actions.getUnpaginatedData(
      '/api/prepack_weights/facility',
      dataNames.prepackFacilityWeights,
      undefined,
      {active: 1}
    );
  }

  /**
   * trigger search submission via prop change
   */
  componentDidUpdate(prevProps) {
    const {productLookup: {doSearch,categoryId,subCategoryId}} = this.props;
    if(doSearch && !prevProps.productLookup.doSearch){
      if(categoryId && subCategoryId){
        this.searchItemMasters(categoryId,subCategoryId);
        this.props.actions.searchProductLookup();
      }
    }
  }

  /**
   * Submit item master search
   *
   * @param {Number} categoryId - Category ID
   * @param {Number} subCategoryId - Subcategory ID
   * @return {Promise<Object[]>} - Search results
   */
  searchItemMasters(categoryId, subCategoryId) {
    this.setState({loading: true});

    const fields = [ 'id', 'organization_id', 'item_number', 'name',
      'display_name', 'is_inventory_item', 'is_sales_item', 'is_purchasing_item',
      'is_manufactured_item', 'category_id', 'subcategory_id', 'strain_id',
      'uom_type', 'default_uom', 'unit_weight_base', 'unit_weight_uom_display',
      'medicated_weight_base', 'medicated_weight_uom_display', 'net_weight_base',
      'net_weight_uom_display', 'created_at', 'updated_at', 'active',
      'strain_name', 'strain_code', 'dominance', 'category_name',
      'category_display_name', 'subcategory_display_name', 'category_code',
      'subcategory_name', 'subcategory_code', 'is_ingredient', 'is_medicated',
      'is_prepack', 'prepack_weight_id', 'item_master_parent_id', 'lot_tracked'
    ];

    const filters = [
      'active:1',
      'is_draft:0',
      'is_ingredient:0',
      'category_id:' + categoryId,
      'subcategory_id:' + subCategoryId
    ];

    const solrParams = {
      sort: 'name asc, display_name asc',
      query: '',
      size: '100000',
      start: '0',
      filter: filters.join(' AND '),
      fields
    };
    const uomCode = this.props.productLookup.currentPackageUom;
    const uom = uomCode && uomTypes[uomCode];

    let chain = this.props.actions.getSearchData( '/api/search/item_masters', null, null, solrParams, (data) => {
      // Shape solr response to match inventory response for item_masters
      return data.map((master) => {
        master.inventory_attributes = {
          is_prepack: master.is_prepack,
          item_master_parent_id: master.item_master_parent_id,
          prepack_weight_id: master.prepack_weight_id,
          lot_tracked: master.lot_tracked
        };
        master.net_weight_base = (master.net_weight_base !== undefined) ? parseFloat(master.net_weight_base).toFixed(2) : '0.00';
        master.default_cost = (master.default_cost !== undefined) ? parseFloat(master.default_cost).toFixed(2) : '0.00';
        master.itemType = getProductType(master);
        return master;
      });
    });

    chain = chain.then( searchResults => {
      return get(this.props,'filterBy',false) ? this.props.filterBy(searchResults) : Promise.resolve(searchResults);
    });

    chain = chain.then( filteredResults => {
      // Filter the prepack weights down to only those that match the provided prepack item otherwise you can radically alter inventory.
      const matchingPrepackWeights = this.props.prepackFacilityWeights.filter((weight) => weight.prepack_weight_id === this.props.productLookup.prepackWeightId);
      const itemMasters = getFacilityPrepackItemMasters(filteredResults, matchingPrepackWeights).filter((itemMaster) => {
        const itemMasterUom = itemMaster && itemMaster.default_uom && uomTypes[itemMaster.default_uom];
        return itemMasterUom.type === uom.type;
      });
      this.setState({
        itemMasters,
        loading: false
      });
    });

    return chain;
  }

  /**
   * Render search results as ListGroup
   *
   * @return {React.Element}
   */
  renderProductList() {
    const {productLookup, isBiotrack} = this.props;
    if(this.state.loading){ return <span className='text-muted'>{I18n.t('general.loading')}</span>; }
    if(!productLookup.categoryId || !productLookup.subCategoryId){ return null; }
    if(!this.state.itemMasters.length){ return <span className='text-muted'>{I18n.t('productLookup.noResults')}</span>; }
    const clickHandler = (evt,itemMasterId) => {
      this.selectItemMaster(itemMasterId);
    };
    const biotrackFilter = (item) => !isBiotrack || item.strain_id === productLookup.itemMasterStrainId;
    const itemMasters = this.state.itemMasters.filter(biotrackFilter).map( (item,idx) => {
      return (<ListGroupItem key={idx}
        onClick={(evt) => clickHandler(evt,item.id)}
        >
        {item.id === productLookup.itemMasterId ? <FaCheck style={{marginRight: '5px'}} /> : null}
        {item.display_name || item.name}
        </ListGroupItem>);
    });

    return <ListGroup className='product-lookup'>{itemMasters}</ListGroup>;
  }

  /**
   * handle category selection
   *
   * @param {SyntheticEvent} evt - React synthetic event
   */
  setCategory(evt) {
    this.props.actions.setProductLookupCategory(+evt.target.value);
  }

  /**
   * handle subcategory selection
   *
   * @param {SyntheticEvent} evt - React synthetic event
   */
  setSubCategory(evt) {
    this.props.actions.setProductLookupSubcategory(+evt.target.value);
    this.searchItemMasters(this.props.productLookup.categoryId, +evt.target.value);
  }

  /**
   * handle product click event
   *
   * @param {Number} itemMasterId - Selected item ID
   */
  selectItemMaster(itemMasterId) {
    this.props.actions.selectProductLookup(
      this.state.itemMasters.find( i => i.id === itemMasterId )
    );
  }

  /**
   * handle modal confirmation button click
   */
  confirmSelection() {
    this.props.actions.confirmProductLookup();
    this.props.actions.changeItemMaster(this.props.productLookup);
  }


  /**
   * filter category list
   */
  filterAllowedCategories(categories) {
    return categories.filter( (category) => {
      if(category.category_code !== 'UNMEDICATED' && !this.props.productLookup.isMedicated) return false;
      if(category.category_code === 'UNMEDICATED' && this.props.productLookup.isMedicated) return false;
      if(category.category_code === 'MARIJUANA' && !this.props.isCanadaFacility) return false;
      return category.category_code !== 'HARVESTS';

    });
  }


  /**
   * filter subcategory list
   */
  filterAllowedSubcategories(subcategories, category) {
    return subcategories.filter( (subcategory) => {
      if(category.category_code === 'PLANTS' && subcategory.subcategory_code === 'WASTE') return false;
      if(category.category_code === 'FLOWER' && subcategory.subcategory_code === 'FINISHED_FLOWER') {
        return this.props.isPaLeaf;
      }
      return true;
    });
  }


  /**
   * render primary component
   *
   * @return {React.Element}
   */
  render() {
    const {isBiotrack} = this.props;
    const categoriesFiltered = this.filterAllowedCategories(this.props.categories);
    const selectedCategory = categoriesFiltered.find( c => c.id === this.props.productLookup.categoryId );

    const subcategories = selectedCategory ? selectedCategory.subcategories : [];
    const subcategoriesFiltered = this.filterAllowedSubcategories(subcategories, selectedCategory);

    const categoryOptions = categoriesFiltered.map( (category) => {
      return {value: category.id, text: category.name};
    });
    let subcategoryOptions = subcategoriesFiltered.map( s => {
      return {value: s.id, text: s.name};
    });
    subcategoryOptions.unshift({value: -1, text: I18n.t('productLookup.selectSubCategory')});

    return (
      <ModalWrapper
        version={2}
        showModal={this.props.isModalOpen}
        onHide={this.props.actions.hideProductLookup}
        title={{text: I18n.t('productLookup.title')}}
        cancelButton={{
          show: true,
          text: 'productLookup.cancelButton',
          onClick: this.props.actions.hideProductLookup
        }}
        okayButton={{
          show: true,
          text: 'productLookup.okayButton',
          disabled: !this.props.productLookup.itemMasterId || !this.props.productLookup.itemMasterName || !this.props.productLookup.itemMasterDefaultUom || !this.props.productLookup.currentPackageName,
          onClick: this.confirmSelection
        }}
        keyboard={true}
        headerClass='bg-white'
        widthClass='modal-sm'
        >
        <div>
          <FormGroup controlId='product_lookup_category'>
            <Form.Label>{I18n.t('productLookup.categoryLabel')}</Form.Label>
            <SelectControl
              options={categoryOptions}
              onChange={this.setCategory}
              value={this.props.productLookup.categoryId}
              disabled={isBiotrack}
              defaultOption={I18n.t('productLookup.selectCategory')} />
          </FormGroup>

          <FormGroup controlId='product_lookup_subcategory'>
            <Form.Label>{I18n.t('productLookup.subCategoryLabel')}</Form.Label>
            <SelectControl
              options={subcategoryOptions}
              onChange={this.setSubCategory}
              disabled={isBiotrack}
              value={this.props.productLookup.subCategoryId || -1}
              />
          </FormGroup>

          {this.renderProductList()}
        </div>
      </ModalWrapper>
    );
  }
}

ProductLookup.propTypes = {
  /**
   * shared app actions
   */
  actions: PropTypes.shape({
    getUnpaginatedData: PropTypes.func.isRequired,
    getSearchData: PropTypes.func.isRequired,
    hideProductLookup: PropTypes.func.isRequired,
    selectProductLookup: PropTypes.func.isRequired,
    confirmProductLookup: PropTypes.func.isRequired
  }),


  /**
   * show/hide ProductLookup modal
   */
  isModalOpen: PropTypes.bool.isRequired,

  /**
   * optional callback to filter item master search results
   *
   * @param {Object[]} searchResults - Full item master search results
   * @return {Promise<Object[]>} - Filtered search results
   */
  filterBy: PropTypes.func,

  /**
   * category select options
   */
  categories: PropTypes.array,

  /**
   * productLookup store data
   */
  productLookup: PropTypes.object.isRequired,

  isCanadaFacility: PropTypes.bool,
  isPaLeaf: PropTypes.bool,
  isBiotrack: PropTypes.bool,
  prepackFacilityWeights: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  const isCanadianFacility = isCanadaFacility(state);
  const integrationState = getIntegrationState(state);
  const {isLeaf, isPaLeaf, isCure, isMetrc, isBiotrack} = integrationState;

  return {
    categories: state.categories,
    productLookup: state.productLookup,
    prepackFacilityWeights: state.prepackFacilityWeights,
    isCanadaFacility: (!isLeaf && !isCure && !isMetrc) ? isCanadianFacility : false,
    isPaLeaf: isPaLeaf,
    isBiotrack
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      getUnpaginatedData,
      getSearchData,
      hideProductLookup,
      selectProductLookup,
      confirmProductLookup,
      setProductLookupCategory,
      setProductLookupSubcategory,
      searchProductLookup,
      changeItemMaster
    }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductLookup);
