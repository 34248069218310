import React from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash.merge';
import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table-cve';

import {sortByDifferentField} from '../../../util/dateHelpers';

/**
 *
 *
 * @example Definition columns for sort and display by different fields (using sortBy key):
 * {
 *    ...
 *    dataId: 'created_at_formatted',
 *    sortBy: 'created_at',
 *    ...
 * },
 *
 * @example Definition columns for sort and display by different fields (using sortFunc key, key sortBy will be ignored):
 * {
 *    ...
 *    dataId: 'created_at_formatted',
 *    sortFunc: (a, b, order) => {
 *      if (order === 'desc') {
 *        return b.created_at.localeCompare(a[fieldName]);
 *      } else {
 *        return a.created_at.localeCompare(b[fieldName]);
 *      }
 *    },
 *    ...
 * },
 */
class TableComponent extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.filter = this.filter.bind(this);
    this.shouldRenderData = this.shouldRenderData.bind(this);

    this.ref = React.createRef();
  }

  shouldComponentUpdate (nextProps) {
    return this.shouldRenderData(nextProps);
  }

  /**
   * Purpose:  To prevent flashes of incomplete data when assembling data in selectors from multiple
   * async calls.
   *
   * Used in conjunction with a renderData property on the objects in the data collection.
   * Add a renderData property to your data set once all data is combined in selectors.
   * Add a useRenderDataFlag property to the TablePageWrapper component at the top of the grid component chain.
   * @param nextProps
   * @returns {boolean|*}
   */
  shouldRenderData (nextProps = false) {
    if (!nextProps) {
      nextProps = this.props;
    }
    const {useRenderDataFlag} = nextProps;
    if (!useRenderDataFlag) {
      return true;
    }
    const data = get(nextProps, 'data', []);
    const renderData = get(data, '0.renderData', false);
    return !data.length || (data.length && renderData);
  }

  filter (filter) {
    this.ref.current.handleFilterData(filter);
  }

  getCurrentDisplayData () {
    return this.ref.current.store.getCurrentDisplayData();
  }

  handleSearch(value) {
    this.ref.current.handleSearch(value);
  }

  handleExportCSV () {
    this.ref.current.handleExportCSV();
  }

  handlePaginationData(page, sizePerPage) {
    this.ref.current.handlePaginationData(page, sizePerPage);
  }

  getNoDataText () {
    const {data, dataState} = this.props;
    if (!dataState || (!data.length && dataState === 'loaded')) {
      return I18n.t('grid.noResults');
    }
    return I18n.t(`grid.${dataState}`);
  }

  render() {
    const { keyField, data, columns, searchString, selectedRows, sizePerPage, handleSelect, bstProps, remoteOptions, noSelectionMode, showSelectionMode } = this.props;
    const showCurrentTo = (start, dataTotalSize, sizePerPage  ) => {
      const sizePerPageNumber = Number(sizePerPage);
      return (start + sizePerPageNumber > dataTotalSize) ? dataTotalSize : (start + sizePerPageNumber);
    };
    const defaultProps = {
      keyField: keyField || 'id',
      data,
      remote: Boolean(remoteOptions),
      condensed: true,
      striped: true,
      bordered: false,
      hover: true,
      pagination: true,
      ignoreSinglePage: true,
      defaultSearch: searchString,
      selectRow: {
        selected: selectedRows,
        onSelect: (row, isSelected) => handleSelect(isSelected, [row]),
        onSelectAll: handleSelect,
        mode: 'checkbox',
        clickToSelect: true
      },
      options: {
        sizePerPage: sizePerPage,
        hideSizePerPage: true,
        paginationShowsTotal: (start, to, total) => {
          if (!total) return '';
          return I18n.t('grid.pagination', {start: start , to: to, total: total});
        },
        noDataText: this.getNoDataText(),
        noAutoBOM: false,
      }
    };

    if(remoteOptions) {
      delete defaultProps.defaultSearch;
      defaultProps.fetchInfo = {dataTotalSize: remoteOptions.dataTotalSize};
      defaultProps.options.page = remoteOptions.page;
      defaultProps.options.onPageChange = remoteOptions.onPageChange;
      defaultProps.options.onSortChange = remoteOptions.onSortChange;
      defaultProps.options.pageStartIndex = 1;
      defaultProps.options.paginationShowsTotal = () => {
        if (!remoteOptions.dataTotalSize) return '';
        return I18n.t('grid.pagination', {
          start: remoteOptions.start + 1,
          to: showCurrentTo(remoteOptions.start, remoteOptions.dataTotalSize, sizePerPage),
          total: remoteOptions.dataTotalSize
        });
      };
    }

    if(noSelectionMode || !showSelectionMode) {
      delete defaultProps.selectRow;
      if(typeof bstProps === 'object') delete bstProps.selectRow;
    }
    const props = merge({}, defaultProps, bstProps);

    return (
      <BootstrapTable ref = {this.ref} {...props} version='4'>

        {columns.filter(column => column.hasPermission !== false).map((column, index) => {

          return (<TableHeaderColumn
              key={index}
              dataField={column.dataId}
              columnClassName={column.columnClassName}
              className={column.className}
              dataSort={column.dataSort !== undefined ? column.dataSort : true}
              dataFormat={column.formatter}
              sortFunc={column.sortFunc || (column.sortBy ? sortByDifferentField(column.sortBy) : undefined) || undefined}
              dataAlign={column.dataAlign || 'center'}
              headerAlign={column.headerAlign || 'center'}
              width={column.width}
              expandable={column.expandable}
              hidden={column.hidden}
              csvHeader={column.name ? I18n.t(column.name) : ''}
              searchable={column.searchable !== false}
              csvFormat={column.csvFormatter ? column.csvFormatter : column.formatter}>
              {column.childComponent ? <column.childComponent/> : (column.name ? I18n.t(column.name) : '')}
              </TableHeaderColumn>);
        }
        )}
      </BootstrapTable>
    );
  }
}

TableComponent.propTypes = {
  bstProps: PropTypes.object,
  keyField: PropTypes.string,
  sizePerPage: PropTypes.number,
  remoteOptions: PropTypes.shape({
    onPageChange: PropTypes.func,
    onSortChange: PropTypes.func,
    dataTotalSize: PropTypes.number,
    page: PropTypes.number,
  }),
  searchString: PropTypes.string,
  selectedRows: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  dataState: PropTypes.string,
  columns: PropTypes.array.isRequired,
  handleSelect: PropTypes.func.isRequired,
  noSelectionMode: PropTypes.bool,
  showSelectionMode: PropTypes.bool,
};

export default TableComponent;
