/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {push} from 'react-router-redux';
import get from 'lodash.get';
import {arrayRemoveAll, change, destroy} from 'redux-form';
import {setRules} from '../../../../actions/ruleActions';
import rules from '../../purchase-orders/RenderRules';
import {getUrlParam} from '../../../../util/routeHelper';
import {addMessage} from '../../../../actions/systemActions';
import {getItemMasterChildren as getItemMasterChildrenAction} from '../../../../actions/itemMasterActions';
import {
  getDataBatchByPost,
  getDataByPost,
  getItem,
  getSearchData,
  getUnpaginatedData,
  postItem,
  getDataBatch
} from '../../../../actions/apiActions';
import {setData, unsetData} from '../../../../actions/dataActions';
import {unsetItem} from '../../../../actions/itemActions';
import {handleComplexSelectRow} from '../../../../actions/helpers/selectedDataHelper';
import {
  getItemMasterWithPricing,
  getSalesOrderItemMasterById
} from '../../../../selectors/itemMastersSelectors';
import {getPartnerFacilities} from '../../../../selectors/partnersSelectors';
import ModalWrapper from '../../../common/ModalWrapper';
import {
  getIsSalesOrderMedicated,
  getOrderTypeOptions,
  getTransferLinesLimit
} from '../../../../selectors/salesOrdersSelectors';
import {getAvailableRegisters} from '../../../../selectors/registersSelectors';
import {getCurrentFacilityId} from '../../../../selectors/facilitiesSelectors';
import {
  getRenderFacts,
  getInitialValues,
  filterPartnersBySalesOrderType,
  getOrderType,
  getPartnerFacility
} from '../../../../selectors/forms/salesOrderFormSelectors';
import {getCurrentFacilityUserOptions} from '../../../../selectors/usersSelectors';
import flattenLocations from '../../../../util/flattenLocations';
import * as dataNames from '../../../../constants/dataNames';
import * as itemNames from '../../../../constants/itemNames';
import * as messageTypes from '../../../../constants/messageTypes';
import * as p from '../../../../constants/permissions';
import * as inventory from '../../../../selectors/inventory';
import * as complianceSettings from '../../../../selectors/complianceSettingsSelectors';
import {SALES_ORDER_FORM} from '../../../../constants/forms';
import OrderForm from '../common/OrderForm'; //eslint-disable-line import/no-named-as-default
import FormWrapper from '../../../common/form/FormWrapper';
import {getIntegrationState} from '../../../../selectors/integration/integrationSelectors';
import {getReservationsQtyByItemMaster} from '../../../../selectors/reservationsSelectors';
import {isMappingRequiredForSupply} from '../../../../selectors/supplySelectors';
import {preparePayload} from '../common/helpers';
import { getUseEntityLocksForItems } from '../../../../selectors/coreSettingsSelectors';



export class CreateSalesOrderPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.state = {
      afterSubmit: false,
      matchingOrderWarnings: false,
      salesOrderSaved: false,
      showNegativeInvConfirmation: {
        show: false,
        onHide: () => {},
      },
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.validateNegativeInventory = this.validateNegativeInventory.bind(this);
    this.showConfirmationPopup = this.showConfirmationPopup.bind(this);
    this.onChangeLocalState = this.onChangeLocalState.bind(this);
    this.onTransfer = this.onTransfer.bind(this);
    this.goBack = this.goBack.bind(this);
    this.onSetPartner = this.onSetPartner.bind(this);
    this.renderConfirmModal = this.renderConfirmModal.bind(this);
  }

  componentWillMount() {
    const {useEntityLocks} = this.props;
    this.props.actions.unsetItem(itemNames.salesOrder);
    this.props.actions.unsetItem(itemNames.transfer);
    this.props.actions.destroy(SALES_ORDER_FORM);
    this.props.actions.getUnpaginatedData('/api/payment_terms/payment_terms_list', dataNames.paymentTerms, {failed:'paymentTerms.get.failed'});
    this.props.actions.getItem('/api/compliance_settings', itemNames.complianceSettings);
    this.props.actions.getUnpaginatedData('/api/payment_types', dataNames.paymentTypes, {failed:'paymentTypes.get.failed'});
    this.props.actions.getDataByPost('/api/registers/ensure', {type: 'ap_ar'}, dataNames.registers, {failed:'registers.getRegisters.ap_ar_inactive'});
    this.props.actions.getUnpaginatedData('/api/partners', dataNames.partners, {failed:'partners.get.failed'}, {exclude_internal_duplicates: 1})
      .then( (result) => {
        const partners = filterPartnersBySalesOrderType(result, this.props.orderType);

        //If we have one partner set it by default
        if(partners.length === 1) {
          this.onSetPartner(partners[0].id);
        } else {
          //If a partner was passed in the URL, use that for partner & facilty
          const org_data = getUrlParam('org');
          if (org_data) {
            this.onSetPartner(parseInt(org_data.toString().split(':').shift()));
            this.props.actions.change(SALES_ORDER_FORM, 'partner_facility_id', parseInt(org_data.toString().split(':').pop()));
          }
        }
      });
    this.props.actions.getUnpaginatedData('/api/categories', dataNames.categories),
    this.props.actions.getUnpaginatedData('/api/partner_facilities', dataNames.partnerFacilities, {failed:'partnerFacilities.get.failed'});
    this.props.actions.getUnpaginatedData('/api/users/current_facility', dataNames.currentFacilityUsers, {failed: 'packaging.getUsers.failed'});
    this.props.actions.getUnpaginatedData('/api/sales_orders/sample_types', dataNames.sampleTypes);
    this.props.actions.getUnpaginatedData('/api/sales_orders/product_sample_types', dataNames.productSampleTypes);
    this.props.actions.getItem(
      '/api/cultivation/settings',
      itemNames.wasteCompliance,
      {failed: 'cultivation.wasteCompliance.get.failed'},
      {ids: ['cult_waste_destruction_compliance_settings', 'inv_waste_packaging', 'distributions_transfer_line_limit']}
    );
    // Use solr to get item masters for speed gain
    if(typeof this.props.actions.getSearchData === 'function') { // required to pass automated testing... believes its not a function
      const params = {
        sort: 'name asc, display_name asc',
        query: 'matchall',
        size: '100000',
        start: '0',
        filter: '(active:1 OR item_active:true) AND is_draft:0 AND item_master_parent_id:0',
        fields: [
          'id',
          'name',
          'display_name',
          'is_medicated',
          'category_id',
          'lot_tracked',
          'is_prepack',
          'item_master_parent_id',
          'prepack_weight_id',
          'is_sales_item',
          'subcategory_code',
          'uom_type',
          'default_uom',
          'active',
          'is_inventory_item',
          'item_active',
        ],
      };

      this.props.actions.getSearchData('/api/search/item_masters', null , null, params,
        (data) => {
          if(data.length === 0) return this.props.actions.addMessage(messageTypes.error, 'products.get.failed');
          const masters = data.map((master) => {
            master.inventory_attributes = {
              lot_tracked: master.lot_tracked,
              is_prepack: master.is_prepack,
              item_master_parent_id: master.item_master_parent_id,
              prepack_weight_id: master.prepack_weight_id
            };
            return master;
          });
          this.props.actions.setData(masters, dataNames.itemMasters);
        });
    }

    // Load waste packages to populate product list with item masters that have active waste packages.
    // Used for sale to "Waste disposal" partners.
    const lockedParam = (useEntityLocks) ? '&is_locked=0' : '';
    this.props.actions.getUnpaginatedData(`/api/items?is_waste=1${lockedParam}`, dataNames.wastePackages);

    this.props.actions.getUnpaginatedData('/api/location_hierarchy', dataNames.locations, {failed:'locations.getLocations.failed'});
    this.props.actions.getUnpaginatedData('/api/prepack_weights/facility', dataNames.prepackFacilityWeights, {failed:'packaging.getPrepackWeights.failed'}, {active: 1});
    this.props.actions.getUnpaginatedData('/api/prepack_weights', dataNames.prepackWeights, {failed:'packaging.getPrepackWeights.failed'});
    this.props.actions.getItem('/api/integration-settings', itemNames.integrationSettings, {failed: 'stateIntegratorSettings.get.failed'});
    this.props.actions.getItem('/api/supply/settings', itemNames.supplySettings);
    this.props.actions.getItem('/api/distribution/settings', itemNames.distributionSettings);
    this.props.actions.getUnpaginatedData('/api/closed_loops/own', dataNames.closedLoops);
    this.props.actions.handleComplexSelectRow(undefined, dataNames.itemMasters, 'clear');
    this.props.actions.getItem('/api/facilities/' + this.props.currentFacilityId + '/details', itemNames.facilityDetailed).then((data) => {
      const has_leaf_pa_config_pack_and_closed_loop = get(data ,'has_leaf_pa_config_pack_and_closed_loop', false);
      this.props.actions.setData(has_leaf_pa_config_pack_and_closed_loop, dataNames.has_leaf_pa_config_pack_and_closed_loop);

      const has_leaf_utah_config_pack_and_closed_loop = get(data ,'has_leaf_utah_config_pack_and_closed_loop', false);
      this.props.actions.setData(has_leaf_utah_config_pack_and_closed_loop, dataNames.has_leaf_utah_config_pack_and_closed_loop);

      const vendor_facility_type = (typeof data.type === 'undefined') ? '' : data.type;

      const format_vendor_facility_type = (vendor_facility_type) => {
        if (vendor_facility_type === 'grow') {
          return 'cultivator';
        } else if (vendor_facility_type === 'manufacturing') {
          return 'processor';
        } else {
          return vendor_facility_type;
        }
      };

      const formatted_vendor_facility_type = format_vendor_facility_type(vendor_facility_type);

      this.props.actions.setData(formatted_vendor_facility_type, dataNames.vendor_facility_type);
    });
  }
  componentWillReceiveProps(nextProps){
    this.props.actions.setRules(rules)
      .then((engine) => {
        engine.setFacts(nextProps.renderFacts);
      });
  }

  // TODO: TGC-54 - Remove or rename this function and its usages in componentWillMount
  onSetPartner(id) {
    this.props.actions.change(SALES_ORDER_FORM, 'partner_id', id);
  }

  onChangeLocalState(field, value){
    return new Promise((resolve) => {
      this.setState(Object.assign({}, this.state, {[field]: value}), () => {
        resolve();
      });
    });
  }

  onTransfer(){
    this.props.actions.unsetData(dataNames.salesOrders);
    this.props.actions.push({pathname: '/transfers/create', query: {so_num: [this.props.salesOrder.id]}});
  }

  goBack(){
    this.props.actions.push('sales-orders/active');
  }

  showConfirmationPopup(resolve, reject) {
    const hidePopup = (cb) => () => {
      this.setState({
        showNegativeInvConfirmation: { show: false },
      });
      cb();
    };
    this.setState({
      showNegativeInvConfirmation: {
        show: true,
        onConfirm: hidePopup(resolve),
        onHide: hidePopup(reject),
      }
    });
  }

  validateNegativeInventory(payload) {
    return new Promise((resolve, reject) => {
      const next = () => resolve(payload);
      const handleOrderLines = (result, orderLine) => {
        const inventories = orderLine.subitems && orderLine.subitems.length
          ? this.props.getInventoryBy('item_master_parent_id', orderLine.item_master_id)
          : this.props.getInventoryBy('item_master_id', orderLine.item_master_id);
        const requestedQnty = parseFloat(orderLine.qty);
        const availableQnty = inventories.reduce((amount, { qty }) => amount + qty, 0);
        const reservedQty = this.props.getQtyReserved(orderLine.item_master_id);
        if (requestedQnty > availableQnty - reservedQty) {
          result = true;
        }

        return result;
      };

      const showPopup = payload.lines.reduce(handleOrderLines, false);

      if (!showPopup || !this.props.isAllowNegativeInventory) {
        return next();
      }

      return this.showConfirmationPopup(next, reject);
    });
  }


  onSubmit(formData) {
    const payload = preparePayload(formData, true, this.props);

    return Promise.resolve(payload)
      .then(this.validateNegativeInventory)
      .then(this.sendRequest);
  }

  sendRequest(payload) {
    const messages = {failed: 'salesOrders.create.fail', success: 'salesOrders.create.success'};
    return this.props.actions.postItem('/api/sales_orders', payload, itemNames.salesOrder, messages, {}, (data) => {
      this.props.actions.handleComplexSelectRow([data], dataNames.salesOrders, 'set');
      if (data.warnings){
        this.setState({matchingOrderWarnings: data.warnings, salesOrderSaved: true});
        return false;
      }
      const completeTransferStates = ['completeTransfer'];
      if(completeTransferStates.indexOf(this.state.afterSubmit) === -1) {
        this.props.actions.unsetItem(itemNames.salesOrder);
        this.props.actions.destroy(SALES_ORDER_FORM);
        return this.goBack();
      }
      this.props.actions.unsetData(dataNames.salesOrders);
      this.props.actions.push({pathname: '/transfers/create', query: {so_num: data.id}});
      });
  }

  getTitle() {
    switch (this.props.orderType) {
      case 'lab':
        return 'salesOrders.create.labTitle';
      case 'waste_disposal':
        return 'salesOrders.create.wasteDisposalTitle';
      default:
        return 'salesOrders.create.title';
    }
  }

  renderConfirmModal() {
    const { showNegativeInvConfirmation } = this.state;

    const okayButtonProps = {
      show: true,
      onClick: showNegativeInvConfirmation.onConfirm,
      text: I18n.t('general.yes')
    };

    const cancelButtonProps = {
      show: true,
      onClick: showNegativeInvConfirmation.onHide,
      text: I18n.t('general.no')
    };

    return (
      <ModalWrapper
        Component={false}
        title={I18n.t('inventory.negativeInventoryAlert')}
        headerClass='bg-info-dark'
        onHide={showNegativeInvConfirmation.onHide}
        showModal={showNegativeInvConfirmation.show}
        okayButton={okayButtonProps}
        cancelButton={cancelButtonProps}
        dialogClassName='modal-sm'
        version={2}
      >
        <p>{I18n.t('inventory.confirmToGoNegative')}</p>
      </ModalWrapper>
    );
  }

  render () {
    const {partners, containers, locations, registers, paymentTerms, getSellableItemMasterById, paymentTypes,
      partnerFacilities, selectedPrePackChildren, inventoryList, orderTypes, users, isMappingRequiredByPlatform,
      hasMedicatedLines, integrationState, renderFlags, transferLinesLimit, isReservationEnabled, initialValues,
      has_leaf_pa_config_pack_and_closed_loop, has_leaf_utah_config_pack_and_closed_loop, vendor_facility_type, categories} = this.props;

      // console.log('categories', categories);
    return (
    <FormWrapper title={this.getTitle()} goBack={this.goBack} className='create-purchase-order'>
      <OrderForm
        form={SALES_ORDER_FORM}
        keepDirtyOnReinitialize={true}
        enableReinitialize={false}
        onSubmit={this.onSubmit}
        initialValues={initialValues}
        integrationState={integrationState}
        paymentTerms={paymentTerms}
        paymentTypes={paymentTypes}
        orderTypes={orderTypes}
        partnerFacilities={partnerFacilities}
        partners={partners}
        containers={containers}
        locations={locations}
        categories={categories}
        registers={registers}
        selectedPrePackChildren={selectedPrePackChildren}
        showContainerInputs={false}
        showStorageLocations={false}
        getSellableItemMasterById={getSellableItemMasterById}
        transferRecord={false}
        inventoryList={inventoryList}
        onChangeLocalState={this.onChangeLocalState}
        localState={this.state}
        requireSalesOrder={false}
        users={users}
        change={this.props.actions.change}
        facilityDetailed={this.props.facilityDetailed}
        actions={{getItem: this.props.actions.getItem, onTransfer: this.onTransfer, onBack: this.goBack}}
        matchingOrderWarnings={this.state.matchingOrderWarnings}
        salesOrderSaved={this.state.salesOrderSaved}
        transferLinesLimit={transferLinesLimit}
        renderFlags={renderFlags}
        isReservationEnabled={isReservationEnabled}
        isMappingRequiredByPlatform={isMappingRequiredByPlatform}
        hasMedicatedLines={hasMedicatedLines}
        has_leaf_pa_config_pack_and_closed_loop ={has_leaf_pa_config_pack_and_closed_loop}
        has_leaf_utah_config_pack_and_closed_loop = {has_leaf_utah_config_pack_and_closed_loop}
        vendor_facility_type = {vendor_facility_type}
      />
      {this.renderConfirmModal()}
    </FormWrapper>);
  }
}

CreateSalesOrderPage.propTypes = {
  actions: PropTypes.shape({
    addMessage: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    handleComplexSelectRow: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    arrayRemoveAll: PropTypes.func.isRequired,
    getSearchData: PropTypes.func.isRequired,
    getDataBatchByPost: PropTypes.func.isRequired,
    setData: PropTypes.func.isRequired,
    getDataBatch: PropTypes.func.isRequired,
    getItemMasterChildrenAction: PropTypes.func.isRequired
  }),
  selectedPrePackChildren: PropTypes.array.isRequired,
  getSellableItemMasterById: PropTypes.func.isRequired,
  itemMasterWithPricing: PropTypes.object.isRequired,
  orderTypes: PropTypes.array.isRequired,
  partners: PropTypes.array,
  partnerFacilities: PropTypes.array.isRequired,
  partnerFacilityOptions: PropTypes.array,
  containers: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  registers: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  paymentTerms: PropTypes.array.isRequired,
  paymentTypes: PropTypes.array.isRequired,
  inventoryList: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  getInventoryBy: PropTypes.func,
  isAllowNegativeInventory: PropTypes.bool,
  isReservationEnabled: PropTypes.bool,
  isMappingRequiredByPlatform: PropTypes.bool,
  hasMedicatedLines: PropTypes.bool,
  transferLinesLimit: PropTypes.number.isRequired,
  renderFlags: PropTypes.object,
  renderFacts: PropTypes.object,
  getQtyReserved: PropTypes.func.isRequired,
  has_leaf_pa_config_pack_and_closed_loop: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  has_leaf_utah_config_pack_and_closed_loop: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  vendor_facility_type: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

function mapStateToProps(state) {
  const {containers, locations, paymentTerms, paymentTypes,
    selectedItemMasters, timezone, salesOrder, has_leaf_pa_config_pack_and_closed_loop, has_leaf_utah_config_pack_and_closed_loop, vendor_facility_type} = state;

  return {
    timezone,
    orderTypes: getOrderTypeOptions(state),
    partnerFacilities: getPartnerFacilities(state),
    partnerFacility: getPartnerFacility(state) || {},
    containers,
    locations: flattenLocations(locations),
    registers: getAvailableRegisters(state),
    paymentTerms,
    paymentTypes,
    inventoryList: inventory.getInventoryList(state),
    getInventoryBy: inventory.getInventoryBy(state),
    isAllowNegativeInventory: complianceSettings.isAllowNegativeInventory(state),
    isReservationEnabled: complianceSettings.getIsReservationEnabled(state),
    isMappingRequiredByPlatform: isMappingRequiredForSupply(state),
    hasMedicatedLines: getIsSalesOrderMedicated(state),
    getSellableItemMasterById: itemMaster => getSalesOrderItemMasterById(state, itemMaster),
    selectedPrePackChildren: selectedItemMasters,
    orderType: getOrderType(state),
    users: getCurrentFacilityUserOptions(state),
    itemMasterWithPricing: getItemMasterWithPricing(state),
    integrationState: getIntegrationState(state),
    transferLinesLimit: getTransferLinesLimit(state),
    salesOrder,
    renderFacts: getRenderFacts(state, {permissionKey: 'manage_connects'}),
    renderFlags: state[itemNames.rules].default || {},
    // NOTE: TGC-54 - would like to move this to OrderForm, but there's a dependency in ModifySalesOrderPage that needs to be dealt with
    initialValues: getInitialValues(state),
    getQtyReserved: item_master_id => getReservationsQtyByItemMaster(state, {item_master_id}),
    currentFacilityId: getCurrentFacilityId(state),
    facilityDetailed: state.facilityDetailed,
    useEntityLocks: getUseEntityLocksForItems(state),
    has_leaf_pa_config_pack_and_closed_loop,
    has_leaf_utah_config_pack_and_closed_loop,
    vendor_facility_type,
    categories: state[dataNames.categories],
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    addMessage,
    push,
    getUnpaginatedData,
    getItem,
    postItem,
    getSearchData,
    setData,
    unsetItem,
    getDataBatchByPost,
    getDataByPost,
    handleComplexSelectRow,
    setRules,
    change,
    destroy,
    arrayRemoveAll,
    getItemMasterChildrenAction,
    getDataBatch,
    unsetData
  };
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (CreateSalesOrderPage);
