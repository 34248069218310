export const BT_PRODUCER_TIER_1 = 1;
export const BT_PRODUCER_TIER_2 = 2;
export const BT_PRODUCER_TIER_3 = 3;
export const BT_PRODUCER_TIER_1_PROCESSOR = 4;
export const BT_PRODUCER_TIER_2_PROCESSOR = 5;
export const BT_PRODUCER_TIER_3_PROCESSOR = 6;
export const BT_PROCESSOR = 7;
export const BT_RETAILER = 8;

export const biotrackLicenseOptions = [
  {value: BT_PRODUCER_TIER_1, text: 'Producer Tier 1'},
  {value: BT_PRODUCER_TIER_2, text: 'Producer Tier 2'},
  {value: BT_PRODUCER_TIER_3, text: 'Producer Tier 3'},
  {value: BT_PRODUCER_TIER_1_PROCESSOR, text: 'Producer Tier 1 + Processor'},
  {value: BT_PRODUCER_TIER_2_PROCESSOR, text: 'Producer Tier 2 + Processor'},
  {value: BT_PRODUCER_TIER_3_PROCESSOR, text: 'Producer Tier 3 + Processor'},
  {value: BT_PROCESSOR, text: 'Processor'},
  {value: BT_RETAILER, text: 'Retailer'},
];

export const btProducerLocationTypes = [
  BT_PRODUCER_TIER_1,
  BT_PRODUCER_TIER_2,
  BT_PRODUCER_TIER_3,
  BT_PRODUCER_TIER_1_PROCESSOR,
  BT_PRODUCER_TIER_2_PROCESSOR,
  BT_PRODUCER_TIER_3_PROCESSOR,
];

export const btProcessorLocationTypes = [
  BT_PROCESSOR,
];

export const btRetailerLocationTypes = [
  BT_RETAILER,
];
