import React from 'react';
import PropTypes from 'prop-types';

const NumericInputControl = ({name, value, onChange, onBlur, fractionPartSize, allowNegativeNumber, shouldRound, dirty, disabled, readOnly, ...props}) => {

  if (shouldRound && (!dirty || disabled || readOnly) && typeof value === 'number') value = Number(value.toFixed(fractionPartSize));

  const parseHandle = event => {
    const {value} = event.target;
    const [integerPart, decimalPart] = value.split('.');
    const regex = allowNegativeNumber ? /^-?\d*\.?\d*$/ : /^\d*\.?\d*$/;
    if((regex.test(value)) || !value){
      if (decimalPart && fractionPartSize !== 0) {
        onChange(`${integerPart}.${decimalPart.slice(0, fractionPartSize)}`);
      } else if(fractionPartSize === 0){
        onChange(integerPart);
      }else{
        onChange(value);
      }
    } else {
      event.preventDefault();
    }
  };

  // Input type="number" is not fully controlled element in Chrome,
  // it doesn't fire onInput/onChange events when its content becomes an invalid number such as:
  // '1-1', '1+1'
  // So we need this extra methods to sanitize user input.
  const handleKeypress = event => {
    if (event.key === '+' || event.key.toUpperCase() === 'E') {
      event.preventDefault();
    }
    if (!allowNegativeNumber && event.key === '-') {
      event.preventDefault();
    }
  };
  const handlePaste = event => {
    const value = (event.clipboardData || window.clipboardData).getData('text');
    const regex = allowNegativeNumber ? /^-?[\d.]*$/ : /^[\d.]*$/;
    if(!regex.test(value)) {
      event.preventDefault();
    }
  };

  props.className = 'integer-part form-control' + (props.className ? ' ' + props.className : '');

  return (
    <div className='decimal-numeric-input'>
      <input
        name={name}
        value={value === false ? null : value}
        disabled={disabled}
        readOnly={readOnly}
        onChange={parseHandle}
        onKeyPress={handleKeypress}
        onPaste={handlePaste}
        onBlur={event => {
          onBlur(shouldRound && !dirty ? undefined : event);
        }}
        {...props}
      />
    </div>
  );
};

NumericInputControl.defaultProps = {
  fractionPartSize: 2,
  shouldRound: false,
  disabled: false,
  readOnly: false,
};

NumericInputControl.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  fractionPartSize: PropTypes.number,
  name: PropTypes.string.isRequired,
  lastChar: PropTypes.string,
  allowNegativeNumber: PropTypes.bool,
  shouldRound: PropTypes.bool,
  onBlur: PropTypes.func,
  dirty: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool
};

export default NumericInputControl;
