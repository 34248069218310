import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {Tab, Row, Col, Nav, Badge, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import capitalize from 'lodash.capitalize';
import routeDefinitions from '../../routes/routeDefinitions';
import {userHasPermission} from '../../selectors/usersSelectors';
import * as systemActions from '../../actions/systemActions';

import ModalWrapper from '../common/ModalWrapper';
import MyPermissions from './MyPermissions';

export class LinkPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.state = {showModal: false, permissions: {}};
  }

  render() {
    const {showModal} = this.state;
    const {permissions, definedRoutes, user} = this.props;

    const style = {
      disabled: 'default',
      production: 'success',
      hardcoded: 'warning',
      wireframe: 'danger',
    };

    return (
      <div className='links-page'>
        <Tab.Container id='links-page-container'>
          <Row className='clearfix'>
            <Col sm={3}>
              <Nav variant='pills' stacked>
                {Object.keys(definedRoutes).map((item) =>
                  <Nav.Item key={item} eventKey={item}>
                    {capitalize(item)}
                  </Nav.Item>
                )}
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content animation>
                {Object.keys(definedRoutes).map((item) =>
                  <Tab.Pane key={item} eventKey={item} className='pane'>
                    {Object.keys(definedRoutes[item]).map((route) =>
                      <LinkContainer  key={route} to={definedRoutes[item][route].link || definedRoutes[item][route].path}>
                        <Nav.Item className='col-xs-3'>
                          <h4>
                            <OverlayTrigger placement='bottom' overlay={<Tooltip id='route'>{'Permissions: ' + JSON.stringify(definedRoutes[item][route].permissions)}</Tooltip>}>
                              <Badge variant={style[definedRoutes[item][route].status]} style={{width:'100%'}}>{route}</Badge>
                            </OverlayTrigger>
                          </h4>
                        </Nav.Item>
                      </LinkContainer>

                    )}
                  </Tab.Pane>
                )}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
        <div>
          Key:
            <div><Badge>Disabled</Badge> You don't have permission for that Page.</div>
            <div><Badge variant='success'>Production</Badge> Production use, this page is working.</div>
            <div><Badge variant='warning'>Hardcoded</Badge> Hardcoded elements exist on the page (Under Development).</div>
            <div><Badge variant='danger'>Wireframe</Badge> This is a conceptualization of how the page will work, it doesn't actually work.</div>
            <div><a onClick={() => {this.setState({showModal: true});}}>Check My Permissions</a></div>
        </div>
        <ModalWrapper
          Component={MyPermissions}
          onHide={() => this.setState({showModal: false})}
          showModal={showModal}
          title='MyPermissions'
          permissions={permissions}
          user={user}
        />
      </div>
    );
  }
}

LinkPage.propTypes = {
  actions: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
  definedRoutes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

function mapStateToProps(state){
  const {user, facility} = state;
  const {permissions} = user;
  const routes = routeDefinitions;

  Object.keys(routes).forEach((item) => {
    Object.keys(routes[item]).forEach((name) => {
      const route = routes[item][name];
      route.status = userHasPermission(state, route) ? route.status : 'disabled';
    });
  });
  return {
    definedRoutes: routes,
    permissions: permissions[facility.key].permissions,
    user: user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(systemActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (LinkPage);
