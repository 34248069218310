import {addMessage} from './systemActions';
import {openUserOptsMenu} from './menuActions';
import * as dataNames from '../constants/dataNames';
import * as apiActions from './apiActions';

export function ensureRegister() {
  return (dispatch, getState) => {
    const state = getState();
    if(!state.user.currentRegister) {
      dispatch(addMessage('warning', ['error.selectRegisters']));
      dispatch(openUserOptsMenu());
      return false;
    }
    return true;
  };
}

export const fetchEnsureRegistries = (params = {}) => {
  return apiActions.getDataByPost('/api/registers/ensure', params, dataNames.registers, {failed:'registers.getRegisters.fail'});
};

export const dummy = () => {};
