import {createSelector} from 'reselect';

import * as dataNames from '../constants/dataNames';
import getSelectedDataName from '../constants/helpers/getSelectedDataName';

export const getCustomerGroups = (state) => state[dataNames.customerGroups];
export const getSelectedCustomerGroups = state => state[getSelectedDataName(dataNames.customerGroups)];

const getConsumerGroupId = (_, props) => props.consumer_group_id;

export const getCustomerGroupById = createSelector(
  getCustomerGroups, getConsumerGroupId,
  (groups, id) => groups.reduce((previous, current) => id == current.id ? current : previous, null)
);

export const getSelectedCustomerGroupsIds = createSelector(
  getSelectedCustomerGroups,
  groups => groups.map(group => group.id)
);
