import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import SimpleTable from '../common/grid/SimpleTable';
import ModalWrapper from '../common/ModalWrapper';
import RouteButton from '../common/RouteButton';
import {getItem} from '../../actions/apiActions';
import {getTestResultsByPackageIds} from '../../selectors/testResultsSelectors';
import {formatDate} from '../../util/dateHelpers';

import {isLeafIntegrator} from '../../selectors/integration/leafSelectors';
import {getIntegrationState} from '../../selectors/integration/integrationSelectors';

class LabsByHarvest extends React.PureComponent {

  constructor(props, context) {
    super(props, context);

    const columns = [{ // Status always present
      name: I18n.t('cultivation.testResults.status'),
      dataId: 'status',
      hidden: false,
      dataSort: true,
      formatter: (cell) => cell ? cell : I18n.t('cultivation.testResults.noStatus')
    }].concat(
      props.integrationState.isWaLeaf // Include eligible flags if Leaf WA only
        ? [{
          name: I18n.t('cultivation.testResults.retestEligible'),
          dataId: 'retest_eligible',
          hidden: !props.integrationState.isWaLeaf,
          dataSort: true,
          formatter: (cell) => !isNaN(parseInt(cell)) ? parseInt(cell) === 1 ? 'Yes' : 'No' : 'No'
        },
          {
            name: I18n.t('cultivation.testResults.extractionEligible'),
            dataId: 'extraction_eligible',
            hidden: !props.integrationState.isWaLeaf,
            dataSort: true,
            formatter: (cell) => !isNaN(parseInt(cell)) ? parseInt(cell) === 1 ? 'Yes' : 'No' : 'No'
          }]
        : [],
      [ // Include balance of columns
        {
          name: I18n.t('cultivation.testResults.source'),
          dataId: 'state_integration_source',
          hidden: false,
          dataSort: true,
          formatter: (cell) => cell ? I18n.t('cultivation.testResults.Integrator') : I18n.t('cultivation.testResults.inHouse')
        },
        {
          name: I18n.t('cultivation.testResults.partnerName'),
          dataId: 'partner_name',
          hidden: false,
          dataSort: true,
        },
        {
          name: I18n.t('cultivation.testResults.productName'),
          dataId: 'product_name',
          hidden: false,
          dataSort: true,
        },
        {
          name: I18n.t('cultivation.testResults.packageCode'),
          dataId: 'package_code',
          hidden: false,
          dataSort: true,
        },
        {
          name: I18n.t('cultivation.testResults.lotNumber'),
          dataId: 'lot_number',
          hidden: false,
          dataSort: true,
        },
        {
          name: I18n.t('cultivation.testResults.testingDate'),
          dataId: 'testing_date',
          hidden: false,
          dataSort: true,
          formatter: (val) => formatDate(val)
        },
        {
          name: I18n.t('cultivation.testResults.goToResults'),
          dataId: 'lab_results_id',
          hidden: false,
          dataSort: true,
          formatter: (lab_results_id) => (
            <RouteButton props={{bsSize: 'sm'}} path={`/test-results/view/${lab_results_id}`}>
              {I18n.t('cultivation.testResults.show')}
            </RouteButton>)
        }
      ]
    );
    this.state = {isFetching: false, showResults: false, columns};
    this.setLeafClass = this.setLeafClass.bind(this);
  }

  setLeafClass(row) {
    if (row.state_integration_source === 'leaf') {
      return 'integrated';
    }
    return '';
  }

  render () {
    const {testResults, showResults, hideTestResults} = this.props;
    return (
      <div>
        {testResults.length ?
          <ModalWrapper
            showModal={showResults}
            onHide={hideTestResults}
            title={'inventory.table.testResults'}
            Component={SimpleTable}
            data={testResults}
            columns={this.state.columns}
            bordered={false}
            compact={true}
            trClassName={this.setLeafClass}
          />
          : <div/>
        }
      </div>
    );
  }
}

LabsByHarvest.propTypes = {
  items: PropTypes.array.isRequired,
  showResults: PropTypes.bool,
  toggleResults: PropTypes.func,
  testResults: PropTypes.array,
  actions: PropTypes.object.isRequired,
  isLeaf: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {
  const testResults = getTestResultsByPackageIds(state, ownProps.items);
  return {
    ...ownProps,
    testResults,
    isLeaf: isLeafIntegrator(state),
    integrationState: getIntegrationState(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {getItem};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LabsByHarvest);
