import React from 'react';
import PropTypes from 'prop-types';
import {FaAngleDown, FaAngleRight} from 'react-icons/fa';
import NestedRecursiveList from './RecursiveList';

const RecursiveList = (props) => {

  const {expandedKey, skipThisProp, wildCardRegex, isExpanded, onExpand, prefix} = props;

  const classes = (!props.isListRoot) ? 'list-group list-group-collapse' : 'list-group list-group-collapse list-group-root well';

  // Exists here and at parent component recursive list.  They are similar but not the same.
  const excludeFilter = ['package_id', 'fulfillment_units'];
  const includeFilter = ['packaged_at', 'package_created_at', 'package_expires_at', 'package_use_by_at', 'vendor_facility_state_integration_id', 'vendor_facility_license_id', 'testing_id', 'state_id', 'state_integration_tracking_id'];

  return (
    <div className={isExpanded(expandedKey) ? classes : 'hide'}>
      {
        props.node.reduce( (acc, node) => {

          if(node === undefined) return acc;
          if(Object.keys(node).length === 0) return acc;

          Object.keys(node).forEach((prop) => {
            const skip = skipThisProp(prop, wildCardRegex, excludeFilter, includeFilter);
            if(skip){
              return null;
            }

            const indent = 15;
            const numIndents = !prefix ? 1 : prefix.split('.').length + 1;

            const style = {
              paddingLeft: (indent * numIndents) + 'px'
            };

            const propPrefix = !props.prefix ? '' : props.prefix + '.';

            if(typeof node[prop] === 'object' && node[prop] !== null){

              const nodePrefix = !prefix ? prop : prefix + '.' + prop;

              style.cursor = 'pointer';

              acc.push(
                <div key={acc.length}>
                  <div
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      props.onExpand(prop);
                    }}
                    style={style}
                    key={acc.length}
                    className='list-group-item'>
                    {props.isExpanded(prop)
                      ? <FaAngleDown style={{position: 'relative', left: '-5px'}}/>
                      : <FaAngleRight style={{position: 'relative', left: '-5px'}}/>
                    }
                    <em><strong>{propPrefix}</strong>{prop}</em>
                  </div>
                  <NestedRecursiveList
                    isExpanded={isExpanded}
                    onExpand={onExpand}
                    expandedKey={prop}
                    isListRoot={false}
                    prefix={nodePrefix}
                    skipThisProp={skipThisProp}
                    wildCardRegex={wildCardRegex}
                    node={[node[prop]]} />
                </div>
              );

            } else {

              acc.push(
                <a
                  style={style}
                  key={acc.length}
                  className='list-group-item'>
                  <em><strong>{propPrefix}</strong>{prop}</em>
                  <span className='float-right'>
                    {node[prop]}
                  </span>
                  <div style={{clear: 'both'}} />
                </a>
              );

            }
          });
          return acc;
        }, [])
      }
    </div>
  );

};

RecursiveList.propTypes = {
  onExpand: PropTypes.func.isRequired,
  isExpanded: PropTypes.func.isRequired,
  isListRoot: PropTypes.bool,
  node: PropTypes.array.isRequired,
  prefix: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]).isRequired,
  skipThisProp: PropTypes.func.isRequired,
  wildCardRegex: PropTypes.string.isRequired,
  expandedKey: PropTypes.string.isRequired,
};

export default RecursiveList;
