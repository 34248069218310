import React from 'react';
import PropTypes from 'prop-types';
import {Card, Button} from 'react-bootstrap';
import {FaChevronDown, FaChevronRight} from 'react-icons/fa';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import NumericInput from '../../common/form/NumericInput';

class Package extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {expanded: props.packages.map((lot, index) => !index)};
    this.toggleExpand = this.toggleExpand.bind(this);
    this.addToContainer = this.addToContainer.bind(this);
  }

  addToContainer(pkg, newQuantity = 0) {
    const {change} = this.props;
    change(`${pkg}.addedQuantity`, newQuantity);
  }

  toggleExpand(packageIndex) {
    const expanded = {...this.state.expanded};
    expanded[packageIndex] = !this.state.expanded[packageIndex];
    this.setState({expanded});
  }

  render() {
    const {packages, fields: {map}, getFormValue} = this.props;
    return (
      <div className='packages'>
        <div className='packages-header layout-row layout-align-start-center'>
          <div className='glyph-col'></div>
          <div className='package-id-col'><strong>{I18n.t('containers.form.packageId')}</strong></div>
          <div className='item-col'><strong>{I18n.t('containers.form.item')}</strong></div>
          <div className='unit-size-col'><strong>{I18n.t('containers.form.unitSize')}</strong></div>
          <div className='quantity-col'><strong>{I18n.t('containers.form.quantity')}</strong></div>
          <div className='uom-col'><strong>{I18n.t('containers.form.uom')}</strong></div>
          <div className='total-col'><strong>{I18n.t('containers.form.total')}</strong></div>
          <div className='uom-col'><strong>{I18n.t('containers.form.uom')}</strong></div>
        </div>
        {map((pkg, packageIndex) => {
          const currentPackage = packages[packageIndex];
          const newQuantity = getFormValue(`${pkg}.transacted_qty`);
          const originalTotal = currentPackage.qty && currentPackage.fulfillment_units
            ? (currentPackage.qty * currentPackage.fulfillment_units) / 100
            : currentPackage.qty && currentPackage.uom_display === 'GR'
              ? currentPackage.qty
              : 0;
          const transactedTotal = newQuantity && currentPackage.fulfillment_units
            ? (newQuantity * currentPackage.fulfillment_units) / 100
            : newQuantity && currentPackage.uom_display === 'GR'
              ? newQuantity
              : 0;
          const isAddingDisabled = newQuantity > currentPackage.qty;

          return (
            <div key={`package-${packageIndex}`} className={this.state.expanded[packageIndex] ? 'package-wrapper opened' : 'package-wrapper'}>
              <div className='package layout-row layout-align-start-center' onClick={() => {this.toggleExpand(packageIndex);}}>
                <div className='glyph-col'>{this.state.expanded[packageIndex] ? <FaChevronDown/> : <FaChevronRight/>}</div>
                <div className='package-id-col'>{currentPackage.package_code}</div>
                <div className='item-col'>{currentPackage.item_name}</div>
                <div className='unit-size-col'>{currentPackage.prepack_name}</div>
                <div className='main-row quantity-col'>{currentPackage.qty}</div>
                <div className='uom-col'>{currentPackage.uom_display}</div>
                <div className='total-col'>{originalTotal}</div>
                <div className='uom-col'>GR</div>
              </div>
              <Card collapsible expanded={this.state.expanded[packageIndex]}>
                <div className='layout-row layout-align-start-center'>
                  <div className='glyph-col'></div>
                  <div className='package-id-col'>{currentPackage.package_code}</div>
                  <div className='item-col'>{currentPackage.item_name}</div>
                  <div className='unit-size-col'>{currentPackage.prepack_name}</div>
                  <div className='quantity-col'>
                    <Field
                      name={`${pkg}.transacted_qty`}
                      component={NumericInput}
                      props={{
                        placeholder: '',
                        min: 0
                      }}
                    />
                  </div>
                  <div className='uom-col'>{currentPackage.uom_display}</div>
                  <div className='total-col'>{transactedTotal}</div>
                  <div className='uom-col'>GR</div>
                  <div style={{display: 'none'}} className='apply-button'>
                    <Button variant='primary' disabled={isAddingDisabled} onClick={() => {this.addToContainer(pkg, newQuantity);}}>
                      {I18n.t('containers.form.addToContainer')}
                    </Button>
                    {isAddingDisabled &&
                      <span className='error-message'>
                        {I18n.t('containers.form.errorMessage')}
                      </span>
                    }
                  </div>
                </div>
              </Card>
            </div>
          );
        })}
      </div>
    );
  }
}

Package.propTypes = {
  packages: PropTypes.array.isRequired,
  change: PropTypes.func.isRequired,
  getFormValue: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired
  }).isRequired
};

export default Package;
