import React from 'react';
import PropTypes from 'prop-types';
import {Row} from 'react-bootstrap';
import * as itemNames from '../../../../constants/itemNames';
import * as p from '../../../../constants/permissions';
import ManualDiscountReduxForm from './ManualDiscountReduxForm';
import PermissionWrapper from '../../../common/PermissionWrapper';

class ManualDiscountComponent extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(formData) {
    const {order, form, showLoader, hideLoader, actions: {postItem, reset}} = this.props;
    const payload = {
      ...formData,
      type: 'manual',
      item_master_ids: formData.item_master_ids.map(i => i.item_master_id || i),
    };
    showLoader();
    return postItem(
      `/api/orders/${order.id}/discounts`,
      payload,
      itemNames.order,
      {failed: 'cart.manualDiscount.failed'},
      undefined,
      () => {
        reset(form);
        hideLoader();
      }
    ).catch(hideLoader);
  }

  render() {
    const {orderProductOptions, appliesTo, discounts, form, order} = this.props;
    return (
      <PermissionWrapper permissions={[p.apply_manual_discounts]}>
        <Row>
          <ManualDiscountReduxForm
            form={form}
            appliesTo={appliesTo}
            orderProductOptions={orderProductOptions}
            discounts={discounts}
            order={order}
            onSubmit={this.onSubmit}
          />
        </Row>
      </PermissionWrapper>
    );
  }
}

ManualDiscountComponent.propTypes = {
  actions: PropTypes.shape({
    reset: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
  }).isRequired,
  order: PropTypes.object.isRequired,
  form: PropTypes.string.isRequired,
  orderProductOptions: PropTypes.array.isRequired,
  discounts: PropTypes.array.isRequired,
  appliesTo: PropTypes.string,
  showLoader: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired,
};

export default ManualDiscountComponent;
