import {createSelector} from 'reselect';

import getCurrentFacility from '../../facility/getCurrentFacility';
import {isMetrcIntegrator} from '../../integration/metrcSelectors';
import {integrationPlatforms, showReverseToParentConfig} from '../../../constants/integrations';

const showReverseToParent = createSelector(getCurrentFacility, isMetrcIntegrator, (facility, hasMetrcSettings) => {
  if(!facility.type) return false;
  return showReverseToParentConfig.facilities.indexOf(facility.type) === -1
    ? false
    : hasMetrcSettings && showReverseToParentConfig.integrations.indexOf(integrationPlatforms.METRC) !== -1
      ? true
      : showReverseToParentConfig.integrations.indexOf(integrationPlatforms.PLATFORM) !== -1;
});

export default showReverseToParent;

