import React from 'react';
import PropTypes from 'prop-types';
import {FaAngleDown, FaPrint} from 'react-icons/fa';
import SampleImage from './SampleImage';

const PrinterQueue = (props) => {

  const getLabelsCount = () => {
    return props.loadQ.reduce( (acc, load) => {
      acc += load.length;
      return acc;
    }, 0);
  };

  const scrollHeight = (parseInt(screen.height) - 500) + 'px';

  // Fields to display in the Block labels
  const fields = {
    itemId: 'plant_id',
    batch: 'batch_name'
  };

  let showSize = false; // toggles on/off a size column in the table

  // Make sure we have it - then test
  if(props.printQ.length > 0) {
    if(props.printQ[0].length > 0) {
      if (props.printQ[0][0].plant_id === undefined) {
        fields.itemId = 'package_id';
        fields.batch  = 'lot_id';
        showSize = true;
      }
    }
  }

  return (
    <div className='col-md-12'>
      <ul className='nav nav-tabs'>
        <li className={props.currentTab === 'sample' ? 'active' : ''} onClick={() => { props.onChangeTab('sample');}}><a href='#'>Sample Image</a></li>
        <li className={props.currentTab !== 'sample' ? 'active' : ''} onClick={() => { props.onChangeTab('print');}}><a href='#'>Print Queue</a></li>
      </ul>
      {
        props.currentTab === 'sample'
          ? (<div style={{margin: '1rem 0'}}>
              <SampleImage
                setTagInState={props.setTagInState}
                selectedTag={props.selectedTag}
                columnClass='col-md-12'
                label={props.label}
                labelCount={1}
              />
            </div>)
          : (<div className='col-md-12' style={{margin: '1rem 0'}}>
              <div className='row' style={{height: scrollHeight, overflow: 'scroll'}}>
                <div className='col-md-6'>
                  You have a total of {getLabelsCount()} labels selected for printing broken into {props.loadQ.length} blocks.
                  <table className='table table-sm table-striped table-bordered'>
                    <thead>
                      <tr>
                        <th style={{width: '5%'}}>#</th>
                        <th style={{width: '20%'}}>Label Blocks</th>
                        <th style={{width: '20%'}}># Of Labels</th>
                        <th style={{width: '20%'}}>Loaded</th>
                        <th style={{width: '15%'}}>Printed</th>
                        <th style={{width: '20%'}}>Print All</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      props.loadQ.length === 0
                        ? null
                        : props.loadQ.map( (load, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {index + 1}
                              </td>
                              <td>
                                <a href onClick={(event) => {
                                  event.stopPropagation();
                                  event.preventDefault();
                                  props.setViewPrintQIndex(index);
                                }}>
                                  {
                                    props.namesQ.length > 0
                                    ? props.namesQ[index].name
                                      : <span>Block {index + 1}</span>
                                  }
                                </a>
                              </td>
                              <td>{load.length}</td>
                              <td>
                                {
                                  props.printQ[index].length > 0
                                    ? 'Yes'
                                    : (
                                    <a href onClick={(event) => {
                                      event.stopPropagation();
                                      event.preventDefault();
                                      props.onLoadBlock(index);
                                    }}>
                                      Load Now
                                    </a>
                                  )
                                }
                              </td>
                              <td>
                                {
                                  props.zplQ[index].printed
                                  ? 'Yes'
                                    : 'No'
                                }
                              </td>
                              <td>
                                {
                                  props.currentPrinter === ''
                                  ? <div>Select Printer</div>
                                    : props.printQ[index].length === 0
                                      ? <div>Load Block</div>
                                      : (
                                        <a href onClick={(event) => {
                                          event.stopPropagation();
                                          event.preventDefault();
                                          props.printBlock(index);
                                        }}>
                                          Print
                                        </a>
                                        )
                                }
                              </td>
                            </tr>
                          );
                        })
                    }
                    </tbody>
                  </table>
                </div>
                <div className='col-md-6'>
                  <div>Click a Label Block name to work with labels in that block.</div>
                  <table className='table table-sm table-striped table-bordered'>
                    <thead>
                      <tr>
                        <th colSpan={showSize ? 5 : 4}>Labels In Block {props.viewPrintQIndex + 1}</th>
                      </tr>
                      <tr>
                        {
                          showSize
                          ? <th style={{width: '20%'}}>Size</th>
                            : null
                        }
                        <th style={{width: showSize ? '20%' : '25%'}}>Item ID</th>
                        <th style={{width: showSize ? '20%' : '25%'}}>Batch</th>
                        <th style={{width: showSize ? '20%' : '25%', textAlign: 'center'}}>Single</th>
                        <th style={{width: showSize ? '20%' : '25%', textAlign: 'center'}}>Many</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      props.loadQ.length === 0
                        ? null
                          : props.printQ[props.viewPrintQIndex].length === 0
                          ? (
                            <tr>
                              <td colSpan={showSize ? 5 : 4} style={{textAlign: 'center'}}>
                                <a href onClick={(event) => {
                                  event.stopPropagation();
                                  event.preventDefault();
                                  props.onLoadBlock(props.viewPrintQIndex);
                                }}>
                                  Block Has Not Been Loaded.  Click To Load
                                </a>
                              </td>
                            </tr>
                          )
                          : props.printQ[props.viewPrintQIndex].map( (label, index) => {
                            return (
                              <tr key={index}>
                                {
                                  showSize
                                    ? <td>{label.weight_value}</td>
                                    : null
                                }
                                <td>{label[fields.itemId]}</td>
                                <td>{label[fields.batch]}</td>
                                <td style={{textAlign: 'center', cursor: 'pointer', color: props.currentPrinter === '' ? '#ccc' : '#000'}} onClick={(event) => {
                                  event.stopPropagation();
                                  event.preventDefault();
                                  if(props.currentPrinter === '') return false;
                                  props.printSingleInBlock(index);
                                }}>
                                  <FaPrint/>
                                </td>
                                <td style={{textAlign: 'center', cursor: 'pointer', color: props.currentPrinter === '' ? '#ccc' : '#000'}} onClick={(event) => {
                                  event.stopPropagation();
                                  event.preventDefault();
                                  if(props.currentPrinter === '') return false;
                                  props.printFromInBlock(index);
                                }}>
                                  <FaPrint/>
                                  <FaAngleDown/>
                                </td>
                              </tr>
                            );
                          })
                    }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>)
      }
    </div>
  );

};

PrinterQueue.propTypes = {
  namesQ: PropTypes.array.isRequired,
  printSingleInBlock: PropTypes.func.isRequired,
  printFromInBlock: PropTypes.func.isRequired,
  zplQ: PropTypes.array.isRequired,
  printQ: PropTypes.array.isRequired,
  viewPrintQIndex: PropTypes.number.isRequired,
  loadQ: PropTypes.array.isRequired,
  currentPrinter: PropTypes.string.isRequired,
  setViewPrintQIndex: PropTypes.func.isRequired,
  onLoadBlock: PropTypes.func.isRequired,
  printBlock: PropTypes.func.isRequired,
  label: PropTypes.object.isRequired,
  selectedTag: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]).isRequired,
};

export default PrinterQueue;
