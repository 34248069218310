/* eslint-disable import/no-named-as-default*/
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack, push} from 'react-router-redux';

import {EA} from '../../../constants/uoms';
import {getItem, getDataByPost, getUnpaginatedData, postItem} from '../../../actions/apiActions';
import {getCompleteEachPackagesPageProps} from '../../../selectors/forms/completeEachPackagesFormSelectors';
import EachPackagesPageBase from '../common/EachPackagesPageBase';
import {fetchMetrcTrackingIdsForSelectInput} from '../../../actions/integrationActions';


class CompleteEachPackagesPage extends EachPackagesPageBase {
  constructor(props, context) {
    super(props, context);
    this.getItemMasterFilter = this.getItemMasterFilter.bind(this);
    this.transformFormValues = this.transformFormValues.bind(this);
  }

  getItemMasterFilter(job) {
    const {item: {item_master: {strain_id}}} = job;
    const {location} = this.props;
    const output_item_master_id = get(location, 'query.item_master_id');
    return `active:1 AND is_draft:0 AND strain_id:${strain_id} AND default_uom:"${EA}" AND is_prepack:0 AND category_code:("FLOWER", "CONCENTRATE") AND id:"${output_item_master_id}"`;
  }

  handlePostSubmitActions() {
    this.props.actions.push('/inventory');
  }

  transformFormValues(formValues) {
    const {integrationState: {isMetrc}} = this.props;

    formValues.is_each_packaging = true;

    if (isMetrc && !formValues.is_trade_sample) {
      formValues.is_trade_sample = false;
    }

    return formValues;
  }
}

CompleteEachPackagesPage.propTypes = {
  actions: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    getDataByPost: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    fetchMetrcTrackingIdsForSelectInput: PropTypes.func.isRequired,
  }).isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  initialValues: PropTypes.object.isRequired,
  employees: PropTypes.array.isRequired,
  itemMasters: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  packagedWeight: PropTypes.number.isRequired,
  timezone: PropTypes.string,
  integrationState: PropTypes.object,
  isEachPackaging: PropTypes.bool.isRequired,
  isMetrcStemHoldingsToggled: PropTypes.bool,
  isMetrcCaDonationsToggled: PropTypes.bool,
};

function mapDispatchToProps(dispatch) {
  const actions = {getItem, getDataByPost, getUnpaginatedData, postItem, goBack, push, fetchMetrcTrackingIdsForSelectInput};

  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(getCompleteEachPackagesPageProps, mapDispatchToProps)(CompleteEachPackagesPage);
