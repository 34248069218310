import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';
import EquipmentForm from '../common/EquipmentForm';
import {validateEquipmentForm as validate} from '../common/validate';

const CreateEquipmentFormWrapper = reduxForm({
  validate
})(EquipmentForm);

CreateEquipmentFormWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onAddVendor: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  getFormValue: PropTypes.func.isRequired,
  uomOptions: PropTypes.array.isRequired,
  images: PropTypes.array.isRequired,
  uploading: PropTypes.bool.isRequired,
  addImageRef: PropTypes.func.isRequired,
  changeUploadStatus: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  vendors: PropTypes.array.isRequired,
  serviceTypes: PropTypes.array.isRequired,
  equipmentTypes: PropTypes.array.isRequired
};

export default CreateEquipmentFormWrapper;
