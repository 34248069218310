import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {change, reset, getFormValues} from 'redux-form';
import {goBack, push} from 'react-router-redux';
import get from 'lodash.get';
import orderBy from 'lodash.orderby';
import {I18n} from 'react-redux-i18n';
import FormWrapper from '../../../common/form/FormWrapper';
import ConnectedInventoryReceiptForm from './InventoryReceiptForm';
import {
  getUnpaginatedData,
  ensureGetUnpaginatedData,
  getItem,
  getDataByPost,
  postItem
} from '../../../../actions/apiActions';
import * as itemNames from '../../../../constants/itemNames';
import {getIntegrationState} from '../../../../selectors/integration/integrationSelectors';
import {getMetrcSettings} from '../../../../selectors/integration/metrcSelectors';
import {INVENTORY_RECEIPT_FORM_METRC} from '../../../../constants/forms';
import {unsetItem} from '../../../../actions/itemActions';
import * as dataNames from '../../../../constants/dataNames';
import {getFlattenedStorageLocations} from '../../../../selectors/locationsSelectors';
import MessageModal from '../../../common/modal-messages/MessageModal';
import {getTestResultDimensions} from '../../../../selectors/testResultsSelectors';
import {getDriversForDropdown} from '../../../../selectors/driversSelectors';
import {getVehiclesForDropdown} from '../../../../selectors/vehiclesSelectors';
import {
  getIncomingTransferDetails,
  getInventoryReceiptPackageIds,
  getInventoryReceiptPayload, getReceiptInitialValues
} from '../../../../selectors/MetrcSupplyChainMappingSelectors';
import {addMessage} from '../../../../actions/systemActions';
import * as messageTypes from '../../../../constants/messageTypes';
import LabelPrinter from '../../../print-services/labels/LabelPrinter';

export class InventoryReceiptPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.title = 'inventoryReceipt.title';
    this.onSubmit = this.onSubmit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSaveAndPrintLabels = this.handleSaveAndPrintLabels.bind(this);
    this.handlePrintLabels = this.handlePrintLabels.bind(this);
    this.onHide = this.onHide.bind(this);
    this.isCreateMode = this.isCreateMode.bind(this);
    this.state = {
      showModal: false,
      printLabelPayload: {},
      hasError: false,
      locked: false
    };
  }

  componentDidMount() {
    // Load resources
    this.props.actions.ensureGetUnpaginatedData('/api/location_hierarchy', dataNames.locations, undefined);
    this.props.actions.ensureGetUnpaginatedData('/api/drivers/details', dataNames.drivers, null, {active: 1});
    this.props.actions.ensureGetUnpaginatedData('/api/vehicles', dataNames.vehicles);
    this.props.actions.getUnpaginatedData('/api/strains/by_organization', dataNames.organizationStrains);
    // If we have a receipt ID then load it
    if (this.props.receiptId) {
      this.props.actions.getItem(`/api/receipts/${this.props.params.id}`, itemNames.inventoryReceipt, {failed: 'cultivation.inventoryReceipts.errors.failedToRetrieve'}, {detailed: true, with_relations:['item_masters']})
        .then((inventoryReceipt) => {
          // Get Purchase Order
          const purchaseOrderId = get(inventoryReceipt, 'source_id');
          this.props.actions.getItem(`/api/purchase_orders/${purchaseOrderId}`, itemNames.purchaseOrder, {failed: 'cultivation.purchaseOrders.errors.failedToRetrieve'}, {detailed: true});
          // Get Item Masters used on receipt
          const ids = inventoryReceipt.lines.map((line) => line.item_master_id);
          const params = {active: 1, detailed: 1, in_ids: ids};
          this.props.actions.getUnpaginatedData('/api/item_masters', dataNames.itemMasters, null, params);
          // Get Test Results for packages used on receipt
          const package_ids = getInventoryReceiptPackageIds(inventoryReceipt);
          this.props.actions.getDataByPost('/api/lab_results/by_package_ids', {ids: package_ids}, dataNames.testResults);
          // Get Package details from items
          this.props.actions.getDataByPost('/api/items/by_package_ids', {ids: package_ids, detailed: 1, with_relations: ['packages']}, dataNames.inventoryItems);
          // Set locked status
          this.setState({ locked: get(inventoryReceipt, 'status') === 'completed' }); // eslint-disable-line react/no-did-mount-set-state
        });
    } else if (this.props.purchaseOrderId) {
      // This is a new receipt. Load PO from request param
      this.props.actions.getItem(`/api/purchase_orders/${this.props.purchaseOrderId}`, itemNames.purchaseOrder, {failed: 'cultivation.purchaseOrders.errors.failedToRetrieve'}, {detailed: true});
    }
  }

  componentWillUnmount() {
    // Todo: unload data
  }

  isCreateMode() {
    return (!this.props.receiptId);
  }

  onSubmit(formValues) {
    this.handleSubmit(formValues)
      .then((result) => {
        // redirect to ...
        const toRoute = get(result, 'status', 'open') === 'open'
          ? '/inventory-receipts'
          : '/inventory-receipts/inactive';
        this.props.actions.push(toRoute);
      })
      . catch((error) => {
        this.props.actions.addMessage(messageTypes.error, error, true);
      });
  }

  handleSaveAndPrintLabels(formValues) {
    this.handleSubmit(formValues)
      .then((result) => {
        // Show print modal
        this.setState({showModal: true, printLabelPayload: {id: result.id}});
      })
      . catch((error) => {
        this.props.actions.addMessage(messageTypes.error, error, true);
      });
  }

  handlePrintLabels() {
    this.setState({showModal: true, printLabelPayload: {id: this.props.inventoryReceipt.id}});
  }

  onHide(){
    this.setState({showModal: false});
  }

  handleSubmit(formValues) {
    const {receiptId, supplyChainMapping, purchaseOrder, itemMasters, integratorState: {isBiotrack}} = this.props;
    const {timezone} = this.state;

    // Determine payload
    const payload = getInventoryReceiptPayload(formValues,
      timezone,
      purchaseOrder,
      itemMasters,
      supplyChainMapping
    );

    // Pre checks
    const items = formValues.lines.map(line => {
      const itemMaster = this.props.itemMasters.find((itemMaster) => itemMaster.id === line.item_master_id);
      let orderedQty = line.qty;
      if (isBiotrack) {
        orderedQty = line.inventory.reduce((qty, lineInventory) => {
          return qty + parseFloat(lineInventory.qty);
        }, 0.00);
      }
      return {
        uom: itemMaster.default_uom,
        qty: orderedQty,
        item_master_id: line.item_master_id,
        id: line.id
      };
    });

    const promises = [
      this.props.actions.postItem('/api/compliance_settings/on_hand_weight/validate', {items}, 'none',
        {failed: (error) => error && error.response && error.response.data && error.response.data.errors && error.response.data.errors.message},
        {}
      )
    ];

    // Create/update receipt
    return new Promise(() => Promise.all(promises)
      .then(() => {
        if (this.isCreateMode()) {
          // Create receipt
          this.props.actions.postItem('/api/receipts', payload, itemNames.inventoryReceipt, {success: 'receipts.create.success', failed: 'receipts.create.fail'})
            .then((data) => Promise.resolve(data))
            .catch((error) => Promise.reject(error))
            .finally(() => this.setState({showLoader: false}));
        } else {
          // Update receipt
          this.props.actions.putItem(`/api/receipts/${receiptId}`, payload, itemNames.inventoryReceipt, {success: 'receipts.modify.success', failed: 'receipts.modify.fail'})
            .then((data) => Promise.resolve(data))
            .catch((error) => Promise.reject(error))
            .finally(() => this.setState({showLoader: false}));
        }
      })
    );
  }

  render() {
    const {
      change,
      reset,
      formErrors,
      formValues,
      initialValues,
      inventoryReceipt,
      purchaseOrder,
      incomingTransferDetails,
      itemMasters,
      storageLocations,
      drivers,
      vehicles,
      metrcSettings,
      strainOptions,
      testResults,
      testResultDimensions
    } = this.props;

    const {locked} = this.state;

    // Make sure we have all information needed. If not throw an error.
    if (this.isCreateMode()) {
      const hasSupplyChainMapping = this.props.supplyChainMapping && Object.keys(this.props.supplyChainMapping).length !== 0;
      const hasIncomingTransferDetails = this.props.incomingTransferDetails && Object.keys(this.props.incomingTransferDetails).length !== 0;
      if (!hasSupplyChainMapping || !hasIncomingTransferDetails) {
        return (
          <MessageModal
            translate={false}
            onHide={this.props.actions.goBack}
            dialogClassName='modal-dialog modal-md'
            showModal={true}
            message={I18n.t('inventoryReceipt.error.preconditionsNotMetInfo')}
            title={I18n.t('inventoryReceipt.error.preconditionsNotMet')}
            modalType={'error'}
          />
        );
      }
    }

    return (
      <FormWrapper title={this.title} goBack={this.props.actions.goBack}>
        <LabelPrinter
          showModal={this.state.showModal}
          onHide={this.onHide}
          labelTag='supply_inventory_receipt_all'
          payload={this.state.printLabelPayload}
        />
        <ConnectedInventoryReceiptForm
          change={change}
          initialValues={initialValues}
          reset={reset}
          onSubmit={this.onSubmit}
          handleSaveAndPrintLabels={this.handleSaveAndPrintLabels}
          handlePrintLabels={this.handlePrintLabels}
          formErrors={formErrors}
          formValues={formValues}
          inventoryReceipt={inventoryReceipt}
          purchaseOrder={purchaseOrder}
          incomingTransferDetails={incomingTransferDetails}
          itemMasters={itemMasters}
          storageLocations={storageLocations}
          drivers={drivers}
          vehicles={vehicles}
          metrcSettings={metrcSettings}
          isCreateMode={this.isCreateMode()}
          strainOptions={strainOptions}
          testResults={testResults}
          testResultDimensions={testResultDimensions}
          locked={locked}
        />
      </FormWrapper>
    );
  }
}

InventoryReceiptPage.propTypes = {
  actions: PropTypes.shape({
    change: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired
  }).isRequired,
  purchaseOrder: PropTypes.object,
  incomingTransferDetails: PropTypes.object,
  formValues: PropTypes.object,
  storageLocations: PropTypes.array.isRequired,
  drivers: PropTypes.array.isRequired,
  vehicles: PropTypes.array.isRequired,
  metrcSettings: PropTypes.object.isRequired,
  receiptId: PropTypes.number,
  purchaseOrderId: PropTypes.number.isRequired,
  strainOptions: PropTypes.array.isRequired,
  itemMasters: PropTypes.array.isRequired,
  testResults: PropTypes.array,
  items: PropTypes.array,
  testResultDimensions: PropTypes.object.isRequired,
  locked: PropTypes.bool.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    formValues: getFormValues(INVENTORY_RECEIPT_FORM_METRC)(state) || ownProps.initialValues,
    supplyChainMapping: state[itemNames.supplyChainMapping],
    initialValues: getReceiptInitialValues(state, { isCreateMode: !ownProps.routeParams.id }),
    integratorState: getIntegrationState(state),
    receiptId: ownProps.routeParams.id,
    inventoryReceipt: state[itemNames.inventoryReceipt],
    purchaseOrderId: parseInt(ownProps.location.query.po_num) || get(state[itemNames.inventoryReceipt], 'source_id'),
    purchaseOrder: state[itemNames.purchaseOrder],
    incomingTransferDetails: getIncomingTransferDetails(state),
    storageLocations: getFlattenedStorageLocations(state, ownProps),
    drivers: getDriversForDropdown(state, ownProps),
    vehicles: getVehiclesForDropdown(state, ownProps),
    metrcSettings: getMetrcSettings(state),
    itemMasters: state[dataNames.itemMasters],
    testResults: state[dataNames.testResults],
    items: state[dataNames.inventoryItems],
    testResultDimensions: getTestResultDimensions(state),
    strainOptions: orderBy(state[dataNames.organizationStrains], 'strain_name', 'asc')
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    getItem,
    getDataByPost,
    getUnpaginatedData,
    postItem,
    ensureGetUnpaginatedData,
    change,
    reset,
    goBack,
    unsetItem,
    addMessage,
    push
  };

  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryReceiptPage);
