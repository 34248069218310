import React from 'react';
import PropTypes from 'prop-types';
import {FieldArray} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Row, Col, Button, ButtonToolbar} from 'react-bootstrap';

import EquipmentItemsFieldArray from './EquipmentItemsFieldArray';

const EquipmentForm = ({onAddVendor, uomOptions, images, addImageRef, uploading, handleSubmit, equipmentTypes,
                         changeUploadStatus, change, setImage, vendors, serviceTypes, pristine, submitting, invalid, modifyMode}) => {


  return (
    <form className='create-harvest-package-form' onSubmit={handleSubmit} noValidate>
      <FieldArray name='items'
                  vendorOptions={vendors}
                  uomOptions={uomOptions}
                  serviceTypes={serviceTypes}
                  onAddVendor={onAddVendor}
                  equipmentTypes={equipmentTypes}
                  images={images}
                  addImageRef={addImageRef}
                  uploading={uploading}
                  changeUploadStatus={changeUploadStatus}
                  change={change}
                  setImage={setImage}
                  modifyMode={modifyMode}
                  component={EquipmentItemsFieldArray}
      />
      <Row className='padding-top'>
        <Col xs={12}>
          <ButtonToolbar className='float-right'>
            {modifyMode
              ? null
              : <Button variant='primary'
                  style={{marginLeft: '10px'}}
                  type='submit'
                  onClick={() => {
                    change('redirect', true);
                  }}
                  disabled={submitting || pristine}>
              {I18n.t('common.actions.saveAndReturn')}
            </Button>}
            <Button variant='primary'
              style={{marginLeft: '10px'}}
              type='submit'
              onClick={() => {
                change('redirect', false);
              }}
              disabled={submitting || pristine}>
              {I18n.t('common.form.save')}
            </Button>
          </ButtonToolbar>
        </Col>
      </Row>
    </form>
  );
};

EquipmentForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onAddVendor: PropTypes.func.isRequired,
  uomOptions: PropTypes.array.isRequired,
  images: PropTypes.array.isRequired,
  uploading: PropTypes.bool.isRequired,
  addImageRef: PropTypes.func.isRequired,
  changeUploadStatus: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  setImage: PropTypes.func.isRequired,
  serviceTypes: PropTypes.array.isRequired,
  vendors: PropTypes.array.isRequired,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  equipmentTypes: PropTypes.array.isRequired,
  modifyMode: PropTypes.bool,
};

export default EquipmentForm;
