import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {Button} from 'react-bootstrap';
import PermissionWrapper from '../../common/PermissionWrapper';
import * as p from '../../../constants/permissions';
import * as statuses from '../../../constants/statuses';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import {getAllPurchasedItemMastersByPartner} from '../../../actions/purchaseOrderActions';
import {getPartnerFacility} from '../../../selectors/fillPurchaseOrderSelectors';
import {isActiveFacilityDispensary} from '../../../selectors/facilitiesSelectors';
import {getDataByPost, getUnpaginatedData} from '../../../actions/apiActions';
import {setItem} from '../../../actions/itemActions';
import InProgressOverlay from '../../common/InProgressOverlay';
import {getReassignMode} from '../../../selectors/inventoryReceiptSelectors';


class ReassignPackageButton extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      loader: false,
    };

    this.toggleLoader = this.toggleLoader.bind(this);
    this.toggleReassignMode = this.toggleReassignMode.bind(this);
    this.enableReassignMode = this.enableReassignMode.bind(this);
    this.disableReassignMode = this.disableReassignMode.bind(this);
  }

  componentWillUnmount() {
    this.disableReassignMode();
  }

  toggleLoader() {
    this.setState({ loader: false });
  }

  toggleReassignMode(flag) {
    return this.props.actions.setItem({enable: flag}, itemNames.reassignPackages);
  }

  disableReassignMode() {
    this.toggleReassignMode(false);
  }

  enableReassignMode() {
    const { actions, partnerId, isActiveFacilityDispensary } = this.props;

    this.toggleLoader(true);

    const getItemMasterChildren = (data) => {
      const ids = data.reduce((acc, itemMaster) => {
        if (get(itemMaster, 'inventory_attributes.is_prepack')) {
          acc.push(get(itemMaster, 'id'));
        }
        return acc;
      }, []);

      if (ids.length === 0) {
        return Promise.resolve([]);
      }

      return actions.getDataByPost('/api/item_masters/child_item_masters',
       {ids},
       dataNames.childItemMasters,
       {failed: 'ei.processingTypes.form.failed'}
      );
    };

    const transferParams = {
      in_statuses: ['open'],
      select_columns: ['id', 'status', 'transfer_number'],
      with_relations: ['lines', 'lines.inventory']
    };

    const promises = [
      actions.getUnpaginatedData('/api/transfers', dataNames.transfers, null, transferParams),
      actions.getUnpaginatedData('/api/production_runs', dataNames.productionRuns, {failed: 'productionRuns.get.failed'}, {detailed: 1, status: statuses.open}),
      actions.getUnpaginatedData('/api/packaging_jobs', dataNames.packagingJobs, {failed: 'packagingJobs.get.failed'}, {detailed: 1, status: statuses.open}),
      actions.getAllPurchasedItemMastersByPartner(partnerId, [dataNames.itemMasters], true)
        .then((data) => {
          getItemMasterChildren(data);
        })
    ];

    if (isActiveFacilityDispensary) {
      promises.push(actions.getUnpaginatedData('/api/open_orders', dataNames.orders, {failed: 'orders.get.failed'}, {detailed: 1}));
    }

    return Promise
      .all(promises)
      .then(() => {
        this.toggleReassignMode(true);
      })
      .finally(() => {
        this.toggleLoader(false);
      });
  }

  render() {
    const { isReassignMode } = this.props;
    const { loader } = this.state;

    return (
      <PermissionWrapper permissions={[p.manage_inventory_receipt_package_cost, p.manually_adjust_package_item]}>
          <InProgressOverlay isActive={loader} message={I18n.t('common.form.loadingData')} />
          <br />
          <Button disabled={isReassignMode} onClick={this.enableReassignMode} className='btn btn-primary'>
            {I18n.t('supplyChain.editInventoryReceipt')}
          </Button>
          <br className='hidden-md' />
          <br className='hidden-md' />
      </PermissionWrapper>
    );
  }
}



ReassignPackageButton.propTypes = {
  actions: PropTypes.object,
  partnerId: PropTypes.number,
  hasPermission: PropTypes.bool,
  isActiveFacilityDispensary: PropTypes.bool
};

function mapStateToProps (state) {
  return {
    partnerId: get(getPartnerFacility(state), 'partner_id'),
    isActiveFacilityDispensary: isActiveFacilityDispensary(state),
    isReassignMode: get(getReassignMode(state), 'enable', false)
  };
}

function mapDispatchToProps (dispatch) {
  const actions = {setItem, getUnpaginatedData, getAllPurchasedItemMastersByPartner, getDataByPost};
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReassignPackageButton);
