import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import get from 'lodash.get';
import { Col, Row, Button} from 'react-bootstrap';
import {FaMinus, FaSave, FaTimes} from 'react-icons/fa';
import * as facilitySelectors from '../../../selectors/facilitiesSelectors';
import { FILE_TYPES, types, COMMON_PRIVATE_IMAGE } from '../../../constants/fileUploads';
import { intake_form, extended_sales_limit, fileOptions, canadian_registration_form } from '../../../constants/fileTypes';
import { evaluatePredicate } from '../../../util/callbackHelpers';
import InternationalDateTimePickerInput from '../../common/form/InternationalDateTimePickerInput';
import FileInput from '../../common/form/FileInput';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import * as applicationModes from '../../../constants/applicationModes';
import { normalizeMarijuanaId, formatMarijuanaId } from '../common/utils';
import CanadianCompliance from './CanadianCompliance';
import MarijuanaIdField from './MarijuanaIdField';
import WillRender from '../../common/concealers/WillRender';
import TextInput from '../../common/form/TextInput';
import * as itemNames from '../../../constants/itemNames';

const intakeForm = fileOptions.find(option => option.type === intake_form);

export class ConsumerIdsComponent extends React.PureComponent {
  constructor (props, context) {
    super(props, context);
    this.state = {
      uploading: {},
      touched_event_on: {}
    };
    this.changeUploadStatus = this.changeUploadStatus.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
    this.eventDateFieldWasDeleted = this.eventDateFieldWasDeleted.bind(this);
    this.eventDateFieldTouched = this.eventDateFieldTouched.bind(this);
  }

  changeUploadStatus (key, status) {
    this.setState(state => ({
      uploading: {...state.uploading, [key]: status},
    }));
  }

  deleteFile (id, fieldName, type) {
    const uri = type === types.image ? 'images' : 'documents';
    return this.props.actions
      .deleteItem(
        `/api/${uri}/${id}`,
        null,
        null,
        {id}
      )
      .then(() => {
        if (fieldName) {
          this.props.change(`${fieldName}.file_id`, null);
          this.props.change(`${fieldName}.file`, null);
        }
      });
  }

  eventDateFieldTouched(fieldName) {
    const {touched_event_on} = this.state;
    if (!touched_event_on[`${fieldName}.event_on`]) {
      touched_event_on[`${fieldName}.event_on`] = true;
      this.setState({touched_event_on});
      this.props.touch(`${fieldName}.event_on`);
    }
  }

  eventDateFieldWasDeleted() {
    this.setState({touched_event_on: {}});
  }

  render () {
    const {
      fileOptions, groupedFields, fields, change, isFieldRequired, isUploadDisabled, customer, isPaLeaf, isOhMetrc, isUtah, paOraclePatientSyncFeature,
      isExpirationRequired, activeFacility, applicationMode, isCanadaFacility, mostRecentMedicalId, idsArray, formName, caregiverInfo, dispensaries
    } = this.props;

    const {uploading} = this.state;
    const hasIntakeFormFile = customer.intake_form_file && customer.intake_form_file.url;
    const options = fileOptions.filter((option) => {
      if (!option.isSingle) {
        return true;
      }
      const group = groupedFields.find(g => g.type === option.type);
      return !group || !group.fields || !group.fields.length;
    });

    const isMetrcMd = applicationMode === applicationModes.metrcMd;
    const metrcMdProps = {};
    if (isMetrcMd) {
      metrcMdProps.normalize = normalizeMarijuanaId;
      metrcMdProps.format = formatMarijuanaId;
    }

    const filesNotRequiringIds = [canadian_registration_form, extended_sales_limit];

    const registrationEventOptions = get(this.props, 'constants.partner.registration_status_settings', []).map((status) => {
      return {
        type: status,
        file_type: COMMON_PRIVATE_IMAGE,
        name: I18n.t(`common.objectFile.${status}`),
        form_type: canadian_registration_form,
        organization_id: get(activeFacility, 'organizationId'),
      };
    });

    const refusalOptions = get(this.props, 'constants.partner.registration_refusal_reasons', []).map((reason) => {
      return {
        value: reason,
        text: I18n.t(`customers.registrations.refusalOptionsMap.${reason}`),
      };
    });
    return (
      <div className='object-files'>
        <div className='files-groups'>
          {groupedFields.map((group, groupIndex) => (
            <div className='file-group' key={groupIndex}>
              <h5>{I18n.t(`common.objectFile.${group.name}`)}</h5>
              {
                group.fields.length
                  ? null
                  : (<div className='alert alert-warning'>
                  {I18n.t(`common.objectFile.${group.type === canadian_registration_form ? 'noEventsAdded' : 'noFilesAdded'}`)}
                </div>)
              }

              {
                group.fields.map((field, idx) => {

                  const {fieldName, fieldIndex, file} = field;
                  const idObject = idsArray[idx];
                  const hasFile = Boolean(file.file && file.file.url);
                  const conditionalProps = (group.type === 'med') ? metrcMdProps : {};
                  const isCanadianRegistrationEvent = (group.type === canadian_registration_form);
                  const fileType = Array.isArray(group.file_type) ? FILE_TYPES.createFromMultiple(group.file_type) : FILE_TYPES[group.file_type];



                  return (
                  <div key={fieldIndex}>
                    <div className='object-file layout-row layout-align-start-start'>
                      <div className={`file-thumb ${hasFile ? ' active' : ''}`}>

                        {
                          hasFile
                            ? (<div>
                            <a href={file.file.url.original || file.file.url} target='_blank'
                               title={I18n.t('common.objectFile.downloadFile')}>
                              <FaSave className='thumb'/>
                            </a>
                            <FaTimes
                              className='remove-button'
                              onClick={() => this.deleteFile(file.file_id, fieldName, fileType)}
                              title={I18n.t('common.objectFile.removeFile')}
                            />
                          </div>)
                            : <FaSave className='thumb'/>
                        }

                      </div>

                        {
                          group.type === canadian_registration_form && isCanadaFacility && get(idObject, 'status', false)
                          ? (<div className='flex-25 field'>
                            <Field name={`${fieldName}.status`} component={TextInput} props={{
                              label: I18n.t('common.objectFile.eventType'),
                              disabled: true,
                              value: I18n.t(`common.objectFile.${idObject.status}`),
                            }}/>
                          </div>)
                          : null
                        }

                      <div className='flex-25 field'>
                        <WillRender ifTrue={filesNotRequiringIds.indexOf(group.type) === -1}>
                          <MarijuanaIdField
                            conditionalProps={conditionalProps}
                            label={group.fieldLabel}
                            isMetrcMd={isMetrcMd}
                            type={group.type}
                            isPaLeaf={isPaLeaf}
                            isUtah={isUtah}
                            caregiverInfo={caregiverInfo}
                            isOhMetrc={isOhMetrc}
                            fieldName={fieldName}
                            isFieldRequired={isFieldRequired}
                            paOraclePatientSyncFeature={paOraclePatientSyncFeature}
                          />
                        </WillRender>

                        {
                          group.type === canadian_registration_form && get(idObject, 'status', false)
                            ? idObject.status === 'return'
                            ? (<Field name={`${fieldName}.from_facility_id`} component={ReactSelectInput} props={{
                              label: I18n.t('customers.registrations.fromFacility'),
                              options: dispensaries,
                              valueKey: 'id',
                              textKey: 'name',
                              isRequired: true,
                            }}
                            />)
                            : (<Field name={`${fieldName}.refusal_reason`} component={ReactSelectInput} props={{
                              label: I18n.t('customers.registrations.refusalOptions'),
                              options: refusalOptions,
                              placeholder: I18n.t('common.form.select'),
                              isRequired: true,
                            }}
                            />)
                            : null
                        }
                      </div>

                        <WillRender ifTrue={group.type === 'med' && isCanadaFacility}>
                          <CanadianCompliance
                            show={group.type === 'med'}
                            fieldName={fieldName}
                            formName={formName}
                            isMostRecent={mostRecentMedicalId && idObject ? mostRecentMedicalId.id === idObject.id : true}
                            isCanadaFacility={isCanadaFacility}
                            change={change}
                          />
                        </WillRender>
                      {/* NOTE: When this feature toggle (paOraclePatientSyncFeature) is removed,
                      please remove WillRender as well. It should always render at that point */}
                      <WillRender ifTrue={!isPaLeaf || paOraclePatientSyncFeature}>
                        <div className='flex-25 field'>
                          {
                            group.type === canadian_registration_form
                              ? (<InternationalDateTimePickerInput name={`${fieldName}.event_on`} props={{
                                label: I18n.t('common.objectFile.registrationEventDate'),
                                isRequired: true,
                                closeOnSelect: true,
                                onFocus: () => this.eventDateFieldTouched(fieldName)
                              }}/>)
                              : (<InternationalDateTimePickerInput name={`${fieldName}.expired_at`} props={{
                                label: I18n.t(
                                  group.type === 'med' ?
                                    'common.objectFile.medicalMarijuanaIdExpirationDate' :
                                    'common.objectFile.expiration'
                                ),
                                readOnly: (isPaLeaf) && group.type === 'med' || isUtah,
                                closeOnSelect: true,
                                isRequired: evaluatePredicate(isExpirationRequired, group.type)
                              }}/>)
                          }
                        </div>
                      </WillRender>

                      <div>
                        <Field
                          name={`${fieldName}.document`}
                          itemName={itemNames.document}
                          component={FileInput}
                          props={{
                            ...fileType,
                            disabled: uploading[fieldName] || evaluatePredicate(isUploadDisabled, group.type),
                            btnContent: uploading[fieldName] ? I18n.t('common.form.uploading') : I18n.t('common.objectFile.uploadFile'),
                            onUploadStart: () => this.changeUploadStatus(fieldName, true),
                            onChangeCb: (f) => {
                              change(`${fieldName}.file_id`, f.id);
                              change(`${fieldName}.file`, f);
                              this.changeUploadStatus(fieldName, false);
                            },
                            btnProps: {
                              className: 'btn btn-primary',
                            },
                          }}/>
                      </div>

                      <div className='remove-row-button'>
                        <Button
                          variant='primary'
                          size='sm'
                          onClick={() => {fields.remove(fieldIndex); this.eventDateFieldWasDeleted();}}
                          disabled={(!!file.file_id || (evaluatePredicate(isFieldRequired, group.type) && group.fields.length === 1)) && !isCanadianRegistrationEvent}
                        >
                          <FaMinus/>
                        </Button>
                      </div>
                    </div>

                    {(group.type === 'med' && isPaLeaf && paOraclePatientSyncFeature) ? (
                    <div className='flex-25 field'>
                      <Field name={`${fieldName}.mmid_status`} component={TextInput} props={{
                        label: I18n.t('common.objectFile.medicalMarijuanaIdCardStatus'),
                        readOnly: true,
                      }}
                      />
                    </div>
                  ) : null}
                  </div>
                );
                })}
            </div>
          ))}

          <div className='layout-row' style={{display: 'none'}}>
            <div className='file-group flex-50'>
              <h5>{intakeForm.name}</h5>

              <div className='object-file layout-row layout-align-start-start'>
                <div className={`file-thumb ${hasIntakeFormFile ? ' active' : ''}`}>
                  {hasIntakeFormFile ?
                    <div>
                      <a href={customer.intake_form_file.url} target='_blank'
                         title={I18n.t('common.objectFile.downloadFile')}>
                        <FaSave className='thumb'/>
                      </a>
                      <FaTimes
                        className='remove-button'
                        onClick={() => this.deleteFile(customer.intake_form_file_id).then(() => {
                          change('customer.intake_form_file_id', null);
                          change('customer.intake_form_file', null);
                        })}
                        title={I18n.t('common.objectFile.removeFile')}
                      />
                    </div> : <FaSave className='thumb'/>}
                </div>

                <div>
                  <Field
                    name='customer.intake_form_file_document'
                    itemName={itemNames.document}
                    component={FileInput}
                    props={{
                      ...FILE_TYPES[intakeForm.file_type],
                      itemName: null,
                      disabled: uploading['customer.intake_form_file'] || evaluatePredicate(isUploadDisabled, intakeForm.type),
                      btnContent: uploading['customer.intake_form_file'] ? I18n.t('common.form.uploading') : I18n.t('common.objectFiles.uploadFile'),
                      onUploadStart: () => this.changeUploadStatus('customer.intake_form_file', true),
                      onChangeCb: (f) => {
                        change('customer.intake_form_file_id', f.id);
                        change('customer.intake_form_file', f);
                        this.changeUploadStatus('customer.intake_form_file', false);
                      },
                      btnProps: {
                        className: 'btn btn-default',
                      },
                    }}/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Row>
          <Col md={4}>
            <Field name='addFile' component={ReactSelectInput} props={{
              options: options.length === 1 ? registrationEventOptions : options,
              valueKey: 'type',
              textKey: 'name',
              label: I18n.t(`common.objectFile.${options.length === 1 ? 'addRegistrationEvent' : 'addFile'}`),
              onChange: (type) => {
                const useOptions = this.props.name === 'registrations' ? registrationEventOptions : fileOptions;
                const selectedOption = useOptions.find(option => get(option, 'type') === type) || {};
                const fileType = Array.isArray(selectedOption.file_type) ? FILE_TYPES.createFromMultiple(selectedOption.file_type) : FILE_TYPES[selectedOption.file_type];
                if (selectedOption) {
                  const object = {
                    type: selectedOption.form_type ? selectedOption.form_type : type,
                    file_type: fileType,
                    file: null,
                    status: selectedOption.type,
                    event_on: new Date(),
                    from_facility_id: selectedOption.type === 'return' ? dispensaries[0].id : undefined,
                    organization_id: get(activeFacility, 'organizationId'),
                  };
                  fields.push(object);
                }
              },
            }}/>
          </Col>
        </Row>
      </div>);
  }
}

ConsumerIdsComponent.propTypes = {
  actions: PropTypes.shape({
    deleteItem: PropTypes.func.isRequired,
  }).isRequired,
  fileOptions: PropTypes.array.isRequired,
  groupedFields: PropTypes.array.isRequired,
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
  }).isRequired,
  change: PropTypes.func.isRequired,
  types: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  isFieldRequired: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
  ]),
  isExpirationRequired: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
  ]),
  isUploadDisabled: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
  ]),
  customer: PropTypes.object.isRequired,
  isCanadaFacility: PropTypes.bool.isRequired,
  formName: PropTypes.string.isRequired,
  isPaLeaf: PropTypes.bool.isRequired,
  paOraclePatientSyncFeature: PropTypes.bool,
  caregiverInfo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  touch: PropTypes.func
};

export default connect(
  (state) => ({
    activeFacility: facilitySelectors.getActiveFacility(state),
  })
)(ConsumerIdsComponent);
