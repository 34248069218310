import {SET_ETAG,
  SET_PAGINATION,
  SET_REQUEST_START,
  SET_REQUEST_STOP,
  SET_REQUEST_HASH,
  SET_FACETS,
  SET_META,
  UNSET_META,
  SET_DATA,
  SET_ITEM,
  UNSET_DATA,
  UNSET_ITEM,
  UNION_DATA,
  DELETE_DATA,
  PUSH_DATA,
  CLEAR_META
} from '../constants/actionTypes';
import initialState from '../constants/initialState';

export default function apiMetaDataReducer (state = initialState.meta, action){
  const {type, payload, name} = action;
  switch (type) {
  case SET_ETAG:
    return Object.assign({}, state, {[name]:{...state[name], etag: payload}});
  case SET_PAGINATION:
    return Object.assign({}, state, {[name]:{...state[name], pagination: payload}});
  case SET_REQUEST_START:
    if(!state[name]){
      return Object.assign({}, state, {[name]:{startTime: payload, stopTime: undefined}});
    }else if(!state[name].startTime || payload >= state[name].startTime){
      return Object.assign({}, state, {[name]:{...state[name], startTime: payload, stopTime: undefined}});
    }else{
      return state;
    }
  case SET_REQUEST_STOP:
    if(payload >= state[name].startTime){
      return Object.assign({}, state, {[name]:{...state[name], stopTime: payload}});
    }else{
      return state;
    }
  case SET_REQUEST_HASH:
    return Object.assign({}, state, {[name]:{...state[name], hash: payload}});
  case SET_FACETS:
    return Object.assign({}, state, {[name]:{...state[name], facets: payload}});
  case SET_META:
    return Object.assign({}, state, {[name]: payload});
  case UNSET_META:
  case SET_DATA:
  case SET_ITEM:
  case UNSET_DATA:
  case UNSET_ITEM:
  case UNION_DATA:
  case DELETE_DATA:
  case PUSH_DATA:
    return Object.assign({}, state, {[name]:initialState.meta[name] ? initialState.meta[name] : {}});
  case CLEAR_META:
    return initialState.meta;
  default:
    return state;
  }
}
