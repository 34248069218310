import { createSelector } from 'reselect';

import * as dataNames from '../constants/dataNames';
import * as productionRunTypes from '../constants/productionRunTypes';
import * as statuses from '../constants/statuses';
import { mapProductionJobForListing } from './productionRunsSelectors';
import { getCurrentFacilityUserOptions } from './usersSelectors';

export const getProcessingJobs = state => state[dataNames.processingJobs];

export const getActiveProcessingJobs = createSelector(
  [getProcessingJobs, getCurrentFacilityUserOptions],
  (jobs, users) => {
    return jobs
      .filter(job => job.run_type === productionRunTypes.processing && job.status === statuses.open)
      .map(job => mapProductionJobForListing(job, users));
  }
);

export const getActiveProcessingTypeIds = createSelector(
  [getActiveProcessingJobs],
  (jobs) => {
    return jobs.map(job => job.processing_type_id);
  }
);
