import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Button, Col, Row} from 'react-bootstrap';
import {FaTimes} from 'react-icons/fa';
import TextInput from '../../../../common/form/TextInput';
import FieldArrayIncrement from '../../../../common/form/FieldArrayIncrement';
import ReactSelectInput from '../../../../common/form/ReactSelectInput';

const AlleavesTerminals = ({fields}) => {

  const modeOptions = [
    { text: 'Regala', value: 'regala' },
    { text: 'Salt Pay', value: 'cashless_atm' },
  ];

  const receiptOptions = [
    { text: 'No', value: 'No' },
    { text: 'Customer copy', value: 'Customer' },
    { text: 'Merchant copy', value: 'Merchant' },
    { text: 'Both', value: 'Both' },
  ];

  return (
    <React.Fragment>
      {fields.map((fieldName, index) => {
        return (
          <Row key={index}>
            <Col sm={2}>
              <Field name={`${fieldName}.tpn`} component={TextInput} props={{
                label: I18n.t('facility.setup.thirdPartyIntegration.alleaves.tpn'),
                isRequired: true,
              }}/>
            </Col>
            <Col sm={2}>
              <Field name={`${fieldName}.register_id`} component={TextInput} props={{
                label: I18n.t('facility.setup.thirdPartyIntegration.alleaves.register_id'),
                isRequired: true
              }}/>
            </Col>
            <Col sm={2}>
              <Field name={`${fieldName}.authkey`} component={TextInput} props={{
                label: I18n.t('facility.setup.thirdPartyIntegration.alleaves.authkey'),
                isRequired: true,
              }} />
            </Col>
            <Col sm={2}>
              <Field name={`${fieldName}.mode`} component={ReactSelectInput} props={{
                label: I18n.t('facility.setup.thirdPartyIntegration.alleaves.mode'),
                isRequired: true,
                options: modeOptions,
                dataId: 'value'
              }} />
              </Col>
              <Col sm={2}>
                <Field name={`${fieldName}.receipt`} component={ReactSelectInput} props={{
                  label: I18n.t('facility.setup.thirdPartyIntegration.alleaves.receipt'),
                  isRequired: true,
                  options: receiptOptions,
                  dataId: 'value'
                }} />
              </Col>

              {fields.length > 1 && (
                <Col sm={1}>
                  <span style={{display: 'block', marginBottom: '1.75em'}}/>
                  <Button size='sm' onClick={() => fields.remove(index)}>
                    <FaTimes/>
                  </Button>
                </Col>
              )}
          </Row>
        );
      })}
      <FieldArrayIncrement
        fields={fields}
        showDecrement={false}
        minLength={1}
      />
    </React.Fragment>
  );
};

AlleavesTerminals.propTypes = {
  fields: PropTypes.object.isRequired,
};

export default AlleavesTerminals;
