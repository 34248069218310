import {createSelector} from 'reselect';
import * as dataNames from '../constants/dataNames';

export const getVisitReasons = (state) => {
  if(!state[dataNames.visitReasons]) return [];
  return state[dataNames.visitReasons];
};

export const getDefaultVisitReason = createSelector([getVisitReasons], (visitReasons) => {
  if(visitReasons.length === 0) return {};
  return visitReasons.find((reason) => reason.name === 'Standard Visit');
});
