import React from 'react';
import PropTypes from 'prop-types';
import {FieldArray} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {FormGroup} from 'react-bootstrap';
import ReturnedItem from './ReturnedItem';

const ReturnedItems = (props) => {
  const {locations} = props;
  let {returnedItems} = props;

  returnedItems = returnedItems.map(returnedItem => {
    if (!returnedItem.received_quantity) {
      returnedItem.received_quantity = 0;
    }
    return returnedItem;
  });

  return (
    <FormGroup className='restock-inventory'>
      <h3>{I18n.t('cultivation.supplyChain.returnedItems')}</h3>
      <div className='alert alert-warning'>{I18n.t('cultivation.transfers.returnedItemsWarning')}</div>
      <FieldArray name='returnedItems' component={ReturnedItem} props={{
        returnedItems,
        locations
      }} />
    </FormGroup>
  );
};

ReturnedItems.propTypes = {
  returnedItems: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired
};

export default ReturnedItems;
