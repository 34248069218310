import * as types from '../../constants/actionTypes';
import initialState from '../../constants/initialState';

export default function checkoutReducer (state = initialState.cart.checkout, action){
  const {checkout} = action;
  switch (action.type) {
  case types.SET_CHECKOUT:
    return checkout;
  default:
    return state;
  }
}
