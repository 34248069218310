import React from 'react';


const SVGWarning = ({fillColor}) =>
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fill-rule='evenodd' clip-rule='evenodd' d='M22.41 20.64L12.66 1.89C12.5291 1.64743 12.2756 1.49623 12 1.49623C11.7243 1.49623 11.4709 1.64743 11.34 1.89L1.58998 20.64C1.45601 20.8721 1.45601 21.158 1.58998 21.39C1.72961 21.6196 1.98138 21.7569 2.24998 21.75H21.75C22.0148 21.75 22.2599 21.6103 22.395 21.3825C22.5308 21.155 22.5365 20.8728 22.41 20.64ZM11.16 7.5H12.8475V15H11.16V7.5ZM12 19.5C11.3787 19.5 10.875 18.9963 10.875 18.375C10.875 17.7537 11.3787 17.25 12 17.25C12.6213 17.25 13.125 17.7537 13.125 18.375C13.125 18.9963 12.6213 19.5 12 19.5Z' fill={fillColor} />
  </svg>;


export default SVGWarning;
