import React from 'react';
import PropTypes from 'prop-types';

const ZipCodes = ({taxToView}) => {
  const zipCodesCount = taxToView.zipcodes.length;
  return (
    <div className='modal-body'>
      {taxToView.zipcodes.map((zipCode, index) => {
        return (
          <strong key={index}>{`${zipCode}${zipCodesCount > 1 ? '; ' : ''}`}</strong>
        );
      })}
    </div>
  );
};

ZipCodes.propTypes = {
  taxToView: PropTypes.object.isRequired
};

export default ZipCodes;
