import {I18n} from 'react-redux-i18n';
import {requiredFieldValidation} from '../../common/form/redux-form/validations';
import {
  isCategoryInfusedEdible,
  isCategoryInfusedNonEdible,
  isCategoryCartridgesPens
} from '../../../selectors/categorySelectors';


const validate = (values, {integrationState: {isBiotrack}, products, isStrainRequired}) => {
  const errors = {};

  if (isBiotrack) {
    const isZero = (value) => (typeof value !== typeof undefined) ? parseFloat(value) === 0 : false;
    const needMedicatedWeight = products.some(product => {
      const cat = product.category;
      return isCategoryInfusedEdible(cat) || isCategoryInfusedNonEdible(cat) || isCategoryCartridgesPens(cat);
    });
    if (needMedicatedWeight && isZero(values.medicated_weight)) {
      errors.medicated_weight = I18n.t('products.form.medicatedNetWeightCannotBeZero');
    }
  }

  if (isStrainRequired) {
    errors.strain_id = requiredFieldValidation(values.strain_id, 'products.form.strainName');
  }

  return errors;
};

export default validate;