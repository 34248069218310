import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {ensureGetUnpaginatedData, postData} from '../../../actions/apiActions';
import {getCreatePhysicianPageProps} from '../../../selectors/forms/physicianFormSelectors';
import AddPhysicianButtonComponent from './AddPhysicianButtonComponent';


function mapDispatchToProps(dispatch) {
  const actions = {ensureGetUnpaginatedData, postData};

  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

const AddPhysicianButton = connect(getCreatePhysicianPageProps, mapDispatchToProps)(AddPhysicianButtonComponent);

AddPhysicianButton.propTypes = {
  onPhysicianAdded: PropTypes.func,
};

export default AddPhysicianButton;
