/* eslint-disable import/no-named-as-default*/
import sortBy from 'lodash.sortby';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFormValues } from 'redux-form';

import { goBack } from 'react-router-redux';

import * as apiActions from '../../../actions/apiActions';
import * as itemNames from '../../../constants/itemNames';
import * as dataNames from '../../../constants/dataNames';
import * as UOMS from '../../../constants/uoms';
import { FEEDING_SCHEDULE_FORM } from '../../../constants/forms';
import { unsetData } from '../../../actions/dataActions';

import FormWrapper from '../../common/form/FormWrapper';
import FeedingScheduleFormWrapper from '../common/FeedingScheduleFormWrapper';
import { getIngredientsFofFeedingSchedules } from '../../../selectors/ingredientsSelectors';
import { getStagesSortedByPhase } from '../../../selectors/stagesSelectors';
import { getFeedingSchedule, getFeedingScheduleLocations } from '../../../selectors/feedingSchedulesSelectors';

export class ModifyFeedingSchedulePage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  componentWillMount() {
    this.props.actions.getUnpaginatedData('/api/cultivation/sections', dataNames.sections);
    this.props.actions.getUnpaginatedData('/api/stages', dataNames.stages);
    this.props.actions.getUnpaginatedData('/api/ingredient_items/by_facility', dataNames.ingredients).then(() => {
      this.props.actions.getItem(`/api/schedules/${this.props.params.id}`, itemNames.feedingSchedule);
    });
  }

  componentWillUnmount() {
    this.props.actions.unsetData(dataNames.sections);
  }

  redirect() {
    this.props.actions.goBack();
  }

  onSubmit(feedingSchedule, dispatch) {
    if (!feedingSchedule['ingredients']) {
      feedingSchedule['ingredients'] = [];
    }

    feedingSchedule['amount_uom'] = UOMS.L;
    feedingSchedule['time_uom'] = 'DAY';

    this.props.actions.putItem(
      `/api/schedules/${this.props.params.id}`,
      feedingSchedule,
      itemNames.feedingSchedule,
      { success: 'feedingSchedules.modify.actions.success', failed: 'feedingSchedules.modify.actions.fail' },
      null,
      this.props.actions.goBack
    );
  }

  render() {
    const { sections, stages, ingredients, feedingSchedule, formValues } = this.props;
    feedingSchedule['rooms'] =
      feedingSchedule && feedingSchedule.locations && sections.length
        ? feedingSchedule.locations.map((location) => {
          const locationId = location.location_id;
          const section = sections.find((section) => section.id === locationId);

          return section;
        })
        : [];

    return (
      <FormWrapper title={'feedingSchedules.modify.title'} goBack={this.redirect}>
        <FeedingScheduleFormWrapper
          isEditMode={true}
          enableReinitialize={true}
          initialValues={feedingSchedule}
          locations={sections || []}
          stages={stages || []}
          ingredientsList={ingredients || []}
          formValues={formValues}
          onSubmit={this.onSubmit}
        />
      </FormWrapper>
    );
  }
}

ModifyFeedingSchedulePage.propTypes = {
  actions: PropTypes.object.isRequired,
  feedingSchedule: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  sections: PropTypes.array.isRequired,
  stages: PropTypes.array.isRequired,
  ingredients: PropTypes.array.isRequired,
  formValues: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
  const feedingSchedule = getFeedingSchedule(state);
  const formValues = getFormValues(FEEDING_SCHEDULE_FORM)(state) || feedingSchedule;
  return {
    feedingSchedule,
    sections: sortBy(getFeedingScheduleLocations(state), ['name', 'asc']),
    stages: getStagesSortedByPhase(state).map((stage) => ({ ...stage, stage_id: stage.id })),
    ingredients: getIngredientsFofFeedingSchedules(state),
    formValues
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, { goBack, unsetData });
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModifyFeedingSchedulePage);
