import React from 'react';
import map from 'lodash.map';
import isEmpty from 'lodash.isempty';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

class RenderCommonRowHistory extends React.PureComponent {

  renderStringValues() {
    const { label, name, prev, current } = this.props.field;

    return (
      <tr key={`${name}`}>
        <td>{label ? I18n.t(label) : I18n.t(`product.form.${name}`)}</td>
        <td>{prev} → {current}</td>
      </tr>
    );
  }

  renderObjectData() {
    const { label, name, prev, current } = this.props.field;

    return (
      <tr key={`${name}`}>
        <td>{label ? I18n.t(label) : I18n.t(`product.form.${name}`)}</td>
        <td>
          {this.renderValues(prev)} → {this.renderValues(current)}
        </td>
      </tr>
    );
  }

  renderValues(value) {
    if (React.isValidElement(value)) {
      return value;
    } else if (isEmpty(value)) {
      return 'null';
    } else if (typeof value === 'string') {
      return value;
    } else if (typeof value === 'object') {
      return map(value, (value, key) => {
        return <div key={key}>{JSON.stringify(value).replace(/"/ig, '')}</div>;
      });
    }

    return null;
  }

  render() {
    const { prev, current, type } = this.props.field;

    // Do not render sales fields because they are rendered using another Component.
    if (type === 'sales') {
      return null;
    }

    return (typeof prev === 'string' && typeof current === 'string')
      ? this.renderStringValues()
      : this.renderObjectData();
  }
}

RenderCommonRowHistory.propTypes = {
  field: PropTypes.object.isRequired
};

export default RenderCommonRowHistory;
