import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { goBack } from 'react-router-redux';
import { change, reset, arrayRemoveAll, initialize } from 'redux-form';
import get from 'lodash.get';

import FormWrapper from '../../common/form/FormWrapper';
import InProgressOverlay from '../../common/InProgressOverlay';
import {
  pricingOptions,
  onlineAvailabilityOptions,
  potencyList,
  terpeneList,
  solvents,
  activationTimes,
  dilutions
} from '../common/data';
import WrappedProductForm from '../common/ProductForm';
import { getPricingWeightsForDisplaying, getProductTypeOptions } from '../../../selectors/productsSelector';
import { isMetrcIntegrator, getMetrcCategoryFromOrgSubcategoryId } from '../../../selectors/integration/metrcSelectors';
import { getIntegrationState } from '../../../selectors/integration/integrationSelectors';
import {
  getModifyProductInitialValues,
  getCreateProductInitialValues,
  getCategoryId,
  getFormSubcategories,
  getProductPricingGroups,
  getSaveProductPayload,
  subCategoryMapErrors,
  getUomOptions,
  getStrains,
  isStrainRequired,
  getRequiredFieldsFromSubCategory
} from '../../../selectors/forms/productFormSelectors';
import { unsetItem, setItem } from '../../../actions/itemActions';
import * as apiActions from '../../../actions/apiActions';
import { setData, unsetData } from '../../../actions/dataActions';
import { getIntegrationCategoriesData } from '../../../actions/integrationActions';
import { getMetrcFilteredCategories } from '../../../selectors/categorySelectors';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import { PRODUCT_FORM } from '../../../constants/forms';
import * as UOMS from '../../../constants/uoms';
import dominance from '../../../constants/strainDominance';
import { getFacilitiesOptions } from '../../../selectors/accountingSelectors';
import { getRetailFacilityOptions } from '../../../selectors/facilitiesSelectors';
import { isSeedPackagingAllowed } from '../../../selectors/forms/prepackWeightsFacilitySelectors';
import { getIsConnectsActive } from '../../../selectors/connectsSelectors';
import * as messageTypes from '../../../constants/messageTypes';

export class CreateProductPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      ready: 0,
      selectedStrain: 0,
      selectedDom: 0,
      uploading: false,
      saving: false,
      loadingMessage: 'products.form.savingProduct',
      showOk: false,
      onDismiss: this.onDismissLoader,
      showLoader: true,
      isPrepackProduct: false,
      isDuplicate: this.props.location && this.props.location.state ? true : false
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubcategoryChange = this.onSubcategoryChange.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.onDismissLoader = this.onDismissLoader.bind(this);
    this.showDelayMessage = this.showDelayMessage.bind(this);
    this.setDefaultMessageShow = this.setDefaultMessageShow.bind(this);
    this.formatDuplicatePayload = this.formatDuplicatePayload.bind(this);
  }

  componentWillMount() {
    // Duplication block
    if (this.state.isDuplicate) {
      this.props.actions.unsetItem(itemNames.image);
      this.props.actions.unsetItem(itemNames.product);
      this.props.actions.unsetItem(itemNames.detailedProduct); // Ugly hack to get product in since product has been used to hold initialValues for some crazy reason
      this.props.actions.unsetData(dataNames.inventoryItems);
      this.props.actions.unsetData(dataNames.childItemMasters);
      this.props.actions.unsetData(dataNames.facilityStrains); // moved from willUnmount - was throwing a lifecycle error
      const { id } = this.props.location.state; // item_master_id from row selected on product page
      Promise.all([
        this.props.actions.getItem(
          `/api/item_masters/${id}`,
          itemNames.product,
          undefined,
          { detailed: 1 },
          (itemMaster) => {
            this.props.actions.setItem(itemMaster, itemNames.detailedProduct); // More ugly hack all to keep us from rendering too soon
            if (itemMaster.inventory_attributes && itemMaster.inventory_attributes.is_prepack) {
              this.props.actions.getUnpaginatedData(
                '/api/item_masters/children/items',
                dataNames.childItemMasters,
                undefined,
                { ids: [itemMaster.id], is_waste: 0, include_inactive_items: true }
              );
            }
          }
        ),
        this.props.actions.getUnpaginatedData(
          '/api/pricing_classes',
          dataNames.pricingClasses,
          { failed: 'retail.pricingClass.get.failed' },
          { active: 1 }
        ),
        this.props.actions.getUnpaginatedData('/api/brands', dataNames.brands),
        this.props.actions.getUnpaginatedData('/api/strains/by_organization', dataNames.organizationStrains),
        this.props.actions.getUnpaginatedData('/api/strains/by_facility', dataNames.facilityStrains),
        this.props.actions.getUnpaginatedData('/api/uoms/inventory', dataNames.uoms),
        this.props.actions
          .getUnpaginatedData('/api/categories?is_only_active=1', dataNames.categories)
          .then(() => this.props.actions.getIntegrationCategoriesData()),
        this.props.actions.getUnpaginatedData('/api/partners', dataNames.partners, undefined, {
          purchase_from: 1,
          exclude_internal_duplicates: 1
        }),
        this.props.actions.getUnpaginatedData('/api/tags', dataNames.tags),
        this.props.actions.getUnpaginatedData('/api/pricing_groups', dataNames.pricingGroups, null, { detailed: 1 }),
        this.props.actions.getUnpaginatedData('/api/consumer_groups', dataNames.customerGroups, {
          failed: 'customers.getGroups.failed'
        }),
        this.props.actions.getUnpaginatedData('/api/pricing_weights', dataNames.pricingWeights),
        this.props.actions.getUnpaginatedData('/api/facility_groups_sharings', dataNames.sharedFacilityGroups, {
          failed: 'failedToGetFacilityGroups'
        }),
        this.props.actions.getItem('/api/organizations/current_organization', itemNames.currentOrganization),
        this.props.actions.getUnpaginatedData('/api/integration-mapping', dataNames.integrationMapping, null, {
          key: 'product_identifier'
        })
      ])
        .then(() => {
          this.setState({ ready: this.state.ready + 1 });
          if (this.props.product.strain_id === null) return true;
          if (this.productStrainIsInStrains()) return true;

          // Get org strains and lock
          this.props.actions.getUnpaginatedData('/api/strains/by_organization', dataNames.facilityStrains).then(() => {
            this.setState({ strainLocked: true });
            if (!this.productStrainIsInStrains())
              this.props.actions.addMessage(messageTypes.error, 'products.getStrain.failed');
          });
        })
        .then(() => {
          //Check Washington integration inventory mapping
          if (this.props.integrationState.isWaLeaf) {
            this.props.actions
              .getUnpaginatedData('/api/leaf/get_wa_inventory_mapping/' + id, dataNames.getWaInventoryMapping)
              .then(() => {
                if (get(this.props, 'waInventoryMapping.0.id')) {
                  this.setState({ isWaInventoryMapped: true });
                }
              });
          }
        })
        .catch(() => this.setState({ ready: this.state.ready + 1 }));
        // Create new block
    } else {
      this.props.actions.unsetItem(itemNames.product);
      this.props.actions.getUnpaginatedData('/api/brands', dataNames.brands);
      this.props.actions.getUnpaginatedData('/api/strains/by_organization', dataNames.organizationStrains);
      this.props.actions.getUnpaginatedData('/api/strains/by_facility', dataNames.facilityStrains);
      this.props.actions.getUnpaginatedData('/api/uoms/inventory', dataNames.uoms);
      this.props.actions
        .getUnpaginatedData('/api/categories?is_only_active=1', dataNames.categories)
        .then(() => this.props.actions.getIntegrationCategoriesData());
      this.props.actions.getUnpaginatedData('/api/partners', dataNames.partners, undefined, {
        purchase_from: 1,
        exclude_internal_duplicates: 1
      });
      this.props.actions.getUnpaginatedData('/api/tags', dataNames.tags);
      this.props.actions.getUnpaginatedData(
        '/api/pricing_groups',
        dataNames.pricingGroups,
        { failed: 'retail.pricingGroup.get.failed' },
        { detailed: 1 }
      );
      this.props.actions.getUnpaginatedData(
        '/api/pricing_classes',
        dataNames.pricingClasses,
        { failed: 'retail.pricingClass.get.failed' },
        { active: 1 }
      );
      // this.props.actions.getUnpaginatedData('/api/activation_times', dataNames.activationTimes); hardcoding for now
      this.props.actions.getUnpaginatedData('/api/consumer_groups', dataNames.customerGroups, {
        failed: 'customers.getGroups.failed'
      });
      this.props.actions.getUnpaginatedData('/api/pricing_weights', dataNames.pricingWeights);
      this.props.actions.getItem('/api/compliance_settings', itemNames.complianceSettings);
      this.props.actions.getItem('/api/organizations/current_organization', itemNames.currentOrganization);
      // Integration settings are downloaded to see if Metrc intergation settings exist. If the Metrc settings exist,
      // then the Medicated Net Weight and Product Net Weight inputs should be required when a user selects a sub-category
      // that is mapped to a METRC category which requires one or both of these values. In other words, some METRC categories
      // require a product to have a Medicated Net Weight, some METRC categories require a product to have a Product Net Weight,
      // some METRC categories require both values to be specified and some METRC categories do not require either value.
      // REPLACED BY getIntegrationCategoriesData above BUT LEFT HERE IN CASE THERES SOME WEIRD BREAKAGE... but nuke on next pass.
      // this.props.actions.getItem(
      //   '/api/integration-settings',
      //   itemNames.integrationSettings,
      //   {failed: 'stateIntegratorSettings.get.failed'}
      // ).then(() => {
      //   if (this.props.hasMetrcSettings) {
      //     this.props.actions.getUnpaginatedData(
      //       '/api/metrc/item_categories',
      //       dataNames.metrcCategories,
      //       {failed: 'categories.getMetrc.failed'}
      //     );
      //     this.props.actions.getUnpaginatedData(
      //       '/api/metrc/item_categories/mapping',
      //       dataNames.metrcCategoryMappings,
      //       {failed: 'categories.getMetrcMappings.failed'}
      //     );
      //   }
      // });
    }
  }

  componentWillReceiveProps(nextProps) {
    // Force set in the case of no default categoryId in props
    if (!this.props.categoryId) {
      this.props.actions.change(PRODUCT_FORM, 'category_id', 1);
    }
    // Force set in the case of a single uom... its getting set but the uom_type is not unless you click in form so fails BE validation
    if (JSON.stringify(this.props.uoms) !== JSON.stringify(nextProps.uoms) && nextProps.uoms.length === 1) {
      const uom = Object.assign({}, nextProps.uoms[0]);
      this.props.actions.change(PRODUCT_FORM, 'default_uom', uom.uom_code);
      this.props.actions.change(PRODUCT_FORM, 'uom_type', uom.uom_type);
    }
    // if (!nextProps[itemNames.detailedProduct].name) return false;
    this.setState({ ready: this.state.ready + 1 }); // Hacky way to keep the form from initializing too soon.
  }

  productStrainIsInStrains() {
    const { product, strains } = this.props;
    return strains.some((strain) => strain.id === product.strain_id);
  }

  onDismissLoader() {
    this.setState({ saving: false });
  }

  onSubmit(formData) {
    this.setState({ saving: true });
    let afterSubmit = () =>
      this.showDelayMessage(() => {
        this.setDefaultMessageShow();
        this.setState({ saving: false });
      });
    switch (formData.afterSubmit) {
    case 'clear':
      afterSubmit = () => {
        const callback = () => {
          this.props.actions.reset(PRODUCT_FORM) && this.resetForm();
          this.setState({ saving: false });
          this.setDefaultMessageShow();
        };
        this.showDelayMessage(callback);
      };
      break;
    case 'redirect':
      afterSubmit = () => {
        const callback = () => {
          this.props.actions.goBack();
          this.setState({ saving: false });
          this.setDefaultMessageShow();
        };
        this.showDelayMessage(callback);
      };
      break;
    case 'nothing':
      afterSubmit = () => {
        const callback = () => {
          this.setState({ saving: false });
          this.setDefaultMessageShow();
        };
        this.showDelayMessage(callback);
      };
      break;
    default:
      break;
    }
    this.setState({ isPrepackProduct: formData.inventory_attributes && formData.inventory_attributes.is_prepack });
    let data = getSaveProductPayload(formData, 'create', this.props.categories, this.props.subcategories);
    data = this.formatDuplicatePayload(data);
    if (this.props.integrationState.isMdMetrc || this.props.integrationState.isOrMetrc)
      data['configuration_validated'] = 1; // Because some of metrcMD is breaking fundamental validation
    return this.props.actions
      .postItem(
        '/api/item_masters',
        data,
        itemNames.product,
        { success: 'products.create.success', failed: 'products.create.failed' },
        {},
        afterSubmit
      )
      .catch((error) => this.setState({ saving: false }));
  }
  /*
  * Formats payload to properly duplicate item_master without modifying original
  */
  formatDuplicatePayload(data) {
    // const { id } = this.props.location.state;
    if (data.id) {
      delete data.id;
      delete data.item_number_prefix;
    }
    if(this.state.isDuplicate && this.props.product.item_number == data.item_number){
      data.item_number = '';
    }
    for (let i = 0; i < data.attribute_lists.length; i++) {
      if (data.attribute_lists[i].id) {
        delete data.attribute_lists[i].id;
      }
      if (data.attribute_lists[i].item_master_id) {
        delete data.attribute_lists[i].item_master_id;
      }
    }
    if (data.inventory_attributes) {
      delete data.inventory_attributes.id;
      delete data.inventory_attributes.item_master_id;
    }
    if (data.pricing_details) {
      if (data.pricing_details.price_lists) {
        for (let j = 0; j < data.pricing_details.price_lists.length; j++) {
          if (data.pricing_details.price_lists[j].id) {
            delete data.pricing_details.price_lists[j].id;
          }
          if (delete data.pricing_details.price_lists[j].item_master_id) {
            delete data.pricing_details.price_lists[j].item_master_id;
          }
          for (let k = 0; k < data.pricing_details.price_lists[j].weight_prices.length; k++) {
            if (data.pricing_details.price_lists[j].weight_prices[k].id) {
              delete data.pricing_details.price_lists[j].weight_prices[k].id;
              delete data.pricing_details.price_lists[j].weight_prices[k].price_list_id;
            }
          }
        }
      }
    }
    if (data.product_images) {
      for (let l = 0; l < data.product_images.length; l++) {
        if (data.product_images[l].item_master_id) {
          delete data.product_images[l].item_master_id;
        }
      }
    }
    if (data.purchasing_attributes) {
      delete data.purchasing_attributes.id;
      delete data.purchasing_attributes.item_master_id;
      if (data.purchasing_attributes.buffer === null) {
        delete data.purchasing_attributes.buffer;
      }
    }
    if (data.sales_attributes) {
      delete data.sales_attributes.id;
      delete data.sales_attributes.item_master_id;
    }
    if (data.vendors) {
      for (let m = 0; m < data.vendors.length; m++) {
        if (data.vendors[m].id) {
          delete data.vendors[m].id;
        }
        if (data.vendors[m].item_master_id) {
          data.vendors[m].item_master_id;
        }
      }
    }
    return data;
  }

  onSubcategoryChange(id) {
    // TODO: Move to middleware
    const { getMetrcCategoryFromOrgSubcategoryId } = this.props;
    const { change } = this.props.actions;
    const metrcSubCategory = getMetrcCategoryFromOrgSubcategoryId({ id });
    const requiredFields = getRequiredFieldsFromSubCategory(metrcSubCategory);
    change(PRODUCT_FORM, 'default_uom', '');
    change(PRODUCT_FORM, 'uom_type', '');
    change(PRODUCT_FORM, 'sales_attributes.pricing_type', '');
    if (requiredFields.uom_type && this.props.uoms.length === 1) {
      const value = requiredFields.uom_type === 'weight' ? UOMS.GR : UOMS.EA;
      const selectedOption = this.props.allUoms.find((uom) => uom.uom_code === value);
      change(PRODUCT_FORM, 'default_uom', value);
      change(PRODUCT_FORM, 'uom_type', (selectedOption && selectedOption.uom_type) || '');
    }
    change(PRODUCT_FORM, 'required_fields', requiredFields);
  }

  showDelayMessage(func) {
    if (this.state.isPrepackProduct) {
      const newState = {
        saving: true,
        showOk: true,
        onDismiss: func,
        loadingMessage: 'products.form.savePrepackItemMaster',
        showLoader: false
      };
      this.setState(newState);
    } else {
      func();
    }
  }

  setDefaultMessageShow() {
    const newState = {
      saving: false,
      showOk: false,
      onDismiss: this.onDismissLoader,
      loadingMessage: 'products.form.savingProduct',
      showLoader: true,
      isPrepackProduct: false
    };
    this.setState(newState);
  }

  resetForm() {
    this.props.actions.unsetItem(itemNames.image);
  }

  render() {
    const {
      pricingWeights,
      strains,
      uoms,
      allUoms,
      partners,
      categories,
      pricingGroups,
      tags,
      dominance,
      dilutions,
      hasMetrcSettings,
      productTypeOptions,
      subcategories,
      facilityOptions,
      retailFacilityOptions,
      subCategoryMapErrors,
      isCanada,
      isSeedPackagingAllowed,
      pricingClasses,
      brands,
      weedmapsCategoryMappings,
      isolocityCategoryMappings,
      isConnectsActive,
      facility,
      strainRequired,
      metrcCategoryMappings,
      metrcCategories,
      product
    } = this.props;

    const { selectedStrain, selectedDom } = this.state;

    // Runs when duplicating product
    if (this.state.isDuplicate) {
      if (product.dominance !== undefined && product.dominance !== '') {
        if (this.props.dominance) {
          const selectedDom = this.props.dominance.find((dom) => product.dominance === dom.value);
          if (selectedDom) {
            product.dominance_id = selectedDom.id;
          }
        }
      }
    }

    const initialValues = Object.assign({}, this.props.initialValues, {
      strain_id: selectedStrain.id !== undefined ? selectedStrain.id : selectedStrain,
      dominance_id: selectedDom.id !== undefined ? selectedDom.id : selectedDom,
      vendors: [{}],
      medicated_weight_uom: 'MG',
      tags: [],
      is_sales_item: true
    });

    if (this.state.isDuplicate && this.state.ready < 2) return null;

    const initValues = this.state.isDuplicate ? product : initialValues;

    return (
      <FormWrapper
        className='create-product-page'
        title={'products.create.title'}
        goBack={this.props.actions.goBack}
        formName={PRODUCT_FORM}
        shouldWarnDirty={true}
      >
        <InProgressOverlay
          isActive={this.state.saving}
          message={this.state.loadingMessage}
          onDismiss={this.state.onDismiss}
          showOk={this.state.showOk}
          showLoader={this.state.showLoader}
          translate={true}
        />
        <WrappedProductForm
          form={PRODUCT_FORM}
          initialValues={initValues}
          enableReinitialize={true}
          keepDirtyOnReinitialize={true}
          solventOptions={solvents}
          dilutionOptions={dilutions}
          categoryOptions={categories}
          strainOptions={strains}
          strainRequired={strainRequired}
          dominanceOptions={dominance}
          vendorOptions={partners}
          pricingOptions={pricingOptions}
          activationTimeOptions={activationTimes}
          onlineAvailabilityOptions={onlineAvailabilityOptions}
          uomOptions={uoms}
          allUoms={allUoms}
          pricingClasses={pricingClasses}
          brands={brands}
          pricingGroupOptions={pricingGroups}
          pricingWeights={pricingWeights}
          tagOptions={tags}
          potencyList={potencyList}
          terpeneList={terpeneList}
          onSubmit={this.onSubmit}
          onStrainChange={this.onStrainChange}
          selectedStrain={selectedStrain}
          selectedDom={selectedDom}
          strainLocked={false}
          onSubcategoryChange={this.onSubcategoryChange}
          resetForm={this.resetForm}
          productTypeOptions={productTypeOptions}
          hasMetrc={hasMetrcSettings}
          isCanada={isCanada}
          isSeedPackagingAllowed={isSeedPackagingAllowed}
          subcategories={subcategories}
          facilityOptions={facilityOptions}
          retailFacilityOptions={retailFacilityOptions}
          subCategoryMapErrors={subCategoryMapErrors}
          weedmapsCategoryMappings={weedmapsCategoryMappings}
          isolocityCategoryMappings={isolocityCategoryMappings}
          isConnectsActive={isConnectsActive}
          facility={facility}
          categories={categories}
          metrcCategories={metrcCategories}
          metrcCategoryMappings={metrcCategoryMappings}
        />
      </FormWrapper>
    );
  }
}

CreateProductPage.propTypes = {
  pricingWeights: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    unsetItem: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    arrayRemoveAll: PropTypes.func.isRequired
  }).isRequired,
  solvents: PropTypes.array.isRequired,
  dilutions: PropTypes.array.isRequired,
  strains: PropTypes.array.isRequired,
  dominance: PropTypes.array,
  uoms: PropTypes.array.isRequired,
  allUoms: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  partners: PropTypes.array.isRequired,
  activationTimes: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  pricingGroups: PropTypes.array.isRequired,
  pricingClasses: PropTypes.array.isRequired,
  initialValues: PropTypes.object,
  hasMetrcSettings: PropTypes.bool.isRequired,
  isCanada: PropTypes.bool.isRequired,
  isSeedPackagingAllowed: PropTypes.bool.isRequired,
  getMetrcCategoryFromOrgSubcategoryId: PropTypes.func.isRequired,
  productTypeOptions: PropTypes.array.isRequired,
  subcategories: PropTypes.array,
  facilityOptions: PropTypes.array.isRequired,
  retailFacilityOptions: PropTypes.array.isRequired,
  integrationState: PropTypes.object,
  brands: PropTypes.array.isRequired,
  facility: PropTypes.object,
  metrcCategoryMappings: PropTypes.array,
  strainRequired: PropTypes.bool,
  metrcCategories: PropTypes.array,
  location: PropTypes.object
};

function mapStateToProps(state) {
  const { tags, partners } = state;
  const product = getModifyProductInitialValues(state);
  const categoryId = getCategoryId(state);
  const initialValues = getCreateProductInitialValues(state);
  const pricingWeights = getPricingWeightsForDisplaying(state);
  const strains = getStrains(state);
  const brands = state[dataNames.brands];
  const weedmapsCategoryMappings = state[dataNames.weedmapsCategoryMappings];
  const subcategories = getFormSubcategories(state);
  const categories = getMetrcFilteredCategories(state);
  const integrationState = getIntegrationState(state);
  const isConnectsActive = getIsConnectsActive(state, { moduleKey: 'CONNECTS' });
  const strainRequired = isStrainRequired(state);
  const isolocityCategoryMappings = state[dataNames.isolocityCategoryMappings];

  return {
    product: product,
    brands,
    initialValues,
    solvents,
    pricingWeights,
    categoryId,
    pricingClasses: state[dataNames.pricingClasses],
    pricingGroups: getProductPricingGroups(state),
    activationTimes,
    dominance,
    tags,
    partners,
    categories,
    subcategories,
    allUoms: state.uoms,
    uoms: getUomOptions(state),
    dilutions,
    strains,
    weedmapsCategoryMappings,
    isolocityCategoryMappings,
    hasMetrcSettings: isMetrcIntegrator(state),
    getMetrcCategoryFromOrgSubcategoryId: (subcategory) => getMetrcCategoryFromOrgSubcategoryId(state, subcategory),
    productTypeOptions: getProductTypeOptions(state),
    facilityOptions: getFacilitiesOptions(state),
    retailFacilityOptions: getRetailFacilityOptions(state),
    subCategoryMapErrors: subCategoryMapErrors(state),
    isCanada: integrationState.isCanada,
    isSeedPackagingAllowed: isSeedPackagingAllowed(state),
    metrcCategoryMappings: state[dataNames.metrcCategoryMappings],
    metrcCategories: state[dataNames.metrcCategories],
    integrationState: integrationState,
    isConnectsActive: isConnectsActive,
    facility: state[itemNames.facility],
    strainRequired
  };
}

function mapDispatchToProps(dispatch) {
  const collectedActions = {
    goBack,
    reset,
    change,
    unsetItem,
    setItem,
    setData,
    unsetData,
    arrayRemoveAll,
    initialize,
    getIntegrationCategoriesData
  };
  return {
    actions: bindActionCreators(Object.assign({}, apiActions, collectedActions), dispatch)
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CreateProductPage)
);
