import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {push} from 'react-router-redux';
import * as dataNames from '../../constants/dataNames';
import {GR} from '../../constants/uoms';
import {hasCanadianFacilities} from '../../selectors/facilitiesSelectors';
import {getUnpaginatedData, postData} from '../../actions/apiActions';
import {setSelectedData, setSelectedDataImmediate, addSelectedData} from '../../actions/selectedDataActions';
import PackagingButtons from './PackagingButtons';
import {isSubCategorySeeds} from '../../selectors/categorySelectors';
import {isSeedPackagingAllowed} from '../../selectors/forms/prepackWeightsFacilitySelectors';
import {getIntegrationState} from '../../selectors/integration/integrationSelectors';
import {nonTestableInventoryTypes} from '../../constants/integration/biotrack/biotrackInventoryTypes';
import {addMessage} from '../../actions/systemActions';
import * as messageTypes from '../../constants/messageTypes';
import * as itemTypes from '../../constants/itemTypes';
import {getProductType} from '../../selectors/itemMastersSelectors';
import {getInventoryComplianceSettings, isInvPackageTrackingNotInCompliance} from '../../selectors/complianceSettingsSelectors';
import {getUseEntityLocksForItems} from '../../selectors/coreSettingsSelectors';


class PackagingRows extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.state = {packagingJobs: {}, pending: true};

    this.cancelPackaging = this.cancelPackaging.bind(this);
    this.completePackaging = this.completePackaging.bind(this);
    this.loadPackagingJobs = this.loadPackagingJobs.bind(this);
    this.checkValidInventory = this.checkValidInventory.bind(this);
  }

  componentWillMount() {
    this.loadPackagingJobs();
  }

  loadPackagingJobs() {
    const {row: {item_id}} = this.props;
    this.props.actions.getUnpaginatedData(
      '/api/packaging_jobs',
      dataNames.packagingJobs,
      {failed: 'packagingJobs.get.failed'},
      {source_item_id: item_id},
      (packagingJobs) => this.setState({
        packagingJobs: packagingJobs.filter(job => job.status === 'open'),
        pending: false,
      })
    );
  }

  cancelPackaging(id) {
    this.setState({pending: true});
    this.props.actions.postData(
      `/api/packaging_jobs/${id}/cancel`,
      {},
      dataNames.packagingJobs,
      {success: 'cultivation.finishedProduct.form.cancelSuccess', failed: 'cultivation.finishedProduct.form.cancelFail'},
      {},
      this.loadPackagingJobs
    );
  }

  completePackaging(row, job) {

    const {
      complianceSettings : {inv_packages_require_tracking_id_message, inv_packages_require_tracking_id}
    } = this.props;

    if ( inv_packages_require_tracking_id  && isInvPackageTrackingNotInCompliance(row) ) {
      this.props.actions.addMessage(messageTypes.error, inv_packages_require_tracking_id_message, true);
      return false;
    }

    this.props.actions.setSelectedDataImmediate([{
      lot_id: row.lot_id,
      lot_number: row.lot_number,
      lab_results_id: row.lab_results_id,
      package_id: row.package_id,
      package_code: row.package_code,
    }], dataNames.packageTests);

    const isCanadianSeed = this.props.isCanada && this.props.isSeedPackagingAllowed && row.default_uom === GR && isSubCategorySeeds(row);
    this.props.actions.push(`/packages/complete/${isCanadianSeed ? 'seed/' : ''}${job.packaging_job_id}`);
  }

  checkValidInventory() {
    const {
      integrationState: {isBiotrack, isPrBiotrack, isWaLeaf, isUtah},
      row: {integration_type, package_code, status, item_id},
      row,
      complianceSettings : {inv_packages_require_tracking_id_message, inv_packages_require_tracking_id}
    } = this.props;

    if (isBiotrack) {
      const labResultFailed = isPrBiotrack ? status === 'failed' : status !== 'passed';

      if (nonTestableInventoryTypes.indexOf(parseInt(integration_type, 10)) === -1 && labResultFailed) {
        if (isPrBiotrack) {
          this.props.actions.addMessage(messageTypes.error, ['packages.modify.errorLabResultFailed', {package_id: package_code}]);
        } else {
          this.props.actions.addMessage(messageTypes.error, ['packages.modify.doesntHavePassedLabResult', {package_id: package_code}]);
        }
        return false;
      }
    }
    if(
      isWaLeaf &&
      getProductType(row) === itemTypes.bulk &&
      (status !== 'passed' && !row.extraction_eligible)
    ){
      this.props.actions.addMessage(messageTypes.error, 'packages.modify.doesntHavePassedLabResultLeafWa');
      return false;
    }

    if ( inv_packages_require_tracking_id  && isInvPackageTrackingNotInCompliance(row) ) {
      this.props.actions.addMessage(messageTypes.error, inv_packages_require_tracking_id_message, true);
      return false;
    }

    // Need to have a passed test result in order to pack
    if (isUtah && (typeof status === undefined || status !== 'passed')) {
      this.props.actions.addMessage(messageTypes.error, ['packages.modify.doesntHavePassedLabResult', {package_id: package_code}]);
      return false;
    }

    this.props.actions.push(`/packages/start/${item_id}`);
    return true;
  }

  render () {
    const {row, useEntityLocks} = this.props;
    const {packagingJobs, pending} = this.state;
    const job = packagingJobs.length ? {id: row.item_id, packaging_job_id: packagingJobs[0].id} : {id: row.item_id};
    // Uncomment if we want to hide the packaging buttons instead of showing an error message to the user
    //const {row: {status}, integrationState: {isUtah}} = this.props;
    //const disableButtons = isUtah && (typeof status === undefined || status !== 'passed');
    return (
      <PackagingButtons job={job}
        pending={pending}
        isLocked={useEntityLocks && row.is_locked}
        // Uncomment if we want to hide the packaging buttons instead of showing an error message to the user
        // disableButtons={disableButtons}
        cancelPackaging={this.cancelPackaging}
        checkValidInventory={this.checkValidInventory}
        completePackaging={e => this.completePackaging(row, job)}
      />);
  }
}

PackagingRows.propTypes = {
  row: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  isCanada: PropTypes.bool,
  isSeedPackagingAllowed: PropTypes.bool,
  stateIntegrators: PropTypes.object
};

function mapStateToProps(state) {
  return {
    isCanada: hasCanadianFacilities(state),
    isSeedPackagingAllowed: isSeedPackagingAllowed(state),
    integrationState: getIntegrationState(state),
    complianceSettings : getInventoryComplianceSettings(state),
    useEntityLocks: getUseEntityLocksForItems(state)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {push, getUnpaginatedData, postData, setSelectedData, setSelectedDataImmediate, addSelectedData, addMessage};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (PackagingRows);
