import {I18n} from 'react-redux-i18n';

const registerTransactionTypes = [
  {value: '', text: I18n.t('common.form.selectPlaceholder')},
  {value: 'MISCOUNT', text: I18n.t('registers.form.miscount')},
  {value: 'CASH_DROP', text: I18n.t('registers.form.cashDrop')},
  {value: 'RECEIVABLE', text: I18n.t('registers.form.accountsReceivable')},
  {value: 'INITIAL_BALANCE', text: I18n.t('registers.form.initialBalance')},
  {value: 'RECONCILIATION', text: I18n.t('registers.form.reconciliation')},
  {value: 'OPENING', text: I18n.t('registers.form.registerOpened')},
  {value: 'CLOSING', text: I18n.t('registers.form.registerClosed')},
];

export default registerTransactionTypes;
