import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {push} from 'react-router-redux';
import {first, get} from 'lodash';
import {Button} from 'react-bootstrap';
import * as dataNames from '../../constants/dataNames';
import {addMessage} from '../../actions/systemActions';
import {unsetData} from '../../actions/dataActions';
import TablePageWrapper from '../common/grid/TablePageWrapper';
import PageTitle from '../common/PageTitle';
import ModalWrapper from '../common/ModalWrapper';
import ListGroupPanel from '../common/ListGroupPanel';
import {clearSelectedData, setSelectedData} from '../../actions/selectedDataActions';
import {getPackagingJobsWithUsers} from '../../selectors/packagingJobSelectors';
import {getPaginatedData, getUnpaginatedData, postData} from '../../actions/apiActions';
import {getTimezone} from '../../selectors/timezoneSelectors';
import {convertDbDateTimeToFormInputDateTime} from '../../util/dateHelpers';

export class PackagingJobsListingPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.ref = React.createRef();
    this.switchTab = this.switchTab.bind(this);
    this.getColumns = this.getColumns.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.editPackagingJob = this.editPackagingJob.bind(this);
    this.cancelPackagingJob = this.cancelPackagingJob.bind(this);
    this.openEmployeesModal = this.openEmployeesModal.bind(this);
    this.ref = React.createRef();

    this.state = {
      activeTab: 'open',
      showEmployeesModal: false,
      packagingJobEmployees: null,
      selectedPackagingJobIds: []
    };

    this.tabs = [
      {
        id: 'packagingJobsActiveTab',
        eventKey: 'active',
        title: 'common.active',
        route: '/packaging_jobs',
        actions: [
          {
            id: 'createPackagingJob',
            path: '/packaging_jobs/packaging_job/new',
            text: I18n.t('packaging.listing.actions.create'),
            glyph: 'plus-sign',
            requireSelect: false
          },
          {
            id: 'modifyPackagingJob',
            func: this.editPackagingJob,
            text: I18n.t('packaging.listing.actions.edit'),
            glyph: 'th-list',
            requireSelect: true
          },
          {
            id: 'cancelPackagingJob',
            func: this.cancelPackagingJob,
            text: I18n.t('packaging.listing.actions.cancel'),
            variant: 'danger',
            requireSelect: true
          }
        ]
      },
      {
        id: 'packagingJobsInactiveTab',
        eventKey: 'completed',
        title: 'common.completed',
        route: '/packaging_jobs/completed',
        actions: [
          {
            id: 'createPackagingJob',
            path: '/packaging_jobs/packaging_job/new',
            text: I18n.t('packaging.listing.actions.create'),
            glyph: 'plus-sign',
            requireSelect: false
          },
          {
            id: 'viewPackagingJob',
            func: this.editPackagingJob,  // Completed PackingJobs will be read only
            text: I18n.t('packaging.listing.actions.view'),
            glyph: 'th-list',
            requireSelect: true
          }
        ]
      }
    ];
  }

  componentDidMount() {
    // Load employees
    this.props.actions.getUnpaginatedData('/api/users/current_facility', dataNames.currentFacilityUsers, {failed: 'packages.getUsers.failed'}, {select_columns: ['id', 'displayName']});
  }

  componentDidUpdate(prevProps) {
    if (this.props.params.tab !== prevProps.params.tab) {
      this.getData();
    }
  }

  getData() {
    if (this.ref.current) {
      this.ref.current.wrappedInstance.ref.current.debouncedExternalSearch();
    }
  }

  handleSearch(sort, search, size = 20, start) {
    const status = !this.props.params.tab ? 'open' : this.props.params.tab;

    const params = {status};
    if (search) {
      params.search = search;
    }
    if (sort) {
      params.sort = sort;
    } else {
      params.sort = status === 'open' ? 'created_at desc' : 'completed_at desc';
    }
    if (size) {
      params.per_page = size;
    }
    if (start) {
      params.page = (start ? start / size : 0) + 1;
    }

    // Save search params in state, so that we can use them to reload the data with the same params after cancelling a job
    this.setState({params});

    this.props.actions.getPaginatedData('/api/packaging_jobs/list', dataNames.packagingJobs, {failed: 'packages.getPackagingJobs.failed'}, params);
  }

  getColumns(activeTab) {
    const columns = [
      {
        dataId: 'id',
        hidden: true
      },
      {
        name: 'packaging.listing.package_number',
        dataId: 'package_number'
      },
      {
        name: 'packaging.listing.item_master_name',
        dataId: 'item_master_name'
      },
      {
        name: 'packaging.listing.package_code',
        dataId: 'package_code'
      },
      {
        name: 'packaging.listing.location_name',
        dataId: 'packaging_location_name',
        formatter: (cell, row) => {
          return get(row, 'packaging_location_name', '-');
        }
      },
      {
        name: 'packaging.listing.created_at',
        dataId: 'created_at',
        formatter: (cell) => convertDbDateTimeToFormInputDateTime(cell, this.props.timezone)
      }
    ];
    if (activeTab === 'completed') {
      columns.push(
        {
          name: 'packaging.listing.completed_at',
          dataId: 'completed_at',
          formatter: (cell) => convertDbDateTimeToFormInputDateTime(cell, this.props.timezone)
        }
      );
    }
    columns.push(
      {
        name: 'packaging.listing.employees',
        dataId: 'employees',
        dataSort: false,
        formatter: (cell, row) => { //eslint-disable-line react/no-multi-comp
          return row.employees && row.employees.length ? (
            <Button variant='primary' size='small' onClick={event => this.openEmployeesModal(event, row.employees)}>
              {I18n.t('packaging.listing.actions.view')}
            </Button>
          ) : '';
        }
      }
    );

    return columns;
  }

  switchTab(activeTab) {
    this.props.actions.unsetData(dataNames.packagingJobs);
    this.props.actions.push(`/packaging_jobs/${activeTab}`);
  }

  handleSelect(id, isSelected, row) {
    this.setState({ selectedPackagingJob: isSelected ? [id] : [] });
    const {clearSelectedData} = this.props.actions;
    if (!isSelected) {
      clearSelectedData(dataNames.packagingJobs);
    }
  }

  editPackagingJob() {
    const selectedId = first(this.state.selectedPackagingJob);
    this.props.actions.push(`/packaging_jobs/packaging_job/${selectedId}`);
  }

  cancelPackagingJob(event) {
    const selectedId = first(this.state.selectedPackagingJob);
    const messages =  { success: 'packages.listing.messages.cancel.success', failed: 'packages.listing.messages.cancel.failed' };
    this.props.actions.postData(`/api/packaging_jobs/${selectedId}/cancel`, {}, null, messages)
      .then(() => {
        // Retrieve current search params from state and reload data
        const params = get(this.state, 'params', {});
        this.handleSearch(get(params, 'sort'), get(params, 'search'), get(params, 'size'), get(params, 'start'));
      });
  }

  openEmployeesModal(event, packagingJobEmployees) {
    event.stopPropagation();
    this.setState({ showEmployeesModal: true, packagingJobEmployees });
  }

  render () {
    const {params, packagingJobs} = this.props;
    const {showEmployeesModal, packagingJobEmployees, selectedPackagingJob} = this.state;
    const activeTab = params.tab || 'active';

    return (
      <div>
        <PageTitle primaryText={I18n.t('packaging.listing.title')}/>
        <ModalWrapper
          Component={ListGroupPanel}
          onHide={() => this.setState({ showEmployeesModal: false, packagingJobEmployees: [] })}
          showModal={showEmployeesModal}
          title={I18n.t('packaging.listing.actions.view')}
          data={packagingJobEmployees}
        />
        <TablePageWrapper
          settingKey='packaging-jobs'
          ref={this.ref}
          columns={this.getColumns(activeTab)}
          activeTab={activeTab}
          tabs={this.tabs}
          switchTab={this.switchTab}
          data={packagingJobs}
          selectedRows={selectedPackagingJob}
          handleSelect={this.handleSelect}
          externalSearch={this.handleSearch}
          external={true}
          hideScanSearch={true}
          hideExport={true}
          bstProps={{
            selectRow: {
              mode: 'radio',
              clickToSelect: true,
              selected: selectedPackagingJob,
              onSelect: (row, isSelected) => this.handleSelect(row.id, isSelected, row),
            }
          }}
        />
      </div>
    );
  }
}

PackagingJobsListingPage.propTypes = {
  params: PropTypes.object.isRequired,
  packagingJobs: PropTypes.array.isRequired,
  selectedPackagingJobIds: PropTypes.array,
  timeZone: PropTypes.object.isRequired,
  dataTotalSize: PropTypes.number,
  actions: PropTypes.shape({
    postData: PropTypes.func.isRequired,
    addMessage: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    setSelectedData: PropTypes.func.isRequired,
    clearSelectedData: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired,
  })
};

function mapStateToProps(state) {
  return {
    packagingJobs: getPackagingJobsWithUsers(state),
    timezone: getTimezone(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({postData, getPaginatedData, getUnpaginatedData, addMessage, push, unsetData, setSelectedData, clearSelectedData}, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PackagingJobsListingPage);
