/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {goBack} from 'react-router-redux';
import {getFormValues, isPristine} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import {Tabs, Tab} from 'react-bootstrap';
import * as dataNames from '../../constants/dataNames';
import * as apiActions from '../../actions/apiActions';
import * as dataActions from '../../actions/dataActions';
import {setItem} from '../../actions/itemActions';
import * as itemNames from '../../constants/itemNames'; // eslint-disable-line import/no-named-as-default
import FormWrapper from '../common/form/FormWrapper';
import SelectPackagesFacilityForm from './SelectPackagesFacilityForm';
import WillRender from '../common/concealers/WillRender';
import getCurrentFacility from '../../selectors/facility/getCurrentFacility';
import {
  getInitialValues,
  getSelectedUom,
  getPrepackWeightsWithHasInventory,
  getCategoryOptionsForPrepacks,
  getPayload,
  getPayloadForPrepackWeights,
  getUomsForTabs,
} from '../../selectors/forms/prepackWeightsFacilitySelectors';
import {getIntegrationState} from '../../selectors/integration/integrationSelectors';
import {getIsHempFacility} from '../../selectors/coreSettingsSelectors';
import {isFeatureEnabledProductMasters} from '../../selectors/accessSelectors';
import {PREPACK_WEIGHT_FACILITY_FORM} from '../../constants/forms';
import {PREPACK_APPLIES_TO} from '../../constants/prepacks';
import * as UOMS from '../../constants/uoms';
import {sortByRatioAndType} from '../../util/uomHelpers';


export class SelectPackagesFacilityPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.redirect = this.redirect.bind(this);
    this.addPrepackWeight = this.addPrepackWeight.bind(this);
    this.getPrepackWeights = this.getPrepackWeights.bind(this);
    this.getFacilityPrepackWeights = this.getFacilityPrepackWeights.bind(this);
    this.getPackagingSettings = this.getPackagingSettings.bind(this);
    this.setActiveTab = this.setActiveTab.bind(this);
    this.setActiveTypeTab = this.setActiveTypeTab.bind(this);

    this.state = {
      newWeight: '',
      saveWithoutMessage: false,
      showTabs: null,
      typeTab: 'weight',
      defaultUomTab: UOMS.GR,
      defaultVolumeUomTab: UOMS.L,
      activeKey: '',
      weightTabs: [],
      volumeTabs: [],
      allTabs: [],
    };
  }

  componentDidMount() {
    this.getPrepackWeights();
    this.getFacilityPrepackWeights();
    this.getUsedFacilityPrepackWeights();
    this.getPackagingSettings();
    this.getCategories();
  }

  componentWillUnmount() {
    this.props.actions.setItem({...this.props.applicationValues, activeTab: UOMS.GR}, itemNames.applicationValues);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      const newState = {};
      if(prevState.showTabs === null && Object.keys(this.props.integrationState).length) {
        newState.showTabs = this.props.isHempFacility;
      }
      if(this.state.activeKey === '' && this.props.uomTabs.length){
        const weightTabs = sortByRatioAndType(this.props.uomTabs, ['weight'], 'asc');
        const volumeTabs = sortByRatioAndType(this.props.uomTabs, ['volume'], 'asc');
        newState.weightTabs = weightTabs;
        newState.volumeTabs = volumeTabs;
        newState.activeKey = prevState.defaultUomTab;
      }
      this.setState(newState);

    }
  }

  setActiveTypeTab(typeTab){
    const activeKey = (typeTab === 'weight') ? this.state.defaultUomTab : this.state.defaultVolumeUomTab;
    this.setState({typeTab});
    this.setActiveTab(activeKey);
  }

  setActiveTab(uom){
    this.setState({
      uomTab: uom,
      activeKey: uom,
    });
    const applicationValues = Object.assign({}, this.props.applicationValues, {activeTab: uom});
    this.props.actions.setItem(applicationValues, itemNames.applicationValues);
  }

  getPackagingSettings() {
    this.props.actions.getItem('/api/inventory_settings', itemNames.complianceSettings, null, {ids: ['inv_packaging_seeds', 'inv_display_inactivation_reason']});
  }

  getCategories() {
    this.props.actions.getUnpaginatedData('/api/categories', dataNames.categories);
  }

  getPrepackWeights() {
    // Backfill refers to the fact that if no facility weight exist all org level are in play.
    const {getUnpaginatedData} = this.props.actions;
    getUnpaginatedData(
      '/api/prepack_weights?active=1&backfill=0',
      dataNames.prepackWeights,
      {failed: 'prepackWeights.getPrepackWeights.failed'},
      {}
    );
  }

  getFacilityPrepackWeights() {
    const {getUnpaginatedData} = this.props.actions;
    getUnpaginatedData(
      '/api/prepack_weights/facility',
      dataNames.prepackFacilityWeights,
      {failed: 'prepackWeights.getPrepackWeights.failed'},
      {}
    );
  }

  getUsedFacilityPrepackWeights() {
    this.props.actions.getUnpaginatedData('/api/prepack_weights/used', dataNames.usedFacilityPrepackWeights, undefined, {level: 'facility'});
  }

  redirect() {
    this.props.actions.goBack();
  }

  addPrepackWeight() {
    const {postData} = this.props.actions;
    //weight comes in a string and has trailing 0s. This causes issues when transferring packages between closed loop orgs.
    //The code below changes it to a number to remove trailing 0s, then back to a string
    const weightInString = get(this.props, 'formValues.newWeight');
    const weightInNumber = parseFloat(weightInString);
    const newWeight = weightInNumber.toString();

    if (newWeight && newWeight > 0) {
      this.setState({
        saveWithoutMessage: true
      }, () => {
        this.onSubmit(this.props.formValues);
      });

      postData('/api/prepack_weights',
        {weight: newWeight, uom: this.props.selectedUom},
        dataNames.prepackWeights,
        {success: 'prepackWeights.add.success', failed: 'prepackWeights.add.fail'},
        null,
        () => {
          this.setState({
            newWeight: '',
            saveWithoutMessage: false
          });
          this.getPrepackWeights();
        }
      );
    }
  }

  onSubmit(formValues) {

    if(get(formValues, 'newWeight')){
      return false; // Handled elsewhere.
    }

    const facilityId = this.props.facility.id;
    const settings = {settings: [
      {setting_key: 'inv_packaging_seeds', value: formValues.packaging_seeds, scope: 'facility', scope_id: facilityId},
      {setting_key: 'inv_display_inactivation_reason', value: formValues.display_inactivation_reason, scope: 'facility', scope_id: facilityId},
    ]};

    this.props.actions.postItem('/api/inventory_settings', settings)
      .then(this.getPackagingSettings);

    const messages = {
      success: 'prepackWeights.modify.success',
      failed: 'prepackWeights.modify.fail'
    };

    const payloadAppliesToProductMasters = getPayloadForPrepackWeights(formValues, 1);
    const payloadNotAppliesToProductMasters = getPayloadForPrepackWeights(formValues, 0);
    const payload = getPayload(formValues, this.props.selectedUom);
    Promise.all([
      this.props.actions.putData('/api/prepack_weights/update_many', payloadAppliesToProductMasters, undefined, messages),
      this.props.actions.putData('/api/prepack_weights/update_many', payloadNotAppliesToProductMasters, undefined, messages),
      this.props.actions.postData('/api/prepack_weights/facility', payload, undefined, messages)
    ]).then(() => {
      this.getPrepackWeights();
      this.getFacilityPrepackWeights();
    });
  }

  render() {
    const {initialValues, categories, formValues, selectedUom, formIsPristine, isHempFacility, isFeatureEnabledProductMasters} = this.props;
    const {showTabs, typeTab} = this.state;
    const appliesToOptions = PREPACK_APPLIES_TO.map(item => ({...item, text: I18n.t(item.text)}));
    return(
      <FormWrapper title={'prepackWeights.selectPackageSizes'} goBack={this.redirect}>

        {/*TOP LEVEL TABS*/}
        <WillRender ifTrue={showTabs && isHempFacility}>
          <Tabs
            activeKey={typeTab}
            onSelect={this.setActiveTypeTab}
          >
            {
              ['weight', 'volume'].map((tab, index) => {
                return (<Tab
                  eventKey={tab}
                  key={index}
                  disabled={!formIsPristine}
                  title={`BY ${tab.toUpperCase()}`}
                />);
              })
            }
          </Tabs>
        </WillRender>

        <div style={{clear: 'both', height: isHempFacility ? '2rem' : '0'}}/>

        <WillRender ifTrue={showTabs}>
          <div style={{padding: isHempFacility ? '3rem' : '0'}}>
            <Tabs
              activeKey={this.state.activeKey}
              onSelect={this.setActiveTab}
            >
              {
                this.state[`${this.state.typeTab}Tabs`].map((tab, index) => {
                  return (<Tab
                    eventKey={tab.uom_code}
                    key={index}
                    disabled={tab.uom_code !== this.state.activeKey && !formIsPristine}
                    title={I18n.t(`uoms.${tab.uom_code}.word`)}
                  />);
                })
              }
            </Tabs>
          </div>
        </WillRender>

        <WillRender ifTrue={!formIsPristine && isHempFacility}>
          <div style={{textAlign: 'center', margin: '0.5rem 0.5rem 0'}} className='text-muted'>
            {I18n.t('prepackWeights.form.tabsDisabledNotice')}<br />
            {I18n.t('prepackWeights.form.enabledTabsNotice')}
          </div>
        </WillRender>

        <div style={{padding: isHempFacility ? '2rem 5rem' : '2rem'}}>
          <SelectPackagesFacilityForm
            addPrepackWeight={this.addPrepackWeight}
            onSubmit={this.onSubmit}
            initialValues={initialValues}
            categories={categories}
            appliesToOptions={appliesToOptions}
            formValues={formValues}
            selectedUom={selectedUom}
            isFeatureEnabledProductMasters={isFeatureEnabledProductMasters}
          />
        </div>
      </FormWrapper>
    );
  }
}

SelectPackagesFacilityPage.propTypes = {
  actions: PropTypes.object.isRequired,
  prepackWeights: PropTypes.array.isRequired,
  facility: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  categories: PropTypes.array,
  formValues: PropTypes.object,
  uomTypes: PropTypes.object,
  applicationValues: PropTypes.object,
  selectedUom: PropTypes.string,
  formIsPristine: PropTypes.bool,
  uomTabs: PropTypes.array,
  isHempFacility: PropTypes.bool,
  isFeatureEnabledProductMasters: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    initialValues: getInitialValues(state),
    prepackWeights: getPrepackWeightsWithHasInventory(state),
    facility: getCurrentFacility(state),
    categories: getCategoryOptionsForPrepacks(state),
    formValues: getFormValues(PREPACK_WEIGHT_FACILITY_FORM)(state),
    uomTypes: get(state[itemNames.constants], 'inv.uom_types', {}),
    applicationValues: state[itemNames.applicationValues],
    selectedUom: getSelectedUom(state),
    formIsPristine: isPristine(PREPACK_WEIGHT_FACILITY_FORM)(state),
    uomTabs: getUomsForTabs(state),
    integrationState: getIntegrationState(state),
    isHempFacility: getIsHempFacility(state),
    isFeatureEnabledProductMasters: isFeatureEnabledProductMasters(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({},
    apiActions,
    dataActions,
    {goBack, setItem}
  );
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (SelectPackagesFacilityPage);
