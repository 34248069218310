import React from 'react';
import PropTypes from 'prop-types';
import TablePageWrapper from '../../common/grid/TablePageWrapper';
import AccordionPanel from '../../common/AccordionPanel';

const PreviewProductList = ({columns, products, ref}) =>
  <AccordionPanel title={'products.table.productListing'}>
    <TablePageWrapper
      ref = {ref}
      settingKey='mass_modify_products_preview'
      data = {products}
      columns = {columns}
      hideScanSearch={true}
      hideExport={true}
      hideSearch={true}
      switchTab={() => {}}
    />
  </AccordionPanel>
;

PreviewProductList.propTypes = {
  columns: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  ref: PropTypes.object.isRequired
};

export default PreviewProductList;

