import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {goBack} from 'react-router-redux';
import get from 'lodash.get';

import * as apiActions from '../../actions/apiActions';
import {unsetItem} from '../../actions/itemActions';
import * as itemNames from '../../constants/itemNames';
import * as dataNames from '../../constants/dataNames';
import {getDrivers} from '../../selectors/driversSelectors';
import {getImage,getInsuranceDocument} from '../../selectors/filesSelectors';
import {getIntegrationState} from '../../selectors/integration/integrationSelectors';
import {getInternationalPhoneCountryCode, getInternationalPhoneNumber} from '../../util/internationalHelpers';
import FormWrapper from '../common/form/FormWrapper';
import CreateVehicleForm from './CreateVehicleForm'; // eslint-disable-line

export class CreateVehiclePage extends React.PureComponent{
  constructor(props){
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.onDocChange = this.onDocChange.bind(this);
    this.changeUploadStatus = this.changeUploadStatus.bind(this);
    this.getVehicleImage = this.getVehicleImage.bind(this);
    this.getInsuranceDocument = this.getInsuranceDocument.bind(this);

    this.state = { uploading: false };
  }

  componentWillMount() {
    const {actions: {unsetItem,getItem,getUnpaginatedData}} = this.props;
    unsetItem(itemNames.vehicle);
    unsetItem(itemNames.image);
    unsetItem(itemNames.insuranceDocument);

    if(!this.props.drivers){
      getUnpaginatedData('/api/drivers', dataNames.drivers);
    }

    if(!this.props.integrationSettings){
      getItem(
        '/api/integration-settings',
        itemNames.integrationSettings,
        {failed: 'stateIntegratorSettings.get.failed'}
      );
    }
  }

  getVehicleImage(id) {
    this.props.actions.unsetItem(itemNames.image);
    return this.props.actions.getItem(`/api/images/${id}`, itemNames.image);
  }

  getInsuranceDocument(id) {
    this.props.actions.unsetItem(itemNames.insuranceDocument);
    return this.props.actions.getItem(`/api/documents/${id}`, itemNames.insuranceDocument);
  }

  onSubmit(data){
    data.insurance_phone_country_code = getInternationalPhoneCountryCode(data.insurance_phone_number);
    data.insurance_phone_number = getInternationalPhoneNumber(data.insurance_phone_number);

    this.props.actions.postData(
      '/api/vehicles ',
      data,
      dataNames.vehicles,
      {
        success: 'cultivation.vehicles.add.success',
        failed: 'cultivation.vehicles.add.fail'
      },
      null,
      this.props.actions.goBack
    );
  }

  onImageChange(file) {
    if(file.id) this.getVehicleImage(file.id);
  }

  onDocChange(file) {
    if(file.id) this.getInsuranceDocument(file.id);
  }

  changeUploadStatus(status) {
    this.setState({uploading: status});
  }

  render(){
    const {drivers, image, insuranceDocument, integrationState} = this.props;
    const initialValues = {
      insured_drivers: []
    };
    const namedDrivers = drivers.map((driver) => {
      driver.name = `${driver.first_name}` + ' ' + `${driver.last_name}`;
      return driver;
    });

    return(
      <FormWrapper title = {I18n.t('cultivation.vehicles.addTitle')} goBack={this.props.actions.goBack}>
        <CreateVehicleForm
          initialValues={initialValues}
          changeUploadStatus={this.changeUploadStatus}
          documentUrl={get(insuranceDocument,'url',null)}
          drivers={namedDrivers}
          imageUrl={get(image,'url.original',null)}
          integrationState={integrationState}
          onDocChange={this.onDocChange}
          onImageChange={this.onImageChange}
          onSubmit = {this.onSubmit}
          uploading={this.state.uploading}
        />
      </FormWrapper>
    );
  }
}

CreateVehiclePage.propTypes = {
  actions: PropTypes.shape({
    getUnpaginatedData: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired,
  }),
  drivers: PropTypes.array.isRequired,
  image: PropTypes.object.isRequired,
  insuranceDocument: PropTypes.object.isRequired,
  integrationState: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    drivers: getDrivers(state),
    image: getImage(state),
    insuranceDocument: getInsuranceDocument(state),
    integrationState: getIntegrationState(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {goBack,unsetItem});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (CreateVehiclePage);
