/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { Field, FieldArray, FormSection } from 'redux-form';
import { Col, Row, Button } from 'react-bootstrap';
import * as p from '../../constants/permissions';
import Cultivation from './Cultivation';
import Phenotypes from './Phenotypes';
import CurrencyInput from '../common/form/CurrencyInput';
import SelectInput from '../common/form/SelectInput';
import TextInput from '../common/form/TextInput';
import NumberInput from '../common/form/NumericInput';
import strainDominance from '../../constants/strainDominance';
import AccordionPanel from '../common/AccordionPanel.js';
import PermissionWrapper from '../common/PermissionWrapper';
import typeOfCannabis from '../../constants/typeOfCannabis';

export const Strain = (props) => {
  const {
    fields: { map },
    isCanada,
    totalPlantLifeCycleValues,
    errors,
    isColombia
  } = props;

  return (
    <div>
      {map((strain, index) => (
        <AccordionPanel title='Strain' className='selected-strains' key={strain}>
          <Row>
            <Col md={4}>
              <Row>
                <Col md={6}>
                  <Field
                    component={TextInput}
                    name={`${strain}.strain_name`}
                    disabled='disabled'
                    props={{
                      label: I18n.t('strains.strain')
                    }}
                  />
                </Col>
                <Col md={6}>
                  <Field
                    component={TextInput}
                    name={`${strain}.strain_code`}
                    maxLength={4}
                    props={{
                      label: I18n.t('strains.strainCode')
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Field
                    component={CurrencyInput}
                    name={`${strain}.strain_value`}
                    props={{
                      label: I18n.t('strains.strainValue'),
                      isRequired: isCanada,
                      leftAddon: <span>$</span>
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Field
                    component={CurrencyInput}
                    name={`${strain}.strain_value_per_gram`}
                    props={{
                      label: I18n.t('strains.strainValuePerGram'),
                      isRequired: isCanada,
                      leftAddon: <span>$</span>
                    }}
                  />
                </Col>
              </Row>

              <FieldArray name={`${strain}.phenotypes`} component={Phenotypes} />
            </Col>

            <Col md={8}>
              <Row>
                <Col md={12}>
                  <h3>{I18n.t('strains.edit.genetics')}</h3>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Field component={TextInput}
                         name={`${strain}.pollen_donor`}
                         props={{
                           label: I18n.t('strains.pollenDonor'),
                         }}
                  />
                </Col>
                <Col md={4}>
                  <Field component={TextInput}
                         name={`${strain}.mother`}
                         props={{
                           label: I18n.t('strains.mother'),
                         }}
                  />
                </Col>

                <Col md={4}>
                  <Field component={SelectInput}
                         name={`${strain}.dominance`}
                         props={{
                           label: I18n.t('strains.dominance'),
                           options: strainDominance,
                         }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <Field component={NumberInput}
                         name={`${strain}.thc_level`}
                         props={{
                           label: I18n.t('strains.thcLevel'),
                           rightAddon: <span>g</span>,
                           type: 'decimal',
                           fractionPartSize: 4
                         }}
                  />
                </Col>
                <Col md={3}>
                  <Field component={NumberInput}
                         name={`${strain}.cbd_level`}
                         props={{
                           label: I18n.t('strains.cbdLevel'),
                           rightAddon: <span>g</span>,
                           type: 'decimal',
                           fractionPartSize: 4
                         }}
                  />
                </Col>
                <Col md={3}>
                  <Field component={NumberInput}
                         name={`${strain}.indica_percent`}
                         props={{
                           label: I18n.t('strains.indica'),
                           rightAddon: <span>%</span>,
                           type: 'decimal',
                           fractionPartSize: 1
                         }}
                  />
                </Col>
                <Col md={3}>
                  <Field component={NumberInput}
                         name={`${strain}.sativa_percent`}
                         props={{
                           label: I18n.t('strains.sativa'),
                           rightAddon: <span>%</span>,
                           type: 'decimal',
                           fractionPartSize: 1
                         }}
                  />
                </Col>
              </Row>
              {isColombia && (
                <Row>
                  <Col md={4}>
                    <Field
                      component={SelectInput}
                      name={`${strain}.type_of_cannabis`}
                      props={{
                        label: I18n.t('strains.typeOfCannabis'),
                        options: typeOfCannabis()
                      }}
                    />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <hr />
            </Col>
          </Row>

          <PermissionWrapper permissions={[p.manage_forcasting, p.forecasting_yield_management]}>
            <FormSection name={`${strain}.cultivation`}>
              <Cultivation strain={strain} totalPlantLifeCycleValues={totalPlantLifeCycleValues} index={index} />
            </FormSection>
          </PermissionWrapper>

          <Row className='submit-wrapper, float-right'>
            <Col md={14}>
              <Button disabled={!!errors[index]} type='submit' variant='primary' className='submit-button'>
                {I18n.t('common.form.save')}
              </Button>
            </Col>
          </Row>
        </AccordionPanel>
      ))}
    </div>
  );
};

Strain.propTypes = {
  reset: PropTypes.func,
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired
  }).isRequired,
  integrationState: PropTypes.object.isRequired,
  isCanada: PropTypes.bool.isRequired,
  isColombia: PropTypes.bool,
  totalPlantLifeCycleValues: PropTypes.array.isRequired,
  errors: PropTypes.array.isRequired
};

export default Strain;
