import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {userHasPermission} from '../../selectors/usersSelectors';
import {evaluateRouteAccessibility} from '../../selectors/accessSelectors';

class PermissionWrapper extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
  }

  render () {
    const {hasPermission, children, alternateComponent, force, isAccessible} = this.props;
    const altChild = alternateComponent ? alternateComponent : null ;

    return (hasPermission && isAccessible && force ? children : altChild);
  }
}

PermissionWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node
  ]),
  alternateComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node
  ]),
  force: PropTypes.bool, //force a fail state for easy render testing
  hasPermission: PropTypes.bool.isRequired,
  accessibility: PropTypes.object,
  isAccessible: PropTypes.bool,
};

PermissionWrapper.defaultProps = { force:true };

function mapStateToProps(state, ownProps){
  const isAccessible = evaluateRouteAccessibility(ownProps.accessibility)(state) ? true : false; // fight against warnings

  return {
    hasPermission: userHasPermission(state, ownProps),
    isAccessible: isAccessible,
    ...ownProps
  };
}

export default connect(mapStateToProps) (PermissionWrapper);
