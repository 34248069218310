export const pricingOptions = [
  {text: '', value: ''},
  {text: 'Weight', value: 'weight'},
  {text: 'Volume', value: 'volume'},
  {text: 'Unit', value: 'unit'},
];
export const onlineAvailabilityOptions = [
  {text: 'Available', value: 1},
  {text: 'View-Only', value: 2},
  {text: 'Don\'t Show', value: 0},
];

export const onlineAvailabilityOptionsForMassPage = [
  {text: '', value: null},
  {text: 'Don\'t Show', value: 0},
  {text: 'Available', value: 1},
  {text: 'View-Only', value: 2},
];

export const potencyList = [
  'd9_thca',
  'd9_thc',
  'd8_thc',
  'thcv',
  'cbd',
  'cbda',
  'cbdv',
  'cbg',
  'cbga',
  'cbc',
  'cbn',
];
export const terpeneList = [
  'bisabolol',
  'humulene',
  'pinene',
  'terpinolene',
  'b_caryophyllene',
  'b_myrcene',
  'b_pinene',
  'caryophyllene_oxide',
  'limonene',
  'linalool',
  'geraniol',
  'trans_nerolidal',
  'guaiol',
  'isopulegol',
  'camphene',
  'carene',
  'y_terpinene',
  'a_terpinene',
  'eucalyptol',
  'ocimene',
  'p_cymene',
];
export const solvents = [
  {text: 'Alcohol', value: 'alcohol'},
  {text: 'BHO', value: 'bho'},
  {text: 'CO2', value: 'co2'},
  {text: 'Ice Water', value: 'water'},
  {text: 'Other', value: 'other'},
  {text: 'Propane', value: 'propane'},

];
export const activationTimes = [
  {text: 'Immediate', value: 'Immediate'},
  {text: '0-2 hours', value: '0-2 hours'},
  {text: '2+ hours', value: '2+ hours'}
];

export const dilutions = [
  {text: 'Coconut Oil', value: 'coconut_oil'},
  {text: 'MCT', value: 'mct'},
  {text: 'Other', value: 'other'},
  {text: 'Polyethylene Glycol', value: 'polyethylene_glycol'},
  {text: 'Propylene Glycol', value: 'propylene_glycol'},
  {text: 'Vegetable Glycerin', value: 'vegetable_glycerin'},
];
