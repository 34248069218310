import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Button, Col, Row} from 'react-bootstrap';

import ReactSelectInput from '../common/form/ReactSelectInput';

const ynOptions = [
  {text: I18n.t('customers.create.no'), value: '0'},
  {text: I18n.t('customers.create.yes'), value: '1'}
];

const AutoPriceForm = (props) => {
  const {handleSubmit, formValues: {order_autoprice_allow}, valid, submitting} = props;
  return (
    <form className='form-wrapper form-inline' onSubmit={handleSubmit}>
      <Row>
        <Col md={12} className='transparent-addon-right'>
          <Field name='order_autoprice_allow' component={ReactSelectInput} props={{
            label: I18n.t('retail.autoPrice.allowRetailLocations'),
            options: ynOptions,
            rightAddon: I18n.t('retail.autoPrice.allowRetailLocationsDescription'),
            isRequired: true
          }}/>
        </Col>
      </Row>
      {order_autoprice_allow === '1' ?
        <div>
          <Row>
            <Col md={12} className='transparent-addon-right'>
              <Field name='order_autoprice_limit_to_pricing_groups' component={ReactSelectInput} props={{
                label: I18n.t('retail.autoPrice.limitingToPricingGroup'),
                rightAddon: I18n.t('retail.autoPrice.limitingToPricingGroupDescription'),
                options: ynOptions,
                isRequired: true
              }}/>
            </Col>
          </Row>
          {/*<Row>
            <Col md={12} className='transparent-addon-right'>
              <Field name='order_autoprice_limit_pricing_weights' component={ReactSelectInput} props={{
                label: I18n.t('retail.autoPrice.limitAutoPrice'),
                options: ynOptions,
                isRequired: true
              }}/>
            </Col>
          </Row>*/}
        </div> :
        null
      }
      <Row>
        <Col xs={12}>
          <div className='float-right'>
            <Button type='submit' variant='primary' disabled={!valid || submitting}>
              {I18n.t('common.form.save')}
            </Button>
          </div>
        </Col>
      </Row>
    </form>
  );
};

AutoPriceForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  formValues: PropTypes.shape({
    order_autoprice_allow: PropTypes.string
  }).isRequired,
  submitting: PropTypes.bool,
  valid: PropTypes.bool
};

export default AutoPriceForm;
