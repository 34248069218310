import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import InternationalNumericInput from './InternationalNumericInput';
import { getInternationalNumberFormat } from '../../../selectors/InternationalOperationsSelectors';
import {fieldFormatHelperDecimals, fieldParseHelperDecimals} from '../../../util/internationalHelpers';


const InternationalDecimalInput = (props) => {

  const allProps = {
    ...props,
    ...props.props
  };

  const {shouldRound, internationalNumberFormat, fractionPartSize} = allProps;

  // This is how we want it in the store
  const fieldParser = (value, name) => {
    return fieldParseHelperDecimals(value, name, internationalNumberFormat);
  };

  // This is how we want it displayed in the component
  const fieldFormatter = (value, name) => {
    return fieldFormatHelperDecimals(value, name, internationalNumberFormat);
  };

  if (props.props.placeholder !== undefined && props.props.placeholder !== null && isNaN(props.props.placeholder) == false) {
    props.props.placeholder = fieldFormatter(props.props.placeholder);
  }

  // This is how we want to display it
  return (
    <Field
      parse={fieldParser}
      format={fieldFormatter}
      name={props.name}
      component={InternationalNumericInput}
      type='text'
      props={{
        ...props.props,
        fractionPartSize,
        internationalNumberFormat,
        shouldRound,
      }}/>
  );
};

InternationalDecimalInput.propTypes = {
  props: PropTypes.object,
  name: PropTypes.string,
  internationalNumberFormat: PropTypes.string.isRequired,
  fractionPartSize: PropTypes.number,
};

InternationalDecimalInput.defaultProps = {
  shouldRound: false,
  fractionPartSize: -1
};

function mapStateToProps(state, ownProps){
  return {
    internationalNumberFormat: getInternationalNumberFormat(state),
  };
}

export default connect(mapStateToProps)(InternationalDecimalInput);
