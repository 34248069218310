import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import {Button, Col, Row} from 'react-bootstrap';
import {FaTrash} from 'react-icons/fa';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import MultiselectInput from '../../../common/form/MultiselectInput';
import HoverTooltip from '../../../common/HoverTooltip';

const OnlineAvailabilityAttributes = (props) => {
  const {isOrganizationMode, fieldName, fieldIndex, fieldLabel, fields, facilityOptions, onlineAvailabilityOptions,
    isRequired, fieldCanModifiedMap} = props;
  const keyForMap = fieldName.replace(/(\[\d+])/, '');

  return (
    <Col xs={12}>
      <Row>
        <Col sm={3}>
          <Field name={`${fieldName}.available_online`} component={ReactSelectInput} props={{
            label: I18n.t(fieldLabel),
            options: onlineAvailabilityOptions,
            rightAddon: !!fieldCanModifiedMap && fieldCanModifiedMap[`${keyForMap}.available_online`].length ?
              <HoverTooltip text={I18n.t('products.massModify.errors.diff_categories_help', {field: I18n.t(fieldLabel), categories: fieldCanModifiedMap[`${keyForMap}.available_online`].join(' ,')})} id={`${fieldName}_tooltip`}/> : null
          }}/>
        </Col>
        {isOrganizationMode ?
          null :
          <Col sm={4}>
            <Row>
              <Col xs={10}>
                <Field name={`${fieldName}.facility_ids`} component={MultiselectInput} props={{
                  label: I18n.t('taxes.form.applicableFacilities'),
                  options: facilityOptions,
                  isRequired: isRequired,
                  allOption: true,
                  rightAddon: !!fieldCanModifiedMap && fieldCanModifiedMap[`${keyForMap}.facility_ids`].length ?
                    <HoverTooltip text={I18n.t('products.massModify.errors.diff_categories_help', {field: I18n.t('taxes.form.applicableFacilities'), categories: fieldCanModifiedMap[`${keyForMap}.facility_ids`].join(' ,')})} id={`${fieldName}_tooltip`}/> : null
                }}/>
              </Col>
              <Col xs={2} style={{paddingTop: '28px'}}>
                <Button variant='danger' size='sm' onClick={() => fields.remove(fieldIndex)}>
                  <FaTrash/>
                </Button>
              </Col>
            </Row>
          </Col>
        }
      </Row>
    </Col>
  );
};

OnlineAvailabilityAttributes.propTypes = {
  isOrganizationMode: PropTypes.bool.isRequired,
  fields: PropTypes.shape({
    remove: PropTypes.func,
  }),
  fieldName: PropTypes.string.isRequired,
  fieldLabel: PropTypes.string.isRequired,
  onlineAvailabilityOptions: PropTypes.array.isRequired,
  fieldIndex: PropTypes.number,
  facilityOptions: PropTypes.array,
  isRequired: PropTypes.bool,
  fieldCanModifiedMap: PropTypes.object
};

export default OnlineAvailabilityAttributes;
