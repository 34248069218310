/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack, push} from 'react-router-redux';
import get from 'lodash.get';
import flattenLocations from '../../util/flattenLocations';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import * as apiActions from '../../actions/apiActions';
import * as itemActions from '../../actions/itemActions';
import * as dataActions from '../../actions/dataActions';
import {addMessage} from '../../actions/systemActions';
import {fetchMetrcTrackingIdsForSelectInput} from '../../actions/integrationActions';
import {isMetrcIntegrator} from '../../selectors/integration/metrcSelectors';
import {getCurrentFacilityUserById} from '../../selectors/usersSelectors';
import {
  convertFormInputDateToDbDate,
  formatDBDate
} from '../../util/dateHelpers';
import FormWrapper from '../common/form/FormWrapper';
import CompletePackageReduxForm from './common/CompletePackageReduxForm';
import PrinterModal from '../printer/PrinterModal';
import InProgressOverlay from '../common/InProgressOverlay';
import {getItemAvailabilityExcludingEntity} from '../../selectors/itemsAvailabilitySelectors';
import reduxMetrcIdAvailability, {setUnavailableTrackingIdFieldError} from '../common/form/redux-form/reduxMetrcIdAvailability';
import {getIntegrationState} from '../../selectors/integration/integrationSelectors';
import {getJobItemMasterOptions, getFilteredBiotrackInvTypes, getInitialValues} from '../../selectors/forms/completePackageFormSelectors';
import {isAllowNegativeInventory, getPrepacksInheritBulkLabResults} from '../../selectors/complianceSettingsSelectors';
import {doNothing} from '../../util/callbackHelpers';
import showNegativeAlert from '../common/negative-inventory/showNegativeAlert';
import NegativeInventoryAlert from '../common/negative-inventory/NegativeInventoryAlert';
import {hasPackagesTags} from '../../selectors/integrationSelectors';
import {convertFromBase} from '../../util/uomHelpers';
import {getPackagingWorkflowValidOutputs, getSubCategorySettings} from '../../selectors/settingsSelectors';
import {getFilter, getValidatedOutputsFilter} from './helpers';
import {isFeatureEnabled} from '../../selectors/featureToggles';

export class CompletePackagePage extends React.PureComponent {


  constructor(props, context) {
    super(props, context);
    this.state = {
      labelPayload: {},
      showInProgress: false,
      message: 'Saving',
      saveComplete: false,
      package: false,
      noStrain: false,
      showNegativeInvConfirmation: {
        show: false,
        onHide: null,
        onConfirm: null,
      },
    };

    this.redirect = this.redirect.bind(this);
    this.generatePackageId = this.generatePackageId.bind(this);
    this.generateContainerId = this.generateContainerId.bind(this);
    this.printLabel = this.printLabel.bind(this);
    this.hidePrinter = this.hidePrinter.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handlePrePackItemMasterChange = this.handlePrePackItemMasterChange.bind(this);
    this.setNegativeConfirmationState = this.setNegativeConfirmationState.bind(this);
  }

  componentWillMount() {

    const {getItem, getUnpaginatedData, getSearchData, unsetData, setItem} = this.props.actions;
    const {params, itemMasterServiceFirstIsEnabled} = this.props;
    if (!params || !params.id) {
      return;
    }
    const strainRequired = (this.props.facility) ? (this.props.facility.type !== 'manufacturing') : true;
    unsetData(dataNames.childItemMasters);

    getItem('/api/compliance_settings', itemNames.complianceSettings);
    getUnpaginatedData('/api/location_hierarchy', dataNames.locations, {failed: 'locations.getLocations.failed'});
    getUnpaginatedData('/api/users/current_facility', dataNames.currentFacilityUsers, {failed: 'packaging.getUsers.failed'});
    getUnpaginatedData('/api/prepack_weights/facility', dataNames.prepackFacilityWeights, {failed: 'packaging.getPrepackWeights.failed'});
    getItem(
      `/api/packaging_jobs/${params.id}/details`,
      itemNames.packagingJob,
      {failed: 'packages.actions.failed.getPackagingJobDetail'},
      {},
      job => {
        const {strain_id, uom_type, subcategory} = job.item.item_master;
        const validatedOutputsFilter = getValidatedOutputsFilter(this.props, job);

        if(typeof validatedOutputsFilter !== 'string' && validatedOutputsFilter.error){
          this.props.actions.addMessage('error', validatedOutputsFilter.message);
          return;
        }

        const itemMasterFetchCallback = (products) => {
          if (get(products, '0') && products.find(product => product.id === job.item.item_master_id)) {
            this.setState({
              item_master_parent_id: job.item.item_master_id
            });
          }
        };

        // NOTE: The intent is to eventually remove this conditional having moved all the "validatedOutputsFilter"
        //  logic and workflow to the backend. Until then, the "else" condition stays on SOLR.
        if (itemMasterServiceFirstIsEnabled && !validatedOutputsFilter) {
          const params = {
            select_columns: ['id', 'name', 'category_id', 'default_uom', 'uom_type'],
            active: 1,
            is_draft: 0,
            strain_id,
            is_packagable_for_inventory: {
              uom_type,
              category_id: subcategory.category_id
            }
          };

          getUnpaginatedData('/api/item_masters/search', dataNames.itemMasters, {failed: 'products.get.failed'}, params, itemMasterFetchCallback);
        } else {
          const filter = getFilter(this.props, job);
          const params = {
            query: '',
            start: 0,
            size: 10000,
            sort: 'name asc, display_name asc',
            filter,
            fields: ['id', 'name', 'category_id', 'default_uom', 'uom_type']
          };

          getSearchData('/api/search/item_masters', dataNames.itemMasters, {failed: 'products.get.failed'}, params, itemMasterFetchCallback);
        }

        if (strainRequired || (!strainRequired && !isNaN(parseInt(strain_id)))) {
          getItem(`/api/strains/${strain_id}`, itemNames.strain, {failed: 'packaging.getStrain.failed'});
        } else {
          this.setState({noStrain: true});
        }

        getItem(`/api/item_availability/${job.item.id}`, itemNames.itemAvailability, {failed: 'itemsAvailability.get.failed'});
        getItem(`/api/items/${job.item.id}`, itemNames.inventoryItem, {failed: 'items.get.failed'});
        setItem('packaging_job', itemNames.entityType);
        setItem(job.id, itemNames.entityId);
        getUnpaginatedData('/api/item_reservations', dataNames.hardReservations, {failed: 'reservations.failed'}, {item_id: job.item.id});
      }
    );
    // Integration settings are downloaded to see if Metrc intergation settings exist. If the Metrc settings exist,
    // then the METRC Tracking ID input is displayed.
    this.props.actions.getItem(
      '/api/integration-settings',
      itemNames.integrationSettings,
      {failed: 'stateIntegratorSettings.get.failed'}
    ).then(() => {
      if (this.props.hasMetrcSettings) {
        this.props.actions.fetchMetrcTrackingIdsForSelectInput();
      }
    });
    this.props.actions.getItem('/api/settings/values/by_key', itemNames.packagingInheritsLabResults, null,
      {ids: ['cult_batch_packaging_inherits_lab_results']});
  }

  generatePackageId(itemMasterId, next) {
    const {actions: {getItem}} = this.props;
    getItem(
      `/api/item_masters/${itemMasterId}/generate_id`,
      itemNames.packageId,
      {failed: 'packaging.generatePackageId.failed'},
      {},
      next
    );
  }

  generateContainerId(next) {
    const {actions: {getItem}} = this.props;
    getItem(
      '/api/containers/generate_id',
      itemNames.containerCode,
      {failed: 'containers.generateCode.failed'},
      {},
      result => next(result)
    );
  }

  redirect() {
    this.props.actions.goBack();
  }


  isInventoryNegative(formData) {
    const packagesWeightBase = formData.quantities.reduce((acc, item) => (acc + item.weightBase * (item.qty || 0)), 0);
    const wasteReported = formData.wasteReportedInput ? Number(formData.wasteReportedInput) : 0;
    const packagingLoss = formData.packagingLossInput ? Number(formData.packagingLossInput) : 0;
    const availableWeight = formData.weight;
    const usedWeight = convertFromBase(packagesWeightBase, formData.inputUom) + wasteReported + packagingLoss;

    return usedWeight > (availableWeight + 0.00001);
  }

  onSubmit(formData) {
    const isInventoryNegative = this.isInventoryNegative(formData);
    showNegativeAlert(isInventoryNegative, this.setNegativeConfirmationState)
      .then(() => this.sendRequest(formData))
      .catch(doNothing);
  }

  sendRequest(formValues) {

    if (this.state.saveComplete) {
      if (formValues.print) {
        this.printLabel(this.state.package);
      }
      return false;
    }

    this.setState({showInProgress: true});

    const hideInProgress = () => {
      this.setState({showInProgress: false});
    };

    const {packagingJob, selectedPackageTests, actions: {postItem}, validateTrackingIdsAvailability, item} = this.props;

    const job = {
      packaging_job_id: packagingJob.id,
      storage_location_id: formValues.storage_location_id,
      completed_at: formValues.completed_at ? convertFormInputDateToDbDate(formValues.completed_at, this.props.timezone) : null,
      package_created_at: formValues.package_created_at ? formatDBDate(formValues.package_created_at) : null, // always just date without timezone
      package_expires_at: formValues.package_expires_at ? formatDBDate(formValues.package_expires_at) : null, // always just date without timezone
      packaged_loss_amt: formValues.packagingLossInput,
      packaged_loss_amt_uom: packagingJob.packaged_loss_amt_uom,
      waste_reported_uom: get(formValues, 'inputUom'),
      waste_reported: get(formValues, 'wasteReportedInput'),
      product_not_altered: formValues.product_not_altered || '0',
      tag_requested: formValues.tag_requested || 0,
      packages: formValues.quantities.filter(quantity => quantity.qty).map(quantity => ({
        item_master_id: quantity.item_master_id,
        package_code: quantity.package_code,
        qty: quantity.qty,
        state_integration_tracking_id: formValues.state_integration_tracking_id || undefined,
        purpose: formValues.purpose,
        is_produced: formValues.is_produced,
        integration_type: quantity.integration_type,
        finished: quantity.finished || 0,
        medically_compliant: quantity.medically_compliant,
        medically_compliant_status: quantity.medically_compliant_status
      })),
    };
    const originalTrackingId = item && item.package && item.package.state_integration_tracking_id;
    //Skip the validation if we use the former tracking ID
    const validationPromise = formValues.state_integration_tracking_id === originalTrackingId ?
      Promise.resolve() : validateTrackingIdsAvailability(formValues, setUnavailableTrackingIdFieldError);

    return validationPromise
      .then(() => {
        return postItem(
          `/api/packaging_jobs/${packagingJob.id}/complete`,
          job,
          null, // itemNames.packagingJob - original redux target for response but clears fields because it does not have details
          {success: 'packaging.complete.success', failed: 'packaging.complete.failed'},
          {},
          (data) => {
            const packageTestData = selectedPackageTests ? selectedPackageTests.find(item => item.package_code === formValues.package_code) : null;

            if (packageTestData && packageTestData.lab_results_id) {
              const references = data.items ? data.items.map(item => {
                return {
                  lab_results_id: packageTestData.lab_results_id,
                  reference_id: item.package_id,
                  reference_type: 'package'
                };
              }) : [];

              if (references.length && this.props.packagingInheritsLabResults && this.props.prepacksInheritBulkLabResults) {
                postItem(`/api/lab_results_references`, references, null, {
                  success: 'packaging.references.success',
                  failed: 'packaging.references.failed'
                }, {}, () => {
                  postItem('/api/search/update', {core: 'inventory'}, null, {
                    success: 'plants.actions.updateSearchSuccess',
                    fail: 'plants.actions.updateSearchFail'
                  });
                });
              }
            }

            this.setState({showInProgress: false, saveComplete: true, package: data});
            formValues.print
              ? this.printLabel(data) :
              this.redirect();
          }
        ).then(hideInProgress).catch(hideInProgress);
      })
      .catch(e => {
        hideInProgress();
        throw e;
      });
  }

  printLabel(data) {

    const namedBlocks = data.items.reduce((acc, item) => {

      const items = [];
      for (let n = 0; n < parseInt(item.qty); n++) {
        items.push(item.id);
      }

      const named = {
        name: item.prepack_name,
        ids: items
      };

      acc.push(named);
      return acc;

    }, []);

    const payload = {namedBlocks: namedBlocks};

    this.setState({showPrinter: true, redirect: false, labelPayload: payload});

  }

  hidePrinter(event) {
    this.setState({showPrinter: false});
  }

  handlePrePackItemMasterChange(id) {
    if (!id) {
      return;
    }

    const {params, itemMasters, integrationState: {isWaLeaf}} = this.props;
    const itemMaster = itemMasters.find(itemMaster => itemMaster.id == id);

    if (itemMaster && itemMaster.uom_type === 'discrete' && !itemMaster.is_prepack) {
      this.props.actions.push(`/packages/complete/each/${params.id}?item_master_id=${itemMaster.id}`);
    } else {
      this.setState({item_master_parent_id: id});
      this.props.actions.getUnpaginatedData('/api/item_masters/children/items', dataNames.childItemMasters, undefined, {ids: [id], active: 1});
      if (isWaLeaf) {
        this.props.actions.getUnpaginatedData(`/api/leaf/get_wa_inventory_mapping/${id}`, dataNames.waPackagingInventoryTypes);
      }
    }
  }

  setNegativeConfirmationState(state) {
    this.setState({
      showNegativeInvConfirmation: state
    });
  }

  render() {
    const {showPrinter, labelTag, labelIds, item_master_parent_id, showNegativeInvConfirmation} = this.state;
    const {
      locations, employees, strain, producer, weight, itemMasters, childItemMasters, hasMetrcSettings, uom,
      trackingIds, inventoryTypes, integrationState, hasPackagesTags, isAllowNegativeInventory, waPackagingInventoryTypes
    } = this.props;
    const initialValues = Object.assign({}, this.props.initialValues, {item_master_parent_id});
    return (<FormWrapper title={'packages.complete.title'} goBack={this.redirect}>
      <InProgressOverlay isActive={this.state.showInProgress} message={this.state.message}/>
      <NegativeInventoryAlert confirmationState={showNegativeInvConfirmation}/>
      <div style={{margin: '10px 10px 10px 10px'}}>
        <CompletePackageReduxForm
          saveComplete={this.state.saveComplete}
          itemMasters={itemMasters}
          locations={locations}
          employees={employees}
          strain={strain}
          childItemMasters={childItemMasters}
          producer={producer}
          weight={weight}
          uom={uom}
          handlePrePackItemMasterChange={this.handlePrePackItemMasterChange}
          generatePackageId={this.generatePackageId}
          generateContainerId={this.generateContainerId}
          initialValues={initialValues}
          enableReinitialize={true}
          hasMetrcSettings={hasMetrcSettings}
          trackingIds={trackingIds}
          onSubmit={this.onSubmit}
          inventoryTypes={inventoryTypes}
          integrationState={integrationState}
          hasPackagesTags={hasPackagesTags}
          noStrain={this.state.noStrain}
          isAllowNegativeInventory={isAllowNegativeInventory}
          waPackagingInventoryTypes={waPackagingInventoryTypes}
        />
        <PrinterModal
          ref='printerModal'
          showPrinter={showPrinter}
          hidePrinter={this.hidePrinter}
          forceLabelBlocks={true}
          httpAction='post'
          labelUrl='/api/labels/generate/inv_package_tag_all/for/many'
          payload={this.state.labelPayload}
          labelTagx={labelTag}
          labelIdsx={labelIds}
        />
      </div>
    </FormWrapper>);
  }
}

CompletePackagePage.propTypes = {
  initialValues: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  packagingJob: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  employees: PropTypes.array.isRequired,
  prepackWeights: PropTypes.array.isRequired,
  strain: PropTypes.string,
  producer: PropTypes.string.isRequired,
  weight: PropTypes.number.isRequired,
  weightUOM: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
  packageCode: PropTypes.string.isRequired,
  itemMasters: PropTypes.array.isRequired,
  childItemMasters: PropTypes.array.isRequired,
  hasMetrcSettings: PropTypes.bool.isRequired,
  facility: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  trackingIds: PropTypes.array.isRequired,
  validateTrackingIdsAvailability: PropTypes.func.isRequired,
  packagingInheritsLabResults: PropTypes.bool.isRequired,
  isAllowNegativeInventory: PropTypes.bool.isRequired,
  integrationState: PropTypes.object.isRequired,
  hasPackagesTags: PropTypes.bool,
  inventoryTypes: PropTypes.array.isRequired,
  categories: PropTypes.array,
  subcategorySettings: PropTypes.object,
  packagingWorkflowValidOutputs: PropTypes.array,
};

function mapStateToProps(state, ownProps) {

  const {
    packagingJob, locations, facility, currentFacilityUsers,
    strain, childItemMasters, timezone
  } = state;

  const item = state[itemNames.inventoryItem];
  const inventoryItem = packagingJob.item;
  const availability = getItemAvailabilityExcludingEntity(state);
  const getEmployees = () => {

    return packagingJob.packaging_job_assigned_to_users && packagingJob.packaging_job_assigned_to_users.length
      ? packagingJob.packaging_job_assigned_to_users.map(user => {
        const facilityUser = getCurrentFacilityUserById(state, user);
        return facilityUser ? `${facilityUser.last_name}, ${facilityUser.first_name}` : '';
      })
      : [];

  };

  const props = {
    prepackWeights: state[dataNames.prepackFacilityWeights],
    packagingJob,
    locations: flattenLocations(locations),
    currentFacilityUsers: currentFacilityUsers.map(user => {
      Object.assign({}, user, {
        fullName: `${user.last_name}, ${user.first_name}`
      });
    }),
    itemMasters: getJobItemMasterOptions(state, ownProps),
    strain: strain.strain_name,
    weight: availability ? availability.qty_available : 1,
    uom: availability ? availability.uom_display : null,
    producer: facility.name,
    packageCode: inventoryItem ? inventoryItem.package_code : '',
    childItemMasters,
    employees: getEmployees(),
    facility,
    timezone,
    hasMetrcSettings: isMetrcIntegrator(state),
    trackingIds: state.trackingIds,
    selectedPackageTests: state['selectedPackageTests'],
    item,
    isAllowNegativeInventory: isAllowNegativeInventory(state),
    integrationState: getIntegrationState(state),
    hasPackagesTags: hasPackagesTags(state),
    inventoryTypes: getFilteredBiotrackInvTypes(state, ownProps),
    waPackagingInventoryTypes: state[dataNames.waPackagingInventoryTypes],
    prepacksInheritBulkLabResults: getPrepacksInheritBulkLabResults(state),
    subcategorySettings: getSubCategorySettings(state),
    packagingWorkflowValidOutputs: getPackagingWorkflowValidOutputs(state),
    itemMasterServiceFirstIsEnabled: isFeatureEnabled(state)('feature_item_master_listing_service_first')
  };

  const packagingInheritsLabResults = state[itemNames.packagingInheritsLabResults];
  if (packagingInheritsLabResults && packagingInheritsLabResults['cult_batch_packaging_inherits_lab_results']) {
    props['packagingInheritsLabResults'] = packagingInheritsLabResults['cult_batch_packaging_inherits_lab_results'].value;
  } else {
    props['packagingInheritsLabResults'] = true;
  }
  props.initialValues = getInitialValues(state, props);
  return props;
}


function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, dataActions, itemActions, {
    push,
    goBack,
    fetchMetrcTrackingIdsForSelectInput,
    addMessage,
  });
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default reduxMetrcIdAvailability(connect(mapStateToProps, mapDispatchToProps)(CompletePackagePage));
