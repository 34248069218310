import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import {saveAs} from 'file-saver';

const ExcelJS = require('exceljs/dist/exceljs');

class InventorySyncAdjustmentsReport extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  downloadTemplate(format) {
    const discrepantPackages = this.props.discrepantPackages;
    const packageAdjustments = this.props.packageAdjustments;
    const user = this.props.user;
    const integrationState = this.props.integrationState;
    const biotrackSettings = this.props.biotrackSettings;
    const metrcSettings = this.props.metrcSettings;
    const { isBiotrack } = integrationState;
    const fileName = 'Inventory Sync report';

    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Akerna - MJ Freeway';
    workbook.lastModifiedBy = 'Akerna - MJ Freeway';
    workbook.created = new Date();
    workbook.modified = workbook.created;
    const worksheet = workbook.addWorksheet(fileName);
    worksheet.columns = [
      { header: 'Name', key: 'name', width: 24 },
      { header: 'Vendor', key: 'vendor', width: 24 },
      { header: isBiotrack ? 'Biotrack ID' : 'Metrc ID', key: 'integrator_id', width: 26 },
      { header: 'Package Code', key: 'package_code', width: 20 },
      { header: 'Category', key: 'category', width: 16 },
      { header: 'Location', key: 'location', width: 24 },
      { header: 'Original MJP qty', key: 'mjp_quantity', width: 16 },
      { header: 'MJP UOM', key: 'mjp_uom', width: 10 },
      { header: 'MJP Adjustment', key: 'mjp_adjustment', width: 16 },
      { header: 'Original Metrc qty', key: 'integrator_quantity', width: 18 },
      { header: isBiotrack ? 'Biotrack UOM' : 'Metrc UOM', key: 'integrator_uom', width: 12 },
      { header: isBiotrack ? 'Biotrack Adjustment' : 'Metrc Adjustment', key: 'integrator_adjustment', width: 18 },
      { header: 'Adjustment Reason', key: 'adjustment_reason', width: 30 },
      { header: 'Adjustment Notes', key: 'adjustment_notes', width: 30 },
      { header: 'Location license #', key: 'location_license', width: 18 },
      { header: 'User license #', key: 'user_license', width: 14 },
    ];
    worksheet.getRow(1).font = { bold: true };
    worksheet.autoFilter = 'A1:P1';
    // Set MJP header cell background color
    const mjpColor = '1E90FF';
    worksheet.getRow(1).getCell('mjp_quantity').fill = {type: 'pattern', pattern: 'solid', fgColor: { argb: mjpColor}};
    worksheet.getRow(1).getCell('mjp_uom').fill = {type: 'pattern', pattern: 'solid', fgColor: { argb: mjpColor}};
    worksheet.getRow(1).getCell('mjp_adjustment').fill = {type: 'pattern', pattern: 'solid', fgColor: { argb: mjpColor}};
    // Set Metrc header cell background color
    const integratorColor = '32CD32';
    worksheet.getRow(1).getCell('integrator_quantity').fill = {type: 'pattern', pattern: 'solid', fgColor: { argb: integratorColor}};
    worksheet.getRow(1).getCell('integrator_uom').fill = {type: 'pattern', pattern: 'solid', fgColor: { argb: integratorColor}};
    worksheet.getRow(1).getCell('integrator_adjustment').fill = {type: 'pattern', pattern: 'solid', fgColor: { argb: integratorColor}};


    packageAdjustments.forEach((packageAdjustment) => {
      // Get discrepant package (contains Metrc package information)
      const discrepantPackage = discrepantPackages.find((discrepantPackage) => discrepantPackage.integrator_id === packageAdjustment.integrator_id);
      // Get MJP package (contains MJP package information)
      const mjpPackages = get(discrepantPackage, 'mjpPackages', []);
      const mjpPackage = mjpPackages.find((mjpPackage) => mjpPackage.package_id === packageAdjustment.package_id);
      const row = worksheet.addRow({
        name: get(mjpPackage, 'name', get(discrepantPackage, 'integrator_name', '-')),
        vendor: get(mjpPackage, 'vendor', ''),
        integrator_id: get(discrepantPackage, 'integrator_id', '-'),
        package_code: get(mjpPackage, 'package_code', '-'),
        category: get(mjpPackage, 'category', '-'),
        location: get(mjpPackage, 'location', '-'),
        mjp_quantity: get(mjpPackage, 'quantity', 0),
        mjp_uom: get(mjpPackage, 'uom', '-'),
        mjp_adjustment: packageAdjustment.target === 'mjp' ? get(packageAdjustment, 'value', 0) : 0,
        integrator_quantity: get(discrepantPackage, 'quantity', 0),
        integrator_uom: get(discrepantPackage, 'uom', '-'),
        integrator_adjustment: packageAdjustment.target !== 'mjp' ? get(packageAdjustment, 'value', 0) : 0,
        adjustment_reason: get(packageAdjustment, 'adjustment_reason', '-'),
        adjustment_notes: get(packageAdjustment, 'adjustment_notes', '-'),
        location_license: isBiotrack ? get(biotrackSettings, 'ubi', '-') : get(metrcSettings, 'license_number', '-'),
        user_license: get(user, 'stateId', '-')
      });
      // Set MJP cell background color
      const mjpCellColor = '7DC1EB';
      row.getCell('mjp_quantity').fill = {type: 'pattern', pattern: 'solid', fgColor: { argb: mjpCellColor}};
      row.getCell('mjp_uom').fill = {type: 'pattern', pattern: 'solid', fgColor: { argb: mjpCellColor}};
      row.getCell('mjp_adjustment').fill = {type: 'pattern', pattern: 'solid', fgColor: { argb: mjpCellColor}};
      // Set Metrc cell background color
      const integratorCellColor = '9ED990';
      row.getCell('integrator_quantity').fill = {type: 'pattern', pattern: 'solid', fgColor: { argb: integratorCellColor}};
      row.getCell('integrator_uom').fill = {type: 'pattern', pattern: 'solid', fgColor: { argb: integratorCellColor}};
      row.getCell('integrator_adjustment').fill = {type: 'pattern', pattern: 'solid', fgColor: { argb: integratorCellColor}};
    });

    if (format === 'excel') {
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/xlsx' });
        saveAs(blob, `${fileName.replace(/ /g,'_')}.xlsx`);
      });
    }
    if (format === 'csv') {
      workbook.csv.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'text/csv' });
        saveAs(blob, `${fileName.replace(/ /g,'_')}.csv`);
      });
    }
  }


  render() {
    return (
      <Dropdown>
        <Dropdown.Toggle id='download' style={{ marginBottom: '12px' }} variant='primary'
        >
          {I18n.t('inventory.sync.actions.download')}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item id='Excel' key='excel' onClick={() => this.downloadTemplate('excel')} >
            {I18n.t('inventory.sync.actions.downloadExcel')}
          </Dropdown.Item>

          <Dropdown.Item id='CSV' key='csv' onClick={() => this.downloadTemplate('csv')} >
            {I18n.t('inventory.sync.actions.downloadCsv')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

InventorySyncAdjustmentsReport.propTypes = {
  discrepantPackages: PropTypes.array.isRequired,
  packageAdjustments: PropTypes.array.isRequired,
  user: PropTypes.object,
  integrationState: PropTypes.object,
  biotrackSettings: PropTypes.object,
  metrcSettings: PropTypes.object,
};

export default InventorySyncAdjustmentsReport;
