import {INTEGRATION_LOCK_RANKINGS} from '../constants/integration/entityLocks';

/**
 * Iterates through array of Entity Locks and selects the highest ranked one, which is pertinent to the UI display
 * of certain warning icons and colors
 *
 * @param entityLocks
 * @returns {null|object}
 */
export const getHighestRankedEntityLock = (entityLocks) => {
  let mostImportantLock = null;

  for(const lock of entityLocks) {

    if (!lock.active) {
      continue;
    }

    const lockRanking = INTEGRATION_LOCK_RANKINGS[lock.lock_reason];

    if (!lockRanking) {
      // Go to next loop if the lock reason isn't in the lockReason Ranking (integration lock reasons)
      continue;
    }

    if (lockRanking === 1) {
      // Return immediately in this case, nothing can be ranked higher
      return lock;
    }

    if (!mostImportantLock) {
      mostImportantLock = lock;
      continue;
    }

    const mostImportantLockRanking = INTEGRATION_LOCK_RANKINGS[mostImportantLock.lock_reason];

    if (lockRanking < mostImportantLockRanking) {
      mostImportantLock = lock;
    }
  }

  return mostImportantLock;
};

/**
 * Takes an array of Entity Locks and filters out Locks that aren't Integration-related
 *
 * @param entityLocks
 * @returns {*}
 */
export const getIntegrationEntityLocks = (entityLocks) =>
  entityLocks.filter(lock => lock.lock_source === 'integration');

/**
 * Parses JSON representation of Entity Locks
 *
 * @param entityLocks
 * @returns {*|*[]}
 */
export const parseEntityLocksFromJson = (entityLocks) =>
  (entityLocks && JSON.parse(entityLocks)) || [];
