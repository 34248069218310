/* eslint-disable react/no-multi-comp */
import get from 'lodash.get';
import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {Col, Row} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import InlineCheckBox from '../../common/form/InlineCheckBox';

/**
 * Render a redux FieldArray of permissions as checkboxes for a single role+module
 */
export class ModulePermissionsFieldArray extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.renderPermission = this.renderPermission.bind(this);
  }

  /**
   * FieldArray.map() handler for permission checkboxes
   * @return {React.Element}
   */
  renderPermission(permissionName, permissionIndex) {
    const {module: {permissions}} = this.props;
    const permission = permissions[permissionIndex] || {};

    if(!this.props.userRolesSearchStrRegExp.test(permission.permission_name)){ return null; }
    return (
      <Col key={permissionName} xs={6} md={4}>
        <Field name={`${permissionName}.enabled`} component={InlineCheckBox} props={{
          label: permission.permission_name,
          groupClassName:'inline-checkbox',
        }}/>
      </Col>
    );
  }

  /**
   * Render primary component
   * @return {React.Element}
   */
  render() {
    const permissionInputs = this.props.fields.map( this.renderPermission );
    if (!permissionInputs.filter( p => !!p ).length) {
      return <p className='text-muted'>No matches</p>;
    }

    return (
      <div className='module-permissions'>
        <h4>{I18n.t('userRoles.permissions')}</h4>
        <Row>
          {permissionInputs}
        </Row>
      </div>
    );
  }
}

ModulePermissionsFieldArray.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
  }).isRequired,
  module: PropTypes.object.isRequired,
  userRolesSearchStrRegExp: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  const userRolesSearchStrRegExp = new RegExp(get(state,'user.userRolesSearchStr',''), 'i');
  return {
    userRolesSearchStrRegExp
  };
}
export default connect(mapStateToProps)(ModulePermissionsFieldArray);
