/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import f_filter from 'lodash.filter';
import get from 'lodash.get';
import { I18n } from 'react-redux-i18n';
import { bindActionCreators } from 'redux';
import { goBack, push } from 'react-router-redux';
import { isInvalid } from 'redux-form';

import QuickMovePanel from './QuickMovePanel';
import {ConnectedIntegrationTrackingStatusModal} from '../common/integration/IntegrationTrackingStatusModal';
import { handleComplexSelectRow } from '../../actions/helpers/selectedDataHelper';
import {
  getCanManageInternalTransfers,
  getProductsForInternalTransfers,
  getSelectedProductIds,
  normalizeFinishedProducts,
  getInventorySelector,
  getChildProducts,
  getIntegrationMappings,
  getInventoryLocks,
} from '../../selectors/finishedProductsSelectors';
import { isFeatureEnabled } from '../../selectors/featureToggles';
import { getIntegrationState } from '../../selectors/integration/integrationSelectors';
import { getFlattenedLocations, getLocationsForSharedProducts } from '../../selectors/locationsSelectors';
import { isActiveFacilityDispensary, isActiveFacilityGrow } from '../../selectors/facilitiesSelectors';
import { getPartners } from '../../selectors/partnersSelectors';
import { unsetData, setData } from '../../actions/dataActions';
import { addMessage } from '../../actions/systemActions';
import { canRetestPackages } from '../../actions/inventory';
import {setListingSourceSolr, setListingSourceService, unsetListingSource} from '../../actions/listingActions';
import * as apiActions from '../../actions/apiActions';
import {unsetItem} from '../../actions/itemActions';
import * as selectedDataActions from '../../actions/selectedDataActions';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import TablePageWrapper from '../common/grid/TablePageWrapper';
import PageTitle from '../common/PageTitle';
import ModalWrapper from '../common/ModalWrapper';
import ActivateInventoryModal from './activate/ActivateInventoryModal';
import LotLineage from './lineage/LotLineage';
import { getTotalResults } from '../../selectors/paginationSelectors';
import ServiceFirstAbstractInventoryPage from './common/ServiceFirstAbstractInventoryPage';
import InProgressOverlay from '../common/InProgressOverlay';
import LabelPrinter from '../print-services/labels/LabelPrinter';
import DailyWastePage from './daily-waste/DailyWastePage';
import { getUserHasPermissionFunc } from '../../selectors/usersSelectors';
import { isInactivationReasonDisplayed } from '../../selectors/forms/prepackWeightsFacilitySelectors';
import ConfirmReleaseReservations from './ConfirmReleaseReservations';
import { DAILY_WASTE_FORM } from '../../constants/forms';
import PackageReservations from '../reservations/PackageReservations';
import { canUserManageReservationAndIsAllowedInComplianceSettings } from '../../selectors/forms/productFormSelectors';
import * as p from '../../constants/permissions';
import InternalTransfers from '../transfers/internal/InternalTransfers';
import * as messageTypes from '../../constants/messageTypes';
import { isAllowedQuickMoveByMedicallyCompliantStatus } from '../../actions/integrationActions';
import { hasPackagesTags } from '../../selectors/integrationSelectors';
import { getInventoryComplianceSettings } from '../../selectors/complianceSettingsSelectors';
import { isColombiaFacility } from '../../selectors/facility/getFacilityCountry';
import { getHarvests } from '../../selectors/harvests/harvestsSelectors';
import { getCupos } from '../../selectors/forms/cupoFormSelectors';
import {setSolrErrorMessage} from '../../actions/solrActions';
import {getUseEntityLocksForItems} from '../../selectors/coreSettingsSelectors';
import {isListingSourceService as isListingSourceServiceSelector, isListingSourceSolr as isListingSourceSolrSelector} from '../../selectors/listingSelectors';
import {getSolrCoreName} from '../../selectors/inventoryItemsSelectors';


// Preserves memoization producing new functions based on the passed in permission
const userHasEditPerm = getUserHasPermissionFunc(p.manage_inventory);

export class ServiceFirstFinishedProductPage extends ServiceFirstAbstractInventoryPage {
  constructor(props, context) {
    super(props, context);

    this.loadTabs = this.loadTabs.bind(this);
    this.setTabs = this.setTabs.bind(this);
    this.setTabsLoaded = this.setTabsLoaded.bind(this);
    this.ensureToken = this.ensureToken.bind(this);
    this.clearTokens = this.clearTokens.bind(this);
    this.cancelTokens = this.cancelTokens.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
    this.releaseAllReservations = this.releaseAllReservations.bind(this);
    this.releaseAllReservationsConfirmed = this.releaseAllReservationsConfirmed.bind(this);
    this.dataUpdated = this.dataUpdated.bind(this);
    this.reload = this.reload.bind(this);
    this.setActiveTab = this.setActiveTab.bind(this);
    this.filter = this.filter.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleListing = this.handleListing.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.unsetSearchData = this.unsetSearchData.bind(this);
    this.hideActivateModal = this.hideActivateModal.bind(this);
    this.getLabResults = this.getLabResults.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.getTableDataSize = this.getTableDataSize.bind(this);

    this.onSubmit = this.onSubmit.bind(this);
    this.switchTab = this.switchTab.bind(this);
    this.printLabel = this.printLabel.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.hidePrinter = this.hidePrinter.bind(this);
    this.isActiveTab = this.isActiveTab.bind(this);
    this.onTabChanged = this.onTabChanged.bind(this);
    this.showWasteModal = this.showWasteModal.bind(this);
    this.hideWasteModal = this.hideWasteModal.bind(this);
    this.productDetails = this.productDetails.bind(this);
    this.isRowExpandable = this.isRowExpandable.bind(this);
    this.onSaveWasteClick = this.onSaveWasteClick.bind(this);
    this.showLineageModal = this.showLineageModal.bind(this);
    this.hideLineageModal = this.hideLineageModal.bind(this);
    this.showActivateModal = this.showActivateModal.bind(this);
    this.showReservationsModal = this.showReservationsModal.bind(this);
    this.hideReservationsModal = this.hideReservationsModal.bind(this);
    this.onActivateInventory = this.onActivateInventory.bind(this);
    this.showTransferModal = this.showTransferModal.bind(this);
    this.hideTransferModal = this.hideTransferModal.bind(this);
    this.beforeSubmitAction = this.beforeSubmitAction.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.refreshActiveTab = this.refreshActiveTab.bind(this);

    this.splitPackage = this.splitPackage.bind(this);
    this.addTestResult = this.addTestResult.bind(this);

    this.showWasteDestructionModal = this.showWasteDestructionModal.bind(this);
    this.hideWasteDestructionModal = this.hideWasteDestructionModal.bind(this);
    this.destroyWaste = this.destroyWaste.bind(this);

    this.getInventoryItems = this.getInventoryItems.bind(this);
    this.updateTrackingId = this.updateTrackingId.bind(this);
    this.handleRevertSplit = this.handleRevertSplit.bind(this);

    this.state = {
      tabsLoaded: false,
      activeTab: null,
      tabs: [],
      ready: false,
      cancelTokens: {},
      item: {},
      showActivateModal: false,
      showPrinter: false,
      showLineageModal: false,
      showWasteDestructionModal: false,
      quickMoveDisabled: false,
      reindexing: false,
      showWasteModal: false,
      showReleaseReservationsConfirmModal: false,
      doFormSubmit: 0,
      selectedRows: [],
      showReservationsModal: false,
      packageIdForReservationsModal: 0,
      packageMetaData: {},
      showTransferModal: false,
      itemIdsForLots: [],
      dataState: 'loading', // loaded, loading, or searching - actually driven by abstract page.
    };
  }

  componentWillMount() {
    this.unsetSearchData();
    this.props.actions.clearSelectedData(dataNames.products);
    this.props.actions.getUnpaginatedData('/api/location_hierarchy', dataNames.locations, undefined, {});
    this.props.actions.getUnpaginatedData('/api/partners', dataNames.partners, { failed: 'partners.get.failed' });
    this.props.actions.getUnpaginatedData('/api/partner_facilities', dataNames.partnerFacilities, {
      failed: 'partners.get.failed'
    });
    this.props.actions.getItem('/api/facility_groups_sharings/facility_status', itemNames.facilitySharingStatus);
    this.loadTabs().then(() => {
      //If url is not being changed, we still need to trigger initial tab change
      this.switchTab(this.props.params.status) || this.onTabChanged(this.props.params.status);
    });
    this.props.actions.setData([], dataNames.itemMastersWithPricing);
    this.props.actions.getItem('/api/integration-settings', itemNames.integrationSettings, {
      failed: 'stateIntegratorSettings.get.failed'
    });
    this.props.actions
      .getItem('/api/settings/values/by_key', itemNames.coreSettings, null, { ids: ['core_internal_transfers_enable'] })
      .then(() => this.setTabs());
    this.props.actions.getData('/api/feature_toggles', dataNames.featureToggles);
  }

  componentWillReceiveProps(nextProps, nextState) {
    //Tab has been changed
    if (this.state.tabsLoaded && nextProps.params.status !== this.props.params.status) {
      this.onTabChanged(nextProps.params.status);
    }
  }

  switchTab(activeTab) {
    this.setDataState('loading');
    const { tabs } = this.state;
    const isExistingTab = tabs && tabs.some((tab) => tab.eventKey === activeTab);
    let tabUrl = '/inventory';
    if (isExistingTab && activeTab) {
      tabUrl += `/${activeTab}`;
    }
    if (this.props.params.status !== activeTab) {
      this.props.actions.push(tabUrl);
      return true;
    }
    return false;
  }

  onTabChanged(status = 'active') {
    const activeTab = this.state.tabs.find((tab) => tab.eventKey === status);
    this.setActiveTab(activeTab, () => {
      this.filter(status);
    });
  }

  isActiveTab() {
    return ['inactive', 'inactive-waste'].indexOf(this.props.params.status) === -1;
  }

  showWasteModal() {
    this.setState({ showWasteModal: true });
  }

  hideWasteModal(event) {
    this.setState({ showWasteModal: false });
  }

  showWasteDestructionModal() {
    this.setState({ showWasteDesctructionModal: true });
  }

  hideWasteDestructionModal(event) {
    this.setState({ showWasteDesctructionModal: false });
  }

  destroyWaste() {
    const { selectedProducts: selectedRows } = this.props;
    const ids = (selectedRows && selectedRows.map((item) => item.item_id)) || [];
    this.props.actions.postData(
      '/api/items/dispose_waste',
      { ids },
      'noDataKey',
      {
        success: 'inventory.wasteDisposal.destroyWasteSuccess',
        failed: 'inventory.wasteDisposal.destroyWasteFailed'
      },
      null,
      () => {
        this.reload();
      }
    );
  }

  //@TODO: Refactor the modal to allow components to be embedded and use the buttons from the modal
  // as the submit buttons, probably through some middleware... so we can easily do wizards and such
  // with redux form and not having to have a ton of custom modals.
  onSaveWasteClick(event) {
    this.setState({ doFormSubmit: this.state.doFormSubmit + 1 });
  }

  beforeSubmitAction(item, integrationState) {
    if (integrationState.isWaLeaf) {
      return this.props.actions.isAllowedQuickMoveByMedicallyCompliantStatus(item);
    }

    return Promise.resolve(item);
  }

  onSubmit(formData) {
    const { integrationState } = this.props;
    this.setState({ quickMoveDisabled: true });
    const item = {
      id: formData.item_id,
      inventory_location_id: formData.inventory_location_id,
      transacted_qty: formData.qty
    };

    return this.beforeSubmitAction(item, integrationState)
      .then(this.handleSubmit)
      .then(() => {
        // Refresh the listing
        this.filter(this.state.activeTab.eventKey, false);
        this.setState({ quickMoveDisabled: false });
        this.reload(false);
      })
      .catch((error) => {
        this.setState({ quickMoveDisabled: false });
        this.props.actions.addMessage(messageTypes.error, error);
      });
  }

  handleSubmit(item) {
    return this.props.actions.postData(`/api/items/${item.id}/move`, item, dataNames.items, {
      success: 'cultivation.finishedProduct.form.quickmoveSuccess',
      failed: 'cultivation.finishedProduct.form.quickmoveFailed'
    });
  }

  productDetails(row) {
    const {
      locations,
      sharedLocations,
      selectedProductIds,
      integrationState,
      displayInactivationReason,
      hasPackagesTags,
      userHasEditPermission,
      canUserManageReservationAndIsAllowedInComplianceSettings,
      complianceSettings,
      isGrow,
      isColombia,
      harvests,
      cupos,
      isMetrcStemHoldingsToggled,
      useEntityLocks,
    } = this.props;
    return (
      <QuickMovePanel
        canUserManageReservationAndIsAllowedInComplianceSettings={
          canUserManageReservationAndIsAllowedInComplianceSettings
        }
        userHasEditPermission={userHasEditPermission}
        quickMoveDisabled={Boolean(this.state.quickMoveDisabled)}
        activeTab={this.state.activeTab}
        showSelect={this.isActiveTab() || row.lot_id}
        active={this.isActiveTab()}
        handleSelect={this.handleSelect}
        locations={locations}
        sharedLocations={sharedLocations}
        selectedProductIds={selectedProductIds}
        row={row}
        onSubmit={this.onSubmit}
        printLabel={this.printLabel}
        integrationState={integrationState}
        hasPackagesTags={hasPackagesTags}
        partners={this.props.partners}
        fetchChildrenAndLabResults={this.getInventoryItems}
        displayInactivationReason={displayInactivationReason}
        showReservationsModal={this.showReservationsModal}
        allowPackageLocationSplit={complianceSettings.allow_package_location_split ? true : false}
        complianceSettings={complianceSettings}
        showModality={isGrow && isColombia}
        harvests={harvests}
        showCupo={isGrow && isColombia}
        cupos={cupos}
        isMetrcStemHoldingsToggled={isMetrcStemHoldingsToggled}
        useEntityLocks={useEntityLocks}
      />
    );
  }

  printLabel(event) {
    event.preventDefault();
    event.stopPropagation();
    const lot_id = event.target.value;
    if (lot_id === undefined) return;
    this.setState({
      showPrinter: true,
      payload: {
        ids: [lot_id]
      }
    });
  }

  hidePrinter() {
    this.setState({ showPrinter: false });
  }

  showActivateModal() {
    const {
      integrationState: { isMetrc },
      selectedProducts,
      actions: { addMessage }
    } = this.props;
    // Do not allow inventory to be activated if the current facility is configured to use METRC and a selected package
    // does not have a Tracking ID.
    if (isMetrc && selectedProducts.some((pkg) => !pkg.state_integration_tracking_id)) {
      addMessage('error', ['cultivation.finishedProduct.error.cannotActivateWithoutTracking']);
    } else {
      this.setState({ showActivateModal: true });
    }
  }

  showLineageModal(event, item) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ item, showLineageModal: true });
  }

  hideLineageModal() {
    this.setState({ showLineageModal: false });
  }

  onRowClick({ id }) {
    const { selectedRows } = this.state;

    if (selectedRows.includes(id)) {
      return this.setState({
        selectedRows: f_filter(selectedRows, (value) => value !== id)
      });
    }

    return this.setState({
      selectedRows: [...selectedRows, id]
    });
  }

  isRowExpandable({ id }) {
    return this.state.selectedRows.includes(id);
  }

  onActivateInventory() {
    this.hideActivateModal();
    this.reload(false);
  }

  updateTrackingId(itemId) {
    // const currentMappingData = Object.assign(this.props.integrationMappings);
    // this.props.actions.unsetData(dataNames.integrationMappings);
    this.props.actions.getDataByPost('/api/integration-mapping/multiple', {
      mapping_key: 'inv_item',
      ids: [itemId]
    }).then((mappingData) => {
      if (mappingData && mappingData.length === 1) {
        const mapping = mappingData[0];
        // const existingMappingData = this.props.integrationMappings;
        // currentMappingData.push(mapping);
        if (mapping.external_identifier) {

          // update state_integration_tracking_id
          // this.props.actions.setData(currentMappingData, dataNames.integrationMappings);

          let updatedItemMasterId = null;
          // const newChildProducts = this.props.childProducts.map(item => {
          this.props.childProducts.map(item => {
            if (item.id === itemId) {
              updatedItemMasterId = item.item_master_id;
            }
            // return item.id === itemId
            //   ? {...item, state_integration_tracking_id: mapping.external_identifier, external_identifier: mapping.external_identifier}
            //   : item;
          });
          // Update the state with the new array of child products
          this.setState({updatedItemExternalIdentifier: {id: itemId, item_master_id: updatedItemMasterId, new_external_identifier: mapping.external_identifier}});
          // this.props.actions.setItem({id: itemId, new_external_identifier: mapping.external_identifier}, itemNames.updatedItemExternalIdentifier);
          // this.props.actions.setData(newChildProducts, dataNames.childProducts);

          this.props.actions.getDataByPost('/api/inventory_locks', {
            active: 1,
            entity_type: 'item',
            in_entity_ids: [itemId],
            select_columns: ['id', 'active', 'entity_id', 'lock_source', 'lock_reason', 'released_at', 'created_at', 'updated_at', 'manually_released']
          }).then((entityLocks) => {
            let updatedInventoryLocks = [];
            if (entityLocks.length > 0) {
              updatedInventoryLocks = this.props.inventoryLocks.map((lock) => {
                if (lock.entity_id === itemId) {
                  return {...entityLocks[0]};
                }
                return lock;
              })
            } else {
              updatedInventoryLocks = this.props.inventoryLocks.filter((lock) => {
                return lock.entity_id !== itemId;
              });
              // close Modal
              this.props.actions.unsetItem(itemNames.integrationTrackingStatusModal);
            }
            // console.log('this.props.inventoryLocks:', this.props.inventoryLocks);
            // console.log('updateTrackingId -> updatedInventoryLocks:', updatedInventoryLocks);
            this.props.actions.setData(updatedInventoryLocks, dataNames.inventoryLocks);
          });
        }
      }
    });
  }

  handleRevertSplit(itemId) {
    // get item by itemId
    this.props.actions.getItem(`/api/items/${itemId}`, undefined).then((item) => {
      const parentItemId = item.parent_item_id;
      const itemAdjustmentPayloads = {
        item_id: itemId,
        over_qty: 0,
        quantity: 0,
        transacted_qty: -Number(item.qty),
        adjustment_reason: 'revert_split',
      };
      // if item is not child item, stop revert and show message and then return
      if (!parentItemId) {
        this.props.actions.addMessage('error', 'integration.transactions.trackingIdStatuses.pendingIntegrator.revertNotAllowNotice');
        this.props.actions.unsetItem(itemNames.integrationTrackingStatusModal);
        this.reload(true);
        return;
      }
      // get parent item by parentItemId
      this.props.actions.getItem(`/api/items/${parentItemId}`, undefined).then((parentItem) => {
        const parentItemAdjustmentPayloads = {
          item_id: parentItemId,
          over_qty: parentItem.qty + Number(item.qty),
          quantity: parentItem.qty + Number(item.qty),
          transacted_qty: Number(item.qty),
          adjustment_reason: 'revert_split',
        };

        // post item amd parentItem to adjust
        const promises = [
          this.props.actions.postItem('/api/reconciliations/adjust',{adjustments: [itemAdjustmentPayloads]}),
          this.props.actions.postItem('/api/reconciliations/adjust',{adjustments: [parentItemAdjustmentPayloads]})
        ];
        Promise.all(promises).then((results) => {
          const arbitraryHighSizeLimit = 20000;
          const params = {
            sort: 'created_at desc',
            size: arbitraryHighSizeLimit,
            start: 0,
          };
          // reload listing
          this.handleListing(params.start, params.size, params.sort);
          // show success message
          this.props.actions.addMessage('success', 'integration.transactions.trackingIdStatuses.pendingIntegrator.revertSplitSuccess');
          // close Modal
          this.props.actions.unsetItem(itemNames.integrationTrackingStatusModal);
          this.reload(false);
        });
      });
    });
  }

  render() {
    const { activeTab, tabs, showPrinter, showActivateModal, showLineageModal, item, dataState, updatedItemExternalIdentifier } = this.state;
    const {
      selectedProductIds,
      locations,
      isInvalidReportDailyWasteForm,
      useEntityLocks
    } = this.props;

    const tableData = this.getTableData();
    if (tableData.length > 0) {
      if (updatedItemExternalIdentifier) {
        // debugger;
        tableData.forEach((row, row_index) => {
          if (row.item_master_id === updatedItemExternalIdentifier.item_master_id) {
            row.children.forEach((child, child_index) => {
              if (child.id === updatedItemExternalIdentifier.id) {
                tableData[row_index]['children'][child_index]['state_integration_tracking_id'] = updatedItemExternalIdentifier.new_external_identifier;
                tableData[row_index]['children'][child_index]['external_identifier'] = updatedItemExternalIdentifier.new_external_identifier;
              }
            });
          }
        });
      }
    }

    return (
      <div>
        <PageTitle
          primaryText={I18n.t('cultivation.finishedProduct.inventory')}
          secondaryText={I18n.t('cultivation.finishedProduct.finishedProduct')}
        />
        {activeTab ? (
          <TablePageWrapper
            ref={this.ref}
            settingKey='finished-products'
            columns={activeTab.columns}
            tabs={tabs}
            activeTab={activeTab.eventKey}
            switchTab={this.switchTab}
            data={tableData}
            dataState={dataState}
            dataTotalSize={this.getTableDataSize()}
            useRenderDataFlag={true}
            handleSelect={this.handleSelect}
            selectedRows={selectedProductIds}
            scanSearchCol='lot_number'
            hideScanSearch={true}
            externalSearch={this.handleSearch}
            showHistory={false}
            external={true}
            className='finished-product-page'
            hideExport={true}
            isSolrListing={true}
            useAutoSuggest={this.props.useAutoSuggest && this.props.useInventoryAutoSuggest}
            autoSuggestPlaceholder='cultivation.finishedProduct.table.suggestPlaceholder'
            sort={'lot_created_at desc'}
            bstProps={{
              options: {
                onSearchChange: this.onSearchChange,
                hideSizePerPage: true,
                onRowClick: this.onRowClick,
                expandBy: 'column'
              },
              selectRow: {
                clickToSelect: false,
                hideSelectColumn: true,
                clickToExpand: true
              },
              expandableRow: this.isRowExpandable,
              expandComponent: this.productDetails,
              ignoreSinglePage: true,
              trClassName: (row) => this.trClassName(useEntityLocks, row)
            }}
          />
        ) : (
          <InProgressOverlay isActive={true} message={I18n.t('common.form.loading')} />
        )}
        <LabelPrinter
          showModal={showPrinter}
          onHide={this.hidePrinter}
          labelTag='inv_package_tag'
          payload={this.state.payload}
        />
        <ModalWrapper
          Component={ActivateInventoryModal}
          title='cultivation.finishedProduct.activateInventory'
          onActivateInventory={this.onActivateInventory}
          locations={locations}
          onHide={this.hideActivateModal}
          showModal={showActivateModal}
        />
        <ModalWrapper
          Component={LotLineage}
          title='lotLineage.title'
          onHide={this.hideLineageModal}
          showModal={showLineageModal}
          lotId={item.lot_id}
        />
        <ModalWrapper
          title='Report Daily Waste By Location'
          onHide={this.hideWasteModal}
          showModal={this.state.showWasteModal}
          headerClass='bg-info-dark'
          dialogClassName='modal-md'
          cancelButton={{ show: true }}
          okayButton={{
            show: true,
            text: 'Save',
            onClick: this.onSaveWasteClick,
            disabled: isInvalidReportDailyWasteForm
          }}
          version={2}
          doFormSubmit={this.state.doFormSubmit} /*for daily waste page*/
          Component={DailyWastePage}
        />
        <ModalWrapper
          Component={false}
          title={I18n.t('inventory.wasteDisposal.disposeWaste')}
          headerClass='bg-info-dark'
          onHide={this.hideWasteDestructionModal}
          showModal={this.state.showWasteDesctructionModal}
          okayButton={{ show: true, onClick: this.destroyWaste, text: I18n.t('general.yes') }}
          cancelButton={{ show: true, onClick: this.hideWasteDestructionModal, text: I18n.t('general.no') }}
          dialogClassName='modal-sm'
          version={2}
        >
          <p>{I18n.t('inventory.wasteDisposal.confirmWasteDestruction')}</p>
        </ModalWrapper>
        <ModalWrapper
          Component={ConfirmReleaseReservations}
          onHide={() => this.setState({ showReleaseReservationsConfirmModal: false })}
          showModal={this.state.showReleaseReservationsConfirmModal}
          confirm={this.releaseAllReservationsConfirmed}
          title='reservations.confirmingClearAllFacilityReservations'
        />
        <ModalWrapper
          title={{
            text: `reservations.packageModalTitle`,
            data: { code: get(this.state.packageMetaData, 'package_id') }
          }}
          onHide={this.hideReservationsModal}
          showModal={this.state.showReservationsModal}
          headerClass='bg-info-dark'
          dialogClassName='modal-xl'
          cancelButton={{ show: true }}
          version={2}
        >
          <PackageReservations
            packageId={this.state.packageIdForReservationsModal}
            packageMetaData={this.state.packageMetaData}
          />
        </ModalWrapper>

        <ModalWrapper
          title={`Internal Transfer`}
          onHide={this.hideTransferModal}
          showModal={this.state.showTransferModal}
          headerClass='bg-info-dark'
          dialogClassName='modal-xl'
          version={2}
        >
          <InternalTransfers
            selectedProducts={this.props.internalTransferProducts}
            handleSelect={this.handleSelect}
            onCancel={this.hideTransferModal}
          />
        </ModalWrapper>
        <ConnectedIntegrationTrackingStatusModal
          updateTrackingId={this.updateTrackingId}
          handleRevertSplit={this.handleRevertSplit}
        />
      </div>
    );
  }
}

ServiceFirstFinishedProductPage.defaultProps = {
  hasMetrcSettings: false
};

ServiceFirstFinishedProductPage.propTypes = {
  products: PropTypes.array.isRequired,
  selectedProductIds: PropTypes.array.isRequired,
  selectedProducts: PropTypes.array.isRequired,
  internalTransferProducts: PropTypes.array,
  locations: PropTypes.array.isRequired,
  sharedLocations: PropTypes.array.isRequired,
  dataTotalSize: PropTypes.number.isRequired,
  hasMetrcSettings: PropTypes.bool,
  timezone: PropTypes.string.isRequired,
  facility: PropTypes.object.isRequired,
  isRetail: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    getItem: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    getSearchData: PropTypes.func.isRequired,
    getData: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired,
    setData: PropTypes.func.isRequired,
    handleComplexSelectRow: PropTypes.func.isRequired,
    clearSelectedData: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    getDataByPost: PropTypes.func.isRequired,
    setSolrErrorMessage: PropTypes.func,
    setListingSourceSolr: PropTypes.func.isRequired,
    setListingSourceService: PropTypes.func.isRequired,
    unsetListingSource: PropTypes.func.isRequired,
    canRetestPackages: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired,
  }).isRequired,
  params: PropTypes.shape({
    status: PropTypes.oneOf(['active', 'inactive'])
  }).isRequired,
  integrationState: PropTypes.object.isRequired,
  partners: PropTypes.array,
  displayInactivationReason: PropTypes.bool,
  isInvalidReportDailyWasteForm: PropTypes.bool,
  hasPackagesTags: PropTypes.bool,
  archiveInactiveAfter: PropTypes.number.isRequired,
  complianceSettings: PropTypes.object,
  isMetrcStemHoldingsToggled: PropTypes.bool,
  useAutoSuggest: PropTypes.bool,
  useInventoryAutoSuggest: PropTypes.bool,
  isListingSourceService: PropTypes.bool.isRequired,
  isListingSourceSolr: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
  const { selectedProducts } = state;

  //@TODO: Remove products and dependencies when done with updates for service first inventory loaded into inventoryByLots
  const isListingSourceService = isListingSourceServiceSelector(state);
  const isListingSourceSolr =  isListingSourceSolrSelector(state);

  // Only run selector when appropriate otherwise we create issues
  const products = isListingSourceSolr ? normalizeFinishedProducts(state, { groupByPackage: ownProps.params.status === 'inactive' }) : [];
  const inventoryByLots = isListingSourceService ? getInventorySelector(state) : [];
  const selectedProductIds = getSelectedProductIds(state);
  const integrationState = getIntegrationState(state);
  const complianceSettings = getInventoryComplianceSettings(state);
  const archiveInactiveAfter = parseInt(get(complianceSettings, 'archive_inactive_inventory_after', '0')) || 0;

  return {
    state,
    locations: getFlattenedLocations(state),
    sharedLocations: getLocationsForSharedProducts(state),
    dataTotalSize: getTotalResults(state, { name: dataNames.products }),
    selectedProductIds,
    selectedProducts,
    internalTransferProducts: getProductsForInternalTransfers(state),
    pageSize: get(state, `${itemNames.columnVisibilitySettings}.per_page`, false),
    facility: state.facility,
    timezone: state.timezone,
    isRetail: isActiveFacilityDispensary(state),
    partners: getPartners(state),
    integrationState,
    displayInactivationReason: isInactivationReasonDisplayed(state),
    isInvalidReportDailyWasteForm: isInvalid(DAILY_WASTE_FORM)(state),
    canUserManageReservationAndIsAllowedInComplianceSettings: canUserManageReservationAndIsAllowedInComplianceSettings(
      state,
      { permissions: [p.manage_reservations] }
    ),
    canManageInternalTransfers: getCanManageInternalTransfers(state),
    userHasEditPermission: userHasEditPerm(state),
    hasPackagesTags: hasPackagesTags(state),
    complianceSettings,
    archiveInactiveAfter,
    isGrow: isActiveFacilityGrow(state),
    isColombia: isColombiaFacility(state),
    harvests: getHarvests(state),
    cupos: getCupos(state),
    isMetrcStemHoldingsToggled: isFeatureEnabled(state)('feature_metrc_stem_holdings'),
    useAutoSuggest: isFeatureEnabled(state)('feature_solr_inventory_suggest'),
    useInventoryAutoSuggest: isFeatureEnabled(state)('feature_solr_inventory_suggest_inv_page_enable'),
    useEntityLocks: getUseEntityLocksForItems(state),
    products,
    inventoryByLots,
    isListingSourceService,
    isListingSourceSolr,
    childProducts: getChildProducts(state),
    integrationMappings: getIntegrationMappings(state),
    inventoryLocks: getInventoryLocks(state),
    solrCoreName: getSolrCoreName(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, selectedDataActions, {
    handleComplexSelectRow,
    goBack,
    push,
    unsetData,
    setData,
    addMessage,
    isAllowedQuickMoveByMedicallyCompliantStatus,
    setSolrErrorMessage,
    setListingSourceSolr,
    setListingSourceService,
    unsetListingSource,
    canRetestPackages,
    unsetItem
  });
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceFirstFinishedProductPage);
