import React from 'react';
import {bindActionCreators} from 'redux';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';

import TablePageWrapper from '../../common/grid/TablePageWrapper';
import {getPaginatedData} from '../../../actions/apiActions';
import * as dataNames from '../../../constants/dataNames';
import {getClosedLoopRegulatedUsers} from '../../../selectors/closedLoopsSelectors';
import {getTotalResults} from '../../../selectors/paginationSelectors';
import {getTabs, getColumns} from './tabDefinitions';


class RegulatedUsersListingPage extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.switchTab = this.switchTab.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.ref = React.createRef();

    this.state = {
      activeTab: 'pending'
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.params.tab !== prevProps.params.tab) {
      this.getData();
    }
  }

  getData() {
    if(this.ref.current){
      this.ref.current.wrappedInstance.ref.current.debouncedExternalSearch();
    }
  }

  switchTab(tab) {
    this.props.actions.push(`/closed-loop/users/${tab}`);
  }

  handleSearch (sort, search, size = 20, start, filter) {
    const approval_status = this.props.params.tab ? this.props.params.tab : 'pending';

    const params = {approval_status};
    if (search) {
      params.search = search;
    }
    params.sort = sort || 'user_created_at desc';
    if (size) {
      params.per_page = size;
    }
    if (start) {
      params.page = ((start ? start / size : 0) + 1);
    }
    this.props.actions.getPaginatedData('/api/closed_loops/users',
      dataNames.closedLoopRegulatedUsers,
      null,
      params,
    );
  }


  render() {
    const {params, dataTotalSize, users, actions} = this.props;
    const activeTab = params.tab || 'pending';

    return (
      <React.Fragment>
        <h1>{I18n.t('closedLoop.regulatedUser.listingPageTitle')}</h1>

        <TablePageWrapper
          ref={this.ref}
          settingKey='closed-loop-regulated-users'
          columns={getColumns(actions, activeTab)}
          data={users}
          dataTotalSize={dataTotalSize}
          activeTab={activeTab}
          tabs={getTabs()}
          switchTab={this.switchTab}
          externalSearch={this.handleSearch}
          hideSearch={false}
          hideScanSearch={true}
          hideExport={true}
          external={true}
        />
      </React.Fragment>
    );
  }
}

RegulatedUsersListingPage.propTypes = {
  users: PropTypes.array.isRequired,
  dataTotalSize: PropTypes.number,
  params: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    getPaginatedData: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  users: getClosedLoopRegulatedUsers(state),
  dataTotalSize: getTotalResults(state, { name: dataNames.closedLoopRegulatedUsers })
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({getPaginatedData, push}, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegulatedUsersListingPage);
