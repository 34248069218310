/* eslint-disable import/no-named-as-default*/
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack} from 'react-router-redux';

import {EA} from '../../../constants/uoms';
import {getItem, getDataByPost, getUnpaginatedData, postItem} from '../../../actions/apiActions';
import {getCompleteSeedPackagesPageProps} from '../../../selectors/forms/completeEachPackagesFormSelectors';
import EachPackagesPageBase from '../common/EachPackagesPageBase';


class CompleteSeedPackagesPage extends EachPackagesPageBase {
  getItemMasterFilter(job) {
    const {item: {item_master: {strain_id}}} = job;
    return `active:1 AND is_draft:0 AND strain_id:${strain_id} AND default_uom:"${EA}" AND category_code:"PLANTS" AND subcategory_code:"SEED" AND uom_type:"discrete"`;
  }
}

CompleteSeedPackagesPage.propTypes = {
  actions: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    getDataByPost: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
  }).isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  initialValues: PropTypes.object.isRequired,
  employees: PropTypes.array.isRequired,
  itemMasters: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  packagedWeight: PropTypes.number.isRequired,
  timezone: PropTypes.string,
  integrationState: PropTypes.object,
  isSeedPackaging: PropTypes.bool.isRequired,
};

function mapDispatchToProps(dispatch) {
  const actions = {getItem, getDataByPost, getUnpaginatedData, postItem, goBack};

  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(getCompleteSeedPackagesPageProps, mapDispatchToProps)(CompleteSeedPackagesPage);
