import {createSelector} from 'reselect';
import {I18n} from 'react-redux-i18n';
import * as dataNames from '../constants/dataNames';
import {filterActive} from './salesLocationsSelectors';
import flattenLocations from '../util/flattenLocations';

export function getStorageLocations(state) {
  return state[dataNames.storageLocations];
}

export const getSetupInitialValues = createSelector(
  [getStorageLocations],
  storageLocations => {
    const initialValues = {
      defaults: [1, 1, 1, 1],
      child_locations: [{
        name: I18n.t('locations.form.building', {id: 1}), is_sales_location: 0,  active: 1, child_locations: [{
          name: I18n.t('locations.form.room', {id: 1}), active: 1, is_sales_location: 0, child_locations: [{
            name: I18n.t('locations.form.zone', {id: 1}), is_sales_location: 0, active: 1, child_locations: [{
              name: I18n.t('locations.form.section', {id: 1}), is_sales_location: 0, active: 1, child_locations: []
            }]
          }]
        }]
      }]
    };
    if (storageLocations && storageLocations.length) {
      const activeLocations = filterActive(storageLocations);
      if (activeLocations && activeLocations.length) {
        initialValues.child_locations = activeLocations;
      }
    }
    return initialValues;
  }
);

/***
 * Returns object containing an array for each type of location: buildings, rooms, zones, sections
 */
export const getStorageLocationCollections = createSelector([getStorageLocations], (storageLocations) => {
  const collections = {buildings: [], rooms: [], zones: [], sections: []};
  if(!storageLocations || !Array.isArray(storageLocations) || storageLocations.length === 0) return collections;
  const levelMap = ['buildings', 'rooms', 'zones', 'sections'];
  return storageLocations.reduce((acc, location) => {
    const store = (children, level) => {
      if(!Array.isArray(children) || level > 3) return false;
      const locations = children.reduce((ac, child) => {
        if(child.child_locations && child.child_locations.length) store(child.child_locations, level + 1);
        ac.push((({id, name, parent_location_id}) => ({id, name, parent_location_id}))(child)); // push subset object
        return ac;
      }, []);
      acc[levelMap[level]] = acc[levelMap[level]].concat(locations);
      return acc;
    };
    return store([location], 0);
  }, collections);
});

export const getActiveStorageLocations = createSelector([getStorageLocations], filterActive);
export const getFlattenedStorageLocations = createSelector([getActiveStorageLocations], flattenLocations);
