export  const adjustTemplate = 'adjustTemplate';
export const applicationValues = 'applicationValues';
export const constants = 'constants';
export const assembly = 'assembly';
export const batch = 'batch';
export const customer = 'customer';
export const connectsRequest = 'connectsRequest';
export const connectsRequestingOrganization = 'connectsRequestingOrganization';
export const connectsTargetOrganization = 'connectsTargetOrganization';
export const connectsPartnerItemMasterMappings = 'connectsPartnerItemMasterMappings';
export const courierDeliveryOrder = 'courierDeliveryOrder';
export const courierPartners = 'courierPartners';
export const deliveryOrder = 'deliveryOrder';
export const refundOrderInitiated = 'refundOrderInitiated';
export const restockOrderInitiated = 'restockOrderInitiated';
export const budTendersObject = 'budTendersObject';
export const destruction = 'destruction';
export const entityType = 'entityType';
export const entityId = 'entityId';
export const updatedItemExternalIdentifier = 'updatedItemExternalIdentifier'; // This is used as a work around to show a newly fetched external identifier without fetching all new data
export const feedingSchedule = 'feedingSchedule';
export const location = 'location';
export const partner = 'partner';
export const partnerFacility = 'partnerFacility';
export const partnerFacilityDetails = 'partnerFacilityDetails';
export const partnerContact = 'partnerContact';
export const partnerSource = 'partnerSource';
export const platformIncomingTransferDetails = 'platformIncomingTransferDetails';
export const incomingTransferDetails = 'incomingTransferDetails';
export const platformPurchaseOrderDetails = 'platformPurchaseOrderDetails';
export const preferredMix = 'preferredMix';
export const phenotype = 'phenotype';
export const lot = 'lot';
export const pkg = 'package';
export const packagingJob = 'packagingJob';
export const plant = 'plant';
export const plantCost = 'plantCost';
export const deferredPlantAction = 'deferredPlantAction';
export const plantMapping = 'plantMapping';
export const product = 'product';
export const detailedProduct = 'detailedProduct';
export const queueCustomer = 'queueCustomer';
export const stage = 'stage';
export const strain = 'strain';
export const uom = 'uom';
export const waste = 'waste';
export const wasteCompliance = 'wasteCompliance';
export const distributionSettings = 'distributionSettings';
export const cultivationSettings = 'cultivationSettings';
export const supplySettings = 'supplySettings';
export const role = 'role';
export const infusion = 'infusion';
export const roleType = 'roleType';
export const permission = 'permission';
export const harvest = 'harvest';
export const harvestBatch = 'harvestBatch';
export const splitHarvestBatch = 'splitHarvestBatch';
export const splitPlant = 'splitPlant';
export const harvestBatchMapping = 'harvestBatchMapping';
export const itemMasterPackageId = 'itemMasterPackageId';
export const container = 'container';
export const containerCode = 'containerCode';
export const integrationSettings = 'integrationSettings';
export const orgIntegrationSettings = 'orgIntegrationSettings';
export const inventoryItem = 'inventoryItem';
export const image = 'image';
export const authenticationKey = 'authenticationKey';
export const document = 'document';
export const itemMaster = 'itemMaster';
export const itemMasterParent = 'itemMasterParent';
export const checkoutScan = 'checkoutScan';
export const facility = 'facility';
export const facilityDetailed = 'facilityDetailed';
export const facilityGroup = 'facilityGroup';
export const closedLoopFacility = 'closedLoopFacility';
export const currentFacilityUser = 'currentFacilityUser';
export const tax = 'tax';
export const taxProfile = 'taxProfile';
export const selectedUser = 'selectedUser';
export const organization = 'organization';
export const currentOrganization = 'currentOrganization';
export const manageOrganization = 'manageOrganization';
// @TODO: Remove temporaryCart after cart can get order and patient tax info type from redux.
export const temporaryCart = 'temporaryCart';
export const register = 'register';
export const registerClosing = 'registerClosing';
export const registerByType = 'registerByType';
export const registerReconciliation = 'registerClosingReconciliation';
export const transaction = 'transaction';
export const order = 'order';
export const orderType = 'orderType';
export const usageType = 'usageType';
export const orderProduct = 'orderProduct';
export const orderDemographic = 'orderDemographic';
export const orderComplianceError = 'orderComplianceError';
export const catalog = 'catalog';
export const label = 'label';
export const printLabel = 'printLabel';
export const printReceipt = 'printReceipt';
export const selectedPrintLabel = 'selectedLabel';
export const printJob = 'printJob';
export const building = 'building';
export const ingredient = 'ingredient';
export const packageId = 'packageId';
export const reconciliation = 'reconciliation';
export const inventoryAudit = 'inventoryAudit';
export const inventoryAuditExport = 'inventoryAuditExport';
export const inventorySyncDataStatus = 'inventorySyncDataStatus';
export const inventorySyncDataStatusMessage = 'inventorySyncDataStatusMessage';
export const packageCodeWeightName = 'packageCodeWeightName';
export const pricingWeightId = 'pricingWeightId';
export const pricingGroup = 'pricingGroup';
export const pricingMultiplier = 'pricingMultiplier';
export const pricingClass = 'pricingClass';
export const customerCurrentOrder = 'customerCurrentOrder';
export const customersPurchasedAmounts = 'customersPurchasedAmounts';
export const customerStat = 'customerStat';
export const customerLimits = 'customerLimits';
export const customerRecentHistory = 'customerRecentHistory';
export const customerGroup = 'customerGroup';
export const purchaseOrder = 'purchaseOrder';
export const parentPurchaseOrder = 'parentPurchaseOrder';
export const purchaseOrderLineage = 'purchaseOrderLineage';
export const inventoryReceipt = 'inventoryReceipt';
export const salesOrder = 'salesOrder';
export const coupon = 'coupon';
export const productList = 'productList';
export const reward = 'reward';
export const rewardSettings = 'rewardSettings';
export const complianceSettings = 'complianceSettings';
export const invComplianceSettings = 'invComplianceSettings';
export const retailComplianceSettings = 'retailComplianceSettings';
export const patientComplianceSettings = 'patientComplianceSettings';
export const patientSettings = 'patientSettings';
export const salesComplianceSettings = 'salesComplianceSettings';
export const minimumDaysSupply = 'minimumDaysSupply';
export const transfer = 'transfer';
export const integratedTransfer = 'integratedTransfer';
export const internalOrder = 'internalOrder';
export const supplyChainMapping = 'supplyChainMapping';
export const receipt = 'receipt';
export const receiptOrder = 'receiptOrder';
export const orderSettings = 'orderSettings';
export const vehicle = 'vehicle';
export const driver = 'driver';
export const testResult = 'testResult';
export const testResultMapping = 'testResultMapping';
export const testResultConfiguration = 'testResultConfiguration';
export const selectedTestType = 'selectedTestType';
export const onHandWeightFacility = 'onHandWeightFacility';
export const onHandWeightOrganization = 'onHandWeightOrganization';
export const refundOrder = 'refundOrder';
export const qzTray = 'qzTray';
export const qzMeta = 'qzMeta';
export const receiptCategories = 'receiptCategories';
export const labelTag = 'labelTag';
export const sourceLabel = 'sourceLabel';
export const labelsCompliance = 'labelsCompliance';
export const selectedPrinter = 'selectedPrinter';
export const localPrintServer = 'localPrintServer';
export const orderTypeCounts = 'orderTypeCounts';
export const processingType = 'processingType';
export const processingJob = 'processingJob';
export const stateIntegrators = 'stateIntegrators';
export const leafStates = 'leafStates';
export const metrcStates = 'metrcStates';
export const biotrackStates = 'biotrackStates';
export const apisicpaStates = 'apisicpaStates';
export const cureCategorySettings = 'cureCategorySettings';
export const metrcPatientLimits = 'metrcPatientLimits';
export const metrcTransfersEnabled = 'metrcTransfersEnabled';
export const finishedProduct = 'finishedProduct';
export const equipment = 'equipment';
export const consumer = 'consumer';
export const validateItemMaster = 'validateItemMaster';
export const itemAvailability = 'itemAvailability';
export const expirePasswordsSettings = 'expirePasswordsSettings';
export const lotLineage = 'lotLineage';
export const checkInFormValues = 'checkInFormValues';
export const oraclePatient = 'oraclePatient';
export const oraclePatientSupplementalInfo = 'oraclePatientSupplementalInfo';
export const oracleCaregiver = 'oracleCaregiver';
export const curePatient = 'curePatient';
export const cureCaregiver = 'cureCaregiver';
export const creditCardProcessors = 'creditCardProcessors';
export const creditCardsSettings = 'creditCardsSettings';
export const orgFacilityStateLeaf = 'orgFacilityStateLeaf';
export const mmePartnersCertNumber = 'mmePartnersCertNumber';
export const creditCardPayment = 'creditCardPayment';
export const leafTransferStatus = 'leafTransferStatus';
export const profileImage = 'profileImage';
export const physician = 'physician';
export const requireLabResultForBatch = 'requireLabResultForBatch';
export const packagingInheritsLabResults = 'packagingInheritsLabResults';
export const isolocityLabResult = 'isolocityLabResult';
export const columnVisibilitySettings = 'columnVisibilitySettings';
export const setSelectedTaxDocumentRecord = 'setSelectedTaxDocumentRecord';
export const solrErrorMessage = 'solrErrorMessage';
export const solrSearchTerms = 'solrSearchTerms';
export const facilitySharingStatus = 'facilitySharingStatus';
export const sharedFacilityGroup = 'sharedFacilityGroup';
export const thirdPartyIntegration = 'thirdPartyIntegration';
export const onfleetWorker = 'onfleetWorker';
export const selectedConsumerId = 'selectedConsumerId';
export const rules = 'rules';
export const insuranceDocument = 'insuranceDocument';
export const certificateOfAnalysisDocument = 'certificateOfAnalysisDocument';
export const debouncedFunctions = 'debouncedFunctions';
export const negativeInventoryStatus = 'negativeInventoryStatus';
export const assemblyFormData = 'assemblyFormData';
export const allPayments = 'allPayments';
export const coreInternalTransfersSettings = 'coreInternalTransfersSettings';
export const metrcReconciliation = 'metrcReconciliation';
export const settingsHash = 'settingsHash';
export const coreSettings = 'coreSettings';
export const reassignPackages = 'reassignPackages';
export const colombiaModalities = 'colombiaModalities';
export const cupo = 'cupo';
export const orderImportItem = 'orderImportItem';
export const activeThirdPartyIntegrations = 'activeThirdPartyIntegrations';
export const metrcVendorProductMappings = 'metrcVendorProductMappings';
export const internationalSettings = 'internationalSettings';
export const labResultReferences = 'labResultReferences';
export const labResult = 'labResult';
export const labResultTemplate = 'labResultTemplate';
export const inheritedLabResult = 'inheritedLabResult';
export const integrationTrackingStatusModal = 'integrationTrackingStatusModal';
export const useEntityLocks = 'useEntityLocks';
export const currentParams = 'currentParams';
export const currentPage = 'currentPage';
export const currentRequest = 'currentRequest';
export const listingSource = 'listingSource';
export const merchantConfigurationCompany = 'merchantConfigurationCompany';
export const merchantProfile = 'merchantProfile';
export const transferStatus = 'transferStatus';
export const activeTabEventKey = 'activeTabEventKey';