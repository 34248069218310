import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack, push} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import labelsConfig from '../common/labels.configuration';

import {getData} from '../../../actions/apiActions';
import {setData} from '../../../actions/dataActions';
import {setItem} from '../../../actions/itemActions';

import {labelsConfiguration, customLabels} from '../../../constants/dataNames';
import {labelTag} from '../../../constants/itemNames';

import FormWrapper from '../../common/form/FormWrapper';
import StatusLegend from '../common/StatusLegend';
import StatusCheckBox from '../common/StatusCheckBox';
import InternationalDecimalStatic from '../../common/form/InternationalDecimalStatic';

class LabelsCustomListPage extends React.PureComponent{

  constructor(props, context) {
    super(props, context);
    this.onLabelSelect = this.onLabelSelect.bind(this);
    this.renderCustomLabel = this.renderCustomLabel.bind(this);
  }

  componentWillMount() {
    this.props.actions.setData(labelsConfig, labelsConfiguration);
    this.props.actions.getData('/api/labels/custom_labels', customLabels);
  }

  onLabelSelect(label) {
    this.props.actions.setItem(label, labelTag);
    this.props.actions.push('/labels/custom/modify');
  }

  renderCustomLabel(label, index) {
    return (
      <tr key={index}>
        <td>
          <a href='#'
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              this.onLabelSelect(label);
            }}>
            {label.name}
          </a>
        </td>
        <td>
          <InternationalDecimalStatic>{label.width}</InternationalDecimalStatic> x <InternationalDecimalStatic>{label.height}</InternationalDecimalStatic>
        </td>
        <td style={{textAlign: 'center'}}>
          <StatusCheckBox status={label.dpi_203} withText={false}/>
        </td>
        <td style={{textAlign: 'center'}}>
          <StatusCheckBox status={label.dpi_300} withText={false}/>
        </td>
        <td style={{textAlign: 'center'}}>
          <StatusCheckBox status={label.dpi_600} withText={false}/>
        </td>
      </tr>
    );
  }

  render(){

    return (
      <FormWrapper title='labels.customLabelsListHeader' goBack={this.props.actions.goBack}>
        <div>
          <div className='row' style={{margin: '10px 0px'}}>
            <div className='col-md-6'>
              <StatusLegend/>
            </div>
            <div className='col-md-6'>
              <strong>{I18n.t('labels.aboutDpiAndLabels')}:</strong> {I18n.t('labels.aboutDpiAndLabelsText')}
            </div>
          </div>
          <div className='row' style={{margin: '10px 0px'}}>
            <div className='col-md-12'>
              <div>
                {I18n.t('labels.clickLabelToOpen')}
              </div>
            </div>
          </div>
          <table className='table table-striped table-bordered'>
            <thead>
            <tr>
              <th>{I18n.t('labels.labelName')}</th>
              <th>{I18n.t('labels.labelDescription')}</th>
              <th style={{width: '10%', textAlign: 'center'}}>{I18n.t('labels.dpi_203')}</th>
              <th style={{width: '10%', textAlign: 'center'}}>{I18n.t('labels.dpi_300')}</th>
              <th style={{width: '10%', textAlign: 'center'}}>{I18n.t('labels.dpi_600')}</th>
            </tr>
            </thead>
            <tbody>
            {(this.props[customLabels].map(this.renderCustomLabel))}
            </tbody>
          </table>
        </div>
      </FormWrapper>
    );
  }

}

LabelsCustomListPage.defaultProps = {
  [customLabels]: [],
};

LabelsCustomListPage.propTypes = {
  [customLabels]: PropTypes.array,
  actions: PropTypes.object.isRequired,
  labelsConfiguration: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  return {
    labelsConfiguration: state.labelsConfiguration,
    customLabels: state[customLabels]
  };
}

function mapDispatchToProps (dispatch) {
  const actions = {setData, setItem, goBack, push, getData};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LabelsCustomListPage);
