import React from 'react';
import PropTypes from 'prop-types';
import {Button, Card} from 'react-bootstrap';
import InternationalDecimalStatic from '../../common/form/InternationalDecimalStatic';

const LabelProperties = (props) => {

  const currentDefinition = props.definitions[props.currentDefinitionIndex] !== undefined
    ? props.definitions[props.currentDefinitionIndex]
    : {
      name: 'Loading',
      definition: false
    };

  if(currentDefinition.multiLabel === undefined) currentDefinition.multiLabel = 0;

  const labelMap = {
    fontSize: 'Font Size',
    height: 'Height',
    width: 'Width',
    name: 'Name',
    is_active: 'Status',
    margins: 'Margins',
    top: 'Top',
    right: 'Right',
    bottom: 'Bottom',
    left: 'Left',
    //lineSpacing: 'Line Spacing', // Deferred until at least 0.6.3
    upperCaseText: 'All Text Upper Case',
    rotateLabel: 'Label Orientation In Degrees',
    multiLabel: 'Print Across Multiple Labels',
  };

  const properties = !currentDefinition.definition
    ? []
    : Object.keys(currentDefinition).reduce((acc, key) => {
      if(key === 'definition') return acc;
      if(key === 'margins'){
        for(const prop in currentDefinition[key]){
          acc.push({
            text: labelMap[prop],
            value: <InternationalDecimalStatic fractionPartSize={5}>{parseFloat(currentDefinition[key][prop])}</InternationalDecimalStatic>
          });
        }
        return acc;
      }
      if(labelMap[key] === undefined) return acc;
      acc.push({
        text: labelMap[key],
        value: key === 'multiLabel'
          ? currentDefinition[key] === 0
            ? 'No'
            : 'Yes'
          : currentDefinition[key],
      });
      return acc;
    }, []);

  return (
    <Card>
      <Card.Header>
        {properties.length > 0 ? `Properties For ${currentDefinition.name}` : 'Loading'}
        <Button
          style={{position: 'relative', top: '-5px', borderRadius: '2px !important'}}
          className='btn btn-primary btn-sm float-right'
          size='sm'
          onClick={(event) => {
            event.target.blur();
            props.onEdit('properties');
          }}
        >
          Edit
        </Button>
      </Card.Header>
      <Card.Body>
        <table className='table table-striped table-bordered'>
          <thead>
          <tr>
            <th style={{width: '80%'}}>Property</th><th>Value</th>
          </tr>
          </thead>
          <tbody>
          {properties.map((prop, index) => {
            return (
              <tr key={index}>
                <td>{prop.text}</td>
                {prop.text === 'Status'
                  ? <td style={{color: prop.value ? 'green' : 'red'}}>{prop.value ? 'Active' : 'Disabled'}</td>
                  : <td>{typeof prop.value === 'boolean' ? prop.value ? 'Yes' : 'No' : prop.value}</td>
                }
              </tr>
              );
          })}
          </tbody>
        </table>
      </Card.Body>
    </Card>
  );
};

LabelProperties.propTypes = {
  definitions: PropTypes.array.isRequired,
  currentDefinitionIndex: PropTypes.number.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default LabelProperties;
