import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {REWARD_POINTS_FORM} from '../../../constants/forms';
import LeafPaConcealer from '../../common/concealers/LeafPaConcealer';
import {ConnectedLeafPaPhysicianNote} from '../../common/leaf-pa-sales-limits/LeafPaPhysicianNote';
import ReduxProfile from './ReduxProfile'; // eslint-disable-line import/no-named-as-default
import Information from './Information';
// import Loyalty from './Loyalty';
import Favorites from './Favorites';
import Orders from './Orders';
import RewardsEditorPOS from '../../retail/rewards/RewardsEditorPOS'; //eslint-disable-line

const Patient = ({user, customer, customerStat, customerRecentHistory, showCloseButton, close, push}) => {
  //let isCustomerCheckout = user.isOnline;
  let isCustomerCheckout = false;

  return (
    <div className='patient'>
      <ReduxProfile showCloseButton={showCloseButton} close={close} editProfile={push} />
      <LeafPaConcealer>
        <ConnectedLeafPaPhysicianNote />
      </LeafPaConcealer>
      <Information
        isCustomerCheckout={isCustomerCheckout} birth_date={customer.birth_date}
        total_spent={customer.total_spent}
        total_orders={customer.total_orders}
        customer_ids={customer.ids}/>

      <RewardsEditorPOS
        form={REWARD_POINTS_FORM}
        customer={customer}
        reloadCustomerAfterSave={true}
        key={get(customer, 'id')}
      />

      <Favorites customerStat={customerStat} />

      <Orders
        push={push}
        close={close}
        customerRecentHistory={customerRecentHistory}
      />
    </div>
  );
};


Patient.propTypes = {
  user: PropTypes.object,
  customer: PropTypes.object,
  customerStat: PropTypes.object,
  customerRecentHistory: PropTypes.object,
  customerOrders: PropTypes.array,
  showCloseButton: PropTypes.bool,
  close: PropTypes.func,
  push: PropTypes.func,
};

Patient.defaultProps = {
  customer: {},
  customerStat: {
    week: {
      order_count: 0,
      total_spent: 0
    },
    month: {
      order_count: 0,
      total_spent: 0
    },
    last_90_days: {
      order_count: 0,
      total_spent: 0
    },
    favorite_flower_item_name: '',
    favorite_edible_item_name: '',
    favorite_concentrate_item_name: '',
    favorite_topical_item_name: ''
  },
  customerRecentHistory: { last_order:null, recent_orders:[] },
  close: () => {},
  push: () => {},
  showCloseButton: false
};


export default Patient;
