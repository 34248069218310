import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { connect } from 'react-redux';
import { Card } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import * as isolocitySelectors from '../../../selectors/integration/isolocitySelectors';

export class DetailsPanel extends React.PureComponent {
  render() {

    const { title = '', onClose, row, dimensions,  Component, ...props } = this.props;
    return (
      <Card>
        <Card.Header>
          <span className='bold'>{title}</span>
          <FaTimes onClick={onClose} className='float-right close-button' />
        </Card.Header>
        <Card.Body>
          <Component row={row} dimensions={dimensions} editable={false} {...props} />
        </Card.Body>
      </Card>
    );
  }
}

DetailsPanel.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
  dimensions: PropTypes.object.isRequired,
  Component: PropTypes.oneOfType([PropTypes.func.isRequired, PropTypes.node.isRequired]).isRequired
};

export default connect((state, ownProps) => ({
  isolocityTestResult: isolocitySelectors.findLabResultsByLabId(state, get(ownProps, 'row.lab_results_id'))
}))(DetailsPanel);
