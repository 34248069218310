import React from 'react';
import {FaDollarSign} from 'react-icons/fa';

const PriceColumn = (cell) => {
  return (
    <div className='price-column'><FaDollarSign/><span>{cell}</span></div>
  );
};

export default PriceColumn;
