import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { Col, Row } from 'react-bootstrap';
import PlantComplianceOnhandSettingsBlock from '../../plant-compliance/PlantComplianceOnhandSettingsBlock';

const options = [
  { text: I18n.t('cultivation.complianceSettings.warn'), value: 'warn' },
  { text: I18n.t('cultivation.complianceSettings.block'), value: 'block' }
];

const ModalitySettings = ({ fields }) => {
  return (
    <div>
      {fields.map((line, index) => {
        const modality = fields.get(index);
        const fieldNamePrefix = `${line}.setting_value.`;
        return (
          <Row key={index}>
            <Col xs={12}>
              <div style={{ fontWeight: 'bold' }}>
                {I18n.t('common.strain')}: {modality.strain_name}
              </div>
              <div className='alert-info' style={{ padding: '5px', textAlign: 'center', fontWeight: 'bold' }}>
                {I18n.t('cultivation.complianceSettings.zeroHint')}
              </div>
              <table className='table table-striped table-bordered'>
                <thead>
                  <tr>
                    <th>{I18n.t('cultivation.complianceSettings.tableRuleHeading')}</th>
                    <th style={{ width: '25%' }}>{I18n.t('cultivation.complianceSettings.tableValueHeading')}</th>
                  </tr>
                </thead>
                <tbody>
                  <PlantComplianceOnhandSettingsBlock
                    options={options}
                    trackPlantsAsGroup={false}
                    fieldNamePrefix={fieldNamePrefix}
                  />
                </tbody>
              </table>
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

ModalitySettings.propTypes = {
  fields: PropTypes.object,
};

export default ModalitySettings;
