import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, FormGroup, Row} from 'react-bootstrap';
import InternationalDecimalInput from '../../../../common/form/InternationalDecimalInput';
import InternationalCurrencyInput from '../../../../common/form/InternationalCurrencyInput';

const OrderTotals = ({summaryTotals}) => {

  return (
    <FormGroup className='order-totals'>

      <Row>
        <Col className='left-label' md={3} offset={3}>
          Product Total
        </Col>
        <Col xs={2} sm={2} md={6}>
          <InternationalCurrencyInput name='productTotal'
            props={{
              readOnly: true,
              value: summaryTotals.productTotal,
            }}
          />
        </Col>

      </Row>
      <Row>
        <Col className='left-label' md={3} offset={3}>
          {I18n.t('cultivation.transfers.form.partnerDiscount')}
        </Col>
        <Col xs={2} sm={2} md={6}>
          <InternationalDecimalInput
            name='partner_discount'
            fractionPartSize={2}
            props={{
              readOnly: true,
              leftAddon: <span>%</span>
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col className='left-label' md={3} offset={3}>
          Disc. Product Total
        </Col>
        <Col xs={2} sm={2} md={6}>
          <InternationalCurrencyInput name='discountedProductTotal'
            props={{
              readOnly: true,
              value: summaryTotals.discountTotal,
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col className='left-label' md={3} offset={3}>
          {I18n.t('cultivation.transfers.form.exciseTaxDue')}
        </Col>
        <Col xs={2} sm={2} md={6}>
          <InternationalCurrencyInput name='excise_tax_due'
            props={{
              disabled: true,
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col className='left-label' md={3} offset={3}>
          {I18n.t('cultivation.transfers.form.transferFee')}
        </Col>
        <Col xs={2} sm={2} md={6}>
          <InternationalCurrencyInput name='transfer_fee'
            props={{
              readOnly: true,
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col className='left-label' md={3} offset={3}>
          {I18n.t('cultivation.transfers.form.inventoryReceiptTotal')}
        </Col>
        <Col xs={2} sm={2} md={6}>
          <InternationalCurrencyInput name='invoiceTotal'
            props={{
              readOnly: true,
              value: summaryTotals.receiptTotal,
            }}
          />
        </Col>
      </Row>

    </FormGroup>
  );
};

OrderTotals.propTypes = {
  summaryTotals: PropTypes.object.isRequired
};

export default OrderTotals;
