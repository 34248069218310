import React from 'react';
import PropTypes from 'prop-types';
import {
  FormLabel,
  FormGroup,
  InputGroup,
  Form} from 'react-bootstrap';
import {FaAsterisk} from 'react-icons/fa';

const BootstrapFormGroup = (props) => {

  const {
    id, size, label, customLabel, leftAddon, rightAddon, validationState, error,
    warning, helpTextBelow, Control, controlProps, children, isRequired, className, dropdownButton
  } = props;

  return (
    <FormGroup className={`form-input ${className ? className : ''}`} size={size}
               id={id}
               validationState={validationState}>
      {isRequired && !customLabel ? <FaAsterisk style={{ fontSize: '0.5em', verticalAlign: 'top', marginTop: '1px' }} /> : null}
      {label && !customLabel ?
        <FormLabel style={isRequired ? { fontWeight: 'bold' } : {}}>{label}</FormLabel>
        : null}
      {
        customLabel
          ? <FormLabel style={isRequired ? { fontWeight: 'bold' } : {}}>{isRequired ? <FaAsterisk style={{ fontSize: '0.5em', verticalAlign: 'top', marginTop: '1px' }} />
          : null} {customLabel}</FormLabel>
          : null
      }
      <InputGroup>
        {leftAddon && <InputGroup.Prepend><InputGroup.Text>{leftAddon}</InputGroup.Text></InputGroup.Prepend>}
        <Control {...controlProps} />
        {dropdownButton ? dropdownButton : null}
        {rightAddon && <InputGroup.Append>{rightAddon}</InputGroup.Append>}
      </InputGroup>
      {error ?
        <Form.Text>{error}</Form.Text> : null}
      {warning ?
        <Form.Text>{warning}</Form.Text> : null}
      {helpTextBelow &&
        <Form.Text>{helpTextBelow}</Form.Text>
      }
      {children}
    </FormGroup>
  );
};

BootstrapFormGroup.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.oneOf(['lg', 'sm', 'large', 'small']),
  validationState: PropTypes.oneOf(['error', 'warning']),
  warning:  PropTypes.string,
  children: PropTypes.node,
  Control: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.node
  ]).isRequired,
  controlProps: PropTypes.object.isRequired,
  isRequired: PropTypes.bool,
  leftAddon: PropTypes.node,
  rightAddon: PropTypes.node,
  dropdownButton: PropTypes.node,
  customLabel: PropTypes.object,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  helpTextBelow: PropTypes.string
};

export default BootstrapFormGroup;
