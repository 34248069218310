import React from 'react';
import PropTypes from 'prop-types';
import {reduxForm, Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {ListGroupItem} from 'react-bootstrap';
import ReactSelectInput from '../common/form/ReactSelectInput';

export const LocationRegisterForm = ({handleSubmit, reset, submitting, pristine, handleLocationChange, handleRegisterChange, locations, registers, currentLocation, currentRegister}) => {
  return (
    <form className='plant-form' onSubmit={handleSubmit}>
      <ListGroupItem>
        {locations.length ? <Field name='location' component={ReactSelectInput} props={{
          label: I18n.t('plants.form.selectLocation'),
          options: locations,
          onChange: handleLocationChange,
          value: currentLocation,
          textKey: 'name',
          valueKey: 'id',
          placeholder: I18n.t('common.form.selectPlaceholder')
        }}/> : <span>{I18n.t('noActiveSalesLocations')}</span>}
    </ListGroupItem>
      <ListGroupItem>
        {registers.length ? <Field name='register' component={ReactSelectInput} props={{
          label: I18n.t('plants.form.selectRegister'),
          options: registers,
          onChange: handleRegisterChange,
          value: currentRegister,
          textKey: 'name',
          valueKey: 'id',
          placeholder: I18n.t('common.form.selectPlaceholder')
        }}/> : <span>{I18n.t('noActiveRegisters')}</span>}
    </ListGroupItem>
    </form>
  );
};

LocationRegisterForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  handleLocationChange: PropTypes.func.isRequired,
  handleRegisterChange: PropTypes.func.isRequired,
  locations: PropTypes.array.isRequired,
  registers: PropTypes.array.isRequired,
  currentLocation: PropTypes.number,
  currentRegister: PropTypes.number
};

export default reduxForm({
  form: 'location-register'
})(LocationRegisterForm);
