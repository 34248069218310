import {I18n} from 'react-redux-i18n';
import {requiredFieldValidation, minValidation} from '../../common/form/redux-form/validations';


const validate = (values, props) => {
  const {isBiotrack, isMetrc, isWaLeaf} = props.integrationState || {};
  const prefix = isBiotrack ? 'biotrack' : isMetrc ? 'metrc' : 'leaf';
  const errors = {};
  errors.items = values.items.map(item => {
    const error = {};
    
    error.integration_adjustment_reason = isBiotrack || isMetrc || isWaLeaf ? requiredFieldValidation(item.integration_adjustment_reason, `packages.form.${prefix}AdjustmentReason`) : null;

    error.received_by_user_id = isWaLeaf ? requiredFieldValidation(item.received_by_user_id, 'packages.form.receivedBy') : null;
    error.transacted_qty = requiredFieldValidation(item.transacted_qty, 'cultivation.finishedProduct.form.quantity')
      || minValidation(item.transacted_qty, 'cultivation.finishedProduct.form.quantity', 0, true);
    return error;
  });
  if (!values.items.length) {
    errors.items._error = I18n.t('cultivation.finishedProduct.form.noPackagesSelected');
  }
  return errors;
};

export default validate;
