import get from 'lodash.get';
import {createSelector} from 'reselect';
import {getCategories} from '../../selectors/categorySelectors';
import {eqFields, paymentsAvailable, metrcOhEqFields} from '../../constants/salesSettings';
import getSalesCompliance from '../compliance/sales/getSalesCompliance';
import {isHypurIntegrator, isMxMerchantIntegrator, isAeropayIntegratedIntegrator, isPosabitIntegrator, isAlleavesIntegrator} from '../integration/thirdPartyIntegrationSelectors';

const getAt = get;

export const getPayload = (formSettings, initialSettings) => {

  //@TODO: Tighten this up... scope and scope id are superfluous and a map can handle the rest for now... but there is a rewrite
  // coming for sales limits... so stuck a pin in it for now.
  const paymentsAccepted = [];
  for(const prop in formSettings) {
    // Only allow saving payment types that exist
    if(paymentsAvailable.find((payment) => payment.value === prop.replace('payment_', ''))) {
      if (prop.indexOf('payment_') !== -1 && formSettings[prop]) paymentsAccepted.push(prop.replace('payment_', ''));
    }
  }
  //if the auto expired is true set the setBuffer, if not send null
  const setBuffer = formSettings.autoExpired == 1 ? formSettings.setBuffer : null;
  const settings = {
    settings: [
      {
        setting_key: 'order_buffer',
        scope: 'organization',
        scope_id:1,
        value: {
          interval: setBuffer,
          time_period: 'days'
        }
      },
      {
        setting_key: 'order_first_in_first_out',
        scope: 'organization',
        scope_id:1,
        value: formSettings.sellFIFO
      },
      {
        setting_key: 'order_delivery_of_product',
        scope: 'organization',
        scope_id:1,
        value: formSettings.allowDelivery
      },
      {
        setting_key: 'order_auto_inactivation',
        scope: 'organization',
        scope_id:1,
        value: formSettings.autoExpired
      },
      {
        setting_key: 'order_upload_of_docs',
        scope: 'organization',
        scope_id:1,
        value: formSettings.allowUpload
      },
      {
        setting_key: 'order_inactive_after',
        scope: 'organization',
        scope_id:1,
        value: {
          interval: formSettings.inactiveAfter,
          time_period: 'days'
        }
      },
      {
        setting_key: 'order_select_payment_options',
        scope: 'organization',
        scope_id:1,
        value: {
          options: paymentsAccepted,
          other_label: formSettings.payment_type_other_label,
        }
      },
      {
        setting_key: 'order_print_labels_and_receipts_without_payment',
        scope: 'organization',
        scope_id:1,
        value: formSettings.printWithoutPayment,
      },
      {
        setting_key: 'order_enable_reservation_API',
        scope: 'organization',
        scope_id:1,
        value: formSettings.enableReservationsAPI,
      },
      {
        setting_key: 'order_disable_limits_on_external_system_error',
        scope: 'organization',
        scope_id:1,
        value: formSettings.order_disable_limits_on_external_system_error,
      },
      {
        setting_key: 'order_receipt_footer_text',
        scope: 'organization',
        scope_id:1,
        value: (typeof formSettings.standardReceiptFooterText === 'string')
          ? (formSettings.standardReceiptFooterText.trim() !== '') ? formSettings.standardReceiptFooterText.trim() : ''
          : ''
      },
      {
        setting_key: 'order_allow_sales_with_temp_paperwork',
        scope: 'organization',
        scope_id: 1,
        value: formSettings.order_allow_sales_with_temp_paperwork,
      },
      {
        setting_key: 'order_allow_leafPA_sales_at_will',
        scope: 'organization',
        scope_id: 1,
        value: formSettings.order_allow_leafPA_sales_at_will,
      },
      {
        setting_key: 'order_packaging_workflow',
        scope: 'organization',
        scope_id: 1,
        value: formSettings.order_packaging_workflow,
      },
      {
        setting_key: 'order_allow_sales_on_id_expiration',
        scope: 'organization',
        scope_id: 1,
        value: formSettings.order_allow_sales_on_id_expiration,
      },
      {
        setting_key: 'order_allow_api_sales_on_medical_patient',
        scope: 'organization',
        scope_id: 1,
        value: formSettings.order_allow_api_sales_on_medical_patient,
      },
      {
        setting_key: 'lock_pre_checkin_online_order',
        scope: 'organization',
        scope_id: 1,
        value: formSettings.lock_pre_checkin_online_order,
      },
      {
        setting_key: 'order_recreational_sales_age_limit',
        scope: 'organization',
        scope_id: 1,
        value: formSettings.order_recreational_sales_age_limit,
      },
      {
        setting_key: 'order_allow_anonymous',
        scope: 'organization',
        scope_id: 1,
        value: formSettings.order_allow_anonymous
      },
      {
        setting_key: 'order_birthdate_for_anonymous_orders',
        scope: 'organization',
        scope_id: 1,
        value: formSettings.order_birthdate_for_anonymous_orders
      },
      {
        setting_key: 'order_allow_expired_dl_on_rec',
        scope: 'organization',
        scope_id: 1,
        value: formSettings.order_allow_expired_dl_on_rec
      },
      {
        setting_key: 'order_anonymous_customer_types',
        scope: 'organization',
        scope_id: 1,
        value: formSettings.order_anonymous_customer_types.map( type => {
          return get(type,'value',false) ? type.value : type;
        })
      },
      {
        setting_key: 'order_enable_weigh_on_hand',
        scope: 'facility',
        scope_id: formSettings.facilityId,
        value: formSettings.order_enable_weigh_on_hand
      },
      {
        setting_key: 'order_weigh_on_hand_margin_error',
        scope: 'facility',
        scope_id: formSettings.facilityId,
        value: formSettings.order_weigh_on_hand_margin_error
      },
      {
        setting_key: 'order_auto_printing',
        scope: 'facility',
        scope_id: formSettings.facilityId,
        value: {
          is_enabled: Boolean(formSettings.order_auto_printing_is_enabled === '1'),
          print_receipts: Boolean(formSettings.order_auto_printing_receipts),
          print_labels: Boolean(formSettings.order_auto_printing_labels),
          default_label: formSettings.order_auto_printing_default_label,
        }
      }
    ]
  };

  const getEquivalencyRules = (patientType) => {
    return eqFields.reduce((acc, field) => {
      acc.push({
        category_code: field.category_code,
        flower_weight_value: parseFloat(formSettings[`${patientType}_flower_${field.category_code}`]),
        weight_value: formSettings[`${patientType}_weight_${field.category_code}`],
        uom: formSettings[`${patientType}_select_${field.category_code}`],
        category_id: field.category_id,
        subcategory_id: field.subcategory_id
      });
      return acc;
    }, []);
  };

  const getCategoryRules = (patientType) => {
    return Object.keys(formSettings).reduce((acc, key) => {
      if(key.indexOf(patientType) === -1 || key.indexOf('__') === -1) return acc;
      const categoryKey = key.split('__').pop();
      let limit = acc.find((l) => l.sales_limit_category_key === categoryKey);
      if(!limit) {
        limit = {sales_limit_category_key: categoryKey};
        acc.push(limit);
      }
      limit[key.split('__').shift().replace(`${patientType}_`, '')] = formSettings[key];
      return acc;
    }, []);
  };

  const getLabResultsBased = (patientType) => {
    return []; // Empty array is intentional for lab based results; there is no eq or rules config.
  };

  const getInitialMethodConfiguration = (patientType) => {
    return getAt(initialSettings, `order_sales_limits_by_consumer_type.value.${patientType}.method_configuration`, []);
  };

  const getLimitTo = (patientType) => {
    return {
      interval: getAt(formSettings, `${patientType}_restrictSalesLimit`, null),
      time_period:  getAt(formSettings, `${patientType}_restrictSalesType`, null),
      weight_value:  getAt(formSettings, `${patientType}_restrictSalesGrams`, null),
      terminal_illness:  getAt(formSettings, `${patientType}_terminal_illness_limits`, null),
    };
  };

  // Set limits config by patient type
  const patientTypes = ['medical', 'recreational'];
  const getLimitsFunctionMap = {
    equivalency: getEquivalencyRules,
    categories: getCategoryRules,
    disabled: getInitialMethodConfiguration,
    lab_results_based: getLabResultsBased,
    mmus: getCategoryRules
  };
  const limits = patientTypes.reduce((acc, patientType) => {
    const limitMethod = getAt(formSettings, `${patientType}_limit_method`, 'equivalency') || 'equivalency';
    acc[patientType] = {
      limit_to: getLimitTo(patientType),
      sales_limit_method: limitMethod,
      method_configuration: getLimitsFunctionMap[limitMethod](patientType),
      order_physician_info: getAt(formSettings, `${patientType}_order_physician_info`, '0'),
      display_uom: getAt(formSettings, `${patientType}_display_uom`),
    };
    return acc;
  }, {});
  settings.settings.push({
    setting_key: 'order_sales_limits_by_consumer_type',
    value: limits,
  });

  // Register Closing settings
  const register_closing_is_allowed = Boolean(formSettings.register_closing_is_allowed === '1');
  settings.settings.push({
    setting_key: 'register_closing',
    value: {
      is_allowed: register_closing_is_allowed,
      count_by: getAt(formSettings, 'register_closing_count_by', register_closing_is_allowed ? 'employee' : ''),
    }
  });

  // Update scope_id to facility though this is done on back end too... it was here and leaving it to be clear
  settings.settings.forEach((setting) => {
    setting.scope = 'facility';
    setting.scope_id = formSettings.facilityId;
  });

  const metrcOhLimits = metrcOhEqFields.reduce((acc, field) => {
    acc.push({
      category_code: field.category_code,
      flower_weight_value: parseFloat(formSettings[`metrc_oh_equivalency_flower_${field.category_code}`]),
      weight_value: formSettings[`metrc_oh_equivalency_weight_${field.category_code}`],
      uom: formSettings[`metrc_oh_equivalency_select_${field.category_code}`],
    });
    return acc;
  }, []);

  settings.settings.push({
    scope: 'facility',
    scope_id: formSettings.facilityId,
    setting_key: 'order_sales_limit_equivalency_by_oh_metrc_category',
    value: metrcOhLimits,
  });

  return settings;
};

export const getMappedRulesCategories = createSelector([getCategories], (categories) => {
  if(!categories || !Array.isArray(categories)) return {value: {}};
  return {value: categories.reduce((cac, category) => { // Iterate categories to get sales limit category key from subs
    cac = cac.concat(category.subcategories.reduce((sac, sub) => { // Iterate subs and merge to top level array
      if(sub.sales_limit_category_key === null || sac.indexOf(sub.sales_limit_category_key) !== -1) return sac;
      sac.push(sub.sales_limit_category_key);
      return sac;
    }, []).filter((sub) => { // Remove those already in category map
      return cac.indexOf(sub) === -1;
    }));
    return cac;
  }, []).reduce((acc, cat) => { // reduce array result to an object where each element is a property of the object (legacy support... this could be cleaner)
    acc[cat] = cat;
    return acc;
  }, {})};
});


/**
 * Generate initial values for Platform payment types based on saved settings and some
 * third-party integrations
 */
const getInitialValuesForPaymentTypes = createSelector(
  [getSalesCompliance, isHypurIntegrator, isMxMerchantIntegrator, isAeropayIntegratedIntegrator, isPosabitIntegrator, isAlleavesIntegrator],
  (salesComplianceSettings, isHypurEnabled, isMxMerchantEnabled, isAeropayIntegratedIntegrator, isPosabitIntegrator, isAlleavesIntegrator) => {
    const {order_select_payment_options} = salesComplianceSettings;

    if (!order_select_payment_options || order_select_payment_options.value === null) return {};

    const acceptedPaymentTypes = [...order_select_payment_options.value.options];

    if (isAeropayIntegratedIntegrator) acceptedPaymentTypes.push('aeropayintegrated');
    if (isAlleavesIntegrator) acceptedPaymentTypes.push('alleaves');
    if (isPosabitIntegrator) acceptedPaymentTypes.push('posabit');
    if (isHypurEnabled) acceptedPaymentTypes.push('hypur');
    if (isMxMerchantEnabled) acceptedPaymentTypes.push('terminal');

    return acceptedPaymentTypes.reduce((initialValues, paymentType) => {
      initialValues[`payment_${paymentType}`] = 1;
      return initialValues;
    }, {});
  }
);

const getInitialValueForPaymentTypeOtherLabel = createSelector(
  [getSalesCompliance],
  (salesComplianceSettings) => {
    const {order_select_payment_options} = salesComplianceSettings;
    if (order_select_payment_options && order_select_payment_options.value && order_select_payment_options.value.other_label) {
      return order_select_payment_options.value.other_label;
    }
    return null;
  }
);

/**
 * TODO: Migrate everything pertaining to initialValues from the SalesSettingsPage into this selector
 */
export const getInitialValues = createSelector(
  [getInitialValuesForPaymentTypes, getInitialValueForPaymentTypeOtherLabel],
  (paymentTypes, paymentTypeOtherLabel) => {
    return {
      ...paymentTypes,
      payment_type_other_label: paymentTypeOtherLabel
    };
  }
);

export default getPayload;
