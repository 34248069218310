import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Row } from 'react-bootstrap';

const LoginHeader = () => {
  return window.location.hostname.includes('leafdatasystems') ? (
    <Row className='login-header'>
      <h1 className='align-center'>
        <div className='login-logo leaf' />
      </h1>
    </Row>
  ) : (
    <Row className='login-header'>
      <h1 className='align-center'>
        <div className='login-logo' />
        {I18n.t('user.title')}
      </h1>
    </Row>
  );
};

export default LoginHeader;
