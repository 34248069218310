/* eslint-disable react/no-multi-comp */
import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {goBack} from 'react-router-redux';
import { bindActionCreators } from 'redux';
import {I18n} from 'react-redux-i18n';
import FormWrapper from '../common/form/FormWrapper';
import ManifestFormWrapper from './manifest/ManifestFormWrapper';

export class GenerateManifestPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
  }

  componentWillMount() {
  }

  componentDidMount() {
  }

  render () {
    return (
      <div>
        <FormWrapper title = {I18n.t('generateManifest.title')} goBack={this.props.actions.goBack}>
          <ManifestFormWrapper />
        </FormWrapper>
      </div>
    );
  }
}

GenerateManifestPage.propTypes = {
  actions: PropTypes.shape({
    goBack: PropTypes.func.isRequired
  })
};

function mapStateToProps(state, ownProps){
  return {};
}

function mapDispatchToProps(dispatch) {

  const actions = {goBack};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (GenerateManifestPage);
