import {I18n} from 'react-redux-i18n';
import moment from 'moment';
import {requiredFieldValidation} from '../../common/form/redux-form/validations';

export const validate = (values) => {
  const errors = {};
  if (values.dateReceived && !moment(values.dateReceived, 'MM/DD/YYYY').isValid()) {
    errors.dateReceived = I18n.t('tracking.receiveTrackingIds.form.dateReceivedError');
  }

  if(values.starting_tag){
    const tailPartRegExp = /\d+$/;
    if(!tailPartRegExp.exec(values.starting_tag)){
      errors.starting_tag = I18n.t('tracking.receiveTrackingIds.form.startingTagError');
    }
    if(values.starting_tag.length < 24){
      errors.starting_tag = I18n.t('tracking.receiveTrackingIds.form.startingTagLengthError');
    }
  }

  errors.qty_received = requiredFieldValidation(values.qty_received, 'tracking.receiveTrackingIds.form.quantityReceived');
  errors.type = requiredFieldValidation(values.type, 'tracking.receiveTrackingIds.form.type');
  errors.received_at = requiredFieldValidation(values.received_at, 'tracking.receiveTrackingIds.form.DateReceived');
  errors.cost = requiredFieldValidation(values.cost, 'tracking.receiveTrackingIds.form.cost');
  return errors;
};

export default validate;
