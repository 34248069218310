import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import map from 'lodash.map';
import get from 'lodash.get';
import {Field, FieldArray, formValueSelector} from 'redux-form';
import {connect} from 'react-redux';
import {Button, Col, Row} from 'react-bootstrap';
import {FaTrash} from 'react-icons/fa';
import MultiselectInput from '../../../common/form/MultiselectInput';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import HoverTooltip from '../../../common/HoverTooltip';
import {PRODUCT_FORM} from '../../../../constants/forms';
import {
  getConsumerGroupsByPricingClass,
  getPricingClassForFacilities
} from '../../../../selectors/pricingClassSelectors';
import ConsumerGroupsTable from './ConsumerGroupsTable';
import InternationalCurrencyInput from '../../../common/form/InternationalCurrencyInput';
import InlineCheckBox from '../../../common/form/InlineCheckBox';


const UnitPriceAttributes = (props) => {
  const {
    fields,
    fieldName,
    fieldLabel,
    fieldIndex,
    isWholesale,
    statusOptions,
    consumerGroups,
    pricingClasses,
    facilityOptions,
    isMassModifyForm,
    disablePriceBlock,
    isOrganizationMode,
    fieldCanModifiedMap,
  } = props;

  const keyForMap = fieldName.replace(/(\[\d+])/, '');
  return (
    <Col xs={12}>
      <Row>
        <Col sm={6} md={3} style={{paddingTop: '22px'}}>
          {isMassModifyForm
            ? <Field name='is_non_taxable' component={ReactSelectInput}
              props={{
                label: I18n.t('products.form.active'),
                options: statusOptions,
                rightAddon: fieldCanModifiedMap[`${keyForMap}.is_non_taxable`].length ?
                  <HoverTooltip
                    id={`${fieldName}_tooltip`}
                    text={I18n.t('products.massModify.errors.diff_categories_help', {
                      field: I18n.t(fieldLabel),
                      categories: fieldCanModifiedMap[`${keyForMap}.is_non_taxable`].join(' ,')
                    })}
                  /> : null
              }}/>
          : <Field name={`${fieldName}.is_non_taxable`} component={InlineCheckBox}
            props={{
              label: I18n.t('products.form.nonTaxableProduct'),
              groupClassName: 'inline-checkbox',
            }}/>}
        </Col>
        {isOrganizationMode ?
          null :
          <Col sm={6} md={4}>
            <Row>
              <Col xs={10}>
                <Field name={`${fieldName}.facility_ids`} component={MultiselectInput} props={{
                  label: I18n.t('taxes.form.applicableFacilities'),
                  options: facilityOptions,
                  isRequired: !isMassModifyForm,
                  allOption: true,
                  rightAddon: isMassModifyForm && fieldCanModifiedMap[`${keyForMap}.facility_ids`].length ?
                    <HoverTooltip
                      id={`${fieldName}_tooltip`}
                      text={I18n.t('products.massModify.errors.diff_categories_help', {
                        field: I18n.t('taxes.form.applicableFacilities'),
                        categories: fieldCanModifiedMap[`${keyForMap}.facility_ids`].join(' ,')
                      })}
                    /> : null
                }}/>
              </Col>
              <Col xs={2} style={{paddingTop: '28px'}}>
                <Button variant='danger' size='sm' onClick={() => fields.remove(fieldIndex)}>
                  <FaTrash/>
                </Button>
              </Col>
            </Row>
          </Col>
        }
        <Col xs={6} md={4}>
          <Row>
            <Col sm={6} md={12}>
              <InternationalCurrencyInput name={`${fieldName}.default_price`}
                props={{
                  fractionPartSize: 2,
                  placeholder: '0.00',
                  label: I18n.t(...fieldLabel),
                  isRequired: !isMassModifyForm && isOrganizationMode,
                  rightAddon: isMassModifyForm && fieldCanModifiedMap[`${keyForMap}.default_price`].length ?
                    <HoverTooltip
                      id={`${fieldName}_tooltip`}
                      text={I18n.t('products.massModify.errors.diff_categories_help', {
                        field: I18n.t(...fieldLabel),
                        categories: fieldCanModifiedMap[`${keyForMap}.default_price`].join(' ,')
                      })}
                    /> : null
                }}
              />
            </Col>
          </Row>
          {(!isWholesale) && (
            <Row>
              <Col xs={12}>
                <Field name={`${fieldName}.pricing_class_id`} component={ReactSelectInput} props={{
                  label: I18n.t('products.form.pricingClass'),
                  options: pricingClasses,
                  textKey: 'name',
                  valueKey: 'id',
                  disabled: disablePriceBlock,
                  rightAddon: isMassModifyForm && fieldCanModifiedMap[`${keyForMap}.pricing_class_id`].length ?
                    <HoverTooltip
                      id={`${fieldName}_tooltip`}
                      text={I18n.t('products.massModify.errors.diff_categories_help', {
                        field: 'Pricing Class',
                        categories: fieldCanModifiedMap[`${keyForMap}.pricing_class_id`].join(' ,')
                      })}
                    /> : null
                }}/>
              </Col>
              {isMassModifyForm ?
                <Col sm={6} md={3}>
                  <Field name='inherit_pricing_class_updates' component={ReactSelectInput} props={{
                    label: I18n.t('products.form.inheritClassChanges'),
                    options: statusOptions,
                    disabled: disablePriceBlock,
                    rightAddon: isMassModifyForm && fieldCanModifiedMap[`inherit_pricing_class_updates`].length ?
                      <HoverTooltip
                        id={`inherit_pricing_class_updates_tooltip`}
                        text={I18n.t('products.massModify.errors.diff_categories_help', {
                          field: 'Inherit Pricing Class Updates',
                          categories: fieldCanModifiedMap[`inherit_pricing_class_updates`].join(' ,')
                        })}
                      /> : null
                  }}/>
                </Col>
                :
                <Col xs={12}>
                  <Field name={`${fieldName}.inherit_pricing_class_updates`} component={InlineCheckBox} props={{
                    label: I18n.t('products.form.inheritClassChanges'),
                    groupClassName: 'inline-checkbox',
                  }}/>
                </Col>}
            </Row>
          )}

          <FieldArray
            name={`${fieldName}.pricing_class_consumer_groups`}
            component={ConsumerGroupsTable}
            props={{
              rows: consumerGroups,
            }}
          />
        </Col>
      </Row>
    </Col>
  );
};

UnitPriceAttributes.propTypes = {
  isOrganizationMode: PropTypes.bool.isRequired,
  fields: PropTypes.shape({
    remove: PropTypes.func,
  }),
  fieldName: PropTypes.string.isRequired,
  fieldLabel: PropTypes.array.isRequired,
  fieldIndex: PropTypes.number,
  isWholesale: PropTypes.bool,
  facilityOptions: PropTypes.array,
  pricingClasses: PropTypes.array,
  consumerGroups: PropTypes.array.isRequired,
  disablePriceBlock: PropTypes.bool.isRequired,
  statusOptions: PropTypes.array.isRequired,
  isMassModifyForm: PropTypes.bool.isRequired,
  fieldCanModifiedMap: PropTypes.object,
  formName: PropTypes.string
};

export default connect((state, ownProps) => {
  const selector = formValueSelector(ownProps.formName ? ownProps.formName : PRODUCT_FORM);
  const pricingClassId = get(selector(state, ownProps.fieldName), 'pricing_class_id');
  const facilityList = get(selector(state, ownProps.fieldName), 'facility_ids', []);
  const applicableFacilitiesIds = (typeof facilityList[0] === 'object') ? map(facilityList, 'value') : facilityList;

  return {
    pricingClasses: getPricingClassForFacilities(state)(applicableFacilitiesIds),
    consumerGroups:  getConsumerGroupsByPricingClass(state)(pricingClassId),
  };
})(UnitPriceAttributes);
