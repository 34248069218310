
import * as types from '../constants/actionTypes';
import initialState from '../constants/initialState';

export default function menusReducer (state = initialState.menus, action){
  switch (action.type) {
  case types.TOGGLE_MAIN_NAV_MENU:
    return Object.assign({}, state, {mainNavMenuOpen: !state.mainNavMenuOpen});
  case types.CLOSE_MAIN_NAV_MENU:
    return Object.assign({}, state, {mainNavMenuOpen: false});
  case types.TOGGLE_APP_BAR:
    return Object.assign({}, state, {appBarOpen: !state.appBarOpen});
  case types.TOGGLE_USER_OPTS_MENU:
    return Object.assign({}, state, {userOptsMenuOpen: !state.userOptsMenuOpen,
      custDetailsOpen: false });
  case types.OPEN_USER_OPTS_MENU:
    return Object.assign({}, state, {userOptsMenuOpen: true,
      custDetailsOpen: false });
  case types.TOGGLE_FACILITY_MENU:
    return Object.assign({}, state, {facilityMenuOpen: !state.facilityMenuOpen});
  case types.TOGGLE_CUST_DETAILS:
    return Object.assign({}, state, {custDetailsOpen: !state.custDetailsOpen,
      userOptsMenuOpen: false});
  case types.CLOSE_CUST_DETAILS:
    return Object.assign({}, state, {custDetailsOpen: false});
  case types.TOGGLE_CART_DETAILS:
    return Object.assign({}, state, {cartDetailsOpen: !state.cartDetailsOpen,
      userOptsMenuOpen: false});
  case types.CLOSE_CART_DETAILS:
    return Object.assign({}, state, {cartDetailsOpen: false});
  case types.TOGGLE_LIMITS_DETAILS:
    return Object.assign({}, state, {limitsDetailsOpen: !state.limitsDetailsOpen});
  default:
    return state;
  }
}
