import React from 'react';
import PropTypes from 'prop-types';
import {getFormValues} from 'redux-form';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import moment from 'moment-timezone';

import InventoryTransactionsSearchForm from './InventoryTransactionsSearchForm';
import TabbedFormWrapper from '../../../common/form/TabbedFormWrapper';
import TablePageWrapper from '../../../common/grid/TablePageWrapper';
import * as dataNames from '../../../../constants/dataNames';
import {inventoryTransactionsReport} from '../../../../constants/dataNames';
import {columnVisibilitySettings} from '../../../../constants/itemNames';
import {INVENTORY_TRANSACTIONS_REPORT} from '../../../../constants/forms';
import {getEnabledTabs} from '../../../../actions/reportActions';
import {unsetData} from '../../../../actions/dataActions';
import {getPaginatedData, getUnpaginatedData, getDataByPost} from '../../../../actions/apiActions';
import getSalesReportTabs from '../common/tabDefinitions';
import {inventoryTransactionReportColumns, inventoryTransactionReportTabs} from './tableDefinitions';
import {
  getCategoryOptions,
  getInventoryTransactionsReport,
  getSubcategoryOptions
} from '../../../../selectors/inventoryTransactionReportSelectors';
import {getTotalResults} from '../../../../selectors/paginationSelectors';


class InventoryTransactionsReportPage extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      salesReportTabs: [],
      isLoading: true,
      searchTriggered: false,
      page: null
    };

    this.switchSalesReportTab = this.switchSalesReportTab.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.pageChange = this.pageChange.bind(this);
  }


  componentWillMount() {
    const {getEnabledTabs, getUnpaginatedData} = this.props;

    this.setState({salesReportTabs: getEnabledTabs(getSalesReportTabs)});
    getUnpaginatedData('/api/categories?is_only_active=1', dataNames.categories);
    getUnpaginatedData('/api/subcategories', dataNames.subcategories);
  }


  switchSalesReportTab(selection) {
    const activeSalesReportTab = selection;
    const tab = this.state.salesReportTabs.find(tab => tab.eventKey === activeSalesReportTab) || this.state.salesReportTabs[0];
    this.props.push(tab.path);
  }


  onSubmit(formValues) {
    const {columnVisibilitySettings} = this.props;
    const perPage = columnVisibilitySettings.per_page || 20;

    this.fetchReport(formValues, 1, perPage);
    this.setState({searchTriggered: true});
  }

  pageChange(sort, query, size, start) {
    // Table component triggers pageChange on load. Ignore until the search has been explicitly triggered by the user
    if (!this.state.searchTriggered) return;

    const {formValues} = this.props;
    const pageNumber = start / size + 1;

    this.fetchReport(formValues, pageNumber, size);
  }


  fetchReport(formValues, page, perPage) {
    this.setState({page});
    const {unsetData, getDataByPost} = this.props;

    unsetData(dataNames.inventoryTransactionsReport);
    this.setState({isLoading: true});

    const eventDate = moment(formValues.event_date);
    const dateFormat = 'YYYY-MM-DD';

    const payload = {
      ...formValues,
      event_date_start: eventDate.format(dateFormat),
      event_date_end: eventDate.add(1, 'day').format(dateFormat),
    };

    const params = {
      paginate: 1,
      page: page,
      per_page: perPage
    };

    getDataByPost(
      '/api/bi_reports/inventory_transactions',
      payload,
      dataNames.inventoryTransactionsReport,
      null,
      params,
      (response) => {
        this.setState({
          isLoading: false,
        });
      });
  }


  render() {
    const {page} = this.state;
    const {categories, subcategories, inventoryTransactionsReport, dataTotalSize} = this.props;

    return (
      <div>
        <TabbedFormWrapper
          title={I18n.t('retail.inventoryTransactionsReport.title')}
          tabs={this.state.salesReportTabs}
          activeTab='inventoryTransactionsReport'
          switchTab={this.switchSalesReportTab}
        >
          <InventoryTransactionsSearchForm
            submitSearch={this.onSubmit}
            categories={categories}
            subcategories={subcategories}
          />
          <TablePageWrapper
            activeTab='default'
            columns={inventoryTransactionReportColumns}
            data={inventoryTransactionsReport}
            dataTotalSize={dataTotalSize}
            settingKey='inventoryTransactionsReport'
            tabs={inventoryTransactionReportTabs}
            hideScanSearch={true}
            hideSearch={true}
            defaultHiddenColumns={['startingLevel', 'newLevel', 'itemIsActive', 'item_id', 'lot_number', 'section_name', 'transaction_type', 'adjustment_reason', 'notes', 'dominance', 'strainName', 'phenotype_name', 'unit_cost']}
            external={true}
            externalSearch={this.pageChange}
            page={page}
            start={page === 1 ? 0 : undefined}
          />
        </TabbedFormWrapper>
      </div>
    );
  }
}

InventoryTransactionsReportPage.propTypes = {
  categories: PropTypes.array.isRequired,
  subcategories: PropTypes.array.isRequired,
  inventoryTransactionsReport: PropTypes.array.isRequired,
  dataTotalSize: PropTypes.number.isRequired,
  columnVisibilitySettings: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  unsetData: PropTypes.func.isRequired,
  getEnabledTabs: PropTypes.func.isRequired,
  getPaginatedData: PropTypes.func.isRequired,
  getUnpaginatedData: PropTypes.func.isRequired,
  getDataByPost: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  categories: getCategoryOptions(state),
  subcategories: getSubcategoryOptions(state),
  inventoryTransactionsReport: getInventoryTransactionsReport(state),
  dataTotalSize: getTotalResults(state, { name: inventoryTransactionsReport }),
  columnVisibilitySettings: state[columnVisibilitySettings],
  formValues: getFormValues(INVENTORY_TRANSACTIONS_REPORT)(state) || {}
});

const mapDispatchToProps = {
  push,
  unsetData,
  getEnabledTabs,
  getPaginatedData,
  getUnpaginatedData,
  getDataByPost
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryTransactionsReportPage);
