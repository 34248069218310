import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {Row, Col} from 'react-bootstrap';
import {Field, getFormValues} from 'redux-form';
import * as dataNames from '../../../../../../constants/dataNames';
import SubmitSection from '../../../../../common/form/SubmitSection';
import InternationalDecimalInput from '../../../../../common/form/InternationalDecimalInput';
import TextInput from '../../../../../common/form/TextInput';
import MultiselectInput from '../../../../../common/form/MultiselectInput';
import {isFeatureEnabled} from '../../../../../../selectors/featureToggles';
import CheckboxRow from './CheckboxRow';

const RewardForm  = (props) => {

  const {invalid, submitting, pristine} = props;

  const settings = {
    actionSettings: {
      submit: {
        submitting,
        invalid,
        pristine,
        text: I18n.t('common.form.save')
      }
    }
  };

  const fieldsLang = 'retail.rewards.reward.fields';
  const uiLang = 'retail.rewards.reward.ui';
  const vendors = props.vendors.map((vendor) => { return { id: vendor.id, name: vendor.name }; });


  return(
    <form onSubmit={props.handleSubmit} noValidate={true}>
      <Row>
        <Col md={6}>
          <Row>
            <Col md={12}>
              {I18n.t(`${uiLang}.definitionTitle`)}
            </Col>
          </Row>
          <Row>
            <Col xs={6} sm={6} md={6}>
              <Field
                name='name'
                component={TextInput}
                props={{
                  label: I18n.t(`${fieldsLang}.name`),
                  isRequired: true,
                  placeholder: 'Name of reward.'
                }}
              >
                <div className='text-muted' style={{fontSize: 'smaller', paddingTop: '5px'}}>
                  {I18n.t(`${uiLang}.rewardNameHint`)}
                </div>
              </Field>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <InternationalDecimalInput
                name='points_required'
                fractionPartSize={0}
                props={{
                  label: I18n.t(`${fieldsLang}.points_required`),
                  isRequired: true
                }}
              >
              <div className='text-muted' style={{fontSize: 'smaller', paddingTop: '5px'}}>
                {I18n.t(`${uiLang}.pointsToPurchaseHint`, {field: I18n.t(`${fieldsLang}.points_required`)})}
              </div>
              </InternationalDecimalInput>
            </Col>
          </Row>
          <Row>
            <Col xs={6} sm={6} md={6}>
              <div className='form-group'>
                <label style={{width:'100%'}}>{I18n.t(`${fieldsLang}.reward_type`)}</label>
                <div>
                  <label>
                    <Field name='reward_type' component='input' type='radio' value='value'/>
                    &nbsp;{I18n.t(`${fieldsLang}.reward_type_value`)}
                  </label>
                </div>
                <div>
                  <label>
                    <Field name='reward_type' component='input' type='radio' value='percent'/>
                    &nbsp;{I18n.t(`${fieldsLang}.reward_type_percent`)}
                  </label>
                </div>
              </div>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <InternationalDecimalInput
                name='reward_amount'
                fractionPartSize={2}
                props={{
                  label: I18n.t(`${fieldsLang}.reward_amount`),
                  isRequired: true
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6} sm={6} md={6}>
              <div className='form-group'>
                <label style={{width: '100%'}}>{I18n.t(`${fieldsLang}.reward_applies_to`)}</label>
                <div>
                  <div>
                    <label>
                      <Field component='input' type='radio' name='reward_applies_to' value='line' />
                       &nbsp;{I18n.t(`${fieldsLang}.reward_applies_to_line`)}
                    </label>
                  </div>
                  <div>
                    <label>
                      <Field component='input' type='radio' name='reward_applies_to' value='order' />
                     &nbsp;{I18n.t(`${fieldsLang}.reward_applies_to_order`)}
                    </label>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={6}>
          <Row>
            <Col md={12}>
              {I18n.t(`${uiLang}.restrictTitle`)}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
            <div style={{border: '1px solid #ddd', padding: '6px'}}>
              <div style={{paddingTop: '5px', cursor: 'pointer'}}>
                <div><strong>Customer Group</strong></div>
                <Field name='consumer_group_ids'
                  component={CheckboxRow}
                  props={{
                    collection: props.customerGroups,
                    dataName: '',
                    nameField: 'group_name',
                  }}
                />
              </div>
              <div style={{paddingTop: '5px', cursor: 'pointer'}}>
                <div><strong>Product Vendors</strong></div>
                <Field
                  name='vendor_ids'
                  component={MultiselectInput}
                  props={{
                    options: vendors,
                    valueKey: 'id',
                    textKey: 'name',
                    allOption: false,
                    defaultValue: [],
                    placeholder: 'All Vendors',
                  }}
                />
              </div>
              <div style={{paddingTop: '5px', cursor: 'pointer'}}>
                <div><strong>Products</strong></div>
                <Field
                  name='item_master_ids'
                  component={MultiselectInput}
                  props={{
                    options: props.products,
                    valueKey: 'id',
                    textKey: 'name',
                    allOption: false,
                    defaultValue: [],
                    placeholder: 'All Products',
                  }}
                />
              </div>
              {props.isProductListsFeatureEnabled &&
                <div style={{paddingTop: '5px', cursor: 'pointer'}}>
                  <div><strong>Product Lists</strong></div>
                  <Field
                    name='product_list_ids'
                    component={MultiselectInput}
                    props={{
                      options: props.productLists,
                      valueKey: 'id',
                      textKey: 'name',
                      defaultValue: [],
                      placeholder: 'All Products',
                    }}
                  />
                </div>
              }
            </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <div style={{height: '20px', clear: 'both'}} />
      <SubmitSection settings={settings}/>
    </form>
  );
};

RewardForm.defaultProps = {
  showModal: false,
};

RewardForm.propTypes = {
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  customerGroups: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  productLists: PropTypes.array,
  vendors: PropTypes.array.isRequired,
  showModal: PropTypes.bool.isRequired, // Injected at redux form wrapper
  modalCollection: PropTypes.string, // Injected at redux form wrapper
  isProductListsFeatureEnabled: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    products: state[dataNames.products],
    productLists: state[dataNames.productLists],
    categories: state[dataNames.categories],
    vendors: state[dataNames.partners],
    customerGroups: state[dataNames.customerGroups],
    formValues: getFormValues('rewardForm')(state),
    isProductListsFeatureEnabled: isFeatureEnabled(state)('feature_product_lists'),
  };
}

export default connect(mapStateToProps)(RewardForm);
