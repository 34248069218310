import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack, push} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import {Row, Col, Button} from 'react-bootstrap';
import moment from 'moment';
import * as apiActions from '../../../../actions/apiActions';
import * as dataNames from '../../../../constants/dataNames';
import getSalesReportTabs from '../common/tabDefinitions';
import TabbedFormWrapper from '../../../common/form/TabbedFormWrapper';
import * as dataActions from '../../../../actions/dataActions';
import {dataUpdateSetAvailable, getDataUpdateAvailable} from '../../../../selectors/dataUpdateSelectors';
import {
  getExportReportMonthsByYearSelector,
  getExportReportMostRecentMonthYearSelector} from '../../../../selectors/salesReportSelectors';
import {getEnabledTabs} from '../../../../actions/reportActions';
import ReactSelectInput from '../../../common/form/ReactSelectInput';

export class MonthlyReportExport extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      salesReportTabs: [],
      activeTab: null,
      isOpenTab: null,
      isWarnings: false,
      isLoading: true,
      date: new Date(),
      activeSalesReportTab: 'canadaExport',
      selectedMonth: null,
      selectedYear: null,
      monthOptions: [],
      yearOptions: [],
      inputError: false,
      initialized: false,
    };

    this.switchTab = this.switchTab.bind(this);
    this.switchSalesReportTab = this.switchSalesReportTab.bind(this);
    this.setActiveTabAndFetch = this.setActiveTabAndFetch.bind(this);
    this.setFormState = this.setFormState.bind(this);
    this.getExportCsv = this.getExportCsv.bind(this);
  }

  componentDidMount() {
    const params = {
      select_columns: [
        'id',
        'data_reported_from',
        'data_reported_to',
        'facility_id',
        'report_key',
        'created_at',
        'updated_at'
      ]
    };
    const messages = {failed: 'retail.salesReport.failed'};
    this.props.actions.getUnpaginatedData('/api/reporting/date_range_stats', dataNames.reportsForExport, messages, params)
      .then(() => {
        this.setState({initialized: true});
      });
    this.setState({salesReportTabs: this.props.actions.getEnabledTabs(getSalesReportTabs)});
  }

  componentWillReceiveProps(nextProps){
    if (
      nextProps.status !== this.state.activeTab ||
      dataUpdateSetAvailable(nextProps.dataUpdateAvailable, this.props.dataUpdateAvailable)
    ) {
      this.setActiveTabAndFetch(nextProps.status);
    }
    this.setFormState(nextProps);
  }

  getExportCsv(){
    const report = this.props.reportsForExport.find((report) => {
      const reportDate = moment(report.data_reported_from);
      return reportDate.month() === this.state.selectedMonth && reportDate.year() === this.state.selectedYear;
    });
    if (!report) {
      this.setState({
        inputError: true,
      });
      return false;
    }
    this.props.actions.getFile(
      `/api/reporting/date_range_stats/${report.id}`,
      'date_range_stats.csv',
      {failed: 'retail.exportReports.reportError'},
      {return_csv: true}
    );
  }

  /**
   * Sets initial state of selected month and year and adjusts the month/year drop downs as appropriate for
   * enabled disabled months / years.
   */
  setFormState(props = false){
    if(!props){
      props = this.props;
    }

    const {monthsByYear, mostRecentMonthYear} = props;
    const {selectedYear, selectedMonth} = this.state;

    const getMonthOptions = (selectedYear) => {
      const monthNumbers = [...Array(12).keys()];
      const monthsInSelectedYear = monthsByYear[selectedYear] ? monthsByYear[selectedYear] : [];
      return monthNumbers.map((month) => {
        return {
          text: I18n.t(`dates.months.${month}`),
          value: month,
          disabled: monthsInSelectedYear.indexOf(month) === -1,
        };
      });
    };

    const getYearOptions = (selectedMonth) => {
      const yearNumbers = Object.keys(monthsByYear)
        .map((year) => parseInt(year))
        .sort((a, b) => {
          return a > b ? 1 : -1;
        });
      return yearNumbers.map((year) => {
        return {
          text: year,
          value: parseInt(year),
          disabled: false,
        };
      });
    };

    const setOptions = () => {
      const newState = {
        monthOptions: getMonthOptions(selectedYear),
        yearOptions: getYearOptions(selectedMonth),
      };
      const selectedMonthOption = newState.monthOptions.find((month) => month.value === selectedMonth);
      if(selectedMonthOption && selectedMonthOption.disabled){
        newState.selectedMonth = -1;
      }
      this.setState(newState);
    };

    if(selectedMonth === null){
      this.setState({
        selectedMonth: mostRecentMonthYear.month,
        selectedYear: mostRecentMonthYear.year,
      }, () => {
        setOptions();
      });
      return true;
    }
    setOptions();
  }

  switchTab(eventKey) {
    const tab = this.state.tabs.find(tab => tab.eventKey === eventKey) || this.state.tabs[0];
    this.props.actions.push(tab.route);
  }

  switchSalesReportTab(selection) {
    const activeSalesReportTab = selection;
    const tab = this.state.salesReportTabs.find(tab => tab.eventKey === activeSalesReportTab) || this.state.salesReportTabs[0];
    this.setState({activeSalesReportTab});
    this.props.actions.push(tab.path);
  }

  setActiveTabAndFetch(activeTab) {
    this.setState(
      {
        activeTab,
        isOpenTab: activeTab === 'open'
      },
      () => {
        // nada
      });
  }


  render() {
    const {monthOptions, yearOptions, selectedMonth, selectedYear, inputError, initialized} = this.state;
    const {reportsForExport} = this.props;
    return (
      <React.Fragment>
        <TabbedFormWrapper title={I18n.t('retail.closingReport.title')} tabs={this.state.salesReportTabs} activeTab={this.state.activeSalesReportTab} switchTab={this.switchSalesReportTab}>
          {
            reportsForExport.length > 0 || !initialized
              ? null
              : (<Row>
                <Col md={9}>
                  <div className='alert-info' style={{padding: '10px', marginBottom: '10px'}}>
                    {I18n.t('retail.exportReports.noReports')}
                  </div>
                </Col>
              </Row>)
          }
          <Row>
            <Col md={3}>
              <ReactSelectInput
                label={I18n.t('dates.month')}
                options={monthOptions}
                meta={{}}
                clearable={false}
                input={{value: selectedMonth, onBlur: () => {}, onChange: (value) => {
                  this.setState({selectedMonth: value}, () => this.setFormState());
                }}}
              />
            </Col>
            <Col md={3}>
              <ReactSelectInput
                label={I18n.t('dates.year')}
                options={yearOptions}
                meta={{}}
                clearable={false}
                input={{value: selectedYear, onBlur: () => {}, onChange: (value) => {
                  this.setState({selectedYear: value}, () => this.setFormState());
                }}}
              />
            </Col>
            <Col md={3}>
              <div className='form-group'>
                <label className='control-label'>&nbsp;</label>
                <Button
                  variant='primary'
                  className='btn-block'
                  onClick={this.getExportCsv}
                >
                  {I18n.t('retail.exportReports.exportToCSV')}
                </Button>
              </div>
            </Col>
          </Row>
          {
            !inputError
              ? null
              : (<Row>
                <Col md={9}>
                  <div className='alert-danger' style={{padding: '10px', marginBottom: '10px'}}>
                    {I18n.t('retail.exportReports.inputError')}
                  </div>
                </Col>
              </Row>)
          }
        </TabbedFormWrapper>
      </React.Fragment>

    );
  }
}

MonthlyReportExport.propTypes = {
  actions: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    getEnabledTabs: PropTypes.func,
  }).isRequired,
};

function mapStateToProps(state, ownProps) {
  const status = ownProps.params && ownProps.params.status === 'closed' ? 'closed' : 'open';
  const dataUpdateAvailable = [getDataUpdateAvailable(state, {name: dataNames.registers})];

  return {
    status,
    dataUpdateAvailable,
    monthsByYear: getExportReportMonthsByYearSelector(state),
    mostRecentMonthYear: getExportReportMostRecentMonthYearSelector(state),
    reportsForExport: state[dataNames.reportsForExport],
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({},
    apiActions,
    dataActions,
    {goBack, push, getEnabledTabs}
  );
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyReportExport);
