import {change, formValueSelector} from 'redux-form';
import {SPLIT_PACKAGE_FORM} from '../../constants/forms';
import {isArrayInsert, isArrayRemove, isFormChangeOnField} from './utils';

const formSelector = formValueSelector(SPLIT_PACKAGE_FORM);

const splitPackage = store => next => action => {
  const result = next(action);
  const {payload, meta} = action;

  if (isFormChangeOnField(action, [SPLIT_PACKAGE_FORM], 'is_test_package', true) && payload) {
    const path = meta.field.replace('.is_test_package', '');
    store.dispatch(change(meta.form, `${path}.destination_partner_id`, ''));
  }

  if (isFormChangeOnField(action, [SPLIT_PACKAGE_FORM], 'destination_partner_id', true)) {
    const path = meta.field.replace('.destination_partner_id', '');
    store.dispatch(change(meta.form, `${path}.destination_partner_facility_id`, ''));
  }

  if (shouldRecalculateRemainingQuantity(action)) {
    const state = store.getState();
    const packages = formSelector(state, 'new_packages') || [];
    const packages_qty = packages.reduce((acc, item) => {
      const transactedQty = parseFloat(item.transacted_qty || 0);
      const splits = parseFloat(item.number_of_splits || 0);
      return acc + (transactedQty * splits);
    }, 0);
    const qty_available = formSelector(state, 'qty_available');
    const remainder = qty_available - packages_qty;

    store.dispatch(change(meta.form, 'remainder', remainder));
  }

  return result;
};


/**
 * Determines whether the remaining quantity for a package should be recalculated based on
 * a number of qualifying actions
 * @param action
 * @return {boolean|boolean}
 */
const shouldRecalculateRemainingQuantity = (action) => {
  return isFormChangeOnField(action, [SPLIT_PACKAGE_FORM], 'transacted_qty', true) ||
    isFormChangeOnField(action, [SPLIT_PACKAGE_FORM], 'number_of_splits', true) ||
    isArrayInsert(action, [SPLIT_PACKAGE_FORM], 'new_packages') ||
    isArrayRemove(action, [SPLIT_PACKAGE_FORM], 'new_packages');
};

export default splitPackage;
