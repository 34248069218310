import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Card} from 'react-bootstrap';
import {FaCheck} from 'react-icons/fa';
import {I18n} from 'react-redux-i18n';
import StatementsIterator from './StatementsIterator';

const LabelStatementsForm = (props) => {

  const getHeaderStyles = (panelType) => {
    if (panelType === 'global'){
      return (props.selectedCategories.length === 0 && props.selectedSubCategories.length === 0) ? {color : '#3c763d', backgroundColor: '#dff0d8'} : {};
    }
    if (panelType === 'categories'){
      return (props.selectedCategories.length > 0 && props.selectedSubCategories.length === 0) ? {color : '#3c763d', backgroundColor: '#dff0d8'} : {};
    }
    if (panelType === 'subcategories') {
      return (props.selectedSubCategories.length > 0) ? {color : '#3c763d', backgroundColor: '#dff0d8'} : {};
    }
  };

  return(
    <div className='label-req-form'>
      <Row>
        <Col md={12}>
          {I18n.t('productStatements.inheritHints')}&nbsp;
          <strong>{I18n.t('productStatements.sourceHints')}</strong>
        </Col>
      </Row>
      <Row>
        <Col md={6} style={{marginTop: '12px'}}>
          <Row>
            <Col md={12}>
              <Card>
                <Card.Header style={getHeaderStyles('global')}>
                  {I18n.t('productStatements.globalStatements')}
                </Card.Header>
                <Card.Body>
                  Global statements are inherited by categories, sub categories, and products.
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={6}>

              <Card>
                <Card.Header style={getHeaderStyles('categories')}>
                  {I18n.t('productStatements.selectCategories')}&nbsp;
                  ({I18n.t('productStatements.numberSelected', {number: props.selectedCategories.length})})
                  <div className='text-muted'>{I18n.t('productStatements.clickToSelect', {type: I18n.t('productStatements.category')})} </div>
                </Card.Header>
                <Card.Body style={{minHeight: '350px', maxHeight: '350px', overflow: 'scroll'}}>
                  <ul className='list-group'>
                      {props.categories.map((category, index) => {
                        return (
                          <li key={index}
                            className='list-group-item' style={{cursor: 'pointer'}}
                            onClick={() => props.onCategorySelect(category)}
                          >
                            {props.categoryIsSelected(category)
                              ? <FaCheck style={{marginRight: '8px', color: 'green'}}/>
                              : null
                            }
                            {category.name}
                          </li>
                        );
                      })}
                    </ul>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>

              <Card>
                <Card.Header style={getHeaderStyles('subcategories')}>
                  {I18n.t('productStatements.selectSubCategories')}&nbsp;
                  ({I18n.t('productStatements.numberSelected', {number: props.selectedSubCategories.length})})
                  <div className='text-muted'>{I18n.t('productStatements.clickToSelect', {type: I18n.t('productStatements.subCategory')})} </div>
                </Card.Header>
                <Card.Body style={{height: '350px', overflow: 'scroll'}}>
                  <ul className='list-group'>
                    {props.categories.map((category, index) => {
                      return !props.categoryIsSelected(category)
                        ? null
                        : (
                          <li key={index} className='list-group-item'>
                            <strong>{category.name}</strong>
                            <ul className='list-group' style={{marginTop: '12px'}}>
                              {category.subcategories.map((sub, index) => {
                                return (
                                  <li key={index}
                                      className='list-group-item' style={{cursor: 'pointer'}}
                                      onClick={() => props.onSubCategorySelect(sub)}
                                  >
                                    {props.subCategoryIsSelected(sub)
                                        ? <FaCheck style={{marginRight: '8px', color: 'green'}} />
                                        : null
                                    }
                                    {sub.name}
                                  </li>
                                );
                              })}
                            </ul>
                          </li>
                      );
                    })}
                  </ul>
                </Card.Body>
              </Card>

            </Col>

          </Row>
        </Col>

        <Col md={6}>
          <form>
            <Card>
              <Card.Header>
                Set Statements For {I18n.t(`productStatements.${props.activeType}`)}
                <div className='text-muted'>
                  {I18n.t('productStatements.statementsHints')}
                </div>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col xs={12} sm={12} md={12}>
                    <StatementsIterator
                      onStatementChange={props.onStatementChange}
                      boundStatements={props.boundStatements}
                      bindStatementsForType={props.bindStatementsForType}
                      productStatements={props.productStatements}
                      categories={props.categories}
                      selectedCategories={props.selectedCategories}
                      selectedSubCategories={props.selectedSubCategories}
                      idIsInExtractedIds={props.idIsInExtractedIds}
                      extractCategoryId={props.extractCategoryId}
                      onToggleDelete={props.onToggleDelete}
                      type='health'
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} sm={12} md={12}>
                    <StatementsIterator
                      onStatementChange={props.onStatementChange}
                      boundStatements={props.boundStatements}
                      bindStatementsForType={props.bindStatementsForType}
                      productStatements={props.productStatements}
                      categories={props.categories}
                      selectedCategories={props.selectedCategories}
                      selectedSubCategories={props.selectedSubCategories}
                      idIsInExtractedIds={props.idIsInExtractedIds}
                      extractCategoryId={props.extractCategoryId}
                      onToggleDelete={props.onToggleDelete}
                      type='ides'
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} sm={12} md={12}>
                    <StatementsIterator
                      onStatementChange={props.onStatementChange}
                      boundStatements={props.boundStatements}
                      bindStatementsForType={props.bindStatementsForType}
                      productStatements={props.productStatements}
                      categories={props.categories}
                      selectedCategories={props.selectedCategories}
                      selectedSubCategories={props.selectedSubCategories}
                      idIsInExtractedIds={props.idIsInExtractedIds}
                      extractCategoryId={props.extractCategoryId}
                      onToggleDelete={props.onToggleDelete}
                      type='mandated'
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </form>
        </Col>

      </Row>
    </div>
  );
};

LabelStatementsForm.propTypes = {
  isClaimSelected: PropTypes.bool,
  isNonOrganicSelected: PropTypes.bool,
  isMandatedStateSelected: PropTypes.bool,
  categoryOptions: PropTypes.array.isRequired,
  subCategoryOptions: PropTypes.array.isRequired,
  onSubCategorySelect: PropTypes.func.isRequired,
  subCategoryIsSelected: PropTypes.func.isRequired,
  onStatementChange: PropTypes.func.isRequired,
  boundStatements: PropTypes.object,
  bindStatementsForType: PropTypes.func.isRequired,
  productStatements: PropTypes.array,
  categories: PropTypes.array,
  selectedCategories: PropTypes.array,
  selectedSubCategories: PropTypes.array,
  idIsInExtractedIds: PropTypes.func.isRequired,
  extractCategoryId: PropTypes.func.isRequired,
  onToggleDelete: PropTypes.func.isRequired,
  activeType: PropTypes.string.isRequired,
};

export default LabelStatementsForm;
