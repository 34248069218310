import React from 'react';
import PropTypes from 'prop-types';

import AddItem from '../../common/form/AddItem';
import ImageField from '../../common/form/files/ImageField';
import {MAX_ALTERNATE_PRODUCT_IMAGES} from '../../../constants/fileUploads';
import {alternateProduct} from '../../../constants/imageTypes';

const AlternateImages = (props) => {
  const {images, change, fields: {map, length, push, pop}} = props;
  return (
    <div className='alternative-images'>
      {map((fieldNamePrefix, index) => {
        const image = images && images[index];
        if (image && image.id && !image.file_id) {
          image.file_id = image.id;
          delete image.id;
        }
        const fileId = image && image.file_id;

        return (
          <ImageField
            key={index}
            imageType={alternateProduct}
            name={`${fieldNamePrefix}.file_id`}
            fileName={fieldNamePrefix}
            change={change}
            value={fileId}
            fileValue={image}
          />
        );
      })}

      <AddItem
        length={length}
        addAction={push}
        removeAction={pop}
        allowEmpty={true}
        showPlus={length < MAX_ALTERNATE_PRODUCT_IMAGES}
      />
    </div>
  );
};

AlternateImages.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    pop: PropTypes.func.isRequired,
    length: PropTypes.number.isRequired
  }).isRequired,
  change: PropTypes.func.isRequired,
  images: PropTypes.array
};

export default AlternateImages;



