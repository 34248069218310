import {createSelector} from 'reselect';
import moment from 'moment';

import * as dataNames from '../constants/dataNames';
import * as itemNames from '../constants/itemNames';

// Raw State Selectors
export const getClosedLoops = state => state[dataNames.closedLoops];
const getClosedLoopFacilities = state => state[dataNames.closedLoopFacilities];
export const getClosedLoopFacility = state => state[itemNames.closedLoopFacility];
export const getClosedLoopRegulatedReceipts = (state) => state[dataNames.closedLoopRegulatedReceipts];
export const getClosedLoopRegulatedUsers = state => state[dataNames.closedLoopRegulatedUsers];
export const getClosedLoopMmuOrders = state => state[dataNames.mmuOrders];

export const isInActiveClosedLoop = createSelector([getClosedLoops], (closedLoops) => {
  if (!closedLoops || !Array.isArray(closedLoops)) {
    return false;
  }
  return closedLoops.some(loop => loop.is_active);
});


/**
 * Selector used for generating a list of Facilities in the Closed Loop
 */
export const getClosedLoopFacilitiesForListing = createSelector(
  [getClosedLoopFacilities],
  (facilities) => {
    return facilities.reduce((facilities, facility) => {
      const initialInventoryByDate = facility.initial_inventory_by_date ?
        moment(facility.initial_inventory_by_date).format( 'M/D/Y') :
        'N/A';

      facilities.push({
        ...facility,
        initialInventoryByDate
      });

      return facilities;
    }, []);
  }
);
