import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack, push} from 'react-router-redux';
import {putItem} from '../../../actions/apiActions';
import FormWrapper from '../../common/form/FormWrapper';
import RewardsList from './components/listing/RewardsList'; //eslint-disable-line
import RewardEditor from './components/editor/RewardEditor';  //eslint-disable-line
import RewardsSetup from './components/setup/RewardsSetup';  //eslint-disable-line

export class RewardsManager extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.redirect = this.redirect.bind(this);
    this.toggleRewardStatus = this.toggleRewardStatus.bind(this);
    this.state = {
      managerMode: false,
      listMode: 'active',
    };
  }

  componentWillMount() {
    this.setManagerMode(this.props);
  }

  componentWillReceiveProps(nextProps){
    this.setManagerMode(nextProps);
  }

  setManagerMode(props){
    if(typeof props.location.pathname !== 'string') return false; // not able to set
    const newState = {};
    const mode = props.location.pathname.split('/').pop(); // [setup||create||rewards||active||inactive]
    const listMode = (mode === 'inactive')  ? mode : 'active';
    if(listMode !== this.state.listMode) newState.listMode = mode;
    const listModes = ['active', 'inactive', 'rewards'];
    const managerMode = (props.params.id)
      ? 'edit'
      : (listModes.indexOf(mode) !== -1) ? 'list' : mode;
    if(managerMode !== this.state.managerMode) newState.managerMode = managerMode;
    if(Object.keys(newState).length > 0) this.setState(newState);
    return true;
  }

  toggleRewardStatus(e, reward){
    return new Promise((resolve, reject) => {
      const is_active = (parseInt(reward.is_active) === 1) ? 0 : 1;
      this.props.actions.putItem(`/api/rewards/${reward.id}`, {is_active}, null, null, null, () => {
        resolve();
      }).then().catch(() => {
        reject();
      });
    });
  }

  redirect(){
    this.props.actions.goBack();
  }

  getTitle(){
    const titles = {
      edit: 'Editor',
      create: 'Creator',
      list: 'Listing',
      setup: 'Set Up'
    };
    return `Rewards ${titles[this.state.managerMode]}`;
  }

  getComponent(){
    const {managerMode, listMode} = this.state;
    const rewardId = this.props.params.id;
    const components = {
      edit: <RewardEditor rewardId={rewardId}  toggleRewardStatus={this.toggleRewardStatus} />,
      create: <RewardEditor rewardsId={0}  toggleRewardStatus={this.toggleRewardStatus} />,
      list: <RewardsList mode={listMode} toggleRewardStatus={this.toggleRewardStatus} />,
      setup: <RewardsSetup />,
    };
    return components[managerMode];
  }

  render(){

    const {managerMode} = this.state;

    if(!managerMode) return <div>No Mode</div>;

    if(managerMode === 'list'){
      return (<div>{this.getComponent()}</div>);
    }

    return (
      <FormWrapper title={this.getTitle()} goBack={this.redirect}>
        {this.getComponent()}
      </FormWrapper>
    );
  }
}

function mapDispatchToProps(dispatch) {
  const actions = {goBack, push, putItem};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(null, mapDispatchToProps)(RewardsManager);
