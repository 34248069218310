import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'redux-form';
import { Button, Col, Row } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import lodashGet from 'lodash.get';
import AccordionPanel from '../../common/AccordionPanel';
import { state, wholesale, tax, AU_state, AU_wholesale } from '../../../constants/fileTypes';
import FacilityMainInfo from './FacilityMainInfo'; // eslint-disable-line import/no-named-as-default
import ObjectFilesFieldArray from '../../common/form/files/ObjectFilesFieldArray';
import WillRender from '../../common/concealers/WillRender';

export class Facilities extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.handleAddFacilityClick = this.handleAddFacilityClick.bind(this);
  }

  handleAddFacilityClick() {
    this.props.arrayPushAction(this.props.formName, 'facilities', { licenses: [], facility_types: [] });
  }

  render() {
    const {
      fields: { map, get },
      change,
      disable,
      submitting,
      isOrgFacility,
      facilities,
      integrationState,
      partner,
      currentFacility,
      currentValues,
      isModifyForm,
      closedLoopPartner
    } = this.props;

    return (
      <div>
        {map((facilityPath, index) => {
          const facilityData = get(index);
          const facility = lodashGet(facilities, index);
          const trimmedFacilityName = lodashGet(facilityData, 'facility_name', '').trim();
          const facilityName = trimmedFacilityName
            ? trimmedFacilityName
            : I18n.t('partners.createPartner.facility').replace('{0}', index + 1);
          const initialCountry = lodashGet(facilityData, 'country_code', 'US');
          facilityData.country_code = initialCountry;
          return (
            <div key={`facility-block-${index}`} className='facility-block'>
              <AccordionPanel title={facilityName}>
                <FacilityMainInfo
                  facilityPath={facilityPath}
                  facility={facility}
                  index={index}
                  isOrgFacility={isOrgFacility}
                  integrationState={integrationState}
                  change={change}
                  initialCountry={initialCountry}
                  partner={partner}
                  currentFacility={currentFacility}
                  currentValues={currentValues}
                  isModifyForm={isModifyForm}
                  closedLoopPartner={closedLoopPartner}
                />
                <h4>{`${facilityName} ${I18n.t('partners.createPartner.documentation')}`}</h4>
                <FieldArray
                  name={`${facilityPath}.licenses`}
                  component={ObjectFilesFieldArray}
                  props={{
                    change,
                    types:
                      lodashGet(facility, 'country_code', 'US') === 'AU'
                        ? [AU_state, AU_wholesale]
                        : [state, wholesale, tax],
                    files: lodashGet(facility, 'licenses', []), // && facility.licenses || [],
                    name: `${facilityPath}.licenses`,
                    partner
                  }}
                />
              </AccordionPanel>
            </div>
          );
        })}
        <Row>
          <Col md={2}>
            <Button onClick={this.handleAddFacilityClick} disabled={disable || closedLoopPartner} variant='primary' className='btn-block'>
              {I18n.t('partners.createPartner.addNewFacility')}
            </Button>
          </Col>
          <WillRender ifTrue={facilities && facilities.length}>
            <Col md={{span: 2, offset: 8}}>
              <Button type='submit' disabled={submitting || disable || closedLoopPartner} className='btn-block' variant='primary'>
                {I18n.t('general.save')}
              </Button>
            </Col>
          </WillRender>
        </Row>
      </div>
    );
  }
}

Facilities.propTypes = {
  facilities: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  disable: PropTypes.bool,
  pristine: PropTypes.bool.isRequired,
  fields: PropTypes.shape({
    push: PropTypes.func.isRequired,
    map: PropTypes.func.isRequired,
    pop: PropTypes.func.isRequired,
    get: PropTypes.func.isRequired,
    length: PropTypes.number.isRequired
  }),
  isOrgFacility: PropTypes.bool,
  editMode: PropTypes.bool,
  integrationState: PropTypes.object.isRequired,
  partner: PropTypes.object.isRequired,
  currentFacility: PropTypes.object,
  arrayPushAction: PropTypes.func.isRequired,
  currentValues: PropTypes.object.isRequired,
  closedLoopPartner: PropTypes.bool.isRequired
};

Facilities.defaultProps = {
  editMode: false,
  handleModifyFacility: () => {},
  closedLoopPartner: false
};

export default Facilities;
