import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import ReduxBootstrapInput from './ReduxBootstrapInput';

const ReactSelectInput = ({textKey, valueKey, label, customLabel, size, input, meta, children, isRequired, options, ...props }) => {
  /*
   *  onBlur and onChange are not passed directly to the Select component
   *  as a workaround for a problem with react-select and redux-form:
   *  https://github.com/JedWatson/react-select/issues/1129
   */

  const handleBlur = () => {
    input.onBlur();
  };
  const handleChange = value => {
    input.onChange(value);
  };

  const newInput = Object.assign({}, input, {onBlur: handleBlur, onChange: handleChange});

  return (
    <ReduxBootstrapInput Control={Select} options={options}
                         simpleValue labelKey={textKey} valueKey={valueKey}
                         label={label} size={size}
                         customLabel={customLabel}
                         input={newInput} meta={meta}
                         name={input.name} isRequired={isRequired} clearable={Boolean(input.value)}
                         {...props} >
      {children}
    </ReduxBootstrapInput>
  );
};

ReactSelectInput.propTypes = {
  valueKey: PropTypes.string.isRequired,
  textKey: PropTypes.string.isRequired,
  label: PropTypes.string,
  size: PropTypes.oneOf(['lg', 'sm', 'large', 'small']),
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired
      ])
    })
  ).isRequired,
  children: PropTypes.node,
  isRequired: PropTypes.bool,
  customLabel: PropTypes.object,
};
ReactSelectInput.defaultProps = {
  valueKey: 'value',
  textKey: 'text',
  placeholder: ''
};

export default ReactSelectInput;
