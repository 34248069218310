import React from 'react';
import PropTypes from 'prop-types';
import Combobox from 'react-widgets/lib/Combobox';
import 'react-widgets/dist/css/react-widgets.css';
import ReduxBootstrapInput from './ReduxBootstrapInput';

const ComboboxInput = ({textKey, valueKey, label, size, input, meta, children, isRequired, options, ...props}) => {
  const handleBlur = () => {
    input.onBlur();
  };
  const handleChange = value => {
    input.onChange(value);
  };

  const newInput = {...input, onBlur: handleBlur, onChange: handleChange};


  return (
    <ReduxBootstrapInput Control={Combobox} data={options} textField={textKey} valueField={valueKey}
                         label={label} size={size} input={newInput} meta={meta}
                         name={input.name} isRequired={isRequired} {...props}>
      {children}
    </ReduxBootstrapInput>
  );
};

ComboboxInput.propTypes = {
  valueKey: PropTypes.string.isRequired,
  textKey: PropTypes.string.isRequired,
  label: PropTypes.string,
  size: PropTypes.oneOf(['lg', 'sm', 'large', 'small']),
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  name: PropTypes.string,
  options: PropTypes.array.isRequired,
  children: PropTypes.node,
  isRequired: PropTypes.bool,
  suggest: PropTypes.bool,
  filter: PropTypes.oneOf([false, 'contains', 'startsWith', 'endsWith'])
};

ComboboxInput.defaultProps = {
  valueKey: 'value',
  textKey: 'text',
  suggest: true,
  filter: 'contains',
};

export default ComboboxInput;
