import {createSelector} from 'reselect';

import {getOrder} from '../ordersSelectors';


export const getOrderManualDiscounts = createSelector(
  [getOrder],
  order => (order && order.coupons || []).filter(coupon => coupon.type === 'manual')
);

export const getOrderProductOptions = createSelector(
  [getOrder],
  order => (order && order.products || []).map(product => ({
    ...product,
    text: product.name,
    value: product.item_master_id,
  }))
);
