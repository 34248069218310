import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import TextInput from '../../common/form/TextInput';

class MarijuanaIdFieldWithMask extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.fieldNormalizer = this.fieldNormalizer.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.setCursor = this.setCursor.bind(this);
    this.state = {selectionStart:0, value: ''};
  }

  componentDidUpdate(prevProps, prevState) {
    this.setCursor();
  }

  setCursor() {
    if (!this.inputField) return true;
    window.requestAnimationFrame(() => {
      let {selectionStart} = this.state;
      if (!(selectionStart % 5)) {
        selectionStart += Math.floor(selectionStart / 5);
      }
      this.inputField.selectionStart = selectionStart;
      this.inputField.selectionEnd = selectionStart;
    });
  }

  fieldNormalizer(value) {const normalizeValue = value ? value
    .toString()
    .replace(/-/g, '')
    .replace(/(.{4})(?=.+)/g, '$1-')
    .replace(/(-)$/, '') : '';
    this.setState({value: normalizeValue});
    return normalizeValue;
  }

  handleOnChange(event) {
    this.inputField = event.target;
    this.setState({selectionStart: this.inputField.selectionStart});
  }

  render() {
    const {conditionalProps, label, fieldName, isRequired} = this.props;
    return (<Field
          name={fieldName}
          component={TextInput}
          {...conditionalProps}
          onChange={this.handleOnChange}
          normalize={this.fieldNormalizer}
          props={{
            label: label,
            placeholder: 'xxxx-xxxx-xxxx-xxxx-xxxx',
            isRequired: isRequired,
          }}/>
    );
  }
}

MarijuanaIdFieldWithMask.propTypes = {
  conditionalProps: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  isRequired: PropTypes.bool
};

export default MarijuanaIdFieldWithMask;