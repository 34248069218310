import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {convertDbDateToShortDateTime} from '../../util/dateHelpers';
import InternationalCurrencyStatic from '../common/form/InternationalCurrencyStatic';

const OrderTransaction = ({name, type, amount, is_pending, tip, fee, date, timezone, alleavesMode}) => {
  const additionalFieldsStyle = {borderStyle:'none', paddingLeft:'1.5em', paddingTop:'0em', paddingBottom:'0em'};
  return (
    <React.Fragment>
      <tr className='coupon clearfix'>
        <td>
          {name.length ? I18n.t(`cart.payment.${name.toLowerCase()}`) : I18n.t('cart.payment.payment')}:
        </td>
        <td>
          {type === 'aeropayintegrated'
            ? I18n.t('cart.payment.aeropayIntegrated')
            : type === 'posabit'
              ? I18n.t('cart.payment.posabit')
              : type === 'alleaves'
                ? alleavesMode && alleavesMode === 'regala' ? I18n.t('cart.payment.alleavesRegala') : I18n.t('cart.payment.alleavesCashlessAtm')
                : I18n.t(`cart.payment.${type}`)
          }
          {is_pending ? <span className={'danger'}>&nbsp;({I18n.t('cart.payment.pending')})</span> : null}
        </td>
        <td>
          {convertDbDateToShortDateTime(date, timezone)}
        </td>
        <td className='text-right'>
          <InternationalCurrencyStatic>{amount}</InternationalCurrencyStatic>
        </td>
      </tr>
      {tip &&
        <tr className='clearfix'>
          <td style={additionalFieldsStyle}>&nbsp;</td>
          <td style={additionalFieldsStyle}>Tip</td>
          <td style={additionalFieldsStyle}>&nbsp;</td>
          <td style={additionalFieldsStyle} className='text-right'>
            <InternationalCurrencyStatic>{tip}</InternationalCurrencyStatic>
          </td>
        </tr>
      }
      {type === 'alleaves' && !is_pending &&
        <tr className='clearfix'>
          <td style={additionalFieldsStyle}>&nbsp;</td>
          <td style={additionalFieldsStyle}>Withdrawal</td>
          <td style={additionalFieldsStyle}>&nbsp;</td>
          <td style={additionalFieldsStyle} className='text-right'>
            <InternationalCurrencyStatic>{parseFloat(amount) + parseFloat(tip)}</InternationalCurrencyStatic>
          </td>
        </tr>
      }
      {fee &&
        <tr className='clearfix'>
          <td style={additionalFieldsStyle}>&nbsp;</td>
          <td style={additionalFieldsStyle}>Fees</td>
          <td style={additionalFieldsStyle}>&nbsp;</td>
          <td style={additionalFieldsStyle} className='text-right'>
            <InternationalCurrencyStatic>{fee}</InternationalCurrencyStatic>
          </td>
        </tr>
      }
    </React.Fragment>
  );
};

OrderTransaction.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  is_pending: PropTypes.bool.isRequired,
  tip: PropTypes.number.isRequired,
  fee: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  alleavesMode: PropTypes.string
};

export default OrderTransaction;
