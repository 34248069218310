import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import {connect} from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Row, Col } from 'react-bootstrap';

import TextInput from '../common/form/TextInput';
import ReactSelectInput from '../common/form/ReactSelectInput';

import {isMetrcDelivery} from '../../selectors/integration/metrcSelectors';

const VehicleInfo = (props) => {
  const {vehicles, change, onChangeDefaultVehicle, isMetrcDelivery} = props;

  return (
    <Row>
      <Col sm={12} xs={12} md={12}>
        <Row>
          <Col sm={12} xs={12} md={12} className='driver-form-header'>
            <p>{I18n.t('cultivation.drivers.form.vehicleInfo')}</p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12}>
            <Row>
              <Col xs={12} sm={12} md={4}>
                <Field
                  name='default_vehicle_id'
                  component={ReactSelectInput}
                  props={{
                    label: I18n.t('cultivation.drivers.form.defaultVehicle'),
                    options: vehicles,
                    textKey: 'nickname',
                    valueKey: 'id',
                    isRequired: isMetrcDelivery,
                    onChange: (e) => onChangeDefaultVehicle(e, change)
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={4}>
                <Field
                  name='make'
                  component={TextInput}
                  props={{
                    label: I18n.t('cultivation.vehicles.table.make'),
                    disabled: true,
                    isRequired: isMetrcDelivery,
                  }}
                />
              </Col>
              <Col xs={12} sm={12} md={4}>
                <Field
                  name='model'
                  component={TextInput}
                  props={{
                    label: I18n.t('cultivation.vehicles.table.model'),
                    disabled: true,
                    isRequired: isMetrcDelivery,
                  }}
                />
              </Col>
              <Col xs={12} sm={12} md={4}>
                <Field
                  name='license_number'
                  component={TextInput}
                  props={{
                    label: I18n.t('cultivation.vehicles.table.plateNumber'),
                    disabled: true,
                    isRequired: isMetrcDelivery,
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

VehicleInfo.propTypes = {
  vehicles: PropTypes.array,
  onChangeDefaultVehicle: PropTypes.func.isRequired,
  change: PropTypes.func,
  isMetrcDelivery: PropTypes.bool,
};

export default connect(
  (state) => ({
    isMetrcDelivery: isMetrcDelivery(state),
  })
)(VehicleInfo);
