import {reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import {MANAGE_USER_ROLES} from '../../../constants/forms';
import ManageUserRolesForm from './ManageUserRolesForm';
import validate from './validate';


const ManageUserRolesFormWrapper = reduxForm({
  form: MANAGE_USER_ROLES,
  enableReinitialize: true,
  validate,
})(ManageUserRolesForm);

ManageUserRolesFormWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  addRoleDisabled: PropTypes.bool.isRequired,
  isSearching: PropTypes.bool
};

export default ManageUserRolesFormWrapper;
