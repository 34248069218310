import React from 'react';
import PropTypes from 'prop-types';
import {Button, Row, Col} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';

import AccordionPanel from '../../common/AccordionPanel';
import PreviewFields from './PreviewFields';

const CommonSectionPreview = (props) => {
  const {sectionData, handleEdit} = props;

  return (
    <AccordionPanel title={sectionData.title}>
        <Row className='preview-form'>
          <Col md={11}>
            <PreviewFields
              fields={sectionData.fields}
            />
          </Col>
          <Col md={1}>
            <Button type='button' onClick={() => handleEdit(sectionData)} variant='default'>
              {I18n.t('products.massModify.edit')}
            </Button>
          </Col>
        </Row>
      </AccordionPanel>
  );
};

CommonSectionPreview.propTypes = {
  sectionData: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired
};

export default CommonSectionPreview;
