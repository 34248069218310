import React from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-bootstrap';

const CheckBoxInput = ({ label, input, disabled, rightAddon}) => {
  return (
    <React.Fragment>
      <Form.Check inline type='checkbox' label={label} checked={input.value} {...input} disabled={disabled}>
      </Form.Check>
      {rightAddon ? rightAddon : null}
    </React.Fragment>
  );
};

CheckBoxInput.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  rightAddon: PropTypes.object,
};

export default CheckBoxInput;
