import {change, formValueSelector} from 'redux-form';
import round from 'lodash.round';

import {COMPLETE_PACKAGE_FORM} from '../../constants/forms';
import {getActionsFromBatchAction, getFormArrayIndexFromString, isFormChangeOnField, isFormInitializing} from './utils';
import * as itemNames from '../../constants/itemNames';
import {getItem} from '../../actions/apiActions';
import {getInputUom, getOutputUom} from '../../selectors/forms/completePackageFormSelectors';
import {convertFromBase, convertWeightFromAndTo} from '../../util/uomHelpers';
import {safeNumberConversion} from '../../util/mathHelpers';

// This middleware is used to save some fields values from re-initializing when item_master_parent_id changes.
// We catch redux-form "initialization" action, save values before re-initialization and restore after.

const formSelector = formValueSelector(COMPLETE_PACKAGE_FORM);

const completePackageForm = store => next => action => {

  let valuesSavedBeforeReinit;
  const fieldsToSaveBeforeReinit = ['completed_at', 'package_created_at', 'package_expires_at', 'storage_location_id', 'purpose', 'state_integration_tracking_id', 'is_produced'];

  const actions = getActionsFromBatchAction(action);
  actions.forEach(action => {
    if (isFormInitializing(action, [COMPLETE_PACKAGE_FORM])) {
      if (action.payload && action.payload.item_master_parent_id !== undefined) {
        valuesSavedBeforeReinit = _extractValuesToSave(store.getState(), fieldsToSaveBeforeReinit);
      }
    }
  });

  const result = next(action);

  if (valuesSavedBeforeReinit) {
    Object.keys(valuesSavedBeforeReinit).map( key => {
      if (valuesSavedBeforeReinit[key]) {
        store.dispatch(change(COMPLETE_PACKAGE_FORM, key, valuesSavedBeforeReinit[key]));
      }
    });
  }

  // Reactions to form changes below
  const {meta} = action;
  const isQuantityChange = isFormChangeOnField(action, COMPLETE_PACKAGE_FORM, 'qty', true);
  const isWasteChange = isFormChangeOnField(action, COMPLETE_PACKAGE_FORM, 'wasteReportedOutput', true);
  const isPackagingLossChange = isFormChangeOnField(action, COMPLETE_PACKAGE_FORM, 'packagingLossOutput', true);

  if (isQuantityChange) {
    const state = store.getState();

    const quantities = formSelector(state, 'quantities');
    const quantityIndex = getFormArrayIndexFromString(meta.field);
    const quantity = quantities[quantityIndex];
    const quantityStr = `quantities[${quantityIndex}]`;
    const dispatch = store.dispatch;

    if (!quantity.isChanged) {
      dispatch(change(COMPLETE_PACKAGE_FORM, `${quantityStr}.isChanged`, true));

      generatePackageId(dispatch, quantity.item_master_id, packageId => {
        dispatch(change(COMPLETE_PACKAGE_FORM, `${quantityStr}.package_code`, packageId));
      });
    }

    updateDisplayedTotals(meta.field.split('.')[1], state, dispatch);
  }

  if (isWasteChange || isPackagingLossChange) updateDisplayedTotals(meta.field, store.getState(), store.dispatch);

  if (isFormChangeOnField(action, COMPLETE_PACKAGE_FORM, 'shake', true)) {
    const shakeIsChanged = formSelector(store.getState(), 'shakeIsChanged');
    const dispatch = store.dispatch;

    if (!shakeIsChanged) {
      dispatch(change(COMPLETE_PACKAGE_FORM, 'shakeIsChanged', true));
      generatePackageId(dispatch, packageId => dispatch(change(COMPLETE_PACKAGE_FORM, 'shakePackageCode', packageId)));
    }
  }

  return result;
};

const calculateTotalPackedBase = (quantities) => {
  const total = quantities.reduce((total, quantity) => {

    const qty = parseInt(quantity.qty);
    return qty
      ? total + qty * quantity.weightBase
      : total;
  }, 0);
  // TODO: #27039 - Remove rounding? Round to 5? Is this a problem with Milli values, did Natalia mention that?
  return round(total, 2);
};

const updateDisplayedTotals = (changedFieldName, state, dispatch) => {

  const inputUom = getInputUom(state);
  const outputUom = getOutputUom(state);

  // Get base values
  const quantities = formSelector(state, 'quantities');
  const startingWeight = safeNumberConversion(formSelector(state, 'weight'));
  const wasteReportedOutput = safeNumberConversion(formSelector(state, 'wasteReportedOutput'));
  const packagingLossOutput = safeNumberConversion(formSelector(state, 'packagingLossOutput'));
  const totalPackedBase = calculateTotalPackedBase(quantities);

  // Get all totals in input and output UOMs
  const startingWeightInInputUom = parseFloat(startingWeight);
  const startingWeightInOutputUom = convertWeightFromAndTo(startingWeight, inputUom, outputUom);

  const wasteInOutputUom = parseFloat(wasteReportedOutput);
  const wasteInInputUom = convertWeightFromAndTo(wasteInOutputUom, outputUom, inputUom);

  const packagingLossInOutputUom = parseFloat(packagingLossOutput);
  const packagingLossInInputUom = convertWeightFromAndTo(packagingLossInOutputUom, outputUom, inputUom);

  const totalPackagedInOutputUom = convertFromBase(totalPackedBase, outputUom);
  const totalPackagedInInputUom = convertWeightFromAndTo(totalPackagedInOutputUom, outputUom, inputUom);

  // Perform sums in same UOM for consistent results
  const unpackedRemainderInOutputUom = startingWeightInOutputUom - (wasteInOutputUom + totalPackagedInOutputUom + packagingLossInOutputUom);
  const unpackedRemainderInInputUom = startingWeightInInputUom - (wasteInInputUom + totalPackagedInInputUom + packagingLossInInputUom);

  // Return object updated based on which field was changed
  const updatesByChangedField = {
    qty: (values) => {
      return Object.assign({}, values, {
        totalPackedOutput: totalPackagedInOutputUom,
        totalPackedInput: totalPackagedInInputUom,
      });
    },
    wasteReportedOutput: (values) => {
      return Object.assign({}, values, {
        wasteReportedInput: wasteInInputUom,
      });
    },
    packagingLossOutput: (values) => {
      return Object.assign({}, values, {
        packagingLossInput: packagingLossInInputUom,
      });
    }
  };

  // Always update these
  const baseValues = {
    unpackedRemainderOutput: unpackedRemainderInOutputUom,
    unpackedRemainderInput: unpackedRemainderInInputUom,
  };

  const newValues = updatesByChangedField[changedFieldName]
    ? updatesByChangedField[changedFieldName](baseValues)
    : baseValues;

  Object.keys(newValues).forEach(
    key => dispatch(change(COMPLETE_PACKAGE_FORM, key, newValues[key]))
  );
};

const _extractValuesToSave = (state, fields) => {
  const savedValues = {};
  if (state && state.form && state.form.completePackage && state.form.completePackage.values) {
    fields.forEach(key => {
      if (state.form.completePackage.values[key]) {
        savedValues[key] = state.form.completePackage.values[key];
      }
    });
  }
  return savedValues;
};


const generatePackageId = (dispatch, itemMasterId, next) => {
  dispatch(getItem(
    `/api/item_masters/${itemMasterId}/generate_id`,
    itemNames.packageId,
    {failed: 'packaging.generatePackageId.failed'},
    {},
    next
  ));
};


export default completePackageForm;
