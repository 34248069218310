import moment from 'moment';
import React from 'react';
import { bindActionCreators } from 'redux';
import { Field, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import { Col,  Row } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import PropTypes from 'prop-types';

import ReactSelectInput from '../../common/form/ReactSelectInput';
import TextInput from '../../common/form/TextInput';
import TextAreaInput from '../../common/form/TextAreaInput';
import InternationalDateTimePickerInput from '../../common/form/InternationalDateTimePickerInput';
import { getPartners, getPartnerFacilitiesByPartner } from '../../../selectors/partnersSelectors';
import { getIntegrationState } from '../../../selectors/integration/integrationSelectors';

export class TransferDestinations extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.onPartnerChange = this.onPartnerChange.bind(this);
    this.onPartnerFacilityChange = this.onPartnerFacilityChange.bind(this);
    this.state = {partnerFacilityLicenseValid: 1};
  }
  
  onPartnerChange(form, value) {
    const { actions: {change}, partnerFacilities, formName } = this.props;
    change(formName, form + '.transporter_partner_id', value);
    const validFacilities = partnerFacilities.filter(facility => facility.partner_id === value);
    change(formName, form + '.integration_transporter_id', validFacilities.length === 1 ? validFacilities[0].id : null);
  }
  
  onPartnerFacilityChange(form, value) {
    const { actions: {change}, partnerFacilities, formName } = this.props;

    change(formName, form + '.integration_transporter_id', value);

    this.setState({partnerFacilityLicenseValid: 0});
    if (value) {
      const selected = partnerFacilities.filter(facility => facility.id === value);
      if(selected.length) {
        const licenses = selected[0].licenses;
        if(licenses && licenses.length) {
          const valid = licenses.filter(item => {
            const expires = moment(item.expires_on);
            const now = moment();
            return item.expires_on === null || now.isBefore(expires);
          });
  
          if(valid.length) {
            this.setState({partnerFacilityLicenseValid: 1});
          }
        }
      }
    }
  }
  
  render() {
    const {fields: {map}, isReadOnly, integrationState, partners, partnerFacilities} = this.props;
    const {isBiotrack, isMetrc, isMetrcTransfersEnabled} = integrationState;
    const {partnerFacilityLicenseValid} = this.state;

    return (
      <div className='destinations'>
        <h3>{I18n.t('cultivation.transfers.form.destination')}</h3>
        {map((itemName, itemIndex) => {
          return (
            <Row key={`transfer-destination-${itemIndex}`}>
              <Col sm={12} md={6}>
                <Row>
                  <Col sm={6} md={8}>
                    <Row>
                      <Col sm={12} md={12}>
                        <Field
                          name={`${itemName}.partner_name`}
                          component={TextInput}
                          props={{
                            label: I18n.t('cultivation.transfers.form.partner'),
                            disabled: true
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={6}>
                        <Field
                          name={`${itemName}.partner_license_number`}
                          component={TextInput}
                          props={{
                            label: I18n.t('cultivation.transfers.form.licenseNumber'),
                            disabled: true
                          }}
                        />
                      </Col>
                      <Col xs={12} sm={6}>
                        <Field
                          name={`${itemName}.partner_dba`}
                          component={TextInput}
                          props={{
                            label: I18n.t('cultivation.transfers.form.dba'),
                            disabled: true
                          }}
                        />
                      </Col>
                    </Row>
                    {isMetrc && isMetrcTransfersEnabled && 
                      <Row>
                        <Col xs={12} sm={6}>
                          <Field name={`${itemName}.transporter_partner_id`} component={ReactSelectInput} props={{
                            options: partners,
                            label: I18n.t('cultivation.transfers.form.transportPartner'),
                            onChange: (value) => { return this.onPartnerChange(itemName, value); },
                            textKey: 'name',
                            valueKey: 'id',
                            disabled: isReadOnly
                          }}/>
                        </Col>
                        <Col xs={12} sm={6}>
                          <Field name={`${itemName}.integration_transporter_id`} component={ReactSelectInput} props={{
                            options: partnerFacilities,
                            label: I18n.t('cultivation.transfers.form.transportPartnerCompany'),
                            textKey: 'facility_name',
                            valueKey: 'id',
                            disabled: isReadOnly,
                            onChange: (value) => { return this.onPartnerFacilityChange(itemName, value); }
                          }}/>
                          {!partnerFacilityLicenseValid && isMetrc && isMetrcTransfersEnabled &&
                            <div className='alert alert-warning'>{I18n.t('supplyChain.metrcTransferWarnings.transporterFacilityLicense')}</div>
                          }
                        </Col>
                      </Row>
                    }
                  </Col>
                </Row>
              </Col>
              <Col sm={12} md={6}>
                <Row>
                  <Col md={8}>
                    <Field
                      name={`${itemName}.method_of_transport`}
                      component={TextAreaInput}
                      props={{
                        label: I18n.t('cultivation.transfers.form.methodOfTransport'),
                        rows: 1,
                        disabled: isReadOnly,
                      }}
                    />
                  </Col>
                  <Col sm={6} md={8}>
                    <Field
                      name={`${itemName}.directions`}
                      component={TextAreaInput}
                      props={{
                        label: I18n.t('cultivation.transfers.form.directions'),
                        rows: 5,
                        disabled: isReadOnly,
                      }}
                    />
                  </Col>
                  <Col sm={6} md={4}>
                    <Row>
                      <Col xs={12}>
                        <InternationalDateTimePickerInput
                          name={`${itemName}.departure_time`}
                          props={{
                            label: I18n.t('cultivation.transfers.form.departureTime'),
                            disabled: isReadOnly,
                            isRequired: isBiotrack,
                            dateFormat: false,
                            timeFormat: true,
                            enableTodayButton: false,
                            inputProps: {
                              disabled: isReadOnly,
                            },
                          }}
                        />
                      </Col>
                      <Col xs={12}>
                        <InternationalDateTimePickerInput
                          name={`${itemName}.arrival_time`}
                          props={{
                            label: I18n.t('cultivation.transfers.form.arrivalTime'),
                            dateFormat: false,
                            timeFormat: true,
                            enableTodayButton: false,
                            disabled: isReadOnly,
                            isRequired: isBiotrack,
                            inputProps: {
                              disabled: isReadOnly,
                            },
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          );
        })}
      </div>
    );
  }
}

TransferDestinations.propTypes = {
  fields: PropTypes.shape({
    push: PropTypes.func.isRequired,
    map: PropTypes.func.isRequired
  }).isRequired,
  partner_facilities: PropTypes.array,
  integrationState: PropTypes.object.isRequired,
  isReadOnly: PropTypes.bool,
};

function mapStateToProps(state, currentProps) {
  const { formName } = currentProps;
  const selector = formValueSelector(formName);
  const partner_id = selector(state, 'destinations[0].transporter_partner_id');

  return {
    integrationState: getIntegrationState(state),
    partners: getPartners(state),
    partnerFacilities: getPartnerFacilitiesByPartner(state, {partner_id})
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({change}, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TransferDestinations);
