import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';
import {getUnpaginatedData, deleteData, putData} from '../../../actions/apiActions';
import * as selectedDataActions from '../../../actions/selectedDataActions';
import {handleComplexSelectRow} from '../../../actions/helpers/selectedDataHelper';
import {getSelectedGroupsData, getPricingGroupsListingData} from '../../../selectors/groupListingSelectors';
import * as dataNames  from '../../../constants/dataNames';
import {unsetData} from '../../../actions/dataActions';
import PageTitle from '../../common/PageTitle';
import TablePageWrapper from '../../common/grid/TablePageWrapper';
import InProgressOverlay from '../../common/InProgressOverlay';
import * as p from '../../../constants/permissions';
import SingleActionColumn from '../../common/grid/columns/SingleActionColumn';
import ModalWrapper from '../../common/ModalWrapper';

const tabStatuses = {inactive: 'inactive', active: 'active'};

class PricingGroupListingPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.createGroup = this.createGroup.bind(this);
    this.editGroup = this.editGroup.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.switchTab = this.switchTab.bind(this);
    this.activateGroup = this.activateGroup.bind(this);
    this.inactivateGroup = this.inactivateGroup.bind(this);
    this.setGroupActivity = this.setGroupActivity.bind(this);
    this.loadGroups = this.loadGroups.bind(this);
    this.showConfirmation = this.showConfirmation.bind(this);
    this.hideConfirmation = this.hideConfirmation.bind(this);

    this.setupTabs();
    this.setupColumns();

    this.state = {
      activeTab: props.params.status === 'inactive' ? tabStatuses.inactive : tabStatuses.active,
      confirmationModal: {
        show: false,
        group: null,
      },
      loading: false,
    };
  }

  setupTabs() {
    const actions = [
      {id: 'createGroup', func: this.createGroup , text: 'retail.pricingGroup.createGroup' , requireSelect: false, glyph: 'plus-sign'},
      {id: 'EditGroup', func: this.editGroup , text: 'retail.pricingGroup.edit' , requireSelect: true, glyph: 'edit'}
    ];
    this.tabs = [
      {
        id: 'activeTab',
        eventKey: 'active',
        title: 'retail.pricingGroupListing.active',
        path: '/retail/pricing-groups/active',
        actions,
      },
      {
        id: 'inactiveTab',
        eventKey: 'inactive',
        title: 'retail.pricingGroupListing.inactive',
        path: '/retail/pricing-groups/inactive',
        actions,
      }
    ];
  }

  setupColumns() {
    const commonColumns = [
      {
        name: I18n.t('retail.pricingGroup.groupName'),
        dataId: 'name',
        width: '300px',
        headerAlign: 'left',
        dataAlign: 'left'
      },
      {
        name: I18n.t('retail.pricingGroup.category'),
        dataId: 'category',
        width: '300px',
        headerAlign: 'left',
        dataAlign: 'left'
      },
    ];
    const actionColumn = {
      hidden: false,
      dataSort: false,
      permissions: [p.manage_pricing_groups],
      columnClassName: 'actions-column',
      width: '170px',
    };
    this.activeColumns = [
      ...commonColumns,
      {
        ...actionColumn,
        formatter: SingleActionColumn({
          permissions: [p.manage_pricing_groups],
          label: 'retail.pricingGroup.inactivate.title',
          action: (e, row) => this.showConfirmation(row)
        }),
      }
    ];

    this.inactiveColumns = [
      ...commonColumns,
      {
        ...actionColumn,
        formatter: SingleActionColumn({
          permissions: [p.manage_pricing_groups],
          label: 'retail.pricingGroup.activate.title',
          action: (e, row) => this.activateGroup(row)
        }),
      }
    ];
  }

  componentWillMount() {
    this.props.actions.getUnpaginatedData('/api/categories', dataNames.categories);
    this.loadGroups();
  }

  componentWillUnmount() {
    this.props.actions.unsetData(dataNames.pricingGroups);
  }

  createGroup() {
    this.props.actions.push('/retail/pricing-groups/create');
  }

  editGroup() {
    const {selectedPricingGroupData} = this.props;
    this.props.actions.push(`/retail/pricing-groups/modify/${selectedPricingGroupData[0]}`);
  }

  handleSelect(isSelected, rows) {
    this.props.actions.handleComplexSelectRow(rows, dataNames.pricingGroupData, 'set');
  }

  loadGroups() {
    this.setState({loading: true});
    this.props.actions.handleComplexSelectRow([], dataNames.pricingGroupData, 'clear');
    this.props.actions.unsetData(dataNames.pricingGroups);
    const messages = {failed: 'retail.pricingGroup.loadGroupsFailed'};
    const active = this.state.activeTab === tabStatuses.inactive ? 0 : 1;
    this.props.actions.getUnpaginatedData('/api/pricing_groups', dataNames.pricingGroups, messages, {active})
      .then(() => {
        this.setState({loading: false});
      }).catch(() => {
        this.setState({loading: false});
      });
  }

  switchTab(activeTab) {
    const tab = this.tabs.find(tab => tab.eventKey === activeTab) || this.tabs[0];
    this.setState({activeTab}, this.loadGroups);
    this.props.actions.push(tab.path);
  }

  activateGroup(group) {
    event.stopPropagation();
    if (!group) return;
    this.setGroupActivity(group.id, 1);
  }

  inactivateGroup() {
    event.stopPropagation();
    const group = this.state.confirmationModal.group;
    if (!group) return;
    this.setGroupActivity(group.id, 0);
  }

  setGroupActivity(id, active) {
    this.setState({loading: true});
    const messages = {
      success: active ? I18n.t('retail.pricingGroup.activate.success') : I18n.t('retail.pricingGroup.inactivate.success'),
      fail: active ? I18n.t('retail.pricingGroup.activate.failed') : I18n.t('retail.pricingGroup.inactivate.failed'),
    };
    this.props.actions.putData('/api/pricing_groups/' + id, {active}, dataNames.pricingGroups, messages)
      .then(() => {this.loadGroups();})
      .catch(() => {this.loadGroups();});
  }

  showConfirmation(group) {
    this.setState({confirmationModal: {show: true, group}});
  }

  hideConfirmation() {
    this.setState({confirmationModal: {show: false, group: null}});
  }

  render() {
    const {pricingGroups, selectedPricingGroupData} = this.props;
    const {activeTab, loading, confirmationModal} = this.state;
    const modalGroupName = confirmationModal.group ? confirmationModal.group.name : '';
    const modalTitle = I18n.t('retail.pricingGroup.inactivate.modalTitle', {modalGroupName});
    return (
      <div className='pricing-group-listing'>
        <PageTitle primaryText={I18n.t('retail.pricingGroup.title')}/>
        <InProgressOverlay isActive={loading} message={I18n.t('retail.pricingGroup.loading')}/>
        <ModalWrapper
          Component={false}
          title={modalTitle}
          headerClass='bg-info-dark'
          onHide={this.hideConfirmation}
          showModal={confirmationModal.show}
          okayButton={{show: true, text: 'general.yes', onClick: this.inactivateGroup}}
          cancelButton={{show: true, text: 'general.no', onClick: this.hideConfirmation}}
          dialogClassName='modal-sm'
          version={2}
        >
          <div>{I18n.t('retail.pricingGroup.inactivate.modalConfirmation')}</div>
        </ModalWrapper>
        <TablePageWrapper
          ref={this.ref}
          settingKey='retail-pricing-groups'
          activeTab={activeTab}
          switchTab={this.switchTab}
          tabs={this.tabs}
          columns = {activeTab === tabStatuses.active ? this.activeColumns : this.inactiveColumns}
          data = {pricingGroups}
          selectedRows = {selectedPricingGroupData}
          handleSelect = {this.handleSelect}
          hideExport = {true}
          hideScanSearch = {true}
          hideTableControls={true}
          external={false}
          bstProps={{
            ignoreSinglePage: true,
            selectRow: {
              mode: 'radio'
            }
          }}
          className='retail-group-table'
        />
      </div>
    );
  }
}

PricingGroupListingPage.propTypes = {
  actions: PropTypes.shape({
    handleComplexSelectRow: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    deleteData: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired
  }),
  pricingGroups: PropTypes.array.isRequired,
  selectedPricingGroupData: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  const pricingGroups = getPricingGroupsListingData(state);
  const selectedPricingGroupData = getSelectedGroupsData(state);
  return {
    pricingGroups,
    selectedPricingGroupData
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {getUnpaginatedData, deleteData, handleComplexSelectRow, selectedDataActions, push, unsetData, putData};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PricingGroupListingPage);
