import React from 'react';
import {FieldArray} from 'redux-form';
import PropTypes from 'prop-types';
import LevelThreeLocations from './LevelThreeLocations'; // eslint-disable-line

export default class LevelThreeArray extends React.PureComponent {

  render() {
    const {
      showIntegratedLocationName,
      disableLocationEdit,
      integratedLocationNames,
      type,
      integrationMapping,
      onDelete,
      isLeaf,
      isCanadaFacility,
      printLabel,
      currentFacility,
      locationName,
      isCultivation,
    } = this.props;

    return (
      <FieldArray
        name={`${locationName}.child_locations`}
        component={LevelThreeLocations}
        props={{
          type,
          printLabel,
          integratedLocationNames,
          showIntegratedLocationName,
          isCanadaFacility,
          currentFacility,
          isLeaf,
          integrationMapping,
          disableLocationEdit,
          isCultivation,
          onDelete
        }}
      />);
  }

}

LevelThreeArray.propTypes = {
  locationName: PropTypes.string.isRequired,
  printLabel: PropTypes.func,
  type: PropTypes.oneOf(['storage', 'sale']).isRequired,
  integratedLocationNames: PropTypes.array,
  showIntegratedLocationName: PropTypes.bool,
  isCanadaFacility: PropTypes.bool,
  currentFacility: PropTypes.object.isRequired,
  isLeaf: PropTypes.bool,
  integrationMapping: PropTypes.array,
  disableLocationEdit: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  isCultivation: PropTypes.bool,
};