import {createSelector} from 'reselect';
import orderBy from 'lodash.orderby';
import get from 'lodash.get';

import * as dataNames from '../constants/dataNames';
import * as itemNames from '../constants/itemNames';
import {getPricingWeights, getPricingWeightsByUom} from './pricingWeightsSelectors';
import {getIntegrationState} from './integration/integrationSelectors';
import {getIsHempFacility} from './coreSettingsSelectors';
import {convertFromBase} from '../util/uomHelpers';
import {GR} from '../constants/uoms';

export const getPricingGroups = state => state[dataNames.pricingGroups];
export const getCurrentPricingGroup = state => state[itemNames.pricingGroup];
const getCategories = state => state[dataNames.categories];

export const getCreatePricingGroupInitialValues = createSelector(
  [getPricingWeights, getIsHempFacility],
  (pricingWeights, isHempFacility) => {
    const initialValues = {};

    if (!isHempFacility) {
      const defaultUom = GR;

      initialValues.uom = defaultUom;
      initialValues.pricing_weights = getPricingWeightsByUom(defaultUom, pricingWeights);
    }

    return initialValues;
  }
);

export const getModifyPricingGroupInitialValues = createSelector(
  [getCurrentPricingGroup, getPricingWeights, getCurrentPricingGroup],
  (currentPricingGroup, pricingWeights, pricingGroup) => {
    if (!pricingWeights.length || !Object.keys(currentPricingGroup).length) {
      return;
    }
    const weights = currentPricingGroup.pricing_weights || [];
    const pricing_weights = orderBy(weights.map((weight) => {
      const pricingWeight = pricingWeights.find(pricingWeight => pricingWeight.id === weight.pricing_weight_id) || {name: '', weight_base: 0};

      return {
        pricing_weight_id: weight.pricing_weight_id,
        weight: convertFromBase(pricingWeight.weight_base, pricingWeight.uom_display),
        weight_base: pricingWeight.weight_base,
        uom_display: pricingWeight.uom_display,
        default_price: Number(weight.default_price),
      };
    }), 'weight_base', 'asc');

    return {
      uom: get(pricingGroup, 'uom', null),
      pricing_weights,
      pricing_class_id: currentPricingGroup.pricing_class_id,
      name: currentPricingGroup.name,
      category_id: currentPricingGroup.category_id
    };
  }
);

export const getPricingGroupSorted = createSelector(
  [getPricingGroups],
  (pricingGroups) => {
    pricingGroups.sort((a, b) => a.name && b.name && a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    return pricingGroups;
  }
);

export const getCategoriesForPricingGroups = createSelector([getCategories, getIntegrationState], (categories, integrationState) =>
  categories.filter(category => ['HARVESTS', 'INFUSED', 'EDIBLE', 'CARTPENS'].indexOf(category.category_code.toUpperCase()) === -1
    && (integrationState.isCanada || category.category_code.toUpperCase() !== 'MARIJUANA'))
);
