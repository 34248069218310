import React from 'react';
import InternationalDateTimeStatic from '../../../common/form/InternationalDateTimeStatic';
import InternationalCurrencyStatic from '../../../common/form/InternationalCurrencyStatic';

export const cashOnHandReportTabs = [
  {
    id: 'default',
    eventKey: 'default',
    title: 'retail.cashOnHandReport.cashOnHand',
    actions: []
  }
];

export const cashOnHandReportColumns = [
  {
    name: 'retail.cashOnHandReport.columns.facility',
    dataId:'facilityName',
    csvFormatter: (cell) => cell,
    width: '200px'
  },
  {
    name: 'retail.cashOnHandReport.columns.registerId',
    dataId:'id',
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.cashOnHandReport.columns.registerName',
    dataId:'name',
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.cashOnHandReport.columns.registerActive',
    dataId:'active',
    formatter: (cell, row) => (cell ? 'Yes' : 'No'),
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.cashOnHandReport.columns.registerType',
    dataId:'type',
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.cashOnHandReport.columns.currentCash',
    dataId:'current_level',
    /* eslint-disable react/no-multi-comp */
    formatter: cell => (<InternationalCurrencyStatic>{cell}</InternationalCurrencyStatic>),
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.cashOnHandReport.columns.address',
    dataId:'address',
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.cashOnHandReport.columns.city',
    dataId:'city',
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.cashOnHandReport.columns.state',
    dataId:'province',
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.cashOnHandReport.columns.postalCode',
    dataId:'postalCode',
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.cashOnHandReport.columns.registerCreatedDate',
    dataId:'created_at',
    /* eslint-disable react/no-multi-comp */
    formatter: (cell) => (<InternationalDateTimeStatic useSystemDate={false} displayTime={false}>{cell}</InternationalDateTimeStatic>),
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.transactionReport.columns.registerLastUpdate',
    dataId:'updated_at',
    /* eslint-disable react/no-multi-comp */
    formatter: (cell) => (<InternationalDateTimeStatic useSystemDate={false} displayTime={false}>{cell}</InternationalDateTimeStatic>),
    csvFormatter: (cell) => cell || '',
  }
];
