import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {Row, Col} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import {push} from 'react-router-redux';
import {Link} from 'react-router';

import {clearMessage, addMessage} from '../../../actions/systemActions';
import Spinner from '../../common/Spinner';
import MessageBlock from '../../common/MessageBlock';
import {postItem} from '../../../actions/apiActions';
import ResetPasswordForm  from './ResetPasswordForm';
import ResetPasswordHeader from './ResetPasswordHeader';
import * as messageTypes from '../../../constants/messageTypes';

export class ResetPasswordPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
  }

  onSubmit (user) {
    this.props.actions.postItem('/api/password/email',user, null,
    {success: 'user.password.emailSuccess' ,failed: 'user.password.emailFailed'},
    null, () => {this.props.actions.addMessage(messageTypes.success, 'user.password.emailSuccess');});
  }

  onDismiss(id) {
    const message = this.props.messages.find(message => id === message.id);
    this.props.actions.clearMessage(id);
    if(message && message.messageType === messageTypes.success){
      this.props.actions.push('/login');
    }
  }

  render () {
    return (
      <Row>
        <Col md={{span: 6, offset: 3}} xs={{span: 8, offset: 2}}>
          <ResetPasswordHeader />
          <Row>
          <MessageBlock style={{borderTop: '10px'}} messages={this.props.messages} dismiss={this.onDismiss} timeout={5000} />

            <ResetPasswordForm
              onSubmit = {this.onSubmit}
            />
          <br/>
          <span>{I18n.t('user.password.resetPasswordText') + ' | '}<Link to='/login'>{I18n.t('user.password.returnToLogin')}</Link></span>
          </Row>
          <Spinner active={this.props.spinner > 0}/>
        </Col>
      </Row>
    );
  }
}

ResetPasswordPage.propTypes = {
  actions: PropTypes.object.isRequired,
  messages: PropTypes.array.isRequired,
  spinner: PropTypes.number
};

function mapStateToProps(state){
  return {
    messages: state.messages || [],
    spinner: state.spinner
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({postItem, push, clearMessage, addMessage}, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (ResetPasswordPage);
