import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import {Col, Row,} from 'react-bootstrap';

import FieldArrayIncrement from '../../common/form/FieldArrayIncrement';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import InternationalCurrencyInput from '../../common/form/InternationalCurrencyInput';

const VendorsFieldArray = ({vendorOptions, values, activeUom, uom, fields}) => {
  return (
    <div className='vendors'>
      {fields.map((vendor, index) => (
        <Row key={index}>
          <Col md={4}>
            <Field name={`${vendor}.partner_id`} component={ReactSelectInput} props={{
              label: I18n.t('products.form.vendor'),
              options: vendorOptions,
              textKey: 'name',
              valueKey: 'id'
            }}/>
          </Col>
          <Col md={4}>
            <InternationalCurrencyInput
              name={`${vendor}.default_unit_cost`}
              props={{
                fractionPartSize: 2,
                label: I18n.t('products.form.costPer') + ` ${uom || '<UOM>'}`,
              }}/>
          </Col>
        </Row>
      ))}
      <FieldArrayIncrement fields={fields} showDecrement={true} label='products.form.addVendor' className='float-right'/>
    </div>
  );
};

VendorsFieldArray.propTypes = {
  vendorOptions: PropTypes.array.isRequired,
  values: PropTypes.array,
  activeUom: PropTypes.string,
  uom: PropTypes.string,
  fields: PropTypes.object.isRequired
};

export default VendorsFieldArray;
