import React from 'react';
import { I18n } from 'react-redux-i18n';
import {Field} from 'redux-form';
import {Row, Col} from 'react-bootstrap';
import PropTypes from 'prop-types';

import AccordionPanel from '../../common/AccordionPanel';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import NumericInput from '../../common/form/NumericInput';
import HoverTooltip from '../../common/HoverTooltip';
import {UOM_VALUE_PRECISION} from '../../../constants/uoms';
import InternationalCurrencyInput from '../../common/form/InternationalCurrencyInput';

const PurchasingInformationSection = (props) => {
  const {vendorOptions, weight_type, showUomError, isPurchasingItem, products} = props;
  const uomErrorText = (showUomError ? I18n.t('products.massModify.errors.diff_uoms', {field: I18n.t('products.form.apiBuffer')}) : '');
  const shouldShowItemAvailability = (isPurchasingItem === 0) || products && products.some(product => !product.is_purchasing_item);
  return (
    <AccordionPanel title={'products.massModify.purchasingInformationTitle'}>
      <span id={'purchasing_section'}/>
      <Row>
        <Col md={3} className='field-width-100'>
          <Field name='purchasing_attributes.par_level' component={NumericInput} props={{
            label: I18n.t('products.form.parLevel'),
            step: 1 / Math.pow(10, UOM_VALUE_PRECISION),
            fractionPartSize: UOM_VALUE_PRECISION,
            rightAddon: shouldShowItemAvailability ? <HoverTooltip text={I18n.t('products.massModify.errors.we_purchase_this_item_availability', {field: I18n.t('products.form.parLevel')})}/> : null
          }}/>
        </Col>
        <Col md={3} className='field-width-100'>
          <Field name='purchasing_attributes.auto_reorder_qty' component={NumericInput} props={{
            label: I18n.t('products.form.autoReorderQuantity'),
            rightAddon: shouldShowItemAvailability ? <HoverTooltip text={I18n.t('products.massModify.errors.we_purchase_this_item_availability', {field: I18n.t('products.form.autoReorderQuantity')})}/> : null
          }}/>
        </Col>
        <Col md={3}>
          <Field name='vendors[0].partner_id' component={ReactSelectInput} props={{
            label: I18n.t('products.form.vendor'),
            options: vendorOptions,
            textKey: 'name',
            valueKey: 'id',
            rightAddon: shouldShowItemAvailability ? <HoverTooltip text={I18n.t('products.massModify.errors.we_purchase_this_item_availability', {field: I18n.t('products.form.vendor')})}/> : null
          }}/>
        </Col>
        <Col md={3}>
          <InternationalCurrencyInput
            name='vendors[0].default_unit_cost'
            props={{
              fractionPartSize: 2,
              label: I18n.t('products.form.costPer') + ' EA',
              rightAddon: shouldShowItemAvailability ? <HoverTooltip text={I18n.t('products.massModify.errors.we_purchase_this_item_availability', {field: I18n.t('products.form.costPerUnit')})}/> : null
            }}/>
        </Col>
      </Row>
      <Row>
        <Col md={3} className='field-width-100'>
          <Field name='purchasing_attributes.buffer' component={NumericInput} props={{
            label: I18n.t('products.form.apiBuffer'),
            fractionPartSize: weight_type === 'weight' ? UOM_VALUE_PRECISION : 0,
            type: null,
            disabled: showUomError,
            rightAddon: showUomError ? <HoverTooltip text={uomErrorText} id={'buffer_tooltip'}/> : shouldShowItemAvailability ? <HoverTooltip text={I18n.t('products.massModify.errors.we_purchase_this_item_availability', {field: I18n.t('products.form.costPerUnit')})}/> : null,
          }}/>
          </Col>
      </Row>
    </AccordionPanel>
  );
};

PurchasingInformationSection.propTypes = {
  vendorOptions: PropTypes.array.isRequired,
  weight_type: PropTypes.string,
  showUomError: PropTypes.bool.isRequired,
  isPurchasingItem: PropTypes.int,
  products: PropTypes.array.isRequired
};

export default PurchasingInformationSection;
