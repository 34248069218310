import {reduxForm} from 'redux-form';

import IngredientsForm from './IngredientsForm';
import {validate} from './validate';

const IngredientsFormWrapper = reduxForm({
  validate,
  enableReinitialize: true,
})(IngredientsForm);

export default IngredientsFormWrapper;
