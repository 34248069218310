import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import {push} from 'react-router-redux';
import {Image} from 'react-bootstrap';

import {deleteData, getUnpaginatedData, putItem} from '../../actions/apiActions';
import {clearSelectedDataAction} from '../../actions/selectedDataActions';
import * as dataNames  from '../../constants/dataNames';
import * as itemNames  from '../../constants/itemNames';

import * as statuses from '../../constants/orderStatuses';
import * as p from '../../constants/permissions';

import {driversFullNameFormation} from '../../selectors/driversSelectors';

import PageTitle from '../common/PageTitle';
import TablePageWrapper from '../common/grid/TablePageWrapper';
import SingleActionColumn from '../common/grid/columns/SingleActionColumn';

export class DriversListingPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.addDriver = this.addDriver.bind(this);
    this.switchTab = this.switchTab.bind(this);
    this.editDriver = this.editDriver.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.inactivateDriver = this.inactivateDriver.bind(this);
    this.getDataForActiveTab = this.getDataForActiveTab.bind(this);
    this.ref = React.createRef();
    const columns = [
      {
        name: 'cultivation.drivers.table.photo',
        dataId:'src',
        width: '100px',
        dataSort: false,
        csvFormatter: (cell, row) => { return I18n.t('cultivation.drivers.table.image'); },
        formatter: (cell, row) => {
          return row.is_licensed_transporter ? '' : (<Image className='driver-image' src={cell}/>);
        }
      },
      {
        name: 'cultivation.drivers.table.name',
        dataId: 'full_name',
        width: '250px',
      },
      {
        name: 'cultivation.drivers.table.driversLicense',
        dataId: 'drivers_license_number',
        width: '300px'
      },
      {
        name: 'cultivation.drivers.table.stateComplianceLicense',
        dataId: 'state_compliance_number',
        width: '300px'
      },
      {
        name: 'cultivation.drivers.table.phoneNumber',
        dataId: 'phone_1',
        width: '300px'
      }
    ];
    const activeColumns = [
      ...columns,
      {
        hidden: false,
        dataSort: false,
        permissions: [p.manage_drivers],
        csvFormatter: (cell) => { return I18n.t('cultivation.drivers.actions.inactivateDriver'); },
        formatter: SingleActionColumn({
          permissions: [p.manage_drivers],
          label: 'cultivation.drivers.actions.inactivateDriver',
          action: (e, row) => this.inactivateDriver(e, row)
        }),
        columnClassName: 'actions-column',
        width: '170px',
      }
    ];
    const inactiveColumns = [
      ...columns,
      {
        hidden: false,
        dataSort: false,
        permissions: [p.manage_drivers],
        csvFormatter: (cell) => { return I18n.t('cultivation.drivers.actions.activateDriver'); },
        formatter: SingleActionColumn({
          permissions: [p.manage_drivers],
          label: 'cultivation.drivers.actions.activateDriver',
          action: (e, row) => this.inactivateDriver(e, row)
        }),
        columnClassName: 'actions-column',
        width: '170px',
      }
    ];
    const tabs = [
      {
        id: 'activeTab',
        eventKey: statuses.activeDriver,
        title: 'cultivation.drivers.activeDrivers',
        path: '/drivers/active',
        actions: [
          {
            id: 'addDriver',
            func: this.addDriver,
            text: 'cultivation.drivers.actions.addDriver',
            glyph: 'plus',
            requireSelect: false
          },
          {
            id: 'editDriver',
            func: this.editDriver,
            text: 'cultivation.drivers.actions.editDriver',
            glyph: 'edit',
            requireSelect: true
          }
        ]
      },
      {
        id: 'inactiveTab',
        eventKey: statuses.inactiveDriver,
        title: 'cultivation.drivers.inActiveDrivers',
        path: '/drivers/inactive',
        actions: [
          {
            id: 'addDriver',
            func: this.addDriver,
            text: 'cultivation.drivers.actions.addDriver',
            glyph: 'plus',
            requireSelect: false
          },
          {
            id: 'editDriver',
            func: this.editDriver,
            text: 'cultivation.drivers.actions.editDriver',
            glyph: 'edit',
            requireSelect: true
          }
        ]
      }
    ];
    this.state = {
      activeColumns,
      inactiveColumns,
      selectedDrivers: [],
      activeTab: props.params.status === 'inactive' ? statuses.inactiveDriver : statuses.activeDriver,
      tabs
    };
  }

  componentWillMount() {
    this.props.actions.getUnpaginatedData('/api/drivers/details', dataNames.drivers);
  }

  addDriver() {
    this.props.actions.push('/driver/create');
  }

  editDriver() {
    this.props.actions.push(`/drivers/modify/${this.state.selectedDrivers[0]}`);
  }

  handleSelect(isSelected, rows){
    const ids = rows.map(row => row.id);
    if(isSelected){
      this.setState({selectedDrivers: ids});
    }else {
      this.setState({selectedDrivers: []});
    }
  }

  inactivateDriver(event, data) {
    event.stopPropagation();
    this.props.actions.putItem(
      `/api/drivers/${data.id}`,
      {
        ...data,
        active: data.active === 1 ? 0 : 1
      },
      itemNames.driver,
      {
        success: data.active === 1 ? I18n.t('cultivation.drivers.inactive.success') : I18n.t('cultivation.drivers.active.success'),
        fail: data.active === 1 ? I18n.t('cultivation.drivers.inactive.fail') : I18n.t('cultivation.drivers.active.fail')
      },
      null,
      () => {
        this.props.actions.getUnpaginatedData('/api/drivers/details', dataNames.drivers);
      }
    );
  }

  switchTab(activeTab) {
    const tab = this.state.tabs.find(tab => tab.eventKey === activeTab) || this.state.tabs[0];
    this.setState({activeTab, selectedDrivers: []});
    this.props.actions.push(tab.path);
  }

  getDataForActiveTab(activeTab) {
    const { drivers } = this.props;

    return drivers.filter((driver) => activeTab === statuses.activeDriver
      ? driver.active === 1
      : driver.active === 0
    );
  }

  render() {
    const {activeColumns, inactiveColumns, tabs, activeTab, selectedDrivers} = this.state;

    return (
      <div className='drivers-listing'>
        <PageTitle primaryText={I18n.t('cultivation.drivers.listingTitle')}/>
        <TablePageWrapper
          ref={this.ref}
          settingKey='drivers'
          columns={activeTab === statuses.activeDriver ? activeColumns : inactiveColumns}
          data={this.getDataForActiveTab(activeTab)}
          selectedRows={selectedDrivers}
          handleSelect={this.handleSelect}
          hideExport={true}
          hideScanSearch={true}
          className='retail-group-table'
          activeTab={activeTab}
          tabs={tabs}
          switchTab={this.switchTab}

          bstProps={{
            selectRow: {
              mode: 'radio',
              clickToSelect: false,
              selected: selectedDrivers,
              onSelect: (row, isSelected) => this.handleSelect(isSelected, [row])
            },
            options: {
              hideSizePerPage: true,
            }
          }}
        />
      </div>
    );
  }
}

DriversListingPage.propTypes = {
  drivers: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    getUnpaginatedData: PropTypes.func,
    deleteData: PropTypes.func,
    push: PropTypes.func,
    putItem: PropTypes.func,
    clearSelectedDataAction: PropTypes.func,
  }).isRequired,
  params:PropTypes.shape({
    status: PropTypes.string
  }),
};

function mapStateToProps(state) {
  return {
    drivers : driversFullNameFormation(state)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {getUnpaginatedData, clearSelectedDataAction, deleteData, putItem, push};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DriversListingPage);
