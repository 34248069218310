import {isFeatureEnabled} from '../selectors/featureToggles';
import {getUserHasOneOfPermissionsFunction} from '../selectors/usersSelectors';
import {getIntegrationState} from '../selectors/integration/integrationSelectors';
import * as itemNames from '../constants/itemNames';

/**
 * Accepts a function that expects isFeatureEnabled selector as its only
 * argument to apply feature toggles within the function.
 * @param getTabs
 * @returns {function(*, *): *}
 */
export const getEnabledTabs = (getTabs) => { //eslint-disable-line
  return (dispatch, getState) => {
    const state = getState();
    const featureIsEnabled = (feature) => isFeatureEnabled(state)(feature);
    const userHasPermission = getUserHasOneOfPermissionsFunction(state);
    const integrations = getIntegrationState(state);
    return getTabs(featureIsEnabled, userHasPermission, state[itemNames.facility], integrations);
  };
};
