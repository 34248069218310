
import * as types from '../../constants/actionTypes';
import initialState from '../../constants/initialState';

export default function itemsReducer (state = initialState.cart.items, action){
  switch (action.type) {
  case types.SET_ITEMS:
    return [...action.items];
  case types.REMOVE_ITEM:
    return state.filter(item => {
      return item.id !== action.itemId;
    });
  case types.ADD_ITEM:
    return [...state, action.item];
  case types.SET_ITEM_PRICING_WEIGHT_ID:
    return state.map(item => {
      return item.id === action.itemId
        ? Object.assign({}, item, {pricingWeightId: action.pricingWeightId})
        : item;
    });
  case types.SET_ITEM_PACKAGE_ID:
    return state.map(item => {
      return item.id === action.itemId
        ? Object.assign({}, item, {packageId: action.packageId})
        : item;
    });
  case types.SET_ITEM_QUANTITY:
    return state.map(item => {
      return item.id === action.itemId
        ? Object.assign({}, item, {quantity: action.quantity})
        : item;
    });
  default:
    return state;
  }
}
