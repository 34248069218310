import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Button, CardDeck, Col, Row} from 'react-bootstrap';
import {pick} from 'lodash';
import SettingsPanel from './SettingsPanel';

const SettingPanels = (props) => {

  const form = pick(props, 'handleSubmit', 'change', 'valid', 'pristine', 'submitting', 'touchedFormFields');

  return (
    <Row>
      <form onSubmit={form.handleSubmit}>
        <CardDeck>
          {props.panels.map((panel, index) => {
            return <SettingsPanel title={panel.type} settings={panel.settings} key={index} {...form} />;
          })}
        </CardDeck>
        <Col xs={12}>
          <hr />
          <div className='float-right'>
            <Button variant='primary' type='submit' style={{marginRight: '5px'}} disabled={form.submitting || form.pristine || !form.valid}
              onClick={() => {
                form.change('afterSubmit', 'stay');
              }}
            >
              {I18n.t('common.form.saveAndStay')}
            </Button>

            <Button variant='primary' type='submit' disabled={form.submitting || form.pristine || !form.valid}>
              {I18n.t('common.form.saveAndExit')}
            </Button>
          </div>
        </Col>
      </form>
    </Row>
  );

};

SettingPanels.defaultProps = {
  touchedFormFields: {},
};

SettingPanels.propTypes = {
  panels: PropTypes.array,
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  touchedFormFields: PropTypes.object,
};

export default SettingPanels;
