import get from 'lodash.get';
import React from 'react';
import PropTypes from 'prop-types';
import SingleFieldInfo from '../common/SingleFieldInfo';


const status = 'foreign_materials_status';

const ForeignMaterials = ({row, dimensions, editable, disabled, isIsolocity, isolocityTestResult, requiredDimensions, displayCompletionStatus, isLabApplyProfiles}) => (
  <SingleFieldInfo
    row={row || {}}
    status={status}
    columns={get(dimensions, 'foreign_materials', [])}
    requiredDimensions={requiredDimensions}
    editable={editable}
    disabled={disabled}
    isIsolocity={isIsolocity}
    isolocityStatus={get(isolocityTestResult, `inspection.sub_statuses.${status}`, 'In Progress')}
    displayCompletionStatus={displayCompletionStatus}
    isLabApplyProfiles={isLabApplyProfiles}
  />
);

ForeignMaterials.propTypes = {
  row: PropTypes.object,
  dimensions: PropTypes.object,
  editable: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  isIsolocity: PropTypes.bool,
  isolocityTestResult: PropTypes.object,
  requiredDimensions: PropTypes.array,
  displayCompletionStatus: PropTypes.bool,
  isLabApplyProfiles: PropTypes.bool,
};

ForeignMaterials.defaultProps = {
  disabled: false,
};

export default ForeignMaterials;
