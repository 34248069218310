import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

const QzError = (props) => {

  return (
    <div>
      <h2 className='text-danger'>{props.title}</h2>
      <div style={{fontSize: '2rem'}}>{props.message}</div>
      <div style={{marginTop: '12px'}}>{I18n.t('common.whenCorrectedClickTryAgain')}</div>
      <div style={{textAlign: 'right'}}>
        <button className='btn btn-primary' onClick={(event) => { props.onTryAgain(event);}}>{I18n.t('common.tryAgain')}</button>
      </div>
    </div>
  );

};

QzError.propTypes = {
  onTryAgain: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
};

export default QzError;
