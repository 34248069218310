import React from 'react';
import { Field } from 'redux-form';
import { Col, Row, } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';

import TextAreaInput from '../../../../common/form/TextAreaInput';
import ReactSelectInput from '../../../../common/form/ReactSelectInput';
import SubmitSection from '../common/SubmitSection';


const Isolocity = () => {
  const activeOptions = [
    { text: 'Yes', value: true },
    { text: 'No', value: false },
  ];

  return (
    <div>
      <Row>
        <Col sm={12} md={8} lg={6}>
          <Field name='api_key' component={TextAreaInput} props={{
            label: I18n.t('facility.setup.thirdPartyIntegration.isolocity.apiKey'),
            isRequired: true,
            rows: 12,
          }} />
        </Col>
        <Col sm={6} md={4} lg={3}>
          <Field name='active' component={ReactSelectInput} props={{
            label: I18n.t('facility.setup.thirdPartyIntegration.isolocity.active'),
            isRequired: true,
            options: activeOptions,
            dataId: 'value'
          }} />
        </Col>
      </Row>
      <SubmitSection />
    </div>
  );
};


export default Isolocity;
