/* eslint-disable import/no-named-as-default */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { goBack } from 'react-router-redux';
import { I18n } from 'react-redux-i18n';
import get from 'lodash.get';
import * as itemNames from '../../../constants/itemNames';
import * as apiActions from '../../../actions/apiActions';
import FormWrapper from '../../common/form/FormWrapper';
import ModalWrapper from '../../common/ModalWrapper';
import PlantComplianceSettingsFormWrapper from './PlantComplianceSettingsFormWrapper';
import { getIntegrationState } from '../../../selectors/integration/integrationSelectors';
import {isFeatureEnabled} from '../../../selectors/featureToggles';


export const getCoercedBooleanSetting = (settings, key) => {
  const setting = get(settings, key, 0);
  if (typeof setting === 'boolean') {
    return setting;
  }
  const numericSetting = parseInt(setting);
  return numericSetting === 1;
};

export class PlantComplianceSettingsPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.redirect = this.redirect.bind(this);
    this.onConfirmPlantsAsGroups = this.onConfirmPlantsAsGroups.bind(this);
    this.onHideConfirmPlantsAsGroups = this.onHideConfirmPlantsAsGroups.bind(this);
    this.getComplianceSettings = this.getComplianceSettings.bind(this);
    this.state = {
      showConfirmPlantsAsGroups: false
    };
  }

  componentWillMount() {
    const loadSyncedSettings = false;
    this.getComplianceSettings(loadSyncedSettings);
  }

  getComplianceSettings(loadSyncedSettings = true) {
    this.props.actions.getItem(
      '/api/settings/plants',
      itemNames.complianceSettings,
      { failed: 'cultivation.complianceSettings.failed' },
      {
        ids: [
          'cult_plants_on_hand',
          'cult_plants_in_phases',
          'cult_distribute_harvest_weights',
          'cult_track_plants_as_groups',
          'cult_create_plant_from_inventory',
          'cult_add_test_results_to_plant_groups',
          'cult_enable_harvest_batch_entity_locks'
        ],
        get_overridable_in_configpack: true
      }
    );
    if(loadSyncedSettings) {
      // We are reading/updating this page through plants BUT the complete
      // cult settings are loaded on facility change and this keeps them synced
      this.props.actions.getItem('/api/cultivation/settings', itemNames.cultivationSettings);
    }
  }

  //User clicked Save on the confirmation modal
  onConfirmPlantsAsGroups() {
    const settings = Object.assign({}, this.state.submittedSettings);

    //We will zero out the settings about which the user was warned
    settings.plants_on_hand = 0;
    settings.plants_in_veg = 0;
    settings.plants_in_flower = 0;
    this.storeSettings(settings);
  }

  //User cancelled or closed the confirmation modal
  onHideConfirmPlantsAsGroups() {
    this.setState({ showConfirmPlantsAsGroups: false });
  }

  //Handle submission of form
  onSubmit(submittedSettings) {
    //If they are tracking plants as groups and any have any non zero values in the limits section, let them know
    //their selection of "plants as groups" will invalidate those settings
    if (submittedSettings.cult_track_plants_as_groups) {
      if (
        submittedSettings.plants_on_hand > 0 ||
        submittedSettings.plants_in_veg > 0 ||
        submittedSettings.plants_in_flower > 0
      ) {
        this.setState({ showConfirmPlantsAsGroups: true, submittedSettings });
        return;
      }
    }

    this.storeSettings(submittedSettings);
  }

  storeSettings(submittedSettings) {
    const settings = {
      settings: [
        {
          //id: 99,
          setting_key: 'cult_distribute_harvest_weights',
          scope: 'facility',
          scope_id: this.props.facility.id,
          value: submittedSettings.cult_distribute_harvest_weights
        },
        {
          setting_key: 'cult_track_plants_as_groups',
          scope: 'facility',
          scope_id: this.props.facility.id,
          value: submittedSettings.cult_track_plants_as_groups
        },
        {
          setting_key: 'cult_add_test_results_to_plant_groups',
          scope: 'facility',
          scope_id: this.props.facility.id,
          value: submittedSettings.cult_add_test_results_to_plant_groups
        },
        {
          setting_key: 'cult_create_plant_from_inventory',
          scope: 'facility',
          scope_id: this.props.facility.id,
          value: submittedSettings.cult_create_plant_from_inventory
        },
        {
          setting_key: 'cult_enable_harvest_batch_entity_locks',
          scope: 'facility',
          scope_id: this.props.facility.id,
          value: submittedSettings.cult_enable_harvest_batch_entity_locks
        }
      ]
    };

    if (!this.props.isColombia) {
      settings.settings.push({
        setting_key: 'cult_plants_on_hand',
        scope: 'facility',
        scope_id: this.props.facility.id,
        value: {
          plants_on_hand: submittedSettings.plants_on_hand,
          action_type: submittedSettings.action_type
        }
      });

      settings.settings.push({
        setting_key: 'cult_plants_in_phases',
        scope: 'facility',
        scope_id: this.props.facility.id,
        value: {
          plants_in_veg: submittedSettings.plants_in_veg,
          plants_in_flower: submittedSettings.plants_in_flower,
        }
      });
    }

    this.props.actions.postItem(
      '/api/settings/plants',
      settings,
      'noOp',
      { failed: 'cultivation.alertSettings.failed', success: 'cultivation.complianceSettings.success' },
      {},
      this.getComplianceSettings
    );
  }

  redirect() {
    this.props.actions.goBack();
  }

  render() {
    const {
      complianceSettings, integrationState, isPlantBatchTestingEnabled, isPendingStatesForHarvestBatchesEnabled
    } = this.props;
    const {isNormal, isMetrc, isBiotrack, isWaLeaf, isColombia, isHemp} = integrationState;

    const initialValues = {
      plants_on_hand: +get(complianceSettings, 'cult_plants_on_hand.value.plants_on_hand', 0),
      action_type: get(complianceSettings, 'cult_plants_on_hand.value.action_type', 'warn'),
      plants_in_veg: +get(complianceSettings, 'cult_plants_in_phases.value.plants_in_veg', 0),
      plants_in_flower: +get(complianceSettings, 'cult_plants_in_phases.value.plants_in_flower', 0),
      cult_distribute_harvest_weights: get(complianceSettings, 'cult_distribute_harvest_weights.value', true),
      cult_track_plants_as_groups: getCoercedBooleanSetting(complianceSettings, 'cult_track_plants_as_groups.value'),
      cult_create_plant_from_inventory: get(complianceSettings, 'cult_create_plant_from_inventory.value', false),
      cult_add_test_results_to_plant_groups: getCoercedBooleanSetting(complianceSettings, 'cult_add_test_results_to_plant_groups.value'),
      cult_enable_harvest_batch_entity_locks: get(complianceSettings, 'cult_enable_harvest_batch_entity_locks.value', false)
    };

    return (
      <div>
        <FormWrapper title='cultivation.complianceSettings.title' goBack={this.redirect}>
          <PlantComplianceSettingsFormWrapper
            initialValues={initialValues}
            onSubmit={this.onSubmit}
            isNormal={isNormal}
            isMetrc={isMetrc}
            isBiotrack={isBiotrack}
            isWaLeaf={isWaLeaf}
            isColombia={isColombia}
            isHemp={isHemp}
            isPlantBatchTestingEnabled={isPlantBatchTestingEnabled}
            isPendingStatesForHarvestBatchesEnabled={isPendingStatesForHarvestBatchesEnabled}
            cultCreatePlantFromInventoryIsOverridable={get(
              complianceSettings,
              'cult_create_plant_from_inventory.overrideable',
              true
            )}
            className='retail-sales-settings'
          />
        </FormWrapper>

        {/*This modal allows the user to confirm that they want to track plants as groups*/}
        <ModalWrapper
          Component={false}
          title='cultivation.complianceSettings.confirmPlantsAsGroups.title'
          headerClass='bg-info-dark'
          onHide={this.onHideConfirmPlantsAsGroups}
          showModal={this.state.showConfirmPlantsAsGroups}
          okayButton={{
            show: true,
            onClick: this.onConfirmPlantsAsGroups,
            text: 'cultivation.complianceSettings.confirmPlantsAsGroups.okayText'
          }}
          cancelButton={{
            show: true,
            onClick: this.onHideConfirmPlantsAsGroups,
            text: 'cultivation.complianceSettings.confirmPlantsAsGroups.cancelText'
          }}
          dialogClassName='modal-sm'
          version={2}
        >
          <div>{I18n.t('cultivation.complianceSettings.confirmPlantsAsGroups.noticeText', {})}</div>
        </ModalWrapper>
      </div>
    );
  }
}

PlantComplianceSettingsPage.propTypes = {
  actions: PropTypes.shape({
    getItem: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired
  }).isRequired,
  complianceSettings: PropTypes.object,
  facility: PropTypes.object.isRequired,
  isNormal: PropTypes.bool.isRequired,
  isMetrc: PropTypes.bool.isRequired,
  isBiotrack: PropTypes.bool.isRequired,
  isColombia: PropTypes.bool.isRequired,
  isWaLeaf: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    complianceSettings: state[itemNames.complianceSettings],
    facility: state[itemNames.facility],
    integrationState: getIntegrationState(state),
    isPlantBatchTestingEnabled: isFeatureEnabled(state)('feature_plant_batch_testing'),
    isPendingStatesForHarvestBatchesEnabled: isFeatureEnabled(state)('feature_pending_states_for_integrations_harvest_batches'),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, { goBack });
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlantComplianceSettingsPage);
