/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { I18n } from 'react-redux-i18n';
import { goBack } from 'react-router-redux';
import { change } from 'redux-form';
import get from 'lodash.get';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import * as messageTypes from '../../../constants/messageTypes';
import { unsetData, setData } from '../../../actions/dataActions';
import { addMessage } from '../../../actions/systemActions';
import {
  getDataByPost,
  getUnpaginatedData,
  ensureGetUnpaginatedData,
  postData,
  postItem,
  getItem,
  getData,
  putItem,
  getSearchData
} from '../../../actions/apiActions';
import { getLocations } from '../../../actions/locationActions';
import { fetchMetrcTrackingIdsForSelectInput } from '../../../actions/integrationActions';
import { showProductLookup } from '../../../actions/productLookupActions';
import { getModulesState } from '../../../selectors/modulesSelectors';
import { getSelectedProductIds } from '../../../selectors/finishedProductsSelectors';
import { isFeatureEnabled } from '../../../selectors/featureToggles';
import {
  getModifyPackagesInitialValues,
  getModifyPackagesPayload,
  isReservedInventoryPackage,
  modPackageFormHasGlobalValues,
  modPackageFormHasGlobalAdjustmentReason,
  modPackageFormHasGlobalIntegrationAdjustmentReason,
  modPackageFormHasAllIntegrationAdjustmentReasons,
  getModifyPackagesFlattenedLocations
} from '../../../selectors/inventoryItemsSelectors';
import { MODIFY_PACKAGES } from '../../../constants/forms';
import { getIntegrationAdjustmentReasonOptions } from '../../../selectors/integration/adjustmentReasonsSelectors';
import { getIntegrationState } from '../../../selectors/integration/integrationSelectors';
import showReverseToParent from '../../../selectors/inventory/behaviors/showReverseToParentSelector';
import FormWrapper from '../../common/form/FormWrapper';
import reduxMetrcIdAvailability from '../../common/form/redux-form/reduxMetrcIdAvailability';
import ModifyPackagesFormWrapper from './ModifyPackagesFormWrapper';
import LabelPrinter from '../../print-services/labels/LabelPrinter';
import InProgressOverlay from '../../common/InProgressOverlay';
import {
  getFormLots,
  getFormPackages,
  hasMedicatedPackages,
  hasPackageWithoutReason,
  hasWastePackages
} from '../../../selectors/forms/modifyPackageFormSelectors';
import ModalWrapper from '../../common/ModalWrapper';
import MetrcIdChangeConfirm from './MetrcIdChangeConfirm';
import { getBiotrackInvTypesWithTitles } from '../../../selectors/integration/biotrackSelectors';
import * as complianceSettings from '../../../selectors/complianceSettingsSelectors';
import { hasPackagesTags } from '../../../selectors/integrationSelectors';

export class ModifyPackagesPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.redirect = this.redirect.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.hidePrinter = this.hidePrinter.bind(this);
    this.showPrinter = this.showPrinter.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.filterProductLookup = this.filterProductLookup.bind(this);
    this.showModalMedical = this.showModalMedical.bind(this);
    this.hideModalMedical = this.hideModalMedical.bind(this);
    this.cancelModalMedical = this.cancelModalMedical.bind(this);
    this.isFailingTrackingRequirements = this.isFailingTrackingRequirements.bind(this);
    this.validateProductCanBeChanged = this.validateProductCanBeChanged.bind(this);

    this.state = {
      printerModalShow: false,
      printerUrl: '/api/labels/generate/inv_package_tag_all/for/many',
      printerPayload: {},
      printerHttpAction: 'POST',
      printerAfterSave: false,
      saved: false,
      package: false, // for printing a second time
      message: '', // in progress overlay
      loading: false,
      rowLevelAdjustmentReasonRequires: false,
      showModal: false, // confirmation modal for integration id change
      isZeroQty: false,
      showConfirmModal: false,
      formValues: {}, // values passed for confirmation mark
      payload: {},
      updateToggle: false,
      showModalMedicalState: false,
      productCanBeChanged: true,
    };
  }

  componentWillMount() {
    const {
      actions: { unsetData, getDataByPost, getUnpaginatedData, getLocations, ensureGetUnpaginatedData }
    } = this.props;

    unsetData(dataNames.inventoryItems);
    unsetData(dataNames.lots);
    unsetData(dataNames.partnerFacilities);

    this.setState({
      loading: true,
      saved: false,
      message: 'general.loading'
    });
    // Excludes unnecessary fields specifically external_ids which theoretically could be large by accident
    const fields = [
      'id',
      'organization_id',
      'item_number',
      'name',
      'display_name',
      'is_inventory_item',
      'is_sales_item',
      'is_purchasing_item',
      'is_manufactured_item',
      'category_id',
      'subcategory_id',
      'strain_id',
      'uom_type',
      'default_uom',
      'unit_weight_base',
      'unit_weight_uom_display',
      'medicated_weight_base',
      'medicated_weight_uom_display',
      'net_weight_base',
      'net_weight_uom_display',
      'created_at',
      'updated_at',
      'active',
      'strain_name',
      'strain_code',
      'dominance',
      'category_name',
      'category_display_name',
      'subcategory_display_name',
      'category_code',
      'subcategory_name',
      'subcategory_code',
      'is_ingredient',
      'is_medicated',
      'is_prepack',
      'prepack_weight_id',
      'item_master_parent_id',
      'lot_tracked'
    ];
    const filter = 'active:1 AND is_draft:0 AND is_ingredient:0';
    const solrParams = { sort: 'name asc, display_name asc', query: '', size: '100000', start: '0', filter, fields };
    const promises = [
      // Get itemMasters from solar instead of inventory service
      getSearchData('/api/search/item_masters', null, null, solrParams, (data) => {
        if (data.length === 0) return this.props.actions.addMessage(messageTypes.error, 'products.get.failed');
        // Shape solr response to match inventory response for item_masters
        const masters = data.map((master) => {
          master.inventory_attributes = {
            is_prepack: master.is_prepack,
            item_master_parent_id: master.item_master_parent_id,
            prepack_weight_id: master.prepack_weight_id,
            lot_tracked: master.lot_tracked
          };
          master.net_weight_base =
            master.net_weight_base !== undefined ? parseFloat(master.net_weight_base).toFixed(2) : '0.00';
          master.default_cost = master.default_cost !== undefined ? parseFloat(master.default_cost).toFixed(2) : '0.00';
          return master;
        });
        setData(masters, dataNames.itemMasters);
      }),
      getUnpaginatedData('/api/adjustment_reasons', dataNames.adjustmentReasons, {
        failed: 'adjustmentReasons.get.failed'
      }),
      getLocations()
        .then(() => {
          const { ids } = this.props;
          if (ids.length) {
            return getDataByPost(
              '/api/items/multiple',
              { ids: ids.map(Number) },
              dataNames.inventoryItems,
              { failed: 'products.get.failed' },
              { detailed: 1 }
            );
          }
        })
        .then(() => {
          if (this.props.inventoryItems.length) {
            if (this.props.integrationState.isWaLeaf) {
              const ids = this.props.inventoryItems
                .filter((item) => item.package_id !== null)
                .map((item) => item.package_id);
              if (ids.length) {
                getUnpaginatedData('/api/transfers/package/' + ids, dataNames.transfers, {}, {});
              }
            }
            return getDataByPost(
              '/api/item_masters/multiple',
              { ids: this.props.inventoryItems.map((item) => item.item_master_id) },
              dataNames.itemMasters
            );
          }
        })
    ];

    promises.push(
      getUnpaginatedData('/api/integration/adjustment_reasons', dataNames.integrationAdjustmentReasons, {
        failed: 'packages.modify.getIntegrationAdjustmentReasons.failed'
      })
    );

    // Integration settings are downloaded to see if Metrc intergation settings exist. If the Metrc settings exist,
    // then the METRC Adjustment Reason and Tracking ID inputs are displayed.
    promises.push(
      this.props.actions
        .getItem('/api/integration-settings', itemNames.integrationSettings, {
          failed: 'stateIntegratorSettings.get.failed'
        })
        .then(() => {
          const integrationPromises = [];
          if (this.props.integrationState.isMetrc) {
            integrationPromises.push(this.props.actions.fetchMetrcTrackingIdsForSelectInput());
          }
          return Promise.all(integrationPromises);
        })
    );

    if (this.props.showPhases) {
      promises.push(ensureGetUnpaginatedData('/api/phases', dataNames.phases, { failed: 'phases.get.failed' }));
    }

    Promise.all(promises)
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
      });

    this.props.actions.getItem('/api/compliance_settings', itemNames.complianceSettings);
    if (this.props.integrationState.isWaLeaf) {
      getUnpaginatedData('/api/users/current_facility', dataNames.users);
    }

    this.props.actions.getItem('/api/integration-settings', itemNames.integrationSettings, {
      failed: 'stateIntegratorSettings.get.failed'
    });

    if (this.props.integrationState.isColombia) {
      this.props.actions.getUnpaginatedData('/api/cupos?with_relations=modalities', dataNames.cupos);
    }
  }

  componentWillUnmount() {
    this.props.actions.unsetData(dataNames.inventoryItems);
  }

  redirect() {
    this.props.actions.goBack();
  }

  onSubmit(formValues) {
    if (formValues.on_hold && this.props.isReservedPackage) {
      return this.props.actions.addMessage(messageTypes.error, 'packages.modify.packageHasReservedAmountMessage');
    }

    if (this.isOverReservedQuantity(formValues.packages)) {
      this.setState({
        formValues: formValues,
        isZeroQty: formValues.packages.filter((item) => parseFloat(item.newQty) === 0).length > 0,
        showConfirmModal: true
      });
    } else if (!this.state.showConfirmModal) {
      this.saveData(formValues);
    }
  }

  isOverReservedQuantity(packages) {
    let overReservedQuantity = false;

    packages.map((item) => {
      if (
        (!isNaN(item.qty_reserved) && !overReservedQuantity && item.newQty * 1.0 < item.qty_reserved * 1.0) ||
        (isNaN(item.qty_reserved) && parseFloat(item.newQty) === 0)
      ) {
        overReservedQuantity = true;
      }
    });

    return overReservedQuantity;
  }

  /***
   * When a medical inventory item is attempted to be Modified and it does not have a tracking ID
   * or it has a needs activation status, do not proceed and display the message from the facility setting
   * Changing the tag_requested field is allowed
   *
   */
  isFailingTrackingRequirements() {
    // If compliance does not enforce tagging, no further validation required
    if (!get(this.props.allInvComplianceSettings, 'inv_packages_require_tracking_id', false)) {
      return false;
    }

    // Scour original values for non compliant items
    const someItemsAreNotCompliant = this.props.inventoryItems.some((item) => {
      return complianceSettings.isInvPackageTrackingNotInCompliance(item);
    });

    return someItemsAreNotCompliant;
  }

  saveData(formValues) {
    if (this.state.saved) {
      if (this.state.printerAfterSave) {
        this.doPrint(this.state.package);
      }
      return false;
    }

    const newState = { formValues };

    newState.message =
      this.props.inventoryItems.length === 1 ? 'packages.form.savingPackage' : 'packages.form.savingPackages';

    // Check to see if we are changing just the integration id and if so, get confirmation, else process as normal
    let requiresConfirmation = false;

    if (
      this.props.inventoryItems.length === 1 &&
      Array.isArray(formValues.packages) &&
      formValues.packages[0].integration_id_platform_correction &&
      !formValues.integration_id_platform_correction_confirmed
    ) {
      newState.showModal = true;
      requiresConfirmation = true;
    } else {
      newState.loading = true;
    }

    const setTrackingIdErrors = (trackingIds, fieldName, errorText) => {
      const errors = { packages: [] };
      trackingIds.forEach((trackingId, index) => {
        errors.packages[index] =
          trackingId.isUnavailable &&
          trackingId.initial_state_integration_tracking_id !== trackingId.state_integration_tracking_id
            ? { [fieldName]: errorText }
            : undefined;
      });
      return errors;
    };

    this.setState(newState);

    if (requiresConfirmation) return false; // Modal displayed for confirmation by state change above

    return this.props
      .validateTrackingIdsAvailability(formValues.packages, setTrackingIdErrors, {
        initialTrackingIds: this.props.trackingIds.map((trackingId) => trackingId.tag),
        checkInInitial: true
      })
      .then(() => {
        return this.props.actions
          .getItem('/api/customers/compliance_settings/validate_inventory', null, null, null, (response) => {
            const transacted_qty = formValues.packages.reduce((acc, item) => acc + parseInt(item.transacted_qty), 0);

            if (transacted_qty > 0 && response.messages && !response.messages.passed) {
              this.setState({ loading: false });
              return this.props.actions.addMessage(messageTypes.error, response.messages.error_message, true);
            }

            return this.props.actions
              .postData(
                '/api/items/multi_actions',
                getModifyPackagesPayload(formValues, this.props.timezone),
                null,
                { success: 'packages.modify.success', failed: 'packages.modify.failed' },
                {},
                (data) => {
                  this.setState({ saved: true, loading: false });
                  this.state.printerAfterSave ? this.doPrint(data) : this.redirect();
                }
              )
              .catch(() => {
                this.setState({ loading: false });
              });
          })
          .catch((e) => {
            this.setState({ loading: false });
            throw e;
          });
      });
  }

  validateProductCanBeChanged (packageData) {
    const {
      integrationState: {isMetrc},
      isMetrcStemHoldingsToggled
    } = this.props;

    if (!isMetrcStemHoldingsToggled || !isMetrc) {
      return Promise.resolve();
    }

    this.setState({
      productCanBeChanged: false
    });

    const data = {
      state_integration_tracking_id: packageData.state_integration_tracking_id,
      package_id: packageData.package_id
    };

    return this.props.actions.postItem(`/api/metrc/packages/${packageData.id}/can_change_product`, data)
      .then(() => {
        this.setState({
          productCanBeChanged: true
        });
      });
  }

  hidePrinter() {
    this.setState({ printerModalShow: false });
  }

  showPrinter() {
    if (!this.state.printerAfterSave) {
      this.setState({ printerAfterSave: true });
    }
  }

  doPrint(data) {
    const newState = Object.assign(
      {},
      {
        printerModalShow: true,
        payload: {
          ids: this.state.package
            ? this.state.package.completed_items.map((item) => item.id)
            : data.completed_items.map((item) => item.id)
        }
      },
      Object.assign({}, this.state.package ? {} : { package: data })
    );
    this.setState(newState);
  }

  hideModal() {
    this.setState({ showModal: false });
  }

  /**
   * filter ProductLookup results to match current item master type
   *
   * @param {Object[]} searchResults - Full item master search results
   * @return {Object[]} - Filtered results
   */
  filterProductLookup(searchResults) {
    const updatePkg = this.props.packages.find((pkg) => {
      return pkg.package_id == this.props.productLookup.referenceId;
    });
    const filteredResults = searchResults.filter((itemMaster) => itemMaster.itemType == updatePkg.itemType);
    return filteredResults;
  }

  showModalMedical(event) {
    if (!event.target.checked) {
      this.setState({
        showModalMedicalState: true,
        currentMedicalElement: event.target.name,
        currentValueMedical: event.target.checked
      });
    } else {
      return true;
    }
  }

  hideModalMedical() {
    this.setState({ showModalMedicalState: false });
  }

  cancelModalMedical(formValues) {
    this.setState({ showModalMedicalState: false });
    this.props.actions.change(MODIFY_PACKAGES, this.state.currentMedicalElement, !this.state.currentValueMedical);
  }

  render() {
    const {
      phases,
      lots,
      actions,
      packages,
      locations,
      showPhases,
      trackingIds,
      initialValues,
      productLookup,
      inventoryItems,
      inventoryTypes,
      hasGlobalValues,
      integrationState,
      adjustmentReasons,
      partnerFacilities,
      hasMedicatedPackages,
      hasPackageWithoutReason,
      hasWastePackages,
      isAllowNegativeInventory,
      hasGlobalAdjustmentReason,
      integrationAdjustmentReasons,
      hasAllIntegrationAdjustmentReasons,
      hasGlobalIntegrationAdjustmentReason,
      transfers,
      facilityUsers,
      allInvComplianceSettings,
      cupos,
      hasManufacturing,
      isMetrcStemHoldingsToggled,
      isMetrcCaDonationsToggled,
      requireOnHandProductExpireDate
    } = this.props;

    return (
      <div>
        <FormWrapper className='modify-packages-page' title='packages.modify.title' goBack={this.redirect}>
          <InProgressOverlay isActive={this.state.loading} message={this.state.message} translate={true} />
          <ModalWrapper
            Component={false}
            version={2}
            headerClass='bg-info-dark'
            dialogClassName='modal-sm'
            showModal={this.state.showConfirmModal}
            title='reconciliation.warning.title'
            cancelButton={{
              show: true,
              text: 'general.cancel',
              variant: 'default'
            }}
            onHide={() => {
              this.setState({ showConfirmModal: false });
            }}
            okayButton={{
              show: isAllowNegativeInventory || this.state.isZeroQty,
              text: I18n.t('general.continue'),
              variant: 'default',
              onClick: () => {
                this.setState({ showConfirmModal: false });
                this.saveData(this.state.formValues);
              }
            }}
          >
            <div>{I18n.t('reconciliation.warning.message')}</div>
          </ModalWrapper>

          <ModalWrapper
            dialogClassName='modal-sm'
            Component={MetrcIdChangeConfirm}
            formValues={this.state.formValues}
            onSubmit={this.onSubmit}
            onHide={this.hideModal}
            showModal={this.state.showModal}
            title='Confirm Tag Replacement'
          />

          <LabelPrinter
            showModal={this.state.printerModalShow}
            onHide={this.hidePrinter}
            labelTag='inv_package_tag_all'
            payload={this.state.payload}
          />

        <ModifyPackagesFormWrapper
          form={MODIFY_PACKAGES}
          hasValue={this.props.hasValue}
          onSubmit={this.onSubmit}
          saved={this.state.saved}
          showPrinter={this.showPrinter}
          adjustmentReasons={adjustmentReasons}
          packages={packages}
          locations={locations}
          phases={phases}
          initialValues={initialValues}
          showPhases={showPhases}
          integrationState={integrationState}
          integrationAdjustmentReasons={integrationAdjustmentReasons}
          trackingIds={trackingIds}
          showReverseInventoryOption={this.props.showReverseToParent}
          hasGlobalAdjustmentReason={hasGlobalAdjustmentReason}
          hasGlobalValues={hasGlobalValues}
          hasGlobalIntegrationAdjustmentReason={hasGlobalIntegrationAdjustmentReason}
          hasAllIntegrationAdjustmentReasons={hasAllIntegrationAdjustmentReasons}
          partnerFacilities={partnerFacilities}
          lots={lots}
          hasPackageWithoutReason={hasPackageWithoutReason}
          hasMedicatedPackages={hasMedicatedPackages}
          hasWastePackages={hasWastePackages}
          showProductLookup={actions.showProductLookup}
          productLookup={productLookup}
          isModalOpen={this.props.productLookup.isModalOpen}
          filterBy={this.filterProductLookup}
          inventoryItems={inventoryItems}
          hasPackagesTags = {hasPackagesTags}
          inventoryTypes={inventoryTypes}
          showModalMedical={this.showModalMedical}
          transfers={transfers}
          facilityUsers = {facilityUsers}
          isFailingTrackingRequirements={this.isFailingTrackingRequirements()}
          isFailingTrackingRequirementsMessage={get(allInvComplianceSettings, 'inv_packages_require_tracking_id_message', '')}
          complianceSettings={allInvComplianceSettings}
          cupos={cupos}
          hasManufacturing={hasManufacturing}
          validateProductCanBeChanged={this.validateProductCanBeChanged}
          productCanBeChanged={this.state.productCanBeChanged}
          isMetrcStemHoldingsToggled={isMetrcStemHoldingsToggled}
          isMetrcCaDonationsToggled={isMetrcCaDonationsToggled}
          requireOnHandProductExpireDate={requireOnHandProductExpireDate}
        />
        <ModalWrapper
          Component={false}
          version={2}
          formValues={this.state.formValues}
          onHide={this.hideModalMedical}
          showModal={this.state.showModalMedicalState}
          title='packages.modify.leafWa.modalTitle'
          dialogClassName='modal-sm'
          okayButton={{
            show: true,
            text: 'packages.modify.leafWa.okayText',
            variant: 'default',
            onClick: this.hideModalMedical
          }}
          cancelButton={{
            show: true,
            text: 'packages.modify.leafWa.cancelText',
            variant: 'default',
            onClick: this.cancelModalMedical
          }}
        >
          <div>{I18n.t('packages.modify.leafWa.modalContent')}</div>
        </ModalWrapper>
      </FormWrapper>
    </div>);
  }
}

ModifyPackagesPage.propTypes = {
  actions: PropTypes.shape({
    ensureGetUnpaginatedData: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    getDataByPost: PropTypes.func.isRequired,
    getLocations: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    getData: PropTypes.func.isRequired,
    addMessage: PropTypes.func.isRequired,
    showProductLookup: PropTypes.func.isRequired,
    setData: PropTypes.func.isRequired,
    getSearchData: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
  }).isRequired,
  params: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  ids: PropTypes.array.isRequired,
  adjustmentReasons: PropTypes.array.isRequired,
  packages: PropTypes.array.isRequired,
  inventoryItems: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  phases: PropTypes.array.isRequired,
  initialValues: PropTypes.object,
  showPhases: PropTypes.bool.isRequired,
  integrationState: PropTypes.object.isRequired,
  integrationAdjustmentReasons: PropTypes.array.isRequired,
  trackingIds: PropTypes.array.isRequired,
  timezone: PropTypes.string.isRequired,
  isReservedPackage: PropTypes.bool.isRequired,
  showReverseToParent: PropTypes.bool.isRequired,
  validateTrackingIdsAvailability: PropTypes.func.isRequired,
  hasGlobalAdjustmentReason: PropTypes.bool,
  hasGlobalValues: PropTypes.bool,
  hasGlobalIntegrationAdjustmentReason: PropTypes.bool,
  hasAllIntegrationAdjustmentReasons: PropTypes.bool,
  lots: PropTypes.array.isRequired,
  hasPackageWithoutReason: PropTypes.bool,
  hasMedicatedPackages: PropTypes.bool,
  hasWastePackages: PropTypes.bool,
  inventoryTypes: PropTypes.array,
  productLookup: PropTypes.object.isRequired,
  isAllowNegativeInventory: PropTypes.bool,
  transfers: PropTypes.array,
  facilityUsers: PropTypes.array,
  hasPackagesTags: PropTypes.bool,
  allInvComplianceSettings: PropTypes.object,
  cupos: PropTypes.array,
  hasManufacturing: PropTypes.bool,
  isMetrcStemHoldingsToggled: PropTypes.bool,
  isMetrcCaDonationsToggled: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {
  const ids = ownProps.params.id ? [ownProps.params.id] : getSelectedProductIds(state);
  const initialValues = getModifyPackagesInitialValues(state);
  const packages = getFormPackages(state);
  const lots = getFormLots(state);
  const integrationState = getIntegrationState(state);
  return {
    ids,
    initialValues,
    packages,
    cupos: state[dataNames.cupos],
    adjustmentReasons: state[dataNames.adjustmentReasons],
    inventoryItems: state[dataNames.inventoryItems],
    locations: getModifyPackagesFlattenedLocations(state),
    phases: state[dataNames.phases],
    showPhases: getModulesState(state).hasManufacturing,
    integrationState,
    integrationAdjustmentReasons: getIntegrationAdjustmentReasonOptions(state),
    trackingIds: state.trackingIds,
    timezone: state.timezone,
    isReservedPackage: isReservedInventoryPackage(state),
    facility: state.facility,
    showReverseToParent: showReverseToParent(state),
    hasGlobalAdjustmentReason: modPackageFormHasGlobalAdjustmentReason(state, { formName: MODIFY_PACKAGES }),
    hasGlobalValues: modPackageFormHasGlobalValues(state, { formName: MODIFY_PACKAGES }),
    hasGlobalIntegrationAdjustmentReason: modPackageFormHasGlobalIntegrationAdjustmentReason(state, {
      formName: MODIFY_PACKAGES
    }),
    hasAllIntegrationAdjustmentReasons: modPackageFormHasAllIntegrationAdjustmentReasons(state, {
      formName: MODIFY_PACKAGES
    }),
    partnerFacilities: state.partnerFacilities || [],
    lots,
    hasPackageWithoutReason: hasPackageWithoutReason(packages),
    hasMedicatedPackages: hasMedicatedPackages(state),
    hasWastePackages: hasWastePackages(state),
    inventoryTypes: integrationState.isBiotrack ? getBiotrackInvTypesWithTitles(state) : [],
    productLookup: state.productLookup,
    isAllowNegativeInventory: complianceSettings.isAllowNegativeInventory(state),
    transfers: state[dataNames.transfers],
    facilityUsers: state[dataNames.users],
    hasPackagesTags: hasPackagesTags(state),
    allInvComplianceSettings: complianceSettings.getInventoryComplianceSettings(state),
    hasManufacturing: getModulesState(state).hasManufacturing,
    isMetrcStemHoldingsToggled: isFeatureEnabled(state)('feature_metrc_stem_holdings'),
    isMetrcCaDonationsToggled: isFeatureEnabled(state)('feature_metrc_ca_donations'),
    requireOnHandProductExpireDate: complianceSettings.requireOnHandProductExpireDate(state)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    fetchMetrcTrackingIdsForSelectInput,
    ensureGetUnpaginatedData,
    getUnpaginatedData,
    getDataByPost,
    postData,
    unsetData,
    addMessage,
    goBack,
    getLocations,
    postItem,
    getItem,
    putItem,
    getData,
    showProductLookup,
    getSearchData,
    setData,
    change
  };
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default reduxMetrcIdAvailability(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ModifyPackagesPage)
);
