import {createSelector} from 'reselect';
import {getFacilities} from './facilitiesSelectors';

export const getAccountTypes = (state) => state.quickbooksTransactionTypes;

export const getFacilitiesOptions = createSelector(
  [getFacilities], (facilities) => {
    let options = {};
    options = Object.keys(facilities).map((e) => {
      const facility = facilities[e];
      return {text:facility.name,value:facility.id};
    });
    return options;
  }
);

export const getQuickbooksAccountsTypes = createSelector(
  [getAccountTypes],(accountTypes) => {
    let options = {};
    options = Object.keys(accountTypes).map((e) => {
      const types = accountTypes[e];
      return {text:types.name,value:types.id};
    });
    return options;
  });

const getAccountingSettings = state => state.accountingSettings;
const getAccountingSettingsFields = state => state.accountingSettingsFields;

export const getAccountSettingsInitialValues = createSelector(
  [getAccountingSettings, getAccountingSettingsFields],
  (settings, fields) => {
    const values = {};
    fields && fields.forEach(field => {
      const value = settings.find(setting => setting.quickbooks_account_id == field.id);
      if (value) {
        values[field.account_key] = {account_name: value.account_name, account_type_id: value.account_type_id};
      } else {
        values[field.account_key] = {account_name: '', account_type_id: 0};
      }
    });
    return values;
  }
);

export const getAccountSettingsFieldsIdsByKey = createSelector(
  [getAccountingSettingsFields],
  (fields) => {
    const values = {};
    fields && fields.map(field => {
      values[field.account_key] = field.id;
    });
    return values;
  }
);

export default getAccountSettingsInitialValues;
