import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import MultiselectInput from '../MultiselectInput';

const ReduxTagField = ({name, ...props}) => {
  return (<Field name={name}
    component={MultiselectInput}
    createNew={true}
    normalize={(value, prevValue) => Array.isArray(value) ? value.filter(el => el) : [...prevValue, value].filter(el => el)}
    {...props}
    props={{
      ...props.props
    }}/>);
};

ReduxTagField.propTypes = {
  name: PropTypes.string.isRequired,
  props: PropTypes.object
};

export default ReduxTagField;
