import React from 'react';
import PropTypes from 'prop-types';
import {Field, reduxForm} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import { Row, Col } from 'react-bootstrap';
import {Erase32} from '@carbon/icons-react';

import MultiselectInput from '../common/form/MultiselectInput';
import WillRender from '../common/concealers/WillRender';
import {facilityTypeLabels} from '../../constants/facilityTypes';

const facilityTypeOptions = Object.entries(facilityTypeLabels).map((type) => {
  return {text: type[1], value: type[0]};
});

const initialValues = {
  facility_types: [],
  facilities: [],
};

export class AnalyticsFilterForm extends React.Component {
  render() {
    const {change} = this.props;
    const facilities = this.props.facilities;

    return (this.props.includeFacilityFilters || this.props.includeFacilityTypeFilter) ? (
      <div className='analytics-report-container'>
        <Row className='facility-filters'>
          <WillRender ifTrue={this.props.includeFacilityTypeFilter}>
            <Col md={6}>
              <Field
                name='facility_types' component={MultiselectInput}
                onChange={this.props.changeFacilityType}
                props={{
                  options: facilityTypeOptions,
                  textKey: 'text',
                  valueKey: 'value',
                  placeholder: I18n.t('reporting.analytics.facilityTypes'),
                }} />
            </Col>
          </WillRender>
          <WillRender ifTrue={this.props.includeFacilityFilters}>
            <Col md={5}>
              <Field
                name='facilities' component={MultiselectInput}
                onChange={this.props.changeFacility}
                props={{
                  options: facilities,
                  textKey: 'name',
                  valueKey: 'id',
                  placeholder: I18n.t('reporting.analytics.facilities'),
                }} />
            </Col>
          </WillRender>
          <span className='icons-react'>
            <Erase32 onClick={() => {
              if (this.props.includeFacilityFilters) change('facilities', initialValues.facilities);
              if (this.props.includeFacilityTypeFilter) change('facility_types', initialValues.facility_types);

              this.props.onClearFilters();
            }}/>
          </span>
        </Row>
      </div>
    ) : null;
  }
}

AnalyticsFilterForm.propTypes = {
  filter: PropTypes.object,
  includeFacilityTypeFilter: PropTypes.bool.isRequired,
  includeFacilityFilters: PropTypes.bool,
  facilityTypes: PropTypes.array,
  facilities: PropTypes.array,
  changeFacility: PropTypes.func,
  changeFacilityType: PropTypes.func,
  onClearFilters: PropTypes.func,
  change: PropTypes.func,
};

export default reduxForm({form: 'filterForm', initialValues})(AnalyticsFilterForm);
