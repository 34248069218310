import {createSelector} from 'reselect';
import {formValueSelector} from 'redux-form';
import get from 'lodash.get';
import sortBy from 'lodash.sortby';
import round from 'lodash.round';
import moment from 'moment-timezone';
import {formatCurrencyDecimal} from '../../util/formatHelper';
import {SALES_ORDER_FORM} from '../../constants/forms';
import * as itemTypes from '../../constants/itemTypes';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import {
  btProcessorLocationTypes,
  btProducerLocationTypes,
  btRetailerLocationTypes
} from '../../constants/integration/biotrack/biotrackLocationTypes';
import {
  btProcessorInventoryTypes,
  btProducerInventoryTypes,
  btRetailerInventoryTypes
} from '../../constants/integration/biotrack/biotrackInventoryTypes';
import {getSalesOrder} from '../salesOrdersSelectors';
import {getActiveFacility, isActiveFacilityDispensary} from '../facilitiesSelectors';
import { WEIGHT, uomTypes } from '../../constants/uomTypes';
import {
  getItemMasters,
  getProductType,
  isItemMasterMedicated,
  isMedicated,
  isPrePackChild,
  isSold,
  isWaste
} from '../itemMastersSelectors';
import {getIntegrationState} from '../integration/integrationSelectors';
import { userHasPermission } from '../permissionsSelectors';
import {getApplicablePlatformSubcategoryIds} from '../integration/biotrackCategoriesSelectors';
import { safelyParseJsonString } from '../../util/dataHelpers';
import {
  getPrepackWeights,
  getPrepackWeightsForArray,
  getSalesOrderWeightsMapping,
  getOrganizationPrepackWeights,
  getFacilityEnabledPrepackWeights
} from '../../selectors/prepackWeightSelectors';
import {isPaLeafIntegrator, isWaLeafIntegrator} from '../integration/leafSelectors';
import {isInActiveClosedLoop} from '../closedLoopsSelectors';
import {getPartnerFacilities, filterPartnersForLeafIntegration, getPartnersByPermission} from '../partnersSelectors';
import {isLeafPaDisallowRetailTransferOfPlantSubtypesEnabled} from '../featureToggles';
import {isNonTransferableItemMasterForLeafPa} from '../../components/transfers/helpers';
import {NON_MEDICATED_CATEGORY_ID} from '../../constants/categories';



/***
 * Relatively simple now but connects will probably complicate the logic... hence a selector.
 * @type {Reselect.Selector<any, any>}
 */
export const isLinkedSalesOrder = createSelector([getSalesOrder], (salesOrder) => {
  const field = 'internal_purchase_order_linked';
  return get(salesOrder, field, false) && get(salesOrder, field) !== null;
});

export const salesOrderConnectsStatus = createSelector([getSalesOrder], (salesOrder) => {
  const connectStatus = get(salesOrder, 'connect_status', false);
  return connectStatus && connectStatus !== null  ? connectStatus : false;
});

const selector = formValueSelector(SALES_ORDER_FORM);

const getSelectedPartnerFacilityId = (state) => selector(state, 'partner_facility_id');
const getOrderIsMedicated = (state) => selector(state, 'contains_medicated');
export const getOrderType = (state) => selector(state, 'order_type');
const getOrderLines = (state) => selector(state, 'lines') || [];
const getPartnerIdFormValue = (state) => selector(state, 'partner_id');
export const getPartnerFacilityIdFormValue = (state) => selector(state, 'partner_facility_id');
export const getOddMoneyFormValue = (state) => selector(state, 'oddMoney');
const getWastePackages = (state) => state[dataNames.wastePackages];


export const getPartnerFacilitiesForSalesOrder = createSelector(
  [getPartnerFacilities, getPartnerIdFormValue, isWaLeafIntegrator, isPaLeafIntegrator, isInActiveClosedLoop, filterPartnersForLeafIntegration, getOrderType],
  (allPartnerFacilities, partnerId, isWaLeafIntegrator, isPaLeafIntegrator, isInClosedLoop, partnersForLeaf, orderType) => {

    const partnerFacilities = allPartnerFacilities.filter(facility => facility.partner_id === partnerId);

    // Separate logic for Leaf integrated facilities
    if (isWaLeafIntegrator || isPaLeafIntegrator) {
      return partnersForLeaf;
    }

    // Separate logic for Closed Loops - filter by is_lab flag on partner facility.
    // This flag is set by Closed Loop when facility is included in Lab members list.
    if (isInClosedLoop) {
      return orderType === 'lab'
        ? partnerFacilities.filter(facility => facility.is_lab)
        : partnerFacilities.filter(facility => !facility.is_lab);
    }

    // For other integrations and platform without integration - show all partner facilities,
    // since flag is_lab cant be set on partner_facility level.
    return partnerFacilities;
  }
);

export const getPartnersForSalesOrder = createSelector(
  [getPartnersByPermission, getOrderType],
  (partners, order_type) => filterPartnersBySalesOrderType(partners, order_type)
);

export const getPartnerFacility = createSelector(
  getPartnerFacilityIdFormValue, getPartnerFacilities,
  (facilityId, partnerFacilities) => partnerFacilities.reduce(
    (previous, current) => current.id === facilityId ? current : previous, null
  )
);

export const getIsActivePartner = createSelector(
  [getPartnerFacility],
  (partnerFacility) => partnerFacility && partnerFacility.partner && partnerFacility.partner.active
);

export const filterPartnersBySalesOrderType = (partners, orderType) => {
  return partners ? partners.filter((partner) => {
    switch (orderType) {
    case 'sales':
      return partner.sell_to === 1;
    case 'lab':
      return partner.is_lab === 1;
    case 'waste_disposal':
      return partner.is_waste_disposal === 1;
    default:
      return false;
    }
  }) : [];
};

const getOrderPartner = createSelector(
  [getPartnerFacilities, getPartnerFacilityIdFormValue],
  (partnerFacilities, partnerFacilityId) => partnerFacilities.find(pf => pf.id === partnerFacilityId)
);


const getApplicableBiotrackInventoryTypes = createSelector(
  [getOrderPartner],
  (partner) => {
    const type = partner && Number(partner.state_integration_type);
    if (btProducerLocationTypes.indexOf(type) > -1) {
      return btProducerInventoryTypes;
    } else if (btProcessorLocationTypes.indexOf(type) > -1) {
      return btProcessorInventoryTypes;
    } else if (btRetailerLocationTypes.indexOf(type) > -1) {
      return btRetailerInventoryTypes;
    }
    return [];
  }
);

const getBiotrackSubcategoryIds = getApplicablePlatformSubcategoryIds(getApplicableBiotrackInventoryTypes);


export const getAvailableQuantityFromPackage = (p, transferId) => {
  const reservationHasTransferId = (reservation) => (reservation.entity_type === 'transfer' && reservation.entity_id === transferId);
  const activeReservationsExcludingThisTransferId = (reservation) => !reservationHasTransferId(reservation);
  const quantityReserved = (p.reservations || [])
    .filter(activeReservationsExcludingThisTransferId)
    .reduce((acc, reservation) => acc + reservation.qty_base, 0) || 0;
  const reservationUom = uomTypes[p.uom_reserved || p.uom]; // for pre-packs uom_reserved is GR, while uom is EA
  let calculatedReservedQuantity = reservationUom.type === WEIGHT ? (quantityReserved / reservationUom.ratio) : quantityReserved;
  //for pre-packs get qty of package instead of weight
  if (p.is_prepack) {
    calculatedReservedQuantity = calculatedReservedQuantity / p.prepack_weight;
  }
  return p.qty - calculatedReservedQuantity;
};


const addWasteToProductName = im => {
  const isWasteProduct = (isMedicated(im) && !isPrePackChild(im) && isWaste(im));
  return isWasteProduct
    ? im
    : {...im, name: 'Waste: ' + im.name};
};

export const getSalesOrderItemMasters = createSelector(
  [
    getItemMasters, getOrderIsMedicated, getOrderType, getBiotrackSubcategoryIds, getIntegrationState, getWastePackages,
    getSalesOrder, isActiveFacilityDispensary, getPartnerFacility, isLeafPaDisallowRetailTransferOfPlantSubtypesEnabled
  ],
  (
    itemMasters, isMedicatedOrder, orderType, btSubcategoryIds, {isLeaf, isPaLeaf, isBiotrack}, wastePackages,
    salesOrder, isActiveFacilityDispensary, partnerFacility, isLeafPaDisallowRetailTransferOfPlantSubtypesEnabled
  ) => {
    // If an item master id is already on the sales order then it should be in the item master array by definition
    // even if excluded by some other rule.  Specifically added to include ingredients that are in internal transfers.
    const salesOrderItemMasterIds = get(salesOrder, 'lines', []).map((line) => {
      return get(line, 'item_master_id'); // Not worrying about prepacks as they are sold anyway
    });

    const validItemMasters = itemMasters
      .filter(im => {
        if (orderType === 'waste_disposal') {
          // return true if product has Waste category
          if (isMedicated(im) && !isPrePackChild(im) && isWaste(im)) {
            return true;
          }
          // otherwise try to find active waste package with this item master
          return wastePackages.some(p => p.item_master_id === im.id);
        }
        if (orderType === 'lab') {
          //For lab destinations use only active medicated
          return isMedicated(im) && !isWaste(im) && !isPrePackChild(im);
        }

        if (
          isLeafPaDisallowRetailTransferOfPlantSubtypesEnabled &&
          isPaLeaf &&
          partnerFacility &&
          isNonTransferableItemMasterForLeafPa(
            isActiveFacilityDispensary,
            partnerFacility.state_integration_type === 'dispensary',
            im.subcategory_code
          )
        ) return false;

        if (isLeaf || isBiotrack) {
          //Leaf integration logic
          if (isMedicatedOrder === 1) {
            //Show medicated products only for medicated orders
            return isSold(im) && isItemMasterMedicated(im, isLeaf) && !isWaste(im);
              //&& (!isBiotrack || btSubcategoryIds.indexOf(im.subcategory_id) > -1);
          } else if (isMedicatedOrder === 0) {
            //Show non-medicated products only for non-medicated orders
            return isSold(im) && !isItemMasterMedicated(im, isLeaf) && !isWaste(im);
          }
          //if isMedicated was not selected, do not show any item masters
          return false;
        }

        return (isSold(im) && !isWaste(im)) || salesOrderItemMasterIds.indexOf(im.id) !== -1;
      })
      .map(im => orderType === 'waste_disposal' ? addWasteToProductName(im) : im)
      .map(im => ({
        ...im,
        itemType: getProductType(im) || itemTypes.prepack,
      }));
    return sortBy(validItemMasters, 'name');
  }
);

export const getUnusedSalesOrderItemMasters = createSelector(
  [getSalesOrderItemMasters, getOrderLines],
  (itemMasters, lines) => itemMasters.filter(im => !lines.some(line => line.itemMaster && line.itemMaster.id === im.id))
);

export const canCreateMatchingOrder = createSelector([getSalesOrder, getPartnerFacilities, getActiveFacility, getSelectedPartnerFacilityId, getIntegrationState], (salesOrder, facilities, activeFacility, partnerFacilityId, {isMetrc}) => {

  const isLinked = ((salesOrder && salesOrder.internal_purchase_order_linked && parseInt(salesOrder.internal_purchase_order_linked) === 1) || !salesOrder);

  const canBeLinked = facilities.find((facility) => facility.id === partnerFacilityId && facility.partner.is_internal_partner);

  const valid_selection = facilities.find((facility) => ((facility.id === partnerFacilityId) && facility.facility_id));

  return (!isLinked && valid_selection && canBeLinked && !(isMetrc && get(salesOrder, 'is_imported')))
    ? 1 // show button and enable
    : !canBeLinked // hide button
      ? 0
      : -1; // already linked so disable button
});

export const getRenderFacts = createSelector(
  [getSalesOrder, getPartnerFacilities, getActiveFacility, getSelectedPartnerFacilityId, userHasPermission],
  (salesOrder, partnerFacilities, activeFacility, selectedPartnerFacilityId, userHasPermission) => {
    const isInternalPartner = (facility) => {
      return facility.id === selectedPartnerFacilityId && get(facility, 'partner.is_internal_partner', false);
    };
    const isConnectsPartner = (facility) => {
      return facility.id === selectedPartnerFacilityId && get(facility, 'connect_facility_code', false) !== null;
    };
    const isLinked = () => {
      return (salesOrder && get(salesOrder, 'internal_purchase_order_linked', false) === 1) || !salesOrder;
    };
    return {
      canBeLinked: Boolean(partnerFacilities.find((facility) => isInternalPartner(facility) || isConnectsPartner(facility))),
      isLinked: isLinked(),
      hasTransfer: false, // for purchase orders but we reused rules so...
      hasManageConnectsPermission: userHasPermission,
      hasCompletedTransfer: false
    };
  });


export const getFormFields = (state) => {
  return selector(state, 'lines', 'transfer_fee', 'discount_percent', 'taxes', 'payments', 'order_type', 'partner_id');
};

export const getOrderTotal = (state) => {
  const formFields = getFormFields(state);
  const lines = get(formFields, 'lines', []);
  let subTotalAmount = 0;

  for (const line of lines) {
    if (line.unit_price === null || line.line_item_price === null) {
      return null;
    }

    subTotalAmount += parseFloat(line.line_item_price);
  }

  const discountAmount = parseFloat(get(formFields, 'discount_percent', 0)) / 100 * subTotalAmount;
  const taxAmount = parseFloat(get(formFields, 'taxes', 0));
  const feeAmount = parseFloat(get(formFields, 'transfer_fee', 0));

  return subTotalAmount - discountAmount + taxAmount + feeAmount;
};

export const getPaymentsTotal = (state) => {
  const payments = get(getFormFields(state), 'payments', []);
  return payments.reduce((acc, payment) => {
    acc += round(payment.amount || 0, 2);
    return acc;
  }, 0);
};

export const getTitle = (state) => {
  const {partner_id} = getFormFields(state);
  const partners = state[dataNames.partners];
  const partner = partner_id ? partners.find(partner => partner.id === partner_id) : {};
  const partnerName = partner && partner.name ? partner.name : '';
  const date_ordered = selector(state, 'date_ordered');
  const date = date_ordered && moment(date_ordered).isValid() ? moment(date_ordered).format('MM/DD/YYYY') : '';
  return `${partnerName} ${date}`;
};

export const getPartnerId = (state) => {
  const partnerFacilities = state[dataNames.partnerFacilities];
  const salesOrder = state[itemNames.salesOrder];
  const partnerFacilityId = get(salesOrder, 'partner_facility_id', 0);
  const partnerFacility = partnerFacilities.find((partnerFacility) => partnerFacility.id === partnerFacilityId);
  return get(partnerFacility, 'partner_id', 0);
};


const getTimeZone = (state) => state.timezone;

const getInitialValuesLines = (state) => {

  const salesOrder = state[itemNames.salesOrder];

  if(Object.keys(salesOrder).length === 0) return [];

  const hasPrepacksWithoutWeights = () => {
    const hasPrepacks = get(salesOrder, 'lines', []).reduce((acc, line) => {
      if (acc) return acc;
      if (line.subitems) {
        return true;
      }
      return acc;
    }, false);
    if(!hasPrepacks) return false;
    const orgPrepackWeights = getOrganizationPrepackWeights(state);
    const facilityPrepackWeights = getFacilityEnabledPrepackWeights(state);
    return orgPrepackWeights.length === 0 || facilityPrepackWeights.length === 0;
  };

  const itemMasters = state[dataNames.itemMasters].map((itemMaster) => {
    itemMaster.itemType = getProductType(itemMaster);
    return itemMaster;
  });

  const itemMasterLookup = itemMasters.reduce((acc, itemMaster) => {
    acc[get(itemMaster, 'id')] = itemMaster;
    return acc;
  }, {});

  // Don't return lines if we can't complete them.
  if(itemMasters.length === 0 || hasPrepacksWithoutWeights()) return [];

  const prepackWeights = getPrepackWeights(state);

  return get(salesOrder, 'lines', []).map((lineItem) => {

    const meta = get(lineItem, 'meta', '[]');
    const line = Object.assign({}, lineItem, {...(safelyParseJsonString(meta))});
    const foundItemMaster = itemMasters.find((itemMaster) => itemMaster.id === get(line, 'item_master_id'));
    const itemMaster = Object.assign({}, foundItemMaster);
    itemMaster.children = get(itemMaster, 'is_prepack', 0)
      ? itemMasters.filter((im) => get(im, 'item_master_parent_id') === itemMaster.id)
      : [];
    const formatToDecimals = ['unit_price', 'line_item_price'];
    formatToDecimals.forEach((field) => {
      line[field] = formatCurrencyDecimal(line[field]);
    });

    const getPrepackItemMasterById = (id) => {
      const itemMaster = get(itemMasterLookup, String(id));
      if(!itemMaster) return false;
      return get(itemMaster, 'is_prepack', 0) ? itemMaster : false;
    };

    const itemMastersNeedingWeights = [];
    const subItemsAsArray = get(line, 'subitems', []);
    const subItems = subItemsAsArray.reduce((acc, item) => {
      const itemMasterId = get(item, 'item_master_id');
      const itemMaster = getPrepackItemMasterById(itemMasterId);
      if(itemMaster && get(item, 'qty', 0) > 0){
        const prepackWeight = prepackWeights.find((weight) => weight.id === get(itemMaster, 'prepack_weight_id'));
        if(!prepackWeight){
          itemMastersNeedingWeights.push(itemMaster);
        }
      }
      acc[itemMasterId] = item;
      return acc;
    }, {});

    line.itemMaster = itemMaster;

    if(itemMaster.itemType === itemTypes.prepack) {
      const useWeights = itemMastersNeedingWeights.length
        ? getPrepackWeightsForArray(state, {array: itemMastersNeedingWeights})
        : prepackWeights;
      line.weights = getSalesOrderWeightsMapping(useWeights, itemMaster, line, subItems, subItemsAsArray);
    }
    if(itemMaster.itemType === itemTypes.bulk){
      line.qty = parseFloat(get(line, 'qty', 0));
    }
    if(itemMaster.itemType === itemTypes.unit){
      line.qty = line.qty_base;
    }
    return line;
  });

};

export const getInitialValues = createSelector(
  [getSalesOrder, getOrderTotal, getPaymentsTotal, getIntegrationState, getTitle, getTimeZone, getInitialValuesLines, getItemMasters, getPartnerId],
  (rawSalesOrder, orderTotal, paymentsTotal, integrationState, title, timezone, lines, itemMasters, partnerId) => {

    const isCreate = Object.keys(rawSalesOrder).length === 0;
    const nonMedicatedCategoryId = NON_MEDICATED_CATEGORY_ID;
    const overPayment = paymentsTotal > orderTotal ? round(orderTotal - paymentsTotal, 2) : 0;
    const isLeaf = integrationState.isLeaf;
    const baseValues = {
      title,
      order_total: isNaN(parseFloat(orderTotal)) ? '0.00' : formatCurrencyDecimal(orderTotal),
      order_type: 'sales',
      payments: [{}],
      taxes: '0.00',
      transfer_fee: '0.00',
      discount_percent: '0.00',
      isLeaf,
      reserve_inventory: 1,
      oddMoney: {
        payment_type: 'cash',
        amount: formatCurrencyDecimal(overPayment),
      },
    };

    if(isCreate){
      return baseValues;
    }

    const payments = rawSalesOrder.payments ? rawSalesOrder.payments.map(payment => ({
      ...payment,
      payment_date: moment(payment.payment_date) || '',
    })) : [];

    // The UI ensures there's only one change "payment"
    const changePayment = payments.find(payment => Number(payment.amount) < 0);

    const salesOrder = {
      ...rawSalesOrder,
      date_expected: rawSalesOrder.date_expected ? moment.utc(rawSalesOrder.date_expected).tz(timezone) : '',
      date_ordered: rawSalesOrder.date_ordered ? moment.utc(rawSalesOrder.date_ordered).tz(timezone) : '',
      lines,
      payments: payments.filter((payment) => {
        return Number(payment.amount) > 0;
      }),
      oddMoney: {
        ...changePayment,
      },
    };

    // Force defaults for sending to Lab:
    if (salesOrder.order_type === 'lab') {
      if (!salesOrder.transfer_type) {
        salesOrder.transfer_type = 'testing_lab';
      }
    }

    // Return empty object if we aren't fully loaded.  Lines are not returned if we have prepacks and don't have weights yet.
    if(get(rawSalesOrder, 'lines', []).length !== get(salesOrder, 'lines', []).length) return {};

    const containsMedicated = lines.find((line) => get(line, 'itemMaster.category_id') !== nonMedicatedCategoryId) ? 1 : 0;

    return {
      ...baseValues,
      ...salesOrder,
      new_note: '',
      contains_medicated: containsMedicated,
      partner_id: partnerId,
      isLeaf // I don't know why... was in the original
    };

  });
