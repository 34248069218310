import React from 'react';
import {I18n} from 'react-redux-i18n';
import {Button} from 'react-bootstrap';
import {INTEGRATION_ERROR_INTEGRATOR, INTEGRATION_ERROR_PLATFORM, INTEGRATION_PENDING} from '../../../constants/integration/entityLocks';
import IntegrationTrackingStatusModalTimer from './IntegrationTrackingStatusModalTimer';

const messageMap = {
  [INTEGRATION_ERROR_INTEGRATOR]: {
    i18nType: 'errorIntegrator',
    leaf: {
      email: 'PASupport@mjfreeway.com'
    },
    metrc: {
      email: null
    },
    biotrack: {
      email: 'support@biotrackthc.com'
    }
  },
  [INTEGRATION_ERROR_PLATFORM]: {
    i18nType: 'errorPlatform',
    leaf: {
      email: 'PASupport@mjfreeway.com'
    },
    metrc: {
      email: 'Support@mjfreeway.com'
    },
    biotrack: {
      email: 'prsupport@mjfreeway.com'
    }
  },
  [INTEGRATION_PENDING]: {
    i18nType: 'pendingIntegrator',
    leaf: {
      email: 'PASupport@mjfreeway.com'
    },
    metrc: {
      email: 'Support@mjfreeway.com'
    },
    biotrack: {
      email: 'prsupport@mjfreeway.com'
    }
  }
};

class IntegrationEntityLockSupportMessage extends React.Component {
  constructor(props) {
    super(props);

    this.handleRefreshClick = this.handleRefreshClick.bind(this);
    this.state = {
      showNoTrackingIdMessage: false
    };
  }

  handleRefreshClick(itemId) {
    this.props.updateTrackingId(itemId);
    this.setState({ showNoTrackingIdMessage: true });
  }

  render() {
    const { lockReason, createdAt, integrationState: { isLeaf, isBiotrack, isMetrc }, handleRevertSplit, itemId } = this.props;
    const { showNoTrackingIdMessage } = this.state;
    const lockTypeMap = messageMap[lockReason];

    if (!lockTypeMap) return null;

    let integrationType;

    if (isLeaf) {
      integrationType = 'leaf';
    } else if (isBiotrack) {
      integrationType = 'biotrack';
    } else if (isMetrc) {
      integrationType = 'metrc';
    } else {
      return null;
    }

    const supportMessageI18nKey = `integration.transactions.trackingIdStatuses.${lockTypeMap.i18nType}.${integrationType}.supportMessageBegin`;
    const email = lockTypeMap[integrationType].email;

    return (
      <p>
        {((integrationType === 'biotrack' || integrationType === 'metrc') && lockTypeMap.i18nType === 'pendingIntegrator')
          ? <span>
              <p>{I18n.t('integration.transactions.trackingIdStatuses.pendingIntegrator.tryRefresh')}<br /><Button onClick={() => this.handleRefreshClick(itemId)}>Refresh</Button></p>
              {showNoTrackingIdMessage && <p>{I18n.t('integration.transactions.trackingIdStatuses.pendingIntegrator.tryRefreshAgain')}</p>}
            </span>
          : ''
        }
        <IntegrationTrackingStatusModalTimer createdAt={createdAt} itemId={itemId} handleRevertSplit={handleRevertSplit}/>
        <hr />
        {I18n.t(supportMessageI18nKey)}
        {integrationType === 'leaf' || ((integrationType === 'biotrack' || integrationType === 'metrc') && lockTypeMap.i18nType === 'errorPlatform')
          ? <span><a href='https://support.mjfreeway.com/hc/en-us/articles/1500002911482-Contacting-Customer-Support' target='_blank'>{I18n.t('integration.transactions.trackingIdStatuses.contactCustomerSupport')}.</a> {I18n.t('integration.transactions.trackingIdStatuses.or')} </span>
          : ''}
        {email && <a href={`mailto: ${email}`}>{email}</a>}
      </p>
    );
  }
}

export default IntegrationEntityLockSupportMessage;
