import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Table, Col, Button, Row, ButtonToolbar, FormControl, InputGroup, ButtonGroup} from 'react-bootstrap';
import {FaBarcode} from 'react-icons/fa';
import {Field} from 'redux-form';
import ProductsTableHeader from './ProductsTableHeader';
import Product from './Product';
import {isMedicated} from '../../selectors/itemMastersSelectors'; // eslint-disable-line import/no-named-as-default
import OrderPackagingButtons from './OrderPackagingButtons';
import ReactSelectInput from '../common/form/ReactSelectInput';

const Products = ({fields, products, inventoryItems, pricingWeights, prepackWeights, packageCodes,
                    handleRemoveItem, handleKeyPress, handleRemoveProduct,
                    getProductFulfillmentStatus, change, itemMasters, getOrderProductCoupons, scanMode,
                    couponIsAppliedToAllProducts, quantityTotals, salesLocations, toggleScanMode,
                    currentLocation, reset, scanField, handleScanChange, subcategoryFormOptions, isCure, disablingAddInventory,
                    changeFulfillmentStatus, orderPackagingWorkflow, selectedChildItemMasters, processing, isOhMetrc,
                    isRefillNumberRequired, order, allowUpdateCart, blockExpiredProductAction, scanInputRef, focusBarcode}) => {


  return (
    <div className='items'>
      <div className='clearfix search-wrapper'>
        <Col md={2} className='float-left'>
          <Button onClick={toggleScanMode} active={scanMode} >
            {I18n.t('cart.toggleScanMode')}
          </Button>
        </Col>
        <Col md={4} className='float-right scan-and-status'>
          <InputGroup>
            <FormControl type='text'
              id='barcode-scan'
              placeholder={I18n.t('cart.items.scanField')}
              onKeyPress={handleKeyPress}
              onChange={handleScanChange}
              value={scanField}
              ref={scanInputRef}
              />
            <InputGroup.Append>
              <InputGroup.Text>
                <FaBarcode/>
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
      </Col>
        {(isCure || isOhMetrc) && (
          <Col xs={12} style={{fontSize: 'smaller', paddingTop: '8px'}}>
            {I18n.t('cart.disabledInventoryNotice')}
          </Col>
        )}
      </div>
      <Table>
        <ProductsTableHeader/>
          {fields.map((product, index) => {
            const formProduct = products[index];
            if(!formProduct) return null;
            const fulfillmentStatus = getProductFulfillmentStatus(formProduct);
            const itemMaster = itemMasters[formProduct.item_master_id] || {};
            const productCoupons = getOrderProductCoupons(formProduct)
              .filter(coupon => !couponIsAppliedToAllProducts(coupon));
            const row = fields.get(index);
            const overrideLocation = row && row.addItem && row.addItem.location ? row.addItem.location : currentLocation;

            let items = [];
            if(inventoryItems[products[index].item_master_id]){
              items = inventoryItems[products[index].item_master_id];
            }else{
              const selectedChildItemMastersfromParent = selectedChildItemMasters.filter(v => v.item_master_parent_id === products[index].item_master_id );
              selectedChildItemMastersfromParent.forEach(v => {
                if(inventoryItems[v.item_master_id]){
                  items = items.concat(inventoryItems[v.item_master_id]);
                }
              });
            }

            const totalQuantity = quantityTotals[index] || 0;
            const limitedSalesLocations = salesLocations ? salesLocations.find(salesLocation => salesLocation.id === overrideLocation) : [];

            const filteredItems = salesLocations && salesLocations.length && limitedSalesLocations ? items.filter(item => limitedSalesLocations.child_ids.indexOf(item.inventory_location_id) >= 0) : items;

            if (filteredItems.length === 1 && !row.addItem && filteredItems[0] !== undefined) { // Was row.addItem.item_id
              setTimeout( () => { // prevents react complaining about setState call in render...
                change(`${product}.addItem.item_id`, filteredItems[0].id);
                change(`${product}.addItem.prepack_weight_id`, filteredItems[0].prepack_weight_id);
                change(`${product}.addItem.qty_available`, filteredItems[0].qty_available);
              }, 10);

            }
            const formOptions = subcategoryFormOptions[formProduct.subcategory_id] || [];

            const allowedLocation = salesLocations.filter((saleLocation) => {
              return saleLocation.id === currentLocation || items.find(item => saleLocation.child_ids.indexOf(item.inventory_location_id) !== -1);
            });

            const isProductMedicated = isMedicated(itemMaster);

            return (
              <Product
                key={index}
                index={index}
                fields={product}
                product={products[index]}
                isFulfilled={fulfillmentStatus.isFulfilled}
                pricingWeights={pricingWeights}
                prepackWeights={prepackWeights}
                packageCodes={packageCodes}
                coupons={productCoupons}
                change={change}
                itemMaster={itemMaster}
                items={items}
                inventoryItems={filteredItems}
                handleRemoveItem={handleRemoveItem}
                handleRemoveProduct={handleRemoveProduct}
                totalQuantity={totalQuantity}
                salesLocations={allowedLocation}
                overrideLocation={overrideLocation}
                currentLocation={currentLocation}
                fieldNamePrefix={`products[${index}]`}
                formOptions={formOptions}
                isCure={isCure}
                isProductMedicated={isProductMedicated}
                disablingAddInventory={disablingAddInventory}
                isOhMetrc={isOhMetrc}
                order={order}
                blockExpiredProductAction={blockExpiredProductAction}
              />);
          })}
      </Table>
      <Row>
        <Col xs={3}>
          {isRefillNumberRequired ?
            <Field
              name={`refill_number`}
              component={ReactSelectInput}
              props={{
                textKey: 'value',
                options: [{value: '0'}, {value: '01'}, {value: '02'}, {value: '03'}],
                valueKey: 'value',
                placeholder: I18n.t('cart.form.selectRefillNumber')
              }}/>
            : null
          }
        </Col>
        <Col xs={9}>
          <ButtonToolbar className='float-right'>
            <Button variant='success' type='submit' disabled={!allowUpdateCart}>{I18n.t('order.updateCart')}</Button>
            {orderPackagingWorkflow ?
              <ButtonGroup>
                <OrderPackagingButtons
                    changeFulfillmentStatus={changeFulfillmentStatus}
                    disablingAddInventory={disablingAddInventory}
                    processing={processing}
                />
              </ButtonGroup> : null
            }
            <Button onClick={reset}>{I18n.t('order.reset')}</Button>
          </ButtonToolbar>
        </Col>
      </Row>
    </div>
  );
};

Products.defaultProps = {
  isReadyStatus: false,
};

Products.propTypes = {
  products: PropTypes.array.isRequired,
  order: PropTypes.object,
  inventoryItems: PropTypes.object.isRequired,
  pricingWeights: PropTypes.array.isRequired,
  prepackWeights: PropTypes.array.isRequired,
  packageCodes: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  coupons: PropTypes.array.isRequired,
  itemMasters: PropTypes.object.isRequired,
  checkout: PropTypes.bool.isRequired,
  handleRemoveItem: PropTypes.func.isRequired,
  handleRemoveProduct: PropTypes.func.isRequired,
  handleKeyPress: PropTypes.func.isRequired,
  getProductFulfillmentStatus: PropTypes.func.isRequired,
  couponIsAppliedToAllProducts: PropTypes.func.isRequired,
  getOrderProductCoupons: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  quantityTotals: PropTypes.array.isRequired,
  salesLocations: PropTypes.array,
  currentLocation: PropTypes.number,
  reset: PropTypes.func.isRequired,
  scanField: PropTypes.string,
  handleScanChange: PropTypes.func,
  customer: PropTypes.object.isRequired,
  isCure: PropTypes.bool,
  subcategoryFormOptions: PropTypes.object,
  scanMode: PropTypes.bool,
  toggleScanMode: PropTypes.func,
  disablingAddInventory: PropTypes.bool,
  changeFulfillmentStatus: PropTypes.func,
  orderPackagingWorkflow: PropTypes.number,
  isReadyStatus: PropTypes.bool.isRequired,
  selectedChildItemMasters: PropTypes.array,
  processing: PropTypes.bool,
  isOhMetrc: PropTypes.bool,
  isRefillNumberRequired: PropTypes.bool,
  allowUpdateCart: PropTypes.bool,
  blockExpiredProductAction: PropTypes.func,
  scanInputRef: PropTypes.func.isRequired,
  focusBarcode: PropTypes.func.isRequired
};

export default Products;
