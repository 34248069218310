import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import get from 'lodash.get';
import {getFormValues} from 'redux-form';
import { bindActionCreators } from 'redux';
import {goBack} from 'react-router-redux';
import FormWrapper from '../../common/form/FormWrapper';
import DeliveryOrderFormWrapper from './DeliveryOrderFormWrapper';
import {DELIVERY_ORDER_FORM} from '../../../constants/forms';
import * as itemNames from '../../../constants/itemNames';
import * as dataNames from '../../../constants/dataNames';
import {
  getDeliveryOrdersInitialValues,
  getCourierDeliveryOrdersInitialValues,
  getCourierOptions,
  getTimeZone,
  getConsumerInfo,
  getCourierConsumerInfo,
  getOrderDetails,
  getCourierOrderDetails
} from '../../../selectors/deliveryOrdersSelectors.js';
import * as apiActions from '../../../actions/apiActions';
import {setData} from '../../../actions/dataActions';
import {unsetItem} from '../../../actions/itemActions';

export class ModifyDeliveryOrderPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);

    // local state
    this.state = {
      dataLoaded : false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  componentWillMount(){

    if ( this.props.location.pathname.includes('courier') ) {
      this.props.actions.getItem(
        `/api/delivery_orders/courier/${this.props.params.id}`,
        itemNames.courierDeliveryOrder
      ).then(
        () => {
          this.setState({dataLoaded: true});
        }
      );
    } else {
      Promise.all([
        this.props.actions.getUnpaginatedData('/api/partner_facilities/in_closed_loop', dataNames.courierPartners, undefined, {
          is_courier: '1',
          select_columns: ['id', 'facility_name', 'legal_name']
        }),
        this.props.actions.getItem(
          `/api/delivery_orders/retail/${this.props.params.id}`,
          itemNames.deliveryOrder
        )
      ]).then(
        () => {
          this.setState({dataLoaded: true});
        }
      );
    }
  }

  componentWillUnmount() {
    this.props.location.pathname.includes('courier') ? this.props.actions.unsetItem(itemNames.deliveryOrder) : this.props.actions.unsetItem(itemNames.courierDeliveryOrder)
  }

  onSubmit(formData, dispatch) {
    const payload = {};
    if (get(formData, 'partner_facility_id') > 0) {
      payload.partner_facility_id = get(formData, 'partner_facility_id');
    }
    if (get(formData, 'delivery_expected_at')) {
      payload.delivery_expected_at = formData.delivery_expected_at.utc().format('YYYY-MM-DD HH:mm:ss');
    }
    payload.delivery_method = formData.delivery_method;
    payload.delivery_status = formData.delivery_status;

    const urlPut = this.props.location.pathname.includes('courier') ?  `/api/delivery_orders/courier/${this.props.params.id}`  :  `/api/delivery_orders/retail/${this.props.params.id}`;
    this.props.actions.putItem(
      urlPut,
      payload,
      null, {}, {}, () => this.props.actions.goBack()
    );
  }

  redirect() {
    this.props.actions.goBack();
  }

  render() {

    const {initialValues, formValues, courierOptions, facilityTimezone, consumerInfo, orderDetails} = this.props;

    return (
      <FormWrapper
        title={'deliveryOrders.modify.title'}
        goBack={this.redirect}
      >
        {this.state.dataLoaded ?
          <DeliveryOrderFormWrapper
            enableReinitialize={true}
            onDeliveryMethodChange={this.onDeliveryMethodChange}
            initialValues={initialValues}
            formValues={formValues}
            onSubmit={this.onSubmit}
            courierOptions={courierOptions}
            facilityTimezone={facilityTimezone}
            consumerInfo={consumerInfo}
            orderDetails={orderDetails}
            isCourier={this.props.location.pathname.includes('courier')}
          />
          : null
        }
      </FormWrapper>
    );
  }
}

ModifyDeliveryOrderPage.propTypes = {
  actions: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps){

  const formValuesSelector = getFormValues(DELIVERY_ORDER_FORM);
  return {
    facilityTimezone: getTimeZone(state),
    initialValues: (ownProps.location.pathname.includes('courier') ? getCourierDeliveryOrdersInitialValues(state) : getDeliveryOrdersInitialValues(state)) || {},
    formValues: formValuesSelector(state) || {},
    courierOptions: getCourierOptions(state) || [],
    consumerInfo: (ownProps.location.pathname.includes('courier') ? getCourierConsumerInfo(state) : getConsumerInfo(state)) || {},
    orderDetails: (ownProps.location.pathname.includes('courier') ? getCourierOrderDetails(state) : getOrderDetails(state)) || {},
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {setData}, {unsetItem}, {goBack});
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (ModifyDeliveryOrderPage);
