import {change} from 'redux-form';
import {PACKAGE_MOTHER_PLANTS_FORM} from '../../constants/forms';
import {isFormChangeOnField} from './utils';
import {getItemMasterById} from '../../selectors/itemMastersSelectors';

const packageMotherPlantsForm = store => next => action => {
  const result = next(action);
  const {payload, meta} = action;

  if (isFormChangeOnField(action, [PACKAGE_MOTHER_PLANTS_FORM], 'item_master_id', true)) {
    const state = store.getState();
    const itemMaster = getItemMasterById(state, {id: payload});
    const path = meta.field.replace('.item_master_id', '');
    store.dispatch(change(meta.form, `${path}.qty`, ''));
    store.dispatch(change(meta.form, `${path}.uom`, itemMaster && itemMaster.default_uom || ''));
  }

  return result;
};

export default packageMotherPlantsForm;
