/* eslint-disable import/prefer-default-export */
export const LABEL_TYPES = {
  PACKAGE_LABELS: {
    name: 'Inventory Package Labels',
    tags: [
      'inv_package_tag_small',
      'inv_package_tag_large',
      'inv_package_tag',
      'inv_package_tag_all',
      'inv_package_tag_2x4',
      'inv_package_tag_verbose',
      'supply_inventory_receipt_small',
      'supply_inventory_receipt_large',
      'supply_inventory_receipt_all'
    ]
  },
  PATIENT_LABELS: {
    name: 'Patient Label',
    tags: [
      'patient_label_small',
      'patient_label',
      'patient_label_large',
      'patient_label_2x4'
    ]
  },
  PLANT_LABELS: {
    name: 'Plant Label',
    tags: [
      'cult_plant_tag',
      'cult_plant_tag_large',
      'cult_plant_tag_small',
      'cult_plant_tag_all'
    ]
  },
  LOCATION_LABELS: {
    name: 'Location Label',
    tags: [
      'location_section_tag',
      'location_zone_tag',
      'location_room_tag',
      'inv_location_section_tag',
      'inv_location_zone_tag',
      'inv_location_room_tag'
    ]
  },
  HARVEST_LABELS: {
    name: 'Harvest Batch Label',
    tags: [
      'harvest_batch_plant_label',
      'harvest_batch_plant_label_all',
    ]
  },
  RETAIL_RECEIPT: {
    name: 'Retail Receipt',
    tags: [
      'RETAIL_RECEIPT'
    ]
  },
  PRODUCTION_INV_PACKAGE_TAG_LABELS: {
    name: 'Draft Complete Processing/Infusion Label',
    tags: [
      'production_inv_package_tag_label_all',
      'production_inv_package_tag_label',
    ]
  },
  COUPON_LABELS: {
    name: 'Coupon Labels',
    tags: [
      'coupon_all'
    ]
  },
};
