import * as types from '../../constants/actionTypes';
import initialState from '../../constants/initialState';

export default function currentOrderReducer (state = initialState.cart.currentOrder, action){
  const {order} = action;
  switch (action.type) {
  case types.SET_CURRENT_ORDER:
    return order;
  default:
    return state;
  }
}
