import React from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {connect} from 'react-redux';
import { reduxForm, Field, FieldArray, change } from 'redux-form';
import get from 'lodash.get';
import { I18n } from 'react-redux-i18n';
import { Button, ButtonToolbar, Col, Form, FormGroup, Row } from 'react-bootstrap';
import { user } from '../../constants/imageTypes';
import TextInput from '../common/form/TextInput';
import ImageField from '../common/form/files/ImageField';
import InternationalDateTimePickerInput from '../common/form/InternationalDateTimePickerInput';
import CheckBoxInput from '../common/form/CheckBoxInput';
import ReactSelectInput from '../common/form/ReactSelectInput';
import UserFacilityRoles from './UserFacilityRoles';
import UserAccountPhones from './UserAccountPhones';
import CountryField from '../common/form/redux-form/CountryField';
import PostalCodeField from '../common/form/redux-form/PostalCodeField';
import { USER_FORM } from '../../constants/forms';
import * as itemNames from '../../constants/itemNames';
import PermissionWrapper from '../common/PermissionWrapper';
import * as p from '../../constants/permissions';
import ContentConcealer from '../common/concealers/ContentConcealer';
import {isFeatureEnabled} from '../../selectors/featureToggles';
import {isLeafPaConfigPackClosedLoopFacility} from '../../selectors/facilitiesSelectors';
import ComboboxInput from '../common/form/ComboboxInput';

const statuses = [
  { value: 1, text: I18n.t('userAccounts.active') },
  { value: 0, text: I18n.t('userAccounts.inactive') }
];

const approval_statuses = [
  { value: 'pending', text: I18n.t('userAccounts.pending') },
  { value: 'approved', text: I18n.t('userAccounts.approved') },
  { value: 'rejected', text: I18n.t('userAccounts.rejected') },
  { value: 'revoked', text: I18n.t('userAccounts.revoked') }
];

const userTypeOptions = [
  { value: 'platform_user', text: I18n.t('userAccounts.userTypes.platform_user') },
  { value: 'affiliated_only', text: I18n.t('userAccounts.userTypes.affiliated_only') },
];

export const UserAccountForm = (props) => {
  const {
    actions,
    facilities,
    userRoles,
    employeeTitles,
    handleSubmit,
    invalid,
    isModify,
    change,
    initialCountryCode,
    integrationState,
    getFormValue,
    initialValues,
    hasBiotrackFacilities,
    isTagIntegrated,
    userRequiresStateLicense,
    internationalModuleEnabled,
    isLeafPaConfigPackClosedLoopFacility,
    isRegulationConsoleUserApproval,
  } = props;

  const facilityRoles = getFormValue('facilityRoles') ? getFormValue('facilityRoles') : [];
  const isActive = getFormValue('active');
  const isGotApproval = getFormValue('approval_status') == 'approved';
  const authorityIntegrationId = getFormValue('authority_integration_id');
  const userType = getFormValue('user_type');
  const isAffiliatedOnlyUser = userType === 'affiliated_only';

  const disableStatus = isLeafPaConfigPackClosedLoopFacility && isRegulationConsoleUserApproval ?  !isGotApproval : !authorityIntegrationId;

  const onToggle = (event) => {};
  const alertBoxForMultipleFacilities =
    facilities.length > 1 ? (
      isAffiliatedOnlyUser
        ? <div className='alert alert-info'>{I18n.t('userAccounts.userRolesWillBeCleared')}</div>
        : <div className='alert alert-info'>{I18n.t('userAccounts.requiresAtLeastOneRoleBelow')}</div>
    ) : (
      ''
    );

  const { isPaLeaf, isWaLeaf, hasBiotrack, isCanada } = integrationState;
  const showRoles = isActive && (!isPaLeaf || (isPaLeaf && isModify));
  const countryCode = getFormValue('country_code') ? getFormValue('country_code') : initialCountryCode;

  const internationaLanguageOptions = [
    { value: isCanada ? 'enCA' : 'en', text: I18n.t('nav.English')},
    { value: 'es', text:  I18n.t('nav.Spanish') },
    { value: 'fr', text: I18n.t('nav.French')},
    { value: 'it', text: I18n.t('nav.Italian') },
  ];

  const onClickGenerateAuthenticationKey = (event) => {
    event.stopPropagation();
    event.preventDefault();

    actions.getItem(`/api/users/new_key`, itemNames.authenticationKey).then((data) => {
      if (data && data.key) {
        change('authentication_key', data.key);
      }
    });
  };

  return (
    <form className='user-account-form' noValidate autoComplete='off'>
      <Row>
        <Col xs={6} md={4}>
          <FormGroup>
            <h4>{I18n.t('userAccounts.loginInformation')}</h4>
            <Field
              name='name'
              component={TextInput}
              autoComplete='off'
              props={{
                label: I18n.t('userAccounts.username'),
                placeholder: I18n.t('userAccounts.username').toLowerCase(),
                isRequired: true
              }}
            />
            {isModify ? (
              <FormGroup className='form-input'>
                <Form.Label>{I18n.t('userAccounts.password')}</Form.Label>
                <div
                  style={{
                    backgroundColor: '#e5e5e5',
                    padding: '10px',
                    fontSize: '0.9em',
                    fontWeight: 'bold',
                    color: '#444'
                  }}
                >
                  {I18n.t('userAccounts.modifyPassword')}
                </div>
              </FormGroup>
            ) : (
              <FormGroup className='form-input'>
                <Form.Label>{I18n.t('userAccounts.password')}</Form.Label>
                <div
                  style={{
                    backgroundColor: '#e5e5e5',
                    padding: '10px',
                    fontSize: '0.9em',
                    fontWeight: 'bold',
                    color: '#444'
                  }}
                >
                  {I18n.t('userAccounts.createPassword')}
                </div>
              </FormGroup>
            )}
            {isLeafPaConfigPackClosedLoopFacility && isRegulationConsoleUserApproval &&
              <Field
                name='user_type'
                component={ReactSelectInput}
                props={{
                  label: I18n.t('userAccounts.userType'),
                  options: userTypeOptions,
                  placeholder: I18n.t('common.form.select'),
                }}
              />
            }
            <Field
              name='card_swipe_id'
              component={TextInput}
              props={{
                label: I18n.t('userAccounts.cardSwipe'),
                placeholder: I18n.t('userAccounts.cardSwipe').toLowerCase(),
                isRequired: false
              }}
            />
            <Field
              name='email'
              component={TextInput}
              props={{
                label: I18n.t('userAccounts.email'),
                placeholder: I18n.t('userAccounts.email').toLowerCase(),
                isRequired: true
              }}
            />
            <Field
              name='confirm_email'
              component={TextInput}
              props={{
                label: I18n.t('userAccounts.confirmEmail'),
                placeholder: I18n.t('userAccounts.confirmEmail').toLowerCase(),
                isRequired: true
              }}
            />
          </FormGroup>
          <FormGroup>
            <h4>{I18n.t('userAccounts.User IDs')}</h4>
            <Field
              name={isWaLeaf || hasBiotrack ? 'state_integration_id' : 'state_id'}
              component={TextInput}
              props={{
                isRequired: hasBiotrackFacilities || userRequiresStateLicense,
                label: I18n.t('userAccounts.stateLicense'),
                placeholder: I18n.t('userAccounts.stateLicense').toLowerCase()
              }}
            />
            <Field
              name='employee_id'
              component={TextInput}
              props={{
                label: I18n.t('userAccounts.employeeID'),
                placeholder: I18n.t('userAccounts.employeeID').toLowerCase(),
                isRequired: hasBiotrackFacilities
              }}
            />
            {isPaLeaf && isModify ? (
              <Field
                name='state_integration_id'
                component={TextInput}
                props={{
                  label: I18n.t('userAccounts.stateIntegrationId'),
                  disabled: true
                }}
              />
            ) : null}
            {isWaLeaf ? (
              <Field
                name='state_integration_id'
                component={TextInput}
                props={{
                  label: I18n.t('userAccounts.stateIntegrationId')
                }}
              />
            ) : null}
            {isTagIntegrated ? (
              <div>
                <Field
                  name='authentication_key'
                  component={TextInput}
                  // disabled={true}
                  props={{
                    label: I18n.t('userAccounts.authenticationKey'),
                    disabled: true
                  }}
                />

                <Button
                  onClick={(event) => {
                    onClickGenerateAuthenticationKey(event);
                  }}
                  variant='primary'
                >
                  {I18n.t('userAccounts.generateKey')}
                </Button>
              </div>
            ) : null}
            <ContentConcealer show={get(initialValues, 'sso_enabled', false)}>
              <PermissionWrapper permissions={[p.manage_facility_users, p.manage_users]}>
                <Field
                  name='sso_id'
                  component={TextInput}
                  props={{
                    label: I18n.t('userAccounts.form.ssoId'),
                    disabled: get(initialValues, 'sso_user_has_primary_id', false)
                  }}
                />
              </PermissionWrapper>
            </ContentConcealer>
          </FormGroup>
        </Col>
        {showRoles ? (
          <Col xs={6} md={4}>
            <h4>{I18n.t('userAccounts.assignUserRole')}</h4>
            {alertBoxForMultipleFacilities}
            <FieldArray
              name='facilityRoles'
              props={{
                isRequired: facilities.length > 1
              }}
              component={UserFacilityRoles}
              facilities={facilities}
              integrationState={integrationState}
              userRoles={userRoles}
              facilityRoles={facilityRoles}
              isAffiliatedOnlyUser={isAffiliatedOnlyUser}
            />
          </Col>
        ) : (
          <Col xs={6} md={4}>
            &nbsp;
          </Col>
        )}
        <Col xs={6} md={4}>
          <ImageField
            name='image_file_id'
            fileName='image_file'
            change={change}
            imageType={user}
            value={getFormValue('image_file_id')}
            fileValue={getFormValue('image_file')}
          />

          <div className='tooltip-settings form-horizontal'>
            <Field
              name='userAccounts.tooltipSettings'
              component={CheckBoxInput}
              label={I18n.t('userAccounts.tooltipSettings')}
              onChange={onToggle}
            />
          </div>
          <ContentConcealer show={internationalModuleEnabled}>
            <PermissionWrapper permissions={[p.manage_facility_users, p.manage_users]}>
              <Field
                name='language'
                component={ReactSelectInput}
                props={{
                  label: I18n.t('userAccounts.PreferredLanguage'),
                  options: internationaLanguageOptions,
                  placeholder: I18n.t('common.form.select'),
                }}
              />
            </PermissionWrapper>
          </ContentConcealer>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <FormGroup>
            <h4>{I18n.t('userAccounts.personalInformation')}</h4>
            <Row className='personal-information'>
              <Col xs={4} sm={5} md={4} lg={3}>
                <Field
                  name='first_name'
                  component={TextInput}
                  props={{
                    label: I18n.t('userAccounts.firstName'),
                    isRequired: true,
                    placeholder: I18n.t('userAccounts.firstName').toLowerCase()
                  }}
                />
              </Col>
              <Col xs={3} sm={2} md={2}>
                <Field
                  name='middle_name'
                  component={TextInput}
                  props={{
                    label: I18n.t('userAccounts.middleInitial'),
                    maxLength: 1
                  }}
                />
              </Col>
              <Col xs={4} sm={5} md={4} lg={3}>
                <Field
                  name='last_name'
                  component={TextInput}
                  props={{
                    label: I18n.t('userAccounts.lastName'),
                    isRequired: true,
                    placeholder: I18n.t('userAccounts.lastName').toLowerCase()
                  }}
                />
              </Col>
              <Col xs={5} sm={4} md={3}>
                <InternationalDateTimePickerInput
                  name='birth_date'
                  props={{
                    label: I18n.t('userAccounts.birthDate'),
                    placeholder: I18n.t('userAccounts.birthDate').toLowerCase(),
                    enableTodayButton: false,
                    isRequired: hasBiotrackFacilities
                  }}
                />
              </Col>
              <Col xs={5} sm={5} md={4} lg={4}>
                <Field
                  name='address'
                  component={TextInput}
                  props={{
                    label: I18n.t('userAccounts.address'),
                    placeholder: I18n.t('userAccounts.address').toLowerCase()
                  }}
                />
              </Col>
              <Col xs={4} md={3} lg={2}>
                <Field
                  name='city'
                  component={TextInput}
                  props={{
                    label: countryCode === 'AU' ? I18n.t('common.form.suburb') : I18n.t('common.form.city'),
                    placeholder: countryCode === 'AU' ? I18n.t('common.form.suburb') : I18n.t('common.form.city')
                  }}
                />
              </Col>
              <CountryField
                change={change}
                name='country_code'
                stateFieldName='state'
                countryFieldSize={2}
                stateFieldSize={3}
                currentCountry={countryCode}
              />
              <Col xs={4} lg={2}>
                <PostalCodeField name='zip_code' countryCode={countryCode} />
              </Col>
            </Row>
            <FieldArray name='phones' component={UserAccountPhones} />
            <Row>
              <Col xs={12} sm={8} md={6}>
                <Field
                  name='employee_title'
                  component={ComboboxInput}
                  props={{
                    label: I18n.t('userAccounts.employeeTitle'),
                    placeholder: I18n.t('userAccounts.employeeTitlePlaceHolder'),
                    options: employeeTitles,
                    textKey: 'employee_title',
                    valueKey: 'id'
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={5} sm={4} md={3}>
                <InternationalDateTimePickerInput
                  name='hire_date'
                  props={{
                    label: I18n.t('userAccounts.hireDate'),
                    isRequired: hasBiotrackFacilities
                  }}
                />
              </Col>
              <Col xs={5} sm={4} md={3}>
                <InternationalDateTimePickerInput
                  name='termination_date'
                  props={{
                    label: I18n.t('userAccounts.terminationDate')
                  }}
                />
              </Col>
              <Col xs={4} sm={3} md={2}>
                <Field
                  name='active'
                  component={ReactSelectInput}
                  props={{
                    label: I18n.t('userAccounts.status'),
                    options: statuses,
                    placeholder: I18n.t('common.form.select'),
                    isRequired: true,
                    disabled: isPaLeaf && disableStatus
                  }}
                />
              </Col>
              {isPaLeaf && isLeafPaConfigPackClosedLoopFacility && isRegulationConsoleUserApproval &&
                <Col xs={4} sm={3} md={2}>
                <Field
                  name='approval_status'
                  component={ReactSelectInput}
                  props={{
                    label: I18n.t('userAccounts.approval_status'),
                    options: approval_statuses,
                    placeholder: I18n.t('common.form.select'),
                    isRequired: true,
                    disabled: true,
                    readOnly: true
                  }}
                />
              </Col>}
            </Row>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <FormGroup>
            <h4>{I18n.t('userAccounts.vehicleInformation')}</h4>
            <Row>
              <Col xs={4} md={3}>
                <Field
                  name='vehicle_make'
                  component={TextInput}
                  props={{
                    label: I18n.t('userAccounts.make'),
                    placeholder: I18n.t('userAccounts.make').toLowerCase()
                  }}
                />
              </Col>
              <Col xs={4} md={3}>
                <Field
                  name='vehicle_model'
                  component={TextInput}
                  props={{
                    label: I18n.t('userAccounts.model'),
                    placeholder: I18n.t('userAccounts.model').toLowerCase()
                  }}
                />
              </Col>
              <Col xs={4} md={3}>
                <Field
                  name='vehicle_license'
                  component={TextInput}
                  props={{
                    label: I18n.t('userAccounts.licensePlate'),
                    placeholder: I18n.t('userAccounts.licensePlate').toLowerCase()
                  }}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>
      </Row>
      <div className='buttons'>
        <ButtonToolbar className='float-right'>
          {isModify && (
            <Button type='submit' onClick={handleSubmit(actions.saveAndResetPassword, initialValues)} variant='primary'>
              {I18n.t('userAccounts.resetPassword')}
            </Button>
          )}
          <Button
            type='submit'
            onClick={handleSubmit(actions.save, initialValues)}
            variant='primary'
            disabled={invalid}
          >
            {I18n.t('userAccounts.save')}
          </Button>
        </ButtonToolbar>
      </div>
    </form>
  );
};

UserAccountForm.propTypes = {
  authenticationKey: PropTypes.string,
  facilities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  actions: PropTypes.shape({
    save: PropTypes.func.isRequired,
    saveAndResetPassword: PropTypes.func
  }),
  userRoles: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  initialValues: PropTypes.object,
  validate: PropTypes.func.isRequired,
  isModify: PropTypes.bool.isRequired,
  change: PropTypes.func,
  integrationState: PropTypes.object.isRequired,
  initialCountryCode: PropTypes.string,
  getFormValue: PropTypes.func,
  hasBiotrackFacilities: PropTypes.bool,
  isTagIntegrated: PropTypes.bool,
  userRequiresStateLicense: PropTypes.bool,
  internationalModuleEnabled: PropTypes.bool,
  isLeafPaConfigPackClosedLoopFacility: PropTypes.bool,
  isRegulationConsoleUserApproval: PropTypes.bool,
  employeeTitles: PropTypes.array.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    isLeafPaConfigPackClosedLoopFacility: isLeafPaConfigPackClosedLoopFacility(state),
    isRegulationConsoleUserApproval: isFeatureEnabled(state)('feature_regulation_console_user_approval'),
  };
};


export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: USER_FORM
  })
)(UserAccountForm);
