import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';
import {getUnpaginatedData} from '../../actions/apiActions';
import {handleComplexSelectRow} from '../../actions/helpers/selectedDataHelper';
import * as dataNames  from '../../constants/dataNames';
import {getPhysiciansPageProps} from '../../selectors/physiciansSelectors';
import PageTitle from '../common/PageTitle';
import InProgressOverlay from '../common/InProgressOverlay';
import TablePageWrapper from '../common/grid/TablePageWrapper';


export class PhysiciansPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.editPhysician = this.editPhysician.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.switchTab = this.switchTab.bind(this);

    const columns = [
      {
        name: 'physicians.table.firstName',
        dataId: 'first_name',
        width: '200px',
        dataAlign: 'left',
        headerAlign: 'left',
      },
      {
        name: 'physicians.table.lastName',
        dataId: 'last_name',
        width: '500px',
        dataAlign: 'left',
        headerAlign: 'left',
      },
    ];

    const tabs = [
      {
        id: 'activeTab',
        eventKey: 'active',
        title: 'physicians.table.active',
        path: '/retail/physicians/active',
        actions: [
          {
            id: 'addPhysician',
            path: '/retail/physicians/create',
            text: 'physicians.actions.create',
            requireSelect: false,
            glyph: 'plus-sign',
          },
          {
            id: 'editPhysician',
            func: this.editPhysician,
            text: 'physicians.actions.edit',
            requireSelect: true,
            glyph: 'edit',
          },
        ],
      },
      {
        id: 'inactiveTab',
        eventKey: 'inactive',
        title: 'physicians.table.inactive',
        path: '/retail/physicians/inactive',
        actions: [
          {
            id: 'editPhysician',
            func: this.editPhysician,
            text: 'physicians.actions.edit',
            requireSelect: true,
            glyph: 'edit',
          },
        ],
      }
    ];

    this.state = {
      columns,
      tabs,
      loading: true,
    };
  }

  componentWillMount() {
    this.props.actions
      .getUnpaginatedData('/api/physicians', dataNames.physicians)
      .then(() => this.setState({loading: false}))
      .catch(() => this.setState({loading: false}));
    this.props.actions.handleComplexSelectRow([], dataNames.physicians, 'clear');
  }

  editPhysician() {
    const {selectedRows: [id]} = this.props;
    this.props.actions.push(`/retail/physicians/modify/${id}`);
  }

  handleSelect(row) {
    const {selectedRows} = this.props;
    const action = selectedRows.indexOf(row.id) > -1 ? 'remove' : 'set';
    this.props.actions.handleComplexSelectRow([row], dataNames.physicians, action);
  }

  switchTab(activeTab) {
    const {tabs} = this.state;
    const tab = tabs.find(tab => tab.eventKey === activeTab) || tabs[0];
    this.props.actions.push(tab.path);
  }

  render() {
    const {data, selectedRows, activeTab} = this.props;
    const {tabs, columns, loading} = this.state;

    return (
      <div>
        <PageTitle primaryText={I18n.t('physicians.table.title')}/>
        <InProgressOverlay isActive={loading} message='physicians.get.loading' translate={true}/>
        <TablePageWrapper
          settingKey='retail-physicians'
          columns={columns}
          data={data}
          selectedRows={selectedRows}
          handleSelect={this.handleSelect}
          hideExport={true}
          hideScanSearch={true}
          external={false}
          tabs={tabs}
          activeTab={activeTab}
          switchTab={this.switchTab}
          bstProps={{
            selectRow: {
              mode: 'radio',
              selected: {selectedRows},
              onSelect: this.handleSelect,
              className: 'table table-sm table-bordered table-striped retail-group-table',
            },
            options: {
              defaultSortName: 'first_name',
              defaultSortOrder: 'asc',
            },
          }}
        />
      </div>
    );
  }
}

PhysiciansPage.propTypes = {
  actions: PropTypes.shape({
    getUnpaginatedData: PropTypes.func.isRequired,
    handleComplexSelectRow: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }),
  selectedRows: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  activeTab: PropTypes.string.isRequired,
};

function mapDispatchToProps(dispatch) {
  const actions = {getUnpaginatedData, handleComplexSelectRow, push};

  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(getPhysiciansPageProps, mapDispatchToProps)(PhysiciansPage);
