import React from 'react';

const RefundItemsHeader = (props) => {

  return (
    <thead>
      <tr>
        <th colSpan={4}>Refund Items</th>
      </tr>
      <tr>
        <th>Product</th>
        <th>Qty.</th>
        <th>Ea.</th>
        <th>Line</th>
      </tr>
    </thead>
  );

};

export default RefundItemsHeader;


