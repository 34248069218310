export const TEST_TYPES = {
  cannabinoidPotency: 'Cannabinoid Potency',
  terpeneProfile: 'Terpene Profile',
  microbials: 'Microbial',
  residualSolvents: 'Residual Solvents',
  heavyMetals: 'Heavy Metals',
  mycotoxins: 'Mycotoxins',
  pesticides: 'Pesticides' 
};

export default TEST_TYPES;
