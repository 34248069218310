import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import {Row, Col, } from 'react-bootstrap';
import TextInput from '../../../common/form/TextInput';
import AddItem from '../../../common/form/AddItem';

const Directions = ({fields, getFormValue, change}) => {
  return (
    <div>
      {fields.map((line, index) => {
        return (
          <Row key={`${line}`}>
            <Col xs={10} md={9}>
              <Field
                name={`${line}.text`}
                component={TextInput}
                props={{label:I18n.t('ei.processingTypes.form.directionsStep', {step_num: index + 1})}}
              />
            </Col>
            <Col xs={2} md={1}>
              <AddItem
                length={fields.length}
                textMode='left'
                alignMode='right'
                addAction={() => {
                  return fields.push({});
                }}
                showPlus={fields.length - 1 === index}
                removeAction={() => fields.remove(index)}
              />
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

Directions.propTypes = {
  fields: PropTypes.object.isRequired,
  getFormValue: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired
};

export default Directions;

