import React from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';
import debounce from 'lodash.debounce';
import {pick} from 'lodash';
import keyby from 'lodash.keyby';
import get from 'lodash.get';
import Autosuggest from 'react-autosuggest';
import { I18n } from 'react-redux-i18n';
import { getContextQueryString } from '../../../util/solrHelpers';
import { searchTermDuration } from '../../../constants/solr';
import Search from './Search';
import FilterTabs from './FilterTabs';
import ActionBar from './ActionBar';
import Table from './Table';
import {dataUpdateSetAvailable} from '../../../selectors/dataUpdateSelectors';
import * as dataNames from '../../../constants/dataNames';

class TablePage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    const pageSize = (props.settings && props.settings.per_page) || 20;
    const searchString = this.props.searchString;
    const scanSearchString = '';
    const scanSearchEnabled = false;
    const historyEnabled = false;
    const sort = (props.sort !== undefined) ? props.sort : '';
    const page = 1;
    const start = 0;
    const {columns, scanSearchCol} = props;
    const shiftPressed = false;
    const lastRowChecked = undefined;
    const pageSizeList = props.pageSizeList || [5, 10, 20, 50];
    this.state = {
      pageSize,
      searchString: typeof searchString === 'string' ? searchString : '',
      scanSearchString,
      columns,
      scanSearchEnabled,
      historyEnabled,
      page,
      sort,
      start,
      scanSearchCol,
      shiftPressed,
      lastRowChecked,
      pageSizeList,
      initialized: false,
      // Auto suggest specific
      suggestString: '',
      lastTerm: null,
      lastSuggestTerm: '',

      solrErrorMessage: false,
      errorTime: false,
      searchTime: false,
      suggestTime: false,
      searchTermKey: false,

      suggestStringMinLength: 4,
    };
    this.updateSearch = this.updateSearch.bind(this);
    this.setVisibleColumns = this.setVisibleColumns.bind(this);
    this.setPageSize = this.setPageSize.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.toggleScanSearch = this.toggleScanSearch.bind(this);
    this.toggleHistory = this.toggleHistory.bind(this);
    this.callExternalSearch = this.callExternalSearch.bind(this);
    this.throttledExternalSearch = throttle(this.callExternalSearch, props.autoRefresh, {trailing: false});
    this.debouncedExternalSearch = debounce(this.callExternalSearch, 500).bind(this);
    this.setScanSearchCol = this.setScanSearchCol.bind(this);
    this.listenForEnter = this.listenForEnter.bind(this);
    this.handleShiftDown = this.handleShiftDown.bind(this);
    this.handleShiftUp = this.handleShiftUp.bind(this);
    this.handleSelect = this.handleSelect.bind(this);

    // SUGGEST METHODS
    this.getAutoSuggestInput = this.getAutoSuggestInput.bind(this);
    this.updateSuggestString = this.updateSuggestString.bind(this);
    this.getSuggestions = this.getSuggestions.bind(this);
    this.debouncedGetSuggestions = debounce(this.getSuggestions, 500).bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.onChange = this.onChange.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);
    this.getSuggestionValue = this.getSuggestionValue.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.setSuggestString = this.setSuggestString.bind(this);
    this.handleSolrErrorMessaging = this.handleSolrErrorMessaging.bind(this);
    this.setSolrSearchTerm = this.setSolrSearchTerm.bind(this);
    this.setSolrSearchTermInLocalState = this.setSolrSearchTermInLocalState.bind(this);
    this.useAutoSuggest = this.useAutoSuggest.bind(this);

    // this.registerRef = this.registerRef.bind(this);
    this.doSearch = this.doSearch.bind(this);
    this._tableContainer = React.createRef();
    this.searchDelay;
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleShiftDown);
    document.addEventListener('keyup', this.handleShiftUp);
    this.props.tableActions.setData && this.props.tableActions.setData([], dataNames.autoSuggestions);
    this.setSolrSearchTermInLocalState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeTab != this.props.activeTab || nextProps.columns != this.props.columns) {
      const {columns} = this.state;
      const newColumns = nextProps.columns.map((column, index) => ({
        ...column,
        hidden: columns[index] ? columns[index].hidden : column.hidden
      }));
      this.setState({columns: newColumns});
    }
    if (nextProps.searchString != this.props.searchString) {
      this.updateSearch({target: {value: nextProps.searchString}});
    }
    this.handleSolrErrorMessaging(nextProps);
    if(dataUpdateSetAvailable(nextProps.dataUpdateAvailable, this.props.dataUpdateAvailable)) {
      this.debouncedExternalSearch();
      this.props.dataUpdated();
    }
  }



  componentDidUpdate(prevProps, prevState) {
    if (this.props.external) {
      const {pageSize, searchString, scanSearchString, scanSearchEnabled, sort, page, scanSearchCol, filter} = this.state;

      // if activeTab has changed, reset start and page
      if (prevProps.activeTab && prevProps.activeTab !== this.props.activeTab) {
        this.setState({start: 0, page: 1});
      }

      if ((this.props.columnSettingsAreLoaded && pageSize != prevState.pageSize) ||
        searchString != prevState.searchString ||
        scanSearchString != prevState.scanSearchString ||
        scanSearchEnabled != prevState.scanSearchEnabled ||
        sort != prevState.sort ||
        page != prevState.page ||
        filter != prevState.filter ||
        scanSearchCol != prevState.scanSearchCol) {
        this.debouncedExternalSearch();
      } else if (this.props.external && this.props.autoRefresh) {
        this.throttledExternalSearch();
      } else {
        if(!this.state.initialized){
          this.setState({initialized: true}, () => { //eslint-disable-line
            if (this.props.searchOnInitialization) this.debouncedExternalSearch();
          });
        }
      }

      if (scanSearchEnabled && this.props.data !== prevProps.data) {
        this.props.handleSelect('set', this.props.data);
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleShiftDown);
    document.removeEventListener('keyup', this.handleShiftUp);
    this.setSolrSearchTerm();
  }

  useAutoSuggest() {
    return this.props.useAutoSuggest && !this.state.scanSearchEnabled;
  }

  handleShiftDown(event) {
    const {shiftPressed} = this.state;
    if (!shiftPressed && event.key == 'Shift') {
      this.setState({shiftPressed: true});
    }
  }

  handleShiftUp(event) {
    const {shiftPressed} = this.state;
    if (shiftPressed && event.key == 'Shift') {
      this.setState({shiftPressed: false});
    }
  }

  handleSelect(isSelected, rows) {
    const {shiftPressed, lastRowChecked} = this.state;
    const {data} = this.props;
    let selectedRows = rows;
    if (rows.length == 1) {
      const currentIndex = data.findIndex(datum => datum.id === rows[0].id);
      if (shiftPressed && lastRowChecked !== undefined) {
        if(isSelected){
          if (lastRowChecked < currentIndex) {
            selectedRows = data.filter((datum, index) => index > lastRowChecked && index <= currentIndex);
          } else {
            selectedRows = data.filter((datum, index) => index < lastRowChecked && index >= currentIndex);
          }
        }else{
          if (lastRowChecked < currentIndex) {
            selectedRows = data.filter((datum, index) => index >= lastRowChecked && index <= currentIndex);
          } else {
            selectedRows = data.filter((datum, index) => index <= lastRowChecked && index >= currentIndex);
          }
        }
      }
      this.setState({lastRowChecked: currentIndex});
    }
    this.props.handleSelect(isSelected ? 'add' : 'remove' , selectedRows);
  }

  setVisibleColumns(event) {
    const {columns} = this.state;
    const {id} = event.target;
    const newColumns = columns.map((column, index) => {
      if (index != id) {
        return column;
      }
      return {...column, ...{hidden: !column.hidden}};
    });
    const cleanSort = () => {
      const sortingList = keyby(get(this._tableContainer, 'current.wrappedInstance.ref.current.store.sortList', []), 'sortField');
      newColumns.find(column => column.hidden && sortingList[column.dataId]) && this._tableContainer.current.wrappedInstance.ref.current.cleanSort();
    };
    cleanSort();
    this.setState({columns: newColumns}, () => {
      if (this.props.updateColumnSettings) {
        const columnSettings = newColumns.map(column => (pick(column, ['name', 'dataSort', 'hidden'])));
        this.props.updateColumnSettings({data: columnSettings, per_page: this.state.pageSize});
      }
    });
  }

  listenForEnter(event) {
    const {scanSearchEnabled} = this.state;
    if (scanSearchEnabled && event.key === 'Enter') {
      event.preventDefault();
      const {searchString, scanSearchString} = this.state;
      const newSearchString = searchString.replace(/ /g, '\ ');
      const searchArray = scanSearchString.split('^');
      const index = searchArray.indexOf(searchString);
      if (index == -1) {
        searchArray.push(newSearchString);
      }

      this.setState({scanSearchString: searchArray.join('^'), searchString: ''});
    }
  }

  updateSearch(event) {
    const {value} = event.target;

    this.setState({
      searchString: value,
    });

    // removes old request for search while user is still typing
    clearTimeout(this.searchDelay);

    // 1/3 second delay ensures that too many api request are not called
    // while user is still typing
    this.searchDelay = setTimeout(() => {
      this.onPageChange(1, this.state.pageSize);
      this.props.handleSearch(this.state.searchString);
    }, 300);

    this._tableContainer.current.wrappedInstance.handlePaginationData(1, this.state.pageSize);
  }

  doSearch(value) {
    this._tableContainer.current.wrappedInstance.handleSearch(value);
  }

  callExternalSearch(suggestString = false) {
    const {pageSize, start, sort, searchString, scanSearchEnabled, scanSearchCol, filter, lastTerm, lastSuggestTerm} = this.state;
    let {scanSearchString} = this.state;
    const {isSolrListing} = this.props;

    // For Solr listings, let's not do anything until the query is at least 3 characters in length or
    // until they hit enter (only applies when scan searching is enabled and is handled elsewhere).
    if(!suggestString) {
      if (isSolrListing && (searchString && searchString.length < 3)) {
        return;
      }
    }

    const isEmptyString = (string) => {
      return (typeof string === 'string' ? string : '').trim() === '';
    };

    const getScanSearchString = () => {
      const useString = this.useAutoSuggest()
        ? suggestString
        : !isEmptyString(scanSearchString)
          ? scanSearchString
          : searchString;
      return typeof useString === 'string' ? useString : '';
    };

    if (scanSearchEnabled) {
      scanSearchString = getScanSearchString();
      const searchArray = scanSearchString.split('^');
      if (scanSearchString.trim() === '') {
        return false;
      }
      const filterFields = searchArray.reduce((accumulator, current) => {
        if(current === '') {
          return accumulator;
        }else if(accumulator === '') {
          return `${scanSearchCol}:${current}`;
        }else{
          return `${accumulator} OR ${scanSearchCol}:${current}`;
        }
      }, '');
      const scanFilter = scanSearchString ? filterFields : '';
      if (scanFilter === '') {
        this.props.externalSearch(sort, ' ', pageSize, start, filter, scanSearchEnabled);
      } else {
        const combinedFilter = filter ? `${filter} AND (${scanFilter})` : scanFilter;
        this.props.externalSearch(sort, undefined, pageSize, start, combinedFilter, scanSearchEnabled);
      }
    } else {
      let string = suggestString ? suggestString : searchString;
      if (this.useAutoSuggest() && !suggestString) {
        const tempString = searchString || lastTerm || lastSuggestTerm;
        string = typeof tempString !== 'string' ? '' : tempString;
        if (!suggestString && string) { // Force true because this occurs on context change (tab change) so the term needs to be re-run.
          this.onChange({}, {newValue: string}, true);
        }
      }
      this.props.externalSearch(sort, string.replace(/ /g, '\ '), pageSize, start, filter, scanSearchEnabled);
    }
  }

  /*******************************
   * BEGIN ** SUGGEST SPECIFIC CODE
   *******************************/

  /**
   * Called when component mounts to set the searchTermKey and get the term from redux store if present.
   * @param props
   */
  setSolrSearchTermInLocalState (props) {
    if (!props.tableActions || !props.tableActions.setSolrSearchTerm) {
      return false;
    }
    const temp = getContextQueryString(this.props.autoSuggestContextKey);
    const key = typeof temp === 'string' ? temp.split('?').shift() : '';
    const term = get(props, `solrSearchTerms.${key}`, {});
    const currentFacilityId = get(props, 'facility.id', null);
    const facilityId = get(props.solrSearchTerms, 'facilityId', false);
    const searchTerm = get(term, 'term', false);
    const currentTime = new Date().getTime();
    const time = get(term, 'time', 0);
    const timeElapsed = currentTime - time;
    const resetSolrSearchTermsForFacility = (currentFacilityId) => {
      props.tableActions.setSolrSearchTerm(false, false, currentFacilityId);
    };
    // We only preserve this when transitioning from on tab to another.  Going to a wholly different place and coming
    // back should not be preserving the search.  At least that's not the story I am working.
    if (searchTerm && timeElapsed < searchTermDuration && currentFacilityId === facilityId) {
      this.onChange({}, {newValue: searchTerm}, true);
    } else {
      resetSolrSearchTermsForFacility(currentFacilityId);
    }
    this.setState({
      searchTermKey: key
    });
  }

  /**
   * Called on component unmount to preserve the last search term in redux.  Expires according to constant
   * "searchTermDuration"
   */
  setSolrSearchTerm(){
    if (this.state.searchTermKey && this.props.tableActions && this.props.tableActions.setSolrSearchTerm) {
      this.props.tableActions.setSolrSearchTerm(this.state.searchTermKey, this.state.suggestString);
    }
  }

  /**
   * On Suggest field change event where newValue = full string value
   * @param e
   * @param data
   * @param force - passed down to ensure a call is done for suggestions; used when context has changed and
   * term is the same.
   */
  onChange (e, data, force = false) {
    const {scanSearchEnabled} = this.state;
    const newString = data.newValue;
    this.updateSuggestString(newString)
      .then(() => {
        this.debouncedGetSuggestions(force);
        if (scanSearchEnabled) {
          this.callExternalSearch(newString);
        }
      });
  }

  /**
   * Where e.key == key pressed.
   * @param e
   */
  onKeyPress(e){
    if (this.onEnterKeyPressed(e)) {
      this.onPageChange(1, this.state.pageSize);
      this.callExternalSearch(this.state.suggestString);
    }
  }

  // During testing got an error that these weren't present in some cases with the autosuggest component.
  // onSuggestionsFetchRequested is the same thing as onChange so we already have it in a way that
  // makes sense in our code.  Added these to avoid mystery errors.
  onSuggestionsFetchRequested(){}
  onSuggestionsClearRequested(){}

  /**
   * Updates local state value of suggestString
   * @param newString
   * @returns {*}
   */
  updateSuggestString(newString){
    return new Promise((resolve) => {
      if(newString === this.state.suggestString){
        resolve();
      }
      this.setSuggestString(newString)
        .then(() => resolve());
    });
  }

  /**
   * Calls solr for suggestions based on suggestString
   */
  getSuggestions(force = false){
    const suggestString = this.state.suggestString;
    if(suggestString.length === 0){ // If I've backspaced down my list... when I hit no characters load the default
      this.setState({lastSuggestTerm: suggestString, lastTerm: suggestString, searchString: suggestString}, () => {
        this.callExternalSearch(suggestString);
      });
      return false;
    }
    if(suggestString === this.state.lastSuggestTerm && !force){ // Don't waste effort
      return false;
    }
    if(suggestString.length < this.state.suggestStringMinLength && !force){ // Limit is 2 characters
      this.setState({lastSuggestTerm: ''});
      this.props.tableActions.setData([], dataNames.autoSuggestions);
      return false;
    }
    this.setState({lastSuggestTerm: suggestString});
    const url =  `/api/suggest/${getContextQueryString(this.props.autoSuggestContextKey)}&term=${this.state.suggestString}`;

    const message = 'cultivation.finishedProduct.table.solrError';
    const timeoutInMs = 10000;
    const config = {
      timeout: {
        ms: timeoutInMs,
        message,
        action: this.props.setSolrErrorMessage,
      },
      errorHandler: {
        message,
        action: () => {
          this.updateSearch({target: {value: suggestString}});
          this.props.tableActions.setData && this.props.tableActions.setData([], dataNames.autoSuggestions);
        },
        clearOnSuccess: false,
      }
    };
    this.props.getData(url, dataNames.autoSuggestions, null, null, null, config)
      .catch(() => {}); // Prevents warnings in console.  We've already handled the case.
  }

  /**
   * Simple helper for readability.
   * @param e
   * @returns {boolean}
   */
  onEnterKeyPressed(e){
    return this.getKeyPressed(e) === 'enter';
  }

  /**
   * Simple helper for string consistency.
   * @param e
   * @returns {*}
   */
  getKeyPressed(e){
    return typeof e.key !== 'string'
      ? e.key // numbers
      : e.key.length > 1
        ? e.key.toLowerCase() // lower case enter and others
        : e.key; // single characters
  }

  /**
   * Returns plain text version of the suggestion search term to the AutoSuggest component.
   * @param suggestion
   * @returns {*}
   */
  getSuggestionValue(suggestion){
    const string = get(suggestion, 'term', '');
    const term = string.replace(/<[^>]*>?/gm, '');
    this.callExternalSearch(term);
    this.setState({lastSuggestTerm: term});
    return term;
  }

  /**
   * Renders an individual item in the AutoSuggest component.
   * @param suggestion
   * @returns {*}
   */
  renderSuggestion(suggestion){
    const html = suggestion.term;
    return (<div style={{fontSize: 'smaller'}} dangerouslySetInnerHTML={{__html: html}} />); //eslint-disable-line
  }

  /**
   * Returns the AutoSuggest input component which is then used in our search.
   * @returns {*}
   */
  getAutoSuggestInput(){
    if (!this.useAutoSuggest()) {
      return false;
    }
    const inputProps = {
      placeholder: I18n.t(this.props.autoSuggestPlaceholder),
      value: this.state.suggestString,
      onChange: this.onChange,
      onKeyDown: this.onKeyPress,
    };
    return (<Autosuggest
      suggestions={this.props.autoSuggestions}
      onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
      onSuggestionsClearRequested={this.onSuggestionsClearRequested}
      getSuggestionValue={this.getSuggestionValue}
      renderSuggestion={this.renderSuggestion}
      inputProps={inputProps}
    />);
  }

  /**
   * Sanitize string ensuring what we set in local state always is a string.
   * @param suggestString - suggestString
   * @param newState - allow setting additional state with this.
   */
  setSuggestString(suggestString, newState = {}){
    return new Promise((resolve) => {
      if(typeof suggestString !== 'string'){
        suggestString = '';
      }
      this.setState(Object.assign({}, {suggestString}, newState), () => {
        resolve();
      });
    });
  }

  /**
   * Handles setting and clearing the error message string in local state and setting timestamp
   * for comparison so we know when to show an error vs default message.
   * @param nextProps
   */
  handleSolrErrorMessaging (nextProps) {
    if (JSON.stringify(this.props.solrErrorMessage) !== JSON.stringify(nextProps.solrErrorMessage)) {
      const solrErrorMessage = get(nextProps, 'solrErrorMessage.message', false);
      this.setState({solrErrorMessage, errorTime: new Date().getTime()});
    }
  }

  /*******************************
   * END ** SUGGEST SPECIFIC CODE
   *******************************/

  setPageSize(pageSize) {
    this.setState({pageSize}, () => {
      if (this.props.updateColumnSettings) {
        this.props.updateColumnSettings({data: this.state.columns, per_page: pageSize});
      }
    });

    this._tableContainer.current.wrappedInstance.handlePaginationData(1, pageSize);
  }

  exportCSV() {
    this._tableContainer.current.wrappedInstance.handleExportCSV();
  }

  setScanSearchCol(eventKey, event) {
    this.setState({scanSearchCol: eventKey});
  }

  filter(filter) {
    this.setState({filter});
    this.onPageChange(1, this.state.pageSize);
    this._tableContainer.current.wrappedInstance.filter(filter);
  }

  toggleScanSearch() {
    const {scanSearchEnabled} = this.state;
    const pageSize = scanSearchEnabled ? 20 : 50;
    this.props.handleSelect('clear');
    const pageSizeList = scanSearchEnabled ? [5, 10, 20, 50] : [5, 10, 20, 50, 10000];
    this.setState({scanSearchEnabled: !scanSearchEnabled,
      pageSize,
      searchString: '',
      scanSearchString: '',
      pageSizeList: pageSizeList
    });
    this.onPageChange(1, pageSize);
  }

  toggleHistory() {
    this.setState({historyEnabled: !this.state.historyEnabled});
  }

  onSortChange(sortName, sortOrder) {
    this.setState({sort: `${sortName} ${sortOrder}`});
  }

  onPageChange(page, pageSize) {
    const start = pageSize * (page - 1);
    this.setState({page, start, lastRowChecked: undefined});
  }

  render() {
    const {pageSize, searchString, columns, scanSearchEnabled, scanSearchCol, historyEnabled, pageSizeList} = this.state;
    const {data, selectedRows, tabs, className, switchTab, bstProps, activeTab, noSelectionMode, showSelectedCount, toggleScanIdSearch,
      actions, dataTotalSize, external, hideSearch, hideExport, hideScanSearch, showHistory, hideTableControls, searchPlaceholder, settingKey, useRenderDataFlag, hidePagination, filterTabsChildren} = this.props;

    // validation for default sort field
    if (bstProps && bstProps.options && bstProps.options.defaultSortName && columns) {
      let found = false;
      columns.forEach(item => {
        found = found || item.dataId === bstProps.options.defaultSortName;
      });
      if (!found) {
        // remove property for hide unlock page by "Loader...".
        delete bstProps.options.defaultSortName;
      }
    }

    // Allows forcing the pagination to a specific point from parent page
    const page = this.props.page ? this.props.page : this.state.page;
    const start = this.props.start !== undefined && this.props.start > -1 ? this.props.start : this.state.start;

    return (
      <div className={`table-page ${tabs ? 'has-tabs' : ''} ${className || ''}`}>
        {
          hideSearch
            ? null
            : (<Search
              scanSearchCol={scanSearchCol}
              searchPlaceholder={searchPlaceholder}
              columns={columns}
              searchString={searchString}
              updateSearch={this.updateSearch}
              scanSearchEnabled={scanSearchEnabled}
              setScanSearchCol={this.setScanSearchCol}
              handleKeyPress={this.listenForEnter}
              autoSuggestInput={this.getAutoSuggestInput()}
              hintText={this.state.solrErrorMessage || 'cultivation.finishedProduct.table.suggestHint'}
              hintClass={this.state.solrErrorMessage ? 'text-danger' : 'text-muted'}
            />)
        }

        {tabs ? <FilterTabs
          tabs={tabs}
          switchTab={switchTab}
          activeTab={activeTab}
          columns={columns}
          scanSearchEnabled={scanSearchEnabled}
          historyEnabled={historyEnabled}
          toggleScanSearch={!hideScanSearch ? this.toggleScanSearch : null}
          toggleScanIdSearch = {toggleScanIdSearch}
          toggleHistory={showHistory ? this.toggleHistory : null}
          setVisibleColumns={this.setVisibleColumns}
          setPageSize={this.setPageSize}
          exportCSV={this.exportCSV}
          pageSizeList={pageSizeList}
          pageSize={pageSize}
          rowIsSelected={selectedRows.length > 0}
          hideExport={hideExport}
          hideTableControls={hideTableControls}
          showSelectedCount={showSelectedCount}
          selectedCount={selectedRows.length}
        >{filterTabsChildren}</FilterTabs> : null}
        {
          !this.props.disableChanges
            ? null
            : (<div className='text-muted' style={{textAlign: 'center'}}>
              <div>Search, Tabs, Column Sorting, Column Visibility, Result Size, and Pagination are disabled.</div>
              {this.props.disableReason ? <div>{this.props.disableReason}</div> : null}
            </div>)
        }

        {actions ?
          <ActionBar
            actions={actions}
            setPageSize={this.setPageSize}
            pageSize={pageSize}
            pageSizeList={pageSizeList}
            scanSearchEnabled={scanSearchEnabled}
            historyEnabled={historyEnabled}
            toggleScanSearch={!hideScanSearch ? this.toggleScanSearch : null}
            toggleScanIdSearch = {toggleScanIdSearch}
            toggleHistory={showHistory ? this.toggleHistory : null}
            setVisibleColumns={this.setVisibleColumns}
            columns={columns}
            exportCSV={this.exportCSV}
            hideExport={hideExport}
            hideTableControls={hideTableControls}
            rowIsSelected={selectedRows.length > 0}
            showSelectedCount={showSelectedCount}
            selectedCount={selectedRows.length}
            hidePagination={hidePagination}
          />
          : null}
        {external ?
          <Table
            ref={this._tableContainer}
            selectedRows={selectedRows}
            columns={columns}
            data={data}
            dataState={this.props.dataState}
            useRenderDataFlag={useRenderDataFlag}
            remoteOptions={{
              onSortChange: this.onSortChange,
              onPageChange: this.onPageChange,
              dataTotalSize: dataTotalSize,
              start: start,
              page: page
            }}
            sizePerPage={pageSize}
            handleSelect={this.handleSelect}
            settingKey={settingKey}
            bstProps={bstProps}
          />
          :
          <Table
            ref={this._tableContainer}
            selectedRows={selectedRows}
            columns={columns}
            data={data}
            dataState={this.props.dataState}
            useRenderDataFlag={useRenderDataFlag}
            searchString={searchString}
            sizePerPage={pageSize}
            handleSelect={this.handleSelect}
            noSelectionMode={noSelectionMode}
            settingKey={settingKey}
            bstProps={bstProps}
          />}
      </div>
    );
  }
}

TablePage.propTypes = {
  data: PropTypes.array.isRequired,
  dataState: PropTypes.string,
  columns: PropTypes.array.isRequired,
  selectedRows: PropTypes.array,
  handleSelect: PropTypes.func,
  handleSearch: PropTypes.func,
  externalSearch: PropTypes.func,
  bstProps: PropTypes.object,
  activeTab: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  tabs: PropTypes.array,
  actions: PropTypes.array,
  switchTab: PropTypes.func,
  className: PropTypes.string,
  dataTotalSize: PropTypes.number,
  scanSearchCol: PropTypes.string,
  external: PropTypes.bool,
  hideSearch: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  hideTableControls: PropTypes.bool,
  hideExport: PropTypes.bool,
  hideScanSearch: PropTypes.bool,
  showHistory: PropTypes.bool,
  noSelectionMode: PropTypes.bool,
  showSelectedCount: PropTypes.bool,
  updateColumns: PropTypes.bool,
  autoRefresh: PropTypes.number,
  sort: PropTypes.string,
  pageSizeList: PropTypes.array,
  searchString: PropTypes.string,
  toggleScanIdSearch: PropTypes.func,
  dataUpdateAvailable: PropTypes.array,
  updateColumnSettings: PropTypes.func,
  dataUpdated: PropTypes.func,
  disableChanges: PropTypes.bool,
  disableReason: PropTypes.string,
  isSolrListing: PropTypes.bool,
  useAutoSuggest: PropTypes.bool,
  getData: PropTypes.func.isRequired,
  autoSuggestions: PropTypes.array.isRequired,
  autoSuggestPlaceholder: PropTypes.string,
  setSolrErrorMessage: PropTypes.func.isRequired,
  solrErrorMessage: PropTypes.object.isRequired,
  autoSuggestContextKey: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  tableActions: PropTypes.object.isRequired,
  facility: PropTypes.object.isRequired,
  solrSearchTerms: PropTypes.object.isRequired,
  searchOnInitialization: PropTypes.bool.isRequired,
  columnSettingsAreLoaded: PropTypes.bool.isRequired,
  // See documentation TableComponent::shouldRenderData method docblock for usage.
  useRenderDataFlag: PropTypes.bool,
  hidePagination: PropTypes.bool,
  filterTabsChildren: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node
  ]),
};

TablePage.defaultProps = {
  searchString: '',
  selectedRows: [],
  isSolrListing: false,
  dataUpdated: () => {},
  handleSelect: () => {},
  handleSearch: () => {},
  tableActions: {},
  searchOnInitialization: true,
  columnSettingsAreLoaded: true
};

export default TablePage;
