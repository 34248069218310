import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {push} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import {OverlayTrigger, Tooltip, Button} from 'react-bootstrap';
import InternationalCurrencyStatic from '../../common/form/InternationalCurrencyStatic';
import SingleActionColumn from '../../common/grid/columns/SingleActionColumn';
import TablePageWrapper from '../../common/grid/TablePageWrapper';
import PageTitle from '../../common/PageTitle';
import * as p from '../../../constants/permissions';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import * as apiActions from '../../../actions/apiActions';
import {unsetData} from '../../../actions/dataActions';
import {setItem, unsetItem} from '../../../actions/itemActions';
import { handleComplexSelectRow} from '../../../actions/helpers/selectedDataHelper';
import * as statuses from '../../../constants/orderStatuses';
import {getSelectedSalesOrdersIds} from '../../../selectors/orderSelectors';
import {facilityHasModule} from '../../../selectors/facilitiesSelectors';
import * as messageTypes from '../../../constants/messageTypes';
import {addMessage} from '../../../actions/systemActions';
import {isMetrcIntegrator, isMetrcTransfersEnabled} from '../../../selectors/integration/metrcSelectors';
import LinkManifest from '../../integration/metrc/LinkManifest';
import InternationalDateTimeStatic from '../../common/form/InternationalDateTimeStatic';
import ModalWrapper from '../../common/ModalWrapper';
import WillRender from '../../common/concealers/WillRender';

export class SalesOrderListing extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.createTransfer = this.createTransfer.bind(this);

    const tabs = [
      {
        id: 'activeTab',
        eventKey: statuses.activeSales,
        path: '/sales-orders',
        title: 'cultivation.salesOrders.actions.viewActive',
        actions: [
          {
            id: 'createOrder',
            path: '/sales-orders/create',
            text: 'cultivation.salesOrders.actions.create',
            glyph: 'plus',
            requireSelect: false
          },
          {
            id: 'fulfillOrder',
            path: '/transfers/create',
            text: 'cultivation.salesOrders.actions.fulfill',
            glyph: 'briefcase',
            requireSelect: true,
            func: this.createTransfer
          }
        ]
      },
      {
        id: 'inactiveTab',
        eventKey: statuses.inactiveSales,
        path: '/sales-orders/inactive',
        title: 'cultivation.salesOrders.actions.viewInactive',
        actions: [
          {
            id: 'createOrder',
            path: '/sales-orders/create',
            text: 'cultivation.salesOrders.actions.create',
            glyph: 'plus',
            requireSelect: false
          }
        ]
      },
      {
        id: 'transfersActiveTab',
        eventKey: statuses.activeTransfers,
        title: 'cultivation.transfers.active',
        path: '/transfers',
        actions: []
      },
      {
        id: 'transfersInactiveTab',
        eventKey: statuses.inactiveTransfers,
        title: 'cultivation.transfers.inActive',
        path: '/transfers/inactive',
        actions: []
      }
    ];

    this.state = {
      tabs,
      showLinkMetrcManifestModal: false,
      activeTab: props.params.status === 'inactive' ? statuses.inactiveSales : statuses.activeSales
    };

    this.switchTab = this.switchTab.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleCustomActions = this.handleCustomActions.bind(this);
    this.handleRetrieveMetrcTransfers = this.handleRetrieveMetrcTransfers.bind(this);
    this.handleLinkMetrcManifest = this.handleLinkMetrcManifest.bind(this);
    this.handleHideLinkMetrcManifestModal = this.handleHideLinkMetrcManifestModal.bind(this);
    this.editOrder = this.editOrder.bind(this);
    this.getData = this.getData.bind(this);
    this.toggleTabAction = this.toggleTabAction.bind(this);
  }

  // In order to have access to the current page and the active tab in the socket middleware
  // the current route and status need to be stashed into the redux store to determine
  // if we need to retrieve the sales orders
  componentWillMount() {
    this.getData();
    this.handleCustomActions();

    this.props.actions.setItem('sales-orders', itemNames.currentPage);
    const status = this.props.params.status === 'inactive' ?  'inactive' : 'active';
    this.props.actions.setItem({status: status}, itemNames.currentParams);
  }

  // Keep the status updated in the redux store to ensure we are pulling the correct
  // data into the tab
  componentDidUpdate(nextProps, nextState) {
    if(this.props.params.status != nextProps.params.status) {
      const status = (this.props.params.status === 'inactive' ?  'inactive' : 'active');
      this.props.actions.setItem({status: status}, itemNames.currentParams);
      this.props.actions.unsetData(dataNames.salesOrders);
      this.getData();
    }
  }

  // To prevent pulling the sales orders if navigated away from the Sales Orders page
  // we unset the identifiers in the redux state
  componentWillUnmount() {
    this.props.actions.unsetItem(itemNames.currentPage);
    this.props.actions.unsetItem(itemNames.currentParams);
    this.props.actions.unsetItem(itemNames.currentRequest);
  }

  createTransfer() {
    const {selectedSalesOrders} = this.props;
    this.props.actions.postItem(
      `/api/sales_orders/${selectedSalesOrders}/can_transfer`,
      {},
      'noOp',
      {
        failedHandler: (e) => {
          const {all_item_masters_active_or_have_inventory, closed_loop_restrictions} = get(e.response, 'data.errors.VALIDATION');
          let messages = [];

          if (Array.isArray(all_item_masters_active_or_have_inventory) && all_item_masters_active_or_have_inventory[0] === false) {
            this.props.actions.push(`/sales-orders/modify/${selectedSalesOrders}`);
            messages.push({error: 'salesOrders.hasInactiveItemMasters'});
          }
          if(closed_loop_restrictions){
            messages = messages.concat(closed_loop_restrictions.map(error => ({error, localization: true})));
          }

          messages.forEach((message) => {
            this.props.actions.addMessage(messageTypes.error, message.error, message.localization);
          });

        },
      })
      .then(() => {
        this.props.actions.unsetData(dataNames.salesOrders);
        this.props.actions.push({pathname: '/transfers/create', query: {so_num: selectedSalesOrders}});
      });
  }

  getData() {
    const {isMetrc, isMetrcTransfersEnabled} = this.props;

    const status = this.props.params.status === 'inactive' ?  'inactive' : 'active';

    if (isMetrc && isMetrcTransfersEnabled && status === 'inactive') {
      this.props.actions.getUnpaginatedData(`/api/sales_orders/${status}?with_relations=transfers`, dataNames.salesOrders);
    } else {
      this.props.actions.getUnpaginatedData(`/api/sales_orders/${status}`, dataNames.salesOrders);
    }

    this.props.actions.handleComplexSelectRow(undefined, dataNames.salesOrders, 'clear');
  }

  handleCustomActions() {
    const {isMetrc, isMetrcTransfersEnabled} = this.props;

    if (isMetrc && isMetrcTransfersEnabled) {
      const {tabs} = this.state;

      for (const key in tabs) {
        const tab = tabs[key];

        if (tab.id !== 'activeTab') {
          continue;
        }

        tabs[key].actions.push({
          id: 'retrieveTransfers',
          text: 'cultivation.salesOrders.actions.metrcRetrieve',
          func: this.handleRetrieveMetrcTransfers
        });

        this.setState({tabs});
      }
    }
  }

  handleSelect(isSelected, rows) {
    const {isMetrc, isMetrcTransfersEnabled} = this.props;
    const transfers = get(rows, '0.transfers');
    const isImported = get(rows, '0.is_imported');

    if (transfers && transfers.some(transfer => transfer.status !== 'cancelled')) {
      this.toggleTabAction('activeTab', 'fulfillOrder', false);
    } else if (isMetrc && isMetrcTransfersEnabled && isImported) {
      this.toggleTabAction('activeTab', 'fulfillOrder', false);
    } else {
      this.toggleTabAction('activeTab', 'fulfillOrder', true);
    }

    const action = isSelected === 'add' ? 'set' : isSelected;
    this.props.actions.handleComplexSelectRow(rows, dataNames.salesOrders, action);
  }

  toggleTabAction(tabId, actionId, enabled) {
    const {tabs} = this.state;

    for (const key in tabs) {
      const tab = tabs[key];

      if (tab.id === tabId) {
        tabs[key].actions = tab.actions.map(action => {
          if (action.id === actionId) {
            action.disabled = !enabled;
          }

          return action;
        });
      }
    }

    this.setState({tabs});
  }

  handleRetrieveMetrcTransfers() {
    this.props.actions.postItem('/api/metrc/transfers/retrieve', {type: 'outgoing'})
      .then(() => this.props.actions.addMessage(messageTypes.success, 'cultivation.salesOrders.actions.metrcRetrieveSuccess'));
  }

  handleLinkMetrcManifest (salesOrder) {
    this.props.actions.setItem(salesOrder, itemNames.salesOrder).then(() => {
      this.setState({
        showLinkMetrcManifestModal: true,
      });
    });
  }

  handleHideLinkMetrcManifestModal (updateListing = false) {
    this.setState({
      showLinkMetrcManifestModal: false,
    }, () => {
      this.props.actions.unsetItem(itemNames.salesOrder);

      if (updateListing) {
        this.getData();
      }
    });
  }

  editOrder(event, row) {
    event.stopPropagation();
    this.props.actions.push(`/sales-orders/modify/${row.id}`);
  }

  switchTab(activeTab) {
    const tab = this.state.tabs.find(tab => tab.eventKey === activeTab) || this.state.tabs[0];
    this.setState({activeTab});
    this.props.actions.push(tab.path);
  }

  render() {
    const {selectedSalesOrders, salesOrders, facilityHasConnects, isMetrc, isMetrcTransfersEnabled} = this.props;
    const {activeTab} = this.state;
    const columns = [
      {
        name: '',
        dataId: '',
        hidden: false,
        dataSort: false,
        width: '50px',
        formatter: (field, row) => {
          const transfers = get(row, 'transfers');
          const so_number = get(row, 'sales_order_number');
          const active = transfers ? transfers.find(transfer => ['open', 'out_for_delivery'].find(status => status === transfer.status)) : null;
          const transfer_number = active ? get(active, 'transfer_number') : '';
          return active ? <OverlayTrigger delay={100} placement='top' overlay={<Tooltip id={so_number}>{I18n.t('cultivation.salesOrders.messages.activeTransfer', {so_number: so_number, transfer_number: transfer_number})}<br />{I18n.t('cultivation.salesOrders.messages.activeTransferComplete')}</Tooltip>}><img src='/images/notifications/warning-alt-filled.svg' /></OverlayTrigger> : '';
        }
      },
      {
        name: 'cultivation.salesOrders.table.date',
        dataId: 'date_ordered',
        /* eslint-disable react/no-multi-comp */
        formatter: cell => <InternationalDateTimeStatic useSystemDate={false} displayTime={false}>{cell}</InternationalDateTimeStatic>,
        hidden: false,
        dataSort: true,
        width:'100px'
      },
      {
        name: 'cultivation.salesOrders.table.delivery',
        dataId: 'date_expected',
        /* eslint-disable react/no-multi-comp */
        formatter: cell => <InternationalDateTimeStatic useSystemDate={false} displayTime={false}>{cell}</InternationalDateTimeStatic>,
        hidden: false,
        dataSort: true,
        width:'100px'
      },
      {
        name: 'cultivation.salesOrders.table.partner',
        dataId: 'partner_contact_name',
        hidden: false,
        dataSort: true,
        width:'100px'
      },
      {
        name: 'cultivation.salesOrders.table.contact',
        dataId: 'partner_contact_phone',
        hidden: false,
        dataSort: true,
        width: '100px'
      },
      {
        name: 'cultivation.common.table.numberOfItems',
        dataId: 'item_count',
        hidden: false,
        dataSort: true,
        width: '100px'
      },
      {
        name: 'cultivation.salesOrders.table.total',
        dataId: 'order_total',
        /* eslint-disable react/no-multi-comp */
        formatter: cell => (<InternationalCurrencyStatic>{cell}</InternationalCurrencyStatic>),
        hidden: false,
        dataSort: true,
        width:'100px'
      },
      {
        name: 'cultivation.salesOrders.table.sales',
        dataId: 'sales_order_number',
        hidden: false,
        dataSort: true,
        width:'100px'
      },
      {
        name: 'cultivation.salesOrders.table.partnerPO',
        dataId: 'partner_invoice_number',
        hidden: false,
        dataSort: true,
        width:'100px'
      },
      {
        name: 'cultivation.salesOrders.table.status',
        dataId: 'payment_status',
        hidden: false,
        dataSort: true,
        width:'100px'
      }];

    if (facilityHasConnects) {
      columns.push({
        name: 'purchaseOrders.table.connectStatus',
        dataId: 'connect_status',
        hidden: false,
        dataSort: true,
        width: '100px',
        columnClassName: (fieldValue) => {
          const classByStatus = {accepted: 'text-success', declined: 'text-danger', acknowledged: 'text-danger'};
          return (classByStatus[fieldValue]) || 'text-secondary';
        },
        formatter: (connect_status, row) => {
          return (connect_status && row.internal_purchase_order_linked) ? I18n.t(`salesOrders.connect_statuses.${connect_status}`) : '';
        }
      });
    }

    if (isMetrc && isMetrcTransfersEnabled) {
      columns.push({
        name: 'cultivation.salesOrders.table.metrcIntegrated',
        dataId: 'is_imported',
        formatter: cell => cell ? I18n.t('general.yes') : I18n.t('general.no'),
        hidden: false,
        dataSort: true,
        width: '100px'
      });

      if (salesOrders.some(salesOrder => !salesOrder.is_imported && (salesOrder.transfers && salesOrder.transfers.length))) {
        columns.push({
          hidden: !isMetrc || !isMetrcTransfersEnabled,
          dataSort: false,
          dataId: 'is_imported',
          formatter: (cell, row) => {
            if (!cell && row.transfers && row.transfers.length) {
              return <Button variant='primary' onClick={() => this.handleLinkMetrcManifest(row)}>{I18n.t('cultivation.salesOrders.table.linkManifest')}</Button>;
            }
          },
          columnClassName: 'actions-column',
          width: '170px'
        });
      }
    }

    columns.push({
      hidden: false,
      dataSort: false,
      permissions: [p.manage_sales_orders],
      csvFormatter: (cell) => {
        return I18n.t('cultivation.salesOrders.actions.edit');
      },
      formatter: SingleActionColumn({
        path: function (row) {
          return `/sales-orders/modify/${row.id}`;
        },
        label: 'cultivation.salesOrders.actions.edit',
        action: this.editOrder
      }),
      columnClassName: 'actions-column',
      width: '170px'
    });

    return (
      <div>
        <PageTitle primaryText={I18n.t('supplyChain.salesOrders')}/>
        <TablePageWrapper
          ref={this.ref}
          settingKey='sales-orders'
          columns={columns}
          data={Array.isArray(salesOrders) ? salesOrders : []}
          activeTab={activeTab}
          tabs={this.state.tabs}
          switchTab={this.switchTab}
          selectedRows={selectedSalesOrders}
          handleSelect={this.handleSelect}
          hideScanSearch = {true}
          hideExport={true}
          bstProps={{
            selectRow: {
              mode: 'radio'
            }
          }}
        />
        <WillRender ifTrue={isMetrc && isMetrcTransfersEnabled}>
          <ModalWrapper
            Component={LinkManifest}
            title={I18n.t('metrc.linkManifest.linkManifestModalTitle')}
            onHide={this.handleHideLinkMetrcManifestModal}
            showModal={this.state.showLinkMetrcManifestModal}
            dialogClassName={'modal-md'}
          />
        </WillRender>
      </div>
    );
  }
}

SalesOrderListing.propTypes = {
  selectedSalesOrders: PropTypes.array.isRequired,
  salesOrders: PropTypes.array.isRequired,
  timezone: PropTypes.string.isRequired,
  params:PropTypes.shape({
    status: PropTypes.string
  }),
  actions: PropTypes.shape({
    unsetData: PropTypes.func.isRequired,
    handleComplexSelectRow: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired
  }),
  facilityHasConnects: PropTypes.bool,
  isMetrc: PropTypes.bool,
  isMetrcTransfersEnabled: PropTypes.bool,
};

export function mapStateToProps(state, ownProps) {
  const {salesOrders, timezone} = state;
  return {
    selectedSalesOrders: getSelectedSalesOrdersIds(state),
    salesOrders,
    timezone,
    facilityHasConnects: facilityHasModule(state, {moduleKey: 'CONNECTS'}),
    isMetrc: isMetrcIntegrator(state),
    isMetrcTransfersEnabled: isMetrcTransfersEnabled(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {
    handleComplexSelectRow,
    push,
    unsetData,
    addMessage,
    setItem,
    unsetItem,
  });

  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesOrderListing);
