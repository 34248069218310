import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import {connect} from 'react-redux';
import {Button, Col, Row, Form} from 'react-bootstrap';
import {FaTrash} from 'react-icons/fa';
import MultiselectInput from '../../../common/form/MultiselectInput';
import InternationalCurrencyInput from '../../../common/form/InternationalCurrencyInput';
import WillRender from '../../../common/concealers/WillRender';


const UnitPriceAttributes = (props) => {
  const {
    fields,
    fieldName,
    fieldLabel,
    fieldIndex,
    facilityOptions,
    disablePriceBlock,
    formValues,
  } = props;

  const isFacilityMode = facilityOptions && facilityOptions.length > 0;
  const colNumPrice = isFacilityMode ? 2 : 7;
  const colNumMargin = isFacilityMode ? 2 : 3;

  // margin calculation
  const unitCost = get(formValues, 'vendors.0.default_unit_cost', null);
  const unitPrice = get(formValues, `${fieldName}.default_price`, null);
  const margin = unitPrice && unitCost && unitPrice > 0 ? (unitPrice - unitCost) / unitPrice : null;
  const marginPercent = margin ? (margin * 100).toFixed(2) : null;

  return (
    !disablePriceBlock && <Col xs={12}>
      <Row>
        {isFacilityMode && <React.Fragment>
          <Col sm={10} md={3}>
            <Field name={`${fieldName}.facility_ids`} component={MultiselectInput} props={{
              label: I18n.t('taxes.form.applicableFacilities'),
              options: facilityOptions,
              isRequired: false,
              allOption: true,
            }}/>
          </Col>
          <Col sm={2} md={1}>
            <p>&nbsp;</p>
            <Button variant='danger' size='sm' onClick={() => fields.remove(fieldIndex)}>
              <FaTrash/>
            </Button>
          </Col>
        </React.Fragment>}
        <Col sm={12} md={colNumPrice}>
          <InternationalCurrencyInput name={`${fieldName}.default_price`}
            props={{
              fractionPartSize: 2,
              placeholder: '0.00',
              label: I18n.t(...fieldLabel),
              isRequired: !isFacilityMode,
            }}
          />
        </Col>
        <Col sm={12} md={colNumMargin}>
          <WillRender ifTrue={marginPercent}>
            <Form.Label>{I18n.t('products.form.margin')}</Form.Label>
            <br />
            {`${marginPercent} %`}
          </WillRender>
        </Col>
      </Row>
    </Col>
  );
};

UnitPriceAttributes.propTypes = {
  fields: PropTypes.shape({
    remove: PropTypes.func,
  }),
  fieldName: PropTypes.string.isRequired,
  fieldLabel: PropTypes.array.isRequired,
  fieldIndex: PropTypes.number,
  isWholesale: PropTypes.bool,
  facilityOptions: PropTypes.array,
  disablePriceBlock: PropTypes.bool,
  formName: PropTypes.string,
  formValues: PropTypes.object,
};

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps)(UnitPriceAttributes);
