import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Row, Image, Card} from 'react-bootstrap';
import {REWARD_POINTS_FORM} from '../../../../constants/forms';
import * as p from '../../../../constants/permissions';
import PermissionButton from '../../../common/PermissionButton';
import RewardsEditorPOS from '../../../retail/rewards/RewardsEditorPOS'; //eslint-disable-line

const CustomerProfile = (props) => {

  const {profileData, searchCustomer, goToQueue, checkInRequired, integrationState: {isCure, isPaLeaf: isPALeafIntegrator}, facility, patientGoesByEnabled} = props;

  const haveProfile = Object.keys(profileData).length > 0;

  function getPhones() {

    if(profileData.phone_numbers === undefined) return '';

    const phones = [];

    profileData.phone_numbers.forEach( (phone) => {
      if(phone.type === 'primary') return phones.unshift(phone.number);
      phones.push(phone.number);
    });

    return phones.join(', ');

  }

  function getGroups(){

    if(profileData.groups === undefined) return '';
    const myGroups = profileData.groups.map(customerGroup => customerGroup.group_name || '');
    return myGroups.join(', ');
  }

  const disabled = (!profileData.active);
  // Some of the button code in this component is copied from CustomerActionButtons and should probably be refactored.
  let qt = '';
  if(!searchCustomer.order_source || searchCustomer.order_source === 'in_store'){
    qt = 'inStore';
  }else if(searchCustomer.fulfillment_method === 'in_store'){
    qt = 'pickup';
  }else{
    qt = 'delivery';
  }

  // Filter out orders from other facilities which lead to incorrect button states
  const facilityId = parseInt(facility.id);

  const customerQueues = ((searchCustomer && searchCustomer.queues) ? JSON.parse(searchCustomer.queues) : [])
    .filter((record) => parseInt(record.facility_id) === facilityId);
  const customerInQueue = (Array.isArray(customerQueues)) ? customerQueues.length : false;

  const customerOrders = ((searchCustomer && searchCustomer.orders) ? JSON.parse(searchCustomer.orders) : [])
    .filter((order) => parseInt(order.facility_id) === facilityId);
  const customerHasOrderId = (Array.isArray(customerOrders))
    ? customerOrders.length
      ? customerOrders[0].order_id
      : false
    : false;

  return(
    <div className='patient-profile-info'>
      <div>
        <Card>
          <Card.Body>
            {
              !haveProfile
              ? (<Row className='patient-data' >
                  <Col md={3} className='patient-image' style={{textAlign:'center'}}>
                    <Image src='/images/user-avatar.png' style={{width:'100%'}} />
                  </Col>
                  <Col md={9} className='text-danger' style={{textAlign:'center', paddingTop: '2rem'}}>
                    Customer Not Found
                  </Col>
                </Row>)
                : (<Row className='patient-data' >
                <Col md={3} className='patient-image' style={{textAlign:'center'}}>
                  <a href onClick={(event) => { props.onOpenProfile(event);}}>{I18n.t('cultivation.orderHistory.profileInfo.edit')}</a>
                  <div style={{height:'8px'}} />
                  <Image src={profileData.image_file ? profileData.image_file : ''} style={{width:'100%'}} />
                </Col>
                <Col md={9}>
                  <div className='text-success' style={{width:'40%', textAlign:'center', float: 'left', height: '60px'}}>
                    <span>Orders</span>
                    <div style={{fontSize:'24px'}}>{props.orderStats.ordersCount}</div>
                  </div>
                  <div className='text-success' style={{width:'60%', textAlign:'center', float: 'left', height: '60px'}}>
                    <span>Lifetime</span>
                    <div style={{fontSize:'24px'}}>
                      {props.orderStats && props.orderStats.ordersTotal
                        ? props.orderStats.ordersTotal.toFixed(2)
                        : null
                      }
                    </div>
                  </div>
                  <div className='text-danger' style={{width:'40%', textAlign:'center', float: 'left', height: '60px'}}>
                    <span>Refunds</span>
                    <div style={{fontSize:'24px'}}>{props.orderStats.refunds}</div>
                  </div>
                  <div className='text-danger' style={{width:'60%', textAlign:'center', float: 'left', height: '60px'}}>
                    <span>Restocks</span>
                    <div style={{fontSize:'24px'}}>{props.orderStats.restocks}</div>
                  </div>
                  <div style={{textAlign: 'center', fontWeight: 'bold'}}>{I18n.t('cultivation.orderHistory.profileInfo.MMJCard')}: {searchCustomer.med_license_number}</div>
                </Col>
              </Row>)
            }
            <div style={{height: '8px'}} />
            {
              !haveProfile
                ? null
                : (<div>
                {isPALeafIntegrator || isCure ? null :
                <Row>
                  <Col md={6} style={{paddingRight: '1px'}}>
                    {customerInQueue || searchCustomer.order_id ?
                      <PermissionButton
                        permissions={[p.create_orders, p.manage_orders]}
                        props={{
                          variant: 'primary',
                          className: 'btn-block',
                          disabled: disabled,
                          onClick: event => goToQueue(searchCustomer)
                        }}
                      >
                        {I18n.t('consumers.checkin.goToQueue.' + qt)}
                      </PermissionButton> :
                      (checkInRequired) ? '' :
                      <PermissionButton
                        permissions={[p.create_orders, p.manage_orders]}
                        props={{
                          variant: 'primary',
                          className: 'btn-block',
                          disabled: disabled,
                          onClick: (event) => props.onCheckIn(event)
                        }}
                      >
                        {I18n.t('consumers.checkin.checkIn')}
                      </PermissionButton>
                    }
                  </Col>
                  <Col md={6} style={{paddingLeft: '1px'}}>
                    {customerHasOrderId ?
                      <PermissionButton
                        permissions={[p.create_orders, p.manage_orders]}
                        props={{
                          variant: 'primary',
                          className: 'btn-block',
                          disabled: disabled,
                          onClick: (event) => props.modifyOrder({order_id: customerHasOrderId})
                        }}
                      >
                        {I18n.t('consumers.checkin.modifyOrder')}
                      </PermissionButton>
                      : (checkInRequired) ? '' :
                      <PermissionButton
                        permissions={[p.create_orders, p.manage_orders]}
                        props={{
                          variant: 'primary',
                          className: 'btn-block',
                          disabled: disabled,
                          onClick: (event) => props.onStartOrder(Object.assign({}, searchCustomer, {queue_id: customerInQueue}))
                        }} >
                        {I18n.t('cultivation.orderHistory.profileInfo.startOrder')}
                      </PermissionButton>}
                  </Col>
                  <Col md={12} style={{marginTop: '2px'}}>
                    {checkInRequired ? '' :
                    <PermissionButton
                      permissions={[p.create_orders, p.manage_orders]}
                      props={{
                        variant: 'primary',
                        className: 'btn-block',
                        disabled: disabled,
                        onClick: (event) => props.onFastTrack(customerHasOrderId ? {order_id: customerHasOrderId} : searchCustomer)
                      }}
                    >
                      {I18n.t('cultivation.orderHistory.profileInfo.fastTrackToCart')}
                    </PermissionButton>}
                  </Col>
                </Row>}
                <div style={{height: '8px'}}></div>
                <Row>
                  <Col md={12}>
                    <table className='table-sm' style={{width:'100%'}}>
                      <tbody>
                      <tr>
                        <td style={{width: '33%'}}>
                          Customer:
                        </td>
                        <td style={{fontWeight: 'bold'}}>
                          {profileData.first_name} {profileData.last_name}
                        </td>
                      </tr>
                      {patientGoesByEnabled && profileData.goes_by &&
                        <tr>
                          <td>
                            {I18n.t('customers.table.goesBy')}:
                          </td>
                          <td>
                            {profileData.goes_by}
                          </td>
                        </tr>
                      }
                      <tr>
                        <td>
                          Email:
                        </td>
                        <td>
                          {profileData.email_address}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Phone:
                        </td>
                        <td>
                          {getPhones()}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {I18n.t('cultivation.orderHistory.profileInfo.id')}:
                        </td>
                        <td>
                          {profileData.id}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {I18n.t('cultivation.orderHistory.profileInfo.group')}:
                        </td>
                        <td>
                          {getGroups()}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {I18n.t('cultivation.orderHistory.profileInfo.birthDate')}
                        </td>
                        <td>
                          {profileData.birth_date}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>
                <RewardsEditorPOS form={REWARD_POINTS_FORM} customer={profileData} />
              </div>)
            }
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

CustomerProfile.propTypes = {
  facility: PropTypes.object.isRequired,
  profileData: PropTypes.object.isRequired,
  searchCustomer: PropTypes.object.isRequired,
  onStartOrder: PropTypes.func.isRequired,
  onOpenProfile: PropTypes.func.isRequired,
  onCheckIn: PropTypes.func.isRequired,
  onFastTrack: PropTypes.func.isRequired,
  goToQueue: PropTypes.func.isRequired,
  modifyOrder: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  refundedProductIds: PropTypes.array,
  orderStats: PropTypes.object.isRequired,
  integrationState: PropTypes.object.isRequired,
  checkInRequired: PropTypes.bool.isRequired,
  patientGoesByEnabled: PropTypes.bool,
};

CustomerProfile.defaultProps = {
  patientGoesByEnabled: false,
};

export default CustomerProfile;
