import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';

import SingleFieldInfo from '../common/SingleFieldInfo';

const status = 'water_activity_status';

const WaterActivity = ({row, dimensions, editable, disabled, isIsolocity, isolocityTestResult, isLabApplyProfiles, displayCompletionStatus, requiredDimensions}) => (
  <SingleFieldInfo
    row={row || {}}
    status={status}
    columns={get(dimensions, 'water_activity', [])}
    editable={editable}
    disabled={disabled}
    isIsolocity={isIsolocity}
    isolocityStatus={get(isolocityTestResult, `inspection.sub_statuses.${status}`, 'In Progress')}
    isLabApplyProfiles={isLabApplyProfiles}
    displayCompletionStatus={displayCompletionStatus}
    requiredDimensions={requiredDimensions}
  />
);

WaterActivity.propTypes = {
  row: PropTypes.object,
  dimensions: PropTypes.object,
  editable: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  isIsolocity: PropTypes.bool,
  isolocityTestResult: PropTypes.object,
  isLabApplyProfiles: PropTypes.bool,
  displayCompletionStatus: PropTypes.bool,
  requiredDimensions: PropTypes.array
};

WaterActivity.defaultProps = {
  disabled: false,
};

export default WaterActivity;
