import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { Table, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { postItem } from '../../../actions/apiActions';
import { addMessage } from '../../../actions/systemActions';

const ApiKeys = ({ organizationDetails: { apikeys = [] }, actions }) => {
  const [disabledKeys, setDisabledKeys] = useState([]);

  const sendNotification = (api_key_id) => {
    actions.postItem('/api/organizations/manage_organization/notify_api_key', {api_key_id}, null,
      { success: 'partner.notify.emailSuccess', failed: 'partner.notify.emailFailed' },
      null, () => {
        actions.addMessage('success', 'partner.notify.emailSuccess');
        setDisabledKeys(prevDisabledKeys => [...prevDisabledKeys, api_key_id]);
      });
  };

  return apikeys.length ? (
    <Table className='information'>
      <thead>
        <tr>
          <th>{I18n.t('common.apiKey')}</th>
          <th>{I18n.t('common.approvedPartner')}</th>
          <th>{I18n.t('common.createdAt')}</th>
          <th>{I18n.t('common.notify')}</th>
        </tr>
      </thead>
      <tbody>
        {apikeys.map((apiKey) => (
          <tr key={apiKey.id}>
            <td>{apiKey.api_key}</td>
            <td>{apiKey.name}</td>
            <td>{apiKey.created_at}</td>
            <td>
              <Button
                onClick={() => sendNotification(apiKey.id)}
                disabled={disabledKeys.includes(apiKey.id)}>
                {I18n.t('common.notifyPartner')}
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  ) : null;
};

ApiKeys.propTypes = {
  organizationDetails: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ postItem, addMessage }, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(ApiKeys);
