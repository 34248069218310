import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {reduxForm, Field, getFormValues} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Row, Col} from 'react-bootstrap';
import {postItem} from '../../../actions/apiActions';
import {LINK_METRC_MANIFEST_FORM} from '../../../constants/forms';
import * as itemNames from '../../../constants/itemNames';
import {requiredFieldValidation} from '../../common/form/redux-form/validations';
import TextInput from '../../common/form/TextInput';
import SubmitSection from '../../common/form/SubmitSection';

class LinkManifest extends React.PureComponent {
  constructor (props, context) {
    super(props, context);

    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      submitting: false,
    };
  }

  onSubmit () {
    this.setState({
      submitting: true,
    });

    const {formValues, salesOrder} = this.props;
    
    const payload = {
      sales_order_id: get(salesOrder, 'id'),
      transfer_id: get(salesOrder, 'transfers.0.id'),
      manifest_number: get(formValues, 'manifest_number'),
    };

    const messages = {
      success: 'metrc.linkManifest.messages.success',
      failed: 'metrc.linkManifest.messages.failed',
    };

    this.props.actions.postItem('/api/metrc/transfers/link_manifest', payload, undefined, messages, undefined, () => {
      this.setState({
        submitting: false,
      });

      this.props.onHide(true);
    }).catch(() => {
      this.setState({
        submitting: false,
      });
    });
  }

  render () {
    const {invalid} = this.props;
    const {submitting} = this.state;

    const submitSectionSettings = {
      actionSettings: {
        save: {
          text: I18n.t('common.form.save'),
          style: 'primary',
          action: this.onSubmit,
          invalid: invalid || submitting
        }
      },
      align: 'right'
    };

    return (
      <form className={LINK_METRC_MANIFEST_FORM} onSubmit={this.onSubmit}>
        <Row>
          <Col sm={12}>
            <Field
              name='manifest_number'
              component={TextInput}
              props={{
                label: I18n.t('metrc.form.manifestNumber'),
                isRequired: true,
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Field
              name='sales_order_number'
              component={TextInput}
              props={{
                label: I18n.t('common.salesOrder.form.salesOrderNumber'),
                disabled: true
              }}
            />
          </Col>
        </Row>
        <SubmitSection settings={submitSectionSettings} />
      </form>
    );
  }
}

LinkManifest.propTypes = {
  salesOrder: PropTypes.object,
  formValues: PropTypes.object,
  form: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired,
  invalid: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

function mapStateToProps (state, ownProps) {
  const salesOrder = state[itemNames.salesOrder];

  return {
    ...ownProps,
    form: LINK_METRC_MANIFEST_FORM,
    salesOrder: salesOrder,
    initialValues: {
      sales_order_number: salesOrder && salesOrder.sales_order_number
    },
    formValues: getFormValues(LINK_METRC_MANIFEST_FORM)(state),
  };
}

function mapDispatchToProps (dispatch) {
  const actions = {
    postItem,
  };

  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

const LinkManifestForm = reduxForm({
  validate: values => {
    return {
      manifest_number: requiredFieldValidation(values.manifest_number)
    };
  }
})(LinkManifest);

export default connect(mapStateToProps, mapDispatchToProps)(LinkManifestForm);