import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import get from 'lodash.get';
import DoubleFieldInfo from '../common/DoubleFieldInfo';
import PopulateLOQCheckbox from '../common/PopulateLOQCheckbox';
import {getFacilityState} from '../../../selectors/facility/getCurrentFacility';
import { isFeatureEnabled } from '../../../selectors/featureToggles';
import {isLeafUtahConfigPackClosedLoopFacility} from '../../../selectors/facilitiesSelectors';

const status = 'cannabinoid_potency_status';

class CannabinoidPotency extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const {row, dimensions, requiredDimensions, editable, disabled, isIsolocity, isolocityTestResult, isFacilityStateOregon, isUtahLabFieldImprovementsToggled, isLeafUtahConfigPackClosedLoopFacility, change, isPerServingMode, servingsPerContainer, servingsUom} = this.props;

    const cannabinoid_potency_dimensions = [...get(dimensions, 'cannabinoid_potency', [])];

    if (isUtahLabFieldImprovementsToggled && isLeafUtahConfigPackClosedLoopFacility) {
      const indexOfTotalDimension = cannabinoid_potency_dimensions.findIndex(column => column.key === 'total_cb_profile');

      const totalColumn = cannabinoid_potency_dimensions[indexOfTotalDimension];
      cannabinoid_potency_dimensions.splice(indexOfTotalDimension, 1);
      cannabinoid_potency_dimensions.push(totalColumn);

      const indexOfThcDimension = cannabinoid_potency_dimensions.findIndex(column => column.key === 'thc');
      cannabinoid_potency_dimensions.splice(indexOfThcDimension, 1);
      cannabinoid_potency_dimensions.unshift({
        name: 'THC',
        display_name: 'D9 THC',
        key: 'thc',
        display_unit: '',
        precision: 3
      });
    }

    // This component is also called from AverageTestResultPage. In that case 'isPerServingMode' and 'servingsUom'
    // are not passed in as props and need to be retrieved from 'row'.
    const _isPerServingMode = isPerServingMode ? isPerServingMode : get(row, 'test_results_value_type', 'percentage') === 'serving';
    const _servingsUom = servingsUom ? servingsUom : get(row, 'servings_uom');

    return (
      <div>
        {isFacilityStateOregon && (
            <PopulateLOQCheckbox editable={editable} populate_loq={get(row, 'populate_loq', false)}/>
          )
        }
        <DoubleFieldInfo
          row={row || {}}
          columns={cannabinoid_potency_dimensions}
          requiredDimensions={requiredDimensions}
          editable={editable}
          disabled={disabled}
          isIsolocity={isIsolocity}
          isolocityStatus={get(isolocityTestResult, `inspection.sub_statuses.${status}`, 'In Progress')}
          isFacilityStateOregon={isFacilityStateOregon}
          change={change}
          isPerServingMode={_isPerServingMode}
          servingsPerContainer={servingsPerContainer}
          servingsUom={_servingsUom}
        />
      </div>
    );
  }
}
CannabinoidPotency.propTypes = {
  row: PropTypes.object,
  dimensions: PropTypes.object,
  requiredDimensions: PropTypes.array,
  editable: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  isIsolocity: PropTypes.bool,
  isolocityTestResult: PropTypes.object,
  isFacilityStateOregon: PropTypes.bool,
  change: PropTypes.func,
  isPerServingMode: PropTypes.bool.isRequired,
  servingsPerContainer: PropTypes.string,
  servingsUom: PropTypes.string
};

CannabinoidPotency.defaultProps = {
  disabled: false,
  isFacilityStateOregon: false,
};

function mapStateToProps(state) {
  return {
    isFacilityStateOregon: getFacilityState(state) === 'OR',
    isUtahLabFieldImprovementsToggled: isFeatureEnabled(state)('feature_ut_lab_field_improvements'),
    isLeafUtahConfigPackClosedLoopFacility: isLeafUtahConfigPackClosedLoopFacility(state),
  };
}

export default connect(mapStateToProps)(CannabinoidPotency);
