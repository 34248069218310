import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {Field, formValueSelector, getFormSyncErrors} from 'redux-form';
import {Col, Row, Table, Card} from 'react-bootstrap';

import {WASTE_COMPLIANCE_FORM} from '../../../constants/forms';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import MultiSelectInput from '../../common/form/MultiselectInput';
import SubmitSection from '../../common/form/SubmitSection';
import ContentConcealer from '../../common/concealers/ContentConcealer';

const yesNoOptions = [
  {text: 'Yes', value: '1'},
  {text: 'No', value: '0'},
];

class WasteComplianceForm extends React.PureComponent {

  constructor(props,context) {
    super(props, context);
  }

  // touch all invalid fields to prompt error displays
  componentDidUpdate() {

  }

  render() {
    const {
      handleSubmit,
      valid,
      invalid,
      submitting,
      pristine,
      stageOptions,
      dayOptions,
      locations,
      trackWastePackages,
      transferringWaste,
      trackingOfWastePackagesIsOverrideable,
      requireWasteTransfer,
      trackWasteEnabled,
      integrationState: {isBiotrack, isWaLeaf},
    } = this.props;

    const settings = {
      actionSettings: {
        submit: {
          submitting,
          valid,
          invalid,
          pristine,
          text: I18n.t('common.form.save'),
          style: 'primary',
        }
      }
    };

    return (
      <form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} md={6}>
            <Card><Card.Header><h3 className='panel-title'>{I18n.t('cultivation.wasteCompliance.wastePackages')}</h3></Card.Header>
              <Table striped={true} bordered={true}>
                <tbody>
                <tr>
                  <td>
                    {I18n.t('cultivation.wasteCompliance.trackWaste')}
                  </td>
                  <td>
                    <Field
                      name='track_waste_packages'
                      component={ReactSelectInput}
                      props={{
                        options: yesNoOptions,
                        clearable: false,
                        groupClassName: 'thin',
                        disabled: (isBiotrack || !trackingOfWastePackagesIsOverrideable),
                      }}
                    />
                  </td>
                </tr>
                <ContentConcealer show={false}>
                  <tr>
                    <td>
                      {I18n.t('cultivation.wasteCompliance.trackDuring')}
                    </td>
                    <td>
                      <Field
                        name='trackable_waste_stages.values'
                        component={MultiSelectInput}
                        props={{
                          options: stageOptions,
                          groupClassName: 'wide',
                          disabled: !trackWastePackages,
                        }}
                      />
                    </td>
                  </tr>
                </ContentConcealer>
                <tr>
                  <td>
                    {I18n.t('cultivation.wasteCompliance.wastePackageLocation')} {trackWasteEnabled && '*'}
                  </td>
                  <td>
                    <Field
                      name='waste_package_location_id'
                      component={ReactSelectInput}
                      props={{
                        options: locations,
                        clearable: false,
                        groupClassName: 'wide',
                        disabled: !trackWastePackages,
                        placeholder: I18n.t('Choose Location')
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card>
            <Card.Header><h3 className='panel-title'>{I18n.t('cultivation.wasteCompliance.transferringWaste')}</h3></Card.Header>
            <Table striped={true} bordered={true}>
              <tbody>
                <tr>
                  <td>
                    {I18n.t('cultivation.wasteCompliance.transferWaste')}
                  </td>
                  <td>
                    <Field
                      name='transferring_waste'
                      component={ReactSelectInput}
                      props={{
                        options: yesNoOptions,
                        clearable: false,
                        disabled: !trackWastePackages || isWaLeaf || !get(requireWasteTransfer, 'overrideable'),
                        groupClassName: 'thin',
                      }}
                    />
                  </td>
                </tr>
                <ContentConcealer show={false}>
                <tr>
                  <td>
                    {I18n.t('cultivation.wasteCompliance.transferringDays')}
                  </td>
                  <td>
                    <Field
                      name='transferring_waste_days.values'
                      component={MultiSelectInput}
                      props={{
                        options: dayOptions,
                        groupClassName: 'wide',
                        disabled: !trackWastePackages || !transferringWaste,
                      }}
                    />
                  </td>
                </tr>
                </ContentConcealer>
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
      <SubmitSection settings={settings}/>
    </form>
    );

  }
}

WasteComplianceForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  stageOptions: PropTypes.array.isRequired,
  dayOptions: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
  trackWastePackages: PropTypes.bool,
  trackingOfWastePackagesIsOverrideable: PropTypes.bool,
  transferringWaste: PropTypes.bool,
  touch: PropTypes.func.isRequired,
  trackWasteEnabled: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    formErrors: getFormSyncErrors(WASTE_COMPLIANCE_FORM)(state),
    trackWasteEnabled: Boolean(Number(formValueSelector(WASTE_COMPLIANCE_FORM)(state, 'track_waste_packages')))
  };
}

export default connect(mapStateToProps)(WasteComplianceForm);
