import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {push} from 'react-router-redux';

import {getItem, postItem} from '../../actions/apiActions';
import {toggleCustDetails} from '../../actions/menuActions';
import * as itemNames from '../../constants/itemNames';
import PatientDrawer from '../cart/patient/PatientDrawer';

class PatientProfileLoader extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
  }

  componentWillMount(){
    if (this.props.showProfile && Object.getOwnPropertyNames(this.props.facility).length) {
      const {order, customer} = this.props;
      let id;
      if(order.consumer_id === undefined) return false;
      if (customer && customer.id === order.consumer_id) {
        id = customer.id;
      } else {
        this.props.actions.getItem(`/api/customers/${order.consumer_id}`, itemNames.customer, {failed: 'customers.getCustomer.failed'});
        id = order.consumer_id;
      }
      this.props.actions.getItem(`/api/consumer_orders/${id}/stats`, itemNames.customerStat);
      this.props.actions.getItem(`/api/consumer_orders/${id}/recent_history`, itemNames.customerRecentHistory);
    }
  }

  render () {
    const {showProfile} = this.props;
    return (
      <div>
        {showProfile && <PatientDrawer onClose={this.props.actions.toggleCustDetails} push={this.props.actions.push} />}
      </div>
    );
  }
}

PatientProfileLoader.propTypes = {
  actions: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  customer: PropTypes.object,
  showProfile: PropTypes.bool,
  facility: PropTypes.object.isRequired
};

PatientProfileLoader.defaultProps = {
  showProfile: false
};

function mapStateToProps(state, ownProps){
  const {order, customer, facility} = state;
  return {
    facility,
    order,
    customer,
    showProfile: !!((order && order.consumer_id) || (customer && customer.id)),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {getItem, postItem, toggleCustDetails, push};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (PatientProfileLoader);
