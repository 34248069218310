import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Row, Col} from 'react-bootstrap';
import {getIntegrationState} from '../../../../selectors/integration/integrationSelectors';
import getCurrentFacility from '../../../../selectors/facility/getCurrentFacility';
import {manufacturing} from '../../../../constants/facilityTypes';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import ContentConcealer from '../../../common/concealers/ContentConcealer';
import CheckBoxInput from '../../../common/form/CheckBoxInput';

class ProductTypeFields extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    const {is_sample ,sample_type} = props.field;
    this.state = {showSampleType: !!is_sample, showProductType: !!sample_type};

    this.changeSampleTypeVisibility = this.changeSampleTypeVisibility.bind(this);
    this.changeProductSampleTypeVisibility = this.changeProductSampleTypeVisibility.bind(this);
  }

  changeSampleTypeVisibility(event) {
    if (!event.target.checked) {
      this.setState({showSampleType: false, showProductType: false});
    } else {
      this.setState({showSampleType: true, showProductType: false});
    }
    this.props.change(`${this.props.line}.is_sample`, event.target.checked);
  }

  changeProductSampleTypeVisibility(event) {
    if (!event) {
      this.setState({showProductType: false});
    } else {
      this.setState({showProductType: true});
    }
    this.props.change(`${this.props.line}.sample_type`, event);
  }

  render() {
    const {isWaLeaf, isManufacturing, line, sampleTypes, productSampleTypes, field} = this.props;
    const {showSampleType, showProductType} = this.state;

    return (
      <div>
        <ContentConcealer show={isWaLeaf && isManufacturing && field.itemMaster.category_code == 'FLOWER'}>
          <Row>
            <Col md={1}>
              <Field name={`${line}.is_sample`} component={CheckBoxInput} props={{
                label: I18n.t('salesOrders.form.isSample'),
                groupClassName: 'inline-checkbox',
                onChange: this.changeSampleTypeVisibility
              }}/>
            </Col>
            <ContentConcealer show={showSampleType}>
              <Col md={2}>
                <Field
                  name={`${line}.sample_type`}
                  component={ReactSelectInput}
                  props={{
                    label: I18n.t('salesOrders.form.sampleType'),
                    options: sampleTypes,
                    placeholder: 'Select One...',
                    required: true,
                    valueKey: 'code',
                    textKey: 'name',
                    onChange: this.changeProductSampleTypeVisibility
                  }}
                />
              </Col>
            </ContentConcealer>
            <ContentConcealer show={showProductType}>
              <Col md={2}>
                <Field
                  name={`${line}.product_sample_type`}
                  component={ReactSelectInput}
                  props={{
                    label: I18n.t('salesOrders.form.productSampleType'),
                    options: productSampleTypes,
                    placeholder: 'Select One...',
                    required: true,
                    valueKey: 'code',
                    textKey: 'name'
                  }}
                />
              </Col>
            </ContentConcealer>
          </Row>
        </ContentConcealer>
      </div>
    );
  }
}

ProductTypeFields.propTypes = {
  isWaLeaf: PropTypes.bool,
  isManufacturing: PropTypes.bool,
  orderType: PropTypes.string,
  line: PropTypes.string.isRequired,
  sampleTypes: PropTypes.array,
  productSampleTypes: PropTypes.array,
  change: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
  contains_medicated: PropTypes.bool
};

function mapStateToProps(state, ownProps){
  const {sampleTypes, productSampleTypes} = state;
  const {isWaLeaf} = getIntegrationState(state);
  const currentFacility = getCurrentFacility(state);
  return {
    isWaLeaf,
    isManufacturing: currentFacility.type === manufacturing,
    sampleTypes,
    productSampleTypes
  };
}

export default connect(mapStateToProps)(ProductTypeFields);
