import {getFormValues, initialize, isDirty} from 'redux-form';

import {startSubmittingForm, stopSubmittingForm, warnPendingFormChanges} from '../../actions/forms/openFormActions';
import {
  getCurrentPathFromState,
  getOpenFormPathMappings
} from '../../selectors/forms/openFormSelectors';
import {isFeatureEnabled} from '../../selectors/featureToggles';
import {
  REDUX_FORM_SUBMIT_START,
  REDUX_FORM_SUBMIT_SUCCESS,
  REDUX_FORM_SUBMIT_FAILED,
  ROUTER_CALL_HISTORY_METHOD,
  ROUTER_LOCATION_CHANGE
} from '../../constants/actionTypes';

/**
 * Middleware to trap location changes when dirty forms existing for the current path (page)
 *
 * @param store
 * @returns {function(*): Function}
 */
export const openFormMiddleware = store => next => action => {

  const state = store.getState();

  if (
    !isFeatureEnabled(state)('feature_unsaved_form_changes_warning') ||
    !actionTypeOfInterest(action.type) ||
    isCallHistoryActionWithoutPush(action)
  ) {
    return next(action);
  }

  const currentPath = getCurrentPathFromState(state);
  const openFormPathMappings = getOpenFormPathMappings(state);

  // Return if there are no open forms on the page
  if (!openFormPathMappings[currentPath]) return next(action);

  // Temporarily suppress modal warnings by setting a "submitting" flag
  if (action.type === REDUX_FORM_SUBMIT_START) {
    const formName = action.meta.form;

    store.dispatch(startSubmittingForm(formName, currentPath));
    return next(action);
  }

  // Re-initialize the form on submit success so it's not dirty
  if (action.type === REDUX_FORM_SUBMIT_SUCCESS) {
    const formName = action.meta.form;
    const formValues = getFormValues(formName)(state);

    store.dispatch(initialize(formName, formValues, false, {keepSubmitSucceeded: true}));
  }

  // With the form re-initialized (above), we can now set our "submitting" flag to false
  if (action.type === REDUX_FORM_SUBMIT_SUCCESS || action.type === REDUX_FORM_SUBMIT_FAILED) {
    const formName = action.meta.form;

    store.dispatch(stopSubmittingForm(formName, currentPath));
    return next(action);
  }

  const forms = {...openFormPathMappings[currentPath].forms};
  const dirtyFormName = getFirstDirtyFormName(state, forms);

  // Return if there are no dirty forms
  if (!dirtyFormName) return next(action);

  store.dispatch(warnPendingFormChanges(dirtyFormName, currentPath, getNextPath(action)));

  return;
};

/**
 * Determine if the action type is of interest to this middleware
 *
 * @param actionType
 * @returns {boolean}
 */
const actionTypeOfInterest = (actionType) =>
  ([
    ROUTER_LOCATION_CHANGE,
    ROUTER_CALL_HISTORY_METHOD,
    REDUX_FORM_SUBMIT_START,
    REDUX_FORM_SUBMIT_SUCCESS,
    REDUX_FORM_SUBMIT_FAILED

  ]).includes(actionType);

/**
 * NOTE: In some cases, "@@router/CALL_HISTORY_METHOD" is called just before "@@router/LOCATION_CHANGE". If its "method" isn't "push", we can ignore it. But if it's push, we need to intercept before "@@router/LOCATION_CHANGE" subsequently fires, as in that case the url in the browser changes, and we don't want that.
 *
 * @param action
 * @returns {boolean}
 */
export const isCallHistoryActionWithoutPush = (action) =>
  action.type === ROUTER_CALL_HISTORY_METHOD && action.payload.method !== 'push';

/**
 * Gets the formName of the first dirty form it finds
 *
 * @param state
 * @param forms
 * @returns {string|null}
 */
export const getFirstDirtyFormName = (state, forms) => {
  for (const formName in forms) {
    if (!forms.hasOwnProperty(formName)) continue;

    const openForm = forms[formName];

    if (!openForm.dismissed && !openForm.submitting && isDirty(formName)(state)) {
      return formName;
    }
  }

  return null;
};

/**
 * Gets the next path user intended to navigate to
 *
 * @param action
 * @returns {string|*}
 */
export const getNextPath = (action) => {
  let pathname = action.type === ROUTER_CALL_HISTORY_METHOD ?
    `${action.payload.args[0]}` :
    action.payload.pathname;

  if (pathname[0] !== '/') pathname = `/${pathname}`;

  return pathname;
};

export default openFormMiddleware;
