import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';
import {PACKAGE_MOTHER_PLANTS_FORM} from '../../../constants/forms';
import PackageMotherPlantsForm from './PackageMotherPlantsForm';
import validate from './validate';


const PackageMotherPlantsFormWrapper = reduxForm({
  form: PACKAGE_MOTHER_PLANTS_FORM,
  validate,
})(PackageMotherPlantsForm);

PackageMotherPlantsFormWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  itemMasters: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  trackingTags: PropTypes.array,
  integrationState: PropTypes.object.isRequired,
};

export default PackageMotherPlantsFormWrapper;
