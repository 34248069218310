/* eslint-disable import/no-named-as-default */
import Leafly from './Leafly';
import Onfleet from './Onfleet';
import Hypur from './Hypur';
import Weedmaps from './Weedmaps'; //eslint-disable-line
import Isolocity from './Isolocity';
import FutuCare from './FutuCare';
import MxMerchant from './MxMerchant';
import Intellicheck from './Intellicheck';
import Aeropay from './Aeropay';
import Posabit from './Posabit';
import Alleaves from './Alleaves';

export default {
  integration_leafly_settings: Leafly,
  integration_onfleet_settings: Onfleet,
  integration_weedmaps_settings: Weedmaps,
  integration_isolocity_settings: Isolocity,
  integration_hypur_settings: Hypur,
  integration_futucare_settings: FutuCare,
  integration_mxmerchant_settings: MxMerchant,
  integration_intellicheck_settings: Intellicheck,
  integration_aeropayintegrated_settings: Aeropay,
  integration_posabit_settings: Posabit,
  integration_alleaves_settings: Alleaves,
};
