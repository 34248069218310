import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import ModalWrapper from '../../common/ModalWrapper';

const ReassignConfirmModal = (props) => {

  return (
    <ModalWrapper
      Component={false}
      title={I18n.t('supplyChain.reassignConfirmTitle')}
      headerClass='bg-info-dark'
      onHide={props.onHide}
      showModal={props.show}
      okayButton={props.okayButtonProps}
      cancelButton={props.cancelButtonProps}
      dialogClassName='modal-sm'
      version={2}
    >
      <p>{I18n.t('supplyChain.reassignConfirmMessage')}</p>
    </ModalWrapper>
  );
};

ReassignConfirmModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  okayButtonProps: PropTypes.object,
  cancelButtonProps: PropTypes.object,
};

export default ReassignConfirmModal;
