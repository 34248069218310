import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {I18n} from 'react-redux-i18n';
import {FaTimes} from 'react-icons/fa';

const InProgressOverlay = (props) => {

  const translate = (props.translate) ? props.translate : false;
  const showLoader = (props.showLoader !== undefined) ? props.showLoader : true;
  const showOk = (props.showOk !== undefined) ? props.showOk : false;
  const messageObject = (props.messageObject) ? props.messageObject : {};
  const classes = ['simple-overlay'];

  /*const defaults = {
    translate: false,
    showLoader: true,
    messageObject: {},
    onDismiss: false
  };

  const settings = Object.assign({}, defaults, props);*/

  classes.push(props.isActive ? 'show-loader' : 'hide-loader');

  const dismissButton = (<div className='loader-dismiss'>
    <div><FaTimes style={{fontSize: '24px', color: '#ccc'}} onClick={() => {props.onDismiss();}}/></div>
  </div>);

  let loader;
  if(showLoader){
    loader = (<div className='logo-rotate'/>);
  }

  let okButton;
  if(showOk){
    okButton = (<button style={{marginTop: '8px'}} onClick={() => {props.onDismiss('buttonClick');}} className='btn-primary btn btn-block'>{I18n.t('general.okay')}</button>);
  }

  const dismissable = (props.onDismiss) ? dismissButton : '';

  let message = I18n.t('inProgressOverlay.message');

  if(props.message){
    message = (translate) ? I18n.t(props.message, messageObject) : props.message;
  }

  return (<div className={cx(classes)}>
    <div className='overlay-loader'>
      {dismissable}
      {loader}
      <div className='loader-message' style={{marginTop:'8px'}}>
        {message}
      </div>
      <div>
        {okButton}
      </div>
    </div>
  </div>);

};

InProgressOverlay.propTypes = {
  isActive: PropTypes.bool,
  message: PropTypes.string,
  messageObject: PropTypes.object,
  onDismiss: PropTypes.oneOfType([PropTypes.func,PropTypes.bool]),
  translate: PropTypes.bool,
  showLoader: PropTypes.bool,
  showOk: PropTypes.bool
};

export default InProgressOverlay;
