import {requiredFieldValidation} from '../../../common/form/redux-form/validations';

const validate = values => {
  const errors = {};
  errors.date_from = requiredFieldValidation(values.date_from, 'common.form.startDate');
  errors.date_to = requiredFieldValidation(values.date_to, 'common.form.endDate');
  return errors;
};

export default validate;
