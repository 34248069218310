import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import get from 'lodash.get';
import map from 'lodash.map';
import isEmpty from 'lodash.isempty';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { goBack } from 'react-router-redux';
import TestResultsForm from './TestResultsForm';
import validate from './validate';  //eslint-disable-line
import { getIntegrationState } from '../../../selectors/integration/integrationSelectors';
import * as isolocityActions from '../../../actions/integrations/isolocity';
import * as dataNames from '../../../constants/dataNames';
import * as testResultsActions from '../../../actions/lab-result';
import * as isolocitySelectors from '../../../selectors/integration/isolocitySelectors';
import { convertFormInputDateToDbDate } from '../../../util/dateHelpers';
import { getModifyTestResultsPayload,
  isIsolocityStatusPassed,
  canRenderTestResultSection,
  getDisallowTestResultCreation,
  getTestResultConfig,
  getTestResultTestTypes,
  isPickableDate } from '../../../selectors/testResultsSelectors';
import { unsetData } from '../../../actions/dataActions';
import {getLabApplyProfilesSetting} from '../../../selectors/complianceSettingsSelectors';
import {isFeatureEnabled} from '../../../selectors/featureToggles';

const TestResultsFormWrapper = reduxForm({
  validate,
  enableReinitialize: true
})(TestResultsForm);

TestResultsFormWrapper.propTypes = {
  form: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  partners: PropTypes.array.isRequired,
  partnerFacilities: PropTypes.array.isRequired,
  packageOptions: PropTypes.array.isRequired,
  clearSearch: PropTypes.func.isRequired,
  addMessage: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  isEditingDisabled: PropTypes.bool,
  mode: PropTypes.string,
  documentUrl: PropTypes.string,
  changeUploadStatus: PropTypes.func.isRequired,
  onDocChange: PropTypes.func.isRequired,
  inheritFromItemMaster: PropTypes.func,
  requiredDimensions: PropTypes.array,
  change: PropTypes.func.isRequired,
  isLabApplyProfiles: PropTypes.bool,
  facilityState: PropTypes.string,
  canRenderDimensionGroup: PropTypes.func.isRequired,
  disallowTestResultCreation: PropTypes.func.isRequired,
  isUtahQaChangeRequestEnabled: PropTypes.bool.isRequired,
  isLeafPaConfigPackEnabled: PropTypes.bool.isRequired,
  isPerServingMode: PropTypes.bool
};

TestResultsFormWrapper.defaultProps = {
  loading: false,
  isEditingDisabled: false
};



export default withRouter(
  connect(
    (state, ownProps) => ({
      integrationState: getIntegrationState(state),
      isolocityTestResult: isolocitySelectors.getTestResult(state),
      isIsolocityStatusPassed: isIsolocityStatusPassed(state),
      isLabApplyProfiles: getLabApplyProfilesSetting(state),
      canRenderDimensionGroup: canRenderTestResultSection(state),
      disallowTestResultCreation: getDisallowTestResultCreation(state),
      isUtahQaChangeRequestEnabled: isFeatureEnabled(state)('feature_ut_qa_change_request'),
      isLeafPaConfigPackEnabled: isFeatureEnabled(state)('feature_leaf_pa_configuration_pack'),
      isPaLabEnhancementEnabled: isFeatureEnabled(state)('feature_pa_hb_1024_lab_enhancements'),
      testResultConfig: getTestResultConfig(state),
      testResultTestTypes: getTestResultTestTypes(state),
      isPickableDate: isPickableDate(state),
    }),
    (dispatch, ownProps) => {
      const actions = {
        /**
         * Called when user create a new test result
         * @param payload
         * @returns {*}
         */
        onCreate(payload) {
          return dispatch(
            testResultsActions.save({
              ...payload,
              testing_date: convertFormInputDateToDbDate(payload.testing_date, ownProps.timezone),
              medical_conditions: !isEmpty(payload.medical_conditions)
                ? JSON.stringify(payload.medical_conditions)
                : undefined
            })
          );
        },

        /**
         * Called when user is updating already existed lab result
         * @param id
         * @param payload
         * @returns {*}
         */
        onUpdate(id, payload) {
          payload.isolocity = Boolean(payload.isolocity);
          payload.testing_date = convertFormInputDateToDbDate(payload.testing_date, ownProps.timezone);
          payload.medical_conditions = !isEmpty(payload.medical_conditions)
            ? JSON.stringify(payload.medical_conditions)
            : null;
          payload = getModifyTestResultsPayload(payload, ownProps.initialValues, ownProps.timezone);
          return dispatch(testResultsActions.update(id, payload));
        },

        /**
         * Getting isolocity status for a specific test result
         */
        fetchIsolocityTestResult() {
          const references = get(ownProps, 'initialValues.references', []);

          dispatch(
            isolocityActions.fetchIsolocityTestResult(ownProps.params.id, {
              force: true,
              package_ids: map(references, 'reference_id'),
              package_codes: map(references, 'package_code')
            }, {
              failed: 'cultivation.testResults.isolocity.labResultNotMapped'
            })
          );
        },
        /**
         * Handle submit button
         */
        save(payload) {
          const promise =
            ownProps.params.id > 0 ? actions.onUpdate(ownProps.params.id, payload) : actions.onCreate(payload);

          return promise.then(() => dispatch(goBack()));
        },

        /**
         * Handle saving the record as in progress
         * @param payload
         * @returns {*}
         */
        saveAsInProgress() {
          const payload = ownProps.currentValues;
          payload.completion_status = 'in_progress';
          actions.save(payload);
        },

        /**
         * Handle saving the record as in progress
         * @param payload
         * @returns {*}
         */
        saveAsComplete(payload) {
          payload.completion_status = 'completed';
          actions.save(payload);
        },

        /**
         * Handle saving of Isolocity Test Results
         * @param payload
         * @returns {*}
         */
        saveIsolocityTestResult(payload) {
          payload.isolocity = true;

          actions.save(payload);
        },

        /**
         * Clear search data
         */
        clearSearch() {
          dispatch(unsetData(dataNames.products));
        },

        /**
         * Package change
         */
        handleFirstPackageChange(pkg) {
          if (get(ownProps, 'facilityHasLabModule')) {
            dispatch(testResultsActions.setPartnerFromPackage(get(pkg, 'reference_id'), get(ownProps, 'form')));
          }
        }
      };

      return actions;
    }
  )(TestResultsFormWrapper)
);

