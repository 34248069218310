import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import {Field} from 'redux-form';

import ReactSelectInput from '../../../common/form/ReactSelectInput';
import MultiselectInput from '../../../common/form/MultiselectInput';

const CureApiProductFilterForm = ({orderTypeOptions, formOptions}) => {
  return (
    <form noValidate={true}>
      <Row>
        <Col xs={4} md={3} lg={2}>
          <Field name='order_type_id' component={ReactSelectInput} props={{
            label: I18n.t('categoryManagement.highLowTHC'),
            options: orderTypeOptions,
          }}/>
        </Col>
        <Col xs={8} sm={6} md={4} lg={3}>
          <Field name='form_ids' component={MultiselectInput} props={{
            label: I18n.t('categoryManagement.deliveryMethod'),
            options: formOptions,
          }}/>
        </Col>
      </Row>
    </form>
  );
};

CureApiProductFilterForm.propTypes = {
  orderTypeOptions: PropTypes.array.isRequired,
  formOptions: PropTypes.array.isRequired,
};

export default CureApiProductFilterForm;
