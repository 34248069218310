import {createSelector, createStructuredSelector} from 'reselect';
import {BT_CLONE, BT_PLANT_TISSUE, BT_SEED} from '../../constants/integration/biotrack/biotrackInventoryTypes';
import {getApplicablePlatformSubcategoryIds} from '../integration/biotrackCategoriesSelectors';
import {getMetrcTags} from '../integrationSelectors';
import {getIntegrationState} from '../integration/integrationSelectors';
import {getSelectedPlants, getSelectedPlantStrainIds} from '../plantsSelectors';
import {getFlattenedLocations} from '../locationsSelectors';
import {getItemMasters} from '../itemMastersSelectors';
import {isFeatureEnabled} from '../featureToggles';

const getBiotrackSubcategoryIds = getApplicablePlatformSubcategoryIds([BT_CLONE, BT_SEED, BT_PLANT_TISSUE]);
const isItemMasterServiceFirstEnabled = state => isFeatureEnabled(state)('feature_item_master_listing_service_first');

const getTrackingTags = createSelector([getIntegrationState, getMetrcTags], (integrationState, metrcTags) => {
  if (integrationState.isMetrc) {
    return metrcTags;
  }

  return [];
});

const getSubcategoryIds = createSelector(
  [getIntegrationState, getBiotrackSubcategoryIds],
  ({isBiotrack}, btSubcategoryIds) => {
    if (isBiotrack) {
      return btSubcategoryIds;
    }
    return [];
  }
);

const getItemMastersGroupped = createSelector(
  [getSelectedPlantStrainIds, getItemMasters],
  (strainIds, itemMasters) => {
    return strainIds.reduce(
      (acc, strainId) => ({
        ...acc,
        [strainId]: itemMasters.filter(itemMaster => itemMaster.strain_id === strainId),
      }),
      {}
    );
  }
);

const getInitialValues = createSelector(
  [getSelectedPlants],
  (plants) => {
    return {
      plants: plants.map(plant => {
        return {
          plant_id: plant.id,
          strain_id: plant.strain_id,
          plant_name: plant.plant_id,
          strain_name: plant.strain_name,
          batch_name: plant.batch_name,
          packages: [{}],
        };
      })
    };
  }
);

export const getPackageMotherPlantsPageProps = createStructuredSelector({
  integrationState: getIntegrationState,
  subcategoryIds: getSubcategoryIds,
  selectedStrainIds: getSelectedPlantStrainIds,
  locations: getFlattenedLocations,
  itemMasters: getItemMastersGroupped,
  initialValues: getInitialValues,
  trackingTags: getTrackingTags,
  useItemMasterServiceFirst: isItemMasterServiceFirstEnabled,
});

export const getPackageMotherPlantsPayload = (formValues) => {
  return {
    plants: formValues.plants.map(plant => {
      return {
        plant_id: plant.plant_id,
        packages: plant.packages,
      };
    }),
  };
};
