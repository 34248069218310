import {createSelector} from 'reselect';
import {I18n} from 'react-redux-i18n';
import {formValueSelector} from 'redux-form';

import {analyticsReports,aLaCartAvailableForOrg} from '../constants/dataNames';
import getCurrentFacility from './facility/getCurrentFacility';
import {getFacilities} from './facilitiesSelectors';
import {ANALYTICS_REPORTS_SEARCH_FORM} from '../constants/forms';


const getActiveTab = (_, props) => props.tab;
const getAnalyticsReports = (state) => state[analyticsReports];
const getALaCartAvailableForOrg = (state) => state[aLaCartAvailableForOrg];
const searchFormSelector = formValueSelector(ANALYTICS_REPORTS_SEARCH_FORM);
const getSearchFormValue = (state) =>
  (searchFormSelector(state, 'report_search') || '').toLowerCase();

/**
 * Determine whether the report attribute in question is for regulatory purposes and if so,
 * whether the current facility's province is included in the report's specified provinces
 *
 * @param reportAttribute
 * @param report
 * @param currentFacility
 *
 * @returns {boolean}
 */
const isRegulatoryAndProvinceNotIncluded = (reportAttribute, report, currentFacility) =>
  reportAttribute === 'is_regulatory' &&
  !report.provinces.includes(currentFacility.province_code);

/**
 * Determine whether the active tab is included in the select list of tabs
 *
 * @param tabsAndReportsMap
 * @param activeTab
 * @returns {boolean}
 */
const activeTabIncludedInTabs = (tabsAndReportsMap, activeTab) =>
  Object.keys(tabsAndReportsMap).includes(activeTab);

/**
 * Generate a complete map of tabs and associated reports
 *
 * @returns Object
 */
export const getTabsAndReportsMap = createSelector(
  [getFacilities, getCurrentFacility, getAnalyticsReports,getALaCartAvailableForOrg],
  (facilities, currentFacility, analyticsReports, aLaCartAvailableForOrg) => {
    const facilityTypeToTabMap = {
      grow: 'cultivation',
      dispensary: 'retail',
      manufacturing: 'manufacturing',
      lab: 'lab'
    };

    const reportAttributeToTabTypeMap = {
      is_regulatory: 'regulatory',
      available_to_retail: 'retail',
      available_to_extraction_and_infusion: 'manufacturing',
      available_to_cultivation: 'cultivation',
      available_to_lab: 'lab',
      purchased:'purchased',
      for_purchase:'for_purchase'
    };


    // create unique array of tab types derived from facility types
    const tabTypes = [
      ...new Set(facilities.map(facility => facilityTypeToTabMap[facility.type])),
      'regulatory','for_purchase','purchased'
    ];

    const favorites = [];

    analyticsReports.map((f) => {
      const isALaCart = f.is_ala_cart;
      const isPurchased = !!aLaCartAvailableForOrg.find(id => id === f.id);
      //if it is not ala cart it stays a normal report
      if(!isALaCart){
        f.for_purchase = 0;
        f.purchased = 0;
      }
      //if it is ala cart and already purchased it goes into the "purchased report" tab
      if(isPurchased){
        f.purchased = 1;
        f.for_purchase = 0;
      }
      //if it is ala cart and has not been purchased it goes into the "for purchase" tab
      if(isALaCart  && !isPurchased){
        f.available_to_retail = 0;
        f.available_to_cultivation = 0;
        f.available_to_extraction_and_infusion = 0;
        f.available_to_lab = 0;
        f.is_regulatory = 0;
        f.for_purchase = 1;
      }
    });


    const reportsByTab = analyticsReports.reduce((result, report) => {
      let pushedToAllTab = false;

      // loop through report attributes
      Object.keys(reportAttributeToTabTypeMap).forEach(reportAttribute => {
        if (!report[reportAttribute]) return result;

        const tabForReport = reportAttributeToTabTypeMap[reportAttribute];

        if (
          (!tabTypes.includes(tabForReport)) ||
          (isRegulatoryAndProvinceNotIncluded(reportAttribute, report, currentFacility)) 
        ) return result;

        // Initialize the key to an empty array if the key doesn't exist
        if (!result[tabForReport]) result[tabForReport] = [];

        result[tabForReport].push(report);

        // handle favorites
        if(report.is_favorite){
          const haveReport = favorites.find((r) => r.id === report.id);
          if(!haveReport){
            favorites.push(report);
          }
        }

        // Push to the "All" tab if it hasn't been already but remove ones that are for putchase
        if (!pushedToAllTab && !report.for_purchase) {
          pushedToAllTab = true;
          result.all.push(report);
        }
      });

      return result;
    }, {all: []});

    if(favorites.length){
      reportsByTab.favorites = favorites;
    }
    return reportsByTab;
  }
);

/**
 * Create complete tab data for select tab types that contain reports
 *
 * @returns array
 */
export const getTabs = createSelector(
  [getTabsAndReportsMap],
  (tabsAndReportsMap) => {
    const selectTabTypes = [...Object.keys(tabsAndReportsMap)];

    return selectTabTypes.reduce((result, tab) => {
      result.push({
        id: tab,
        eventKey: tab,
        title: I18n.t(`reporting.analytics.tabs.${tab}`)
      });
      //pushed the "for Purchase" tab last
      result.push(result.splice(result.findIndex(v => v.title == 'For Purchase'), 1)[0]);
      return result;
    }, []);
  }
);

/**
 * Selector to get analytics reports for the active tab
 *
 * @returns array
 */
export const getAnalyticsReportsForActiveTabSelector = createSelector(
  [getTabsAndReportsMap, getActiveTab, getSearchFormValue],
  (tabsAndReportsMap, activeTab, searchFormValue) =>
    getAnalyticsReportsForActiveTab(tabsAndReportsMap, activeTab, searchFormValue)
);

/**
 * Get analytics reports for the active tab
 *
 * @param getTabsAndReportsMap
 * @param activeTab
 * @param searchFormValue
 * @returns {Array|*}
 */
export const getAnalyticsReportsForActiveTab = (tabsAndReportsMap, activeTab, searchFormValue) => {
  if (!activeTab || !activeTabIncludedInTabs(tabsAndReportsMap, activeTab)) return [];

  const activeTabReports = tabsAndReportsMap[activeTab];

  if (!searchFormValue) return activeTabReports;

  return activeTabReports.filter((report) =>
    report.name.toLowerCase().includes(searchFormValue)
  );
};
