import React from 'react';

import SubmitSection from '../common/SubmitSection';
import BaseFormFields from '../common/BaseFormFields';


const Intellicheck = () => {
  return (
    <div>
      <BaseFormFields integrator='intellicheck' />
      <SubmitSection />
    </div>
  );
};


export default Intellicheck;
