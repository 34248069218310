import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Table, ModalBody} from 'react-bootstrap';
import RouteButton from '../../common/RouteButton';
import * as productionRunTypes from '../../../constants/productionRunTypes';

const ItemProductionRunsComponent = ({productionRuns}) => {
  return (
    <ModalBody>
      <Table striped={true}>
        <thead>
        <tr>
          <th>{I18n.t('ei.inventory.table.runName')}</th>
          <th>{I18n.t('ei.inventory.table.runType')}</th>
          <th>{I18n.t('ei.inventory.table.reservedQty')}</th>
          <th>{I18n.t('common.form.uom')}</th>
          <th>{I18n.t('ei.inventory.actions.completeRun')}</th>
        </tr>
        </thead>
        <tbody>
        {productionRuns.map((run, index) => {
          const path = `/ei/${run.runType === productionRunTypes.assembly ? 'infusions' : 'processing'}/complete/${run.id}`;
          return (
            <tr key={index}>
              <td>{run.name}</td>
              <td>{I18n.t(`runTypes.${run.runType}`)}</td>
              <td>{run.reservedQty}</td>
              <td>{run.uom}</td>
              <td className='btn-col'>
                <RouteButton path={path} props={{block: true}}>
                  {I18n.t('ei.inventory.actions.completeRun')}
                </RouteButton>
              </td>
            </tr>
          );
        })}
        </tbody>
      </Table>
    </ModalBody>
  );
};

ItemProductionRunsComponent.propTypes = {
  productionRuns: PropTypes.array.isRequired,
};

export default ItemProductionRunsComponent;
