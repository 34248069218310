import round from 'lodash.round';
import {convertFromBase} from '../../../../util/uomHelpers';

export const dummy = () => {};

export const getLineTotals = (subItems, item, transferRecord) => {
  if(transferRecord && item) return {
    price: round( (item.qty * item.unit_price), 2),
    weight: round(item.qty, 2)
  };

  return (subItems || []).reduce(
    (acc, item) => {
      return {
        price: round((acc.price || 0) + (item.qty || 0) * item.unit_price, 2),
        weight: round((acc.weight || 0) + (item.qty || 0) * (item.itemWeight && convertFromBase(item.itemWeight.weight_base, item.itemWeight.uom) || 0), 2),
      };
    },
    {price: 0, weight: 0}
  );
};