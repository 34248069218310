import React from 'react';
import PropTypes from 'prop-types';

import ReduxPatient from './ReduxPatient'; // eslint-disable-line import/no-named-as-default

const PatientDrawer = ({onClose, push}) => {
  return (
    <div className='patient-drawer'>
      <ReduxPatient
        close={onClose}
        push={push}
        showCloseButton={true}
      />
    </div>
  );
};


PatientDrawer.propTypes = {
  onClose: PropTypes.func,
  push: PropTypes.func,
};

PatientDrawer.defaultProps = {
  onClose: () => {},
  push: () => {},
};

export default PatientDrawer;
