import { createSelector } from 'reselect';
import { userHasPermission } from '../permissionsSelectors';

export const getRenderFacts = createSelector([userHasPermission], // eslint-disable-line
  (userHasPermission) => {
    return {
      canEnablePublicProfile: userHasPermission,
      showConnectsTabs: userHasPermission,
    };
  });
