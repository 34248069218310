import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import find from 'lodash.find';
import {push} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import {FaSave, FaTrash} from 'react-icons/fa';
import {isLeafIntegrator} from '../../selectors/integration/leafSelectors';
import {getReceiptListingData, getSelectedInventoryReceiptsIds} from '../../selectors/orderSelectors';
import {getPurchaseOrderListingTabs} from '../../selectors/purchaseOrdersSelectors';
import {handleComplexSelectRow} from '../../actions/helpers/selectedDataHelper';
import {getUnpaginatedData} from '../../actions/apiActions';
import {setItem, unsetItem} from '../../actions/itemActions';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import * as statuses from '../../constants/orderStatuses';
import * as p from '../../constants/permissions';
import TablePageWrapper from '../common/grid/TablePageWrapper';
import PageTitle from '../common/PageTitle';
import MultiActionColumn from '../common/grid/columns/MultiActionColumn';
import {isMetrcIntegrator, isMetrcTransfersEnabled} from '../../selectors/integration/metrcSelectors';
import {getCurrencySymbol, getInternationalNumberFormat} from '../../selectors/InternationalOperationsSelectors';
import {fieldFormatHelperDecimals} from '../../util/internationalHelpers';
import InternationalCurrencyStatic from '../common/form/InternationalCurrencyStatic';
import InternationalDateStatic from '../common/form/InternationalDateStatic';
import {isFeatureEnabled} from '../../selectors/featureToggles';
import {isLeafPaConfigPackClosedLoopFacility, isLeafUtahConfigPackClosedLoopFacility} from '../../selectors/facilitiesSelectors';

export class InventoryReceiptListingPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.switchTab = this.switchTab.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.printInvoice = this.printInvoice.bind(this);
    this.printManifest = this.printManifest.bind(this);
    this.editReceipt = this.editReceipt.bind(this);
    this.editMetrcReceipt = this.editMetrcReceipt.bind(this);
    this.receiveReceipt = this.receiveReceipt.bind(this);
    this.updateColumns = this.updateColumns.bind(this);

    this.updateColumns();
    this.state = {activeTab: null};
  }

  componentWillMount() {
    this.props.actions.setItem('inventory-receipts', itemNames.currentPage);

    const {status} = this.props.params;
    const eventKey = status === 'inactive' ? statuses.inactiveInventory : statuses.activeInventory;
    this.switchTab(eventKey);
    this.props.actions.getUnpaginatedData('/api/partner_facilities', dataNames.partnerFacilities);
    this.props.actions.getUnpaginatedData('/api/drivers', dataNames.drivers);
  }

  componentWillUnmount() {
    this.props.actions.unsetItem(itemNames.currentPage);
    this.props.actions.unsetItem(itemNames.currentParams);
    this.props.actions.unsetItem(itemNames.currentRequest);
  }

  updateColumns () {
    const {isMetrc, isMetrcTransfersEnabled, params: {status}, isFeaturePaHempTransfer, isLeafPaConfigPackClosedLoopFacility, isFeatureUtHempWaste, isLeafUtahConfigPackClosedLoopFacility, newProductMasterIsEnabled } = this.props;

    const showApprovalStatusColumn = (isFeaturePaHempTransfer && isLeafPaConfigPackClosedLoopFacility) || (isFeatureUtHempWaste && isLeafUtahConfigPackClosedLoopFacility);

    const columns = [
      {
        name: 'cultivation.purchaseOrders.table.receiptID',
        dataId: 'receipt_number',
        hidden: false,
        dataSort: true,
        width: '150px',
      },
      {
        name: 'cultivation.purchaseOrders.table.dateOrdered',
        dataId: 'date_ordered',
        /* eslint-disable react/no-multi-comp */
        formatter: (cell, row) => cell ? <InternationalDateStatic useSystemDate={false}>{cell}</InternationalDateStatic> : ' ',
        hidden: false,
        dataSort: true,
      },
    ];

    if (showApprovalStatusColumn) {
      columns.push(
        {
          name: 'cultivation.purchaseOrders.table.approvalStatus',
          dataId: 'approval_status',
          formatter: (cell, row) => row['contains_only_cbd_flower_or_hemp_waste'] && row['partner_facility'] && row['partner_facility']['connect_facility_code'] === null
            ? cell
              ? cell.charAt(0).toUpperCase() + cell.slice(1) : 'Pending'
              : '',
          hidden: false,
          dataSort: true,
        },
      );
    }

    columns.push(
      {
        name: 'cultivation.purchaseOrders.table.quotedDelivery',
        dataId: 'date_expected',
        /* eslint-disable react/no-multi-comp */
        formatter: (cell, row) => cell ? <InternationalDateStatic useSystemDate={false}>{cell}</InternationalDateStatic> : ' ',
        hidden: false,
        dataSort: true,
      },
      {
        name: 'cultivation.purchaseOrders.table.partner',
        dataId: 'partner_name',
        hidden: false,
        dataSort: true,
        width: '200px',
      },
      {
        name: 'cultivation.common.table.driver',
        dataId: 'driver_name',
        hidden: false,
        dataSort: true,
        width: '200px',
      },
      {
        name: 'cultivation.common.table.numberOfItems',
        dataId: 'item_count',
        hidden: false,
        dataSort: true,
      },
      {
        name: 'cultivation.purchaseOrders.table.orderTotal',
        dataId: 'order_total',
        csvFormatter: (cell) => { return this.props.currencySymbol + fieldFormatHelperDecimals(Number(cell).toFixed(2), '', this.props.internationalNumberFormat); },
        /* eslint-disable react/no-multi-comp */
        formatter: (cell, row) => <InternationalCurrencyStatic>{cell}</InternationalCurrencyStatic>,
        hidden: false,
        dataSort: true,
        width: '200px',
      },
      {
        name: 'cultivation.purchaseOrders.table.purchaseOrder',
        dataId: 'po_number',
        hidden: false,
        dataSort: true,
        width: '150px',
      },
      {
        name: 'cultivation.purchaseOrders.table.status',
        dataId: 'status',
        hidden: false,
        dataSort: true,
        width: '150px',
      }
    );

    if (isMetrc && isMetrcTransfersEnabled) {
      columns.push({
        name: 'cultivation.salesOrders.table.metrcIntegrated',
        dataId: 'is_imported',
        formatter: cell => cell ? I18n.t('general.yes') : I18n.t('general.no'),
        hidden: false,
        dataSort: true,
        width: '100px'
      });
    }

    columns.push({
      hidden: false,
      name: 'common.form.actions',
      csvFormatter: cell => {
        return I18n.t('common.form.actions');
      },
      formatter: MultiActionColumn({
        permissions: [
          p.manage_inventory_receipts,
        ],
        buttons: [
          {
            label: 'common.form.edit',
            action: this.editMetrcReceipt,
            display: newProductMasterIsEnabled && isMetrc,
          },
          {
            label: 'common.form.edit',
            action: this.editReceipt,
            display: !newProductMasterIsEnabled || !isMetrc
          },
          {
            label: 'common.form.receive',
            action: this.receiveReceipt,
            display: isMetrc && (status !== 'inactive'),
          },
        ],
      }),
      width: '200px',
      dataSort: false,
    });

    if(this.props.isLeaf){
      columns.push({
        name: 'cultivation.transfers.form.integrationStatus',
        dataId: 'integration_issue_flag',
        width: '100px',
        hidden: false,
        dataSort: true,
        /* eslint-disable react/no-multi-comp */
        formatter: (cell) => {
          if(cell === undefined) return '';
          return cell ? <FaTrash className='danger'/> : <FaSave className='success'/>;
        }
      });
    }

    this.setState({
      columns,
    });
  }

  editReceipt(event, row) {
    event.preventDefault();
    event.stopPropagation();
    this.props.actions.push(`/inventory-receipts/modify/${row.id}`);
  }

  editMetrcReceipt(event, row) {
    event.preventDefault();
    event.stopPropagation();
    this.props.actions.push(`/inventory-receipts/metrc/${row.id}`);
  }

  receiveReceipt (e, row) {
    e.preventDefault();
    e.stopPropagation();
    this.props.actions.push(`/inventory-receipts/modify/${row.id}?status=completed`);
  }

  switchTab(eventKey) {
    const {tabs} = this.props;
    const tab = find(tabs, {eventKey}) || tabs[2];
    this.props.actions.push(tab.route);
    this.setState({activeTab: eventKey});
    if (tab.apiRoute) {
      this.props.actions.getUnpaginatedData(
        tab.apiRoute,
        dataNames.inventoryReceipts,
        {failed: 'cultivation.inventoryReceipts.get.failed'}
      ).then(() => {
        this.updateColumns();
      });
      this.props.actions.setItem({ url: tab.apiRoute, params: {} }, itemNames.currentRequest);
    }
  }

  handleSelect(isSelected, rows) {
    this.props.actions.handleComplexSelectRow(rows, dataNames.inventoryReceipts, isSelected);
  }

  printInvoice() {}

  printManifest() {}

  render() {
    const {inventoryReceipts, selectedInventoryReceiptsIds, tabs} = this.props;
    const {activeTab, columns} = this.state;

    return (
      <div>
        <PageTitle primaryText={I18n.t('supplyChain.inventoryReceipts')}/>
        <TablePageWrapper
          ref={this.ref}
          settingKey='inventory-receipts'
          columns={columns}
          data={inventoryReceipts}
          activeTab={activeTab}
          tabs={tabs}
          selectedRows={selectedInventoryReceiptsIds}
          switchTab={this.switchTab}
          handleSelect={this.handleSelect}
          hideScanSearch = {true}
          noSelectionMode={true}
          hideExport={true}
        />
      </div>
    );
  }
}

InventoryReceiptListingPage.propTypes = {
  inventoryReceipts: PropTypes.array.isRequired,
  selectedInventoryReceiptsIds: PropTypes.array.isRequired,
  tabs: PropTypes.array.isRequired,
  timezone: PropTypes.string.isRequired,
  isLeaf: PropTypes.bool,
  isMetrc: PropTypes.bool,
  isMetrcTransfersEnabled: PropTypes.bool,
  params: PropTypes.shape({
    status: PropTypes.oneOf(['', 'active', 'inactive'])
  }).isRequired,
  actions: PropTypes.shape({
    handleComplexSelectRow: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  currencySymbol: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const inventoryReceipts = getReceiptListingData(state);
  const selectedInventoryReceiptsIds = getSelectedInventoryReceiptsIds(state);
  return {
    isLeaf: isLeafIntegrator(state),
    inventoryReceipts,
    selectedInventoryReceiptsIds,
    timezone: state.timezone,
    tabs: getPurchaseOrderListingTabs(state, {actions: {receiveTransfer: () => {}}}),
    isMetrc: isMetrcIntegrator(state),
    isMetrcTransfersEnabled: isMetrcTransfersEnabled(state),
    currencySymbol: getCurrencySymbol(state),
    internationalNumberFormat: getInternationalNumberFormat(state),
    isFeaturePaHempTransfer: isFeatureEnabled(state)('feature_pa_hemp_transfer'),
    isLeafPaConfigPackClosedLoopFacility: isLeafPaConfigPackClosedLoopFacility(state),
    isFeatureUtHempWaste: isFeatureEnabled(state)('feature_ut_hemp_waste'),
    isLeafUtahConfigPackClosedLoopFacility: isLeafUtahConfigPackClosedLoopFacility(state),
    newProductMasterIsEnabled: isFeatureEnabled(state)('feature_new_product_master'),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {handleComplexSelectRow, getUnpaginatedData, push, setItem, unsetItem};

  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryReceiptListingPage);
