
import * as types from '../../constants/actionTypes';
import initialState from '../../constants/initialState';

export default function paymentReducer (state = initialState.cart.payment, action){
  switch (action.type) {
  case types.SET_CREDIT:
    return Object.assign({}, state, {credit: action.credit});
  case types.SET_CHECK:
    return Object.assign({}, state, {check: action.check});
  case types.SET_CASH:
    return Object.assign({}, state, {cash: action.cash});
  case types.SET_DEBIT:
    return Object.assign({}, state, {debit: action.debit});
  case types.SET_GIFT_CARD:
    return Object.assign({}, state, {giftCard: action.giftCard});
  case types.SET_STORE_CREDIT:
    return Object.assign({}, state, {storeCredit: action.storeCredit});
  default:
    return state;
  }
}