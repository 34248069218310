import { createSelector } from 'reselect';
import find from 'lodash.find';
import get from 'lodash.get';
import * as itemNames from '../../constants/itemNames';
import * as dataNames from '../../constants/dataNames';
import {getIntegrationSettings} from './commonSelectors';
import {getSalesRegisters} from '../registersSelectors';

const getCategories = (state) => state[dataNames.categories];
const getIntegrationMappings = (state) => state[dataNames.integrationCategoryMappings];
const getThirdPartyIntegration = state => state[itemNames.thirdPartyIntegration];
const getActiveThirdPartyIntegrations = state => state[itemNames.activeThirdPartyIntegrations];
const getCurrentRegister = (state) => state.user.currentRegister; // Needed to duplicate here to prevent circular dependency

export const transformThirdPartyIntegration = createSelector([getThirdPartyIntegration], thirdPartyIntegration => {
  if (!thirdPartyIntegration || Object.keys(thirdPartyIntegration).length === 0) {
    return null;
  }

  const key = Object.keys(thirdPartyIntegration)[0];

  return {
    name: key,
    value: thirdPartyIntegration[key].value
  };
});

export const transformActiveThirdPartyIntegrations = createSelector([getActiveThirdPartyIntegrations], thirdPartyIntegrations => {
  if (!thirdPartyIntegrations || Object.keys(thirdPartyIntegrations).length === 0) {
    return [];
  }

  const integrations = [];
  const keys = Object.keys(thirdPartyIntegrations);

  for (const key in keys) {
    const integration = keys[key];

    integrations.push({
      name: integration,
      value: thirdPartyIntegrations[integration]
    });
  }

  return integrations;
});

export const getThirdPartyIntegrationValues = (integration) => {
  const cache = JSON.parse(localStorage.getItem('cache'));
  return get(cache, `integrationSettings.integration_${integration}_settings`);
};

// This will need to be refactored
// when we add more integrations.
export const getThirdPartyIntegrationPayload = (values) => {
  const payload = {
    settings:  [{
      scope: 'facility',
      scope_id: values.facility_id,
      setting_key: values.integration,
      value: {
        api_key: values.api_key,
        active: values.active ? 1 : 0
      }
    }]
  };

  if (values.integration === 'integration_leafly_settings') {
    payload.settings[0].value.is_scheduled_push = values.is_scheduled_push ? 1 : 0;
    payload.settings[0].value.inventory_preference = values.inventory_preference;
    payload.settings[0].value.is_sandbox = values.is_sandbox || false;
  } else if (values.integration === 'integration_weedmaps_settings') {
    payload.settings[0].value.dispensary_id = values.dispensary_id;
    payload.settings[0].value.is_scheduled_push = values.is_scheduled_push ? true : false;
    payload.settings[0].value.inventory_preference = values.inventory_preference;
    payload.settings[0].value.is_live_mode = values.is_live_mode ? true : false;
    payload.settings[0].value.pricing_type = values.pricing_type ? values.pricing_type : null;
  } else if (values.integration === 'integration_futucare_settings') {
    payload.settings[0].value = {
      username: values.username,
      password: values.password,
      active: values.active ? 1 : 0,
    };
  } else if (values.integration === 'integration_aeropayintegrated_settings') {
    payload.settings[0].value.merchant_location_uuid = values.merchant_location_uuid;
  } else if (values.integration === 'integration_posabit_settings') {
    payload.settings[0].value = {
      client_id: values.client_id,
      client_secret: values.client_secret,
      active: values.active ? 1 : 0,
    };
  } else if (values.integration === 'integration_alleaves_settings') {
    payload.settings[0].value = {
      active: values.active ? 1 : 0,
      terminals: values.terminals
    };
  }

  return payload;
};

// This will need to be refactored
// when we add more integrations.
export const getInitialValues = (integration) => {
  if (!integration) {
    return;
  }

  const initialValues = {
    api_key: integration.value.api_key,
    active: Boolean(integration.value.active),
  };

  if (integration && integration.name === 'integration_leafly_settings') {
    initialValues.inventory_preference = integration.value.inventory_preference;
    initialValues.is_scheduled_push = Boolean(integration.value.is_scheduled_push);
    initialValues.is_sandbox = get(integration, 'value.is_sandbox') || false;
  } else if (integration && integration.name === 'integration_weedmaps_settings') {
    initialValues.dispensary_id = integration.value.dispensary_id;
    initialValues.inventory_preference = integration.value.inventory_preference;
    initialValues.is_live_mode = integration.value.is_live_mode ? integration.value.is_live_mode : false;
    initialValues.pricing_type = integration.value.pricing_type ? integration.value.pricing_type : null;
  } else if (integration && integration.name === 'integration_futucare_settings') {
    initialValues.username = integration.value.username;
    initialValues.password = integration.value.password;
  } else if (integration && integration.name === 'integration_aeropayintegrated_settings') {
    initialValues.merchant_location_uuid = integration.value.merchant_location_uuid;
  } else if (integration && integration.name === 'integration_posabit_settings') {
    initialValues.client_id = integration.value.client_id;
    initialValues.client_secret = integration.value.client_secret;
  } else if (integration && integration.name === 'integration_alleaves_settings') {
    initialValues.terminals = integration.value.terminals;
  }

  return initialValues;
};

export const areAllProductsMappedToCategories = createSelector([getCategories, getIntegrationMappings], (categories, mappings) => {
  return categories.reduce((acc, category) => {
    if (!acc) return acc;
    const state = category.subcategories.reduce((acc, subcategory) => {
      if (get(subcategory, 'product_counts.active', 0) || get(subcategory, 'product_counts.inactive', 0)) {
        const mapping = mappings.find((mapping) => mapping.subcategory_id === subcategory.id);
        if (!mapping) return false;
      }
      return acc;
    }, true);
    return state;
  }, true);
});

export const isLeaflyIntegrator = createSelector(
  [getIntegrationSettings],
  integrationSettings => Boolean(get(integrationSettings, 'integration_leafly_settings.value.active'))
);

export const isWeedmapsIntegrator = createSelector(
  [getIntegrationSettings],
  integrationSettings => Boolean(get(integrationSettings, 'integration_weedmaps_settings.value.active'))
);

export const isOnfleetIntegrator = createSelector(
  [getIntegrationSettings],
  integrationSettings => Boolean(get(integrationSettings, 'integration_onfleet_settings.value.active'))
);

export const isIsolocityIntegrator = createSelector(
  [getIntegrationSettings],
  integrationSettings => Boolean(get(integrationSettings, 'integration_isolocity_settings.value.active'))
);

export const canShowIsolocityColumnForCategory = createSelector(
  [isIsolocityIntegrator],
  (isIsolocityIntegrator) => (category) => {
    return isIsolocityIntegrator && ['CONCENTRATE', 'INFUSED', 'EDIBLE', 'FLOWER', 'CARTPENS'].includes(category);
  }
);

export const isHypurIntegrator = createSelector(
  [getIntegrationSettings],
  integrationSettings => Boolean(get(integrationSettings, 'integration_hypur_settings.value.active'))
);

export const isMxMerchantIntegrator = createSelector(
  [getIntegrationSettings],
  integrationSettings => Boolean(get(integrationSettings, 'integration_mxmerchant_settings.value.active'))
);

export const isFutuCareIntegrator = createSelector(
  [getIntegrationSettings],
  integrationSettings => Boolean(get(integrationSettings, 'integration_futucare_settings.value.active'))
);

export const isAeropayIntegratedIntegrator = createSelector(
  [getIntegrationSettings],
  integrationSettings => Boolean(get(integrationSettings, 'integration_aeropayintegrated_settings.value.active'))
);

export const isPosabitIntegrator = createSelector(
  [getIntegrationSettings],
  integrationSettings => Boolean(get(integrationSettings, 'integration_posabit_settings.value.active'))
);

export const isAlleavesIntegrator = createSelector(
  [getIntegrationSettings],
  integrationSettings => Boolean(get(integrationSettings, 'integration_alleaves_settings.value.active'))
);

export const getAlleavesRegisterIds = createSelector(
  getIntegrationSettings,
  integrationSettings => {
    const terminals = get(integrationSettings, 'integration_alleaves_settings.value.terminals', []);
    return terminals.map(terminal => {
      return {mode: terminal.mode, register_id: terminal.register_id};
    });
  }
);

export const getAlleavesMode = createSelector(
  getIntegrationSettings,
  integrationSettings => (register) => {
    const alleaves_register_id = get(register, 'alleaves_register_id');
    if (!alleaves_register_id) {
      return;
    }
    const split = alleaves_register_id.split('-');
    return get(split, '1');
  }

);

// Can refund using Alleaves if Alleaves integration is active, mode is set to 'pin_debit', and current register is mapped to a terminal
// 20240220 - pin_debit is no longer supported. No longer allow refunds of Alleaves transactions
export const canRefundAlleaves = createSelector([], () => {
  return false;
});
// export const canRefundAlleaves = createSelector(
//   [getCurrentRegister, getSalesRegisters, getIntegrationSettings],
//   (currentRegister, registers, integrationSettings) => {
//     const register = find(registers, ['id', currentRegister]);
//     const alleavesRegisterId = get(register, 'alleaves_register_id');
//     const terminals = get(integrationSettings, 'integration_alleaves_settings.value.terminals', []);
//     const registerIsMapped = Boolean(terminals.find(terminal => terminal.register_id === alleavesRegisterId));
//     return get(integrationSettings, 'integration_alleaves_settings.value.mode') === 'pin_debit' &&
//       Boolean(get(integrationSettings, 'integration_alleaves_settings.value.active')) &&
//       registerIsMapped;
//   }
// );

export const getCurrentRegisterAlleavesRegisterId = createSelector(
  [getCurrentRegister, getSalesRegisters, getIntegrationSettings],
  (currentRegister, registers, integrationSettings) => {
    const register = find(registers, ['id', currentRegister]);
    return get(register, 'alleaves_register_id');
  }
);
