import * as types from '../constants/actionTypes';

import {maturePlantsRequireTracking, maturePlantsRequireTrackingErrorMessage, getSelectedPlants} from '../selectors/plantsSelectors';
import * as messageTypes from '../constants/messageTypes';
import { addMessage } from './systemActions';
import { getIntegrationState } from '../selectors/integration/integrationSelectors';

export function validateHarvestPlants() {
  return (dispatch, getState) => {
    const { selectedPlants } = getState();
    const selectedStrains = selectedPlants.reduce((strains, plant) => {
      strains.indexOf(plant.strain_id) < 0 && strains.push(plant.strain_id);
      return strains;
    }, []);
    if (selectedStrains.length !== 1) {
      dispatch(harvestMultipleStrains());
      return false;
    }

    if (maturePlantsRequireTracking(getState())) {
      const somePlantsDontHaveTrackingIds = selectedPlants.some((plant) => {
        if (!plant.state_integration_tracking_id) return true;
        if (plant.tag_requested) return true;
        return false;
      });

      if (somePlantsDontHaveTrackingIds) {
        dispatch(addMessage('error', maturePlantsRequireTrackingErrorMessage(getState())));
        return false;
      }
    }

    if (dispatch(_validateModalitiyPlants()) === false || dispatch(_validatePlantCupos()) === false) {
      return false;
    }

    return true;
  };
}

/**
 * Validate that all plants contain the same modality value
 * @returns {Function}
 */
const _validateModalitiyPlants = () => (dispatch, getState) => {
  const compared = {};
  const { isColombia } = getIntegrationState(getState());
  const selectedPlants = getSelectedPlants(getState());

  // Always true if it's not a Colombia
  if (isColombia === false) {
    return true;
  }

  selectedPlants.forEach((plant) => {
    compared[plant.modality] = plant.modality;
  });

  if (Object.keys(compared).length === 1) {
    return true;
  }

  dispatch(addMessage(messageTypes.error, 'error.harvestMultipleModalities'));

  return false;
};

const _validatePlantCupos = () => (dispatch, getState) => {
  const compared = {};
  const { isColombia } = getIntegrationState(getState());
  const selectedPlants = getSelectedPlants(getState());

  // Always true if it's not a Colombia
  if (isColombia === false) {
    return true;
  }

  selectedPlants.forEach((plant) => {
    compared[plant.cupo_id] = plant.modality;
  });

  if (Object.keys(compared).length === 1) {
    return true;
  }

  dispatch(addMessage(messageTypes.error, 'error.harvestDifferentCupos'));
  return false;
};

export function harvestMultipleStrains() {
  return { type: types.HARVEST_PLANTS_MULTIPLE_STRAINS };
}
