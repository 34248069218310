import {createSelector} from 'reselect';
import find from 'lodash.find';
import omit from 'lodash.omit';
import orderBy from 'lodash.orderby';
import * as itemNames from '../constants/itemNames';
import {getActiveFacility} from './facilitiesSelectors';
import {getItemProducerName} from './inventoryItemsSelectors';
import {getItemsAvailabilitySearch} from './itemsAvailabilitySelectors';
import {getPartners} from './partnersSelectors';
import {getOrganizationStrains} from './organizationStrainsSelectors';
import {getPhases} from  './phasesSelectors';

export const getCheckoutScan = state => state[itemNames.checkoutScan];

export const getFoundItems = createSelector(
  [getCheckoutScan],
  (checkoutScan) => {
    if (checkoutScan.item_master) {
      const itemMaster = omit(checkoutScan.item_master, 'items');
      return checkoutScan.item_master.items.map(item => ({...item, itemMaster}));
    } else if (checkoutScan.items) {
      return checkoutScan.items.map(item => ({
        ...omit(item, 'item_master'),
        itemMaster: item.item_master,
      }));
    }
    return [];
  }
);

export const getFoundItemOptions = createSelector(
  [getFoundItems, getPhases, getActiveFacility, getPartners, getOrganizationStrains, getItemsAvailabilitySearch],
  (items, phases, activeFacility, partners, strains, itemsAvailability) => {
    const itemOptions = items.map((item) => {
      const strain = strains.find(strain => strain.id === item.itemMaster.strain_id);
      const phase = find(phases, {id: item.manufacturing_phase_id});
      const availability = itemsAvailability.find(availability => availability.item_id === item.id);

      return {
        ...omit(item, 'id'),
        item_id: item.id,
        uom: item.uom,
        qty: Number(availability && availability.qty_available || 0).toFixed(2),
        maxQty: availability && availability.qty_available || 0,
        packageCode: item.package_code,
        lotName: item.lot_number,
        locationName: item.location_name,
        productName: item.itemMaster.name,
        vendorName: getItemProducerName(item, activeFacility, partners),
        strainName: strain && strain.strain_name || '',
        phaseName: phase && phase.name || '',
        phaseId: item.manufacturing_phase_id || 1,
        subcategoryId: item.itemMaster.subcategory_id,
        optionName: `${item.package_code} - ${item.location_name} - ${item.itemMaster.name}${phase ? ' - ' + phase.name : ''}`,
      };
    });

    return orderBy(itemOptions, 'optionName');
  }
);
