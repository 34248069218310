import React from 'react';
import PropTypes from 'prop-types';
import {Alert, Col, Row} from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash.get';
import { destroy } from 'redux-form';
import * as itemNames from '../../../constants/itemNames';
import * as dataNames from '../../../constants/dataNames';
import * as permissions from '../../../constants/permissions';
import {customPaymentMethods} from '../../../constants/salesSettings';
import { getOrderFulfillmentOptions } from '../../../constants/orderFulfillmentMethods';
import * as apiActions from '../../../actions/apiActions';
import { unsetItem, setItem } from '../../../actions/itemActions';
import { unsetData, setData } from '../../../actions/dataActions';
import { ensureRegister } from '../../../actions/registerActions';
import { getConsumer} from '../../../actions/consumerActions';
import { ensureSalesLocation } from '../../../actions/locationActions';
import { getPaymentModificationPaymentTypes, getOrderSettings } from '../../../selectors/orderSettingsSelectors';
import { userNeedsRegistersLocations } from '../../../selectors/usersSelectors';
import { getIntegrationState } from '../../../selectors/integration/integrationSelectors';
import { refundedInventoryItems, getOrdersStats, getDetailedCustomer } from '../../../selectors/customerSelectors';
import {
  addPrepackWeightsToRefundOrder,
  getTransactions,
  getOrdersForHistory,
  getOrdersWithRefundCollections
} from '../../../selectors/ordersSelectors';
import {
  canRefundAlleaves,
  getCurrentRegisterAlleavesRegisterId,
  isOnfleetIntegrator
} from '../../../selectors/integration/thirdPartyIntegrationSelectors';
import PageTitle from '../../common/PageTitle';
import CustomerProfile from './components/CustomerProfile';
import RefundAndRestock from './components/RefundAndRestock';
import RefundOrRestock from './components/RefundOrRestock';
import OrdersTable from './components/OrdersTable';
import InProgressOverlay from '../../common/InProgressOverlay';
import ModalWrapper from '../../common/ModalWrapper';
import StartOrderFormWrapper from '../../customers/checkin/StartOrderFormWrapper';
import { getImageUrl, getImgByGender } from '../../../util/images';
import { getNonMedicatedCategoryId } from '../../../selectors/categorySelectors';
import { convertFromBase } from '../../../util/uomHelpers';
import {patientSettingsGoesByEnabled} from '../../../selectors/complianceSettingsSelectors';
import {isFeatureEnabled} from '../../../selectors/featureToggles';
import {getTotalResults} from '../../../selectors/paginationSelectors';
import {addMessage} from '../../../actions/systemActions';

class CustomerPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.ref = React.createRef();
    this.state = {
      loadingMessage: 'Loading',

      translate: false,
      isActive: false,
      expandedOrders: [],

      refundQueue: [],
      refundOrder: false,
      restockQueue: [],

      page: 1,
      first: 1,
      last: 0,
      perPage: 5,
      numRecords: 0,

      customerId: false,

      itemMasters: [],
      detailedOrders: [],
      showStartOrderModal: false,
      fastTrack: false,
      orderCustomerId: null,
      showRefundRestockModal: false,
      showOnfleetDataRequiredModal: false,
      onfleetErrorMessage: null,
      orderFulfillmentOptions: getOrderFulfillmentOptions(),
      isRefundAndRestock: false
    };

    this.onRestockAllClick = this.onRestockAllClick.bind(this);
    this.onRefundAllClick = this.onRefundAllClick.bind(this);
    this.onRefundToggle = this.onRefundToggle.bind(this);
    this.onToggleRefundAndRestock = this.onToggleRefundAndRestock.bind(this);
    this.onRestockToggle = this.onRestockToggle.bind(this);
    this.toggleQueue = this.toggleQueue.bind(this);
    this.onToggleBoth = this.onToggleBoth.bind(this);
    this.onExpandOrder = this.onExpandOrder.bind(this);
    this.onApplyRefund = this.onApplyRefund.bind(this);
    this.setIsRefundAndRestock = this.setIsRefundAndRestock.bind(this);

    this.isExpanded = this.isExpanded.bind(this);

    this.getDetailedOrder = this.getDetailedOrder.bind(this);

    this.onOpenProfile = this.onOpenProfile.bind(this);
    this.reloadOrders = this.reloadOrders.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.onCheckIn = this.onCheckIn.bind(this);
    this.goToQueue = this.goToQueue.bind(this);
    this.startOrder = this.startOrder.bind(this);
    this.openOrderModal = this.openOrderModal.bind(this);
    this.closeOrderModal = this.closeOrderModal.bind(this);
    this.fastTrack = this.fastTrack.bind(this);
    this.hideLoader = this.hideLoader.bind(this);
    this.afterOrdersLoaded = this.afterOrdersLoaded.bind(this);
    this.preventDefault = this.preventDefault.bind(this);

    this.removeProductFromRefundOrder = this.removeProductFromRefundOrder.bind(this);
    this.removeProductFromRestockOrder = this.removeProductFromRestockOrder.bind(this);
    this.removeProductFromRefundMetrcOrder = this.removeProductFromRefundMetrcOrder.bind(this);

    this.onRestockProduct = this.onRestockProduct.bind(this);

    this.onRemoveItemFromRestocking = this.onRemoveItemFromRestocking.bind(this);
    this.onApplyRestock = this.onApplyRestock.bind(this);
    this.onApplyRefundAndRestock = this.onApplyRefundAndRestock.bind(this);
    this.onApplyRefundAndRestockMetrc = this.onApplyRefundAndRestockMetrc.bind(this);
    this.applyRestockAsPromises = this.applyRestockAsPromises.bind(this);
    this.onPaymentSubmit = this.onPaymentSubmit.bind(this);
    this.submitPaymentOrDiscountChange = this.submitPaymentOrDiscountChange.bind(this);
    this.getRefundOrderPaymentType = this.getRefundOrderPaymentType.bind(this);
    this.onMmapPaymentSubmit = this.onMmapPaymentSubmit.bind(this);
    this.addMmapDiscountsToRefundOrder = this.addMmapDiscountsToRefundOrder.bind(this);

    this.modifyOrder = this.modifyOrder.bind(this);
    this.closeOrderModal = this.closeOrderModal.bind(this);
    this.addTransactionInfoToOrder = this.addTransactionInfoToOrder.bind(this);
    this.closeRefundRestockModal = this.closeRefundRestockModal.bind(this);
    this.showRefundRestockModal = this.showRefundRestockModal.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.submitDate = this.submitDate.bind(this);
  }

  /*****************************************
   * Lifecycle
   */

  componentDidMount() {
    // eslint-disable-next-line
    this.setState({
      loadingMessage: 'Loading Customer Data',
      isActive: true,
      customerId: this.props.params.id
    });

    const doneLoading = () => {
      // eslint-disable-next-line
      this.setState({
        isActive: false
      });
    };

    this.props.actions.getUnpaginatedData(
      '/api/registers',
      dataNames.salesRegisters,
      { failed: 'registers.getRegisters.fail' },
      { type: 'sales' }
    );
    this.props.actions.unsetItem(itemNames.orderSettings);
    this.props.actions.unsetItem(itemNames.refundOrder);
    this.props.actions.unsetItem(itemNames.customer);
    this.props.actions.unsetData(dataNames.searchCustomer);
    this.props.actions.setItem({ value: false }, itemNames.refundOrderInitiated);
    this.props.actions.setItem({ value: false }, itemNames.restockOrderInitiated);
    this.props.actions.getItem('/api/order_settings', itemNames.orderSettings);
    this.props.actions.getUnpaginatedData('/api/categories', dataNames.categories);
    this.props.actions.getUnpaginatedData('/api/prepack_weights', dataNames.prepackWeights);
    this.loadGroups();
    this.loadCustomer().then(doneLoading).catch(doneLoading);

    // @TODO: Remove the double consumer dependency in this page - https://akerna.atlassian.net/browse/PTD-3
    // Get customer with orders and queues
    this.props.actions.getConsumer(this.props.params.id, dataNames.searchCustomer);

    this.props.actions.getUnpaginatedData(
      '/api/taxes',
      dataNames.taxes,
      {failed: 'taxes.get.failed'},
      undefined,
      undefined,
      {debounce: true}
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.refundOrder !== undefined && !this.state.refundOrder) {
      this.setState({ refundOrder: nextProps.refundOrder });
    }
  }

  componentWillUnmount() {
    this.props.actions.unsetData(dataNames.orders);
    this.props.actions.unsetItem(itemNames.refundOrder);
    this.props.orders.map((order) => {
      this.props.actions.destroy(`orderPayments${order.id}`);
    });
  }

  loadGroups() {
    const url = '/api/consumer_groups';
    return this.props.actions.getData(url, dataNames.customerGroups, { failed: I18n.t('customers.getGroups.failed') });
  }

  loadCustomer() {
    const url = `/api/customers/${this.props.params.id}`;
    return this.props.actions.getItem(url, itemNames.customer, { failed: I18n.t('customers.getCustomer.failed') }, {});
  }

  reloadOrders() {
    return new Promise((resolve) => {
      this.ref.current.wrappedInstance.ref.current.callExternalSearch();
      resolve();
    });
  }

  handleSearch(sort, query = '', per_page = 5, start) {
    const params = {
      select_columns: [
        'created_at',
        'effective_order_date',
        'fulfillment_status',
        'id',
        'name',
        'order_status',
        'order_status_date',
        'order_total',
        'order_type',
        'restock_transactions',
        'sales_associate',
        'tax_total',
      ],
      with: [
        'products.items',
        'products.refundedInventory',
        'products.refundedOrder.order',
        'refundingOrders.products.refundedInventory'
      ],
      search: query || undefined,
      consumer_id: this.props.params.id,
      has_products: 1,
      not_order_status: 'open',
      sort: sort.split(',').map(sort => sort.trim().replace(/\s/, ':')),
      per_page: 5,
      page: (start ? start / per_page : 0) + 1,
    };

    const path = `/api/orders/search`;
    return new Promise((resolve) => {
      this.props.actions
        .getPaginatedData(path, dataNames.orders, { failed: I18n.t('customers.history.messages.ordersFailed') }, params)
        .then((orders) => {
          const restockTransactions = orders.reduce((acc, order) => {
            return order.restock_transactions ? [...acc, ...order.restock_transactions] : acc;
          }, []);
          this.props.actions.setData(restockTransactions, dataNames.restockTransactions);
          resolve();
        });
    });
  }

  /********************************************
   * Refunds and Restocks
   */

  toggleQueue(queueString, row, event) {
    if (event !== undefined) event.target.blur();

    const state = this.state[queueString];

    let newQueue = state.filter((product) => {
      return product.id !== row.id;
    });

    if (newQueue.length === state.length) {
      newQueue = state.concat([row]);
    }

    return this.setState({ [queueString]: newQueue });
  }

  onToggleBoth(row, event) {
    this.toggleQueue('refundQueue', row, event);
    this.toggleQueue('restockQueue', row, event);
    return true;
  }

  onRestockToggle(row, event) {
    return this.toggleQueue('restockQueue', row, event);
  }

  onRestockAllClick(event, order, products) {
    event.stopPropagation();
    event.preventDefault();

    if (!products || !products.length) {
      return;
    }

    this.props.actions.setItem({ value: true }, itemNames.restockOrderInitiated);

    const newRestockQueue = [];

    // Queue all the products for restock
    products.forEach((product) => {
      if (!product.restocked) {
        const queuedProduct = Object.assign({}, product, { quantity: product.qty });
        newRestockQueue.push(queuedProduct);
        this.adjustDetailedOrder(product, product.qty);
      }
    });
    this.setState({ restockQueue: newRestockQueue });
  }

  onRestockProduct(event, product) {
    event.stopPropagation();
    event.preventDefault();
    const quantity = product.uom === 'EA' ? 1 : product.qty;
    const queueProduct = Object.assign({}, product, { quantity });
    const restockQueue = this.state.restockQueue.map((item) => item);
    restockQueue.push(queueProduct);

    this.props.actions.setItem({ value: true }, itemNames.restockOrderInitiated);

    this.adjustDetailedOrder(product, quantity);
    this.setState({ restockQueue });
  }

  onRemoveItemFromRestocking(event, product) {
    event.stopPropagation();
    event.preventDefault();

    const newRestockQueue = this.state.restockQueue
      .map((item) => item)
      .filter((item) => {
        return item.id !== product.id;
      });

    this.setState({ restockQueue: newRestockQueue });
  }

  addMmapDiscountsToRefundOrder() {
    // Check if there are any MMAP discounts on the order
    const detailedOrder = get(this.props, 'detailedOrder');
    if (!detailedOrder) {
      return;
    }
    const discounts = get(detailedOrder, 'coupons');
    if (!discounts) {
      return;
    }
    const mmapDiscounts = discounts.filter((discount) => discount.type === 'mmap');
    if (!mmapDiscounts || mmapDiscounts.length === 0) {
      return;
    }

    const amount = mmapDiscounts.reduce(
      (sum, mmapDiscount) => sum + parseFloat(mmapDiscount.amount),
      0,
    );

    if (amount === 0) {
      return;
    }

    const path = `/api/orders/${this.props.refundOrder.id}/discounts`;
    const messages = {};
    const discountPayload = {
      operation_type: 'refund',
      type: 'mmap',
      discount_amount: amount,
      coupon_applies_to: 'order'
    };
    this.props.actions.postItem(path, discountPayload, null, messages, {}, null);
  }

  onApplyRefundAndRestock(e, type = 'cash') {
    if (!this.props.actions.ensureSalesLocation()) {
      return;
    }
    const { refundOrder, currentRegister, currentRegisterAlleavesRegisterId } = this.props;
    const { restockQueue } = this.state;

    const payload = {};
    if (refundOrder && refundOrder.order_total < 0) {
      payload.refund = {
        consumer_id: this.state.customerId,
        order_id: refundOrder.id,
        amount: refundOrder.order_total,
        payment_type: type === 'alleaves'
          ? canRefundAlleaves ? type : 'cash'
          : this.getRefundOrderPaymentType(),
        operation_type: 'refund',
        register_id: currentRegister,
        alleaves_register_id: currentRegisterAlleavesRegisterId
      };
    }
    const order_id = this.props.detailedOrder.id;
    const restock = (restockQueue || []).reduce((acc, item) => {
      let product = acc.find((p) => p.order_product_inventory_id === item.id);
      if (!product) {
        product = {
          consumer_id: this.state.customerId,
          order_product_inventory_id: item.id,
          order_product_id: item.order_product_id,
          qty: 0,
          uom: item.uom,
          inventory_location_id: item.inventory_location_id
        };
        acc.push(product);
      }
      product.qty += item.quantity;
      return acc;
    }, []);
    if (restock.length) {
      payload.restock = restock.slice();
    }
    this.setState({ loadingMessage: I18n.t('customers.history.loadingMessages.restockingProducts'), isActive: true });

    this.props.actions
      .postData(`/api/orders/${order_id}/refund_restock`, payload)
      .then((refund_restock_order) => {
        this.addMmapDiscountsToRefundOrder();
      }) // See if we need to 'refund' MMAP discounts
      .then(this.reloadOrders)
      .then(() => {
        const { orders } = this.props;
        this.props.actions.unsetItem(itemNames.refundOrder);
        this.props.actions.setItem({ value: false }, itemNames.restockOrderInitiated);
        this.props.actions.setItem({ value: false }, itemNames.refundOrderInitiated);

        this.setState({
          isActive: false,
          isRefundAndRestock: false,
          restockQueue: [],
          detailedOrders: orders.map(this.addTransactionInfoToOrder)
        });
      })
      .catch(this.hideLoader);
  }

  onApplyRestock(event) {
    if (!this.props.actions.ensureSalesLocation()) {
      return;
    }

    const restockQueue = this.state.restockQueue.reduce((acc, product) => {
      // NOTE: id is actually order_product_inventory_id
      const existingProduct = acc.find((p) => p.id === product.id);
      if (existingProduct) {
        existingProduct.quantity += product.quantity;
      } else {
        acc.push(product);
      }
      return acc;
    }, []);

    this.setState({ loadingMessage: I18n.t('customers.history.loadingMessages.restockingProducts'), isActive: true });
    let  successfulRestocks = 0;

    const restockProduct = (product) =>
      new Promise((resolve, reject) => {
        const payload = {
          consumer_id: this.state.customerId,
          order_product_id: product.order_product_id,
          inventory_location_id: this.props.currentLocation, // checked for validity by ensureSalesLocation in this method
          is_refund_restock: this.state.isRefundAndRestock
        };
        if (product.items.length !== 0) {
          payload.qty = product.quantity;
          payload.uom = product.uom;
          payload.order_product_inventory_id = product.id;
        }
        // This needs some error trapping
        this.props.actions
          .postItem('/api/orders/restocks', payload, null, {}, {}, (data) => {
            if (data.failures.length === 0) {
              successfulRestocks++;
            }

            if (restockQueue.length > 0) {
              if (restockQueue.length === 1) {
                const detailedOrders = this.state.detailedOrders.map((order) => order);
                const currentOrder = detailedOrders.find((order) => order.id === product.order_id);
                const index = detailedOrders.indexOf(currentOrder);
                detailedOrders.splice(index, 1);
                this.setState({ detailedOrders: detailedOrders, isActive: false });
                this.reloadOrders().then(() => {
                  this.addDetailedOrder(currentOrder);
                  this.props.actions.setItem({ value: false }, itemNames.restockOrderInitiated);
                });
              }
              restockQueue.pop();
            }
            if (restockQueue.length === 0) {
              this.setState({ restockQueue: restockQueue });
            }
            return resolve();
          })
          .then(this.reloadOrders)
          .catch(this.hideLoader);
      });

    const iterator = restockQueue.map((product) => restockProduct(product));

    this.setState({
      loadingMessage: 'Restocking Product(s)',
      isActive: true,
      isRefundAndRestock: false,
    });

    Promise.all(iterator).then(this.reloadOrders).then(() => {
      const total_restocks = this.props.customer.total_restocks + successfulRestocks;
      this.props.actions.setItem({ ...this.props.customer, total_restocks }, itemNames.customer);
    });
  }

  adjustDetailedOrder(product, plusOrMinus) {
    const detailedOrders = this.state.detailedOrders.map((product) => product);

    const order = detailedOrders.find((order) => order.id === product.order_id);

    const foundProduct = order.products.reduce((acc, p) => {
      if (p.id === product.order_product_id) {
        if (product.items.length > 0) {
          const item = product.items.find((item) => {
            if (item.id === product.id) {
              return item;
            }
          });
          if (acc.length === 0) acc.push(item);
          return acc;
        }
        if (acc.length === 0) acc.push(product);
        return acc;
      }
      return acc;
    }, []);

    if (foundProduct.length > 0) {
      const adjustProduct = foundProduct.pop();

      if (adjustProduct.restockCount === undefined) adjustProduct.restockCount = 0;

      if (typeof plusOrMinus === 'string') {
        adjustProduct.restockCount =
          plusOrMinus === 'up' ? adjustProduct.restockCount + 1 : adjustProduct.restockCount - 1;
      } else {
        adjustProduct.restockCount = adjustProduct.restockCount + plusOrMinus; // in this case it's a number - this is support for all or nothing
      }

      this.setState({ detailedOrders: detailedOrders });
    }
  }

  ////////////////////////////////////////////
  // Refund Specific Methods
  // - should be a single transaction but BE not set up that way
  //

  /**
   * Check if a refund is possible.  This is done at the order level not at the product level
   * @param order
   * @param products
   * @returns {boolean}
   */
  isOrderRefundPossible(order, products) {
    if (!(products && products.length && order && order.id)) {
      return false;
    }

    if (order.fulfillment_status !== 'completed') {
      return false;
    }

    if (this.props.integrationState.isBiotrack) {
      const hasMedicatedRefunds = (this.props.ordersWithRefunds[`_${order.order_id}`] || []).some((o) =>
        (o.products || []).some((p) => {
          const refundedProduct = products.find((product) => product.id === p.refunded_order_product_id);
          return refundedProduct && refundedProduct.category_id !== this.props.nonMedicatedCategoryId;
        })
      );

      // For Biotrack, if an order has a medicated product refund, we do not allow more refund on that order.
      if (hasMedicatedRefunds) {
        return false;
      }
    }

    return true;
  }

  /**
   *
   * @param event - click
   * @param order - order from the OrderDetail component
   * @param products - products from the OrderDetail component
   * @returns {boolean}
   */
  onRefundAllClick(event, order, products) {
    event.stopPropagation();
    event.preventDefault();

    const orderId = products[0].order_id;

    // check
    if (!this.isOrderRefundPossible(order, products)) {
      return;
    }

    // Check if the order as any medicated restock
    const hasMedicatedRestocks = products.some((product) =>
      product.category_id !== this.props.nonMedicatedCategoryId &&
      product.items.some((item) => item.restock_transactions));

    const promises = [];

    // If the refund does not exist, we have to create it
    if (
      !this.props.refundOrder ||
      !Object.keys(this.props.refundOrder).length ||
      (this.props.refundOrder.payments && this.props.refundOrder.payments.length > 0)
    ) {
      const payload = {
        consumer_id: this.state.customerId,
        order_source: 'in_store',
        order_type: 'refund',
        refunded_order_id: orderId
      };

      const getOrder = () => {
        return new Promise((resolve, reject) => {
          this.props.actions
            .postItem('/api/orders', payload, itemNames.refundOrder, {})
            .then(() => {
              resolve();
            })
            .catch((error) => {
              reject();
            });
        });
      };
      promises.push(getOrder);
    }

    // Add the remaining qty for all the qualifying products in the order
    products.forEach((product) => {
      // For Biotrack, does not allow refunding a medical product within an order that has medical restock
      if (this.props.isBiotrack && hasMedicatedRestocks && product.category_id !== this.props.nonMedicatedCategoryId) {
        return;
      }

      if (product.uom !== 'EA' && !product.prepack_weight_id && product.refunded > 0) {
        return;
      }

      if ((product.qty === undefined && product.quantity === product.refunded) || !product.refundable) {
        return;
      }
      // is inventory item
      if (product.qty <= product.refunded || !product.refundable) {
        return;
      }

      promises.push(() => {
        return this.addRemainingQtyToRefundOrderAsAPromise(product);
      });
    });

    // execute Promise chain synchronously
    promises
      .reduce((current, next, promiseIndex) => {
        return current.then(() => next());
      }, Promise.resolve([]))
      .then(() => {});

    return true;
  }

  onToggleRefundAndRestock (event, product) {
    this.setIsRefundAndRestock().then(() => {
      this.onRefundToggle(event, product);
      this.onRestockProduct(event, product);
    });
  }

  onRefundToggle(event, product) {
    event.stopPropagation();
    event.preventDefault();

    if (!this.props.refundOrder || !Object.keys(this.props.refundOrder).length) return this.createRefundOrder(product);

    if (this.props.refundOrder.payments && this.props.refundOrder.payments.length > 0)
      return this.createRefundOrder(product);

    return this.addProductToRefundOrder(product);
  }

  onApplyRefundAndRestockMetrc(event, type) {
    if (!this.props.actions.ensureRegister()) {
      return;
    }

    const payment_type = type === 'alleaves'
      ? canRefundAlleaves ? type : 'cash'
      : this.getRefundOrderPaymentType();

    this.setState({ loadingMessage: I18n.t('customers.history.loadingMessages.finalizingRefund'), isActive: true });

    const messages = {};
    const path = `/api/orders/${this.props.refundOrder.id}/payments`;

    // Apply refund
    const refundPayload = {
      operation_type: 'refund',
      payment_type,
      amount: this.props.refundOrder.order_total,
      register_id: this.props.currentRegister,
      alleaves_register_id: this.props.currentRegisterAlleavesRegisterId
    };

    this.props.actions
      .postItem(path, refundPayload, null, messages)
      .then(() => {
        // Apply restock
        this.setState({
          loadingMessage: I18n.t('customers.history.loadingMessages.restockingProducts'),
          isActive: true
        });
        const restockPromises = this.applyRestockAsPromises();
        return (Promise.all(restockPromises));
      })
      .then(this.reloadOrders)
      .then(this.afterOrdersLoaded)
      .catch(this.hideLoader);
  }

  applyRestockAsPromises() {
    const restockQueue = this.state.restockQueue.reduce((acc, product) => {
      // NOTE: id is actually order_product_inventory_id
      const existingProduct = acc.find((p) => p.id === product.id);
      if (existingProduct) {
        existingProduct.quantity += product.quantity;
      } else {
        acc.push(product);
      }
      return acc;
    }, []);

    const restockProduct = (product) =>
      new Promise((resolve, reject) => {
        const payload = {
          consumer_id: this.state.customerId,
          order_product_id: product.order_product_id,
          inventory_location_id: this.props.currentLocation // checked for validity by ensureSalesLocation in this method
        };
        if (product.items.length !== 0) {
          payload.qty = product.quantity;
          payload.uom = product.uom;
          payload.order_product_inventory_id = product.id;
          payload.is_refund_restock = true;
        }

        // This needs some error trapping
        this.props.actions.postItem('/api/orders/restocks', payload, null, {}, {}, (data) => {
          if (restockQueue.length > 0) {
            if (restockQueue.length === 1) {
              const detailedOrders = this.state.detailedOrders.map((order) => order);
              const currentOrder = detailedOrders.find((order) => order.id === product.order_id);
              const index = detailedOrders.indexOf(currentOrder);
              detailedOrders.splice(index, 1);
              this.setState({ detailedOrders: detailedOrders, isActive: false });
              this.reloadOrders().then(() => {
                this.addDetailedOrder(currentOrder);
              });
            }
            restockQueue.pop();
          }
          if (restockQueue.length === 0) {
            this.setState({ restockQueue: restockQueue });
          }
          return resolve();
        });
      });
    return restockQueue.map((product) => restockProduct(product));
  }

  createRefundOrder(product) {
    if (Object.keys(this.props.refundOrder).length) {
      if (this.props.refundOrder.payments.length === 0) {
        return this.addProductToRefundOrder(product);
      }
    }

    this.setState({ loadingMessage: 'Creating Refund', isActive: true });

    const that = this;
    const messages = {}; // {failed: 'orders.create.failed', success: 'orders.create.success'};

    const payload = {
      consumer_id: this.state.customerId,
      order_source: 'in_store',
      order_type: 'refund',
      refunded_order_id: product.order_id
    };

    that.props.actions.postItem('/api/orders', payload, itemNames.refundOrder, messages, {}, (refundOrder) => {
      that.addProductToRefundOrder(product);
      this.props.actions.setItem({ value: true }, itemNames.refundOrderInitiated);
    });
  }

  addProductToRefundOrder(product) {
    this.setState({
      loadingMessage: I18n.t('customers.history.loadingMessages.addingProductToRefunds'),
      isActive: true
    });

    const { prepackWeights } = this.props;
    const messages = {}; // {failed: 'productAddFailure', success: 'productAddSuccess'};
    const path = `/api/orders/${this.props.refundOrder.id}/products`;

    const payload = {
      item_non_taxable: get(product, 'item_non_taxable', 0),
      item_master_id: product.product_item_master_id,
      quantity: 1
    };

    const refundedItem = Array.isArray(product.items) ? product.items.find((item) => item.id === product.id) : null;
    if (product.uom !== 'EA' && refundedItem) {
      payload.sold_weight = refundedItem.qty;
      payload.sold_weight_uom = refundedItem.uom;
    } else if (!this.props.newProductMasterIsEnabled) { // When the new Product Master is enabled we don't have Prepacks anymore. Just return EA items based on qty
      if (product.prepack_weight_id) {
        const weight = prepackWeights.find((weight) => weight.id === product.prepack_weight_id);
        payload.sold_weight = convertFromBase(weight.weight_base, weight.uom);
        payload.sold_weight_uom = product.sold_weight_uom;
      } else {
        payload.sold_weight = product.sold_weight;
        payload.sold_weight_uom = product.sold_weight_uom;
      }
    }

    if (product.items.length === 0) {
      // Is not an inventory item
      payload.refunded_order_product_id = product.id;
    } else {
      // Is an inventory item
      payload.refunded_order_product_inventory_id = product.id;
    }

    this.props.actions
      .postItem(path, payload, itemNames.refundOrder, messages, {}, () => {
        this.hideLoader();
      })
      .catch(() => {
        this.hideLoader();
      });
  }

  addRemainingQtyToRefundOrderAsAPromise(product) {
    return new Promise((resolve, reject) => {
      this.setState({
        loadingMessage: I18n.t('customers.history.loadingMessages.addingProductToRefunds'),
        isActive: true
      });

      const {prepackWeights} = this.props;
      const path = `/api/orders/${this.props.refundOrder.id}/products`;

      const payload = {
        item_non_taxable: get(product, 'item_non_taxable', 0),
        item_master_id: product.product_item_master_id,
        quantity: product.uom === 'EA' || product.prepack_weight_id ? product.qty - product.refunded : 1
      };

      const refundedItem = Array.isArray(product.items) ? product.items.find((item) => item.id === product.id) : null;

      if (product.uom !== 'EA' && refundedItem) {
        payload.sold_weight = refundedItem.qty;
        payload.sold_weight_uom = refundedItem.uom;
      } else if (!this.props.newProductMasterIsEnabled) { // When the new Product Master is enabled we don't have Prepacks anymore. Just return EA items based on qty
        if (product.prepack_weight_id) {
          const weight = prepackWeights.find((weight) => weight.id === product.prepack_weight_id);
          payload.sold_weight = weight.weight * payload.quantity;
          payload.sold_weight_uom = product.sold_weight_uom;
        } else {
          payload.sold_weight = product.sold_weight;
          payload.sold_weight_uom = product.sold_weight_uom;
        }
      }

      if (product.items.length === 0) {
        // Is not an inventory item
        payload.refunded_order_product_id = product.id;
      } else {
        // Is an inventory item
        payload.refunded_order_product_inventory_id = product.id;
      }

      this.props.actions
        .postItem(path, payload, itemNames.refundOrder, {})
        .then(() => {
          this.hideLoader();
          resolve();
        })
        .catch(() => {
          this.hideLoader();
          reject();
        });
    });
  }

  haveDetailedOrder(order) {
    const detailedOrder = this.state.detailedOrders.find((detailed) => detailed.id === order.id);
    return detailedOrder !== undefined;
  }

  addTransactionInfoToOrder(order) {
    const transactions = this.props.restockTransactions;
    return Object.assign({}, order, {
      products: order.products.map((p) => {
        return Object.assign({}, p, {
          items: p.items.map((item) => {
            if (!item.item_transaction_id) return item;
            const restockTransactions = transactions.filter((t) =>
              (t.reference_id === item.item_transaction_id && t.item_id === item.item_id));
            if (restockTransactions) {
              return Object.assign({}, item, {restock_transactions: restockTransactions});
            }
            return item;
          })
        });
      })
    });
  }

  addDetailedOrder(order, forceReload = false) {
    if (this.haveDetailedOrder(order) && !forceReload) return true;

    this.setState({
      loadingMessage: I18n.t('customers.history.loadingMessages.gettingOrderDetails'),
      isActive: true
    });

    const doneLoading = () => {
      this.setState({ isActive: false });
    };

    const detailedOrders = this.state.detailedOrders.concat([]);
    const url = `/api/orders/details/${order.id}`;
    this.props.actions
      .getItem(url, itemNames.order, { failed: 'Failed' }, { detailed: true }, (order) => {
        const orderWithRestocks = this.addTransactionInfoToOrder(order);
        // If the detailed order already exists then replace otherwise push
        const index = detailedOrders.findIndex((order) => order.id === orderWithRestocks.id);
        if (index === -1) {
          detailedOrders.push(orderWithRestocks);
        } else {
          detailedOrders[index] = orderWithRestocks;
        }
        this.setState({ detailedOrders, isActive: false });
      })
      .finally(doneLoading);
  }

  getDetailedOrder(order) {
    const detailedOrder = this.state.detailedOrders.find((detailed) => detailed.id === order.id);
    return detailedOrder ? detailedOrder : false;
  }

  removeProductFromRefundOrder(product) {
    const refundOrderId = this.props.refundOrder.id;

    this.setState({
      loadingMessage: I18n.t('customers.history.loadingMessages.removingProductFromRefunds'),
      isActive: true
    });

    const messages = {}; // {failed: 'productAddFailure', success: 'productAddSuccess'};
    const path = `/api/orders/${this.props.refundOrder.id}/products/${product.id}`;

    this.props.actions.deleteItem(path, itemNames.refundOrder, messages, {}, (data) => {
      if (data.products.length > 0) {
        this.setState({
          loadingMessage: I18n.t('customers.history.loadingMessages.updatingRefundDetails'),
          isActive: true
        });
        this.props.actions.getItem(
          `/api/orders/details/${data.id}`,
          itemNames.refundOrder,
          messages,
          { detailed: true },
          (refundOrder) => {
            this.setState({ isActive: false });
          }
        );
      } else {
        this.setState({ isActive: false, isRefundAndRestock: false });
        this.props.actions.postItem(`/api/orders/${refundOrderId}/cancel_order`, {}, null).then(() => {
          this.props.actions.setItem({value : false}, itemNames.refundOrderInitiated);
        });
      }
    });
  }

  removeProductFromRefundMetrcOrder(refundedProduct) {
    this.removeProductFromRefundOrder(refundedProduct);
    this.removeProductFromRestockOrder(refundedProduct);
  }

  removeProductFromRestockOrder(refundedProduct) {
    const productIdToRestock = refundedProduct.refunded_inventory.id;
    const newRestockQueue = this.state.restockQueue
      .map((item) => item)
      .filter((item) => {
        return item.id !== productIdToRestock;
      });

    this.setState({ restockQueue: newRestockQueue });
  }

  // Applies the payment and if applicable the mmap discounts closing the refund
  onApplyRefund(e, type) {
    if (!this.props.actions.ensureRegister()) {
      return;
    }

    this.setState({ loadingMessage: I18n.t('customers.history.loadingMessages.finalizingRefund'), isActive: true });

    const messages = {};
    const path = `/api/orders/${this.props.refundOrder.id}/payments`;

    const refundPayload = {
      operation_type: 'refund',
      payment_type: type === 'alleaves'
        ? canRefundAlleaves ? type : 'cash'
        : this.getRefundOrderPaymentType(),
      amount: this.props.refundOrder.order_total,
      register_id: this.props.currentRegister,
      alleaves_register_id: this.props.currentRegisterAlleavesRegisterId
    };

    // if metrc integrated, and we are only refunding all, set the flag to trigger a metrc api call on the backend
    if (this.props.integrationState.isMetrc && !this.state.isRefundAndRestock) {
      refundPayload.metrc_refund_only = true;
    }

    let refunded_order = {};
    this.props.actions
      .postItem(path, refundPayload, null, messages, {}, (data) => {
        const totals = {
          total_orders: this.props.customer.total_orders + 1,
          total_refunds: this.props.customer.total_refunds + 1,
          total_spent: parseFloat(this.props.customer.total_spent) + parseFloat(data.order_total)
        };
        this.props.actions.setItem({ ...this.props.customer, ...totals }, itemNames.customer);

        // See if we need to 'refund' MMAP discounts
        this.addMmapDiscountsToRefundOrder();
        refunded_order = data; //containsMmapPayment(data);
      })
      .then(this.reloadOrders)
      .then(this.afterOrdersLoaded)
      //.then((data) => this.redirectIfMmapRefund(refunded_order, data))
      .then(() => this.redirectIfMmapRefund(refunded_order))
      .catch(this.hideLoader);
  }

  afterOrdersLoaded(orders) {
    this.props.actions.unsetItem(itemNames.refundOrder);
    this.hideLoader();
    return Promise.resolve(orders);
  }

  // In case there is an MMAP refund redirect the user to the Refund Order (so that they can copy/paste the MMAP Reference and Patient ID
  redirectIfMmapRefund(refunded_order) {
    const payments = get(refunded_order, 'payments');
    if (payments.some((payment) => payment.payment_type === 'mmap' && payment.is_cancelled === 0)) {
      this.props.actions.push(`/order-history/customers/${this.props.customer.id}/${refunded_order.id}`);
      // TODO: Expand the row (I tried but no luck)
    }
  }

  /****************************************************
   * Misc Methods
   */

  preventDefault(event) {
    if (event === undefined) return true;
    event.stopPropagation();
    event.preventDefault();
    return true;
  }

  dismiss() {
    this.setState({ isActive: false });
  }

  onExpandOrder(order) {
    this.addDetailedOrder(order);
  }

  isExpanded(id) {
    return this.state.expandedOrders.find((o) => o.id === id);
  }

  /*** Profile methods - I would rather these live in Profile, but it seems that's not React-y ***/

  hideLoader() {
    this.setState({ isActive: false });
  }

  addToQueue() {
    return new Promise((resolve, reject) => {
      this.props.actions
        .postItem(
          '/api/queue/customers',
          { consumer_id: this.props.customer.id, active: this.props.customer.active },
          itemNames.queueCustomer,
          { failed: 'customers.addToQueue.failed' },
          {},
          (data) => {
            resolve(data);
          }
        )
        .catch(() => {
          reject();
        });
    });
  }

  onOpenProfile(event) {
    this.preventDefault(event);
    this.props.actions.push(`/patients/modify/${this.props.params.id}`);
  }

  onCheckIn(event) {
    this.preventDefault(event);

    if (!this.props.customer.active) return false;

    this.setState({ loadingMessage: I18n.t('customers.history.loadingMessages.checkingIn'), isActive: true });

    this.addToQueue()
      .then((data) => {
        this.hideLoader();
        this.props.actions.push('/patients');
      })
      .catch(() => {
        this.hideLoader();
      });
  }

  selectFavorites(id) {
    this.props.actions.getUnpaginatedData(`/api/orders/favorites/${id}`, dataNames.customerFavorites);
  }

  submitPaymentOrDiscountChange(url, payload, customMessages = null) {
    const messages = customMessages
      ? customMessages
      : {
        failed: 'cultivation.orderHistory.actions.replacePayments.failed',
        success: 'cultivation.orderHistory.actions.replacePayments.success'
      };
    return new Promise((resolve, reject) => {
      this.props.actions
        .postItem(
          url,
          payload,
          undefined,
          messages,
          undefined,
          (data) => resolve(data)
        )
        .catch((data) => {
          reject(data);
        });
    });
  }

  onPaymentSubmit(formValues) {
    this.setState({
      loadingMessage: I18n.t('common.form.saving'),
      isActive: true
    });

    const oldLoadingMessage = this.state.loadingMessage;
    const doneLoading = () => {
      this.setState({
        loadingMessage: oldLoadingMessage,
        isActive: false
      });
    };
    const url = `/api/orders/${formValues.orderId}/replace_payments`;
    //filter out payment less than or equal to 0 to not have duplicate change for non refunded orders
    const payments = formValues.payments.filter((payment) => payment.order_type != 'refund' ? (payment.amount && payment.amount !== null && payment.amount >= 0) : payment);
    const payload = {
      refund_register_transactions: formValues.refund_register_transactions,
      payments
    };

    this.submitPaymentOrDiscountChange(url, payload)
      .finally(doneLoading);
  }

  onMmapPaymentSubmit(formValues) {
    this.setState({
      loadingMessage: I18n.t('common.form.saving'),
      isActive: true
    });

    const oldLoadingMessage = this.state.loadingMessage;
    const doneLoading = () => {
      this.setState({
        loadingMessage: oldLoadingMessage,
        isActive: false
      });
    };
    const url = `/api/orders/${formValues.orderId}/replace_mmap_payments`;
    const mmapPayments = formValues.mmapPayments.filter((mmapPayment) => mmapPayment.amount && mmapPayment.amount !== null);
    const payload = {
      mmap_payments: mmapPayments
    };

    const customMessages = {
      failed: 'cultivation.orderHistory.actions.replaceMmapPayments.failed',
      success: 'cultivation.orderHistory.actions.replaceMmapPayments.success'
    };

    this.submitPaymentOrDiscountChange(url, payload, customMessages)
      .then(this.reloadOrders)
      .then(this.afterOrdersLoaded)
      .then(() => {
        this.addDetailedOrder({id: formValues.orderId}, true);
      })
      .finally(doneLoading);
  }

  // This was copied from another CustomerPage component and should probably be refactored.
  goToQueue(consumer) {
    let path = '/queue';
    if (!consumer.order_source || consumer.order_source === 'in_store') {
      path += '/in_store';
    } else if (consumer.order_fulfilment_method === 'in_store') {
      path += '/pickup';
    } else {
      path += '/delivery';
    }
    this.props.actions.push(path);
  }

  // This was copied from another CustomerPage component and should probably be refactored.
  startOrder(customerId, formData = null, fastTrack = false) {
    if (!fastTrack || typeof fastTrack === 'function') {
      fastTrack = this.state.fastTrack;
    }

    if (this.props.isOnfleetIntegrator && formData.fulfillment_method === 'delivery') {
      this.props.actions.getItem(`/api/customers/${customerId}`, itemNames.consumer, {}, null, (data) => {
        const phoneNumbers = data.phone_numbers.filter((phone) => phone.number !== '' && phone.number !== null);
        const addresses = data.addresses.filter(
          (address) => address.street_address_1 !== '' && address.street_address_1 !== null
        );

        const state = {
          disabled: false,
          showStartOrderModal: false,
          showOnfleetDataRequiredModal: true
        };

        if (phoneNumbers && phoneNumbers.length === 0 && (addresses && addresses.length === 0)) {
          state.onfleetErrorMessage = I18n.t('customers.table.onfleetDataRequiredBody');
          this.setState(state);
        } else if (addresses && addresses.length === 0) {
          state.onfleetErrorMessage = I18n.t('customers.table.onfleetAddressRequiredBody');
          this.setState(state);
        } else if (phoneNumbers && phoneNumbers.length === 0) {
          state.onfleetErrorMessage = I18n.t('customers.table.onfleetPhoneRequiredBody');
          this.setState(state);
        } else {
          this.startOrderRedirect(formData, fastTrack);
        }
      });
    } else {
      this.startOrderRedirect(formData, fastTrack);
    }
  }

  startOrderRedirect(formData, fastTrack) {
    if (!this.props.registersLocationsRequired) {
      this.props.actions.postItem(
        '/api/orders',
        formData,
        itemNames.order,
        { failed: 'orders.create.failed', success: 'orders.create.success' },
        {},
        (order) => (fastTrack ? this.props.actions.push('/cart') : this.props.actions.push(`/product-menu/${order.id}`))
      );
    } else {
      this.props.actions.push(`/queue/${formData.fulfillment_method_value}`);
    }
  }

  // This was copied from another CustomerPage component and should probably be refactored.
  openOrderModal(consumer, fastTrack = false) {
    if (consumer.queue_id) {
      this.setState({ fastTrack });
      const order = this.state.orderFulfillmentOptions.find((option) => option.value === 'in_store');
      this.startOrder(
        consumer.id,
        {
          consumer_id: consumer.id,
          fulfillment_method_value: order.value,
          fulfillment_method: order.fulfillment_method || null,
          order_source: order.set_order_source || null,
          is_submitted: order.is_submitted || 0
        },
        fastTrack
      );
    } else {
      this.setState({
        orderCustomerId: consumer.id,
        showStartOrderModal: true,
        fastTrack
      });
    }
  }

  // This was copied from another CustomerPage component and should probably be refactored.
  fastTrack(consumer) {
    if (consumer.order_id) {
      // this.selectCustomer(consumer, () => this.props.actions.push(`/cart`));
      this.props.actions.push(`/cart`);
    } else {
      this.openOrderModal(consumer, true);
    }
  }

  modifyOrder(consumer) {
    // this.selectCustomer(consumer, () => this.props.actions.push(`/product-menu/${consumer.order_id}`));
    this.props.actions.push(`/product-menu/${consumer.order_id}`);
  }

  closeOrderModal() {
    this.setState({ showStartOrderModal: false });
  }

  closeRefundRestockModal() {
    this.setState({ showRefundRestockModal: false });
  }

  showRefundRestockModal() {
    this.setState({ showRefundRestockModal: true });
  }

  setIsRefundAndRestock() {
    return new Promise(resolve => {
      this.setState({
        isRefundAndRestock: true,
      }, () => {
        resolve();
      });
    });
  }

  getRefundOrderPaymentType () {
    const {orders, refundOrder, detailedOrder} = this.props;

    if (detailedOrder && detailedOrder.payments) {
      return detailedOrder.payments[detailedOrder.payments.length - 1].payment_type;
    }

    if (refundOrder) {
      const order = orders.find(order => order.id === refundOrder.refunded_order_id);

      if (order && order.payments) {
        for (const key in order.payments) {
          const payment_type = order.payments[key].payment_type;

          if (~customPaymentMethods.indexOf(payment_type)) {
            return payment_type;
          }
        }
      }
    }

    return 'cash';
  }

  submitDate(url, payload, customMessages = null) {
    const messages = customMessages
      ? customMessages
      : {
        failed: 'cultivation.orderHistory.actions.changeDate.failed',
        success: 'cultivation.orderHistory.actions.changeDate.success'
      };
    return new Promise((resolve, reject) => {
      this.props.actions
        .postItem(
          url,
          payload,
          undefined,
          messages,
          undefined,
          (data) => resolve(data)
        )
        .catch((data) => {
          reject(data);
        });
    });
  }

  onDateChange(formValues) {
    this.setState({
      loadingMessage: I18n.t('common.form.saving'),
      isActive: true
    });

    const oldLoadingMessage = this.state.loadingMessage;
    const doneLoading = () => {
      this.setState({
        loadingMessage: oldLoadingMessage,
        isActive: false
      });
    };

    const url = `/api/orders/${formValues.orderId}/change_date`;
    //if they do not change the date and save anyhow it just goes through as the former completed date
    const payload = {
      new_status_date: formValues.new_status_date ? formValues.new_status_date  : formValues.completed_date
    };

    this.submitDate(url, payload)
      .finally(doneLoading);
  }

  render() {
    const initialValues = {
      rewardsPoints: '123.00'
    };
    const { integrationState, nonMedicatedCategoryId, facility, checkInRequired, patientGoesByEnabled, isRefundAndRestockUnificationToggled, canRefundAlleaves } = this.props;
    const { showOnfleetDataRequiredModal, isRefundAndRestock } = this.state;
    const { isMetrc } = integrationState;
    return (
      <div className='order-history'>
        <InProgressOverlay
          message={this.state.loadingMessage}
          isActive={this.state.isActive}
          translate={this.state.translate}
        />
        <Row>
          <Col xs={12} sm={6} md={8} lg={9} className='orders-tables'>
            <PageTitle primaryText={I18n.t('cultivation.orderHistory.title')} secondaryText='' />
            <Alert variant='warning'>
              {I18n.t('customers.history.orderHistoryWarning')}
            </Alert>
            <ModalWrapper
              showModal={showOnfleetDataRequiredModal}
              dialogClassName='onfleet-phone-required'
              title='customers.table.onfleetPhoneRequiredTitle'
              onHide={() => {}}
              okayButton={{
                show: true,
                text: I18n.t('general.okay'),
                variant: 'default',
                onClick: () => {
                  this.setState({ showOnfleetDataRequiredModal: false });
                }
              }}
              version={2}
            >
              <p>{this.state.onfleetErrorMessage}</p>
            </ModalWrapper>
            <ModalWrapper
              Component={StartOrderFormWrapper}
              onHide={this.closeOrderModal}
              customerId={this.state.orderCustomerId}
              onSubmit={(formData) => this.startOrder(this.state.orderCustomerId, formData)}
              title='customers.table.startOrder'
              showModal={this.state.showStartOrderModal}
              dialogClassName='customer-order-modal'
            />
            <ModalWrapper
              Component={false}
              title='customers.history.refundRestock.modalTitle'
              headerClass='bg-info-dark'
              onHide={this.closeRefundRestockModal}
              showModal={this.state.showRefundRestockModal}
              okayButton={{
                show: true,
                onClick: this.onApplyRefundAndRestock,
                text: 'customers.history.refundRestock.okayText'
              }}
              cancelButton={{
                show: true,
                onClick: this.closeRefundRestockModal,
                text: 'customers.history.refundRestock.cancelText'
              }}
              dialogClassName='modal-sm'
              version={2}
            >
              <div>{I18n.t('customers.history.refundRestock.bodyText')}</div>
            </ModalWrapper>
            {/*<OrderHistoryTable data={data}/>*/}
            <OrdersTable
              ref={this.ref}
              handleSearch={this.handleSearch}
              refundOrderInitiated={this.props.refundOrderInitiated}
              restockOrderInitiated={this.props.restockOrderInitiated}
              prepackWeights={this.props.prepackWeights}
              datax={this.props.order}
              orderx={this.props.order}
              data={this.props.orders}
              taxes={this.props.taxes}
              ordersWithRefunds={this.props.ordersWithRefunds}
              onExpandOrder={this.onExpandOrder}
              isExpanded={this.isExpanded}
              refundQueuex={this.state.refundQueue}
              refundedOrderIds={this.props.refundedOrderIds}
              refundQueue={this.props.refundOrder}
              restockQueue={this.state.restockQueue}
              onRestockProduct={this.onRestockProduct}
              onRefundClick={isMetrc ? this.onToggleRefundAndRestock : this.onRefundToggle}
              onRestockClick={this.onRestockToggle}
              onRefundAllClick={this.onRefundAllClick}
              onRestockAllClick={this.onRestockAllClick}
              expandedOrders={this.state.expandedOrders}
              getDetailedOrder={this.getDetailedOrder}
              refundOrder={this.props.refundOrder}
              first={this.state.first}
              last={this.state.last}
              page={this.state.page}
              numRecords={this.state.numRecords}
              dataTotalSize={this.props.dataTotalSize}
              searchOrderName={this.props.searchOrderName}
              registers={this.props.registers}
              getPaymentModificationPaymentTypes={this.props.getPaymentModificationPaymentTypes}
              onPaymentSubmit={this.onPaymentSubmit}
              onMmapPaymentSubmit={this.onMmapPaymentSubmit}
              refundedInventoryItems={this.props.refundedInventoryItems}
              integrationState={integrationState}
              nonMedicatedCategoryId={nonMedicatedCategoryId}
              setIsRefundAndRestock={this.setIsRefundAndRestock}
              customer={this.props.customer}
              addMessage={this.props.actions.addMessage}
              onDateChange={this.onDateChange}
            />
          </Col>
          <Col xs={12} sm={6} md={4} lg={3}>
            <CustomerProfile
              profileData={this.props.customer}
              searchCustomer={this.props.searchCustomer}
              orderStats={this.props.orderStats}
              onCheckIn={this.onCheckIn}
              onOpenProfile={this.onOpenProfile}
              onFastTrack={this.fastTrack}
              goToQueue={this.goToQueue}
              modifyOrder={this.modifyOrder}
              initialValue={initialValues}
              form='patient-info'
              onStartOrder={this.openOrderModal}
              refundedProductIds={this.props.refundedOrderIds}
              groups={this.props.customerGroups}
              integrationState={integrationState}
              facility={facility}
              checkInRequired={checkInRequired}
              patientGoesByEnabled={patientGoesByEnabled}
            />
            {integrationState.isBiotrack || isRefundAndRestockUnificationToggled ? (
              <RefundAndRestock
                refundOrderInitiated={this.props.refundOrderInitiated}
                restockOrderInitiated={this.props.restockOrderInitiated}
                onQueueClick={this.onRefundToggle}
                onApplyRefund={this.onApplyRefund}
                onDeleteRefund={isMetrc ? this.removeProductFromRefundMetrcOrder : this.removeProductFromRefundOrder}
                onApplyRestock={integrationState.isBiotrack ? this.showRefundRestockModal : this.onApplyRestock}
                onRemoveItemFromRestocking={this.onRemoveItemFromRestocking}
                integrationState={integrationState}
                queue={this.state.restockQueue}
                refundOrder={this.props.refundOrder}
                isRefundAndRestockUnificationToggled={isRefundAndRestockUnificationToggled}
                canRefundAlleaves={canRefundAlleaves}
              />
            ) : (
              <React.Fragment>
                <RefundOrRestock
                  refundOrderInitiated={this.props.refundOrderInitiated}
                  restockOrderInitiated={this.props.restockOrderInitiated}
                  isRefundContainer={true}
                  isRefundAndRestock={isRefundAndRestock}
                  queue={this.state.refundQueue}
                  queueProperty='products'
                  onQueueClick={this.onRefundToggle}
                  onApplyRefund={isMetrc && isRefundAndRestock ? this.onApplyRefundAndRestockMetrc : this.onApplyRefund}
                  onDeleteRefund={isMetrc ? this.removeProductFromRefundMetrcOrder : this.removeProductFromRefundOrder}
                  refundOrder={this.props.refundOrder}
                  integrationState={integrationState}
                  title='Refund Products'
                  buttonText='Do Refund'
                  canRefundAlleaves={canRefundAlleaves}
                />
                <RefundOrRestock
                  refundOrderInitiated={this.props.refundOrderInitiated}
                  restockOrderInitiated={this.props.restockOrderInitiated}
                  isRefundContainer={false}
                  refundOrder={this.props.refundOrder}
                  queue={this.state.restockQueue}
                  onQueueClick={this.onRestockToggle}
                  onApplyRestock={this.onApplyRestock}
                  onApplyRefund={this.onApplyRefund}
                  onRemoveItemFromRestocking={this.onRemoveItemFromRestocking}
                  integrationState={integrationState}
                  title='Re-Stock Products'
                  buttonText='Do Re-Stock'
                  canRefundAlleaves={canRefundAlleaves}
                />
              </React.Fragment>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

CustomerPage.propTypes = {
  prepackWeights: PropTypes.array,
  ordersWithRefunds: PropTypes.object.isRequired,
  orderStats: PropTypes.object,
  order: PropTypes.array,
  taxes: PropTypes.array,
  refundOrder: PropTypes.object,
  orders: PropTypes.array,
  refundedOrderIds: PropTypes.array,
  customerGroups: PropTypes.array,
  params: PropTypes.object,
  actions: PropTypes.object,
  customer: PropTypes.object.isRequired,
  currentLocation: PropTypes.number,
  currentRegister: PropTypes.number,
  searchOrderName: PropTypes.string.isRequired,
  registers: PropTypes.array.isRequired,
  getPaymentModificationPaymentTypes: PropTypes.func.isRequired,
  searchCustomer: PropTypes.object.isRequired,
  integrationState: PropTypes.object.isRequired,
  nonMedicatedCategoryId: PropTypes.number,
  refundOrderInitiated: PropTypes.bool,
  restockOrderInitiated: PropTypes.bool,
  checkInRequired: PropTypes.bool,
  patientGoesByEnabled: PropTypes.bool,
  isRefundAndRestockUnificationToggled: PropTypes.bool.isRequired,
  addMessage: PropTypes.func.isRequired,
  canRefundAlleaves: PropTypes.bool.isRequired,
  currentRegisterAlleavesRegisterId: PropTypes.string,
  newProductMasterIsEnabled: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
  const normalizeCustomer = (customer) => {
    return Object.assign({}, customer, {
      image_file: getImageUrl(customer && customer.image_file, '100x100', getImgByGender(customer.gender))
    });
  };

  const {
    customer,
    customerGroups,
    order,
    refundOrder,
    prepackWeights,
    salesRegisters,
    user: { currentLocation, currentRegister },
    taxes
  } = state;

  const altWithRefundsSelector = getOrdersWithRefundCollections(state);
  const normalizedOrdersSelector = getOrdersForHistory(state);
  const normalizedCustomer = normalizeCustomer(customer);
  const searchOrder = normalizedOrdersSelector.find((order) => +order.id === +ownProps.params.order_id);
  const searchCustomer = getDetailedCustomer(state);

  const refundOrderInitiated = state[itemNames.refundOrderInitiated] ? state[itemNames.refundOrderInitiated].value : false;
  const restockOrderInitiated = state[itemNames.restockOrderInitiated] ? state[itemNames.restockOrderInitiated].value : false;
  const settings = getOrderSettings(state);

  return {
    prepackWeights,
    taxes: taxes || [],
    customer: normalizedCustomer,
    customerGroups,
    orderStats: getOrdersStats(state),
    orders: normalizedOrdersSelector,
    dataTotalSize: getTotalResults(state, { name: dataNames.orders }),
    ordersWithRefunds: altWithRefundsSelector,
    detailedOrder: order,
    refundOrder: addPrepackWeightsToRefundOrder(refundOrder, prepackWeights),
    refundedOrderIds: [],
    currentRegister,
    currentLocation,
    searchOrderName: searchOrder ? searchOrder.name : '',
    registers: salesRegisters,
    getPaymentModificationPaymentTypes: (order) => getPaymentModificationPaymentTypes(state, order),
    searchCustomer: (searchCustomer && searchCustomer[0]) || {},
    registersLocationsRequired: userNeedsRegistersLocations(state, { permissions: [permissions.view_registers] }),
    refundedInventoryItems: refundedInventoryItems(state),
    restockTransactions: getTransactions(state),
    integrationState: getIntegrationState(state),
    nonMedicatedCategoryId: getNonMedicatedCategoryId(state),
    isOnfleetIntegrator: isOnfleetIntegrator(state),
    facility: state[itemNames.facility],
    refundOrderInitiated: refundOrderInitiated,
    restockOrderInitiated: restockOrderInitiated,
    checkInRequired: get(settings, 'order_requires_checkin.value'),
    patientGoesByEnabled: patientSettingsGoesByEnabled(state),
    isRefundAndRestockUnificationToggled: isFeatureEnabled(state)('feature_refund_and_restock_unification'),
    canRefundAlleaves: isFeatureEnabled(state)('feature_alleaves_payment_integration') && canRefundAlleaves(state),
    currentRegisterAlleavesRegisterId: getCurrentRegisterAlleavesRegisterId(state),
    newProductMasterIsEnabled: isFeatureEnabled(state)('feature_new_product_master'),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign(
    {},
    { setItem, unsetItem, ensureRegister, push, unsetData, ensureSalesLocation, setData, destroy, getConsumer, addMessage },
    apiActions
  );
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerPage);
