import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {push} from 'react-router-redux';
import * as apiActions from '../../../actions/apiActions';
import * as itemNames from '../../../constants/itemNames';
import {setData} from '../../../actions/dataActions';
import {unsetItem} from '../../../actions/itemActions';
import FormWrapper from '../../common/form/FormWrapper';
import MinimumDaysSupplyForm from './components/MinimumDaysSupplyForm';
import {getMinimumDaysSupply} from '../../../selectors/ohMetrcCategoriesSelectors.js';
import * as ohMetrcCategoriesActions from '../../../actions/forms/ohMetrcCategoriesActions';

class MinimumDaysSupplyPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount(){
    this.props.actions.loadMinimumDays();
  }

  componentWillUnmount(){
    this.props.actions.unsetItem(itemNames.minimumDaysSupply);
  }

  render() {
    const {actions, supply} = this.props;

    return (
      <FormWrapper
        title={I18n.t('retail.title') + ' ' + I18n.t('retail.minimumDaysSupply.title')}
        goBack={actions.redirect}
      >
        <MinimumDaysSupplyForm
          actions={actions}
          supply={supply}
          onSubmit={this.handleSubmit}
        />
      </FormWrapper>
    );
  }

  handleSubmit (formValues) {
    this.props.actions.save(formValues);
  }
}

MinimumDaysSupplyPage.propTypes = {
  supply: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    goBack: PropTypes.func,
    getItem: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    setData: PropTypes.func.isRequired
  }),
  label: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    supply: getMinimumDaysSupply(state),
  };
}

function mapDispatchToProps(dispatch) {
  const bindedActions = bindActionCreators({
    ...apiActions,
    setData,
    unsetItem
  }, dispatch);

  const actions = {
    ...bindedActions,

    redirect: () => {
      return dispatch(push('/setup'));
    },

    loadMinimumDays: () => {
      return dispatch(ohMetrcCategoriesActions.fetch());
    },

    save: (formValues) => {
      return dispatch(ohMetrcCategoriesActions.update(formValues))
        .then(actions.redirect);
    }
  };

  return {
    actions,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MinimumDaysSupplyPage);
