import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Row, Table, Card} from 'react-bootstrap';
import {getTranslationLabel} from '../../../../util/translationHelpers';
import ContentConcealer from '../../../common/concealers/ContentConcealer';
import DisplayQty from '../../../common/DisplayQty';

const getLabel = getTranslationLabel('batchHistory.model.');


const HarvestBatchWasteReportedDetails = ({model, delta, integrationState}) => {

  // Get delta for waste or waste_reported in that order
  // waste comes from BE.  In some cases yet to be figured out, the value is missing but is calculated on the FE
  // If waste come from BE - that is the definitive record.  The FE calc is a hacky solution
  const wasteDelta = delta.sort((a,b) => a.name.localeCompare(b.name))
    .find((d) => ['waste_reported'].indexOf(d.name) !== -1);

  const wasteBefore = wasteDelta ? wasteDelta.prev : undefined;
  const wasteAfter = wasteDelta ? wasteDelta.current : undefined;

  const waste_other_material_before = Number(model.total_other_material_waste || 0);
  const waste_other_material_after = waste_other_material_before + Number(model.data && model.data.waste_other_material || 0);
  return (
    <Card>
      <Row>
        <Col xs={6}>
          <h3>{I18n.t('batchHistory.model.harvestBatchUpdateDetails')}</h3>
          <Table striped className='plant-details'>
            <thead>
            <tr>
              <th>{I18n.t('plantHistory.field')}</th>
              <th>{I18n.t('plantHistory.valueAdjustment')}</th>
            </tr>
            </thead>
            <tbody>
            <ContentConcealer show={wasteBefore !== wasteAfter && wasteAfter}>
              <tr>
                <td>{getLabel('totalWasteReported', integrationState)}</td>
                <td>
                  <DisplayQty qty={wasteBefore} uom={wasteDelta.uom_display} displayUom={true} /> ->
                  <DisplayQty qty={wasteAfter} uom={wasteDelta.uom_display} displayUom={true} />
                </td>
              </tr>
            </ContentConcealer>
            <ContentConcealer show={!!waste_other_material_after && waste_other_material_after !== waste_other_material_before && integrationState.isWaLeaf}>
              <tr>
                <td>{getLabel('totalOtherMaterialWasteReported', integrationState)}</td>
                <td>{waste_other_material_before} {model.uom} -> {waste_other_material_after} {model.uom}</td>
              </tr>
            </ContentConcealer>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Card>
  );
};

HarvestBatchWasteReportedDetails.propTypes = {
  model: PropTypes.object.isRequired,
  delta: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
};

export default HarvestBatchWasteReportedDetails;
