import React from 'react';
import PropTypes from 'prop-types';
import {ModalBody, ButtonToolbar, Button} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import * as itemNames from '../../../constants/itemNames';
import * as dataNames from '../../../constants/dataNames';
import LotLineageNode from './LotLineageNode';
import InProgressOverlay from '../../common/InProgressOverlay';

class LotLineageComponent extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.toggle = this.toggle.bind(this);
    this.state = {
      loading: false,
      defaultExpanded: false,
    };
  }

  componentWillMount() {
    const {lotId, actions: {unsetItem, getItem, getDataByPost}} = this.props;
    this.setState({loading: true});
    unsetItem(itemNames.lotLineage);
    getItem(`/api/lots/${lotId}/lineage`, itemNames.lotLineage, {failed: 'lotLineage.get.failed'})
      .then(() => {
        const {itemMasterIds} = this.props;
        if (itemMasterIds.length) {
          return getDataByPost(
            '/api/item_masters/multiple',
            {ids: itemMasterIds},
            dataNames.itemMasters,
            {failed: 'ei.processingTypes.form.failed'}
          );
        }
      })
      .then(() => this.setState({loading: false}))
      .catch(() => this.setState({loading: false}));
  }

  toggle(defaultExpanded) {
    this.setState({defaultExpanded});
  }

  render() {
    const {lotLineage} = this.props;
    const {loading, defaultExpanded} = this.state;

    return (
      <ModalBody className='lot-lineage'>
        <InProgressOverlay isActive={loading} message='common.loading' translate={true}/>
        <ButtonToolbar>
          <Button variant='success' onClick={() => this.toggle(true)}>{I18n.t('lotLineage.expandAll')}</Button>
          <Button variant='primary' onClick={() => this.toggle(false)}>{I18n.t('lotLineage.collapseAll')}</Button>
        </ButtonToolbar>
        {!loading && lotLineage.id ? <LotLineageNode node={lotLineage} defaultExpanded={defaultExpanded} /> : null}
      </ModalBody>
    );
  }
}

LotLineageComponent.propTypes = {
  actions: PropTypes.shape({
    unsetItem: PropTypes.func.isRequired,
    getItem: PropTypes.func,
    getDataByPost: PropTypes.func,
  }).isRequired,
  lotLineage: PropTypes.object.isRequired,
  itemMasterIds: PropTypes.array.isRequired,
  lotId: PropTypes.number,
};

export default LotLineageComponent;
