import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import ReactSelectInput from '../../../../common/form/ReactSelectInput';

const PrinterForm = (props) => {
  const {fields: {map}} = props;
  const options = [
    {text: 'Public', value: 1},
    {text: 'Private', value: 0},
  ];
  const resolutionOptions = [
    {text: '203', value: 203},
    {text: '300', value: 300},
    {text: '600', value: 600},
  ];
  return (
    <tbody>
      {map((printer, index) => {
        const p = props.printers && Array.isArray(props.printers) && props.printers[index] ? props.printers[index] : {};
        return (
          <tr key={index} className={props.isLocalPrintServer && !p.present ? 'text-danger' : ''}>
            <td style={{width: '50%'}}>{p.name}</td>
            <td style={{width: '10%'}} className='form-group-no-margin'>
              <Field
                name={`${printer}.status`}
                component={ReactSelectInput}
                clearable={false}
                props={{
                  options
                }}
              />
            </td>
            <td style={{width: '10%'}} className='form-group-no-margin'>
              <Field
                name={`${printer}.resolution`}
                component={ReactSelectInput}
                clearable={false}
                props={{
                  options: resolutionOptions
                }}
              />
            </td>
            <td style={{width: '10%'}}>
              {
                !props.isLocalPrintServer
                  ? 'NA'
                  : p.present
                    ? 'Yes'
                    : <a
                    href
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      e.target.blur();
                      props.fields.remove(index);
                    }}
                  >
                    No - Click To Remove
                  </a>
              }
            </td>
            {/*<td style={{width: '10%'}}>{p.in_flight}</td>*/}
            {/*<td style={{width: '10%'}}>{p.clients}</td>*/}
            {/*<td style={{width: '20%'}}>{p.last_processed}</td>*/}
          </tr>
        );
      })}
    </tbody>
  );
};

PrinterForm.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    length: PropTypes.number.isRequired
  }).isRequired,
  printers: PropTypes.array.isRequired,
  localPrinters: PropTypes.array.isRequired,
  isLocalPrintServer: PropTypes.bool.isRequired,
};

export default PrinterForm;
