import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Row, Col} from 'react-bootstrap';

import ReactSelectInput from '../../../common/form/ReactSelectInput';
import NumericInput from '../../../common/form/NumericInput';
import MultiSelectInput from '../../../common/form/MultiselectInput';
import InternationalCurrencyInput from '../../../common/form/InternationalCurrencyInput';

const countInReporting = [
  {text: 'Yes', value: 1},
  {text: 'No', value: 0}
];

const applyToBasePrice = [
  {text: 'Base Price', value: 'base'},
  {text: 'Group Price', value: 'group'},
];

const CouponLimitsCol = ({categories, customerGroups, products, productLists, vendors, integrationState, isProductListsFeatureEnabled}) => {
  return(
    <div>
      <h3>{I18n.t('retail.createCoupon.couponLimits')}</h3>
      <Row>
        <Col xs={12} sm={12} md={12}>
          <p><b>{I18n.t('retail.createCoupon.minOrderLimit')}</b></p>
        </Col>
        <Col xs={3} sm={3} md={3}>
          <InternationalCurrencyInput
            name='coupon_minimum_required'
            fractionPartSize = {2}
            props={{
              disabled: false
            }}
          />
        </Col>
        <Col xs={9} sm={9} md={9}>
          {I18n.t('retail.createCoupon.minOrderLimitText')}
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12}>
          <p><b>{I18n.t('retail.createCoupon.minItemPrice')}</b></p>
        </Col>
        <Col xs={3} sm={3} md={3}>
          <InternationalCurrencyInput
            name='min_line_item_price'
            component={NumericInput}
            fractionPartSize = {2}
            props={{
              disabled: false
            }}
          />
        </Col>
        <Col xs={9} sm={9} md={9}>
          {I18n.t('retail.createCoupon.minItemPriceText')}
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12}>
          <p><b>{I18n.t('retail.createCoupon.maxRedemptionsCoupon')}</b></p>
        </Col>
        <Col xs={3} sm={3} md={3}>
          <Field
            name='max_redemptions'
            component={NumericInput}
            fractionPartSize = {0}
          />
        </Col>
        <Col xs={9} sm={9} md={9}>
          {I18n.t('retail.createCoupon.maxRedemptionsText')}
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12}>
          <p><b>{I18n.t('retail.createCoupon.maxRedemptionsPatient')}</b></p>
        </Col>
        <Col xs={3} sm={3} md={3}>
          <Field
            name='max_redemptions_per_customer'
            component={NumericInput}
            fractionPartSize = {0}
          />
        </Col>
        <Col xs={9} sm={9} md={9}>
          {I18n.t('retail.createCoupon.maxRedemptionsText')}
        </Col>
      </Row>
      <Row>
        <Col xs={8} sm={8} md={8}>
          <Field
            name='consumer_group_ids'
            component={MultiSelectInput}
            props={{
              label: I18n.t('retail.createCoupon.selectedGroup'),
              options: customerGroups,
              valueKey: 'id',
              textKey: 'group_name'
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={8} sm={8} md={8}>
          <Field
            name='category_ids'
            component={MultiSelectInput}
            props={{
              label: I18n.t('retail.createCoupon.selectedCategories'),
              options: integrationState.isWaLeaf ? categories.filter((category) => category.category_code !== 'HARVESTS') : categories,
              valueKey: 'id',
              textKey: 'name'
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={8} sm={8} md={8}>
          <Field
            name='vendor_ids'
            component={MultiSelectInput}
            props={{
              label: I18n.t('retail.createCoupon.selectedVendor'),
              options: vendors,
              valueKey: 'id',
              textKey: 'name'
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={8} sm={8} md={8}>
          <Field
            name='item_master_ids'
            component={MultiSelectInput}
            props={{
              label: I18n.t('retail.createCoupon.selectedProduct'),
              options: products,
              valueKey: 'id',
              textKey: 'name'
            }}
          />
        </Col>
      </Row>
      {isProductListsFeatureEnabled &&
        <Row>
          <Col xs={8} sm={8} md={8}>
            <Field
              name='product_list_ids'
              component={MultiSelectInput}
              props={{
                label: I18n.t('retail.createCoupon.selectedProductList'),
                options: productLists,
                valueKey: 'id',
                textKey: 'name'
              }}
            />
          </Col>
        </Row>
      }
      <Row>
        <Col xs={8} sm={8} md={8}>
          <Field
            name='count_in_discount_percentage_reporting'
            component={ReactSelectInput}
            props={{
              label: I18n.t('retail.createCoupon.countInReporting'),
              options: countInReporting,
              valueKey: 'value',
              textKey: 'text'
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={8} sm={8} md={8}>
          <Field
            name='apply_to_pricing'
            component={ReactSelectInput}
            props={{
              label: I18n.t('retail.createCoupon.applyToBasePrice'),
              options: applyToBasePrice,
              valueKey: 'value',
              textKey: 'text'
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

CouponLimitsCol.propTypes = {
  categories: PropTypes.array.isRequired,
  customerGroups: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  productLists: PropTypes.array,
  vendors: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
  isProductListsFeatureEnabled: PropTypes.bool.isRequired
};

export default CouponLimitsCol;
