import React from 'react';
import PropTypes from 'prop-types';
import {change, Field, formValueSelector} from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { InputGroup } from 'react-bootstrap';
import {FaBarcode} from 'react-icons/fa';
import {groupedByItemMasterId} from '../../../selectors/inventoryItemsSelectors';
import * as formNames from '../../../constants/formNames';
import TextInput from '../../common/form/TextInput';

const formName = formNames.createTransfer;

class PackageScanInput extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.onScanCharacter = this.onScanCharacter.bind(this);
  }

  onScanCharacter(event) { // handleKeyPress
    const controlCodes = ['tab', 'enter'];
    if(controlCodes.indexOf(event.key.toLowerCase()) === -1) return true; // Not control, let onLocationScanChange handle it
    if(typeof this.props.packageCode !== 'string' || this.props.packageCode.trim() === '') return true; // No string, is legit control character

    event.preventDefault();
    event.stopPropagation();

    this.props.actions.change(formName, 'processScan', new Date()); // Triggers processing in middleware... date used just because its always new
  }

  render(){
    return (
      <InputGroup>
        <Field
          name='scannedPackageCode'
          component={TextInput}
          props={{
            onKeyDown: this.onScanCharacter,
            placeholder: 'Scan packages to complete transfer fields.'
          }}
        />
        <InputGroup.Append>
          <InputGroup.Text>
            <FaBarcode/>
          </InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
    );
  }

}

PackageScanInput.propTypes = {
  inventoryItemsByItemMasterId: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector(ownProps.formName);
  return {
    inventoryItemsByItemMasterId: groupedByItemMasterId(state),
    packageCode: selector(state, 'scannedPackageCode'),
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = {change};
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PackageScanInput);






