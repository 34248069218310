import React from 'react';
import PropTypes from 'prop-types';
import {reduxForm, Field} from 'redux-form';
import {Col, Row} from 'react-bootstrap';

import CheckBoxInput from '../../../common/form/CheckBoxInput';
import SubmitSection from '../../../common/form/SubmitSection';
import AddItem from '../../../common/form/AddItem';

export const PricingWeightsForm = ({handleSubmit, addGroup, pricingWeights}) => {
  return (
    <form>
      <Row>
        <Col md={2}>
          <table className='table table-bordered weights-table'>
            {pricingWeights.map((weight, index) => (
              <tr key={weight.id}>
                <td>{weight.size}</td>
                <td>{weight.uom}</td>
                <td className='td-checkbox'><Field name={`sizes[${index}].checked`} component={CheckBoxInput}/></td>
              </tr>
            ))}
          </table>
        </Col>
        <AddItem text='cultivation.pricingWeights.addGroup' addAction={addGroup} textMode='left' alignMode='right'/>
      </Row>
      <SubmitSection handleSubmit={handleSubmit} />
    </form>
  );
};

PricingWeightsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  addGroup: PropTypes.func.isRequired,
  pricingWeights: PropTypes.array.isRequired
};

export default reduxForm({form: 'pricingWeights'})(PricingWeightsForm);
