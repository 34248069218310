import {I18n} from 'react-redux-i18n';
import {requiredFieldValidation, minValidation} from '../common/form/redux-form/validations';

const locationChanged = (locationId, initialLocationId) => {
  return locationId === initialLocationId ? I18n.t('cultivation.finishedProduct.error.location') : undefined;
};

const qtyIsLower = (qty, initialQty) => {
  return qty <= initialQty ? undefined : I18n.t('cultivation.finishedProduct.error.quantity');
};

const validate = (values, props) => {
  return {
    inventory_location_id: requiredFieldValidation(values.inventory_location_id, 'cultivation.finishedProduct.form.storageLocation')
      || locationChanged(values.inventory_location_id, props.item && props.item.inventory_location_id),
    qty: requiredFieldValidation(values.qty, 'cultivation.finishedProduct.form.quantity')
      || qtyIsLower(values.qty, props.item && (props.item.qty - props.item.qty_reserved || 0))
      || minValidation(values.qty, 'cultivation.finishedProduct.form.quantity', 0, true),
  };
};

export const validateIngredientsAdjustFrom = (values) => {
  return {
    qty: requiredFieldValidation(values.qty, 'cultivation.finishedProduct.form.quantity'),
  };
};

export default validate;
