import * as types from '../constants/actionTypes';
import { maturePlantsRequireTracking, maturePlantsRequireTrackingErrorMessage } from '../selectors/plantsSelectors';
import { addMessage } from './systemActions';
import { getIntegrationState } from '../selectors/integration/integrationSelectors';
import { isColombiaFacility } from '../selectors/facility/getFacilityCountry';

export function setPackageInfo(packageInfo) {
  return { type: types.SET_PACKAGE_INFO, packageInfo };
}

export const dummy = () => {};

export function validatePackagePlants() {
  return (dispatch, getState) => {
    const state = getState();
    const { selectedPlants } = state;

    const selectedStrains = selectedPlants.reduce((strains, plant) => {
      strains.indexOf(plant.strain_id) < 0 && strains.push(plant.strain_id);
      return strains;
    }, []);

    if (selectedStrains.length !== 1) {
      dispatch(addMessage('error', 'plants.table.onlyOneStrain'));
      return false;
    }

    const isColombia = isColombiaFacility(state);
    if (isColombia) {
      const selectedCupos = selectedPlants.reduce((cupos, plant) => {
        cupos.indexOf(plant.cupo_id) < 0 && cupos.push(plant.cupo_id);
        return cupos;
      }, []);

      if (selectedCupos.length !== 1) {
        dispatch(addMessage('error', 'plants.table.onlyOneCupo'));
        return false;
      }

      const selectedModalities = selectedPlants.reduce((modalities, plant) => {
        modalities.indexOf(plant.modality) < 0 && modalities.push(plant.modality);
        return modalities;
      }, []);

      if (selectedModalities.length !== 1) {
        dispatch(addMessage('error', 'plants.table.onlyOneModality'));
        return false;
      }
    }

    const { isMetrc, isLeaf } = getIntegrationState(state);
    if (isLeaf || isMetrc) {
      const uniqueBatchName = [...new Set(selectedPlants.map((item) => item.batch_name))];
      if (uniqueBatchName.length > 1) {
        dispatch(addMessage('error', 'plants.table.onlyOneBatch'));
        return false;
      }
    }

    if (maturePlantsRequireTracking(getState())) {
      const somePlantsDontHaveTrackingIds = selectedPlants.some((plant) => {
        if (plant.stage_name !== 'Propagation') { //Propagation is not mature
          if ( !plant.state_integration_tracking_id ) return true;
          if ( plant.tag_requested ) return true;
        }
        return false;
      });

      if (somePlantsDontHaveTrackingIds) {
        dispatch(addMessage('error', maturePlantsRequireTrackingErrorMessage(getState())));
        return false;
      }
    }

    return true;
  };
}

export const changeItemMaster = ({ itemMasterId, itemMasterName, itemMasterDefaultUom, currentPackageName}) => {
  return {
    type: types.CHANGE_ITEM_MASTER,
    newId: itemMasterId,
    newName: itemMasterName,
    newUom: itemMasterDefaultUom,
    currentPackageName
  };
};
