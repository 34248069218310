import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Row, Col} from 'react-bootstrap';
import {Field} from 'redux-form';

import TextInput from '../../../common/form/TextInput';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import NumericInput from '../../../common/form/NumericInput';
import InternationalCurrencyInput from '../../../common/form/InternationalCurrencyInput';

const SuppliesFieldArray = (props) => {
  const {partners, storageLocations} = props;
  const uomOptions = [{text: 'EA', value: 1}, {text: 'G', value: 2}, {text: 'FL ', value: 3}, {text: 'OZ ', value: 4}];
  const categoryOptions = [{text: 'Uniforms', value: 1}, {text: 'Cleaning Supplies', value: 2}, {text: 'Medical Supplies', value: 3}];
  return (
    <div className='supply-wrapper'>
      <Row>
        <Col md={3}>
          <Field
            name='name'
            component={TextInput}
            props={{
              label: I18n.t('retail.supplies.create.name')
            }}
          />
        </Col>
        <Col md={3}>
          <Field
            name='category'
            component={ReactSelectInput}
            props={{
              label: I18n.t('retail.supplies.create.category'),
              defaultOption: I18n.t('common.placeholders.choose'),
              options: categoryOptions,
              valueKey: 'value',
              textKey: 'text'
            }}
          />
        </Col>
        <Col md={3} className='supplier'>
          <Field
            name='supplier'
            component={ReactSelectInput}
            props={{
              label: I18n.t('retail.supplies.create.supplier'),
              defaultOption: I18n.t('common.placeholders.choose'),
              options: partners,
              valueKey: 'id',
              textKey: 'name'
            }}
          />
          <span className='add-supplier-link'><a href='/partners/create'>{I18n.t('retail.supplies.create.addSupplier')}</a></span>
        </Col>
        <Col md={3}>
          <Field
            name='storageLocation'
            component={ReactSelectInput}
            props={{
              label: I18n.t('retail.supplies.create.storageLocation'),
              defaultOption: I18n.t('common.placeholders.choose'),
              options: storageLocations,
              valueKey: 'value',
              textKey: 'text'
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <Field
            name='containerSize'
            component={NumericInput}
            props={{
              label: I18n.t('retail.supplies.create.containerSize')
            }}
          />
        </Col>
        <Col md={2}>
          <Field
            name='uom'
            component={ReactSelectInput}
            props={{
              label: I18n.t('common.form.uom'),
              options: uomOptions,
              valueKey: 'value',
              textKey: 'text'
            }}
          />
        </Col>
        <Col md={2} className='transparent-addon-left-inner'>
          <InternationalCurrencyInput
            name='costPerUnit'
            props={{
              label: I18n.t('common.form.costPerUnit')
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

SuppliesFieldArray.propTypes = {
  partners: PropTypes.array.isRequired,
  storageLocations: PropTypes.array.isRequired,
  fields: PropTypes.shape({
    push: PropTypes.func.isRequired,
    map: PropTypes.func.isRequired,
    length: PropTypes.number.isRequired
  })
};

export default SuppliesFieldArray;
