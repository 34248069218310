import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Row, Col, Button } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import { FaMinus } from 'react-icons/fa';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import InternationalQuantityByUomInput from '../../common/form/InternationalQuantityByUomInput';
import {GR} from '../../../constants/uoms';

const getSelectedIngredient = (ingredientsList, itemMasterId) =>
  ingredientsList.find(ingredient => ingredient.id === itemMasterId);

const IngredientList = (props) => {
  const { fields: { map, remove }, ingredientsList, change, fields } = props;
  return (
    <div>
      {map((ingredient, index) => {
        const ingredientValues = fields.get(index);
        const selectedIngredient = getSelectedIngredient(ingredientsList, ingredientValues.item_master_id);
        const selectedIngredientUom = selectedIngredient ? selectedIngredient.default_uom : GR;

        return (
          <Row key={index}>
            <Col xs={6} md={4} lg={3}>
              <Field
                name={`${ingredient}.item_master_id`}
                component={ReactSelectInput}
                props={{
                  label: I18n.t('feedingSchedules.form.selectIngredient'),
                  options: ingredientsList,
                  textKey: 'name',
                  valueKey: 'id',
                  onChange: (value) => {
                    const newlySelectedIngredient = getSelectedIngredient(ingredientsList, value);
                    change(`${ingredient}.item_master_id`, value);
                    change(`${ingredient}.uom`, newlySelectedIngredient.default_uom);
                    if (!value) {
                      change(`${ingredient}.qty`, 0);
                    }
                  }
                }}
              />
            </Col>
            <Col md={2} lg={1}>
              <InternationalQuantityByUomInput
                name={`${ingredient}.qty`}
                label={I18n.t('feedingSchedules.form.qtyLiter')}
                uom={selectedIngredientUom}
              />
            </Col>
            <Col md={2} lg={1} className='removeCol'>
              <Button variant='primary' size='sm' onClick={() => {
                remove(index);
              }}>
                <FaMinus />
              </Button>
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

IngredientList.propTypes = {
  fields: PropTypes.object.isRequired,
  ingredientsList: PropTypes.array.isRequired,
  change: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
};

export default IngredientList;
