import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import {requiredFieldValidation, minValidation, maxValidation, productionRunValidation, lotNumberValidation} from '../../../common/form/redux-form/validations';
import * as UOMS from '../../../../constants/uoms';
import {convertToBase, convertWeightFromAndTo} from '../../../../util/uomHelpers';
import {isInvPackageTrackingNotInCompliance} from '../../../../selectors/complianceSettingsSelectors';

function validateOutputUniqueness(output, outputs, ...keys) {
  return outputs.filter(item => keys.every(key => item[key] === output[key])).length < 2;
}

function getNonUniqueMessage(isUnique, fieldName) {
  return isUnique ? undefined : (fieldName === 'item_master_id'  ? I18n.t('ei.processing.form.nonUnique') : ' ');
}

// ui/src/components/ei/processing/complete/validatePurchasedAmounts.js
const validate = (values, props) => {

  const isLabResultsFailed = (input) => {
    const lab_results_references = get(input, 'package.lab_results_reference', []);
    if (!lab_results_references.length) {
      return false;  // Treat no lab results as not failed!!!!
    }
    // Sort references so that most recent is first
    const sorted = lab_results_references.sort((lrr1, lrr2) => {
      return lrr1.id < lrr2.id ? 1 : -1;
    });

    const latest_lab_results = sorted[0].lab_results;
    return latest_lab_results.status === 'passed';
  };

  const {integrationState: {isBiotrack, isWaLeaf}} = props;
  const errors = {
    actual_completion_time: requiredFieldValidation(values.actual_completion_time, 'ei.processing.form.completionTime'),
    equipments: requiredFieldValidation(values.equipments.length > 0, 'ei.processing.form.equipment'),
    employees: requiredFieldValidation(values.employees.length > 0, 'ei.processing.form.employee'),
  };
  //Total output weight plus waste reported should not exceed the total input weight
  let inputWeightBase = (values.inputs || []).reduce((acc, input) => acc + input.uom ? convertToBase(input.qty || 0, input.uom) : 0, 0);
  const outputWeightBase = (values.outputs || []).reduce((acc, output) => acc + convertToBase(output.qty || 0, UOMS.GR), convertToBase(values.waste || 0, UOMS.GR));

  if (isBiotrack) {
    inputWeightBase = (values.inputs || []).reduce((acc, input) => acc += input.uom ? convertToBase(input.qty || 0, input.uom) : 0, 0);
  }

  const outputExceedsInputError = outputWeightBase > inputWeightBase ? I18n.t('ei.processing.form.outputExceedsInputError') : undefined;

  errors.inputs = values.inputs.map((input) => {
    const inputErrors = {
      item_id: requiredFieldValidation(input.item_id, 'ei.processing.form.productionRun'),
    };
    if (input.item_id) {
      inputErrors.qty = requiredFieldValidation(input.qty, 'ei.processing.form.weight')
        || minValidation(input.qty, 'ei.processing.form.weight', 0, true)
        || (!props.isAllowNegativeInventory && maxValidation(input.qty, 'ei.processing.form.weight', input.maxQty));
      // Only failed flower can be processed. Any other failed items cannot
      inputErrors.packageCode = props.isPaRemediationLabelsEnabled && input.item_category_code !== 'FLOWER' && isLabResultsFailed(input)
        ? I18n.t('ei.processing.form.failedTestResult')
        : null;
    }
    return inputErrors;
  });

  errors.outputs = values.outputs.map((output) => {
    const isUnique = validateOutputUniqueness(output,  values.outputs, 'item_master_id', 'storage_location_id', 'phase_id', 'inventory_type');
    return {
      item_master_id: requiredFieldValidation(output.item_master_id, 'ei.processing.form.newItem') || getNonUniqueMessage(isUnique, 'item_master_id'),
      qty: requiredFieldValidation(output.qty, 'ei.processing.form.newQty') || minValidation(output.qty, 'ei.processing.form.newQty', 0, true) || (isBiotrack && outputExceedsInputError),
      storage_location_id: requiredFieldValidation(output.storage_location_id, 'ei.processing.form.storageLocation') || getNonUniqueMessage(isUnique, 'storage_location_id'),
      integration_type: (isBiotrack || isWaLeaf) && requiredFieldValidation(output.integration_type, 'inventory.form.inventoryType'),
      phase_id: requiredFieldValidation(output.phase_id, 'ei.processing.form.newPhase') || getNonUniqueMessage(isUnique, 'phase_id'),
      name: productionRunValidation(output.name, 'ei.processing.form.productionRun'),
      lot_name: lotNumberValidation(output.lot_name, null, false),
      package_expires_at: output.tag_requested ? requiredFieldValidation(output.package_expires_at) : undefined
    };
  });

  const inputWeightInGrams = (values.inputs || []).reduce((acc, input) => {
    try {
      acc += convertWeightFromAndTo(parseInt(input.qty), input.uom, UOMS.GR);
    } catch(err) {
      // nothing to do here
    }
    return acc;
  }, 0);

  const wasteQuantity = get(values, 'waste', 0);
  const wasteUom = get(values, 'waste_uom', UOMS.GR);
  let wasteInGrams;
  try {
    wasteInGrams = convertWeightFromAndTo(wasteQuantity, wasteUom, UOMS.GR); // Future proof.  Currently waste is always in grams.
  } catch (err) {
    wasteInGrams = 0;
  }

  if (wasteInGrams > inputWeightInGrams) {
    errors.waste = I18n.t('ei.processing.form.reportWasteValidation');
  }

  if ( props.complianceSettings.inv_packages_require_tracking_id) {
    if (values.inputs.some((input) => {
      return isInvPackageTrackingNotInCompliance(input);
    })) {
      errors.inputs = {
        _error: props.complianceSettings.inv_packages_require_tracking_id_message,
      };
    }
  }

  return errors;
};

export default validate;
