import * as dataNames from '../constants/dataNames';
import {getUnpaginatedData} from './apiActions';
import {addMessage} from './systemActions';
import {openUserOptsMenu} from './menuActions';
import {isActiveFacilityDispensary} from '../selectors/facilitiesSelectors';

export function getLocations() {
  return (dispatch, getState) => {
    const isDispensaryFacility = isActiveFacilityDispensary(getState());
    const params = isDispensaryFacility ? undefined : {is_sales_location: 0};
    return getUnpaginatedData(
      '/api/location_hierarchy',
      dataNames.locations,
      {failed: 'locations.getLocations.failed'},
      params
    )(dispatch, getState);
  };
}

export function ensureSalesLocation() {
  return (dispatch, getState) => {
    const state = getState();
    if(state.salesLocations && !state.user.currentLocation) {
      dispatch(addMessage('warning', ['error.selectLocations']));
      dispatch(openUserOptsMenu());
      return false;
    }
    return true;
  };
}

export const dummy = () => {};
