//import round from 'lodash.round';

const columns = {
  assemblyListingColumns: [
    {
      name: 'inventory.assemblyListing.assemblyName',
      dataId: 'name',
      width:'20%',
    },
    {
      name: 'inventory.assemblyListing.itemProduced',
      dataId: 'productName',
      width:'20%',
    },
    {
      name: 'inventory.assemblyListing.Quantity',
      dataId: 'qty_base',
      width:'10%',
    }//, // hidden for expedience since it seems to not be clear what this should be showing.
    // {
    //   name: 'inventory.assemblyListing.totalCost',
    //   dataId: 'cost',
    //   width:'20%',
    //   formatter: (cell) => round(cell, 2),
    // }
  ]
};

export default columns;
