import {push, LOCATION_CHANGE} from 'react-router-redux';
import get from 'lodash.get';
import * as types from '../constants/actionTypes';
import * as itemNames from '../constants/itemNames';
import * as dataNames from '../constants/dataNames';
import {
  locationChangeWithoutFacility,
  setFacility, setFacilitySwitch,
  setTimezone
} from '../actions/facilityActions';
import {getTranslations} from '../actions/forms/userAccountActions';
import {getItem, getData, getUnpaginatedData} from '../actions/apiActions';
import {fetchUseEntityLocks, getSettingByKey} from '../actions/core/settingsActions';
import {unsetData} from '../actions/dataActions';
import {unsetItem} from '../actions/itemActions';
import {setSocketFacility} from '../actions/socketActions';
// import {setBugsnagUser} from '../util/bugsnag';
import {getFeatureToggles} from '../selectors/featureToggles';

export const loginSuccessFacilityRedirect = store => next => action => {
  /*
   * Redirect user to Facilities page if they do not have a default
   * facility set after logging in. A default facility should exist
   * after successful log in for a user who has only one facility.
   * If a default facility does exist, redirect to the landing page.
   */
  const result = next(action);
  if (action.type !== types.LOGIN_SUCCESS) {
    return result;
  }
  if (typeof action.facility !== 'object') {
    return result;
  }
  const hasDefaultFacility = Object.keys(action.facility).length;
  if (!hasDefaultFacility) {
    store.dispatch(push('/facilities'));
  }
  else {
    const {env} = store.getState();
    if (
      !window.location.hostname.includes('leafdatasystems') && env.ssoEnabled &&
      get(action.facility, 'configuration_packs', []).some((configPack) => configPack.includes('utah'))
    ) {
      // If on MJP and the organization has a single Utah facility THEN we redirect
      const location = window.location.hostname.includes('pre') ? env.utahUrlPrerelase : env.utahUrl;
      window.location.replace(`${location}/facilities`);
      return;
    } else if (

      window.location.hostname.includes('leafdatasystems') && env.ssoEnabled &&
      !get(action.facility, 'configuration_packs', []).some((configPack) => configPack.includes('utah'))
    ) {
      // If on Leafdatasystem and the organization has a single NON-Utah facility THEN we redirect
      window.location.replace(`${env.platformUrl}/facilities`);
      return;
    }
    store.dispatch(setFacility(action.facility));
  }
  return result;
};

export const setFacilityLanguage = store =>  next => action => {

  if (action.type === types.LOGIN_SUCCESS) {
    //Set the users language upon login if specified
    if (get(action, 'user.language')) {
      store.dispatch(getTranslations(get(action, 'user.language'), true)).then(() => {
        return next(action);
      });
    }
  }

  if (action.type === types.SET_FACILITY_SUCCESS) {
    //If the user has an explicit language set, no change should be made when switching facilities
    const {user} = store.getState();
    if (get(user, 'language')) {
      return next(action);
    }

    ///Otherwise base it upon facility settings
    let lang = 'en';
    if(action.payload.facility.country_code && action.payload.facility.country_code.toLowerCase() === 'ca'){
      if(action.payload.facility.language === 'en'){
        lang = 'enCA';
      }else if(action.payload.facility.language){
        lang = action.payload.facility.language;
      }else{
        lang = 'enCA';
      }
    }else if(action.payload.facility.country_code && action.payload.facility.country_code.toLowerCase() === 'au'){
      if(action.payload.facility.language === 'en'){
        lang = 'enAU';
      }else if(action.payload.facility.language){
        lang = action.payload.facility.language;
      }else{
        lang = 'enAU';
      }
    }else if(action.payload.facility.country_code && action.payload.facility.country_code.toLowerCase() === 'nz'){
      if(action.payload.facility.language === 'en'){
        lang = 'enNZ';
      }else if(action.payload.facility.language){
        lang = action.payload.facility.language;
      }else{
        lang = 'enNZ';
      }
    }else{
      if(action.payload.facility.language){
        lang = action.payload.facility.language;
      }
    }
    store.dispatch(getTranslations(lang, true)).then(() => {
      return next(action);
    });
  }else {
    return next(action);
  }
};

export const setFacilityRedirect = store => next => action => {
  const result = next(action);
  if (action.type === types.SET_FACILITY_SUCCESS) {
    // This flag tells us whether the action was dispatched as a result of direct user input vs an event listener
    // See notes below where it's used for further clarity.
    // Also NOTE this is an interim solution until this whole Facility switching process is audited and
    // hopefully rebuilt from the ground up
    const dispatchedForAncillaryTab = action.ancillary;
    const {dispatch} = store;

    // If this dispatched action is for an ancillary browser tab, we skip setting this to false and let
    // the primary browser tab (the tab where the user actually selected the Facility) manage Local Storage
    if (!dispatchedForAncillaryTab) {
      localStorage.setItem('facilityLoadingSequenceIsComplete', false);
    }

    dispatch(setFacilitySwitch(true, true));

    // const {user} = store.getState();
    // if (user && action && action.payload) {
    //   setBugsnagUser({user, facility: action.payload.facility});
    // }

    const {id, organizationId, type} = action.payload.facility;
    const promises = [];

    if(type === 'dispensary'){
      promises.push(
        dispatch(getData('/api/location_hierarchy?is_sales_location=1&active=1', dataNames.salesLocations)),
        dispatch(getData('/api/registers?active=1&type=sales', dataNames.salesRegisters))
      );
    }

    if(id){ // api calls require facility id
      promises.push(
        dispatch(setSocketFacility({facility_id: id, organization_id: organizationId})),
        dispatch(unsetItem(itemNames.facilitySharingStatus))
      );

      // settings
      promises.push(
        dispatch(getItem('/api/third-party-integrations/active', itemNames.activeThirdPartyIntegrations)),
        dispatch(getItem('/api/integration-settings', itemNames.integrationSettings)).then((response) => {
          const innerPromises = [];
          const integratorValue = get(response, 'integration_state_integrator.value');

          if (integratorValue === 'metrc') {
            innerPromises.push(dispatch(getItem('/api/metrc/transfers_enabled', itemNames.metrcTransfersEnabled)));
          }

          if (integratorValue !== 'none') {
            innerPromises.push(dispatch(fetchUseEntityLocks()));
          }

          return Promise.all(innerPromises);
        })
      );

      if (window.location.hostname.includes('leafdatasystems')) {
        promises.push(dispatch(getItem('/api/qlik/leaf')));
      } else {
        promises.push(dispatch(getItem('/api/qlik/mjp')));
      }

      const baseSettingKeys = [
        'core_allow_state_integrations',
        'core_is_hemp_facility',
        'cult_add_test_results_to_plant_groups',
        'core_is_cca_facility',
        'partner_consumer_address_allow_zip_plus_four',
        'core_global_id_generation_enabled',
      ];

      const keysBehindUtahCategoryToggle = [
        'inv_category_display_name_configuration',
        'inv_subcategory_display_name_configuration',
        'inv_jurisdiction_categories',
      ];

      const keysBehindUtahChangeRequestToggle = [
        'inv_packaging_workflow_valid_outputs',
        'inv_packaging_workflow_lab_result_inheritance',
        'mf_processing_workflow_valid_outputs',
        'inv_lab_enable_test_result_creation_jurisdiction_category_whitelist',
        'inv_lab_test_result_creation_jurisdiction_category_whitelist'
      ];

      /**
       * Gets settings by keys.  Chained to response of feature toggles.
       * @param featureToggles
       */
      const getSettingsByKeys = () => {
        const featureToggles = getFeatureToggles(store.getState());
        const getFeatureToggle = (toggleName) => {
          return featureToggles.find((toggle) => {
            return toggle.name === toggleName;
          });
        };

        const getSettingKeys = () => {
          const utahCategoryToggleOn = get(getFeatureToggle('feature_ut_category_management'), 'is_enabled', false);
          const utahChangeRequestToggleOn = get(getFeatureToggle('feature_ut_qa_change_request'), 'is_enabled', false);
          let settingKeys = baseSettingKeys;
          if(utahCategoryToggleOn){
            settingKeys = settingKeys.concat(keysBehindUtahCategoryToggle);
          }
          if(utahChangeRequestToggleOn){
            settingKeys = settingKeys.concat(keysBehindUtahChangeRequestToggle);
          }
          return settingKeys;
        };

        return getSettingByKey(getSettingKeys());
      };

      promises.push(
        dispatch(getData('/api/feature_toggles', dataNames.featureToggles)).then(() => {
          return dispatch(getSettingsByKeys());
        }),
        dispatch(getData('/api/modules', dataNames.modules)).then(() => {
          return dispatch(getData(`/api/facilities/${id}/modules`, dataNames.currentFacilityModules));
        })
      );

      promises.push(
        dispatch(getItem('/api/labels/compliance_settings', itemNames.labelsCompliance)),
        dispatch(getItem('/api/order_settings/sales_limit', itemNames.salesComplianceSettings)),
        dispatch(getItem('/api/distribution/settings', itemNames.distributionSettings)),
        dispatch(getItem('/api/cultivation/settings', itemNames.cultivationSettings)),
        dispatch(unsetData(dataNames.locations)),
        dispatch(getUnpaginatedData('/api/uoms/inventory', dataNames.uoms)),
        //In the future we can expand this to just load all settings, or add to an explicit list
        dispatch(getItem('/api/settings/values/by_key?ids[]=inv_lab_edit_requires_manage_lab_testing', itemNames.coreSettings)),
        dispatch(getItem('/api/settings/values/by_key', itemNames.internationalSettings, null, { ids: [
          'core_currency_symbol',
          'core_numerical_separator',
          'core_time_format',
          'core_date_format',
          'core_phone_number_include_country_code',
          'core_default_language',
        ]}))
      );

      const allPromises = Promise.all(promises);

      allPromises
        .then(() => {
          // If this dispatched action is for an ancillary browser tab, we skip setting this to true and let
          // the primary browser tab (the tab where the user actually selected the Facility) manage Local Storage
          if (!dispatchedForAncillaryTab) {
            localStorage.setItem('facilityLoadingSequenceIsComplete', true);
          }

          // save facility to localStorage to trigger event in non-focused windows
          localStorage.setItem('facility', JSON.stringify({facility: action.payload.facility}));
          dispatch(setFacilitySwitch(false));
          store.dispatch(push('/'));
        })
        .catch((error) => {
          // If this dispatched action is for an ancillary browser tab and an error occurs, only then should
          // the ancillary tab set this value to false in Local Storage (to prevent navigation and further errors
          // from occurring in other browser tabs
          if (dispatchedForAncillaryTab) {
            localStorage.setItem('facilityLoadingSequenceIsComplete', false);
          }

          /*
            TODO: Add error handling here in a follow-up ticket after discussion and determinations of what should
            happen. The "promise all/then" logic was added in https://akerna.atlassian.net/browse/REBEL-1980,
            so the app doesn't swallow any more errors than it already did to this point.
          */
        });
    }
  }
  return result;
};

export const requireFacility = store => next => action => {
  /*
   * If a user is logged in and navigates to a page without having a facility,
   * redirect to the /facilities page and display an error.
   */
  const result = next(action);
  if (action.type === LOCATION_CHANGE) {
    const state = store.getState();
    const path = action.payload.pathname;
    const routeAction = action.payload.action;
    const isAuthenticated = state.user.authenticated;
    const hasFacility = !!Object.keys(state.facility).length;
    const facilityLoadingSequenceIsNotComplete = localStorage.getItem('facilityLoadingSequenceIsComplete') === 'false';

    // Unsure why the original logic here was limited to PUSH actions, but at the risk of breakage kept it in place,
    // and added the OR logic to handle page reloading, which fires a POP action
    if (path !== '/facilities' && isAuthenticated &&
      (routeAction === 'PUSH' && (!hasFacility || facilityLoadingSequenceIsNotComplete)) ||
      (routeAction === 'POP' && facilityLoadingSequenceIsNotComplete)
    ) {
      store.dispatch(push('/facilities'));
      store.dispatch(locationChangeWithoutFacility());
    }
  }
  return result;
};

export const setFaciltyTimezone = store => next => action => {
  const result = next(action);
  if(action.type === types.SET_FACILITY_SUCCESS) {
    const {timezone} = action.payload.facility;
    store.dispatch(setTimezone(timezone));
  }
  return result;
};
