import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack, push} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import * as apiActions from '../../../../actions/apiActions';
import * as dataNames from '../../../../constants/dataNames';
import getSalesReportTabs from '../common/tabDefinitions';
import TabbedFormWrapper from '../../../common/form/TabbedFormWrapper';
import * as dataActions from '../../../../actions/dataActions';
import * as itemNames from '../../../../constants/itemNames';
import {getEnabledTabs} from '../../../../actions/reportActions';
import CashOnHandSearchForm from './CashOnHandSearchForm';
import TablePageWrapper from '../../../common/grid/TablePageWrapper';
import { cashOnHandReportTabs, cashOnHandReportColumns } from './tableDefinitions';
import { getCashOnHandReport } from '../../../../selectors/forms/cashOnHandSelectors';

export class CashOnHandReportPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      salesReportTabs: [],
      isLoading: true,
      featureToggles: [],
    };
    this.switchSalesReportTab = this.switchSalesReportTab.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount() {
    this.props.actions.getItem('/api/order_settings/sales_limit', itemNames.salesComplianceSettings);
    this.setState({salesReportTabs: this.props.actions.getEnabledTabs(getSalesReportTabs)});
  }

  switchSalesReportTab(selection) {
    const activeSalesReportTab = selection;
    const tab = this.state.salesReportTabs.find(tab => tab.eventKey === activeSalesReportTab) || this.state.salesReportTabs[0];
    this.setState({activeSalesReportTab});
    this.props.actions.push(tab.path);
  }

  onSubmit(formValues){
    this.props.actions.unsetData(dataNames.cashOnHandReport);
    const getData = this.props.actions.getUnpaginatedData;
    this.setState({isLoading: true});
    const payload = {
      date: moment(formValues.report_date).format('YYYY-MM-DD'),
      is_active: formValues.register_status === -1 ? undefined : formValues.register_status
    };
    getData('/api/bi_reports/cash_on_hand', dataNames.cashOnHandReport, null, payload)
      .then(() => {
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    return (
      <div>
          <TabbedFormWrapper
            title={I18n.t('retail.closingReport.title')}
            tabs={this.state.salesReportTabs}
            activeTab='cashOnHandReport'
            switchTab={this.switchSalesReportTab}
          >
            <CashOnHandSearchForm
              submitSearch={this.onSubmit}
            />
            <TablePageWrapper
              activeTab='default'
              columns={cashOnHandReportColumns}
              data={this.props.cashOnHandReport}
              dataTotalSize={this.props.cashOnHandReport.length}
              external={false}
              hideExport={false}
              hideScanSearch={true}
              hideSearch={true}
              ref={this.ref}
              settingKey='cashOnHandReport'
              tabs={cashOnHandReportTabs}
            />
          </TabbedFormWrapper>
      </div>
    );
  }
}

CashOnHandReportPage.propTypes = {
  actions: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    getItem: PropTypes.func,
    getUnpaginatedData: PropTypes.func,
  }).isRequired,
  cashOnHandReport: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    cashOnHandReport: getCashOnHandReport(state),
    facility: state[itemNames.facility],
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({},
    apiActions,
    dataActions,
    {goBack, push, getEnabledTabs}
  );
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CashOnHandReportPage);
