import {createSelector} from 'reselect';

const getCoupons = state => state.coupons;
const getAppliedCoupons  = state => state.appliedCoupons;
const getPricingWeights = state => state.pricingWeights;
const getItems = state => state.items;
const getTaxRate = state => state.taxRate;
const getManualDiscount = state => state.manualDiscount;
const getCredit = state => state.payment.credit;
const getCheck = state => state.payment.check;
const getDebit = state => state.payment.debit;
const getCash = state => state.payment.cash;
const getGiftCard = state => state.payment.giftCard;
const getHypur = state => state.payment.hypur;
const getStoreCredit = state => state.payment.storeCredit;
const getPointsCurrency = state => state.payment.points_currency;

const getPricingWeightId = (_, props) => props.pricingWeightId;
const getQuantity = (_, props) => props.quantity;
const getGroupId = (_, props) => props.groupId;
const getPaymentOptions = (_, props) => props && props.paymentOptions || [];

export const roundToCent = amount => Math.round(amount * 100) / 100;

export const getAppliedCouponsById = createSelector(
  [getCoupons, getAppliedCoupons],
  (coupons, appliedCouponIds) => {
    return coupons.filter(coupon => {
      return appliedCouponIds.indexOf(coupon.id) > -1;
    });
  }
);

export const getItemPricingWeight = createSelector(
  [getPricingWeightId, getPricingWeights],
  (pricingWeightId, pricingWeights) => {
    return pricingWeights.reduce((previous, current) => {
      return current.id === pricingWeightId
        ? current
        : previous;
    }, {});
  }
);

export const getGroupPricingWeights = createSelector(
  [getGroupId, getPricingWeights],
  (groupId, pricingWeights) =>  pricingWeights.filter(
    pricingWeight => pricingWeight.groupId === groupId
  )
);

export const getItemLineTotal = createSelector(
  [getQuantity, getItemPricingWeight],
  (quantity, pricingWeight) => {
    return roundToCent(pricingWeight.price * quantity);
  }
);

export const getItemsTotal = createSelector(
  [getItems, getPricingWeights],
  (items, pricingWeghts) => {
    const total = items.reduce((previousSum, item) => {
      const price = pricingWeghts.reduce((previousPrice, pricingWeight) => {
        return item.pricingWeightId === pricingWeight.id
          ? pricingWeight.price
          : previousPrice;
      }, 0);
      return previousSum + price * item.quantity;
    }, 0);
    return roundToCent(total);
  }
);

export const getCouponsDiscount = createSelector(
  [getAppliedCouponsById, getItemsTotal],
  (coupons, total) => {
    const discount = coupons.reduce((previousTotal, coupon) => {
      return previousTotal + (coupon.isPercentage ? total * coupon.amount : coupon.amount);
    }, 0);
    return roundToCent(discount);
  }
);

export const getSubTotal = createSelector(
  [getItemsTotal, getCouponsDiscount, getManualDiscount],
  (itemsTotal, couponsDiscount, manualDiscount) => {
    const subTotal = roundToCent(itemsTotal - couponsDiscount - manualDiscount);
    return subTotal > 0 ? subTotal : 0;
  }
);

export const getTax = createSelector(
  [getTaxRate, getSubTotal],
  (taxRate, subTotal) => {
    return roundToCent(taxRate * subTotal);
  }
);

export const getTotal = createSelector(
  [getSubTotal, getTax],
  (subTotal, tax) => {
    const total = roundToCent(subTotal + tax);
    return total > 0 ? total : 0;
  }
);

export const getAmountTendered = createSelector(
 [getCredit, getCheck, getDebit, getCash, getGiftCard, getStoreCredit, getHypur, getPointsCurrency, getPaymentOptions],
  (credit, check, debit, cash, giftCard, storeCredit, hypur, pointsCurrency, paymentOptions) => {
    let total = 0.00;
    
    for (const key in paymentOptions) {
      const paymentOption = paymentOptions[key];

      switch (paymentOption.value) {
      case 'credit':
        total += credit;
        break;
      case 'check':
        total += check;
        break;
      case 'cash':
        total += cash;
        break;
      case 'debit':
        total += debit;
        break;
      case 'giftCard':
        total += giftCard;
        break;
      case 'hypur':
        total += hypur;
        break;
      }
    }

    return total + storeCredit;
  }
);

export const getChangeDue = createSelector(
  [getTotal, getAmountTendered],
  (total, amountTendered) => {
    return amountTendered - total;
  }
);

export const getItemCount = createSelector(
  getItems,
  (items) => {
    return items.length;
  }
);

export const getPaymentTotal = createSelector(
  [getCredit, getCheck, getDebit, getCash, getGiftCard, getStoreCredit],
  (credit, check, debit, cash, giftCard, storeCredit) => credit + check + debit + cash + giftCard + storeCredit
);

export const getAmountDue = createSelector(
  [getTotal, getPaymentTotal],
  (total, paymentTotal) => total - paymentTotal
);
