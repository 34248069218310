import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {reduxForm, FieldArray, formValueSelector} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Row, Col} from 'react-bootstrap';
import SubmitSection from '../../../common/form/SubmitSection';
import {validate} from './validate';
import {HARVEST_BATCH_SPLIT_FORM} from '../../../../constants/forms';
import {convertDbDateTimeToFormInputDateTime} from '../../../../util/dateHelpers';
import NewSplitHarvestBatches from './NewSplitHarvestBatches'; //eslint-disable-line

export const HarvestBatchSplitForm = (props) => {
  const {submitting, pristine, handleSubmit, splitHarvestBatch, locations, newWeight} = props;
  const settings = {
    actionSettings: {
      saveAndStay: {
        type: 'button',
        disabled: submitting || pristine,
        submitting,
        text: (props.afterSubmit === 'print') ? I18n.t('common.form.printLabels') : I18n.t('common.form.saveAndPrintLabels'),
        action: (e) => {
          e.target.blur();
          if(props.afterSubmit !== 'print') {
            props.change('afterSubmit', 'print');
            setTimeout(() => {
              handleSubmit();
            }, 10);
          } else {
            props.onPrintLabels();
          }
        }
      },
      submit: {
        disabled: submitting || pristine || props.afterSubmit === 'print',
        submitting,
        text: I18n.t('common.form.save')
      }
    }
  };

  const location = locations.find((location) => parseInt(location.value) === parseInt(splitHarvestBatch.inventory_location_id));

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div>
        <h4>Source Harvest Batch</h4>
      </div>
      <div>
        <table className='table table-sm table-bordered'>
          <thead>
          <tr>
            <th style={{width: '25%'}}>Batch Name</th>
            <th style={{width: '25%'}}>Original Weight</th>
            <th style={{width: '25%'}}>Harvest Date</th>
            <th style={{width: '25%'}}>Storage Location</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{splitHarvestBatch.batch_name}</td>
            <td>{splitHarvestBatch.current_weight} Grams</td>
            <td>{convertDbDateTimeToFormInputDateTime(splitHarvestBatch.harvested_at)}</td>
            <td>{location && location.text ? location.text : 'Not Available'}</td>
          </tr>
          <tr>
            <td style={{fontWeight: 'bold', textAlign: 'right'}}>New Weight:</td>
            <td>{newWeight} Grams</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div>
        <Row>
          <Col md={12}>
            <h4>
              New Harvest Batches Split From Source Batch
            </h4>
          </Col>
        </Row>

      </div>
      <div>
        <table className='table table-sm table-striped table-bordered'>
          <thead>
          <tr>
            <th style={{width: '50px'}}>Delete</th>
            <th style={{width: '50px'}}>Ord</th>
            <th style={{width: '50%'}}>Weight</th>
            <th style={{width: '50%'}}>Location</th>
            <th style={{width: '50px'}}>Add</th>
          </tr>
          </thead>
          <FieldArray
            name='splits'
            component={NewSplitHarvestBatches}
            locations={locations}
          />
        </table>
      </div>
      <SubmitSection settings={settings}/>
    </form>
  );
};

HarvestBatchSplitForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  change: PropTypes.func,
  splitHarvestBatch: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onPrintLabels: PropTypes.func.isRequired,
  newWeight: PropTypes.number,
  actions: PropTypes.object,
  splits: PropTypes.array,
  afterSubmit: PropTypes.string,
};

const form = reduxForm({
  form: HARVEST_BATCH_SPLIT_FORM,
  enableReinitialize: true,
  validate
})(HarvestBatchSplitForm);

function mapStateToProps(state) {
  const selector = formValueSelector(HARVEST_BATCH_SPLIT_FORM);
  return {
    newWeight: selector(state, 'newWeight'),
    afterSubmit: selector(state, 'afterSubmit'),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, {});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (form);


