import React from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-bootstrap';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import omit from 'lodash.omit';
import {I18n} from 'react-redux-i18n';

import * as apiActions from '../../../actions/apiActions';
import * as itemActions from '../../../actions/itemActions';
import * as messageTypes from '../../../constants/messageTypes';
import * as systemActions from '../../../actions/systemActions';
import {MAX_UPLOAD_SIZE, MAX_UPLOAD_SIZE_DISPLAY, FILE_TYPES, types} from '../../../constants/fileUploads';

class FileButton extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.onChange = this.onChange.bind(this);
    this.validateFile = this.validateFile.bind(this);
    this.tempUpload = this.tempUpload.bind(this);
  }

  validateFile(file) {
    let isValid = true;
    const {file_type} = this.props;
    const {addMessage} = this.props.actions;
    const fileIsValid = FILE_TYPES.getFirstValidInternalType(file_type, file);

    if (!fileIsValid) {
      isValid = false;
      addMessage(messageTypes.error, this.props.errors.file_type ? this.props.errors.file_type : 'error.invalidFileTypeUploaded');
    }
    if (file.size > MAX_UPLOAD_SIZE) {
      isValid = false;
      addMessage(messageTypes.error, ['error.maximumUploadExceeded', {limit: MAX_UPLOAD_SIZE_DISPLAY}]);
    }
    return isValid && fileIsValid;
  }

  onChange(event) {
    const {onFocus, onChange, onBlur, skipupload = 0} = this.props;
    const {files} = event.target;
    onFocus(event);
    if (files && files[0]) {
      const reader = new FileReader();
      Object.keys(files).forEach((index) => {
        const file = files[index];
        const validFile = this.validateFile(file);
        if (validFile) {
          // Skip upload to server. Use this when file is used on front-end only
          if (skipupload) {
            this.props.actions.setItem(file, this.props.itemName);
            this.props.onChangeCb(file);
          } else {
            reader.onloadend = e => {
              const {type, file_type} = validFile;
              return this.tempUpload({
                name: file.name,
                size: file.size,
                type,
                file_type,
                input: files[index],
                data: e.target.result,
              });
            };
          }
          reader.readAsDataURL(file);
        } else {
          onChange('');
          onBlur('');
        }
      });
    } else {
      onChange('');
      onBlur('');
    }
  }

  tempUpload(file) {
    const data = new FormData();
    const {onChangeCb} = this.props;

    data.append('file', file.input);
    data.append('name', file.name);
    data.append('file_type', file.file_type);
    if (this.props.facilityId) {
      data.append('facility_id', this.props.facilityId);
    }
    if (this.props.userId) {
      data.append('user_id', this.props.userId);
    }
    if (Array.isArray(this.props.imageSizes) && this.props.imageSizes.length) {
      data.append('image_sizes', this.props.imageSizes);
    }
    this.props.onUploadStart();
    let url;
    switch (file.type) {
    case types.document:
    case types.wordDocument:
      url = '/api/documents';
      break;
    case types.importFile:
      url = '/api/documents';
      break;
    case types.image:
      url = '/api/images';
      break;
    default:
      throw new Error(`Unsupported file type provided: ${file.type}`);
    }
    this.props.actions.postItem(
      url,
      data,
      this.props.itemName,
      {success: 'file.upload.success'},
      {},
      (response) => onChangeCb(response, file)
    ).catch((e) => {
      const errors = e && e.response && e.response.data && e.response.data.errors || {};
      onChangeCb(e.response, file);

      if (Object.keys(errors).length) {
        Object.keys(errors).forEach((key) => {
          const error = errors[key];
          this.props.actions.addMessage(messageTypes.error, [error]);
        });
      } else {
        // Raise standard error if we don't get a message from the server
        this.props.actions.addMessage(messageTypes.error, [I18n.t('file.upload.failed')]);
      }
    });
  }

  render() {
    const {btnContent, btnProps, name, handleRef, ...props} = this.props;
    const inputProps = omit(
      props,
      [
        'type',
        'value',
        'state',
        'userId',
        'errors',
        'actions',
        'itemName',
        'onChange',
        'file_type',
        'onChangeCb',
        'extensions',
        'facilityId',
        'imageSizes',
        'onUploadStart',
        // fight against warnings
        'dirty'
      ]
    );
    return (
      <div>
        <Form.Label className='btn' {...btnProps} disabled={inputProps.disabled}>
          {btnContent}
          <input id={name} type='file' name={name} className='hidden-input'
                 onChange={this.onChange} ref={handleRef} {...inputProps}/>
        </Form.Label>
      </div>
    );
  }
}

FileButton.propTypes = {
  actions: PropTypes.object,
  btnContent: PropTypes.node,
  btnProps: PropTypes.object,
  name: PropTypes.string.isRequired,
  itemName: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChangeCb: PropTypes.func,
  onUploadStart: PropTypes.func,
  handleRef: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  file_type: PropTypes.string,
  type: PropTypes.string,
  facilityId: PropTypes.number,
  userId: PropTypes.number,
  imageSizes: PropTypes.array,
  errors: PropTypes.object,
  skipupload: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
};

FileButton.defaultProps = {
  onUploadStart: () => {},
  onChangeCb: () => {},
  errors: {},
  skipupload: 0
};

function mapStateToProps(state) {
  return {
    state
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, systemActions, itemActions);
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(FileButton);
