import {change} from 'redux-form';
import {REDUX_FORM_CHANGE} from '../../constants/actionTypes';
import {CREATE_PRICING_GROUP_FORM} from '../../constants/forms';
import {getPricingWeights, getPricingWeightsByUom} from '../../selectors/pricingWeightsSelectors';
import {getIsHempFacility} from '../../selectors/coreSettingsSelectors';
import {addMessage} from '../../actions/systemActions';
import {warning} from '../../constants/messageTypes';


export const pricingGroupFormMiddleware = store => next => action => {
  const result = next(action);
  const {type, meta, payload} = action;

  if (type !== REDUX_FORM_CHANGE || meta.form !== CREATE_PRICING_GROUP_FORM) return result;

  if (meta.field === 'uom') handleUomChange(store.getState(), store.dispatch, payload);
  if (meta.field === 'category_id') handleCategoryChange(store.getState(), store.dispatch);

  return result;
};

const handleCategoryChange = (state, dispatch) => {
  if (!getIsHempFacility(state)) return;

  dispatch(
    change(CREATE_PRICING_GROUP_FORM, 'uom', '')
  );
};

const handleUomChange = (state, dispatch, payload) => {
  const pricingWeights = getPricingWeights(state);
  const filteredPricingWeights = getPricingWeightsByUom(payload, pricingWeights);

  dispatch(
    change(CREATE_PRICING_GROUP_FORM, 'pricing_weights', filteredPricingWeights)
  );

  if (payload && !filteredPricingWeights.length) {
    dispatch(
      addMessage(warning, 'retail.pricingGroup.form.pricingWeightsForUomWarning')
    );
  }
};

export default pricingGroupFormMiddleware;
