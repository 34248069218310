import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {Col, Row, Form} from 'react-bootstrap';
import RouteButton from '../../common/RouteButton';
import RoutePermissionWrapper from '../../common/RoutePermissionWrapper';
import {getActiveFacility, getFacilitySetupLinks, isRegulatorFacilitySelector} from '../../../selectors/facilitiesSelectors';
import * as p from '../../../constants/permissions';
import {isFeatureEnabled} from '../../../selectors/featureToggles';
import {userHasPermission} from '../../../selectors/usersSelectors';
import {getLocalStorage, setLocalStorage} from '../../../util/localStorage';
import {facilitySetupLinks} from './links';

const props = {links: facilitySetupLinks};
export class FacilitySetupPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.state = {
      links: [],
      defaultFacility: null,
    };
  }

  componentDidMount() {
    const {facility} = this.props;
    const defaultFacility = getLocalStorage(`localSettings.org_${facility.organizationId}.defaultFacility`);

    // Disabling lint here as it's acceptable to call setState in some situations
    /* eslint-disable react/no-did-mount-set-state */
    this.setState({defaultFacility: defaultFacility});
  }

  toggleDefaultFacility() {
    const { facility } = this.props;
    const { defaultFacility } = this.state;
    const organizationId = facility.organizationId;
    const currentFacilityId = facility.id;

    const value = (defaultFacility == currentFacilityId) ? null : currentFacilityId;
    setLocalStorage(`localSettings.org_${organizationId}.defaultFacility`, value);

    this.setState({defaultFacility: value});
  }

  render() {
    const {facility, links, canManageDefaultFacility, feature_set_default_facility_for_login, isRegulatorFacility} = this.props;
    const defaultFacility = this.state.defaultFacility == facility.id ? 1 : 0;
    return (
      <div>
        <div className='setup-page'>
          <h1>{I18n.t('facility.setup.title', {facility: facility.name})}</h1>
          {(feature_set_default_facility_for_login && canManageDefaultFacility && !isRegulatorFacility) ? (
            <Row>
              <Col xs={12} md={6} lg={6} xl={4} >
                <Form.Check
                  type='checkbox'
                  name='isFacilityDefault'
                  checked={defaultFacility}
                  label={I18n.t('facility.setup.setAsDefaultFacility')}
                  onClick={() => { this.toggleDefaultFacility(); }}
                />
              </Col>
            </Row>
          ) : null}
          {
            !isRegulatorFacility
              ? (<Row>
                {links.map(item => (
                  <RoutePermissionWrapper key={item.title} path={item.link} accessibility={item.accessibility}>
                    <Col xs={12} md={6} lg={6} xl={4} >
                      <RouteButton path={item.link} props={{block:true}}>
                        <h3>{I18n.t(item.title)}</h3>
                      </RouteButton>
                    </Col>
                  </RoutePermissionWrapper>
                ))}
              </Row>)
              : null
          }
          {
            !isRegulatorFacility
              ? <hr />
              : null
          }
          <Row>
            <Col xs={12}>
              <RouteButton path='/setup/advanced' props={{variant:'link'}}>
                <h3>{I18n.t('facility.setup.advancedSettings')}</h3>
              </RouteButton>
            </Col>
          </Row>
        </div>
      </div>

    );
  }
}

FacilitySetupPage.propTypes = {
  facility: PropTypes.object.isRequired,
  links: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  const facility = getActiveFacility(state);
  const canManageDefaultFacility = userHasPermission(state, {permissions: [p.manage_default_facility_login]});
  const feature_set_default_facility_for_login = isFeatureEnabled(state)('feature_set_default_facility_for_login');

  return {
    facility,
    canManageDefaultFacility,
    feature_set_default_facility_for_login,
    links: getFacilitySetupLinks(state, props),
    isRegulatorFacility: isRegulatorFacilitySelector(state),
  };
}

export default connect(mapStateToProps)(FacilitySetupPage);
