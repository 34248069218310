import React from 'react';
import {connect} from 'react-redux';
import { formValueSelector, change } from 'redux-form';
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'react-bootstrap'
import Notice from '../../common/notice';
import { AuthRequest } from '../../../managers/request';
import RegulatedUserForm from './RegulatedUserForm';

// the approval buttons appear in 2 places, this config helps keep things DRY
const approvalTypes = {
  approved: {
    value: 'approved',
    text: 'Approve',
    button: 'primary'
  },
  rejected: {
    value: 'rejected',
    text: 'Reject',
    button: 'danger'
  },
  revoked: {
    value: 'revoked',
    text: 'Revoke',
    button: 'warning'
  }
};

class RegulatedUser extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      user: {},
      error: null,
      initialized: false,
      loading: false,
      showModal: false,
      reason: '',
      modalType: '',
    }

    this.request = AuthRequest.create();
    this.requestUserData = this.requestUserData.bind(this);
    this.handleUserApproval = this.handleUserApproval.bind(this);
    this.handleModalShow = this.handleModalShow.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.renderRejectModal = this.renderRejectModal.bind(this);
    this.setApprovalStatus = this.setApprovalStatus.bind(this);
    this.lengthBan = this.lengthBan.bind(this);
  }

  componentWillMount() {
    this.requestUserData();
  }

  lengthBan(e, field, change){
    const value = e.target.value;
    if( !(value.length > 25)){
      change(field, value);
    }
  }

  requestUserData() {
    const endpoint = `/api/closed_loops/users/${this.props.params.id}`;
    return this.request.get(endpoint).then((userResponse) => {
      const user = userResponse.data;
      this.setState({
        user,
        initialized: true,
      });
    }).catch((error) => {
      this.setState({
        initialized: true,
        error,
      });
    });
  }

  // Update the user.approval_status and rehydrate the user
  handleUserApproval(status) {
    const api = '/api/closed_loops/users/approval';
    const finalApprovalDate = this.props.getFormValue('final_approval_date');
    const backgroundCheckRefNoFormValue = this.props.getFormValue('background_check_ref_no');

    const payload = {
      approval_status: status,
      approval_message: this.state.reason,
      user_id: this.props.params.id,
      final_approval_date: finalApprovalDate,
      background_check_ref_no: backgroundCheckRefNoFormValue,
    };

    this.setState({
      loading: true,
    });

    this.request.post(api, payload).then(() => {
      this.requestUserData();
    }).then(() => {
      this.setState({
        loading: false,
        reason: '',
      });
    });
  }

  handleModalShow(modalType, finalApprovalDate) {
    this.setState({
      showModal: true,
      modalType,
      finalApprovalDate
    });
  }

  handleModalClose() {
    this.setState({
      showModal: false,
      modalType: '',
      finalApprovalDate: ''
    });
  }

  setApprovalStatus(approvalStatus) {
    this.setState({approvalStatus})
  }

  renderRejectModal() {
    const { showModal, user, modalType, reason, finalApprovalDate } = this.state;
    const org = user.facilities[0].facility.organization.name;
    const approvalType = approvalTypes[modalType];

    if (!showModal) {
      return null;
    }

    return (
      <Modal dialogClassName='modal-sm' show={showModal} onHide={this.handleModalClose}>
        <ModalHeader style={{textAlign: 'center', fontWeight: 'bold'}} closeButton>{approvalType.text} user access</ModalHeader>
        <ModalBody>
          <span>{`${approvalType.text} ${user.name} ${user.last_name} to access ${org}. User will receive an email notification.`}</span>
          {modalType !== approvalTypes.approved.value &&
              <textarea
                rows={4}
                placeholder={`Provide a reason to ${approvalType.text.toLowerCase()} user access. (Optional)`}
                className='form-control form-control-sm'
                value={reason}
                onChange={(event => this.setState(
                  { reason: event.target.value }))}
              />
          }
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button
              title={`${approvalType.text} button`}
              variant={approvalType.button}
              className='btn'
              onClick={() => {
                this.handleUserApproval(approvalType.value, finalApprovalDate);
                this.handleModalClose();
              }}
            >
              {approvalType.text}
            </Button>
            <Button onClick={() => this.handleModalClose()}>
              Close
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    );
  }

  render () {
    const { user, initialized, loading, error } = this.state;

    let render = null;
    if (initialized) {
      if (error) {
        const { response: {data} } = error;
        render = <Notice title={data} type={'warning'}/>
      } else if (user) {
        render = (
          <React.Fragment>
            <RegulatedUserForm
              user={user}
              loading={loading}
              getFormValue={this.props.getFormValue}
              handleModalShow={this.handleModalShow}
              handleUserApproval={this.handleUserApproval}
              approvalTypes={approvalTypes}
              lengthBan={this.lengthBan}
              change={change}
            />
            <br/>
            <hr/>
            <span>Access approval logs: </span>
            <ApprovalLogs logs={user.audit_log}/>
          </React.Fragment>
        );
      } else {
        render = <Notice title={'No user found'} type={'warning'}/>
      }
    }
    return (
      <Common>
        {render}
        {initialized ? this.renderRejectModal() : null}
      </Common>
    );
  }
}

const Common = ({ children }) => {
  return (
    <React.Fragment>
      <h1>Regulation: User Profile</h1>
      {children}
    </React.Fragment>
  )
}


const ApprovalLogs = ({logs}) => {
  if (!logs.length) {
    return <span>No approval history</span>
  }

  return logs.map((log) => {
    return (
      <ApprovalLogRow
        createdAt={log.created_at}
        action={log.action}
        by={`${log.user.first_name} ${log.user.last_name}`}
      />
    )
  })
}

const ApprovalLogRow = ({ createdAt, action, by }) => {
  return (
    <div>
      <span>{`${createdAt} - ${action} by ${by}`}</span>
    </div>
  )
}

const selector = formValueSelector('RegulatedUserForm');

const mapStateToProps = (state, props) => {
  return {
    getFormValue: (name) => selector(state, name),
  }
}

function mapDispatchToProps(dispatch) {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegulatedUser);
