import React from 'react';
import PropTypes from 'prop-types';
//import {I18n} from 'react-redux-i18n';

const NamedDefinitions = (props) => {

  const loading = Object.keys(props.labelData).length === 0;

  const width = props.labelData.width !== undefined ? props.labelData.width * 100 : 0;

  return (
    <div className='row' style={{marginTop: '12px'}}>
      <div className='col-md-12'>
      <div className='col-md-12'>
        <div style={{marginBottom: '12px', textAlign: 'center'}} className='text-muted'>
          Sample Images are for general purposes.  Spacing will always be somewhat different than the printed
          version.  <strong>Always print labels to test that your spacing is as planned.</strong>
        </div>
        {
          loading
            ? (<div style={{textAlign: 'center', fontSize: '26px', color:'#ccc', marginTop: '40px'}}>Loading Label...</div>)
            : (<div><div style={{border: '1px solid #ccc', width: `${width}px`, margin: 'auto'}}>
                <img style={{width: '100%'}} src={['data:image/png;base64', props.labelData.image.base64]} />
          </div></div>)
        }
      </div>
      <div className='col-md-12'>
        <hr />
        <button
          onClick={(event) => {
            props.onHide();
          }}
          className='btn btn-danger'
        >
          Cancel
        </button>
        <button
          onClick={(event) => {
            props.onSaveModalData();
          }}
          className='btn btn-primary float-right'
        >
          Print
        </button>
      </div>
      </div>
    </div>
  );
};

NamedDefinitions.propTypes = {
  definitions: PropTypes.array.isRequired,
  currentDefinitionIndex: PropTypes.number.isRequired,
  onSelectNamedDefinition: PropTypes.func.isRequired,
  onSaveModalData: PropTypes.func.isRequired,
};

export default NamedDefinitions;
