import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Table} from 'react-bootstrap';
import ContentConcealer from '../../../common/concealers/ContentConcealer';
import PermissionLink from '../../../common/PermissionLink';
import * as p from '../../../../constants/permissions';


const LabResultDetails = ({model, eventType, isPlant}) => {
  const linkProps = {
    title: I18n.t('common.history'),
    to: `/test-results/view/${model.lab_results_id}`,
    className: 'btn btn-sm btn-primary',
    target: '_blank',
  };
  return (
    <Table striped className='item-details'>
      <thead>
        <tr>
          <th>{I18n.t('itemTransactions.field')}</th>
          <th>{I18n.t('itemTransactions.value')}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{I18n.t('cultivation.testResults.form.labPartner')}</td>
          <td>{model.partner_name || ''}</td>
        </tr>
        <tr>
          <td>{I18n.t('cultivation.testResults.form.testingId')}</td>
          <td>{model.testing_id || ''}</td>
        </tr>
        <tr>
          <td>{I18n.t('cultivation.testResults.table.testingDate')}</td>
          <td>{model.testing_date || ''}</td>
        </tr>
        {
          !isPlant
            ? null
            : (<tr>
                <td id='testing-results-phase-id'>{I18n.t('cultivation.testResults.table.phaseId')}</td>
                <td>{model.stage_name}</td>
              </tr>)
        }


        <ContentConcealer show={eventType === 'LAB_RESULT_ASSIGNED'}>
          <tr>
            <td>{I18n.t('common.details')}</td>
            <td>
              <PermissionLink permissions={[p.view_testing]} props={linkProps}>
                {I18n.t('common.view')}
              </PermissionLink>
              </td>
          </tr>
        </ContentConcealer>
      </tbody>
    </Table>
  );
};

LabResultDetails.propTypes = {
  model: PropTypes.object.isRequired,
  eventType: PropTypes.string.isRequired,
  isPlant: PropTypes.bool,
};

export default LabResultDetails;
