import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import { withRouter } from 'react-router';
import {bindActionCreators} from 'redux';
import { unsetData } from '../../../../../actions/dataActions';
import { addMessage } from '../../../../../actions/systemActions';
import {getDataBatch, getDataBatchByPost} from '../../../../../actions/apiActions';

import BiotrackPurchasableAmountComponent from './BiotrackPurchasableAmountComponent';
import {isBiotrackIntegrator} from '../../../../../selectors/integration/biotrackSelectors';
import {
  getBiotrackCardInfo,
  getPayloadForLimit
} from '../../../../../selectors/integration/biotrackPatientCardSelectors';
import {getOrderProductItemMasterIds} from '../../../../../selectors/orderSelectors';
import * as dataNames from '../../../../../constants/dataNames';
import {RECREATIONAL} from '../../../../../constants/consumerTypes';

const PurchasableAmount = (props) => {
  if(!props.isBiotrack || get(props, 'customer.type', '') === RECREATIONAL) return null;
  return <BiotrackPurchasableAmountComponent {...props}/>;
};

PurchasableAmount.propTypes = {
  isBiotrack: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {
  const cartLimit = get(getBiotrackCardInfo(state, ownProps), 'card_limit', 0);
  const orderWeight = state[dataNames.orderLimit];
  const overage = orderWeight - cartLimit;
  const isOverage = cartLimit > 0 && orderWeight > 0 && orderWeight > cartLimit;

  return {
    overage,
    cartLimit,
    isOverage,
    orderWeight,
    payloadLimit: getPayloadForLimit(state),
    isBiotrack: isBiotrackIntegrator(state),
    itemMasterIds: getOrderProductItemMasterIds(state),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const actions = bindActionCreators({
    getDataBatch,
    getDataBatchByPost,
    addMessage,
    unsetData
  }, dispatch);


  return {
    actions,

    componentWillUnmount() {
      // if (ownProps.location)
      actions.unsetData(dataNames.products);
      actions.unsetData(dataNames.biotrackPatientCards);
    },
    componentWillMount({ payloadLimit }) {
      if (!isEmpty(payloadLimit.items)) {
        actions.getDataBatchByPost('/api/biotrack/order_limits', payloadLimit, dataNames.orderLimit, {});
      }
    },

    componentWillReceiveProps(props, nextProps) {
      if (!isEmpty(nextProps.payloadLimit.items) && props.payloadLimit.items !== nextProps.payloadLimit.items) {
        actions.getDataBatchByPost('/api/biotrack/order_limits', nextProps.payloadLimit, dataNames.orderLimit, {});
      } else if (isEmpty(nextProps.payloadLimit.items)) {
        actions.unsetData(dataNames.orderLimit);
      }
    },
  };
}

const BiotrackPurchasableAmount = withRouter(connect(mapStateToProps, mapDispatchToProps)(PurchasableAmount));

BiotrackPurchasableAmount.propTypes = {
  customer: PropTypes.object,
  orderId: PropTypes.number,
  showHeader: PropTypes.bool,
  className: PropTypes.string,
  showOrderData: PropTypes.bool,
};

BiotrackPurchasableAmount.defaultProps = {
  showHeader: true,
  className: '',
  showOrderData: false,
};

export default BiotrackPurchasableAmount;
