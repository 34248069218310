import React from 'react';
import PropTypes from 'prop-types';
import DateTimeField from 'react-datetime';
import {Button} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import moment from 'moment';
import {FaCalendarAlt, FaRegClock} from 'react-icons/fa';
import ReduxBootstrapInput from './ReduxBootstrapInput';

const DatePickerInput = ({ label, size, input, meta, children, isRequired, leftAddon, timeFormat, inputProps, handleChange, enableTodayButton, ...props }) => {
  const leftIcon = leftAddon || (timeFormat ? <FaRegClock/> : <FaCalendarAlt/>);

  const rightAddon = enableTodayButton && <Button onClick={() => {typeof(handleChange) !== 'undefined' ? handleChange(moment()) : input.onChange(moment());}} disabled={inputProps && inputProps.disabled || false}>{I18n.t('common.actions.today')}</Button>;

  return (
    <ReduxBootstrapInput Control={DateTimeField}
                         label={label} size={size}
                         input={input} meta={meta}
                         name={input.name}
                         isRequired={isRequired}
                         timeFormat={timeFormat}
                         onFocus={() => {}}
                         leftAddon={leftIcon}
                         rightAddon={rightAddon}
                         inputProps={inputProps}
                         onChange={typeof(handleChange) !== 'undefined' ? (value) => handleChange(value) : (value) => input.onChange(value)}
                         className='date-picker-field' {...props} >
      {children}
    </ReduxBootstrapInput>
  );
};

DatePickerInput.propTypes = {
  label: PropTypes.string,
  size: PropTypes.oneOf(['lg', 'sm', 'large', 'small']),
  timeFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onClick: PropTypes.func,
    onHandleChange: PropTypes.func,

  }).isRequired,
  inputProps: PropTypes.object,
  meta: PropTypes.object.isRequired,
  children: PropTypes.node,
  leftAddon: PropTypes.node,
  isRequired: PropTypes.bool,
  enableTodayButton: PropTypes.bool,
  handleChange: PropTypes.func,
};

DatePickerInput.defaultProps = {
  timeFormat: false,
  enableTodayButton: true,
};

export default DatePickerInput;
