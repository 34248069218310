import React from 'react';
import PropTypes from 'prop-types';

const FilterOnShowByStateButtons = (props) => {

  const {currentQueue, showByState, onSelectState} = props;

  const validQueues = ['in_store', 'pickup'];

  if(validQueues.indexOf(currentQueue) === -1) return null;

  const buttonIsSelected = (type) => {
    return (type === showByState) ? 'btn btn-primary' : 'btn';
  };

  const buttonStyle = {
    marginRight: '2px'
  };

  return (<div className='col-md-12x'>
    {
      currentQueue === 'in_store'
      ? (<div className='btn-group toggles' style={{borderx:'1px solid #ccc', padding:'3px', marginTop: '8px'}}>
          <button type='button' style={buttonStyle} className={buttonIsSelected('all')} onClick={(event) => onSelectState(event, 'all')}>All</button>
          <button type='button' style={buttonStyle}  className={buttonIsSelected('new')} onClick={(event) => onSelectState(event, 'new')}>New</button>
          <button type='button' style={buttonStyle}  className={buttonIsSelected('active')} onClick={(event) => onSelectState(event, 'active')}>In Progress</button>
          <button type='button'  className={buttonIsSelected('mine')} onClick={(event) => onSelectState(event, 'mine')}>Mine</button>
        </div>)
        : (<div className='btn-group'>
            <button type='button' style={buttonStyle} className={buttonIsSelected('all')} onClick={(event) => onSelectState(event, 'all')}>All</button>
            {/*<button type='button' style={buttonStyle}  className={buttonIsSelected('active')} onClick={(event) => onSelectState(event, 'active')}>In Progress</button>*/}
            {/*<button type='button' style={buttonStyle}  className={buttonIsSelected('assigned')} onClick={(event) => onSelectState(event, 'assigned')}>Ready For Payment</button>*/}
            <button type='button' style={buttonStyle}  className={buttonIsSelected('present')} onClick={(event) => onSelectState(event, 'present')}>Customer In Store</button>
            <button type='button' style={buttonStyle}  className={buttonIsSelected('curbside')} onClick={(event) => onSelectState(event, 'curbside')}>Curbside</button>
            {/*<button type='button' style={buttonStyle}  className={buttonIsSelected('mine')} onClick={(event) => onSelectState(event, 'mine')}>Mine</button>*/}
          </div>)
    }
    {
      React.Children.map(props.children, (child) => child)
    }
  </div>);

};

FilterOnShowByStateButtons.propTypes = {
  currentQueue: PropTypes.string.isRequired,
  showByState: PropTypes.string.isRequired,
  onSelectState: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
};

export default FilterOnShowByStateButtons;
