import get from 'lodash.get';
import {HARVEST_TEST_SAMPLE_CATEGORY_ID, NON_MEDICATED_CATEGORY_ID} from '../constants/categories';

/***
 * Written this way so I can centralize it if this is common enough.
 * @param facility
 * @param itemMaster
 * @returns {boolean}
 */
const showFinishedInventoryFlag = (facility, itemMaster) => {
  const harvestTestSampleCategory = HARVEST_TEST_SAMPLE_CATEGORY_ID;
  const nonMedicatedCategory = NON_MEDICATED_CATEGORY_ID;
  if(get(facility, 'country_code', '').trim().toLowerCase() !== 'ca') return false;
  return [harvestTestSampleCategory, nonMedicatedCategory].indexOf(get(itemMaster, 'category_id')) === -1;
};

export default showFinishedInventoryFlag;
