import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import SettingPanels from './SettingPanels';

const SettingsReduxForm = reduxForm({
  form: 'receiptSettings',
})(SettingPanels);

function mapStateToProps(state, ownProps) {
  return { state, formFields: ownProps.formFields };
}

const SettingFormWrapper = connect(mapStateToProps)(SettingsReduxForm);

SettingFormWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default SettingFormWrapper;
