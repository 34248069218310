import React from 'react';
import PropTypes from 'prop-types';
import {reduxForm, Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Carousel, Col, Row, Tabs, Tab, Image, Button} from 'react-bootstrap';

import ReactSelectInput from '../../common/form/ReactSelectInput';
import {validate} from '../validate';
import {terpeneList, potencyList} from '../common/data';
import {LEAF} from '../../../constants/imageUrls';
import {getImageUrl} from '../../../util/images';
import MeasurementList from '../common/MeasurementList';
import Lineage from './Lineage';
import ProductPrice from '../common/ProductPrice';
import {isMedicated} from '../../../selectors/itemMastersSelectors';
import InternationalQuantityByUomInput from '../../common/form/InternationalQuantityByUomInput';

export const ProductDetail = ({
  itemMaster,
  strain,
  handleSubmit,
  submitting,
  pristine,
  valid,
  itemMasterType,
  isCure,
  formOptions
}) => {
  return (
    <form className='product-details-form' onSubmit={handleSubmit}>
      <Row>
        <Col md={4} className='carousel-container'>
          {itemMaster.product_images && itemMaster.product_images.length ? (
            <Carousel>
              <Carousel.Item key='first'>
                <Image src={itemMaster.src} fluid/>
              </Carousel.Item>
              {itemMaster.product_images &&
              itemMaster.product_images.map((image) => (
                <Carousel.Item key={image.id}>
                  <Image src={getImageUrl(image, '400x400', LEAF)} fluid/>
                </Carousel.Item>
              ))}
            </Carousel>
          ) : (
            <Image src={itemMaster.src} fluid/>
          )}
        </Col>
        <Col md={8}>
          <h2>{itemMaster.display_name || itemMaster.name}</h2>
          <p>{itemMaster.description}</p>
          <ProductPrice itemMaster={itemMaster} isDetailPage={true}/>
          {isCure && isMedicated(itemMaster) ? (
            <Field
              name='form_id'
              component={ReactSelectInput}
              props={{
                label: I18n.t('categoryManagement.deliveryMethod'),
                options: formOptions,
                isRequired: true
              }}
            />
          ) : null}
          <div className='form-input-container'>
            <InternationalQuantityByUomInput
              name='quantity'
              isRequired={true}
              label={itemMasterType === 'unit' ? I18n.t('common.form.quantity') : I18n.t('common.form.weight')}
              uom={itemMaster.default_uom}

            />
            <Button variant='primary' type='submit' disabled={submitting || pristine || !valid}>
              {I18n.t('productMenu.addToCart')}
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>&nbsp;</Col>
      </Row>
      {itemMaster.strain_id ? (
        <Row>
          <Col xs={12}>
            <Tabs defaultActiveKey={2} id='filterTabs' className='productDetailTabs'>
              <Tab eventKey={1} title={I18n.t('products.form.lineage')} style={{marginTop: '20px'}}>
                <Lineage strain={strain}/>
              </Tab>
              <Tab eventKey={2} title={I18n.t('products.form.potency')} style={{marginTop: '20px'}}>
                <MeasurementList itemMaster={itemMaster} measurements={potencyList} readOnly={true}/>
              </Tab>
              <Tab eventKey={3} title={I18n.t('products.form.terpene')} style={{marginTop: '20px'}}>
                <MeasurementList itemMaster={itemMaster} measurements={terpeneList} readOnly={true}/>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      ) : null}
    </form>
  );
};

ProductDetail.propTypes = {
  itemMaster: PropTypes.object.isRequired,
  itemMasterType: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired,
  strain: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  isCure: PropTypes.bool,
  formOptions: PropTypes.array.isRequired
};

export default reduxForm({
  form: 'productDetail',
  validate
})(ProductDetail);
