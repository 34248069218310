import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Row, Table, Card} from 'react-bootstrap';
import ContentConcealer from '../../../common/concealers/ContentConcealer';
import {getTranslationLabel} from '../../../../util/translationHelpers';

const getLabel = getTranslationLabel('batchHistory.model.');


const HarvestBatchPlantsAddedDetails = ({model, delta, integrationState}) => {
  return (
    <Card>
      <Row>
        <Col xs={6}>
          <h3>{I18n.t('batchHistory.model.plantHarvestDetails')}</h3>
          <Table striped className='item-details'>
            <thead>
            <tr>
              <th>{I18n.t('itemTransactions.field')}</th>
              <th>{I18n.t('itemTransactions.value')}</th>
            </tr>
            </thead>
            <tbody>
            <ContentConcealer show={Boolean(model.plants_count)}>
              <tr>
                <td>{I18n.t('batchHistory.model.plantCount')}</td>
                <td>{model.plants_count}</td>
              </tr>
            </ContentConcealer>
            <tr>
              <td>{getLabel('wetWeight', integrationState)}</td>
              <td>{model.data.wet_weight_harvest} {model.data.uom}</td>
            </tr>
            <ContentConcealer show={Boolean(integrationState.isWaLeaf && model.wet_other_material_weight)}>
              <tr>
                <td>{getLabel('wetOtherMaterialWeight', integrationState)}</td>
                <td>{model.wet_other_material_weight} {model.uom}</td>
              </tr>
            </ContentConcealer>
            <tr>
              <td>{I18n.t('batchHistory.model.pestResistance')}</td>
              <td>{model.data.rating_avg_pest_resistance}</td>
            </tr>
            <tr>
              <td>{I18n.t('batchHistory.model.mold')}</td>
              <td>{model.data.rating_avg_mold}</td>
            </tr>
            <tr>
              <td>{I18n.t('batchHistory.model.powderyMildew')}</td>
              <td>{model.data.rating_avg_mildew}</td>
            </tr>
            <tr>
              <td>{I18n.t('batchHistory.model.budRot')}</td>
              <td>{model.data.rating_avg_bud_rot}</td>
            </tr>
            <tr>
              <td>{I18n.t('batchHistory.model.stretching')}</td>
              <td>{model.data.rating_avg_stretching}</td>
            </tr>
            <tr>
              <td>{I18n.t('batchHistory.model.airyBuds')}</td>
              <td>{model.data.rating_avg_airy_buds}</td>
            </tr>
            <ContentConcealer show={Boolean(model.plants_waste)}>
              <tr>
                <td>{I18n.t('batchHistory.model.wasteUponCreation')}</td>
                <td>{model.plants_waste} {model.uom}</td>
              </tr>
            </ContentConcealer>
            </tbody>
          </Table>
        </Col>
        <Col xs={6}>
          <h3>{I18n.t('batchHistory.model.harvestBatchUpdateDetails')}</h3>
          <Table striped className='plant-details'>
            <thead>
            <tr>
              <th>{I18n.t('plantHistory.field')}</th>
              <th>{I18n.t('plantHistory.valueAdjustment')}</th>
            </tr>
            </thead>
            <tbody>
            {delta.map((field) =>
              <tr key={field.name}>
                <td>{I18n.t(`batchHistory.model.${field.name}`)}</td>
                <td>{field.prev} -> {field.current}</td>
              </tr>
            )}
            <ContentConcealer show={model.waste_weight}>
              <tr>
                <td>{I18n.t('batchHistory.model.wasteReported')}</td>
                <td>{model.waste_weight} GR</td>
              </tr>
            </ContentConcealer>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Card>
  );
};

HarvestBatchPlantsAddedDetails.propTypes = {
  model: PropTypes.object.isRequired,
  delta: PropTypes.object.isRequired,
  integrationState: PropTypes.object.isRequired,
};

export default HarvestBatchPlantsAddedDetails;
