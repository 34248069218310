import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import find from 'lodash.find';
import omit from 'lodash.omit';
import get from 'lodash.get';
import {set} from 'lodash';
import orderBy from 'lodash.orderby';
import {PRODUCT_FORM, PRODUCT_MASTER_FORM} from '../../constants/forms';
import { MEDICAL, RECREATIONAL } from '../../constants/consumerTypes';
import * as pricingTypes from '../../constants/pricingTypes';
import {RETAIL, WHOLESALE} from '../../constants/saleTypes';
import * as uomTypes from '../../constants/uomTypes';
import {
  getCategories,
  getCategoryById,
  getCategoryManagementCategories,
  getSubCategoriesByCategoryId,
  isCategoryCartridgesPens,
  isCategoryCbdflower,
  isCategoryConcentrate,
  isCategoryFlower,
  isCategoryHarvest,
  isCategoryHempWaste,
  isCategoryInfusedEdible,
  isCategoryInfusedNonEdible,
  isCategoryMarijuana,
  isCategoryNonMedicated,
  isCategoryPlants,
  isNotPASubCategory,
  isPASubCategory,
  isSubCategoryClones,
  isSubCategoryMature,
  isSubCategorySeeds,
  isSubCategoryTissue,
  isSubCategoryTissueCulture,
  isSubCategoryWaste,
  isSubCategoryIngredient,
  isSubCategoryWholesaleSeeds,
  isSubCategoryWholesalePlantTissues,
  isSubCategoryWholesaleTissueCultures,
  isSubCategoryWholesaleClones
} from '../categorySelectors';
import * as itemNames from '../../constants/itemNames';
import * as dataNames from '../../constants/dataNames';
import {
  getSubCategoryById,
  isDisabledSubcategoryForOklahoma,
  isLeafWaHiddenSubcategory
} from '../subcategoriesSelectors';
import {getInventoryComplianceSettings, isMedicatedWeightAutoSuffix} from '../complianceSettingsSelectors';
import {isPaLeafIntegrator, isWaLeafIntegrator} from '../integration/leafSelectors';
import {
  getMetrcCategories,
  getMetrcCategoryMappings,
  isMetrcIntegrator,
  isOARRSReportingEnabled,
  getMetrcUoms
} from '../integration/metrcSelectors';
import {getIntegrationState} from '../integration/integrationSelectors';
import {getPricingGroupSorted} from '../pricingGroupsSelectors';
import {getPricingClasses} from '../pricingClassSelectors';
import {getPricingWeightsForDisplaying, getProduct, getProductTypeOptions} from '../productsSelector';
import {getUoms} from '../uomsSelectors';
import {getApplicationMode} from '../applicationModesSelectors';
import * as applicationModes from '../../constants/applicationModes';
import {isCanadaFacility} from '../facility/getFacilityCountry';
import {isOklahoma} from '../facility/getCurrentFacility';
import {hasSalesRulesEnabled} from '../salesSettingsSelectors';
import {getFacilityStrains} from '../facilityStrainsSelectors';
import {getOrganizationStrains} from '../organizationStrainsSelectors';
import * as UOMS from '../../constants/uoms';
import { getIntegrationMapping } from '../integration/commonSelectors';
import { userHasPermission } from '../usersSelectors';
import { flowerCombined } from '../../constants/subcategoryGroups';
import { isLeafPaConfigPackClosedLoopFacility } from '../facilitiesSelectors';
import {getTestResultDimensions} from '../testResultsSelectors';
import { isLeafPaItemMasterRequireNetWeightEnabled } from '../featureToggles';


const {uomFlowerConcentrate, excludeNonMedical} = uomTypes;

const getItemMaster = (state) => (state[itemNames.product] ? state[itemNames.product] : {});
const getChildItemMasters = (state) => (state[dataNames.childItemMasters] ? state[dataNames.childItemMasters] : []);
export const productFormValueSelector = new formValueSelector(PRODUCT_FORM); //eslint-disable-line
export const productMasterFormValueSelector = new formValueSelector(PRODUCT_MASTER_FORM); //eslint-disable-line

// Helper selector to determine if new form or old form is used
export const getForm = (state, props) => {
  // Check if the new form should be used
  if (get(props, 'form') === PRODUCT_MASTER_FORM) {
    return PRODUCT_MASTER_FORM;
  }
  return PRODUCT_FORM;
};

export const getCategoryId = (state, props) => {
  // Check if the new form should be used
  if (get(props, 'form') === PRODUCT_MASTER_FORM) {
    return productMasterFormValueSelector(state, 'category_id');
  }
  return productFormValueSelector(state, 'category_id');
};

export const getSubCategoryId = (state, props) => {
  if (get(props, 'form') === PRODUCT_MASTER_FORM) {
    return productMasterFormValueSelector(state, 'subcategory_id');
  }
  return productFormValueSelector(state, 'subcategory_id');
};

export const getLeaflyShare = (state, props) => {
  if (get(props, 'form') === PRODUCT_MASTER_FORM) {
    return productMasterFormValueSelector(state, 'sales_attributes.leafly_share');
  }
  return productFormValueSelector(state, 'sales_attributes.leafly_share');
};

export const getWeedmapsShare = (state, props) => {
  if (get(props, 'form') === PRODUCT_MASTER_FORM) {
    return productMasterFormValueSelector(state, 'sales_attributes.weedmaps_share');
  }
  return productFormValueSelector(state, 'sales_attributes.weedmaps_share');
};

export const getCurrentItemMaster = createSelector(
  [getItemMaster],
  (itemMaster) => itemMaster
);

export const getProductPricingGroups = createSelector(
  [getPricingGroupSorted, getCategoryId],
  (pricingGroups, categoryId) => {
    return pricingGroups.filter((pricingGroup) => pricingGroup.category_id === categoryId);
  }
);

const setupPASubCategories = (isPa, isSunsetFacility, subcategories) =>
  subcategories.filter((subcategory) => {
    return isPa || isSunsetFacility ? isPASubCategory(subcategory) : isNotPASubCategory(subcategory);
  });

const addDisableSubcategories = (state, subcategories) => {
  if (isOklahoma(state)) {
    return subcategories.map((subcategory) =>
      isDisabledSubcategoryForOklahoma(isOklahoma, subcategory.subcategory_code)
        ? { ...subcategory, disabled: 'yes' }
        : subcategory
    );
  }
  return subcategories;
};

export const getFormSubcategories = (state, props) => {
  const category_id = getCategoryId(state, props);
  const subcategories = getSubCategoriesByCategoryId(state, {category_id});
  const isPa = isPaLeafIntegrator(state);
  const isWa = isWaLeafIntegrator(state);
  const {isUtah} = getIntegrationState(state);
  const isSunsetFacility = isLeafPaConfigPackClosedLoopFacility(state);

  if(isUtah){ // Utah filtering happens on back.  One day all will.  :-)
    return subcategories;
  }

  const disableSubcategories = addDisableSubcategories(state, setupPASubCategories(isPa, isSunsetFacility, subcategories));
  if (isWa) {
    return addDisableSubcategories(state, isLeafWaHiddenSubcategory(isWa, subcategories));
  } else {
    return disableSubcategories.filter(
      (subcategory) =>
        (isOklahoma(state) && subcategory.subcategory_code === flowerCombined) ||
        subcategory.subcategory_code !== flowerCombined
    );
  }
};

export const getFormCategory = (state, props) => {
  const category_id = getCategoryId(state, props);
  return getCategoryById(state, { category_id });
};

export const getFormSubCategory = (state, props) => {
  const subcategory_id = getSubCategoryId(state, props);
  const subcategory = getSubCategoryById(state, { subcategory_id });
  return subcategory;
};

export const getFormDefaultUom = (state, props) => {
  // Check if the new form should be used
  if (get(props, 'form') === PRODUCT_MASTER_FORM) {
    return productMasterFormValueSelector(state, 'default_uom');
  }
  return productFormValueSelector(state, 'default_uom');
};

const ensureId = (v) => v.value || v;

export const getFacilityOptions = (options, values, fieldIndex) => {
  const usedFacilityIds = values.reduce((acc, value, valueIndex) => {
    if (valueIndex !== fieldIndex) {
      return acc.concat(value.facility_ids.map(ensureId));
    }
    return acc;
  }, []);

  return options.filter((option) => usedFacilityIds.indexOf(option.value) === -1);
};

export const getSelectedFacilityIds = (values) =>
  values.reduce((acc, value) => acc.concat(value.facility_ids.map(ensureId)), []);

const generatePriceListItem = (props = {}, extraProps = {}) => {
  const {
    id,
    is_default_for_org = 0,
    consumer_type = null,
    sale_type = RETAIL,
    default_price = '0.00',
    facility_ids = []
  } = props;

  return {
    ...props,
    id,
    is_default_for_org,
    consumer_type,
    sale_type,
    default_price,
    facility_ids,
    ...extraProps
  };
};

export const generateWeightPrices = (props = {}, categoryId, pricingWeights = [], pricingGroups = [], uom, form) => {
  const { weight_prices = [], pricing_group_id = null, sale_type } = props;
  if (sale_type === WHOLESALE) {
    return [];
  }
  const defaultPrices = {};
  const lowerCaseUom = uom ? uom.toLowerCase() : '';
  let uom_weights = pricingWeights
    .filter((weight) => {
      return get(weight, 'uom', '').toLowerCase() === lowerCaseUom;
    });
  const pricingWeightIds = pricingGroups.reduce((ids, group) => {
    if (
        group.pricing_weights &&
        (group.id === pricing_group_id || (!pricing_group_id && group.category_id === categoryId) || categoryId === 0)
      ) {
      return group.pricing_weights.reduce((ids, weight_price) => {
        if (pricing_group_id) {
          defaultPrices[weight_price.pricing_weight_id] = weight_price.default_price;
        }
        if (uom_weights.find(w => w.id === weight_price.pricing_weight_id)) {
          return ids.concat(uom_weights.find(w => w.id === weight_price.pricing_weight_id) && ids.indexOf(weight_price.pricing_weight_id) > 0 ? [] : weight_price.pricing_weight_id);
        }
        return ids;
      }, ids);
    }
    return ids;
  }, []);

  // Apply filters if pricing weight ids exist
  if (pricingWeightIds.length) {
    uom_weights = uom_weights.filter((weight) => pricingWeightIds.indexOf(weight.id) > -1);
  }
  return uom_weights.map((weight) => {
    const existing = weight_prices.find((wp) => wp.pricing_weight_id === weight.id) || {};
    return {
      name: weight.name,
      pricing_weight_id: weight.id,
      default_price: defaultPrices[weight.id],
      ...existing
    };
  });
};

export const generateUnitPriceListItem = (props = {}) => {
  const { inherit_pricing_group_updates = 1, inherit_pricing_class_updates = 1 } = props;

  return generatePriceListItem(props, {
    inherit_pricing_group_updates,
    inherit_pricing_class_updates,
    pricing_type: pricingTypes.UNIT
  });
};

export const generateWeightPriceListItem = (props = {}, categoryId = null, pricingWeights = [], pricingGroups = [], uom, form) => {
  const {
    pricing_group_id = null,
    pricing_class_id = null,
    inherit_pricing_group_updates = 1,
    inherit_pricing_class_updates = 1,
    is_non_taxable = false,
  } = props;

  return generatePriceListItem(props, {
    pricing_group_id,
    pricing_class_id,
    inherit_pricing_class_updates,
    inherit_pricing_group_updates,
    is_non_taxable,
    weight_prices: generateWeightPrices(props, categoryId, pricingWeights, pricingGroups, uom, form),
    pricing_type: pricingTypes.WEIGHT
  });
};

const getInheritedPricingClasses = (price_lists, pricingClasses) => {
  if (price_lists && pricingClasses) {
    // check to see if there is a pricing class value that can be inherited
    price_lists.forEach((plv, plk) => {
      const pricing_class_id = plv.pricing_class_id;
      if (plv.pricing_class_consumer_groups && plv.inherit_pricing_class_updates) {
        const pricing_class_consumer_groups_hash = {};
        plv.pricing_class_consumer_groups.forEach((cgv, cgk) => {
          pricing_class_consumer_groups_hash[cgv.consumer_group_id] = cgv;
        });

        price_lists[plk].pricing_class_consumer_groups = [];

        pricingClasses.forEach((v) => {
          if (v.active && v.consumer_groups) {
            v.consumer_groups.forEach((cv) => {
              if (cv.pricing_class_id === pricing_class_id) {
                let pricing_class = {
                  consumer_group_id: cv.consumer_group_id,
                  percentage_of_base: parseInt(cv.percentage_of_base)
                };

                if (pricing_class_consumer_groups_hash[cv.consumer_group_id]) {
                  pricing_class = Object.assign(
                    {},
                    pricing_class_consumer_groups_hash[cv.consumer_group_id],
                    pricing_class
                  );
                }
                price_lists[plk].pricing_class_consumer_groups.push(pricing_class);
              }
            });
          }
        });
      }
    });
    return price_lists;
  } else {
    return price_lists;
  }
};

export const getInitialPriceLists = (
  priceLists = [],
  pricing_type = pricingTypes.WEIGHT,
  categoryId = null,
  pricingWeights = [],
  pricingGroups = [],
  pricingClasses = [],
  inherit_pricing_group_updates,
  inherit_pricing_class_updates,
  is_non_taxable,
  uom,
  form,
  ) => {

  const price_lists = getInheritedPricingClasses(priceLists, pricingClasses);

  const getNonNullOrDefaultValue = (inputValue, defaultValue) => {
    return inputValue === null ? defaultValue : inputValue;
  };

  const getDefaultPrice = (type) => {
    const getPricesBy = {
      [MEDICAL]: (list) => list.sale_type === RETAIL && list.consumer_type === MEDICAL,
      [RECREATIONAL]: (list) => list.sale_type === RETAIL && list.consumer_type === RECREATIONAL,
      [WHOLESALE]: (list) => list.sale_type === WHOLESALE
    };
    if (!getPricesBy[type]) return '0.00';
    //if (!getPricesBy[type]) return null;
    const priceList = price_lists.find(getPricesBy[type]);
    return priceList ? priceList.default_price + '' : '0.00';
    //return priceList ? priceList.default_price + '' : null;
  };


  const generator =
    pricing_type === pricingTypes.UNIT || pricing_type === uomTypes.DISCRETE
      ? generateUnitPriceListItem
      : generateWeightPriceListItem;

  const initialShape = {
    organization: {
      [RETAIL]: {
        [MEDICAL]: generator(
          {
            consumer_type: MEDICAL,
            default_price: getDefaultPrice(MEDICAL),
            is_default_for_org: 1,
            inherit_pricing_group_updates: getNonNullOrDefaultValue(inherit_pricing_group_updates, 0),
            inherit_pricing_class_updates: getNonNullOrDefaultValue(inherit_pricing_class_updates, 0),
            is_non_taxable: getNonNullOrDefaultValue(is_non_taxable, 0),
          },
          categoryId,
          pricingWeights,
          pricingGroups,
          uom,
          form
        ),
        [RECREATIONAL]: generator(
          {
            consumer_type: RECREATIONAL,
            default_price: getDefaultPrice(RECREATIONAL),
            is_default_for_org: 1,
            inherit_pricing_group_updates: getNonNullOrDefaultValue(inherit_pricing_group_updates, 0),
            inherit_pricing_class_updates: getNonNullOrDefaultValue(inherit_pricing_class_updates, 0),
            is_non_taxable: getNonNullOrDefaultValue(is_non_taxable, 0),
          },
          categoryId,
          pricingWeights,
          pricingGroups,
          uom,
          form
        )
      },
      [WHOLESALE]: generator({
        sale_type: WHOLESALE,
        is_default_for_org: 1,
        default_price: getDefaultPrice(WHOLESALE),
        inherit_pricing_group_updates: getNonNullOrDefaultValue(inherit_pricing_group_updates, 0),
        inherit_pricing_class_updates: getNonNullOrDefaultValue(inherit_pricing_class_updates, 0),
        is_non_taxable: getNonNullOrDefaultValue(is_non_taxable, 0),
      },
      null,
      null,
      null,
      uom,
      form
      )
    },
    facilities: {
      [RETAIL]: {
        [MEDICAL]: [],
        [RECREATIONAL]: []
      },
      [WHOLESALE]: []
    }
  };

  const values = price_lists.reduce((shape, listItem) => {
    const { is_default_for_org, consumer_type, sale_type } = listItem;
    const internalListItem = generator(listItem, categoryId, pricingWeights, pricingGroups, uom, form);

    if (is_default_for_org) {
      if (sale_type === WHOLESALE) {
        shape.organization[sale_type] = internalListItem;
      } else if (sale_type === RETAIL) {
        shape.organization[sale_type][consumer_type] = internalListItem;
      }
    } else {
      if (sale_type === WHOLESALE) {
        shape.facilities[sale_type].push(internalListItem);
      } else if (sale_type === RETAIL) {
        shape.facilities[sale_type][consumer_type].push(internalListItem);
      }
    }

    return shape;
  }, initialShape);

  //Ensure at least one item for facilities
  if (!values.facilities[WHOLESALE].length) {

    values.facilities[WHOLESALE].push(
      generator({
        inherit_pricing_group_updates: getNonNullOrDefaultValue(inherit_pricing_group_updates, 0),
        inherit_pricing_class_updates: getNonNullOrDefaultValue(inherit_pricing_class_updates, 0),
        is_non_taxable: getNonNullOrDefaultValue(is_non_taxable, 0),
        sale_type: WHOLESALE
      }, null, null, null, uom, form)
    );
  }
  if (!values.facilities[RETAIL][MEDICAL].length) {
    values.facilities[RETAIL][MEDICAL].push(
      generator({
        inherit_pricing_group_updates: getNonNullOrDefaultValue(inherit_pricing_group_updates, 0),
        inherit_pricing_class_updates: getNonNullOrDefaultValue(inherit_pricing_class_updates, 0),
        is_non_taxable: getNonNullOrDefaultValue(is_non_taxable, 0),
        consumer_type: MEDICAL
      },
      categoryId,
      pricingWeights,
      pricingGroups,
      uom, form)
    );
  }
  if (!values.facilities[RETAIL][RECREATIONAL].length) {

    values.facilities[RETAIL][RECREATIONAL].push(
      generator({
        inherit_pricing_group_updates: getNonNullOrDefaultValue(inherit_pricing_group_updates, 0),
        inherit_pricing_class_updates: getNonNullOrDefaultValue(inherit_pricing_class_updates, 0),
        is_non_taxable: getNonNullOrDefaultValue(is_non_taxable, 0),
        consumer_type: RECREATIONAL
      },
      categoryId,
      pricingWeights,
      pricingGroups,
      uom,
      form)
    );
  }
  return values;
};

export const getCreateProductInitialValues = createSelector(
  [
    getCategoryId,
    getCategoryManagementCategories,
    getPricingWeightsForDisplaying,
    getPricingGroupSorted,
    getInventoryComplianceSettings,
    isMetrcIntegrator,
    getUoms,
    getProductTypeOptions,
    getForm,
  ],
  (selectedCategoryId, categories, pricingWeights, pricingGroups, settings, hasMetrc, uoms, productTypes, form) => {
    if (!pricingWeights.length || !settings) {
      return;
    }
    const firstCategoryOption = productTypes[0];
    const defaultUOM = uoms.find((uom) => uom.uom_code === 'GR');
    const categoryId = selectedCategoryId ? selectedCategoryId : firstCategoryOption ? firstCategoryOption.id : 1;
    const category = categories.find((category) => category.id === selectedCategoryId);
    return {
      active: true,
      category_id: categoryId,
      tags: [],
      vendors: [],
      hasMetrc,
      requireMedicatedNetWeight: false,
      requireProductNetWeight: false,
      requireExpirationDate: settings.require_on_hand_product_expire_date,
      sales_attributes: {
        pricing_type: 'weight', // NOTE, this corresponds to the defaultUOM
        weedmaps_share: '0'
      },
      online_availability: getInitialOnlineAvailability(),
      exclude_product_from_menu: false,
      test_results_source: 'item_master',
      test_results_value_type: 'percentage',
      prices: getInitialPriceLists([], pricingTypes.WEIGHT, categoryId, pricingWeights, pricingGroups, null, null, null, null, defaultUOM.uom_code, form),
      net_weight_uom: defaultUOM ? defaultUOM.uom_code : null,
      unit_weight_uom: defaultUOM ? defaultUOM.uom_code : null,
      medicated_weight_uom: 'MG',
      servings_uom: 'mg',
      medicated_volume_uom: 'ML',
      medicated_volume: undefined,
      afterSubmit: 'redirect',
      product_images: [{}],
      inventory_attributes: {
        lot_tracked: category ? (isCategoryNonMedicated(category) ? 0 : 1) : 1
      },
      default_uom: defaultUOM.uom_code,
      uom_type: defaultUOM.uom_type
    };
  }
);

const getInventoryAttributes = (attributes) => {
  if (!attributes) {
    return {};
  }
  return Object.assign(attributes, {
    multipack_enabled: Boolean(attributes.multipack_enabled),
    require_individual_skus: Boolean(attributes.require_individual_skus)
  });
};

export const getInitialOnlineAvailability = (attribute_lists = []) => {
  const initialShape = {
    organization: { is_default_for_org: 1, available_online: 1, facility_ids: [] },
    facilities: []
  };

  const values = attribute_lists.reduce((shape, list) => {
    if (list.is_default_for_org) {
      shape.organization = list;
    } else {
      shape.facilities.push(list);
    }
    return shape;
  }, initialShape);

  if (!values.facilities.length) {
    values.facilities.push({ is_default_for_org: 0, available_online: 1, facility_ids: [] });
  }

  return values;
};

export const getMetrcCategoryFromProductSubcategoryId = createSelector(
  [getProduct, getMetrcCategories, getMetrcCategoryMappings],
  (product, metrcCategories, mappings) => {
    const subcategoryId = product.subcategory_id;
    const mapping = mappings.find((mapping) => mapping.subcategory_id === subcategoryId);
    return mapping ? metrcCategories.find((category) => category.id === mapping.metrc_item_category_id) : undefined;
  }
);

// Fields in the product form that are required (used for metrc only as of now)
export const getRequiredFieldsFromSubCategory = (metrcSubCategory) => {
  if (metrcSubCategory && metrcSubCategory.requires_unit_thc_percent) {
    metrcSubCategory.requires_unit_d9_thca_content = metrcSubCategory.requires_unit_thc_percent;
  }
  return omit(metrcSubCategory, ['created_at', 'facility_type', 'id', 'metrc_state', 'name']);
};

export const getModifyProductInitialValues = createSelector(
  [
    getProduct,
    getPricingWeightsForDisplaying,
    getPricingGroupSorted,
    getPricingClasses,
    isMetrcIntegrator,
    getMetrcCategories,
    getMetrcCategoryMappings,
    getCategories,
    getIntegrationMapping,
    getChildItemMasters,
    getMetrcCategoryFromProductSubcategoryId,
    getTestResultDimensions,
    getForm,
    isMedicatedWeightAutoSuffix
  ],
  (
    product,
    pricingWeights,
    pricingGroups,
    pricingClasses,
    hasMetrc,
    metrcCategories,
    metrcMappings,
    categories,
    integrationMapping,
    childItemMasters,
    metrcSubCategory,
    testResultDimensions,
    form,
    isMedicatedWeightAutoSuffix
  ) => {
    if (!product.name) {
      return {};
    }

    const category = categories.find((c) => c.id === product.category_id);
    const ssc = ((category && category.subcategories) || []).find((ssc) => ssc.id === Number(product.subcategory_id)); // item subcategory object
    const metrcMapping =
      metrcMappings &&
      product.subcategory_id &&
      metrcMappings.find((mapping) => mapping.subcategory_id === product.subcategory_id);
    const metrcCategory =
      metrcMapping &&
      metrcCategories &&
      metrcCategories.find((category) => category.id === metrcMapping.metrc_item_category_id);
    if (!Object.keys(product).length) {
      return {};
    }
    if (product) {
      if (product.sales_attributes !== undefined) {
        if (parseInt(product.is_sales_item) === 1 && product.sales_attributes.pricing_type === '') {
          product.sales_attributes.pricing_type = 'unit'; // Defaulting to unit if not set.  Should be validation there instead I think
        }
      }
    }

    if (!product.purchasing_attributes) {
      product.purchasing_attributes = {};
    }

    const buffer = get(product, 'purchasing_attributes.buffer', null);
    if (buffer !== null) {
      if (get(product, 'default_uom') === 'EA') {
        product.purchasing_attributes.buffer = Math.round(buffer);
      } else {
        product.purchasing_attributes.buffer = isNaN(parseFloat(buffer)) || !parseFloat(buffer) ? null : buffer;
      }
    }
    const priceLists = (product && product.pricing_details && product.pricing_details.price_lists) || [];
    let brand = null;
    if (product.brand && (product.brand.id && product.brand.name)) {
      brand = {
        id: product.brand.id,
        name: product.brand.name
      };
    }

    const prices = getInitialPriceLists(
      priceLists,
      pricingTypes.WEIGHT,
      category && category.id,
      pricingWeights,
      pricingGroups,
      pricingClasses,
      null,
      null,
      null,
      product.default_uom,
      form
    );

    // name_edit. This contains the editable part of the product name (i.e. without brand name and/or med weight suffix)
    let name_edit = product.name;

    if (form === PRODUCT_MASTER_FORM) {

      // if 'medicated_weight_auto_suffix' enabled break-down 'name' into the editable part ('EA' products only)
      // otherwise just use product.name as editable value
      if (isMedicatedWeightAutoSuffix && get(product, 'default_uom') === 'EA') {
        // Verify if the product name follows the weight suffix standard (i.e. colon, space, number, 'g' or 'mg') (it should)
        if (name_edit.match(/(: )[0-9.]+(mg|g)$/)) {
          // Strip everything after last :
          name_edit = name_edit.substring(0, name_edit.lastIndexOf(':'));
        }
      }

      // if 'append_brand_to_item_master_name' is enabled remove it, so that only the 'real' product name is editable
      if (isMedicatedWeightAutoSuffix && get(product, 'brand.name')) {
        name_edit = name_edit.replace(' - ' + get(product, 'brand.name'), '');
      }

      // pricing multiplier
      set(prices, 'pricing_multiplier', get(prices, 'organization.retail.medical.pricing_multiplier_id'));
      // organization level prices
      const orgRetailMedPrices = get(prices, 'organization.retail.medical.weight_prices', []).filter((weight_price) => {
        return get(weight_price, 'default_price') !== undefined;
      });
      set(prices, 'organization.retail.medical.weight_prices', orgRetailMedPrices);

      const orgRetailRecPrices = get(prices, 'organization.retail.recreational.weight_prices', []).filter((weight_price) => {
        return get(weight_price, 'default_price') !== undefined;
      });
      set(prices, 'organization.retail.recreational.weight_prices', orgRetailRecPrices);

      // facility level prices
      const facilityRetailMedPrices = get(prices, 'facilities.retail.medical', []).map((facility, index) => {
        const weight_prices = get(facility, 'weight_prices', []).filter((weight_price) => {
          return get(weight_price, 'default_price') !== undefined;
        });
        return { ...facility, weight_prices };
      });
      set(prices, 'facilities.retail.medical', facilityRetailMedPrices);

      const facilityRetailRecPrices = get(prices, 'facilities.retail.recreational', []).map((facility, index) => {
        const weight_prices = get(facility, 'weight_prices', []).filter((weight_price) => {
          return get(weight_price, 'default_price') !== undefined;
        });
        return { ...facility, weight_prices };
      });
      set(prices, 'facilities.retail.recreational', facilityRetailRecPrices);
    }

    const getInternalIdentifier = () => {
      const child_ids = childItemMasters.map((im) => im.id);
      const currentItemMastersIds = [product.id].concat(child_ids);
      return get(
        integrationMapping.find(
          (mapping) => currentItemMastersIds.indexOf(parseInt(mapping.internal_identifier)) !== -1
        ),
        'external_identifier'
      );
    };

    const requiredFields = getRequiredFieldsFromSubCategory(metrcSubCategory);

    // Map old 'available_online' field to new 'exclude_product_from_menu' checkbox
    const attribute_lists = product && product.attribute_lists ? product.attribute_lists : [];
    const default_for_org = attribute_lists.find((attr_list) => attr_list.is_default_for_org === 1) || {};
    const exclude_product_from_menu = get(default_for_org, 'available_online', 1) === 0 ? 1 : 0;

    const potencyList = get(testResultDimensions, 'cannabinoid_potency', []).map(testResultDimension => testResultDimension.key);
    const terpeneList = get(testResultDimensions, 'terpene_profile', []).map(testResultDimension => testResultDimension.key);
    const potency_test_results = getTestResults('potency_test_results', potencyList, product);
    const terpene_test_results = getTestResults('terpene_test_results', terpeneList, product);

    return {
      ...product,
      name_edit,
      active: Boolean(product.active),
      hasMetrc,
      brand: brand,
      requireMedicatedNetWeight: Boolean(metrcCategory && metrcCategory.requires_unit_thc_content),
      requireProductNetWeight: Boolean(metrcCategory && metrcCategory.requires_unit_weight),
      is_purchasing_item: Boolean(product.is_purchasing_item),
      is_inventory_item: Boolean(product.is_inventory_item),
      is_sales_item: !isCategoryHarvest(category) && !(isCategoryPlants(category) && isSubCategoryWaste(ssc)),
      is_produced_item: Boolean(product.is_produced_item),
      requires_refrigeration: Boolean(product.requires_refrigeration),
      inventory_attributes: getInventoryAttributes(product.inventory_attributes),
      prices,
      required_fields: requiredFields,
      online_availability: getInitialOnlineAvailability(product.attribute_lists),
      exclude_product_from_menu,
      test_results_source: product.test_results_source ? product.test_results_source : 'item_master',
      test_results_value_type: product.test_results_value_type ? product.test_results_value_type : 'percentage',
      potency_test_results,
      terpene_test_results,
      //medicated_weight_uom: 'MG',
      medicated_volume_uom: 'ML',
      net_weight: product.net_weight ? product.net_weight.toFixed(2) : undefined,
      medicated_weight: product.medicated_weight ? parseFloat(Number(product.medicated_weight)) : undefined,
      medicated_volume: product.medicated_volume ? parseFloat(Number(product.medicated_volume)) : undefined,
      net_volume: product.net_volume ? parseFloat(Number(product.net_volume)) : undefined,
      unit_weight: product.unit_weight ? parseFloat(product.unit_weight) : undefined,
      net_weight_uom: product.net_weight_uom ? product.net_weight_uom : 'GR',
      unit_weight_uom: product.unit_weight_uom ? product.unit_weight_uom : 'GR',
      afterSubmit: 'redirect',
      product_identifier: getInternalIdentifier()
    };
  }
);

const getTestResults = (field, list, product) => {

  // Get values
  const values = get(product, field, '[]');
  if (values !== '[]') {
    return JSON.parse(values);
  }

  // If no test results as JSON object, try to construct from legacy fields
  const obj = [];
  list.forEach((name) => {
    const percent_value = get(product, `${name}_percent`);
    const weight_value = get(product, `${name}_weight`);
    if (percent_value && percent_value !== '0.00') {
      obj.push({name, value: percent_value, uom: 'percentage'});
    }
    else if (weight_value && weight_value !== '0.00') {
      obj.push({name, value: weight_value, uom: 'mg_g'});
    }
  });
  return obj;
};

const flattenPriceLists = (priceLists) => [
  priceLists.organization[RETAIL][MEDICAL],
  priceLists.organization[RETAIL][RECREATIONAL],
  priceLists.organization[WHOLESALE],
  ...priceLists.facilities[RETAIL][MEDICAL],
  ...priceLists.facilities[RETAIL][RECREATIONAL],
  ...priceLists.facilities[WHOLESALE]
];

const preparePriceLists = (priceLists) =>
  flattenPriceLists(priceLists)
    .filter((list) => {
      return list.is_default_for_org || list.facility_ids.length > 0 || list.id;
    })
    .map((list) => {
      return {
        ...list,
        pricing_multiplier_id: list.sale_type !== 'wholesale' ? priceLists.pricing_multiplier : null,
        facility_ids: list.facility_ids.map(ensureId),
        weight_prices: (list.weight_prices || [])
          .filter((weightPrice) => weightPrice.default_price > 0)
          .map(({ name, ...weightPrice }) => weightPrice)
      };
    });

const prepareAttributeLists = (availability) => {
  const result = [get(availability, 'organization', []), ...get(availability, 'facilities', [])];

  const skipNullValues = (list) => 'available_online' in list && list.available_online !== null;
  const mapListOfAvailabilities = (list) => ({
    ...list,
    facility_ids: get(list, 'facility_ids', []).map(ensureId)
  });

  return result.filter(skipNullValues).map(mapListOfAvailabilities);
};


export const getSaveProductPayload = (formValues, scenario, categories, subcategories, formName = '') => {
  // Map new UI only field 'exclude_product_from_menu' to old 'online_availability' field if new form is used
  if (formName === PRODUCT_MASTER_FORM) {
    const available_online = get(formValues, 'exclude_product_from_menu', 0) ? 0 : 1;
    set(formValues, 'online_availability.organization.available_online', available_online);
  }
  const values = {
    ...omit(
      formValues,
      ['required_fields', 'has_metrc', 'has_items', 'prices', 'online_availability']
    ),
    is_draft: formValues.action && formValues.action === 'saveProgress' ? true : false,
    brand: formValues.brand ? formValues.brand : null,
    strain_id: formValues.strain_id ? formValues.strain_id : null,
    product_images: formValues.product_images ? formValues.product_images.filter((image) => image !== null) : [],
    dominance_id: formValues.dominance_id ? formValues.dominance_id : undefined,
    pricing_details: {
      price_lists: formValues.prices ? preparePriceLists(formValues.prices) : []
    },
    attribute_lists: formValues.online_availability ? prepareAttributeLists(formValues.online_availability) : []
  };

  if (scenario === 'create') {
    if (!values.category_id) {
      values.category_id = 1;
    }

    const ct = categories.find((ct) => ct.id === values.category_id);
    const ssc = subcategories.find((ssc) => ssc.id === Number(values.subcategory_id));

    values.active = true;
    values.is_sales_item = !isCategoryHarvest(ct) && !(isCategoryPlants(ct) && isSubCategoryWaste(ssc));
    values.is_inventory_item = !isCategoryNonMedicated(ct) ? true : formValues.is_inventory_item;
    values.is_manufactured_item = formValues.is_manufactured_item;
    values.test_results_source = formValues.test_results_source;
    values.test_results_value_type = formValues.test_results_value_type;
    values.potency_test_results = formValues.potency_test_results;
    values.terpene_test_results = formValues.terpene_test_results;

    values.serving_size = isCategoryPlants(ct) || isCategoryNonMedicated(ct) ? '' : formValues.serving_size;
    values.servings_per_container =
      isCategoryPlants(ct) || isCategoryNonMedicated(ct) ? '' : formValues.servings_per_container;
    values.servings_uom =
      isCategoryPlants(ct) || isCategoryNonMedicated(ct) ? '' : formValues.servings_uom;
    values.solvent =
      isCategoryFlower(ct) || isCategoryPlants(ct) || isCategoryNonMedicated(ct) || isCategoryMarijuana(ct)
        ? ''
        : formValues.solvent;
    values.pieces_per_unit =
      isCategoryPlants(ct) || isCategoryInfusedEdible(ct) || isCategoryInfusedNonEdible(ct)
        ? formValues.pieces_per_unit
        : '';
    values.dilution_method = isCategoryCartridgesPens(ct) ? formValues.dilution_method : '';
  }

  if (values.inventory_attributes && values.inventory_attributes.is_prepack) {
    values.inventory_attributes.lot_tracked = true;
  }

  // If empty string it is coerced at db to 0.00 which is then a string which then fails validation on modify
  if (values.purchasing_attributes) {
    const buffer = get(values, 'purchasing_attributes.buffer', null);
    values.purchasing_attributes.buffer = isNaN(parseFloat(buffer)) ? null : buffer;
  }

  return values;
};

/***
 * Tests the sub category of the current product in redux against rules based on integration, country, and if sales rules are enabled.
 * Intended to be called through subCategoryMapErrors really for syntax only.
 */
export const testProductSubCategoryMapping = createSelector(
  [
    getCategoryManagementCategories,
    getCategoryId,
    getSubCategoryId,
    getApplicationMode,
    isCanadaFacility,
    hasSalesRulesEnabled,
    getIntegrationState,
    getLeaflyShare,
    getWeedmapsShare,
    isOklahoma,
    isOARRSReportingEnabled
  ],
  (
    categories,
    productCategoryId,
    productSubCategoryId,
    applicationMode,
    isCanadaFacility,
    hasSalesRulesEnabled,
    integrationState,
    leaflyShare,
    weedmapsShare,
    isOklahoma,
    isOARRSReportingEnabled
  ) => {
    const excludes = {
      categoryIds: [6, 10],
      subcategoryIds: []
    };
    if (excludes.categoryIds.indexOf(productCategoryId) !== -1) return false;
    if (excludes.subcategoryIds.indexOf(productSubCategoryId) !== -1) return false;

    const category = categories.find((category) => category.id === productCategoryId);
    if (!category) return false; // Until we actually have values we cant have errors
    const subCategory = category.subcategories.find((sub) => sub.id === productSubCategoryId);
    if (!subCategory) return false; // Until we actually have values we cant have errors
    subCategory.category_code = category.category_code;
    let testMap = {
      leaf: {
        applicationModes: [],
        preCondition: (modes) => modes.indexOf(applicationMode) !== -1 && !integrationState['isWaLeaf'],
        field: 'leaf_category_id',
        test: (sub, field) => !isNaN(parseInt(sub[field])),
        friendlyType: 'Leaf Integration'
      },
      ohmetrc: {
        applicationModes: ['OH_METRC'],
        preCondition: () => isOARRSReportingEnabled,
        field: 'ohmetrc_category_id',
        test: (sub, field) => isCategoryPlants(category) || !isNaN(parseInt(sub[field])),
        friendlyType: 'Metrc Sales'
      },
      metrc: {
        applicationModes: [],
        preCondition: (modes) => modes.indexOf(applicationMode) !== -1,
        field: 'metrc_item_category_id',
        test: (sub, field) => !isNaN(parseInt(sub[field])),
        friendlyType: 'Metrc Integration'
      },
      cure: {
        applicationModes: [],
        preCondition: (modes) => modes.indexOf(applicationMode) !== -1,
        field: 'cure_order_type_id',
        test: (sub, field) => isCategoryPlants(category) || !isNaN(parseInt(sub[field])),
        friendlyType: 'Cure Integration'
      },
      leafly: {
        applicationModes: [],
        preCondition: (modes, integrationState) =>
          (leaflyShare && leaflyShare == 1 && integrationState && integrationState['isLeafly']) || false,
        field: 'leafly_category_id',
        test: (sub, field) => !isNaN(parseInt(sub[field])),
        friendlyType: 'Leafly Integration'
      },
      weedmaps: {
        applicationModes: [],
        preCondition: (modes, integrationState) =>
          (weedmapsShare && weedmapsShare == 1 && integrationState && integrationState['isWeedmaps']) || false,
        field: 'weedmaps_category_id',
        test: (sub, field) => !isNaN(parseInt(sub[field])),
        friendlyType: 'Weedmaps Integration'
      },
      canada: {
        preCondition: () => isCanadaFacility,
        field: 'canada_category_id',
        test: (sub, field) => !isNaN(parseInt(sub[field])),
        friendlyType: 'Reporting Mapping'
      },
      sales_rules: {
        preCondition: () => hasSalesRulesEnabled,
        field: 'sales_limit_category_key',
        test: (sub, field) => typeof sub[field] === 'string' && sub[field] !== '',
        friendlyType: 'Sales Rules'
      },
      isolocity: {
        applicationModes: [],
        preCondition: (modes, integrationState) => (integrationState && integrationState['isIsolocity']) || false,
        field: 'specification_id',
        test: (sub, field) => isCategoryPlants(category) || isCategoryHarvest(category) || !isNaN(parseInt(sub[field])),
        friendlyType: 'Isolocity Integration'
      },
      cca: {
        applicationModes: [],
        preCondition: (modes, integrationState) => (integrationState && integrationState['isCca']) || false,
        field: 'cca_category_id',
        test: (sub, field) => !isNaN(parseInt(sub[field])),
        friendlyType: 'CCA'
      }
    };

    testMap = Object.keys(applicationModes).reduce((acc, mode) => {
      Object.keys(acc).forEach((key) => {
        if (!acc[key].applicationModes) return true;
        if (applicationModes[mode].toLowerCase().indexOf(key) !== -1) //eslint-disable-line
          acc[key].applicationModes.push(applicationModes[mode]); //eslint-disable-line
      });
      return acc;
    }, testMap);

    return Object.keys(testMap).reduce((acc, type) => {
      if (!testMap[type].preCondition(testMap[type].applicationModes, integrationState)) return acc;
      if (!testMap[type].test(subCategory, testMap[type].field)) {
        acc.push({
          type,
          friendlyType: testMap[type].friendlyType,
          field_in_error: testMap[type].field,
          subCategory
        });
      }
      return acc;
    }, []);
  }
);

export const subCategoryMapErrors = createSelector(
  [testProductSubCategoryMapping],
  (mapping) => {
    return mapping.length === 0 ? false : mapping;
  }
);

// NOTE, this is similar to prepareUomOptions in uomSelectors.js. Consider refactor.
export const prepareUomOptions = (uomOptions, sc, subcategoryId, modes, metrcCategories, metrcCategoryMappings, metrcUomOptions, form) => {
  if (!sc) {
    return [];
  }

  const ssc = (sc.subcategories && sc.subcategories.find((sc) => sc.id === subcategoryId)) || {};

  if (isCategoryNonMedicated(sc) &&
      (
        isSubCategoryWholesaleClones(ssc) ||
        isSubCategoryWholesaleSeeds(ssc) ||
        isSubCategoryWholesalePlantTissues(ssc) ||
        isSubCategoryWholesaleTissueCultures(ssc)
      )
    ) {
    return uomOptions.filter((uom) => uom.uom_code === UOMS.EA);
  }

  if (modes.isMetrc && subcategoryId && metrcCategories && metrcCategoryMappings.length > 0) {
    const subCategoryMapping = metrcCategoryMappings.find((map) => subcategoryId === map.subcategory_id);
    if (subCategoryMapping) {
      const metrcCategory = metrcCategories.find((category) => category.id === subCategoryMapping.metrc_item_category_id);
      // NOTE: make sure to get metrcCategory from metrcCategories that fetched after fetching categoryMappings
      if (metrcUomOptions && metrcCategory && (isCategoryFlower(sc) || isCategoryConcentrate(sc))) {
        return metrcUomOptions.filter((uom) => uom.uom_type === metrcCategory.uom_type);
      }
      // Metrc Flower and Concentrate can have the same options as regular Flower and Concentrate
      // The other categories still have to follow the restrictions below
      if (!isCategoryFlower(sc) && !isCategoryConcentrate(sc)) {
        if (metrcCategory && metrcCategory.uom_type === 'weight') {
          return uomOptions.filter((uom) => uom.uom_code === UOMS.GR);
        } else if (metrcCategory && metrcCategory.uom_type === 'discrete') {
          return uomOptions.filter((uom) => uom.uom_type === uomTypes.DISCRETE);
        }
      }
    }
  }

  if (isCategoryNonMedicated(sc)) {
    if (isSubCategoryIngredient(ssc)) {
      return uomOptions.filter((uom) => ['weight', 'discrete', 'volume'].indexOf(uom.uom_type) != -1);
    } else {
      return uomOptions.filter(
        (uom) =>
          ['weight', 'discrete', 'volume'].indexOf(uom.uom_type) != -1 && excludeNonMedical.indexOf(uom.uom_code) == -1
      );
    }
  }

  if (isCategoryPlants(sc) && isSubCategorySeeds(ssc)) {
    if (modes.isWaLeaf) {
      return uomOptions.filter((uom) => uom.uom_code === UOMS.EA);
    } else {
      //Allow GR/EA for Seeds
      return uomOptions.filter((uom) => uom.uom_code === UOMS.GR || uom.uom_type === uomTypes.DISCRETE);
    }
  }

  if (isCategoryPlants(sc) &&
    (isSubCategoryMature(ssc) || isSubCategoryTissue(ssc) || isSubCategoryTissueCulture(ssc))
  ) {
    return uomOptions.filter((uom) => uom.uom_code === UOMS.EA);
  }

  if (isCategoryPlants(sc) && isSubCategoryWaste(ssc)) {
    //Allow GR only for Waste
    return uomOptions.filter((uom) => uom.uom_code === UOMS.GR);
  }

  if (isCategoryFlower(sc) || isCategoryConcentrate(sc)) {
    if (modes.isNormal) {
      if (modes.isHemp) {
        return isCategoryFlower(sc)
          ? uomOptions.filter((uom) => uomFlowerConcentrate.none.default.indexOf(uom.uom_code) > -1)
          // Excluding TBSP and TSP because of MJP-26975
          : uomOptions.filter((uom) => uom.uom_type !== 'discrete' && uom.uom_code !== 'TBSP' && uom.uom_code !== 'TSP');
      }
    }
    // If PRODUCT_MASTER_FORM is used allow EA for Flower and Concentrate products
    return form === PRODUCT_MASTER_FORM
      ? uomOptions.filter((uom) => uom.uom_code === UOMS.GR || uom.uom_code === UOMS.EA)
      : uomOptions.filter((uom) => uom.uom_code === UOMS.GR);
  }

  if (isCategoryHarvest(sc) || isCategoryMarijuana(sc) || isCategoryHempWaste(sc)) {
    return uomOptions.filter((uom) => uom.uom_code === UOMS.GR);
  }
  if (isCategoryCbdflower(sc)) {
    // If PRODUCT_MASTER_FORM is used allow EA for Flower and Concentrate products
    return form === PRODUCT_MASTER_FORM
      ? uomOptions.filter((uom) => uom.uom_code === UOMS.GR || uom.uom_code === UOMS.EA)
      : uomOptions.filter((uom) => uom.uom_code === UOMS.GR);
  }

  if (
    isCategoryInfusedEdible(sc) ||
    isCategoryInfusedNonEdible(sc) ||
    (isCategoryPlants(sc) && isSubCategoryClones(ssc))
  ) {
    return uomOptions.filter((uom) => uom.uom_type === uomTypes.DISCRETE);
  }

  if (
    isCategoryCartridgesPens(sc)
  ) {
    // If PRODUCT_MASTER_FORM is used allow MG & EA for Concentrate products
    return form === PRODUCT_MASTER_FORM
      ? uomOptions.filter((uom) => uom.uom_code === UOMS.MG || uom.uom_code === UOMS.GR || uom.uom_code === UOMS.EA)
      : uomOptions.filter((uom) => uom.uom_code === UOMS.EA);
  }



  return [];
};

export const getUomOptions = createSelector(
  [
    getUoms,
    getFormCategory,
    getSubCategoryId,
    getIntegrationState,
    getMetrcCategories,
    getMetrcCategoryMappings,
    getMetrcUoms,
    getForm,
  ],
  prepareUomOptions
);

/***
 * if facility strain is present, overwrite dominance with that facility level dominance if present
 */
export const getStrains = createSelector(
  [getOrganizationStrains, getFacilityStrains],
  (orgStrains, facilityStrains) => {
    if (!orgStrains || !facilityStrains || orgStrains.length === 0 || facilityStrains.length === 0) return [];
    return orderBy(
      orgStrains.map((strain) => {
        const facilityStrain = facilityStrains.find(
          (fs) =>
            (fs.global_strain_id && fs.global_strain_id === strain.global_strain_id) ||
            (fs.global_strain_id === null && fs.id === strain.id)
        );
        return Object.assign(
          {},
          strain,
          facilityStrain ? { dominance: facilityStrain.dominance, phenotypes: facilityStrain.phenotypes } : {}
        );
      }),
      'strain_name',
      'asc'
    );
  }
);

export const isMedicatedWeightRequired = createSelector(
  [getFormCategory, getFormDefaultUom, getIntegrationState, isLeafPaItemMasterRequireNetWeightEnabled],
  (selectedCategory, default_uom, { isBiotrack, isPaLeaf }, isLeafPaItemMasterRequireNetWeightEnabled) => {

    return (
      // For PA All EA products require Medicated Weight. (Exclude PLANTS, FLOWER, CBD_FLOWER and CONCENTRATE (bulk))
      (isPaLeaf && isLeafPaItemMasterRequireNetWeightEnabled &&
        (
          (isCategoryFlower(selectedCategory) && default_uom === 'EA') ||
          (isCategoryConcentrate(selectedCategory) && default_uom === 'EA') ||
          (isCategoryCbdflower(selectedCategory) && default_uom === 'EA') ||
          isCategoryInfusedEdible(selectedCategory) ||
          isCategoryInfusedNonEdible(selectedCategory) ||
          isCategoryCartridgesPens(selectedCategory)
        )
      )
      ||
      (isBiotrack &&
        (isCategoryInfusedEdible(selectedCategory) ||
          isCategoryInfusedNonEdible(selectedCategory) ||
          isCategoryCartridgesPens(selectedCategory))
      )
    );
  }
);

export const isProductNetWeightRequired = createSelector(
  [getFormCategory, getSubCategoryId, isOklahoma],
  (category, subcategoryId, isOklahoma) => {
    if (isOklahoma) {
      if (isCategoryCartridgesPens(category) || isCategoryInfusedEdible(category)) {
        return true;
      }

      if (isCategoryInfusedNonEdible(category)) {
        const subCategory = find(category.subcategories, ['id', subcategoryId]);
        return get(subCategory, 'subcategory_code') === 'SUPPOSITORY';
      }
    }

    return false;
  }
);

export const isPrepackWithInventory = createSelector(
  [getItemMaster, getChildItemMasters],
  (itemMaster, childItemMasters) => {
    if (!itemMaster.inventory_attributes || !itemMaster.inventory_attributes.is_prepack) return false;
    return childItemMasters.reduce((acc, childItemMaster) => {
      if (acc) return acc;
      if (childItemMaster.items && childItemMaster.items.length) return true;
      return acc;
    }, false);
  }
);

export const isStrainRequired = createSelector(
  [getCategories, getFormSubcategories, getCategoryId, getSubCategoryId, isWaLeafIntegrator],
  (categories, subcategories, categoryId, subcategoryId, isWaLeafIntegrator) => {
    if (!isWaLeafIntegrator) {
      return false;
    }

    const category = categories.find((category) => category.id === categoryId);
    const subcategory = subcategories.find((subcategory) => subcategory.id === subcategoryId);
    return (
      isCategoryPlants(category) &&
      (isSubCategorySeeds(subcategory) ||
        isSubCategoryTissue(subcategory) ||
        isSubCategoryTissueCulture(subcategory) ||
        isSubCategoryClones(subcategory))
    );
  }
);

export const canUserManageReservationAndIsAllowedInComplianceSettings = createSelector(
  [getInventoryComplianceSettings, userHasPermission],
  (settings, userCan) => {
    return settings.enable_reservations === '1' && userCan;
  }
);
