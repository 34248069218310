import {createSelector} from 'reselect';
import {formValueSelector} from 'redux-form';
import {USER_FORM} from '../../constants/forms';
import {getBiotrackFacilityIds} from '../integration/biotrackSelectors';

const selector = formValueSelector(USER_FORM);
export const getFacilityRoles = state => selector(state, 'facilityRoles') || [];

export const userHasBiotrackFacilities = createSelector(
  [getFacilityRoles, getBiotrackFacilityIds],
  (userFacilityRoles, btFacilityIds) => {
    return userFacilityRoles.some(
      role => role.role_ids.length > 0 && btFacilityIds.indexOf(role.facility_id) > -1
    );
  }
);
