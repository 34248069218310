import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import {/*formValueSelector, */ reduxForm, Field} from 'redux-form';
import {Button} from 'react-bootstrap';
import {getOrderMetaInitialValues} from '../../selectors/ordersSelectors';
import {getCustomerAddressesForSelect} from '../../selectors/customersSelectors';
import {driversFullNameFormation} from '../../selectors/driversSelectors';
import {getVehicles} from '../../selectors/vehiclesSelectors';
import {includeAddressOnAllReceipts} from '../../selectors/salesSettingsSelectors';
import ReactSelectInput from '../common/form/ReactSelectInput';
import TextAreaInput from '../common/form/TextAreaInput';
import validateOrderMeta from './validation';
import {getOrder} from '../../selectors/orderSelectors';
import {isMetrcDelivery} from '../../selectors/integration/metrcSelectors';
import * as validations from '../common/form/redux-form/validations';
import DatePickerInput from '../common/form/DatePickerInput';
import {isFeatureEnabled} from '../../selectors/featureToggles';

export const OrderMetaForm = (props) => {
  const {submitting, pristine, invalid, isOnfleetEnabled, customer, fulfillmentMethod, isMetrcDelivery, pageDisplayType, isFeaturePlannedRouteToggled} = props;

  const isRequired = (field) => {
    return props.required && Array.isArray(props.required) && props.required.indexOf(field) !== -1;
  };

  return (
    <div  className='totals form-inline'>
    <form onSubmit={props.handleSubmit} noValidate={true} className='full-width-components'>
      <Field
        component={TextAreaInput}
        name='note'
        style={{width:'100%', height: '200px'}}
        props={{
          label: 'Order Notes',
          placeholder: 'Notes to be added to bottom of receipt'
        }}
      />
      {isMetrcDelivery && isFeaturePlannedRouteToggled && fulfillmentMethod === 'delivery' && pageDisplayType === 'delivery'
        ? (
            <Field
              component={TextAreaInput}
              name='planned_route'
              style={{width:'100%', height: '200px'}}
              props={{
                label: 'Planned Route',
                placeholder: 'To destination'
              }}
            />
        )
        : ''
      }
      <div style={{height: '12px'}} />
      {
        isRequired('delivery_address_id') || (!isRequired('delivery_address_id') && props.includeAddressOnAllReceipts)
        ? (<div>
          <Field
            name='delivery_address_id'
            component={ReactSelectInput}
            props={{
              label: I18n.t('customers.create.customerAddress'),
              options: props.addresses,
              placeholder: 'Leave Empty To Skip Address On Receipt',
              isRequired: isOnfleetEnabled
            }}
          />
          <div>
            {
              isRequired('delivery_address_id') && props.addresses.length === 0
                ? <div style={{marginTop: '12px'}} className='text-danger'>A Delivery Address is required For Updates To Delivery.  Add an address to this patient.</div> : null
            }
          </div>
        </div>)
          : null
      }
      {
        isRequired('onfleet_phone_number') && isOnfleetEnabled
        ? (<div>
          <Field
            name='onfleet_phone_number'
            component={ReactSelectInput}
            props={{
              label: I18n.t('customers.create.phone'),
              options: customer && customer.phone_numbers,
              isRequired: isOnfleetEnabled,
              textKey: 'number',
              valueKey: 'number',
            }}
          />
        </div>)
          : null
      }

      {
        isRequired('driver_id')
        ? <div>
          <div style={{height: '12px'}} />
          <Field
            name='driver_id'
            component={ReactSelectInput}
            props={{
              label: I18n.t('driver'),
              options: props.drivers,
              labelKey: 'full_name',
              valueKey: 'id',
            }}
          />
        </div>
          : null
      }
      <div>
        {
          isRequired('driver_id') && props.drivers.length === 0
            ? <div style={{marginTop: '12px'}} className='text-danger'>Drivers Are Required For Updates To Delivery.  Configure drivers.</div> : null
        }
      </div>

      {(isMetrcDelivery && fulfillmentMethod === 'delivery' && pageDisplayType === 'delivery') && (
        <div>
          <div style={{height: '12px'}} />
          <Field
            name='departure_time'
            component={DatePickerInput}
            validate={(value) => validations.required(value, I18n.t('purchaseOrders.departureTime'))}
            props={{
              label: I18n.t('purchaseOrders.departureTime'),
              isRequired: true,
              timeFormat: true,
            }}
          />
          <div style={{height: '12px'}} />
          <Field
            name='arrival_time'
            component={DatePickerInput}
            validate={(value) => validations.required(value, I18n.t('purchaseOrders.arrivalTime'))}
            props={{
              label: I18n.t('purchaseOrders.arrivalTime'),
              isRequired: true,
              timeFormat: true,
            }}
          />
        </div>
      )}

      <div style={{height: '12px'}} />
      <Button type='submit' variant='primary' block disabled={submitting || invalid || pristine}>
        Save / Update
      </Button>
    </form>
    </div>
  );
};

OrderMetaForm.defaultProps = {
  pageDisplayType: '',
};

OrderMetaForm.propTypes = {
  handleSubmit: PropTypes.func,
  addresses: PropTypes.array,
  drivers: PropTypes.array,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  required: PropTypes.array,
  isMetrcDelivery: PropTypes.bool,
  pageDisplayType: PropTypes.string,
  fulfillmentMethod: PropTypes.string,
  includeAddressOnAllReceipts: PropTypes.bool,
  isOnfleetEnabled: PropTypes.bool.isRequired,
  customer: PropTypes.object,
  isFeaturePlannedRouteToggled: PropTypes.bool.isRequired,
};

const form = 'ORDER_META_FORM';

const WrappedComponent = reduxForm({
  form,
  validate: validateOrderMeta,
})(OrderMetaForm);

function mapStateToProps(state){
  return {
    initialValues: getOrderMetaInitialValues(state),
    addresses: getCustomerAddressesForSelect(state),
    drivers: driversFullNameFormation(state),
    vehicles: getVehicles(state),
    isMetrcDelivery: isMetrcDelivery(state),
    fulfillmentMethod: get(getOrder(state), 'fulfillment_method', null),
    includeAddressOnAllReceipts: includeAddressOnAllReceipts(state),
    isFeaturePlannedRouteToggled: isFeatureEnabled(state)('feature_planned_route'),
  };
}

export default connect(mapStateToProps, null)(WrappedComponent);
