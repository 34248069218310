import {I18n} from 'react-redux-i18n';

export const validate = (values, props) => {
  const errors = {};
  if(
    +values['track_waste_packages'] &&
    (!+values['waste_package_location_id'] ||
    !props.locations.find((location) => location.value === values['waste_package_location_id']))
  ){
    errors['waste_package_location_id'] = I18n.t('cultivation.wasteCompliance.wasteLocationRequired');
  }
  return errors;
};

export default validate;
