import React from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {goBack, push} from 'react-router-redux';
import {submit} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {pick} from 'lodash';

import * as itemNames from '../../../../constants/itemNames';
import * as dataNames from '../../../../constants/dataNames';
import {getUnpaginatedData, getDataByPost, postItem} from '../../../../actions/apiActions';
import {getPayload} from '../../../../selectors/couponsSelectors';
import FormWrapper from '../../../common/form/FormWrapper';
import CreateCouponFormWrapper from './CreateCouponFormWrapper';
import {getFacilities} from '../../../../selectors/facilitiesSelectors';
import {getIntegrationState} from '../../../../selectors/integration/integrationSelectors';
import {CREATE_COUPON_FORM} from '../../../../constants/forms';
import {isFeatureEnabled} from '../../../../selectors/featureToggles';

export class CreateCouponPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      shouldPrint: false
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount(){
    this.props.actions.getUnpaginatedData('/api/categories', dataNames.categories);
    this.props.actions.getUnpaginatedData('/api/partners', dataNames.partners, {failed:'partners.get.failed'}, {sell_to: 1, purchase_from: 1});
    this.props.actions.getUnpaginatedData('/api/consumer_groups', dataNames.customerGroups);

    const searchParams = {
      filter: 'active:1 AND is_draft:0 AND is_ingredient:0 AND item_master_parent_id:0 AND !subcategory_id:74',
      query:'',
      size:15000,
      sort: 'name asc, display_name asc',
      fields: ['id', 'strain_id', 'category_id', 'subcategory_id', 'name'],
      start: 0
    };
    this.props.actions.getDataByPost(
      '/api/search/item_masters',
      searchParams,
      dataNames.products,
      {failed: 'products.get.failed'}
    );
    this.props.actions.getUnpaginatedData('/api/product_lists', dataNames.productLists, undefined, {active: 1});
  }

  onSubmit(coupon) {
    const {actions} = this.props;
    const {shouldPrint} = this.state;
    if (shouldPrint) this.setState({shouldPrint: false});

    const couponPayload = getPayload(coupon, pick(this.props, ['timezone', 'facilities']));
    const postCallback = (couponId) => {
      return shouldPrint ?
        actions.push(`/retail/coupons/modify/${couponId}?print=1`) :
        actions.goBack();
    };

    this.props.actions.postItem(
      '/api/coupons',
      couponPayload,
      itemNames.coupon,
      {
        success: 'retail.createCoupon.create.success',
        failed: 'retail.createCoupon.create.fail'
      },
      null,
      ({id}) => postCallback(id)
    );
  }

  render() {
    const {facilities, integrationState, isProductListsFeatureEnabled, actions} = this.props;
    return (
      <FormWrapper title={'retail.createCoupon.title'} goBack={actions.goBack} className='create-coupon-page'>
        <CreateCouponFormWrapper
          onSubmit={this.onSubmit}
          facilities={facilities}
          integrationState={integrationState}
          isProductListsFeatureEnabled={isProductListsFeatureEnabled}
          customSubmitSettings={{
            submitAndPrint: {
              text: I18n.t('common.saveAndPrintSku'),
              action: () => {
                this.setState(
                 {shouldPrint: true},
                 () => actions.submit(CREATE_COUPON_FORM)
                );
              },
              disableFor: {
                pristine: true,
                submitting: true,
                invalid: true
              }
            }
          }}
        />
      </FormWrapper>
    );
  }
}

CreateCouponPage.propTypes = {
  actions: PropTypes.shape({
    postItem: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    getDataByPost: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired
  }).isRequired,
  timezone: PropTypes.string.isRequired,
  facilities: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
  isProductListsFeatureEnabled: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    integrationState:  getIntegrationState(state),
    timezone: state.timezone,
    facilities: getFacilities(state),
    isProductListsFeatureEnabled: isFeatureEnabled(state)('feature_product_lists')
  };
}


function mapDispatchToProps(dispatch) {
  const actions = {getDataByPost, getUnpaginatedData, goBack, push, postItem, submit};

  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateCouponPage);
