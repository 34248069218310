import React from 'react';

import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, FormGroup, Row } from 'react-bootstrap';
import {Field} from 'redux-form';
import {DriverAndVehicleInformation} from './DriverAndVehicleInformation';
import InternationalDateTimePickerInput from '../../common/form/InternationalDateTimePickerInput';
import {OrderListing} from './OrderListing';
import TextAreaInput from '../../common/form/TextAreaInput';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import SubmitSection from '../../common/form/SubmitSection';

export const ManifestForm = (props) => {
  const {
    submitting,
    invalid
  } = props;


  const submitSectionSettings = {
    actionSettings: {
      saveDraftAndPrint: {
        text: I18n.t('generateManifest.form.cancelManifest'),
        style: 'cancel'
      },
      reset: {
        text: I18n.t('common.form.reset'),
        style: 'default'
      },
      viewAndPrint: {
        text: I18n.t('generateManifest.form.viewPrintManifest'),
        style: 'info'
      },
      submit: {
        invalid,
        submitting,
        text: I18n.t('common.actions.save'),
        style: 'primary',
      }
    },
    align: 'right'
  };

  return (
    <form className='manifest-form'>

        <DriverAndVehicleInformation
          drivers={[]}
          vehicles={[]}
          createDriver={() => {}}
          isDriverCompany={false}
          integrationState={{}}
        />

        <OrderListing
          orders={[]}
        />

      <FormGroup className='trip-information'>
        <h3>{I18n.t('generateManifest.form.tripInformation')}</h3>
        <Row>
          <Col xs={6} sm={4} md={3}>
            <label>{I18n.t('generateManifest.form.orderCount')}</label>
          </Col>
          <Col xs={6} sm={4} md={3}>
            <InternationalDateTimePickerInput
              name='departure_time'
              props={{
                label: I18n.t('deliveryOrders.form.departureTime'),
                enableTodayButton: true,
                timeFormat: true,
                disabled: true
              }}
            />
          </Col>
          <Col xs={6} sm={4} md={3}>
            <InternationalDateTimePickerInput
              name='arrival_time'
              props={{
                label: I18n.t('deliveryOrders.form.arrivalTime'),
                enableTodayButton: true,
                timeFormat: true,
                disabled: true
              }}
            />
          </Col>
        </Row>

      </FormGroup>

      <FormGroup className='manifest-notes'>
        <h3>{I18n.t('generateManifest.form.notes')}</h3>
        <Field
          name='notes'
          component={TextAreaInput}
          props={{
            className: 'form-inline',
            maxLength: 2500,
            rows: 5
          }}
        />
      </FormGroup>

      <FormGroup className='manifest-notes'>
        <h3>{I18n.t('generateManifest.form.deliveryOrderStatus')}</h3>

        <Row>
          <Col xs={6} sm={4} md={3}>
            <Field
              name='delivery_method'
              component={ReactSelectInput}
              props={{
                label: I18n.t('generateManifest.form.status'),
                options: [],
                placeholder: I18n.t('common.form.select'),
                isRequired: true,
              }}
            />
          </Col>
        </Row>
      </FormGroup>

      <SubmitSection settings={submitSectionSettings} />
    </form>
  );
}

ManifestForm.propTypes = {
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
};
export default  ManifestForm;