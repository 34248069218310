import React from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {reduxForm, Field} from 'redux-form';
import {Button} from 'react-bootstrap';
import TextInput from '../../common/form/TextInput';
import InternationalDateTimePickerInput from '../../common/form/InternationalDateTimePickerInput';

export const RegulatedUserForm = (props) => {
  const {user, loading, handleModalShow, handleUserApproval, approvalTypes, getFormValue, lengthBan, change} = props;
  const finalApprovalDateFormValue = getFormValue('final_approval_date');

  let finalApprovalDate = null;
  if (finalApprovalDateFormValue) {
    finalApprovalDate = moment(finalApprovalDateFormValue).format('MM-DD-YYYY');
  } else {
    finalApprovalDate = moment(user.final_approval_date).format('MM-DD-YYYY');
  }

  return (
    <form onSubmit={() => {}}>
      <h3>{`${user.first_name} ${user.last_name}`}</h3>
      <span><strong>Employee title</strong>: {user.employee_title && user.employee_title.employee_title}</span><br/>
      <span><strong>Status</strong>: {user.approval_status ? user.approval_status : 'Pending'}</span>
      <Field name='background_check_ref_no' component={TextInput}
        props={{
          label: I18n.t('closedLoop.regulatedUser.background_check_ref_no'),
          isRequired: false,
          value: getFormValue('background_check_ref_no') || user.background_check_ref_no,
          onChange: (e) => lengthBan(e, `background_check_ref_no`, change),
        }}
      />
      <InternationalDateTimePickerInput
        name='final_approval_date'
        props={{
          label: I18n.t('closedLoop.regulatedUser.finalApprovalDate'),
          isRequired: false,
          enableTodayButton: true,
        }}
        inputProps={{
          value: finalApprovalDate
        }}
      />
      <ApprovalButtons
        loading={loading}
        handleModalShow={handleModalShow}
        handleUserApproval={handleUserApproval}
        currentApprovalStatus={user.approval_status}
        approvalTypes={approvalTypes}
      />
    </form>
  );
};

const ApprovalButtons = ({ loading, handleModalShow, currentApprovalStatus, approvalTypes, handleUserApproval }) => {
  // approval default status value is null (pending)
  if (currentApprovalStatus == null) currentApprovalStatus = 'pending';
  const allowedStatuses = allowedStatusesList[currentApprovalStatus];
  return (
    <div>
        {Object.values(approvalTypes).map((type) => {
          return (
            <Button
              title={`${type.value} button`}
              variant={`${type.button} ${!allowedStatuses.includes(type.value) ? 'hidden': ''}`}
              disabled={loading || !allowedStatuses.includes(type.value)}
              onClick={() => {
                handleModalShow(type.value)
              }}
            >
            {type.text}
            </Button>
          );
        })
      }
      <Button
        title={`${I18n.t('common.actions.save')}`}
        onClick={ () => {
          handleUserApproval();
        }}
        >
          {`${I18n.t('common.actions.save')}`}
        </Button>
    </div>
  );
};

// approval statuses and next allowed statuses list config
const allowedStatusesList = {
  pending: ['approved', 'rejected'],
  approved: ['revoked'],
  rejected: ['approved'],
  revoked: ['approved']
};

RegulatedUserForm.propTypes = {
  user: PropTypes.object,
  loading: PropTypes.bool.required,
  handleModalShow: PropTypes.func.required,
  handleUserApproval: PropTypes.func.required,
  approvalTypes: PropTypes.array.required,
  getFormValue: PropTypes.func,
  lengthBan: PropTypes.func,
  change: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {}

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'RegulatedUserForm',
  })
)(RegulatedUserForm);
