import React from 'react';
import { I18n } from 'react-redux-i18n';
import PropTypes from 'prop-types';
import {Col, Row} from 'react-bootstrap';
import {Field} from 'redux-form';

import AccordionPanel from '../../common/AccordionPanel';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import HoverTooltip from '../../common/HoverTooltip';

const PotencyInformationSection = (props) => {
  const {activationTimeOptions, fieldCanModifiedMap} = props;
  return (
    <AccordionPanel title={'products.massModify.potencyInformationTitle'}>
      <span id={'potency_section'}/>
      <Row>
        <Col md={3} sm={6}>
          <Field name='activation_time' component={ReactSelectInput} props={{
            label: I18n.t('products.form.activationTime'),
            options: activationTimeOptions,
            rightAddon: fieldCanModifiedMap.activation_time.length ?
              <HoverTooltip text={I18n.t('products.massModify.errors.diff_categories_help', {field: I18n.t('products.form.activationTime'), categories: fieldCanModifiedMap.activation_time.join(' ,')})} id={'activation_time_tooltip'}/> : null
          }}/>
        </Col>
      </Row>
    </AccordionPanel>
  );
};

PotencyInformationSection.propTypes = {
  activationTimeOptions: PropTypes.array.isRequired,
  fieldCanModifiedMap: PropTypes.object.isRequired
};

export default PotencyInformationSection;