import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import ReactSelectInput from '../../../../../common/form/ReactSelectInput';

const RewardPointsApplication  = ({setting}) => {
  const options = [
    {value: 'discount', text: 'Discount'},
    {value: 'tender', text: 'Payment Tender'}
  ];

  return (
    <Field
      style={{width: '100%'}}
      name={setting.text}
      component={ReactSelectInput}
      props={{options, clearable: false}}
    />
  );
};

RewardPointsApplication.propTypes = {
  setting: PropTypes.object.isRequired,
};

export default RewardPointsApplication;
