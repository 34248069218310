import {createSelector, createStructuredSelector} from 'reselect';
import {I18n} from 'react-redux-i18n';
import {getIntegrationState} from '../integration/integrationSelectors';
import {getSelectedPlants} from '../plantsSelectors';
import {getTimezone} from '../timezoneSelectors';
import {requiresAdditionalDataForMetrcPlantDestroy, getMetrcWasteMethods, getMetrcWasteReasons} from '../integrationSelectors';


export const getDestroyReasonOptions = createSelector(
  [getIntegrationState],
  ({isBiotrack, isWaLeaf, isLesotho}) => {
    if (isBiotrack) {
      return [
        {value: 'Other', text: I18n.t('plants.form.btReasons.other'), btReason: 0},
        {value: 'Waste', text: I18n.t('plants.form.btReasons.waste'), btReason: 1},
        {value: 'Unhealthy', text: I18n.t('plants.form.btReasons.unhealthy'), btReason: 2},
        {value: 'Infestation', text: I18n.t('plants.form.btReasons.infestation'), btReason: 3},
        {value: 'ProductReturn', text: I18n.t('plants.form.btReasons.productReturn'), btReason: 4},
        {value: 'Mistake', text: I18n.t('plants.form.btReasons.mistake'), btReason: 5},
        {value: 'Spoilage', text: I18n.t('plants.form.btReasons.spoilage'), btReason: 6},
        {value: 'Quality', text: I18n.t('plants.form.btReasons.qualityControl'), btReason: 7},
      ];
    }
    else if (isWaLeaf) {
      return [
        {value: 'Infestation', text: I18n.t('plants.form.btReasons.infestation')},
        {value: 'Quality', text: I18n.t('plants.form.btReasons.qualityControl')},
        {value: 'Unhealthy', text: I18n.t('plants.form.btReasons.unhealthy')},
        {value: 'LCB Mandated', text: I18n.t('plants.form.btReasons.lcbMandated')},

      ];
    }else if (isLesotho) {
      return [
        {value: 'Stem rot', text: I18n.t('plants.form.lsReasons.stemRot')},
        {value: 'Dead terminal buds', text: I18n.t('plants.form.lsReasons.deadTerminalBuds')},
        {value: 'Data entry error', text: I18n.t('plants.form.lsReasons.dataEntryError')},
        {value: 'Not rooting', text: I18n.t('plants.form.lsReasons.notRooting')},
        {value: 'Space constraint', text: I18n.t('plants.form.lsReasons.spaceConstraint')},
        {value: 'Stunted growth', text: I18n.t('plants.form.lsReasons.stuntedGrowth')},
        {value: 'Hermaphrodite', text: I18n.t('plants.form.lsReasons.hermaphrodite')},
        {value: 'Damaged plant', text: I18n.t('plants.form.lsReasons.damagedPlant')},
        {value: 'Root rot', text: I18n.t('plants.form.lsReasons.rootRot')},
        {value: 'Water logging', text: I18n.t('plants.form.lsReasons.waterLogging')},
        {value: 'Reached senescence', text: I18n.t('plants.form.lsReasons.reachedSenescence')},
        {value: 'Bananas', text: I18n.t('plants.form.lsReasons.bananas')},
        {value: 'Poor genetics', text: I18n.t('plants.form.lsReasons.poorGenetics')},
        {value: 'Poor cutting', text: I18n.t('plants.form.lsReasons.poorCutting')}
      ];
    }

    return [
      {value: 'Cull', text: I18n.t('plants.form.cull')},
      {value: 'Mold', text: I18n.t('plants.form.mold')},
      {value: 'Powdery Mildew', text: I18n.t('plants.form.powderyMildew')},
      {value: 'Pests', text: I18n.t('plants.form.pests')},
      {value: 'BudRot', text: I18n.t('plants.form.budRot')},
      {value: 'Blight', text: I18n.t('plants.form.blight')},
      {value: 'Male', text: I18n.t('plants.form.male')},
      {value: 'Destroy', text: I18n.t('plants.form.destroy')},
      {value: 'Lost/Stolen', text: I18n.t('plants.form.lostStolen')},
      {value: 'Other', text: I18n.t('plants.form.other')},
    ];
  }
);

const getInitialValues = createSelector(
  [getSelectedPlants],
  (selectedPlants) => {
    return {
      plants: selectedPlants.map(plant => ({
        id: plant.id,
        plant_id: plant.plant_id,
        uom: 'GR',
        strain: plant.strain_name,
        batch_name:plant.batch_name
      })),
      use_overall: true,
    };
  }
);

const getComplianceSettings = state => state.complianceSettings;

export const getDestroyPlantsPageProps = createStructuredSelector({
  initialValues: getInitialValues,
  reasons: getDestroyReasonOptions,
  selectedPlants: getSelectedPlants,
  timezone: getTimezone,
  complianceSettings: getComplianceSettings,
  requiresAdditionalDataForMetrc: requiresAdditionalDataForMetrcPlantDestroy,
  metrcWasteMethods: getMetrcWasteMethods,
  metrcWasteReasons: getMetrcWasteReasons,
});
