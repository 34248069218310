import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Button, Col, Row} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import WillRender from '../../../common/concealers/WillRender';

const ActionsPanel = (props) => {
  const { formValues, change, isValid, isDirty, itemMaster } = props;
  // It is not allowed to save a product that has items as draft
  const isLockedProduct = get(formValues, 'has_items');
  const isDraft = get(itemMaster,'is_draft');
  const isModify = get(itemMaster,'id');
  const isActive = get(itemMaster,'active');

  return (
    <React.Fragment>
      <Row>
        <Col sm={3}>
          {I18n.t('products.form.action.deleteText')}
        </Col>
        <Col sm={3}>
          {isValid ? I18n.t('products.form.action.saveProgressTextInvalid') : I18n.t('products.form.action.saveProgressTextValid')}
          {}
        </Col>
        <Col sm={3}>
          {isValid ? I18n.t('products.form.action.saveAndPublishTextInvalid') : I18n.t('products.form.action.saveAndPublishTextInvalid')}
        </Col>
        <Col sm={3}>
          {I18n.t('products.form.action.saveAndDuplicateText')}
        </Col>
      </Row>
      <Row style={{textAlign: 'center'}}>
        <Col sm={3}>
          <WillRender ifTrue={isDraft}>
            <Button
              type='submit'
              variant='danger'
              onClick={() => change('action', 'delete')}
            >
              {I18n.t('products.form.action.delete')}
            </Button>
          </WillRender>
          <WillRender ifTrue={!isDraft && isActive}>
            <Button
              type='submit'
              variant='warning'
              disabled={!isModify}
              onClick={() => change('action', 'inactivate')}
            >
              {I18n.t('products.form.action.inactivate')}
            </Button>
          </WillRender>
          <WillRender ifTrue={!isDraft && !isActive}>
            <Button
              type='submit'
              variant='success'
              disabled={!isModify}
              onClick={() => change('action', 'activate')}
            >
              {I18n.t('products.form.action.activate')}
            </Button>
          </WillRender>
        </Col>
        <Col sm={3}>
          <Button
            type='submit'
            variant='primary'
            onClick={() => change('action', 'saveProgress')}
            disabled={/*isValid || */!isDirty || isLockedProduct}
          >
            {I18n.t('products.form.action.saveProgress')}
          </Button>
        </Col>
        <Col sm={3}>
          <Button
            type='submit'
            variant='primary'
            onClick={() => change('action', 'save')}
            disabled={!isValid || !isDirty}
          >
            {I18n.t('products.form.action.saveAndPublish')}
          </Button>
        </Col>
        <Col sm={3}>
          <Button
            type='submit'
            variant='primary'
            onClick={() => change('action', 'saveAndDuplicate')}
            disabled={/*!isValid*/false}
          >
            {isDirty ? I18n.t('products.form.action.saveAndDuplicate') : I18n.t('products.form.action.duplicate')}
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

ActionsPanel.propTypes = {
  formValues: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  isDirty: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  itemMaster: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
  };
}

export default connect(mapStateToProps)(ActionsPanel);
