import PropTypes from 'prop-types';
import {formValueSelector} from 'redux-form';
import {connect} from 'react-redux';
import moment from 'moment';
import {bindActionCreators} from 'redux';
import {getApplicationMode} from '../../../selectors/applicationModesSelectors';
import {getFileOptions, getGroupedFiles} from '../../../selectors/objectFilesSelectors';
import {deleteItem} from '../../../actions/apiActions';
import ConsumerIdsComponent from './ConsumerIdsComponent';
import * as itemNames from '../../../constants/itemNames';
import * as dataNames from '../../../constants/dataNames';
import {getIntegrationState} from '../../../selectors/integration/integrationSelectors';

function mapStateToProps(state, ownProps) {
  const selector = formValueSelector(ownProps.formName);
  const mostRecentMedicalId = state[itemNames.customer] && Array.isArray(state[itemNames.customer].ids) && state[itemNames.customer].ids.length
    ? state[itemNames.customer].ids.reduce((acc, id) => {
      if(id.type !== 'med') return acc;
      return acc.concat([Object.assign({}, id, {
        expiredAt: id.expired_at !== null ? moment(id.expired_at).toDate() : new Date()
      })]);
    }, [])
      .sort((a,b) => b.expiredAt.getTime() - a.expiredAt.getTime())
      .shift()
    : {id: 0};
  const integrationState = getIntegrationState(state);
  const {isOhMetrc, isPaLeaf} = integrationState;
  return {
    mostRecentMedicalId,
    dispensaries: state[dataNames.facilities].filter((facility) => facility.type === 'dispensary'),
    idsArray: selector(state, ownProps.name),
    fileOptions: getFileOptions(state, ownProps),
    groupedFields: getGroupedFiles(state, ownProps),
    applicationMode: getApplicationMode(state),
    caregiverInfo: selector(state, 'caregiverInfo'),
    isPaLeaf,
    isOhMetrc,
    constants: state[itemNames.constants],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({deleteItem}, dispatch),
  };
}

const ConsumerIdsFieldArray = connect(mapStateToProps, mapDispatchToProps)(ConsumerIdsComponent);

ConsumerIdsFieldArray.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
  }).isRequired,
  change: PropTypes.func.isRequired,
  types: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  files: PropTypes.array.isRequired,
  isFieldRequired: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
  ]),
  isExpirationRequired: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
  ]),
  isUploadDisabled: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
  ]),
  customer: PropTypes.object.isRequired,
  isCanadaFacility: PropTypes.bool.isRequired,
  caregiverInfo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
};

export default ConsumerIdsFieldArray;
