import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Modal, Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

class NotesModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notes: props.notes || ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.notes !== this.props.notes) {
      this.setState({ notes: this.props.notes });
    }
  }

  handleChange(event) {
    this.setState({ notes: event.target.value });
  }

  handleSave() {
    this.props.onSave(this.state.notes);
  }

  render() {
    const { show, onHide } = this.props;

    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t('common.editNotes')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId='formNotes'>
              <Form.Label>{I18n.t('common.notes')}</Form.Label>
              <Form.Control
                as='textarea'
                rows={10}
                value={this.state.notes}
                onChange={this.handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className='d-flex'>
          <Button variant='secondary' onClick={onHide}>
            {I18n.t('common.cancel')}
          </Button>
          <Button variant='primary' className='ml-auto' onClick={this.handleSave}>
            {I18n.t('common.done')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

NotesModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  notes: PropTypes.string
};

export default NotesModal;
