import get from 'lodash.get';
import {set} from 'lodash';
import {getInventoryReceipt} from '../../selectors/fillPurchaseOrderSelectors';
import * as itemNames from '../../constants/itemNames';
import {setItem} from '../itemActions';

/**
 * Public method for updating Inventory Receipt
 * @param fields
 * @returns {function(*, *): *}
 */
export const updateInventoryReceipt = (fields) => (dispatch, getState) => {
  const inventoryReceipt = getInventoryReceipt(getState());

  return dispatch(setItem({
    ...inventoryReceipt,
    ...fields,
  }, itemNames.inventoryReceipt));
};

/**
 * Method updates a specific Line in Purchase Order in Redux state
 * @param index - and ordering key of line
 * @param lineValues - updating values
 * @returns {Function}
 */
export const updateInventoryReceiptLine = (index, lineValues) => (dispatch, getState) => {
  const inventoryReceipt = getInventoryReceipt(getState());
  const lines = get(inventoryReceipt, 'lines', []);

  set(lines, index, {
    ...get(lines, index, {}),
    ...lineValues,
  });

  return dispatch(updateInventoryReceipt({
    ...inventoryReceipt,
    lines,
  }));
};

export default {
  updateInventoryReceipt,
};