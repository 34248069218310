import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'react-bootstrap';
import {FaPlus} from 'react-icons/fa';
import { I18n } from 'react-redux-i18n';
import InternationalReduxPhoneInput from '../common/form/redux-form/InternationalReduxPhoneInput';

const UserAccountPhones = (props) => {
  const {fields: {push, map, length}} = props;

  return (
    <Row>
      {map((phone, phoneIndex) => {
        const isLast = phoneIndex + 1 === length;
        return (
          <Col {...calculateSize(isLast)} className='row' key={`user-phone-${phoneIndex}`}>
            <Col xs={isLast ? 9 : 12}>
              <InternationalReduxPhoneInput
                name={`${phone}.value`}
                props={{
                  label: getItemLabel(phoneIndex),
                }}/>
            </Col>
            {isLast &&
            <Col xs={3} className='add-button'>
              <Button variant='primary' size='sm' onClick={() => push({value: ''})}><FaPlus/></Button>
            </Col>}
          </Col>
        );
      })}
    </Row>
  );
};

UserAccountPhones.propTypes = {
  fields: PropTypes.shape({
    push: PropTypes.func.isRequired,
    map: PropTypes.func.isRequired,
    length: PropTypes.number.isRequired
  })
};

function calculateSize(isLast) {
  return {
    xs: isLast ? 8 : 7,
    sm: isLast ? 7 : 6,
    md: isLast ? 6 : 5,
    lg: isLast ? 5 : 4
  };
}

function getItemLabel(index) {
  return I18n.t('userAccounts.Phone') + ' ' + (index + 1);
}

export default UserAccountPhones;
