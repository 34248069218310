import {LOCATION_CHANGE, replace} from 'react-router-redux';

import * as actionTypes from '../constants/actionTypes';
import * as messageTypes from '../constants/messageTypes';
import {addMessage} from '../actions/systemActions';

import * as itemNames from '../constants/itemNames';
import * as dataNames from '../constants/dataNames';
import {findRouteDefinition} from '../util/routeHelper';
import {getIntegrationState, isSetupMode} from '../selectors/integration/integrationSelectors';

/*
  persists setup mode message;
*/
export const isIntegrationSetup = store => next => action => {
  const result = next(action);
  const isQualifyingAction = [actionTypes.GET_ITEM_SUCCESS, actionTypes.SET_ITEM].indexOf(action.type) !== -1;
  if (isQualifyingAction && action.name === itemNames.integrationSettings) {
    const state = store.getState();
    if (isSetupMode(state)) {
      const integrationState = getIntegrationState(state);
      const messages = {
        isApisicpa: 'integration.apisicpaInSetupMode',
        isBiotrack: 'integration.biotrackInSetupMode',
        isLeaf: 'integration.leafInSetupMode',
      };
      const messageKey = Object.keys(messages).find(key => integrationState[key]);
      const messageString = messages[messageKey];
      const hasMessage = state[dataNames.messages].find((msg) => msg.message === messageString);
      if (messageString && !hasMessage) {
        store.dispatch(addMessage(messageTypes.warning, messageString));
      }
    }
  }
  return result;
};

/*
  blocks navigation to dangerous pages when setup mode is enabled
*/
export const blockSetupModeNav = store => next => action => {
  if (action.type === LOCATION_CHANGE
    && findRouteDefinition(action.payload.pathname)
    && findRouteDefinition(action.payload.pathname).blockOnSetup
    && (isSetupMode(store.getState()))
  ) {
    return store.dispatch(replace('/setup-mode'));
  } else {
    return next(action);
  }
};
