import { createSelector } from 'reselect';
import map from 'lodash.map';
import get from 'lodash.get';
import find from 'lodash.find';
import filter from 'lodash.filter';
import * as dataNames from '../constants/dataNames';
import * as itemNames from '../constants/itemNames';
import { getCustomerGroups } from './customerGroupsSelectors';
import {productFormValueSelector} from './forms/productFormSelectors';
import {AlphaBeticalCaseInsensitive} from '../util/sortHelper';

const getPricingClassData = state => state.selectedPricingClassData;
export const getPricingClasses = state => state[dataNames.pricingClasses].sort(AlphaBeticalCaseInsensitive);
export const getCurrentPricingClass = state => state[itemNames.pricingClass];
const getIsActiveTab = (_, props) => props.status !== 'inactive';
const getFacilities = state => state[dataNames.facilities];

export const getCreatePricingClassInitialValues = () => {
  return {
    active: 1,
    facilities: [],
    customer_groups: [{}]
  };
};

export const getModifyPricingClassInitialValues = createSelector([getCurrentPricingClass, getFacilities], (pricingClass, facilities) => {
  if (Object.keys(pricingClass).length === 0) {
    return {};
  }

  let customerGroups = [{}];

  if (pricingClass && pricingClass.consumer_groups && pricingClass.consumer_groups.length > 0) {
    customerGroups = pricingClass.consumer_groups.map(consumerGroup => {
      return {
        id: consumerGroup.id,
        customer_group_id: consumerGroup.consumer_group_id,
        percentage_of_base: consumerGroup.percentage_of_base
      };
    });
  }

  if (pricingClass && pricingClass.facilities && pricingClass.facilities.length > 0) {
    const ids = pricingClass.facilities.map(facility => facility.facility_id);
    pricingClass.facilities = facilities.filter(({ id }) => ids.includes(id));
  }

  return {
    active: pricingClass.active,
    id: pricingClass.id,
    name: pricingClass.name,
    organization_id: pricingClass.organization_id,
    created_at: pricingClass.created_at,
    updated_at: pricingClass.updated_at,
    facilities: pricingClass && pricingClass.facilities ? pricingClass.facilities.map(facility => facility.id) : [],
    customer_groups: customerGroups
  };
});

export const getSelectedPricingClass = createSelector([getPricingClassData], selectedPricingClass => {
  return selectedPricingClass.map(pricingClass => pricingClass.id);
});

export const getPricingClassesListingData = createSelector(
  [getPricingClasses, getIsActiveTab, getFacilities],
  (pricingClasses, isActive, facilities) =>
  pricingClasses
    .map(pricingClass => {
      if (pricingClass.facilities.length === 0) {
        pricingClass.facility_name = '';
      }

      const facilityNames = pricingClass.facilities.filter(facility => {
        return facility.facility_id !== null;
      }).map(pricingClassFacility => {
        const facility = facilities.find(facility => facility.id === pricingClassFacility.facility_id) || {};
        return facility.name;
      }).filter(Boolean);

      pricingClass.facility_name = facilityNames.join(', ');

      return pricingClass;
    })
    .filter(pricingClass => Boolean(pricingClass.active) === isActive)
);

/**
 * Returning a list of pricing classes which are applicable for
 * transferred facilities
 * @type {Reselect.Selector<any, any>}
 */
export const getPricingClassForFacilities = createSelector(
  [getPricingClasses],
  (pricingClasses) => (ids) => {

    if (ids.length === 0) {
      return pricingClasses;
    }

    const filterHandler = ({facilities = []}) => {
      if (facilities.length === 0) {
        return true;
      }

      const facilityIds = map(facilities, 'facility_id');
      const result = ids.filter((id) => facilityIds.indexOf(id) > -1);
      return (result.length === ids.length);
    };

    return filter(pricingClasses, filterHandler);
  });

/**
 * Returning pricing groups by pricing class ID
 * @example getConsumerGroupsByPricingClass(state)(pricingClassId)
 * @type {Reselect.Selector<any, Function> | Reselect.Selector<any, any>}
 */
export const getConsumerGroupsByPricingClass = createSelector(
  [getPricingClasses, getCustomerGroups],
  (pricingClasses, customerGroups) => (pricingClassId) => {
    const pricingClass = find(pricingClasses, ['id', pricingClassId]) || {};

    return (pricingClass.consumer_groups || []).map((consumerGroup) => ({
      ...consumerGroup,
      group_name: get(find(customerGroups, ['id', consumerGroup.consumer_group_id]), 'group_name'),
    }));
  });

/**
 * Returning fields for pricing class snaphost which is belonging to an item master
 * @param state
 * @param path
 * @param pricingClassId
 * @returns {*}
 */
export const getSnapshotOfPricingClass = (state, path, pricingClassId = null) => {
  const priceList = productFormValueSelector(state, path);
  const consumerGroups = getConsumerGroupsByPricingClass(state)(pricingClassId);
  return consumerGroups.map((item) => ({
    price_list_id: priceList.id,
    consumer_group_id: item.consumer_group_id,
    percentage_of_base: item.percentage_of_base,
  }));
};