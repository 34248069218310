import isEmpty from 'lodash.isempty';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import { CancelToken } from 'axios';

import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import SingleActionColumn from '../common/grid/columns/SingleActionColumn';
import TablePageWrapper from '../common/grid/TablePageWrapper';
import * as p from '../../constants/permissions';
import * as messageTypes from '../../constants/messageTypes';
import * as apiActions from '../../actions/apiActions';
import { addMessage } from '../../actions/systemActions';
import { setData, unsetData } from '../../actions/dataActions';
import { unsetItem } from '../../actions/itemActions';
import { handleComplexSelectRow } from '../../actions/helpers/selectedDataHelper';
import {
  getSelectedItemMasterIds,
  getItemMastersForListing,
  isValidItemMaster,
  getSolrReworkQueryAndFilter
} from '../../selectors/itemMastersSelectors';
import { getTotalResults } from '../../selectors/paginationSelectors';
import { getDataUpdateAvailable } from '../../selectors/dataUpdateSelectors';
import { isPaLeaf, isWaLeaf } from '../../selectors/integrationSelectors';
import PricingColumn from './common/PricingColumn';
// import HistoryButton from './common/HistoryButton';
import { setSolrErrorMessage } from '../../actions/solrActions';
import { isFeatureEnabled } from '../../selectors/featureToggles';
import { getUrlParam } from '../../util/routeHelper';
import addTooltip from '../../util/tooltipHelper';

export class ProductsPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    const productFilters = [];
    const searchString = '';
    const sizePerPage = 50;
    this.switchTab = this.switchTab.bind(this);
    this.state = {
      productFilters,
      searchString,
      sizePerPage,
      activeTab: 'active',
      reindexing: false,
      cancelToken: false
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.editProduct = this.editProduct.bind(this);
    this.inactivateProduct = this.inactivateProduct.bind(this);
    this.filter = this.filter.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
    this.validateItemMaster = this.validateItemMaster.bind(this);
    this.ref = React.createRef();
    this.handleModify = this.handleModify.bind(this);
    this.handleDuplicate = this.handleDuplicate.bind(this);
    this.massModifyProdicts = this.massModifyProdicts.bind(this);
    this.updatePricing = this.updatePricing.bind(this);
    this.getCancelToken = this.getCancelToken.bind(this);
  }

  componentDidMount() {
    this.props.actions.unsetData(dataNames.itemMasters);
    this.updatePricing();
    this.switchTab(this.state.activeTab);
  }

  componentDidUpdate(nextProps, nextState) {
    if (this.state.activeTab != nextState.activeTab) {
      this.props.actions.unsetData(dataNames.itemMasters);
    }
  }

  componentWillUnmount() {
    this.props.actions.unsetData(dataNames.itemMasters);
  }

  updatePricing() {
    return Promise.all([
      this.props.actions.getDataByPost(
        '/api/pricing_classes/item_masters_by_pricing_classes',
        {},
        dataNames.pricingClassesWithProduct
      ),
      this.props.actions.getDataByPost(
        '/api/pricing_groups/item_masters_by_pricing_groups',
        {},
        dataNames.pricingGroupsWithProduct
      )
    ]);
  }

  switchTab(activeTab) {
    this.props.actions.unsetData(dataNames.itemMasters);
    this.setState({ activeTab });
    this.filter(activeTab === 'inactive' ? 0 : 1);
  }

  filter(active) {
    if (this.ref.current) {
      this.ref.current.wrappedInstance.filter(`active:${active} AND is_draft:0 AND prepack_weight_id:0 AND is_ingredient:0`);
    }
  }

  handleSelect(action, rows) {
    this.props.actions.handleComplexSelectRow(rows, dataNames.itemMasters, action);
  }

  editProduct(event, row) {
    this.props.actions.unsetItem(itemNames.validateItemMaster);
    this.validateItemMaster(row);
  }

  validateItemMaster(row) {
    Promise.all([
      this.props.actions.getItem(`/api/item_masters/${row.id}`, itemNames.validateItemMaster),
      this.props.actions.getUnpaginatedData('/api/categories', dataNames.categories)
    ]).then(() => {
      if (this.props.isValidItemMaster) {
        this.props.actions.push(`/products/modify/${row.id}`);
      } else {
        this.props.actions.addMessage(messageTypes.error, [
          'products.validation.productConfigIncorrect',
          { item_number: row.item_number }
        ]);
      }
    });
  }

  inactivateProduct(event, row) {
    const active = this.state.activeTab === 'active' ? 0 : 1;
    const payload = { active };
    this.props.actions.putItem(
      `/api/item_masters/${row.id}`,
      payload,
      'noOp',
      { failed: 'products.modify.failed', success: 'products.modify.success' },
      undefined,
      () => this.switchTab(this.state.activeTab)
    );
  }

  updateSearch() {
    this.updatePricing().then(() => {
      // TODO: once we deprecated the old item_masters core, we need update core to new_item_masters for update search index here
      this.props.actions.postItem('/api/search/update', { core: 'item_masters' }, null, {
        success: 'plants.actions.updateSearchSuccess',
        failed: 'plants.actions.updateSearchFail'
      });
      this.setState({ reindexing: true });
    });
  }

  handleSearch(sort, query = 'matchall', size, start, filter) {
    // initial loading should go through the service, searching will still go through solr -> that's what it's made to do

    // including toggling feature from url so we can test this on The Mint - Tempe
    const serviceFirstEnabledFromUrl = getUrlParam('feature_item_master_listing_service_first');
    if ((this.props.itemMasterServiceFirstIsEnabled || serviceFirstEnabledFromUrl) && !query) {
      return this.getItemMastersFromService(sort, query, size, start, filter);
    }

    const { solrReworkEnabled } = this.props;

    const {updatedQueryString, updatedFilter} = getSolrReworkQueryAndFilter(query, filter, solrReworkEnabled);

    const params = {
      sort,
      query: updatedQueryString,
      size,
      start,
      filter: updatedFilter,
      fields: [
        'id',
        'item_number',
        'name',
        'display_name',
        'description',
        'uom_type',
        'default_uom',
        'category_display_name',
        'subcategory_display_name',
        'category_code',
        'subcategory_code',
        'active',
        'is_draft',
        'primary_product_image_file_id',
        'external_ids',
        'is_ingredient',
        'context_field',
        'suggestString'
      ]
    };
    const config = {
      errorHandler: {
        message: 'cultivation.finishedProduct.table.solrError',
        action: this.props.actions.setSolrErrorMessage,
        clearOnSuccess: true
      }
    };
    const cancelToken = this.getCancelToken();
    this.props.actions
      .getSearchData(
        '/api/search/item_masters',
        dataNames.itemMasters,
        null,
        params,
        (data) => {
          let ids = data ? data.map((product) => product.primary_product_image_file_id).filter(Boolean) : [];
          ids = ids.filter((v) => v != null);
          if (ids.length) {
            this.props.actions.getDataByPost('/api/images/multiple', { ids }, dataNames.images);
          }
        },
        cancelToken.token,
        config
      )
      .then(() => this.clearCancelToken())
      .catch(() => this.clearCancelToken());
  }

  getItemMastersFromService(sort, query, size, start) {
    // activeTab should contain either active or inactive, defaulting to active if that is ever not the case though
    const showActiveProducts = this.state.activeTab === 'inactive' ? 0 : 1;
    const itemMastersHaveTrackingIds = this.props.isWaLeaf || this.props.isPaLeaf ? 1 : 0;

    // parse the string from the column sort: eg. "name asc, display_name asc"
    const explodedSort = sort.split(',');
    const orderBy = explodedSort.map((sort) => sort.trim().replace(/\s/, ':'));

    const params = {
      select_columns: [
        'id',
        'name',
        'item_number',
        'description',
        'display_name',
        'category_id',
        'uom_type',
        'default_uom',
        'lot_tracked',
        'is_prepack',
        'item_master_parent_id',
        'prepack_weight_id',
        'is_inventory_item',
        'is_sales_item',
        'active',
        'is_draft',
        'subcategory_code',
        'subcategory_display_name',
        'category_display_name'
      ],
      // filters
      active: showActiveProducts,
      is_draft: 0,
      has_parent_item_master: 0,
      is_ingredient: 0,

      // additional data
      include_images: 1,
      include_external_identifiers: itemMastersHaveTrackingIds,

      // pagination
      page: (start ? start / size : 0) + 1,
      per_page: size,
      sort: orderBy
    };

    this.props.actions.getPaginatedData(
      '/api/item_masters/search',
      dataNames.itemMasters,
      { failed: 'products.get.failed' },
      params,
      (itemMasters) => {
        const images = [];
        if (itemMasters) {
          itemMasters.forEach((product) => {
            const image = product.primary_image_file;
            // the backend will return an empty array if the item master doesn't have an image or an image object if it does
            if (!isEmpty(image)) {
              images.push(product.primary_image_file);
            }
          });
        }
        if (images.length) {
          this.props.actions.setData(images, dataNames.images);
        }
      }
    );
  }

  getCancelToken() {
    const cancelToken = CancelToken.source(); // Get new token

    if (this.state.cancelToken) {
      // Trigger the old one if still present
      this.state.cancelToken.cancel();
    }

    this.setState({ cancelToken });
    return cancelToken;
  }

  clearCancelToken() {
    this.setState({ cancelToken: false });
  }

  massModifyProdicts() {
    this.props.actions.unsetData(dataNames.products);
    this.props.actions.push('/products/mass_modify');
  }

  handleModify() {
    this.massModifyProdicts(this.props.selectedItemMasters);
  }

  handleDuplicate() {
    // const formValues = this.props.itemMasters.find((e) => e.id === this.props.selectedItemMasters[0]);
    const id = this.props.selectedItemMasters[0];
    this.props.actions.push({
      pathname: '/products/create',
      state: {
        id: id
      }
    });
  }

  render() {
    const { itemMasters, selectedItemMasters, dataTotalSize, dataUpdateAvailable } = this.props;
    const columns = [
      {
        name: 'products.table.image',
        dataId: 'image_url',
        hidden: false,
        csvFormatter: (cell) => {
          return 'image';
        },
        formatter: (image_url) => <img className='product-logo' src={image_url} />,
        dataSort: false,
        width: '70px'
      },
      {
        name: 'products.table.name',
        dataId: 'name',
        hidden: false,
        dataSort: true,
        formatter: (cell) => addTooltip(cell)
      },
      {
        name: 'products.table.displayName',
        dataId: 'display_name',
        hidden: false,
        dataSort: true,
        formatter: (cell) => addTooltip(cell)
      },
      {
        name: 'products.table.itemNumber',
        dataId: 'item_number',
        hidden: false,
        dataSort: true,
        formatter: (cell) => addTooltip(cell)
      },
      {
        name: 'products.table.category',
        dataId: 'category_display_name',
        hidden: false,
        dataSort: true
      },
      {
        name: 'products.table.subCategory',
        dataId: 'subcategory_display_name',
        hidden: false,
        dataSort: true
      },
      {
        name: 'products.table.pricingGroups',
        dataId: 'pricing_group_name',
        hidden: false,
        dataSort: false,
        formatter: (cell) => PricingColumn(cell)
      },
      {
        name: 'products.table.pricingClasses',
        dataId: 'pricing_class_name',
        hidden: false,
        dataSort: false,
        formatter: (cell) => PricingColumn(cell)
      },
      {
        name: 'products.table.uom',
        dataId: 'default_uom',
        hidden: false,
        dataSort: true,
        width: '60px'
      }
    ];

    if (this.props.isWaLeaf || this.props.isPaLeaf) {
      columns.push({
        name: 'products.table.trackingNumber',
        dataId: 'external_ids',
        hidden: false,
        dataSort: false,
        formatter: (cell, row) => {
          // taking into account that the format will be different if it's coming from solr or our own api aka service first
          // from the service we will return an array of ids
          if (cell && Array.isArray(cell)) {
            return cell.reduce((acc, entity) => {
              return entity.split(',').length > 1 ? I18n.t('common.form.multiple') : entity;
            }, '');
          }

          // solr returns an string array where we have to parse for the key storing the id
          const ids = cell ? JSON.parse(cell) : [];
          return ids.reduce((acc, entity) => {
            return entity.external_identifier.split(',').length > 1
              ? I18n.t('common.form.multiple')
              : entity.external_identifier;
          }, '');
        }
      });
    }
    //this is the item master history button. In order to reactive it just uncomment below and line 30 as well
    // columns.push({
    //   name: 'common.history',
    //   dataId: 'history',
    //   hidden: false,
    //   dataSort: false,
    //   formatter: (cell, row) => HistoryButton(row.id)
    // });

    columns.push({
      name: 'products.table.edit',
      data: 'id',
      hidden: false,
      permissions: [p.manage_products],
      csvFormatter: (cell) => {
        return I18n.t('products.actions.edit');
      },
      formatter: SingleActionColumn({
        label: 'products.actions.edit',
        action: this.editProduct,
        permissions: [p.manage_products]
      }),
      dataSort: false,
      columnClassName: 'action-column',
      width: '100px'
    });

    columns.push({
      name: 'products.table.active',
      dataId: 'active',
      hidden: false,
      permissions: [p.manage_products],
      csvFormatter: (cell) => {
        return cell ? I18n.t('products.actions.inactivate') : I18n.t('products.actions.activate');
      },
      formatter: (cell, row) =>
        cell
          ? SingleActionColumn({
            label: 'products.actions.inactivate',
            action: this.inactivateProduct,
            style: 'warning',
            permissions: [p.manage_products]
          })(cell, row)
          : SingleActionColumn({
            label: 'products.actions.activate',
            action: this.inactivateProduct,
            style: 'success',
            permissions: [p.manage_products]
          })(cell, row),
      dataSort: false,
      columnClassName: 'action-column',
      width: '100px'
    });

    const actions = [
      {
        id: 'createProduct',
        path: '/products/create',
        text: 'products.actions.createProduct',
        glyph: 'plus',
        requireSelect: false
      },
      {
        id: 'cloneProduct',
        path: '/products/create',
        text: 'products.actions.cloneProduct',
        glyph: 'duplicate',
        requireSelect: false,
        func: this.handleDuplicate,
        disabled: selectedItemMasters.length > 1 || selectedItemMasters.length === 0 ? true : false
      },
      {
        id: 'modifyProduct',
        text: 'products.actions.massModify',
        glyph: 'th-list',
        requireSelect: false,
        func: this.handleModify,
        permissions: [p.mass_update_products]
      },
      {
        id: 'updateSearch',
        func: this.updateSearch,
        text: 'plants.actions.updateSearch',
        glyph: 'arrow-up',
        variant: 'warning',
        requireSelect: false,
        disabled: this.state.reindexing
      }
    ];
    const tabs = [
      {
        id: 'activeTab',
        eventKey: 'active',
        title: 'products.actions.viewActive',
        actions
      },
      {
        id: 'inactiveTab',
        eventKey: 'inactive',
        title: 'products.actions.viewInactive',
        actions
      }
    ];
    return (
      <div className='product-listing-page'>
        <h1>{I18n.t('products.table.productListing')}</h1>
        <TablePageWrapper
          ref={this.ref}
          settingKey='products'
          columns={columns}
          data={itemMasters}
          tabs={tabs}
          dataUpdateAvailable={dataUpdateAvailable}
          dataUpdated={() => this.setState({ reindexing: false })}
          hideScanSearch={true}
          activeTab={this.state.activeTab}
          switchTab={this.switchTab}
          handleSelect={this.handleSelect}
          dataTotalSize={dataTotalSize}
          externalSearch={this.handleSearch}
          sort='name asc'
          selectedRows={selectedItemMasters}
          className='tab-listing'
          external={true}
          hideExport={true}
          isSolrListing={true}
          useAutoSuggest={this.props.useAutoSuggest}
          autoSuggestPlaceholder='products.table.suggestPlaceholder'
          autoSuggestContextKey={this.state.activeTab === 'active' ? false : 'products/inactive'}
        />
      </div>
    );
  }
}

ProductsPage.propTypes = {
  selectedItemMasters: PropTypes.array.isRequired,
  itemMasters: PropTypes.array.isRequired,
  dataTotalSize: PropTypes.number.isRequired,
  actions: PropTypes.shape({
    postItem: PropTypes.func.isRequired,
    getDataByPost: PropTypes.func.isRequired,
    getSearchData: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired,
    handleComplexSelectRow: PropTypes.func.isRequired,
    ensureGetUnpaginatedData: PropTypes.func.isRequired,
    putItem: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    addMessage: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired
  }),
  isValidItemMaster: PropTypes.bool,
  dataUpdateAvailable: PropTypes.array.isRequired,
  isWaLeaf: PropTypes.bool,
  isPaLeaf: PropTypes.bool,
  useAutoSuggest: PropTypes.bool,
  itemMasterServiceFirstIsEnabled: PropTypes.bool
};

function mapStateToProps(state) {
  const props = {
    dataTotalSize: getTotalResults(state, { name: dataNames.itemMasters }),
    selectedItemMasters: getSelectedItemMasterIds(state),
    itemMasters: getItemMastersForListing(state),
    // TODO: once we deprecated the old item_masters core, we need update core to new_item_masters for checking update status here
    dataUpdateAvailable: [getDataUpdateAvailable(state, { name: dataNames.itemMasters, core: 'item_masters' })],
    isPaLeaf: isPaLeaf(state),
    isWaLeaf: isWaLeaf(state),
    useAutoSuggest: isFeatureEnabled(state)('feature_solr_inventory_suggest'),
    itemMasterServiceFirstIsEnabled: isFeatureEnabled(state)('feature_item_master_listing_service_first'),
    solrReworkEnabled: isFeatureEnabled(state)('feature_solr_rework')
  };

  if (!isEmpty(state[itemNames.validateItemMaster])) {
    props.isValidItemMaster = isValidItemMaster(state);
  }
  return props;
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {
    handleComplexSelectRow,
    push,
    setData,
    unsetData,
    addMessage,
    unsetItem,
    setSolrErrorMessage
  });
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductsPage);
