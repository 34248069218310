import { I18n } from 'react-redux-i18n';
import { integerFieldValidation, validNumericFieldValidation } from '../../../../../common/form/redux-form/validations';

const fieldsLang = 'retail.rewards.reward.fields';

const validate = (values) => {
  const errors = {};
  const requiredFields = ['name', 'reward_type', 'points_required', 'reward_amount', 'reward_applies_to'];
  const integerFields = ['points_required'];
  const numericFields = ['reward_amount'];
  requiredFields.forEach((field) => {
    // 0 is actually a value...
    errors[field]  = values[field] || values[field] == 0 ? undefined : I18n.t('common.form.isRequired', {fieldName: I18n.t(`${fieldsLang}.${field}`)});
  });
  integerFields.forEach((field) => {
    if(errors[field] !== undefined) return false;
    errors[field]  = !integerFieldValidation(parseFloat(values[field])) ? I18n.t('common.form.mustBeAnInteger', {fieldName: I18n.t(`${fieldsLang}.${field}`)}) : undefined;
  });
  numericFields.forEach((field) => {
    if(errors[field] !== field) return false;
    errors[field]  = !validNumericFieldValidation(parseFloat(values[field])) ? I18n.t('common.form.mustBeNumeric', {fieldName: I18n.t(`${fieldsLang}.${field}`)}) : undefined;
  });
  if(values.reward_type === 'percent'){
    if(errors.reward_amount === undefined){
      if(parseFloat(values.reward_amount) > 100) errors.reward_amount = `${I18n.t(`${fieldsLang}.reward_amount`)} must be 100 or less when in percent.`;
    }
  }
  return errors;
};

export default validate;
