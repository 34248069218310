import { change, blur, arrayRemoveAll, arrayPush, formValueSelector, arrayRemove, arrayInsert } from 'redux-form';
import get from 'lodash.get';
import round from 'lodash.round';
import { PURCHASE_ORDER_FORM } from '../../constants/forms';
import { isFormChangeAction } from './utils';
import * as dataNames from '../../constants/dataNames';
import * as actionTypes from '../../constants/actionTypes';
import {getItem} from '../../actions/apiActions';
import {unionData} from '../../actions/dataActions';
import {getPartnerFacilitiesForPurchaseOrder} from '../../selectors/partnersSelectors';
import {getIntegrationState} from '../../selectors/integration/integrationSelectors';
import {isLeafReturn, getProductOptions } from '../../selectors/purchaseOrdersSelectors';
import {formatClientDate} from '../../util/dateHelpers';
import {isBulkType, convertFromBase, convertPrice} from '../../util/uomHelpers';
import {getGroupedItemMasterChildren} from '../../selectors/fillPurchaseOrderSelectors';
import {getPurchaseOrderItemMastersForPartner, getChildItemMasters} from '../../actions/purchaseOrderActions';
import * as itemNames from '../../constants/itemNames';
import { getLineTotals } from '../../components/transfers/common/line-items/lineItemsHelpers';
import {NON_MEDICATED_CATEGORY_ID} from '../../constants/categories';

const purchaseOrderFormValueSelector = formValueSelector(PURCHASE_ORDER_FORM);
const myDataNames = {itemMasters: dataNames.vendorItemMasters, childItemMasters: dataNames.childItemMasters};

const purchaseOrderFormMiddleware = store => next => action => {
  const result = next(action);
  const {meta, payload} = action;

  if(!meta || meta && meta.form !== PURCHASE_ORDER_FORM){
    return result;
  }

  const state = store.getState();
  const integration = getIntegrationState(state);

  /***
   * This already exists as a test BUT because we are using solr for item masters sometimes (leaf pa) the shape of the item master
   * object can vary.  While we do map it to a mostly standard item master, this is just some low cost insurance.
   * @param itemMaster
   * @returns {*|boolean}
   */
  const isMedicated = (itemMaster) => {
    const nonMedicatedCategoryId = NON_MEDICATED_CATEGORY_ID;
    const isMedicated = get(itemMaster, 'is_medicated', get(itemMaster, 'category_id', 0) !== nonMedicatedCategoryId);
    const isLotTracked = get(itemMaster, 'lot_tracked', get(itemMaster, 'inventory_attributes.lot_tracked', false));
    return isMedicated && (!integration.isPaLeaf || isLotTracked);
  };

  const haveNewData = () => {
    return [actionTypes.GET_DATA_SUCCESS, actionTypes.ADD_DATA_SUCCESS].indexOf(action.type) !== -1;
  };

  const haveNewItemMasters = () => {
    return action.name === dataNames.itemMasters;
  };

  //////////////// MAIN

  if(action.type && action.type === actionTypes.REDUX_FORM_FOCUS && meta.form && meta.form === PURCHASE_ORDER_FORM){
    store.dispatch(change(PURCHASE_ORDER_FORM, 'focused', 1)); // Used to determine true initial state of form for connects amend button
  }

  // Handle partner_id and vendor_facility_id having been set in initial values.
  if(action.type === actionTypes.REDUX_FORM_REGISTER_FIELD && payload.name === 'partner_id'){
    const partnerId = purchaseOrderFormValueSelector(state, 'partner_id');
    const isInitial = purchaseOrderFormValueSelector(state, 'is_initial');
    if(partnerId && !isInitial){
      onSetPartnerId(partnerId, store, false);
    } else { // Was known as getPurchaseOrderItemMastersForInitialInventory on the purchase page which aliased this in actions.
      const vendorId = get(state, 'purchaseOrder.partner_id');
      if (vendorId) {
        store.dispatch(getPurchaseOrderItemMastersForPartner(vendorId, myDataNames));
      }
    }
  }

  if(haveNewData() && haveNewItemMasters()){
    const itemMasters = state[dataNames.itemMasters];
    const onlyMedicated = itemMasters.every((itemMaster) => isMedicated(itemMaster));
    const onlyNonMedicated = itemMasters.every((itemMaster) => !isMedicated(itemMaster));
    if(onlyMedicated || onlyNonMedicated){
      store.dispatch(change(PURCHASE_ORDER_FORM, 'contains_medicated', onlyNonMedicated ? 0 : 1));
    }
  }

  if (isFormChangeAction(action, [PURCHASE_ORDER_FORM])) {

    /***
     * On change of item_master_id:
     * Check to see if we have vendors array.  If we do not (solr does not) - get the item master with details to get the vendors array.
     * Set the item_master values using vendor to get default pricing..
     * If prepack load the child item masters and then set the subitem values.
     */
    if (meta.field.indexOf('item_master_id') !== -1){

      const getItemMasterChildren = () => {
        return getGroupedItemMasterChildren(store.getState());
      };

      const itemMasterId = payload;
      const prefix = meta.field.split('.')[0];

      store.dispatch(arrayRemoveAll(meta.form, `${prefix}.subitems`));

      if(itemMasterId) {
        const updateForm = (itemMaster) => {
          const itemMasterUom = get(itemMaster, 'default_uom', 'GR');
          const limitChildrenToParentUom = (childItemMasters) => {
            return childItemMasters.filter((child) => {
              const childUom = get(child, 'itemWeight.uom');
              return childUom === itemMasterUom;
            });
          };
          const partnerId = purchaseOrderFormValueSelector(store.getState(), 'partner_id');
          const vendor = (itemMaster.vendors || []).find((vendor) => vendor.partner_id === partnerId);
          const unit_price = vendor ? parseFloat(vendor.default_unit_cost || 0) : 0;
          store.dispatch(change(meta.form, `${prefix}.itemMaster`, {...itemMaster}));
          store.dispatch(change(meta.form, `${prefix}.uom`, itemMaster.default_uom));
          store.dispatch(change(meta.form, `${prefix}.unit_price`, unit_price));
          store.dispatch(change(meta.form, `${prefix}.qty`, ''));

          if (get(itemMaster, 'inventory_attributes.is_prepack', 0)) {

            const setSubItems = (childItemMasters) => {
              childItemMasters = limitChildrenToParentUom(childItemMasters);

              const subItems = childItemMasters.map(childItemMaster => {
                return {
                  ...childItemMaster,
                  unit_price: (convertFromBase(childItemMaster.itemWeight.weight_base, childItemMaster.itemWeight.uom) * unit_price)
                };
              }).sort((a, b) => a.itemWeight.weight_base < b.itemWeight.weight_base ? -1 : 1);

              subItems.forEach(subItem => {
                store.dispatch(arrayPush(meta.form, `${prefix}.subitems`, subItem));
              });
            };

            store.dispatch(change(meta.form, `${prefix}.lineType`, 'prepack'));
            if(!Array.isArray(getItemMasterChildren()[itemMasterId])) { // Load childItemMasers if not found
              store.dispatch(getChildItemMasters(itemMasterId))
                .then(() => {
                  setSubItems(getItemMasterChildren()[itemMasterId] || []);
                });
            } else {
              setSubItems(getItemMasterChildren()[itemMasterId] || []);
            }
          } else if (isBulkType(itemMaster.uom_type)) {
            store.dispatch(change(meta.form, `${prefix}.lineType`, 'bulk'));
          } else {
            store.dispatch(change(meta.form, `${prefix}.lineType`, 'unit'));
          }
        };

        const itemMasters = getProductOptions(store.getState());
        const itemMaster = itemMasters.find((itemMaster) => itemMaster.id === itemMasterId);
        const vendors = get(itemMaster, 'vendors', []);
        if(!vendors.length){
          store.dispatch(getItem(`/api/item_masters/${itemMasterId}`, null, null, {detailed: 1})) // Load detailed item master if we need vendors
            .then((itemMasterWithVendor) => {
              store.dispatch(unionData([itemMasterWithVendor], dataNames.itemMasters, 'id'));
              updateForm(Object.assign({}, itemMaster, itemMasterWithVendor));
            });
          return false;
        }
        updateForm(itemMaster);
      } else {
        const resetFields = ['uom', 'unit_price', 'qty', 'lineType', 'itemMaster'];
        resetFields.forEach((field) => {
          store.dispatch(change(meta.form, `${prefix}${field}`, ''));
        });
      }
    }

    /***
     * A good argument can be made to remove this except for the is return part.
     */
    if (meta.field === 'vendor_facility_id' && payload){

      const vendorFacilityId = purchaseOrderFormValueSelector(store.getState(), 'vendor_facility_id');

      if (vendorFacilityId) {
        // Retrieve MJP facility record for partner facility
        store.dispatch(getItem(`/api/partner_facilities/facility_details/${vendorFacilityId}`, itemNames.partnerFacilityDetails))
          .then(() => {
            // Evaluate if PO is a return and set is_return checkbox accordingly
            store.dispatch(change(PURCHASE_ORDER_FORM, 'is_return', isLeafReturn(store.getState())));
          })
          .then(() => {
            store.dispatch(getPurchaseOrderItemMastersForPartner(partnerId, myDataNames));
            const partners = state[dataNames.partners];
            const selectedPartner = partners.find((partner) => partner.id === partnerId);
            const dateOrdered = purchaseOrderFormValueSelector(state, 'date_ordered');
            setPurchaseOrderTitle(store, selectedPartner, dateOrdered);
          });
      }

      store.dispatch(arrayRemoveAll(PURCHASE_ORDER_FORM, 'lines'));
      store.dispatch(arrayPush(PURCHASE_ORDER_FORM, 'lines', {}));
      const partnerId = purchaseOrderFormValueSelector(state, 'partner_id');
      onSetPartnerId(partnerId, store, true);
    }

    /***
     * Get applicable item masters on selection of a partner.
     */
    if (meta.field === 'partner_id' && payload) {
      onSetPartnerId(parseInt(payload), store, true);
      setPurchaseOrderTitle(store);
    }

    if (meta.field === 'date_ordered' && payload) {
      setPurchaseOrderTitle(store);
    }

    if(meta.field === 'contains_medicated' && payload){
      store.dispatch(arrayRemoveAll(PURCHASE_ORDER_FORM, 'lines'));
      store.dispatch(arrayPush(PURCHASE_ORDER_FORM, 'lines', {}));
    }

    /* ******************************************************
     * The following section focus on the line items changes
     */
    let lineFieldName = null;
    let match = meta.field.match(/^lines\[\d+]\.(\w+)/);
    if (match && match.length > 1) {
      lineFieldName = match[1];
    }

    if (['qty', 'unit_price', 'line_item_price', 'uom', 'subitems'].includes(lineFieldName)) {

      const findLineIndex = () => {
        const match = meta.field.match(/^lines\[(\d+)]\.\w+/);
        if (match && match.length > 1) {
          return match[1];
        }
        return -1;
      };


      const lineIndex = findLineIndex();
      if (lineIndex < 0) {
        return next(action);
      }
      const lines = purchaseOrderFormValueSelector(state, 'lines');

      /*
       * Line item unit_price change
       */
      if (lineFieldName === 'unit_price') {

        const subItems = get(lines[lineIndex], 'subitems', false);
        let quantity = parseFloat(get(lines[lineIndex], 'qty', 0));
        if (subItems && Array.isArray(subItems) && subItems.length > 0) {
          quantity = subItems.reduce((acc, subitem) => {
            acc += round((parseFloat(subitem.qty) || 0) * convertFromBase(get(subitem, 'itemWeight.weight_base', 0), subitem.itemWeight.uom), 2);
            return acc;
          }, 0);
        }

        if (!get(lines[lineIndex], 'editableLinePrice', false)) {
          const newLineItemPrice = quantity * payload;
          store.dispatch(change(meta.form, `lines[${lineIndex}].line_item_price`, newLineItemPrice.toFixed(2)));
        }

        if (subItems && Array.isArray(subItems) && subItems.length > 0) {
          subItems.forEach((subItem, index) => {
            const weight = convertFromBase(get(subItem, 'itemWeight.weight_base', 0), get(subItem, 'itemWeight.uom'));
            const newSubItem = Object.assign({}, subItem, {unit_price: round(round(payload,2) * weight, 2)});
            store.dispatch(arrayRemove(meta.form, `lines[${lineIndex}].subitems`, index));
            store.dispatch(arrayInsert(meta.form, `lines[${lineIndex}].subitems`, index, newSubItem));
          });
        }
      }


      /*
       * Line item qty change
       */
      else if (lineFieldName === 'qty') {

        if (!get(lines[lineIndex], 'editableLinePrice', false)) {

          const lineItemPrice = parseFloat(lines[lineIndex].qty || 0) * parseFloat(lines[lineIndex].unit_price || 0);
          store.dispatch(change(meta.form, `lines[${lineIndex}].line_item_price`, lineItemPrice.toFixed(2)));

        } else {

          const unitPrice = parseFloat(payload || 0) > 0 ? parseFloat(lines[lineIndex].line_item_price || 0) / parseFloat(payload) : 0;
          store.dispatch(change(meta.form, `lines[${lineIndex}].unit_price`, unitPrice.toFixed(2)));
        }
      }

      /*
       * Line item line_item_price change
       */
      else if (lineFieldName === 'line_item_price') {

        if (get(lines[lineIndex], 'editableLinePrice', false)) {

          const subItems = get(lines[lineIndex], 'subitems', false);
          if (subItems && Array.isArray(subItems) && subItems.length > 0) {
            const quantity = subItems.reduce((acc, subitem) => {
              acc += convertFromBase(subitem.itemWeight.weight_base, subitem.itemWeight.uom) * subitem.qty || 0;
              return acc;
            }, 0);
            const unitPrice = quantity > 0 ? parseFloat(payload || 0) / quantity : 0;
            store.dispatch(change(meta.form, `lines[${lineIndex}].unit_price`, unitPrice.toFixed(2)));

          } else {

            const unitPrice = parseFloat(lines[lineIndex].qty || 0) > 0 ? parseFloat(payload || 0) / parseFloat(lines[lineIndex].qty) : 0;
            store.dispatch(change(meta.form, `lines[${lineIndex}].unit_price`, unitPrice.toFixed(2)));
          }
        }
      }

      /*
       * Line item uom change
       */
      else if (lineFieldName === 'uom') {

        if (!get(lines[lineIndex], 'editableLinePrice', false)) {
          const defaultUnitCost = parseFloat(get(lines[lineIndex], 'itemMaster.default_unit_cost', 0));
          const defaultUom = get(lines[lineIndex], 'itemMaster.default_uom', 'G');
          const unitPrice = convertPrice(defaultUnitCost, defaultUom, lines[lineIndex].uom);
          store.dispatch(change(meta.form, `lines[${lineIndex}].unit_price`, unitPrice.toFixed(2)));
        }
      }

      /*
       * On a prepack line change
       */
      else if (lineFieldName === 'subitems') {

        const findPrepackLineIndex = () => {
          const match = meta.field.match(/^lines\[\d+]\.subitems\[(\d+)]\.\w+/);
          if (match && match.length > 1) {
            return match[1];
          }
          return -1;
        };

        const findPrepackField = () => {
          const match = meta.field.match(/^lines\[\d+]\.subitems\[\d+]\.(\w+)/);
          if (match && match.length > 1) {
            return match[1];
          }
          return null;
        };

        const subItems = get(lines[lineIndex], 'subitems', []);
        if (!Array.isArray(subItems)) {
          return next(action);
        }

        const prepackLine = findPrepackLineIndex();
        if (prepackLine < 0) {
          return next(action);
        }

        const prepackField = findPrepackField();
        if (prepackField === null ) {
          return next(action);
        }

        if (['qty'].includes(prepackField)) {

          if (prepackField == 'qty') {

            if (lines[lineIndex] && lines[lineIndex].editableLinePrice) {
              const lineItemPrice = parseFloat(lines[lineIndex].line_item_price || 0);

              const quantity = subItems.reduce((acc, subitem) => {
                acc += round((parseFloat(subitem.qty) || 0) * convertFromBase(subitem.itemWeight.weight_base, subitem.itemWeight.uom), 2);
                return acc;
              }, 0);

              const unitPrice = quantity > 0 ? lineItemPrice / quantity : 0;
              store.dispatch(change(meta.form, `lines[${lineIndex}].unit_price`, unitPrice.toFixed(2)));

              subItems.forEach((subItem, index) => {
                store.dispatch(change(meta.form, `lines[${lineIndex}].subitems[${index}].unit_price`, (unitPrice * convertFromBase(subItem.itemWeight.weight_base, subItem.itemWeight.uom)).toFixed(2)));
              });
            }

            const transferRecord = get(store.getState()[itemNames.purchaseOrder], 'transfer_record', false);
            const totals = getLineTotals(subItems, lines[lineIndex], transferRecord);

            if(lines[lineIndex] && !lines[lineIndex].editableLinePrice){
              store.dispatch(change(meta.form, `lines[${lineIndex}].line_item_price`, totals.price.toFixed(2)));
            }

            if(lines[lineIndex]){
              store.dispatch(change(meta.form, `lines[${lineIndex}].qty`, totals.weight));
            }

            const subItem = subItems[prepackLine];
            const newTotal = subItem && subItem.itemWeight ? convertFromBase(subItem.itemWeight.weight_base, subItem.itemWeight.uom) * (subItem.qty || 0) : 0;
            store.dispatch(change(meta.form, `lines[${lineIndex}].subitems[${prepackLine}].total`, newTotal.toFixed(2)));

          }
        }
      }
    }


    /* ****************************************************
    * The following section focus on payments items changes
    */
    let paymentFieldName = null;
    match = meta.field.match(/^payments\[\d+]\.(\w+)$/);
    if (match && match.length > 1) {
      paymentFieldName = match[1];
    }

    if (['payment_type', 'amount', 'register_id', 'payment_date'].includes(paymentFieldName)) {

      const payments = purchaseOrderFormValueSelector(state, 'payments');

      const findPaymentIndex = () => {
        const match = meta.field.match(/^payments\[(\d+)]\.\w+$/);
        if (match && match.length > 1) {
          return match[1];
        }
        return -1;
      };

      const blurTheseFiels = (paymentIndex, fieldList) => {
        fieldList.map((f) => {
          store.dispatch(blur(meta.form, `${payments[paymentIndex]}.${f}`));
        });
      };


      if (lineFieldName === 'payment_type') {

        const paymentIndex = findPaymentIndex();
        if (paymentIndex < 0) {
          next(action);
        }
        blurTheseFiels(paymentIndex, ['amount', 'register_id', 'user_id', 'payment_date']);
      }

      else if (lineFieldName === 'amount') {

        const paymentIndex = findPaymentIndex();
        if (paymentIndex < 0) {
          next(action);
        }
        blurTheseFiels(paymentIndex, ['payment_type', 'register_id', 'user_id', 'payment_date']);
      }

      else if (lineFieldName === 'register_id') {

        const paymentIndex = findPaymentIndex();
        if (paymentIndex < 0) {
          next(action);
        }
        blurTheseFiels(paymentIndex, ['user_id', 'payment_type', 'amount', 'payment_date']);
      }
    }
  }

  return result;
};


const onSetPartnerId = (partnerId, store, resetVendorFacilityId = true) => {
  const state = store.getState();
  const partners = state[dataNames.partners];
  const partnerFacilities = getPartnerFacilitiesForPurchaseOrder(state, {partner_id: partnerId});
  const selectedPartner = partners.find((partner) => partner.id === partnerId);
  if(selectedPartner) {
    const selectedVendorFacilityId  = purchaseOrderFormValueSelector(state, 'vendor_facility_id');
    const vendorFacilityId = selectedVendorFacilityId
      ? false
      : partnerFacilities.length === 1
        ? partnerFacilities[0].id
        : '';

    if(vendorFacilityId && resetVendorFacilityId){
      store.dispatch(change(PURCHASE_ORDER_FORM, 'vendor_facility_id', vendorFacilityId));
      if (vendorFacilityId === '') {
        store.dispatch(change(PURCHASE_ORDER_FORM, 'is_return', 0));
      }
    }
    store.dispatch(arrayRemoveAll(PURCHASE_ORDER_FORM, 'lines'));
    store.dispatch(arrayPush(PURCHASE_ORDER_FORM, 'lines', {}));
  }
};

const setPurchaseOrderTitle = (store) => {
  const partnerId = purchaseOrderFormValueSelector(store.getState(), 'partner_id');
  const dateOrdered = purchaseOrderFormValueSelector(store.getState(), 'date_ordered');
  const state = store.getState();
  const partners = state[dataNames.partners];
  const partner = partners.find(p => p.id === partnerId);
  const partnerString = partner ? partner.name : 'Purchase Order';
  const dateString = dateOrdered ? formatClientDate(dateOrdered) : '';
  store.dispatch(change(PURCHASE_ORDER_FORM, 'title', `${partnerString} ${dateString}`));
};

export default purchaseOrderFormMiddleware;
