import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import {Field} from 'redux-form';
import TextInput from '../../common/form/TextInput';
import InternationalDecimalReadOnlyInput from '../../common/form/InternationalDecimalReadOnlyInput';

class StringFieldInfo extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { row, editable, columns } = this.props;

    return (
      <Row className='string-fields-info'>
        {columns.map((column, index) => {
          const column_name = (get(column, 'display_name'))  ? get(column, 'display_name') : I18n.t(column.name);
          return (
            <Col xs={6} sm={4} md={3} lg={2} className='transparent-addon-right' key={column.key}>
              {editable ? (
                <Field
                  name={column.key}
                  component={TextInput}
                  props={{
                    label: column_name
                  }}
                />
              ) : (
                <div>
                  <h5 className='bold'>{column_name}</h5>
                  <InternationalDecimalReadOnlyInput
                    name={column.key}
                    props={{
                      value: row[column.key]
                    }}/>
                </div>
              )}
          </Col>
          );
        })}
      </Row>
    );
  }
}

StringFieldInfo.propTypes = {
  row: PropTypes.object,
  columns: PropTypes.array.isRequired,
  editable: PropTypes.bool.isRequired,
};

export default StringFieldInfo;
