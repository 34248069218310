import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment-timezone';
import {getInternationalTimeFormat, getInternationalDateFormat} from '../../../selectors/InternationalOperationsSelectors';
import {convertDbToClientTZ} from '../../../util/dateHelpers';

const InternationalDateTimeStatic = (props) => {

  /* eslint no-unused-vars: 0 */
  const {dispatch, internationalTimeFormat, internationalDateFormat, useSystemDate, adjustForClientTimezone, timezone, displaySeconds, displayTime, displayDate, children, ...rest } = props;

  const canRenderChildren = typeof children === 'object' && !moment.isMoment(children) && !(children instanceof Date);

  if(canRenderChildren){
    return <div children={children} {...rest}/>;
  }

  // This is how we want the value to be displayed.
  // Hopefully, the format in the database can be parsed by moment,
  // which parses pretty much anything.
  const formatThis = (value) => {

    if (value === null || value === undefined || value === '') {
      return '';
    }

    let dateTime = moment.utc(value);

    if (!dateTime.isValid()) {
      return value;
    }

    if (adjustForClientTimezone) {
      dateTime = convertDbToClientTZ(moment.utc(value), timezone);
    }

    //Date portion (default to system date)
    let formatMaskDate = 'YYYY-MM-DD';
    if (!useSystemDate) {
      formatMaskDate = (internationalDateFormat) ? internationalDateFormat : 'MM/DD/YYYY';
    }

    //Time portion
    let formatMaskTime = 'h:mm:ss A';
    if (internationalTimeFormat === 'H:mm') {
      formatMaskTime = 'H:mm' + ((displaySeconds) ? ':ss' : '');
    } else {
      formatMaskTime = 'h:mm' + ((displaySeconds) ? ':ss' : '') + ' A';
    }

    const formatMask = formatMaskDate + ((displayTime) ? ` ${formatMaskTime}` : '');
    return dateTime.format(formatMask);
  };

  const formattedInnerText = formatThis(children);
  return (
    <span {...rest}>
      {formattedInnerText}
    </span>
  );
};

InternationalDateTimeStatic.propTypes = {
  internationalDateFormat: PropTypes.string,
  internationalTimeFormat: PropTypes.string.isRequired,
  displayDate: PropTypes.bool,
  useSystemDate: PropTypes.bool,
  displayTime: PropTypes.bool,
  displaySeconds: PropTypes.bool,
  timezone: PropTypes.string.isRequired,
  adjustForClientTimezone: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dispatch: PropTypes.func
};

InternationalDateTimeStatic.defaultProps = {
  useSystemDate: true,
  displayDate: true,
  displayTime: true,
  displaySeconds: true,
  adjustForClientTimezone: true,
};


function mapStateToProps(state, ownProps){
  const {timezone} = state;
  return {
    timezone,
    internationalDateFormat: getInternationalDateFormat(state),
    internationalTimeFormat: getInternationalTimeFormat(state)
  };
}

export default connect(mapStateToProps)(InternationalDateTimeStatic);
