import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { I18n } from 'react-redux-i18n';
import { push } from 'react-router-redux';
import { formValueSelector } from 'redux-form';
import { Col } from 'react-bootstrap';
import FormWrapper from '../common/form/FormWrapper';
import * as apiActions from '../../actions/apiActions';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import { getDetailedFacilitiesWithIntegration } from '../../selectors/facilitiesSelectors';
import {getCreateUserInitialValues, getEmployeeTitles} from '../../selectors/usersSelectors';
import { getIntegrationState } from '../../selectors/integration/integrationSelectors';
import UserAccountForm from './UserAccountForm'; //eslint-disable-line
import { validate } from './validate';
import { getRolesForUserPage } from '../../selectors/userRolesSelector';
import { USER_FORM } from '../../constants/forms';
import { userHasBiotrackFacilities } from '../../selectors/forms/userFormSelectors';
import * as userAccountActions from '../../actions/forms/userAccountActions';
import { isTagIntegrated, userRequiresStateLicense } from '../../selectors/integrationSelectors';
import {getInternationalPhoneCountryCode, getInternationalPhoneNumber} from '../../util/internationalHelpers';
import { isInternationalModuleEnabled } from '../../selectors/InternationalOperationsSelectors';

export class CreateUserAccountPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
  }

  componentWillMount() {
    this.props.actions.getUnpaginatedData('/api/facilities/details', dataNames.detailedFacilities);
    this.props.actions.getItem('/api/integration-settings', itemNames.integrationSettings, {
      failed: 'stateIntegrators.settings.get.failed'
    }),
      this.props.actions.getUnpaginatedData('/api/roles', dataNames.roles);
    this.props.actions.getItem('/api/integration-settings/organization', itemNames.orgIntegrationSettings, {
      failed: 'stateIntegratorSettings.get.failed'
    });
    this.props.actions.getItem('/api/integration-settings', itemNames.integrationSettings, {
      failed: 'stateIntegratorSettings.get.failed'
    });
    this.props.actions.getUnpaginatedData('/api/employee_titles', dataNames.employeeTitles);
  }

  render() {
    const {
      roles,
      actions,
      facilities,
      getFormValue,
      initialValues,
      integrationState,
      hasBiotrackFacilities,
      isTagIntegrated,
      userRequiresStateLicense,
      internationalModuleEnabled,
      employeeTitles
    } = this.props;

    return (
      <FormWrapper
        title={I18n.t('userAccounts.User Accounts')}
        goBack={this.props.actions.redirect}
        className='create-user-account-page'
        formName={USER_FORM}
        shouldWarnDirty={true}
      >
        <Col xs={12} className='form-container'>
          <UserAccountForm
            authenticationKey={this.props.state[itemNames.authenticationKey].key}
            facilities={facilities}
            userRoles={roles}
            invalid={this.props.state.form.invalid}
            actions={actions}
            initialValues={initialValues}
            validate={validate}
            enableReinitialize={true}
            integrationState={integrationState}
            getFormValue={getFormValue}
            hasBiotrackFacilities={hasBiotrackFacilities}
            isModify={false}
            isTagIntegrated={isTagIntegrated}
            userRequiresStateLicense={userRequiresStateLicense}
            internationalModuleEnabled={internationalModuleEnabled}
            employeeTitles={employeeTitles}
          />
        </Col>
      </FormWrapper>
    );
  }
}

CreateUserAccountPage.propTypes = {
  authenticationKey: PropTypes.string,
  state: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    getUnpaginatedData: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired
  }),
  facilities: PropTypes.array.isRequired,
  initialValues: PropTypes.object.isRequired,
  integrationState: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  getFormValue: PropTypes.func.isRequired,
  hasBiotrackFacilities: PropTypes.bool,
  isTagIntegrated: PropTypes.bool,
  userRequiresStateLicense: PropTypes.bool,
  internationalModuleEnabled: PropTypes.bool,
  employeeTitles: PropTypes.array.isRequired
};

const selector = formValueSelector(USER_FORM);

function mapStateToProps(state) {
  return {
    state,
    facilities: getDetailedFacilitiesWithIntegration(state),
    initialValues: getCreateUserInitialValues(state),
    integrationState: getIntegrationState(state),
    roles: getRolesForUserPage(state),
    getFormValue: (name) => selector(state, name),
    hasBiotrackFacilities: userHasBiotrackFacilities(state),
    isTagIntegrated: isTagIntegrated(state),
    userRequiresStateLicense: userRequiresStateLicense(state),
    internationalModuleEnabled: isInternationalModuleEnabled(state) ? true : false,
    employeeTitles: getEmployeeTitles(state)
  };
}

function mapDispatchToProps(dispatch) {
  const bindedActions = bindActionCreators(
    {
      ...apiActions
    },
    dispatch
  );

  const actions = {
    ...bindedActions,

    redirect: () => {
      return dispatch(push('/users'));
    },

    save: (payload, dispatch, props) => {

      payload.phones.forEach ((item, index) => {
        payload.phones[index].phone_country_code = getInternationalPhoneCountryCode(item.value);
        payload.phones[index].number = getInternationalPhoneNumber(item.value);
      });

      return dispatch(userAccountActions.create(payload, props)).then(actions.redirect);
    }
  };

  return {
    actions
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateUserAccountPage);
