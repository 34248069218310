import React from 'react';

import BaseFormFields from '../common/BaseFormFields';
import SubmitSection from '../common/SubmitSection';


const Onfleet = () => {
  return (
    <div>
      <BaseFormFields integrator='onfleet' />
      <SubmitSection />
    </div>
  );
};


export default Onfleet;
