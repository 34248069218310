import React from 'react';
import PropTypes from 'prop-types';
import {Button, ButtonToolbar, Row, Col} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';

const BUTTON_TYPES = ['button', 'submit', 'reset'];

const properButtonType = (key) => {
  return BUTTON_TYPES.some(type => type === key) ? key : 'button';
};

const SubmitSection = (props) => {
  const {handleSubmit} = props;
  const defaultSettings = {
    actionSettings: {
      submit: {
        text: I18n.t('common.form.save'),
        action: handleSubmit
      }
    },
    align: 'right'
  };
  const {align, actionSettings} = props.settings || defaultSettings;
  return (
    <Row className='submit-section'>
      <Col md={12}>
        <div className='button-wrapper'>
          <ButtonToolbar className={`float-${align || 'right'}`}>
            {
              Object.keys(actionSettings).map((key, index) => {
                const type = actionSettings[key].type || properButtonType(key);
                const display = actionSettings[key].display || null;
                const template = actionSettings[key].template;

                // Allowing to render a custom button
                if (template && typeof template === 'function') {
                  return template(index, actionSettings[key]);
                }

                return (
                  <Button
                    key={`${index}`}
                    type={type}
                    style={actionSettings[key].cssStyle ? actionSettings[key].cssStyle : {}}
                    variant={actionSettings[key].style}
                    onClick={actionSettings[key].action || null}
                    disabled={actionSettings[key].submitting || actionSettings[key].pristine || actionSettings[key].disabled || actionSettings[key].invalid || null}
                    className={`${key}-button ${display}`}>
                    {actionSettings[key].text}
                  </Button>
                );
              })
            }
          </ButtonToolbar>
        </div>
      </Col>
    </Row>
  );
};

SubmitSection.propTypes = {
  settings: PropTypes.shape({
    actionSettings: PropTypes.object.isRequired,
    align: PropTypes.string
  }),
  handleSubmit: PropTypes.func
};

export default SubmitSection;
