import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import get from 'lodash.get';

import TotalsButtons from './TotalsButtons';
import CartTotalsTable from './CartTotalsTable';

const Totals = ({
  order,
  openAssignDriver,
  fillOrder,
  couponIsAppliedToAllProducts,
  change,
  meetsComplianceLimits,
  isOrderFulfilled,
  changeMakePayment,
  goToPage,
  validCureLimits,
  onSubmit,
  handleSubmit,
  allowOrderUpdate
}) => {
  const {
    order_total,
    coupon_total,
    order_subtotal,
    taxable_total,
    taxes,
    order_source,
    coupons,
    fulfillment_method,
    delivery_address_id
  } = order;

  const orderType =
    order_source === undefined
      ? ''
      : order_source === 'in_store' && fulfillment_method === 'in_store'
      ? 'in_store'
      : fulfillment_method === 'in_store'
      ? 'pickup'
      : 'delivery';

  const displayCoupons =
    coupons && coupons.length ? coupons.filter((coupon) => couponIsAppliedToAllProducts(coupon)) : [];

  return (
    <div className='totals form-inline'>
      <h2>{I18n.t('cart.totals.cartTotals')}</h2>
      <CartTotalsTable
        subTotal={order_subtotal}
        coupons={displayCoupons}
        couponsTotal={coupon_total}
        payments={get(order, 'payments', [])}
        taxableTotal={taxable_total}
        taxes={taxes}
        total={order_total}
      />
      <TotalsButtons
        order={order}
        fillOrder={fillOrder}
        onSubmit={onSubmit}
        openAssignDriver={openAssignDriver}
        handleSubmit={handleSubmit}
        orderType={orderType}
        change={change}
        goToPage={goToPage}
        isOrderFulfilled={isOrderFulfilled}
        changeMakePayment={changeMakePayment}
        meetsComplianceLimits={meetsComplianceLimits}
        validCureLimits={validCureLimits}
        fulfillment_method={fulfillment_method}
        delivery_address_id={delivery_address_id}
        allowOrderUpdate={allowOrderUpdate}
      />
    </div>
  );
};

Totals.propTypes = {
  // toggleCheckout: PropTypes.func.isRequired,
  // setManualDiscount: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  fillOrder: PropTypes.func.isRequired,
  openAssignDriver: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  couponIsAppliedToAllProducts: PropTypes.func,
  change: PropTypes.func,
  meetsComplianceLimits: PropTypes.bool,
  isOrderFulfilled: PropTypes.bool,
  changeMakePayment: PropTypes.func,
  goToPage: PropTypes.func,
  validCureLimits: PropTypes.bool,
  allowOrderUpdate: PropTypes.bool
};

export default Totals;
