import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { I18n } from 'react-redux-i18n';
import { push } from 'react-router-redux';
import get from 'lodash.get';
import isEqual from 'lodash.isequal';
import omit from 'lodash.omit';
import {pick} from 'lodash';
import { CancelToken } from 'axios';
import { getUnpaginatedData, getDataByPost, getSearchData, postItem, getItem } from '../../actions/apiActions';
import {setData, unsetData} from '../../actions/dataActions';
import {getTestResultDimensions} from '../../selectors/testResultsSelectors';
import {getFacilityHasModule, getFacilityTitle} from '../../selectors/facilitiesSelectors';
import { isLeafIntegrator } from '../../selectors/integration/leafSelectors';
import { isBiotrackIntegrator } from '../../selectors/integration/biotrackSelectors';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import TablePageWrapper from '../common/grid/TablePageWrapper';
import AbstractTestResultListing from './common/AbstractTestResultListing';
import getColumns from './common/columns';
import { getTotalResults } from '../../selectors/paginationSelectors';
import { getDataUpdateAvailable } from '../../selectors/dataUpdateSelectors';
import { getIntegrationState } from '../../selectors/integration/integrationSelectors';
import ModalWrapper from '../common/ModalWrapper';
import { fetchIsolocityTestResults } from '../../actions/integrations/isolocity';
import { labResultsHasEditPermission } from '../../selectors/accessSelectors';
import {isFeatureEnabled, getFeatureEnabledList} from '../../selectors/featureToggles';
import { setSolrErrorMessage } from '../../actions/solrActions';
import { getTabs } from './common/tabDefinitions';

export class TestResultListingPage extends AbstractTestResultListing {
  constructor(props, context) {
    super(props, context);
    this.handleSearch = this.handleSearch.bind(this);
    this.switchTab = this.switchTab.bind(this);
    this.onTabChanged = this.onTabChanged.bind(this);
    this.filter = this.filter.bind(this);
    this.reload = this.reload.bind(this);
    this.ensureToken = this.ensureToken.bind(this);
    this.clearTokens = this.clearTokens.bind(this);
    this.cancelTokens = this.cancelTokens.bind(this);
    this.viewResultsButton = this.viewResultsButton.bind(this);
    this.toggleViewDetails = this.toggleViewDetails.bind(this);
    this.expandComponent = this.expandComponent.bind(this);
    this.setBodyState = this.setBodyState.bind(this);
    this.importBiotrackResults = this.importBiotrackResults.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
    this.onHide = this.onHide.bind(this);

    this.loadLabResultReferenceObjects = this.loadLabResultReferenceObjects.bind(this);

    const tabActionsConfig = {
      importResultsBiotrack: {
        func: this.importBiotrackResults,
        hidden: () => !this.props.isBiotrack
      },
      updateSearch: {
        func: this.updateSearch,
        disabled: () => this.state.reindexing
      }
    };
    const tabs = getTabs(tabActionsConfig);

    const expanded = [];
    this.state = {
      tabs,
      expanded,
      cancelTokens: {},
      reindexing: false,
      showModal: false,
      searchParams: {}
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.labResultDimensions !== this.props.labResultDimensions) {
      const { activeColumns, historicalColumns, averagesColumns } = getColumns(
        this,
        this.props.integrationState,
        this.props.hasEditPermission,
        this.props.labResultDimensions,
        this.props.displayCompletionStatus,
        this.props.featureEnabledList,
      );

      this.setState({ //eslint-disable-line
        activeColumns: activeColumns,
        historicalColumns: historicalColumns,
        averagesColumns: averagesColumns,
      });
    }
  }

  componentDidMount() {
    const { integrationState, params } = this.props;
    this.switchTab(params.tab) || this.onTabChanged(params.tab, true);

    if (integrationState.isIsolocity) {
      this.props.actions.fetchIsolocityTestResults();
    }

    Promise.all([
      this.props.actions.getItem(`/api/lab_results/configuration`, itemNames.testResultConfiguration, null),
      this.props.actions.getItem(`/api/compliance_settings`, itemNames.complianceSettings, null)
    ])
      .then(() => {
        const { activeColumns, historicalColumns, averagesColumns } = getColumns(
          this,
          this.props.integrationState,
          this.props.hasEditPermission,
          this.props.labResultDimensions,
          this.props.displayCompletionStatus,
          this.props.featureEnabledList,
        );

        this.setState({ //eslint-disable-line
          activeColumns: activeColumns,
          historicalColumns: historicalColumns,
          averagesColumns: averagesColumns,
          ready: true,
        });

      })
      .catch(() => this.setState({ ready: true })); //eslint-disable-line
  }

  componentWillReceiveProps(newProps) {
    if (newProps.params.tab !== this.props.params.tab) {
      this.onTabChanged(newProps.params.tab || '', true);
    }
  }

  componentWillUnmount() {
    this.cancelTokens('search');
  }

  onEdit(event, id) {
    event.stopPropagation();
    this.props.actions.push(`/test-results/modify/${id}`);
  }

  onTabChanged(activeTab, clearExpand) {
    this.filter(activeTab);
    if (clearExpand) {
      this.setState({ expanded: [] });
      this.setBodyState({ expanding: [] });
    }
  }

  filter(tab) {
    if (tab === 'historical') {
      this.ref.current &&
        this.ref.current.wrappedInstance.filter(
          '((is_active_package:0 OR is_latest_lab_result:0) AND NOT (deleted_at:[* TO *]))'
        );
    } else {
      this.ref.current &&
        this.ref.current.wrappedInstance.filter(
          '(is_active_package:1 AND is_latest_lab_result:1 AND NOT deleted_at:[* TO *])'
        );
    }
    this.reload(true);
  }

  reload(unsetData) {
    unsetData && this.props.actions.unsetData(dataNames.testResultGroups);
    unsetData && this.props.actions.unsetData(dataNames.testResults);
    // this.ref.current && this.ref.current.wrappedInstance.getBaseRef().handleExportCSV();
  }

  importBiotrackResults() {
    this.setState({ showModal: true });
    return this.props.actions
      .getDataByPost('/api/biotrack/import_lab_results_manual', '', {
        failed: 'cultivation.testResults.get.biotrackFailed'
      })
      .then(() => this.reload());
  }

  ensureToken(name) {
    const oldToken = this.state.cancelTokens[name];
    oldToken && oldToken.cancel('Concurrency request');
    const newToken = CancelToken.source();
    this.setState({
      cancelTokens: {
        ...this.state.cancelTokens,
        [name]: newToken
      }
    });
    return newToken;
  }

  clearTokens(...names) {
    this.setState({ cancelTokens: omit(this.state.cancelTokens, names) });
  }

  cancelTokens(...names) {
    const tokens = pick(this.state.cancelTokens, names);
    tokens && Object.keys(tokens).forEach((key) => tokens[key].cancel('Cancel request'));
    this.clearTokens(...names);
  }

  handleSearch(sort, query = 'matchall', size, start, filter) {
    //Skip searching before the tab filter is set
    if (!filter) {
      return;
    }
    const group = { //eslint-disable-line
      'group': 'true',
      'group.format': 'grouped',
      'group.field': 'lab_results_id',
      'group.ngroups': 'true'
    };
    const config = {
      errorHandler: {
        message: 'cultivation.finishedProduct.table.solrError',
        action: this.props.actions.setSolrErrorMessage,
        clearOnSuccess: true,
      }
    };
    const params = { sort: sort || 'lab_results_id desc', query, size, start, filter, group };

    // Hack to prevent unnecessary searches. If none of the search params have changed, there is no need to redo the search
    if (isEqual(this.state.searchParams, params)) {
      return;
    }
    this.setState({searchParams: params});

    const cancelToken = this.ensureToken('search');
    this.props.actions
      .getSearchData(
        '/api/search/lab_results',
        dataNames.testResultGroups,
        null,
        params,
        (groups) => {
          this.clearTokens('search');
          this.fetchChildren(groups, filter);
        },
        cancelToken.token,
        config
      )
      .catch((error) => {
        if (!error.__CANCEL__) {
          return Promise.reject(error);
        }
      });
  }

  fetchChildren(groups, tabFilter) {
    if (groups) {
      // Convert SOLR result into array of TestResults
      const testResults = groups.map((group) => get(group, 'doclist.docs.0'));
      const labResultsIds = testResults.map((tr) => tr.lab_results_id);
      this.props.actions.getDataByPost('/api/lab_results/multiple', { ids: labResultsIds, with_relations: 'references' }).then((data) => {
        // Add details to testResults
        const testResultsWithDetails = [];
        testResults.forEach((tr, idx) => {
          const index = data.findIndex((trd) => trd.id === tr.lab_results_id);
          const testResultDetails = data[index];
          delete testResultDetails.id; // This is the test_result_id. We don't want to override the package id (key: id) in the testResultWithDetails
          const testResultWithDetails = {...tr, ...testResultDetails};
          testResultsWithDetails.push(testResultWithDetails);
        });
        this.props.actions.setData(testResultsWithDetails, dataNames.testResults);
      });
    }
  }

  updateSearch() {
    this.props.actions.postItem('/api/search/update', { core: 'lab_results' }, null, {
      success: 'plants.actions.updateSearchSuccess',
      fail: 'plants.actions.updateSearchFail'
    });
    this.setState({ reindexing: true, searchParams: {} });
  }

  onHide() {
    this.setState({ showModal: false });
  }

  render() {
    const { tabs, activeColumns, historicalColumns, expanded } = this.state;
    const expanding = expanded.map((row) => row.id);
    const activeTab = this.props.params.tab || 'active';
    const { dataTotalSize, dataUpdateAvailable, testResults } = this.props;

    return (
      <div className='test-results-wrapper'>
        <ModalWrapper
          Component={false}
          title={I18n.t('cultivation.testResults.importingTestResults')}
          headerClass='bg-info-dark'
          onHide={this.onHide}
          showModal={this.state.showModal}
          okayButton={{ show: true, onClick: this.onHide }}
          dialogClassName='modal-sm'
          version={2}
        >
          <div>{I18n.t('cultivation.testResults.importTestNotification')}</div>
        </ModalWrapper>

        <TablePageWrapper
          ref={this.ref}
          settingKey='test-results'
          handleSelect={() => {}}
          selectedRows={[]}
          dataUpdateAvailable={dataUpdateAvailable}
          dataUpdated={() => this.setState({ reindexing: false })}
          data={testResults}
          dataTotalSize={dataTotalSize}
          tabs={tabs}
          activeTab={activeTab}
          switchTab={this.switchTab}
          hideScanSearch={true}
          columns={activeTab === 'historical' ? historicalColumns : activeColumns}
          externalSearch={this.handleSearch}
          external={true}
          autoRefresh={10000}
          hideExport={true}
          useAutoSuggest={this.props.useAutoSuggest}
          autoSuggestPlaceholder='cultivation.testResults.suggestPlaceholder'
          bstProps={{
            selectRow: {
              clickToSelect: false,
              clickToExpand: false,
              hideSelectColumn: true,
              selected: []
            },
            expandableRow: () => true,
            expandComponent: (row) => {
              const dataConfig = this.props.labResultDimensions;
              return this.expandComponent(row, dataConfig);
            },
            options: {
              //After setState the array is mutating so workaround is to use slice
              onRowClick: () => this.setBodyState({ expanding: expanding.slice() })
            }
          }}
        />
      </div>
    );
  }
}

TestResultListingPage.propTypes = {
  testResults: PropTypes.array.isRequired,
  facilityTitle: PropTypes.string.isRequired,
  dataTotalSize: PropTypes.number,
  params: PropTypes.shape({
    tab: PropTypes.string
  }).isRequired,
  actions: PropTypes.shape({
    push: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    getSearchData: PropTypes.func.isRequired,
    getDataByPost: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired
  }).isRequired,
  labResultDimensions: PropTypes.object,
  isLeaf: PropTypes.bool,
  dataUpdateAvailable: PropTypes.array.isRequired,
  hasEditPermission: PropTypes.bool,
  displayCompletionStatus: PropTypes.bool,
  useAutoSuggest: PropTypes.bool,
  featureEnabledList: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  const displayCompletionStatus = getFacilityHasModule(state)('TESTING_LAB');
  return {
    facilityTitle: getFacilityTitle(state) || '',
    testResults: state.testResults,
    dataTotalSize: getTotalResults(state, { name: dataNames.testResultGroups }),
    dataUpdateAvailable: [getDataUpdateAvailable(state, { name: dataNames.testResults, core: 'lab_results' })],
    isLeaf: isLeafIntegrator(state),
    isBiotrack: isBiotrackIntegrator(state),
    integrationState: getIntegrationState(state),
    labResultDimensions: getTestResultDimensions(state),
    hasEditPermission: labResultsHasEditPermission(state),
    displayCompletionStatus,
    useAutoSuggest: isFeatureEnabled(state)('feature_solr_inventory_suggest'),
    featureEnabledList: getFeatureEnabledList(state)(true),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    push,
    setData,
    unsetData,
    getUnpaginatedData,
    getItem,
    getSearchData,
    getDataByPost,
    postItem,
    fetchIsolocityTestResults,
    setSolrErrorMessage,
  };
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TestResultListingPage);
