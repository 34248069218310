export const getCustomReportLink = (customReports, facilityId) => {
  const getReportByFacilityId = (id) => {
    const link = customReports.find((report) => {
      return id === null
        ? report.facility_id === id || report.facility_id === 0
        : report.facility_id === id;
    });
    return link || {};
  };

  const facilityLink = getReportByFacilityId(facilityId);
  return facilityLink.report_url
    ? facilityLink.report_url
    : getReportByFacilityId(null).report_url;
};

const getVerticalPageOverhead = (classNames) => {
  const getHeight = (el) => {
    const styles = window.getComputedStyle(el);
    const aggregateAttributes = ['marginTop', 'marginBottom', 'borderTopWidth', 'borderBottomWidth'];
    const margin = aggregateAttributes.reduce((acc, attribute) => {
      const value = parseFloat(styles[attribute]);
      if (!isNaN(value)) {
        acc += value;
      }
      return acc;
    }, 0);
    return Math.ceil(el.offsetHeight + margin);
  };

  return classNames.reduce((acc, className) => {
    const elementsCollection = document.getElementsByClassName(className);
    const elementsArray = Array.from(elementsCollection);
    const height = elementsArray.reduce((acc, element) => {
      acc += getHeight(element);
      return acc;
    }, 0);
    acc += height;
    return acc;
  }, 0);
};

export const resizeIframe = (targetId, classNames, addPixels = 0) => {
  const iframe = document.getElementById(targetId);
  const offset = getVerticalPageOverhead(classNames);
  const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  const iframeHeight = windowHeight - (offset + addPixels);
  iframe.style.height = `${iframeHeight}px`;
};

export default getCustomReportLink;
