import {maturePlantsRequireTracking, maturePlantsRequireTrackingErrorMessage} from '../selectors/plantsSelectors';
import {addMessage} from './systemActions';

export const dummy = () => {};

export function validateDestroyPlants() {
  return (dispatch, getState) => {

    const state = getState();
    const {selectedPlants} = state;


    if (maturePlantsRequireTracking(getState())) {
      const somePlantsDontHaveTrackingIds = selectedPlants.some((plant) => {
        if (plant.stage_name !== 'Propagation') {
          if ( !plant.state_integration_tracking_id ) return true;
          if ( plant.tag_requested ) return true;
        }
      });

      if ( somePlantsDontHaveTrackingIds ) {
        dispatch(addMessage('error', maturePlantsRequireTrackingErrorMessage(getState())));
        return false;
      }
    }

    return true;
  };
}
