/* eslint-disable import/prefer-default-export*/
import {LOCATION_CHANGE} from 'react-router-redux';
import {LOGOUT_SUCCESS} from '../constants/actionTypes';
import {getUserValid} from '../actions/userActions';

export const validateAuth = store => next => action => {
  const result = next(action);
  if(action.type === LOCATION_CHANGE){
    const user = store.getState().user;
    if (user.authenticated) {
      store.dispatch(getUserValid());
    }
  }else if(action.type === LOGOUT_SUCCESS){
    localStorage.removeItem('Authorization');
    localStorage.removeItem('state');
    localStorage.removeItem('facility');
    localStorage.removeItem('login');
    localStorage.removeItem('metrcReconciliations');
  }
  return result;
};
