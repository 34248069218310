/* eslint-disable react/no-multi-comp */
import get from 'lodash.get';
import React from 'react';
import moment from 'moment';
import {Button} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import InternationalDateStatic from '../../common/form/InternationalDateStatic';

export default function(component) {
  const columns = [
    {
      name: 'common.location',
      dataId: 'model',
      formatter: cell => cell && cell.location_name || '-',
      width: '100px',
      dataSort: false,
      headerAlign: 'left',
      dataAlign: 'left',
      mode: 'package',
    },
    {
      name: 'common.action',
      dataId: 'message',
      width: '100px',
      dataSort: false,
      headerAlign: 'left',
      dataAlign: 'left',
    },
    {
      name: 'common.employee',
      dataId: 'user_name',
      width: '70px'
    },
    {
      name: 'common.actionDate',
      dataId: 'event_date',
      width: '60px',
      sortBy: 'event_date_sort',
      formatter: (cell, row) => {
        const actionDate = get(row, 'event_date') || get(row, 'model.last_transaction_time');
        return actionDate ? <InternationalDateStatic displayTime={true} useSystemDate={false}>{moment(actionDate)}</InternationalDateStatic> : null;
      }
    },
    {
      name: 'common.entryDate',
      dataId: 'created_at',
      width: '60px',
      sortBy: 'created_at_sort',
      formatter: (cell) => <InternationalDateStatic>{moment(cell)}</InternationalDateStatic>
    },
    {
      name: 'harvest.startingLevel',
      dataId: 'level_before',
      width: '60px',
      sortBy: 'level_before_sort',
      mode: 'item',
    },
    {
      name: 'harvest.startingLevel',
      dataId: 'package_level_before',
      width: '60px',
      sortBy: 'package_level_before_sort',
      mode: 'package',
    },
    {
      name: 'harvest.newLevel',
      dataId: 'level_after',
      width: '60px',
      sortBy: 'level_after_sort',
      mode: 'item',
    },
    {
      name: 'harvest.newLevel',
      dataId: 'package_level_after',
      width: '60px',
      sortBy: 'package_level_after_sort',
      mode: 'package',
    },
    {
      name: 'common.notes',
      dataId: 'notes',
      formatter: (cell, row) => {
        return (
          <Button variant='primary' bsSize='xsmall' disabled={!(row.notes && row.notes.length)} onClick={event => component.openNotesModal(event, row.notes)}>
            {I18n.t('customers.table.view')}
          </Button>
        );
      },
      width: '50px',
      dataSort: false,
    },
  ];

  return ['item', 'package'].reduce(
    (acc, mode) => ({
      ...acc,
      [mode]: columns.filter(c => c.mode === undefined || c.mode === mode),
    }),
    {}
  );
}
