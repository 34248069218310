import React from 'react';

const RestockItemsHeader = (props) => {

  return (
    <thead>
    <tr>
      <th colSpan={4}>Restock Items</th>
    </tr>
    <tr>
      <th>Product</th>
      <th>Order</th>
      <th style={{textAlign: 'right'}}>Qty.</th>
    </tr>
    </thead>
  );

};

export default RestockItemsHeader;
