import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { I18n } from 'react-redux-i18n';

import { isFeatureEnabled } from '../../selectors/featureToggles';
import AppNavigationCardList from '../navigation/AppNavigationCardList';
import {getActiveFacility} from '../../selectors/facilitiesSelectors';
import {getItem} from '../../actions/apiActions';


export class LandingPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
  }

  render() {
    const { availableApps, isAkernaRebrandingDemo } = this.props;
    const company = isAkernaRebrandingDemo ? 'Akerna' : 'MJ Freeway';
    const welcomeTo = isAkernaRebrandingDemo ? 'Akerna' : 'MJ Platform';

    return (
      <div className='landing-page'>
        <h2 className='branding-title'>{I18n.t('landing.logo', { company })}</h2>
        <div className='branding-header layout-column layout-align-start-center'></div>

        <h2 className={'mjp-welcome' + (isAkernaRebrandingDemo ? ' text-center' : '')}>
          {I18n.t('landing.welcomeMessage', { welcomeTo })}
        </h2>

        <div className={'layout-row layout-wrap app-group' + (isAkernaRebrandingDemo ? ' layout-align-center' : '')}>
          <AppNavigationCardList apps={availableApps} />
        </div>
      </div>
    );
  }
}

LandingPage.propTypes = {
  actions: PropTypes.shape({
    getItem: PropTypes.func.isRequired
  }).isRequired,
  facility: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  facility: getActiveFacility(state),
  isAkernaRebrandingDemo: isFeatureEnabled(state)('feature_akerna_rebranding_demo'),
});

function mapDispatchToProps(dispatch) {
  const actions = {
    getItem
  };
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (LandingPage);
