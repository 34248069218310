import {createSelector} from 'reselect';
import get from 'lodash.get';
import {set} from 'lodash';
import * as itemNames from '../../constants/itemNames';

const getPricingMultiplier = (state) => state[itemNames.pricingMultiplier];

export const getInitialValues = createSelector(
  [getPricingMultiplier],
  (pricing_multiplier) => {

    const pricing_tiers = get(pricing_multiplier, 'pricing_tiers', {});
    // Loop over both pricing tiers
    for (const [key, pricing_tier] of Object.entries(pricing_tiers)) {
      const tiers = [];
      get(pricing_tier, 'tiers', []).forEach((tier) => {
        const tier_qty = get(tier, 'tier');
        const tier_price = get(tier, 'price');
        if (tier_qty && tier_price) {
          if (tier_qty === 1 && tier_price) {
            set(tiers, '0.override', true);
          }
          set(tiers, (tier.tier - 1) + '.price', tier.price);
        }
      });
      // Loop over all tiers and fill the gaps with empty objects
      for (let i = 0; i < tiers.length; i++) {
        tiers[i] = get(tiers, i.toString(), {price: ''}); // Populate empties with empty price to prevent sparse array from collapsing
      }
      pricing_tier.tiers = tiers;
      set(pricing_tiers, key, pricing_tier);
    }

    return {
      id: get(pricing_multiplier, 'id'),
      name: get(pricing_multiplier, 'name'),
      mix_and_match: get(pricing_multiplier, 'mix_and_match'),
      item_master_ids: get(pricing_multiplier, 'item_master_ids', []),
      pricing_tiers
    };
  });

export const getPayload = (formValues) => {
  const payload =  JSON.parse(JSON.stringify(formValues)); // clone
  const pricing_tiers = get(payload, 'pricing_tiers', {});
  // Loop over both pricing tiers
  for (const [key, pricing_tier] of Object.entries(pricing_tiers)) {
    const tiers = [];
    get(pricing_tier, 'tiers', []).forEach((tier, index) => {
      if (Object.keys(tier).length) {
        tiers.push({tier: index + 1, price: tier.price});
      }
    });
    set(payload, `pricing_tiers.${key}.tiers`, tiers);
  }

  return payload;
};

export default getPricingMultiplier;
