import React from 'react';
import PropTypes from 'prop-types';

const ImportOrdersViewTableOrderInfoProducts = ({ products }) => (
  <tbody>
  {products.map((row, key) => (
    <tr key={key}>
      <td>{row.product}</td>
      <td>{row.itemMasterId}</td>
      <td>{row.packageId}</td>
      <td>{row.unit}</td>
      <td>{row.location}</td>
      <td>${row.price}</td>
      <td style={{color: 'red'}} title={row.notes}>{row.notes}</td>
    </tr>
  ))}
  </tbody>
);


ImportOrdersViewTableOrderInfoProducts.propTypes = {
  products: PropTypes.array.isRequired,
};

export default ImportOrdersViewTableOrderInfoProducts;

