import React from 'react';
import {Field} from 'redux-form';
import {Table} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import ReactSelectInput from '../../../common/form/ReactSelectInput';

const yesNoOptions = [
  {text: 'Yes', value: '1'},
  {text: 'No', value: '0'},
];

const CourierHomeDeliverySettings = props => {
  return (
    <Table striped={true} bordered={true}>
      <tbody>
        <tr>
          <td>
            {I18n.t('deliveryOrders.setup.form.enableManifestGeneration')}
          </td>
          <td>
            <Field
              name='enable_manifest_generation'
              component={ReactSelectInput}
              props={{
                options: yesNoOptions,
                clearable: false,
                groupClassName: 'thin',
                disabled: true
              }}
            />
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default CourierHomeDeliverySettings;