import { getFormValues } from 'redux-form';
import get from 'lodash.get';

import * as itemNames from '../../../constants/itemNames';
import { getGroupedItemMasterChildren, isAllowInitialInventory } from '../../../selectors/fillPurchaseOrderSelectors';
import {
  getIntegrationState,
  isIncomingTransferMappingEnabled, isIncomingTransferMappingRequired
} from '../../../selectors/integration/integrationSelectors';
import { getCurrentFacilityUserOptions, userHasPermission } from '../../../selectors/usersSelectors';
import * as p from '../../../constants/permissions';
import {
  getPartnerFacilitiesByPartner,
  getPartnerFacilitiesForPurchaseOrder,
  getPartnerIntegrations,
  getPartnersForPurchase
} from '../../../selectors/partnersSelectors';
import {
  getCreatePurchaseOrderInitialValues,
  getItemMasters,
  getMedicatedStatus, getModifiedLinesByItemMaster, getModifyPurchaseOrderInitialValues,
  getOrderTotals,
  getProductOptions,
  getReceiveTransferButtonStatus,
  getRegistersByType,
  getRegistersWithNoRegisterOption,
  hasAllCbdFlowerLines,
  hasAllMedicated,
  hasAllNonMedicated, hasItemMasters,
  isLeafReturn, isMedicatedStatusSelected
} from '../../../selectors/purchaseOrdersSelectors';
import {getActiveFacility, isLeafPaConfigPackClosedLoopFacility} from '../../../selectors/facilitiesSelectors';
import {
  canChoosePartner,
  canCreateMatchingOrder,
  getRenderFacts
} from '../../../selectors/forms/purchaseOrderFormSelectors';
import { formatClientDate } from '../../../util/dateHelpers';

import { isMappingRequiredForSupply } from '../../../selectors/supplySelectors';
import { getFlattenedLocations, getLocationsForSharedProducts } from '../../../selectors/locationsSelectors';
import { getTransferLinesLimit } from '../../../selectors/salesOrdersSelectors';
import * as dataNames from '../../../constants/dataNames';

import { PURCHASE_ORDER_FORM } from '../../../constants/forms';
import { pathnameContains } from '../../../util/routeHelper';
import { isMetrcTransfersEnabled } from '../../../selectors/integration/metrcSelectors';
import {isFeatureEnabled} from '../../../selectors/featureToggles';


const formFields = [
  'afterSubmit', 'lines', 'transfer_fee', 'discount_percent', 'taxes', 'payments', 'partner_id',
  'date_ordered', 'is_initial_mode', 'generate_internal_sales_order', 'update_internal_sales_order', 'vendor_facility_id'
];

export const getUnifiedMapStateToProps = (state, ownProps, selector, errorSelector) => {
  const isCreate = pathnameContains(ownProps, 'create');

  const {
    afterSubmit,
    lines,
    transfer_fee,
    discount_percent,
    taxes,
    payments,
    partner_id,
    date_ordered,
    is_initial_mode,
    generate_internal_sales_order,
    update_internal_sales_order,
    vendor_facility_id,
  } = selector(state, ...formFields);

  const {paymentTerms, paymentTypes, uoms, pricingWeights, pricingGroups, categories} = state;
  const purchaseOrder = state[itemNames.purchaseOrder];
  const allowInitialInventory = isAllowInitialInventory(state);
  const isInitialMode = isCreate
    ? allowInitialInventory && (is_initial_mode || is_initial_mode === undefined)
    : purchaseOrder && purchaseOrder.is_initial;
  const integrationState = getIntegrationState(state);
  const externalEnabled = userHasPermission(state, {permissions: [p.view_partners, p.manage_partners]});
  const partners = getPartnersForPurchase(state);
  const {balance, total, oddMoney} = getOrderTotals(lines, transfer_fee, taxes, discount_percent, payments);
  const hasAllMedicatedProducts = hasAllMedicated(state);
  const hasAllNonMedicatedProducts = hasAllNonMedicated(state);
  const hasAllMedicatedOrNonMedicated = hasAllMedicatedProducts || hasAllNonMedicatedProducts;
  const hasAllCbdFlower = hasAllCbdFlowerLines(state);

  // These are consistent for create and update modes
  const createAndUpdate = {
    afterSubmit, // I think this can be nuked
    activeFacilityType: getActiveFacility(state).type,
    allowInitialInventory,
    canCreateMatchingOrder: canCreateMatchingOrder(state),
    categories,
    dateOrdered: formatClientDate(date_ordered),
    errors: errorSelector(state),
    facilityCountryCode: get(state[itemNames.facility], 'country_code', null),
    forceMedicatedMapping: isIncomingTransferMappingEnabled(state) && (isIncomingTransferMappingRequired(state) || !isInitialMode),
    generateInternalSalesOrder: generate_internal_sales_order,
    updateInternalSalesOrder: update_internal_sales_order,
    integrationState,
    isLeafPaClosedLoopFacility: isFeatureEnabled(state)('feature_leaf_pa_configuration_pack') && isLeafPaConfigPackClosedLoopFacility(state),
    isMappingRequiredByPlatform: isMappingRequiredForSupply(state),
    isReturn: isLeafReturn(state),
    itemMasterChildren: getGroupedItemMasterChildren(state),
    itemMasters: getProductOptions(state),
    lines,
    locations: getFlattenedLocations(state),
    medicatedStatus: getMedicatedStatus(state),
    oddMoney,
    partnerIntegrations: getPartnerIntegrations(state),
    partnerId: partner_id,
    partners: externalEnabled ? partners : partners.filter(partner => partner.is_internal_partner),
    partnerFacilitiesAreLoaded: state[dataNames.partnerFacilities].length,
    paymentTerms,
    paymentTypes,
    pricingGroups,
    pricingWeights,
    registers: getRegistersWithNoRegisterOption(state),
    registersByType: getRegistersByType(state),
    renderFacts: getRenderFacts(state),
    renderFlags: state[itemNames.rules].default || {},
    sharedLocations: getLocationsForSharedProducts(state),
    timezone: state.timezone,
    transferLinesLimit: getTransferLinesLimit(state),
    uoms,
    users: getCurrentFacilityUserOptions(state),
    payments,
    hasAllCbdFlower
  };

  // These are dependent on mode and provide undefined if they don't apply.
  const createOrUpdate = {
    allItemMastersForPartner: isCreate ? getItemMasters(state) : undefined,
    allPartnerFacilities: isCreate ? undefined : state[dataNames.partnerFacilities],
    balance,
    canReceiveTransfer: isCreate ? true : getReceiveTransferButtonStatus(state),
    canChoosePartner: isCreate ? true : canChoosePartner(state),
    currentFormValues: isCreate ? undefined : getFormValues(PURCHASE_ORDER_FORM)(state),
    externalEnabled: isCreate ? userHasPermission(state, {permissions: [p.view_partners, p.manage_partners]}) : undefined,
    hasAllMedicated: isCreate ? hasAllMedicated : undefined,
    hasAllMedicatedOrNonMedicated: isCreate ? hasAllMedicatedOrNonMedicated : undefined,
    hasAllNonMedicated: isCreate ? hasAllNonMedicated : undefined,
    hasItemMasters: isCreate ? hasItemMasters(state) : undefined,
    initialValues: isCreate
      ? getCreatePurchaseOrderInitialValues(state)
      : getModifyPurchaseOrderInitialValues(state),
    isInitialMode: isCreate ? isInitialMode : undefined,
    isMedicatedStatusSelected: isCreate ? isMedicatedStatusSelected(state) : undefined,
    metrcIsImported: isCreate ? false : get(integrationState, 'isMetrc') && get(purchaseOrder, 'is_imported', 0),
    modifiedLinesByItemMaster: isCreate ? undefined : getModifiedLinesByItemMaster(state),
    partnerFacilities: isCreate
      ? getPartnerFacilitiesForPurchaseOrder(state, {partner_id})
      : getPartnerFacilitiesByPartner(state, {partner_id}),
    purchaseOrder: isCreate ? {} : purchaseOrder,
    purchaseOrders: isCreate ? [] : state[dataNames.purchaseOrders],
    selectedPartnerFacilityId: isCreate ? vendor_facility_id : null,
    total,
  };

  return Object.assign({}, createAndUpdate, createOrUpdate);
};

//@TODO: Nuke this if we have no need to switch it back on by June.
export const getCreateMapStateToProps = (state, ownProps, selector, errorSelector) => {

  const {
    afterSubmit,
    lines,
    transfer_fee,
    discount_percent,
    taxes,
    payments,
    partner_id,
    date_ordered,
    is_initial_mode,
    generate_internal_sales_order,
    update_internal_sales_order,
    vendor_facility_id,
  } = selector(state, ...formFields);

  const {paymentTerms, paymentTypes, uoms, pricingWeights, pricingGroups, categories} = state;
  const {balance, total, oddMoney} = getOrderTotals(lines, transfer_fee, taxes, discount_percent, payments);
  const externalEnabled = userHasPermission(state, {permissions: [p.view_partners, p.manage_partners]});
  const partners = getPartnersForPurchase(state);
  const hasAllMedicatedProducts = hasAllMedicated(state);
  const hasAllNonMedicatedProducts = hasAllNonMedicated(state);
  const hasAllMedicatedOrNonMedicated = hasAllMedicatedProducts || hasAllNonMedicatedProducts;
  const allowInitialInventory = isAllowInitialInventory(state);
  const isInitialMode = allowInitialInventory && (is_initial_mode || is_initial_mode === undefined);
  const initialValues = getCreatePurchaseOrderInitialValues(allowInitialInventory, isInitialMode);

  // Is mapping required by integration:
  const forceMedicatedMapping = isIncomingTransferMappingEnabled(state) && (isIncomingTransferMappingRequired(state) || !isInitialMode);
  // Is mapping required by platform settings:
  const isMappingRequiredByPlatform = isMappingRequiredForSupply(state);

  const facilityCountryCode = get(state[itemNames.facility], 'country_code', null);
  const partnerFacilities = getPartnerFacilitiesForPurchaseOrder(state, {partner_id});
  const activeFacilityType = getActiveFacility(state).type;

  return {
    activeFacilityType,
    afterSubmit,
    allItemMastersForPartner: getItemMasters(state),
    allowInitialInventory,
    balance,
    canCreateMatchingOrder: canCreateMatchingOrder(state),
    canReceiveTransfer: true,
    categories,
    dateOrdered: formatClientDate(date_ordered),
    errors: errorSelector(state),
    externalEnabled,
    facilityCountryCode,
    forceMedicatedMapping,
    generateInternalSalesOrder: generate_internal_sales_order,
    updateInternalSalesOrder: update_internal_sales_order,
    hasAllMedicated: hasAllMedicatedProducts,
    hasAllMedicatedOrNonMedicated,
    hasAllNonMedicated: hasAllNonMedicatedProducts,
    hasItemMasters: hasItemMasters(state),
    initialValues,
    integrationState: getIntegrationState(state),
    isInitialMode,
    isMappingRequiredByPlatform,
    isMedicatedStatusSelected: isMedicatedStatusSelected(state),
    isMetrcTransfersEnabled: isMetrcTransfersEnabled(state),
    isReturn: isLeafReturn(state),
    itemMasterChildren: getGroupedItemMasterChildren(state),
    itemMasters: getProductOptions(state),
    lines,
    locations: getFlattenedLocations(state),
    medicatedStatus: getMedicatedStatus(state),
    oddMoney,
    partnerFacilities,
    partnerId: partner_id,
    partnerIntegrations: getPartnerIntegrations(state),
    partners: externalEnabled ? partners : partners.filter(partner => partner.is_internal_partner),
    paymentTerms,
    paymentTypes,
    pricingGroups,
    pricingWeights,
    purchaseOrder: state[itemNames.purchaseOrder],
    registers: getRegistersWithNoRegisterOption(state),
    registersByType: getRegistersByType(state),
    renderFacts: getRenderFacts(state),
    renderFlags: state[itemNames.rules].default || {},
    selectedPartnerFacilityId: vendor_facility_id,
    sharedLocations: getLocationsForSharedProducts(state),
    timezone: state.timezone,
    total,
    transferLinesLimit: getTransferLinesLimit(state),
    uoms,
    users: getCurrentFacilityUserOptions(state),
  };

};

//@TODO: Nuke this if we have no need to switch it back on by June.
export const getModifyMapStateToProps = (state, ownProps, selector, errorSelector) => {

  const {
    afterSubmit,
    lines,
    transfer_fee,
    discount_percent,
    taxes,
    payments,
    partner_id,
    date_ordered,
    is_initial_mode, //eslint-disable-line
    generate_internal_sales_order,
    update_internal_sales_order,
    vendor_facility_id, //eslint-disable-line
  } = selector(state, ...formFields);

  const {uoms, pricingGroups, pricingWeights} = state;

  // Well, this code below creates problems with decimals because  qty * price is not always equal to the line_item_price if the latter is being overriden by a user;
  // even when we recalculate the price, limit on precision inherited from money fields (we cannot charge half a cent, can we?) is preventing the inverse function to be accurate.
  // Since we need only one source of truth we will forget about the editable field state and keep the line_item_price displayed in the form as the one and only source of truth.

  // let linesWithEditable = lines;
  // if(Array.isArray(lines)) {
  //   linesWithEditable = lines.map((line) => {
  //     if(line.editableLinePrice !== undefined) return line;
  //     const potentialTotal = (line.lineType === 'prepack')
  //       ? getLineTotals(line.subitems)
  //       : parseFloat(line.unit_price) * parseFloat(line.qty);
  //     const calculatedTotal = (potentialTotal.price !== undefined) ? potentialTotal.price : potentialTotal;
  //     line.editableLinePrice = (calculatedTotal !== round(parseFloat(line.line_item_price), 2));
  //     return line;
  //   });
  // }

  const {balance, total, oddMoney} = getOrderTotals(lines, transfer_fee, taxes, discount_percent, payments);
  const externalEnabled = userHasPermission(state, {permissions: [p.view_partners, p.manage_partners]});
  const partners = getPartnersForPurchase(state);
  const purchaseOrder = state[itemNames.purchaseOrder];
  const isInitialMode = purchaseOrder && purchaseOrder.is_initial;

  // Is mapping required by integration:
  const forceMedicatedMapping = isIncomingTransferMappingEnabled(state) && (isIncomingTransferMappingRequired(state) || !isInitialMode);
  // Is mapping required by platform settings:
  const isMappingRequiredByPlatform = isMappingRequiredForSupply(state);

  const facilityCountryCode = get(state[itemNames.facility], 'country_code', null);
  const activeFacilityType = getActiveFacility(state).type;
  const integrationState = getIntegrationState(state);
  const metrcIsImported = get(integrationState, 'isMetrc') && get(purchaseOrder, 'is_imported', 0);

  return {
    activeFacilityType,
    afterSubmit,
    allPartnerFacilities: state[dataNames.partnerFacilities],
    allowInitialInventory: isAllowInitialInventory(state),
    balance,
    canChoosePartner: canChoosePartner(state),
    canCreateMatchingOrder: canCreateMatchingOrder(state),
    canReceiveTransfer: getReceiveTransferButtonStatus(state),
    categories: state[dataNames.categories],
    currentFormValues: getFormValues(PURCHASE_ORDER_FORM)(state),
    dateOrdered: formatClientDate(date_ordered),
    errors: errorSelector(state),
    facilityCountryCode,
    forceMedicatedMapping,
    generateInternalSalesOrder: generate_internal_sales_order,
    updateInternalSalesOrder: update_internal_sales_order,
    initialValues: getModifyPurchaseOrderInitialValues(state),
    integrationState,
    isMappingRequiredByPlatform,
    isReturn: isLeafReturn(state),
    itemMasterChildren: getGroupedItemMasterChildren(state),
    itemMasters: getProductOptions(state),
    lines,
    locations: getFlattenedLocations(state),
    medicatedStatus: getMedicatedStatus(state),
    metrcIsImported,
    modifiedLinesByItemMaster: getModifiedLinesByItemMaster(state),
    oddMoney,
    partnerFacilities: getPartnerFacilitiesByPartner(state, {partner_id}),
    partnerId: partner_id,
    partnerIntegrations: getPartnerIntegrations(state),
    partners: externalEnabled ? partners : partners.filter(partner => partner.is_internal_partner),
    paymentTerms: state[dataNames.paymentTerms],
    paymentTypes: state[dataNames.paymentTypes],
    payments,
    pricingGroups,
    pricingWeights,
    purchaseOrder,
    purchaseOrders: state[dataNames.purchaseOrders],
    registers: getRegistersWithNoRegisterOption(state),
    registersByType: getRegistersByType(state),
    renderFacts: getRenderFacts(state),
    renderFlags: state[itemNames.rules].default || {},
    sharedLocations: getLocationsForSharedProducts(state),
    timezone: state.timezone,
    total,
    transferLinesLimit: getTransferLinesLimit(state),
    uoms,
    users: getCurrentFacilityUserOptions(state),
  };

};
