import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import {bindActionCreators} from 'redux';
import {Field} from 'redux-form';
import {Col, Row, ButtonToolbar, Button} from 'react-bootstrap';
import TextInput from '../../../../common/form/TextInput';
import ReactSelectInput from '../../../../common/form/ReactSelectInput';
import {getUnpaginatedData, postItem} from '../../../../../actions/apiActions';
import * as dataNames from '../../../../../constants/dataNames';
import {areAllProductsMappedToCategories} from '../../../../../selectors/integration/thirdPartyIntegrationSelectors';

export class Leafly extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.syncMenu = this.syncMenu.bind(this);
    this.navigateToCategories = this.navigateToCategories.bind(this);

    this.inventoryPreferenceOptions = [
      { text: 'Exclude out of stock', value: 'exclude_out_of_stock' },
      { text: 'Everything', value: 'everything' },
    ];

    this.activeOptions = [
      { text: 'Yes', value: true },
      { text: 'No', value: false },
    ];

    this.state = {
      error: '',
    };
  }

  componentDidMount(){
    const leaflyUrl = '/api/leafly/item_categories/mapping';
    const categoriesUrl = '/api/categories?include_product_counts=1&integration=leafly&paginate=0';
    this.props.actions.getUnpaginatedData(categoriesUrl, dataNames.categories);
    this.props.actions.getUnpaginatedData(leaflyUrl, dataNames.integrationCategoryMappings);
  }

  navigateToCategories(){
    this.props.actions.push('/category/management/leafly');
  }

  syncMenu() {
    const responses = {
      success: 'facility.setup.thirdPartyIntegration.leafly.resyncJobDispatched',
      failed: 'facility.setup.thirdPartyIntegration.leafly.resyncJobFailed'
    };
    this.props.actions.postItem('/api/leafly/sync-menu', null, '', responses);
  }

  render() {
    return (
      <div className='leafly-container'>
        <Row>
          <Col sm={12} md={3} lg={3}>
            {
              !this.props.productsFullyMapped
                ? (<div>
                  <div className='text-danger' style={{textAlign: 'center'}}>
                    {I18n.t('facility.setup.thirdPartyIntegration.leafly.categoryMapError')}
                  </div>
                  <Button variant='primary' className='btn-block' onClick={this.navigateToCategories}>
                    {I18n.t('facility.setup.thirdPartyIntegration.leafly.mapCategories')}
                  </Button>
                  <div className='text-muted' style={{textAlign: 'center', fontSize: 'smaller'}}>
                    {I18n.t('facility.setup.thirdPartyIntegration.leafly.synchronizeMessage')}
                  </div>
                </div>) : null
            }
            <p>{this.state.error}</p>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={4} lg={3}>
            <Field name='api_key' component={TextInput} props={{
              label: I18n.t('facility.setup.thirdPartyIntegration.leafly.leaflyApiKey'),
              isRequired: true,
            }} />
          </Col>
          <Col sm={6} md={4} lg={3}>
            <Field name='inventory_preference' component={ReactSelectInput} props={{
              label: I18n.t('facility.setup.thirdPartyIntegration.leafly.inventoryPreference'),
              isRequired: true,
              options: this.inventoryPreferenceOptions,
              dataId: 'value'
            }} />
          </Col>
          <Col sm={6} md={4} lg={3}>
            <Field name='active' component={ReactSelectInput} props={{
              label: I18n.t('common.active'),
              isRequired: true,
              options: this.activeOptions,
              dataId: 'value'
            }} />
          </Col>
          <Col sm={6} md={4} lg={3}>
            <Field name='is_sandbox' component={ReactSelectInput} props={{
              label: I18n.t('facility.setup.thirdPartyIntegration.leafly.isSandbox'),
              isRequired: true,
              options: this.activeOptions,
              dataId: 'value'
            }} />
          </Col>
        </Row>
        <Row className='submit-section'>
          <Col xs={12}>
            <div className='button-wrapper'>
              <ButtonToolbar className='float-right'>
                {this.props.productsFullyMapped ?
                  <Button variant='primary' className='menu-resync' onClick={this.syncMenu}>
                    {I18n.t('facility.setup.thirdPartyIntegration.leafly.dumpAndResync')}
                  </Button> : null}
                <Button variant='primary' type='submit'>
                  {I18n.t('common.form.save')}
                </Button>
              </ButtonToolbar>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state){
  return {
    categories: state[dataNames.categories],
    mappings: state[dataNames.integrationCategoryMappings],
    productsFullyMapped: areAllProductsMappedToCategories(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, {getUnpaginatedData, postItem, push});
  return {
    actions: bindActionCreators(actions, dispatch, push)
  };
}

Leafly.propTypes = {
  actions: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(Leafly);
