import {change, formValueSelector} from 'redux-form';

import {REGISTER_FORM} from '../../constants/forms';
import {isFormChangeOnField} from './utils';
import {addMessage} from '../../actions/systemActions';
import {warning} from '../../constants/messageTypes';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import {getDefaultRegister} from '../../selectors/registersSelectors';


const registerForm = store => next => action => {
  const {meta, payload} = action;

  if (isFormChangeOnField(action, [REGISTER_FORM], 'type')) {
    store.dispatch(change(meta.form, 'is_default_for_pos', 0));
  }

  if (isFormChangeOnField(action, [REGISTER_FORM], 'is_default_for_pos') && payload) {
    const state = store.getState();
    const selector = formValueSelector(REGISTER_FORM);
    const isActive = parseInt(selector(state, 'active'));
    const currentRegister = state[itemNames.register];
    const defaultRegister = getDefaultRegister(state[dataNames.registers], currentRegister);
    if (defaultRegister && isActive) {
      store.dispatch(addMessage(warning, 'registers.form.defaultRegisterChanged'));
    }
  }

  if (isFormChangeOnField(action, [REGISTER_FORM], 'active') && payload === '0') {
    const selector = formValueSelector(REGISTER_FORM);
    const isDefault = selector(store.getState(), 'is_default_for_pos');
    if (isDefault) {
      store.dispatch(addMessage(warning, 'registers.form.defaultRegisterInactivated'));
    }
  }


  return next(action);
};

export default registerForm;
