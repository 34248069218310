import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {bindActionCreators} from 'redux';
import {goBack, push} from 'react-router-redux';
import {formValueSelector} from 'redux-form';
import * as apiActions from '../../../../actions/apiActions';
import {setData} from '../../../../actions/dataActions';
import * as dataNames from '../../../../constants/dataNames';
import {addMessage} from '../../../../actions/systemActions';
import {getAssemblyPayload, getCalculatedTotal, getAssemblyOutputs,
  getIngredientsWithCosts, getInitialValues} from '../../../../selectors/assembliesSelectors';
import {getPossibleUoms} from '../../../../selectors/uomsSelectors';
import FormWrapper from '../../../common/form/FormWrapper';
import AssemblyFormWrapper from './../common/AssemblyFormWrapper';
import InProgressOverlay from '../../../common/InProgressOverlay';
import {getIntegrationState} from '../../../../selectors/integration/integrationSelectors';
import {setItem, unsetItem} from '../../../../actions/itemActions';
import * as itemNames from '../../../../constants/itemNames';
import {ASSEMBLY_FORM} from '../../../../constants/forms';


class CreateAssemblyPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      costings: [],
      ingredients: [],
      ready: false,
      costingsReady: false,
    };
    this.getPossibleUom = this.getPossibleUom.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.saveAndDuplicate = this.saveAndDuplicate.bind(this);
  }

  componentWillMount() {
    const {getUnpaginatedData,postData} = this.props.actions;
    const removeLoader = () => this.setState({ready: true});
    const afterFacilityLoadHandle = (ingredients) => {
      const ingredientsIds = ingredients.map( i => i.id );
      return postData('/api/costing/item_masters/multiple', {ids: ingredientsIds}, dataNames.costings)
        .then(() => this.setState({costingsReady: true}))
        .catch(() => this.setState({costingsReady: true}));
    };

    const promises = [
      getUnpaginatedData('/api/item_masters/manufacturing', dataNames.itemMasters, null, {active: 1, is_medicated: 1}),
      getUnpaginatedData('/api/ingredient_items/by_facility', dataNames.ingredients, null, {active: 1}, afterFacilityLoadHandle),
      getUnpaginatedData('/api/categories', dataNames.categories),
      getUnpaginatedData('/api/uoms', dataNames.uoms),
    ];

    Promise
      .all(promises)
      .then(removeLoader)
      .catch(removeLoader);
  }

  componentWillUnmount() {
    this.props.actions.unsetItem(itemNames.assemblyFormData);
  }

  getPossibleUom(defaultUom) {
    return getPossibleUoms(defaultUom, this.props.uoms);
  }


  onSubmit(formData) {
    let postAction = () => this.props.actions.push('/ei/assemblies');
    if (formData.saveAndDuplicate) {
      postAction = () => this.saveAndDuplicate(formData);
    }
    return this.props.actions.postData(
      '/api/assemblies',
      getAssemblyPayload(formData),
      dataNames.assemblies,
      { success: 'ei.assemblies.create.success', failed: 'ei.assemblies.create.failed' },
      null,
      postAction
    );
  }

  saveAndDuplicate(formData) {
    formData.saveAndDuplicate = false;
    this.props.actions.setItem(formData, itemNames.assemblyFormData);
  }

  render() {
    const {products, ingredients, totalCost, getFormValue, actions:{addMessage}, initialValues, integrationState} = this.props;
    const allReady = ['ready', 'costingsReady'].every((stateName) => this.state[stateName], this);

    return (
      <FormWrapper title={I18n.t('inventory.assemblyListing.createAssembly.title')} goBack={this.props.actions.goBack}>
        <div className='create-assembly-page'>
          {allReady ?
            <AssemblyFormWrapper
              form={ASSEMBLY_FORM}
              initialValues={initialValues}
              onSubmit={this.onSubmit}
              products={products}
              ingredients={ingredients}
              totalCost={totalCost}
              getFormValue={getFormValue}
              getPossibleUom={this.getPossibleUom}
              addMessage={addMessage}
              integrationState={integrationState}
            /> :
            <InProgressOverlay isActive={true} message={I18n.t('common.loading')}/>
          }
        </div>
      </FormWrapper>
    );
  }
}

CreateAssemblyPage.propTypes = {
  actions: PropTypes.shape({
    getUnpaginatedData: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    setItem: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired
  }),
  products: PropTypes.array.isRequired,
  ingredients: PropTypes.array.isRequired,
  uoms: PropTypes.array.isRequired,
  getFormValue: PropTypes.func.isRequired,
  totalCost: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  integrationState: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  const selector = formValueSelector(ASSEMBLY_FORM);
  const getFormValue = (...names) => selector(state, ...names);
  const formIngredients = getFormValue('ingredients');
  return {
    products: getAssemblyOutputs(state),
    ingredients: getIngredientsWithCosts(state),
    uoms: state[dataNames.uoms],
    getFormValue,
    totalCost: getCalculatedTotal(formIngredients),
    integrationState: getIntegrationState(state),
    initialValues: getInitialValues(state)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {goBack, addMessage, setData, setItem, unsetItem, push});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAssemblyPage);
