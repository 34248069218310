import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Row, Col} from 'react-bootstrap';
import SubmitSection from '../../../common/form/SubmitSection';
import RetailCouponCol from './RetailCouponCol';
import CouponLimitsCol from './CouponLimitsCol';

/**
 * Appends default submit settings (like "pristine", "submitting", "invalid") to custom submit settings
 *
 * @param customSubmitSettings
 * @param additionalDefaults
 * @returns {}
 */
const makeCustomSubmitSettings = (customSubmitSettings, additionalDefaults = {}) => {
  return Object.keys(customSubmitSettings).reduce((customSubmitSettings, settingKey) => {
    const {disableFor, ...setting} = customSubmitSettings[settingKey];

    // if disableFor isn't specified by the parent component, there's nothing to do here
    if (typeof disableFor !== 'object') return customSubmitSettings;

    // Loop through ALL potential additional defaults, and collect only those which the parent component is interested in
    const disableSettings = {};
    Object.keys(additionalDefaults).forEach(additionalDefaultKey => {
      if (disableFor[additionalDefaultKey]) {
        disableSettings[additionalDefaultKey] = additionalDefaults[additionalDefaultKey];
      }
    });

    // Combine existing setting with newly collected disableSettings
    customSubmitSettings[settingKey] = {...setting, ...disableSettings};

    return customSubmitSettings;
  }, {...customSubmitSettings});
};


const CouponForm  = (props) => {
  const {
    invalid, submitting, pristine, discountType, couponAppliesTo, couponType, categories, customerGroups, facilities,
    products, productLists, vendors, change, integrationState, customSubmitSettings, isProductListsFeatureEnabled
  } = props;

  const settings = {
    actionSettings: {
      ...makeCustomSubmitSettings(customSubmitSettings, { pristine, submitting, invalid }),
      submit: {
        submitting,
        invalid,
        pristine,
        text: I18n.t('common.form.submit')
      }
    }
  };

  return(
    <form onSubmit={props.handleSubmit} noValidate={true}>
      <Row>
        <Col xs={6} sm={6} md={6}>
          <RetailCouponCol
            change={change}
            facilities={facilities}
            discountType={discountType}
            couponAppliesTo={couponAppliesTo}
            couponType={couponType}
          />
        </Col>
        <Col xs={6} sm={6} md={6}>
          <CouponLimitsCol
            categories={categories}
            customerGroups={customerGroups}
            products={products}
            productLists={productLists}
            vendors={vendors}
            integrationState={integrationState}
            isProductListsFeatureEnabled={isProductListsFeatureEnabled}
          />
        </Col>
      </Row>
      <SubmitSection settings={settings}/>
    </form>
  );
};

CouponForm.propTypes = {
  change: PropTypes.func.isRequired,
  discountType: PropTypes.string,
  couponAppliesTo: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  categories: PropTypes.array.isRequired,
  customerGroups: PropTypes.array.isRequired,
  facilities: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  productLists: PropTypes.array,
  vendors: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
  customSubmitSettings: PropTypes.object,
  couponType: PropTypes.string,
  isProductListsFeatureEnabled: PropTypes.bool.isRequired
};

CouponForm.defaultProps = {
  customSubmitSettings: {}
};

export default CouponForm;
