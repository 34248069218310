import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {Field, FieldArray} from 'redux-form';
import {Link} from 'react-router';
import {Button, Col, Row} from 'react-bootstrap';
import {FaTrash} from 'react-icons/fa';
import MultiselectInput from '../../../common/form/MultiselectInput';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import WeightsFieldArray from './WeightsFieldArray';
import WillRender from '../../../common/concealers/WillRender';

const WeightPriceAttributes = (props) => {
  const {
    fields,
    fieldName,
    fieldIndex,
    fieldLabel,
    pricingWeights,
    facilityOptions,
    pricingGroupOptions,
    disablePriceBlock,
    formValues,
    change
  } = props;

  // const keyForMap = fieldName.replace(/(\[\d+\])/, '');
  const isFacilityMode = facilityOptions && facilityOptions.length > 0;
  const colNum = isFacilityMode ? 4 : 10;

  return (
    !disablePriceBlock && <Col xs={12}>
      <Row>
        {isFacilityMode && <React.Fragment>
          <Col sm={10} md={3}>
            <Field name={`${fieldName}.facility_ids`} component={MultiselectInput} props={{
              label: I18n.t('taxes.form.applicableFacilities'),
              options: facilityOptions,
              isRequired: false,
              allOption: true,
            }}/>
          </Col>
          <Col sm={2} md={1} style={{paddingTop: '28px'}}>
            <Button variant='danger' size='sm' onClick={() => fields.remove(fieldIndex)}>
              <FaTrash/>
            </Button>
          </Col>
        </React.Fragment>}
        <WillRender ifTrue={pricingWeights && pricingWeights.length > 0}>
          <Col xs={10} md={colNum}>
            <Field name={`${fieldName}.pricing_group_id`} component={ReactSelectInput} props={{
              label: I18n.t('products.form.pricingGroup'),
              options: pricingGroupOptions,
              textKey: 'name',
              valueKey: 'id',
              onChange: (value) => {
                if (!value) {
                  change(`${fieldName}.weight_prices`, []);
                }
                change(`${fieldName}.pricing_group_id`, value);
              }
            }}/>
          </Col>
          <Col sm={12} md={colNum}>
            <FieldArray
              name={`${fieldName}.weight_prices`}
              component={WeightsFieldArray}
              props={{
                label: I18n.t(...fieldLabel),
                options: pricingWeights,
                formValues
              }}/>
          </Col>
        </WillRender>
        <WillRender ifTrue={!(pricingWeights && pricingWeights.length > 0)}>
          <Col sm={12}>
            {I18n.t('products.form.pricingWeightSettingError.reason')}
            <Link to={{ pathname: '/prepack-weights/facility', state: { goBackAfterSubmit: true } }} onlyActiveOnIndex={false}>
              <Button variant='link'>{I18n.t('products.form.pricingWeightSettingError.link')}</Button>
            </Link>
          </Col>
        </WillRender>
      </Row>
    </Col>
  );
};

WeightPriceAttributes.propTypes = {
  isWholesale: PropTypes.bool.isRequired,
  fields: PropTypes.shape({
    remove: PropTypes.func,
  }),
  fieldName: PropTypes.string.isRequired,
  fieldLabel: PropTypes.array.isRequired,
  fieldIndex: PropTypes.number,
  facilityOptions: PropTypes.array,
  pricingWeights: PropTypes.array,
  pricingGroupOptions: PropTypes.array,
  disablePriceBlock: PropTypes.bool,
  formName: PropTypes.string,
  formValues: PropTypes.object,
  change: PropTypes.func,
};


function mapStateToProps() {
  return {

  };
}

export default connect(mapStateToProps)(WeightPriceAttributes);
