import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {bindActionCreators} from 'redux';
import {batchActions} from 'redux-batched-actions';
import {goBack, push} from 'react-router-redux';
import {formValueSelector, getFormSyncErrors, change, arrayRemoveAll, arrayPush, touch} from 'redux-form';
import {addMessage} from '../../../actions/systemActions';
import {setRules, setFacts} from '../../../actions/ruleActions';
import rules from './RenderRules';
import {pathnameContains, paramsGet, getUrlParam} from '../../../util/routeHelper';
import {validateInventoryReceiptExists} from '../../../actions/complianceSettingsActions';
import {setSelectedDataImmediate} from  '../../../actions/selectedDataActions';
import {ensureGetUnpaginatedData, getUnpaginatedData, getItem, postItem, getFile, getData, postData, putItem} from '../../../actions/apiActions';
import {unsetData} from '../../../actions/dataActions';
import {unsetItem} from '../../../actions/itemActions';
import {getCreatePayload, getModifyPayload} from '../../../selectors/purchaseOrdersSelectors';
import * as p from '../../../constants/permissions';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import PurchaseOrderFormWrapper from './common/PurchaseOrderFormWrapper';
import ModalWrapper from '../../common/ModalWrapper';
import FormWrapper from '../../common/form/FormWrapper';
import InProgressOverlay from '../../common/InProgressOverlay';
import {getAllPurchasedItemMastersByPartner, getPurchaseOrderHistoryData} from '../../../actions/purchaseOrderActions';
import { PURCHASE_ORDER_FORM } from '../../../constants/forms';
import * as messageTypes from '../../../constants/messageTypes';
import {getUnifiedMapStateToProps} from './helpers';

const formName = PURCHASE_ORDER_FORM;

export class PurchaseOrderPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);

    this.doSubmit = this.doSubmit.bind(this);
    this.onReceiveTransfer = this.onReceiveTransfer.bind(this);
    this.onBack = this.onBack.bind(this);
    this.onHideInvoiceNumberModal = this.onHideInvoiceNumberModal.bind(this);
    this.setRules = this.setRules.bind(this);
    this.onEditPayments = this.onEditPayments.bind(this);
    this.onSetPartner = this.onSetPartner.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onOpenTransfer = this.onOpenTransfer.bind(this);
    this.onChangeLocalState = this.onChangeLocalState.bind(this);
    this.onNavigate = this.onNavigate.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.cancelOrder = this.cancelOrder.bind(this);
    this.cancelPurchaseOrder = this.cancelPurchaseOrder.bind(this);
    this.setRules = this.setRules.bind(this);
    this.checkTransferStatus = this.checkTransferStatus.bind(this);
    this.checkReadyState = this.checkReadyState.bind(this);

    this.state = {
      canEditPayments: true, // From modify
      cancelModalOpen: false,
      formModified: false, // For connects
      initialForm: false, // For connects
      isCreate: pathnameContains(props, 'create'),
      itemMasterIdTouched: false,
      matchingOrderWarnings: false,
      printingInvoice: false,
      purchaseOrderCreated: false,
      purchaseOrderId: paramsGet(props, 'id', false),
      purchaseOrderSaved: false,
      ready: false,
      setPayments: false, // From modify
      showInvoiceNumberModal: false,
      inProgress: true,
      inProgressText: 'Loading Purchase Order',
    };
  }

  componentDidMount() {
    this.props.actions.batchActions([
      unsetData(dataNames.itemMasters),
      unsetData(dataNames.itemMasterChildren),
      unsetData(dataNames.vendorItemMasters),
      unsetData(dataNames.vendorChildItemMasters),
      unsetItem(itemNames.purchaseOrder),
      unsetItem(itemNames.parentPurchaseOrder),
      unsetData(dataNames.purchaseOrderEvents),
    ]);

    // No loading dependencies
    const asyncs = [
      this.props.actions.ensureGetUnpaginatedData('/api/payment_types', dataNames.paymentTypes),
      this.props.actions.ensureGetUnpaginatedData('/api/registers', dataNames.registers, '', {active: 1, type: 'ap_ar'}),
      this.props.actions.getUnpaginatedData('/api/prepack_weights/facility', dataNames.prepackWeights),
      this.props.actions.ensureGetUnpaginatedData('/api/pricing_groups', dataNames.pricingGroups, null, {detailed: 1}),
      this.props.actions.ensureGetUnpaginatedData('/api/pricing_weights', dataNames.pricingWeights),
      this.props.actions.ensureGetUnpaginatedData('/api/location_hierarchy', dataNames.locations),
      this.props.actions.ensureGetUnpaginatedData('/api/uoms', dataNames.uoms),
      this.props.actions.ensureGetUnpaginatedData('/api/strains/by_facility', dataNames.facilityStrains),
      this.props.actions.ensureGetUnpaginatedData('/api/users/current_facility', dataNames.currentFacilityUsers, {failed: 'packaging.getUsers.failed'}),
      this.props.actions.getItem('/api/facility_groups_sharings/facility_status', itemNames.facilitySharingStatus),
      this.props.actions.getItem('/api/integration-settings', itemNames.integrationSettings, {failed: 'stateIntegrators.settings.get.failed'}),
      this.props.actions.getItem('/api/cultivation/settings', itemNames.wasteCompliance, null, {ids: ['distributions_transfer_line_limit']}),
      this.props.actions.getItem('/api/supply/settings', itemNames.supplySettings),
      this.props.actions.ensureGetUnpaginatedData('/api/payment_terms/payment_terms_list', dataNames.paymentTerms, {failed:'paymentTerms.get.failed'}),
      this.props.actions.getUnpaginatedData('/api/partners', dataNames.partners, undefined, {exclude_internal_duplicates: 1}),
      this.props.actions.getUnpaginatedData('/api/categories', dataNames.categories)
    ];

    if(this.state.isCreate){
      if (getUrlParam('org')) {
        const orgData = getUrlParam('org');
        const partnerId = parseInt(orgData.toString().split(':').shift());
        asyncs.push(this.props.actions.getAllPurchasedItemMastersByPartner(partnerId, [dataNames.itemMasters]));
      }
      if(this.props.allowInitialInventory){
        asyncs.push(this.props.actions.getAllPurchasedItemMastersByPartner(false, [dataNames.itemMasters]));
      }
    } else {
      asyncs.push(this.props.actions.getItem(`/api/purchase_orders/${this.state.purchaseOrderId}`, itemNames.purchaseOrder, {failed: 'orders.get.fail'}));
      asyncs.push(this.props.actions.getData(`/api/purchase_orders/${this.state.purchaseOrderId}/events`, dataNames.purchaseOrderEvents));
    }

    // Potentially having downstream actions
    const syncs = [
      this.props.actions.ensureGetUnpaginatedData('/api/partner_facilities', dataNames.partnerFacilities)
    ];

    Promise.all(asyncs);
    Promise.all(syncs);
  }

  componentWillReceiveProps(nextProps) {
    this.checkReadyState(nextProps);
    if(nextProps.initialValues !== undefined && !this.state.setPayments) {
      if (nextProps.initialValues.status === 'inactive')
        this.setState({canEditPayments: false, setPayments: true});
    }
    const linesString = JSON.stringify(this.props.lines);
    const nextLinesString = JSON.stringify(nextProps.lines);
    // Total is calculated and presented from that value but never sets order_total which breaks validation of
    // payment amount.
    if(linesString !== nextLinesString){
      this.props.actions.change(formName, 'order_total', this.props.total);
    }
    if (nextProps.oddMoney !== this.props.oddMoney) {
      this.props.actions.change(formName, 'oddMoney.amount', nextProps.oddMoney.toFixed(2));
    }
    this.setRules(nextProps);
  }

  checkReadyState(props = false){
    if(!props){
      props = this.props;
    }
    const hasPartners = props.partners.length && props.partnerFacilitiesAreLoaded;
    const hasPurchaseOrder = this.state.isCreate || (props.purchaseOrder && Object.keys(props.purchaseOrder).length);
    const isReady = hasPartners && hasPurchaseOrder;
    if(this.state.inProgress && isReady){
      this.setState({
        inProgress: false,
      });
    }
    return isReady;
  }

  setRules(props = false) {
    if(!props) props = this.props;
    this.props.actions.setRules(rules)
      .then((engine) => {
        engine.setFacts(props.renderFacts);
      });
  }

  onReceiveTransfer(){
    this.props.actions.push(`/inventory-receipts/create?po_num=${this.state.purchaseOrderId}&po=1`);
  }

  onBack(){
    this.props.actions.goBack();
  }

  onHideInvoiceNumberModal() {
    this.setState({showInvoiceNumberModal: false});
  }

  doSubmit(formData, receiveTransfer = false, printInvoice = false){

    const {balance, total, timezone, isReturn, isInitialMode, purchaseOrder} = this.props;

    if (!this.checkTransferStatus()) {
      return false;
    }

    if(isReturn && !formData.vendor_invoice_number){
      this.setState({showInvoiceNumberModal: true});
      return false;
    }

    this.setState({inProgress: true, inProgressText: 'Saving Purchase Order'});

    const messages = {
      failed: `purchaseOrders.${this.state.isCreate ? 'create' : 'modify'}.fail`,
      success: `purchaseOrders.${this.state.isCreate ? 'create' : 'modify'}.success`
    };

    const payload = this.state.isCreate
      ? {...getCreatePayload(formData, balance, total, timezone), isInitialMode}
      : getModifyPayload(formData, balance, total, timezone, purchaseOrder);

    const method = this.state.isCreate ? 'postItem' : 'putItem';
    const baseUrl = '/api/purchase_orders';
    const url =  this.state.isCreate ? baseUrl : `${baseUrl}/${this.state.purchaseOrderId}`;

    this.props.actions[method](url, payload, itemNames.purchaseOrder, messages, {}, (data) => {
      this.props.actions.setSelectedDataImmediate([data], dataNames.purchaseOrders);
      if(data.warnings){
        const stateUpdate = {
          saving: false,
          purchaseOrderSaved: true,
          matchingOrderWarnings: data.warnings,
          purchaseOrderId: data.id,
          isCreate: false
        };
        this.setState(stateUpdate);
        return false;
      }
      if(receiveTransfer){
        return this.state.isCreate
          ? this.props.actions.push(`/inventory-receipts/create?po_num=${data.id}&po=1`)
          : this.props.actions.validateInventoryReceiptExists() && this.props.actions.push(`/inventory-receipts/create?po_num=${data.id}&po=1`);
      }
      if (printInvoice) {
        this.setState({printingInvoice: true});
        this.props.actions.getFile(
          `/api/purchase_orders/${data.id}/print`,
          `PurchaseOrder.pdf`,
          {failed: 'purchaseOrders.form.printingFailed'},
          {}
        ).then(() => this.props.actions.goBack()).catch(() => this.setState({printingInvoice: false}));
      } else {
        this.props.actions.goBack();
      }
    }).catch(() => this.setState({saving: false}));
  }

  /************* FROM MODIFY **************/

  onEditPayments(){
    this.setState({canEditPayments: true});
  }

  checkTransferStatus() {
    const {purchaseOrder, integrationState: {isMetrc}} = this.props;
    if (!(isMetrc && get(purchaseOrder, 'is_imported')) && get(purchaseOrder, 'transfer_record.status') === 'open') {
      this.props.actions.addMessage(messageTypes.error, ['purchaseOrders.validateIRExists', {ir_id: get(purchaseOrder, 'transfer_record.receipt_number')}]);
      return false;
    }
    return true;
  }

  onSetPartner(id) {
    if(id) this.props.actions.change(formName, 'partner_id', id);
  }

  onCancel() {
    const {params: {id}} = this.props;
    this.props.actions.putItem(`/api/purchase_orders/${id}/status`,
      {status: 'cancelled'},
      itemNames.purchaseOrder,
      {failed: 'purchaseOrder.modify.failed', success: 'purchaseOrder.modify.success'},
      null,
      this.props.actions.goBack()
    );
  }

  onOpenTransfer(id){
    this.props.actions.push(`/inventory-receipts/modify/${id}`);
  }

  onChangeLocalState(field, value){
    return new Promise((resolve) => {
      this.setState(Object.assign({}, this.state, {[field]: value}), () => {
        resolve();
      });
    });
  }

  onNavigate(){
    this.props.actions.push(`/purchase-orders/${this.props.params.id}/history`);
  }


  cancelOrder() {
    //This is intentionally commented out. Fixes RS-782.
    //Existing code references a state variable that appears to be used to trigger a modal but there is no use of this variable anywhere
    /*if(this.props.renderFlags.hasMatchingOrder){
      return this.setState({ cancelModalOpen : true });
    }*/
    this.onCancel();
  }

  hideModal(){
    this.setState({ cancelModalOpen : false });
  }

  cancelPurchaseOrder(row){
    this.hideModal();
    this.props.actions.putItem(`/api/purchase_orders/${row.id}/status`,
      {status: 'cancelled'},
      null,
      {success: 'transfers.cancelProcess.success', failed: 'transfers.cancelProcess.failed'},
      null
    )
      .then(d => {
        this.props.actions.goBack();
      });
  }

  render () {
    const {partners, locations, sharedLocations, itemMasters, registersByType, paymentTerms, timezone, integrationState, forceMedicatedMapping,
      paymentTypes, partnerFacilities, lines, partnerId, balance, total, oddMoney, errors, uoms, hasAllMedicatedOrNonMedicated,
      isMedicatedStatusSelected, hasItemMasters, medicatedStatus, users, canCreateMatchingOrder, isReturn, allowInitialInventory,
      isInitialMode, initialValues, transferLinesLimit, generateInternalSalesOrder, updateInternalSalesOrder, renderFlags, facilityCountryCode, activeFacilityType,
      isMappingRequiredByPlatform, canChoosePartner, canReceiveTransfer, purchaseOrder, metrcIsImported, hasAllCbdFlower,
      modifiedLinesByItemMaster, categories
    } = this.props;

    // PO partner facilities should contain non-research university falicities
    const filteredPartnerFacilities = partnerFacilities.filter(pf => pf.is_research_university == 0);
    const {printingInvoice} = this.state;
    const showMedicatedStatusMessage = forceMedicatedMapping && hasItemMasters && !hasAllMedicatedOrNonMedicated && !isMedicatedStatusSelected;
    const showNoPartnerProductsMessage = partnerId && !hasItemMasters;
    if(initialValues) initialValues.is_return = isReturn;

    // This is from modify - once everything change this to something else PLEASE!!!!
    const transferRecord = (purchaseOrder.transfer_record !== undefined && purchaseOrder.transfer_record !== null);
    const transfer = (transferRecord)
      ? purchaseOrder.transfer_record
      : {};

    const useInitialMode = this.state.isCreate ? isInitialMode : initialValues && initialValues.is_initial_mode;

    return (<div>
      <FormWrapper
        title={this.state.isCreate ? 'purchaseOrders.create.title' : 'purchaseOrders.modify.title'}
        goBack={this.props.actions.goBack}
        className='create-purchase-order'
      >
        <InProgressOverlay
          isActive={this.state.inProgress}
          message={this.state.inProgressText}
        />
        <InProgressOverlay
          isActive={printingInvoice}
          message={I18n.t(`purchaseOrders.form.generatingFileForPrinting`)}
        />
        {
          !this.checkReadyState()
            ? null
            : (<PurchaseOrderFormWrapper
              actions={{onReceiveTransfer: this.onReceiveTransfer, onBack: this.onBack}}
              activeFacilityType={activeFacilityType}
              allowInitialInventory={allowInitialInventory}
              balance={balance}
              canChoosePartner={this.state.isCreate || canChoosePartner}
              canCreateMatchingOrder={canCreateMatchingOrder}
              canEditPayments={this.state.canEditPayments}
              canReceiveTransfer={canReceiveTransfer}
              canStatusBeChanged={initialValues !== undefined ? initialValues.canStatusBeChanged : true}
              categories={categories}
              doSubmit={this.doSubmit}
              enableReinitialize={true}
              errors={errors}
              existingNotes={purchaseOrder.notes}
              facilityCountryCode={facilityCountryCode}
              forceMedicatedMapping={forceMedicatedMapping}
              form={formName}
              formModified={false}
              generateInternalSalesOrder={generateInternalSalesOrder}
              updateInternalSalesOrder={updateInternalSalesOrder}
              hasAllCbdFlower={hasAllCbdFlower}
              initialValues={initialValues}
              integrationState={integrationState}
              isEditMode={!this.state.isCreate}
              isInitialMode={useInitialMode}
              isMappingRequiredByPlatform={isMappingRequiredByPlatform}
              isReturn={isReturn}
              itemMasters={itemMasters}
              keepDirtyOnReinitialize={true}
              lines={lines}
              locations={locations}
              matchingOrderWarnings={this.state.matchingOrderWarnings}
              medicatedStatus={medicatedStatus}
              metrcIsImported={this.state.isCreate ? false : metrcIsImported}
              modifiedLinesByItemMaster={this.state.isCreate ? [] : modifiedLinesByItemMaster}
              oddMoney={oddMoney}
              onCancel={this.state.isCreate ? false : this.cancelOrder}
              onChangeLocalState={this.onChangeLocalState}
              onEditPayments={this.onEditPayments}
              onNavigate={this.onNavigate}
              onOpenTransfer={this.onOpenTransfer}
              order_class={purchaseOrder.order_class}
              partnerFacilities={filteredPartnerFacilities}
              partnerId={partnerId}
              partners={partners}
              paymentStatus={purchaseOrder.payment_status}
              paymentTerms={paymentTerms}
              paymentTypes={paymentTypes}
              payments={purchaseOrder.payments || []}
              purchaseOrder={purchaseOrder}
              purchaseOrderSaved={this.state.purchaseOrderSaved}
              received={purchaseOrder.received}
              registersByType={registersByType}
              renderFlags={renderFlags}
              sharedLocations={sharedLocations}
              showMedicatedStatusMessage={this.state.isCreate ? showMedicatedStatusMessage : false}
              showNoPartnerProductsMessage={this.state.isCreate ? showNoPartnerProductsMessage : false}
              status={this.state.isCreate ? 'active' : initialValues !== undefined ? initialValues.status : 'active'}
              timezone={timezone}
              total={total}
              transfer={transfer}
              transferLinesLimit={transferLinesLimit}
              transferRecord={this.state.isCreate ? false : transferRecord}
              uoms={uoms}
              users={users}
            />)
        }

      </FormWrapper>
      <ModalWrapper
        dialogClassName='modal-sm'
        okayButton={{
          show: true,
          text: I18n.t('general.okay'),
          variant: 'danger',
          onClick: this.onHideInvoiceNumberModal
        }}
        onHide={this.onHideInvoiceNumberModal}
        showModal={this.state.showInvoiceNumberModal}
        title={I18n.t('purchaseOrders.invoiceNumberModal.title')}
        version={2}
      >
        <p>{I18n.t('purchaseOrders.invoiceNumberModal.body')}</p>
      </ModalWrapper>
    </div>);
  }
}

PurchaseOrderPage.propTypes = {
  actions: PropTypes.shape({
    arrayPush: PropTypes.func.isRequired,
    arrayRemoveAll: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    putItem: PropTypes.func.isRequired,
    setData: PropTypes.func.isRequired,
    setSelectedDataImmediate: PropTypes.func.isRequired,
    touch: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired,
    validateInventoryReceiptExists: PropTypes.func.isRequired,
  }),
  activeFacilityType: PropTypes.string,
  afterSubmit: PropTypes.string,
  allPartnerFacilities: PropTypes.array.isRequired,
  allowInitialInventory: PropTypes.bool,
  balance: PropTypes.number,
  canChoosePartner: PropTypes.bool.isRequired,
  canCreateMatchingOrder: PropTypes.number,
  categories: PropTypes.array.isRequired,
  canReceiveTransfer: PropTypes.bool.isRequired,
  dateOrdered: PropTypes.string,
  errors: PropTypes.object,
  facilityCountryCode: PropTypes.string,
  forceMedicatedMapping: PropTypes.bool,
  initialValues: PropTypes.object,
  integrationState: PropTypes.object.isRequired,
  isInitialMode: PropTypes.bool.isRequired,
  isMappingRequiredByPlatform: PropTypes.bool,
  isMedicatedStatusSelected: PropTypes.bool.isRequired,
  isMetrcTransfersEnabled: PropTypes.bool,
  isReturn: PropTypes.bool.isRequired,
  itemMasterChildren: PropTypes.object.isRequired,
  itemMasters: PropTypes.array.isRequired,
  lines: PropTypes.array,
  locations: PropTypes.array.isRequired,
  medicatedStatus: PropTypes.number,
  metrcIsImported: PropTypes.bool,
  modifiedLinesByItemMaster: PropTypes.array,
  oddMoney: PropTypes.number,
  params: PropTypes.shape({
    id: PropTypes.string
  }),
  partnerFacilities: PropTypes.array.isRequired,
  partnerId: PropTypes.number,
  partnerIntegrations: PropTypes.array.isRequired,
  partners: PropTypes.array.isRequired,
  paymentTerms: PropTypes.array.isRequired,
  paymentTypes: PropTypes.array.isRequired,
  payments: PropTypes.array,
  pricingGroups: PropTypes.array.isRequired,
  pricingWeights: PropTypes.array.isRequired,
  registers: PropTypes.array.isRequired,
  purchaseOrder: PropTypes.object,
  registersByType: PropTypes.object,
  renderFacts: PropTypes.object.isRequired,
  renderFlags: PropTypes.object.isRequired,
  sharedLocations: PropTypes.array.isRequired,
  timezone: PropTypes.string.isRequired,
  total: PropTypes.number,
  transferLinesLimit: PropTypes.number.isRequired,
  uoms: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
};

const selector = formValueSelector(formName);
const errorSelector = getFormSyncErrors(formName);
function mapStateToProps(state, ownProps) {
  return getUnifiedMapStateToProps(state, ownProps, selector, errorSelector);
}

function mapDispatchToProps(dispatch) {
  const actions = {
    addMessage,
    arrayPush,
    arrayRemoveAll,
    batchActions,
    change,
    getAllPurchasedItemMastersByPartner,
    getData,
    getFile,
    getItem,
    getPurchaseOrderHistoryData,
    getUnpaginatedData,
    ensureGetUnpaginatedData,
    goBack,
    postData,
    postItem,
    push,
    putItem,
    setFacts,
    setRules,
    setSelectedDataImmediate,
    touch,
    unsetData,
    unsetItem,
    validateInventoryReceiptExists,
  };
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (PurchaseOrderPage);
