import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import {Field} from 'redux-form';
import {Button, Col, Row, Form} from 'react-bootstrap';
import {FaTimes} from 'react-icons/fa';
import FieldArrayIncrement from '../../../common/form/FieldArrayIncrement';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import InternationalCurrencyInput from '../../../common/form/InternationalCurrencyInput';
import WillRender from '../../../common/concealers/WillRender';

const WeightsFieldArray = ({options, label, formValues, fields}) => {
  const path = fields.name.substring(0, fields.name.lastIndexOf('.')) + '.pricing_group_id';
  const pricingGroupId = get(formValues, path);
  const selectedOptions = get(formValues, fields.name, []);
  const costPerGram = get(formValues, 'vendors.0.default_unit_cost', null);

  // Filter out already selected options, except when used in this field
  const getFilteredOptions = (fieldIndex) => {
    return options.filter(option => !selectedOptions.some((selectedOption, index) => selectedOption.pricing_weight_id === option.id && index !== fieldIndex));
  };

  return (
    <div>
      <h5>
        <span>{label} &nbsp;</span>
        <span>{!pricingGroupId && <FieldArrayIncrement className='inline' fields={fields} showDecrement={true} minLength={1} maxLength={options.length} label={null} />}</span>
      </h5>
      {fields.map((fieldName, fieldIndex) => {

        // margin calculation
        const weightPrice = get(formValues, `${fieldName}.default_price`, null);
        const weightName = get(formValues,`${fieldName}.name`, '');
        const weight = parseFloat(weightName.match(/[\d.]+/));
        const margin = weightPrice &&  weightName && weight && weightPrice > 0 ? (weightPrice - (weight * costPerGram)) / weightPrice : null;
        const marginPercent = margin ? (margin * 100).toFixed(2) : null;

        return (
          <React.Fragment key={fieldIndex}>
              <WillRender ifTrue={marginPercent && fieldIndex === 0}>
                <Row>
                  <Col sm={{span: 2, offset:9}}>
                    <Form.Label>{I18n.t('products.form.margin')}</Form.Label>
                  </Col>
                </Row>
              </WillRender>
            <Row>
              <Col sm={4} style={{paddingRight: '6px'}}>
                <Field name={`${fieldName}.pricing_weight_id`} component={ReactSelectInput} props={{
                  options: getFilteredOptions(fieldIndex),
                  textKey: 'name',
                  valueKey: 'id',
                }}/>
              </Col>
              <Col sm={5} style={{paddingLeft: '0', paddingRight: '6px'}}>
                <InternationalCurrencyInput name={`${fieldName}.default_price`} props={{fractionPartSize: 2, placeholder: '0.00'}}/>
              </Col>
              <Col sm={2} style={{paddingLeft: '0', paddingRight: '6px', whiteSpace: 'nowrap', textAlign: 'right'}}>
                <WillRender ifTrue={marginPercent}>
                  {`${marginPercent} %`}
                </WillRender>
              </Col>
              <Col sm={1} style={{paddingLeft: '0', paddingRight: '6px'}}>
                {!pricingGroupId && <Button variant='link' style={{color: '#777777'}} onClick={() => fields.remove(fieldIndex)}>
                  <FaTimes/>
                </Button>}
              </Col>
            </Row>
          </React.Fragment>
        );
      })}
    </div>
  );
};

WeightsFieldArray.propTypes = {
  options: PropTypes.array.isRequired,
  source: PropTypes.string,
  value_type: PropTypes.string,
  formValues: PropTypes.object.isRequired,
  change: PropTypes.func,
  fields: PropTypes.object.isRequired,
  label: PropTypes.string,
};

export default WeightsFieldArray;
