import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import get from 'lodash.get';

import {TERPENE_PROFILE_DEFAULT_DIMENSIONS, TOTAL_TERPENE_KEY} from '../../../constants/testResults';
import DoubleFieldInfo from '../common/DoubleFieldInfo';
import DoubleFieldInfoColumn from '../common/DoubleFieldInfoColumn';
import {calculateTerpeneTotals} from '../../../actions/forms/testResultsActions';
import {areTerpeneTotalsEditable} from '../../../selectors/testResultsSelectors';
import {isFeatureEnabled} from '../../../selectors/featureToggles';


const status = 'terpene_profile_status';

const TerpeneProfile = ({actions, row, editable, disabled, isIsolocity, isolocityTestResult, requiredDimensions, formName, areTerpeneTotalsEditable, isTerpeneTotalsFeatureEnabled, change, isPerServingMode, servingsPerContainer, servingsUom}) => {
  const dimensions = [...TERPENE_PROFILE_DEFAULT_DIMENSIONS].sort((a, b) => (a.key > b.key) ? 1 : -1);
  const indexOfTotalDimension = dimensions.findIndex(column => column.key === TOTAL_TERPENE_KEY);
  const totalColumn = dimensions[indexOfTotalDimension];

  dimensions.splice(indexOfTotalDimension, 1);

  // This component is also called from AverageTestResultPage. In that case 'isPerServingMode' and 'servingsUom'
  // are not passed in as props and need to be retrieved from 'row'.
  const _isPerServingMode = isPerServingMode ? isPerServingMode : get(row, 'test_results_value_type', 'percentage') === 'serving';
  const _servingsUom = servingsUom ? servingsUom : get(row, 'servings_uom');

  const props = {
    row: row || {},
    columns: dimensions,
    requiredDimensions,
    editable,
    disabled,
    isIsolocity,
    isolocityStatus: get(isolocityTestResult, `inspection.sub_statuses.${status}`, 'In Progress'),
    change,
    isPerServingMode: _isPerServingMode,
    servingsPerContainer,
    servingsUom: _servingsUom
  };

  if (isTerpeneTotalsFeatureEnabled) {
    props.onChange = () => actions.calculateTerpeneTotals(formName, dimensions);
  }

  return (
    <React.Fragment>
      <DoubleFieldInfo
        {...props}
      >
        {isTerpeneTotalsFeatureEnabled &&
          <DoubleFieldInfoColumn row={row || {}}
                                 column={totalColumn}
                                 disabled={disabled || !areTerpeneTotalsEditable}
                                 editable={editable}
                                 change={change}
                                 isRequired={false}
                                 isPerServingMode={_isPerServingMode}
                                 servingsPerContainer={servingsPerContainer}
                                 servingsUom={_servingsUom}

          />
        }
      </DoubleFieldInfo>
    </React.Fragment>
  );
};

TerpeneProfile.propTypes = {
  actions: PropTypes.object.isRequired,
  formName: PropTypes.string,
  areTerpeneTotalsEditable: PropTypes.bool,
  isTerpeneTotalsFeatureEnabled: PropTypes.bool,
  row: PropTypes.object,
  dimensions: PropTypes.object,
  requiredDimensions: PropTypes.array,
  editable: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  isIsolocity: PropTypes.bool,
  isolocityTestResult: PropTypes.object,
  onChange: PropTypes.func,
  change: PropTypes.func,
  isPerServingMode: PropTypes.bool.isRequired,
  servingsPerContainer: PropTypes.string,
  servingsUom: PropTypes.string
};

TerpeneProfile.defaultProps = {
  disabled: false,
};

const mapStateToProps = (state, ownProps) => ({
  areTerpeneTotalsEditable: areTerpeneTotalsEditable(state, {form: ownProps.formName}),
  isTerpeneTotalsFeatureEnabled: isFeatureEnabled(state)('feature_terpene_totals_for_test_results')
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({calculateTerpeneTotals}, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TerpeneProfile);
