import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {change} from 'redux-form';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Button, Table, Alert} from 'react-bootstrap';
import {TbRefresh} from 'react-icons/tb';
import {FaFileExport} from 'react-icons/fa';
import moment from 'moment-timezone';
import get from 'lodash.get';
import {convertDbDateTimeToFormInputDateTime} from '../../util/dateHelpers';
import {getData, getItem} from '../../actions/apiActions';
import {addMessage, startSpinner, stopSpinner} from '../../actions/systemActions';
import {AuthRequest} from '../../managers/request';
import {userHasPermission} from '../../selectors/usersSelectors';
import * as itemNames from '../../constants/itemNames';
import * as permissions from '../../constants/permissions';
import * as dataNames from '../../constants/dataNames';
import * as messageTypes from '../../constants/messageTypes';
import exportCSV from '../../util/csv_export_util';

const initialState = {
  selectAll: {complete: false, incomplete: false},
  selectedIds: [],
  inventoryAuditExport: {},
  // Available statuses:
  //    'waiting' -> Ready to request CSV export,
  //    'requested' -> CSV export being generated,
  //    'ready' -> CSV export available
  inventoryAuditExportStatus: 'waiting',
  includeNonAudited: false
};

class InventoryAuditListing extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = initialState;
    this.request = AuthRequest.create();

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleExportAudits = this.handleExportAudits.bind(this);
    this.handleIncludeNonAudited = this.handleIncludeNonAudited.bind(this);
    this.loadAudits = this.loadAudits.bind(this);
  }

  componentWillMount() {
    this.loadAudits();
  }

  getLocationName(inventory_location_id) {
    const location = this.props.locations.find(location => location.id === inventory_location_id);
    return location ? location.name : '';
  }

  handleChange(e) {
    const checked = e.target.checked;
    const inventoryAuditId = parseInt(e.target.value);
    const selectedIds = this.state.selectedIds;
    if (checked) {
      selectedIds.push(inventoryAuditId);
    }
    else {
      const index = selectedIds.findIndex((id => id === inventoryAuditId));
      if (index !== -1) {
        selectedIds.splice(index, 1);
      }
    }
    this.setState({selectedIds: selectedIds});
  }

  handleIncludeNonAudited(e) {
    this.setState({includeNonAudited: e.target.checked});
  }

  handleSelectAll(type) {
    const newState = !this.state.selectAll[type];
    const inventoryAudits = this.props.inventoryAudits ? this.props.inventoryAudits[type] : [];
    const selectedIds = this.state.selectedIds;
    inventoryAudits.forEach((inventoryAudit) => {
      const index = selectedIds.findIndex((id => id === inventoryAudit.id));
      if (newState) {
        // Add if not yet in list
        if (index === -1) selectedIds.push(inventoryAudit.id);
      }
      else {
        // Remove if in list
        if (index !== -1) selectedIds.splice(index, 1);
      }
    });
    this.setState({selectedIds: selectedIds});
    const complete = type === 'complete' ? newState : this.state.selectAll['complete'];
    const incomplete = type === 'incomplete' ? newState : this.state.selectAll['incomplete'];

    this.setState({selectAll: {complete, incomplete}});
  }

  handleExportAudits() {
    this.setState({inventoryAuditExportStatus: 'requested'});
    this.props.actions.startSpinner();
    this.props.actions.getItem(`/api/inventory_audit/export`, null, null, {ids: this.state.selectedIds, includeNonAudited: this.state.includeNonAudited})
      .then((inventoryAuditExport) => {
        const timestamp = moment().format('YYYYMMDD_HHmmss');
        const filename = 'InventoryAudit_' + timestamp + '.csv';
        exportCSV(inventoryAuditExport.data, inventoryAuditExport.keys, filename, ',', false);
      })
      .catch((error) => {
        // Show error to user
        const message = get(error, 'response.data.errors') ? get(error, 'response.data.errors') : 'inventory.audit.export.failed';
        this.props.actions.addMessage(messageTypes.error, message);
      })
      .finally(() => {
        this.setState({inventoryAuditExportStatus: 'ready'});
        this.props.actions.stopSpinner();
      });
  }

  loadAudits() {
    this.props.actions.getData('/api/inventory_audit', dataNames.inventoryAudits);
  }

  render () {
    const {
      selectAll,
      inventoryAuditExportStatus,
      selectedIds,
      includeNonAudited
    } = this.state;

    const {
      inventoryAudits,
      handleLoadAudit,
      hasManageInventoryAuditsPermission
    } = this.props;

    const completeInventoryAudits = inventoryAudits ? inventoryAudits.complete : [];
    const incompleteInventoryAudits = inventoryAudits ? inventoryAudits.incomplete : [];

    return (
      <React.Fragment>
        {(completeInventoryAudits.length === 0 && incompleteInventoryAudits.length === 0) &&
          <h3>{I18n.t('inventory.audit.noPreviousAudits')}</h3>
        }
        {(completeInventoryAudits.length !== 0 || incompleteInventoryAudits.length !== 0) &&
          <React.Fragment>
            <h3>{I18n.t('inventory.audit.previousAudits')}</h3>
            {!hasManageInventoryAuditsPermission &&
              <Alert variant='warning'>
                {I18n.t('inventory.audit.error.noPermission')}
              </Alert>
            }
            <form>
              <Button
                className='btn btn-primary'
                disabled={!hasManageInventoryAuditsPermission}
                onClick={this.loadAudits}
              >
                <TbRefresh/>
                &nbsp;
                {I18n.t('inventory.audit.button.refresh')}
              </Button>
              &nbsp;
              &nbsp;
              <Button
                className='btn btn-primary'
                disabled={!hasManageInventoryAuditsPermission || selectedIds.length === 0 || inventoryAuditExportStatus === 'requested'}
                onClick={this.handleExportAudits}
              >
                <FaFileExport/>
                &nbsp;
                {I18n.t('inventory.audit.button.export')}
              </Button>
              &nbsp;
              &nbsp;
              <input
                type='checkbox'
                checked={includeNonAudited}
                onChange={this.handleIncludeNonAudited}
              />
              &nbsp;
              {I18n.t('inventory.audit.includeNonAudited')}
              <Table hover>
                {completeInventoryAudits.length > 0 &&
                  <React.Fragment>
                    <thead>
                    <tr>
                      <th colSpan='5'>
                        {hasManageInventoryAuditsPermission &&
                          <React.Fragment>
                            <input
                              type='checkbox'
                              checked={selectAll.complete}
                              onChange={() => this.handleSelectAll('complete')}
                            />
                            &nbsp;&nbsp;
                          </React.Fragment>
                        }
                        <strong>{I18n.t('inventory.audit.previousAuditsComplete')}</strong>
                      </th>
                    </tr>
                    <tr>
                      <th />
                      <th>Audited By</th>
                      <th>Location</th>
                      <th>Audit start</th>
                      <th>Audit end</th>
                    </tr>
                    </thead>
                    <tbody>
                    {completeInventoryAudits.map((inventoryAudit, index) => (
                      <tr key={index}>
                        <td>
                          {hasManageInventoryAuditsPermission &&
                            <React.Fragment>
                              <input
                                type='checkbox'
                                value={inventoryAudit.id}
                                checked={selectedIds.findIndex(id => id === inventoryAudit.id) !== -1}
                                onChange={this.handleChange}
                              />
                              &nbsp;
                            </React.Fragment>
                          }
                        </td>
                        <td>{inventoryAudit.audited_by}</td>
                        <td>{this.getLocationName(inventoryAudit.inventory_location_id)}</td>
                        <td>{convertDbDateTimeToFormInputDateTime(inventoryAudit.audit_started_at)}</td>
                        <td>{convertDbDateTimeToFormInputDateTime(inventoryAudit.audit_ended_at)}</td>
                      </tr>
                    ))}
                    </tbody>
                  </React.Fragment>
                }
                {incompleteInventoryAudits.length > 0 &&
                  <React.Fragment>
                    <thead>
                    <tr>
                      <th colSpan='5'>
                        {hasManageInventoryAuditsPermission &&
                          <React.Fragment>
                            <input
                              type='checkbox'
                              checked={selectAll.incomplete}
                              onChange={() => this.handleSelectAll('incomplete')}
                            />
                            &nbsp;&nbsp;
                          </React.Fragment>
                        }
                        <strong>{I18n.t('inventory.audit.previousAuditsIncomplete')}</strong>
                      </th>
                    </tr>
                    <tr>
                      <th />
                      <th>Audited By</th>
                      <th>Location</th>
                      <th colSpan='2'>Audit start</th>
                    </tr>
                    </thead>
                    <tbody>
                    {incompleteInventoryAudits.map((inventoryAudit, index) => (
                      <tr key={index}>
                        <td>
                          {hasManageInventoryAuditsPermission &&
                            <React.Fragment>
                              <input
                                type='checkbox'
                                value={inventoryAudit.id}
                                checked={selectedIds.findIndex(id => id === inventoryAudit.id) !== -1}
                                onChange={this.handleChange}
                              />
                              &nbsp;
                            </React.Fragment>
                          }
                        </td>
                        <td>{inventoryAudit.audited_by}</td>
                        <td>{this.getLocationName(inventoryAudit.inventory_location_id)}</td>
                        <td>{convertDbDateTimeToFormInputDateTime(inventoryAudit.audit_started_at)}</td>
                        <td>
                          <Button
                            className='btn btn-default'
                            size='sm'
                            onClick={() => handleLoadAudit(inventoryAudit.id)}
                          >
                            {I18n.t('inventory.audit.button.loadAudit')}
                          </Button>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </React.Fragment>
                }
              </Table>
            </form>
          </React.Fragment>
        }
      </React.Fragment>);
  }
}

InventoryAuditListing.propTypes = {
  actions: PropTypes.shape({
    addMessage: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    getData: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    startSpinner: PropTypes.func.isRequired,
    stopSpinner: PropTypes.func.isRequired
  }).isRequired,
  handleLoadAudit: PropTypes.func.isRequired,
  hasManageInventoryAuditsPermission: PropTypes.bool.isRequired,
  inventoryAuditExport: PropTypes.object,
  inventoryAudits: PropTypes.object,
  locations: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    hasManageInventoryAuditsPermission: userHasPermission(state, {permissions: [permissions.manage_inventory_audits]}),
    inventoryAuditExport: state[itemNames.inventoryAuditExport] ? state[itemNames.inventoryAuditExport] : {},
    inventoryAudits: state[dataNames.inventoryAudits] ? state[dataNames.inventoryAudits][0] : {},
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    addMessage,
    change,
    getData,
    getItem,
    startSpinner,
    stopSpinner
  };
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (InventoryAuditListing);
