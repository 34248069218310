import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Card, Col, Row, Table} from 'react-bootstrap';
import ContentConcealer from '../../../common/concealers/ContentConcealer';

const PlantCreated = ({item}) => {
  return (
    <Card>
      <Row>
        <Col xs={6}>
          <h3>{I18n.t('itemTransactions.item.itemDetails')}</h3>
          <Table striped className='item-details'>
            <thead>
            <tr>
              <th>{I18n.t('itemTransactions.field')}</th>
              <th>{I18n.t('itemTransactions.value')}</th>
            </tr>
            </thead>
            <tbody>
              <ContentConcealer show={Boolean(item.transaction.planting_batch && item.transaction.planting_batch.batch_name)}>
                <tr>
                  <td>{I18n.t('itemTransactions.item.plantBatchID')}</td>
                  <td>{item.transaction.planting_batch && item.transaction.planting_batch.batch_name}</td>
                </tr>
              </ContentConcealer>
              <tr>
                <td>{I18n.t('itemTransactions.item.qtyUsed')}</td>
                <td>{Math.abs(item.transaction.transacted_qty)} {item.transaction.transacted_uom}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Card>
  );
};

PlantCreated.propTypes = {
  item: PropTypes.object.isRequired,
};

export default PlantCreated;
