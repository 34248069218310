import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import PrinterListItem from './PrinterListItem';

const PrinterList = (props) => {

  const selectedPrinter = props.machinePrinters.find((printer) => printer.selected);
  if(selectedPrinter) selectedPrinter.language_type = 'escpos';
  const languageType = !selectedPrinter || (selectedPrinter && !selectedPrinter.language_type) ? 'html' : selectedPrinter.language_type;

  return (
    <div className='printer-settings'>
    <table className='table table-sm table-striped table-bordered'>
      <thead>
        <tr>
          {
          props.showFontSize ? null : (<th colSpan={3}>
            &nbsp;
          </th>)
          }
          {
            props.showFontSize ? null : (
              <th colSpan={props.showFontSize ? 2 : 3}>{I18n.t('printerSettings.resolution')} <span
                style={{fontWeight: 'normal'}}>(Click or Tap to change DPI)</span></th>)
          }
        </tr>
        {
          props.showFontSize
          ? (
            <tr>
              <th style={{width: '24px', textAlign: 'center'}}>&nbsp;</th>
              <th style={{width: '50%'}}>Printer</th>
              <th style={{width: '50%'}}>Status</th>
            </tr>
          )
            : (
            <tr>
              <th style={{width: '24px', textAlign: 'center'}}>&nbsp;</th>
              <th>Printer</th>
              <th>Status</th>
              <th>Auto Print</th>
              <th style={{textAlign: 'center'}}>{I18n.t('printerSettings.dpi203')}</th>
              <th style={{textAlign: 'center'}}>{I18n.t('printerSettings.dpi300')}</th>
              <th style={{textAlign: 'center'}}>{I18n.t('printerSettings.dpi600')}</th>
            </tr>
          )
        }
      </thead>
      <tbody>
      {
        props.machinePrinters.map( (printer, index) => {
          if(printer && printer.remote) return null;
          return (
            <PrinterListItem
              key={index}
              showFontSize={props.showFontSize}
              printer={printer}
              removePrinter={props.removePrinter}
              onChangeFontSize={props.onChangeFontSize}
              onChangeDpi={props.onChangeDpi}
              onToggleSelected={props.onToggleSelected}
              onChangeField={props.onChangeField}
            />
          );
        })
      }
      </tbody>
      {
        props.labelKey !== 'RETAIL_RECEIPT'
        ? null
          : !selectedPrinter ? null : <tbody>
        <tr>
          <td colSpan={5}>
            <strong>Receipt Printers Must Support ESCPOS</strong><br />
            Characters per line are used to calculate the percent based width of the first and second columns.  Generally
            the print size is approximately 16 characters per inch but your resolution may vary so it's best to test these incrementally.
          </td>
        </tr>
        </tbody>
      }
      {
        languageType === 'escpos' && selectedPrinter && props.labelKey === 'RETAIL_RECEIPT'
          ? <tfoot>
          <tr>
            <td colSpan={5}>
              <div className='col-md-3'>
                <div className='form-group'>
                  <label>Characters Per Line</label>
                  <input
                    type='number'
                    placeholder='Number'
                    className='form-control form-control-sm'
                    min={0}
                    onChange={(event) => {
                      props.onToggleSetting('characters_per_line', event.target.value);
                    }}
                    value={selectedPrinter && selectedPrinter.characters_per_line !== undefined ? selectedPrinter.characters_per_line : 48}
                  />
                </div>
                <div className='form-group'>
                  <label>First Column Width</label>
                  <input
                    type='number'
                    placeholder='Number'
                    className='form-control form-control-sm'
                    min={0}
                    onChange={(event) => {
                      props.onToggleSetting('first_column_width', event.target.value);
                    }}
                    value={selectedPrinter && selectedPrinter.first_column_width !== undefined ? selectedPrinter.first_column_width : 60}
                  />
                  <div className='text-muted'>In Percent.  The second column will be calculated from this.  Use integers between 1 and 100.</div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label>Configuration String</label>
                  <textarea
                    className='form-control form-control-sm'
                    onChange={(event) => {
                      props.onToggleSetting('printParams', event.target.value);
                    }}
                    value={selectedPrinter && selectedPrinter.printParams !== undefined ? selectedPrinter.printParams : ''}
                  />
                  <div className='text-muted'>
                    Configuration string is a collection of properties delimited by ampersands (&) and equal signs.<br />
                    For example, <strong>configParams=width&width=7.3&calculateHeight=1</strong>.
                    <div>Please speak with onboarding staff regarding the specifics of this field and its use.</div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
          : null
      }
    </table>
      <div className='row'>
        <div className='col-md-12' style={{textAlign: 'right'}}>
          <button
            className='btn btn-primary'
            style={{marginLeft: '2px'}}
            disabled={!props.printersChanged}
            onClick={(event) => {
              props.onSaveForMachine(event);
            }}>
            {I18n.t('common.savePreferencesForThisMachine')}
          </button>
        </div>
      </div>
    </div>
  );

};

PrinterList.propTypes = {
  onChangeFontSize: PropTypes.func.isRequired,
  showFontSize: PropTypes.bool,
  printersChanged: PropTypes.bool.isRequired,
  savedMessage: PropTypes.string.isRequired,
  onSaveForMachine: PropTypes.func.isRequired,
  onSaveForSession: PropTypes.func.isRequired,
  removePrinter: PropTypes.func.isRequired,
  onToggleSelected: PropTypes.func.isRequired,
  onChangeDpi: PropTypes.func.isRequired,
  qzPrinters: PropTypes.array.isRequired,
  machinePrinters: PropTypes.array.isRequired,
  onTogglePreferencesHelp: PropTypes.func.isRequired,
  showPreferencesHelp: PropTypes.bool.isRequired,
  labelKey: PropTypes.string,
  onToggleSetting: PropTypes.func.isRequired,
  onChangeField: PropTypes.func.isRequired,
};

export default PrinterList;
