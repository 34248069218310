import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack} from 'react-router-redux';
import {formValueSelector} from 'redux-form';
import * as dataNames from '../../../../constants/dataNames';
import * as itemNames from '../../../../constants/itemNames';
import * as messageTypes from '../../../../constants/messageTypes';
import * as apiActions from '../../../../actions/apiActions';
import {unsetItem} from '../../../../actions/itemActions';
import {unsetData} from '../../../../actions/dataActions';
import {addMessage} from '../../../../actions/systemActions';
import {getOptionErrorMessage} from '../../common/productionRunMessages';
import {
  getStartInfusionInitialValues, getInfusionPotencyTableData,
  getAssemblyItemOptions, getStartInfusionFormInputs, getAssemblyOptions, getAssemblyProductIds
} from '../../../../selectors/assemblyJobsSelectors';
import {getIntegrationState} from '../../../../selectors/integration/integrationSelectors';
import {getSelectedProducts} from '../../../../selectors/productsSelectors';
import {getItemOptions} from '../../../../selectors/processingSelectors';
import {getCurrentFacilityUserOptions} from '../../../../selectors/usersSelectors';
import {convertFormInputDateToDbDate} from '../../../../util/dateHelpers';
import InProgressOverlay from '../../../common/InProgressOverlay';
import FormWrapper from '../../../common/form/FormWrapper';
import StartInfusionFormWrapper from './StartInfusionFormWrapper';
import {
  isAllowNegativeInventory,
  getInventoryComplianceSettings
} from '../../../../selectors/complianceSettingsSelectors';
import NegativeInventoryAlert from '../../../common/negative-inventory/NegativeInventoryAlert';
import showNegativeAlert from '../../../common/negative-inventory/showNegativeAlert';
import * as statuses from '../../../../constants/statuses';
import {START_INFUSION_FORM} from '../../../../constants/forms';
import {isFeatureEnabled} from '../../../../selectors/featureToggles';
import {isLeafPaConfigPackClosedLoopFacility} from '../../../../selectors/facilitiesSelectors';

export class StartInfusionPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.loadTestResults = this.loadTestResults.bind(this);
    this.setNegativeConfirmationState = this.setNegativeConfirmationState.bind(this);
    this.filterRemediatedTopicalAssemblies = this.filterRemediatedTopicalAssemblies.bind(this);
    this.state = {
      ready: false,
      submitting: false,
      showNegativeInvConfirmation: {
        show: false,
        onHide: null,
        onConfirm: null,
      },
      hasRemediatedTestResults: false
    };
  }

  componentWillMount() {
    this.props.actions.unsetData(dataNames.hardReservations);
    this.props.actions.unsetData(dataNames.itemsAvailability);
    this.props.actions.unsetData(dataNames.testResults);

    this.props.actions.getItem('/api/compliance_settings', itemNames.complianceSettings)
      .then(compliance => this.loadData(compliance))
      .catch(() => this.loadData());
  }

  componentWillUnmount() {
    this.props.actions.unsetData(dataNames.hardReservations);
    this.props.actions.unsetData(dataNames.itemsAvailability);
    this.props.actions.unsetData(dataNames.testResults);
    this.props.actions.unsetData(dataNames.itemMasters);
  }

  setNegativeConfirmationState(state) {
    this.setState({
      showNegativeInvConfirmation: state
    });
  }

  loadData(compliance) {
    const {
      actions: {getUnpaginatedData, ensureGetUnpaginatedData, getDataByPost, goBack, addMessage},
      selectedProducts
    } = this.props;
    const promises = [
      getUnpaginatedData('/api/assemblies', dataNames.assemblies).then(() => {
        const {assemblyProductIds} = this.props;
        return getDataByPost(
          '/api/item_masters/multiple',
          {ids: assemblyProductIds, with_relations: 'subcategory'},
          dataNames.products,
          {failed: 'ei.processingTypes.form.failed'}
        );
      }),
      getUnpaginatedData('/api/strains/by_organization', dataNames.organizationStrains, {failed: 'strains.get.failed'}, {with_trashed: 1}),
      getUnpaginatedData('/api/equipment', dataNames.equipmentItems, {failed: 'equipment.get.failed'}),
      getUnpaginatedData('/api/partners', dataNames.partners, {failed: 'partners.get.failed'}),
      getUnpaginatedData('/api/users/current_facility', dataNames.currentFacilityUsers, {failed: 'packaging.getUsers.failed'}),
      ensureGetUnpaginatedData('/api/phases', dataNames.phases, {failed: 'phases.get.failed'}),
    ];

    if (this.props.integrationState.isBiotrack) {
      promises.push(
        this.props.actions.getUnpaginatedData('/api/biotrack/categories', dataNames.biotrackCategories),
        this.props.actions.getUnpaginatedData('/api/biotrack/categories/mapping', dataNames.biotrackCategoryMappings)
      );
    }

    const allowedIds = [];

    promises.push(
      getUnpaginatedData(
        '/api/production_runs',
        dataNames.productionRuns,
        {failed: 'productionRuns.get.failed'},
        {detailed: 1, status: statuses.open}
      ).then(() => {
        if (selectedProducts.length) {
          const {initialValues: {productionRunPackageCodes}} = this.props;

          selectedProducts.map(product => {
            if (productionRunPackageCodes.includes(product.package_code)) {
              addMessage(
                messageTypes.warning,
                ['cart.getScannedItem.notFound', {packageId: product.package_code}]
              );
            } else {
              allowedIds.push(product.item_id);
            }
          });

          this.loadTestResults(this.props.packageIds);
          getDataByPost(
            '/api/items/multiple',
            {ids: allowedIds, detailed: 1},
            dataNames.inventoryItems,
            {failed: 'products.get.failed'},
            {detailed: 1}
          ).then(() => {
            const {items} = this.props;
            const ids = items.map(item => item.item_master_id);
            return getDataByPost(
              '/api/item_masters/multiple',
              {ids, with_relations: 'subcategory'},
              dataNames.itemMasters,
              {failed: 'ei.processingTypes.form.failed'},
              {detailed: 1}
            );
          })
            .then(() => {
              const {allowNegativeInventory, items, initialValues, actions: {addMessage}} = this.props;
              items
                .filter(item => !initialValues.inputs.some(input => input.item_id === item.item_id))
                .forEach(item => {
                  const error = getOptionErrorMessage(item, undefined, undefined, allowNegativeInventory);
                  return addMessage(messageTypes.error, [`ei.searchItem.${error}`, {packageId: get(item, 'package_code', '')}]);
                });
            });

          getUnpaginatedData('/api/item_reservations', dataNames.reservations, {failed: 'reservations.failed'}, {item_ids: allowedIds});
          getDataByPost('/api/item_availability/multiple', {ids: allowedIds}, dataNames.itemsAvailability, {failed: 'itemsAvailability.get.failed'})
            .then((results) => {
              if (!results) return true;
              if (!this.props.allowNegativeInventory) return true;
              const negativeItem = results.find(r => r.qty_onhand_base <= r.qty_reserved_base);
              return showNegativeAlert(!!negativeItem, this.setNegativeConfirmationState).catch(goBack);
            });
        }
      })
    );

    Promise
      .all(promises)
      .then(() => this.setState({ready: true}))
      .catch(() => this.setState({ready: true}));
  }

  loadTestResults(packageIds) {
    return this.props.actions.getDataByPost(
      '/api/lab_results/by_package_ids',
      {ids: packageIds},
      dataNames.testResults,
      {failed: 'cultivation.testResults.get.failed'},
      null,
      (testResults) => {
        this.setState({dataLoaded: true});
        // If any of test results types is 'remediated', then only allow to create 'Remediated Topical'
        const hasRemediatedTestResults = !!testResults.find((testResult) => testResult.test_type === 'remediation');
        this.setState({hasRemediatedTestResults});
      }
    );
  }

  onSubmit(formData) {
    const sendData = {
      assembly_id: formData.assembly_id,
      assembly_qty: formData.assembly_qty,
      start_time: convertFormInputDateToDbDate(formData.start_time, this.props.timezone),
      expected_completion_time: convertFormInputDateToDbDate(formData.expected_completion_time, this.props.timezone),
      equipment_ids: formData.equipment_ids.map(equipment => equipment.id),
      employee_ids: formData.employee_ids.map(employee => employee.id),
      name: formData.name || undefined,
      inputs: formData.inputs.map(batch => {
        return {
          item_id: batch.item_id,
          qty: batch.qty,
          uom: batch.uom_display,
          item_master_id: batch.itemMaster && batch.itemMaster.id || batch.item_master_id || null,
          package_code: batch.packageCode,
        };
      })
    };

    return this.props.actions.postItem(
      '/api/assembly_jobs',
      sendData,
      itemNames.infusion,
      {
        success: 'ei.infusion.create.actions.successStart',
        fail: 'ei.infusion.create.actions.failStart'
      },
      null,
      this.props.actions.goBack
    );
  }

  clearSearch() {
    return this.props.actions.unsetItem(itemNames.checkoutScan);
  }

  // Return assemblies for 'REMEDIATED_TOPICAL' subcategories
  filterRemediatedTopicalAssemblies() {
    return this.props.assemblies.filter((assembly) => {
      const item_master = this.props.itemMasters.find((itemMaster) => itemMaster.id === assembly.item_master_id);
      if (!item_master) {
        return false;
      }
      return (item_master.subcategory.subcategory_code === 'REMEDIATED_TOPICAL');
    });
  }

  render() {
    const {
      inputs,
      assemblies,
      equipments,
      employees,
      itemOptions,
      initialValues,
      potencyTableData,
      allowNegativeInventory,
      complianceSettings,
      actions: {addMessage},
      getFormValue,
      isPaRemediationLabelsEnabled
    } = this.props;
    const {ready, submitting, showNegativeInvConfirmation, hasRemediatedTestResults} = this.state;
    const message = `common.form.${submitting ? 'saving' : 'loadingData'}`;

    const filteredAssemblies = hasRemediatedTestResults ? this.filterRemediatedTopicalAssemblies() : assemblies;

    return (
      <FormWrapper title='ei.infusion.create.title' className='start-infusion-page' goBack={this.props.actions.goBack}>
        <InProgressOverlay isActive={!ready || submitting} translate={true} message={message}/>
        <NegativeInventoryAlert confirmationState={showNegativeInvConfirmation}/>
        <StartInfusionFormWrapper
          form={START_INFUSION_FORM}
          inputs={inputs}
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          addMessage={addMessage}
          loadTestResults={this.loadTestResults}
          clearSearch={this.clearSearch}
          assemblies={filteredAssemblies}
          employees={employees}
          equipments={equipments}
          itemOptions={itemOptions}
          potencyTableData={potencyTableData}
          allowNegativeInventory={allowNegativeInventory}
          complianceSettings={complianceSettings}
          getFormValue={getFormValue}
          isPaRemediationLabelsEnabled={isPaRemediationLabelsEnabled}
        />
      </FormWrapper>
    );
  }
}

StartInfusionPage.propTypes = {
  actions: PropTypes.shape({
    addMessage: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    ensureGetUnpaginatedData: PropTypes.func.isRequired,
    getDataBatchByPost: PropTypes.func.isRequired,
    getDataByPost: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired,
  }).isRequired,
  selectedProducts: PropTypes.array.isRequired,
  assemblyProductIds: PropTypes.array.isRequired,
  assemblies: PropTypes.array.isRequired,
  employees: PropTypes.array.isRequired,
  equipments: PropTypes.array,
  initialValues: PropTypes.object,
  potencyTableData: PropTypes.object,
  items: PropTypes.array.isRequired,
  inputs: PropTypes.array,
  itemOptions: PropTypes.array.isRequired,
  timezone: PropTypes.string.isRequired,
  integrationState: PropTypes.object.isRequired,
  allowNegativeInventory: PropTypes.bool,
  isPaRemediationLabelsEnabled: PropTypes.bool.isRequired
};

function mapDispatchToProps(dispatch) {
  const actions = {
    ...apiActions,
    goBack,
    addMessage,
    unsetItem,
    unsetData
  };

  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

const selector = formValueSelector(START_INFUSION_FORM);

function mapStateToProps(state) {
  const selectedProducts = getSelectedProducts(state);
  const packageIds = selectedProducts.reduce(
    (acc, product) => {
      acc.indexOf(product.package_id) > -1 || acc.push(product.package_id);
      return acc;
    },
    []
  );
  return {
    assemblyProductIds: getAssemblyProductIds(state),
    inputs: getStartInfusionFormInputs(state),
    assemblies: getAssemblyOptions(state),
    equipments: state[dataNames.equipmentItems],
    employees: getCurrentFacilityUserOptions(state),
    initialValues: getStartInfusionInitialValues(state),
    potencyTableData: getInfusionPotencyTableData(state),
    items: getItemOptions(state),
    itemOptions: getAssemblyItemOptions(state),
    timezone: state.timezone,
    integrationState: getIntegrationState(state),
    packageIds,
    selectedProducts,
    allowNegativeInventory: isAllowNegativeInventory(state),
    complianceSettings: getInventoryComplianceSettings(state),
    getFormValue: (name) => selector(state, name),
    itemMasters: state[dataNames.products],
    isPaRemediationLabelsEnabled: isLeafPaConfigPackClosedLoopFacility(state) && isFeatureEnabled(state)('feature_pa_hb_1024_remediation_labels')
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StartInfusionPage);
