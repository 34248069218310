import {createSelector} from 'reselect';
import get from 'lodash.get';
import * as dataNames from '../constants/dataNames';
import * as itemNames from '../constants/itemNames';
import {facilityHasModule} from './facilitiesSelectors';

export const getPublicOrganizations = (state) => state[dataNames.publicOrganizations];

const getItemMasterCategories = (state) => state[dataNames.categories];

const getPoAndSoTransactions = (state) => state[dataNames.poAndSoTransactions];

const getCurrentOrganization = (state) => state[itemNames.currentOrganization];

export const getConnectsPartners = (state) => state[dataNames.connectsPartners];

export const getPublicOrganizationsWithCategories = createSelector(
  [getPublicOrganizations, getItemMasterCategories], (organizations, categories) => {
    return organizations.map((organization) => {
      return Object.assign({}, organization, {
        categoryString: (organization.public_category_ids && organization.public_category_ids.length) ? organization.public_category_ids.map((cat_id) => {
          const selectedCat = categories.find(cat => {
            return cat.id === cat_id;
          });
          return selectedCat ? selectedCat.name : '';
        }).join(', ') : 'None',
      });
    });
  });


export const getIsConnectsActive = createSelector([getCurrentOrganization, facilityHasModule], (organization, facilityHasModule) =>
{
  return facilityHasModule && get(organization, 'public_profile_enabled', false);
});

export const getSupplyChainTransactions = createSelector(
  [getPoAndSoTransactions], (transactions) => {
    return transactions.map((order) => {
      return {
        id: order.id,
        type: order.po_number ? 'PO' : 'SO',
        number: order.po_number ? order.po_number : order.sales_order_number,
        date_ordered: order.date_ordered,
        total: order.order_total,
        partnerId: order.po_number ? order.vendor_facility_id : order.partner_facility_id
      };
    }).reduce((acc, order) => {
      if(!order.partnerId || order.partnerId === null) return acc;
      if(acc[order.partnerId]){
        acc[order.partnerId].push(order);
      } else {
        acc[order.partnerId] = [order];
      }
      return acc;
    }, {});
  });
