/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import filter from 'lodash.filter';
import get from 'lodash.get';
import { bindActionCreators } from 'redux';
import { isInvalid } from 'redux-form';
import { goBack, push } from 'react-router-redux';
import { handleComplexSelectRow } from '../../actions/helpers/selectedDataHelper';
import {
  getCanManageInternalTransfers,
  getProductsForInternalTransfers,
  getSelectedProductIds,
  normalizeFinishedProducts
} from '../../selectors/finishedProductsSelectors';
import { isFeatureEnabled } from '../../selectors/featureToggles';
import { getIntegrationState } from '../../selectors/integration/integrationSelectors';
import { getFlattenedLocations, getLocationsForSharedProducts } from '../../selectors/locationsSelectors';
import { unsetData, setData } from '../../actions/dataActions';
import { addMessage } from '../../actions/systemActions';
import * as apiActions from '../../actions/apiActions';
import * as selectedDataActions from '../../actions/selectedDataActions';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import * as messageTypes from '../../constants/messageTypes';
import TablePageWrapper from '../common/grid/TablePageWrapper';
import PageTitle from '../common/PageTitle';
import PrinterModal from '../printer/PrinterModal';
import ModalWrapper from '../common/ModalWrapper';
import ActivateInventoryModal from './activate/ActivateInventoryModal';
import InProgressOverlay from '../common/InProgressOverlay';
import LotLineage from '../finished-products/lineage/LotLineage';
import ItemProductionRuns from './production-runs/ItemProductionRuns';
import { getOptionsErrorMessages } from '../ei/common/productionRunMessages';
import { getTotalResults } from '../../selectors/paginationSelectors';
import { getDataUpdateAvailable } from '../../selectors/dataUpdateSelectors';
import AbstractInventoryPage from './common/AbstractInventoryPage';
import QuickMovePanel from './QuickMovePanel';
import {ConnectedIntegrationTrackingStatusModal} from '../common/integration/IntegrationTrackingStatusModal';
import { isInactivationReasonDisplayed } from '../../selectors/forms/prepackWeightsFacilitySelectors';
import { isActiveFacilityManufacturing } from '../../selectors/facilitiesSelectors';
import { canUserManageReservationAndIsAllowedInComplianceSettings } from '../../selectors/forms/productFormSelectors';

import { getUserHasPermissionFunc } from '../../selectors/usersSelectors';
import DailyWastePage from './daily-waste/DailyWastePage';
import ConfirmReleaseReservations from './ConfirmReleaseReservations';
import { nonTestableInventoryTypes } from '../../constants/integration/biotrack/biotrackInventoryTypes';
import { DAILY_WASTE_FORM } from '../../constants/forms';
import PackageReservations from '../reservations/PackageReservations';
import * as p from '../../constants/permissions';
import InternalTransfers from '../transfers/internal/InternalTransfers';
import { isAllowedQuickMoveByMedicallyCompliantStatus } from '../../actions/integrationActions';
import {
  getInventoryComplianceSettings,
  isInvPackageTrackingNotInCompliance
} from '../../selectors/complianceSettingsSelectors';
import { hasPackagesTags } from '../../selectors/integrationSelectors';
import { setSolrErrorMessage } from '../../actions/solrActions';
import {getUseEntityLocksForItems} from '../../selectors/coreSettingsSelectors';
import {getSolrCoreName} from '../../selectors/inventoryItemsSelectors';

// Preserves memoization producing new functions based on the passed in permission
const userManagesInternalTransfers = getUserHasPermissionFunc(p.manage_inventory);

export class EIInventoryPage extends AbstractInventoryPage {
  constructor(props, context) {
    super(props, context);

    this.loadTabs = this.loadTabs.bind(this);
    this.setTabs = this.setTabs.bind(this);
    this.setTabsLoaded = this.setTabsLoaded.bind(this);
    this.ensureToken = this.ensureToken.bind(this);
    this.clearTokens = this.clearTokens.bind(this);
    this.cancelTokens = this.cancelTokens.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
    this.releaseAllReservations = this.releaseAllReservations.bind(this);
    this.releaseAllReservationsConfirmed = this.releaseAllReservationsConfirmed.bind(this);
    this.dataUpdated = this.dataUpdated.bind(this);
    this.reload = this.reload.bind(this);
    this.setActiveTab = this.setActiveTab.bind(this);
    this.filter = this.filter.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.fetchChildren = this.fetchChildren.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.unsetSearchData = this.unsetSearchData.bind(this);
    this.hideActivateModal = this.hideActivateModal.bind(this);
    this.getLabResults = this.getLabResults.bind(this);

    this.startJob = this.startJob.bind(this);
    this.validateReservedAndOnholdStatus = this.validateReservedAndOnholdStatus.bind(this);
    this.switchTab = this.switchTab.bind(this);
    this.onTabChanged = this.onTabChanged.bind(this);
    this.isActiveTab = this.isActiveTab.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.printLabel = this.printLabel.bind(this);
    this.hidePrinter = this.hidePrinter.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.isRowExpandable = this.isRowExpandable.bind(this);
    this.showProductionRunsModal = this.showProductionRunsModal.bind(this);
    this.hideProductionRunsModal = this.hideProductionRunsModal.bind(this);
    this.productDetails = this.productDetails.bind(this);
    this.showActivateModal = this.showActivateModal.bind(this);
    this.showLineageModal = this.showLineageModal.bind(this);
    this.hideLineageModal = this.hideLineageModal.bind(this);
    this.onActivateInventory = this.onActivateInventory.bind(this);
    this.showWasteModal = this.showWasteModal.bind(this);
    this.hideWasteModal = this.hideWasteModal.bind(this);
    this.onSaveWasteClick = this.onSaveWasteClick.bind(this);

    this.showWasteModal = this.showWasteModal.bind(this);
    this.hideWasteModal = this.hideWasteModal.bind(this);
    this.onSaveWasteClick = this.onSaveWasteClick.bind(this);
    this.validateLabResultStatus = this.validateLabResultStatus.bind(this);
    this.showReservationsModal = this.showReservationsModal.bind(this);
    this.hideReservationsModal = this.hideReservationsModal.bind(this);
    this.showTransferModal = this.showTransferModal.bind(this);
    this.hideTransferModal = this.hideTransferModal.bind(this);

    this.splitPackage = this.splitPackage.bind(this);
    this.addTestResult = this.addTestResult.bind(this);

    this.beforeSubmitAction = this.beforeSubmitAction.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      activeTab: null,
      tabs: [],
      ready: false,
      showActivateModal: false,
      showProductionRunsModal: false,
      showLineageModal: false,
      showPrinter: false,
      tabsLoaded: false,
      item: {},
      cancelTokens: {},
      quickMoveDisabled: false,
      reindexing: false,
      showWasteModal: false,
      showReleaseReservationsConfirmModal: false,
      doFormSubmit: 0,
      selectedRows: [],
      showReservationsModal: false,
      packageIdForReservationsModal: 0,
      packageMetaData: {},
      showTransferModal: false
    };
    this._componentType = 'EIInventory';
  }

  componentWillMount() {
    this.setState({ tabsLoaded: false });
    this.props.actions.clearSelectedData(dataNames.products);
    this.props.actions.getUnpaginatedData(
      '/api/location_hierarchy',
      dataNames.locations,
      { failed: 'locations.getLocations.failed' },
      { is_sales_location: 0 }
    );
    this.props.actions.getUnpaginatedData(
      '/api/production_runs',
      dataNames.productionRuns,
      { failed: 'productionRuns.get.failed' },
      { status: 'open', detailed: 1 }
    );
    this.props.actions.getUnpaginatedData('/api/partners', dataNames.partners, { failed: 'partners.get.failed' });
    this.props.actions.getItem('/api/facility_groups_sharings/facility_status', itemNames.facilitySharingStatus);
    this.loadTabs().then(() => {
      //If url is not being changed, we still need to trigger initial tab change
      this.switchTab(this.props.params.status) || this.onTabChanged(this.props.params.status);
    });
    this.props.actions.setData([], dataNames.itemMastersWithPricing);
    this.props.actions
      .getItem('/api/settings/values/by_key', itemNames.coreSettings, null, { ids: ['core_internal_transfers_enable'] })
      .then(() => this.setTabs());
    this.props.actions.getData('/api/feature_toggles', dataNames.featureToggles);
  }

  componentWillUnmount() {
    this.state.selectedRows = [];
  }

  componentWillReceiveProps(nextProps) {
    //Tab has been changed
    if (this.state.tabsLoaded && nextProps.params.status !== this.props.params.status) {
      this.onTabChanged(nextProps.params.status);
    }
  }

  startJob(name) {
    const {
      integrationState,
      complianceSettings: { inv_packages_require_tracking_id_message, inv_packages_require_tracking_id },
      selectedProducts
    } = this.props;
    const invalidProducts = [].concat(this.validateReservedAndOnholdStatus(), this.validateLabResultStatus());
    const runType = name === 'processing' ? name : 'assembly';

    if (name === 'packaging') {
      this.props.actions.push(`/packaging_jobs/packaging_job`);
      return true;
    }

    if (inv_packages_require_tracking_id) {
      const somePackagesDontHaveTrackinId = selectedProducts.some((item) => {
        return isInvPackageTrackingNotInCompliance(item);
      });

      if (somePackagesDontHaveTrackinId) {
        this.props.actions.addMessage('error', inv_packages_require_tracking_id_message, true);
        return false;
      }
    }

    if (invalidProducts.length) {
      const errors = getOptionsErrorMessages(invalidProducts, runType, integrationState);

      if (errors.length) {
        errors.forEach((error, index) => {
          const packageId = invalidProducts[index] && invalidProducts[index].package_code;
          this.props.actions.addMessage(messageTypes.error, [`ei.searchItem.${error}`, { packageId }]);
        });
      }
    } else {
      this.props.actions.push(`/ei/${name}/start`);
    }
  }

  validateReservedAndOnholdStatus() {
    const { products } = this.props;
    const selectedProductIds = this.props.selectedProductIds;

    return products.reduce((all, product) => {
      if (~selectedProductIds.indexOf(product.id)) {
        if (product.is_reserved || product.on_hold) {
          all.push(product);
        }
      }

      return all;
    }, []);
  }

  validateLabResultStatus() {
    const {
      selectedProducts,
      integrationState: { isBiotrack, isPrBiotrack }
    } = this.props;
    if (isBiotrack) {
      let labResultFailed;

      return selectedProducts.reduce((all, product) => {
        labResultFailed = isPrBiotrack ? product.status === 'failed' : product.status !== 'passed';

        if (nonTestableInventoryTypes.indexOf(parseInt(product.integration_type, 10)) === -1 && labResultFailed) {
          product.test_results_status = 'failed';
          all.push(product);
        }

        return all;
      }, []);
    }
    return [];
  }

  switchTab(status) {
    const { tabs } = this.state;
    const isExistingTab = tabs && tabs.some((tab) => tab.eventKey === status);
    if (this.props.params.status !== status) {
      this.props.actions.push(`/ei/inventory${isExistingTab ? `/${status}` : ''}`);
      return true;
    }
    return false;
  }

  onTabChanged(status = 'prerun') {
    const activeTab = this.state.tabs.find((tab) => tab.eventKey === status);
    this.setActiveTab(activeTab, () => {
      this.filter(status);
    });
  }

  isActiveTab(status) {
    return ['inactive', 'inactive-waste'].indexOf(arguments.length ? status : this.props.params.status) === -1;
  }

  beforeSubmitAction(item, integrationState) {
    if (integrationState.isWaLeaf) {
      return this.props.actions.isAllowedQuickMoveByMedicallyCompliantStatus(item);
    }

    return Promise.resolve(item);
  }

  onSubmit(formData) {
    const { integrationState } = this.props;
    this.setState({ quickMoveDisabled: true });
    const item = {
      id: formData.item_id,
      inventory_location_id: formData.inventory_location_id,
      transacted_qty: formData.qty,
      phase_id: formData.phase_id
    };

    return this.beforeSubmitAction(item, integrationState)
      .then(this.handleSubmit)
      .then(() => {
        this.setState({ quickMoveDisabled: 'pending' });
        this.reload();
      })
      .catch((error) => {
        this.setState({ quickMoveDisabled: false });
        this.props.actions.addMessage(messageTypes.error, error);
      });
  }

  handleSubmit(item) {
    return this.props.actions.postData(`/api/items/${item.id}/move`,
      item,
      dataNames.items,
      {success:'cultivation.finishedProduct.form.quickmoveSuccess', failed: 'cultivation.finishedProduct.form.quickmoveFailed'})
      .catch((error) => {
      });
  }

  printLabel(event) {
    event.preventDefault();
    event.stopPropagation();
    const lot_id = event.target.value;
    if (lot_id === undefined) return;
    const ids = [lot_id];
    this.setState({ showPrinter: true, labelTag: 'inv_package_tag', labelIds: ids });
  }

  hidePrinter() {
    this.setState({ showPrinter: false });
  }

  showProductionRunsModal(item) {
    this.setState({ item, showProductionRunsModal: true });
  }

  hideProductionRunsModal() {
    this.setState({ showProductionRunsModal: false });
  }

  productDetails(row) {
    const {
      locations,
      sharedLocations,
      selectedProductIds,
      integrationState,
      displayInactivationReason,
      hasPackagesTags,
      userHasEditPermission,
      canUserManageReservationAndIsAllowedInComplianceSettings,
      complianceSettings,
      isMetrcStemHoldingsToggled,
      useEntityLocks
    } = this.props;
    // Fetch children uses default args to get ids which then are used to get lab results
    return (
      <QuickMovePanel
        canUserManageReservationAndIsAllowedInComplianceSettings={
          canUserManageReservationAndIsAllowedInComplianceSettings
        }
        userHasEditPermission={userHasEditPermission}
        quickMoveDisabled={Boolean(this.state.quickMoveDisabled)}
        showSelect={this.isActiveTab() || row.lot_id}
        active={this.isActiveTab()}
        handleSelect={this.handleSelect}
        locations={locations}
        sharedLocations={sharedLocations}
        selectedProductIds={selectedProductIds}
        row={row}
        onSubmit={this.onSubmit}
        printLabel={this.printLabel}
        hasPackagesTags={hasPackagesTags}
        showProductionRunsModal={this.showProductionRunsModal}
        isManufacturing={true}
        fetchChildrenAndLabResults={this.fetchChildren}
        integrationState={integrationState}
        displayInactivationReason={displayInactivationReason}
        showReservationsModal={this.showReservationsModal}
        isMetrcStemHoldingsToggled={isMetrcStemHoldingsToggled}
        allowPackageLocationSplit={complianceSettings.allow_package_location_split ? true : false}
        useEntityLocks={useEntityLocks}
      />
    );
  }

  showActivateModal() {
    const {
      integrationState: { isMetrc },
      selectedProducts,
      actions: { addMessage }
    } = this.props;
    // Do not allow inventory to be activated if the current facility is configured to use METRC and a selected package
    // does not have a Tracking ID.
    if (isMetrc && selectedProducts.some((pkg) => !pkg.state_integration_tracking_id)) {
      addMessage('error', ['cultivation.finishedProduct.error.cannotActivateWithoutTracking']);
    } else {
      this.setState({ showActivateModal: true });
    }
  }

  showLineageModal(event, item) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ item, showLineageModal: true });
  }

  hideLineageModal() {
    this.setState({ showLineageModal: false });
  }

  onActivateInventory() {
    this.hideActivateModal();
    this.reload();
  }

  showWasteModal() {
    this.setState({ showWasteModal: true });
  }

  hideWasteModal(event) {
    this.setState({ showWasteModal: false });
  }

  onRowClick({ id }) {
    const { selectedRows } = this.state;

    if (selectedRows.includes(id)) {
      return this.setState({
        selectedRows: filter(selectedRows, (value) => value !== id)
      });
    }

    return this.setState({
      selectedRows: [...selectedRows, id]
    });
  }

  isRowExpandable({ id }) {
    return this.state.selectedRows.includes(id);
  }

  onSaveWasteClick(event) {
    this.setState({ doFormSubmit: this.state.doFormSubmit + 1 });
  }

  render() {
    const {
      activeTab,
      tabs,
      showPrinter,
      showActivateModal,
      labelTag,
      labelIds,
      item,
      showProductionRunsModal,
      showLineageModal
    } = this.state;
    const {
      products,
      selectedProductIds,
      locations,
      dataTotalSize,
      dataUpdateAvailable,
      isActiveFacilityManufacturing,
      isInvalidReportDailyWasteForm,
      useEntityLocks
    } = this.props;
    return (
      <div>
        <PageTitle
          primaryText={isActiveFacilityManufacturing ? I18n.t('ei.title') : ''}
          secondaryText={I18n.t('ei.inventory.title')}
        />
        {activeTab ? (
          <TablePageWrapper
            ref={this.ref}
            settingKey='ei-inventory'
            columns={activeTab.columns}
            tabs={tabs}
            dataUpdateAvailable={dataUpdateAvailable}
            dataUpdated={this.dataUpdated}
            activeTab={activeTab.eventKey}
            switchTab={this.switchTab}
            data={products}
            handleSelect={this.handleSelect}
            selectedRows={selectedProductIds}
            scanSearchCol='package_code'
            hideScanSearch={true}
            dataTotalSize={dataTotalSize}
            externalSearch={this.handleSearch}
            showHistory={true}
            hideExport={true}
            external={true}
            isSolrListing={true}
            useAutoSuggest={this.props.useAutoSuggest && this.props.useInventoryAutoSuggest}
            autoSuggestPlaceholder='cultivation.finishedProduct.table.suggestPlaceholder'
            sort={'lot_created_at desc'}
            className='finished-product-page'
            bstProps={{
              options: {
                onSearchChange: this.onSearchChange,
                hideSizePerPage: true,
                onRowClick: this.onRowClick,
                expandBy: 'column'
              },
              selectRow: {
                clickToSelect: false,
                hideSelectColumn: true,
                clickToExpand: true
              },
              expandableRow: this.isRowExpandable,
              expandComponent: this.productDetails,
              ignoreSinglePage: true,
              trClassName: (row) => this.trClassName(useEntityLocks, row)
            }}
          />
        ) : (
          <InProgressOverlay isActive={true} message={I18n.t('phases.get.loading')} />
        )}
        <PrinterModal
          ref='printerModal'
          showPrinter={showPrinter}
          hidePrinter={this.hidePrinter}
          labelTag={labelTag}
          labelIds={labelIds}
        />
        <ModalWrapper
          Component={ActivateInventoryModal}
          title='cultivation.finishedProduct.activateInventory'
          onActivateInventory={this.onActivateInventory}
          locations={locations}
          onHide={this.hideActivateModal}
          showModal={showActivateModal}
        />
        <ModalWrapper
          Component={ItemProductionRuns}
          title='productionRuns.title'
          onHide={this.hideProductionRunsModal}
          showModal={showProductionRunsModal}
          item={item}
        />
        <ModalWrapper
          Component={LotLineage}
          title='lotLineage.title'
          onHide={this.hideLineageModal}
          showModal={showLineageModal}
          lotId={item.lot_id}
        />
        <ModalWrapper
          title='Report Daily Waste By Location'
          onHide={this.hideWasteModal}
          showModal={this.state.showWasteModal}
          headerClass='bg-info-dark'
          dialogClassName='modal-md'
          cancelButton={{ show: true }}
          okayButton={{
            show: true,
            text: 'Save',
            onClick: this.onSaveWasteClick,
            disabled: isInvalidReportDailyWasteForm
          }}
          version={2}
          doFormSubmit={this.state.doFormSubmit} /*for daily waste page*/
          Component={DailyWastePage}
        />
        <ModalWrapper
          Component={ConfirmReleaseReservations}
          onHide={() => this.setState({ showReleaseReservationsConfirmModal: false })}
          showModal={this.state.showReleaseReservationsConfirmModal}
          confirm={this.releaseAllReservationsConfirmed}
          title='reservations.confirmingClearAllFacilityReservations'
        />
        <ModalWrapper
          title={{
            text: `reservations.packageModalTitle`,
            data: { code: get(this.state.packageMetaData, 'package_id') }
          }}
          onHide={this.hideReservationsModal}
          showModal={this.state.showReservationsModal}
          headerClass='bg-info-dark'
          dialogClassName='modal-xl'
          cancelButton={{ show: true }}
          version={2}
        >
          <PackageReservations
            packageId={this.state.packageIdForReservationsModal}
            packageMetaData={this.state.packageMetaData}
          />
        </ModalWrapper>

        <ModalWrapper
          title={`Internal Transfer`}
          onHide={this.hideTransferModal}
          showModal={this.state.showTransferModal}
          headerClass='bg-info-dark'
          dialogClassName='modal-xl'
          version={2}
        >
          <InternalTransfers
            selectedProducts={this.props.internalTransferProducts}
            handleSelect={this.handleSelect}
            onCancel={this.hideTransferModal}
          />
        </ModalWrapper>
        <ConnectedIntegrationTrackingStatusModal />
      </div>
    );
  }
}

EIInventoryPage.propTypes = {
  state: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  selectedProductIds: PropTypes.array.isRequired,
  selectedProducts: PropTypes.array.isRequired,
  internalTransferProducts: PropTypes.array,
  locations: PropTypes.array.isRequired,
  sharedLocations: PropTypes.array.isRequired,
  phases: PropTypes.array.isRequired,
  dataTotalSize: PropTypes.number.isRequired,
  dataUpdateAvailable: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    ensureGetUnpaginatedData: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    getSearchData: PropTypes.func.isRequired,
    getData: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired,
    setData: PropTypes.func.isRequired,
    handleComplexSelectRow: PropTypes.func.isRequired,
    clearSelectedData: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    addMessage: PropTypes.func.isRequired
  }).isRequired,
  params: PropTypes.shape({
    status: PropTypes.string
  }).isRequired,
  integrationState: PropTypes.object.isRequired,
  displayInactivationReason: PropTypes.bool,
  userHasEditPermission: PropTypes.bool,
  isActiveFacilityManufacturing: PropTypes.bool,
  isInvalidReportDailyWasteForm: PropTypes.bool,
  hasPackagesTags: PropTypes.bool,
  complianceSettings: PropTypes.object,
  isMetrcStemHoldingsToggled: PropTypes.bool,
  useAutoSuggest: PropTypes.bool,
  useInventoryAutoSuggest: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {
  const { selectedProducts } = state;
  const products = normalizeFinishedProducts(state, {
    groupByPackage: ownProps.params.status === 'inactive',
    groupField: 'group_id_on_lot_number'
  });
  const selectedProductIds = getSelectedProductIds(state);
  const integrationState = getIntegrationState(state);
  const dataUpdateAvailable = [
    getDataUpdateAvailable(state, { name: dataNames.products, core: 'inventory' }),
    getDataUpdateAvailable(state, { name: dataNames.testResults, core: 'lab_results' })
  ];
  const complianceSettings = getInventoryComplianceSettings(state);
  const archiveInactiveAfter = parseInt(get(complianceSettings, 'archive_inactive_inventory_after', '0')) || 0;

  return {
    state,
    products,
    selectedProductIds,
    dataUpdateAvailable,
    selectedProducts,
    phases: state[dataNames.phases],
    locations: getFlattenedLocations(state),
    sharedLocations: getLocationsForSharedProducts(state),
    internalTransferProducts: getProductsForInternalTransfers(state),
    dataTotalSize: getTotalResults(state, { name: dataNames.products }),
    integrationState,
    displayInactivationReason: isInactivationReasonDisplayed(state),
    isActiveFacilityManufacturing: isActiveFacilityManufacturing(state),
    isInvalidReportDailyWasteForm: isInvalid(DAILY_WASTE_FORM)(state),
    canUserManageReservationAndIsAllowedInComplianceSettings: canUserManageReservationAndIsAllowedInComplianceSettings(
      state,
      { permissions: [p.manage_reservations] }
    ),
    canManageInternalTransfers: getCanManageInternalTransfers(state),
    userHasEditPermission: userManagesInternalTransfers(state),
    hasPackagesTags: hasPackagesTags(state),
    complianceSettings,
    archiveInactiveAfter,
    isMetrcStemHoldingsToggled: isFeatureEnabled(state)('feature_metrc_stem_holdings'),
    useAutoSuggest: isFeatureEnabled(state)('feature_solr_inventory_suggest'),
    useInventoryAutoSuggest: isFeatureEnabled(state)('feature_solr_inventory_suggest_inv_page_enable'),
    useEntityLocks: getUseEntityLocksForItems(state),
    solrCoreName: getSolrCoreName(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...apiActions,
    ...selectedDataActions,
    handleComplexSelectRow,
    goBack,
    push,
    unsetData,
    setData,
    addMessage,
    isAllowedQuickMoveByMedicallyCompliantStatus,
    setSolrErrorMessage,
  };
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EIInventoryPage);
