import {change, formValueSelector} from 'redux-form';
import moment from 'moment';
import get from 'lodash.get';
import {START_PROCESSING_FORM} from '../../constants/forms';
import {isFormChangeAction} from './utils';

import * as dataNames from '../../constants/dataNames';


const startProcessingFormMiddleware = store => next => action => {
  const result = next(action);
  const {meta, payload} = action;

  const state = store.getState();

  /*
  If the form has changed...
 */
  if (isFormChangeAction(action, [START_PROCESSING_FORM])) {

    const selector = formValueSelector(START_PROCESSING_FORM);
    const getFormValue = (fieldName) => selector(store.getState(), fieldName);

    const onChangeProcessingType = () => {
      const equipments = state[dataNames.equipmentItems];
      const processingTypes = state[dataNames.processingTypes];
      const startTime = getFormValue('start_time');

      const processingType = processingTypes.find(pt => pt.id === payload);
      const newEquipment = processingType ? equipments.filter(e => processingType.equipment.some(pe => pe.equipment_id === e.id)) : [];
      const newTime = startTime ? moment(startTime).add(processingType && processingType.estimated_time, 'minutes') : '';

      store.dispatch(change(meta.form, 'estimated_time', processingType && processingType.estimated_time || null));
      store.dispatch(change(meta.form, 'equipments', newEquipment));
      store.dispatch(change(meta.form, 'expected_completion_time', newTime));
    };

    const onChangeStartTime = () => {
      const estimatedTime = getFormValue('estimated_time');

      if (estimatedTime && payload && moment(payload).isValid()) {
        change('expected_completion_time', payload ? moment(payload).add(estimatedTime, 'minutes') : '');
      }
    };

    if (['start_time', 'processing_type_id'].indexOf(meta.field) >= 0) {

      /**
       * Start time has changed
       */
      if (get(meta, 'field') == 'start_time') {
        onChangeStartTime();
      }

      /**
       * Processing Type has changed
       */
      if (get(meta, 'field') == 'processing_type_id') {
        onChangeProcessingType();
      }
    }
  }
  return result;
};

export default startProcessingFormMiddleware;
