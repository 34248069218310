import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {reduxForm, change} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import { push } from 'react-router-redux';
import {Button, ListGroup, ButtonToolbar} from 'react-bootstrap';
import {FaTimes} from 'react-icons/fa';
import keyBy from 'lodash.keyby';
import {pick} from 'lodash';
import get from 'lodash.get';
import map from 'lodash.map';
import isequal from 'lodash.isequal';
import {getOrderSubtotal, getCartDrawerProducts, getCartDrawerOrder, isReadyStatus, soldWeightsSelector} from '../../selectors/orderSelectors';
import {getCatalogItemMasterTypeById, isMedicated} from '../../selectors/itemMastersSelectors';
import {getItemMastersAvailability} from '../../actions/itemMasterActions';
import {setItem} from '../../actions/itemActions';
import {unionData, setData} from '../../actions/dataActions';
import {addMessage} from '../../actions/systemActions';
import * as apiActions from '../../actions/apiActions';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import {getSalesLimitError} from '../../selectors/salesSettingsSelectors';
import DrawerItem from './DrawerItem';
import {getFormOptionsBySubcategory, isCureIntegrator} from '../../selectors/integration/cureApiSelectors';
import OrderPackagingButtons from './OrderPackagingButtons';
import {isAllowNegativeInventory} from '../../selectors/complianceSettingsSelectors';
import ModalWrapper from '../common/ModalWrapper';
import {CART_DRAWER} from '../../constants/forms';
import InternationalCurrencyStatic from '../common/form/InternationalCurrencyStatic';
import {isLeafPaConfigPackClosedLoopFacility} from '../../selectors/facilitiesSelectors';
import {isFeatureEnabled} from '../../selectors/featureToggles';

export class CartDrawer extends React.PureComponent {

  constructor(props, context) {
    super(props, context);

    this.state = {
      processing: false,
      showNegativeInvConfirmation: {
        show: false,
        onHide: () => {},
      },
    };
    this.removeCartProduct = this.removeCartProduct.bind(this);
    this.updateCartProductProperty = this.updateCartProductProperty.bind(this);
    this.refreshCartDrawerLine = this.refreshCartDrawerLine.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.resetCart = this.resetCart.bind(this);
    this.changeFulfillmentStatus = this.changeFulfillmentStatus.bind(this);
    this.validateNegativeInventory = this.validateNegativeInventory.bind(this);
    this.getInventoryItemsQty = this.getInventoryItemsQty.bind(this);
    this.showConfirmationPopup = this.showConfirmationPopup.bind(this);
    this.renderConfirmModal = this.renderConfirmModal.bind(this);
  }

  componentWillMount(){
    this.resetCart(this.props.order);
  }

  componentWillReceiveProps(nextProps, nextState){
    const {order, soldWeights} = nextProps;
    if(order && order !== this.props.order){
      this.resetCart(order);
    }

    // Step 2:  Use this lifecycle hook to update the calculated fields
    if (soldWeights && !isequal(soldWeights,  this.props.soldWeights)) {
      Object.keys(this.state.products).forEach((productId) => {
        const propertyName = get(this.state.products[productId], 'itemMasterType') === 'unit' ? 'quantity' : 'sold_weight';
        if (soldWeights[productId] && soldWeights[productId] !== this.state.products[productId][propertyName] || !soldWeights[productId]) {
          this.updateCartProductProperty(productId, propertyName, soldWeights[productId]);
        }
      });
    }
  }

  resetCart(order){
    if(!order){
      order = this.props.order;
    }
    const products = {};
    order.products.map(product => {
      products[product.id] = {
        ...pick(product, [
          'quantity',
          'item_master_id',
          'sold_weight_uom',
        ]),
        sold_weight: get(product, 'sold_weight', undefined),
        form_id: get(product, 'cure_order_product.form_id', null),
        itemMasterType: get(product, 'sold_weight', undefined) === undefined ? 'unit' : 'bulk'
      };
    });
    const product_ids = map(order.products,'item_master_id').sort();
    const prev_product_ids = map(this.state.products,'item_master_id').sort();
    if (!isequal(product_ids, prev_product_ids) && product_ids.length) {
      this.props.actions.getDataBatchByPost(
        '/api/items/by_item_master_ids',
        {ids: product_ids},
        dataNames.inventoryItems
      );
      this.props.actions.getUnpaginatedData(
        '/api/item_masters/children/items',
        null,
        null,
        {ids: map(order.products,'item_master_id')},
        (response) => {
          let items = [];
          response.map(im => {items = items.concat(im.items);});
          this.props.actions.unionData(items, dataNames.inventoryItems);
        }
      );
    }
    this.setState({products});
    Object.keys(products).forEach(key => {
      this.refreshCartDrawerLine(products, key);
    });
  }

  removeCartProduct(orderProductId) {
    const {order, isCure, isMmuEnabled} = this.props;
    const {getItem, deleteItem, deleteData} = this.props.actions;
    const product = order.products.find(p => p.id === Number(orderProductId));
    if (order.id && orderProductId) {
      deleteItem(
        `/api/orders/${order.id}/products/${orderProductId}`,
        itemNames.orderProduct,
        {failed: 'orders.products.remove.failed', failedHandler: getSalesLimitError},
        {},
        (data) => this.props.actions.setItem(data, itemNames.order)
      )
        .then(() => {
          // If Leaf PA and MMU limits enabled, refresh sales limit
          if (isMmuEnabled) {
            getItem(`/api/consumer_orders/${order.consumer_id}/limits`, itemNames.customerLimits);
          }
        });
      if (isCure && product && product.cure_order_product) {
        deleteData(
          '/api/cureapi/order_products/',
          product.cure_order_product.id,
          dataNames.cureOrderProducts,
          {failed: 'cureOrderProducts.remove.failed'}
        );
      }
    }
  }

  changeFulfillmentStatus(fulfillment_status) {
    const {order} = this.props;
    const {putItem} = this.props.actions;
    if (order.id) {
      this.setState({processing: true});
      putItem(
          `/api/orders/${order.id}`,
          {fulfillment_status},
          null,
          {failed: 'orders.products.remove.failed', failedHandler: getSalesLimitError},
          {},
          () => {
            this.props.actions.getItem(`/api/orders/details/${order.id}`, itemNames.order, {failed: 'orders.get.fail'},
                undefined, undefined,  {debounce: true})
              .then(() => this.setState({processing: false}))
              .catch(() => this.setState({processing: false}));
          }
      ).catch(() => this.setState({processing: false}));
    }
  }

  refreshCartDrawerLine(newProducts, productId) {
    const value = newProducts[productId].itemMasterType === 'unit' ? newProducts[productId].quantity : newProducts[productId].sold_weight;
    this.props.actions.change(CART_DRAWER, `sold-weight-${productId}`, value === undefined || value === null ? '' : value);
  }

  updateCartProductProperty(orderProductId, name, value) {
    const {products} = this.state;
    const newProducts = {
      ...products,
      [orderProductId]: {
        ...products[orderProductId],
        [name]: value,
      },
    };
    this.setState({products: {...newProducts}});
  }

  onSubmit(checkout) {

    const {order, isMmuEnabled, actions: {getItem, putItem, deleteItem, deleteData, putData}, isCure} = this.props;
    const {products} = this.state;
    this.setState({processing: true});

    const buildPromise = () => {
      // Carried this comment over - not sure exactly what it wants or if its still an issue.
      //There is a be issue: sending multiple requests on be in parallel causes unexpected results
      //@todo in order to improve performance, there should be endpoint that takes batch results
      return Object.keys(products).reduce(
        (promise, id) => {
          const oldProduct = order.products.find(p => p.id === Number(id));
          const cureProductId = oldProduct && oldProduct.cure_order_product && oldProduct.cure_order_product.id;
          if (
            products[id].itemMasterType === 'unit' && !products[id].quantity ||
            products[id].itemMasterType === 'bulk'  && !products[id].sold_weight
          ) {
            return promise.then(() => deleteItem(
              `/api/orders/${order.id}/products/${id}`,
              null,
              {failed: 'orders.products.remove.failed', failedHandler: getSalesLimitError},
              {},
              (data) => {
                this.props.actions.setItem(data, itemNames.order);
                if (cureProductId && isCure) {
                  deleteData(
                    '/api/cureapi/order_products/',
                    cureProductId,
                    dataNames.cureOrderProducts,
                    {failed: 'cureOrderProducts.remove.failed'}
                  );
                }
              }
            ));
          } else if (
            products[id].itemMasterType === 'unit' && products[id].quantity && products[id].quantity !== oldProduct.quantity ||
            products[id].itemMasterType === 'bulk' && products[id].sold_weight && products[id].sold_weight !== oldProduct.sold_weight
          ) {
            if(order.fulfillment_status === 'assigned' ) this.props.actions.addMessage('warning', ['error.adjustQuantityCheckout']);
            return promise.then(() => putItem(
              `/api/orders/${order.id}/products/${id}`,
              products[id],
              itemNames.order,
              {failed: 'orders.modify.failed', failedHandler: getSalesLimitError},
              {},
              () => {
                if (isCure && cureProductId) {
                  const curePayload = {
                    id: cureProductId,
                    order_id: order.id,
                    order_product_id: id,
                    form_id: products[id].form_id,
                  };
                  putData(
                    `/api/cureapi/order_products/${cureProductId}`,
                    curePayload,
                    dataNames.cureOrderProducts,
                    {failed: 'cureOrderProducts.save.failed'}
                  );
                }
              }
            ));
          } else if (isCure && cureProductId && products[id].form_id !== oldProduct.cure_order_product.form_id) {
            const curePayload = {
              id: cureProductId,
              order_id: order.id,
              order_product_id: id,
              form_id: products[id].form_id,
            };
            putData(
              `/api/cureapi/order_products/${cureProductId}`,
              curePayload,
              dataNames.cureOrderProducts,
              {failed: 'cureOrderProducts.save.failed'}
            );
          }
          return promise;
        },
        Promise.resolve()
      );
    };

    const unsetProcessing = () => {
      this.setState({processing: false});
    };

    this.validateNegativeInventory()
      .then(() => {
        const updatePromise = buildPromise();
        updatePromise
          .then(() => {
            // If Leaf PA and MMU limits enabled, refresh sales limit
            if (isMmuEnabled) {
              getItem(`/api/consumer_orders/${order.consumer_id}/limits`, itemNames.customerLimits);
            }
          })
          .then(() => new Promise(resolve => {
            this.setState({processing: false}, () => {
              if (checkout) {
                unsetProcessing();
                this.props.onClose();
                resolve(this.props.actions.push(checkout));
              } else {
                resolve();
              }
            });
          }))
          .catch(() => {
            this.resetCart();
            unsetProcessing();
          });
      })
      .catch((requestedAmountsData) => {
        this.showConfirmationPopup(requestedAmountsData);
      });
  }



  /***
   * @returns {boolean}
   */
  validateNegativeInventory() {
    return new Promise((resolve, reject) => {

      // If we don't need to eval for negative, skip
      const allowsNegative = () => {
        return this.props.isAllowNegativeInventory;
      };

      const itemMastersAlreadyApproved = (itemMasterIds) => {
        return itemMasterIds.reduce((acc, itemMasterId) => {
          if(!acc) return acc;
          if(this.props.itemMasterIds.indexOf(itemMasterId) === -1){
            return false;
          }
          return acc;
        }, true);
      };

      if(!allowsNegative()){
        resolve();
        return false;
      }

      const getQuantity = (orderLine) => {
        return get(orderLine, 'sold_weight', get(orderLine, 'quantity', 0));
      };

      /**
       * Get our actual requested difference quantities.  For example, if I have 4 units on order and move to 6 my
       * request is actually 2 additional units.
       */
      const requestedQuantities = Object.keys(this.state.products).reduce((acc, key) => {
        const product = this.state.products[key];
        const itemMasterId = get(product, 'item_master_id');
        const orderLine = get(this.props.order, 'products', []).find((product) => product.item_master_id === itemMasterId);
        const quantityOnOrder = getQuantity(orderLine);
        const requestedQuantity = getQuantity(product);
        const newQuantity = requestedQuantity - quantityOnOrder;
        if(newQuantity <= 0){
          return acc;
        }
        acc.push({
          item_master_id: itemMasterId,
          quantity: requestedQuantity - quantityOnOrder,
        });
        return acc;
      }, []);

      if(!requestedQuantities.length){
        resolve();
        return false;
      }

      const itemMasterIds = requestedQuantities.map((q) => q.item_master_id);
      if((allowsNegative() && itemMastersAlreadyApproved(itemMasterIds))){
        resolve();
        return false;
      }

      this.props.actions.getItemMastersAvailability(itemMasterIds, requestedQuantities)
        .then((data) => {
          const allAvailable = data.reduce((acc, item) => {
            if(!acc) return acc;
            if(!get(item, 'isAvailable', false)){
              return false;
            }
            return acc;
          }, true);
          if(!allAvailable){
            reject(data);
            return false;
          }
          resolve(data);
        });
    });
  }

  getInventoryItemsQty(itemMasterId) {
    const {inventoryItems} = this.props;
    return inventoryItems
      .filter(item => item.item_master_id === itemMasterId || item.item_master_parent_id === itemMasterId)
      .reduce((acc, item) => acc + item.qty, 0);
  }

  showConfirmationPopup(requestedAmountsData) {
    const newItemMasterIds = requestedAmountsData.map((item) => item.item_master_id);
    const hidePopup = (callBack) => {
      this.setState({
        showNegativeInvConfirmation: { show: false },
        processing: false,
      });
      if(typeof callBack === 'function'){
        callBack();
      }
    };
    this.setState({
      showNegativeInvConfirmation: {
        show: true,
        onConfirm: () => {
          const itemMasterIds = this.props.itemMasterIds.concat(newItemMasterIds);
          this.props.actions.setData(itemMasterIds, dataNames.itemMasterIds);
          hidePopup();
          setTimeout(() => {
            this.onSubmit();
          }, 100);
        },
        onHide: () => {
          hidePopup();
        }
      }
    });
  }



  renderConfirmModal() {
    const {showNegativeInvConfirmation} = this.state;

    const okayButtonProps = {
      show: true,
      onClick: showNegativeInvConfirmation.onConfirm,
      text: I18n.t('general.yes')
    };

    const cancelButtonProps = {
      show: true,
      onClick: showNegativeInvConfirmation.onHide,
      text: I18n.t('general.no')
    };

    return (
      <ModalWrapper
        Component={false}
        title={I18n.t('inventory.negativeInventoryAlert')}
        headerClass='bg-info-dark'
        onHide={showNegativeInvConfirmation.onHide}
        showModal={showNegativeInvConfirmation.show}
        okayButton={okayButtonProps}
        cancelButton={cancelButtonProps}
        dialogClassName='modal-sm'
        version={2}
      >
        <p>{I18n.t('inventory.confirmToGoNegative')}</p>
      </ModalWrapper>
    );
  }


  render() {
    // Remove getCatalogItemMasterTypeById if we can on next iteration.  Replaced with simple evaluation of weight method.
    // But want to leave an easy backward path if this has side effects.
    const {
      onClose, subTotal, items, order_type, subcategories, pricingWeights,
      subcategoryFormOptions, isCure, orderPackagingWorkflow, isReadyStatus
    } = this.props;
    const cartRoute = order_type === 'in_store'
      ? '/cart'
      : `/checkout/${order_type}`;
    const {products, processing} = this.state;
    return (
      <div className='cart-drawer'>
        <div className='close-drawer'>
          <Button size='sm' className='float-left close-button' onClick={onClose}>
            <FaTimes />
          </Button>
        </div>
        <div className='clearfix'/>
        <ListGroup className='items'>
          {items.map((item) => {
            const formOptions = subcategoryFormOptions[item.subcategory_id] || [];
            const quantity = !isNaN(parseFloat(products[item.id].quantity)) ? parseFloat(products[item.id].quantity) : undefined;
            const form_id = products[item.id].form_id;
            const unitPrice = item.unit_price || 0;
            let lineTotal = unitPrice;
            if (products[item.id].itemMasterType === 'unit') {
              lineTotal = unitPrice && quantity && parseFloat(unitPrice) && parseFloat(quantity)
                ? (unitPrice * parseFloat(quantity))
                : 0;
            }
            const itemMediated = isMedicated(item);

            if(products && products[item.id]){
              return (<DrawerItem
                id={item.id} key={item.id}
                description={item.name}
                category={subcategories[item.subcategory_id] && subcategories[item.subcategory_id].name ? subcategories[item.subcategory_id].name : ''}
                src={item.src}
                pricingWeight={
                  item.pricing_weight_id && pricingWeights && pricingWeights[item.pricing_weight_id] && pricingWeights[item.pricing_weight_id].name
                    ? pricingWeights[item.pricing_weight_id].name
                    : ''
                }
                itemTotal={parseFloat(lineTotal) || 0}
                removeItem={this.removeCartProduct}
                updateCartProductProperty={this.updateCartProductProperty}
                itemMasterType={products[item.id].itemMasterType}
                formOptions={formOptions}
                form_id={form_id}
                isCure={isCure}
                itemMediated={itemMediated}
              />);}
          })}
        </ListGroup>
        <div className='sub-total float-right'>
          {I18n.t('cart.drawer.subTotal')}:<InternationalCurrencyStatic>{subTotal.toFixed(2)}</InternationalCurrencyStatic>
        </div>
        <div className='clearfix'/>
        <ButtonToolbar className='checkout float-right'>
          <Button onClick={() => this.resetCart()} disabled={processing}>
            {I18n.t('cart.drawer.reset')}
          </Button>
          {orderPackagingWorkflow &&
            <OrderPackagingButtons
              changeFulfillmentStatus={this.changeFulfillmentStatus}
              disablingAddInventory={!isReadyStatus}
              processing={processing}
            />
          }
          <Button variant='success' onClick={() => this.onSubmit()} disabled={processing}>
            {I18n.t('cart.drawer.updateCart')}
          </Button>
          {order_type === 'in_store' ? (
            <Button variant='primary' disabled={processing} className='checkout-button' onClick={() => this.onSubmit(cartRoute)}>
              {I18n.t('cart.drawer.checkOut')}
            </Button>) : (
            <Button variant='primary' disabled={processing} className='checkout-button' onClick={() => this.onSubmit(cartRoute)}>
              {I18n.t('cart.drawer.reviewOrder')}
            </Button>
          )}
        </ButtonToolbar>
        <div className='clearfix'/>
        {this.renderConfirmModal()}
      </div>
    );
  }
}

CartDrawer.propTypes = {
  actions: PropTypes.shape({
    postItem: PropTypes.func.isRequired,
    putItem: PropTypes.func.isRequired,
    deleteItem: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    setItem: PropTypes.func.isRequired,
    getDataBatchByPost: PropTypes.func
  }).isRequired,
  onClose: PropTypes.func,
  order: PropTypes.object.isRequired,
  subTotal: PropTypes.number,
  order_type: PropTypes.string,
  items: PropTypes.array.isRequired,
  subcategories: PropTypes.object,
  pricingWeights: PropTypes.object,
  getCatalogItemMasterTypeById: PropTypes.func.isRequired,
  subcategoryFormOptions: PropTypes.object.isRequired,
  isCure: PropTypes.bool,
  orderPackagingWorkflow: PropTypes.bool,
  isReadyStatus: PropTypes.bool.isRequired,
  isAllowNegativeInventory: PropTypes.bool,
  inventoryItems: PropTypes.array
};

CartDrawer.defaultProps = {
  onClose: () => {},
  subTotal: 0,
  items: [],
  order_type: 'in_store',
  orderPackagingWorkflow: false,
};

function mapStateToProps(state, ownProps){
  const {onClose} = ownProps;
  const {customer, salesComplianceSettings: { order_packaging_workflow }, inventoryItems} = state;

  return {
    // Step 1.  International numbers changes: Track a change on a form AFTER the from has been updated in the store,
    // as opposed to with OnChange which happens before and prevents the change.  We do this with a selector on the form value
    soldWeights: soldWeightsSelector(state),
    //
    onClose,
    customer,
    facilities: state.facilities,
    facility: state.facility,
    order: getCartDrawerOrder(state),
    items: getCartDrawerProducts(state),
    orderType: state.order && state.order.fulfillment_method ? state.order.fulfillment_method : 'in_store',
    subcategories: keyBy(state.subcategories, 'id'),
    pricingWeights: keyBy(state.pricingWeights, 'id'),
    subTotal: getOrderSubtotal(state),
    getCatalogItemMasterTypeById: itemMaster => getCatalogItemMasterTypeById(state, itemMaster),
    integrationSettings: state.integrationSettings,
    subcategoryFormOptions: getFormOptionsBySubcategory(state, {customer, mode: 'customer'}),
    isCure: isCureIntegrator(state),
    orderPackagingWorkflow: order_packaging_workflow && order_packaging_workflow.value === '1',
    isReadyStatus: isReadyStatus(state),
    isAllowNegativeInventory: isAllowNegativeInventory(state),
    inventoryItems,
    itemMasterIds: state[dataNames.itemMasterIds], // holds those that have been approved to go negative
    isMmuEnabled: isLeafPaConfigPackClosedLoopFacility(state) && isFeatureEnabled(state)('feature_leaf_pa_mmu_limits')
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {push, change, setItem, addMessage, unionData, getItemMastersAvailability, setData});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

const WrappedCartDrawer = reduxForm({form: CART_DRAWER})(CartDrawer);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedCartDrawer);
