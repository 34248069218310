import PropTypes from 'prop-types';
import {reduxForm, formValueSelector} from 'redux-form';
import {connect} from 'react-redux';
import {CONNECTS_PRODUCT_MAPPING_FORM} from '../../../../constants/forms';
import validate from './validation';
import ConnectsProductMappingForm from './ConnectsProductMappingForm';


const selector = formValueSelector(CONNECTS_PRODUCT_MAPPING_FORM);

const ConnectsProductMappingReduxForm = reduxForm({
  form: CONNECTS_PRODUCT_MAPPING_FORM,
  validate,
  enableReinitialize: true,
})(ConnectsProductMappingForm);

function mapStateToProps(state) {
  const partnerProducts = selector(state, 'partnerProducts');

  return {
    itemMasters: selector(state, 'itemMasters'),
    mappedItemMasterIds: partnerProducts && Array.isArray(partnerProducts)
      ? partnerProducts.reduce((acc, product) => {

        const mappedItemMasterId = parseInt(product.item_master_id);
        if(!isNaN(mappedItemMasterId) && mappedItemMasterId > 0){
          acc.push(mappedItemMasterId);
        }
        return acc;
      }, [])
      : []
  };
}

const ConnectsProductMappingFormWrapper = connect(mapStateToProps)(ConnectsProductMappingReduxForm);

ConnectsProductMappingFormWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

export default ConnectsProductMappingFormWrapper;
