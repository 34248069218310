import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Button} from 'react-bootstrap';

const SalesExportButton = ({isMetrcOh, onSendExport, data}) => isMetrcOh ? null :
  <div>
    <p className='padding-top padding-bottom'>{I18n.t('stateIntegrators.salesExport.reviewPackage')}</p>
    <Button
      variant='primary'
      onClick={onSendExport}
      disabled={!data.length}
      >
      {I18n.t('stateIntegrators.salesExport.sendExportToStateSytem')}
    </Button>
  </div>
;

SalesExportButton.propTypes = {
  isMetrcOh: PropTypes.bool.isRequired,
  onSendExport: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired
};

export default SalesExportButton;
