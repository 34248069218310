/* eslint-disable import/prefer-default-export*/
import {createSelector} from 'reselect';
import find from 'lodash.find';
import {getCategories} from './categorySelectors';
import * as dataNames from '../constants/dataNames';

const getSelectedMultiplier = state => state.selectedPricingMultiplierData;
const getCustomerMultiplier = state => state.selectedCustomerMultiplierData;
export const getPricingMultipliers = state => state[dataNames.pricingMultipliers];

export const getSelectedMultipliersData = createSelector(getSelectedMultiplier, selectedMultipliers => selectedMultipliers.map(selectedMultiplier => selectedMultiplier.id));
export const getSelectedCustomerMultiplierData = createSelector(getCustomerMultiplier, selectedCustomerMultipliers => selectedCustomerMultipliers.map(selectedCustomerMultiplier => selectedCustomerMultiplier.id));

export const getPricingMultipliersListingData = createSelector(
  [getPricingMultipliers, getCategories],
  (pricingMultipliers, categories) => pricingMultipliers.map(pricingMultiplier => {
    const category = find(categories, {id: pricingMultiplier.category_id});
    return {...pricingMultiplier, category: category && category.name};
  })
);
