import {change, formValueSelector} from 'redux-form';
import * as actionTypes from '../../constants/actionTypes';
import * as dataNames from '../../constants/dataNames';
import * as forms from '../../constants/forms';

export const importLocalPrinters = store => next => action => {
  const result = next(action);
  if(action.type === actionTypes.IMPORT_LOCAL_PRINTERS){
    const selector = formValueSelector(forms.PRINT_SERVER_FORM);
    const printers = selector(store.getState(), 'printers');
    const localPrinters = store.getState()[dataNames.localPrinters];
    const updatedPrinters = printers.map((printer) => { // Mark those not found locally
      printer.saved = true;
      if(localPrinters.indexOf(printer.name) === -1) {
        printer.present = false;
      } else {
        printer.present = true;
      }
      return printer;
    });
    localPrinters.forEach((printerName) => { // Add thos found locally but no present
      if(!printers.find((printer) => printer.name === printerName)) {
        const p = {
          name: printerName,
          present: true,
          saved: false,
          status: 1,
          resolution: 203,
        };
        updatedPrinters.push(p);
      }
    });
    store.dispatch(change(forms.PRINT_SERVER_FORM, 'printers', updatedPrinters.sort((a,b) => {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    })));
  }
  return result;
};

export default importLocalPrinters;
