import round from 'lodash.round';
import get from 'lodash.get';
import {uomTypes, DISCRETE, VOLUME, WEIGHT, TIME} from '../constants/uomTypes';
import * as UOMS from '../constants/uoms';
import {getFractional, safeNumberConversion} from './mathHelpers';

const {EA, UOM_VALUE_PRECISION} = UOMS;

const defaultPrecision = UOM_VALUE_PRECISION;

/**
 * Converts a value in a given UOM to a base value in micrograms/microliters
 *
 * NOTE: Don't use this in conjunction with convertFromBase(). Use convertWeightFromAndTo() instead for that purpose
 *
 * @param value
 * @param fromUOM
 * @returns value as base
 */
export const convertToBase = (value, fromUOM) => {
  if (!uomTypes[fromUOM]) {
    throw new Error(`Unrecognizable UOM: ${fromUOM}`);
  }

  // Round to nearest integer
  return round(safeNumberConversion(value) * uomTypes[fromUOM].ratio);
};

/**
 * Converts a base value in micrograms/microliters to the value for a given UOM
 *
 * NOTE: Don't use this in conjunction with convertToBase(). Use convertWeightFromAndTo() instead for that purpose
 *
 * @param value
 * @param toUOM
 * @returns value by uom
 */
export const convertFromBase = (value, toUOM) => {
  if (!uomTypes[toUOM]) {
    throw new Error(`Unrecognizable UOM: ${toUOM}`);
  }

  return safeNumberConversion(value) / uomTypes[toUOM].ratio;
};


/**
 * DEPRECATED - use convertWeightFromAndTo() instead
 *
 * @param value
 * @param fromUOM
 * @param toUOM
 * @param precision
 * @returns {*}
 */
export const convert = (value, fromUOM, toUOM, precision = defaultPrecision) => {
  return convertWeightFromAndTo(value, fromUOM, toUOM, precision);
  // Original code left below to illustrate why the change.  Without the intermediate step to base weight
  // we are returning a result that is not the same as it would be when stored on the back end.
  // First identified in MJP-27039
  // const fromUOMType = uomTypes[fromUOM];
  // const toUOMType = uomTypes[toUOM];
  // if (!possibleToConvert(fromUOM, toUOM)) {
  //   throw new Error(`Conversion Error: ${fromUOM} can not be converted to ${toUOMType}`);
  // }
  // const val = safeNumberConversion(value);
  // const converted = (val * fromUOMType.ratio) / toUOMType.ratio;
  // return precision === -1 ? converted : round(converted, precision);
};

/***
 * Converts a value for a given fromUom to the corresponding value of a given toUom by converting to and from the
 * base value. Uses extended precision to prevent premature rounding issues and better output accuracy
 *
 * Uses only the base conversions.  Convert, on the other hand, has been using its own math so long it scares me to
 * just change it.
 *
 * @param weight
 * @param fromUom
 * @param toUom
 * @returns {*}
 */
export const convertWeightFromAndTo = (quantity, fromUom, toUom) => {
  if (!possibleToConvert(fromUom, toUom)) {
    throw new Error(`Conversion Error: ${fromUom} can not be converted to ${toUom}`);
  }

  // TODO: If you figure out a way to convert without using base values while ensuring 'Conversion testing with same uom' test passes. You get a bonusly from Wesley
  const baseValue = convertToBase(quantity, fromUom);
  const returnValue = convertFromBase(baseValue, toUom);
  return returnValue;
};


export const convertPrice = (price, fromUOM, toUOM) => {
  const fromUOMType = uomTypes[fromUOM];
  const toUOMType = uomTypes[toUOM];
  if (!fromUOMType || !toUOMType || fromUOMType.type !== toUOMType.type) {
    throw new Error(`Conversion Error: ${fromUOM} can not be converted to ${toUOMType}`);
  }

  return safeNumberConversion(price) * (toUOMType.ratio / fromUOMType.ratio);
};

export const convertCostByUOM = (qty, price, fromUOM, toUom, round_to = 2) => {
  qty = safeNumberConversion(qty);
  price = safeNumberConversion(price);

  if (qty === 0) return 0;

  const convertedQty = convert(qty, fromUOM, toUom, -1);
  const convertedPrice = convertPrice(price, fromUOM, toUom);

  let converted;
  if (qty / convertedQty >= 1) {
    converted = convertedQty * price;
  } else {
    converted = qty * convertedPrice;
  }

  if (round_to) {
    return round(converted, round_to);
  }

  return converted;
};

export const isBulkType = (uom_type) => {
  const uomTypesForBulk = ['weight', 'volume'];
  return uomTypesForBulk.indexOf(uom_type) !== -1;
};

export const possibleToConvert = (fromUOM, toUOM) => {
  const fromUOMType = uomTypes[fromUOM];
  const toUOMType = uomTypes[toUOM];
  return !(!fromUOMType || !toUOMType || fromUOMType.type !== toUOMType.type);
};

/***
 * Accepts array of uoms (from uoms table in inv) and returns list of those equal to or lesser
 * than the passed in selectedUom.
 * @param uoms
 * @param uom
 */
export const getUomsOfLesserOrEqualValue = (uoms, uom) => {
  if(!uom) return [];
  if(uom.toLowerCase() === 'ea'){ // Each returns weight uoms as these are being used for medicated weight (at this point)
    uom = 'KG';
  }
  const uomRecord = uomTypes[uom];
  const uomsByType = uoms.filter((uom) => uom.uom_type === uomRecord.type)
    .reduce((acc, uom) => { // Deduplicate - found in local can be multiples
      const includedUom = acc.find((u) => u.uom_code === uom.uom_code);
      if(includedUom){
        return acc;
      }
      acc.push(uom);
      return acc;
    }, []);

  const availableUoms = uomsByType.sort((a, b) => {
    return parseFloat(a.conversion_ratio) < parseFloat(b.conversion_ratio)
      ? 1
      : parseFloat(b.conversion_ratio) < parseFloat(a.conversion_ratio)
        ? -1
        : 0;
  });
  const selectedUomIndex = availableUoms.findIndex((u) => u.uom_code.toLowerCase() === uom.toLowerCase());

  return availableUoms.filter((uom, index) => index >= selectedUomIndex).map((uom) => {
    return {
      text: uom.uom_code,
      value: uom.uom_code,
    };
  });
};

export const sortByRatio = (uoms, order = 'asc', forceEachToZeroIndex = true) => {

  let orderedUoms = [...uoms];

  orderedUoms = orderedUoms.sort((a,b) => Number(a.conversion_ratio) > Number(b.conversion_ratio) ? 1 : -1);

  if (order === 'desc') orderedUoms = orderedUoms.reverse();

  const EAUomIndex = orderedUoms.findIndex(uom => uom.uom_code === EA);

  if (forceEachToZeroIndex && EAUomIndex !== -1) {
    const EAUom = orderedUoms[EAUomIndex];
    orderedUoms.splice(EAUomIndex, 1);
    orderedUoms.unshift(EAUom);
  }

  return orderedUoms;
};

export const sortByRatioAndType = (uoms, typeOrder = [DISCRETE, WEIGHT, VOLUME, TIME], sortOrder = 'asc') => {
  const typeArrays = typeOrder.reduce((acc, type) => {
    acc[type] = [];
    return acc;
  }, {});
  const uomsByTypes = uoms.reduce((acc, uom) => {
    if(!acc[uom.uom_type]){
      return acc;
    }
    acc[uom.uom_type].push(uom);
    acc[uom.uom_type] = acc[uom.uom_type].sort((a, b) => {
      const aRatio = parseFloat(a.conversion_ratio);
      const bRatio = parseFloat(b.conversion_ratio);
      return aRatio < bRatio ? 1 : aRatio > bRatio ? -1 : 0;
    });
    return acc;
  }, typeArrays);
  const sortedGroupedUoms = Object.keys(uomsByTypes).reduce((acc, uomType) => {
    const typeArray = uomsByTypes[uomType];
    acc = acc.concat(typeArray);
    return acc;
  }, []);
  return sortOrder === 'asc' ? sortedGroupedUoms : sortedGroupedUoms.reverse();
};

/**
 * Get the level of decimal precision for a given uom
 *
 * @param uom
 * @returns {undefined|Number}
 */
export const getDecimalPrecisionForUom = (uom) => {
  if (!uomTypes[uom]) return undefined;

  return uomTypes[uom].fractionalSize;
};

/**
 * Determines whether a given value is supported by the system based on its UOM and number of decimal places
 *
 * @param value
 * @param uom
 * @returns {boolean}
 */
export const validValueForUom = function(value, uom) {
  const fractionDigitsCount = Math.pow(10, getFractional(value));

  return fractionDigitsCount <= uomTypes[uom].fractionalSize;
};

// Assumes there is only one type present which is a big presumption
export const getUomTypeFromCollection = (children, field = 'uom') => {
  if(!Array.isArray(children) || children.length === 0) return Object.assign({}, uomTypes[UOMS.MG], {code: UOMS.MG});
  const child = children[0];
  const uom = get(child, field, UOMS.MG);
  const uomType = uomTypes[uom];
  return uomType.type;
};


const getLargestUomByUomType = (uomType) => {
  return Object.keys(uomTypes).map((key) => {
    return Object.assign({}, uomTypes[key], {code: key});
  }).filter((uom) => uom.type === uomType).sort((a,b) => {
    return a.ratio < b.ratio
      ? 1
      : a.ratio === b.ratio ? 0 : -1;
  }).shift();
};

export const getSmallestUomInCollection = (children, field = 'uom') => {
  if(!children.length){
    return getLargestUomByUomType('discrete');
  }
  const uomType = getUomTypeFromCollection(children, field);
  const largestUom = getLargestUomByUomType(uomType);
  return children.reduce((acc, child) => {
    const uom = get(child, field, largestUom.code);
    const childUom = Object.assign({}, uomTypes[uom], {code: uom});
    return childUom.ratio < acc.ratio
      ? childUom
      : acc;
  }, largestUom);
};

export const getUomType = (uomString, throwError = true) => {
  const uomType = uomTypes[uomString];
  if (!uomType) {
    if(throwError) {
      throw new Error(`Unrecognizable UOM: ${uomString}`);
    }
    return null;
  }
  return uomType;
};

export const uomIsWeight = (uomString, throwError = true) => {
  return get(getUomType(uomString, throwError), 'type') === WEIGHT;
};

export const uomIsVolume = (uomString, throwError = true) => {
  return get(getUomType(uomString, throwError), 'type') === VOLUME;
};

export const uomIsDiscrete = (uomString, throwError = true) => {
  return get(getUomType(uomString, throwError), 'type') === DISCRETE;
};
