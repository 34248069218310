export const UNMEDICATED_CATEGORY_CODES = ['UNMEDICATED'];

export const NON_MEDICATED_CATEGORY_ID = 6;
export const PLANTS_CATEGORY_ID = 7;
export const CBD_FLOWER_CATEGORY_ID = 11;
export const WHOLESALE_PLANTS_CATEGORY_ID = 10;
export const HARVEST_TEST_SAMPLE_CATEGORY_ID = 8;
export const WASTE_SUBCATEGORY_ID = 74;

export default UNMEDICATED_CATEGORY_CODES;
