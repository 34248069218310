/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { bindActionCreators } from 'redux';
import {goBack, push} from 'react-router-redux';
import get from 'lodash.get';
import {
  getCultivationLocationsInitialValues,
  getLocationNamesInUse,
} from '../../selectors/locationsSelectors';
import {getLocationsPayload, setDefaultIntegratedLocationType} from '../../selectors/salesLocationsSelectors';
import {isLeafIntegrator, isWaLeafIntegrator} from '../../selectors/integration/leafSelectors';
import {getUnpaginatedData, postData, postItem, putData, deleteData} from '../../actions/apiActions';
import {setData, unsetData} from '../../actions/dataActions';
import {addMessage} from '../../actions/systemActions';
import * as dataNames from '../../constants/dataNames';
import FormWrapper from '../common/form/FormWrapper';
import ModifyLocationFormWrapper from './SetupLocationsFormWrapper';
import PrinterModal from '../printer/PrinterModal';
import {
  isMetrcIntegrator,
  hasMetrcLocationTypes,
} from '../../selectors/integration/metrcSelectors'
import {isApisicpaIntegrator} from '../../selectors/integration/apisicpaSelectors';
import * as messageTypes from '../../constants/messageTypes';

const building_types_default = [
  {id: 1, name: 'Indoor', code: 'INDOOR'},
  {id: 2, name: 'Outdoor', code: 'OUTDOOR'},
  {id: 3, name: 'Greenhouse', code: 'GREENHOUSE'}
];
const  building_types_apisicpa = [
  {id: 1, name: 'Indoor', code: 0},
  {id: 2, name: 'Outdoor', code: 1},
  {id: 3, name: 'Mixed', code: 2}
];

export class SetupCultivationLocationsPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.state = {current:{}, showPrinter: false, labelUrl: false, loading: false};
    this.onSubmit = this.onSubmit.bind(this);
    this.reload = this.reload.bind(this);
    this.printLabel = this.printLabel.bind(this);
    this.hidePrinter = this.hidePrinter.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.populateSectionNames = this.populateSectionNames.bind(this);
  }

  componentWillMount() {
    this.setState({loading: true});
    this.props.actions.getUnpaginatedData('/api/cultivation/sections/integrated_location', dataNames.integratedLocationNames);
    this.props.actions.getUnpaginatedData('/api/integration-mapping', dataNames.integrationMapping, null, {key: 'cult_section'});
    this.props.actions.getUnpaginatedData('/api/locations/sections', dataNames.locationSections);
    if (this.props.hasMetrcLocationTypes) {
      this.props.actions.getUnpaginatedData('/api/metrc/locations/types', dataNames.metrcLocationTypes);
    }
    this.reload();
  }

  componentWillUnmount() {
    this.props.actions.unsetData(dataNames.sections);
    this.props.actions.unsetData(dataNames.buildings);
  }

  printLabel(event, id, type){

    if(event){
      event.stopPropagation();
      event.preventDefault();
      event.target.blur();
    }

    const tagMap = {
      room: 'location_room_tag',
      section: 'location_section_tag',
      zone: 'location_zone_tag'
    };

    const tag = tagMap[type];

    const url = `/api/labels/generate/${tag}/for/${id}`;

    this.setState({showPrinter: true, labelUrl: url});

  }

  hidePrinter(){
    this.setState({showPrinter: false});
  }

  onDelete(location, func){

    const getChildLocationIds = (location, childIds = [], returnObjects = false) => {
      childIds.push(returnObjects ? location : location.id);
      if(location.child_locations.length){
        location.child_locations.forEach((location) => {
          return getChildLocationIds(location, childIds, returnObjects);
        });
      }
      return childIds;
    };

    const childObjects = getChildLocationIds(location, [], true);

    const getLocationName = (location, names = []) => {
      names.push(location.name);
      if(location.parent_location_id !== null){
        const nextLocation = childObjects.find((object) => object.id === location.parent_location_id);
        if(nextLocation) return getLocationName(nextLocation, names);
      }
      return names;
    };

    const hasPlants = childObjects.reduce((acc, location) => {
      if(location && parseInt(location.active_plants_count) > 0) {
        const names = getLocationName(location, []);
        const name = names.reverse().join(':');
        const message = I18n.t('locations.setupStorageLocations.cultErrorOnDelete', {location: name});
        this.props.actions.addMessage(messageTypes.error, message, true);
        acc = true;
      }
      return acc;
    }, false);

    return hasPlants ? false : func();

  }

  reload() {
    this.props.actions.getUnpaginatedData('/api/cultivation/buildings', dataNames.buildings, undefined, {active: 1, detailed: 1}, this.populateSectionNames);
  }


  /**
   * Set dataNames.sections populated from received locations hierarchy (to validate uniqueness of section names)
   */
  populateSectionNames(data) {
    const cultivationSectionNames = [];
    (data || []).forEach(building => {
      (building.child_locations || []).forEach(room => {
        (room.child_locations || []).forEach(zone => {
          (zone.child_locations || []).forEach(section => {
            cultivationSectionNames.push({id: section.id, name: section.name});
          });
        });
      });
    });
    this.props.actions.setData(cultivationSectionNames, dataNames.sections);
    this.setState({loading: false});
  }

  /**
   * Action for a creating new location
   * @param payload
   * @private
   * @returns {*}
   */
  _createLocation(payload) {
    return this.props.actions.postData('/api/cultivation/buildings/multiple', payload, dataNames.storageLocations);
  }

  /**
   * Action for an updating location
   * @param payload
   * @private
   * @returns {*}
   */
  _modifyLocation(payload) {
    return this.props.actions.putData('/api/cultivation/buildings/multiple', payload, dataNames.storageLocations);
  }

  _saveSettings(payload) {
    return this.props.actions.postItem('/api/settings/plants', payload, dataNames.storageLocations);
  }

  onSubmit(formData) {
    const { addMessage, push } = this.props.actions;
    const mapChildLocation = source => building => ({
      ...building
    });

    const settingsPayload = {settings: []};

    if (get(formData, 'facility_square_footage') !== get(this.props.initialValues, 'facility_square_footage')) {
      settingsPayload.settings.push( {
        setting_key: 'cult_facility_square_footage',
        scope: 'facility',
        value:  {facility_square_footage: get(formData, 'facility_square_footage')}
      });
    }

    if (get(formData, 'facility_total_rent') !== get(this.props.initialValues, 'facility_total_rent')) {
      settingsPayload.settings.push({
        setting_key: 'cult_facility_total_rent',
        scope: 'facility',
        value: {facility_total_rent: get(formData, 'facility_total_rent')}
      });
    }

    // Set "integrated_location_name" to "veg" by default
    if (this.props.showIntegratedLocationName) {
      formData = setDefaultIntegratedLocationType(formData);
    }

    const payload = getLocationsPayload(
      this.props.initialValues.child_locations.map(mapChildLocation(this.props.initialValues)),
      formData.child_locations.map(mapChildLocation(formData))
    );

    const onSuccess = () => {
      addMessage(messageTypes.success, 'locations.setupStorageLocations.success');
      push('/setup');
    };

    const onFail = () => (
      addMessage(messageTypes.error, 'locations.setupStorageLocations.failed')
    );

    let createPromise = Promise.resolve();
    let modifyPromise = Promise.resolve();
    let settingsPromise = Promise.resolve();

    if(get(payload,'create.length',0) > 0){
      createPromise = this._createLocation(payload.create);
    }
    if(get(payload,'modify.length',0) > 0){
      modifyPromise = this._modifyLocation(payload.modify);
    }
    if(get(settingsPayload,'settings.length',0) > 0){
      settingsPromise = this._saveSettings(settingsPayload);
    }

    return Promise.resolve()
      .then( () => createPromise )
      .then( () => modifyPromise )
      .then( () => settingsPromise )
      .then( onSuccess )
      .catch( onFail );
  }

  render() {
    const {initialValues, integratedLocationNames, showIntegratedLocationName, integrationMapping, isLeaf, locationsNamesInUse, isMetrc, isWaLeaf, buildingTypes, hasMetrcLocationTypes} = this.props;

    if (this.state.loading) {
      return (<FormWrapper title={'locations.setup.title'} goBack={this.props.actions.goBack}>
        {I18n.t('general.loading')}
      </FormWrapper>);
    }

    return (
      <FormWrapper title={'locations.setup.title'} goBack={this.props.actions.goBack}>
        <PrinterModal
          hidePrinter={this.hidePrinter}
          showPrinter={this.state.showPrinter}
          httpAction='get'
          labelUrl={this.state.labelUrl}
        />
        <ModifyLocationFormWrapper
          initialValues={initialValues}
          integratedLocationNames={integratedLocationNames}
          showIntegratedLocationName={showIntegratedLocationName}
          printLabel={this.printLabel}
          onSubmit={this.onSubmit}
          buildingTypes={buildingTypes}
          integrationMapping={integrationMapping}
          isLeaf={isLeaf}
          locationsNamesInUse={locationsNamesInUse}
          isMetrc={isMetrc}
          onDelete={this.onDelete}
          isWaLeaf={isWaLeaf}
          isCultivation={true}
          hasMetrcLocationTypes={hasMetrcLocationTypes}
        />
      </FormWrapper>);
  }
}

SetupCultivationLocationsPage.propTypes = {
  actions: PropTypes.shape({
    addMessage: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    putData: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    deleteData: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }).isRequired,
  initialValues: PropTypes.object,
  buildingTypes: PropTypes.array.isRequired,
  integratedLocationNames: PropTypes.array.isRequired,
  showIntegratedLocationName: PropTypes.bool.isRequired,
  integrationMapping: PropTypes.array,
  isLeaf: PropTypes.bool,
  isMetrc: PropTypes.bool,
  locationsNamesInUse: PropTypes.array.isRequired,
  isWaLeaf: PropTypes.bool,
  hasMetrcLocationTypes: PropTypes.bool,
};

function mapStateToProps(state) {
  const {integratedLocationNames, integrationMapping} = state;
  const initialValues = getCultivationLocationsInitialValues(state);
  const locationsNamesInUse = getLocationNamesInUse(state);

  return {
    initialValues,
    buildingTypes: isApisicpaIntegrator(state)
      ? building_types_apisicpa
      : building_types_default,
    integratedLocationNames,
    showIntegratedLocationName: isLeafIntegrator(state),
    isLeaf: isLeafIntegrator(state),
    isMetrc: isMetrcIntegrator(state),
    integrationMapping,
    locationsNamesInUse,
    hasMetrcLocationTypes: hasMetrcLocationTypes(state),
    isWaLeaf: isWaLeafIntegrator(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {goBack, push, getUnpaginatedData, postData, putData, postItem, deleteData, addMessage, setData, unsetData};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) ( SetupCultivationLocationsPage);
