// NOTE: This can be removed when more functions are added to this file, it barks if there's only 1 function
/* eslint-disable import/prefer-default-export */

/**
 * Deems an item master non-transferable if sending or receiving facility is Retail and the subcategory is include in the blacklisted types
 *
 * @param isActiveFacilityRetail
 * @param isPartnerFacilityRetail
 * @param subcategoryCode
 * @returns {*|boolean}
 */
export const isNonTransferableItemMasterForLeafPa = (isActiveFacilityRetail, isPartnerFacilityRetail, subcategoryCode) =>
  (isActiveFacilityRetail || isPartnerFacilityRetail) &&
      ['SEED', 'CLONE', 'PLANT_TISSUE', 'TISSUE_CULTURE'].includes(subcategoryCode);


// Anybody can transfer to a lab
export const isLabTransfer = (partnerFacilityType) => {
  return partnerFacilityType === 'lab';
};

// A manufacturing facility can return to a grow or manufacturing facility
// A dispensary can return to a manufacturing facility or a dispensary when the transfer_type is internal
export const isValidReturn = (currentFacilityType, partnerFacilityType, transferType) => {
  return (
    (currentFacilityType === 'manufacturing' && (
      partnerFacilityType === 'grow' ||
      partnerFacilityType === 'manufacturing'
    ))
    ||
    (currentFacilityType === 'dispensary' && (
      partnerFacilityType === 'manufacturing' ||
      (partnerFacilityType === 'dispensary' && transferType === 'internal')))
  );
};

// A grow facility can transfer to another grow or a manufacturing facility
// A manufacturing facility can transfer to another manufacturing facility or a dispensary
// A dispensary can transfer to a dispensary when the transfer_type is internal
export const isValidTransfer = (currentFacilityType, partnerFacilityType, transferType) => {
  return (
    (currentFacilityType === 'grow' && (
      partnerFacilityType === 'grow' ||
      partnerFacilityType === 'manufacturing'))
    ||
    (currentFacilityType === 'manufacturing' && (
      partnerFacilityType === 'manufacturing' ||
      partnerFacilityType === 'dispensary'))
    ||
    (currentFacilityType === 'dispensary' && (
      (partnerFacilityType === 'dispensary' && transferType === 'internal')))
  );
};

