import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';

export class SetDefaultLabel extends React.PureComponent{

  constructor (props, context){

    super(props, context);

    this.onSetDefaultLabel = this.onSetDefaultLabel.bind(this);
    this.onViewAllLabels = this.onViewAllLabels.bind(this);

    this.state = {
      currentDefaultTag: '',
    };

  }

  componentWillMount(){
    if(Object.keys(this.props.facility).length > 0){
      this.putDefaultTagInState();
    }
  }

  componentWillUpdate(){
    if(Object.keys(this.props.facility).length > 0){
      this.putDefaultTagInState();
    }
  }

  putDefaultTagInState(){
    const defaultLabels = this.getDefaultLabelsForMachine();
    if(defaultLabels[this.props.primaryTag] === undefined) return false;
    if(this.state.currentDefaultTag !== defaultLabels[this.props.primaryTag]) {
      this.setState({currentDefaultTag: defaultLabels[this.props.primaryTag]});
    }
  }

  onSetDefaultLabel(remove = false){
    this.setDefaultLabelForMachine(remove);
  }

  getDefaultLabelsForMachine(getComplete = false){
    const defaultLabels = localStorage.getItem('defaultLabels');
    const tags = (defaultLabels !== null) ? JSON.parse(defaultLabels) : {};
    if(getComplete) return tags;
    const facility = `facility_${this.props.facility.id}`;
    if(tags[facility] !== undefined) return tags[facility];
    return {};
  }

  saveDefaultLabelsForMachine(defaultLabels){
    const getComplete = true;
    const completeDefaultLabels = this.getDefaultLabelsForMachine(getComplete);
    const facility = `facility_${this.props.facility.id}`;
    completeDefaultLabels[facility] = defaultLabels;
    localStorage.setItem('defaultLabels', JSON.stringify(completeDefaultLabels));
  }

  setDefaultLabelForMachine(remove){
    const defaultLabels = this.getDefaultLabelsForMachine();
    if(!remove) {
      this.setState({currentDefaultTag: this.props.currentTag});
      defaultLabels[this.props.primaryTag] = this.props.currentTag;
    } else {
      this.setState({currentDefaultTag: ''});
      delete(defaultLabels[this.props.primaryTag]);
    }
    this.saveDefaultLabelsForMachine(defaultLabels);
  }

  onViewAllLabels(){
    this.props.getSelectedLabel(this.props.primaryTag);
  }

  render() {

    return (
      <div className={this.props.isMultiple || this.props.primaryTag === this.props.currentTag || this.props.currentTag === '' ? 'hide' : ''}>
        <div>
          <a
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              event.target.blur();
              this.onViewAllLabels();
            }}
            className='float-right' style={{marginLeft: '5px'}}
          >
            {I18n.t('defaultLabel.viewAllLabels')}
          </a>
          <span className='float-right' style={{marginLeft: '5px'}}> | </span>
          {
            this.props.currentTag === this.state.currentDefaultTag
              ? (<a
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                event.target.blur();
                const remove = true;
                this.onSetDefaultLabel(remove);
              }}
              className='float-right text-warning'
            >
              {I18n.t('defaultLabel.removeDefault')}
            </a>)
              : (<a
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                event.target.blur();
                this.onSetDefaultLabel();
              }}
              className='float-right'
            >
              {I18n.t('defaultLabel.setDefault')}
            </a>)
          }

        </div>
      </div>
    );

  }

}

SetDefaultLabel.getDefaultLabel = (tag, facility) => {

  const defaultLabels = localStorage.getItem('defaultLabels');
  const tags = (defaultLabels !== null) ? JSON.parse(defaultLabels) : {};
  const fac = `facility_${facility.id}`;
  if(tags[fac] !== undefined){
    return (tags[fac][tag] !== undefined) ? tags[fac][tag] : false;
  }
  return false;

};

SetDefaultLabel.propTypes = {
  primaryTag: PropTypes.string.isRequired,
  currentTag: PropTypes.string.isRequired,
  getSelectedLabel: PropTypes.func.isRequired,
  isMultiple: PropTypes.bool.isRequired,
  facility: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(SetDefaultLabel);
