import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import {bindActionCreators} from 'redux';
import get from 'lodash.get';
import {Alert, Button, ButtonToolbar, Col, Row, FormGroup} from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import {Field, FieldArray, change, formValueSelector} from 'redux-form';
import {connect} from 'react-redux';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import TextInput from '../../common/form/TextInput';
import InternationalDateTimePickerInput from '../../common/form/InternationalDateTimePickerInput';
import ReferencesFieldArray from './ReferencesFieldArray'; //eslint-disable-line
import WaterActivity from '../panels/WaterActivity';
import CannabinoidPotency from '../panels/CannabinoidPotency';
import FileInput from '../../common/form/FileInput';
import HeavyMetals from '../panels/HeavyMetals';
import Microbials from '../panels/Microbials';
import Mycotoxins from '../panels/Mycotoxins';
import Pesticides from '../panels/Pesticides';
import ResidualSolvents from '../panels/ResidualSolvents';
import TerpeneProfile from '../panels/TerpeneProfile';
import ForeignMaterials from '../panels/ForeignMaterials';
import ThickeningAgents from '../panels/ThickeningAgents';
import CustomFields from '../panels/CustomFields';
import WillRender from '../../common/concealers/WillRender';
import ReduxTagField from '../../common/form/redux-form/ReduxTagField';
import { FILE_TYPES } from '../../../constants/fileUploads';
import * as itemNames from '../../../constants/itemNames';
import { getCompletionStatusOptions } from './completionStatuses';

import { isLeafIntegrator } from '../../../selectors/integrationSelectors';
import {
  isLeafPaConfigPackClosedLoopFacility,
  isLeafUtahConfigPackClosedLoopFacility
} from '../../../selectors/facilitiesSelectors';
import { isTestTypeEditingDisable } from '../../../selectors/testResultsSelectors';
import {addMessage} from '../../../actions/systemActions';
import TestResultsTemplate from './TestResultsTemplate';

export class TestResultsFormClass extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      medicalConditions: []
    };
    this.addMedicalCondition = this.addMedicalCondition.bind(this);
  }

  addMedicalCondition(next, prev) {
    const conditions = this.state.medicalConditions;
    if (next.length < prev.length) {
      const item = prev.filter((item) => !next.includes(item));
      if (!conditions.includes(item[0])) conditions.push(item[0]);
      this.setState({
        medicalConditions: conditions
      });
    }
  }

  render() {
    const {
      mode,
      reset,
      valid,
      save,
      saveAsInProgress,
      saveAsComplete,
      params,
      loading,
      pristine,
      partners,
      partnerFacilities,
      isWaLeaf,
      isPlants,
      addMessage,
      submitting,
      clearSearch,
      handleSubmit,
      initialValues,
      packageOptions,
      hasActivePackage,
      integrationState,
      isEditingDisabled,
      isLabApplyProfiles,
      isolocityTestResult,
      testResultTestTypes,
      isIsolocityStatusPassed,
      fetchIsolocityTestResult,
      saveIsolocityTestResult,
      handleFirstPackageChange,
      change,
      onDocChange,
      inheritFromItemMaster,
      changeUploadStatus,
      documentUrl,
      labResultDimensions,
      requiredDimensions,
      facilityHasLabModule,
      isFacilityStateOregon,
      mounted,
      complianceSettings,
      canRenderDimensionGroup,
      isUtahQaChangeRequestEnabled,
      isLeafUtahConfigPackClosedLoopFacility,
      isLeafPaConfigPackEnabled,
      isPaLabEnhancementEnabled,
      inheritedDimensionCategories,
      disallowTestResultCreation,
      canRetestPackages,
      isPickableDate,
      form: formName,
      isLeaf,
      isTestTypeEditingDisable,
      categories,
      currentValues,
      product,
      perServingModeAvailable,
    } = this.props;

    const retestEligible = get(initialValues, 'retest_eligible');
    const extractionEligible = get(initialValues, 'extraction_eligible');
    const isIntegrationSource = get(initialValues, 'state_integration_source');
    const initialStatus = get(initialValues, 'isolocity_status');
    const initialReferences = get(initialValues, 'references');
    const isolocityStatus = get(
      isolocityTestResult,
      'inspection.status',
      initialStatus ? initialStatus : 'In Progress'
    );
    const showTestingId = mode === 'modify' || !facilityHasLabModule;
    const completionStatusOptions = getCompletionStatusOptions();
    const saveIsDisabled = pristine || submitting || isEditingDisabled || !hasActivePackage || (integrationState.isIsolocity && get(isolocityTestResult, 'id', 0) > 0);

    const getFilteredTestResultTestTypes = () => {
      // Filter out test_type remediation if not all packages are of the category 'CONCENTRATE'
      const concentrateCategory = categories.find((category) => category.category_code === 'CONCENTRATE');
      if (get(currentValues, 'references', []).some((ref) => ref.category_id !== get(concentrateCategory, 'id'))) {
        return testResultTestTypes.filter(testResultTestType => testResultTestType.value !== 'remediation');
      }
      return testResultTestTypes;
    };

    const isInheritedCategory = (category) => {
      return inheritedDimensionCategories.indexOf(category) !== -1;
    };

    const isDisabledCategory = (category) => {
      if(isEditingDisabled || isIsolocityStatusPassed){
        return true;
      }
      return isInheritedCategory(category);
    };

    // Apply different behavior based on status being saved
    const handleSaveForStatus = (event, is_complete) => {
      if (event) {
        event.preventDefault();
      }
      const saveHandler = (is_complete) ? saveAsComplete : saveAsInProgress;

      //Trigger a re-validation based on the status in which we are saving
      this.props.change('saveAsInProgress', !is_complete);

      setTimeout(() => {handleSubmit(saveHandler)(event); }, 10);
    };

    // Only show template download/upload buttons in edit mode
    //const showTemplateDownloadUpload = false; // Set to false until all functionality is complete
    const showTemplateDownloadUpload = !isEditingDisabled && isLeafUtahConfigPackClosedLoopFacility;

    const isPerServingMode = get(currentValues, 'test_results_value_type', 'percentage') === 'serving';
    const servings_per_container = get(currentValues, 'servings_per_container');
    const servings_uom = get(currentValues, 'servings_uom', 'mg');

    return (
      <form className='test-results-form'>
        {isEditingDisabled && (
          <Row>
            <Col xs={12}>
              <Alert variant='info'>
                <FaInfoCircle />
                <span className='padding-left'>{I18n.t('cultivation.testResults.form.leafLabResultAlert')}</span>
              </Alert>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={6} sm={6} md={3}>
            {!facilityHasLabModule ? (
              <Field
                name='lab_partner_id'
                component={ReactSelectInput}
                props={{
                  label: I18n.t('cultivation.testResults.form.labPartner'),
                  options: partners,
                  valueKey: 'id',
                  textKey: 'name',
                  disabled: isEditingDisabled || isIsolocityStatusPassed
                }}
              />
            ) : (
              <Field
                name='for_partner_facility_id'
                component={ReactSelectInput}
                props={{
                  label: I18n.t('cultivation.testResults.form.partnerFacility'),
                  options: partnerFacilities,
                  valueKey: 'id',
                  textKey: 'facility_name',
                  disabled: !get(complianceSettings, 'testing_lab_designates_partner_facility_on_test_result')
                }}
              />
            )}
          </Col>
          {showTestingId && (
            <Col xs={6} sm={6} md={3}>
              <Field
                name='testing_id'
                component={TextInput}
                props={{
                  label: I18n.t('cultivation.testResults.form.testingId'),
                  disabled: isEditingDisabled || isIsolocityStatusPassed || facilityHasLabModule,
                  isRequired: integrationState.isIsolocity
                }}
              />
            </Col>
          )}
          <Col xs={6} sm={6} md={3}>
            <InternationalDateTimePickerInput
              name='testing_date'
              props={{
                label: I18n.t('cultivation.testResults.table.testingDate'),
                isRequired: true,
                inputProps: {
                  disabled: isEditingDisabled || isIsolocityStatusPassed
                }
              }}
            />
          </Col>
          <Col xs={6} sm={6} md={3} className='file-upload'>
            {/* Only labs are allowed to upload documents in Leaf */}
            <WillRender ifTrue={facilityHasLabModule || !isLeaf}>
              <Field
                name='certificate_doc'
                itemName={itemNames.document}
                groupClassName={'float-none'}
                component={FileInput}
                props={{
                  ...FILE_TYPES['COMMON_LAB_CERTIFICATE_OF_ANALYSIS'],
                  label: I18n.t('cultivation.testResults.table.certificateUpload.certTitle'),
                  btnContent: I18n.t('cultivation.testResults.table.certificateUpload.action'),
                  onChangeCb: (f) => {
                    onDocChange(f);
                    change('coa_file_id', f.id);
                    changeUploadStatus(false);
                  },
                  onUploadStart: () => {
                    changeUploadStatus(true);
                  },
                  btnProps: {
                    className: 'btn btn-primary'
                  }
                }}
              />
              <p>{I18n.t('cultivation.testResults.table.certificateUpload.limits')}</p>
              <p>{I18n.t('cultivation.testResults.table.certificateUpload.ext')}</p>

              <FormGroup>
                <p>
                  {!documentUrl ? null : (
                    <a target='_new' href={documentUrl}>
                      {I18n.t('cultivation.testResults.table.certificateUpload.viewCOA')}
                    </a>
                  )}
                </p>
              </FormGroup>
            </WillRender>

            <TestResultsTemplate
              labResultDimensions={labResultDimensions}
              testResult={currentValues}
              change={change}
              addMessage={addMessage}
              show={showTemplateDownloadUpload}
            />
          </Col>

          {facilityHasLabModule && (
            <Col xs={6} sm={6} md={3}>
              <Field
                name='completion_status'
                component={ReactSelectInput}
                props={{
                  label: I18n.t('cultivation.testResults.form.completionStatus'),
                  isRequired: false,
                  options: completionStatusOptions,
                  disabled: true
                }}
              />
            </Col>
          )}
          {facilityHasLabModule && isPaLabEnhancementEnabled && (
            <Col xs={6} sm={6} md={3}>
              <Field
                name='test_type'
                component={ReactSelectInput}
                props={{
                  label: I18n.t('cultivation.testResults.form.testTypes'),
                  isRequired: true,
                  options: getFilteredTestResultTestTypes(),
                  disabled: isTestTypeEditingDisable,
                }}
              />
            </Col>
          )}
          {facilityHasLabModule && isPaLabEnhancementEnabled && (
            <Col xs={6} sm={6} md={3}>
              <InternationalDateTimePickerInput
                name='stability_test_date'
                props={{
                  label: I18n.t('cultivation.testResults.table.StabilityTestingDate'),
                  isRequired: facilityHasLabModule && isPaLabEnhancementEnabled,
                  isValidDate: isPickableDate,
                  enableTodayButton: false,
                  closeOnSelect: true,
                }}
              />
            </Col>
          )}
        </Row>
        {integrationState.isIsolocity && (
          <Row>
            <Col xs={6} sm={6} md={3}>
              <Field
                name='analyst_name'
                component={TextInput}
                props={{
                  label: I18n.t('cultivation.testResults.isolocity.analystName'),
                  isRequired: true,
                  disabled: isIsolocityStatusPassed
                }}
              />
            </Col>
            <Col xs={6} sm={6} md={3}>
              <ReduxTagField
                name='medical_conditions'
                onChange={(event, next, prev) => {
                  this.addMedicalCondition(next, prev);
                }}
                props={{
                  label: I18n.t('cultivation.testResults.isolocity.medicalConditions'),
                  placeholder: I18n.t('cultivation.testResults.isolocity.medicalPlaceholder'),
                  disabled: isIsolocityStatusPassed,
                  options: this.state.medicalConditions
                }}
              />
            </Col>
            {params.id > 0 && (
              <Col sm={6} md={3}>
                <Row>
                  <Col md={6}>
                    <div className='readonly-input-group'>
                      <label>{I18n.t('cultivation.testingCompliance.isolocityStatus')}</label>
                      <div className='block'>
                        <i className='text-muted'>{isolocityStatus}</i>
                      </div>
                    </div>
                  </Col>
                  <Col md={6} className='text-right'>
                    <Button
                      variant='primary'
                      onClick={fetchIsolocityTestResult}
                      className='label-offset-top'
                      disabled={isIsolocityStatusPassed}
                    >
                      {I18n.t('cultivation.testingCompliance.getIsolocityStatus')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        )}

        {isWaLeaf && mode === 'modify' && isIntegrationSource && (
          <Row>
            <Col xs={6} sm={6} md={3}>
              <h5>{I18n.t('cultivation.testResults.table.eligibleForExtraction')}</h5>
              <span className={extractionEligible ? 'text-success' : 'text-danger'}>
                {extractionEligible ? 'Yes' : 'No'}
              </span>
            </Col>
            <Col xs={6} sm={6} md={3}>
              <h5>{I18n.t('cultivation.testResults.table.eligibleForRetest')}</h5>
              <span className={retestEligible ? 'text-success' : 'text-danger'}>{retestEligible ? 'Yes' : 'No'}</span>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={12}>
            <h3 id='test-results-target-object'>
            {
              isPlants
                ? I18n.t('cultivation.testResults.form.plantGroups')
                : I18n.t('cultivation.testResults.form.packages')
            }
            </h3>
            <FieldArray
              name='references'
              component={ReferencesFieldArray}
              currentValues={currentValues}
              loading={loading}
              isEditingDisabled={isEditingDisabled || isIsolocityStatusPassed}
              packageOptions={packageOptions}
              clearSearch={clearSearch}
              addMessage={addMessage}
              mode={mode}
              product={product}
              handleFirstPackageChange={handleFirstPackageChange}
              inheritFromItemMaster={inheritFromItemMaster}
              isPlants={isPlants}
              disallowTestResultCreation={disallowTestResultCreation}
              complianceSettings={complianceSettings}
              canRetestPackages={canRetestPackages}
              perServingModeAvailable={perServingModeAvailable}
              isPerServingMode={isPerServingMode}
              change={change}
              labResultDimensions={labResultDimensions}
            />
          </Col>
        </Row>

        {mounted ? (
        <div class='lab-results-categories'>
        <WillRender ifTrue={isUtahQaChangeRequestEnabled || isLeafPaConfigPackEnabled ? canRenderDimensionGroup('water_activity', initialReferences) : !isLabApplyProfiles}>
          <Row>
            <Col xs={12}>
              <h3>{I18n.t('cultivation.testingCompliance.waterActivity.title')}</h3>
              <WaterActivity
                isIsolocity={integrationState.isIsolocity}
                isolocityTestResult={isolocityTestResult}
                dimensions={labResultDimensions}
                requiredDimensions={requiredDimensions}
                editable={true}
                disabled={isDisabledCategory('water_activity')}
                isLabApplyProfiles={isLabApplyProfiles}
              />
            </Col>
          </Row>
        </WillRender>
        <WillRender ifTrue={canRenderDimensionGroup('cannabinoid_potency', initialReferences)}>
          <Row>
            <Col xs={12}>
              <h3>{I18n.t('cultivation.testResults.table.cannabinoidPotency')}</h3>
              <CannabinoidPotency
                isIsolocity={integrationState.isIsolocity}
                isolocityTestResult={isolocityTestResult}
                dimensions={labResultDimensions}
                requiredDimensions={requiredDimensions}
                editable={true}
                disabled={isDisabledCategory('cannabinoid_potency')}
                isFacilityStateOregon={isFacilityStateOregon}
                change={change}
                isPerServingMode={isPerServingMode}
                servingsPerContainer={servings_per_container}
                servingsUom={servings_uom}
              />
            </Col>
          </Row>
        </WillRender>
        <WillRender ifTrue={canRenderDimensionGroup('terpene_profile', initialReferences)}>
          <Row>
            <Col xs={12}>
              <h3>{I18n.t('cultivation.testingCompliance.terpeneProfile.title')}</h3>
              <TerpeneProfile
                isIsolocity={integrationState.isIsolocity}
                isolocityTestResult={isolocityTestResult}
                requiredDimensions={requiredDimensions}
                editable={true}
                disabled={isDisabledCategory('terpene_profile')}
                formName={formName}
                change={change}
                isPerServingMode={isPerServingMode}
                servingsPerContainer={servings_per_container}
                servingsUom={servings_uom}
              />
            </Col>
          </Row>
        </WillRender>
        <WillRender ifTrue={canRenderDimensionGroup('microbial', initialReferences)}>
          <Row>
            <Col xs={12}>
              <h3>{I18n.t('cultivation.testingCompliance.microbials.title')}</h3>
              <Microbials
                isIsolocity={integrationState.isIsolocity}
                isolocityTestResult={isolocityTestResult}
                dimensions={labResultDimensions}
                requiredDimensions={requiredDimensions}
                editable={true}
                isLabApplyProfiles={isLabApplyProfiles}
                disabled={isDisabledCategory('microbial')}
              />
            </Col>
          </Row>
        </WillRender>
        <WillRender ifTrue={canRenderDimensionGroup('residual_solvents', initialReferences)}>
          <Row>
            <Col xs={12}>
              <h3>{I18n.t('cultivation.testingCompliance.residualSolvents.title')}</h3>
              <ResidualSolvents
                isIsolocity={integrationState.isIsolocity}
                isolocityTestResult={isolocityTestResult}
                dimensions={labResultDimensions}
                requiredDimensions={requiredDimensions}
                editable={true}
                isLabApplyProfiles={isLabApplyProfiles}
                disabled={isDisabledCategory('residual_solvents')}
              />
            </Col>
          </Row>
        </WillRender>
        <WillRender ifTrue={canRenderDimensionGroup('heavy_metals', initialReferences)}>
          <Row>
            <Col xs={12}>
              <h3>{I18n.t('cultivation.testingCompliance.heavyMetals.title')}</h3>
              <HeavyMetals
                isIsolocity={integrationState.isIsolocity}
                isolocityTestResult={isolocityTestResult}
                dimensions={labResultDimensions}
                requiredDimensions={requiredDimensions}
                editable={true}
                isLabApplyProfiles={isLabApplyProfiles}
                disabled={isDisabledCategory('heavy_metals')}
              />
            </Col>
          </Row>
        </WillRender>
        <WillRender ifTrue={canRenderDimensionGroup('mycotoxin', initialReferences)}>
          <Row>
            <Col xs={12}>
              <h3>{I18n.t('cultivation.testingCompliance.mycotoxins.title')}</h3>
              <Mycotoxins
                isIsolocity={integrationState.isIsolocity}
                isolocityTestResult={isolocityTestResult}
                dimensions={labResultDimensions}
                requiredDimensions={requiredDimensions}
                editable={true}
                isLabApplyProfiles={isLabApplyProfiles}
                disabled={isDisabledCategory('mycotoxin')}
              />
            </Col>
          </Row>
        </WillRender>
        <WillRender ifTrue={canRenderDimensionGroup('pesticides', initialReferences)}>
          <Row>
            <Col xs={12}>
              <h3>{I18n.t('cultivation.testingCompliance.pesticides.title')}</h3>
              <Pesticides
                isIsolocity={integrationState.isIsolocity}
                isolocityTestResult={isolocityTestResult}
                dimensions={labResultDimensions}
                requiredDimensions={requiredDimensions}
                editable={true}
                isLabApplyProfiles={isLabApplyProfiles}
                disabled={isDisabledCategory('pesticides')}
              />
            </Col>
          </Row>
        </WillRender>
        <WillRender ifTrue={isUtahQaChangeRequestEnabled || isLeafPaConfigPackEnabled ? canRenderDimensionGroup('foreign_materials', initialReferences) : !isLabApplyProfiles}>
          <Row>
            <Col xs={12}>
              <h3>{I18n.t('cultivation.testingCompliance.foreignMaterials.title')}</h3>
              <ForeignMaterials
                isIsolocity={integrationState.isIsolocity}
                isolocityTestResult={isolocityTestResult}
                dimensions={labResultDimensions}
                requiredDimensions={requiredDimensions}
                editable={true}
                disabled={isDisabledCategory('foreign_materials')}
                isLabApplyProfiles={isLabApplyProfiles}
              />
            </Col>
          </Row>
        </WillRender>
        <WillRender ifTrue={labResultDimensions.hasOwnProperty('thickening_agents')}>
          <Row>
            <Col xs={12}>
              <h3>{I18n.t('cultivation.testingCompliance.thickeningAgents.title')}</h3>
              <ThickeningAgents
                isIsolocity={integrationState.isIsolocity}
                isolocityTestResult={isolocityTestResult}
                dimensions={labResultDimensions}
                requiredDimensions={requiredDimensions}
                editable={true}
                disabled={isDisabledCategory('thickening_agents')}
              />
            </Col>
          </Row>
        </WillRender>
        <WillRender ifTrue={labResultDimensions.hasOwnProperty('custom_fields')}>
          <Row>
            <Col xs={12}>
              <h3>{I18n.t('cultivation.testingCompliance.customFields.title')}</h3>
              <CustomFields
                dimensions={labResultDimensions}
                editable={true}
              />
            </Col>
          </Row>
        </WillRender>
        </div> ) : null}
        <Row className='submit-section'>
          <Col xs={12}>
            <div className='button-wrapper'>
              <WillRender ifTrue={!hasActivePackage}>
                <div style={{ textAlign: 'right', padding: '8px 0' }} className='text-info'>
                  {I18n.t('cultivation.testingCompliance.noActivePackages')}
                </div>
              </WillRender>
              <ButtonToolbar className='float-right'>
                <Button onClick={reset} disabled={pristine || isEditingDisabled || !hasActivePackage}>
                  {I18n.t('common.form.reset')}
                </Button>
                {integrationState.isIsolocity && (
                  <Button
                    variant='primary'
                    onClick={handleSubmit(saveIsolocityTestResult)}
                    disabled={!valid || pristine || submitting || isIsolocityStatusPassed}
                  >
                    {I18n.t('cultivation.testingCompliance.saveAndSendToIsolocity')}
                  </Button>
                )}
                {facilityHasLabModule ? (
                  <span>
                    <Button
                      variant='primary'
                      type='button'
                      onClick={(e) => saveAsInProgress()}
                      disabled={saveIsDisabled}
                    >
                      {I18n.t('common.form.saveAsInProgress')}
                    </Button>

                    <Button
                    variant='primary'
                    type='submit'
                    onClick={(e) => handleSaveForStatus(e, true)}
                    disabled={saveIsDisabled}
                    >
                  {I18n.t('common.form.saveAndComplete')}
                    </Button>
                  </span>

                ) : (

                <Button
                  variant='primary'
                  type='submit'
                  onClick={handleSubmit(save)}
                  disabled={saveIsDisabled}
                >
                  {I18n.t('common.form.save')}
                </Button>
                )}
              </ButtonToolbar>
            </div>
          </Col>
        </Row>
      </form>
    );
  }
}


TestResultsFormClass.defaultProps = {
  mounted: true,
};

TestResultsFormClass.propTypes = {
  handleSubmit: PropTypes.func,
  save: PropTypes.func,
  isolocityTestResult: PropTypes.object,
  isIsolocityStatusPassed: PropTypes.bool,
  saveIsolocityTestResult: PropTypes.func,
  onDocChange: PropTypes.func.isRequired,
  inheritFromItemMaster: PropTypes.func,
  changeUploadStatus: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  fetchIsolocityTestResult: PropTypes.func,
  reset: PropTypes.func,
  integrationState: PropTypes.object,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  partners: PropTypes.array.isRequired,
  partnerFacilities: PropTypes.array.isRequired,
  packageOptions: PropTypes.array.isRequired,
  clearSearch: PropTypes.func.isRequired,
  handleFirstPackageChange: PropTypes.func.isRequired,
  addMessage: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  isEditingDisabled: PropTypes.bool,
  mode: PropTypes.string,
  hasActivePackage: PropTypes.bool.isRequired,
  isWaLeaf: PropTypes.bool,
  labResultDimensions: PropTypes.object,
  params: PropTypes.object,
  documentUrl: PropTypes.string,
  facilityHasLabModule: PropTypes.bool,
  initialValues: PropTypes.object,
  requiredDimensions: PropTypes.array,
  values: PropTypes.object,
  isLabApplyProfiles: PropTypes.bool,
  isPlants: PropTypes.bool.isRequired,
  isFacilityStateOregon: PropTypes.bool,
  mounted: PropTypes.bool,
  complianceSettings: PropTypes.object.isRequired,
  canRenderDimensionGroup: PropTypes.func.isRequired,
  disallowTestResultCreation: PropTypes.func.isRequired,
  isUtahQaChangeRequestEnabled: PropTypes.bool.isRequired,
  isLeafPaConfigPackEnabled: PropTypes.bool.isRequired,
  isPaLabEnhancementEnabled: PropTypes.bool.isRequired,
  inheritedDimensionCategories: PropTypes.array,
  canRetestPackages: PropTypes.func.isRequired,
  isPickableDate: PropTypes.func.isRequired,
  ifLeaf: PropTypes.bool,
  isTestTypeEditingDisable: PropTypes.bool,
  perServingModeAvailable: PropTypes.bool
};



// TODO: consolidate props passed from View/Add TestResultsPage that are only used in this component and move them here.
function mapStateToProps(state, ownProps) {
  const selector = formValueSelector(ownProps.form);
  return {
    isLeaf: isLeafIntegrator(state) || isLeafPaConfigPackClosedLoopFacility(state),
    isTestTypeEditingDisable: isTestTypeEditingDisable(state),
    isLeafUtahConfigPackClosedLoopFacility: isLeafUtahConfigPackClosedLoopFacility(state),
    getFormValue: name => selector(state, name),
    product: state[itemNames.product],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({change, addMessage}, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TestResultsFormClass);
