import * as types from '../constants/actionTypes';
import initialState from '../constants/initialState';

export default function spinnerReducer (state = initialState.spinner, action){
  if(action.type === types.STOP_SPINNER ||
     action.type.endsWith('_SUCCESS') ||
     action.type.endsWith('_FAILED')){
    return (state - 1 < 0) ? 0 : state - 1; // is there a use case for less than 0?
    //return state - 1;
  }else if (action.type === types.START_SPINNER){
    return state + 1;
  }else{
    return state;
  }
}
