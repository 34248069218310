import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import get from 'lodash.get';
import ReduxUomSelect from './redux-form/ReduxUomSelect';
import NumericInput from './NumericInput';
import {getDecimalPrecisionForUom} from '../../../util/uomHelpers';
import {getUomInputPrecision} from '../../../selectors/uomsSelectors';
import {UOM_VALUE_PRECISION} from '../../../constants/uoms';

const QuantityByUomInput = (props) => {
  const allProps = Object.assign({}, props, get(props, 'props', {}));
  const {name, label, disabled, uom, readOnly, selectable, whiteBackground, selectableName, isRequired, onItemChange, defaultUom, hideUom, precision } = allProps;
  let {fractionPartSize} = allProps;
  const {...restProps} = props;
  const abbrev = I18n.t(`uoms.${uom}.abbrev`);
  const addOn = selectable && whiteBackground && !disabled ? 'dropdownButton' : 'rightAddon';

  if (isNaN(fractionPartSize)) {
    fractionPartSize = precision !== undefined
      ? precision
      : uom
        ? getDecimalPrecisionForUom(uom)
        : UOM_VALUE_PRECISION;
  }

  const inputProps = {
    name,
    label,
    readOnly,
    isRequired,
    ...restProps,
    fractionPartSize,
    [addOn]: selectable
      ? <Field
        disabled={allProps.disabled}
        defaultUom={defaultUom}
        name={selectableName}
        options={allProps.options}
        component={ReduxUomSelect}
        onItemChange={onItemChange}
      />
      : hideUom
        ? null
        : <span>{abbrev}</span>
  };

  return (
    <Field
      name={name}
      component={NumericInput}
      disabled={disabled}
      props={inputProps}
    />
  );
};

QuantityByUomInput.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string,
  uom: PropTypes.string,
  selectable: PropTypes.bool,
  selectableName: PropTypes.string,
  noBackground: PropTypes.bool,
  onItemChange: PropTypes.func,
  defaultUom: PropTypes.string,
  precision: PropTypes.number,
  shouldRound: PropTypes.bool
};

QuantityByUomInput.defaultProps = {
  disabled: false,
  selectable: false,
  selectableName: 'uom_display',
  whiteBackground: false,
  readOnly: false,
  shouldRound: true
};

function mapStateToProps(state, ownProps){
  return {
    precision: getUomInputPrecision(state, ownProps),
  };
}

export default connect(mapStateToProps, null)(QuantityByUomInput);
