import get from 'lodash.get';
import { I18n } from 'react-redux-i18n';
import * as dataNames from '../../constants/dataNames';

export const getCupoNumbers = (state) => state[dataNames.cupo_numbers];

/**
 * Returns cupo number options based on strain_id
 * @param state
 * @returns {Array}
 */
export const getCupoNumberOptions = (state) => {
  const cupoNumbers = (getCupoNumbers(state) || []).filter((cupo) => get(cupo, 'cupo.is_active'));

  const makeUnique = (acc, row) => {
    const { cupo } = row;
    if (!cupo) {
      return {};
    }

    acc[cupo.id] = {
      text: cupo.cupo_number,
      value: cupo.id
    };

    return acc;
  };

  return Object.values(cupoNumbers.reduce(makeUnique, {}));
};

/**
 *  Return colombia options based on strain_id
 * @param strain_id
 * @param cupo_id
 * @param state
 * @returns {Array}
 */
export const getModalityOptions = (cupo_id, strain_id, state) => {
  const modalities = getModalitiesByCupoNumber(cupo_id, state);
  const byStrain = (modality) => modality.strain_id === strain_id;
  const toOptions = (modality) => ({
    text: I18n.t(`modalities.list.${get(modality, 'modality')}`),
    value: get(modality, 'modality')
  });

  return modalities.filter(byStrain).map(toOptions);
};

/**
 * Return a list of modalities by cupo id
 * @param cupo_id
 * @param state
 * @returns {{id: number, cupo_number: number, facility_id: number, modalities: *[]}|{modalities: Array}}
 */
const getModalitiesByCupoNumber = (cupo_id, state) => {
  return (
    getCupoNumbers(state).filter((cupo) => {
      return cupo.cupo_id === cupo_id;
    }) || []
  );
};
