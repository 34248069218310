import React from 'react';
import PropTypes from 'prop-types';
import {ListGroup, ListGroupItem} from 'react-bootstrap';

const DetailsPanel = ({ data }) => (
  <ListGroup>
    {data.map(item => <ListGroupItem key={item.key}>{item.value}</ListGroupItem>)}
  </ListGroup>
);

DetailsPanel.propTypes = {
  data: PropTypes.array
};

export default DetailsPanel;
