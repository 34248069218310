import React from 'react';
import PropTypes from 'prop-types';
import {FieldArray, Field} from 'redux-form';
import {Alert, Col, Row} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import {FaExclamationTriangle} from 'react-icons/fa';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import MultiselectInput from '../../../common/form/MultiselectInput';
import InternationalDateTimePickerInput from '../../../common/form/InternationalDateTimePickerInput';
import SubmitSection from '../../../common/form/SubmitSection';
import InputsFieldArray from '../common/ProcessingInputsFieldArray';

/******************
 * THIS FORM USES A MIDDLEWARE
 */

const getProcessingTypeOptions = (processingTypes, inputs) => {
  return processingTypes.filter(processingType => inputs.every((productInput) => {
    return processingType.starting_phases.some(phase => phase.phase_id === productInput.phaseId) &&
      processingType.inputs.some(input => input.inventory_subcategory_id === productInput.subcategoryId);
  }));

};

const StartProcessingForm = (props) => {
  const {
    invalid,
    pristine,
    submitting,
    handleSubmit,
    reset,
    change,
    itemOptions,
    employees,
    equipments,
    processingTypes,
    inputs,
    ready,
    addMessage,
    loadTestResults,
    clearSearch,
    initialValues,
    allowNegativeInventory,
    isPaRemediationLabelsEnabled
  } = props;
  const processingTypeOptions = getProcessingTypeOptions(processingTypes, inputs || []);
  const shouldRenderError = ready && !processingTypeOptions.length;
  const settings = {
    actionSettings: {
      reset: {
        text: I18n.t('common.form.reset'),
        action: reset,
        style: 'default',
        pristine,
      },
      submit: {
        text: I18n.t('common.form.save'),
        submitting,
        invalid,
        pristine,
        disabled: !inputs || inputs.length < 1
      }
    }
  };
  return (
    <form className='processing-form' onSubmit={handleSubmit}>
      <FieldArray
        name='inputs'
        component={InputsFieldArray}
        addMessage={addMessage}
        loadTestResults={loadTestResults}
        clearSearch={clearSearch}
        change={change}
        itemOptions={itemOptions}
        inputs={inputs || []}
        initialValues={initialValues}
        allowNegativeInventory={allowNegativeInventory}
        isPaRemediationLabelsEnabled={isPaRemediationLabelsEnabled}
      />

      <Row className='padding-top'>
        <Col xs={6} sm={4} md={3}>
          {shouldRenderError ?
            <Alert variant='danger'>
              <FaExclamationTriangle/>
              <span className='padding-left'>{I18n.t('ei.processing.form.noProcessingTypeAvailable')}</span>
            </Alert> : null
          }
          <Field
            name='processing_type_id'
            component={ReactSelectInput}
            props={{
              label: I18n.t('ei.processing.form.processingType'),
              options: processingTypeOptions,
              isRequired: true,
              textKey: 'name',
              valueKey: 'id',
              groupClassName: shouldRenderError ? 'hidden' : '',
            }}
          />
        </Col>
        <Col xs={6} sm={4} md={3}>
          <Field
            name='equipments'
            component={MultiselectInput}
            props={{
              label: I18n.t('ei.processing.form.assignEquipment'),
              options: equipments,
              textKey: 'model_name',
              valueKey: 'id',
              isRequired: true,
            }}
          />
        </Col>
        <Col xs={6} sm={4} md={3}>
          <Field
            name='employees'
            component={MultiselectInput}
            props={{
              label: I18n.t('ei.processing.form.assignEmployees'),
              options: employees,
              textKey: 'displayName',
              valueKey: 'id',
              isRequired: true,
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6} sm={4} md={3}>
          <InternationalDateTimePickerInput
            name='start_time'
            props={{
              label: I18n.t('ei.processing.form.processingStartTime'),
              timeFormat: true,
              isRequired: true,
              enableTodayButton: false,
            }}
          />
        </Col>
        <Col xs={6} sm={4} md={3}>
          <InternationalDateTimePickerInput
            name={`expected_completion_time`}
            props={{
              label: I18n.t('ei.processing.form.expectedCompletionTime'),
              timeFormat: true,
              isRequired: true,
              enableTodayButton: false,
            }}
          />
        </Col>
      </Row>
      <SubmitSection settings={settings}/>
    </form>
  );
};

StartProcessingForm.propTypes = {
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  addMessage: PropTypes.func.isRequired,
  loadTestResults: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  change: PropTypes.func,
  itemOptions: PropTypes.array.isRequired,
  employees: PropTypes.array.isRequired,
  equipments: PropTypes.array.isRequired,
  processingTypes: PropTypes.array.isRequired,
  inputs: PropTypes.array,
  ready: PropTypes.bool,
  initialValues: PropTypes.object,
  allowNegativeInventory: PropTypes.bool,
  isPaRemediationLabelsEnabled: PropTypes.bool
};

export default StartProcessingForm;
