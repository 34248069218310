import React from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Row} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';

import AccordionPanel from '../../common/AccordionPanel';
import PricePreviewWrapper from './PricePreviewWrapper';
import ContentConcealer from '../../common/concealers/ContentConcealer';

const SalesSectionPreview = (props) => {
  const {sectionData, handleEdit} = props;
  const online_availability_org = get(sectionData.fields.find(field => field.name === 'online_availability.organization'), 'value');
  const online_availability_facility = get(sectionData.fields.find(field => field.name === 'online_availability.facilities'), 'value', [])
    .filter(item => item.availability || item.facilities.length);
  const medical_retail_price_org = get(sectionData.fields.find(field => field.name === 'prices.organization.retail.medical'), 'value', {});
  const rec_retail_price_org = get(sectionData.fields.find(field => field.name === 'prices.organization.retail.recreational'), 'value', {});
  const wholesale_price_org = get(sectionData.fields.find(field => field.name === 'prices.organization.wholesale'), 'value', {});
  const medical_retail_price_facility = get(sectionData.fields.find(field => field.name === 'prices.facilities.retail.medical'), 'value', [{}]);
  const rec_retail_price_facility = get(sectionData.fields.find(field => field.name === 'prices.facilities.retail.recreational'), 'value', [{}]);
  const wholesale_price_facility = get(sectionData.fields.find(field => field.name === 'prices.facilities.wholesale'), 'value', [{}]);

  const showSection = medical_retail_price_org.show_section || rec_retail_price_org.show_section || wholesale_price_org.show_section
    || medical_retail_price_facility.filter(sec => sec.show_section).length || rec_retail_price_facility.filter(sec => sec.show_section).length
    || wholesale_price_facility.filter(sec => sec.show_section).length || online_availability_org || online_availability_facility.length;

  return (showSection ?
    <AccordionPanel title={sectionData.title}>
      <Row>
        <Col md={11}>
          <Row>
            <Col md={6} className='field-data price-section'>
              <Row>
                <Col md={12} className='price-field'>
                  <h4>{I18n.t('products.form.onlineAvailability')} {I18n.t('products.form.organization')}</h4>
                </Col>
              </Row>
              {online_availability_org ?
                <Row>
                  <Col md={12} className='price-field'>
                    <Row>
                      <Col md={12}>
                        <label>{I18n.t('products.form.onlineAvailability')}</label>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        {online_availability_org}
                      </Col>
                    </Row>
                  </Col>
                </Row> : null}
            </Col>

            <Col md={6} className='field-data price-section'>
              <Row>
                <Col md={12}>
                  <h4>{I18n.t('products.form.onlineAvailability')} {I18n.t('products.form.byFacility')}</h4>
                </Col>
              </Row>
              {online_availability_facility.map(value => (
                value['availability'] || value['facilities'] ? (
                  <Row>
                    <Col md={6} className='price-field'>
                      <ContentConcealer show={value['availability']}>
                        <Row>
                          <Col md={12}>
                            <label>{I18n.t('products.form.onlineAvailability')}</label>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            {value['availability']}
                          </Col>
                        </Row>
                      </ContentConcealer>
                    </Col>
                    <Col md={6} className='price-field'>
                      <ContentConcealer show={value['facilities']}>
                        <Row>
                          <Col md={12}>
                            <label>{I18n.t('taxes.form.applicableFacilities')}</label>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            {value['facilities']}
                          </Col>
                        </Row>
                      </ContentConcealer>
                    </Col>
                  </Row>
                ) : null
              ))}
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <PricePreviewWrapper
                data={medical_retail_price_org}
                title={'products.form.medicalPrices'}
                levelTitle={'products.form.organization'}
              />
            </Col>

            <Col md={6}>
              <PricePreviewWrapper
                data={medical_retail_price_facility}
                title={'products.form.medicalPrices'}
                levelTitle={'products.form.byFacility'}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <PricePreviewWrapper
                data={rec_retail_price_org}
                title={'products.form.recreationalPrices'}
                levelTitle={'products.form.organization'}
              />
            </Col>
            <Col md={6}>
              <PricePreviewWrapper
                data={rec_retail_price_facility}
                title={'products.form.recreationalPrices'}
                levelTitle={'products.form.byFacility'}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <PricePreviewWrapper
                data={wholesale_price_org}
                title={'products.form.wholesalePrices'}
                levelTitle={'products.form.organization'}
              />
            </Col>
            <Col md={6}>
              <PricePreviewWrapper
                data={wholesale_price_facility}
                title={'products.form.wholesalePrices'}
                levelTitle={'products.form.byFacility'}
              />
            </Col>
          </Row>
        </Col>
        <Col md={1}>
          <Button type='button' onClick={() => handleEdit(sectionData)} variant='default'>
            {I18n.t('products.massModify.edit')}
          </Button>
        </Col>
      </Row>
    </AccordionPanel> : null);
};

SalesSectionPreview.propTypes = {
  sectionData: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired
};

export default SalesSectionPreview;
