import { SET_TIMEZONE } from '../constants/actionTypes';
import initialState from '../constants/initialState';

export default function timezoneReducer (state = initialState.timezone, action){
  const {timezone} = action;

  switch (action.type) {
  case SET_TIMEZONE:
    return timezone;
  default:
    return state;
  }
}
