import {I18n} from 'react-redux-i18n';
import {requiredFieldValidation} from '../common/form/redux-form/validations';

export const validate = (values) => {
  const errors = {};
  if (values.itemMasterType === 'prepack' && !(values.uom && parseInt(values.uom))) {
    errors.uom = I18n.t('common.form.requiredError');
  }
  errors.form_id = values.isCure ? requiredFieldValidation(values.form_id) : null;
  return errors;
};

export default validate;
