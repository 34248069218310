import {createSelector} from 'reselect';
import get from 'lodash.get';
import * as itemNames from '../constants/itemNames';
import * as dataNames from '../constants/dataNames';
import {mapIsMedicatedToItemMasters, mapIsMedicatedToItemMaster} from './itemMastersSelectors';
import {
  getMetrcFilteredCategories,
} from './categorySelectors';
import productTypeOptions from '../constants/productTypeOptions';
import {getIntegrationState} from './integration/integrationSelectors';
import { getInternationalNumberFormat } from './InternationalOperationsSelectors';
import {convertFromBase} from '../util/uomHelpers';
import { GR } from '../constants/uoms';
import {fieldFormatHelperDecimals} from '../util/internationalHelpers';
import {isFeatureEnabled} from './featureToggles';
import {isLeafPaConfigPackClosedLoopFacility} from './facilitiesSelectors';
import {PRODUCT_MASTER_FORM} from '../constants/forms';

export const getProduct = (state) => {
  const itemMaster = state[itemNames.product];
  return mapIsMedicatedToItemMaster(itemMaster);
};

const getPricingWeights = (state, props) => {
  if (get(props, 'form') === PRODUCT_MASTER_FORM) {
    return state[dataNames.productMasterPricingWeights];
  }
  return state[dataNames.pricingWeights];
};

export const getProducts = (state) => {
  const itemMasters = state[dataNames.products];
  return mapIsMedicatedToItemMasters(itemMasters);
};

export const getPricingWeightsForDisplaying = createSelector(
  [getPricingWeights, getInternationalNumberFormat], (pricingWeights = [], numberFormat) => {
    pricingWeights.sort((obj1, obj2) => {
      return (obj1.fulfillment_units - obj2.fulfillment_units);
    });

    return pricingWeights.map((weight) => {
      const uom = weight.uom || GR;
      const weight_amount = convertFromBase(weight.weight_base, uom);
      const weight_formatted = fieldFormatHelperDecimals(weight_amount , 'weight', numberFormat);
      return {
        ...weight,
        name: `${weight_formatted} ${uom}`
      };
    });
  }
);

const showMarijuanaCategory = (category, isCanadaFacility) => isCanadaFacility || category.category_code !== 'MARIJUANA';

const showHarvestCategory = (category, hide) => !hide || category.category_code !== 'HARVESTS';

const showCbdFlowerCategory = (category, isLeafPaConfigPackClosedLoopFacility, isFtPAHempTransfer) =>
  category.category_code !== 'CBD_FLOWER' || (category.category_code === 'CBD_FLOWER' && isLeafPaConfigPackClosedLoopFacility && isFtPAHempTransfer);

export const getProductTypeOptions = createSelector(
  [getMetrcFilteredCategories, getIntegrationState, isFeatureEnabled, isLeafPaConfigPackClosedLoopFacility],
  (categories, {isPaLeaf, isCanada, isCure, isWaLeaf}, isFeatureEnabled, isLeafPaConfigPackClosedLoopFacility) =>
    productTypeOptions
      .filter(option => categories.find(category =>
        (category.category_code === option.category_code
          && category.subcategories.find(subcategory => subcategory.active)
          && showHarvestCategory(category, isPaLeaf || isCure || isWaLeaf)
          && showMarijuanaCategory(category, isCanada)
          && showCbdFlowerCategory(category, isLeafPaConfigPackClosedLoopFacility, isFeatureEnabled('feature_pa_hemp_transfer'))
        )))
      .map(option => {
        const relatedCategory = categories.find(category => category.category_code === option.category_code);
        return {
          ...option,
          label: relatedCategory && ['PLANTS', 'HARVESTS'].indexOf(relatedCategory.category_code) === -1 ? relatedCategory.name : option.label,
          id: relatedCategory.id,
        };
      })
);
