import without from 'lodash.without';
import * as types from '../constants/actionTypes';
import initialState from '../constants/initialState';

export default function batchesReducer (state = initialState.selectedBatches, action){
  const {batches} = action;
  switch (action.type) {
  case types.ADD_SELECTED_BATCHES:
    return [...state, ...batches];
  case types.REMOVE_SELECTED_BATCHES:
    return without(state, ...batches);
  default:
    return state;
  }
}
