import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, FormSection, FieldArray} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {
  Button,
  ButtonToolbar,
  Col,
  Form,
  Row,
  Card
} from 'react-bootstrap';
import LevelOneLocations from './LevelOneLocations';
import NumericInput from '../../common/form/NumericInput';
import InternationalDecimalReadOnlyInput from '../../common/form/InternationalDecimalReadOnlyInput';
import {locationsOrSectionsExceedFormMaximum} from '../../../selectors/salesLocationsSelectors';

const locationNames = [
  {storage: 'room', sale: 'locations.form.salesLocation'},
  {storage: 'zone', sale: 'locations.form.area'},
  {storage: 'section', sale: 'locations.form.subSection'},
];

const createLocation = (type, level, index, defaults) => {
  const location = {
    name: I18n.t(locationNames[level][type], {id: index + 1}),
    active: 1,
    is_sales_location: 1,
    is_shared: false,
    total_square_footage: 0,
    child_locations: []
  };
  if (level < 3) {
    for (let i = 0; i < defaults[level + 1]; i++) {
      location.child_locations.push(createLocation(type, level + 1, i, defaults));
    }
  }
  return location;
};

class SalesLocationsForm extends React.PureComponent {

  // touch all invalid fields to prompt error displays
  componentDidUpdate (prevProps) {
    if (this.props.valid || !this.props.formErrors) { return; }
    this.props.touch(...this.findPropPath(this.props.formErrors, 'name'));
  }

  findPropPath (obj, name) {
    const arr = [];
    for (const prop in obj) {
      if (prop === name) {
        arr.push(name);
      }
      if (typeof obj[prop] === 'object') {
        const result = this.findPropPath(obj[prop], name);
        if (result) {
          result.forEach((item) => {
            arr.push(prop + '.' + item);
          });

        }
      }
    }
    return arr;
  }

  render() {
    const {
      handleSubmit, submitting, pristine, invalid, array: {push}, locations, defaults,
      onIsSharedChange, totalSquareFootage, integratedLocationNames, showIntegratedLocationName,
      isLeaf, integrationMapping, printLabel, facilitySharingStatus, onDelete, locationsOrSectionsExceedFormMaximum
    } = this.props;

    return (
      <form className='create-location-form' onSubmit={handleSubmit}>
        <Row className='form-fields-row'>
          <Col xs={12} sm={9} md={7} lg={5}>
            <FormSection name='defaults'>
              <Card body>
                <h4>{I18n.t('locations.form.quickSetup')}</h4>
                <div className='quick-setup'>
                  <div className='layout-row layout-align-end-center'>
                    <Form.Label>{I18n.t('locations.form.numSales')}</Form.Label>
                    <Field name='0' component={NumericInput} props={{
                      value: locations.length,
                      readOnly: true,
                    }}/>
                  </div>
                  <div className='layout-row layout-align-end-center'>
                    <Form.Label>{I18n.t('locations.form.defaultNumAreas')}</Form.Label>
                    <Field name='1' component={NumericInput} props={{min: 1}}/>
                  </div>
                  <div className='layout-row layout-align-end-center'>
                    <Form.Label>{I18n.t('locations.form.defaultNumSubSections')}</Form.Label>
                    <Field name='2' component={NumericInput} props={{min: 1}}/>
                  </div>
                </div>
              </Card>
            </FormSection>
          </Col>
          <Col className='form-fields-col' xs={8} md={4} lg={3}>
            <InternationalDecimalReadOnlyInput
              name='retail_square_footage'
              props={{
                label: I18n.t('locations.form.retailSqFootage'),
                value: totalSquareFootage,
                readOnly: true,
              }}/>
          </Col>
        </Row>

        <FieldArray name='child_locations' component={LevelOneLocations} props={{
          createLocation,
          push,
          locations,
          defaults,
          integratedLocationNames,
          showIntegratedLocationName,
          isLeaf,
          integrationMapping,
          printLabel,
          onIsSharedChange,
          facilitySharingStatus,
          onDelete,
          expanded: !locationsOrSectionsExceedFormMaximum,
          renderBodyWhenCollapsed: false
        }}/>

        <Row className='padding-top'>
          <Col xs={12}>
            <ButtonToolbar className='float-right'>
              <Button variant='primary'
                      type='submit'
                      disabled={submitting || pristine || invalid}>
                {I18n.t('locations.form.saveAllChanges')}
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>
      </form>
    );
  }
}

SalesLocationsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  array: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  defaults: PropTypes.array.isRequired,
  totalSquareFootage: PropTypes.string,
  integratedLocationNames: PropTypes.array.isRequired,
  showIntegratedLocationName: PropTypes.bool.isRequired,
  isLeaf: PropTypes.bool,
  integrationMapping: PropTypes.array,
  printLabel: PropTypes.func.isRequired,
  onIsSharedChange: PropTypes.func.isRequired,
  facilitySharingStatus: PropTypes.object,
  onDelete: PropTypes.func.isRequired,
  locationsOrSectionsExceedFormMaximum: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  locationsOrSectionsExceedFormMaximum: locationsOrSectionsExceedFormMaximum(state)
});

export default connect(mapStateToProps)(SalesLocationsForm);
