import React from 'react';
import PropTypes from 'prop-types';
import ContentConcealer from '../../../common/concealers/ContentConcealer';
import InternationalCurrencyStatic from '../../../common/form/InternationalCurrencyStatic';
import InternationalDecimalStatic from '../../../common/form/InternationalDecimalStatic';

const RequestedPrePackRow = ({itemMaster, prepackWeight, subItem, mult, editableLinePrice, isLabPartner}) => {
  return (<tr>
    <td className='weightName'><label>{prepackWeight ? prepackWeight.name : ''}</label></td>
    <td className='qty'>{subItem ? subItem.qty : 0}</td>
    <td className='total'>
      <InternationalDecimalStatic fractionPartSize={2}>{subItem ? (subItem.qty * mult ) : 0}</InternationalDecimalStatic>
    </td>
    <ContentConcealer show={!isLabPartner && !editableLinePrice}>
      <td className='cost'>
        <InternationalCurrencyStatic>
          {subItem ? parseFloat(subItem.unit_price) : parseFloat(itemMaster.default_cost)}
        </InternationalCurrencyStatic>
      </td>
      <td className='lineTotal'>
        <InternationalCurrencyStatic>
          {subItem ? (parseFloat(subItem.unit_price) * (subItem.qty)) : '0.00'}
        </InternationalCurrencyStatic>
      </td>
    </ContentConcealer>
  </tr>);
};

RequestedPrePackRow.propTypes = {
  editableLinePrice: PropTypes.bool,
  itemMaster: PropTypes.object.isRequired,
  prepackWeight: PropTypes.object.isRequired,
  subItem: PropTypes.object.isRequired,
  mult: PropTypes.number.isRequired,
  isLabPartner: PropTypes.bool,
};

export default RequestedPrePackRow;
