import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import isEmpty from 'lodash.isempty';
import get from 'lodash.get';
import {LinkContainer} from 'react-router-bootstrap';
import {Link} from 'react-router';
import {Badge, Col, Image, Nav, Navbar, NavDropdown, Row} from 'react-bootstrap';
import camelCase from 'lodash.camelcase';
import * as itemNames from '../../constants/itemNames';
import {getImageUrl, getImgByGender} from '../../util/images';
import * as apiActions from '../../actions/apiActions';
import * as itemActions from '../../actions/itemActions';
import * as menuActions from '../../actions/menuActions';
import {getIntegrationState} from '../../selectors/integration/integrationSelectors';
import {getOrderProductCount} from '../../selectors/orderSelectors';
import {getFacilitySalesSettingsForCustomer, normalizeOrderTypeCounts} from '../../selectors/customersSelectors';
import OrderCategoryDisplay from '../orders/order/category-display/OrderCategoryDisplay';
import CureApiPurchasableAmount from '../common/cure-api-purchasable-amount/CureApiPurchasableAmount';
import {getApplicationMode} from '../../selectors/applicationModesSelectors';
import {getDataUpdateAvailable} from '../../selectors/dataUpdateSelectors';
import * as applicationModes from '../../constants/applicationModes';
import BiotrackPurchasableAmount from '../common/integration/biotrack/purchasable-amount/BiotrackPurchasableAmount';
import OhMetrcComplianceWeight from './OhMetrcComplianceWeight';
import InternationalDecimalStatic from '../common/form/InternationalDecimalStatic';
import {getPatientsComplianceLimits} from '../../selectors/complianceSettingsSelectors';
import PurchasedAmounts from '../orders/customer/components/PurchasedAmounts';
import MmuLimit from '../customers/checkin/MmuLimit';
import {isLeafPaConfigPackClosedLoopFacility} from '../../selectors/facilitiesSelectors';
import {isFeatureEnabled} from '../../selectors/featureToggles';


class ProductsHeader extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.handleCustomerClick = this.handleCustomerClick.bind(this);
    this.getCustomerCounts = this.getCustomerCounts.bind(this);
  }

  componentWillMount() {
    Promise.all([
      this.props.actions.getItem('/api/order_settings/sales_limit', itemNames.salesComplianceSettings),
      this.getCustomerCounts(),
    ]);
  }

  componentWillUnmount(){
  }

  componentDidMount() {
    const customerNamesLabel = this.refs.customerNames;

    if (customerNamesLabel) {
      const { customer } = this.props;
      customerNamesLabel.title = (customerNamesLabel.offsetWidth < customerNamesLabel.scrollWidth || customerNamesLabel.innerText.length > 30) ?
        `${customer.first_name} ${customer.last_name}` : '';
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dataUpdateAvailable !== this.props.dataUpdateAvailable && nextProps.dataUpdateAvailable){
      setTimeout(() => this.getCustomerCounts(), 100);
    }
  }

  getCustomerCounts () {
    return this.props.actions.getItem('/api/queue/consumers_count', itemNames.orderTypeCounts, {failed: 'header.orderTypeCounts.failed'});
  }

  handleCustomerClick() {
    const {custDetailsOpen, locked} = this.props;
    if (custDetailsOpen || !locked) {
      this.props.actions.toggleCustDetails();
    }
  }

  getComplianceWeight(){

    const {complianceWeightInGrams, orderProducts} = this.props;

    const result =  complianceWeightInGrams
      ? parseFloat(complianceWeightInGrams)
      : orderProducts.reduce((acc, product) => {
        const value = parseFloat(product.compliance_weight_grams);
        if(isNaN(value)) return acc;
        acc += value;
        return acc;
      }, 0);

    const options = {minimumFractionDigits:3, maximumFractionDigits:3};
    return result.toLocaleString('en-US', options);

  }

  complianceIsActive(){
    return this.props.orderProducts.reduce((acc, product) => {
      return (acc)
        ? acc
        : product.compliance_weight_grams !== null
          ? true
          : acc;
    }, false);
  }

  render () {
    const {customer, cartCount, orderTypeCounts, showCart, showProfile, showFastTrack, orderProducts, integrationSate,
      complianceWeightInGrams, hideEquivalencyStatus, order, paymentComplete, salesComplianceSettings, displayCategories,
      patientComplianceSettings, isMmuEnabled} = this.props;

    const haveCustomer = () => !isNaN(parseInt(customer.id));
    const orderIsAnonymous = () => order.consumer_id === null;
    const allowAnonymousOrders = () => get(salesComplianceSettings, 'order_allow_anonymous.value', false);
    const {isOhMetrc, isPaLeaf} = integrationSate;

    const orderCategoryDisplay = haveCustomer() || (orderIsAnonymous() && allowAnonymousOrders())
      ? <OrderCategoryDisplay multiViews={false} consumerId={haveCustomer() ? customer.id : 0} displayCategories={displayCategories} />
      : null;

    const showCompliance = this.props.applicationMode === applicationModes.cure
      ? !isEmpty(orderProducts) && !hideEquivalencyStatus
      : !isEmpty(orderProducts) && !hideEquivalencyStatus && this.complianceIsActive();

    const displayState = {
      showFastTrack,
      showCart,
      showProfile: showProfile && !isEmpty(customer),
      showCompliance,
    };

    const {toggleCartDetails} = this.props.actions;
    return (<div className='cart-and-products-header'>
      <Row>
        <Col md={{span: 8, offset: 2}} style={{textAlign: 'center'}}>
          <div className='queue-nav'>
            <Navbar>
              {/*We just want to show the med queue if med only state like pa */}
              {isPaLeaf ?
                <Nav variant='pills' style={{width: '100%'}} className='queue-counts-nav' activeKey={1}>
                  {Object.keys(orderTypeCounts).map((orderType, index) =>
                    (<Nav.Item
                      key={index}
                      className='queue-counts-nav-item layout-column flex-50'
                      eventKey={1}>
                      <LinkContainer key={index} to={`/queue/${orderType}/medical`}>
                        <span>{I18n.t(`productMenu.${camelCase(orderType)}`)}
                          &nbsp;&nbsp;<Badge className='queue-counts-badge'>{orderTypeCounts[orderType].medical}</Badge>
                        </span>
                      </LinkContainer>
                    </Nav.Item>)
                  )}
                </Nav>
                :
                <Nav style={{width: '100%'}} className='queue-counts-nav'>
                  {Object.keys(orderTypeCounts).map((orderType, index) => (
                      <NavDropdown id={`nav-dropdown-${index}`} key={index}
                         style={{textAlign: 'center'}}
                         className='queue-counts-nav-dropdown layout-column flex-50'
                         title={
                           <span>{I18n.t(`productMenu.${camelCase(orderType)}`)}
                             <Badge className='queue-counts-badge'>{orderTypeCounts[orderType].all}</Badge>
                            </span>
                         }
                       >

                        {Object.keys(orderTypeCounts[orderType]).map((key) => (
                          <LinkContainer key={key} to={`/queue/${orderType}/${key}`}>
                            <Nav.Item className='queue-counts-nav-item layout-column'>
                              {<span>{I18n.t(`productMenu.${key}`)}
                                <Badge className='queue-counts-badge'>{orderTypeCounts[orderType][key]}</Badge></span>}
                            </Nav.Item>
                          </LinkContainer>
                        ))}

                      </NavDropdown>)
                  )}
                </Nav>
              }
            </Navbar>
          </div>
        </Col>
      </Row>
      <Row style={{justifyContent: 'space-between'}}>
        {/*FIRST COLUMN*/}
        <Col md={4} className='compliance-table' style={{paddingRight: 0}}>
          {orderCategoryDisplay}
          {(!paymentComplete && !!get(customer, 'id', false)) ?
            <PurchasedAmounts customer={customer}/>
            : null
          }
          {!isEmpty(customer) ?
            <CureApiPurchasableAmount
              customer={customer}
              orderId={order && order.id}
              showHeader={false}
              showOrderData={true}
              className='retail-flow'
            />
            : null
          }
          {(!isEmpty(customer)) && (integrationSate.isBiotrack) && (
              <BiotrackPurchasableAmount
                customer={customer}
                showHeader={false}
                showOrderData={true}
                className='retail-flow'
              />
            )}
        </Col>
        {/*SECOND COLUMN*/}
        <Col md={4} mdPush={4} style={{textAlign: 'right'}}>
          <Row>
            <Col md={3} sm={4} style={{paddingLeft: 0}}>
              {!displayState.showFastTrack ? null : <Link to={`/cart`} className='btn btn-primary btn-block' onlyActiveOnIndex={false}>
                {I18n.t('customer.fastTrack')}
              </Link>}
            </Col>
            <Col
              md={displayState.showCart ? 5 : 6}
              sm={displayState.showCart ? 4 : 5}
              style={{paddingRight: '14px', paddingTop: '4px'}}
              className='products-header'
            >
            {!displayState.showProfile ? null :
              <div onClick={this.handleCustomerClick}
                style={{cursor: 'pointer'}}
                >
                {patientComplianceSettings && patientComplianceSettings.goes_by_enabled && customer.goes_by ? <span>{customer.goes_by}</span> :
                  <span>{customer.first_name} {customer.last_name}</span>
                }
                <Image className='user-avatar' src={customer.image_url} />
              </div>}
            </Col>
            {!displayState.showCart
                ? <Col md={3} className='products-header' style={{paddingRight: '14px', paddingTop: '4px'}}>
                    {order.name}
                    <Badge style={{marginLeft: '8px', color:'transparent', backgroundColor: 'transparent'}}>{cartCount}</Badge>
                  </Col>
                : <Col md={4} className='products-header' style={{cursor: 'pointer', paddingRight: '14px', paddingTop: '4px'}} onClick={toggleCartDetails}>
                    {I18n.t('cart.cart', {name: order.name})}
                    <Badge className='rounded-circle' style={{marginLeft: '8px', fontSize: '12px', color: 'white', backgroundColor: '#777777'}}>{cartCount}</Badge>
                  </Col>
            }
            <Col md={{offset: 3, span: 9}} style={{textAlign: 'right'}}>
              {(displayState.showCompliance && !isOhMetrc) &&
              (<div
                style={Object.assign({}, {
                  color: parseFloat(complianceWeightInGrams) > 0 ? 'red' : 'green',
                  paddingRight: '4px',
                  position: 'relative'
                })}>
                {
                  this.complianceIsActive()
                    ?
                    <span>{I18n.t('retail.salesSettings.flowerEquivalenceStatement')}:&nbsp;<InternationalDecimalStatic>{this.getComplianceWeight()}</InternationalDecimalStatic></span>
                    : <span>Compliance Weight Awaiting Inventory Specification</span>
                }
              </div>)
              }
              {!isEmpty(customer) && isMmuEnabled &&
                <div style={{position: 'relative', float: 'right'}}>
                  <MmuLimit
                    customerId={customer.id}
                    customerLimits={get(this.state, 'customerLimits', {})}
                  />
                </div>
              }
              {isOhMetrc &&
                <OhMetrcComplianceWeight consumerId={haveCustomer() ? customer.id : 0}/>
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </div>);
  }
}



ProductsHeader.propTypes = {
  actions: PropTypes.object.isRequired,
  orderTypeCounts: PropTypes.object.isRequired,
  custDetailsOpen: PropTypes.bool.isRequired,
  customer: PropTypes.object,
  cartCount: PropTypes.number,
  showCart: PropTypes.bool,
  showFastTrack: PropTypes.bool,
  showProfile: PropTypes.bool,
  locked: PropTypes.bool,
  orderProducts: PropTypes.array,
  complianceWeightInGrams: PropTypes.number,
  hideEquivalencyStatus: PropTypes.bool,
  applicationMode: PropTypes.string.isRequired,
  order: PropTypes.object,
  paymentComplete: PropTypes.bool.isRequired,
  dataUpdateAvailable: PropTypes.bool.isRequired,
  salesComplianceSettings: PropTypes.object,
  integrationSate: PropTypes.object,
  displayCategories: PropTypes.array,
  isMmuEnabled: PropTypes.bool.isRequired
};
ProductsHeader.defaultProps = {
  locked: false,
  showCart: true,
  showProfile: true,
  showFastTrack: false,
  paymentComplete: false
};

function mapStateToProps(state, ownProps){
  const {customer} = state;
  if (customer && !isEmpty(customer)) {
    customer.image_url = getImageUrl(customer && customer.image_file, '100x100', getImgByGender(customer.gender));
  }
  const customSettings = getFacilitySalesSettingsForCustomer(state);
  return {
    showCart: ownProps.showCart,
    showProfile: ownProps.showProfile,
    showFastTrack: ownProps.showFastTrack,
    customer: customer,
    cartCount: getOrderProductCount(state),
    custDetailsOpen: state.menus.custDetailsOpen,
    orderTypeCounts: normalizeOrderTypeCounts(state),
    hideEquivalencyStatus: get(customSettings, 'order_sales_limit_method.value', 'disabled') !== 'equivalency',
    applicationMode: getApplicationMode(state),
    order: state[itemNames.order],
    dataUpdateAvailable: getDataUpdateAvailable(state, {name: itemNames.orderTypeCounts}),
    salesComplianceSettings: state[itemNames.salesComplianceSettings],
    integrationSate: getIntegrationState(state),
    patientComplianceSettings: getPatientsComplianceLimits(state),
    isMmuEnabled: isLeafPaConfigPackClosedLoopFacility(state) && isFeatureEnabled(state)('feature_leaf_pa_mmu_limits')
  };
}
function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, menuActions, apiActions, itemActions);
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (ProductsHeader);
