import {
  BT_BUBBLE_HASH,
  BT_CANNABIS_EXTRACT_FOR_INHALATION,
  BT_CANNABIS_INFUSED_TOPICALS,
  BT_CANNABIS_MIX,
  BT_CANNABIS_MIX_INFUSED,
  BT_CANNABIS_MIX_PACKAGED,
  BT_CAPSULE,
  BT_CO2_HASH_OIL,
  BT_FLOWER_LOT,
  BT_FOOD_GRADE_SOLVENT_EXTRACT,
  BT_HASH,
  BT_HYDROCARBON_WAX,
  BT_INFUSED_COOKING_OIL,
  BT_INFUSED_DAIRY_BUTTER,
  BT_KIEF,
  BT_LIQUID_CANNABIS_INFUSED_EDIBLE,
  BT_NON_SMOKEABLE_INFUSED_EXTRACT,
  BT_OTHER_MATERIAL_LOT,
  BT_SAMPLE_JAR,
  BT_SOLID_CANNABIS_INFUSED_EDIBLE,
  BT_SUPPOSITORY,
  BT_TINCTURE,
  BT_TRANSDERMAL_PATCH,
  BT_USABLE_CANNABIS,
  BT_USABLE_TRIM,
  BT_USABLE_SHAKE,
  BT_USABLE_KEIF,
} from './biotrackInventoryTypes';


const BT_PR_INTERMEDIATE_PRODUCTS = [
  BT_BUBBLE_HASH,
  BT_CO2_HASH_OIL,
  BT_FOOD_GRADE_SOLVENT_EXTRACT,
  BT_HASH,
  BT_HYDROCARBON_WAX,
  BT_INFUSED_COOKING_OIL,
  BT_INFUSED_DAIRY_BUTTER,
  BT_KIEF,
  BT_CANNABIS_MIX,
  BT_USABLE_TRIM,
  BT_USABLE_SHAKE,
  BT_USABLE_KEIF
];

const BT_PR_INFUSION_PRODUCTS = [
  BT_SOLID_CANNABIS_INFUSED_EDIBLE,
  BT_LIQUID_CANNABIS_INFUSED_EDIBLE,
  BT_CANNABIS_INFUSED_TOPICALS,
  BT_SUPPOSITORY,
  BT_CANNABIS_EXTRACT_FOR_INHALATION,
];

export const BT_PR_PROCESSING_RULES = {
  [BT_OTHER_MATERIAL_LOT]: [...BT_PR_INTERMEDIATE_PRODUCTS],
  [BT_FLOWER_LOT]: [...BT_PR_INTERMEDIATE_PRODUCTS],
  [BT_BUBBLE_HASH]: [...BT_PR_INTERMEDIATE_PRODUCTS],
  [BT_CO2_HASH_OIL]: [...BT_PR_INTERMEDIATE_PRODUCTS],
  [BT_FOOD_GRADE_SOLVENT_EXTRACT]: [...BT_PR_INTERMEDIATE_PRODUCTS],
  [BT_HASH]: [...BT_PR_INTERMEDIATE_PRODUCTS],
  [BT_HYDROCARBON_WAX]: [...BT_PR_INTERMEDIATE_PRODUCTS],
  [BT_INFUSED_COOKING_OIL]: [...BT_PR_INTERMEDIATE_PRODUCTS],
  [BT_INFUSED_DAIRY_BUTTER]: [...BT_PR_INTERMEDIATE_PRODUCTS],
  [BT_KIEF]: [...BT_PR_INTERMEDIATE_PRODUCTS],
  [BT_CANNABIS_MIX]: [...BT_PR_INTERMEDIATE_PRODUCTS],
};

export const BT_PR_INFUSION_RULES = {
  [BT_BUBBLE_HASH]: BT_PR_INFUSION_PRODUCTS,
  [BT_CO2_HASH_OIL]: BT_PR_INFUSION_PRODUCTS,
  [BT_FOOD_GRADE_SOLVENT_EXTRACT]: [...BT_PR_INFUSION_PRODUCTS, BT_CAPSULE, BT_TINCTURE, BT_TRANSDERMAL_PATCH],
  [BT_HASH]: BT_PR_INFUSION_PRODUCTS,
  [BT_HYDROCARBON_WAX]: BT_PR_INFUSION_PRODUCTS,
  [BT_INFUSED_COOKING_OIL]: [...BT_PR_INFUSION_PRODUCTS, BT_CAPSULE, BT_TINCTURE, BT_TRANSDERMAL_PATCH],
  [BT_INFUSED_DAIRY_BUTTER]: [...BT_PR_INFUSION_PRODUCTS, BT_CAPSULE, BT_TINCTURE],
  [BT_KIEF]: [...BT_PR_INFUSION_PRODUCTS, BT_TRANSDERMAL_PATCH, BT_CANNABIS_MIX_INFUSED],
  [BT_FLOWER_LOT]: [BT_CANNABIS_MIX_INFUSED],
  [BT_CANNABIS_MIX]: [BT_CANNABIS_MIX_INFUSED],
};

export const BT_PR_PACKAGING_RULES = {
  [BT_FLOWER_LOT]: [BT_USABLE_CANNABIS, BT_SAMPLE_JAR, BT_USABLE_TRIM, BT_USABLE_SHAKE, BT_USABLE_KEIF],
  [BT_CANNABIS_MIX]: [BT_CANNABIS_MIX_PACKAGED],
  [BT_BUBBLE_HASH]: [BT_CANNABIS_EXTRACT_FOR_INHALATION],
  [BT_CO2_HASH_OIL]: [BT_CANNABIS_EXTRACT_FOR_INHALATION],
  [BT_FOOD_GRADE_SOLVENT_EXTRACT]: [BT_CANNABIS_EXTRACT_FOR_INHALATION],
  [BT_HASH]: [BT_CANNABIS_EXTRACT_FOR_INHALATION],
  [BT_HYDROCARBON_WAX]: [BT_CANNABIS_EXTRACT_FOR_INHALATION],
  [BT_INFUSED_COOKING_OIL]: [BT_CANNABIS_EXTRACT_FOR_INHALATION],
  [BT_INFUSED_DAIRY_BUTTER]: [BT_CANNABIS_EXTRACT_FOR_INHALATION],
  [BT_KIEF]: [BT_CANNABIS_EXTRACT_FOR_INHALATION, BT_USABLE_TRIM, BT_USABLE_SHAKE, BT_USABLE_KEIF],
  [BT_OTHER_MATERIAL_LOT]: [BT_USABLE_TRIM, BT_USABLE_SHAKE, BT_USABLE_KEIF]
};

export const BT_PR_END_PRODUCTS = [
  BT_FLOWER_LOT,
  BT_BUBBLE_HASH,
  BT_HASH,
  BT_INFUSED_DAIRY_BUTTER,
  BT_SOLID_CANNABIS_INFUSED_EDIBLE,
  BT_LIQUID_CANNABIS_INFUSED_EDIBLE,
  BT_CANNABIS_EXTRACT_FOR_INHALATION,
  BT_CANNABIS_INFUSED_TOPICALS,
  BT_USABLE_CANNABIS,
  BT_CANNABIS_MIX,
  BT_CANNABIS_MIX_PACKAGED,
  BT_CANNABIS_MIX_INFUSED,
  BT_CAPSULE,
  BT_HYDROCARBON_WAX,
  BT_TINCTURE,
  BT_TRANSDERMAL_PATCH,
  BT_SUPPOSITORY,
  BT_USABLE_TRIM,
  BT_USABLE_SHAKE,
  BT_USABLE_KEIF,
];

export const BT_PR_CONVERSION_PRODUCTS = [
  BT_KIEF, BT_BUBBLE_HASH, BT_HASH, BT_HYDROCARBON_WAX, BT_CO2_HASH_OIL, BT_FOOD_GRADE_SOLVENT_EXTRACT,
  BT_INFUSED_DAIRY_BUTTER, BT_INFUSED_COOKING_OIL, BT_SOLID_CANNABIS_INFUSED_EDIBLE, BT_LIQUID_CANNABIS_INFUSED_EDIBLE,
  BT_CANNABIS_EXTRACT_FOR_INHALATION, BT_CANNABIS_INFUSED_TOPICALS, BT_SAMPLE_JAR, BT_USABLE_CANNABIS,
  BT_CANNABIS_MIX, BT_CANNABIS_MIX_PACKAGED, BT_CANNABIS_MIX_INFUSED, BT_CAPSULE, BT_TINCTURE, BT_TRANSDERMAL_PATCH,
  BT_SUPPOSITORY, BT_NON_SMOKEABLE_INFUSED_EXTRACT,
];
