import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { Col, Row, Table, Card } from 'react-bootstrap';

const PlantTaggingHistory = ({ message, model }) => {
  let fieldName = message;
  let index = message.toLowerCase().indexOf('added');
  if (index < 0) {
    index = message.toLowerCase().indexOf('deleted');
  }
  if (index < 0) {
    index = message.toLowerCase().indexOf('updated');
  }
  if (index < 0) {
    index = message.toLowerCase().indexOf('created');
  }
  if (index > 0) {
    fieldName = message.substring(0, index - 1);
  }

  return (message, model) ? (
    <Card>
      <Row>
        <Col xs={12}>
          <h3>{I18n.t('plantHistory.plant.plantDetails')}</h3>
          <Table striped>
            <thead>
              <tr>
                <th>{I18n.t('plantHistory.field')}</th>
                <th>{I18n.t('plantHistory.valueAdjustment')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{fieldName}</td>
                <td>{model.state_integration_tracking_id}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Card>
  ) : null;
};

PlantTaggingHistory.propTypes = {
  model: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired
};

export default PlantTaggingHistory;
