import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Alert, Col, Row, Card} from 'react-bootstrap';
import {FaInfoCircle} from 'react-icons/fa';
import PatientDetailsLeafPA from './PatientDetailsLeafPA';


const CaregiverDetailsLeafPA = ({caregiver, budTenders, onRegister, validateSelectedPatientData, timezone, expanded, onDetailsExpand, allowSalesAtWill}) => {
  const patients = caregiver.patients || [];
  return (
    <Row>
      <Col xs={12}>
        <Card className='clearfix' body>
          <Row>
            <Col xs={6} sm={4} md={3}>
              <label>{I18n.t('consumers.checkin.caregiverName')}</label>
              <div>{caregiver.caregiver_name}</div>
            </Col>
            <Col xs={6} sm={4} md={3}>
              <label>{I18n.t('consumers.checkin.caregiverId')}</label>
              <div>{caregiver.caregiver_id}</div>
            </Col>
            <Col xs={6} sm={4} md={3}>
              <label>{I18n.t('consumers.checkin.caregiverCardNumber')}</label>
              <div>{caregiver.caregiver_card_number}</div>
            </Col>
          </Row>
        </Card>
      </Col>
      {patients.length === 0 ?
        <Col xs={12}>
          <Alert variant='info'>
            <FaInfoCircle/>
            <span>{I18n.t('consumers.checkin.caregiverHasNoPatientsAssigned')}</span>
          </Alert>
        </Col>
        : null
      }
      {patients.map(patient => (
        <PatientDetailsLeafPA
          key={patient.patient_id}
          caregiver={caregiver}
          patient={patient}
          onRegister={onRegister}
          validateSelectedPatientData={validateSelectedPatientData}
          budTenders={budTenders}
          timezone={timezone}
          expanded={expanded}
          onDetailsExpand={onDetailsExpand}
          allowSalesAtWill={allowSalesAtWill}
        />
      ))}
    </Row>
  );
};

CaregiverDetailsLeafPA.propTypes = {
  caregiver: PropTypes.object.isRequired,
  onRegister: PropTypes.func.isRequired,
  validateSelectedPatientData: PropTypes.func,
  budTenders: PropTypes.array,
  timezone: PropTypes.string,
  expanded: PropTypes.object.isRequired,
  onDetailsExpand: PropTypes.func.isRequired,
  allowSalesAtWill: PropTypes.bool.isRequired,
};

export default CaregiverDetailsLeafPA;
