/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import {Link} from 'react-router';

import {ListGroup, ListGroupItem} from 'react-bootstrap';
import UserOptsLink from './UserOptsLink';
import RegisterLoader from './RegisterLoader';

const UserOptsMenu = ({notifications, translateEnglish, translateSpanish, translateFrench, translateItalian,
  handleLogout, handleCloseClick, clearMeta, userPermission, user}) => {
  return (
  <div>

    <h3>{I18n.t('nav.welcome')},</h3>
    <h4>{user.firstName} {user.lastName}</h4>
    <ListGroup>
      {userPermission && (userPermission.view_notifications) ?
      <ListGroupItem onClick={handleCloseClick}>
        <Link to='/notifications'>
          <UserOptsLink
            glyph='alert'
            displayText={I18n.t('nav.Notifications')}
            count={notifications}
          />
        </Link>
      </ListGroupItem> : null}
      <ListGroupItem onClick={() => handleLogout()}>
        <UserOptsLink
            glyph='off'
            displayText={I18n.t('nav.Logout')}
        />
      </ListGroupItem>
      <RegisterLoader />
        <ListGroupItem onClick={() => {translateEnglish(); handleCloseClick();}}>
            <UserOptsLink
              glyph='info-sign'
              displayText={I18n.t('nav.English')}
            />
        </ListGroupItem>
        <ListGroupItem onClick={() => {translateSpanish(); handleCloseClick();}}>
            <UserOptsLink
              glyph='info-sign'
              displayText={I18n.t('nav.Spanish')}
            />
        </ListGroupItem>
        <ListGroupItem onClick={() => {translateFrench(); handleCloseClick();}}>
            <UserOptsLink
              glyph='info-sign'
              displayText={I18n.t('nav.French')}
            />
        </ListGroupItem>
      <ListGroupItem onClick={() => {translateItalian(); handleCloseClick();}}>
        <UserOptsLink
            glyph='info-sign'
            displayText={I18n.t('nav.Italian')}
        />
      </ListGroupItem>
        <ListGroupItem onClick={() => clearMeta()}>
          <UserOptsLink
              glyph='remove-sign'
              displayText={I18n.t('nav.clearCache')}
          />
        </ListGroupItem>
      </ListGroup>
  </div>
  );
};

UserOptsMenu.propTypes = {
  notifications : PropTypes.number,
  handleCloseClick: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  translateEnglish: PropTypes.func.isRequired,
  translateSpanish: PropTypes.func.isRequired,
  translateFrench: PropTypes.func.isRequired,
  translateItalian: PropTypes.func.isRequired,
  clearMeta: PropTypes.func.isRequired,
  userPermission: PropTypes.object,
  user: PropTypes.object
};

export default UserOptsMenu;
