import React from 'react';
import get from 'lodash.get';
import InternationalDecimalStatic from '../../../common/form/InternationalDecimalStatic';
import InternationalDateStatic from '../../../common/form/InternationalDateStatic';


//
// Current Inventory Report table definition props
//
export const inventoryReportTabs = [
  {
    id: 'default',
    eventKey: 'default',
    title: 'retail.inventoryReport.currentInventory',
    actions: []
  }
];

export const inventoryReportColumns = [
  {
    name: 'retail.inventoryReport.columns.itemName',
    dataId:'item_master_name',
    csvFormatter: (cell) => cell,
    width: '200px'
  },
  {
    name: 'retail.inventoryReport.columns.vendor',
    dataId:'partner_name',
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.inventoryReport.columns.category',
    dataId:'category_name',
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.inventoryReport.columns.subcategory',
    dataId:'subcategory_name',
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.inventoryReport.columns.storageLocation',
    dataId:'location_name',
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.inventoryReport.columns.totalWeightQuantity',
    dataId:'qty',
    formatter: (cell, row) => (<InternationalDecimalStatic suffix={' '  +  get(row, 'uom_display')}>{cell}</InternationalDecimalStatic>),
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.inventoryReport.columns.strain',
    dataId:'strain',
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.inventoryReport.columns.stateTrackingId',
    dataId:'state_tracking_id',
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.inventoryReport.columns.packageId',
    dataId:'package_code',
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.transactionReport.columns.unitCost',
    dataId:'unit_cost',
    /* eslint-disable react/no-multi-comp */
    formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.inventoryReport.columns.pricingGroups',
    dataId:'pricing_groups',
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.inventoryReport.columns.pricingClasses',
    dataId:'pricing_classes',
    csvFormatter: (cell) => cell || '',
  },

  // Hidden columns
  {
    name: 'retail.inventoryReport.columns.productType',
    dataId:'product_type',
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.inventoryReport.columns.basePricePerGramRetail',
    dataId:'base_price_per_gram_retail',
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.inventoryReport.columns.basePricePerGramWholesale',
    dataId:'base_price_per_gram_wholesale',
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.inventoryReport.columns.packageCreatedDate',
    dataId:'package_created_date',
    formatter: (cell) => (<InternationalDateStatic adjustForClientTimezone={false}>{cell}</InternationalDateStatic>),
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.inventoryReport.columns.packageExpirationDate',
    dataId:'package_expiration_date',
    formatter: (cell) => (<InternationalDateStatic adjustForClientTimezone={false}>{cell}</InternationalDateStatic>),
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.inventoryReport.columns.itemNumber',
    dataId:'item_number',
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.inventoryReport.columns.batchId',
    dataId:'batch_id',
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.inventoryReport.columns.productOnlineAvailability',
    dataId:'product_online_availability',
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.inventoryReport.columns.reservedQty',
    dataId:'reserved_qty',
    formatter: (cell, row) => (<InternationalDecimalStatic>{cell}</InternationalDecimalStatic>),
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.inventoryReport.columns.reservedUom',
    dataId:'reserved_uom',
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.inventoryReport.columns.itemMedicatedWeightTotal',
    dataId:'item_medicated_weight_total',
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.inventoryReport.columns.itemMedicatedWeightUom',
    dataId:'item_medicated_weight_uom',
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.inventoryReport.columns.itemNetWeightTotal',
    dataId:'item_net_weight_total',
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.inventoryReport.columns.itemNetWeightUom',
    dataId:'item_net_weight_uom',
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.inventoryReport.columns.itemUnitWeightTotal',
    dataId:'item_unit_weight_total',
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.inventoryReport.columns.itemUnitWeightUom',
    dataId:'item_unit_weight_uom',
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.inventoryReport.columns.itemId',
    dataId:'item_id',
    csvFormatter: (cell) => cell || '',
  },
];
