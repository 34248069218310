import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {Col, Row} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import TextInput from '../../common/form/TextInput';
import ReduxTagField from '../../common/form/redux-form/ReduxTagField';
import WillRender from '../../common/concealers/WillRender';

export const PatientIdBlock = ({tagOptions, isPaLeaf, isWaLeaf, paOraclePatientSyncFeature}) => {
  const tags = (<Col md={12}>
    <ReduxTagField name='customer.tags' props={{
      options: tagOptions,
      label: I18n.t('customers.create.tags'),
      textKey: 'tag_name',
      valueKey: 'tag_name'
    }} />
  </Col>);
  return isPaLeaf || isWaLeaf
    ? (<Row>
      {tags}
      <Col xs={6}>
        <Field name='customer.state_integration_tracking_id' component={TextInput} props={{
          label: I18n.t('customers.create.statePatientId'),
          isRequired: isWaLeaf,
          readOnly: paOraclePatientSyncFeature && isPaLeaf,
        }}/>
      </Col>
      <WillRender ifTrue={paOraclePatientSyncFeature}>
        <Col xs={6}>
          <Field
            name='customer.patient_certification_status'
            component={TextInput}
            props={{
              label: I18n.t('customers.create.patientCertificationStatus'),
              readOnly: true,
            }}
          />
        </Col>
      </WillRender>
    </Row>
  )
    : <Row>{tags}</Row>;
};

PatientIdBlock.propTypes = {
  tagOptions: PropTypes.array.isRequired,
  isPaLeaf: PropTypes.bool.isRequired,
  isWaLeaf: PropTypes.bool.isRequired,
  paOraclePatientSyncFeature: PropTypes.bool,
};

export default PatientIdBlock;
