import React from 'react';
import PropTypes from 'prop-types';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import { Tab, Tabs, Row, Col } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import { getData, getFile } from '../../../actions/apiActions';
import { setItem } from '../../../actions/itemActions';
import AbstractTestResultListing from '../common/AbstractTestResultListing';
import * as formNames from '../../../constants/forms';
import { getTabs } from '../common/tabDefinitions';
import TestResultsExportForm from './TestResultsExportForm';
import { isValidDate } from '../../../util/dateHelpers';
import { dateRangeConstraints } from './helpers';

/**
 * TestResultsExportPage
 * uses form above.
 */
export class TestResultsExportPage extends AbstractTestResultListing {
  constructor (props, context) {
    super(props, context);
    this.viewResultsButton = this.viewResultsButton.bind(this);
    this.toggleViewDetails = this.toggleViewDetails.bind(this);
    this.switchTab = this.switchTab.bind(this);
    this.expandComponent = this.expandComponent.bind(this);
    this.setBodyState = this.setBodyState.bind(this);

    this.onSubmit = this.onSubmit.bind(this);
    this.fetchLabResults = this.fetchLabResults.bind(this);
    this.dateIsWithinValidityConstraints = this.dateIsWithinValidityConstraints.bind(this);
    this.getMostRecentLabResult = this.getMostRecentLabResult.bind(this);

    const tabs = getTabs({}).map((tab) => {
      tab.actions = [];
      return tab;
    });

    this.state = {
      tabs,
      startDate: false,
    };
  }

  componentWillMount () {
    this.getMostRecentLabResult();
  }

  /**
   * Used to set the default start date.
   */
  getMostRecentLabResult () {
    const params = {
      sort: 'testing_date:desc',
      limit: 1,
    };
    this.props.actions.getData('/api/lab_results', null, null, params)
      .then((data) => {
        const getEndDate = (startDate) => {
          const endDate = startDate.clone();
          endDate.add('years', 1);
          if (endDate > moment()) { // If greater than today then set it to today.
            return moment();
          }
          return endDate;
        };
        const latestLabResult = data.length ? data.pop() : false;
        const startDate = !latestLabResult || !isValidDate(latestLabResult.testing_date) ? moment() : moment(latestLabResult.testing_date);
        this.setState({
          startDate,
          endDate: getEndDate(startDate),
        });
      });
  }

  onSubmit (formData) {
    const params = Object.keys(formData).reduce((acc, key) => {
      acc[key] = moment(formData[key]).utc().format('YYYY-MM-DD');
      return acc;
    }, {});
    params.sort = 'testing_date:asc';
    params.return_csv = 1;
    this.fetchLabResults(params);
  }

  //@TODO: Put errorHandler on here once apiActions getfile is refactored.
  fetchLabResults (params) {
    this.props.actions.getFile(
      '/api/lab_results',
      'lab_results_export.csv',
      {failed: 'cultivation.testResults.get.csvNoRecords'},
      params
    );
  }

  getInitialValues () {
    const {startDate, endDate} = this.state;
    if (!startDate) {
      return false;
    }
    return {
      testing_date_from: startDate,
      testing_date_to: endDate,
    };
  }

  dateIsWithinValidityConstraints (field, current) {
    return dateRangeConstraints(field, current, this.props.formValues);
  }

  render () {
    const {tabs} = this.state;
    const initialValues = this.getInitialValues();
    if (!initialValues) {
      return null;
    }
    return (<div>
      <Tabs id='filterTabs' activeKey='export' onSelect={this.switchTab}>
        {tabs.map((tab, index) =>
          <Tab key={index} eventKey={tab.eventKey} title={I18n.t(tab.title)}>
            {tab.description && <div className='tab-description'>{tab.description}</div>}
          </Tab>
        )}
      </Tabs>
      <TestResultsExportForm
        initialValues={initialValues}
        onSubmit={this.onSubmit}
        startDateIsValid={(current) => {
          return this.dateIsWithinValidityConstraints('testing_date_from', current);
        }}
        endDateIsValid={(current) => {
          return this.dateIsWithinValidityConstraints('testing_date_to', current);
        }}
      />
      <Row>
        <Col md={9}>
          <div className='text-muted'>
            Lab Result exports include all lab results active and historical. Maximum date range is 1 year. Default date
            range
            is from the date of your most recent lab result testing date through the current day.
          </div>
        </Col>
      </Row>
    </div>);
  }
}

TestResultsExportPage.propTypes = {
  actions: PropTypes.shape({
    push: PropTypes.func.isRequired,
    getData: PropTypes.func.isRequired,
  }).isRequired,
  formValues: PropTypes.object,
};

function mapStateToProps (state) {
  return {
    formValues: getFormValues(formNames.TEST_RESULTS_EXPORT_FORM)(state),
  };
}

function mapDispatchToProps (dispatch) {
  const actions = {push, setItem, getData, getFile};
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TestResultsExportPage);
