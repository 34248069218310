import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';

import SingleFieldInfo from '../common/SingleFieldInfo';


const status = 'thickening_agents_status';

const ThickeningAgents = ({row, editable, disabled, isIsolocity, isolocityTestResult, dimensions, requiredDimensions, displayCompletionStatus}) => (
  <SingleFieldInfo
    row={row || {}}
    status={status}
    columns={get(dimensions, 'thickening_agents', [])}
    requiredDimensions={requiredDimensions}
    editable={editable}
    disabled={disabled}
    isIsolocity={isIsolocity}
    isolocityStatus={get(isolocityTestResult, `inspection.sub_statuses.${status}`, 'In Progress')}
    displayCompletionStatus={displayCompletionStatus}
  />
);

ThickeningAgents.propTypes = {
  row: PropTypes.object,
  dimensions: PropTypes.object,
  editable: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  isIsolocity: PropTypes.bool,
  isolocityTestResult: PropTypes.object,
  requiredDimensions: PropTypes.array,
  displayCompletionStatus: PropTypes.bool
};

ThickeningAgents.defaultProps = {
  disabled: false,
};

export default ThickeningAgents;
