import React from 'react';
import PropTypes from 'prop-types';
import {reduxForm, Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {
  Button,
  ButtonToolbar,
  Col,
  Row } from 'react-bootstrap';

import ReactSelectInput from '../../common/form/ReactSelectInput';
import MultiselectInput from '../../common/form/MultiselectInput';
import TextInput from '../../common/form/TextInput';
import InternationalDateTimePickerInput from '../../common/form/InternationalDateTimePickerInput';

import {validate} from './validateStartPackaging';
import DisplayQty from '../../common/DisplayQty';

export const StartPackagingForm = ({locations, currentFacilityUsers, handleSubmit, submitting, pristine,
                                     selectedPackageInfo, hasFacilityPrepackWeights, noStrain}) => {
  return (
    <form className='harvest-packages-form' onSubmit={handleSubmit}>
      <Row>
        <Col xs={8} md={3} lg={3}>
          <Field
            name='package_code' component={TextInput}
            props={{
              label: I18n.t('packaging.form.lotId'),
              disabled: true
            }} />
        </Col>
        <Col xs={8} md={5} lg={4}>
          <Field
            name='inventory_location_id' component={ReactSelectInput}
            props={{
              label: I18n.t('packaging.form.packagingLocation'),
              options: locations,
              placeholder: I18n.t('common.form.selectPlaceholder')
            }} />
        </Col>
      </Row>
      <Row>
        {
          noStrain
            ? null
            : <Col xs={6}>
            <strong>{I18n.t('packaging.form.strain')}: </strong>
            &nbsp;{selectedPackageInfo.strain}
          </Col>
        }
      </Row>
      <Row>
        <Col xs={6}>
          <strong>{I18n.t('packaging.form.weight')}: </strong>
          <DisplayQty qty={selectedPackageInfo.quantity} uom={selectedPackageInfo.uom} displayUom={true} />
        </Col>
      </Row>
      <Row className='vendor-row'>
        <Col xs={6}>
          <strong>{I18n.t('packaging.form.producer')}: </strong>
          {selectedPackageInfo.vendor}
        </Col>
      </Row>
      <Row>
        <Col xs={8} md={3} lg={3}>
          <Field
            name='user_ids' component={MultiselectInput}
            props={{
              label: I18n.t('packaging.form.assignEmployees'),
              options: currentFacilityUsers,
              placeholder: I18n.t('common.form.selectPlaceholder'),
              valueKey: 'id',
              textKey: 'displayName'
            }} />
        </Col>
        <Col xs={8} md={3} lg={3}>
          <InternationalDateTimePickerInput
            name='packaging_start_time'
            props={{
              label: I18n.t('packaging.form.packagingStartTime'),
              timeFormat: true
            }} />
        </Col>
        <Col xs={8} md={3} lg={3}>
          <InternationalDateTimePickerInput
            name='expected_completion_time'
            props={{
              label: I18n.t('packaging.form.expectedCompletionTime'),
              timeFormat: true
            }} />
        </Col>
      </Row>
      <Row className='border-top padding-all'>
        <Col xs={12}>
          <ButtonToolbar className='float-right'>
            <Button variant='primary'
                    type='submit'
                    disabled={submitting || pristine || !hasFacilityPrepackWeights || noStrain}>
              {I18n.t('common.form.save')}
            </Button>
          </ButtonToolbar>
        </Col>
      </Row>
    </form>
  );
};

StartPackagingForm.propTypes = {
  locations: PropTypes.array.isRequired,
  currentFacilityUsers: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  hasFacilityPrepackWeights: PropTypes.bool.isRequired,
  selectedPackageInfo: PropTypes.object.isRequired,
  noStrain: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'crateHarvestPackages',
  validate
})(StartPackagingForm);
