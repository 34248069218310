import {formValueSelector, getFormValues} from 'redux-form';
import {createSelector} from 'reselect';
import get from 'lodash.get';
import {getFacilityGroups, getDetailedFacilities, getFacilitiesWithTypeEqualToCurrentFacility, getActiveFacilityId, getActiveFacility} from '../facilitiesSelectors';
import {getCurrentUser} from '../usersSelectors';
import {getItem} from '../configurableSelectors';
import {FACILITY_GROUP_FORM} from '../../constants/forms';
import * as itemNames from '../../constants/itemNames';


const FILTER_SHOW_ALL_VALUES = 'All';

const selector = new formValueSelector(FACILITY_GROUP_FORM);
const facilityFormValues = getFormValues(FACILITY_GROUP_FORM);

/***
 * Creates filters based on the field values in the available facilities
 * @param state
 * @returns {Array}
 */
export const getFacilityFilters = createSelector(
  [getFacilitiesWithTypeEqualToCurrentFacility],
  (facilities) => {
    const filterTypes = ['province_code', 'retail_type'];

    return filterTypes.map((filterType) => {
      const options = [FILTER_SHOW_ALL_VALUES];

      const filterProps = {
        type: filterType,
        options: [],
        selected: null,
      };

      facilities.forEach((f) => {
        const val = f[filterType];
        if (options.indexOf(val) === -1) {
          options.push(val);
        }
      });

      filterProps.options = options.map((o) => ({text: o}));

      return filterProps;
    });
  }
);

/***
 * Returns facilities filtered by filter.type field in redux form
 * @param state
 * @returns {Array}
 */
export const getFilteredFacilities = createSelector(
  [getFacilityFilters, getFacilitiesWithTypeEqualToCurrentFacility, facilityFormValues],
  (filters, facilities, formValues = {}) => {
    return facilities.filter((facility) =>
      filters.map((userFilter) =>
        [facility[userFilter.type], FILTER_SHOW_ALL_VALUES]
          .includes(formValues[userFilter.type])
      ).every((v) => !!v)
    );
  }
);

const getFacilityGroup = (state) => {
  return state[itemNames.facilityGroup];
};

/***
 * Get initialValues for create or edit
 * @type {Reselect.Selector<TInput, TOutput>}
 */
export const getInitialValues = createSelector([getFacilityGroup, getFacilityFilters], (facilityGroup, filters) => {
  const getFilterDefaults = () => {
    return filters.reduce((acc, filter) => {
      acc[filter.type] = filter.options[0].text;
      return acc;
    }, {});
  };
  const getMemberFacilities = () => {
    if(!facilityGroup.members) return {};
    return facilityGroup.members.reduce((acc, member) => {
      acc[`facility_${member.facility_id}`] = true;
      return acc;
    }, {});
  };
  return Object.assign({}, facilityGroup, getFilterDefaults(), getMemberFacilities());
});

export const getSelectedFacilityIds = createSelector(
  [getFacilitiesWithTypeEqualToCurrentFacility, facilityFormValues],
  (facilities, formValues = {}) => {
    return facilities.reduce((acc, facility) => {
      const val = formValues[`facility_${facility.id}`];
      if(val) acc.push(facility.id);
      return acc;
    }, []);
  }
);

export const getPayload = (formData) => {
  return {
    name: formData.name,
    member_ids: Object.keys(formData).reduce((acc, prop) => {
      if(prop.indexOf('facility_') === -1) return acc;
      if(!formData[prop]) return acc;
      acc.push(prop.split('_').pop());
      return acc;
    }, [])
  };
};

export const getAfterSubmitValue = (state) => {
  return selector(state, 'afterSubmit');
};

export const getFacilityGroupsWithMemberDetails = createSelector([getFacilityGroups, getDetailedFacilities, getActiveFacilityId, getItem], (facilityGroups, facilities, currentFacilityId, settings) => {
  // Handle case where a bad itemName is passed in resulting invalid settings
  if(Array.isArray(settings)) settings = {};
  if(typeof settings !== 'object') settings = {};

  const groupHasAtLeastOneSetting = (group) => {
    if(!Array.isArray(group.settings)) return false;
    if(!group.members.find((member) => member.facility_id === currentFacilityId)) return false;
    return group.settings.reduce((acc, setting) => {
      if(acc) return acc;
      const settingKey = get(setting, 'setting.setting_value.setting_key', false);
      if(!settingKey) return false;
      return settings[settingKey] !== undefined;
    }, false);
  };

  return facilityGroups.map((facilityGroup) => {
    return Object.assign({}, facilityGroup, {
      members: facilityGroup.members.map((member) => {
        const facility = facilities.find((facility) => facility.id === member.facility_id);
        if(facility) facility.isCurrentFacility = facility.id === currentFacilityId;
        return facility;
      }).sort((a,b) => a.name.localeCompare(b.name)),
      controlsSettings: groupHasAtLeastOneSetting(facilityGroup),
      controlledSettings: facilityGroup.settings.filter((setting) => {
        return get(setting, 'setting.setting_value.setting_key', false);
      })
    });
  });
});

const getPermissionKey = (_, props) => props.permissionKey;

export const userManagesSettings = createSelector([getCurrentUser, getActiveFacility, getPermissionKey], (user, facility, permissionKey) => {
  if(!facility || !facility.key) return false;
  return Boolean(user && user.permissions
    && user.permissions[facility.key]
    && user.permissions[facility.key].permissions
    && user.permissions[facility.key].permissions[permissionKey]);
});

