export const BT_KIEF = 5;
export const BT_FLOWER = 6;
export const BT_CLONE = 7;
export const BT_OTHER_MATERIAL = 9;
export const BT_SEED = 10;
export const BT_PLANT_TISSUE = 11;
export const BT_MATURE_PLANT = 12;
export const BT_FLOWER_LOT = 13;
export const BT_OTHER_MATERIAL_LOT = 14;
export const BT_BUBBLE_HASH = 15;
export const BT_HASH = 16;
export const BT_HYDROCARBON_WAX = 17;
export const BT_CO2_HASH_OIL = 18;
export const BT_FOOD_GRADE_SOLVENT_EXTRACT = 19;
export const BT_INFUSED_DAIRY_BUTTER = 20;
export const BT_INFUSED_COOKING_OIL = 21;
export const BT_SOLID_CANNABIS_INFUSED_EDIBLE = 22;
export const BT_LIQUID_CANNABIS_INFUSED_EDIBLE = 23;
export const BT_CANNABIS_EXTRACT_FOR_INHALATION = 24;
export const BT_CANNABIS_INFUSED_TOPICALS = 25;
export const BT_SAMPLE_JAR = 26;
export const BT_WASTE = 27;
export const BT_USABLE_CANNABIS = 28;
export const BT_WET_FLOWER = 29;
export const BT_CANNABIS_MIX = 30;
export const BT_CANNABIS_MIX_PACKAGED = 31;
export const BT_CANNABIS_MIX_INFUSED = 32;
export const BT_CAPSULE = 34;
export const BT_TINCTURE = 35;
export const BT_TRANSDERMAL_PATCH = 36;
export const BT_SUPPOSITORY = 37;
export const BT_NON_SMOKEABLE_INFUSED_EXTRACT = 38;
export const BT_USABLE_TRIM = 39;
export const BT_USABLE_SHAKE = 40;
export const BT_USABLE_KEIF = 41;

export const btReassignableInventoryTypes = [
  BT_KIEF,
  BT_BUBBLE_HASH,
  BT_HASH,
  BT_HYDROCARBON_WAX,
  BT_CO2_HASH_OIL,
  BT_FOOD_GRADE_SOLVENT_EXTRACT,
  BT_INFUSED_DAIRY_BUTTER,
  BT_INFUSED_COOKING_OIL,
  BT_CANNABIS_MIX,
];

export const btProducerInventoryTypes = [
  BT_KIEF,
  BT_FLOWER,
  BT_CLONE,
  BT_OTHER_MATERIAL,
  BT_SEED,
  BT_PLANT_TISSUE,
  BT_MATURE_PLANT,
  BT_FLOWER_LOT,
  BT_OTHER_MATERIAL_LOT,
  BT_BUBBLE_HASH,
  BT_HASH,
  BT_HYDROCARBON_WAX,
  BT_CO2_HASH_OIL,
  BT_FOOD_GRADE_SOLVENT_EXTRACT,
  BT_INFUSED_DAIRY_BUTTER,
  BT_INFUSED_COOKING_OIL,
  BT_SOLID_CANNABIS_INFUSED_EDIBLE,
  BT_LIQUID_CANNABIS_INFUSED_EDIBLE,
  BT_CANNABIS_EXTRACT_FOR_INHALATION,
  BT_CANNABIS_INFUSED_TOPICALS,
  BT_SAMPLE_JAR,
  BT_WASTE,
  BT_USABLE_CANNABIS,
  BT_WET_FLOWER,
  BT_CANNABIS_MIX,
  BT_CANNABIS_MIX_PACKAGED,
  BT_CANNABIS_MIX_INFUSED,
  BT_CAPSULE,
  BT_TINCTURE,
  BT_TRANSDERMAL_PATCH,
  BT_SUPPOSITORY,
  BT_NON_SMOKEABLE_INFUSED_EXTRACT,
  BT_USABLE_TRIM,
  BT_USABLE_SHAKE,
  BT_USABLE_KEIF,

];

export const btProcessorInventoryTypes = [
  BT_KIEF,
  BT_FLOWER,
  BT_CLONE,
  BT_OTHER_MATERIAL,
  BT_SEED,
  BT_MATURE_PLANT,
  BT_FLOWER_LOT,
  BT_OTHER_MATERIAL_LOT,
  BT_BUBBLE_HASH,
  BT_HASH,
  BT_HYDROCARBON_WAX,
  BT_CO2_HASH_OIL,
  BT_FOOD_GRADE_SOLVENT_EXTRACT,
  BT_INFUSED_DAIRY_BUTTER,
  BT_INFUSED_COOKING_OIL,
  BT_SOLID_CANNABIS_INFUSED_EDIBLE,
  BT_LIQUID_CANNABIS_INFUSED_EDIBLE,
  BT_CANNABIS_EXTRACT_FOR_INHALATION,
  BT_CANNABIS_INFUSED_TOPICALS,
  BT_SAMPLE_JAR,
  BT_WASTE,
  BT_USABLE_CANNABIS,
  BT_CANNABIS_MIX,
  BT_CANNABIS_MIX_PACKAGED,
  BT_CANNABIS_MIX_INFUSED,
  BT_CAPSULE,
  BT_TINCTURE,
  BT_TRANSDERMAL_PATCH,
  BT_SUPPOSITORY,
];

export const btRetailerInventoryTypes = [
  BT_KIEF,
  BT_FLOWER,
  BT_CLONE,
  BT_OTHER_MATERIAL,
  BT_SEED,
  BT_MATURE_PLANT,
  BT_FLOWER_LOT,
  BT_OTHER_MATERIAL_LOT,
  BT_BUBBLE_HASH,
  BT_HASH,
  BT_HYDROCARBON_WAX,
  BT_CO2_HASH_OIL,
  BT_FOOD_GRADE_SOLVENT_EXTRACT,
  BT_INFUSED_DAIRY_BUTTER,
  BT_INFUSED_COOKING_OIL,
  BT_SOLID_CANNABIS_INFUSED_EDIBLE,
  BT_LIQUID_CANNABIS_INFUSED_EDIBLE,
  BT_CANNABIS_EXTRACT_FOR_INHALATION,
  BT_CANNABIS_INFUSED_TOPICALS,
  BT_SAMPLE_JAR,
  BT_WASTE,
  BT_USABLE_CANNABIS,
  BT_CANNABIS_MIX,
  BT_CANNABIS_MIX_PACKAGED,
  BT_CANNABIS_MIX_INFUSED,
  BT_CAPSULE,
  BT_TINCTURE,
  BT_TRANSDERMAL_PATCH,
  BT_SUPPOSITORY,
];

export const btRetailerInventoryTypesIlinois = [
  BT_SOLID_CANNABIS_INFUSED_EDIBLE,
  BT_LIQUID_CANNABIS_INFUSED_EDIBLE,
  BT_CANNABIS_EXTRACT_FOR_INHALATION,
  BT_SAMPLE_JAR,
  BT_USABLE_CANNABIS,
  BT_CANNABIS_MIX_PACKAGED,
  BT_CANNABIS_MIX_INFUSED
];

export const nonQASampleInventoryTypes = [
  BT_CLONE,
  BT_SEED,
  BT_PLANT_TISSUE,
  BT_MATURE_PLANT,
  BT_SAMPLE_JAR,
];

export const BT_PACKAGING_TYPES = [
  BT_CANNABIS_MIX_PACKAGED, BT_USABLE_CANNABIS, BT_SAMPLE_JAR, BT_CANNABIS_EXTRACT_FOR_INHALATION
];

export const btLabInventoryTypes = [
  BT_KIEF,
  BT_FLOWER,
  BT_CLONE,
  BT_OTHER_MATERIAL,
  BT_SEED,
  BT_MATURE_PLANT,
  BT_FLOWER_LOT,
  BT_OTHER_MATERIAL_LOT,
  BT_BUBBLE_HASH,
  BT_HASH,
  BT_HYDROCARBON_WAX,
  BT_CO2_HASH_OIL,
  BT_FOOD_GRADE_SOLVENT_EXTRACT,
  BT_INFUSED_DAIRY_BUTTER,
  BT_INFUSED_COOKING_OIL,
  BT_SOLID_CANNABIS_INFUSED_EDIBLE,
  BT_LIQUID_CANNABIS_INFUSED_EDIBLE,
  BT_CANNABIS_EXTRACT_FOR_INHALATION,
  BT_CANNABIS_INFUSED_TOPICALS,
  BT_SAMPLE_JAR,
  BT_WASTE,
  BT_USABLE_CANNABIS,
  BT_CANNABIS_MIX,
  BT_CANNABIS_MIX_PACKAGED,
  BT_CANNABIS_MIX_INFUSED,
  BT_CAPSULE,
  BT_TINCTURE,
  BT_TRANSDERMAL_PATCH,
  BT_SUPPOSITORY,
];

export const nonTestableInventoryTypes = [
  BT_CLONE,
  BT_OTHER_MATERIAL,
  BT_SEED,
  BT_PLANT_TISSUE,
  BT_MATURE_PLANT,
  BT_OTHER_MATERIAL_LOT,
  BT_SAMPLE_JAR,
  BT_WET_FLOWER,
  BT_FLOWER,
];

export const nonIRInventoryTypes = [
  BT_WASTE,
  BT_FLOWER,
  BT_WET_FLOWER,
  BT_OTHER_MATERIAL,
  BT_NON_SMOKEABLE_INFUSED_EXTRACT,
];
