import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Button, ListGroupItem} from 'react-bootstrap';
import {FaTimes} from 'react-icons/fa';
import InternationalCurrencyStatic from '../common/form/InternationalCurrencyStatic';

const Coupon = ({coupon, removeCoupon}) => {

  const map = {
    coupon: {
      amount: 'discount_amount',
      type: 'discount_type',
      sku: 'coupon_sku'
    },
    reward: {
      amount: 'reward_amount',
      type: 'reward_type',
      sku: 'reward_code'
    }
  };

  const product_name = coupon.target_order_product_name;
  const typeMap = {
    coupon: 'Coupon',
    redemption: 'Reward Points Redemption',
    manual: 'Manual Discount',
    mmap: 'MMAP Discount',
    reward: 'Reward Coupon',
  };

  const isPercentBased = () => {
    if(!coupon.fullCoupon || !map[coupon.type]) return false;
    return coupon.fullCoupon[map[coupon.type].type] === 'percent';
  };

  const getPercentBasedLabel = (label) => {
    if(!isPercentBased()) return '';
    return `${label}: ${coupon.fullCoupon[map[coupon.type].amount]}%`;
  };

  const hasSku = () => {
    return coupon.fullCoupon && map[coupon.type];
  };

  const getSku = (label) => {
    if(!hasSku()) return '';
    return `${label}: ${coupon.fullCoupon[map[coupon.type].sku]}`;
  };

  return (
    <ListGroupItem className='coupon clearfix'>
      <div className='float-left'>
        Type: {typeMap[coupon.type] ? typeMap[coupon.type] : 'Unknown'}
        {coupon.fullCoupon ? `: ${coupon.fullCoupon.name}` : ''}
        {coupon.type === 'reward' && coupon.fullCoupon ? ` (${coupon.fullCoupon.points_required} Pts.)` : ''}
        {coupon.type === 'redemption' ? ` (${coupon.points_used} Pts.)` : ''}
      </div>
      <div className='float-right'>
        <Button size='sm' onClick={() => removeCoupon(coupon.id)}>
          <FaTimes/>
        </Button>
      </div>
      <div className='float-right' style={{marginRight: '8px'}}>
        <InternationalCurrencyStatic>{coupon.amount}</InternationalCurrencyStatic>
        {product_name ? ' ' + I18n.t('cart.coupons.off') + ' ' + product_name : ''}
      </div>
      <div style={{clear:'both'}} />
      <div>
        {getSku('SKU')}{getPercentBasedLabel(hasSku() ? ' Percentage Value' : 'Percentage Value')}
      </div>
    </ListGroupItem>
  );
};

Coupon.propTypes = {
  removeCoupon: PropTypes.func.isRequired,
  coupon: PropTypes.object.isRequired,
};

export default Coupon;
