import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import get from 'lodash.get';
import { Tab, Tabs } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import * as p from '../../constants/permissions';
import { getSettingAddTestResultsToPlantGroups } from '../../selectors/settingsSelectors';
import { userHasPermission } from '../../selectors/usersSelectors';
import { getIntegrationState } from '../../selectors/integration/integrationSelectors';
import PageTitle from '../common/PageTitle';
import TestResultListingPage from './TestResultListingPage'; //eslint-disable-line
import TestResultListingPageForPlants from './TestResultListingPageForPlants'; //eslint-disable-line
import AverageTestResultsPage from './AverageTestResultsPage'; //eslint-disable-line
import TestResultsExportPage from './TestResultsExport/TestResultsExportPage'; //eslint-disable-line
import { isFeatureEnabled } from '../../selectors/featureToggles'; //eslint-disable-line

const getTestResultsTypeFromPath = (path) => {
  return path.indexOf('plants') !== -1 ? 'plant_group' : 'package';
};

export class TestResultsPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);

    this.setActiveTab = this.setActiveTab.bind(this);

    this.state = {
      activeKey: 'package',
      tabs: [
        {
          key: 'package',
          text: 'Packages'
        },
        {
          key: 'plant_group',
          text: 'Plants',
        }
      ],
      showResultTypeTabs: false,
    };
  }

  componentWillMount(){
    const trueToShowTypeTabs = ['integrationState.isHemp', 'canAddTestResults', 'hasAddTestResultsSetting'];
    this.setState({
      showResultTypeTabs: trueToShowTypeTabs.reduce((acc, key) => {
        if(get(this.props, key, false)){
          acc = acc + 1;
        }
        return acc;
      }, 0) === trueToShowTypeTabs.length && this.props.ftOnForPlantBatchTesting,
      activeKey: getTestResultsTypeFromPath(this.props.location.pathname),
    });
  }

  componentWillReceiveProps(nextProps){
    const activeKey = getTestResultsTypeFromPath(nextProps.location.pathname);
    if(activeKey !== this.state.activeKey){
      this.setState({activeKey});
    }
  }


  setActiveTab(tabKey){
    if(tabKey === 'package'){
      this.props.actions.push(`/test-results/${this.props.params.tab}`);
    } else {
      this.props.actions.push(`/plants/test-results/${this.props.params.tab}`);
    }
  }


  render () {
    const activeTab = get(this.props, 'params.tab', 'active');
    const currentPath = this.props.location.pathname;
    return (
      <div>
        <PageTitle
          primaryText={I18n.t(`cultivation.testResults.${activeTab || 'active'}Header`)}
        />
        {
          this.state.showResultTypeTabs
            && (<div id='test-results-types-tab' style={{margin: '24px 0 18px 0'}}>
              <Tabs
                activeKey={this.state.activeKey}
                onSelect={this.setActiveTab}
              >
                {
                  this.state.tabs.map((tab, index) => {
                    return (<Tab
                      eventKey={tab.key}
                      key={index}
                      title={I18n.t(`${tab.text}`)}
                    />);
                  })
                }
              </Tabs>
            </div>)
        }
        <div>
          {
            this.state.showResultTypeTabs
              ? ['active', 'historical'].indexOf(activeTab) !== -1
                ? (<div style={{padding: '0 20px'}} id='test-results-nested-service'>
                  {
                    getTestResultsTypeFromPath(currentPath) === 'plant_group'
                      ? <TestResultListingPageForPlants
                          params={this.props.params}
                          referenceType={getTestResultsTypeFromPath(this.props.location.pathname)}
                        />
                      : <TestResultListingPage
                          params={this.props.params}
                          referenceType={getTestResultsTypeFromPath(this.props.location.pathname)}
                        />
                  }
                </div>)
                : ['averages'].indexOf(activeTab) !== -1
                ? (<div style={{padding: '0 20px'}} id='test-results-nested-averages'>
                  <AverageTestResultsPage params={this.props.params} referenceType={this.state.activeKey} />
                </div>)
                : (<div style={{padding: '0 20px'}} id='test-results-nested-averages'>
                  <TestResultsExportPage params={this.props.params} referenceType={this.state.activeKey} />
                </div>)
              : ['active', 'historical'].indexOf(activeTab) !== -1
                ? <TestResultListingPage params={this.props.params} referenceType='package'/>
                : ['averages'].indexOf(activeTab) !== -1
                ? <AverageTestResultsPage params={this.props.params} />
                : <TestResultsExportPage params={this.props.params} />
          }
        </div>
      </div>
    );
  }
}

TestResultsPage.propTypes = {
  integrationState: PropTypes.object,
  canAddTestResults: PropTypes.bool,
  hasAddTestResultsSetting: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    integrationState: getIntegrationState(state),
    canAddTestResults: userHasPermission(state, {permissions: [p.manage_plant_testing, p.manage_testing]}),
    hasAddTestResultsSetting: getSettingAddTestResultsToPlantGroups(state) ? true : false,
    ftOnForPlantBatchTesting: isFeatureEnabled(state)('feature_plant_batch_testing'),
  };
}

function mapDispatchToProps(dispatch){
  const actions = {push};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (TestResultsPage);
