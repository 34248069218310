import React from 'react';
import { I18n } from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import {Field} from 'redux-form';
import PropTypes from 'prop-types';

import AccordionPanel from '../../common/AccordionPanel';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import ContentConcealer from '../../common/concealers/ContentConcealer';

const WeedmapsAvailabilitySection = (props) => {
  const {commonOptions, isWeedmaps} = props;
  return (
    <ContentConcealer show={isWeedmaps}>
      <AccordionPanel title={'products.massModify.weedmapsAvailabilityTitle'}>
        <Row>
          <Col sm={3}>
            <Field name={'sales_attributes.weedmaps_share'} component={ReactSelectInput} props={{
              options: commonOptions,
              label: I18n.t('products.form.shareProductWithWeedmaps'),
            }}/>
          </Col>
        </Row>
    </AccordionPanel>
    </ContentConcealer>
  );
};

WeedmapsAvailabilitySection.propTypes = {
  isWeedmaps: PropTypes.bool.isRequired,
  commonOptions: PropTypes.array.isRequired
};

export default WeedmapsAvailabilitySection;