import {createSelector} from 'reselect';
import map from 'lodash.map';
import * as itemNames from '../../constants/itemNames';

export const getBiotrackStates = state => state[itemNames.biotrackStates];

export const getBiotrackStateOptions = createSelector(
  [getBiotrackStates],
  biotrackStates => map(biotrackStates, (text, value) => ({text, value}))
);
