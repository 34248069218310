/* eslint-disable import/no-named-as-default */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {goBack} from 'react-router-redux';
import {change} from 'redux-form';
import get from 'lodash.get';
import find from 'lodash.find';
import * as itemNames from '../../constants/itemNames';
import * as dataNames from '../../constants/dataNames';
import {unsetItem} from '../../actions/itemActions';
import * as apiActions from '../../actions/apiActions';
import SalesSettingForm from './SalesSettingForm';
import getRulesCategories from '../../selectors/compliance/sales/getRulesCategories';
import {getMetrcSettings} from '../../selectors/integration/metrcSelectors';
import {getApplicationMode} from '../../selectors/applicationModesSelectors';
import {getIntegrationState} from '../../selectors/integration/integrationSelectors';
import {getAllowAnonymousOrders} from '../../selectors/salesSettingsSelectors';
import {SALES_SETTINGS_FORM} from '../../constants/forms';
import getSalesSettings from '../../util/salesSettings';
import FormWrapper from '../common/form/FormWrapper';
import InProgressOverlay from '../common/InProgressOverlay';
import {getInitialValues, getPayload} from '../../selectors/forms/salesSettingsFormSelectors';
import FacilityGroupsPage from '../settings/facility-groups/FacilityGroupsPage';
import {getLabelTags} from '../../selectors/labelsSelectors';
import {getIsHempFacility} from '../../selectors/coreSettingsSelectors';

const getAt = get;

export class SalesSettingPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.redirect = this.redirect.bind(this);
    this.getComplianceSettings = this.getComplianceSettings.bind(this);
    this.getSettingByKey = this.getSettingByKey.bind(this);
    this.salesSettings = getSalesSettings();
    this.setComplianceType = this.setComplianceType.bind(this);
    this.showFacilityGroups = this.showFacilityGroups.bind(this);
    this.onHideFacilityGroups = this.onHideFacilityGroups.bind(this);
    this.getIntegrationSettings = this.getIntegrationSettings.bind(this);
    this.getConfiguredComplianceType = this.getConfiguredComplianceType.bind(this);

    this.state = {
      paymentsAvailable: this.salesSettings.config.paymentsAvailable,
      eqFields: this.salesSettings.config.eqFields,
      metrcOhEqFields: this.salesSettings.config.metrcOhEqFields,
      eqUoms: this.salesSettings.config.eqUoms,
      restrictSalesTimePeriods: this.salesSettings.config.restrictSalesTimePeriods,
      loading: true,
      saving: false,
      message: 'loading',
      complianceType: 'equivalency',
      complianceSet: false,
      integrationState: this.props.integrationState,
      showFacilityGroups: false,
    };
  }

  componentWillMount() {
    this.props.actions.unsetItem(itemNames.salesComplianceSettings);
    this.getIntegrationSettings();
    this.getComplianceSettings();
    this.getCategories(); // For category based rules
    this.getPatientLabelTags();
  }

  getIntegrationSettings () {
    this.props.actions.getItem('/api/integration-settings', itemNames.integrationSettings);
  }

  getPatientLabelTags() {
    this.props.actions.getData('/api/labels/tags/patient_label', dataNames.labelTags);
  }

  getComplianceSettings() {
    this.setState({saving: false});
    const loadingComplete = () => this.setState({loading: false});
    this.salesSettings.getSettings(this.props.actions.getItem, true).then(loadingComplete).catch(loadingComplete);
    this.props.actions.getItem('/api/compliance_settings', itemNames.complianceSettings);
  }

  getCategories(){
    this.props.actions.getUnpaginatedData('/api/categories', dataNames.categories); // Ensures cats available for rules based settings
  }

  setComplianceType(complianceType){
    this.setState({complianceType});
  }

  getDisableOnFailure(props){
    if(!props) props = this.props;
    if(!props.salesComplianceSettings || !props.salesComplianceSettings.order_disable_limits_on_external_system_error) return '0';
    return (props.salesComplianceSettings.order_disable_limits_on_external_system_error.value)
      ? props.salesComplianceSettings.order_disable_limits_on_external_system_error.value
      : '0';
  }

  getConfiguredComplianceType(patientType){
    const rulesPath = 'order_sales_limits_by_consumer_type.value';
    const settings = getAt(this.props.salesComplianceSettings, `${rulesPath}.${patientType}.method_configuration`, []);
    return settings.length
      ? settings[0].category_code !== undefined ? 'equivalency' : 'categories'
      : 'equivalency';
  }

  onSubmit(formSettings) {
    this.setState({saving: true, message: 'saving'});
    const hideLoader = () => {
      this.setState({saving: false});
    };
    formSettings.facilityId = this.props.facility.id;
    // salesComplianceSettings passed in so on disabling we can get original method config instead of nuking
    const settings = getPayload(formSettings, this.props.salesComplianceSettings);
    const messages = {failed: 'retail.salesComplianceSettings.failed', success: 'retail.salesComplianceSettings.salesSettingHaveBeenSaved'};
    this.props.actions.postItem('/api/order_settings/sales_limit', settings, '', messages, {}, () => {
      hideLoader();
      this.props.actions.getItem('/api/order_settings/sales_limit', itemNames.salesComplianceSettings); // Reload after save
      if(formSettings.afterSubmit !== 'stay'){
        this.redirect();
        return false;
      }
    });
  }

  redirect() {
    this.props.actions.goBack();
  }

  getSettingByKey(key) {
    const {salesComplianceSettings} = this.props;
    let value = undefined;
    switch (key) {
    case 'order_restrict_sales':
      value = {
        weight_value: null,
        interval: null,
        time_period: null
      };
      if (salesComplianceSettings[key] && salesComplianceSettings[key] !== null) {
        value = Object.assign(value, salesComplianceSettings[key].value);
      }
      break;
    case 'order_buffer':
    case 'order_inactive_after':
      value = {
        interval: null,
        time_period: null
      };
      if (salesComplianceSettings[key]) {
        value = Object.assign(value, salesComplianceSettings[key].value);
      }
      break;
    case 'order_select_payment_options':
      if (salesComplianceSettings[key] && salesComplianceSettings[key] !== null && salesComplianceSettings[key].value !== null) {
        value = salesComplianceSettings[key].value.options;
      }
      break;
    default:
      if (salesComplianceSettings[key] && salesComplianceSettings[key] !== null) {
        value = salesComplianceSettings[key].value;
      }
    }
    return value;
  }

  showFacilityGroups(){
    this.setState({showFacilityGroups: true});
  }

  onHideFacilityGroups(){
    this.setState({showFacilityGroups: false});
  }

  render() {

    const retailType = getAt(this.props.facility, 'retail_type', 'medical');
    const limitsPath = 'order_sales_limits_by_consumer_type.value';
    const order_buffer = this.getSettingByKey('order_buffer');
    const order_inactive_after = this.getSettingByKey('order_inactive_after');

    // TODO: Migrate everything pertaining to initialValues from this page to the "getInitialValues" selector
    // Please do not add any more nonsense here.
    const initialValues = {
      ...this.props.initialValues,
      patient_type: (retailType.indexOf('co_located') !== -1) ? 'medical' : retailType, // sets initial patient type toggle
      allowUpload: this.getSettingByKey('order_upload_of_docs'),
      allowDelivery: this.getSettingByKey('order_delivery_of_product'),
      sellFIFO: this.getSettingByKey('order_first_in_first_out'),
      paymentOptions: this.getSettingByKey('order_select_payment_options'),
      autoExpired: this.getSettingByKey('order_auto_inactivation'),
      setBuffer: order_buffer.interval,
      inactiveAfter: order_inactive_after.interval,
      enableReservationsAPI: this.getSettingByKey('order_enable_reservation_API'),
      printWithoutPayment: this.getSettingByKey('order_print_labels_and_receipts_without_payment'),
      standardReceiptFooterText: this.getSettingByKey('order_receipt_footer_text'),
      order_allow_leafPA_sales_at_will: getAt(this.props.salesComplianceSettings, 'order_allow_leafPA_sales_at_will.value', '0'),
      order_allow_sales_with_temp_paperwork: getAt(this.props.salesComplianceSettings, 'order_allow_sales_with_temp_paperwork.value', '0'),
      order_packaging_workflow: getAt(this.props.salesComplianceSettings, 'order_packaging_workflow.value', '0'),
      order_allow_api_sales_on_medical_patient: getAt(this.props.salesComplianceSettings, 'order_allow_api_sales_on_medical_patient.value', '0'),
      lock_pre_checkin_online_order: getAt(this.props.salesComplianceSettings, 'lock_pre_checkin_online_order.value', '0'),
      order_allow_sales_on_id_expiration: getAt(this.props.salesComplianceSettings, 'order_allow_sales_on_id_expiration.value', '0'),
      order_recreational_sales_age_limit: getAt(this.props.salesComplianceSettings, 'order_recreational_sales_age_limit.value', '21'),
      order_allow_anonymous: this.props.allowAnonymousOrders,
      order_birthdate_for_anonymous_orders: getAt(this.props.salesComplianceSettings, 'order_birthdate_for_anonymous_orders.value', true),
      order_allow_expired_dl_on_rec: getAt(this.props.salesComplianceSettings, 'order_allow_expired_dl_on_rec.value', 1),
      order_anonymous_customer_types: getAt(this.props.salesComplianceSettings, 'order_anonymous_customer_types.value', []),
      register_closing_is_allowed: getAt(this.props.salesComplianceSettings, 'register_closing.value.is_allowed') ? '1' : '0',
      register_closing_count_by: getAt(this.props.salesComplianceSettings, 'register_closing.value.count_by', ''),
      order_auto_printing_is_enabled: getAt(this.props.salesComplianceSettings, 'order_auto_printing.value.is_enabled') ? '1' : '0',
      order_auto_printing_receipts: getAt(this.props.salesComplianceSettings, 'order_auto_printing.value.print_receipts'),
      order_auto_printing_labels: getAt(this.props.salesComplianceSettings, 'order_auto_printing.value.print_labels'),
      order_auto_printing_default_label: getAt(this.props.salesComplianceSettings, 'order_auto_printing.value.default_label'),
      order_enable_weigh_on_hand: getAt(this.props.salesComplianceSettings,'order_enable_weigh_on_hand.value', '1'),
      order_weigh_on_hand_margin_error: getAt(this.props.salesComplianceSettings,'order_weigh_on_hand_margin_error.value', '0.000'),
    };

    const patientTypes = ['medical', 'recreational'];
    const rulesBasedDefaults = {qty: 1, uom: 'gr', limit_using: 'gross_weight'};

    // Set defaults for equivalency for all patient types
    this.state.eqFields.forEach((field) => {
      patientTypes.forEach((patientType) => {
        initialValues[`${patientType}_weight_${field.category_code}`] = field.defaultValue;
        initialValues[`${patientType}_select_${field.category_code}`] = this.state.eqUoms.find((uom) => uom.isDefault).value;
        initialValues[`${patientType}_flower_${field.category_code}`] = 0;
        initialValues[`${patientType}_disabled_${field.category_code}`] = true;
        // Removed 03/29/2018 Per Product
        // if(field.category_code === 'EDIBLE' && this.props.facility.country_code === 'CA'){ // Defaulting Canada edibles to high value; coming online in summer this prevents them from being excluded.
        //   initialValues[`${patientType}_flower_${field.category_code}`] = 1000;
        //   initialValues[`${patientType}_weight_${field.category_code}`] = 0.01;
        // }
      });
    });

    // Set defaults for rules based for all patient types
    if(this.props.rulesCategories){ // Set either defaults or custom for category based rules
      this.props.rulesCategories.forEach((rule) => {
        patientTypes.forEach((patientType) => {
          Object.keys(rulesBasedDefaults).forEach((field) => {
            initialValues[`${patientType}_${field}__${rule.value}`] = rulesBasedDefaults[field];
          });
        });
      });
    }

    const rulesPath = 'order_sales_limits_by_consumer_type.value';
    // Override defaults in patient type / rule type combination
    patientTypes.forEach((patientType) => {
      const settings = getAt(this.props.salesComplianceSettings, `${rulesPath}.${patientType}.method_configuration`, []);
      const limitMethod = getAt(this.props.salesComplianceSettings, `${rulesPath}.${patientType}.sales_limit_method`, 'equivalency');
      const limitType = settings.length && limitMethod === 'disabled'
        ? settings[0].category_code !== undefined ? 'equivalency' : 'categories'
        : false;
      if (this.props.integrationState.isOhMetrc) {
        const metrcOhSettings = getAt(this.props.salesComplianceSettings, `order_sales_limit_equivalency_by_oh_metrc_category.value`, []);
        this.state.metrcOhEqFields.forEach((field) => {
          const metrcOhSetting = metrcOhSettings && metrcOhSettings.length > 0 && metrcOhSettings.find((s) => s.category_code === field.category_code);
          initialValues[`metrc_oh_equivalency_weight_${field.category_code}`] = getAt(metrcOhSetting, 'weight_value', 0);
          initialValues[`metrc_oh_equivalency_select_${field.category_code}`] = getAt(metrcOhSetting, 'uom', 'GR');
          initialValues[`metrc_oh_equivalency_flower_${field.category_code}`] = getAt(metrcOhSetting, 'flower_weight_value', 0);
          initialValues[`metrc_oh_equivalency_disabled_${field.category_code}`] = (getAt(metrcOhSetting, 'flower_weight_value', 0) === 0);
        });
      }
      if(limitMethod === 'equivalency' || limitType === 'equivalency'){
        this.state.eqFields.forEach((field) => {
          const setting = find(settings, { category_code: field.category_code });
          initialValues[`${patientType}_weight_${field.category_code}`] = getAt(setting, 'weight_value', 0);
          initialValues[`${patientType}_select_${field.category_code}`] = getAt(setting, 'uom', 'GR');
          initialValues[`${patientType}_flower_${field.category_code}`] = getAt(setting, 'flower_weight_value', 0);
          initialValues[`${patientType}_disabled_${field.category_code}`] = (getAt(setting, 'flower_weight_value', 0) === 0);
        });
      } else if (limitMethod === 'categories' || limitType === 'categories') {
        if(this.props.rulesCategories){
          this.props.rulesCategories.forEach((rule) => {
            const setting = Array.isArray(settings) ? settings.find((r) => r.sales_limit_category_key === rule.value) : false;
            Object.keys(rulesBasedDefaults).forEach((field) => {
              initialValues[`${patientType}_${field}__${rule.value}`] = (setting) ? getAt(setting, field, rulesBasedDefaults[field]) : rulesBasedDefaults[field];
            });
          });
        }
      }
    });

    // Set limit methods and restrictions by patient type
    patientTypes.forEach((patientType) => {
      initialValues[`${patientType}_limit_method`] = getAt(this.props.salesComplianceSettings, `${limitsPath}.${patientType}.sales_limit_method`, 'equivalency');
      initialValues[`${patientType}_restrictSalesGrams`] = getAt(this.props.salesComplianceSettings, `${limitsPath}.${patientType}.limit_to.weight_value`, null);
      initialValues[`${patientType}_restrictSalesLimit`] = getAt(this.props.salesComplianceSettings, `${limitsPath}.${patientType}.limit_to.interval`, this.props.facility.country_code === 'CA' ? '1' : null);
      initialValues[`${patientType}_restrictSalesType`] = getAt(this.props.salesComplianceSettings, `${limitsPath}.${patientType}.limit_to.time_period`, this.props.facility.country_code === 'CA' ? 'month' : null);
      initialValues[`${patientType}_order_physician_info`] = getAt(this.props.salesComplianceSettings, `${limitsPath}.${patientType}.order_physician_info`, '0');
      if (this.props.integrationState.isOhMetrc) {
        initialValues[`${patientType}_terminal_illness_limits`] = getAt(this.props.salesComplianceSettings, `${limitsPath}.${patientType}.limit_to.terminal_illness`, 283.5);
      }
      if (!initialValues[`${patientType}_restrictSalesLimit`] && this.props.facility.country_code === 'CA') {
        initialValues[`${patientType}_restrictSalesLimit`] = '1';
      }
      if (!initialValues[`${patientType}_restrictSalesType`] && this.props.facility.country_code === 'CA') {
        initialValues[`${patientType}_restrictSalesType`] = 'month';
      }
    });
    if (this.props.integrationState.isCure) {
      patientTypes.forEach((patientType) => {
        initialValues[`${patientType}_limit_method`] = 'lab_results_based';
        initialValues[`${patientType}_display_uom`] = getAt(this.props.salesComplianceSettings, `${rulesPath}.${patientType}.display_uom`, 'GR');
      });
    }

    initialValues.order_disable_limits_on_external_system_error = this.getDisableOnFailure(); // returns default if not present

    return (
      <FormWrapper title={I18n.t('retail.title') + ' ' + I18n.t('retail.salesSettings.title')} goBack={() => this.props.actions.goBack()}>
        <InProgressOverlay isActive={this.state.loading || this.state.saving} translate={true} message={this.state.message}/>
        <div className='retail-sales-settings'>
          <FacilityGroupsPage
            settingsIntegration={true}
            settingsReduxName={itemNames.salesComplianceSettings}
            formName={SALES_SETTINGS_FORM}
            getPayload={getPayload}
            reloadSettings={this.getComplianceSettings}
            permissionKey='manage_organization'>

            <SalesSettingForm
              onSubmit={this.onSubmit}
              change={this.props.change}
              eqFields={this.state.eqFields}
              metrcOhEqFields={this.state.metrcOhEqFields}
              eqUoms={this.state.eqUoms}
              initialValues={initialValues}
              paymentsAvailable={this.state.paymentsAvailable}
              autoExpired={initialValues.autoExpired}
              restrictSalesTimePeriods={this.state.restrictSalesTimePeriods}
              setComplianceType={this.setComplianceType}
              complianceType={this.state.complianceType}
              rulesCategories={this.props.rulesCategories}
              showRulesBasedCompliance={this.props.showRulesBasedCompliance}
              applicationMode={this.props.applicationMode}
              integrationState={this.props.integrationState}
              labelTags={this.props.labelTags}
              isCoLocated={getAt(this.props.facility, 'retail_type', 'medical').indexOf('co_located') !== -1}
              getConfiguredComplianceType={this.getConfiguredComplianceType}
              facility={this.props.facility}
              isHempFacility={this.props.isHempFacility}
              settingsMethodIsOverridable={getAt(this.props.salesComplianceSettings,'order_sales_limits_by_consumer_type.overrideable', true)}
            />
          </FacilityGroupsPage>
        </div>
      </FormWrapper>
    );
  }
}

SalesSettingPage.propTypes = {
  actions: PropTypes.shape({
    getItem: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired
  }).isRequired,
  salesComplianceSettings: PropTypes.object,
  facility: PropTypes.object,
  rulesCategories: PropTypes.array,
  integrationState: PropTypes.object,
  showRulesBasedCompliance: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const {salesComplianceSettings, facility} = state;
  const rulesCategories = getRulesCategories(state);
  return {
    salesComplianceSettings,
    facility,
    metrcSettings: getMetrcSettings(state),
    allowAnonymousOrders: getAllowAnonymousOrders(state),
    rulesCategories: rulesCategories,
    showRulesBasedCompliance: (Array.isArray(rulesCategories) && rulesCategories.length > 0),
    applicationMode: getApplicationMode(state),
    integrationState: getIntegrationState(state),
    labelTags: getLabelTags(state),
    isHempFacility: getIsHempFacility(state),
    initialValues: getInitialValues(state)
  };
}

function mapDispatchToProps (dispatch) {
  const actions = {
    ...apiActions,
    change,
    goBack,
    unsetItem,
  };
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesSettingPage);
