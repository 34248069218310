import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Table, Card} from 'react-bootstrap';
import ContentConcealer from '../../../common/concealers/ContentConcealer';

const PlantHarvestedDetails = ({model}) => {
  const arrowIndicator = '->';
  const {harvest_batch, harvest_batch_plant, tracking_id} = model;
  const startWeight = isNaN(parseFloat(model.start_weight_batch)) ? model.wet_weight_harvest : model.start_weight_batch;
  const finishWeight = isNaN(parseFloat(model.finish_weight_batch)) ? harvest_batch.current_weight : model.finish_weight_batch;
  return (
    <Card>
      <Table striped className='plant-details'>
        <thead>
        <tr>
          <th>{I18n.t('plantHistory.field')}</th>
          <th>{I18n.t('plantHistory.value')}</th>
        </tr>
        </thead>
        <tbody>
        <ContentConcealer show={Boolean(harvest_batch && harvest_batch.batch_name)}>
          <tr>
            <td>{I18n.t('plantHistory.plant.harvestBatch')}</td>
            <td>{harvest_batch && harvest_batch.batch_name}</td>
          </tr>
        </ContentConcealer>
        <ContentConcealer show={Boolean(harvest_batch && harvest_batch.current_weight && harvest_batch.uom)}>
          <tr>
            <td>{I18n.t('plantHistory.plant.weight')}</td>
            <td>{parseFloat(startWeight).toFixed(2)} {arrowIndicator} {parseFloat(finishWeight).toFixed(2)} {harvest_batch.uom}</td>
          </tr>
        </ContentConcealer>
        <ContentConcealer show={Boolean(harvest_batch_plant && harvest_batch_plant.rating_avg_pest_resistance)}>
          <tr>
            <td>{I18n.t('plantHistory.plant.pestResistance')}</td>
            <td>{harvest_batch_plant && harvest_batch_plant.rating_avg_pest_resistance}</td>
          </tr>
        </ContentConcealer>
        <ContentConcealer show={Boolean(harvest_batch_plant && harvest_batch_plant.rating_avg_mold)}>
          <tr>
            <td>{I18n.t('plantHistory.plant.mold')}</td>
            <td>{harvest_batch_plant && harvest_batch_plant.rating_avg_mold}</td>
          </tr>
        </ContentConcealer>
        <ContentConcealer show={Boolean(harvest_batch_plant && harvest_batch_plant.rating_avg_mildew)}>
          <tr>
            <td>{I18n.t('plantHistory.plant.powderyMildew')}</td>
            <td>{harvest_batch_plant && harvest_batch_plant.rating_avg_mildew}</td>
          </tr>
        </ContentConcealer>
        <ContentConcealer show={Boolean(harvest_batch_plant && harvest_batch_plant.rating_avg_bud_rot)}>
          <tr>
            <td>{I18n.t('plantHistory.plant.budRot')}</td>
            <td>{harvest_batch_plant && harvest_batch_plant.rating_avg_bud_rot}</td>
          </tr>
        </ContentConcealer>
        <ContentConcealer show={Boolean(harvest_batch_plant && harvest_batch_plant.rating_avg_stretching)}>
          <tr>
            <td>{I18n.t('plantHistory.plant.stretching')}</td>
            <td>{harvest_batch_plant && harvest_batch_plant.rating_avg_stretching}</td>
          </tr>
        </ContentConcealer>
        <ContentConcealer show={Boolean(harvest_batch_plant && harvest_batch_plant.rating_avg_airy_buds)}>
          <tr>
            <td>{I18n.t('plantHistory.plant.airyBuds')}</td>
            <td>{harvest_batch_plant && harvest_batch_plant.rating_avg_airy_buds}</td>
          </tr>
        </ContentConcealer>
        <ContentConcealer show={Boolean(tracking_id)}>
          <tr>
            <td>{I18n.t('plantHistory.plant.trackingId')}</td>
            <td>{tracking_id}</td>
          </tr>
        </ContentConcealer>
        </tbody>
      </Table>
    </Card>
  );
};

PlantHarvestedDetails.propTypes = {
  model: PropTypes.object.isRequired,
};

export default PlantHarvestedDetails;
