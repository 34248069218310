import {I18n} from 'react-redux-i18n';
import {requiredFieldValidation, integerFieldValidation} from '../../common/form/redux-form/validations';

export const validate = (values) => {
  const errors = {
    schedule_name: requiredFieldValidation(values.schedule_name, 'feedingSchedules.form.name'),
    stages: requiredFieldValidation(values.stages && values.stages.length, 'feedingSchedules.form.phase'),
    rooms: requiredFieldValidation(values.rooms && values.rooms.length, 'feedingSchedules.form.location'),
    amount_per_feeding: requiredFieldValidation(values.amount_per_feeding, 'feedingSchedules.form.litersPerFeedingPlaceholder'),
    frequency: requiredFieldValidation(values.frequency, 'feedingSchedules.form.frequencyPlaceholder'),
    time: requiredFieldValidation(values.time, 'feedingSchedules.form.daysPlaceholder'),
  };

  if (!errors.frequency && values.frequency) {
    errors.frequency = !integerFieldValidation(+values.frequency) ? I18n.t('feedingSchedules.form.frequencyCountWrong') : '';
  }

  if (!errors.time && values.time) {
    errors.time = !integerFieldValidation(+values.time) ? I18n.t('feedingSchedules.form.daysCountWrong') : '';
  }

  if (values.ingredients && values.ingredients.length) {
    errors['ingredients'] = values.ingredients.map(ing => ({
      item_master_id: requiredFieldValidation(ing.item_master_id, 'feedingSchedules.form.ingredient'),
      qty: requiredFieldValidation(ing.qty, 'feedingSchedules.form.qtyLiter'),
    }));
  }

  return errors;
};

export default validate;
