import React from 'react';
import PropTypes from 'prop-types';
import startcase from 'lodash.startcase';
import { I18n } from 'react-redux-i18n';
import { Button } from 'react-bootstrap';
import { FaCaretRight } from 'react-icons/fa';
import get from 'lodash.get';
import Packages from './TestResultProducts';
import DetailsPanel from './DetailsPanel'; //eslint-disable-line
import WaterActivity from '../panels/WaterActivity';
import CannabinoidPotency from '../panels/CannabinoidPotency';
import HeavyMetals from '../panels/HeavyMetals';
import Microbials from '../panels/Microbials';
import Mycotoxins from '../panels/Mycotoxins';
import Pesticides from '../panels/Pesticides';
import ResidualSolvents from '../panels/ResidualSolvents';
import TerpeneProfile from '../panels/TerpeneProfile';
import ForeignMaterials from '../panels/ForeignMaterials';
import * as dataNames from '../../../constants/dataNames';

const tabs = ['active', 'historical', 'averages', 'export'];

class AbstractTestResultListing extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.ref = React.createRef();
  }

  static getComponent(componentName) {
    const list = {
      Packages,
      WaterActivity,
      CannabinoidPotency,
      HeavyMetals,
      Microbials,
      Mycotoxins,
      Pesticides,
      ResidualSolvents,
      TerpeneProfile,
      ForeignMaterials
    };

    return list[componentName];
  }

  switchTab(activeTab) {
    if (tabs.indexOf(activeTab) < 0) {
      return this.switchTab(tabs[0]);
    }
    if (activeTab === this.props.params.tab) {
      return false;
    }

    const path = this.props.referenceType === 'package'
      ? `/test-results/${activeTab}`
      : `/plants/test-results/${activeTab}`;

    this.props.actions.push(path);
    return true;
  }

  viewResultsButton(details) {
    return (_, row) => {
      const expanded = this.state.expanded.find((r) => r.id === row.id && r.details === details);
      return (
        <Button variant='link' onClick={(event) => this.toggleViewDetails(event, details, row.id)}>
          <span>{I18n.t(`cultivation.testResults.table.${details === 'packages' ? 'view' : 'viewResults'}`)}</span>
          <FaCaretRight className={expanded ? 'expanded' : ''} />
        </Button>
      );
    };
  }

  /**
   * Gets the references that belong to a given lab result, then gets the related objects (package or plants right now)
   * then normalizes and then updates redux.  This could be cleaner but this at least decouples the results from the
   * referenced object (plant or package).
   * @param id
   */
  loadLabResultReferenceObjects(id){

    const normalizePackage = (reference, object) => {
      return Object.assign({}, object, reference, {
        is_active_package: get(reference, 'reference_is_active', 0),
        is_latest_lab_result: get(reference, 'is_latest_lab_result', 0),
        lot_number: get(object, 'lot.lot_number', ''),
        product_name: get(object, 'item_master.name', ''),
      });
    };

    const normalizePlant = (reference, object) => {
      return Object.assign({}, object, reference, {
        is_active_package: get(reference, 'reference_is_active', 0),
        batch_name: get(object, 'planting_batch.batch_name'),
        reference_name: get(object, 'plant_id'),
      });
    };

    const reference = this.props.testResults.find((reference) => reference.id === id);

    if(!reference){
      return false;
    }

    const referenceType = get(reference, 'reference_type', false);
    const labResultId = get(reference, 'lab_results_id', 0);

    // only disable request for Average tab
    if (labResultId === 0) {
      return false;
    }
    this.props.actions.getItem(`/api/lab_results/${labResultId}`, null)
      .then((labResult) => {

        const filter = this.getActiveTab() === 'active'
          ? (ref) => get(ref, 'reference_is_active', false) && get(ref, 'is_latest_lab_result', false)
          : (ref) => !get(ref, 'is_latest_lab_result', false) || !get(ref, 'reference_is_active', false);

        const allReferences = get(labResult, 'all_references', []);

        const references = allReferences.filter(filter);

        const ids = references.map((reference) => reference.reference_id);
        const url = referenceType === 'package' ? '/api/packages' : '/api/plants';
        const idsProperty = referenceType === 'package' ? 'in_package_ids' : 'plant_ids';
        const params = {
          [idsProperty]: ids,
          detailed: true,
        };

        const normalize = referenceType === 'package' ? normalizePackage : normalizePlant;
        // Get the referenced objects - plant or package and normalize if required
        this.props.actions.getData(url, null, null, params)
          .then((referencedObjects) => {
            const refs = references.map((ref) => {
              const referencedObject = referencedObjects.find((ro) => ro.id === ref.reference_id);
              if(referencedObject){
                return normalize(ref, referencedObject);
              }
              return ref;
            });

            // Update test results array in redux adding the references for the expanded row
            const testResults = this.props.testResultGroups.map((result) => {
              if(id === result.id){
                return Object.assign({}, labResult, result, {references: refs});
              }
              return result;
            });
            this.props.actions.setData(testResults, dataNames.testResultGroups);
          });

      });
    return true;
  }

  toggleViewDetails(event, details, id) {
    this.loadLabResultReferenceObjects(id);
    event.preventDefault();
    event.stopPropagation();
    const { expanded } = this.state;
    const rowInfo = expanded.find((row) => row.id === id);
    let state;
    if (rowInfo) {
      if (rowInfo.details === details) {
        state = { expanded: expanded.filter((row) => row.id !== id) };
      } else {
        state = { expanded: expanded.map((row) => (row.id === id ? { ...row, details } : row)) };
      }
    } else {
      state = { expanded: expanded.concat({ id, details }) };
    }
    const expanding = state.expanded.map((row) => row.id);
    this.setState(state);
    this.setBodyState({ expanding });
  }

  expandComponent(row, labResultDimensions, hideStatus) {
    const { expanded } = this.state;
    const { referenceType, displayCompletionStatus } = this.props;
    const rowInfo = expanded.find((r) => r.id === row.id);
    if (!rowInfo) {
      return;
    }
    const componentName = startcase(rowInfo.details).replace(/\s/gi, '');

    const Component = AbstractTestResultListing.getComponent(componentName);
    if (!Component) {
      return null;
    }

    const titleKey = rowInfo.details === 'packages' && referenceType === 'plant_group' ? 'plant_groups' : `${rowInfo.details}.title`;

    return (
      <DetailsPanel
        title={I18n.t(`cultivation.testingCompliance.${titleKey}`)}
        onClose={(event) => this.toggleViewDetails(event, rowInfo.details, rowInfo.id)}
        row={row}
        dimensions={labResultDimensions}
        referenceType={referenceType}
        Component={Component}
        displayCompletionStatus={displayCompletionStatus}
        isLabApplyProfiles={hideStatus}
      />
    );
  }

  setBodyState(state) {
    //Little hack here. BSTable ignores properties update and uses the inner state so we have to update in manually
    if (this.ref.current) {
      this.ref.current.wrappedInstance.getBaseRef().setState(state);
    }
  }
}

AbstractTestResultListing.propTypes = {
  params: PropTypes.shape({
    tab: PropTypes.string
  }).isRequired,
  actions: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default AbstractTestResultListing;
