import get from 'lodash.get';
import {pick} from 'lodash';
import { setData } from './dataActions';

export const getSelectedOrdersData = (orders) => {
  const orderFields = [
    'consumer_id',
    'facility_id',
    'fulfillment_method',
    'is_submitted',
    'locked', 'order_name',
    'order_source',
    'order_status',
    'order_type',
    'updated_at'
  ];

  return (Array.isArray(orders) ? orders : []).map((order) => {
    return {
      order_id: get(order, 'id', undefined),
      ...pick(order, orderFields)
    };
  });
};

export const getSelectedOrderData = (order) => {
  if (typeof order !== 'object') {
    return {};
  }
  const orders = getSelectedOrdersData([order]);
  return orders.length ? orders[0] : {};
};

export const getSelectedQueuesData = (queues) => {
  const queueFields = ['consumer_id', 'deleted_at', 'facility_id', 'order_id', 'updated_at'];
  return (Array.isArray(queues) ? queues : []).map((queue) => {
    return {
      queue_id: get(queue, 'id', undefined),
      ...pick(queue, queueFields),
    };
  });
};

export const getSelectedQueueData = (queue) => {
  if (typeof queue !== 'object') {
    return {};
  }
  const queues = getSelectedQueuesData([queue]);
  return queues.length ? queues[0] : {};
};

/**
 * This could be a selector but as its for a single entity it will always re run anyway until we can do away with it (soon).
 * @param consumer
 * @param orders
 * @param queues
 * @param dispatch
 * @param storeName
 * @returns {{default_phone_country_code: *, country_code: *, consumer_id: *, queues: string, orders: string, med_license_number: *, order_id: *, med_license_expiration: *, queue_id: *, default_phone_number: *}}
 */
export const createSearchCustomer = (consumer, orders, queues, dispatch, storeName) => {
  //@TODO: Reduce this.  Currently it replaces the solr data for customer which is wired in all over the place.
  // https://akerna.atlassian.net/browse/PTD-3
  const order = getSelectedOrderData(orders.length ? orders[0] : undefined);
  const queue = getSelectedQueueData(queues.length ? queues[0] : undefined);

  const medicalId = get(consumer, 'ids', []).find((id) => {
    return id.type === 'med' && id.active;
  });

  const defaultPhone = get(consumer, 'phone_numbers', []).find((phone) => {
    return phone.active && phone.type === 'default';
  });

  const defaultAddress = get(consumer, 'addresses', []).find((address) => {
    return address.active && address.primary;
  });

  const jsonOrders = JSON.stringify(Object.keys(order).length ? [order] : []);
  const jsonQueues = JSON.stringify(Object.keys(queue).length ? [queue] : []);

  const selectedData = {
    med_license_number: get(medicalId, 'identification_number', undefined),
    med_license_expiration: get(medicalId, 'expired_at', undefined),
    default_phone_number: get(defaultPhone, 'number', undefined),
    default_phone_country_code: get(defaultPhone, 'phone_country_code', undefined),
    country_code: get(defaultAddress, 'country_code', undefined),
    orders: jsonOrders,
    queues: jsonQueues,
    consumer_id: get(consumer, 'id', undefined),
    order_id: get(order, 'order_id', undefined),
    queue_id: get(queue, 'queue_id', undefined),
  };

  const extendedConsumer = {
    ...consumer,
    ...selectedData,
  };
  dispatch(setData([extendedConsumer], storeName));
};
