//eslint-disable indent
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {bindActionCreators} from 'redux';
import {FaAngleDown, FaAngleUp} from 'react-icons/fa';
import * as apiActions from '../../../../../actions/apiActions';
import getIsOrderAnonymous from '../../../../../selectors/anonymousOrderSelectors';
import {getIntegrationState} from '../../../../../selectors/integration/integrationSelectors';
import LimitsTableFooter from './LimitsTableFooter';
import {getDisplayRules} from '../../../../../selectors/customerLimitSelectors';
import {getDisplayRulesForMetrcOhio} from '../../../../../selectors/compliance/sales/metrcOhSalesLimits';
import {hasMetrcSalesLimits} from '../../../../../selectors/salesSettingsSelectors';

class PlatformEquivalencyDisplay extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
  }

  render() {

    const {integrationState: {isBiotrack}, multiViews, hasMetrcSalesLimits, isAnonymousOrder} = this.props;
    if(Object.keys(this.props.salesCompliance).length === 0) return null;

    const limit = this.props.salesCompliance.order_restrict_sales.value;

    if(this.props.customerId === 0 || isAnonymousOrder){
      limit.time_period = 'visit';
      limit.interval = 1;
    }

    const {displayRules} = this.props;

    const getDisplayRulesPropertySum = (field, returnString = false) => {
      const sum = Object.keys(displayRules).reduce((acc, rule) => {
        acc += displayRules[rule][field];
        return acc;
      }, 0);
      if(!returnString) return sum;
      // Return string formatted with minimal required after decimal and no more than 3
      const stringSum = String(sum);
      const temp = stringSum.split('.');
      const toFixedNumber = (temp.length > 1)
        ? temp[1].length > 2
          ? 3
          : temp[1].length
        : 0;
      return sum.toFixed(toFixedNumber);
    };

    const getStyle = () => {

      if(getDisplayRulesPropertySum('order') + getDisplayRulesPropertySum('previous_orders') === parseFloat(getTotalLimit())){
        return {color: 'green'};
      }
      return {};

    };

    const areConsumerLimitsLoading = () => {
      const props = this.props;
      return !!(props.customerId && props.customerLimits && !props.customerLimits.limits);
    };

    const getTotalLimit = () => {
      if(get(this.props, 'customerLimits.limits.total_weight_restriction', false)){
        return this.props.customerLimits.limits.total_weight_restriction;
      }
      return limit.weight_value ? limit.weight_value : 0;
    };

    const showInOrderWeight = (rule) => {
      return rule.awaiting_inventory
        ? <span className='text-danger' style={{fontSize: 'smaller'}}>Awaiting Inventory Specification</span>
        : `${rule.order} GR`;
    };

    return (<div>
      {
        !this.props.consumer
          ? null
          : <h4>Purchase Limits By Category</h4>
      }
      <table className='table table-sm table-bordered text-muted' style={{fontSize: 'smaller', backgroundColor: 'inherit'}}>
        <thead>
          <tr>
            <th
              style={this.props.headerStyle}
              onClick={this.props.onToggleSummary}
            >
              Purchase Limits By Category
              {this.props.showRows
                  ? <FaAngleUp className='float-right' />
                  : <FaAngleDown className='float-right' />
              }
            </th>
            <th onClick={this.props.onToggleSummary} style={Object.assign({}, this.props.headerStyle, {width: '20%'})}>Equivalence</th>
            {
              // TODO: Clean up all these very similar conditionals https://akerna.atlassian.net/browse/CBYTES-487
              isBiotrack || (this.props.customerId && hasMetrcSalesLimits) || isAnonymousOrder
              ? null
                : <th onClick={this.props.onToggleSummary} style={Object.assign({}, this.props.headerStyle, {width: '20%'})}>
                  {I18n.t('cart.limitTable.ordered')}
              </th>
            }
            {
              this.props.consumer || isBiotrack || hasMetrcSalesLimits
              ? null
                : <th onClick={this.props.onToggleSummary} style={Object.assign({}, this.props.headerStyle, {width: '20%'})}>
                  {I18n.t('cart.limitTable.inOrder')}
              </th>
            }
          </tr>
        </thead>

        {
          !this.props.showRows
          ? null
            : (<tbody>
            {
              Object.keys(displayRules).map((rule, index) => {
                return (<tr key={index}>
                  <td>{displayRules[rule].name}</td>
                  <td style={{width: '20%'}}>{displayRules[rule].rule}</td>
                  {isBiotrack || (this.props.customerId && hasMetrcSalesLimits) || isAnonymousOrder
                    ? null
                    : <td style={{width: '20%'}}>{displayRules[rule].previous_orders} GR</td>
                  }

                  {this.props.consumer || isBiotrack || hasMetrcSalesLimits || !isAnonymousOrder
                    ? null
                    : <td>{showInOrderWeight(displayRules[rule])}</td>
                  }
                </tr>);
              })
            }
            </tbody>)
        }
        <LimitsTableFooter
          integrationState={this.props.integrationState}
          limit={limit}
          areConsumerLimitsLoading={areConsumerLimitsLoading}
          getTotalLimit={getTotalLimit}
          getStyle={getStyle}
          getDisplayRulesPropertySum={getDisplayRulesPropertySum}
          consumer={this.props.consumer}
          customerLimits={this.props.customerLimits}
          customerId={this.props.customerId}
          multiViews={multiViews}
          isAnonymousOrder={isAnonymousOrder}
          hasMetrcSalesLimits={hasMetrcSalesLimits}
        />
      </table>
    </div>);
  }
}

PlatformEquivalencyDisplay.propTypes = {
  consumer: PropTypes.object,
  salesCompliance: PropTypes.object,
  headerStyle: PropTypes.object.isRequired,
  customerId: PropTypes.number.isRequired,
  customerLimits: PropTypes.object,
  stats: PropTypes.object,
  isAnonymousOrder: PropTypes.bool,
  integrationState: PropTypes.object,
  displayRules: PropTypes.object,
  multiViews: PropTypes.bool,
  hasMetrcSalesLimits: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {

  const isAnonymousOrder = getIsOrderAnonymous(state);
  const integrationState = getIntegrationState(state);
  const displayRules = (integrationState.isOhMetrc)
    ? getDisplayRulesForMetrcOhio(state, ownProps)
    : getDisplayRules(state, ownProps);

  return {
    integrationState,
    isAnonymousOrder,
    displayRules,
    hasMetrcSalesLimits: hasMetrcSalesLimits(state)
  };

}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions);
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PlatformEquivalencyDisplay);
