import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {bindActionCreators} from 'redux';
import exportCSV from '../../../util/csv_export_util';
import {convertDbDateTimeToFullDateTime} from '../../../util/dateHelpers';
import * as dataNames from '../../../constants/dataNames';
import {getUnpaginatedData} from '../../../actions/apiActions';
import {getActiveFacility} from '../../../selectors/facilitiesSelectors';
import TablePageWrapper from '../../common/grid/TablePageWrapper';
import SingleActionColumn from '../../common/grid/columns/SingleActionColumn';
import PageTitle from '../../common/PageTitle';

class CSVReportPage extends React.PureComponent {
  constructor (props, context) {
    super(props, context);

    this.exportReport = this.exportReport.bind(this);

    this.state = {
      columns: [
        {
          name: 'csvReports.table.report',
          dataAlign: 'left',
          headerAlign: 'left',
          dataSort: false,
          formatter: (cell, row) => {
            return get(row, 'csv_report_type.description');
          }
        },
        {
          name: 'csvReports.table.start',
          dataAlign: 'left',
          headerAlign: 'left',
          dataSort: false,
          formatter: (cell, row) => {
            const starts_at = get(row, 'starts_at');

            if (starts_at) {
              return convertDbDateTimeToFullDateTime(starts_at, get(props, 'facility.timezone'));
            }
            
            return null;
          }
        },
        {
          name: 'csvReports.table.end',
          dataAlign: 'left',
          headerAlign: 'left',
          dataSort: false,
          formatter: (cell, row) => {
            const ends_at = get(row, 'ends_at');
            
            if (ends_at) {
              return convertDbDateTimeToFullDateTime(ends_at, get(props, 'facility.timezone'));
            }
            
            return null;
          }
        },
        {
          name: 'csvReports.table.created',
          dataAlign: 'left',
          headerAlign: 'left',
          dataSort: false,
          formatter: (cell, row) => {
            const dispatched_at = get(row, 'dispatched_at');
            
            if (dispatched_at) {
              return convertDbDateTimeToFullDateTime(dispatched_at, get(props, 'facility.timezone'));
            }
            
            return null;
          }
        },
        {
          name: 'csvReports.table.export',
          dataAlign: 'left',
          headerAlign: 'left',
          dataSort: false,
          formatter: SingleActionColumn({
            label: 'csvReports.table.export',
            action: this.exportReport
          })
        },
      ]
    };
  }

  componentWillMount () {
    this.props.actions.getUnpaginatedData('/api/csv_reports', dataNames.csvReports);
  }

  exportReport (event, row) {
    exportCSV(row.report, this.getExportHeaders(row), `${row.csv_report_type.description}.csv`, ',', false);
  }

  getExportHeaders (row) {
    const sample = get(row, 'report.0');
    const keys = Object.keys(sample);

    return keys.map(key => {
      return {
        field: key,
        header: key,
        row: 0,
        rowSpan: 1,
        colSpan: 1,
      };
    });
  }

  render () {
    const {csvReports} = this.props;

    return (
      <div>
        <PageTitle primaryText={I18n.t('csvReports.table.title')} />
        <TablePageWrapper
          settingKey='csv-reports'
          columns={this.state.columns}
          data={csvReports}
          hideExport={true}
          hideScanSearch={true}
        />
      </div>
    );
  }
}

CSVReportPage.propTypes = {
  csvReports: PropTypes.array,
  facility: PropTypes.object.isRequired,
};

function mapStateToProps (state) {
  return {
    facility: getActiveFacility(state),
    csvReports: state[dataNames.csvReports],
  };
}

function mapDispatchToProps (dispatch) {
  const actions = {
    getUnpaginatedData
  };

  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CSVReportPage);