import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {push, goBack} from 'react-router-redux';
import {Link} from 'react-router';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import {Col, Row} from 'react-bootstrap';
import get from 'lodash.get';
import {ensureRegister} from '../../../../actions/registerActions';
import {ensureSalesLocation} from '../../../../actions/locationActions';
import * as dataNames from '../../../../constants/dataNames';
import * as itemNames from '../../../../constants/itemNames';
import * as apiActions from '../../../../actions/apiActions';
import * as dataActions from '../../../../actions/dataActions';
import * as itemActions from '../../../../actions/itemActions';
import {getPaymentModificationPaymentTypes} from '../../../../selectors/orderSettingsSelectors';
import {getIntegrationState} from '../../../../selectors/integration/integrationSelectors';
import {getNonMedicatedCategoryId} from '../../../../selectors/categorySelectors';
import {isFeatureEnabled} from '../../../../selectors/featureToggles';
import {
  canRefundAlleaves,
  getCurrentRegisterAlleavesRegisterId
} from '../../../../selectors/integration/thirdPartyIntegrationSelectors';
import FormWrapper from '../../../common/form/FormWrapper';
import OrderDetails from '../../customer/components/OrderDetails';
import RefundAndRestock from '../../customer/components/RefundAndRestock';
import RefundOrRestock from '../../customer/components/RefundOrRestock';
import InProgressOverlay from '../../../common/InProgressOverlay';
import WillRender from '../../../common/concealers/WillRender';


export class OrderView extends React.Component {

  constructor (props, context) {
    super(props, context);

    this.onRefundToggle = this.onRefundToggle.bind(this);
    this.onToggleRefundAndRestock = this.onToggleRefundAndRestock.bind(this);
    this.onRefundAllClick = this.onRefundAllClick.bind(this);
    this.onRestockToggle = this.onRestockToggle.bind(this);
    this.onRestockAllClick = this.onRestockAllClick.bind(this);
    this.onRemoveItemFromRestocking = this.onRemoveItemFromRestocking.bind(this);
    this.toggleQueue = this.toggleQueue.bind(this);
    this.onToggleBoth = this.onToggleBoth.bind(this);
    this.onRestockApply = this.onRestockApply.bind(this);
    this.onRefundApply = this.onRefundApply.bind(this);
    this.getDetailedOrder = this.getDetailedOrder.bind(this);
    this.removeProductFromRefundOrder = this.removeProductFromRefundOrder.bind(this);
    this.onRestockProduct = this.onRestockProduct.bind(this);
    this.onDeleteRestockProduct = this.onDeleteRestockProduct.bind(this);
    this.onApplyRestock = this.onApplyRestock.bind(this);
    this.onPaymentSubmit = this.onPaymentSubmit.bind(this);
    this.submitPayment = this.submitPayment.bind(this);
    this.printReceipt = this.printReceipt.bind(this);
    this.addTransactionInfoToOrder = this.addTransactionInfoToOrder.bind(this);
    this.setIsRefundAndRestock = this.setIsRefundAndRestock.bind(this);

    this.state = {
      // In prog message
      loadingMessage: 'Loading',
      translate: false,
      isActive: false,
      // all else
      refundQueue: [],
      restockQueue: [],
      refundItems: [],
      refundOrders: {},
      detailedOrders: [],
      isRefundAndRestock: false,
    };
  }

  componentDidMount(){
    this.initRedux();
    this.props.actions.getUnpaginatedData('/api/registers', dataNames.salesRegisters, {failed: 'registers.getRegisters.fail'}, {type: 'sales'});
    this.props.actions.getItem('/api/order_settings', itemNames.orderSettings);
    this.props.actions.getUnpaginatedData('/api/categories', dataNames.categories);
    this.props.actions.getUnpaginatedData('/api/prepack_weights', dataNames.prepackWeights);
    this.loadGroups();
    this.props.actions.getUnpaginatedData('/api/taxes', dataNames.taxes, {failed: 'taxes.get.failed'}, undefined, undefined, {debounce: true});
    this.loadOrders();
  }

  initRedux(){
    this.props.actions.unsetItem(itemNames.orderSettings);
    this.props.actions.unsetItem(itemNames.refundOrder);
    this.props.actions.unsetItem(itemNames.customer);
    this.props.actions.unsetItem(itemNames.order);
  }

  loadOrders(){
    this.props.actions.unsetItem(itemNames.order);
    return new Promise((resolve, reject) => {
      this.setState({
        refundItems: [],
        refundOrders: []
      }, () => {
        this.props.actions.getItem(`/api/orders/details/${this.props.params.id}`, itemNames.order)
          .then((order) => {

            if(order.consumer_id) this.loadCustomer(order.consumer_id);

            const refundItems = get(order, 'products', [])
              .reduce((acc, product) => { // get the inventory lines that have refunds on them
                const refunds = get(product, 'items', []).filter((item) => {
                  return Array.isArray(item.refunded_by) && item.refunded_by.length;
                });
                return acc.concat(refunds);
              }, []);

            this.setState({refundItems});

            const refundIds = refundItems.reduce((acc, refund) => { // get just the ids
              return acc.concat(refund.refunded_by.map((refundedBy) => {
                return refundedBy.order_id;
              }));
            }, []);

            const restockTransactionIds = order.products.reduce((acc, product) => {
              return acc.concat(product.items.reduce((acc, item) => {
                if(item.restock_transaction_id){
                  acc.push(item.restock_transaction_id);
                }
                return acc;
              }, []));
            }, []);

            const getRelatedRefundOrders = (refundIds) => {
              // Filter removes duplicates which, if present are wasteful and double refund counts
              refundIds.filter((value, index, self) => self.indexOf(value) === index).forEach((refundId) => {
                this.props.actions.getItem(`/api/orders/details/${refundId}`, null)
                  .then((refundOrder) => {
                    if(get(refundOrder, 'fulfillment_status') !== 'completed') return false; // Must be complete to be used
                    const key = `_${this.props.params.id}`;
                    const refundOrders = Object.assign({}, this.state.refundOrders);
                    if(refundOrders[key] === undefined){
                      refundOrders[key] = [];
                    }
                    refundOrders[key].push(refundOrder);
                    this.setState({refundOrders});
                    this.props.actions.unionData(refundOrder, dataNames.orders, 'id');
                  });
              });
              resolve();
            };

            const setDetailedOrder = (order) => {
              this.setState({detailedOrders: [order]});
            };

            if(restockTransactionIds.length){
              this.props.actions.getData('/api/item_transactions', dataNames.restockTransactions, null, {in_ids: restockTransactionIds})
                .then((transactions) => {
                  getRelatedRefundOrders(refundIds);
                  const orderWithRestocks = Object.assign({}, order, {
                    products: order.products.map((p) => {
                      return Object.assign({}, p, {
                        items: p.items.map((item) => {
                          if (!item.restock_transaction_id) return item;
                          const transaction = transactions.find((t) => t.id === item.restock_transaction_id);
                          if (transaction) {
                            return Object.assign({}, item, {restock_transaction: transaction});
                          }
                          return item;
                        })
                      });
                    })
                  });
                  setDetailedOrder(orderWithRestocks);
                });
            } else {
              getRelatedRefundOrders(refundIds);
              setDetailedOrder(order);
            }
          });
      });
    });
  }

  loadGroups(){
    const url = '/api/consumer_groups';
    return this.props.actions.getData(url, dataNames.customerGroups, {failed: I18n.t('customers.getGroups.failed')});
  }

  loadCustomer(customerId){
    const url = `/api/customers/${customerId}`;
    this.props.actions.getItem(url, itemNames.customer, {failed: I18n.t('customers.getCustomer.failed')}, {});
    // Get from search for queue data
    const params = {sort: 'id asc', query: '', size: 1, start: 0, filter: `id:${customerId}`};
    this.props.actions.getSearchData('/api/search/customers', dataNames.searchCustomer, {failed: 'customers.getCustomer.failed'}, params);
  }

  /********************************************
   * Refunds and Restocks
   */

  toggleQueue(queueString, row, event){
    if(event !== undefined) event.target.blur();
    const state = this.state[queueString];
    let newQueue = state.filter( (product) => {
      return product.id !== row.id;
    });
    if(newQueue.length === state.length){
      newQueue = state.concat([row]);
    }
    return this.setState({[queueString]: newQueue});
  }

  onToggleBoth(row, event){
    this.toggleQueue('refundQueue', row, event);
    this.toggleQueue('restockQueue', row, event);
    return true;
  }

  onRestockToggle(row, event){
    return this.toggleQueue('restockQueue', row, event);
  }

  onRestockProduct(event, product){
    event.stopPropagation();
    event.preventDefault();
    const quantity = product.uom === 'EA' ? 1 : product.qty;
    const queueProduct = Object.assign({}, product, {quantity});
    const restockQueue = this.state.restockQueue.map( (item) => item);
    restockQueue.push(queueProduct);
    this.adjustDetailedOrder(product, quantity);
    this.setState({restockQueue});
  }

  onDeleteRestockProduct(event, product){
    event.stopPropagation();
    event.preventDefault();
    const restockQueue = this.state.restockQueue.map( (item) => item);
    let removedItem = false;
    let restockCount = 0;
    const newQueue = restockQueue.filter( (item) => {
      if(!removedItem  && (item.id === product.id)) {
        removedItem = true;
        restockCount++;
        return false;
      }
      return item;
    });
    this.adjustDetailedOrder(product, restockCount * -1);
    this.setState({restockQueue: newQueue});
  }

  onApplyRestock(event) {
    if (!this.props.actions.ensureSalesLocation()) {
      return;
    }

    const restockQueue = this.state.restockQueue.reduce((acc, product) => {
      // NOTE: id is actually order_product_inventory_id
      const existingProduct = acc.find((p) => p.id === product.id);
      if(existingProduct){
        existingProduct.quantity += product.quantity;
      } else {
        acc.push(product);
      }
      return acc;
    }, []);

    this.setState({loadingMessage: I18n.t('customers.history.loadingMessages.restockingProducts'), isActive: true});

    const restockProduct = (product) => new Promise((resolve, reject) => {
      const payload = {
        order_product_id: product.order_product_id,
        inventory_location_id: this.props.currentLocation, // checked for validity by ensureSalesLocation in this method
        is_refund_restock: this.state.isRefundAndRestock,
      };
      if(product.items.length !== 0){
        payload.qty = product.quantity;
        payload.uom = product.uom;
        payload.order_product_inventory_id = product.id;
      }
      // This needs some error trapping
      this.props.actions.postItem('/api/orders/restocks', payload, null, {}, {}, (data) => {
        if(restockQueue.length > 0) {
          if(restockQueue.length === 1) {
            const detailedOrders = this.state.detailedOrders.map( (order) => order);
            const currentOrder = detailedOrders.find( (order) => order.id === product.order_id);
            const index = detailedOrders.indexOf(currentOrder);
            detailedOrders.splice(index, 1);
            this.setState({detailedOrders: detailedOrders, isActive: false, restockQueue: []});
            this.loadOrders().then(() => {
              this.addDetailedOrder(currentOrder);
            });
          }
          restockQueue.pop();
        }
        if(restockQueue.length === 0) {
          this.setState({restockQueue: restockQueue});
        }

        return resolve();
      }).catch(() => {
        this.setState({isActive: false});
      });

    });

    const iterator = restockQueue.map( (product) => restockProduct(product));

    this.setState({
      loadingMessage: 'Restocking Product(s)',
      isActive: true
    });

    Promise.all(iterator).then(() => {
      this.setState({
        isRefundAndRestock: false
      });
    });
  }

  afterOrdersLoaded(orders) {
    this.props.actions.unsetItem(itemNames.refundOrder);

    this.setState({
      isActive: false
    });

    return Promise.resolve(orders);
  }

  adjustDetailedOrder(product, plusOrMinus){

    const detailedOrders = this.state.detailedOrders.map( (product) => product);

    const order = detailedOrders.find( (order) => order.id === product.order_id);

    const foundProduct = order.products.reduce( (acc, p) => {
      if(p.id === product.order_product_id){
        if(product.items.length > 0){
          const item = product.items.find( (item) => {
            if(item.id === product.id){
              return item;
            }
          });
          if(acc.length === 0) acc.push(item);
          return acc;
        }
        if(acc.length === 0) acc.push(product);
        return acc;
      }
      return acc;
    }, []);

    if(foundProduct.length > 0){

      const adjustProduct = foundProduct.pop();

      if(adjustProduct.restockCount === undefined) adjustProduct.restockCount = 0;

      if(typeof plusOrMinus === 'string'){
        adjustProduct.restockCount = (plusOrMinus === 'up') ? adjustProduct.restockCount + 1 : adjustProduct.restockCount - 1;
      } else {
        adjustProduct.restockCount = adjustProduct.restockCount + plusOrMinus; // in this case it's a number - this is support for all or nothing
      }

      this.setState({detailedOrders: detailedOrders});

    }

  }

  onRestockApply(products){
    return true;
  }


  ////////////////////////////////////////////
  // Refund Specific Methods
  // - should be a single transaction but BE not set up that way
  //

  onToggleRefundAndRestock (event, product) {
    this.setIsRefundAndRestock().then(() => {
      this.onRefundToggle(event, product);
      this.onRestockProduct(event, product);
    });
  }

  onRefundToggle(event, product){

    event.stopPropagation();
    event.preventDefault();

    if(!Object.keys(this.props.refundOrder).length) return this.createRefundOrder(product);

    if(this.props.refundOrder.payments.length > 0) return this.createRefundOrder(product);

    return this.addProductToRefundOrder(product);

  }

  isOrderRefundPossible(order, products) {
    if (!(products && products.length && order && order.id)) {
      return false;
    }

    if (order.fulfillment_status !== 'completed') {
      return false;
    }

    if (this.props.integrationState.isBiotrack) {
      const refundOrder = get(this.state, `refundOrders._${order.order_id}`) || [];

      const hasMedicatedRefunds = refundOrder.some(o => (o.products || []).some(p => {
        const refundedProduct = products.find(product => product.id === p.refunded_order_product_id);
        return refundedProduct && refundedProduct.category_id !== this.props.nonMedicatedCategoryId;
      }));

      // For Biotrack, if an order has a medicated product refund, we do not allow more refund on that order.
      if (hasMedicatedRefunds) {
        return false;
      }
    }

    return true;
  }

  createRefundOrder(product){
    if (Object.keys(this.props.refundOrder).length) {
      if (this.props.refundOrder.payments.length === 0) {
        return this.addProductToRefundOrder(product);
      }
    }

    this.setState({
      loadingMessage: 'Creating Refund',
      isActive: true
    });

    const that = this;

    const payload = {
      consumer_id: this.props.customer.id ? this.props.customer.id : null,
      order_source: 'in_store',
      order_type: 'refund',
      refunded_order_id: product.order_id,
    };

    // include consumer caregivers in order payload for special Leaf PA validation (MJP-14265)
    const caregivers = get(this.props,'customer.caregivers',null);

    if (caregivers && caregivers.length) {
      payload.caregivers = caregivers;
      payload.caregiver_id = caregivers[0].id;
    }

    that.props.actions.postItem('/api/orders', payload, itemNames.refundOrder, {}, {}, (refundOrder) => {
      that.addProductToRefundOrder(product);
    });

  }

  addProductToRefundOrder(product){
    this.setState({
      loadingMessage: I18n.t('customers.history.loadingMessages.addingProductToRefunds'),
      isActive: true
    });

    const {prepackWeights} = this.props;
    const path = `/api/orders/${this.props.refundOrder.id}/products`;

    const payload = {
      item_non_taxable: get(product, 'item_non_taxable', 0),
      item_master_id: product.product_item_master_id,
      quantity: 1
    };

    if (product.prepack_weight_id) {
      const weight = prepackWeights.find(weight => weight.id === product.prepack_weight_id);
      payload.sold_weight = weight.weight;
      payload.sold_weight_uom =  product.sold_weight_uom;
    } else {
      payload.sold_weight = product.sold_weight;
      payload.sold_weight_uom = product.sold_weight_uom;
    }

    if (product.items.length === 0) { // Is not an inventory item
      payload.refunded_order_product_id = product.id;
    } else { // Is an inventory item
      payload.refunded_order_product_inventory_id = product.id;
    }

    this.props.actions.postItem(path, payload, itemNames.refundOrder, {}, {}, (order) => {
      this.setState({isActive: false});
    });
  }

  haveDetailedOrder(order){

    const detailedOrder = this.state.detailedOrders.find((detailed) => detailed.id === order.id);
    return (detailedOrder !== undefined);

  }

  addTransactionInfoToOrder(order) {
    const transactions = this.props.restockTransactions;
    return Object.assign({}, order, {
      products: order.products.map((p) => {
        return Object.assign({}, p, {
          items: p.items.map((item) => {
            if (!item.restock_transaction_id) return item;
            const transaction = transactions.find((t) => t.id === item.restock_transaction_id);
            if (transaction) {
              return Object.assign({}, item, {restock_transaction: transaction});
            }
            return item;
          })
        });
      })
    });
  }

  addDetailedOrder(order){

    if(this.haveDetailedOrder(order)) return true;

    this.setState({
      loadingMessage: I18n.t('customers.history.loadingMessages.gettingOrderDetails'),
      isActive: true
    });

    const doneLoading = () => {
      this.setState({isActive: false});
    };

    const detailedOrders = this.state.detailedOrders.concat([]);
    const url = `/api/orders/details/${order.id}`;
    this.props.actions.getItem(url, itemNames.order, {failed: 'Failed'}, {detailed: true}, (order) => {
      const orderWithRestocks = this.addTransactionInfoToOrder(order);
      detailedOrders.push(orderWithRestocks);
      this.setState({detailedOrders, isActive: false});
    }).then((order) => {}).catch(doneLoading);
  }

  getDetailedOrder(order){
    const detailedOrder = this.state.detailedOrders.find( (detailed) => detailed.id === order.id);
    return (detailedOrder) ? detailedOrder : false;
  }

  removeProductFromRefundOrder(product){

    const refundOrderId = this.props.refundOrder.id;

    this.setState({loadingMessage: I18n.t('customers.history.loadingMessages.removingProductFromRefunds'), isActive: true});

    const messages = {}; // {failed: 'productAddFailure', success: 'productAddSuccess'};
    const path = `/api/orders/${this.props.refundOrder.id}/products/${product.id}`;

    this.props.actions.deleteItem(path, itemNames.refundOrder, messages, {}, (data) => {

      if(data.products.length > 0) {
        this.setState({loadingMessage: I18n.t('customers.history.loadingMessages.updatingRefundDetails'), isActive: true});
        this.props.actions.getItem(`/api/orders/details/${data.id}`, itemNames.refundOrder, messages, {detailed: true}, (refundOrder) => {
          this.setState({isActive: false});
        });
      } else {
        this.setState({
          isActive: false,
          isRefundAndRestock: false
        });

        this.props.actions.postItem(`/api/orders/${refundOrderId}/cancel_order`, {}, null);
      }
    });
  }

  // Applies the payment closing the refund
  onRefundApply(event, type = 'cash'){
    if (!this.props.actions.ensureRegister()) {
      return;
    }

    this.setState({loadingMessage: I18n.t('customers.history.loadingMessages.finalizingRefund'), isActive: true});
    const messages = {};
    const path = `/api/orders/${this.props.refundOrder.id}/payments`;
    const refundPayload = {
      payment_type: type,
      operation_type: 'refund',
      amount: this.props.refundOrder.order_total,
      register_id: this.props.currentRegister,
      alleaves_register_id: this.props.currentRegisterAlleavesRegisterId
    };

    this.props.actions.postItem(path, refundPayload, null, messages, {}, () => {
      this.loadOrders().then(() => {
        this.props.actions.unsetItem(itemNames.refundOrder);
        this.setState({isActive: false, refundQueue: [], refundOrders: []});
      });
    });
  }

  onRestockAllClick(event, order, products) {
    event.stopPropagation();
    event.preventDefault();

    if (!products || !products.length) {
      return;
    }

    this.props.actions.setItem({
      value: true
    }, itemNames.restockOrderInitiated);

    const newRestockQueue = [];

    products.forEach((product) => {
      if (!product.restocked) {
        const queuedProduct = Object.assign({}, product, {
          quantity: product.qty
        });

        newRestockQueue.push(queuedProduct);
        this.adjustDetailedOrder(product, product.qty);
      }
    });

    this.setState({
      restockQueue: newRestockQueue
    });
  }

  onRefundAllClick(event, order, products) {
    event.stopPropagation();
    event.preventDefault();

    const orderId = products[0].order_id;

    if (!this.isOrderRefundPossible(order, products)) {
      return;
    }

    // Check if the order as any medicated restock
    const hasMedicatedRestocks = products.some((product) => {
      return product.category_id !== this.props.nonMedicatedCategoryId && product.items.some(item => item.restock_transaction);
    });

    const promises = [];

    // If the refund does not exist, we have to create it
    if ((!this.props.refundOrder || !Object.keys(this.props.refundOrder).length) ||
      (this.props.refundOrder.payments && this.props.refundOrder.payments.length > 0) ) {

      const payload = {
        consumer_id: this.state.customerId,
        order_source: 'in_store',
        order_type: 'refund',
        refunded_order_id: orderId,
      };

      const getOrder = () => {
        return new Promise((resolve, reject) => {
          this.props.actions.postItem('/api/orders', payload, itemNames.refundOrder, {})
            .then(() => {
              resolve();
            }).catch((error) => {
              reject();
            });
        });
      };

      promises.push(getOrder);
    }

    // Add the remaining qty for all the qualifying products in the order
    products.forEach(product => {
      // For Biotrack, does not allow refunding a medical product within an order that has medical restock
      if (this.props.isBiotrack && hasMedicatedRestocks && product.category_id !== this.props.nonMedicatedCategoryId) {
        return;
      }

      if (product.uom !== 'EA' && !product.prepack_weight_id && product.refunded > 0) {
        return ;
      }

      if (product.qty === undefined && product.quantity === product.refunded || !product.refundable) {
        return;
      }

      // is inventory item
      if (product.qty <= product.refunded || !product.refundable) {
        return;
      }

      promises.push(() => this.addRemainingQtyToRefundOrderAsAPromise(product));

    });

    // excecute Promise chain synchronously
    promises.reduce(
      (current, next, promiseIndex) => {
        return current.then(() => next());
      },
      Promise.resolve([])
    ).then(() => {});

    return true;
  }

  addRemainingQtyToRefundOrderAsAPromise (product) {
    return new Promise((resolve, reject) => {
      this.setState({
        loadingMessage: I18n.t('customers.history.loadingMessages.addingProductToRefunds'),
        isActive: true
      });

      const {prepackWeights} = this.props;
      const path = `/api/orders/${this.props.refundOrder.id}/products`;

      const payload = {
        item_non_taxable: get(product, 'item_non_taxable', 0),
        item_master_id: product.product_item_master_id,
        quantity: product.uom == 'EA' || product.prepack_weight_id ? product.qty - product.refunded : 1
      };

      if (product.prepack_weight_id) {
        const weight = prepackWeights.find(weight => weight.id === product.prepack_weight_id);
        payload.sold_weight = weight.weight * payload.quantity;
        payload.sold_weight_uom =  product.sold_weight_uom;
      } else {
        payload.sold_weight = product.sold_weight;
        payload.sold_weight_uom = product.sold_weight_uom;
      }

      if (product.items.length === 0){ // Is not an inventory item
        payload.refunded_order_product_id = product.id;
      } else { // Is an inventory item
        payload.refunded_order_product_inventory_id = product.id;
      }

      this.props.actions.postItem(path, payload, itemNames.refundOrder, {})
        .then( () => {
          this.setState({
            isActive: false,
          });
          resolve();
        })
        .catch(() => {
          this.setState({
            isActive: false,
          });
          reject();
        });
    });
  }

  onRemoveItemFromRestocking(event, product) {
    event.stopPropagation();
    event.preventDefault();

    const newRestockQueue = this.state.restockQueue.filter((item) => {
      return item.id !== product.id;
    });

    this.setState({
      restockQueue: newRestockQueue
    });
  }


  submitPayment(url, payload) {
    return new Promise( (resolve, reject) => {
      this.props.actions.postItem(
        url,
        payload,
        undefined,
        {failed: 'cultivation.orderHistory.actions.replacePayments.failed', success: 'cultivation.orderHistory.actions.replacePayments.success'},
        undefined,
        data => resolve(data)
      ).catch((data) => {
        reject(data);
      });
    });
  }

  onPaymentSubmit(formValues) {
    this.setState({
      loadingMessage: I18n.t('common.form.saving'),
      isActive: true
    });

    const oldLoadingMessage = this.state.loadingMessage;
    const doneLoading = () => {
      this.setState({
        loadingMessage: oldLoadingMessage,
        isActive: false
      });
    };
    const url = `/api/orders/${formValues.orderId}/replace_payments`;
    const payload = {
      refund_register_transactions: formValues.refund_register_transactions,
      payments: formValues.payments,
    };

    this.submitPayment(url, payload).then(doneLoading).catch(doneLoading);
  }

  printReceipt(event, order){
    event.preventDefault();
    event.stopPropagation();
    const newState = {orderId: order.id, printReceipt: true};
    this.setState(newState, () => {
      this.setState({printReceipt: false});
    });
  }

  setIsRefundAndRestock() {
    return new Promise(resolve => {
      this.setState({
        isRefundAndRestock: true,
      }, () => {
        resolve();
      });
    });
  }

  /***==============================================================================***/

  render(){

    const {integrationState, order, customer, isRefundAndRestockUnificationToggled, canRefundAlleaves} = this.props;

    const orderNumber = get(this.props.order, 'name', 'Loading...');
    return (<FormWrapper title={`${I18n.t('Order')} ${orderNumber}`} goBack={this.props.actions.goBack} localize={false}>
      <InProgressOverlay message={this.state.loadingMessage} isActive={this.state.isActive} translate={this.state.translate} />
      <WillRender ifTrue={order && order.order_type}>
        <Row>
          <Col md={3}>
            <div style={{marginLeft: '15px'}}>
              <h5><strong>Order Type:</strong> {I18n.t(`orders.status.${order.order_type}`) + ' - ' + I18n.t(`orders.status.${order.order_status}`)}</h5>
            </div>
          </Col>
          <Col md={6}>
            <div style={{marginLeft: '15px'}}>
              <h5>
                <strong>Customer: </strong>
                {customer && customer.id
                  ? (<React.Fragment>
                    {customer.first_name} {customer.last_name}
                    <Link style={{float: 'right', marginLeft: '10px', marginRight: '15px'}} to={`/patients/modify/${customer.id}`}>Profile</Link>
                    <Link style={{float: 'right', marginLeft: '10px'}} to={`/order-history/customers/${customer.id}`}>Order History</Link>
                  </React.Fragment>)
                  : 'Anonymous'}
                  </h5>
            </div>
          </Col>
          <Col md={3}>
            <div>
              <h5>
                <strong>Customer Type: </strong>
                {customer && customer.id
                  ? I18n.t(`patients.${customer.type}`)
                  : I18n.t(`patients.${order.consumer_type}`)}
              </h5>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={9}>
            <OrderDetails
              collapseSelf={() => {}}
              order={this.props.order}
              data={this.props.order.products || []}
              ordersWithRefunds={this.state.refundOrders}
              refundedOrderIds={[]}
              prepackWeights = {this.props.prepackWeights}
              refundQueue={this.state.refundQueue}
              onRefundClick={integrationState.isMetrc ? this.onToggleRefundAndRestock : this.onRefundToggle}
              nonMedicatedCategoryId={7}
              restockQueue={this.state.restockQueue}
              onRestockProduct={this.onRestockProduct}
              printReceipt={this.printReceipt}
              onRestockClick={this.onRestockToggle}
              onRefundAllClick={this.onRefundAllClick}
              onRestockAllClick={this.onRestockAllClick}
              refundOrder={this.props.refundOrder}
              page={1}
              first={1}
              last={1}
              getDetailedOrder={this.getDetailedOrder}
              numRecords={1}
              registers={this.props.registers}
              getPaymentModificationPaymentTypes={this.props.getPaymentModificationPaymentTypes}
              onPaymentSubmit={this.onPaymentSubmit}
              refundedInventoryItems={this.state.refundItems}
              taxes={this.props.taxes}
              integrationState={integrationState}
              setIsRefundAndRestock={this.setIsRefundAndRestock}
            />
          </Col>
          <Col md={3}>
            {integrationState.isBiotrack || isRefundAndRestockUnificationToggled ?
              <RefundAndRestock
                onQueueClick={this.onRefundToggle}
                onApplyClick={this.onRefundApply}
                onDeleteRefund={this.removeProductFromRefundOrder}
                onApplyRestock={integrationState.isBiotrack ? this.showRefundRestockModal : this.onApplyRestock}
                onApplyRefund={this.onRefundApply}
                onDeleteRestockProduct={this.onDeleteRestockProduct}
                integrationState={integrationState}
                queue={this.state.restockQueue}
                refundOrder={this.props.refundOrder}
                onRemoveItemFromRestocking={this.onRemoveItemFromRestocking}
                isRefundAndRestockUnificationToggled={isRefundAndRestockUnificationToggled}
                canRefundAlleaves={canRefundAlleaves}
              />
              :
              <React.Fragment>
                <RefundOrRestock
                  isRefundContainer={true}
                  queue={this.state.refundQueue}
                  queueProperty='products'
                  onQueueClick={this.onRefundToggle}
                  onApplyClick={this.onRefundApply}
                  onApplyRefund={this.onRefundApply}
                  onDeleteRefund={this.removeProductFromRefundOrder}
                  refundOrder={this.props.refundOrder}
                  integrationState={integrationState}
                  onRemoveItemFromRestocking={this.onRemoveItemFromRestocking}
                  title='Refund Products'
                  buttonText='Do Refund'
                  canRefundAlleaves={canRefundAlleaves}
                />
                <RefundOrRestock
                  isRefundContainer={false}
                  queue={this.state.restockQueue}
                  onQueueClick={this.onRestockToggle}
                  onApplyRestock={this.onApplyRestock}
                  onApplyClick={this.onRestockApply}
                  onApplyRefund={this.onRefundApply}
                  onDeleteRestockProduct={this.onDeleteRestockProduct}
                  integrationState={integrationState}
                  onRemoveItemFromRestocking={this.onRemoveItemFromRestocking}
                  title='Re-Stock Products'
                  buttonText='Do Re-Stock'
                  canRefundAlleaves={canRefundAlleaves}
                />
              </React.Fragment>
            }
          </Col>
        </Row>
      </WillRender>
    </FormWrapper>);
  }

}

OrderView.propTypes = {
  actions: PropTypes.object,
  params: PropTypes.object,
  order: PropTypes.object,
  taxes: PropTypes.array,
  registers: PropTypes.array,
  getPaymentModificationPaymentTypes: PropTypes.func,
  integrationState: PropTypes.object,
  prepackWeights: PropTypes.array,
  refundOrder: PropTypes.object,
  currentLocation: PropTypes.object,
  currentRegister: PropTypes.object,
  restockTransactions: PropTypes.array,
  customer: PropTypes.object,
  nonMedicatedCategoryId: PropTypes.number,
  isBiotrack: PropTypes.bool,
  isRefundAndRestockUnificationToggled: PropTypes.bool.isRequired,
  canRefundAlleaves: PropTypes.bool.isRequired,
  currentRegisterAlleavesRegisterId: PropTypes.string
};

function mapStateToProps(state) {
  const {user:{currentLocation, currentRegister}, salesRegisters, prepackWeights, taxes, refundOrder} = state;
  return {
    order: state[itemNames.order],
    refundOrders: state[dataNames.orders],
    prepackWeights,
    taxes,
    currentLocation,
    currentRegister,
    refundOrder,
    registers: salesRegisters,
    getPaymentModificationPaymentTypes: order => getPaymentModificationPaymentTypes(state, order),
    integrationState: getIntegrationState(state),
    restockTransactions: state[dataNames.restockTransactions],
    customer: state[itemNames.customer],
    nonMedicatedCategoryId: getNonMedicatedCategoryId(state),
    isRefundAndRestockUnificationToggled: isFeatureEnabled(state)('feature_refund_and_restock_unification'),
    canRefundAlleaves: isFeatureEnabled(state)('feature_alleaves_payment_integration') && canRefundAlleaves(state),
    currentRegisterAlleavesRegisterId: getCurrentRegisterAlleavesRegisterId(state)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, {push, goBack}, apiActions, dataActions, itemActions, {ensureRegister, ensureSalesLocation});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderView);
