import {change, formValueSelector, touch} from 'redux-form';

import {isFormChangeOnField} from './utils';


const reconciliationForm = store => next => action => {

  const result = next(action);
  const state = store.getState();
  const {payload, meta} = action;
  const formName = 'reconciliation';

  if(state.form[formName]){

    if (isFormChangeOnField(action, [formName], 'quantity', true)) {

      const triggerField = 'quantity';
      const formSelector = formValueSelector(formName);
      const origQty = formSelector(state, meta.field.replace(triggerField , 'qty'));
      const prepackMultiplier = ( formSelector(state, meta.field.replace(triggerField , 'prepack_weight')) || 1);
      const discrepancy = (payload === '') ? '' : Math.round(10000 * ((payload - origQty) * prepackMultiplier)) / 10000;
      store.dispatch(change(meta.form, meta.field.replace(triggerField , 'discrepancy'), discrepancy));
      store.dispatch(touch(meta.form, meta.field.replace(triggerField, 'adjustment_reason')));
    }

  }
  return result;
};

export default reconciliationForm;
