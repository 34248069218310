import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Row, Button, ButtonToolbar} from 'react-bootstrap';
import PermissionWrapper from '../common/PermissionWrapper';
import * as p from '../../constants/permissions';
import CartTotalsTable from './CartTotalsTable';

const PaymentTotals = ({subTotal, printReceipt, printAllLabels, couponsTotal, tax, taxableTotal, manualDiscountsTotal, total, taxRate,
                         paymentComplete, pageDisplayType, validCureLimits, isOrderFulfilled, order, onPaymentOrderClick, viewReceipt, showPrinterSettings, taxes, printBeforePayment, editOrder,
                         submitInProgress, cancelOrder, onCancelOrder, confirmCancel, editFulfillment, printJob}) => {

  const isPaymentAllow = order.fulfillment_method !== 'delivery' || order.delivery_address_id;

  return (
    <div style={{border: 'solid 1px #ccc', padding: '10px'}}>
      <Row>
        <Col md={12}>
          <ButtonToolbar className='float-right'>
            {
              !confirmCancel
                ? <Button style={{marginRight: '6px'}} variant='warning' onClick={onCancelOrder} disabled={submitInProgress || paymentComplete}>
                  {I18n.t('cart.totals.cancelOrder')}
                </Button>
                : <Button style={{marginRight: '6px'}} variant='danger' onClick={cancelOrder} disabled={submitInProgress || paymentComplete}>
                  {I18n.t('cart.payment.confirmCancel')}
                </Button>
            }

            <Button style={{marginRight: '6px'}} onClick={editOrder} disabled={submitInProgress || paymentComplete}>
              {I18n.t('customer.productsMenu')}
            </Button>
            <Button style={{marginRight: '6px'}} onClick={editFulfillment} disabled={submitInProgress || paymentComplete}>
              {I18n.t('cart.totals.editFulfillment')}
            </Button>

            {(pageDisplayType === 'delivery') && (
              <Button style={{marginRight: '6px'}} onClick={onPaymentOrderClick} disabled={!(validCureLimits && isOrderFulfilled && isPaymentAllow)}>
                {I18n.t('cart.totals.payment')}
              </Button>
            )}
          </ButtonToolbar>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <h2 style={{marginTop: '10px', borderTop: 'solid 1px #eee'}}>
            {I18n.t('cart.totals.cartTotals')}
          </h2>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <CartTotalsTable
            subTotal={subTotal}
            couponsTotal={couponsTotal}
            manualDiscountsTotal={manualDiscountsTotal}
            taxableTotal={taxableTotal}
            taxes={taxes}
            total={total}
          />
        </Col>
        <PermissionWrapper permissions={[p.print_labels]}>
          <Col md={6}>
            <Button disabled={!paymentComplete && !printBeforePayment} onClick={(e) => printAllLabels(e, true)} variant='primary' className='btn-block'>{I18n.t('cart.totals.printAllLabels')}</Button>
            <Button disabled={!paymentComplete && !printBeforePayment} onClick={printReceipt} variant='primary' className='btn-block'>{I18n.t('cart.totals.printReceipt')}</Button>
            <hr style={{marginBottom: '5px'}} />
            {
              !Object.keys(printJob).length
                ? null
                : (<div>
                  <div style={{clear:'both'}} />
                  <div className='progress progress-striped active' style={{marginBottom: '0px'}}>
                    <div style={{width: '100%'}} className='progress-bar'  role='progressbar' aria-valuenow='100' aria-valuemin='0' aria-valuemax='100' />
                  </div>
                  <div style={{textAlign: 'center', fontSize: 'smaller'}} className='text-muted'>
                    <div>Sending Print Job To Printer...</div>
                  </div>
                </div>)
            }
            <hr style={{marginTop: '5px'}} />
            <Button disabled={!paymentComplete && !printBeforePayment} onClick={(e) => printAllLabels(e, false)} variant='primary' className='btn-block'>{I18n.t('cart.totals.viewPrintAllLabels')}</Button>
            <Button disabled={!paymentComplete && !printBeforePayment} onClick={viewReceipt} variant='primary' className='btn-block'>{I18n.t('cart.totals.viewPrintReceipt')}</Button>
          </Col>
        </PermissionWrapper>
      </Row>
    </div>
  );
};

PaymentTotals.propTypes = {
  pageDisplayType: PropTypes.string.isRequired,
  validCureLimits: PropTypes.bool,
  isOrderFulfilled: PropTypes.bool,
  order: PropTypes.object.isRequired,
  onPaymentOrderClick: PropTypes.func.isRequired,
  showPrinterSettings: PropTypes.func.isRequired,
  viewReceipt: PropTypes.func.isRequired,
  paymentComplete: PropTypes.bool.isRequired,
  subTotal: PropTypes.number,
  tax: PropTypes.number.isRequired,
  taxableTotal: PropTypes.number,
  manualDiscountsTotal: PropTypes.number,
  couponsTotal: PropTypes.number,
  total: PropTypes.number,
  taxRate: PropTypes.number.isRequired,
  printReceipt: PropTypes.func,
  printAllLabels: PropTypes.func,
  taxes: PropTypes.array,
  orderNote: PropTypes.string,
  onOrderNoteBlur: PropTypes.func,
  onOrderNoteSave: PropTypes.func,
  haveUnsavedNote: PropTypes.bool,
  printBeforePayment: PropTypes.bool.isRequired,
  editOrder: PropTypes.func.isRequired,
  submitInProgress: PropTypes.bool,
  cancelOrder: PropTypes.func.isRequired,
  confirmCancel: PropTypes.bool,
  editFulfillment: PropTypes.func,
  onCancelOrder: PropTypes.func.isRequired,
  addresses: PropTypes.array,
  printJob: PropTypes.object,
};

export default PaymentTotals;
