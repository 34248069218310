import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row, Button, ButtonGroup, Card} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import NotificationDetails from './NotificationDetails';


const PriorityNotifications = ({notifications, formatDateTime, markAsRead, processing}) => {
  return (
    <div className='notification-details'>
      {notifications.map(notification => <Row key={notification.id}>
        <Col xs={12}>
          <Card>
            <h4 style={{paddingLeft: '10px'}}>{notification.subject}</h4>
            <NotificationDetails notification={notification} formatDateTime={formatDateTime}/>
          </Card>
        </Col>
        </Row>)}
        <hr/>
        <Row>
          <Col xs={12}>
            <ButtonGroup className='float-right'>
              <Button variant={'primary'} onClick={markAsRead}>
                {processing ? I18n.t('notifications.actions.acknowledging') : I18n.t('notifications.actions.acknowledge')}
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
    </div>
  );
};

PriorityNotifications.propTypes = {
  notifications: PropTypes.array.isRequired,
  formatDateTime: PropTypes.func.isRequired,
  markAsRead: PropTypes.func.isRequired,
  processing: PropTypes.bool
};

export default PriorityNotifications;
