import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Button, Col, Row} from 'react-bootstrap';
import {pick} from 'lodash';
import SettingsPanel from './SettingsPanel';

const SettingPanels = (props) => {

  const form = pick(props, 'handleSubmit', 'change', 'valid', 'pristine', 'submitting');

  return (
    <form onSubmit={form.handleSubmit}>
      <Row className='label-setting-panels'>
        {
          props.panels.map((panel, index) => {
            return (
              <div className='col-md-6' key={index} style={index % 2 === 0 ? {clear:'both'} : {}}>
                <SettingsPanel title={panel.type} settings={panel.settings} {...form} />
              </div>
            );
          })
        }
        <Col xs={12}>
          <hr />
          <div className='float-right'>
            <Button
              variant='primary'
              type='submit'
              style={{marginRight: '5px'}}
              onClick={(event) => {
                form.change('afterSubmit', 'stay');
              }}
              disabled={form.submitting || form.pristine || !form.valid}
            >
              {I18n.t('common.form.saveAndStay')}
            </Button>

            <Button
              variant='primary'
              type='submit'
              disabled={form.submitting || form.pristine || !form.valid}
            >
              {I18n.t('common.form.saveAndExit')}
            </Button>
          </div>
        </Col>
      </Row>
    </form>
  );

};

SettingPanels.propTypes = {
  panels: PropTypes.array,
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default SettingPanels;
