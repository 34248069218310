import { I18n } from 'react-redux-i18n';
import { EMAIL_REG_EXP } from '../common/form/validationRegExs';
import { requiredFieldValidation } from '../common/form/redux-form/validations';

export const validate = (values, props) => {
  const errors = {};
  const {
    integrationState: { isPaLeaf },
    hasBiotrackFacilities,
    userRequiresStateLicense
  } = props;

  if (hasBiotrackFacilities) {
    errors.state_integration_id = requiredFieldValidation(values.state_integration_id);
    errors.birth_date = requiredFieldValidation(values.birth_date);
    errors.hire_date = requiredFieldValidation(values.hire_date);
    errors.employee_id = requiredFieldValidation(values.employee_id);
  }
  // NOTE: username is labeled name on back end

  if (values.is_create) {
    if (!values.name) {
      errors.name = I18n.t('userAccounts.form.enterUsernameError');
    }
  } else {
    // is_create = undefined and is update

    if (!values.name) {
      errors.name = I18n.t('userAccounts.form.enterUsernameError');
    }
    if (values.password && values.password.length < 8) {
      errors.password = I18n.t('userAccounts.form.passwordMustBeLongerThanEightCharacters');
    }
  }

  if (!values.email) {
    errors.email = I18n.t('userAccounts.form.enterEmailError');
  } else if (!EMAIL_REG_EXP.test(values.email)) {
    errors.email = I18n.t('userAccounts.form.invalidEmailError');
  }

  if (!values.state_id && userRequiresStateLicense) {
    errors.state_id = I18n.t('userAccounts.form.stateLicenseRequired');
  }

  if (values.email !== values.confirm_email) {
    errors.confirm_email = I18n.t('userAccounts.form.emailMatchError');
  }

  if (!values.first_name) {
    errors.first_name = I18n.t('userAccounts.form.firstNameError');
  }

  if (!values.last_name) {
    errors.last_name = I18n.t('userAccounts.form.lastNameError');
  }
  if (!(values.active === 1 || values.active === 0)) {
    errors.active = I18n.t('userAccounts.form.selectStatus');
  }

  // If leafPa and create mode we don't check for roles - they are not assigned until later
  if (!isPaLeaf || props.isModify) {
    // Ensure at least one role selected
    const noRolesAssigned =
      values.facilityRoles &&
      values.facilityRoles.length &&
      values.facilityRoles.reduce((state, f) => {
        return !state ? state : f.role_ids.length === 0;
      }, true);

    // Set message on all if none selected and there are multiples
    // Affiliated only users don't need to have any user roles assigned (Cleared in create/update payload)
    if (noRolesAssigned && values.user_type !== 'affiliated_only') {
      let msg = I18n.t('userAccounts.requiresRole');
      if (values.facilityRoles.length > 1) {
        msg = I18n.t('userAccounts.requiresAtLeastOneRole');
      }
      errors.facilityRoles = [];

      values.facilityRoles.forEach((f, index) => {
        errors.facilityRoles[index] = {};
        errors.facilityRoles[index].role_ids = msg;
      });
    }
  }

  if (
    values.image &&
    values.image[0] &&
    values.image[0].type !== 'image/png' &&
    values.image[0].type !== 'image/jpeg'
  ) {
    errors.image = I18n.t('error.imageFormat');
  }

  return errors;
};

export default validate;
