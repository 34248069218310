import React from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

import {
  INTEGRATION_LOCK_STYLES
} from '../../constants/integration/entityLocks';
import {getUseEntityLocksForItems} from '../../selectors/coreSettingsSelectors';



const LotIntegrationTrackingStatus = (props) => {
  if (!props.lot) {
    return '';
  }
  const {
    lot: {
      state_integration_tracking_id,
      highest_ranked_entity_lock,
    },
    useEntityLocks
  } = props;

  if (!useEntityLocks || !highest_ranked_entity_lock) {
    return <span>{state_integration_tracking_id}</span>;
  }

  const SVGComponent = INTEGRATION_LOCK_STYLES[highest_ranked_entity_lock.lock_reason].svgComponent;

  return (
    <OverlayTrigger
      delay={100}
      placement='top'
      overlay={
        <Tooltip>
          {I18n.t('integration.transactions.trackingIdStatuses.lotLevelWarning')}
        </Tooltip>
      }
    >
      <div className='tracking-id-warning-and-text'>
        <div>
          <SVGComponent fillColor={INTEGRATION_LOCK_STYLES[highest_ranked_entity_lock.lock_reason].fillColor} />
        </div>
        {
          state_integration_tracking_id &&
          <div>
            {state_integration_tracking_id}
          </div>
        }
      </div>
    </OverlayTrigger>
  );
};

const mapStateToProps = (state) => ({
  useEntityLocks: getUseEntityLocksForItems(state)
});


export default connect(mapStateToProps)(LotIntegrationTrackingStatus);
