export const CREATE_PLANT_FORM = 'createPlantForm';
export const MODIFY_PLANT_FORM = 'modifyPlantForm';
export const MANAGE_USER_ROLES = 'manageUserRolesForm';
export const SALES_SETTINGS_FORM = 'retailSalesSettings';
export const MINIMUM_DAYS_SUPPLY_FORM = 'minimumDaysSupplyForm';
export const PRODUCT_FORM = 'productForm';
export const PRODUCT_MASTER_FORM = 'productMasterForm';
export const PRICING_MULTIPLIER_FORM = 'pricingMultiplierForm';
export const PRODUCT_LIST_FORM = 'productListForm';
export const FACILITY_GROUP_FORM = 'facilityGroupForm';
export const CUSTOMER_GROUP_FORM = 'customerGroupForm';
export const PLANT_COMPLIANCE_FORM = 'plantCompliance';
export const WASTE_COMPLIANCE_FORM = 'waste-compliance';
export const COMPLETE_PROCESSING_FORM = 'completeProcessing';
export const START_INFUSION_FORM = 'startInfusionForm';
export const START_PROCESSING_FORM = 'startProcessingForm';
export const COMPLETE_INFUSION_FORM = 'completeInfusionForm';
export const MODIFY_PACKAGES = 'modifyPackages';
export const DESTROY_PACKAGES_FORM = 'destroyPackagesForm';
export const SPLIT_PACKAGE_FORM = 'splitPackageForm';
export const POS_PAYMENT_FORM = 'payment-form';
export const SUPPLY_CHAIN_MAPPING = 'SupplyChainMappingForm';
export const SUPPLY_CHAIN_MAPPING_FORM_METRC = 'SupplyChainMappingFormMetrc';
export const INVENTORY_RECEIPT_FORM_METRC = 'InventoryReceiptFormMetrc';
export const SALES_ORDER_FORM = 'orderForm';
export const PURCHASE_ORDER_FORM = 'purchaseOrderForm';
export const PARTNER_FORM = 'partnerForm';
export const MODIFY_HARVEST_BATCH_FORM = 'modifyHarvestBatchForm';
export const SETUP_STORAGE_LOCATIONS_FORM = 'setupStorageLocations';
export const SETUP_SALES_LOCATIONS_FORM = 'salesLocations';
export const PHYSICIAN_FORM = 'physicianForm';
export const CHECK_IN_CURE_API = 'checkInCureAPI';
export const CUSTOMER_FORM = 'customer';
export const USER_FORM = 'user_account';
export const PREPACK_WEIGHT_FACILITY_FORM = 'prepackWeightsFacility';
export const COMPLETE_EACH_PACKAGES_FORM = 'completeEachPackages';
export const PACKAGING_JOB_FORM = 'packagingJobForm';
export const PRINT_SERVER_FORM = 'printServerForm';
export const CREATE_HARVEST_PACKAGES = 'createHarvestPackages';
export const TAX_PROFILE_FORM = 'taxProfileForm';
export const HARVEST_BATCH_SPLIT_FORM = 'harvestBatchSplitForm';
export const DAILY_WASTE_FORM = 'dailyWasteForm';
export const PACKAGE_PLANTS_FORM = 'packagePlantsForm';
export const PACKAGE_MOTHER_PLANTS_FORM = 'packageMotherPlantsForm';
export const PLANT_SPLIT_FORM = 'plantSplitForm';
export const IMPORT_ORDERS_FORM = 'importOrderForm';
export const IMPORT_ORDERS_PAGE_FILTER_FORM = 'importOrderPageFilterForm';
export const CREATE_FACILITY_GROUP_FORM = 'createFacilityGroup';
export const MODIFY_FACILITY_GROUP_FORM = 'modifyFacilityGroup';
export const CATEGORY_MANAGEMENT_FORM = 'categoryManagementForm';
export const PRODUCT_MASS_MODIFY = 'productMassModifyForm';
export const PRODUCT_MULTI_SEARCH_FORM = 'search-products-toolbar';
export const CONNECTS_REQUEST_FORM = 'connectsRequestForm';
export const CONNECTS_PRODUCT_MAPPING_FORM = 'connectsProductMappingForm';
export const ANONYMOUS_CUSTOMER_FORM = 'anonymousCustomerForm';
export const STATE_INTEGRATION_SETTINGS_FORM = 'stateIntegrationSettings';
export const COMPLETE_PACKAGE_FORM = 'completePackage';
export const CREATE_DRIVER_FORM = 'createDriverForm';
export const TRANSACTION_SEARCH_FORM = 'transactionSearchForm';
export const INVENTORY_SEARCH_FORM = 'inventorySearchForm';
export const PRODUCT_SALES_SEARCH_FORM = 'productSalesSearchForm';
export const METRC_SALES_SEARCH_FORM = 'metrcSalesSearchForm';
export const INVENTORY_SYNC_SEARCH_FORM = 'inventorySyncSearchForm';
export const INVENTORY_SYNC_INTEGRATOR_MODAL = 'inventorySyncIntegratorModal';
export const INVENTORY_SYNC_MJP_MODAL = 'inventorySyncMjpModal';
export const INVENTORY_SYNC_PREPACK_MODAL = 'inventorySyncPrepackModal';
export const PARTNER_CONTACTS = 'partnerContacts';
export const FEEDING_SCHEDULE_FORM = 'feedingScheduleForm';
export const INVENTORY_RECEIPT_FORM = 'receive-transfer';
export const HARVEST_PLANTS_FORM = 'harvest_plants';
export const CREATE_PROPAGATION_MATERIAL_FORM = 'createPropagationMaterialForm';
export const REGISTER_FORM = 'registerForm';
export const REGISTER_RECONCILIATION_FORM = 'registerReconciliationForm';
export const TRANSFER_CASH_FORM = 'transferCashForm';
export const API_TRANSACTIONS_FORM = 'API_Transactions';
export const INTERNAL_TRANSFERS_SETTINGS_FORM = 'internalTransfersSettingsForm';
export const INTERNAL_TRANSFER_FORM = 'internalTransferForm';
export const PRODUCT_MANAGEMENT_FORM = 'productManagementForm';
export const CREATE_PRICING_GROUP_FORM = 'createPricingGroupForm';
export const MODIFY_PRICING_GROUP_FORM = 'modifyPricingGroupForm';
export const CUPO_FORM = 'cupoFrom';
export const CUPO_SETTINGS_FORM = 'cupoSettingsFrom';
export const CREATE_ASSEMBLY_FORM = 'createAssembly';
export const ASSEMBLY_FORM = 'assemblyForm';
export const INTERNATIONAL_SETTINGS = 'InternationalSettingsForm';
export const PRODUCT_MENU = 'productMenu';
export const CART_DRAWER = 'cartDrawer';
export const PROCESSING_TYPE_FORM = 'ProcessingTypesForm';
export const PREFERRED_MIX_FORM = 'PreferredMixForm';
export const CREATE_COUPON_FORM = 'createCoupon';
export const MODIFY_COUPON_FORM = 'modifyCoupon';
export const LINK_METRC_MANIFEST_FORM = 'linkMetrcManifestForm';
export const CASH_ON_HAND_REPORT = 'cashOnHandReport';
export const INVENTORY_TRANSACTIONS_REPORT = 'inventoryTransactionsReport';
export const REGISTER_CLOSING_ACTIVITY_FORM = 'registerClosingActivityForm';
export const DELIVERY_ORDER_FORM = 'deliveryOrderForm';
export const DELIVERY_MANIFEST_FORM = 'deliveryManifestForm';
export const REWARD_POINTS_FORM = 'rewardPointsForm';
export const ANALYTICS_REPORTS_SEARCH_FORM = 'analyticsReportsSearchForm';
export const TEST_RESULTS_EXPORT_FORM = 'testResultsExportForm';
export const DELIVER_ORDER_SETUP_FORM = 'deliverOrderSetupForm';
export const ADD_TEST_RESULTS_FORM = 'addTestResults';
export const MOD_TEST_RESULTS_FORM = 'modifyTestResults';
export const MERCHANT_PROFILE = 'merchant_profile';
export const FACILITY_INITIAL_INVENTORY_FORM = 'facilityInitialInventoryForm';
export const INVENTORY_AUDIT_FORM = 'inventoryAuditForm';
