/* eslint-disable import/no-named-as-default */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button, Row} from 'react-bootstrap';

import * as apiActions from '../../actions/apiActions';
import ScanInputModal from './ScanInputModal';

export class ScanInputTestPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {showScanModal: false, results: ''};
    this.showScanModal = this.showScanModal.bind(this);
    this.hideScanModal = this.hideScanModal.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  showScanModal() {
    this.setState({showScanModal: true});
  }

  hideScanModal() {
    this.setState({showScanModal: false});
  }

  handleKeyPress(parsedData) {
    if (parsedData) {
      this.setState({showScanModal: false, results: parsedData});
    }
  }

  render() {
    return (
      <Row>
        <h3>Scan Test</h3>
        <p>Name: {this.state.results.name ? this.state.results.name.first + ' ' + this.state.results.name.middle + ' ' + this.state.results.name.last : ''}</p>
        <p>Address: {this.state.results.address}</p>
        <p>City: {this.state.results.city}</p>
        <p>State: {this.state.results.state}</p>
        <p>Zip: {this.state.results.postal_code}</p>
        <p>Birthday: {this.state.results.birthday}</p>
        <p>Gender: {this.state.results.sex}</p>
        <Button type='button' onClick={this.showScanModal}>Scan</Button>
        <ScanInputModal showModal={this.state.showScanModal} handleKeyPress={this.handleKeyPress} hideModal={this.hideScanModal} />
      </Row>
    );
  }
}

ScanInputTestPage.propTypes = {
  actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps (dispatch) {
  const actions = Object.assign({}, apiActions);
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ScanInputTestPage);
