export const formatValueByUOM = (newUOM, qty_form, org_cost, default_uom) => {

  const qty = qty_form && parseFloat(qty_form);
  const newCost = org_cost && parseFloat(org_cost);
  let org_value = qty * newCost;

  const gr = 'GR';
  const kg = 'KG';
  const mg = 'MG';
  const l = 'L';
  const ml = 'ML';

  // TODO: Validate invalid formatting (like volume to weight)

  if(default_uom && newUOM && default_uom === newUOM) return org_value;
  if(qty_form === '') return 0;
  switch(default_uom){
  case mg:
    if(newUOM === gr) org_value *= 1000;
    if(newUOM === kg) org_value *= 1000 * 1000;
    break;
  case gr:
    if(newUOM === mg) org_value /= 1000;
    if(newUOM === kg) org_value *= 1000;
    break;
  case kg:
    if(newUOM === mg) org_value /= 1000 * 1000;
    if(newUOM === gr) org_value /= 1000;
    break;
  case ml:
    if(newUOM === l) org_value *= 1000;
    break;
  case l:
    if(newUOM === ml) org_value /= 1000;
    break;
  }

  return org_value;
};

export default formatValueByUOM;
