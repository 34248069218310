import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import moment from 'moment';
import {goBack} from 'react-router-redux';
import {change, formValueSelector, touch} from 'redux-form';

import {formatDate} from '../../util/dateHelpers';
import * as apiActions from '../../actions/apiActions';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import {CREATE_DRIVER_FORM} from '../../constants/forms';

import {getIntegratedOrApprovedUsers} from '../../selectors/driversSelectors';
import {getIntegrationState} from '../../selectors/integration/integrationSelectors';
import {isOnfleetIntegrator} from '../../selectors/integration/thirdPartyIntegrationSelectors';

import InProgressOverlay from '../common/InProgressOverlay';
import FormWrapper from '../common/form/FormWrapper';
import CreateDriverForm from './CreateDriverForm';
import {getCurrentFacilityCountry} from '../../selectors/facilitiesSelectors';
import {isMetrcDelivery} from '../../selectors/integration/metrcSelectors';
import {getInternationalPhoneCountryCode, getInternationalPhoneNumber} from '../../util/internationalHelpers';

export class CreateDriverPage extends React.PureComponent{
  constructor(props){
    super(props);
    this.addLoadingOverlay = this.addLoadingOverlay.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeDefaultVehicle = this.onChangeDefaultVehicle.bind(this);
    this.onUserChange = this.onUserChange.bind(this);
    this.setFormField = this.setFormField.bind(this);
  }

  componentWillMount() {
    this.props.actions.getUnpaginatedData('/api/facilities', dataNames.facilities);
    this.props.actions.getUnpaginatedData('/api/vehicles', dataNames.vehicles);
    this.props.actions.getUnpaginatedData('/api/onfleet/teams', dataNames.onfleetTeams);
    this.props.actions.getItem('/api/integration-settings', itemNames.integrationSettings, {failed: 'stateIntegratorSettings.get.failed'});
    this.state = {
      loading: false
    };
  }

  componentDidMount() {
    this.props.actions.getUnpaginatedData('/api/users/current_organization', dataNames.users, {}, {
      with_integration_ids: get(this.props, 'integrationState.isPaLeaf') ? 1 : 0
    });
  }

  onSubmit(data){
    this.addLoadingOverlay();

    const driver_phones = [];

    data.driver_phones.forEach ((item, index) => {
      const dp = {};
      dp.phone_country_code = getInternationalPhoneCountryCode(item.number);
      dp.number = getInternationalPhoneNumber(item.number);
      driver_phones.push(dp);
    });

    data.driver_phones = driver_phones;

    const postData = {
      ...data,
      birth_date: formatDate(data.birth_date),
    };

    this.setState({
      loading: false,
    });

    this.props.actions.postItem(
      '/api/drivers',
      postData,
      itemNames.driver,
      {
        success: 'cultivation.drivers.add.success',
        failed: 'cultivation.drivers.add.fail'
      },
      null,
      () => {
        this.setState({
          loading: false,
        });
        this.props.actions.goBack();
      }
    );
  }

  addLoadingOverlay () {
    this.setState({
      loading: true
    });
  }

  setFormField(field,value) {
    this.props.actions.change(CREATE_DRIVER_FORM,field,value);
    this.props.actions.touch(CREATE_DRIVER_FORM,field);
  }

  onUserChange(value){
    let chosenUser = {};
    this.props.users.map((user) => {
      user.id === value ? chosenUser = user : chosenUser;
    });
    // set birth date
    const chosenUserBirthDate = moment(chosenUser.birth_date);
    const birthDate = chosenUserBirthDate.isValid() ? chosenUserBirthDate : '';
    this.setFormField('birth_date',birthDate);
    this.setFormField('first_name', chosenUser.first_name || '');
    this.setFormField('middle_initial', chosenUser.middle_name || '');
    this.setFormField('last_name', chosenUser.last_name || '');
    this.setFormField('state_compliance_number', chosenUser.state_compliance_number || '');
    this.setFormField('drivers_license_number', chosenUser.drivers_license_number || '');
    this.setFormField('street_address_1', chosenUser.street_address_1 || '');
    this.setFormField('city', chosenUser.city || '');
    this.setFormField('province_code', chosenUser.province_code || '');
    this.setFormField('postal_code', chosenUser.postal_code || '');
    this.setFormField('phone', chosenUser.phone || '');
    this.setFormField('user_id', chosenUser.id || '');
  }

  onChangeDefaultVehicle(value){
    const {vehicles} = this.props;
    this.setFormField('default_vehicle_id', value);
    let chosenVehicle = {};
    vehicles.map((vehicle) => {
      vehicle.id === value ? chosenVehicle = vehicle : chosenVehicle;
    });
    this.setFormField('make', chosenVehicle.make || '');
    this.setFormField('model', chosenVehicle.model || '');
    this.setFormField('license_number', chosenVehicle.license_number || '');
  }

  render(){
    const {
      vehicles,
      facilities,
      users,
      isCompany,
      integrationState,
      onfleetIsEnabled,
      onfleetTeams,
      initialValues,
      isMetrcDelivery,
      countryCode
    } = this.props;
    return(
      <div className='driver-wrapper'>
        <InProgressOverlay isActive={this.state.loading}/>
        <FormWrapper title = {I18n.t('cultivation.drivers.addTitle')} goBack={this.props.actions.goBack}>
          <CreateDriverForm
            onSubmit = {this.onSubmit}
            vehicles={vehicles}
            facilities={facilities}
            users={users}
            onfleetTeams={onfleetTeams}
            onfleetIsEnabled={onfleetIsEnabled}
            isMetrcDelivery={isMetrcDelivery}
            onChangeDefaultVehicle={this.onChangeDefaultVehicle}
            onUserChange = {this.onUserChange}
            addLoadingOverlay = {this.addLoadingOverlay}
            initialValues = {initialValues}
            isCompany={isCompany}
            integrationState={integrationState}
            countryCode={countryCode}
          />
        </FormWrapper>
      </div>
    );
  }
}

CreateDriverPage.propTypes = {
  actions: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired
  }),
  facilities: PropTypes.array,
  users: PropTypes.array,
  vehicles: PropTypes.array,
  organization_id: PropTypes.number,
  timezone: PropTypes.string.isRequired,
  isCompany: PropTypes.bool,
  integrationState: PropTypes.object,
  onfleetTeams: PropTypes.array,
  countryCode: PropTypes.string,
};

const selector = formValueSelector(CREATE_DRIVER_FORM);

function mapStateToProps(state) {
  const {vehicles, facilities, timezone} = state;
  const isCompany = !!selector(state, 'is_licensed_transporter');
  const integrationState = getIntegrationState(state);
  const onfleetIsEnabled = isOnfleetIntegrator(state);
  const onfleetTeams = state[dataNames.onfleetTeams];
  return {
    vehicles,
    facilities,
    users: getIntegratedOrApprovedUsers(state),
    organization_id: state.facility.organizationId,
    timezone: timezone,
    isCompany,
    integrationState,
    onfleetIsEnabled,
    isMetrcDelivery: isMetrcDelivery(state),
    onfleetTeams,
    initialValues: {
      driver_phones:[{}],
      onfleet_teams: []
    },
    countryCode: getCurrentFacilityCountry(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {goBack,change,touch});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (CreateDriverPage);
