import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {push,goBack} from 'react-router-redux';
import * as apiActions from '../../../actions/apiActions';
import * as itemNames from '../../../constants/itemNames';
import * as dataNames from '../../../constants/dataNames';
import {getFlattenedLocations} from '../../../selectors/locationsSelectors';
import FormWrapper from '../../common/form/FormWrapper';
import HarvestBatchSplitForm from './components/HarvestBatchSplitForm'; //eslint-disable-line
import getPayload from '../../../selectors/forms/harvestBatchSplitFormSelectors';
import LabelPrinter from '../../print-services/labels/LabelPrinter'; //eslint-disable-line

export class HarvestBatchSplitPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.onHideLabelPrinter = this.onHideLabelPrinter.bind(this);
    this.onPrintLabels = this.onPrintLabels.bind(this);
    this.state = {
      payload: {}, // for labels
      showLabelPrinter: false,
    };
  }

  componentDidMount(){
    this.props.actions.getItem(`/api/harvests/${this.props.params.id}`, itemNames.splitHarvestBatch);
    this.props.actions.getUnpaginatedData('/api/location_hierarchy', dataNames.locations, undefined, {is_sales_location:0});
  }

  onSubmit(values){

    let numSplits = values.splits.length;

    const batchIds = [values.id];

    /***
     * Split until no splits remain
     * @param index
     */
    const createSplit = (index) => {
      const payload = getPayload(values, index);
      this.props.actions.postItem(`/api/harvests/${this.props.params.id}/split`, payload)
        .then((data) => {
          batchIds.push(data.id);
          numSplits--;
          index++;
          if(numSplits > 0) return createSplit(index);
          if(values.afterSubmit !== 'print') return this.props.actions.goBack();
          // Collect the ids which is not as simple as it sounds
          this.setState({
            payload: {ids: batchIds},
            showLabelPrinter: true,
          });
        });
    };
    createSplit(0);
  }

  onPrintLabels(){
    this.setState({
      showLabelPrinter: true,
    });
  }

  onHideLabelPrinter(){
    this.setState({showLabelPrinter: false});
  }

  render () {
    const {splitHarvestBatch, initialValues, locations} = this.props;
    return (<FormWrapper title={I18n.t('cultivation.harvestBatches.harvestBatchSplit')} goBack={(event) => this.props.actions.goBack()}>
      <LabelPrinter
        showModal={this.state.showLabelPrinter}
        onHide={this.onHideLabelPrinter}
        labelTag='harvestBatchPlantLabel'
        payload={this.state.payload}
      />
      <HarvestBatchSplitForm
        splitHarvestBatch={splitHarvestBatch}
        initialValues={initialValues}
        locations={locations}
        onPrintLabels={this.onPrintLabels}
        onSubmit={this.onSubmit}
      />
    </FormWrapper>);
  }
}

HarvestBatchSplitPage.propTypes = {
  initialValues: PropTypes.object,
};

function mapStateToProps(state) {
  const initialValues = Object.assign({}, state[itemNames.splitHarvestBatch], {newWeight: 0, splits: [{weight: 0, location: null}]});
  return {
    initialValues,
    splitHarvestBatch: state[itemNames.splitHarvestBatch],
    locations: getFlattenedLocations(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {push, goBack});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (HarvestBatchSplitPage);


