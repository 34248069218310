/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import get from 'lodash.get';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { goBack } from 'react-router-redux';
import { reset, change, stopSubmit, getFormValues } from 'redux-form';
import {Link} from 'react-router';
import {Button} from 'react-bootstrap';
import * as apiActions from '../../../actions/apiActions';
import { unsetData } from '../../../actions/dataActions';
import * as messageTypes from '../../../constants/messageTypes';
import { addMessage } from '../../../actions/systemActions';
import { setItem, unsetItem } from '../../../actions/itemActions';
import { loadSourceInventoryItems } from '../../../actions/forms/plantFormActions';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import { CREATE_PLANT_FORM } from '../../../constants/forms';
import { convertFormInputDateToDbDate } from '../../../util/dateHelpers';
import { getOrderedFacilityStrains } from '../../../selectors/facilityStrainsSelectors';
import {
  getFormFilteredLocations,
  getFormPhenotypes,
  getFormMatriarchName,
  getFormBatchName,
  getFormFilteredFeedingSchedules,
  getSubcategoryIds,
  getApplicableItems,
  getPlantTypes,
  getFilteredMotherPlants,
  getFormTypeOfCannabis
} from '../../../selectors/forms/createPlantFormSelectors';
import ModalWrapper from '../../common/ModalWrapper';
import FormWrapper from '../../common/form/FormWrapper';
import CreatePlantFormWrapper from './CreatePlantForm';
import PrinterModal from '../../printer/PrinterModal';
import InProgressOverlay from '../../common/InProgressOverlay';
import { getPlantsError } from '../../../selectors/plantsSelectors';
import { fetchMetrcTrackingIdsForSelectInput } from '../../../actions/integrationActions';
import { getMetrcTags } from '../../../selectors/integration/metrcSelectors';
import reduxMetrcIdAvailability, {
  setUnavailableTrackingIdFieldError
} from '../../common/form/redux-form/reduxMetrcIdAvailability';
import { getIntegrationState } from '../../../selectors/integration/integrationSelectors';
import { getItemMasters } from '../../../selectors/itemMastersSelectors';
import { getSettingValue } from '../../../util/settingsHelpers';
import { isAddPlantsToExistingBatchFeatureEnabled } from '../../../selectors/featureToggles';

export class CreatePlantPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      phenotypes: [],
      showLoadingMessage: false,
      showLoader: true,
      showOk: false,
      showConfirmationModal: false,
      loadingMessage: 'plants.create.creating',
      onDismiss: () => {},
      ignorePromise: false,
      doPrint: false,
      saved: false,
      saveCount: 0,
      sourceItemIdIsDisabled: false,
      startingMotherPlantIdIsDisabled: false,
      labelParams: {},
      batch: {}
    };
    this.initialSubmit = this.initialSubmit.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.printLabel = this.printLabel.bind(this);
    this.hidePrinter = this.hidePrinter.bind(this);
    this.doPrint = this.doPrint.bind(this);
    this.goBack = this.goBack.bind(this);
    this.disableSourceItemId = this.disableSourceItemId.bind(this);
    this.disableStartingMotherPlantId = this.disableStartingMotherPlantId.bind(this);

    this.showLoadingMessage = this.showLoadingMessage.bind(this);
    this.hideLoadingMessage = this.hideLoadingMessage.bind(this);
    this.showDismissableMessage = this.showDismissableMessage.bind(this);
    this.afterDeferredPlantActionCompleted = this.afterDeferredPlantActionCompleted.bind(this);
  }

  componentWillMount() {
    const {integrationState: {isBiotrack}} = this.props;
    this.props.actions.unsetData(dataNames.motherPlants);
    this.props.actions.unsetData(dataNames.batches);
    this.props.actions.unsetItem(itemNames.deferredPlantAction);
    this.props.actions
      .getItem('/api/integration-settings', itemNames.integrationSettings, {
        failed: 'stateIntegratorSettings.get.failed'
      })
      .then(() => {
        if (this.props.integrationState.isCaMetrc) {
          this.props.actions.fetchMetrcTrackingIdsForSelectInput({ type: 'plant' });
        }
      });
    this.props.actions.getUnpaginatedData('/api/strains/by_facility', dataNames.facilityStrains);
    this.props.actions.getUnpaginatedData('/api/schedules', dataNames.feedingSchedules, undefined, { active: 1 });
    this.props.actions.getUnpaginatedData('/api/cultivation/sections', dataNames.sections);
    this.props.actions.getUnpaginatedData('/api/stages', dataNames.stages);
    this.props.actions
      .getItem('/api/settings/plants', itemNames.complianceSettings, null, {
        ids: ['cult_track_plants_as_groups', 'cult_create_plant_from_inventory', 'cult_colombia_modality']
      })
      .then(() => {
        if (isBiotrack || getSettingValue('cult_create_plant_from_inventory')(this.props.complianceSettings)) {
          this.props.actions.getUnpaginatedData('/api/subcategories', dataNames.subcategories);

          const searchParams = {
            select_columns: ['id', 'strain_id', 'category_id', 'subcategory_id', 'name'],
            sort: ['name:asc', 'display_name:asc'],
            active: 1,
            is_creatable_for_plants: 1,
            per_page: 0
          };

          this.props.actions.getUnpaginatedData(
            '/api/item_masters/search',
            dataNames.itemMasters,
            {failed: 'products.get.failed'},
            searchParams
          ).then(() => {
            const { itemMasters } = this.props;
            if (itemMasters.length) {
              return this.props.actions.loadSourceInventoryItems(itemMasters);
            }
          });
        }
      });
    if (this.props.isLocal) {
      this.props.actions.getUnpaginatedData('/api/plants', dataNames.plants);
    }
  }

  componentWillUnmount() {
    this.props.actions.unsetData(dataNames.itemMasters);
    this.props.actions.unsetData(dataNames.inventoryItems);
  }

  componentWillReceiveProps(nextProps) {
    //due to an error on local we get set a timeout of 5 seconds after batch gets a name
    //this allows printing on local
    if(this.props.isLocal) {
      if (nextProps.batch.id) {
        setTimeout(() => {
          setItem(Object.assign({}, nextProps.deferredPlantAction, { complete: false }), itemNames.deferredPlantAction);
          this.setState(
            {
              labelIds: nextProps.deferredPlantAction.ids
            },
          () => {
            this.setState({
              showLoader: false,
              loadingMessage: this.state.doPrint ? 'plants.create.successBulkPrint' : 'plants.create.successBulk'
            });
  
            this.afterDeferredPlantActionCompleted(nextProps);
          }
        );
          this.hideLoadingMessage(); 
        }, 5000);
      }
    } else {
      if (nextProps.deferredPlantAction.complete) {
        setItem(Object.assign({}, nextProps.deferredPlantAction, { complete: false }), itemNames.deferredPlantAction);
        this.setState(
          {
            labelIds: nextProps.deferredPlantAction.ids
          },
          () => {
            this.setState({
              showLoader: false,
              loadingMessage: this.state.doPrint ? 'plants.create.successBulkPrint' : 'plants.create.successBulk'
            });
  
            this.afterDeferredPlantActionCompleted(nextProps);
          }
        );
      }
    }
  }

  showLoadingMessage(batch) {
    const message = 'plants.create.creating';
    const messageObject = {
      count: batch.starting_qty,
      plantNoun: parseInt(batch.starting_qty) === 1 ? I18n.t('common.plant') : I18n.t('common.plants')
    };
    this.setState({
      showLoadingMessage: true,
      showLoader: true,
      onDismiss: () => {},
      showOk: false,
      loadingMessage: message,
      messageObject: messageObject
    });
  }

  showDismissableMessage(func) {
    const newState = {
      showOk: true,
      onDismiss: func
    };
    this.setState(newState);
  }

  hideLoadingMessage() {
    this.setState({ showLoadingMessage: false });
  }

  initialSubmit(plantBatch, dispatch) {
    const { isMetrc } = this.props.integrationState;
    if (isMetrc && (!plantBatch.source_item_id && !plantBatch.starting_mother_plant_id)) {
      this.setState({
        showConfirmationModal: true,
        plantBatch: plantBatch
      });
    } else {
      this.onSubmit(plantBatch, dispatch);
    }
  }

  afterDeferredPlantActionCompleted(nextProps) {
    const batch_id = get(nextProps, 'batch.id');
    const { planting_batch_id } = nextProps.deferredPlantAction;
    if (batch_id !== planting_batch_id) return false;

    const { batch } = this.state;
    const formReset = () => {
      if (this.props.integrationState.isCaMetrc) {
        this.props.actions.fetchMetrcTrackingIdsForSelectInput({ type: 'plant' });
      }

      this.props.actions.reset(CREATE_PLANT_FORM);
      this.setState({ saved: false, doPrint: false, batch: {} });
    };

    const callback = () => {
      // Original callback code
      if (this.state.doPrint) {
        this.printLabel();
        this.hideLoadingMessage();
      } else {
        if (batch.afterSubmit === 'redirect' && this.props.actions.goBack) {
          this.props.actions.goBack(this.state.saveCount);
        } else {
          this.hideLoadingMessage();
          formReset();
        }
      }
    };

    if (parseInt(batch.starting_qty) === 1) {
      callback();
    } else {
      this.showDismissableMessage(callback);
    }
  }

  onSubmit(plantBatch) {

    if (!moment.isMoment(plantBatch.planting_date)) {
      return this.props.actions.addMessage(messageTypes.error, 'plants.create.invalidDateFormat');
    }

    const {
      validateTrackingIdsAvailability,
      integrationState: { isMetrc, isWaLeaf },
      addPlantsToExistingBatchFeatureEnabled
    } = this.props;
    const formReset = () => {
      this.props.actions.reset(CREATE_PLANT_FORM);
      this.setState({ saved: false, doPrint: false });
    };
    if (this.state.saved) {
      if (this.state.doPrint) {
        this.printLabel();
      } else {
        if (plantBatch.afterSubmit !== 'redirect') formReset();
      }
      return false;
    }

    this.showLoadingMessage(plantBatch);
    const that = this;
    const hideMessage = this.hideLoadingMessage;
    const batch = {
      ...plantBatch,
      planting_date: convertFormInputDateToDbDate(plantBatch.planting_date, this.props.timezone),
      starting_stage_id: isWaLeaf ? 1 : 2,
      state_integration_tracking_id: plantBatch.batch_tracking_id
    };
    if (batch.batch_name && !addPlantsToExistingBatchFeatureEnabled) {
      delete batch.batch_name;
    }
    const setTrackingIdError = (trackingIds, fieldName, errorText) =>
      setUnavailableTrackingIdFieldError(trackingIds, 'batch_tracking_id', errorText);
    return validateTrackingIdsAvailability(batch, setTrackingIdError, {
      fieldName: 'state_integration_tracking_id',
      requireUnused: isMetrc
    })
      .then(() => {
        return this.props.actions
          .postItem(
            '/api/batches',
            batch,
            itemNames.batch,
          {
            failed: 'plants.create.fail',
            failedHandler: getPlantsError,
            success: parseInt(batch.starting_qty) === 1 ? 'plants.create.success' : null
          },
            null,
            (data) => {
              this.setState({
                ignorePromise: true,
                saved: true,
                saveCount: this.state.saveCount + 1,
                labelParams: { planting_batch_id: data.id },
                batch
              });
            }
          )
          .then((arg) => {
            setTimeout(() => {
              if (that.state.ignorePromise) return that.setState({ ignorePromise: false });
              hideMessage();
            }, 1000);
          });
      })
      .catch((e) => {
        hideMessage();
        this.props.actions.stopSubmit(CREATE_PLANT_FORM, e.errors);
      });
  }

  doPrint() {
    this.setState({ doPrint: true });
  }

  printLabel() {
    const batch = this.props.batch;
    if (batch.id === undefined) return;
    //due to an error on local we get the plants for the facility, then plug those in as opposed to the new ones created.
    //This allows labels to print on local
    if (this.props.isLocal) {
      const ids = [this.props.plants[0].id, this.props.plants[1].id];
      const newState = { showPrinter: true, labelTag: 'cult_plant_tag', redirect: false, doPrint: false };
      if (!this.props.deferredPlantAction.complete && ids.length) {
        newState.labelIds = ids;
      }
      this.setState(newState);
    } else {
      const batch = this.props.batch;
      if (batch.id === undefined) return;
      const ids = get(batch, 'plants', []).map((plant) => plant.id);
      const newState = { showPrinter: true, labelTag: 'cult_plant_tag', redirect: false, doPrint: false };
      if (!this.props.deferredPlantAction.complete && ids.length) {
        newState.labelIds = ids;
      } else {
        newState.labelIds = [get(this.state, 'ids.0')];
      }
      this.setState(newState);
    }
  }

  hidePrinter(event) {
    this.setState({ showPrinter: false });
  }

  goBack() {
    this.props.actions.goBack(this.state.saveCount);
  }

  disableSourceItemId(disabled) {
    this.setState({
      sourceItemIdIsDisabled: disabled
    });
  }

  disableStartingMotherPlantId(disabled) {
    this.setState({
      startingMotherPlantIdIsDisabled: disabled
    });
  }

  render() {
    const {
      showPrinter,
      labelTag,
      labelIds,
      labelParams,
      sourceItemIdIsDisabled,
      startingMotherPlantIdIsDisabled
    } = this.state;
    const { addPlantsToExistingBatchFeatureEnabled } = this.props;

    return (
      <div>
        <FormWrapper title={'plants.create.title'} goBack={this.goBack}>
          <InProgressOverlay
            isActive={this.state.showLoadingMessage}
            messageObject={this.state.messageObject}
            onDismiss={this.state.onDismiss}
            showOk={this.state.showOk}
            showLoader={this.state.showLoader}
            message={this.state.loadingMessage}
            translate={true}
          />
          <CreatePlantFormWrapper
            form={CREATE_PLANT_FORM}
            formValues={this.props.formValues}
            onSubmit={this.initialSubmit}
            batch_name={this.props.batch_name}
            strains={this.props.strains}
            doPrint={this.doPrint}
            saved={this.state.saved}
            locations={this.props.locations}
            stages={this.props.stages}
            sourceItemIdIsDisabled={sourceItemIdIsDisabled}
            disableSourceItemId={this.disableSourceItemId}
            startingMotherPlantIdIsDisabled={startingMotherPlantIdIsDisabled}
            disableStartingMotherPlantId={this.disableStartingMotherPlantId}
            motherPlants={this.props.motherPlants}
            phenotypes={this.props.phenotypes}
            schedules={this.props.schedules}
            metrcTags={this.props.metrcTags}
            sourceItems={this.props.sourceItems}
            plantTypes={this.props.plantTypes}
            integrationState={this.props.integrationState}
            complianceSettings={this.props.complianceSettings}
            matriarchPlant={this.props.matriarchPlant}
            batches={this.props.batches}
            typeOfCannabis={this.props.typeOfCannabis}
            addPlantsToExistingBatchFeatureEnabled={addPlantsToExistingBatchFeatureEnabled}
          />
          <PrinterModal
            ref='printerModal'
            forceLabelBlocks={true}
            showPrinter={showPrinter}
            hidePrinter={this.hidePrinter}
            labelTag={labelTag}
            labelIds={labelIds}
            labelParams={labelParams}
            goToAfter={'/plants/propagation'}
          />
        </FormWrapper>
        <ModalWrapper
          dialogClassName='modal-sm'
          onHide={() => this.setState({ showConfirmationModal: false })}
          okayButton={{
            show: !this.props.integrationState.isCaMetrc,
            text: I18n.t('general.yes'),
            variant: 'danger',
            onClick: () => {
              this.setState({ showConfirmationModal: false });
              this.onSubmit(this.state.plantBatch);
            }
          }}
          cancelButton={{
            show: true,
            text: I18n.t('general.no'),
            variant: 'default',
            onClick: () => this.setState({ showConfirmationModal: false })
          }}
          showModal={this.state.showConfirmationModal}
          title={I18n.t('facility.setup.compliance.plantWithoutSourceTitle')}
          version={2}
        >
          {this.props.integrationState.isCaMetrc
            ? getSourceRequiredBody()
            : <p>{I18n.t('facility.setup.compliance.plantWithoutSourceBody')}</p>
          }
        </ModalWrapper>
      </div>
    );
  }
}

CreatePlantPage.propTypes = {
  batch: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  stages: PropTypes.array.isRequired,
  motherPlants: PropTypes.array.isRequired,
  strains: PropTypes.array.isRequired,
  timezone: PropTypes.string.isRequired,
  schedules: PropTypes.array.isRequired,
  matriarchPlant: PropTypes.string.isRequired,
  batch_name: PropTypes.string,
  metrcTags: PropTypes.array,
  subcategoryIds: PropTypes.array.isRequired,
  itemMasters: PropTypes.array.isRequired,
  sourceItems: PropTypes.array.isRequired,
  plantTypes: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
  validateTrackingIdsAvailability: PropTypes.func.isRequired,
  complianceSettings: PropTypes.object.isRequired,
  batches: PropTypes.array,
  addPlantsToExistingBatchFeatureEnabled: PropTypes.bool,
  plants: PropTypes.array
};

function mapStateToProps(state) {
  const locations = getFormFilteredLocations(state);
  const phenotypes = getFormPhenotypes(state);
  const batch_name = getFormBatchName(state);
  const schedules = getFormFilteredFeedingSchedules(state);
  const strains = getOrderedFacilityStrains(state);
  const matriarchPlant = getFormMatriarchName(state);
  const motherPlants = getFilteredMotherPlants(state);
  const { batches } = state;

  return {
    timezone: state.timezone,
    batch: state.batch,
    batches: batches,
    phenotypes,
    locations,
    schedules,
    strains,
    stages: state.stages,
    motherPlants: motherPlants,
    batch_name,
    matriarchPlant,
    formValues: { isEnabledChooseBatches: false, ...getFormValues(CREATE_PLANT_FORM)(state) },
    metrcTags: getMetrcTags(state),
    integrationState: getIntegrationState(state),
    trackingIds: state.trackingIds,
    subcategoryIds: getSubcategoryIds(state),
    itemMasters: getItemMasters(state),
    sourceItems: getApplicableItems(state),
    plantTypes: getPlantTypes(state),
    complianceSettings: state.complianceSettings,
    deferredPlantAction: state[itemNames.deferredPlantAction],
    typeOfCannabis: getFormTypeOfCannabis(state),
    addPlantsToExistingBatchFeatureEnabled : isAddPlantsToExistingBatchFeatureEnabled(state)(),
    isLocal: state.env.appEnv === 'local',
    plants: state.plants
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {
    goBack,
    reset,
    change,
    unsetData,
    unsetItem,
    addMessage,
    fetchMetrcTrackingIdsForSelectInput,
    stopSubmit,
    setItem,
    loadSourceInventoryItems,
  });
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function getSourceRequiredBody() {
  return (
    <p>
      {I18n.t('facility.setup.compliance.plantWithoutRequiredSourceBody')}
        <Link to={{pathname: '/products/create', state: {goBackAfterSubmit: true}}}>
          <Button variant='link'>Go To Create Products</Button>
        </Link>
    </p>
  );
}

export default reduxMetrcIdAvailability(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CreatePlantPage)
);
