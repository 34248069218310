/* eslint-disable import/no-named-as-default*/
import get from 'lodash.get';
import {connect} from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import {FieldArray, Field} from 'redux-form';
import {Alert, FormGroup} from 'react-bootstrap';
import {FaExclamationTriangle} from 'react-icons/fa';
import CollapsiblePanel from '../../common/CollapsiblePanel';
import ModulePermissionsFieldArray from './ModulePermissionsFieldArray';
import IndeterminateCheckbox from '../../common/form/IndeterminateCheckbox';

/**
 * Render redux FieldArray of modules+permissions for a single user role
 */
export class RoleModulesFieldArray extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.renderModule = this.renderModule.bind(this);
  }


  /**
   * FieldArray.map() handler, renders module as CollapsiblePanel
   * @return {React.Element}
   */
  renderModule(moduleName,moduleIndex) {
    const module = get(this.props.role,`modules[${moduleIndex}]`,{permissions: []});
    if (!module.permissions.length) return null;
    const enabledPermissions = module.permissions.filter(p => p.enabled);
    const hasPermissions = !!enabledPermissions.length;
    const allChecked = module.permissions.length === enabledPermissions.length;

    const searchRegExp = new RegExp(this.props.searchStr, 'i');
    if(!module.permissions.find( p => searchRegExp.test(p.permission_name))){
      return null;
    }

    // panel header
    const heading = (<span>
      {module.module_name}
      <Field name={`${moduleName}.all_permissions_selected`}
        component={({input: {onChange}}) => (
          <IndeterminateCheckbox
            checked={allChecked}
            onChange={onChange}
            indeterminate={!allChecked && hasPermissions}
            className='select-all-permissions'
          />
        )}/>
    </span>);

    // panel body
    const body = (<FieldArray
      name={`${moduleName}.permissions`}
      component={ModulePermissionsFieldArray} props={{module}}
    />);

    return (
      <CollapsiblePanel
        key={moduleIndex}
        className={hasPermissions ? 'panel-success' : 'panel-default muted'}
        expanded={true}
        heading={heading}
        body={body}
      />
    );
  }

  /**
   * Render primary component
   * @return {React.Element}
   */
  render() {
    const {fields,meta,role} = this.props;
    return (
      <FormGroup id={`${role.role_name}-modules`}>
        {(meta && meta.error) ?
          <Alert variant='danger'>
            <FaExclamationTriangle/>
            <span className='padding-left'>{meta.error}</span>
          </Alert> : null}

        {fields.map( this.renderModule )}
      </FormGroup>
    );
  }
}

RoleModulesFieldArray.propTypes = {
  fields: PropTypes.shape({
    get: PropTypes.func.isRequired,
    map: PropTypes.func.isRequired,
  }).isRequired,
  role: PropTypes.object.isRequired,
  meta: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    searchStr: get(state,'user.userRolesSearchStr','')
  };
}

export default connect(mapStateToProps)(RoleModulesFieldArray);
