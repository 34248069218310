import {createSelector} from 'reselect';
import get from 'lodash.get';
import map from 'lodash.map';
import * as dataNames from '../constants/dataNames';
import * as itemNames from '../constants/itemNames';
import {getActiveFacilityId, getFacilities} from './facilitiesSelectors';

export const getFacilitySharingStatus = state => state[itemNames.facilitySharingStatus];
export const getSharedFacilityGroups = state => state[dataNames.sharedFacilityGroups];
export const getSharedFacilityGroup = state => state[itemNames.sharedFacilityGroup];
export const getSelectedSharedFacilityGroups = state => state.selectedSharedFacilityGroups;
const facilities = (state) => state.facilities;

export const getSelectedSharedFacilityGroupsIds = createSelector(
  getSelectedSharedFacilityGroups, selectedSharingFacilityGroups => selectedSharingFacilityGroups.map(selectedGroup => selectedGroup.id)
);

export const getCreateSharedFacilityGroupInitialValues = () => ({
  name: '',
  facilities: [],
  masterFacility: null
});

export const getModifySharedFacilityGroupInitialValues = createSelector(
  [getSharedFacilityGroup, getFacilities], (sharedFacilityGroup, organizationFacilities) => {
    const masterFacility = organizationFacilities.find(el => el.id === sharedFacilityGroup.facility_id);
    const facilities = sharedFacilityGroup.children && sharedFacilityGroup.children.map(el => el.facility) || [];
    if(masterFacility) facilities.push(masterFacility);

    return {
      ...sharedFacilityGroup,
      masterFacilityId: (masterFacility || {}).id,
      facilities
    };
  }
);

export const getFormattedSharedFacilityGroups = createSelector(
  getSharedFacilityGroups,
  sharedFacilityGroups => sharedFacilityGroups.map(group => {
    return {
      ...group,
      facilities: map(get(group, 'children', []), 'facility.name').concat(get(group, 'facility.name')).join(', '),
      masterFacility: get(group, 'facility.name', ''),
    };
  })
);


export const isFacilityGroupMaster = createSelector([getActiveFacilityId, getSharedFacilityGroups], (activeFacilityId, sharedFacilitiesGroups) => {
  return Boolean(sharedFacilitiesGroups.some(group => !group.facility_group_parent_id && group.facility_id === activeFacilityId));
});

const getSharedFacilityIds = createSelector(getSharedFacilityGroups, sharedFacilityGroups => map(sharedFacilityGroups, 'facility_id'));

export const getAllowedFacilitiesForSelecting = createSelector([getSharedFacilityIds, facilities], (sharedFacilityIds, facilities) => {
  return facilities.filter(facility => !sharedFacilityIds.includes(facility.id));
});
