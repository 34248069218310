import get from 'lodash.get';
import map from 'lodash.map';
import { getIntegrationState } from '../../selectors/integration/integrationSelectors';
import { isLabResultRequired } from '../../selectors/transfersSelectors';
import { fetchChildrenItemMasters, fetchItemMasters } from '../itemMasterActions';
import { fetchInventoryByItemMasterIDs } from '../inventory';
import * as labResultActions from '../lab-result';
import * as itemNames from '../../constants/itemNames';
import { getItem, getUnpaginatedData } from '../apiActions';
import * as dataNames from '../../constants/dataNames';
import { setData } from '../dataActions';

/**
 * Loading transfer by ID
 * @param id
 * @returns {function(*): *}
 */
export const loadTransferById = (id) => (dispatch) =>
  dispatch(getItem(`/api/transfers/${id}`, itemNames.transfer, { failed: 'cultivation.transfers.getTransfer.failed' }));

/**
 * Method which is loading inventory items for any type of product
 * @param orders
 * @param data
 * @returns {Function}
 */
export const loadInventoryForSalesOrders = (orders, data = {}) => (dispatch, getState) => {
  const integrations = getIntegrationState(getState());
  const order_type = get(orders, '[0].order_type', null);

  const payload = {
    ..._getInventoryPayloadData({ orders, order_type, integrations }),
    ...data
  };

  const promises = [
    dispatch(fetchInventoryByItemMasterIDs(payload)),
    dispatch(fetchItemMasters(payload.ids, { detailed: true }))
  ];

  return Promise.all(promises)
    .then((response) => dispatch(_fetchChildItemMasters(response, orders, data)))
    .then((response) => dispatch(_fetchLabResultsForInventory(response, orders)))
    .then((inventoryItems) => dispatch(_fetchItemReservations(inventoryItems)));
};

/**
 *
 * @param orders
 * @param integrationState
 * @param externalTransfer
 * @param labResultRequiredConfig
 * @returns {*}
 * @private
 * @constructor
 */
const _AreTestResultsRequired = (orders, integrationState, externalTransfer, labResultRequiredConfig) => {
  const { isPaLeaf, isWaLeaf, isBiotrack, isIsolocity } = integrationState;

  let result = isPaLeaf || isWaLeaf || isBiotrack || (isIsolocity && externalTransfer === 1) || labResultRequiredConfig;
  orders.forEach((order) => {
    order.lines.forEach((line) => {
      if (line.meta && typeof line.meta === 'string' && JSON.parse(line.meta).product_sub_type) {
        result = true;
      }
    });
  });

  return result;
};

/**
 * Is orders transferred within same organization
 *
 * @param partnerFacilities
 * @param orders
 * @returns {integer}
 */
const isExternalTransfer = (partnerFacilities, orders) => {
  let isExternal = -1;

  orders.map((order) => {
    if (isExternal !== 1) {
      const partnerFacilityId = get(order, 'partner_facility_id');
      const isLocal = partnerFacilities.find(
        (facility) => facility.id === partnerFacilityId && facility.partner.is_internal_partner
      );
      isExternal = !isLocal ? 1 : -1;
    }
  });

  return isExternal;
};

/**
 * Fetching from backend information about test results
 * @param inventoryItems
 * @param orders
 * @returns {Function}
 * @private
 */
const _fetchLabResultsForInventory = (inventoryItems, orders) => (dispatch, getState) => {
  const integrations = getIntegrationState(getState());
  const partnerFacilities = get(getState(), 'partnerFacilities');
  const externalTransfer = isExternalTransfer(partnerFacilities, orders);
  const labResultsRequiredConfig = isLabResultRequired(getState());

  if (_AreTestResultsRequired(orders, integrations, externalTransfer, labResultsRequiredConfig)) {
    dispatch(labResultActions.fetchLabResultsByIDs(map(inventoryItems, 'id')));
  }

  return Promise.resolve(inventoryItems);
};

/**
 *
 * @param response
 * @param orders
 * @param params
 * @returns {Function}
 * @private
 */
const _fetchChildItemMasters = (response, orders, params) => (dispatch, getState) => {
  const order_type = get(orders, '[0].order_type', null);
  const order_partner_facility_id = get(orders, '0.partner_facility_id');
  const partnerFacilityIsLab = get(orders, '0.partner_facility.partner.is_lab', false);
  const [inventoryItems, itemMasters] = response;
  const integrationState = getIntegrationState(getState());

  /**
   * Getting only parent item master IDs
   * @param acc
   * @param itemMaster
   * @returns {*}
   */
  const handleItemMaster = (acc, itemMaster) => {
    if (get(itemMaster, 'inventory_attributes.is_prepack') && !itemMaster.item_master_parent_id) {
      acc.push(itemMaster.id);
    }

    return acc;
  };

  /**
   * Concat inventory items with items located in child item master
   * @param childItemMasters
   */
  const concatInventoryItems = (childItemMasters) => {
    const handleItems = (acc, childItemMaster) => acc.concat(childItemMaster.items);
    const childInventories = childItemMasters.reduce(handleItems, []);
    const result = inventoryItems.concat(childInventories);

    return Promise.resolve(result);
  };

  const parentItemMasterIDs = itemMasters.reduce(handleItemMaster, []);

  // Children don't exist just return inventoryItems
  if (parentItemMasterIDs.length === 0) {
    return Promise.resolve(inventoryItems);
  }

  const payload = {
    ids: parentItemMasterIDs,
    is_waste: order_type === 'waste_disposal' ? 1 : 0,
    active: 1
  };

  if (get(params, 'active_only', true) === false) {
    payload.include_inactive_items = true;
  }

  if (get(integrationState, 'isBiotrack') && partnerFacilityIsLab) {
    payload.is_test_package = 1;
    payload.destination_partner_facility_id = order_partner_facility_id;
  }

  return dispatch(fetchChildrenItemMasters(payload)).then(concatInventoryItems);
};

/**
 * Returns payload needed for getting inventory for sales transfer
 * @param orders
 * @param order_type
 * @param integrations
 * @returns {{ids: *, is_waste: number}}
 * @private
 */
export const _getInventoryPayloadData = ({ orders, order_type, integrations }) => {
  const payload = {
    ids: orders.reduce((acc, order) => acc.concat(map(order.lines, 'item_master_id')), []),
    is_waste: order_type === 'waste_disposal' ? 1 : 0
  };

  // TODO: for next phase of refactor use hook methods which are invoked for specific integrators
  if (integrations.isBiotrack && order_type === 'lab') {
    payload.is_test_package = 1;
    payload.destination_partner_facility_id = orders[0].partner_facility_id;
  }

  return payload;
};

const _fetchItemReservations = (inventoryItems) => (dispatch, getState) => {
  const itemIds = inventoryItems.map((item) => item.id);
  const perChunk = 150;
  const result = itemIds.reduce((resultArray, item, index) => {
    const chunkIndex =  Math.floor(index / perChunk);
    if(!resultArray[chunkIndex]){
      resultArray[chunkIndex] = [];
    }
    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);
  const results = [];
  const promises = [
    result.forEach((in_item_ids) => {
      dispatch(
        getUnpaginatedData(
          '/api/item_reservations',
          dataNames.reservations,
          { failed: 'reservations.failed' },
          { in_item_ids },
          (reservations) => {
            // Assign reservations to the items in state.childItemMasters
            if (Array.isArray(reservations)) {
              const childItemMasters = getState()[dataNames.childItemMasters];
              const childItemMastersWithReservations = childItemMasters.map((im) => ({
                ...im,
                items: (im.items || []).map((item) => ({
                  ...item,
                  reservations: reservations.filter((r) => r.item_id == item.id)
                }))
              }));
              dispatch(setData(childItemMastersWithReservations, dataNames.childItemMasters));
            }
          }
        )
      );
    }),results.push(...results,result)

  ];
  Promise.all(promises).then(() => dispatch(setData(results, inventoryItems)));
  return Promise.resolve(inventoryItems);
};

