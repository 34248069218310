import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {OverlayTrigger, Popover, ButtonToolbar} from 'react-bootstrap';
import {FaInfoCircle} from 'react-icons/fa';
import get from 'lodash.get';
import SimpleTable from '../common/grid/SimpleTable';
import ModalWrapper from '../common/ModalWrapper';
import RouteButton from '../common/RouteButton';
import PermissionButton from '../common/PermissionButton';
import {getTestResultsByReferenceId, getActiveTestResult} from '../../selectors/testResultsSelectors';
import * as p from '../../constants/permissions';
import {isLeafPaConfigPackClosedLoopFacility} from '../../selectors/facilitiesSelectors';


export class TestResultsCannabinoid extends React.PureComponent {

  constructor(props, context) {
    super(props, context);

    const columns = [
      {
        name: I18n.t('cultivation.testingCompliance.cannabinoidPotency.thc'),
        dataId: 'thc_mg_g',
        hidden: false,
        dataSort: true,
        formatter: (val) => val === null ? 0 : val
      },
      {
        name: I18n.t('cultivation.testingCompliance.cannabinoidPotency.thca'),
        dataId: 'thca_mg_g',
        hidden: false,
        dataSort: true,
        formatter: (val) => val === null ? 0 : val
      },
      {
        name: I18n.t('cultivation.testingCompliance.cannabinoidPotency.thcv'),
        dataId: 'thcv_mg_g',
        hidden: false,
        dataSort: true,
        formatter: (val) => val === null ? 0 : val
      },
      {
        name: I18n.t('cultivation.testingCompliance.cannabinoidPotency.cbd'),
        dataId: 'cbd_mg_g',
        hidden: false,
        dataSort: true,
        formatter: (val) => val === null ? 0 : val
      },
      {
        name: I18n.t('cultivation.testingCompliance.cannabinoidPotency.cbdv'),
        dataId: 'cbdv_mg_g',
        hidden: false,
        dataSort: true,
        formatter: (val) => val === null ? 0 : val
      },
      {
        name: I18n.t('cultivation.testingCompliance.cannabinoidPotency.cbg'),
        dataId: 'cbg_mg_g',
        hidden: false,
        dataSort: true,
        formatter: (val) => val === null ? 0 : val
      },
      {
        name: I18n.t('cultivation.testingCompliance.cannabinoidPotency.cbc'),
        dataId: 'cbc_mg_g',
        hidden: false,
        dataSort: true,
        formatter: (val) => val === null ? 0 : val
      },
      {
        name: I18n.t('cultivation.testingCompliance.cannabinoidPotency.cbn'),
        dataId: 'cbn_mg_g',
        hidden: false,
        dataSort: true,
        formatter: (val) => val === null ? 0 : val
      },
      {
        name: I18n.t('cultivation.testResults.goToResults'),
        dataId: 'id',
        hidden: false,
        dataSort: true,
        formatter: (lab_results_id) => (
          <RouteButton props={{bsSize: 'sm'}} path={`/test-results/view/${lab_results_id}`}>
            {I18n.t('cultivation.testResults.show')}
          </RouteButton>)
      }]
    ;
    this.state = {showResults: false, columns};
    this.toggleResults = this.toggleResults.bind(this);
    this.setLeafClass = this.setLeafClass.bind(this);
  }

  setLeafClass(row) {
    if (row.state_integration_source === 'leaf') {
      return 'integrated';
    }
    return '';
  }

  toggleResults() {
    this.setState({showResults: !this.state.showResults});
  }

  labStatus(status) {
    if(status){
      let style = '';
      switch (status.toLowerCase()) {
      case 'passed':
        style = 'success';
        break;
      case 'failed':
      case 'quarantined':
        style = 'danger';
        break;
      default:
        style = 'info';
      }
      if(status.toLowerCase() === 'quarantined'){
        return (<OverlayTrigger tigger='hover' placement='right' overlay={
            <Popover id='popover-trigger-focus'>
              <span>{I18n.t('inventory.table.quarantineMessage')}</span>
            </Popover>}>
          <span><span className={style}>{status} <FaInfoCircle/></span></span>
        </OverlayTrigger>);
      }else{
        return <span><span className={style}>{status}</span></span>;
      }
    }
  }

  render () {
    const {
      testResults,
      activeTestResultStatus,
      activeTestResultCompletionStatus,
    } = this.props;
    const {showResults} = this.state;
    const displayShowTestResultsButton = testResults.length && activeTestResultCompletionStatus !== 'in_progress';
    const testStatusText = (activeTestResultCompletionStatus === 'in_progress' ? I18n.t('cultivation.testResults.testResultsInProgress') : I18n.t('cultivation.testResults.noTestResults'));
    let testingIdText = '';

    if (testResults[0]) {
      testingIdText = `ID: ${testResults[0].testing_id ? testResults[0].testing_id : ''} - unit: mg/g`;
    }


    return (
      <React.Fragment>
        {testResults.length ?
          <ModalWrapper
            showModal={showResults}
            onHide={this.toggleResults}
            title={'inventory.table.testResults'}
            noTranslationTitle={testingIdText}
            Component={SimpleTable}
            data={testResults}
            columns={this.state.columns}
            bordered={false}
            compact={true}
            trClassName={this.setLeafClass}
          /> : null
        }
        {!activeTestResultStatus ? null : <label style={{verticalAlign: 'top', padding: '5px'}}>{this.labStatus(activeTestResultStatus)}</label>}
        <ButtonToolbar style={{display: 'inline-block'}}>
          {displayShowTestResultsButton ?
            <PermissionButton
              permissions={[p.view_testing, p.manage_testing]}
              props={{
                onClick: this.toggleResults,
                bsSize: 'sm',
              }}
            >
              {I18n.t('cultivation.testResults.showCannabinoids')}
            </PermissionButton>
            : <span className='padding-left'>{testStatusText}</span>
          }
        </ButtonToolbar>
      </React.Fragment>
    );
  }
}

TestResultsCannabinoid.propTypes = {
  item: PropTypes.object.isRequired,
  testResults: PropTypes.array,
  activeTestResultStatus: PropTypes.string,
  activeTestResultCompletionStatus: PropTypes.string,
  fetchChildrenAndLabResults: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
  const testResults = getTestResultsByReferenceId(state, {item: ownProps.item});
  const activeTestResult = getActiveTestResult(state, {item: ownProps.item}) || {};
  const activeTestResultStatus = get(activeTestResult, 'status', '');
  const activeTestResultCompletionStatus = get(activeTestResult, 'completion_status', '');

  return {
    ...ownProps,
    testResults,
    activeTestResultStatus,
    activeTestResultCompletionStatus,
    isLeafPaConfigPackClosedLoopFacility: isLeafPaConfigPackClosedLoopFacility(state),
  };
}

export default connect(mapStateToProps)(TestResultsCannabinoid);
