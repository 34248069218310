import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {goBack, push} from 'react-router-redux';
import { change,touch } from 'redux-form';
import {
  getSetupInitialValues,
  getLocationsPayload,
  getSalesLocations
} from '../../../selectors/salesLocationsSelectors';
import {getUnpaginatedData, getPaginatedData, postData, putData, getItem, getDataByPost} from '../../../actions/apiActions';
import {addMessage} from '../../../actions/systemActions';
import * as dataNames from '../../../constants/dataNames';
import FormWrapper from '../../common/form/FormWrapper';
import SetupSalesLocationsFormWrapper from './SetupSalesLocationsFormWrapper';
import {isLeafIntegrator, isWaLeaf} from '../../../selectors/integrationSelectors';
import PrinterModal from '../../printer/PrinterModal'; //eslint-disable-line
import ConfirmUnshareLocation from '../../common/locations/create/ConfirmUnshareLocation';
import ModalWrapper from '../../common/ModalWrapper';
import {SETUP_SALES_LOCATIONS_FORM} from '../../../constants/forms';
import * as itemNames from '../../../constants/itemNames';
import * as messageTypes from '../../../constants/messageTypes';
import {doNothing} from '../../../util/callbackHelpers';
import {setUserRetailLocation} from '../../../actions/userActions';
import { hasMetrcLocationTypes } from '../../../selectors/integration/metrcSelectors';

export class SetupSalesLocationsFormPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.printLabel = this.printLabel.bind(this);
    this.hidePrinter = this.hidePrinter.bind(this);
    this.onIsSharedChange = this.onIsSharedChange.bind(this);
    this.onUnshareConfirm = this.onUnshareConfirm.bind(this);
    this.onUnshareConfirmationHide = this.onUnshareConfirmationHide.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.renderConfirmModal = this.renderConfirmModal.bind(this);
    this.showConfirmationPopup = this.showConfirmationPopup.bind(this);
    this.validateDefaultLocation = this.validateDefaultLocation.bind(this);
    this.setDefaultLocation = this.setDefaultLocation.bind(this);

    this.state = {
      showPrinter: false,
      showUnshareConfirmation: false,
      showUnshareConfirmationName: '',
      showIsDefaultConfirmation: {}
    };
  }

  componentWillMount() {
    const apiRequests = [
      this.props.actions.getItem('/api/facility_groups_sharings/facility_status', itemNames.facilitySharingStatus),
      this.props.actions.getUnpaginatedData('/api/location_hierarchy?is_sales_location=1&active=1', dataNames.salesLocations),
      this.props.actions.getUnpaginatedData('/api/cultivation/sections/integrated_location', dataNames.integratedLocationNames),
      this.props.actions.getUnpaginatedData('/api/integration-mapping', dataNames.integrationMapping, null, {key: 'inv_location'}),
    ];

    if (this.props.hasMetrcLocationTypes) {
      apiRequests.push(this.props.actions.getUnpaginatedData('/api/metrc/locations/types', dataNames.metrcLocationTypes));
    }

    Promise.all(apiRequests).then(() => this.setState({loading: false}))
      .catch(() => this.setState({loading: false}));
  }

  componentDidUpdate() {
    this.props.actions.touch(SETUP_SALES_LOCATIONS_FORM,'retail_square_footage');
  }

  onDelete(location, func){
    const getChildLocationIds = (location, childIds = [], returnObjects = false) => {
      childIds.push(returnObjects ? location : location.id);
      if(location.child_locations.length){
        location.child_locations.forEach((location) => {
          return getChildLocationIds(location, childIds, returnObjects);
        });
      }
      return childIds;
    };

    const childObjects = getChildLocationIds(location, [], true);
    const childIds = childObjects.map(object => object.id).filter(object => object);

    if (childIds.length) {
      this.props.actions.getPaginatedData('/api/items', undefined, undefined, {
        page: 1,
        per_page: 1,
        in_inventory_location_ids: childIds
      }, (items) => {
        const locationIdsInUse = (items && items.map(item => item.inventory_location_id)) || [];
        if(locationIdsInUse.length){
          const getLocationName = (location, names = []) => {
            names.push(location.name);
            if(location.parent_location_id !== null){
              const nextLocation = childObjects.find((object) => object.id === location.parent_location_id);
              if(nextLocation) return getLocationName(nextLocation, names);
            }
            return names;
          };

          locationIdsInUse.forEach((locationId) => {
            const location = childObjects.find((object) => object.id === locationId);
            const names = getLocationName(location, []);
            const name = names.reverse().join(':');
            const message = I18n.t('locations.setupStorageLocations.errorOnDelete', {location: name});
            this.props.actions.addMessage(messageTypes.error, message, true);
          });
          return false;
        }
        func();
      });
    } else {
      func();
    }
  }

  onSubmit(formData) {
    const {initialValues, salesLocations} = this.props;
    const payload = getLocationsPayload(initialValues.child_locations, formData.child_locations);
    const refetchSalesLocations = () => this.props.actions.getUnpaginatedData('/api/location_hierarchy?is_sales_location=1&active=1', dataNames.salesLocations);
    this.validateDefaultLocation(payload)
      .then(() => {
        if (payload.create.length) {
          this.props.actions.postData('/api/location_hierarchy/multiple',
            payload.create,
            dataNames.salesLocations,
            {success: 'locations.createSalesLocations.success', failed: 'locations.createSalesLocations.failed'},
            null,
            (response) => {
              this.setDefaultLocation(response, salesLocations);
              this.props.actions.push('/setup');
            });
        }
        if (payload.modify.length) {
          this.props.actions.putData('/api/location_hierarchy/multiple',
            payload.modify,
            dataNames.salesLocations,
            {success: 'locations.modifySalesLocations.success', failed: 'locations.modifySalesLocations.failed'},
            null,
            (response) => {
              this.setDefaultLocation(response, salesLocations);
              refetchSalesLocations();
              this.props.actions.push('/setup');
            }
          );
        }
      })
      .catch(doNothing);

  }

  printLabel(event, id, type){

    if(event){
      event.stopPropagation();
      event.preventDefault();
      event.target.blur();
    }

    const tagMap = {
      room: 'inv_location_room_tag',
      section: 'inv_location_section_tag',
      zone: 'inv_location_zone_tag'
    };

    const tag = tagMap[type];

    const url = `/api/labels/generate/${tag}/for/${id}`;

    this.setState({showPrinter: true, labelUrl: url});

  }

  hidePrinter(){
    this.setState({showPrinter: false});
  }


  onIsSharedChange(locationName, event) {
    const locationIndex = locationName.match(/\[(\d+)\]/)[1];
    let wasInitiallyShared = false;
    if(this.props.initialValues.child_locations[locationIndex]) {
      wasInitiallyShared  = this.props.initialValues.child_locations[locationIndex].is_shared;
    }
    this.props.actions.change(SETUP_SALES_LOCATIONS_FORM, locationName + '.unshare', '');
    if(!event.target.checked && wasInitiallyShared) {
      event.preventDefault();
      this.setState({
        showUnshareConfirmation: true,
        showUnshareConfirmationName: locationName,
      });
    }
  }

  onUnshareConfirm() {
    this.setState({ showUnshareConfirmation: false });
    this.props.actions.change(SETUP_SALES_LOCATIONS_FORM, this.state.showUnshareConfirmationName + '.is_shared', false);
    this.props.actions.change(SETUP_SALES_LOCATIONS_FORM, this.state.showUnshareConfirmationName + '.unshare', '1');
  }

  onUnshareConfirmationHide() {
    this.setState({ showUnshareConfirmation: false });
    this.props.actions.change(SETUP_SALES_LOCATIONS_FORM, this.state.showUnshareConfirmationName + '.is_shared', true);
  }

  renderConfirmModal() {
    const { showIsDefaultConfirmation } = this.state;

    const okayButtonProps = {
      show: true,
      onClick: showIsDefaultConfirmation.onConfirm,
      text: I18n.t('general.yes')
    };

    const cancelButtonProps = {
      show: true,
      onClick: showIsDefaultConfirmation.onHide,
      text: I18n.t('general.no')
    };

    return (
      <ModalWrapper
        Component={false}
        title={I18n.t('locations.iDefaultConfirmationModal.switchLocationTitle')}
        headerClass='bg-info-dark'
        onHide={showIsDefaultConfirmation.onHide}
        showModal={showIsDefaultConfirmation.show}
        okayButton={okayButtonProps}
        cancelButton={cancelButtonProps}
        dialogClassName='modal-sm'
        version={2}
      >
        <p>{I18n.t('locations.iDefaultConfirmationModal.switchLocationBody')}</p>
      </ModalWrapper>
    );
  }

  showConfirmationPopup(resolve, reject) {
    const hidePopup = (cb) => () => {
      this.setState({
        showIsDefaultConfirmation: { show: false },
      });
      cb();
    };
    this.setState({
      showIsDefaultConfirmation: {
        show: true,
        onConfirm: hidePopup(resolve),
        onHide: hidePopup(reject),
      }
    });
  }

  validateDefaultLocation(payload) {
    const {currentLocation} = this.props;
    return new Promise((resolve, reject) => {
      const next = () => resolve();
      const newDefaultLocation = payload.create.find(loc => loc.is_default_for_retail) || payload.modify.find(loc => loc.is_default_for_retail);
      const showPopup = newDefaultLocation && currentLocation && newDefaultLocation.id !== currentLocation && newDefaultLocation.active;
      if (!showPopup) {
        return next();
      }
      return this.showConfirmationPopup(next, reject);
    });
  }

  setDefaultLocation(data, salesLocations) {
    const defaultLocation = data.find(location => location.is_default_for_retail && location.active);
    const prevDefaultLocation = salesLocations.find(loc => loc.is_default_for_retail && loc.active);
    const newDefaultLocation = data.find(loc => prevDefaultLocation && loc.id === prevDefaultLocation.id);
    if (defaultLocation) {
      this.props.actions.setUserRetailLocation(defaultLocation.id);
    } else if (prevDefaultLocation && newDefaultLocation && !newDefaultLocation.is_default_for_retail) {
      this.props.actions.setUserRetailLocation(null);
    }
  }

  render() {
    const {integratedLocationNames, initialValues, showIntegratedLocationName, isLeaf, integrationMapping, facilitySharingStatus, hasMetrcLocationTypes} = this.props;
    return (
      <FormWrapper title={'locations.createSalesLocations.title'} goBack={this.props.actions.goBack}>
        <PrinterModal
          hidePrinter={this.hidePrinter}
          showPrinter={this.state.showPrinter}
          httpAction='get'
          labelUrl={this.state.labelUrl}
        />
        <ModalWrapper
          Component={ConfirmUnshareLocation}
          showModal={this.state.showUnshareConfirmation}
          onHide={this.onUnshareConfirmationHide}
          confirm={this.onUnshareConfirm}
          title={I18n.t('locations.unshareLocation.confirmationText')}
        />
        {this.renderConfirmModal()}
        <SetupSalesLocationsFormWrapper
          initialValues={initialValues}
          integratedLocationNames={integratedLocationNames}
          showIntegratedLocationName={showIntegratedLocationName}
          printLabel={this.printLabel}
          onSubmit={this.onSubmit}
          onIsSharedChange={this.onIsSharedChange}
          isLeaf={isLeaf}
          integrationMapping={integrationMapping}
          facilitySharingStatus={facilitySharingStatus}
          onDelete={this.onDelete}
          hasMetrcLocationTypes={hasMetrcLocationTypes}
        />
      </FormWrapper>);
  }
}

SetupSalesLocationsFormPage.propTypes = {
  actions: PropTypes.shape({
    getUnpaginatedData: PropTypes.func.isRequired,
    getPaginatedData: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    putData: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    touch: PropTypes.func.isRequired,
    setUserRetailLocation: PropTypes.func.isRequired
  }),
  integratedLocationNames: PropTypes.array.isRequired,
  showIntegratedLocationName: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  isLeaf: PropTypes.bool,
  integrationMapping: PropTypes.array,
  facilitySharingStatus: PropTypes.object,
  salesLocations: PropTypes.array,
  currentLocation: PropTypes.number,
  hasMetrcLocationTypes: PropTypes.bool,
};

function mapStateToProps(state) {
  const {integratedLocationNames, integrationMapping, facilitySharingStatus, user: {currentLocation}} = state;
  return {
    initialValues: getSetupInitialValues(state),
    integratedLocationNames,
    showIntegratedLocationName: isWaLeaf(state),
    isLeaf: isLeafIntegrator(state),
    integrationMapping,
    facilitySharingStatus,
    salesLocations: getSalesLocations(state),
    currentLocation,
    hasMetrcLocationTypes: hasMetrcLocationTypes(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {getUnpaginatedData, getPaginatedData, postData, putData, goBack, push, change, touch, getItem, getDataByPost, addMessage, setUserRetailLocation};
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SetupSalesLocationsFormPage);
