
export function normalizeMarijuanaId(value, prevValue) {
  if (!value) {
    return value;
  }
  value = String(value).replace(/-/g, '');
  if (/^\w+$/.test(value)) {
    return value;
  }
  return prevValue;
}

export function formatMarijuanaId(value) {
  value = normalizeMarijuanaId(value);
  if (!value) {
    return '';
  }
  return String(value)
    .split('')
    .reduce(
      (chunks, char, index) => {
        const chunkIndex = Math.floor(index / 4);
        if (chunkIndex > 3) {
          return chunks;
        }
        if (!chunks[chunkIndex]) {
          chunks[chunkIndex] = '';
        }
        chunks[chunkIndex] += char;
        return chunks;
      },
      []
    )
    .join('-');
}
