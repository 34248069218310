/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {push} from 'react-router-redux';
import {Button, ButtonToolbar} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';

import TablePageWrapper from '../common/grid/TablePageWrapper';
import PageTitle from '../common/PageTitle';
import SingleActionColumn from '../common/grid/columns/SingleActionColumn';
import ModalWrapper from '../common/ModalWrapper';

// import PermissionButton from '../common/PermissionButton';
import * as p from '../../constants/permissions';
import * as statuses from '../../constants/orderStatuses';
import * as apiActions from '../../actions/apiActions';
import * as UOMS from '../../constants/uoms';
import {handleComplexSelectRow} from '../../actions/helpers/selectedDataHelper';
import {clearSelectedDataAction} from '../../actions/selectedDataActions';
import {getSelectedFeedingSchedulesIds} from '../../selectors/feedingSchedulesSelectors';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import {convertFromBase} from '../../util/uomHelpers';
import {getTotalResults} from '../../selectors/paginationSelectors';
import InternationalDecimalStatic from '../common/form/InternationalDecimalStatic';
import InternationalCurrencyStatic from '../common/form/InternationalCurrencyStatic';

export class FeedingSchedulesPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);

    const columns = [
      {
        name: 'feedingSchedules.table.id',
        dataId: 'id',
        hidden: true,
      },
      {
        name: 'feedingSchedules.table.name',
        dataId: 'schedule_name',
        hidden: false,
      },
      {
        name: 'feedingSchedules.table.phase',
        dataId: 'stages',
        hidden: false,
        formatter: (cell) => {
          if (cell.length) {
            let phases = cell.map(item => item.stage_id ? I18n.t(`feedingSchedules.table.data.stages.${item.stage_id}`) || '' : '');
            phases = phases.map(phase => phase.charAt(0) || '');
            return phases.join(', ');
          } else {
            return '';
          }
        }
      },
      {
        name: 'feedingSchedules.table.frequency',
        dataId: 'frequency',
        hidden: false,
        formatter: (cell, row) => I18n.t('feedingSchedules.table.data.frequency', {
          frequency: row.frequency,
          time: row.time || row.time_base
        })
      },
      {
        name: 'feedingSchedules.table.costPerLiter',
        dataId: 'last_applied_cost_per_liter',
        hidden: false,
        formatter: (cell) => <InternationalCurrencyStatic>{cell}</InternationalCurrencyStatic>,
      },
      {
        name: 'feedingSchedules.table.liters',
        dataId: 'amount_per_feeding',
        hidden: false,
        formatter: (cell, row) => <InternationalDecimalStatic>{convertFromBase(row['amount_per_feeding_base'], row['amount_uom_display'] || UOMS.L)}</InternationalDecimalStatic>,
      },
      // [NOTE] will be done in other release
      // {
      //   name: 'feedingSchedules.table.viewIngredients',
      //   dataId: 'id',
      //   hidden: false,
      //   csvFormatter: (cell) => { return I18n.t('feedingSchedules.actions.viewIngredients'); },
      //   formatter: (cell, row) => {
      //     return cell === 0 ? '' :
      //       <Button >{I18n.t('feedingSchedules.actions.viewIngredients')} </Button>;
      //   }
      // },
      // {
      //   name: 'feedingSchedules.table.printFeeding',
      //   dataId: 'id',
      //   hidden: false,
      //   permissions: [p.print_labels],
      //   csvFormatter: (cell) => { return I18n.t('feedingSchedules.actions.printFeeding'); },
      //   formatter: (cell, row) => {
      //     return cell === 0 ? '' : <PermissionButton
      //     permissions={[p.print_labels]}
      //     props={{variant:'info'}}>
      //       {I18n.t('feedingSchedules.actions.printFeeding')}
      //     </PermissionButton>;
      //   }
      // },
    ];
    const activeColumns = [
      ...columns,
      {
        hidden: false,
        dataSort: false,
        permissions: [p.manage_feeding_schedules],
        csvFormatter: () => { return I18n.t('feedingSchedules.actions.edit'); },
        formatter: SingleActionColumn({
          label: 'feedingSchedules.actions.edit',
          action: (e, row) => this.editSchedule(e,row),
          permissions: [p.manage_feeding_schedules]
        }),
        columnClassName: 'actions-column',
      },
      {
        hidden: false,
        dataSort: false,
        permissions: [p.manage_feeding_schedules],
        csvFormatter: () => { return I18n.t('feedingSchedules.actions.status'); },
        formatter: SingleActionColumn({
          style: 'warning',
          label: 'feedingSchedules.actions.deactivate',
          action: (e, row) => this.toggleSchedule(e,row),
          permissions: [p.manage_feeding_schedules]
        }),
        columnClassName: 'actions-column',
      },
    ];
    const inactiveColumns = [
      ...columns,
      {
        hidden: false,
        dataSort: false,
        permissions: [p.manage_feeding_schedules],
        csvFormatter: () => { return I18n.t('feedingSchedules.actions.status'); },
        formatter: SingleActionColumn({
          style: 'success',
          label: 'feedingSchedules.actions.activate',
          action: (e, row) => this.disableFeedingSchedule(row.id),
          permissions: [p.manage_feeding_schedules]
        }),
        columnClassName: 'actions-column',
      },
    ];
    const actions = [{
      id: 'createFeedingSchedule', path: '/feeding-schedules/create',
      text: 'feedingSchedules.actions.createFeedingSchedule', glyph: 'plus-sign', requireSelect: false
    }];
    const tabs = [
      {
        id: 'activeTab',
        eventKey: statuses.activeSchedule,
        title: 'feedingSchedules.nav.active',
        path: '/feeding-schedules/active',
        actions
      },
      {
        id: 'inactiveTab',
        eventKey: statuses.inactiveSchedule,
        title: 'feedingSchedules.nav.inactive',
        path: '/feeding-schedules/inactive',
        actions
      }
    ];

    this.state = {
      rowToDisable: undefined,
      showDisableConfirm: false,
      activeColumns,
      inactiveColumns,
      activeTab: props.params.active === 'inactive' ? statuses.inactiveSchedule : statuses.activeSchedule,
      tabs
    };

    this.switchTab = this.switchTab.bind(this);
    this.handleSelect = this.handleSelect.bind(this);

    this.toggleSchedule = this.toggleSchedule.bind(this);
    this.disableFeedingSchedule = this.disableFeedingSchedule.bind(this);
    this.editSchedule = this.editSchedule.bind(this);
  }

  componentWillMount() {
    const params = {active: this.props.params.active === 'active' ? 1 : 0};
    this.props.actions.getUnpaginatedData('/api/schedules', dataNames.feedingSchedules, undefined ,params);
  }

  componentWillReceiveProps(nextProps, nextState){
    if(nextProps.params.active !== this.props.params.active){
      const params = {active: nextProps.params.active === 'active' ? 1 : 0};
      this.props.actions.getUnpaginatedData('/api/schedules', dataNames.feedingSchedules, undefined ,params);
    }
  }

  handleSelect(action, rows) {
    this.props.actions.handleComplexSelectRow(rows, dataNames.feedingSchedules, action);
  }

  switchTab(activeTab) {
    const tab = this.state.tabs.find(tab => tab.eventKey === activeTab) || this.state.tabs[0];
    this.setState({activeTab});
    this.props.actions.push(tab.path);
  }

  toggleSchedule(e, row){
    const query = 'matchall';
    const size = 1;
    const filter = `feeding_schedule_id: ${row.id} AND is_harvested: 0 AND is_packaged: 0 AND is_destroyed: 0`;

    this.props.actions.getPaginatedData('/api/search/plants', dataNames.plants, undefined, {query, size, filter},
      (data) => {
        if(data.length !== 0){
          this.setState({showDisableConfirm: true, rowToDisable:row});
        }else{
          this.disableFeedingSchedule(row.id);
        }
      });
  }

  disableFeedingSchedule(id) {
    const params = {active: this.props.params.active === 'active' ? 1 : 0};
    this.setState({showDisableConfirm: false});
    this.props.actions.putItem(`/api/schedules/${id}`,
      {id, active: params.active ? 0 : 1 },
      itemNames.feedingSchedule,
      {
        success:'feedingSchedules.disable.actions.success',
        failed: 'feedingSchedules.disable.actions.failed'
      },
      null,
      () => {
        this.props.actions.clearSelectedDataAction(itemNames.feedingSchedule);
        this.props.actions.getUnpaginatedData('/api/schedules', dataNames.feedingSchedules, undefined, params);
      }
    );
  }

  editSchedule(e, row){
    this.props.actions.push(`/feeding-schedules/modify/${row.id}`);
  }

  render() {
    const {feedingSchedules, selectedFeedingSchedules, dataTotalSize} = this.props;
    const {activeColumns, inactiveColumns, activeTab, tabs, showDisableConfirm} = this.state;

    const data = feedingSchedules.filter((feedingSchedule) => {
      return activeTab === statuses.activeSchedule ? feedingSchedule.active === 1 : feedingSchedule.active === 0;
    });
    return (
      <div>
        <PageTitle primaryText={I18n.t('facility.setup.feedingSchedules')} />
        <ModalWrapper showModal={showDisableConfirm}
          onHide={() => this.setState({showDisableConfirm: false, rowToDisable: undefined})}
          title={'feedingSchedules.disable.confirm'}
          Component={() =>
            <div style={{marginTop: '20px'}}>
              {I18n.t('feedingSchedules.disable.message', {
                name: this.state.rowToDisable ? this.state.rowToDisable.schedule_name : '',
                total: dataTotalSize
              })}
              <div className='clearfix'>
                <ButtonToolbar className={'float-right'}>
                  <Button variant={'danger'} onClick={() => this.disableFeedingSchedule(this.state.rowToDisable.id)}>
                    {I18n.t('yes')}
                  </Button>
                  <Button  onClick={() => this.setState({showDisableConfirm: false, rowToDisable: undefined})}>
                    {I18n.t('no')}
                  </Button>
                </ButtonToolbar>
              </div>
          </div>}
        />
        <TablePageWrapper
          ref={this.ref}
          settingKey='feeding-schedules'
          columns={activeTab === statuses.activeSchedule ? activeColumns : inactiveColumns}
          data={data}
          hideScanSearch={true}
          activeTab={activeTab}
          tabs={tabs}
          switchTab={this.switchTab}

          selectedRows={selectedFeedingSchedules}
          handleSelect={this.handleSelect}
          className='feedingSchedules-page'
          hideExport={true}
        />
      </div>
    );

  }
}

FeedingSchedulesPage.propTypes = {
  feedingSchedules: PropTypes.array.isRequired,
  selectedFeedingSchedules: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  dataTotalSize: PropTypes.number.isRequired
};

// Commented out in upgrade to react 16.2 - do not see that it was used.
// FeedingSchedulesPage.contextTypes = {
//   router: React.PropTypes.object.isRequired
// };

function mapStateToProps(state) {
  const {feedingSchedules} = state;
  return {
    selectedFeedingSchedules: getSelectedFeedingSchedulesIds(state),
    feedingSchedules,
    dataTotalSize: getTotalResults(state, {name: dataNames.plants}),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {handleComplexSelectRow, clearSelectedDataAction}, {push});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedingSchedulesPage);
