import React from 'react';
import {connect} from 'react-redux';
import {FieldArray, reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import { Card } from 'react-bootstrap';
import get from 'lodash.get';
import MmapPayments from './MmapPayments';


const Form = ({order, registers, customer, handleSubmit, reset, addMessage}) => {
  const reference = order.order_type === 'refund'
    ? get(order, 'products.0.refunded_order.order_id')  // For refunds use the ID of the original order as reference
    : get(order, 'id');                                 // For regular sale  use the order ID as reference
  return (
    <Card>
      <Card.Body>
        <h5><b>{I18n.t('cultivation.orderHistory.table.mmapPayments')}</b></h5>
        <form className='simple-form' onSubmit={handleSubmit}>
          <table className='table table-bordered table-striped product-detail-table'>
            <thead>
            <tr>
              <th>{I18n.t('cultivation.orderHistory.table.paymentMethod')}</th>
              <th>{I18n.t('cultivation.orderHistory.table.paymentAmount')}</th>
              <th></th>
            </tr>
            </thead>
            <FieldArray
              name='mmapPayments'
              component={MmapPayments}
              order={order}
              registers={registers}
              reference={reference}
              patientId={parseInt(get(customer, 'state_integration_tracking_id'), 10)}
              reset={reset}
              addMessage={addMessage}
              isRefund={order.order_type === 'refund'}
            />
          </table>
        </form>
      </Card.Body>
    </Card>
  );

};

Form.propTypes = {
  order: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  registers: PropTypes.array.isRequired,
  customer: PropTypes.object.isRequired,
  addMessage: PropTypes.func.isRequired
};

const ReduxMmapPaymentsForm = reduxForm({
  enableReinitialize: true,
  destroyOnUnmount: false,
})(Form);

const MmapPaymentsForm = connect(() => {

})(ReduxMmapPaymentsForm);

export default MmapPaymentsForm;
