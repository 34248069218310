import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import TrWrapper from './TrWrapper';
import {showSection} from '../../../selectors/productHistorySelector';
import SaleRowByOrgLevel from './SaleRowByOrgLevel';
import {WHOLESALE} from '../../../constants/saleTypes';

const SaleRowHistory = (props) => {
  const {title, delta, type, show} = props;
  if (!show) return null;
  const showOrgLevel = showSection(type, delta, ['organization']);
  const showFacilityLevel = showSection(type, delta, ['facility']);

  return (<TrWrapper>
    <tr>
      <td colSpan={2}>
        <h4>{I18n.t(title)}</h4>
      </td>
    </tr>
    {showOrgLevel ?
      <TrWrapper>
        <tr>
          <td colSpan={2}>
            <h5>{I18n.t('products.form.organization')}</h5>
          </td>
        </tr>
        <SaleRowByOrgLevel delta={delta} prefix={`organization_${type === WHOLESALE ? WHOLESALE : ''}_${type === WHOLESALE ? '' : type}`}/>
      </TrWrapper>
      : null}
    {showFacilityLevel ?
      <TrWrapper>
        <tr>
          <td colSpan={2}>
            <h5>{I18n.t('products.form.byFacility')}</h5>
          </td>
        </tr>
        <SaleRowByOrgLevel delta={delta} prefix={`facility_${type === WHOLESALE ? WHOLESALE : ''}_${type === WHOLESALE ? '' : type}`}/>
      </TrWrapper>
      : null}
  </TrWrapper>);
};

SaleRowHistory.propTypes = {
  title: PropTypes.string.isRequired,
  delta: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired
};

export default SaleRowHistory;
