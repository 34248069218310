import React from 'react';
import { I18n } from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import {Field} from 'redux-form';
import PropTypes from 'prop-types';

import AccordionPanel from '../../common/AccordionPanel';

import ReactSelectInput from '../../common/form/ReactSelectInput';

import TextAreaInput from '../../common/form/TextAreaInput';
import InternationalDecimalInput from '../../common/form/InternationalDecimalInput';
import HoverTooltip from '../../common/HoverTooltip';


const AdditionalInformationSection = (props) => {
  const {solventOptions, commonOptions, dilutionOptions, tagsActions, fieldCanModifiedMap, change, fieldActions} = props;

  return (
    <AccordionPanel title={'products.massModify.additionalInformationTitle'}>
      <span id={'add_info_section'}/>
      <Row>
        <Col md={3}>
          <InternationalDecimalInput name='serving_size' props={{
            label: I18n.t('products.form.servingSize'),
            rightAddon: fieldCanModifiedMap.serving_size.length ?
              <HoverTooltip text={I18n.t('products.massModify.errors.diff_categories_help', {field: I18n.t('products.form.servingSize'), categories: fieldCanModifiedMap.serving_size.join(' ,')})} id={'serving_size_tooltip'}/> : null
          }}/>
        </Col>
        <Col md={3}>
          <InternationalDecimalInput name='servings_per_container'  props={{
            label: I18n.t('products.form.servingsPerContainer'),
            rightAddon: fieldCanModifiedMap.servings_per_container.length ?
              <HoverTooltip text={I18n.t('products.massModify.errors.diff_categories_help', {field: I18n.t('products.form.servingsPerContainer'), categories: fieldCanModifiedMap.servings_per_container.join(' ,')})} id={'servings_per_container_tooltip'}/> : null
          }}/>
        </Col>
        <Col md={3}>
          <Field name='solvent' component={ReactSelectInput} props={{
            label: I18n.t('products.form.solvent'),
            options: solventOptions,
            rightAddon: fieldCanModifiedMap.solvent.length ?
              <HoverTooltip text={I18n.t('products.massModify.errors.diff_categories_help', {field: I18n.t('products.form.solvent'), categories: fieldCanModifiedMap.solvent.join(' ,')})} id={'solvent_tooltip'}/> : null
          }}/>
        </Col>
        <Col md={3}>
          <InternationalDecimalInput name='pieces_per_unit'  props={{
            label: I18n.t('products.form.piecesPerUnit'),
            placeholder: I18n.t('products.form.amountPlaceholder'),
            rightAddon: fieldCanModifiedMap.pieces_per_unit.length ?
              <HoverTooltip text={I18n.t('products.massModify.errors.diff_categories_help', {field: I18n.t('products.form.piecesPerUnit'), categories: fieldCanModifiedMap.pieces_per_unit.join(' ,')})} id={'pieces_per_unit_tooltip'}/> : null
          }}/>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <Field name='requires_refrigeration'  component={ReactSelectInput} props={{
            options: commonOptions,
            label:I18n.t('products.form.requiresRefrigeration')
          }}/>
        </Col>
        <Col md={3}>
          <Field name='dilution_method' component={ReactSelectInput} props={{
            label: I18n.t('products.form.dilutionMethod'),
            options: dilutionOptions,
            rightAddon: fieldCanModifiedMap.dilution_method.length ?
              <HoverTooltip text={I18n.t('products.massModify.errors.diff_categories_help', {field: I18n.t('products.form.dilutionMethod'), categories: fieldCanModifiedMap.dilution_method.join(' ,')})} id={'dilution_method_tooltip'}/> : null
          }}/>
        </Col>
      </Row>
      <Row>
        <Col md={2}>
          <Field name='ingredients_action' component={ReactSelectInput} props={{
            label: I18n.t('products.form.ingredients'),
            options: tagsActions,
            onChange: (value) => {
              if (value === 'clear') {
                change(`ingredients`, '');
              }
              change(`ingredients_action`, value);
            },
          }}/>
        </Col>
        <Col md={{span: 2, offset: 2}}>
          <Field name='allergens_action' component={ReactSelectInput} props={{
            label: I18n.t('products.form.allergens'),
            options: tagsActions,
            onChange: (value) => {
              if (value === 'clear') {
                change(`allergens`, '');
              }
              change(`allergens_action`, value);
            },
          }}/>
        </Col>
        <Col md={{span: 2, offset: 2}}>
          <Field name='nutritional_info_action' component={ReactSelectInput} props={{
            label: I18n.t('products.form.nutritional'),
            options: tagsActions,
            onChange: (value) => {
              if (value === 'clear') {
                change(`nutritional_info`, '');
              }
              change(`nutritional_info_action`, value);
            },
          }}/>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Field name='ingredients' component={TextAreaInput} props={{
            placeholder: I18n.t('products.form.ingredientsPlaceholder'),
            rows: 5,
            disabled: fieldActions.ingredients_action === 'clear'
          }}/>
        </Col>
        <Col md={4}>
          <Field name='allergens' component={TextAreaInput} props={{
            placeholder: I18n.t('products.form.allergensPlaceholder'),
            rows: 5,
            disabled: fieldActions.allergens_action === 'clear'
          }}/>
        </Col>
        <Col md={4}>
          <Field name='nutritional_info' component={TextAreaInput} props={{
            placeholder: I18n.t('products.form.nutritionalPlaceholder'),
            rows: 5,
            disabled: fieldActions.nutritional_info_action === 'clear'
          }}/>
        </Col>
      </Row>
    </AccordionPanel>
  );
};

AdditionalInformationSection.propTypes = {
  solventOptions: PropTypes.array.isRequired,
  commonOptions: PropTypes.array.isRequired,
  dilutionOptions: PropTypes.array.isRequired,
  tagsActions: PropTypes.array.isRequired,
  fieldCanModifiedMap: PropTypes.object.isRequired,
  fieldActions: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired
};

export default AdditionalInformationSection;
