import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {Modal, Button, Row, Col} from 'react-bootstrap';
import {FaExclamationTriangle, FaPrint} from 'react-icons/fa'; // eslint-disable-line import/no-named-as-default
import {push} from 'react-router-redux';
import get from 'lodash.get';
import * as apiActions from '../../actions/apiActions';
import {getQzTray, signQzRequest} from '../../actions/printerActions';
import * as itemActions from '../../actions/itemActions';
import * as itemNames from '../../constants/itemNames';
import {getDefaultLabelType} from '../../selectors/labelsSelectors';
import MessageModal from '../common/modal-messages/MessageModal';
import LoadingBar from './LoadingBar';
import SampleImages from './SampleImages';
import LabelTypeBox from './LabelTypeBox';
import {LABEL_TYPES} from '../../constants/labelTypes'; //eslint-disable-line import/prefer-default-export
import SetPrinterModal from './setprinter/SetPrinterModal'; // eslint-disable-line import/no-named-as-default
import SetDefaultLabel from './default-label/SetDefaultLabel'; // eslint-disable-line import/no-named-as-default

let autoPrint = 0;

export class PrinterModal extends React.PureComponent {

  constructor(props, context) {

    super(props, context);

    this.getLabel       = this.getLabel.bind(this);
    this.getLegacyLabel = this.getLegacyLabel.bind(this);

    this.onEnter    = this.onEnter.bind(this);
    this.onExit     = this.onExit.bind(this);
    this.printLabel = this.printLabel.bind(this);
    this.onHide     = this.onHide.bind(this);

    this.setPrintXTimes = this.setPrintXTimes.bind(this);
    this.doPrint = this.doPrint.bind(this);
    this.printTestLabel = this.printTestLabel.bind(this);
    this.setTagInState = this.setTagInState.bind(this);
    this.getSelectedLabel = this.getSelectedLabel.bind(this);

    this.onChangeTab = this.onChangeTab.bind(this);
    this.setViewPrintQIndex = this.setViewPrintQIndex.bind(this);
    this.onLoadBlock = this.onLoadBlock.bind(this);
    this.printBlock = this.printBlock.bind(this);

    this.printSingleInBlock = this.printSingleInBlock.bind(this);
    this.printFromInBlock = this.printFromInBlock.bind(this);

    this.onShowPrinterSettings = this.onShowPrinterSettings.bind(this);
    this.hidePrinterSettingsCallback = this.hidePrinterSettingsCallback.bind(this);
    this.getPrinterForLabel = this.getPrinterForLabel.bind(this);
    this.reconnectQzTray = this.reconnectQzTray.bind(this);

    this.onSelectLabelType = this.onSelectLabelType.bind(this);
    this.onToggleLabelTypes = this.onToggleLabelTypes.bind(this);
    this.onToggleShowLabelDetails = this.onToggleShowLabelDetails.bind(this);

    this.isAutoPrintEnabled = this.isAutoPrintEnabled.bind(this);
    this.isAutoPrintAvailable = this.isAutoPrintAvailable.bind(this);

    this.testPrintZpl = this.testPrintZpl.bind(this);
    this.testPrintImage = this.testPrintImage.bind(this);
    this.showQzError = this.showQzError.bind(this);


    this.state = {

      // Legacy - DO NOT USE IN DEVELOPMENT - SEE PROPS
      labelTag: this.props.labelTag,
      labelIds: this.props.labelIds,

      // Printer Modal dependencies
      label      : {},
      showPrinter: this.props.showPrinter,
      showModal  : false,
      modalTitle : '',
      modalType  : 'error',
      modalText  : '',

      // Print dependencies (internal)
      //qz            : QzTrayConfig(),
      qzLoaded      : false,
      printers      : [],
      defaultPrinter: '',
      currentPrinter: '',
      printXTimes   : 1,
      selectedTag : false,

      // Status dependencies (internal)
      connecting   : false,
      printing     : false,
      loadingSample: false,
      loadingError : false,

      loadQIndex: 0,
      loadQ: [],
      printQ: [],
      zplQ: [],
      namesQ: [],
      currentTab: 'sample',
      isMultiple: true,
      viewPrintQIndex: 0,
      httpAction: false,
      usingQ: false,
      url: false,

      queueSize: 50,
      forceLabelBlocks: false,

      usePrinter: false,
      labelName: '',
      statusBar: 'Printer Selected and Qz Tray Present: Ready to Print...',
      statusFlag: 'success',
      labelKey: '',
      showSettings: false,
      secondsUntilQzIsInvalid: 10,

      qzTrayConnected: false,
      printerAvailable: false,
      printerMessage: '',
      moreInfo: '',
      resolution: 203,
      qzTrayRunning: false,

      currentTag: '',
      primaryTag: '',
      checkDefaultTag: true,

      labelType: '',
      showLabelTypes: false,

      showLabelDetails: false,

      printSilent: 0,
      forceShow: false,

      qzTrayTestingEnabled: true,
    };

  }

  componentWillReceiveProps(nextProps){
    if(!this.state.labelType && nextProps.defaultLabelType){
      this.setState({labelType: nextProps.defaultLabelType});
    }
    if(autoPrint !== nextProps.autoPrint && nextProps.autoPrint){
      autoPrint = nextProps.autoPrint;
      this.onEnter();
    }
  }

  onEnter() {

    // If this.state.forceShow we are in auto print that can't be completed... this part has already been done.
    if(this.state.forceShow) return false;

    // Gets instance of qz tray
    this.props.actions.getQzTray(this.props.actions.signQzRequest);

    let newState = {
      forceLabelBlocks: this.props.forceLabelBlocks === undefined ? false : this.props.forceLabelBlocks,
      loadQ: [],
      printQ: [],
      zplQ: [],
      namesQ: [],
      viewPrintQIndex: 0,
      loadQIndex: 0,
      isMultiple: true,
      qzTrayConnected: false,
      printerAvailable: false,
      currentTag: '',
      checkDefaultTag: true,
    };

    //this.getPrinterForLabel();

    if(this.state.printers.length === 0){
      const noPrinterState = {currentPrinter: '', defaultPrinter: '', qzLoaded: false, qz: false, loadingError: false, url: false};
      newState = Object.assign({}, newState, noPrinterState);
    } else {
      const havePrinterState = {loadingError: false, url: false};
      newState = Object.assign({}, newState, havePrinterState);
    }

    setTimeout( () => {
      const setState = (newState) => {
        this.setState(newState, () => {
          if (this.props.labelTag && this.props.labelIds) return this.getLegacyLabel();
          this.prepareQueues();
        });
      };
      if(!this.state.qzTrayRunning){
        this.qzTrayIsRunning().then(() => {
          newState.qzTrayRunning = true;
          setState(newState);
        }).catch(() => {
          setState(newState);
        });
      } else {
        setState(newState);
      }
    }, 100);

    this.props.actions.unsetItem(itemNames.label);

    this.props.actions.getItem('/api/labels/compliance_settings', itemNames.labelsCompliance);

  }

  onHide(onExit = false) {
    this.setState({showModal: false, forceShow: false});
    if(typeof this.props.hidePrinter === 'function') this.props.hidePrinter(onExit);
  }

  onExit() {
    this.onHide(true);
    this.props.actions.unsetItem(itemNames.label);
  }

  setTagInState(event, getTagImmediately = false){
    this.setState({selectedTag: event.target.value}, () => {
      if(getTagImmediately) this.getSelectedLabel();
    });
  }


  /// GET LABEL METHODS

  getLegacyLabel() {

    const url       = ('/api/labels/generate/[TAGNAME]/for/many').replace('[TAGNAME]', this.props.labelTag);
    const payload = {
      ids: this.props.labelIds
    };
    this.setState({httpAction: 'POST', payload, url: url}, () => { // No promise?!
      this.prepareQueues(url, payload);
    });

  }

  getSelectedLabel(tag = false){

    if(typeof tag !== 'string') {
      this.setState({currentTag: this.state.selectedTag});
      tag = this.state.selectedTag;
    }

    const httpAction = (this.state.httpAction) ? this.state.httpAction : this.props.httpAction ? this.props.httpAction : 'get';

    if(httpAction.toLowerCase() === 'post'){

      const url       = ('/api/labels/generate/[TAGNAME]/for/many').replace('[TAGNAME]', tag);
      const payload = {
        ids: (this.props.labelIds !== undefined) ? this.props.labelIds : this.props.payload.ids
      };

      this.setState({httpAction: 'POST', payload, url: url}, () => { // No promise?!
        this.prepareQueues(url, payload);
      });


    } else { // Added for get requests to support orders iteration

      // replace 4th element with the selected tag
      let url = get(this.props,'labelUrl','').split('/');
      url[4] = tag;
      url = url.join('/');

      this.setState({httpAction: 'GET', payload: {}, url: url}, () => {
        this.getLabel(url, {});
      });

    }

  }

  prepareQueues(url, payload){

    const httpAction = (this.state.httpAction) ? this.state.httpAction : this.props.httpAction ? this.props.httpAction : 'get';

    const requestType = httpAction ? httpAction.toLowerCase() : 'get';

    if(requestType === 'get') return this.getLabel(url, payload);

    payload = (payload !== undefined) ? payload : this.props.payload;

    if(payload && payload.ids === undefined && payload.namedBlocks === undefined) return this.getLabel(url, payload);

    if(this.state.loadQ.length > 0) return this.getLabel(url, payload);

    let newState;

    if(!payload || payload.namedBlocks === undefined) {
      newState = this.createSizeBasedQueues(payload.ids, true); // true = setState
    } else {
      newState = this.createNameBasedQueues(payload.namedBlocks);
    }

    this.setState(newState, () => {
      this.getLabel(url, payload);
    });

  }

  createSizeBasedQueues(ids, setState) {
    ids = ids.map((id) => parseInt(id)).sort();
    const loadQ   = [], printQ = [], zplQ = [];
    const qSize   = this.state.queueSize;
    const size    = ids.length;
    const qBlocks = (size > qSize) ? Math.ceil(size / qSize) : 1;

    for (let n = 0; n < qBlocks; n++) {

      const start = n * qSize;
      const end   = (n * qSize) + qSize;

      loadQ.push(ids.slice(start, end));
      printQ.push([]);
      zplQ.push({zpl: '', printed: false});

    }

    if (!setState) return {loadQ, printQ, zplQ};

    return {loadQ: loadQ, printQ: printQ, loadQIndex: 0, zplQ: zplQ, usingQ: true};

  }

  createNameBasedQueues(namedBlocks) {

    const loadQ = [], printQ = [], zplQ = [], namesQ = [];

    namedBlocks.forEach((namedBlock) => {

      const response = this.createSizeBasedQueues(namedBlock.ids, false); // false indicates no set state and return
      const name     = namedBlock.name;

      response.loadQ.forEach((load, index) => {
        loadQ.push(load);
        printQ.push(response.printQ[index]);
        zplQ.push(response.zplQ[index]);
        namesQ.push({name: name});
      });

    });

    return {loadQ: loadQ, printQ: printQ, loadQIndex: 0, zplQ: zplQ, namesQ: namesQ, usingQ: true};

  }

  setViewPrintQIndex(index){

    this.setState({viewPrintQIndex: index});

  }

  getLabel(url, payload) {

    this.setState({loadingSample: true});

    const httpAction = this.state.httpAction || this.props.httpAction || '';
    const noMessages = {failed: I18n.t('common.errorLabelLoad')};
    const noParams   = (this.props.params === undefined) ? {} : this.props.params;
    const method     = (httpAction.toLowerCase() === 'get') ? 'getLabelByGetRequest' : 'getLabelByPostRequest';
    const that       = this;

    payload = (payload !== undefined) ? payload : this.props.payload;

    url = (url !== undefined) // If url was passed in
      ? url
      : this.state.url // If url was put into state - in which case changed from original by selection
        ? this.state.url
        : get(this.props,'labelUrl',''); // Finally use original if no other option

    const callIndex = this.state.loadQIndex;

    if(this.state.loadQ.length > 0){
      if(payload === undefined){
        payload = {ids: []};
      }
      if(this.state.loadQ.length < this.state.loadQIndex) return false;
      payload.ids = this.state.loadQ[this.state.loadQIndex];
      delete(payload.namedBlocks);
    }

    const handleAutoPrinting = () => {
      if(this.isAutoPrintEnabled() && !this.isAutoPrintAvailable()) this.setState({forceShow: true});
    };

    const printer = this.getPrinterForLabel();

    // Handle case where there is a default set... in which case we just parse it into the initial URL call
    // getPrinterForLabel has already set the primaryTag
    if(this.state.checkDefaultTag) { // Only check on first pass - true is set on open and is the default in state
      const temp = url.split('/');
      const defaultTag = SetDefaultLabel.getDefaultLabel(temp[4], this.props.facility);
      const newState = {checkDefaultTag: false};
      if (defaultTag) {
        newState.currentTag = defaultTag;
        newState.selectedTag = defaultTag;
        temp[4] = defaultTag;
        url = temp.join('/');
      }
      this.setState(newState, () => {
        handleAutoPrinting();
      });
    } else {
      handleAutoPrinting();
    }

    const handleSuccess = (data) => {
      const newState = {loadingSample: false, loadingError: false, isMultiple: false};
      if(!data.is_multiple && data.tag){ // If you only have 1 label in play this reloads as a selected label
        newState.currentTag = data.tag;
        newState.selectedTag = data.tag;
        that.setState(newState, () => {
          this.getSelectedLabel();
        });
        return true;
      }
      if(data.emptyMessage === undefined) {
        if (parseInt(data.is_multiple) === 1) {
          newState.isMultiple = true;
        } else {
          if (this.state.usingQ) {
            const printQ        = this.state.printQ.map((arr) => arr);
            const zplQ          = this.state.zplQ.map((arr) => arr);
            printQ[callIndex]   = data.data;
            zplQ[callIndex].zpl = data.zpl;
            newState.printQ     = printQ;
            newState.zplQ       = zplQ;
          }
        }
      }
      if(this.isAutoPrintEnabled() && this.isAutoPrintAvailable()) this.printLabel();
      that.setState(newState);
    };

    const handlePromise = () => {
      setTimeout( () => { // Allow time for the reducer to run... uncertain if it cedes control on long loops
        if (that.state.loadingSample) that.setState({loadingSample: false, loadingError: true});
      }, 500);
    };

    if(noParams.resolution === undefined){
      noParams.resolution = (printer) ? printer.dpi : 203;
    }
    this.setState({resolution: noParams.resolution});
    const args = [url, payload, noMessages, noParams, handleSuccess, handlePromise];
    this[method].apply(this, args);
  }

  getLabelByGetRequest(url, payload, messages, params, handleSuccess, handlePromise) {
    // payload intentionally not used for get requests but args go to both post and get
    params.label_type = this.state.labelType;
    if(this.props.labelParams) params = Object.assign({}, params, this.props.labelParams);
    this.props.actions.getItem(url, itemNames.label, messages, params, handleSuccess).catch(handlePromise);
  }

  getLabelByPostRequest(url, payload, messages, params, handleSuccess, handlePromise) {
    params.label_type = this.state.labelType;
    if(this.props.labelParams) params = Object.assign({}, params, this.props.labelParams);
    this.props.actions.postItem(url, payload, itemNames.label, messages, params, handleSuccess).catch(handlePromise);
  }

  onLoadBlock(index){
    this.setState({loadQIndex: index}, () => {
      this.getLabel();
    });

  }

  onChangeTab(tab){
    this.setState({currentTab: tab});
  }

  reconnectQzTray(){
    if(this.props.qzTray.websocket.isActive()){
      this.props.qzTray.websocket.disconnect();
    }
    this.setState({qzTrayConnected: false, printerAvailable: false, printerMessage: '', moreInfo: 'Trying to reconnect to QZ Tray...'}, () => {
      this.getPrinterForLabel();
    });
  }

  getPrinterForLabel(){

    const url = this.state.url // If url was put into state - in which case changed from original by selection
      ? this.state.url
      : get(this.props,'labelUrl',''); // Finally use original if no other option

    const temp = url.split('/');
    let currentTag = '';
    let tag = currentTag = temp[4];

    // Handle configurable/customizable labels where the id has been appended to the tag name
    const testForConfigurable = tag.split('_');
    if(!isNaN(parseInt(testForConfigurable[testForConfigurable.length - 1]))){
      testForConfigurable.pop();
      tag = testForConfigurable.join('_');
    }

    const primaryTag = tag;

    let labelKey = this.state.labelKey;

    if(!labelKey) {
      for (const prop in LABEL_TYPES) {
        LABEL_TYPES[prop].tags.forEach((collection) => {
          if (collection.indexOf(tag) !== -1) labelKey = prop;
        });
      }

      this.setState({primaryTag, currentTag});

      if (!labelKey) {
        this.setState({moreInfo: 'Base label definition was not found.  Contact us immediately.  This is a mis-configuration.'});
        return false;
      }

    }

    const labelName = LABEL_TYPES[labelKey].name;

    const allSessionPrinters = sessionStorage.getItem('printerSettings');
    const allPrinters = localStorage.getItem('printerSettings');

    let parsedPrinters = [];
    let sessionPrinters = null;
    let printers = null;

    if(allSessionPrinters !== null){
      parsedPrinters = JSON.parse(allSessionPrinters);
      if(parsedPrinters[labelKey] !== undefined) sessionPrinters = parsedPrinters[labelKey];
    }

    if(allPrinters !== null){
      parsedPrinters = JSON.parse(allPrinters);
      if(parsedPrinters[labelKey] !== undefined) printers = parsedPrinters[labelKey];
    }

    const printerArray = (sessionPrinters !== null) ? sessionPrinters : (printers !== null) ? printers : [];

    const usePrinter = printerArray.reduce((acc, printer) => {
      if(acc) return acc;
      if(printer.selected) return printer;
      return acc;
    }, false);

    this.setState({currentPrinter: usePrinter.name, usePrinter, labelName, labelKey});

    this.qzTrayIsRunning()
      .then(() => {
        this.setState({statusBar: 'Ready To Print... QZ Tray Present!', statusFlag: 'success', qzTrayConnected: true}, () => {
          this.props.qzTray.printers.find()
            .then((data) => {
              if(!usePrinter){
                this.setState({
                  moreInfo: 'You have no printer set for this label.  Click Printer Settings to set a printer for this label.',
                  printerAvailable: true,
                  printerMessage: ''
                });
                return true;
              }
              const foundPrinter = data.find( (printer) => {
                return printer === usePrinter.name;
              });
              if(foundPrinter) {
                this.setState({
                  moreInfo: '',
                  printerAvailable: true,
                  printerMessage: ''
                });
                return true;
              }
              this.setState({
                moreInfo: 'Your selected printer for this label was not found in QZ Tray.  Click Printer Settings to adjust your selected printer.',
                printerAvailable: true,
                printerMessage: ''
              });
            })
            .catch(() => {
              this.setState({
                moreInfo: 'No printers were found in QZ Tray.  Check your computer printer settings or restart QZ Tray and then click Reconnect QZ Tray.',
                printerAvailable: false,
                printerMessage: 'Cannot find printers in QZ Tray'
              });
            });
        });
      })
      .catch(() => {
        this.setState({
          moreInfo: 'We cannot connect to QZ Tray. Ensure it is installed on your computer or device and is turned on.',
          qzTrayConnected: false,
          printerAvailable: false,
          printerMessage: ''
        });
      });

    return usePrinter;

  }

  /// New QZ Methods
  // QZ Methods

  /***
   * Test for active status... if not active... then connect
   */
  qzTrayIsRunning() {

    this.setState({qzLoaded: true});

    return new Promise( (resolve, reject) => {

      this.setState({statusBar: true});

      if(this.props.qzTray.websocket.isActive()){
        resolve();
      } else {
        this.catchQzTrayHung(); // Qz if hung never completes promise, trap with timeout
        this.props.qzTray.websocket.connect()
          .then(() => {
            this.clearQzTrayHung();
            resolve();
          })
          .catch((e) => {
            this.clearQzTrayHung();
            reject(e);
          });
      }

    });

  }

  /***
   * QZ Tray can hang in which cases promises never resolve and leaves us permanently loading.
   */
  catchQzTrayHung(){
    const connectTimeout = setTimeout(() => {
      this.showQzError('qzTraySuspended');
      this.setState({connectTimeout: false});
    }, this.state.secondsUntilQzIsInvalid * 1000);
    this.setState({connectTimeout: connectTimeout});
  }

  showQzError(error){

    let newState = {};

    switch(error){
    case 'noQzTray':
      newState = {
        showError : true,
        title: I18n.t('common.errorConnectingQzTrayTitle'),
        message : I18n.t('common.errorConnectingQzTrayMessage'),
        connecting: false
      };
      break;
    case 'noPrinters':
      newState = {
        showError : true,
        title: I18n.t('common.errorNoPrintersFoundTitle'),
        message : I18n.t('common.errorNoPrintersFound'),
        connecting: false
      };
      break;
    case 'qzTraySuspended':
      newState = {
        showError : true,
        title: I18n.t('common.errorQzTrayNotResponding'),
        message : I18n.t('common.errorQzTrayNotResponding'),
        connecting: false
      };
      break;
    }
    this.setState(newState);

  }

  /***
   * Clear the timeout for hung state
   */
  clearQzTrayHung(){
    if(!this.state.connectTimeout) return true;
    clearTimeout(this.state.connectTimeout);
    this.setState({connectTimeout: false});
  }

  //// PRINT METHODS
  setPrintXTimes(event){
    let val = parseInt(event.target.value);
    if(val < 1) val = 1;
    this.setState({printXTimes: val});
  }

  printTestLabel(){

    const data = [this.props.label.single];
    this.doPrint(data);

  }

  printLabel(){

    let data = [this.props.label.zpl_array.join('')];
    if(this.props.label.count === 1 && this.state.printXTimes > 1){
      data = '';
      for(let i = 0; i < this.state.printXTimes; i++){
        data += this.props.label.single;
      }
      data = [data];
    }
    this.doPrint(data);

  }

  doPrint(data){

    const qz = this.props.qzTray;
    this.setState({printing: true});
    // Pass the printer name into the next Promise
    const config = qz.configs.create(this.state.currentPrinter);
    qz.print(config, data).then(() => {
      setTimeout( () => {
        this.setState({printing: false});
      },1000);
    }).catch( () => {});


    return true;

  }

  printBlock(index){

    const data = [this.state.zplQ[index].zpl];

    const zplQ = this.state.zplQ.map( (zpl) => zpl);
    zplQ[index].printed = true;

    const qz = this.props.qzTray;
    this.setState({printing: true});
    // Pass the printer name into the next Promise
    const config = qz.configs.create(this.state.currentPrinter);
    qz.print(config, data).then(() => {
      setTimeout( () => {
        this.setState({printing: false, zplQ: zplQ});
      },1000);
    }).catch( () => {});

    return true;

  }

  getZplFromString(index, end){

    if(end === -1) end = this.state.printQ[this.state.viewPrintQIndex].length;
    const zpl = this.state.zplQ[this.state.viewPrintQIndex].zpl;
    const zpls = zpl.split('^XZ');
    const single = zpls.slice(index, end);
    return [single.join('^XZ') + '^XZ'];

  }

  printSingleInBlock(index){

    const data = this.getZplFromString(index, index + 1);

    const qz = this.props.qzTray;
    this.setState({printing: true});

    const config = qz.configs.create(this.state.currentPrinter);
    qz.print(config, data).then(() => {
      setTimeout(() => {
        this.setState({printing: false});
      },1000);
    }).catch(() => {});

    return true;

  }

  printFromInBlock(index){

    const data = this.getZplFromString(index, -1);

    const qz = this.props.qzTray;
    this.setState({printing: true});

    const config = qz.configs.create(this.state.currentPrinter);
    qz.print(config, data).then(() => {
      setTimeout(() => {
        this.setState({printing: false});
      },1000);
    }).catch(() => {});

    return true;

  }

  // Printer Settings Methods
  onShowPrinterSettings(){
    this.setState({showSettings: true});
  }

  hidePrinterSettingsCallback(){
    const printer = this.getPrinterForLabel();
    if(this.state.resolution !== printer.dpi){
      this.getLabel();
    }
    this.setState({showSettings: false});
  }

  onSelectLabelType(type){
    this.setState({labelType: type}, () => {
      this.getLabel();
    });
  }

  onToggleLabelTypes(){
    this.setState({showLabelTypes: !this.state.showLabelTypes});
  }

  onToggleShowLabelDetails(label){
    const show = !this.state.showLabelDetails;
    const selectedLabel = (show)
      ? Array.isArray(this.props.label.labels)
        ? this.props.label.labels.find((l) => JSON.stringify(l.label) === JSON.stringify(label))
        : {label: this.props.label}
      : null;
    //const selectedLabel = (show) ? this.props.label.labels.find((l) => JSON.stringify(l.label) === JSON.stringify(label)) : null;
    this.setState({showLabelDetails: show, selectedLabel: (show) ? selectedLabel.label : null});
  }

  isAutoPrintEnabled() {
    return this.props.autoPrint && this.props.showPrinter && !this.state.forceShow;
  }

  isAutoPrintAvailable(returnReason = false){
    if(!this.isAutoPrintEnabled()) return false;
    if(!returnReason) {
      return this.state.qzTrayRunning && this.state.usePrinter && this.state.usePrinter.autoPrint && this.state.currentTag;
    }
    if(returnReason){
      return !this.state.qzTrayRunning
        ? 'QZ Tray is not connected; cannot proceed with auto printing.'
        : !this.state.usePrinter
          ? 'No default printer is selected; cannot proceed with auto printing.'
          : !this.state.usePrinter.autoPrint
            ? 'Auto Print has been disabled.'
            : 'No default label is selected; select a label and set it as the default.';
    }
  }

  //Send ZPL - never got it to work
  testPrintZpl(){
    const printWindow = window.open();
    printWindow.document.open('text/plain');
    printWindow.document.write(this.props.label.zpl);
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  }

  //Also didn't work with Zebra but did with others though scaling is a mess.
  testPrintImage(){
    const html = `<html lang='en'><body><img alt='' src='data:image/png;base64,${this.props.label.image.base64}' /></body></html>`;
    const printWindow = window.open();
    printWindow.document.open();
    printWindow.document.write(html);
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  }


  render() {

    const facilityCanTestQzTray = this.props.facility.street_address_2 === '8022237240';

    return (
      <React.Fragment>
        <SetPrinterModal
          show={this.state.showSettings}
          setForLabel={this.state.labelKey}
          onHideCallback={this.hidePrinterSettingsCallback}
        />
        <MessageModal
          onHide={this.onHide}
          dialogClassName='modal-sm'
          showModal={this.state.showModal}
          title={this.state.modalTitle}
          message={this.state.modalText}
          modalType={this.state.modalType}
        />
        <Modal onEnter={this.onEnter} show={(this.props.showPrinter && !this.props.autoPrint) || this.state.forceShow} onExit={this.onExit} onHide={this.onHide} className='printer-modal'>
          <Modal.Body>
            <Row>
              <Col md='9'>
                <strong>QZ Tray:&nbsp;</strong>
                <span className={this.state.qzTrayConnected ? 'text-success' : 'text-danger'}>
                  {this.state.qzTrayConnected ? 'Running' : 'Not Found'}
                </span>
              </Col>
              <Col md='3' className='float-right text-right'>
                <a className={this.state.qzTrayConnected ? 'hide' : ''}
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    event.target.blur();
                    this.reconnectQzTray();
                  }}>
                  Reconnect QZ Tray
                </a>
              </Col>
            </Row>
            <Row>
              <Col md='6'>
                <strong>Label:</strong> {this.state.labelName}
              </Col>
              <Col md='6' className='float-right text-right'>
                <SetDefaultLabel
                  primaryTag={this.state.primaryTag}
                  isMultiple={this.state.isMultiple}
                  currentTag={this.state.currentTag}
                  facility={this.props.facility}
                  getSelectedLabel={this.getSelectedLabel} />
              </Col>
            </Row>
            <Row>
              <Col md='9'>
                <strong>Printer:</strong>&nbsp;
                <span className={this.state.printerAvailable ? 'text-success' : 'text-danger'}>
                    {this.state.usePrinter.name} {this.state.printerMessage !== '' ? `(${this.state.printerMessage})` : ''}
                  </span>
              </Col>
              <Col md='3' className='float-right text-right'>
                <a href='#' onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  event.target.blur();
                  this.onShowPrinterSettings();
                }}>
                  <FaPrint style={{marginRight: '10px'}}/>
                  Printer Settings
                </a>
              </Col>
            </Row>
            <Row>
              <Col md='12'>
                <strong>{I18n.t('labels.labelType')}:</strong> {I18n.t(`labels.${this.state.labelType}`)}&nbsp;
                {this.state.labelType === 'zpl' ? <a className='float-right' onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  e.target.blur();
                  this.onToggleLabelTypes();
                }}>{this.state.showLabelTypes ? I18n.t('labels.hideLabelTypes') : I18n.t('labels.showLabelTypes')}</a> : null}
              </Col>
            </Row>

            {!this.isAutoPrintEnabled() || this.isAutoPrintAvailable()
              ? null
              : (<Row>
                  <Col md='12' style={{fontWeight: 'bold'}} className='text-danger'>
                    Auto Printing Not Available: {this.isAutoPrintAvailable(true)}
                  </Col>
                </Row>)
            }

            <Row>
              <Col md='12' className={this.state.showLabelTypes ? null : 'hide'} style={{marginTop: '12px'}}>
                <span style={{fontSize: 'smaller', padding: '0'}}>
                  Click a box below to select that type of label Preview and Printing.<br/>
                  <strong><span className='text-danger'>Zpl labels will be removed on or shortly after June 1st, 2018.</span></strong>
                </span><br/>
                {
                  ['zpl', 'image', 'both_image'].map((type, index) => {
                    return <LabelTypeBox type={type} key={index} onSelectLabelType={this.onSelectLabelType} currentLabelType={this.state.labelType} />;
                  })
                }
              </Col>
            </Row>

            <Row>
              <Col md='12'>
                <div className={this.state.moreInfo === '' ? 'hide' : ''} style={{border: '1px solid #ccc', padding: '8px', marginTop: '8px', fontSize: '14px'}}>
                  <FaExclamationTriangle style={{fontSize: '18px'}} /> {this.state.moreInfo}
                </div>
              </Col>
            </Row>

            {/* Image Loader, Error Message, and Image */}
            <Row>
              <Col md='12'>
              {this.state.loadingSample ? <LoadingBar type='warning' message='common.loadingSampleImage'/> : null}
              {
                !this.state.loadingError
                  ? null
                  : (
                  <div className='text-danger'>
                    <strong>
                      <FaExclamationTriangle style={{fontSize: '18px'}} />  {I18n.t('common.loadingSampleImageError')}
                    </strong>
                  </div>
                )
              }
              </Col>
            </Row>

            <Row>
              <Col md='12'>
                <SampleImages
                  forceLabelBlocks={this.state.forceLabelBlocks}
                  loadQ={this.state.loadQ}
                  printQ={this.state.printQ}
                  printSingleInBlock={this.printSingleInBlock}
                  printFromInBlock={this.printFromInBlock}
                  zplQ={this.state.zplQ}
                  namesQ={this.state.namesQ}
                  printBlock={this.printBlock}
                  currentTab={this.state.currentTab}
                  currentPrinter={this.state.currentPrinter}
                  setViewPrintQIndex={this.setViewPrintQIndex}
                  viewPrintQIndex={this.state.viewPrintQIndex}
                  onLoadBlock={this.onLoadBlock}
                  onChangeTab={this.onChangeTab}
                  label={this.props.label}
                  isMultiple={this.state.isMultiple}
                  setTagInState={this.setTagInState}
                  selectedTag={this.state.selectedTag}
                  defaultLabelType={this.props.defaultLabelType}
                  showLabelDetails={this.state.showLabelDetails}
                  onToggleShowLabelDetails={this.onToggleShowLabelDetails}
                  selectedLabel={this.state.selectedLabel}
                />
              </Col>
            </Row>

            {/* Printing Loaders */}
            <Row>
              <Col md='12' style={{margin: '12px 0px'}}>
                {this.state.connecting ? <LoadingBar type='warning' message='common.connectingToQzTray'/> : null}
                {this.state.printing ? <LoadingBar type='success' message='common.printing'/> : null}
              </Col>
            </Row>

          </Modal.Body>

          <Modal.Footer>
            {(this.state.currentPrinter)
                ? null
                : (<Row>
                    <Col md='12' className='float-right'>
                      <div className='text-danger' style={{margin: '8px 0'}}>{I18n.t('common.selectAPrinterToEnablePrinting')}</div>
                    </Col>
                  </Row>)
            }

            <Button
              style={{float: 'left'}}
              onClick={this.onHide}
              className='save-button'
              variant='warning'>
              {I18n.t('common.actions.close')}
            </Button>

            {
              !this.props.label || this.props.label.is_multiple === undefined || this.props.label && this.props.label.is_multiple || !this.state.qzTrayTestingEnabled || !facilityCanTestQzTray
                ? null
                : <div style={{float:'left', marginLeft: '8px'}}>
                <Button
                  style={{float: 'left'}}
                  onClick={this.testPrintZpl}
                  className='save-button'
                  variant='info'>
                  Test Zpl (No QZT)
                </Button>
                <Button
                  style={{float: 'left'}}
                  onClick={this.testPrintImage}
                  className='save-button'
                  variant='info'>
                  Test Html/Image (No QZT)
                </Button>
              </div>
            }

            {/*Actions*/}
            <div style={{float: 'right'}}>
              {
                (this.props.label.labels === undefined)
                  ? null
                  : (
                  <Button
                    onClick={this.getSelectedLabel}
                    disabled={!this.state.selectedTag}
                    className='save-button'
                    variant='primary'>
                    Load Selected Label For Printing
                  </Button>
                )
              }

              {
                (Object.keys(this.props.label).length > 0 && this.props.label.count > 1)
                  ? this.state.loadQ.length < 2 && !this.state.forceLabelBlocks
                    ? (
                        <Button
                          onClick={this.printTestLabel}
                          className='save-button'
                          disabled={Object.keys(this.props.label).length === 0 || !this.state.currentPrinter || this.state.loadQ.length > 1}
                          variant='primary'>
                          Print Test Label
                        </Button>
                      )
                    : null
                  : null
              }
              {
                (Object.keys(this.props.label).length > 0)
                  ? this.state.loadQ.length < 2  && !this.state.forceLabelBlocks
                    ? (
                      <Button
                        onClick={this.printLabel}
                        className='save-button'
                        disabled={!this.state.currentPrinter || this.props.label.labels !== undefined}
                        variant='primary'>
                        Print {this.props.label.count > 1 ? 'All ' + this.props.label.count + ' Labels' : ' Label'}
                      </Button>
                    )
                    : null
                  : null
              }
              {
                (Object.keys(this.props.label).length > 0)
                  ? (this.props.label.count === 1
                    ? (
                    <span style={{paddingLeft: '8px'}}>
                      <input type='number' style={{width: '100px'}} onChange={this.setPrintXTimes} value={this.state.printXTimes} /> Time(s)
                    </span>
                    )
                    : null)
                  : null
              }
            </div>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

PrinterModal.propTypes = {
  params: PropTypes.object,
  forceLabelBlocks: PropTypes.bool,
  qzTray: PropTypes.object.isRequired,
  hidePrinter: PropTypes.func.isRequired,
  showPrinter: PropTypes.bool,
  labelTag   : PropTypes.string,
  labelIds   : PropTypes.array,
  label      : PropTypes.object,
  labelUrl: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  payload    : PropTypes.object,
  goToAfter  : PropTypes.string,
  actions    : PropTypes.object.isRequired,
  httpAction : PropTypes.string,
  facility: PropTypes.object,
};


function mapStateToProps(state, ownProps) {

  const {label} = state;

  return {
    facility: state.facility,
    goToAfter: ownProps.goToAfter,
    qzTray: state.qzTray,
    label,
    defaultLabelType: getDefaultLabelType(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, itemActions, {push}, {getQzTray, signQzRequest});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PrinterModal);
