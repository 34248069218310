import React from 'react';
import {connect} from 'react-redux';

import {getUseEntityLocksForItems} from '../../selectors/coreSettingsSelectors';
import IntegrationTrackingStatusInvestigateCell from '../common/integration/IntegrationTrackingStatusInvestigateCell';
import {isFeatureEnabled} from '../../selectors/featureToggles';


const ItemIntegrationTrackingStatus = (props) => {
  const {
    item: {
      state_integration_tracking_id,
      highest_ranked_entity_lock,
      integration_entity_locks,
      global_id
    },
    useEntityLocks,
    isLeafPaConfigPackEnabled
  } = props;

  const trackingId = isLeafPaConfigPackEnabled ? (state_integration_tracking_id ? state_integration_tracking_id : global_id) : state_integration_tracking_id ;

  if (!trackingId && highest_ranked_entity_lock) {
    return (
      <IntegrationTrackingStatusInvestigateCell
        trackingId={trackingId}
        entityLocks={integration_entity_locks}
        entityLockForDisplay={highest_ranked_entity_lock}
      />
    );
  }

  if (!useEntityLocks || !highest_ranked_entity_lock) {
    return <span>{trackingId}</span>;
  }

  return (
    <IntegrationTrackingStatusInvestigateCell
      trackingId={trackingId}
      entityLocks={integration_entity_locks}
      entityLockForDisplay={highest_ranked_entity_lock}
    />
  );
};


const mapStateToProps = (state) => ({
  useEntityLocks: getUseEntityLocksForItems(state),
  isLeafPaConfigPackEnabled: isFeatureEnabled(state)('feature_leaf_pa_configuration_pack')
});


export default connect(mapStateToProps)(ItemIntegrationTrackingStatus);
