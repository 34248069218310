import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {ButtonToolbar} from 'react-bootstrap';

const ConfirmUnshareLocation = (props) => {

  return (
    <div className='row' style={{marginTop: '12px'}}>
      <div className='col-md-12'>
        <ButtonToolbar className='float-right'>
          <button
            onClick={(event) => {
              props.onHide();
            }}
            className='btn btn-primary'
          >
            {I18n.t('locations.unshareLocation.keepShared')}
          </button>
          <button
            onClick={(event) => {
              props.confirm();
            }}
            className='btn btn-danger'
          >
            {I18n.t('locations.unshareLocation.stopSharing')}
          </button>
        </ButtonToolbar>
      </div>
    </div>
  );
};

ConfirmUnshareLocation.propTypes = {
  onHide: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
};

export default ConfirmUnshareLocation;
