import {change} from 'redux-form';
import {batchActions} from 'redux-batched-actions';
import {MANAGE_USER_ROLES} from '../../constants/forms';
import {isArrayPush, isFormChangeOnField} from './utils';
import {getManageUserRolesFormField, getPushedRolePayload} from '../../selectors/forms/manageUserRolesFormSelectors';

const manageUserRolesForm = store => next => action => {
  if (isArrayPush(action, [MANAGE_USER_ROLES], 'roles')) {
    action.payload = getPushedRolePayload(store.getState());
    store.dispatch(change(action.meta.form, 'role_name', ''));
  }

  const result = next(action);

  if (isFormChangeOnField(action, [MANAGE_USER_ROLES], 'all_permissions_selected', true)) {
    const path = action.meta.field.replace('.all_permissions_selected', '.permissions');
    const state = store.getState();
    const permissions = getManageUserRolesFormField(path)(state);
    const actions = permissions.reduce(
      (actions, permission, index) => {
        if (permission.enabled !== Boolean(action.payload)) {
          actions.push(change(action.meta.form, `${path}[${index}].enabled`, Boolean(action.payload)));
        }
        return actions;
      },
      []
    );
    if (actions.length > 0) {
      store.dispatch(batchActions(actions));
    }
  }

  return result;
};

export default manageUserRolesForm;
