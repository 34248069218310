import { createSelector } from 'reselect';
import get from 'lodash.get';
import orderBy from 'lodash.orderby';
import * as dataNames from '../constants/dataNames';
import * as itemNames from '../constants/itemNames';
import { getImageUrl } from '../util/images';
import { PERSON } from '../constants/imageUrls';
import { isPaLeafIntegrator } from './integration/leafSelectors';
import { getIntegrationState } from './integration/integrationSelectors';
import { getActiveFacilityId } from './facilitiesSelectors';
import {buildCompleteInternationalPhoneNumber}  from '../util/formatHelper';
import {getPhoneShouldIncludeCountryCode} from './InternationalOperationsSelectors';
import {isFeatureEnabled} from './featureToggles';

export const getDrivers = (state) => state[dataNames.drivers];
export const getUsers = (state) => state[dataNames.users];
export const getDriver = (state) => state[itemNames.driver];
const getVehicles = (state) => state[dataNames.vehicles];
const getSelectedDrivers = (state) => state.selectedDrivers;
const getLicenseNumber = (_, props) => props.licenseNumber;

export const getDriversForDropdown = createSelector(
  getDrivers,
  (drivers) =>
    drivers.map((driver) => ({
      ...driver,
      name: driver.is_licensed_transporter
        ? driver.name
        : `${driver.first_name} ${driver.middle_initial} ${driver.last_name}`
    }))
);

export const getSelectedDriversIds = createSelector(
  getSelectedDrivers,
  (selectedDrivers) => selectedDrivers.map((selectedDriver) => selectedDriver.id)
);

const getActiveUsers = createSelector(
  [getUsers],
  (users) => users.filter((user) => user.active)
);

export const usersFullNameFormation = createSelector(
  getActiveUsers,
  (users) =>
    users.map((user) => {
      return {
        ...user,
        full_name: `${user.first_name}` + ' ' + `${user.last_name}`
      };
    })
);

export const getIntegratedOrApprovedUsers = createSelector(
  [usersFullNameFormation, isPaLeafIntegrator, getActiveFacilityId, isFeatureEnabled],
  (users, isPaLeaf, activeFacilityId, isFeatureEnabled) => {
    return users.filter((user) => {
      const integrationIdStatusCheck = (user.integration_ids || []).some((id) => {
        return id.facility_id == activeFacilityId && id.active && id.access_level != 'disabled';
      });
      return !isPaLeaf || (user.integration_status && integrationIdStatusCheck) || (isFeatureEnabled('feature_regulation_console_user_approval') && user.approval_status === 'approved');
    });
  }
);


export const driversFullNameFormation = createSelector(
  getDrivers, getPhoneShouldIncludeCountryCode,
  (drivers, includeCountryCode) =>
    drivers.map((driver) => ({
      ...driver,
      src: getImageUrl(get(driver, 'image_file[0]', {}), '30x30', PERSON),
      phone_1: buildCompleteInternationalPhoneNumber(get(driver, 'driver_phones[0].number', '—'),
        get(driver, 'driver_phones[0].phone_country_code', null), includeCountryCode),
      full_name: driver.is_licensed_transporter ? driver.name : `${driver.first_name} ${driver.last_name}`
    }))
);

export const defaultVehicleFormation = createSelector(
  [getDriver, getVehicles],
  (driver, vehicles) => {
    const chosenVehicle = vehicles.find((vehicle) => {
      return vehicle.id === driver.default_vehicle_id;
    });
    return {
      ...driver,
      ...chosenVehicle,
      driver_phones: driver.driver_phones && driver.driver_phones[0] ? driver.driver_phones : [{}]
    };
  }
);

export const getDriverByLicenseNumber = createSelector(
  getDrivers,
  getLicenseNumber,
  (drivers, licenseNumber) => {
    const licenseNumbers = Array.isArray(licenseNumber) ? licenseNumber : [licenseNumber];
    return (
      (Array.isArray(drivers) &&
        drivers.find((driver) => licenseNumbers.indexOf(driver.drivers_license_number) !== -1)) ||
      undefined
    );
  }
);

export const getTransferDriverOptions = createSelector(
  [getDrivers, getIntegrationState, getPhoneShouldIncludeCountryCode],
  (drivers, { isBiotrack }, includeCountryCode) => {
    const driverOptions = drivers
      .filter((driver) => !(driver.is_licensed_transporter && isBiotrack))
      .map((driver) => {
        const full_name = driver.is_licensed_transporter
          ? driver.name
          : `${driver.first_name} ${driver.middle_initial} ${driver.last_name}`;
        //const phone_number = driver.driver_phones && driver.driver_phones.length ? driver.driver_phones[0].number : '';

        const phone_number = driver.driver_phones && driver.driver_phones.length ? buildCompleteInternationalPhoneNumber(driver.driver_phones[0].number, driver.driver_phones[0].phone_country_code, includeCountryCode) : '';
        if (phone_number != '') {
          driver.driver_phones[0].number = phone_number;
        }

        return { ...driver, full_name, phone_number };
      });
    return orderBy(driverOptions, 'full_name', 'asc');
  }
);
