/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack} from 'react-router-redux';
import omit from 'lodash.omit';
import get from 'lodash.get';

import {getUnpaginatedData, getItem, postItem, putItem} from '../../actions/apiActions';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import FormWrapper from '../common/form/FormWrapper';
import ReduxModifyRegisterForm from './ReduxModifyRegisterForm';
import {unsetItem} from '../../actions/itemActions';
import {addMessage} from '../../actions/systemActions';
import isRetailFacility from '../../selectors/facility/isRetailFacility';
import {setUserRetailRegister} from '../../actions/userActions';
import ModalWrapper from '../common/ModalWrapper';
import {doNothing} from '../../util/callbackHelpers';
import {getSelectableRegisterTransactionTypes} from '../../selectors/registersSelectors';
import {getAlleavesRegisterIds, isAlleavesIntegrator, isPosabitIntegrator} from '../../selectors/integration/thirdPartyIntegrationSelectors';
import {getCurrentUser} from '../../selectors/usersSelectors';
import {isFeatureEnabled} from '../../selectors/featureToggles';
import * as messageTypes from '../../constants/messageTypes';

export class ModifyRegisterPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.verifyTerminalId = this.verifyTerminalId.bind(this);
    this.setShowIsDefaultFlag = this.setShowIsDefaultFlag.bind(this);
    this.renderConfirmModal = this.renderConfirmModal.bind(this);
    this.showConfirmationPopup = this.showConfirmationPopup.bind(this);
    this.validateDefaultRegister = this.validateDefaultRegister.bind(this);
    this.handlePosabitClick = this.handlePosabitClick.bind(this);

    this.state = {
      show_is_default: false,
      showConfirmation: {}
    };
  }

  componentDidMount() {
    const {getItem, getUnpaginatedData} = this.props.actions;
    const {params} = this.props;

    getItem(`/api/registers/${params.id}`, itemNames.register, undefined, {purchase_from: 1}, (response) => this.setState({show_is_default: response.type === 'sales'}));
    getUnpaginatedData('/api/registers', dataNames.registers, {failed:'registers.get.failed'}, {detailed:1, active:1});
    getUnpaginatedData('/api/partners', dataNames.partners, undefined, {purchase_from: 1});
  }

  componentWillUnmount() {
    this.props.actions.unsetItem(itemNames.register);
  }

  handlePosabitClick() {
    const {user} = this.props;
    const payload = {
      type: 'posabit_referral',
      entity_type: 'user',
      action: 'User click POSaBIT referral link.',
      entity_id: user.id
    };

    this.props.actions.postItem('/api/audit_logs',
      payload).then(() => {
        window.open('https://www.posabit.com', '_blank');
      }
    ).catch(() => {});
  }

  onSubmit(formData, dispatch) {
    const {goBack, putItem, postItem} = this.props.actions;
    const register = Object.assign({},
      omit(formData, [
        'collects_from',
        'created_at',
        'current_level',
        'facility_id',
        'organization_id',
        'roles',
        'updated_at',
        'transaction'
      ]), {
      /*
       * Might be a bug in redux-form or Multiselect input: If a register has "collects from" registers and the form is
       * saved without modifying the "collects from" registers, the collects_from_ids array contains register IDs.
       * If a register has "collects from" registers and the form is saved after modifying the "collects from"
       * registers, the collects_from_ids array contains register objects instead of IDs.
       */
        collects_from_ids: formData.collects_from_ids.map(
          register => typeof register === 'object' ? register.id : register
        )
      }
    );

    const updateRegisterDetail = () => {
      const {register: initialState} = this.props;
      const messages = {success: 'registers.modify.success', failed: 'registers.modify.fail'};
      this.validateDefaultRegister(register)
        .then(() => {
          if (register.is_default_for_pos) {
            this.props.actions.setUserRetailRegister(register.id);
          } else if (!register.is_default_for_pos && initialState.is_default_for_pos) {
            this.props.actions.setUserRetailRegister(null);
          }
        })
        .then(async () => {
          try {
            if (register.terminal_id && register.terminal_id !== initialState.terminal_id) {
              await this.verifyTerminalId(register.terminal_id);
            }
            putItem(`/api/registers/${register.id}`, register, itemNames.register, messages)
              .then(goBack)
              .catch(doNothing);
          }
          catch (err) {
            doNothing();
          }
        });
    };

    if (formData.transaction) {
      const transaction = Object.assign({}, formData.transaction, {
        action_type: 'manual'
      });
      const messages = {success:'registers.createTransaction.success', failed:'registers.createTransaction.fail'};
      postItem(`/api/registers/${register.id}/transact`, transaction, itemNames.transaction, messages)
        .then(() => {
          updateRegisterDetail();
        });
    } else {
      updateRegisterDetail();
    }

  }

  verifyTerminalId(terminalId) {
    return new Promise((resolve, reject) => {
      this.props.actions.getItem('/api/posabit/terminal_status', null, {failed: 'registers.posabit_terminal_id_invalid'}, {terminal_id: terminalId})
        .then((response) => {
          if (get(response, 'status') === 'online' || get(response, 'status') === 'offline') {
            resolve();
          }
          else {
            this.props.actions.addMessage(messageTypes.error, 'registers.posabit_terminal_id_invalid');
            reject();
          }
        })
        .catch((err) => {
          reject();
        });
    });
  }

  setShowIsDefaultFlag(event) {
    this.setState({show_is_default: (event.target.value === 'sales')});
  }

  renderConfirmModal() {
    const { showConfirmation } = this.state;

    const okayButtonProps = {
      show: true,
      onClick: showConfirmation.onConfirm,
      text: I18n.t('general.yes')
    };

    const cancelButtonProps = {
      show: true,
      onClick: showConfirmation.onHide,
      text: I18n.t('general.no')
    };

    return (
      <ModalWrapper
        Component={false}
        title={I18n.t('registers.confirmationModal.switchRegisterTitle')}
        headerClass='bg-info-dark'
        onHide={showConfirmation.onHide}
        showModal={showConfirmation.show}
        okayButton={okayButtonProps}
        cancelButton={cancelButtonProps}
        dialogClassName='modal-sm'
        version={2}
      >
        <p>{I18n.t('registers.confirmationModal.switchRegisterBody')}</p>
      </ModalWrapper>
    );
  }

  showConfirmationPopup(resolve, reject) {
    const hidePopup = (cb) => () => {
      this.setState({
        showConfirmation: { show: false },
      });
      cb();
    };
    this.setState({
      showConfirmation: {
        show: true,
        onConfirm: hidePopup(resolve),
        onHide: hidePopup(reject),
      }
    });
  }

  validateDefaultRegister(payload) {
    const {register, currentRegister} = this.props;
    return new Promise((resolve, reject) => {
      const next = () => resolve();
      if (!payload.is_default_for_pos || !currentRegister || currentRegister === register.id) {
        return next();
      }
      return this.showConfirmationPopup(next, reject);
    });
  }

  render() {
    const {register, registers, partners, isRetailFacility, selectableRegisterTransactionTypes, isPosabitIntegrator, isPosabitIntegrationEnabled, isAlleavesIntegrator, isAlleavesIntegrationEnabled, alleavesRegisterIds} = this.props;
    const {show_is_default} = this.state;
    const {goBack} = this.props.actions;
    const statuses = [
      {text: I18n.t('partners.createPartner.Active'), value: 1},
      {text: I18n.t('partners.createPartner.Inactive'), value: 0}
    ];
    const registerTypes = [
      {value: 'ap_ar', text: I18n.t('registers.form.accountsPayableReceivable')},
      {value: 'vault', text: I18n.t('registers.form.vault')},
      ...(isRetailFacility ? [{value: 'sales', text: I18n.t('registers.form.salesTransactions')}] : []),
    ];
    const initialValues = Object.assign({}, register, {
      collects_from_ids: register.collects_from && register.collects_from.length
        ? register.collects_from.map(register => register.collects_from_register.id)
        : []
    });

    return (
      <div>
        <FormWrapper title={'registers.modify.title'} goBack={goBack}>
          <ReduxModifyRegisterForm
            registers={registers}
            statuses={statuses}
            registerTypes={registerTypes}
            partners={partners}
            reasons={selectableRegisterTransactionTypes}
            enableReinitialize={true}
            initialValues={initialValues}
            showIsDefault={show_is_default}
            setShowIsDefaultFlag={this.setShowIsDefaultFlag}
            handlePosabitClick={this.handlePosabitClick}
            onSubmit={this.onSubmit}
            isPosabitIntegrator={isPosabitIntegrator}
            isPosabitIntegrationEnabled={isPosabitIntegrationEnabled}
            isAlleavesIntegrator={isAlleavesIntegrator}
            isAlleavesIntegrationEnabled={isAlleavesIntegrationEnabled}
            alleavesRegisterIds={alleavesRegisterIds}
          />
        </FormWrapper>
        {this.renderConfirmModal()}
      </div>);
  }
}

ModifyRegisterPage.propTypes = {
  actions: PropTypes.object.isRequired,
  register: PropTypes.object.isRequired,
  registers: PropTypes.array.isRequired,
  partners: PropTypes.array.isRequired,
  params: PropTypes.object.isRequired,
  isRetailFacility: PropTypes.bool,
  currentRegister: PropTypes.number,
  isPosabitIntegrator: PropTypes.bool,
  isPosabitIntegrationEnabled: PropTypes.bool.isRequired,
  isAlleavesIntegrationEnabled: PropTypes.bool.isRequired,
  isAlleavesIntegrator: PropTypes.bool,
  alleavesRegisterIds: PropTypes.array,
};

function mapStateToProps(state, ownProps) {
  const {register, registers, partners, user: {currentRegister}} = state;
  const id = ownProps.params.id;
  return {
    register,
    partners: [
      {id: '', name: I18n.t('common.form.selectPlaceholder')},
      ...partners
    ],
    registers: registers.filter(register => register.id != id),
    isRetailFacility: isRetailFacility(state),
    currentRegister,
    user: getCurrentUser(state),
    selectableRegisterTransactionTypes: getSelectableRegisterTransactionTypes(state),
    isPosabitIntegrator: isPosabitIntegrator(state),
    isPosabitIntegrationEnabled: isFeatureEnabled(state)('feature_posabit_payment_integration'),
    isAlleavesIntegrator: isAlleavesIntegrator(state),
    isAlleavesIntegrationEnabled: isFeatureEnabled(state)('feature_alleaves_payment_integration'),
    alleavesRegisterIds: getAlleavesRegisterIds(state)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    goBack,
    getItem,
    putItem,
    getUnpaginatedData,
    postItem,
    unsetItem,
    setUserRetailRegister,
    addMessage
  };
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModifyRegisterPage);
