import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import isEmpty from 'lodash.isempty';
import get from 'lodash.get';
import {pick} from 'lodash';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {change, Field, formValueSelector} from 'redux-form';
import {Button, Col, Row} from 'react-bootstrap';

import ReactSelectInput from '../common/form/ReactSelectInput';
import ModalWrapper from '../common/ModalWrapper';
import {getCouponLines} from '../../selectors/ordersSelectors';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import {deleteItem, getUnpaginatedData, postItem} from '../../actions/apiActions';
import {setItem, unsetItem} from '../../actions/itemActions';
import {unsetData} from '../../actions/dataActions';
import {addMessage} from '../../actions/systemActions';
import * as messageTypes from '../../constants/messageTypes';
import {COUPON_LIMIT} from '../../constants/coupons';


export class CouponSelect extends React.PureComponent {
  constructor(props, context){
    super(props, context);
    this.state = {
      disableCouponApplyButton: true,
      couponUpdateing: false,
      availableCouponsLoaded: false,
      showLineItemCouponModal: false
    };
    this.handleCouponChange = this.handleCouponChange.bind(this);
    this.applyCoupon = this.applyCoupon.bind(this);
    this.getAvailableCouponsForOrder = this.getAvailableCouponsForOrder.bind(this);
  }

  componentWillUnmount() {
    this.props.actions.unsetData(dataNames.coupons);
    this.props.actions.unsetData(dataNames.rewards);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {lineItemCouponScanned} = this.props;

    if (!prevProps.lineItemCouponScanned && lineItemCouponScanned) {
      this.handleLineItemCouponScanned(lineItemCouponScanned.id);
    }
  }

  handleLineItemCouponScanned(couponId) {
    this.handleCouponChange(couponId);
    this.setState({
      showLineItemCouponModal: true
    });
  }

  getAvailableCouponsForOrder(){
    this.setState({couponUpdating: true, availableCouponsLoaded: true});
    const {order_id} = this.props;
    this.props.showLoader('fetchingCoupons');
    this.props.actions.getUnpaginatedData(`/api/orders/${order_id}/available_coupons`,
      dataNames.coupons, {failed: 'orders.coupons.failed'}, undefined,
      () => {
        this.setState({couponUpdating: false});
        this.props.couponsUpdated();
        this.props.hideLoader();
      }
    );
  }

  handleCouponChange(coupon){
    const {formName} = this.props;
    this.props.actions.change(formName, 'coupon', coupon);
    this.props.actions.change(formName, 'target_order_product_id', undefined);
    this.props.actions.change(formName, 'opi_id', undefined);
    this.setState({disableCouponApplyButton: !coupon});
  }

  applyCoupon() {
    const {coupon, target_order_product_id, order_id, opi_id} = this.props;
    const payload = {coupon_id: coupon};
    this.setState({disableCouponApplyButton: true});
    if (target_order_product_id){
      payload.target_order_product_id = target_order_product_id;
    }
    if (opi_id){
      payload.opi_id = opi_id;
    }
    const failedHandler = (response) => {
      const commonErrors = get(response, 'response.data.errors.VALIDATION.ORDER_ERROR', []);
      const validationErrors = Object.values(pick(
        get(response, 'response.data.errors.VALIDATION', {}),
        ['REDEMPTIONS_INVALID', 'ORDER_MINIMUM']
      ));
      const displayingErrors = isEmpty(validationErrors) ? commonErrors : validationErrors;

      displayingErrors.map((message) => this.props.actions.addMessage(messageTypes.error, message, true));
    };

    this.props.showLoader();
    this.props.actions
      .postItem(`/api/orders/${order_id}/coupons`, payload, itemNames.order, { failedHandler })
      .finally(this.props.hideLoader);
  }


  render(){
    const {
      coupons, availableLines, products, target_order_product_id, lineItemCouponScanned, completeLineItemCouponScan, order, coupon, opi_id
    } = this.props;
    const {disableCouponApplyButton, availableCouponsLoaded, showLineItemCouponModal} = this.state;

    const disableCouponApplyButtonForLineMode = !target_order_product_id && availableLines;


    const isPrepackAndDifferentSizes = products.flatMap(product => {
      if (product.id == target_order_product_id && product.product_type == 'prepack') {
        const prepackIds = new Set(product.items.map(item => item.prepack_weight_id));
        if (prepackIds.size > 1) {
          return product.items;
        }
      }
      return [];
    });

    const foundCoupon = coupons.find(coupn => coupn.id === coupon);

    const maxApplicationQty = foundCoupon ? foundCoupon.max_application_qty : null;

    const needOpiId = (isPrepackAndDifferentSizes.length > 0 && maxApplicationQty > 0 && !opi_id > 0) ? 1 : 0;

    return (
      <Row>
        <Col xs={12} sm={6} md={6} lg={6}>
          <Field name='coupon' component={ReactSelectInput} props={{
            label: I18n.t('cart.couponName'),
            options: availableCouponsLoaded ? coupons : [],
            textKey: 'name',
            valueKey: 'id',
            disabled: !availableCouponsLoaded || (coupons.length < 1 || products.length < 1),
            onChange: (value) => this.handleCouponChange(value)
          }}/>
        </Col>
        {availableLines ? <Col xs={12} sm={6} md={6} lg={6}>
          <Field name='target_order_product_id' component={ReactSelectInput} props={{
            label: I18n.t('cart.lineItem'),
            options: availableLines,
            textKey: 'name',
            valueKey: 'id'
          }}/>
        </Col> : null}
        {isPrepackAndDifferentSizes.length > 0 && maxApplicationQty > 0 ? <Col xs={12} sm={6} md={6} lg={6}>
          <Field name='opi_id' component={ReactSelectInput} props={{
            label: I18n.t('cart.coupons.applyToSpecificPacakge'),
            options: isPrepackAndDifferentSizes,
            textKey: 'package_code',
            valueKey: 'id'
          }}/>
        </Col> : null}
        <Col xs={12} sm={12} md={12} lg={12} >
            {availableCouponsLoaded && coupons.length > 0 && products.length > 0 &&
              <Button
                className='float-right'
                variant='primary'
                disabled={disableCouponApplyButton || disableCouponApplyButtonForLineMode || get(order, 'coupons', []).length >= COUPON_LIMIT || needOpiId}
                onClick={this.applyCoupon}>
                  {I18n.t('cart.items.applyCoupon')}
              </Button>
            }
            {!availableCouponsLoaded && <Button className='float-right' onClick={this.getAvailableCouponsForOrder} disabled={this.state.couponUpdating || !products.length}>{I18n.t('cart.items.fetchCoupons')}</Button>}
        </Col>

        {/*
            This modal is an alternative way of selecting and applying line item coupons via coupon scanning
            using the same underlying form as the input/select elements above.
        */}
        <ModalWrapper
          dialogClassName='modal-sm'
          title={showLineItemCouponModal && I18n.t('cart.coupons.lineItemModalTitle', {name: lineItemCouponScanned.name})}
          showModal={showLineItemCouponModal}
          component={false}
          version={2}
          okayButton={{
            show: true,
            variant: 'primary',
            text: I18n.t('cart.items.applyCoupon'),
            onClick: () => {
              this.applyCoupon();
              this.setState({showLineItemCouponModal: false});
              completeLineItemCouponScan();
            }
          }}
          onHide={() => {
            this.setState({showLineItemCouponModal: false});
            completeLineItemCouponScan();
          }}
        >
          <Field name='target_order_product_id' component={ReactSelectInput} props={{
            label: I18n.t('cart.lineItem'),
            options: availableLines,
            textKey: 'name',
            valueKey: 'id'
          }}/>
        </ModalWrapper>
      </Row>
    );
  }
}

CouponSelect.propTypes = {
  actions: PropTypes.shape({
    getUnpaginatedData: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    deleteItem: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    setItem: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired,
    unsetData: PropTypes.func
  }),
  showLoader: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  coupon: PropTypes.number,
  coupons: PropTypes.array.isRequired,
  availableLines: PropTypes.array,
  couponUpdateRequired: PropTypes.bool.isRequired,
  target_order_product_id: PropTypes.number,
  order_id: PropTypes.number,
  couponsUpdated: PropTypes.func.isRequired,
  products: PropTypes.array,
  order: PropTypes.object,
  opi_id: PropTypes.number
};



function mapStateToProps(state, ownProps) {
  const selector = formValueSelector(ownProps.formName);
  const {
    coupons
  } = state;

  const {coupon, target_order_product_id, opi_id} = selector(state, 'coupon', 'target_order_product_id', 'opi_id');
  const availableLines = getCouponLines(state, {id: coupon});
  const {
    couponUpdateRequired,
    order_id,
    formName,
    couponsUpdated,
    showLoader,
    hideLoader,
    products,
  } = ownProps;

  return {
    coupon,
    coupons,
    availableLines,
    couponUpdateRequired,
    target_order_product_id,
    order_id,
    formName,
    couponsUpdated,
    showLoader,
    hideLoader,
    products,
    opi_id
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({},
    {getUnpaginatedData, postItem, deleteItem, addMessage,
      change, setItem, unsetItem, unsetData});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CouponSelect);
