import {I18n} from 'react-redux-i18n';
import {requiredFieldValidation} from '../../common/form/redux-form/validations';
import {convertToBase} from '../../../util/uomHelpers';

const validate = (values, props) => {
  const errors = {quantities: {}};
  const {isBiotrack, isWaLeaf} = props.integrationState;
  if (!values.storage_location_id) {
    errors.storage_location_id = I18n.t('packaging.form.selectStorageLocation');
  }
  if (!values.item_master_parent_id) {
    errors.item_master_parent_id = I18n.t('packaging.form.selectProducts');
  }
  if (!values.completed_at) {
    errors.completed_at = I18n.t('packaging.form.enterPackagingTime');
  }
  const currentWeightBase = values.quantities.reduce((acc, item, index) => {
    acc += item.weightBase * (item.qty || 0);
    if ((isBiotrack || isWaLeaf) && item.qty && !item.integration_type) {
      errors.quantities[index] = {integration_type: requiredFieldValidation(item.integration_type, 'packages.form.inventoryType')};
    }
    return acc;
  }, 0);

  if (!props.isAllowNegativeInventory && props.inputUom) {
    const availableWeightBase = convertToBase(values.weight, props.inputUom);
    const wasteBase = convertToBase(values.wasteReportedInput, props.inputUom);
    const packagingLossBase = convertToBase(values.packagingLossInput, props.inputUom);

    if (wasteBase > availableWeightBase) {
      errors.wasteReportedOutput = I18n.t('packaging.form.wasteWeightError');
    }

    if (packagingLossBase > availableWeightBase) {
      errors.packagingLossOutput = I18n.t('packaging.form.packagingLossError');
    }

    if (currentWeightBase > availableWeightBase) {
      errors.totalPackedOutput = I18n.t('packaging.form.enteredPackagingWeight');
    }

    if (values.unpackedRemainderOutput < 0) {
      errors.quantities._error = I18n.t('packaging.form.unpackedRemainderError');
    }
  }

  if (props.didRequestTag) {
    errors.package_expires_at = requiredFieldValidation(values.package_expires_at);
  }

  return errors;
};

export default validate;
