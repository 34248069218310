//import {I18n} from 'react-redux-i18n';

export const validate = (values) => {
  const errors = {};

  const fields = ['plants_on_hand', 'plants_in_flower', 'plants_in_veg'];

  fields.forEach((field) => {
    if (!values[field] && values[field] !== 0) {
      errors[field] = 'Must Be Numeric';
    }
  });

  if (Object.keys(errors).length === 0) {
    if (parseFloat(values.plants_on_hand) < parseFloat(values.plants_in_flower) + parseFloat(values.plants_in_veg)) {
      fields.forEach((field) => {
        errors[field] = 'On Hand should be greater than or equal to the sum of Immature + Mature values.';
      });
    }
  }

  return errors;
};

export default validate;
