import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import toArray from 'lodash.toarray';
import {Col, Row, Table, Card} from 'react-bootstrap';

const HarvestBatchPackagedDetails = ({model}) => {
  return (
    <Card>
      <Row>
        <Col xs={12} sm={10} md={8}>
          <h3>{I18n.t('batchHistory.model.createdPackages')}</h3>
          <Table striped className='item-details'>
            <thead>
            <tr>
              <th>{I18n.t('batchHistory.model.packageId')}</th>
              <th>{I18n.t('batchHistory.model.location')}</th>
              <th>{I18n.t('batchHistory.model.product')}</th>
              <th>{I18n.t('batchHistory.model.quantity')}</th>
            </tr>
            </thead>
            <tbody>
            {(toArray(model.data && model.data.harvest_packages) || []).map((pkg, index) => {
              const {item} = pkg;
              if (!item) {
                return null;
              }
              return (
                <tr key={index}>
                  <td>{item.package_code}</td>
                  <td>{item.location_name}</td>
                  <td>{item.item_name}</td>
                  <td>{item.qty} {item.uom}</td>
                </tr>
              );
            })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Card>
  );
};

HarvestBatchPackagedDetails.propTypes = {
  model: PropTypes.object.isRequired,
};

export default HarvestBatchPackagedDetails;
