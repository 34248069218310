import {formValueSelector} from 'redux-form';
import {createSelector} from 'reselect';
import get from 'lodash.get';
import {WASTE_COMPLIANCE_FORM} from '../../constants/forms';
import {getComplianceSettings} from '../complianceSettingsSelectors';


const selector = new formValueSelector(WASTE_COMPLIANCE_FORM);

export const getTrackWastePackages = state => Boolean(Number(selector(state, 'track_waste_packages')));

export const getTransferringWaste = createSelector(
  [getComplianceSettings],
  (settings) => {
    return get(settings, 'inv_require_waste_transfer.value', false);
  });

export const getWasteCompliancePayload = (formValues, facilityId) => {

  const transferring_waste =  Boolean(Number(formValues.transferring_waste || 0));
  const cult_waste_destruction_compliance_settings = {
    ...formValues,
    transferring_waste: Boolean(Number(formValues.transferring_waste || 0)),
    trackable_waste_stages: {
      ...formValues.trackable_waste_stages,
      values: get(formValues, 'trackable_waste_stages.values', []).map(v => v.value || v),
    },
    transferring_waste_days: {
      ...formValues.transferring_waste_days,
      values: get(formValues, 'transferring_waste_days.values', []).map(v => v.value || v),
    },
  };

  const inv_waste_packaging = {
    track_waste_packages: Boolean(Number(formValues.track_waste_packages || 0)),
    waste_package_location_id: formValues.waste_package_location_id || null,
  };

  //The setting is being split into 2 but leaving the combined until after it cuts over.  It can then be removed.
  return {
    cult_waste_destruction_compliance_settings: {
      settings: [
        {
          setting_key: 'cult_waste_destruction_compliance_settings',
          scope: 'facility',
          scope_id: facilityId,
          value: cult_waste_destruction_compliance_settings,
        },
      ],
    },
    inv_waste_packaging: {
      settings: [
        {
          setting_key: 'inv_require_waste_transfer',
          scope: 'facility',
          scope_id: facilityId,
          value: transferring_waste
        },
        {
          setting_key: 'inv_track_waste_packages',
          scope: 'facility',
          scope_id: facilityId,
          value: inv_waste_packaging.track_waste_packages,
        },
        {
          setting_key: 'inv_track_waste_location_id',
          scope: 'facility',
          scope_id: facilityId,
          value: inv_waste_packaging.waste_package_location_id,
        },

        //inv_waste_packaging Can be removed post 2.8.0.0
        {
          setting_key: 'inv_waste_packaging',
          scope: 'facility',
          scope_id: facilityId,
          value: inv_waste_packaging,
        }
      ]
    }
  };
};
