import {batchActions} from 'redux-batched-actions';

import * as types from '../constants/actionTypes';


export const handleApiSuccess = store => next => action => {
  if(action.type === types.GET_DATA_SUCCESS || action.type === types.GET_ITEM_SUCCESS ||
    action.type === types.GET_DATA_SUCCESS || action.type === types.GET_DATA_BATCH_SUCCESS ||
    action.type === types.ADD_DATA_SUCCESS || action.type === types.EDIT_DATA_SUCCESS ||
    action.type === types.GET_ITEM_SUCCESS || action.type === types.ADD_ITEM_SUCCESS ||
    action.type === types.EDIT_ITEM_SUCCESS
  ) {
    const {payload, name} = action;
    const meta = store.getState().meta[name];
    const data = store.getState()[name];
    const etag = payload.headers && payload.headers.etag;
    const actions = [];
    if(meta){
      if(!meta.startTime || !meta.stopTime || meta.startTime <= payload.stopTime){
        if (meta.etag !== etag || !etag) {
          actions.push({type: types.SET_REQUEST_HASH, payload: payload.hash, name});
          actions.push({type: types.SET_ETAG, payload: etag, name});
          actions.push({type: types.SET_REQUEST_STOP, payload: payload.stopTime, name});
          if(payload.pagination){
            actions.push({type: types.SET_PAGINATION, payload: payload.pagination, name});
          }
          if(payload.facets){
            actions.push({type: types.SET_FACETS, payload: payload.facets, name});
          }
          store.dispatch(batchActions(actions));
        }else{
          action.payload.data = data;
        }
      }else{
        action.payload.data = data;
      }
    }else{
      const m = {
        hash: payload.hash,
        etag,
        startTime:payload.startTime,
        stopTime: payload.stopTime,
      };
      if(payload.pagination){
        m.pagination = payload.pagination;
      }
      if(payload.facets){
        m.facets = payload.facets;
      }
      store.dispatch({type: types.SET_META, payload: m, name});
    }
  }
  return next(action);
};

export default handleApiSuccess;
