import get from 'lodash.get';
import {createSelector} from 'reselect';
import * as dataNames from '../constants/dataNames';

export const getOhMetrcCategories = state => state[dataNames.ohMetrcCategories];

export const getMinimumDaysSupply = createSelector(
  [getOhMetrcCategories],
  (ohMetrcCategories) => {
    const minimumDaysSupply = {
      non_terminal: {},
      terminal: {},
    };

    ohMetrcCategories.forEach(category => {
      minimumDaysSupply.terminal[category.code] = get(category, 'terminal_minimum_unit', 0);
      minimumDaysSupply.non_terminal[category.code] = get(category, 'non_terminal_minimum_unit', 0);
    });

    return minimumDaysSupply;
  }
);
