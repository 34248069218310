import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Button} from 'react-bootstrap';
import {I18n}from 'react-redux-i18n';

import InternationalQuantityByUomInput from '../common/form/InternationalQuantityByUomInput';

class IngredientAdjustForm extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {disabled: true};
    this.onAdjust = this.onAdjust.bind(this);
  }

  onAdjust(event, status) {
    event.preventDefault();
    this.setState({disabled: status});
  }

  render() {
    const {handleSubmit, reset, item} = this.props;
    const {disabled} = this.state;
    return (
      <form onClick={(e) => e.stopPropagation()}>
        <Row>
          <Col xs={4}>
            <InternationalQuantityByUomInput
              name='qty'
              disabled={disabled}
              allowNegativeNumber={true}
              hideUom={true}
              uom={item.default_uom}
            />
          </Col>
          {disabled ?
            <Col xs={5}>
              <Button variant='primary' onClick={(env) => this.onAdjust(env, false)}>
                {I18n.t('ingredients.table.adjust')}
              </Button>
            </Col> :
            <Col xs={8}>
              <Button variant='primary' onClick={(env) => {
                handleSubmit();
                this.onAdjust(env, true);
              }}>
                {I18n.t('ingredients.table.save')}
              </Button>
              <Button type='reset' onClick={(env) => {
                reset();
                this.onAdjust(env, true);
              }}>
                {I18n.t('ingredients.table.cancel')}
              </Button>
            </Col>}
        </Row>
      </form>
    );
  }
}

IngredientAdjustForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default IngredientAdjustForm;
