
import omit from 'lodash.omit';
import decode from 'jwt-decode';
import {LOAD_CSRF_TOKEN, LOGIN_SUCCESS, SET_TOKEN, LOGIN_FAILED, LOGOUT_SUCCESS} from '../constants/actionTypes';
import initialState from '../constants/initialState';

export default function apiHeaderReducer (state = initialState.headers, action){
  const {token, payload} = action;
  switch (action.type) {
  case LOAD_CSRF_TOKEN:
    return Object.assign({}, state, {'x-csrf-token': token});
  case LOGIN_SUCCESS:
    return Object.assign({}, state, {Authorization:token});
  case SET_TOKEN:
    //adding a ttl check to prevent replacing tokens out of order
    return state.Authorization && decode(state.Authorization).exp <= decode(payload).exp ? Object.assign({}, state, {Authorization:payload}) : state;
  case LOGIN_FAILED:
  case LOGOUT_SUCCESS:
    return omit(state, ['Authorization']);
  default:
    return state;
  }
}
