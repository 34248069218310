/* eslint-disable react/no-multi-comp */
/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import omit from 'lodash.omit';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push, replace } from 'react-router-redux';
import { I18n } from 'react-redux-i18n';

import { convertFormInputDateToDbDate, getToday } from '../../util/dateHelpers';
import {
  canPlantsBeActivated,
  getArchiveInactiveAfter,
  getSelectedPlantIds,
  getDecoratedPlantsSelector, 
  getSolrCoreName,
  getPlantSolrReworkQueryAndFilter
} from '../../selectors/plantsSelectors';
import { getIntegrationState } from '../../selectors/integration/integrationSelectors';

import { validateHarvestPlants } from '../../actions/harvestActions';
import { validatePackagePlants } from '../../actions/packageActions';
import { validateDestroyPlants } from '../../actions/destroyActions';
import * as p from '../../constants/permissions';
import * as apiActions from '../../actions/apiActions';
import * as selectedDataActions from '../../actions/selectedDataActions';
import { unsetData } from '../../actions/dataActions';
import { addMessage } from '../../actions/systemActions';
import TablePageWrapper from '../common/grid/TablePageWrapper';
import PageTitle from '../common/PageTitle';
import * as dataNames from '../../constants/dataNames';
import getColumns from './common/columnDefinitions';
import * as itemNames from '../../constants/itemNames';
import { handleComplexSelectRow } from '../../actions/helpers/selectedDataHelper';
import PrinterModal from '../printer/PrinterModal';
import PermissionButton from '../common/PermissionButton';
import { validatePatientCompliancePlants, validatePlants } from '../../actions/complianceSettingsActions';
import { getTotalResults } from '../../selectors/paginationSelectors';
import { plantStages } from '../../constants/plants';
import { hasPlantsTags } from '../../selectors/integrationSelectors';
import { getFacilityStrains } from '../../selectors/facilityStrainsSelectors';
import { clearCupoNumbers, fetchCupos } from '../../actions/integrations/colombia';
import { getCupoNumbers } from '../../selectors/integration/colombiaSelectors';
import { userHasPermission } from '../../selectors/usersSelectors';
import { isFeatureEnabled } from '../../selectors/featureToggles';
import TestResults from '../../components/finished-products/TestResults';
import { setSolrErrorMessage } from '../../actions/solrActions';
import { getUseEntityLocksForPlants } from '../../selectors/coreSettingsSelectors';
import IntegrationTrackingStatusInvestigateCell from '../common/integration/IntegrationTrackingStatusInvestigateCell';
import { ConnectedIntegrationTrackingStatusModal } from '../common/integration/IntegrationTrackingStatusModal';
import { getEntityLockRemediesSelector } from '../../selectors/entityLockSelectors';
import { getUrlParam } from '../../util/routeHelper';

export class PlantPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    const plantFilters = [];
    const columns = [];
    this.state = { columns, plantFilters, labelIds: [], reindexing: false, mounted: false };
    this.activatePlants = this.activatePlants.bind(this);
    this.packagePlants = this.packagePlants.bind(this);
    this.destroyPlants = this.destroyPlants.bind(this);
    this.collectWaste = this.collectWaste.bind(this);
    this.filterPhases = this.filterPhases.bind(this);
    this.getFilter = this.getFilter.bind(this);
    this.harvestPlants = this.harvestPlants.bind(this);
    this.setTab = this.setTab.bind(this);
    this.getTabs = this.getTabs.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.printLabel = this.printLabel.bind(this);
    this.hidePrinter = this.hidePrinter.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
    this.stageColumns = this.stageColumns.bind(this);
    this.createPlant = this.createPlant.bind(this);
    this.splitAvailable = this.splitAvailable.bind(this);
    this.packageMotherPlantAvailable = this.packageMotherPlantAvailable.bind(this);
    this.getStrains = this.getStrains.bind(this);
    this.ref = React.createRef();
  }

  componentWillMount() {
    this.props.actions.getItem('/api/compliance_settings', itemNames.invComplianceSettings);
    this.props.actions.getUnpaginatedData('/api/stages', dataNames.stages);
    //this was added during the initial scaffolding of entity locks, but this call fires on page load for everyone
    //even if they have opted out of entity locks. There is a second, filtered, call that happens after page load
    //that returns relevant data. Commenting this out for now, but we may want to remove entirely in the future.
    //this.props.actions.getData('/api/cultivation/entity_locks', null);
  }

  componentDidMount() {
    this.props.actions.initComponent();
    this.filterPhases(this.props.params.stage);
    this.getStrains();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ mounted: true }); // Fixes lag when plants is populated with a thousand or more before page loads
    if (nextProps.params.stage !== this.props.params.stage) {
      this.props.actions.unsetData(dataNames.plants);
      this.setState({ columns: this.stageColumns(nextProps.params.stage, nextProps.timezone) });
      this.props.actions.handleSelect('clear');
      this.filterPhases(nextProps.params.stage);
    }
  }

  componentDidUpdate(prevProps) {
    // Reload plants if we get compliance settings with different archiveInactiveAfter value
    // (only if we are on inactive tab)
    if (prevProps.archiveInactiveAfter !== this.props.archiveInactiveAfter) {
      if (this.props.params.stage === 'inactive') {
        this.setState({ plantFilters: [] }, () => { //eslint-disable-line
          this.filterPhases(this.props.params.stage);
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.actions.clearCupoNumbers();
  }

  getStrains() {
    if (this.props.integrationState.isColombia) {
      this.props.actions.getUnpaginatedData('/api/strains/by_facility', dataNames.facilityStrains, undefined, {
        include_cultivation: 1
      });
    }
  }

  createPlant() {
    this.props.actions.validatePatientCompliancePlants('create') && //not sure this  actually does anything
      this.props.actions.validatePlants('on hand') &&
      this.props.actions.validatePlants('immature') &&
      this.props.actions.push('/plants/create');
  }

  activatePlants() {
    const { selectedPlantIds } = this.props;
    const payload = {
      ids: selectedPlantIds,
      modification_date: convertFormInputDateToDbDate(getToday(), this.props.timezone),
      is_destroyed: 0,
      is_harvested: 0,
      is_packaged: 0,
      is_activate: 1
    };

    if (this.props.actions.validatePatientCompliancePlants('create') && this.props.actions.validatePlants('create')) {
      this.props.actions.putData(
        '/api/plants/update',
        payload,
        dataNames.plants,
        { failed: 'plants.activate.failed', success: 'plants.activate.success' },
        null,
        () => {
          this.props.actions.handleSelect('clear');
          this.props.actions.updatePlantStats();
          this.props.actions.complianceValidatePlants();
        }
      );
    }
  }


  stageColumns(stage, timezone, trackPlantsAsGroup) {

    function getColsForStage(stage, columns) {
      if (stage === 'harvest') {
        return columns.harvestBatchColumns;
      } else {
        return columns.propagationColumns.filter((o) => {
          return typeof o.removeForStage !== 'undefined' ? !o.removeForStage.includes(stage) : o;
        });
      }
    }
    const { hasPlantsTags, strains, cupoNumbers, hasAddTestResultsSetting, feature_plant_batch_testing, useEntityLocks } = this.props;

    const columns = getColumns(timezone, trackPlantsAsGroup, this.props.integrationState, strains, cupoNumbers, stage, this.props.plantsServiceFirstIsEnabled);
    const newCol = getColsForStage(stage, columns);

    const { isLeaf, isMetrc, isBiotrack, isCaMetrc, isGlobalIdEnabled } = this.props.integrationState;
    if (isLeaf || isMetrc || isBiotrack) {
      const propertyName =
        isLeaf || isBiotrack
          ? 'external_identifier'
          : isCaMetrc && ['propagation', 'vegetation'].indexOf(this.props.params.stage) !== -1
          ? 'batch_integration_tracking_id'
          : 'state_integration_tracking_id';

      const trackingIdCellFormatter = (cell, row) => {
        const trackingId = get(row, 'state_integration_tracking_id', '');
        return get(row, 'is_locked') && get(row, 'highest_ranked_entity_lock')
          ? (<IntegrationTrackingStatusInvestigateCell
            trackingId={trackingId}
            entityLocks={get(row, 'entity_locks', [])}
            entityLockForDisplay={get(row, 'highest_ranked_entity_lock')}
          />)
          : <span>{trackingId}</span>;
      };

      const trackingIdCellFormatterGlobalId = (cell, row) => {
        const externalId = get(row, propertyName, '');
        const globalId = get(row, 'global_id', '');
        return <span>{externalId ? externalId : (isGlobalIdEnabled ? globalId : undefined)}</span>;
      };

      newCol.push({
        name: I18n.t('cultivation.finishedProduct.form.trackingID'),
        dataId: propertyName,
        hidden: false,
        dataSort: true,
        scanSearchColumn: true,
        formatter: useEntityLocks ? trackingIdCellFormatter : trackingIdCellFormatterGlobalId,
      });
    }

    if (feature_plant_batch_testing && hasAddTestResultsSetting) {
      newCol.push({
        name: 'testResults',
        removeForStage: ['inactive'],
        dataId: 'latestTestResultStatus',
        hidden: false,
        dataSort: false,
        permissions: [p.print_labels],
        csvFormatter: (cell) => {
          return I18n.t('plants.table.Label');
        },
        formatter: (cell, row) => {
          return cell === 0 ? (
            ''
          ) : (
            <TestResults
              item={{reference_id: row.id, ...row}}
              fetchChildrenAndLabResults={function() {}}
              isPlant={true}
            />
          );
        } // eslint-disable-line react/display-name
      });
    }

    if (hasPlantsTags && (this.props.params.stage === 'flowering' || this.props.params.stage === 'vegetation')) {
      newCol.push({
        name: I18n.t('plants.soloTag'),
        dataId: 'state_integration_tracking_id',
        hidden: false,
        dataSort: true,
        scanSearchColumn: true,
        formatter: (cell, row) => {
          return (
            (row.state_integration_tracking_id ? `${row.state_integration_tracking_id} ` : '') +
            (row.tag_requested && row.state_integration_tracking_id ? '<br>' : '') +
            // '<em style="color:lightgrey">' +
            (row.tag_requested ? I18n.t('plants.table.tagNeedsActivation') : '') // +
            // '</em>'
          );
        }
      });
    }

    if (isBiotrack && stage === 'inactive') {
      const formatter = (cell, row) => {
        if (row.is_packaged) {
          return I18n.t('plants.table.packaged');
        }
        if (row.is_harvested) {
          return I18n.t('plants.table.harvested');
        }
        if (row.bt_destroy_scheduled_at && !row.bt_destroyed_at) {
          return I18n.t('plants.table.scheduledForDestroy');
        }
        return I18n.t('plants.table.destroyed');
      };
      newCol.push({
        name: I18n.t('plants.table.inactivationReason'),
        dataId: 'is_destroyed',
        hidden: false,
        dataSort: false,
        scanSearchColumn: false,
        formatter,
        csvFormatter: formatter
      });
    }

    newCol.push({
      name: 'common.print',
      removeForStage: ['inactive'],
      dataId: 'print',
      hidden: false,
      dataSort: false,
      permissions: [p.print_labels],
      csvFormatter: (cell) => {
        return I18n.t('plants.table.Label');
      },
      formatter: (cell, row) => {
        return cell === 0 ? (
          ''
        ) : (
          <PermissionButton
            permissions={[p.print_labels]}
            props={{
              variant: 'info',
              value: row.id,
              onClick: (event) => {
                event.stopPropagation();
                event.preventDefault();
                this.printLabel(event);
              }
            }}
          >
            {I18n.t('plants.table.Label')}
          </PermissionButton>
        );
      } // eslint-disable-line react/display-name
    });
    return newCol;
  }

  packagePlants() {
    (!this.props.selectedPlantIds.length || this.props.actions.validatePackagePlants()) &&
      this.props.actions.push('/plants/package');
  }

  destroyPlants() {
    (!this.props.selectedPlantIds.length || this.props.actions.validateDestroyPlants()) &&
      this.props.actions.push('/plants/destroy');
  }

  collectWaste() {
    this.props.actions.push('/plants/collect-waste');
  }

  harvestPlants() {
    (!this.props.selectedPlantIds.length || this.props.actions.validateHarvestPlants()) &&
      this.props.actions.push('/plants/harvest');
  }

  updateSearch() {
    this.props.actions.postItem('/api/search/update', {}, null, {
      success: 'plants.actions.updateSearchSuccess',
      fail: 'plants.actions.updateSearchFail'
    });
    this.setState({ reindexing: true });
  }

  getFilter(phase) {
    const { plantFilters } = this.state;
    if (plantFilters.find((filter) => filter === phase) !== undefined) {
      return '((is_destroyed:0 OR is_harvested:0 OR is_packaged:0) AND qty:[1 TO *])';
    } else if (phase === 'inactive') {
      const inactiveClause = 'qty:[1 TO *] AND (is_destroyed:1 OR is_harvested:1 OR is_packaged:1)';
      const archiveAfter = this.props.archiveInactiveAfter;
      return archiveAfter > 0 ? `(${inactiveClause} AND updated_at: [NOW-${archiveAfter}DAY TO NOW])` : inactiveClause;
    } else {
      return `(stage_name:${phase} AND is_destroyed:0 AND is_harvested:0 AND is_packaged:0)`;
    }
  }

  filterPhases(phase) {
    const { plantFilters } = this.state;
    if (plantFilters.find((filter) => filter === phase) !== undefined) {
      this.setState({ plantFilters: [] });
    } else {
      this.setState({ plantFilters: [phase] });
    }
    if (this.ref.current) {
      this.ref.current.wrappedInstance.filter(this.getFilter(phase));
    }
  }

  setTab(activeTab) {
    this.props.actions.push(`/plants/${activeTab}`);
  }

  handleSearch(sort, query = 'matchall', size, start, filter = this.getFilter(this.props.params.stage), isScanSearch) {
    const serviceFirstEnabledFromUrl = getUrlParam('feature_service_first_plants_listing');
    if ((this.props.plantsServiceFirstIsEnabled ||  serviceFirstEnabledFromUrl) && !query) {
      return this.getPlantsFromService(sort, query, size, start, filter);
    }

    const {solrCoreName} = this.props;    
    const {updatedQueryString, updatedFilter} = getPlantSolrReworkQueryAndFilter(query, filter, solrCoreName === 'new_plants');

    // Use Solr
    this.props.actions.handleSearch({ sort, query: updatedQueryString, size, start, filter: updatedFilter, isScanSearch, solrCoreName });
  }

  getPlantsFromService(sort, query, size, start, filter) {
    const currentStage = this.props.params.stage; // TAB selected
    const archiveAfter = this.props.archiveInactiveAfter // filters inactive plants after x number of days
    const params = {
      select_columns: [
        'id',
        'plant_id',
        'last_stage_move_date',
        'type',
        'qty',
        'planting_date',
        'cupo_id',
        'modality',
        'batch_name',
        'batch_integration_tracking_id',
        'global_id',
        'phenotype_id',
        'facility_id',
        'state_integration_tracking_id',
        'tag_requested',
        'mother_plant_id',
        'is_mother',
        'is_packaged',
        'is_harvested',
        'is_destroyed',
        'location_name',
        'schedule_name',
        'stage_name',
        'strain_name',
        'phenotype_name',
        'waste_reported',
        'external_identifier'
      ],

      queries: ['motherPlant', 'wasteReported'],

      is_active: currentStage === 'inactive' ? 0 : 1,

      // Order By
      sort: sort.split(',').map(sort => sort.trim().replace(/\s/, ':')),

      // Pagination
      page: (start ? start / size : 0) + 1,
      per_page: size,
    };

    if (currentStage !== 'inactive') {
      params.is_in_stage = currentStage
    } else if (archiveAfter > 0) {
      params.archive_after = archiveAfter
    }

    this.props.actions.getPaginatedData('/api/plants/search', dataNames.plants, {failed: 'plants.get.failed'}, params);
  }

  printLabel(event) {
    const ids =
      this.props.selectedPlants.length > 0 ? this.props.selectedPlants.map((plant) => plant.id) : [event.target.value];
    const id = event.target.value;
    if (ids.length > 1) {
      const index = ids.indexOf(parseInt(id));
      if (index !== -1) ids.splice(index, 1);
      ids.unshift(id);
    }
    this.setState({ showPrinter: true, labelTag: 'cult_plant_tag', labelIds: ids });
  }

  hidePrinter() {
    this.setState({ showPrinter: false });
  }

  splitAvailable() {
    const selectedPlant = this.props.selectedPlants[0] || { qty: 0 };
    const trackPlantsAsGroup = this.props.trackPlantsAsGroup;
    return trackPlantsAsGroup && selectedPlant.qty > 1;
  }

  packageMotherPlantAvailable() {
    const {selectedPlants, integrationState} = this.props;

    if (integrationState.isCaMetrc) {
      return selectedPlants.every(selectedPlant => !!selectedPlant.mother_plant_id);
    }

    const selectedPlant = this.props.selectedPlants[0] || { is_mother: 0 };

    return selectedPlant.is_mother;
  }

  getTabs() {
    const { reindexing } = this.state;
    const {
      selectedPlantIds,
      trackPlantsAsGroup,
      integrationState,
      archiveInactiveAfter,
      canAddTestResults,
      hasAddTestResultsSetting,
      feature_plant_batch_testing,
      plantsServiceFirstIsEnabled,
    } = this.props;
    const firstSelectedPlant = selectedPlantIds[0];
    const modifyPath = '/plants/modify';

    const testResultsButton = feature_plant_batch_testing ? {
      id: 'addTestResults',
      path: '/plants/test-results/add',
      text: 'plants.actions.addTestResults',
      hide: !integrationState.isHemp || !hasAddTestResultsSetting,
      disabled: !canAddTestResults,
    } : undefined;

    const updateSearchButton = !plantsServiceFirstIsEnabled ? {
      id: 'updateSearch',
      func: this.updateSearch,
      text: 'plants.actions.updateSearch',
      glyph: 'arrow-up',
      variant: 'warning',
      requireSelect: false,
      disabled: reindexing,
    } : undefined;

    const tabs = [
      {
        id: 'propagationTab',
        eventKey: 'propagation',
        title: 'nav.Propagation',
        actions: [
          {
            id: 'createPlant',
            path: '/plants/create',
            func: this.createPlant,
            text: 'plants.actions.createPlants',
            glyph: 'plus-sign',
            requireSelect: false
          },
          {
            id: 'modifyPlant',
            path: `${modifyPath}?stage=${plantStages.propagation.string}`,
            text: 'plants.actions.plantModification',
            glyph: 'th-list',
            requireSelect: false
          },
          {
            id: 'packagePlants',
            path: '/plants/package',
            func: this.packagePlants,
            text: 'plants.actions.packagePlants',
            glyph: 'gift',
            requireSelect: true,
            hide: integrationState.isPrBiotrack
          },
          {
            id: 'packageMotherPlants',
            path: '/plants/package-mother-plants',
            text: 'plants.actions.packageMotherPlants',
            glyph: 'grain',
            requireSelect: true,
            hide: !integrationState.isBiotrack && !integrationState.isCaMetrc,
            disabled: !this.packageMotherPlantAvailable()
          },
          {
            id: 'splitPlant',
            path: `/plants/${firstSelectedPlant}/split`,
            text: 'plants.actions.splitGroup',
            glyph: 'duplicate',
            requireSelect: true,
            hide: !trackPlantsAsGroup,
            disabled: !this.splitAvailable()
          },
          {
            id: 'destroyPlants',
            path: '/plants/destroy',
            func: this.destroyPlants,
            text: 'plants.actions.destroyPlants',
            glyph: 'trash',
            requireSelect: true
          },
          testResultsButton,
          updateSearchButton
        ]
      },
      {
        id: 'vegetationTab',
        eventKey: 'vegetation',
        title: 'nav.Vegetation',
        actions: [
          {
            id: 'createPlant',
            path: '/plants/create',
            func: this.createPlant,
            text: 'plants.actions.createPlants',
            glyph: 'plus-sign',
            requireSelect: false
          },
          {
            id: 'modifyPlant',
            path: `${modifyPath}?stage=${plantStages.vegetation.string}`,
            text: 'plants.actions.plantModification',
            glyph: 'th-list',
            requireSelect: false
          },
          {
            id: 'packagePlants',
            path: '/plants/package',
            func: this.packagePlants,
            text: 'plants.actions.packagePlants',
            glyph: 'gift',
            requireSelect: true,
            hide: integrationState.isPrBiotrack
          },
          {
            id: 'packageMotherPlants',
            path: '/plants/package-mother-plants',
            text: 'plants.actions.packageMotherPlants',
            glyph: 'grain',
            requireSelect: true,
            hide: !integrationState.isBiotrack && !integrationState.isCaMetrc,
            disabled: !this.packageMotherPlantAvailable()
          },
          {
            id: 'splitPlant',
            path: `/plants/${firstSelectedPlant}/split`,
            text: 'plants.actions.splitGroup',
            glyph: 'duplicate',
            requireSelect: true,
            hide: !trackPlantsAsGroup,
            disabled: !this.splitAvailable()
          },
          {
            id: 'destroyPlants',
            path: '/plants/destroy',
            func: this.destroyPlants,
            text: 'plants.actions.destroyPlants',
            glyph: 'trash',
            requireSelect: true
          },
          testResultsButton,
          updateSearchButton
        ]
      },
      {
        id: 'floweringTab',
        eventKey: 'flowering',
        title: 'nav.Flowering',
        actions: [
          {
            id: 'harvestPlants',
            path: '/plants/harvest',
            func: this.harvestPlants,
            text: 'plants.actions.harvestPlants',
            glyph: 'plus-sign',
            requireSelect: false
          },
          {
            id: 'modifyPlant',
            path: `${modifyPath}?stage=${plantStages.flowering.string}`,
            text: 'plants.actions.plantModification',
            glyph: 'th-list',
            requireSelect: false
          },
          {
            id: 'packagePlants',
            path: '/plants/package',
            func: this.packagePlants,
            text: 'plants.actions.packagePlants',
            glyph: 'gift',
            requireSelect: true,
            hide: integrationState.isPrBiotrack
          },
          {
            id: 'packageMotherPlants',
            path: '/plants/package-mother-plants',
            text: 'plants.actions.packageMotherPlants',
            glyph: 'grain',
            requireSelect: true,
            hide: !integrationState.isBiotrack,
            disabled: !this.packageMotherPlantAvailable()
          },
          {
            id: 'splitPlant',
            path: `/plants/${firstSelectedPlant}/split`,
            text: 'plants.actions.splitGroup',
            glyph: 'duplicate',
            requireSelect: true,
            hide: !trackPlantsAsGroup,
            disabled: !this.splitAvailable()
          },
          {
            id: 'destroyPlants',
            path: '/plants/destroy',
            func: this.destroyPlants,
            text: 'plants.actions.destroyPlants',
            glyph: 'trash',
            requireSelect: true
          },
          testResultsButton,
          updateSearchButton
        ]
      }
    ];

    if (!this.props.integrationState.isWaLeaf) {
      tabs.push({
        id: 'inactiveTab',
        eventKey: 'inactive',
        title: 'nav.Inactive',
        actions: [
          {
            id: 'activate',
            func: this.activatePlants,
            text: 'plants.actions.activate',
            glyph: 'th-list',
            variant: 'primary',
            requireSelect: true,
            disabled: () => !this.props.canActivate
          },
          testResultsButton,
          updateSearchButton
        ],
        description:
          archiveInactiveAfter > 0
            ? I18n.t('cultivation.finishedProduct.archivedInventoryDescription', { days: archiveInactiveAfter })
            : undefined
      });
    } else {
      //remove propagation tab for WA
      tabs.shift();
      tabs.push({
        id: 'inactiveTab',
        eventKey: 'inactive',
        title: 'nav.Inactive',
        actions: [updateSearchButton]
      });
    }

    /**
     * If metrc, only allow plants in propagation to be packaged
     * EXCEPT metrc CA, which can package plants in propagation, vegetation and flowering
     */
    if (integrationState.isMetrc && !integrationState.isCaMetrc) {
      tabs[1].actions = tabs[1].actions.filter((action) => action && action.id !== 'packagePlants');
      tabs[2].actions = tabs[2].actions.filter((action) => action && action.id !== 'packagePlants');
    }

    // Get rid of actions that are "switched" off
    const filteredTabs = tabs.map((tab) => {
      tab.actions = tab.actions.filter((action) => action);
      return tab;
    });

    return filteredTabs;
  }

  render() {
    const { showPrinter, labelTag } = this.state;
    const {
      plants,
      params,
      searchQuery,
      selectedPlantIds,
      dataTotalSize,
      timezone,
      trackPlantsAsGroup,
      entityLockRemedies
    } = this.props;
    const columns = this.stageColumns(params.stage, timezone, trackPlantsAsGroup);

    return (
      <div>
        <PageTitle primaryText={I18n.t('plants.title')} />
        <TablePageWrapper
          // dateUpdated is used when service first is NOT enabled via "Update Search" button
          dataUpdated={() => this.setState({ reindexing: false })}
          ref={this.ref}
          settingKey='plants'
          columns={columns}
          searchString={searchQuery}
          data={this.state.mounted ? plants : []}
          activeTab={params.stage}
          tabs={this.getTabs()}
          switchTab={this.setTab}
          selectedRows={selectedPlantIds}
          handleFilter={this.handleFilter}
          handleSelect={this.props.actions.handleSelect}
          dataTotalSize={dataTotalSize}
          externalSearch={this.handleSearch}
          sort='planting_date desc,plant_id asc'
          scanSearchCol='plant_id'
          external={true}
          hideScanSearch={false}
          showHistory={false}
          className='plant-page'
          hideExport={true}
          showSelectedCount={true}
          isSolrListing={true}
          useAutoSuggest={this.props.useAutoSuggest}
          autoSuggestPlaceholder='plants.table.suggestPlaceholder'
        />
        <PrinterModal
          ref='printerModal'
          showPrinter={showPrinter}
          forceLabelBlocks={this.state.labelIds.length > 1}
          hidePrinter={this.hidePrinter}
          labelTag={labelTag}
          labelIds={this.state.labelIds}
        />
        <ConnectedIntegrationTrackingStatusModal
          entityLockRemedies={entityLockRemedies}
        />
      </div>
    );
  }
}

PlantPage.propTypes = {
  plants: PropTypes.array.isRequired,
  selectedPlants: PropTypes.array.isRequired,
  selectedPlantIds: PropTypes.array.isRequired,
  dataTotalSize: PropTypes.number,
  timezone: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  integrationState: PropTypes.object.isRequired,
  trackPlantsAsGroup: PropTypes.bool,
  hasPlantsTags: PropTypes.bool,
  archiveInactiveAfter: PropTypes.number.isRequired,
  useAutoSuggest: PropTypes.bool,
  entityLockRemedies: PropTypes.array,
  plantsServiceFirstIsEnabled: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {
  const { selectedPlants, timezone, complianceSettings } = state;
  const selectedPlantIds = getSelectedPlantIds(state);
  const integrationState = getIntegrationState(state);
  const { cult_track_plants_as_groups } = complianceSettings;
  const canAddTestResults = userHasPermission(state, {permissions: [p.manage_plant_testing]});
  const hasAddTestResultsSetting = get(state[itemNames.cultivationSettings], 'cult_add_test_results_to_plant_groups.value', false);
  const feature_plant_batch_testing = isFeatureEnabled(state)('feature_plant_batch_testing');

  return {
    dataTotalSize: getTotalResults(state, { name: dataNames.plants }),
    selectedPlantIds,
    selectedPlants,
    plants: getDecoratedPlantsSelector(state),
    timezone,
    integrationState,
    hasAddTestResultsSetting,
    canAddTestResults,
    feature_plant_batch_testing,
    searchQuery: get(ownProps.router.getCurrentLocation(), 'query.q', ''),
    cupoNumbers: getCupoNumbers(state),
    trackPlantsAsGroup: cult_track_plants_as_groups && cult_track_plants_as_groups.value,
    canActivate: canPlantsBeActivated(state),
    hasPlantsTags: hasPlantsTags(state),
    archiveInactiveAfter: getArchiveInactiveAfter(state),
    strains: getFacilityStrains(state),
    useAutoSuggest: isFeatureEnabled(state)('feature_solr_inventory_suggest'),
    useEntityLocks: getUseEntityLocksForPlants(state),
    entityLockRemedies: getEntityLockRemediesSelector(state),
    plantsServiceFirstIsEnabled: isFeatureEnabled(state)('feature_service_first_plants_listing'),
    solrCoreName: getSolrCoreName(state),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const actions = bindActionCreators(
    {
      ...apiActions,
      ...selectedDataActions,
      validateHarvestPlants,
      validatePackagePlants,
      validateDestroyPlants,
      handleComplexSelectRow,
      addMessage,
      fetchCupos,
      clearCupoNumbers,
      push,
      replace,
      unsetData,
      validatePatientCompliancePlants,
      validatePlants,
      setSolrErrorMessage,
    },
    dispatch
  );

  return {
    actions: {
      ...actions,

      handleSelect(action, rows) {
        actions.handleComplexSelectRow(rows, dataNames.plants, action);
      },

      updatePlantStats() {
        actions.getData('/api/search/plants', dataNames.plantStats, undefined, {
          query: 'matchall',
          filter: 'is_harvested: 0 AND is_packaged:0 AND is_destroyed:0',
          size: 0,
          facet: {
            'facet': 'true', //eslint-disable-line
            'facet.field': 'stage_name' //eslint-disable-line
          }
        });
      },

      complianceValidatePlants() {
        actions.getItem('/api/customers/compliance_settings/validate_plants', itemNames.patientComplianceSettings, {
          failed: 'retail.patientSettings.loadSettingsForPlants'
        });
      },

      /**
       * Initialization method of component
       */
      initComponent() {
        this.handleSelect('clear');
        // Integration settings are downloaded to see if Metrc intergation settings exist. If the Metrc settings exist,
        // then the Metrc category inputs are displayed for each sub-category.
        actions.getItem('/api/integration-settings', itemNames.integrationSettings, {
          failed: 'stateIntegratorSettings.get.failed'
        });

        this.complianceValidatePlants();

        actions.fetchCupos();
        actions.getItem(
          '/api/settings/plants',
          itemNames.complianceSettings,
          { failed: 'cultivation.complianceSettings.failed' },
          {
            ids: [
              'cult_plants_on_hand',
              'cult_plants_in_phases',
              'cult_distribute_harvest_weights',
              'cult_track_plants_as_groups',
              'cult_mature_plants_require_tracking_id'
            ]
          }
        );
        this.updatePlantStats();
      },

      /**
       * Method which is handling search behaviour
       * @param params
       */
      handleSearch(params) {
        const router = ownProps.router.getCurrentLocation();
        const query = params.query && !params.isScanSearch ? `?q=${escape(params.query)}` : '';
        const config = {
          errorHandler: {
            message: 'cultivation.finishedProduct.table.solrError',
            action: actions.setSolrErrorMessage,
            clearOnSuccess: true,
          }
        };

        actions.replace({ pathname: router.pathname, search: query });
        actions.getSearchData(`/api/search/${params.solrCoreName}`, dataNames.plants, null, omit(params, 'isScanSearch'), (plants) => {
          const plantIds = plants.map((plant) => plant.id);
          const reference_ids = plantIds;
          if (reference_ids.length) {
            actions.getDataByPost('/api/lab_results_references/by_reference_ids', {reference_ids, reference_type: 'plant_group'}, dataNames.testResults);
            // Backfill entity locks data (in inventory we get from solr but as part of service first initiative not much point in doing it that way).
            const params = {
              in_entity_ids: plantIds,
              active: 1,
              entity_type: 'plant',
            };
            actions.getData('/api/cultivation/entity_locks', dataNames.entityLocks, null, params);
          }
        }, null, config);
      }
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlantPage);
