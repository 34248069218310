import React from 'react';
import PropTypes from 'prop-types';

import FileButton from './FileButton';
import ReduxBootstrapInput from './ReduxBootstrapInput';

const FileInput = ({ label, size, input, meta, children, isRequired, groupClassName, ...props }) => {
  return (
    <ReduxBootstrapInput Control={FileButton}
                         label={label} size={size}
                         input={input} meta={meta}
                         name={input.name} isRequired={isRequired}
                         groupClassName={groupClassName ? groupClassName : 'float-right'}
                         {...props} >
      {children}
    </ReduxBootstrapInput>
  );
};

FileInput.propTypes = {
  label: PropTypes.string,
  size: PropTypes.oneOf(['lg', 'sm', 'large', 'small']),
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func
  }).isRequired,
  meta: PropTypes.object.isRequired,
  children: PropTypes.node,
  handleRef: PropTypes.func,
  isRequired: PropTypes.bool,
  fileType: PropTypes.string,
  type: PropTypes.string,
  errors: PropTypes.object,
  groupClassName: PropTypes.string
};

export default FileInput;
