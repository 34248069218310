import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import {pick} from 'lodash';
import {Card} from 'react-bootstrap';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import TextInput from '../../common/form/TextInput';

const SettingsPanel = (props) => {

  const titles = {
    header: '',
    patient: '',
    med_info: '',
    z_extras: ''
  };

  for(const title in titles){
    titles[title] = I18n.t(`retail.receiptSettings.panels.${title}`);
  }

  const options = {
    yesNo: [
      {text: 'cultivation.complianceSettings.yes', value: 1},
      {text: 'cultivation.complianceSettings.no', value: 0}
    ].map(yesNo => {
      yesNo.text = I18n.t(yesNo.text);
      return yesNo;
    }),
    fontSize: [
      {text: 'cultivation.complianceSettings.fontSizeUnit', value: 8},
      {text: 'cultivation.complianceSettings.fontSizeUnit', value: 9},
      {text: 'cultivation.complianceSettings.fontSizeUnit', value: 10},
      {text: 'cultivation.complianceSettings.fontSizeUnit', value: 11},
      {text: 'cultivation.complianceSettings.fontSizeUnit', value: 12}
    ].map(fontSize => {
      fontSize.text = I18n.t(fontSize.text, {size: fontSize.value});
      return fontSize;
    })
  };

  const properCase = (string) => {
    return string.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const form = pick(props, 'handleSubmit', 'change', 'valid', 'pristine', 'submitting');

  return (
    <Card>
      <Card.Header>
        {titles[props.title]}
        <button
          disabled={props.submitting || props.pristine || !props.valid}
          onMouseUp={(event) => event.target.blur()}
          onMouseDown={(event) => props.change('saveAndStay', true)}
          onClick={(event) => {
            form.change('afterSubmit', 'stay');
          }}
          type='submit'
          className='btn btn-primary btn-sm float-right'
          style={{position:'relative',top:'-5px'}}>
          Save
        </button>
      </Card.Header>
      <Card.Body>
        <table className='table table-bordered table-striped'>
          <thead>
          <tr>
            <th>Setting</th>
            <th style={{width:'25%'}}>Required</th>
          </tr>
          </thead>
          <tbody>
          {
            props.settings.map((setting, index) => {
              if(setting.optionsString === 'string'){
                return (<tr key={index}>
                  <td style={{verticalAlign: 'middle'}} colSpan={2}>
                    <label style={{width: '100%', fontWeight: 'normal'}}>{properCase(I18n.t(setting.text))}</label>
                    <Field
                      name={setting.name}
                      component={TextInput}
                      props={{
                        placeholder: (setting.hint_text !== undefined) ? setting.hint_text : ''
                      }}
                    />
                  </td>
                </tr>);
              }
              return (
                <tr key={index}>
                  <td style={{verticalAlign: 'middle'}}>
                    {properCase(I18n.t(setting.text))}
                    <div className='text-muted' style={{fontSize:'smaller'}}>{setting.hint_text}</div>
                    </td>
                  <td>
                    <Field
                      key={index}
                      style={{width: '100%'}}
                      name={setting.name}
                      clearable={false}
                      component={ReactSelectInput}
                      props={{
                        options: options[setting.optionsString]
                      }}
                    />
                  </td>
                </tr>
              );
            })
          }
          </tbody>
        </table>
      </Card.Body>
    </Card>
  );

};

SettingsPanel.propTypes = {
  title: PropTypes.string,
  settings: PropTypes.array,
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  valid: PropTypes.bool,
};

export default SettingsPanel;
