import {createSelector} from 'reselect';
import sortBy from 'lodash.sortby';
import * as dataNames from '../constants/dataNames';
import {facilityTypeLabels} from '../constants/facilityTypes';

export const getIngredientCategories = state => state[dataNames.ingredientCategories];

export const getSortedIngredientsCategoriesWithTypes = createSelector([getIngredientCategories], categories => {
  return sortBy(categories.map((c) => {
    return {
      ...c,
      name: c.name + ' (' + facilityTypeLabels[c.facility_type] + ')',
    };
  }), ['facility_type', 'name']);
});