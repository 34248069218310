/* eslint-disable import/prefer-default-export*/
import * as actionTypes from '../constants/actionTypes';
import * as itemNames from '../constants/itemNames';
import {addMessage} from '../actions/systemActions';
import {hasAllProductCounts, hasEqualProductCounts} from '../selectors/supplyChainMappingSelectors';

export const validateEqualProductCounts = store => next => action => {
  const result = next(action);
  // When an integrated transfer is being mapped to an internal order, make sure the integrated transfer and internal
  // order have the same number of products.
  if(action.type === actionTypes.GET_ITEM_SUCCESS
    && (action.name === itemNames.internalOrder || action.name === itemNames.integratedTransfer)) {
    const state = store.getState();
    if (hasAllProductCounts(state) && !hasEqualProductCounts(state)) {
      store.dispatch(addMessage('error', ['supplyChainMapping.form.productCountError']));
    }
  }
  return result;
};
