import React from 'react';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {FaDollarSign} from 'react-icons/fa';
import NumberInput from '../../common/form/NumericInput';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import DatePickerInput from '../../common/form/DatePickerInput';


const VerificationWithoutEmployeeCountFields = ({dropRegisters, resetAndDrop, onChangeResetBalanceTo}) => {
  return (
    <React.Fragment>
      <Field
        name='balance_before_closing'
        component={NumberInput}
        props={{
          label: I18n.t('retail.registerReconciliation.summary.balance_before_closing'),
          leftAddon: (<FaDollarSign/>),
          readOnly: true,
        }}
      />
      <hr />
      <Field
        name='verified_total'
        component={NumberInput}
        props={{
          label: I18n.t('retail.registerReconciliation.summary.verified_total'),
          leftAddon: (<FaDollarSign/>),
          readOnly: true,
        }}
      />
      <Field
        name='reset_balance_to'
        component={NumberInput}
        fractionPartSize={2}
        min={0}
        props={{
          label: I18n.t('retail.registerReconciliation.summary.reset_balance_to'),
          leftAddon: (<FaDollarSign/>),
          disabled: resetAndDrop.disabled,
        }}
        onChange={(event, value) => onChangeResetBalanceTo(value)}
      />
      <Field
        name='verified_drop'
        component={NumberInput}
        props={{
          label: I18n.t('retail.registerReconciliation.summary.verified_drop'),
          leftAddon: (<FaDollarSign/>),
          readOnly: true,
        }}
      />
      <Field
        name='verified_over_short'
        component={NumberInput}
        props={{
          label: I18n.t('retail.registerReconciliation.summary.verified_over_short'),
          leftAddon: (<FaDollarSign/>),
          className: 'discrepancy',
          disabled: true,
        }}
      />
      <hr />
      <Field
        name='drop_register_id'
        component={ReactSelectInput}
        props={{
          label: I18n.t('retail.registerReconciliation.summary.dropRegister'),
          options: dropRegisters,
          disabled: resetAndDrop.disabled
        }}
      />
      <Field
        name='verified_at'
        component={DatePickerInput}
        props={{
          label: I18n.t('retail.registerReconciliation.summary.verified_at'),
          inputProps: {
            disabled: true
          },
          enableTodayButton: false
        }}
      />
    </React.Fragment>
  );
};


export default VerificationWithoutEmployeeCountFields;
