/* eslint-disable indent */
 import React from 'react';
import PropTypes from 'prop-types';
import {reduxForm, FieldArray, Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Row, Col, Button} from 'react-bootstrap';
import SubmitSection from '../../../../common/form/SubmitSection';
import {validate} from './validate';
import TextInput from '../../../../common/form/TextInput';
import ReactSelectInput from '../../../../common/form/ReactSelectInput';
import {PRINT_SERVER_FORM} from '../../../../../constants/forms';
import PrinterForm from './PrinterForm';

export const Form = (props) => {

  const {submitting, pristine, handleSubmit, serverId, localPrintServer, selectedPrinter, serverActive} = props;

  const isLocalPrintServer = () => {
    if(!serverId) return false;
    return Boolean(localPrintServer && localPrintServer.id && localPrintServer.id === serverId);
  };

  const hasLocalPrintServer = () => {
    return Boolean(localPrintServer && Object.keys(localPrintServer).length > 0);
  };

  const isAllowingOverwriting = () => { //eslint-disable-line
    return false;
  };

  const canAddServer = () => {
    if(!props.selectedPrinter.qzConnected) return false;
    return Boolean(!serverId && !hasLocalPrintServer());
  };

  const canEditServer = () => {
    if(!props.selectedPrinter.qzConnected) return false;
    return Boolean(serverId && isLocalPrintServer() || (serverId && !hasLocalPrintServer() && !serverActive));
  };

  const settings = {
    actionSettings: {
      submit: {
        disabled: submitting || pristine || (!canAddServer() &&  !canEditServer()),
        submitting,
        text: I18n.t('common.form.save')
      }
    }
  };

  const onAction = (e, actionType) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.blur();
    props.onAction(actionType);
  };

  const getConflictReason = () => {
    let reason = 'unknown';
    switch(true){
      case (!selectedPrinter.qzConnected):
        reason = 'noQzTray';
        break;
      case (!serverId && !canAddServer() && hasLocalPrintServer()):
      case (serverId && !canEditServer() && hasLocalPrintServer()):
        reason = 'haveLocalPrintServer';
        break;
      case (serverId && !canEditServer()):
        reason = 'selectedPrintServerActive';
        break;
    }
    return `printServers.errors.${reason}`;
  };

  return (
    <form className='tax-form' onSubmit={handleSubmit}>
      {
        canAddServer() || canEditServer()
          ? null
          : (<div className='alert alert-info'>
          <p>
            {I18n.t(getConflictReason())}
          </p>
          <div style={{margin: '12px 0'}}>
            {
              localPrintServer && localPrintServer.name
                ? (<div>
                <strong>Conflict Details:</strong><br />
                Machine is set up as: {localPrintServer.name}.<br />
                Because of this, you cannot add or edit other machines from this machine.
                Remember that Print Server management must be done on the machine where the printers are connected.
                To resolve this, if it is an error, you can:
                <div style={{margin: '12px 0'}}>
                  <ul>
                    <li><a href onClick={(e) => onAction(e, 'release')}>Release This Machine</a> - This will disconnect the relationship between this machine and the Print Server named above.</li>
                    <li><a href onClick={(e) => onAction(e, 'del')}>Delete The Print Server</a> - This will delete the Print Server named above and release this machine.</li>
                    <div style={{margin: '8px'}}>
                      ** For either of the options above, you can then add this machine as a new Print Server or link this machine to an existing but not active Print Server.
                    </div>
                    <li><a href onClick={(e) => onAction(e, 'delAll')}>Delete All Print Servers</a> - This will delete every print server you have registered allowing you to reset your network from scratch.</li>
                  </ul>
                </div>
              </div>)
                : null
            }
            {
              serverId && !hasLocalPrintServer() && serverActive
                ? (<div>
                <div>This Print Server is active and is not currently controlled by this machine.  Clicking the link will force this machine into the role of owner.</div>
                <div style={{margin: '12px 0'}}>
                <ul>
                  <li><a href onClick={(e) => onAction(e, 'setLocal')}>Control this Printer Server from this Machine</a> - This will allow management of this print server record.</li>
                </ul>
                </div>
              </div>)
                : null
            }
          </div>
        </div>)
      }
      <Row>
        <Col md={9}>
          <Field
            name='name'
            component={TextInput}
            props={{
              label: 'Name',
              required: true,
              placeholder: 'A descriptive name for this collection of printers; it will be visible to users when selecting a printer.'
            }}
          />
        </Col>
        <Col md={3}>
          <div className='form-group'>
            <label style={{width: '100%'}}>&nbsp;</label>
            <Button
              variant='primary'
              disabled={!canAddServer() && !canEditServer()}
              className='btn btn-primary btn-block btn-sm'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                e.target.blur();
                props.onImportPrinters();
              }}
            >
              Import Printers From QZ Tray
            </Button>
            {
              props.selectedPrinter.qzConnected
                ? null
                : <div className='text-muted' style={{fontSize: 'smaller'}}>
                QZ Tray must be connected to import Printers.
              </div>
            }
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div><strong>Printers</strong></div>
          <div style={{marginBottom: '12px'}}>
            <Row>
            <Col md={9} className='text-muted' style={{fontSize: 'smaller'}}>
              Private printers may not be selected by remote clients.  Present = Yes indicates QZ Tray can see the printer.
            </Col>
            </Row>
            <div style={{clear: 'both'}} />
          </div>
          <table className='table table-sm table-bordered table-striped'>
            <thead>
            <tr>
              <th style={{width: '50%'}}>Name</th>
              <th style={{width: '10%'}}>Status</th>
              <th style={{width: '10%'}}>Resolution</th>
              <th style={{width: '10%'}}>Present</th>
              {/*<th style={{width: '10%'}}>In Flight</th>*/}
              {/*<th style={{width: '10%'}}>Clients</th>*/}
              {/*<th style={{width: '20%'}}>Last Processed</th>*/}
            </tr>
            </thead>
            {
              !props.printers || props.printers.length !== 0
                ? null
                : <tbody><tr><td colSpan={6}>Click Import Printers From Qz Tray to populate this list.</td></tr></tbody>
            }
            <FieldArray
              name='printers'
              component={PrinterForm}
              props={{
                printers: props.printers,
                localPrinters: props.localPrinters,
                isLocalPrintServer: isLocalPrintServer() || !serverId,
              }}
            />
          </table>
        </Col>
      </Row>
      <Row>
        <Col md={3} offset={9}>
          <Field
            name='active'
            component={ReactSelectInput}
            clearable={false}
            disabled={!canAddServer() && !canEditServer()}
            props={{
              label: 'Is Active',
              options: [
                {text: 'Yes', value: 1},
                {text: 'No', value: 0}
              ],

            }}
          />
        </Col>
      </Row>
      <SubmitSection settings={settings}/>
    </form>
  );
};

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  selectedPrinter: PropTypes.object,
  serverId: PropTypes.string,
  onImportPrinters: PropTypes.func,
  printers: PropTypes.array,
  localPrintServer: PropTypes.object,
  onAction: PropTypes.func.isRequired,
  serverActive: PropTypes.bool.isRequired,
  localPrinters: PropTypes.array.isRequired,
};

export default reduxForm({
  form: PRINT_SERVER_FORM,
  enableReinitialize: true,
  validate
})(Form);
