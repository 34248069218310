import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import {Table} from 'react-bootstrap';
import {FaCheck, FaTimes} from 'react-icons/fa';
import get from 'lodash.get';
import NumericInput from '../../common/form/NumericInput';

const getProvinceCode = facility => {
  const code = get(facility, 'province_code', '');
  return (code == null ? '' : code);
};

const isCalifornia = facility => {
  const province_code = getProvinceCode(facility);
  return province_code.toLowerCase() === 'ca';
};

const ProfilesFieldArray = (props) => {
  const {fields: {getAll}, facility, facilities} = props;
  const profiles = getAll() || [];
  const appliesAttributes = ['in_state', 'out_of_state', 'medical', 'recreational'];
  const isCali = isCalifornia(facility);
  if(isCali){
    appliesAttributes.push('non_mmic'); // Actually MMIC - almost all this mmic stuff is backwards
  }
  if(getProvinceCode(facility).toLowerCase() === 'ok') {
    appliesAttributes.push('disabled_veterans');
  }
  const alignCenter = {textAlign: 'center'};
  return (
    <Table condensed={true} striped={true} hover={true}>
      <thead>
      <tr>
        <th style={{width: '100px'}}>{I18n.t('taxes.form.order')}</th>
        <th>{I18n.t('taxes.form.name')}</th>
        <th>{I18n.t('taxes.form.active')}</th>
        {
          appliesAttributes.map((attribute, index) => {
            if(['in_state', 'out_of_state'].indexOf(attribute) === -1) attribute = `to_${attribute}`;
            return (<th key={index} style={alignCenter}>{I18n.t(`taxes.form.applies_to`)}<br />{I18n.t(`taxes.form.applies_${attribute}`)}</th>);
          })
        }
      </tr>
      </thead>
      <tbody>
      {profiles.sort((a, b) => a.weight - b.weight).map((profile) => {
        const index = profiles.indexOf(profile);
        return (
          <tr key={profile.id}>
            <td><Field name={`tax_profiles[${index}].weight`} component={NumericInput} /></td>
            <td>{profile.tax_profile_name}</td>
            <td>{profile.active ? <FaCheck/> : <FaTimes/>}</td>
            {
              appliesAttributes.map((attribute) => { // Reverse the medical and mmic attributes to align columns for california
                const isCali = profile.facility_ids.some(facilityId => {
                  const facility = facilities.find(facility => facility.id === facilityId);
                  return isCalifornia(facility);
                });
                if(isCali && attribute === 'medical') return 'non_mmic';
                if(isCali && attribute === 'non_mmic') return 'medical';
                return attribute;
              }).map((attribute, index) => {
                if(['in_state', 'out_of_state'].indexOf(attribute) === -1) attribute = `to_${attribute}`;
                return (<td key={index} style={alignCenter}>{profile[`applies_${attribute}`] ? <FaCheck/> : <FaTimes/>}</td>);
              })
            }
          </tr>
        );
      })}
      </tbody>
    </Table>);
};



ProfilesFieldArray.propTypes = {
  fields: PropTypes.func,
  facility: PropTypes.object.isRequired,
  facilities: PropTypes.array.isRequired,
};

export default ProfilesFieldArray;
