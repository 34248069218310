import React from 'react';
import PropTypes from 'prop-types';
import {Field, FieldArray} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import TextInput from '../../common/form/TextInput';
import InternationalDecimalInput from '../../common/form/InternationalDecimalInput';
import MultiselectInput from '../../common/form/MultiselectInput';
import PostalCodeField from './PostalCodeTaxField';
import {getOrderFulfillmentOptions} from '../../../constants/orderFulfillmentMethods';
import WillRender from '../../common/concealers/WillRender';
import InlineCheckBox from '../../common/form/InlineCheckBox';
import TaxRateCategoriesFieldArray from './TaxRateCategoriesFieldArray';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import {TAX_TYPE_CODES} from '../../../constants/taxTypeCodes';

const TaxRateFieldArray = (props) => {
  const {fields: {map, length}, categories, taxRates, facility, tax, handleCategoriesChange, handleApplyToAllChange} = props;
  const isOklahoma = facility && facility.province_code && facility.province_code === 'OK';
  const isDisabled = tax && tax.gross_receipts_ok;
  const fulfillmentMethodOptions = getOrderFulfillmentOptions().filter((method) => {
    return !['pickup'].includes(method.value)  && !method.not_fulfillment_method;
  });

  return (
    <div>
      {map((taxRate, taxRateIndex) => {
        const tax = taxRates[taxRateIndex];
        const showDelivery = (tax && tax.fulfillment_methods || []).some(method => (method.value || method) === 'delivery');
        return (
          <div key={taxRateIndex}>
            <Row>
              <Col xs={6} sm={4} md={3}>
                <Field name={`${taxRate}.tax_name`} component={TextInput} props={{
                  label: I18n.t('taxes.form.taxName'),
                  isRequired: true
                }}/>
              </Col>
              <Col xs={6} sm={4} md={4} lg={3}>
                <Field name={`${taxRate}.tax_agency_vendor_name`} component={TextInput} props={{
                  label: I18n.t('taxes.form.taxAgencyVendorName')
                }}/>
              </Col>
              <Col xs={6} sm={3} md={2}>
                <InternationalDecimalInput name={`${taxRate}.amount`} fractionPartSize={3} props={{
                  label: I18n.t('taxes.form.taxRate'),
                  isRequired: true,
                  rightAddon: '%'
                }}/>
              </Col>
              <Col xs={6} sm={3} md={2}>
                <Field
                  name={`${taxRate}.tax_type_code`}
                  component={ReactSelectInput}
                  props={{
                    label: I18n.t('taxes.form.taxTypeCode'),
                    options: TAX_TYPE_CODES,
                    textKey: 'name',
                    valueKey: 'id'
                  }}/>
              </Col>
              <Col className='form-horizontal' xs={4} md={2}  style={{paddingTop: 25}}>
                <Field name={`${taxRate}.is_active`} component={InlineCheckBox} props={{
                  label: I18n.t('taxes.form.active')
                }}/>
              </Col>
            </Row>

            <Row className='fulfillmentMethodContainer'>
              <Col xs={6} md={4} lg={3}>
                <Field name={`${taxRate}.fulfillment_methods`} component={MultiselectInput} props={{
                  label: I18n.t('customer.form.fulfillmentMethod'),
                  options: fulfillmentMethodOptions,
                  allOption: true,
                }}/>
              </Col>
              <Col xs={6} md={4} lg={3}>
                <p>{I18n.t('taxes.form.fulfillmentMethodInfo')}</p>
              </Col>
              <WillRender ifTrue={isOklahoma}>
                <Col md={3}>
                  <div style={{padding: '18px 0'}}>
                    <Field name={`${taxRate}.gross_receipts_ok`} component={InlineCheckBox} props={{
                      label: I18n.t('taxes.form.thisIsOklahomaGrossReceiptsTax'),
                      disabled: isDisabled,
                    }}/>
                  </div>
                </Col>
              </WillRender>
              <PostalCodeField
                name={taxRate}
                show={showDelivery}
              />
            </Row>

            <Row>
              <Col xs={12} md={4} lg={3}>
                <Field
                  name={`${taxRate}.categories`}
                  component={MultiselectInput}
                  onChange={(event, selectedCategories) => handleCategoriesChange(taxRateIndex, selectedCategories)}
                  props={{
                    label: I18n.t('taxes.form.applicableProductCategories'),
                    options: categories,
                    textKey: 'name',
                    valueKey: 'id',
                    allOption: true,
                  }}
                />
              </Col>
              <Col xs={12} md={6} lg={6}>
                <FieldArray
                  component={TaxRateCategoriesFieldArray}
                  name={`${taxRate}.categorySubcategories`}
                  tax={tax}
                  categories={categories}
                  taxRateIndex={taxRateIndex}
                  handleApplyToAllChange={handleApplyToAllChange}
                />
              </Col>
            </Row>

            {length > 1 ? <hr /> : null}
          </div>
        );
      })}
    </div>
  );
};

TaxRateFieldArray.propTypes = {
  categories: PropTypes.array.isRequired,
  taxRates: PropTypes.array.isRequired,
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    length: PropTypes.number.isRequired
  }).isRequired,
  facility: PropTypes.object.isRequired,
  tax: PropTypes.object,
  handleApplyToAllChange: PropTypes.func.isRequired,
  handleCategoriesChange: PropTypes.func.isRequired,
};

export default TaxRateFieldArray;
