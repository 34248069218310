import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Tab, Tabs} from 'react-bootstrap';
import Editor from './Editor';

const DefaultSamplesByDpi = (props) => {

  const spreadProps = {
    loadPrinterModal: props.loadPrinterModal,
    sourceIsSelected: props.sourceIsSelected,
    onSave: props.onSave,
    getLabel: props.getLabel,
    onFieldChange: props.onFieldChange,
    isSample: true
  };

  return (
      <Tabs defaultActiveKey='dpi_203' id='label-editor-default-samples-tabs' style={{marginTop: '16px'}}>
        <Tab eventKey={'dpi_203'} title={I18n.t('labels.defaultDpi203')}>
          <Editor
            resolution={203}
            {...spreadProps} />
        </Tab>
        <Tab eventKey={'dpi_300'} title={I18n.t('labels.defaultDpi300')}>
          <Editor
            resolution={300}
            {...spreadProps} />
        </Tab>
        <Tab eventKey={'dpi_600'} title={I18n.t('labels.defaultDpi600')}>
          <Editor
            resolution={600}
            {...spreadProps} />
        </Tab>
      </Tabs>
  );

};

DefaultSamplesByDpi.propTypes = {
  loadPrinterModal: PropTypes.func.isRequired,
  onDefaultsTabChange: PropTypes.func.isRequired,
  activeDefaultTab: PropTypes.string.isRequired,
  sourceIsSelected: PropTypes.bool,
  onSave: PropTypes.func,
  getLabel: PropTypes.func,
  onFieldChange: PropTypes.func

};

export default DefaultSamplesByDpi;

