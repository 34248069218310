import {createSelector} from 'reselect';

const getStages = (state) => state.stages;

const getStageId = (_, props) => props.stage_id;

export const getStageById = createSelector(
  [getStages, getStageId],
  (stages, stageId) => {
    return stages.reduce((previous, current) => {
      return stageId === current.id ? current : previous;
    }, null);
  }
);

export const getStagesSortedByPhase = createSelector([getStages], (stages) => {
  const orderedStagesCode = {prop: 1, veg: 2, flower: 3};
  return stages.sort((stage1, stage2) => orderedStagesCode[stage1.code] - orderedStagesCode[stage2.code]);
});
