import React from 'react';
import PropTypes from 'prop-types';
import {FieldArray} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import MotherPlantsFieldArray from './MotherPlantsFieldArray';
import SubmitSection from '../../common/form/SubmitSection';


const PackageMotherPlantsForm = (props) => {
  const {reset, handleSubmit, invalid, pristine, submitting, itemMasters, locations, trackingTags} = props;
  const settings = {
    actionSettings: {
      reset: {
        pristine,
        text: I18n.t('common.actions.reset'),
        style: 'default',
        action: reset
      },
      submit: {
        invalid,
        submitting,
        text: I18n.t('common.actions.save'),
      }
    },
    align: 'right'
  };

  return (
    <form noValidate={true} onSubmit={handleSubmit}>
      <FieldArray name='plants' component={MotherPlantsFieldArray} props={{
        itemMasters,
        locations,
        trackingTags,
      }}/>
      <SubmitSection settings={settings}/>
    </form>
  );
};

PackageMotherPlantsForm.propTypes = {
  reset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  itemMasters: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  trackingTags: PropTypes.array,
};

export default PackageMotherPlantsForm;
