import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field, FieldArray} from 'redux-form';
import {Row, Col, Button} from 'react-bootstrap';
import {FaPlus, FaPrint, FaTimes} from 'react-icons/fa';
import TextInput from '../../../common/form/TextInput';
import InternationalDecimalInput from '../../../common/form/InternationalDecimalInput';
import LevelTwoLocations from './LevelTwoLocations';
import StorageLocationType from './StorageLocationType';

const text = {
  storage: {
    label: 'locations.form.roomName',
    add: 'locations.form.addZones'
  },
  sale: {
    label: 'locations.form.locationName',
    add: 'locations.form.addAreas'
  }
};

const LevelOneLocations = ({fields, push, type, createLocation, defaults, showSquareFootage, printLabel, integrationMapping,
                             integratedLocationNames, showIntegratedLocationName, isCanadaFacility, currentFacility, isLeaf,
                             disableLocationEdit, onDelete, isCultivation}) => {

  return (
    <React.Fragment>
    {fields.map((locationName, index) => {
      const location = fields.get(index);
      const childItem = location.child_locations && location.child_locations.length > 0 ? location.child_locations[0] : false;
      if(childItem === undefined) return null;
      return (
        <React.Fragment key={locationName}>
          <Row className='form-fields-row'>
            <Col xs={2}>
              {!disableLocationEdit &&
                <Button
                  size='sm'
                  variant='danger'
                  className='btn-inline float-right'
                  disabled={fields.length < 2}
                  onClick={() => {
                    onDelete(fields.get(index), () => {
                      fields.remove(index);
                    });
                  }} >
                    <FaTimes/>
                </Button>
              }
            </Col>
            <Col className='form-fields-col' xs={7} md={4} lg={3}>
              <Field
                name={`${locationName}.name`} component={TextInput}
                props={{
                  label: I18n.t(text[type].label),
                  isRequired: true,
                  readOnly: disableLocationEdit
                }} />
            </Col>
            <StorageLocationType name={locationName} isCanadaFacility={isCanadaFacility} type={type}/>
            <Col xs={3}>
              {
                childItem.id === undefined
                  ? null
                  : (
                  <Button
                    size='sm'
                    className='btn btn-sm btn-inline float-left'
                    style={{marginRight: '5px'}}
                    onClick={(event) => { printLabel(event, location.id ,'room');}}
                  >
                    <FaPrint/> Print
                  </Button>
                )
              }
              {!disableLocationEdit &&
                <Button size='sm'
                  variant='primary'
                  onClick={() => push(`${locationName}.child_locations`, createLocation(type, 2, location.child_locations.length, defaults))}
                  className='btn-inline float-left' >
                  <FaPlus/>
                </Button>
              }
              <label className='btn-inline'>{I18n.t(text[type].add)}</label>
            </Col>
          </Row>
          {showSquareFootage ? (
            <Row>
              <Col className='form-fields-col' xs={{span: 7, offset: 2}} md={{span: 4, offset: 2}} lg={{span: 3, offset: 2}}>
                <InternationalDecimalInput
                  name={`${locationName}.cultivation_square_footage`}
                  props={{
                    label: I18n.t('locations.form.roomSqft'),
                    isRequired: false,
                    placeholder: I18n.t('locations.form.sqFtPlaceholder')
                  }} />
              </Col>
            </Row>
          ) : null}
          <FieldArray
            name={`${locationName}.child_locations`}
            component={LevelTwoLocations}
            props={{
              push,
              type,
              createLocation,
              defaults,
              printLabel,
              integratedLocationNames,
              showIntegratedLocationName,
              isCanadaFacility,
              currentFacility,
              isLeaf,
              integrationMapping,
              disableLocationEdit,
              onDelete,
              isCultivation,
            }}/>
        </React.Fragment>
      );
    })}
    </React.Fragment>);
};

LevelOneLocations.propTypes = {
  printLabel: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  createLocation: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['storage', 'sale']).isRequired,
  push: PropTypes.func.isRequired,
  defaults: PropTypes.array.isRequired,
  showSquareFootage: PropTypes.bool,
  integratedLocationNames: PropTypes.array,
  showIntegratedLocationName: PropTypes.bool,
  isCanadaFacility: PropTypes.bool,
  currentFacility: PropTypes.object.isRequired,
  isLeaf: PropTypes.bool,
  integrationMapping: PropTypes.array,
  disableLocationEdit: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  isCultivation: PropTypes.bool,
};

LevelOneLocations.defaultProps = {
  showSquareFootage: true
};

export default LevelOneLocations;
