import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import InternationalDisplayQty from '../../../../common/InternationalDisplayQty';
import InternationalDecimalStatic from '../../../../common/form/InternationalDecimalStatic';

const LimitRow = ({
  isBiotrack,
  areConsumerLimitsLoading,
  getTotalLimit,
  limit,
  getDisplayRulesPropertySum,
  consumer,
  getStyle,
}) =>
   isBiotrack ? null : (<tfoot>
  <tr>
    <th style={{textAlign: 'right'}}>Limit and Totals</th>
    <th style={getStyle()}>
      {areConsumerLimitsLoading()
        ? '...'
        :
          <InternationalDecimalStatic suffix={` GR / ${limit.interval} ` + I18n.t(`retail.restrictSalesTimePeriods.${limit.time_period}`)}>{getTotalLimit()}</InternationalDecimalStatic>
      }
    </th>
    <th style={getStyle()}>
      {areConsumerLimitsLoading()
        ? '...'
        // fight against warnings
        : <InternationalDisplayQty qty={getDisplayRulesPropertySum('previous_orders')} uom='GR' displayUom={true}/>
      }
    </th>
    {
      consumer
        ? null
        // fight against warnings
        : <th style={getStyle()}><InternationalDisplayQty qty={getDisplayRulesPropertySum('order')} uom='GR' displayUom={true}/></th>
    }
  </tr>
  </tfoot>);

LimitRow.propTypes = {
  isBiotrack: PropTypes.bool.isRequired,
  areConsumerLimitsLoading: PropTypes.func.isRequired,
  getTotalLimit: PropTypes.func.isRequired,
  limit: PropTypes.object.isRequired,
  getDisplayRulesPropertySum: PropTypes.func.isRequired,
  consumer: PropTypes.object,
  getStyle: PropTypes.func.isRequired,
  isAnonymousOrder: PropTypes.bool,
  isMdMetrc: PropTypes.bool,
};

export default LimitRow;
