import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { goBack } from 'react-router-redux';
import get from 'lodash.get';
import {Row, Col, Form, Card} from 'react-bootstrap';

import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import * as apiActions from '../../actions/apiActions';
import {getCustomerRecord} from '../../selectors/customerSelectors';
import {getCustomerHistoryTableData} from '../../selectors/customerHistorySelectors';

import FormWrapper from '../common/form/FormWrapper';
import TablePageWrapper from '../common/grid/TablePageWrapper';

/**
 * Consumer event log component.
 *
 * `/patients/{id}/history`
 */
export class CustomerHistoryPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.renderChangeDetails = this.renderChangeDetails.bind(this);
    this.renderPatientData = this.renderPatientData.bind(this);
  }

  // Fetch customer details + event log from API
  componentWillMount() {
    const { actions: {getItem,getUnpaginatedData} } = this.props;

    getItem(
      `/api/customers/${this.props.params.id}`,
      itemNames.customer,
      { failed: I18n.t('customers.getCustomer.failed') }
    );

    getUnpaginatedData(
      `/api/customers/${this.props.params.id}/log`,
      dataNames.customerHistory,
      { failed: I18n.t('customers.getCustomerHistory.failed') }
    );

    getUnpaginatedData(
      '/api/customers/qualifying_conditions',
      dataNames.qualifyingConditions
    );
  }

  // Render change details for a single update event
  renderChangeDetails(row) {
    const changes = row.change_details.map( (change,i) => {
      return (
        <Col key={i} sm={6} lg={3}>
          <Card body>
            <p>
              <Form.Label>{change.field}</Form.Label>
            </p>

            <p className='text-muted'>
              {I18n.t('customers.history.previousLabel')}:
            </p>
            <pre style={{whiteSpace: 'pre-wrap'}}>{change.previous}</pre>

            <p className='text-muted'>
              {I18n.t('customers.history.currentLabel')}:
            </p>
            <pre style={{whiteSpace: 'pre-wrap'}}>{change.current}</pre>
          </Card>
        </Col>
      );
    });

    return <Row>{changes}</Row>;
  }

  // Render consumer identifier data
  renderPatientData(label,value) {
    return (
      <p>
        <Form.Label style={{minWidth: 125}}>{label}:</Form.Label>
        <span>{value}</span>
      </p>
    );
  }

  // Render primary component
  render() {
    const { customer, customerHistory } = this.props;

    const tableColumns = [
      {
        name: I18n.t('general.date'),
        dataId: 'event_date',
        width:'200px'
      },
      {
        name: I18n.t('general.user'),
        dataId: 'user_name',
        width:'200px'
      },
      {
        name: I18n.t('customers.history.fieldsChanged'),
        dataId: 'fields_changed',
        dataAlign: 'left',
      },
    ];

    return (
      <FormWrapper
        title={I18n.t('customers.history.title')}
        goBack={this.props.actions.goBack}
        className='customer-history-page'
        >

        {this.renderPatientData(
          I18n.t('customers.history.patientName'),
          get(customer,'first_name','') + ' ' + get(customer,'last_name')
        )}

        {this.renderPatientData(
          I18n.t('customers.history.patientId'),
          customer.id
        )}

        {this.renderPatientData(
          I18n.t('customers.history.patientCreated'),
          customer.created_at
        )}

        <TablePageWrapper
          settingKey='patientLogs'
          columns={tableColumns}
          data={customerHistory}
          handleSelect={() => {}}
          hideExport={true}
          hideScanSearch={true}
          pageSize={3}
          bstProps={{
            expandableRow: () => true,
            expandComponent: this.renderChangeDetails,
          }}
        />

      </FormWrapper>
    );
  }
}

CustomerHistoryPage.propTypes = {
  params: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  customerHistory: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    customer: getCustomerRecord(state),
    customerHistory: getCustomerHistoryTableData(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, { goBack });
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomerHistoryPage);
