/* eslint-disable react/no-multi-comp */
import React from 'react';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import {FaSave, FaTrash} from 'react-icons/fa';
import SingleActionColumn from '../../common/grid/columns/SingleActionColumn';
import InternationalDisplayQty from '../../common/InternationalDisplayQty';
import InternationalDateStatic from '../../common/form/InternationalDateStatic';
import {getIntegrationState} from '../../../selectors/integration/integrationSelectors';
import {isCanadaFacility} from '../../../selectors/facility/getFacilityCountry';
import {hasPackagesTags} from '../../../selectors/integrationSelectors';
import {isInactivationReasonDisplayed} from '../../../selectors/forms/prepackWeightsFacilitySelectors';
import {canUserManageReservationAndIsAllowedInComplianceSettings} from '../../../selectors/forms/productFormSelectors';
import * as p from '../../../constants/permissions';
import LotIntegrationTrackingStatus from '../LotIntegrationTrackingStatus';

const sortColumns = (columns) => columns
  .map((column, index) => ({...column, position: column.position ? column.position : index}))
  .sort((column1, column2) => column1.position - column2.position);

/**
 * Callback to disable 'View Lineage' button for items with
 * no lot_id.
 */
const disableLineage = (row) => !get(row,'lot_id',false);


const canUserManageReservationAndIsAllowedInComplianceSettings_ = (component) => {
  const {props: {state}} = component;
  return canUserManageReservationAndIsAllowedInComplianceSettings(state, {permissions: [p.manage_reservations]});
};

export const getCommonInventoryColumns = (component) => {
  const {props: {state}} = component;

  let columns = [
    {
      name: I18n.t('ei.inventory.table.batchNumber'),
      dataId: 'lot_number',
      hidden: false,
      dataSort: true,
      width: '130px',
      scanSearchColumn:true,
    },
    {
      name: I18n.t('ei.inventory.table.product'),
      dataId: 'name',
      hidden: false,
      dataSort: true,
      width: '225px',
      scanSearchColumn:true,
      formatter: (cell, row) => {
        if (row.is_waste && cell.indexOf('Waste:') === -1) {
          return `Waste: ${cell}`;
        }
        return cell;
      }
    }
  ];

  canUserManageReservationAndIsAllowedInComplianceSettings_(component) ?
    columns.push({
      name: I18n.t('ei.inventory.table.quantity'),
      dataId: 'weight_gr_total',
      hidden: false,
      dataSort: false,
      width: '155px',
      formatter: (cell, row) => {
        if (parseFloat(cell) < 0) {
          return (<span><span className='danger'><InternationalDisplayQty qty={cell} uom={row.uom_display} /></span> (<InternationalDisplayQty qty={row.weight_reserved_total} uom={row.uom_display} />)</span>);
        } else {
          return (<span><InternationalDisplayQty qty={cell} uom={row.uom_display} /> (<InternationalDisplayQty qty={row.weight_reserved_total} uom={row.uom_display} />)</span>);
        }
      }
    }) :
    columns.push({
      name: I18n.t('ei.inventory.table.quantityNoReservation'),
      dataId: 'weight_gr_total',
      hidden: false,
      dataSort: false,
      width: '155px',
      formatter: (cell, row) => {
        if (parseFloat(cell) < 0) {
          return (<span className='danger'><InternationalDisplayQty qty={cell} uom={row.uom_display} /></span>);
        } else {
          return (<InternationalDisplayQty qty={cell} uom={row.uom_display} />);
        }
      }
    });
  columns = columns.concat([
    {
      name: I18n.t('cei.inventory.table.uom'),
      dataId: 'uom_display',
      hidden: false,
      dataSort: true,
      width: '60px',
    },
    {
      name: 'cultivation.finishedProduct.form.storageLocation',
      dataId: 'location_name',
      hidden: false,
      dataSort: false,
      width: '150px',
    },
    {
      name: I18n.t('ei.inventory.table.dateCreated'),
      dataId: 'package_created_at',
      hidden: false,
      dataSort: true,
      width: '110px',
      formatter: (cell, row) => {
        if (cell === 'Multiple') {
          return cell;
        }
        if (row.package_created_at) {
          return <InternationalDateStatic  adjustForClientTimezone={false}>{cell}</InternationalDateStatic>;
        }

        if (row.lot_created_at === 'Multiple') {
          return row.lot_created_at;
        }
        return <InternationalDateStatic>{row.lot_created_at}</InternationalDateStatic>;
      },
    },
    {
      name: I18n.t('ei.inventory.table.vendor'),
      dataId: 'vendor_name',
      hidden: false,
      dataSort: false,
      width: '175px',
    },
    {
      name: I18n.t('ei.inventory.table.lineage'),
      dataId: 'lineage',
      hidden: false,
      dataSort: false,
      width: '80px',
      formatter: (cell, row) => {
        return SingleActionColumn({
          label: 'common.view',
          action: component.showLineageModal,
          style: 'primary',
          isDisabled: disableLineage,
        })(cell, row);
      }
    }
  ]);

  const {isLeaf, isMetrc, isBiotrack} = getIntegrationState(state);

  if (isLeaf || isMetrc || isBiotrack) {
    columns.push({
      name: I18n.t('cultivation.finishedProduct.form.trackingID'),
      dataId: 'state_integration_tracking_id',
      hidden: false,
      dataSort: false,
      width: '150px',
      scanSearchColumn: true,
      formatter: (cell, row) => {
        return <LotIntegrationTrackingStatus lot={row} />;
      }
    });
  }
  else if (hasPackagesTags(state)) {
    columns.push({
      name: I18n.t('cultivation.finishedProduct.form.soloTag'),
      dataId: 'state_integration_tracking_id',
      hidden: false,
      dataSort: false,
      width: '150px',
      scanSearchColumn: true,
    });
  }

  if (isLeaf) {
    columns.push({
      name: I18n.t('cultivation.finishedProduct.form.testResults'),
      dataId: 'test_results_status',
      hidden: false,
      dataSort: false,
      width: '90px',
      scanSearchColumn: false,
      formatter: (cell) => {
        const className = cell === 'failed' ? 'danger' : cell === 'passed' ? 'success' : 'text-muted';
        return (
          cell === 'passed' ? <FaSave className={className}/> : <FaTrash className={className}/>
        );
      }
    });
  }

  columns.push({
    name: I18n.t('cultivation.finishedProduct.form.purposes'),
    dataId: 'purpose_code',
    hidden: !isCanadaFacility(state),
    dataSort: false,
    width: '150px',
    formatter: (cell, row) => row.purpose,
    position: 100 //position rank should be more then column count
  });

  return sortColumns(columns);
};

export const getActiveInventoryColumns = (component) => sortColumns(getCommonInventoryColumns(component).concat([
  {
    name: I18n.t('cultivation.finishedProduct.form.reserved'),
    dataId: 'is_reserved',
    hidden: !canUserManageReservationAndIsAllowedInComplianceSettings_(component),
    dataSort: false,
    width: '125px',
    formatter: (cell) => cell === -1 ? 'Multiple' : (cell ? 'Yes' : 'No'),
  },
  {
    name: I18n.t('ei.inventory.table.onHold'),
    dataId: 'on_hold',
    hidden: false,
    dataSort: false,
    width: '125px',
    formatter: (cell) => cell === -1 ? 'Multiple' : cell ? 'Yes' : 'No',
  },
]));

export const getInactiveInventoryColumns = (component) => {
  const {props: {state}} = component;
  const columns = [...getCommonInventoryColumns(component)];
  if (isInactivationReasonDisplayed(state)) {
    columns.push({
      name: I18n.t('cultivation.finishedProduct.form.inactivationReason'),
      dataId: 'inactivation_reason',
      hidden: false,
      dataSort: false,
      scanSearchColumn: false,
      width: '125px',
      position: 101,
    });
  }
  return sortColumns(columns);
};

export const getCommonIngredientColumns = (component) => {
  const {props: {state}} = component;

  return sortColumns([
    {
      name: I18n.t('ingredients.table.sku'),
      dataId: 'item_number',
      hidden: false,
      dataSort: true,
      width: '160px',
      scanSearchColumn:true
    },
    {
      name: I18n.t('ingredients.table.category'),
      dataId: 'ingredient_category_name',
      hidden: false,
      dataSort: true,
      width: '160px',
      formatter: (cell) => {
        const category = component.props.ingredientCategories.find(category => category.code === cell);
        return category ? category.name : cell;
      },
    },
    {
      name: I18n.t('ei.inventory.table.product'),
      dataId: 'name',
      hidden: false,
      dataSort: true,
      width: '160px',
      scanSearchColumn:true
    },
    {
      name: I18n.t('ei.inventory.table.quantity'),
      dataId: 'weight_gr_total',
      hidden: false,
      dataSort: false,
      width: '150px',
      formatter: (cell, row) => {
        return (<span>
          <InternationalDisplayQty qty={cell} uom={row.uom_display} /> (<InternationalDisplayQty qty={row.weight_reserved_total} uom={row.uom_display} />)
        </span>);
      },
    },
    {
      name: I18n.t('ei.inventory.table.uom'),
      dataId: 'uom_display',
      hidden: false,
      dataSort: true,
      width: '75px'
    },
    {
      name: I18n.t('ei.inventory.table.dateCreated'),
      dataId: 'package_created_at',
      hidden: false,
      dataSort: true,
      width: '125px',
      formatter: (cell) => <InternationalDateStatic>{cell}</InternationalDateStatic>,
    },
    {
      name: I18n.t('cultivation.finishedProduct.form.purposes'),
      dataId: 'purpose_code',
      hidden: !isCanadaFacility(state),
      dataSort: false,
      width: '150px',
      formatter: (cell, row) => row.purpose,
      position: 100 //position rank should be more then column count
    }
  ]);
};
