import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';
import {connect} from 'react-redux';

import CompleteProcessingForm from './CompleteProcessingForm'; //eslint-disable-line
import validate from './validate';
import {roundQtyCurried} from '../../../../selectors/uomsSelectors';

const CompleteProcessingFormWrapper = reduxForm({
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(CompleteProcessingForm);

const mapStateToProps = state => ({
  roundQty: roundQtyCurried(state)
});

CompleteProcessingFormWrapper.propTypes = {
  form: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  is_cannabis_mix: PropTypes.bool,
  isManufacturing: PropTypes.bool,
  products: PropTypes.array.isRequired,
  employees: PropTypes.array.isRequired,
  equipment: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  productionRuns: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
  trackingIds: PropTypes.array.isRequired,
  phases: PropTypes.array.isRequired,
  weights: PropTypes.object.isRequired,
  ingredients: PropTypes.array.isRequired,
  ingredientOptions: PropTypes.object.isRequired,
  ingredientsCost: PropTypes.number,
  ingredientsStandardCost: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  loading: PropTypes.bool,
  status: PropTypes.string,
  outputItemIds: PropTypes.array.isRequired,
  showPrinter: PropTypes.func.isRequired,
  adjustmentReasons: PropTypes.array,
};

export default connect(mapStateToProps)(CompleteProcessingFormWrapper);
