import {createSelector} from 'reselect';
import orderBy from 'lodash.orderby';
import {LEAF} from '../constants/imageUrls';
import * as dataNames from '../constants/dataNames';

const getImages = (state) => state.images;
const getCategories = state => state.categories;
export const getProducts = (state) => state[dataNames.products];
export const getSelectedProducts = (state) => state.selectedProducts;
const getProductFilter = (state) => state.productFilter;
const getCategoryId = (_, props) => props.category_id;

export const filteredProducts = createSelector([getProducts, getProductFilter], (products, productFilter) => {
  if(productFilter !== ''){
    return products.filter(product => (product.strain.toLowerCase().indexOf(productFilter.toLowerCase()) > -1));
  }else if (products){
    return products;
  }else{
    return [];
  }
});

export const categoriesByIds = createSelector(
  getCategories,
  categories => {
    const categoriesByIds = {};
    categories.forEach(category => categoriesByIds[category.id] = category);
    return categoriesByIds;
  }
);

export const categoryById = createSelector(
  [getCategories, getCategoryId],
  (categories, id) => {
    return categories.find(category => category.id === id);
  }
);


export const getProductsWithImages = createSelector ([getProducts, getImages], (item_masters, images) => {
  if(item_masters && images) {
    return item_masters.map(item_master => {
      const image = images.find(image => image.id === item_master.primary_product_image_file_id);
      return {...item_master, image_url: image ? image.url.original : LEAF};
    });
  }
});

export const areSelectedProductsDestroyed = createSelector(
  [getSelectedProducts],
  products => products.some(product => product.is_destroyed)
);

export const getSelectedProductIds = createSelector(getSelectedProducts, selectedProducts => selectedProducts.map(selectedProduct => selectedProduct.id));

export const getFoundPackages = createSelector(
  [getProducts],
  products => orderBy(
    products.reduce(
      (acc, product) => {
        let option = acc.find(option => option.lot_id === product.lot_id && option.package_id === product.package_id);
        if (!option) {
          option = {
            package_id: product.package_id,
            package_code: product.package_code,
            lot_id: product.lot_id,
            lot_number: product.lot_number,
            product_name: product.parent_item_master_name || product.name || product.item_name,
            optionName: `${product.lot_number} - ${product.package_code} - ${product.parent_item_master_name || product.name || product.item_name}`,
            items: [],
          };
          acc.push(option);
        }
        option.items.push(product);
        return acc;
      },
      []
    ),
    ['optionName'],
    ['asc']
  )
);
