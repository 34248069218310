import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import moment from 'moment';
import constants from './constants';
import {
  requiredFieldValidation,
  lotNumberValidation,
  packageCodeValidation,
  metrcTrackingIdValidation,
  isDate,
  maxValidation
} from '../../../common/form/redux-form/validations';

const validatePackageCode = (lines, item) => {
  const packageErrors = {
    itemMaster: I18n.t('receipts.validationErrors.itemMaster'),
    facility: I18n.t('receipts.validationErrors.facility'),
    lotNumber: I18n.t('receipts.validationErrors.lotNumber')
  };

  if(item.packageCodeError) return packageErrors[item.packageCodeError];
  if(!item.package_code) return undefined;

  const itemMasterNames = [];
  const itemMasterIds = [];
  const lotNumbers = [];

  lines.forEach((line, lineIndex) => {
    line.inventory.forEach((inventory, itemIndex) => {
      if (Array.isArray(inventory.prepack_inventory_rows)) {
        inventory.prepack_inventory_rows.forEach((subItem, subItemIndex) => {
          if(subItem.package_code === undefined) return true;
          if(subItem.package_code === item.package_code) {
            if(itemMasterIds.indexOf(subItem.item_master_id) === -1) {
              itemMasterIds.push(subItem.item_master_id);
              itemMasterNames.push(subItem.name);
            }
            if(lotNumbers.indexOf(subItem.lot_number) === -1){
              lotNumbers.push(subItem.lot_number);
            }
          }
        });
      }
      else {
        if(inventory.package_code === undefined) return true;
        if(inventory.package_code === item.package_code) {
          if(itemMasterIds.indexOf(inventory.item_master_id) === -1) {
            itemMasterIds.push(inventory.item_master_id);
            itemMasterNames.push(inventory.name);
          }
          if(lotNumbers.indexOf(inventory.lot_number) === -1){
            lotNumbers.push(inventory.lot_number);
          }
        }
      }
    });
  });

  const messages = [];

  if(itemMasterIds.length > 1) {
    const msg = I18n.t('receipts.validationErrors.multipleProducts');
    messages.push(`${msg}${itemMasterNames.join(', ')}`);
  }
  if(lotNumbers.length > 1){
    const msg = I18n.t('receipts.validationErrors.multipleLotNumbers');
    messages.push(`${msg}${lotNumbers.join(', ')}`);
  }
  if(messages.length) return messages.join(' ');

  return undefined;

};

const validate = (values, props) => {

  const momentDateTimeFormat = props.internationalDateFormat;

  // This allows undefined and null and empty string - not a "validDate" so much as valid enough for a given circumstance
  const isValidDate = (value) => {
    return (value && moment(value, momentDateTimeFormat, true).isValid() || value === null || value === undefined  || value === '' ? undefined : I18n.t('common.form.wrongInternationalDateTimeFormat', {format: momentDateTimeFormat}));
  };

  //if require_on_hand_product_expire_date is set to yes we will require them to enter an exp date on receiving only. Locked means re-assigning. == because it comes in as a string
  const { require_on_hand_product_expire_date } = values;
  const { locked } = props;
  const isDateRequiredAndValid = (value) => {
    if (require_on_hand_product_expire_date == 1 && !value && !locked) {
      return requiredFieldValidation(value);
    }
    return isValidDate(value);
  };

  const errors = {};
  const {isBiotrack} = props.integrationState;
  const {isMetrc} = props.integrationState;
  const validStorageLocationIds = Array.isArray(props.storageLocations) ? props.storageLocations.map((location) => parseInt(location.value)) : []; // Flattened locations push the id into value
  const isOpenCbdFlowerOrHempWasteReceipt = values.contains_only_cbd_flower_or_hemp_waste && values.status === 'open';

  const storageLocationIdIsValid = (error, value) => {
    error = requiredFieldValidation(value); // Validates null, undefined, empty string
    if(error === undefined){
      if(validStorageLocationIds.indexOf(parseInt(value)) === -1){ // Validates presence in list of locations
        // The id from leaf mapping is not in the valid storage locations in platform...
        // so we throw a null error because for the user it's functionally the same
        error = requiredFieldValidation(null);
      }
    }
    return error;
  };

  // Require date_received if the status is completed.
  if(values.status === 'completed'){
    const dateReceived = values.date_received;
    errors.date_received = isDate(dateReceived)
      ? undefined
      : I18n.t('common.form.wrongInternationalDateTimeFormat', {format: momentDateTimeFormat});
  }
  errors.lines = [];
  const lines = values.lines;
  lines.forEach((line, lineIndex) => {
    const itemMaster = line.itemMaster || {};

    errors.lines[lineIndex] = {};
    errors.lines[lineIndex].lot_number = lotNumberValidation(line.lot_number, false, itemMaster.is_inventory_item && !isOpenCbdFlowerOrHempWasteReceipt);
    errors.lines[lineIndex].qty = maxValidation(line.qty, false, constants.QTY_LIMIT);

    errors.lines[lineIndex].item_master_id = (itemMaster.active === 0 && get(props, 'initialValues.status') !== 'completed')
      ? I18n.t('purchaseOrders.form.inactiveItemMasterError', {item_master: itemMaster.name}) : undefined;
    if (line.inventory && line.inventory.length) {
      errors.lines[lineIndex].inventory = [];
      line.inventory.forEach(((item, itemIndex) => {
        const isMetrcNotRejectedPackage = isMetrc && item.qty !== 0;
        errors.lines[lineIndex].inventory[itemIndex] = {};
        if (isMetrc) {
          errors.lines[lineIndex].inventory[itemIndex].state_integration_tracking_id =
            requiredFieldValidation(item.state_integration_tracking_id) ||
            metrcTrackingIdValidation(item.state_integration_tracking_id);
        }
        if (!isMetrc || isMetrcNotRejectedPackage) {
          errors.lines[lineIndex].inventory[itemIndex].lot_number = lotNumberValidation(item.lot_number, false, itemMaster.is_inventory_item && !isOpenCbdFlowerOrHempWasteReceipt);
        }
        // can't explain why Field validation is not working for these 2 fields
        errors.lines[lineIndex].inventory[itemIndex].date_item_created = isValidDate(item.date_item_created);
        errors.lines[lineIndex].inventory[itemIndex].date_item_use_by = isValidDate(item.date_item_use_by);
        errors.lines[lineIndex].inventory[itemIndex].qty = maxValidation(item.qty, false, constants.QTY_LIMIT);
        if (Array.isArray(item.prepack_inventory_rows)) {
          errors.lines[lineIndex].inventory[itemIndex].prepack_inventory_rows = [];
          item.prepack_inventory_rows.forEach((subItem, subItemIndex) => {
            errors.lines[lineIndex].inventory[itemIndex].prepack_inventory_rows[subItemIndex] = {};
            errors.lines[lineIndex].inventory[itemIndex].prepack_inventory_rows[subItemIndex].item_master_id
              = requiredFieldValidation(subItem.item_master_id);
            if (!isMetrc) {
              errors.lines[lineIndex].inventory[itemIndex].prepack_inventory_rows[subItemIndex].qty = requiredFieldValidation(subItem.qty);
            }
            if (!isMetrc || isMetrcNotRejectedPackage) {
              errors.lines[lineIndex].inventory[itemIndex].prepack_inventory_rows[subItemIndex].storage_location_id
                = storageLocationIdIsValid(errors.lines[lineIndex].inventory[itemIndex].prepack_inventory_rows[subItemIndex].storage_location_id, subItem.storage_location_id);
            }
            errors.lines[lineIndex].inventory[itemIndex].prepack_inventory_rows[subItemIndex].package_code
              = packageCodeValidation(subItem.package_code) || validatePackageCode(lines, subItem);
            if (isBiotrack) {
              errors.lines[lineIndex].inventory[itemIndex].prepack_inventory_rows[subItemIndex].integration_type
                = requiredFieldValidation(subItem.integration_type);
            }
            errors.lines[lineIndex].inventory[itemIndex].prepack_inventory_rows[subItemIndex].date_item_expired = isDateRequiredAndValid(lines[lineIndex].inventory[itemIndex].prepack_inventory_rows[subItemIndex].date_item_expired);
          });
        } else {
          if (!isMetrc) {
            errors.lines[lineIndex].inventory[itemIndex].qty = requiredFieldValidation(item.qty);
          }
          if ((!isMetrc || isMetrcNotRejectedPackage) && !isOpenCbdFlowerOrHempWasteReceipt) {
            errors.lines[lineIndex].inventory[itemIndex].storage_location_id = storageLocationIdIsValid(errors.lines[lineIndex].inventory[itemIndex].storage_location_id, item.storage_location_id);
          }
          errors.lines[lineIndex].inventory[itemIndex].package_code = packageCodeValidation(item.package_code) || validatePackageCode(lines, item);
          if (isBiotrack) {
            if(get(item, 'non_medicated', false) == false){
              errors.lines[lineIndex].inventory[itemIndex].integration_type = requiredFieldValidation(item.integration_type);
            }
          }
          errors.lines[lineIndex].inventory[itemIndex].date_item_expired = isDateRequiredAndValid(lines[lineIndex].inventory[itemIndex].date_item_expired);
        }
      }));
    }
  });
  return errors;
};

export default validate;
