import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { goBack } from 'react-router-redux';
import { change } from 'redux-form';
import moment from 'moment';
import get from 'lodash.get';
import FormWrapper from '../../common/form/FormWrapper';
import reduxMetrcIdAvailability, {
  setUnavailableTrackingIdFieldArrayErrors
} from '../../common/form/redux-form/reduxMetrcIdAvailability';
import CreateHarvestPackagesReduxForm from './CreateHarvestPackagesReduxForm';
import { fetchMetrcTrackingIdsForSelectInput } from '../../../actions/integrationActions';
import { fetchModalitiesByStrainId } from '../../../actions/integrations/colombia';
import { validateCurrentOnHandWeight } from '../../../actions/complianceSettingsActions';
import * as apiActions from '../../../actions/apiActions';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import PrinterModal from '../../printer/PrinterModal'; //eslint-disable-line
import InProgressOverlay from '../../common/InProgressOverlay';
import { formatDBDate, convertFormInputDateToDbDate } from '../../../util/dateHelpers';
import { getCreateHarvestPackagesPageProps } from '../../../selectors/forms/createHarvestPackagesFormSelectors';
import { CREATE_HARVEST_PACKAGES } from '../../../constants/forms';
import ModalWrapper from '../../common/ModalWrapper';

export class CreateHarvestPackagesPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.onSubmit = this.onSubmit.bind(this);
    this.saveAndPrint = this.saveAndPrint.bind(this);
    this.redirect = this.redirect.bind(this);
    this.printLabel = this.printLabel.bind(this);
    this.hidePrinter = this.hidePrinter.bind(this);
    this.doPrint = this.doPrint.bind(this);

    this.state = {
      package: 0,
      doPrint: false,
      saveComplete: false,
      showLoader: true,
      ready: false,

      showRetryModal: false,
      retryModalText: '',
      retryModalOnOkay: () => {},
    };
  }

  componentWillMount() {
    const { getUnpaginatedData, getDataBatch, getItem, getData, fetchModalitiesByStrainId } = this.props.actions;
    const {
      harvestBatch,
      integrationState: { isAkMetrc, isCoMetrc, isColombia }
    } = this.props;
    const itemMasterFilterParams = { active: 1, include_prepack: 0, is_medicated: 1 };
    const removeLoader = () => this.setState({ ready: true, showLoader: false });
    const afterItemMasterWeightLoaded = () => {
      if (isAkMetrc || isCoMetrc) {
        return getDataBatch(`/api/item_masters/harvest/${harvestBatch.strain_id}`, dataNames.itemMastersWeighted);
      }
    };

    const complianceSettingsIds = [
      'harvest_batch_inherits_results_from_test_sample',
      'inv_allow_package_location_split',
      'inv_compliance_settings',
      'inv_configurable_lab_results_enabled',
      'inv_display_inactivation_reason',
      'inv_lab_apply_profiles',
      'inv_lab_edit_requires_manage_lab_testing',
      'inv_lab_result_dimensions',
      'inv_lab_testing_profiles',
      'inv_packages_require_tracking_id',
      'inv_packages_require_tracking_id_message',
      'inv_packaging_seeds',
      'inv_require_waste_transfer',
      'inv_test_results_compliance_settings',
      'inv_track_waste_location_id',
      'inv_track_waste_packages',
      'cult_colombia_modality'
    ];

    const promises = [
      getUnpaginatedData(
        '/api/item_masters/weighted',
        dataNames.itemMastersWeighted,
        undefined,
        itemMasterFilterParams
      ).then(afterItemMasterWeightLoaded),
      getUnpaginatedData('/api/categories', dataNames.categories, { failed: 'categories.get.failed' }),
      getUnpaginatedData('/api/integration/harvest-waste-types', dataNames.harvestWasteTypes, {
        failed: 'harvest.form.getHarvestWasteTypes.failed'
      }),
      getUnpaginatedData(
        '/api/location_hierarchy',
        dataNames.locations,
        { failed: 'locations.getLocations.failed' },
        { is_sales_location: 0 }
      ),
      getUnpaginatedData(
        '/api/users/current_facility',
        dataNames.currentFacilityUsers,
        { failed: 'ei.activeProcessing.failedLoadUsers' },
        { active: 1 }
      ),
      getItem('/api/compliance_settings/on_hand_weight/facility', itemNames.onHandWeightFacility),
      getItem('/api/compliance_settings', itemNames.complianceSettings, null, { ids: complianceSettingsIds }),
      getItem('/api/cultivation/settings', itemNames.cultivationSettings)
    ];

    if (isColombia) {
      promises.push(getData('/api/cupos', dataNames.cupos));
      promises.push(fetchModalitiesByStrainId(harvestBatch.strain_id, { with_relations: ['cupo'] }));
    }

    if (this.props.integrationState.isMetrc) {
      promises.push(this.props.actions.fetchMetrcTrackingIdsForSelectInput());
    }
    if (get(this.props, 'integrationState.isLeaf')) {
      if (get(this.props, 'integrationState.isWaLeaf')) {
        promises.push(getUnpaginatedData('/api/leaf/get_wa_inventory_harvest', dataNames.leafWaHarvestTypes));
      } else {
        promises.push(
          getUnpaginatedData('/api/leaf/categories', dataNames.leafCategories),
          getUnpaginatedData('/api/leaf/categories/mapping', dataNames.leafCategoryMappings)
        );
      }
    }
    if (this.props.integrationState.isBiotrack) {
      promises.push(
        getUnpaginatedData('/api/biotrack/categories', dataNames.biotrackCategories),
        getUnpaginatedData('/api/biotrack/categories/mapping', dataNames.biotrackCategoryMappings)
      );
    }

    Promise.all(promises)
      .then(removeLoader)
      .catch(removeLoader);
  }

  onSubmit (payload) {
    if (this.state.saveComplete) {
      if (this.state.doPrint) {
        this.printLabel(this.state.package);
      }
      return false;
    }

    if (!this.props.actions.validateCurrentOnHandWeight()) {
      return false;
    }

    const clearLoader = () => {
      this.setState({showLoader: false});
    };

    this.setState({showLoader: true});

    const getItemsNotCreated = (responseData) => {
      return get(responseData, 'warnings.INV_ITEM_NOT_CREATED', []);
    };

    const hasItemsNotCreated = (responseData) => {
      return getItemsNotCreated(responseData).length;
    };

    /**
     // Parses response data for warning of items not created, gets them from original payload, then updates
     // form and payload for resubmit.
     * @param responseData
     * @param originalPayload
     */
    const showRetryModal = (responseData, originalPayload) => {
      const itemsNotCreated = getItemsNotCreated(responseData);
      const originalPackages = get(originalPayload, 'packages', []);
      const packagesNotCreated = itemsNotCreated.reduce((acc, itemJson) => {
        const item = JSON.parse(itemJson);
        const matchingPackage = originalPackages.find((p) => {
          return get(item, 'package_code') === get(p, 'package_code');
        });
        if (matchingPackage) {
          acc.push(matchingPackage);
        }
        return acc;
      }, []);
      const packageCodes = packagesNotCreated.map((p) => p.package_code);
      // Update the form in case they cancel.
      this.props.actions.change(CREATE_HARVEST_PACKAGES, 'packages', packagesNotCreated);
      this.props.actions.change(CREATE_HARVEST_PACKAGES, 'waste', 0);
      this.props.actions.change(CREATE_HARVEST_PACKAGES, 'waste_other_material', 0);
      this.setState({
        submitResponseData: responseData,
        retryModalText: I18n.t('harvestPackages.create.warningText', {packages: packageCodes.join(', ')}),
        showRetryModal: true,
        retryModalOnOkay: () => {
          const newPayload = Object.assign({}, originalPayload, {
            packages: packagesNotCreated,
            waste_other_material: 0,
            waste: 0
          });
          submitHarvestBatch(newPayload);
        },
      });
    };

    const submitHarvestBatch = (payload, originalPayload) => {
      this.setState({showLoader: true}, () => {
        const onSuccess = (data) => {
          if (hasItemsNotCreated(data)) {
            return showRetryModal(data, originalPayload);
          }
          this.setState({saveComplete: true, showLoader: false});
          this.state.doPrint ? this.printLabel(data) : this.redirect();
        };
        const messages = {failed: 'harvestPackages.create.fail', success: 'harvestPackages.create.success'};
        const url = `/api/harvests/${id}/packages`;
        // suppress warnings because we are handling them here.
        postData(url, payload, null, messages, null, onSuccess, {suppressWarnings: true})
          .then(clearLoader)
          .catch(clearLoader);
      });
    };

    const {waste, waste_uom, waste_other_material, waste_other_material_uom, waste_type} = payload;
    const packages = payload.packages.map((pkg) => {
      return {
        ...pkg,
        packaged_at: convertFormInputDateToDbDate(pkg.packaged_at, this.props.timezone), // TIMEZONE NOT REQUIRED
        package_created_at: formatDBDate(pkg.package_created_at), // TIMEZONE NOT REQUIRED
        package_expires_at: formatDBDate(pkg.package_expires_at), // TIMEZONE NOT REQUIRED
        finished_at: pkg.finished && pkg.finished_dirty ? moment().format('YYYY-MM-DD HH:mm:ss') : undefined,
        tag_requested: pkg.tag_requested === 1 ? 1 : 0,
        is_trade_sample: get(pkg, 'is_trade_sample') || 0
      };
    });

    const modality = get(payload, 'harvestBatch.modality', null);
    const cupo_id = get(payload, 'harvestBatch.cupo_id', null);
    const {
      actions: {postData},
      harvestBatch: {id},
      validateTrackingIdsAvailability
    } = this.props;

    const harvestBatch = {
      packages,
      waste,
      waste_uom,
      waste_other_material,
      waste_other_material_uom,
      waste_type,
      modality,
      cupo_id
    };

    return validateTrackingIdsAvailability(packages, setUnavailableTrackingIdFieldArrayErrors('packages'))
      .then(() => {
        submitHarvestBatch(harvestBatch, payload);
      })
      .catch((e) => {
        clearLoader();
        throw e;
      });
  }

  doPrint() {
    this.setState({ doPrint: true });
  }

  redirect() {
    this.props.actions.goBack();
  }

  saveAndPrint() {
    this.printLabel();
  }

  printLabel(data) {
    this.setState({ doPrint: false, package: data });
    const ids = data.map((item) => item.item_id);
    this.setState({ showPrinter: true, labelTag: 'inv_package_tag', labelIds: ids, redirect: false });
  }

  hidePrinter() {
    this.setState({ showPrinter: false });
  }

  renderLoader() {
    const { ready, showLoader } = this.state;

    return (
      <InProgressOverlay
        isActive={showLoader}
        message={ready ? 'harvestPackages.create.creatingLotsMessage' : 'general.loading'}
        translate={true}
      />
    );
  }

  render() {
    const { showPrinter, labelTag, labelIds } = this.state;
    const {
      cupos,
      users,
      totals,
      packages,
      locations,
      trackingIds,
      itemMasters,
      harvestBatch,
      initialValues,
      integrationState,
      testPackageStatus,
      harvestWasteTypes,
      harvestTypeOptions,
      materialTypeOptions,
      designatedInventoryAsTestPackageEnabled
    } = this.props;

    return (
      <FormWrapper
        className='create-harvest-packages-page'
        title={'harvestPackages.create.title'}
        goBack={this.redirect}
      >
        {this.renderLoader()}
        <CreateHarvestPackagesReduxForm
          users={users}
          totals={totals}
          doPrint={this.doPrint}
          packages={packages}
          onSubmit={this.onSubmit}
          locations={locations}
          itemMasters={itemMasters}
          trackingIds={trackingIds}
          totalQuality={Math.floor(harvestBatch.total_quality)}
          harvestBatch={harvestBatch}
          saveComplete={this.state.saveComplete}
          saveAndPrint={this.saveAndPrint}
          initialValues={initialValues}
          integrationState={integrationState}
          harvestWasteTypes={harvestWasteTypes}
          testPackageStatus={testPackageStatus}
          harvestTypeOptions={harvestTypeOptions}
          materialTypeOptions={materialTypeOptions}
          hasPackagesTags={this.props.hasPackagesTags}
          designatedInventoryAsTestPackageEnabled={designatedInventoryAsTestPackageEnabled}
          cupos={cupos}
        />
        <PrinterModal
          ref='printerModal'
          showPrinter={showPrinter}
          hidePrinter={this.hidePrinter}
          labelTag={labelTag}
          labelIds={labelIds}
          goToAfter={'/harvests'}
        />

        <ModalWrapper
          title='harvestPackages.create.warningTitle'
          size='small'
          headerClass='none'
          version={2}
          showModal={this.state.showRetryModal}
          onHide={() => this.setState({showRetryModal: false})}
          okayButton={{show: true, onClick: this.state.retryModalOnOkay}}
          cancelButton={{show: true}}
        >
          <div>
            {this.state.retryModalText}
          </div>
        </ModalWrapper>
      </FormWrapper>
    );
  }
}

CreateHarvestPackagesPage.propTypes = {
  actions: PropTypes.object.isRequired,
  harvests: PropTypes.array,
  harvestBatch: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  itemMasters: PropTypes.shape({
    regular: PropTypes.array.isRequired,
    test: PropTypes.array.isRequired,
    flower: PropTypes.array.isRequired,
    other_material: PropTypes.array.isRequired
  }).isRequired,
  trackingIds: PropTypes.array.isRequired,
  validateTrackingIdsAvailability: PropTypes.func.isRequired,
  integrationState: PropTypes.object.isRequired,
  timezone: PropTypes.string,
  initialValues: PropTypes.object.isRequired,
  packages: PropTypes.array.isRequired,
  totals: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  testPackageStatus: PropTypes.object,
  harvestWasteTypes: PropTypes.array,
  hasPackagesTags: PropTypes.bool,
  designatedInventoryAsTestPackageEnabled: PropTypes.bool,
  cupos: PropTypes.array
};

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {
    goBack,
    fetchMetrcTrackingIdsForSelectInput,
    validateCurrentOnHandWeight,
    fetchModalitiesByStrainId,
    change
  });

  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default reduxMetrcIdAvailability(
  connect(
    getCreateHarvestPackagesPageProps,
    mapDispatchToProps
  )(CreateHarvestPackagesPage)
);
