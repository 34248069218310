import React from 'react';
import PropTypes from 'prop-types';
import TextInput from './TextInput';

const StaticPlaceholderInput = (props) => {

  const {label, placeholder, name, onChange} = props;

  return (
    <div className='form-group'>
      <label style={{width: '100%', fontWeight: 'normal'}} className='text-muted'>{label !== undefined ? label : '&nbsp;'}</label>
      <TextInput meta={{}} id={name} input={{
        name,
        value: placeholder,
        disabled: true,
        onChange,
      }}/>
    </div>
  );

};

StaticPlaceholderInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string.isRequired
};

StaticPlaceholderInput.defaultProps = {
  name: 'static_placeholder',
  onChange: () => {}
};

export default StaticPlaceholderInput;
