/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {push} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';

import TablePageWrapper from '../common/grid/TablePageWrapper';
import InternationalDateStatic from '../common/form/InternationalDateStatic';
import InternationalDateTimeStatic from '../common/form/InternationalDateTimeStatic';
import InternationalCurrencyStatic from '../common/form/InternationalCurrencyStatic';
import PageTitle from '../common/PageTitle';
import {handleComplexSelectRow} from '../../actions/helpers/selectedDataHelper';
import {clearSelectedDataAction} from '../../actions/selectedDataActions';
import * as apiActions from '../../actions/apiActions';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import {setItem} from '../../actions/itemActions';
import {setData} from '../../actions/dataActions';
import {getSelectedCourierDeliveryOrdersId, mapCourierDeliveryOrders} from '../../selectors/deliveryOrdersSelectors';
import {getEnableManifestGeneration} from '../../selectors/forms/homeDeliverySetupFormSelectors';
import SingleActionColumn from '../common/grid/columns/SingleActionColumn';

export class CourierDeliveryOrderPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);

    this.generateManifest   = this.generateManifest.bind(this);
    this.setTab             = this.setTab.bind(this);
    this.getColumns         = this.getColumns.bind(this);
    this.handleSelect       = this.handleSelect.bind(this);
    this.viewManifest       = this.viewManifest.bind(this);
    this.cancelManifest     = this.cancelManifest.bind(this);
    this.modifyDeliveryOrder = this.modifyDeliveryOrder.bind(this);
    this.handleSelect       = this.handleSelect.bind(this);
  }

  componentWillMount() {
    this.props.actions.getItem('/api/distribution/settings', itemNames.distributionSettings, undefined, {
      ids: ['distributions_home_delivery']
    });

    this.props.actions.getUnpaginatedData(
      '/api/delivery_orders/courier',
      dataNames.courierDeliveryOrders,
      {failed: 'deliveryOrders.get.failure'},
      {
        delivery_status: this.props.params.status || 'pending',
      }
    );
  }

  componentDidMount() {
    this.handleSelect('clear');
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.params.status != this.props.params.status) {
      this.props.actions.getUnpaginatedData(
        '/api/delivery_orders/courier',
        dataNames.courierDeliveryOrders,
        {failed: 'deliveryOrders.get.failure'},
        {
          delivery_status: nextProps.params.status || 'pending',
        }
      );
    }
  }

  handleSelect(action, rows) {
    this.props.actions.handleComplexSelectRow(rows, dataNames.courierDeliveryOrders, action);
  }

  getColumns () {
    const {enableManifestGeneration} = this.props;

    const columns = [
      {
        name: 'deliveryOrders.table.completedAt',
        dataId:'completed_date',
        hidden: false,
        formatter: (cell, row) => <InternationalDateStatic>{cell}</InternationalDateStatic>
      },
      {
        name: 'deliveryOrders.table.quotedDelivery',
        dataId:'delivery_expected_at',
        hidden: false,
        formatter: (cell, row) => <InternationalDateTimeStatic displaySeconds={false} useSystemDate={false}>{cell}</InternationalDateTimeStatic>
      },
      {
        name: I18n.t('deliveryOrders.table.patientName'),
        dataId:'consumer_name',
        hidden: false,
      },
      {
        name: 'deliveryOrders.table.nbOfItems',
        dataId:'total_products',
        hidden: false,
      },
      {
        name: 'deliveryOrders.table.orderTotal',
        dataId:'order_total',
        hidden: false,
        formatter: (cell, row) => <InternationalCurrencyStatic>{cell}</InternationalCurrencyStatic>
      },
      {
        name: 'deliveryOrders.table.orderNumber',
        dataId:'name',
        hidden: false,
      },
      {
        name: 'deliveryOrders.table.orderSourceName',
        dataId:'source_partner_facility_name',
        hidden: false,
      },
    ];

    if (enableManifestGeneration) {
      columns.push({
        data: 'id',
        hidden: false,
        formatter: SingleActionColumn({
          label: 'deliveryOrders.actions.viewManifest',
          action: this.viewManifest,
        }),
        dataSort: false,
        columnClassName: 'action-column',
      });

      columns.push({
        data: 'id',
        hidden: false,
        formatter: (cell, row) => {
          if (get(row, 'delivery_status') !== 'completed') {
            return SingleActionColumn({
              label: 'deliveryOrders.actions.cancelManifest',
              action: this.cancelManifest,
            })();
          }
        },
        dataSort: false,
        columnClassName: 'action-column',
      });
    }

    columns.push({
      data: 'id',
      hidden: false,
      formatter: SingleActionColumn({
        label: 'deliveryOrders.actions.modify',
        action: this.modifyDeliveryOrder,
      }),
      dataSort: false,
      columnClassName: 'action-column',
    });

    return columns;
  }

  setTab(activeTab) {
    this.props.actions.push(`/courier/delivery-orders/${activeTab}`);
  }

  viewManifest() {
  }

  cancelManifest() {
  }

  modifyDeliveryOrder(event, deliveryOrder) {
    this.props.actions.push(`/courier/delivery-orders/modify/${deliveryOrder.id}`);
  }

  generateManifest() {
    this.props.actions.push('/courier/generate-manifest');
  }

  render () {
    const {deliveryOrders, selectedDeliveryOrdersId, params, enableManifestGeneration} = this.props;

    const actions = [];

    if (enableManifestGeneration) {
      actions.push({
        id: 'generateManifest',
        func: this.generateManifest,
        text: 'deliveryOrders.actions.generateManifest' ,
        requireSelect: true
      });
    }

    const tabs = [
      {
        id: 'pending',
        eventKey: 'pending',
        title: 'deliveryOrders.nav.pending',
        actions,
      },
      {
        id: 'out_for_delivery',
        eventKey: 'out_for_delivery',
        title: 'deliveryOrders.nav.outForDelivery',
        actions,
      },
      {
        id: 'completed',
        eventKey: 'completed',
        title: 'deliveryOrders.nav.completed',
        actions,
      }
    ];

    return (
      <div>
        <PageTitle primaryText={I18n.t('deliveryOrders.title')} />
        <TablePageWrapper
          ref={this.ref}
          settingKey='courier-delivery-orders' // This is enables the selection base on permissions
          columns = {this.getColumns()}
          data = {deliveryOrders}
          hideScanSearch={true}
          hideExport={true}
          activeTab = {params.status || 'pending'}
          tabs = {tabs}
          sort='completed_date desc'
          switchTab = {this.setTab}
          showSelectedCount={true}
          selectedRows = {selectedDeliveryOrdersId}
          handleSelect = {this.handleSelect}
          className = 'deliveryOrders-page'
          autoSuggestPlaceholder=''
        />
      </div>
    );
  }
}

CourierDeliveryOrderPage.propTypes = {
  deliveryOrders: PropTypes.array.isRequired,
  selectedDeliveryOrders: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  enableManifestGeneration: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps){
  const {selectedCourierDeliveryOrders} = state;

  return {
    selectedCourierDeliveryOrders,
    deliveryOrders: mapCourierDeliveryOrders(state),
    selectedDeliveryOrdersId: getSelectedCourierDeliveryOrdersId(state),
    enableManifestGeneration: getEnableManifestGeneration(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {setItem}, {setData}, {handleComplexSelectRow, clearSelectedDataAction}, {push});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (CourierDeliveryOrderPage);
