import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

const AssignedSettings = (props) => {

  const getSettingsByLockStatus = (settings, status) => {
    return settings.reduce((acc, setting) => {
      if(setting.is_locked === status) acc++;
      return acc;
    }, 0);
  };

  const groupIncludesCurrentFacility = () => {
    return props.group.members.find((member) => member.id === props.facility.id);
  };

  //@TODO: Move this to a selector once we know we have the behavior dialed in
  const getCountGroupMembersWithTheseSettingsInOtherGroups = () => {
    return props.group.members.reduce((acc, member) => {
      const memberIsInOtherGroupWithSettings = props.facilityGroups.filter((group) => {
        return group.controlledSettings.length > 0 && group.id !== props.group.id;
      }).reduce((acc, group) => {
        if(acc) return acc;
        if(group.members.find((m) => m.id === member.id)) acc = true;
        return acc;
      }, false);
      if(memberIsInOtherGroupWithSettings) acc++;
      return acc;
    }, 0);
  };

  const isBindable = () => {
    if(props.group.controlsSettings) return true; // Deselect
    if(props.facilityGroups.find((group) => group.controlsSettings) && (groupIncludesCurrentFacility() || getCountGroupMembersWithTheseSettingsInOtherGroups() > 0)) return false; // already set on another for these settings
    return true;
  };

  const onBindFacilityGroup = () => {
    if(!isBindable()) return false;
    props.onBindFacilityGroup(props.group);
  };

  return (
      <td
        style={isBindable() ? {cursor: 'pointer', color: '#337ab7'} : {}}
        onClick={() => {
          onBindFacilityGroup();
        }}
      >
        {
          props.group.controlledSettings.length === 0
          ? isBindable() ? 'Click To Assign These Settings To This Group' : 'Not Available To This Group (A member of this group is already in another group with these settings.)'
            : (<div>
             Assigned
            {getSettingsByLockStatus(props.group.controlledSettings, 1)} Settings (Click To Remove)
          </div>)
        }
      </td>
  );

};

AssignedSettings.propTypes = {
  facilityGroups: PropTypes.array,
  group: PropTypes.object,
  onBindFacilityGroup: PropTypes.func,
  facility: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    facility: state.facility
  };
}

export default connect(mapStateToProps)(AssignedSettings);
