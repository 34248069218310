import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import isEmpty from 'lodash.isempty';
import { I18n } from 'react-redux-i18n';
import { NavDropdown, Dropdown } from 'react-bootstrap';
import {FaMapMarkerAlt} from 'react-icons/fa';
import * as facilityActions from '../../actions/facilityActions';
import FacilityMenuItem from './FacilityMenuItem';
import { getFacilitiesForDropdown } from '../../selectors/facilitiesSelectors';

class FacilityMenu extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = { loading: false };
    this.selectFacility = this.selectFacility.bind(this);
  }

  selectFacility(facility) {
    const { setFacility } = this.props.actions;

    // If this is a Utah facility and we are not currently on a leafdatasystem url, we have to redirect to login page
    if (
      !window.location.hostname.includes('leafdatasystems') && this.props.ssoEnabled &&
      get(facility, 'configuration_packs', []).some((configPack) => configPack.includes('utah'))
    ) {
      window.location.replace(`${this.props.utahUrl}/facilities`);
      return;
    } else if (
      window.location.hostname.includes('leafdatasystems') && this.props.ssoEnabled &&
      !get(facility, 'configuration_packs', []).some((configPack) => configPack.includes('utah'))
    ) {
      window.location.replace(`${this.props.platformUrl}/facilities`);
      return;
    }
    this.setState({ loading: true });
    setFacility(facility)
      .then(() => this.setState({ loading: false }))
      .catch(() => this.setState({ loading: false }));
  }

  render() {
    const { loading } = this.state;
    const { facility, facilities } = this.props;
    const facilityName = isEmpty(facility) ? I18n.t('facilities.choose') : facility.name;
    const facilityToggle = (
      <div className='facility-menu-toggle'>
        <span className='hidden-xs'>{facilityName}</span>
        <FaMapMarkerAlt className='map-marker-glyphicon' />
      </div>
    );
    return (
      <NavDropdown id='facilityMenuDropDown' className='facility-menu' title={facilityToggle}>
        <div className='facility-menu-dropdown'>
          {' '}
          {facilities.map((iteratedFacility, index) => (
            <div key={index}>
              <Dropdown.Item
                onSelect={() => this.selectFacility(iteratedFacility.facility)}
                disabled={loading}>
                <FacilityMenuItem
                  facilityName={iteratedFacility.name}
                  facilityAddress={iteratedFacility.facilityAddress}
                  isChecked={iteratedFacility.isChecked}
                />
              </Dropdown.Item>
              <hr className='facility-menu-divider' />
            </div>
          ))}
        </div>
      </NavDropdown>
    );
  }
}

FacilityMenu.propTypes = {
  actions: PropTypes.object.isRequired,
  facility: PropTypes.object,
  facilities: PropTypes.array.isRequired,
  utahUrl: PropTypes.string,
  platformUrl: PropTypes.string,
  ssoEnabled: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    facility: state.facility,
    facilities: getFacilitiesForDropdown(state),
    utahUrl: window.location.hostname.includes('pre') ? state.env.utahUrlPrerelease : state.env.utahUrl,
    platformUrl: state.env.platformUrl,
    ssoEnabled: state.env.ssoEnabled,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(facilityActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FacilityMenu);
