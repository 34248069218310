import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import PricePreviewSection from './PricePreviewSection';

const PricePreviewWrapper = (props) => {
  const {data, title, levelTitle} = props;
  return (
    <Row>
      <Col md={12} className='price-section'>
        <Row>
          <Col md={12}>
            <h4>{I18n.t(title)} {I18n.t(levelTitle)}</h4>
          </Col>
        </Row>
        {Array.isArray(data) ? data.map((sectionData, index) =>
          <PricePreviewSection key={index} sectionData={sectionData}/>
        ) : <PricePreviewSection  sectionData={data}/>}
      </Col>
    </Row>
  );
};

PricePreviewWrapper.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  levelTitle: PropTypes.string.isRequired
};

export default PricePreviewWrapper;