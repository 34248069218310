import {Button, ButtonToolbar, Col, Row} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import React from 'react';


const SubmitSection = () => {
  return (
    <Row className='submit-section'>
      <Col xs={12}>
        <div className='button-wrapper'>
          <ButtonToolbar className='float-right'>
            <Button variant='primary' type='submit'>
              {I18n.t('common.save')}
            </Button>
          </ButtonToolbar>
        </div>
      </Col>
    </Row>
  );
};

export default SubmitSection;
