import {getFormValues} from 'redux-form';
import get from 'lodash.get';
import moment from 'moment';
import {
  getEditInventoryReceiptInitialValues,
  getGroupedItemMasterChildren,
  getItemMasterIds,
  getItemMasters, getLineItemPrices, getLineItemQuantities, getPONumber, getPurchaseOrderForInventoryReceipt,
  getPurchaseOrderItemIds,
  getReceiveInventoryReceiptInitialValues, getSummaryTotals, isAllowInitialInventory,
  isEditMode, getTranactions, getReassignMode
} from '../../../../selectors/fillPurchaseOrderSelectors';
import { isLeafReturn } from '../../../../selectors/purchaseOrdersSelectors';
import { getFlattenedLocations, getLocationsForSharedProducts } from '../../../../selectors/locationsSelectors';
import { getDriversForDropdown } from '../../../../selectors/driversSelectors';
import { getVehiclesForDropdown } from '../../../../selectors/vehiclesSelectors';
import {
  getIntegrationState,
  isIncomingTransferMappingRequired
} from '../../../../selectors/integration/integrationSelectors';
import { getBiotrackInvTypesForIRForm, getBiotrackSettings } from '../../../../selectors/integration/biotrackSelectors';
import { userHasPermission } from '../../../../selectors/usersSelectors';
import * as p from '../../../../constants/permissions';
import { isMetrcTransfersEnabled } from '../../../../selectors/integration/metrcSelectors';
import { getSourceDisplaySetting } from '../../../../selectors/settingsSelectors';
import {
  getInternationalDateFormat,
  getInternationalNumberFormat
} from '../../../../selectors/InternationalOperationsSelectors';
import {isFeatureEnabled} from '../../../../selectors/featureToggles';
import {isLeafPaConfigPackClosedLoopFacility} from '../../../../selectors/facilitiesSelectors';
import {INVENTORY_RECEIPT_FORM} from '../../../../constants/forms';


export const mapStateToPropsHelper = (state, ownProps, selector) => { //eslint-disable-line
  const {selectedPurchaseOrders, timezone, uoms, pricingGroups, pricingWeights} = state;
  const editMode = isEditMode(state, ownProps);
  const status = get(ownProps, 'location.query.status');
  const initialValues = editMode ? getEditInventoryReceiptInitialValues(state, ownProps) : getReceiveInventoryReceiptInitialValues(state, ownProps);

  if (status && status === 'completed') {
    initialValues.status = status;
  }
  const transactions = getTranactions(state);
  const transactionsArray = transactions[0];

  return {
    selectedPurchaseOrders,
    getValue: (...values) => selector(state, ...values),
    editMode,  // as opposed to create mode
    initialValues,
    itemMasterChildren: getGroupedItemMasterChildren(state, ownProps),
    storageLocations: getFlattenedLocations(state, ownProps),
    sharedLocations: getLocationsForSharedProducts(state),
    drivers: getDriversForDropdown(state, ownProps),
    isApplyStorageLocation: selector(state, 'isApplyStorageLocation'),
    vehicles: getVehiclesForDropdown(state, ownProps),
    itemMasters: getItemMasters(state, ownProps),
    orderItemIds: getPurchaseOrderItemIds(state, ownProps),
    itemMasterIds: getItemMasterIds(state, ownProps),
    purchaseOrder: getPurchaseOrderForInventoryReceipt(state, ownProps),
    lineItemPrices: getLineItemPrices({lines: selector(state, 'lines')}),
    lineItemQuantities: getLineItemQuantities({
      ...state,
      lines: selector(state, 'lines'),
    }),
    summaryTotals: getSummaryTotals({
      lines: selector(state, 'lines'),
      partner_discount: selector(state, 'partner_discount'),
      excise_tax_due: selector(state, 'excise_tax_due'),
      transfer_fee: selector(state, 'transfer_fee')
    }),
    integrationState: getIntegrationState(state),
    biotrackSettings: getBiotrackSettings(state),
    trackingIds: state.trackingIds,
    supplyChainMapping: state.supplyChainMapping,
    timezone,
    uoms,
    pricingGroups,
    pricingWeights,
    hasPrintPermission: userHasPermission(state, {permissions: [p.print_labels]}),
    isReturn: isLeafReturn(state),
    inventoryTypes: getBiotrackInvTypesForIRForm(state),
    purchaseOrderId: parseInt(ownProps.location.query.po_num),
    isInitialMode: isAllowInitialInventory(state),
    forceMedicatedMapping: isIncomingTransferMappingRequired(state),
    isMetrcTransfersEnabled: isMetrcTransfersEnabled(state),
    purchaseOrderNumber: getPONumber(state),
    displaySourceFields: getSourceDisplaySetting(state),
    internationalNumberFormat: getInternationalNumberFormat(state),
    internationalDateFormat: getInternationalDateFormat(state),
    formFields: {
      status: selector(state, 'status'),
    },
    isLeafPaConfigPackClosedLoopFacility: isFeatureEnabled(state)('feature_leaf_pa_configuration_pack') && isLeafPaConfigPackClosedLoopFacility(state),
    formValues: getFormValues(INVENTORY_RECEIPT_FORM)(state),
    created: initialValues.canReReceive && transactionsArray ? transactionsArray.created : null,
    isReassignMode: get(getReassignMode(state), 'enable', false),
    isFeatureEnabledReReceiveTransfer: isFeatureEnabled(state)('feature_rereceive_transfer')
  };
};

export const handleInventoryReceipt = (component, inventoryReceipt) => {
  if(!inventoryReceipt){
    return false;
  }

  inventoryReceipt.date_received = (inventoryReceipt.date_received !== '0000-00-00 00:00:00'
    ? moment(inventoryReceipt.date_received)
    : '');

  component.fetchPurchaseOrder(inventoryReceipt.source_id);
  if(['completed', 'cancelled'].includes(inventoryReceipt.status)) {
    component.setState({
      locked: true,
      saved: true,
      canPrintLabels: component.props.initialValues.status === 'completed',
      inventoryReceipt
    });
  }
  return true;
};

export const handleCanPrintLabels = (component, nextProps) => {
  const canPrintLabels = get(nextProps, 'formFields.status', 'open') === 'completed';
  if(component.state.canPrintLabels !== canPrintLabels){
    component.setState({canPrintLabels});
  }
};
