import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Table, Card} from 'react-bootstrap';
import ContentConcealer from '../../../common/concealers/ContentConcealer';

const QuickMoveOldItemDetails = ({delta, model}) => {
  const {transaction: {new_item}} = model;
  const qty = delta.find(item => item.name === 'qty');
  const oldValue = qty && parseFloat(qty.prev);
  const newValue = qty && parseFloat(qty.current);
  const newQty = oldValue && (oldValue - newValue).toFixed(2);

  return (
    <Card>
      <h3>{I18n.t('itemTransactions.quickMoveDetails')}</h3>
      <Table striped className='item-details'>
        <thead>
        <tr>
          <th>{I18n.t('itemTransactions.field')}</th>
          <th>{I18n.t('itemTransactions.valueAdjustment')}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>{I18n.t('itemTransactions.item.newLocation')}</td>
          <td>{new_item.location_name}</td>
        </tr>
        <ContentConcealer show={Boolean(oldValue)}>
          <tr>
            <td>{I18n.t('itemTransactions.item.newQuantity')}</td>
            <td>{newQty} {new_item.uom}</td>
          </tr>
        </ContentConcealer>
        {delta.map((field) =>
          <tr key={field.name}>
            <td>{I18n.t(`itemTransactions.item.${field.name}`)}</td>
            <td>{field.prev} -> {field.current}</td>
          </tr>
        )}
        </tbody>
      </Table>
    </Card>
  );
};

QuickMoveOldItemDetails.propTypes = {
  delta: PropTypes.array.isRequired,
  model: PropTypes.object.isRequired,
};

export default QuickMoveOldItemDetails;
