import {createSelector} from 'reselect';
import moment from 'moment';
import {getClosedLoopFacility} from '../closedLoopsSelectors';

export const getInitialValues = createSelector(
  [getClosedLoopFacility],
  (facility) => {

    const {id, allow_initial_inventory, initial_inventory_by_date} = facility;

    return {
      facility_id: id,
      allow_initial_inventory: allow_initial_inventory
        ? moment() <= moment(initial_inventory_by_date)
        : false,
      initial_inventory_by_date: null,
      current_initial_inventory_by_date: initial_inventory_by_date
        ? moment(initial_inventory_by_date).format( 'M/D/Y')
        : null
    };
  }
);

export default getInitialValues;
