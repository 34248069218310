/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import union from 'lodash.union';
import map from 'lodash.map';
import difference from 'lodash.difference';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import { Button } from 'react-bootstrap';
import { FaExclamationCircle } from 'react-icons/fa';

import * as apiActions from '../../actions/apiActions';
import * as dataNames from '../../constants/dataNames';
import {getNotifications} from '../../selectors/notificationsSelectors';
import ModalWrapper from '../common/ModalWrapper';
import NotificationDetails from './NotificationDetails';
import TablePageWrapper from '../common/grid/TablePageWrapper';
import {useLeafDataSystemsBranding} from '../../selectors/integrationSelectors';
import InternationalDateTimeStatic from '../common/form/InternationalDateTimeStatic';

export class NotificationPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.getRowClass = this.getRowClass.bind(this);
    this.viewNotification = this.viewNotification.bind(this);
    this.updateNotifications = this.updateNotifications.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.markAsRead = this.markAsRead.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.markAsUnread = this.markAsUnread.bind(this);
    this.markAllAsRead = this.markAllAsRead.bind(this);
    this.markAllAsUnread = this.markAllAsUnread.bind(this);

    this.state = {
      showModal: false,
      notification: {
        subject: '',
        body: '',
        starts_at: '',
        expires_at: ''
      },
      recentlyReadIds: [],
      selectedNotifications: [],
    };

    this.columns = [
      {
        name: I18n.t('notifications.table.priority'),
        formatter: (cell) => cell === 'priority' ? <FaExclamationCircle /> : '',
        csvFormatter: (cell) => cell === 'priority' ? '!' : '',
        dataId: 'alert_type',
        hidden: false,
        width: '100px'
      },
      {
        name: I18n.t('notifications.table.sent'),
        dataId: 'starts_at',
        hidden: false,
        /* eslint-disable react/no-multi-comp */
        formatter: (cell) => <InternationalDateTimeStatic adjustForClientTimezone={false}>{cell}</InternationalDateTimeStatic>,
        /* eslint-disable react/no-multi-comp */
        csvFormatter: (cell) => <InternationalDateTimeStatic adjustForClientTimezone={false}>{cell}</InternationalDateTimeStatic>,
        width: '200px'
      },
      {
        name: I18n.t('notifications.table.subject'),
        dataId: 'subject',
        hidden: false,
      },
      {
        name: I18n.t('notifications.table.body'),
        dataId: 'body',
        hidden: false,
      },
      {
        name: '',
        dataId: '',
        hidden: false,
        dataSort: false,
        width: '70px',
        csvFormatter: () => '',
        formatter: (cell, row) => { // eslint-disable-line
          return (
            <Button variant='primary' bsSize='xsmall' onClick={event => {
              event.stopPropagation();
              this.viewNotification(row);
            }}>
              {I18n.t('notifications.table.view')}
            </Button>
          );
        }
      }
    ];
  }

  componentWillMount() {
    const {user, actions: {getUnpaginatedData}} = this.props;
    getUnpaginatedData(
      `/api/notifications/active/user/${user.id}`, dataNames.notifications, {failed: 'notifications.get.failed'}
    );
  }

  handleSelect(flag, rows) {
    const { selectedNotifications } = this.state;
    const ids = map(rows, 'id');

    switch (flag) {
    case 'add':
      this.setState({ selectedNotifications: ids });
      break;
    case 'remove':
      this.setState({ selectedNotifications: difference(selectedNotifications, ids) });
      break;
    case 'clear':
      this.setState({ selectedNotifications: [] });
      break;
    }
  }

  onSelect(isSelected, row) {
    if(!row) {
      return this.setState({selectedNotifications: []});
    }

    let selectedNotifications = [].concat(this.state.selectedNotifications);

    if (~selectedNotifications.indexOf(row.id)) {
      selectedNotifications = selectedNotifications.filter(notifyId => notifyId !== row.id);
    } else {
      selectedNotifications.push(row.id);
    }

    this.setState({selectedNotifications});
  }

  updateNotifications(ids, maskAsRead) {
    if(ids && ids.length) {
      const notificationIds = ids.map(id => this.props.data.find(notification => notification.id === id).alert_id);
      const {actions: {postData, getUnpaginatedData}, user} = this.props;
      const act = maskAsRead ? 'mark_viewed' : 'mark_not_viewed';
      postData(
        `/api/notifications/${act}`,
        {ids: notificationIds},
        null,
        {failed: 'notifications.markViewed.failed'},
        undefined,
        () => {
          getUnpaginatedData(
            `/api/notifications/active/user/${user.id}`, dataNames.notifications, {failed: 'notifications.get.failed'}
          );
          this.setState({
            selectedNotifications: [],
            recentlyReadIds: maskAsRead ? union(this.state.recentlyReadIds, ids) : difference(this.state.recentlyReadIds, ids),
          });
        }
      );
    }
  }

  updateAllNotifications(maskAsRead) {
    const {actions: {postData}} = this.props;
    const act = maskAsRead ? 'mark_viewed_all' : 'mark_not_viewed_all';
    postData(
      `/api/notifications/${act}`,
      undefined,
      dataNames.notifications,
      {failed: 'notifications.markViewed.failed'},
      undefined,
      () => {
        location.reload();
      }
    );
  }

  getRowClass(row) {
    return row.viewed_timestamp || this.state.recentlyReadIds.find(id => id === row.id) ? 'read' : 'unread';
  }

  viewNotification(row) {
    this.setState({
      showModal: true,
      notification: row,
      recentlyReadIds: union(this.state.recentlyReadIds, [row.id]),
    });
  }

  hideModal() {
    this.setState({showModal: false});
  }

  markAsRead() {
    this.updateNotifications(this.state.selectedNotifications, true);
  }

  markAsUnread() {
    this.updateNotifications(this.state.selectedNotifications, false);
  }

  markAllAsRead() {
    this.updateAllNotifications(true);
  }

  markAllAsUnread() {
    this.updateAllNotifications(false);
  }

  render() {
    const {data, leafDataSystemsBranding} = this.props;
    const {showModal, notification} = this.state;

    const isButtonAvailable = (checkFn)  => {
      return this.props.data.filter((i) => ~this.state.selectedNotifications.indexOf(i.id)).every(checkFn);
    };

    const actions = [
      { id: 'markAsRead', func: this.markAsRead, text: 'notifications.markAsRead', requireSelect: true, disabled: isButtonAvailable((i) => !!i.viewed_timestamp) },
      { id: 'markAsUnread', func: this.markAsUnread, text: 'notifications.markAsUnread', requireSelect: true, disabled: isButtonAvailable((i) => !i.viewed_timestamp) },
      { id: 'markAllAsRead', func: this.markAllAsRead, text: 'notifications.markAllAsRead', requireSelect: false },
      { id: 'markAllAsUnread', func: this.markAllAsUnread, text: 'notifications.markAllAsUnread', requireSelect: false },
    ];

    const vendor = leafDataSystemsBranding ? 'Leaf Data Systems' : 'MJ Freeway';

    return (
      <div className='notification-page'>
        <h1>{I18n.t('notifications.title')}</h1>
        <p>{I18n.t('notifications.archiveMessage', {vendor})}</p>
        <ModalWrapper
          Component={NotificationDetails}
          onHide={this.hideModal}
          showModal={showModal}
          title={notification.subject}
          notification={notification}
        />
        <TablePageWrapper
          ref={this.ref}
          settingKey='notifications'
          columns={this.columns}
          data={data}
          actions={actions}
          className='notifications-table'
          hideSearch={true}
          handleSelect={this.handleSelect}
          selectedRows={this.state.selectedNotifications}
          showSelectedCount={true}
          bstProps={{
            trClassName: this.getRowClass,
            selectRow: {
              onSelect: (row, isSelected) => this.onSelect(isSelected, row)
            },
          }}
        />
      </div>
    );
  }
}

NotificationPage.propTypes = {
  data: PropTypes.array.isRequired,
  leafDataSystemsBranding: PropTypes.bool,
  actions: PropTypes.shape({
    getUnpaginatedData: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
};

function mapStateToProps(state) {
  return {
    leafDataSystemsBranding: useLeafDataSystemsBranding(state),
    data: getNotifications(state),
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...apiActions,
  };
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPage);
