import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import {connect} from 'react-redux';

import * as facilityTypes from '../../../constants/facilityTypes';
import timezoneOptions from '../../../constants/timeZoneOptions';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import TextInput from '../../common/form/TextInput';
import CountryField from '../../common/form/redux-form/CountryField';
import ContentConcealer from '../../common/concealers/ContentConcealer';
import InternationalReduxPhoneInput from '../../common/form/redux-form/InternationalReduxPhoneInput';
import {getPostalCodeLabel} from '../../common/form/validatePostalCode';
import {getIntegrationState} from '../../../selectors/integration/integrationSelectors';


const CreateFacilityMainInfo = (props) => {
  const {
    change,
    isOhMetrc,
    integrationState,
    facilityId,
    optionTypes,
    facilityPath,
    initialCountry,
    isRetailFacility,
    retailFacilityTypes,
    facilitiesWithWaLeaf,
  } = props;

  const {isPaLeaf} = integrationState;

  const cityLabelOverrides = {
    AU: I18n.t('common.form.suburb'),
    ES: I18n.t('common.form.town'),
  };

  return (
    <div className='form-panel facility-main-info col-md-12'>
      {isRetailFacility && (
        <Row>
          <Col md={4}>
            <Field name={`${facilityPath}.retail_type`} component={ReactSelectInput} props={{
              label: I18n.t('partners.createPartner.retailFacilityType'),
              options: retailFacilityTypes,
              isRequired: true,
              labelKey: 'title',
              valueKey: 'code'
            }}/>
          </Col>
        </Row>
      )}

      <Row>
        <Col md={6}>
          <Field name={`${facilityPath}.name`} component={TextInput} props={{
            label: I18n.t('partners.createPartner.facilityName'),
            placeholder: I18n.t('partners.createPartner.facilityName'),
            isRequired: true
          }}/>
        </Col>
        <Col md={6}>
          <Field name={`${facilityPath}.dba`} component={TextInput} props={{
            label: I18n.t('partners.createPartner.legalBusinessEntity'),
            placeholder: I18n.t('partners.createPartner.entityName'),
            required: true
          }}/>
        </Col>
        {
          isPaLeaf ?
            <Col md={6}>
              <Field name={`${facilityPath}.program_defined_name`} component={TextInput} props={{
                label: I18n.t('partners.createPartner.programDefinedName'),
                placeholder: I18n.t('partners.createPartner.programDefinedName'),
                isRequired: isPaLeaf,
                disabled: true
              }}/>
            </Col>
            : null
        }
        <Col
        md={3}>
          <Field name={`${facilityPath}.type`} component={ReactSelectInput} props={{
            label: I18n.t('common.form.type'),
            options: optionTypes,
            required: true,
            disabled: true
          }}/>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <InternationalReduxPhoneInput
            name={`${facilityPath}.phone_number`}
            countryCode={initialCountry}
            props={{
              label: I18n.t('partners.createPartner.phone'),
              isRequired: isRetailFacility && isOhMetrc,
            }}
          />
        </Col>
        <Col md={4}>
          <Field
            name={`${facilityPath}.street_address_1`}
            component={TextInput}
            props={{
              label: I18n.t('common.form.address'),
              placeholder: I18n.t('common.form.address'),
              isRequired: isOhMetrc,
            }}
          />
        </Col>
        <Col md={4}>
          <Field
            name={`${facilityPath}.street_address_2`}
            component={TextInput}
            props={{
              label: I18n.t('common.form.address2'),
              placeholder: I18n.t('common.form.address2'),
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <Field
            name={`${facilityPath}.city`}
            component={TextInput}
            props={{
              label: get(cityLabelOverrides, initialCountry,  I18n.t('common.form.city')),
              placeholder: get(cityLabelOverrides, initialCountry,  I18n.t('common.form.city')),
              isRequired: isOhMetrc,
            }}
          />
        </Col>
        <CountryField
          name={`${facilityPath}.country_code`}
          stateFieldName={`${facilityPath}.province_code`}
          countryFieldSize={2}
          stateFieldSize={2}
          change={change}
          isRequired={isOhMetrc}
          currentCountry={initialCountry}
          stateProps={{isRequired: true}}
        />
        <Col md={2}>
          <Field
            name={`${facilityPath}.postal_code`}
            component={TextInput}
            props={{
              label: I18n.t(getPostalCodeLabel(initialCountry)),
              isRequired: isOhMetrc,
            }}
          />
        </Col>
        <Col md={3}>
          <Field name={`${facilityPath}.timezone`} component={ReactSelectInput} props={{
            label: I18n.t('common.form.timezone'),
            options: timezoneOptions,
            placeholder: I18n.t('customers.create.select'),
            isRequired: true,
          }}/>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <ContentConcealer show={facilitiesWithWaLeaf && facilitiesWithWaLeaf.indexOf(facilityId) !== -1}>
            <Field name={`${facilityPath}.leaf_organization_id`} component={TextInput} props={{
              label: I18n.t('partners.createPartner.leafOrganizationID'),
              placeholder: I18n.t('partners.createPartner.leafOrganizationID')
            }}/>
          </ContentConcealer>
        </Col>
      </Row>
    </div>
  );
};

CreateFacilityMainInfo.defaultProps = {
  change: () => null,
};

CreateFacilityMainInfo.propTypes = {
  touch: PropTypes.func,
  isOhMetrc: PropTypes.bool,
  integrationState: PropTypes.object.isRequired,
  facilityPath: PropTypes.string.isRequired,
  retailFacilityTypes: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  optionTypes: PropTypes.array,
  initialCountry: PropTypes.string,
  isRetailFacility: PropTypes.bool,
  facilitiesWithWaLeaf: PropTypes.array.isRequired,
  facilityId: PropTypes.number.isRequired
};

export default connect(
  (state, ownProps) => {
    const facilityState = ownProps.formSelector(`${ownProps.facilityPath}.province_code`);
    const integrationState = getIntegrationState(state);
    const isOhMetrc = (integrationState.isOhMetrc && facilityState === 'OH');

    return {
      isOhMetrc,
      integrationState,
      facilityState: ownProps.formSelector(`${ownProps.facilityPath}.province_code`),
      isRetailFacility: (facilityTypes.dispensary === ownProps.type),
      optionTypes: [{
        text: 'Cultivator',
        value: facilityTypes.grow,
      }, {
        text: 'Extraction & Infusion',
        value: facilityTypes.manufacturing,
      }, {
        text: 'Retailer',
        value: facilityTypes.dispensary,
      }, {
        text: 'Lab',
        value: facilityTypes.lab
      }],
    };
  }
)(CreateFacilityMainInfo);
