/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {goBack} from 'react-router-redux';
import {change} from 'redux-form';
import * as apiActions from '../../../actions/apiActions';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import FormWrapper from '../../common/form/FormWrapper';
import ReorderForm from './ReorderForm';

const FORM_NAME = 'tax-reorder-form';

export class ReorderTaxProfilesPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  componentWillMount(){
    this.props.actions.getUnpaginatedData('/api/tax_profiles?active=1', dataNames.taxProfiles, {failed: 'taxProfiles.get.failed'});
    this.props.actions.getUnpaginatedData('/api/facilities', dataNames.facilities);
  }

  redirect() {
    this.props.actions.goBack();
  }

  onSubmit({tax_profiles}) {
    const promises = tax_profiles.map(profile => {
      const orginalProfile = this.props.taxProfiles.find(p => p.id === profile.id);
      if(orginalProfile.weight !== profile.weight){
        return this.props.actions.putItem(`/api/tax_profiles/${profile.id}`, {weight: profile.weight});
      }
      return Promise.resolve();
    });
    Promise.all(promises).then(() => this.props.actions.goBack());

  }

  render() {
    const {facilities, taxProfiles, facility} = this.props;
    return(
      <FormWrapper title={'taxProfiles.actions.reorderTaxes'} goBack={this.redirect}>
        <ReorderForm
          form={FORM_NAME}
          facilities={facilities}
          initialValues={{tax_profiles: taxProfiles}}
          facility={facility}
          onSubmit={this.onSubmit}/>
     </FormWrapper>
    );
  }
}

ReorderTaxProfilesPage.propTypes = {
  actions: PropTypes.shape({
    getUnpaginatedData: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired
  }),

  facilities: PropTypes.array.isRequired,
  taxProfiles: PropTypes.array.isRequired,
  facility: PropTypes.object,
};

// const selector = formValueSelector('tax');

function mapStateToProps(state) {

  const {taxProfiles, facilities} = state;
  return {
    facilities,
    taxProfiles: taxProfiles || [],
    facility: state[itemNames.facility],
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({},apiActions, {goBack, change}
  );
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (ReorderTaxProfilesPage);
