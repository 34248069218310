import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { Col, Row } from 'react-bootstrap';
import { Field } from 'redux-form';

import SelectInput from '../common/form/SelectInput';
import MultiselectInput from '../common/form/MultiselectInput';

const budtenderKnowledge = [
  {text: '1', value: 1},
  {text: '2', value: 2},
  {text: '3', value: 3},
  {text: '4', value: 4},
  {text: '5', value: 5},
  {text: '6', value: 6},
  {text: '7', value: 7},
  {text: '8', value: 8},
  {text: '9', value: 9},
  {text: '10', value: 10},
];

class UserFacilityRoles extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    //const {fields: {map}, userRoles, facilities, integrationState: {isPaLeaf}, facilityRoles, isAffiliatedOnlyUser} = this.props;
    const {fields: {map}, userRoles, facilities, facilityRoles, isAffiliatedOnlyUser} = this.props;
    const extendedRoles = facilities.map(facility => ({
      facility_id: facility.id,
      facility: facility,
      role_ids: facility.roles ? facility.roles : [],
      leaf_access_level: facilityRoles.reduce((acc, value) => {
        if (facility.id === value.facility_id) acc = value.leaf_access_level;
        return acc;
      }, '')
    }));

    return (
      <Row>
        {map((name, index) => {
          const facilityRole = extendedRoles[index];
          const selectedRoles = facilityRole ? userRoles.filter(userRole => facilityRole.role_ids.indexOf(userRole.id) >= 0) : [];
          const displayExtraFields = !!selectedRoles.find(role => role.id === 18); // TODO: Add some kind of isBudtender flag to db

          const roles = userRoles.map((role) => {
            return {text: role.role_name, value: role.id, active: role.active};
          }).sort((a, b) => a.text.localeCompare(b.text));

          // Show "lab" roles only for Lab facilities
          const roles_filtered = roles.filter( role => {
            if (role.text && role.text.match(/^Lab:/) && facilityRole && facilityRole.facility.type !== 'lab') return false;
            return true;
          });

          //const labelLeafAccessPostfix = isPaLeaf ? `: ${facilityRole.leaf_access_level}` : '';
          const facilityName = facilityRole ? facilityRole.facility.name : `Facility ${index + 1}`;
          const label = `${facilityName}`; //${labelLeafAccessPostfix}`;
          return (
            <Col xs={12} key={index}>
              <Field name={`${name}.role_ids`} component={MultiselectInput}
                     props={{
                       label,
                       options: roles_filtered,
                       isRequired: this.props.isRequired && !isAffiliatedOnlyUser,
                     }}/>
              {displayExtraFields &&
              <div className='budtender-knowledge'>
                <Field name={`${name}.budtender_knowledge`} component={SelectInput}
                       props={{
                         label: I18n.t('userAccounts.budtenderknowledge:'),
                         options: budtenderKnowledge
                       }}
                />
              </div>
              }
            </Col>
          );
        })}

      </Row>
    );
  }
}

UserFacilityRoles.propTypes = {
  fields: PropTypes.shape({
    push: PropTypes.func.isRequired,
    map: PropTypes.func.isRequired
  }),
  userRoles: PropTypes.array.isRequired,
  facilities: PropTypes.array,
  isRequired: PropTypes.bool.isRequired,
  integrationState: PropTypes.object.isRequired
};

export default UserFacilityRoles;
