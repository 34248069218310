import React from 'react';
import {Field} from 'redux-form';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import AddPhysicianButton from '../../physicians/create/AddPhysicianButton';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import TextInput from '../../common/form/TextInput';
import DatePickerInput from '../../common/form/TempDatePickerInput';


class PhysicianSection extends React.Component {
  constructor(props) {
    super(props);

    this.renderPhysicianDropDown = this.renderPhysicianDropDown.bind(this);
  }

  renderPhysicianDropDown() {
    const { patientSettings, physicians, hasTempId, integrationState } = this.props;
    const { isOhMetrc } = integrationState;

    return (
      <Field name='customer.physician_id' component={ReactSelectInput} props={{
        label: I18n.t('customers.create.physicianName'),
        placeholder: I18n.t('customers.placeholders.physicianName'),
        isRequired: Boolean(patientSettings && patientSettings.doctorRequiredForTempPaperwork && hasTempId) || isOhMetrc,
        options: physicians,
        textKey: 'display_name',
        valueKey: 'id',
      }} />
    );
  }

  render() {
    const { isOhMetrc } = this.props.integrationState;

    return (
      <div>
        <Row>
          <Col md={3}>
            <AddPhysicianButton/>
            {this.renderPhysicianDropDown()}
          </Col>
          <Col md={3}>
            <Field name='customer.physician.display_license' component={TextInput} props={{
              label: isOhMetrc ? I18n.t('customers.create.physicianStateLicense') : I18n.t('customers.create.physicianLicense'),
              disabled: true,
            }} />
          </Col>
          {isOhMetrc && (
            <Col md={3}>
              <Field name='customer.physician.display_dea_number' component={TextInput} props={{
                label: I18n.t('customers.create.physicianDeaNumber'),
                disabled: true,
              }} />
            </Col>
          )}
        </Row>
        <Row>
          <Col md={3}>
            <Field name='customer.physician.display_address' component={TextInput} props={{
              label: I18n.t('customers.create.physicianAddress'),
              disabled: true,
            }} />
          </Col>
          <Col md={3}>
            <Field name='customer.physician.display_clinic' component={TextInput} props={{
              label: I18n.t('customers.create.clinicName'),
              disabled: true,
            }} />
          </Col>
          {isOhMetrc && (
            <Col md={3}>
              <Field name='customer.physician_recommendation_date' component={DatePickerInput} props={{
                label: I18n.t('customers.create.physicianRecommendationDate'),
                isRequired: isOhMetrc
              }} />
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

PhysicianSection.propTypes = {
  hasTempId: PropTypes.bool.isRequired,
  physicians: PropTypes.array,
  patientSettings: PropTypes.object,
  integrationState: PropTypes.object,
};

export default PhysicianSection;
