import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {displayQtyCurried, displayQtyAndUomCurried} from '../../selectors/uomsSelectors';


const DisplayQty = ({displayQty, displayQtyAndUom, qty, uom, displayUom }) => {
  const formatQtyDisplay = (displayUom && uom) ? displayQtyAndUom : displayQty;
  return (<span>{formatQtyDisplay(qty, uom)}</span>);
};

const mapStateToProps = state => ({
  displayQty: displayQtyCurried(state),
  displayQtyAndUom: displayQtyAndUomCurried(state),
});

DisplayQty.propTypes = {
  displayQty: PropTypes.func,
  displayQtyAndUom: PropTypes.func,
  qty: PropTypes.number,
  uom: PropTypes.string,
  displayUom: PropTypes.bool,
};

DisplayQty.defaultProps = {
  displayUom: false,
};

export default connect(mapStateToProps)(DisplayQty);
