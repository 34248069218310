import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import DateTimeField from 'react-datetime';
import {Button} from 'react-bootstrap';
import {FaCalendarAlt, FaRegClock} from 'react-icons/fa';
import {I18n} from 'react-redux-i18n';
import moment from 'moment';
import {getInternationalDateFormat, getInternationalTimeFormat} from '../../../selectors/InternationalOperationsSelectors';
import ReduxBootstrapInput from './ReduxBootstrapInput';

/******************************************************************
 * Please consider using InternationalDateTimePickerInput instead of this component.
 * InternationalDateTimePickerInput will validate manual user input against
 * International Settings
 */

const InternationalDatePickerInput = ({ timezone, label, size, input, meta, children, isRequired, leftAddon, timeFormat, dateFormat, internationalTimeFormat, internationalDateFormat, inputProps, handleChange, enableTodayButton, ...props }) => {
  const leftIcon = leftAddon || (timeFormat ? <FaRegClock/> : <FaCalendarAlt/>);

  const todayButton = timezone ?
    <Button onClick={(e) => {typeof(handleChange) !== 'undefined' ? handleChange(moment().tz(timezone)) : input.onChange(moment().tz(timezone));}} disabled={inputProps && inputProps.disabled || false}>{I18n.t('common.actions.today')}</Button> :
    <Button onClick={(e) => {typeof(handleChange) !== 'undefined' ? handleChange(moment()) : input.onChange(moment());}} disabled={inputProps && inputProps.disabled || false}>{I18n.t('common.actions.today')}</Button>;
  const rightAddon = enableTodayButton && todayButton;

  internationalTimeFormat = internationalTimeFormat ? internationalTimeFormat : 'h:mm A';
  internationalDateFormat = internationalDateFormat ? internationalDateFormat : 'MM/DD/YYYY';

  // extreme overriding with {...props}
  return (
    <ReduxBootstrapInput Control={DateTimeField}
                         label={label} size={size}
                         input={input} meta={meta}
                         name={input.name}
                         isRequired={isRequired}
                         timeFormat={timeFormat ? internationalTimeFormat : false}
                         dateFormat={dateFormat ? internationalDateFormat : false}
                         onFocus={() => {}}
                         leftAddon={leftIcon}
                         rightAddon={rightAddon}
                         inputProps={inputProps}
                         readOnly={props.readOnly}
                         props={{readOnly: props.readOnly}}
                         onChange={typeof(handleChange) !== 'undefined' ? (value) => handleChange(value) : (value) => input.onChange(value)}
                         className='date-picker-field' {...props} >
      {children}
    </ReduxBootstrapInput>
  );
};

InternationalDatePickerInput.propTypes = {
  label: PropTypes.string,
  size: PropTypes.oneOf(['lg', 'sm', 'large', 'small']),
  timeFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  dateFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onClick: PropTypes.func,
    onHandleChange: PropTypes.func,

  }).isRequired,
  inputProps: PropTypes.object,
  meta: PropTypes.object.isRequired,
  children: PropTypes.node,
  leftAddon: PropTypes.node,
  isRequired: PropTypes.bool,
  enableTodayButton: PropTypes.bool,
  handleChange: PropTypes.func,
  internationalTimeFormat: PropTypes.string,
  internationalDateFormat: PropTypes.string,
  props: PropTypes.object,
  readOnly: PropTypes.bool, //?
};

InternationalDatePickerInput.defaultProps = {
  timeFormat: false,
  dateFormat: true,
  enableTodayButton: true,
};


function mapStateToProps(state, ownProps){
  return {
    internationalDateFormat: getInternationalDateFormat(state),
    internationalTimeFormat: getInternationalTimeFormat(state),
  };
}

export default connect(mapStateToProps)(InternationalDatePickerInput);
