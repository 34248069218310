const productSubTypes = [
  // Applies to leaf WA where a product sub type is selected for sales orders
  // value is the value used on the sales order when the type is selected
  // translationKey is used to create the text option of the selection
  // comparison can be either a property on the lab result expected to equal value
  // or a function accepting the lab result and returning a boolean result
  {value: 'extraction', translationKey: 'extraction', comparison: () => true},
  {value: 'finished', translationKey: 'finished', comparison: (testResult) => testResult.lab_result && testResult.lab_result.status === 'passed'}
];

export default productSubTypes;
