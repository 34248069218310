import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {push} from 'react-router-redux';
import * as dataNames from '../../constants/dataNames';
import {getItem, getUnpaginatedData} from '../../actions/apiActions';
import {getFacilityHasModule, getFacilityTitle} from '../../selectors/facilitiesSelectors';
import {getTestResultDimensions,getAverageTableData} from '../../selectors/testResultsSelectors';
import TablePageWrapper from '../common/grid/TablePageWrapper';
import AbstractTestResultListing from './common/AbstractTestResultListing';
import getColumns from './common/columns';
import * as itemNames from '../../constants/itemNames';
import {getTabs} from './common/tabDefinitions';
import {getFeatureEnabledList} from '../../selectors/featureToggles';


export class AverageTestResultsPage extends AbstractTestResultListing {
  constructor(props, context) {
    super(props, context);
    this.viewResultsButton = this.viewResultsButton.bind(this);
    this.toggleViewDetails = this.toggleViewDetails.bind(this);
    this.switchTab = this.switchTab.bind(this);
    this.expandComponent = this.expandComponent.bind(this);
    this.setBodyState = this.setBodyState.bind(this);
    const tabs = getTabs({}).map((tab) => {
      tab.actions = [];
      return tab;
    });
    const expanded = [];
    this.state = {tabs, expanded};
  }

  componentWillMount() {
    this.props.actions.getUnpaginatedData(
      `/api/lab_results/averages`,
      dataNames.averageTestResults,
      {failed: 'cultivation.testResults.get.failed'}
    );

    Promise.all([
      this.props.actions.getItem(`/api/lab_results/configuration`, itemNames.testResultConfiguration, null),
    ])
      .then(() => {
        const {averagesColumns} = getColumns(
          this,
          this.props.integrationState,
          this.props.hasEditPermission,
          this.props.labResultDimensions,
          this.props.displayCompletionStatus,
          this.props.featureEnabledList
        );

        this.setState({
          averagesColumns: averagesColumns
        });

        this.setState(
          {
            ready: true
          });

      })
      .catch(() => this.setState({ready: true}));
  }

  render() {
    const {tabs, averagesColumns, expanded} = this.state;
    const expanding = expanded.map(row => row.id);
    const activeTab = 'averages';
    const {testResults} = this.props;

    return (
      <div className='test-results-wrapper'>
        <TablePageWrapper
          ref={this.ref}
          settingKey='test-results-averages'
          handleSelect={() => {}}
          selectedRows={[]}
          data={testResults}
          tabs={tabs}
          activeTab={activeTab}
          switchTab={this.switchTab}
          hideScanSearch={true}
          columns={averagesColumns}
          hideExport={true}
          bstProps={{
            selectRow: {
              clickToSelect: false,
              clickToExpand: false,
              hideSelectColumn: true,
              selected: [],
            },
            expandableRow: () => true,
            expandComponent: (row) => {
              const dataConfig = this.props.labResultDimensions;
              const hideStatus = true;
              return this.expandComponent(row, dataConfig, hideStatus);
            },
            options: {
              //After setState the array is mutating so workaround is to use slice
              onRowClick: () => this.setBodyState({expanding: expanding.slice()}),
            },
          }}/>
      </div>
    );
  }
}

AverageTestResultsPage.propTypes = {
  testResults: PropTypes.array.isRequired,
  facilityTitle: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    push: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    facilityTitle: getFacilityTitle(state),
    testResults: getAverageTableData(state),
    labResultDimensions: getTestResultDimensions(state),
    displayCompletionStatus: getFacilityHasModule(state)('TESTING_LAB'),
    featureEnabledList: getFeatureEnabledList(state)(true),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {push, getUnpaginatedData, getItem};
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AverageTestResultsPage);
