
import {requiredFieldValidation} from '../../common/form/redux-form/validations';

export const validate = (values) => {
  const errors = {};
  errors.date_from = requiredFieldValidation(values.date_from, 'integration.transactions.dateFrom');
  return errors;
};

export default validate;
