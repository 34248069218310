/* eslint-disable import/no-named-as-default */
import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, FieldArray } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { Col, FormGroup, Row, Button, ListGroup, ListGroupItem, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {FaExclamationTriangle} from 'react-icons/fa';
import * as consumerTypes from '../../../constants/consumerTypes';
import {temp_id} from '../../../constants/fileTypes';
import {patient} from '../../../constants/imageTypes';
import {CUSTOMER_FORM} from '../../../constants/forms';
import {PA_MED_CAREGIVER, PA_MED_PATIENT, DL} from '../../../constants/idTypes';
import {PERSON_MALE, PERSON_FEMALE, PERSON} from '../../../constants/imageUrls';
import InternationalReduxPhoneInput from '../../common/form/redux-form/InternationalReduxPhoneInput';
import TextInput from '../../common/form/TextInput';
import TextAreaInput from '../../common/form/TextAreaInput';
import InternationalDateTimePickerInput from '../../common/form/InternationalDateTimePickerInput';
import MultiselectInput from '../../common/form/MultiselectInput';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import ComboboxInput from '../../common/form/ComboboxInput';
import { NewCaregiverFormGroup } from './NewCaregiverFormGroup';
import { NewDeliveryAddressFormGroup } from './NewDeliveryAddressFormGroup';
import ConsumerIdsFieldArray from './ConsumerIdsFieldArray';
import AccordionPanel from '../../common/AccordionPanel';
import PatientIdBlock from './PatientIdBlock';
import ComplianceLimitsBlock from './ComplianceLimitsBlock';
import ImageField from '../../common/form/files/ImageField';
import ContentConcealer from '../../common/concealers/ContentConcealer';
import { validate, isFieldRequired, isExpirationRequired } from './validate';
import PermissionWrapper from '../../common/PermissionWrapper';
import * as p from '../../../constants/permissions';
import PhysicianSection from '../common/PhysicianSection';
import WillRender from '../../common/concealers/WillRender';
import CustomerIdMethodField from '../create-edit/CustomerIdMethodField';

export const CustomerForm = (props) => {

  const californiaTypes = [
    { text: I18n.t('customers.create.medical'), value: consumerTypes.MEDICAL},
    { text: I18n.t('customers.create.medical_ca'), value: consumerTypes.MMIC},
    { text: I18n.t('customers.create.recreational'), value: consumerTypes.RECREATIONAL},
  ];

  const contactPreferences = [
    { text: I18n.t('common.form.phone'), value: 'phone' },
    { text: I18n.t('common.form.text'), value: 'text' },
    { text: I18n.t('common.form.email'), value: 'email' },
    { text: I18n.t('common.form.physicalMail'), value: 'physical_mail' }
  ];
  const genders = [
    { text: I18n.t('common.form.female'), value: 'female' },
    { text: I18n.t('common.form.male'), value: 'male' },
    { text: I18n.t('common.form.unspecified'), value: 'unspecified' }
  ];
  if(props.integrationState.isUtah){
    genders.push({text: I18n.t('common.form.nonbinary'), value: 'nonbinary'});
  }
  const statuses = [
    { text: I18n.t('customers.create.active'), value: '1' },
    { text: I18n.t('customers.create.inactive'), value: '0' }
  ];

  const types = [
    { text: I18n.t('customers.create.medical'), value: consumerTypes.MEDICAL },
    { text: I18n.t('customers.create.recreational'), value: consumerTypes.RECREATIONAL }
  ];
  if(props.facilityState === 'OK'){
    types.push({text: I18n.t('customers.create.disabled_veterans'), value: consumerTypes.DISABLED_VETERANS });
  }

  const inStateOptions = [
    { text: I18n.t('customers.create.inState'), value: '1' },
    { text: I18n.t('customers.create.outOfState'), value: '0' },
  ];
  const useFrequencies = [
    { text: I18n.t('customers.create.firstTime'), value: 'first_time' },
    { text: I18n.t('customers.create.daily'), value: 'daily' },
    { text: I18n.t('customers.create.weekly'), value: 'weekly' },
    { text: I18n.t('customers.create.monthly'), value: 'monthly' }
  ];
  const taxExempts = [
    { text: I18n.t('customers.create.no'), value: '0' },
    { text: I18n.t('customers.create.yes'), value: '1' },
  ];

  const terminalIllnessOptions = [
    {value: 0, text: I18n.t('customers.create.no') },
    {value: 1, text: I18n.t('customers.create.yes')},
  ];

  const {
    applicationMode,
    array: {push},
    budTenders,
    change,
    complianceLimits,
    currentFormValues,
    customer,
    customerGroups,
    documentUploads,
    registrations,
    enableScanModal,
    facilities,
    facilityState,
    fileTypes,
    form,
    handleHistory,
    handleSave,
    handleSaveAndCheckIn,
    handleSubmit,
    isCanadaFacility,
    isPaLeaf,
    isWaLeaf,
    lastUpdatedComment,
    medicalIdExpirationIsRequired,
    notes,
    onfleetIsEnabled,
    patientSettings,
    physicians,
    primaryFacilityIsRequired,
    qualifyingConditions,
    referralSources,
    showScanModal,
    tagOptions,
    canCheckIn,
    integrationState,
    touch,
    paOraclePatientSyncFeature,
    syncRegistry,
    addressFieldsDisabled,
    displaySaveAndCheckIn,
    hasMetrcSalesLimits
  } = props;
  const hasTempId = documentUploads.some(file => file.type === temp_id && file.identification_number && file.identification_number.trim());
  const groupFieldPermissions = (customer && customer.number_id) ? [p.edit_customers, p.create_customers] : [p.create_customers];
  const {isOhMetrc, isBiotrack, isHypur, isFutuCare, isPrBiotrack, isUtah} = integrationState;

  return (
    <form className='create-customer-form'>
      <Row>
      {isPaLeaf && lastUpdatedComment !== undefined && (lastUpdatedComment ?
        (
          <Col xs={6} style={{marginLeft: '15px', padding: '5px', marginBottom: '12px'}} className='bg-warning text-warning'>
            <FaExclamationTriangle style={{paddingRight: '8px'}}/>
            {I18n.t('customers.create.seeRegistry', {date: lastUpdatedComment})}
          </Col>
        )
          : (
          <Col xs={6} style={{marginLeft: '15px', padding: '5px', marginBottom: '12px'}} className='bg-warning text-warning'>
            <FaExclamationTriangle style={{paddingRight: '8px'}}/>
            {I18n.t('customers.create.noMedicalProfessionalComments')}
          </Col>
        )
      )}
      </Row>
      <FormGroup>
        <Row className='patient-information'>
          <Col md={12}>
            <h3>
              {I18n.t('customers.create.patientInfo')}
              {enableScanModal && <div className='float-right'><Button type='button' onClick={showScanModal('scan', DL)}>{I18n.t('customers.create.scanLicense')}</Button></div>}
              {isPaLeaf && <div className='float-right'>
                <Button type='button' onClick={showScanModal('scan', PA_MED_PATIENT)}>{I18n.t('customers.create.scanPatientID')}</Button>
                <Button type='button' onClick={showScanModal('scan', PA_MED_CAREGIVER)}>{I18n.t('customers.create.scanCaregiverID')}</Button>
                {
                  paOraclePatientSyncFeature && customer && (typeof syncRegistry === 'function') &&
                  <OverlayTrigger focus={'click'} placement='top' overlay={<Tooltip id={'syncWithRegistry_overlay'}>{I18n.t('customers.modify.syncWithRegistry.tooltip')}</Tooltip>}>
                    <Button type='button'
                            onClick={syncRegistry}>
                      {I18n.t('customers.modify.syncWithRegistry.button')}
                    </Button>
                  </OverlayTrigger>
                }
              </div>}
            </h3>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <Row>
              <Col md={4}>
                <Field name='customer.first_name' component={TextInput} props={{
                  label: I18n.t('customers.create.firstName'),
                  placeholder: I18n.t('customers.placeholders.enterFirstName'),
                  isRequired: true,
                  readOnly: isUtah
                }} />
              </Col>
              <Col md={1}>
                <Field name='customer.middle_name' component={TextInput} props={{
                  maxLength: 1,
                  label: I18n.t('customers.create.middleName'),
                  readOnly: isUtah
                }} />
              </Col>
              <Col md={4}>
                <Field name='customer.last_name' component={TextInput} props={{
                  label: I18n.t('customers.create.lastName'),
                  placeholder: I18n.t('customers.placeholders.enterLastName'),
                  isRequired: true,
                  readOnly: isUtah
                }} />
              </Col>
              {
                patientSettings.goesByEnabled && (
                  <Col md={3}>
                    <Field name='customer.goes_by' component={TextInput} props={{
                      label: I18n.t('customers.create.goesBy'),
                      placeholder: I18n.t('customers.placeholders.enterGoesBy'),
                      isRequired: false
                    }} />
                  </Col>
                )
              }
            </Row>
            {
              (isFutuCare || isPrBiotrack) && (
                <Row>
                  <Col md={3}>
                    <Field name='customer.ssn_last_4' component={TextInput} props={{
                      label: I18n.t('customers.create.ssnLast4'),
                      isRequired: true
                    }} />
                  </Col>
                </Row>
              )
            }
            <Row>
              <Col md={3}>
                <InternationalDateTimePickerInput
                  name='customer.birth_date'
                  props={{
                    label: I18n.t('customers.create.dateOfBirth'),
                    isRequired: true,
                    readOnly: isUtah
                  }} />
              </Col>
              <Col md={3}>
                <InternationalReduxPhoneInput name='customer.number' props={{
                  label: I18n.t('customers.create.phone'),
                  isRequired: onfleetIsEnabled || isOhMetrc
                }} />
              </Col>
              <Col md={3}>
                <Field name='customer.email_address' component={TextInput} props={{
                  label: I18n.t('customers.create.email'),
                  placeholder: I18n.t('customers.placeholders.emailAddress')
                }} />
              </Col>
              <Col md={3}>
                <Field name='customer.preferred_contact' component={ReactSelectInput} props={{
                  label: I18n.t('customers.create.preferredContact'),
                  options: contactPreferences,
                  placeholder: I18n.t('common.form.select')
                }} />
              </Col>
            </Row>
            <Row>
              <PermissionWrapper hasPermission={(groupFieldPermissions.length > 0)}>
                <Col md={2}>
                  <Field name='customer.group_ids' component={MultiselectInput} props={{
                    label: I18n.t('customers.create.customerGroups'),
                    options: customerGroups,
                    valueKey: 'id',
                    textKey: 'group_name'
                  }} />
                </Col>
              </PermissionWrapper>
              <Col md={2}>
                <Field name='customer.gender' component={ReactSelectInput} props={{
                  label: I18n.t('customers.create.gender'),
                  options: genders,
                  placeholder: I18n.t('common.form.select'),
                  isRequired: true
                }} />
              </Col>
              <Col md={2}>
                <Field name='customer.frequency_of_use' component={ReactSelectInput} props={{
                  label: I18n.t('customers.create.frequency'),
                  options: useFrequencies,
                  placeholder: I18n.t('common.form.select')
                }} />
              </Col>
              <Col md={2}>
                <Field name='customer.active' component={ReactSelectInput} props={{
                  label: I18n.t('customers.create.status'),
                  options: statuses,
                  placeholder: I18n.t('common.form.select'),
                  isRequired: true
                }} />
              </Col>
              <Col md={2}>
                <Field name='customer.type' component={ReactSelectInput} props={{
                  label: I18n.t('customers.create.type'),
                  options: facilityState === 'CA' ? californiaTypes : types,
                  placeholder: I18n.t('common.form.select'),
                  isRequired: true,
                  disabled: isPaLeaf
                }} />
              </Col>
              <Col md={2}>
                <Field name='customer.in_state' component={ReactSelectInput} props={{
                  label: I18n.t('customers.create.inState'),
                  options: inStateOptions,
                  placeholder: I18n.t('common.form.select'),
                  isRequired: true,
                  disabled: isPaLeaf
                }} />
              </Col>
              <div style={{clear:'both'}} />
              {isCanadaFacility ?
                <Col md={3}>
                  <Field name='customer.personal_use_producer' component={ReactSelectInput} props={{
                    label: I18n.t('customers.create.personalUseProducer'),
                    options: taxExempts,
                    placeholder: I18n.t('common.form.select'),
                    isRequired: true
                  }} />
                </Col>
                : null
              }
            </Row>
            <Row>
              <Col md={2}>
                <Field name='rewards_group_name' component={TextInput} props={{
                  label: I18n.t('customers.create.rewardCustomerGroup'),
                  disabled: true
                }} />
              </Col>
              <Col md={4}>
                <Field name='customer.referral_source' component={ComboboxInput} props={{
                  label: I18n.t('customers.create.referralSource'),
                  options: referralSources,
                  textKey: 'name',
                  valueKey: 'id',
                  placeholder: I18n.t('common.form.select')
                }} />
              </Col>
              <Col md={2}>
                <Field name='customer.tax_exempt' component={ReactSelectInput} props={{
                  label: I18n.t('customers.create.taxExempt'),
                  options: taxExempts,
                  placeholder: I18n.t('common.form.select')
                }} />
              </Col>
              <Col md={4}>
                <Field name='customer.preferred_csr' component={ReactSelectInput} props={{
                  label: I18n.t('customers.create.preferredService'),
                  options: budTenders,
                  textKey: 'name',
                  valueKey: 'id',
                  placeholder: I18n.t('common.form.select')
                }} />
              </Col>
              {isHypur ?
                <Col md={4}>
                  <Field name='customer.extra_data.hypur_name' component={TextInput} props={{
                    label: I18n.t('customers.modify.hypurName'),
                    disabled: true
                  }} />
                </Col>
                : null
              }
              <Col md={3}>
                {<CustomerIdMethodField/>}
              </Col>
            </Row>
            {(isOhMetrc) && (
              <Row>
                <Col md={3}>
                  <Field name='customer.is_terminal_illness' component={ReactSelectInput} props={{
                    label: I18n.t('customers.create.terminalIllness'),
                    options: terminalIllnessOptions,
                  }} />
                </Col>
              </Row>
            )}
          </Col>
          <Col md={4}>
            <ImageField
              imageType={patient}
              name='customer.image_file_id'
              fileName='customer.image_file'
              change={change}
              value={customer.image_file_id}
              fileValue={customer.image_file}
              thumbnail={() => (customer.gender === 'male' && PERSON_MALE) || (customer.gender === 'female' && PERSON_FEMALE) || PERSON}
            />
            <PatientIdBlock isPaLeaf={isPaLeaf} isWaLeaf={isWaLeaf} tagOptions={tagOptions} paOraclePatientSyncFeature={paOraclePatientSyncFeature} />
          </Col>
        </Row>
      </FormGroup>
      <AccordionPanel
        title={'customers.create.caregiverInfo'}>
          <FieldArray
            name='caregiverInfo'
            props={{
              arrayPush: push,
              change,
              applicationMode,
              isPaLeaf,
              isOhMetrc,
              paOraclePatientSyncFeature,
            }}
            component={NewCaregiverFormGroup}
          />
        </AccordionPanel>
      <AccordionPanel
        title={'customers.create.addressInformation'}
        expanded={isOhMetrc}
        defaultExpanded={false}>
          <FieldArray
            name='addresses'
            component={NewDeliveryAddressFormGroup}
            props={{
              change: change,
              integrationState,
              addressFieldsDisabled: addressFieldsDisabled
            }}
          />
      </AccordionPanel>
      <AccordionPanel
        title={'customers.create.documents'}
        expanded={[consumerTypes.MMIC, consumerTypes.MEDICAL, consumerTypes.DISABLED_VETERANS].indexOf(customer.type) !== -1}>

          <FieldArray name='ids' component={ConsumerIdsFieldArray} props={{
            change,
            customer,
            types: fileTypes,
            files: documentUploads,
            isPaLeaf,
            isUtah,
            name: 'ids',
            isUploadDisabled: () => !patientSettings || !patientSettings.documentUploads,
            isFieldRequired: (type) => isFieldRequired(type, medicalIdExpirationIsRequired, patientSettings, hasTempId),
            isExpirationRequired: (type) => isExpirationRequired(type, currentFormValues.customer),
            isCanadaFacility,
            formName: form,
            paOraclePatientSyncFeature:paOraclePatientSyncFeature,
          }}/>
      </AccordionPanel>
      <WillRender ifTrue={isCanadaFacility}>
        <AccordionPanel title={'customers.registrationEvents'} expanded={false}>
          <FieldArray name='registrations' component={ConsumerIdsFieldArray} props={{
            change,
            touch,
            customer,
            types: ['canadian_registration_form'],
            files: registrations,
            isPaLeaf,
            name: 'registrations',
            isUploadDisabled: () => !patientSettings || !patientSettings.documentUploads,
            isFieldRequired: (type) => isFieldRequired(type, medicalIdExpirationIsRequired, patientSettings, hasTempId),
            isExpirationRequired: (type) => isExpirationRequired(type, currentFormValues.customer),
            isCanadaFacility,
            formName: form,
          }}/>
        </AccordionPanel>
      </WillRender>
      <AccordionPanel
        title={'customers.create.additionalMarijuanaInfo'}>
          <FormGroup>
            <Row>
              <Col md={2}>
                <Field name='customer.primary_facility_id' component={ReactSelectInput} props={{
                  label: I18n.t('customers.create.primaryFacility'),
                  options: facilities,
                  textKey: 'name',
                  valueKey: 'id',
                  placeholder: I18n.t('common.form.select'),
                  isRequired: primaryFacilityIsRequired && (customer && customer.type === 'medical')
                }} />
              </Col>
              <Col md={3}>
                <InternationalDateTimePickerInput
                  name='customer.date_provider_can_switch'
                  props={{
                    label: I18n.t('customers.create.dateAvailableToSwitch')
                  }}
                />
              </Col>
              <WillRender ifTrue={!isUtah}>
                <Col md={2}>
                  <Field name='customer.qualifying_condition_id' component={ComboboxInput} props={{
                    label: I18n.t('customers.create.qualifyingCondition'),
                    options: qualifyingConditions,
                    textKey: 'text',
                    valueKey: 'value',
                    suggest: false,
                    placeholder: I18n.t('common.form.selectOrType', {data_name: 'New Condition'})
                  }} />
                </Col>
                <Col md={3}>
                  <Field name='customer.diagnosis' component={TextInput} props={{
                    label: I18n.t('customers.create.diagnosis'),
                    placeholder: I18n.t('customers.placeholders.diagnosis')
                  }} />
                </Col>
              </WillRender>
              {isOhMetrc ?
                <Col md={2}>
                  <Field name='customer.refills_authorized' component={ReactSelectInput} props={{
                    label: I18n.t('customers.create.refillsAuthorized'),
                    options: [{value: '0'}, {value: '01'}, {value: '02'}, {value: '03'}],
                    textKey: 'value',
                    valueKey: 'value',
                    isRequired: true
                  }} />
                </Col>
                : null
              }
            </Row>
            <h4>{I18n.t('customers.create.physicianInformation')}</h4>
            <PhysicianSection
              hasTempId={hasTempId}
              physicians={physicians}
              patientSettings={patientSettings}
              integrationState={integrationState}
            />
            <Row>
              <Col xs={12}>
                <Field name='customer.physician_notes' component={TextAreaInput} props={{
                  label: I18n.t('customers.create.physicianNotes'),
                  placeholder: I18n.t('customers.create.physicianNotes'),
                  rows: 3,
                }}/>
              </Col>
            </Row>
          </FormGroup>
      </AccordionPanel>
      <AccordionPanel
        title={'customers.create.notes'}>
          <FormGroup>
            {notes && notes.length ?
              <ListGroup>
                {notes.map((note, index) =>
                  <ListGroupItem key={index}>
                    <b>{note.createdAt}</b> {note.note}
                  </ListGroupItem>
                )}
              </ListGroup>
              : null}
            <Row>
              <Col xs={12}>
                <Field name='customer.new_note' component={TextAreaInput} props={{
                  placeholder: I18n.t('purchaseOrders.form.notePlaceholder'),
                  rows: 3,
                  label: '',
                }}/>
              </Col>
            </Row>
          </FormGroup>
      </AccordionPanel>
      <ContentConcealer show={complianceLimits && complianceLimits.show_compliance_limits_block && !isBiotrack && !hasMetrcSalesLimits}>
        <AccordionPanel title={'customers.create.complianceLimits'} className='inner-top-margin-0'>
          <ComplianceLimitsBlock
            complianceLimits={complianceLimits}
            change={change}
          />
        </AccordionPanel>
      </ContentConcealer>
      <Row className='limits-and-ordering'>
        <Col md={12}>
          <div className='form-group-border'/>
        </Col>
        <Col md={12}>
          <div className='float-right button-wrapper'>
            {(!customer || !customer.number_id) ? null :
            <Button
              onClick={handleHistory}
              variant='default'
              className='history-button'>
              {I18n.t('customers.history.viewHistory')}
            </Button>}
            <Button
              onClick={handleSubmit(handleSave)}
              variant='primary'
              className='email-button'>
              {I18n.t('common.form.save')}
            </Button>
            <ContentConcealer show={displaySaveAndCheckIn && canCheckIn && !isPaLeaf}>
              <Button
                onClick={handleSubmit(handleSaveAndCheckIn)}
                variant='primary'
                className='submit-button'>
                {I18n.t('customers.create.saveAndCheckIn')}
              </Button>
            </ContentConcealer>
          </div>
        </Col>
      </Row>
    </form>
  );
};

CustomerForm.propTypes = {
  onfleetIsEnabled: PropTypes.bool,
  handleHistory: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleSaveAndCheckIn: PropTypes.func.isRequired,
  customerGroups: PropTypes.array.isRequired,
  referralSources: PropTypes.array.isRequired,
  budTenders: PropTypes.array.isRequired,
  facilities: PropTypes.array.isRequired,
  physicians: PropTypes.array.isRequired,
  change: PropTypes.func.isRequired,
  tagOptions: PropTypes.array.isRequired,
  enableScanModal: PropTypes.bool,
  showScanModal: PropTypes.func,
  documentUploads: PropTypes.array.isRequired,
  registrations: PropTypes.array.isRequired, // same thing as document uploads for registrations
  notes: PropTypes.array.isRequired,
  qualifyingConditions: PropTypes.array.isRequired,
  patientSettings: PropTypes.object.isRequired,
  currentFormValues: PropTypes.object,
  medicalIdExpirationIsRequired: PropTypes.bool,
  complianceLimits: PropTypes.object,
  fileTypes: PropTypes.array.isRequired,
  customer: PropTypes.object.isRequired,
  isPaLeaf: PropTypes.bool,
  orgHasCanada: PropTypes.bool,
  array: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  applicationMode: PropTypes.string.isRequired,
  isCanadaFacility: PropTypes.bool.isRequired,
  form: PropTypes.string.isRequired,
  isWaLeaf: PropTypes.bool,
  facilityState: PropTypes.string,
  lastUpdatedComment: PropTypes.string,
  primaryFacilityIsRequired: PropTypes.bool.isRequired,
  integrationState: PropTypes.object.isRequired,
  canCheckIn: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
  paOraclePatientSyncFeature: PropTypes.bool,
  syncRegistry: PropTypes.func,
  touch: PropTypes.func.isRequired,
  addressFieldsDisabled: PropTypes.bool,
  displaySaveAndCheckIn: PropTypes.bool.isRequired,
  hasMetrcSalesLimits: PropTypes.bool,
};

export default reduxForm({
  form: CUSTOMER_FORM,
  validate,
  enableReinitialize: true
})(CustomerForm);
