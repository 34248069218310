import React from 'react';
import PropTypes from 'prop-types';
import {Table, Button, FormLabel} from 'react-bootstrap';
import {FaDollarSign} from 'react-icons/fa';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import {normalizeCreditCardNumber, formatCreditCardNumber, normalizeMonth, normalizeYear} from './utils';
import CheckBoxInput from '../../common/form/CheckBoxInput';
import NumericInput from '../../common/form/NumericInput';
import PasswordInput from '../../common/form/PasswordInput';
import TextInput from '../../common/form/TextInput';

class CardPayment extends React.PureComponent {

  constructor(props, context) {

    super(props, context);

    this.state = {
      ccBlured: false,
      ccAutoFocus: false,
      cvvBlured: false,
      cvvAutoFocus: false
    };

    this.CcOnBlur = this.CcOnBlur.bind(this);
    this.CcOnFocus = this.CcOnFocus.bind(this);
    this.CvvOnBlur = this.CvvOnBlur.bind(this);
    this.CvvOnFocus = this.CvvOnFocus.bind(this);

  }

  CcOnBlur(){
    this.setState({ ccBlured: true });
  }

  CcOnFocus(){
    this.setState({ ccBlured: false, ccAutoFocus: true });
    setTimeout(() => {
      this.setState({ ccAutoFocus: false });
    }, 10);
  }

  CvvOnBlur(){
    this.setState({ cvvBlured: true });
  }

  CvvOnFocus(){
    this.setState({ cvvBlured: false, cvvAutoFocus: true });
    setTimeout(() => {
      this.setState({ cvvAutoFocus: false });
    }, 10);
  }

  render(){

    const { amount, isOverride, paymentDisabled, showScamModal }  = this.props;

    const fieldsDisabled = Number(amount || '') === 0 || isOverride;

    let CreditCardComponent = TextInput;
    let CVVComponent = TextInput;

    if(this.state.ccBlured){
      CreditCardComponent = PasswordInput;
    }

    if(this.state.cvvBlured){
      CVVComponent = PasswordInput;
    }

    return (
      <div>
        <Table bordered={true} className='credit-card'>
          <thead>
          <tr>
            <th colSpan={2}>
              <div className='layout-row layout-align-space-between-center'>
                <span>{I18n.t('cart.payment.card.title')}</span>
                <Button size='sm' variant='warning' onClick={showScamModal} disabled={paymentDisabled || fieldsDisabled}>{I18n.t('cart.payment.card.swipeCard')}</Button>
              </div>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td><FormLabel>{I18n.t('cart.payment.card.amount')}</FormLabel></td>
            <td>
              <Field name='charge_amount' component={NumericInput} props={{
                fractionPartSize: 2,
                readOnly: true,
                value: amount,
                leftAddon: <FaDollarSign/>,
                groupClassName: 'amount',
              }}/>
            </td>
          </tr>
          <tr>
            <td><FormLabel>{I18n.t('cart.payment.card.cardNumber')}</FormLabel></td>
            <td>
              <Field
                name='credit_card.number'
                component={CreditCardComponent}
                normalize={normalizeCreditCardNumber}
                format={formatCreditCardNumber}
                props={{
                  autoFocus: this.state.ccAutoFocus,
                  onFocus: this.CcOnFocus,
                  onBlur: this.CcOnBlur,
                  placeholder: 'xxxx xxxx xxxx xxxx',
                  disabled: fieldsDisabled,
                }}
              />
            </td>
          </tr>
          <tr>
            <td><FormLabel>{I18n.t('cart.payment.card.expiration')}</FormLabel></td>
            <td>
              <div className='layout-row layout-align-center-center expiration'>
                <Field
                  name='credit_card.exp_month'
                  component={TextInput}
                  normalize={normalizeMonth}
                  props={{
                    disabled: fieldsDisabled,
                    placeholder: 'mm',
                    groupClassName: 'month',
                  }}
                />
                <span className='separator'>/</span>
                <Field
                  name='credit_card.exp_year'
                  component={TextInput}
                  normalize={normalizeYear}
                  props={{
                    disabled: fieldsDisabled,
                    placeholder: 'yyyy',
                    groupClassName: 'year',
                  }}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td><FormLabel>{I18n.t('cart.payment.card.cvv')}</FormLabel></td>
            <td>
              <Field name='credit_card.cvv' component={CVVComponent}
              props={{
                autoFocus: this.state.cvvAutoFocus,
                onFocus: this.CvvOnFocus,
                onBlur: this.CvvOnBlur,
                placeholder: I18n.t('cart.payment.card.cvvPlaceholder'),
                disabled: fieldsDisabled,
              }}/>
            </td>
          </tr>
          <tr>
            <td><FormLabel>{I18n.t('cart.payment.card.zipCode')}</FormLabel></td>
            <td>
              <Field name='credit_card.zip' component={TextInput} props={{
                placeholder: I18n.t('cart.payment.card.zipCodePlaceholder'),
                disabled: fieldsDisabled,
              }}/>
            </td>
          </tr>
          <tr>
            <td><FormLabel>{I18n.t('cart.payment.card.streetName')}</FormLabel></td>
            <td>
              <Field name='credit_card.street_name' component={TextInput} props={{
                placeholder: I18n.t('cart.payment.card.streetName'),
                maxLength: 19,
                disabled: fieldsDisabled,
              }}/>
            </td>
          </tr>
          <tr>
            <td><FormLabel>{I18n.t('cart.payment.card.streetNumber')}</FormLabel></td>
            <td>
              <Field name='credit_card.street_number' component={TextInput} props={{
                placeholder: I18n.t('cart.payment.card.streetNumber'),
                maxLength: 19,
                disabled: fieldsDisabled,
              }}/>
            </td>
          </tr>

          </tbody>
        </Table>
        <div className='layout-row layout-align-center-center'>
          <Field name='is_override' component={CheckBoxInput} props={{
            label: I18n.t('cart.payment.card.paymentTaken'),
            groupClassName: 'inline-checkbox',
          }}/>
        </div>
      </div>
    );
  }
}


CardPayment.propTypes = {
  showScamModal: PropTypes.func.isRequired,
  paymentDisabled: PropTypes.bool,
  amount: PropTypes.number,
  isOverride: PropTypes.bool,
};

export default CardPayment;
