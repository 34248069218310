import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col, Card } from 'react-bootstrap';
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { TAX_PROFILE_FORM } from '../../../../../constants/forms';
import TextInput from '../../../../common/form/TextInput';
import MultiselectInput from '../../../../common/form/MultiselectInput';
import validate from './../validate';
import TaxGroups from './../components/TaxGroups';
import TaxGroup from './../components/TaxGroup'; //eslint-disable-line
import SubmitSection from '../../../../common/form/SubmitSection';

const TaxProfileForm = (props) => {
  const { submitting, invalid, handleSubmit, facilities, change, selectedFacilities, selectedCustomerTypes } = props;
  const settings = {
    actionSettings: {
      submit: {
        submitting,
        invalid,
        action: () => {
          change('stay', true);
        },
        variant: 'primary',
        text: I18n.t('common.form.saveAndStay')
      },
      submitAndExit: {
        submitting,
        type: 'submit',
        invalid,
        variant: 'primary',
        text: I18n.t('common.form.saveAndExit')
      }
    }
  };

  const customerTypes = [
    { value: 'applies_to_medical', text: I18n.t('taxes.form.medical') },
    { value: 'applies_to_recreational', text: I18n.t('taxes.form.recreational') }
  ];

  //we need to use a special cali customer type aray because medical values are flipped in California
  //it makes no sense, so don't even try to understand it
  const californiaCustomerTypes = [
    { value: 'applies_to_non_mmic', text: I18n.t('taxes.form.medical') },
    { value: 'applies_to_recreational', text: I18n.t('taxes.form.recreational') },
    { value: 'applies_to_medical', text: I18n.t('taxes.form.mmic') }
  ];

  const inStateOptions = [
    { value: 'applies_in_state', text: I18n.t('taxes.form.inState') },
    { value: 'applies_out_of_state', text: I18n.t('taxes.form.outOfState') }
  ];

  // NOTE: These are not great candidates for selectors since the form data is an argument; they would rarely have same inputs
  /**
   * @param {boolean} // true if any facility is in california
   */
  const isCalifornia = Array.isArray(facilities) && facilities.some((facility) => facility.province_code === 'CA');
  const isOklahoma = Array.isArray(facilities) && facilities.some((facility) => facility.province_code === 'OK');
  const firstSelectedFacility =
    Array.isArray(selectedFacilities) && selectedFacilities.length ? selectedFacilities[0] : false;
  const usingMMIC =
    Array.isArray(selectedCustomerTypes) &&
    isCalifornia &&
    selectedCustomerTypes.some((type) => type.value === 'applies_to_medical');

  let allowedCustomerTypes = [];

  if (
    (isCalifornia && !firstSelectedFacility) ||
    (isCalifornia && firstSelectedFacility && firstSelectedFacility.province_code === 'CA')
  ) {
    allowedCustomerTypes = californiaCustomerTypes;
  } else if (
    (isOklahoma && !firstSelectedFacility) ||
    (isOklahoma && firstSelectedFacility && firstSelectedFacility.province_code === 'OK')
  ) {
    allowedCustomerTypes = [
      ...customerTypes,
      { value: 'applies_to_disabled_veterans', text: I18n.t('taxes.form.disabledVeterans') }
    ];
  } else {
    allowedCustomerTypes = customerTypes;
  }

  // If there is a selected facility ensure other facilities available for selection are in the same state
  const addOptionText = (facility) => ({
    ...facility,
    nameAndState: `${facility.name} (${facility.province_code})`
  });

  const filterByState = (facility) => {
    // If MMIC in selected list, only CA facilities available
    if (firstSelectedFacility.province_code === 'CA' && usingMMIC) {
      return facility.province_code === 'CA';
    }

    return Array.isArray(selectedFacilities) && selectedFacilities.length !== 0 // If selectedFacilitie, all facilities must match state
      ? facility.province_code === selectedFacilities[0].province_code
      : true;
  };

  const allowedFacilities = facilities.map(addOptionText).filter(filterByState);

  const allFacilitiesInSameState =
    facilities.reduce((acc, facility) => {
      if (acc.indexOf(facility.province_code) === -1) {
        acc.push(facility.province_code);
      }
      return acc;
    }, []).length === 1;

  return (
    <form className='tax-profiles' onSubmit={handleSubmit}>
      <Row>
        <Col md={4}>
          <Field
            name='tax_profile_name'
            component={TextInput}
            props={{
              label: 'Profile Name',
              isRequired: true
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Field
            name='facilities'
            component={MultiselectInput}
            props={{
              label: I18n.t('taxes.form.applicableFacilities'),
              options: allowedFacilities,
              textKey: 'nameAndState',
              valueKey: 'id',
              allOption: allFacilitiesInSameState,
              isRequired: true,
              placeholder: 'Select At Least One...'
            }}
          />
        </Col>
        <Col md={4}>
          <Field
            name='customer_types'
            component={MultiselectInput}
            props={{
              label: I18n.t('taxes.form.customerTypes'),
              options: allowedCustomerTypes,
              allOption: true,
              isRequired: true,
              disabled: !selectedFacilities || (selectedFacilities && selectedFacilities.length === 0),
              placeholder: 'Select At Least One...'
            }}
          />
        </Col>
        <Col md={4}>
          <Field
            name='states'
            component={MultiselectInput}
            props={{
              label: I18n.t('taxes.form.inState'),
              options: inStateOptions,
              allOption: true,
              isRequired: true,
              placeholder: 'Select At Least One...'
            }}
          />
        </Col>
      </Row>
      <div style={{ height: '20px', clear: 'both' }} />
      <Row>
        <Col md={6}>
          <FieldArray name='groups' component={TaxGroups} />
        </Col>
        <Col md={6}>
          <Card>
            <Card.Header>
              <Card.Title>{I18n.t('taxProfiles.form.taxesAppliedToSubTotalAndGroupedTaxes')}:</Card.Title>
            </Card.Header>
            <Card.Body>
              <Field
                component={TaxGroup}
                name='taxes'
                groupKey='taxes'
                idx={0}
                hideTaxGroupName={true}
                totalLabel='taxedResult'
                inboundLabel={I18n.t('taxProfiles.form.subTotalPlusGroupedTaxes')}
                props={{ hideTaxGroupName: true }}
              />
            </Card.Body>
          </Card>
          <hr />
          <Card>
            <Card.Header>
              <Card.Title>
              {I18n.t('taxProfiles.form.taxesAppliedToSubTotalOnly')}:
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Field
                component={TaxGroup}
                name='taxes_on_subtotal'
                groupKey='taxes_on_subtotal'
                idx={0}
                hideTaxGroupName={true}
                inboundLabel={I18n.t('taxProfiles.form.subTotalPlusCompoundingTaxes')}
                props={{ hideTaxGroupName: true, subtax: true }}
              />
            </Card.Body>
          </Card>
        </Col>
        <div style={{ clear: 'both' }} />
        <hr />
        <Col md={12}>
          <SubmitSection settings={settings} />
        </Col>
      </Row>
    </form>
  );
};

TaxProfileForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  facilities: PropTypes.array.isRequired,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
  facility: PropTypes.object.isRequired,
  selectedFacilities: PropTypes.array,
  selectedCustomerTypes: PropTypes.array
};

const selector = formValueSelector(TAX_PROFILE_FORM);

function mapStateToProps(state) {
  return {
    selectedFacilities: selector(state, 'facilities'),
    selectedCustomerTypes: selector(state, 'customer_types')
  };
}

const TaxProfileReduxForm = reduxForm({ form: TAX_PROFILE_FORM, validate, enableReinitialize: true })(TaxProfileForm);
const TaxProfileFormWrapper = connect(mapStateToProps)(TaxProfileReduxForm);
export default TaxProfileFormWrapper;
