import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Row, Table, Card} from 'react-bootstrap';
import ContentConcealer from '../../common/concealers/ContentConcealer';
import PricingBlockHistory from './PricingBlockHistory';

const ProductCreatedDetails = (props) => {
  const {product} = props;
  return (
    <Card>
      <Row>
        <Col xs={6}>
          <h3>{I18n.t('products.productHistory.productDetails')}</h3>
          <Table striped className='item-details'>
            <thead>
            <tr>
              <th>{I18n.t('itemTransactions.field')}</th>
              <th>{I18n.t('itemTransactions.value')}</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>{I18n.t('products.form.itemCategory')}</td>
              <td>{product.category_name}</td>
            </tr>
            <tr>
              <td>{I18n.t('products.form.itemSubcategory')}</td>
              <td>{product.subcategory_name}</td>
            </tr>
            <ContentConcealer show={!!product.strain_name}>
              <tr>
                <td>{I18n.t('products.form.strainName')}</td>
                <td>{product.strain_name}</td>
              </tr>
            </ContentConcealer>
            <tr>
              <td>{I18n.t('products.form.itemName')}</td>
              <td>{product.name}</td>
            </tr>
            <ContentConcealer show={!!product.display_name}>
              <tr>
                <td>{I18n.t('products.form.displayName')}</td>
                <td>{product.display_name}</td>
              </tr>
            </ContentConcealer>
            <ContentConcealer show={!!product.item_number}>
              <tr>
                <td>{I18n.t('products.form.itemId')}</td>
                <td>{product.item_number}</td>
              </tr>
            </ContentConcealer>
            <ContentConcealer show={!!product.tags_name}>
              <tr>
                <td>{I18n.t('products.form.tags')}</td>
                <td>{product.tags_name}</td>
              </tr>
            </ContentConcealer>
            <ContentConcealer show={!!product.description}>
              <tr>
                <td>{I18n.t('products.form.productDescription')}</td>
                <td>{product.description}</td>
              </tr>
            </ContentConcealer>
            {product.brand ?
              <tr>
                <td>{I18n.t('products.form.brand')}</td>
                <td>{product.brand ? product.brand.name : ''}</td>
              </tr>
            : null}
            <tr>
              <td>{I18n.t('products.form.purchaseInventory')}</td>
              <td>{product.is_purchasing_item ? 'Yes' : 'No'}</td>
            </tr>
            <tr>
              <td>{I18n.t('products.form.trackInventory')}</td>
              <td>{product.is_inventory_item ? 'Yes' : 'No'}</td>
            </tr>
            <tr>
              <td>{I18n.t('products.form.sellInventory')}</td>
              <td>{product.is_sales_item ? 'Yes' : 'No'}</td>
            </tr>
            <tr>
              <td>{I18n.t('products.form.produceInventory')}</td>
              <td>{product.is_manufactured_item ? 'Yes' : 'No'}</td>
            </tr>
            <tr>
              <td>{I18n.t('products.form.inventoryUom')}</td>
              <td>{product.default_uom}</td>
            </tr>
            <ContentConcealer show={!!product.phenotype_name}>
              <tr>
                <td>{I18n.t('products.form.phenotype')}</td>
                <td>{product.phenotype_name}</td>
              </tr>
            </ContentConcealer>
            <ContentConcealer show={!!product.dominance}>
              <tr>
                <td>{I18n.t('products.form.dominanceName')}</td>
                <td>{product.dominance}</td>
              </tr>
            </ContentConcealer>
            <tr>
              <td>{I18n.t('products.form.prePackItem')}</td>
              <td>{product.inventory_attributes.is_prepack ? 'Yes' : 'No'}</td>
            </tr>
            <tr>
              <td>{I18n.t('products.form.trackLots')}</td>
              <td>{product.inventory_attributes.lot_tracked ? 'Yes' : 'No'}</td>
            </tr>
            <ContentConcealer show={!!product.show_medicated_weight}>
              <tr>
                <td>{I18n.t('products.form.medicatedNetWeight')}</td>
                <td>{product.medicated_weight}</td>
              </tr>
            </ContentConcealer>
            <ContentConcealer show={!!product.show_medicated_weight}>
              <tr>
                <td>{I18n.t('products.form.uom')}</td>
                <td>{product.medicated_weight_uom}</td>
              </tr>
            </ContentConcealer>
            <ContentConcealer show={!!product.show_net_weight}>
              <tr>
                <td>{I18n.t('products.form.productNetWeight')}</td>
                <td>{product.net_weight}</td>
              </tr>
            </ContentConcealer>
            <ContentConcealer show={!!product.show_net_weight}>
              <tr>
                <td>{I18n.t('products.form.uom')}</td>
                <td>{product.net_weight_uom}</td>
              </tr>
            </ContentConcealer>
            <ContentConcealer show={!!product.show_unit_weight}>
              <tr>
                <td>{I18n.t('products.form.grossWeight')}</td>
                <td>{product.unit_weight}</td>
              </tr>
            </ContentConcealer>
            <ContentConcealer show={!!product.show_unit_weight}>
              <tr>
                <td>{I18n.t('products.form.uom')}</td>
                <td>{product.unit_weight_uom}</td>
              </tr>
            </ContentConcealer>
            <ContentConcealer show={!!product.show_medicated_volume}>
              <tr>
                <td>{I18n.t('products.form.medicatedNetVolume')}</td>
                <td>{product.medicated_volume}</td>
              </tr>
            </ContentConcealer>
            <ContentConcealer show={!!product.show_medicated_volume}>
              <tr>
                <td>{I18n.t('products.form.uom')}</td>
                <td>{product.medicated_volume_uom}</td>
              </tr>
            </ContentConcealer>
            <ContentConcealer show={!!product.show_net_volume}>
              <tr>
                <td>{I18n.t('products.form.netVolume')}</td>
                <td>{product.net_volume}</td>
              </tr>
            </ContentConcealer>
            <ContentConcealer show={!!product.show_net_volume}>
              <tr>
                <td>{I18n.t('products.form.uom')}</td>
                <td>{product.net_volume_uom}</td>
              </tr>
            </ContentConcealer>
            {product.purchasing_attributes && product.purchasing_attributes.par_level ?
              <tr>
                <td>{I18n.t('products.form.parLevel')}</td>
                <td>{product.purchasing_attributes.par_level}</td>
              </tr>
              : null}
            {product.purchasing_attributes && product.purchasing_attributes.auto_reorder_qty ?
              <tr>
                <td>{I18n.t('products.form.autoReorderQuantity')}</td>
                <td>{product.purchasing_attributes.auto_reorder_qty}</td>
              </tr>
            : null}
            {product.purchasing_attributes && product.purchasing_attributes.buffer ?
              <tr>
                <td>{I18n.t('products.form.apiBuffer')}</td>
                <td>{product.purchasing_attributes.buffer}</td>
              </tr>
            : null}
            <ContentConcealer show={!!product.vendors.length}>
              <tr>
                <td colSpan={2}>{I18n.t('products.form.vendor')}</td>
              </tr>
              {product.vendors.map(vendor => (
                <tr key={vendor.id}>
                  <td style={{paddingLeft: '30px'}}>{vendor.vendor_name}</td>
                  <td>{I18n.t('products.form.costPer') + ` ${product.default_uom || '<UOM>'}`}: {vendor.default_unit_cost}</td>
                </tr>
              ))}
            </ContentConcealer>
            <ContentConcealer show={product.is_sales_item}>
              <ContentConcealer show={!!product.sales_attributes.pricing_type}>
                <tr>
                  <td>{I18n.t('products.form.pricingType')}</td>
                  <td>{product.sales_attributes.pricing_type}</td>
                </tr>
              </ContentConcealer>
              <ContentConcealer show={product.pricing_details && product.pricing_details.price_lists.length}>
                {product.pricing_details.price_lists.map((price_list, index) => (<PricingBlockHistory key={index} pricing={price_list} uom={product.default_uom}/>))}
              </ContentConcealer>
            </ContentConcealer>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Card>
  );
};

ProductCreatedDetails.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ProductCreatedDetails;
