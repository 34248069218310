import {getUnpaginatedData} from '../actions/apiActions';
import * as dataNames from '../constants/dataNames';
import {SALES_ORDER_LISTING_UPDATED, PURCHASE_ORDER_LISTING_UPDATED} from '../constants/actionTypes';

/* ==================================
|   IMPROVEMENTS REFACTOR NEEDED
|====================================
| Ideally we should design a service provider that can instantiate
| the socket.io instance at the bootstrap of the application and inject it
| into the middleware to maintain global processing where relevant: I.E. facility information changing
| but also the same instance in the service provider can be injected into components so relevant
| updates are handled inside the component, since it only needs to occur in those specific components
| and we'd have access to the existing state of the component and the methods for firing retrievals
*/

const supplyChainUpdated = store => next => action => {
  const result = next(action);
  const state = store.getState();

  const page = state.currentPage;
  const params = state.currentParams;
  const request = state.currentRequest;

  // If we receive a notification that a sales order has been retrieved, if we are on the 
  // SalesOrdersListingPage or the TransfersPage, we will refresh the current tab.  The component
  // itself is sending the params and page keys into state to be used here.
  if (action.type === SALES_ORDER_LISTING_UPDATED) {
    if (page === 'sales-orders') {
      const status = params.status || 'active';
      store.dispatch(getUnpaginatedData('/api/sales_orders/' + status + '?with_relations=transfers', dataNames.salesOrders));
    }

    if (page === 'transfers') {
      const status = params.status || 'active';
      store.dispatch(getUnpaginatedData('/api/transfers/' + status, dataNames.salesOrders));
    }
  }

  // If we receive a notification that a purchase order has been retrieved, if we are on the 
  // PurchaseOrdersListingPage or the InventoryReceiptsPage, we will refresh the current tab.  The component
  // itself is sending the params and page keys into state to be used here.
  if (action.type === PURCHASE_ORDER_LISTING_UPDATED) {
    if (page === 'purchase-orders') {    
      const status = params.status || 'active';
      store.dispatch(getUnpaginatedData('/api/purchase_orders/' + status, dataNames.salesOrders));
    }

    if (page === 'inventory-receipts') {
      const url = (request && request.url) ? request.url : null;
      if (url) {
        store.dispatch(getUnpaginatedData(url, dataNames.inventoryReceipts));
      }
    }
  }

  return result;
};

export default supplyChainUpdated;
