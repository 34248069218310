import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {Row, Col} from 'react-bootstrap';
import * as dataNames from '../../../constants/dataNames';
import {getUnpaginatedData} from '../../../actions/apiActions';
import {unsetData} from '../../../actions/dataActions';
import {WASTE_SUBCATEGORY_ID} from '../../../constants/categories';
import DualListProductSelector from '../../common/DualListProductSelector';

export class PricingMultiplierProductsComponent extends React.PureComponent {
  constructor (props) {
    super(props);

    this.defaultSearchParams = {
      // NOTE: add search filter default_uom:EA only for products that can be applied pricing multiplier
      filter: `active:1 AND default_uom:EA AND is_draft:0 AND is_ingredient:0 AND item_master_parent_id:0 AND !subcategory_id:${WASTE_SUBCATEGORY_ID}`,
      query: '',
      size: 15000,
      sort: 'name asc, display_name asc',
      start: 0,
      fields: ['id', 'name', 'display_name', 'is_medicated', 'category_id', 'subcategory_id', 'lot_tracked',
        'is_prepack', 'item_master_parent_id', 'prepack_weight_id', 'is_sales_item', 'category_code',
        'subcategory_code', 'uom_type', 'default_uom', 'active', 'is_inventory_item', 'item_active', 'vendor_id',
        'medicated_weight_base', 'medicated_weight_uom_display']
    };
  }

  componentDidMount() {
    // If this is a new pricing multiplier set the id to 0 so that any item_masters used in other pricing multipliers will be excluded.
    const id = this.props.pricingMultiplierId ? this.props.pricingMultiplierId : 0;
    this.props.actions.getUnpaginatedData(`/api/pricing_multipliers/${id}/excluding_items_ids`, dataNames.pricingMultiplierDisabledItems);
  }

  componentWillUnmount() {
    this.props.actions.unsetData(dataNames.pricingMultiplierDisabledItems);
  }

  render () {
    const {change, getFormValue, onSelectedItemMasterChange, pricingMultiplierDisabledItems, categories, vendors, medicatedWeights, selectedItemMasterIds, hideFormLabel} = this.props;

    return (
      <React.Fragment>
        <Row>
          <Col style={{padding: '20px'}}>
            <DualListProductSelector
              change={change}
              getFormValue={getFormValue}
              defaultSearchParams={this.defaultSearchParams}
              onSelectedItemMasterChange={onSelectedItemMasterChange}
              disabledItemMasterIds={pricingMultiplierDisabledItems}
              categories={categories}
              vendors={vendors}
              medicatedWeights={medicatedWeights}
              selectedItemMasterIds={selectedItemMasterIds}
              hideFormLabel={hideFormLabel}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

PricingMultiplierProductsComponent.propTypes = {
  change: PropTypes.func.isRequired,
  getFormValue: PropTypes.func.isRequired,
  itemMasters: PropTypes.array.isRequired,
  pricingMultiplierId: PropTypes.string,
  categories: PropTypes.array.isRequired,
  vendors: PropTypes.array.isRequired,
  medicatedWeights: PropTypes.array.isRequired,
  selectedItemMasterIds: PropTypes.array.isRequired,
  onSelectedItemMasterChange: PropTypes.func.isRequired,
  pricingMultiplierDisabledItems: PropTypes.array,
  hideFormLabel: PropTypes.bool,
  actions: PropTypes.shape({
    getUnpaginatedData: PropTypes.func.isRequired,
  }),
};

function mapStateToProps (state) {
  return {
    itemMasters: state[dataNames.itemMasters] ? state[dataNames.itemMasters] : [],
    pricingMultiplierDisabledItems: state[dataNames.pricingMultiplierDisabledItems]
  };}

function mapDispatchToProps (dispatch) {
  const actions = Object.assign({}, {getUnpaginatedData, unsetData});

  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PricingMultiplierProductsComponent);
