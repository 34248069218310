import moment from 'moment';
import {convertClientToDbTz} from '../../util/dateHelpers';

const getPayload = (formData) => {

  const map = {
    waste: 'reportWaste',
    waste_other_material: 'otherMaterialWaste',
    weight_before: 'current_weight',
    current_weight: 'newWeight',
    current_other_material_weight: 'newOtherMaterialWeight',
    moisture_pct: 'moisture_pct',
    calculated_moisture_loss: 'calculated_moisture_loss',
  };

  let actionDate;
  return Object.assign({}, Object.keys(map).reduce((acc, key) => {
    acc[key] = formData[map[key]] && !isNaN(parseFloat(formData[map[key]])) ? parseFloat(formData[map[key]]) : 0;
    return acc;
  }, {}), {

    action_date: actionDate = formData.action_date ? convertClientToDbTz(formData.action_date) : null,
    completed_at: formData.finalWeight
      ? formData.action_date
        ? actionDate
        : moment.utc()
      : null,
    final_weight_recorded: formData.finalWeight,
    final_weight: !formData.finalWeight ? null : undefined,
    inventory_location_id: formData.inventory_location_id,
    section_id: formData.section_id,
    uom: formData.uom_display,
    notes: formData.notes,
    waste_type: formData.waste_type
  });

};

export default getPayload;
