/* eslint-disable import/no-named-as-default*/
import {reduxForm} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {registerTransactionIsInRange} from '../common/form/redux-form/validations';
import {REGISTER_FORM} from '../../constants/forms';

import ModifyRegisterForm from './ModifyRegisterForm';

const validate = (values) => {
  const errors = {transaction: {}};
  const transaction = values.transaction;
  if (!values.name) {
    errors.name = I18n.t('registers.form.enterName');
  }
  if (transaction) {
    if (transaction.transacted_amount && !transaction.reason_code) {
      errors.transaction.reason_code = I18n.t('registers.form.selectReason');
    }
    if (!transaction.transacted_amount || !parseFloat(transaction.transacted_amount)) {
      errors.transaction.transacted_amount = I18n.t('registers.form.enterQuantity');
    }
    if(!errors.transaction.transacted_amount){
      errors.transaction.transacted_amount = registerTransactionIsInRange(parseFloat(transaction.transacted_amount), 'full');
    }
  }
  return errors;
};

const ReduxModifyRegisterForm = reduxForm({
  form: REGISTER_FORM,
  validate,
})(ModifyRegisterForm);

export default ReduxModifyRegisterForm;
