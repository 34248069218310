import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash.get';
import InProgressOverlay from '../../common/InProgressOverlay';
import * as apiActions from '../../../actions/apiActions';
import {setData} from '../../../actions/dataActions';
import {setItem} from '../../../actions/itemActions';
import {setSolrErrorMessage, setSolrSearchTerm} from '../../../actions/solrActions';
import * as itemNames from '../../../constants/itemNames';
import * as dataNames from '../../../constants/dataNames';
import {getFilteredColumns, getFilteredTabs} from '../../../selectors/tableSelectors';

import ErrorBoundry from '../ErrorBoundry';
import TablePage from './TablePage';

class TablePageWrapper extends React.PureComponent {

  constructor(props, context) {
    super(props, context);

    this.state = {
      ready: false,
      showLoader: false,
    };
    this.ref = React.createRef();
    this.updateColumnSettings = this.updateColumnSettings.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.filter = this.filter.bind(this);
    this.getBaseRef = this.getBaseRef.bind(this);
  }

  componentWillMount() {
    const pageKey = this.props.settingKey;
    this.setState({ showLoader: true });
    this.props.tableActions.getItem(
      `/api/user_settings/column_visibility/${pageKey}`,
      itemNames.columnVisibilitySettings,
      { failed: 'user.settings.columnVisibility' },
      null,
      settings => {

        const setDefaultHiddenColumns = () => {
          if(settings.data && Array.isArray(settings.data) && settings.data.length > 0) return false;
          if(!this.props.defaultHiddenColumns || !Array.isArray(this.props.defaultHiddenColumns)) return false;
          const columnDefinitions = this.props.columns.map((column) => {
            column.hidden = this.props.defaultHiddenColumns.indexOf(column.dataId) !== -1;
            return column;
          });
          this.props.tableActions.setData(Object.assign({}, settings, {data: columnDefinitions}), itemNames.columnVisibilitySettings);
        };
        setDefaultHiddenColumns();
        const tablePageInside = this.ref.current;
        if (tablePageInside) {
          const columns = this.updateColumnVisibilitySettings(this.props.columns, settings['data'] || []);
          const pageSize = this.props.settings ? this.props.settings.per_page : settings['per_page'] || 20;
          tablePageInside.setState({ columns, pageSize });
        }
        this.setState({ ready: true, showLoader: false });
      }, d => d).catch(error => {
        this.setState({ ready: true, showLoader: false });
      });
  }


  updateColumnSettings(settings) {
    if (this.props.settingKey && settings && settings.data && settings.per_page) {
      const page = this.props.settingKey;
      //in scan search there is an option for 10,000 plants, but this will not allow 10,000 to be saved
      settings.per_page = settings.per_page > 50 ? 50 : settings.per_page;
      this.props.tableActions.postItem(
        '/api/user_settings/column_visibility/',
        { page, ...settings },
        null,
        { failed: 'user.settings.columnVisibilityStore' }
      ).then((result) => {
        // NOTE: This is a bandage to hold us over until TGC-1046 is considered
        // The server response is inconsistent on first save for a given page vs subsequent saves
        if (result.success && !result.response) return;

        const visibilitySettings = Object.assign({}, settings, {data: result});
        this.props.tableActions.setItem(visibilitySettings, itemNames.columnVisibilitySettings);
      });
    }
  }

  updateColumnVisibilitySettings(columns, columnsSetting) {
    if (columns && columns.length && columnsSetting && columnsSetting.length) {
      columns = columns.map(column => {
        const columnSetting = columnsSetting.find(setting => setting.name === column.name);

        if (columnSetting) {
          return {
            ...column,
            ...columnSetting,
          };
        }

        return column;
      });
    }
    return columns;
  }

  getBaseRef() {
    return this.ref.current._tableContainer.current.wrappedInstance.ref.current;
  }
  handleSearch(value) {
    const doSearch = get(this.ref, 'current.doSearch');
    doSearch && doSearch(value);
  }

  filter(value) {
    this.ref.current && this.ref.current.filter(value);
  }

  render() {
    const { ready, showLoader } = this.state;

    const props = {
      ...this.props,
      updateColumnSettings: this.updateColumnSettings,
      handleSearch: this.props.handleCustomSearch ? this.props.handleCustomSearch : this.handleSearch,
      ref: this.ref,
      getData: this.props.tableActions.getData,
      setSolrErrorMessage: this.props.tableActions.setSolrErrorMessage,
      columnSettingsAreLoaded: ready
    };

    let className = props.className || '';
    if (props.disableChanges) {
      className += ' disable-table-changes';
    }

    return (
      <ErrorBoundry>
        <InProgressOverlay
          isActive={showLoader}
          onDismiss={false}
          showOk={false}
          showLoader={showLoader}
          translate={false}
        />
        <TablePage {...props} style={{ visibility: ready ? 'visible' : 'hidden' }} className={className} />
      </ErrorBoundry>
    );
  }
}

TablePageWrapper.propTypes = {
  tableActions: PropTypes.object,
  // See documentation TableComponent::shouldRenderData method docblock for usage.
  useDataRenderFlag: PropTypes.bool,
};

function mapDispatchToProps(dispatch) {
  const actions = {setData, setItem, setSolrErrorMessage, setSolrSearchTerm};
  return {
    tableActions: bindActionCreators(Object.assign({}, apiActions, actions), dispatch),
  };
}

function mapStateToProps(state, ownProps) {
  return {
    tabs: getFilteredTabs(state, {tabs: ownProps.tabs || [], state}),
    columns: getFilteredColumns(state, {columns: ownProps.columns || [], state}),
    autoSuggestions: state[dataNames.autoSuggestions],
    solrErrorMessage: state[itemNames.solrErrorMessage],
    solrSearchTerms: state[itemNames.solrSearchTerms],
    facility: state[itemNames.facility],
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(TablePageWrapper);
