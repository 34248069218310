import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import {betweenValidation, requiredFieldValidation, requiredNonEmptyFieldValidation, minValidation, maxValidation} from '../../common/form/redux-form/validations';
import { isInvPackageTrackingNotInCompliance} from '../../../selectors/complianceSettingsSelectors';
import {getSubcategoryId} from '../../../selectors/categorySelectors';

export const referenceErrorMessages = {
  packages: 'cultivation.testResults.form.noPackagesSelected',
  plants: 'cultivation.testResults.form.noPlantGroupsSelected'
};

export const validate = (values, ownProps) => {
  const {
    integrationState: {isIsolocity, isPaLeaf}, complianceSettings, facilityHasLabModule, requiredDimensions, isPlants,
    labResultDimensions, inheritedDimensionCategories, testResultConfig
  } = ownProps;
  const saveAsInProgress = get(values, 'saveAsInProgress');

  // Ignore validation on inherited dimensions; the source may have been less complete than the version in force now.
  const inheritedDimensions = Object.keys(labResultDimensions).reduce((acc, category) => {
    if (inheritedDimensionCategories.indexOf(category) === -1) {
      return acc;
    }
    const dimensions = labResultDimensions[category];
    dimensions.forEach((dimension) => {
      acc[dimension.key] = category;
    });
    return acc;
  }, {});
  const isInheritedDimension = (dimensionKey) => {
    return inheritedDimensions[dimensionKey];
  };

  const errors = {
    testing_date: requiredFieldValidation(values.testing_date, 'cultivation.testResults.form.testingDate'),
    analyst_name: isIsolocity && requiredFieldValidation(values.analyst_name, 'cultivation.testResults.isolocity.analystName'),
    testing_id: isIsolocity && requiredFieldValidation(values.testing_id, 'cultivation.testResults.form.testingId'),
  };

  if (testResultConfig && testResultConfig.enabled && testResultConfig.dimensions) {
    testResultConfig.dimensions.forEach(dimension => {
      const field = labResultDimensions[dimension.category_key].find(field => field.key === dimension.dimension_key);

      if (field) {
        if (dimension.range_start) {
          errors[field.key] = minValidation(values[field.key], field.name, dimension.range_start);
        }
        if (dimension.range_end) {
          errors[field.key] = maxValidation(values[field.key], field.name, dimension.range_end);
        }
      }
    });
  }

  if (requiredDimensions && !saveAsInProgress) {
    requiredDimensions.forEach(key => {
      if (!isInheritedDimension(key)) {
        errors[key] = requiredNonEmptyFieldValidation(values[key], I18n.t('common.form.field'));
      }
    });
  }

  Object.keys(values).forEach(key => {
    if (/percent$/.test(key)) {
      errors[key] = betweenValidation(values[key], null, 0, 100);
    }
  });

  if (!values.references || !values.references.length) {
    errors.references = {
      _error: I18n.t(referenceErrorMessages[isPlants ? 'plants' : 'packages']),
    };
  }

  if (get(complianceSettings, 'inv_packages_require_tracking_id', false)) {
    if (get('values', 'references', []).some((reference) => {
      return isInvPackageTrackingNotInCompliance(reference);
    })) {
      errors.references = {
        _error: complianceSettings.inv_packages_require_tracking_id_message,
      };
    }
  }

  if (facilityHasLabModule) {
    errors.completion_status = requiredFieldValidation(values.completion_status, 'cultivation.testResults.form.completionStatus');
  }

  // test_type 'remediation' is only valid for the category_code 'INFUSED', subcategory_code 'REMEDIATED_TOPICAL'
  if (get(values, 'test_type') === 'remediation') {
    const subcategoryId = getSubcategoryId(get(ownProps, 'categories'), 'INFUSED', 'REMEDIATED_TOPICAL');
    // Add error if any package is not of the type 'REMEDIATED_TOPICAL'
    if (get(values, 'references', []).some((ref) => ref.subcategory_id !== subcategoryId)) {
      errors['test_type'] = I18n.t('cultivation.testResults.form.notRemediatedProduct');
    }
  }

  // if PA and the lab enhancements Feature Toggle is enabled, the test_type field is mandatory
  if (isPaLeaf && get(ownProps, 'isPaLabEnhancementEnabled') === true) {
    errors['test_type'] = requiredFieldValidation(values.test_type, 'cultivation.testResults.form.testTypes');
  }

  return errors;
};

export default validate;
