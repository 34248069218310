import React from 'react';
import PropTypes from 'prop-types';
//import {Link} from 'react-router';
import {I18n} from 'react-redux-i18n';
import {reduxForm, Field} from 'redux-form';
import {Button, Col, Row} from 'react-bootstrap';
import MultiselectInput from '../common/form/MultiselectInput';



const validate = (values) => {
  const errors = {};
  if (!values.organizationStrains.length) {
    errors.organizationStrains = I18n.t('strains.organization.emptyFieldErorr');
  }
  return errors;
};

const OrganizationStrainListing = ({strains, handleSubmit}) => {
  return (
    <div>
        <Row>
          <Col md={12}>
            <Field name='organizationStrains' component={MultiselectInput}
              normalize={(value, prevValue) => Array.isArray(value) ? value.filter(el => el) : [...prevValue, value].filter(el => el)}
              createNew={true}
              props={{
                placeholder:'Enter strain name',
                label: I18n.t(`strains.organization.strainInputLabel`),
                options: strains,
                textKey: 'strain_name',
                messages: {
                  createNew: I18n.t('strains.organization.createNewStrain'),
                },
              }}/>
          </Col>

        </Row>
        <Row>
          <Col md={6} offset={1}>
            <div className='float-right button-wrapper'>
              <Button
                onClick={handleSubmit}
                variant='primary'
                className='save-strain'>
                {I18n.t('strains.organization.createNew')}
              </Button>
            </div>
          </Col>
        </Row>
    </div>
  );
};

OrganizationStrainListing.propTypes = {
  strains: PropTypes.array,
  handleSubmit: PropTypes.func,
};

export default reduxForm({
  form: 'create-organization-strain',
  validate
})(OrganizationStrainListing);
