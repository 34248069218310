import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';

import NumericInput from '../../common/form/NumericInput';

const ExpiredPasswordBlock = ({expirePasswordsSettings, enabledPALeafIntegration}) => {
  return (
    <div className='alert-settings-page'>
      <div className='rows layout-column'>
        <div className='input-row'>
          <div className='form-horizontal layout-row layout-align-start-center'>
            <span>
              {I18n.t('partner.createPartners.expireUserProfilePasswords')}
            </span>
            <Field
              name='expiration_period'
              component={NumericInput}
              disabled={enabledPALeafIntegration}
            />
            <span>
              {I18n.t('partner.createPartners.days')}
            </span>
          </div>
        </div>
        <div className='input-row'>
          <div className='form-horizontal layout-row layout-align-start-center'>
            <span>
              {I18n.t('partner.createPartners.sendPasswordExpirationWarningEmail')}
            </span>
            <Field
              name='expiration_reminder_period'
              component={NumericInput}
            />
            <span>
              {I18n.t('partner.createPartners.days')}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

ExpiredPasswordBlock.propTypes = {
  expirePasswordsSettings: PropTypes.object.isRequired,
  enabledPALeafIntegration: PropTypes.bool.isRequired,
};

export default ExpiredPasswordBlock;