import React from 'react';
import PropTypes from 'prop-types';
import {Pagination} from 'react-bootstrap';

const SimplePagination = ({
  onPageChange = () => {},
  size,
  bsSize,
  items = 0,
  activePage = 0,
  onSelect,
  maxButtons = 5,
  first = true,
  last = true,
  next = true,
  prev = true,
}) => {

  const handleSelect = (eventKey) => {
    if (onSelect) {
      onSelect(eventKey);
    }
    onPageChange(eventKey);
  };

  const paginationSize = size || bsSize || 'md';

  // Determine at what point the link to the '1' page should be dropped
  const number_of_pages  = [...Array(items)].length;
  const shift_at = Math.ceil(maxButtons / 2);
  let start = 1;
  let end = maxButtons;
  if (activePage >= shift_at && number_of_pages > maxButtons) {
    start = activePage - shift_at + 1;
    end = start + maxButtons - 1;
  }

  const buttons = [];
  for (let idx = start; idx <= end; idx++) {
    buttons.push(
    <Pagination.Item
      key={idx}
      active={idx === activePage}
      onClick={() => handleSelect(idx)}
    >
      {idx}
    </Pagination.Item>);
  }

  return (
    <Pagination size={paginationSize}>
      {first && <Pagination.First onClick={() => handleSelect(1)} />}
      {prev && <Pagination.Prev onClick={() => handleSelect(activePage - 1)} />}

      {buttons}

      {next && <Pagination.Next onClick={() => handleSelect(activePage + 1)} />}
      {last && <Pagination.Last onClick={() => handleSelect(items)} />}
    </Pagination>
  );
};

SimplePagination.propTypes = {
  size: PropTypes.string,
  bsSize: PropTypes.string,
  items: PropTypes.number.isRequired,
  activePage: PropTypes.number,
  onSelect: PropTypes.func,
  onPageChange: PropTypes.func,
  maxButtons: PropTypes.number,
  first: PropTypes.bool,
  last: PropTypes.bool,
  next: PropTypes.bool,
  prev: PropTypes.bool,
};

export default SimplePagination;
