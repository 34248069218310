import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { I18n } from 'react-redux-i18n';
import { goBack } from 'react-router-redux';
import { change, formValueSelector } from 'redux-form';
import {set} from 'lodash';
import get from 'lodash.get';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import { leafWaReconciliationReasonsRequiringReceivedBy } from '../../../constants/leaf';
import { getTotalResults } from '../../../selectors/paginationSelectors';
import {setData, unsetData} from '../../../actions/dataActions';
import {setItem} from '../../../actions/itemActions';
import * as messageTypes from '../../../constants/messageTypes';
import { addMessage } from '../../../actions/systemActions';
import {getUnpaginatedData, postItem, getItem, getSearchData, getDataByPost} from '../../../actions/apiActions';
import { getReconciliationInitialValues } from '../../../selectors/inventoryItemsSelectors';
import showReverseToParent from '../../../selectors/inventory/behaviors/showReverseToParentSelector';
import FormWrapper from '../../common/form/FormWrapper';
import ModalWrapper from '../../common/ModalWrapper';
import InProgressOverlay from '../../common/InProgressOverlay';
import ReconciliationFormWrapper from './ReconciliationFormWrapper';
import { getIntegrationState } from '../../../selectors/integration/integrationSelectors';
import { getIntegrationAdjustmentReasonOptions } from '../../../selectors/integration/adjustmentReasonsSelectors';
import * as complianceSettings from '../../../selectors/complianceSettingsSelectors';
import {roundQtyCurried} from '../../../selectors/uomsSelectors';
import {getUseEntityLocksForItems} from '../../../selectors/coreSettingsSelectors';
import {isFeatureEnabled} from '../../../selectors/featureToggles';

export class AdjustReconciliationsPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    const flowerCategoryId = 1;
    this.state = {
      saving: false,
      ready: false,
      tabs: [],
      activeTabEventKey: flowerCategoryId,
      start: 0,
      page: 0,
      showModal: false,
      payload: {},
      isZeroQty: false,
      adjustments: {},
      showConfirmModal: false,
      auditImportModeEnabled: false
    };
    this.redirect = this.redirect.bind(this);
    this.onAdjust = this.onAdjust.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.haveAdjustmentsInProgress = this.haveAdjustmentsInProgress.bind(this);
    this.changeTab = this.changeTab.bind(this);
  }

  componentDidMount() {
    const { getUnpaginatedData, unsetData } = this.props.actions;
    unsetData(dataNames.inventoryItems);
    const isReady = () => {
      this.setState({ ready: true });
    };

    Promise.all([
      getUnpaginatedData('/api/categories', dataNames.categories, { failed: 'categories.get.failed' }),
      getUnpaginatedData('/api/adjustment_reasons', dataNames.adjustmentReasons, {
        failed: 'adjustmentReasons.get.failed'
      }),
      getUnpaginatedData('/api/prepack_weights', dataNames.prepackWeights),
      getUnpaginatedData('/api/integration/adjustment_reasons', dataNames.integrationAdjustmentReasons, {
        failed: 'packages.modify.getIntegrationAdjustmentReasons.failed'
      })
    ])
      .then(isReady)
      .catch(isReady);

    if (this.props.integrationState.isWaLeaf) {
      getUnpaginatedData('/api/users/current_facility', dataNames.users);
    }

    this.props.actions.getItem('/api/compliance_settings', itemNames.complianceSettings);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const categories =
      this.props && this.props.categories.length
        ? this.props.categories.map((cat) => cat).sort((a, b) => a.name.localeCompare(b.name))
        : [];

    if (this.state.tabs.length || !categories.length) return false;

    this.setState({
      tabs: categories
        .map((category) => {
          return Object.assign({}, category, {
            title: category.name,
            eventKey: category.id,
            actions: []
          });
        })
        .filter((category) =>
          !prevProps.integrationState.isCanada ? category.category_code.indexOf('MARIJUANA') === -1 : true
        )
      // MARIJUANA is the category code of Fresh Marijuana which is Canada specific
    });
  }

  haveAdjustmentsInProgress() {
    return this.props.itemsLookup.reduce((acc, item) => {
      if (item.discrepancy && parseFloat(item.discrepancy)) {
        acc += parseFloat(item.discrepancy);
      }
      return acc;
    }, 0);
  }

  changeTab(tabKey) {
    let auditImportModeEnabled = false;
    if (tabKey === 0) {
      auditImportModeEnabled = true;
    }
    this.setState({ activeTabEventKey: tabKey, auditImportModeEnabled});
  }

  onTabChange(eventKey) {
    this.props.actions.setItem(eventKey, itemNames.activeTabEventKey);
    this.setState(
      {
        activeTabEventKey: this.haveAdjustmentsInProgress() ? this.state.lastTabEventKey : eventKey
      },
      () => {
        this.handleSearch(
          this.state.lastSearch ? this.state.lastSearch.sort : 'item_name asc',
          'matchall',
          null,
          0,
          null,
          true
        );
      }
    );
  }

  handleSearch(sort, query = 'matchall', size, start, filter) {
    if (typeof query === 'object' && query !== null) return false; // discards weird search case
    sort = sort && typeof sort === 'string' && sort.trim() !== '' ? sort.replace('item_name', 'name') : 'name asc';
    filter = filter
      ? `${filter} AND active:1 AND category_id:${this.state.activeTabEventKey}`
      : `active:1 AND category_id:${this.state.activeTabEventKey}`;

    // TODO: in case still need Metrc specific search here, this can be removed after confirm
    const {
      integrationState: { isMetrc }
    } = this.props;
    const group = isMetrc && this.state.activeTabEventKey
      ? {
        'group': true, //eslint-disable-line
        'group.format': 'grouped', //eslint-disable-line
        'group.field': 'state_integration_tracking_id', //eslint-disable-line
        'group.ngroups': true, //eslint-disable-line
        'group.limit': 99 //eslint-disable-line
      }
      : {};

    if (size === null) {
      size = this.state.lastSearch.size;
    }
    const params = { sort, query, size, start, filter, group };
    // Resets start and page to 0 which are ignored by table and it uses its internal counters
    this.setState({lastSearch: params, lastTabEventKey: this.state.activeTabEventKey, start: -1, page: 0}, () => {
      this.props.actions.getSearchData('/api/search/inventory', dataNames.inventoryItems, null, params)
        .then((itemsFromSolr) => {
          const ids = itemsFromSolr.map((item) => item.item_id);
          const params = {
            in_item_ids: ids
          };
          // Back fills solr results with reservations data since we removed reservations from the solr query.
          this.props.actions.getUnpaginatedData('/api/item_reservations', dataNames.reservations, null, params);
        });
    });
  }

  redirect() {
    this.props.actions.goBack();
  }

  onSubmit(formData) {
    const { adjustment_reason, integration_adjustment_reason, received_by_user_id } = formData;
    const groupValues = { adjustment_reason, integration_adjustment_reason, received_by_user_id };
    const adjustments = this.prepareAdjustment(formData);
    return this.onAdjust(adjustments, groupValues);
  }

  prepareAdjustment(formData) {
    const {
      integrationState: { isMetrc }
    } = this.props;
    return formData.categories.reduce((adjustments, category, categoryIndex) => {
      const categoryItems = category.items.reduce((items, item, itemIndex) => {
        if (
          Number.parseFloat(item.discrepancy) !== 0 &&
          (!isMetrc || item.integration_adjustment_reason || formData.integration_adjustment_reason)
        ) {
          const itemName = `categories[${categoryIndex}].items[${itemIndex}]`;

          // for the next fields we ignore row values if global form values are set:
          item.adjustment_reason = formData.adjustment_reason || item.adjustment_reason || undefined;
          item.integration_adjustment_reason =
            formData.integration_adjustment_reason || item.integration_adjustment_reason || undefined;
          item.received_by_user_id = formData.received_by_user_id || item.received_by_user_id || undefined;

          items.push({ item, itemName });
        }
        return items;
      }, []);
      return adjustments.concat(categoryItems);
    }, []);
  }

  onAdjust(adjustments, groupValues) {
    const payload = this.preparePayload(adjustments, groupValues);

    // If compliance enforces tagging, check that adjusted packages have a tag
    if (get(this.props.complianceSettings, 'inv_packages_require_tracking_id', false)) {
      if (Array.isArray(adjustments) && adjustments.length > 0) {
        const notCompliant = adjustments.some((item) => {
          return complianceSettings.isInvPackageTrackingNotInCompliance(item.item);
        });
        if (notCompliant) {
          return this.props.actions.addMessage(
            messageTypes.error,
            get(this.props.complianceSettings, 'inv_packages_require_tracking_id_message', ''),
            true
          );
        }
      }
    }

    if (this.isOverReservedQuantity(payload.adjustments)) {
      this.setState({
        payload: payload,
        isZeroQty: payload.adjustments.filter((item) => Math.sign(item.quantity) === 0).length > 0,
        adjustments: adjustments,
        showConfirmModal: true
      });
    } else if (!this.state.showConfirmModal) {
      this.saveData(payload, adjustments);
    }
  }

  preparePayload(adjustments, groupValues) {
    const {roundQty} = this.props;

    return {
      adjustments: adjustments.map((adjustment) => ({
        item_id: adjustment.item.id,
        transacted_qty: roundQty(adjustment.item.quantity - adjustment.item.qty),
        over_qty: roundQty(adjustment.item.quantity - get(adjustment, 'item.qty_reserved', 0)),
        quantity: roundQty(adjustment.item.quantity),
        integration_adjustment_reason: adjustment.item.integration_adjustment_reason || groupValues.integration_adjustment_reason,
        adjustment_reason: adjustment.item.adjustment_reason || groupValues.adjustment_reason,
        notes: adjustment.item.notes,
        add_back_to_parent: adjustment.item.add_back_to_parent,
        received_by_user_id: adjustment.item.received_by_user_id || groupValues.received_by_user_id
      }))
    };
  }

  isOverReservedQuantity(payload) {
    let overReservedQuantity = false;
    payload.map((item) => {
      if (!overReservedQuantity && Math.sign(item.over_qty) <= 0) {
        overReservedQuantity = true;
      }
    });

    return overReservedQuantity;
  }

  saveData(payload, adjustments) {
    this.setState({ saving: true });

    this.props.actions.getItem(
      '/api/customers/compliance_settings/validate_inventory',
      null,
      null,
      null,
      (response) => {
        const transacted_qty = payload.adjustments.reduce((acc, item) => acc + parseInt(item.transacted_qty), 0);
        if (transacted_qty > 0 && response.messages && !response.messages.passed) {
          return this.props.actions.addMessage(messageTypes.error, response.messages.error_message, true);
        }

        this.props.actions
          .postItem(
            '/api/reconciliations/adjust',
            payload,
            itemNames.reconciliation,
            { success: 'reconciliation.adjust.success', failed: 'reconciliation.adjust.failed' },
            {},
            () => {
              const initialValues = Object.assign({}, this.props.initialValues);
              adjustments.forEach((adjustment) => {
                //this.props.actions.change('reconciliation', `${adjustment.itemName}.total`, adjustment.item.quantity);
                this.props.actions.change('reconciliation', `${adjustment.itemName}.qty`, adjustment.item.quantity);
                this.props.actions.change('reconciliation', `${adjustment.itemName}.discrepancy`, '0.00');
                set(initialValues, `${adjustment.itemName}.qty`, adjustment.item.quantity);
                set(initialValues, `${adjustment.itemName}.quantity`, adjustment.item.quantity);
              });
              this.setState({ saving: false, initialValues });
            }
          )
          .catch(() => this.setState({ saving: false }));
      }
    );
  }

  render() {
    const {
      totalResults,
      initialValues,
      integrationState,
      adjustmentReasons,
      showReverseToParent,
      formUpdateInProgress,
      isAllowNegativeInventory,
      integrationAdjustmentReasons,
      shouldDisplayReceivedByDropdown,
      facilityUsers,
      actions,
      prepackWeights,
      complianceSettings,
      useEntityLocks,
      isFeatureEnabledInventoryAuditImport
    } = this.props;
    const { tabs, ready, saving, isZeroQty, activeTabEventKey, auditImportModeEnabled } = this.state;
    const overlayMessage = I18n.t(`reconciliation.${saving ? 'save' : 'load'}`);

    const data = initialValues.categories && initialValues.categories.length
      ? initialValues.categories.reduce((acc, category) => {
        if (category.id === Number(activeTabEventKey)) return category.items;
      }, [])
      : [];

    const tableData = {
      ref: this.ref,
      tabs,
      activeTab: activeTabEventKey,
      switchTab: this.onTabChange,
      onSearchChange: this.handleSearch,
      data: data,
      dataTotalSize: totalResults,
      externalSearch: this.handleSearch
    };

    return (
      <FormWrapper title='reconciliation.header' goBack={this.redirect}>
        <InProgressOverlay isActive={saving || !ready} message={overlayMessage} />
        <ModalWrapper
          Component={false}
          version={2}
          headerClass='bg-info-dark'
          dialogClassName='modal-sm'
          showModal={this.state.showConfirmModal}
          title='reconciliation.warning.title'
          cancelButton={{
            show: true,
            text: 'general.cancel',
            variant: 'default'
          }}
          onHide={() => {
            this.setState({ showConfirmModal: false });
          }}
          okayButton={{
            show: isAllowNegativeInventory || isZeroQty,
            text: I18n.t('general.continue'),
            variant: 'default',
            onClick: () => {
              this.setState({ showConfirmModal: false });
              this.saveData(this.state.payload, this.state.adjustments);
            }
          }}
        >
          <div>{I18n.t('reconciliation.warning.message')}</div>
        </ModalWrapper>

        <ReconciliationFormWrapper
          onRedirect={this.redirect}
          onSubmit={this.onSubmit}
          onAdjust={this.onAdjust}
          initialValues={initialValues}
          adjustmentReasons={adjustmentReasons}
          integrationAdjustmentReasons={integrationAdjustmentReasons}
          integrationState={integrationState}
          showReverseToParent={showReverseToParent}
          activeTab={activeTabEventKey}
          activeTabEventKey={activeTabEventKey}
          tableData={tableData}
          formUpdateInProgress={formUpdateInProgress}
          auditImportModeEnabled={auditImportModeEnabled}
          shouldDisplayReceivedByDropdown={shouldDisplayReceivedByDropdown}
          facilityUsers={facilityUsers}
          actions={actions}
          prepackWeights={prepackWeights}
          complianceSettings={complianceSettings}
          useEntityLocks={useEntityLocks}
          isFeatureEnabledInventoryAuditImport={isFeatureEnabledInventoryAuditImport}
          changeTab={this.changeTab}
        />
      </FormWrapper>
    );
  }
}

AdjustReconciliationsPage.propTypes = {
  actions: PropTypes.shape({
    getUnpaginatedData: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired,
    addMessage: PropTypes.func.isRequired
  }).isRequired,
  initialValues: PropTypes.object,
  adjustmentReasons: PropTypes.array.isRequired,
  integrationAdjustmentReasons: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
  showReverseToParent: PropTypes.bool,
  itemsLookup: PropTypes.array,
  isAllowNegativeInventory: PropTypes.bool,
  formUpdateInProgress: PropTypes.bool,
  shouldDisplayReceivedByDropdown: PropTypes.bool.isRequired,
  facilityUsers: PropTypes.array
};

function mapStateToProps(state) {
  const formCategories = formValueSelector('reconciliation')(state, 'categories');
  const itemsLookup = formCategories && formCategories.length ? formCategories[0].items : [];
  const formUpdateInProgress = Boolean(itemsLookup.find((item) => item.discrepancy && parseFloat(item.discrepancy)));

  const shouldDisplayReceivedByDropdown = itemsLookup.some((item) => {
    return leafWaReconciliationReasonsRequiringReceivedBy.includes(item.integration_adjustment_reason);
  });

  return {
    roundQty: roundQtyCurried(state),
    initialValues: getReconciliationInitialValues(state),
    adjustmentReasons: state[dataNames.adjustmentReasons],
    integrationAdjustmentReasons: getIntegrationAdjustmentReasonOptions(state),
    integrationState: getIntegrationState(state),
    showReverseToParent: showReverseToParent(state),
    categories: state[dataNames.categories],
    inventoryItems: state[dataNames.inventoryItems],
    tableData: state[itemNames.columnVisibilitySettings],
    totalResults: getTotalResults(state, { name: dataNames.inventoryItems }),
    isAllowNegativeInventory: complianceSettings.isAllowNegativeInventory(state),
    itemsLookup,
    formUpdateInProgress,
    shouldDisplayReceivedByDropdown,
    facilityUsers: state[dataNames.users],
    prepackWeights: state[dataNames.prepackWeights],
    complianceSettings: complianceSettings.getInventoryComplianceSettings(state),
    useEntityLocks: getUseEntityLocksForItems(state),
    isFeatureEnabledInventoryAuditImport: isFeatureEnabled(state)('feature_inventory_audit_import')
  };
}

function mapDispatchToProps(dispatch) {
  const actions = { goBack, getUnpaginatedData, postItem, getItem, unsetData, change, addMessage, getSearchData, setData, getDataByPost, setItem };
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdjustReconciliationsPage);
