import axios from 'axios';
import get from 'lodash.get';

class RequestManager {
  constructor() {
    this.requestHooks = {};
    this.responseHooks = {};
  }

  create(options) {
    const request = axios.create();
    const key = get(options, 'key', 'default');
    const requests = this.requestHooks[key] || [];
    for (const hook of requests) {
      const { intercept, exception } = hook;
      request.interceptors.request.use(
        intercept,
        exception
      );
    }

    const responses = this.responseHooks[key] || [];
    for (const hook of responses) {
      const { intercept, exception } = hook;
      request.interceptors.response.use(
        intercept,
        exception
      );
    }
    
    return request;
  }

  before(key, intercept, exception) {
    const hooks = this.requestHooks[key] || [];
    hooks.push({
      intercept: intercept,
      exception: exception
    });
    this.requestHooks[key] = hooks;
  }

  after(key, intercept, exception) {
    const hooks = this.responseHooks[key] || [];
    hooks.push({
      intercept: intercept,
      exception: exception
    });
    this.responseHooks[key] = hooks;
  }

  error(error) {
    const response = error.response || null;
    const errors = {};
    let message = error.message;
    if (response) {
      const body = response.data;
      if (response.status === 422) {
        message = 'Request had a validation error';
        const validations = get(body, 'errors.VALIDATION', {});
        for (const key in validations) {
          const messages = validations[key];
          errors[key] = messages.join('<br/>');
        }
      }
      message = get(body, 'message', message);
    }
    
    return {
      message: message,
      errors: errors,
      response: response,
      exception: error //Send original just in case
    };
  }

  catchErrors(error) {
    return Promise.reject(this.error(error));
  }

  getBody(response) {
    return response.data || null;
  }
}

class AuthRequestManager extends RequestManager {
  create(options) {
    const defaults = {
      key: 'auth'
    };
    return super.create(Object.assign({}, defaults, options));
  }

  setToken(intercept, exception) {
    super.before('auth', intercept, exception);
  }

  swapToken(intercept, exception) {
    super.after('auth', intercept, exception);
  }
}


const Request = new RequestManager();
const AuthRequest = new AuthRequestManager();
export { Request, AuthRequest };