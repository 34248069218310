import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';
import { requiredFieldValidation } from '../../../common/form/redux-form/validations';

const validate = (values, props) => {
  const isAlleaves = get(props, 'integration.name') === 'integration_alleaves_settings';
  const errors = {
    api_key: !isAlleaves ? requiredFieldValidation(values.api_key, 'common.apiKeyRequired') : null,
  };

  if (isAlleaves) {
    for (const key in values.terminals) {
      if (!values.terminals[key].tpn) {
        if (!errors.hasOwnProperty('terminals')) {
          errors.terminals = [];
        }
        errors.terminals[key] = {tpn: I18n.t('common.form.isRequired', {fieldName: I18n.t('facility.setup.thirdPartyIntegration.alleaves.tpn')})};
      }
      if (!values.terminals[key].register_id) {
        if (!errors.hasOwnProperty('terminals')) {
          errors.terminals = [];
        }
        errors.terminals[key] = {register_id: I18n.t('common.form.isRequired', {fieldName: I18n.t('facility.setup.thirdPartyIntegration.alleaves.register_id')})};
      }
      if (!values.terminals[key].authkey) {
        if (!errors.hasOwnProperty('terminals')) {
          errors.terminals = [];
        }
        errors.terminals[key] = {tpn: I18n.t('common.form.isRequired', {fieldName: I18n.t('facility.setup.thirdPartyIntegration.alleaves.authkey')})};
      }
      if (!values.terminals[key].mode) {
        if (!errors.hasOwnProperty('terminals')) {
          errors.terminals = [];
        }
        errors.terminals[key] = {tpn: I18n.t('common.form.isRequired', {fieldName: I18n.t('facility.setup.thirdPartyIntegration.alleaves.mode')})};
      }
      if (!values.terminals[key].receipt) {
        if (!errors.hasOwnProperty('terminals')) {
          errors.terminals = [];
        }
        errors.terminals[key] = {tpn: I18n.t('common.form.isRequired', {fieldName: I18n.t('facility.setup.thirdPartyIntegration.alleaves.receipt')})};
      }
    }
  }

  return errors;
};

export default validate;
