import {I18n} from 'react-redux-i18n';
import {maxValidation, minValidation, requiredFieldValidation} from '../../common/form/redux-form/validations';

const validate = (values, {loading, packagedWeight, isEachPackaging, integrationState: {isMetrc}}) => {
  const packages = (values && values.packages || []).map(() => ({}));
  if (!packages.length && !loading) {
    packages._error = I18n.t('packaging.form.noSeedProductFound');
  } else if (!loading && !packagedWeight) {
    packages._error = I18n.t('packaging.form.selectAtLeastOneProduct');
  }
  const qty = values.item && values.item.qty || 0;
  const qty_available = values.item && values.item.qty_available || 0;

  const validators = {
    packages,
    storage_location_id: values.storage_location_id ? null : I18n.t('packaging.form.selectStorageLocation'),
    completed_at: values.completed_at ? null : I18n.t('packaging.form.enterPackagingTime'),

    remaining_weight: requiredFieldValidation(values.remaining_weight) ||
      maxValidation(values.remaining_weight, 'packages.prePack.remainingWeight', qty_available) ||
      minValidation(values.remaining_weight, 'packages.prePack.remainingWeight', qty - qty_available),
  };

  if (isEachPackaging && isMetrc) {
    validators['state_integration_tracking_id'] = requiredFieldValidation(values.state_integration_tracking_id, 'common.form.trackingId');
  }

  return validators;
};

export default validate;
