import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import TrWrapper from './TrWrapper';
import RenderPrevWeightHistory from './RenderPrevWeightHistory';
import RenderCurrWeightHistory from './RenderCurrWeightHistory';

const SaleRowByOrgLevel = (props) => {
  const {prefix, delta} = props;
  const defaultPrice = delta.find(row => row.name === `${prefix}_default_price`);
  const inheritClass = delta.find(row => row.name === `${prefix}_inherit_pricing_class_updates`);
  const inheritGroup = delta.find(row => row.name === `${prefix}_inherit_pricing_group_updates`);
  const isNonTaxable = delta.find(row => row.name === `${prefix}_is_non_taxable`);
  const groupName = delta.find(row => row.name === `${prefix}_group_name`);
  const className = delta.find(row => row.name === `${prefix}_class_name`);
  const weightPrices = delta.find(row => row.name === `${prefix}_weight_prices`);
  const facilities = delta.find(row => row.name === `${prefix}_applicable_facilities`);

  return (<TrWrapper>
    {defaultPrice ?
      <tr>
        <td>
          {I18n.t(defaultPrice.label)}
        </td>
        <td>
          {defaultPrice.prev} → {defaultPrice.current}
        </td>
      </tr>
      : null}
    {isNonTaxable ?
      <tr>
        <td>
          {I18n.t(isNonTaxable.label)}
        </td>
        <td>
          {isNonTaxable.prev} → {isNonTaxable.current}
        </td>
      </tr>
      : null}
    {groupName ?
      <tr>
        <td>
          {I18n.t(groupName.label)}
        </td>
        <td>
          {groupName.prev} → {groupName.current}
        </td>
      </tr>
      : null}
    {className ?
      <tr>
        <td>
          {I18n.t(className.label)}
        </td>
        <td>
          {className.prev} → {className.current}
        </td>
      </tr>
      : null}
    {inheritClass ?
      <tr>
        <td>
          {I18n.t(inheritClass.label)}
        </td>
        <td>
          {inheritClass.prev} → {inheritClass.current}
        </td>
      </tr>
      : null}
    {inheritGroup ?
      <tr>
        <td>
          {I18n.t(inheritGroup.label)}
        </td>
        <td>
          {inheritGroup.prev} → {inheritGroup.current}
        </td>
      </tr>
      : null}
    {facilities ?
      <tr>
        <td>
          {I18n.t(facilities.label)}
        </td>
        <td>
          {facilities.prev} → {facilities.current}
        </td>
      </tr>
      : null}
    {weightPrices ?
      <TrWrapper>
        <RenderPrevWeightHistory field={weightPrices}/>
        <RenderCurrWeightHistory field={weightPrices}/>
      </TrWrapper>
      : null}
  </TrWrapper>);
};

SaleRowByOrgLevel.propTypes = {
  delta: PropTypes.array.isRequired,
  prefix: PropTypes.string.isRequired
};

export default SaleRowByOrgLevel;
