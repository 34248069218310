import { createSelector } from 'reselect';
import * as UOMS from '../constants/uoms';
import {convertFromBase} from '../util/uomHelpers';
import {getItemMasters} from './itemMastersSelectors';

export const getFeedingSchedule = (state) => {
  if (state.feedingSchedule['amount_per_feeding_base']) {
    state.feedingSchedule['amount_per_feeding'] = convertFromBase(state.feedingSchedule['amount_per_feeding_base'], state.feedingSchedule['amount_uom_display'] || UOMS.L);
  }

  if (state.feedingSchedule['ingredients'] && state.feedingSchedule['ingredients'].length) {
    state.feedingSchedule['ingredients'] = state.feedingSchedule['ingredients'].map(ing => ({
      ...ing,
      qty: ing.qty || convertFromBase(ing['qty_base'], ing['uom'] || ing['uom_display'])
    }));
  }

  if (state.feedingSchedule['time_base'] && state.feedingSchedule['time_uom_display']) {
    state.feedingSchedule['time'] = convertFromBase(state.feedingSchedule['time_base'], state.feedingSchedule['time_uom_display']);
  }

  return state.feedingSchedule;
};
export const getFeedingSchedules = (state) => state.feedingSchedules;
const getSelectedFeedingSchedules = state => state.selectedFeedingSchedules;
const getFeedingScheduleId = (_, props) => props.feeding_schedule_id;
const getFilters = (_, props) => props.filters;
const getSections = state => state.sections;

export const getFeedingScheduleById = createSelector(
  [getFeedingSchedules, getFeedingScheduleId],
  (feedingSchedules, feedingScheduleId) => {
    return feedingSchedules.reduce((previous, current) => {
      return feedingScheduleId === current.id ? current : previous;
    }, null);
  }
);


export const getFilteredFeedingSchedules = createSelector([getFeedingSchedules, getFilters],(schedules, filters) => {
  if(schedules){
    const filteredSchedules = schedules.filter(schedule => {
      let locFound = true;
      let stageFound = true;
      let locsFound = true;
      if(filters.location_id){
        if (Array.isArray(filters.location_id)) {
          locFound = schedule.locations && schedule.locations.find(loc => filters.location_id.indexOf(loc.location_id) !== -1) ? true : false;
        } else {
          locFound = schedule.locations && schedule.locations.find(loc => loc.location_id == filters.location_id) ? true : false;
        }
      }
      if(filters.stage_id){
        stageFound = schedule.stages && schedule.stages.find(stage => stage.stage_id == filters.stage_id || stage.stage_id == 0) ? true : false;
      }
      if(filters.location_ids){
        locsFound = filters.location_ids.reduce((acc, val) => acc && Boolean(schedule.locations && schedule.locations.find(location => location.location_id == val)), true);
      }
      return locFound && stageFound && locsFound;
    });
    return filteredSchedules;
  }else{
    return [];
  }
});

export const getSelectedFeedingSchedulesIds = createSelector(getSelectedFeedingSchedules, selectedFeedingSchedules => selectedFeedingSchedules.map(selectedFeedingSchedule => selectedFeedingSchedule.id));

export const getFeedingSchedulesItemMasterIds = createSelector(
  [getFeedingSchedules],
  schedules => schedules.reduce(
    (ids, schedule) => schedule.ingredients.reduce(
      (ids, ingredient) => {
        if (ids.indexOf(ingredient.item_master_id) === -1) {
          ids.push(ingredient.item_master_id);
        }
        return ids;
      },
      ids
    ),
    []
  )
);

export const getFeedingSchedulesWithIngredients = createSelector(
  [getFeedingSchedules, getItemMasters],
  (schedules, itemMasters) => schedules.map((schedule) => ({
    ...schedule,
    ingredients: schedule.ingredients.map((ingredient) => {
      const item_master = itemMasters.find(im => im.id === ingredient.item_master_id);
      return {
        ...ingredient,
        item_master
      };
    })
  }))
);


export const getFeedingScheduleLocations = createSelector([getSections, getFeedingSchedule], (sections, feedingSchedule) => {
  const feedingSchedulesSections = sections;
  if (feedingSchedule.locations) {
    feedingSchedule.locations.map(feedingScheduleLocation => {
      const section = feedingSchedulesSections.find(section => section && section.id === feedingScheduleLocation.location_id);
      if (!section && feedingScheduleLocation.location) {
        feedingSchedulesSections.push(feedingScheduleLocation.location);
      }
    });
  }
  return feedingSchedulesSections;
});
