import { setData } from './dataActions';
import * as dataNames from '../constants/dataNames';
import * as apiActions from './apiActions';

export function mergePartner(partner) {
  return (dispatch, getState) => {
    const partners = getState()[dataNames.partners].map((item) => {
      if (partner.id === item.id) {
        return { ...item, ...{ isMerged: !item.isMerged } };
      }
      return item;
    });
    dispatch(setData(partners, dataNames.partners));
  };
}

/**
 * Load list of partners.
 * Doesn't support pagination
 * @param params
 * @returns {function(*): *}
 */
export const fetchPartners = (params) => (dispatch) => {
  const messages = {
    failed: 'partners.get.failed'
  };

  return dispatch(apiActions.getUnpaginatedData('/api/partners', dataNames.partners, messages, params));
};

export const fetchPartnerFacilities = (params) => (dispatch) => {
  const messages = {
    failed: 'partners.get.failed'
  };

  return dispatch(
    apiActions.getUnpaginatedData('/api/partner_facilities', dataNames.partnerFacilities, messages, params)
  );
};

export const dummy = () => {};
