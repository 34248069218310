import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import cln from 'classnames';
import DoubleFieldInfoColumn from './DoubleFieldInfoColumn';

class DoubleFieldInfo extends React.PureComponent {
  constructor(props) {
    super(props);

    this.renderIsolocityStatus = this.renderIsolocityStatus.bind(this);
  }

  renderIsolocityStatus() {
    const { isIsolocity, isolocityStatus } = this.props;
    const className = cln('block', {
      'text-success': (isolocityStatus === 'passed'),
      'text-danger': (isolocityStatus === 'failed'),
      'text-muted': (isolocityStatus === 'in progress'),
    });

    return (isIsolocity && isolocityStatus) && (
      <Col sm={12}>
        <Row>
          <Col md={5}>
            <div className='readonly-input-group'>
              <label>{I18n.t('cultivation.testResults.isolocity.title')}</label>
              <dd className={className}>{isolocityStatus}</dd>
            </div>
          </Col>
        </Row>
      </Col>
    );
  }

  render() {
    const { row, editable, disabled, columns, requiredDimensions, children, onChange, change, isPerServingMode, servingsPerContainer, servingsUom } = this.props;

    return (
      <Row className='double-fields-info'>
        {this.renderIsolocityStatus()}
        {columns.map((column, index) => {
          const isRequired = requiredDimensions && (requiredDimensions.includes(`${column.key}_percent`) || requiredDimensions.includes(`${column.key}_mg_g`));
          return (
            <DoubleFieldInfoColumn key={index}
                                   row={row}
                                   column={column}
                                   editable={editable}
                                   disabled={disabled}
                                   onChange={onChange}
                                   change={change}
                                   isRequired={isRequired}
                                   isPerServingMode={isPerServingMode}
                                   servingsPerContainer={servingsPerContainer}
                                   servingsUom={servingsUom}
            />
          );
        })}
        {children}
      </Row>
    );
  }
}

DoubleFieldInfo.propTypes = {
  row: PropTypes.object,
  isolocityStatus: PropTypes.string,
  columns: PropTypes.array.isRequired,
  editable: PropTypes.bool.isRequired,
  requiredDimensions: PropTypes.array,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  change: PropTypes.func,
  isPerServingMode: PropTypes.bool,
  servingsPerContainer: PropTypes.string,
  servingsUom: PropTypes.string
};

export default DoubleFieldInfo;
