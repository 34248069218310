/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack} from 'react-router-redux';
import {change, SubmissionError} from 'redux-form';

import ActivateDeactivateTrackingIdsForm from './ActivateDeactivateTrackingIdsForm';
import FormWrapper from '../../common/form/FormWrapper';
import validate from './validateActivateDeactivate';
import Notice from '../../common/notice';
import * as dataNames from '../../../constants/dataNames';
import * as apiActions from '../../../actions/apiActions';
import {error, success} from '../../../constants/messageTypes';
import {addMessage, startSpinner, stopSpinner} from '../../../actions/systemActions';
import {AuthRequest} from '../../../managers/request';

export class ActivateDeactivateTrackingIdsPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.redirect = this.redirect.bind(this);
    this.getTrackingId = this.getTrackingId.bind(this);
    this.getIdsFromRange = this.getIdsFromRange.bind(this);
    this.validateTags = this.validateTags.bind(this);
    this.updateTrackingTagGroupType = this.updateTrackingTagGroupType.bind(this);
    this.updateTrackingTagGroup = this.updateTrackingTagGroup.bind(this);
    this.showTypeUpdateWarning = this.showTypeUpdateWarning.bind(this);
    this.generateTypeWarningMessage = this.generateTypeWarningMessage.bind(this);
    this.request = AuthRequest.create();

    this.state = {
      trackingTagGroup: {},
      trackingTagGroupType: '',
    };
  }

  componentWillMount() {
    const {params: {id}} = this.props;

    // note the query so that we can also display which tags are active if the user decides to update the type
    this.request.get(`/api/metrc/tracking_tag_groups/${id}?with_used_tags=1`).then((response) => {
      this.setState({
        trackingTagGroup: response.data,
        trackingTagGroupType: response.data.type
      });
    });

    this.props.actions.getData(
      '/api/metrc/tracking_tags',
      dataNames.trackingIds,
      {failed: 'tracking.getTrackingIds.failed'},
      {group_id: id, page: 1, per_page: 100}
    );
  }

  getTrackingId(tag) {
    return new Promise(resolve => {
      this.props.actions.getItem(
        `/api/metrc/tracking_tags/by_tag/${tag}`,
        undefined,
        {failed: 'tracking.getTrackingId.failed'},
        undefined,
        trackingId => resolve(trackingId)
      ).catch(e => resolve());
    });
  }

  redirect() {
    this.props.actions.goBack();
  }

  getIdsFromRange(start, end) {
    const ids = [];

    // Maybe we should display an error when end < start.
    if (end && end < start) {
      return [start];
    }
    for (let i = start; i <= end; i++) {
      ids.push(i);
    }
    return ids;
  }

  validateTags(formValues) {
    // Determine whether starting_tag and ending_tag (if set) exist in the current tag group
    const {params: {id}} = this.props;
    const tagIds = {};
    const promises = [this.getTrackingId(formValues.starting_tag)];
    if (formValues.ending_tag) {
      promises.push(this.getTrackingId(formValues.ending_tag));
    }
    return Promise.all(promises).then(results => {
      const startingTrackingId = results[0];
      if (startingTrackingId && startingTrackingId.group_id == id) {
        tagIds.startingTagId = startingTrackingId.id;
      }
      else {
        return {errorField: 'starting_tag'};
      }
      if (formValues.ending_tag) {
        const endingTrackingId = results[1];
        if (endingTrackingId && endingTrackingId.group_id == id) {
          tagIds.endingTagId = endingTrackingId.id;
        }
        else {
          return {errorField: 'ending_tag'};
        }
      }
      return tagIds;
    });
  }

  updateTrackingTagGroupType(type) {
    this.setState({
      trackingTagGroupType: type
    });
  }

  // Update integration.metrc_tracking_tag_groups.type since the type is set at the group level not tag level
  updateTrackingTagGroup() {
    const payload = {
      type: this.state.trackingTagGroupType
    };

    this.props.actions.startSpinner();
    this.request.put(`/api/metrc/tracking_tag_groups/${this.props.params.id}`, payload)
      .then(() => {
        this.props.actions.addMessage(success, I18n.t('tracking.activateDeactivateIds.form.updateTrackingTagGroupTypeSuccess'));
        this.redirect();
      })
      .catch(() => {
        this.props.actions.addMessage(error, I18n.t('tracking.activateDeactivateIds.form.updateTrackingTagGroupTypeFailed'));
      }).then(() => {
        this.props.actions.stopSpinner();
      });
  }

  onSubmit(formValues) {
    return this.validateTags(formValues).then(tagIds => {
      if (tagIds.errorField) {
        const error = {};
        if (tagIds.errorField === 'starting_tag') {
          error.starting_tag = I18n.t('tracking.activateDeactivateIds.form.beginningIdInvalidForGroup');
          error._error = I18n.t('tracking.activateDeactivateIds.form.beginningIdInvalidForGroup');
        }
        else {
          error.ending_tag = I18n.t('tracking.activateDeactivateIds.form.endingIdInvalidForGroup');
          error._error = I18n.t('tracking.activateDeactivateIds.form.endingIdInvalidForGroup');
        }
        throw new SubmissionError(error);
      }

      const start = tagIds && tagIds.startingTagId;
      const end = tagIds && tagIds.endingTagId;
      if (start) {
        const ids = this.getIdsFromRange(start, end || start);
        if (ids && ids.length) {
          const payload = {
            is_inactive: formValues.action === 'deactivate' ? true : false,
            ids
          };
          this.props.actions.postItem(
            '/api/metrc/tracking_tags/update',
            payload,
            undefined,
            {failed: 'tracking.setTrackingIdStatuses.failed', success: 'tracking.setTrackingIdStatuses.success'},
            undefined,
            this.redirect
          );
        }
      }
    });
  }

  // If the tag group type is being changed and the group has tags that are currently in use, we should warn them, but not prevent this
  showTypeUpdateWarning() {
    const trackingTagGroup = this.state.trackingTagGroup;
    const newTrackingTagGroupType = this.state.trackingTagGroupType;

    const activeTags = trackingTagGroup.tags || [];
    const typeChanged = trackingTagGroup ? trackingTagGroup.type !== newTrackingTagGroupType : false;

    // No need to warn them of anything if the type hasn't changed or if none of the tags in the group are active
    return activeTags.length && typeChanged;

  }

  // List out the tracking ids that are currently active along with a warning that they will be affected
  generateTypeWarningMessage() {
    const trackingTagGroup = this.state.trackingTagGroup;
    const usedTags = [];
    trackingTagGroup.tags.forEach(tracking_tag => usedTags.push(tracking_tag.tag));
    return I18n.t('tracking.activateDeactivateIds.form.updateTrackingTagGroupTypeWarning') + usedTags.join(', ');
  }

  render() {
    const {trackingIds} = this.props;

    let notice = null;
    if (this.showTypeUpdateWarning()) {
      notice = <Notice type='danger' title={this.generateTypeWarningMessage()} />;
    }

    return (
      <div className='activate-deactivate-tracking-ids-page'>
        <FormWrapper title='tracking.activateDeactivateIds.title' goBack={this.redirect} className='activate-deactivate-tracking-ids-form-wrapper'>
          {notice}
          <ActivateDeactivateTrackingIdsForm
            onSubmit={this.onSubmit}
            validate={validate}
            trackingIds={trackingIds}
            trackingTagGroupType={this.state.trackingTagGroupType}
            handleTrackingTagGroupTypeChange={this.updateTrackingTagGroupType}
            handleTrackingTagGroupSave={this.updateTrackingTagGroup}
          />
        </FormWrapper>
      </div>
    );

  }
}

ActivateDeactivateTrackingIdsPage.propTypes = {
  actions: PropTypes.shape({
    change: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    getData: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    addMessage: PropTypes.func.isRequired,

  }),
  params: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  trackingIds: PropTypes.array.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    trackingIds: state.trackingIds,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {change, goBack, addMessage, startSpinner, stopSpinner});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivateDeactivateTrackingIdsPage);
