import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Button} from 'react-bootstrap';
import {goBack} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import {bindActionCreators} from 'redux';
import RouteButton from '../common/RouteButton';

class SetupMode extends React.PureComponent {

  constructor(props, context) {
    super(props, context);

  }

  render() {
    return (
      <div>
        <h2>
          {I18n.t('integration.setupMode.header')}
        </h2>
        <p>
          {I18n.t('integration.setupMode.body')}
        </p>
        <hr/>
        <RouteButton props={{variant:'success', className:'float-right'}} path='/setup/integration'>{I18n.t('integration.setupMode.fix')}</RouteButton>
        <Button variant='primary' onClick={this.props.actions.goBack}>{I18n.t('integration.setupMode.goBack')}</Button>
      </div>
    );
  }
}

SetupMode.propTypes = {
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state){

  return {

  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({goBack}, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (SetupMode);
