import {I18n} from 'react-redux-i18n';
import {requiredFieldValidation} from '../../common/form/redux-form/validations';

const validate = (values, props) => {
  const errors = {};
  errors.transfer_to = requiredFieldValidation(values.transfer_to, 'transferTo');
  const amount = parseFloat(values.transacted_amount || 0);
  errors.transacted_amount = amount ? false : requiredFieldValidation(false, 'amount');
  if (amount && (props.currentRegister.current_level - amount) < 0) {
    errors.transacted_amount = I18n.t('registers.transferCash.amountMoreThenBalanceError');
  }
  errors.reason_code = requiredFieldValidation(values.reason_code, 'reason');
  errors.register_name = props.currentRegister.id ? false : I18n.t('registers.transferCash.registerShouldBeSelected');
  return errors;
};

export default validate;