import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';
import {FaMinus, FaPlus} from 'react-icons/fa';
import {TAX_PROFILE_FORM} from '../../../../../constants/forms';
import {getTaxDefinitionIdsInUse} from '../../../../../selectors/forms/taxProfileFormSelectors';
import * as dataNames from '../../../../../constants/dataNames';
import {getOrderFulfillmentOptions}  from '../../../../../constants/orderFulfillmentMethods';
import ReactSelectInput from '../../../../common/form/ReactSelectInput';
import InternationalCurrencyStatic from '../../../../common/form/InternationalCurrencyStatic';
import InternationalDecimalStatic from '../../../../common/form/InternationalDecimalStatic';

export const TaxGroupItems = (props) => {

  const {fields, items, taxDefinitionIdsInUse, taxDefinitions, categories} = props;

  const onRemoveItem = (index) => {
    fields.remove(index);
  };

  const onAddItem = () => {
    fields.push({});
  };

  const showAddItem = () => {
    const itemsWithoutTaxIds = items.filter((item) => !item.tax_id || isNaN(parseInt(item.tax_id)));
    return !itemsWithoutTaxIds.length && taxDefinitionIdsInUse.length < taxDefinitions.length;
  };

  const orderFulfillmentOptions = getOrderFulfillmentOptions();

  return (<tbody>
  {
    fields.map((item, index) => {
      const itemData = items[index];
      const taxId = parseInt(itemData.tax_id);
      const taxDefinition = taxDefinitions.find((def) => def.id === itemData.tax_id);
      const taxCategories = taxDefinition
        ? taxDefinition.item_category_ids.length > 0
          ? taxDefinition.item_category_ids.map((categoryId) => {
            const c = categories.find((cat) => cat.id === categoryId);
            return (c) ? c.name : null;
          })
          : ['N/A']
        : categories.map((cat) => cat.name);
      const fulfillmentMethods = taxDefinition
        ? taxDefinition.fulfillment_methods.length > 0
          ? taxDefinition.fulfillment_methods.map((methodCode) => {
            const m = orderFulfillmentOptions.find((option) => option.value === methodCode);
            return (m) ? m.text : null;
          })
          : ['N/A']
        : orderFulfillmentOptions.map((f) => f.text);
      return (<tr key={index}>
        <td style={{width: '30px', textAlign: 'center', verticalAlign: 'middle', position: 'relative'}} >
          <FaMinus
            className={fields.length < 2 ? 'text-muted' : 'text-danger'}
            style={fields.length > 1 ? {cursor: 'pointer'} : {}}
            onClick={() => {
              if(fields.length < 2) return false;
              onRemoveItem(index);
            }}
          />
          <FaPlus
            className='text-primary'
            style={(fields.length === (index + 1) && showAddItem()) ? {cursor: 'pointer', position: 'absolute', top: '70px', left: '8px'} : {display: 'none'}}
            onClick={() => {
              onAddItem();
            }}
          />
        </td>
        <td className='form-group-no-margin'>
          <Field
            name={`${item}.tax_id`}
            component={ReactSelectInput}
            props={{
              options: taxDefinitions.filter((item) => taxDefinitionIdsInUse.indexOf(item.id) === -1 || item.id === itemData.tax_id),
              textKey: 'tax_name',
              valueKey: 'id',
              placeholder: 'Select One... (optional)'
            }}
          />
          {
            isNaN(taxId) || taxId < 1
              ? null
              : (<div className='text-muted' style={{fontSize: 'smaller', marginTop: '5px'}}>
              <div><strong>Applied To Product Categories:</strong> {taxCategories.join(', ')}</div>
            </div>)
          }
          {
            isNaN(taxId) || taxId < 1
              ? null
              : (<div className='text-muted' style={{fontSize: 'smaller', marginTop: '5px'}}>
              <div><strong>Applied To Fulfillment Methods:</strong> {fulfillmentMethods.join(', ')}</div>
            </div>)
          }
        </td>
        <td style={{textAlign: 'right', verticalAlign: 'middle'}}>
          {itemData.rate && !isNaN(itemData.rate) ?
            <InternationalDecimalStatic fractionPartSize={3} suffix={'%'}>{itemData.rate}</InternationalDecimalStatic>
              : 'N/A'}
        </td>
        <td style={{textAlign: 'right', verticalAlign: 'middle'}}>
          {itemData.sample && !isNaN(itemData.sample) ?
            <InternationalCurrencyStatic>{itemData.sample.toFixed(2)}</InternationalCurrencyStatic>
            : 'N/A'
          }
        </td>
      </tr>);
    })
  }
  </tbody>);

};

TaxGroupItems.propTypes = {
  idx: PropTypes.number.isRequired,
  onRemoveGroup: PropTypes.func.isRequired,
  showDivider: PropTypes.bool.isRequired,
  fields: PropTypes.object.isRequired,
  items: PropTypes.array,
  taxDefinitionIdsInUse: PropTypes.array,
  taxDefinitions: PropTypes.array,
  categories: PropTypes.array,
};

const selector = formValueSelector(TAX_PROFILE_FORM);

function mapStateToProps(state, ownProps){
  return {
    taxDefinitionIdsInUse: getTaxDefinitionIdsInUse(state),
    items: selector(state, `${ownProps.groupKey}.items`),
    taxDefinitions: state[dataNames.taxes],
    categories: state[dataNames.categories],
  };
}

export default connect(mapStateToProps)(TaxGroupItems);
