/* eslint-disable import/prefer-default-export*/
import * as actionTypes from '../constants/actionTypes';
import * as dataNames from '../constants/dataNames';
import * as itemNames from '../constants/itemNames';
import {getUnpaginatedData} from '../actions/apiActions';
import {setUserRetailLocation, setUserRetailRegister} from '../actions/userActions';
import {getCurrentLocation, getCurrentRegister} from '../selectors/usersSelectors';
import {getActiveSalesLocations} from '../selectors/salesLocationsSelectors';


export const salesRegisters = store => next => action => {
  const result = next(action);
  const {ADD_ITEM_SUCCESS, EDIT_ITEM_SUCCESS, GET_DATA_SUCCESS} = actionTypes;

  //Load sales registers on changes
  if ([ADD_ITEM_SUCCESS, EDIT_ITEM_SUCCESS].indexOf(action.type) > -1 && action.name === itemNames.register) {
    store.dispatch(getUnpaginatedData('/api/registers', dataNames.salesRegisters, undefined, {active: 1, type: 'sales'}));
  }

  const state = store.getState();
  const activeRegisterId = getCurrentRegister(state);
  const activeLocationId = getCurrentLocation(state);

  //Select proper register on changes
  if (action.type === GET_DATA_SUCCESS && action.name === dataNames.salesRegisters) {
    const registers = action.payload.data;
    const registerIsValid = activeRegisterId && registers.some(reg => reg.id === activeRegisterId);

    if (!registerIsValid) {
      if (registers.length === 1) {
        store.dispatch(setUserRetailRegister(registers[0].id));
      } else if (activeRegisterId) {
        store.dispatch(setUserRetailRegister(null));
      }
    }
  }

  //Select proper location on changes
  if ([GET_DATA_SUCCESS].indexOf(action.type) > -1 && action.name === dataNames.salesLocations) {
    const salesLocations = getActiveSalesLocations(state);
    const locationIsValid = activeLocationId && salesLocations.some(loc => loc.id === activeLocationId);

    if (!locationIsValid) {
      if (salesLocations.length === 1) {
        store.dispatch(setUserRetailLocation(salesLocations[0].id));
      } else if (activeLocationId) {
        store.dispatch(setUserRetailLocation(null));
      }
    }
  }

  return result;
};
