/* eslint-disable react/display-name */
import React from 'react';
import {ButtonGroup, Button} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import PermissionWrapper from '../../PermissionWrapper';

const MultiActionColumn = config => (cell, row) => {
  const {buttons} = config;

  return (
    <ButtonGroup className='actions-column'>
      {
        buttons.map((button, key) => {
          if (button.display == false) {
            return null;
          }

          return (
            <PermissionWrapper permissions={config.permissions} key={key}>
              <Button variant='primary' onClick={event => button.action(event, row)}>
                {I18n.t(button.label)}
              </Button>
            </PermissionWrapper>
          );
        })
      }
    </ButtonGroup>
  );
};

export default MultiActionColumn;
