const strainDominance = [
  {id: 1, value: 'hybrid', text: 'Hybrid'},
  {id: 2, value: 'indica', text: 'Indica'},
  //{id: 4, value: 'indica_hybrid', text: 'Indica Dominant Hybrid'},
  {id: 3, value: 'indica_dominant', text: 'Indica Dominant Hybrid'},
  {id: 4, value: 'sativa', text: 'Sativa'},
  //{id: 6, value: 'sativa_hybrid', text: 'Sativa Dominant Hybrid'},
  {id: 5, value: 'sativa_dominant', text: 'Sativa Dominant Hybrid'}
];

export default strainDominance;
