import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import {Link} from 'react-router';
import {Button} from 'react-bootstrap';
import ReactSelectInput from '../common/form/ReactSelectInput';
import NumberInput from '../common/form/NumericInput';

const RulesSettings = (props) => {

  const {rulesCategories, patientType, isReadOnly} = props;

  const weightFields = [
    {text: 'Net Weight', value: 'net_weight'},
    {text: 'Gross Weight', value: 'gross_weight'},
    {text: 'Medicated Weight', value: 'medicated_weight'}
  ];

  const uoms = [
    {text: 'GR', value: 'gr'},
    {text: 'MG', value: 'mg'},
    {text: 'EA', value: 'ea'}
  ];

  if(!Array.isArray(rulesCategories) || rulesCategories.length === 0){

    return (<div className='alert alert-info'>
      <p>
        <strong>Category Based Rules</strong> for sales limit enforcement requires that product categories be mapped
        to a set of rules categories.  Click the button below to go to your categories management page
        and set up your mappings.  Once saved, return here to set your rules.
      </p>
      <Link  to='/category/management?srm=1'>
        <Button variant='primary' className='float-right' style={{marginTop: '12px'}}>Add Sales Rules Mapping To Categories</Button>
      </Link>
      <div style={{clear: 'both'}} />
    </div>);

  }

  return (
    <div className='equivalency-settings-form sales-settings-form'>
      <div style={{marginBottom: '12px'}}>
        {I18n.t('retail.salesSettings.rulesHint')}
      </div>
      <table className='table table-sm table-bordered'>
        <thead>
        <tr>
          <th style={{width:'25%'}}>Category</th>
          <th style={{width:'25%'}}>Quantity Limit</th>
          <th style={{width:'25%'}}>Unit Of Measure</th>
          <th style={{width:'25%'}}>Weight Field</th>
        </tr>
        </thead>
        <tbody>
        {
          rulesCategories.map((category, index) => {
            return (
              <tr key={index}>
                <td style={{verticalAlign: 'middle'}}>{category.text}</td>
                <td>
                  <Field
                    name={`${patientType}_qty__${category.value}`}
                    component={NumberInput}
                    props={{
                      style: {width: '100%', margin: '0'},
                      placeholder: '',
                      step:'any',
                      disabled: isReadOnly
                    }}
                  />
                </td>
                <td>
                  <Field
                    name={`${patientType}_uom__${category.value}`}
                    clearable={false}
                    component={ReactSelectInput}
                    props={{
                      options: uoms,
                      disabled: isReadOnly
                    }}
                  />
                </td>
                <td>
                  <Field
                    name={`${patientType}_limit_using__${category.value}`}
                    clearable={false}
                    component={ReactSelectInput}
                    props={{
                      options: weightFields,
                      disabled: isReadOnly
                    }}
                  />
                </td>
              </tr>
            );
          })
        }
        </tbody>
      </table>
    </div>
  );
};

RulesSettings.propTypes = {
  rulesCategories: PropTypes.array.isRequired,
  patientType: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool.isRequired
};

export default RulesSettings;
