import { I18n } from 'react-redux-i18n';

const validate = (values, props) => {

  // ONLY validate manufacturing
  if(!props.facility || !props.facility.type ||  props.facility.type !== 'manufacturing') return {};

  const errors = {
    inv_compliance_settings: {
      grams_on_hand_block: '',
      coupled_grams_on_hand_block: '',
      grams_on_hand_warn: '',
      coupled_grams_on_hand_warn: ''
    }
  };

  if (values.inv_compliance_settings.grams_on_hand_warn && values.inv_compliance_settings.grams_on_hand_block) {
    if (+values.inv_compliance_settings.grams_on_hand_block < +values.inv_compliance_settings.grams_on_hand_warn) {
      errors.inv_compliance_settings.grams_on_hand_block = I18n.t('ei.complianceSettings.validationBlocker');
      errors.inv_compliance_settings.grams_on_hand_warn = I18n.t('ei.complianceSettings.validationBlocker');
    }
  }

  if (values.inv_compliance_settings.coupled_grams_on_hand_warn && values.inv_compliance_settings.coupled_grams_on_hand_block) {
    if (+values.inv_compliance_settings.coupled_grams_on_hand_block < +values.inv_compliance_settings.coupled_grams_on_hand_warn) {
      errors.inv_compliance_settings.coupled_grams_on_hand_block = I18n.t('ei.complianceSettings.validationBlocker');
      errors.inv_compliance_settings.coupled_grams_on_hand_warn = I18n.t('ei.complianceSettings.validationBlocker');
    }
  }

  return errors;
};

export default validate;
