import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, formValueSelector} from 'redux-form';

import AccountingExportForm from './AccountingExportForm';
import validate from './validateAccountingExport'; // eslint-disable-line import/no-named-as-default

const form = 'accountingExportForm';
const selector = formValueSelector(form);
const AccountingExportReduxForm = reduxForm({
  validate,
  form
})(AccountingExportForm);

function mapStateToProps(state) {
  //const categories = selector(state, 'categories') || [];
  return {
    getFormValue: name => selector(state, name),
  };
}

const AccountingExportFormWrapper = connect(mapStateToProps)(AccountingExportReduxForm);

AccountingExportFormWrapper.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onAdjust: PropTypes.func.isRequired,
  onRedirect: PropTypes.func.isRequired,
  exportFormats: PropTypes.array.isRequired,
  validate: PropTypes.func,
};

export default AccountingExportFormWrapper;
