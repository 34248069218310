import React from 'react';
import PropTypes from 'prop-types';
import Multiselect from 'react-widgets/lib/Multiselect';
import {I18n} from 'react-redux-i18n';
import 'react-widgets/dist/css/react-widgets.css';

import ReduxBootstrapInput from './ReduxBootstrapInput';

const selectAllValue = -1;

const addAllOption = (options, textKey, valueKey) => [{
  [valueKey]: selectAllValue,
  [textKey]: I18n.t('common.form.selectAll'),
}].concat(options);
const getOptions = (options, values, allOption, textKey, valueKey) =>
  allOption && options.some(o => values && !values.some(v => v[valueKey] === o[valueKey])) ?
    addAllOption(options, textKey, valueKey) : options;

const onChangeHandler = (onChange, allOption, valueKey, options, disabled) => value => {
  const filteredOptions = (disabled && disabled.length > 0) ? options.filter(option => !disabled.includes(option[valueKey])) : options;
  return onChange(
    allOption && value.some(v => v[valueKey] === selectAllValue) ? filteredOptions : value
  )}

const ListItem = (props) => {
  if (props.disabled) {
    return (
      <option value={props.value} disabled>
        {props.text}
      </option>
    );
  }
  return <option value={props.value}>{props.text}</option>;
};

const MultiselectInput = ({textKey, valueKey, label, createNew, size, input, meta, children, isRequired, options, allOption, ...props }) => {
  /*
   *  onBlur and onChange are not passed directly to the Select component
   *  as a workaround for a problem with react-select and redux-form:
   *  https://github.com/JedWatson/react-select/issues/1129
   */
  const inputHandlers = {
    onBlur: () => input.onBlur(),
    onChange: onChangeHandler(input.onChange, allOption, valueKey, options, props.disabled)
  };
  if(createNew){
    inputHandlers.onCreate = value => input.onChange(value);
  }
  const newInput = Object.assign({}, input, inputHandlers);
  return (
    <ReduxBootstrapInput
      Control={Multiselect}
      data={getOptions(options, input.value, allOption, textKey, valueKey)}
      itemComponent={ListItem}
      textField={textKey}
      valueField={valueKey}
      label={label}
      size={size}
      input={newInput}
      meta={meta}
      name={input.name}
      isRequired={isRequired}
      {...props} >
      {children}
    </ReduxBootstrapInput>
  );
};

MultiselectInput.propTypes = {
  valueKey: PropTypes.string.isRequired,
  textKey: PropTypes.string.isRequired,
  label: PropTypes.string,
  createNew: PropTypes.bool,
  size: PropTypes.oneOf(['lg', 'sm', 'large', 'small']),
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  allOption: PropTypes.bool,
  children: PropTypes.node,
  isRequired: PropTypes.bool
};
MultiselectInput.defaultProps = {
  valueKey: 'value',
  textKey: 'text',
};

export default MultiselectInput;
