import { createSelector } from 'reselect';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import first from 'lodash.first';
import find from 'lodash.find';
import intersection from 'lodash.intersection';
import { METRC } from '../../constants/integrations';
import * as dataNames from '../../constants/dataNames';
import { getIntegrationSettings, getStateIntegrator } from './commonSelectors';
import { getTimezone } from '../timezoneSelectors';
import { formatMetrcDateTime, formatMetrcORDateTime } from '../../util/dateHelpers';
import isRetailFacility from '../facility/isRetailFacility';
import { salesComplianceSettings, metrcTransfersEnabled } from '../../constants/itemNames';

// TODO: Had to duplicate this method because the app has cycle links of imports
export const getSettings = (state) => state[salesComplianceSettings];

export const getOhMetrcCategories = (state) => state[dataNames.ohMetrcCategories];
export const getMetrcCategories = (state) => state[dataNames.metrcCategories];
export const getMetrcCategoryMappings = (state) => state[dataNames.metrcCategoryMappings];
export const getOhMetrcCategoryMappings = (state) => state[dataNames.ohMetrcCategoryMappings];
export const getMetrcProducts = (state) => state[dataNames.metrcProducts];
export const getMetrcProductsMapping = (state) => state[dataNames.metrcProductsMapping];
export const getMetrcTransferPackages = (state) => state[dataNames.metrcTransferPackages];
export const getMetrcSalesCustomerTypes = state => state[dataNames.metrcSalesCustomerTypes];
export const getMetrcLocationTypes = state => state[dataNames.metrcLocationTypes];

export const getMetrcUoms = (state) => (state[dataNames.metrcUoms] ? state[dataNames.metrcUoms] : []);
const metrcTags = state => state[dataNames.trackingIds];
const getSalesExportData = state => state[dataNames.salesExportData];
const getMetrcTransfersEnabled = state => state[metrcTransfersEnabled];
const getId = (_, props) => props && props.id;
const getOrder = (_, props) => props && props.order;

export const isMetrcIntegrator = createSelector(
  [getStateIntegrator],
  (integrator) => integrator === METRC
);

export const getMetrcSettings = createSelector(
  [getIntegrationSettings, isMetrcIntegrator],
  (settings, isMetrc) =>
    (isMetrc && settings.integration_metrc_settings && settings.integration_metrc_settings.value) || null
);

export const getMetrcProductsMappingInitalValues = createSelector(
  [getMetrcProductsMapping],
  (productsMapping) => {
    return !isEmpty(productsMapping)
      ? productsMapping
      : [
        {
          product_id: null,
          item_id: null
        }
      ];
  }
);

export const getMetrcSalesCustomerTypesOptions = createSelector([getMetrcSalesCustomerTypes], metrcSalesCustomerTypes => {
  return metrcSalesCustomerTypes && metrcSalesCustomerTypes.map(type => {
    return {
      text: type,
      value: type,
    };
  }) || [];
});

const getMetrcState = createSelector(
  getMetrcSettings,
  (settings) => settings && settings.state
);
const getMetrcFacilityType = createSelector(
  getMetrcSettings,
  (settings) => settings && settings.facility_type
);

const isRecreationalFacilityType = createSelector(
  [getMetrcFacilityType],
  (type) => type === 'recreational'
);

/**
 * Check if METRC transfers are enabled for a given facility.
 * @return {boolean}
 */
export const isMetrcTransfersEnabled = createSelector(
  [getMetrcTransfersEnabled],
  (settings) => {
    return get(settings, 'enabled');
  }
);

export const isAlMetrcIntegrator = createSelector(
  [getMetrcState],
  (state) => state === 'al'
);

export const isCoMetrcIntegrator = createSelector(
  [getMetrcState],
  (state) => state === 'co'
);

export const isAkMetrcIntegrator = createSelector(
  [getMetrcState],
  (state) => state === 'ak'
);

export const isMdMetrcIntegrator = createSelector(
  [getMetrcState],
  (state) => state === 'md'
);

export const isOrMetrcIntegrator = createSelector(
  [getMetrcState],
  (state) => state === 'or'
);

export const isMoMetrcIntegrator = createSelector(
  [getMetrcState],
  (state) => state === 'mo'
);

export const isCaMetrcIntegrator = createSelector(
  [getMetrcState],
  (state) => state === 'ca'
);

export const isOhMetrcIntegrator = createSelector(
  [getMetrcState],
  (state) => state === 'oh'
);

/**
 *  For Michigan state
 * @type {Reselect.Selector<any, boolean> | Reselect.Selector<any, any>}
 */
export const isMiMetrcIntegrator = createSelector(
  [getMetrcState],
  (state) => state === 'mi'
);

/**
 * For Montana state
 * @type {Reselect.Selector<any, boolean> | Reselect.Selector<any, any>}
 */
export const isMtMetrcIntegrator = createSelector(
  [getMetrcState],
  (state) => state === 'mt'
);

/**
 * For Nevada state
 * @type {Reselect.Selector<any, boolean> | Reselect.Selector<any, any>}
 */
export const isNvMetrcIntegrator = createSelector(
  [getMetrcState],
  (state) => state === 'nv'
);

export const isOregonRecMetrc = createSelector(
  [isOrMetrcIntegrator, isRecreationalFacilityType],
  (isOregon, isRecreactional) => isOregon && isRecreactional
);

export const isDcMetrcIntegrator = createSelector(
  getMetrcSettings,
  (settings) => {
    return settings && settings.state === 'dc';
  }
);

/**
 * For Oklahoma state
 * @type {Reselect.Selector<any, boolean> | Reselect.Selector<any, any>}
 */
export const isOkMetrcIntegrator = createSelector(
    [getMetrcState],
    (state) => state === 'ok'
  );
export const isMeMetrcIntegrator = createSelector(
    [getMetrcState],
    (state) => state === 'me'
  );

/**
 * Using for adding required fields for Driver page due to form
 * a valid request to a new METRC delivery call.
 * @type {Reselect.Selector<any, any>}
 */
export const isMetrcDelivery = createSelector(
    [isDcMetrcIntegrator, isCoMetrcIntegrator, isMeMetrcIntegrator, isMdMetrcIntegrator, isMiMetrcIntegrator, isMtMetrcIntegrator, isOrMetrcIntegrator, isNvMetrcIntegrator, isMoMetrcIntegrator],
    (isWashingtonDC, isColorado, isMaine, isMaryland, isMichigan, isMontana, isOregon, isNevada, isMissouri) => isWashingtonDC || isColorado || isMaine || isMaryland || isMichigan || isMontana || isOregon || isNevada || isMissouri
);

/**
 * Method which is checking is there sales rule is enabled for specific patient type
 * @type {Reselect.Selector<any, any>}
 */
export const getSalesRulesMethodFor = createSelector(
  [getSettings],
  (settings) => {
    const SETTING_KEY = 'order_sales_limits_by_consumer_type.value.${type}.sales_limit_method';

    return get(settings, SETTING_KEY.replace('${type}', 'medical'));
  }
);

/**
 * Checking that active sales rules are "equivalency" rule.
 * Uses for determining of showing or not additional tab
 * on Category Management Page for OARRS reporting
 * @type {Reselect.Selector<any, boolean> | Reselect.Selector<any, any>}
 */
export const isEquivalencyMethodEnabled = createSelector(
  [getSalesRulesMethodFor],
  (salesRulesMethod) => salesRulesMethod === 'equivalency'
);

/**
 * Method that is checking are there all conditions are turn on for working
 * with OARRS reporting
 * @type {Reselect.Selector<any, any>}
 */
export const isOARRSReportingEnabled = createSelector(
  [isRetailFacility, isOhMetrcIntegrator, isEquivalencyMethodEnabled],
  (isRetailFacility, isOhioMetrc, isEquivalencyMethodEnabled) =>
    isRetailFacility && isOhioMetrc && isEquivalencyMethodEnabled
);

/**
 * Using on Category Mapping Page for determining is this needed to show a column for OARRS Reporting or not
 * @type {Reselect.Selector<any, function(*): boolean> | Reselect.Selector<any, any>}
 */
export const canShowOARRSColumnForCategory = createSelector(
  [isOARRSReportingEnabled],
  (isOARRSReportingEnabled) => (category) => {
    return isOARRSReportingEnabled && ['CONCENTRATE', 'INFUSED', 'EDIBLE', 'FLOWER', 'CARTPENS'].includes(category);
  }
);

export const getValidPatientLicenseNumberStates = (facilityType, state) => {
  const validPatientLicenseNumberStates = ['md', 'dc', 'ak', 'nv', 'or', 'mi', 'mt', 'ca'];
  if (facilityType === 'medical' && state === 'co') {
    validPatientLicenseNumberStates.push('co');
  }
  return validPatientLicenseNumberStates;
};

export const getSalesExportColumns = createSelector(
  [getMetrcState, getTimezone, getMetrcFacilityType],
  (state, timezone, facilityType) => {

    let prefix = 'stateIntegrators.salesExport';

    if (state === 'ca') {
      prefix = 'stateIntegrators.salesExport.ca';
    }

    const columns = [
      {
        name: `${prefix}.salesReceiptNumber`,
        dataId: 'receiptNumber',
        hidden: true,
        available: true,
      },
      {
        name: `${prefix}.externalReceiptNumber`,
        dataId: 'order_id',
        hidden: true,
        available: true,
      },
      {
        name: 'stateIntegrators.salesExport.salesDateTime',
        dataId: 'salesDateTime',
        hidden: false,
        formatter: (cell) =>
          state === 'or'
            ? formatMetrcORDateTime(cell, '', undefined, false)
            : formatMetrcDateTime(cell, '', undefined, false),
        csvFormatter: (cell) =>
          state === 'or'
            ? formatMetrcORDateTime(cell, '', undefined, false)
            : formatMetrcDateTime(cell, '', undefined, false),
        available: true
      },
      {
        name: 'stateIntegrators.salesExport.salesCustomerType',
        dataId: 'salesCustomerType',
        hidden: false,
        available: true
      },
      {
        name: `${prefix}.PatientLicenseNumber`,
        dataId: 'customerNumber',
        hidden: getValidPatientLicenseNumberStates(facilityType, state).indexOf(state) !== -1,
        available: true,
      },
      {
        name: 'stateIntegrators.salesExport.CaregiverNumber',
        dataId: 'caregiverNumber',
        available: true,
        dataSort: false,
        hidden: ['or', 'mi', 'mt', 'ca'].indexOf(state) !== -1,
      },

      {
        name: 'customers.create.identificationMethod',
        dataId: 'identificationMethod',
        available: true,
        dataSort: false,
        hidden: state !== 'co'
      },
      {
        name: `${prefix}.packageLabel`,
        dataId: 'packageLabel',
        hidden: false,
        available: true
      },
      {
        name: 'stateIntegrators.salesExport.quantity',
        dataId: 'quantity',
        hidden: false,
        formatter: (cell) => Number(cell),
        csvFormatter: (cell) => Number(cell),
        available: true
      },
      {
        name: `${prefix}.uom`,
        dataId: 'uom',
        hidden: false,
        available: true
      },
      {
        name: `${prefix}.unitThcPercent`,
        dataId: 'unitThcPercent',
        hidden: true,
        available: ['ca'].indexOf(state) == -1
      },
      {
        name: `${prefix}.unitThcContent`,
        dataId: 'unitThcContent',
        hidden: true,
        available: ['ca'].indexOf(state) == -1
      },
      {
        name: `${prefix}.unitThcContentUnitOfMeasure`,
        dataId: 'unitThcContentUnitOfMeasure',
        hidden: true,
        available: ['ca'].indexOf(state) == -1
      },
      {
        name: `${prefix}.unitWeight`,
        dataId: 'unitWeight',
        hidden: true,
        available: ['ca'].indexOf(state) == -1
      },
      {
        name: `${prefix}.unitWeightUnitOfMeasure`,
        dataId: 'unitWeightUnitOfMeasure',
        hidden: true,
        available: ['ca'].indexOf(state) == -1
      },
      {
        name: `${prefix}.totalAmount`,
        dataId: 'totalAmount',
        hidden: false,
        formatter: (cell) => '$' + Number(cell).toFixed(2),
        csvFormatter: (cell) => Number(cell).toFixed(2),
        available: true
      },
      {
        name: 'stateIntegrators.salesExport.orderType',
        dataId: 'fulfillmentMethod',
        hidden: state !== 'nv',
        available: true
      }
    ];


    return columns.filter((column) => column.available);
  }
);

export const getMetrcTags = createSelector(
  [metrcTags],
  (tags) => {
    let options = [];
    if (Array.isArray(tags)) {
      options = tags.map((tag) => {
        return { text: tag.tag, value: tag.id };
      });
    }
    return options;
  }
);

export const flattenSalesExportData = createSelector(
  getSalesExportData,
  (salesExportData) => {
    const data = [];
    if (Array.isArray(salesExportData)) {
      salesExportData.forEach((order, orderIndex) => {
        if (Array.isArray(order.Transactions)) {
          order.Transactions.forEach((transaction, transactionIndex) =>
            data.push({
              id: `${orderIndex}-${transactionIndex}`,
              salesDateTime: order.SalesDateTime || '',
              receiptNumber: transaction.ReceiptNumber || '',
              packageLabel: transaction.PackageLabel || '',
              quantity: transaction.Quantity || '',
              uom: transaction.UnitOfMeasure || '',
              unitThcPercent: transaction.UnitThcPercent || '',
              unitThcContent: transaction.UnitThcContent || '',
              unitThcContentUnitOfMeasure: transaction.UnitThcContentUnitOfMeasure || '',
              unitWeight: transaction.UnitWeight || '',
              unitWeightUnitOfMeasure: transaction.UnitWeightUnitOfMeasure || '',
              totalAmount: transaction.TotalAmount || '',
              salesCustomerType: order.SalesCustomerType || '',
              fulfillmentMethod: order.FulfillmentMethod || '',
              customerNumber: order.PatientLicenseNumber || '',
              caregiverNumber: order.CaregiverLicenseNumber || '',
              identificationMethod: order.IdentificationMethod || '',
              order_id: order.order_id || '',
            })
          );
        }
      });
    }
    return data;
  }
);

export const ohMetrcSubcategoriesForSale = createSelector(
  [getOhMetrcCategories, getOhMetrcCategoryMappings],
  (ohMetrcCategories, mappings) => {
    const allowedCats = ['plant_material_tier_1', 'plant_material_tier_2', 'concentrate', 'edible', 'topical'];
    const allowedCatsIds = ohMetrcCategories
      .filter((cat) => allowedCats.find((code) => code === cat.code))
      .map((cat) => cat.id);
    return mappings
      .filter((mapping) => allowedCatsIds.some((catid) => catid === mapping.ohmetrc_category_id))
      .map((mapping) => mapping.subcategory_id);
  }
);

export const getMetrcSendExportPayload = createSelector(
  getSalesExportData,
  (salesExportData) => ({ data: salesExportData })
);

export const getMetrcCategoryFromOrgSubcategoryId = createSelector(
  [getId, getMetrcCategories, getMetrcCategoryMappings],
  (subcategoryId, metrcCategories, mappings) => {
    const mapping = mappings.find((mapping) => mapping.subcategory_id === subcategoryId);
    return mapping ? metrcCategories.find((category) => category.id === mapping.metrc_item_category_id) : undefined;
  }
);

export const getGroupedMetrcCategories = createSelector(
  [getMetrcCategories],
  (metrcCategories) => {
    const mappedCategories = { 0: [] };
    metrcCategories.map((category) => {
      const maps = category.mjp_category_ids ? category.mjp_category_ids.split(',') : [];
      if (maps.length) {
        maps.map((i) => {
          if (mappedCategories[i]) {
            mappedCategories[i].push(category);
          } else {
            mappedCategories[i] = [category];
          }
        });
      } else {
        mappedCategories[0].push(category);
      }
    });
    Object.keys(mappedCategories).map((key) => {
      if (Number(key) > 0 || mappedCategories[0]) {
        mappedCategories[key].concat(mappedCategories[0]);
      }
    });
    // mappedCategories['0'] = metrcCategories;
    return mappedCategories;
  }
);

export const getOhMetrcMappingByCategoryCode = (categoryCode) =>
  createSelector(
    [getOhMetrcCategories, getOhMetrcCategoryMappings],
    (ohMetrcCategories, mappings) => {
      const ohMetrcCategory = ohMetrcCategories.find((cat) => cat.code === categoryCode);
      return ohMetrcCategory ? mappings.filter((mapping) => mapping.ohmetrc_category_id === ohMetrcCategory.id) : [];
    }
  );

export const getIsRefillNumberRequired = createSelector(
  [getOrder, ohMetrcSubcategoriesForSale, isOhMetrcIntegrator],
  (order, ohMetrcAllowedSubcategoriesForSale, isOhMetrc) => {
    const products = get(order, 'products', []);
    const itemsSubcategories = products.map((product) => product.subcategory_id);
    const subcategories = intersection(itemsSubcategories, ohMetrcAllowedSubcategoriesForSale);
    return isOhMetrc && order.consumer_type === 'medical' && subcategories.length > 0;
  }
);

/**
 * Finding appropriate metrc package in inventory receipt by state_integration_tracking_id
 * @type {Reselect.Selector<any, any>}
 */
export const findMetrcPackageByInventory = createSelector(
  [getMetrcTransferPackages],
  (metrcPackages) => (inventories) => {
    const state_integration_tracking_id = get(first(inventories), 'state_integration_tracking_id');

    return find(metrcPackages, { state_integration_tracking_id });
  }
);

/**
 * These states have configurable location types (locations/v1/types) that should
 * be used as the "LocationTypeName" be used when creating / updating a location
 * Look up Metrc Bulletin 101 for more information
 */
export const hasMetrcLocationTypes = createSelector(
  [isCaMetrcIntegrator, isOkMetrcIntegrator, isNvMetrcIntegrator],
  (isCa, isOK, isNV) => isCa || isOK || isNV
);

// response from metrc locations/v1/types
export const getMetrcLocationTypesOptions = createSelector(
  [getMetrcLocationTypes],
  (metrcLocationTypes) => {
    return metrcLocationTypes.map((locationType) => {
      return {
        id: locationType.Id,
        name: locationType.Name,
        // I don't know what's happening but when I submit the payload this value gets uppercased.. doing it here also so that the value can persist on next page load
        code: locationType.Name.toUpperCase(),
      };
    });
  }
);
