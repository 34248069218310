import React from 'react';
import { connect } from 'react-redux';
import Profile from './Profile';
import {getPatientsComplianceLimits} from '../../../selectors/complianceSettingsSelectors';

export const ReduxProfile = (props) => {
  return (<Profile {...props} />);
};

function mapStateToProps(state, ownProps){
  const {customer, customerGroups} = state;
  return {
    customer,
    customerGroups,
    patientComplianceSettings: getPatientsComplianceLimits(state),
  };
}

export default  connect(mapStateToProps)(ReduxProfile);
