import { reduxForm } from 'redux-form';

import { CUPO_SETTINGS_FORM } from '../../../../constants/forms';
import validate from './validate'; //eslint-disable-line
import { CupoSettingsForm } from './CupoSettingsForm';

export const CupoFormWrapper = reduxForm({
  form: CUPO_SETTINGS_FORM,
  validate,
  enableReinitialize: true
})(CupoSettingsForm);

export default CupoFormWrapper;
