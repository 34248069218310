import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { Col, Row } from 'react-bootstrap';
import * as ReactIcons from 'react-icons/fa';
import * as ReactPiIcons from 'react-icons/pi';
import * as ReactFa6Icons from 'react-icons/fa6';

const ProductTypeOptionsPanel = ({ options, category, onClick, isLockedProduct, isChild }) => {
  return (
    <div className='product-type-options'>
      <Row>
        <Col xs={12}>
          <h3><label className='product-type-header'>{I18n.t('products.typeOptions.title')}</label></h3>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {options ? options.map((option, index) => {
            let selected = '';
            const disabled = isLockedProduct ? 'disabled' : '';
            if (category) {
              selected = option.category_code === category.category_code ? 'selected' : '';
            }
            const onClickHandler = () => {
              if(isLockedProduct) return false;
              return onClick(option.category_code, option.subcategory_code);
            };

            // Mapping from Glyphicon to ReactIcon
            const iconMapping = [
              {key: 'grain', icon: ReactIcons.FaCannabis},
              {key: 'tint', icon: ReactIcons.FaTint},
              {key: 'fire', icon: ReactIcons.FaFire},
              {key: 'apple', icon: ReactIcons.FaAppleAlt},
              {key: 'oil', icon: ReactIcons.FaDatabase},
              {key: 'leaf', icon: ReactPiIcons.PiPottedPlantFill},
              {key: 'cd', icon: ReactIcons.FaCompactDisc},
              {key: 'scale', icon: ReactFa6Icons.FaWeightScale},
              {key: 'tree-deciduous', icon: ReactIcons.FaCannabis},
              {key: 'certificate', icon: ReactIcons.FaCertificate},
            ];

            const IconComponent = iconMapping.find((icon) => icon.key === option.glyphicon);

            return (
              <div key={index} className={`product-type-option ${selected} ${disabled}`} onClick={isChild ? null : onClickHandler}>
                <IconComponent.icon style={{width: '40px', height: '40px'}}/>
                <div className='product-type-label'>{option.label}</div>
              </div>
            );
          }) : null}
        </Col>
      </Row>
    </div>
  );
};

ProductTypeOptionsPanel.propTypes = {
  options: PropTypes.array.isRequired,
  category: PropTypes.object,
  subcategoryId: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  isLockedProduct: PropTypes.bool.isRequired,
  isChild: PropTypes.bool
};


export default ProductTypeOptionsPanel;
