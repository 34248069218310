export const saveParse = (value, defaultValue) => {
  if (value) {
    try {
      return JSON.parse(value);
    } catch (err) {
      return defaultValue;
    }
  }
  return defaultValue;
};

export const isValidJSON = (value) => {
  if(typeof value == 'object') return true;
  if (value && typeof value == 'string') {
    try {
      JSON.parse(value);
    } catch (err) {
      return false;
    }
    return true;
  }
  return false;
};

export const dummy = () => {};
