import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';
import * as types from '../../constants/actionTypes';

export const isChangeAction = (action, forms, fieldName = false, isTail = false) => {
  return isAction(action, forms, types.REDUX_FORM_CHANGE, fieldName, isTail);
};

export const isBlurAction = (action, forms, fieldName = false, isTail = false) => {
  return isAction(action, forms, types.REDUX_FORM_BLUR, fieldName, isTail);
};

export const isChangeActionOnField = (action, forms, fieldName, isTail = false) => {
  return isChangeAction(action, forms, fieldName, isTail);
};


export const isArrayPush = (action, forms, fieldName = false, isTail = false) => {
  return isAction(action, forms, types.REDUX_FORM_ARRAY_PUSH, fieldName, isTail);
};

export const isArrayPushOnField = (action, forms, fieldName, isTail = false) => {
  return isArrayPush(action, forms, fieldName, isTail);
};

export const isArrayInsert = (action, forms, fieldName) => {
  return isAction(action, forms, types.REDUX_FORM_ARRAY_INSERT, fieldName);
};

export const isArrayRemove = (action, forms, fieldName = false, isTail = false) => {
  return isAction(action, forms, types.REDUX_FORM_ARRAY_REMOVE, fieldName, isTail);
};

export const isArrayRemoveOnField = (action, forms, fieldName, isTail = false) => {
  return isArrayRemove(action, forms, fieldName, isTail);
};

// Original function here mapped to updated version
export const isFormChangeAction = (action, forms) => {
  return isChangeAction(action, forms, false, false);
};

// Original function here mapped to updated version
export const isFormChangeOnField = (action, forms, fieldName, tail) => {
  return isChangeActionOnField(action, forms, fieldName, tail);
};

export const isFormInitializing = (action, forms) => {
  return isAction(action, forms, types.REDUX_FORM_INITIALIZE);
};

export const getActionsFromBatchAction = (action) => {
  return (action.type === types.REDUX_BATCH_ACTION) ? action.payload : [action];
};


// GENERICS USED INTERNALLY

// Base function of all other functions. Could be called directly but others help to self document
export const isAction = (action, forms, actionType, fieldName = false, isTail = false) => {
  const isAction = isReduxAction(action, forms, actionType);
  if(!fieldName || !isAction) return isAction;
  return isReduxActionOnField(action, fieldName, isTail);
};

export const isReduxAction = (action, forms, actionType) => {
  return Boolean(action && action.meta && forms.indexOf(action.meta.form) > -1 && action.type === actionType);
};

export const isReduxActionOnField = (action, fieldName, isTail) => {
  const field = action.meta.field.split('.');
  return field.length > 1 && !isTail
    ? Boolean(field.find(piece => piece === fieldName))
    : fieldName === field.pop();
};

export const getFormArrayIndexFromString = (string, prefix = false) => {
  if (prefix) {
    string = string.split(prefix).pop();
  }
  const regex = /\[[0-9]{0,10}?\]/;
  const found = string.match(regex);
  return parseInt(get(found, '0').replace(']', '').replace('[', ''));
};

export const isField = (field, targetField) => {
  return field.indexOf(targetField) !== -1;
};

/*
 * Helper function to fix missing translations keys.
 * If the key contains a period it will format the last part of the key to a readable string.
 * Keys without a period are returned as is. They are likely already readable strings.
 */
export const handleMissingTranslation = (key) => {
  if (key.endsWith('.')) {
    return key;
  }
  let fallback = key.charAt(0).toUpperCase() + key.slice(1);           // Uppercase first letter
  if (fallback.indexOf('.') !== -1) {
    fallback = fallback.split(/[,.]+/).pop();                          // Take last part of the key
    fallback = fallback.charAt(0).toUpperCase() + fallback.slice(1);          // Uppercase first letter
    const segments = fallback.match(/[A-Z][a-z]+/g);  // Split up before each capital
    if (Array.isArray(segments)) {
      fallback = segments.join(' ');                                          // Separate segments with a space
    }
    // Check if local
    if (location.hostname === 'ui.mjp') {
      if (typeof(Storage) !== 'undefined' && localStorage.getItem('state')) {
        const state = JSON.parse(localStorage.getItem('state'));
        const locale = get(state, 'i18n.locale', '?');
        // Show some kind of notification on locals to help find missing keys
        console.log(`Missing translation key: ${key} (${locale})`);
      }
    }
  }
  return fallback;
};

I18n.setHandleMissingTranslation(handleMissingTranslation);
