import React from 'react';
import PropTypes from 'prop-types';
import {Field, FieldArray, FormSection} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Button, ButtonToolbar, Col, Form, ListGroup, ListGroupItem, Card, Row, Table} from 'react-bootstrap';
import * as p from '../../../constants/permissions';
import PermissionButton from '../../common/PermissionButton';
import TextInput from '../../common/form/TextInput';
import DatePickerInput from '../../common/form/DatePickerInput';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import PackagePurposeField from '../../common/form/redux-form/PackagePurposeField';
import EachPackagesFieldArray from '../common/EachPackagesFieldArray';
import ReduxMetrcIdField from '../../common/form/redux-form/ReduxMetrcIdField';


const EachPackagesForm = (props) => {

  const {
    initialValues: {item}, employees, itemMasters, locations, packagedWeight, saveComplete,
    pristine, submitting, invalid, reset, handleSubmit, change, integrationState, isEachPackaging,
    trackingIds, isMetrcStemHoldingsToggled, isMetrcCaDonationsToggled,
  } = props;
  const {isCaMetrc} = integrationState;

  return (
    <form className='seed-packages-form pre-pack-form' noValidate={true} onSubmit={handleSubmit}>
      <FormSection name='item'>
        <Card>
          <Card.Header>
            <h3 className='panel-title'>
              {I18n.t('packages.prePack.packageProduct')}
            </h3>
          </Card.Header>
          <Table className='inputs-table'>
            <thead>
            <tr>
              <th><Form.Label>{I18n.t('packaging.form.parentPackageId')}</Form.Label></th>
              <th><Form.Label>{I18n.t('ei.processing.form.productName')}</Form.Label></th>
              <th><Form.Label>{I18n.t('ei.processing.form.vendor')}</Form.Label></th>
              <th><Form.Label>{I18n.t('ei.processing.form.strain')}</Form.Label></th>
              <th><Form.Label>{I18n.t('ei.processing.form.availableWeight')}</Form.Label></th>
              <th><Form.Label>{I18n.t('ei.processing.form.weight')}</Form.Label></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                <Field name='package_code' component={TextInput} props={{
                  disabled: true,
                }}/>
              </td>
              <td>
                <Field name='item_name' component={TextInput} props={{
                  disabled: true,
                }}/>
              </td>
              <td>
                <Field name='producer_name' component={TextInput} props={{
                  disabled: true,
                }}/>
              </td>
              <td>
                <Field name='strain_name' component={TextInput} props={{
                  disabled: true,
                }}/>
              </td>
              <td>
                <Field name='qty_available' component={TextInput} props={{
                  rightAddon: item && item.uom,
                  groupClassName: 'transparent-addon-right',
                  disabled: true,
                }}/>
              </td>
              <td>
                <Field name='qty' component={TextInput} props={{
                  rightAddon: item && item.uom,
                  groupClassName: 'transparent-addon-right',
                  disabled: true,
                }}/>
              </td>
            </tr>
            </tbody>
          </Table>
        </Card>
      </FormSection>

      <Row>
        <Col xs={12} sm={4} md={3}>
          <Card>
            <Card.Header>
              <h3 className='panel-title'>
                {I18n.t('packaging.form.employees')}
              </h3>
            </Card.Header>
            <ListGroup>
              {employees.map((employee, index) => <ListGroupItem key={index}>{employee.displayName}</ListGroupItem>)}
            </ListGroup>
          </Card>
        </Col>
        <Col xs={12} sm={8} md={9}>
          <FieldArray name='packages' component={EachPackagesFieldArray} props={{
            itemMasters,
            packagedWeight,
            integrationState,
            isEachPackaging,
          }}/>
        </Col>
      </Row>

      <Row>
        <Col xs={6} sm={4} md={3}>
          <Field name='estimated_completion_at' component={DatePickerInput} props={{
            label: I18n.t('packaging.form.estimatedCompletionTime'),
            timeFormat: true,
            inputProps: {disabled: true},
          }}/>
        </Col>
        <Col xs={6} sm={4} md={3}>
          <Field name='completed_at' component={DatePickerInput} props={{
            label: I18n.t('packaging.form.actualCompletionTime'),
            timeFormat: true,
            isRequired: true
          }}/>
        </Col>
        <Col xs={6} sm={4} md={3}>
          <Field name='package_created_at' component={DatePickerInput} props={{
            label: I18n.t('packaging.form.packageCreatedAt'),
            timeFormat: false,
          }}/>
        </Col>
        <Col xs={6} sm={4} md={3}>
          <Field name='package_expires_at' component={DatePickerInput} props={{
            label: I18n.t('packaging.form.packageExpiresAt'),
            timeFormat: false,
            isRequired: false,
            enableTodayButton: false,
          }}/>
        </Col>
        <Col xs={6} sm={4} md={3}>
          <Field
            name='storage_location_id'
            component={ReactSelectInput}
            props={{
              label: I18n.t('packages.form.storageLocation'),
              options: locations,
              placeholder: I18n.t('common.form.selectPlaceholder'),
              isRequired: true
            }}
          />
        </Col>
        <Col xs={6} sm={4} md={3}>
          <PackagePurposeField name='purpose' />
        </Col>
        {isEachPackaging && (
          <Col xs={6} sm={4} md={3}>
            <ReduxMetrcIdField
              name='state_integration_tracking_id'
              props={{
                options: trackingIds,
                isRequired: true
              }}
            />
          </Col>
        )}
        {isEachPackaging && isMetrcStemHoldingsToggled && (
          <Col xs={6} sm={4} md={3}>
            <Field name={`is_trade_sample`} component={ReactSelectInput}
              props={{
                label: I18n.t('metrc.form.tradeSample'),
                options: [
                  {text: 'Yes', value: 1},
                  {text: 'No', value: 0}
                ]
              }}
            />
          </Col>
        )}
        {isCaMetrc && isMetrcCaDonationsToggled && (
          <Col xs={6} sm={4} md={3}>
            <Field name={`is_donation`} component={ReactSelectInput}
              props={{
                label: I18n.t('metrc.form.donation'),
                options: [
                    {text: 'No', value: 0},
                    {text: 'Yes', value: 1},
                ]
              }}
            />
          </Col>
        )}
      </Row>

      <Row>
        <Col xs={12}>
          <ButtonToolbar className='float-right'>
            <Button
              type='reset'
              onClick={reset}
              disabled={submitting || pristine}
            >
              {I18n.t('packages.form.reset')}
            </Button>
            <PermissionButton
              permissions={[p.print_labels]}
              props={{
                variant: 'info',
                type: 'submit',
                disabled: submitting || pristine || invalid,
                onClick: () => change('print', true),
              }}
            >
              {!saveComplete ? I18n.t('common.saveAndPrintLabels') : I18n.t('common.printLabels')}
            </PermissionButton>
            <Button variant='primary'
                    type='submit'
                    onClick={() => change('print', false)}
                    disabled={submitting || pristine || invalid || saveComplete}>
              {I18n.t('packages.form.save')}
            </Button>
          </ButtonToolbar>
        </Col>
      </Row>
    </form>
  );
};

EachPackagesForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  saveComplete: PropTypes.bool.isRequired,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  employees: PropTypes.array.isRequired,
  itemMasters: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  packagedWeight: PropTypes.number.isRequired,
  reset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  integrationState: PropTypes.object.isRequired,
  isEachPackaging: PropTypes.bool.isRequired,
  trackingIds: PropTypes.array,
  isMetrcStemHoldingsToggled: PropTypes.bool,
  isMetrcCaDonationsToggled: PropTypes.bool,
};

export default EachPackagesForm;
