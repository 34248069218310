import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field, FieldArray, reduxForm} from 'redux-form';
import {
  Button,
  ButtonToolbar,
  Col,
  Row
} from 'react-bootstrap';

import PackageMappings from './PackageMappings';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import TextInput from '../../common/form/TextInput';

export const SupplyChainMappingForm = ({integratedTransfersOptions, fetchIntegratedTransfer, internalOrdersOptions,
  itemMasterOptions, fetchInternalOrder, handleSubmit, submitting, pristine, invalid, hasValidProductCounts, error}) => {

  return (
    <form className='supply-chain-mapping-form' onSubmit={handleSubmit}>
      <Row className='padding-top'>
        <Col xs={12} md={8} lg={6}>
          <Field
            name='external_id' component={ReactSelectInput}
            props={{
              label: I18n.t('supplyChainMapping.form.searchIntegrationTransfers'),
              options: integratedTransfersOptions
            }}
            onChange={(event, newValue) => fetchIntegratedTransfer(newValue)}
          />
        </Col>
        {/*<Col xs={12} md={4} lg={3}>
          <Field
            name='external_partner_name' component={TextInput}
            props={{
              label: I18n.t('supplyChainMapping.form.partnerName'),
              readOnly: true,
              value: integratedTransfer.transfer_from_name
            }}
          />
        </Col>*/}
        <Col xs={12} md={4} lg={3}>
          <Field
            name='product_count' component={TextInput}
            props={{
              label: I18n.t('supplyChainMapping.form.productTotalCount'),
              readOnly: true
            }}
          />
        </Col>
      </Row>
      <Row className='padding-top'>
        <Col xs={12} md={8} lg={6}>
          <Field
            name='receipt_id' component={ReactSelectInput}
            props={{
              label: I18n.t('supplyChainMapping.form.activePurChaseOrder'),
              options: internalOrdersOptions
            }}
            onChange={(event, newValue) => fetchInternalOrder(newValue)}
          />
        </Col>
        <Col xs={12} md={4} lg={3}>
          <Field
            name='item_count' component={TextInput}
            props={{
              label: I18n.t('supplyChainMapping.form.productTotalCount'),
              readOnly: true
            }}
          />
        </Col>
      </Row>
      {hasValidProductCounts ?
        <div>
          <h4>{I18n.t('supplyChainMapping.form.productNameMapping')}</h4>
          <FieldArray
            name='products'
            component={PackageMappings}
            itemMasterOptions={itemMasterOptions}
          />
          {error &&
          <div className='has-error'><span className='help-block'>{error}</span></div>
          }
          <Row>
            <Col xs={12}>
              <ButtonToolbar className='float-right'>
                <Button variant='primary'
                        type='submit'
                        disabled={submitting || pristine || invalid}>
                  {I18n.t('supplyChainMapping.form.receive')}
                </Button>
              </ButtonToolbar>
            </Col>
          </Row>
        </div>
        : null
      }
    </form>
  );
};

SupplyChainMappingForm.propTypes = {
  fetchIntegratedTransfer: PropTypes.func.isRequired,
  fetchInternalOrder: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  integrationState: PropTypes.object.isRequired,
  integratedTransfersOptions: PropTypes.array.isRequired,
  internalOrdersOptions: PropTypes.array.isRequired,
  itemMasterOptions: PropTypes.array.isRequired,
  error: PropTypes.string,
  uomByInventoryType: PropTypes.object,
  hasValidProductCounts: PropTypes.bool.isRequired
};

export default reduxForm({form: 'SupplyChainMappingForm'})(SupplyChainMappingForm);
