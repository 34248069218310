import {createSelector} from 'reselect';
import {formValueSelector} from 'redux-form';
import get from 'lodash.get';
import {getPurchaseOrder} from '../purchaseOrdersSelectors';
import {getPartnerFacilities, getActiveFacility} from '../facilitiesSelectors';
import {getIntegrationState} from '../integration/integrationSelectors';
import {PURCHASE_ORDER_FORM} from '../../constants/forms';

const selector = formValueSelector(PURCHASE_ORDER_FORM);

const getSelectedPartnerFacilityId = (state) => selector(state, 'vendor_facility_id');

export const canCreateMatchingOrder = createSelector(
  [getPurchaseOrder, getPartnerFacilities, getActiveFacility, getSelectedPartnerFacilityId, getIntegrationState],
  (purchaseOrder, facilities, activeFacility, partnerFacilityId, {isMetrc}) => {

    const isInternalPartner = (facility) => {
      return facility.id === partnerFacilityId && get(facility, 'partner.is_internal_partner', false);
    };

    const isLinkedPo = () => {
      return (purchaseOrder && get(purchaseOrder, 'internal_sales_order_linked', false) === 1) || !purchaseOrder;
    };

    const isSameOrganizaton = (facility) => {
      return facility.id === partnerFacilityId && facility.facility_id;
    };

    const canBeLinked = facilities.find((facility) => isInternalPartner(facility));
    const canCreateSalesOrder = facilities.find((facility) => isSameOrganizaton(facility));

    if (!canCreateSalesOrder) {
      return 0;
    }
    return !isLinkedPo() && canBeLinked && !(isMetrc && get(purchaseOrder, 'is_imported'))
      ? 1 // show button and enable
      : !canBeLinked // hide button
        ? 0
        : -1; // already linked so disable button

  });

export const canChoosePartner = createSelector([getPurchaseOrder, canCreateMatchingOrder, getIntegrationState], (purchaseOrder, canCreateMatchingOrder, {isMetrc}) => {
  if (canCreateMatchingOrder === -1) {
    return false;
  }
  if (isMetrc && get(purchaseOrder, 'is_imported')) {
    return false;
  }
  if (['ordered', 'partial'].indexOf(purchaseOrder.payment_status) !== -1) {
    return false;
  }
  if (purchaseOrder.received) {
    return false;
  }
  return true;
});

export const getRenderFacts = createSelector([getPurchaseOrder, getPartnerFacilities, getActiveFacility, getSelectedPartnerFacilityId],
  (purchaseOrder, partnerFacilities, activeFacility, selectedPartnerFacilityId) => {
    const isInternalPartner = (facility) => {
      return facility.id === selectedPartnerFacilityId && get(facility, 'partner.is_internal_partner', false);
    };
    const isConnectsPartner = (facility) => {
      return facility.id === selectedPartnerFacilityId && get(facility, 'connect_facility_code', false) !== null;
    };
    const isLinkedPo = () => {
      return get(purchaseOrder, 'internal_sales_order_linked', 0) === 1;
    };
    return {
      canBeLinked: Boolean(partnerFacilities.find((facility) => isInternalPartner(facility) || isConnectsPartner(facility))),
      isLinked: isLinkedPo(),
      hasTransfer: Boolean(get(purchaseOrder, 'transfer_record', false)),
      hasCompletedTransfer: get(purchaseOrder, 'transfer_record.status', false) === 'completed'
    };
  });
