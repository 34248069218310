import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Alert } from 'react-bootstrap';
import {FaExclamationTriangle} from 'react-icons/fa';
import get from 'lodash.get';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {getAutoMappedItemMaster, getCompatibleItemMasters, hasAutoMappedItemMasters} from '../../../../selectors/MetrcSupplyChainMappingSelectors';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import InlineCheckBox from '../../../common/form/InlineCheckBox';

const ProductMapping = ({ /*formValues, formErrors, */ meta, getFormValue, change, incomingTransferDetails, itemMasters, itemMasterMappings, purchaseOrder }) => {

  const getMappedProductQuantity = (index) =>  {
    const item_master = getFormValue(`product_mapping.${index}.item_master`);
    if (!item_master) {
      return;
    }

    // If auto generated PO determine quantity from incoming transfer
    if (getFormValue('auto_generate_po')) {
      const pkg = incomingTransferDetails.packages[index];
      if (pkg.transfer_uom === 'GR' && item_master.default_uom === 'EA') {
        const med_weight_uom = get(item_master, 'medicated_weight_uom_display');
        const med_weight = get(item_master, 'medicated_weight_base', 0) / 1000000;
        // Return if no med_weight_uom or med_weight
        if (!med_weight_uom || !med_weight) {
          return;
        }
        return pkg.transfer_qty / med_weight;
      }
      return pkg.transfer_qty;
    }
    // Otherwise get from Purchase Order
    if (purchaseOrder && Object.keys(purchaseOrder).length !== 0) {
      const purchaseOrderLines = get(purchaseOrder, 'lines', []);
      const purchaseOrderLine = purchaseOrderLines.find((po_line) => po_line.item_master_id === item_master.id);
      if (!purchaseOrderLine) {
        // Couldn't find tem_master on PO
        return '-';
      }
      return purchaseOrderLine.qty_base;
    }
    // Couldn't determine quantity. (i.e. auto_generate_po is off and a purchase_order is not yet selected).
    return '-';
  };

  const getMappedProductUom = (index) =>  {
    const item_master = getFormValue(`product_mapping.${index}.item_master`);
    if (!item_master) {
      return;
    }
    return item_master.default_uom;
  };

  const transferHasAutoMappedItems = hasAutoMappedItemMasters(incomingTransferDetails.packages, itemMasterMappings, itemMasters);

  return (
    <React.Fragment>

      {meta && meta.error ? (
        <Row>
          <Col xs={12}>
            <Alert variant='danger'>
              <FaExclamationTriangle />
              <span className='padding-left'>{meta.error}</span>
            </Alert>
          </Col>
        </Row>
      ) : null}

      {/* Incoming Metrc Packages - MJP Products Header */}
      <Row>
        <Col xs={6} className='product-mapping-col-header'>
          <div>{I18n.t('supplyChainMapping.form.incomingMetrcPackages')}</div>
        </Col>
        <Col xs={6} className='product-mapping-col-header'>
          <div>{I18n.t('supplyChainMapping.form.mjpProducts')}</div>
        </Col>
      </Row>
      <Row style={{display: 'flex', flexWrap: 'nowrap'}}>
        {/* Metrc fields */}
        <Col xs={6} className='product-mapping-col' style={{borderRight: '1px solid #ccc'}} />
        {/* MJP fields */}
        <Col xs={6} className='product-mapping-col'>
          <Row>
            <Col xs={1}>{transferHasAutoMappedItems && I18n.t('supplyChainMapping.form.autoMap')}</Col>
            <Col xs={11}/>
          </Row>
        </Col>
      </Row>

      {incomingTransferDetails.packages.map((pkg, index) => {
        // Get auto mapped item master for this metric_item_id
        const autoMappedItemMaster = getAutoMappedItemMaster(get(pkg, 'metrc_item_id'), itemMasterMappings, itemMasters);

        // Find all compatible item masters
        const compatibleItemMasters = getCompatibleItemMasters(pkg.mjp_category_ids, itemMasters);
        // Set auto map fields
        const current_item_master = getFormValue(`product_mapping.${index}.item_master`);
        if (autoMappedItemMaster && /* current_auto_map === undefined && */ !current_item_master) {
          change(`product_mapping.${index}.auto_map`, true);
          change(`product_mapping.${index}.item_master`, autoMappedItemMaster);
        }

        // Check if package is not Accepted
        const notReceived = get(pkg, 'status', '') !== 'Accepted';

        return (
          <Row key={index} style={{display: 'flex', flexWrap: 'nowrap'}}>
            {/* Metrc data */}
            <Col xs={6} className='product-mapping-row-border-left-margin product-mapping-col-border'>
              <Row>
                <Col xs={12} className={notReceived ? 'product-mapping-error' : ''}>
                  <Field
                    style={notReceived ? {borderColor: 'red'} : {}}
                    name={`product_mapping.${index}.metrcPackage`}
                    component={ReactSelectInput}
                    props={{
                      options: incomingTransferDetails.packages,
                      textKey: 'product_name',
                      valueKey: 'package_code',
                      disabled: true,
                      value: pkg
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={5} className={notReceived ? 'product-mapping-error' : ''}>
                  {I18n.t('supplyChainMapping.form.metrcId')}:
                  {get(pkg, 'package_code', '')}
                </Col>
                <Col xs={2} className={notReceived ? 'product-mapping-error' : ''}>
                  {I18n.t('supplyChainMapping.form.metrcQty')}: {get(pkg, 'transfer_qty', '')}
                </Col>
                <Col xs={2} className={notReceived ? 'product-mapping-error' : ''}>
                  {I18n.t('supplyChainMapping.form.metrcUom')}: {get(pkg, 'transfer_uom', '')}
                </Col>
                <Col xs={3} className={notReceived ? 'product-mapping-error' : ''}>
                  {I18n.t('supplyChainMapping.form.metrcStatus')}: {get(pkg, 'status', '')}
                </Col>
              </Row>
            </Col>
            {/* MJP data */}
            <Col xs={6} className='product-mapping-row-border-right-margin'>
              <Row>
                <Col xs={1} className={notReceived ? 'product-mapping-error' : ''}>
                  {autoMappedItemMaster &&
                    <Field
                      name={`product_mapping.${index}.auto_map`}
                      component={InlineCheckBox}
                      onChange={(event) => {
                        const value = !!event.target.checked;
                        // if (true) then set im mapping
                        if (value) {
                          change(`product_mapping.${index}.item_master`, autoMappedItemMaster);
                        }
                        change(`product_mapping.${index}.auto_map`, value);
                      }}
                    />
                  }
                </Col>
                <Col xs={11}>
                  {/* For some reason we have to set the warning as a style. Setting it as a class doesn't work */}
                  <Field
                    style={notReceived ? {borderColor: 'red'} : {}}
                    name={`product_mapping.${index}.item_master`}
                    component={ReactSelectInput}
                    props={{
                      options: compatibleItemMasters,
                      textKey: 'name',
                      valueKey: 'id',
                      disabled: autoMappedItemMaster,
                      onChange: (value) => {
                        const item_master = itemMasters.find((im) => im.id === value);
                        change(`product_mapping.${index}.item_master`, item_master);
                      }
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={1}/>
                <Col xs={5} className={notReceived ? 'product-mapping-error' : ''}>
                  {getMappedProductQuantity(index) && (
                    <React.Fragment>{I18n.t('supplyChainMapping.form.qty')}: {getMappedProductQuantity(index)}</React.Fragment>
                  )}
                  &nbsp;
                </Col>
                <Col xs={6} className={notReceived ? 'product-mapping-error' : ''}>
                  {getMappedProductUom(index) && (
                    <React.Fragment>{I18n.t('supplyChainMapping.form.uom')}: {getMappedProductUom(index)}</React.Fragment>
                  )}
                  &nbsp;
                </Col>
              </Row>
            </Col>
          </Row>
        );
      })}
    </React.Fragment>
  );
};

ProductMapping.propTypes = {
  //formErrors: PropTypes.object,
  formValues: PropTypes.object,
  meta: PropTypes.object,
  getFormValue: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  incomingTransferDetails: PropTypes.object.isRequired,
  itemMasters: PropTypes.array,
  itemMasterMappings: PropTypes.array,
  purchaseOrder: PropTypes.object
};

export default ProductMapping;
