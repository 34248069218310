import epson from './lib/epson';
import star115 from './lib/star.rev.1.15';

const escpos = function(){

  this.commandsType = 'star115';
  this.commands = star115;
  this.buffer = [];
  this.columnPercents = [];
  this.columns = [];
  this.width = 3;
  this.uom = 'in';
  this.lines = [];
  this.dataline = [];
  this.escapeArray = [];
  this.charactersPerLine = 48;
  this.centered = false;
  this.left = false;
  this.debug = true;
  this.flags = {
    align_center: false,
    align_right: false,
    align_left: true
  };
};

escpos.prototype.setCommands = function(type){
  if(type !== undefined) this.commandsType = type;
  switch(type){                       //eslint-disable-line
    case 'star.115':                  //eslint-disable-line
      this.commands = star115;        //eslint-disable-line
      break;                          //eslint-disable-line
    case 'epson':                     //eslint-disable-line
      this.commands = epson;          //eslint-disable-line
      break;                          //eslint-disable-line
    default:                          //eslint-disable-line
      this.commands = star115;        //eslint-disable-line
  }
  return this;
};

escpos.prototype.setWidth = function(width, uom){
  this.width = width;
  this.uom = uom;
};

escpos.prototype.clearColumns = function(){ this.columns = [];};

escpos.prototype.getColumns = function(){ return this.columns;};

escpos.prototype.setColumn = function(width){
  this.columnPercents.push(width);
  const characters = Math.floor(this.charactersPerLine * (width / 100));
  return this.columns.push(characters);
};

escpos.prototype.setCharactersPerLine = function(characters){
  return this.charactersPerLine = characters;
};

escpos.prototype.getConfiguredValues = function(){
  return {
    columnsInPercent: this.columnPercents,
    columnsInCharacters: this.columns,
    charactersPerLine: this.charactersPerLine
  };
};

escpos.prototype.truncate = function(string){
  const maxLength = (this.columns[0] - 3);
  return string.length > maxLength ? string.substr(0, maxLength) + '...' : string;
};

escpos.prototype.leftPad = function(string, length){
  return string.length < length ? this.leftPad(' ' + string, length) : string;
};
escpos.prototype.rightPad = function(string, length){
  return string.length < length ? this.rightPad(string + ' ', length) : string;
};

escpos.prototype.centerPad = function(string, length){
  if(length === undefined) length = this.charactersPerLine;
  if(string.length >= length) return string;
  const pad = Math.floor((length - string.length) / 2);
  const space = ' ';
  return space.repeat(pad) + string;
};

escpos.prototype.setFont = function(){};

escpos.prototype.lf = function(numberOfLines = 1){
  if(numberOfLines === 1) {
    this.dataline = this.dataline.concat(this.commands.CTL_LF);
  } else {
    this.dataline.push('\x1B\x61' + this.commands[numberOfLines]);
  }
  return this;
};

escpos.prototype.setFlag = function(flag, value){
  const root = flag.split('_').shift();
  for(const prop in this.flags){
    if(prop.indexOf(root) !== -1) this.flags[prop] = false;
  }
  this.flags[flag] = value;
};

escpos.prototype.mockAlign = function(text){
  if(this.commandsType !== 'star115') return text;
  if(this.flags.align_center) return this.centerPad(text);
  if(this.flags.align_left) return text;
  if(this.flags.align_right) return this.leftPad(text, this.charactersPerLine);
  this.setFlag('align_left', true);
  return text;
};

escpos.prototype.centerText = function(){
  this.setFlag('align_center', true);
  if(this.commandsType === 'star115') return this;
  this.dataline.push(this.commands.TXT_ALIGN_CT); return this;
};

escpos.prototype.leftText = function(){
  this.setFlag('align_left', true);
  if(this.commandsType === 'star115') return this;
  this.dataline.push(this.commands.TXT_ALIGN_LT); return this;
};

escpos.prototype.rightText = function(){
  this.setFlag('align_right', true);
  if(this.commandsType === 'star115') return this;
  this.dataline.push(this.commands.TXT_ALIGN_RT); return this;
};

escpos.prototype.text = function(text){
  if(Array.isArray(text)) {
    this.multiText(text);
    return this;
  }
  text = this.mockAlign(text);
  this.dataline.push(text);
  return this;
};

escpos.prototype.multiText = function(text){
  if(Array.isArray(text)) {
    this.dataline.push(this.rightPad(this.truncate(text[0]), this.columns[0]));
    this.dataline.push(this.leftPad(text[1], this.columns[1]));
    return this;
  }
  this.dataline.push(text);
};

escpos.prototype.partialCut = function(){
  this.dataline = this.dataline.concat(this.commands.PAPER_PART_CUT, this.commands.CTL_LF);
  return this;
};

escpos.prototype.drawLine = function(char = '-'){
  this.dataline.push(char.repeat(this.charactersPerLine));
  return this;
};

escpos.prototype.updateLines = function(){
  if(this.dataline.length) this.lines.push(this.dataline);
  this.dataline = [];
  return this;
};

escpos.prototype.startLine = function(){
  this.updateLines();
  return this;
};

escpos.prototype.loadBuffer = function(){
  this.updateLines();
  this.lines.forEach((line, index) => {
    this.buffer.push(line);
  });
  return this;
};

escpos.prototype.getBuffer = function(initOutput = true){
  const buffer = this.buffer.map((buff) => buff);
  if(initOutput) buffer.unshift(this.commands.HW_INIT);
  return buffer;
};

escpos.prototype.getData = function(){
  return this.loadBuffer().getBuffer().map((buff) => {
    return buff.join('');
  });
};

export default escpos;
