import { I18n } from 'react-redux-i18n';
import get from 'lodash.get';
import map from 'lodash.map';
import {
  requiredFieldValidation,
  alphaNumericValidation,
  checkDateOfBirthValidation,
  dateFieldValidation,
  isInteger,
} from '../../common/form/redux-form/validations';
import * as consumerTypes from '../../../constants/consumerTypes';
import {
  temp_id,
  intake_form,
  extended_sales_limit,
  inventory_receipt_document,
  medical_id,
  driver_license
} from '../../../constants/fileTypes';
import * as applicationModes from '../../../constants/applicationModes';
//import {PHONE_NUMBER_REG_EXP} from '../common/form/validationRegExs';
const incorrectCharsRegExp = /[~`!#$%^&*+=\-\[\]\\';,/{}|":<>?]/g;

export const isFieldRequired = (
  type,
  medicalIdExpirationIsRequired,
  patientSettings,
  hasTempId
) => {
  if (
    type === intake_form ||
    type === extended_sales_limit ||
    type === inventory_receipt_document ||
    type === driver_license
  ) {
    return false;
  }
  if (type === medical_id) {
    return (
      medicalIdExpirationIsRequired ||
      !(patientSettings && patientSettings.allowTempPaperwork && hasTempId)
    );
  } else {
    return patientSettings && patientSettings.allowTempPaperwork && hasTempId;
  }
};

export const isCaregiversHaveMedicalId = (caregiverInfo) => Array.isArray(caregiverInfo) ?
  caregiverInfo.some(caregiver => caregiver.medical_id) :
  false;

export const isExpirationRequired = (
  type,
  medicalIdExpirationIsRequired,
  customer
) =>
  Boolean(
    type === medical_id &&
      medicalIdExpirationIsRequired &&
      customer &&
      customer.type === consumerTypes.MEDICAL
  );

const validateRegistrationFields = (props, registrations) => {
  if (props.isCanadaFacility) {
    return registrations.map((registration) => {
      switch (registration.status) {
      case 'return':
        return {
          from_facility_id: requiredFieldValidation(registration.from_facility_id, 'customers.registrations.fromFacility'),
          event_on: requiredFieldValidation(registration.event_on, 'common.objectFile.registrationEventDate')
        };
      case 'refusal':
        return {
          refusal_reason: requiredFieldValidation(registration.refusal_reason, 'customers.registrations.refusalOptions'),
          event_on: requiredFieldValidation(registration.event_on, 'common.objectFile.registrationEventDate')
        };
      }
      return {};
    });
  }
  return [];
};

export const validate = (values, props) => {
  const isMetrcMd = props.applicationMode === applicationModes.metrcMd;
  const {integrationState: {isOhMetrc}} = props;
  const hasTempId = (values.ids || []).some(
    file =>
      file.type === temp_id &&
      file.identification_number &&
      file.identification_number.trim()
  );
  const errors = {
    customer: {
      personal_use_producer: requiredFieldValidation(
        values.customer.personal_use_producer
      )
    },
    limitsInfo: {}
  };

  const requiresMedicalData = () => isOhMetrc || ['medical', 'mmic'].indexOf(values.customer.type) !== -1;
  const isMedicalIdRequiredLeafPA = props.isPaLeaf && !isCaregiversHaveMedicalId(values.caregiverInfo);

  errors.registrations = validateRegistrationFields(props, get(values, 'registrations', []));

  errors.ids = map(get(values, 'ids', []), file => {
    const error = {};
    error.expired_at = dateFieldValidation(file.expired_at);

    if (requiresMedicalData()) {
      if (
        isFieldRequired(file.type, props.medicalIdExpirationIsRequired, props.patientSettings, hasTempId) &&
        (!props.isPaLeaf || isMedicalIdRequiredLeafPA)
      ) {
        error.identification_number = requiredFieldValidation(file.identification_number);
      }
      if (
        isExpirationRequired(
          file.type,
          props.medicalIdExpirationIsRequired,
          values.customer
        )
      ) {
        error.expired_at = requiredFieldValidation(file.expired_at);
      }
      if (isMetrcMd && error.identification_number === undefined) {
        if (file.type === 'med') {
          error.identification_number =
            file.identification_number.length < 16
              ? 'Identification Number Must Be 16 Characters Long'
              : undefined;
        }
      }
      if (isOhMetrc && file.type === 'med' && error.identification_number === undefined) {
        error.identification_number =
          !(/^(([A-Z0-9]){4}-){4}([A-Z0-9]){4}$/ig.test(file.identification_number))
            ? I18n.t('customers.create.medicalMarijuanaIdFormat')
            : undefined;
      }
    }
    return error;
  });

  if (props.integrationState.isOhMetrc) {
    errors.customer.refills_authorized = requiredFieldValidation(values.customer.refills_authorized, 'customers.create.refillsAuthorized');
    errors.customer.physician_recommendation_date = requiredFieldValidation(values.customer.physician_recommendation_date, 'customers.create.physicianRecommendationDate');
    errors.customer.physician_id = requiredFieldValidation(values.customer.physician_id, 'customers.create.physicianRecord');
    if(values.addresses && values.addresses[0]){
      const countryCode = (values.addresses[0].country_code || '').trim();
      errors.addresses = [{
        street_address_1: requiredFieldValidation(values.addresses[0].street_address_1, 'customers.create.street'),
        city: requiredFieldValidation(values.addresses[0].city, 'customers.create.city'),
        country_code: requiredFieldValidation(countryCode ? countryCode : undefined, 'customers.create.country'),
        province_code: requiredFieldValidation(values.addresses[0].province_code, 'customers.create.state'),
        postal_code: requiredFieldValidation(values.addresses[0].postal_code, 'customers.create.zipCode')
      }];
    }
  }

  if (props.integrationState.isFutuCare || props.integrationState.isPrBiotrack) {
    errors.customer.ssn_last_4 = requiredFieldValidation(values.customer.ssn_last_4, 'customers.create.ssnLast4')
      || isInteger(values.customer.ssn_last_4, 'customers.create.ssnLast4', true)
      || ((values.customer.ssn_last_4 && values.customer.ssn_last_4.length !== 4) ? I18n.t('customer.create.ssnLast4LengthError') : undefined);
  }

  if (props.primaryFacilityIsRequired && (props.customer && props.customer.type === 'medical') && !(values.customer && values.customer.primary_facility_id)) {
    errors.customer.primary_facility_id = I18n.t('customers.create.primaryFacilityIsRequired');
  }

  if (props.onfleetIsEnabled || isOhMetrc) {
    errors.customer.number = requiredFieldValidation(values.customer.number, 'customers.create.phone');
  }

  if (!values.customer.first_name) {
    errors.customer.first_name = I18n.t('customers.create.enterFirstName');
  }
  if (!values.customer.last_name) {
    errors.customer.last_name = I18n.t('customers.create.enterLastName');
  }
  if (!values.customer.birth_date) {
    errors.customer.birth_date = I18n.t('customers.create.enterBirthDate');
  } else if(
    props.isPaLeaf &&
    /^\d?\d\/\d?\d$/.test(values.customer.birth_date)
  ) {
    errors.customer.birth_date = I18n.t('customers.create.provideBirthYear');
  } else if (values.customer.birth_date) {
    errors.customer.birth_date = checkDateOfBirthValidation(new Date(values.customer.birth_date), values.customer.type, props.salesAgeLimit);
  }

  // Physican ID Required check
  if (props.patientSettings.doctorRequiredForTempPaperwork && hasTempId) {
    if (!values.customer.physician_id) {
      errors.customer.physician_id = I18n.t(
        'customers.physicianNameIsRequiredWithTempPaperwork'
      );
    }
  }

  if (props.isWaLeaf) {
    errors.customer.state_integration_tracking_id = requiredFieldValidation(
      values.customer.state_integration_tracking_id
    );
  }

  if (values.customer.state_integration_tracking_id) {
    if (
      incorrectCharsRegExp.test(values.customer.state_integration_tracking_id)
    ) {
      errors.customer.state_integration_tracking_id = I18n.t(
        'customers.create.invalidFormat'
      );
    }
  }
  if (values.caregiverInfo && values.caregiverInfo.length) {
    errors.caregiverInfo = values.caregiverInfo.map(caregiver => ({
      state_integration_tracking_id:
        isMetrcMd &&
        caregiver &&
        caregiver.state_integration_tracking_id &&
        typeof caregiver.state_integration_tracking_id === 'string'
          ? caregiver.state_integration_tracking_id.length < 16
            ? 'Identification Number Must Be 16 Characters Long'
            : undefined
          : undefined,
      medical_id: isOhMetrc && caregiver && caregiver.medical_id && !(/^(([A-Z0-9]){4}-){4}([A-Z0-9]){4}$/ig.test(caregiver.medical_id))
        ? I18n.t('customers.create.medicalMarijuanaIdFormat')
        : undefined
    }));
  }
  if (!values.customer.gender) {
    errors.customer.gender = I18n.t('customers.create.selectGender');
  }
  if (!values.customer.active) {
    errors.customer.active = I18n.t('customers.create.selectStatus');
  }
  if (!values.customer.tax_exempt) {
    errors.customer.tax_exempt = I18n.t('customers.create.selectTaxExempt');
  }
  if (!values.customer.type) {
    errors.customer.type = I18n.t('customers.create.selectType');
  }
  errors.customer.in_state = requiredFieldValidation(values.customer.in_state);

  //compliance limit block
  if (values.limitsInfo.facility_sales_limit) {
    const patientOverrideLimit = parseInt(values.limitsInfo.facility_sales_limit);
    errors.limitsInfo.facility_sales_limit = isInteger(
      patientOverrideLimit,
      'general.errors.integer',
      true
    );
  }
  if (
    parseInt(values.limitsInfo.facility_possession_limit) <
      parseFloat(
        props.complianceLimits.default_values.facility_possession_limit
      ) &&
    parseFloat(values.limitsInfo.facility_possession_limit) !==
      parseFloat(props.complianceLimits.facility_possession_limit)
  ) {
    errors.limitsInfo.facility_possession_limit = I18n.t(
      'customers.create.salesLimitError',
      { limit: props.complianceLimits.default_values.facility_possession_limit }
    );
  }
  if (
    parseInt(values.limitsInfo.facility_plants_veg_limit) <
      parseInt(
        props.complianceLimits.default_values.facility_plants_veg_limit
      ) &&
    parseInt(values.limitsInfo.facility_plants_veg_limit) !==
      parseInt(props.complianceLimits.facility_plants_veg_limit)
  ) {
    errors.limitsInfo.facility_plants_veg_limit = I18n.t(
      'customers.create.salesLimitError',
      { limit: props.complianceLimits.default_values.facility_plants_veg_limit }
    );
  }
  if (
    parseInt(values.limitsInfo.facility_plants_total_limit) <
      parseInt(
        props.complianceLimits.default_values.facility_plants_total_limit
      ) &&
    parseInt(values.limitsInfo.facility_plants_total_limit) !==
      parseInt(props.complianceLimits.facility_plants_total_limit)
  ) {
    errors.limitsInfo.facility_plants_total_limit = I18n.t(
      'customers.create.salesLimitError',
      {
        limit: props.complianceLimits.default_values.facility_plants_total_limit
      }
    );
  }
  if (
    parseInt(values.limitsInfo.facility_plants_flower_limit) <
      parseInt(
        props.complianceLimits.default_values.facility_plants_flower_limit
      ) &&
    parseInt(values.limitsInfo.facility_plants_flower_limit) !==
      parseInt(props.complianceLimits.facility_plants_flower_limit)
  ) {
    errors.limitsInfo.facility_plants_flower_limit = I18n.t(
      'customers.create.salesLimitError',
      {
        limit:
          props.complianceLimits.default_values.facility_plants_flower_limit
      }
    );
  }

  if (
    props.applicationMode === applicationModes.metrcDc &&
    values.addresses &&
    values.addresses[0] &&
    values.addresses[0].province_code === 'MD'
  ) {
    if (values.customer.extra_data) {
      errors.customer.extra_data = {
        integration_metrc_identification_method: requiredFieldValidation(
          values.customer.extra_data.integration_metrc_identification_method
        )
      };
    } else {
      errors.customer.extra_data = {
        integration_metrc_identification_method: requiredFieldValidation(
          values.customer.extra_data
        )
      };
    }
    if (
      values.customer &&
      values.customer.extra_data &&
      values.customer.extra_data.integration_metrc_identification_method &&
      !alphaNumericValidation(
        values.customer.extra_data.integration_metrc_identification_method
      )
    ) {
      errors.customer.extra_data = {
        integration_metrc_identification_method: I18n.t(
          'customers.create.identificationMethodError'
        )
      };
    }
  }
  return errors;
};

export default validate;
