const payload = (formValues) => {
  let facilities = [];
  let customerGroups = [];

  if (formValues.customer_groups && formValues.customer_groups.length > 0) {
    customerGroups = formValues.customer_groups.map(customer_group => {
      return {
        consumer_group_id: customer_group.customer_group_id,
        percentage_of_base: customer_group.percentage_of_base
      };
    });
  }

  if (formValues.facilities && formValues.facilities.length > 0) {
    facilities = formValues.facilities.map(facility => {
      if (Number.isInteger(facility)) {
        return { facility_id: facility };
      }

      return { facility_id: facility.id };
    });
  }

  return {
    name: formValues.name,
    active: formValues.active,
    consumer_groups: customerGroups,
    facilities: facilities
  };
};

export default payload;
