import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';
import {getUnpaginatedData, putData} from '../../actions/apiActions';
import {handleComplexSelectRow} from '../../actions/helpers/selectedDataHelper';
import * as dataNames  from '../../constants/dataNames';
import * as p  from '../../constants/permissions';
import {getTaxProfilesPageProps} from '../../selectors/taxProfilesSelectors';
import PageTitle from '../common/PageTitle';
import InProgressOverlay from '../common/InProgressOverlay';
import TablePageWrapper from '../common/grid/TablePageWrapper';
import SingleActionColumn from '../common/grid/columns/SingleActionColumn';


export class TaxProfilesPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.stopLoader = this.stopLoader.bind(this);
    this.editTaxProfile = this.editTaxProfile.bind(this);
    this.activateTaxProfile = this.activateTaxProfile.bind(this);
    this.inactivateTaxProfile = this.inactivateTaxProfile.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.switchTab = this.switchTab.bind(this);
    this.loadTaxesData = this.loadTaxesData.bind(this);

    const columns = [
      {
        name: 'taxProfiles.table.name',
        dataId: 'tax_profile_name',
        // width: '200px',
        dataAlign: 'left',
        headerAlign: 'left',
      },
      {
        name: 'taxProfiles.table.customerType',
        dataId: 'customer_types',
        // width: '200px',
        dataAlign: 'left',
        headerAlign: 'left'
      },
      {
        name: 'taxProfiles.table.inState',
        dataId: 'in_state',
        // width: '80px',
        dataAlign: 'left',
        headerAlign: 'left'
      },
      {
        name: 'taxProfiles.table.facilities',
        dataId: 'facilities',
        // width: '200px',
        dataAlign: 'left',
        headerAlign: 'left',
      },
      {
        name: 'taxProfiles.table.weight',
        dataId:'weight',
        dataSort: true,
        hidden: false
      },
    ];

    const activeColumns = columns.concat([
      {
        dataSort: false,
        width: '120px',
        perimissions: [p.manage_taxes],
        csvFormatter: () => I18n.t('taxProfiles.actions.inactivate'),
        formatter: SingleActionColumn({
          permissions: [p.manage_taxes],
          action: this.inactivateTaxProfile,
          label: 'taxProfiles.actions.inactivate',
        }),
      },
    ]);

    const inactiveColumns = columns.concat([
      {
        dataSort: false,
        width: '120px',
        perimissions: [p.manage_taxes],
        csvFormatter: () => I18n.t('taxProfiles.actions.activate'),
        formatter: SingleActionColumn({
          permissions: [p.manage_taxes],
          action: this.activateTaxProfile,
          label: 'taxProfiles.actions.activate',
        }),
      },
    ]);

    const tabs = [
      {
        id: 'activeTab',
        eventKey: 'active',
        title: 'taxProfiles.table.active',
        path: '/taxes/profiles/active',
        actions: [
          {
            id: 'addTaxProfile',
            path: '/taxes/profiles/create',
            text: 'taxProfiles.actions.create',
            requireSelect: false,
            glyph: 'plus-sign',
          },
          {
            id: 'editTaxProfile',
            func: this.editTaxProfile,
            text: 'taxProfiles.actions.edit',
            requireSelect: true,
            glyph: 'edit',
          },
          {
            id: 'reorderTaxes',
            path:'/taxes/profiles/reorder',
            text: 'taxProfiles.actions.reorderTaxes',
            glyph:'list-alt',
            requireSelect: false}
        ],
      },
      {
        id: 'inactiveTab',
        eventKey: 'inactive',
        title: 'taxProfiles.table.inactive',
        path: '/taxes/profiles/inactive',
        actions: [
          {
            id: 'editTaxProfile',
            func: this.editTaxProfile,
            text: 'taxProfiles.actions.edit',
            requireSelect: true,
            glyph: 'edit',
          },
        ],
      },
    ];

    this.state = {
      activeColumns,
      inactiveColumns,
      tabs,
      loading: true,
      message: 'taxProfiles.get.loading',
    };
  }

  componentWillMount() {
    const {actions: {handleComplexSelectRow}} = this.props;
    this.loadTaxesData();
    handleComplexSelectRow([], dataNames.taxProfiles, 'clear');
  }

  loadTaxesData() {
    const {actions: {getUnpaginatedData}} = this.props;
    this.stopLoader(
      getUnpaginatedData('/api/tax_profiles', dataNames.taxProfiles, {failed: 'taxProfiles.get.failed'}),
      getUnpaginatedData('/api/facilities', dataNames.facilities)
    );
  }

  stopLoader(promise) {
    return promise
      .then(() => this.setState({loading: false}))
      .catch(() => this.setState({loading: false}));
  }

  editTaxProfile() {
    const {selectedRows: [id]} = this.props;
    this.props.actions.push(`/taxes/profiles/${id}`);
  }

  inactivateTaxProfile(event, taxProfile) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({loading: true, message: 'taxProfiles.inactivate.loading'});
    const {actions: {putData}} = this.props;
    this.stopLoader(
      putData(
        `/api/tax_profiles/${taxProfile.id}`,
        {active: 0},
        dataNames.taxProfiles,
        {success: 'taxProfiles.inactivate.success', failed: 'taxProfiles.inactivate.failed'},
        this.loadTaxesData
      )
    );
  }

  activateTaxProfile(event, taxProfile) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({loading: true, message: 'taxProfiles.activate.loading'});
    const {actions: {putData}} = this.props;
    this.stopLoader(
      putData(
        `/api/tax_profiles/${taxProfile.id}`,
        {active: 1},
        dataNames.taxProfiles,
        {success: 'taxProfiles.activate.success', failed: 'taxProfiles.activate.failed'},
        this.loadTaxesData
      )
    );
  }

  handleSelect(row) {
    const {selectedRows} = this.props;
    const action = selectedRows.indexOf(row.id) > -1 ? 'remove' : 'set';
    this.props.actions.handleComplexSelectRow([row], dataNames.taxProfiles, action);
  }

  switchTab(activeTab) {
    const {tabs} = this.state;
    const tab = tabs.find(tab => tab.eventKey === activeTab) || tabs[0];
    this.props.actions.push(tab.path);
  }

  render() {
    const {data, selectedRows, activeTab} = this.props;
    const {tabs, activeColumns, inactiveColumns, loading, message} = this.state;
    const columns = activeTab === 'inactive' ? inactiveColumns : activeColumns;

    return (
      <div>
        <PageTitle primaryText={I18n.t('taxProfiles.table.title')}/>
        <InProgressOverlay isActive={loading} message={message} translate={true}/>
        <TablePageWrapper
          settingKey='tax-profiles'
          columns={columns}
          data={data}
          selectedRows={selectedRows}
          handleSelect={this.handleSelect}
          hideExport={true}
          hideScanSearch={true}
          external={false}
          tabs={tabs}
          activeTab={activeTab}
          switchTab={this.switchTab}
          bstProps={{
            selectRow: {
              mode: 'radio',
              selected: {selectedRows},
              onSelect: this.handleSelect,
              className: 'table table-sm table-bordered table-striped retail-group-table',
            },
            options: {
              defaultSortName: 'weight',
              defaultSortOrder: 'asc',
            },
          }}
        />
      </div>
    );
  }
}

TaxProfilesPage.propTypes = {
  actions: PropTypes.shape({
    getUnpaginatedData: PropTypes.func.isRequired,
    putData: PropTypes.func.isRequired,
    handleComplexSelectRow: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }),
  selectedRows: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  activeTab: PropTypes.string.isRequired,
};

function mapDispatchToProps(dispatch) {
  const actions = {getUnpaginatedData, putData, handleComplexSelectRow, push};

  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(getTaxProfilesPageProps, mapDispatchToProps)(TaxProfilesPage);
