import React from 'react';
import PropTypes from 'prop-types';

const Pagination = (props) => {

  const numPagers = 5;

  const {first, last} = props;
  const nouns = {singular: 'Product', plural: 'Products'};
  const perPage = props.perPage;
  const numberOfPages = Math.ceil(props.numRecords / perPage);
  const visiblePages = (numberOfPages <= numPagers) ? numberOfPages : numPagers;
  let pagersStart = 0;

  if(numberOfPages > numPagers && props.page > 2){
    pagersStart = props.page - 2;
  }

  let pagersEnd = pagersStart + visiblePages;

  if(pagersEnd > numberOfPages){
    pagersEnd = numberOfPages;
    pagersStart = pagersEnd - numPagers;
  }

  const pages = [];
  for(let i = pagersStart; i < pagersEnd; i++){
    pages.push({
      number: (i + 1),
      className: ((i + 1) === props.page) ? 'active' : ''
    });
  }

  const nextPage = (props.page < numberOfPages) ? (pages.find( (page) => page.number == props.page + 1)) : false;
  const prevPage = (props.page > 1) ? (pages.find( (page) => page.number == props.page - 1)) : false;

  return (
    <div className='clearfix'>
      <div className='col-md-12'>
        <div style={{float: 'left', width: '50%'}}>Showing {first} to {last} of {props.numRecords} {props.numRecords > 1 ? nouns['plural'] : nouns['singular']}</div>
        <ul className='pagination' style={{float: 'right', marginTop: '0'}}>
          {props.page > 1
            ? <li><a href='#' onClick={(event) => {props.onPageChange(event, {number: 1});}}>{'<<'}</a></li>
            : ''}
          {prevPage
            ? <li><a href='#' onClick={(event) => {props.onPageChange(event, prevPage);}}>{'<'}</a></li>
            : ''}
          {pages.map( (page, index) => {
            return (
              <li key={index}
                  className={page.className}>
                <a
                  href='#'
                  onClick={(event) => {
                    props.onPageChange(event, page);
                  }}>
                  {page.number}
                </a>
              </li>
            );
          })}
          {nextPage
            ? <li><a href='#' onClick={(event) => {props.onPageChange(event, nextPage);}}>{'>'}</a></li>
            : ''}
          {props.page !== numberOfPages
            ? <li><a href='#' onClick={(event) => {props.onPageChange(event, numberOfPages);}}>{'>>'}</a></li>
            : ''}
        </ul>
      </div>
    </div>
  );

};

Pagination.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  first: PropTypes.number.isRequired,
  last: PropTypes.number.isRequired
};

export default Pagination;
