import PropTypes from 'prop-types';
import {reduxForm, getFormValues} from 'redux-form';
import {connect} from 'react-redux';

import AutoPriceForm from './AutoPriceForm';
import validate from './validate';

const AutoPriceReduxForm = reduxForm({
  validate
})(AutoPriceForm);

function mapStateToProps(state, ownProps) {
  const form = 'auto-price-form';
  return {
    form,
    formValues: getFormValues(form)(state) || ownProps.initialValues || {}
  };
}

const AutoPriceFormWrapper = connect(mapStateToProps)(AutoPriceReduxForm);

AutoPriceFormWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object
};

export default AutoPriceFormWrapper;
