import * as types from '../constants/actionTypes';
import {getData, getItem} from '../actions/apiActions';
import {setUserPermissions, updateUser} from '../actions/userActions';
import {unsetItem} from '../actions/itemActions';
import * as itemNames from '../constants/itemNames';
import {getFacilitiesSuccess} from '../actions/facilityActions';
import {getActionsFromBatchAction} from './forms/utils';

// Download the notifications for a user after they select a facility.
export const getDataUpdates = store => next => action => {
  const result = next(action);
  const actions = getActionsFromBatchAction(action).filter(action => action.type === types.SERVER_DATA_UPDATE_AVAILABLE);
  actions.forEach(action => {
    const {user} = store.getState();
    switch (action.payload.name) {
    case 'permissions': {
      if(user && user.id && user.permissions){
        const promises = Object.keys(user.permissions).map(facility_key => {
          return new Promise ((resolve, reject) => {
            const facility = user.permissions[facility_key];
            store.dispatch(getData(`/api/users/${user.id}/facilities/${facility.id}/permissions`,
              null, undefined, {keys: 1}, (data) => {
                const permissions = {};
                data.map(p => permissions[p] = p);
                resolve({...facility, permissions});
              }));
          });
        });
        Promise.all(promises).then(results => {
          const permissions = {};
          results.map(facilty => permissions[facilty.facility_key] = facilty);
          store.dispatch(setUserPermissions(permissions));
        });

      }
      break;
    }
    case 'user': {
      store.dispatch(getData(`/api/users/${user.id}`, null, null, null, (response) => {
        if (response.image_file_id !== user.image_file_id) {
          if (response.image_file_id) {
            store.dispatch(getItem(`/api/images/${response.image_file_id}`, itemNames.profileImage));
          } else {
            store.dispatch(unsetItem(itemNames.profileImage));
          }
        }
        store.dispatch(updateUser(response));
        store.dispatch(getData(`/api/users/facilities`, null, null, null, (response) => {
          store.dispatch(getFacilitiesSuccess(response));
        }));
      }));
      break;
    }
    default:
    }
  });
  return result;
};

export default getDataUpdates;
