import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import {Field} from 'redux-form';
import NumericInput from '../../../common/form/NumericInput';
import InternationalQuantityByUomInput from '../../../common/form/QuantityByUomInput';
import InternationalCurrencyInput from '../../../common/form/InternationalCurrencyInput';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import WillRender from '../../../common/concealers/WillRender';
import {UOM_UNIT_VALUE_PRECISION} from '../../../../constants/uoms';

const UnitLineItem = ({line, showContainerInputs, change, transferRecord, disabled, hideItemPrices, uom}) => {

  const { abbrev } = I18n.t(`uoms.${uom}`);

  return (
  <Col lg={8}>
    <Row>
      <Col lg={2}>
        {showContainerInputs
          ? <Field name={`${line}.container_type_id`}
              component={ReactSelectInput}
              props={{
                label: I18n.t('purchaseOrders.form.containerType'),
                options: [],
                textKey: 'name',
                valueKey: 'id'
              }} />
         : null}
      </Col>
      <Col lg={2}>
        {showContainerInputs
          ? <Field name={`${line}.container_count`}
              component={NumericInput}
              props={{
                label: I18n.t('purchaseOrders.form.numContainers'),
              }}/>
          : null}
      </Col>
      <Col lg={3}>
        <InternationalQuantityByUomInput
          name={`${line}.qty`}
          disabled={!!(transferRecord || disabled)}
          onFocus={(event) => {
            change(`${line}.validate`, false);
          }}
          onBlur={(event) => {
            change(`${line}.validate`, true);
          }}
          uom={uom}
          label={I18n.t('purchaseOrders.form.numUnits')}
          fractionPartSize={UOM_UNIT_VALUE_PRECISION}
          allowNegativeNumber={false}
        />
      </Col>

      <WillRender ifFalse={hideItemPrices}>
        <Col lg={2}>
          <InternationalCurrencyInput
            name={`${line}.unit_price`}
            props={{
              label: I18n.t('purchaseOrders.form.pricePer', {label: abbrev}),
              placeholder:'',
              disabled:!!(transferRecord || disabled),
              isRequired:false
            }} />
        </Col>
      </WillRender>
      <WillRender ifFalse={hideItemPrices}>
        <Col lg={3}>
          <InternationalCurrencyInput
            name={`${line}.line_item_price`}
            props={{
              label: I18n.t('purchaseOrders.form.itemPrice'),
              placeholder:'',
              disabled:true,
              isRequired:false
            }} />
        </Col>
      </WillRender>
    </Row>
  </Col>);
};

UnitLineItem.propTypes = {
  change: PropTypes.func.isRequired,
  line: PropTypes.string.isRequired,
  showContainerInputs: PropTypes.bool,
  disabled: PropTypes.bool,
  hideItemPrices: PropTypes.bool,
  transferRecord: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ]).isRequired,
  uom: PropTypes.string.required
};

UnitLineItem.defaultProps = {
  showContainerInputs: true
};

export default UnitLineItem;
