import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { Row } from 'react-bootstrap';

const BrowserData = ({ browser }) => {
  const minimums = {
    chrome: 45, // fall 2015
    firefox: 41 // fall 2015, 34 = 2014
    //ie: 11, // fall 2015 removed per Andy
    //edge: 15, // summer 2015 removed per Andy
    //opera: 32 // fall 2015 removed per Andy
  };

  const meetsMinimums = () => {
    if (!isDefined()) return false;
    const versionNumbers = browser.version.split('.');
    const version =
      versionNumbers.length > 1 ? parseFloat(`${versionNumbers[0]}.${versionNumbers[1]}`) : parseInt(versionNumbers[0]);
    return minimums[browser.name] <= version;
  };

  const isDefined = () => {
    return minimums[browser.name];
  };

  return (
    <Row className='login-footer'>
      <div style={{ textAlign: 'center' }}>
        <div>
          Browser: <span style={{ textTransform: 'capitalize' }}>{browser.name}</span> Version: {browser.version}
        </div>
        {meetsMinimums() ? (
          <div className='text-muted'>
            {window.location.hostname.includes('leafdatasystems')
              ? I18n.t('browser.okTextLeaf')
              : I18n.t('browser.okText')}
          </div>
        ) : browser.name !== 'safari' ? (
          <div className='text-danger'>{I18n.t('browser.uncertainText')}</div>
        ) : (
          <div className='text-danger' style={{ fontWeight: 'bold', textAlign: 'center' }}>
            {I18n.t('browser.safariNotSupported')}
          </div>
        )}
      </div>
    </Row>
  );
};

BrowserData.propTypes = {
  browser: PropTypes.object
};

export default BrowserData;
