import React from 'react';
import {connect} from 'react-redux';
import get from 'lodash.get';
import debounce from 'lodash.debounce';
import {bindActionCreators} from 'redux';
import PropTypes from 'prop-types';
import * as apiActions from '../../../actions/apiActions';
import {getQzTray, signQzRequest} from '../../../actions/printerActions';
import {setItem} from '../../../actions/itemActions';
import {setData} from '../../../actions/dataActions';
import * as itemNames from '../../../constants/itemNames';
import * as dataNames from '../../../constants/dataNames';
import {getDefaultLabelPrinter} from '../../../selectors/labelsSelectors';

export class QZTray extends React.PureComponent {

  constructor(props, context){
    super(props, context);
    this.state = {connects: 0};
    this.loadedLocalPrinters = false;
  }

  componentWillMount() {
    if(!this.props.qzTray || !this.props.qzTray.websocket || !this.props.qzTray.websocket.isActive()) {
      this.props.actions.getQzTray(this.props.actions.signQzRequest);
    }
    const selectedPrinter = Object.assign({}, this.props.selectedPrinter, {qzConnected: false});
    delete(selectedPrinter.qzRetry);
    // Move the setItem and setData calls to actions maybe?  Not sure why these need to be deferred but they dont throw errors if they are
    setTimeout(() => { // This prevents a a null error in debounce middleware... not sure why but is fix for 1.1.6.
      this.props.actions.setItem(selectedPrinter, itemNames.selectedPrinter);
    }, 100);
  }

  componentWillReceiveProps(nextProps){
    const props = nextProps;
    const setDefaultPrinter = () => {
      return new Promise((resolve) => {
        const newSelectedPrinter = Object.assign({}, props.selectedPrinter, {[props.labelTag]: props.defaultPrinter});
        setTimeout(() => {  // This prevents a a null error in debounce middleware... not sure why but is fix for 1.1.6.
          this.props.actions.setItem(newSelectedPrinter, itemNames.selectedPrinter);
        }, 100);
        resolve(newSelectedPrinter);
      });
    };

    const tryQzConnection = (selectedPrinter) => { // If theres a default printer and its remote we intentionally dont connect to the qz tray automatically
      const qzRetry = () => selectedPrinter.qzRetry === undefined || selectedPrinter.qzRetry;
      if (!selectedPrinter.qzConnected && qzRetry()) {
        this.tryQzConnection(Object.assign({}, nextProps, {selectedPrinter}));
      }
    };

    if(JSON.stringify(nextProps.selectedPrinter[nextProps.labelTag]) !== JSON.stringify(nextProps.defaultPrinter)){
      setDefaultPrinter()
        .then((selectedPrinter) => {
          tryQzConnection(selectedPrinter);
        });
    } else {
      tryQzConnection(nextProps.selectedPrinter);
    }

  }

  tryQzConnection(props = false, refresh = false){
    if(!props) props = this.props;
    if(props.uiOnly && !refresh) return false;
    this.setState({connects: this.state.connects + 1});
    setTimeout(() => {
      const setQzTrayConnectedOriginal = (status) => {
        if(!status) {
          this.props.actions.setItem({skipAutoConnect: true}, itemNames.qzMeta);
          return false;
        }
        this.props.actions.setItem({skipAutoConnect: false}, itemNames.qzMeta);
        const newPrinter = Object.assign({}, props.selectedPrinter, {qzConnected: status, qzRetry: false});
        props.actions.setItem(newPrinter, 'selectedPrinter');
        if(status && !this.loadedLocalPrinters || (!status && refresh)) {
          props.qzTray.printers.find()
            .then((printers) => {
              this.loadedLocalPrinters = true;
              setTimeout(() => {  // This prevents a a null error in debounce middleware... not sure why but is fix for 1.1.6.
                this.props.actions.setData(printers, dataNames.localPrinters);
              });
            })
            .catch(() => {
              setTimeout(() => {  // This prevents a a null error in debounce middleware... not sure why but is fix for 1.1.6.
                this.props.actions.setData([], dataNames.localPrinters);
              });
            });
        }
      };
      const setQzTrayConnected = debounce(setQzTrayConnectedOriginal, 3000);
      const skipAutoConnect = get(props, 'qzMeta.skipAutoConnect', false);
      if(!skipAutoConnect || (skipAutoConnect && refresh)) {
        if (!props.qzTray.websocket.isActive()) {
          props.qzTray.websocket.connect()
            .then(() => {
              setQzTrayConnected(true);
            })
            .catch(() => setQzTrayConnected(false));
        } else {
          setQzTrayConnected(true);
        }
      }
    }, 500);
  }

  render(){

    if(this.props.headless) return null;

    return (<div>
      {this.props.label ? `${this.props.label}: ` : null}
      {
        this.props.selectedPrinter.qzConnected
          ? <span>Connected</span>
          : null
      }
      <a href style={{cursor: 'pointer'}} onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        this.tryQzConnection(false, true);
      }}
      >
        {
          this.props.selectedPrinter.qzConnected
            ? <span style={{cursor: 'pointer'}}> (Refresh Connection)</span>
            : <span style={{cursor: 'pointer'}}>Click To Connect (Attempts: {this.state.connects} | QZ Tray must be running)</span>
        }
      </a>
    </div>);
  }
}

QZTray.propTypes = {
  qzTray: PropTypes.object,
  qzMeta: PropTypes.object,
  actions: PropTypes.object,
  selectedPrinter: PropTypes.object,
  label: PropTypes.string,
};

function mapStateToProps(state, ownProps){
  return {
    qzTray: state[itemNames.qzTray],
    qzMeta: state[itemNames.qzMeta],
    selectedPrinter: state[itemNames.selectedPrinter],
    defaultPrinter: getDefaultLabelPrinter(state, {tag: ownProps.labelTag}),
    localPrinters: state[dataNames.localPrinters],
    labelsCompliance: state[itemNames.labelsCompliance],
  };
}

function mapDispatchToProps(dispatch){
  const actions = Object.assign({}, {getQzTray, signQzRequest, setItem, setData}, apiActions);
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QZTray);
