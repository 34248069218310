import {
  FORM_DISMISS_DIRTY,
  FORM_IS_SUBMITTING,
  FORM_KEEP_DIRTY,
  FORM_REGISTER_AS_OPEN,
  FORM_SUBMIT_COMPLETE,
  FORM_UNREGISTER_AS_OPEN,
  FORM_WARN_PENDING_CHANGES
} from '../../constants/actionTypes';
import initialState from '../../constants/initialState';

const nextPathDefault = null;
const warnDefault = false;
const dismissedDefault = false;

const getPayload = (action) => action.payload || {};

/**
 * Standard method for updating attributes for tracking a given form on a given page (path)
 *
 * @param state
 * @param path
 * @param formName
 * @param formAttributes
 */
const updateOpenFormAttributes = (state, path, formName, formAttributes) => {
  const newState = {
    ...state,
    [path]: {
      ...state[path],
      forms: {
        ...state[path].forms,
        [formName]: formAttributes
      }
    }
  };

  // Leaving this for easy debugging
  // console.log('updateOpenFormAttributes newState', newState);

  return newState;
};


const openFormReducer = (state = initialState.openForms, action) => {

  const payload = getPayload(action);
  const {formName, path, nextPath} = payload;
  let newState;

  switch(action.type) {

  case FORM_REGISTER_AS_OPEN:
    newState = {...state};

    if (!newState[path]) newState[path] = {forms: {}};

    return updateOpenFormAttributes(newState, path, formName, {
      formName,
      path,
      nextPath: nextPathDefault,
      warn: warnDefault,
      dismissed: dismissedDefault
    });

  case FORM_UNREGISTER_AS_OPEN:
    newState = JSON.parse(JSON.stringify(state));

    if (!newState[path].forms.length) {
      delete newState[path];
    }

    return newState;

  case FORM_WARN_PENDING_CHANGES:
    return updateOpenFormAttributes(state, path, formName, {
      formName,
      path,
      nextPath,
      warn: true,
      dismissed: dismissedDefault
    });

  case FORM_DISMISS_DIRTY:
    return updateOpenFormAttributes(state, path, formName, {
      formName,
      path,
      nextPath: nextPathDefault,
      warn: true,
      dismissed: true
    });

  case FORM_KEEP_DIRTY:
    return updateOpenFormAttributes(state, path, formName, {
      formName,
      path,
      nextPath: nextPathDefault,
      warn: warnDefault,
      dismissed: dismissedDefault
    });

  case FORM_IS_SUBMITTING:
    return updateOpenFormAttributes(state, path, formName, {
      ...state[path].forms[formName],
      submitting: true
    });

  case FORM_SUBMIT_COMPLETE:
    return updateOpenFormAttributes(state, path, formName, {
      ...state[path].forms[formName],
      submitting: false
    });

  default:
    return state;
  }
};

export default openFormReducer;
