import React from 'react';
import PropTypes from 'prop-types';
import {Card, Image, Row, Col} from 'react-bootstrap';
import {FaDollarSign, FaGlobeAmericas, FaMapMarkerAlt, FaPhone, FaShoppingCart, FaStar} from 'react-icons/fa'; //eslint-disable-line
import {I18n} from 'react-redux-i18n';
import moment from 'moment';
import get from 'lodash.get';
import {convertDbToClientTZ} from '../../util/dateHelpers';
import InternationalDateTimeStatic from '../common/form/InternationalDateTimeStatic';
import CustomerTileButton from './CustomerTileButton';
import * as p from '../../constants/permissions';
import PermissionWrapper from '../common/PermissionWrapper';
// eslint-disable-next-line import/no-named-as-default
import OrderAndQueueActions from './common/OrderAndQueueActions';

const CustomerTile = ({customer, selectCustomer, selectProducts,
  fillOrder, checkout, orderType, timezone, cancelOrderAndReload,
  patientComplianceSettings, isLockPreCheckinOnlineOrder}) => {

  const borderClass = customer.type === 'recreational' ? 'customer-type-recreational' : 'customer-type-medical';

  const startTime = customer.order
    ? convertDbToClientTZ(customer.order.created_at, timezone)
    : (customer.checked_in ? convertDbToClientTZ(customer.checked_in, timezone) : null);

  const customerIsCheckedIn = (customer, returnType = 'color') => {
    return returnType === 'color'
      ? customer.checked_in
        ? 'green' : '#ccc'
      : customer.checked_in
        ? 'Customer Is Present' : 'Customer Is Not Present';
  };

  const getOrderTypeText = (customer) => {
    const map = {
      in_store: {
        text: 'In Store Order'
      },
      phone: {
        text: 'Phone Order For Pickup'
      },
      online: {
        text: 'Online Order For Pickup'
      }
    };
    return !customer.order_source || customer.order_source === 'in_store'
      ? map.in_store['text']
      : customer.order_source === 'phone'
        ? map.phone['text']
        : map.online['text'];
  };

  const fulfillmentMethodMap = (method) => {
    if (!method) return '';

    const translationKey = {
      in_store: 'inStore',
      curbside: 'curbside'
    }[method];

    return I18n.t(`customer.title.${translationKey}`);
  };

  const isCustomerNameTooLong = customer.consumer_name.length > 50;

  // to be brought back once this behavior is nailed down
  // const getInventoryStatus = (customer, returnType = 'color') => {
  //   return returnType === 'color'
  //     ? customer.checked_in
  //       ? 'green' : 'red'
  //     : customer.checked_in
  //       ? 'Order Filled' : 'Order Pending Inventory Fulfillment';
  // };

  return (
    <Card style={{minWidth: '200px', maxWidth: '200px', margin: '6px'}}>
      <Card.Body className={`${borderClass} patient-info`} style={{paddingTop: '0', paddingBottom: '3px', height: 'auto' }}>
        <Row style={{borderBottom: '1px solid #ccc', height:'32px', textAlign: 'center'}}>
          <Col md='2' style={{borderRight: '1px solid #ccc', paddingLeft: '0', paddingRight: '0'}}>
            {(customer.order_source && customer.order_source !== 'in_store' && customer.fulfillment_method === 'in_store')
                ? <FaMapMarkerAlt
                  fontSize='20px'
                  title={customerIsCheckedIn(customer, 'title')}
                  style={{
                    color: customerIsCheckedIn(customer),
                  }}
                />
                : <FaStar
                  fontSize='20px'
                  title={getOrderTypeText(customer, 'text')}
                  style={{color: (customer.prefersCurrentUser) ? 'gold' : '#ccc'}}
                />
            }
          </Col>
          <Col md='8' style={{borderRight: '1px solid #ccc',fontSize: '10px', textTransform: 'uppercase', paddingTop: '6px', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
            {customer.type}
          </Col>
          <Col md='2' style={{paddingLeft: '0', paddingRight: '0'}}>
            {!customer.order_source || customer.order_source === 'in_store'
              ? <FaShoppingCart fontSize='20px' className={customer.order_started ? 'text-success' : ''} title={getOrderTypeText(customer, 'text')}/>
              : customer.order_source === 'phone'
                ? <FaPhone fontSize='20px' className={customer.order_started ? 'text-success' : ''} title={getOrderTypeText(customer, 'text')}/>
                : <FaGlobeAmericas fontSize='20px' className={customer.order_started ? 'text-success' : ''} title={getOrderTypeText(customer, 'text')}/>
            }
          </Col>
        </Row>
        <Row>
          <Col md='2'>
            {Boolean(customer.order && customer.order.is_payment_attempted && !customer.order.payment_completed) &&
              <FaDollarSign
                className='pulse text-danger'
                title={I18n.t('customer.paymentIsNotCompleted')}
                size='sm'
                style={{position: 'absolute', top: '2px', left: '2px', fontSize: '3rem'}}
              />
            }
          </Col>
          <Col md='8'
            style={{textAlign: 'center', height: '24px', paddingTop: '6px', fontWeight: 'bold', cursor: 'pointer', position:'relative'}}
            className='text-danger'
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              cancelOrderAndReload(customer);
              event.target.blur();
            }}
          >
            { // Show 'Force complete order' label for locked orders that got stuck somehow
              (get(customer, 'order.locked') && get(customer, 'order.payment_complete') && get(customer, 'order.payment_complete') === 'completed')
                ? 'Force Complete Order'
                : 'Cancel Order'
            }
          </Col>
          <Col md='2'/>
        </Row>
        <Row>
          <Col xs={12} className='customer-col'>
            {
              (customer.prefersCurrentUser && customer.rocks) ? <FaStar title='You are the preferred CSR for this customer.' style={{position: 'absolute', top: '-5px', left: '2px', fontSize: '2rem', color: 'gold'}}/> : null
            }
            {
              (customer.order_source && customer.order_source !== 'in_store' && customer.rocks)
                ? <FaMapMarkerAlt
                  title={customerIsCheckedIn(customer, 'title')}
                  style={{
                    position: 'absolute',
                    top: '-5px',
                    left: '2px',
                    fontSize: '2rem',
                    color: customerIsCheckedIn(customer),
                  }}
                  glyph='map-marker'/>
                : null
            }
            <Image className='customer-image rounded-circle' style={{color:'red'}} src={customer.image_url} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className='customer-col'>
            {customer && customer.order && customer.order.consumer_birth_date ? 'DOB: ' + moment(customer.order.consumer_birth_date).format('MM-DD-YYYY') : <span>&nbsp;</span>}
          </Col>
        </Row>
        <Row>
          <Col xs={12} className='customer-col'>
            <h4 className={isCustomerNameTooLong ? 'names short-names' : ''} style={{marginBottom: '5px'}} title={isCustomerNameTooLong ? customer.consumer_name : ''}>
              {patientComplianceSettings && patientComplianceSettings.goes_by_enabled && customer.goes_by ? customer.goes_by : customer.consumer_name}
            </h4>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className='customer-col'>
            {customer && customer.order && customer.order.name ? customer.order.name : <span>&nbsp;</span>}
          </Col>
        </Row>
        <Row>
          <Col xs={12} className='customer-col'>
            {fulfillmentMethodMap(customer.fulfillment_method)}
          </Col>
        </Row>
        <Row>
          <Col xs={12} className='customer-col'>
          <span>{customer.order_started
            ? I18n.t('customer.orderPlaced')
            : I18n.t('customer.checkedIn')}:&nbsp;
          </span>
            <InternationalDateTimeStatic displaySeconds={false} useSystemDate={false}>{startTime}</InternationalDateTimeStatic>
          </Col>
        </Row>
        <PermissionWrapper permissions={[p.create_orders, p.manage_orders]}>
          <Row>
            <Col style={{paddingLeft: '0', paddingRight: '0'}}>
              <OrderAndQueueActions customer={customer}>
                <CustomerTileButton
                  orderType={orderType}
                  selectCustomer={selectCustomer}
                  selectProducts={selectProducts}
                  fillOrderx={fillOrder}
                  checkoutx={checkout}
                  isLockPreCheckinOnlineOrder={isLockPreCheckinOnlineOrder}
                />
              </OrderAndQueueActions>
            </Col>
          </Row>
        </PermissionWrapper>
      </Card.Body>
    </Card>
  );
};

CustomerTile.propTypes = {
  customer: PropTypes.object.isRequired,
  selectCustomer: PropTypes.func.isRequired,
  selectOrders: PropTypes.func,
  selectProducts: PropTypes.func.isRequired,
  fillOrder: PropTypes.func.isRequired,
  checkout: PropTypes.func.isRequired,
  cancelOrderAndReload: PropTypes.func.isRequired,
  orderType: PropTypes.string,
  usageType: PropTypes.string,
  timezone: PropTypes.string,
  patientComplianceSettings: PropTypes.object,
  isLockPreCheckinOnlineOrder: PropTypes.bool
};

export default CustomerTile;
