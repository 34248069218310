import {requiredFieldValidation, minValidation} from '../../common/form/redux-form/validations';

const validate = (values) => {
  const errors = {};
  errors.source_id = requiredFieldValidation(values.source_id, 'packages.form.sourceStorageLocationId');
  errors.storage_location_id =  requiredFieldValidation(values.storage_location_id, 'packages.form.destinationStorageLocationId');
  errors.quantity = requiredFieldValidation(values.quantity, 'packages.form.quantityInGrams');
  if(errors.quantity === undefined){
    errors.quantity = minValidation(values.quantity, 'packages.form.quantityInGrams', .001);
  }
  return errors;
};

export default validate;
