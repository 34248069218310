import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { I18n } from 'react-redux-i18n';

import { getItem, getUnpaginatedData, getDataByPost } from '../../../actions/apiActions';
import {unsetItem} from '../../../actions/itemActions';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import {getItemMaster} from '../../../selectors/itemMastersSelectors';
import ProductHistoryNotes from './ProductHistoryNotes';
import ModalWrapper from '../../common/ModalWrapper';
import TablePageWrapper from '../../common/grid/TablePageWrapper';
import InProgressOverlay from '../../common/InProgressOverlay';
import DateColumn from '../../common/grid/columns/DateColumn';
import {
  sortByAdditionalFieldIfEqual,
  convertDbDateTimeToFullDateTime
} from '../../../util/dateHelpers';
import {getProductHistoryTableData} from '../../../selectors/productHistorySelector';
import ProductUpdateDetails from './ProductUpdateDetails';
import ProductCreatedDetails from './ProductCreatedDetails';


export class ProductHistoryPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      ready: false,
      showNotesModal: false,
      displayedNotes: [],
      historyBySearch: null,
    };

    this.openNotesModal = this.openNotesModal.bind(this);
    this.expandableRow = this.expandableRow.bind(this);
    this.expandComponent = this.expandComponent.bind(this);
    this.ref = React.createRef();
    this.columns = [
      {
        name: 'common.action',
        dataId: 'message',
        width: '120px',
        dataAlign: 'left',
      },
      {
        name: 'common.employee',
        dataId: 'user_name',
        width: '90px',
      },
      {
        name: 'common.entryDate',
        dataId: 'event_date',
        width: '70px',
        formatter: (cell) => <DateColumn altDateFormat={false} altDate={convertDbDateTimeToFullDateTime(cell)}/>, //eslint-disable-line
        sortFunc: sortByAdditionalFieldIfEqual('event_date', 'id')
      }
    ];
  }

  componentWillMount() {
    const id = this.props.params.id || '';
    this.props.actions.unsetItem(itemNames.itemMaster);
    const promises = [
      this.props.actions.getItem(`/api/item_masters/${id}`, itemNames.itemMaster, null, {detailed: 1}, ((product) => {
        if (product.strain_id) {
          this.props.actions.getItem(`/api/strains/${product.strain_id}`, itemNames.strain);
        }
      })),
      this.props.actions.getUnpaginatedData('/api/categories', dataNames.categories),
      this.props.actions.getUnpaginatedData('/api/strains/by_organization', dataNames.organizationStrains),
      this.props.actions.getUnpaginatedData(`/api/item_masters/${id}/log`, dataNames.itemMasterHistory),
      this.props.actions.getUnpaginatedData('/api/users/current_facility', dataNames.currentFacilityUsers),
      this.props.actions.getUnpaginatedData('/api/partners', dataNames.partners, undefined, {purchase_from: 1}),
      this.props.actions.getUnpaginatedData('/api/pricing_groups', dataNames.pricingGroups, {failed: 'retail.pricingGroup.get.failed'}, {detailed: 1}),
      this.props.actions.getUnpaginatedData('/api/pricing_classes', dataNames.pricingClasses, {failed: 'retail.pricingClass.get.failed'}),
      this.props.actions.getUnpaginatedData('/api/pricing_weights', dataNames.pricingWeights),
      this.props.actions.getUnpaginatedData('/api/strains/by_facility', dataNames.facilityStrains)
    ];

    Promise.all(promises)
      .then(() => this.setState({ready: true}))
      .catch(() => this.setState({ready: true}))
    ;
  }

  openNotesModal(event, displayedNotes) {
    event.stopPropagation();
    this.setState({ showNotesModal: true, displayedNotes });
  }

  expandableRow(row) {
    return Boolean(row.model);
  }

  expandComponent(row) {
    switch (row.event_type) {
    case 'created_item_master':
      return <ProductCreatedDetails product={row.model}/>;
    case 'update_item_master':
      if (!row.delta) {
        return null;
      }
      return <ProductUpdateDetails product={row.model} delta={row.delta}/>;
    default:
      return null;
    }
  }


  render() {
    const { itemMaster, history} = this.props;
    return (
      <div className='harvest-history-page history-page'>
        {this.state.ready ? '' : <InProgressOverlay isActive={true} />}
        <ModalWrapper
          Component={ProductHistoryNotes}
          onHide={() => this.setState({ showNotesModal: false })}
          showModal={this.state.showNotesModal}
          title='history.notes'
          notes={this.state.displayedNotes}
        />
        <div className='harvest-history-page-harvest-info'>
          <p>{I18n.t('products.form.itemName')}: {itemMaster.name || ''}</p>
        </div>
        <TablePageWrapper
          ref={this.ref}
          settingKey='products-history'
          columns={this.columns}
          data={history}
          selectedRows={[]}
          handleSelect={() => {}}
          bstProps={{
            selectRow: {
              clickToSelect: false,
              hideSelectColumn: true,
              clickToExpand: true,
            },
            expandableRow: this.expandableRow,
            expandComponent: this.expandComponent,
            options : {
              defaultSortName: 'event_date',
              defaultSortOrder: 'desc',
            },
          }}
        />
      </div>
    );
  }
}

ProductHistoryPage.propTypes = {
  itemMaster: PropTypes.object.isRequired,
  history: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  timezone: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    itemMaster: getItemMaster(state),
    history: getProductHistoryTableData(state),
    timezone: state.timezone,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {getItem, getUnpaginatedData, getDataByPost, unsetItem};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductHistoryPage);
