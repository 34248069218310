import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FieldArray, reduxForm, formValueSelector} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Card} from 'react-bootstrap';

import Payments from './Payments';

const Form = ({order, registers, paymentTypes, form, invalid, handleSubmit, reset, getMayChangePayment, payments}) => {
  const displayChangePayment = order.order_status === 'completed' && (order.order_type === 'sales' || order.order_type === 'refund');
  return (
    <Card>
      <Card.Body>
        <h5><b>{I18n.t('cultivation.orderHistory.table.paymentsIn')}: {order.name}</b></h5>
        <form className='simple-form' onSubmit={handleSubmit}>
          <table className='table table-bordered table-striped product-detail-table'>
            <thead>
            <tr>
              <th>{I18n.t('cultivation.orderHistory.table.paymentMethod')}</th>
              <th>{I18n.t('cultivation.orderHistory.table.paymentAmount')}</th>
              <th>{I18n.t('cultivation.orderHistory.table.register')}</th>
            </tr>
            </thead>
            <FieldArray
              name='payments'
              component={Payments}
              registers={registers}
              paymentTypes={paymentTypes}
              invalid={invalid}
              reset={reset}
              mayChangePayment={getMayChangePayment(form)}
              payments={payments}
              displayChangePayment={displayChangePayment}
              allowNegativeNumber={order.order_type === 'refund' ? true : false}
            />
          </table>
        </form>
      </Card.Body>
    </Card>
  );

};

Form.propTypes = {
  order: PropTypes.object.isRequired,
  registers: PropTypes.array.isRequired,
  paymentTypes: PropTypes.array.isRequired,
  form: PropTypes.string.isRequired,
  invalid: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  getMayChangePayment: PropTypes.func.isRequired,
  payments: PropTypes.array,
};

const ReduxPaymentsForm = reduxForm({
  enableReinitialize: true,
  destroyOnUnmount: false,
})(Form);

const PaymentsForm = connect((state, props) => {
  const selector = formValueSelector(props.form);
  const payments = selector(state, 'payments');
  const getMayChangePayment = formName => {
    const selector = formValueSelector(formName);
    return selector(state, 'mayChangePayment');
  };
  return {
    getMayChangePayment,
    payments,
  };
})(ReduxPaymentsForm);

export default PaymentsForm;
