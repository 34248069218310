import initialState from '../constants/initialState';
import {SERVER_UPDATE_AVAILABLE, SERVER_DATA_UPDATE_AVAILABLE} from '../constants/actionTypes';

export default function dataUpdateReducer (state = initialState.dataUpdated, action) {
  const {type, payload} = action;
  switch (type) {
  case SERVER_UPDATE_AVAILABLE:
    return Object.assign({}, state, ...payload.map(core => ({[core.name]: Date.now()})));
  case SERVER_DATA_UPDATE_AVAILABLE:
    return Object.assign({}, state, {[payload.name]: Date.now()});
  default:
    return state;
  }
}
