import {createSelector, createStructuredSelector} from 'reselect';
import * as dataNames from '../constants/dataNames';
import * as m from '../constants/moduleKeys';
import {getActiveFacilityId} from './facilitiesSelectors';

export const getModules = state => state[dataNames.modules] || [];
export const getCurrentFacilityModules = state => state[dataNames.currentFacilityModules] || [];

export const getCurrentFacilityModuleIds = createSelector(
  [getCurrentFacilityModules, getActiveFacilityId],
  (modules, facility_id) => modules
    .filter(module => module.facility_id === facility_id && module.enabled === 1)
    .map(module => module.module_id)
);

export const getCurrentFacilityModuleDetails = createSelector(
  [getCurrentFacilityModuleIds, getModules],
  (ids, modules) => modules.filter(module => ids.indexOf(module.id) > -1)
);


const hasAnyModule = moduleKeys => createSelector(
  [getCurrentFacilityModuleDetails],
  (modules) => modules.some(module => moduleKeys.indexOf(module.module_key) > -1)
);


export const isManufacturingEnabled = hasAnyModule([m.EXTRACTION, m.INFUSION]);
export const isCultivationEnabled = hasAnyModule([m.CULTIVATION]);
export const isRetailEnabled = hasAnyModule([m.RETAIL]);


/**
 * Single selector which knows everything about module state
 * @typedef {Object} ModuleState
 * @property {bool} hasCultivation
 * @property {bool} hasManufacturing
 * @property {bool} hasRetail
 * @return {ModuleState}
 */
export const getModulesState = createStructuredSelector({
  hasCultivation: isCultivationEnabled,
  hasManufacturing: isManufacturingEnabled,
  hasRetail: isRetailEnabled,
});