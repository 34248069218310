import { createSelector } from 'reselect';
import groupBy from 'lodash.groupby';
import get from 'lodash.get';
import { getActiveColombiaModalitySettings } from '../settingsSelectors';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import { getFacilityStrains } from '../facilityStrainsSelectors';

export const getCupos = (state) => state[dataNames.cupos];
export const getCupo = (state) => state[itemNames.cupo];
const getModalityKey = (_, props) => props.modality_key;

export const getInitialValues = createSelector(
  [getActiveColombiaModalitySettings],
  (modalities) => {
    return {
      cupo_number: '',
      modalities: modalities
        .filter((item) => item.is_active)
        .map((modality) => ({
          ...modality,
          selected: false,
          strains: []
        }))
    };
  }
);

export const getModifyCupoInitialValues = createSelector(
  [getCupo, getActiveColombiaModalitySettings],
  (cupo, modalities) => {
    const cupoModalities = groupBy(cupo.modalities || [], 'modality');
    return {
      ...cupo,
      modalities: modalities.map((item) => {
        return {
          ...item,
          modality: get(cupoModalities[item.key], '0.modality'),
          cupo_id: get(cupoModalities[item.key], '0.cupo_id'),
          selected: Boolean(cupoModalities[item.key]),
          strains: (cupoModalities[item.key] || []).map((modality) => ({ id: modality.strain_id }))
        };
      })
    };
  }
);

export const getManageCupoSettingsInitialValues = createSelector(
  [getCupo, getModalityKey, getFacilityStrains],
  (cupo, modalityKey, strains) => ({
    ...cupo,
    modalities: get(cupo, 'modalities', [])
      .filter((modality) => modality.modality === modalityKey)
      .map((modality) => {
        const strain = strains.find((strain) => strain.id === modality.strain_id) || {};
        //auto set action type to 'warn' if it does not exist to prevent backend error. See CBYTES-2699
        if (modality.setting_value === null) {
          modality.setting_value = { action_type: 'warn' };
        } else if (modality.setting_value.action_type === null) {
          modality.setting_value.action_type = 'warn';
        }
        return {
          ...modality,
          strain_name: strain.strain_name
        };
      })
  })
);

export default getInitialValues;
