/* eslint-disable react/no-multi-comp */
/* eslint-disable import/no-named-as-default */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CancelToken } from 'axios';
import { bindActionCreators } from 'redux';
import {I18n} from 'react-redux-i18n';
import {Button, Image} from 'react-bootstrap';
import {push} from 'react-router-redux';
import {getItem, getUnpaginatedData, getSearchData, postItem, putData, postData, deleteItem, getData, getDataByPost, getPaginatedData} from '../../actions/apiActions';
import * as itemActions from '../../actions/itemActions';
import {unsetData, unionData} from '../../actions/dataActions';
import {getConsumers} from '../../actions/consumerActions';
import * as itemNames from '../../constants/itemNames';
import * as dataNames from '../../constants/dataNames';
import {getServiceFirstDetailedCustomers} from '../../selectors/customersSelectors';
import {userNeedsRegistersLocations} from '../../selectors/usersSelectors';
import {getFacilityByPrimaryId, getCurrentFacilityCountry} from '../../selectors/facilitiesSelectors';
import {getAllowAnonymousOrders, getOrderRequiresCheckIn} from '../../selectors/salesSettingsSelectors';
import {patientSettingsGoesByEnabled} from '../../selectors/complianceSettingsSelectors';
import TablePageWrapper from '../common/grid/TablePageWrapper';
import ScanInputModal from '../scan-input/ScanInputModal';
import ModalWrapper from '../common/ModalWrapper';
import CustomerDetail from './common/CustomerDetail';
import Groups from './Groups';
import PermissionButton from '../common/PermissionButton';
import PageTitle from '../common/PageTitle';
import *  as p from '../../constants/permissions';
import AnonymousOrderBundle from '../orders/order/anonymous/AnonymousOrderBundle';
import CustomerActionButtons from './checkin/CustomerActionButtons';
import {getTotalResults} from '../../selectors/paginationSelectors';
import {getIntegrationState} from '../../selectors/integration/integrationSelectors';
import {getDefaultVisitReason} from '../../selectors/queueSelectors';
import InternationalDateStatic from '../common/form/InternationalDateStatic';
import { setSolrErrorMessage } from '../../actions/solrActions';
import { isFeatureEnabled } from '../../selectors/featureToggles';
import { consumerTypes } from './checkin/CustomerPanel'

export class ServiceFirstCustomerPage extends React.Component {

  constructor(props, context) {
    super(props, context);
    const filters = [];
    const defaultSort = 'consumer_code:asc';
    this.openGroupsModal = this.openGroupsModal.bind(this);
    this.activateCustomer = this.activateCustomer.bind(this);
    this.createCustomer = this.createCustomer.bind(this);
    this.modifyCustomer = this.modifyCustomer.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleExpand = this.handleExpand.bind(this);
    this.setTab = this.setTab.bind(this);
    this.filterStatus = this.filterStatus.bind(this);
    this.customerDetails = this.customerDetails.bind(this);
    this.showScanModal = this.showScanModal.bind(this);
    this.hideScanModal = this.hideScanModal.bind(this);
    this.onScanComplete = this.onScanComplete.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
    this.refreshSolrIndex = this.refreshSolrIndex.bind(this);
    this.setBodyState = this.setBodyState.bind(this);
    this.setDataState = this.setDataState.bind(this);
    this.clearCancelToken = this.clearCancelToken.bind(this);
    this.reloadOrdersAndQueues = this.reloadOrdersAndQueues.bind(this);
    this.ref = React.createRef();
    this.state = {
      filters,
      defaultSort,
      selectedCustomers: [],
      showScanModal: false,
      scanSearchString: '',
      showGroupsModal: false,
      modalCustomer: {},
      customersDetails: {},
      orderActionsData: false,
      reindexing: false,
      dataState: 'loading',
      cancelToken: false,
    };

    this.searchInFlight = false;
    this.consumerIds = []; // These come from solr search and are used to constrain the service requests
  }

  componentWillMount() {
    this.props.actions.unsetItem(itemNames.metrcPatientLimits);
    this.props.actions.unsetItem(itemNames.customer);
    this.props.actions.unsetItem(itemNames.orderSettings);
    this.props.actions.getItem(
      '/api/csrs',
      itemNames.budTendersObject,
      {failed: I18n.t('customers.getBudTenders.failed')},
      {active: 1}
    );
    this.props.actions.getUnpaginatedData(
      `/api/consumer_groups`,
      dataNames.customerGroups,
      {failed: I18n.t('customers.getGroups.failed')},
      {activeAndInactive: true}
    );

    this.props.actions.getItem('/api/order_settings', itemNames.orderSettings);

    this.props.actions.getItem(
      '/api/customers/compliance_settings',
      itemNames.patientComplianceSettings,
      {failed: 'retail.patientSettings.loadSettingsForPlants'}
    );

    this.props.actions.getData(
      '/api/queue/visit_reasons',
      dataNames.visitReasons,
      {});

    if (this.props.isOhMetrc) {
      this.props.actions.getUnpaginatedData('/api/ohmetrc/item_categories', dataNames.ohMetrcCategories);
      this.props.actions.getUnpaginatedData('/api/ohmetrc/item_categories/mapping', dataNames.ohMetrcCategoryMappings);
    }
  }

  componentDidMount() {
    this.filterStatus(this.props.status);
    this.props.actions.unsetItem(itemNames.image);
    this.props.actions.unsetItem(itemNames.order);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.status !== this.props.status) {
      this.handleSelect(false, []);
      this.setBodyState({expanding: []});
      this.props.actions.unsetData(dataNames.customers);
      this.filterStatus(nextProps.status);
    }
  }

  shouldComponentUpdate(nextProps, nextState){
    if(this.state.showScanModal && nextState.showScanModal === this.state.showScanModal ){
      return false;
    }else{
      return true;
    }
  }

  componentWillUnmount() {
    this.props.actions.unsetData(dataNames.customers);
  }

  setBodyState(state) {
    //Little hack here. BSTable ignores properties update and uses the inner state so we have to update in manually
    if (this.ref.current) {
      this.ref.current.wrappedInstance.getBaseRef().setState(state);
    }
  }

  filterStatus(status) {
    const {filters} = this.state;
    if(filters.find(filter => filter === status) !== undefined){
      this.setState({filters: []});
      if (this.ref.current) {
        this.ref.current.wrappedInstance.filter('');
      }
    } else if(status === 'inactive') {
      this.setState({filters: [status]});

    } else{
      this.setState({filters: [status]});
    }
    if (this.ref.current) {
      this.ref.current.wrappedInstance.filter(`active:${status === 'active' ? 1 : 0}`);
    }
  }

  openGroupsModal(event, row) {
    event.stopPropagation();
    const {customerGroups} = this.props;
    const modalCustomer = {...row,
      groups: row.group_ids && row.group_ids.length
        ? row.group_ids.split(',').map(group => Object.assign({}, {consumer_group_id: group}, customerGroups.reduce((previous, current) => group == current.id ? current : previous, null)))
        : []
    };
    this.setState({showGroupsModal: true, modalCustomer});
  }


  createCustomer(event){
    this.props.actions.push('/patients/create');
  }

  modifyCustomer (event) {
    const customerId =  this.state.selectedCustomers && this.state.selectedCustomers.length
      ? this.state.selectedCustomers[0]
      : null;
    if (customerId) {
      this.props.actions.push(`/patients/modify/${customerId}`);
    }
  }

  activateCustomer(event) {
    event.preventDefault();
    event.stopPropagation();
    const customerId = event.target.attributes['data-customer-id'].value;
    this.props.actions.putData(
      `/api/customers/${customerId}`,
      {active: 1},
      null,
      {failed: 'customers.activate.failed', success: 'customers.activate.success'},
      undefined,
      () => this.ref.current.wrappedInstance.ref.current.callExternalSearch()
    );
  }

  exportCSV() {
    if(this.ref.current){
      this.ref.current.wrappedInstance.getBaseRef().handleExportCSV();
    }
  }

  setVisibleColumns (event) {
    const {columns} = this.state;
    const {id} = event.target;
    columns[id].hidden = !columns[id].hidden;
    this.setState({columns:[...columns]});
  }

  setTab(activeTab) {
    const path = activeTab === 'inactive' ? '/patients/inactive' : '/patients';
    this.props.actions.push(path);
  }

  refreshSolrIndex() {
    this.props.actions.postItem(
      '/api/search/update',
      {core: 'customers'},
      null,
      {
        success: 'plants.actions.updateSearchSuccess',
        fail: 'plants.actions.updateSearchFail'
      }
    );
  }

  updateSearch() {
    this.refreshSolrIndex();
    this.setState({reindexing: true});
  }

  handleSelect (isSelected, rows) {
    if(rows.length){
      this.props.actions.setItem(rows[0].id, itemNames.selectedConsumerId);
    }
    this.setState({selectedCustomers: isSelected ? rows.map((row) => row.id) : []});
  }

  getCancelToken(){
    const cancelToken = CancelToken.source(); // Get new token
    if(this.state.cancelToken){ // Trigger the old one if still present
      this.state.cancelToken.cancel();
    }
    this.setState({cancelToken});
    return cancelToken;
  }

  clearCancelToken(){
    this.setState({cancelToken: false});
  }

  handleSearch (sort, query = 'matchall', size, start, filter) {
    if(this.searchInFlight){
      return;
    }
    const isSearch = Boolean(query && query !== this.lastQuery);
    const isEmptySearch = Boolean( query && query === this.lastQuery && !this.consumerIds.length);
    if(!isSearch){
      if (!query) {
        this.consumerIds = [];
      }
      if(isEmptySearch){
        return;
      }
      this.fetchCustomers(sort, size, start);
      return;
    }

    this.props.actions.unsetData(dataNames.customers);
    this.lastQuery = query;
    if (sort === '') {
      sort = this.state.defaultSort;
    }

    const config = {
      errorHandler: {
        message: 'cultivation.finishedProduct.table.solrError',
        action: this.props.actions.setSolrErrorMessage,
        clearOnSuccess: true,
      }
    };

    const arbitraryLargeSearch = 20000;
    const params = {
      sort: undefined,
      query,
      filter,
      start: 0,
      size: arbitraryLargeSearch
    };
    this.searchInFlight = true;
    this.setDataState('searching')
      .then(() => {
        this.props.actions.getSearchData('/api/search/customers', null, null, params, null, null, config)
          .then((consumers) => {
            const ids = consumers.map((consumer) => consumer.consumer_id);
            this.searchInFlight = false;
            if(!ids.length){
              this.setDataState('loaded');
              this.consumerIds = [];
              return;
            }
            this.fetchCustomers(sort, size, 0, ids);
          })
          .catch(() => {
            this.searchInFlight = false;
            this.fetchCustomers(sort, size, start);
          });
      });
  }

  fetchCustomers (sort, size, start, ids = []) {
    sort = sort ? sort.replace(/\s/, ':') : this.state.defaultSort;
    const consumerIds = ids.length || this.consumerIds.length
      ? ids.length
        ? ids
        : this.consumerIds
      : [];

    if(ids.length){
      this.consumerIds = ids; // Store for paging through search result sets
    }
    const isActive = this.props.status === 'active' ? 1 : 0;
    this.setDataState('loading')
      .then(() => {
        const cancelToken = this.getCancelToken();
        const config = {setDataState: this.setDataState, isSearchCustomer: false, cancelToken: cancelToken.token, clearCancelToken: this.clearCancelToken};
        this.props.actions.getConsumers(sort, size, start, isActive, consumerIds, config);
      });
  }

  reloadOrdersAndQueues(){
    return new Promise((resolve) => {
      const consumerIds = this.props.customers.map((customer) => customer.id);
      const ordersParams = {
        order_status: 'open',
        order_type: 'sales',
        select_columns: [
          'id',
          'consumer_id',
          'facility_id',
          'order_source',
          'fulfillment_method',
          'updated_at',
          'order_name',
          'is_submitted',
          'order_status',
          'order_type',
          'locked'
        ],
        in_consumer_ids: consumerIds,
      };
      const queueParams = {in_consumer_ids: consumerIds};
      this.props.actions.getData(`/api/orders`, dataNames.orders, null, ordersParams);
      this.props.actions.getData('api/queue', dataNames.customersQueue, null, queueParams);
      resolve();
    });
  }

  handleExpand(row) {
    return true;
  }

  customerDetails(row) {
    return (
      <CustomerDetail
        customerId={row.id}
        timezone={this.props.timezone}
        loadCustomerData={this.state.selectedCustomers.indexOf(row.id) !== -1}
      />
    );
  }

  showScanModal(scanType) {
    return () => {
      this.setState({showScanModal: true, scanType});
    };
  }

  hideScanModal() {
    this.setState({showScanModal: false});
  }

  onScanComplete(parsedData) {
    if (parsedData) {
      this.setState({showScanModal: false, searchString: parsedData.dl});
    }
  }

  setDataState(state){
    return new Promise((resolve) => {
      this.setState({dataState: state}, () => {
        resolve();
      });
    });
  }

  render () {
    const defaultHiddenColumns = ['temp_license_expiration', 'patient_state_id', 'partner_state_expiration_date', 'group_ids', 'primary_facility', 'default_phone_number'];
    const {customers, dataTotalSize, status, isCure, allowAnonymousOrders, orderRequiresCheckIn, isPaLeaf, patientGoesByEnabled} = this.props;

    const commonColumns = [
      {
        name: 'Customer Type',
        dataId:'type',
        dataSort: false,
        hidden: true,
        csvFormatter: (cell) => '',
        formatter: (cell) => <span
          style={{fontSize: '2rem', color: consumerTypes[cell]['color']}
        }>
          {consumerTypes[cell]['text']}
        </span>
      },
      {
        name: 'customers.table.image',
        dataId:'image_url',
        dataSort: false,
        hidden: false,
        fixedWidth: '80px',
        csvFormatter: (cell) => '',
        formatter: (cell) => <Image className='mini-customer-image' src={cell}/>
      },//eslint-disable-line react/display-name
      {
        name: 'customers.table.patientId',
        dataId: 'consumer_code',
        hidden: false,
        fixedWidth: '230px'
      },
    ];

    if (patientGoesByEnabled) {
      commonColumns.push({
        name: 'customers.table.goesBy',
        dataId:'goes_by',
        dataSort: false,
        hidden: false,
        dataAlign: 'left',
        headerAlign: 'left',
        fixedWidth: '140px'
      });
    }

    commonColumns.push(
      {
        name: 'customers.table.lastName',
        dataId:'last_name',
        hidden: false,
        dataAlign: 'left',
        headerAlign: 'left',
        fixedWidth: '140px'
      },
      {name: 'customers.table.firstName',
        dataId:'first_name',
        hidden: false,
        dataAlign: 'left',
        headerAlign: 'left',
        width: '120px',
      },
      {name: 'customers.table.birthDate',
        dataId: 'birth_date',
        hidden: false,
        fixedWidth: '200px',
        csvFormatter: (cell) => cell ? <InternationalDateStatic adjustForClientTimezone={false}>{cell}</InternationalDateStatic> : '',
        formatter: (cell, row) => cell ? <InternationalDateStatic adjustForClientTimezone={false}>{cell}</InternationalDateStatic> : '-'
      },
      {name: 'customers.table.tempExpiration',
        dataId: 'temp_license_expiration',
        hidden: false,
        width: '120px',
        dataSort: false,
        csvFormatter: (cell, row) => cell ?  <InternationalDateStatic adjustForClientTimezone={false}>{cell}</InternationalDateStatic> : '',
        formatter: (cell, row) => cell ?  <InternationalDateStatic adjustForClientTimezone={false}>{cell}</InternationalDateStatic> : '-'},
      {name: 'customers.table.statePatientID',
        dataId: 'patient_state_id',
        hidden: false,
        dataAlign: 'left',
        width: '120px',
        dataSort: false,
        csvFormatter: (cell) => cell ? cell : '',
        formatter: (cell, row) => cell ? cell : '-'},
      {name: 'customers.table.expiration',
        dataId: 'partner_state_expiration_date',
        hidden: false,
        dataAlign: 'left',
        width: '120px',
        dataSort: false,
        csvFormatter: (cell) => cell ?  <InternationalDateStatic adjustForClientTimezone={false}>{cell}</InternationalDateStatic> : '',
        formatter: (cell, row) => cell ? <InternationalDateStatic adjustForClientTimezone={false}>{cell}</InternationalDateStatic> : '-'
      },
      {
        name: 'customers.table.groups',
        dataId: 'group_ids',
        hidden: false,
        dataSort: false,
        csvFormatter: (cell, row) => cell && cell.length ? I18n.t('customers.table.view') : '',
        formatter: (cell, row) => { //eslint-disable-line react/display-name
          const variant = row.has_active_groups ? 'primary' : 'default';
          return cell && cell.length ? (
            <Button variant={variant} bsSize='xsmall' onClick={event => this.openGroupsModal(event, row)}>
              {I18n.t('customers.table.view')}
            </Button>
          ) : '-';
        },
        width: '120px',
        permissions: [p.view_customers, p.manage_customers]
      },
      {
        name: 'customers.table.primaryFacility',
        hidden: false,
        width: '120px',
        dataSort: false,
        dataId: 'primary_facility',
        csvFormatter: (cell) => cell ? cell : '',
        formatter: (cell, row) => {
          return cell ? <div title={cell}>{cell}</div> : '-';
        }
      },
      {
        name: 'customers.table.medicalId',
        dataId: 'med_license_number',
        hidden: false,
        dataSort: false,
        fixedWidth: '220px',
        csvFormatter: (cell) => cell ? cell : '',
        formatter: (cell, row) => cell ? cell : '-'
      });


    if (!isPaLeaf) {
      commonColumns.push(
        {
          name: 'customers.table.medIdExpiration',
          dataId: 'med_license_expiration',
          dataSort: false,
          hidden: {isPaLeaf},
          fixedWidth: '260px',
          csvFormatter: (cell, row) => cell ?  <InternationalDateStatic adjustForClientTimezone={false}>{cell}</InternationalDateStatic> : '',
          formatter: (cell, row) => cell ? <InternationalDateStatic adjustForClientTimezone={false}>{cell}</InternationalDateStatic> : '-'
        }
      );
    }

    commonColumns.push(
      {
        name: 'customers.table.driversLicense',
        dataId: 'drivers_license_number',
        dataSort: false,
        hidden: false,
        fixedWidth: '260px',
        csvFormatter: (cell) => cell ? cell : '',
        formatter: (cell, row) => cell ? cell : '-'
      },
      {
        name: 'customers.table.phoneNumber',
        dataId: 'default_phone_number',
        dataSort: false,
        hidden: false,
        dataAlign: 'center',
        width: '120px',
        csvFormatter:  (cell) => cell ? cell : '',
        formatter:  (cell) => cell ? cell  : '-'
      }
    );

    const activeColumns = (!isPaLeaf && !orderRequiresCheckIn) ?
      commonColumns.concat([
        {
          name: 'customers.table.actions',
          hidden: false,
          dataSort: false,
          fixedWidth: '250px',
          dataId: 'id',
          permissions: [p.manage_queue],
          csvFormatter: (cell) => { return I18n.t('customers.table.actions'); },
          formatter: (cell, row) => //eslint-disable-line react/display-name
            <CustomerActionButtons
              reloadOrdersAndQueues={this.reloadOrdersAndQueues}
              serviceFirst={true}
              budTenders={this.props.budTenders}
              consumer={row}
              bsSize='xsmall'
              vertical
            />
        }]) :
      commonColumns;

    const inactiveColumns = commonColumns.concat([
      {
        name: 'customers.table.activate',
        hidden: false,
        fixedWidth: '100px',
        dataId: 'id',
        permissions: [p.manage_customers],
        csvFormatter: (cell) => { return I18n.t('customers.table.activate'); },
        formatter: (cell, row) => ( //eslint-disable-line react/display-name
          <PermissionButton permissions={[p.manage_customers]} props={{
            'variant': 'primary',
            'bsSize': 'xsmall',
            'onClick': this.activateCustomer,
            'data-customer-id': cell,
          }}>
            {I18n.t('customers.table.activate')}
          </PermissionButton>
        ),
      }
    ]);


    const {showGroupsModal, modalCustomer, selectedCustomers, reindexing, dataState} = this.state;
    const modalGroups = modalCustomer.groups && modalCustomer.groups.length ? modalCustomer.groups : [];
    const modalTitle = I18n.t('customers.table.groupsOf') + ' ' + modalCustomer.last_name + ' ' + modalCustomer.first_name;
    let tableColumns = null;

    if (isCure) {
      tableColumns = status === 'inactive' ? inactiveColumns : commonColumns;
    } else {
      tableColumns = status === 'inactive' ? inactiveColumns : activeColumns;
    }

    const tabs = [
      {
        id: 'activeTab', eventKey: 'active', title: 'nav.Active', actions: [
          {
            id: 'createCustomer',
            func: this.createCustomer,
            text: 'customers.actions.createPatient',
            glyph: 'plus-sign',
            requireSelect: false,
            permissions: [p.create_customers]
          },
          {
            id: 'modifyCustomer',
            permissions: [p.manage_customers, p.edit_customers],
            func: this.modifyCustomer,
            text: 'customers.actions.modifyPatient',
            glyph: 'edit',
            requireSelect: true
          },
          {
            id: 'updateSearch',
            func: this.updateSearch,
            text: 'plants.actions.updateSearch',
            glyph: 'arrow-up',
            variant: 'warning',
            requireSelect: false,
            disabled: reindexing
          }
        ]
      },
      {
        id: 'inactiveTab', eventKey: 'inactive', title: 'nav.Inactive', actions: [
          {
            id: 'updateSearch',
            func: this.updateSearch,
            text: 'plants.actions.updateSearch',
            glyph: 'arrow-up',
            variant: 'warning',
            requireSelect: false,
            disabled: reindexing
          }
        ]
      },
    ];

    return (
      <div className='customer-page'>
        <PageTitle primaryText={I18n.t('customers.title')}/>
        {allowAnonymousOrders ? <AnonymousOrderBundle /> : null}
        <ModalWrapper
          Component={Groups}
          onHide={() => this.setState({showGroupsModal: false})}
          showModal={showGroupsModal}
          title={modalTitle}
          groups={modalGroups}
        />
        <ScanInputModal
          showModal={this.state.showScanModal}
          scanType={this.state.scanType}
          onScanComplete={this.onScanComplete}
          hideModal={this.hideScanModal} />
        <TablePageWrapper
          ref={this.ref}
          settingKey='patients'
          columns = {tableColumns}
          defaultHiddenColumns={defaultHiddenColumns}
          data = {customers}
          dataState = {dataState}
          handleSelect={this.handleSelect}
          handleFilter = {undefined}
          selectedRows={selectedCustomers}
          activeTab = {status}
          tabs = {tabs}
          dataUpdated={() => this.setState({reindexing: false})}
          switchTab = {this.setTab}
          dataTotalSize = {dataTotalSize}
          externalSearch = {this.handleSearch}
          hideScanSearch={true}
          scanSearchCol= 'drivers_license'
          searchString= {this.state.searchString}
          toggleScanIdSearch = {this.showScanModal}
          external = {true}
          className = 'customer-page'
          defaultSort = {this.state.defaultSort}
          hideExport={true}
          isSolrListing={true}
          useAutoSuggest={this.props.useAutoSuggest}
          autoSuggestPlaceholder='customers.table.suggestPlaceholder'
          bstProps={{
            selectRow: {
              mode: 'radio',
              clickToSelect: true,
              clickToExpand: true,
              selected: this.state.selectedCustomers,
            },
            options: {
              hideSizePerPage: true,
              onRowClick: (row) => this.handleSelect(true, [row]),
            },
            expandableRow: (row) => this.handleExpand(row),
            expandComponent: this.customerDetails,
          }}
        />
      </div>
    );
  }
}

ServiceFirstCustomerPage.propTypes = {
  allowAnonymousOrders: PropTypes.bool.isRequired,
  orderRequiresCheckIn: PropTypes.bool.isRequired,
  customers: PropTypes.array.isRequired,
  budTenders: PropTypes.array.isRequired,
  dataTotalSize: PropTypes.number,
  status: PropTypes.oneOf(['active', 'inactive']),
  actions: PropTypes.object.isRequired,
  customerStats: PropTypes.array,
  customerRecentHistories: PropTypes.array,
  registersLocationsRequired: PropTypes.bool.isRequired,
  salesComplianceSettings: PropTypes.object,
  timezone: PropTypes.string.isRequired,
  customerGroups: PropTypes.array.isRequired,
  isPaLeaf: PropTypes.bool,
  isCure: PropTypes.bool,
  countryCode: PropTypes.string,
  isOhMetrc: PropTypes.bool.isRequired,
  useAutoSuggest: PropTypes.bool,
  patientGoesByEnabled: PropTypes.object,
};

function mapStateToProps(state, ownProps){
  const {budTendersObject, customerStats, customerRecentHistories,
     timezone, customerGroups} = state;
  const budTenders = Object.keys(budTendersObject).map(id => ({
    id,
    name: budTendersObject[id]
  }));
  const status = ownProps.params && ownProps.params.status === 'inactive' ? 'inactive' : 'active';
  const registersLocationsRequired = userNeedsRegistersLocations(state, {permissions: [p.view_registers]});
  const customers = getServiceFirstDetailedCustomers(state).map(customer => {
    const primaryFacility = customer.primary_facility_id && getFacilityByPrimaryId(state, customer)
      ? getFacilityByPrimaryId(state, customer).name
      : '';
    return Object.assign({}, customer, {
      primary_facility: primaryFacility
    });
  });
  const {isCure, isPaLeaf, isOhMetrc} = getIntegrationState(state);

  return {
    allowAnonymousOrders: getAllowAnonymousOrders(state),
    orderRequiresCheckIn: getOrderRequiresCheckIn(state),
    customerGroups,
    customers,
    registersLocationsRequired,
    customerStats,
    customerRecentHistories,
    dataTotalSize: getTotalResults(state, {name: dataNames.customers}),
    status,
    budTenders,
    timezone,
    isPaLeaf,
    defaultVisitReason: getDefaultVisitReason(state),
    isCure,
    isOhMetrc,
    countryCode: getCurrentFacilityCountry(state),
    useAutoSuggest: isFeatureEnabled(state)('feature_solr_inventory_suggest'),
    patientGoesByEnabled: patientSettingsGoesByEnabled(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, itemActions,
    {
      getSearchData,
      getItem,
      getUnpaginatedData,
      postItem,
      unionData,
      getData,
      getDataByPost,
      putData,
      push,
      unsetData,
      postData,
      deleteItem,
      setSolrErrorMessage,
      getPaginatedData,
      getConsumers,
    }
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (ServiceFirstCustomerPage);
