import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getCurrencySymbol} from '../../../selectors/InternationalOperationsSelectors';
import InternationalDecimalStatic from './InternationalDecimalStatic';


const InternationalCurrencyStatic = (props) => {

  const { currencySymbol, ...rest } = props;

  return (
      <InternationalDecimalStatic
        prefix={currencySymbol}
        suffix=''
        fractionPartSize={2}
        {...rest}
      />
  );
};

InternationalCurrencyStatic.propTypes = {
  currencySymbol: PropTypes.string.isRequired
};


function mapStateToProps(state, ownProps){
  return {
    currencySymbol: getCurrencySymbol(state),
  };
}

export default connect(mapStateToProps)(InternationalCurrencyStatic);
