import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../common/grid/Table';

const StrainTable = ({data,trackPlantsAsGroups}) => {
  const columns = [
    {
      name: 'plants.packagePlants.id',
      dataId: 'id',
      hidden: true,
    },
    {
      name: trackPlantsAsGroups ? 'plants.packagePlants.plantGroupId' : 'plants.packagePlants.plantId',
      dataId: 'plantId',
      hidden: false,
      width: '100px',
      dataAlign: 'left',
      headerAlign: 'left'
    },
  ];
  return (
    <div className='package-plants'>
      <Table
        noSelectionMode={true}
        className='strain-table'
        sizePerPage={10}
        data={data}
        columns={columns}
        handleSelect={() => {}}
        selectedRows={[]}
        bstProps={{
          ignoreSinglePage: true
        }}
      />
    </div>
  );
};

StrainTable.propTypes = {
  data: PropTypes.array.isRequired,
  trackPlantsAsGroups: PropTypes.bool
};

export default StrainTable;
