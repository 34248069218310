import { createSelector } from 'reselect';
import get from 'lodash.get';
import * as itemNames from '../constants/itemNames';
import { isFeatureEnabled } from './featureToggles';

const getCultivationSettings = (state) => state[itemNames.cultivationSettings];
const getComplianceSettings = (state) => state[itemNames.complianceSettings] || {};
const getColombiaModalitiesSettings = (state) => state[itemNames.colombiaModalities];
export const getSettingsHash = (state) => state[itemNames.settingsHash];

export const getInventorySettings = createSelector(
  [getComplianceSettings],
  (settings) => {
    return get(settings, 'inv_compliance_settings', {});
  }
);

export const getSourceDisplaySetting = createSelector(
  [getInventorySettings],
  (settings) => {
    const key = 'display_source_strain_source_batch_harvest_date_for_lots_on_transfer_manifest_and_inventory_receipt';
    // Try at object, value containing object, or value array first element containing object.
    return Boolean(get(settings, key, get(settings, `value.${key}`, get(settings, `value.0.${key}`, false))));
  }
);

export const getColombiaModalitySettings = createSelector(
  [getColombiaModalitiesSettings],
  (settings) => {
    return get(settings, 'cult_colombia_modality.value', []);
  }
);

export const getActiveColombiaModalitySettings = createSelector(
  [getColombiaModalitySettings],
  (settings) => {
    return settings.filter((setting) => setting.is_active);
  }
);

export const getSettingAddTestResultsToPlantGroups = createSelector([getCultivationSettings], (settings) => {
  const key = 'cult_add_test_results_to_plant_groups.value';
  return get(settings, key, false);
});


const isUtahCategoriesFeatureEnabled = (isFeatureEnabled) => {
  const toggle = 'feature_ut_category_management';
  return isFeatureEnabled(toggle);
};

const isUtahChangeRequestFeatureEnabled = (isFeatureEnabled) => {
  const toggle = 'feature_ut_qa_change_request';
  return isFeatureEnabled(toggle);
};

export const getCategorySettings = createSelector([getSettingsHash, isFeatureEnabled], (settingsHash, isFeatureEnabled) => {
  if(!isUtahCategoriesFeatureEnabled(isFeatureEnabled)){
    return {};
  }
  const key = 'inv_category_display_name_configuration';
  const arr = get(settingsHash, `${key}.value`, get(settingsHash, `0.${key}.value`, []));
  return arr.reduce((acc, item) => {
    acc[item.category_code] = item;
    return acc;
  }, {});
});

export const getSubCategorySettings = createSelector([getSettingsHash, isFeatureEnabled], (settingsHash, isFeatureEnabled) => {
  if(!isUtahCategoriesFeatureEnabled(isFeatureEnabled)){
    return {};
  }
  const key = 'inv_subcategory_display_name_configuration';
  const arr = get(settingsHash, `${key}.value`, get(settingsHash, `0.${key}.value`, []));
  return arr.reduce((acc, item) => {
    acc[`${item.category_code}_${item.subcategory_code}`] = item;
    return acc;
  }, {});
});

export const getSubCategorySettingsArray = createSelector(
  [getSubCategorySettings],
  subcategorySettings => Object.values(subcategorySettings)
);

export const getPackagingWorkflowValidOutputs = createSelector([getSettingsHash, isFeatureEnabled], (settingsHash, isFeatureEnabled) => {
  if(!isUtahChangeRequestFeatureEnabled(isFeatureEnabled)){
    return [];
  }
  const key = 'inv_packaging_workflow_valid_outputs';
  return get(settingsHash, `${key}.value`, get(settingsHash, `0.${key}.value`, []));
});

export const getProcessingWorkflowValidOutputs = createSelector([getSettingsHash, isFeatureEnabled], (settingsHash, isFeatureEnabled) => {
  if(!isUtahChangeRequestFeatureEnabled(isFeatureEnabled)){
    return [];
  }
  const key = 'mf_processing_workflow_valid_outputs';
  return get(settingsHash, `${key}.value`, get(settingsHash, `0.${key}.value`, []));
});

export const getUsZipPlus4Setting = createSelector([getSettingsHash], (settingsHash) => {
  const key = 'partner_consumer_address_allow_zip_plus_four';
  return get(settingsHash, `${key}.value`, get(settingsHash, `0.${key}.value`, []));
});
