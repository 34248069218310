import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';
import {connect} from 'react-redux';

import LabelSettingsForm from './Form';

const LabelSettingsReduxForm = reduxForm({
  form: 'labelSettings',
})(LabelSettingsForm);

function mapStateToProps(state, ownProps) {
  return { state, formFields: ownProps.formFields };
}

const LabelSettingsFormWrapper = connect(mapStateToProps)(LabelSettingsReduxForm);

LabelSettingsFormWrapper.propTypes = {
  formFields: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default LabelSettingsFormWrapper;
