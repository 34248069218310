import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {Button, Form, Col, FormControl, FormGroup, Row} from 'react-bootstrap';
import SimplePagination from '../common/SimplePagination';
import {getStrainsFirstLetters, getStrainsPageData, getFilteredStrains} from '../../selectors/strainsSelectors';

class StrainsTable extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.isChecked = this.isChecked.bind(this);
    this.handleCheckAllStrains = this.handleCheckAllStrains.bind(this);
  }

  isChecked(strain) {
    const {selectedStrainIds, selectedOrgStrainIds, level} = this.props;
    return (strain.id && selectedStrainIds.indexOf(strain.id) > -1) ||
      (level === 'organization' && (selectedOrgStrainIds.indexOf(strain.organization_strain_id) > -1 || strain.global_strain_id === null));
  }

  isDisabled(strain) {
    const {level} = this.props;
    if(level === 'organization'){
      return strain.global_strain_id === null;
    }else{
      return false;
    }
  }

  // build a list of the strain ids from the filtered list and return to parent to update state.selectedStrainIds
  handleCheckAllStrains(toggle = false) {
    const {filteredStrains, selectStrains} = this.props;
    const selectedStrainIds = filteredStrains.map((strain) => {
      return strain.id;
    });

    selectStrains(selectedStrainIds, toggle);
  }

  render() {
    const {alphabet, page, searchString, activePage, searchLetter, search,
      searchAlphabetically, switchPage, toggleSelect, handleSubmit, allStrainsSelected, level} = this.props;

    const showCheckAllCheckbox = level === 'facility' && !searchString && !searchLetter;

    return (
      <div className='strains-table-container'>
        <Row className='filters'>
          <Col xs={6} sm={4} md={1}>
            {showCheckAllCheckbox &&
            <div className='selectAllCheckbox'>
              <Form.Label>{I18n.t('strains.listing.checkAllStrains')}</Form.Label>
              <Form.Check type='checkbox'
                checked={allStrainsSelected}
                onChange={() => this.handleCheckAllStrains(true)}
              />
            </div>
            }
          </Col>
          <Col xs={6} sm={4} md={3}>
            <FormGroup controlId='search-strain'>
              <Form.Label>{I18n.t('strains.searchByName')}</Form.Label>
              <FormControl
                type='text'
                value={searchString}
                onChange={search}
                placeholder={I18n.t('strains.searchPlaceholder')}/>
            </FormGroup>
          </Col>
          <Col xs={12} sm={8} md={8}>
            <FormGroup>
              <Form.Label>{I18n.t('strains.searchAlphabetically')}</Form.Label>
              <div className='alphabet flex layout-row layout-start-start layout-wrap'>
                {alphabet.map(item =>
                  <Button
                    variant='link'
                    size='sm'
                    active={searchLetter === item.letter}
                    disabled={item.count === 0}
                    key={`letter-${item.letter}`}
                    className='letter'
                    onClick={event => searchAlphabetically(event, item.letter)}
                  >
                    {item.letter}
                  </Button>
                )}
                <Button className='letter'
                  variant='link'
                  size='sm'
                  disabled={!searchLetter && !searchString}
                  onClick={event => searchAlphabetically(event)}
                >
                  {I18n.t('strains.showAll')}
                </Button>
              </div>
            </FormGroup>
          </Col>
        </Row>
        <div className='hint'>{I18n.t('strains.selectHint')}</div>

        <div className='strains-table layout-row layout-align-start-start layout-wrap'>
          {page.items.map((strain, index) =>
            <Col sm={4} md={3} lg={2} key={index}>
              <Form.Check type='checkbox'
                style={{whiteSpace: 'nowrap'}}
                key={`strain-${strain.id}-${strain.organization_strain_id}`}
                checked={this.isChecked(strain)}
                label={strain.strain_name}
                onChange={() => toggleSelect(strain)}
                disabled={this.isDisabled(strain)}
              />
            </Col>
          )}
        </div>
        {page.pagesCount > 0 &&
        <div className='flex layout-row layout-align-space-between-center page-info' style={{marginTop: '10px'}}>
          <span>
            {I18n.t('grid.pagination', {start: page.start , to: page.end , total: page.total})}
          </span>
          {page.pagesCount > 1 &&
          <SimplePagination
            size='sm'
            items={page.pagesCount}
            activePage={activePage}
            onSelect={switchPage}
            maxButtons={5}
            first={true}
            last={true}
            next={true}
            prev={true} />}

        </div>}
        <div className='flex layout-row layout-align-end-end'>
          <Button onClick={handleSubmit} variant='primary'>
            {I18n.t('strains.addToInventory')}
          </Button>
        </div>
      </div>
    );
  }
}

StrainsTable.propTypes = {
  alphabet: PropTypes.arrayOf(
    PropTypes.shape({
      letter: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired
    })
  ),
  page: PropTypes.shape({
    items: PropTypes.array.isRequired,
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
    pagesCount: PropTypes.number.isRequired
  }),
  selectedStrainIds: PropTypes.array.isRequired,
  selectedOrgStrainIds: PropTypes.array.isRequired,
  searchString: PropTypes.string.isRequired,
  searchLetter: PropTypes.string.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  activePage: PropTypes.number.isRequired,
  search: PropTypes.func.isRequired,
  searchAlphabetically: PropTypes.func.isRequired,
  switchPage: PropTypes.func.isRequired,
  toggleSelect: PropTypes.func.isRequired,
  selectStrains: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  level: PropTypes.string,
  allStrainsSelected: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {
  return {
    alphabet: getStrainsFirstLetters(state, ownProps),
    page: getStrainsPageData(state, ownProps),
    filteredStrains: getFilteredStrains(state, ownProps),
  };
}

export default connect(mapStateToProps)(StrainsTable);
