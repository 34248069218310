import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row, Card} from 'react-bootstrap';
import {connect} from 'react-redux';
import MmuSettings from './MmuSettings';
import {getSalesLimitsByConsumerType, isSalesLimitsAppliedAcrossLoop} from '../../../selectors/salesSettingsSelectors';
import {isLeafPaConfigPackClosedLoopFacility} from '../../../selectors/facilitiesSelectors';
import {isFeatureEnabled} from '../../../selectors/featureToggles';

class MmuLimitsPanel extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Row>
        <Col md={2} />
        <Col md={8}>
          <Card>
            <Card.Body>
              <MmuSettings
                isSalesLimitsAppliedAcrossLoop={this.props.isSalesLimitsAppliedAcrossLoop}
                salesLimitsByConsumerType={this.props.salesLimitsByConsumerType}
                patientType={this.props.patientType}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col md={2} />
      </Row>
    );
  }
}

MmuLimitsPanel.propTypes = {
  patientType: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    isLeafPaMmuToggled: isFeatureEnabled(state)('feature_leaf_pa_mmu_limits'),
    isLeafPaConfigPackClosedLoopFacility: isLeafPaConfigPackClosedLoopFacility(state),
    isSalesLimitsAppliedAcrossLoop: isSalesLimitsAppliedAcrossLoop(state),
    salesLimitsByConsumerType: getSalesLimitsByConsumerType(state),
  };
}

export default connect(mapStateToProps, null)(MmuLimitsPanel);
