import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';
import {requiredFieldValidation, minValidation, maxValidation, productionRunValidation, lotNumberValidation} from '../../../common/form/redux-form/validations';
import {isInvPackageTrackingNotInCompliance} from '../../../../selectors/complianceSettingsSelectors';

const validate = (values, props) => {

  const isLabResultsFailed = (input) => {
    return get(input, 'lab_result_status') === 0;
  };

  const { integrationState, isDraft } = props;
  const errors = {
    actual_completion_time: requiredFieldValidation(values.actual_completion_time, 'ei.processing.form.completionTime'),
    equipments: requiredFieldValidation(values.equipments.length > 0, 'ei.processing.form.equipment'),
    employees: requiredFieldValidation(values.employees.length > 0, 'ei.processing.form.employee'),
  };

  errors.inputs = values.inputs.map((input) => {
    const maxQty = parseFloat(input.maxQty);
    const qty = parseFloat(input.qty);

    return {
      qty: requiredFieldValidation(qty, 'ei.processing.form.weight')
      || minValidation(qty, 'ei.processing.form.weight', 0, true)
      || (maxQty === 0 && !isDraft && I18n.t('ei.processing.form.notEnoughInventory'))
      || (!props.isAllowNegativeInventory && maxQty > 0 && maxValidation(qty, 'ei.processing.form.weight', maxQty)),
      // In PA, you cannot infuse failed product
      packageCode: props.isPaRemediationLabelsEnabled && isLabResultsFailed(input)
        ? I18n.t('ei.processing.form.failedTestResult')
        : null
    };
  });

  errors.outputs = values.outputs.map((output) => {
    const qty = parseInt(output.qty);
    return {
      qty: requiredFieldValidation(qty, 'ei.processing.form.newQty') || minValidation(qty, 'ei.processing.form.newQty', 0, true),
      storage_location_id: requiredFieldValidation(output.storage_location_id, 'ei.processing.form.storageLocation'),
      phase_id: requiredFieldValidation(output.phase_id, 'ei.processing.form.newPhase'),
      name: productionRunValidation(output.name, 'ei.processing.form.productionRun'),
      integration_type: (integrationState.isBiotrack || integrationState.isWaLeaf) && requiredFieldValidation(output.integration_type, 'inventory.form.inventoryType'),
      lot_name: output.lot_name && lotNumberValidation(output.lot_name) || undefined,
      package_expires_at: output.tag_requested ? requiredFieldValidation(output.package_expires_at) : undefined
    };
  });

  if (integrationState.isBiotrack && values.waste < 0) {
    errors.waste = I18n.t('ei.processing.form.unacceptableWasteValue');
  }

  if ( props.complianceSettings.inv_packages_require_tracking_id) {
    if (values.inputs.some((input) => {
      return isInvPackageTrackingNotInCompliance(input);
    })) {
      errors.inputs = {
        _error: props.complianceSettings.inv_packages_require_tracking_id_message,
      };
    }
  }

  return errors;
};

export default validate;
