import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {push} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import first from 'lodash.first';
import PropTypes from 'prop-types';

import {deleteItem, getUnpaginatedData} from '../../../actions/apiActions';
import * as dataNames from '../../../constants/dataNames';
import * as selectedDataActions from '../../../actions/selectedDataActions';
import PageTitle from '../../common/PageTitle';
import TablePageWrapper from '../../common/grid/TablePageWrapper';
import SingleActionColumn from '../../common/grid/columns/SingleActionColumn';
import {
  getSelectedSharedFacilityGroups,
  getSelectedSharedFacilityGroupsIds,
  getFormattedSharedFacilityGroups,
} from '../../../selectors/facilityGroupsSharingSelectors';

import { getCurrentFacilityId } from '../../../selectors/facilitiesSelectors';


import ModalWrapper from '../../common/ModalWrapper';
import InProgressOverlay from '../../common/InProgressOverlay';

export class SharedFacilityGroupsPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showConfirmModal: false,
      deleting: false,
      groupForRemovingId: null
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.onConfirmRemoving = this.onConfirmRemoving.bind(this);
    this.modifyFacilityGroup = this.modifyFacilityGroup.bind(this);
    this.onShowConfirmModal = this.onShowConfirmModal.bind(this);
    this.onHideConfirmModal = this.onHideConfirmModal.bind(this);
  }

  componentWillMount() {
    this.loadFacilityGroups();
  }

  onShowConfirmModal(event, row) {
    this.setState({showConfirmModal: true, groupForRemovingId: row.id});
  }

  onHideConfirmModal() {
    this.setState({showConfirmModal: false, groupForRemovingId: null});
  }

  onConfirmRemoving() {
    const {groupForRemovingId} = this.state;

    this.setState({deleting: true});
    this.onHideConfirmModal();
    this.props.actions.deleteItem(`/api/facility_groups_sharings/${groupForRemovingId}`,
      'noOp',
      {failed: 'facilityGroupsSharing.delete.failed', success: 'facilityGroupsSharing.delete.success'},
      undefined
    )    
      .then(() => this.loadFacilityGroups().then(this.setState({deleting: false})))
      .catch(() => {     
        this.setState({deleting: false});
      });

  }

  loadFacilityGroups(){
    return this.props.actions.getUnpaginatedData('/api/facility_groups_sharings', dataNames.sharedFacilityGroups, {failed: 'failedToGetFacilityGroups'}, {
      detailed: 1,
      only_master: 1
    });
  }

  handleSelect(add, rows) {
    if (add) {
      this.props.actions.setSelectedData(rows, dataNames.sharedFacilityGroups);
    } else {
      this.props.actions.removeSelectedData(rows, dataNames.sharedFacilityGroups);
    }
  }

  modifyFacilityGroup() {
    const selectedId = first(this.props.selectedGroupIds);
    if(selectedId){
      this.props.actions.push(`/shared-facility-groups/${selectedId}`);
    }
  }

  render(){

    const {showConfirmModal, deleting} = this.state;
    const columns = [
      {
        name: 'facilityGroupsSharing.table.groupName',
        dataId: 'name',
        hidden: false,
        dataAlign: 'left',
        headerAlign: 'left'
      },
      {
        name: 'facilityGroupsSharing.table.facilities',
        dataId: 'facilities',
        hidden: false,
        dataAlign: 'left',
        headerAlign: 'left',
      },
      {
        name: 'facilityGroupsSharing.table.masterFacility',
        dataId: 'masterFacility',
        hidden: false,
        dataAlign: 'left',
        headerAlign: 'left',
      },
      {
        name: '',
        hidden: false,
        formatter: SingleActionColumn({
          label: 'facilityGroupsSharing.actions.remove',
          action: this.onShowConfirmModal,
          isDisabled: (row) => {
            if(row.facility_id === this.props.currentFacilityId){
              return false;
            }            
            return true;
          }
        }),
        dataSort: false,
        columnClassName: 'action-column'
      }
    ];

    const actions = [
      {id: 'createFacilityGroup', path: '/shared-facility-groups/create', text: 'facilityGroupsSharing.actions.createFacilityGroup' , glyph: 'plus-sign', requireSelect: false},
      {id: 'modifyFacilityGroup', text: 'facilityGroupsSharing.actions.editFacilityGroup' , glyph: 'pencil', requireSelect: true, func: this.modifyFacilityGroup},
    ];
    return (
      <div>
        <InProgressOverlay isActive={deleting} message='common.form.deleting' translate={true}/>
        <PageTitle primaryText={I18n.t('facilityGroupsSharing.form.title')}/>
        <TablePageWrapper
          settingKey='shared-facility-groups'
          columns={columns}
          actions={actions}
          data={this.props.facilityGroups}
          handleSelect={this.handleSelect}
          selectedRows = {this.props.selectedGroupIds}
          hideExport={true}
          hideTableControls={true}
          hideScanSearch={true}
          bstProps={{
            selectRow: {
              mode: 'radio'
            }
          }}
        />
        <ModalWrapper
          Component={false}
          title='facilityGroupsSharing.remove.removeFacilityGroup'
          headerClass='bg-info-dark'
          onHide={this.onHideConfirmModal}
          showModal={showConfirmModal}
          okayButton={{show: true, onClick: this.onConfirmRemoving, text: 'common.actions.confirm'}}
          cancelButton={{show: true, onClick: this.onHideConfirmModal, text: 'common.cancel'}}
          dialogClassName='modal-sm'
          version={2}
        >
          <div>{I18n.t('facilityGroupsSharing.remove.confirmationMessage')}</div>
        </ModalWrapper>
      </div>
    );
  }

}

SharedFacilityGroupsPage.propTypes = {
  selectedGroups: PropTypes.array,
  facilityGroups: PropTypes.array.isRequired,
  selectedGroupIds: PropTypes.array.isRequired,
  currentFacilityId: PropTypes.number
};

function mapStateToProps(state) {
  return {
    selectedGroups: getSelectedSharedFacilityGroups(state),
    selectedGroupIds: getSelectedSharedFacilityGroupsIds(state),
    facilityGroups: getFormattedSharedFacilityGroups(state),
    currentFacilityId: getCurrentFacilityId(state)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, {getUnpaginatedData, push, deleteItem}, selectedDataActions);
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SharedFacilityGroupsPage);
