import {getSearchData, postData, getItem} from '../apiActions';
import {addMessage} from '../systemActions';
import * as dataNames from '../../constants/dataNames';
import * as messageTypes from '../../constants/messageTypes';

export const getAllInventory = () => {
  const params = {
    sort: 'created_at desc',
    query: '',
    size: 100000,
    start: 0,
  };

  return (dispatch) => new Promise((resolve) => {
    dispatch(getSearchData(
      '/api/search/inventory',
      null,
      null,
      params,
      (groups) => {
        resolve(groups);
      })
    );
  });
};

/**
 * Loading inventory items passing list of item master IDS
 * @param payload
 * @returns {Promise}
 */
export const fetchInventoryByItemMasterIDs = (payload) => (dispatch) => {
  return dispatch(postData('/api/items/by_item_master_ids', payload, dataNames.inventoryItems, null, null, null, { debounce: true }));
};

/**
 * Loading inventory item by id
 * @param id
 * @returns {Promise}
 */
export const getInventoryById = (id) => (dispatch) => {
  return dispatch(getItem(`/api/items/${id}`));
};

/**
 * When provided an array of package ids, reach out to the backend to determine which can be retested.
 * @param package_ids
 * @param onFailure
 * @param onSuccess
 */
export const canRetestPackages = (packages, limit, onSuccess) => (dispatch) => {
  const package_ids = packages.map(p => p.package_id);

  const request = postData('/api/lab_results/check_allow_retest', {package_ids}, undefined, undefined, undefined, (result) => {
    const restricted_ids = result.restricted_ids;

    if (restricted_ids && restricted_ids.length) {
      restricted_ids.forEach(id => {
        const p = packages.find(p => p.package_id === id);

        const translation = {
          package_code: p.package_code,
          limit
        };

        const message = addMessage(messageTypes.error, [
          'complianceSettings.inventory.retestLimitReached',
          translation
        ]);

        dispatch(message);
      });
    } else {
      onSuccess();
    }
  });

  return dispatch(request);
};
