import React from 'react';
import PropTypes from 'prop-types';

import BulkLineItem from './BulkLineItem';
import IngredientBulkLineItem from './IngredientBulkLineItem';

const BulkLineItemWrapper = (props) => {
  const {lineItem} = props;
  return (
    (lineItem.itemMaster
      && lineItem.itemMaster.inventory_attributes
      && lineItem.itemMaster.inventory_attributes.is_ingredient)
      ?
      <IngredientBulkLineItem {...props}/>
      :
      <BulkLineItem {...props}/>
  );
};

BulkLineItemWrapper.propTypes = {
  lineItem: PropTypes.object.isRequired
};

export default BulkLineItemWrapper;