import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';
import {getUnpaginatedData, deleteData, putData} from '../../../actions/apiActions';
import {handleComplexSelectRow} from '../../../actions/helpers/selectedDataHelper';
import * as selectedDataActions from '../../../actions/selectedDataActions';
import * as dataNames  from '../../../constants/dataNames';
import * as p from '../../../constants/permissions';
import {unsetData} from '../../../actions/dataActions';
import PageTitle from '../../common/PageTitle';
import TablePageWrapper from '../../common/grid/TablePageWrapper';
import InProgressOverlay from '../../common/InProgressOverlay';
import SingleActionColumn from '../../common/grid/columns/SingleActionColumn';
import {addTooltip} from '../../../util/tooltipHelper';

const tabStatuses = {active: 'active', inactive: 'inactive'};

class ProductListsListingPage extends React.Component {

  constructor(props) {
    super(props);

    this.createProductList = this.createProductList.bind(this);
    this.editProductList = this.editProductList.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.switchTab = this.switchTab.bind(this);
    this.activateProductList = this.activateProductList.bind(this);
    this.deactivateProductList = this.deactivateProductList.bind(this);
    this.loadProductLists = this.loadProductLists.bind(this);

    this.setupTabs();
    this.setupColumns();

    this.state = {
      activeTab: props.params.status === 'inactive' ? tabStatuses.inactive : tabStatuses.active,
      confirmationModal: {
        show: false,
        multiplier: null,
      },
      loading: false,
    };

  }

  componentDidMount() {
    this.loadProductLists();
  }

  componentWillUnmount() {
  }

  setupTabs() {
    const actions = [
      {id: 'createProductList', func: this.createProductList , text: 'retail.productList.actions.create.label' , requireSelect: false, glyph: 'plus-sign'},
      {id: 'EditProductList', func: this.editProductList , text: 'retail.productList.actions.edit.label' , requireSelect: true, glyph: 'edit'}
    ];
    this.tabs = [
      {
        id: 'activeTab',
        eventKey: 'active',
        title: 'retail.productList.tabs.active',
        path: '/retail/product-lists/active',
        actions,
      },
      {
        id: 'inactiveTab',
        eventKey: 'inactive',
        title: 'retail.productList.tabs.inactive',
        path: '/retail/product-lists/inactive',
        actions,
      }
    ];
  }

  setupColumns() {
    const commonColumns = [
      {
        name: I18n.t('retail.productList.columns.name'),
        dataId: 'name',
        width: '300px',
        headerAlign: 'left',
        dataAlign: 'left'
      },
      {
        name: I18n.t('retail.productList.columns.description'),
        dataId: 'description',
        width: '300px',
        headerAlign: 'left',
        dataAlign: 'left',
        formatter: (cell) => addTooltip(cell)
      },
    ];
    const actionColumn = {
      hidden: false,
      dataSort: false,
      permissions: [p.manage_coupons],
      columnClassName: 'actions-column',
      width: '170px',
    };
    this.activeColumns = [
      ...commonColumns,
      {
        ...actionColumn,
        formatter: SingleActionColumn({
          permissions: [p.manage_coupons],
          label: 'retail.productList.actions.deactivate.label',
          action: (e, row) => this.deactivateProductList(row)
        }),
      }
    ];

    this.inactiveColumns = [
      ...commonColumns,
      {
        ...actionColumn,
        formatter: SingleActionColumn({
          permissions: [p.manage_coupons],
          label: 'retail.productList.actions.activate.label',
          action: (e, row) => this.activateProductList(row)
        }),
      }
    ];
  }

  createProductList() {
    this.props.actions.push('/retail/product-list');
  }

  editProductList() {
    const {selectedProductListData} = this.props;
    this.props.actions.push(`/retail/product-list/${selectedProductListData[0]}`);
  }

  handleSelect(isSelected, rows) {
    this.props.actions.handleComplexSelectRow(rows, dataNames.productListData, 'set');
  }

  loadProductLists() {
    this.setState({loading: true});
    this.props.actions.handleComplexSelectRow([], dataNames.productListData, 'clear');
    this.props.actions.unsetData(dataNames.productLists);
    const messages = {failed: 'retail.productLists.actions.loadListing.failure'};
    const active = this.state.activeTab === tabStatuses.inactive ? 0 : 1;
    this.props.actions.getUnpaginatedData('/api/product_lists', dataNames.productLists, messages, {active})
      .finally(() => {
        this.setState({loading: false});
      });
  }

  switchTab(activeTab) {
    const tab = this.tabs.find(tab => tab.eventKey === activeTab) || this.tabs[0];
    this.setState({activeTab}, this.loadProductLists);
    this.props.actions.push(tab.path);
  }

  activateProductList(productList) {
    this.setState({loading: true});
    const messages = {
      success: I18n.t('retail.productList.actions.activate.success'),
      fail: I18n.t('retail.productList.actions.activate.failed')
    };
    this.props.actions.putData('/api/product_lists/' + productList.id, {active: true}, dataNames.productLists, messages)
      .finally(() => {this.loadProductLists();});
  }

  deactivateProductList(productList) {
    this.setState({loading: true});
    const messages = {
      success: I18n.t('retail.productList.actions.deactivate.success'),
      fail: I18n.t('retail.productList.actions.deactivate.failed')
    };
    this.props.actions.putData('/api/product_lists/' + productList.id, {active: false}, dataNames.productLists, messages)
      .then(() => {this.loadProductLists();})
      .catch(() => {
        // TODO: Show error (i.e. product list is in use)
        this.loadProductLists();
      });
  }

  render() {
    const {productLists, selectedProductListData} = this.props;
    const {activeTab, loading} = this.state;
    return (
      <div className='product-list-listing'>
        <PageTitle primaryText={I18n.t('retail.productList.listingPage.title')}/>
        <InProgressOverlay isActive={loading} message={I18n.t('retail.productList.listingPage.loading')}/>
        <TablePageWrapper
          ref={this.ref}
          settingKey='retail-product-lists'
          activeTab={activeTab}
          switchTab={this.switchTab}
          tabs={this.tabs}
          columns = {activeTab === tabStatuses.active ? this.activeColumns : this.inactiveColumns}
          data = {productLists}
          selectedRows = {selectedProductListData}
          handleSelect = {this.handleSelect}
          hideExport = {true}
          hideScanSearch = {true}
          hideTableControls={true}
          external={false}
          bstProps={{
            ignoreSinglePage: true,
            selectRow: {
              mode: 'radio',
            }
          }}
          noSelectionMode={false}
          showSelectionMode={true}
          className='retail-product-list-table'
        />
      </div>
    );
  }
}

ProductListsListingPage.propTypes = {
  actions: PropTypes.shape({
    handleComplexSelectRow: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    deleteData: PropTypes.func.isRequired,
    putData: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired
  }),
  params: PropTypes.object,
  productLists: PropTypes.array.isRequired,
  selectedProductListData: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  const productLists = state[dataNames.productLists];
  const selectedProductListData = state['selectedProductListData'] ? state['selectedProductListData'].map(productList => productList.id) : null;
  return {
    productLists,
    selectedProductListData
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {getUnpaginatedData, deleteData, handleComplexSelectRow, selectedDataActions, push, unsetData, putData};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductListsListingPage);
