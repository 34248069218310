import React from 'react';
import PropTypes from 'prop-types';
//import {I18n} from 'react-redux-i18n';

const AddLabelRow = (props) => {

  const dataIsValid = () => {
    if(props.modalData.field === undefined) return false;
    if(props.modalData.field.trim() === '') return false;
    if(props.modalData.value === undefined) return false;
    if(props.modalData.value.trim() === '') return false;
    return true;
  };

  return (
    <div className='row' style={{marginTop: '12px'}}>
      <div className='col-md-12'>
      <div className='col-md-6'>
        <div className='form-group'>
          <label>Field Label</label>
          <input type='text' onChange={(event) => props.onChangeModalData('field', event.target.value)} value={props.modalData.field} className='form-control' />
        </div>
      </div>
      <div className='col-md-6'>
        <div className='form-group'>
          <label>Data Key</label>
          <input type='text' onChange={(event) => props.onChangeModalData('value', event.target.value)} value={props.modalData.value} className='form-control' />
        </div>
      </div>
      <div className='col-md-12'>
        <hr />
        <button
          onClick={(event) => {
            props.onHide();
          }}
          className='btn btn-danger'
        >
          Cancel
        </button>
        <button
          onClick={(event) => {
            props.onSaveModalData();
          }}
          className='btn btn-primary float-right'
          disabled={!dataIsValid()}
        >
          Save
        </button>
      </div>
      </div>
    </div>
  );
};

AddLabelRow.propTypes = {
  definitions: PropTypes.array.isRequired,
  currentDefinitionIndex: PropTypes.number.isRequired,
  onSelectNamedDefinition: PropTypes.func,
  modalData: PropTypes.object.isRequired,
  onChangeModalData: PropTypes.func.isRequired,
  onSaveModalData: PropTypes.func.isRequired,
};

export default AddLabelRow;
