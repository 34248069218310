import * as types from '../constants/actionTypes';
import initialState from '../constants/initialState';

export default function facilitySwitchReducer(state = initialState.facilitySwitch, action) {
  const {payload, type} = action;

  switch (type) {
  case types.FACILITY_SWITCH_IN_PROGRESS:
    return {...state, ...payload};
  default:
    return state;
  }
}
