/* eslint-disable indent */
import { I18n } from 'react-redux-i18n';
import isEmpty from 'lodash.isempty';
import { validNumericFieldValidation, dateTimeValidation } from '../../../../../common/form/redux-form/validations';

const validate = (values, ownProps) => {

  const timeRegex = new RegExp(/(0[1-9]:[0-5][0-9]((\ ){0,1})((AM)|(PM)|(am)|(pm)))|([1-9]:[0-5][0-9]((\ ){0,1})((AM)|(PM)|(am)|(pm)))|(1[0-2]:[0-5][0-9]((\ ){0,1})((AM)|(PM)|(am)|(pm)))/);

  const errors = {};

  if(!validNumericFieldValidation(parseFloat(values.reward_points_accrued_per_dollar_rate))) {
    errors.reward_points_accrued_per_dollar_rate = `${I18n.t('retail.rewardSetup.fields.reward_points_accrued_per_dollar_rate')} must be numeric`;
  }

  if(values.reward_one_point_is_worth__override_enabled){
    if(!validNumericFieldValidation(parseFloat(values.reward_one_point_is_worth__override_value))) {
      errors.reward_one_point_is_worth__override_value = `${I18n.t('common.value')} must be numeric`;
    }
  }

  if(!(isEmpty(values.reward_remove_points_after_days_between_visit) || validNumericFieldValidation(parseFloat(values.reward_remove_points_after_days_between_visits)))) {
    errors.reward_remove_points_after_days_between_visits = `${I18n.t('common.value')} must be numeric`;
  }

  // Consider making this a global validator with a bit more flexibility
  const valueIsOfType = (value, types) => {
    if(!Array.isArray(types)) return false;
    if(value === null) return false;
    return types.reduce((acc, type) => {
      if(acc) return acc; // If one of is true, we are good
      switch(true){
        case type === 'array':
          if(!Array.isArray(value)) return false;
          if(value.length === 0) return false;
          break;
        case type === 'number':
          if(isNaN(value)) return false;
          break;
        default:
          if (typeof value !== type) return false;
          break;
      }
      if(type === 'string' && value.trim() === '') return false;
      return true;
    }, undefined);
  };

  const timeFrames = 'reward_timeframe_based_accrual_rate';

  const typeMap = {
    name: {type: 'string', error: 'Multiplier Period must be named.'},
    multiplier: {type: 'number', error: 'Must be numeric.'},
    on_days: {type: 'array', error: 'Must have at least one day selected.'},
    start_time: {type: 'string,object', error: 'Must be a time value in the format of HH:MM [AM|PM].'},
    end_time: {type: 'string,object', error: 'Must be a time value in the format of HH:MM [AM|PM].'}
  };

  const atLeastOneFields = [
    'reward_allow_rewards_for_order_type',
    'reward_accrue_points_by_category',
    //'reward_eligible_reward_groups',
  ];

  atLeastOneFields.forEach((field) => {
    if(values[field] === undefined || values[field].length === 0){
      if(ownProps.customerGroups.length === 0 && field === 'reward_eligible_reward_groups') return true;
      errors[field] = 'You must select at least one constraint for rewards to be applied.';
    }
  });

  if(values[timeFrames] !== undefined) {
    if (values[timeFrames].length > 0) {
      errors[timeFrames] = [];
      values[timeFrames].forEach((window, index) => {
        const windowError = {};
        Object.keys(typeMap).forEach((key) => {
          windowError[key] = undefined;
          if (!valueIsOfType(window[key], typeMap[key].type.split(','))) {
            windowError[key] = typeMap[key].error;
          } else {
            if(key.indexOf('time') !== -1){
              if(typeof window[key] === 'object'){
                if(dateTimeValidation(window[key].toDate())) windowError[key] = typeMap[key].error;
              } else {
                if(!timeRegex.test(window[key])){
                  windowError[key] = typeMap[key].error;
                }
              }
            }
          }
        });
        const errorValue = Object.keys(windowError).reduce((acc, field) => {
          if(acc) return acc;
          if(windowError[field] !== undefined) return false;
        }, false);
        if(errorValue){
          errors[timeFrames][index] = windowError;
        }
      });
      if(errors[timeFrames].length === 0) delete(errors[timeFrames]);
    }
  }

  return errors;
};

export default validate;
