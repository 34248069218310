import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Button, Col, Row, Table} from 'react-bootstrap';
import {FaPlus, FaTimes} from 'react-icons/fa';
import {Field} from 'redux-form';
import orderBy from 'lodash.orderby';
import * as itemActions from '../../../../actions/itemActions';
import * as itemNames from '../../../../constants/itemNames';
import InternationalDecimalInput from '../../../common/form/InternationalDecimalInput';
import InternationalDecimalReadOnlyInput from '../../../common/form/InternationalDecimalReadOnlyInput';
import InternationalDisplayQty from '../../../common/InternationalDisplayQty';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import { getInitialValues, getTaxDefinitions } from '../../../../selectors/forms/taxProfileFormSelectors';

class PricingWeightsFieldArray extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {addMenuOpen: false};
    this.toggleAddMenu = this.toggleAddMenu.bind(this);
    this.addWeightButtonDisabled = this.addWeightButtonDisabled.bind(this);
    this.addWeight = this.addWeight.bind(this);
    this.addWeightRow = this.addWeightRow.bind(this);
  }

  toggleAddMenu() {
    this.setState({addMenuOpen: !this.state.addMenuOpen});
  }

  addWeightButtonDisabled() {
    const {getFormValue, fields: {getAll}} = this.props;
    const value = Number(getFormValue('addPricingWeight'));
    const selectedUom = getFormValue('uom');
    const allWeights = getAll ? getAll() : [];
    return !value || (allWeights ? allWeights.find(w => w.weight === value && w.uom_display === selectedUom) : []);
  }

  addWeight() {
    const {onPricingWeightAdded, getFormValue, pricingWeights} = this.props;
    const selectedUom = getFormValue('uom');
    const pricingWeight = selectedUom === 'GR' ? {
      weight: getFormValue('addPricingWeight'),
      name: getFormValue('addPricingWeight') + 'g',
      uom: selectedUom
    } : {
      weight: getFormValue('addPricingWeight'),
      name: getFormValue('addPricingWeight') + I18n.t(`uoms.${selectedUom}.abbrev`),
      uom: selectedUom
    };

    const existingWeight = pricingWeights.find(
      w => (w.weight === Number(pricingWeight.weight) && w.uom_display === selectedUom)
    );

    if (existingWeight) {
      this.addWeightRow(existingWeight);
    } else {
      onPricingWeightAdded(pricingWeight, this.addWeightRow);
    }
  }

  addWeightRow(weight) {
    const {fields: {insert, getAll}, change} = this.props;
    const value = {
      pricing_weight_id: weight.id,
      name: /g$/.test(weight.name) ? weight.name : weight.name + 'g',
      weight_base: weight.weight_base,
      weight: weight.weight,
      uom_display: weight.uom_display
    };

    let values = orderBy(getAll(), 'weight_base');

    const valueExistsAlready = values.find(
      w => (w.pricing_weight_id === Number(value.pricing_weight_id) && w.uom_display === value.uom_display)
    );

    if (!valueExistsAlready) {
      values = orderBy(getAll().concat(value), 'weight_base');
      insert(values.indexOf(value), value);
    }

    change('addPricingWeight', '');
    this.setState({addMenuOpen: false});
  }

  calculatePostTax(defaultSubTotalValue) {
    const taxes = this.state.initialValues.taxes;
    const groups = this.state.initialValues.groups;
    const taxes_on_subtotal = this.state.initialValues.taxes_on_subtotal;

    let sum = 0;
    const allGroups = groups.concat([taxes, taxes_on_subtotal]);

    allGroups.forEach((group, index) => {
      let inboundValue = defaultSubTotalValue;

      if (index === 0) {
        sum = defaultSubTotalValue;
      } else {
        inboundValue = (index + 1 === allGroups.length) ? defaultSubTotalValue : sum;
      }

      if (group.items && Array.isArray(group.items)) {
        group.items.forEach((item) => {
          const definition = this.state.taxDefinitions.find((def) => def.id === item.tax_id);
          if (definition) {
            const taxAmount = inboundValue * definition.rate;
            sum += taxAmount;
          }
        });
      }
    });

    return sum.toFixed(2);
  }

  render() {
    const {change, fields: {map, get, remove}, meta: {error}, fieldsMetadata, taxProfiles, getFormValue} = this.props;
    const {addMenuOpen} = this.state;
    const touchedIndex = fieldsMetadata.findIndex(meta => meta && meta.default_price && meta.default_price.touched);
    const selectedUom = getFormValue('uom');

    return (
      <div className='base-price'>
        <div className='alert alert-warning'>The post-tax price reflects the tax profile only, additional pricing changes cannot be displayed.</div>
        <Field name='tax_profile_id' component={ReactSelectInput} props={{
          label: I18n.t('retail.pricingGroup.form.taxProfile'),
          options: taxProfiles,
          valueKey: 'id',
          textKey: 'tax_profile_name',
          isRequired: false,
          onChange: value => {
            change('tax_profile_id', value);
            let taxProfile = taxProfiles.find(taxProfile => taxProfile.id === value);
            if (!taxProfile) taxProfile = {};
            this.props.actions.setItem(taxProfile, itemNames.taxProfile).then(() => {
              const initialValues = getInitialValues(this.props.state);
              const taxDefinitions = getTaxDefinitions(this.props.state);
              this.setState({
                initialValues: initialValues,
                taxDefinitions: taxDefinitions
              });
            });
          }
        }}/>
        {selectedUom &&
          <React.Fragment>
            <h4 className='text-align-center'>{I18n.t('retail.pricingGroup.form.basePrice')}</h4>
            {error && touchedIndex > -1 ? <div className='has-error'><span className='help-block'>{I18n.t(error)}</span></div> : null}
            <Table>
              <thead>
              <tr>
                <th/>
                <th className='text-align-left'>{I18n.t('retail.pricingGroup.form.preTax')}</th>
                <th className='text-align-left'>{I18n.t('retail.pricingGroup.form.postTax')}</th>
                <th/>
              </tr>
              </thead>
              <tbody>
              {map((fieldName, fieldIndex) => {
                const row = get(fieldIndex);
                const price = Number.parseFloat(row.default_price);
                const uomAbbrev = I18n.t(`uoms.${row.uom_display}.abbrev`);

                let postTaxPrice = '';
                if (price && this.state.initialValues && this.state.initialValues.tax_profile_name) {
                  postTaxPrice = this.calculatePostTax(price);
                }

                return (
                  <tr key={fieldName}>
                    <td className='text-align-right'>
                      <InternationalDisplayQty qty={row.weight} uom={uomAbbrev} displayUom={true}/>
                      </td>
                    <td className={error && fieldIndex === touchedIndex ? 'has-error input' : 'input'}>
                      <InternationalDecimalInput name={`${fieldName}.default_price`} fractionPartSize={2} props={{}}/>
                    </td>
                    <td className='input'>
                      <InternationalDecimalReadOnlyInput name={`${fieldName}.default_price_post_tax`} fractionPartSize={2} props={{
                        disabled: true,
                        value: postTaxPrice
                      }}/>
                    </td>
                    <td>
                      <Button variant='link' onClick={() => remove(fieldIndex)}>
                        <FaTimes/>
                      </Button>
                    </td>
                  </tr>
                );
              })}
              </tbody>
            </Table>
            <Row>
              <Col xs={9}>
                <div className='text-align-right'>
                  <label>{I18n.t('retail.pricingGroup.form.addWeight')}</label>
                  <Button variant='primary' size='sm' onClick={this.toggleAddMenu}>
                    <FaPlus/>
                  </Button>
                </div>
              </Col>
            </Row>
            {addMenuOpen &&
              <Row>
                <Col xs={12}>
                <InternationalDecimalInput name='addPricingWeight' fractionPartSize={2} props={{
                  label: I18n.t('retail.pricingGroup.form.pricingWeight'),
                  rightAddon: I18n.t(`uoms.${selectedUom}.abbrev`),
                }}/>
                </Col>
                <Col xs={12}>
                <Button variant='primary' onClick={this.addWeight} disabled={this.addWeightButtonDisabled()}>
                {I18n.t('retail.pricingGroup.form.addWeight')}
                </Button>
                </Col>
              </Row>
            }
          </React.Fragment>
        }
        {!selectedUom &&
          <div>{I18n.t('retail.pricingGroup.form.pricingWeightsDisplayCondition')}</div>
        }
      </div>
    );
  }
}

PricingWeightsFieldArray.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    insert: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    get: PropTypes.func.isRequired,
    getAll: PropTypes.func.isRequired,
    length: PropTypes.number.isRequired,
  }).isRequired,
  change: PropTypes.func.isRequired,
  taxProfiles: PropTypes.array.isRequired,
  onPricingWeightAdded: PropTypes.func.isRequired,
  getFormValue: PropTypes.func.isRequired,
  pricingWeights: PropTypes.array.isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
  }).isRequired,
  fieldsMetadata: PropTypes.array.isRequired
};

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, itemActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    state: state
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PricingWeightsFieldArray);
