import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash.omit';
import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import { goBack, push } from 'react-router-redux';
import {formValueSelector, getFormValues, change, reset} from 'redux-form';
import getSalesSettings from '../../../util/salesSettings';
import {convertFormInputDateToDbDate, getToday, formatDate, formatClientDate, formatDBDate} from '../../../util/dateHelpers';
import * as apiActions from '../../../actions/apiActions';
import * as itemActions from '../../../actions/itemActions';
import * as itemNames from '../../../constants/itemNames';
import * as dataNames from '../../../constants/dataNames';
import * as messageTypes from '../../../constants/messageTypes';
import {medical_id, extended_sales_limit, driver_license} from '../../../constants/fileTypes';
import {FILE_TYPES, COMMON_PRIVATE_IMAGE} from '../../../constants/fileUploads';
import {PA_MED_CAREGIVER, PA_MED_PATIENT, DL} from '../../../constants/idTypes';
import {CUSTOMER_FORM} from '../../../constants/forms';
import {addMessage} from '../../../actions/systemActions';
import {getCaregivers} from '../../../selectors/customerSelectors';
import {
  getOrderedQualifyingConditions,
  getComplianceLimits,
  getFormFileTypes,
  getOraclePatientAsCustomerDeprecated,
  getOraclePatientAsCustomer,
  getOracleCaregiverForCustomer,
  setCustomerQualifyingCondition,
  getLastUpdatedComment,
  getAddressFieldsDisabled,
  getDisplaySaveAndCheckIn
} from '../../../selectors/customersSelectors';
import {getIntegrationSettings} from '../../../selectors/integration/commonSelectors';
import {isOnfleetIntegrator} from '../../../selectors/integration/thirdPartyIntegrationSelectors';
import {getIntegrationState} from '../../../selectors/integration/integrationSelectors';
import {getCurePatientAsCustomer} from '../../../selectors/integration/cureApiSelectors';
import {getApplicationMode} from '../../../selectors/applicationModesSelectors';
import {getInternationalPhoneCountryCode, getInternationalPhoneNumber} from '../../../util/internationalHelpers';
import * as applicationModes from '../../../constants/applicationModes';
import {formatMarijuanaId} from '../common/utils';
import {getOrderedClinics} from '../../../selectors/clinicsSelectors';
import {getDispensaries, hasCanadianFacilities} from '../../../selectors/facilitiesSelectors';
import {getOrderedPhysicians} from '../../../selectors/physiciansSelectors';
import {
  getPatientSettings,
  getSalesAgeLimit,
  hasMetrcSalesLimits,
} from '../../../selectors/salesSettingsSelectors';
import {getFacilityState} from '../../../selectors/facility/getCurrentFacility';
import {isFeatureEnabled} from '../../../selectors/featureToggles';
import {getVisitReasons, getDefaultVisitReason} from '../../../selectors/queueSelectors';
import CustomerForm from './CustomerForm'; // eslint-disable-line import/no-named-as-default
import ScanInputModal from '../../scan-input/ScanInputModal'; // eslint-disable-line import/no-named-as-default
import BirthdateModal from '../common/BirthdateModal';
import FormWrapper from '../../common/form/FormWrapper';
import CaregiverModal from './CaregiverModal';
import CustomerCheckInModal from '../checkin/CustomerCheckInModal'; // eslint-disable-line import/no-named-as-default
import {isCanadaFacility} from '../../../selectors/facility/getFacilityCountry';
import {MMIC, MEDICAL, DISABLED_VETERANS} from '../../../constants/consumerTypes';

export class CreateCustomerPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      uploadingPhoto: false,
      uploadingDocuments: [false, false, false, false],
      ready: false,
      showScanModal: false,
      scanType: '',
      scanData: {},
      patients: [],
      showBirthdateModal: false,
      showCaregiverModal: false,
      showCheckInModal: false,
      blockCheckIn: false,
    };
    this.ensureReferralSource = this.ensureReferralSource.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onSaveAndCheckIn = this.onSaveAndCheckIn.bind(this);
    this.saveCustomer = this.saveCustomer.bind(this);
    this.showScanModal = this.showScanModal.bind(this);
    this.hideScanModal = this.hideScanModal.bind(this);
    this.onScanComplete = this.onScanComplete.bind(this);
    this.getCardInfo = this.getCardInfo.bind(this);
    this.onBirthdateSubmit = this.onBirthdateSubmit.bind(this);
    this.selectCaregiverPatient = this.selectCaregiverPatient.bind(this);
    this.setCaregiverFormValues = this.setCaregiverFormValues.bind(this);
    this.openCheckInModal = this.openCheckInModal.bind(this);
    this.closeCheckInModal = this.closeCheckInModal.bind(this);
    this.checkIn = this.checkIn.bind(this);
  }

  componentWillMount() {
    const { actions: {getData,getItem,getUnpaginatedData,unsetItem,reset} } = this.props;
    unsetItem(itemNames.image);
    unsetItem(itemNames.customer);
    unsetItem(itemNames.oracleCaregiver);
    reset(CUSTOMER_FORM);

    const apiPromises = [];

    apiPromises.push( getUnpaginatedData(
      `/api/consumer_groups`,
      dataNames.customerGroups,
      { failed: I18n.t('customers.getGroups.failed') },
      {is_rewards_program_group: 0}
    ));

    apiPromises.push( getUnpaginatedData(
      `/api/referral_sources`,
      dataNames.referralSources,
      { failed: I18n.t('customers.getReferralSources.failed') }
    ));

    apiPromises.push( getItem(
      '/api/csrs',
      itemNames.budTendersObject,
      { failed: I18n.t('customers.getBudTenders.failed') },
      {active: 1}
    ));

    apiPromises.push( getUnpaginatedData(
      `/api/physicians/details`,
      dataNames.physicians
    ));

    apiPromises.push( getUnpaginatedData(
      '/api/customers/tags', dataNames.tags
    ));

    apiPromises.push( getUnpaginatedData(
      '/api/customers/qualifying_conditions',
      dataNames.qualifyingConditions
    ));

    apiPromises.push( getData(
      '/api/queue/visit_reasons',
      dataNames.visitReasons,
      {}
    ));

    apiPromises.push( getItem(
      '/api/customers/compliance_settings',
      itemNames.patientSettings
    ));

    // State integration settings are downloaded to determine whether the current facility is configured to use Leaf.
    // If the facility is configured to use Leaf, patient types are "Medical" and "Out of State", otherwise the
    // patient types are "Medical" and "Recreational".
    // The state integration settings are also downloaded to determine whether Medical ID expiration should be
    // required. The expiration date is required if the current facility is configured to use METRC for Colorado.
    apiPromises.push( getItem(
      '/api/integration-settings',
      itemNames.integrationSettings,
      {failed: 'stateIntegratorSettings.get.failed'}
    ));

    const ready = () => this.setState({ ready: true });
    Promise.all(apiPromises).then(ready).catch(ready);

    const caregiverFromPropsLocation = get(this.props, 'location.state.caregiver');
    if(caregiverFromPropsLocation){
      this.props.actions.setItem(caregiverFromPropsLocation, itemNames.oracleCaregiver);
    }
  }

  componentWillUnmount() {
    this.props.actions.unsetItem(itemNames.oraclePatient);
    this.props.actions.unsetItem(itemNames.oracleCaregiver);
  }

  ensureReferralSource(referralSource) {
    if(!referralSource) return Promise.resolve(null);

    if (get(referralSource,'id',false)) {
      // validate selection of existing referral source:
      if(this.props.referralSources.find( (r) => r.id === referralSource.id )){
        return Promise.resolve(referralSource.id);
      }
    } else {
      // post a new referral source
      return this.props.actions.postData(
        '/api/referral_sources',
        {name: referralSource},
        dataNames.referralSources,
        {failed: 'referralSources.create.failed'}
      ).then( response => response.id);
    }
  }

  // CustomerForm save handler
  onSave(formValues) {
    return this.saveCustomer(formValues, false);
  }

  // Submit customer data to api, shared by onSave and onSaveAndCheckIn
  saveCustomer(formValues, isSaveAndCheckIn) {
    const {timezone} = this.props;
    const isMetrcMd = this.props.applicationMode === applicationModes.metrcMd;
    return Promise.all([this.ensureReferralSource(formValues.customer.referral_source)])
      .then(([referralSourceId]) => {
        const caregivers = formValues.caregiverInfo && formValues.caregiverInfo.length
          ? formValues.caregiverInfo.map(
            caregiver =>
            {
              return Object.assign({}, caregiver, {
                birth_date: formatDate(caregiver.birth_date),
                phone_country_code: getInternationalPhoneCountryCode(caregiver.phone),
                phone: getInternationalPhoneNumber(caregiver.phone),
                medical_id: get(caregiver, 'medical_id', null),
                state_integration_tracking_id: isMetrcMd
                ? formatMarijuanaId(caregiver.state_integration_tracking_id)
                : caregiver.state_integration_tracking_id
              });
            })
          : [];
        const customerType = get(formValues, 'customer.type', undefined);
        let filesToIncludeInFiles;
        const filesToExcludeFromIds = filesToIncludeInFiles = [extended_sales_limit];
        const phone_country_code = getInternationalPhoneCountryCode(formValues.customer.number);
        const number = getInternationalPhoneNumber(formValues.customer.number);

        const customer = Object.assign({}, omit(formValues.customer, 'physician'), {
          caregivers,
          birth_date: formatDBDate(formValues.customer.birth_date),
          date_started: convertFormInputDateToDbDate(getToday(), timezone),
          date_provider_can_switch: formatDate(formValues.date_provider_can_switch),
          type: [MMIC, DISABLED_VETERANS].includes(customerType) ? MEDICAL : customerType,
          is_not_mmic: customerType !== MMIC,
          is_disabled_veterans: customerType === DISABLED_VETERANS,
          phone_numbers: [{
            type: 'default',
            number: number,
            phone_country_code: phone_country_code
          }],
          ids: formValues.ids.filter(id => filesToExcludeFromIds.indexOf(id.type) === -1).map(file => {
            const identification_number = (file.type === 'med' && isMetrcMd)
              ? formatMarijuanaId(file.identification_number)
              : file.identification_number;
            return {
              ...file,
              identification_number,
              expired_at: formatDate(file.expired_at),
            };
          }),
          files: (formValues.ids || []).filter(id => filesToIncludeInFiles.indexOf(id.type) !== -1).map(file => ({
            ...file,
            file_type: get(file, 'file_type.accept[0]'),
            expires_on: formatDate(file.expired_at),
          })),
          registrations: formValues.registrations,
          group_ids: formValues.customer.group_ids.map(group => group.id),
          tags: formValues.customer.tags.map(tag => typeof(tag) === 'object' ? tag.tag_name : tag), // Weird behavior: New tags are string, but old tags are object.
          addresses: formValues.addresses,
          referral_source: referralSourceId,
          facility_possession_limit: formValues.limitsInfo.facility_possession_limit || undefined,
          facility_sales_limit: formValues.limitsInfo.facility_sales_limit || null,
          facility_plants_veg_limit: formValues.limitsInfo.facility_plants_veg_limit || undefined,
          facility_plants_flower_limit: formValues.limitsInfo.facility_plants_flower_limit || undefined,
          facility_plants_total_limit: formValues.limitsInfo.facility_plants_total_limit || undefined,
          physician_recommendation_date: formatDate(formValues.customer.physician_recommendation_date),
          partner_state_expiration_date: formatDate(formValues.customer.partner_state_expiration_date),
          personal_use_producer: Number(formValues.customer.personal_use_producer),
        });

        // Sets either qualifying_condition_id or new_qualifying_condition on customerData
        setCustomerQualifyingCondition(customer, formValues, this.props.qualifyingConditions);

        this.setState({blockCheckIn: false});
        return this.props.actions.postItem(
          '/api/customers',
          customer,
          itemNames.customer,
          {failed: 'customers.create.failed', success: 'customers.create.success'},
          {}
        ).then( (newCustomer) => {
          // get new caregivers for this patient:
          this.props.actions.getData(
            `/api/customers/${newCustomer.id}/caregivers`,
            dataNames.caregivers
          );
          // save id state for use by CheckinModal
          this.setState({newCustomerId: newCustomer.id});

          if (!isSaveAndCheckIn) {
            this.props.actions.unsetItem(itemNames.image);
            this.props.actions.goBack();
          }
        }).catch( err => {
          this.setState({blockCheckIn: true});
          throw err;
        });
      })
      .catch(error => this.props.actions.addMessage(messageTypes.error, 'customers.modify.failed'));
  }

  // CustomerForm onSaveAndCheckIn handler
  onSaveAndCheckIn(formValues) {
    return this.saveCustomer(formValues, true).then( (customer) => {
      if(!this.state.blockCheckIn){
        this.openCheckInModal();
      }
    });
  }

  closeCheckInModal() {
    this.setState({showCheckInModal: false});
    this.props.actions.push(`/patients`);
  }

  openCheckInModal() {
    this.setState({showCheckInModal: true});
  }

  checkIn(formValues) {
    const payload = {consumer_id: this.state.newCustomerId};
    if(formValues.visit_reason_id !== undefined) payload.visit_reason_id = formValues.visit_reason_id;
    if(formValues.preferred_csr !== undefined) payload.preferred_csr = formValues.preferred_csr;
    if(formValues.caregiver_id !== undefined) payload.caregiver_id = formValues.caregiver_id;
    return this.props.actions.postItem(
      '/api/queue/customers',
      payload,
      itemNames.queueCustomer,
      {failed: 'customers.addToQueue.failed', success: 'customers.addToQueue.success'}
    ).then( () => {
      this.props.actions.push(`/patients`);
    }).catch(() => {});
  }


  showScanModal(scanType, idType) {
    return () => {
      this.setState({showScanModal: true, scanType, idType});
    };
  }

  hideScanModal() {
    this.setState({showScanModal: false});
  }

  onBirthdateSubmit(formData){
    const {scanData, idType} = this.state;
    this.getCardInfo({
      dob: formatDate(formData.birth_date),
      registry_id: idType === PA_MED_PATIENT ?
        scanData.customer.state_integration_tracking_id :
        scanData.caregiverInfo.state_integration_tracking_id,
      card_number: idType === PA_MED_PATIENT ?
        scanData.medical_id :
        scanData.caregiverInfo.medical_id
    });
    this.setState({showBirthdateModal: false});
  }

  selectCaregiverPatient(id){
    this.props.actions.setItem(this.state.patients[id], itemNames.oraclePatient);
    this.setState({showCaregiverModal: false});
  }

  setCaregiverFormValues(formValues, caregiver, index) {
    const changeEmptyField = (formField, caregiverField) => {
      if (!formValues[formField]) {
        this.props.actions.change(CUSTOMER_FORM, `caregiverInfo[${index}].${formField}`, caregiver[caregiverField]);
      }
    };

    changeEmptyField('state_integration_tracking_id','caregiver_id');
    changeEmptyField('addresses[0].street_address_1', 'caregiver_address1');
    changeEmptyField('addresses[0].city', 'caregiver_city');
    changeEmptyField('addresses[0].province_code', 'caregiver_state');
    changeEmptyField('addresses[0].postal_code', 'caregiver_zip');
    if (caregiver.caregiver_name) {
      const nameParts = caregiver.caregiver_name.match(/^([^\s]+)\s+(.*)/);
      if (nameParts[1] && !formValues.first_name) {
        this.props.actions.change(CUSTOMER_FORM, `caregiverInfo[${index}].first_name`, nameParts[1]);
      }
      if (nameParts[2] && !formValues.last_name) {
        this.props.actions.change(CUSTOMER_FORM, `caregiverInfo[${index}].last_name`, nameParts[2]);
      }
    }
  }

  getCardInfo(payload){
    const {currentFormValues} = this.props;
    const lastCaregiverIndex = currentFormValues.caregiverInfo.length - 1;

    if(this.state.idType === PA_MED_CAREGIVER){
      this.props.actions.postItem('/api/leaf/oracle/caregiver_details', payload,
        itemNames.oracleCaregiver, {failed: 'customers.integration.caregiverFailed'},
        undefined, (data) => {
          this.setState({
            patients: data.patients,
            showCaregiverModal: true});
          this.props.actions.change(CUSTOMER_FORM, `caregiverInfo[${lastCaregiverIndex}].birth_date`, formatClientDate(payload.dob));
          this.setCaregiverFormValues(currentFormValues.caregiverInfo[lastCaregiverIndex], data, lastCaregiverIndex);
        }
      );
    }else{
      this.props.actions.postItem('/api/leaf/oracle/patient_details', payload,
        itemNames.oraclePatient, {failed: 'customers.integration.patientFailed'},
        undefined, () => {
          this.props.actions.change(CUSTOMER_FORM, 'customer.birth_date', formatClientDate(payload.dob));
        });
    }
  }



  onScanComplete(parsedData) {
    let showBirthdateModal = false;
    let scanData = {};

    if (parsedData) {
      try{
        if(this.state.idType === DL){
          scanData = {
            customer: {
              first_name: parsedData && parsedData.name ? parsedData.name.first : '',
              middle_name: parsedData && parsedData.name && parsedData.name.middle ? parsedData.name.middle.charAt(0) : '',
              last_name: parsedData && parsedData.name ? parsedData.name.last : '',
              birth_date: parsedData && parsedData.birthday ? `${parsedData.birthday.substring(4,6)}/${parsedData.birthday.substring(6)}/${parsedData.birthday.substring(0,4)}` : '',
              gender: parsedData && parsedData.sex ? parsedData.sex.toLowerCase() : '',
              drivers_license: parsedData ? parsedData.dl : '',
              drivers_license_exp: parsedData && parsedData.expiration_date ? `${parsedData.expiration_date.substring(4,6)}/${parsedData.expiration_date.substring(6)}/${parsedData.expiration_date.substring(0,4)}` : '',
              tax_exempt: '0',
              tags: [],
              group_ids: []
            },
            addresses: [{
              street_address_1: parsedData ? parsedData.address : '',
              city: parsedData ? parsedData.city : '',
              province_code: parsedData ? parsedData.state : '',
              postal_code: parsedData ? parsedData.postal_code : ''
            },{}],
            id: parsedData ? parsedData.id : ''
          };
        }else if(this.state.idType === PA_MED_PATIENT){
          scanData = {
            customer: {
              state_integration_tracking_id: parsedData ? parsedData.registry_id : ''
            },
            medical_id: parsedData ? parsedData.card_id : '',
          };
          if(this.props.currentFormValues.customer.birth_date){
            this.getCardInfo({
              dob: formatDate(this.props.currentFormValues.customer.birth_date),
              registry_id: scanData.customer.state_integration_tracking_id,
              card_number: scanData.medical_id
            });
          }else{
            showBirthdateModal = true;
          }
        }else if(this.state.idType === PA_MED_CAREGIVER){
          const  lastCaregiverIndex = this.props.currentFormValues.caregiverInfo.length - 1;
          scanData = {
            caregiverInfo: {
              medical_id: parsedData ? parsedData.card_id : '',
              state_integration_tracking_id: parsedData ? parsedData.registry_id : '',
            }
          };
          if(this.props.currentFormValues.caregiverInfo[lastCaregiverIndex].birth_date){
            this.getCardInfo({
              dob: formatDate(this.props.currentFormValues.caregiverInfo[lastCaregiverIndex].birth_date),
              registry_id: scanData.caregiverInfo.state_integration_tracking_id,
              card_number: scanData.caregiverInfo.medical_id
            });
          }else{
            showBirthdateModal = true;
          }
        }
      }catch(e) {
        console.error(e); //eslint-disable-line
        this.props.actions.addMessage(messageTypes.error, I18n.t('customers.scan.failed'));
        this.setState({showScanModal: false});
        // add some error handling here
      }

      this.setState({showScanModal: false, scanData, showBirthdateModal});
    }
  }

  render() {
    const {ready, scanData} = this.state;
    const { customerGroups, referralSources, physicians, budTenders, facilities,
      fileTypes, customer, isCanadaFacility, tags, documents, qualifyingConditions,
      patientSettings, currentFormValues, complianceLimits, salesAgeLimit,
      isLeafIntegrator, medicalIdExpirationIsRequired, limitsInfo, oracleCustomer,
      oracleCaregiver, isPaLeaf, checkInFormValues, curePatient, applicationMode,
      orgHasCanada, isWaLeaf, facilityState, integrationState, lastUpdatedComment,
      onfleetIsEnabled, primaryFacilityIsRequired, visitReasons, defaultVisitReason,
      caregivers, registrations, paOraclePatientSyncFeature, addressFieldsDisabled,
      displaySaveAndCheckIn, hasMetrcSalesLimits
    } = this.props;
    let initialCustomer = {
      tax_exempt: '0',
      in_state: '1',
      tags: [],
      group_ids: [],
      image_file_id: null,
      personal_use_producer: '0',
      active: '1'
    };
    let caregiverBirthDate = null;
    if(oracleCustomer && oracleCustomer.customer){
      initialCustomer = {...initialCustomer, ...oracleCustomer.customer, isOracle: true};
    }
    if(scanData.customer){
      initialCustomer = {... initialCustomer, ...scanData.customer};
    }
    if (checkInFormValues && checkInFormValues.dob && checkInFormValues.license_type) {
      const dob = formatClientDate(checkInFormValues.dob);
      if (checkInFormValues.license_type === PA_MED_CAREGIVER) {
        caregiverBirthDate = dob;
      }
    }
    if (curePatient && curePatient.customer) {
      initialCustomer = {...initialCustomer, ...curePatient.customer};
    }
    if (integrationState.isOhMetrc) {
      initialCustomer.is_terminal_illness = 0;
    }
    if (isPaLeaf) {
      initialCustomer.type = MEDICAL;
    }
    let addresses = [{}];
    if(oracleCustomer && oracleCustomer.addresses && oracleCustomer.addresses[0] && oracleCustomer.addresses[0].street_address_1){
      addresses = oracleCustomer && Array.isArray(oracleCustomer.addresses) ? oracleCustomer.addresses : addresses;
    }else{
      addresses = scanData && scanData.addresses && Array.isArray(scanData.addresses) ? scanData.addresses : addresses;
    }
    let caregiverInfo = {};
    if(oracleCaregiver){
      caregiverInfo = {...scanData.caregiverInfo, ...oracleCaregiver, birth_date: caregiverBirthDate};
    }

    let ids = [{
      type: medical_id,
      file_type: FILE_TYPES[COMMON_PRIVATE_IMAGE].type,
      identification_number: scanData && scanData.medical_id,
    }];
    if (oracleCustomer && oracleCustomer.ids && oracleCustomer.ids[0] && oracleCustomer.ids[0].identification_number) {
      ids = oracleCustomer.ids;
    } else if (curePatient && curePatient.ids) {
      ids = curePatient.ids;
    }
    if (scanData && scanData.id){
      const dlIndex = ids.findIndex(id => id.type === driver_license);
      if(dlIndex === -1){
        ids.push({
          type: driver_license,
          file_type: FILE_TYPES[COMMON_PRIVATE_IMAGE].type,
          identification_number: scanData.id,
          expired_at: get(scanData,'customer.drivers_license_exp',null)
        });
      }else{
        ids[dlIndex] = {
          type: driver_license,
          file_type: FILE_TYPES[COMMON_PRIVATE_IMAGE].type,
          identification_number: scanData.id,
          expired_at: get(scanData,'customer.drivers_license_exp',null)
        };
      }
    }
    const initialValues = {
      isLeafIntegrator,
      caregiverInfo: [caregiverInfo],
      addresses,
      customer: initialCustomer,
      limitsInfo: {
        facility_order_total_limit_period: complianceLimits && complianceLimits.facility_order_total_limit_period,
        facility_plants_total_limit: complianceLimits && complianceLimits.facility_plants_total_limit,
        facility_plants_flower_limit: complianceLimits && complianceLimits.facility_plants_flower_limit,
        facility_plants_veg_limit: complianceLimits && complianceLimits.facility_plants_veg_limit,
        facility_sales_limit: null,
        facility_possession_limit: complianceLimits && complianceLimits.facility_possession_limit,
        facility_order_total_limit: complianceLimits && complianceLimits.facility_order_total_limit,
      },
      ids,
    };

    return (
      <FormWrapper title={I18n.t('customers.create.title')} goBack={this.props.actions.goBack} className='create-customer-page'>
        {ready && <CustomerForm
          form={CUSTOMER_FORM}
          enableReinitialize={true}
          keepDirtyOnReinitialize={true}
          limitsInfo={limitsInfo}
          initialValues={initialValues}
          salesAgeLimit={salesAgeLimit}
          handleSave={this.onSave}
          handleSaveAndCheckIn={this.onSaveAndCheckIn}
          customerGroups={customerGroups}
          physicians={physicians}
          medicalIdExpirationIsRequired={medicalIdExpirationIsRequired}
          referralSources={referralSources}
          budTenders={budTenders}
          facilities={facilities}
          tagOptions={tags}
          documentUploads={documents}
          registrations={registrations}
          enableScanModal={true}
          showScanModal={this.showScanModal}
          qualifyingConditions={qualifyingConditions}
          notes={[]}
          onfleetIsEnabled={onfleetIsEnabled}
          patientSettings={patientSettings}
          currentFormValues={currentFormValues}
          restrictSalesTimePeriods={getSalesSettings().config.restrictSalesTimePeriods}
          complianceLimits={complianceLimits}
          fileTypes={fileTypes}
          customer={customer}
          isPaLeaf={isPaLeaf}
          applicationMode={applicationMode}
          isCanadaFacility={isCanadaFacility}
          orgHasCanada={orgHasCanada}
          isWaLeaf={isWaLeaf}
          facilityState={facilityState}
          integrationState={integrationState}
          lastUpdatedComment={lastUpdatedComment}
          primaryFacilityIsRequired={primaryFacilityIsRequired}
          canCheckIn={+get(currentFormValues,'customer.active',0)}
          paOraclePatientSyncFeature={paOraclePatientSyncFeature}
          addressFieldsDisabled={addressFieldsDisabled}
          displaySaveAndCheckIn={displaySaveAndCheckIn}
          hasMetrcSalesLimits={hasMetrcSalesLimits}
        />}
        <ScanInputModal showModal={this.state.showScanModal}
          scanType={this.state.scanType}
          onScanComplete={this.onScanComplete}
          hideModal={this.hideScanModal} />
        <BirthdateModal showModal={this.state.showBirthdateModal}
          onSubmit={this.onBirthdateSubmit}
          hideModal={() => this.setState({showBirthdateModal: false})}/>
        <CaregiverModal showModal={this.state.showCaregiverModal}
          onClick={this.selectCaregiverPatient}
          patients={this.state.patients}
          hideModal={() => this.setState({showCaregiverModal: false})}/>
        <CustomerCheckInModal
          showModal={this.state.showCheckInModal}
          close={this.closeCheckInModal}
          checkIn={this.checkIn}
          visitReasons={visitReasons}
          initialValues={{visit_reason_id: get(defaultVisitReason,'id',0)}}
          customer={this.props.customer}
          budTenders={budTenders}
          caregivers={caregivers}
        />
      </FormWrapper>
    );
  }
}

CreateCustomerPage.propTypes = {
  customerGroups: PropTypes.array.isRequired,
  physicians: PropTypes.array.isRequired,
  referralSources: PropTypes.array.isRequired,
  budTenders: PropTypes.array.isRequired,
  facilities: PropTypes.array.isRequired,
  documents: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  tags: PropTypes.array.isRequired,
  timezone: PropTypes.string.isRequired,
  qualifyingConditions: PropTypes.array.isRequired,
  patientSettings: PropTypes.object.isRequired,
  currentFormValues: PropTypes.object.isRequired,
  isLeafIntegrator: PropTypes.bool,
  onfleetIsEnabled: PropTypes.bool,
  medicalIdExpirationIsRequired: PropTypes.bool,
  complianceLimits: PropTypes.object.isRequired,
  fileTypes: PropTypes.array.isRequired,
  customer: PropTypes.object.isRequired,
  limitsInfo: PropTypes.object.isRequired,
  checkInFormValues: PropTypes.object.isRequired,
  isPaLeaf: PropTypes.bool,
  orgHasCanada: PropTypes.bool,
  curePatient: PropTypes.object,
  isCanadaFacility: PropTypes.bool.isRequired,
  isWaLeaf: PropTypes.bool,
  facilityState: PropTypes.string,
  integrationState: PropTypes.object,
  lastUpdatedComment: PropTypes.string,
  salesAgeLimit: PropTypes.number,
  primaryFacilityIsRequired: PropTypes.bool.isRequired,
  visitReasons: PropTypes.array,
  defaultVisitReason: PropTypes.object,
  caregivers: PropTypes.array,
  addressFieldsDisabled: PropTypes.bool,
  displaySaveAndCheckIn: PropTypes.bool.isRequired,
  hasMetrcSalesLimits: PropTypes.bool,
};

const selector = formValueSelector('customer');

function mapStateToProps(state) {
  const {customerGroups, referralSources, budTendersObject, tags, timezone, checkInFormValues} = state;
  const budTenders = Object.keys(budTendersObject).map(id => ({
    id,
    name: budTendersObject[id]
  }));
  const currentFormValues = getFormValues('customer')(state) || {};
  const formValues = selector(state, 'ids', 'customer', 'limitsInfo', 'registrations');
  //const documents = formValues.ids || [];
  const documents = (formValues.ids || []);
  const registrations = (formValues.registrations || []);
  const customer = formValues.customer || {};
  const limitsInfo = formValues.limitsInfo || {};
  const integrationState = getIntegrationState(state);
  const {isLeaf: isLeafIntegrator, isCoMetrc: medicalIdExpirationIsRequired, isPaLeaf, isWaLeaf} = integrationState;
  const onfleetIsEnabled = isOnfleetIntegrator(state);
  const facilities = getDispensaries(state);
  const paOraclePatientSyncFeature = isFeatureEnabled(state)('feature_pa_oracle_patient_sync');

  const primaryFacilityIsRequired = facilities.filter(facility => {
    return facility.country_code === 'CA' && facility.type === 'dispensary';
  }).length > 0;

  return {
    isPaLeaf,
    oracleCaregiver: getOracleCaregiverForCustomer(state),
    oracleCustomer: paOraclePatientSyncFeature ? getOraclePatientAsCustomer(state) : getOraclePatientAsCustomerDeprecated(state),
    curePatient: getCurePatientAsCustomer(state),
    documents,
    registrations,
    limitsInfo,
    customerGroups,
    isLeafIntegrator,
    onfleetIsEnabled,
    salesAgeLimit: getSalesAgeLimit(state),
    complianceLimits: getComplianceLimits(state, {customer: {type: get(formValues, 'customer.type', 'medical')}}),
    clinics: getOrderedClinics(state),
    physicians: getOrderedPhysicians(state),
    referralSources,
    budTenders,
    tags,
    qualifyingConditions: getOrderedQualifyingConditions(state),
    facilities,
    primaryFacilityIsRequired,
    timezone,
    patientSettings: getPatientSettings(state, {customer: {type: get(formValues, 'customer.type', 'medical')}}), // based on sales compliance
    currentFormValues,
    customer,
    checkInFormValues,
    fileTypes: getFormFileTypes(state, {customer: {type: get(formValues, 'customer.type', 'medical')}}),
    medicalIdExpirationIsRequired,
    applicationMode: getApplicationMode(state),
    isCanadaFacility: isCanadaFacility(state),
    orgHasCanada: hasCanadianFacilities(state),
    isWaLeaf,
    facilityState: getFacilityState(state),
    integrationState,
    lastUpdatedComment: getLastUpdatedComment(state),
    integrationSettings: getIntegrationSettings(state),
    visitReasons: getVisitReasons(state),
    defaultVisitReason: getDefaultVisitReason(state),
    caregivers: getCaregivers(state),
    paOraclePatientSyncFeature,
    addressFieldsDisabled: getAddressFieldsDisabled(state),
    displaySaveAndCheckIn: getDisplaySaveAndCheckIn(state),
    hasMetrcSalesLimits: hasMetrcSalesLimits(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, itemActions,
    {addMessage, goBack, change, push, reset});
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateCustomerPage);
