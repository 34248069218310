import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Nav} from 'react-bootstrap';
import PermissionWrapper from '../common/PermissionWrapper';
import AppNavigationListItem from './AppNavigationListItem';
import {getFilteredAppsWithPermissions} from '../../selectors/appsSelectors';


class AppNavigationList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      expandedAppListItems: {}
    };

    this.setExpandedAppState = this.setExpandedAppState.bind(this);
  }

  setExpandedAppState(app) {
    const expanded = Boolean(this.state.expandedAppListItems[app.name]);

    this.setState({
      expandedAppListItems: {
        ...this.state.expandedAppListItems,
        [app.name]: !expanded
      }
    });
  }

  render() {
    const {availableApps} = this.props;

    return (
      <Nav>
        {availableApps.map((app) => {
          return (
            <PermissionWrapper key={app.id}
                                 permissions={app.permissions}
                                 accessibility={app.accessibility}
            >
              <AppNavigationListItem app={app}
                                     showSubNavigation={this.state.expandedAppListItems[app.name]}
                                     onToggleShowSubNavigation={() => this.setExpandedAppState(app)}
              />
            </PermissionWrapper>
          );
        })}
      </Nav>
    );
  }
}

AppNavigationList.propTypes = {
  availableApps: PropTypes.array
};

const mapStateToProps = (state) => ({
  availableApps: getFilteredAppsWithPermissions(state)
});

export default connect(mapStateToProps)(AppNavigationList);
