import {createSelector} from 'reselect';
import orderBy from 'lodash.orderby';
import omit from 'lodash.omit';
import map from 'lodash.map';
import * as dataNames from '../constants/dataNames';
import {getActiveFacilityId} from './facilitiesSelectors';
import {getFeedingScheduleById} from './feedingSchedulesSelectors';
import flattenLocations from '../util/flattenLocations';
import {getIntegrationState} from './integration/integrationSelectors';
import {getFacilitySharingStatus} from './facilityGroupsSharingSelectors';

export const getLocations = state => state[dataNames.locations];
export const getPartnerLocations = state => state[dataNames.partnerLocations];
export const getCultivationLocations = state => state[dataNames.cultivationLocations];
export const getBuildings = state => state[dataNames.buildings];
export const getSections = (state) => state[dataNames.sections];
export const getLocationsSections = (state) => state[dataNames.locationSections];

const locationNames = ['buildings', 'child_locations', 'child_locations', 'child_locations'];

function deNormalizeLocation(location, level = 0) {
  const childLocationsName = locationNames[level + 1];
  const childLocations = location[childLocationsName];
  if (!childLocations || level > 3) {
    return {...location, child_locations: []};
  }
  return {
    ...omit(location, childLocationsName),
    child_locations: childLocations.map(childLocation => deNormalizeLocation(childLocation, level + 1)),
  };
}

const getBuildingsHierarchy = createSelector(
  [getBuildings],
  buildings => buildings.map(building => deNormalizeLocation(building, 0))
);

export const getCultivationLocationsInitialValues = createSelector(
  [getBuildingsHierarchy],
  buildings => ({
    defaults: [0, 1, 1, 1],
    facility_square_footage: buildings.length ? buildings[0].facility_square_footage : '',
    facility_total_rent: buildings.length ? buildings[0].facility_total_rent : '',
    child_locations: buildings,
  })
);



function normalizeLocation(location, level = 0) {
  if (!location.child_locations || !location.child_locations.length || level > 3) {
    return omit(location, 'child_locations');
  }
  return {
    ...omit(location, 'child_locations'),
    [locationNames[level + 1]]: location.child_locations.map(child => normalizeLocation(child, level + 1))
  };
}

export function calculateDiff(initialLocations, locations, level, totals) {
  if (level > 3) {
    return totals;
  }
  const locationTotals = totals[locationNames[level]];
  initialLocations.forEach(initialLocation => {
    if (initialLocation.id && !locations.find(l => l.id === initialLocation.id)) {
      return locationTotals.deleted.push(initialLocation.id);
    }
  });
  locations.forEach(location => {
    if (location.id) {
      const initialLocation = initialLocations.find(initialLocation => initialLocation.id === location.id);
      const groupedChildren = location.child_locations.reduce(
        (acc, childLocation) => {
          acc[childLocation.id ? 'updated' : 'created'].push(childLocation);
          return acc;
        },
        {created: [], updated: []}
      );
      locationTotals.updated.push(normalizeLocation({...location, child_locations: groupedChildren.created}, level));
      calculateDiff(initialLocation.child_locations, groupedChildren.updated, level + 1, totals);
    } else {
      locationTotals.created.push(normalizeLocation(location, level));
    }
  });

  return totals;
}


export function getCultivationLocationsPayload(initialLocations, formValues) {
  const totals = {
    buildings: {
      created: [],
      updated: [],
      deleted: [],
    },
    child_locations: {
      created: [],
      updated: [],
      deleted: [],
    }
  };
  calculateDiff(initialLocations, formValues.child_locations, 0, totals);
  totals.buildings.created = totals.buildings.created.map(building => ({
    ...building,
    facility_square_footage: formValues.facility_square_footage,
    facility_total_rent: formValues.facility_total_rent,
  }));
  totals.buildings.updated = totals.buildings.updated.map(building => ({
    ...building,
    facility_square_footage: formValues.facility_square_footage,
    facility_total_rent: formValues.facility_total_rent,
  }));
  return totals;
}

export const getFilteredLSections = createSelector([getSections, getActiveFacilityId], (sections, facilityId) => {
  const filteredSections = sections.filter(section => section.facility_id === facilityId);
  return orderBy(filteredSections, 'name', 'asc');
});

export const getLocationSectionOptions = createSelector( [getSections], sections => {
  return sections.map( section => {
    return {
      text: section.name,
      value: section.id
    };
  });
});

export const getCultivationLocationOptions = createSelector(
  [getFilteredLSections],
  sections => sections.map(section => ({...section, text: section.name, value: section.id}))
);

export const getFlattenedLocations = createSelector(getLocations, locations => flattenLocations(locations));
export const getFlattenedPartnerLocations = createSelector(getPartnerLocations, locations => flattenLocations(locations));

export const getFlattenedCultivationLocations = createSelector(getCultivationLocations, locations => flattenLocations(locations));

export const getHarvestStorageLocations = createSelector(
  [getFlattenedLocations, getCultivationLocationOptions, getIntegrationState],
  (storageLocations, cultLocations, {isBiotrack}) => isBiotrack ? cultLocations : storageLocations
);

export const getFlattenedSalesLocations = createSelector(getFlattenedLocations,
  locations => locations.filter(location => location.is_sales_location));

export const getFlattenedStorageLocations = createSelector(getFlattenedLocations,
    locations => locations.filter(location => !location.is_sales_location));

export const getFlatTopLocations = createSelector(getLocations, locations => {
  return locations.map(location => {
    const child_ids = [location.id, ...getChildIds(location.child_locations)];
    return {...location, child_ids};
  });
});

const getChildIds = (locations) => {
  return locations.reduce((acc, value) => {
    if(value.child_locations){
      return acc.concat([value.id, ...getChildIds(value.child_locations)]);
    }else{
      return acc.concat([value.id]);
    }
  } ,[]);
};

export const getLSectionsByFeedingSchedule = createSelector([getFilteredLSections, getFeedingScheduleById], (locations, feedingSchedule) => {
  if(feedingSchedule){
    return locations.filter(location => {
      if(feedingSchedule){
        return Boolean(feedingSchedule.locations.find(loc => loc.location_id === location.id));
      }else{
        return true;
      }
    });
  }else{
    return locations;
  }
});

const getChildBuilding = (locations, level) => {
  let acc = [];
  locations.forEach(location => {
    if (location.child_locations) {
      acc = acc.concat(getChildBuilding(location.child_locations, level + 1));
    }
    const locationData = {...omit(location, 'child_locations'), level: level};
    acc.push(locationData);
  });
  return acc;
};

export const getFlatBuildings = createSelector([getBuildings], (buildings) => {
  let flatBuildings = [];
  buildings.forEach(building => {
    const locationData = {...omit(building, 'child_locations'), level: 0};
    flatBuildings.push(locationData);
    flatBuildings = flatBuildings.concat(getChildBuilding(building.child_locations, 1));
  });
  return flatBuildings;
});

export const getLocationNamesInUse = createSelector([getSections, getLocationsSections], (cultivationsSections, locationsSections) => {
  const sections = [...cultivationsSections, ...locationsSections];
  return map(sections, 'name');
});

export const getLocationsForSharedProducts = createSelector([getFlattenedLocations, getFacilitySharingStatus], (locations, facilitySharingStatus) => {
  if (facilitySharingStatus && facilitySharingStatus.is_in_sharing_group) {
    return locations.filter(location => location.shared_location_id);
  } else {
    return locations;
  }
});
