import React from 'react';
import PropTypes from 'prop-types';
import {CardGroup} from 'react-bootstrap';
import CustomerTile from '../CustomerTile';

const SegmentedCustomerList = ({currentQueue, customers, segments, pageWidth, activeSegmentId, customerTileProps, defaultVisitReason}) => {

  if(activeSegmentId !== 0 || !Array.isArray(segments) || defaultVisitReason.id === undefined) return null;

  const customerFieldMap = {
    in_store: 'visit_reason_id',
    delivery: 'zone_id'
  };

  const groupedCustomers = {};

  if(Array.isArray(segments) && segments.length && Array.isArray(customers)) {
    segments.forEach((seg) => {
      if (seg.id === 0) return false;
      groupedCustomers[seg.id] = [];
    });
    if(segments.length > 1) { // When 1 the catch all "All" is the only one available so no other segments to fill
      customers.forEach((customer) => {
        // Force any unassigned visit reasons into Standard Visit - this works for in store, but will need refinement for deliver
        const groupKey = (groupedCustomers[customer[customerFieldMap[currentQueue]]] === undefined) ? defaultVisitReason.id : customer[customerFieldMap[currentQueue]];
        groupedCustomers[groupKey].push(customer);
      });
    }
  }

  return (<div>
      {Object.keys(groupedCustomers).map((key, index) => {
        const segment = segments.find((seg) => seg.id === parseInt(key));
        const segmentName = segment ? segment.name : 'Unknown Segment';
        return (<div key={index}>
            <div style={{clear:'both', paddingLeft: '15px'}}>
              <h4 style={{marginBottom: '5px'}}>{segmentName} ({groupedCustomers[key].length})</h4>
              <hr style={{marginTop: '5px'}} />
            </div>
            <div style={{clear: 'both'}}>
              <CardGroup>
              {groupedCustomers[key].map((customer, index) => {
                return (<CustomerTile
                  key={index}
                  customer={customer}
                  {...customerTileProps}
                />);
              })}
              </CardGroup>
            </div></div>
        );
      })}
    </div>);

};

SegmentedCustomerList.propTypes = {
  currentQueue: PropTypes.string,
  customers: PropTypes.array,
  segments: PropTypes.array,
  defaultVisitReason: PropTypes.object.isRequired,
  activeSegmentId: PropTypes.number,
  pageWidth: PropTypes.number,
  customerTileProps: PropTypes.object.isRequired,
};

export default SegmentedCustomerList;
