import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Button, Col, Row} from 'react-bootstrap';
import {FaMinus} from 'react-icons/fa';
import TextInput from '../../../common/form/TextInput';
import AddItem from '../../../common/form/AddItem';

const DirectionsRow = (props) => {
  const {fields:{map, push, remove, length}} = props;
  return (
    <div>
      {
        map((item, index) => {
          return(
            <Row key={index}>
              <Col xs={10} sm={10} md={11}>
                <Field
                  name={`${item}.direction`}
                  component={TextInput}
                  props={{
                    label: I18n.t('inventory.assemblyListing.createAssembly.form.step', {number: index + 1})
                  }}
                />
              </Col>
              <Col xs={2} sm={2} md={1}>
                <div className='float-right ingredients-minus-wrapper'>
                  <Button variant='primary' size='sm' onClick={() => remove(index)} disabled={length < 2}>
                    <FaMinus/>
                  </Button>
                </div>
              </Col>
            </Row>
          );
        })
      }
      <Row>
        <AddItem
          textMode='left'
          alignMode='right'
          addAction={() => push({})}
        />
      </Row>
    </div>
  );
};

DirectionsRow.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    length: PropTypes.number
  })
};

export default DirectionsRow;
