import {requiredFieldValidation, betweenValidation} from '../../../common/form/redux-form/validations';

export const validateEquipmentForm = values => {
  const errors = {};
  errors.items = values.items.map(value => {
    return {
      equipType: requiredFieldValidation(value.equipType, 'equipment.form.equipmentType'),
      vendor: requiredFieldValidation(value.vendor, 'equipment.form.vendor'),
      equipModel: requiredFieldValidation(value.equipModel, 'equipment.form.modelName'),
      hoursPerDay: value.hoursPerDay ? betweenValidation(value.hoursPerDay, '', 0, 24) : null
    };
  });
  return errors;
};

export default {
  validateEquipmentForm,
};
