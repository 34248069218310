import {
  PARTNER_STATE_LICENSE,
  PARTNER_WHOLESALE_TAX_ID,
  PARTNER_STATE_TAX_ID,
  COMMON_PRIVATE_IMAGE,
  COMMON_PRIVATE_DOCUMENT,
  COMMON_PRIVATE_WORD_DOCUMENT,
  PARTNER_COUNTY_LICENSE
} from './fileUploads';

// Facility Documents
export const state = 'state';
export const wholesale = 'wholesale';
export const dea_number = 'dea_number';
export const tax = 'tax';
export const county = 'county';
export const AU_state = 'AU_state';
export const AU_wholesale = 'AU_wholesale';
//Patient Documents
export const medical_id = 'med';
export const driver_license = 'driver_license';
export const intake_form = 'intake_form';
export const extended_sales_limit = 'extended_sales_limit';
export const canadian_registration_form = 'canadian_registration_form';
export const temp_id = 'temp';
//Inventory Receipt Files
export const inventory_receipt_document = 'inventory_receipt_document';

export const fileOptions = [
  {
    id: state,
    type: state,
    file_type: PARTNER_STATE_LICENSE,
    name: 'partners.createPartner.stateLicense',
    fieldLabel: 'partners.createPartner.licenseNumber',
  },
  {
    id: wholesale,
    type: wholesale,
    file_type: PARTNER_WHOLESALE_TAX_ID,
    name: 'partners.createPartner.wholeSaleTaxId',
    fieldLabel: 'partners.createPartner.wholesaleTaxIdNumber',
  },
  {
    id: tax,
    type: tax,
    file_type: PARTNER_STATE_TAX_ID,
    name: 'partners.createPartner.salesTaxId',
    fieldLabel: 'partners.createPartner.salesTaxIdNumber',
  },
  {
    id: county,
    type: county,
    file_type: PARTNER_COUNTY_LICENSE,
    name: 'partners.createPartner.countyLicenseNumber',
    fieldLabel: 'partners.createPartner.countyLicenseNumber',
  },
  {
    id: AU_state,
    type: state,
    file_type: PARTNER_STATE_LICENSE,
    name: 'partners.createPartner.ABN',
    fieldLabel: 'partners.createPartner.ABN',
  },
  {
    id: dea_number,
    type: dea_number,
    file_type: COMMON_PRIVATE_IMAGE,
    name: 'partners.createPartner.deaNumber',
    fieldLabel: 'partners.createPartner.deaNumber',
    isSingle: true,
  },
  {
    id:AU_wholesale,
    type: wholesale,
    file_type: PARTNER_WHOLESALE_TAX_ID,
    name: 'partners.createPartner.AHPRA',
    fieldLabel: 'partners.createPartner.AHPRA',
  },
  {
    id: medical_id,
    type: medical_id,
    file_type: [COMMON_PRIVATE_IMAGE, COMMON_PRIVATE_DOCUMENT, COMMON_PRIVATE_WORD_DOCUMENT],
    name: 'customers.create.medicalMarijuanaId',
    fieldLabel: 'customers.create.medicalMarijuanaId',
  },
  {
    id: canadian_registration_form,
    type: canadian_registration_form,
    file_type: [COMMON_PRIVATE_IMAGE, COMMON_PRIVATE_DOCUMENT, COMMON_PRIVATE_WORD_DOCUMENT],
    name: 'customers.create.canadianRegistrationForm',
    fieldLabel: 'customers.create.canadianRegistrationForm',
  },
  {
    id: driver_license,
    type: driver_license,
    file_type: [COMMON_PRIVATE_IMAGE, COMMON_PRIVATE_DOCUMENT, COMMON_PRIVATE_WORD_DOCUMENT],
    name: 'customers.create.driverLicenseId',
    fieldLabel: 'partners.createPartner.driversLicense',
  },
  {
    id: intake_form,
    type: intake_form,
    file_type: [COMMON_PRIVATE_IMAGE, COMMON_PRIVATE_DOCUMENT, COMMON_PRIVATE_WORD_DOCUMENT],
    name: 'customers.create.intakeForm',
    fieldLabel: 'customers.create.documentId',
  },
  {
    id: extended_sales_limit,
    type: extended_sales_limit,
    file_type: [COMMON_PRIVATE_IMAGE, COMMON_PRIVATE_DOCUMENT, COMMON_PRIVATE_WORD_DOCUMENT],
    name: 'customers.create.extendedSalesLimit',
    fieldLabel: 'customers.create.documentId',
  },
  {
    id: temp_id,
    type: temp_id,
    file_type: [COMMON_PRIVATE_IMAGE, COMMON_PRIVATE_DOCUMENT, COMMON_PRIVATE_WORD_DOCUMENT],
    name: 'customers.create.temporaryPaperwork',
    fieldLabel: 'customers.create.physicianTemporaryId',
    isSingle: true,
  },
  {
    id: inventory_receipt_document,
    type: inventory_receipt_document,
    file_type: COMMON_PRIVATE_IMAGE,
    name: 'cultivation.transfer.form.inventoryReceiptFiles',
    fieldLabel: 'cultivation.transfer.form.name',
  },
];
