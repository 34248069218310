import React from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import {Button, Col, Row} from 'react-bootstrap';
import moment from 'moment';
import PageTitle from '../../common/PageTitle';
import TablePageWrapper from '../../common/grid/TablePageWrapper';
import * as dataNames from '../../../constants/dataNames';
import * as apiActions from '../../../actions/apiActions';
import {getOrderImportHistory} from '../../../selectors/orderSelectors';
import ImportOrdersListPageForm from './ImportOrdersListPageForm';
import statusesMapping from './StatusMapping';

export class ImportOrdersListPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.getData = this.getData.bind(this);
    this.viewDetails = this.viewDetails.bind(this);

    const columns = [
      {
        name: 'ordersImport.table.imported_by',
        dataId: 'imported_by',
        hidden: false,
      },
      {
        name: 'ordersImport.table.file',
        dataId: 'file_name',
        hidden: false,
        formatter: (cell, row) => <a target='_blank' href={row.file_link}>{cell || row.file_link}</a>
      },
      {
        name: 'ordersImport.table.status',
        dataId: 'status',
        hidden: false,
        formatter: cell => {
          return I18n.t(`ordersImport.tableFilter.statuses.${statusesMapping[cell]}`);
        },
      },
      {
        name: 'ordersImport.table.created_at',
        dataId: 'created_at',
        hidden: false,
        formatter: cell => {
          if (cell) {
            return moment(cell).format('YYYY-MM-DD hh:mm A');
          }
          return cell;
        },
      },
      {
        dataId: 'id',
        hidden: false,
        dataSort: false,
        formatter: (cell, row) => { // eslint-disable-line react/no-multi-comp
          const onClick = () => row.status === 'failed' ? window.open(row.file_with_errors_link) : this.viewDetails(cell);
          return (<Button
            onClick={onClick}
            variant='primary'
          >
            {I18n.t('ordersImport.table.view')}
          </Button>);
        },
      }
    ];

    this.state = {
      columns,
    };
  }

  componentWillMount() {
    this.getData();
  }


  getData(props = {}) {
    const newProps = {...props};

    if (newProps.created_at) {
      newProps.created_at = newProps.created_at.format('Y-M-D');
    }
    if (newProps.status === 'completed') {
      newProps.status = 'imported';
    }

    this.props.actions.getUnpaginatedData('/api/orders/import', dataNames.orderImportHistory, null, newProps);
  }

  viewDetails(id) {
    this.props.actions.push('/import/orders/view/' + id);
  }

  render() {
    const {actions: {push}} = this.props;

    return (
      <div>
        <PageTitle primaryText={I18n.t('ordersImport.pageTitle')}/>
        <ImportOrdersListPageForm
          onSubmit={this.getData}
        />
        <Row>
          <Col md={1}>
            <Button type='submit' variant='primary' onClick={() => push('/import/orders/import')}>
              {I18n.t('ordersImport.uploadNewFile')}
            </Button>
          </Col>
        </Row>
        <TablePageWrapper
          ref={this.ref}
          settingKey='import_orders'
          columns={this.state.columns}
          data={this.props.orderImportHistory}
          className='orders-import-page'
          hideExport={true}
          pageSizeList={[5, 10, 20, 50]}
        />
      </div>
    );
  }
}

ImportOrdersListPage.propTypes = {
  actions: PropTypes.shape({
    push: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
  }),
  orderImportHistory: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    orderImportHistory: getOrderImportHistory(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {push});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportOrdersListPage);
