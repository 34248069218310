import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Link} from 'react-router';
import { Col, Row, Button } from 'react-bootstrap';

import ReactSelectInput from '../../common/form/ReactSelectInput';
import InternationalCurrencyInput from '../../common/form/InternationalCurrencyInput';

const SupplierRow = (props) => {
  const {suppliers, uomsOptions, fields: {map, remove}} = props;
  return(
    <div className='supplier-row'>
      {
        map((itemName, itemIndex) => {
          return(
            <Row className='form-fields-row'  key={itemIndex}>
                <Col className='form-fields-col supplier' xs={8} md={3} lg={3}>
                  <Field
                    name={`${itemName}.partner_id`}
                    component={ReactSelectInput}
                    props={{
                      label: I18n.t('ingredients.form.supplier'),
                      options: suppliers,
                      isRequired: true,
                      textKey: 'name',
                      valueKey: 'id',
                      placeholder: I18n.t('ingredients.placeholder.choose')
                    }} />
                    {(itemIndex === 0) && (
                      <span className='add-supplier-link'>
                        <Link to={{pathname: '/partners/create', state: {goBackAfterSubmit: true}}}>
                          <Button variant='link'>{I18n.t('ingredients.actions.createSupplier')}</Button>
                        </Link>
                      </span>)}
                </Col>
                <Col className='form-fields-col' xs={8} md={2} lg={2}>
                  <Field
                    name={`${itemName}.default_uom`}
                    component={ReactSelectInput}
                    props={{
                      label: I18n.t('ingredients.form.uom'),
                      options: uomsOptions,
                      isRequired: true,
                      textKey: 'uom_code',
                      valueKey: 'uom_code',
                      placeholder: I18n.t('ingredients.placeholder.choose')
                    }} />
                </Col>
                <Col className='form-fields-col' xs={8} md={2} lg={2}>
                  <InternationalCurrencyInput
                    name={`${itemName}.default_unit_cost`}
                    props={{
                      fractionPartSize: 2,
                      label: I18n.t('ingredients.form.unitCost'),
                      isRequired: true,
                      placeholder: I18n.t('ingredients.placeholder.unitCost')
                    }} />
                </Col>
                {(itemIndex > 0) && (
                <Col className='form-fields-col' xs={2} md={2} lg={2}>
                   <FaTimes className='go-back-x' onClick={() => { remove(itemIndex); }} />
                </Col>)}
            </Row>
          );
        })
      }
    </div>

  );
};

SupplierRow.propTypes = {
  suppliers: PropTypes.array.isRequired,
  uomsOptions: PropTypes.array.isRequired,
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  })
};

export default SupplierRow;
