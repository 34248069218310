import {createSelector} from 'reselect';
import {pick} from 'lodash';
import uniqBy from 'lodash.uniqby';
import get from 'lodash.get';
import * as dataNames from '../constants/dataNames';
import * as itemNames from '../constants/itemNames';
import * as UOM from '../constants/uoms';
import {getCategories} from './categorySelectors';
import {getItemMasters} from './itemMastersSelectors';
import { convertCostByUOM, possibleToConvert, convertFromBase, convertToBase } from '../util/uomHelpers';
import {getIntegrationState} from './integration/integrationSelectors';
import {
  getApplicablePlatformSubcategoryIds,
  getAssemblyInventoryTypes
} from './integration/biotrackCategoriesSelectors';

export const getAssemblies = state => state[dataNames.assemblies];
export const getSelectedAssemblies = state => state.selectedAssemblies;
export const getAssembly = state => state[itemNames.assembly];
export const getCostings = state => state[dataNames.costings];
export const getIngredients = state => state[dataNames.ingredients];
const getDataForDuplicate = state => state[itemNames.assemblyFormData];

export const getSelectedAssemblyIds = createSelector(getSelectedAssemblies, selectedAssemblies => selectedAssemblies.map(selectedAssembly => selectedAssembly.id));

export const getAssemblyPayload = (formData, initialValues) => {
  const uomDisplay = get(formData, 'uom_display');
  return {
    ...pick(formData, [
      'name',
      'item_master_id',
      'uom',
      'potency_thc',
      'potency_thca',
      'potency_thcv',
      'potency_cbd',
      'potency_cbdv',
      'potency_cbg',
      'potency_cbc',
      'potency_cbn',
      'potency_total_cb_profile',
    ]),
    active: 1,
    uom_display: uomDisplay,
    qty_base: convertToBase(get(formData, 'qty', 0), uomDisplay),
    ingredients: (formData.ingredients || []).map(ingredient => pick(ingredient, ['id', 'qty', 'item_master_id', 'uom'])),
    directions: formData.directions.filter(direction => direction.direction),
    delete_ingredients: initialValues && initialValues.ingredients && initialValues.ingredients.filter(ing => !formData.ingredients.some(i => i.id === ing.id)).map(i => i.id)
  };
};

export const getCalculatedTotal = (ingredients) => ingredients && ingredients.reduce((prev, current) =>  {
  const currentValue = current.cost ? parseFloat(current.cost) : 0;
  return (parseFloat(prev) + currentValue).toFixed(2);
}, 0);

export const getIngredientsWithCosts = createSelector(
  [getIngredients, getCostings],
  (ingredients, costings) => {
    if (ingredients && ingredients.length && costings && costings.length) {
      costings = uniqBy(costings, 'item_master_id');
      ingredients = ingredients.map((ingredient) => {
        return {
          ...ingredient,
          cost: costings.reduce((agr, costing) => agr + (ingredient.id === costing.item_master_id && costing.average_onhand_cost && costing.average_onhand_cost.cost || 0), 0),
        };
      });
    }
    return ingredients;
  }
);

const updateCostsInIngredients = (assemblyIngredients, costings) => {
  if (assemblyIngredients && assemblyIngredients.length && costings && costings.length) {
    assemblyIngredients = assemblyIngredients.map(assemblyIngredient => {
      const costing = costings.filter(costing => costing.item_master_id === assemblyIngredient.item_master_id).pop();
      let cost;
      if (costing) {
        cost = costing.average_onhand_cost && costing.average_onhand_cost.cost || 0;

        if (cost !== 0) {
          const oldUOM = costing.average_onhand_cost.cost_uom;
          const newUOM = assemblyIngredient.uom;
          try {
            cost = convertCostByUOM(assemblyIngredient.qty, parseFloat(cost), oldUOM, newUOM);
          } catch (e) {
            cost = 0;
          }
        }
      }

      if (parseFloat(cost) % 1 === 0) {
        cost = parseFloat(cost).toFixed(2);
      } else {
        cost = parseFloat(cost).toFixed(4);
      }

      return {
        ...assemblyIngredient,
        cost,
      };
    });
  }
  return assemblyIngredients;
};

export const getManufacturingItemMasters = createSelector(
  [getItemMasters, getCategories],
  (itemMasters, categories) => {
    const plantCategoryIds = categories
      .filter(c => ['PLANTS','HARVESTS'].indexOf(c.category_code) != -1)
      .map(c => c.id);
    return (Array.isArray(itemMasters) ? itemMasters : []).filter( (master) => {
      const isEA = master.default_uom === UOM.EA;
      const isPlant = plantCategoryIds.indexOf(master.category_id) != -1;
      return isEA && !isPlant;
    });
  }
);

const getBiotrackSubcategoryIds = getApplicablePlatformSubcategoryIds(getAssemblyInventoryTypes);

export const getAssemblyOutputs = createSelector(
  [getManufacturingItemMasters, getIntegrationState, getBiotrackSubcategoryIds],
  (itemMasters) => itemMasters
);

export const getAssemblyWithCosts = createSelector(
  [getAssembly, getCostings, getManufacturingItemMasters],
  (assembly, costings, productsActiveMedicatedEA) => {
    if (assembly) {
      const assembly_positive = assembly.ingredients && assembly.ingredients.length && costings && costings.length;
      const ingredients =  assembly_positive && updateCostsInIngredients(assembly.ingredients, costings);
      const cost = assembly_positive && ingredients && getCalculatedTotal(ingredients);
      const product = assembly.item_master_id && productsActiveMedicatedEA && productsActiveMedicatedEA.length && productsActiveMedicatedEA.filter(product => product.id === assembly.item_master_id)[0];
      const product_uom = product && product.default_uom || '';
      return {
        ...assembly,
        ingredients,
        cost,
        product_uom,
      };
    }
    return assembly;
  }
);

export function addCostToIngredient(ingredient, costings) {
  const costing = costings.find(costing => ingredient.item_master_id === costing.item_master_id);
  const cost = costing && costing.average_onhand_cost && costing.average_onhand_cost.cost || 0;
  return {
    ...ingredient,
    cost,
  };
}

export const getAssembliesWithCosts = createSelector(
  [getAssemblies, getItemMasters, getCostings],

  (assemblies, itemMasters, costings) => {
    return assemblies.map((assembly) => {
      const ingredients = (assembly.ingredients || []).map(ingredient => addCostToIngredient(ingredient, costings));
      const cost = getCalculatedTotal(ingredients);
      const itemMaster = itemMasters.find(im => im.id === assembly.item_master_id);
      const productName = itemMaster && itemMaster.name || '';
      return {
        ...assembly,
        ingredients,
        cost,
        productName,
      };
    });
  }
);

export const checkIngredientsUom = createSelector(
  [getAssembly, getIngredients], (assembly, ingredients) => {
    const result = assembly.ingredients && assembly.ingredients.find(ing => {
      const itemMaster = ingredients.find(itemMasterIng => itemMasterIng.id === ing.item_master_id
        && !possibleToConvert(itemMasterIng.default_uom, ing.uom));
      return Boolean(itemMaster);
    });
    return !result;
  }
);

export const getInitialValues = createSelector([getDataForDuplicate], (duplicateData) => {
  const initialValues = {
    directions: [{}],
    ingredients: [{}],
    potency_thc: 0,
    potency_thca: 0,
    potency_thcv: 0,
    potency_cbd: 0,
    potency_cbdv: 0,
    potency_cbg: 0,
    potency_cbc: 0,
    potency_cbn: 0,
    potency_total_cb_profile: 0,
    saveAndDuplicate: false,
    qty: 0,
    uom_display: UOM.EA,
  };

  return Object.assign({}, initialValues, duplicateData);
});

export const getInitialValuesModifyForm = createSelector(
  [getAssemblyWithCosts, getIngredientsWithCosts, getDataForDuplicate],
  (assembly, ingredients, duplicateData) => {
    assembly.ingredients = assembly &&  assembly.ingredients && ingredients && assembly.ingredients.map(ing => {
      const ingredient = ingredients.find(i => i.id === ing.item_master_id);
      return {
        ...ing,
        ingredient
      };
    });
    // Get a clean quantity value in case weights are allowed at some point.
    const quantity = {
      qty: convertFromBase(get(assembly, 'qty_base'), get(assembly, 'uom_display', UOM.EA))
    };
    return Object.assign({}, assembly, duplicateData, quantity);

  }
);
