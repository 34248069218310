import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import {FaExclamationTriangle} from 'react-icons/fa';
import {Field, FieldArray} from 'redux-form';
import InternationalDecimalReadOnlyInput from '../../../common/form/InternationalDecimalReadOnlyInput';
import InternationalCurrencyInput from '../../../common/form/InternationalCurrencyInput';
import InternationalQuantityByUomInput from '../../../common/form/InternationalQuantityByUomInput';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import UnitPackages from './UnitPackages';
import ProductSubTypeDisplay from './ProductSubTypeDisplay';
import ContentConcealer from '../../../common/concealers/ContentConcealer';
import {UOM_UNIT_VALUE_PRECISION} from '../../../../constants/uoms';

const UnitLine = ({line, lineTotal, sentTotal, change, fieldName, locations, packages, isApplyStorageLocation, itemMaster,
  isEditMode, orderedQty, isLotTracked, isComplete, isCancelled, integrationState, transferId, inventoryLocked, formName,
  isMedicated, sentNetTotal, formData, itemMasterIsMedicated}) => {

  return (
  <Col xs={12}>
    <Row>
      <Col lg={4}>
        <Field name={`${fieldName}.item_master_id`} component={ReactSelectInput} props={{
          label: I18n.t('cultivation.transfers.form.item'),
          options: [itemMaster],
          textKey: 'name',
          valueKey: 'id',
          disabled: true
        }}/>
        <div>
          <ProductSubTypeDisplay fieldName={fieldName} formName={formName} />
        </div>
      </Col>
      <Col lg={2} >
        <InternationalQuantityByUomInput
          name={`${fieldName}.ordered_qty`}
          readOnly={true}
          label={I18n.t('purchaseOrders.form.unitsOrdered')}
          // TODO: uom - replace hardcoded value with itemMaster default_uom value
          uom='EA'
          fractionPartSize={UOM_UNIT_VALUE_PRECISION}
          onChange={(value) => {
            change(`${fieldName}.ordered_qty`, value);
            change(`${fieldName}.update_sales_order_line_quantity`, 1);
            change(`${fieldName}.update_sales_order_line`, 1);
          }}
          leftAddon={(
            <div style={{cursor: isComplete ? 'not-allowed' : 'pointer'}} onClick={(event) => {
              if (isComplete) return;
              const input  = event.target.parentNode.parentNode.getElementsByTagName('input');
              const newState = !input[0].readOnly;
              input[0].readOnly = newState;
              event.target.innerHTML = (!newState) ? 'Lock' : 'Edit';
            }}>
              Edit
            </div>
          )}
        />
      </Col>
      <Col lg={1} >
        <InternationalDecimalReadOnlyInput name={`${fieldName}.sent_total`}
          props={{
            readOnly:true,
            label: I18n.t('purchaseOrders.form.sentTotal'),
            value: sentTotal
          }}
        />
      </Col>
      {integrationState.isNormal &&
      <Col lg={1}>
        <InternationalDecimalReadOnlyInput name={`${fieldName}.sent_net_total`}
         props={{
           readOnly:true,
           label: I18n.t('purchaseOrders.form.sentNetTotal'),
           value: sentNetTotal
         }}
        />
      </Col>
      }
      <ContentConcealer show={!line.is_lab_partner}>
        <Col lg={2}>
          <InternationalCurrencyInput name={`${fieldName}.unit_price`}
            props={{
              label: I18n.t('purchaseOrders.form.unitPrice'),
              disabled: (isComplete || isCancelled)
            }}
          />
        </Col>
        <Col lg={2}>
          <InternationalCurrencyInput name={`${fieldName}.line_item_price`}
            props={{
              label: I18n.t('purchaseOrders.form.itemPrice'),
              disabled: true,
              value: lineTotal
            }}
          />
        </Col>
      </ContentConcealer>
    </Row>
    <Row>
      <Col lg={4}>&nbsp;</Col>
      <Col lg={8} style={{margin: '0px 0px 12px 0px'}} className={orderedQty !== sentTotal ? 'text-danger' : 'hide'}>
        <FaExclamationTriangle style={{marginRight: '8px'}} />
        {I18n.t('purchaseOrders.form.requestedAmount')} is not equal to {I18n.t('purchaseOrders.form.sentTotal')}.
        Edit <strong>{I18n.t('purchaseOrders.form.requestedAmount')}</strong> directly to update the value on your Sales Order.
      </Col>
    </Row>
    <FieldArray
      name={`${fieldName}.inventory`}
      component={UnitPackages}
      isApplyStorageLocation={isApplyStorageLocation}
      locations={locations}
      packages={packages}
      fieldName={fieldName}
      change={change}
      isEditMode={isEditMode}
      isLotTracked={isLotTracked}
      isComplete={isComplete}
      isCancelled={isCancelled}
      integrationState={integrationState}
      isMedicated={isMedicated}
      transferId={transferId}
      inventoryLocked={inventoryLocked}
      formName={formName}
      itemMaster={itemMaster}
      itemMasterIsMedicated={itemMasterIsMedicated}
      formData={formData}
    />
  </Col>);
};

UnitLine.propTypes = {
  orderedQty: PropTypes.number,
  change: PropTypes.func.isRequired,
  line: PropTypes.object.isRequired,
  sentTotal: PropTypes.number.isRequired,
  lineTotal: PropTypes.string.isRequired,
  packages: PropTypes.array,
  fieldName: PropTypes.string.isRequired,
  isApplyStorageLocation: PropTypes.bool,
  locations: PropTypes.array,
  itemMaster: PropTypes.object.isRequired,
  itemMasterIsMedicated: PropTypes.bool,
  isEditMode: PropTypes.bool,
  isMedicated: PropTypes.bool,
  isLotTracked: PropTypes.number.isRequired,
  isComplete: PropTypes.bool.isRequired,
  isCancelled: PropTypes.bool,
  inventoryLocked: PropTypes.bool,
  integrationState: PropTypes.object.isRequired,
  transferId: PropTypes.string,
  formName: PropTypes.string.isRequired,
  formData: PropTypes.object.isRequired,
  sentNetTotal: PropTypes.number.isRequired,
};

UnitLine.defaultProps = {
  isEditMode: false
};

export default UnitLine;
