import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

import RefundItems from './RefundItems';
import RefundItemsHeader from './RefundItemsHeader';
import RefundFooter from './RefundFooter';
import RestockItemsHeader from './RestockItemsHeader';
import RestockItems from './RestockItems';
import RestockFooter from './RestockFooter';

const RefundOrRestock = (props) => {
  const {isRefundContainer, integrationState, canRefundAlleaves} = props;
  return (
    <Card>
      <Card.Body>
        <table className='table table-sm' style={{width:'100%', marginBottom: '8px'}}>
          {isRefundContainer ?
            <React.Fragment>
              <RefundItemsHeader />
              <RefundItems
                refundOrder={props.refundOrder}
                onDeleteRefund={props.onDeleteRefund} />
              <RefundFooter
                onApplyClick={props.onApplyRefund}
                refundOrder={props.refundOrder}
                isRefundAndRestock={props.isRefundAndRestock}
                refundOrderInitiated={props.refundOrderInitiated}
                restockOrderInitiated={props.restockOrderInitiated}
                integrationState={integrationState}
                canRefundAlleaves={canRefundAlleaves}
              />
            </React.Fragment>
            :
            <React.Fragment>
              <RestockItemsHeader />
              <RestockItems
                onRemoveItemFromRestocking={props.onRemoveItemFromRestocking}
                queue={props.queue}
                integrationState={integrationState}
                refundOrder={props.refundOrder} />
              <RestockFooter
                onApplyRestock={props.onApplyRestock}
                queue={props.queue}
                onApplyClick={props.onApplyClick}
                refundOrderInitiated={props.refundOrderInitiated}
                restockOrderInitiated={props.restockOrderInitiated}
                integrationState={integrationState}/>
            </React.Fragment>
          }
        </table>
      </Card.Body>
    </Card>
  );

};

RefundOrRestock.propTypes = {
  onApplyRestock: PropTypes.func,
  onRemoveItemFromRestocking: PropTypes.func,
  onDeleteRefund: PropTypes.func,
  isRefundContainer: PropTypes.bool.isRequired,
  refundOrder: PropTypes.object,
  title  : PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  queue: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  onQueueClick: PropTypes.func.isRequired,
  onApplyClick: PropTypes.func,
  integrationState: PropTypes.object.isRequired,
  onApplyRefund: PropTypes.func.isRequired,
  refundOrderInitiated: PropTypes.bool.isRequired,
  restockOrderInitiated: PropTypes.bool.isRequired,
  isRefundAndRestock: PropTypes.bool,
  canRefundAlleaves: PropTypes.bool.isRequired
};

export default RefundOrRestock;
