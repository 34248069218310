import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { I18n } from 'react-redux-i18n';
import { Col, Form, Row } from 'react-bootstrap';
import { Field } from 'redux-form';
import cln from 'classnames';
import InternationalDecimalReadOnlyInput from '../../common/form/InternationalDecimalReadOnlyInput';
import InternationalDecimalInput from '../../common/form/InternationalDecimalInput';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import WillRender from '../../common/concealers/WillRender';


class SingleFieldInfo extends React.PureComponent {
  constructor(props) {
    super(props);

    this.renderStatus = this.renderStatus.bind(this);
    this.renderColumn = this.renderColumn.bind(this);
    this.renderEditableStatus = this.renderEditableStatus.bind(this);
    this.isStatusFieldDisabled = this.isStatusFieldDisabled.bind(this);
    this.renderIsolocityStatus = this.renderIsolocityStatus.bind(this);
  }

  static getStatusOptions() {
    return [
      { text: I18n.t('cultivation.testResults.failed'), value: '0' },
      { text: I18n.t('cultivation.testResults.passed'), value: '1' }
    ];
  }

  isStatusFieldDisabled() {
    const { isolocityStatus, disabled } = this.props;

    return isolocityStatus === 'passed' ? false : disabled;
  }

  renderStatus() {
    const { row, status, editable, displayCompletionStatus} = this.props;

    const selectedStatus =
      !editable && SingleFieldInfo.getStatusOptions().find((option) => row[status] === Number(option.value));
    let  statusText = (selectedStatus && selectedStatus.text) || '';

    //If the testing workflow feature toggle is enabled AND it is a lab testing facility AND the completion_status is not completed, show the words 'In Progress' instead
    if (displayCompletionStatus && get(row, 'completion_status') !== 'completed') {
      statusText = I18n.t('common.inProgress');
    }

    return (
      <div>
        <Form.Label>{I18n.t('cultivation.testResults.status')}:</Form.Label>
        <span className={row[status] === 1 ? 'test-status text-success padding-left' : 'test-status text-danger padding-left'}>
          {statusText}
        </span>
      </div>
    );
  }

  renderEditableStatus() {
    const { status } = this.props;

    return (
      <Field
        name={status}
        component={ReactSelectInput}
        props={{
          label: I18n.t('cultivation.testResults.status'),
          options: SingleFieldInfo.getStatusOptions(),
          disabled: this.isStatusFieldDisabled()
        }}
      />
    );
  }

  renderColumn(column) {
    const { row, editable, disabled, requiredDimensions } = this.props;
    const column_name = (get(column, 'display_name'))  ? get(column, 'display_name') : I18n.t(column.name);
    const uom_display = (get(column, 'display_unit')) ? get(column, 'display_unit') : I18n.t(column.unit);
    const precision = get(column, 'precision', 3);
    const isRequired = requiredDimensions && requiredDimensions.includes(column.key);

    return (
      <Col xs={6} sm={4} md={3} lg={2} className='transparent-addon-right' key={column.key}>
        {editable ? (
          <InternationalDecimalInput
            name={column.key}
            props={{
              label: column_name,
              rightAddon: uom_display,
              fractionPartSize: precision,
              allowNegativeNumber: false,
              isRequired,
              disabled
            }}
          />
        ) : (
          <div>
            <h5 className='bold'>{column_name}</h5>
            <InternationalDecimalReadOnlyInput
              name={column.key}
              rightAddon={uom_display}
              props={{
                value: row[column.key]
              }}/>
          </div>
        )}
      </Col>
    );
  }

  renderIsolocityStatus() {
    const { isIsolocity, isolocityStatus } = this.props;
    const className = cln('block', {
      'text-success': isolocityStatus === 'passed',
      'text-danger': isolocityStatus === 'failed',
      'text-muted': isolocityStatus === 'in progress'
    });

    return (
      isIsolocity && isolocityStatus && (
        <Row>
          <Col md={5}>
            <div className='readonly-input-group'>
              <label>{I18n.t('cultivation.testResults.isolocity.title')}</label>
              <div className={className}>{isolocityStatus}</div>
            </div>
          </Col>
        </Row>
      )
    );
  }

  render() {
    const { columns, editable, status, isLabApplyProfiles } = this.props;

    return (
      <Row className='layout-row layout-align-start-end layout-wrap'>
        <WillRender ifFalse={Boolean(isLabApplyProfiles)}>
          <Col xs={12}>
            {this.renderIsolocityStatus()}
            <Row>
              <Col xs={6} sm={4} md={3} lg={2}>
                {status && editable && this.renderEditableStatus()}
                {status && !editable && this.renderStatus()}
              </Col>
            </Row>
          </Col>
        </WillRender>
        {columns.map(this.renderColumn)}
      </Row>
    );
  }
}

SingleFieldInfo.propTypes = {
  row: PropTypes.object.isRequired,
  isolocityStatus: PropTypes.string,
  columns: PropTypes.array.isRequired,
  editable: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  status: PropTypes.string,
  isLabApplyProfiles: PropTypes.bool,
  displayCompletionStatus: PropTypes.bool,
};

export default SingleFieldInfo;
