import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import {FaCheck} from 'react-icons/fa';


const MmuSettings = (props) => {

  const {patientType, isSalesLimitsAppliedAcrossLoop, salesLimitsByConsumerType} = props;

  const salesLimits = salesLimitsByConsumerType.find(sl => sl.consumer_type === patientType);
  const rules = get(salesLimits, 'rules', []);

  return (
    <div className='equivalency-settings-form sales-settings-form'>
        <div style={{marginBottom: '12px'}}>
          {I18n.t('retail.salesSettings.controlledByRegulator')}
          {isSalesLimitsAppliedAcrossLoop &&
            <span>
              <br/>
              {I18n.t('retail.salesSettings.limitsAppliedAcrossLoop')}
            </span>
          }
        </div>
        <table className='table table-sm table-bordered'>
          <thead>
            <tr>
              <th style={{width:'10%'}}>{I18n.t('retail.salesSettings.enabled')}</th>
              <th style={{width:'10%'}}>{I18n.t('retail.salesSettings.mmu')}</th>
              <th style={{textAlign: 'center', width:'5%'}}>=</th>
              <th style={{width:'15%'}}>{I18n.t('retail.salesSettings.quantity')}</th>
              <th style={{width:'30%'}}>{I18n.t('retail.salesSettings.inWeightUom')}</th>
              <th style={{width:'30%'}}>{I18n.t('retail.salesSettings.ofProductType')}</th>
            </tr>
          </thead>
          <tbody>
          {rules.map((rule, index) =>
            <tr key={index}>
              <td style={{width:'10%'}}><FaCheck className='text-success' /></td>
              <td style={{width:'10%'}}>1</td>
              <td style={{textAlign: 'center', width:'5%'}}>=</td>
              <td style={{width:'15%'}}>{get(rule, 'qty', '')}</td>
              <td style={{width:'30%'}}>
                {get(rule, 'uom', '')}
                {get(rule, 'limit_using') === 'medicated_weight' && <span>&nbsp;({I18n.t('retail.salesSettings.medNetWeight')})</span>}
              </td>
              <td style={{width:'30%'}}>{get(rule, 'name', '-')}</td>
            </tr>
          )}
          </tbody>
      </table>
      <div>
        {I18n.t('retail.salesSettings.limitsReminders')}
      </div>
    </div>
  );
};

MmuSettings.propTypes = {
  patientType: PropTypes.string,
  isSalesLimitsAppliedAcrossLoop: PropTypes.bool,
  salesLimitsByConsumerType: PropTypes.array.isRequired,
};

export default MmuSettings;
