import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Spinner = ({active}) => {
  return (<div className={cx('app--spinner', {active})}/>);
};

Spinner.propTypes = {
  active: PropTypes.bool.isRequired,
};

export default Spinner;
