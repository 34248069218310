import {I18n} from 'react-redux-i18n';
import {paymentsAvailable, eqFields, eqUoms, fieldDefaults, restrictSalesTimePeriods, metrcOhEqFields} from '../constants/salesSettings';
import {salesComplianceSettings} from '../constants/itemNames';

const salesSettings = function(settings){
  //this.consumerId = (consumerId !== undefined) ? consumerId : false;
  //this.consumerUrl = false;
  this.settingsUrl = '/api/order_settings/sales_limit';
  this.store = salesComplianceSettings;
  this.config = {
    paymentsAvailable: paymentsAvailable,
    eqFields: eqFields,
    metrcOhEqFields: metrcOhEqFields,
    eqUoms: eqUoms,
    restrictSalesTimePeriods: restrictSalesTimePeriods
  };
  this.defaults = fieldDefaults,
  this.keyMap = {
    autoPrice: 'order_auto_price_allow',
    autoPriceOnlyPricingGroups: 'order_autoprice_limit_to_pricing_groups',
    autoPriceOnlyPricingWeights: 'order_autoprice_limit_pricing_weights',
    eqSettings: 'order_sales_limit_equivalency',
    restrictSales: 'order_restrict_sales',
    allowTempPaperwork: 'order_allow_sales_with_temp_paperwork',
    doctorRequiredForTempPaperwork: 'order_physician_info',
    deliverProduct: 'order_delivery_of_product',
    uploadDocs: 'order_upload_of_docs',
    paymentOptions: 'order_select_payment_options',
    autoExpired: 'order_auto_inactivation',
    gracePeriodForAutoExpired: 'order_buffer',
  };
  this.settings = false;
  this.setLanguage();
  if(settings !== undefined){
    this.settings = settings;
  }
};

salesSettings.prototype.setLanguage = function(){
  const mapTextFor = ['eqFields', 'eqUoms', 'paymentsAvailable', 'restrictSalesTimePeriods'];
  const prefixes = {
    eqFields: 'retail.salesSettings',
    eqUoms: 'retail.salesSettings',
    restrictSalesTimePeriods: 'retail.restrictSalesTimePeriods',
    paymentsAvailable: 'retail.paymentTypes'
  };
  mapTextFor.forEach((property) => {
    const keyField = (property === 'eqFields') ? 'category_code' : 'value';
    const prefix  = prefixes[property];
    this.config[property].map((field) => {
      field.text = I18n.t(`${prefix}.${field[keyField]}`);
      return field;
    });
  });
};

salesSettings.prototype.getSettings = function(getItem, getOverridableInConfigpack = false){
  return getItem(this.settingsUrl, this.store, {failed: 'retail.salesComplianceSettings.failed'}, {get_overridable_in_configpack: getOverridableInConfigpack}, (data) => {
    this.settings = data;
  });
};

salesSettings.prototype.setSettings = function(settings){
  if(settings === undefined) return this;
  this.settings = settings;
  return this;
};

salesSettings.prototype.getDefault = function(key, subkey){

  let setting;

  if(key === 'eqSettings' && subkey !== undefined){
    if(this.defaults[this.keyMap[key]] === undefined) return false;
    if(!Array.isArray(this.defaults[this.keyMap[key]])) return false;
    if(subkey !== undefined){
      setting = this.defaults[this.keyMap[key]].find((setting) => setting.category_code === subkey);
      if(setting === undefined) return false;
    }
    return this.defaults[this.keyMap[key]];
  }

  if(key === 'paymentOptions'){
    if(this.defaults[this.keyMap[key]] === undefined) return false;
    if(!Array.isArray(this.defaults[this.keyMap[key]])) return false;
    return this.defaults[this.keyMap[key]].reduce((acc, payment) => {
      if(payment.isDefault) acc.push(payment.value);
      return acc;
    },[]);
  }

  if(this.defaults[this.keyMap[key]] === undefined) return false;
  setting = this.defaults[this.keyMap[key]];
  if(subkey !== undefined && setting[subkey] !== undefined) return setting[subkey];
  return setting;

};

salesSettings.prototype.getSetting = function(key, subkey){

  if(this.keyMap[key] === undefined) return 0;

  let setting;

  if(!this.settings) return this.getDefault(key, subkey);

  if(key === 'eqSettings' && subkey !== undefined){
    if(this.settings[this.keyMap[key]] === undefined) return this.getDefault(key, subkey);
    if(!Array.isArray(this.settings[this.keyMap[key]].value)) return this.getDefault(key, subkey);
    setting = this.settings[this.keyMap[key]].value.find((setting) => setting.category_code === subkey);
    if(setting === undefined) return this.getDefault(key, subkey);
  }

  if(key === 'paymentOptions'){
    if(this.settings[this.keyMap[key]] === undefined) return this.getDefault(key);
    if(!Array.isArray(this.settings[this.keyMap[key]].value.options)) return this.getDefault(key);
    return this.settings[this.keyMap[key]].value.options;
  }

  if(this.settings[this.keyMap[key]] === undefined) return this.getDefault(key);
  setting = this.settings[this.keyMap[key]];
  if(Object.keys(setting).length === 1) return setting.value;
  if(subkey !== undefined && setting[subkey] !== undefined) return setting[subkey];
  return setting;

};

// Stub
salesSettings.prototype.getConsumer = function(consumerId){
  if(consumerId === undefined) consumerId = this.consumerId;
  if(!consumerId) return false;
  return false;
};



export default function getSalesSettings(settings){
  return new salesSettings(settings);
}
