import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'redux-form';
import ItemRows from './ItemRows';
import ProductRow from './ProductRow';
import AddItemRow from './AddItemRow';
import CouponsRow from './CouponsRow';
import DeliveryMethod from './DeliveryMethod';

const Product = ({
  product,
  items,
  inventoryItems,
  handleRemoveItem,
  pricingWeights,
  prepackWeights,
  fields,
  handleRemoveProduct,
  isFulfilled,
  change,
  itemMaster,
  order,
  coupons,
  totalQuantity,
  salesLocations,
  overrideLocation,
  currentLocation,
  fieldNamePrefix,
  isCure,
  formOptions,
  isProductMedicated,
  disablingAddInventory,
  isOhMetrc,
  blockExpiredProductAction
}) => {
  const hasCoupons = !!(coupons && coupons.length);
  return (
    <tbody>
      <ProductRow
        pricingWeights={pricingWeights}
        handleRemoveProduct={handleRemoveProduct}
        product={product}
        isFulfilled={isFulfilled}
        itemMaster={itemMaster}
        hasCoupons={hasCoupons}
        totalQuantity={totalQuantity}
        fieldNamePrefix={fieldNamePrefix}
        change={change}
        disablingAddInventory={disablingAddInventory}
        isOhMetrc={isOhMetrc}
        order={order}
      />
      <FieldArray
        name={`${fields}.items`}
        component={ItemRows}
        items={items}
        prepackWeights={prepackWeights}
        handleRemoveItem={handleRemoveItem}
        itemMaster={itemMaster}
        blockExpiredProductAction={blockExpiredProductAction}
      />
      <AddItemRow
        change={change}
        fieldName={`${fields}.addItem`}
        prepackWeights={prepackWeights}
        product={product}
        inventoryItems={inventoryItems}
        disablingAddInventory={disablingAddInventory}
        itemMaster={itemMaster}
        salesLocations={salesLocations}
        overrideLocation={overrideLocation}
        currentLocation={currentLocation}
      />
      <DeliveryMethod
        isProductMedicated={isProductMedicated}
        formOptions={formOptions}
        isCure={isCure}
        fieldNamePrefix={fieldNamePrefix}
      />
      {hasCoupons ? <CouponsRow coupons={coupons} product={product} /> : null}
    </tbody>
  );
};
Product.propTypes = {
  index: PropTypes.number.isRequired,
  fields: PropTypes.string.isRequired,
  order: PropTypes.object,
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    unit_price: PropTypes.string,
    product_type: PropTypes.string,
    sold_weight_uom_display: PropTypes.string,
    quantity: PropTypes.number.isRequired,
    uom: PropTypes.number.isRequired,
    pricing_weight_id: PropTypes.number,
    items: PropTypes.array,
    item_master_id: PropTypes.number.isRequired,
    src: PropTypes.string
  }),
  pricingWeights: PropTypes.array.isRequired,
  prepackWeights: PropTypes.array.isRequired,
  inventoryItems: PropTypes.array.isRequired,
  coupons: PropTypes.array.isRequired,
  handleRemoveItem: PropTypes.func.isRequired,
  handleRemoveProduct: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  isFulfilled: PropTypes.bool,
  itemMaster: PropTypes.object.isRequired,
  totalQuantity: PropTypes.number.isRequired,
  salesLocations: PropTypes.array,
  overrideLocation: PropTypes.number,
  currentLocation: PropTypes.number,
  fieldNamePrefix: PropTypes.string.isRequired,
  isCure: PropTypes.bool,
  formOptions: PropTypes.array,
  isProductMedicated: PropTypes.bool,
  disablingAddInventory: PropTypes.bool,
  isOhMetrc: PropTypes.bool,
  blockExpiredProductAction: PropTypes.bool,
  items:PropTypes.array
};

export default Product;
