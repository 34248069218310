import * as types from '../constants/actionTypes';
import initialState from '../constants/initialState';

export default function apiRequestReducer (state = initialState.pagination, action){
  const {type, pagination} = action;
  switch (type) {
  case types.GET_PAGINATION_SUCCESS:
    return Object.assign({}, state, pagination);
  case types.GET_PAGINATION_FAILED:
  default:
    return state;
  }
}
