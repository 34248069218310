import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';

import {COMPLETE_INFUSION_FORM} from '../../../../constants/forms';
import CompleteInfusionForm from './CompleteInfusionForm'; //eslint-disable-line
import validate from './validate';

const CompleteInfusionFormWrapper = reduxForm({
  form: COMPLETE_INFUSION_FORM,
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(CompleteInfusionForm);

CompleteInfusionFormWrapper.propTypes = {
  form: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  phases: PropTypes.array.isRequired,
  productionRuns: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
  trackingIds: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  employees: PropTypes.array.isRequired,
  equipment: PropTypes.array.isRequired,
  ingredients: PropTypes.array.isRequired,
  ingredientOptions: PropTypes.object.isRequired,
  ingredientsCost: PropTypes.number,
  isManufacturing: PropTypes.bool,
  ingredientsStandardCost: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  status: PropTypes.string,
  outputItemIds: PropTypes.array.isRequired,
  showPrinter: PropTypes.func.isRequired,
};

export default CompleteInfusionFormWrapper;
