import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import {FaCheckSquare, FaRegSquare} from 'react-icons/fa';
import InternationalDecimalInput from '../../../../../common/form/InternationalDecimalInput';
import TextInput from '../../../../../common/form/TextInput';

const RewardPointsAsDollars  = (props) => {
  const currentValue = props.formSelector('reward_one_point_is_worth__override_enabled');

  const onChange = (enable) => {
    props.change('reward_one_point_is_worth__override_enabled', enable);
  };

  return (
    <div className='input-group'>
      {
        !currentValue
          ? <Field
              name=''
              component={TextInput}
              props={{
                placeholder: I18n.t('common.disabled'),
                rightAddon: <span><FaRegSquare aria-hidden='true' onClick={() => onChange(true)}/></span>,
                disabled: true
              }}
            />
          : <InternationalDecimalInput name='reward_one_point_is_worth__override_value'
              fractionPartSize={2}
              props={{
                allowNegativeNumber: false,
                step: 0.01,
                className: 'form-control',
                rightAddon: <span><FaCheckSquare style={{color: '#0075ff'}} aria-hidden='true' onClick={() => onChange(false)}/></span>
              }}
            />
      }
    </div>
  );

};

RewardPointsAsDollars.propTypes = {
  formSelector: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
};

export default RewardPointsAsDollars;

