/* eslint-disable no-case-declarations */
import {createSelector} from 'reselect';
import orderBy from 'lodash.orderby';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import keyBy from 'lodash.keyby';
import * as dataNames from '../constants/dataNames';
import * as itemTransactionTypes from '../constants/itemTransactionTypes';
import {convertDbDateTimeToFormInputDateTimeWithSeconds, convertDbDateToFormInputDate} from '../util/dateHelpers';
import {getPackageEvents, getItemEvents, getLabResultEvents} from './packageEventsSelectors';
import {getTimezone} from './timezoneSelectors';
import {getPhases} from './phasesSelectors';
import {getInventoryItem} from './inventoryItemsSelectors';
import {displayQtyAndUomCurried} from './uomsSelectors';
import {convertFromBase} from '../util/uomHelpers';

export const getItemTransactions = state => state[dataNames.itemTransactions];
export const getWaste = state => keyBy(state[dataNames.waste], 'id');
export const getSections = state => keyBy(state[dataNames.sections], 'id');
const getItemId = (_, props) => props && props.params && Number(props.params.id);

const measureBaseConvert = (qtyBase, uomConvert, displayFunc) => {
  return displayFunc(convertFromBase(qtyBase, uomConvert), uomConvert);
};

const getItemTransactionsByItem = createSelector(
  [getItemTransactions, getItemId],
  (transactions, item_id) => transactions.filter(t => t.item_id === item_id)
);

const getWasteSource = (event, waste, sections) => {
  const leveWasteSourceId = get(event, 'model.package.lot.source_id');
  const wasteLocationId = get(waste, leveWasteSourceId + '.sources.0.source_id');
  return get(sections, wasteLocationId + '.name');
};

export const getPackageHistoryData = createSelector(
  [getItemTransactions, getPackageEvents, getTimezone, getWaste, getSections, displayQtyAndUomCurried],
  getHistoryData
);

export const getItemHistoryData = createSelector(
  [getItemTransactionsByItem, getItemEvents, getTimezone, getWaste, getSections, displayQtyAndUomCurried],
  getHistoryData
);

export const getPackageHistoryWithDelta = createSelector(
  [getPackageHistoryData, getPhases],
  getHistoryWithDelta
);

export const getItemHistoryWithDelta = createSelector(
  [getItemHistoryData, getPhases],
  getHistoryWithDelta
);

export const getPackageHistoryWithLabResults = createSelector(
  [getPackageHistoryWithDelta, getPackageEvents, getInventoryItem],
  addLabResultEvents
);

function getLevelBefore(event, transaction, displayFunc) {
  if (transaction.transaction_type === itemTransactionTypes.newItemMaster) {
    const level_before = get(event, 'model.transaction.source_item.qty_base', transaction.level_before);
    const uom = get(event, 'model.transaction.source_item.uom', transaction.transacted_uom);
    return measureBaseConvert(level_before, uom, displayFunc);
  } else {
    return measureBaseConvert(transaction.level_before, transaction.transacted_uom, displayFunc);
  }
}

function getLevelAfter(event, transaction, displayFunc) {
  const uom = get(transaction, 'transacted_uom', get(event, 'model.item_master.default_uom'));
  return measureBaseConvert(transaction.level_after, uom, displayFunc);
}

function getHistoryData(transactions, events, timezone, waste, sections, displayFunc) {
  const orderedEvents = orderBy(events, 'id', 'desc');
  return transactions.map((transaction) => {
    //First of all, we need to check if transaction event exists
    const event = orderedEvents.find(event => event.model && event.model.transaction && event.model.transaction.id === transaction.id);
    const wasteSource = getWasteSource(event, waste, sections);
    const level_before = getLevelBefore(event, transaction, displayFunc);
    const level_after = getLevelAfter(event, transaction, displayFunc);
    const {package_level_before, package_level_after} = calculatePackageLevels(transaction, event, displayFunc);
    const event_date = convertDbDateTimeToFormInputDateTimeWithSeconds(transaction.event_date, timezone);
    const created_at = convertDbDateToFormInputDate(transaction.created_at, timezone);
    const id  = event && event.id || `t_${transaction.id}`;

    return {
      id,
      transaction_id: transaction.id,
      item_id: transaction.item_id,
      transaction_type: transaction.transaction_type,
      message: formatMessage(transaction),
      user_name: event && event.user_name || transaction.user_name,
      created_at,
      event_date,
      created_at_sort: `${transaction.created_at} ${id}`,
      event_date_sort: `${transaction.event_date} ${id}`,
      level_before,
      level_before_sort: level_before + id,
      level_after,
      level_after_sort: level_after + id,
      package_level_before,
      package_level_before_sort: package_level_before + id,
      package_level_after,
      package_level_after_sort: package_level_after + id,
      notes: transaction.notes,
      model: event && {...event.model, wasteSource},
      reference_id: event && event.reference_id || transaction.reference_id,
    };
  });
}

function calculatePackageLevels(transaction, event, displayFunc) {
  switch (transaction.transaction_type) {
  case itemTransactionTypes.split:
  case itemTransactionTypes.merge:
    return {package_level_before: measureBaseConvert(transaction.level_before, transaction.transacted_uom, displayFunc), package_level_after: measureBaseConvert(transaction.level_after, transaction.transacted_uom, displayFunc)};
  case itemTransactionTypes.newItemMaster:
    return {
      package_level_before: getLevelBefore(event, transaction, displayFunc),
      package_level_after: getLevelAfter(event, transaction, displayFunc)
    };
  default:
    const package_level_before = transaction.package_level_before ? measureBaseConvert(transaction.package_level_before, transaction.transacted_uom, displayFunc) : '-';
    const package_level_after = transaction.package_level_after ? measureBaseConvert(transaction.package_level_after, transaction.transacted_uom, displayFunc) : '-';
    return {package_level_before, package_level_after};
  }
}

function formatMessage(transaction) {
  switch (transaction.transaction_type) {
  case itemTransactionTypes.create:
    return I18n.t('itemTransactions.types.create');
  case itemTransactionTypes.manual:
    return I18n.t('itemTransactions.types.manual');
  case itemTransactionTypes.merge:
    return I18n.t('itemTransactions.types.merge');
  case itemTransactionTypes.split:
    return I18n.t('itemTransactions.types.quickMove');
  case itemTransactionTypes.mfgProduction:
    return I18n.t('itemTransactions.types.mfgProduction');
  case itemTransactionTypes.newItemMaster:
    return I18n.t('itemTransactions.types.newItemMaster');
  case itemTransactionTypes.newLevel:
    return I18n.t('itemTransactions.types.newLevel');
  case itemTransactionTypes.none:
    return I18n.t('itemTransactions.types.none');
  case itemTransactionTypes.packageSplit:
    return I18n.t('itemTransactions.types.packageSplit');
  case itemTransactionTypes.packaging || itemTransactionTypes.packagingEach:
    return I18n.t('itemTransactions.types.packaging');
  case itemTransactionTypes.receipt:
    return I18n.t('itemTransactions.types.receipt');
  case itemTransactionTypes.reconcile:
    return I18n.t('itemTransactions.types.reconcile');
  case itemTransactionTypes.restock:
    return I18n.t('itemTransactions.types.restock');
  case itemTransactionTypes.sale:
    return I18n.t('itemTransactions.types.sale');
  case itemTransactionTypes.transfer:
    return I18n.t('itemTransactions.types.transfer');
  case itemTransactionTypes.feedingSchedule:
    return I18n.t('itemTransactions.types.feedingSchedule');
  case itemTransactionTypes.cancelTransfer:
    return I18n.t('itemTransactions.types.transferCancelled');
  case itemTransactionTypes.sharedItemClone:
    return I18n.t('itemTransactions.types.sharedItemClone');
  case itemTransactionTypes.sharedItemTransactionReplication:
    return I18n.t('itemTransactions.types.sharedItemTransactionReplication');
  case 'LAB_RESULT_ASSIGNED':
    return I18n.t('itemTransactions.types.labResultAssigned');
  case 'LAB_RESULT_UNASSIGNED':
    return I18n.t('itemTransactions.types.labResultUnassigned');
  case 'BIOTRACK_PLANT_CREATE':
    return I18n.t('itemTransactions.types.plantCreated');
  case itemTransactionTypes.tagUpdate:
    return transaction.message;
  default:
    return 'To be done…';
  }
}

function getHistoryWithDelta(history, phases) {
  return history.reduce(
    (acc, transaction) => {
      if (acc.itemModels[transaction.item_id] && transaction.model) {
        //We are trying here to understand delta between the previous transaction model and the current transaction model
        acc.history.push({
          ...transaction,
          delta: calculateItemDelta(acc.itemModels[transaction.item_id], transaction.model, {phases}),
        });
      } else {
        acc.history.push(transaction);
      }
      acc.itemModels[transaction.item_id] = {
        ...acc.itemModels[transaction.item_id],
        ...transaction.model,
      };
      return acc;
    },
    {history: [], itemModels: {}}
  ).history;
}

const deltaConfig = [
  {name: 'location_name'},
  {name: 'container_id'},
  {name: 'parent_item_id'},
  {name: 'active', get: model => model.active ? 'Yes' : 'No'},
  {name: 'is_reserved', get: model => model.is_reserved ? 'Yes' : 'No'},
  {name: 'on_hold', get: model => model.package && model.package.on_hold ? 'Yes' : 'No'},
  {name: 'tracking_id', get: model => model.package && model.package.state_integration_tracking_id},
  {name: 'qty', get: model => `${model.qty} ${model.uom}`},
  {
    name: 'phase',
    get: (model, {phases}) => {
      if (model.phase) {
        return model.phase.name;
      }
      const phase = phases.find(phase => phase.id === model.phase_id);
      return phase && phase.name || 'Pre-Run';
    },
    compare: (prev, current) => current !== 'Unknown' && prev !== current,
  },
  {name: 'packagedAt', get: model => model && model.packaged_at},
  {name: 'packageCreatedAt', get: model => model && model.package_created_at},
  {name: 'packageExpiresAt', get: model => model && model.package_expires_at},
  {name: 'purpose', get: model => model && model.purpose_name},
  {name: 'item_master_id', get: model => model && model.item_master_id},
  {name: 'item_master_name', get: (model) => get(model, 'transaction.item_master_name') || get(model, 'item_name')},
];

function getValue(model, fieldConfig, extraData = {}) {
  if (typeof fieldConfig.get === 'function') {
    return fieldConfig.get(model, extraData) || 'Unknown';
  }
  return model[fieldConfig.name] || 'Unknown';
}

function compareValues(prev, current, fieldConfig) {
  if (typeof fieldConfig.compare === 'function') {
    return fieldConfig.compare(prev, current);
  }
  return prev !== current;
}

function calculateItemDelta(prevState, currentState, extraData = {}) {
  return deltaConfig.reduce(
    (acc, fieldConfig) => {
      const prevValue = getValue(prevState, fieldConfig, extraData);
      const currentValue = getValue(currentState, fieldConfig, extraData);
      if (compareValues(prevValue, currentValue, fieldConfig)) {
        acc.push({
          name: fieldConfig.name,
          prev: prevValue,
          current: currentValue,
        });
      }
      return acc;
    },
    []
  );
}

function addLabResultEvents(history, packageEvents, item) {
  const labEvents = getLabResultEvents({events: packageEvents, package_id: item.package_id});
  const merged = history.concat(labEvents.map(event => ({
    ...event,
    item_id: item.id,
    message: formatMessage({...event, transaction_type: event.event_type}),
    transaction_type: event.event_type,
    created_at: event.created_at_formatted,
    event_date: event.event_date_formatted,
  })));
  const eventTypes = ['LAB_RESULT_ASSIGNED', 'LAB_RESULT_UNASSIGNED'];

  return orderBy(merged, 'event_date_sort', 'asc').reduce(
    (acc, item, index) => {
      const prev = acc[index - 1];
      if (eventTypes.indexOf(item.transaction_type) > -1) {
        acc.push({
          ...item,
          level_before: prev && prev.level_after || '-',
          level_before_sort: `${prev && prev.level_after || '-'} ${item.id}`,
          level_after: prev && prev.level_after || '-',
          level_after_sort: `${prev && prev.package_level_after || '-'} ${item.id}`,
          package_level_before: prev && prev.package_level_after || '-',
          package_level_before_sort: `${prev && prev.package_level_after || '-'} ${item.id}`,
          package_level_after: prev && prev.package_level_after || '-',
          package_level_after_sort: `${prev && prev.package_level_after || '-'} ${item.id}`,
        });
      } else {
        acc.push(item);
      }

      return acc;
    },
    []
  );
}
