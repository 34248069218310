import React from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {goBack} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import ImportOrdersFormWrapper from './ImportOrdersFormWrapper';
import {getImportOrdersPageProps, getImportOrdersPagePayload} from '../../../selectors/forms/importOrdersFormSelector';
import FormWrapper from '../../common/form/FormWrapper';
import InProgressOverlay from '../../common/InProgressOverlay';
import {postData} from '../../../actions/apiActions';
import statusesMapping from './StatusMapping';

export class ImportOrdersPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      submitting: false,
      completed: false,
      result: {},
    };
  }

  onSubmit(form) {
    const payload = getImportOrdersPagePayload(form);

    this.setState({submitting: true, result: {}});

    return this.props.actions
      .postData(
        '/api/orders/import',
        payload,
        'noOp',
        {success: 'common.success'}
      )
      .then((r) => this.setState({submitting: false, result: r}))
      .catch((r) => this.setState({submitting: false, result: r}));
  }

  render() {
    const {registers, initialValues} = this.props;
    const {submitting, result} = this.state;
    const uploadingMessage = `common.form.${submitting ? 'saving' : 'loadingData'}`;

    const messageClass = 'alert alert-' + (result.success === true ? 'success' : 'danger');
    const status = result.status ? I18n.t('ordersImport.statusOfImportedFile') + ':' + I18n.t(`ordersImport.tableFilter.statuses.${statusesMapping[result.status]}`) : '';
    const messages = result.success !== undefined ? <div className={messageClass}>{result.messages} {status}</div> : '';

    return (
      <FormWrapper title='ordersImport.pageTitle' goBack={this.props.actions.goBack}>
        <InProgressOverlay isActive={submitting} translate={true} message={uploadingMessage}/>
        {messages}

        <ImportOrdersFormWrapper
          onSubmit={this.onSubmit}
          initialValues={initialValues}
          registers={registers}
        />
      </FormWrapper>
    );
  }
}

ImportOrdersPage.propTypes = {
  actions: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
  }),
  initialValues: PropTypes.object.isRequired,
  registers: PropTypes.array.isRequired,
};

function mapDispatchToProps(dispatch) {
  const actions = {goBack, postData};

  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(getImportOrdersPageProps, mapDispatchToProps)(ImportOrdersPage);
