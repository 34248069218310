/* eslint-disable import/prefer-default-export*/
import {createSelector} from 'reselect';
import {I18n} from 'react-redux-i18n';
import find from 'lodash.find';
import get from 'lodash.get';
import * as dataNames from '../constants/dataNames';
import registerTransactionTypes from '../constants/registerReasons';
import getSalesCompliance from './compliance/sales/getSalesCompliance';

export const getRegisters = state => state[dataNames.registers];
export const getSalesRegisters = state => state[dataNames.salesRegisters];

export const getAvailableRegisters = createSelector([getRegisters], registers => registers.filter(
  register => register.active && !register.is_closed
));

const getId = (_, props) => props.id;

export const getRegisterById = createSelector(
  getId, getRegisters,
  (id, registers) => find(registers, ['id', id])
);

export const getRegisterListingData = createSelector(
  [getRegisters],
  registers => {
    const registerTypes = {
      sales: I18n.t('registers.table.salesTransactions'),
      vault: I18n.t('registers.table.vault'),
      ap_ar: I18n.t('registers.table.accountsPayableReceivable'),
    };

    return registers.map(register => ({
      ...register,
      type: registerTypes[register.type],
      collectsFrom: register.collects_from ? register.collects_from.map(r => r.collects_from_register.name).join(', ') : '',
    }));
  }
);

export const getActiveSalesRegisters = createSelector([getSalesRegisters],(registers) =>
  registers.filter(register => register.active && !register.is_closed && register.type === 'sales')
);

export const getDefaultSalesRegister = createSelector([getActiveSalesRegisters], (registers) =>
  registers.find(register => register.is_default_for_pos)
);

export const getDefaultRegister = (allRegisters, currentRegister) =>
  allRegisters.find(reg => reg.type === 'sales' && reg.is_default_for_pos && currentRegister.id !== reg.id && reg.active);

/**
 * This determines specifically whether Register Closing is allowed from within the Reporting area of Platform,
 * and NOT from within Facility Settings -> Registers -> Modify Register or Transfer Cash
 *
 * @type boolean
 */
export const getRegisterClosingIsAllowed = createSelector(
  [getSalesCompliance],
  (salesComplianceSettings) =>
    Boolean(get(salesComplianceSettings, 'register_closing.value.is_allowed'))
);

/**
 * This selector filters out the CLOSING transaction type when Register Closing is allowed. While this seems
 * backwards, what this is doing is disallowing CLOSING from happening on the Modify Register and Transfer Cash
 * pages when the setting is turned on, as we need the user to perform the Closing from within Reporting
 * (where Registers can also be Opened, Counted, Verified, etc)
 *
 * @type array
 */
export const getSelectableRegisterTransactionTypes = createSelector(
  [getRegisterClosingIsAllowed],
  (registerClosingIsAllowed) => {
    const disallowedTransactionTypes = ['OPENING'];

    // You may be wondering why we're filtering CLOSING if it's allowed. See function description (above)
    if (registerClosingIsAllowed) disallowedTransactionTypes.push('CLOSING');

    return registerTransactionTypes.filter(transactionType =>
      !disallowedTransactionTypes.includes(transactionType.value));
  }
);
