import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack} from 'react-router-redux';

import FormWrapper from '../../common/form/FormWrapper';
import InfoModForm from './InfoModForm';

export class ProductInfoModificationPage extends React.PureComponent{
  constructor(props, context){
    super(props, context);

    this.onSubmit = this.onSubmit.bind(this);
    this.onReset = this.onReset.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  onSubmit(data) {
    this.redirect();
  }

  onReset(){
  }

  redirect(){
    this.props.actions.goBack();
  }

  render(){
    return(
      <FormWrapper className='product-info-mod' title={'products.infoMod.title'} goBack={this.redirect} >
        <InfoModForm
          onSubmit={this.onSubmit}
          onReset={this.onReset}/>
      </FormWrapper>
    );
  }
}

ProductInfoModificationPage.propTypes = {
  actions: PropTypes.shape({
    goBack: PropTypes.func.isRequired
  })
};

function mapStateToProps(state) {
  return {
    state
  };
}

function mapDispatchToProps (dispatch) {
  const actions = {goBack};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductInfoModificationPage);
