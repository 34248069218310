import {requiredFieldValidation, urlValidation} from '../common/form/redux-form/validations';

export default function validate(values) {
  const {payment_credit_card_processor, payment_cc_authnet_settings, payment_cc_nmi_settings, payment_cc_moneris_settings} = values;
  const errors = {
    payment_credit_card_processor: {
      value: requiredFieldValidation(payment_credit_card_processor && payment_credit_card_processor.value),
    },
  };

  if (payment_credit_card_processor) {
    if (payment_credit_card_processor.value === 'authnet' && payment_cc_authnet_settings && payment_cc_authnet_settings.value) {
      errors.payment_cc_authnet_settings = {
        value: {
          api_login: requiredFieldValidation(payment_cc_authnet_settings.value.api_login),
          transaction_key: requiredFieldValidation(payment_cc_authnet_settings.value.transaction_key),
        },
      };
    }

    if (payment_credit_card_processor.value === 'nmi' && payment_cc_nmi_settings && payment_cc_nmi_settings.value) {
      errors.payment_cc_nmi_settings = {
        value: {
          url: requiredFieldValidation(payment_cc_nmi_settings.value.url) || urlValidation(payment_cc_nmi_settings.value.url),
          username: requiredFieldValidation(payment_cc_nmi_settings.value.username),
          password: requiredFieldValidation(payment_cc_nmi_settings.value.password),
        },
      };
    }

    if (payment_credit_card_processor.value === 'moneris' && payment_cc_moneris_settings && payment_cc_moneris_settings.value) {
      errors.payment_cc_moneris_settings = {
        value: {
          store_id: requiredFieldValidation(payment_cc_moneris_settings.value.store_id),
          api_token: requiredFieldValidation(payment_cc_moneris_settings.value.api_token)
        },
      };
    }
  }

  return errors;
}
