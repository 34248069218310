import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import {Card} from 'react-bootstrap';
import get from 'lodash.get';
import * as p from '../../constants/permissions';
import ModifyItems from './ModifyItems'; // eslint-disable-line import/no-named-as-default
import PermissionWrapper from '../common/PermissionWrapper';
import IndeterminateCheckbox from '../common/form/IndeterminateCheckbox';
import MetrcConcealer from '../common/concealers/MetrcConcealer';
import ContentConcealer from '../common/concealers/ContentConcealer';

const QuickMovePanel = ({
  row,
  onSubmit,
  locations,
  sharedLocations,
  printLabel,
  active,
  showSelect,
  handleSelect,
  selectedProductIds,
  showProductionRunsModal,
  isManufacturing,
  isIngredient,
  onIngredientAdjust,
  integrationState,
  quickMoveDisabled,
  activeTab,
  partners,
  fetchChildrenAndLabResults,
  displayInactivationReason,
  userHasEditPermission,
  showReservationsModal,
  hasPackagesTags,
  canUserManageReservationAndIsAllowedInComplianceSettings,
  allowPackageLocationSplit,
  showModality,
  harvests,
  showCupo,
  cupos,
  isMetrcStemHoldingsToggled,
  useEntityLocks,
}) => {
  const ids = row.children ? row.children.map((child) => child.id) : [];
  const checkedIds = ids.filter((id) => selectedProductIds.indexOf(id) > -1);
  const checked = ids.length === checkedIds.length;
  const indeterminate = checkedIds.length > 0 && checkedIds.length < ids.length;
  const showTrackingId = hasPackagesTags === true ? true : !isIngredient && integrationState.hasIntegrator;

  const reasonIsDisplayed = !active && !isIngredient && displayInactivationReason;
  const lotIsLocked = useEntityLocks && row.is_locked;

  return (
    <div className='quick-move-form-div'>
      <Card>
        <Card.Header>
          <div className='layout-row layout-align-start-start flex-header'>

            {showSelect && (
              <div className='flex-col checkbox-col'>
                <ContentConcealer show={userHasEditPermission}>
                  <IndeterminateCheckbox
                    checked={checked}
                    disabled={lotIsLocked}
                    indeterminate={indeterminate}
                    onChange={() => {
                      checked
                        ? handleSelect('remove', row.children)
                        : handleSelect('add', row.children.filter((child) => checkedIds.indexOf(child.id) === -1));
                    }}
                  />
                </ContentConcealer>
              </div>
            )}

            {!isIngredient ? (
              <div className='flex-col flex'>{I18n.t('cultivation.finishedProduct.form.unit')}</div>
            ) : null}

            {hasPackagesTags ? (
              <div className='flex-col flex'>{I18n.t('cultivation.finishedProduct.form.soloTag')}</div>
            ) : null}

            {!hasPackagesTags && showTrackingId ? (
              <div className='flex-col flex'>{I18n.t('common.form.trackingId')}</div>
            ) : null}

            {showCupo && <div className='flex-col xs-col'>{I18n.t('cupos.cupo')}</div>}

            {showModality && <div className='flex-col xs-col'>{I18n.t('plants.form.modality')}</div>}

            {!isIngredient ? (
              <PermissionWrapper permissions={[p.rate_products]}>
                <div className='flex-col xs-col'>{I18n.t('cultivation.finishedProduct.form.qualityRating')}</div>
              </PermissionWrapper>
            ) : null}

            {!isIngredient ? (
              <div className='flex-col xs-col'>{I18n.t('cultivation.finishedProduct.form.packageSize')}</div>
            ) : null}

            <div className={`flex-col ${isIngredient ? 'normal-col' : 'xs-col'}`}>
              {I18n.t('cultivation.finishedProduct.form.quantity')}
            </div>

            <div className={`flex-col text-center ${isIngredient ? 'small-col' : 'xs-col'}`}>
              {I18n.t('cultivation.finishedProduct.form.uom')}
            </div>

            <div className={`flex-col ${isIngredient ? 'lg-col' : 'xs-col'}`}>
              {I18n.t('cultivation.finishedProduct.form.total')}
            </div>

            <div className={`flex-col text-center ${isIngredient ? 'small-col' : 'xs-col'}`}>
              {I18n.t('cultivation.finishedProduct.form.uom')}
            </div>

            <div className={`flex-col ${isIngredient ? 'normal-col' : 'small-col'}`}>
              {I18n.t('cultivation.finishedProduct.form.storageLocation')}
            </div>

            {!isIngredient ? (
              <PermissionWrapper permissions={[p.print_labels]}>
                <div className='flex-col xs-col'>{I18n.t('cultivation.finishedProduct.form.printLabel')}</div>
              </PermissionWrapper>
            ) : null}

            <PermissionWrapper permissions={[p.view_inventory_history]}>
              <div className={`flex-col ${isIngredient ? 'small-col' : 'xs-col'}`}>{I18n.t('common.history')}</div>
            </PermissionWrapper>

            {!isIngredient ? (
              <div className='flex-col xs-col'>{I18n.t('ei.inventory.table.onHold')}</div>
            ) : null}

            {integrationState.isWaLeaf && (
              <div className='flex-col xs-col'>{I18n.t('inventory.form.inventoryType')}</div>
            )}

            <div className='flex-col small-col'>
              {integrationState['isBiotrack']
                ? I18n.t('cultivation.finishedProduct.form.biotrackInventoryType')
                : I18n.t('cultivation.finishedProduct.form.destinationPartner')}
            </div>

            {!isIngredient ? (
              <div className='flex-col xs-col'>{I18n.t('cultivation.finishedProduct.form.testPackage')}</div>
            ) : null}

            <div className='flex-col small-col'>{I18n.t('cultivation.finishedProduct.form.purposes')}</div>

            {!isIngredient ? (
              <MetrcConcealer>
                <div className='flex-col xs-col'>{I18n.t('metrc.form.isProductionBatch')}</div>
              </MetrcConcealer>
            ) : null}

            {!isIngredient && isMetrcStemHoldingsToggled ? (
              <MetrcConcealer>
                <div className='flex-col xs-col'>{I18n.t('metrc.form.tradeSample')}</div>
              </MetrcConcealer>
            ) : null}

            {reasonIsDisplayed ? (
              <div className='flex-col xs-col'>{I18n.t('cultivation.finishedProduct.form.inactivationReason')}</div>
            ) : null}

          </div>
        </Card.Header>
        <ModifyItems
          userHasEditPermission={userHasEditPermission}
          canUserManageReservationAndIsAllowedInComplianceSettings={
            canUserManageReservationAndIsAllowedInComplianceSettings
          }
          locations={locations}
          sharedLocations={sharedLocations}
          selectedProductIds={selectedProductIds}
          onSubmit={onSubmit}
          id={row.id}
          showSelect={showSelect}
          active={active}
          handleSelect={handleSelect}
          children={row.children}
          total={row.weight_gr_total}
          commonUom={get(row, 'uom_display')}
          printLabel={printLabel}
          showProductionRunsModal={showProductionRunsModal}
          isManufacturing={isManufacturing}
          isIngredient={isIngredient}
          onIngredientAdjust={onIngredientAdjust}
          quickMoveDisabled={quickMoveDisabled}
          activeTab={activeTab}
          integrationState={integrationState}
          hasPackagesTags={hasPackagesTags}
          partners={partners}
          showTrackingId={showTrackingId}
          fetchChildrenAndLabResults={fetchChildrenAndLabResults}
          reasonIsDisplayed={reasonIsDisplayed}
          showReservationsModal={showReservationsModal}
          allowPackageLocationSplit={allowPackageLocationSplit}
          showModality={showModality}
          harvests={harvests}
          showCupo={showCupo}
          cupos={cupos}
        />
      </Card>
    </div>
  );
};

QuickMovePanel.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onIngredientAdjust: PropTypes.func,
  printLabel: PropTypes.func.isRequired,
  showProductionRunsModal: PropTypes.func,
  row: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  sharedLocations: PropTypes.array,
  active: PropTypes.bool.isRequired,
  showSelect: PropTypes.bool.isRequired,
  selectedProductIds: PropTypes.array.isRequired,
  handleSelect: PropTypes.func,
  isManufacturing: PropTypes.bool,
  isIngredient: PropTypes.bool,
  integrationState: PropTypes.object.isRequired,
  hasPackagesTags: PropTypes.bool,
  quickMoveDisabled: PropTypes.bool,
  activeTab: PropTypes.object,
  partners: PropTypes.array,
  fetchChildrenAndLabResults: PropTypes.func,
  displayInactivationReason: PropTypes.bool,
  userHasEditPermission: PropTypes.bool,
  showReservationsModal: PropTypes.func,
  canUserManageReservationAndIsAllowedInComplianceSettings: PropTypes.bool,
  allowPackageLocationSplit: PropTypes.bool,
  showModality: PropTypes.bool,
  harvests: PropTypes.array,
  showCupo: PropTypes.bool,
  cupos: PropTypes.array,
  isMetrcStemHoldingsToggled: PropTypes.bool,
  useEntityLocks: PropTypes.bool,
};

export default QuickMovePanel;
