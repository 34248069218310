import {createSelector} from 'reselect';
import * as dataNames from '../../constants/dataNames';
import {getRewardSettings} from '../rewardsSelectors';

export const getCustomerGroups = (state) => state[dataNames.customerGroups];

const getCustomerGroupId = (_, props) => props.customerGroupId ? props.customerGroupId : 0;

export const getCustomerGroup = createSelector([getCustomerGroups, getCustomerGroupId], (groups, groupId) => {
  if(!Array.isArray(groups)) return {};
  const filteredGroups = groups.filter((g) => parseInt(g.id) === parseInt(groupId));
  if(filteredGroups.length > 0) return filteredGroups[0];
  return {};
});

export const getInitialValues = createSelector([getCustomerGroup], (customerGroup) => {
  const defaults = {
    is_rewards_program_group: false,
    standard_discount_percent: 100.00,
    min_visit_frequency: 0,
    min_visit_frequency_days: 0,
    min_accrued_points: 0,
    min_total_spend: 1
  };
  if(Object.keys(customerGroup).length === 0) return defaults;
  return Object.keys(customerGroup).reduce((acc, key) => {
    acc[key] = customerGroup[key] !== null ? customerGroup[key] : defaults[key];
    if(key === 'is_rewards_program_group') acc[key] = Boolean(customerGroup[key]);
    return acc;
  }, {});
});

/***
 * Returns boolean to determine whether a group can be deleted or not.  If in rewards setttings needs to be removed
 * first.
 * @type {Reselect.Selector<TInput, TOutput>}
 */
export const isUsedInRewardsSetup = createSelector([getCustomerGroup, getRewardSettings], (group, settings) => {
  const getSettingsGroupsArray = () => {
    const property = 'reward_eligible_reward_groups';
    if(!settings[property]) return [];
    return (Array.isArray(settings[property].value))
      ? settings[property].value
      : (Array.isArray(settings[property].default_value))
        ? settings[property].default_value
        : [];
  };
  return (group && settings)
    ? getSettingsGroupsArray().indexOf(group.id) !== -1
    : false;
});

export const getPayload = (formData) => {
  return formData.customerGroups.map(group => ({
    ...group,
    is_rewards_program_group: group.is_rewards_program_group ? 1 : 0,
    group_name: group.group_name.trim(),
    active: group.active !== undefined ? group.active : 1,
  }));
};
