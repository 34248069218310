import * as types from '../../constants/actionTypes';
import initialState from '../../constants/initialState';

export default function couponReducer (state = initialState.cart.coupon, action){
  const {couponId} = action;
  switch (action.type) {
  case types.SET_COUPON:
    return couponId;
  default:
    return state;
  }
}
