import React from 'react';
import PropTypes from 'prop-types';
import {Field, FormSection} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import * as p from '../../../../constants/permissions';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import TextInput from '../../../common/form/TextInput';
import InlineCheckBox from '../../../common/form/InlineCheckBox';
import PermissionWrapper from '../../../common/PermissionWrapper';

const MetrcIntegratorSettings = ({integratorName, metrcStates, isRetailFacility, selectedMetrcState, integrator, features}) => {
  const override_environment_url = integrator.value ?  integrator.value.override_environment_url : false;
  const facilityTypes = [
    {text: 'Recreational', value: 'recreational'},
    {text: 'Medical', value: 'medical'}
  ];
  const barcodeSources = [
    {text: 'State Integration Tracking ID', value: 'state_integration_tracking_id'},
    {text: 'Platform generated package code', value: 'platform_generated_package_code'}
  ];

  return (
    <Col xs={12}>
      <h3>{I18n.t('stateIntegrators.form.metrcConfiguration')}</h3>
      <FormSection name={`${integratorName}.value`}>
        <Row>
          <Col sm={6} md={4} lg={3}>
            <Field name='user_api_key' component={TextInput} props={{
              label: I18n.t('stateIntegrators.form.userApiKey'),
              isRequired: true,
            }}/>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={4} lg={3}>
            <Field name='license_number' component={TextInput} props={{
              label: I18n.t('stateIntegrators.form.licenseNumber'),
              isRequired: true,
            }}/>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={4} lg={3}>
            <Field name='state' component={ReactSelectInput} props={{
              label: I18n.t('stateIntegrators.form.metrcState'),
              options: metrcStates,
              isRequired: true,
            }}/>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={4} lg={3}>
            <Field name='facility_type' component={ReactSelectInput} props={{
              label: I18n.t('stateIntegrators.form.metrcType'),
              placeholder:  I18n.t('stateIntegrators.form.selectPlaceholder'),
              options: facilityTypes,
              isRequired: true,
            }}/>
          </Col>
        </Row>
        {(isRetailFacility) ? <Row>
          <Col sm={6} md={4} lg={3}>
            <Field name='enable_sales_push' component={InlineCheckBox} props={{
              label: I18n.t('stateIntegrators.form.pushToMetrc'),
              disabled: (selectedMetrcState === 'oh')
            }}/>
          </Col>
        </Row> : null}
        <PermissionWrapper permissions={[p.super_admin_only]} alternateComponent={override_environment_url ?
          <Row>
            <Col sm={6} md={4} lg={3}>
              <Field name='url' component={TextInput} props={{
                label: I18n.t('stateIntegrators.form.url'),
                disabled: true
              }}/>
            </Col>
          </Row> : null
          }>
          <Row>
            <Col sm={6} md={4} lg={3}>
              <Field name='url' component={TextInput} props={{
                label: I18n.t('stateIntegrators.form.url'),
                disabled: !override_environment_url
              }}/>
            </Col>
            <Col sm={3} md={2} lg={2}>
              <Field name='override_environment_url' component={InlineCheckBox} props={{
                label: I18n.t('stateIntegrators.form.overrideEnvironmentUrl'),
              }}/>
            </Col>
          </Row>
          {(features.isMetrcTransfersToggled) ? <Row>
            <Col sm={6} md={4} lg={3}>
              <Field name='enabled_metrc_transfers' component={InlineCheckBox} props={{
                label: I18n.t('stateIntegrators.form.enableMetrcTransfers')
              }}/>
            </Col>
          </Row> : null}
          {(features.isProductMasterToggled) ? <Row>
            <Col sm={6} md={4} lg={3}>
              <Field name='package_barcode_source' component={ReactSelectInput} props={{
                options: barcodeSources,
                label: I18n.t('stateIntegrators.form.barcodeSource'),
              }}/>
            </Col>
          </Row> : null}
        </PermissionWrapper>
      </FormSection>
    </Col>
  );
};

MetrcIntegratorSettings.propTypes = {
  integratorName: PropTypes.string.isRequired,
  metrcStates: PropTypes.array.isRequired,
  isRetailFacility: PropTypes.bool.isRequired,
  features: PropTypes.object,
  selectedMetrcState: PropTypes.string,
  integrator: PropTypes.object
};

export default MetrcIntegratorSettings;

