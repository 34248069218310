import axios from 'axios';

const qz = require('qz-tray');

//import * as itemNames from '../../constants/itemNames';
//import * as dataNames from '../../constants/dataNames';
//import {postItem} from '../../actions/apiActions';

// Kept for my reference for a cycle or two until I see the new cert working reliably with no side effects

/***
 * Usage: Import into the target file and invoke the function assigning the result to a varaible
 * you will use to print.
 *
 * Print calls look like:
      qz.websocket.connect().then(() => {

        qz.printers.getDefault().then((printer) => {

          // Pass the printer name into the next Promise
          const config = qz.configs.create(printer);

          // Create a default config for the found printer
          //const data = [ZPL_DATA_GOES_HERE];
          const data = [ZPL_DATA_GOES_HERE];
          // Raw ZPL
          return qz.print(config, data);

        }).catch((e) => {
          // Handle case where defaultPrinter can't be retrieved
        }).then(() => {
          qz.websocket.disconnect().then(() => {
            // Any cleanup
          });

      });

    }).catch( (error) => {
      // Error handling when can't connect - which happens when QZTray is not running on the client
    });
 *
 *
 * @returns {qz}
 * @constructor
 */
const QzTrayConfig = () => {

  qz.api.setPromiseType((resolver) => { return new Promise(resolver); });

  qz.security.setCertificatePromise( (resolve) => {
    resolve(`-----BEGIN CERTIFICATE-----
    MIIEwTCCAqugAwIBAgIBADALBgkqhkiG9w0BAQUwgZgxCzAJBgNVBAYTAlVTMQsw
    CQYDVQQIDAJOWTEbMBkGA1UECgwSUVogSW5kdXN0cmllcywgTExDMRswGQYDVQQL
    DBJRWiBJbmR1c3RyaWVzLCBMTEMxGTAXBgNVBAMMEHF6aW5kdXN0cmllcy5jb20x
    JzAlBgkqhkiG9w0BCQEWGHN1cHBvcnRAcXppbmR1c3RyaWVzLmNvbTAeFw0xNzA1
    MDYyMTAwMDBaFw0xODA1MDcyMTAwMDBaMIGPMQswCQYDVQQGDAJVUzERMA8GA1UE
    CAwIQ29sb3JhZG8xDzANBgNVBAcMBkRlbnZlcjESMBAGA1UECgwJTUpGcmVld2F5
    MRIwEAYDVQQLDAlNSkZyZWV3YXkxEjAQBgNVBAMMCU1KRnJlZXdheTEgMB4GCSqG
    SIb3DQEJAQwRaWtlQG1qZnJlZXdheS5jb20wggEgMAsGCSqGSIb3DQEBAQOCAQ8A
    MIIBCgKCAQEA9oO4q2pYUGzwo0Q5WvcPekSHvC/i+Z0tRtqK/QCjWl56nB7ZCYLV
    Wz5FQpoachHzsydU4EcmevBrNrXYh54FuRs6S4KIpSZP42cczcMe558FS0fwiuoa
    /GU2mLWGev8f6RXtUzzEd3kAFBIo7IjI6pdT+9dPhk7Z0rEWnMzqo8L2rgfLCqiv
    t6B70SmGq787uj0VHrSdqw1THTJNEU95iY6Fdccsj4SPW5O8pzvljXuYB62TILww
    UzFETZIzdth3NE7gQ2reDAUabCP/dHi/CpxXrjs0d8USxkj2RUL4cVAA4y57Z0Pd
    +jJ/6PL4MhxeyKKoFD1lXylfHekPm57KMQIDAQABoyMwITAfBgNVHSMEGDAWgBSQ
    plC3hNS56l/yBYQTeEXoqXVUXDALBgkqhkiG9w0BAQUDggIBAA4evtMlKkJxC1dA
    +p/ZNgah7uDODxcBQl8WhCtfXKgRpbC/dCd/G9yKLAA3KuUUSJO9cgbIUszHRj8m
    dSLgg0NkQHkn2O1qDmI6fLCSujqOHgvSivKG70tzGIELRzbmoRyXvJsOCborcaHT
    isGP/DzKVHBN/sUhEY5JiMlTQ9suMpeiq52MEJLSO0TrigjiS/tOmVd6bZUVWMGs
    3tw30od7yLjL4NhntGqbJwIbOYJ0sV0ChfedrGJHfILm+gSEfFeybSHHnJwYya3S
    N8GYwKUe/tXnLjC5LI05Ch1JQ24xVoX4hQv8RDGAnfiAE3DihceOk0QJcpxYLxC/
    nzQolBjw/FRoAqTuCqyHwgWrBX1fveHqJ4a/rBCfCD3Zx1dpHnkNqhvpYXSAixzp
    PVRJ1lLKHw23iQ4EAeFzk8jxkoKnImYPUepDKq/4/MOqLIverFneXKwrnEuSTPLC
    0rtFoWR9Mue1hd0d+8bQH3NXML5G9kE4l9LqtQQht7DRC3mksCOEgQJdlNrfaykr
    m2jVPoRu7qdvNC1rgqLr5k7eKi4Pede6SkcLwJlNilh154d1zeMba7EgiGZD0JUp
    2l8smnasjcB3UGUkr1pzB8yQbQkkCZM5dm8Ww+qoq4D+p9UUoKOkfrLd4V/ofba7
    tsjHLXZOXhGYnYX8+A1PgtphGAFP
    -----END CERTIFICATE-----
      --START INTERMEDIATE CERT--
    -----BEGIN CERTIFICATE-----
      MIIFEjCCA/qgAwIBAgICEAAwDQYJKoZIhvcNAQELBQAwgawxCzAJBgNVBAYTAlVT
    MQswCQYDVQQIDAJOWTESMBAGA1UEBwwJQ2FuYXN0b3RhMRswGQYDVQQKDBJRWiBJ
    bmR1c3RyaWVzLCBMTEMxGzAZBgNVBAsMElFaIEluZHVzdHJpZXMsIExMQzEZMBcG
    A1UEAwwQcXppbmR1c3RyaWVzLmNvbTEnMCUGCSqGSIb3DQEJARYYc3VwcG9ydEBx
    emluZHVzdHJpZXMuY29tMB4XDTE1MDMwMjAwNTAxOFoXDTM1MDMwMjAwNTAxOFow
    gZgxCzAJBgNVBAYTAlVTMQswCQYDVQQIDAJOWTEbMBkGA1UECgwSUVogSW5kdXN0
    cmllcywgTExDMRswGQYDVQQLDBJRWiBJbmR1c3RyaWVzLCBMTEMxGTAXBgNVBAMM
    EHF6aW5kdXN0cmllcy5jb20xJzAlBgkqhkiG9w0BCQEWGHN1cHBvcnRAcXppbmR1
    c3RyaWVzLmNvbTCCAiIwDQYJKoZIhvcNAQEBBQADggIPADCCAgoCggIBANTDgNLU
    iohl/rQoZ2bTMHVEk1mA020LYhgfWjO0+GsLlbg5SvWVFWkv4ZgffuVRXLHrwz1H
    YpMyo+Zh8ksJF9ssJWCwQGO5ciM6dmoryyB0VZHGY1blewdMuxieXP7Kr6XD3GRM
    GAhEwTxjUzI3ksuRunX4IcnRXKYkg5pjs4nLEhXtIZWDLiXPUsyUAEq1U1qdL1AH
    EtdK/L3zLATnhPB6ZiM+HzNG4aAPynSA38fpeeZ4R0tINMpFThwNgGUsxYKsP9kh
    0gxGl8YHL6ZzC7BC8FXIB/0Wteng0+XLAVto56Pyxt7BdxtNVuVNNXgkCi9tMqVX
    xOk3oIvODDt0UoQUZ/umUuoMuOLekYUpZVk4utCqXXlB4mVfS5/zWB6nVxFX8Io1
    9FOiDLTwZVtBmzmeikzb6o1QLp9F2TAvlf8+DIGDOo0DpPQUtOUyLPCh5hBaDGFE
    ZhE56qPCBiQIc4T2klWX/80C5NZnd/tJNxjyUyk7bjdDzhzT10CGRAsqxAnsjvMD
    2KcMf3oXN4PNgyfpbfq2ipxJ1u777Gpbzyf0xoKwH9FYigmqfRH2N2pEdiYawKrX
    6pyXzGM4cvQ5X1Yxf2x/+xdTLdVaLnZgwrdqwFYmDejGAldXlYDl3jbBHVM1v+uY
    5ItGTjk+3vLrxmvGy5XFVG+8fF/xaVfo5TW5AgMBAAGjUDBOMB0GA1UdDgQWBBSQ
    plC3hNS56l/yBYQTeEXoqXVUXDAfBgNVHSMEGDAWgBQDRcZNwPqOqQvagw9BpW0S
    BkOpXjAMBgNVHRMEBTADAQH/MA0GCSqGSIb3DQEBCwUAA4IBAQAJIO8SiNr9jpLQ
    eUsFUmbueoxyI5L+P5eV92ceVOJ2tAlBA13vzF1NWlpSlrMmQcVUE/K4D01qtr0k
    gDs6LUHvj2XXLpyEogitbBgipkQpwCTJVfC9bWYBwEotC7Y8mVjjEV7uXAT71GKT
    x8XlB9maf+BTZGgyoulA5pTYJ++7s/xX9gzSWCa+eXGcjguBtYYXaAjjAqFGRAvu
    pz1yrDWcA6H94HeErJKUXBakS0Jm/V33JDuVXY+aZ8EQi2kV82aZbNdXll/R6iGw
    2ur4rDErnHsiphBgZB71C5FD4cdfSONTsYxmPmyUb5T+KLUouxZ9B0Wh28ucc1Lp
    rbO7BnjW
    -----END CERTIFICATE-----`);
  });
  /*
  qz.security.setCertificatePromise((resolve) => {
    resolve('-----BEGIN CERTIFICATE-----\n' +
      'MIIFAzCCAuugAwIBAgICEAIwDQYJKoZIhvcNAQEFBQAwgZgxCzAJBgNVBAYTAlVT\n' +
      'MQswCQYDVQQIDAJOWTEbMBkGA1UECgwSUVogSW5kdXN0cmllcywgTExDMRswGQYD\n' +
      'VQQLDBJRWiBJbmR1c3RyaWVzLCBMTEMxGTAXBgNVBAMMEHF6aW5kdXN0cmllcy5j\n' +
      'b20xJzAlBgkqhkiG9w0BCQEWGHN1cHBvcnRAcXppbmR1c3RyaWVzLmNvbTAeFw0x\n' +
      'NTAzMTkwMjM4NDVaFw0yNTAzMTkwMjM4NDVaMHMxCzAJBgNVBAYTAkFBMRMwEQYD\n' +
      'VQQIDApTb21lIFN0YXRlMQ0wCwYDVQQKDAREZW1vMQ0wCwYDVQQLDAREZW1vMRIw\n' +
      'EAYDVQQDDAlsb2NhbGhvc3QxHTAbBgkqhkiG9w0BCQEWDnJvb3RAbG9jYWxob3N0\n' +
      'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAtFzbBDRTDHHmlSVQLqjY\n' +
      'aoGax7ql3XgRGdhZlNEJPZDs5482ty34J4sI2ZK2yC8YkZ/x+WCSveUgDQIVJ8oK\n' +
      'D4jtAPxqHnfSr9RAbvB1GQoiYLxhfxEp/+zfB9dBKDTRZR2nJm/mMsavY2DnSzLp\n' +
      't7PJOjt3BdtISRtGMRsWmRHRfy882msBxsYug22odnT1OdaJQ54bWJT5iJnceBV2\n' +
      '1oOqWSg5hU1MupZRxxHbzI61EpTLlxXJQ7YNSwwiDzjaxGrufxc4eZnzGQ1A8h1u\n' +
      'jTaG84S1MWvG7BfcPLW+sya+PkrQWMOCIgXrQnAsUgqQrgxQ8Ocq3G4X9UvBy5VR\n' +
      'CwIDAQABo3sweTAJBgNVHRMEAjAAMCwGCWCGSAGG+EIBDQQfFh1PcGVuU1NMIEdl\n' +
      'bmVyYXRlZCBDZXJ0aWZpY2F0ZTAdBgNVHQ4EFgQUpG420UhvfwAFMr+8vf3pJunQ\n' +
      'gH4wHwYDVR0jBBgwFoAUkKZQt4TUuepf8gWEE3hF6Kl1VFwwDQYJKoZIhvcNAQEF\n' +
      'BQADggIBAFXr6G1g7yYVHg6uGfh1nK2jhpKBAOA+OtZQLNHYlBgoAuRRNWdE9/v4\n' +
      'J/3Jeid2DAyihm2j92qsQJXkyxBgdTLG+ncILlRElXvG7IrOh3tq/TttdzLcMjaR\n' +
      '8w/AkVDLNL0z35shNXih2F9JlbNRGqbVhC7qZl+V1BITfx6mGc4ayke7C9Hm57X0\n' +
      'ak/NerAC/QXNs/bF17b+zsUt2ja5NVS8dDSC4JAkM1dD64Y26leYbPybB+FgOxFu\n' +
      'wou9gFxzwbdGLCGboi0lNLjEysHJBi90KjPUETbzMmoilHNJXw7egIo8yS5eq8RH\n' +
      'i2lS0GsQjYFMvplNVMATDXUPm9MKpCbZ7IlJ5eekhWqvErddcHbzCuUBkDZ7wX/j\n' +
      'unk/3DyXdTsSGuZk3/fLEsc4/YTujpAjVXiA1LCooQJ7SmNOpUa66TPz9O7Ufkng\n' +
      '+CoTSACmnlHdP7U9WLr5TYnmL9eoHwtb0hwENe1oFC5zClJoSX/7DRexSJfB7YBf\n' +
      'vn6JA2xy4C6PqximyCPisErNp85GUcZfo33Np1aywFv9H+a83rSUcV6kpE/jAZio\n' +
      '5qLpgIOisArj1HTM6goDWzKhLiR/AeG3IJvgbpr9Gr7uZmfFyQzUjvkJ9cybZRd+\n' +
      'G8azmpBBotmKsbtbAU/I/LVk8saeXznshOVVpDRYtVnjZeAneso7\n' +
      '-----END CERTIFICATE-----\n' +
      '--START INTERMEDIATE CERT--\n' +
      '-----BEGIN CERTIFICATE-----\n' +
      'MIIFEjCCA/qgAwIBAgICEAAwDQYJKoZIhvcNAQELBQAwgawxCzAJBgNVBAYTAlVT\n' +
      'MQswCQYDVQQIDAJOWTESMBAGA1UEBwwJQ2FuYXN0b3RhMRswGQYDVQQKDBJRWiBJ\n' +
      'bmR1c3RyaWVzLCBMTEMxGzAZBgNVBAsMElFaIEluZHVzdHJpZXMsIExMQzEZMBcG\n' +
      'A1UEAwwQcXppbmR1c3RyaWVzLmNvbTEnMCUGCSqGSIb3DQEJARYYc3VwcG9ydEBx\n' +
      'emluZHVzdHJpZXMuY29tMB4XDTE1MDMwMjAwNTAxOFoXDTM1MDMwMjAwNTAxOFow\n' +
      'gZgxCzAJBgNVBAYTAlVTMQswCQYDVQQIDAJOWTEbMBkGA1UECgwSUVogSW5kdXN0\n' +
      'cmllcywgTExDMRswGQYDVQQLDBJRWiBJbmR1c3RyaWVzLCBMTEMxGTAXBgNVBAMM\n' +
      'EHF6aW5kdXN0cmllcy5jb20xJzAlBgkqhkiG9w0BCQEWGHN1cHBvcnRAcXppbmR1\n' +
      'c3RyaWVzLmNvbTCCAiIwDQYJKoZIhvcNAQEBBQADggIPADCCAgoCggIBANTDgNLU\n' +
      'iohl/rQoZ2bTMHVEk1mA020LYhgfWjO0+GsLlbg5SvWVFWkv4ZgffuVRXLHrwz1H\n' +
      'YpMyo+Zh8ksJF9ssJWCwQGO5ciM6dmoryyB0VZHGY1blewdMuxieXP7Kr6XD3GRM\n' +
      'GAhEwTxjUzI3ksuRunX4IcnRXKYkg5pjs4nLEhXtIZWDLiXPUsyUAEq1U1qdL1AH\n' +
      'EtdK/L3zLATnhPB6ZiM+HzNG4aAPynSA38fpeeZ4R0tINMpFThwNgGUsxYKsP9kh\n' +
      '0gxGl8YHL6ZzC7BC8FXIB/0Wteng0+XLAVto56Pyxt7BdxtNVuVNNXgkCi9tMqVX\n' +
      'xOk3oIvODDt0UoQUZ/umUuoMuOLekYUpZVk4utCqXXlB4mVfS5/zWB6nVxFX8Io1\n' +
      '9FOiDLTwZVtBmzmeikzb6o1QLp9F2TAvlf8+DIGDOo0DpPQUtOUyLPCh5hBaDGFE\n' +
      'ZhE56qPCBiQIc4T2klWX/80C5NZnd/tJNxjyUyk7bjdDzhzT10CGRAsqxAnsjvMD\n' +
      '2KcMf3oXN4PNgyfpbfq2ipxJ1u777Gpbzyf0xoKwH9FYigmqfRH2N2pEdiYawKrX\n' +
      '6pyXzGM4cvQ5X1Yxf2x/+xdTLdVaLnZgwrdqwFYmDejGAldXlYDl3jbBHVM1v+uY\n' +
      '5ItGTjk+3vLrxmvGy5XFVG+8fF/xaVfo5TW5AgMBAAGjUDBOMB0GA1UdDgQWBBSQ\n' +
      'plC3hNS56l/yBYQTeEXoqXVUXDAfBgNVHSMEGDAWgBQDRcZNwPqOqQvagw9BpW0S\n' +
      'BkOpXjAMBgNVHRMEBTADAQH/MA0GCSqGSIb3DQEBCwUAA4IBAQAJIO8SiNr9jpLQ\n' +
      'eUsFUmbueoxyI5L+P5eV92ceVOJ2tAlBA13vzF1NWlpSlrMmQcVUE/K4D01qtr0k\n' +
      'gDs6LUHvj2XXLpyEogitbBgipkQpwCTJVfC9bWYBwEotC7Y8mVjjEV7uXAT71GKT\n' +
      'x8XlB9maf+BTZGgyoulA5pTYJ++7s/xX9gzSWCa+eXGcjguBtYYXaAjjAqFGRAvu\n' +
      'pz1yrDWcA6H94HeErJKUXBakS0Jm/V33JDuVXY+aZ8EQi2kV82aZbNdXll/R6iGw\n' +
      '2ur4rDErnHsiphBgZB71C5FD4cdfSONTsYxmPmyUb5T+KLUouxZ9B0Wh28ucc1Lp\n' +
      'rbO7BnjW\n' +
      '-----END CERTIFICATE-----\n');
  });*/

  /*qz.security.setSignaturePromise(() => {
    return (resolve) => {resolve();};
  });*/

  qz.security.setSignaturePromise( (toSign) => {

    return (resolve, reject) => {

      const path    = '/api/labels/sign';
      const params  = {request: toSign};
      const headers = {};

      axios.post(path, {headers, params})
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject();
        });

    };

  });

  const createHash = require('sha.js');

  qz.api.setSha256Type((data) => {
    return createHash('sha256').update(data).digest('hex');
  });

  return qz;

};

export default QzTrayConfig;
