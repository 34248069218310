import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Table, Col} from 'react-bootstrap';
import get from 'lodash.get';
import RequestedPrePackRow from './RequestedPrePackRow';
import ContentConcealer from '../../../common/concealers/ContentConcealer';
import * as UOMS from '../../../../constants/uoms';
import {convertFromBase} from '../../../../util/uomHelpers';
import InternationalCurrencyStatic from '../../../common/form/InternationalCurrencyStatic';
import InternationalDecimalStatic from '../../../common/form/InternationalDecimalStatic';

const RequestedPrePacks = ({prepackWeights, fieldName, childItemMasters, line, editableLinePrice}) => {

  let totalWeight = 0;
  let totalCost = 0;
  const uom = get(line, 'default_uom', UOMS.GR);
  return (<Col xs={12}  >
    <h4>{I18n.t('transfers.form.itemsRequested')}</h4>
      <ContentConcealer show={!line.is_lab_partner && editableLinePrice}>
        <div style={{margin: '8px 0'}} className='text-danger'>Cost Per Unit and Line Total not shown when Total Line Item Price is set directly.</div>
      </ContentConcealer>
    <Table condensed className={'RequestedPrePacks'} >
    <thead>
      <tr>
        <th>{`${I18n.t(`uoms.${uom}.word`)}s`}</th>
        <th>{I18n.t('purchaseOrders.form.qty')}</th>
        <th>{I18n.t('purchaseOrders.form.total')}</th>
        <ContentConcealer show={!line.is_lab_partner && !editableLinePrice}>
          <th>{I18n.t('purchaseOrders.form.unitCost')}</th>
          <th>{I18n.t('cultivation.transfers.form.lineTotal')}</th>
        </ContentConcealer>
      </tr>
    </thead>
    <tbody>
      {line.source_sales_order_subitems && line.source_sales_order_subitems.length ?
        line.source_sales_order_subitems.map((subItem, index) => {
          const itemMaster = childItemMasters.find(master => master.item_master_id === subItem.item_master_id);
          const prepackWeight = prepackWeights.find(weight => weight.id === itemMaster.prepack_weight_id);
          const baseWeight = get(prepackWeight, 'weight_base', 0);
          const uom = get(prepackWeight, 'uom', UOMS.GR);
          const mult = convertFromBase(baseWeight, uom);
          const lineTotal = parseFloat(subItem.unit_price) * (subItem.qty);
          totalWeight += subItem.qty * mult;
          totalCost += lineTotal;
          return (
            <RequestedPrePackRow
              key={index}
              subItem={subItem}
              itemMaster={itemMaster}
              prepackWeight={prepackWeight}
              editableLinePrice={editableLinePrice}
              isLabPartner={line.is_lab_partner}
              mult={mult}/>
          );
        }
      ) : null}
    </tbody>
    <tfoot>
      <tr><th/>
        <th>{I18n.t('cultivation.transfer.form.totalWeight')}</th>
        <th><InternationalDecimalStatic fractionPartSize={2}>{totalWeight}</InternationalDecimalStatic></th>
        <ContentConcealer show={!line.is_lab_partner && !editableLinePrice}>
          <th>{I18n.t('cultivation.transfers.form.totalCost')}</th>
          <th>
            <InternationalCurrencyStatic>{totalCost.toFixed(2)}</InternationalCurrencyStatic>
          </th>
        </ContentConcealer>
      </tr>
    </tfoot>
  </Table>
  </Col>
  );
};

RequestedPrePacks.propTypes = {
  editableLinePrice: PropTypes.bool,
  childItemMasters: PropTypes.array.isRequired,
  prepackWeights: PropTypes.array.isRequired,
  fieldName: PropTypes.string.isRequired,
  line: PropTypes.object.isRequired,
};

export default RequestedPrePacks;
