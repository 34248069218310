import React from 'react';
import PropTypes from 'prop-types';
import {FieldArray} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import SubmitSection from '../../common/form/SubmitSection';
import RolesFieldArray from './RolesFieldArray';

const ManageUserRolesForm = (props) => {
  const {handleSubmit, reset, pristine, submitting, roles, addRoleDisabled } = props;

  const submitSectionSettings = {
    actionSettings: {
      reset: {
        pristine,
        text: I18n.t('common.actions.reset'),
        style: 'default',
        action: reset
      },
      submit: {
        submitting,
        text: I18n.t('common.actions.save'),
      }
    },
    align: 'right'
  };

  return (
    <form noValidate={true} onSubmit={handleSubmit}>
      <FieldArray name='roles' component={RolesFieldArray} props={{
        roles,
        addRoleDisabled
      }} />
      <SubmitSection settings={submitSectionSettings} />
    </form>
  );
};

ManageUserRolesForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  roles: PropTypes.array.isRequired,
  addRoleDisabled: PropTypes.bool.isRequired
};

export default ManageUserRolesForm;
