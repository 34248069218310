import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';
import {notEmptyArrayValidation} from '../common/form/redux-form/validations';

const validate = (values) => {
  const newWeight = parseFloat(get(values, 'newWeight', undefined));
  const errors = {};
  const sizes = get(values, 'sizes', []);
  errors.sizes = !sizes.length || !isNaN(newWeight)
    ? undefined
    : sizes.map((weight) => {
      const isActive = get(weight, 'active', 0);
      if(!isActive){
        return undefined;
      }
      return {
        appliesTo: notEmptyArrayValidation(get(weight, 'appliesTo', []), I18n.t('prepackWeights.form.packageSizeType')),
        categories: notEmptyArrayValidation(get(weight, 'categories', []), I18n.t('prepackWeights.form.category'))
      };
    });
  const sizesWithoutUndefined = get(errors, 'sizes', []).filter((size) => size !== undefined);
  if(!sizesWithoutUndefined.length){ // If an array of undefined values, set the sizes array itself undefined.
    errors.sizes = undefined;
  }
  return errors;
};

export default validate;
