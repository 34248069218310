import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {push} from 'react-router-redux';
import {FaPrint} from 'react-icons/fa';
import * as apiActions from '../../../../actions/apiActions';
import {handlePrintJob, printJobDispatch, clearPrintToScreen} from '../../../../actions/printerActions';
import * as itemActions from '../../../../actions/itemActions';
import * as itemNames from '../../../../constants/itemNames';
import * as dataNames from '../../../../constants/dataNames';
import {getPrintServers} from '../../../../selectors/forms/printServerFormSelectors';
import {getDefaultLabelType, getPrintLabels, getDefaultLabel, getPrintJobStats} from '../../../../selectors/labelsSelectors';
import LabelPreviews from './LabelPreviews'; //eslint-disable-line
import PrintingNotAvailable from '../../qzprinter/PrintingNotAvailable'; // eslint-disable-line import/no-named-as-default


export class Label extends React.PureComponent {

  constructor (props, context) {
    super(props, context);
    this.print = this.print.bind(this);
    this.printToScreen = this.printToScreen.bind(this);
    this.onPrintSample = this.onPrintSample.bind(this);
    this.setSelectedTag = this.setSelectedTag.bind(this);
    this.onChangePrintX = this.onChangePrintX.bind(this);
    this.setDefaultLabel = this.setDefaultLabel.bind(this);
    this.loadSelectedTag = this.loadSelectedTag.bind(this);
    this.renderPrintButton = this.renderPrintButton.bind(this);
    this.renderLabelPreviews = this.renderLabelPreviews.bind(this);
    this.renderReceiptPreview = this.renderReceiptPreview.bind(this);

    this.state = {
      printX: 1,
      lastCall: false,
      autoPrint: 0,
      selectedTag: props.defaultLabel || false,
      defaultLabel: props.defaultLabel || false,
    };
  }

  componentWillMount(){
    this.getLabel(this.state.selectedTag);
  }

  componentWillUnmount() {
    this.props.actions.unsetItem(itemNames.printReceipt);
    this.props.actions.unsetItem(itemNames.printLabel);
  }

  componentWillReceiveProps(nextProps){
    if(this.getResolution(nextProps) !== this.getResolution(this.props)) {
      this.getLabel(this.state.selectedTag, nextProps);
    } else {
      if(!this.isAutoPrint(nextProps)){
        this.getLabel(this.state.selectedTag, nextProps);
      }
    }

    if(JSON.stringify(this.state.lastCall) !== JSON.stringify(nextProps.printLabel.call)){
      const printObject = Object.assign({}, nextProps.printLabel, {call: this.state.lastCall});
      const printObjectDataName = this.props.labelTag === 'RETAIL_RECEIPT' ? itemNames.printReceipt : itemNames.printLabel;
      this.props.actions.setItem(printObject, printObjectDataName);
    }
  }

  isAutoPrint(props){
    if(props.autoPrint && props.autoPrint !== this.state.autoPrint){
      if(this.canAutoPrint()) {
        this.setState({autoPrint: props.autoPrint});
        return true;
      }
    }
    return false;
  }

  canAutoPrint(props = false){
    if(!props) props = this.props;
    if(props.showModalRequired) return false; // Already showing so don't bother

    if(props.labelTag !== 'RETAIL_RECEIPT') {
      // This should test printer, label, and qzConnected if printer is local
      if (!props.selectedPrinter.qzConnected) {
        this.props.onShowModalRequired();
        return false;
      }
    } else {
      if (!props.selectedPrinter.qzConnected || !props.selectedPrinter[props.labelTag]){
        this.props.onShowModalRequired();
        return false;
      }
    }
    return true;
  }

  getLabel(useSelected = false, props = this.props){
    if(!useSelected) {
      this.setState({selectedTag: false});
    }

    if(
      (this.getPayloadId() === -1) || // <-- Indicates we aren't loaded yet
      (this.isAutoPrint(props) && this.canAutoPrint())) {
      return false;
    }

    const url = this.getUrl(useSelected);
    const dataName = this.props.labelTag === 'RETAIL_RECEIPT' ? itemNames.printReceipt : itemNames.printLabel;
    const params = {
      version: 2,
      resolution: this.getResolution(props),
      label_type: get(props, 'defaultLabelType', 'image'),
      ...this.props.params,
    };

    // Prevent duplicates can happen on resolution change
    const callData = {
      params,
      url,
      payload: props.payload,
      method: this.isGetCall() ? 'GET' : 'POST'
    };

    if(JSON.stringify(callData) === JSON.stringify(this.state.lastCall)) {
      return false;
    }

    const callComplete = () => {
      this.setState({progress: false});
    };

    this.setState({progress: true, lastCall: callData}, () => {
      this.props.actions.clearPrintToScreen();
      if (this.isGetCall()) {
        this.props.actions.getItem(url, dataName, null, params).then(callComplete);
      } else {
        this.props.actions.postItem(url, props.payload, dataName, null, params).then(callComplete);
      }
    });
  }

  getResolution(props) {
    return get(props, `selectedPrinter[${this.props.labelTag}].resolution`, 203);
  }

  isGetCall(){
    if(this.props.labelTag === 'RETAIL_RECEIPT') return true;
    return this.getPayloadId();
  }

  getPayloadId(){
    const p = this.props.payload;
    if(typeof p !== 'object') return p;
    if(Object.keys(this.props.payload).length > 1) return false; // New
    if(p.ids && Array.isArray(p.ids) && p.ids.length > 1) return false;
    if(p.ids && Array.isArray(p.ids) && p.ids.length === 1) return p.ids[0];
    if(p.ids && !Array.isArray(p.ids)) return p.ids;
    if(p.id) return p.id;
    return -1;
  }

  getUrl(useSelected = false){
    if(this.props.labelTag === 'RETAIL_RECEIPT') {
      return `/api/orders/receipt/${this.props.order.id}/pdf`;
    }
    const tag = (useSelected && this.state.selectedTag ? this.state.selectedTag : this.props.labelTag);
    if(!this.getPayloadId()) return `/api/labels/${tag}/generates`;
    return `/api/labels/${tag}/generates/${this.getPayloadId()}`;
  }

  setSelectedTag(tag){
    return new Promise((resolve) => {
      this.setState({selectedTag: tag}, () => {
        resolve();
      });
    });
  }

  loadSelectedTag(){
    return this.getLabel(true);
  }

  setDefaultLabel(label){
    this.setState({defaultLabel: label});
  }

  print(printSample = false, event = false){
    if(event) event.target.blur();
    this.props.actions.printJobDispatch({
      copies: this.state.printX || 1,
      tag: this.props.labelTag,
      quantity:  1,
      printSample: printSample,
      isReceipt: this.props.labelTag === 'RETAIL_RECEIPT'
    });
  }

  printToScreen(){
    if(event) event.target.blur();
    this.props.actions.clearPrintToScreen();
    this.props.actions.printJobDispatch({
      copies: this.state.printX || 1,
      tag: this.props.labelTag,
      quantity: 1,
      printSample: false,
      isReceipt: false,
      printToScreen: true,
    });
  }

  onPrintSample(){
    const printSample = true;
    this.print(printSample);
  }

  onChangePrintX(value){
    if(!value || isNaN(parseInt(value))) value = undefined;
    this.setState({printX: value});
  }

  renderPrintButton() {
    const qzConnected = this.props.selectedPrinter.qzConnected;
    const selectedPrinter = this.props.selectedPrinter[this.props.labelTag];
    const printServer = this.props.printServers.find(({ id }) => id === get(selectedPrinter, 'server.id')) || {};
    const isDisabled = (!get(selectedPrinter, 'server.remote') && !qzConnected) || !selectedPrinter || (get(selectedPrinter, 'server.remote') && !printServer.active);

    return (
      <button
        style={{margin: '5px auto', width: '72mm'}}
        onClick={(event) => this.print(false, event)}
        disabled={isDisabled}
        className='btn btn-primary'
      >
        <FaPrint style={{marginRight: '5px'}}/>
        Print
      </button>
    );
  }

  renderReceiptPreview() {
    const { printLabel } = this.props;

    if (!printLabel.image) {
      return null;
    }

    return (
      <div className='col-md-12'>
        <div style={{textAlign: 'center'}}>
          {this.renderPrintButton()}
          <PrintingNotAvailable labelTag={this.props.labelTag} />
          <div style={{textAlign:'center'}}>
            <img alt='label' style={{width:'72mm', border: '1px solid #ccc', padding: '5px'}} src={`data:image/png;base64,${printLabel.image}`} />
          </div>
          {this.renderPrintButton()}
        </div>
      </div>
    );
  }

  renderLabelPreviews() {
    return (
      <LabelPreviews
        printLabels={this.props.printLabels}
        selectedTag={this.state.selectedTag}
        defaultLabel={this.state.defaultLabel}
        setSelectedTag={this.setSelectedTag}
        loadSelectedTag={this.loadSelectedTag}
        loadLabel={() => this.getLabel()}
        labelTag={this.props.labelTag}
        selectedPrinter={this.props.selectedPrinter}
        setDefaultLabel={this.setDefaultLabel}
        print={this.print}
        printX={this.state.printX}
        onChangePrintX={this.onChangePrintX}
        onPrintSample={this.onPrintSample}
        printServers={this.props.printServers}
        localPrinters={this.props.localPrinters}
        printJob={this.props.printJob}
        printJobStats={this.props.printJobStats}
        printToScreen={this.printToScreen}
      />
    );
  }

  render() {

    return (
      <div>
        {(this.state.progress) && (
          <div>
            <div style={{clear:'both'}} />
            <div className='progress progress-striped active'>
              <div style={{width: '100%'}} className='progress-bar'  role='progressbar' aria-valuenow='100' aria-valuemin='0' aria-valuemax='100' />
            </div>
          </div>
        )}
        {(this.props.labelTag === 'RETAIL_RECEIPT')
          ? this.renderReceiptPreview()
          : this.renderLabelPreviews()
        }
      {
        !Object.keys(this.props.printJob).length
          ? null
          : (<div>
          <div style={{clear:'both'}} />
          <div className='progress progress-striped active'>
        <div style={{width: '100%'}} className='progress-bar'  role='progressbar' aria-valuenow='100' aria-valuemin='0' aria-valuemax='100' />
        </div>
          <div style={{textAlign: 'center', fontSize: 'smaller'}} className='text-muted'>
            {
              this.props.labelTag !== 'RETAIL_RECEIPT'
                ? <div>Printing {this.props.printJobStats.totalLabels} labels in {this.props.printJobStats.totalBlocks} groups.  Groups queued: {this.props.printJobStats.loadedBlocks}</div>
                : <div>Sending Receipt To Printer...</div>
            }
          </div>
        </div>)
      }
    </div>);
  }
}

Label.propTypes = {
  params: PropTypes.object,
  forceLabelBlocks: PropTypes.bool,
  qzTray: PropTypes.object.isRequired,
  hidePrinter: PropTypes.func.isRequired,
  showPrinter: PropTypes.bool,
  labelTag   : PropTypes.string,
  labelIds   : PropTypes.array,
  label      : PropTypes.object,
  labelUrl: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  payload    : PropTypes.object,
  goToAfter  : PropTypes.string,
  actions    : PropTypes.object.isRequired,
  httpAction : PropTypes.string,
  facility: PropTypes.object,

  renderType: PropTypes.string,
};


function mapStateToProps(state, ownProps) {
  const {label} = state;
  return {
    facility: state.facility,
    goToAfter: ownProps.goToAfter,
    qzTray: state.qzTray,
    label,
    defaultLabelType: getDefaultLabelType(state),
    selectedPrinter: state[itemNames.selectedPrinter],
    printLabel: state[ownProps.labelTag === 'RETAIL_RECEIPT' ? itemNames.printReceipt : itemNames.printLabel],
    printLabels: getPrintLabels(state),
    defaultLabel: getDefaultLabel(state[itemNames.facility].id, ownProps.labelTag),
    printJob: state[itemNames.printJob],
    printJobStats: getPrintJobStats(state),
    printServers: getPrintServers(state),
    localPrinters: state[dataNames.localPrinters],
    order: state[itemNames.order],
    printToScreen: state[dataNames.printToScreenQueue],
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, itemActions, {push}, {handlePrintJob, printJobDispatch, clearPrintToScreen});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Label);


