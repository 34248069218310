import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import {Button, ListGroup, ListGroupItem, Row, Col} from 'react-bootstrap';

const ProductsListing = ({products, isLoading}) => {
  return (
    <div>
      {isLoading ? 'Loading products...' :
        <Row>
        <Col md={6}>
          <h4>Active Products</h4>
          <ListGroup>
            {
              products.filter((product) => product.active).length
                ? null
                : <ListGroupItem variant='info'>No Active Products In Sub-Category</ListGroupItem>
            }
            {
              products
                .filter((product) => product.active)
                .map((product, index) => {
                  return (<ListGroupItem key={index}>
                    <Link  to={`/products/modify/${product.id}`} className='product-listing-link'>
                      <Button variant='link'>{product.name}</Button>
                    </Link>
                  </ListGroupItem>);
                })
            }
          </ListGroup>
        </Col>
        <Col md={6}>
          <h4>Inactive Products</h4>
          <ListGroup>
            {
              products.filter((product) => !product.active).length
                ? null
                : <ListGroupItem variant='info'>No Inactive Products In Sub-Category</ListGroupItem>
            }
            {
              products
                .filter((product) => !product.active)
                .map((product, index) => {
                  return (<ListGroupItem key={index}>
                    <Link  to={`/products/modify/${product.id}`} className='product-listing-link'>
                      <Button variant='link'>{product.name}</Button>
                    </Link>
                  </ListGroupItem>);
                })
            }
          </ListGroup>
        </Col>
      </Row>}
    </div>
  );
};

ProductsListing.propTypes = {
  products: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default ProductsListing;
