import { change, formValueSelector } from 'redux-form';
import get from 'lodash.get';
import * as apiActions from '../../actions/apiActions';
import * as types from '../../constants/actionTypes';
import * as itemNames from '../../constants/itemNames';
import * as dataNames from '../../constants/dataNames';
import {getRewardsPointsToCurrencyConversion} from '../../selectors/rewardsSelectors';
import {POS_PAYMENT_FORM} from '../../constants/forms';

const posPaymentForm = store => next => action => {
  const result = next(action);
  const {meta, type, payload} = action;
  /***
   * Convert points to currency for ui
   */
  if(meta && meta.form === POS_PAYMENT_FORM){
    const selector = formValueSelector(meta.form);
    const pointsToCurrency = getRewardsPointsToCurrencyConversion(store.getState());
    const fields = ['points', 'points_currency'];
    const customerPoints = fields.indexOf(meta.field) !== -1
      ? parseFloat(store.getState()[itemNames.customer].total_points)
      : 0;

    const convertToCurrency = (points) => {
      points = (points > customerPoints) ? customerPoints : parseFloat(points);
      return (pointsToCurrency > 0) ? (points * pointsToCurrency) : points;
    };

    // const convertToPoints = (currency) => {
    //   currency = parseFloat(currency);
    //   return (currency * pointsToCurrency);
    // };

    const setPoints = (points) => {
      if(selector(store.getState(), 'points') !== points){
        store.dispatch(change(meta.form, 'points', points.toFixed(0)));
      }
    };

    const setCurrency = (currency) => {
      if(selector(store.getState(), 'points_currency') !== currency){
        store.dispatch(change(meta.form, 'points_currency', currency.toFixed(2)));
      }
    };

    if(type === types.REDUX_FORM_CHANGE){ // conversions round and generally make life difficult if on CHANGE event
      if(meta.field === 'points'){
        const newPoints = parseFloat(payload);
        if(newPoints > customerPoints) setPoints(customerPoints);
        const newPointsValue = (newPoints > customerPoints) ? customerPoints : newPoints;
        setCurrency(convertToCurrency(newPointsValue));
      }
      // if(meta.field === 'points_currency'){ // No longer needed but keeping until we know we're done with this form
      //   const maxCurrency = convertToCurrency(customerPoints);
      //   if(payload > maxCurrency) setCurrency(maxCurrency);
      //   setPoints(convertToPoints(payload > maxCurrency ? maxCurrency : payload));
      // }
    }

  }

  /***
   * Coupons and Rewards need to be loaded so their display is correct in the UI... this checks if any are not present
   * in redux and if not, they are all re-fetched so the mapping of values is correct.  Also used to calculate expended
   * reward points.
   */

  const state = store.getState();
  const validActionTypes = [types.GET_ITEM_SUCCESS, types.ADD_ITEM_SUCCESS];
  // Facility added as constraint as a guess to solve 500 error from api/rewards/setup getting called when there is no org/facility but why would order trigger?
  if(validActionTypes.indexOf(type) !== -1  && action.name === itemNames.order && Object.keys(state[itemNames.facility]).length > 0){
    // Make sure we've loaded rewardSettings - they can get lost when tweaking settings and then leaping to payment or fast track
    if(Object.keys(state[itemNames.rewardSettings]).length === 0){
      store.dispatch(apiActions.getItem('/api/rewards/setup', itemNames.rewardSettings));
    }
    // Make sure we have the coupons and rewards we ned
    const couponsOnOrder = get(action, 'payload.data.coupons', false);
    if(couponsOnOrder && Array.isArray(couponsOnOrder)){

      const validTypes = ['reward', 'coupon'];
      // Identify an missing ones
      const missing = couponsOnOrder.reduce((acc, coupon) => {
        if(validTypes.indexOf(coupon.type) === -1) return acc;
        acc[coupon.type === 'coupon' ? 'couponIds' : 'rewardIds'].push(coupon.coupon_id);
        const haveCoupon = state[coupon.type === 'coupon' ? dataNames.couponsInOrder : dataNames.rewardsInOrder].find((c) => c.id === coupon.coupon_id);
        if(!haveCoupon) {
          acc[coupon.type === 'coupon' ? 'coupons' : 'rewards'].push(coupon.coupon_id);
        }
        return acc;
      }, {coupons: [], rewards: [], couponIds: [], rewardIds: []});
      // Fetch as needed
      if(missing.coupons.length > 0){
        store.dispatch(apiActions.getUnpaginatedData(`/api/coupons/by_ids`, dataNames.couponsInOrder, {failed: 'orders.coupons.failed'}, {ids: missing.couponIds}));
      }
      if(missing.rewards.length > 0){
        store.dispatch(apiActions.getUnpaginatedData(`/api/rewards/by_ids`, dataNames.rewardsInOrder, {failed: 'orders.coupons.failed'}, {ids: missing.rewardIds}));
      }
    }
  }

  if(type === types.ADD_ITEM_SUCCESS && action.name === 'redemption'){
    store.dispatch(change(POS_PAYMENT_FORM, 'points', null)); // Unset points on successful redemption
    store.dispatch(change(POS_PAYMENT_FORM, 'afterSubmit', null)); // Remove the after submit flag
  }

  return result;
};

export default posPaymentForm;
