import {has, pick} from 'lodash';
import get from 'lodash.get';
import moment from 'moment-timezone';
import * as messageTypes from '../../../constants/messageTypes';
import * as itemNames from '../../../constants/itemNames';
import {convertFromBase, getUomType} from '../../../util/uomHelpers';
import {getSaveProductPayload} from '../../../selectors/forms/productFormSelectors';
import * as dataNames from '../../../constants/dataNames';
import * as mappingSelectors from '../../../selectors/PlatformSupplyChainMappingSelectors';
import {convertDbDateToFormInputDate, convertDbTimeToFormInputTime} from '../../../util/dateHelpers';
import {SUPPLY_CHAIN_MAPPING} from '../../../constants/forms';

/***
 * Functions migrated from PlatformSupplyChainMappingPage for reuse in LeafSupplyChainMappingPage to support 'Closed loop' functionality
 */

export const onSubmit = (formValues, props) => {
  const {partners, timezone, compatibleItemMasters, platformIncomingTransferDetails} = props;

  let {purchaseOrderInfo} = props;
  let purchaseOrderPayload = {};

  if (get(formValues, 'auto_generate_po', false) === false && purchaseOrderInfo) {
    purchaseOrderInfo.lines.forEach((line) => {
      if (has(line, 'is_prepack') && has(line, 'product_map') && line.is_prepack) {
        const line_product_map_formValues = get(formValues, line.product_map, []);
        line_product_map_formValues.forEach((item) => {
          Object.assign(item, { unit_price: line.unit_price });
        });
      }
    });
  }

  if (props.canGenerateAutomaticPO) {
    const validateSubcategories = () => {
      let found = true;
      platformIncomingTransferDetails['lines'].forEach((line) => {

        if (found) {
          const categories = props.categories;
          const inventory_item = line.inventory[0];

          const cat = categories.find(category => {
            return category.id === inventory_item['item_master_category_id'];
          });

          found = false;

          if (cat) {
            const subcat = cat['subcategories'].find(subcategory => {
              return subcategory.name.toLowerCase() === inventory_item['item_master_subcategory_name'].toLowerCase();
            });

            if (subcat) {
              found = true;
            }
          }
        }
      });

      return found;
    };

    if (!validateSubcategories()) {
      props.actions.addMessage(messageTypes.error, 'categoryManagement.subCategoryNotFound');
      return false;
    }
  }

  const promises = [];
  const itemMasters = [];
  let transferOrganization = null;

  if (props.canGenerateAutomaticPO) {
    const getPartnerOrganization = () => {
      return new Promise((resolve) => {
        props.actions.getUnpaginatedData('/api/organizations/' + platformIncomingTransferDetails.organization_id, null, itemNames.organization, undefined, {}, {})
          .then((data) => {
            transferOrganization = data;
            resolve();
          });
      });
    };

    promises.push(getPartnerOrganization);
  }

  // Keep track of new item masters that are created
  // {item_master_name: [inventory_item.id, ...]}  e.g. {'Flower-Shake(GR)':, [1000, 1001, ...], 'Flower-Trim(GR)':, [1003]}
  const newItemMasterNames = {};

  // Loop over all transfer lines.
  // Promises are created for all backend calls and executed synchronously later
  //
  //   For lines WITH AUTO PRODUCT MAPPING SELECTED check if there is a match with compatible item_masters
  //     Compatible item masters are all item masters with a matching category, subcategory, UoM and prepack type
  //     A 'match' is valid when the name of the compatible item_master equals to the values from the transfer line:
  //     "{category}-{subcategory}({uom}" or "{category}-{subcategory}({uom} {prepack}" if the transfer line item is a prepack
  //     If there is a match:
  //     - if prepack: add the item_master_parent_id to the list of itemMasters (used to create PO)
  //     - else: add item_master_id to the list of itemsMasters
  //     if there is no match then:
  //     - check if the is an inactive item master that could be a match; if so add a promise to activate it
  //     - check if a similar new item master is already to be created (i.e. for another transfer line with the same cat/subcat/uom/prepack
  //       if so, then register in newItemMasterNames that that item master should be used for this transfer line as well
  //       if not, create a promise to create a new item master and register it in newItemMasterNames
  //         (when this promise eventually gets resolved set the auto product map form value(s) for all transfer lines registered in newItemsMasterNames to the new item_master_id)
  //     If there is a match then:
  //     - set the form value for the auto product map line to the item master id
  //
  //   For lines WITHOUT AUTO PRODUCT MAPPING SELECTED
  //     Create a promise to add the select value of auto product map to the list of itemMasters
  platformIncomingTransferDetails.lines.forEach((line) => {
    const inventory_item = line.inventory[0];

    if (props.canGenerateAutomaticPO && typeof formValues['auto_po_product_map_auto_' + inventory_item['id']] != 'undefined') {

      let name = inventory_item['item_master_category_name'] + '-' + inventory_item['item_master_subcategory_name'] + '(' + line['ordered_uom_display'] + ')';
      if (inventory_item['is_prepack']) {
        name += ' prepack';
      }

      let item_master = null;

      // Filter match function for finding matching item masters
      const findMatchOnItemMasterNameWithoutWeight = (itemMasterOption) => {
        // Item master name should match exactly. Item masters created before with the wrong naming (e.g. Flower-Shake(GR)_20220113132738_0) will not be reused
        // Strip possible prepack weight from inventoryItemMasterName (weight is appended to name with ': ')
        const inventoryItemMasterName = inventory_item['item_master_name'].substring(0, inventory_item['item_master_name'].lastIndexOf(': ') > -1 ? inventory_item['item_master_name'].lastIndexOf(': ') : inventory_item['item_master_name'].length);
        // Strip possible prepack weight from itemMasterOptionName
        const itemMasterOptionName = itemMasterOption['name'].substring(0, itemMasterOption['name'].lastIndexOf(': ') > -1 ? itemMasterOption['name'].lastIndexOf(': ') : itemMasterOption['name'].length);
        return (inventoryItemMasterName === itemMasterOptionName || name === itemMasterOptionName);
      };

      // Check to see if there is a match we can use in the compatibleItemMasters
      if (typeof (compatibleItemMasters[inventory_item['id']]) !== 'undefined') {
        const itemSpecificCompItemMasters = compatibleItemMasters[inventory_item['id']];
        // Names for item masters are unique
        const filteredItemMasters = itemSpecificCompItemMasters.filter(itemMasterOption => {
          return findMatchOnItemMasterNameWithoutWeight(itemMasterOption);
        });

        if (filteredItemMasters.length > 0) {
          item_master = filteredItemMasters[0];
          if (item_master['inventory_attributes'].is_prepack) {
            promises.push(() => {
              return new Promise((resolve) => {
                itemMasters.push(item_master['inventory_attributes'].item_master_parent_id);
                resolve();
              });
            });
          }
          else {
            promises.push(() => {
              return new Promise((resolve) => {
                itemMasters.push(item_master['inventory_attributes'].item_master_id);
                resolve();
              });
            });
          }
        }
      }

      if (!item_master) {
        //check if it is a prepack, and if so get get the prepack size name
        const is_prepack = inventory_item.is_prepack;
        const prepackSize = getPrepackSizeFromInventoryItem(inventory_item);
        // No active item master match found
        // Check if there is an inactive item master or an item master with the incorrect subcategory. If so, activate it or give warning
        const createActivateOrWrongSubcatItemMaster = () => {
          return new Promise((resolve, reject) => {
            // First search if they have the required PP size active.
            // If not activate it and tell them to wait for the system to process then refresh and try again
            if (is_prepack && props.isActiveFacilityLab) {
              checkIfPrepackSizeExistsOrCreate(prepackSize, props);
            }
            //Check if the item master is inactive or the wrong subcat
            props.actions.getSearchData(`/api/search/item_masters?query=${name}`, null, null, null)
              .then((inactiveOrIncorrectSubCatItemMasters) => {
                const inactiveOrIncorrectSubCatItemMaster = inactiveOrIncorrectSubCatItemMasters.filter((im) => {
                  return im.name === name;
                });
                // Inactive or incorrect subcat item master found. Activate it or give warning.
                if (inactiveOrIncorrectSubCatItemMaster.length > 0) {
                  //if subcat is wrong give warning
                  if (inactiveOrIncorrectSubCatItemMaster[0].subcategory_id !== inventory_item.item_master_subcategory_id) {
                    props.actions.addMessage(messageTypes.error, ['products.modify.incorrectSubCat', {name: name, subcat: inventory_item.item_master_subcategory_name, item_number: inactiveOrIncorrectSubCatItemMaster[0].item_number}]);
                    reject();
                  } else {
                  // Active currently inactive item master
                    props.actions.putItem(`/api/item_masters/${inactiveOrIncorrectSubCatItemMaster[0].id}`, {active: 1}, {}, {
                      failed: 'products.modify.failed', success: 'products.modify.success'
                    }, {},{})
                      .then(() => {
                        newItemMasterNames[name].forEach((inventoryItemId) => {
                          formValues['auto_po_product_map_' + inventoryItemId] = inactiveOrIncorrectSubCatItemMaster[0].id;
                          itemMasters.push(inactiveOrIncorrectSubCatItemMaster[0].id);
                        });
                        resolve();
                      });
                  }
                }
                // No active or inactive item master found. Create one
                else {
                  const payload = {
                    inventory_attributes: {
                      lot_tracked: true,
                      multipack_enabled: false,
                      is_prepack: inventory_item['is_prepack']
                    },
                    is_inventory_item: true,
                    category_id: inventory_item['item_master_category_id'],
                    subcategory_id: inventory_item['item_master_subcategory_id'],
                    display_name: name,
                    name: name,
                    uom_type: getUomType(line['ordered_uom_display'])['type'],
                    default_uom: line['ordered_uom_display'],
                    is_sales_item: true,
                  };

                  // determining the sales_attributes is a little lengthier so breaking it out here
                  const UomDisplay = line['ordered_uom_display'];

                  // getUomType returns 'discrete' for EA products which isn't valid for inv_sales_attributes.pricing_types
                  let salesPricingType = 'unit';
                  if (UomDisplay) {
                    salesPricingType = UomDisplay === 'EA' ? 'unit' : getUomType(line['ordered_uom_display'])['type'];
                  }
                  payload.sales_attributes = {pricing_type: salesPricingType };

                  const data = getSaveProductPayload(payload, 'create', props.categories, props.subcategories);
                  data['dispatch_now'] = true;
                  data['is_sales_item'] = false;

                  if (props.integrationState.isMdMetrc || props.integrationState.isOrMetrc) {
                    data['configuration_validated'] = 1; // Because some of metrcMD is breaking fundamental validation
                  }

                  // Add empty price list if no price list exists in payload
                  const hasPriceList = !!get(data, 'pricing_details.price_lists', []).length;
                  if (!hasPriceList) {
                    data['pricing_details'] = {
                      price_lists: [
                        {
                          consumer_type: 'medical',
                          default_price: '0.00',
                          is_default_for_org: 1,
                          inherit_pricing_group_updates: 0,
                          inherit_pricing_class_updates: 0,
                          is_non_taxable: 0,
                          sale_type: 'retail',
                          facility_ids: [],
                          pricing_group_id: null,
                          pricing_class_id: null,
                          weight_prices: [],
                          pricing_type: 'weight'
                        },
                        {
                          consumer_type: 'recreational',
                          default_price: '0.00',
                          is_default_for_org: 1,
                          inherit_pricing_group_updates: 0,
                          inherit_pricing_class_updates: 0,
                          is_non_taxable: 0,
                          sale_type: 'retail',
                          facility_ids: [],
                          pricing_group_id: null,
                          pricing_class_id: null,
                          weight_prices: [],
                          pricing_type: 'weight'
                        },
                        {
                          sale_type: 'wholesale',
                          is_default_for_org: 1,
                          default_price: '0.00',
                          inherit_pricing_group_updates: 0,
                          inherit_pricing_class_updates: 0,
                          is_non_taxable: 0,
                          consumer_type: null,
                          facility_ids: [],
                          pricing_group_id: null,
                          pricing_class_id: null,
                          weight_prices: [],
                          pricing_type: 'weight',
                          pricing_multiplier_id: null
                        }
                      ]
                    };
                  }

                  // No active or inactive item master. Create a new one
                  props.actions.postItem('/api/item_masters', data, itemNames.product, {
                    success: 'products.create.success',
                    failed: 'products.create.failed'
                  }, {}, {})
                    .then((im) => {
                      item_master = im;
                      // Iterate over all inventory items that this item master should be mapped to
                      newItemMasterNames[name].forEach((inventoryItemId) => {
                        formValues['auto_po_product_map_' + inventoryItemId] = item_master['id'];
                        itemMasters.push(item_master['id']);
                      });
                      resolve();
                    });
                }
              });
          });
        };

        // Add the inventory_item['id'] to the list of mapping ids to be set with the new item master id

        // Check if a new compatible item master is already going to be created/activated for another inventory_item
        if (name in newItemMasterNames) {
          // Add this inventory item id to be mapped with a new item master that is already prepared to be created
          newItemMasterNames[name].push(inventory_item['id']);
        } else {
          // If not, create a promise to create a new item master
          newItemMasterNames[name] = [inventory_item['id']];
          promises.push(createActivateOrWrongSubcatItemMaster);
        }
      } else {
        // Match found. use existing item master
        formValues['auto_po_product_map_' + inventory_item['id']] = item_master['id'];
      }
    } else {
      // Manual mapping
      promises.push(() => {
        return new Promise((resolve) => {
          itemMasters.push(formValues['auto_po_product_map_' + inventory_item['id']]);
          resolve();
        });
      });
    }
  });

  const purchaseOrderLines = [];

  const createPurchaseOrderLines = () => {
    return new Promise((resolve) => {
      const promises = [];
      platformIncomingTransferDetails['lines'].forEach((transferLine, index) => {
        const itemMaster = itemMasters[index];

        const line = {
          item_master_id: itemMaster,
          line_item_price: transferLine['line_item_price'],
          qty: convertFromBase(transferLine['ordered_qty_base'], transferLine['ordered_uom_display']),
          unit_price: transferLine['unit_price'],
          uom: transferLine['ordered_uom_display'],
          transfer_line_id: transferLine.id,
          transfer_item_master_id: transferLine.item_master_id
        };

        if (transferLine.inventory[0].is_prepack) {
          const lineInventoryId = get(transferLine, 'inventory.0.id');

          if (typeof formValues[`auto_po_product_map_auto_${lineInventoryId}`] === 'undefined') {
            const selectedItemMaster = (compatibleItemMasters[lineInventoryId] || []).find(compatibleItemMaster => compatibleItemMaster.id === itemMaster);

            if (selectedItemMaster) {
              line['item_master_id'] = get(selectedItemMaster, 'inventory_attributes.item_master_parent_id');

              const subitems = [];
              transferLine.inventory.forEach((inventory) => {
                subitems.push({
                  item_master_id: formValues[`auto_po_product_map_${inventory.id}`],
                  qty: inventory.qty_display,
                  unit_price: inventory.unit_price,
                  transfer_line_inventory_id: inventory.id,
                  transfer_item_master_id: inventory.item_master_id
                });
              });

              line['subitems'] = subitems;
              purchaseOrderLines.push(line);
            }
          } else {
            // Transfer line should be auto product mapped (lab only functionality)
            const getPrepackChildren = () => {
              return new Promise((resolve, reject) => {
                props.actions.getUnpaginatedData('/api/item_masters/children/items?ids[]=' + itemMaster, dataNames.childItemMasters, {}, {}, {})
                  .then((childItemMasters) => {
                    // Get the weight on this inventory
                    const subitems = [];
                    let prepackSizeMissing = false;
                    transferLine.inventory.forEach((inventory) => {

                      // If we already determined a prepack size is missing don't continue this loop (and Reject promise)
                      if (prepackSizeMissing) {
                        return;
                      }

                      //.25g, 1g, 15g, etc.
                      let weight_str = inventory['item_master_name'].substr(inventory['item_master_name'].lastIndexOf(': ') + 1);
                      weight_str = ':' + weight_str;

                      const child_item_master = childItemMasters.filter((cim) => {
                        return cim['name'].indexOf(weight_str) !== -1;
                      });

                      // If the child_item_master for the weight is not found show error and reject promist
                      if (!get(child_item_master, '0')) {
                        props.actions.addMessage(messageTypes.warning, ['supplyChainMapping.form.prePackSizeNotAvailable', {prepackWeight: weight_str}]);
                        props.actions.change(SUPPLY_CHAIN_MAPPING, 'incoming_transfer_id', null);
                        prepackSizeMissing = true;
                        return;
                      }

                      // If child item master is inactive activate it
                      const activateChildItemMaster = () => {
                        return new Promise((resolve) => {
                          props.actions.putItem(`/api/item_masters/${child_item_master[0].id}`, {active: 1}, {}, {
                            failed: 'products.modify.failed', success: 'products.modify.success'
                          }, {},{})
                            .then(() => {
                              resolve();
                            });
                        });
                      };

                      // Check if item master is inactive and create a new promise to active it if so
                      if (!get(child_item_master, '0.active')) {
                        promises.push(activateChildItemMaster);
                      }

                      formValues['auto_po_product_map_' + inventory['id']] = child_item_master[0].id;
                      subitems.push({
                        id: child_item_master[0].id,
                        item_master_id: child_item_master[0].id,
                        qty: inventory.qty_display,
                        unit_price: inventory.unit_price,
                        transfer_line_inventory_id: inventory.id,
                      });
                    });

                    if (prepackSizeMissing) {
                      reject();
                    }

                    line['subitems'] = subitems;
                    purchaseOrderLines.push(line);
                    resolve();
                  });
              });
            };

            promises.push(getPrepackChildren);
          }
        } else {
          const populatePurchaseOrderLine = () => {
            return new Promise((resolve) => {
              purchaseOrderLines.push(line);
              resolve();
            });
          };

          promises.push(populatePurchaseOrderLine);
        }
      });

      promises.reduce(
        (current, next) => {
          return current
            .then(() => next())
            .catch(() => Promise.reject([]));
        },
        Promise.resolve([])
      ).then(() => {
        createPurchaseOrder()
          .then(() => {
            getUpdatePurchaseOrderDetails()
              .then(() => {
                return resolve();
              });
          });
      });
    });
  };

  const createPurchaseOrder = () => {
    return new Promise((resolve) => {
      const partnerMatch = partners.find((partner) => {
        return (partner.connect_organization_code === transferOrganization.organization_code || partner.partner_code === transferOrganization.organization_code);
      });

      const partnerVendor = props.partnerFacilities.find((vendor) => {
        return (vendor.connect_facility_code === platformIncomingTransferDetails.connect_code);
      });

      purchaseOrderPayload = {
        partner_id: partnerMatch.id,
        is_return: platformIncomingTransferDetails.sales_orders[0].sales_order.is_return,
        contains_medicated: true,
        vendor_facility_id: partnerVendor.id,
        lines: purchaseOrderLines,
        date_ordered: moment(new Date()),
        date_expected: moment(new Date()),
        vendor_invoice_number: platformIncomingTransferDetails.sales_orders[0].sales_order.sales_order_number
      };

      props.actions.postItem('/api/purchase_orders', purchaseOrderPayload, itemNames.purchaseOrder, {
        success: 'processing.start.success',
        failed: 'products.start.failed'
      }, {detailed: 1}, {}).then((purchaseOrder) => {
        formValues.purchase_order_id = purchaseOrder.id;
        resolve(purchaseOrder);
      });
    });
  };

  const getUpdatePurchaseOrderDetails = () => {

    const getMappedTransferLine = (purchaseOrderLine, index) => {
      const purchaseOrderPayloadLine = purchaseOrderPayload.lines[index];
      return platformIncomingTransferDetails['lines'].find((transferLine) => transferLine.id === purchaseOrderPayloadLine.transfer_line_id);
    };

    return new Promise((resolve) => {
      props.actions.getItem(`/api/purchase_orders/${formValues.purchase_order_id}`, itemNames.platformPurchaseOrderDetails, {failed: 'supplyChainMapping.internalOrders.get.failed'}, {detailed: 1})
        .then((purchaseOrder) => {
          // The inventory receipt page expects a supply chain mapping as if manually mapped. Construct form fields accordingly
          purchaseOrder.lines.forEach((line, index) => {
            const transfer_line = getMappedTransferLine(line, index);
            if (line.subitems.length > 0) {
              line.subitems.forEach((subitem, subitemsIndex) => {
                // Check if this line of the transfer has an auto_product_map (i.e. auto generate item master (lab only))
                formValues['product_map_' + line.id + '_' + subitem.id] = [...transfer_line.inventory[subitemsIndex]];
                formValues['product_map_' + line.id + '_' + subitem.id][0] = transfer_line.inventory[subitemsIndex];
                formValues['product_map_' + line.id + '_' + subitem.id][0].item_master_id = purchaseOrderLines[index].subitems[subitemsIndex].item_master_id;
              });
            } else {
              // Check if this line of the transfer has an auto_product_map (i.e. auto generate item master (lab only))
              formValues['product_map_' + line.id] = transfer_line.inventory;
              // ensure that each inventory item has the updated item master id
              formValues['product_map_' + line.id].forEach((inventory, inv_index) => {
                formValues['product_map_' + line.id][inv_index].item_master_id = purchaseOrderLines[index].item_master_id;
              });
            }
          });

          // Overwrite purchaseOrderInfo for auto generated PO
          purchaseOrderInfo = {...purchaseOrder, auto_generated: true};
          props.actions.setItem(itemNames.platformPurchaseOrderDetails, purchaseOrderInfo);
          // Since we may have created or activated new item masters, reload all itemMasters for incomingTransfer
          loadItemMasters(platformIncomingTransferDetails, [], props)
            .then(() => {
              resolve(purchaseOrderInfo);
            });
        });
    });
  };

  if (formValues['auto_generate_po']) {
    promises.push(createPurchaseOrderLines);
  }

  // execute Promise chain synchronously
  promises.reduce((current, next) => {
    return current.then(next);
  }, Promise.resolve()).then(() => {
    // Apply mapping to the incoming inventory
    const mappedValues = mappingSelectors.mapItemMasters(formValues, purchaseOrderInfo);

    /**
     * Cherry-pick additional information from the selected incoming transfer for later use in the Inventory Receipt page
     */
    const sales_order = {
      ...pick(platformIncomingTransferDetails.sales_orders[0].sales_order,
        'sales_order_id',
        'title',
        'partner_facility_name',
        'partner_facility_id',
        'sales_order_number',
        'partner_invoice_number'
      )
    };

    const supplyChainMapping = {
      ...mappedValues,

      /**
       * Identify this mapping as a platform mapping for later use in the Inventory Receipt page
       */
      platform_supply_chain_map: true,
      loaded_from_supply_chain_mapping: true,
      platform_incoming_transfer_details: {
        facility_id: get(platformIncomingTransferDetails, 'facility_id', ''),
        transfer_number: get(platformIncomingTransferDetails, 'transfer_number', ''),
        sales_order,
        date_expected: convertDbDateToFormInputDate(platformIncomingTransferDetails.sales_orders[0].sales_order.date_expected, timezone),
        departure_time: convertDbTimeToFormInputTime(platformIncomingTransferDetails.destinations[0].departure_time, timezone),
        arrival_time: convertDbTimeToFormInputTime(platformIncomingTransferDetails.destinations[0].arrival_time, timezone),
      }
    };

    // Save item master mappings, so they can be used for auto mapping future incoming transfers
    // Don't save for labs
    if (props.featureLeafPaSupplyChainAutoMap && !props.isActiveFacilityLab) {
      saveItemMasterMappings(formValues, purchaseOrderInfo, platformIncomingTransferDetails, purchaseOrderPayload, props);
    }

    // save this in the store before re-routing to Inventory Receipt page
    props.actions.setItem(supplyChainMapping, itemNames.supplyChainMapping)
      .then(() => {
        props.actions.push(`/inventory-receipts/create?po_num=${formValues.purchase_order_id}`);
      });
  });
};

const getPrepackSizeFromInventoryItem = (inventory_item) => {
  const is_prepack = inventory_item.is_prepack;
  const item_master_name = inventory_item.item_master_name;
  const parts = is_prepack ? item_master_name.split(':') : '';
  return is_prepack ? parts[parts.length - 1].trim() : '';
};

const loadItemMasters = (incomingTransferDetails, prepack_weight_ids, props) => {
  return new Promise((resolve, reject) => {
    // Determine unique item master subcategories on transfer
    const lines = get(incomingTransferDetails, 'lines');
    const inventory = lines.reduce((inventory, line) => inventory.concat(line.inventory), []);
    const subcategoryIds = [...new Set(inventory.map(({item_master_subcategory_id}) => item_master_subcategory_id))];

    // Load relevant item masters if active facility is a lab (required for automapping products if autogenerate PO is selected)
    // Only query columns that are needed and item master for subcategories that are on the transfer
    const params = {
      select_columns: ['id', 'active', 'item_number', 'name', 'category_id', 'subcategory_id', 'default_uom'],
      active: 1,
      detailed: 0,
      in_subcategory_ids: subcategoryIds,
      with_relations: ['inventoryAttributes', 'category', 'subcategory']
    };
    if (prepack_weight_ids) {
      params.in_prepack_weight_ids = prepack_weight_ids;
    }
    props.actions.getUnpaginatedData('/api/item_masters', dataNames.itemMasters, null, params)
      .then((itemMasters) => {
        resolve(itemMasters);
      }
      ).catch(() => reject());
  });
};

const checkIfPrepackSizeExistsOrCreate = (prepackSize, props) => {
  const thisPrePackSize = props.prepackWeights.filter((pp) => {
    return pp.name === prepackSize;
  });
  if (thisPrePackSize.length === 0) {
    const messages = {success: 'prepackWeights.add.success', failed: 'prepackWeights.add.fail'};
    props.actions.postData('/api/prepack_weights', {weight: parseFloat(prepackSize), uom: 'GR'}, null, messages);
    props.actions.addMessage(messageTypes.warning, ['supplyChainMapping.form.prePackSizeAdded']);
  }
  return false;
};

export const onIncomingTransferChange = (newValue, props, callback) => {
  // Load the product list from the unreceived transfer
  if (newValue && props.selectedIncomingTransfer !== newValue) {

    // Clear all the product maps since we have to start over
    if (props.formValues) {
      Object.keys(props.formValues).map(field => {
        if (field.includes('product_map') || field.includes('auto_product_map')) {
          props.actions.arrayRemoveAll(SUPPLY_CHAIN_MAPPING, field);
        }
      });
    }

    if ( newValue > 0 ) {
      props.actions.getItem(`/api/transfers/incoming/${newValue}`, itemNames.platformIncomingTransferDetails, {failed: 'supplyChainMapping.integratedTransfers.get.failed'})
        .then((platformIncomingTransferDetails) => {
          // Verify prepack sizes. All prepack sizes need to exist
          // Get all PrepackSizes from Transfer
          const transferPrepackSizes = [];
          platformIncomingTransferDetails.lines.forEach((line) => {
            line.inventory.forEach((inventory_item) => {
              const is_prepack = inventory_item.is_prepack;
              if (is_prepack) {
                const prepackSize = getPrepackSizeFromInventoryItem(inventory_item);
                transferPrepackSizes.push(prepackSize);
              }
            });
          });
          // For each size on transfer check if it exists
          if (props.isActiveFacilityLab) {
            transferPrepackSizes.forEach((prepackSize) => {
              checkIfPrepackSizeExistsOrCreate(prepackSize, props);
            });
          }

          fetchUnreceivedPurchaseFromGlobalVendor(get(platformIncomingTransferDetails, 'connect_code'), props);

          const transferPrepackSizeIds = props.prepackWeights.reduce((acc, prepackWeight) => {
            if (transferPrepackSizes.includes(prepackWeight.name)) {
              acc.push(prepackWeight.id);
            }
            return acc;
          }, []);

          loadItemMasters(platformIncomingTransferDetails, transferPrepackSizeIds, props)
            .then(() => {
              if (get(props.formValues, 'auto_generate_po', false)) {
                onAutoGeneratePurchaseOrderChange(platformIncomingTransferDetails, props.partnerFacilities, props.isActiveFacilityLab, {...props});
              }
            })
            .finally(() => {
              callback();
            });
        });
    } else {
      props.actions.unsetItem(itemNames.platformIncomingTransferDetails);
      props.actions.unsetItem(dataNames.platformPurchaseOrders);
    }
  }
};

export const fetchUnreceivedPurchaseFromGlobalVendor = (connect_code, props) => {
  props.actions.getUnpaginatedData(`/api/purchase_orders/vendor/${connect_code}`, dataNames.platformPurchaseOrders, {failed: 'supplyChainMapping.internalOrders.get.failed'}, {received: 0, contains_medicated: 1});
};

export const onUnreceivedPurchaseOrderChange = (newValue, props, callback) => {
  // Load the product list from the unreceived purchase order
  if (newValue && props.selectedPurchaseOrder !== newValue) {

    // Clear all the product maps since we have to start over
    if (props.formValues) {
      Object.keys(props.formValues).map(field => {
        if (field.includes('product_map')) {
          props.actions.arrayRemoveAll(SUPPLY_CHAIN_MAPPING, field);
        }
      });
    }

    if (newValue > 0) { // Construct automappings from Purchase Order
      props.actions.unsetData(dataNames.itemMasters);
      props.actions.getItem(`/api/purchase_orders/${newValue}`, itemNames.platformPurchaseOrderDetails, {failed: 'supplyChainMapping.internalOrders.get.failed'})
        .then((purchaseOrderDetails) => {
          const ids = purchaseOrderDetails['lines'].reduce((ids, line) => {
            //Handle pre-packs
            if (line && line.subitems && line.subitems.length) {
              return line.subitems.reduce(
                (ids, subitem) => (subitem && subitem.qty > 0 ? ids.concat(subitem.item_master_id) : ids),
                ids
              );
            }
            return ids.concat(line.item_master_id);
          }, []);
          if (!ids.length) {
            if (callback) callback(newValue);
            return;
          }
          props.actions.getDataByPost('/api/item_masters/multiple', {ids}, dataNames.itemMasters, {failed: 'supplyChainMapping.itemMasters.get.failed'}, {detailed: 1})
            .then((itemMasters) => {
              if (callback) callback(newValue);

              if (props.featureLeafPaSupplyChainAutoMap) {
                props.actions.getDataByPost('/api/integration/partner_item_master_mapping/by_ids', {ids}, dataNames.itemMasterMappings)
                  .then((itemMasterMappings) => {
                    supplyChainMappingAutoMapValues(purchaseOrderDetails, itemMasters, itemMasterMappings, props);
                  });
              }
            });
        });
    } else {
      props.actions.unsetItem(itemNames.platformPurchaseOrderDetails);
      // Reset purchase_order_id field
      if (callback) callback(null);
    }
  }
};


const saveItemMasterMappings = (formValues, purchaseOrderInfo, platformIncomingTransferDetails, purchaseOrderPayload, props) => {
  const {partnerFacilities} = props;

  const product_mappings = Object.keys(formValues)
    .filter(key => key.startsWith('product_map'))
    .reduce((obj, key) => {
      obj[key] = formValues[key];
      return obj;
    }, {});

  const getItemMasterMappingPayload = () => {

    const getPartnerFacilityId = () => {
      const partnerFacility = partnerFacilities.find((vendor) => {
        return (vendor.connect_facility_code === platformIncomingTransferDetails.connect_code);
      });
      return partnerFacility.id;
    };

    const excludeSelfMapsAndMissingIds = (mapping) => {
      return mapping.item_master_id !== mapping.partner_item_master_id && mapping.partner_item_master_id !== 0;
    };

    if (get(formValues, 'auto_generate_po', false)) {
      // Generate itemMasterMappingPayload from (auto)mapping and transfer
      return {
        partner_facility_id: getPartnerFacilityId(),
        item_master_mappings: get(purchaseOrderPayload, 'lines', [])
          .reduce((acc, line) => {
            if (has(line, 'subitems')) {
              line.subitems.forEach((subitem) => {
                acc.push({
                  item_master_id: subitem.item_master_id,
                  partner_item_master_id: subitem.transfer_item_master_id
                });
              });
            } else {
              acc.push({
                item_master_id: line.item_master_id,
                partner_item_master_id: line.transfer_item_master_id
              });
            }
            return acc;
          }, [])
          .filter(excludeSelfMapsAndMissingIds)
          .flat()
      };
    }
    // Generate itemMasterMappingPayload from mapping and purchase order
    const poLines = get(purchaseOrderInfo, 'lines');
    if (!poLines) {
      return {};
    }
    return {
      partner_facility_id: getPartnerFacilityId(),          // Used for new transfers
      //item_master_mappings: Object.values(product_mappings).reduce((acc, mapping) => {
      item_master_mappings: Object.entries(product_mappings).reduce((acc, mapping) => {
        const key = mapping[0].replace('product_map_','');
        const value = mapping[1];
        if (!key) {
          return acc;
        }
        const poLine = poLines.find(line => {
          return (line.line_id).toString() === key;
        });
        if (poLine) {
          acc.push({
            item_master_id: poLine.item_master_id,
            partner_item_master_id: value[0].item_master_id
          });
        }
        return acc;
      }, [])
        .filter(excludeSelfMapsAndMissingIds)
    };
  };

   // Same org mappings aren't needed; in that case we have a mappings length of 0 and skip this.
  const itemMasterMappingPayload = getItemMasterMappingPayload();
  if (get(itemMasterMappingPayload, 'item_master_mappings', []).length) {
    props.actions.postData('/api/integration/partner_item_master_mapping', itemMasterMappingPayload);
  }
};

export const onAutoGeneratePurchaseOrderChange = (incomingTransferDetails, partnerFacilities, isActiveFacilityLab, props) => {
  // Only try to automap if FT enabled, not a lab and required data available
  if (!props.featureLeafPaSupplyChainAutoMap || isActiveFacilityLab || !incomingTransferDetails || !partnerFacilities) {
    return;
  }

  // Determine partnerFacilityId
  const partner_facility_id = get(partnerFacilities.find((pf) => pf.facility_id === incomingTransferDetails.facility_id), 'id');
  const lines = get(incomingTransferDetails, 'lines', []);
  const partner_item_master_ids = lines.map((line) => line.inventory.map((inventory) => inventory.item_master_id)).flat();

  // Get all item master mappings for the inner organization facility transfer
  lines.map((line) => {
    // Loop over inventory for line
    line.inventory.map((inventory) => {
      // Find item master
      const partner_item_master_id = inventory.item_master_id;
      const field_name = `auto_po_product_map_${inventory.id}`;
      props.actions.change(SUPPLY_CHAIN_MAPPING, field_name, partner_item_master_id);
    });
  });


  // Get all item master mappings for the cross organization facility transfer
  props.actions.getDataByPost('/api/integration/partner_item_master_mapping/by_partner_item_master_ids', {item_master_ids: partner_item_master_ids, partner_facility_id: partner_facility_id}, null)
    .then((itemMasterMappings) => {
      // Retrieve itemMasters - We do this to make sure the item master is still an active products
      const item_master_ids = itemMasterMappings.map((imm) => imm.item_master_id);
      props.actions.getDataByPost('/api/item_masters/multiple', {ids: item_master_ids}, null, {failed: 'supplyChainMapping.itemMasters.get.failed'}, {active: 1})
        .then((itemMasters) => {
          lines.map((line) => {
            // Loop over inventory for line
            line.inventory.map((inventory) => {
              // Find item master
              const partner_item_master_id = inventory.item_master_id;
              const item_master_mapping = itemMasterMappings.find((imm) => imm.partner_item_master_id === partner_item_master_id);
              if (item_master_mapping) {
                const item_master_id = item_master_mapping.item_master_id;
                const item_master = itemMasters.find((im) => im.id === item_master_id);
                const field_name = `auto_po_product_map_${inventory.id}`;
                if (item_master) {
                  props.actions.change(SUPPLY_CHAIN_MAPPING, field_name, item_master_id);
                }
              }
            });
          });

        });
    });
};

const supplyChainMappingAutoMapValues = (purchaseOrderDetails, itemMasters, itemMasterMappings, props) => {

  const orderLines = purchaseOrderDetails.lines || [];

  const lines = orderLines.reduce(
    (lines, orderLine) => {
      if (orderLine && orderLine.subitems && orderLine.subitems.length) {
        return orderLine.subitems.reduce(
          (lines, subItem) => {
            if (subItem.qty > 0) {
              const potentialPackages = mappingSelectors.getPreviouslyMappedPackagesForItemMaster(subItem.item_master_id, itemMasterMappings, props.incomingTransferProductsOptions);
              return lines.concat({
                field_name: `product_map_${orderLine.id}_${subItem.id}`,
                inventory: mappingSelectors.getInventoryPackages(potentialPackages, subItem.qty),
                po_unit_price: subItem.unit_price
              });
            }
            return lines;
          },
          lines
        );
      }
      const potentialPackages = mappingSelectors.getPreviouslyMappedPackagesForItemMaster(orderLine.item_master_id, itemMasterMappings, props.incomingTransferProductsOptions);
      return lines.concat({
        field_name: `product_map_${orderLine.id}`,
        inventory: mappingSelectors.getInventoryPackages(potentialPackages, orderLine.qty),
        // CORE-662: passing the purchase order unit price to lines to override the inventory unit price from sales order.
        po_unit_price: orderLine.unit_price
      });
    },
    []
  );

  lines.forEach(line => {
    line.inventory.forEach((inventory, idx) => {
      props.actions.arrayPush(SUPPLY_CHAIN_MAPPING, `${line.field_name}`, inventory);
      props.actions.change(SUPPLY_CHAIN_MAPPING, `${line.field_name}[${idx}].item_master_name`, inventory.item_master_name);
      props.actions.change(SUPPLY_CHAIN_MAPPING, `${line.field_name}[${idx}].package_code`, inventory.package_code);
      props.actions.change(SUPPLY_CHAIN_MAPPING, `${line.field_name}[${idx}].qty_display`, inventory.qty_display);
      // CORE-662: passing the purchase order unit price to lines to override the inventory unit price from sales order.
      props.actions.change(SUPPLY_CHAIN_MAPPING, `${line.field_name}[${idx}].unit_price`, line.po_unit_price ? line.po_unit_price : inventory.unit_price);
    });
  });

};
