import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ContentConcealer from './ContentConcealer';

function mapStateToProps(state, ownProps) {
  return {
    show: ownProps.predicate(ownProps.selector(state, ownProps)),
  };
}

const PredicateConcealer = connect(mapStateToProps)(ContentConcealer);

PredicateConcealer.propTypes = {
  force: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
  ]),
  alternateComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
  ]),
};

export default PredicateConcealer;
