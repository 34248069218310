import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { Button, Col, Row } from 'react-bootstrap';
import { Field } from 'redux-form';

import ReactSelectInput from '../../common/form/ReactSelectInput';
import ContentConcealer from '../../common/concealers/ContentConcealer';
import PlantComplianceOnhandSettingsBlock from './PlantComplianceOnhandSettingsBlock';

const options = [
  { text: I18n.t('cultivation.complianceSettings.warn'), value: 'warn' },
  { text: I18n.t('cultivation.complianceSettings.block'), value: 'block' }
];

const yesNo = [
  { text: I18n.t('cultivation.complianceSettings.yes'), value: true },
  { text: I18n.t('cultivation.complianceSettings.no'), value: false }
];

const PlantComplianceSettingsForm = ({
  handleSubmit,
  valid,
  submitting,
  pristine,
  isNormal,
  trackPlantsAsGroup,
  isMetrc,
  isBiotrack,
  isWaLeaf,
  isColombia,
  isHemp,
  isPlantBatchTestingEnabled,
  isPendingStatesForHarvestBatchesEnabled,
  cultCreatePlantFromInventoryIsOverridable
}) => (
  <form onSubmit={handleSubmit} className='alert-settings-page sales-settings-formcd .'>
    <Row>
      <Col xs={12} md={{span: 8, offset:2}} lg={{span: 6, offset:3}}>
        <div className='alert-info' style={{ padding: '5px', textAlign: 'center', fontWeight: 'bold' }}>
          {I18n.t('cultivation.complianceSettings.zeroHint')}
        </div>
        <table className='table table-striped table-bordered'>
          <thead>
            <tr>
              <th>{I18n.t('cultivation.complianceSettings.tableRuleHeading')}</th>
              <th style={{ width: '25%' }}>{I18n.t('cultivation.complianceSettings.tableValueHeading')}</th>
            </tr>
          </thead>
          <tbody>
            <PlantComplianceOnhandSettingsBlock
              options={options}
              trackPlantsAsGroup={trackPlantsAsGroup}
              disabled={isColombia}
            />
            <tr>
              <td>{I18n.t('cultivation.complianceSettings.ruleAllowWeightsDistributed')}</td>
              <td>
                <Field
                  name='cult_distribute_harvest_weights'
                  clearable={false}
                  component={ReactSelectInput}
                  props={{ options: yesNo }}
                />
              </td>
            </tr>
            <tr>
              <td>{I18n.t('cultivation.complianceSettings.ruleCreatePlantFromInventory')}</td>
              <td>
                <Field
                  name='cult_create_plant_from_inventory'
                  clearable={false}
                  component={ReactSelectInput}
                  props={{ options: yesNo }}
                  disabled={isMetrc || isBiotrack || isWaLeaf || !cultCreatePlantFromInventoryIsOverridable}
                />
              </td>
            </tr>
            <ContentConcealer show={isNormal}>
              <tr>
                <td>{I18n.t('cultivation.complianceSettings.trackPlantsAsGroups')}</td>
                <td>
                  <Field
                    name='cult_track_plants_as_groups'
                    clearable={false}
                    component={ReactSelectInput}
                    props={{ options: yesNo }}
                  />
                </td>
              </tr>
            </ContentConcealer>
            <ContentConcealer show={isHemp && isPlantBatchTestingEnabled}>
              <tr>
                <td>{I18n.t('cultivation.complianceSettings.addTestResultsToPlantGroups')}</td>
                <td>
                  <Field
                    name='cult_add_test_results_to_plant_groups'
                    clearable={false}
                    component={ReactSelectInput}
                    props={{ options: yesNo }}
                    value={1}
                  />
                </td>
              </tr>
            </ContentConcealer>
            <ContentConcealer show={isMetrc && isPendingStatesForHarvestBatchesEnabled}>
              <tr>
                <td>{I18n.t('cultivation.complianceSettings.harvestBatchEntityLockSetting')}</td>
                <td>
                  <Field
                    name='cult_enable_harvest_batch_entity_locks'
                    clearable={false}
                    component={ReactSelectInput}
                    props={{ options: yesNo }}
                  />
                  <div>
                    {I18n.t('cultivation.complianceSettings.harvestBatchEntityLockMessage')}
                  </div>
                </td>
              </tr>
            </ContentConcealer>
          </tbody>
        </table>

        <Button variant='primary' type='submit' className='float-right' disabled={submitting || pristine || !valid}>
          {I18n.t('common.form.save')}
        </Button>
      </Col>
    </Row>
  </form>
);

PlantComplianceSettingsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  isNormal: PropTypes.bool.isRequired,
  isMetrc: PropTypes.bool.isRequired,
  isBiotrack: PropTypes.bool.isRequired,
  cultCreatePlantFromInventoryIsOverridable: PropTypes.bool.isRequired,
  trackPlantsAsGroup: PropTypes.bool.isRequired,
  isWaLeaf: PropTypes.bool,
  isColombia: PropTypes.bool,
  isHemp: PropTypes.bool,
  isPlantBatchTestingEnabled: PropTypes.bool,
  isPendingStatesForHarvestBatchesEnabled: PropTypes.bool
};

export default PlantComplianceSettingsForm;
