import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Field } from 'redux-form';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import CheckBoxInput from '../../../common/form/InlineCheckBox';
import MultiselectInput from '../../../common/form/MultiselectInput';
import HoverTooltip from '../../../common/HoverTooltip';
import * as p from '../../../../constants/permissions';
import PermissionButton from '../../../common/PermissionButton';

const ModalityRow = ({ fields, strains, isEditMode }) => {
  return (
    <div>
      <Row style={{ fontWeight: 'bold' }}>
        <Col offset={4} xs={4}>
          <div>{I18n.t('common.strain')}</div>
        </Col>
      </Row>
      {fields.map((line, index) => {
        const modality = fields.get(index);
        const disabled = !modality.selected;
        const disableManageSettings =
          !isEditMode || get(modality, 'strains', []).length === 0 || !modality.modality || !modality.cupo_id;
        return (
          <Row key={index}>
            <Col xs={4}>
              <Field
                name={`${line}.selected`}
                component={CheckBoxInput}
                props={{
                  label: I18n.t('modalities.list.' + modality.key)
                }}
              />
            </Col>
            <Col xs={4}>
              <Field
                name={`${line}.strains`}
                component={MultiselectInput}
                props={{
                  options: strains,
                  textKey: 'strain_name',
                  valueKey: 'id',
                  disabled
                }}
              />
            </Col>
            <Col xs={4}>
              <PermissionButton
                permissions={[p.manage_compliance_settings]}
                props={{
                  disabled: disableManageSettings,
                  href: `/settings/cupos/${modality.cupo_id}/modality/${modality.modality}`
                }}
              >
                {I18n.t('cupos.manageSettings')}
              </PermissionButton>
              <HoverTooltip
                text={'You can manage cupo settings only after creating a cupo. Please click Save button below'}
                id={'cupo' + index}
                type={'warning'}
              />
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

ModalityRow.propTypes = {
  isEditMode: PropTypes.bool,
  fields: PropTypes.object,
  strains: PropTypes.array,
};

export default ModalityRow;
