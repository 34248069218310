import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {bindActionCreators} from 'redux';

import * as dataNames from '../../../constants/dataNames';
import InProgressOverlay from '../../common/InProgressOverlay';

import {unsetData} from '../../../actions/dataActions';
import {PRODUCT_MANAGEMENT_FORM} from '../../../constants/forms';
import ProductsFormWrapper from './ProductsFormWrapper';
import {getSearchData} from '../../../actions/apiActions';
import {fetchItemMastersFromSolr} from '../../../actions/itemMasterActions';
import {
  fetchMetrcItems,
  fetchMetrcProductsMapping,
  fetchMetrcCategoryMapping
} from '../../../actions/integrations/metrc';
import FormWrapper from '../../common/form/FormWrapper';


export class ProductManagementPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: false,
      progressMessage: I18n.t('inProgressOverlay.message'),
    };
    this.setProduct = this.setProduct.bind(this);
    this.clearDataName = this.clearDataName.bind(this);
    this.onListenVisibilityChange = this.onListenVisibilityChange.bind(this);
  }

  onListenVisibilityChange() {
    if (!document.hidden) {
      this.setProduct();
    }
  }

  componentWillMount() {
    this.setProduct();
    document.addEventListener('visibilitychange', this.onListenVisibilityChange);
  }

  setProduct() {
    this.clearDataName();
    this.setState({loading: true});

    const search_params = {
      fields: 'id, category_code, name, strain_name, active, subcategory_id',
      sort: 'name asc',
      query: 'matchall',
      size: '100000',
      start: '0',
      filter: ' -subcategory_code: "WASTE" AND active: 1 AND item_master_parent_id:0'
    };

    Promise.all([
      this.props.actions.fetchItemMastersFromSolr(search_params),
      this.props.actions.fetchMetrcItems(),
      this.props.actions.fetchMetrcProductsMapping(),
      this.props.actions.fetchMetrcCategoryMapping()
    ]).then(() => this.setState({loading: false}))
      .catch(() => this.setState({loading: false}));
  }

  clearDataName() {
    unsetData(dataNames.products);
    unsetData(dataNames.metrcProducts);
    unsetData(dataNames.metrcProductsMapping);
    unsetData(dataNames.metrcCategoryMappings);
  }

  componentWillUnmount() {
    this.clearDataName();
  }

  render() {
    const {mjpProducts, integratorProducts, initialValues} = this.props;
    const {loadingProducts} = this.state;
    return (
      <div className='product-management-page'>
        <InProgressOverlay isActive={this.state.loading} message={this.state.progressMessage}/>
        <FormWrapper title={'METRCProductManagement.title'}
                     titleDescription={I18n.t('METRCProductManagement.description')} goBack={this.props.actions.goBack}>
          <ProductsFormWrapper
            form={PRODUCT_MANAGEMENT_FORM}
            mjpProducts={mjpProducts}
            integratorProducts={integratorProducts}
            isLoading={loadingProducts}
            initialValues={initialValues}
          />
        </FormWrapper>
      </div>
    );
  }
}

ProductManagementPage.defaultProps = {
  params: {
    integration: null,
  }
};

ProductManagementPage.propTypes = {
  actions: PropTypes.shape({
    unsetData: PropTypes.func,
    getSearchData: PropTypes.func,
  }),
  initialValues: PropTypes.object,
  params: PropTypes.shape({
    integration: PropTypes.string
  }),
};


function mapDispatchToProps(dispatch) {
  const actions = {
    unsetData,
    getSearchData,
    fetchItemMastersFromSolr,
    fetchMetrcItems,
    fetchMetrcProductsMapping,
    fetchMetrcCategoryMapping
  };
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(null, mapDispatchToProps)(ProductManagementPage);
