/* eslint-disable import/no-mutable-exports*/
import {connect} from 'react-redux';
import {reduxForm, getFormValues, formValueSelector} from 'redux-form';
import { MODIFY_PLANT_FORM } from '../../../constants/forms';
import validate from '../common/validateModify';
import ModifyPlantForm from './ModifyPlantForm';
import {getFilteredFeedingSchedules} from '../../../selectors/feedingSchedulesSelectors';

export const ModifyPlantFormWrapper = reduxForm({
  form: MODIFY_PLANT_FORM,
  validate}
)(ModifyPlantForm);

const selector = formValueSelector(MODIFY_PLANT_FORM);
export default connect((state, ownProps) => {
  const location_id = selector(state, 'location_id');
  const section_id = selector(state, 'section_id');
  const selectedPlants = selector(state, 'selectedPlants') || [];
  const plantStage = selector(state, 'stage_id');
  const feeding_schedule_id = selector(state, 'feeding_schedule_id');
  const selectedBatchName = selector(state, 'selected_batch_name');
  const maxPlants = selector(state, 'max_plants');
  const currentlySelectedLocationIds = ['building_ids', 'room_ids', 'zone_ids', 'section_ids'].reduce((acc, field) => {
    const formField = (field === 'section_ids') ? 'location_id' : field;
    acc[field] = selector(state, formField);
    return acc;
  }, {});

  const filters = {};
  let selectableLocations = ownProps.selectableLocations;
  if(section_id) {
    filters.location_id = section_id;
  }else if(location_id && location_id.length){
    filters.location_id = location_id.map(location => location.id);
  }else if(selectedPlants){
    filters.location_ids = selectedPlants.reduce(
      (acc, value) => acc.indexOf(value.location_id) == -1 ? [...acc, value.location_id] : acc,
      []
    );
  }
  if(plantStage) {
    filters.stage_id = plantStage;
  }else if(ownProps.currentStage){
    filters.stage_id = ownProps.currentStage;
  }
  const currentStage = ownProps.stages.find(stage => stage.id === plantStage);
  const schedules = getFilteredFeedingSchedules(state, {filters});

  if(feeding_schedule_id){
    const schedule = schedules.find(sch => sch.id == feeding_schedule_id);
    selectableLocations = ownProps.selectableLocations.filter(location => {
      if(schedule){
        return Boolean(schedule.locations.find(loc => loc.location_id == location.id));
      }else{
        return true;
      }
    });
  }
  return {
    currentlySelectedLocationIds,
    selectedBatchName,
    location_id: [location_id],
    selectedPlants,
    plantStage,
    schedules,
    selectableLocations,
    formValues: getFormValues(MODIFY_PLANT_FORM)(state) || {},
    currentStageCode: currentStage && currentStage.code,
    maxPlants,
  };
})(ModifyPlantFormWrapper);
