import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';
import * as actionDefs from './actionDefinitions';
import {
  getCommonInventoryColumns,
  getActiveInventoryColumns,
  getCommonIngredientColumns,
  getInactiveInventoryColumns
} from './serviceFirstColumnDefinitions';
import {isActiveFacilityDispensary, isActiveFacilityGrow, isActiveFacilityLab} from '../../../selectors/facilitiesSelectors';
import {getModulesState} from '../../../selectors/modulesSelectors';
import {isWasteDisposalEnabledSelector} from '../../../selectors/compliance/waste/wasteComplianceSelectors';

const isRegularInventory = (component) => {
  const {props: {state}} = component;
  return !getModulesState(state).hasManufacturing;
};
const isEIInventory = (component) => {
  const {props: {state}} = component;
  return getModulesState(state).hasManufacturing;
};
const isWasteEnabled = (component) => {
  const {props: {state}} = component;
  return isWasteDisposalEnabledSelector(state);
};
const isIngredientInventoryEnabled = (component) => {
  const {props: {state}} = component;
  return !isActiveFacilityDispensary(state);
};

const isGrowInventory = (component) => {
  const {props: {state}} = component;
  return isActiveFacilityGrow(state);
};

const isLab = (component) => {
  const {props: {state}} = component;
  return isActiveFacilityLab(state);
};

const configureActions = actions => component => actions.map(action => action(component)).filter(Boolean);
const configureColumns = columns => component => typeof columns === 'function' ? columns(component) : columns;

const buildFilter = (component, {active = 1, phase_id = 0, is_waste = 0, is_ingredient = 0, archiveInactiveAfter = 0}) => {
  if (component.props.isListingSourceService) {
    return {
      active,
      is_waste,
      is_ingredient,
      manufacturing_phase_id: phase_id ? phase_id : undefined,
    };
  }

  let activePredicate;
  if (is_ingredient) {
    activePredicate = `active:${active}`;
  } else {
    activePredicate = active ? 'active:1' : '(package_active:0 || active:0)';
  }

  const wastePredicate = `${is_waste ? '' : '-'}is_waste:1`;
  const ingredientPredicate = `is_ingredient:${is_ingredient}`;
  const phaseStatement = phase_id ? `AND phase_id:${phase_id}` : '';
  const archiveStatement = archiveInactiveAfter > 0 ? `AND updated_at: [NOW-${archiveInactiveAfter}DAY TO NOW]` : '';

  return `${activePredicate} AND ${ingredientPredicate} AND ${wastePredicate} ${phaseStatement} ${archiveStatement}`;
};

const buildChildFilter = ({active = 1, phase_id = 0, is_waste = 0, is_ingredient = 0}) => {
  let activePredicate;
  if (is_ingredient) {
    activePredicate = `active:${active}`;
  } else {
    activePredicate = active ? 'active:1' : '(package_active:0 || active:0)';
  }

  const wastePredicate = `${is_waste ? '' : '-'}is_waste:1`;
  const ingredientPredicate = `is_ingredient:${is_ingredient}`;
  const phaseStatement = phase_id ? `AND phase_id:${phase_id}` : '';

  return `(${activePredicate} AND ${ingredientPredicate} AND ${wastePredicate} ${phaseStatement})`;
};

/***
 * Component used to access the props which gets us the condition if its a string.  Position can be negative to start
 * from left for the insert position.
 * @param component
 * @param actions
 * @param action
 * @param condition
 * @param position
 * @returns {*}
 */
const addAction = (component, actions, action, condition, position) => {

  const spliceMethod = () => {
    return actions.splice(position, 0, action);
  };

  if(typeof condition === 'string'){
    if(get(component, `props.${condition}`)){
      return spliceMethod();
    }
  }

  if(typeof condition === 'function'){
    if(condition(component, actions, action)){
      return spliceMethod();
    }
  }

  return actions; // Not necessarily used since splice operates in place on the array
};

const showInternalTransfers = (component) => {
  const integrationState = get(component, 'props.integrationState', {});
  const {isLeaf, isBiotrack, isMetrc} = integrationState;
  return !isLeaf && !isBiotrack && !isMetrc;
};

const getActiveInventoryTab = (component) => {

  const actions = [
    actionDefs.modifyLot,
    actionDefs.splitPackage,
    actionDefs.destroyItems,
  ];
  const isWaLeaf = get(component, 'props.integrationState.isWaLeaf');
  const isPaLeaf = get(component, 'props.integrationState.isPaLeaf');

  addAction(component, actions, actionDefs.addTestResult, () => !isPaLeaf || isLab, 1);
  addAction(component, actions, actionDefs.createPropagationMaterial, () => isWaLeaf && isGrowInventory(component), 3);
  addAction(component, actions, actionDefs.releaseAllReservations, 'canUserManageReservationAndIsAllowedInComplianceSettings', 4);

  if(showInternalTransfers(component)) {
    addAction(component, actions, actionDefs.showTransferModal, 'canManageInternalTransfers', -1);
  }

  return ({
    id: 'activeFinishedProductsTab',
    eventKey: 'active',
    title: 'cultivation.finishedProduct.nav.active',
    actions,
    columns: getActiveInventoryColumns,
    groupField: 'group_id',
    filter: buildFilter(component, {}),
    childrenFilter: buildChildFilter({}),
    visible: isRegularInventory,
  });
};

const getManufacturingTabs = (component) => {
  const phases = component.props.phases || [];
  const columns = getActiveInventoryColumns(component);

  const actions = [
    actionDefs.startPackagingJob,
    actionDefs.startProcessing,
    actionDefs.startInfusion,
    actionDefs.modifyLot,
    actionDefs.splitPackage,
    actionDefs.destroyItems,
  ];

  const isPaLeaf = get(component, 'props.integrationState.isPaLeaf');
  addAction(component, actions, actionDefs.addTestResult, () => !isPaLeaf || isLab, 3);

  addAction(component, actions, actionDefs.releaseAllReservations, 'canUserManageReservationAndIsAllowedInComplianceSettings', 3);
  if(showInternalTransfers(component)) {
    addAction(component, actions, actionDefs.showTransferModal, 'canManageInternalTransfers', -1);
  }

  return phases.map(phase => ({
    id: `${phase.phase_code}InventoryTab`,
    eventKey: phase.phase_code,
    title: `ei.inventory.tabs.${phase.phase_code}`,
    actions,
    columns,
    groupField: 'group_id_on_lot_number',
    filter: buildFilter(component, {phase_id: phase.id}),
    childrenFilter: buildChildFilter({phase_id: phase.id}),
    visible: isEIInventory,
  }));
};
const getInactiveInventoryTab = (component) => {
  const archiveInactiveAfter = get(component, 'props.archiveInactiveAfter');
  return {
    id: 'inactiveFinishedProductsTab',
    eventKey: 'inactive',
    title: 'cultivation.finishedProduct.nav.inactive',
    description: archiveInactiveAfter > 0 ? I18n.t('cultivation.finishedProduct.archivedInventoryDescription', {days: archiveInactiveAfter}) : undefined,
    actions: [
      actionDefs.activateProducts,
    ],
    columns: getInactiveInventoryColumns,
    groupField: component._componentType === 'EIInventory' ? 'group_id_on_lot_number' : 'group_id',
    filter: buildFilter(component, {active: 0, archiveInactiveAfter}),
    childrenFilter: buildChildFilter({active: 0}),
    visible: () => true,
  };
};

const getActiveWasteTab = (component) => ({
  id: 'activeWasteTab',
  eventKey: 'waste',
  title: 'cultivation.finishedProduct.nav.waste',
  actions: component.props.integrationState.isWaLeaf === false
    ? [actionDefs.modifyLot, actionDefs.reportDailyWaste]
    : [actionDefs.reportDailyWaste, actionDefs.destroyWaste],
  columns: getActiveInventoryColumns,
  groupField: component._componentType === 'EIInventory' ? 'group_id_on_lot_number' : 'group_id',
  filter: buildFilter(component, {is_waste: 1}),
  childrenFilter: buildChildFilter({is_waste: 1}),
  visible: isWasteEnabled,
});

const getInactiveWasteTab = (component) => ({
  id: 'inactiveWasteTab',
  eventKey: 'inactive-waste',
  title: 'cultivation.finishedProduct.nav.inactiveWaste',
  actions: component.props.integrationState.isWaLeaf == false ? [actionDefs.activateProducts] : [],
  columns: getCommonInventoryColumns,
  groupField: component._componentType === 'EIInventory' ? 'group_id_on_lot_number' : 'group_id',
  filter: buildFilter(component, {active: 0, is_waste: 1}),
  childrenFilter: buildChildFilter({active: 0, is_waste: 1}),
  visible: isWasteEnabled,
});

const actions = [];

const activeIngredientsTab = (component) => ({
  id: 'activeIngredientsInventoryTab',
  eventKey: 'ingredients/active',
  title: 'ei.inventory.tabs.activeIngredients',
  actions: null, // populated in the getTabs function to use component props
  columns: getCommonIngredientColumns,
  groupField: 'group_id',
  filter: buildFilter(component, {is_ingredient: 1}),
  childrenFilter: buildChildFilter({is_ingredient: 1}),
  visible: isIngredientInventoryEnabled,
});

const inactiveIngredientsTab = (component) => ({
  id: 'inactiveIngredientsInventoryTab',
  eventKey: 'ingredients/inactive',
  title: 'ei.inventory.tabs.inactiveIngredients',
  actions: [],
  columns: getCommonIngredientColumns,
  groupField: 'group_id',
  filter: buildFilter(component, {active: 0, is_ingredient: 1}),
  childrenFilter: buildChildFilter({active: 0, is_ingredient: 1}),
  visible: isIngredientInventoryEnabled,
});


export const getTabs = (component) => {


  const includeInternalTransfers = get(component, 'props.canManageInternalTransfers') && showInternalTransfers(component);

  const activeIngTab = Object.assign({}, activeIngredientsTab(component), {
    actions: includeInternalTransfers ? [actionDefs.showTransferModal].concat(actions) : actions
  });

  const tabs = [
    getActiveInventoryTab(component),
    ...getManufacturingTabs(component),
    getInactiveInventoryTab(component),
    getActiveWasteTab(component),
    getInactiveWasteTab(component),
    activeIngTab,
    inactiveIngredientsTab(component),
  ];

  return tabs.reduce(
    (acc, {visible, actions, columns, ...tab}) => {
      if (visible(component)) {
        return acc.concat({
          ...tab,
          actions: configureActions(actions)(component),
          columns: configureColumns(columns)(component),
        });
      }
      return acc;
    },
    []
  );
};

export const dummy = () => {};
