import { I18n } from 'react-redux-i18n';
import { requiredFieldValidation, maxValidation } from '../../common/form/redux-form/validations';
import { getSettingValue } from '../../../util/settingsHelpers';

const validate = (values, props) => {
  const errors = {};
  const trackPlantsToGroup = getSettingValue('cult_track_plants_as_groups')(props.complianceSettings);
  const createPlantFromInventory = getSettingValue('cult_create_plant_from_inventory')(props.complianceSettings);
  if (!values.strain_id) {
    errors.strain_id = I18n.t('plants.form.selectStrainError');
  }
  errors.planting_date = requiredFieldValidation(values.planting_date, 'plants.form.plantingDateError');
  if (!values.starting_section_id) {
    errors.starting_section_id = I18n.t('plants.form.selectLocationError');
  }
  if (!values.starting_stage_id) {
    errors.starting_stage_id = I18n.t('plants.form.selectGrowthPhaseError');
  }
  if (!values.starting_type) {
    errors.starting_type = I18n.t('plants.form.plantTypeError');
  }
  if (!values.starting_qty) {
    errors.starting_qty = I18n.t('plants.form.enterQuantityError');
  } else if (props.integrationState.isCaMetrc && values.starting_qty > 100) {
    errors.starting_qty = I18n.t('plants.form.mustBeSmallEnoughErrorMetrcCa');
  } else if (!Number.isInteger(Number(values.starting_qty))) {
    errors.starting_qty = I18n.t('plants.form.mustBeIntegerError');
  } else if (trackPlantsToGroup && values.starting_qty > 9999999) {
    errors.starting_qty = I18n.t('plants.form.mustBeSmallEnoughErrorForGroup');
  } else if (!trackPlantsToGroup && values.starting_qty > 9999) {
    errors.starting_qty = I18n.t('plants.form.mustBeSmallEnoughError');
  } else if (values.starting_qty < 0) {
    errors.starting_qty = I18n.t('plants.form.mustBePositiveError');
  }
  if (props.integrationState.isBiotrack || props.integrationState.isWaLeaf) {
    errors.source_item_id = requiredFieldValidation(values.source_item_id);
  }
  if (createPlantFromInventory) {
    if (values.source_item_id && values.starting_qty) {
      errors.starting_qty = maxValidation(values.starting_qty, 'plants.form.quantity', values.source_item_qty || 0);
    }
  }
  // if (!values.starting_feeding_schedule_id) {
  //   errors.starting_feeding_schedule_id = I18n.t('plants.form.selectFeedingScheduleError');
  // }
  if (props.integrationState.isCaMetrc) {
    errors.batch_tracking_id = requiredFieldValidation(values.batch_tracking_id, 'plants.modify.trackingId');
  }
  if (props.integrationState.isColombia && values.cupo_id && !values.modality) {
    errors.modality = I18n.t('plants.form.modalityRequired');
  }
  return errors;
};

export default validate;
