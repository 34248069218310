import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import AnalyticsFilterForm from './AnalyticsFilter'; //eslint-disable-line
import { getItem } from '../../actions/apiActions';
import {DOMO_EMBED_HOST} from '../../constants/reports';
import { resizeIframe } from './helpers';

class AnalyticsViewPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      name: '',
      facilities: [],
      selectedFacilityTypes: [],
      selectedFacilities: [],
      include_facility_filters: false,
    };

    this.changeFacility = this.changeFacility.bind(this);
    this.changeFacilityType = this.changeFacilityType.bind(this);
    this.resetSelectedFacilitiesAndTypes = this.resetSelectedFacilitiesAndTypes.bind(this);
    this.loadEmbed = this.loadEmbed.bind(this);
  }

  componentDidMount() {
    const resizeIframe = this.resizeIframe;
    window.addEventListener('resize', resizeIframe);
    this.loadEmbed(this.props.params.id);
  }

  componentWillUnmount(){
    const resizeIframe = this.resizeIframe;
    window.removeEventListener('resize', resizeIframe);
  }

  resizeIframe(){
    // classNames are of elements with heights/margins/borders that are deducted from window height to calculate
    // the max iframe height we can set without displaying window level scrollbars.
    const classNames = ['header-offset', 'application-footer', 'facility-filters'];
    const extraHeight = 50;
    const targetId = 'domo_report_embed';
    resizeIframe(targetId, classNames, extraHeight);
  }

  changeFacility(_, facilites) {
    const facility_ids = Object.values(facilites).map((facility) => {
      return facility.id;
    });
    this.setState({selectedFacilities: facilites});

    this.loadEmbed(this.props.params.id, {facilities: facility_ids});
  }

  changeFacilityType(_, facilityTypes) {
    const types = Object.values(facilityTypes).map((type) => {
      return type.value;
    });

    this.setState({selectedFacilityTypes: facilityTypes, selectedFacilities: []});
    this.loadEmbed(this.props.params.id, {facility_types: types});
  }

  resetSelectedFacilitiesAndTypes() {
    this.loadEmbed(this.props.params.id);
    this.setState({
      selectedFacilityTypes: [],
      selectedFacilities: []
    });
  }

  loadEmbed(id, filter) {
    const resizeIframe = () => {
      setTimeout(() => {
        this.resizeIframe();
      },1); // Take a beat
    };
    this.setState({loading: true});
    document.querySelector('#domo_report_embed').src = '';
    this.props.getItem(`/api/domo_reports/${id}/embed_token`, null, { failed: 'general.thirdPartyServiceUnavailable' }, filter)
      .then((report) => {
        this.setState({facilities: report.facilities});
        const html = `
            <html>
              <body>
                <form id="form" action="${DOMO_EMBED_HOST}/embed/pages/${report.embed_id}" method="post">
                  <input type="hidden" name="embedToken" value='${report.embed_token}'>
                </form>
                <script>
                  const form = document.getElementById("form");
                  form.submit();
                </script>
              </body>
            </html>`;

        document.querySelector('#domo_report_embed').contentDocument.write(html);

        this.setState({
          name: report.name,
          include_facility_type_filters: report.include_facility_type_filters,
          include_facility_filters: report.include_facility_filters,
        }, resizeIframe);
      });
  }

  render() {
    const {include_facility_filters, include_facility_type_filters, filter} = this.state;
    const all_facilities = this.state.facilities;
    const {selectedFacilityTypes} = this.state;
    const facilities = (selectedFacilityTypes && selectedFacilityTypes.length > 0) ? all_facilities.filter((f) => {
      return selectedFacilityTypes.find((facility) => {
        return facility.value === f.type;
      });
    }) : all_facilities;
    return (
      <React.Fragment>
        <AnalyticsFilterForm
          filter={filter}
          includeFacilityTypeFilter={include_facility_type_filters}
          includeFacilityFilters={include_facility_filters}
          facilities={facilities}
          changeFacility={this.changeFacility}
          changeFacilityType={this.changeFacilityType}
          onClearFilters={this.resetSelectedFacilitiesAndTypes}
        />
        <div>
          <div className='analytics-report-container'>
            <iframe src='' id='domo_report_embed' width='100%' frameBorder='0' />
          </div>
        </div>

      </React.Fragment>
    );
  }
}

AnalyticsViewPage.propTypes = {
  getItem: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  getItem,
};

export default connect(null, mapDispatchToProps)(AnalyticsViewPage);
