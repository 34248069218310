import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Card, Col, Row, Table} from 'react-bootstrap';
import ContentConcealer from '../../../common/concealers/ContentConcealer';

const QuickMoveNewItemDetails = ({item}) => {
  return (
    <Card>
      <Row>
        <Col xs={6}>
          <h3>{I18n.t('itemTransactions.item.itemDetails')}</h3>
          <Table striped className='item-details'>
            <thead>
            <tr>
              <th>{I18n.t('itemTransactions.field')}</th>
              <th>{I18n.t('itemTransactions.value')}</th>
            </tr>
            </thead>
            <tbody>
            <ContentConcealer show={Boolean(item.package_code)}>
              <tr>
                <td>{I18n.t('itemTransactions.item.packageId')}</td>
                <td>{item.package_code}</td>
              </tr>
            </ContentConcealer>
            <tr>
              <td>{I18n.t('itemTransactions.item.location')}</td>
              <td>{item.location_name}</td>
            </tr>
            <tr>
              <td>{I18n.t('itemTransactions.item.qty')}</td>
              <td>0 {item.uom} -> {item.qty} {item.uom}</td>
            </tr>
            <tr>
              <td>{I18n.t('itemTransactions.item.isReserved')}</td>
              <td>{item.is_reserved ? 'Yes' : 'No'}</td>
            </tr>
            <tr>
              <td>{I18n.t('itemTransactions.item.onHold')}</td>
              <td>{item.on_hold ? 'Yes' : 'No'}</td>
            </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Card>
  );
};

QuickMoveNewItemDetails.propTypes = {
  item: PropTypes.object.isRequired,
};

export default QuickMoveNewItemDetails;
