import { createSelector } from 'reselect';
import {betweenDates} from '../util/dateHelpers';
import {getActiveFacilityId} from './facilitiesSelectors';

export const getNotifications = state => state.notifications;

export const getCurrentFacilityNotifications = createSelector([getNotifications, getActiveFacilityId],
  (notifications, facility_id) => {
    notifications = notifications.filter(notification => notification.facility_id == facility_id);
    // de-deplicate - remove messages with the same alert_id:
    return notifications.filter((notification, pos) => (!notification.alert_id || (notifications.findIndex( item => item.alert_id === notification.alert_id ) === pos)));
  }
);

export const getUnreadNotificationsCount = createSelector(getCurrentFacilityNotifications, notifications =>
  notifications.reduce((total, notification) => total + (notification.viewed_timestamp || notification.is_viewed ? 0 : 1), 0)
);



export const getPriorityNotifications = createSelector(getCurrentFacilityNotifications, notifications => notifications.filter(notification  => {
  if(notification.alert_type === 'priority' && !notification.viewed_timestamp){
    return betweenDates(notification.starts_at, notification.expires_at);
  }
  return false;
}));
