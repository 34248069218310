import PropTypes from 'prop-types';
import {change, reduxForm} from 'redux-form';

import { CREATE_HARVEST_PACKAGES } from '../../../constants/forms';
import validate from './validateHarvestPackages';
import CreateHarvestPackagesForm from './CreateHarvestPackagesForm';

const updateCurrentWeight = (values, dispatch, props) => {
  dispatch(change(CREATE_HARVEST_PACKAGES, 'currentWeight', props.totals.currentWeight));
  dispatch(change(CREATE_HARVEST_PACKAGES, 'currentOtherMaterialWeight', props.totals.currentOtherMaterialWeight));
  dispatch(change(CREATE_HARVEST_PACKAGES, 'packageWeight', props.totals.packagedWeight));
  dispatch(change(CREATE_HARVEST_PACKAGES, 'packageOtherMaterialWeight', props.totals.packagedOtherMaterialWeight));
  dispatch(change(CREATE_HARVEST_PACKAGES, 'remainingWeight', props.totals.remainingWeight));
  dispatch(change(CREATE_HARVEST_PACKAGES, 'remainingOtherMaterialWeight', props.totals.remainingOtherMaterialWeight));
};

// todo reload form once its loaded, fill intitialValues with those from total, or have value input work into the InternationalQuantityByUomInput


const CreateHarvestPackagesReduxForm = reduxForm({
  form: CREATE_HARVEST_PACKAGES,
  validate,
  enableReinitialize: true,
  onChange: updateCurrentWeight,
})(CreateHarvestPackagesForm);


CreateHarvestPackagesReduxForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  saveComplete: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  doPrint: PropTypes.func.isRequired,
  locations: PropTypes.array.isRequired,
  itemMasters: PropTypes.shape({
    regular: PropTypes.array.isRequired,
    test: PropTypes.array.isRequired,
    flower: PropTypes.array.isRequired,
    other_material: PropTypes.array.isRequired,
  }).isRequired,
  harvestWasteTypes: PropTypes.array,
  integrationState: PropTypes.object.isRequired,
  trackingIds: PropTypes.array.isRequired,
  packages: PropTypes.array.isRequired,
  totals: PropTypes.object.isRequired,
  totalQuality: PropTypes.number.isRequired,
  harvestBatch: PropTypes.object.isRequired,
  designatedInventoryAsTestPackageEnabled: PropTypes.bool
};

export default CreateHarvestPackagesReduxForm;
