const showNegativeAlert = (isInventoryNegative, setNegativeConfirmationState) => {
  return new Promise((resolve, reject) => {
    if (isInventoryNegative) {
      let wasHided = false;
      const hidePopup = (cb) => () => {
        if (!wasHided) {
          setNegativeConfirmationState({show: false, onConfirm: null, onHide: null});
          wasHided = true;
          cb();
        }
      };
      setNegativeConfirmationState({
        show: true,
        onConfirm: hidePopup(resolve),
        onHide: hidePopup(reject),
      });
      // don't resolve or reject here - wait for user input in modal
    } else {
      resolve();
    }
  });
};

export default showNegativeAlert;