import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {goBack} from 'react-router-redux';
import {formatDate} from '../../util/dateHelpers';

import * as itemActions from '../../actions/itemActions';
import * as apiActions from '../../actions/apiActions';
import * as facilityActions from '../../actions/facilityActions';
import {setRules} from '../../actions/ruleActions';
import rules from './RenderRules';
import * as itemNames from '../../constants/itemNames';
import * as dataNames from '../../constants/dataNames';
import FormWrapper from '../common/form/FormWrapper';
import CreateOrganizationReduxForm from './common/CreateOrganizationReduxForm';
import {PERSON} from '../../constants/imageUrls';
import {formatBranchIdentifier} from './common/validateOrganization';
import {hasOrgFacilityPALeaf, getFacilitiesWithWa} from '../../selectors/integrationSettingsSelectors';
import {getCoreUserPasswordExpiration} from '../../selectors/usersSelectors';
import { getPhoneShouldIncludeCountryCode } from '../../selectors/InternationalOperationsSelectors';
import {getRenderFacts} from '../../selectors/forms/organizationPageSelectors';
import {getInternationalPhoneCountryCode, getInternationalPhoneNumber} from '../../util/internationalHelpers';

export class CreateOrganizationPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {uploading: false};
    this.onSubmit = this.onSubmit.bind(this);
    this.changeUploadStatus = this.changeUploadStatus.bind(this);
    this.addImageRef = this.addImageRef.bind(this);
  }

  componentWillMount() {
    this.props.actions.unsetItem(itemNames.image);
    this.props.actions.getItem('/api/organizations/manage_organization', itemNames.manageOrganization,
      {failed: 'organization.get.failed'}, {with_banking_information: 1},
      (org) => {
        const {image_file_id} = org.organization;
        image_file_id ? this.props.actions.getItem(`/api/images/${image_file_id}`, itemNames.image) : null;
      }
    );
    this.props.actions.getUnpaginatedData('/api/facilities/get_retail_types', dataNames.retailFacilityTypes);
    this.props.actions.getItem('/api/settings/values/by_key', itemNames.expirePasswordsSettings, null,
      {ids: ['core_user_password_expiration_reminder_period', 'core_user_password_expiration_period']});
    this.props.actions.getItem('/api/settings/facilities_by_state_leaf', itemNames.orgFacilityStateLeaf, null, {states: ['pa', 'wa']});
    this.props.actions.setRules(rules)
      .then((engine) => {
        engine.setFacts(this.props.renderFacts);
      });

    this.props.actions
      .getItem('/api/settings/values/by_key', itemNames.internationalSettings, null,
        { ids: ['core_phone_number_include_country_code',]},
        () => {this.setState({ mounted: true});}
      );
  }

  componentWillUnmount() {
    this.props.actions.unsetItem(itemNames.manageOrganization);
  }
  changeUploadStatus(uploading){
    this.setState({uploading});
  }

  addImageRef(input) {
    this.imageInput = input;
  }

  onSubmit(formValues) {

    const {manageOrganization, timezone, facilities} = this.props;
    const {organization} = manageOrganization;
    const org = {
      core_user_password_expiration_period: formValues.expiration_period,
      core_user_password_expiration_reminder_period: [{days: formValues.expiration_reminder_period}],
      organization: {
        image_file_id: this.props.image.id || organization.image_file_id,
        marketing_email_address: formValues.marketing_email_address,
        public_profile_enabled: formValues.public_profile_enabled,
        facilities: formValues && formValues.facilities ? formValues.facilities.map(facility => {
          const originalFacility = facility.id ? facilities.find(f => f.id === facility.id) : null;
          const files = originalFacility && originalFacility.files || [];

          const phoneCountryCode = getInternationalPhoneCountryCode(facility.phone_number);
          const phoneNumber = getInternationalPhoneNumber(facility.phone_number);

          facility.phone_country_code = phoneCountryCode;
          facility.phone_number = phoneNumber;

          return ({
            ...facility,
            banking_information: facility.banking.map((bank) => {
              if(bank && bank.branch_identifier) {
                bank.branch_identifier = formatBranchIdentifier(bank.branch_identifier);
              }
              return bank;
            }).shift(),
            files: facility.files.map(file => ({
              ...file,
              expires_on: formatDate(file.expires_on, null, null, timezone),
            })),
            delete_file_ids: files.filter(file => !facility.files.some(f => f.id === file.id)).find(f => f.id),
          });
        }) : [],
        contacts: formValues.contacts ? formValues.contacts : []
      }};

    return this.props.actions.postData('/api/organizations/manage_organization',
      org,
      itemNames.manageOrganization,
      {success: 'organization.create.success'}, undefined,
      (data) => {
        const {facilities} = data.organization;
        const updatedFacilites = this.props.origFacilities.map(origFacility => {
          const facility = facilities.find(fac => fac.id === origFacility.id);
          if (facility) {
            return Object.assign({}, origFacility, {
              key: facility.facility_key,
              name: facility.name,
              type: facility.type,
              retail_type: facility.retail_type,
              city: facility.city,
              postal_code: facility.postal_code,
              province_code: facility.province_code,
              country_code: facility.country_code,
              street_address_1: facility.street_address_1,
              street_address_2: facility.street_address_2,
              timezone: facility.timezone
            });
          }
          return origFacility;
        });
        const updatedCurrentFacility = updatedFacilites.find(upFac => upFac.id === this.props.currentFacility.id);
        if (updatedCurrentFacility) {
          this.props.actions.updateFacility(Object.assign({}, this.props.currentFacility, updatedCurrentFacility));
          this.props.actions.setTimezone(updatedCurrentFacility.timezone);
        }
        this.props.actions.getFacilitiesSuccess(updatedFacilites);
        this.props.actions.goBack();
      }
    );
  }

  render() {
    const {organization, facilities, contacts, imageUrl, retailFacilityTypes, expirePasswordsSettings, enabledPALeafIntegration,
      facilitiesWithWaLeaf, renderFlags} = this.props;

    const public_profile_enabled = (organization) ? organization.public_profile_enabled : false;
    const marketing_email_address = (organization) ? organization.marketing_email_address : null;
    const registry_id = organization ? organization.registry_id : null;
    const initialValues = {facilities, contacts, public_profile_enabled, marketing_email_address, registry_id, ...expirePasswordsSettings};

    return (
      <FormWrapper title='organizations.createOrganization.createOrganizationProfile' goBack={this.props.actions.goBack}>
            {organization ? <CreateOrganizationReduxForm
              organization={organization.name}
              organizationDetails={organization}
              initialValues={initialValues}
              enabledPALeafIntegration={enabledPALeafIntegration}
              expirePasswordsSettings={expirePasswordsSettings}
              imageUrl={imageUrl}
              onSubmit={this.onSubmit}
              changeUploadStatus={this.changeUploadStatus}
              addImageRef={this.addImageRef}
              uploading={this.state.uploading}
              enableReinitialize={true}
              retailFacilityTypes={retailFacilityTypes}
              facilitiesWithWaLeaf={facilitiesWithWaLeaf}
              renderFlags={renderFlags}
            /> : null}
      </FormWrapper>
    );
  }
}

CreateOrganizationPage.propTypes = {
  manageOrganization: PropTypes.object.isRequired,
  organization: PropTypes.object,
  facilities: PropTypes.array.isRequired,
  contacts: PropTypes.array.isRequired,
  origFacilities: PropTypes.array.isRequired,
  currentFacility: PropTypes.object.isRequired,
  image: PropTypes.object,
  imageUrl: PropTypes.string,
  timezone: PropTypes.string.isRequired,
  retailFacilityTypes: PropTypes.array.isRequired,
  expirePasswordsSettings: PropTypes.object.isRequired,
  enabledPALeafIntegration: PropTypes.bool.isRequired,
  facilitiesWithWaLeaf: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    unsetItem: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    getFacilitiesSuccess: PropTypes.func.isRequired,
    updateFacility: PropTypes.func.isRequired,
    setTimezone: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    setRules: PropTypes.func,
  }),
  renderFacts: PropTypes.object,
  renderFlags: PropTypes.object,
};

CreateOrganizationPage.defaultProps = {
  imageUrl: PERSON
};

function mapStateToProps(state) {
  const {manageOrganization, image, timezone, retailFacilityTypes} = state;
  const {organization} = manageOrganization;
  const imageUrl = image && image.url ? image.url.original : undefined;
  const enabledPALeafIntegration = hasOrgFacilityPALeaf(state);
  const facilitiesWithWaLeaf = getFacilitiesWithWa(state);
  const includeCountryCode = getPhoneShouldIncludeCountryCode(state);

  const getBanksForFacility = (facilityId) => {
    const defaultBanking = [{}];

    if(!manageOrganization
      || !manageOrganization.organization
      || !manageOrganization.organization.facilities
      || !Array.isArray(manageOrganization.organization.facilities)) return defaultBanking;

    const banking = manageOrganization.organization.facilities.reduce((acc, facility) => {
      if(facility.id !== facilityId) return acc;
      if(facility.banking_information) {
        if(Array.isArray(facility.banking_information)) {
          acc = facility.banking_information;
        } else {
          if(typeof facility.banking_information === 'object'){
            acc.push(facility.banking_information);
          }
        }
      }
      return acc;
    }, []);

    return (!banking.length) ? defaultBanking : banking;
  };

  const facilities = organization && organization.facilities ? organization.facilities.map(
    facility => ({
      ...facility,
      phone_number: (includeCountryCode ? (facility.phone_country_code ? facility.phone_country_code + '-' + facility.phone_number : facility.phone_number) : facility.phone_number),
      facility_name: facility.name,
      banking: getBanksForFacility(facility.id),
      files: facility.files.map(file => ({
        ...file,
        expires_on: (file.expires_on) ? moment(file.expires_on) : null,
      })),
    })
  ) : [];

  const contacts = organization && organization.contacts ? organization.contacts.map(
    contact => ({
      ...contact,
      //Set the value of partner facility id to the value of the MJP facility ID
      facilities: contact.facilities.map(
        facility => ({
          id: facility.facility_id,
          partner_facility_id: facility.id
        })
      )
    })
  ) : [];

  const facility = state.facility;
  return {
    image,
    manageOrganization,
    facilities,
    contacts,
    origFacilities: state.facilities,
    currentFacility: facility,
    organization,
    imageUrl,
    timezone: timezone,
    retailFacilityTypes,
    expirePasswordsSettings: getCoreUserPasswordExpiration(state),
    enabledPALeafIntegration,
    facilitiesWithWaLeaf,
    renderFacts: getRenderFacts(state, {permissionKey: 'manage_connects'}),
    renderFlags: state[itemNames.rules].default || {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...apiActions,
      ...itemActions,
      ...facilityActions,
      setRules,
      goBack
    }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (CreateOrganizationPage);
