import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { I18n } from 'react-redux-i18n';
import { push } from 'react-router-redux';
import PageTitle from '../../common/PageTitle';
import TablePageWrapper from '../../common/grid/TablePageWrapper';
import * as apiActions from '../../../actions/apiActions';
import * as dataNames from '../../../constants/dataNames';
import { getCupos } from '../../../selectors/forms/cupoFormSelectors';
import PermissionLink from '../../common/PermissionLink';
import * as p from '../../../constants/permissions';
import SingleActionColumn from '../../common/grid/columns/SingleActionColumn';

class ManageCuposPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: props.params.status === 'inactive' ? 'inactive' : 'active'
    };

    this.getTabs = this.getTabs.bind(this);
    this.getColumns = this.getColumns.bind(this);
    this.fetchCupos = this.fetchCupos.bind(this);
    this.inactivateCupo = this.inactivateCupo.bind(this);
    this.activateCupo = this.activateCupo.bind(this);
    this.switchTab = this.switchTab.bind(this);
  }

  componentWillMount() {
    this.fetchCupos();
  }

  getColumns() {
    return [
      {
        name: 'Cupo number',
        dataId: 'cupo_number',
        width: '80%',
        headerAlign: 'left',
        dataAlign: 'left'
        //formatter: (key) => I18n.t('modalities.list.' + key),
      },
      {
        dataId: 'edit',
        hidden: false,
        dataSort: false,
        width: '10%',
        formatter: (cell, row) => {
          return cell === 0 ? (
            ''
          ) : (
            <PermissionLink
              permissions={[p.manage_compliance_settings]}
              props={{
                title: I18n.t('common.edit'),
                to: `/settings/cupos/modify/${row.id}`,
                className: 'btn btn-default',
                onClick: (event) => {
                  // stop click on row
                  event.stopPropagation();
                }
              }}
            >
              {I18n.t('common.edit')}
            </PermissionLink>
          );
        }
      },
      this.state.activeTab === 'active' && {
        dataId: 'inactivate',
        hidden: false,
        dataSort: false,
        width: '10%',
        formatter: (cell, row) =>
          SingleActionColumn({
            label: 'common.inactivate',
            action: this.inactivateCupo,
            style: 'default'
          })(cell, row)
      },
      this.state.activeTab === 'inactive' && {
        dataId: 'activate',
        hidden: false,
        dataSort: false,
        width: '10%',
        formatter: (cell, row) =>
          SingleActionColumn({
            label: 'common.activate',
            action: this.activateCupo,
            style: 'default'
          })(cell, row)
      }
    ];
  }

  fetchCupos() {
    const { activeTab } = this.state;
    this.props.actions.getData('/api/cupos', dataNames.cupos, null, { is_active: activeTab === 'active' ? 1 : 0 });
  }

  switchTab(activeTab) {
    this.props.actions.push(`/settings/cupos/${activeTab}`);
    this.setState({ activeTab }, this.fetchCupos);
  }

  inactivateCupo(event, row) {
    this.props.actions.putItem(`/api/cupos/${row.id}`, { is_active: 0 }, 'noOp').then(this.fetchCupos);
  }

  activateCupo(event, row) {
    this.props.actions
      .putItem(`/api/cupos/${row.id}`, { is_active: 1 }, 'noOp', { success: 'cupos.form.success' })
      .then(this.fetchCupos);
  }

  getTabs() {
    return [
      {
        id: 'activeTab',
        title: 'common.active',
        eventKey: 'active',
        actions: [
          {
            id: 'createCupo',
            path: '/settings/cupos/create',
            text: 'cupos.actions.createCupo',
            glyph: 'plus-sign',
            requireSelect: false
          }
        ]
      },
      {
        id: 'inactiveTab',
        title: 'common.inactive',
        eventKey: 'inactive',
        actions: []
      }
    ];
  }

  render() {
    const { activeTab } = this.state;
    const { cupos } = this.props;
    return (
      <div>
        <PageTitle primaryText={I18n.t('cupos.title')} />
        <TablePageWrapper
          tabs={this.getTabs()}
          columns={this.getColumns()}
          data={cupos}
          hideExport={true}
          hideScanSearch={true}
          hideTableControls={true}
          activeTab={activeTab}
          switchTab={this.switchTab}
          hideSearch={true}
          settingKey='modalities'
          bstProps={{
            selectRow: {
              clickToSelect: false,
              hideSelectColumn: true
            }
          }}
        />
      </div>
    );
  }
}

ManageCuposPage.propTypes = {
  actions: PropTypes.object,
  params: PropTypes.object,
  cupos: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    cupos: getCupos(state)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({ push }, apiActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageCuposPage);
