export const receiptStatusMessages = {
  printing        : 'PRINTING',
  printingComplete: 'PRINTING_COMPLETE',
  complete        : 'COMPLETE',
  connectingQzTray: 'CONNECTING_QZTRAY',
  noTray          : 'NOTRAY',
  noPrinter       : 'NOPRINTER',
  error           : 'ERROR',
  building        : 'BUILDING',
  buildingComplete: 'BUILDING_COMPLETE',
  fetching        : 'FETCHING'
};

export default receiptStatusMessages;
