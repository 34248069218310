/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import {pick} from 'lodash';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack, push} from 'react-router-redux';
import * as apiActions from '../../../actions/apiActions';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import FormWrapper from '../../common/form/FormWrapper';
import {unsetItem} from '../../../actions/itemActions';
import TransferCashFormWrapper from './TransferCashFormWrapper';
import {getInitialValues, getRegistersExceptCurrent, getCurrentRegister} from '../../../selectors/forms/transferCashFormSelector';
import InProgressOverlay from '../../common/InProgressOverlay';
import ModalWrapper from '../../common/ModalWrapper';
import {clearSelectedData} from '../../../actions/selectedDataActions';
import {getSelectableRegisterTransactionTypes} from '../../../selectors/registersSelectors';

export class TransferCashPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);

    this.onSubmit = this.onSubmit.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.goToRegisterListing = this.goToRegisterListing.bind(this);

    this.state = {
      showProgress: false,
      showModal: false
    };
  }

  componentWillMount() {
    this.props.actions.getUnpaginatedData(
      '/api/registers',
      dataNames.registers,
      {failed: 'registers.getRegisters.fail'},
      {detailed:1, active:1});
  }

  componentWillUnmount() {
    this.props.actions.unsetItem(itemNames.register);
    this.props.actions.unsetItem(itemNames.transaction);
    this.props.actions.clearSelectedData('registers');
  }

  showModal() {
    this.setState({showModal: true});
  }

  hideModal() {
    this.setState({showModal: false});
  }

  goToRegisterListing() {
    this.props.actions.push(`/registers`);
  }

  onSubmit(formData) {
    this.setState({showProgress: true});
    const {currentRegister} = this.props;
    const payload = pick(formData, ['notes', 'reason_code', 'transacted_amount']);
    const messages = {success:'registers.createTransaction.success', failed:'registers.createTransaction.fail'};
    this.props.actions.postItem(`/api/registers/${currentRegister.id}/transfer/${formData.transfer_to}`, payload, itemNames.transaction, messages)
      .then(this.goToRegisterListing)
      .catch(() => this.setState({showProgress: false}));
  }

  render() {
    const {registers, initialValues, currentRegister, selectableRegisterTransactionTypes} = this.props;
    const {showProgress, showModal} = this.state;

    return (
      <div>
        <InProgressOverlay isActive={showProgress} message={I18n.t('common.loading')}/>
        <ModalWrapper
          onHide={this.hideModal}
          showModal={showModal}
          title={'registers.transferCash.cancellationModalTitle'}
          Component={false}
          version={2}
          okayButton={{show: true, onClick: this.goToRegisterListing}}
          cancelButton={{show: true, onClick: this.hideModal}}
        >
          <div>{I18n.t('registers.transferCash.cancellationMessage')}</div>
        </ModalWrapper>
        <FormWrapper title={'registers.transferCash.title'} goBack={this.showModal}>
          <TransferCashFormWrapper
            registers={registers}
            initialValues={initialValues}
            reasons={selectableRegisterTransactionTypes}
            currentRegister={currentRegister}
            onSubmit={this.onSubmit}
            showModal={this.showModal}
          />
        </FormWrapper>
      </div>);
  }
}

TransferCashPage.propTypes = {
  actions: PropTypes.object.isRequired,
  registers: PropTypes.array.isRequired,
  currentRegister: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    registers: getRegistersExceptCurrent(state),
    currentRegister: getCurrentRegister(state),
    initialValues: getInitialValues(state),
    selectableRegisterTransactionTypes: getSelectableRegisterTransactionTypes(state)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {goBack, unsetItem, push, clearSelectedData});
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TransferCashPage);
