import {createSelector} from 'reselect';
import get from 'lodash.get';
import sortBy from 'lodash.sortby';

import {getRegisters} from '../registersSelectors';

const getSelectedRegisterId = state => get(state, 'selectedRegisters.0', {});

export const getCurrentRegister = createSelector([getRegisters, getSelectedRegisterId], (registers, currentRegisterId) =>
  registers.find(register => register.id === currentRegisterId) || {}
);

export const getInitialValues = createSelector([getCurrentRegister], (selectedRegister) => ({
  register_name: selectedRegister.name,
  amount_remain: selectedRegister.current_level || 0
}));

export const getRegistersExceptCurrent = createSelector([getRegisters, getSelectedRegisterId], (registers, currentRegister) =>
  sortBy(registers.filter(register => register.id !== currentRegister), 'name')
);