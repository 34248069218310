import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, formValueSelector} from 'redux-form';
import PackagesForm from './PackagesForm';
import validate from './validate';
import {MODIFY_PACKAGES} from '../../../constants/forms';
import {isFeatureEnabled} from '../../../selectors/featureToggles';
import {hasPackagesTags} from '../../../selectors/integrationSelectors';

const ModifyPackagesFormWrapper = reduxForm({
  validate,
  enableReinitialize: true,
})(PackagesForm);

const selector = formValueSelector(MODIFY_PACKAGES);
function mapStateToProps(state) {
  const integrationAdjustmentReason = selector(state, 'integration_adjustment_reason') ? selector(state, 'integration_adjustment_reason') : '';
  return {
    integrationAdjustmentReason,
    hasPackagesTags: hasPackagesTags(state),
    isUtahGlobalIdGenerationFeatureEnabled: isFeatureEnabled(state)('feature_utah_global_id_generation'),
    isFeatureToggleEditInventoryItemCost: isFeatureEnabled(state)('feature_edit_inventory_item_cost')
  };
}

ModifyPackagesFormWrapper.propTypes = {
  form: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  adjustmentReasons: PropTypes.array.isRequired,
  packages: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  phases: PropTypes.array.isRequired,
  saved: PropTypes.bool.isRequired,
  showPrinter: PropTypes.func.isRequired,
  showPhases: PropTypes.bool.isRequired,
  integrationState: PropTypes.object.isRequired,
  integrationAdjustmentReasons: PropTypes.array.isRequired,
  hasGlobalIntegrationAdjustmentReason: PropTypes.bool,
  hasAllIntegrationAdjustmentReasons: PropTypes.bool,
  hasMedicatedPackages: PropTypes.bool,
  trackingIds: PropTypes.array.isRequired,
  showProductLookup: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  inventoryTypes: PropTypes.array,
  filterBy: PropTypes.func.isRequired,
  isUtahGlobalIdGenerationFeatureEnabled: PropTypes.bool,
  isFeatureToggleEditInventoryItemCost: PropTypes.bool
};

export default connect(mapStateToProps)(ModifyPackagesFormWrapper);
