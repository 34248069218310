import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';

import IntegrationSettingsForm from './IntegrationSettingsForm';
import validate from './validate';

const IntegrationSettingsFormWrapper = reduxForm({
  validate,
  enableReinitialize: true,
})(IntegrationSettingsForm);

IntegrationSettingsFormWrapper.propTypes = {
  form: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  integrators: PropTypes.array.isRequired,
  selectedIntegrator: PropTypes.string,
  metrcStates: PropTypes.array.isRequired,
  biotrackStates: PropTypes.array.isRequired,
  apisicpaStates: PropTypes.array.isRequired,
  selectedMetrcState: PropTypes.string,
  isLeafPaConfigPackClosedLoopFacility: PropTypes.bool,
  isLeafUtahConfigPackClosedLoopFacility: PropTypes.bool,
  features: PropTypes.object,
};

export default IntegrationSettingsFormWrapper;
