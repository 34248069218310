import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Card, Col, Row, Table} from 'react-bootstrap';

import ContentConcealer from '../../../common/concealers/ContentConcealer';

const PackageSplittedOldItemDetails = ({model, delta}) => {
  const {new_item} = model.transaction;
  return (
    <Card>
      <Row>
        <Col xs={6}>
          <Table striped className='item-details'>
            <thead>
            <tr>
              <th>{I18n.t('itemTransactions.field')}</th>
              <th>{I18n.t('itemTransactions.value')}</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>{I18n.t('itemTransactions.item.childPackageId')}</td>
              <td>{new_item.package_code}</td>
            </tr>
            <tr>
              <td>{I18n.t('itemTransactions.item.childLocation')}</td>
              <td>{new_item.location_name}</td>
            </tr>
            <ContentConcealer show={Boolean(new_item.purpose_name)}>
              <tr>
                <td>{I18n.t('itemTransactions.item.purpose')}</td>
                <td>{new_item.purpose_name}</td>
              </tr>
            </ContentConcealer>
            {delta.map((field) =>
              <tr key={field.name}>
                <td>{I18n.t(`itemTransactions.item.${field.name}`)}</td>
                <td>{field.prev} -> {field.current}</td>
              </tr>
            )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Card>
  );
};

PackageSplittedOldItemDetails.propTypes = {
  model: PropTypes.object.isRequired,
  delta: PropTypes.object.isRequired,
};

export default PackageSplittedOldItemDetails;
