export const WA_MARIJUANA_MIX = 'marijuana_mix';
export const WA_FLOWER_LOTS = 'flower_lots';
export const WA_OTHER_MATERIAL_LOTS = 'other_material_lots';
export const WA_OTHER_MATERIAL = 'other_material';
export const WA_PACKAGED_MARIJUANA_MIX = 'packaged_marijuana_mix';
export const WA_USABLE_MARIJUANA = 'usable_marijuana';
export const WA_SAMPLE_JAR = 'sample_jar';
export const WA_INFUSED_MIX = 'infused_mix';
export const WA_HYDROCARBON_CONCENTRATE = 'hydrocarbon_concentrate';
export const WA_CONCENTRATE_FOR_INHALATION = 'concentrate_for_inhalation';
export const WA_FOOD_GRADE_SOLVENT_CONCENTRATE = 'food_grade_solvent_concentrate';
export const WA_INFUSED_COOKING_MEDIUM = 'infused_cooking_medium';
export const WA_CAPSULES = 'capsules';
export const WA_SOLID_EDIBLE = 'solid_edible';
export const WA_LIQUID_EDIBLE = 'liquid_edible';
export const WA_TINCTURES = 'tinctures';
export const WA_TOPICAL = 'topical';
export const WA_TRANSDERMAL_PATCHES = 'transdermal_patches';
export const WA_SUPPOSITORY = 'suppository';
export const WA_SEEDS = 'seeds';
export const WA_CLONES = 'clones';
export const WA_PLANT_TISSUE = 'plant_tissue';
export const WA_MATURE_PLANT = 'mature_plant';

