import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field, FieldArray} from 'redux-form';
import {Col, Row} from 'react-bootstrap';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import TextInput from '../../common/form/TextInput';
import SubmitSection from '../../common/form/SubmitSection';
import ComboboxInput from '../../common/form/ComboboxInput';
import PhysicianAddressesFieldArray from './PhysicianAddressessFieldArray';
import PhysicianLicensesFieldArray from './PhysicianLicensesFieldArray';

const inactiveStatuses = [
  {text: I18n.t('customers.create.inactive'), value: '0'},
];

const statuses = [
  {text: I18n.t('customers.create.active'), value: '1'},
  {text: I18n.t('customers.create.inactive'), value: '0'},
];


const PhysicianForm = (props) => {
  const {
    handleSubmit, reset, pristine, invalid, submitting, clinics, countries, provinces, primaryAddressIndex,
    activeLicenseIndex, loading, change, integrationState, licenses, touch
  } = props;
  const submitSectionSettings = {
    actionSettings: {
      reset: {
        pristine,
        text: I18n.t('common.actions.reset'),
        style: 'default',
        action: reset,
      },
      submit: {
        invalid,
        submitting,
        disabled: submitting || pristine || invalid,
        style: 'primary',
        text: I18n.t('common.actions.save'),
      }
    },
    align: 'right',
  };

  return (
    <form className='physician-form' noValidate={true} onSubmit={handleSubmit}>
      <h4>{I18n.t('physicians.form.physicianInformation')}</h4>
      <Row>
        <Col xs={6} sm={5} md={3}>
          <Field name='first_name' component={TextInput} props={{
            label: I18n.t('physicians.form.firstName'),
            placeholder: I18n.t('physicians.form.firstNamePlaceholder'),
            isRequired: true,
          }}/>
        </Col>
        <Col xs={4} md={1}>
          <Field name='middle_initial' component={TextInput} props={{
            label: I18n.t('physicians.form.middleName'),
            maxLength: 1,
            onChange: (e) => {
              const value = typeof e.currentTarget.value === 'string'
                ? e.currentTarget.value.replace(/[^a-zA-Z]/, '')
                : undefined;
              change('middle_initial', value);
            }
          }}/>
        </Col>
        <Col xs={6} sm={5} md={3}>
          <Field name='last_name' component={TextInput} props={{
            label: I18n.t('physicians.form.lastName'),
            placeholder: I18n.t('physicians.form.lastNamePlaceholder'),
            isRequired: true,
          }}/>
        </Col>
        <Col xs={6} sm={5} md={3}>
          <Field name='clinic_id' component={ComboboxInput} props={{
            label: I18n.t('physicians.form.clinic'),
            placeholder: I18n.t('physicians.form.clinicPlaceholder'),
            options: clinics,
            textKey: 'name',
            valueKey: 'id',
            isRequired: true,
          }}/>
        </Col>
        <Col xs={4} sm={3} md={2}>
          <Field name='active' component={ReactSelectInput} props={{
            label: I18n.t('physicians.form.status'),
            options: statuses,
            placeholder: I18n.t('common.form.select'),
            isRequired: true,
          }}/>
        </Col>
      </Row>

      <FieldArray name='addresses' component={PhysicianAddressesFieldArray} props={{
        clinics,
        countries,
        provinces,
        statuses,
        inactiveStatuses,
        primaryAddressIndex,
        loading,
        blur,
        change,
        touch
      }}/>

      <FieldArray name='licenses' component={PhysicianLicensesFieldArray} props={{
        statuses,
        inactiveStatuses,
        activeLicenseIndex,
        integrationState,
        licenses,
        loading
      }}/>

      <SubmitSection settings={submitSectionSettings}/>
    </form>
  );
};

PhysicianForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  clinics: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  provinces: PropTypes.array.isRequired,
  primaryAddressIndex: PropTypes.number.isRequired,
  activeLicenseIndex: PropTypes.number.isRequired,
  blur: PropTypes.func,
  change: PropTypes.func,
  integrationState: PropTypes.object,
  licenses: PropTypes.array,
  touch: PropTypes.func,
};

export default PhysicianForm;
