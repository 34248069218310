import React from 'react';
import PropTypes from 'prop-types';
import {Field, FieldArray} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import WillRender from '../../common/concealers/WillRender';
import IntegratorsFieldArray from './integrators/IntegratorsFieldArray';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import SubmitSection from '../../common/form/SubmitSection';

const IntegrationSettingsForm = (props) => {
  const {
    pristine,
    submitting,
    invalid,
    handleSubmit,
    reset,
    integrators,
    metrcStates,
    biotrackStates,
    apisicpaStates,
    selectedIntegrator,
    isRetailFacility,
    selectedMetrcState,
    getSynchronizableModules,
    synchronizableModules,
    features,
    isLeafPaConfigPackClosedLoopFacility,
    isLeafUtahConfigPackClosedLoopFacility
  } = props;

  const submitSettings = {
    actionSettings: {
      reset: {
        text: I18n.t('common.form.reset'),
        action: reset,
        style: 'default',
        pristine,
      },
      submit: {
        text: I18n.t('common.form.save'),
        submitting,
        invalid,
        pristine,
      },
    },
  };

  // Add check if formValues.sync = true
  if (synchronizableModules && synchronizableModules.length) {
    submitSettings.actionSettings.submit.text = I18n.t('common.form.continue');
  }

  return (
    <form className='integration-settings' onSubmit={handleSubmit} noValidate={true}>
      <Row>
        <Col xs={6} sm={5} md={3} lg={2}>
          <Field name='selectedIntegrator.value' component={ReactSelectInput} props={{
            label: I18n.t('stateIntegrators.form.selectIntegrator'),
            options: integrators,
            isRequired: true,
            disabled: isLeafPaConfigPackClosedLoopFacility || isLeafUtahConfigPackClosedLoopFacility,
            onChange: (integrator) => {
              getSynchronizableModules(integrator);
            }
          }}/>
        </Col>
      </Row>
      <WillRender ifTrue={isLeafPaConfigPackClosedLoopFacility}>
        <Row>
          <Col xs={12}>
            <h5>This facility is integrated with the Department of Health in Pennsylvania.</h5>
          </Col>
        </Row>
      </WillRender>
      <WillRender ifTrue={isLeafUtahConfigPackClosedLoopFacility}>
        <Row>
          <Col xs={12}>
            <h5>This facility is integrated with the State of Utah Medical Cannabis Program.</h5>
          </Col>
        </Row>
      </WillRender>
      <FieldArray
        name='integrators'
        component={IntegratorsFieldArray}
        metrcStates={metrcStates}
        biotrackStates={biotrackStates}
        apisicpaStates={apisicpaStates}
        selectedIntegrator={selectedIntegrator}
        isRetailFacility={isRetailFacility}
        features={features}
        selectedMetrcState={selectedMetrcState}
        rerenderOnEveryChange={true}
        isLeafUtahConfigPackClosedLoopFacility={isLeafUtahConfigPackClosedLoopFacility}
      />
      <SubmitSection settings={submitSettings}/>
    </form>
  );
};

IntegrationSettingsForm.propTypes = {
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  reset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  integrators: PropTypes.array.isRequired,
  metrcStates: PropTypes.array.isRequired,
  biotrackStates: PropTypes.array.isRequired,
  apisicpaStates: PropTypes.array.isRequired,
  selectedIntegrator: PropTypes.string,
  isRetailFacility: PropTypes.bool.isRequired,
  selectedMetrcState: PropTypes.string,
  getSynchronizableModules: PropTypes.func.isRequired,
  synchronizableModules: PropTypes.array,
  selectedBiotrackState: PropTypes.string,
  features: PropTypes.object,
  isLeafPaConfigPackClosedLoopFacility: PropTypes.bool,
  isLeafUtahConfigPackClosedLoopFacility: PropTypes.bool
};

export default IntegrationSettingsForm;
