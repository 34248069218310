import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import get from 'lodash.get';
import {
  requiredFieldValidation,
  registerTransactionIsInRange,
  dateFieldValidation
} from '../../../common/form/redux-form/validations';
import { getLineTotals } from '../../common/line-items/lineItemsHelpers';

export const validatePurchaseOrderForm = (values, props) => {
  const {
    integrationState: { isOklahoma, isCanada }
  } = props;
  const errors = {};
  errors.partner_id = !props.isInitialMode && requiredFieldValidation(values.partner_id, 'purchaseOrders.form.partner');
  errors.vendor_facility_id = !props.isInitialMode && requiredFieldValidation(values.vendor_facility_id, 'purchaseOrders.form.partnerFacility');

  if (isCanada || isOklahoma) {
    errors.vendor_facility_type = requiredFieldValidation(
      values.vendor_facility_type,
      isOklahoma ? 'purchaseOrders.form.partnerFacilityType' : 'purchaseOrders.form.partnerType'
    );

    if(isCanada && props.generateInternalSalesOrder) {
      errors.order_class = requiredFieldValidation(values.order_class, 'salesOrders.form.order');
    }

    if (values.partner_id && values.vendor_facility_id && (!props.facilityTypes || props.facilityTypes.length === 0)) {
      errors.vendor_facility_type = isOklahoma
        ? I18n.t('purchaseOrders.form.noPartnerFacilityTypeAssigned')
        : I18n.t('purchaseOrders.form.noPartnerTypeAssigned');
    }
  }

  errors.contains_medicated =
    !values.contains_medicated &&
    values.contains_medicated !== 0 &&
    requiredFieldValidation(values.contains_medicated, 'purchaseOrders.form.medicatedStatus');
  const usedItemMasterIds = [];
  errors.lines = (values.lines || []).map((line) => {
    //if the PP item master is inactivate and the PO is received this will allow the client to save the PO for adding payments and such
    const qty = line.lineType === 'prepack' ? getLineTotals(line.subitems).weight || values.received ? line.qty : 0 : line.qty;
    const priceLabel = `purchaseOrders.form.${line.lineType === 'unit' ? 'unitPrice' : 'pricePerGram'}`;
    const qtyLabel = `purchaseOrders.form.${line.lineType === 'unit' ? 'numUnits' : 'requestedAmount'}`;
    const duplicateProduct = usedItemMasterIds.includes(line.item_master_id);
    const duplicateProductError =
      duplicateProduct && line.showEditors ? I18n.t('purchaseOrders.form.duplicateProduct') : undefined;
    if (line.showEditors) {
      usedItemMasterIds.push(line.item_master_id);
    }
    let itemMasterError;
    if (
      line.itemMaster &&
      (!line.itemMaster.inventory_attributes || !get(line, 'itemMaster.inventory_attributes.is_ingredient')) &&
      !line.itemMaster.has_facility_strain
    ) {
      itemMasterError = I18n.t('purchaseOrders.form.strainError');
    } else if (!line.item_master_id) {
      itemMasterError = requiredFieldValidation(line.item_master_id, 'purchaseOrders.form.item');
    } else if (duplicateProductError) {
      itemMasterError = duplicateProductError;
    } else if (get(props, 'transfer.status') !== 'completed' && get(line, 'itemMaster.active') === 0) {
      itemMasterError = I18n.t('purchaseOrders.form.inactiveItemMasterError', {
        item_master: get(line, 'itemMaster.name')
      });
    }
    return {
      item_master_id: !line.itemMaster || !line.itemMaster.strain_id ? undefined : itemMasterError,
      qty: requiredFieldValidation(qty, qtyLabel) || mustBeGreaterThanZero(qty, qtyLabel),
      unit_price: requiredFieldValidation(String(line.unit_price), priceLabel)
    };
  });

  if (
    (values.generate_internal_sales_order || values.internal_sales_order_linked) &&
    (values.lines || []).length > props.transferLinesLimit &&
    props.transferLinesLimit
  ) {
    errors.lines._error = I18n.t('cultivation.transfers.form.maxItemsWithIntegratedSoError', {
      maxLine: props.transferLinesLimit
    });
  }

  const calculatedTotal = parseFloat(props.total ? props.total : 0); // Total is calculated and provided as a prop so use that

  const isPaymentAmountValid = (amount) => {
    const paymentAmount = parseFloat(amount);
    if (calculatedTotal === 0 && paymentAmount === 0) return true;
    return calculatedTotal > 0 && paymentAmount > 0;
  };

  errors.payments = {};

  if (values.payments && values.payments[0] && values.payments[0].payment_type && values.date_ordered) {
    const date_ordered =
      typeof values.date_ordered !== 'string'
        ? values.date_ordered
        : moment(values.date_ordered).isValid
        ? moment(values.date_ordered)
        : null;
    errors.payments = values.payments.map((payment) => {
      const date_diff = date_ordered && payment.payment_date && date_ordered.diff(payment.payment_date);
      const errors_line = {
        payment_type: requiredFieldValidation(payment.payment_type, 'purchaseOrders.form.paymentType'),
        register_id: payment.register_id === undefined ? I18n.t('purchaseOrders.form.selectRegister') : undefined,
        user_id: requiredFieldValidation(payment.user_id)
      };
      errors_line.payment_date =
        payment.payment_date && date_diff > 0
          ? I18n.t('purchaseOrders.form.paymentDateError')
          : requiredFieldValidation(payment.payment_date, 'purchaseOrders.form.paymentDate');
      if (!errors_line.payment_date && payment.payment_date) {
        errors_line.payment_date = dateFieldValidation(payment.payment_date);
      }
      errors_line.amount = payment.amount
        ? !isPaymentAmountValid(payment.amount) && I18n.t('purchaseOrders.form.amountError')
        : requiredFieldValidation(payment.amount, 'purchaseOrders.form.amount');
      if (!errors_line.amount) {
        errors_line.amount = registerTransactionIsInRange(payment.amount, 'upper');
      }
      return errors_line;
    });
  }

  errors.payments._error =
    errors.payments &&
    errors.payments[0] &&
    errors.payments[0].register_id &&
    errors.payments.some((error) => error && Object.keys(error).some((key) => error[key]))
      ? I18n.t('purchaseOrders.form.paymentPopulated')
      : undefined;

  if (values.oddMoney && values.oddMoney.amount <= 0.01) {
    errors.oddMoney = {
      register_id: requiredFieldValidation(values.oddMoney.register_id),
      payment_date: requiredFieldValidation(values.oddMoney.payment_date, 'purchaseOrders.form.paymentDate')
    };
  }

  if (values.date_ordered) {
    errors.date_ordered = dateFieldValidation(values.date_ordered);
  }

  if (values.date_expected) {
    errors.date_expected = dateFieldValidation(values.date_expected);
  }

  return errors;
};

const getFieldName = (label) => {
  return label ? I18n.t(label) : 'This field';
};

export const mustBeGreaterThanZero = (value, label) => {
  return value > 0
    ? undefined
    : I18n.t('purchaseOrders.form.mustBeGreaterThanZero', { fieldName: getFieldName(label) });
};

export default {
  validatePurchaseOrderForm
};
