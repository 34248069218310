import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getInternationalNumberFormat} from '../../../selectors/InternationalOperationsSelectors';

const InternationalDecimalStatic = (props) => {
  /* eslint no-unused-vars: 0 */
  const { dispatch, internationalNumberFormat, fractionPartSize, prefix, suffix, children, ...rest } = props;

  // if inner html is not simple text, just return
  if (typeof children === 'object') {
    return <div children={children} {...rest}/>;
  }

  // This is how we want it displayed,  It will always be xxxx.xx coming from the database,
  // so we will have to add the number separators here using the toLocaleString JS function
  const formatThis = (value) => {

    // Firstly, we assume that all numbers passed are in fact numbers...
    value = parseFloat(value);

    const options = fractionPartSize < 0 ? {} : {
      minimumFractionDigits: fractionPartSize,
      maximumFractionDigits: fractionPartSize
    };

    // NOTE this will round the result if fractionPartSize > -1 and fractions part > fractionPartSize
    if ( internationalNumberFormat  == 'NUMERICAL_SEPARATOR_PERIOD' ) {
      return value.toLocaleString('de-DE', options);
    }
    return value.toLocaleString('en', options);
  };

  const formattedInnerText = formatThis(children);
  return (
    <span {...rest}>
      {prefix}{formattedInnerText}{suffix}
    </span>
  );
};

InternationalDecimalStatic.propTypes = {
  internationalNumberFormat: PropTypes.string.isRequired,
  fractionPartSize: PropTypes.number,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  dispatch: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.number, PropTypes.string])
};

InternationalDecimalStatic.defaultProps = {
  fractionPartSize    : -1,
  prefix              : '',
  suffix              : ''
};


function mapStateToProps(state, ownProps){
  return {
    internationalNumberFormat: getInternationalNumberFormat(state),
  };
}

export default connect(mapStateToProps)(InternationalDecimalStatic);
