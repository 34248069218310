import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {formValueSelector, reduxForm, Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Alert, Button, Col, Row, Table} from 'react-bootstrap';
import {FaExclamationTriangle} from 'react-icons/fa';
import get from 'lodash.get';
import NumericInput from '../common/form/NumericInput';
import ReactSelectInput from '../common/form/ReactSelectInput';
import CheckBoxInput from '../common/form/CheckBoxInput';
import PasswordInput from '../common/form/PasswordInput';
import CardPaymentComponent from './card/CardPaymentComponent';
import ReduxButton from '../common/form/redux-form/ReduxButton';
import ModalWrapper from '../common/ModalWrapper';
import {getAdjustedCustomerPoints} from '../../selectors/customerSelectors';
import {getPaymentOptions} from '../../selectors/salesSettingsSelectors';
import {getRewardsPointsToCurrencyConversion} from '../../selectors/rewardsSelectors';
import {paymentValidation} from './validation';
import InternationalCurrencyStatic from '../common/form/InternationalCurrencyStatic';
import InternationalDecimalInput from '../common/form/InternationalDecimalInput';
import IntegrationConsumerType from './patient/IntegrationConsumerType';


export const Payment = ({
  change, amountDue, amountTendered, changeDue, handleSubmit, invalid, editOrder, cancelOrder, paymentComplete, confirmCancel, onCancelOrder,
  showPoints, onScanComplete, paymentOptions, submitInProgress, customer, order, credit, submit, allowCreditCard, pointsInCurrency,
  customerPoints, conversionRate, isHypur, onHypurPaymentChange, hypurCheckedInUsers, showHypurPACModal, onHideHypurPACModal, onSubmitHypurPayment,
  isAnonymousOrder, onRememberCheckedInUser, rememberHypurUser, selectedCheckedInUser, disableCheckedInUsers, onHypurSelectedUserChange,
  form, metrcSalesCustomerTypes, isMetrc, isPatientTypeDropdownToggled, isAeroPayToggled, isAeropayIntegratedToggled, isAeropayIntegrated,
  isShowAeropayintegratedModal, showAeropayintegratedModal, hideAeropayintegratedModal, onSubmitAeropayintegratedAmount, orderSettings,
  revised_authorization_amount, has_revised_authorization_amount_error, getFormValue, isPosabitOnline, isAlleavesToggled, isAlleavesIntegratorEnabled,
  register, alleavesMode, isHypurToggled
}) => {
  const paymentDisabled = Boolean(
    submitInProgress ||
    (customer.id && !customer.active) ||
    (amountTendered < 0.01 && amountDue) ||
    (order.products && order.products.length === 0)
  );

  const paymentTypeOtherLabel = get(orderSettings, 'order_select_payment_options.value.other_label');

  // An Aeropay Preauthorization exists
  const authorization_exists = get(order, 'authorization_code') || get(order, 'revised_authorization_code');

  const payments = get(order, 'payments', []);
  const pendingAmountTotal = payments.reduce((total, payment) => {
    return payment.is_pending ? Number(payment.amount) + total : 0 + total;
  }, 0);

  // Alleaves requires the alleaves_register_id to be mapped in the Register. Use this bool to show a message if it isn't
  const isAlleavesRegisterIdSetUp = isAlleavesToggled && isAlleavesIntegratorEnabled && get(register, 'alleaves_register_id');
  const isAeropayIntegratedEnabled = isAeropayIntegratedToggled && isAeropayIntegrated;

  return (
    <form className='payment' onSubmit={handleSubmit} noValidate={true} autoComplete={'off'}>
      <Table variant='table-sm' className='table-sm'>
        <thead>
          <tr>
            <th className='text-center'>{I18n.t('cart.payment.amountDue')}</th>
            <th><InternationalCurrencyStatic>{amountDue > 0 ? amountDue : '0'}</InternationalCurrencyStatic></th>
          </tr>
          {pendingAmountTotal ?
            <React.Fragment>
              <tr className='amount-pending'>
                <th className='text-center'>{I18n.t('cart.payment.pending')}</th>
                <th><InternationalCurrencyStatic>{pendingAmountTotal}</InternationalCurrencyStatic></th>
              </tr>
              <tr className='amount-remaining'>
                <th className='text-center'>{I18n.t('cart.payment.remaining')}</th>
                <th><InternationalCurrencyStatic>{Number(order.balance_due) - pendingAmountTotal}</InternationalCurrencyStatic></th>
              </tr>
            </React.Fragment> : null
          }
        </thead>
        {
          !showPoints || !customerPoints
            ? null
            : <tbody>
              <tr>
                <td colSpan={2} style={{textAlign: 'center', fontWeight: 'bold'}}>
                  <div>Pay With Reward Points!</div>
                  <div>Available Points: {customerPoints}</div>
                  <div style={{textAlign:'center', fontWeight: 'normal'}}>(Worth <InternationalCurrencyStatic>{(customerPoints * conversionRate)}</InternationalCurrencyStatic>)</div>
                  <div style={{clear:'both', marginBottom: '8px'}} />
                  <Col md={9} style={{padding: '0 2px'}}>
                    <div className='full-width-components'>
                      <Field name='points' component={NumericInput} props={{
                        fractionPartSize:0,
                        allowNegativeNumber:false,
                        max: customerPoints,
                        className: 'form-control',
                        placeholder: 'Enter Points To Use'
                      }}/>
                    </div>
                  </Col>
                  <Col md={3} style={{padding: '0 2px'}}>
                    <ReduxButton variant='primary'
                      name='afterSubmit'
                      type='submit'
                      props={{
                        value: 'apply_points'
                      }}
                      disabled={false}>
                  Apply
                    </ReduxButton>
                  </Col>
                  <div style={{clear:'both'}} />
                  <div style={{marginTop: '8px'}} className='text-muted'>
                    Applying These Points Saves <InternationalCurrencyStatic>{pointsInCurrency && !isNaN(pointsInCurrency) ? pointsInCurrency : '0.00'}</InternationalCurrencyStatic>!
                  </div>
                </td>
              </tr>
            </tbody>
        }
        {
          showPoints && !customerPoints
            ? <tbody><tr><th colSpan={2} style={{textAlign:'center'}}>Available Points: 0</th></tr></tbody>
            : null
        }
        <tbody>
          {paymentOptions.map((payment, index) => {
            // Disable these payment types if not enabled through configuration
            if ((payment.value === 'hypur' && !isHypurToggled) ||
            (payment.value === 'aeropay' && !isAeroPayToggled) ||
            (payment.value === 'aeropayintegrated' && !isAeropayIntegratedEnabled) ||
            (payment.value === 'posabit' && !isPosabitOnline) ||
            (payment.value === 'alleaves' && !isAlleavesRegisterIdSetUp)) {
              return null;
            }
            // Give aeropayintegrated button a different background color to indicate that it can be clicked
            const backgroundColor = payment.value === 'aeropayintegrated' && !get(order, 'transaction_id') ? '#4A4A4A' : '#929292';
            return (
              <React.Fragment key={index}>
                <tr>
                  <th className='text-center'>
                    <Button disabled={payment.value !== 'aeropayintegrated' || get(order, 'transaction_id') || (!isPosabitOnline && payment.value === 'posabit')}
                            style={{width: '150px', backgroundColor, color: '#FFFFFF', fontWeight: 'bold', border: 'none'}}
                            onClick={() => showAeropayintegratedModal()}
                    >
                      {payment.value === 'other'
                        ? paymentTypeOtherLabel ? paymentTypeOtherLabel : I18n.t('cart.payment.other')
                        : payment.value === 'alleaves'
                          ? alleavesMode === 'regala' ? I18n.t('cart.payment.alleavesRegala') : I18n.t('cart.payment.alleavesCashlessAtm')
                          : I18n.t(payment.i18n ? payment.i18n : payment.text)
                      }
                    </Button>
                  </th>
                  <td className='text-right'>
                    <InternationalDecimalInput
                      name={payment.value}
                      props={{
                        fractionPartSize: 2,
                        autoComplete: 'off',
                        allowNegativeNumber: false,
                        // AeroPay Integrated is always disabled for editing. The value should only be changed through the AeroPay Modal
                        readOnly: payment.value === 'aeropayintegrated' || (!isPosabitOnline && payment.value === 'posabit') || (!isAlleavesRegisterIdSetUp && payment.value === 'alleaves'),
                        onClick: () => {
                          if (isPosabitOnline && payment.value === 'posabit') { change('posabit', amountDue); }
                        }
                      }}/>
                  </td>
                </tr>
                {(!isPosabitOnline && payment.value === 'posabit') && <tr><td colSpan={2}>{I18n.t('cart.payment.terminalOffline')}</td></tr>}
                {(!isAlleavesRegisterIdSetUp && payment.value === 'alleaves') && <tr><td colSpan={2}>{I18n.t('cart.payment.alleavesRegisterIdNotSetUp')}</td></tr>}
              </React.Fragment>
            );
          })}
          {isHypur && !isAnonymousOrder ?
            <tr>
              <th className='text-center'>
                <Button disabled={true} style={{border: 'none'}}>
                  {I18n.t(`cart.payment.hypur`)}
                </Button>
              </th>
              <td className='text-right'>
                <InternationalDecimalInput
                  name='hypur'
                  props={{
                    fractionPartSize: 2,
                    autoComplete: 'off',
                    allowNegativeNumber: false,
                    onBlur: () => {
                      onHypurPaymentChange();
                    }
                  }}/>
              </td>
            </tr> : null
          }
        </tbody>
      </Table>
      {isHypur && !isAnonymousOrder ?
        <div className='hypur'>
          <div className='form-group'>
            <Field name='hypur_payment.individual_isn' component={ReactSelectInput} props={{
              label: I18n.t('cart.payment.hypurUser'),
              options: hypurCheckedInUsers,
              textKey: 'individualName',
              valueKey: 'individualISN',
              disabled: disableCheckedInUsers,
              onChange: (value) => {
                onHypurSelectedUserChange(value);
              }
            }} />
          </div>
          {selectedCheckedInUser ?
            <div className='form-group'>
              <Field name='remember_hypur_user' component={CheckBoxInput} label={I18n.t('cart.payment.rememberHypurUser')} props={{
                groupClassName: 'inline-checkbox',
                onChange: () => {
                  onRememberCheckedInUser(!rememberHypurUser, selectedCheckedInUser);
                }
              }} />
            </div>
            : null
          }
        </div>
      : null}
      <Table>
        <tfoot>
          <tr className='amount-tendered'>
            <th style={{width:'60%'}}>{I18n.t('cart.payment.amountTendered')}</th>
            <th><InternationalCurrencyStatic>{amountTendered}</InternationalCurrencyStatic></th>
          </tr>
        </tfoot>
      </Table>
      <Table>
        <tfoot>
          <tr>
            <th style={{width:'60%'}}>{I18n.t('cart.payment.changeDue')}</th>
            <th><InternationalCurrencyStatic>{changeDue > 0 ? changeDue : 0}</InternationalCurrencyStatic></th>
          </tr>
        </tfoot>
      </Table>
      {paymentComplete || !allowCreditCard ? null :
        <CardPaymentComponent
          onScanComplete={onScanComplete}
          amount={credit}
          orderId={order.id}
          paymentDisabled={paymentDisabled}
          onPayment={() => submit()}
        />
      }

      {
        paymentComplete
          ? null
          : (
            <div>
              <Row>
                <Col xs={12}>
                  {
                    isMetrc && isPatientTypeDropdownToggled && (
                      <IntegrationConsumerType form={form} options={metrcSalesCustomerTypes} />
                    )
                  }
                </Col>
              </Row>
              <Row>
                {(customer.active || !customer.id) ? null : <Col xs={12} style={{paddingBottom: '6px'}}>
                  <Alert variant='danger'>
                    <FaExclamationTriangle />
                    <span>{I18n.t('cart.payment.inactiveCustomerError')}</span>
                  </Alert>
                </Col>}
                <Col xs={12} style={{paddingBottom: '6px'}}>
                  <Button variant='primary' block className='complete-orderx'
                    type='submit' disabled={invalid || paymentDisabled}>{I18n.t('cart.payment.completePayment')}</Button>
                </Col>
              </Row>
              <Row>
                <Col xs={12} style={{paddingBottom: '6px'}}>
                  <Button variant='primary' block className='edit-orderx btn-block'
                    onClick={editOrder} disabled={submitInProgress}>
                    {I18n.t('cart.totals.editOrder')}
                  </Button>
                </Col>
              </Row>
              {
                !confirmCancel
                  ? (
                    <Row>
                      <Col xs={12} style={{paddingBottom: '6px'}}>
                        <Button variant='primary' onClick={onCancelOrder}
                          className='cancel-orderx pull-rightx btn-block' disabled={submitInProgress}>
                          {I18n.t('cart.payment.cancelOrder')}
                        </Button>
                      </Col>
                    </Row>
                  )
                  : (
                    <Row>
                      <Col xs={12} style={{paddingBottom: '6px'}}>
                        <Button variant='danger' onClick={cancelOrder}
                          className='cancel-orderx pull-rightx btn-block' disabled={submitInProgress}>
                          {I18n.t('cart.payment.confirmCancel')}
                        </Button>
                      </Col>
                    </Row>
                  )
              }
            </div>
          )
      }

      <ModalWrapper
        component={false}
        version={2}
        showModal={showHypurPACModal}
        title='cart.payment.hypurPACModalTitle'
        dialogClassName='modal-sm'
        okayButton={{
          show: true,
          variant: 'default',
          text: 'cart.payment.completePayment'
        }}
        cancelButton={{
          show: true,
          variant: 'default',
          text: 'common.close',
        }}
        onHide={(e) => {
          if (e.buttonClicked === 'okay') {
            onSubmitHypurPayment();
          } else {
            onHideHypurPACModal();
          }
        }}
      >
        <div className='alert alert-warning'>
          {I18n.t('cart.payment.hypurPACMessage')}
        </div>
        <div className='form-group'>
          <Field name='hypur_payment.pac' component={PasswordInput} props={{
            label: I18n.t('cart.payment.hypurPAC'),
            maxLength: 4
          }} />
        </div>
      </ModalWrapper>

      <ModalWrapper
        component={false}
        version={2}
        showModal={isShowAeropayintegratedModal}
        title='cart.payment.aeropayintegrated.title'
        dialogClassName='modal-sm'
        okayButton={{
          show: true,
          variant: 'default',
          text: 'cart.payment.aeropayintegrated.requestPreAuth',
          disabled: !revised_authorization_amount || has_revised_authorization_amount_error
        }}
        cancelButton={{
          show: true,
          variant: 'default',
          text: 'common.cancel',
        }}
        onHide={(e) => {
          if (e.buttonClicked === 'okay') {
            onSubmitAeropayintegratedAmount();
          } else {
            hideAeropayintegratedModal();
          }
        }}
      >
        <div className='alert alert-warning'>
          {I18n.t('cart.payment.aeropayintegrated.instruction')}<br/>
        </div>
        <div className='form-group'>
          {!authorization_exists && getFormValue('revised_aeropayintegrated') && // Show QR code if new authorization when amount is entered
            <Row>
              <Col md={12} lg={5} xs={12}>
                QR code with print button here....<br/><br/>
              </Col>
            </Row>
          }
          {getFormValue('aeropayintegrated') && // Only show current authorization amount if it exists
            <Row>
              <Col md={6} lg={5} xs={12}>
                {I18n.t('cart.payment.aeropayintegrated.currentAuthorizationAmount')}
              </Col>
              <Col md={6} lg={5} xs={12}>
                <InternationalDecimalInput
                  name={'aeropayintegrated'}
                  props={{
                    fractionPartSize: 2,
                    autoComplete: 'off',
                    allowNegativeNumber: false,
                    disabled: true
                  }}/>
              </Col>
            </Row>
          }
          <Row>
            <Col md={6} lg={5} xs={12}>
              {I18n.t('cart.payment.aeropayintegrated.newAuthorizationAmount')}
            </Col>
            <Col md={6} lg={5} xs={12}>
              <InternationalDecimalInput
                name={'revised_aeropayintegrated'}
                props={{
                  fractionPartSize: 2,
                  autoComplete: 'off',
                  allowNegativeNumber: false,
                }}/>
            </Col>
          </Row>
        </div>
      </ModalWrapper>

    </form>
  );
};

Payment.propTypes = {
  confirmCancel: PropTypes.bool.isRequired,
  onCancelOrder: PropTypes.func.isRequired, // cancel is two step process
  paymentComplete: PropTypes.bool.isRequired,
  amountDue: PropTypes.number.isRequired,
  amountTendered: PropTypes.number.isRequired,
  changeDue: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  editOrder: PropTypes.func.isRequired,
  cancelOrder: PropTypes.func.isRequired,
  paymentOptions: PropTypes.array.isRequired,
  submitInProgress: PropTypes.bool.isRequired,
  customer: PropTypes.object.isRequired,
  order: PropTypes.object,
  credit: PropTypes.number,
  allowCreditCard: PropTypes.bool,
  showPoints: PropTypes.bool,
  pointsInCurrency: PropTypes.string,
  customerPoints: PropTypes.number,
  conversionRate: PropTypes.number,
  onScanComplete: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  isHypur: PropTypes.bool.isRequired,
  isMetrc: PropTypes.bool.isRequired,
  onHypurPaymentChange: PropTypes.func.isRequired,
  hypurCheckedInUsers: PropTypes.array,
  showHypurPACModal: PropTypes.bool,
  onHideHypurPACModal: PropTypes.func.isRequired,
  onSubmitHypurPayment: PropTypes.func.isRequired,
  isAnonymousOrder: PropTypes.bool.isRequired,
  onRememberCheckedInUser: PropTypes.func.isRequired,
  rememberHypurUser: PropTypes.bool,
  selectedCheckedInUser: PropTypes.number,
  disableCheckedInUsers: PropTypes.bool.isRequired,
  onHypurSelectedUserChange: PropTypes.func.isRequired,
  metrcSalesCustomerTypes: PropTypes.array,
  form: PropTypes.string.isRequired,
  isPatientTypeDropdownToggled: PropTypes.bool.isRequired,
  isAeroPayToggled: PropTypes.bool.isRequired,
  isAeropayIntegrated: PropTypes.bool.isRequired,
  isAeropayIntegratedToggled: PropTypes.bool.isRequired,
  isPosabitToggled: PropTypes.bool.isRequired,
  isPosabitOnline: PropTypes.bool,
  isAlleavesIntegratorEnabled: PropTypes.bool.isRequired,
  isAlleavesToggled: PropTypes.bool.isRequired,
  isHypurToggled: PropTypes.bool.isRequired,
  isShowAeropayintegratedModal: PropTypes.bool.isRequired,
  showAeropayintegratedModal: PropTypes.func.isRequired,
  hideAeropayintegratedModal: PropTypes.func.isRequired,
  onSubmitAeropayintegratedAmount: PropTypes.func.isRequired,
  orderSettings: PropTypes.object.isRequired,
  revised_authorization_amount: PropTypes.number,
  has_revised_authorization_amount_error: PropTypes.bool,
  getFormValue: PropTypes.func.isRequired,
  register: PropTypes.object,
  alleavesMode: PropTypes.string
};

const WrappedComponent = reduxForm({
  enableReinitialize: true,
  validate: paymentValidation,
})(Payment);

const selector = formValueSelector('payment-form');

const OuterComponent = connect(
  state => {
    const paymentOptions = getPaymentOptions(state);
    const fieldValues = {};
    const validPaymentTypes = paymentOptions.map((option) => option.value).concat(['points_currency', 'is_override']);
    const amountTendered = validPaymentTypes.reduce((acc, type) => {
      const value = parseFloat(selector(state, type));
      if (!isNaN(value)) {
        acc += value;
      }
      fieldValues[type] = !isNaN(value) ? value : 0;
      return acc;
    }, 0);
    const orderTotal = parseFloat(state.order.balance_due);
    const amountDue = orderTotal > amountTendered ? orderTotal - amountTendered : 0;
    const changeDue = orderTotal < amountTendered ? amountTendered - orderTotal : 0;
    return {
      amountTendered,
      amountDue,
      changeDue,
      order: state.order,
      credit: fieldValues.credit || null,
      pointsInCurrency: selector(state, 'points_currency'),
      customerPoints: getAdjustedCustomerPoints(state),
      conversionRate: getRewardsPointsToCurrencyConversion(state),
      isOverride: fieldValues.is_override || false,
      completedHypurPAC: state.completedHypurPAC,
      rememberHypurUser: selector(state, 'remember_hypur_user'),
      selectedCheckedInUser: selector(state, 'hypur_payment.individual_isn'),
      revised_authorization_amount: selector(state, 'revised_aeropayintegrated'),
      has_revised_authorization_amount_error: get(state, 'form.payment-form.syncErrors.revised_aeropayintegrated')
    };
  }
)(WrappedComponent);

export default OuterComponent;
