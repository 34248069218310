/* eslint-disable quotes*/
module.exports = {
  en: {
    TedDiBiase: ' Ted Di Biase',
    accounting: {
      accountingAccountTypes: {
        failed: 'Failed'
      },
      accountingSettings: {
        failed: 'Failed'
      },
      accountingSettingsFields: {
        failed: 'Failed'
      },
      acquisitions: 'Acquisitions',
      cashManagement: 'Cash Management',
      export: {
        displayClass: 'Display Class on all reports and separate facility values',
        displayGross: 'Display Gross Sales and Discounts on Sales Export',
        displayVendor: 'Display Vendor and each PO on Acquisitions report',
        endDate: 'End Date',
        error: {
          invalideDate: 'Invalid Date'
        },
        failed: 'Export Failed',
        header: 'Accounting Export',
        selectExportFormat: 'Export Format',
        selectFacility: 'Locations to Export',
        selectTransactionType: 'Transaction Types',
        startDate: 'Start Date',
        submitButton: 'Save and Export Document',
        success: 'Success'
      },
      form: {
        account: 'Account',
        generalLedgerAccountName: 'General Ledger Account Name',
        generalLedgerAccountType: 'General Ledger Account Type'
      },
      inventoryAdjustments: 'Inventory Adjustments',
      inventoryAndCogs: 'Inventory And Cogs',
      salesAndPayments: 'Sales And Payments',
      setup: {
        accountingExportSetup: 'Accounting Export Setup'
      },
      wholesales: 'Wholesales'
    },
    alerts: {
      settings: {
        days: 'days',
        packageAlertForHarvest: 'Package Alert for harvest batches reaching',
        packageAlertTimeInCure: 'Package Alert based on historical time in cure and moisture loss per strain',
        plantAdvAlertFlowerToHarvest: 'Plant Advancement Alert for flower to harvest (based on strain flower cycle)',
        plantAdvAlertPropToVeg: 'Plant Advancement Alert for propagation to vegetative based on historical reporting',
        plantAdvAlertVegToFlower: 'Plant Advancement Alert for vegetative to flower (based on strain vegetative cycle)',
        propagationAlertForFutureGoals:
          'Propagation Alert for how many cuttings/seeds needed to reach future inventory goals',
        taskAlertForIncomplete: "Task Completion Alert for tasks that haven't been marked as complete at",
        testAlertForTestsNotEntered: "Test Return Alert for tests that haven't been entered for"
      },
      settingsTitle: 'Cultivation: Alerts'
    },
    assemblyListing: {
      createAssembly: {
        form: {
          costCol: 'Cost Col',
          ingredientName: 'Ingredient Name',
          quantityCol: 'Quantity Col',
          step: 'Step'
        }
      }
    },
    batchHistory: {
      eventTypes: {
        harvestDeactivatedPackaged: 'Harvest Deactivated (Packaged)',
        harvestPackaged: 'Harvest Packaged',
        labResultAssigned: 'Test Results Assigned',
        labResultUnassigned: 'Test Results Unassigned'
      },
      modal: {
        totalQuality: 'Total Quality'
      },
      model: {
        airyBuds: 'Airy Buds',
        batchName: 'Batch Name',
        biotrack: {
          currentWeight: 'Current Weight',
          totalWasteReported: 'Total Waste Reported',
          wasteUponCreation: 'Waste Upon Creation',
          wetWeight: 'Wet Weight'
        },
        budRot: 'Bud Rot',
        calculatedMoistureLoss: 'Calculated Moisture Loss',
        createdPackages: 'Created Packages',
        currentWeight: 'Current Weight',
        finalWeight: 'Final Weight',
        finalWeightRecorded: 'Final Weight Recorded',
        harvestBatchDetails: 'Harvest Batch Details',
        harvestBatchSplitDetails: 'Harvest Batch Split Details',
        harvestBatchUpdateDetails: 'Harvest Batch Update Details',
        leafWa: {
          currentOtherMaterialWeight: 'Current Other Material Weight',
          currentWeight: 'Current Flower Weight',
          otherMaterialWasteUponCreation: 'Other Material Waste Upon Creation',
          totalOtherMaterialWasteReported: 'Total Other Material Waste Reported',
          totalWasteReported: 'Total Flower Waste Reported',
          wasteUponCreation: 'Flower Waste Upon Creation',
          wetOtherMaterialWeight: 'Wet Other Material Weight',
          wetWeight: 'Wet Flower Weight'
        },
        location: 'Location',
        moisture: 'Moisture',
        mold: 'Mold',
        newBatchCurrentWeight: 'New Batch Current Weight',
        newBatchName: 'New Batch Name',
        packageId: 'Package ID',
        parentBatchName: 'Parent Batch Name',
        pestResistance: 'Pest Resistance',
        plantCount: 'Group Count',
        plantHarvestDetails: 'Plant Harvest Details',
        powderyMildew: 'Powdery Mildew',
        previousRecordOnBatchSplit:
          'Note: The previous Harvest Modified record is the transfer of weight from the original harvest batch to the new harvest batch.',
        product: 'Product',
        quantity: 'Quantity',
        strain: 'Strain',
        stretching: 'Stretching',
        totalWasteReported: 'Total Waste Reported',
        waste: 'Waste',
        wasteReported: 'Waste Reported',
        wasteUponCreation: 'Waste Upon Creation',
        wetWeight: 'Wet Weight'
      },
      orderDisposalReasons: {
        contaminated: 'Contaminated',
        damaged: 'Damaged',
        defective: 'Defective',
        expired: 'Expired'
      }
    },
    batches: {
      table: {
        stateLicense: 'State License',
        tracking: 'Tracking'
      }
    },
    biotrackPurchasableAmount: {
      get: {
        failed: 'Failed to load Purchasable Amount'
      },
      limit: 'Limit',
      title: 'Purchasable Amount'
    },
    browser: {
      okText: 'This browser is known to work well on MJ Platform.',
      okTextLeaf: 'This browser is known to work well on Leaf Data Systems.',
      safariNotSupported:
        'Safari is known to have issues.  We specifically do not support it.  We strongly recommend using another browser.',
      uncertainText:
        'We specifically support recent versions of Chrome and Firefox.  You might want to consider upgrading your browser for an optimal experience.'
    },
    cart: {
      OrderTransactions: 'Order Transactions',
      assignDriver: {
        assignDriver: 'Assign Driver',
        driverError: 'This Driver is not integrated with the Registry. Please check Driver profile.',
        printManifest: 'PRINT MANIFEST',
        printReceipt: 'PRINT RECEIPT',
        save: 'Save',
        selectDriver: 'Select Driver',
        selectPatientAddress: 'Select Patient Address'
      },
      cancelOrder: {
        cancelButton: 'Do Not Cancel',
        cancelledByCustomer: 'Cancelled By Customer',
        cancelledByFacility: 'Order Refused',
        instructions: 'Click one of the reasons shown below and then click the Cancel Order button.',
        okayButton: 'Cancel Order',
        title: 'Cancel Order ID %{id}'
      },
      cart: '%{name}: Cart',
      cartCount: {
        cart: 'CART'
      },
      checkOut: 'Check Out',
      couponName: 'Coupon Name',
      coupons: {
        adjustments: 'Adjustments',
        coupon: 'Coupon',
        coupons: 'Coupons',
        off: 'off',
        offTotalCoupon: 'Off Total Coupon'
      },
      disabledInventoryNotice:
        'NOTE: Inventory Drop Downs displaying disabled packages are those that exist but have no lab results and so are not currently available for assignment.',
      drawer: {
        checkOut: 'CHECK OUT',
        itemTotal: 'Total',
        release: 'Release',
        reset: 'Reset',
        reviewOrder: 'REVIEW ORDER',
        subTotal: 'SUBTOTAL',
        submitForPackaging: 'Submit for packaging',
        updateCart: 'Update Cart',
        weight: 'Weight'
      },
      form: {
        daysSupply: 'Days Supply',
        partialFillIndicator: 'Partial Fill Indicator',
        refillNumber: 'Refill Number',
        selectAlternateLocation: 'Select Alternate Location',
        selectRefillNumber: 'Select Refill Number'
      },
      getScannedItem: {
        failed: 'Failed to get information for scanned item.',
        notFound: 'No items were found for %{packageId}'
      },
      items: {
        applyCoupon: 'APPLY COUPON',
        applyReward: 'Apply Reward',
        autoPrice: 'AUTO PRICE',
        couponCode: 'Coupon Code',
        daysSupply: 'Days supply',
        daysSupplyLabel: 'Days Supply',
        fetchCoupons: 'Fetch Coupons',
        fetchRewards: 'Fetch Rewards',
        image: 'Image',
        itemDescription: 'Item Description',
        itemId: 'Item ID',
        labels: 'Labels',
        lineTotal: 'Line Total',
        locationsScanField: 'Locations Scan Field',
        notApplicable: 'N/A',
        partialFillIndicator: 'Partial Fill Indicator',
        partialFillLabel: 'Partial Fill',
        pricingWeight: 'Pricing Weight',
        printLabel: 'Print Label',
        qty_exceeded: 'Quantity exceeds the amount available for this product',
        qty_exceeded_for_package: 'Quantity exceeds available inventory for %{packageCode}',
        qty_exceeded_ordered_qty: 'Total selected quantity exceeds ordered amount of this product',
        quantity: 'Quantity',
        scanField: 'Scan Field',
        selectAlternateLocation: 'Select Alternate Location',
        selectRefillNumber: 'Select Refill Number',
        unavailableLocation: 'No inventory available at this location',
        unitPrice: 'Unit Price'
      },
      limitTable: {
        inOrder: 'In Order',
        limits: 'Limits',
        metrcMiLimitPeriod: 'Monthly/Daily',
        ordered: 'Ordered'
      },
      lineItem: 'Line Item',
      loadingMessage: {
        addingItem: 'Adding item to order…',
        addingProduct: 'Adding product to order…',
        cancellingOrder: 'Canceling order…',
        cureValidation: 'Validating order with state authority.',
        invalid: 'Scanned Code Is Not Valid.  It must be a string of characters.',
        lookup: 'Looking up scanned code…',
        removingItem: 'Removing item from order…',
        removingProduct: 'Removing product from order…',
        savingDelivery: 'Saving delivery information.',
        savingPayments: 'Saving Payment(s)',
        updatingOrder: 'Updating order…'
      },
      manualDiscount: {
        addDiscount: 'Add Discount',
        appliesTo: 'Applies To',
        dollarAmount: 'Dollar Amount',
        failed: 'Failed To Add Manual Discount',
        items: 'Items',
        order: 'Order',
        title: 'Manual Discount'
      },
      partialPaymentCancelConfirm: {
        body:
          'This order has partial payments applied, are you sure you want to cancel? This will remove the previously applied payments.',
        cancel: 'Return to order',
        confirm: "Yes, I'm sure I want to cancel",
        header: 'Are you sure you want to cancel?'
      },
      patient: {
        birthDate: 'Birthday',
        cardExpiration: 'Exp',
        cardNumber: 'MMJ Card #',
        currentTotal: 'Current Total',
        editPoints: 'Edit Points',
        editProfile: 'Edit Profile',
        favoriteConcentrate: 'Most Frequent Concentrate',
        favoriteEdible: 'Most Frequent Edible',
        favoriteFlower: 'Most Frequent Flower',
        favoriteTopical: 'Most Frequent Topical',
        favorites: 'Favorites',
        group: 'Group',
        information: 'Information',
        lastOrder: 'Last Order',
        loyaltyPoints: 'Loyalty Points',
        order: 'Order',
        orders: 'Orders',
        totalOrders: 'Orders',
        totalSpent: 'Total Spent',
        viewHistory: 'View Order History',
        viewOrderHistory: 'View Order History'
      },
      payment: {
        amountDue: 'Amount Due',
        amountTendered: 'Amount Tendered',
        cancelOrder: 'CANCEL ORDER',
        card: {
          amount: 'Amount',
          cardNumber: 'Card Number',
          cvv: 'CVV',
          cvvPlaceholder: 'cvv',
          expiration: 'Expiration',
          failed: 'Failed to take credit card payment',
          paymentNote: 'You are about to charge credit card payment in amount of $%{amount}',
          paymentTaken: 'Payment Taken Do Not Process',
          scanFailed: 'Failed to scan credit card',
          streetName: 'Street Name',
          streetNumber: 'Street Number',
          success: 'Credit Card Payment taken successfully',
          swipeCard: 'Swipe Card',
          title: 'Credit Card Information',
          zipCode: 'Zip Code',
          zipCodePlaceholder: 'zip'
        },
        cash: 'Cash',
        change: 'Change Due',
        changeDue: 'Change Due',
        check: 'Check',
        completeOrder: 'Complete Order',
        completePayment: 'COMPLETE PAYMENT',
        confirmCancel: 'CONFIRM CANCEL',
        credit: 'Credit',
        debit: 'Debit',
        giftCard: 'Gift Card',
        inactiveCustomerError: 'Can not complete payment for inactive customer',
        payment: 'Payment',
        paymentErrors: 'Payment Errors',
        points: 'Use Points',
        points_currency: 'Points In $',
        post: {
          emptyResponse: 'Failed to post a payments. Response is empty.',
          failed: 'Failed to post a payment',
          orderIdError: '%{serverError}. One possible cause for this is attempting too many payments.',
          success: 'Successfully posted a payment'
        },
        refund: 'REFUND',
        storeCredit: 'Store Credit'
      },
      rewardName: 'Reward Name',
      statusMessage: {
        default: 'Default',
        error: 'An unknown error occurred.  Please try again or contact support.',
        multiplesFound: 'Multiple items were found.  Please select an item.',
        noCurrentOrder: "Can't show Cart because there is no current Order",
        notFound: 'The scanned code did not return a Product or Inventory Item.',
        notYourLocation:
          'Inventory for this product is not available at your location but is at others.  Select an alternate location…',
        productAlreadyInOrder: 'The scanned product is already in your order.',
        qty_exceeded: 'The entered Quantity value exceeded the available amount of Package'
      },
      toggleScanMode: 'Toggle Scan Mode',
      totals: {
        cancelOrder: 'CANCEL ORDER',
        cartTotals: 'Cart Totals',
        checkOut: 'CHECK OUT',
        coupons: 'Coupons Total',
        editFulfillment: 'EDIT FULFILLMENT',
        editOrder: 'EDIT ORDER',
        fillOrder: 'FILL ORDER',
        fulfill: 'FULFILL',
        manualDiscount: 'Manual Discount',
        outForDelivery: 'OUT FOR DELIVERY',
        payment: 'PAYMENT',
        paymentAndPrinting: 'PAYMENT / PRINTING',
        printAllLabels: 'PRINT ALL LABELS',
        printReceipt: 'PRINT RECEIPT',
        setPrinter: 'SET PRINTER',
        subTotal: 'Sub Total',
        tax: 'Tax',
        taxDetailed: 'Tax: %{name} (%{rate}%)',
        toQueue: 'BACK TO QUEUE',
        total: 'Total',
        viewPrintAllLabels: 'VIEW/PRINT All LABELS',
        viewPrintLabel: 'VIEW/PRINT LABEL',
        viewPrintReceipt: 'VIEW/PRINT RECEIPT',
        viewReceipt: 'VIEW RECEIPT'
      }
    },
    categories: {
      collection: 'Categories',
      get: {
        failed: 'Failed to get categories.'
      },
      getCure: {
        failed: 'Failed'
      },
      getCureMappings: {
        failed: 'Failed'
      },
      getLeaf: {
        failed: 'Failed to get Leaf categories.'
      },
      getLeafMappings: {
        failed: 'Failed to get Leaf category mappings.'
      },
      getMetrc: {
        failed: 'Failed to get METRC categories.'
      },
      getMetrcMappings: {
        failed: 'Failed to get METRC category mappings.'
      },
      item: 'Category',
      length: 'Length',
      products: {
        products: 'Products'
      },
      update: {
        failed: 'Failed',
        success: 'Success'
      },
      updateLeafMappings: {
        failed: 'Failed to update Leaf category mappings.'
      },
      updateMetrcMappings: {
        failed: 'Failed to update METRC category mappings.'
      },
      updateWeedmapsMappings: {
        failed: 'Failed to update Weedmaps category mappings.'
      }
    },
    category: {
      title: 'Title'
    },
    categoryManagement: {
      apisicpaCategory: 'Apisicpa Category',
      biotrackCategory: 'Biotrack Category',
      cancelButtonText: 'Leave It',
      deliveryMethod: 'Delivery Method',
      displayCategoryName: 'Display Category Name',
      displaySubCategoryName: 'Display Sub-Category',
      error: 'The length limit is 24 characters',
      highLowTHC: 'High/Low THC',
      lastOneWarning: 'If no Sub-category is selected, this Category will not be visible throughout the system',
      leafCategory: 'Leaf Category',
      leaflyCategory: 'Leafly Category',
      metrcCategory: 'METRC Category',
      newFlagText: 'NEW!',
      optional: 'Optional',
      productsAmount: 'Product Quantity',
      removeButtonText: 'Remove It',
      removeSubcategoryNote:
        'Newly created sub categories may be removed by deselecting their checkbox and selecting "Remove It" in the pop up option.',
      removeSubcategoryText:
        'The subcategory you disabled is new. Would you like to remove it instead of setting it inactive?',
      removeSubcategoryTitle: 'Remove Sub Category?',
      reportingMapping: 'Reporting Mapping',
      required: 'Required',
      rulesCategory: 'Sales Rules Category',
      salesRulesCategory: 'Sales Rules Category',
      shared: 'Shared',
      sharedConfirmation:
        'If you select to proceed, your linked inventories will no longer be shared with your mapped facilities. You will need to do a reconciliation for each facility.',
      sharedMessage: 'Inventory items of the selected sub-categories will be shared with the configured facilities.',
      sharedProducts: 'Shared Product',
      subCategoryName: 'Sub-Category',
      title: 'Category/Sub-Category Management',
      weedmapsCategory: 'Weedmaps Category'
    },
    cei: {
      inventory: {
        table: {
          uom: 'Uom'
        }
      }
    },
    clinics: {
      create: {
        failed: 'Failed to create clinic'
      }
    },
    collectWaste: {
      create: {
        fail: 'Server error: Waste collection failed',
        success: 'Waste was recorded successfully.',
        title: 'Collect Waste'
      }
    },
    common: {
      action: 'Action',
      actionDate: 'Action Date',
      actions: {
        '0': '5',
        '1': '10',
        '2': '25',
        '3': '50',
        actions: 'Actions',
        batchUpdate: 'Batch Update',
        bulkActions: 'Bulk Actions',
        bulkEdit: 'Bulk Edit',
        cancel: 'Cancel',
        cancelDelete: 'Cancel Delete',
        close: 'Close',
        columnVisibility: 'Column Visibility',
        columnsVisibility: 'Columns Visibility',
        confirm: 'Confirm',
        confirmDelete: 'Confirm Delete',
        create: 'Create',
        createItem: 'Create %{item}',
        delete: 'Delete',
        deleteItem: 'Delete',
        destroy: 'Destroy',
        destroyItem: 'Delete %{item}',
        duplicate: 'Duplicate',
        editItem: 'Edit %{item}',
        export: 'Export',
        exportCSV: 'Export CSV',
        failedTo: 'Failed to %{action}',
        getCollection: 'Get %{collection}',
        getItem: 'Get %{item}',
        goBack: 'Go Back',
        history: 'History',
        modify: 'Modify',
        modifyItem: 'Modify %{item}',
        okay: 'Okay',
        pagination: 'Showing %{num} Entries',
        printLabel: 'Print Label',
        putOnHold: 'Put On Hold',
        refresh: 'Refresh',
        releaseOnHold: 'Release On Hold',
        reset: 'Reset',
        save: 'Save',
        saveAndPrint: 'Save and Print',
        saveAndReturn: 'Save And Return',
        saveAndSplitOther: 'Save And Split Other',
        scanId: 'Scan ID',
        scanSearch: 'Scan Search',
        search: 'Search',
        showColumns: 'Show Columns',
        successOn: 'Success On %{action}',
        swipeId: 'Swipe ID',
        today: 'Today',
        view: 'View'
      },
      activate: 'Activate',
      active: 'Active',
      apiKeyRequired: 'API Key is a required field',
      cancel: 'Cancel',
      cancelDelete: 'Cancel Delete',
      close: 'Close',
      confirm: 'Confirm',
      connectingToQzTray: 'Connecting to QZ Tray software…',
      create: {
        update: 'Update'
      },
      cultivationLocation: 'Cultivation Location',
      details: 'Details',
      disabled: 'Disabled',
      dummy: {
        printerSettingsFor: 'Printer Settings For'
      },
      edit: 'Edit',
      employee: 'Employee',
      ensurePrinterAvailable: 'Make sure the selected printer is plugged in and on.',
      enterQuantityError: 'Enter a quantity.',
      entryDate: 'Entry Date',
      error: {
        required: 'Required'
      },
      errorCannotConnectQZTray: 'The remote print server is unable to connect to QZ Tray and its printers.',
      errorConnectingQzTrayMessage:
        'We cannot connect to QZ Tray.  Ensure it is installed on your computer or device and is turned on.',
      errorConnectingQzTrayTitle: 'Cannot connect to QZ Tray!',
      errorLabelLoad: 'Label failed to load.',
      errorLessThan: 'This field should be not less than %{number}',
      errorNoDefaultPrinterMessage:
        'No default printer was found.  Ensure you have specified a default printer and that it is both connected and turned on.',
      errorNoDefaultPrinterTitle: 'No default printer found!',
      errorNoPrintersFound:
        'Please ensure you have printers set up on your computer or device including a default printer.',
      errorNoPrintersFoundTitle: 'No printers were found through QZ Tray!',
      errorPlantLabelLoad: 'Plant is not found. Label failed to load.',
      errorPrintServerNotActive: 'The selected printer is on a remote print server that is not currently active.',
      errorQzTrayNotResponding: 'Error Qz Tray Not Responding',
      failed: 'Failed',
      form: {
        addIngredient: 'Add Ingredient',
        address: 'Address',
        address2: 'Address 2',
        amount: 'Amount',
        browse: 'Browse',
        choosePlaceholder: 'Choose Placeholder',
        chosePlaceholder: 'Choose Placeholder',
        city: 'City',
        clear: 'Clear',
        costPerUnit: 'Cost Per Unit',
        country: 'Country',
        date: 'Date',
        deleting: 'Deleting',
        department: 'Department',
        dontRestock: "Don't Restock",
        edit: 'Edit',
        email: 'Email',
        endDate: 'End Date',
        female: 'Female',
        filterBy: 'Filter By',
        finishedInventoryCheckbox: 'Is Finished Inventory',
        groupName: 'Group Name',
        invalidEmail: 'Email has invalid format',
        invalidFormat: 'Invalid format',
        invalidPhone: 'Phone has invalid format',
        isBetween: '%{fieldName} must be between %{start} and %{end}',
        isRequired: '%{fieldName} is Required',
        isUnique: '%{fieldName} must be Unique',
        loading: 'Loading',
        loadingData: 'Loading Data…',
        lotNumberError:
          '%{fieldName} must be less than 25 characters and cannot contain carets(^), leading, or trailing spaces.',
        male: 'Male',
        max: '%{fieldName} must be lower or equal to %{max}',
        maxStrict: '%{fieldName} must be below %{max}',
        metrcTrackingIdError: '%{fieldName} must be at least 24 alphanumeric characters and end with a digit.',
        min: '%{fieldName} must be greater or equal to %{min}',
        minStrict: '%{fieldName} must be above %{min}',
        multiple: 'Multiple',
        mustBeAlphaNumeric: '%{fieldName} must be alphanumeric.',
        mustBeAnInteger: '%{fieldName} must be an integer.',
        mustBeNumeric: '%{fieldName} must be numeric.',
        name: 'Name',
        namePlaceholder: 'Enter Name',
        noChange: 'No Change',
        notes: 'Notes',
        numericPlaceholder: 'Enter Amount',
        packageCodeError:
          '%{fieldName} must be less than 25 characters and cannot contain carets(^), leading, or trailing spaces.',
        partnerCode: 'Partner Code',
        phone: 'Phone',
        physicalMail: 'Physical Mail',
        postCode: 'Postcode',
        postCode_plural: 'Postcodes',
        postalCode: 'Postal Code',
        postalCodeIncorrectFormat: 'Incorrect format',
        postalCode_plural: 'Postal Codes',
        print: 'Print',
        printLabels: 'Print Labels',
        productImageDescription: 'Display in you Product List Page for your Point of Scale as well as Online Ordering.',
        productionRunError: '%{fieldName} must have less than 25 alphanumeric or "." characters.',
        province: 'Province',
        purposes: 'Purpose',
        quantity: 'Quantity',
        region: 'Region',
        requiredError: 'Required Error',
        reset: 'Reset',
        restockAndCompleteTransfer: 'Restock And Complete Transfer',
        save: 'Save',
        saveAndExit: 'Save And Exit',
        saveAndPrint: 'Save And Print',
        saveAndPrintLabels: 'Save And Print Labels',
        saveAndReturn: 'Save and Return',
        saveAndStay: 'Save And Stay',
        saveIngredient: 'Save Ingredient',
        saving: 'Saving…',
        search: 'Search',
        searching: 'Searching…',
        select: 'Select:',
        selectAll: 'Select All',
        selectOrType: 'Select or Type %{data_name}',
        selectPlaceholder: 'Select:',
        sizeLimit: 'Maximum File Size: %{limit}',
        startDate: 'Start Date',
        state: 'State',
        submit: 'Submit',
        suburb: 'Suburb',
        text: 'Text',
        timezone: 'Timezone',
        total: 'Total',
        trackingId: 'Tracking ID',
        type: 'Type',
        typeLimit: 'Allowed Extensions: %{extensions}',
        units: {
          grams: 'Grams'
        },
        unspecified: 'Unspecified',
        uom: 'UOM',
        uomPlaceholder: 'Select Unit of Measure',
        update: 'Update',
        upload: 'Upload',
        uploadFile: 'Upload File',
        uploading: 'Uploading',
        urlError: '%{fieldName} must be a valid URL.',
        weight: 'Weight',
        wrongDateFormat: 'Please use the following date format MM/DD/YYYY',
        wrongDateTimeFormat: 'Field should be MM/DD/YYYY H:m',
        zipCode: 'Zip Code',
        zipCode_plural: 'Zip Codes'
      },
      history: 'History',
      imageUpload: {
        description: 'Displays in your user account, and in the Recent User Activity section of the Dashboard',
        limits: '(10MB max and must be JPG or PNG on a white background)'
      },
      inProgress: 'In Progress',
      inactivate: 'Inactivate',
      inactive: 'Inactive',
      initQzTrayLink: 'Select A Printer (QZ Tray must be installed and on!)',
      label: 'Label',
      labelsInfo:
        'Only one label is displayed here.  When printing, multiple labels will be printed if your task calls for them.',
      loading: 'Loading',
      loadingSampleImage: 'Loading sample image…',
      loadingSampleImageError: 'An error occurred while loading this label.',
      location: 'Location',
      mustBeIntegerError: 'Must be an integer.',
      mustBeSmallEnoughError: 'Must be < 10000.',
      mustBeWholeNumber: 'Must Be Whole Number',
      no: 'No',
      noFacilityPermission: {
        text: 'We recommend going back.',
        title: 'Requested page is unavailable for this facility'
      },
      noSelectedPrinter: 'No Selected Printer',
      nopermission: {
        text: 'We recommend going back.',
        title: 'Not Authorized'
      },
      notListed: 'Not Listed',
      notes: 'Notes',
      objectFile: {
        addFile: 'Add File',
        addRegistrationEvent: 'Add Registration Event',
        canadianRegistrationForm: 'Registration Events',
        downloadFile: 'Download File',
        driverLicenseId: 'Driver License ID',
        eventType: 'Event Type',
        expiration: 'Expiration',
        extendedSalesLimit: 'Extended Sales Limit',
        fileName: 'File Name',
        medicalMarijuanaId: 'Medical Marijuana ID',
        noEventsAdded: 'No Events Added',
        noFilesAdded: 'No files were added',
        refusal: 'Registration Refused',
        registrationEventData: 'Registration Event Date',
        registrationEventDate: 'Registration Event Date',
        registrationEvents: 'Registration Events',
        removeFile: 'Remove File',
        return: 'Transferred or Returned',
        temporaryPaperwork: 'Temporary Paperwork',
        uploadFile: 'Upload File'
      },
      objectFiles: {
        addFile: 'Add File',
        expiration: 'Expiration',
        fileName: 'File Name',
        uploadFile: 'Upload File'
      },
      placeHolders: {
        choose: 'Choose'
      },
      placeholders: {
        choose: 'Choose'
      },
      plant: 'Plant',
      plants: 'Plants',
      print: 'Print',
      printLabel: 'Print Label',
      printLabels: 'Print Labels',
      printerNotFound: 'Printer Not Found',
      printerPresent: 'Printer Present',
      printing: 'Sending To Printer… (Printer Must Be On And Connected!)',
      save: 'Save',
      saveAndPrintLabel: 'Save And Print Label',
      saveAndPrintLabels: 'Save And Print Labels',
      savePreferencesForThisMachine: 'Save Preferences For This Machine',
      searching: 'Searching',
      select: 'Select',
      selectAPrinterToEnablePrinting: 'Click "Select A Printer" at the top left of this screen to enable printing.',
      selectedPrinterNotFoundByQzTray: 'Selected Printer Not Found By Qz Tray',
      settingsSavedForMachine: 'Settings Saved For Machine',
      settingsSavedForSession: 'Settings Saved For Session',
      showingXEntries: 'Showing {0} Entries',
      showingXYOfZEntries: 'Showing {0} to {1} of {2} Entries',
      status: 'Status',
      strains: 'Strains',
      success: 'Success',
      tryAgain: 'Try Again',
      units: {
        cfug: 'cfu/g',
        ea: 'EA',
        gram: 'Gram',
        grams: 'Gram(s)',
        gramsShort: 'G',
        kwh: 'kw/h',
        liter: 'liter',
        mgg: 'mg/g',
        per: 'per',
        percent: '%',
        ppb: 'ppb',
        ppm: 'ppm',
        sqft: 'sq/ft',
        unit: 'Unit',
        usd: '$'
      },
      value: 'Value',
      view: 'View',
      viewTestResult: 'View Test Result',
      whenCorrectedClickTryAgain: 'When Corrected Click Try Again',
      yes: 'Yes',
      youHaveUnsavedChanges: 'You Have Unsaved Changes'
    },
    complianceSettings: {
      inventory: {
        block_expired_product_action: 'Select Warn or Block sales if product is expired',
        componentTitle: 'Inventory Compliance Settings',
        coupled_grams_on_hand_block:
          'Block when facility and its coupled cultivation facility on-hand inventory quantity is greather than or equal to',
        coupled_grams_on_hand_warn:
          'Warn when facility and its coupled cultivation facility on-hand inventory quantity is greather than or equal to',
        eiComponentTitle: 'Finished Product Inventory Compliance Settings',
        grams_on_hand_block: 'Block when facility on-hand quantity is greater than or equal to',
        grams_on_hand_warn: 'Warn when facility on-hand quantity is greater than or equal to',
        include_infused_weight_on_hand: 'Included infused products in on-hand inventory calculations',
        onHandTitle: 'On-Hand Inventory',
        product_on_hold_action: 'Select Warn or Block sales if product is on-hold',
        require_on_hand_product_expire_date: 'On-hand inventory requires an expiration date',
        reservationsTitle: 'Inventory Reservations'
      }
    },
    connectRequest: 'Connect Request',
    consumer: {
      checkin: {
        cardNumber: 'Card Number',
        caregiverStateMedicalID: 'Caregiver State Medical ID',
        customerCode: 'Customer Code',
        dateOfBirth: 'Date Of Birth',
        driversLicense: 'Drivers License',
        emailAddress: 'Email Address',
        filter: 'Filter',
        firstName: 'First Name',
        fulfillment_method: 'Fulfillment Method',
        getCustomersFailed: 'Get Customers Failed',
        ids: 'Ids',
        lastName: 'Last Name',
        licenseType: 'License Type',
        medicalMarijuanaID: 'Medical Marijuana ID',
        phoneNumber: 'Phone Number',
        registryId: 'Registry Id',
        reset: 'Reset',
        search: 'Search',
        stateMedicalID: 'State Medical ID'
      },
      id: 'Id',
      ids: 'Ids',
      type: 'Type'
    },
    consumers: {
      checkin: {
        actions: 'Actions',
        allowDispensaryRecommendation: 'Allow Dispensary Recommendation',
        cancel: 'Cancel',
        caregiver: 'Caregiver',
        caregiverCardNumber: 'Caregiver Card Number',
        caregiverHasNoPatientsAssigned: 'Caregiver Has No Patients Assigned',
        caregiverId: 'Caregiver Id',
        caregiverName: 'Caregiver Name',
        caregiverStateMedicalID: 'Caregiver State Medical ID',
        checkIn: 'Check-In',
        code: 'Code',
        cureDateOfBirth: '%{entity} Date of Birth',
        cureFirstName: '%{entity} First Name',
        cureLastName: '%{entity} Last Name',
        customerDataDoesNotMatch: 'Data provided does not match MJP saved data. Please update the patient.',
        dispensaryConsiderations: 'Dispensary Considerations',
        driversLicense: "Driver's License",
        email: 'Email',
        fastTrack: 'Fast Track',
        goToQueue: {
          delivery: 'Delivery Queue',
          inStore: 'Store Queue',
          pickup: 'Pickup Queue'
        },
        groups: 'Groups',
        licenseType: 'License Type',
        limitationsAndRestrictions: 'Limitations and Restrictions',
        medicalId: 'Medical Id',
        medicalMarijuanaID: 'Medical Marijuana ID',
        modifyOrder: 'Modify Order',
        name: 'Name',
        noOrders: 'There are no open Cure orders for this patient',
        patient: 'Patient',
        patientId: 'Patient Id',
        patientNumber: 'Patient Number',
        phoneNumber: 'Phone Number',
        populateCustomerData: 'Patient is not found in MJP. Please populate patient fields to continue.',
        recommendedForms: 'Recommended Forms',
        registerPatient: 'Register Patient',
        startOrder: 'Start Order',
        stateMedicalID: 'State Medical ID',
        temporaryId: 'Temporary Id'
      },
      checking: {
        allowDispensaryRecommendation: 'Allow Dispensary Recommendation',
        recommendedForms: 'Recommended Forms'
      },
      get: {
        failed: 'Failed'
      }
    },
    contact: {
      create: {
        fail: 'Server error: Contact creation failed.',
        success: 'Contact was saved successfully.'
      },
      modify: {
        failed: 'Failed to modify contact.',
        success: 'Contact was saved successfully.'
      }
    },
    containers: {
      create: {
        failed: 'Failed to create a container.',
        success: 'Container was created.',
        title: 'Create Container'
      },
      form: {
        addToContainer: 'Add to Container',
        assignTrackingId: 'Assign Tracking ID',
        containerId: 'Container ID',
        createContainer: 'Create Container',
        creationDate: 'Creation Date',
        currentContainerSummary: 'Current Container Summary',
        errorMessage: 'Error Message',
        item: 'Item',
        lotId: 'Lot ID',
        lotInformation: 'Lot Information',
        lotTitle: 'Lot ID: %{id}',
        masterItem: 'Master Item',
        package: 'Package',
        packageId: 'Package ID',
        quantity: 'Quantity',
        reset: 'Reset',
        saveAllChanges: 'Save All Changes',
        storageLocation: 'Storage Location',
        today: 'Today',
        total: 'Total',
        totalContainerWeight: 'Total Container Weight',
        totalPackages: 'Total Packages',
        trackingInformation: 'Tracking Information',
        unitSize: 'Unit Size',
        uom: 'UOM',
        weight: 'Weight'
      },
      generateCode: {
        failed: 'Failed to generate container ID.'
      }
    },
    creditCardsSettings: {
      form: {
        apiLogin: 'API Login',
        apiToken: 'API Token',
        api_token: 'Api Token',
        creditCardProcessor: 'Credit Card Processor',
        liveMode: 'Live Mode',
        password: 'Password',
        storeId: 'Store Id',
        store_id: 'Store Id',
        transactionKey: 'Transaction Key',
        url: 'URL',
        username: 'Username'
      },
      save: {
        failed: 'Failed to save Credit Card Settings',
        success: 'Credit Card Settings updated'
      },
      title: 'Credit Cards Settings'
    },
    cultivation: {
      alertSettings: {
        addCategory: 'Add another Category',
        category: 'Category',
        coupon: 'Coupon Alert - allow manager authorization required coupons to be built',
        equivalencyEnd: 'sales associates when categories/overall limits have been reached',
        equivalencyStart: 'Equivalency Alert - ',
        expirationEnd: 'before expiration',
        expirationStart: 'Expiration Alert - Alerts reception when patient is selected with',
        failed: 'Failed to modify Plant Compliance settings',
        fifoAlert:
          'FIFO Alert - alert sales associate when attempting to sell out of a package when oa older package exists',
        inNetworkPartner: 'In-Network Partner Alert - alert admin when new partners become available',
        inventory: 'Inventory level Alert - prompts re-order of products when par level is met',
        invoiceUpload:
          'Invoice Upload Alert - alert user when a transfer has been completed without an invoice attached',
        label:
          'Label Alert - alert users when labels are attempted to be printed with no information in required fields',
        maximum: 'Maximum',
        minimum: 'Minimum',
        onHandInventory: 'On-Hand Inventory Alert -',
        onlineOrderAlert:
          'Online Order Alert - alert sales associate when a previously expired customer places an online order',
        patientCount: 'Patient Count Alert - notifies managers when limits are reached',
        register: 'Register Level Alert - alerts manager when register reaches admin specified level',
        success: 'Plant Compliance settings modified successfully',
        title: 'Cultivation: Alerts',
        uom: 'UOM'
      },
      common: {
        table: {
          driver: 'Driver',
          numberOfItems: '# of Items'
        }
      },
      complianceSettings: {
        allowWeightDistribution: 'Allow Harvest Wet Weights to be evenly distributed for Harvest Batch',
        block: 'Block',
        confirmPlantsAsGroups: {
          cancelText: 'Cancel',
          noticeText: 'Choosing to store your plants as groups will disable your plant count limits upon save.',
          okayText: 'Store Settings',
          title: 'Confirm Selection of Plants as Groups'
        },
        facilityLessThan: 'Our facility must have less than',
        failed: 'Failed',
        fontSizeUnit: ' %{size} Point',
        loadingSettings: 'Loading Saved Settings…',
        moreAttempted: 'if more are attempted to be added.',
        no: 'No',
        ourMax: 'Our maximum is',
        plantsHand: 'plants on hand',
        plantsInFlower: 'plants in flowering.',
        plantsInProp: 'plants in propagation/veg and',
        ruleAllowWeightsDistributed:
          'Allow Harvest Wet Weights to be evenly distributed to all plants within the Harvest Batch:',
        ruleCreatePlantFromInventory: 'Create Plant from Inventory',
        ruleImmaturePlants: 'Maximum number of plants for this facility in the immature phase:',
        ruleImmaturePlantsSlug: 'Propagation And Vegetation Stage',
        ruleMaturePlants: 'Maximum number of plants for this facility in the mature phase:',
        ruleMaturePlantsSlug: 'Flowering Stage',
        rulePlantsOnHand: 'Maximum number of plants this facility may have on hand at any given time.',
        rulePlantsOnHandSlug: 'Total Plants On Hand',
        ruleSystemAction:
          'If an action will cause this facility to exceed the maximum number of plants on hand defined above:',
        ruleSystemActionSlug: 'System Action',
        savingSettings: 'Saving Settings…',
        success: 'Plant Compliance Settings Saved',
        tableRuleHeading: 'Rule',
        tableValueHeading: 'Value',
        title: 'Cultivation: Plant Compliance',
        totalCantExceed: 'Sum of phase limits cannot exceed overall limit for plants on hand',
        trackPlantsAsGroups: 'Track Plants as Groups',
        trackWastePackages: 'We are required to track waste packages whenever waste is reported',
        warn: 'Warn',
        yes: 'Yes',
        zeroHint: 'Setting a value to 0 disables compliance evaluations.'
      },
      drivers: {
        actions: {
          activateDriver: 'Activate Driver',
          addDriver: 'Add Driver',
          deleteDriver: 'Delete Driver',
          editDriver: 'Edit Driver',
          inactivateDriver: 'Inactivate Driver'
        },
        active: {
          fail: 'Fail to activate driver',
          success: 'Successfully activated driver'
        },
        activeDrivers: 'View Active Drivers',
        add: {
          fail: 'Failed to add driver',
          success: 'Successfully added driver'
        },
        addTitle: 'Add Driver Account',
        delete: {
          fail: 'Failed to delete driver',
          success: 'Successfully deleted driver'
        },
        edit: {
          fail: 'Failed to edit driver',
          success: 'Successfully edited driver'
        },
        editTitle: 'Edit Driver Account',
        form: {
          address: 'Address',
          address2: 'Address 2',
          authFacilities: 'Authorized Facilities',
          birthDate: 'Birth Date',
          city: 'City',
          companyInformation: 'Company Information',
          defaultVehicle: 'Default Vehicle',
          driverLicense: "Driver's License",
          firstName: 'First Name',
          initial: 'M. Initial',
          lastName: 'Last Name',
          name: 'Name',
          onfleetTeamsRequired: 'Onfleet Teams is required',
          personalInfo: 'Personal Information',
          phone: 'Phone %{number}',
          phoneNumberRequired: 'Phone number is required',
          phonePlaceHolder: 'ex. + 38 (066) 875 67 97',
          profileImage: 'Profile Image',
          selectUser: 'Select User',
          state: 'State',
          stateLicense: 'State Compliance License',
          transporterCompany: 'Transporter Company',
          vehicleInfo: 'Vehicle Information',
          zipCode: 'Zip Code'
        },
        inActiveDrivers: 'View In-Active Drivers',
        inactive: {
          fail: 'Fail to inactivate driver',
          success: 'Successfully inactivated driver'
        },
        listingTitle: 'Driver Listing',
        table: {
          driversLicense: "Driver's License",
          image: 'Image',
          name: 'Name',
          phoneNumber: 'Phone Number',
          photo: 'Driver Photo',
          stateComplianceLicense: 'State Compliance License'
        }
      },
      finishedProduct: {
        actions: {
          activate: 'Activate'
        },
        activateInventory: 'Activate Inventory',
        cancelPackaging: 'Cancel',
        cannotPackage: 'Cannot Package',
        completePackaging: 'Complete',
        createContainer: 'Create Container',
        error: {
          cannotActivateWithoutTracking:
            'This package was never associated with a Tracking ID and cannot be reactivated.',
          location: 'Location Should Be Changed',
          quantity: 'Exceeds Original Quantity'
        },
        finishedProduct: 'Finished Products',
        form: {
          Num: 'Num',
          addTrackingId: 'Add Tracking ID',
          adjustFailed: 'Adjust Failed',
          adjustSuccess: 'Adjust Success',
          amountToMove: 'Amount to Move',
          batch: 'Batch',
          batchName: 'Batch Name',
          biotrackInventoryType: 'Inventory Type',
          cancelFail: 'Failed to cancel packaging job',
          cancelSuccess: 'Successfully canceled packaging job',
          currentWeight: 'Current Weight',
          dateCreated: 'Date Created',
          destinationPartner: 'Destination Partner',
          grams: 'Grams',
          harvestBatch: 'Harvest Batch',
          inactivationReason: 'Inactivation Reason',
          label: 'Label',
          locationMovePlaceholder: 'Select Location',
          lotId: 'Lot ID',
          move: 'Move Amount to New Location',
          noData: 'No Data',
          noPackagesSelected: 'No Packages Selected',
          num: 'Num',
          onHold: 'On Hold',
          packageId: 'Package ID',
          packageSize: 'Package Size',
          printLabel: 'Print Label',
          product: 'Product',
          productName: 'Product Name',
          purposes: 'Purposes',
          qualityRating: 'Quality Rating',
          quantity: 'Quantity',
          quickmoveFailed: 'Quickmove encountered an error',
          quickmovePendingMedicallyCompliant:
            'The inventory selected has pending medically compliant status and cannot be moved.',
          quickmoveSuccess: 'Quickmove occured rapidly',
          reserved: 'Reserved',
          save: 'Move',
          splitFailed: 'Split Failed',
          splitSuccess: 'Split Success',
          storageLocation: 'Stor. Loc.',
          testPackage: 'Test package',
          testResults: 'Test Results',
          total: 'Total',
          trackingID: 'Tracking ID',
          type: 'Type',
          unit: 'Package ID',
          units: 'UOM',
          uom: 'UOM',
          vendor: 'Vendor'
        },
        header: 'Finished Product',
        importMedicallyCompliantStatus: 'Import Compliance Status',
        inventory: 'Inventory: ',
        medicallyCompliantStatus: 'Medical Status',
        modify: 'Modify',
        modifyLot: 'Modify Lot',
        modifyPackages: 'Modify Packages',
        nav: {
          active: 'Active Inventory',
          inactive: 'Inactive Inventory',
          inactiveWaste: 'Inactive Waste',
          waste: 'Waste'
        },
        noMedicallyCompliantStatus: 'no',
        packageId: 'Package Id',
        packaging: 'Packaging',
        reservations: {
          cancel: 'Cancel',
          confirm: 'Confirm'
        },
        searchPlaceholder: 'Search Packages',
        startPackaging: 'Start',
        table: {
          action: 'Action',
          actionDate: 'Action Date',
          employee: 'Employee',
          entryDate: 'Entry Date',
          lotNumber: 'Production Run/Lot',
          newLevel: 'New Level',
          notes: 'Notes',
          startingLevel: 'Starting Level',
          view: 'View'
        },
        transferProduct: 'Transfer Product'
      },
      getAppliedFeedingSchedules: {
        failed: 'Failed to load all feeding schedule(s) applied with plant'
      },
      getHistory: {
        failed: 'Failed to load history for Plant.'
      },
      harvestBatches: {
        harvestBatchSplit: 'Harvest Batch Split'
      },
      header: 'Cultivation: ',
      inventoryReceipts: {
        get: {
          failed: 'Failed to get Inventory Receipts'
        }
      },
      labelSettings: {
        minFontSize: 'Minimum Font Size',
        minimum_font_size: 'Minimum Font Size',
        packageLabel: 'Package Label',
        patientLabel: 'Patient Label',
        requireAllergens: 'allergens',
        requireCannabinoidPotency: 'Require Cannabinoid Potency',
        requireCultivationFacilityLicense: 'cultivation facility license number on',
        requireDateOfSale: 'date of sale',
        requireFacilityAddress: 'facility address',
        requireFacilityLicense: 'facility license',
        requireFacilityName: 'facility name',
        requireFacilityPhone: 'facility phone number',
        requireGrossWeight: 'gross weight',
        requireHarvestBatchNumber: 'harvest batch number',
        requireHealthAndBenefitsClaims: 'health and benefits claims',
        requireIngredients: 'ingredients',
        requireListOfNonOrganicPesticides: 'list of non-organic pesticides/herbicides/fungicides',
        requireLotNumber: 'lot number',
        requireMandatedStatements: 'mandated statements',
        requireNetWeight: 'net weight',
        requireNutritionalFacts: 'nutritional facts panel',
        requirePackageTests: 'Require Package Tests',
        requirePackageTestsDate: 'Require Package Tests Date',
        requirePatientDiagnosis: 'patient diagnosis',
        requirePatientRegistryNumber: 'patient registry number',
        requirePhysicianDiagnosis: 'physician diagnosis',
        requirePhysicianLicense: 'physician license number',
        requirePhysicianName: 'physician name',
        requireProductCategory: 'product category',
        requireRetailStoreLicense: 'retail store license number',
        requireTerpene: 'Require Terpene',
        requireTestResultsCannabinoidPotency: 'test results: cannabinoid potency',
        requireTestResultsTerpene: 'test results: terpene profile',
        requireTrackingID: 'Tracking ID',
        requireVendorLicense: 'Require Vendor License',
        requireVendorName: 'Require Vendor Name',
        requireWeight: 'weight',
        require__allergens: 'allergens',
        require__ingredients: 'ingredients',
        require__mandated_statements: 'mandated statements',
        require__weight: 'weight',
        require_allergens: 'Allergens',
        require_box_on_test_results: 'Visually Denote the Test Results (default results only)',
        require_cannabinoid_potency: 'test results: cannabinoid potency',
        require_cultivation_license_number: 'Cultivation License Number',
        require_date_of_sale: 'Date Of Sale',
        require_facility_address: 'facility address',
        require_facility_license_number: 'facility license',
        require_facility_name: 'facility name',
        require_facility_phone: 'facility phone number',
        require_gross_weight: 'gross weight',
        require_harvest_batch: 'Harvest Batch',
        require_harvest_batch_number: 'harvest batch number',
        require_health_and_benefits_claims: 'Health And Benefits Claims',
        require_health_benefits_claims: 'health and benefits claims',
        require_ingredients: 'Ingredients',
        require_list_of_non_organic: 'List Of Non Organic',
        require_lot_number: 'lot number',
        require_mandated_statements: 'Mandated Statements',
        require_net_weight: 'net weight',
        require_nonorganic_pesticides: 'list of non-organic pesticides/herbicides/fungicides',
        require_nutrition_facts: 'nutritional facts panel',
        require_nutritional_facts: 'Nutritional Facts',
        require_package_tests: 'Package Tests',
        require_package_tests_date: 'Package Tests Date',
        require_packaging_employee_state_id: 'Facility Packager State ID',
        require_patient_diagnosis: 'Patient Diagnosis',
        require_patient_registry_number: 'Patient Registry Number',
        require_physician_diagnosis: 'Physician Diagnosis',
        require_physician_name: 'Physician Name',
        require_product_category: 'Product Category',
        require_terpene_profile: 'test results: terpene profile',
        require_tracking_id: 'Tracking Id',
        require_vendor_license: 'Vendor License',
        require_vendor_name: 'Vendor Name',
        require_weight: 'Weight',
        title: 'Cultivation: Label Settings Compliance'
      },
      lotId: 'Lot ID',
      orderHistory: {
        actions: {
          reStock: 'Re-Stock',
          refund: 'Refund',
          replacePayments: {
            failed: 'Failed to replace payments',
            success: 'Successfully replaced payments'
          },
          storeCredit: 'Store Credit'
        },
        profileInfo: {
          MMJCard: 'MMJ Card #: ',
          birthDate: 'Birthday: ',
          checkIn: 'Check-In',
          edit: 'Edit Profile',
          fastTrackToCart: 'Fast Track To Cart',
          group: 'Group',
          id: 'Patient ID',
          patientInfo: 'Patient Information',
          rewardsPoints: 'Rewards Points: ',
          startOrder: 'Start Order',
          totalOrders: 'Total # of Orders: ',
          totalSpent: 'Total Spent: '
        },
        refundMayDiffer:
          'Refunds may differ by a very small amount due to tax calculations based on rounding if a single order is refunded more than once',
        refundTotal: 'Refund Total',
        table: {
          actions: 'Actions',
          cash: 'Cash',
          changePayment: 'Change Payment',
          check: 'Check',
          credit: 'Credit',
          debit: 'Debit',
          employee: 'Employee',
          'gift card': 'Gift Card',
          notEnoughPayment: 'Total amount must be at least %{orderTotal}',
          order: 'Order:',
          orderDate: 'Order Date',
          orderNumber: 'Order Number',
          paymentAmount: 'Payment Amount',
          paymentMethod: 'Payment Method',
          paymentsIn: 'Payments In',
          postTaxPrice: 'Post-Tax Price',
          preTaxPrice: 'Pre-Tax Price',
          price: 'Price',
          product: 'Product',
          productsIn: 'Products In',
          register: 'Register',
          salesAssociate: 'Sales Associate',
          'store credit': 'Store Credit',
          subTotal: 'Sub Total',
          taxes: 'Taxes',
          total: 'Total',
          totalCollected: 'Total Collected',
          unitId: 'Unit Id',
          unitSize: 'Unit Size'
        },
        title: 'Order History',
        totalDue: 'Total Due: '
      },
      pricingWeights: {
        addGroup: 'Add new Pricing Group',
        get: {
          failed: 'Failed to get pricing weights.'
        },
        post: {
          failed: 'Failed to create Pricing Weight',
          success: 'A new weight has been created successfully'
        },
        title: 'Select Pricing Weights'
      },
      productName: 'productName',
      purchaseOrders: {
        actions: {
          activate: 'Activate P.O.',
          cancel: 'CANCEL P.O.',
          continue: 'Continue',
          create: 'Create Purchase Order',
          edit: 'EDIT P.O.',
          receive: 'Receive Transfer',
          viewActive: 'View Active Purchase Orders',
          viewInactive: 'View In-Active Purchase Orders'
        },
        cancelConfirmation: {
          cancelInfoMessage:
            'If you elect to proceed the system will only cancel the Purchase Order, you should notify your partner to cancel the corresponding Sales Order. Would you like to continue?',
          cancelPO: 'Cancel Purchase Order',
          title: 'Title'
        },
        form: {
          addItem: 'Add Another Item',
          calculateTotal: 'Calculate Total',
          containerType: 'Container Type',
          containersCount: '# of Containers',
          dateCreated: 'Date Created',
          dateExpected: 'Date Expected',
          dateOrdered: 'Date Ordered',
          driver: 'Driver Information',
          driverError: 'Select Driver',
          expirationDate: 'Expiration Date',
          harvestDate: 'Harvest Date',
          item: 'Item',
          itemPrice: 'Total Line Item Price',
          orderItems: 'Order Items',
          orderPlaced: 'Order Placed',
          orderStatus: 'Order Status',
          orderTotal: 'Order Total',
          partner: 'Partner',
          partnerDiscount: 'Partner Discount',
          partnerError: 'Select Partner',
          partnerFacility: 'Partner Facility',
          partnerInvoice: 'Partner Invoice Number',
          partnerInvoiceError: 'Enter Partner Invoice Number',
          partnerInvoicePlaceholder: 'ex. 757929287367438290',
          paymentInfo: 'Payment Information',
          poNumber: 'P.O. Number',
          poTitle: 'P.O. Title',
          pricePerGram: 'Price Per Gram',
          pricePerUnit: 'Price Per Unit',
          requestedAmount: 'Requested Amount',
          status: 'Status',
          storageLocation: 'Storage Location',
          tax: 'Tax',
          terms: 'Terms',
          timeExpected: 'Time Expected',
          totalUnitsCount: 'Total # of Units',
          transferFee: 'Transfer Fee',
          unitsOfMeasurement: 'UOM',
          vendorError: 'Vendor Error',
          vendorInvoiceError: 'Vendor Invoice Error'
        },
        movedToActive: 'Moved To Active',
        table: {
          dateExpected: 'Date Expected',
          dateOrdered: 'Date Ordered',
          numberOfItems: '# of Items',
          numberOfPackages: '# of Packages',
          orderTotal: 'Order Total',
          orderedBy: 'Ordered By',
          partner: 'Partner',
          partnerContact: 'Partner Contact',
          partnerInvoice: 'Partner Invoice #',
          purchaseOrder: 'Purchase Order #',
          quotedDelivery: 'Quoted Delivery',
          receiptID: 'Receipt ID',
          status: 'Status',
          title: 'PO Title'
        },
        title: 'Purchase Orders'
      },
      salesOrder: {
        form: {
          salesOrderNumber: 'Sales Order Number'
        }
      },
      salesOrders: {
        actions: {
          create: 'Create Sales Order',
          edit: 'EDIT S.O.',
          fulfill: 'Fulfill Sales Order',
          saveAndCreateTransfer: 'Save And Create Transfer',
          viewActive: 'View Active Sales Orders',
          viewInactive: 'View In-Active Sales Orders'
        },
        form: {
          addItem: 'Add Another Item',
          backOrderItem: 'Backorder Item',
          contactEmail: 'Contact Email Address',
          contactName: 'Contact Name',
          contactPhone: 'Contact Phone Number',
          containerType: 'Container Type',
          containersCount: '# of Containers',
          dateExpected: 'Date Expected',
          dateOrdered: 'Date Ordered',
          disposalReason: 'Disposal Reason',
          inactiveItemMasterError:
            'Item %{item_master} has been inactivated and cannot be sold. Please remove it from the order if you want to proceed.',
          item: 'Item',
          itemPrice: 'Total Line Item Price',
          orderPlaced: 'Order Placed',
          orderStatus: 'Order Status',
          orderTotal: 'Order Total',
          orderTotals: 'Order Totals',
          orderType: 'Order Type',
          partnerDiscount: 'Partner Discount',
          partnerInvoice: 'Partner Invoice Number',
          partnerInvoicePlaceholder: 'ex. 757929287367438290',
          partnerName: 'Partner Name',
          pricePerGram: 'Price Per Gram',
          pricePerUnit: 'Price Per Unit',
          purchaseOrder: 'Purchase Order',
          requestedAmount: 'Requested Amount',
          salesOrderNumber: 'Sales Order Number',
          soNumber: 'S.O. Number',
          soTitle: 'S.O. Title',
          tax: 'Tax',
          transferFee: 'Transfer Fee',
          transferType: 'Transfer Type',
          unitsCount: 'Total # of Units'
        },
        orderHistory: {
          actions: 'Actions',
          price: 'Price',
          product: 'Product',
          productsIn: 'Products In',
          subTotal: 'Sub Total',
          taxes: 'Taxes',
          total: 'Total',
          unitId: 'Unit Id',
          unitSize: 'Unit Size'
        },
        table: {
          contact: 'Contact',
          date: 'Date Ordered',
          delivery: 'Quoted Delivery',
          number: '# of Packages',
          partner: 'Partner',
          partnerPO: 'Partner P.O.',
          sales: 'Sales Order #',
          status: 'Order Status',
          total: 'Order Total'
        },
        title: 'Sales Orders'
      },
      supplyChain: {
        incomingInventory: 'Incoming Inventory',
        inventoryReceipts: 'Inventory Receipts',
        outgoingInventory: 'Outgoing Inventory',
        purchaseOrders: 'Purchase Orders',
        returnedItems: 'Returned Items',
        salesOrders: 'Sales Orders',
        searchReceipts: 'Search Receipts',
        searchTransfers: 'Search Transfers',
        supplyChainMapping: 'Supply Chain Mapping',
        title: 'Supply Chain',
        transfers: 'Transfers'
      },
      testResults: {
        Integrator: 'Integrator',
        actions: {
          importResults: 'Import Results'
        },
        active: 'Active',
        activeHeader: 'Test Results',
        add: {
          failed: 'Failed to add Test Results',
          success: 'Successfully added Test Results',
          title: 'Add Test Results'
        },
        averages: 'Averages',
        averagesHeader: 'Average Test Results',
        edit: 'Edit',
        extractionEligible: 'Extraction Eligible',
        failed: 'Failed',
        fetching: 'Fetching',
        form: {
          active: 'Active',
          batchName: 'Batch',
          invalidPackage: '%{package_code} can not have test results',
          labPartner: 'Lab Partner',
          latestResult: 'Latest Result',
          leafLabResultAlert: 'Lab results obtained from LEAF can not be modified',
          noPackagesSelected: 'No packages were selected to test.',
          packageId: 'Package ID',
          packages: 'Tested Packages',
          productName: 'Product Name',
          searchPackage: 'Search Package',
          selectPackage: 'Select Package',
          testingDate: 'Testing Date',
          testingId: 'Testing ID'
        },
        get: {
          biotrackFailed: 'Failed to import results',
          failed: 'Failed to get Test Results',
          leafEmpty: 'No new results were found',
          leafFailed: 'Failed to import results'
        },
        goToResults: 'Go To Results',
        historical: 'Historical',
        historicalHeader: 'Historical Test Results',
        importTestNotification:
          'We have started the process of importing your lab results.  This may take some time.  You will be sent a notification when the import process is complete.',
        importTests: 'Import',
        importingTestResults: 'Importing Test Results',
        inHouse: 'In House',
        lotNumber: 'Lot Number',
        modify: {
          failed: 'Failed to modify Test Results',
          success: 'Successfully modified Test Results',
          title: 'Modify Test Results'
        },
        noStatus: '-',
        noTestResults: 'No Test Results Available',
        packageCode: 'Package Code',
        partnerName: 'Partner Name',
        passed: 'Passed',
        productName: 'Product Name',
        productsModalHeader: 'Products',
        retestEligible: 'Retest Eligible',
        show: 'Show',
        showResults: 'Show Results',
        source: 'Source',
        status: 'Status',
        table: {
          cannabinoidPotency: 'Cannabinoid Potency',
          cannabinoidPotencyAverage: 'Average Cannabinoid Potency',
          eligibleForExtraction: 'Eligible for Extraction',
          eligibleForRetest: 'Eligible for Retest',
          emptyResults: 'N/A',
          extractionEligible: 'Extraction Eligible',
          heavyMetal: 'Heavy Metals',
          heavyMetalAverage: 'Average Heavy Metal',
          lotId: 'Lot ID',
          microbial: 'Microbial',
          microbialAverage: 'Average Microbial',
          mycotoxin: 'Mycotoxin',
          mycotoxinAverage: 'Average Mycotoxin',
          packages: 'Packages',
          pesticide: 'Pesticide',
          pesticideAverage: 'Average Pesticide',
          productAverage: 'Product',
          products: 'Product(s)',
          retestEligible: 'Retest Eligible',
          solvents: 'Solvents',
          solventsAverage: 'Average Solvents',
          status: 'Status',
          terpeneProfile: 'Terpene Profile',
          terpeneProfileAverage: 'Average Terpene Profile',
          testId: 'Test ID',
          testingDate: 'Testing Date',
          view: 'View',
          viewResults: 'View Results'
        },
        testingDate: 'Testing Date',
        view: 'View',
        viewTitle: 'View Test Results'
      },
      testingCompliance: {
        addTest: 'Add Test',
        cannabinoidPotency: {
          cbc: 'CBC',
          cbcv: 'CBCV',
          cbd: 'CBD',
          cbda: 'CBDA',
          cbdv: 'CBDV',
          cbg: 'CBG',
          cbga: 'CBGA',
          cbgm: 'CBGM',
          cbgv: 'CBGV',
          cbl: 'CBL',
          cbn: 'CBN',
          cbv: 'CBV',
          d8thc: 'D8 THC',
          d9thc: 'D9 THC',
          d9thca: 'D9 THCA',
          thc: 'THC',
          thca: 'THCA',
          thcv: 'THCV',
          title: 'Cannabinoid',
          total_cb_profile: 'Total Cannabinoid Profile'
        },
        foreignMaterials: {
          hair: 'Hair',
          insects: 'Insects',
          other: 'Other',
          plant_other: 'Plant other',
          plant_stems: 'Plant stems',
          title: 'Foreign Materials',
          titleAverage: 'Average Foreign Materials'
        },
        heavyMetals: {
          arsenic: 'Arsenic',
          cadmium: 'Cadmium',
          lead: 'Lead',
          mercury: 'Mercury',
          title: 'Heavy Metals'
        },
        microbials: {
          animalParts: 'Animal Parts',
          aspergillusFlavus: 'Aspergillus flavus',
          aspergillusFumigatus: 'Aspergillus fumigatus',
          aspergillusNiger: 'Aspergillus niger',
          aspergillusSpp: 'Aspergillus Spp.',
          aspergillusTerreus: 'Aspergillus terreus',
          botrytisCinerea: 'Botrytis cinerea',
          cladosporiumHerbarum: 'Cladosporium Herbarum',
          coli: 'E. Coli',
          coli_and_salmonella: 'E. Coli and Salmonella',
          epicoccumNigrum: 'Epicoccum Nigrum',
          fecalContamination: 'Fecal Contamination',
          gramNegativeBacteria: 'Gram-negative Bacteria',
          insectParts: 'Insect Parts',
          mold: 'Mold',
          pAeruginosa: 'P. Aeruginosa',
          sAureus: 'S. Aureus',
          salmonella: 'Salmonella',
          sclerotiniaSclerotiorum: 'Sclerotinia Sclerotiorum',
          title: 'Microbial',
          totalAerobicBacteria: 'Total Aerobic Bacteria',
          totalColiforms: 'Total Coliforms',
          yeast: 'Yeast',
          yeastMold: 'Yeast/Mold'
        },
        mycotoxins: {
          aflaToxins: 'Aflatoxins',
          aflatoxins: 'Aflatoxins',
          alfatoxinB1: 'Aflatoxin B1',
          alfatoxinB2: 'Aflatoxin B2',
          alfatoxinG1: 'Aflatoxin G1',
          alfatoxinG2: 'Aflatoxin G2',
          citrinin: 'Citrinin',
          ergotAlkaloids: 'Ergot Alkaloids',
          fusarim: 'Fusarim',
          ochraToxin: 'Ochratoxin',
          ochratoxin: 'Ochratoxin',
          ochratoxinA: 'Ochratoxin A',
          pptulin: 'Patulin',
          title: 'Mycotoxin',
          total_mycotoxins: 'Total mycotoxins'
        },
        noActivePackages: 'There are no active packages associated with this test result.  It may not be edited.',
        pesticides: {
          abamectin: 'Abamectin',
          acephate: 'Acephate',
          acequinocyl: 'Acequinocyl',
          acetamiprid: 'Acetamiprid',
          aldicarb: 'Aldicarb',
          azoxystrobin: 'Azoxystrobin',
          befentrin: 'Befentrin',
          bifenazate: 'Bifenazate',
          boscalid: 'Boscalid',
          captan: 'Captan',
          carbaryl: 'Carbaryl',
          carbofuran: 'Carbofuran',
          chlorantraniliprole: 'Chlorantraniliprole',
          chlorfenapyr: 'Chlorfenapyr',
          chlorpyrifos: 'Chlorpyrifos',
          clofentezine: 'Clofentezine',
          cyfluthrin: 'Cyfluthrin',
          cypermethrin: 'Cypermethrin',
          daminozide: 'Daminozide',
          ddvp_dichlorvos: 'Ddvp Dichlorvos',
          diazinon: 'Diazinon',
          dimethoate: 'Dimethoate',
          dimethomorph: 'Dimethomorph',
          ethoprophos: 'Ethoprophos',
          etofenprox: 'Etofenprox',
          etoxazole: 'Etoxazole',
          fenhexamid: 'Fenhexamid',
          fenoxycarb: 'Fenoxycarb',
          fenpyroximate: 'Fenpyroximate',
          fipronil: 'Fipronil',
          flonicamid: 'Flonicamid',
          fludioxonil: 'Fludioxonil',
          hexythiazox: 'Hexythiazox',
          imazalil: 'Imazalil',
          imidacloprid: 'Imidacloprid',
          kresoxim_methyl: 'Kresoxim Methyl',
          malathion: 'Malathion',
          metalaxyl: 'Metalaxyl',
          methiocarb: 'Methiocarb',
          methomyl: 'Methomyl',
          methyl_parathion: 'Methyl Parathion',
          mgk_264: 'Mgk 264',
          myclobutanil: 'Myclobutanil',
          naled: 'Naled',
          oxamyl: 'Oxamyl',
          paclobutrazol: 'Paclobutrazol',
          pcnb: 'PCNB',
          permethrinsa: 'Permethrinsa',
          phosmet: 'Phosmet',
          piperonylButoxide: 'Piperonyl Butoxide',
          prallethrin: 'Prallethrin',
          propiconazole: 'Propiconazole',
          propoxur: 'Propoxur',
          pyrethrin: 'Pyrethrin',
          pyridaben: 'Pyridaben',
          spinetoram: 'Spinetoram',
          spinosad: 'Spinosad',
          spirotetramat: 'Spirotetramat',
          spirotetramet: 'Spirotetramet',
          spiroxamine: 'Spiroxamine',
          tebuconazole: 'Tebuconazole',
          thiacloprid: 'Thiacloprid',
          thiamethoxam: 'Thiamethoxam',
          title: 'Pesticides',
          trifloxystrobin: 'Trifloxystrobin'
        },
        residualSolvents: {
          acetone: 'Acetone',
          benzene: 'Benzene',
          btanes: 'Btanes',
          butanes: 'Butanes',
          chloroform: 'Chloroform',
          componentMixInDimethylSulfoxide_15: '15 Component Mix in Dimethyl Sulfoxide',
          componentMixInDimethylSulfoxide_2: '2 Component Mix in Dimethyl Sulfoxide',
          componentMixInDimethylSulfoxide_24: '24 Component Mix in Dimethyl Sulfoxide',
          componentMixInDimethylSulfoxide_5: '5 Component Mix in Dimethyl Sulfoxide',
          componentMixInDimethylSulfoxide_8: '8 Component Mix in Dimethyl Sulfoxide',
          cyclohexane: 'Cyclohexane',
          dichloromethane: 'Dichloromethane',
          ethane: 'Ethane',
          ethanol: 'Ethanol',
          ethyl_acetate: 'Ethyl Acetate',
          heptane: 'Heptane',
          heptaneResidualSolvent: 'Heptane',
          hexanes: 'Hexanes',
          methane: 'Methane',
          methanol: 'Methanol',
          methylbutane_2: '2-Methylbutane',
          methylpropane_2: '2-Methylpropane',
          nHexane: 'n-Hexane',
          nPentane: 'n-Pentane',
          pharmResidualSolvent: 'Pharm Residual Solvent',
          propane: 'Propane',
          propaneResidualSolvent: 'Propane',
          propanol_2: '2-Propanol',
          residualSolventTotal: 'Residual Solvent total',
          title: 'Residual Solvents',
          toluene: 'Toluene',
          xylene: 'Xylene'
        },
        selectTestPlaceHolder: 'Select Test',
        shipment: 'Prior to shipment we are required to test for',
        terpeneProfile: {
          a_terpinene: 'a-Terpinene',
          bCaryophyllene: 'B Caryophyllene',
          bMyrcene: 'B Myrcene',
          bisabolol: 'Bisabolol',
          bpinene: 'B Pinene',
          camphene: 'Camphene',
          carene: 'Carene',
          caryophylleneOxide: 'Carophyllene Oxide',
          eucalyptol: 'Eucalyptol',
          geraniol: 'Geraniol',
          guaiol: 'Guaiol',
          humulene: 'Humulene',
          isopulegol: 'Isopulegol',
          limonene: 'Limonene',
          linalool: 'Linalool',
          ocimene: 'Ocimene',
          p_cymene: 'p-Cymene',
          pinene: 'Pinene',
          terpinolene: 'Terpinolene',
          title: 'Terpene Profile',
          trans_nerolidal: 'Trans Nerolidal',
          trans_nerolidol: 'Trans Nerolidol',
          y_terpinene: 'y-Terpinene'
        },
        testLabel: 'For %{title} select %{title}',
        title: 'Cultivation: Testing Compliance',
        waterActivity: {
          moistureContent: 'Moisture Content',
          moistureContentWaterActivity: 'Water Activity Rate',
          title: 'Water Activity',
          titleAverage: 'Average Water Activity'
        }
      },
      transfer: {
        form: {
          createDestination: 'Create Destination',
          inventoryReceiptFiles: 'Inventory Receipt Files',
          name: 'Name',
          totalWeight: 'Total Weight'
        }
      },
      transfers: {
        active: 'View Active Transfers',
        cancel: 'Cancel Transfer',
        cancelProcess: {
          failed: 'Failed to Cancel Transfer',
          success: 'Successfully Cancelled Transfer'
        },
        cancellationOfTransfer: 'Cancellation Of Transfer',
        checkLabResults: {
          failed: 'Failed to find lab results for inventory',
          forLabAlreadyPassed: 'This package cannot be sent to the Lab since it already has passed the tests.',
          forLabAlreadyPassedPackage:
            'Package %{packageCode} cannot be sent to the Lab since it already has passed the tests.',
          forLabNoRetest: 'This package cannot be sent to the Lab since is not eligible for re-testing.',
          noPassedTest: 'Selected package does not have passed test results.',
          noPassedTestPackage: "Package %{packageCode} cannot be transfer as it doesn't have passed lab results.",
          onHold: 'Selected package is on hold and can be transferred only to Lab.',
          onHoldPackage: "Package %{packageCode} cannot be transfer as it doesn't have passed lab results.",
          packageFailedLabResults:
            'Package ID %{packageCode} test results have failed status. All packages require passed test results to be eligible for transfer.',
          packageMissingLabResults:
            'Package ID %{packageCode} has no test results associated. All packages require test results to be eligible for transfer.',
          waValidateTransfer: {
            failed: 'Package ID %{packageCode} failed to find lab results for inventory',
            forLabAlreadyPassed:
              'Package ID %{packageCode} cannot be sent to the Lab since it already has passed the tests.',
            forLabNoRetest: 'Package ID %{packageCode} cannot be sent to the Lab since is not eligible for re-testing.',
            noPassedTest: 'Package ID %{packageCode} does not have passed test results.',
            onHold: 'Package ID %{packageCode}  is on hold and can be transferred only to Lab.'
          }
        },
        create: 'Create Transfer',
        edit: 'Edit Transfer',
        form: {
          DBA: 'DBA',
          addPackage: 'Add Another Package',
          addPrePacks: 'Add Pre-Packs',
          additionalTracking: 'Additional Tracking',
          allergens: 'Allergens',
          allergensFile: 'Allergens File',
          applyStorageLocation: 'Apply Storage Location to all Packages',
          arrivalTime: 'Arrival Time',
          batch: 'Batch',
          calculatedPrice: 'Calculated Total Price',
          calculatedWeight: 'Calculated Total Weight',
          canceled: 'Canceled',
          cancelled: 'Cancelled',
          completed: 'Completed',
          contact: 'Contact',
          containerType: 'Container Type',
          containersNumber: '# of Containers',
          createDestination: 'Create New Destination',
          createDriver: 'Create New Driver',
          createPartner: 'Create New Partner',
          created: 'Created',
          createdDate: 'Created Date',
          dateFilled: 'Date Filled',
          dateOrdered: 'Date Ordered',
          dateReceived: 'Date Received',
          dba: 'DBA',
          deleteRow: 'Delete Row',
          departureTime: 'Departure Time',
          description: 'Description',
          destination: 'Destination',
          destinationTotal: 'Destination Total',
          directions: 'Directions To Location',
          documentManagement: 'Document Management',
          driver: 'Driver',
          driverInfo: 'Driver Information',
          driverLicense: 'Driver License',
          duplicatePackageLocationError: 'Package/Location combination must be unique',
          enterIngredients: 'Enter Ingredients and Allergens',
          exceedQuantityError: 'Cannot exceed quantity available',
          exciseTaxDue: 'Excise Tax Due',
          expirationDate: 'Expiration Date',
          failedToCancelTransfer: 'Failed To Cancel Transfer',
          grams: 'Gram(s)',
          grossWeight: 'Gross Weight',
          harvestBatch: 'Harvest Batch',
          harvestDate: 'Harvest Date',
          harvestWasteType: 'Harvest Waste Type',
          incomingTransfer: 'Incoming Transfer',
          ingredients: 'Ingredients',
          ingredientsAndAllergens: 'Ingredients & Allergens',
          ingredientsFile: 'Ingredients File',
          integrationStatus: 'Integration Status',
          inventoryReceipt: 'Inventory Receipt',
          inventoryReceiptFiles: 'Inventory Receipt Files',
          inventoryReceiptTotal: 'Inventory Receipt Total',
          inventoryType: 'Inventory Type',
          isForExtraction: 'Intended for Extraction',
          item: 'Item',
          itemsFulfilled: 'Items Fulfilled',
          itemsRequested: 'Items Requested',
          leafGlobalId: 'Leaf Global ID',
          licenseNumber: 'License Number',
          licensePlate: 'License Plate',
          lineTotal: 'Line Total',
          lotNumber: 'Lot Number',
          make: 'Make',
          maxItemsWithIntegratedSoError:
            'Maximum number of packages per transfer at this time is %{maxLine} please adjust or create a new sales order in order to comply.',
          model: 'Model',
          name: 'Name',
          notes: 'Notes',
          open: 'Open',
          orderNumber: 'Order Number',
          orderStatus: 'Order Status',
          orderTotal: 'Order Total',
          orderTotals: 'Order Totals',
          outForDelivery: 'Out for Delivery',
          out_for_delivery: 'Out for Delivery',
          packageId: 'Package Id',
          packageTotal: 'Package Total',
          packages: 'Packages',
          packagesCount: '# of Packages',
          partner: 'Partner',
          partnerDiscount: 'Partner Discount',
          partnerList: 'Partner List',
          partnerPO: 'Partner P.O.',
          phoneNumber: 'Phone Number',
          prePackWeight: 'Pre Pack Weight',
          pricePerGram: 'Price Per Gram',
          pricePerUnit: 'Price Per Unit',
          printLabels: 'Print Labels',
          producer: 'Producer',
          producerName: 'Producer Name',
          productionBatch: 'Production Batch',
          productionDate: 'Production Date',
          purchaseOrder: 'Purchase Order',
          qtyAvailable: 'Qty Available',
          quantity: 'Quantity',
          receiveTransfer: 'Receive Transfer',
          received: 'Received',
          receivedGross: 'Received Gross',
          receivedNet: 'Received Net',
          requestAmount: 'Requested Amount',
          requestNet: 'Requested Net',
          selectItem: 'Select Item',
          selectOrder: 'Select Order',
          selectPartner: 'Select Partner',
          sentGross: 'Sent Gross',
          sentGrossError: 'Sent Gross should be an integer',
          sentNet: 'Sent Net',
          sentNetError: 'Sent Net should be an integer',
          sizeLimit: 'Maximum Filesize: 4 MB',
          stateLicense: 'State License',
          status: 'Status',
          storageLocation: 'Storage Location',
          testingId: 'Testing ID',
          totalCalcPrice: 'Total Calculated Price',
          totalCalcWeight: 'Total Calculated Weight',
          totalCost: 'Total Cost',
          totalLinePrice: 'Total Line Item Price',
          totalWeight: 'Total Weight',
          totalWeightOrdered: 'Total Weight Ordered',
          tracking_id: 'Tracking Id',
          transferFee: 'Transfer Fee',
          transferLimitation: 'You cannot create a Transfer between these facility types',
          transferPackages: 'Transfer Packages',
          transferStatus: 'Transfer Status',
          transferType: 'Transfer Type',
          typeLimit: 'Allowed Extensions: PDF, DOC, DOCX, JPG, GIF and PNG',
          unitDiscount: 'Unit Discount',
          unitPrice: 'Unit Price',
          unitsOrdered: 'Units Ordered',
          unitsReceived: 'Units Received',
          unitsTotalNumber: 'Total # of Units',
          uom: 'UOM',
          upload: 'Upload',
          uploadInvoice: 'Upload Invoice',
          vehicle: 'Vehicle',
          vehicleInfo: 'Vehicle Information',
          washington24HourHoldDate:
            'Washington requires a 24-hour hold on transfers. Date must be after creation date.',
          washington24HourHoldTime:
            'Washington requires a 24-hour hold on transfers. This value combined with Date Filled must be 24 hours after creation of this transfer.',
          wholeAmountError: 'Whole amount of the lot should be transferred'
        },
        get: {
          purchaseOrdersFail: 'Failed to get Order'
        },
        getTransfer: {
          failed: 'Failed to get transfer.'
        },
        hasReturnedItems: 'This transfer has returned items, please confirm which you want to restock.',
        header: 'Transfers',
        inActive: 'View In-Active Transfers',
        integrationError: 'Integration Error',
        loading: 'Loading Transfer',
        modify: 'Modify Transfer',
        printInvoice: 'Print Invoice',
        printInvoiceFailed: 'Failed to print Invoice',
        printManifest: 'Print Manifest',
        printManifestFailed: 'Failed to print Manifest',
        receiveTransfer: 'Inventory Receipt',
        returnedItemsValidationError: 'Please ensure all returned items have a storage location.',
        returnedItemsWarning:
          "Some of your transferred inventory was returned. Do you want to restock returned inventory? If not, your transfer will stay as 'Out of Delivery'.",
        saving: 'Saving Transfer',
        transferAlreadyReceived: 'The transfer cannot be edited because it has already been received'
      },
      vehicles: {
        actions: {
          add: 'Add Vehicle',
          delete: 'Delete Vehicle',
          edit: 'Edit Vehicle'
        },
        add: {
          doc: 'Upload Insurance Document',
          fail: 'Failed to add vehicle',
          success: 'Successfully added vehicle'
        },
        addTitle: 'Add Vehicle',
        delete: {
          fail: 'Failed to delete vehicle',
          success: 'Successfully deleted vehicle'
        },
        edit: {
          fail: 'Failed to edit vehicle',
          success: 'Successfully edited vehicle'
        },
        editTitle: 'Edit Vehicle',
        insuranceInfo: 'Insurance Information',
        listingTitle: 'Vehicles Listing',
        profileImage: 'Profile Image',
        table: {
          color: 'Color',
          insurance: 'Insurance Company',
          insureDrivers: 'Insured Drivers',
          licensePlate: 'License Plate #',
          make: 'Make',
          model: 'Model',
          nickname: 'Nickname',
          nicknameVehicle: 'Vehicle Nickname',
          phone: 'Phone',
          plateNumber: 'Plate Number',
          policyNumber: 'Policy Number',
          vin: 'VIN',
          year: 'Year'
        },
        vehicleInfo: 'Vehicle Information',
        viewInsuranceDocument: 'View Insurance Document',
        vinLengthValidation: 'VINs for vehicles produced after 1981 should be 17 digits.',
        yearMaxValidation: "Year can't be older %{year}",
        yearMinValidation: "Year can't be younger %{year}"
      },
      wasteCompliance: {
        get: {
          failed: 'Failed to get Waste Compliance'
        },
        save: {
          failed: 'Failed to save Waste Compliance'
        },
        title: 'Waste & Destruction Compliance',
        trackDuring: 'We are required to track waste during',
        trackWaste: 'We are required to track waste packages',
        transferWaste: 'We are required to transfer waste',
        transferringDays: 'Transferring days',
        transferringWaste: 'Transferring Waste',
        wasteLocationRequired: 'Storage location is required when tracking waste packages',
        wastePackageLocation: 'Store waste packages at',
        wastePackages: 'Waste Packages'
      }
    },
    cureOrderProducts: {
      get: {
        failed: 'Failed to get Product Delivery Methods'
      },
      remove: {
        failed: 'Failed to remove Product Delivery Method'
      },
      save: {
        failed: 'Failed to set Product Delivery Method'
      }
    },
    curePurchasableAmount: {
      byCategory: 'Purchase Limits By Category',
      get: {
        failed: 'Failed to load Purchasable Amount'
      },
      inOrder: 'In Order',
      limit: 'Limit',
      physicianName: 'Physician Name',
      startDate: 'Start Date',
      title: 'Purchasable Amount'
    },
    customer: {
      active: 'Active',
      addModOrder: 'ADD/MODIFY',
      caregivers: 'Caregivers',
      checkOut: 'CHECK OUT',
      checkedIn: 'Checked In',
      create: {
        renewal: 'Renewal'
      },
      diagnosis: 'Diagnosis',
      fastTrack: 'FULFILL',
      fillOrder: 'FILL ORDER',
      form: {
        fulfillmentMethod: 'Fulfillment Method'
      },
      gender: 'Gender',
      id: 'Id',
      modify: {
        failed: 'Failed'
      },
      modifyOrder: 'ADD PRODUCTS',
      number: 'Number',
      orderPlaced: 'Order Placed',
      payment: 'PAYMENT',
      paymentIsNotCompleted: 'Payment Is Not Completed',
      physician: 'Physician',
      productsMenu: 'PRODUCT MENU',
      startOrder: 'START ORDER',
      tags: 'Tags',
      title: {
        delivery: 'Delivery',
        inStore: 'In Store',
        online: 'Online',
        packaging: 'Packaging',
        payment: 'Payment',
        pickup: 'Pickup'
      },
      type: 'Type'
    },
    customers: {
      actions: {
        createPatient: 'Create Patient',
        modifyPatient: 'Modify Patient'
      },
      activate: {
        failed: 'Failed to activate patient',
        success: 'Successfully activated patient'
      },
      addToQueue: {
        fail: 'Unable to add patient to queue.',
        failed: 'Unable to add patient to queue.',
        success: 'The patient was successfully added to the queue.'
      },
      checkin: {
        details: 'Details',
        noResultsFound: 'No Results Found'
      },
      create: {
        active: 'Active',
        addAddress: 'Add another delivery address',
        addCaregiver: 'Add New Caregiver',
        addCaregiverAddress: 'Add a caregiver address',
        addNewDocument: 'Add New Document',
        additionalMarijuanaInfo: 'Additional Medical Marijuana Patient Information',
        additionalMarijuanaInfoHint: '(Not Required for recreational users.)',
        address: 'Address',
        addressNumber: 'Address #%{number}',
        apartment: 'Apt #',
        canadianRegistrationForm: 'Canadian Registration Form',
        caregiverAddr: 'Caregiver Address',
        caregiverAddress: 'Caregiver Address #%{number}',
        caregiverBirthDate: 'Caregiver Birth Date',
        caregiverEmail: 'Caregiver Email',
        caregiverFirstName: 'Caregiver First Name',
        caregiverInfo: 'Caregiver Information',
        caregiverLastName: 'Caregiver Last Name',
        caregiverMedicalId: 'Caregiver Medical ID',
        caregiverMiddleName: 'Caregiver Middle Name',
        caregiverNumber: 'Caregiver #%{number}',
        caregiverPhone: 'Caregiver Phone',
        caregiverPhoto: 'Caregiver Photo',
        caregiverStateId: 'Caregiver State ID',
        city: 'City',
        clinicName: 'Clinic Name',
        complianceLimits: 'Compliance Limits',
        country: 'Country',
        coupledFacilities: 'Coupled facilities may grow',
        coupledFacilitiesPlantsFlower: 'Coupled facilities may grow %{value} plants in flower',
        coupledFacilitiesPlantsVeg: 'Coupled facilities may grow %{value} plants in veg',
        coupledFacilitiesTotalPlants: 'Coupled facilities may grow %{value} total plants',
        currentMarijuanaProvider: 'Current Marijuana Provider',
        customerAddress: 'Customer Address',
        customerGroups: 'Special Grouping',
        daily: 'Daily',
        dateAvailableToSwitch: 'Date Avail. to Switch',
        dateHasWrongFormat: 'Date Has Wrong Format',
        dateOfBirth: 'Date of Birth',
        diagnosis: 'Diagnosis',
        disabled_veterans: 'Disabled Veterans',
        doNotOverride: 'do not override',
        documentHint: 'Displays in your user account, and in the Recent User Activity section of the Dashboard',
        documentId: 'Document Id',
        documents: 'Documents',
        driverLicenseId: 'Driver License Id',
        driversLicense: 'Enter license number',
        driversLicenseExp: 'Enter license expiration',
        driversLicenseExpirationDate: 'Driver License Expiration Date',
        effectiveDate: 'Effective Date',
        email: 'Email Address',
        emailLoginInformation: 'Email Login Information',
        enter: 'enter',
        enterBirthDate: 'Enter Birth Date',
        enterEmail: 'Enter Email',
        enterFirstName: 'Enter First Name',
        enterLastName: 'Enter Last Name',
        enterPhoneNumber: 'Enter Phone Number',
        expiration: 'Expiration',
        extendedSalesLimit: 'Extended Sales Limit',
        facilityPosses: 'Facility may possess %{value} g',
        facilitySalesLimit: 'The facility sales limit is currently set at %{grams} G.',
        facilitySell: 'Facility may sell %{value} g',
        failed: 'Failed to create patient.',
        firstName: 'First Name',
        firstTime: 'First Time',
        frequency: 'Frequency Of Use',
        futureDateOfBirth: 'Future Date Of Birth',
        gender: 'Patient Gender',
        identificationMethod: 'Identification Method',
        identificationMethodError: 'Identification Method Error',
        inState: 'In-State',
        inactive: 'Inactive',
        intakeForm: 'Intake Form',
        invalidFormat: 'Invalid Format',
        inventoryLimits: 'Inventory Limits',
        lastName: 'Last Name',
        limitOrder: 'Limit order total to',
        limitOrderRow: 'Limits are reset every %{number} %{period}(s)',
        limitOrderTotalTo: 'Limit Order Total To',
        mailingAddress: 'Mailing Address',
        medical: 'Medical',
        medicalId: 'Medical Marijuana ID',
        medicalIdError: 'Enter Medical Marijuana ID',
        medicalMarijuanaId: 'Medical Marijuana Id',
        medicalMarijuanaIdFormat: 'Medical Marijuana Id format must be xxxx-xxxx-xxxx-xxxx-xxxx.',
        medical_ca: 'MMIC',
        middleName: 'M.I.',
        mmic: 'Medical',
        monthly: 'Monthly',
        no: 'No',
        noMedicalProfessionalComments: 'No medical professional comments',
        notes: 'Notes',
        onlineOrderStatus: 'Online Order Status',
        onlineOrdering: 'Online Ordering',
        outOfState: 'Out Of State',
        override: 'override',
        password: 'password',
        pastDateOfBirth: 'Past Date Of Birth',
        patientInfo: 'Patient Information',
        patientPhoto: 'Patient Photo',
        per: 'per',
        personalUseProducer: 'Personal Use Producer',
        phone: 'Phone Number',
        physicianAddress: 'Address',
        physicianDeaNumber: 'DEA Number',
        physicianInformation: 'Physician Information',
        physicianLicense: 'License',
        physicianName: 'Name',
        physicianNotes: 'Notes',
        physicianRecommendationDate: 'Physician Recommendation Date',
        physicianRecord: 'Physician record',
        physicianStateLicense: 'State License',
        physicianTemporaryId: 'Physician/State Temporary ID',
        plantsFlower: 'plants in flower',
        plantsVeg: 'plants in veg',
        preferredContact: 'Preferred Contact',
        preferredService: 'Preferred Customer Service Representative',
        presentDriverLicense: "Recreational customers must present a valid driver's license.",
        primaryFacility: 'Primary Facility',
        primaryFacilityIsRequired: 'A primary facility is required',
        provideBirthYear: 'You must provide a birth year, this is not supplied by the registry.',
        qualifyingCondition: 'Qualifying Condition',
        recreational: 'Recreational',
        recreationalAgeTwentyOne: 'Recreational patient must be at least {AGE} years old',
        referralSource: 'Referral Source',
        refillsAuthorized: 'Refills Authorized',
        removeCaregiver: 'Remove Caregiver #%{number}',
        removeCaregiverAddress: 'Remove caregiver address #%{number}',
        rewardCustomerGroup: 'Reward Customer Group',
        salesLimitError: 'Current Facility Setting for Sales Limits is %{limit}',
        saveAndCheckIn: 'Save and Check In',
        scanCaregiverID: 'Scan Caregiver ID',
        scanLicense: 'Scan License',
        scanPatientID: 'Scan Patient ID',
        seeRegistry: 'Please see registry for comments, last updated comment made %{date}.',
        select: 'Select',
        selectGender: 'Select Gender',
        selectPreferredContact: 'Select Preferred Contact',
        selectStatus: 'Select Status',
        selectTaxExempt: 'Select Tax Exempt',
        selectType: 'Select Type',
        specialGrouping: 'Special Grouping',
        state: 'State',
        stateCaregiverId: 'State Caregiver Id',
        stateId: 'State/Government ID',
        statePatientIDExpirationDate: 'State Patient ID Expiration Date',
        statePatientId: 'State Patient ID',
        status: 'Status',
        street: 'Street',
        success: 'The patient was created successfully.',
        tags: 'Tags',
        taxExempt: 'Tax Exempt',
        temporaryPaperwork: 'Temporary Paperwork',
        terminalIllness: 'Terminal Illness',
        title: 'Patients: Create Patient/Customer',
        totalPlants: 'total plants',
        type: 'Customer Type',
        useForMailingAddress: '*use for mailing address',
        useProfileEmail: 'Use Profile Email',
        validUntil: 'Valid Until',
        weekly: 'Weekly',
        yes: 'Yes',
        zipCode: 'Zip Code'
      },
      details: {
        concentrate: 'Most Frequent Concentrate',
        currentTotal: 'Current Total',
        driverLicenseID: 'Driver License/ID',
        edible: 'Most Frequent Edible',
        expiration: 'Expiration',
        extendedPlantCountSalesLimit: 'Extended Plant Count/Sales Limit',
        favorites: 'Favorites',
        fileUploads: 'File Uploads',
        flower: 'Most Frequent Flower',
        intakeForm: 'Intake Form',
        lastMonth: 'Last Month',
        lastOrder: 'Last Order',
        lastThreeMonths: 'Last 90 Days',
        lastWeek: 'Last Week',
        loyaltyPoints: 'Loyalty Points',
        medicalMarijuanaId: 'Medical Marijuana ID',
        new_points_total: 'New point(s) total…',
        numberOfOrders: 'Number Of Orders',
        order: 'Order',
        orders: 'Orders',
        rewardsPoints: 'Rewards Points',
        rewards_disabled: 'Rewards are not enabled',
        rewards_view_not_permitted: 'Your permissions do not allow for viewing rewards',
        stateGovernmentIssuedId: 'State/Government Issued ID',
        topical: 'Most Frequent Topical',
        totalSpent: 'Total Spent',
        viewOrderHistory: 'View Order History',
        visitFrequency: 'Visit Frequency',
        visits_90_days: 'Visits 90 Days',
        visits_last_month: 'Visits Last Month',
        visits_last_week: 'Visits Last Week'
      },
      get: {
        failed: 'Failed'
      },
      getBudTenders: {
        failed: 'Failed to get bud tenders.'
      },
      getClinics: {
        failed: 'Failed to get clinics.'
      },
      getCustomer: {
        failed: 'Failed to get patient.'
      },
      getCustomerHistory: {
        failed: 'Failed to load history for patient.'
      },
      getCustomers: {
        failed: 'Failed to get patients.'
      },
      getGroups: {
        failed: 'Failed to get customer groups.'
      },
      getPagers: {
        failed: 'Failed to get pagers.'
      },
      getReferralSources: {
        failed: 'Failed to get referral sources.'
      },
      groups: {
        get: {
          failed: 'Failed to get customer groups.'
        }
      },
      history: {
        currentLabel: 'Updated',
        fieldLabel: 'Field',
        fieldsChanged: 'Fields Changed',
        loadingMessages: {
          addingProductToRefunds: 'Adding Product To Refunds.',
          checkingIn: 'Checking In.',
          fastTrack: 'Fast Tracking to Cart.',
          finalizingRefund: 'Finalizing Refund.',
          gettingOrderDetails: 'Getting Order Details.',
          removingProductFromRefunds: 'Removing Product From Refunds.',
          restockingProducts: 'Restocking product(s).',
          startingAnOrder: 'Starting An Order',
          updatingRefundDetails: 'Updating Refund Details'
        },
        messages: {
          addProductToRefunds: {
            failed: 'Failed to add product to refund',
            success: false
          },
          ordersFailed: 'Failed to load customer orders.',
          ordersSuccess: false
        },
        patientCreated: 'Created Date',
        patientId: 'Patient ID',
        patientName: 'Patient Name',
        previousLabel: 'Previous',
        refundRestock: {
          bodyText: 'You can only refund once. Are you sure?',
          cancelText: 'No',
          modalTitle: 'Complete Refund & Restock',
          okayText: 'Yes'
        },
        title: 'Patient Update History',
        viewHistory: 'View History'
      },
      integration: {
        caregiverFailed: 'Caregiver Failed',
        patientFailed: 'Patient Failed'
      },
      modify: {
        failed: 'Failed to save patient changes.',
        success: 'The patient was modified successfully.',
        title: 'Patients: Modify Patient/Customer'
      },
      physicianNameIsRequiredWithTempPaperwork: 'Physician Name Is Required With Temp Paperwork',
      placeholders: {
        apartment: '123',
        city: 'City',
        clinicName: 'Clinic Name',
        diagnosis: 'Diagnosis',
        email: 'email',
        emailAddress: 'email@address.com',
        enterFirstName: 'Enter First Name',
        enterLastName: 'Enter Last Name',
        firstName: 'Enter First Name',
        id: 'ID',
        identificationMethod: 'Identification Method',
        lastName: 'Enter Last Name',
        password: 'password',
        phoneNumber: '352-555-7890',
        physicianAddress: 'Physician Address',
        physicianLicense: 'Physician License #',
        physicianName: 'Name',
        qty: 'QTY',
        street: 'Street Address',
        tempId: 'Temporary ID'
      },
      registrationEvents: 'Registration Events',
      registrations: {
        fromFacility: 'From Facility',
        noAdditionalDataRequired: 'No Additional Information Required',
        refusalOptions: 'Refusal Options',
        refusalOptionsMap: {
          incomplete_information: 'Incomplete Information',
          invalid_medical_document: 'Invalid Medical Document',
          misleading_information_suspicion: 'Suspicion of False or Misleading Information',
          other: 'Other Reason'
        }
      },
      scan: {
        failed: 'unable to parse scanned ID'
      },
      table: {
        actions: 'Actions',
        activate: 'Activate',
        assignPager: 'Assign Pager',
        birthDate: 'Birth Date',
        budTender: 'Bud Tender',
        caregivers: 'Caregivers',
        caregiversNote: 'If a caregiver is ordering on behalf of a patient, select the caregiver.',
        checkIn: 'Check-In',
        driversLicense: 'Drivers License',
        expiration: 'Expiration',
        firstName: 'First Name',
        groups: 'Groups',
        groupsOf: 'Groups of',
        image: 'Image',
        inactiveGroup: 'inactive group',
        lastName: 'Last Name',
        medIdExpiration: 'Med Id Expiration',
        medicalId: 'Medical Id',
        ohioDataRequiredBody:
          'Please make sure the patient has the following information defined before starting the order: ',
        ohioDataRequiredTitle: 'Missing Data',
        onfleetAddressRequiredBody:
          'In order to create a Delivery Order, you must first ensure that this Patient has an Address.',
        onfleetDataRequiredBody:
          'In order to create a Delivery Order, you must first ensure that this Patient has a Phone Number and Address.',
        onfleetDataRequiredTitle: 'Missing Data',
        onfleetPhoneRequiredBody:
          'In order to create a Delivery Order, you must first ensure that this Patient has a Phone Number.',
        onfleetPhoneRequiredTitle: 'Onfleet Phone Required Title',
        patientId: 'Patient Id',
        phoneNumber: 'Phone Number',
        preferredBudTender: 'Preferred Bud Tender',
        primaryFacility: 'Primary Facility',
        startOrder: 'Start Order',
        statePatientID: 'State Patient ID',
        tempExpiration: 'Temp Expiration',
        textAlert: 'Text Alert',
        view: 'View',
        visitReason: 'Visit Reason'
      },
      title: 'Patient Listing'
    },
    defaultLabel: {
      removeDefault: 'Remove As Default Label For This Page',
      setDefault: 'Set As Default Label For This Page',
      viewAllLabels: 'View All Available Labels'
    },
    driver: 'Driver',
    ei: {
      activeInfusions: {
        actions: {
          cancelProcessing: 'Cancel Infusion',
          completeProcessing: 'Complete Infusion'
        },
        failedLoadAssemblyJobs: 'Failed to load assembly jobs',
        form: {
          cancelFail: 'Failed to cancel assembly job',
          cancelSuccess: 'Successfully canceled assembly job'
        },
        table: {
          assemblyName: 'Assembly Name',
          assemblyType: 'Assembly Type'
        },
        title: 'E&I: Active Infusions'
      },
      activeProcessing: {
        actions: {
          cancelProcessing: 'Cancel Processing',
          completeProcessing: 'Complete Processing',
          modifyLot: 'Modify Lot'
        },
        employeesDetailsPanel: {
          title: 'Employee(s)'
        },
        failedLoadProcessingJobs: 'Failed to load processing jobs',
        failedLoadUsers: 'Failed to load users',
        form: {
          cancelFail: 'Failed to cancel processing job',
          cancelSuccess: 'Successfully canceled processing job'
        },
        lotIDDetailsPanel: {
          title: 'Lot ID'
        },
        table: {
          dateStarted: 'Date Started',
          employees: 'Employee(s)',
          expectedCompletion: 'Expected Completion',
          id: 'Id',
          lotID: 'Lot ID',
          name: 'Name',
          processType: 'Process Type',
          totalWeight: 'Total Weight',
          uom: 'UOM'
        },
        title: 'E&I: Active Processing',
        view: 'View',
        viewEmployees: 'Employees',
        viewLots: 'Lot ID'
      },
      adjustmentReasonsFailed: 'Failed to get Adjustment Reasons.',
      assemblies: {
        activate: 'Activate',
        activation: {
          failed: 'Failed to activate assembly',
          succeed: 'Successfully activated assembly'
        },
        assemblies: 'Assemblies',
        create: {
          failed: 'Failed to create Assembly',
          success: 'Successfully created new Assembly'
        },
        createAssembly: 'Create Assembly',
        deactivate: 'Deactivate',
        deactivation: {
          activeJobsError:
            '%{assemblyName} has active infusion runs assigned. Please complete or cancel infusions before deactivating the assembly.',
          failed: 'Failed to deactivate assembly',
          succeed: 'Successfully deactivated assembly'
        },
        editAssembly: 'Edit Assembly',
        form: {
          addNewDirection: 'Add New Direction',
          addNewIngredient: 'Add New Ingredient',
          assemblyTime: 'Assembly Time',
          cannabinoidTableTitle: 'Cannabinoid Potency in mg per Unit',
          cbc: 'CBC',
          cbd: 'CBD',
          cbdv: 'CBDV',
          cbg: 'CBG',
          cbn: 'CBN',
          costCol: 'Line Item Cost',
          directions: 'Directions',
          emailAssembly: 'Email Assembly',
          ingredientName: 'Ingredient Name',
          ingredients: 'Ingredients',
          item: 'Production Item',
          lessThenCent: 'Current Line Item Cost is less than one cent',
          name: 'Assembly Name',
          printAssembly: 'Print Assembly',
          producing: 'Produces',
          productName: 'Product Name',
          qty: 'Quantity',
          qtyAvailable: 'Qty Available',
          qtyUsed: 'Qty Used',
          quantityCol: 'Qty',
          saveAndDuplicateAssembly: 'Save and Duplicate Assembly',
          saveAssembly: 'Save Assembly',
          step: 'Step %{number}',
          thc: 'THC',
          thca: 'THCA',
          thcv: 'THCV',
          totalCost: 'Total Cost',
          units: 'Each',
          unitsCol: 'UOM',
          uom: 'UOM'
        },
        get: {
          failed: 'Failed to get Assemblies',
          itemFailed: 'Item Failed'
        },
        inactive: 'Inactive',
        modify: {
          failed: 'Failed to modify Assembly',
          success: 'Successfully modified Assembly'
        },
        uomChanged:
          'One of the ingredients in this job has been changed and the job is no longer valid. Please change the ingredient back to the UOM type it originally had.'
      },
      cantCompleteInfusion: "All reservations of the job were canceled. Infusion can't be completed.",
      common: {
        activate: 'Activate',
        active: 'Active',
        inactivate: 'Inactivate',
        inactive: 'Inactive'
      },
      complianceSettings: {
        add: 'Add Excise Tax',
        andBlockAt: 'and block at',
        coupledFacility: 'coupled facility should be alerted within',
        facilitAnd: 'Our facility and',
        facilitWouldLikeToTrack: 'Our facility would like to track excise tax at',
        facilityShouldBe: 'Our facility should be alerted within',
        fail: 'Fail',
        grams: 'grams',
        gramsFor: 'grams for',
        gramsForCategory: 'grams for category',
        per: 'per',
        success: 'Success',
        title: 'E&I: Finished Product Compliance',
        validationBlocker: '`Block` amount must be greater than `Warn` amount'
      },
      infusion: {
        create: {
          actions: {
            failStart: 'Failed to Start Infusion',
            successStart: 'Successfully Started Infusion'
          },
          form: {
            batch: 'Production Run/Batch',
            cannabinoidTableTitle: 'Cannabinoid Potency in mg Required for Assembly',
            completeTime: 'Expected Completion Time',
            currentlySelected: 'Currently Selected',
            employee: 'Assign Employee(s)',
            equipment: 'Assign Equipment',
            package: 'Package ID',
            productName: 'Product Name',
            qtyAssembly: 'Qty  of Assembly',
            qtyAvailable: 'Quantity Available',
            quantityUsed: 'Quantity Used',
            required: 'Required',
            selectAssembly: 'Select Assembly',
            startTime: 'Infusion Start Time',
            storageLocation: 'Storage Location'
          },
          from: {
            quantityUsed: 'Quantity Used'
          },
          title: 'Start Infusion'
        }
      },
      infusions: {
        complete: {
          failed: 'Failed',
          success: 'Success'
        },
        form: {
          assemblyName: 'Assembly Name'
        },
        get: {
          itemFailed: 'Item Failed'
        }
      },
      inputsRequired: 'At least one input is required to start a job',
      inventory: {
        actions: {
          activate: 'Activate',
          completeInfusion: 'Complete Infusion',
          completeProcessing: 'Complete Processing',
          completeRun: 'Complete Run',
          createPropagationMaterial: 'Create Propagation Material',
          destroyPackage: 'Destroy Package',
          modifyLot: 'Modify Lot',
          reportDailyWasteByLocation: 'Report Daily Waste By Location',
          startInfusion: 'Start Infusion',
          startProcessing: 'Start Processing'
        },
        table: {
          addTestResults: 'Add Test Results',
          batchNumber: 'Production Run/Batch',
          dateCreated: 'Date Created',
          lineage: 'Lineage',
          onHold: 'On Hold',
          product: 'Product',
          qualityRating: 'Quality Rating',
          quantity: 'Quantity (Reserved)',
          quantityNoReservation: 'Quantity',
          reservedQty: 'Reserved Qty',
          runName: 'Run Name',
          runType: 'Run Type',
          trackingId: 'Tracking ID',
          uom: 'UOM',
          vendor: 'Vendor'
        },
        tabs: {
          activeIngredients: 'Active Ingredients',
          curing: 'Curing',
          finished: 'Finished Products',
          inactive: 'Inactive Inventory',
          inactiveIngredients: 'Inactive Ingredients',
          infusionconcentrate: 'Infusion Concentrate',
          prerun: 'Pre-Run'
        },
        title: 'Inventory'
      },
      itemPhases: {
        get: {
          failed: 'Failed to get Item Phases'
        }
      },
      notCompatibleInventories:
        'The input inventories you selected cannot be used to produce any outputs per your integration.',
      notesField: {
        add: 'Add note',
        notePlaceholder: 'Type note…',
        title: 'Notes'
      },
      preferredMixes: {
        activation: {
          failed: 'Failed to activate assembly',
          succeed: 'Successfully activated assembly'
        },
        create: {
          failed: 'Failed to create Preferred Mix',
          success: 'Successfully created new Preferred Mix'
        },
        createMix: 'Create Mix',
        createPreferredMix: 'Create Preferred Mix',
        editMix: 'Edit Mix',
        editPreferredMix: 'Edit Preferred Mix',
        form: {
          ingredientName: 'Ingredient Name',
          inputItems: 'Input Items',
          name: 'Preferred Mix Name',
          outputItems: 'Output Items',
          productName: 'Product Name',
          qty: 'Qty',
          saveAndReturn: 'Save & Return',
          savePreferredMix: 'Save Preferred Mix',
          uom: 'UOM'
        },
        inactivation: {
          failed: 'Failed to inactivate Preferred Mix.',
          succeed: 'Successfully inactivated Preferred Mix.'
        },
        modify: {
          failed: 'Failed to modify Preferred Mix',
          success: 'Successfully modified Preferred Mix'
        },
        preferredMixes: 'Preferred Mixes'
      },
      processing: {
        complete: {
          failed: 'Failed to complete processing',
          success: 'Successfully completed processing'
        },
        form: {
          actualCost: 'Line Item Cost',
          actualIngredientsUsed: 'Actual Ingredients Used',
          addItem: 'Add Item',
          addPackage: 'Add Package',
          aroma: 'Aroma',
          assignEmployees: 'Assign Employee(s)',
          assignEquipment: 'Assign Equipment',
          availableWeight: 'Available Weight',
          clarity: 'Clarity',
          color: 'Color',
          completionTime: 'Completion Time',
          consistency: 'Consistency',
          employee: 'Employee',
          employees: 'Employee(s)',
          equipment: 'Equipment',
          expectedCompletionTime: 'Expected Completion Time',
          extractionTime: 'Extraction Time',
          location: 'Location',
          lot: 'Lot',
          name: 'Name',
          newItem: 'New Item',
          newItems: 'New Items',
          newPhase: 'New Phase',
          newQty: 'New Qty',
          newQuantity: 'New Quantity',
          noProcessingTypeAvailable: 'No Processing Type available based on selected items',
          noValidProductAvailable:
            'No valid product available for this Processing Type! To correct this, create a product with the same sub-category as the used Processing Type.',
          nonUnique: 'Combination of Product, Storage Location And Phase must be unique',
          notEnoughInventory: 'There is not enough inventory to complete the processing job',
          note: 'Note',
          notePlaceHolder: 'Type note…',
          outputExceedsInputError: 'Total output weight exceeds total input weight',
          processedPackages: 'Processed Packages',
          processingStartTime: 'Processing Start Time',
          processingType: 'Processing Type',
          productName: 'Product Name',
          productionRun: 'Production Run',
          productionRunLotName: 'Production Run/Lot Name',
          qtyPlanned: 'Qty Planned',
          qtyUsed: 'Qty Used',
          qualityRatings: 'Quality Ratings',
          reportAutoCalculatedWaste: 'Auto Calculated Waste ',
          reportWaste: 'Report Waste',
          reportWasteValidation: "Can't be greater than 'Total Weight Used'",
          returnedPercent: 'Returned %',
          searchProduct: 'Search Product',
          selectProduct: 'Select Product',
          standardCost: 'Standard Cost',
          startTime: 'Start Time',
          stateTracking: 'State Tracking',
          storageLocation: 'Storage Location',
          strain: 'Strain(s)',
          totalQuality: 'Total Quality',
          totalWeightUsed: 'Total Weight Used',
          unacceptableWasteValue: 'There is not enough quantity to produce defined number of units.',
          variance: 'Variance',
          vendor: 'Vendor',
          warnings: {
            cancel: 'Cancel',
            hint:
              'Click Proceed to ignore this warning and complete the job without the required tags.  Clicking Cancel will return you to the page for editing.',
            proceed: 'Proceed',
            product: 'Product',
            quantity: 'Quantity',
            tableText: 'The products listed below do not have assigned package tags.',
            warning:
              'Your state requires you to assign a package tag to complete this job, choosing to not assign a Package Tag at this time may lead to inventory discrepancies.'
          },
          weight: 'Weight'
        },
        get: {
          itemFailed: 'Failed to get Processing Job'
        },
        start: {
          failed: 'Failed',
          success: 'Success'
        }
      },
      processingType: {
        form: {
          loadIngredientsFailed: 'Load Ingredients Failed'
        }
      },
      processingTypes: {
        activationFailed: 'Failed to activate Process Type',
        activationSuccess: 'Successfully activated Process Type',
        create: {
          fail: 'Processing Type not Created',
          success: 'Processing Type is Created',
          title: 'Create Processing Type'
        },
        deactivationFailed: 'Failed to deactivate Process Type',
        deactivationSucceed: 'Successfully deactivated Process Type',
        form: {
          defaultProcessingTimeInvalid: 'Please enter whole numbers',
          directionsStep: 'Step %{step_num}:',
          equipmentUsed: 'Equipment Used',
          failed: 'Failed to get Materials',
          inputSubcategoriesSection: 'Input Sub-Categories',
          loadIngredientsFailed: 'Failed to load unit cost',
          materialsQty: 'Qty',
          materialsUnitCost: 'Line Item Cost',
          materialsUom: 'UOM',
          materialsUsed: 'Materials Used',
          name: 'Process Name',
          outputSubcategoriesSection: 'Output Sub-Categories',
          processCompletionsPhase: 'Process Completion Phase',
          standardProcessTime: 'Standard Process Time (min)',
          startingPhaseName: 'Processing Start Phase',
          subcategoriesBlockError: 'This Block is Required',
          totalCost: 'Total Cost:'
        },
        get: {
          failed: 'Failed to get Processing Types',
          itemFailed: 'Failed to get Processing Type'
        },
        inactivateFailed:
          'Selected Processing Type cannot be inactivated because there is at least one Active Processing job using it',
        listing: {
          activate: 'Activate',
          activeTab: 'Processes',
          completionPhase: 'Completion Phase',
          createProcessType: 'Create Process',
          editProcessType: 'Edit Process',
          failedPrint: 'Failed to print Process Type',
          inactivate: 'Inactivate',
          inactiveTab: 'Inactive',
          inputs: 'Inputs',
          moveToActive: 'move to active',
          name: 'Process Name',
          outputs: 'Outputs',
          print: 'Print',
          printLabel: 'Print',
          standardTime: 'Standard Time',
          startingPhase: 'Starting Phase',
          title: 'Processing Types'
        },
        loading: 'Loading Process Type',
        modify: {
          fail: 'Processing Type not Updated',
          getFailed: 'Failed to load Processing Type',
          success: 'Processing Type is Updated',
          title: 'Modify Processing Type'
        },
        save: 'Save Process',
        saving: 'Saving Process Type',
        uomChanged:
          'One of the ingredients in this job has been changed and the job is no longer valid. Please change the ingredient back to the UOM type it originally had.'
      },
      searchItem: {
        errorAlreadySelected: '%{packageId} items are already selected',
        errorInvalidItem: 'You cannot Start Infusion for Pre-Pack/Unit product',
        errorInvalidItemProcessing: 'You cannot Start Processing for Pre-Pack product',
        errorIsReservedItem:
          '%{packageId} item has been marked as reserved, please remove the reserve to use this inventory',
        errorLabResultFailed: 'Package %{packageId} has failed lab result and cannot be used in this job.',
        errorNoLabResults: "You are not allowed to use the item %{packageId} as it doesn't have a passed lab result.",
        errorNonMedicated: '%{packageId} is non-medicated product',
        errorNotAvailableItem: '%{packageId} has no weight available',
        errorOnHoldItem: '%{packageId} item has been marked as on-hold, please remove the hold to use this inventory',
        errorPrePack: '%{packageId} is Pre-Pack item'
      },
      title: 'E&I: '
    },
    employee: 'Employee',
    equipment: {
      actions: {
        addEquipment: 'Add Equipment',
        failCreate: 'Failed Add Equipment',
        loading: 'Loading Data',
        modifyEquipment: 'Modify Equipment',
        saving: 'Saving Data',
        successCreate: 'Equipment Added'
      },
      form: {
        addEquipment: 'Add Equipment',
        addEquipmentType: 'Add Service Type',
        addVendor: 'Add Vendor',
        energyUsage: 'Energy Usage',
        equipmentImage: 'Upload Image File',
        equipmentProfile: 'Equipment Profile',
        equipmentType: 'Equipment Type',
        frequency: 'Frequency of Service',
        hoursInUse: 'Number of Hours in-use Per Day',
        hoursPerDay: 'Number of Hours in-use Per Day',
        inactiveEquipment: 'Inactive Equipment',
        initialCost: 'Initial Cost',
        kwh: 'kw/h',
        lifeExpectancy: 'Life Expectancy',
        locationAssignment: 'Location Assignment',
        maintenance: 'Maintenance',
        modelName: 'Model Name',
        nextService: 'Next Service',
        orderEquipment: 'Order Equipment',
        placeholderLifeExpectancy: 'days',
        placeholderModelName: 'model name',
        placeholderServiceType: 'client entered',
        placeholderUomEquipProfile: 'Choose',
        placeholderVendor: 'vendor name',
        price: 'Purchase Price',
        reOrderPoint: 'Re-Order Point',
        receiveEquipment: 'Receive Equipment',
        searchPlaceholder: 'Search Equipment',
        serialNumber: 'Serial Number',
        serviceType: 'Service Type',
        timeOwned: 'Time Owned',
        uom: 'UOM',
        uploadPhoto: 'Upload File',
        uploadingPhoto: 'Uploading Photo',
        vendor: 'Vendor'
      },
      get: {
        failed: 'Failed to get equipment'
      },
      listing: {
        activateButton: 'Activate',
        activateFailed: 'Failed to Activate Equipment',
        activateSuccess: 'Equipment is Active',
        adjustButton: 'Adjust',
        avgPurchasePrice: 'Avg. Purchase Price',
        createEquipmentButton: 'Create Equipment',
        deactivateFailed: 'Failed to Deactivate Equipment',
        deactivateSuccess: 'Equipment is Inactive',
        edit: 'Edit',
        editEquipmentButton: 'Edit Equipment',
        energyUsage: 'Energy Usage',
        failedEquipmentTypes: 'Fail to load Equipment Types',
        inactivateButton: 'Inactivate',
        lifeExpectancy: 'Life Expectancy',
        loading: 'Loading Data',
        modelName: 'Model Name',
        onHandQuantity: 'On-Hand Quantity',
        title: 'Equipment Listing',
        vendor: 'Vendor',
        viewActiveButton: 'View Active',
        viewInactiveButton: 'View Inactive'
      },
      modify: {
        activateSuccess: 'Activate Success',
        failed: 'Failed to modify equipment',
        success: 'Successfully modified equipment'
      },
      tabs: {
        ballast: 'Ballast',
        bulbs: 'Bulbs',
        climateControl: 'Climate Control',
        cloner: 'Cloner',
        fertigationMachine: 'Fertigation Machine',
        hoods: 'Hoods',
        irrigation: 'Irrigation',
        misc: 'Misc',
        pots: 'Pots',
        reflector: 'Reflector',
        reservoir: 'Reservoir',
        scales: 'Scales',
        sprayer: 'Sprayer',
        tables: 'Tables'
      },
      title: 'Equipment'
    },
    error: {
      '403': '403 Access Denied',
      '404': '404 Page not Found',
      '500': '500 Server Error',
      adjustQuantityCheckout:
        'You have already assigned inventory to this item, please adjust this quantity on the Checkout page.',
      chooseFacility: 'Please choose a facility.',
      harvestMultipleStrains:
        "Can't harvest plants of different strains into the same Harvest Batch. Please limit your selection to one strain at a time.",
      healthCheck: 'Health Check Failed',
      imageFormat: 'Incorrect image format',
      invalidFileTypeUploaded: 'Invalid file type uploaded',
      invalidLeafGlobalId: 'Your Leaf Global ID is not active, please contact your Administrator.',
      invalidTokenFromServer: 'Invalid token received from server.',
      loadTranslations: 'Load Translations Failed',
      maximumUploadExceeded: 'Maximum upload size of %{limit} exceeded',
      orderNeedsFulfilled: 'Order needs to be fulfilled.',
      printServerOpen: 'You have an active print server that must be set inactive before logging out.',
      selectLocations:
        'You have not selected any Sales Location, please select a Sales Location from the Employee Drawer',
      selectRegisters: 'You have not selected any Registers, please select a Register from the Employee Drawer',
      unableToTakePayment: 'Unable to take payment',
      unhandled: {
        instructions:
          'An Unhandled expection occured, and this page may no longer be operational, please either refresh the page or go back',
        title: 'Unhandled Exception'
      }
    },
    facilities: {
      choose: 'Choose a Facility',
      get: {
        failed: 'Failed to get facilities'
      },
      noFacilitySelectedError: 'Please select a facility before viewing the setup storage locations',
      type: {
        grow: 'Grow',
        manufacturing: 'Manufacturing',
        retail: 'Retail'
      }
    },
    facility: {
      ChooseAFacility: 'Choose a Facility',
      create: {
        fail: 'Server error: Facility creation failed.',
        success: 'Facility was created successfully.'
      },
      labelsManager: 'Labels Manager',
      modify: {
        failed: 'Failed to modify facility.',
        success: 'Facility was modified successfully.'
      },
      name: 'Name',
      setup: {
        accounting: 'Accounting',
        advancedSettings: 'Advanced Settings',
        advancedSettingsComplianceTitle: 'Compliance',
        advancedSettingsDescription: 'View and edit the advanced settings in this Facility.',
        advancedSettingsTitle: 'Advanced Settings - %{facility}',
        alerts: 'Alerts',
        alertsDescription: '',
        assemblies: 'Assemblies',
        assembliesDescription: '',
        assignEquipment: 'Assign Equipment',
        assignEquipmentDescription: '',
        autoPrice: 'Auto Price Setup',
        autoPriceDescription: '',
        categoryManagement: 'Category Management',
        categoryManagementDescription: '',
        compliance: {
          'e&iFinishedProductCompliance': 'E&I Finished Product Compliance',
          eifinishedProductCompliance: 'E&I Finished Product Compliance',
          facilityGroups: 'Facility Groups',
          inventory: 'Inventory',
          labels: 'Labels',
          packageSizeManagement: 'Package Size Management',
          packageSizeManagementDescription: '',
          patients: 'Patients',
          plant: 'Plant',
          plantWithoutSourceBody: "Please confirm you're creating a plant without a source.",
          plantWithoutSourceTitle: 'Plant without a Source',
          plants: 'Plants Compliance Settings',
          plantsDescription: '',
          receipts: 'Receipts',
          sales: 'Sales',
          testing: 'Testing',
          title: 'Compliance',
          wasteDestruction: 'Waste & Destruction'
        },
        coupons: 'Coupons',
        couponsDescription: '',
        creditCardsSettings: 'Credit Cards Settings',
        creditCardsSettingsDescription: 'Manage Credit Cards Settings',
        cultivationIngredients: 'Cultivation Ingredients',
        cultivationIngredientsDescription: 'View, create and edit the Ingredients in this Facility.',
        cultivationLocations: 'Cultivation Locations',
        cultivationLocationsDescription: '',
        customerGroups: 'Customer Groups',
        customerGroupsDescription: '',
        drivers: 'Drivers',
        driversDescription: 'View, create and edit the Drivers in this Facility.',
        equipment: 'Equipment',
        equipmentDescription: '',
        facilityGroups: 'Facility Groups',
        facilityStrains: 'Facility Strains',
        facilityStrainsDescription: '',
        feedingSchedules: 'Feeding Schedules',
        feedingSchedulesDescription: 'View, create and edit the Feeding Schedules in this Facility.',
        ingredients: 'Ingredients',
        ingredientsDescription: '',
        ingredientsTypesDescription: 'Ingredients Types Description',
        labelRequirements: 'Label Requirements',
        labelRequirementsDescription: '',
        labelsCustom: 'Labels Custom',
        labelsCustomDescription: 'Create Custom Labels',
        labelsSettings: 'Labels Settings',
        labelsSettingsDescription: 'Set Your Label Preferences',
        manageProducts: '',
        manageProductsDescription: '',
        manageThirdPartyIntegration: 'Manage Third Party Integration',
        manageThirdPartyIntegrations: 'Manage Third Party Integrations',
        minimumDaysSupply: 'Minimum Days Supply',
        minimumDaysSupplyDescription: 'Minimum Days Supply Description',
        orderImportDescription: 'Import orders from a file',
        ordersImport: 'Orders Import',
        ordersImportDescription: 'Orders Import Description',
        organizationProfile: 'Organization Profile',
        organizationProfileDescription: '',
        organizationStrains: 'Organization Strains',
        organizationStrainsDescription: '',
        packageSizeManagement: 'Package Size Management',
        packageSizeManagementDescription: 'View, create and edit Package Sizes for this facility.',
        partners: 'Partners',
        partnersDescription: 'View, create and edit the Partners in this Facility.',
        physicians: 'Physicians',
        physiciansDescription: 'Physicians Description',
        plantCosting: 'Plant Costing',
        plantCostingDescription: 'View, create and edit the settings that control plant costing in this Facility.',
        preferredMix: 'Preferred Mix',
        preferredMixDescription: '',
        pricingClasses: 'Pricing Classes',
        pricingClassesDescription: '',
        pricingGroups: 'Pricing Groups',
        pricingGroupsDescription: '',
        processingTypes: 'Manage Processing Types',
        processingTypesDescription: '',
        receipts: 'Receipts',
        registers: 'Registers',
        registersDescription: 'View, create and edit the Registers in this Facility.',
        rewards: 'Rewards',
        rewardsDescription: '',
        rewardsSetup: 'Rewards Setup',
        rewardsSetupDescription: '',
        rolesManagement: 'Roles Management',
        rolesManagementDescription: '',
        salesLocations: 'Sales Locations',
        salesLocationsDescription: '',
        stateIntegrator: 'Manage State Integration',
        stateIntegratorDescription: 'State Integration Settings',
        storageLocations: 'Storage Locations',
        storageLocationsDescription: '',
        strains: 'Strain Listing',
        strainsDescription: 'View, create and edit the Strains in this Facility.',
        supplies: 'Supplies',
        suppliesDescription: '',
        tasks: 'Tasks',
        tasksDescription: '',
        taxProfileDescription: '',
        taxProfiles: 'Tax Profiles',
        taxProfilesDescription: 'Tax Profiles Description',
        taxes: 'Taxes',
        taxesDescription: '',
        thirdPartyIntegration: {
          delete: {
            failed: 'Failed to deleted Third Party Integration',
            success: 'Successfully deleted Third Party Integration.'
          },
          leafly: {
            resyncJobDispatched: 'A job to re-sync your menu is processing in the background.',
            resyncJobFailed: 'There was an issue dispatching a job to re-sync your menu.'
          },
          manage: {
            failed: 'Failed to save Third Party Integration',
            success: 'Successfully saved Third Party Integration.'
          },
          weedmaps: {
            resyncJobDispatched: 'A job to re-sync your menu is processing in the background.',
            resyncJobFailed: 'There was an issue dispatching a job to re-sync your menu.'
          }
        },
        thirdPartyIntegrationsDescription: 'Third Party Integrations',
        title: 'Facility Settings - %{facility}',
        users: 'Users',
        usersDescription: 'View, create and edit the Users in this Facility.',
        vehicles: 'Vehicles',
        vehiclesDescription: 'View, create and edit the Vehicles in this Facility.'
      },
      type: 'Type'
    },
    facilityGroupsSharing: {
      actions: {
        createFacilityGroup: 'Create Facility Group',
        editFacilityGroup: 'Edit Facility Group',
        remove: 'Remove'
      },
      create: {
        createFacilityGroup: 'Create Facility Group',
        info:
          'To share your non-medicated inventory among your organization facilities, please create a group of facilities. Please note, a single facility can be associated with one group only.'
      },
      delete: {
        failed: 'You do not have permission to delete this facility group',
        success: 'You have successfully deleted this facility group'
      },
      form: {
        masterFacility: 'Master Facility',
        selectFacilities: 'Select Facilities',
        selectLocation: 'Select a location.',
        title: 'Facility Groups'
      },
      modify: {
        modifyFacilityGroup: 'Modify Facility Group',
        removeFacilityFromGroupWarning:
          'Your linked inventories will no longer be shared with your mapped facilities. You will need to do a reconciliation for each facility.'
      },
      remove: {
        confirmationMessage:
          'If you select to proceed, your linked inventories will no longer be shared with your mapped facilities. You will need to do a reconciliation for each facility.',
        removeFacilityGroup: 'Remove Facility Group'
      },
      table: {
        facilities: 'Facilities',
        groupName: 'Group Name',
        masterFacility: 'Master Facility'
      }
    },
    feedingSchedule: {
      form: {
        ingredients: 'Ingredients'
      }
    },
    feedingSchedules: {
      actions: {
        activate: 'Activate',
        createFeedingSchedule: 'Create Feeding Schedule',
        deactivate: 'Deactivate',
        delete: 'Delete schedule',
        edit: 'Edit schedule',
        printFeeding: 'Print Feeding',
        status: 'Status',
        viewIngredients: 'View Ingredients'
      },
      create: {
        failure: 'Failed to create feeding schedule',
        success: 'Successfully created feeding schedule %{schedule_name}',
        title: 'Create Feeding Schedule'
      },
      createFeedingSchedule: {
        failed: 'Failed',
        success: 'Success'
      },
      delete: {
        actions: {
          fail: 'Fail to inactivate Feeding Schedule',
          success: 'Successfully inactivated Feeding Schedule'
        }
      },
      disable: {
        actions: {
          failed: 'Failed',
          success: 'Success'
        },
        confirm: 'Are you sure you want to disable this schedule?',
        message:
          'There are %{total} plants associated with Feeding Schedule %{name}. Are you sure you want to deactivate it?'
      },
      form: {
        addIngredient: 'Add Ingredient',
        calculatedCost: 'Calculated Cost',
        calculatedIngredientsCost: 'Ingredients Calculated Cost / Liter',
        days: 'Days',
        daysCountWrong: 'Number of Days field should be an integer',
        daysPlaceholder: 'Days',
        enterFeedingScheduleNameError: 'Enter Feeding Schedule Name Error',
        feedingScheduleName: 'Feeding Schedule Name',
        frequencyCountWrong: 'Number of Feedings field should be an integer',
        frequencyOfFeeding: 'Frequency of Feeding',
        frequencyPlaceholder: '# Feedings',
        gallonsPerFeeding: 'Gallons Per Feeding',
        gallonsPerFeedingPlaceholder: 'Enter Gallons',
        ingredient: 'Ingredient',
        ingredients: 'ingredients',
        isRepeatingOccurrence: 'This is a repeating occurrence',
        litersPerFeeding: 'Liters per Feeding',
        litersPerFeedingPlaceholder: 'Enter Liters',
        location: 'Rooms Associated',
        name: 'Feeding Schedule Name',
        namePlaceholder: 'Enter Name',
        per: 'Per',
        phase: 'Feeding Schedule Phase',
        print: 'Print Feeding Schedule',
        qtyGal: 'Qty/Gal',
        qtyLiter: 'Qty/Liter',
        reset: 'Reset',
        restockAndCompleteTransfer: 'Restock and Complete Transfer',
        save: 'Save',
        selectIngredient: 'Select Ingredient',
        startDate: 'Schedule Start Date',
        summary: 'View Summary',
        uom: 'UOM'
      },
      modify: {
        actions: {
          fail: 'Fail to modify Feeding Schedule',
          success: 'Successfully modified Feeding Schedule'
        },
        title: 'Modify Feeding Schedule'
      },
      nav: {
        active: 'Active',
        inactive: 'Inactive'
      },
      table: {
        costPerGallon: 'Cost per Gallon',
        costPerLiter: 'Cost per Liter',
        data: {
          frequency: '%{frequency} feedings per %{time} days',
          stages: {
            '1': 'Vegetation',
            '2': 'Propagation',
            '3': 'Flowering'
          }
        },
        frequency: 'Frequency',
        gallons: 'Gallons',
        id: 'ID',
        liters: 'Liters',
        name: 'Name',
        phase: 'Phase',
        printFeeding: 'Print Feeding Schedule',
        viewIngredients: 'View Ingredients'
      }
    },
    file: {
      download: {
        generateInvoice: 'Generating invoice for download…',
        generateManifest: 'Generating manifest for download…'
      },
      upload: {
        failed: 'Failed to receive file, please try again',
        start: 'Downloading file…',
        success: 'Successfully received file'
      },
      url: 'Url'
    },
    general: {
      cancel: 'Cancel',
      continue: 'Continue',
      date: 'Date',
      edit: 'Edit',
      errors: {
        integer: 'Must be an integer value.'
      },
      errorsWithField: {
        integer: '%{fieldName} must be an integer value.'
      },
      loading: 'Loading…',
      no: 'No',
      okay: 'Okay',
      pending: 'In Progress',
      save: 'Save',
      thirdPartyServiceUnavailable: 'Third party service is currently unavailable',
      user: 'User',
      yes: 'Yes'
    },
    grid: {
      noResults: 'No results.',
      pagination: 'Showing %{start} to %{to} of %{total} entries',
      plantsByPhase: 'Plants by Phase',
      profitByExtractionType: 'Profit by Extraction Type',
      revenueOverTime: 'Gross Sales - Year to Date',
      salesByCategory: 'Sales by Category'
    },
    harvest: {
      batchName: 'Batch Name',
      biotrack: {
        newLevel: 'New Level',
        startingLevel: 'Starting Level'
      },
      create: {
        creating: 'Creating Harvest Batch',
        fail: 'Fail',
        failed: 'Failed to harvest plants',
        searchPlantsError:
          'Displayed only plant(s) with strain %{strain_name}. %{other_plants} plant(s) with other strains',
        success: 'Successfully created harvest batch: %{batch_name}',
        title: 'Harvest Plant(s)'
      },
      createLots: {
        createNewInventoryItem: 'Create New Inventory Item',
        passed:
          'Your have a completed test for this package.  Only one successful test package may be created per harvest.',
        pendingTestResults:
          'Harvest test results are pending. Your jurisdiction requires successful test results before a harvest may be converted to inventory. No further actions may be performed on this harvest until the test results have returned.',
        requiredFailed: 'Your jurisdiction requires a test be performed on each harvest.  Previous test(s) failed.',
        requiredNotPresent:
          'Your jurisdiction requires a test be performed on each harvest.  You do not yet have a sample package.'
      },
      form: {
        Mold: ' Mold',
        additionalTracking: 'Additional Tracking',
        airyBuds: 'Airy Buds',
        biotrack: {
          remainingWetWeight: 'Total Harvest Weight - Waste',
          waste: 'Waste',
          wetWeight: 'Total Harvest Weight'
        },
        budRot: 'Bud Rot',
        getHarvestWasteTypes: {
          failed: 'Failed'
        },
        harvestBatch: 'Harvest Batch',
        harvestDate: 'Harvest Date',
        harvestWasteType: 'Harvest Waste Type',
        leafWa: {
          otherMaterialWaste: 'Other Material Waste',
          remainingWetOtherMaterialWeight: 'Other Material Total Harvest Weight - Waste',
          remainingWetWeight: 'Total Wet Flower Weight - Waste',
          totalWetOtherMaterialWeight: 'Total Other Material Harvest Weight',
          totalWetWeight: 'Total Wet Flower Harvest Weight',
          waste: 'Flower Waste',
          wetOtherMaterialWeight: 'Total Wet Other Material Weight',
          wetWeight: 'Total Wet Flower Weight'
        },
        mold: 'mold',
        pestResistance: 'Pest Resistance',
        pestResistence: 'Pest Resistance',
        plantGroupId: 'Group ID',
        plantId: 'Plant ID',
        powderyMildew: 'Powdery Mildew',
        remainingWetWeight: 'Total Harvest Weight - Waste',
        selectHarvestBatch: 'Select Harvest Batch',
        stretching: 'Stretching',
        totalPlants: 'Total Plants',
        totalQuality: 'Total Quality',
        totalWetOtherMaterialWeight: 'Total Other Material Harvest Weight',
        totalWetWeight: 'Total Harvest Weight',
        useIndividualValues: 'Use Individual Values',
        useOverallValues: 'Use Overall Values',
        waste: 'Waste',
        wetWeight: 'Total Harvest Weight'
      },
      getHarvestBatch: {
        failed: 'Failed to load Harvest Batch.'
      },
      getHarvestWasteTypes: {
        failed: 'Failed to load Harvest Waste Types'
      },
      getHistory: {
        failed: 'Failed to load history for Harvest Batch.'
      },
      leafWa: {
        newLevel: 'New Flower Level',
        newLevelOtherMaterial: 'New Other Material Level',
        startingLevel: 'Starting Flower Level',
        startingLevelOtherMaterial: 'Starting Other Material Level'
      },
      newLevel: 'New Level',
      startingLevel: 'Starting Level',
      strainName: 'Strain Name'
    },
    harvestBatch: {
      actions: {
        activate: 'Activate'
      },
      activate: {
        fail: 'Fail',
        failed: 'Failed to activate harvest batch',
        mustBeReplicatedInMetrc:
          'This action must be replicated in METRC. There is no integration for this workflow. Are you sure you want to continue?',
        noIntegrationForThisWorkflow: 'There is no integration for this workflow. Are you sure you want to continue?',
        quantity: 'New Weight',
        storageLocation: 'Storage Location',
        success: 'Successfully activated harvest batch',
        title: 'Activate Harvest Batch'
      },
      create: {
        fail: 'Server Error: Failed to report Harvest Batch',
        success: 'Harvest Batch reported. %{batch_name}',
        title: 'Create Harvest Batch'
      },
      currentWeightString: 'Current Weight String',
      finalize: {
        cancelText: 'Cancel',
        noticeText:
          'Harvest %{name} has not been finalized.  Would you like to finalize this harvest and proceed to Create Lot(s)?',
        okayText: 'Finalize This Harvest',
        title: 'Finalize Harvest Automatically?'
      },
      form: {
        enterWasteOrWeight: 'Enter weight or waste.',
        newWeightError: 'Enter a weight.',
        quantity: 'Quantity',
        reportWasteError: 'Enter waste.',
        selectLocation: 'Select a location.'
      },
      modify: {
        currentCalculatedMoistureLoss: 'Current Calculated Moisture Loss',
        currentFlowerWeight: 'Current Flower Weight',
        currentMoisture: 'Current Moisture',
        currentMoisturePlaceholder: 'Ex: 5',
        currentOtherMaterialWeight: 'Current Other Material Weight',
        currentWeight: 'Current Weight',
        currentWeightPlaceholder: 'Err',
        date: 'Date',
        fail: 'Failed to report Harvest Batch',
        finalWeight: 'Final Weight',
        flowerWaste: 'Flower Waste',
        harvestWasteType: 'Harvest Waste Type',
        inactivateFail: 'Failed to deactivated Harvest Batch',
        inactivateSuccess: 'Harvest batch deactivated successfully.',
        name: 'Harvest Batch',
        newFlowerWeight: 'New Flower Weight',
        newOtherMaterialWeight: 'New Other Material Weight',
        newWeight: 'New Harvest Batch Wt.',
        newWeightPlaceholder: 'Ex: 5.00',
        originalWeight: 'Original Weight',
        originalWeightPlaceholder: 'Err',
        otherMaterialsWaste: 'Other Material Waste',
        reportWaste: 'Report Waste',
        reportWastePlaceholder: 'Ex: 5.00',
        storageArea: 'Storage Area',
        success: 'Harvest Batch reported.',
        title: 'Modify Harvest Batch'
      },
      nav: {
        active: 'Active',
        inactive: 'Inactive'
      },
      otherMaterialWeightString: 'Other Material Weight String',
      table: {
        batch: 'Batch',
        biotrack: {
          currentWeight: 'Current Weight',
          wasteReported: 'Waste Reported'
        },
        changeStatus: 'Change Status',
        currentWeight: 'Current Weight',
        dateCreated: 'Date Created',
        daysInPhase: 'Days In Phase',
        feeding: 'Feeding',
        flowerWeight: 'Flower Weight',
        groupTotal: 'Group Total',
        harvestBatch: 'Harvest Batch',
        leafWa: {
          currentWeight: 'Flower Weight',
          otherMaterialWaste: 'Other Material Waste',
          otherMaterialWeight: 'Other Material Weight',
          wasteReported: 'Flower Waste'
        },
        location: 'Location',
        metrcWeight: 'METRC Current Weight',
        moistureLoss: 'Moisture Loss',
        motherPlant: 'Mother Plant',
        name: 'Batch Name',
        onHold: 'On Hold',
        otherMaterialsWeight: 'Other Materials Weight',
        phase: 'Phase',
        plantId: 'Plant ID',
        plantTotal: 'Plant Total',
        quantity: 'Qty',
        rating: 'Rating',
        reportWaste: 'Report Waste',
        stage: 'Stage',
        strain: 'Strain',
        total: 'Total',
        tracking: 'Tracking',
        trackingId: 'Tracking ID',
        type: 'Type',
        uom: 'UOM',
        userId: 'User ID',
        wasteReported: 'Waste Reported'
      },
      totalQualityInt: 'Total Quality Int'
    },
    harvestPackages: {
      create: {
        creatingLotsMessage: 'Creating Lot(s)',
        fail: 'Error: Failed to create harvest package(s).',
        success: 'Harvest package(s) created successfully.',
        title: 'Create Lot(s)'
      },
      form: {
        ProductionBatch: 'Production Batch',
        addNewLot: 'Add New Lot',
        batchQualityRating: 'Batch Quality Rating',
        biotrack: {
          currentWeight: 'Flower Weight',
          flower_lot: 'Flower Lots',
          lotType: 'Lot Type',
          otherMaterialWeight: 'Other Material Weight',
          other_material_lot: 'Other Material Lots',
          packagedWeight: 'Packaged Weight',
          remainingWeight: 'Remaining Weight',
          reportedWaste: 'Waste'
        },
        createLot: 'Create Lot',
        createNewPackage: 'Create New Package',
        createPackages: 'Create Package(s)',
        currentWeight: 'Current Weight',
        endingTag: 'Ending Tag',
        enterGrossWeight: 'Enter Gross Weight',
        enterNetWeight: 'Enter Net Weight',
        enterTracking: 'Enter Tracking',
        enterWaste: 'Enter Waste',
        grandmotherPlant: 'Grandmother Plant',
        grossGreaterThanNet: 'The Lot Weight cannot be greater than the Gross Weight.',
        grossWeight: 'Gross Wt.',
        harvestBatch: 'Harvest Batch',
        leafPa: {
          createdUser: 'Create By User'
        },
        leafWa: {
          currentWeight: 'Flower Weight',
          flower: 'Flower',
          flower_lot: 'Flower Lots',
          inventoryTypes: {
            capsules: 'Capsules',
            clones: 'Clones',
            co2_concentrate: 'CO2 Concentrate',
            concentrate_for_inhalation: 'Concentrate For Inhalation',
            ethanol_concentrate: 'Ethanol Concentrate',
            flower: 'Flower Lot',
            food_grade_solvent_concentrate: 'Food Grade Solvent Concentrate',
            hydrocarbon_concentrate: 'Hydrocarbon Concentrate',
            infused_cooking_medium: 'Infused Cooking Medium',
            infused_mix: 'Infused Mix',
            liquid_edible: 'Liquid Edible',
            marijuana_mix: 'Marijuana Mix',
            mature_plant: 'Mature Plant',
            'non-solvent_based_concentrate': 'Non-Solvent Based Concentrate',
            other_material: 'Other Material Lot',
            packaged_marijuana_mix: 'Packaged Marijuana Mix',
            plant_tissue: 'Immature Plant',
            sample_jar: 'Sample Jar',
            seeds: 'Seeds',
            solid_edible: 'Solid Edible',
            suppository: 'Suppository',
            tinctures: 'Tinctures',
            topical: 'Topical',
            transdermal_patches: 'Transdermal Patches',
            usable_marijuana: 'Usable Marijuana'
          },
          lotType: 'Inventory Type',
          material_type: 'Material Type',
          medicallyCompliantTransfer:
            'This inventory is in the seeking medical compliance protocol and cannot be changed at this time.',
          otherMaterialWaste: 'Other Material Waste',
          otherMaterialWeight: 'Other Material Weight',
          other_material: 'Other Material',
          other_material_lot: 'Other Material Lots',
          packagedOtherMaterialWeight: 'Packaged Other Material Weight',
          packagedWeight: 'Packaged Flower Weight',
          remainingOtherMaterialWeight: 'Remaining Other Material Weight',
          remainingWeight: 'Remaining Flower Weight',
          reportedWaste: 'Flower Waste',
          seekingMed: 'Seeking Med.',
          seekingMedicalDesignation: 'Seeking Medical Designation'
        },
        location: 'Storage Location',
        lot: 'Lot',
        lotId: 'Lot ID',
        lotQualityRating: 'Quality Rating',
        lotWeight: 'Lot Wt.',
        netWeight: 'Net Wt.',
        otherMaterialPackagesCountExceedsFlowerPackages:
          'Due to your state integration Other Material Lot cannot be created without Flower Lot',
        package: 'Package',
        packageId: 'Package ID',
        packagedWeight: 'Packaged Weight',
        product: 'Product',
        remainingWeight: 'Remaining Weight',
        remainingWeightNegative: 'Total weight and waste cannot exceed remaining weight.',
        removePackage: 'Remove Package',
        reportedWaste: 'Report Waste',
        selectLocation: 'Select Location',
        selectProduct: 'Select Product',
        strain: 'Strain',
        trackingId: 'Tracking ID'
      },
      title: 'Harvest Batch Listing'
    },
    harvests: {
      batchName: 'Batch Name',
      getHistory: {
        failed: 'Failed'
      },
      location: 'Location',
      lotId: 'Lot Id',
      packageId: 'Package Id',
      product: 'Product',
      productName: 'Product Name',
      strainName: 'Strain Name'
    },
    ie: {
      activeProcessing: {
        view: 'View'
      }
    },
    importOrders: {
      viewTable: {
        innerTable: {
          amount: 'Amount',
          itemMasterId: 'Item Master Id',
          location: 'Location',
          note: 'Note',
          packageId: 'Package Id',
          paymentType: 'Payment Type',
          payments: 'Payments',
          price: 'Price',
          product: 'Product',
          productsIn: 'Products In',
          subTotal: 'Sub Total',
          taxes: 'Taxes',
          total: 'Total',
          unit: 'Unit'
        }
      }
    },
    inProgressOverlay: {
      message: 'Loading…'
    },
    ingredients: {
      actions: {
        activate: 'Activate',
        addIngredient: 'Add Ingredient',
        addSupplier: 'Add Supplier',
        createIngredients: 'Create Ingredients',
        createSupplier: 'Create Supplier',
        deleteIngredients: 'Delete',
        editIngredient: 'Edit Ingredient',
        editIngredients: 'Edit',
        inactivate: 'Inactivate',
        orderIngredients: 'Order Ingredients',
        receiveIngredients: 'Receive Ingredients',
        saveIngredient: 'Save Ingredient',
        viewActive: 'View Active',
        viewInactive: 'View Inactive'
      },
      active: {
        fail: 'Failed to activate ingredient %{name}',
        success: 'Successfully activate ingredient %{name}'
      },
      create: {
        fail: 'Failed to create Ingredient',
        failName: 'The name has already been taken',
        success: 'Successfully created ingredient: %{name} : %{item_number}'
      },
      editIngredient: 'Edit Ingredient',
      form: {
        category: 'Ingredient Category',
        containerSize: 'Container Size',
        name: 'Ingredient name',
        storageLocation: 'Storage Location',
        supplier: 'Supplier',
        suppliers: 'Suppliers',
        title: 'Create Ingredients',
        unitCost: 'Cost Per Unit',
        uom: 'UOM',
        uomType: 'UOM Type'
      },
      inactive: {
        fail: 'Failed to inactivate ingredient %{name}',
        success: 'Successfully inactivate ingredient %{name}'
      },
      ingredientCategoriesWereNotImported: 'Ingredient categories were not imported',
      listing: 'Ingredient Listing',
      modify: {
        failed: 'Failed to modify Ingredient',
        success: 'Successfully modified Ingredient'
      },
      nav: {
        CO2: 'CO2',
        GROW_MED: 'Growing Medium',
        NUTRIENT: 'Nutrients',
        PEST: 'Pest Management',
        TRIM_SUPPLY: 'Trim Supplies'
      },
      placeholder: {
        category: 'Category',
        choose: 'Choose',
        containerSize: 'Container Size',
        name: 'Name',
        suppliers: 'Suppliers',
        unitCost: 'Unit Cost',
        uom: 'Uom',
        uomType: 'Uom Type'
      },
      placeholders: {
        category: 'Category',
        name: 'Enter Ingredient Name',
        suppliers: 'Select Suppliers',
        unitCost: 'Enter Unit Cost',
        uom: 'UOM',
        uomType: 'UOM Type'
      },
      table: {
        adjust: 'Adjust',
        adjustButton: 'Adjust',
        cancel: 'Cancel',
        category: 'Category',
        containerSize: 'Container Size',
        costUnit: 'Cost/Unit',
        currentLevel: 'Current Level',
        deleteCol: 'Delete',
        edit: 'Edit',
        name: 'Ingredient name',
        save: 'Save',
        sku: 'SKU',
        suppliers: 'Suppliers',
        uom: 'UOM'
      }
    },
    integration: {
      apisicpaInSetupMode: 'Your Apisicpa integration is in setup mode and so is not communicating with Apisicpa.',
      biotrackInSetupMode: 'Your BioTrack integration is in setup mode and so is not communicating with BioTrack.',
      leafInSetupMode: 'Your Leaf integration is in setup mode and so is not communicating with leaf.',
      setupMode: {
        body:
          'Due to an invalid connection with your integrator, your application has been put into setup mode. While the application is in setup mode, major functionality is disabled in order to prevent the application and the integrator from becoming out of sync. To resolve the setup mode issue, either update your integration settings to the correct values, or - if you believe this mode has been enabled in error - contact support',
        fix: 'Change Integration Settings',
        goBack: 'Go Back',
        header: 'Your facility is currently in setup mode, integrated actions are limited'
      },
      taxDocuments: {
        details: {
          downloadLink: 'Download Document',
          message: 'A tax document has been generated and is available to print',
          salesCalculated: 'Sales Calculated',
          taxAssessmentDate: 'taxAssessmentDate',
          taxDocumentTypeTitle: 'Title',
          taxDueDate: 'Due Date',
          taxPeriod: 'taxPeriod',
          totalAssessmentAuditInterest: 'totalAssessmentAuditInterest',
          totalAssessmentAuditOther: 'totalAssessmentAuditOther',
          totalAssessmentAuditPenalty: 'totalAssessmentAuditPenalty',
          totalAssessmentAuditTax: 'totalAssessmentAuditTax',
          totalAssessmentOther: 'totalAssessmentOther',
          totalAssessmentPenalty: 'totalAssessmentPenalty',
          totalInterest: 'totalInterest',
          totalInterestAudit: 'totalInterestAudit',
          totalInterestBalance: 'totalInterestBalance',
          totalInterestCredit: 'totalInterestCredit',
          totalInterestPayment: 'totalInterestPayment',
          totalOther: 'totalOther',
          totalOtherAudit: 'totalOtherAudit',
          totalOtherBalance: 'totalOtherBalance',
          totalOtherCredit: 'totalOtherCredit',
          totalOtherPayment: 'totalOtherPayment',
          totalPenalty: 'totalPenalty',
          totalPenaltyAudit: 'totalPenaltyAudit',
          totalPenaltyBalance: 'totalPenaltyBalance',
          totalPenaltyCredit: 'totalPenaltyCredit',
          totalPenaltyPayment: 'totalPenaltyPayment',
          totalSales: 'totalSales',
          totalTaxAudit: 'totalTaxAudit',
          totalTaxBalance: 'totalTaxBalance',
          totalTaxCredit: 'totalTaxCredit',
          totalTaxForSubDate1: 'totalTaxForSubDate1',
          totalTaxForSubDate2: 'totalTaxForSubDate2',
          totalTaxPayment: 'totalTaxPayment'
        },
        platformReceivedDate: 'Received Date',
        taxDocumentsDetailsTitle: 'Tax Notification Available',
        taxDocumentsPageTitle: 'Tax Notifications',
        taxPeriod: 'Tax Period',
        taxPeriodDateBegin: 'Tax Period Date Begin',
        taxPeriodDateEnd: 'Tax Period Date End',
        taxPeriodDueDate: 'Tax Period Due Date',
        taxType: 'Type',
        totalSales: 'Total Sales',
        view: 'View'
      },
      transactions: {
        api: 'API Call Endpoint',
        apiPlaceholder: 'Select an endpoint to focus your report (optional)',
        callStatus: 'Call Status',
        data_show: 'Sent/Received Data',
        date: 'Date & Time',
        dateFrom: 'Report Start Date',
        dateFromHint: 'Time is always midnight start of day.',
        dateTo: 'Report End Date',
        dateToHint: 'Time is always midnight end of day.',
        details: {
          appliedActionOn: 'Action Applied On',
          applyAction: 'Click To Apply This Corrective Action',
          confirmAction: 'Are you sure you want to apply this corrective action?',
          correctiveActions: 'Corrective Actions',
          correctiveActionsHint:
            'Corrective actions are attempts to correct error conditions by automatically running known solutions for the transaction.  If a corrective action is not successful, please contact support.  Action are only available up to a maximum of 30 days after the original transaction and even then may no longer be valid.',
          dataReceived: 'Data Received',
          dataSent: 'Data Sent',
          messages: {
            failed: 'Corrective action failed…',
            success: 'Corrective action has been applied.'
          },
          noCorrectiveActions: 'No Corrective Actions Are Available',
          noCorrectiveActionsRequired: 'No Corrective Actions Required',
          noParsedResponseData: 'The received response was truncated and can not be parsed for display.',
          noRequestData: 'No Sent Data Found…',
          noResponseData: 'No Received Data Found…',
          propertyLabel: 'Property',
          title: 'Reporting Log Details',
          valueLabel: 'Value'
        },
        fail: 'Failed to get API Transactions information',
        getReport: 'Get Report',
        integrator: 'Integrator',
        integratorPlaceholder: 'Select an integrator to focus your report (optional)',
        number: 'Transaction #',
        packageLabel: 'Package Label',
        packageLabelPlaceholder: 'Provide a Package Label to focus your report (optional)',
        response: 'Response',
        responseType: 'Response Type',
        responseTypes: {
          any: 'Any',
          fail: 'Fail',
          success: 'Success'
        },
        success: '',
        title: 'Reporting Log',
        viewDetails: 'View Details'
      }
    },
    inventory: {
      actions: {
        addPackage: 'Add another Package',
        completeInfusion: 'Complete Infusion',
        completePackaging: 'Complete Packaging',
        completeProcessing: 'Complete Processing',
        completeReProcess: 'Complete Re-Process',
        createAssembly: 'Create Assembly',
        createInfusion: 'Create Infusion',
        createPreferredMix: 'Create Preferred Mix',
        modifyPackages: 'Modify Packages',
        modifyProductionRun: 'Modify Production Run',
        startInfusion: 'Start Infusion',
        startPackaging: 'Start Packaging',
        startPostProcess: 'Start Post-Processing',
        startProcessing: 'Start Processing',
        startProcessingAndTimer: 'Start Processing & Timer',
        startReProcess: 'Start Re-Process',
        transferProduct: 'Transfer Product',
        viewInactive: 'View Inactive'
      },
      assemblyListing: {
        Quantity: ' Quantity',
        assemblyName: 'Assembly Name',
        createAssembly: {
          form: {
            addNewDirection: 'Add New Direction',
            addNewIngredient: 'Add New Ingredient',
            assemblyTime: 'Assembly Time',
            costCol: 'Cost/Qty',
            directions: 'Directions',
            emailAssembly: 'Email Assembly',
            ingredientName: 'Ingredient Name',
            name: 'Assembly Name',
            printAssembly: 'Print Assembly',
            producing: 'Produces',
            quantityCol: 'Qty',
            saveAssembly: 'Save Assembly',
            step: 'Step %{number}',
            totalCost: 'Total Cost',
            units: 'Each',
            unitsCol: 'UOM'
          },
          title: 'Create Assembly'
        },
        directions: 'Directions',
        ingredient: 'Ingredient',
        itemProduced: 'Item Produced',
        title: 'E & I: Assembly Listing',
        totalCost: 'Total Cost'
      },
      biotrackInvTypes: {
        bubble_hash: 'Bubble Hash',
        capsule: 'Capsule',
        clone: 'Clone',
        co2_hash_oil: 'CO2 Hash Oil',
        flower: 'Flower',
        flower_lot: 'Flower Lot',
        food_grade_solvent_extract: 'Food Grade Solvent Extract',
        hash: 'Hash',
        hydrocarbon_wax: 'Hydrocarbon Wax',
        infused_cooking_oil: 'Infused Cooking Oil',
        infused_dairy_butter_or_fat_in_solid_form: 'Infused Dairy Butter Or Fat In Solid Form',
        kief: 'Kief',
        liquid_marijuana_infused_edible: 'Liquid Cannabis Infused Edible',
        marijuana_extract_for_inhalation: 'Cannabis Extract For Inhalation',
        marijuana_infused_topicals: 'Cannabis Infused Topicals',
        marijuana_mix: 'Cannabis Mix',
        marijuana_mix_infused: 'Cannabis Mix Infused',
        marijuana_mix_packaged: 'Cannabis Mix Packaged',
        mature_plant: 'Mature Plant',
        non_smokeable_infused_extract: 'Non Smokeable Infused Extract',
        other_material: 'Other Material',
        other_material_lot: 'Other Material Lot',
        plant_tissue: 'Plant Tissue',
        sample_jar: 'Sample Jar',
        seed: 'Seed',
        solid_marijuana_infused_edible: 'Solid Cannabis Infused Edible',
        suppository: 'Suppository',
        tincture: 'Tincture',
        transdermal_patch: 'Transdermal Patch',
        usable_marijuana: 'Usable Cannabis',
        waste: 'Waste',
        wet_flower: 'Wet Flower'
      },
      completeProcessing: {
        form: {
          addPackage: 'Add Package',
          completionTime: 'Completion Time',
          conversionDate: 'Conversion Date',
          equipmentUsed: 'Equipment Used',
          extractionEmployees: 'Extraction Employees',
          extractionTime: 'Extraction Time',
          getNewProductionRun: 'Get New Production Run',
          newItem: 'New Item',
          newPackages: 'New Packages',
          newQuantity: 'New Quantity',
          newQuantityUnits: 'New Quantity Units',
          processedPackages: 'Processed Packages',
          reportWaste: 'Report Waste',
          runBatch: 'Run Batch',
          stateTracking: 'State Tracking',
          storageLocation: 'Storage Location',
          strain: 'Strain',
          totalYield: 'Total Yield',
          totalYieldUnits: 'Total Yield Units',
          unitsCol: 'Units Col',
          vendor: 'Vendor',
          wasteUnits: 'Waste Units',
          weight: 'Weight'
        },
        title: 'Complete Processing'
      },
      confirmToGoNegative: 'Remaining inventory QTY is not enough, would you like to go negative?',
      curing: 'Curing',
      finishedProducts: 'Finished Products',
      form: {
        actualCost: 'Line Item Cost',
        addPackage: 'Add New Package',
        adjustmentReason: 'Adjustment Reason',
        assemblyWeight: 'Qty of Assembly',
        date: 'Date',
        each: 'Each',
        employee: 'Employee',
        expectedCompletionTime: 'Expected Completion Time',
        infusionCompleteTime: 'Infusion Complete Time',
        infusionStartTime: 'Infusion Start Time',
        inventoryType: 'Inventory Type',
        limitationForSplitPackage: 'Current cap for this feature is %{limit} packages',
        newItem: 'New Item',
        newPhase: 'New Phase',
        newQuantity: 'New Quantity',
        newTag: 'New Tag',
        productionRun: 'Production Run',
        projectedCost: 'Projected Cost',
        quantity: 'Quantity Used',
        quantityPlanned: 'Quantity Planned',
        quantityUsed: 'Quantity Used',
        selectAssembly: 'Select Assembly',
        selectEmployee: 'Select Employee',
        strain: 'Strain',
        uom: 'Uom',
        vendor: 'Vendor',
        weight: 'Weight'
      },
      infusionConcentrate: 'Infusion Concentrate',
      negativeInventoryAlert: 'Negative inventory alert',
      preRun: 'Pre-Run',
      preferredMixListing: {
        ingredient: 'Ingredient',
        ingredient1: 'Ingredient 1',
        ingredient2: 'Ingredient 2',
        ingredient3: 'Ingredient 3',
        item: 'Item Produced',
        mixName: 'Mix Name',
        quantity: 'Quantity',
        quantityProduced: 'Quantity Produced',
        title: 'Preferred Mixes',
        vendor: 'Vendor'
      },
      propagationMaterial: {
        create: {
          creating: 'Creating Propagation Material',
          failed: 'Failed to Propagation Material',
          success: 'Successfully created Propagation Material'
        },
        title: 'Create Propagation Material'
      },
      reservations: {
        releaseAllReservations: 'Release All Reservations'
      },
      reverse: {
        destroy: 'Leave unchecked to have the weight destroyed (eg. spoilage, theft, or loss) OR:',
        toParent: 'This package was created in error, return weight to original package(s).'
      },
      splitPackage: {
        failedMetrcTags: 'Failed to get METRC tracking tags',
        form: {
          currentLocation: 'Current Location',
          location: 'Select Location',
          modificationDate: 'Modification Date',
          packageID: 'Package ID',
          partnerDestination: 'Select Partner Destination',
          productName: 'Product Name',
          qty: 'On-Hand Quantity',
          qtyAvailableLessThanQuantityToUse: 'Qty Available Less Than Quantity To Use',
          qtyUse: 'Quantity to Use',
          quantityAvailable: 'Quantity Available',
          remainder: 'Unpacked Remainder',
          splitByEmployee: 'Split By Employee',
          testPackage: 'Mark as Test Package',
          totalQuantity: 'Total Quantity',
          trackingID: 'Assign Tracking ID',
          uniqueTrackingId: 'Tracking ID must be unique'
        },
        pendingLabResultsError: 'You are not allowed to split the item %{packageId} as it has pending lab result.',
        pendingSplitError:
          'The inventory selected %{packageIds} has Pending medically compliant status and cannot be split.',
        title: 'Split Package'
      },
      startPackaging: {
        assignEmployee: 'Assign Employee',
        packagingLocation: 'Packaging Location',
        startPackagingAndTimer: 'Start Packaging And Timer'
      },
      startProcessing: {
        assignEmployee: 'Assign Employee',
        assignEquipment: 'Assign Equipment',
        form: {
          production: 'Production'
        },
        processingTime: 'Processing Time',
        processingType: 'Processing Type'
      },
      sync: {
        status: {
          message: {
            ready: 'Inventory Sync Data Collection Ready. Please return to the Inventory Sync page to continue the Synchronization process.'
          }
        }
      },
      table: {
        additionalTrackingId: 'Tracking ID',
        amount: 'Amount',
        available: 'Available',
        batch: 'Product Run/Batch',
        compromised: 'Compromised',
        dateCreated: 'Date Created',
        destination: 'Destination',
        failed: 'Failed',
        masterId: 'Package ID',
        onHold: 'On Hold',
        quarantineMessage:
          'This package has been quarantined due to a failed microbial test, and must be re-tested at another facility before it can be released',
        searchPlaceholder: 'Search Packages',
        storageLocation: 'Location',
        strain: 'Strain',
        testResults: 'Test Results',
        type: 'Type',
        uom: 'UOM',
        vendor: 'Vendor',
        view: 'View',
        weight: 'Quantity'
      },
      title: 'E & I: Inventory',
      wasteDisposal: {
        confirmWasteDestruction: 'Are you sure you want to destroy the selected package(s)?',
        destroyWasteFailed: 'Failed to destroy waste packages',
        destroyWasteSuccess: 'Waste packages were successfully destroyed',
        disposeWaste: 'Dispose Waste'
      }
    },
    item: {
      get: {
        failed: 'Failed to get Product'
      },
      title: 'Title'
    },
    itemTransactions: {
      field: 'Field',
      get: {
        failed: 'Failed'
      },
      ingredients: 'Ingredients',
      item: {
        adjustment_reason: 'Adjustment Reason',
        childLocation: 'Child Package Location',
        childPackageId: 'Child Package ID',
        feedingSchedule: 'Feeding Schedule',
        id: 'ID',
        isReserved: 'Is Reserved',
        isTestPackage: 'Is Test Package',
        itemDetails: 'Item Details',
        location: 'Location',
        location_name: 'Location',
        lotId: 'Lot Id',
        newItemDetails: 'New Item Details',
        newLocation: 'New Location',
        newQuantity: 'New Quantity',
        onHold: 'On Hold',
        packageCreatedAt: 'Package Created At',
        packageExpiresAt: 'Package Expires At',
        packageId: 'Package ID',
        packagedAt: 'Packaged At',
        parentItemId: 'Parent Item Id',
        parentPackageId: 'Parent Package ID',
        partnerDestination: 'Partner Destination',
        phase: 'Phase',
        plantBatchID: 'Use Plant Batch ID',
        product: 'Product',
        purpose: 'Purpose',
        purposes: 'Purposes',
        qty: 'Quantity',
        qtyUsed: 'Quantity used',
        reserved: 'Reserved',
        source: 'Source',
        sourceItemDetails: 'Source Item Details',
        sourceTrackingId: 'Source Tracking ID',
        title: 'Location Level',
        trackingId: 'Tracking ID',
        vendor: 'Vendor'
      },
      newPackageDetails: 'New Package Details',
      noEventRecorded: 'No event recorded for transaction',
      package: {
        title: 'Package Level'
      },
      quickMoveDetails: 'Quick Move Details',
      types: {
        create: 'Item created',
        feedingSchedule: 'Feeding schedule applied',
        labResultAssigned: 'Lab Result Assigned',
        labResultUnassigned: 'Lab Result Unassigned',
        manual: 'Manual item adjustment',
        merge: 'Item merged',
        mfgProduction: 'Item used in production',
        newItemMaster: 'Item created',
        newLevel: 'New Level',
        none: 'Unknown action',
        packageSplit: 'Package split',
        packaging: 'Packaging job',
        plantCreated: 'Create new Plant',
        quickMove: 'Package split (Quick Move)',
        receipt: 'Inventory receipt obtained',
        reconcile: 'Item reconciled',
        restock: 'Item restocked',
        sale: 'Item sold',
        setLevel: 'New level set',
        sharedItemClone: 'Shared item clone',
        sharedItemTransactionReplication: 'Shared item transaction replication',
        transfer: 'Item transferred',
        transferCancelled: 'Transfer cancelled'
      },
      value: 'Value',
      valueAdjustment: 'Value Adjustment'
    },
    itemsAvailability: {
      get: {
        failed: 'Failed to get items availability'
      }
    },
    labelRequirements: {
      category: 'Select Category',
      claim: 'Health and Benefits Claim',
      mandatedState: 'Mandated Statements',
      nonOrganic: 'Non-Organic Pesticides/Herbicides/Fungicides',
      products: 'Select Products',
      statements: 'Labeling Statements',
      subCategory: 'Select Sub-Category',
      title: 'Create Labels'
    },
    labels: {
      aboutDpiAndLabels: 'About DPI and Labels',
      aboutDpiAndLabelsText:
        'DPI is a setting on your printer that determines the resolution. Each resolution requires a different (but very similar) ZPL template. If one or more of a resolution is not set, the default label will be used instead.',
      baseLabelSize: 'Base Label Size',
      both_image: 'Version 2 & 1 (Print Version 2)',
      both_image_text: 'Preview Version 2 and 1 Labels; Print Version 2 Labels.',
      both_text_note:
        'Use this to easily compare Version 1 and 2 labels and adjust your Version 2 labels if needed (most will not need adjustment).',
      both_zpl: 'Version 1 and 2 (Print Version 1)',
      both_zpl_text: 'Preview Version 1 and 2 Labels; Print Version 1 Labels.',
      clickLabelToOpen: 'Click a Label Name to open it for editing.',
      custom: {
        lotNumber: 'Lot Number',
        name: 'Name',
        productCode: 'Product Code'
      },
      customLabelsHeader: 'Manage Custom Labels For: ',
      customLabelsListHeader: 'Custom Labels Summary',
      defaultDpi203: 'Default at 203 DPI',
      defaultDpi300: 'Default at 300 DPI',
      defaultDpi600: 'Default at 600 DPI',
      defaultLabel: 'Defaults',
      dpi_203: '203 DPI',
      dpi_300: '300 DPI',
      dpi_600: '600 DPI',
      editor: {
        title: 'Default Label Editor'
      },
      editorFieldLabel: 'Editor',
      editorFieldLabelHint: 'Paste your ZPL code here.',
      hideLabelTypes: 'Hide Label Types',
      image: 'Version 2',
      image_short: 'V2',
      image_text: 'Preview and Print Version 2 labels.',
      isActive: 'Is Active',
      labelDescription: 'Description',
      labelName: 'Label Name',
      labelType: 'Label Type',
      management: {
        title: 'Labels Manager'
      },
      placeholdersFieldLabel: 'Placeholders',
      placeholdersFieldLabelHint:
        'Use the placeholders below in your zpl code to indicate where data should be displayed.',
      saveAndTestCustomLabel: 'Save And Test Custom Label',
      saveCustomLabel: 'Save Custom Label',
      showLabelTypes: 'Show Label Types',
      testDefaultLabel: 'Test Default Label',
      zpl: 'Version 1 (deprecated)',
      zpl_short: 'V1',
      zpl_text:
        'Preview and Print Version 1 labels.  These labels include reports on rows that were excluded and overflow views to help you fine tune your labels.',
      zpl_text_note: 'Version 1 labels will be removed on or shortly after June 1st, 2018.'
    },
    landing: {
      logo: '%{company}',
      welcomeMessage: 'Welcome to %{welcomeTo}'
    },
    lineItemPrice: 'Line Item Price',
    loadingPage: {
      loading: 'Loading…'
    },
    locations: {
      buildingName: 'Building Name',
      create: {
        failed: 'Failed to Create Buildings',
        success: 'Successfully Created Buildings',
        title: 'Create Rooms'
      },
      createLocation: {
        createLocation: 'Create Location'
      },
      createSalesLocations: {
        failed: 'Failed to Create Sales Locations',
        success: 'Successfully Created Sales Locations',
        title: 'Setup Sales Locations'
      },
      createStorageLocations: {
        failed: 'Failed to Create Locations',
        success: 'Successfully Created Locations',
        title: 'Create Storage Locations'
      },
      form: {
        addAreas: 'Add Area to the Sales Location',
        addBuildings: '  Add a New Building',
        addLocations: 'Add a Sales Location to the Building',
        addRooms: 'Add a Room to the Building',
        addSections: 'Add Sections',
        addSubSections: 'Add Sub-Section to Area',
        addZones: 'Add Zones',
        area: 'Area %{id}',
        areaName: 'Area Name',
        building: 'Building %{id}',
        buildingName: 'Edit Building Name',
        cultivationType: 'Cultivation Type',
        cultivationTypePlaceholder: 'Select a type of building',
        defaultNumAreas: 'Default Areas per Location',
        defaultNumRooms: 'Default Rooms per Building',
        defaultNumSales: 'Default Sales Locations per Building',
        defaultNumSections: 'Default Sections per Zone',
        defaultNumSubSections: 'Default Sub-Sections per Area',
        defaultNumZones: 'Default Zones per Room',
        facilityInformation: 'Facility Information',
        facilitySqFootage: 'Total Square Footage for Facility',
        facilitySquareFootageRequired: 'Facility Square Footage Required',
        facilitySquareFootageToLow: "Facility Square Footage must be equal or bigger than sum of Rooms' Square Footage",
        facilityTotalRentRequired: 'Facility Total Rent Required',
        inStoreOnly: 'Inventory is only available in-store',
        integratedLocationName: 'Integrated Location Name',
        integratedLocationNamePlaceholder: 'Select a location name',
        isShared: 'Is Shared',
        locationName: 'Sales Location Name',
        namePlaceholder: 'Name Placeholder',
        numSales: 'Number of Sales Locations',
        quickSetup: 'Quick Setup',
        retailSqFootage: 'Total Retail Square Footage',
        room: 'Room %{id}',
        roomName: 'Edit Room Name',
        roomSqft: 'Room Cultivation Square Footage',
        salesLocation: 'Sales Location %{id}',
        save: 'Save',
        saveAllChanges: 'Save All Changes',
        section: 'Section %{id}',
        sectionName: 'Edit Section Name',
        sqFtPlaceholder: 'Enter Square Feet',
        storageLocationType: 'Type',
        subSection: 'Sub-Section %{id}',
        subSectionName: 'Sub-Section Name',
        totalRent: 'Standard Monthly Facility Rent',
        totalRentPlaceholder: 'Enter Monthly Rent',
        trackingId: 'Tracking Number',
        visibleInNetwork: 'Visible to In Network Partners',
        zone: 'Zone %{id}',
        zoneName: 'Edit Zone Name'
      },
      get: {
        failed: 'Failed'
      },
      getLocations: {
        failed: 'Failed to get locations.'
      },
      modify: {
        failed: 'Failed to Modify Locations',
        success: 'Successfully Modified Locations',
        title: 'Modify Locations'
      },
      modifySalesLocations: {
        failed: 'Failed to Modify Sales Locations',
        success: 'Successfully Modified Sales Locations',
        title: 'Modify Sales Locations'
      },
      name: 'Name',
      roomName: 'Room Name',
      sectionName: 'Section Name',
      setup: {
        failed: 'Failed to set up Locations',
        success: 'Successfully set up Locations',
        title: 'Setup Locations'
      },
      setupStorageLocations: {
        cultErrorOnDelete: '%{location} has plants; it may not be deleted.',
        errorOnDelete: '%{location} has inventory; it may not be deleted.',
        failed: 'Failed to set up Locations',
        success: 'Successfully set up Locations',
        title: 'Setup Storage Locations'
      },
      unshareLocation: {
        confirmationText:
          'If you elect to proceed, your linked inventories will no longer be shared with your mapped facilities; You will need to do a reconciliation for each facility',
        keepShared: 'Keep location shared',
        stopSharing: 'Stop sharing location'
      },
      zoneName: 'Zone Name'
    },
    lotLineage: {
      collapseAll: 'Collapse All',
      expandAll: 'Expand All',
      get: {
        failed: 'Failed'
      },
      packages: 'Packages',
      parents: 'Parents',
      title: 'Lot Lineage'
    },
    metrc: {
      form: {
        isProductionBatch: 'Production Batch',
        lotChangeWarning:
          'Warning: Increases in harvest batch weight is not supported in METRC, and will lead to discrepancies between MJPlatform and METRC',
        noSpecificApiCall:
          'Your state system has no API call for this action. There will be a discrepancy between the systems',
        salesExportIsAddOrderType: 'Add METRC sales Export column for Order Type',
        salesExportLimits:
          'Your Metrc Sales Export is %{limit} rows or more. Due to size limits in Metrc, you will need to split this file into separate files of %{limit} rows or fewer after exporting to CSV'
      }
    },
    modal: {
      enterBirthdate: 'Enter Birthdate',
      selectPatient: 'Select Patient'
    },
    nav: {
      Active: 'Active',
      Cart: 'Cart',
      Clients: 'Clients',
      Cultivation: 'Cultivation',
      English: 'English',
      FeedingSchedules: 'Feeding Schedules',
      FinishedProducts: 'Finished Products',
      Flowering: 'Flowering',
      French: 'Français',
      Gobacktohomepage: 'Go back to homepage',
      Home: 'Home',
      Inactive: 'Inactive',
      Italian: 'Italiano',
      Locations: 'Locations',
      LockScreen: 'Lock Screen',
      Login: 'Login',
      Logout: 'Logout',
      Messages: 'Messages',
      Notifications: 'Notifications',
      Privacy: 'Privacy',
      ProductMenu: 'Product Menu',
      Profile: 'Profile',
      Propagation: 'Propagation',
      Queue: 'Queue',
      Register: 'Request Account',
      Reporting: 'Reporting',
      Settings: 'Settings',
      Setup: 'Setup',
      Spanish: 'Español',
      Support: 'Support',
      Tasks: 'Tasks',
      UserAccount: 'User Account',
      Vegetation: 'Vegetation',
      accounting: 'Accounting',
      active: 'Active',
      activeRates: 'Active Rates',
      activeUsers: 'Active Users',
      alerts: 'Alerts',
      averages: 'Averages',
      batches: 'Batches',
      checkin: 'Check-In',
      checkout: 'Check Out',
      clearCache: 'Clear Cache',
      closingReport: 'Closing Report',
      customers: 'Customers',
      currentInventoryReport: 'Current Inventory Report',
      dailyClosingReport: 'Daily Closing Report',
      dailyTransactionsReport: 'Daily Transactions Report',
      delivery: 'Delivery',
      errorAccess: 'Access is not authorized.  Please contact your administrator.',
      feedingSchedules: 'Feeding Schedules',
      finishedProducts: 'Finished Products',
      flowering: 'Flowering',
      harvest: 'Harvest',
      harvests: 'Harvests',
      historical: 'Historical',
      hr: 'HR',
      humanResources: 'Human Resources',
      inactive: 'Inactive',
      inactiveRates: 'Inactive Rates',
      inactiveUsers: 'Inactive Users',
      ingredients: 'Ingredients',
      inventory: 'Inventory',
      loyalty: 'Loyalty',
      manageClosing: 'Manage Closing',
      manageRooms: 'Manage Rooms',
      materials: 'Materials',
      orderHistory: 'Order History',
      partners: 'Partners',
      patients: 'Patients',
      plants: 'Plants',
      products: 'Products',
      productSalesReport: 'Product Sales Report',
      propagation: 'Propagation',
      reconciliations: 'Reconciliations',
      reporting: 'Reporting',
      reports: 'Reports',
      sales: 'Sales',
      salesReport: 'Sales Report',
      salesSummary: 'Sales Summary',
      settings: 'Settings',
      setup: 'Facility Settings',
      storageLocations: 'Storage Locations',
      supplyChain: 'Supply Chain',
      tasks: 'Tasks',
      tests: 'Tests',
      textMarketing: 'SMS Marketing',
      titleLeaf: 'Log into Leaf Data Systems',
      titleLeafLoading: 'Logging into Leaf Data Systems...',
      trackingIds: 'Tracking Ids',
      transactionReport: 'Daily Transactions Report',
      users: 'Users',
      vegetation: 'Vegetation',
      welcome: 'Welcome'
    },
    netWeight: 'Net Weight',
    no: 'No',
    noActiveRegisters: 'No Active Registers',
    noActiveSalesLocations: 'No Active Sales Locations',
    notification: {
      download: {
        failed: 'Failed'
      },
      table: {
        download: 'Download'
      }
    },
    notifications: {
      actions: {
        acknowledge: 'Acknowledge',
        acknowledging: 'Acknowledging'
      },
      archiveMessage:
        'All notifications for your Facility can be found here. All read notifications will be automatically archived after 60 days to keep your inbox clean, If you need to recover any archived messages, please Contact MJ Freeway Support.',
      download: {
        failed: 'Failed to download file'
      },
      get: {
        failed: 'Failed to get notifications.'
      },
      markAllAsRead: 'Mark All as Read',
      markAllAsUnread: 'Mark All as Unread',
      markAsRead: 'Mark as Read',
      markAsUnread: 'Mark as Unread',
      markViewed: {
        failed: 'Failed to change notification viewed status.'
      },
      priority: 'Priority Notification',
      table: {
        body: 'Body',
        expiration: 'Expiration',
        link: 'Link',
        priority: 'Priority',
        sent: 'Sent',
        subject: 'Subject',
        view: 'View'
      },
      title: 'Notifications'
    },
    numberOfUnits: 'Number Of Units',
    onsumers: {
      checkin: {
        caregiverHasNoPatientsAssigned: 'Caregiver Has No Patients Assigned'
      }
    },
    order: {
      id: 'Id',
      reset: 'Reset',
      updateCart: 'Update Cart'
    },
    orderDisposalReasons: {
      contaminated: 'Contaminated',
      damaged: 'Damaged',
      defective: 'Defective',
      expired: 'Expired'
    },
    orderDocumentsAndSummary: 'Order Documents And Summary',
    orderNumber: 'Order Number',
    orderReceipt: {
      footer: {
        balance: 'Change Due',
        couponTotal: 'Coupons',
        discountTotal: 'Discounts',
        subTotal: 'Sub Total',
        taxTotal: 'Tax',
        total: 'Total'
      },
      header: {
        customerId: 'Customer ID',
        employeeId: 'Employee ID',
        orderId: 'Order ID'
      },
      printing: {
        buildingReceiptProgress: 'Building Your Receipt…',
        buildingReceiptTitle: 'Building Your Receipt.',
        fetching: 'Fetching',
        fetchingReceipt: 'Fetching Receipt',
        noDefaultPrinterMessage:
          'In your printer setup, ensure you have selected the receipt printer as your default printer.',
        noDefaultPrinterTitle: 'No Default Printer was found!',
        noLabelsForRefunds: 'You are unable to print a label for a refunded order.',
        noPermissions: 'You do not have permissions to print labels or receipts.',
        noPrintingTitle: 'Printing Unavailable',
        noQzTrayMessage:
          'Please ensure you have downloaded and installed QZ Tray and have turned it on.  Also, ensure you have a default printer set.',
        noQzTrayTitle: 'QZ Tray must be installed and enabled!',
        printingReceiptProgress: 'Printing Your Receipt…',
        printingReceiptTitle: 'Printing Your Receipt'
      }
    },
    orders: {
      anonymous: {
        ageLimitError: 'Must be %{ageLimit} years old to purchase',
        anonymousOrder: 'Anonymous Order',
        fastTrack: 'Fast Track',
        formTitle: 'Demographic Information',
        recDlInvalidError: 'Driver License is expired',
        startOrder: 'Start Order'
      },
      coupons: {
        failed: 'Failed'
      },
      create: {
        couponFail: 'Failed to apply coupon.',
        couponFailed: 'Coupon Failed',
        failed: 'Server error: Order creation failed.',
        productFail: 'Failed to add product to order.',
        success: 'Order was started successfully.'
      },
      delete: {
        couponFail: 'Failed to remove coupon',
        couponFailed: 'Coupon Failed',
        customerSuccess: 'Customer removed from queue',
        fail: 'Failed to cancel customer order',
        itemFail: 'Failed to remove item',
        productFail: 'Failed to remove product',
        success: 'Order canceled'
      },
      get: {
        fail: 'Failed to retrieve customer order',
        failed: 'Failed to get orders.',
        itemFail: 'Failed to retrieve inventory items'
      },
      modify: {
        fail: 'Failed to modify order.',
        failed: 'Failed to modify order.',
        itemsFail: 'Failed to assign item',
        productsFail: 'Failed to add Product to the order'
      },
      products: {
        create: {
          failed: 'Failed'
        },
        remove: {
          failed: 'Failed'
        }
      },
      removed: {
        customerFailed: 'Customer Failed',
        failed: 'Failed'
      },
      rewards: {
        failed: 'Failed'
      }
    },
    ordersImport: {
      form: {
        file: 'File',
        file_type_error: 'Please use one of the file formats: *.CSV, *.XLS or *.XLSX',
        introductionText:
          'To manually upload the sales please download the template below and follow the import instructions.',
        register: 'Register for payments',
        sampleFile: 'Please see the sample file with transaction',
        startFromRow: 'Start import of data from row number',
        uploadImportFile: 'Upload Import File'
      },
      pageTitle: 'Orders Import',
      statusOfImportedFile: 'Status of imported file',
      table: {
        created_at: 'Created at',
        file: 'File',
        id: 'id',
        imported_by: 'Imported by',
        status: 'Status',
        view: 'View'
      },
      tableFilter: {
        date: 'Date',
        showHistory: 'Show history',
        status: 'Status',
        statuses: {
          canceled: 'Canceled',
          completed: 'Completed',
          failed: 'Failed',
          inProgress: 'In Progress',
          previewAvailable: 'Preview Available'
        }
      },
      uploadNewFile: 'Upload new file',
      viewTable: {
        completeOrders: 'Complete Orders',
        customerNumber: 'Customer #',
        driverLicense: 'Driver License',
        innerTable: {
          amount: 'Amount',
          itemMasterId: 'Item Master ID',
          location: 'Location',
          note: 'Note',
          packageId: 'Package ID',
          paymentType: 'Payment Type',
          payments: 'Payments',
          price: 'Price',
          product: 'Product',
          productsIn: 'Products In',
          subTotal: 'Sub-total',
          taxes: 'Taxes',
          total: 'Total',
          unit: 'Unit'
        },
        medicalId: 'Medical Marijuana ID',
        noticeMessage:
          'Please make sure your orders are correct before submitting them. After orders are accepted you will be able to view them under Order History for the patient. Your inventory will be adjusted by the amount submitted.',
        orderDataSubmitting: 'Orders are being processed. This may take some time.',
        orderNumber: 'Order #',
        ordersCompletedError: 'Orders were not processed due to error.',
        ordersCompletedSuccess: 'Orders have been processed successfully.',
        pageTitle: 'Order Details',
        saleDateAndTime: 'Sale Date&Time',
        salesAssociate: 'Sales Associate',
        totalOrderPrice: 'Total Order Price'
      }
    },
    organization: {
      create: {
        success: 'Success'
      },
      createOrg: {
        save: 'Save'
      },
      get: {
        failed: 'Failed'
      },
      hasOrgFacilityPALeafFail: 'Fail to get information about PA Leaf integration of the organization facilities.',
      setup: {
        compliance: {
          packageSizeManagement: 'Organization Package Size Management',
          packageSizeManagementDescription: ''
        },
        packageSizeManagementDescription: 'Package Size Management Description'
      }
    },
    organizations: {
      createOrganization: {
        createOrganizationProfile: 'Create Organization Profile',
        logo: 'Logo',
        marketing_email_address: 'Marketing Email Address',
        organization: 'Organization',
        organizationContacts: 'Organization Contacts',
        public_profile_enabled: 'Public Profile Enabled'
      }
    },
    package: {
      table: {
        packageId: 'Package Id'
      }
    },
    packages: {
      actions: {
        failed: {
          getPackagingJobDetail: 'You must have a Pre-Pack Product for this strain to complete this packaging job'
        },
        move: 'Move'
      },
      complete: {
        title: 'Complete Packages'
      },
      destroy: {
        biotrackDestroyReason: 'Biotrack Destroy Reason',
        cancel: 'Cancel',
        confirmDestruction: 'Confirm Package Destruction',
        confirmationMessage:
          'Please check your data. Destroy action cannot be undone. Are you sure you want to proceed?',
        destroy: 'Destroy',
        destructionNotice: 'Selected packages will be destroyed fully. The destroy action cannot be undone.',
        failed: 'Failed to destroy package(s)',
        inputsRequired: 'Please select the packages to destroy',
        success: 'Successfully destroyed package(s)',
        title: 'Destroy Package(s)'
      },
      form: {
        AdjustAllAdjustmentReason: 'Adjust All Adjustment Reason',
        SavingPackages: 'Saving Packages…',
        addPackage: 'Add Another Package',
        addSubtract: 'Add Subtract',
        addTestingResult: 'Add Testing Result',
        adjustQty: 'Adjust Qty',
        adjustQuantity: 'Adjust Quantity',
        adjustmentReason: 'Adjustment Reason',
        assignEquipment: 'Assign Equipment',
        assignId: 'Assign ID',
        assignProductionRun: 'Assign Production Run',
        biotrackAdjustmentReason: 'Biotrack Reason',
        biotrackDestroyReason: 'Biotrack Destroy Reason',
        dateFilled: 'Date Filled',
        dateModified: 'Date Modified',
        destinationStorageLocationId: 'Destination Storage Location Id',
        employee: 'Employee',
        enterDateError: 'Enter a date.',
        enterQuantityError: 'Enter a quantity.',
        extractionDate: 'Extraction Date',
        finishPackaging: 'Finish Packaging',
        hold: 'Hold',
        idChangeConfirm: {
          cancel: 'Cancel',
          confirm: 'Confirm',
          newTagValue: 'New Tag Value',
          originalTagValue: 'Original Tag Value',
          text: 'Text'
        },
        inventoryType: 'Inventory Type',
        item: 'Item',
        lot: 'Lot',
        lotFailed: 'Failed to Update Lot',
        lotInformation: 'Lot Information',
        lotMessage:
          'Warning: lot modifications affect all items within a lot, changes made here could affect packages not listed above',
        lotSuccess: 'Sucessfully Updated Lot',
        markAsReserved: 'Mark as Reserved',
        metrcAdjustmentReason: 'METRC Reason',
        modificationDate: 'Modification Date',
        modifyPackage: 'Modify Package And Return',
        modifyPackageAndPrint: 'Modify Package And Print Label',
        modifyPackages: 'Modify Packages And Return',
        modifyPackagesAndPrint: 'Modify Packages And Print Labels',
        movePackages: 'Move Packages',
        mustBeIntegerError: 'Must be an integer.',
        mustBePositiveError: 'Must be positive.',
        mustBeSmallEnoughError: 'Must be < 1000.',
        newPhase: 'New Phase',
        newQty: 'New Qty',
        note: 'Note',
        notePlaceholder: 'Type notes…',
        notes: 'Notes',
        packageAdjustments: 'Package Adjustments',
        packageId: 'Package ID',
        packageInformation: 'Package Information',
        packageModification: 'Package Modification',
        packageSizePaError: 'Packages may not be larger than %{leafPaMaxHarvestWeight} grams per state regulation.',
        packages: {
          form: {
            biotrackDestroyReason: 'Biotrack Destroy Reason'
          }
        },
        processingType: 'Processing Type',
        productionRunInfo: 'Production Run Info',
        quantity: 'Quantity',
        quantityInGrams: 'Quantity In Grams',
        quantityPlaceholder: 'ex. 100',
        reason: 'Reason',
        receivedBy: 'Received By',
        replaceTag: 'Replace Tag',
        reset: 'Reset',
        runBatch: 'Production Run/Batch',
        runTime: 'Run Time',
        save: 'Save',
        saveLot: 'Save Lot',
        saved: 'Saved',
        savingPackage: 'Saving Package…',
        savingPackages: 'Saving Packages',
        selectHoldError: 'Select an option.',
        selectPackage: 'Select Package',
        selectPackageError: 'Select a package.',
        selectPackagePlaceholder: 'ex.',
        selectReasonError: 'Select a reason.',
        selectResultsError: 'Select results.',
        selectStorageLocationError: 'Select a location.',
        selectTagError: 'Select a tag.',
        selectUnitError: 'Select a unit.',
        sourceStorageLocationId: 'Source Storage Location Id',
        storageLocation: 'Storage Location',
        strain: 'Strain:',
        testingResults: 'Testing Results',
        today: 'Today',
        totalQuantity: 'Total Quantity',
        unitOfMeasure: 'UOM',
        uploading: 'Uploading…',
        vendor: 'Vendor:',
        weight: 'Weight:'
      },
      getPackage: {
        failed: 'Failed to get package'
      },
      modify: {
        doesntHavePassedLabResult:
          "You are not allowed to use the item %{package_id} as it doesn't have a passed lab result.",
        doesntHavePassedLabResultLeafWa:
          'The input inventory cannot be used because it does not have eligible lab results',
        errorLabResultFailed: 'Package %{package_id} has failed lab result and cannot be used in this job.',
        failed: 'Package modification failed.',
        getIntegrationAdjustmentReasons: {
          failed: 'Failed'
        },
        getItemsByPackageIdFailed: 'Failed to get items of the package.',
        getMetrcAdjustmentReasons: {
          failed: 'Failed to get METRC package adjustment reasons.'
        },
        getPackages: {
          failed: 'Failed to get packages from server.'
        },
        individualModification: 'Individual Modification',
        isTestSampleError: 'Item sent for QA testing cannot be activated.',
        leafWa: {
          cancelText: 'Cancel',
          modalContent:
            'If you select to mark this inventory as not seeking medical designation, you will lose your passed lab results and will need to retest.',
          modalTitle: 'Seeking Medical Compliance',
          okayText: 'Confirm'
        },
        modifyGroup: 'Modify Group',
        packageHasReservedAmountMessage: 'The package has the reserved amount and cannot be Hold On.',
        success: 'Packages modified successfully.',
        title: 'Modify Packages'
      },
      prePack: {
        childPackageId: 'Child Package ID',
        createContainer: 'Create Container',
        date: 'Date',
        enterWeights: 'Enter Weights',
        grams: 'Gram(s)',
        labels: 'Labels',
        makeIntoContainer: 'Make Into Container',
        newWeight: 'New Weight',
        packageId: 'Package ID',
        packageProduct: 'Package Product',
        packagingLoss: 'Packaging Loss',
        packagingQuantity: 'Packaging Quantity',
        print: 'Print',
        printContainer: 'Print Container',
        quantity: 'Quantity',
        reconciledWaste: 'Reconciled Waste',
        remainingWeight: 'Remaining Weight',
        select: 'Select',
        shake: 'Shake in grams',
        strain: 'Strain',
        title: 'Pre-Pack',
        totalGrams: 'Total grams',
        totalPacked: 'Total Packed',
        unitId: 'Unit ID',
        unpackedRemainder: 'Unpacked Remainder',
        vendor: 'Vendor',
        wasteReported: 'Waste Reported',
        weight: 'Weight'
      },
      table: {
        actions: 'Actions',
        crtWgt: 'Crt Wgt',
        harvestBatch: 'Harvest Batch',
        location: 'Location',
        testResults: 'Test Results',
        tracking: 'Tracking',
        uom: 'Uom',
        vendor: 'Vendor'
      }
    },
    packaging: {
      complete: {
        failed: 'Packaging job completion failed.',
        success: 'Packaging job was successfully completed.'
      },
      form: {
        actualCompletionTime: 'Actual Completion Time / Packaged Date',
        actualPackagingTime: 'Actual Packaging Time',
        addAnotherPackage: 'Add another package',
        assignEmployees: 'Assign Employee(s)',
        createContainer: 'Create Container',
        date: 'Date',
        employees: 'Employee(s)',
        enterPackagingTime: 'Enter packaging time.',
        enteredPackagingWeight:
          "The sum of new packages net weight can not be greater than the existing package's net weight",
        estimatedCompletionTime: 'Estimated Completion Time',
        estimatedPackagingTime: 'Estimated Packaging Time',
        expectedCompletionTime: 'Expected Completion Time',
        leafWa: {
          medicallyCompliant: 'Medically Compliant',
          medicallyCompliantNote:
            'This action cannot be undone and new medical testing will be required if you decide to switch it back to medical.',
          productNotAltered: 'Product Not Altered',
          productNotAlteredNote:
            'By checking this box I attest this product has undergone no change and does not require additional quality assurance testing.',
          seekingMedicalDesignation: 'Seeking Medical Designation'
        },
        lotId: 'Lot ID',
        makeIntoContainer: 'Make this packaged product into a container',
        noSeedProductFound: 'No Valid Seed EA Products Found',
        packageCreatedAt: 'Created Date',
        packageExpiresAt: 'Expiration Date',
        packageId: 'Package ID',
        packagedAt: 'Packaged Date',
        packages: 'Packages',
        packagingLocation: 'Packaging Location',
        packagingStartTime: 'Packaging Start Time',
        parentPackageId: 'Parent Package ID',
        producer: 'Producer',
        product: 'Product',
        saveLot: 'Save Lot',
        saved: 'Saved',
        selectAtLeastOneProduct: 'Select At least one product',
        selectEmployee: 'Select employee(s).',
        selectProducts: 'You must select a product to package into',
        selectStorageLocation: 'Select a location.',
        sourceFacility: 'Source Facility',
        startPackagingAndTimer: 'Start Packaging & Timer',
        strain: 'Strain',
        wasteWeightError: 'Waste cannot be greater than item quantity.',
        weight: 'Weight'
      },
      generatePackageId: {
        failed: 'Failed to generate package ID.'
      },
      getItem: {
        failed: 'Failed to get item.'
      },
      getItemMaster: {
        failed: 'Failed to get item master.'
      },
      getPackagingJobDetail: {
        failed: 'Failed to get packaging job details.'
      },
      getPackagingJobs: {
        failed: 'Failed to get packaging jobs.'
      },
      getPrepackWeights: {
        failed: 'Failed to get prepack weights.',
        missingPackageSizes: 'You must set up package sizes for this facility.'
      },
      getStrain: {
        failed: 'Failed to get strain. Make sure that it was added to your facility'
      },
      getUsers: {
        failed: 'Failed to get users.'
      },
      references: {
        failed: 'Failed to save package lab result references.',
        success: 'The package lab result references have been saved.'
      },
      savePackagingJob: {
        failed: 'Failed to save packaging job.',
        success: 'The packaging job has been saved.'
      },
      start: {
        failed: 'Packaging job creation failed.',
        success: 'Packaging job was successfully created.',
        title: 'Start Packaging'
      }
    },
    pagers: {
      get: {
        failed: 'Failed to get pagers.'
      }
    },
    partner: {
      createPartners: {
        days: 'Days',
        expireUserProfilePasswords: 'Expire User Profile Passwords',
        sendPasswordExpirationWarningEmail: 'Send Password Expiration Warning Email'
      },
      inNetworkProductDescription: 'In Network Product Description',
      name: 'Name',
      outOfNetworkProductDescription: 'Out Of Network Product Description',
      productList: 'Product List',
      table: {
        address: 'Address',
        phone: 'Phone',
        stateLicense: 'State License'
      },
      title: 'Partners'
    },
    partnerFacilities: {
      get: {
        failed: 'Failed to get partner facilities.'
      }
    },
    partners: {
      InNetwork: 'In Network',
      OutOfNetwork: 'Out Of Network',
      actions: {
        createPartner: 'Create Partner',
        modifyPartner: 'Modify Partner'
      },
      active: 'Active',
      advancedSearch: 'Advanced search',
      availableConnects: {
        categories: 'Categories'
      },
      byRating: 'By Rating',
      connectRequests: {
        organizationCode: 'Organization Code',
        organizationName: 'Organization Name',
        requestedOn: 'Requested On'
      },
      connects: {
        incoming: 'Incoming',
        mapProducts: {
          allowInheritProducts:
            'Allow any additional products from this partner to be created in your system automatically.',
          availablePartnerProducts: 'Available Partner Products',
          mapPartnerProducts: 'Map Partner Products',
          mapSubcategory: 'Map Sub-Category',
          selectItemId: 'Select Item ID',
          yourProduct: 'Your Product'
        },
        outgoing: 'Outgoing',
        request: 'Request',
        requests: {
          cancel: 'Cancel',
          cancelDescription: 'Are you sure you want to cancel your pending request to connect with your partner?',
          cancelFail: 'Unable to cancel request',
          cancelSuccess: 'Request successfully cancelled',
          cancelText: 'Cancel',
          cancelTitle: 'Cancel pending request?',
          okayText: 'OK',
          requestType: 'Request Type',
          respond: 'Respond'
        }
      },
      create: {
        enterName: 'Enter Name',
        fail: 'Server error: Partner creation failed.',
        failed: 'Failed',
        success: 'The partner was successfully created.',
        successWithFacilities: 'The partner and facility or facilities were successfully created.'
      },
      createPartner: {
        ABN: 'ABN',
        AHPRA: 'AHPRA',
        Active: ' Active',
        Inactive: ' Inactive',
        MarketingEmailAddress: 'Marketing Email Address',
        addNewContact: 'Add New Contact',
        addNewFacility: 'Add New Facility',
        biotrackLocationType: 'Biotrack Location Type',
        cod: 'C.O.D.',
        contact: 'Contact {0} ',
        contactInformation: 'Contact Information',
        contactName: 'Contact Name',
        createFacilityProfile: 'Create Facility Profile',
        days: 'days',
        deaNumberErrorMessage: 'DEA Number is required for a facility.',
        documentation: 'Documentation',
        driversLicense: 'Drivers License',
        email: 'Email',
        entityName: 'Entity Name',
        expirationDate: 'Expiration Date',
        expireUserProfilePasswords: 'Expire user profile passwords',
        facilities: 'Facilities',
        facility: 'Facility {0} ',
        facilityName: 'Facility Name',
        firstName: 'First Name',
        flatDiscountPercentage: 'Flat Discount Percentage',
        isExport: 'Export',
        isImport: 'Import',
        lab: 'Lab',
        lastName: 'Last Name',
        leafLicenseeType: 'Leaf Licensee Type',
        leafOrganizationID: 'Leaf Organization ID',
        leafWaLicenseTypes: {
          'co-op': 'Co-op',
          cultivator: 'Producer',
          cultivator_production: 'Producer/Processor',
          dispensary: 'Retailer',
          lab: 'Testing Laboratory',
          production: 'Processor',
          tribe: 'Tribe'
        },
        legalBusinessEntity: 'Legal Business Entity',
        licenseNumber: 'License Number',
        linkedPartnerID: 'Linked Partner ID',
        logo: 'Logo',
        marketingEmailAddress: 'Marketing E-mail Address',
        net: 'Net %{number}',
        note: 'Note',
        number: 'Number',
        onlyNumberContain: 'Field allows only integers',
        organization: 'Organization',
        organizationID: 'Organization ID',
        organizationName: 'Organization Name',
        organizationStatus: 'Organization Status',
        partnerCode: 'Partner Code',
        partnerFacilityType: 'Partner Facility Type',
        partnerGroup: 'Partner Group',
        partnerLogoInfo: 'Display in your Partner account',
        partnerName: 'Partner Name',
        partnerNameDba: 'Partner Name (DBA)',
        partnerRating: 'Partner Rating',
        partnerType: 'Partner Type',
        paymentTerms: 'Payment Terms',
        phone: 'Phone',
        position: 'Position',
        purchaseFrom: 'Purchase From',
        removeUnsavedContact: 'Remove Unsaved Contact',
        retailFacilityType: 'Retail Facility Type',
        salesTaxId: 'Sales Tax ID',
        salesTaxIdNumber: 'Sales Tax Id Number',
        saveAndAddNewContact: 'Save and Add New Contact',
        saveAndAddNewFacility: 'Save and Add New Facilities',
        saveContact: 'Save Contact',
        saveContacts: 'Save Contacts',
        saveFacility: 'Save Facilities',
        saveOrganization: 'Save Organization',
        selectFacility: 'Select at least one facility.',
        sellTo: 'Sell To',
        sendPasswordExpirationWarningEmail: 'Send password expiration warning email',
        stateIntegrationId: 'Leaf Data MME Code',
        stateLicense: 'State License',
        title: 'Create Partner Profile',
        typeNote: 'Type Note…',
        wasteDisposal: 'Waste Disposal',
        wholeSaleTaxId: 'Wholesale Tax ID',
        wholesaleTaxIdNumber: 'Wholesale Tax Id Number'
      },
      createTitle: 'Create Partner Profile',
      form: {
        isNotValidForRegion: 'Is Not Valid For Region'
      },
      get: {
        failed: 'Failed to get partners.'
      },
      getPartner: {
        failed: 'Failed to get partner.'
      },
      inNetworkPartners: 'In-Network Partners',
      inactive: 'Inactive',
      mergeTitle: 'Merge Title',
      modify: {
        fail: 'Failed to modify partner.',
        success: 'The partner was successfully modified.',
        successWithFacilities: 'The partner and facility or facilities were successfully modified.',
        title: 'Modify Partner Profile'
      },
      orderIncomplete: 'Order Incomplete',
      ordersInTransit: 'Orders in Transit',
      ordersPending: 'Orders Pending',
      outOfNetworkPartners: 'Out-of-Network Partners',
      partnerRequests: 'Partner Requests',
      pendingRequests: 'Pending Requests',
      searchPlaceholder: 'Search Partners',
      table: {
        address: 'Address',
        createOrder: 'Create Order',
        dateOrder: 'Date Order',
        email: 'Email',
        id: 'Id',
        items: 'Items',
        merge: 'Merge with In-Network Account',
        orderDetails: 'Order Details',
        orderRequest: 'Order Request',
        orderStatus: 'Order Status',
        orderTotal: 'Order Total',
        organization: 'Organization',
        organizationCode: 'Organization Code',
        partner: 'Partner',
        partnerInfo: 'Partner Information',
        phone: 'Phone',
        phoneLabel: 'Phone:',
        poNumber: 'PO Number',
        products: 'Products',
        rating: 'Rating',
        startPurchaseOrder: 'Start P.O.',
        startSalesOrder: 'Start S.O.',
        stateLicense: 'State License Number:',
        status: 'Status'
      },
      title: 'Partners',
      types: {
        cultivator: 'Cultivator',
        dispensary: 'Dispensary',
        hemp: 'Hemp',
        lab: 'Lab',
        processor: 'Processor',
        researcher: 'Researcher'
      },
      updateTitle: 'Update Partner Profile'
    },
    paymentTerms: {
      get: {
        failed: 'Failed to get payment terms.'
      }
    },
    paymentTypes: {
      get: {
        failed: 'Failed to get payment types.'
      }
    },
    phases: {
      get: {
        failed: 'Failed to load Phases',
        loading: 'Loading Phases…'
      }
    },
    physicians: {
      actions: {
        create: 'Add Physician',
        edit: 'Edit Physician'
      },
      create: {
        failed: 'Failed to create Physician',
        success: 'Successfully created a Physician',
        title: 'Create Physician'
      },
      form: {
        DEANumberLicenseType: 'DEA Number type of license for a physician',
        addAddress: 'Add Address',
        addLicense: 'Add License',
        address: 'Physician Address %{n}',
        addressPlaceholder: 'Enter Address',
        city: 'City',
        cityPlaceholder: 'Enter City',
        clinic: 'Clinic Name',
        clinicPlaceholder: 'Enter Clinic Name',
        country: 'Country',
        deaNumberIsRequired: 'DEA Number is required for a Physician',
        deaNumberType: 'DEA Number',
        firstName: 'First Name',
        firstNamePlaceholder: 'Enter First Name',
        lastName: 'Last Name',
        lastNamePlaceholder: 'Enter Last Name',
        license: 'License Number',
        licensePlaceholder: 'Enter License Number',
        licenseType: 'License Type',
        licenseTypePlaceholder: 'Enter License Type',
        middleName: 'M.I.',
        physicianAddresses: 'Physician Addresses',
        physicianInformation: 'Physician Information',
        physicianLicenses: 'Physician Licenses',
        postalCodePlaceholder: 'Postal Code Placeholder',
        primary: 'Is Primary Address',
        primaryAddressError: 'Please add Primary Address',
        select: 'Select',
        state: 'State/Province',
        stateType: 'State License',
        status: 'Active',
        zipcode: 'Zip/Postal Code',
        zipcodePlaceholder: 'Enter Zip/Postal Code'
      },
      get: {
        failed: 'Failed to load Physicians',
        loading: 'Loading Physicians…'
      },
      modify: {
        failed: 'Failed to modify Physician',
        loading: 'Loading Physician…',
        success: 'Successfully modified Physician',
        title: 'Modify Physician'
      },
      saving: 'Saving Physician…',
      table: {
        active: 'View Active Physicians',
        firstName: 'First Name',
        inactive: 'View Inactive Physicians',
        lastName: 'Last Name',
        title: 'Physician Listing'
      }
    },
    plantCosting: {
      addEmployee: 'Add Employee',
      addNewItemCost: 'Add New Item Cost',
      allocatedOverhead: 'Allocated Overhead',
      allocatedOverheadDescription:
        'Cost is fixed based on the room for these two amounts. Plants in those rooms during the time period have costs bren down by day and applied to the plants in that room for each day',
      cost: 'Cost',
      'cost/Item': 'Cost Item',
      costItem: 'Cost/Item',
      createPlantCosting: 'Create Plant Costing',
      cure: 'Cure',
      day: 'Day',
      directCostsByStage: 'Direct Costs By Stage',
      electricity: 'Electricity',
      employee: 'Employee',
      employeeName: 'Employee Name',
      fixedCostByStage: 'Fixed Cost By Stage',
      flowering: 'Flowering',
      harvest: 'Harvest',
      item: 'Item',
      laborCost: 'Standard Labor Cost per Month',
      laborOverhead: 'Labor Overhead',
      monthlyPay: 'Monthly Pay',
      package: 'Package',
      propagation: 'Propagation',
      qty: 'Qty',
      quantity: 'Quantity',
      rent: 'Rent',
      save: 'Save',
      shipping: 'Shipping',
      standardCost: 'Standard Monthly Cost',
      totalBill: 'Total Bill',
      trim: 'Trim',
      uom: 'Uom',
      vegetation: 'Vegetation',
      vegetative: 'Vegetative',
      water: 'Water',
      waterDescription: 'Based on feeding schedule applied to the individual plant and the amount of water required.'
    },
    plantHistory: {
      feedingScheduleDetails: 'Feeding Schedule Details',
      field: 'Field',
      noFeedingScheduleIngredients: 'No ingredient assigned to the feeding schedule',
      plant: {
        airyBuds: 'Airy Buds',
        amountWaste: 'Amount Waste',
        amountWasteRecord: 'From %{from} %{uom} to %{to} %{uom}',
        budRot: 'Bud Rot',
        compromised: 'Compromised',
        destroyReason: 'Destroy Reason',
        destroyedWeight: 'Destroyed Weight',
        failed: 'Failed',
        feedingSchedule: 'Feeding Schedule',
        harvestBatch: 'Harvest Batch',
        location: 'Location',
        lotId: 'Lot Id',
        mold: 'Mold',
        motherPlant: 'Mother Plant',
        packageCode: 'Package Code',
        packageLocation: 'Package Location',
        pestResistance: 'Pest Resistance',
        phase: 'Phase',
        phenotype: 'Phenotype',
        plantBatch: 'Plant Batch',
        plantDetails: 'Plant Details',
        plantId: 'Plant Id',
        plantType: 'Plant Type',
        powderyMildew: 'Powdery Mildew',
        product: 'Product',
        qty: 'Qty',
        sourceID: 'Source ID',
        strain: 'Strain',
        stretching: 'Stretching',
        totalQuality: 'Total Quality',
        trackingId: 'Tracking Id',
        wasteReported: 'Waste Reported',
        weight: 'Weight'
      },
      plantGroupId: 'Group ID',
      plantId: 'Plant Id',
      value: 'Value',
      valueAdjustment: 'Value Adjustment'
    },
    plants: {
      actions: {
        activate: 'Activate Plants',
        createHarvestPackage: 'Create Harvest Package',
        createLots: 'Create Lot(s)',
        createPackage: 'Create Package',
        createPlants: 'Create Plants',
        createPropagationMaterial: 'Create Propagation Material',
        destroyPlants: 'Destroy Plants',
        harvestBatch: 'Harvest Batch',
        harvestPlants: 'Harvest Plants',
        modifyHarvestBatch: 'Modify Harvest Package',
        modifyPlant: 'Modify Plant',
        packageMotherPlants: 'Package Mother Plant(s)',
        packagePlants: 'Package Plant(s)',
        plantModification: 'Plant Modification',
        showColumns: 'Show Columns',
        splitGroup: 'Split Group',
        updateSearch: 'Update Search',
        updateSearchFail: 'Reindexing failed',
        updateSearchSuccess: 'Reindexing was completed successfully'
      },
      activate: {
        failed: 'Failed to activate plant(s)',
        success: 'Successfully activated plant(s)'
      },
      create: {
        creating: 'Creating %{count} %{plantNoun}',
        exceedsOverallLimit: 'Adding %{starting_qty} plants would exceed overall limit of %{plants_on_hand}.',
        exceedsVegLimit: 'Adding %{starting_qty} plants would exceed vegetation limit of %{plants_in_veg}.',
        fail: 'Server error: Plant creation failed.',
        success:
          'Your plants have been created successfully. Bulk changes can take up to a few minutes to process. Please allow time for the Plant Listing to be updated.',
        successBulk:
          'Your plants have been created successfully. Bulk changes can take up to a few minutes to process. Please allow time for the Plant Listing to be updated.',
        successBulkPrint: 'Your plants have been created successfully. Labels can now be printed.',
        title: 'Create Plant'
      },
      createBatch: {
        fail: 'Failed to create batch',
        success: 'Created batch.',
        title: 'Create Plant Batch'
      },
      createPackages: {
        failed: 'Failed',
        success: 'Success'
      },
      cumulativeCost: 'Cumulative Cost',
      destroy: {
        destroying: 'Destroying %{count} %{plantNoun}',
        fail: 'Failed to destroy plants',
        failed: 'Failed to destroy plants',
        success: 'Plants destroyed.',
        successBulk:
          'Your plants have been destroyed successfully. Bulk changes can take up to a few minutes to process. Please allow time for the Plant Listing to be updated.',
        title: 'Destroy Plants'
      },
      feedingScheduleName: 'Feeding Schedule Name',
      form: {
        addAndCreateNewPlants: 'Add And Create New Plants',
        additionalTracking: 'Additional Tracking',
        airyBuds: 'Airy Buds',
        assignTag: 'Assign Plant Tag',
        batch: 'Batch',
        batchError: 'Enter a batch.',
        batchPlaceholder: 'ex. BUK716-G1',
        batch_name: 'Batch Name',
        beginTag: 'Beginning Tag',
        beginningTag: 'Beginning Tag',
        blight: 'Blight',
        btReasons: {
          infestation: 'Infestation',
          lcbMandated: 'LCB Mandated',
          mistake: 'Mistake',
          other: 'Other',
          productReturn: 'Product Return',
          qualityControl: 'Quality Control',
          spoilage: 'Spoilage',
          unhealthy: 'Unhealthy or Died',
          waste: 'Waste'
        },
        budRot: 'Bud Rot',
        createMore: 'Create More',
        cull: 'Cull',
        destroy: 'Destroy',
        destroyPlant: 'Destroy Plant',
        destroyPlants: 'Destroy Plants',
        destroyReason: 'Destroy Reason',
        destroyReasonPlaceholder: 'Destroy Reason Placeholder',
        destructionDate: 'Destruction Date',
        endTag: 'Ending Tag',
        endingTag: 'Ending Tag',
        enterDestroyDate: 'Enter Destroy Date',
        enterDestroyReason: 'Enter Destroy Reason',
        enterDestroyWeight: 'Enter Destroy Weight',
        enterGroupError: 'Enter a group name.',
        enterQuantityError: 'Enter a quantity.',
        feeding: 'Feeding Schedule',
        flowerWeightExceeded:
          'New Flower Weight %{new_weight} Gram(s) New Harvest Batch Wt. must be lower or equal to %{current_weight}',
        grandmotherPlant: 'Grandmother Plant',
        group: 'Group Name',
        groupPlaceholer: 'ex. BubbaKrusher',
        growthPhase: 'Change Growth Phase',
        harvestBatch: 'Harvest Batch',
        harvestDate: 'Harvest Date',
        harvestWasteType: 'Harvest Waste Type',
        isMother: 'Is Mother',
        location: 'Change Location',
        lostStolen: 'Lost Stolen',
        male: 'Male',
        matriarchPlant: 'Matriarch Plant',
        modify: 'Modify Selection',
        mold: 'Mold',
        motherPlant: 'Mother Plant',
        mustBeIntegerError: 'Must be an integer.',
        mustBePositiveError: 'Must be positive.',
        mustBeSmallEnoughError: 'Must be < 10000.',
        mustBeSmallEnoughErrorForGroup: 'Must be < 10000000.',
        mustBeWholeNumber: 'Must be whole number.',
        other: 'Other',
        otherMaterialExceeded:
          'Other Material Weight %{new_weight} Gram(s) New Harvest Batch Wt. must be lower or equal to %{current_weight}',
        overallValues: 'Overall Values',
        pestResistance: 'Pest Resistance',
        pests: 'Pests',
        phenotype: 'Phenotype',
        plantId: 'Plant ID',
        plantIdPlaceholder: 'ex. BUK716-G1',
        plantName: 'Plant Name',
        plantNamePlaceholder: 'ex. BUK716-G1',
        plantType: 'Plant Type',
        plantTypeError: 'Select a plant type.',
        plantingBatch: 'Planting Batch',
        plantingDate: 'Planting Date',
        plantingDateError: 'Enter a planting date.',
        powderyMildew: 'Powdery Mildew',
        printLabel: 'Print Label',
        qtyExceedsMaximum: 'Quantity would exceed maximum allowed plants.',
        quantity: 'Quantity',
        quantityPlaceholder: 'ex. 100',
        rangeEndingPlant: 'Range Ending Plant',
        rangeStartingPlant: 'Range Starting Plant',
        replaceTag: 'Replace Plant Tag',
        reset: 'Reset',
        save: 'Save',
        saveAndAddMore: 'Save and Continue',
        saveAndAddToInventory: 'Save And Add To Inventory',
        saveAndCreateMore: 'Save and Create More',
        saveAndPrintLabel: 'Save and Print Label',
        saveAndReturn: 'Save and Return',
        selectBuilding: 'Select Building',
        selectExistingBatch: 'Select Existing Batch',
        selectFeedingScheduleError: 'Select a feeding schedule.',
        selectGrowthPhaseError: 'Select Growth Phase Error',
        selectItemMasterError: 'Select a Product',
        selectLocation: 'Select Location',
        selectLocationError: 'Select a location',
        selectMotherPlantError: 'Select a mother plant.',
        selectPlantTypeError: 'Select Plant Type Error',
        selectPlantingBatch: 'Select Planting Batch',
        selectRegister: 'Select Register',
        selectRoom: 'Select Room',
        selectSection: 'Select Section',
        selectStrainError: 'Select a strain.',
        selectTrackingTagError: 'Select a tracking tag.',
        selectUom: 'Select Uom',
        selectZone: 'Select Zone',
        selectedPlants: 'Selected Plants',
        sourceItem: 'Source Item',
        storageArea: 'Storage Area',
        strain: 'Strain Name',
        stretching: 'Stretching',
        totalCantExceed: "Total can't exceed",
        totalPlantCount: 'Total Plant Count',
        totalQuality: 'Total Quality',
        tracking: 'Assign Tracking',
        trackingId: 'Tracking Id',
        viewAll: 'View All',
        waste: 'Report Waste',
        weight: 'Weight',
        weightPlaceholder: 'Weight Placeholder',
        wetWeightZero: 'Wet weight must be > 0.'
      },
      getPlant: {
        failed: 'Failed to load Plant'
      },
      inactivateReasons: {
        harvested: 'Reason: Harvested',
        packaged: 'Reason: Packaged'
      },
      modify: {
        additionalId: 'Additional ID',
        beginningTag: 'Beginning Tag',
        changePhenotype: 'Change Phenotype',
        changeStrain: 'Change Strain',
        destroyPlant: 'Destroy Plant',
        editPlantInformation: 'Edit Plant Information',
        fail: 'Server error: Plant modification failed.',
        groupCount: 'Group Count',
        groupTitle: 'Group Plant Modification',
        individualPlantsRightTitle: 'Planting Batch: %{batchTitle}',
        individualPlantsTitle: 'Location: %{locationTitle}',
        invalidSelection: 'Missing Tag Assignment',
        modificationDate: 'Modification Date',
        modifyGroup: 'Modify Group',
        modifying: 'Modifying %{count} %{plantNoun}',
        moreIdsNeeded: 'More tracking IDs needed',
        moreThanDate: '* Please enter something more than modification date to modify plant(s)',
        motherPlantId: 'Mother Plant ID',
        newBatchName: 'New Batch Name',
        newBatchNamePending: 'New batch name pending…',
        newFeedingSchedule: 'New Feeding Schedule',
        plantAdjustments: 'Plant Adjustments',
        plantCount: 'Plant Count',
        plantGroupId: 'Group ID',
        plantId: 'Plant ID',
        plantTracking: 'Plant Tracking',
        propagationMixCatch: 'Tag Assignment Catch',
        propagationMixError: 'Propogation Tags Required',
        quantity: 'Qty',
        reportWaste: 'Report Waste',
        selectPlants: 'Select Plants',
        showTagsElement: 'Show tracking IDs',
        singleSuccess: 'Plant was modified successfully.',
        success: 'Plant was modified successfully. %{batch_names}',
        successBulk:
          'Your plants have been modified successfully. Bulk changes can take up to a few minutes to process. Please allow time for the Plant Listing to be updated.',
        title: 'Plant Modification',
        trackingId: 'Tracking Id'
      },
      packageMothers: {
        failed: 'Failed to package Plants',
        form: {
          addPackage: 'Add Package',
          batch: 'Harvest Batch',
          packages: 'Mother Plant Packages',
          plantId: 'Plant ID',
          product: 'Product',
          quantity: 'Quantity',
          storageLocation: 'Storage Location',
          strainName: 'Strain Name'
        },
        success: 'Plants packaged successfully',
        title: 'Package Mother Plants'
      },
      packagePlants: {
        actions: {
          assignTags: 'Assign Tag(s)',
          exitPage: 'Exit Page',
          packagePlants: 'Package Plant(s)',
          packagingPlantsMessage: 'Packaging Plant(s)',
          printAndSave: 'Save And Print'
        },
        datePackaged: 'Date Packaged',
        id: 'Id',
        lotId: 'Lot ID',
        newItem: 'New Item',
        noNewItem: 'No items available for use',
        noValidProductsError:
          'No valid product available for this plant! To correct this, create a product with the same strain as the plant.  Set it\'s "Item Category" to Plants, "Inventory Unit of Measure" to EA,       and check both "We Track This Inventory" and "Track this item in Lots".',
        note: 'Note',
        plantGroupId: 'Group ID',
        plantId: 'Plant ID',
        storageLocation: 'Storage Location',
        title: 'Package Plant(s)',
        tracking: 'Tracking'
      },
      packages: {
        prePack: {
          packageId: 'Package Id',
          storageLocation: 'Storage Location'
        }
      },
      plantGroupId: 'Group ID',
      plantId: 'Plant ID',
      split: {
        add: 'Add',
        delete: 'Delete',
        location: 'Location',
        newPlantQty: 'New Quantity',
        newPlantsGroupTitle: 'New Plants Split From Source Group',
        newPlantsTitle: 'New Plants Split From Source Plant',
        newQty: 'New Qty:',
        ord: 'Ord',
        plantGroupId: 'Group ID',
        plantId: 'Plant ID',
        plantingDate: 'Planting Date',
        qty: 'Original Qty',
        sourceGroupTitle: 'Source Group',
        sourceTitle: 'Source Plant',
        storageLocation: 'Storage Location',
        title: 'Plant Split'
      },
      strainName: 'Strain Name',
      table: {
        Label: 'Label',
        batch: 'Batch',
        dateCreated: 'Date Created',
        daysInPhase: 'Days In Phase',
        daysInPhaseDescription:
          'The number of days being displayed here is based on the date the action was applied to the plant. If you are not reporting phase change in real time you will have an inaccurate number displayed.',
        destroyed: 'Destroyed',
        feeding: 'Feeding',
        groupId: 'Group Id',
        harvested: 'Harvested',
        id: 'ID',
        inactivationReason: 'Inactivation Reason',
        isMother: 'Is Mother',
        location: 'Location',
        motherPlant: 'Mother Plant',
        onHold: 'Hold',
        onlyOneBatch: 'All plants must have the same batch name to be packaged.',
        onlyOneStrain: 'All plants must have the same strain name to be packaged.',
        packaged: 'Packaged',
        phase: 'Phase',
        phenotype: 'Phenotype',
        plantGroupId: 'Group ID',
        plantId: 'Plant ID',
        plantingDate: 'Planting Date',
        quantity: 'Qty',
        scheduledForDestroy: 'Scheduled For Destroy',
        stage: 'Stage',
        strain: 'Strain',
        tracking: 'Tracking',
        type: 'Type',
        userId: 'User ID',
        waste: 'Waste',
        wasteReported: 'Waste Reported'
      },
      title: 'Plant Listing',
      waste: {
        fail: 'Failed to report live waste',
        success: 'Live waste reported.',
        title: 'Collect Live Waste',
        wasteReportedPlantModified: 'Waste Reported (Plant Modified)'
      }
    },
    prepackWeights: {
      add: {
        fail: 'Failed to add custom Package size',
        success: 'Custom Package size added'
      },
      form: {
        applyToPurchases: 'Apply to PO',
        applyToSales: 'Apply to SO',
        category: 'Category',
        customPackageSize: 'Custom Package Size',
        displayInactivationReason: 'Display Inactivation Reason',
        packageSizeType: 'Package Size Type',
        packagingSeeds: 'Packaging Seeds',
        save: 'Save',
        selectCategories: 'Select categories…',
        selectTypes: 'Select types…'
      },
      getPrepackWeights: {
        failed: 'Failed to get Prepack Weights'
      },
      modify: {
        fail: 'Failed to apply Package sizes to facility',
        success: 'Package sizes are applied to facility'
      },
      modifyOrg: {
        fail: 'Failed to apply Package sizes to organization',
        failed: 'Failed',
        success: 'Package sizes are applied to organization'
      },
      selectPackageSizes: 'Select Package Sizes'
    },
    printServers: {
      errors: {
        haveLocalPrintServer: 'This machine is already set up as a print server.',
        noQzTray: 'Refresh or connect QZ Tray',
        selectedPrinterActive: 'The selected Print Server is active implying it is bound elsewhere.',
        unknown: 'An unexpected error has occurred.'
      }
    },
    printerSettings: {
      dpi203: '203 DPI',
      dpi300: '300 DPI',
      dpi600: '600 DPI',
      largeFont: 'Large Font',
      printers: 'Printers',
      resolution: 'Resolution',
      smallFont: 'Small Font'
    },
    productDetails: {
      measurements: {
        aPinene: 'A PINENE',
        bPinene: 'B PINENE',
        bisabolol: 'BISABOLOL',
        caryophyllene: 'CARYOPHYLLENE',
        caryophylleneOxide: 'CARYOPHYLLENE OXIDE',
        humulene: 'HUMULENE',
        limonene: 'LIMONENE',
        linalool: 'LINALOOL',
        myrcene: 'MYRCENE',
        terpinolene: 'TERPINOLENE'
      }
    },
    productLookup: {
      cancelButton: 'Cancel',
      categoryLabel: 'Category',
      noResults: 'No results',
      okayButton: 'Confirm',
      selectCategory: 'Select category',
      selectSubCategory: 'Select subcategory',
      subCategoryLabel: 'Subcategory',
      title: 'Select Product'
    },
    productMenu: {
      addToCart: 'Add To Cart',
      all: 'All',
      catalog: {
        get: {
          failed: 'Failed to get catalog.'
        }
      },
      delivery: 'DELIVERY',
      fastTrack: 'Fast Track',
      inStore: 'IN STORE',
      medical: 'Medical',
      networkError: 'Network error. Check your internet connection and try again.',
      online: 'ONLINE',
      packaging: 'PACKAGING',
      payment: 'PAYMENT',
      pickup: 'PICKUP ORDERS',
      price: 'Price',
      pricingMessage: 'price may vary based on amount ordered',
      productMenu: 'Product Menu',
      recreational: 'Recreational',
      search: 'Search',
      sortCategoryAsc: 'Sort Category Asc',
      sortCategoryDesc: 'Sort Category Desc',
      sortNameAsc: 'Sort Name Asc',
      sortNameDesc: 'Sort Name Desc',
      sortPreferences: 'Sort Preferences',
      sortPriceAsc: 'Sort Price Asc',
      sortPriceDesc: 'Sort Price Desc',
      unitOfMeasure: 'UOM'
    },
    productStatements: {
      category: 'Category',
      clickToSelect: 'Click a %{type} to select or de-select.',
      global: 'Global',
      globalStatements: 'Global Statements',
      health: 'Health And Benefits Statement',
      ides: 'Non-Organic Pesticides/Herbicides/Fungicides Statement',
      inheritHints:
        'Statements follow an inheritance chain.  For example, a product may inherit a mandated statement from global, a health statement from its category, and a pesticides statement from its sub category.  Any statement set directly on a product will override inherited statements.',
      item_master: 'Product',
      manageStatements: 'Manage Product Statements For Labels',
      mandated: 'Mandated Statement',
      messages: {
        categoryLoading: 'Loading Category Statements…',
        categorySaving: 'Saving Category Statements…',
        globalLoading: 'Loading Global Statements…',
        globalSaving: 'Saving Global Statements…',
        subcategoryLoading: 'Loading Sub Category Statements…',
        subcategorySaving: 'Saving Sub Category Statements…'
      },
      moreThanOneStatement: 'Selected Items have more than one statement set in this statement type.',
      notSet: 'Not Set',
      numberSelected: '%{number} selected',
      saveStatements: 'Save Statements',
      selectCategories: 'Select Categories',
      selectSubCategories: 'Select Sub Categories',
      sourceHints: 'The panel with a green header indicates where statements will be set.',
      statement: 'Statement',
      statements: 'Statements',
      statementsHints: 'To delete a statement click the X icon for the statement and save.',
      subCategory: 'Sub Category',
      subcategory: 'Sub Category'
    },
    productType: {
      label: {
        Concentrate: 'Concentrate',
        cartridges_pens: 'Cartridges/Pens',
        concentrate: 'Concentrate',
        flower: 'Flower',
        harvest_test_sample: 'Harvest Test Sample',
        infused_edible: 'Infused Edible',
        infused_non_edible: 'Infused Non-Edible',
        marijuana: 'Marijuana',
        non_medicated: 'Non-medicated',
        plants: 'Plants',
        plants_clones: 'Plants/Clones',
        plants_seeds: 'Plants/Seeds'
      }
    },
    productionRuns: {
      get: {
        failed: 'Failed to load Production Runs'
      },
      noRuns: 'No Production Runs found',
      runTypes: {
        assembly: 'Assembly',
        processing: 'Processing'
      },
      title: 'Production Runs'
    },
    products: {
      actions: {
        activate: 'Activate',
        addTestResults: 'Add Test Results',
        completePackaging: 'Complete Packaging',
        createContainer: 'Create Container',
        createProduct: 'Create Product',
        cloneProduct: 'Duplicate Product',
        edit: 'Edit',
        inactivate: 'Inactivate',
        massModify: 'Mass Modify',
        modifyPackages: 'Modify Packages',
        prePack: 'Prepack',
        replaceTestResults: 'Replace Test Results',
        splitHarvestBatch: 'Split Harvest Batch',
        startPackaging: 'Start Packaging',
        transferPackages: 'Transfer Packages',
        transferProduct: 'Transfer Product',
        viewActive: 'View Active',
        viewInactive: 'View Inactive'
      },
      collection: 'Products',
      create: {
        failed: 'Failed to Create Product',
        success: 'Successfully Created Product',
        title: 'Create Products'
      },
      detail: {
        dominance: 'Dominance',
        mother: 'Mother',
        pollenDonor: 'Pollen Donor',
        strainName: 'Strain Name'
      },
      form: {
        AdministrationMethod: 'Administration Method',
        AdministrationMethodsPlaceholder: 'Administration Methods Placeholder',
        activationTime: 'Activation Time',
        active: 'Active',
        addAnotherStation: 'Add Another Station',
        addMultiPackInfo: 'Add Multi-Pack Information',
        addVendor: 'Add Another Vendor',
        additionalInfo: 'Additional Information',
        administrationMethod: 'Administration Methods',
        administrationMethodPlaceholder: 'Enter Administration Methods, One per Line',
        allergens: 'Allergens',
        allergensFile: 'Allergens File',
        allergensPlaceholder: 'Enter Allergens, One per Line',
        alternateProductImage: 'Alternate Product Image',
        amountPlaceholder: 'Amount Placeholder',
        apiBuffer: 'Inventory Buffer (equal or less than)',
        autoReorderQuantity: 'Auto-Reorder Quantity',
        availableToConnects: 'Available for Connects',
        b_caryophyllene: 'B Caryophyllene',
        b_caryophyllene_percent: 'B Caryophyllene Percent',
        b_caryophyllene_weight: 'B Caryophyllene Weight',
        b_myrcene: 'B Myrcene',
        b_myrcene_percent: 'B Myrcene Percent',
        b_myrcene_weight: 'B Myrcene Weight',
        b_pinene: 'B Pinene',
        b_pinene_percent: 'B Pinene Percent',
        b_pinene_weight: 'B Pinene Weight',
        basePrice: 'Base Price Per %{uom}',
        bisabolol: 'Bisabolol',
        bisabolol_percent: 'Bisabolol Percent',
        bisabolol_weight: 'Bisabolol Weight',
        brand: 'Item Brand',
        byFacility: 'By Facility',
        caryophyllene_oxide: 'Caryophyllene Oxide',
        caryophyllene_oxide_percent: 'Caryophyllene Oxide Percent',
        caryophyllene_oxide_weight: 'Caryophyllene Oxide Weight',
        categoryManagementLink: 'Go To Category Management',
        cbc: 'CBC',
        cbc_percent: 'CBC Percent',
        cbc_weight: 'CBC Weight',
        cbd: 'CBD',
        cbdPercent: 'Cbd Percent',
        cbdWeight: 'Cbd Weight',
        cbd_percent: 'CBD Percent',
        cbd_weight: 'CBD Weight',
        cbda: 'CBDA',
        cbda_percent: 'CBDA Percent',
        cbda_weight: 'CBDA Weight',
        cbdv: 'CBDV',
        cbdv_percent: 'CBDV Percent',
        cbdv_weight: 'CBDV Weight',
        cbg: 'CBG',
        cbg_percent: 'CBG Percent',
        cbg_weight: 'CBG Weight',
        cbga: 'CBGA',
        cbga_percent: 'CBGA Percent',
        cbga_weight: 'CBGA Weight',
        cbn: 'CBN',
        cbn_percent: 'CBN Percent',
        cbn_weight: 'CBN Weight',
        cost: 'Cost',
        costPer: 'Cost Per',
        costPerGram: 'Cost Per Gram',
        costPerUnit: 'Cost Per Unit',
        createIndSku: 'Create Individual SKUs',
        customerGroup: 'Customer Group',
        d8_thc: 'D8 THC',
        d8_thc_percent: 'D8 THC Percent',
        d8_thc_weight: 'D8 Weight',
        d9_thc: 'D9 THC',
        d9_thc_percent: 'D9 THC Percent',
        d9_thc_weight: 'D9 THC Weight',
        d9_thca: 'D9 THCA',
        d9_thca_percent: 'D9 THCA Percent',
        d9_thca_weight: 'D9 THCA Weight',
        defaultPrice: 'Default Price',
        dilutionMethod: 'Method of Dilution',
        displayName: 'Display Name',
        displayNamePlaceholder: 'Enter Display Name',
        dominanceName: 'Dominance Name',
        editLoction: 'Edit Location',
        enterCost: 'Enter Cost',
        grossWeight: 'Gross Weight',
        humulene: 'Humulene',
        humulene_percent: 'Humulene Percent',
        humulene_weight: 'Humulene Weight',
        ingredients: 'Ingredients',
        ingredientsAllergensAndNutritionalInfo: 'Ingredients, Allergens, And Nutritional Information',
        ingredientsFile: 'Ingredients File',
        ingredientsPlaceholder: 'Enter Ingredients, One per Line',
        inheritClassChanges: 'Inherit Pricing Class Updates',
        inheritGroupChanges: 'Inherit Pricing Group Changes',
        invalidApiBuffer: 'Invalid Inventory buffer',
        inventorySettings: 'Inventory Settings',
        inventoryUom: 'Inventory Unit of Measure',
        itemCategory: 'Item Category',
        itemId: 'Item ID',
        itemName: 'Item Name',
        itemNamePlaceholder: 'Enter Item Name',
        itemSubcategory: 'Item Subcategory',
        leaflyAvailability: 'Leafly Availability',
        limonene: 'Limonene',
        limonene_percent: 'Limonene Percent',
        limonene_weight: 'Limonene Weight',
        linalool: 'Linalool',
        linalool_percent: 'Linalool Percent',
        linalool_weight: 'Linalool Weight',
        lineage: 'Lineage',
        location: 'Location',
        masterItem: 'Master Item',
        medicalPrices: 'Medical Price',
        medicatedInventory: 'This is a medicated item',
        medicatedNetVolume: 'Medicated Net Volume',
        medicatedNetWeight: 'Medicated Net Weight',
        medicatedNetWeightCannotBeZero: 'Medicated Net Weight cannot be zero',
        medicatedNetWeightPer1Gr: 'Medicated Net Weight per 1 gram',
        medicatedVolume: 'Medicated Volume',
        metrcProductIdentifier: 'Metrc Product Identifier',
        missingApplicableFacilities:
          'You should select applicable facilities when you have defined pricing by facility.',
        multipackName: 'Mult-Pack Name',
        netVolume: 'Net Volume',
        nonTaxableProduct: 'Non-Taxable Product',
        nutritional: 'Nutritional Information',
        nutritionalFile: 'Nutritional Information File',
        nutritionalPlaceholder: 'Enter Nutritional Information, One per Line',
        onlineAvailability: 'Online Availability',
        organization: 'Organization Defaults',
        packaging: 'Packaging',
        packagingOptions: {
          bag: 'Bag',
          bottle: 'Bottle',
          box: 'Box',
          jar: 'Jar'
        },
        parLevel: 'Par Level',
        percentOfBasePrice: '% of Base Price',
        phenotype: 'Phenotype',
        piecesPerUnit: 'Pieces Per Unit',
        pinene: 'Pinene',
        pinene_percent: 'Pinene Percent',
        pinene_weight: 'Pinene Weight',
        potency: 'Potency',
        potencyInfo: 'Potency Information',
        prePackItem: 'Pre-Pack Item',
        pricePerUnit: 'Price Per Unit',
        pricePlaceholder: 'Enter Price',
        pricingClass: 'Pricing Class',
        pricingGroup: 'Pricing Group',
        pricingType: 'Pricing Type',
        produceInventory: 'We produce this item',
        productActive: 'Active',
        productDescription: 'Product Expansion Description',
        productImage: 'Product Image',
        productImageDescription: 'Displays in your Product Listing Page for your Point of Sale, as well as Online.',
        productImageLimits: '(2MB Max and must JPG or PNG on a white background)',
        productInformation: 'Product Information',
        productNetWeight: 'Product Net Weight',
        purchaseInventory: 'We purchase this item',
        purchasingInfo: 'Purchasing Information',
        quantity: 'Quantity',
        recreationalPrices: 'Recreational Price',
        requiredReorderQuantity: 'Required Reorder Quantity',
        requiresRefrigeration: 'Product Requires Refrigeration',
        reservationsHistory: 'Reservations History',
        salesInfo: 'Sales Info',
        save: 'Save',
        saveAndCreateAnother: 'Save and Create Another',
        saveAndDuplicate: 'Save and Duplicate',
        saveAndReturn: 'Save and Return',
        savePrepackItemMaster:
          'Your product has been created successfully. Please allow time for the the system to complete setup of this product.',
        savingProduct: 'Saving Product…',
        selectPricingGroup: 'Please select Pricing Group',
        sellInventory: 'We sell this Item',
        servingSize: 'Serving Size',
        servingsPerContainer: 'Servings Per Container',
        shareProductWithLeafly: 'Share Product With Leafly',
        shareProductWithWeedmaps: 'Share Product With Weedmaps',
        sharedProductOff:
          'If you select to proceed, the shared inventories will no longer be shared with the other facilities. Please check out and do reconciliation to correct the inventory quantities as required.',
        sharedProductOn:
          'You have defined a shared subcategory for the product. Inventory items will be shared with the configured facilities.',
        solvent: 'Solvent',
        strainName: 'Strain Name',
        strainNotForFacility: 'Strain is not assigned to this facility so cannot be edited.',
        strainRequired: 'Select Strain',
        subcategoryWarning:
          'If you update the subcategory of the product(s), please make sure you have this subcategory mapped.',
        supplyDurationDays: 'Supply Duration',
        tags: 'Tags',
        terpene: 'Terpene',
        terpine: 'Terpene',
        terpineInfo: 'Terpene Information',
        terpinolene: 'Terpinolene',
        terpinolene_percent: 'Terpinolene Percent',
        terpinolene_weight: 'Terpinolene Weight',
        thcv: 'THCV',
        thcv_percent: 'THCV Percent',
        thcv_weight: 'THCV Weight',
        total: 'Total',
        trackInventory: 'We track this inventory',
        trackLots: 'Track this item in Lots',
        trackMultiPacks: 'Track in Multi-Packs',
        unit: 'Unit',
        unitCount: 'Unit Count',
        uom: 'UOM',
        uploadImage: 'Upload Image',
        uploading: 'Uploading…',
        variant: 'Variant',
        variantId: 'Variant Id',
        vendor: 'Vendor',
        vendors: 'Vendors',
        weedmapsAvailability: 'Weedmaps Availability',
        weedmapsCategoryShouldBeMapped: 'This subcategory needs to be mapped to a Weedmaps category',
        wholesalePrices: 'WholeSale Price'
      },
      get: {
        failed: 'Failed to get products.'
      },
      getStrain: {
        failed: 'Failed to find Product Strain. Make sure it was added to organization strains',
        strainIdInvalid: 'Strain is not assigned to this product and is required for packaging.'
      },
      infoMod: {
        activation: 'Activation',
        addMultiPackInfo: 'Add Multi Pack Info',
        additionalInfo: 'Additional Info',
        cost: 'Cost',
        dominance: 'Dominance',
        editInfo: 'Edit Info',
        generalInfo: 'General Info',
        grossWeight: 'Gross Weight',
        individSKU: 'Individ SKU',
        inventorySettings: 'Inventory Settings',
        inventoryUnit: 'Inventory Unit',
        medItem: 'Med Item',
        medicatedWeight: 'Medicated Weight',
        methodOfDilution: 'Method Of Dilution',
        miltipack: 'Miltipack',
        modDate: 'Mod Date',
        modify: 'Modify',
        numberOfSizes: 'Number Of Sizes',
        online: 'Online',
        piecesUnit: 'Pieces Unit',
        placeholderCost: 'Placeholder Cost',
        placeholderCount: 'Placeholder Count',
        placeholderName: 'Placeholder Name',
        placeholderNumberOfSize: 'Placeholder Number Of Size',
        placeholderQuantity: 'Placeholder Quantity',
        placeholderSize: 'Placeholder Size',
        potencyInfo: 'Potency Info',
        prePackItem: 'Pre Pack Item',
        pricePerUnit: 'Price Per Unit',
        pricingGroup: 'Pricing Group',
        pricingType: 'Pricing Type',
        productInfo: 'Product Info',
        productName: 'Product Name',
        productWeight: 'Product Weight',
        purchaseItem: 'Purchase Item',
        requiredQuantity: 'Required Quantity',
        requiresFrige: 'Requires Frige',
        salesInfo: 'Sales Info',
        sellItem: 'Sell Item',
        solvent: 'Solvent',
        status: 'Status',
        strainName: 'Strain Name',
        tags: 'Tags',
        title: 'Title',
        trackInPacks: 'Track In Packs',
        trackInventory: 'Track Inventory',
        unitCount: 'Unit Count',
        uom: 'Uom',
        wholesale: 'Wholesale',
        wholesalePrice: 'Wholesale Price'
      },
      item: 'Product',
      massModify: {
        addToList: 'Add To List',
        additionalInformationTitle: 'Additional Information',
        edit: 'Edit',
        errors: {
          diff_categories_error: "'%{field}' cannot be updated because you selected products of different categories.",
          diff_categories_help:
            "'%{field}' is not applicable for product categories: %{categories}. The value you define won’t be saved for products of such categories.",
          diff_field_error: "'%{field}' cannot be updated because you selected products of different %{label}.",
          diff_uoms: "'%{field}' cannot be updated because you have selected products with different UOM type.",
          inventory_exist: "'%{field}' cannot be updated because some products you selected already have inventory.",
          metrc_subcategories:
            'You updated the subcategory of the product(s), please make sure you have this subcategory mapped.',
          not_shared_to_shared:
            'You have defined a shared subcategory for the product. The inventory items will be shared with the configured facilities.',
          shared_to_not_shared:
            'If you select to proceed, your linked inventories will no longer be shared with your mapped facilities. You will need to do a reconciliation for each facility.',
          we_purchase_this_item_availability:
            "'%{field}'  is only available for products marked as 'We Purchase This Item'. The value you define won't be saved for products without option 'We Purchase This Item'"
        },
        generalInformationTitle: 'General Information',
        inherit_pricing_class: 'Inherit Pricing Class Updates',
        inventorySettingsTitle: 'Inventory Settings',
        leaflyAvailabilityTitle: 'Leafly Availability',
        modificationDate: 'Modification Date',
        modifyProducts: 'Modify Product(s)',
        potencyInformationTitle: 'Potency Information',
        preview: 'Preview',
        previewTitle: 'Preview Page',
        pricingBlockTitle: 'Select under what level you want to search for Pricing Group/Class:',
        productInformationTitle: 'Product Information',
        productListing: {
          addProducts: 'Add Product(s)',
          category: 'Category',
          modalTitle: 'Add Products to Update List',
          name: 'Product Name',
          pricingClass: 'Pricing Class',
          pricingGroup: 'Pricing Group',
          productNumber: 'Item Number',
          remove: 'Remove',
          subCategoryFilter: 'SubCategory',
          subcategory: 'Sub-Category',
          uom: 'UOM'
        },
        productStatus: 'Product Status',
        purchasingInformationTitle: 'Purchasing Information',
        return: 'Return',
        salesInformationTitle: 'Sales Information',
        save: 'Save',
        searchByFacility: 'Facility',
        searchByOrg: 'Organization',
        shareProductWithWeedmaps: 'Share product with Weedmaps',
        statuses: {
          active: 'Active',
          inactive: 'Inactive'
        },
        subTitle: 'Edit Product Information',
        tagsActions: {
          add: 'Add to existing',
          clear: 'Clear all'
        },
        title: 'Product Information Modification',
        weedmapsAvailabilityTitle: 'Weedmaps Availability'
      },
      modify: {
        failed: 'Product update failed!',
        incorrectSubCat: 'Item %{name} %{item_number} has the incorrect subcategory. Please change it to %{subcat} or change the name.',
        success: 'Product updated successfully!',
        title: 'Modify Products'
      },
      productHistory: {
        productDetails: 'Product Details'
      },
      quickMove: 'Quick Move',
      table: {
        activate: 'Activate',
        active: 'Change Status',
        category: 'Category',
        currentLevel: 'Current Level',
        dateCreated: 'Created',
        displayName: 'Display Name',
        edit: 'Edit',
        id: 'ID',
        image: 'Image',
        inactivate: 'Inactivate',
        itemNumber: 'Item Number',
        masterId: 'Master ID',
        name: 'Product Name',
        pricingClasses: 'Pricing Classes',
        pricingGroups: 'Pricing Groups',
        prodBatch: 'Batch',
        productListing: 'Product Listing',
        startDate: 'Start Date',
        storageLocation: 'Location',
        strain: 'Strain',
        subCategory: 'Sub-Category',
        testResults: 'Test Results',
        trackingNumber: 'Tracking Number',
        type: 'Type',
        unitOfMeasure: 'UOM',
        uom: 'UOM',
        userId: 'User ID',
        vendor: 'Vendor',
        weight: 'Weight'
      },
      typeOptions: {
        title: 'What are you trying to build?'
      },
      validation: {
        category: 'Select category',
        defaultUom: 'UOM',
        name: 'Enter name',
        pricingType: 'Pricing Type',
        productConfigIncorrect:
          'The Product configuration is not correct. Please deactivate it [%{item_number}] and create new one.',
        strain: 'Select strain',
        strainName: 'Strain Name',
        subcategory: 'Select subcategory',
        uom: 'Select unit'
      }
    },
    purchaseOrders: {
      activate: 'ACTIVATE P.O.',
      arrivalTime: 'Arrival Time',
      arrivalTimeError: 'Arrival time must be set after the departure time',
      cancel: 'CANCEL P.O.',
      connectActions: {
        acknowledgeDecline: {
          cancelText: 'Cancel',
          description: 'Your connected partner declined the Purchase Order.',
          okayText: 'Acknowledge',
          title: 'Request Declined'
        }
      },
      connect_actions: {
        declined: 'Declined'
      },
      create: {
        fail: 'Failed to Create Purchase Order',
        success: 'Successfully Created Purchase Order',
        title: 'Create Purchase Order'
      },
      createOrder: 'Create Purchase Order',
      departureTime: 'Departure Time',
      destination: 'Destination',
      driver: 'Driver',
      edit: 'EDIT P.O.',
      fetch: {
        childItemMasters: {
          failed: 'Failed'
        }
      },
      form: {
        accept: 'Accept',
        addItem: 'Add Another Item',
        amend: 'Amend',
        amount: 'Amount',
        amountError: 'Amount of payment and balance should be greater than zero',
        approxPricePer: 'Approx Price Per %{label}',
        approxPricePerGram: 'Approx Price Per Gram',
        balance: 'Balance',
        calculateTotal: 'Calculated Total',
        changeDue: 'Change Due',
        completeTransfer: 'Complete Transfer',
        containerType: 'Container Type',
        dateCreated: 'Date Created',
        dateExpected: 'Date Expected',
        dateOrdered: 'Date Ordered',
        decline: 'Decline',
        driver: 'Driver Information',
        driverError: 'Select Driver',
        duplicateProduct: 'A product may only be selected once.',
        expirationDate: 'Expiration Date',
        failed: 'Failed',
        grams: 'Gram(s)',
        id: 'Id',
        inactiveItemMasterError:
          'Item %{item_master} has been inactivated and cannot be purchased. Please remove it from the order if you want to proceed.',
        initialInventoryCreation: 'Initial Inventory Creation',
        item: 'Item',
        itemPrice: 'Total Line Item Price',
        medicated: 'Medicated',
        medicatedStatus: 'Sales Order Type',
        mustBeGreaterThanZero: '%{fieldName} must be greater than 0.',
        myNote: 'My Note',
        noPartnerFacilityTypeAssigned: 'This partner facility does not have a valid Partner Facility Type assigned.',
        noPartnerTypeAssigned: 'This partner facility does not have a valid Partner Type assigned.',
        noProductsNoPartnerProducts: 'The selected partner is not associated with any products for purchasing.',
        noProductsNoPartnerSelected: 'Select a partner to load their product list.',
        nonMedicated: 'Non-Medicated',
        noteFromMyPartner: 'Note from My Partner',
        notePlaceholder: 'Enter Note',
        noteToMyPartner: 'Note to My Partner',
        notes: 'Notes',
        numContainer: '# Containers',
        numContainers: 'Num Containers',
        numUnits: 'Total # of Units',
        orderItems: 'Order Items',
        orderPlaced: 'Order Placed',
        orderTotal: 'Order Total',
        partner: 'Partner',
        partnerDiscount: 'Partner Discount',
        partnerError: 'Select Vendor',
        partnerFacility: 'Partner Facility',
        partnerFacilityType: 'Partner Facility Type',
        partnerHasMedicatedAndNonMedicated:
          'The selected partner has medicated and non-medicated products. Please select a Sales Order Type. Medicated and non-medicated products are not allowed in the same PO.',
        partnerInvoice: 'Vendor Invoice Number',
        partnerInvoiceError: 'Enter Vendor Invoice Number',
        partnerInvoicePlaceholder: 'ex. 757929287367438290',
        partnerType: 'Partner Type',
        paymentDate: 'Payment Date',
        paymentDateError: "Payment Date can't be earlier than Date Ordered",
        paymentInfo: 'Payment Information',
        paymentPopulated: 'Payment line(s) should be populated entirely if at least one attribute is indicated',
        paymentType: 'Payment Type',
        poNumber: 'P.O. Number',
        poTitle: 'P.O. Title',
        pricePer: 'Price Per %{label}',
        pricePerGram: 'Price Per Gram',
        pricePerUnit: 'Price Per Unit',
        purchaseOrder: 'Purchase Order',
        qty: 'Quantity',
        requestedAmount: 'Requested Amount',
        reserveSalesQuantity: 'Reserve Sales Quantity?',
        return: 'Return',
        saveAndReceiveTransfer: 'Save And Receive Transfer',
        selectRegister: 'Select Register',
        sentGross: 'Sent Gross',
        sentNet: 'Sent Net',
        sentTotal: 'Sent Total',
        status: 'Status',
        storageLocation: 'Expected Storage Location',
        strainError: 'Your facility does not have the strain selected for this Item',
        tax: 'Tax',
        terms: 'Terms',
        timeExpected: 'Time Expected',
        total: 'Total',
        totalWeightOrdered: 'Total Weight Ordered',
        transferExists: 'Transfer Exists.  Original quantities shown for reference.',
        transferFee: 'Transfer Fee',
        unitCost: 'Cost per Unit',
        unitDiscount: 'Unit Discount',
        unitPrice: 'Unit Price',
        unitsOfMeasurement: 'UOM',
        unitsOrdered: 'Units Ordered',
        unitsTransfered: 'Units Transferred'
      },
      get: {
        loadingOrder: 'Loading Purchase Order…'
      },
      getListing: {
        failed: 'Failed to get purchase orders'
      },
      header: 'Purchase Orders',
      invoiceNumber: 'Invoice #',
      invoiceNumberModal: {
        body:
          'If this is being created for a return receipt, please fill in the Vendor Invoice Number field with reference to the original Transfer.',
        title: 'Missing Invoice Number'
      },
      leafIRMedicatedMessage: 'Purchase Order of medicated products can be processed via Supply Chain Mapping flow',
      leafPaInitialInventoryCreationWarning:
        'Inventory Receipt cannot be created for a Purchase Order after initial inventory creation window.',
      manifestNumber: 'Manifest #',
      modify: {
        fail: 'Failed to Modify Purchase Order',
        success: 'Successfully Modified Purchase Order',
        title: 'Modify Purchase Order'
      },
      noData: 'Nothing to display',
      orderTotal: 'Order Total',
      packagesCount: '# of Packages',
      printInvoice: 'Print Invoice',
      printManifest: 'Print Manifest',
      purchaseOrderHistory: 'Purchase Order History',
      save: {
        savingOrder: 'Saving Purchase Order…'
      },
      statuses: {
        cancelled: 'Cancelled',
        completed: 'Completed',
        ordered: 'Ordered',
        partial: 'Partial'
      },
      table: {
        connectStatus: 'Connect Status',
        dateExpected: 'Date Expected',
        dateOrdered: 'Date Ordered',
        hasInactiveItemMasters:
          'You have been redirected to the Purchase Order as it contains inactivated item(s). Please remove them from the order if you want to proceed.',
        numberOfPackages: '# of Packages',
        orderTotal: 'Order Total',
        orderedBy: 'Ordered By',
        partner: 'Partner',
        partnerContact: 'Partner Contact',
        partnerInvoice: 'Partner Invoice #',
        partnerSalesOrder: 'Partner S.O. #',
        paymentStatus: 'Payment Status',
        poTitle: 'P.O. Title',
        purchaseOrder: 'Purchase Order #',
        quotedDelivery: 'Quoted Delivery',
        received: 'Received'
      },
      validateIRExists:
        'Inventory Receipt was created already. Please review Inventory Receipt #%{ir_id}. If existing Inventory receipt was created mistakenly, you can cancel it and create a new one',
      viewActiveOrders: 'View Active Purchase Orders',
      viewActiveReceipts: 'View Active Inventory Receipts',
      viewInactive: 'View In-Active Purchase Orders',
      viewInactiveOrders: 'View In-Active Purchase Orders',
      viewInactiveReceipts: 'View In-Active Inventory Receipts',
      viewMedicatedOrders: 'View Medicated Purchase Orders',
      viewNonMedicatedOrders: 'View Non-Medicated Purchase Orders'
    },
    receipts: {
      confirmationModal: {
        body:
          'Submission of this return receipt will reduce your tax due for this period, this data will be sent to the PA Department of Revenue. Would you like to continue?',
        title: 'Return Receipt'
      },
      create: {
        fail: 'Failed to Create Inventory Receipt',
        success: 'Successfully Created Inventory Receipt'
      },
      modify: {
        failed: 'Failed',
        success: 'Success'
      },
      orderedQuantityNotEqualFulfilledQuantity:
        '%{orderedQuantityField} and %{fulfilledQuantityField} are not equal.  To update the original purchase order, edit %{orderedQuantityField}.',
      validationErrors: {
        duplicateBatch: 'Each Batch value must be unique',
        facility: 'This package ID has already been used and must be unique.',
        itemMaster: 'This package ID has already been used and must be unique.',
        lotNumber: 'This package ID has already been used and must be unique.',
        multipleLotNumbers:
          'This package id is in use on more than one batch.  Packages are restricted to a single batch.  Currently assigned to: ',
        multipleProducts:
          'This package id is in use on more than one product.  Packages are restricted to a single product.  Currently assigned to: '
      }
    },
    reconciliation: {
      actions: {
        adjust: 'Adjust',
        adjustAll: 'Adjust All',
        exitPage: 'Exit Page',
        reset: 'Reset',
        zeroQuantityHint: 'Reset Zero Quantities from Inactive Inventory.'
      },
      adjust: {
        failed: 'Failed to adjust items',
        success: 'Successfully adjusted items'
      },
      adjustmentReason: 'Adjustment Reason',
      discrepancy: 'Discrepancy',
      form: {
        biotrackReason: 'Biotrack Reason',
        discrepancy: 'Discrepancy',
        itemName: 'Item Name',
        metrcReason: 'METRC Reason',
        packageId: 'Package ID/Unit ID',
        quantity: 'Quantity',
        storageLocation: 'Storage Location',
        systemTotal: 'System Total',
        unitSize: 'Unit Size',
        vendor: 'Vendor'
      },
      header: 'Reconciliation',
      integratedAdjustmentReason: 'Integrated Adjustment Reason',
      itemName: 'Item Name',
      load: 'Loading',
      packageIdUnitId: 'Package Id Unit Id',
      quantity: 'Quantity',
      receivedBy: 'Received By',
      save: 'Saving',
      storageLocation: 'Storage Location',
      total: 'Total',
      unitSize: 'Unit Size',
      uom: 'Uom',
      vendorName: 'Vendor Name',
      warning: {
        message:
          "Adjusted quantity is less than quantity reserved. Adjusting quantity to '0' inactivates the inventory - reservations need to be cleared manually",
        title: 'Adjusting Quantity Alert'
      }
    },
    referralSources: {
      create: {
        failed: 'Failed to create referral source'
      }
    },
    registers: {
      actions: {
        createRegister: 'Create Register',
        modifyRegister: 'Modify Register',
        reactivate: 'Reactivate Register',
        transferCash: 'Transfer Cash',
        viewHistory: 'View History'
      },
      create: {
        fail: 'Failed to create register.',
        success: 'Register was successfully created.',
        title: 'Create Register'
      },
      createTransaction: {
        fail: 'Failed to create transaction.',
        success: 'Transaction was successfully created.'
      },
      form: {
        accountsPayable: 'Accounts Payable',
        accountsPayableReceivable: 'Accounts Payable/Receivable',
        accountsReceivable: 'Accounts Receivable',
        adjustBalance: 'Adjust Balance',
        alertAbove: 'Alert above',
        alertBelow: 'Alert below',
        cashDrop: 'Cash Drop',
        collectsFrom: 'This Register Collects from',
        enterName: 'Enter a name.',
        enterQuantity: 'Enter a quantity.',
        initialBalance: 'Initial Balance',
        miscount: 'Miscount',
        notes: 'Notes',
        payee: 'Payee',
        quantity: 'Quantity',
        reason: 'Reason',
        reconciliation: 'Reconciliation',
        registerFor: 'This register is for',
        registerName: 'Register Name',
        salesTransactions: 'Sales Transactions',
        save: 'Save',
        selectReason: 'Select a reason.',
        selectStatus: 'Select a status.',
        selectType: 'Select a type.',
        status: 'Status',
        vault: 'Vault'
      },
      get: {
        failed: 'Failed'
      },
      getRegister: {
        fail: 'Fail'
      },
      getRegisters: {
        ap_ar_inactive: 'Accounts Payable and Accounts Receivable Register Inactive.',
        fail: 'Failed to get registers.'
      },
      getTransactions: {
        fail: 'Failed to get transactions.'
      },
      history: {
        title: '%{register} History'
      },
      modify: {
        fail: 'Failed to modify register.',
        success: 'Register was successfully modified.',
        title: 'Modify Register'
      },
      nav: {
        active: 'Active',
        inactive: 'Inactive'
      },
      rangeErrors: {
        full: 'Range is %{min} to %{max}.',
        lower: 'Minimum Value is %{min}.',
        upper: 'Maximum Value is %{max}.'
      },
      table: {
        accountsPayableReceivable: 'AP/AR',
        action: 'Action',
        alertAbove: 'Alert Above',
        alertBelow: 'Alert Below',
        amount: 'Amount',
        balance: 'Balance',
        collectsFrom: 'Collects From',
        credit: 'Credit',
        date: 'Date',
        debit: 'Debit',
        id: 'ID',
        manual: 'Manual',
        newLevel: 'New Level',
        notes: 'Notes',
        reason: 'Reason',
        registerName: 'Register Name',
        sale: 'Sale',
        salesTransactions: 'Sales',
        transfer: 'Transfer',
        type: 'Type',
        user: 'User',
        vault: 'Vault'
      },
      title: 'Register Listing'
    },
    reservations: {
      cancel: 'Cancel',
      clear: 'Clear',
      clearAll: 'Clear All',
      clearFail: 'Failed to cancel reservations',
      clearInventory: 'Clear Inventory',
      clearSuccess: 'Successfully canceled reservations',
      confirm: 'Confirm',
      confirmingClearAllFacilityReservations:
        'Confirming this action will clear all reservations for all products and items for this facility.',
      confirmingClearAllReservations: 'Confirming this action will clear all reservations for this product.',
      confirmingClearReservations: 'Confirming this action will clear all reservations for this item.',
      confirms: {
        releaseAllForPackage: 'Confirming the action will clear all package reservations',
        releaseAllForPackageId:
          'You are about to release all reservations for the package %{package_id}.  Please confirm this action.',
        releaseAllForProduct: 'Confirming the action will clear all product reservations',
        releaseOneForPackageId: 'You are about to release a single reservation.  Please confirm this action.',
        releaseOneForProduct: 'Confirming the action will clear selected reservation.'
      },
      failed: 'Failed to get reservations',
      labels: {
        hardReservations: 'Active Package Reservations',
        packageTitle: 'Package Reservations For',
        productTitle: 'Product Reservations For',
        releaseAllPackages: 'Release All Package Reservations',
        releaseAllProducts: 'Release All Product Reservations',
        softReservations: 'Active Product Reservations'
      },
      metaDataLabels: {
        batch_id: 'Batch ID',
        item_master_name: 'Product Name',
        location_name: 'Location Name',
        package_id: 'Package ID',
        reservations_count: 'Reservations Count'
      },
      noReservations: 'None',
      packageModalTitle: 'Reservations For: %{code} ',
      tableColumns: {
        batch_id: 'Batch ID',
        created_at: 'Reserved On',
        entity_id: 'Internal ID',
        entity_name: 'Reserved By',
        entity_type: 'Source',
        id: 'Reservation ID',
        location: 'Location',
        package_code: 'Batch ID',
        package_id: 'Package ID',
        pleaseReleaseMe: 'Release',
        prepackWeight: 'Prepack Weight',
        prepack_weight_id: 'Prepack Weight Id',
        qty: 'QTY Reserved',
        qty_base: 'Quantity',
        quantity: 'QTY Reserved',
        reserved_at: 'Reserved On',
        reserved_by: 'Source',
        reserved_by_id: 'Source ID'
      }
    },
    reset: 'Reset',
    retail: {
      autoPrice: {
        allowRetailLocations: 'Allow retail locations to use auto-price',
        allowRetailLocationsDescr: '(Auto-price allows users to give a break for bulk purchases)',
        allowRetailLocationsDescription: '(Auto-price allows users to give a price break for bulk purchases)',
        calculatedOnSameSubCategory: 'Auto-price should be calculated on same sub-category',
        calculatedOnSameSubCategoryDescr: '(Except for Flower)',
        calculatedTotalWeight: 'Auto-price should calculate total weight regardless of category',
        failed: 'Failed to update settings',
        limitAutoPrice: 'Limit auto-price to entered pricing weights',
        limitingToPricingGroup: 'Auto-price should be limited to Pricing Groups',
        limitingToPricingGroupDescr:
          '(if no, look to the total purchased amount of flower and give the bulk purchase discount)',
        limitingToPricingGroupDescription: '(if no, look to the category total and give the bulk purchase discount)',
        success: 'Settings updated successfully',
        title: 'Auto Price'
      },
      closingReport: {
        beginningBalance: 'Beginning Register Balance',
        cash: 'Cash',
        check: 'Check',
        closeTill: 'Close Till',
        credit: 'Credit',
        date: 'Date',
        debit: 'Debit',
        endBalance: 'End of Day Balances',
        form: {
          closeTill: 'Close Till'
        },
        getReport: {
          failed: 'Failed to get Report',
          success: 'Report received successfully'
        },
        gift_card: 'Gift Cards',
        manualAdjustments: 'Manual Adjustments',
        noRegistersCreated: 'No registers found, please create one',
        receivedPayments: 'Received Payments',
        refundedPayments: 'Refunded Payments',
        rewards: 'Rewards',
        title: 'Closing Report',
        total_payments: 'Totals',
        totals: 'Totals',
        verifyCount: 'Verify Count'
      },
      closingTill: {
        date: 'Date',
        denomination: 'Denomination',
        discrepancy: 'Discrepancy',
        employeeCount: 'Employee Count',
        error: 'Enter the number of coins or bills, not the dollar amount',
        failPost: 'Closing Till failed',
        salesTotal: 'Reported Sales Total',
        successPost: 'Till closed successfully',
        title: 'Till Reconciliation',
        verification: 'Verification',
        verifyTotal: 'Verify Total'
      },
      complianceSettings: {
        block: 'Block',
        blockAt: 'Block at',
        fail: 'Fail to apply settings',
        grams: 'grams.',
        gramsOnHand: 'grams on-hand.',
        gramsOnHandCoupled: 'Warn if facility and its coupled cultivation facility have',
        gramsOnHandFacility: 'Warn if facility has',
        ifProductIsPlaced: 'If product is placed on-hold, ',
        includeInfused: 'Include infused products in on-hand',
        onHand: {
          errorCoupledFacility:
            'Unable to receive transfer. The combined on-hand weight is %{current} grams for your coupled facilities. The maximum allowed amount for this them is %{allowed} grams',
          errorFacility:
            'Unable to receive transfer. Current facility on-hand weight is %{current} grams. The maximum allowed amount for this facility is %{allowed} grams',
          errorOrganization:
            'Unable to receive transfer. Current organization on-hand weight is %{current} grams. The maximum allowed amount for this organization is %{allowed} grams',
          warningCoupledFacility:
            'Current on-hand weight is %{current} grams for your coupled facilities. Consider reducing it below %{allowed} grams.',
          warningFacility:
            'Current on-hand facility weight is %{current} grams. Consider reducing it below %{allowed} grams.',
          warningOrganization:
            'Current on-hand organization weight is %{current} grams. Consider reducing it below %{allowed} grams.'
        },
        onHandInventorySettings: 'On-Hand Inventory Settings',
        patientComplianceError:
          'Unable to create more patients. Current number is %{patients}. The maximum allowed is %{max_block}',
        patientComplianceWarning:
          'Current number of patients has exceeded the configured patient limit threshold (%{patients}/%{max_warn})',
        plantComplianceError:
          'Unable to create more plants. Current number is %{plants}.The maximum allowed in plants compliance number is %{setting}',
        plantComplianceTypeError:
          'Unable to create more %{type} plants. Current number is %{plants}.The maximum allowed in plants compliance number is %{setting}',
        plantComplianceWarning:
          'Current number of %{type} plants is %{plants}. The maximum allowed %{type} plants is %{setting}',
        plantsError:
          'Unable to create more plants.Current number is %{plants}.The maximum allowed number is %{setting}',
        plantsWarning: 'Current patient on-hand plants is %{plants}. The maximum allowed number is %{setting}',
        productRequireExpiration: 'On-hand product require an expiration date',
        sales: 'sales until off hold.',
        success: 'Settings applied successfully',
        title: 'Inventory Compliance Settings',
        warn: 'Warn'
      },
      couponSetup: {
        allowCoupon: 'Allow manufacturer coupons to be created',
        allowLine: 'Allow line item discounting',
        createCoupons: 'Create coupons that require management authorization',
        title: 'Coupons Setup'
      },
      coupons: {
        actions: {
          activate: 'Activate',
          create: 'Create Coupon',
          inactivate: 'Inactivate',
          modify: 'Modify Coupon',
          print: 'Print Name & Code',
          view: 'View'
        },
        get: {
          failed: 'Failed to get Coupons'
        },
        inactivate: {
          failed: 'Failed to inactivate coupon',
          success: 'Successfully inactivated coupon'
        },
        modify: {
          failed: 'Failed to modify coupon',
          getCouponFailed: 'Failed to get Coupon',
          success: 'Successfully modified coupon',
          title: 'Modify Coupon'
        },
        nav: {
          active: 'Active',
          inactive: 'Inactive'
        },
        table: {
          amount: 'Coupon Amount',
          category: 'Category',
          code: 'Coupon Code',
          expirationDate: 'Expiration Date',
          groups: 'Groups',
          name: 'Coupon Name',
          noLimit: 'no limit',
          products: 'Products',
          redemption: 'Number of Redemption',
          type: 'Coupon Type',
          vendor: 'Vendor'
        },
        title: 'Coupons'
      },
      createCoupon: {
        amount: 'Coupon Amount',
        and: 'and',
        applicableItemCount: 'Applicable Item Count',
        applicableItemCountWrong: 'Applicable Item Count field should be an integer',
        applyToBasePrice: 'This Coupon should apply to the base price/group price',
        between: 'Between',
        code: 'Coupon Code',
        countInReporting: 'This coupon should count in my discount percentage reporting',
        couponLimits: 'Coupon Limits',
        create: {
          fail: 'Fail',
          failed: 'Failed to create coupon',
          success: 'Successfully created coupon'
        },
        date: 'Expiration Date',
        discountApplication: 'Discount Application',
        discountType: 'Discount Type',
        effectiveDate: 'Effective Date',
        expirationDateShouldBeAfterEffectiveDate: 'Expiration date should be after Effective date',
        facilities: 'Applicable Facilities',
        ifAmountDiscount: 'If dollar amount discount',
        ifPercentageDiscount: 'If percentage discount',
        lineItemTotal: 'or make the line item total',
        maxPercentage: 'Percentage should not exceed 100%',
        maxRedemptionsCoupon: 'Maximum number of redemptions per coupon',
        maxRedemptionsPatient: 'Maximum number of redemptions per patient',
        maxRedemptionsText: '(Enter maximum number of times this coupon may be used, 0 for unlimited)',
        minItemPrice: 'Minimum line item price with coupon',
        minItemPriceText:
          '(A Minimum price of the line item after the coupon is applied. If the field is greater than the line item, the price will be raised to match the value)',
        minOrderLimit: 'Minimum Order limit',
        minOrderLimitText: '(A Minimum order total that applies to the coupon, 0 for no limit)',
        name: 'Coupon Name',
        retailCoupon: 'Retail Coupon',
        selectedCategories: 'This coupon is for the selected categories',
        selectedGroup: 'This coupon is for the selected group(s)',
        selectedProduct: 'This coupon is for the selected product',
        selectedVendor: 'This coupon is for the selected vendor',
        title: 'Retail: Create Coupon',
        type: 'Coupon Type',
        valid: 'Coupon valid on the following day(s)'
      },
      createCustomerGroups: {
        addNew: 'Add New Customer Group',
        assign_new_reward_group: 'Assign this reward group to existing qualified patients?',
        failed: 'Failed to create customer group(s)',
        groupName: 'Group Name',
        is_rewards_program_group: 'Is a rewards group?',
        min_accrued_points: 'Minimum Lifetime Points to Qualify',
        min_total_spend: 'Minimum Lifetime Spend to Qualify',
        min_visit_frequency: 'Number of Visits',
        min_visit_frequency_days: 'Number of Days',
        new_reward_group_title: 'Assign reward group',
        rewardsGroupsHint: 'If a rewards group, at least one of the following value must be greater than zero.',
        rewardsGroupsNote:
          'Rewards groups are automatically assigned and removed based on customer activity. A customer will only have a single rewards group assigned and it will always be the maximum to which they are entitled. Rewards upgrade assignments are performed at the completion of an order.',
        standard: 'Standard % of Base Price',
        success: 'Successfully created customer group(s)',
        title: 'Create Customer Group(s)'
      },
      customerGroupListing: {
        activate: {
          failed: 'Failed to activate Customer Group',
          success: 'Successfully activated Customer Group',
          title: 'Activate'
        },
        active: 'Active',
        create: 'Create Customer Group',
        delete: 'Delete Group',
        deleteGroupFailed: 'Failed To Delete Customer Group',
        deleteGroupSuccess: 'Successfully Deleted Pricing Group',
        edit: 'Edit Group',
        error: 'Field should be entered as a percentage',
        groupName: 'Customer Group Name',
        inactivate: {
          failed: 'Failed to inactivate Customer Group',
          success: 'Successfully inactivated Customer Group',
          title: 'Inactivate'
        },
        inactive: 'Inactive',
        isBonusGroup: 'Is Bonus Group',
        loadGroupsFailed: 'Failed To Load Customer Groups',
        loading: 'Loading Customers Groups',
        pointsToQualify: 'Points To Qualify',
        spendingToQualify: 'Spending To Qualify',
        standard: 'Standard % of Base Price',
        title: 'Customer Group Listing',
        type: 'Type',
        typeReward: 'Reward',
        typeStandard: 'Standard',
        visitFrequencyToQualify: 'Visit Frequency To Qualify'
      },
      manageClosing: {
        title: 'Manage Closing'
      },
      minimumDaysSupply: {
        category: 'Category',
        concentrate: 'Concentrate',
        edible: 'Edible',
        loadingSettings: 'Loading Minimum Days Supply…',
        minimumWholeDayUnit: 'Minimum Whole-Day Unit',
        nonTerminalPatient: 'Non Terminal Patient',
        plant_material_tier_1: 'Plant Material Tier I',
        plant_material_tier_2: 'Plant Material Tier II',
        savingSettings: 'Saving Minimum Days Supply…',
        successfullySaved: "You've successfully updated the Minimum Days Supply Settings.",
        terminalPatient: 'Terminal Patient',
        title: 'Minimum Days Supply',
        topical: 'Topical',
        uom: 'UOM',
        validSetting: 'Please enter a valid up to 4 decimal places.'
      },
      modifyCustomerGroups: {
        failed: 'Failed to update customer group',
        success: 'Successfully updated customer group',
        title: 'Modify Customer Group'
      },
      patientSettings: {
        blockSize: 'Block number should be greater than Warn number',
        facilityCount: 'Maximum Patient Count for Facility',
        facilityCoupled: 'This facility is coupled with',
        fail: 'Fail to apply settings',
        header: 'Patient Settings',
        loadSettingsForPlants: 'Fail to load settings',
        maxWarn: 'Warn at',
        maxblock: 'Block at',
        onHandDefault: 'This Facility may possess',
        patientsDefault: 'Patients default to',
        perPatient: 'G per patient',
        plantsFlower: 'plants in flower.',
        plantsTotal: 'total plants per patient.',
        plantsVeg: 'plants in veg.',
        success: 'Successfully applied settings',
        title: 'Patient Compliance Settings'
      },
      paymentTypes: {
        cash: 'Cash',
        check: 'Check',
        credit: 'Credit Card',
        debit: 'Debit Card',
        giftcard: 'Gift Card'
      },
      pricingClass: {
        className: 'Pricing Class',
        create: {
          failed: 'Failed to delete Pricing Class.',
          success: 'Successfully created Pricing Class.',
          title: 'Retail: Create Pricing Discount Class'
        },
        deleteItem: {
          failed: 'Failed to remove Pricing Class',
          success: 'Pricing Class successfully removed'
        },
        facilities: 'Facilities',
        form: {
          className: 'Pricing Class Name',
          createTitle: 'Retail: Create Pricing Class',
          facilities: 'Applicable Facilities',
          nameValidationError: 'Alphanumeric 30-character limit, allow spaces, no special characters.',
          saveAndReturn: 'Save And Return'
        },
        get: {
          failed: 'failed to get pricing groups'
        },
        modify: {
          failed: 'Failed to modify Pricing Class',
          success: 'Successfully modified Pricing Class',
          title: 'Retail: Modify Pricing Class'
        },
        title: 'Pricing Class Listing'
      },
      pricingClasses: {
        form: {
          facilities: 'Facilities'
        },
        get: {
          failed: 'Failed'
        }
      },
      pricingGroup: {
        activate: {
          failed: 'Failed to activate Pricing Group',
          success: 'Successfully activated Pricing Group',
          title: 'Activate'
        },
        active: 'Active',
        category: 'Category',
        create: {
          failed: 'Failed to create pricing group.',
          success: 'Successfully created pricing group.'
        },
        createGroup: 'Create Group',
        deleteItem: {
          failed: 'Failed to remove pricing group',
          success: 'Pricing group removed successfully'
        },
        edit: 'Edit',
        editGroup: 'Edit Group',
        form: {
          addCustomerGroup: 'Add New Customer Group',
          addWeight: 'Add New Weight',
          allowRounding: 'Allow rounding to even dollars',
          basePrice: 'Base Price',
          createTitle: 'Retail: Create Pricing Group(s)',
          customerGroup: 'Select Customer Group',
          customerGroupError: 'Customer Group',
          defaultPriceRequiredError: 'At least one Base Price is Required',
          groupName: 'Pricing Group Name',
          percent: 'Enter % of Base Prices',
          percentError: '% of Base Prices',
          postTax: 'Post-Tax',
          preTax: 'Pre-Tax',
          pricingClass: 'Pricing Class',
          pricingType: 'Build Group based on selected pricing',
          pricingWeight: 'Enter Weight',
          productCategory: 'Applicable Product Category',
          rewardPointAccrual: 'Reward Point Accrual',
          saveAndReturn: 'Save And Return',
          taxProfile: 'Tax Profile'
        },
        get: {
          failed: 'Failed to get pricing groups.'
        },
        groupName: 'Pricing Group Name',
        inactivate: {
          failed: 'Failed to inactivate Pricing Group',
          modalConfirmation:
            "This Pricing Group will be removed from all Products on which it's being used. Would you like to continue?",
          modalTitle: 'Inactivate group: %{modalGroupName}',
          success: 'Successfully inactivated Pricing Group',
          title: 'Inactivate'
        },
        inactive: 'Inactive',
        loadGroupsFailed: 'Load Groups Failed',
        loading: 'Loading Pricing Groups',
        modify: {
          failed: 'Failed to modify pricing group.',
          success: 'Successfully modified pricing group.',
          title: 'Retail: Modify Pricing Group(s).'
        },
        title: 'Pricing Group Listing'
      },
      pricingGroupListing: {
        active: 'Active',
        inactive: 'Inactive'
      },
      receipt: {
        discountPrice: 'Discount Price',
        facility: {
          failed: 'Failed to get facility.'
        },
        failed: 'Failed to get receipt.'
      },
      receiptSettings: {
        include__patient_address: 'Include Patient Address',
        include_caregiver_address: 'Include Caregiver Address',
        include_caregiver_name: 'Include Caregiver Name',
        include_caregiver_state_license: 'Include Caregiver State License',
        include_diagnosis: 'Include Diagnosis',
        include_facility_address_include_country: 'Include Facility Country',
        include_facility_address_include_street_address: 'Include Facility Street Address',
        include_facility_address_on_one_line: 'Include Facility Address On One Line',
        include_facility_include_phone: 'Include Facility Phone',
        include_facility_name: 'Include Facility Name',
        include_footer_text: 'Footer Text',
        include_patient_name: 'Include Patient Name',
        include_patient_state_license: 'Include Patient State License',
        include_physician_name: 'Include Physician Name',
        include_recommendation_if_applicable: 'Include Physician Recommendation If Applicable',
        include_secondary_footer_text: 'Secondary Footer Text',
        panels: {
          header: 'Receipt Header',
          med_info: 'Medical Information',
          patient: 'Patient Information',
          z_extras: 'Additional'
        },
        title: 'Receipt Settings'
      },
      restrictSalesTimePeriods: {
        calendar_month: 'Calendar Month',
        calendar_week: 'Calendar Week',
        day: 'Day',
        month: 'Month',
        visit: 'Visit',
        week: 'Week'
      },
      rewardSetup: {
        accrue: 'Accrue',
        accruePoints: 'Accrue points by category',
        allowEdit: 'Allow manual edit of reward points',
        by: 'by',
        customerGroupsFooterText:
          'Selecting no groups allows rewards to apply to all customers regardless of their customer group.  It is, effectively, the same as selecting all groups.',
        fields: {
          reward_accrue_points_by_category: 'Points are earned in the following product categories',
          reward_accrue_points_on_orders_with_redeemed_rewards: 'Points may be earned on orders paid for with rewards',
          reward_allow_rewards_for_order_type: 'Points are earned n the following order types',
          reward_apply_rewards_as: 'Rewards are applied as',
          reward_apply_rewards_as_hint: "If 'Payment Tender' rewards are applied like cash.",
          reward_eligible_reward_groups: 'Points are earned in the following customer/patient groups',
          reward_include_tax_spent_in_rewards_points_accrued: 'Points earned are calculated including tax',
          reward_one_point_is_worth: 'When points are used as currency, what is a point worth?',
          reward_one_point_is_worth_hint:
            'When enabled points are spent and the ability to create rewards is disabled.',
          reward_points_accrued_per_dollar_rate: 'Points earned per dollar spent',
          reward_record_points_based_on_sales: 'Customers earn points based on sales',
          reward_record_points_based_on_sales_hint: "When 'No' reward points are disabled.",
          reward_remove_points_after_days_between_visits:
            'Points balance is set to zero after how many days between visits?',
          reward_remove_points_after_days_between_visits_hint:
            'Enabling this will cause your customers points to expire!'
        },
        includeTax: 'Include tax spent in rewards points accrued',
        messages: {
          saving: 'Saving Rewards Settings'
        },
        noBonusPointWindowsFound: 'No Bonus Point Windows Found',
        or: 'or',
        patientsAccrue: 'Patients accrue points on order where rewards are used for non-discounted products',
        points: 'points for every',
        recordPoints: 'Record Points based on sales',
        removePoints: 'Remove points after',
        selectGroups: 'Select groups that can accrue reward points',
        spent: 'spent on approved categories',
        title: 'Rewards Setup',
        ui: {
          addPointsBonusWindow: 'Add Day and Time Window',
          eligibility: 'Reward Constraints',
          expandable: 'Reward Multiplier Periods',
          removePointsBonusWindow: 'Remove Day and Time Window',
          reward_application: 'Reward Value And Configuration'
        },
        worthRate: '1 Point is worth'
      },
      rewards: {
        collection: 'Rewards',
        createReward: 'Create Reward',
        dollarAmount: 'Dollar amount',
        ifDollarAmount: 'If dollar amount discount',
        ifPercentageDiscount: 'If percentage discount',
        indicatedItems: 'Indicated Items',
        isForTheSelectedProduct: 'This reward is for the selected product',
        isForTheSelectedVendor: 'This reward is for the selected vendor',
        item: 'Reward',
        limitRedemption: 'Limit redemption to once every',
        limitedToSelectedGroups: 'Reward limited to selected groups',
        limitedToSelectedVendor: 'Limited To Selected Vendor',
        messages: {
          get: {
            failed: 'Failed to get Rewards'
          }
        },
        name: 'Reward Name',
        percentageAmount: 'Percentage Amount',
        pointsRequired: 'Points Required',
        reward: {
          fields: {
            consumer_group_ids: 'Consumer Groups',
            item_master_ids: 'Products',
            name: 'Reward Name',
            points_required: 'Points To Purchase',
            reward_amount: 'Reward Value',
            reward_applies_to: 'Reward / Discount Applies To',
            reward_applies_to_line: 'Line Items',
            reward_applies_to_order: 'Order Product Total',
            reward_type: 'Reward Type',
            reward_type_percent: 'Percent',
            reward_type_value: 'Cash',
            reward_value: 'Reward Value',
            vendor_ids: 'Vendors'
          },
          messages: {
            create: {
              failed: 'Failed to Create Reward',
              success: 'Successfully Created Reward'
            },
            get: {
              failed: 'Failed to get Reward.'
            },
            modify: {
              failed: 'Failed to Modify Reward',
              success: 'Successfully Modified Reward'
            }
          },
          ui: {
            customerGroups: 'Customer Groups',
            definitionTitle: 'Reward Definition',
            pointsToPurchaseHint: 'Setting %{field} to zero will allow this reward to be gifted.',
            productVendors: 'Product Vendors',
            products: 'Products',
            restrictHint: 'Click the boxes below to view and select from their items.',
            restrictTitle: 'Apply restrictions to refine how this reward can be used or awarded.',
            rewardNameHint: 'Name should be short yet descriptive and ideally, unique.'
          }
        },
        rewardAmount: 'Reward Amount',
        rewardCode: 'Reward Code',
        rewardCreated: 'Reward Created',
        singeLineItem: 'Singe Line Item',
        singleLineItem: 'A Single Line Item',
        subtotal: 'The Subtotal',
        title: 'Rewards'
      },
      salesComplianceSettings: {
        failed: 'Failed',
        salesSettingHaveBeenSaved: 'Sales Setting Have Been Saved'
      },
      salesSettings: {
        CARTPENS: 'Cartridges / Pens',
        CLONE: 'Plants: Clone',
        CONCENTRATE: 'Concentrate',
        EA: 'Each',
        EDIBLE: 'Infused Edible',
        GR: 'Grams',
        INFUSED: 'Infused Non Edible',
        MARIJUANA: 'Fresh Marijuana',
        MG: 'Milligrams',
        SEED: 'Plants: Seeds',
        allValuesRequired: 'All equivalency values are required',
        allowAnonymousOrders: 'Allow Anonymous Orders?',
        allowDelivery: 'Allow delivery of product',
        allowExpirationDateSales: 'Allow patients to purchase on the date their Medical Marijuana ID expires?',
        allowExpirationDateSalesDescription:
          "When set to 'Yes', if the patient's MMJ ID expires on 10/23/2018, the patient will be able to purchase _on _ 10/23/2018",
        allowPaperwork: 'Allow sales with temporary paperwork?',
        allowUpload: 'Allow document uploads?',
        allow_expired_dl_for_rec: 'Allow expired driver license for recreational purchases?',
        anonymousCustomerTypes: 'Select Customer Types for Anonymous Orders',
        applyComplianceToInventory: 'Apply compliance rules to quantity removed from inventory.',
        autoExpired: 'Automatically set expired patients inactive?',
        concentrate: 'concentrate',
        edible: 'edible',
        enableReservationsAPI: 'Allow API Integrators to Place Reservations',
        enabled: 'Enabled',
        enabledHint:
          'Categories in equivalency settings that are not enabled will evaluate the equivalency to zero.  In other words, they will not contribute to customer Grams or Equivalent calculations.',
        equivalency: 'Equivalency Settings',
        equivalencyHint:
          'Set your product equivalencies for regulatory purposes.  Generally this is expressed as 1 gram of flower product equals x quantity of a another product type.',
        equivalencySettings: 'Limit Settings',
        errorDecimals: 'Should be up to 2 decimals',
        errorInteger: 'Should be an integer',
        errors: {
          biotrack_limit_exceeded:
            'Consumer sales limit exceeded by %{overage} gram(s) or equivalent. Consumer has %{grams} grams of flower based product or equivalent remaining not including this order.  Limit in force: %{weight_limit_enforced}. ',
          category_limit_exceeded:
            'This action would exceed compliance limits.  Check the Purchase Limits By Category table for details.  The rejected action is shown in red.  Patient limit will reset on %{reset}.',
          failedLoading: 'Sales Limits Settings failed to load.',
          lab_compliance_limit_exceeded:
            'This action would exceed compliance limits.  Check the Purchase Limits By Category table for details.',
          limit_exceeded:
            'Consumer sales limit exceeded by %{overage} gram(s) or equivalent. Consumer has %{grams} grams of flower based product or equivalent remaining not including this order.  Limit in force: %{weight_limit_enforced}.  Patient limit will reset on %{reset}.',
          metrc_mi_limit_exceeded:
            'Consumer %{period} sales limit exceeded by %{exceeded_grams} gram(s) or equivalent. Consumer has Daily limit %{daily_limit} and Monthly limit %{monthly_limit} grams of flower based product or equivalent remaining not including this order.'
        },
        flower: 'Flower',
        flowerEquivalenceStatement: 'Order Quantity in Grams of Flower',
        grams: 'gram(s) = ',
        gramsOfFlower: 'Gram(s) of Flower Product',
        gramsOfPlantMaterialTierI: 'Gram(s) of Plant Material Tier I Product',
        gramsOrEquivalent: 'grams or its equivalent. ',
        inWeightUom: 'In Weight Measure',
        inactive: 'Inactive after',
        limitsReset: 'Reset patient sales limits every: ',
        ofProductType: 'Of Product Type',
        packagingWorkflow: 'Packaging Workflow',
        patients: 'Patients',
        paymentOptions: 'Select your payment options',
        paymentsAccepted: 'Payments Accepted',
        printWithoutPayment: 'Print Retail Labels & Receipts Without Payment',
        priority: 'Priority',
        quantity: 'Quantity',
        recreationalAgeLimit: 'Minimum age restriction for recreational sales',
        requireBirthdateForAnonymousOrders: 'Require Birthdate For Anonymous Orders',
        requireInfo: 'Require physician information for temporary paperwork?',
        restrictSales:
          'Restrict sales to patients without over-rides to how many grams of flower product or its equivalent?',
        rulesHint: 'Rules restrict an order based on the limits specified below by category.',
        sellFIFO: 'Sell products First In - First Out',
        setBuffer: 'Grace period (in days) before expired patients are set inactive.',
        setBufferLabel: 'Grace period',
        standardReceiptFooterText: 'Standard Receipt Footer Text',
        system: 'System',
        terminal: 'Priority',
        title: 'Sales Settings',
        topical: 'topical'
      },
      supplies: {
        actions: {
          edit: 'Edit Level',
          orderSupplies: 'Order Supplies'
        },
        create: {
          addNewSupply: 'Add New Supply',
          addSupplier: 'Add Supplier',
          addSupplies: 'Add Supplies',
          category: 'Supply Category',
          containerSize: 'Container Size',
          costPerUnit: 'Cost Per Unit',
          name: 'Supply Name',
          returnSupplies: 'Save and Return',
          storageLocation: 'Storage Location',
          supplier: 'Supplier',
          uom: 'UOM'
        },
        table: {
          cleaningSupplies: 'Cleaning Supplies',
          containerSize: 'Container Size',
          costUnit: 'Cost/Unit',
          currentLevel: 'Current Level',
          medicalSupplies: 'Medical Supplies',
          sku: 'SKU',
          storageLocation: 'Storage Location',
          supplier: 'Supplier',
          supplyName: 'Supply Name',
          uniforms: 'Uniforms',
          uom: 'UOM'
        },
        title: 'Supplies'
      },
      title: 'Retail: ',
      transactionReport: {
        anonymousConsumer: 'Anonymous',
        columns: {
          cash: 'Cash',
          change: 'Change Given',
          check: 'Check',
          credit: 'Credit',
          csr: 'Sales Associate',
          debit: 'Debit',
          discount: 'Discount Total',
          fulfillmentMethod: 'Fulfillment Method',
          gift_card: 'Gift Card',
          orderDate: 'Order Date/Time',
          orderId: 'Order ID',
          orderType: 'Order Type',
          patientName: 'Patient Name',
          patientType: 'Patient Type',
          register: 'Register',
          rewards: 'Rewards',
          subtotal: 'Order Total',
          tax: 'Tax Total',
          total: 'Total Amount'
        },
        csrsLabel: 'Sales Associates',
        failed: 'Failed to get transactions',
        fulfillmentMethodsLabel: 'Fulfillment Methods',
        invalidReportDate: 'Report Date is invalid',
        orderIdLabel: 'Order ID',
        orderTypes: {
          refunds: 'Refunds',
          sales: 'Sales'
        },
        orderTypesLabel: 'Order Types',
        patientTypes: {
          medical: 'Medical',
          mmic: 'MMIC',
          recreational: 'Recreational'
        },
        patientTypesLabel: 'Patient Types',
        paymentMethodsLabel: 'Payment Methods',
        registersLabel: 'Registers',
        reportDateLabel: 'Report Date',
        title: 'Daily Transactions Report',
        totals: 'Totals',
        transactions: 'Transactions'
      }
    },
    role: {
      create: {
        notUniqueRoleName: 'Please enter unique role name'
      }
    },
    salesOrders: {
      create: {
        fail: 'Failed to create sales order.',
        labTitle: 'Create Sales Order (Lab)',
        success: 'Successfully created sales order.',
        title: 'Create Sales Order',
        wasteDisposalTitle: 'Create Sales Order (Waste Disposal)'
      },
      form: {
        applyDisposalReason: 'Apply Disposal Reason',
        bulkItems: 'Bulk Items',
        cancelOrder: 'Cancel Order',
        cancelTransfer: 'Cancel Transfer',
        delivered: 'Delivered',
        disposalReason: 'Disposal Reason',
        failedToGetInventory: 'Failed to get inventory',
        hasTransfer:
          'Transfer ID: %{transferId} Status: %{status} | Quantities and prices shown below are not editable and are for reference only.',
        inactiveItemMasterError:
          'Item %{item_master} has been inactivated and cannot be sold. Please remove it from the order if you want to proceed.',
        inactivePartner: 'The Partner associated with this Sales Order has been marked as Inactive',
        internal: 'Internal',
        inventoryUnavailable:
          'No Inventory Available.  If you have multiple products on the transfer, move this product to an alternate sales order to proceed.',
        isSample: 'Is Sample',
        loading: 'Loading Sales Order For Modification…',
        openTransfer: 'Go to Transfer ID %{transferId}',
        order: 'Order',
        orderPlaced: 'Order Placed',
        orderStatus: 'Order Status',
        orderTotals: 'Order Totals',
        orderType: 'Order Type',
        orderTypes: {
          medical: 'Medical',
          recreational: 'Recreational'
        },
        paid: 'Paid',
        partialPayment: 'Partial Payment',
        partnerFacilityEmpty: 'You cannot create a Sales Order between these facility types',
        partnerInvoiceNumber: 'Partner Invoice Number',
        partnerName: 'Partner Name',
        partnerTypes: {
          labPartners: 'Lab Partners',
          salesPartners: 'Sales Partners',
          wasteDisposalPartners: 'Waste Disposal Partners'
        },
        paymentsExistMessage: 'You can not cancel a purchase order once a payment has been added',
        prePackItems: 'Pre-Pack Items',
        productSampleType: 'Product Sample Type',
        productSampleTypeError: 'Please select product sample type',
        return: 'Is return',
        sampleType: 'Sample Type',
        sampleTypeError: 'Please select sample type',
        saving: 'Saving Sales Order…',
        selectItemError: 'Select an item',
        status: 'Status',
        transferType: 'Transfer Type',
        unitItems: 'Unit Items',
        wholesale: 'Wholesale'
      },
      getSalesOrder: {
        failed: 'Failed to get sales order.'
      },
      hasInactiveItemMasters:
        'You have been redirected to the Sales Order as it contains inactivated item(s).  Please remove them from the order if you want to proceed.',
      modify: {
        cancelled: 'Successfully cancelled sales order. Linked transfer(s) cancelled too.',
        failed: 'Failed to modify sales order.',
        labTitle: 'Modify Sales Order (Lab)',
        success: 'Successfully modified sales order.',
        title: 'Modify Sales Order',
        wasteDisposalTitle: 'Modify Sales Order (Waste Disposal)'
      },
      noInventory: 'Product has no inventory on hand',
      noInventoryPrePack: 'Product has no inventory on hand for all weight sizes',
      partialInventoryPrePack: 'Product has no inventory for some weight sizes'
    },
    save: 'Save',
    scanInput: {
      modal: {
        scanNow: 'Scan Now',
        swipeNow: 'Swipe Now'
      }
    },
    search: 'Search',
    sections: {
      name: 'Name'
    },
    setDOB: 'Set DOB',
    some: {
      form: {
        selectThirdOption: 'Select Third Option'
      }
    },
    stateIntegrators: {
      apisicpaStates: {
        get: {
          failed: 'Failed'
        }
      },
      biotrackStates: {
        get: {
          failed: 'Failed to get Biotrack states'
        }
      },
      form: {
        apiKey: 'API Key',
        api_token: 'API Token',
        apisicpaConfiguration: 'Apisicpa Configuration',
        biotrackConfiguration: 'Biotrack Configuration',
        client_id: 'Client Id',
        client_secret: 'Client Secret',
        cureConfiguration: 'CureAPI Configuration',
        dispenserId: 'API Security Token',
        fifteenDayWindow: 'Fifteen  Day Window',
        initialLoading: 'Initial Loading',
        leafConfiguration: 'Leaf Configuration',
        licenseNumber: 'License Number',
        license_site_id: 'License Site Id',
        locationNumber: 'Location Number',
        metrcConfiguration: 'Metrc Configuration',
        metrcState: 'METRC State',
        metrcType: 'METRC Facility Type',
        mmeCode: 'MME Code',
        overrideEnvironmentUrl: 'Override Environment Url',
        password: 'Password',
        pushToMetrc: 'Push Sales Export Data to METRC In Real Time',
        quarantineLocation: 'Quarantine Location',
        requirePatientCardLookup: 'Require Patient Card Lookup',
        selectIntegrator: 'Select Integrator',
        selectPlaceholder: 'Select A Facility Type',
        setupMode: 'Setup Mode',
        state: 'State',
        store_id: 'STORE ID',
        subscriberId: 'API User ID',
        trainingMode: 'Training Mode',
        ubi: 'UBI',
        updateMetrcExport: 'Update METRC Export',
        url: 'URL',
        useOracleTestRegistry: "Use Oracle's Test Patient Registry",
        userApiKey: 'User API Key',
        username: 'Username'
      },
      get: {
        failed: 'Failed to get state integrators'
      },
      integrationPage: {
        APITransactions: 'API Transactions',
        salesExport: 'Sales Export',
        taxDocuments: 'Tax Notifications',
        title: 'Integration',
        trackingIds: 'Tracking IDs'
      },
      leafPaPurchasedAmounts: {
        get: {
          failed: 'Failed to get purchased amounts.'
        },
        noCategory: 'No Category Listed',
        title: 'Purchased Amounts'
      },
      leafStates: {
        get: {
          failed: 'Failed to get states'
        }
      },
      metrcMd: {
        patientIdInvalid: 'External Limits Error.  This may be due to an Invalid METRC patient license number.',
        patientUnavailable: 'Patient ID is Invalid or Currently Unavailable',
        serviceUnavailable: 'Notice: External Limits Service Unavailable'
      },
      metrcStates: {
        get: {
          failed: 'Failed to get METRC states'
        }
      },
      salesExport: {
        CaregiverNumber: 'Caregiver Number',
        PatientLicenseNumber: 'Patient License Number',
        caregiverNumber: 'Caregiver Number',
        clickUpdateButton:
          'Please click the Update button to review the data before sending the data to the State system.',
        customerNumber: 'Customer Number',
        exportToCsv: 'Export to CSV',
        getData: {
          failed: 'Failed to get Sales Export data'
        },
        orderType: 'Order Type',
        packageLabel: 'Package Label',
        quantity: 'Quantity',
        receiptNumber: 'Receipt Number',
        reviewPackage: 'Please review the changes above before sending to the State system.',
        salesCustomerType: 'Customer Type',
        salesDateTime: 'Sales Date/Time',
        sendExport: {
          failed: 'Failed to send export to state system',
          success: 'Successfully sent export to state system'
        },
        sendExportToStateSytem: 'Send Export to State System',
        title: 'Sales Export',
        totalAmount: 'Total Amount',
        uom: 'UOM'
      },
      settings: {
        get: {
          failed: 'Failed to get state integrator settings'
        },
        modify: {
          delayNotice: 'Changes made in MJ Platform may take several minutes to appear in your integrated system.',
          failed: 'Failed to modify integrator settings',
          success: 'State integrator settings modified successfully'
        }
      }
    },
    strains: {
      addToInventory: 'Add to inventory',
      cloneMortalityRate: 'Clone Mortality Rate',
      clones: 'Clones',
      dominance: 'Dominance',
      edit: {
        addNewStrain: 'Add New Strain',
        averageYield: 'Average Yield Per Plant',
        days: 'Days',
        desiredWeightPerWeek: 'Desired Weight Per Week',
        ea: 'EA',
        fail: 'Server error: Strain update failed',
        genetics: 'Genetics',
        grams: 'G',
        growthCycleInfo: 'Growth Cycle Information',
        header: 'Edit Strains',
        strain: 'Strain',
        success: 'Strain was updated.'
      },
      facility: {
        strainsTitle: 'Select Strains for Facility'
      },
      flower: 'Flower',
      flowerCycle: 'Flower Cycle',
      form: {
        abbreviation: 'Abbreviation',
        addPhenotype: 'Add Phenotype',
        phenotype: 'Phenotype'
      },
      get: {
        failed: 'Failed to get strains'
      },
      getStrain: {
        failed: 'Failed to get strain.'
      },
      listing: {
        addForFacility: 'Add for Facility',
        addForOrganization: 'Add for Organization',
        addNewStrain: 'Add New Strain',
        editContent: 'Edit Content',
        editStrain: 'Edit Strain',
        manageStrain: 'Manage Strain',
        strainName: 'Strain Name',
        title: 'Strain Listing'
      },
      mother: 'Mother',
      organization: {
        createNew: 'Create New',
        createNewStrain: 'Create New Strain',
        emptyFieldErorr: 'Strain Field is Empty',
        strainInputLabel: 'Strain Name',
        strainModalTitle: 'Create Strains',
        strainsTitle: 'Select Strains for Organization'
      },
      pollenDonor: 'Pollen Donor',
      propCycle: 'Prop Cycle',
      propagationCycle: 'Propagation Cycle',
      searchAlphabetically: 'Search Alphabetically',
      searchByName: 'Search Strains by Name',
      searchPlaceholder: 'Search…',
      selectHint: 'Select strains that you would like to add to your inventory list.',
      showAll: '…show all',
      strain: 'Strain',
      strainCode: 'Strain Code',
      strainValue: 'Average Market Value Per Plant',
      strainValuePerGram: 'Average Market Value Per Gram',
      strainsTitle: 'Strains Title',
      totalPlantLifecycle: 'Total Plant Lifecycle',
      trackingId: 'Tracking Number',
      trim: 'Trim',
      vegetativeCycle: 'Vegetative Cycle',
      wholePlant: 'Whole Plant (Wet)'
    },
    supplyChain: {
      cancelHint: 'This %{sourceType} has a matching auto-created %{targetType} with your partner.',
      cancelWarning: 'Canceling the order here will not cancel the corresponding %{targetType}.',
      createMatchingDone: 'A matching partner %{targetType} has already been created!',
      createMatchingOrder:
        'Because your facility and the partner facility for this %{sourceType} are linked, you can select to create the matching %{targetType} in the partner facility.',
      createMatchingPrompt: 'Create the matching partner',
      inventoryReceipts: 'Inventory Receipts',
      purchaseOrder: 'Purchase Order',
      purchaseOrderAction: 'purchase from',
      salesOrder: 'Sales Order',
      salesOrderAction: 'sell to',
      salesOrders: 'Sales Orders',
      sourceSaved: 'Your %{sourceType} was successfully saved!',
      targetFailed:
        'Errors occurred while creating the matching %{targetType}.  Check your settings on the partner facility to ensure they %{targetAction} your facility. You can attempt to create the matching order again by editing this order and selecting the create matching option again.',
      transfers: 'Transfers',
      publicManifest: 'Public Manifest',
      publicManifestFailure: 'Invalid Manifest',
      publicManifestLoading: 'Loading',
      publicManifestSuccess: 'Manifest Available for Download'
    },
    supplyChainMapping: {
      finishedProduct: {
        cannotPackage: 'Cannot Package',
        completePackaging: 'Complete Packaging',
        form: {
          addTrackingId: 'Add Tracking ID',
          batch: 'Batch',
          cancelFail: 'Failed to cancel packaging job',
          cancelSuccess: 'Successfully canceled packaging job',
          currentWeight: 'Current Weight',
          dateCreated: 'Date Created',
          inactivationReason: 'Inactivation Reason',
          noData: 'No Data',
          onHold: 'On Hold',
          packageId: 'Package ID',
          productName: 'Product Name',
          quickmoveFailed: 'Quickmove encountered an error',
          quickmoveSuccess: 'Quickmove occured rapidly',
          storageLocation: 'Stor. Loc.',
          testResults: 'Test Results',
          type: 'Type',
          unit: 'Package ID',
          units: 'UOM',
          vendor: 'Vendor'
        },
        header: 'Finished Product',
        modifyPackages: 'Modify Packages',
        packageId: 'Package Id',
        searchPlaceholder: 'Search Packages',
        startPackaging: 'Start Packaging',
        transferProduct: 'Transfer Product'
      },
      form: {
        activePurChaseOrder: 'Active Purchase Order',
        activePurChaseOrderSalesOrder: 'Active Purchase Order/Sales Order',
        addInventory: 'Add Inventory',
        allPOProductsHaveToBeUsed: 'All the products from Purchase Order should be used in mapping.',
        allTheProductsHaveToBeMapped: 'All the products have to be mapped',
        alreadyMappedForAnother: 'Product already mapped for another product',
        biotrackUomMismatchErrorBulk: 'The product you are receiving is not pre-pack. You cannot map it to a pre-pack.',
        externalId: 'External ID',
        integratedTransferProducts: 'Integrated Transfer Products',
        inventoryName: 'Inventory Name',
        mapTo: 'Map To',
        partnerName: 'Partner Name',
        partnerProducts: 'Partner Products',
        productCountError: 'Product counts must be equal',
        productName: 'Product Name',
        productNameMapping: 'Product Name Mapping',
        productTotalCount: 'Product Total Count',
        prePackSizeAdded : 'PrePack size added! It may take a moment to update, so please refresh and try again!',
        purchaseOrderProducts: 'Purchase Order Products',
        quantity: 'Quantity',
        quantityMismatchError: 'Quantities must match',
        receive: 'Receive',
        recommendedProducts: 'Recommended Products',
        searchIntegrationTransfers: 'Search Integration Transfers',
        uniqueProductError: 'Product already mapped',
        uomMismatchError: 'UOMs must match. Your product UOM is %{internalUom}. Partner UOM is %{partnerUom}.',
        yourProducts: 'Your Products'
      },
      integratedTransfer: {
        get: {
          failed: 'Failed to get integrated transfer'
        }
      },
      integratedTransfers: {
        get: {
          failed: 'Failed to get integrated transfers'
        }
      },
      internalOrder: {
        get: {
          failed: 'Failed to get internal order'
        }
      },
      internalOrders: {
        get: {
          failed: 'Failed to get internal orders'
        }
      },
      itemMasters: {
        get: {
          failed: 'Failed to get item masters'
        }
      },
      pricingWeights: {
        addGroup: 'Add new Pricing Group',
        get: {
          failed: 'Failed to get pricing weights.'
        },
        title: 'Select Pricing Weights'
      },
      title: {
        incoming: 'Incoming Supply Chain Mapping',
        outgoing: 'Outgoing Supply Chain Mapping'
      },
      transfers: {
        cancel: 'Cancel Transfer',
        create: 'Create Transfer',
        edit: 'Edit Transfer',
        form: {
          addPackage: 'Add Another Package',
          additionalTracking: 'Additional Tracking',
          allergens: 'Allergens',
          allergensFile: 'Allergens File',
          arrivalTime: 'Arrival Time',
          canceled: 'Canceled',
          completed: 'Completed',
          createDestination: 'Create New Destination',
          createDriver: 'Create New Driver',
          createPartner: 'Crate New Partner',
          dateFilled: 'Date Filled',
          dba: 'DBA',
          departureTime: 'Departure Time',
          destination: 'Destination',
          destinationTotal: 'Destination Total',
          directions: 'Directions To Location',
          driverInfo: 'Driver Information',
          driverLicense: 'Driver License',
          enterIngredients: 'Enter Ingredients and Allergens',
          exceedQuantityError: 'Cannot exceed quantity available',
          exciseTaxDue: 'Excise Tax Due',
          harvestBatch: 'Harvest Batch',
          ingredients: 'Ingredients',
          ingredientsAndAllergens: 'Ingredients & Allergens',
          ingredientsFile: 'Ingredients File',
          inventoryType: 'Inventory Type',
          item: 'Item',
          licenseNumber: 'License Number',
          licensePlate: 'License Plate',
          lineTotal: 'Line Total',
          make: 'Make',
          model: 'Model',
          name: 'Name',
          open: 'Open',
          orderNumber: 'Order Number',
          orderTotals: 'Order Totals',
          outForDelivery: 'Out for Delivery',
          packageTotal: 'Package Total',
          packages: 'Packages',
          partnerList: 'Partner List',
          phoneNumber: 'Phone Number',
          receiveTransfer: 'Receive Transfer',
          selectItem: 'Select Item',
          selectOrder: 'Select Order',
          selectPartner: 'Select Partner',
          sizeLimit: 'Maximum Filesize: 4 MB',
          stateLicense: 'State License',
          status: 'Status',
          storageLocation: 'Storage Location',
          testingId: 'Testing ID',
          totalWeight: 'Total Weight',
          transferFee: 'Transfer Fee',
          transferStatus: 'Transfer Status',
          transferType: 'Transfer Type',
          typeLimit: 'Allowed Extensions: PDF, DOC, DOCX, JPG, GIF and PNG',
          unitPrice: 'Unit Price',
          upload: 'Upload',
          uploadInvoice: 'Upload Invoice',
          vehicle: 'Vehicle',
          vehicleInfo: 'Vehicle Information'
        },
        header: 'Transfers',
        integrationError: 'Integration Error: %{package_code}: %{message}',
        integrationErrorQuarantined: "You can't create a Transfer to Sales Partner with Quarantined lot",
        printInvoice: 'Print Invoice',
        printManifest: 'Print Manifest',
        receiveTransfer: 'Receive Transfer'
      }
    },
    systemMessages: {
      generalErrorAlert: 'We found %{count} form errors.  We have scrolled your form to the first one.',
      generalErrorAlert_1: 'We found a form error and have scrolled your form to it.'
    },
    taxProfiles: {
      actions: {
        activate: 'Activate',
        create: 'Create Tax Profile',
        edit: 'Edit Tax Profile',
        inactivate: 'Inactivate',
        reorderTaxes: 'Re-order Tax Profiles'
      },
      activate: {
        failed: 'Failed to activate Tax Profile',
        loading: 'Activating Tax Profile…',
        success: 'Successfully activated Tax Profile'
      },
      form: {
        addTaxGroup: 'Add Group',
        appliedToMethods: 'Applied To Fulfillment Methods',
        appliedToProductCategories: 'Applied To Product Categories',
        compoundedTaxesHeading: 'Taxes Applied To Sub Total (and compounding)',
        compoundedTaxesHeadingSub:
          'Multiple group so taxation may be specified by your jurisdiction that are to be calculated from the sub total and compounded.',
        customer_types: 'Customer Types',
        facilities: 'Applicable Facilities',
        sampleTaxAmount: 'Sample Tax Amount',
        states: 'In-State',
        subTotalAmount: 'Carried Forward Amount',
        subTotalAmountNoGroups: 'After Taxes Applied To Sub Total Amount',
        subTotalAmountTaxGroupOne: 'Sample Product Sub Total Amount',
        subTotalPlusCompoundingTaxes: 'Sub Total Plus Compounding Taxes',
        subTotalPlusGroupedTaxes: 'Sub Total Plus Grouped Taxes',
        taxGroupName: 'Tax Group %{number}',
        taxPercent: 'Tax Percent',
        taxRateName: 'Tax Rate Name',
        tax_profile_name: 'Profile Name',
        taxedResult: 'Taxed Result',
        taxedResultGrandTotal: 'Grand Total',
        taxesAppliedGlobally: 'Taxes Applied Globally',
        taxesAppliedToSubTotalAndGroupedTaxes: 'Taxes Applied To Sub Total And Grouped Taxes',
        taxesAppliedToSubTotalOnly: 'Taxes Applied To Sub Total Only'
      },
      get: {
        failed: 'Failed to load Tax Profiles',
        loading: 'Loading Tax Profiles…'
      },
      inactivate: {
        failed: 'Failed to inactivate Tax Profile',
        loading: 'Inactivating Tax Profile…',
        success: 'Successfully inactivated Tax Profile'
      },
      successSave: 'Success Save',
      table: {
        active: 'Active',
        customerType: 'Customer Type',
        facilities: 'Applicable facilities',
        inState: 'In-State',
        inactive: 'Inactive',
        name: 'Tax Profile Name',
        title: 'Tax Profiles',
        weight: 'Order'
      }
    },
    taxes: {
      categories: 'Categories',
      failAdd: 'Failed to add Tax Rate(s)',
      failEdit: 'Failed to modify Tax',
      form: {
        active: 'Active',
        addTaxRate: 'Add Tax Rate',
        applicableDeliveryZipcodes: 'Applicable Delivery %{label}',
        applicableFacilities: 'Applicable Facilities',
        applicableFacilitiesValidation: "'Applicable Facilities' is required",
        applicableProductCategories: 'Applicable Product Categories',
        applies_in_state: 'In State Customers',
        applies_out_of_state: 'Out of State Customers',
        applies_to: 'Applies To',
        applies_to_disabled_veterans: 'Disabled Veterans',
        applies_to_medical: 'Medical Customers',
        applies_to_non_mmic: 'MMIC Customers',
        applies_to_recreational: 'Recreational Customers',
        customerTypes: 'Customer Types',
        customerTypesValidation: "'Customer Types' is required",
        disabledVeterans: 'Disabled Veterans',
        enterTaxNameError: 'Enter Tax Name Error',
        exciseTax: 'Special Excise Tax (applied to the subtotal before other taxes)',
        inState: 'In-State',
        inStateValidation: "'In-State' is required",
        medical: 'Medical',
        mmic: 'MMIC',
        name: 'Tax Name',
        order: 'Order',
        outOfState: 'Out-of-State',
        recreational: 'Recreational',
        save: 'Save',
        taxAgencyVendorName: 'Tax Agency Vendor Name',
        taxName: 'Tax Rate Name',
        taxRate: 'Tax Rate',
        thisIsOklahomaGrossReceiptsTax: 'This is the Oklahoma 7% Gross Receipts Tax',
        title: 'Create Tax Rates',
        zipcodeNotes:
          'Applicable Delivery %{labelPlural} will be considered for delivery orders only. Leaving this field empty will cause tax to be applied to any %{label}. Coma-separated values are supported.',
        zipcodeValidation: 'this field only accepts comma separated list of five digit zipcodes'
      },
      get: {
        failed: 'Failed to load Taxes'
      },
      modifyTax: {
        modifyTax: 'Modify Tax'
      },
      name: 'Tax Rate Name',
      rate: 'Tax %',
      successAdd: 'Successfully added Tax Rate(s)',
      successEdit: 'Successfully modified Tax',
      title: 'Tax Listing',
      view: 'View',
      zipCodes: 'Zip Codes'
    },
    theirs: 'Theirs',
    timeoutModal: {
      header: 'Still Working?',
      keepWorkingButton: 'Keep Working',
      logoutButton: 'Logout',
      modalBody: "You're about to be logged out for inactivity, are you still working or do you want to logout?"
    },
    tracking: {
      actions: {
        activateDeactivateIds: 'Activate/Deactivate IDs',
        modifyGroup: 'Modify Group',
        receiveTrackingIds: 'Receive Tracking IDs'
      },
      activateDeactivateIds: {
        form: {
          activate: 'Activate',
          beginningId: 'Beginning ID',
          beginningIdInvalidForGroup: 'The Beginning ID is not valid for this group',
          deactivate: 'Deactivate',
          endingId: 'Ending ID',
          endingIdAfterBeginningId: 'The Ending ID must come after the Beginning ID',
          endingIdInvalidForGroup: 'The Ending ID is not valid for this group'
        },
        title: 'Activate/Deactivate Tracking IDs'
      },
      common: {
        form: {
          trackingIdMustBeActive: 'Tracking ID not found among available IDs.',
          trackingIdMustBeAvailable: 'Tracking Id Must Be Available'
        }
      },
      getTrackingId: {
        failed: 'Failed to get Tracking ID'
      },
      getTrackingIds: {
        failed: 'Failed to get Tracking IDs'
      },
      receiveTrackingIds: {
        form: {
          DateReceived: 'Date Received',
          beginningId: 'Beginning ID',
          cost: 'Cost',
          dateReceived: 'Date Received',
          dateReceivedError: 'Date is invalid',
          endingId: 'Ending ID',
          fail: 'Receive Tracking IDs save failed',
          packageType: 'Package',
          plantType: 'Plant',
          quantityReceived: 'Quantity Received',
          startingTagError: "The 'Beginning ID' shouldn't end with the letter",
          startingTagLengthError: "The 'Beginning ID' must be at least 24 characters",
          success: 'Receive Tracking IDs saved successfully',
          today: 'Today',
          type: 'Type'
        },
        title: 'Receive Tracking IDs'
      },
      savingMessage: 'Saving Tracking IDs',
      setTrackingIdStatuses: {
        failed: 'Failed to set Tracking ID statuses',
        success: 'Successfully set Tracking ID statuses'
      },
      table: {
        beginningId: 'Beginning ID',
        cost: 'Cost',
        dateReceived: 'Date Received',
        endingId: 'Ending ID',
        orderTotal: 'Order Total',
        quantityReceived: 'Quantity Received',
        type: 'Type'
      },
      title: 'Tracking IDs'
    },
    tranfsers: {
      form: {
        itemsRequested: 'Items Requested'
      }
    },
    transfers: {
      cancelProcess: {
        failed: 'Failed',
        success: 'Success'
      },
      cancellationOfTransfer: 'Cancellation Of Transfer',
      create: {
        fail: 'Fail',
        success: 'Success'
      },
      extraction: 'For Extraction',
      finished: 'Finished Product',
      form: {
        batch: 'Batch',
        item: 'Item',
        packageId: 'Package Id',
        requestedAmounts: 'Requested Amounts',
        saveAndCompleteTransfer: 'Save And Complete Transfer',
        storageLocation: 'Storage Location'
      },
      get: {
        salesOrdersFail: 'Sales Orders Fail'
      },
      packageFilterNotice: 'Packages are filtered to this sub type',
      productSubType: 'Sub Type'
    },
    unitCost: 'Unit Cost',
    uoms: {
      get: {
        failed: 'Failed to load UOMs'
      }
    },
    user: {
      HavingTroubleLoggingIn: 'Having trouble logging in? ',
      LogIntoAccount: 'Log into  Account',
      Password: 'Password',
      RecoverALost: 'Recover A lost ',
      SendEmail: 'Send Email',
      Username: 'Username',
      create: {
        emailNotSent: 'Password Setup email failed to send, please try again.',
        fail: 'User Create Failed',
        success: 'User Profile Saved.',
        successWithEmail: 'User Profile Saved and Password setup email sent to user.'
      },
      logIn: 'log in',
      loginYo: 'please login',
      modify: {
        failUpdateUser: 'Fail Update User',
        failedResetPassword: 'Password Reset email failed to send, please try again.',
        successResetPassword: 'Password Reset sent to user’s Email',
        successUpdateUser: 'Success Update User'
      },
      password: {
        changeFailed: 'Failed to change password',
        changePassword: 'Change Password',
        changeSuccess: 'Password was successfully changed',
        complexityRequirements: 'Your Password must meet the below Complexity Requirements:',
        confirmNewPassword: 'Confirm New Password',
        confirmPassword: 'Confirm New Password',
        create: 'Create',
        createHeader: 'Create Password',
        currentPassword: 'Current Password',
        email: 'Please enter the email address associated with this account',
        emailFailed: 'Failed to send reset email, please contact a system administrator',
        emailInvalid: 'Email Invalid',
        emailSuccess: 'Check your inbox for a reset email',
        linkExpired:
          'Your link to password setup has been expired. Please, contact your Administrator to get a new password setup email.',
        matchInvalid: 'Passwords must match',
        name: 'Please enter the username associated with this account',
        newPassword: 'New Password',
        password1: 'Enter new password',
        password2: 'Re-enter password',
        passwordHasExpired: 'Your password has expired',
        passwordTooShort: 'Password is too short',
        reset: 'Reset',
        resetFailed: 'Failed reset your password, please contact a system administrator',
        resetHeader: 'Reset Password',
        resetPasswordText: "Type in your username and we'll send you details on how to reset your password",
        resetSuccess: 'Successfully submitted your password reset request',
        returnToLogin: 'Return to the login page',
        rules: {
          minLength: 'Minimum of 8 Characters',
          oneNumber: 'At least 1 number',
          partsOfUsername: 'Don’t use your username or part of it',
          specialChar: 'At Least 1 Special Character (*,$,#,!,^,%,&,@,#,~,-,_)',
          threeChars: 'Don’t use any characters 3 times in a row',
          uppercase: 'At least 1 Upper or lowercase character of Roman alphabet'
        },
        saved: 'Password was successfully saved. Now you will be redirected to Login Page.',
        send: 'Send Password Reset Email'
      },
      register: 'Register',
      settings: {
        columnVisibility: 'Failed to load user column visibility settings.',
        columnVisibilityStore: 'Failed to store user column visibility settings.'
      },
      title: 'Log into MJ Platform',
      userRolesSearchStr: 'User Roles Search Str',
      usernameOrPassword: 'Username or password '
    },
    userAccounts: {
      AddNewFacilityRole: 'Add New Facility Role',
      AddNewUserAccount: 'Add New User Account',
      Address: 'Address',
      AssignUserRole: 'Assign User Role',
      BirthDate: 'Birth Date',
      BudtenderKnowledge: 'Budtender knowledge:',
      CardSwipe: 'Card Swipe',
      City: 'City',
      ConfirmEmail: 'Confirm Email',
      Email: 'Email',
      EmployeeID: 'Employee ID',
      FirstName: 'First Name',
      HireDate: 'Hire Date',
      LastName: 'Last Name',
      LicensePlate: 'License Plate #',
      LogInInformation: 'Log-in information',
      MInitial: 'M. Initial',
      Make: 'Make',
      Model: 'Model',
      Password: 'Password',
      PersonalInformation: 'Personal Information',
      Phone: 'Phone',
      PhonePlaceholder: 'ex. +38 (066) 875 67 97',
      PreferredLanguage: 'Preferred Language',
      ProfileImage: 'Profile Image',
      ProfileImageDescription: 'Displays in your user account and in the Recent User Activity section of the Dashboard',
      ProfileImageLimits: '(2MB max and be JPG or PNG on a white background)',
      Save: 'Save',
      State: 'State',
      StateLicense: 'State License',
      TerminationDate: 'Termination Date',
      TooltipSettings: 'Tooltip Settings',
      UploadFile: 'Upload File',
      UserAccounts: 'User Accounts',
      UserIDs: 'User IDs',
      Username: 'Username',
      VehicleInformation: 'Vehicle Information',
      ZipCode: 'Zip Code',
      active: 'Active',
      address: 'Address',
      assignUserRole: 'Assign User Role',
      birthDate: 'Birth Date',
      budtenderknowledge: 'Budtenderknowledge:',
      'budtenderknowledge:': 'Budtenderknowledge',
      cardSwipe: 'Card Swipe',
      city: 'City',
      confirmEmail: 'Confirm Email',
      createPassword: 'Password Setup email will be sent to email entered, when saving User Profile',
      email: 'Email',
      employeeID: 'EmployeeID',
      firstName: 'First Name',
      form: {
        emailMatchError: 'Confirm Email does not match Email',
        enterEmailError: 'Enter Email',
        enterPasswordError: 'Enter Password',
        enterUsernameError: 'Enter Username',
        exceededBy:
          'New Flower Weight [entered new ] Gram(s) New Harvest Batch Wt. must be lower or equal to [current flower weight]',
        firstNameError: 'Enter First Name',
        invalidEmailError: 'Invalid Email',
        lastNameError: 'Enter Last Name',
        passwordMustBeLongerThanEightCharacters: 'Password Must Be Longer Than Eight Characters',
        selectStatus: 'Select Status',
        stateLicenseError: 'Enter State License'
      },
      grid: {
        noResults: 'No Results'
      },
      hireDate: 'Hire Date',
      inactive: 'Inactive',
      lastName: 'Last Name',
      licensePlate: 'License Plate',
      loginInformation: 'Login Information',
      make: 'Make',
      middleInitial: 'Middle Initial',
      model: 'Model',
      modifyPassword: "Password Reset email will be sent to email entered upon 'Save and Reset'",
      password: 'Password',
      personalInformation: 'Personal Information',
      profileImage: 'Profile Image',
      profileImageDescription: 'Profile Image Description',
      profileImageLimits: 'Profile Image Limits',
      requiresAtLeastOneRole: 'Assign at least one user role in one of these facilities',
      requiresAtLeastOneRoleBelow: 'Assign at least one user role in one of the facilities listed below.',
      requiresRole: 'Assign at least one user role.',
      resetPassword: 'Save and Reset',
      save: 'Save',
      state: 'State',
      stateIntegrationId: 'Leaf Data Global User ID',
      stateLicense: 'State License',
      status: 'Status',
      terminationDate: 'Termination Date',
      tooltipSettings: 'Tooltip Settings',
      username: 'Username',
      vehicleInformation: 'Vehicle Information',
      zipCode: 'Zip Code'
    },
    userRoles: {
      createCustomRole: 'Create Custom Role',
      failedToLoad: 'Failed to load roles',
      loadingRoles: 'Loading roles',
      noPermissionsError: 'Please select at least one permission for the role',
      permissions: 'Role Permissions',
      roleName: 'Role Name',
      save: {
        failed: 'Failed to update User Roles',
        success: 'Role(s) saved successfully'
      },
      searchByName: 'Search Permission by name',
      searchPlaceholder: 'Search…',
      title: 'User Roles'
    },
    users: {
      actions: {
        createTax: 'Create Tax',
        createUser: 'Create User',
        modifyTax: 'Modify Tax',
        modifyUser: 'Modify User'
      },
      form: {
        profileImageLimits: 'Profile Image Limits'
      },
      table: {
        email: 'Email',
        expirationDate: 'Expiration Date',
        firstName: 'First Name',
        id: 'Id',
        lastName: 'Last Name',
        name: 'Name',
        phoneNumber: 'Phone Number',
        role: 'Role',
        userName: 'User Name'
      },
      title: 'User Listing'
    },
    vehicles: {
      viewInsuranceDocument: 'View Insurance Document'
    },
    versionModal: {
      header: 'A new version of the client software is available!',
      modalBody:
        'It looks like your client is out of date, you can retrieve the latest version of the client application by hitting the refresh button below, or simply refreshing your browser. You may also choose to temporarily dismiss this notice and finish what you are doing, but choosing to do so can cause the application to behave unexpectedly.',
      keepWorkingButton: 'Remind me in 5 minutes',
      refreshButton: 'Refresh'
    }
  }
};
