import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Button, ButtonToolbar, Col} from 'react-bootstrap';
import {Field} from 'redux-form';
import moment from 'moment/moment';
import InlineCheckBox from '../../../common/form/InlineCheckBox';
import InternationalDateTimePickerInput from '../../../common/form/InternationalDateTimePickerInput';
import {convertDbToClientTZ} from '../../../../util/dateHelpers';


class ChangeDate extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
  }
  
  render() {
    const { mayChangeDate, initialValues, displayChangeDate } = this.props;
    const initial_completed_date = convertDbToClientTZ(initialValues.completed_date);
    //do not allow them to put the date in the future
    const isValidDate = current => {
      return current && current.isSameOrBefore(moment());
    };
    return (
        <div>
          {displayChangeDate && (
            <React.Fragment>
              <Col sm={4}>
                <Field
                  name='mayChangeDate'
                  component={InlineCheckBox}
                  label={I18n.t('cultivation.orderHistory.table.changeDate')}
                />
              </Col>
              <br></br>
              <Col lg={8}>
                {mayChangeDate && (
                  <React.Fragment>
                    <div style={{ fontWeight: 'bold'}}>
                        <InternationalDateTimePickerInput
                        name='new_status_date'
                        label={I18n.t('cultivation.orderHistory.table.orderDate')}
                        isValidDate={isValidDate}
                        isRequired={false}
                        initialValue={initial_completed_date}
                        props={{
                          timeFormat: true
                        }}
                        />
                    </div>
                    <ButtonToolbar>
                        <Button variant='primary' type='submit'>
                        {I18n.t('common.form.save')}
                        </Button>
                    </ButtonToolbar>
                  </React.Fragment>
                )}
              </Col>
            </React.Fragment>
          )}
        </div>
      );
  }
}
  
ChangeDate.propTypes = {
  mayChangeDate: PropTypes.bool,
  initialValues: PropTypes.object.isRequired,
  displayChangeDate: PropTypes.bool.isRequired,
};
  
export default ChangeDate;