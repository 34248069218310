
import * as types from '../constants/actionTypes';
import initialState from '../constants/initialState';

export default function facilityReducer (state = initialState.facility, action){
  const {payload} = action;
  switch (action.type) {
  case types.SET_FACILITY_SUCCESS:
    return Object.assign({}, payload.facility);
  case types.SET_FACILITY_SUBMISSION_STATUS:
    state.submissionStatus = action.status;
    return Object.assign({}, state);  
  case types.UPDATE_FACILITY:
    return Object.assign({}, action.facility);  
  case types.UPDATE_FACILITY_ORGANIZATION:
    state.public_profile_enabled = action.organization.public_profile_enabled;
    return Object.assign({}, state);        
  case types.LOGIN_SUCCESS:
    return Object.assign({}, action.facility);
  default:
    return state;
  }
}
