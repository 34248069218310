import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Alert} from 'react-bootstrap';
import {FaCheck, FaExclamationTriangle, FaInfoCircle, FaRegTimesCircle} from 'react-icons/fa';
import differenceby from 'lodash.differenceby';
import {toastr} from 'react-redux-toastr';

import * as messageTypes from '../../constants/messageTypes';

class MessageBlock extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      useToastrs: true,
      typeMap: {[messageTypes.error]: 'error', [messageTypes.warning]: 'warning', [messageTypes.success]: 'success', [messageTypes.info]: 'info'},
    };
    this.getMessageStyle = this.getMessageStyle.bind(this);
    this.getMessageGlyph = this.getMessageGlyph.bind(this);
    this.getMessageText = this.getMessageText.bind(this);
    this.getToastrOptions = this.getToastrOptions.bind(this);
    this.messageDismiss = this.messageDismiss.bind(this);
  }

  componentWillReceiveProps(newProps) {
    /***
     * Left support for non toastr messages during debug period so we can easily switch over to legacy messages to see behaviors
     * in case anything has changed that can't easily be sorted out.  Once done with debug - this probably wants to live in middleware
     * as the page level dependency is solved at the app level.
     ***/
    if(!this.state.useToastrs) {
      if (newProps.timeout && newProps.dismiss) {
        differenceby(newProps.messages, this.props.messages, 'id')
          .filter(message => [messageTypes.success, messageTypes.info].indexOf(message.messageType) > -1)
          .forEach(message => setTimeout(() => newProps.dismiss(message.id), newProps.timeout));
      }
      return true;
    }
    // TOASTR Dispatch
    const messagesDispatched = []; // Trap duplicates that I think are getting caught by two handlers
    if(newProps.messages && newProps.messages.length){
      newProps.messages.forEach((message, index) => {
        const methodMap = {error: 'error', warning: 'warning'};
        const options = this.getToastrOptions(message, newProps.messages.length > 1 && newProps.messages.length - 1 === index ? newProps.messages.length : 1);
        const method = methodMap[options.status] ? methodMap[options.status] : 'light';
        const text = this.getMessageText(message);
        const simpleMessage = typeof text === 'string' ? text.replace(/ /g, '').toLowerCase() : false;
        if(messagesDispatched.indexOf(simpleMessage) === -1){
          messagesDispatched.push(simpleMessage);
          toastr[method]('', text, options);
        }
      });
    }

  }

  getMessageStyle(item) {
    switch (item.messageType) {
    case messageTypes.error:
      return 'danger';
    case messageTypes.warning:
      return 'warning';
    case messageTypes.success:
      return 'success';
    default:
      return 'info';
    }
  }

  getMessageGlyph(item) {
    if (item.messageType === messageTypes.warning || item.messageType === messageTypes.error) {
      return <FaExclamationTriangle/>;
    } else if (item.messageType === messageTypes.success) {
      return <FaCheck/>;
    }
    return <FaInfoCircle/>;
  }

  getMessageText(item) {
    try {
      const message = (typeof item.message === 'string')
        ? (item.localization ? item.message : I18n.t(item.message))
        : (item.localization ? item.message : I18n.t(...item.message));

      return (Array.isArray(message))
        ? message.join('\n')
        : message;
    } catch(exception) {
      return (item.message || '').toString();
    }
  }

  getToastrOptions(message, messageCount){
    const {typeMap} = this.state;
    const status = typeMap[message.messageType] ? typeMap[message.messageType] : 'info';
    const isPersistent = status === 'error' || status === 'warning';
    const dismiss = () => this.messageDismiss(message);
    const dismissAll = () => this.messageDismiss(message, true);
    return Object.assign({}, {
      status,
      position: 'bottom-left',
      timeOut: isPersistent ? 0 : this.props.timeout ? this.props.timeout : 3000,
      removeOnHover: !isPersistent,
      onHideComplete: dismiss,
      onCloseButtonClick: dismiss,
    }, (messageCount === 1 || !isPersistent) ? {} : {component: (<div style={{marginTop: '12px'}}>
        <a
          href
          style={{color: 'inherit', fontSize: 'smaller', cursor: 'pointer'}}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            e.target.blur();
            dismissAll();
          }}
        >
          Dismiss All {status === 'error' ? 'Errors' : 'Warnings'}
        </a>
      </div>)
    });
  }

  messageDismiss(message, dismissAll = false){
    const {typeMap} = this.state;
    if(dismissAll) toastr.removeByType(typeMap[message.messageType]);
    if(this.props.messages.length > 1 && dismissAll) {
      this.props.messages.forEach((msg) => {
        this.props.dismiss(msg.id);
      });
      return true;
    }
    this.props.dismiss(message.id);
  }

  render() {
    if(this.state.useToastrs) return null;

    const {messages, dismiss} = this.props;
    return (
      <div className='messages'>
        {messages.map((item, index) => {
          return (
            <Alert key={item.id} variant={this.getMessageStyle(item)} onDismiss={() => dismiss && dismiss(item.id)}>
              {this.getMessageGlyph(item)}
              {index !== 0 && index === messages.length - 1 ?
                <FaRegTimesCircle
                  className='dismiss-all'
                  title='Dismiss all'
                  onClick={() => dismiss && messages.forEach(message => dismiss(message.id))}/>
                : null}
              {this.getMessageText(item)}
            </Alert>
         );
        })}
      </div>
    );
  }
}

MessageBlock.propTypes = {
  messages: PropTypes.array.isRequired,
  dismiss: PropTypes.func,
  timeout: PropTypes.number
};

export default MessageBlock;
