import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { Row, Col } from 'react-bootstrap';
import {CREATE_DRIVER_FORM} from '../../constants/forms';
import SubmitSection from '../common/form/SubmitSection';
import ReactSelectInput from '../common/form/ReactSelectInput';
import MultiselectInput from '../common/form/MultiselectInput';
import WillRender from '../common/concealers/WillRender';
import PersonalInfo from './PersonalInfo';
import CompanyInfo from './CompanyInfo';
import { validate } from './validate';
import VehicleInfo from './VehicleInfo';
import InlineCheckBox from '../common/form/InlineCheckBox';

const CreateDriverForm = (props) => {
  const {
    submitting,
    vehicles,
    users,
    change,
    onChangeDefaultVehicle,
    onUserChange,
    isCompany,
    integrationState,
    onfleetIsEnabled,
    onfleetTeams,
    onfleetWorkers,
    countryCode,
    shouldDisableOnfleetTeams,
    disableOnfleetTeams,
  } = props;

  const settings = {
    actionSettings: {
      submit: {
        submitting,
        text: I18n.t('common.form.save'),
      }
    }
  };

  return(
    <form onSubmit={props.handleSubmit} className='create-driver-form'>
      <Row>
        <Col xs={12} sm={12} md={12}>
          <WillRender ifTrue={!integrationState.isBiotrack && !integrationState.isPaLeaf}>
          <Row>
            <Col sm={4} xs={4} md={4}>
              <Field name='is_licensed_transporter' component={InlineCheckBox} props={{
                label: I18n.t('cultivation.drivers.form.transporterCompany'),
                groupClassName: 'inline-checkbox',
                disabled: integrationState.isWaLeaf
              }}/>
            </Col>
          </Row>
          </WillRender>
          <WillRender ifTrue={!isCompany}>
            <PersonalInfo
              users={users}
              change={change}
              onfleetIsEnabled={onfleetIsEnabled}
              onUserChange={onUserChange}
              integrationState={integrationState}
              countryCode={countryCode}
            />
          </WillRender>
          <WillRender ifTrue={isCompany}>
            <CompanyInfo
              countryCode={countryCode}
              onfleetIsEnabled={onfleetIsEnabled}
            />
          </WillRender>
        </Col>
      </Row>
      <WillRender ifTrue={!isCompany}>
        <VehicleInfo
          vehicles={vehicles}
          onChangeDefaultVehicle={onChangeDefaultVehicle}
          change={change}
          />
      </WillRender>
      {(onfleetIsEnabled) && (
        <div>
          <Row>
            <Col sm={12} xs={12} md={12} className='driver-form-header'>
              <p>Onfleet Integration</p>
            </Col>
          </Row>
          <Row>
            <Col sm={4} xs={4} md={4}>
              <Field name='onfleet_teams' component={MultiselectInput} props={{
                label: 'Onfleet Teams',
                disabled: shouldDisableOnfleetTeams,
                isRequired: !shouldDisableOnfleetTeams,
                options: onfleetTeams,
                valueKey: 'id',
                textKey: 'name',
              }}/>
            </Col>
            {(onfleetWorkers && onfleetWorkers.length > 0) && (
              <Col sm={4} xs={4} md={4}>
                <Field name='onfleet_worker_mapping' component={ReactSelectInput} props={{
                  label: 'Map Driver to Onfleet Worker',
                  options: onfleetWorkers,
                  valueKey: 'id',
                  textKey: 'name',
                  onChange: (value) => {
                    disableOnfleetTeams(!value);
                    change('onfleet_worker_mapping', value);
                    return value;
                  }
                }}/>
              </Col>
            )}
          </Row>
        </div>
      )}
      <SubmitSection settings={settings}/>
    </form>
  );
};

CreateDriverForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  facilities: PropTypes.array,
  vehicles: PropTypes.array,
  users: PropTypes.array,
  onChangeDefaultVehicle: PropTypes.func.isRequired,
  onUserChange: PropTypes.func.isRequired,
  change: PropTypes.func,
  onImageChange: PropTypes.func,
  imageUrl: PropTypes.string,
  isCompany: PropTypes.bool.isRequired,
  integrationState: PropTypes.object.isRequired,
  onfleetIsEnabled: PropTypes.bool.isRequired,
  onfleetTeams: PropTypes.array,
  onfleetWorkers: PropTypes.array,
  countryCode: PropTypes.string.isRequired,
  disableOnfleetTeams: PropTypes.func,
  shouldDisableOnfleetTeams: PropTypes.bool,
  addLoadingOverlay: PropTypes.func.isRequired
};

export default reduxForm({
  form: CREATE_DRIVER_FORM,
  validate,
  enableReinitialize: true
})(CreateDriverForm);
