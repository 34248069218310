import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {goBack} from 'react-router-redux';
import {Button, Col, FormControl, InputGroup, Row, Table} from 'react-bootstrap';
import {FaPlus} from 'react-icons/fa';
import BootstrapFormGroup from '../common/form/BootstrapFormGroup';
import SelectControl from '../common/form/SelectControl';

export class CreatePlantCostingPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
  }

  render() {
    const CostInput = () => ( //eslint-disable-line react/no-multi-comp
      <InputGroup>
        <InputGroup.Append>$</InputGroup.Append>
        <FormControl type='number' min={0} />
      </InputGroup>
    );
    const PercentageInput = () => ( //eslint-disable-line react/no-multi-comp
      <InputGroup className='percentage-input'>
        <FormControl type='number' min={0} />
        <InputGroup.Append>%</InputGroup.Append>
      </InputGroup>
    );

    return (<div className='create-plant-costing-page'>
      <h1>{I18n.t('plantCosting.createPlantCosting')}</h1>
      <hr/>
      <h2>{I18n.t('plantCosting.fixedCostByStage')}</h2>
      <Row>
        <Col lg={4} md={6}>
          <h3>{I18n.t('plantCosting.propagation')}</h3>
          <hr/>
          <Row className='input-row'>
            <Col md={4}>
              <BootstrapFormGroup
                id='propagation-item'
                label={I18n.t('plantCosting.item')}
                Control={SelectControl}
                controlProps={{
                  options: [
                    {value:'',text:''},
                    {value:1,text:'Rapid Rooter Plug'},
                    {value:2,text:'4 Gallon Pot'},
                    {value:3,text:'Coco Fiber'},
                    {value:4,text:'10 Gallon Pot'}
                  ]
                }}/>
            </Col>
            <Col md={2}>
              <BootstrapFormGroup
                id='propagation-qty'
                label={I18n.t('plantCosting.qty')}
                Control={FormControl}
                controlProps={{
                  type: 'number',
                  min: 0
                }}/>
            </Col>
            <Col md={2}>
              <BootstrapFormGroup
                id='propagation-uom'
                label={I18n.t('plantCosting.uom')}
                Control={SelectControl}
                controlProps={{
                  options: [
                    {value:'',text:''},
                    {value:1,text:'EA'},
                    {value:2,text:'GL'},
                    {value:3,text:'G'},
                    {value:4,text:'FL OZ'}
                  ]
                }}/>
            </Col>
            <Col md={3}>
              <BootstrapFormGroup
                id='propagation-cost'
                label={I18n.t('plantCosting.cost/Item')}
                Control={CostInput}
                controlProps={{}}/>
            </Col>
          </Row>
          <Row className='input-row'>
            <Col md={4}>
              <SelectControl options={[
                    {value:'',text:''},
                    {value:1,text:'Rapid Rooter Plug'},
                    {value:2,text:'4 Gallon Pot'},
                    {value:3,text:'Coco Fiber'},
                    {value:4,text:'10 Gallon Pot'}
              ]}/>
            </Col>
            <Col md={2}>
              <FormControl type='number' min={0}/>
            </Col>
            <Col md={2}>
              <SelectControl options={[
                    {value:'',text:''},
                    {value:1,text:'EA'},
                    {value:2,text:'GL'},
                    {value:3,text:'G'},
                    {value:4,text:'FL OZ'}
              ]}/>
            </Col>
            <Col md={3}>
              <CostInput/>
            </Col>
          </Row>
          <Row className='button-row'>
            <Col xs={12}>
              <Button bsSize='xsmall' variant='primary'>
                <FaPlus/>
              </Button>
              &nbsp;{I18n.t('plantCosting.addNewItemCost')}
            </Col>
          </Row>
        </Col>
        <Col lg={4} md={6}>
          <h3>{I18n.t('plantCosting.vegetative')}</h3>
          <hr/>
          <Row className='input-row'>
            <Col md={4}>
              <BootstrapFormGroup
                id='vegetative-item'
                label={I18n.t('plantCosting.item')}
                Control={SelectControl}
                controlProps={{
                  options: [
                    {value:'',text:''},
                    {value:1,text:'Rapid Rooter Plug'},
                    {value:2,text:'4 Gallon Pot'},
                    {value:3,text:'Coco Fiber'},
                    {value:4,text:'10 Gallon Pot'}
                  ]
                }}/>
            </Col>
            <Col md={2}>
              <BootstrapFormGroup
                id='vegetative-qty'
                label={I18n.t('plantCosting.qty')}
                Control={FormControl}
                controlProps={{
                  type: 'number',
                  min: 0
                }}/>
            </Col>
            <Col md={2}>
              <BootstrapFormGroup
                id='vegetative-uom'
                label={I18n.t('plantCosting.uom')}
                Control={SelectControl}
                controlProps={{
                  options: [
                    {value:'',text:''},
                    {value:1,text:'EA'},
                    {value:2,text:'GL'},
                    {value:3,text:'G'},
                    {value:4,text:'FL OZ'}
                  ]
                }}/>
            </Col>
            <Col md={3}>
              <BootstrapFormGroup
                id='vegetative-cost'
                label={I18n.t('plantCosting.cost/Item')}
                Control={CostInput}
                controlProps={{}}/>
            </Col>
          </Row>
          <Row className='input-row'>
            <Col md={4}>
              <SelectControl options={[
                {value:'',text:''},
                {value:1,text:'Rapid Rooter Plug'},
                {value:2,text:'4 Gallon Pot'},
                {value:3,text:'Coco Fiber'},
                {value:4,text:'10 Gallon Pot'}
              ]}/>
            </Col>
            <Col md={2}>
              <FormControl type='number' min={0}/>
            </Col>
            <Col md={2}>
              <SelectControl options={[
                {value:'',text:''},
                {value:1,text:'EA'},
                {value:2,text:'GL'},
                {value:3,text:'G'},
                {value:4,text:'FL OZ'}
              ]}/>
            </Col>
            <Col md={3}>
              <CostInput/>
            </Col>
          </Row>
          <Row className='button-row'>
            <Col xs={12}>
              <Button bsSize='xsmall' variant='primary'>
                <FaPlus/>
              </Button>
              &nbsp;{I18n.t('plantCosting.addNewItemCost')}
            </Col>
          </Row>
        </Col>
        <Col lg={4} md={6}>
          <h3>{I18n.t('plantCosting.flowering')}</h3>
          <hr/>
          <Row className='input-row'>
            <Col md={4}>
              <BootstrapFormGroup
                id='flowering-item'
                label={I18n.t('plantCosting.item')}
                Control={SelectControl}
                controlProps={{
                  options: [
                    {value:'',text:''},
                    {value:1,text:'Rapid Rooter Plug'},
                    {value:2,text:'4 Gallon Pot'},
                    {value:3,text:'Coco Fiber'},
                    {value:4,text:'10 Gallon Pot'}
                  ]
                }}/>
            </Col>
            <Col md={2}>
              <BootstrapFormGroup
                id='flowering-qty'
                label={I18n.t('plantCosting.qty')}
                Control={FormControl}
                controlProps={{
                  type: 'number',
                  min: 0
                }}/>
            </Col>
            <Col md={2}>
              <BootstrapFormGroup
                id='flowering-uom'
                label={I18n.t('plantCosting.uom')}
                Control={SelectControl}
                controlProps={{
                  options: [
                    {value:'',text:''},
                    {value:1,text:'EA'},
                    {value:2,text:'GL'},
                    {value:3,text:'G'},
                    {value:4,text:'FL OZ'}
                  ]
                }}/>
            </Col>
            <Col md={3}>
              <BootstrapFormGroup
                id='flowering-cost'
                label={I18n.t('plantCosting.cost/Item')}
                Control={CostInput}
                controlProps={{}}/>
            </Col>
          </Row>
          <Row className='input-row'>
            <Col md={4}>
              <SelectControl options={[
                {value:'',text:''},
                {value:1,text:'Rapid Rooter Plug'},
                {value:2,text:'4 Gallon Pot'},
                {value:3,text:'Coco Fiber'},
                {value:4,text:'10 Gallon Pot'}
              ]}/>
            </Col>
            <Col md={2}>
              <FormControl type='number' min={0}/>
            </Col>
            <Col md={2}>
              <SelectControl options={[
                {value:'',text:''},
                {value:1,text:'EA'},
                {value:2,text:'GL'},
                {value:3,text:'G'},
                {value:4,text:'FL OZ'}
              ]}/>
            </Col>
            <Col md={3}>
              <CostInput/>
            </Col>
          </Row>
          <Row className='button-row'>
            <Col xs={12}>
              <Button bsSize='xsmall' variant='primary'>
                <FaPlus/>
              </Button>
              &nbsp;{I18n.t('plantCosting.addNewItemCost')}
            </Col>
          </Row>
        </Col>
      </Row>
      <h2>{I18n.t('plantCosting.allocatedOverhead')}</h2>
      <hr/>
      <Row>
        <Col lg={3} md={4}>
          <Table className='total-bill'>
            <tr>
              <th></th>
              <th className='text-center'>
                {I18n.t('plantCosting.totalBill')}
              </th>
            </tr>
            <tr>
              <th>
                {I18n.t('plantCosting.electricity')}
              </th>
              <td><CostInput/></td>
            </tr>
            <tr>
              <th>
                {I18n.t('plantCosting.water')}
              </th>
              <td><CostInput/></td>
            </tr>
            <tr>
              <th>
                {I18n.t('plantCosting.rent')}
              </th>
              <td><CostInput/></td>
            </tr>
          </Table>
        </Col>
        <Col lg={{span: 3, offset: 2}} md={{span: 4, offset: 1}}>
          <Table className='percentages'>
            <tr>
              <th>{I18n.t('plantCosting.propagation')}</th>
              <th>
                {I18n.t('plantCosting.vegetative')}
              </th>
              <th>{I18n.t('plantCosting.flowering')}</th>
            </tr>
            <tr>
              <td>
                <PercentageInput/>
              </td>
              <td>
                <PercentageInput/>
              </td>
              <td>
                <PercentageInput/>
              </td>
            </tr>
            <tr>
              <td>
                <PercentageInput/>
              </td>
              <td>
                <PercentageInput/>
              </td>
              <td>
                <PercentageInput/>
              </td>
            </tr>
            <tr>
              <td>
                <PercentageInput/>
              </td>
              <td>
                <PercentageInput/>
              </td>
              <td>
                <PercentageInput/>
              </td>
            </tr>
          </Table>
        </Col>
      </Row>
      <h2>{I18n.t('plantCosting.laborOverhead')}</h2>
      <hr/>
      <Table className='labor-overhead' responsive={true}>
        <tr>
          <th className='employee-heading'>{I18n.t('plantCosting.employee')}</th>
          <th className='monthly-pay-heading'>{I18n.t('plantCosting.monthlyPay')}</th>
          <th>{I18n.t('plantCosting.propagation')}</th>
          <th>{I18n.t('plantCosting.vegetation')}</th>
          <th>{I18n.t('plantCosting.flowering')}</th>
          <th>{I18n.t('plantCosting.harvest')}</th>
          <th>{I18n.t('plantCosting.cure')}</th>
          <th>{I18n.t('plantCosting.trim')}</th>
          <th>{I18n.t('plantCosting.package')}</th>
          <th>{I18n.t('plantCosting.shipping')}</th>
        </tr>
        <tr>
          <th>John Blount</th>
          <td><CostInput/></td>
          <td><PercentageInput/></td>
          <td><PercentageInput/></td>
          <td><PercentageInput/></td>
          <td><PercentageInput/></td>
          <td><PercentageInput/></td>
          <td><PercentageInput/></td>
          <td><PercentageInput/></td>
          <td><PercentageInput/></td>
        </tr>
        <tr>
          <th>Jimmy Bleaser</th>
          <td><CostInput/></td>
          <td><PercentageInput/></td>
          <td><PercentageInput/></td>
          <td><PercentageInput/></td>
          <td><PercentageInput/></td>
          <td><PercentageInput/></td>
          <td><PercentageInput/></td>
          <td><PercentageInput/></td>
          <td><PercentageInput/></td>
        </tr>
        <tr>
          <th>Darby O&apos;Gill</th>
          <td><CostInput/></td>
          <td><PercentageInput/></td>
          <td><PercentageInput/></td>
          <td><PercentageInput/></td>
          <td><PercentageInput/></td>
          <td><PercentageInput/></td>
          <td><PercentageInput/></td>
          <td><PercentageInput/></td>
          <td><PercentageInput/></td>
        </tr>
      </Table>
      <Row className='button-row'>
        <Col xs={12}>
          <Button bsSize='xsmall' variant='primary'>
            <FaPlus/>
          </Button>
          &nbsp;{I18n.t('plantCosting.addEmployee')}
        </Col>
      </Row>
      <hr/>
      <Button className='save float-right'
              bsSize='large'
              onClick={this.props.actions.goBack}
              variant='primary'>
        {I18n.t('plantCosting.save')}
      </Button>
    </div>);
  }

}

CreatePlantCostingPage.propTypes = {
  actions: PropTypes.object.isRequired
};

function mapStateToProps(){
  return {
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {goBack};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default  connect(mapStateToProps, mapDispatchToProps)(CreatePlantCostingPage);
