import React from 'react';
import get from 'lodash.get';
import find from 'lodash.find';
import filter from 'lodash.filter';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Col, Row, Button, Alert} from 'react-bootstrap';
import {FaExclamationTriangle, FaMinus, FaSave, FaTrash} from 'react-icons/fa';
import {FILE_TYPES, types} from '../../../../constants/fileUploads';
import {evaluatePredicate} from '../../../../util/callbackHelpers';
import InternationalDateTimePickerInput from '../InternationalDateTimePickerInput';
import FileInput from '../FileInput';
import ReactSelectInput from '../ReactSelectInput';
import TextInput from '../TextInput';
import * as itemNames from '../../../../constants/itemNames';

class ObjectFilesComponent extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      uploading: {},
    };
    this.changeUploadStatus = this.changeUploadStatus.bind(this);
    this.filterFileOptions = this.filterFileOptions.bind(this);
    this.renderWarning = this.renderWarning.bind(this);
    this.renderError = this.renderError.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
  }

  changeUploadStatus(key, status) {
    this.setState(state => ({
      uploading: {...state.uploading, [key]: status},
    }));
  }

  deleteFile(id, fieldName, type) {
    const uri = type === types.image ? 'images' : 'documents';
    return this.props.actions
      .deleteItem(
        `/api/${uri}/${id}`,
        null,
        null,
        {id}
      )
      .then(() => {
        this.props.change(`${fieldName}.file_id`, null);
        this.props.change(`${fieldName}.file`, null);
      });
  }

  filterFileOptions() {
    const { fileOptions, groupedFields, isFieldReadOnly } = this.props;
    const uniqueOptions = filter(fileOptions, { isSingle: true });
    let filteredOptions = fileOptions || [];

    // if field is read only, don't allow the user to add another
    // should be an optional method that we don't always need to pass to this component
    filteredOptions = filter(filteredOptions, (opt) => !isFieldReadOnly(opt.type));

    uniqueOptions.forEach((option) => {
      const groupField = find(groupedFields, { id: option.id }) || { fields: [] };
      if (groupField.fields.length >= 1) {
        filteredOptions = filter(fileOptions, (opt) => opt.id !== option.id);
      }
    });

    return filteredOptions;
  }

  renderWarning(fields) {
    if (fields.length > 0) {
      return null;
    }

    return (
      <div className='alert alert-warning'>
        {I18n.t('common.objectFile.noFilesAdded')}
      </div>
    );
  }

  renderError(groupType) {
    const { meta } = this.props;

    return get(meta, `error[${groupType}]`, null) && (
      <Row>
        <Col xs={12}>
          <Alert variant='danger'>
            <FaExclamationTriangle/>
            <span className='padding-left'>{get(meta, `error[${groupType}]`)}</span>
          </Alert>
        </Col>
      </Row>
    );
  }

  render() {
    const {fileOptions, groupedFields, fields, change, isFieldRequired, isUploadDisabled, partner, facilityState, isWaLeaf, isFieldReadOnly} = this.props;
    const isInternalPartner = partner && partner.is_internal_partner;
    const {uploading} = this.state;

    return (
      <div className='object-files'>
        <div className='files-groups'>
          {groupedFields.map((group, groupIndex) => (
            <div className='file-group' key={groupIndex}>
              <h5>{group.name}</h5>
              {this.renderWarning(group.fields)}
              {this.renderError(group.type)}
              {group.fields.map((field) => {
                const {fieldName, fieldIndex, file} = field;
                const hasFile = Boolean(file.file) && file.file.url;
                const isOhioLicenseNumber = (facilityState === 'OH' && group.type === 'state');
                return (
                  <div className='object-file layout-row layout-align-start-start' key={fieldIndex}>
                    <div className={`file-thumb ${hasFile ? ' active' : ''}`}>
                      {hasFile && !isInternalPartner ?
                        <div>
                          <a href={file.file.url.original || file.file.url} target='_blank' title={I18n.t('common.objectFile.downloadFile')} >
                            <FaSave className='thumb'/>
                          </a>
                          <FaTrash
                            className='remove-button'
                            onClick={() => this.deleteFile(file.file_id, fieldName, FILE_TYPES[group.file_type].type)}
                            title={I18n.t('common.objectFile.removeFile')}
                          />
                        </div> : <FaSave className='thumb'/>}
                    </div>

                    <div className='flex-25 field'>
                      <Field name={`${fieldName}.name`} component={TextInput} props={{
                        label: group.fieldLabel,
                        placeholder: I18n.t('common.objectFiles.fileName'),
                        isRequired: evaluatePredicate(isFieldRequired, group.type) || isOhioLicenseNumber,
                        disabled: (isWaLeaf && file.name) || isInternalPartner || isFieldReadOnly(group.type),
                      }}/>
                    </div>

                    <div className='flex-25 field'>
                      <InternationalDateTimePickerInput
                        name={`${fieldName}.expires_on`}
                        props={{
                          label: I18n.t('common.objectFiles.expiration'),
                          closeOnSelect: true,
                          inputProps: isInternalPartner ? {disabled: true} : {}
                        }}
                      />
                    </div>

                    <div>
                      <Field
                        name={`${fieldName}.document`}
                        itemName={itemNames.document}
                        component={FileInput}
                        props={{
                          ...FILE_TYPES[group.file_type],
                          itemName: null,
                          disabled: uploading[fieldName] || evaluatePredicate(isUploadDisabled, group.type) || isInternalPartner,
                          btnContent: uploading[fieldName] ? I18n.t('common.form.uploading') : I18n.t('common.objectFiles.uploadFile'),
                          onUploadStart: () => this.changeUploadStatus(fieldName, true),
                          onChangeCb: (f) => {
                            change(`${fieldName}.file_id`, f.id);
                            change(`${fieldName}.file`, f);
                            this.changeUploadStatus(fieldName, false);
                          },
                          btnProps: {
                            className: 'btn btn-default',
                          },
                        }}/>
                    </div>

                    {/*if you're not allowed to edit the field you also shouldn't be able to delete it*/}
                    {isFieldReadOnly(group.type)
                      ? null
                      :
                        <div className='remove-row-button'>
                          <Button
                            variant='primary'
                            size='sm'
                            onClick={() => fields.remove(fieldIndex)}
                            disabled={!!file.file_id || (evaluatePredicate(isFieldRequired, group.type) && group.fields.length === 1) || isInternalPartner}
                          >
                            <FaMinus/>
                          </Button>
                        </div>
                    }
                  </div>
                );
              })}
            </div>
          ))}
        </div>

        <Row>
          <Col md={4} className={isInternalPartner ? 'hide' : ''}>
            <Field name='addFile' component={ReactSelectInput} props={{
              options: this.filterFileOptions(),
              valueKey: 'type',
              textKey: 'name',
              label: I18n.t('common.objectFiles.addFile'),
              onChange: (type) => {
                const selectedOption = fileOptions.find(option => option.type === type);
                if (selectedOption) {
                  fields.push({
                    type,
                    file_type: FILE_TYPES[selectedOption.file_type].type,
                    file: null,
                  });
                }
              },
            }}/>
          </Col>
        </Row>
      </div>
    );
  }
}

ObjectFilesComponent.propTypes = {
  actions: PropTypes.shape({
    deleteItem: PropTypes.func.isRequired,
  }).isRequired,
  fileOptions: PropTypes.array.isRequired,
  groupedFields: PropTypes.array.isRequired,
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
  }).isRequired,
  change: PropTypes.func.isRequired,
  types: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  isFieldRequired: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
  ]),
  isFieldReadOnly: PropTypes.func,
  isUploadDisabled: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
  ]),
  partner: PropTypes.object,
  facilityState: PropTypes.string
};

ObjectFilesComponent.defaultProps = {
  // if this method isn't passed, we shouldn't disable anything
  isFieldReadOnly: () => false,
};

export default ObjectFilesComponent;
