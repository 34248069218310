import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import {Row, Col, ButtonToolbar, /*Button,*/ Table, Button} from 'react-bootstrap';
import PermissionWrapper from '../../../common/PermissionWrapper';
import * as p from '../../../../constants/permissions';
import InternationalDecimalStatic from '../../../common/form/InternationalDecimalStatic';

const Tills = ({openRegister, closeRegister, verifyCount, data, registerClosingSettings, enabledPayments}) => {
  const columns = ['cash', 'credit', 'debit', 'check'].concat(enabledPayments).concat(['rewards', 'gift', 'total_payments']);

  /* eslint-disable react/no-multi-comp */
  const formatDecimals = (value) => {
    const numericValue = parseFloat(value);
    return (<InternationalDecimalStatic fractionPartSize={2}>{!isNaN(numericValue) ? numericValue : 0}</InternationalDecimalStatic>);
  };

  return (
    <div>
      {
        data.map((till, index) => {
          const {payments, ending_balance, manual_adjustments} = till.closing_report || {};
          const starting_balance = parseFloat(get(till, 'closing_report.starting_balance', 0));
          const currentClosing = till.register_closing;
          const closingOpeningAllowed = currentClosing && registerClosingSettings && registerClosingSettings.is_allowed;
          const closingEnabled = registerClosingSettings && registerClosingSettings.is_allowed;
          const showCountButton = closingEnabled && currentClosing && registerClosingSettings.count_by !== 'verification';
          const showVerificationButton = closingEnabled && currentClosing && registerClosingSettings.count_by !== 'employee';
          const isCountDisabled = currentClosing && (currentClosing.verified_at || currentClosing.is_completed);
          const isVerificationDisabled = currentClosing && currentClosing.verified_at;

          return (
            <Row className='sales-summary-block' key={index}>
              <Col sm={12} md={12} xs={12}>
                <Row className='sales-summary-header'>
                  <Col sm={12} md={12} xs={12}>
                    {till.name}
                  </Col>
                </Row>

                {payments ? <Table bordered={true} className='till-table'>
                  <tbody>
                  <tr>
                    <td/>
                    {columns.map((header, index) =>
                      <td key={index}>{I18n.t(`retail.closingReport.${header}`)}</td>
                    )}
                  </tr>
                  <tr>
                    <td>{I18n.t(`retail.closingReport.beginningBalance`)}</td>
                    <td>{formatDecimals(starting_balance)}</td>
                    {[{}, {}, {}, {}, {}].map((header, index) =>
                      <td key={index}/>
                    )}
                  </tr>

                  {payments.orders ? <tr>
                    <td>{I18n.t(`retail.closingReport.receivedPayments`)}</td>
                    {
                      columns.map((column, index) => {
                        return (<td key={index}>{formatDecimals(payments.orders[column] || 0)}</td>);
                      })
                    }
                  </tr> : null}

                  {payments.refunds ? <tr className={`${ manual_adjustments ? null : 'bar' } refunded-payments`}>
                    <td>{I18n.t(`retail.closingReport.refundedPayments`)}</td>
                    {
                      columns.map((column, index) => {
                        return (<td key={index}>{formatDecimals(payments.refunds[column] || 0)}</td>);
                      })
                    }
                  </tr> : null}

                  {manual_adjustments ? <tr className={`${ manual_adjustments ? 'bar' : null } manual-adjustments`}>
                    <td>{I18n.t(`retail.closingReport.manualAdjustments`)}</td>
                    <td>{formatDecimals(manual_adjustments || 0)}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr> : null}

                  <tr>
                    <td>{I18n.t(`retail.closingReport.endBalance`)}</td>
                    <td>{formatDecimals(ending_balance)}</td>
                    {[{}, {}, {}, {}, {}].map((header, index) =>
                      <td key={index}/>
                    )}
                  </tr>
                  </tbody>
                </Table> : null}
                {closingEnabled &&
                <Row>
                  <Col sm={6} md={6} xs={6}>
                    <ButtonToolbar>
                      {till.type !== 'vault' && !currentClosing &&
                      <Button variant='primary'
                              onClick={() => {
                                closeRegister(till.id);
                              }}>
                        {I18n.t('retail.closingReport.form.closeRegister')}
                      </Button>
                      }
                      {currentClosing && showCountButton &&
                      <Button variant='primary'
                              type='submit'
                              disabled={isCountDisabled}
                              onClick={() => {
                                verifyCount(currentClosing.id, 'count');
                              }}>
                        {I18n.t('retail.closingReport.count')}
                      </Button>
                      }
                      {currentClosing && showVerificationButton &&
                      <PermissionWrapper permissions={[p.verify_register_count]}>
                        <Button variant='primary'
                                type='submit'
                                disabled={isVerificationDisabled}
                                onClick={() => {
                                  verifyCount(currentClosing.id, 'verification');
                                }}>
                          {I18n.t('retail.closingReport.verifyCount')}
                        </Button>
                      </PermissionWrapper>
                      }
                      {currentClosing && closingOpeningAllowed &&
                      <PermissionWrapper permissions={[p.reopen_closed_register]}>
                        <Button
                          variant='primary'
                          onClick={() => {
                            openRegister(till.id);
                          }}
                        >
                          {I18n.t('retail.closingReport.openRegister')}
                        </Button>
                      </PermissionWrapper>
                      }
                    </ButtonToolbar>
                  </Col>
                </Row>
                }
              </Col>
            </Row>
          );
        })
      }
    </div>

  );
};

Tills.propTypes = {
  data: PropTypes.array.isRequired,
  closeRegister: PropTypes.func.isRequired,
  openRegister: PropTypes.func.isRequired,
  verifyCount: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  registerClosingSettings: PropTypes.object,
};

export default Tills;
