import React from 'react';
import PropTypes from 'prop-types';
import {Field, FieldArray} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Col, FormGroup, Row} from 'react-bootstrap';
import TextInput from '../../../common/form/TextInput';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import MultiselectInput from '../../../common/form/MultiselectInput';
import SubmitSection from '../../../common/form/SubmitSection';
import SubCategories from './SubCategories';
import Ingredients from './Ingredients';
import Directions from './Directions';
import NumericInput from '../../../common/form/NumericInput';

const ProcessingTypeForm = (props) => {

  const {
    reset, invalid, pristine, submitting, change, getFormValue, handleSubmit, phases, equipmentItems, ingredients,
    getPossibleUom, calculateTotalCost, isTouch, onChangeSubcategories, integrationState,
    validInputAndOutputJurisdictionCategories, featureUtQaChangeRequestEnabled
  } = props;

  const notesStyle = {
    fontSize: 'smaller',
    display: featureUtQaChangeRequestEnabled ? 'block' : 'none',
  };

  const submitSectionSettings = {
    actionSettings: {
      reset: {
        text: I18n.t('common.form.reset'),
        action: reset,
        style: 'default',
        pristine,
      },
      saveAndPrint: {
        text: I18n.t('common.actions.saveAndPrint'),
        type: 'submit',
        invalid,
        submitting,
        pristine,
        action: () => {
          onChangeSubcategories('subcategories_output');
          onChangeSubcategories('subcategories_input');
          change('afterSubmit', 'print');
        }
      },
      submit: {
        text: I18n.t('ei.processingTypes.save'),
        invalid,
        submitting,
        pristine,
        action: () => {
          onChangeSubcategories('subcategories_output');
          onChangeSubcategories('subcategories_input');
          change('afterSubmit', '');
        }
      },
    },
    align: 'right'
  };
  return (
    <form onSubmit={handleSubmit} className='process-type' noValidate={true}>
      <FormGroup className='general'>
        <Row>
          <Col xs={6} sm={4} md={3}>
            <Field name='name' component={TextInput}
                   props={{
                     label: I18n.t('ei.processingTypes.form.name'),
                     isRequired: true
                   }}/>
          </Col>
          <Col xs={6} sm={4} md={3}>
            <Field name='starting_phase_ids' component={MultiselectInput}
                   props={{
                     label: I18n.t('ei.processingTypes.form.startingPhaseName'),
                     options: phases,
                     textKey: 'name',
                     valueKey: 'id',
                     isRequired: true
                   }}/>
          </Col>
        </Row>
      </FormGroup>
      <div className='subcategories-block'>
        <h3>{I18n.t('ei.processingTypes.form.inputSubcategoriesSection')} *</h3>
        <div style={notesStyle} className='text-muted'>
          {I18n.t('ei.processingTypes.form.inputSubcategoriesNote')}
        </div>
        <hr/>
        <FieldArray
          name='subcategories_input'
          component={SubCategories}
          getFormValue={getFormValue}
          isTouch={isTouch.subcategories_input}
          onChangeSubcategories={onChangeSubcategories}
          props={{integrationState}}
          validInputAndOutputJurisdictionCategories={validInputAndOutputJurisdictionCategories}
        />
      </div>
      <div className='subcategories-block'>
        <h3>{I18n.t('ei.processingTypes.form.outputSubcategoriesSection')} *</h3>
        <div style={notesStyle} className='text-muted'>
          {I18n.t('ei.processingTypes.form.outputSubcategoriesNote')}
        </div>
        <hr/>
        <FieldArray
          name='subcategories_output'
          component={SubCategories}
          getFormValue={getFormValue}
          isTouch={isTouch.subcategories_output}
          onChangeSubcategories={onChangeSubcategories}
          props={{integrationState}}
          validInputAndOutputJurisdictionCategories={validInputAndOutputJurisdictionCategories}
        />
      </div>
      <FormGroup className='general'>
        <Row>
          <Col xs={6} sm={4} md={3}>
            <Field name='equipment_ids' component={MultiselectInput}
                   props={{
                     label: I18n.t('ei.processingTypes.form.equipmentUsed'),
                     options: equipmentItems,
                     textKey: 'model_name',
                     valueKey: 'id',
                   }}/>
          </Col>
          <Col xs={6} sm={4} md={3}>
            <Field name='completion_phase_id' component={ReactSelectInput}
                   props={{
                     label: I18n.t('ei.processingTypes.form.processCompletionsPhase'),
                     options: phases,
                     textKey: 'name',
                     valueKey: 'id',
                     isRequired: true
                   }}/>
          </Col>
          <Col xs={6} sm={4} md={3}>
            <Field
              component={NumericInput}
              name='default_processing_time'
               props={{
                 fractionPartSize: 0,
                 allowNegativeNumber: false,
                 label: I18n.t('ei.processingTypes.form.standardProcessTime'),
               }}/>
          </Col>
        </Row>
      </FormGroup>
      <hr/>
      <FieldArray
        name='materials'
        component={Ingredients}
        getFormValue={getFormValue}
        ingredients={ingredients}
        getPossibleUom={getPossibleUom}
        calculateTotalCost={calculateTotalCost}
        change={change}
        integrationState={integrationState}
      />
      <FieldArray
        name='directions'
        component={Directions}
        getFormValue={getFormValue}
        change={change}
      />
      <SubmitSection settings={submitSectionSettings}/>
    </form>
  );
};

ProcessingTypeForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  getFormValue: PropTypes.func.isRequired,
  reset: PropTypes.func,
  change: PropTypes.func,
  invalid: PropTypes.bool,
  initialValues: PropTypes.object,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  saving: PropTypes.bool.isRequired,
  phases: PropTypes.array.isRequired,
  equipmentItems: PropTypes.array.isRequired,
  ingredients: PropTypes.array.isRequired,
  getPossibleUom: PropTypes.func.isRequired,
  calculateTotalCost: PropTypes.func.isRequired,
  onChangeSubcategories: PropTypes.func.isRequired,
  isTouch: PropTypes.object.isRequired,
  integrationState: PropTypes.object,
  validInputAndOutputJurisdictionCategories: PropTypes.object.isRequired,
  featureUtQaChangeRequestEnabled: PropTypes.bool,
};

export default ProcessingTypeForm;
