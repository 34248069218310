/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { Button, ButtonToolbar, Col, OverlayTrigger, Popover, Row, } from 'react-bootstrap';
import {FaInfoCircle} from 'react-icons/fa';
import * as p from '../../../constants/permissions';
import PermissionButton from '../../common/PermissionButton';
import TextInput from '../../common/form/TextInput';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import MultiSelectInput from '../../common/form/MultiselectInput';
import InternationalDateTimePickerInput from '../../common/form/InternationalDateTimePickerInput';
import EditIndividualPlants from './EditIndividualPlants';
import AddNoteSection from '../common/AddNoteSection';
import ReduxMetrcIdField from '../../common/form/redux-form/ReduxMetrcIdField';
import SelectPlantsSection from './SelectPlantsSection';
import NumericInput from '../../common/form/NumericInput';
import WillRender from '../../common/concealers/WillRender';
import { plantStages } from '../../../constants/plants';

const isModifiedNotOnlyDate = (formValues) =>
  formValues &&
  formValues.modification_date &&
  (
    !!formValues.stage_id ||
    !!formValues.section_id ||
    !!formValues.strain_id ||
    !!formValues.feeding_schedule_id ||
    !!formValues.report_waste_total ||
    (formValues.tag_requested !== undefined && formValues.tag_requested !== null)
  );

const splitPlantingBatchPopover = () => {
  return (
    <div className='split-batch-label-popover'>
      <label>{I18n.t('plants.modify.splitPlantBatchTag')}</label>
      <OverlayTrigger
        placement='top'
        overlay={
          <Popover id='split_tag_info'>
            <span>{I18n.t('plants.modify.splitPlantBatchTagInfo')}</span>
        </Popover>
      }>
      <FaInfoCircle/>
      </OverlayTrigger>
    </div>
  );
};

/**
 * Modify plant form takes on many forms
 * If the "num_plants" field is present, this is more accurate
 * selectedPlants contains the whole batch when entering the "Plant Modification" page without any plants selected on the
 * "Plants Listing" page
 * formValues.num_plants comes in as a string, so it's cast to an int if available
 * @param formValues
 */
const getPlantCount = (formValues) => {
  const numPlants = formValues.num_plants && parseInt(formValues.num_plants)
    ? parseInt(formValues.num_plants)
    : 0;

  if (numPlants) {
    return numPlants;
  }

  return formValues.selectedPlants && formValues.selectedPlants.length
    ? formValues.selectedPlants.length
    : 0;
};

const ModifyPlantForm = (props) => {
  const {
    strains,
    currentStage,
    notEnoughTags,
    handleTagChange,
    metrcTags,
    metrcTagEnd,
    complianceSettings,
    locations,
    selectableLocations,
    batches,
    plants,
    schedules,
    selectedPlants,
    form,
    stages,
    handleSubmit,
    itemsPerPage,
    switchPage,
    activePage,
    reset,
    submitting,
    trackingIds,
    initialValues,
    change,
    array,
    saveAndPrint,
    saveOnePlant,
    toggleInvPlants,
    moreTagsButton,
    showInvPlants,
    onPlantFilterChange,
    showBulkFields,
    hideRange,
    hideBatch,
    saveComplete,
    calculatePlantsWaste,
    currentStageCode,
    validatePlantCompliance,
    submitDisabled,
    formValues,
    scannedLocations,
    currentlySelectedLocationIds,
    selectedBatchName,
    integrationState,
    hasPlantsTags,
    onChangeRequestTag,
    plantCupos,
    splitPlantBatch,
    showStrainField,
    isUtahGlobalIdGenerationFeatureEnabled
  } = props;

  const hasEnoughTags = !notEnoughTags;
  const maxPlants = parseInt(props.maxPlants);
  const showTagsElement = () => {
    change('beginning_tag', '');
    props.showTagsElement();
  };

  const activeSelection = selectedPlants && selectedPlants.length ? selectedPlants : plants;
  const plantCount = getPlantCount(formValues);
  const propagationStageId = plantStages.propagation.value;
  const propagationStageCount = activeSelection.filter((plants) => plants.stage_id === propagationStageId).length;
  const plantsStagesPresent = activeSelection.reduce((acc, plant) => {
    if (acc.indexOf(plant.stage_id) === -1) {
      acc.push(plant.stage_id);
    }
    return acc;
  }, []);

  // If we don't have enough tags or any plants shouldn't we be throwing an error?  I think we may want to expand
  // how we handle this and lock out the UI with an error message if we don't already do this
  const hasMetrcTags = integrationState.isMetrc && hasEnoughTags && plantCount;

  const trackPlantsAsGroup =
    complianceSettings.cult_track_plants_as_groups && complianceSettings.cult_track_plants_as_groups.value;
  const allPropagation = activeSelection.length === propagationStageCount;
  const propagationMixCatch = integrationState.isMetrc && !allPropagation &&
    plantsStagesPresent.length > 1 && plantsStagesPresent.indexOf(propagationStageId) !== -1;

  // the plantStages objects have different names from the form options in the dropdown, so we need to define the mature array
  // for both set of values for determining if plants are in mature stages or not.
  const maturePhases = integrationState.isMetrc && integrationState.isCaMetrc ? [plantStages.flowering] : [plantStages.flowering, plantStages.vegetation];
  const matureFormPhases = integrationState.isMetrc && integrationState.isCaMetrc ? ['flower'] : ['flower', 'veg'];

  // We need to find if we are editing only immature plants, the only circumstance where we assign tags is when the plants are immature
  // and we are attempting to move to a mature stage.  This is when Metrc needs to track each plant with a tracking label.
  const immaturePlants = activeSelection.filter((plant) => !maturePhases.some(phase => phase.value === plant.stage_id));
  const allImmaturePlants = immaturePlants.length === activeSelection.length;

  // Use the value from the dropdown to determine if we are trying to move to a mature stage from an immature stage
  const isMaturePhase = (integrationState.isMetrc && matureFormPhases.find(phase => phase === currentStageCode));
  const isTrackablePhase = (allImmaturePlants && isMaturePhase) || false;

  // If we have the tags available and we are moving to a trackable plant phase from a non-tracked plant phase
  // we need to show the tracking label selection options
  const useMetrcTags = !!(hasMetrcTags && isTrackablePhase);
  const metrcTagRequired = !!(integrationState.isMetrc && isTrackablePhase);


  const filterStagesOption = () => {
    if (integrationState.isWaLeaf) {
      return stages.filter((stage) => {
        return stage.code !== 'prop';
      });
    } else {
      return stages;
    }
  };

  // not shown in a metrc facility when modifying an immature plant, so if there are any mature plants
  // we should be showing the modification button
  const showIndividualModificationButton = (!integrationState.isMetrc || !allImmaturePlants);
  const showSplitPlantBatchField = (splitPlantBatch && integrationState.isCaMetrc);

  const disableButtonDueToMetrcTags = metrcTagRequired
    ? !(formValues.beginning_tag && metrcTagEnd)
    : false;



  const saveButtonText = plantCount === 1
    ? I18n.t('plants.actions.modifyPlant')
    : I18n.t('plants.actions.modifyGroup');

  return (
    <form className='modify-plant-form' onSubmit={handleSubmit} noValidate={true}>
      <SelectPlantsSection
        change={change}
        showBulkFields={showBulkFields}
        onPlantFilterChange={onPlantFilterChange}
        locations={locations}
        hideBatch={hideBatch}
        batches={batches}
        useMetrcTags={useMetrcTags}
        propagationMixCatch={propagationMixCatch}
        hideRange={hideRange}
        plants={plants}
        currentlySelectedLocationIds={currentlySelectedLocationIds}
        selectedBatchName={selectedBatchName || []}
        scannedLocations={scannedLocations}
      />
      <h4>{I18n.t('plants.modify.plantAdjustments')}</h4>
      <Row className='form-fields-row'>
        <Col className='form-fields-col' xs={8} md={3} lg={3}>
          <Field
            style={integrationState.isCaMetrc && currentStage === 3 ? { display: 'none' } : {}}
            name='stage_id'
            component={ReactSelectInput}
            props={{
              label: I18n.t('plants.form.growthPhase'),
              options: filterStagesOption(),
              disabled: integrationState.isCaMetrc && currentStage === 3,
              textKey: 'stage_name',
              valueKey: 'id',
              onBlur: () => {
                if (useMetrcTags && formValues && !formValues.beginning_tag) {
                  change('beginning_tag', null);
                }
              },
              onChange: (value) => {
                change('stage_id', value);
                onPlantFilterChange('stage_id', value);
                validatePlantCompliance(value);
                if (propagationMixCatch) {
                  change('propogation_catch', I18n.t('plants.modify.invalidSelection'));
                }
              },
              placeholder: I18n.t('common.form.noChange')
            }}
          />
          {integrationState.isCaMetrc && currentStage === plantStages.flowering.value ? (
            <div
              className='alert alert-danger'
              style={{ padding: '5px 8px 5px 5px', position: 'relative', top: '-15px' }}
            >
              Plants may not be moved from Flowering to a less mature stage.
            </div>
          ) : null}
        </Col>
        <Col className='form-fields-col' xs={8} md={3} lg={3}>
          <Field
            name='section_id'
            component={ReactSelectInput}
            props={{
              label: I18n.t('plants.form.changeLocation'),
              options: selectableLocations,
              textKey: 'name',
              valueKey: 'id',
              placeholder: I18n.t('common.form.noChange'),
              onBlur: () => {
                if (splitPlantBatch && formValues && !formValues.split_plant_batch_tag) {
                  change('split_plant_batch_tag', null);
                }
              }
            }}
          />
        </Col>
        {useMetrcTags ? (
          <Col className='form-fields-col' xs={8} md={3} lg={3}>
            <div className='form-group'>
              <ReduxMetrcIdField
                name='beginning_tag'
                props={{
                  options: metrcTags,
                  label: I18n.t('plants.form.beginTag'),
                  textKey: 'text',
                  valueKey: 'text'
                }}
                onBlur={() => {
                  if (formValues && !formValues.beginning_tag) {
                    change('beginning_tag', null);
                  }
                }}
                onChange={(event, value) => {
                  const tag = metrcTags.find((tag) => tag.text === value);
                  const isQueriedTag = value && !tag;
                  handleTagChange(tag, plantCount, isQueriedTag, value);
                }}
              />
            </div>
          </Col>
        ) : null}
        {useMetrcTags ? (
          <Col className='form-fields-col' xs={8} md={3} lg={3}>
            <div className='form-group'>
              <Field
                name='ending_tag'
                component={TextInput}
                readOnly
                props={{
                  value: metrcTagEnd,
                  readOnly: true,
                  isRequired: true,
                  label: 'Ending tag'
                }}
              />
            </div>
          </Col>
        ) : null}
        {notEnoughTags ? (
          <Col className='form-fields-col not-enough-tags' xs={8} md={6} lg={6}>
            <div className='form-group'>
              <Button onClick={moreTagsButton} className='btn btn-primary'>
                {I18n.t('plants.modify.moreIdsNeeded')}
              </Button>
            </div>
            <div className='form-group'>
              <Button onClick={showTagsElement} className='btn btn-primary'>
                {I18n.t('plants.modify.showTagsElement')}
              </Button>
            </div>
          </Col>
        ) : null}
        {propagationMixCatch ? (
          <Col className='form-fields-col' xs={6} md={3} lg={3}>
            <div className='form-group'>
              <Field
                name='propogation_catch'
                component={TextInput}
                props={{
                  value: I18n.t('plants.modify.invalidSelection'),
                  isRequired: true,
                  readOnly: true,
                  placeholder: I18n.t('plants.modify.invalidSelection'),
                  label: I18n.t('plants.modify.propagationMixCatch')
                }}
              />
            </div>
          </Col>
        ) : null}

        <Col className='form-fields-col' xs={8} md={3} lg={3}>
          <Field
            name='feeding_schedule_id'
            component={ReactSelectInput}
            props={{
              label: I18n.t('plants.modify.newFeedingSchedule'),
              options: schedules,
              textKey: 'schedule_name',
              valueKey: 'id',
              placeholder: I18n.t('common.form.noChange')
            }}
          />
        </Col>
        {!integrationState.isBiotrack && (
          <Col className='form-fields-col' xs={6} md={2} lg={2}>
            <Field
              name='report_waste_total'
              component={NumericInput}
              props={{
                label: I18n.t('plants.modify.reportWaste'),
                rightAddon: <span>G</span>,
                max: 99999,
                min: 0,
                placeholder: I18n.t('plants.form.quantityPlaceholder'),
                onChange: calculatePlantsWaste
              }}
            />
          </Col>
        )}
        {showStrainField && (
          <Col className='form-fields-col' xs={8} md={3} lg={3}>
            <Field
              name='strain_id'
              component={ReactSelectInput}
              props={{
                label: I18n.t('plants.modify.changeStrain'),
                options: strains,
                textKey: 'strain_name',
                valueKey: 'id',
                placeholder: I18n.t('common.form.noChange'),
              }}
              onBlur={() => {
                if (splitPlantBatch && formValues && !formValues.split_plant_batch_tag) {
                  change('split_plant_batch_tag', null);
                }
              }}
            />
          </Col>
        )}
      </Row>
      <Row className='form-fields-row'>
        <Col xs={3} md={3}>
          <Field
            name='num_plants'
            component={NumericInput}
            props={Object.assign(
              {},
              {
                label: trackPlantsAsGroup ? I18n.t('plants.modify.groupCount') : I18n.t('plants.modify.plantCount'),
                readOnly: isNaN(maxPlants),
                onBlur: (e) => {
                  // Cannot access the field event in middleware so...
                  const value = parseInt(e.currentTarget.value);
                  if (isNaN(value)) {
                    change('num_plants', 1);
                  }
                }
              },
              isNaN(maxPlants) ? { value: selectedPlants.length } : {}
            )}
          />
          <WillRender ifTrue={!isNaN(maxPlants)}>
            <div style={{ fontSize: 'smaller', position: 'relative', top: '-5px' }}>
              {I18n.t('plants.modify.minMax', { min: 1, max: maxPlants })}
              <br />
              {I18n.t('plants.modify.numPlantsNote')}
            </div>
          </WillRender>
        </Col>
        <Col xs={6} md={3}>
          <InternationalDateTimePickerInput
            name='modification_date'
            props={{
              label: I18n.t('plants.modify.modificationDate'),
              isRequired: true
            }}
          />
        </Col>


        {
        !isUtahGlobalIdGenerationFeatureEnabled &&
        (
          ((hasPlantsTags && (currentStageCode === 'flower' || currentStageCode === 'veg')) ||
           (hasPlantsTags && currentStageCode === undefined && ( currentStage === 1 || currentStage === 3)))
        ) ?
        <Col xs={6} md={3}>
          <Field
            name='tag_requested' component={ReactSelectInput}
            props={{
              label: I18n.t('plants.modify.needsTag'),
              options: [{text : I18n.t('common.yes'), value : 1} , {text : I18n.t('common.no'), value :  0}],
              textKey: 'text',
              valueKey: 'value',
              placeholder: I18n.t('common.form.noChange'),
              onChange: onChangeRequestTag
            }} />
        </Col>
        : null
        }

        {integrationState.isColombia ? (
          <Col xs={6} md={3}>
            <Field
              disabled
              name='plant_cupos'
              component={MultiSelectInput}
              props={{
                label: I18n.t('cupos.plantCupos'),
                options: plantCupos,
                textKey: 'cupo_number',
                valueKey: 'id'
              }}
            />
          </Col>
        ) : null}
      </Row>
      <Row>
        <Col xs={6}>
          <AddNoteSection />
        </Col>
      </Row>
      {formValues && formValues.modification_date && !isModifiedNotOnlyDate(formValues) ? (
        <Row>
          <Col xs={12}>{I18n.t('plants.modify.moreThanDate')}</Col>
        </Row>
      ) : null}

      {/* Plant batch specific fields*/}
      {splitPlantBatch ? (
        <div>
          <h4>{I18n.t('plants.modify.plantBatchAdjustments')}</h4>
          <Row className='form-fields-row'>
            <Col className='form-fields-col' xs={8} md={3} lg={2}>
              <Field
                name='batch_name'
                component={TextInput}
                readOnly
                props={{
                  label: I18n.t('plants.modify.batchName'),
                }}
              />
            </Col>

            {showSplitPlantBatchField ? (
              <Col className='form-fields-col' xs={8} md={3} lg={3}>
                <Field
                  name='split_plant_batch_tag'
                  component={ReactSelectInput}
                  props={{
                    customLabel: splitPlantingBatchPopover(),
                    options: metrcTags,
                    textKey: 'text',
                    valueKey: 'text',
                    isRequired: true,
                  }}
                  onBlur={() => {
                    if (formValues && !formValues.split_plant_batch_tag) {
                      change('split_plant_batch_tag', null);
                    }
                  }}
                />
              </Col>
            ) : null}
          </Row>
        </div>
        ) : null}
      <hr />
      <Row>
        <Col xs={12}>
          {showIndividualModificationButton && (
            <ButtonToolbar className='float-left'>
              <Button variant='primary' type='button' onClick={toggleInvPlants}>
                {I18n.t('packages.modify.individualModification')}
              </Button>
            </ButtonToolbar>
          )}
          <ButtonToolbar className='float-right'>
            <Button
              type='reset'
              disabled={saveComplete}
              onClick={() => {
                reset();
                if (showBulkFields) {
                  change('building_ids', []);
                  change('room_ids', []);
                  change('zone_ids', []);
                  change('location_id', []);
                  change('selected_batch_name', []);
                  onPlantFilterChange('location_id', [{ id: 0 }]);
                }
              }}
            >
              {I18n.t('packages.form.reset')}
            </Button>
            <PermissionButton
              permissions={[p.print_labels]}
              props={{
                variant: 'info',
                type: 'submit',
                disabled: plants && plants.length === 0 && (selectedPlants && selectedPlants.length === 0),
                onClick: saveAndPrint
              }}
            >
              {saveComplete ? I18n.t('common.printLabel') : I18n.t('common.saveAndPrintLabel')}
            </PermissionButton>
            <Button
              variant='primary'
              disabled={
                saveComplete ||
                submitting ||
                propagationMixCatch ||
                submitDisabled ||
                !isModifiedNotOnlyDate(formValues) ||
                disableButtonDueToMetrcTags

              }
              type='submit'
            >
              {saveButtonText}
            </Button>
          </ButtonToolbar>
        </Col>
      </Row>
      <EditIndividualPlants
        selectedPlants={selectedPlants}
        strains={strains}
        switchPage={switchPage}
        formName={form}
        initialValues={initialValues}
        change={change}
        splice={array.splice}
        saveOnePlant={saveOnePlant}
        itemsPerPage={itemsPerPage}
        currentStage={currentStage}
        integrationState={integrationState}
        trackingIds={trackingIds}
        activePage={activePage}
        complianceSettings={complianceSettings}
        showInvPlants={showInvPlants}
        hasPlantsTags={hasPlantsTags}
        currentStageCode={currentStageCode}
        isUtahGlobalIdGenerationFeatureEnabled={isUtahGlobalIdGenerationFeatureEnabled}
      />
    </form>
  );
};

ModifyPlantForm.propTypes = {
  saveComplete: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  array: PropTypes.object.isRequired,
  selectableLocations: PropTypes.array.isRequired,
  schedules: PropTypes.array.isRequired,
  stages: PropTypes.array.isRequired,
  strains: PropTypes.array.isRequired,
  locations: PropTypes.array,
  form: PropTypes.string,
  batches: PropTypes.array.isRequired,
  plants: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  saveAndPrint: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  selectedPlants: PropTypes.array,
  includedPlants: PropTypes.array,
  initialValues: PropTypes.object.isRequired,
  saveOnePlant: PropTypes.func.isRequired,
  toggleInvPlants: PropTypes.func.isRequired,
  showInvPlants: PropTypes.bool.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  activePage: PropTypes.number.isRequired,
  switchPage: PropTypes.func.isRequired,
  onPlantFilterChange: PropTypes.func.isRequired,
  showBulkFields: PropTypes.bool.isRequired,
  hideRange: PropTypes.bool,
  hideBatch: PropTypes.bool,
  notEnoughTags: PropTypes.bool,
  handleTagChange: PropTypes.func,
  moreTagsButton: PropTypes.func,
  showTagsElement: PropTypes.func,
  integrationState: PropTypes.object.isRequired,
  trackingIds: PropTypes.array.isRequired,
  metrcTags: PropTypes.array,
  metrcTagEnd: PropTypes.string,
  currentStage: PropTypes.number,
  propagationMixCatch: PropTypes.bool,
  validatePlantCompliance: PropTypes.func.isRequired,
  submitDisabled: PropTypes.bool.isRequired,
  formValues: PropTypes.object.isRequired,
  calculatePlantsWaste: PropTypes.func.isRequired,
  scannedLocations: PropTypes.array,
  currentlySelectedLocationIds: PropTypes.object,
  selectedBatchName: PropTypes.array,
  currentStageCode: PropTypes.string,
  complianceSettings: PropTypes.object.isRequired,
  maxPlants: PropTypes.string,
  hasPlantsTag: PropTypes.bool,
  onChangeRequestTag: PropTypes.func,
  plantCupos: PropTypes.array,
  hasPlantsTags: PropTypes.bool,
  splitPlantBatch: PropTypes.bool,
  showStrainField: PropTypes.bool,
  isSequentialTrackingTagCheckToggled: PropTypes.bool,
  isUtahGlobalIdGenerationFeatureEnabled: PropTypes.bool,
};

export default ModifyPlantForm;
