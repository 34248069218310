import * as types from '../constants/actionTypes';
import initialState from '../constants/initialState';

export default function filterReducer (state = initialState.productFilter, action){
  switch (action.type) {
  case types.SET_PRODUCT_FILTER:
    return action.filter;
  default:
    return state;
  }
}
