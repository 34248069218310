import {createSelector} from 'reselect';
import moment from 'moment';
import get from 'lodash.get';
import {LEAF} from '../constants/imageUrls';
import * as itemNames from '../constants/itemNames';
import * as dataNames from '../constants/dataNames';
import {getCurrentLocation} from './usersSelectors';
import {getFlatTopLocations} from './locationsSelectors';
import {getTaxes} from './taxesSelectors';
import {getImageUrl} from '../util/images';
import {getCureOrderProducts} from './integration/cureApiSelectors';
import {getTotalResults} from './paginationSelectors';
import {
  convertDbDateTimeToFormInputDateTime,
  formatClientDate,
  getToday,
  getFourWeeksAgo
} from '../util/dateHelpers';
import {getIntegrationState} from './integration/integrationSelectors';
import {getPrePackWeights} from './fillPurchaseOrderSelectors';
import {getProductType} from './itemMastersSelectors';
import {convertFromBase,isBulkType} from '../util/uomHelpers';
import {getOhMetrcMappingByCategoryCode} from './integration/metrcSelectors';

export const getOrder = state => state.order;
export const getReceiptOrder = state => state[itemNames.receiptOrder];
export const getOrders = state => state.orders;
const getPricingWeights = state => state.pricingWeights;
const getPrepackWeights = state => state.prepackWeights;
const getInventoryItems = state => state.inventoryItems;
const getAvailableCoupons = state => state.coupons;
const getAvailableRewards = state => state.rewards;
const getImages = state => state.images;
const getItemMasters = state => state.itemMasters;
const getActivePage = (_, props) => props.activePage;
const getItemsPerPage = (_, props) => props.itemsPerPage;
const getItemsAvailability = state => state[dataNames.itemsAvailability];
const getCustomersPurchasedAmounts = state => state[itemNames.customersPurchasedAmounts];

// These return the base coupon used in the order, not the coupon line item from the order
const getCouponsInOrder = state => state[dataNames.couponsInOrder];
const getRewardsInOrder = state => state[dataNames.rewardsInOrder];

const getId = (_, props) => {
  // Solves a looping issue when props is undefined - originally assumed props was always populated
  return (props !== undefined) ? props.id : undefined;
};
const getOrderId = (_, props) => props && props.order_id ? props.order_id : -1;

export const getOrderById = createSelector(
  [getOrderId, getOrders],
  (id, orders) => orders.reduce((previous, current) => current.id === id ? current : previous, null)
);

const getCureProductInfo = (cureOrderProducts, orderProduct, orderId) => {
  return cureOrderProducts.find(cureProduct => Number(cureProduct.order_product_id) === orderProduct.id && Number(cureProduct.order_id) === orderId);
};

export const getInventoryItem = createSelector(
  getId, getOrder,
  (itemId, order) => {
    let inventoryItem = undefined;
    if (order && order.products && order.products.length) {
      order.products.forEach(product => {
        if (product.items && product.items.length) {
          product.items.forEach(item => {
            if (item.item_id == itemId) {
              inventoryItem = item;
            }
          });
        }
      });
    }
    return inventoryItem;
  }
);

const getOrderProducts = createSelector(
  getOrder, order => order && Array.isArray(order.products) ? order.products : []
);

const getOrderProductById = createSelector(
  getId, getOrderProducts, (orderProductId, products) =>
    products && Array.isArray(products) && products.find(product => product.id == orderProductId)
);

const getProductFulfillmentStatus = (product, pricingWeights, prepackWeights) => {
  let amountRequested = undefined, amountFulfilled = 0;
  if (product.sold_weight) {
    amountRequested = product.sold_weight;
  }
  else {
    amountRequested = product.quantity;
  }
  if (product && product.items && product.items.length) {
    amountFulfilled = product.items.reduce((total, current) => {
      const prepackWeight = current.prepack_weight_id && prepackWeights.find(
        weight => weight.id === current.prepack_weight_id
      );
      if (prepackWeight) {
        const fulfillmentWeightByUom = convertFromBase(prepackWeight.weight_base, prepackWeight.uom);
        return prepackWeight && prepackWeight.weight_base && current.qty
          ? Math.round(10000 * (current.qty * fulfillmentWeightByUom + total)) / 10000
          : total;
      }
      else {
        return current.qty
          ? current.qty + total
          : total;
      }
    }, 0);
  }

  return {
    amountRequested,
    amountFulfilled,
    isFulfilled: Boolean(
      amountRequested && amountFulfilled &&
      (isBulkType(product.product_type) ? amountFulfilled >= amountRequested : amountFulfilled === amountRequested)
    ),
    needLimitValidation: !isBulkType(product.product_type) && amountFulfilled >= amountRequested
  };
};

export const getProductFulfillmentStatusById = createSelector(
  [getOrderProductById, getPricingWeights, getPrepackWeights],
  getProductFulfillmentStatus
);

export const isOrderFulfilled = createSelector(
  [getOrder, getOrderProducts, getPricingWeights, getPrepackWeights],
  (order, products, pricingWeights, prepackWeights) => {
    if(order.fulfillment_method === 'delivery' && order.fulfillment_status === 'assigned') return true; // This is straight from queue and should be definitive
    return products.length > 0 && products.every(product => getProductFulfillmentStatus(product, pricingWeights, prepackWeights).isFulfilled);
  }
);

export const getOrderProductItems = createSelector(
  getId, getOrder,
  (orderProductId, order) => {
    const items = [];
    if (order && order.products && order.products.length) {
      const product = order.products.find(product => product.id == orderProductId);
      if (product && product.items && product.items.length) {
        return [...product.items];
      }
    }
    return items;
  }
);

export const couponIsAppliedToAllProducts = createSelector(
  getId, getOrder,
  (couponId, order) => {
    let couponIsAppliedToAllProducts = false;
    if (order && order.products && order.products.length && order.coupons && order.coupons.length) {
      const coupon = order.coupons.find(orderCoupon => orderCoupon.id == couponId);
      if (coupon) {
        couponIsAppliedToAllProducts = true;
        order.products.forEach(product => {
          if (!(coupon.allocations && coupon.allocations.find(allocation => allocation.order_product_id == product.id))) {
            couponIsAppliedToAllProducts = false;
          }
        });
      }
    }
    return couponIsAppliedToAllProducts;
  }
);

export const getOrderProductCoupons = createSelector(
  getId, getOrder,
  (orderProductId, order) => {
    const coupons = [];
    if (order && order.coupons && order.coupons.length) {
      return order.coupons.filter(coupon => {
        let hasAllocation = false;
        if (coupon.allocations && coupon.allocations.length) {
          coupon.allocations.forEach(allocation => {
            if (allocation.order_product_id == orderProductId) {
              hasAllocation = true;
            }
          });
        }
        return hasAllocation;
      });
    }
    return coupons;
  }
);

export const getOrderMmapPayments = createSelector(
  [getOrder],
  (order) => {
    if (!order) {
      return [];
    }
    return (order && order.payments || []).filter(payment => payment.payment_type === 'mmap' && payment.is_cancelled === 0);
  }
);

export const getItemsGroupedByProduct = createSelector(
  [getInventoryItems],
  (inventoryItems) => {

    const items = {};

    if (inventoryItems && inventoryItems.length) {
      inventoryItems.forEach(item => {
        item.packageName = item.package_code ? item.package_code + ' - ' + item.location_name : item.location_name;
        if(!items[item.item_master_id]){
          items[item.item_master_id] = [item];
        }else{
          items[item.item_master_id].push(item);
        }

        if(!items[item.item_master_parent_id]) {
          items[item.item_master_parent_id] = [];
        }
        if (item.items && item.items.length) {
          item.items.forEach(i => {
            i.packageName = i.package_code
              ? i.prepack_name + ' - ' + i.package_code + ' - ' + i.location_name
              :  i.location_name;
            items[i.item_master_parent_id].push(item);
          });
        }
      });
    }

    return items;
  });


const getItemsWithTestResults = (state) => state[dataNames.testResults];
const getSalesComplianceSettings = (state) => state[itemNames.salesComplianceSettings];

export const getFilteredItems = createSelector(
  [getItemsGroupedByProduct, getItemsWithTestResults, getIntegrationState, getItemMasters, getSalesComplianceSettings, getOhMetrcMappingByCategoryCode('concentrate'), getItemsAvailability, getOrder],
  (items, itemsWithTestResults, integrationState, itemMasters, salesSettings, ohMetrcConcentrateMapping, itemsAvailability, order) => {

    const order_products = get(order, 'products', []);

    const filteredItems = {};
    Object.keys(items).map(id => {
      const imItems = items[id];
      if(imItems && imItems.length){
        filteredItems[id] = imItems.map((item, index) => {
          const isMedicated = itemMasters.find( m => m.id === item.item_master_id && m.category_id !== 6);
          const hasTestResults = itemsWithTestResults.find((testResultItem) => testResultItem.item_id === item.id);
          const hasPassedTestResults = itemsWithTestResults.find((testResultItem) => testResultItem.item_id === item.id && testResultItem.lab_result && testResultItem.lab_result.status === 'passed');
          const parentItemMaster = item.item_master_parent_id && itemMasters.find(m => m.id === item.item_master_parent_id);
          const isOhMetrcConcentrate = integrationState.isOhMetrc && parentItemMaster && parentItemMaster.subcategory_id && ohMetrcConcentrateMapping.some(mapping => mapping.subcategory_id === parentItemMaster.subcategory_id);
          const limitMethod = get(salesSettings, 'order_sales_limit_method.value', '');
          const disabledForCure = integrationState.isCure && isMedicated && !hasTestResults;
          const disabledForOhMetrc = integrationState.isOhMetrc && limitMethod === 'equivalency' && item.is_prepack && isOhMetrcConcentrate && !hasPassedTestResults;
          const availability = itemsAvailability.find( a => a.item_id === item.id );
          const qty_reserved_base = get(availability,'qty_reserved_base',0);
          const qty_reserved_uom = get(availability,'uom_display','GR');
          const qty_reserved_on_order = get(availability,'qty_reserved_on_order',0);

          let qty_reserved = 0;
          if(get(item,'prepack_weight_base',null)){
            qty_reserved = convertFromBase(qty_reserved_base / item.prepack_weight_base,item.uom_display);
          } else {
            qty_reserved = convertFromBase(qty_reserved_base,item.uom_display);
          }

          // Get relevant order product items
          const order_product = item.item_master_parent_id
            ? order_products.find(op => op.item_master_id === item.item_master_parent_id)
            : order_products.find(op => op.item_master_id === item.item_master_id);
          const order_product_items = get(order_product, 'items', []);
          const order_product_item = order_product_items.find(opi => opi.item_id === item.id );
          const qty_fulfilled_on_order = order_product_item
            ? get(order_product_item, 'qty', 0)
            : 0;
          const qty_soft_reserved_on_order = qty_reserved_on_order - qty_fulfilled_on_order;
          const qty_available = item.qty - qty_reserved + qty_soft_reserved_on_order;

          return {
            ...item,
            qty_reserved_base,
            qty_reserved_uom,
            qty_reserved,
            qty_available,
            packageName: item.packageName + ` (${qty_available})`,
            disabled: Boolean(disabledForCure || disabledForOhMetrc),
          };
        });
      }
    });
    return filteredItems;
  }
);

function mapOrderTaxes(order, taxes) {
  const orderTaxes = get(order, 'taxes', []);
  return orderTaxes.length === 0 ? [] : orderTaxes.map(orderTax => ({
    ...orderTax,
    tax: taxes.find(tax => tax.id === orderTax.tax_id),
  }));
}

export const getOrderTaxes = createSelector(
  [getOrder, getTaxes],
  mapOrderTaxes
);

export const getReceiptOrderTaxes = createSelector(
  [getReceiptOrder, getTaxes],
  (order, taxes) => mapOrderTaxes(order && order.order || {}, taxes)
);

export const getReceiptOrderWithTaxes = createSelector(
  [getReceiptOrder, getReceiptOrderTaxes],
  (order, taxes) => {
    if (order && order.order) {
      return {
        ...order,
        order: {
          ...order.order,
          taxes,
        }
      };
    }
    return order;
  }
);

export const getOrderWithRichProducts = createSelector(
  [getOrder, getImages, getItemMasters, getCurrentLocation, getFlatTopLocations, getOrderTaxes, getCureOrderProducts],
  (order, images, item_masters, userLocation, salesLocations, taxes, cureProducts) => {
    const prods = order.products ? order.products : [];
    const newOrder = {...order, taxes};
    if(item_masters && images) {
      const newProds = prods.map(product => {
        const item_master = item_masters.find(item_master => item_master.id === product.item_master_id);
        const image = item_master ? images.find(image => image.id === item_master.primary_product_image_file_id) : undefined;
        const src = getImageUrl(image, '30x30', LEAF);
        if(product.items.length > 0){
          product.package_id = product.items[0].package_id;
        }
        product.medicated_weight_base = item_master ? item_master.medicated_weight_base : 0;
        product.medicated_weight_uom_display = item_master ? item_master.medicated_weight_uom_display : 0;
        // Set a default value for the Sales Location input to match the user location.
        if (userLocation && Array.isArray(salesLocations) && salesLocations.find(location => location.id === userLocation)) {
          product.addItem = {location: userLocation};
        }

        let productType = null;

        if(item_master){
          productType = getProductType(item_master);
        }else{
          if(product.product_type != 'EA'){
            if(product.items.find(d => d.prepack_weight_id == null) ){
              productType = 'bulk';
            }
          }
        }

        product.cure_order_info = getCureProductInfo(cureProducts, product, order.id);
        product.product_type = productType == 'bulk' ? 'weight' : productType;

        return {...product, src};
      });
      newOrder.products = newProds;
    }
    return newOrder;
  }
);

export const getCouponLines = createSelector([getAvailableCoupons, getOrder, getId], (coupons, order, couponId) => {
  if(coupons && order && couponId){
    const coupon = coupons.find(coupons => coupons.id === couponId);
    if(coupon && coupon.valid_for_lines) {
      return coupon.valid_for_lines.map(id => order.products.find(product => product.id === id));
    }
  }
  return undefined;
});

export const getRewardLines = createSelector([getAvailableRewards, getOrder, getId], (rewards, order, couponId) => {
  if(rewards && order && couponId){
    const reward = rewards.find(reward => reward.id === couponId);
    if(reward && reward.valid_for_lines) {
      return reward.valid_for_lines.map(id => order.products.find(product => product.id === id));
    }
  }
  return undefined;
});

export const getChangePaymentInitialValues = createSelector(
  getOrder, order => {
    return order && Array.isArray(order.payments)
      ? ({
        orderId: order.id,
        refund_register_transactions: 1,
        payments: order.payments.filter(payment => !payment.is_cancelled && !payment.is_pending && payment.payment_type !== 'mmap').map(payment => ({
          amount: payment.amount,
          payment_type: payment.payment_type,
          register_id: payment.register_id,
          order_type: order.order_type
        }))
      })
      : {};
  }
);

export const getOrderDateInitialValues = createSelector(
  getOrder, order => {
    return order
      ? ({
        orderId: order.id,
        fulfillment_status_date: order.fulfillment_status_date,
        order_status_date: order.order_status_date,
        completed_date: order.completed_date,
      })
      : {};
  }
);


export const getMmapPaymentsInitialValues = createSelector(
  getOrder, order => {
    return order && Array.isArray(order.payments)
      ? ({
        orderId: order.id,
        mmapPayments: order.payments.filter(payment => !payment.is_cancelled && payment.payment_type === 'mmap').map(payment => ({
          amount: payment.amount,
          payment_type: payment.payment_type,
          register_id: payment.register_id,
          id: payment.id
        }))
      })
      : {};
  }
);

export const getOrderWithProductMappings = createSelector(
  [getOrder, getCureOrderProducts],
  (order, cureOrderProducts) => ({
    ...order,
    products: (order && order.products || []).map((orderProduct) => {
      const cure_order_product = getCureProductInfo(cureOrderProducts, orderProduct, order.id);
      return {
        ...orderProduct,
        cure_order_product,
      };
    }),
  })
);

/***
 * Configurable initial values for order meta data
 */
export const getOrderMetaInitialValues = createSelector(
  [getOrder],
  (order) => {
    const fieldList = {
      note: null,
      planned_route: null,
      driver_id: null,
      arrival_time: null,
      departure_time: moment(),
      delivery_address_id: null,
      onfleet_phone_number: null,
    };
    const handler = (result, fieldName) => {
      result[fieldName] = order[fieldName] || fieldList[fieldName];

      return result;
    };

    return Object
      .keys(fieldList)
      .reduce(handler, {});
  });

/***
 * Adds coupon details to the order coupons for rendering the coupons table.
 * @type {Reselect.Selector<TInput, TOutput>}
 */
export const getDetailedOrderCoupons = createSelector([getOrder, getCouponsInOrder, getRewardsInOrder], (order, coupons, rewards) => {
  if(!order.coupons || !Array.isArray(order.coupons)) return [];
  return order.coupons.map((coupon, index) => {
    coupon.fullCoupon = [coupons, rewards][coupon.type === 'coupon' ? 0 : 1]
      .reduce((acc, couponItem) => {
        if(acc) return acc;
        if(couponItem.id === coupon.coupon_id) acc = couponItem;
        return acc;
      }, undefined);
    return coupon;
  }).map((coupon) => {
    order.products.forEach((product, productIndex) => {
      if (coupon.target_order_product_id === product.id ) {
        coupon.target_order_product_name = order.products[productIndex].name;
      }
    });
    return coupon;
  });
});

export const getRewardPointsOnOrder = createSelector([getDetailedOrderCoupons], (coupons) => {
  return coupons.reduce((acc, coupon) => {
    acc += (coupon.type === 'redemption')
      ? parseInt(coupon.points_used)
      : coupon.type === 'reward' && coupon.fullCoupon
        ? parseInt(coupon.fullCoupon.points_required)
        : 0;
    return acc;
  }, 0);
});

export const getProductsPageData = createSelector([getTotalResults, getActivePage, getItemsPerPage], (total, activePage, itemsPerPage) => {
  const pagesCount = Math.ceil(total / itemsPerPage);
  const start = itemsPerPage * (activePage - 1) < total ? itemsPerPage * (activePage - 1) : total - 1;
  const end = itemsPerPage * activePage < total ? itemsPerPage * activePage : total;
  return {
    start: start + 1,
    end,
    total,
    pagesCount
  };
});

export const getRestockTransactionIds = createSelector([getOrders], (orders) => {

  return orders.reduce((acc, order) => {
    acc.concat(order.products.reduce((acc, product) => {
      product.items.forEach((item) => {
        if(item.restock_transaction_id) acc.push(item.restock_transaction_id);
      });
      return acc;
    }, []));
    return acc;
  }, []);
});

// Used for ProductRows on completed sales orders to show refund information
export const getRefundOrders = createSelector([getOrders, getPrePackWeights], (orders, prepackWeights) => {
  const refundOrders = orders.reduce((acc, order) => {
    if (!order.hasOwnProperty('refunding_orders') || !order.refunding_orders.length) {
      return acc;
    }

    order.refunding_orders.forEach((refundOrder) => {
      if (!acc.includes(refundOrder) && refundOrder.order_status === 'completed') {
        acc.push(refundOrder);
      }
    });
    return acc;
  }, []);

  return refundOrders.reduce((acc, order) => {
    if (order.products.length === 0) {
      return acc;
    }

    order.products.forEach((product) => {
      const refundOnOrderId = '_' + product.order_id;
      if (acc[refundOnOrderId] === undefined) {
        acc[refundOnOrderId] = {name: order.name, products: []};
      }

      acc[refundOnOrderId].products.push({
        refunded_order_product_id: product.refunded_order_product_id,
        refunded_order_product_inventory_id: product.refunded_order_product_inventory_id,
        quantity: _calcProductRefundedQuantity(product, prepackWeights),
        uom: get(product, 'sold_weight_uom_display', 'GR')
      });
    });
    return acc;
  }, {});
});

const _calcProductRefundedQuantity = (product, prepackWeights) => {
  if (product.refunded_inventory && product.refunded_inventory.prepack_weight_id) {
    const prepack_weight = prepackWeights.find(weight => weight.id === product.refunded_inventory.prepack_weight_id);
    if (prepack_weight) {
      return (product.sold_weight_base / prepack_weight.weight_base);
    }
  }
  if (product.sold_weight_base ) {
    return convertFromBase(product.sold_weight_base, product.sold_weight_uom_display);
  }
  return product.quantity;
};

export const getOrdersWithRefunds = createSelector([getOrders, getRefundOrders], (orders, refundOrders) => {
  if(!Array.isArray(orders)) return {};
  return orders.reduce( (acc, order) => {
    let ref;
    const orderRefunds = [];
    if(order.products === undefined) return acc;
    // This assumes there will only be one refund... bad assumption
    const hasRefund = order.products.find((product) => {
      let refund = false;
      for(const prop in refundOrders){
        const found = refundOrders[prop].products.find((p) => {
          return p.refunded_order_product_id === product.id;
        });
        if(found) {
          refund = found;
          ref = refundOrders[prop];
          orderRefunds.push(refundOrders[prop]);
        }

      }
      return refund; // actually returns product
    });
    if(!hasRefund) return acc;
    const key = '_' + order.id;
    acc[key] = ref; // sets the refund data
    return acc;
  }, {});
});

export const getOrdersWithRefundCollections = createSelector([getOrders, getRefundOrders], (orders, refunds) => {
  return orders.reduce( (acc, order) => {
    const orderRefunds = [];
    if(order.products === undefined) return acc;
    const hasRefunds = order.products.filter((product) => {
      let refundFound = false;
      for(const prop in refunds) {
        const refundedProducts = [];
        refunds[prop].products.find((p) => {
          if (p.refunded_order_product_id === product.id) {
            refundedProducts.push(p);
          }
        });
        if (refundedProducts.length > 0) {
          orderRefunds.push({name:refunds[prop].name, products:refundedProducts});
          refundFound = true;
        }
      }
      return refundFound;
    });
    if(!hasRefunds.length) return acc;
    const key = '_' + order.id;
    acc[key] = orderRefunds; // sets the refund data
    return acc;
  }, {});
});

export const getTransactions = (state) => state[dataNames.restockTransactions] ? state[dataNames.restockTransactions].map((t) => t) : [];
const getFacility = (state) => state[itemNames.facility];

export const getOrdersForHistory = createSelector([getOrders, getOrdersWithRefunds, getTransactions, getFacility], (orders, refunds, transactions, facility) => {

  const {timezone} = facility;

  if(!Array.isArray(orders)) return [];

  const getEffectiveOrderDate = (order) => {
    return (order.order_status_date === null)
      ? convertDbDateTimeToFormInputDateTime(order.created_at, timezone)
      : convertDbDateTimeToFormInputDateTime(order.order_status_date, timezone);
  };

  const getSortDate = (order) => {
    return (order.order_status_date === null)
      ? moment(order.created_at).valueOf()
      : moment(order.order_status_date).valueOf();
  };

  const orderHasRefunds = (order) => {
    const key = '_' + order.id;
    return refunds[key] !== undefined;
  };

  const getSalesAssociate = (order) => {
    return (order.completed_by_user_id === null) ? order.started_by_user_name : order.completed_by_user_name;
  };

  return orders.reduce( (acc, order) => {
    if(order.products === undefined) return acc;
    if(order.products.length === 0) return acc;
    if(order.order_status === 'open') return acc;

    acc.push(Object.assign({}, order, {
      hasRefunds: orderHasRefunds(order),
      //products: order.products,
      products: order.products.map((p) => {
        return Object.assign({}, p, {
          items: p.items.map((item) => {
            if (!item.item_transaction_id) return item;
            const restockTransactions = transactions.filter((t) =>
              (t.reference_id === item.item_transaction_id && t.item_id === item.item_id));
            if (restockTransactions) {
              return Object.assign({}, item, {restock_transactions: restockTransactions});
            }
            return item;
          })
        });
      }),
      salesAssociate: getSalesAssociate(order),
      effectiveOrderDate: getEffectiveOrderDate(order),
      sortDate: getSortDate(order)
    }));
    return acc;
  }, []);

});

export const getOrderByIdFromHistory = createSelector([getOrdersForHistory, getOrderId], (orders, orderId) => {
  return orders.find((o) => o.id === parseInt(orderId));
});

export const addPrepackWeightsToRefundOrder = (order, prepackWeights) => {
  if (order && order.products) {
    order.products = order.products.map( product => {
      if (!product.refunded_inventory) return product;
      const prepack = prepackWeights.find(pw => pw.id === product.refunded_inventory.prepack_weight_id);
      if (!prepack) return product;
      return {...product, refunded_inventory: {
        ...product.refunded_inventory,
        prepack_weight: convertFromBase(prepack.weight_base, prepack.uom),
      }};
    });
  }
  return order;
};

export const getComputedProductQuantity = (product) => {
  if (product && product.refunded_inventory && product.refunded_inventory.prepack_weight && product.sold_weight) {
    return Math.round(product.sold_weight / product.refunded_inventory.prepack_weight);
  } else if (get(product, 'uom') === 'GR' || get(product, 'sold_weight_uom') === 'GR') {
    return parseFloat(product.sold_weight ? product.sold_weight : product.quantity);
  }
  return parseInt(product.sold_weight ? product.sold_weight : product.quantity);
};
// END: SELECTORS MOVED FROM Customer Page for order history


// utility method used on Cart page
export const findInventoryItem = (itemId, inventoryItems) => {
  let itemPackage = null;
  if (!itemId || !inventoryItems) {
    return false;
  }
  const inventoryItemsKeys = Object.keys(inventoryItems);
  inventoryItemsKeys.forEach((key) => {
    if (inventoryItems[key] && inventoryItems[key].forEach) {
      inventoryItems[key].forEach((v, i) => {
        if (v.id === itemId) {
          itemPackage = inventoryItems[key][i];
        }
      });
    }
  });
  return itemPackage;
};

// utility method used on Cart page
// detect if selected package has enough available qty for entered qty
export const inventoryHasEnoughQty = (inventoryItem, qty) => {
  if (!inventoryItem || !qty) {
    return true;
  }
  const addedQty = parseFloat(qty);
  const packageQtyAvailable = inventoryItem ? parseFloat(inventoryItem.qty_available) : undefined;

  return inventoryItem && addedQty <= packageQtyAvailable;
};

export const getPurchasedAmountsInitialValues = createSelector(
  [getCustomersPurchasedAmounts, getId],
  (customersPurchasedAmounts, customerId) => {
    const initialValues = {
      date_from: formatClientDate(getFourWeeksAgo()),  // 28 days ago
      date_to: formatClientDate(getToday())
    };
    if (customerId && customersPurchasedAmounts[customerId]) {
      initialValues.date_from = formatClientDate(customersPurchasedAmounts[customerId].date_from);
      initialValues.date_to = formatClientDate(customersPurchasedAmounts[customerId].date_to);
    }
    return initialValues;
  }
);

export const getPurchasedAmountsData = createSelector(
  [getCustomersPurchasedAmounts, getPurchasedAmountsInitialValues, getId],
  (customersPurchasedAmounts, initialValues, customerId) => {
    const hasSales = (customerId && customersPurchasedAmounts[customerId] && Array.isArray(customersPurchasedAmounts[customerId].sales));
    const hasItemMastersNeedingCorrection = (customerId && customersPurchasedAmounts[customerId] && Array.isArray(customersPurchasedAmounts[customerId].item_masters_needing_correction));
    return Object.assign({}, initialValues, {
      sales: hasSales ? customersPurchasedAmounts[customerId].sales : [],
      item_masters_needing_correction : hasItemMastersNeedingCorrection ? customersPurchasedAmounts[customerId].item_masters_needing_correction : [],
    });
  }
);
