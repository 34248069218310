import React from 'react';
import PropTypes from 'prop-types';
import {Row} from 'react-bootstrap';

import Facility from './Facility';

const Facilities = ({facilities, handleFacilityClick}) => {
  return (
    <Row> {
      facilities.map((facility, index) => {
        return (
          <Facility key={index}
                    name={facility.name}
                    city={facility.city}
                    postal_code= {facility.postal_code}
                    province_code= {facility.province_code}
                    street_address_1= {facility.street_address_1}
                    street_address_2= {facility.street_address_2}
                    handleClick={() => { handleFacilityClick(facility); }} />
        );
      })
    }
    </Row>
  );
};

Facilities.propTypes = {
  facilities: PropTypes.array.isRequired,
  handleFacilityClick: PropTypes.func.isRequired
};

export default Facilities;
