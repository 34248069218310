import get from 'lodash.get';
import find from 'lodash.find';
import last from 'lodash.last';
import * as itemNames from '../../constants/itemNames';
import * as dataNames from '../../constants/dataNames';


export const getTestResult = (state) => {
  return get(last(get(state, itemNames.isolocityLabResult)), 'data');
};

export const findLabResultsByLabId = (state, id) => {
  const handler = (isolocityLabResult) => (id === isolocityLabResult.lab_result_id);

  return get(find(state[dataNames.isolocityLabResults], handler), 'data');
};
