import React from 'react';
import PropTypes from 'prop-types';
import {change, reset} from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { goBack, push } from 'react-router-redux';
import get from 'lodash.get';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import * as apiActions from '../../../actions/apiActions';
import {unsetData} from '../../../actions/dataActions';
import {unsetItem} from '../../../actions/itemActions';
import {PRICING_MULTIPLIER_FORM} from '../../../constants/forms';
import PricingMultiplierForm from './PricingMultiplierForm';
import FormWrapper from '../../common/form/FormWrapper';
import {getInitialValues, getPayload} from '../../../selectors/forms/pricingMultiplierFormSelector';

export class PricingMultiplierPage extends React.PureComponent {
  constructor (props) {
    super(props);
    this.onSelectedItemMasterChange = this.onSelectedItemMasterChange.bind(this);
    this.redirect = this.redirect.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      isModify: false,
      selectedItemMasterIds: []
    };

  }

  componentDidMount() {
    this.props.actions.unsetData(dataNames.itemMasters);

    const promises = [
      this.props.actions.getUnpaginatedData('/api/categories?is_only_active=1', dataNames.categories),
      this.props.actions.getUnpaginatedData('/api/partners', dataNames.partners, {failed:'partners.get.failed'}, {sell_to: 1, purchase_from: 1}),
      this.props.actions.getUnpaginatedData('/api/item_masters/medicated_weights', dataNames.medicatedWeights),
    ];

    // Load data
    const {params: {id}} = this.props;
    if (id) {
      this.setState({ isModify: true }); // eslint-disable-line react/no-did-mount-set-state
      promises.push(
        this.props.actions.getItem(`/api/pricing_multipliers/${id}`, itemNames.pricingMultiplier)
          .then((pricingMultiplier) => {
            this.setState({selectedItemMasterIds: get(pricingMultiplier, 'item_master_ids')}); // eslint-disable-line react/no-did-mount-set-state
          })
      );
    }

    Promise.all(promises);
  }

  componentWillUnmount () {
    this.props.actions.reset(PRICING_MULTIPLIER_FORM);
    this.props.actions.unsetItem(itemNames.pricingMultiplier);
    this.props.actions.unsetData(dataNames.categories);
    this.props.actions.unsetData(dataNames.partners);
  }

  onSelectedItemMasterChange(selectedItemMasterIds) {
    this.setState({selectedItemMasterIds});
  }

  redirect () {
    this.props.actions.push('/retail/pricing-multipliers/active');
  }

  onSubmit (formValues) {
    const { isModify, selectedItemMasterIds } = this.state;
    const mode = isModify ? 'modify' : 'create';

    const payload = getPayload(formValues);
    payload.item_master_ids = selectedItemMasterIds;

    const messages = {
      success: `retail.pricingMultiplier.${mode}.success`,
      failed: `retail.pricingMultiplier.${mode}.failed`,
    };

    const next = () => {
      if (formValues.afterSubmit === 'redirect' ) {
        this.redirect();
      } else {
        this.props.actions.reset(PRICING_MULTIPLIER_FORM);
      }
    };

    const id = get(payload, 'id', get(this.props, 'params.id'));
    if (id) {
      return this.props.actions.putItem(`/api/pricing_multipliers/${id}`, payload, itemNames.pricingMultiplier, messages, {}, next);
    }

    return this.props.actions.postItem('/api/pricing_multipliers', payload, itemNames.pricingMultiplier, messages, {}, next)
      .then(() => this.setState({ isModify: true }));
  }

  render () {
    const { actions: {change}, initialValues, params: {id}, categories, vendors, medicatedWeights } = this.props;
    const { isModify, selectedItemMasterIds } = this.state;

    return (
      <FormWrapper
        title={isModify ? 'retail.pricingMultiplier.modify.title' : 'retail.pricingMultiplier.create.title'}
        goBack={this.redirect}>
        <PricingMultiplierForm
          change={change}
          onSelectedItemMasterChange={this.onSelectedItemMasterChange}
          onSubmit={this.onSubmit}
          initialValues={initialValues}
          pricingMultiplierId={id}
          categories={categories}
          vendors={vendors}
          medicatedWeights={medicatedWeights}
          selectedItemMasterIds={selectedItemMasterIds}
        />
      </FormWrapper>
    );
  }
}

PricingMultiplierPage.propTypes = {
  actions: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    putItem: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
  }),
  initialValues: PropTypes.object
};

function mapStateToProps (state) {
  const initialValues = {
    ...getInitialValues(state),
    ...{category_ids: [], vendor_ids: [], medicated_weights: []} // Add or overwrite with these properties to ensure proper initialization of these filter fields
  };
  return {
    initialValues,
    categories: state[dataNames.categories] ? state[dataNames.categories].filter((category) => category.category_code !== 'HARVESTS') : [],
    vendors: state[dataNames.partners] ? state[dataNames.partners] : [],
    medicatedWeights: state[dataNames.medicatedWeights] ? state[dataNames.medicatedWeights] : [],
  };
}

function mapDispatchToProps (dispatch) {
  const actions = Object.assign({}, apiActions, {goBack, reset, push, change, unsetData, unsetItem});

  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PricingMultiplierPage);
