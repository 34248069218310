import React from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {Field, reduxForm} from 'redux-form';
import {Col, Row} from 'react-bootstrap';
import moment from 'moment';

import {INVENTORY_TRANSACTIONS_REPORT} from '../../../../constants/forms';
import InternationalDatePickerInput from '../../../common/form/InternationalDatePickerInput';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import SubmitSection from '../../../common/form/SubmitSection';
import TextInput from '../../../common/form/TextInput';


const InventoryTransactionsSearchForm = ({handleSubmit, pristine, reset, submitSearch, categories, subcategories}) => {
  const submitSettings = {
    actionSettings: {
      reset: {
        action: reset,
        text: I18n.t('common.form.reset'),
        pristine
      },
      submit: {
        action: handleSubmit(submitSearch),
        text: I18n.t('common.actions.search'),
      }
    }
  };


  return (
    <form onSubmit={handleSubmit(submitSearch)}>
      <Row>
        <Col sm={6} md={4}>
          <Field
            name='event_date'
            component={InternationalDatePickerInput}
            props={{
              label: I18n.t('retail.inventoryTransactionsReport.reportDateLabel')
            }}
            isRequired={true}
          />
        </Col>

        <Col sm={6} md={4}>
          <Field
            name='category_id'
            component={ReactSelectInput}
            props={{
              label: I18n.t('retail.inventoryTransactionsReport.categorySelectLabel'),
              options: categories,
              clearable: false,
            }}
          />
        </Col>

        <Col sm={6} md={4}>
          <Field
            name='subcategory_id'
            component={ReactSelectInput}
            props={{
              label: I18n.t('retail.inventoryTransactionsReport.subcategorySelectLabel'),
              options: subcategories,
              clearable: false,
            }}
          />
        </Col>

        <Col sm={6} md={4}>
          <Field
            name='item_name'
            component={TextInput}
            props={{
              label: I18n.t('retail.inventoryTransactionsReport.itemNameLabel')
            }}
          />
        </Col>

        <div className='d-block d-sm-block d-none d-md-block d-none d-lg-block clearfix'></div>

        <Col xs={12}>
          <SubmitSection settings={submitSettings} />
        </Col>
      </Row>

    </form>
  );
};

const validate = (values) => {
  const errors = {};

  // require report_date
  if(!values.report_date){
    errors.report_date = I18n.t('common.form.isRequired',{
      fieldName: I18n.t('retail.inventoryTransactionsReport.reportDateLabel')
    });
  }

  if(!moment.isMoment(values.report_date)){
    errors.report_date = I18n.t('common.form.invalidFormat');
  } else {
    // require valid date format
    if(!values.report_date.isValid()){
      errors.report_date = I18n.t('common.form.invalidFormat');
    }

    // require past date
    if(+values.report_date.format('YYYYMMDD') > +moment().format('YYYYMMDD')){
      errors.report_date = I18n.t('retail.inventoryTransactionsReport.invalidReportDate');
    }
  }

  return errors;
};


InventoryTransactionsSearchForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  submitSearch: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  categories: PropTypes.array.isRequired,
  subcategories: PropTypes.array.isRequired
};

const mapStateToProps = (state) => ({
  initialValues: {
    event_date: moment()
  }
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: INVENTORY_TRANSACTIONS_REPORT,
    validate
  })
)(InventoryTransactionsSearchForm);
