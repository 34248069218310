import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Button, Row} from 'react-bootstrap';
import InternationalCurrencyInput from '../../../common/form/InternationalCurrencyInput';
import InternationalQuantityByUomInput from '../../../common/form/InternationalQuantityByUomInput';
import {SALES_ORDER_FORM} from '../../../../constants/forms';
import WillRender from '../../../common/concealers/WillRender';

const BulkLineItem = ({line, editableLineTotal, onEditableToggle, transferRecord, disabled, uom, hideItemPrices}) => {

  const editLineItem = (editableLineTotal === undefined) ? false : editableLineTotal;
  const { abbrev } = I18n.t(`uoms.${uom}`);

  const lineItemPriceReadProps = {
    label: I18n.t('purchaseOrders.form.itemPrice'),
    disabled: !!transferRecord,
    readOnly: true,
  };
  if (!transferRecord) {
    lineItemPriceReadProps.rightAddon = <Button size='sm' onClick={() => {onEditableToggle(true);}}>Edit</Button>;
  }

  const lineItemPriceEditProps = {
    label: I18n.t('purchaseOrders.form.itemPrice'),
    formName:{SALES_ORDER_FORM},
  };
  if (!transferRecord) {
    lineItemPriceEditProps.rightAddon = <Button size='sm' onClick={() => {onEditableToggle(false);}}>Calc</Button>;
  }

  return (
  <Col lg={8}>
    <Row>
      <Col lg={4}>
        <InternationalQuantityByUomInput
          name={`${line}.qty`}
          disabled={!!(transferRecord || disabled)}
          uom={uom}
          label={I18n.t('purchaseOrders.form.requestedAmount')}
        />
      </Col>

      <WillRender ifFalse={hideItemPrices}>
        {
          !editLineItem
            ? (
              <Col lg={4}>
                <InternationalCurrencyInput
                  name={`${line}.unit_price`}
                  props={{
                    label: I18n.t('purchaseOrders.form.pricePer', {label: abbrev}),
                    disabled:!!(transferRecord || disabled),
                    formName:{SALES_ORDER_FORM},
                    instance:'1',
                  }}
                />
              </Col>
            )
            : (
              <Col lg={4}>
                <InternationalCurrencyInput
                  name={`${line}.unit_price`}
                  props={{
                    label: I18n.t('purchaseOrders.form.approxPricePer', {label: abbrev}),
                    placeholder:'',
                    disabled:true,
                    isRequired:false
                  }}
                />
              </Col>
            )
        }
      </WillRender>

      <WillRender ifFalse={hideItemPrices}>
        {
          !editLineItem
            ? (
              <Col lg={3}>
                <InternationalCurrencyInput
                name={`${line}.line_item_price`}
                props={lineItemPriceReadProps} />
              </Col>
            )
            : (
              <Col lg={3} offset={4}>
                <InternationalCurrencyInput
                name={`${line}.line_item_price`}
                props={lineItemPriceEditProps} />
              </Col>
            )
        }
      </WillRender>
    </Row>
  </Col>);
};

BulkLineItem.propTypes = {
  editableLineTotal: PropTypes.bool,
  onEditableToggle: PropTypes.func.isRequired,
  line: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  hideItemPrices: PropTypes.bool,
  transferRecord: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ]).isRequired,
  uom: PropTypes.string.required,
};

export default BulkLineItem;
