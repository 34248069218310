export const getPackageColumns = (includeFulfilled) => {
  const columns = [
    {
      dataId: 'created_at',
      dataSort: true,
    },
    {
      dataId: 'qty',
      dataSort: true,
    },
  ];
  if (includeFulfilled) {
    columns.push(
      {
        dataId: 'fulfilled',
        dataSort: true,
      }
    );
  }
  columns.push(
    {
      dataId: 'uom_display',
      dataSort: true,
    },
    {
      dataId: 'prepackWeight',
      dataSort: true,
    },
    {
      dataId: 'entity_type',
      dataSort: true,
    },
    {
      dataId: 'entity_name',
      dataSort: true,
    },
    {
      id: 'release', // Used by test
      name: 'reservations.tableColumns.pleaseReleaseMe',
    }
  );
  return columns;
};

export const itemMasterHardReservations = [
  {
    dataId: 'created_at',
    dataSort: true,
  },
  {
    dataId: 'qty',
    dataSort: true,
  },
  {
    dataId: 'uom_display',
    dataSort: true,
  },
  {
    dataId: 'prepackWeight',
    dataSort: true,
  },
  {
    dataId: 'package_id',
    dataSort: true,
  },
  {
    dataId: 'batch_id',
    dataSort: true,
  },
  {
    dataId: 'location',
    dataSort: true,
  },
  {
    dataId: 'entity_type',
    dataSort: true,
  },
  {
    dataId: 'entity_name',
    dataSort: true,
  },
  {
    dataId: 'entity_id',
    dataSort: true,
  },
  {
    id: 'release', // Used by test
    name: 'reservations.tableColumns.pleaseReleaseMe',
  },
];

export const itemMasterSoftReservations = [
  {
    dataId: 'created_at',
    dataSort: true,
  },
  {
    dataId: 'qty',
    dataSort: true,
  },
  {
    dataId: 'uom_display',
    dataSort: true,
  },
  {
    dataId: 'entity_type',
    dataSort: true,
  },
  {
    dataId: 'entity_name',
    dataSort: true,
  },
  {
    dataId: 'entity_id',
    dataSort: true,
  },
  {
    id: 'release', // Used by test
    name: 'reservations.tableColumns.pleaseReleaseMe',
  },
];
