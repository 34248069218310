import get from 'lodash.get';
import round from 'lodash.round';
import isempty from 'lodash.isempty';
import moment from 'moment';
import {formValueSelector, arrayRemove, arrayRemoveAll, arrayInsert, change} from 'redux-form';
import {getItem, getDataBatchByPost} from '../../actions/apiActions';
import {unsetItem} from '../../actions/itemActions';
import {getItemMasterChildren} from '../../actions/itemMasterActions';
import * as itemNames from '../../constants/itemNames';
import * as dataNames from '../../constants/dataNames';
import {SALES_ORDER_FORM} from '../../constants/forms';
import * as types from '../../constants/actionTypes';
import {getSalesOrderItemMasterById} from '../../selectors/itemMastersSelectors';
import {getPrepackWeights, getSalesOrderWeightsMapping} from '../../selectors/prepackWeightSelectors';
import {formatCurrencyDecimal} from '../../util/formatHelper';
import getFormArrayIndexFromString from '../../util/formHelpers';
import { convertFromBase } from '../../util/uomHelpers';
import {getActiveFacilityId} from '../../selectors/facilitiesSelectors';
import {UOM_VALUE_PRECISION} from '../../constants/uoms';
import {getSalesOrder} from '../../selectors/salesOrdersSelectors';
import {getPartnerFacilities} from '../../selectors/partnersSelectors';
import {getPartnersForSalesOrder} from '../../selectors/forms/salesOrderFormSelectors';

export const isField = (field, targetField) => {
  return field.indexOf(targetField) !== -1;
};

export const lineFieldString = (index, field) => {
  return `lines[${index}].${field}`;
};

const salesOrderForm = store => next => action => {

  const {meta, type, payload} = action;

  if(action.type === types.ROUTER_LOCATION_CHANGE && get(action, 'payload.pathname', '').indexOf('sales-orders') !== -1){
    store.dispatch(unsetItem(itemNames.salesOrder));
  }

  const targetField = 'lines';
  const salesOrder = getSalesOrder(store.getState());
  const isCreating = isempty(salesOrder);

  const isSalesOrderLinesInsert = () => {
    return type === types.REDUX_FORM_ARRAY_PUSH && meta.form === SALES_ORDER_FORM && meta.field === targetField;
  };

  const isSalesOrderChange = () => {
    return type === types.REDUX_FORM_CHANGE && meta.form === SALES_ORDER_FORM;
  };

  const isSalesOrderPrepackChange = () => {
    return type === types.REDUX_FORM_CHANGE && meta.form === SALES_ORDER_FORM && meta.field.indexOf('weights') !== -1;
  };

  const updateLineItemPrice = (index, line) => {
    const qty = isNaN(parseFloat(line.qty)) ? null : parseFloat(line.qty);
    const unit_price = isNaN(parseFloat(line.unit_price)) ? null : parseFloat(line.unit_price);
    const lineItemPrice = (unit_price === null || qty === null) ? null : Math.round((qty * unit_price) * 100) / 100;

    store.dispatch(change(SALES_ORDER_FORM, lineFieldString(index, 'line_item_price'), lineItemPrice));
  };

  const updateWeightUnitPrices = (unitPrice, line, lineIndex, updateLineItemPrice = true) => {
    let newLineItemPrice = unitPrice === null ? null : 0;

    get(line, 'weights', []).forEach((weight, weightIndex) => {
      const newWeight = Object.assign({}, weight);

      if (unitPrice === null) {
        newWeight.unit_cost = null;
      } else {
        const unitCost = unitPrice * convertFromBase(weight.weight_base, weight.prepack_weight.uom);
        newLineItemPrice += unitCost * parseFloat(weight.qty || 0);
        newWeight.unit_cost = Math.round(100 * unitCost) / 100;
      }

      store.dispatch(arrayRemove(SALES_ORDER_FORM, lineFieldString(lineIndex, 'weights'), weightIndex));
      store.dispatch(arrayInsert(SALES_ORDER_FORM, lineFieldString(lineIndex, 'weights'), weightIndex, newWeight));
    });

    if (updateLineItemPrice) {
      store.dispatch(change(SALES_ORDER_FORM, lineFieldString(lineIndex, 'line_item_price'), newLineItemPrice));
    }
  };

  if (isSalesOrderChange()) {
    const state = store.getState();
    const selector = formValueSelector(SALES_ORDER_FORM);


    const changeOrderTotal = (changedLineIndex = false, field = false, payload = false) => {
      const lines = selector(state, 'lines') || [];
      let subTotalAmount = 0;

      for (const [index, line] of lines.entries()) {
        const lineItemPrice = (index === changedLineIndex) ?
          isNaN(parseFloat(payload)) ?
            null :
            parseFloat(payload) :
          parseFloat(line.line_item_price);

        // If either are null, order_total becomes "incalculable", represented as null in the database
        if (line.unit_price === null || lineItemPrice === null) {
          store.dispatch(change(SALES_ORDER_FORM, 'order_total', '0.00'));
          return;
        }

        subTotalAmount += lineItemPrice;
      }

      const overRideField = field ? {[field]: payload} : {};
      const selectedFields = Object.assign({}, selector(state, 'discount_percent', 'taxes', 'transfer_fee'), overRideField);
      const discountPercent = parseFloat(get(selectedFields, 'discount_percent', 0));
      const productTotalDiscountPercent = (discountPercent || 0) / 100;
      const transferFee = parseFloat(get(selectedFields, 'transfer_fee', 0));
      const taxes = parseFloat(get(selectedFields, 'taxes', 0));
      const orderTotal = subTotalAmount - (subTotalAmount * productTotalDiscountPercent) + (transferFee || 0) + (taxes || 0);

      store.dispatch(change(SALES_ORDER_FORM, 'order_total', orderTotal));
    };

    const getUpdatedLines = (lineIndex = false, field = false, value = false) => {
      const lines = selector(state, 'lines').map((line) => line);
      lines[lineIndex] = Object.assign({}, lines[lineIndex], {[field]: value});
      return lines;
    };

    // Order Type Changes
    if (isField(meta.field, 'order_type')) {
      if (isCreating) store.dispatch(arrayRemoveAll(SALES_ORDER_FORM, 'lines'));

      store.dispatch(change(SALES_ORDER_FORM, 'contains_medicated',['lab', 'waste_disposal'].indexOf(payload) === -1  ? null : 1));
      store.dispatch(change(SALES_ORDER_FORM, 'partner_id', null));
      store.dispatch(change(SALES_ORDER_FORM, 'partner_facility_id', null));

      if (payload === 'lab') {
        store.dispatch(change(SALES_ORDER_FORM, 'payment_status', 'ordered'));
        store.dispatch(change(SALES_ORDER_FORM, 'transfer_type', 'testing_lab'));
      } else {
        if (selector(state, 'transfer_type') === 'testing_lab') {
          store.dispatch(change(SALES_ORDER_FORM, 'transfer_type', null));
        }
      }
    }

    // Line Item Changes
    if (isField(meta.field, 'unit_price')) {
      const value = isNaN(parseFloat(payload)) ? null : parseFloat(payload);
      const index = getFormArrayIndexFromString(meta.field);
      const lines = getUpdatedLines(index, 'unit_price', value);
      const line = lines[index];
      const isEditableLineTotal = get(line, 'editableLineTotal', false);
      const isPrepack = get(line, 'weights', false);
      const isWeightsLocked = get(line, 'weightsLocked', false);
      if (!isEditableLineTotal && !isPrepack) {
        updateLineItemPrice(index, line);
      }

      if (isPrepack && !isWeightsLocked) {
        updateWeightUnitPrices(value, line, index);
      }
    }

    if (isField(meta.field, 'qty')) {
      const index = getFormArrayIndexFromString(meta.field);
      const lines = getUpdatedLines(index, 'qty', payload);
      const line = lines[index];
      const isEditableLineTotal = get(line, 'editableLineTotal', false);
      const isPrepack = get(line, 'weights', false);

      if (isEditableLineTotal) {
        const lineItemPrice = isNaN(parseFloat(line.line_item_price)) ? null : parseFloat(line.line_item_price);
        const quantity = isNaN(parseFloat(payload)) ? null : parseFloat(payload);
        const newUnitPrice = (lineItemPrice === null || quantity === null) ? null : (lineItemPrice / quantity);

        store.dispatch(change(SALES_ORDER_FORM, lineFieldString(index, 'unit_price'), newUnitPrice));
      } else if (!isPrepack) {
        updateLineItemPrice(index, line);
      }
    }

    if (isField(meta.field, 'line_item_price')) {
      const state = store.getState();
      const selector = formValueSelector(SALES_ORDER_FORM);
      const lines = selector(state, 'lines');
      const index = getFormArrayIndexFromString(meta.field);
      const line = lines[index];
      const isEditableLineTotal = get(line, 'editableLineTotal', false);
      const isPrepack = get(line, 'weights', false);
      const isWeightsLocked = get(line, 'weightsLocked', false);

      const updateUnitPrice = (lineItemPrice, quantity) => {
        const newUnitPrice = (quantity === null || lineItemPrice === null) ?
          null :
          Math.round(100 * (parseFloat(lineItemPrice) / quantity)) / 100;

        if (!isWeightsLocked) {
          store.dispatch(change(SALES_ORDER_FORM, lineFieldString(index, 'weightsLocked'), true)); // Prevents update of weights unit costs
        }

        store.dispatch(change(SALES_ORDER_FORM, lineFieldString(index, 'unit_price'), newUnitPrice));

        if (!isWeightsLocked) {
          store.dispatch(change(SALES_ORDER_FORM, lineFieldString(index, 'weightsLocked'), false)); // Prevents update of weights unit costs
        }

        const updateLineItemPrice = false;

        if (!isWeightsLocked && isEditableLineTotal) {
          updateWeightUnitPrices(newUnitPrice, line, index, updateLineItemPrice);
        }
      };

      const quantity = parseFloat(line.qty) || null;
      const lineItemPrice = isNaN(parseFloat(payload)) ? null : parseFloat(payload);

      if (isEditableLineTotal || isPrepack) {
        updateUnitPrice(lineItemPrice, quantity);
      }

      changeOrderTotal(index, false, payload);
    }

    const changeSalesOrderTitle = (field) => {
      const titleData = Object.assign(selector(state, 'date_ordered', 'partner_id'), {[field]: payload});
      const partners = state[dataNames.partners];
      const partner = partners.find(partner => partner.id === titleData.partner_id) || {};
      const partnerName = get(partner, 'name');
      const date = moment(titleData.date_ordered).isValid() ? moment(titleData.date_ordered).format('MM/DD/YYYY') : '';
      const salesOrderTitle = `${partnerName} ${date}`;
      store.dispatch(change(SALES_ORDER_FORM, 'title', salesOrderTitle));
    };

    if (isField(meta.field, 'transfer_fee')) {
      changeOrderTotal(false, 'transfer_fee', payload);
    }
    if (isField(meta.field, 'taxes')) {
      changeOrderTotal(false, 'taxes', payload);
    }
    if (isField(meta.field, 'discount_percent')) {
      if (payload > 100) {
        store.dispatch(change(SALES_ORDER_FORM, 'discount_percent', formatCurrencyDecimal(100)));
        return true;
      }
      changeOrderTotal(false, 'discount_percent', payload);
    }

    if (isField(meta.field, 'date_ordered') || isField(meta.field, 'partner_id')) {
      changeSalesOrderTitle(meta.field, payload);
    }

    if(isField(meta.field, 'partner_id')) {
      handlePartnerIdChange(state, store.dispatch, selector, payload);
    }

    const recalculateChangeDue = (payments) => {
      if (!payments) payments = selector(state, 'payments');
      const orderTotal = selector(state, 'order_total');
      const sumPayments = payments.reduce((acc, payment) => {
        const amount = parseFloat(payment.amount);
        acc += (isNaN(amount)) ? 0 : amount;
        return acc;
      }, 0);

      const overPaymentAmount = (orderTotal > sumPayments) ? 0 : (sumPayments - orderTotal);

      if(overPaymentAmount === 0 || sumPayments === 0){
        store.dispatch(change(SALES_ORDER_FORM, 'oddMoney', {payment_type: 'cash'})); //nuke it
      } else {
        store.dispatch(change(SALES_ORDER_FORM, 'oddMoney', {payment_type: 'cash', amount: (overPaymentAmount * -1).toFixed(2)}));
      }
      return true;
    };

    if (isField(meta.field, 'payments') && isField(meta.field, 'amount')) {
      const payments = selector(state, 'payments');
      const paymentIndex = getFormArrayIndexFromString(meta.field, 'payments');
      payments[paymentIndex]['amount'] = parseFloat(payload);
      recalculateChangeDue(payments);
    }
  }

  if (isSalesOrderPrepackChange()) {
    const state = store.getState();
    const selector = formValueSelector(SALES_ORDER_FORM);

    const temp = meta.field.split('.');
    temp.pop(); // throw out the current field
    const inventoryLine = temp.join('.');
    const prepackLine = selector(state, inventoryLine);


    const getUpdatedPrepackData = (lineIndex = false, weightIndex = false, field = false, value = false) => {
      const weights = selector(state, `lines[${lineIndex}].weights`).map((weight) => weight);
      weights[weightIndex][field] = parseFloat(value);
      const prepackData = weights.reduce((acc, weight) => {
        const qty = parseFloat(get(weight, 'qty', 0));
        const prepackQuantity = isNaN(qty) ? 0 : qty;
        const prepackLinePrice = prepackQuantity * parseFloat(get(weight, 'unit_cost'));
        const weight_value = convertFromBase(weight.weight_base, weight.prepack_weight.uom);
        acc.weight = acc.weight + prepackQuantity * weight_value;
        acc.price = round(acc.price + prepackLinePrice, 2);
        acc.quantity += (prepackQuantity || 0);
        return acc;
      }, {weight: 0, price: 0, quantity: 0});
      return {
        weight: round(prepackData.weight, UOM_VALUE_PRECISION),
        price: round(prepackData.price, 2),
        quantity: prepackData.quantity,
      };
    };

    // Handles all updates for qty and total - no point in handling from cascaded total since this is the only thing that can change total.
    if (isField(meta.field, 'qty')) {
      const quantity = parseFloat(payload || 0);
      const total = quantity * convertFromBase(get(prepackLine, 'prepack_weight.weight_base'), get(prepackLine, 'prepack_weight.uom'));
      const index = getFormArrayIndexFromString(meta.field);
      store.dispatch(change(SALES_ORDER_FORM, lineFieldString(index, 'weightsLocked'), true)); // Prevents update of weights unit costs
      store.dispatch(change(SALES_ORDER_FORM, meta.field.replace('qty', 'total'), total));
      const weightIndex = getFormArrayIndexFromString(meta.field, 'weights');
      const prepackData = getUpdatedPrepackData(index, weightIndex, 'qty', quantity);

      store.dispatch(change(SALES_ORDER_FORM, lineFieldString(index, 'qty'), prepackData.weight));
      store.dispatch(change(SALES_ORDER_FORM, lineFieldString(index, 'line_item_price'), prepackData.price || null));
      store.dispatch(change(SALES_ORDER_FORM, lineFieldString(index, 'weightsLocked'), false)); // Prevents update of weights unit costs
    }

    if (isField(meta.field, 'unit_cost')) {
      const index = getFormArrayIndexFromString(meta.field);
      const weightIndex = getFormArrayIndexFromString(meta.field, 'weights');
      const prepackData = getUpdatedPrepackData(index, weightIndex, 'unit_cost', payload || 0);
      const averagePricePerUom = Math.round(100 * (prepackData.price / prepackData.weight)) / 100;

      store.dispatch(change(SALES_ORDER_FORM, lineFieldString(index, 'weightsLocked'), true)); // Prevents update of weights unit costs
      store.dispatch(change(SALES_ORDER_FORM, lineFieldString(index, 'line_item_price'), prepackData.price));
      if (!isNaN(averagePricePerUom)) store.dispatch(change(SALES_ORDER_FORM, lineFieldString(index, 'unit_price'), averagePricePerUom.toFixed(2)));
      store.dispatch(change(SALES_ORDER_FORM, lineFieldString(index, 'weightsLocked'), false)); // allows again for direct changes from unit price
    }
  }


  if (isSalesOrderLinesInsert()) {

    const state = store.getState();
    const selector = formValueSelector(SALES_ORDER_FORM);
    const orderType = selector(state, 'order_type') || 'sales';
    const targetFieldArray = selector(state, targetField) || [];
    const itemMasterId = get(payload, 'item_master_id', 0);
    const prepackWeights = getPrepackWeights(state);

    const itemMasterWithItemType = getSalesOrderItemMasterById(state, {id: itemMasterId});

    const getChildren = (itemMaster) => new Promise((resolve) => {
      const itemType = get(itemMaster, 'itemType');
      if (itemType !== 'prepack') {
        return resolve([]);
      }

      store.dispatch(getItemMasterChildren(get(itemMaster, 'id')))
        .then((itemMasterChildren) => {
          resolve(itemMasterChildren);
        });
    });

    const getItemMaster = (itemMasterId) => new Promise((resolve) => {
      const itemMasterUrl = `/api/item_masters/${itemMasterId}`;
      store.dispatch(getItem(itemMasterUrl, itemNames.itemMaster, {failed: 'products.get.failed'}))
        .then((itemMasterWithPricing) => {
          resolve(itemMasterWithPricing);
        });
    });

    const getInventoryItems = (ids) => {
      const url = '/api/items/by_item_master_ids';
      const messages = {failed: 'salesOrders.form.failedToGetInventory'};
      store.dispatch(getDataBatchByPost(url, {ids}, dataNames.inventoryItems, messages));
    };

    const getDefaultPrice = (itemMaster) => {
      const unitPrice = get(itemMaster, 'prices.wholesale', 0);
      if (unitPrice > 0) return unitPrice;
      const facilityId = getActiveFacilityId(state);
      const lists = get(itemMaster, 'pricing_details.price_lists', []);
      const facilityPriceList = lists.find(list =>
        get(list, 'sale_type') === 'wholesale' && list.facility_ids.includes(facilityId)
      );
      return parseFloat(get(facilityPriceList || lists.find((list) => {
        return get(list, 'sale_type') === 'wholesale' && list.is_default_for_org;
      }), 'default_price', 0));
    };

    const getNewOrderRow = (itemMaster) => {
      const state = store.getState();
      const orderTypesWithPresetQuantity = ['lab', 'waste_disposal'];
      const unitPrice = orderTypesWithPresetQuantity.indexOf(orderType) === -1
        ? getDefaultPrice(itemMaster)
        : '0.00';

      const transferFullQty = get(state[itemNames.distributionSettings], 'distributions_transfer_requires_full_quantity.value', false);
      const usePresetQty = !transferFullQty && orderTypesWithPresetQuantity.indexOf(orderType) !== -1;
      const quantity = usePresetQty ? 1 : undefined;

      const row = {
        item_master_id: get(itemMaster, 'id'),
        itemMaster,
        line_item_price: quantity ? parseFloat(unitPrice) * parseFloat(quantity) : '0.00',
        unit_price: unitPrice,
        editableLineTotal: false,
        qty: quantity,
      };

      if (itemMaster.itemType === 'prepack') {
        row.weights = getSalesOrderWeightsMapping(prepackWeights, itemMaster, {unit_price: unitPrice});
      }
      return row;
    };

    // MAIN
    getChildren(itemMasterWithItemType)
      .then((itemMasterChildren) => {
        getInventoryItems([get(itemMasterWithItemType, 'id')].concat(itemMasterChildren.map((child) => child.id)));
        getItemMaster(get(itemMasterWithItemType, 'id'))
          .then((itemMaster) => {
            itemMaster.itemType = itemMasterWithItemType.itemType;
            itemMaster.children = itemMasterChildren;
            const row = getNewOrderRow(itemMaster);
            const index = targetFieldArray.length;
            store.dispatch(arrayRemove(SALES_ORDER_FORM, targetField, index));
            store.dispatch(arrayInsert(SALES_ORDER_FORM, targetField, index, row));
          });
      });

  }

  return next(action);
};

/**
 * Propagate changes related to the Partner (partner_id) changing
 *
 * @param state
 * @param dispatch
 * @param formSelector
 * @param payload
 */
const handlePartnerIdChange = (state, dispatch, formSelector, payload) => {
  const partnerOptions = getPartnersForSalesOrder(state);
  const partnerFacilities = getPartnerFacilities(state);
  const partner = (partnerOptions || []).find(x => x.id === payload);

  if (partner && partner.payment_terms && !formSelector(state, 'payment_terms')) {
    dispatch(change(SALES_ORDER_FORM, 'payment_terms', partner.payment_terms));
  }

  const validFacilities = partnerFacilities.filter(facility => facility.partner_id === payload);
  dispatch(change(SALES_ORDER_FORM, 'partner_facility_id', validFacilities.length === 1 ? validFacilities[0].id : null));
};

export default salesOrderForm;
