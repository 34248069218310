import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {Form, FormControl, FormGroup, InputGroup, Button} from 'react-bootstrap';
import {FaBarcode} from 'react-icons/fa';
import * as messageTypes from '../../../constants/messageTypes';
import {PACKAGE_CODE_REG_EXP} from '../form/validationRegExs';
import InProgressOverlay from '../InProgressOverlay';
//import Form from '../../print-services/servers/components/editor/Form';

function executeCallback(callback, ...args) {
  if (typeof callback === 'function') {
    return callback(...args);
  }
}

function validate(value) {
  return typeof value === 'string' && !value.trim() || !value
    ? true
    : PACKAGE_CODE_REG_EXP.test(value);
}

class ProductSearchComponent extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.onInputChange = this.onInputChange.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.search = this.search.bind(this);
  }

  componentWillMount() {
    this.setState({value: '', searching: false});
  }

  onInputChange(event) {
    const {target: {value}} = event;
    if (validate(value)) {
      this.setState({value});
    } else {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  onKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.search();
    }
  }

  search() {
    const {value, searching} = this.state;

    if (searching || !value) {
      return;
    }

    const {actions: {addMessage, performSearch}} = this.props;

    if (get(this.props, 'initialValues.productionRunPackageCodes', []).includes(value)) {
      this.setState({value: ''});
      return addMessage(
        messageTypes.warning,
        ['cart.getScannedItem.notFound', {packageId: value}]
      );
    }

    this.setState({searching: true});
    executeCallback(this.props.onSearchStart);
    performSearch(value, () => this.props)
      .then((result) => {
        const {foundItems, foundOptions} = this.props;
        const suppressNotFoundMessage = typeof result === 'object' && result !== null && result.suppressNotFoundMessage;

        this.setState({searching: false});

        if (!foundItems.length && !suppressNotFoundMessage) {
          addMessage(messageTypes.warning, ['cart.getScannedItem.notFound', {packageId: value}]);
        }
        return executeCallback(this.props.onSearchComplete, value, foundItems, foundOptions);
      })
      .then(() => this.setState({value: ''}))
      .catch((error) => {
        this.setState({searching: false});
        return executeCallback(this.props.onSearchFailed, error);
      });
  }

  render() {
    const {value, searching} = this.state;
    const {label, loadingMessage, showLoader, disabled} = this.props;
    return (
      <FormGroup className='product-search'>
        <InProgressOverlay isActive={showLoader && searching} message={loadingMessage} translate={true}/>
        {label ? <Form.Label>{label}</Form.Label> : null}
        <InputGroup>
          <FormControl
            type='text'
            onChange={this.onInputChange}
            onKeyPress={this.onKeyPress}
            value={value}
            disabled={disabled}
          />
          <InputGroup.Append>
            <Button onClick={() => this.search()} disabled={disabled}><FaBarcode/></Button>
          </InputGroup.Append>
        </InputGroup>
      </FormGroup>
    );
  }
}

ProductSearchComponent.propTypes = {
  actions: PropTypes.shape({
    addMessage: PropTypes.func.isRequired,
    performSearch: PropTypes.func.isRequired,
  }).isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.node,
  ]),
  showLoader: PropTypes.bool,
  initialValues: PropTypes.object,
  loadingMessage: PropTypes.string,
  onSearchStart: PropTypes.func,
  onSearchComplete: PropTypes.func,
  onSearchFailed: PropTypes.func,
  foundItems: PropTypes.array.isRequired,
  foundOptions: PropTypes.array,
  disabled: PropTypes.bool,
};

ProductSearchComponent.defaultProps = {
  showLoader: true,
  loadingMessage: 'common.form.searching',
};

export default ProductSearchComponent;
