import { createSelector } from 'reselect';
import { I18n } from 'react-redux-i18n';
import forEach from 'lodash.foreach';
import get from 'lodash.get';
import {
  convertDbDateTimeToFullDateTime,
  formatClientDate,
} from '../util/dateHelpers';
import * as dataNames from '../constants/dataNames';
import * as itemNames from '../constants/itemNames';
import { leafWaLicenseTypes } from '../constants/leaf';
import {isFeatureEnabled,} from '../selectors/featureToggles';

const getCurrentTimezone = (state) => state.timezone;

export const getIntegrationSettings = (state) => state.integrationSettings;
const getMetrcPackageAdjustmentReasons = (state) => state.metrcPackageAdjustmentReasons;
const getTransactions = (state) => state[dataNames.transactions];
const getTransactionDetails = (state) => state[itemNames.transaction];
const metrcTags = (state) => state[dataNames.trackingIds];
const getSalesExportData = (state) => state[dataNames.salesExportData];
export const getMetrcCategories = (state) => state[dataNames.metrcCategories];
export const getMetrcCategoryMappings = (state) => state[dataNames.metrcCategoryMappings];
const getCustomersPurchasedAmounts = (state) => state[itemNames.customersPurchasedAmounts];
const getActiveFacility = state => state && state.facility || {};
const isMetrcAdditionalDataPlantDestroyEnabled = state =>
  isFeatureEnabled(state)('feature_metrc_additional_data_plant_destroy');
const getId = (_, props) => props && props.id;
export const getMetrcWasteReasons = (state) => {
  const wasteReasons = state[dataNames.metrcPlantsWasteReasons];
  const wasteReasonsOptions =  wasteReasons.map((reason) => {
    return {
      value: reason.Name,
      text: reason.Name,
      requires_note: reason.RequiresNote,
    };
  });

  return wasteReasonsOptions;
};

export const getMetrcWasteMethods = (state) => {
  const wasteMethods = state[dataNames.metrcPlantsWasteMethods];
  const wasteMethodsOptions =   wasteMethods.map((method) => {
    return {
      value: method.Name,
      text: method.Name,
    };
  });
  return wasteMethodsOptions;
};

export const getIntegationMapping = (state) => state[dataNames.integrationMapping];

const METRC = 'metrc';
const LEAF = 'leaf';

export const getMetrcSettings = createSelector(
  getIntegrationSettings,
  (settings) =>
    settings &&
    settings.integration_state_integrator &&
    settings.integration_state_integrator.value === METRC &&
    settings.integration_metrc_settings &&
    settings.integration_metrc_settings.value
);
const getLeafSettings = createSelector(
  getIntegrationSettings,
  (settings) =>
    settings &&
    settings.integration_state_integrator &&
    settings.integration_state_integrator.value === LEAF &&
    settings.integration_leaf_settings &&
    settings.integration_leaf_settings.value
);

export const hasMetrcSettings = createSelector(
  getMetrcSettings,
  (settings) => Boolean(settings && settings.state)
);

/**
 * Check whether app is in LEAF mode
 * @todo replace all usages with isLeafIntegrator
 * @deprecated since 1.0.1 Use isLeafIntegrator instead
 */
export const hasLeafSettings = createSelector(
  [getLeafSettings],
  Boolean
);

const getMetrcState = createSelector(
  getMetrcSettings,
  (settings) => settings && settings.state
);

export const isMetrcAlCoIntegrator = createSelector(
  getMetrcSettings,
  (settings) => !!settings && ['al', 'co'].indexOf(settings.state) > -1
);

export const getMetrcTags = createSelector(
  [metrcTags],
  (tags) => {
    let options = [];
    if (Array.isArray(tags)) {
      options = tags.map((tag) => {
        return { text: tag.tag, value: tag.id };
      });
    }
    return options;
  }
);

export const getMetrcPackageAdjustmentOptions = createSelector(
  getMetrcPackageAdjustmentReasons,
  (reasons) =>
    reasons && Array.isArray(reasons) ? reasons.sort().map((reason) => ({ text: reason, value: reason })) : []
);

export const flattenSalesExportData = createSelector(
  getSalesExportData,
  (salesExportData) => {
    const data = [];
    if (Array.isArray(salesExportData)) {
      salesExportData.forEach((order, orderIndex) => {
        if (Array.isArray(order.Transactions)) {
          order.Transactions.forEach((transaction, transactionIndex) =>
            data.push({
              id: `${orderIndex}-${transactionIndex}`,
              salesDateTime: order.SalesDateTime || '',
              packageLabel: transaction.PackageLabel || '',
              quantity: transaction.Quantity || '',
              uom: transaction.UnitOfMeasure || '',
              totalAmount: transaction.TotalAmount || '',
              salesCustomerType: order.SalesCustomerType || '',
              customerNumber: order.PatientLicenseNumber || '',
              caregiverNumber: order.CaregiverLicenseNumber || ''
            })
          );
        }
      });
    }
    return data;
  }
);

export const getMetrcSendExportPayload = createSelector(
  getSalesExportData,
  (salesExportData) => ({ data: salesExportData })
);

function formatTransaction(transaction, timezone) {
  let response_body = transaction && transaction.response;
  let request_body = transaction && transaction.request_parameters;
  if (response_body) {
    try {
      response_body = JSON.parse(response_body);
    } catch (error) {} // eslint-disable-line no-empty
  }
  if (request_body) {
    try {
      request_body = JSON.parse(request_body);
    } catch (error) {} // eslint-disable-line no-empty
  }
  return {
    id: transaction && transaction.id,
    code: transaction && transaction.response_code,
    success: transaction.success,
    date: transaction && convertDbDateTimeToFullDateTime(transaction.created_at, timezone),
    api_call:
      transaction &&
      transaction.request_type &&
      transaction.end_point &&
      `${transaction.end_point} - ${transaction.request_type}`,
    response:
      transaction && transaction.response_code && transaction.success && transaction.success === 1
        ? 'Successful'
        : `Failed - ${transaction.response_code}`,
    request: transaction && transaction.request,
    response_body,
    request_body
  };
}

export const getTransactionsForDisplay = createSelector(
  [getTransactions, getCurrentTimezone],
  (transactions, timezone) => {
    return transactions ? transactions.map((trans) => formatTransaction(trans, timezone)) : null;
  }
);

export const getTransactionDetailsForDisplay = createSelector(
  getTransactionDetails,
  (details) => formatTransaction(details)
);

export const getStateIntegrator = createSelector(
  [getIntegrationSettings],
  (settings) => settings && settings.integration_state_integrator && settings.integration_state_integrator.value
);

export const hasStateIntegrator = createSelector(
  [getIntegrationSettings],
  (settings) =>
    Boolean(
      settings &&
        settings.integration_state_integrator &&
        settings.integration_state_integrator.value &&
        settings.integration_state_integrator.value !== 'none'
    )
);

export const isLeafIntegrator = createSelector(
  [getStateIntegrator],
  (integrator) => integrator === 'leaf'
);

export const isCureIntegrator = createSelector(
  [getStateIntegrator],
  (integrator) => integrator === 'cureapi'
);

// CBYTES-1232 - LEAF PA logic check front end selectors
// use has_leaf_pa_config_pack_and_closed_loop instead of settings.state === 'pa'
export const isPaLeaf = createSelector(
  [getActiveFacility],
  (facility) => facility.has_leaf_pa_config_pack_and_closed_loop || false
);
// export const isPaLeaf = createSelector(
//   getLeafSettings,
//   (settings) => settings && settings.state === 'pa'
// );

export const isWaLeaf = createSelector(
  getLeafSettings,
  (settings) => settings && settings.state === 'wa'
);

export const isMdMetrc = createSelector(
  getMetrcSettings,
  (settings) => {
    return settings && settings.state === 'md';
  }
);

export const isOregonRecMetrc = createSelector(
  getMetrcSettings,
  (settings) => {
    return settings && settings.state === 'or' && settings.facility_type === 'recreational';
  }
);

export const getMetrcCategoryFromOrgSubcategoryId = createSelector(
  [getId, getMetrcCategories, getMetrcCategoryMappings],
  (subcategoryId, metrcCategories, mappings) => {
    const mapping = mappings.find((mapping) => mapping.subcategory_id === subcategoryId);
    return mapping ? metrcCategories.find((category) => category.id === mapping.metrc_item_category_id) : undefined;
  }
);

export const requireMedicalIdExpirationForMetrc = createSelector(
  getMetrcSettings,
  (settings) => Boolean(settings && settings.state === 'co')
);

// CBYTES-1232 - LEAF PA logic check front end selectors
// use has_leaf_pa_config_pack_and_closed_loop instead of settings.state === 'pa'
export const isPALeafIntegrator = createSelector(
  [getActiveFacility],
  (facility) => facility.has_leaf_pa_config_pack_and_closed_loop || false
);
// export const isPALeafIntegrator = createSelector(
//   [isLeafIntegrator, getLeafSettings],
//   (isLeaf, settings) => Boolean(isLeaf && settings && settings.state && settings.state === 'pa')
// );

export const getLeafPaPurchasedAmountsInitialValues = createSelector(
  getCustomersPurchasedAmounts,
  getId,
  (customersPurchasedAmounts, customerId) => {
    const initialValues = {
      date_from: '',
      date_to: ''
    };
    if (customerId && customersPurchasedAmounts[customerId]) {
      initialValues.date_from = formatClientDate(customersPurchasedAmounts[customerId].date_from);
      initialValues.date_to = formatClientDate(customersPurchasedAmounts[customerId].date_to);
    }
    return initialValues;
  }
);

export const getLeafPaPurchasedAmountsData = createSelector(
  getCustomersPurchasedAmounts,
  getLeafPaPurchasedAmountsInitialValues,
  getId,
  (customersPurchasedAmounts, initialValues, customerId) => {
    const hasSales =
      customerId && customersPurchasedAmounts[customerId] && Array.isArray(customersPurchasedAmounts[customerId].sales);
    return Object.assign({}, initialValues, {
      sales: hasSales ? customersPurchasedAmounts[customerId].sales : []
    });
  }
);

export const getWaInventoryTypesWithName = (waInventoryTypes) => {
  const waInventoryTypesOptions = [];
  if (waInventoryTypes) {
    forEach(waInventoryTypes, ({ leaf_inventory_type }) => {
      waInventoryTypesOptions.push({
        value: leaf_inventory_type,
        text: I18n.t(`harvestPackages.form.leafWa.inventoryTypes.${leaf_inventory_type}`)
      });
    });
  }
  return waInventoryTypesOptions;
};

export const getLeafWaLicenseTypesOptions = () => {
  const waLicenseTypes = [];
  forEach(leafWaLicenseTypes, (item) => {
    waLicenseTypes.push({
      value: item,
      text: I18n.t(`partners.createPartner.leafWaLicenseTypes.${item}`)
    });
  });
  return waLicenseTypes;
};

export const isTagIntegrated = createSelector(
  getIntegrationSettings,
  (settings) =>
    settings &&
    settings.integration_tagging_settings &&
    settings.integration_tagging_settings.value &&
    (settings.integration_tagging_settings.value.plants || settings.integration_tagging_settings.value.packages)
);

export const hasPlantsTags = createSelector(
  getIntegrationSettings,
  (settings) =>
    settings &&
    settings.integration_tagging_settings &&
    settings.integration_tagging_settings.value &&
    settings.integration_tagging_settings.value.plants
);

export const hasPackagesTags = createSelector(
  [getIntegrationSettings],
  (settings) => {
    return get(settings, 'integration_tagging_settings.value.packages', false);
  }
);

export const userRequiresStateLicense = createSelector(
  [getIntegrationSettings],
  (settings) => {
    return Boolean(get(settings, 'users_require_state_license.value', false));
  }
);

export const useLeafDataSystemsBranding = createSelector([getIntegrationSettings, getActiveFacility],
  (settings, facility) => {
    const leaf_data_systems_branding_setting = Boolean(get(settings, 'integration_use_leaf_data_systems_branding.value', false));
    // The Leaf regulator doesn't have the config pack assigned, so we check the facility type and province code
    const isLeafDataSystemsRegulator = get(facility, 'type', '') === 'regulator_access' && get(facility, 'province_code', '') === 'UT';
    return leaf_data_systems_branding_setting || isLeafDataSystemsRegulator;
  }
);

export const getCustomCheckinForm = createSelector([getIntegrationSettings], (settings) => {
  return (get(settings, 'custom_checkin_form.value', true));
});


export const allowAccessToStateIntegratorPage = createSelector([getIntegrationSettings], (settings) => {
  return (get(settings, 'integration_allow_state_user_selection.value', true) == 'false' || false) ? false : true;
});

export const requiresAdditionalDataForMetrcPlantDestroy = createSelector(
  [getMetrcState, isMetrcAdditionalDataPlantDestroyEnabled],
  (integrationState, featureToggled) => {
    // this state list comes from metrc bulletin #83
    const states = ['ak','ca', 'dc', 'la', 'me', 'ma', 'md', 'mi', 'mo', 'mt', 'nv'];
    return states.includes(integrationState) && featureToggled;
  });
