/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { Col, Row } from 'react-bootstrap';

import MultiSelectInput from '../../../common/form/MultiselectInput';
import InternationalDateTimePickerInput from '../../../common/form/InternationalDateTimePickerInput';
import SubmitSection from '../../../common/form/SubmitSection';

import InfusionInputsFieldArray from '../common/InfusionInputsFieldArray';
import InfusionPotencyTable from './InfusionPotencyTable';

const StartInfusionForm = (props) => {
  const {
    inputs,
    reset,
    handleSubmit,
    invalid,
    submitting,
    pristine,
    change,
    addMessage,
    loadTestResults,
    clearSearch,
    assemblies,
    employees,
    equipments,
    itemOptions,
    initialValues,
    potencyTableData,
    allowNegativeInventory,
    getFormValue,
    isPaRemediationLabelsEnabled
  } = props;


  const submitSectionSettings = {
    actionSettings: {
      reset: {
        pristine,
        text: I18n.t('common.actions.reset'),
        style: 'default',
        action: reset
      },
      submit: {
        invalid,
        submitting,
        disabled: !inputs || inputs.length < 1,
        text: I18n.t('common.actions.save'),
      }
    },
    align: 'right'
  };

  return (
    <form className='create-infusion-form' onSubmit={handleSubmit}>
      <Row>
        <Col xs={12}>
          <Row>
            <Col xs={12}>
              <InfusionPotencyTable tableData={potencyTableData} assemblies={assemblies} change={change} getFormValue={getFormValue}/>
            </Col>
          </Row>

          <h4 className='section-title' />
          <Row>
            <Col xs={12} sm={12} md={12}>
              <FieldArray
                name='inputs'
                component={InfusionInputsFieldArray}
                addMessage={addMessage}
                loadTestResults={loadTestResults}
                clearSearch={clearSearch}
                itemOptions={itemOptions}
                initialValues={initialValues}
                allowNegativeInventory={allowNegativeInventory}
                isPaRemediationLabelsEnabled={isPaRemediationLabelsEnabled}
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs={6} sm={6} md={3}>
              <Field
                name='employee_ids'
                component={MultiSelectInput}
                props={{
                  label: I18n.t('ei.infusion.create.form.employee'),
                  options: employees,
                  textKey: 'displayName',
                  valueKey: 'id'
                }}
              />
            </Col>
            <Col xs={6} sm={6} md={3}>
              <Field
                name='equipment_ids'
                component={MultiSelectInput}
                props={{
                  label: I18n.t('ei.infusion.create.form.equipment'),
                  options: equipments,
                  textKey: 'model_name',
                  valueKey: 'id'
                }}
              />
            </Col>
            <Col xs={6} sm={6} md={3}>
              <InternationalDateTimePickerInput
                name='start_time'
                props={{
                  label: I18n.t('ei.infusion.create.form.startTime'),
                  isRequired: true,
                  timeFormat: true,
                  enableTodayButton: false,
                }}
              />
            </Col>
            <Col xs={6} sm={6} md={3}>
              <InternationalDateTimePickerInput
                name='expected_completion_time'
                props={{
                  label: I18n.t('ei.infusion.create.form.completeTime'),
                  isRequired: true,
                  timeFormat: true,
                  enableTodayButton: false,
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <SubmitSection settings={submitSectionSettings} />
    </form>
  );
};

StartInfusionForm.propTypes = {
  inputs: PropTypes.array,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  addMessage: PropTypes.func.isRequired,
  loadTestResults: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  assemblies: PropTypes.array.isRequired,
  employees: PropTypes.array.isRequired,
  equipments: PropTypes.array,
  itemOptions: PropTypes.array.isRequired,
  initialValues: PropTypes.object,
  potencyTableData: PropTypes.object,
  allowNegativeInventory: PropTypes.bool,
  getFormValue: PropTypes.func.isRequired,
  isPaRemediationLabelsEnabled: PropTypes.bool.isRequired
};

export default StartInfusionForm;
