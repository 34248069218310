import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import {reduxForm, Field} from 'redux-form';
import PasswordInput from '../../common/form/PasswordInput';
import TextInput  from '../../common/form/TextInput';
import PasswordRulesBlock from '../common/PasswordRulesBlock';


export const ResetPasswordWithTokenForm = (props) => {
  const {handleSubmit, isCreateMode, checkPasswordRules, username, password} = props;
  const rules = checkPasswordRules(password, username);
  return (
    <form onSubmit={handleSubmit}>
        <Field
          name='name'
          component={TextInput}
          autoComplete='username'
          props={{
            label: I18n.t('user.password.name')
          }}
        />
        <hr/>
        <Field
          name='password'
          component={PasswordInput}
          autoComplete='new-password'
          props={{
            label: I18n.t('user.password.password1'),
          }}
        />
        <PasswordRulesBlock rules={rules} isDirty={!!password}/>
        <Field
          name='password_confirmation'
          component={PasswordInput}
          autoComplete='new-password'
          props={{
            label: I18n.t('user.password.password2'),
          }}
        />
        <br/>
        <Button type='submit' variant='primary' block>
          {isCreateMode
            ? I18n.t('user.password.create')
            : I18n.t('user.password.reset')}
        </Button>
    </form>
  );
};

ResetPasswordWithTokenForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  checkPasswordRules: PropTypes.func.isRequired,
  isCreateMode: PropTypes.bool,
  username: PropTypes.string,
  password: PropTypes.string,
};

export default reduxForm({
  form: 'resetPassword'
})(ResetPasswordWithTokenForm);
