import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { Row } from 'react-bootstrap';

import { UNIT } from '../../../../constants/pricingTypes';
import { WHOLESALE } from '../../../../constants/saleTypes';
import {
  generateUnitPriceListItem,
  generateWeightPriceListItem
} from '../../../../selectors/forms/productFormSelectors';

import AccordionPanel from '../../../common/AccordionPanel';
import UnitPriceAttributes from './UnitPriceAttributes';
import WeightPriceAttributes from './WeightPriceAttributes';
import FacilitySalesAttributesFieldArray from './FacilitySalesAttributesFieldArray';
import { GR } from '../../../../constants/uoms';

const statusOptions = [{ value: null, text: '' }, { value: 1, text: 'Yes' }, { value: 0, text: 'No' }];

const PricingPanel = (props) => {
  const {
    sale_type,
    consumer_type,
    pricing_type,
    title,
    categoryId,
    pricingWeights,
    pricingGroupOptions,
    pricingClasses,
    facilityOptions,
    retailFacilityOptions,
    isMassModifyForm,
    showError,
    fieldCanModifiedMap,
    formName,
    unitWeightUom
  } = props;

  const PriceComponent = pricing_type === UNIT ? UnitPriceAttributes : WeightPriceAttributes;
  const pathTail = sale_type + (consumer_type ? `.${consumer_type}` : '');
  const generator = pricing_type === UNIT ? generateUnitPriceListItem : generateWeightPriceListItem;
  const uomCode = unitWeightUom ? unitWeightUom : GR;
  const uom = I18n.t(`uoms.${uomCode}.word`);
  const isWholesale = sale_type === WHOLESALE;
  const disablePriceBlock = !pricing_type;
  const generatorProps = {
    consumer_type,
    sale_type,
    inherit_pricing_group_updates: isMassModifyForm ? null : undefined,
    inherit_pricing_class_updates: isMassModifyForm ? null : undefined
  };

  return (
    <AccordionPanel defaultExpanded={true} title={title}>
      <AccordionPanel defaultExpanded={true} title='products.form.organization'>
        <Row>
          <PriceComponent
            isOrganizationMode={true}
            isWholesale={isWholesale}
            fieldName={`prices.organization.${pathTail}`}
            fieldLabel={['products.form.basePrice', { uom }]}
            pricingGroupOptions={pricingGroupOptions}
            pricingClasses={pricingClasses}
            isMassModifyForm={isMassModifyForm}
            statusOptions={statusOptions}
            showError={showError}
            disablePriceBlock={disablePriceBlock}
            fieldCanModifiedMap={fieldCanModifiedMap}
            formName={formName}
          />
        </Row>
      </AccordionPanel>
      <AccordionPanel title='products.form.byFacility' defaultExpanded={false}>
        <FieldArray
          name={`prices.facilities.${pathTail}`}
          component={FacilitySalesAttributesFieldArray}
          props={{
            facilityOptions: isWholesale ? facilityOptions : retailFacilityOptions,
            pushTemplate: generator(generatorProps, categoryId, pricingWeights, pricingGroupOptions, uomCode),
            AttributesComponent: PriceComponent,
            attributesComponentProps: {
              isWholesale,
              fieldLabel: ['products.form.basePrice', { uom }],
              pricingGroupOptions,
              pricingClasses,
              isMassModifyForm,
              statusOptions,
              showError,
              disablePriceBlock,
              fieldCanModifiedMap,
              formName
            }
          }}
        />
      </AccordionPanel>
    </AccordionPanel>
  );
};

PricingPanel.propTypes = {
  title: PropTypes.string.isRequired,
  sale_type: PropTypes.string.isRequired,
  pricing_type: PropTypes.string.isRequired,
  consumer_type: PropTypes.string,
  categoryId: PropTypes.number,
  pricingWeights: PropTypes.array.isRequired,
  pricingGroupOptions: PropTypes.array.isRequired,
  pricingClasses: PropTypes.array.isRequired,
  facilityOptions: PropTypes.array.isRequired,
  retailFacilityOptions: PropTypes.array.isRequired,
  isMassModifyForm: PropTypes.bool,
  showError: PropTypes.bool,
  fieldCanModifiedMap: PropTypes.object,
  formName: PropTypes.string,
  unitWeightUom: PropTypes.string,
};

PricingPanel.defaultProps = {
  isMassModifyForm: false,
  showError: false
};

export default PricingPanel;
