import {createSelector} from 'reselect';
import {pick} from 'lodash';
import get from 'lodash.get';
import map from 'lodash.map';
import * as UOMS from '../constants/uoms';
import * as dataNames from '../constants/dataNames';
import * as productionRunTypes from '../constants/productionRunTypes';

const getItemId = (_, item) => item && item.id;
export const getProductionRuns = state => state[dataNames.productionRuns];

export const getItemReservations = createSelector(
  [getProductionRuns, getItemId],
  (productionRuns, itemId) => productionRuns.reduce(
    (acc, productionRun) => {
      const itemReservation = productionRun.inputs.find(input => input.item_id === itemId);
      if (itemReservation) {
        acc.push({
          id: productionRun.id,
          name: productionRun.name,
          runType: productionRun.run_type,
          reservedQty: itemReservation.qty,
          uom: itemReservation.uom,
        });
      }
      return acc;
    },
    []
  )
);

export function mapProductionJobForListing(job, users) {
  const activeJob = pick(job, ['id', 'name', 'start_time', 'expected_completion_time', 'inputs', 'processing_type_id']);
  if (job.run_type === productionRunTypes.processing) {
    activeJob.process_type = job.processing_type && job.processing_type.name;
  } else {
    activeJob.process_type = job.assembly && job.assembly.name;
  }

  const jobInputData = (job.inputs || []).reduce(
    (acc, input, index) => ({
      uom: get(input, 'uom', UOMS.GR),
      total_weight_base: acc.total_weight_base + input.qty_base,
      lotList: acc.lotList.concat([{
        key: `${index}_${input.package_code}`,
        value: input.package_code,
      }]),
    }),
    {
      uom: UOMS.GR,
      total_weight_base: 0,
      lotList: [],
    }
  );

  activeJob.employeeList = (job.employees || []).reduce(
    (acc, employee) => {
      const user = users.find(user => user.id === employee.user_id);
      user && acc.push({
        key: user.id,
        value: `${user.first_name} ${user.last_name}`,
      });

      return acc;
    },
    []
  );

  return {
    ...activeJob,
    ...jobInputData,
    lotIdRow : jobInputData.lotList.map(lot => lot.value).join()
  };
}

export const getProductionRunPackageCodes = createSelector(
  [getProductionRuns],
  (productionRuns) => {
    const activeProductionRunOutputs = [];
    productionRuns.forEach(productionRun =>
      get(productionRun, 'outputs', []).forEach(output => activeProductionRunOutputs.push(output))
    );

    return map(activeProductionRunOutputs, 'package_code');
  });
