import {createSelector} from 'reselect';
import isEmpty from 'lodash.isempty';
import get from 'lodash.get';
import omit from 'lodash.omit';
import uuid from 'uuid';
import moment from 'moment';
import { formatDate } from '../../util/dateHelpers';
import * as itemNames from '../../constants/itemNames';
import {getPartner} from '../partnersSelectors';
import {isWasteTransferAvailableSelector} from '../compliance/waste/wasteComplianceSelectors';
import {hasCanadianFacilities} from '../facilitiesSelectors';
import {partnerFacilityTypes} from '../../constants/partnerFacilityTypes';
import { types } from '../../constants/fileUploads';
import {buildCompleteInternationalPhoneNumber} from '../../util/formatHelper';
import {getPhoneShouldIncludeCountryCode} from '../InternationalOperationsSelectors';

const getPartnerContact = (state) => state[itemNames.partnerContact];
const getTimezone = (state) => state.timezone;

export const getInitialValues = createSelector([getPartner, isWasteTransferAvailableSelector, hasCanadianFacilities, getTimezone, getPhoneShouldIncludeCountryCode],
  (partner, isWasteTransferAvailable, isCanadian, timezone, includeCountryCode) => {

    const initialValues = {
      active: 1,
      rating: 3,
      partnerTypes: [],
      group_ids: [],
      facility: [],
      facilities: [],
    };

    // Translate columns into array data
    const partnerTypes = []
      .concat(partner.purchase_from ? ['purchaseFrom'] : [])
      .concat(partner.sell_to ? ['sellTo'] : [])
      .concat(partner.is_lab ? ['lab'] : [])
      .concat(partner.is_import && isCanadian ? ['isImport'] : [])
      .concat(partner.is_export && isCanadian ? ['isExport'] : [])
      .concat(partner.is_waste_disposal && isWasteTransferAvailable ? ['wasteDisposal'] : []);

    // Update facilities to include easily accessed file data and facility types.
    partner.facilities = (partner.facilities || []).map((facility) => {
      facility.facility_phone = buildCompleteInternationalPhoneNumber(facility.facility_phone, facility.facility_phone_country_code, includeCountryCode);
      const newProperties = {
        facility_types: getFacilityTypes(facility),
        licenses: getLicensesFromServerData(facility.licenses),
      };
      return Object.assign({}, facility, newProperties);
    });

    return !partner || Object.keys(partner).length === 0
      ? initialValues
      : Object.assign({}, initialValues, partner, {partnerTypes});

  });

// Translate facility columns into array data
export const getFacilityTypes = (facility) => {
  return partnerFacilityTypes.map(fT => fT.value).map(value => facility[`is_${value}`] ? value : 0).filter((v) => v);
};

// Translate partner facility type array data into column flags
export const getFacilityTypeColumns = (facility) => {
  return partnerFacilityTypes.reduce((acc, facilityType) => {
    acc[`is_${facilityType.value}`] = (facility.facility_types || []).find((type) => {
      if(typeof type === 'string'){
        return type === facilityType.value ? 1 : 0;
      }
      return type && type.value && type.value === facilityType.value ? 1 : 0;
    }) ? 1 : 0;
    return acc;
  }, {});
};

// Pull nested file data up to license level
const getLicensesFromServerData = (licenses) => {
  return (licenses || []).map(file => ({
    id: file.id,
    type: file.license_type,
    name: file.license_number,
    expires_on: (file.expires_on) ? moment(file.expires_on) : null,
    file_type: types.document,
    file_id: file.license_file_id || null,
    file: file.file,
  }));
};

const getLicensesFromClientData = (licenses) => {
  return (licenses || []).map(file => ({
    id: file.id || undefined,
    license_type: file.type,
    license_number: file.name,
    expires_on: formatDate(file.expires_on),
    license_file_id: file.file_id || null,
    file: file.file,
  }));
};

export const getPayload = (formValues, partnerImage, timezone, partner) => {
  // Set partner payload
  const partnerTypes = formValues.partnerTypes.map((partnerType) => typeof partnerType === 'string' ? partnerType : partnerType.value);
  const payload = Object.assign({}, omit(formValues, ['partnerTypes', 'group_ids']), {
    purchase_from: partnerTypes.indexOf('purchaseFrom') > -1 ? 1 : 0,
    sell_to: partnerTypes.indexOf('sellTo') > -1 ? 1 : 0,
    is_lab: partnerTypes.indexOf('lab') > -1 ? 1 : 0,
    is_waste_disposal: partnerTypes.indexOf('wasteDisposal') > -1 ? 1 : 0,
    is_import: partnerTypes.indexOf('isImport') > -1 ? 1 : 0,
    is_export: partnerTypes.indexOf('isExport') > -1 ? 1 : 0,
    group_names: formValues.group_ids.map(groupIds => typeof groupIds === 'object' ? groupIds.group_name : groupIds),
    state_integration_id: formValues.state_integration_id
  }, {group_names: undefined, facility: undefined});
  if (partnerImage) {
    payload.image_file_id = partnerImage.id;
  }
  if(!get(partner, 'id', false)){ // Only on create
    payload.partner_code = get(formValues, 'partner_code', uuid.v4());
  } else {
    delete(payload.partner_code);
  }

  // Not sure why these are even present BUT have changed so much thought I'd leave this here
  delete(payload.facility);
  delete(payload.group_names);

  // Update facilities payload
  payload.facilities = (payload.facilities || []).map((facility) => {
    const f = Object.assign({}, facility, {
      ...getFacilityTypeColumns(facility),
      licenses: getLicensesFromClientData(facility.licenses),
      delete_license_ids: (((partner.facilities || [])
        .find((f) => f.id === facility.id) || {}).licenses || []) // Find this facility but original
        .filter((license) => { // find ids of licenses on original and not on facility to submit
          return !(facility.licenses || []).some((l) => l.id === license.id);
        }).map((license) => license.id)
    });
    delete(f.facility_types);
    return f;
  });

  return payload;
};

export const getPartnerFacilityData = (facilityData, partner, timezone) => (
  {
    ...facilityData,
    partner_id: partner.id,
    licenses: (facilityData.licenses || []).map(file => ({
      partner_id: partner.id,
      license_type: file.type,
      license_number: file.name,
      expires_on: formatDate(file.expires_on, null, null, timezone),
      license_file_id: file.file_id || null,
      file: file.file,
      partner_facility_id: facilityData.id
    })),
  }
);

const partnerContacts = {};

export const getPartnerContactInitialValues = createSelector(
  [getPartnerContact],
  (contact) => (formValues) => {
    if (formValues.length === 0) {
      return {
        contacts: [{
          facilities: [],
        }],
      };
    }


    if (!isEmpty(contact)) {
      partnerContacts[contact.id] = contact;
    }

    const listOfParnterContacts = Object.values(partnerContacts);
    if (listOfParnterContacts.length === (formValues.length - 1)) {
      return {
        contacts: formValues.map((form, index) => ({
          ...listOfParnterContacts[index] || {},
          ...form,
        })),
      };
    }
  });

export default getPartnerFacilityData;
