import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Button, ButtonToolbar, Col, Row} from 'react-bootstrap';
import {FaAsterisk} from 'react-icons/fa';
import InlineCheckBox from '../../common/form/InlineCheckBox';
import DatePickerInput from '../../common/form/DatePickerInput';
import NumericInput from '../../common/form/NumericInput';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import TextInput from '../../common/form/TextInput';
import TextAreaInput from '../../common/form/TextAreaInput';

const WaFromFields = ({reset, change, sections, invalid, pristine, handleSubmit,
                        finalWeight, hasFinalWeight, initialValues, handleSave}) => {
  return (
    <form className='modify-harvest-batch-form' onSubmit={handleSubmit(formData =>
      handleSave(Object.assign({}, formData, {calculated_moisture_loss: null, moisture_pct: null})))}>

      <Row>
        <Col md={{span:8, offset:2}}>
          <Field name='batch_name' component={TextInput} props={{
            label: I18n.t('harvestBatch.modify.name'),
            disabled: true
          }}/>
        </Col>
      </Row>

      <Row>
        <Col md={{span:4, offset:2}} className='text-align-right'>
          {I18n.t('harvestBatch.modify.originalWeight')}
        </Col>
        <Col md='4'>
          <Field name='wet_weight_harvest' component={NumericInput} props={{
            placeholder: I18n.t('harvestBatch.modify.originalWeightPlaceholder'),
            rightAddon: <span>{I18n.t('common.units.grams')}</span>,
            disabled: true
          }}/>
        </Col>
      </Row>

      <Row>
        <Col md={{span:4, offset:2}} className='text-align-right'>
          {I18n.t('harvestBatch.modify.currentFlowerWeight')}
        </Col>
        <Col md='4'>
          <Field name='current_weight' component={NumericInput} props={{
            rightAddon: <span>{I18n.t('common.units.grams')}</span>,
            disabled: true
          }}/>
        </Col>
      </Row>

      <Row>
        <Col md={{span:4, offset:2}} className='text-align-right'>
          {I18n.t('harvestBatch.modify.currentOtherMaterialWeight')}
        </Col>
        <Col md='4'>
          <Field name='current_other_material_weight' component={NumericInput} props={{
            rightAddon: <span>{I18n.t('common.units.grams')}</span>,
            disabled: true
          }}/>
        </Col>
      </Row>

      <Row>
        <Col md={{span:4, offset:2}} className='text-align-right'>
          {I18n.t('harvestBatch.modify.flowerWaste')}
        </Col>
        <Col md='4'>
          <Field name='reportWaste' component={NumericInput} props={{
            rightAddon: <span>{I18n.t('common.units.grams')}</span>,
            fractionPartSize: 2,
            step: 0.01,
            min: 0,
            disabled: hasFinalWeight && finalWeight,
          }}
          />
        </Col>
      </Row>

      <Row>
        <Col md={{span:4, offset:2}} className='text-align-right'>
          {I18n.t('harvestBatch.modify.otherMaterialsWaste')}
        </Col>
        <Col md='4'>
          <Field name='otherMaterialWaste' component={NumericInput} props={{
            rightAddon: <span>{I18n.t('common.units.grams')}</span>,
            fractionPartSize: 2,
            step: 0.01,
            min: 0,
            disabled: hasFinalWeight && finalWeight,
          }}
          />
        </Col>
      </Row>

      <Row className='layout-row layout-align-start-end'>
        <Col md={{span:4, offset:2}} className='text-align-right'>
          <FaAsterisk style={{ fontSize: '0.5em', verticalAlign: 'top', marginTop: '1px' }} />
          <span style={{fontWeight: 'bold'}}>{I18n.t('harvestBatch.modify.newFlowerWeight')}</span>
        </Col>
        <Col md='4'>
          <Field name='newWeight' component={NumericInput} props={{
            rightAddon: <span>{I18n.t('common.units.grams')}</span>,
            fractionPartSize: 2,
            step: 0.01,
            min: 0,
            isRequired: true,
            disabled: hasFinalWeight && finalWeight
          }}
          />
        </Col>
        <Col md='2'>
          <Field
            name='finalWeight'
            component={InlineCheckBox}
            props={{
              label: I18n.t('harvestBatch.modify.finalWeight'),
              disabled: hasFinalWeight && finalWeight
            }}
            onChange={(event) => {
              const value = event.target.checked;
              if (value && hasFinalWeight) {
                change('newWeight', initialValues.newWeight);
                change('reportWaste', '0.00');
                change('otherMaterialWaste', '0.00');
                change('currentMoisture', '0.00');
                change('newOtherMaterialWeight', initialValues.newOtherMaterialWeight);
              }
              change('finalWeight', value);
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col md={{span:4, offset:2}} className='text-align-right'>
          <FaAsterisk style={{ fontSize: '0.5em', verticalAlign: 'top', marginTop: '1px' }} />
          <span style={{fontWeight: 'bold'}}>{I18n.t('harvestBatch.modify.newOtherMaterialWeight')}</span>
        </Col>
        <Col md='4'>
          <Field name='newOtherMaterialWeight' component={NumericInput} props={{
            rightAddon: <span>{I18n.t('common.units.grams')}</span>,
            fractionPartSize: 2,
            step: 0.01,
            min: 0,
            disabled: hasFinalWeight && finalWeight
          }}
          />
        </Col>
      </Row>

      <Row>
        <Col md={{span:4, offset:2}} className='text-align-right'>
          {I18n.t('harvestBatch.modify.date')}
        </Col>
        <Col md='4'>
          <Field name='action_date' component={DatePickerInput} />
        </Col>
      </Row>

      <Row>
        <Col md={{span:4, offset:2}} className='text-align-right'>
          <FaAsterisk style={{ fontSize: '0.5em', verticalAlign: 'top', marginTop: '1px' }} />
          <span style={{fontWeight: 'bold'}}>{I18n.t('harvestBatch.modify.storageArea')}</span>
        </Col>
        <Col md='4'>
          <Field
            name={'inventory_location_id'}
            component={ReactSelectInput}
            props={{
              options: sections,
              placeholder: I18n.t('common.form.selectPlaceholder'),
            }}/>
        </Col>
      </Row>

      <Row>
        <Col md={{span:4, offset:2}} className='text-align-right'>
          {I18n.t('registers.form.notes')}
        </Col>
        <Col md='4'>
          <Field
            name='notes'
            component={TextAreaInput}
            props={{
              rows: 3,
              groupClassName: 'notes-field'
            }}/>
        </Col>
      </Row>

      <ButtonToolbar className='float-right'>
        <Button type='reset'
                onClick={reset}>
          {I18n.t('common.form.reset')}
        </Button>
        <Button variant='primary'
                disabled={invalid || pristine}
                type='submit'>
          {I18n.t('common.form.save')}
        </Button>
      </ButtonToolbar>
    </form>
  );
};

WaFromFields.propTypes = {
  change: PropTypes.func,
  reset: PropTypes.func.isRequired,
  sections: PropTypes.array.isRequired,
  hasFinalWeight: PropTypes.bool,
  finalWeight: PropTypes.bool,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  initialValues: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired
};

WaFromFields.defaultProps = {
  finalWeight: false
};

export default WaFromFields;
