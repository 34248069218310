import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getItem, getDataBatchByPost, getUnpaginatedData} from '../../../actions/apiActions';
import * as itemNames from '../../../constants/itemNames';
import CureApiPurchasableAmountComponent from './CureApiPurchasableAmountComponent';
import {
  isCureIntegrator,
  getCurePatientOrderGroups,
  getCurePatientOrderGroupsAgainstOrder
} from '../../../selectors/integration/cureApiSelectors';
import {getComplianceLimits} from '../../../selectors/customersSelectors';

function mapStateToProps(state, ownProps) {
  const customerType = ownProps.customer && ownProps.customer.type
    ? ownProps.customer.type
    : ownProps.customer.customer && ownProps.customer.customer.type
      ? ownProps.customer.customer.type
      : 'medical';
  return {
    isCure: isCureIntegrator(state),
    groups: ownProps.showOrderData ? getCurePatientOrderGroupsAgainstOrder(state, ownProps) : getCurePatientOrderGroups(state, ownProps),
    compliance: getComplianceLimits(state, {customer: {type: customerType}}),
    order: state[itemNames.order],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({getItem, getDataBatchByPost, getUnpaginatedData,}, dispatch),
  };
}

const CureApiPurchasableAmount = connect(mapStateToProps, mapDispatchToProps)(CureApiPurchasableAmountComponent);

CureApiPurchasableAmount.propTypes = {
  customer: PropTypes.object,
  orderId: PropTypes.number,
  showHeader: PropTypes.bool,
  className: PropTypes.string,
  curePatient: PropTypes.object,
  mode: PropTypes.oneOf(['customer', 'patient']),
  showOrderData: PropTypes.bool,
};

CureApiPurchasableAmount.defaultProps = {
  showHeader: true,
  className: '',
  showOrderData: false,
};

export default CureApiPurchasableAmount;
