/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {bindActionCreators} from 'redux';
import {goBack} from 'react-router-redux';
import {change, formValueSelector} from 'redux-form';
import get from 'lodash.get';
import {convertFormInputDateToDbDate, convertFormInputDateToYYYYMMDD} from '../../../util/dateHelpers';
import {fetchMetrcTrackingIdsForSelectInput} from '../../../actions/integrationActions';
import {EA} from '../../../constants/uoms';
import {PACKAGE_PLANTS_FORM} from '../../../constants/forms';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import * as apiActions from '../../../actions/apiActions';
import FormWrapper from '../../common/form/FormWrapper';
import PackagePlantsForm from './PackagePlantsForm';
import PrinterModal from '../../printer/PrinterModal';
import InProgressOverlay from '../../common/InProgressOverlay';
import reduxMetrcIdAvailability, {setUnavailableTrackingIdFieldError} from '../../common/form/redux-form/reduxMetrcIdAvailability';
import {getPackagePlantsPageProps} from '../../../selectors/forms/packagePlantsFormSelectors';
import {hasPackagesTags} from '../../../selectors/integrationSelectors';
import {isFeatureEnabled} from '../../../selectors/featureToggles';


class PackagePlantsPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.exitPage = this.exitPage.bind(this);

    this.setLotNumber = this.setLotNumber.bind(this);
    this.hidePrinter = this.hidePrinter.bind(this);

    this.printPackageLabel = this.printPackageLabel.bind(this);
    this.printPackageLabelAfterSave = this.printPackageLabelAfterSave.bind(this);
    this.printSavedPackageLabels = this.printSavedPackageLabels.bind(this);

    this.state = {
      printPackageLabelAfterSave: false,
      package: {},
      saveComplete: false,
      loading: true,
      saving: false,
    };

  }

  componentWillMount() {
    const {selectedPlants, integrationState: {isMetrc, isBiotrack}} = this.props;
    const strainId = selectedPlants[0] ? selectedPlants[0].strain_id : '';
    const promises = [];
    if (isBiotrack) {
      promises.push(
        this.props.actions.getUnpaginatedData('/api/biotrack/categories', dataNames.biotrackCategories),
        this.props.actions.getUnpaginatedData('/api/biotrack/categories/mapping', dataNames.biotrackCategoryMappings)
      );
    }
    Promise
      .all(promises)
      .then(() => {
        const searchParams = {
          active: 1,
          strain_id: strainId,
          select_columns: [
            'id', 'name', 'strain_id', 'category_id', 'subcategory_id', 'default_uom', 'subcategory_code'
          ],
          per_page: 0
        };

        // NOTE: Other than Biotrack logic here, other integrator/non-integrator specific logic has been moved
        // to the backend (within the is_packagable_for_plants filter). The intent is to move the Biotrack logic at some
        // point, but that needs more investigation first as the gathering of the subcategoryIds appears to
        // be quite involved
        if (isBiotrack) {
          const {subcategoryIds} = this.props;
          const ids = subcategoryIds.length ? subcategoryIds : [0];

          searchParams.uom_type = 'discrete';
          searchParams.default_uom = EA;
          searchParams.in_subcategory_ids = ids;
        } else {
          searchParams.is_packagable_for_plants = 1;
        }

        return this.props.actions.getUnpaginatedData(
          '/api/item_masters/search',
          dataNames.itemMasters,
          {failed: 'products.get.failed'},
          searchParams
        );
      })
      .then(() => this.setState({loading: false}))
      .catch(() => this.setState({loading: false}));
    this.getLocations();
    if (isMetrc) {
      this.props.actions.fetchMetrcTrackingIdsForSelectInput();
    }

    this.props.actions.getItem(
      '/api/settings/plants',
      itemNames.complianceSettings,
      {failed: 'cultivation.complianceSettings.failed'},
      {ids: [
        'cult_track_plants_as_groups'
      ]}
    );
  }

  getLocations() {
    this.props.actions.getUnpaginatedData(
      '/api/location_hierarchy',
      dataNames.locations,
      {failed: 'locations.getLocations.failed'}, {is_sales_location: 0}
    );
  }

  setLotNumber(data) {
    if (data.package_code) {
      this.props.actions.change(PACKAGE_PLANTS_FORM, 'lot_number', data.package_code);
    }
    this.setState({package: data, saveComplete: true, saving: false});
    if (this.state.printPackageLabelAfterSave) this.printPackageLabel(data);
  }

  onSubmit(formData) {
    if (this.state.saveComplete) {
      if (this.state.printPackageLabelAfterSave) {
        this.printPackageLabel(this.state.package);
      }
      return false;
    }

    const clearLoader = () => {
      this.setState({saving: false});
    };

    this.setState({saving: true});
    const {selectedPlants, validateTrackingIdsAvailability, integrationState: {isCanada} } = this.props;
    const event_date = convertFormInputDateToDbDate(formData.datePackaged, this.props.timezone);
    const packaging_date = convertFormInputDateToYYYYMMDD(formData.datePackaged, this.props.timezone);
    const packageData = {
      storage_location_id: formData.storage_location_id,
      item_master_id: formData.item_master_id,
      event_date,
      package_created_at: event_date,
      package_created_on: packaging_date,
      plant_ids: selectedPlants.map(plant => plant.id),
      notes: formData.notes,
      state_integration_tracking_id: formData.package_individual_plants ? undefined : (formData.state_integration_tracking_id || undefined),
      tag_requested: this.props.hasPackagesTags ? formData.tag_requested || 0 : 0,
      purpose: formData.purpose,
      split_packages: this.props.integrationState.isBiotrack,
      finished: isCanada ? formData.finished_inventory : undefined,
      finished_at: formData.finished_inventory &&  formData.finished_inventory_dirty ? moment().format('YYYY-MM-DD HH:mm:ss') : undefined,
      package_individual_plants: formData.package_individual_plants,
      is_donation: formData.is_donation == true,
      is_trade_sample: formData.is_trade_sample == true,
      qty: formData.qty,
      deactivate_plants: !!formData.deactivate_plants
    };

    if (formData.package_individual_plants) {
      packageData.individual_plants = formData.individual_plants;
    }

    return validateTrackingIdsAvailability(packageData, setUnavailableTrackingIdFieldError)
      .then(() => {
        return this.props.actions.postItem(
          '/api/plants/package',
          packageData,
          itemNames.pkg,
          {success: 'plants.createPackages.success', failed: 'plants.createPackages.failed'},
          {},
          this.setLotNumber
        );
      })
      .then(clearLoader)
      .catch(clearLoader);
  }

  printPackageLabelAfterSave() {
    this.setState({printPackageLabelAfterSave: true});
  }

  printSavedPackageLabels() {
    this.printPackageLabel(this.state.package);
  }

  printPackageLabel(data) {

    this.setState({printPackageLabelAfterSave: false});

    const ids = data.packaged_plants.map(plant => plant.item_id);
    const tag = 'inv_package_tag_all';
    this.setState({showPrinter: true, labelTag: tag, labelIds: ids, redirect: false});

  }

  hidePrinter() {
    this.setState({showPrinter: false});
  }

  exitPage() {
    this.props.actions.goBack();
  }

  render() {
    const {showPrinter, labelTag, labelIds, saving, loading} = this.state;
    const message = loading ? 'common.loading' : 'plants.packagePlants.actions.packagingPlantsMessage';
    const {
      complianceSettings, selectedPlants, integrationState, trackingIds, selectedItemMaster,
      packagingIndivialPlants, hasMotherPlants, isTradeSample, isDonation, isPaUnmedicatedWholesaleSubcategoriesEnabled
    } = this.props;
    const currentStrain = selectedPlants[0] ? selectedPlants[0].strain_name : '';
    const currentPhase = selectedPlants[0] ? selectedPlants[0].code : '';

    const allowIndividualPackagingMetrcCa = integrationState.isCaMetrc && ['flower', 'veg'].includes(currentPhase);

    const strain = {
      type: currentStrain,
      quantity: selectedPlants.length,
      plants: selectedPlants.map(plant => {
        return {id: plant.id, plantId: plant.plant_id, trackingId: plant.tracking_id};
      })
    };
    const initialValues = {
      /*
        This on/off checkbox is only shown for Canadian facilities and is always defaulted to 1, for now
       */
      finished_inventory : 1,
      tag_requested : 1,
      package_individual_plants: packagingIndivialPlants || allowIndividualPackagingMetrcCa,
      individual_plants: selectedPlants && selectedPlants.map(selectedPlant => {
        return {
          id: selectedPlant.id,
          plant_id: selectedPlant.plant_id,
          count: null,
          state_integration_tracking_id: null,
        };
      }),
      qty: null,
      deactivate_plants: true
    };

    return (
      <div className='package-plants'>
        <FormWrapper title={'plants.packagePlants.title'} goBack={this.props.actions.goBack}>
          <InProgressOverlay isActive={loading || saving} message={message} translate={true}/>
          <PackagePlantsForm
            onSubmit={this.onSubmit}
            initialValues={initialValues}
            saveComplete={this.state.saveComplete}
            exitPage={this.exitPage}
            strain={strain}
            form={PACKAGE_PLANTS_FORM}
            locations={this.props.locations}
            existingItemMasters={this.props.existingItemMasters}
            lotNumber={this.props.lotNumber}
            saveAndPrint={this.saveAndPrint}
            printSavedPackageLabels={this.printSavedPackageLabels}
            printPackageLabelAfterSave={this.printPackageLabelAfterSave}
            integrationState={integrationState}
            trackingIds={trackingIds}
            loading={loading}
            trackPlantsAsGroups={get(complianceSettings,'cult_track_plants_as_groups.value',false)}
            selectedItemMaster={selectedItemMaster}
            hasPackagesTags={this.props.hasPackagesTags}
            packagingIndivialPlants={packagingIndivialPlants}
            currentPhase={currentPhase}
            hasMotherPlants={hasMotherPlants}
            isTradeSample={isTradeSample}
            isDonation={isDonation}
            isPaUnmedicatedWholesaleSubcategoriesEnabled={isPaUnmedicatedWholesaleSubcategoriesEnabled}
            allowIndividualPackagingMetrcCa={allowIndividualPackagingMetrcCa}
          />
          <PrinterModal
            ref='printerModal'
            forceLabelBlocks={true}
            showPrinter={showPrinter}
            hidePrinter={this.hidePrinter}
            labelTag={labelTag}
            labelIds={labelIds}
          />
        </FormWrapper>
      </div>
    );
  }
}

PackagePlantsPage.propTypes = {
  locations: PropTypes.array.isRequired,
  selectedPlants: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    getData: PropTypes.func.isRequired,
    fetchMetrcTrackingIdsForSelectInput: PropTypes.func.isRequired
  }),
  itemMasterId: PropTypes.number.isRequired,
  lotNumber: PropTypes.string.isRequired,
  existingItemMasters: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
  trackingIds: PropTypes.array.isRequired,
  timezone: PropTypes.string.isRequired,
  validateTrackingIdsAvailability: PropTypes.func.isRequired,
  complianceSettings: PropTypes.object.isRequired,
  hasPackagesTags: PropTypes.bool,
  hasMotherPlants: PropTypes.bool,
  packagingIndivialPlants: PropTypes.bool,
  isTradeSample: PropTypes.bool,
  isDonation: PropTypes.bool,
};

const selector = formValueSelector(PACKAGE_PLANTS_FORM);

function mapStateToProps(state){
  const packagingIndivialPlants = !!selector(state, 'package_individual_plants');
  const props = getPackagePlantsPageProps(state);

  return Object.assign({}, props, {
    packagingIndivialPlants,
    hasPackagesTags: hasPackagesTags(state),
    isPaUnmedicatedWholesaleSubcategoriesEnabled: isFeatureEnabled(state)('feature_pa_unmedicated_wholesale_subcategories')
  });
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {goBack, fetchMetrcTrackingIdsForSelectInput, change});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default reduxMetrcIdAvailability(connect(mapStateToProps, mapDispatchToProps)(PackagePlantsPage));
