
import {groupedByItemMasterId} from '../../selectors/inventoryItemsSelectors';
import {getChildItemMastersForFacility, getCreateTransferPrepackItemMasterChildren, getModifyTransferPrepackItemMasterChildren} from '../../selectors/transfersSelectors';

// Source is just a selector that in use on transfers... not a specific platform source
class PackageStore {
  constructor(state) {
    this.state = state;
    this.packagesFound = [];
  }

  getPackagesFound() {
    return this.packagesFound;
  }

  searchTransferPackagesBySource(source, type) {
    const state = this.state;
    const sourceMap = {
      nonPrePack: groupedByItemMasterId,
      prePack: getChildItemMastersForFacility,
      prePackChildren: (type === 'create')
        ? getCreateTransferPrepackItemMasterChildren
        : getModifyTransferPrepackItemMasterChildren
    };
    if(source === 'nonPrePack') {
      return sourceMap[source](state);
    }
    const collectionObject = sourceMap[source](state);
    return Object.keys(collectionObject).reduce((acc, key) => {
      const child = collectionObject[key];
      if (!Array.isArray(child)) {
        return acc;
      }
      acc = acc.concat(child.reduce((acc, grandchild) => {
        if(!grandchild.items) return acc;
        acc = acc.concat(grandchild.items);
        return acc;
      }, []));
      return acc;
    }, []);
  }

  findTransferPackageByField(value, field, type) {
    const _this = this;
    const packageSources = ['nonPrePack', 'prePack', 'prePackChildren'];
    return packageSources.reduce((acc, source) => {
      if(acc) return acc;
      const codes = _this.searchTransferPackagesBySource(source, type);
      const packagesMatching = (!Array.isArray(codes))
        ? Object.keys(codes).reduce((acc, key) => {
          if(acc) return acc;
          const packages = codes[key];
          const p = packages.filter((p) => p[field] === value);
          if(p.length) acc = p;
          return acc;
        }, false)
        : codes.filter((p) => p[field] === value);
      if(Array.isArray(packagesMatching) && packagesMatching.length){
        _this.packagesFound = _this.packagesFound.concat(packagesMatching);
        return packagesMatching.shift();
      }
      return false;
    }, false);
  }
}

export default PackageStore;