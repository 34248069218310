import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {push} from 'react-router-redux';
import {change} from 'redux-form';
import * as dataNames from '../../../../constants/dataNames';
import * as itemNames from '../../../../constants/itemNames';
import * as messageTypes from '../../../../constants/messageTypes';
import {CURE_API_PATIENT, CURE_API_CAREGIVER} from '../../../../constants/idTypes';
import {getDataUpdateAvailable, dataUpdateSetAvailable} from '../../../../selectors/dataUpdateSelectors';
import {
  getCureApiPayload, getMatchingCustomer, hasRequiredFields,
  isCureCaregiverMode
} from '../../../../selectors/forms/checkinCureApiFormSelectors';
import {addMessage} from '../../../../actions/systemActions';
import {setItem} from  '../../../../actions/itemActions';
import {setData} from  '../../../../actions/dataActions';
import {postItem, getItem, getUnpaginatedData, getSearchData, getDataByPost} from '../../../../actions/apiActions';
import InProgressOverlay from '../../../common/InProgressOverlay';
import CheckinCureApiFormWrapper from './CheckinCureApiFormWrapper';
import PatientDetailsCureApi from './PatientDetailsCureApi';
import CaregiverDetailsCureApi from './CaregiverDetailsCureApi';
import {getCureCaregiver, getCurePatient} from '../../../../selectors/oracleSelectors';


export class CheckinCureApiPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {loading: false, showScanModal: false, license_type: null, expanded: {}, customerId: null};
    this.onDetailsExpand = this.onDetailsExpand.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.ensureCustomer = this.ensureCustomer.bind(this);
    this.getCureApiDetails = this.getCureApiDetails.bind(this);
    this.loadCustomers = this.loadCustomers.bind(this);
    this.onRegister = this.onRegister.bind(this);
  }

  componentWillMount() {
    this.props.actions.getItem('/api/csrs', itemNames.budTendersObject, {failed: I18n.t('customers.getBudTenders.failed')});
    this.props.actions.getUnpaginatedData('/api/queue/visit_reasons', dataNames.visitReasons);
  }

  componentWillReceiveProps(nextProps) {
    if (dataUpdateSetAvailable(nextProps.dataUpdateAvailable, this.props.dataUpdateAvailable)) {
      setTimeout(() => this.loadCustomers(this.state.customerId), 100);
    }
  }

  onDetailsExpand(consumerId, isExpanded) {
    this.setState(state => ({
      expanded: {
        ...state.expanded,
        [consumerId]: isExpanded,
      },
    }));
  }

  onSearch(formData) {
    this.setState({loading: true});
    return this.ensureCustomer(formData)
      .then(customerId => Promise.all([
        this.getCureApiDetails(formData, customerId),
        this.loadCustomers(customerId),
      ]))
      .then(() => {
        this.setState({license_type: formData.license_type, loading: false});
      })
      .catch(() => this.setState({loading: false}));
  }

  ensureCustomer(formData) {
    const params = {search_identification_number: formData.patient_number};
    this.setState({license_type: null, customerId: null});

    return this.props.actions
      .getUnpaginatedData('/api/customers', null, {failed: 'consumers.get.failed'}, params)
      .then((customers) => {
        const matchingCustomer = getMatchingCustomer(customers, formData);
        if (!matchingCustomer && customers.length > 0) {
          this.props.actions.addMessage(messageTypes.error, 'consumers.checkin.customerDataDoesNotMatch');
          return Promise.reject(null);
        } else if (!matchingCustomer && !hasRequiredFields(formData)) {
          this.props.actions.addMessage(messageTypes.error, 'consumers.checkin.populateCustomerData');
          return Promise.reject(null);
        }
        const customerId = matchingCustomer && matchingCustomer.id || null;
        this.setState({customerId});
        return customerId;
      });
  }

  getCureApiDetails(formData, consumer_id) {
    const url = `/api/cureapi/${formData.license_type === CURE_API_CAREGIVER ? 'caregiver_details' : 'patient_details'}`;
    const name = formData.license_type === CURE_API_CAREGIVER ? itemNames.cureCaregiver : itemNames.curePatient;
    const payload = getCureApiPayload(formData, consumer_id);

    return this.props.actions.postItem(
      url,
      payload,
      name,
      {
        failedHandler: (error) => {
          if (error && error.response && error.response.data && error.response.data.errors && error.response.data.errors.CUREAPI) {
            return {message: error.response.data.errors.CUREAPI[0] || 'checkin.getPatient.failed'};
          }
          return {message:  'checkin.getPatient.failed'};
        }
      }
    );
  }

  loadCustomers(consumer_id) {
    if (consumer_id) {
      const params = {
        filter: `id:(${consumer_id})`,
        sort: 'id asc',
        query: 'matchall',
        size: 10000,
      };
      return this.props.actions.getSearchData(
        '/api/search/customers',
        dataNames.customers,
        {failed: 'consumer.checkin.getCustomersFailed'},
        params,
        (data) => {
          let ids = data ? data.map(customer => customer.image_file_id).filter(Boolean) : [];
          ids = ids.filter(v => v != null);
          if (ids.length) {
            this.props.actions.getDataByPost('/api/images/multiple', {ids}, dataNames.images);
          }
        }
      );
    }
  }

  onRegister(patient) {
    return this.props.actions.setItem(patient, itemNames.curePatient)
      .then(() => this.props.actions.push('/patients/create'));
  }

  render() {
    const {patient, caregiver, timezone, budTenders, isCaregiverMode} = this.props;
    const {loading, expanded, license_type} = this.state;
    return (
      <div>
        <InProgressOverlay message='common.searching' isActive={loading} translate={true}/>
        <h1>{I18n.t('consumers.checkin.checkIn')}</h1>

        <CheckinCureApiFormWrapper
          isCaregiverMode={isCaregiverMode}
          onSubmit={this.onSearch}
        />

        {license_type === CURE_API_PATIENT ?
          <Row>
            <PatientDetailsCureApi
              patient={patient}
              onRegister={this.onRegister}
              budTenders={budTenders}
              timezone={timezone}
              expanded={expanded}
              onDetailsExpand={this.onDetailsExpand} />
          </Row>
          : null
        }
        {license_type === CURE_API_CAREGIVER ?
          <CaregiverDetailsCureApi
            caregiver={caregiver}
            onRegister={this.onRegister}
            budTenders={budTenders}
            timezone={timezone}
            expanded={expanded}
            onDetailsExpand={this.onDetailsExpand}
          />
          : null
        }
      </div>
    );
  }
}

CheckinCureApiPage.propTypes = {
  patient: PropTypes.object.isRequired,
  caregiver: PropTypes.object.isRequired,
  budTenders: PropTypes.array.isRequired,
  timezone: PropTypes.string,
  isCaregiverMode: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    postItem: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    setItem: PropTypes.func.isRequired,
    setData: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    addMessage: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    getSearchData: PropTypes.func.isRequired,
    getDataByPost: PropTypes.func.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  const budTendersObject = state[itemNames.budTendersObject];
  const budTenders = Object.keys(budTendersObject).map(id => ({id, name: budTendersObject[id]}));
  return {
    patient: getCurePatient(state),
    caregiver: getCureCaregiver(state),
    timezone: state.timezone,
    dataUpdateAvailable: [getDataUpdateAvailable(state, {name: dataNames.customers, core: 'customers'})],
    budTenders,
    isCaregiverMode: isCureCaregiverMode(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {push, setItem, setData, postItem, getItem, change, addMessage, getUnpaginatedData, getSearchData, getDataByPost};
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckinCureApiPage);
