import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {FormGroup} from 'react-bootstrap'; 
import {FieldArray} from 'redux-form';
import TestsFieldArray from './TestsFieldArray';
import SubmitSection from '../../../common/form/SubmitSection';

export const TestingComplianceForm = ({handleSubmit, submitting, invalid, testData, formValues, testOptions}) => {

  const settings = {
    actionSettings: {
      submit: {
        submitting,
        invalid,
        text: I18n.t('common.form.submit')
      }
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <FormGroup>
        <FieldArray
          formValues={formValues}
          testData={testData}
          testOptions={testOptions}
          name='tests'
          component={TestsFieldArray}
        />
      </FormGroup>
      <SubmitSection settings={settings}/>
    </form>
  );
};

TestingComplianceForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  testData: PropTypes.object,
  formValues: PropTypes.object,
  testOptions: PropTypes.array
};

export default TestingComplianceForm;

