import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {I18n} from 'react-redux-i18n';
import {push} from 'react-router-redux';
import {Button} from 'react-bootstrap';
import * as dataNames from '../../../constants/dataNames';
import * as messageTypes from '../../../constants/messageTypes';
import * as selectedDataActions from '../../../actions/selectedDataActions';
import * as apiActions from '../../../actions/apiActions';
import {handleSingleSelectedRow} from '../../../actions/helpers/selectedDataHelper';
import {addMessage} from '../../../actions/systemActions';
import {unsetData} from '../../../actions/dataActions';
import {getSelectedAssemblyIds, getAssembliesWithCosts} from '../../../selectors/assembliesSelectors';
import TablePageWrapper from '../../common/grid/TablePageWrapper';
import PageTitle from '../../common/PageTitle';
import columnDefinitions from './common/columnDefinitions';


export class AssemblyListingPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.ref = React.createRef();
    this.handleSelect = this.handleSelect.bind(this);
    this.switchTab = this.switchTab.bind(this);
    this.isActiveTab = this.isActiveTab.bind(this);
    this.onTabChanged = this.onTabChanged.bind(this);
    this.editAssembly = this.editAssembly.bind(this);
    this.activateAssembly = this.activateAssembly.bind(this);
    this.deactivateAssembly = this.deactivateAssembly.bind(this);

    const tabs = [
      {
        id: 'assembliesActiveTab',
        eventKey: 'active',
        title: 'ei.common.active',
        route: '/ei/assemblies',
        actions: [
          {
            id: 'createAssembly',
            path: '/ei/assemblies/create',
            text: 'ei.assemblies.createAssembly',
            glyph: 'plus-sign',
            requireSelect: false,
          },
          {
            id: 'modifyAssembly',
            func: this.editAssembly,
            text: 'ei.assemblies.editAssembly',
            glyph: 'th-list',
            requireSelect: true,
          },
        ],
      },
      {
        id: 'assembliesInactiveTab',
        eventKey: 'inactive',
        title: 'ei.assemblies.inactive',
        route: '/ei/assemblies/inactive',
        actions: [
          {
            id: 'createAssembly',
            path: '/ei/assemblies/create',
            text: 'ei.assemblies.createAssembly',
            glyph: 'plus-sign',
            requireSelect: false,
          },
        ],
      },
    ];
    const activeColumns = columnDefinitions.assemblyListingColumns.concat([
      {
        name: '',
        hidden: false,
        width: '80px',
        dataId: 'id',
        dataSort: false,
        formatter: (id, row) => (
          <Button type='button' variant='primary' onClick={(event) => this.deactivateAssembly(event, row)}>
            {I18n.t('ei.assemblies.deactivate')}
          </Button>
        ),
      },
    ]);
    const inactiveColumns = columnDefinitions.assemblyListingColumns.concat([
      {
        name: '',
        hidden: false,
        width: '80px',
        dataId: 'id',
        dataSort: false,
        formatter: (id, row) => ( //eslint-disable-line react/no-multi-comp
          <Button type='button' variant='primary' onClick={(event) => this.activateAssembly(event, id)}>
            {I18n.t('ei.assemblies.activate')}
          </Button>
        ),
      },
    ]);

    this.state = {
      tabs,
      activeColumns,
      inactiveColumns,
    };
  }

  componentWillMount() {
    this.onTabChanged(this.props.params.status || 'active');
  }

  componentWillReceiveProps(newProps) {
    if (newProps.params.status !== this.props.params.status) {
      this.onTabChanged(newProps.params.status || 'active');
    }
  }

  switchTab(tab) {
    this.props.actions.push(`/ei/assemblies${tab === 'inactive' ? '/inactive' : ''}`);
  }

  isActiveTab(tab = this.props.params.status) {
    return tab !== 'inactive';
  }

  onTabChanged(tab) {
    this.setState({loading: true});
    const {clearSelectedData, getUnpaginatedData, getDataByPost} = this.props.actions;
    const params = {detailed: 1, active: this.isActiveTab(tab) ? 1 : 0, sort: 'name asc'};
    clearSelectedData(dataNames.assemblies);
    return getUnpaginatedData('/api/assemblies', dataNames.assemblies, {failed: 'ei.assemblies.get.failed'}, params)
      .then(() => {
        const {assemblies} = this.props;
        const {itemMasterIds, ingredientIds} = assemblies.reduce(
          (acc, assembly) => {
            if (acc.itemMasterIds.indexOf(assembly.item_master_id) < 0) {
              acc.itemMasterIds.push(assembly.item_master_id);
            }
            if (assembly.ingredients && assembly.ingredients.length) {
              acc.ingredientIds.push(...assembly.ingredients.map(i => i.item_master_id));
            }
            return acc;
          },
          {
            itemMasterIds: [],
            ingredientIds: []
          }
        );
        const promises = [];
        if (itemMasterIds.length > 0) {
          promises.push(getDataByPost(
            '/api/item_masters/multiple',
            {ids: itemMasterIds},
            dataNames.itemMasters,
            {failed:'products.get.failed'},
            {detailed: 1}
          ));
        }
        if (ingredientIds.length > 0) {
          promises.push(getDataByPost(
            '/api/costing/item_masters/multiple',
            {ids: ingredientIds},
            dataNames.costings
          ));
        }
        return Promise.all(promises);
      })
      .then(() => this.setState({loading: false}))
      .catch(() => this.setState({loading: false}));
  }

  handleSelect (isSelected, rows) {
    this.props.actions.handleSingleSelectedRow(rows, dataNames.assemblies, isSelected);
  }

  editAssembly() {
    this.props.actions.push(`/ei/assemblies/modify/${this.props.selectedAssemblyIds[0]}`);
  }

  activateAssembly(event, assemblyId) {
    event.stopPropagation();
    this.props.actions.putData(
      `/api/assemblies/${assemblyId}`,
      {active: 1},
      dataNames.assemblies,
      {failed: 'ei.assemblies.activation.failed', success: 'ei.assemblies.activation.succeed'},
      null,
      () => {
        this.onTabChanged(this.props.params.status);
      }
    );
  }

  deactivateAssembly(event, assembly) {
    event.stopPropagation();
    if (assembly.active_production_runs_count && assembly.active_production_runs_count.active_production_runs_count > 0) {
      return this.props.actions.addMessage(messageTypes.error, [
        'ei.assemblies.deactivation.activeJobsError',
        {assemblyName: assembly.name}
      ]);
    }
    this.props.actions.putData(
      `/api/assemblies/${assembly.id}`,
      {active: 0},
      dataNames.assemblies,
      {failed: 'ei.assemblies.deactivation.failed', success: 'ei.assemblies.deactivation.succeed'},
      null,
      () => {
        this.onTabChanged(this.props.params.status);
      }
    );
  }

  render () {
    const {assemblies, selectedAssemblyIds} = this.props;
    const {tabs, activeColumns, inactiveColumns} = this.state;
    const isActiveTab = this.isActiveTab();


    return (
      <div>
        <PageTitle primaryText={I18n.t('ei.assemblies.assemblies')}/>
        <TablePageWrapper
          ref={this.ref}
          settingKey='ei-assemblies'
          className = 'assembly-page'
          activeTab = {isActiveTab ? 'active' : 'inactive'}
          switchTab={this.switchTab}
          tabs = {tabs}
          columns = {isActiveTab ? activeColumns : inactiveColumns}
          data = {assemblies}
          selectedRows = {selectedAssemblyIds}
          handleSelect = {this.handleSelect}
          hideScanSearch = {true}
          hideExport = {true}
          bstProps = {{
            selectRow: {
              mode: isActiveTab ? 'radio' : false,
              selected: selectedAssemblyIds,
              onSelect: (row, isSelected) => this.handleSelect(isSelected, [row]),
            },
            options : {
              defaultSortName: 'name',
              defaultSortOrder: 'asc',
            },
          }}
          noSelectionMode={!isActiveTab}
        />
      </div>
    );
  }
}

AssemblyListingPage.propTypes = {
  assemblies: PropTypes.array.isRequired,
  selectedAssemblyIds: PropTypes.array.isRequired,
  dataTotalSize: PropTypes.number,
  actions: PropTypes.shape({
    addMessage: PropTypes.func.isRequired,
    clearSelectedData: PropTypes.func.isRequired,
    getDataByPost: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    handleSingleSelectedRow: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    putData: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired,
  }),
  params: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    selectedAssemblyIds: getSelectedAssemblyIds(state),
    assemblies: getAssembliesWithCosts(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, selectedDataActions, {handleSingleSelectedRow, addMessage, push, unsetData});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (AssemblyListingPage);
