import forEach from 'lodash.foreach';
import {createSelector} from 'reselect';
import * as dataNames from '../../constants/dataNames';
import {GR} from '../../constants/uoms';
import {convertFromBase} from '../../util/uomHelpers';
import {getOrderProducts} from '../orderSelectors';
import {getProducts} from '../productsSelectors';
import {isMedicated, isUnit} from '../itemMastersSelectors';


export const getBiotrackPatientCards = state => state[dataNames.biotrackPatientCards];

const getConsumerId = (_, props) => props && props.customer && props.customer.id;

export const getBiotrackCardInfo = createSelector(
  [getBiotrackPatientCards, getConsumerId],
  (biotrackPatientCards, consumerId) => biotrackPatientCards.find(card => card.consumer_id === consumerId) || {}
);

export const getPayloadForLimit = createSelector(
  [getOrderProducts],
  (orderProducts) => {
    const items = [];

    forEach(orderProducts, (orderProduct) => {
      forEach(orderProduct.items, ({ item_id, qty }) => {
        items.push({
          qty,
          item_id,
        });
      });
    });

    return { items };
  }
);

export const getOrderMedicatedWeight = createSelector(
  [getOrderProducts, getProducts],
  (orderProducts, itemMasters) => orderProducts.reduce(
    (weight, orderProduct) => {
      const itemMaster = itemMasters.find(im => im.id === orderProduct.item_master_id);
      if (isMedicated(itemMaster) && itemMaster.medicated_weight_base > 0) {
        const medicatedWeight = convertFromBase(itemMaster.medicated_weight_base, GR);

        if (isUnit(itemMaster)) {
          //Unit products
          weight += medicatedWeight * orderProduct.quantity;
        } else {
          //Bulk or Pre-Pack products
          weight += medicatedWeight * orderProduct.sold_weight;
        }
      }
      return weight;
    },
    0
  )
);
