import {createSelector} from 'reselect';
import orderBy from 'lodash.orderby';
import map from 'lodash.map';
import get from 'lodash.get';
import find from 'lodash.find';
import uniqBy from 'lodash.uniqby';
import omit from 'lodash.omit';
import forEach from 'lodash.foreach';
import filter from 'lodash.filter';
import intersection from 'lodash.intersection';
import {I18n} from 'react-redux-i18n';
import moment from 'moment';
import * as itemNames from '../constants/itemNames';
import * as dataNames from '../constants/dataNames';
import * as statuses from '../constants/statuses';
import {
  convertFormInputDateToDbDate,
  formatDBDate,
  convertDbDateTimeToClientTzMoment
} from '../util/dateHelpers';
import {getFoundItemOptions} from './checkoutScanSelectors';
import {getActiveFacility, isLeafPaConfigPackClosedLoopFacility} from './facilitiesSelectors';
import {getIngredientsWithCosting} from './ingredientsSelectors';
import {getItemMasters, isMedicated, isIngredient, isUnitPrePack} from './itemMastersSelectors';
import {getItemsAvailability, getItemsAvailabilityExcludingEntity} from './itemsAvailabilitySelectors';
import {
  isReserved,
  isAvailable,
  isOnHold,
  getItemProducerName,
  getInventoryItemsWithReservation
} from './inventoryItemsSelectors';
import {getBiotrackInvTypesWithTitles} from './integration/biotrackSelectors';
import {getPartners} from './partnersSelectors';
import {getPhases} from './phasesSelectors';
import {getSelectedProducts} from './productsSelectors';
import {getOrganizationStrains} from './organizationStrainsSelectors';
import {getProcessingOutputRules} from './integration/biotrackCategoriesSelectors';
import {BT_CANNABIS_MIX, btReassignableInventoryTypes} from '../constants/integration/biotrack/biotrackInventoryTypes';
import {biotrackItemHasValidLabResult} from './testResultsSelectors';
import {getIntegrationState} from './integration/integrationSelectors';
import * as UOMS from '../constants/uoms';
import {isAllowNegativeInventory} from './complianceSettingsSelectors';
import {convertCostByUOM} from '../util/uomHelpers';
import {getProductionRunPackageCodes} from './productionRunsSelectors';
import {hasPackagesTags} from './integrationSelectors';
import {displayQty, roundQty} from './uomsSelectors';
import {isFeatureEnabled} from './featureToggles';
import {getSubcategories} from './subcategoriesSelectors';

const {EA, UOM_VALUE_PRECISION} = UOMS;

export const getInventoryItems = state => state[dataNames.inventoryItems];
export const getProcessingJob = state => state[itemNames.processingJob];
export const getProcessingType = state => state[itemNames.processingType];
export const getProcessingTypes = state => state[dataNames.processingTypes];
const getProcessingInputs = (_, inputs) => inputs;
export const getCurrentTimezone = state => state.timezone;
const getLabResults = state => state[dataNames.testResults];

export const getItemOptions = createSelector(
  [getInventoryItemsWithReservation, getItemMasters, getPhases, getActiveFacility, getPartners, getOrganizationStrains, getLabResults, getItemsAvailabilityExcludingEntity, displayQty, roundQty],

  (inventoryItems, itemMasters, phases, activeFacility, partners, strains, labResults, availability, displayQty, roundQty) => {
    return orderBy(inventoryItems.filter(item => {
      const itemMaster = itemMasters.find(master => master.id === item.item_master_id);
      return !isIngredient(itemMaster);
    }).map((item) => {
      const itemMaster = find(itemMasters, {id: item.item_master_id}) || {};
      const itemAvailability = find(availability, {item_id: item.id});
      const phase = find(phases, {id: item.manufacturing_phase_id});
      const strain = find(strains, {id: itemMaster.strain_id});

      return {
        ...omit(item, 'id'),
        itemMaster,
        item_id: item.id,
        uom: item.uom,
        qty: roundQty(get(itemAvailability, 'qty_available', 0)),
        maxQty: displayQty(get(itemAvailability, 'qty_available', 0)),
        packageCode: item.package_code,
        lotName: item.lot_number,
        integration_type: item.integration_type ? parseInt(item.integration_type) : null,
        locationName: item.location_name,
        productName: itemMaster && itemMaster.name || '',
        vendorName: getItemProducerName(item, activeFacility, partners),
        strainName: get(strain, 'strain_name', ''),
        phaseName: phase && phase.name || '',
        phaseId: item.manufacturing_phase_id || 1,//Some hardcode: using Pre-Run as default
        subcategoryId: itemMaster && itemMaster.subcategory_id,
        optionName: `${item.package_code} - ${item.location_name} - ${itemMaster && itemMaster.name || ''}`,
        lab_result_status: labResults
          .filter(lab_result => lab_result.package_id === item.package_id)
          .reduce((acc, lab_res) => {
            if (lab_res.status !== 'passed') {
              acc &= false;
            }
            return acc;
          }, true)
      };
    }), 'optionName');
  }
);

const getProcessingTypeOutputSubcategoryIds = createSelector(
  [getProcessingType],
  processingType => (processingType.outputs || []).map(o => o.inventory_subcategory_id)
);

/**
 * 6. Finally, after changes logic for Biotrack do not apply category mapping rules
 * @return {number[]}
 */
export const getProcessingJobOutputSubcategoryIds = createSelector(
  [getProcessingTypeOutputSubcategoryIds],
  (subcategoryIds) => subcategoryIds
);

/**
 * Returning output inventory types based on Biotrack inventory rules.
 * @type {Reselect.Selector<any, any>}
 */
export const getProcessingInvTypes = createSelector(
  [getItemOptions, getBiotrackInvTypesWithTitles, getProcessingOutputRules],
  (lotItems, inventoryTypes, BT_PROCESSING_RULES) => {
    const isCannabisMix = Boolean(find(lotItems, {integration_type: BT_CANNABIS_MIX}));
    const rules = [];
    let outputInvTypes = [];

    forEach(lotItems, ({integration_type}) => {
      rules.push(BT_PROCESSING_RULES[parseInt(integration_type)] || []);
    });

    outputInvTypes = intersection(...rules);

    // If incoming lots have Cannabis Mix, so we exclude the one from output list
    if (isCannabisMix) {
      outputInvTypes = filter(outputInvTypes, (id) => id !== BT_CANNABIS_MIX);
    }

    const inputTypes = uniqBy(lotItems, 'integration_type');

    if (inputTypes.length === 1) {
      const reassignableInventoryType = get(lotItems, '0.integration_type');

      if (btReassignableInventoryTypes.indexOf(reassignableInventoryType) > -1) {
        outputInvTypes.push(get(lotItems, '0.integration_type'));
      }
    }

    return filter(inventoryTypes, ({value}) => outputInvTypes.includes(value)) || [];
  }
);

/**
 *
 * @type {Reselect.Selector<any, any>}
 */
export const areValidPackagesForProcessing = createSelector(
  [getIntegrationState, getProcessingInvTypes],
  (integrationState, biotrackInvTypes) => (fields) => {
    if (integrationState.isBiotrack) {
      return biotrackInvTypes.length > 0;
    }
    if (integrationState.isMetrc || integrationState.isLeaf) {
      return map(fields || [], 'uom').every((uom) => uom === EA) === false;
    }

    return true;
  }
);


export const getStartProcessingInitialValues = createSelector(
  [getSelectedProducts, getItemOptions, isAllowNegativeInventory, getProductionRunPackageCodes, isFeatureEnabled, isLeafPaConfigPackClosedLoopFacility],
  (items, itemOptions, isAllowedNegativeInventory, productionRunPackageCodes, isFeatureEnabled, isLeafPa) => {
    const values = {
      employees: [],
      equipments: [],
      productionRunPackageCodes
    };

    const isRemediationLabelsEnabled = isFeatureEnabled('feature_pa_hb_1024_remediation_labels') && isLeafPa;

    if (items.length) {
      values.inputs = items.reduce(
        (acc, item) => {
          const itemOption = itemOptions.find(io => io.item_id === item.id);
          const allowFailedFlower = isRemediationLabelsEnabled && get(item, 'item_category_code') === 'FLOWER';
          if (isValidProcessingOption(itemOption, undefined, isAllowedNegativeInventory, allowFailedFlower)) {
            acc.push({
              item_id: item.id,
              ...itemOption,
              qty: itemOption.qty > 0 ? itemOption.qty : 0,
            });
          }
          return acc;
        },
        []
      );
    } else {
      values.inputs = [];
    }
    values.start_time = moment();
    return values;
  }
);

function mapProcessingInput(input, roundFunc) {
  const {id, item_id, uom, lot_id, package_id, lot_number} = input;
  return {
    id,
    item_id,
    uom,
    qty: roundFunc(input.qty),
    item_master_id: input.itemMaster && input.itemMaster.id || input.item_master_id || null,
    package_code: input.packageCode,
    lot_id,
    package_id,
    lot_name: lot_number
  };
}

function getProcessingIngredients(formData, roundFunc) {
  const ingredients = [];

  get(formData, 'ingredients', []).forEach((ingredient) => {
    if (ingredient.qty !== ingredient.qtyPlanned) {
      ingredient.item_id = ingredient.item_id ? ingredient.item_id : 0;
      ingredient.qty = ingredient.qty ? ingredient.qty : 0;
    }

    if (ingredient.item_id !== '') {
      ingredients.push(mapProcessingInput(ingredient, roundFunc));
    }
  });

  return ingredients;
}

export const getStartProcessingPayload = (formData, timezone, roundFunc) => {
  return {
    start_time: convertFormInputDateToDbDate(formData.start_time, timezone),
    expected_completion_time: convertFormInputDateToDbDate(formData.expected_completion_time, timezone),
    equipment_ids: formData.equipments.map(equipment => equipment.id || equipment),
    employee_ids: formData.employees.map(employee => employee.id || employee),
    processing_type_id: formData.processing_type_id,
    inputs: formData.inputs.map(input => mapProcessingInput(input, roundFunc)),
    name: formData.name || undefined,
  };
};

export const getCompleteProcessingInitialValues = createSelector(
  [
    getProcessingJob,
    getProcessingType,
    getItemOptions,
    getIngredientsWithCosting,
    getCurrentTimezone,
    getItemsAvailability,
    getIntegrationState,
    getInventoryItems,
    hasPackagesTags,
    displayQty,
    roundQty
  ],
  (
    processingJob,
    processingType,
    itemOptions,
    ingredients,
    timezone,
    itemsAvailability,
    integrationState,
    inventoryItems,
    packagesTags,
    displayQty,
    roundQty
  ) => {
    if (!processingJob.id || !processingType.id) {
      return {
        outputs: [{
          packaged_at: moment(),
          package_created_at: moment(),
          package_expires_at: null,
          qty: 0,
        }],
        inputs: [],
        ingredients: [],
        equipments: [],
        employees: [],
        waste_uom: 'GR',
      };
    }

    const initialValues = {
      ...processingJob,
      employees: processingJob.employees.map(employee => employee.user_id),
      employee: processingJob.employees.reduce((acc, employee) => {
        if (acc > 0) return acc;
        return employee.user_id;
      }, 0),
      equipments: processingJob.equipment.map(equipment => equipment.equipment.id),
      expected_completion_time: convertDbDateTimeToClientTzMoment(processingJob.expected_completion_time, timezone),
      actual_completion_time: convertDbDateTimeToClientTzMoment(processingJob.expected_completion_time, timezone),
      processingTypeName: processingType.name,
      batchName: processingJob.name,
      waste_uom: I18n.t('uoms.GR.abbrev'),
      waste: '',
      ingredientsStandardCost: processingType.materials.reduce(
        (acc, material) => {
          const ingredient = ingredients.find(i => i.id === material.item_master_id);
          const cost = ingredient && ingredient.averageCost ? convertCostByUOM(material.qty, ingredient.averageCost, ingredient.averageCostUOM, material.uom) : 0;
          return acc + cost;
        },
        0
      ).toFixed(UOM_VALUE_PRECISION),
    };

    if (processingJob.status === statuses.completed) {

      initialValues.inputs = processingJob.inputs.reduce(
        (acc, input) => {
          const qty = roundQty(input.qty);
          const itemOption = itemOptions.find(io => io.item_id === input.item_id);
          const isIngredient = processingType.materials.some(i => i.item_master_id === input.item_master_id);
          const itemAvailability = itemsAvailability.find(availability => availability.item_id === input.item_id);
          const qtyOnHand = (itemAvailability && itemAvailability.qty_available + +qty) || itemOptions.maxQty;
          if (!isIngredient && itemOption) {
            acc.push({
              ...itemOption,
              item_id: input.item_id,
              qty,
              maxQty: qtyOnHand,
              id: input.id,
            });
          }
          return acc;
        },
        []
      );

      initialValues.outputs = (processingJob.outputs || []).map((output, index) => ({
        ...output,
        //batchName: processingJob.name,
        qty: roundQty(output.qty),
        medically_compliant: initialValues.inputs[index] && initialValues.inputs[index]['medically_compliant_status'] === 'yes'
      }));

      initialValues.ingredients = processingJob.inputs.reduce(
        (acc, input) => {
          const ingredient = ingredients.find(i => i.id === input.item_master_id);
          const material = processingType.materials.find(m => m.item_master_id === input.item_master_id);
          if (ingredient && material) {
            acc.push({
              ...input,
              name: ingredient.name,
              qty: roundQty(input.qty),
              unitCost: ingredient && ingredient.averageCost || 0,
            });
          }
          return acc;
        },
        []
      );
    } else {

      initialValues.inputs = processingJob.inputs.map((input) => {
        const itemOption = find(itemOptions, {item_id: input.item_id}) || {};
        return {
          ...itemOption,
          maxQty: displayQty(itemOption.maxQty),
          qty: roundQty(input.qty),
          id: input.id,
        };
      });

      const isNoMedicallyCompliant =  initialValues.inputs.find(input => {
        return input.medically_compliant_status !== 'yes';
      });

      initialValues.outputs = processingJob.outputs.length === 0
        ? [{
          qty: '0.00',
          packaged_at: convertDbDateTimeToClientTzMoment(processingJob.expected_completion_time, timezone),
          package_created_at: moment(processingJob.expected_completion_time), // NO TZ Needed
          package_expires_at: null,                                           // NO TZ Needed
          medically_compliant: isNoMedicallyCompliant ? 0 : 1,
          tag_requested: packagesTags ? 1 : 0,
        }]
        : processingJob.outputs.map((output, index) => ({
          ...output,
          //batchName: processingJob.name,
          qty: roundQty(output.qty),
          medically_compliant: initialValues.inputs[index] && initialValues.inputs[index]['medically_compliant_status'] === 'yes',
          tag_requested: packagesTags ? 1 : 0,
          packaged_at: convertDbDateTimeToClientTzMoment(processingJob.expected_completion_time, timezone),
          package_created_at: moment(processingJob.start_time),                 // NO TZ Needed
          package_expires_at: moment(processingJob.expected_complection_time),  // NO TZ Needed
        }));

      initialValues.ingredients = processingType.materials.map((material) => {
        const ingredient = ingredients.find(i => i.id === material.item_master_id) || {};
        const input = processingJob.inputs.find(input => input.item_master_id === ingredient.id);
        return {
          id: get(input, 'id', null),
          item_master_id: material.item_master_id,
          item_id: get(input, 'item_id', ''),
          name: get(ingredient, 'name', ''),
          qtyPlanned: displayQty(material.qty),
          qty: input ? roundQty(input.qty) : roundQty(material.qty),
          unitCost: get(ingredient, 'averageCost', 0),
          unitUOM: get(ingredient, 'averageCostUOM'),
          uom: material.uom,
        };
      });


      initialValues.notes = get(processingJob, 'notes', []).sort((a, b) => {
        return a.id < b.id ? -1 : 1;
      });
      initialValues.waste = roundQty(processingJob.waste) || '';
      initialValues.waste_uom = processingJob.waste_uom || UOMS.GR;
    }

    return initialValues;
  });

export const getCompleteProcessingPayload = (formData, timezone, isMetrcMd, isMetrc, roundFunc) => {
  return {
    actual_completion_time: convertFormInputDateToDbDate(formData.actual_completion_time, timezone),
    inputs: formData.inputs
      .map(input => mapProcessingInput(input, roundFunc))
      .concat(getProcessingIngredients(formData, roundFunc)),
    outputs: formData.outputs.map(output => ({
      ...output,
      id: output.id || null,
      item_id: output.item_id,
      qty: roundFunc(output.qty),
      lot_name: output.lot_name || null,
      state_integration_tracking_id: output.state_integration_tracking_id || null,
      new_phase_id: output.phase_id,
      packaged_at: convertFormInputDateToDbDate(output.packaged_at, timezone),
      package_created_at: formatDBDate(output.package_created_at), // NO TIMEZONE NEEDED
      package_expires_at: formatDBDate(output.package_expires_at), // NO TIMEZONE NEEDED
      tag_requested: output.tag_requested === 1 ? 1 : 0,
      is_trade_sample: isMetrc ? (output.is_trade_sample ? output.is_trade_sample : false) : undefined
    })),
    equipment_ids: formData.equipments.map(equipment => equipment.id || equipment),
    employee_ids: (isMetrcMd)
      ? [formData.employee]
      : formData.employees.map(employee => employee.id || employee),
    waste: roundFunc(formData.waste) || undefined,
    waste_uom: formData.waste_uom || undefined,
    // Only include new notes. Change this later if we end up supporting editing notes
    notes: Array.isArray(formData.notes) ? formData.notes.filter(note => !note.id).map(note => note.note) : [],
  };
};

export const getWeights = (inputs = [], outptus = [], roundFunc) => {
  //We suppose here that we have everything in grams for now
  const inputWeight = inputs.reduce((acc, input) => acc + parseFloat(input.qty || 0), 0);
  const outputWeight = outptus.reduce((acc, output) => acc + parseFloat(output.qty || 0), 0);
  return {
    inputWeight: roundFunc(inputWeight),
    outputWeight: roundFunc(outputWeight),
    returnedPercent: inputWeight ? (100 * outputWeight / inputWeight).toFixed(UOM_VALUE_PRECISION) : '',
  };
};

export const isDraftJob = (outputs = []) => {
  return map(outputs, 'id').filter((id) => Number.isInteger(id)).length !== 0;
};

export const getIngredientsCost = ingredientRows => ingredientRows.reduce(
  (acc, row) => acc + (get(row, 'unitUOM') && convertCostByUOM(row.qty, row.unitCost, row.unitUOM, row.uom)),
  0
);

export const getProcessingItemOptions = createSelector(
  [getFoundItemOptions, getProcessingInputs, getIntegrationState, isFeatureEnabled, isLeafPaConfigPackClosedLoopFacility],
  (options, inputs, integrationState, isFeatureEnabled, isLeafPa) => {
    const allowFailedFlower = isFeatureEnabled('feature_pa_hb_1024_remediation_labels') && isLeafPa;
    return options.filter(option => isValidProcessingOption(option, integrationState, false, allowFailedFlower) && !isSelectedOption(option, inputs));
  }
);

export const getFilteredProcessingTypes = createSelector(
    [getProcessingTypes, getProcessingInputs, isFeatureEnabled, isLeafPaConfigPackClosedLoopFacility, getSubcategories],
    (processingTypes, inputs, isFeatureEnabled, isLeafPa, subcategories) => {
      // Processing types are only filtered if Remediation Labels FT is enabled
      // If no inputs, or none of the inputs are on hold return all
      if (!(isFeatureEnabled('feature_pa_hb_1024_remediation_labels') && isLeafPa) ||
          (inputs && !inputs.some(input => get(input, 'on_hold') === 1))) {
        return processingTypes;
      }

      // We are only allowed to make CONCENTRATE from FLOWER. Filter out any other processing types
      const flowerSubcategories = subcategories.filter(subcategory => get(subcategory, 'category.category_code') === 'FLOWER');
      const concentrateSubcategories = subcategories.filter(subcategory => get(subcategory, 'category.category_code') === 'CONCENTRATE');
      const flowerSubcategoryIds = flowerSubcategories.map(sc => sc.id);
      const concentrateSubcategoryIds = concentrateSubcategories.map(sc => sc.id);

      return processingTypes.filter((processingType) => {
        const inputIds = get(processingType, 'inputs').map(input => get(input, 'inventory_subcategory_id'));
        const outputIds = get(processingType, 'outputs').map(output => get(output, 'inventory_subcategory_id'));
        return flowerSubcategoryIds.some(id => inputIds.includes(id)) && concentrateSubcategoryIds.some(id => outputIds.includes(id));
      });
    }
);

export function isValidProcessingOption(option, integrationState = {}, isAllowNegativeInventory = false, isAllowFailedFlower = false) {
  return option
    && !isReserved(option)
    && isAvailable(option, isAllowNegativeInventory)
    && (!isOnHold(option) || (isAllowFailedFlower && get(option, 'itemMaster.category.category_code') === 'FLOWER'))
    && !isIngredient(option.itemMaster) && isMedicated(option.itemMaster) && !isUnitPrePack(option.itemMaster)
    && (!integrationState.isBiotrack || biotrackItemHasValidLabResult(option, integrationState.isPrBiotrack));
}

export function isSelectedOption(option, inputs) {
  return inputs && inputs.some(input => input.item_id === option.item_id);
}
