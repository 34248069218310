const rules = [
  {
    event: {  // becomes the testable property of the renderRules
      type: 'canEnablePublicProfile'
    },
    conditions: {
      all: [
        {
          fact: 'canEnablePublicProfile',
          operator: 'equal',
          value: true
        }
      ]
    },
  },
  {
    event: {  // becomes the testable property of the renderRules
      type: 'showConnectsTabs'
    },
    conditions: {
      all: [
        {
          fact: 'showConnectsTabs',
          operator: 'equal',
          value: true
        }
      ]
    },
  }
];

export default rules;
