export const plantStages = {
  propagation: {
    value: 2,
    string: 'propagation'
  },
  vegetation: {
    value: 1,
    string: 'vegetation'
  },
  flowering: {
    value: 3,
    string: 'flowering'
  }
};

export default plantStages;
