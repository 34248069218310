import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';
import {DESTROY_PACKAGES_FORM} from '../../../constants/forms';
import validate from './validate';
import DestroyPackagesForm from './DestroyPackagesForm';


const DestroyPackagesFormWrapper = reduxForm({
  form: DESTROY_PACKAGES_FORM,
  validate,
  enableReinitialize: true,
})(DestroyPackagesForm);

DestroyPackagesFormWrapper.propTypes = {
  biotrackDestructionReasons: PropTypes.array.isRequired,
  goBack: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  onShowConfirmModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default DestroyPackagesFormWrapper;
