import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Table} from 'react-bootstrap';
import {Link} from 'react-router';

const Orders = ({customerRecentHistory, push, close}) => {
  return (
    <Table className='information'>
      <thead>
        <tr>
          <th colSpan={2}>{I18n.t('cart.patient.orders')}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <Table className='information'>
              <thead>
                <tr>
                  <th colSpan={2}>{I18n.t('cart.patient.lastOrder')}</th>
                </tr>
              </thead>
              <tbody>
                {
                customerRecentHistory.last_order && customerRecentHistory.last_order.products
                && customerRecentHistory.last_order.products.map((product, index) =>
                <tr key={product.id}>
                  <td>
                    <a onClick={() => {close(); push(`/products/${product.item_master_id}`);}}>
                      {product.name}
                    </a>
                  </td>
                </tr>
                )}
              </tbody>
            </Table>
          </td>
          <td>
            <Table className='information'>
              <thead>
                <tr>
                  <th colSpan={2}>{I18n.t('cart.patient.viewOrderHistory')}</th>
                </tr>
              </thead>
              <tbody>
                {
                customerRecentHistory.recent_orders && customerRecentHistory.recent_orders.map((order, index) =>
                <tr key={order.id}>
                  <td>
                    {I18n.t('cart.patient.order')}:
                    <Link onClick={() => {close();}}
                      to={`/order-history/customers/${customerRecentHistory.id}/${order.id}`}> {order.name}</Link>
                  </td>
                </tr>
                )}
              </tbody>
            </Table>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

Orders.propTypes = {
  customerRecentHistory: PropTypes.object,
  push: PropTypes.func,
  close: PropTypes.func
};

Orders.defaultProps = {
  customerRecentHistory: { last_order:null, recent_orders:[] },
  push: () => {},
  close: () => {}
};

export default Orders;
