const packPayLoad = (formValues, initialValues) => {
  return {
    uom: formValues.uom,
    name: formValues.name,
    // Only include category_id if it changed
    category_id: (!initialValues || !initialValues.category_id || initialValues.category_id !== formValues.category_id) ? formValues.category_id : undefined,
    pricing_class_id: formValues.pricing_class_id,
    pricing_weights: formValues.pricing_weights
      .filter(w => w.default_price > 0)
      .map(w => ({pricing_weight_id: w.pricing_weight_id, default_price: Number(w.default_price)}))
  };
};

export default packPayLoad;
