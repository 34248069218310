import React from 'react';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import {getCompleteEachPackagesPayload} from '../../../selectors/forms/completeEachPackagesFormSelectors';
import FormWrapper from '../../common/form/FormWrapper';
import InProgressOverlay from '../../common/InProgressOverlay';
import PrinterModal from '../../printer/PrinterModal'; //eslint-disable-line
import EachPackagesReduxForm from './EachPackagesReduxForm';

class EachPackagesPageBase extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {loading: true, submitting: false, complete: false, printData: null, showPrinterModal: false};
    this.onSubmit = this.onSubmit.bind(this);
    this.onPrintLabels = this.onPrintLabels.bind(this);
    this.onHidePrinter = this.onHidePrinter.bind(this);
  }

  componentWillMount() {
    const {actions: {getItem, getUnpaginatedData, getDataByPost, fetchMetrcTrackingIdsForSelectInput}, params: {id}, integrationState: {isMetrc}, isEachPackaging} = this.props;

    const promises = [
      getUnpaginatedData('/api/location_hierarchy', dataNames.locations, {failed: 'locations.getLocations.failed'}),
      getUnpaginatedData('/api/users/current_facility', dataNames.currentFacilityUsers, {failed: 'packaging.getUsers.failed'}),
      getUnpaginatedData(`/api/partners`, dataNames.partners, {failed: 'partners.get.failed'}),
      getItem(`/api/packaging_jobs/${id}/details`, itemNames.packagingJob, {failed: 'packages.actions.failed.getPackagingJobDetail'})
        .then(job => {
          const {item: {id, item_master: {strain_id}}} = job;

          const params = {
            filter: this.getItemMasterFilter(job),
            sort: 'name asc',
            size: 100000,
            query: 'matchall'
          };

          return Promise.all([
            getItem(`/api/items/${id}`, itemNames.inventoryItem, {failed: 'items.get.failed'}),
            getItem(`/api/strains/${strain_id}`, itemNames.strain, {failed: 'packaging.getStrain.failed'}),
            getDataByPost(`/api/search/item_masters`, params, dataNames.itemMasters),
          ]);
        })
    ];

    if (isMetrc && isEachPackaging) {
      promises.push(fetchMetrcTrackingIdsForSelectInput({
        type: 'package'
      }));
    }

    Promise
      .all(promises)
      .then(() => this.setState({loading: false}))
      .catch(() => this.setState({loading: false}));
  }

  render() {
    const {actions: {goBack}, employees, locations, itemMasters, initialValues, packagedWeight, integrationState, trackingIds, isEachPackaging, isMetrcStemHoldingsToggled, isMetrcCaDonationsToggled} = this.props;
    const {submitting, loading, complete, showPrinterModal} = this.state;
    const saveComplete = complete || initialValues.status === 'complete';
    const message = loading ? 'common.form.loading' : 'common.form.saving';
    return (
      <FormWrapper goBack={goBack} title='packages.complete.title'>
        <InProgressOverlay isActive={submitting || loading} message={message} translate={true}/>
        <EachPackagesReduxForm
          initialValues={initialValues}
          employees={employees}
          itemMasters={itemMasters}
          locations={locations}
          packagedWeight={packagedWeight}
          onSubmit={this.onSubmit}
          onPrintLabels={this.onPrintLabels}
          loading={loading}
          saveComplete={saveComplete}
          integrationState={integrationState}
          isEachPackaging={isEachPackaging}
          trackingIds={trackingIds}
          isMetrcStemHoldingsToggled={isMetrcStemHoldingsToggled}
          isMetrcCaDonationsToggled={isMetrcCaDonationsToggled}
        />
        <PrinterModal
          ref='printerModal'
          showPrinter={showPrinterModal}
          hidePrinter={this.onHidePrinter}
          forceLabelBlocks={true}
          httpAction='post'
          labelUrl='/api/labels/generate/inv_package_tag_all/for/many'
          payload={this.state.labelPayload}
        />
      </FormWrapper>
    );
  }

  onSubmit(formValues) {
    if (formValues.status === 'complete' || this.state.complete) {
      if (formValues.print) {
        this.onPrintLabels();
      }
      return false;
    }
    this.setState({submitting: true});
    const payload = getCompleteEachPackagesPayload(this.transformFormValues(formValues), this.props.timezone);
    return this.props.actions
      .postItem(
        `/api/packaging_jobs/${formValues.id}/complete`,
        payload,
        null,
        {success: 'packaging.complete.success', failed: 'packaging.complete.failed'}
      )
      .then(data => {
        this.setState({printData: data, submitting: false, complete: true}, () => {
          if (formValues.print) {
            this.onPrintLabels();
          } else {
            this.handlePostSubmitActions();
          }
        });
      })
      .catch(() => this.setState({submitting: false}));
  }

  transformFormValues(formValues) {
    return formValues;
  }

  onPrintLabels() {
    const {printData} = this.state;
    const namedBlocks = printData.items.reduce((acc, item) => {
      const items = [];
      for (let n = 0; n < parseInt(item.qty); n++) {
        items.push(item.id);
      }
      const named = {
        name: item.prepack_name,
        ids: items
      };
      acc.push(named);
      return acc;

    }, []);
    const payload = {namedBlocks: namedBlocks};
    this.setState({showPrinterModal: true, labelPayload: payload});
  }

  handlePostSubmitActions() {
    this.props.actions.goBack();
  }

  onHidePrinter() {
    this.setState({showPrinterModal: false});
  }

  getItemMasterFilter(job) {
    return null;
  }
}

export default EachPackagesPageBase;
