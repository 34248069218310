import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import {FaMinus, FaPlus} from 'react-icons/fa';
import { I18n } from 'react-redux-i18n';
import { Field } from 'redux-form';
import NumericInput from '../../common/form/NumericInput';
import ReactSelectInput from '../../common/form/ReactSelectInput';

export const NewPlants = (props) => {
  const { sections } = props;
  return (
    <tbody>
      {props.fields.map((fieldPrefix, index) => {
        return (
          <tr key={index} className='form-group-no-margin'>
            <td
              style={Object.assign(
                {},
                { textAlign: 'center', verticalAlign: 'middle', fontWeight: 'bold' },
                props.fields.length > 1 ? { cursor: 'pointer' } : {}
              )}
              onClick={(e) => {
                e.target.blur();
                if (props.fields.length > 1) props.fields.remove(index);
              }}
            >
              <FaMinus className={props.fields.length > 1 ? 'text-danger' : 'text-muted'} />
            </td>
            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{index + 1}</td>
            <td>
              <Field
                name={`${fieldPrefix}.qty`}
                component={NumericInput}
                props={{
                  max: 9999999,
                  min: 0,
                  fractionPartSize: 0
                }}
              />
            </td>
            <td>
              <Field
                name={`${fieldPrefix}.number_of_groups`}
                component={NumericInput}
                props={{
                  max: 9999999,
                  min: 1,
                  fractionPartSize: 0
                }}
              />
            </td>
            <td>
              <Field
                name={`${fieldPrefix}.section_id`}
                component={ReactSelectInput}
                props={{
                  options: sections,
                  placeholder: I18n.t('common.form.selectPlaceholder'),
                  valueKey: 'id',
                  textKey: 'name'
                }}
              />
            </td>
            <td style={{ textAlign: 'center', verticalAlign: 'middle', cursor: 'pointer' }}>
              <Button
                variant='primary'
                type='button'
                onClick={(e) => {
                  e.target.blur();
                  props.fields.push({ qty: 1, section_id: null, number_of_groups: 1 });
                }}
                disabled={props.fields.length >= 10}
              >
                <FaPlus/>
              </Button>
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};

NewPlants.propTypes = {
  fields: PropTypes.object.isRequired,
  sections: PropTypes.array.isRequired
};

export default NewPlants;
