import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Row, Col, Button} from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import {push,goBack} from 'react-router-redux';
import * as apiActions from '../../../../actions/apiActions';
import * as itemNames from '../../../../constants/itemNames';
import {getPrintServers} from '../../../../selectors/labelsSelectors';

export class PrintServerList extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  componentWillMount(){
    this.props.actions.getItem('/api/labels/compliance_settings', itemNames.labelsCompliance);
  }

  render () {

    return (<div>
      <div>
        <h4>Print Server List</h4>
      </div>
      {
        !this.props.showHelp
          ? null
          : (<div><Row>
          <Col md={3}>
            <h5>What Is A Print Server</h5>
            <p>
              A print server simply a computer <strong>running QZ Tray</strong> and MJ Platform in a browser window
              that is connected to one or more printers.  The role of a print server
              is to allow other devices logged into MJ Platform in your facility to send print jobs to the Print Server
              without having any physical wired connection to the print server.
            </p>
          </Col>
          <Col md={3}>
            <h5>Setting Up A Print Server</h5>
            <p>
              In order to Add A Print Server you must have QZ Tray installed and running.  Click add a printer and follow the
              instruction there.  <strong>Note that you must be physically working on the Print Server you are setting up.  You cannot set
              them up remotely.
            </strong>
            </p>
          </Col>
          <Col md={3}>
            <h5>Managing A Print Server</h5>
            <p>
              The primary task you'll perform once your Print Server is sewt up is logging into and out of MJ Platform and setting
              the print server active.  This is best done daily to ensure QZ Tray runs smoothly.
            </p>
            <p>
              When you make changes to the attached printers you will want to refresh the printer list and save it.
            </p>
          </Col>
          <Col md={3}>
            <h5>Recovering From Mistakes With Print Servers</h5>
            <p>
              To which print server is which to protect you from accidentally activating the wrong print server, we set a value in your machines browser similar to a cookie.
              At some point it is likely you will delete this or it will become corrupted.  If you delete it you will not be able to edit any of the print
              servers.  To fix this, on the edit page click the Is This My Print Server button.  This will compare the available list of printers
              to the saved list and show you the differences.  If it is highly similar, it's probably the the correct Print Server.
            </p>

          </Col>
        </Row><hr /></div>)
      }
      <div style={{marginBottom: '12px'}}>
        <Row>
          <Col md={3} offset={9}>
            <Button
              variant='primary'
              className=' btn-sm btn-block'
              disabled={!this.props.selectedPrinter.qzConnected}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                e.target.blur();
                this.props.actions.push('/print-servers/create');
              }}
            >
              Add A Print Server
            </Button>
            {
              this.props.selectedPrinter.qzConnected
                ? null
                : <div className='text-muted' style={{fontSize: 'smaller'}}>
                QZ Tray must be connected to add a PrintServer.  Printers for a Print
                Server always come from the list of those available to QZ Tray.
              </div>
            }
            <div>

            </div>
          </Col>
        </Row>
        <div style={{clear: 'both'}} />
      </div>
      <div style={{clear: 'both'}} />
      <table className='table table-striped table-bordered table-sm'>
        <thead>
          <tr>
            <th style={{width: '50px'}}>Local</th>
            <th style={{width: '50px'}}>Active</th>
            <th>Server Name</th>
            <th style={{width: '100px'}}>Printers</th>
            {/*<th>In Flight</th>*/}
            {/*<th>Clients</th>*/}
            {/*<th>Last Served</th>*/}
          </tr>
        </thead>
        <tbody>
        {
          Object.keys(this.props.printServers).length
            ? null
            : <tr><td colSpan={6}>No print servers found!</td></tr>
        }
        {
          Object.keys(this.props.printServers).map((hashId, index) => {
            const server = this.props.printServers[hashId];
            return (<tr key={index}>
              <td>
                {parseInt(this.props.localPrintServer.id) === parseInt(server.id) ? 'Yes' : 'No'}
              </td>
              <td>{server.active ? 'Yes' : 'No'}</td>
              <td><a
                href
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  e.target.blur();
                  this.props.actions.push(`/print-servers/${server.id}`);
                }}
              >{server.name}</a></td>
              <td>{server.printers ? server.printers.length : 0}</td>
              {/*<td>0</td>*/}
              {/*<td>0</td>*/}
              {/*<td>Null</td>*/}
            </tr>);
          })
        }
        </tbody>
      </table>
    </div>);
  }
}

PrintServerList.propTypes = {
  printServers: PropTypes.array,
  selectedPrinter: PropTypes.object,
  complianceSettings: PropTypes.object,
  localPrintServer: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    printServers: getPrintServers(state),
    selectedPrinter: state[itemNames.selectedPrinter],
    complianceSettings: state[itemNames.labelsCompliance],
    localPrintServer: state[itemNames.localPrintServer],
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {push,goBack});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (PrintServerList);
