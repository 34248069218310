import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {FieldArray} from 'redux-form';
import {Col, Row} from 'react-bootstrap';
import ProductsFieldArray from './ProductsFieldArray';
import SubmitSection from '../../common/form/SubmitSection';

const ProductManagementForm = (props) => {
  const {
    mjpProducts,
    integratorProducts,
    isLoading,
    initialValues,
    invalid,
    pristine,
    submitting,
    reset,
    onCancel,
    onSubmit,
    formValues,
    handleSubmit,
    change,
    metrcCategoryMappings,
    formErrors,
    touch
  }
    = props;
  const settings = {
    actionSettings: {
      reset: {
        text: I18n.t('common.form.reset'),
        action: reset,
        style: 'default',
        pristine,
      },
      cancel: {
        text: I18n.t('common.form.cancel'),
        action: onCancel
      },
      submit: {
        text: I18n.t('common.form.save'),
        action: handleSubmit(onSubmit),
        submitting,
        invalid,
        pristine,
        style: 'primary',
      },
    }
  };

  return (
    <div className='product-management-form'>
      <Row className='form-fields-row'>
        <Col md={5}>
          <h4>{I18n.t('METRCProductManagement.form.MJPProducts')}</h4>
        </Col>
        <Col md={5}>
          <h4>{I18n.t('METRCProductManagement.form.METRCProducts')}</h4>
        </Col>
      </Row>
      {isLoading ? I18n.t('METRCProductManagement.LoadingProducts') :
        <FieldArray
          name='productsMapping'
          formValues={formValues.productsMapping}
          component={ProductsFieldArray}
          mjpProducts={mjpProducts}
          integratorProducts={integratorProducts}
          metrcCategoryMappings = {metrcCategoryMappings}
          initialValues={initialValues}
          change = {change}
          formErrors={formErrors}
          touch={touch}
        />
      }
      <SubmitSection settings={settings}/>
    </div>
  );
};

ProductManagementForm.propTypes = {
  mjpProducts: PropTypes.array,
  integratorProducts: PropTypes.array,
  metrcCategoryMappings: PropTypes.array,
  isLoading: PropTypes.bool,
  initialValues: PropTypes.object,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  reset: PropTypes.func,
  onRedirect: PropTypes.func,
  onSubmit: PropTypes.func,
  change: PropTypes.func,
  onCancel: PropTypes.func,
  formValues: PropTypes.object,
  formErrors: PropTypes.object,
  touch: PropTypes.func,
  handleSubmit: PropTypes.func
};

export default ProductManagementForm;
