import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';
import {IMPORT_ORDERS_FORM} from '../../../constants/forms';
import ImportOrdersForm from './ImportOrdersForm';
import validate from './validate';


const ImportOrdersFormWrapper = reduxForm({
  form: IMPORT_ORDERS_FORM,
  validate
})(ImportOrdersForm);

ImportOrdersFormWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  registers: PropTypes.array.isRequired,
};

export default ImportOrdersFormWrapper;
