import {contexts} from '../constants/solr';

/**
 * Returns a query string defining the context for a given table of solr data
 * for suggest queries.  Without the context the query would return all possible
 * matches unscoped to facility (or waste, or ingredient, or active/inacitve).
 * By default it uses the current url which makes it easy to use but also
 * accepts a context_key argument for the same lookup purpose.
 * Lookups are performed against the solrSuggestContexts contexts.
 * @param context_key
 * @returns {*}
 */
export const getContextQueryString = (context_key = false) => { //eslint-disable-line
  // sort by length so that subsets of a longer path don't trigger
  // eg. given keys 'inventory/inactive', 'inventory/inactive-waste'
  // and a url http://localhost/inventory/inactive-waste
  // if not sorted descending inventory/inactive comes first it would be the match for the above url
  const keys = Object.keys(contexts);
  const sortedKeys = keys.sort((a, b) => {
    return b.length - a.length;
  });

  const path = context_key ? context_key : window.location.href;
  const key = sortedKeys.find((key) => {
    return path.indexOf(key) !== -1;
  });
  return contexts[key];
};

/**
 * Converts a string to a solr query string that can be used to search for
 * a term in a solr index.
 * @param str
 * @returns {string}
 */
export const convertTerms = (str) => {
  return str.split(' ')
    .map(word => `*${word.replace(/[\\/\/\!\*\+\-\=\<\>\&\|\(\)\[\]\{\}\^\~\?\:\'\"]/g, '*')}*`)
    .join('')
    .replace(/\*+/g, '*'); 
};