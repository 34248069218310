/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {FieldArray, Field} from 'redux-form';
import {Button, Col, FormGroup, Row} from 'react-bootstrap';
import {FaPlus, FaTrash} from 'react-icons/fa';
import {I18n} from 'react-redux-i18n';
import RoleModulesFieldArray from './RoleModulesFieldArray';
import CollapsiblePanel from '../../common/CollapsiblePanel';
import TextInput from '../../common/form/TextInput';

/**
 * Render redux FieldArray of user roles+modules+permissions
 */
class RolesFieldArray extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.addRole = this.addRole.bind(this);
    this.renderRole = this.renderRole.bind(this);
    this.remove = this.remove.bind(this);
  }

  /**
   * Remove role handler
   */
  remove(index) {
    return event => {
      event.preventDefault();
      event.stopPropagation();
      this.props.fields.remove(index);
    };
  }

  /**
   * FieldArray.map() handler, renders role as CollapsiblePanel
   * @return {React.Element}
   */
  renderRole(roleName, roleIndex) {
    const role = this.props.roles[roleIndex] || {};
    const panelHeading = (
      <span>
        {get(role, 'role_name')}
        {role.is_default_role ? null :
          <Button className='float-right' variant='danger' size='sm' onClick={this.remove(roleIndex)}>
            <FaTrash/>
          </Button>
        }
      </span>
    );
    const panelBody = (
      <div>
        {role.is_default_role ? null :
          <Row>
            <Col sm={6} md={3}>
              <Field name={`${roleName}.role_name`} component={TextInput} props={{
                label: I18n.t('userRoles.roleName'),
                isRequired: true,
              }}/>
            </Col>
          </Row>
        }
        <FieldArray name={`${roleName}.modules`} component={RoleModulesFieldArray} props={{role}} />
      </div>
    );
    return (
      <CollapsiblePanel
        key={roleIndex}
        expanded={false}
        heading={panelHeading}
        body={panelBody}
      />
    );
  }

  /**
   * add new role fieldset
   */
  addRole() {
    this.props.fields.push({});
  }

  /**
   * Render primary component
   * @return {React.Element}
   */
  render() {
    return (
      <FormGroup id='user-roles-panel'>
        {this.props.fields.map(this.renderRole)}

        <CollapsiblePanel
          key='new-role'
          expanded={true}
          heading={(
            <span>{I18n.t('userRoles.createCustomRole')}</span>
          )}
          body={(
            <Row>
              <Col sm={6} md={4} lg={3}>
                <Field name='role_name' component={TextInput} props={{
                  label: I18n.t('userRoles.roleName'),
                  rightAddon: (
                    <Button variant='primary' onClick={this.addRole} disabled={this.props.addRoleDisabled}>
                      <FaPlus/>
                    </Button>
                  )
                }}/>
              </Col>
            </Row>
          )}
        />
      </FormGroup>
    );
  }
}

RolesFieldArray.propTypes = {
  fields: PropTypes.shape({
    get: PropTypes.func.isRequired,
    map: PropTypes.func.isRequired,
  }).isRequired,
  roles: PropTypes.array.isRequired,
  addRoleDisabled: PropTypes.bool.isRequired
};

export default RolesFieldArray;
