import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import {I18n} from 'react-redux-i18n';
import {Modal, Alert} from 'react-bootstrap';
import * as aamva from '../../util/aamva';

import ScanInputForm from './ScanInputForm';

class ScanInputModal extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.focus = this.focus.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleEnter = debounce(this.handleEnter, 500).bind(this);
    this.state = {bodyMessage: null};
    this.scanInputRef = React.createRef();
  }

  componentWillReceiveProps(newProps) {
    if(newProps.showModal !== this.props.showModal){
      this.setState({scannedValue: '', bodyMessage: null});
    }
  }

  focus() {
    this.scanInputRef.current.focus();
  }

  handleKeyPress(event) {
    if (event.key === 'Enter' && event.target.value.length > 3) {
      this.handleEnter(event.target.value);
    }
    this.props.handleKeyPress(event);
  }

  handleEnter(value){
    if(this.props.skipParsing){
      this.props.onScanComplete(value);
    }else{
      try{
        let parsed = {};
        if(value.startsWith('$^')){
          parsed = aamva.easyDL(value);
        }else{
          parsed = aamva.pdf417(value);
        }
        this.props.onScanComplete(parsed, value);
      }catch(e) {
        this.setState({bodyMessage: (<Alert variant={'danger'}>{I18n.t('customers.scan.failed')}</Alert>)});
        this.props.onScanComplete(false, value);
      }
    }

  }

  render(){
    const {showModal, hideModal, scanType, bodyMessage} = this.props;
    return (
      <Modal show={showModal} onHide={hideModal} className='check-in-modal' onEntered = {() => {this.focus();}}>
        <Modal.Header closeButton>
          <h3>{scanType === 'swipe' ? I18n.t('scanInput.modal.swipeNow') : I18n.t('scanInput.modal.scanNow')}</h3>
        </Modal.Header>
        <Modal.Body>
          {bodyMessage || this.state.bodyMessage || null}
          <ScanInputForm handleKeyPress={this.handleKeyPress} scanInputRef={this.scanInputRef}/>
        </Modal.Body>
      </Modal>
    );
  }
}

ScanInputModal.propTypes = {
  showModal: PropTypes.bool,
  hideModal: PropTypes.func.isRequired,
  scanType: PropTypes.string,
  handleKeyPress: PropTypes.func,
  onScanComplete: PropTypes.func,
  skipParsing: PropTypes.bool,
  bodyMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.func,
  ]),
};

ScanInputModal.defaultProps = {
  handleKeyPress: () => {},
  onScanComplete: () => {},
  skipParsing: false,
  scanType: 'scan'
};

export default ScanInputModal;
