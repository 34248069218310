import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FieldArray } from 'redux-form';
import { Row, Col } from 'react-bootstrap';
import { getSelectedPlantPageData } from '../../../selectors/plantsSelectors';
import IndividualPlantInformation from './IndividualPlantInformation';
import { postItem } from '../../../actions/apiActions';

class EditIndividualPlants extends React.PureComponent {
  render() {
    const {
      selectedPlants,
      strains,
      page,
      formName,
      saveOnePlant,
      change,
      switchPage,
      timezone,
      trackingIds,
      currentStage,
      integrationState,
      complianceSettings,
      showInvPlants,
      hasPlantsTags,
      currentStageCode,
      actions,
      isUtahGlobalIdGenerationFeatureEnabled,
      initialValues
    } = this.props;
    return (
      <Row>
        <Col xs={12}>
          <hr />
          <FieldArray
            formName={formName}
            name='selectedPlants'
            component={IndividualPlantInformation}
            saveOnePlant={saveOnePlant}
            props={{
              selectedPlants,
              strains,
              page,
              initialPlants: selectedPlants,
              switchPage,
              change,
              timezone,
              trackingIds,
              currentStage,
              integrationState,
              complianceSettings,
              showInvPlants,
              hasPlantsTags,
              currentStageCode,
              actions,
              isUtahGlobalIdGenerationFeatureEnabled,
              initialValues
            }}
          />
        </Col>
      </Row>
    );
  }
}

EditIndividualPlants.propTypes = {
  change: PropTypes.func.isRequired,
  strains: PropTypes.array.isRequired,
  selectedPlants: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  saveOnePlant: PropTypes.func.isRequired,
  page: PropTypes.object.isRequired,
  formName: PropTypes.string.isRequired,
  switchPage: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
  trackingIds: PropTypes.array.isRequired,
  currentStage: PropTypes.number,
  integrationState: PropTypes.object.isRequired,
  complianceSettings: PropTypes.object.isRequired,
  showInvPlants: PropTypes.bool.isRequired,
  currentStageCode: PropTypes.string,
  hasPlantsTags: PropTypes.bool,
  isUtahGlobalIdGenerationFeatureEnabled: PropTypes.bool,
};

export default connect(
  (state, ownProps) => ({
    page: getSelectedPlantPageData(state, ownProps),
    timezone: state.timezone
  }),
  (dispatch) => ({
    actions: bindActionCreators({ postItem }, dispatch)
  })
)(EditIndividualPlants);
