import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { I18n } from 'react-redux-i18n';

import TextAreaInput from '../../common/form/TextAreaInput';

const AddNoteSection = ({name}) => (
  <div className='add-note-section'>
    <Field name={name} component={TextAreaInput} props={{
      label: I18n.t('ei.notesField.title'),
      placeholder: I18n.t('ei.notesField.notePlaceholder'),
      rows: 1,
    }}/>
  </div>
);

AddNoteSection.propTypes = {
  name: PropTypes.string.isRequired,
};

AddNoteSection.defaultProps = {
  name: 'notes',
};

export default AddNoteSection;
