import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Button, Card} from 'react-bootstrap';
import FormWrapper from '../../../../common/form/FormWrapper';
import AssignedSettings from './components/AssignedSettings';
// import {I18n} from 'react-redux-i18n';

const FacilityGroupList = (props) => {

  const onCreateOrEdit = (e, group) => {
    e.preventDefault();
    e.stopPropagation();
    props.onEdit(group);
    e.target.blur();
  };

  const getFacilityClassName = (member) => {
    if(!member || !member.isCurrentFacility) return '';
    return member.isCurrentFacility ? 'text-success' : '';
  };

  const getFacilityStyle = (member) => {
    if(!member || !member.isCurrentFacility) return {};
    return member.isCurrentFacility ? {fontWeight: 'bold'} : {};
  };

  const facilityGroups = (
    <Row>
      <Col md={12}>
        <Card>
          <Card.Header>Facility Groups</Card.Header>
          <Card.Body>
            <div style={{marginTop: '6px', marginBottom: '12px'}}>
              <p>
                Facility Groups provide a way to manage settings for multiple facilities at the same time. For example, you might put all of your facilities
                within a state into one group and use this for label settings.  Within that same state,
                suppose there are municipal regulations for sales compliance.  In this case, you might group those under the same municipal jurisdiction in their own
                groups and create a catch all group for facilities that do not have municipal regulation and only need to meet state level regulations.
              </p>
              <p>
                You may work with facility groups on specific settings pages.  The intent of this manager is to give you a centralized location where
                you can see from an overview perspective which facilities are in which groups and have which settings.
              </p>
            </div>
            <table className='table table-striped table-bordered table-sm'>
              <thead>
              <tr>
                <th colSpan={3}>
                  <Button
                    variant='primary'
                    size='sm'
                    className='float-right'
                    onClick={(e) => onCreateOrEdit(e, {id: 'create'})}
                  >
                    Add Facility Group
                  </Button>
                </th>
              </tr>
              <tr>
                <th>Group</th>
                <th>Members</th>
                {
                  !props.inModal
                    ? null
                    : <th>Settings</th>
                }
              </tr>
              </thead>
              <tbody>
              {
                props.facilityGroups.map((group, index) => {
                  return (<tr key={index}>
                    <td>
                      <a
                        href
                        onClick={(e) => onCreateOrEdit(e, group)}
                      >
                      {group.name}
                      </a>
                    </td>
                    <td>{group.members.map((member, idx) => {
                      return <div className={getFacilityClassName(member)} style={getFacilityStyle(member)} key={idx}>{member && member.name ? member.name : ''}</div>;
                    })}
                    </td>
                      {props.inModal ? <AssignedSettings {...props} group={group} /> : null}
                  </tr>);
                })
              }
              </tbody>
            </table>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );

  return (!props.inModal)
    ? <FormWrapper title='Manage Facility Groups' goBack={props.onGoBack}>{facilityGroups}</FormWrapper>
    : <div>{facilityGroups}</div>;

};

FacilityGroupList.propTypes = {
  facilityGroups: PropTypes.array,
  inModal: PropTypes.bool,
  onGoBack: PropTypes.func,
  onEdit: PropTypes.func.isRequired,
  onBindFacilityGroup: PropTypes.func,
};

export default FacilityGroupList;

