import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {I18n} from 'react-redux-i18n';
import {Col, Row, Card, Button, Image} from 'react-bootstrap';
import CustomerActionButtons from '../CustomerActionButtons';
import AccordionPanel from '../../../common/AccordionPanel';
import CustomerDetail from '../../common/CustomerDetail';
import {ConnectedLeafPaPhysicianNote} from '../../../common/leaf-pa-sales-limits/LeafPaPhysicianNote';
import LeafPaPhysicianNoteAccordion from '../../../common/leaf-pa-sales-limits/LeafPaPhysicianNoteAccordion';


const PatientDetailsLeafPA = ({patient, budTenders, onRegister, timezone, expanded, validateSelectedPatientData, onDetailsExpand, caregiver,
                                allowSalesAtWill, checkinHandlerOverride}) => {

  const {consumer} = patient;

  if (!consumer || !consumer.id) {
    const customer = {
      state_integration_tracking_id: patient.patient_id,
      birth_date: patient.patient_dob_yyyy_mm_dd
    };

    return (
      <Col xs={12}>
        <Card className='clearfix' body>
          <Row>
            <Col xs={6} sm={4} md={3}>
              <label>{I18n.t('consumers.checkin.name')}</label>
              <div>{patient.patient_name}</div>
            </Col>
            <Col xs={6} sm={4} md={3}>
              <label>{I18n.t('consumers.checkin.patientId')}</label>
              <div>{patient.patient_id}</div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <LeafPaPhysicianNoteAccordion customer={customer} />
            </Col>
            <Col xs={12}>
              <div className='padding-top float-right'>
                <Button onClick={() => onRegister(patient)} variant='primary'>
                  {I18n.t('consumers.checkin.registerPatient')}
                </Button>
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    );
  }

  const groups = Array.isArray(consumer.groups) ? consumer.groups.map(group => group.group.active && group.group || false).filter(Boolean) : [];
  const url = consumer.image_url_medium;
  const showActionButtons = allowSalesAtWill
    ? true
    : patient && (
      !patient.allow_dispensary_recommendation || (
        patient.limitations_restrictions && patient.limitations_restrictions.trim()
      )
    );
  return (
    <Col xs={12}>
      <Card className='clearfix' body>
        <Row>
          <Col xs={6} sm={4} md={3} lg={2}>
            <Image src={url} width={'100%'}/>
          </Col>
          <Col xs={6} sm={8} md={9} lg={10}>
            <Row>
              <Col xs={6} sm={4} md={3} lg={2}>
                <label>{I18n.t('consumers.checkin.name')}</label><br/>
                {consumer.first_name} {consumer.last_name}
              </Col>
              <Col xs={6} sm={4} md={3} lg={2}>
                <label>{I18n.t('consumers.checkin.goesBy')}</label><br/>
                {consumer.goes_by}
              </Col>
              <Col xs={6} sm={4} md={2} lg={1}>
                <label>{I18n.t('consumers.checkin.code')}</label><br/>
                {consumer.consumer_code}
              </Col>
              {consumer.birth_date ? <Col xs={6} sm={4} md={2} lg={2}>
                <label>{I18n.t('consumers.checkin.dateOfBirth')}</label><br/>
                {moment(consumer.birth_date).format('MM-DD-YYYY')}
              </Col> : null}
              <Col xs={6} sm={4} md={3} lg={2}>
                <label>{I18n.t('consumers.checkin.phoneNumber')}</label><br/>
                {consumer.default_phone_number}
              </Col>
              <Col xs={6} sm={4} md={3} lg={2}>
                <label>{I18n.t('consumers.checkin.email')}</label><br/>
                {consumer.email_address}
              </Col>
              {consumer.drivers_license_number ? <Col xs={6} sm={4} md={2} lg={2}>
                <label>{I18n.t('consumers.checkin.driversLicense')}</label><br/>
                {consumer.drivers_license_number}
              </Col> : null}
              {consumer.temp_license_number ? <Col xs={6} sm={4} md={2} lg={2}>
                <label>{I18n.t('consumers.checkin.temporaryId')}</label><br/>
                {consumer.temp_license_number}
              </Col> : null}
              {consumer.med_license_number ? <Col xs={6} sm={4} md={2} lg={2}>
                <label>{I18n.t('consumers.checkin.medicalId')}</label><br/>
                {consumer.med_license_number}
              </Col> : null}
              {groups && groups.length ? <Col xs={6} sm={4} md={2} lg={2}>
                <label>{I18n.t('consumers.checkin.groups')}</label><br/>
                {groups.map(group => <div key={group.id}>{group.group_name}<br/></div>)}
              </Col> : null}
            </Row>
            {showActionButtons ? <Row>
              <Col xs={12} md={6}>
                <label>{I18n.t('consumers.checkin.actions')}</label><br/>
                <CustomerActionButtons
                  caregiver={caregiver}
                  consumer={consumer}
                  budTenders={budTenders}
                  checkinHandlerOverride={checkinHandlerOverride}
                  validateSelectedPatientData={validateSelectedPatientData}
                />
              </Col>
            </Row> : null}

            <hr />

            {caregiver ?
              <LeafPaPhysicianNoteAccordion customer={patient.consumer} /> :
              <ConnectedLeafPaPhysicianNote customer={patient.consumer} />
            }

          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <hr />
            <AccordionPanel title='customers.checkin.details' defaultExpanded={false} onChangeEvent={(expanded) => onDetailsExpand(consumer.id, expanded)}>
              <div className={'customer-page'} >
                <CustomerDetail
                  timezone={timezone}
                  customerId={consumer.id}
                  loadCustomerData={expanded[consumer.id]}
                />
              </div>
            </AccordionPanel>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

PatientDetailsLeafPA.propTypes = {
  patient: PropTypes.object.isRequired,
  onRegister: PropTypes.func.isRequired,
  budTenders: PropTypes.array,
  timezone: PropTypes.string,
  expanded: PropTypes.object.isRequired,
  onDetailsExpand: PropTypes.func.isRequired,
  validateSelectedPatientData: PropTypes.func,
  caregiver: PropTypes.object,
  allowSalesAtWill: PropTypes.bool.isRequired,
  checkinHandlerOverride: PropTypes.func,
};

export default PatientDetailsLeafPA;
