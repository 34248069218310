import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { Field } from 'redux-form';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import {FaExclamationTriangle, FaMinus} from 'react-icons/fa';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import InternationalDateTimePickerInput from '../../../common/form/InternationalDateTimePickerInput';
import AddItem from '../../../common/form/AddItem';
import InternationalCurrencyInput from '../../../common/form/InternationalCurrencyInput';

const Payments = ({ fields, paymentTypes, errors, registers, users, meta, change, blur, oddMoneyAmount, changeDueRecord, canAddPayments }) => {

  const onRemovePayment = (payment_id) => {
    change(`payments[${payment_id}]amount`, 0);
  };

  return (
    <div>
      {meta && meta.error ? <Row>
        <Col md={11}>
          <Alert variant='danger'>
            <FaExclamationTriangle />
            <span className='padding-left'>{meta.error}</span>
          </Alert>
        </Col>
      </Row> : null}
      {fields.map((line, index) => {
        const data = fields.get(index);
        return (
        <Row key={index}>
          <Col xs={4} md={2}>
            <Field name={`${line}.payment_type`} component={ReactSelectInput} props={{
              label: I18n.t('purchaseOrders.form.paymentType'),
              defaultOption: I18n.t('common.placeHolders.choose'),
              options: paymentTypes,
              textKey: 'name',
              valueKey: 'code',
              isRequired: Boolean(errors && errors[index]),
              onChange: value => {
                change(`${line}.payment_type`, value);
                blur(`${line}.amount`);
                blur(`${line}.register_id`);
                blur(`${line}.user_id`);
                blur(`${line}.payment_date`);
              },
            }}
            />
          </Col>
          <Col xs={4} md={2}>
            <InternationalCurrencyInput
              name={`${line}.amount`}
              props={{
                label: I18n.t('purchaseOrders.form.amount'),
                placeholder:'',
                disabled:data.id !== undefined && (changeDueRecord && changeDueRecord.id !== undefined),
                isRequired:Boolean(errors && errors[index]),
              }} />
          </Col>
          <Col xs={4} md={2}>
            <Field name={`${line}.register_id`} component={ReactSelectInput} props={{
              label: I18n.t('purchaseOrders.form.selectRegister'),
              defaultOption: I18n.t('common.placeHolders.choose'),
              options: registers,
              valueKey: 'id',
              textKey: 'name',
              isRequired: Boolean(errors && errors[index]),
              onChange: value => {
                change(`${line}.register_id`, value);
                blur(`${line}.user_id`);
                blur(`${line}.payment_type`);
                blur(`${line}.amount`);
                blur(`${line}.payment_date`);
              },
            }}
            />
          </Col>
          <Col xs={4} md={2}>
            <Field name={`${line}.user_id`} component={ReactSelectInput} props={{
              label: I18n.t('cultivation.drivers.form.selectUser'),
              options: users,
              valueKey: 'id',
              textKey: 'displayName',
              isRequired: Boolean(errors && errors[index]),
            }}/>
          </Col>
          <Col xs={4} md={3} lg={2}>
            <InternationalDateTimePickerInput name={`${line}.payment_date`} props={{
              label: I18n.t('purchaseOrders.form.paymentDate'),
              isRequired: Boolean(errors && errors[index]),
              closeOnSelect: true,
              onChange: value => {
                change(`${line}.payment_date`, value);
                blur(`${line}.payment_type`);
                blur(`${line}.amount`);
                blur(`${line}.register_id`);
                blur(`${line}.user_id`);
              },
            }}
            />
          </Col>
          <Col xs={1} className='add-item'>
            <Button variant='primary' size='sm' onClick={() => {
              onRemovePayment(index);
              fields.remove(index);
            }}>
              <FaMinus />
            </Button>
          </Col>
        </Row>
      );
      })}
      <AddItem
        allowEmpty={true}
        addAction={() => fields.push({})}
        addDisabled={!canAddPayments}
      />
      {oddMoneyAmount < 0 ?
        <Row>
          <Col xs={12}><hr/></Col>
          <Col xs={12}>
            <h4>{I18n.t('purchaseOrders.form.changeDue')}</h4>
          </Col>
          <Col xs={4} md={2}>
            <Field name='oddMoney.payment_type' component={ReactSelectInput} props={{
              label: I18n.t('purchaseOrders.form.paymentType'),
              defaultOption: I18n.t('common.placeHolders.choose'),
              options: paymentTypes,
              textKey: 'name',
              valueKey: 'code',
              disabled: true,
              isRequired: true,
            }}
            />
          </Col>
          <Col xs={4} md={2}>
            <InternationalCurrencyInput name='oddMoney.amount' props={{
              label: I18n.t('purchaseOrders.form.amount'),
              disabled: true,
              isRequired: true
            }}
            />
          </Col>
          <Col xs={4} md={2}>
            <Field name='oddMoney.register_id' component={ReactSelectInput} props={{
              label: I18n.t('purchaseOrders.form.selectRegister'),
              defaultOption: I18n.t('common.placeHolders.choose'),
              options: registers,
              isRequired: true,
              valueKey: 'id',
              textKey: 'name',
            }}/>
          </Col>
          <Col xs={4} md={2}>
            <Field name='oddMoney.user_id' component={ReactSelectInput} props={{
              label: I18n.t('cultivation.drivers.form.selectUser'),
              options: users,
              valueKey: 'id',
              textKey: 'displayName',
              isRequired: true,
            }}/>
          </Col>
          <Col xs={6} md={3} lg={2}>
            <InternationalDateTimePickerInput name='oddMoney.payment_date' props={{
              label: I18n.t('purchaseOrders.form.paymentDate'),
              isRequired: true,
              closeOnSelect: true,
            }}/>
          </Col>
        </Row>
        : null
      }
    </div>
  );
};

Payments.defaultProps = {
  order_total: 0.00,
};

Payments.propTypes = {
  fields: PropTypes.object.isRequired,
  paymentTypes: PropTypes.array.isRequired,
  registers: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  errors: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  change: PropTypes.func,
  blur: PropTypes.func,
  meta: PropTypes.object,
  oddMoneyAmount: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  changeDueRecord: PropTypes.object,
  order_total: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  canAddPayments: PropTypes.bool.isRequired,
};

export default Payments;
