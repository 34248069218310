import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { Button } from 'react-bootstrap';
import {FaMinus} from 'react-icons/fa';
import {I18n} from 'react-redux-i18n';
import InternationalQuantityByUomInput from '../../common/form/InternationalQuantityByUomInput';
import WillRender from '../../common/concealers/WillRender';
import {INTERNAL_TRANSFER_FORM} from '../../../constants/forms';
import DisplayQty from '../../common/DisplayQty';

const Transfer = (props) => {
  return (
    <tbody>
      {
        props.fields.map((fieldIndex, index) => {
          const product = props.fields.get(index);
          const prepackNotAvailable = get(product, 'prepackNotAvailable', false);
          return (<tr key={index}>
            <td style={{textAlign: 'center'}}>
              {product.name}<br />
              {props.isIngredient ? 'Sku: ' : 'Package ID: '}
              {get(product, props.isIngredient ? 'item_number' : 'package_code')}
              <WillRender ifTrue={prepackNotAvailable}>
                <div className='text-danger'>
                  {I18n.t('internalTransfers.form.errors.prepackNotAvailable')}
                </div>
              </WillRender>
            </td>
            <td>
              <DisplayQty qty={(product.qty - product.qty_reserved)} uom={product.uom} displayUom={true} />
            </td>
            <td>
              <InternationalQuantityByUomInput
                name={`${fieldIndex}.transfer_quantity`}
                uom={product.uom}
              />
            </td>
            <td style={{textAlign: 'center'}}>
              <Button
                variant='primary'
                disabled={props.fields.length === 1}
                onClick={() => {
                  props.actions.arrayRemove(INTERNAL_TRANSFER_FORM, 'transfers', index);
                  props.handleSelect('remove', [product]);
                }}
              >
                <FaMinus/>
              </Button>
            </td>
          </tr>);
        })
      }
    </tbody>
  );
};

Transfer.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  selectedProducts: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isIngredient: PropTypes.bool,
  allowNegativeInventory: PropTypes.bool,
};

export default Transfer;
