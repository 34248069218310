import {change, getFormValues} from 'redux-form';
import {SETUP_SALES_LOCATIONS_FORM} from '../../constants/forms';
import {isChangeAction} from './utils';
import {addMessage} from '../../actions/systemActions';
import {warning} from '../../constants/messageTypes';

const setupSalesLocationsForm = store => next => action => {
  const result = next(action);
  const {meta, payload} = action;
  if(isChangeAction(action, [SETUP_SALES_LOCATIONS_FORM]) && meta.field.indexOf('.is_default_for_retail') > -1 && payload){
    const state = store.getState();
    const formValues = getFormValues(SETUP_SALES_LOCATIONS_FORM)(state);
    const regex = /child_locations\[(\d+)]\.is_default_for_retail/;
    const match = regex.exec(action.meta.field);
    const currentIndex = match && match[1];

    const shouldShowNotify = (formValues.child_locations.filter(location => location.is_default_for_retail).length > 1);

    const child_locations = formValues.child_locations.map((location, index) => ({
      ...location,
      is_default_for_retail: currentIndex == index
    }));
    store.dispatch(change(meta.form, 'child_locations', child_locations));

    if (shouldShowNotify) {
      store.dispatch(addMessage(warning, 'locations.form.defaultSaleLocationChanged'));
    }
  }
  return result;
};

export default setupSalesLocationsForm;