import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import {Col, Row} from 'react-bootstrap';

import MultiselectInput from '../../common/form/MultiselectInput';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import ContentConcealer from '../../common/concealers/ContentConcealer';

class SelectPlantsSection extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    const locations = props.locations;

    this.state = {
      buildings: locations.filter(location => location.level === 0),
      rooms: [],
      zones: [],
      sections: [],
      showRoom: true,
      showZone: true,
      showSection: true,
      selectedLocations: [],
      sectionSelected: false,
    };
    this.filterLocations = this.filterLocations.bind(this);
    this.clearBatchName = this.clearBatchName.bind(this);
    this.clearPlantRange = this.clearPlantRange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    // Potentially receives an object representing a scanned heirarchy and merges those into existing values
    const map = {
      buildings: 'building_ids',
      rooms: 'room_ids',
      zones: 'zone_ids',
      sections: 'section_ids'
    };

    if (nextProps.locations) {
      this.setState({
        buildings: nextProps.locations.filter(location => location.level === 0)
      });
    }

    if(nextProps.scannedLocations){
      // Adds location if not already present.  Handles munged arrays to a certain degree.
      const addLocationToExistingValues = (location, key) => {
        const currentlySelected = (Array.isArray(this.props.currentlySelectedLocationIds[map[key]])) ? this.props.currentlySelectedLocationIds[map[key]] : false;
        if(!Array.isArray(currentlySelected) || (currentlySelected.length > 0 && currentlySelected[0] === undefined)) return [location];
        const locationAlreadyPresent = currentlySelected.find((loc) => loc.id === location.id);
        if(locationAlreadyPresent) return false;
        return currentlySelected.concat([location]);
      };

      const addScannedLocation = (location, key) => {
        const newLocationArray = addLocationToExistingValues(location, key);
        if(!newLocationArray) return false;
        const formField = (key !== 'sections') ? map[key] : 'location_id';
        this.props.change(formField, newLocationArray);
        return newLocationArray;
      };

      Object.keys(nextProps.scannedLocations).forEach((key) => {
        const location = nextProps.scannedLocations[key];
        if(!location) return false;
        const newLocationArray = addScannedLocation(location, key);
        if(key === 'sections' && newLocationArray) {
          nextProps.onPlantFilterChange('location_id', newLocationArray);
          this.clearBatchName();
        }
      });
    }
  }

  filterLocations(value, level) {
    const locations = this.props.locations;
    let parentIds = value.map(location => location.id);
    let childLocations = [];
    /*eslint no-fallthrough: "error"*/
    switch(level) {
    case 0:
      childLocations = locations.filter(location => parentIds.indexOf(location.parent_location_id) !== -1);
      this.setState({rooms: childLocations});
      parentIds = [];
      childLocations.map(location => parentIds.push(location.id));
      this.props.change('room_ids', []);
      // falls through
    case 1:
      childLocations = locations.filter(location => parentIds.indexOf(location.parent_location_id) !== -1);
      parentIds = [];
      childLocations.map(location => parentIds.push(location.id));
      this.setState({zones: childLocations});
      this.props.change('zone_ids', []);
      // falls through
    case 2:
      childLocations = locations.filter(location => parentIds.indexOf(location.parent_location_id) !== -1);
      parentIds = [];
      childLocations.map(location => parentIds.push(location.id));
      this.setState({sections: childLocations});
      this.props.change('location_id', []);
      this.clearBatchName();
      this.setState({selectedLocations: []});
    }
  }

  clearBatchName() {
    this.props.change('selected_batch_name', []);
  }

  clearPlantRange() {
    this.props.change('plant_range_end', null);
    this.props.change('plant_range_start', null);
  }

  render() {
    const {change, showBulkFields, onPlantFilterChange, /*hideBatch,*/ batches, /*hideRange,*/ useMetrcTags,
      propagationMixCatch, plants, currentlySelectedLocationIds, selectedBatchName} = this.props;

    const locationIsEnabled = (parentLocation, location) => {
      if(!Array.isArray(currentlySelectedLocationIds[parentLocation])) return false;
      return (currentlySelectedLocationIds[parentLocation].length);
    };

    const selectedSectionIds = this.props.currentlySelectedLocationIds['section_ids'];
    const sectionIsSelected = (selectedSectionIds && Array.isArray(selectedSectionIds) && selectedSectionIds.length > 0);

    const {buildings, rooms, zones, sections, showRoom, showZone, showSection} = this.state;
    return (
      <div>
        {showBulkFields && <div><h4>{I18n.t('plants.modify.selectPlants')}</h4>
          <Row className='form-fields-row'>
            <Col className='form-fields-col' xs={8} md={3} lg={3}>
              <Field
                name='building_ids' component={MultiselectInput}
                props={{
                  label: I18n.t('plants.form.selectBuilding'),
                  options: buildings,
                  textKey: 'name',
                  valueKey: 'id',
                  onChange: (value) => {
                    change('building_ids', value);
                    this.filterLocations(value, 0);
                    this.setState({showRoom: true});
                  },
                  placeholder: I18n.t('common.form.selectPlaceholder')
                }} />
            </Col>
            <ContentConcealer show={showRoom}>
              <Col className='form-fields-col' xs={8} md={3} lg={3}>
                <Field
                  name='room_ids' component={MultiselectInput}
                  props={{
                    label: I18n.t('plants.form.selectRoom'),
                    options: rooms,
                    textKey: 'name',
                    valueKey: 'id',
                    disabled: (!locationIsEnabled('building_ids')),
                    onChange: (value) => {
                      change('room_ids', value);
                      this.filterLocations(value, 1);
                      this.setState({showZone: true});
                    },
                    placeholder: I18n.t('common.form.selectPlaceholder')
                  }} />
              </Col>
            </ContentConcealer>
            <ContentConcealer show={showZone}>
              <Col className='form-fields-col' xs={8} md={3} lg={3}>
                <Field
                  name='zone_ids' component={MultiselectInput}
                  props={{
                    label: I18n.t('plants.form.selectZone'),
                    options: zones,
                    textKey: 'name',
                    valueKey: 'id',
                    disabled: (!locationIsEnabled('room_ids')),
                    onChange: (value) => {
                      change('zone_ids', value);
                      this.filterLocations(value, 2);
                      this.setState({showSection: true});
                    },
                    placeholder: I18n.t('common.form.selectPlaceholder')
                  }} />
              </Col>
            </ContentConcealer>
            <ContentConcealer show={showSection}>
              <Col className='form-fields-col' xs={8} md={3} lg={3}>
                <Field
                  name='location_id' component={MultiselectInput}
                  props={{
                    label: I18n.t('plants.form.selectSection'),
                    options: sections,
                    textKey: 'name',
                    valueKey: 'id',
                    disabled: (!locationIsEnabled('zone_ids')),
                    onChange: (value) => {
                      change('location_id', value);
                      onPlantFilterChange('location_id', value ? value : []);
                      this.filterLocations(value, 3);
                      this.setState({selectedLocations: value});
                    },
                    placeholder: I18n.t('common.form.selectPlaceholder')
                  }} />
              </Col>
            </ContentConcealer>
          </Row>
          <Row className='form-fields-row plant-batches'>
            <Col className='form-fields-col' xs={8} md={3} lg={3}>
              {sectionIsSelected && batches && batches.length ? <Field
                name='selected_batch_name' component={MultiselectInput}
                props={{
                  label: I18n.t('plants.form.selectPlantingBatch'),
                  options: batches,
                  textKey: 'batch_name',
                  valueKey: 'batch_name',
                  onBlur: (value) => {
                    if(useMetrcTags){change('beginning_tag', null);}
                  },
                  onChange: (value) => {
                    change('selected_batch_name', value);
                    change('stage_id', null);
                    onPlantFilterChange('selected_batch_name', value);
                    if(propagationMixCatch){ change('propogation_catch', I18n.t('plants.modify.invalidSelection'));}
                  },
                  placeholder: I18n.t('common.form.selectPlaceholder')
                }} /> : null}
            </Col>
            {(sectionIsSelected && Array.isArray(selectedBatchName) && selectedBatchName.length > 0 && batches && batches.length && plants && plants.length) ? (
              <Col className='form-fields-col' xs={12} md={3} lg={3}>
                {(selectedBatchName.map(batch => {
                  const derivedFields = {
                    start: `plant_range_start${batch.batch_name}`,
                    end: `plant_range_end${batch.batch_name}`
                  };
                  return (
                    <div key={batch.batch_name}>
                      <div>
                        <label>Batch {batch.batch_name}</label>
                      </div>
                      <Col xs={12} sm={6} style={{padding: 0}}>
                        <Field
                          name={derivedFields.start} component={ReactSelectInput}
                          props={{
                            label: I18n.t('plants.form.rangeStartingPlant'),
                            options: plants.filter(plant => plant.batch_name === batch.batch_name),
                            textKey: 'plant_id',
                            valueKey: 'plant_id',
                            onChange: (value) => {
                              change(derivedFields.start, value);
                              onPlantFilterChange('plant_range_start', value, batch); // partial field name intentional
                              if(propagationMixCatch){
                                change('propogation_catch', I18n.t('plants.modify.invalidSelection'));
                              }
                            },
                            placeholder: I18n.t('common.form.selectPlaceholder')
                          }} />
                      </Col>
                      <Col xs={12} sm={6} style={{padding: 0}}>
                        <Field
                          name={derivedFields.end} component={ReactSelectInput}
                          props={{
                            label: I18n.t('plants.form.rangeEndingPlant'),
                            options: plants.filter(plant => plant.batch_name === batch.batch_name),
                            textKey: 'plant_id',
                            valueKey: 'plant_id',
                            onChange: (value) => {
                              change(derivedFields.end, value);
                              onPlantFilterChange('plant_range_end', value, batch); // partial field name intentional
                              if(propagationMixCatch){
                                change('propogation_catch', I18n.t('plants.modify.invalidSelection'));
                              }
                            },
                            placeholder: I18n.t('common.form.selectPlaceholder')
                          }} />
                      </Col>
                    </div>);
                }))}
                {
                  sectionIsSelected && batches && batches.length
                    ? <div className='text-muted' style={{fontSize: 'smaller'}}>Include plants by setting a range for each selected batch.</div>
                    : null
                }
              </Col>) : null}
          </Row>
        </div>}
      </div>
    );
  }
}

SelectPlantsSection.propTypes = {
  change: PropTypes.func.isRequired,
  showBulkFields: PropTypes.bool.isRequired,
  onPlantFilterChange: PropTypes.func.isRequired,
  locations: PropTypes.array.isRequired,
  hideBatch: PropTypes.bool,
  batches: PropTypes.array.isRequired,
  hideRange: PropTypes.bool.isRequired,
  useMetrcTags: PropTypes.bool.isRequired,
  propagationMixCatch: PropTypes.bool.isRequired,
  plants: PropTypes.array.isRequired,
  currentlySelectedLocationIds: PropTypes.object.isRequired,
  selectedBatchName: PropTypes.array.isRequired,
};

export default SelectPlantsSection;
