import get from 'lodash.get';
import routeDefinitions from '../routes/routeDefinitions';

export const findRoutePermissions = (path) => {
  const route = findRouteDefinition(path);
  return route && route.permissions || [];
};

export const findRouteDefinition = (path) => {
  return Object.keys(routeDefinitions).reduce((acc, routeGroup) => {
    if (acc) {
      return acc;
    } else {
      const routes = Object.keys(routeDefinitions[routeGroup]).map(id => routeDefinitions[routeGroup][id]);
      return routes.find(route => {
        const pathArray = path.split('/').filter(Boolean);
        const routeArray = route.path.split('/').filter(Boolean);
        const optionalPart = route.path.lastIndexOf('(');

        //Handle optional url segments - we can omit them if they are not passed
        const pathLength = optionalPart > -1 && routeArray.length > pathArray.length ?
          route.path.substring(0, optionalPart).split('/').filter(Boolean).length : routeArray.length;
        const match = pathArray.filter((slice, index) => {
          if(routeArray[index]){
            if(slice === routeArray[index].replace(/[()]/g, '')){
              return true;
            }else{
              return routeArray[index].startsWith(':');
            }
          }else{
            return false;
          }
        });
        return pathArray.length === pathLength && match.length === pathArray.length;
      });
    }
  }, null);
};

// POJ param fetch
export const getUrlParam = (param) => {
  const params = {};
  if(!location.search) return undefined;
  const parts = location.search.substring(1).split('&');
  for (let i = 0; i < parts.length; i++) {
    const nv = parts[i].split('=');
    if (!nv[0]) continue;
    params[nv[0]] = nv[1] || true;
  }
  return (params[param] !== undefined) ? params[param] : undefined;
};

export const pathnameContains = (props, string) => {
  return get(props, 'location.pathname', '').indexOf(string) !== -1;
};

export const paramsGet = (props, string, defaultValue) => {
  return get(get(props, 'params'), string, defaultValue);
};
