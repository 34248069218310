/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {goBack, push} from 'react-router-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import moment from 'moment';
import map from 'lodash.map';
import get from 'lodash.get';
import {pick} from 'lodash';
import round from 'lodash.round';
import {change, formValueSelector, submit} from 'redux-form';
import {batchActions} from 'redux-batched-actions';
import * as itemNames from '../../../constants/itemNames';
import * as dataNames from '../../../constants/dataNames';
import {getDataByPost, getItem, getUnpaginatedData, postData, postItem, putItem} from '../../../actions/apiActions';
import {addSelectedData} from '../../../actions/selectedDataActions';
import {addMessage} from '../../../actions/systemActions';
import * as messageTypes from '../../../constants/messageTypes';
import {pushData, setData, unsetData} from '../../../actions/dataActions';
import {unsetItem} from '../../../actions/itemActions';
import FormWrapper from '../../common/form/FormWrapper';
import InProgressOverlay from '../../common/InProgressOverlay';
import TransferForm from './TransferForm'; // eslint-disable-line import/no-named-as-default
import {getFlattenedStorageLocations} from '../../../selectors/storageLocationsSelectors';
import {
  getSalesOrdersDestinations,
  getSalesOrdersLines,
  getSalesOrdersTotals,
  getTransferLinesLimit,
  isMedicatedOrder
} from '../../../selectors/salesOrdersSelectors';
import {
  getChildItemMastersForFacility,
  getFilteredInventoryItems,
  getModifyTransferInitialValues,
  getModifyTransferPrepackItemMasterChildren,
  getTransferStatuses,
  isDriverCompany,
  isForLabPartner,
  isOutForDeliveryTransfer,
  isReceivedTransfer,
  needTestResults,
  getCreateTransferSentNetTotals,
  getCreateTransferSentTotals,
  getModifyTransferFormValues,
  getCreateTransferLineTotals,
  getCreateTransferEditablePrices,
  getDistributionSettings
} from '../../../selectors/transfersSelectors';
import {getUrlParam} from '../../../util/routeHelper';
import {
  convertDbDateToFormInputDate,
  convertFormInputDateToDbDate,
  convertFormInputDateToDbTime
} from '../../../util/dateHelpers';
import {getIntegrationState} from '../../../selectors/integration/integrationSelectors';
import {getValidDrivers, mapDriversArrayToDbFields} from '../../../selectors/forms/salesTransferFormSelectors';
import {roundFloat} from '../../../util/mathHelpers';
import {getFacilityType} from '../../../selectors/facilitiesSelectors';
import LabelPrinter from '../../print-services/labels/LabelPrinter'; //eslint-disable-line
import {getLocationsForSharedProducts} from '../../../selectors/locationsSelectors';
import * as complianceSettings from '../../../selectors/complianceSettingsSelectors';
import {loadInventoryForSalesOrders, loadTransferById} from '../../../actions/transfer';
import {checkPackageForLeafWaAndPa} from '../../../selectors/integration/leafSelectors';
import productSubTypes from '../../../constants/salesOrderMetaData';
import * as labResultsSelector from '../../../selectors/lab-results';
import * as statuses from '../../../constants/statuses';
import {isFeatureEnabled} from '../../../selectors/featureToggles';
import {isValidReturn, isValidTransfer} from '../helpers';
import PackageStore from '../../../selectors/inventory/package';
import ModalWrapper from '../../common/ModalWrapper';

const formName = 'modify-transfer';
class ModifyTransferPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.calcTotals = this.calcTotals.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onOpenSalesOrder = this.onOpenSalesOrder.bind(this);
    this.processCancelTransfer = this.processCancelTransfer.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.redirect = this.redirect.bind(this);
    this.handleScanSearch = this.handleScanSearch.bind(this);
    this.startProcessing = this.startProcessing.bind(this);
    this.endProcessing = this.endProcessing.bind(this);
    this.createDriver = this.createDriver.bind(this);
    this.onPrintLabels = this.onPrintLabels.bind(this);
    this.onHidePrinter = this.onHidePrinter.bind(this);
    this.loadSalesOrders = this.loadSalesOrders.bind(this);
    this.afterTransferLoaded = this.afterTransferLoaded.bind(this);
    this.loadTransferInventory = this.loadTransferInventory.bind(this);
    this.handleRestockAndCompleteTransfer = this.handleRestockAndCompleteTransfer.bind(this);
    this.onDontRestock = this.onDontRestock.bind(this);
    this.onCreateMetrcTemplate = this.onCreateMetrcTemplate.bind(this);
    this.showConfirmationPopup = this.showConfirmationPopup.bind(this);
    this.validateNegativeInventory = this.validateNegativeInventory.bind(this);

    this.state = {
      processing: true,
      processingMessage: 'Loading...',
      isComplete: false,
      prepackExpendedInventory: [],
      expendedInventory: [],
      showNegativeInvConfirmation: {
        show: false,
        onHide: () => null,
      },
      showPrinter: false,
      payload: {},
      changesLoaded: false,
      showCancelTransferModal: false
    };
  }

  loadSalesOrders(ids) {
    return this.props.actions.postData(`/api/sales_orders/multiple`, {ids}, dataNames.salesOrders, {failed: 'transfers.get.salesOrdersFail'}, {detailed: 'true', with_partner_details: 1});
  }

  loadTransferInventory() {
    const {salesOrders, transfer} = this.props;
    this.props.actions.loadInventoryForSalesOrders(salesOrders, {active_only: transfer.status === 'open'});
    return Promise.resolve();
  }

  afterTransferLoaded(transfer) {
    if(transfer.status === 'completed') this.setState({isComplete: true});

    const ids = map(get(transfer, 'sales_orders', []), 'sales_order_id');

    if (!ids.length) {
      return Promise.resolve([]);
    }

    return this
      .loadSalesOrders(ids)
      .then(this.loadTransferInventory)
      .then(this.getExpendedInventory)
      .then(this.endProcessing);
  }

  componentWillReceiveProps(nextProps) {
    const { packageStore, actions, childItemMasters, itemMasters } = this.props;
    const { changesLoaded } = this.state;

    // Retrieve the query parameters
    const query = this.props.location ? this.props.location.query : {};
    const { package_key, package_code } = query;

    // Check if any of the item_masters are prepacks, if so we need the prepack children available as well
    const hasPrepack = itemMasters.filter((item) => { return get(item, 'inventory_attributes.is_prepack') === 1;}).length;
    const productsLoaded = (itemMasters.length && ( !hasPrepack || Object.keys(childItemMasters).length ));

    // Ensure the product data for matching the packages is loaded into the component properties before trying
    // to set the package for the inventory_line when passing in package adjustments via the query string
    // this will allow the change event in the salesTransferFormMiddleware to process the change event for the
    // package_code
    if (productsLoaded) {
      if (package_key && package_code && !changesLoaded) {
        const item = packageStore.findTransferPackageByField(package_code, 'package_code', 'modify');
        if (item) {
          actions.change(formName, package_key, package_code);
          this.setState({changesLoaded: true});
        }
      }
    }
  }

  componentWillMount() {
    const { params, integrationState } = this.props;
    const { isMetrc, isMetrcTransfersEnabled } = integrationState;
    if(getUrlParam('print')) this.onPrintLabels(params.id);

    this.props.actions.batchActions([
      unsetData(dataNames.salesOrders),
      unsetData(dataNames.inventoryItems),
      unsetData(dataNames.childItemMasters),
      unsetItem(itemNames.transfer),
      unsetItem(itemNames.leafTransferStatus),
      unsetData(dataNames.itemMasters),
      unsetData(dataNames.inventoryItemsTestResults),
    ]);

    if (isMetrc && isMetrcTransfersEnabled) {
      this.props.actions.getUnpaginatedData('/api/metrc/transfers/types', dataNames.metrcTransferTypes);
    }

    const promises = [
      this.props.actions.loadTransferById(params.id).then(this.afterTransferLoaded),
      this.props.actions.getItem('/api/integration-settings', itemNames.integrationSettings, {failed: 'stateIntegratorSettings.get.failed'}),
      this.props.actions.getUnpaginatedData('/api/vehicles', dataNames.vehicles, {failed: 'failedToGetVehicles'}),
      this.props.actions.getUnpaginatedData('/api/partner_facilities', dataNames.partnerFacilities, {failed: 'partnerFacilities'}),
      this.props.actions.getUnpaginatedData('/api/prepack_weights/facility', dataNames.prepackFacilityWeights, undefined, {active: 1}),
      this.props.actions.getUnpaginatedData('/api/prepack_weights', dataNames.prepackWeights, {failed: 'failedToGetPrePackWeights'}),
      this.props.actions.getItem('/api/facility_groups_sharings/facility_status', itemNames.facilitySharingStatus),
      this.props.actions.getItem('/api/cultivation/settings', itemNames.wasteCompliance, null, {ids: ['distributions_transfer_line_limit']}),
      this.props.actions.getItem('/api/distribution/settings', itemNames.distributionSettings),
      this.props.actions.getItem('/api/compliance_settings', itemNames.complianceSettings),
      this.props.actions.getItem('/api/supply/settings', itemNames.supplySettings),
      this.props.actions.getUnpaginatedData('/api/location_hierarchy', dataNames.storageLocations, {failed: 'failedToGetLocations'}),
      this.props.actions.getUnpaginatedData('/api/partners', dataNames.partners, {failed:'partners.get.failed'}, {exclude_internal_duplicates: 1}, undefined, {debounce: true}),
      this.props.actions.getUnpaginatedData('/api/drivers/details', dataNames.drivers, {failed: 'failedToGetDrivers'}, {active: 1}).then((data) => {
        if (this.props.integrationState.isPaLeaf) {
          const params = {in_ids: data.map((driver) => driver.user_id)};
          this.props.actions.getDataByPost('/api/users/current_facility', params, dataNames.users, {}, {paginate: 0});
        }
      }),
      this.props.actions.getUnpaginatedData(
        '/api/production_runs',
        dataNames.productionRuns,
        {failed: 'productionRuns.get.failed'},
        {detailed: 1, status: statuses.open}
      )
    ];

    if (this.props.integrationState.isBiotrack) {
      promises.push(this.props.actions.getUnpaginatedData(`/api/biotrack/transfer_returns/${params.id}`, dataNames.returnedItems));
    }

    Promise.all(promises)
      .then(this.endProcessing)
      .catch(this.endProcessing);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {formValues:{lines, partner_discount, excise_tax_due, transfer_fee},childItemMasters} = this.props;
    if(prevProps){
      if(lines !== prevProps.formValues.lines) {
        lines.map((line, lineIndex) => {

          if(line.editableLinePrice === undefined) return line;

          if(prevProps.formValues.lines && prevProps.formValues.lines[lineIndex] ){

            if(line.source_sales_order_subitems !== prevProps.formValues.lines[lineIndex].source_sales_order_subitems && line.line_type === 'prepack'){
              const total_weight = line.source_sales_order_subitems.reduce((accumulator, subitem) => accumulator + parseFloat(subitem.total), 0);
              this.props.actions.change(formName, `lines[${lineIndex}].total_weight`, total_weight);
              this.props.actions.change(formName, `lines[${lineIndex}].update_sales_order_line`, 1);
            }
          }
        });
      }

      // If the line has en editable line item price (in which case unit_price is locked out)... set unit_price and line_item_price
      // Note that setting line_item price just manipulates the form object so we have it available on a switch from editable to calculated
      if(lines.length > 0) {
        if (lines[0].editableLinePrice !== undefined) {
            lines.map((line, lineIndex) => {

            const itemMaster = (childItemMasters[line.item_master_id] !== undefined) ? childItemMasters[line.item_master_id] : false;
            const lineItemPrice = parseFloat(get(line, 'line_item_price', 0));
            const quantity = (line.inventory) ? line.inventory.reduce((acc, item) => {
              const inventoryItemMaster = (itemMaster) ? itemMaster.find( (master) => master.id === item.item_master_id) : false;
              if (item.qty === undefined) return acc;
              acc += (inventoryItemMaster)
                ? parseFloat(item.qty * (inventoryItemMaster.prepack_fulfillment_units / 100))
                : parseFloat(item.qty);

              return acc;
            }, 0) : 0;

            let currentUnitPrice          = parseFloat(line.unit_price);
            let unitPrice                 = (lineItemPrice !== 0 && quantity !== 0) ? round(lineItemPrice / quantity, 2) : 0;
            const calculatedLineItemPrice = roundFloat(currentUnitPrice * quantity);

            if (line.editableLinePrice) {

              if (isNaN(currentUnitPrice)) currentUnitPrice = 0;
              if (isNaN(unitPrice)) unitPrice = 0;

              if (currentUnitPrice !== unitPrice) {
                this.props.actions.change(formName, `lines[${lineIndex}].unit_price`, unitPrice.toFixed(2));
                this.props.actions.change(formName, `lines[${lineIndex}].update_sales_order_line`, 1);

                if(line.inventory){
                  line.inventory.forEach( (inventory, inventoryIndex) => {
                    const inventoryItemMaster = (itemMaster) ? itemMaster.find( (master) => master.id === inventory.item_master_id) : false;
                    if(inventoryItemMaster) {
                      const prepackUnitPrice = (unitPrice * (inventoryItemMaster.prepack_fulfillment_units / 100));
                      this.props.actions.change(formName, `lines[${lineIndex}].inventory[${inventoryIndex}].unit_price`, prepackUnitPrice.toFixed(2));
                    }
                  });
                }
              }
            } else {
              if (lineItemPrice !== calculatedLineItemPrice) {
                //this.props.actions.change(formName, `lines[${lineIndex}.line_item_price`, calculatedLineItemPrice.toFixed(2));
                this.props.actions.change(formName, `lines[${lineIndex}].update_sales_order_line`, 1);
              }
            }
          });
        }
      }

      if(lines !== prevProps.formValues.lines ||
        partner_discount !== prevProps.formValues.partner_discount ||
        excise_tax_due !== prevProps.formValues.excise_tax_due ||
        transfer_fee !== prevProps.formValues.transfer_fee) {
        this.calcTotals();
      }
    }
  }

  getExpendedInventory() {

    // Get all inventory items in the transfer
    const allItems = [];
    this.props.transfer.lines.forEach((line) => {
      line.inventory.forEach((item) => {
        allItems.push({
          itemId: item.inventory_item_id,
          itemMasterId: item.item_master_id,
          parentItemMasterId: line.item_master_id
        });
      });
    });

    // Find the items that in the transfer but not in inventoryItems or childItemMasteers.  These have used up all inventory
    const missingItems = [];
    allItems.forEach((item) => {
      if(!item.itemId) {
        return;
      }
      if(item.itemMasterId !== item.parentItemMasterId){
        const itemMasterCollection = this.props.childItemMasters[item.parentItemMasterId] || [];
        const childItemMaster = itemMasterCollection.find((master) => master.id === item.itemMasterId) || [];
        const inventoryItem = childItemMaster.items && childItemMaster.items.find((inventoryItem) => inventoryItem.id === item.itemId);
        item.childItemMaster = childItemMaster;
        if(!inventoryItem) missingItems.push(item);
      } else {
        const itemMasterCollection = this.props.inventoryItems[item.itemMasterId] || [];
        const inventoryItem = itemMasterCollection.find((inventoryItem) => inventoryItem.id === item.itemId);
        if(!inventoryItem) missingItems.push(item);
      }
    });

    // Get the original inventory items so we can populate the dropdowns, storage, and quantity remaining with existing code
    const getMissingItems = () => {

      if(missingItems.length === 0) return false;

      const item = missingItems.pop();
      this.props.actions.getItem(`/api/items/${item.itemId}`, null, {}, {detailed: false}, (data) => {
        const stateInventoryField = (item.itemMasterId !== item.parentItemMasterId) ? 'prepackExpendedInventory' : 'expendedInventory';
        const newArray = this.state[stateInventoryField].map((item) => item);
        newArray.push(data);
        this.setState({[stateInventoryField]: newArray});
        this.props.actions.pushData([data], dataNames.inventoryItems);
        getMissingItems();
      });
    };

    getMissingItems();


    return Promise.resolve();
  }

  calcTotals() {
    const {lines, partner_discount, excise_tax_due, transfer_fee} = this.props.formValues;
    const subtotal = lines ? lines.reduce((accumulator, line) => {
      // line_item_price price value is not being updated, so we will calculate it
      const {unit_price, line_type} = line;
      let lineTotal = 0;
      if(line.editableLinePrice) {
        lineTotal += parseFloat(line.line_item_price);
      } else {
        (line.inventory || []).forEach(item => {
          if (parseFloat(item.qty)) {
            if (line_type === 'prepack' && parseFloat(item.unit_price)) {
              lineTotal += parseFloat(item.unit_price) * parseFloat(item.qty);
            }
            else if (parseFloat(unit_price)) {
              lineTotal += parseFloat(unit_price) * parseFloat(item.qty);
            }
          }
        });
      }
      return accumulator + lineTotal;
    }, 0) : 0;
    const discount = ((parseFloat(partner_discount) / 100) * subtotal);
    const order_total = parseFloat(excise_tax_due) + parseFloat(transfer_fee) + subtotal - discount;

    // Prevents looping behavior (performance issue mostly) which was triggering odd promise behavior throwing
    // errors on load
    if(this.state.destination_total !== order_total.toFixed(2)) {
      this.setState({destination_total: order_total.toFixed(2)}, () => {
        this.props.actions.change(formName, 'destination_total', order_total.toFixed(2));
      });
    }

  }

  onOpenSalesOrder(id) {
    this.props.actions.push(`/sales-orders/modify/${id}`);
  }

  onCancel() {
    const salesOrder = this.props.salesOrders ? this.props.salesOrders[0] ? this.props.salesOrders[0] : null : null;
    const isReceivedTransfer = this.props.isReceivedTransfer;
    // If not yet received and active Sales Oder show modal
    if (!isReceivedTransfer && !salesOrder.is_archived && !salesOrder.delivered) {
      this.setState({ showCancelTransferModal: true });
    }
    else {
      // If there is no active sales order then skip the modal and cancel the transfer
      this.processCancelTransfer();
    }
  }

  processCancelTransfer() {
    this.startProcessing('transfers.cancellationOfTransfer');
    const {params: {id}} = this.props;
    this.props.actions.putItem(`/api/transfers/${id}`,
      {status: 'cancelled'},
      itemNames.transfer,
      {success: 'transfers.cancelProcess.success', failed: 'transfers.cancelProcess.failed'}
    )
      .then(() => {
        this.props.actions.push('/transfers/inactive');
        this.endProcessing();
      })
      .catch(this.endProcessing);
  }

  handleRestockAndCompleteTransfer () {
    const {formValues, params: {id}} = this.props;

    const failedValidation = get(formValues, 'returnedItems', []).filter(returnedItem => {
      return !returnedItem.inventory_location_id;
    });

    if (failedValidation.length > 0) {
      return this.props.actions.addMessage(messageTypes.error, ['cultivation.transfers.returnedItemsValidationError']);
    }

    const returned_items = get(formValues, 'returnedItems', []);

    this.props.actions.postItem('/api/transfers/restock', {
      id,
      returned_items
    }).then(() => {
      this.props.actions.submit();
    });
  }

  showConfirmationPopup(resolve, reject) {
    const hidePopup = (cb) => () => {
      this.setState({
        showNegativeInvConfirmation: {
          show:false,
          onConfirm: null,
          onHide: null
        }
      });
      cb();
    };
    this.setState({
      showNegativeInvConfirmation: {
        show: true,
        onConfirm: hidePopup(resolve),
        onHide: hidePopup(reject),
      }
    });
  }

  validateNegativeInventory(payload) {
    return new Promise((resolve, reject) => {
      const handlePackages = (result, line) => {
        get(line, 'inventory', []).forEach((inv) => {
          if (inv.qty > inv.qty_available) {
            result =  true;
          }
        });

        return result;
      };

      const showPopup = payload.lines.reduce(handlePackages, false);
      if (showPopup && this.props.isAllowNegativeInventory) {
        return this.showConfirmationPopup(resolve, reject);
      }

      return resolve();
    });
  }

  onDontRestock () {
    this.props.actions.changeToOutForDelivery().then(() => {
      this.props.actions.submit();
    });
  }

  onCreateMetrcTemplate () {
    const {transfer} = this.props;

    const payload = {
      transfer_id: transfer.id,
    };

    const messages = {
      success: 'salesOrders.form.createTemplateSuccess',
    };

    this.props.actions.postData('/api/metrc/transfers/templates', payload, undefined, messages);
  }

  onSubmit(result) {
    const {params: {id}, vehicles} = this.props;
    if(!result.move_all_inventory){
      result.new_location_id = undefined;
    }

    const lineFields = ['container_count', 'container_type_id', 'source_sales_order_id', 'source_sales_order_line_id',
      'item_master_id', 'ordered_qty', 'ordered_uom', 'transfer_qty', 'transfer_uom', 'unit_price', 'line_item_price',
      'inventory_item_id', 'id', 'update_sales_order_line'
    ];
    const inventoryFields = [
      'id', 'transfer_line_id', 'item_master_id', 'qty', 'package_code', 'uom', 'unit_price', 'unit_discount', 'source_subitem_id',
      'inventory_item_id', 'qty_available', 'inventory_transaction_id', 'gross_weight', 'gross_weight_uom', 'disposal_reason',
      'hybrid_location_id','is_for_extraction','sent_net', 'sent_gross', 'sent_net_uom', 'sent_gross_uom'
    ];
    const salesOrderFields = [
      'id', 'transfer_id', 'sales_order_id', 'date_filled', 'discount_percent', 'transfer_fee', 'taxes', 'order_total', 'partner_facility'
    ];

    // Set line item prices from totals which calculates them correctly... something not right in probably my code.
    result.lines.forEach( (line, lineIndex) => {
      line.line_item_price = this.props.lineTotals[lineIndex];
    });

    const lines = result.lines.map(line => Object.assign({}, pick(line, lineFields), {
      inventory: line.inventory.map(item => {
        const newItem = pick(item, inventoryFields);
        newItem.sent_gross = newItem.sent_gross === '' ? null : newItem.sent_gross;
        newItem.sent_net = newItem.sent_net === '' ? null : newItem.sent_net;
        newItem.disposal_reason = line.is_waste_disposal ? newItem.disposal_reason || result.disposal_reason : undefined;
        return newItem;
      })
    }));
    const salesOrders = result.sales_orders.map(order => pick(order, salesOrderFields));
    //Update in the case of future iteration of Sales Orders
    salesOrders[0].order_total = `${result.destination_total}`;
    const getVehicle = vehicles.find(vehicle => vehicle.id === result.vehicle_id);

    const transfer = Object.assign({}, {
      vehicle_id: result.vehicle_id,
      vehicle_name: getVehicle && getVehicle.nickname,
      vehicle_make: result.vehicle_make,
      vehicle_model: result.vehicle_model,
      vehicle_license_plate: result.vehicle_license_plate,
      date_transferred: convertFormInputDateToDbDate(result.date_transferred, this.props.timezone) || undefined,
      status: result.status,
      lines,
      destinations: result.destinations,
      files: result.files,
      sales_orders: salesOrders,
      notes: result.notes,
      integration_transfer_type: result.integration_transfer_type
    }, mapDriversArrayToDbFields(result.drivers));

    if (transfer.destinations && transfer.destinations.length) {
      transfer.destinations = transfer.destinations.map(destination => {
        let departure_time = undefined, arrival_time = undefined;
        if (destination.departure_time) {
          if (typeof destination.departure_time === 'string') {
            const departureTimeString = moment().format('YYYY-MM-DD') + ' ' + destination.departure_time;
            departure_time = convertFormInputDateToDbTime(departureTimeString, this.props.timezone);
          }
          else if (typeof destination.departure_time === 'object') {
            departure_time = convertFormInputDateToDbTime(destination.departure_time, this.props.timezone);
          }
        }
        if (destination.arrival_time) {
          if (typeof destination.arrival_time === 'string') {
            const arrivalTimeString = moment().format('YYYY-MM-DD') + ' ' + destination.arrival_time;
            arrival_time = convertFormInputDateToDbTime(arrivalTimeString, this.props.timezone);
          }
          else if (typeof destination.arrival_time === 'object') {
            arrival_time = convertFormInputDateToDbTime(destination.arrival_time, this.props.timezone);
          }
        }
        return Object.assign({}, destination, {
          departure_time,
          arrival_time
        });
      });
    }
    if (result.move_all_inventory) {
      transfer.move_all_inventory = result.move_all_inventory;
      transfer.new_location_id = result.new_location_id;
    }

    // Removing unnecessary values from the sales order.
    // Sales orders are updated based on transfer values - not anything in the sales order
    // request body.
    transfer.sales_orders.map((order) => {
      const fields = ['order_total', 'taxes', 'transfer_fee', 'discount_percent'];
      fields.forEach((field) => {
        delete(order[field]);
      });
      return order;
    });

    // Hotfix - this is not optimal but transfers are broken on production due to null not validating
    // Saw this in testing the hotfix when merged to release/0.4.3.  I don't know why the behavior
    // changed on hotfix... but it has... tested heavily Friday 06/30 and nulls went through on hotfix
    // but today do not.
    const denulls = [];
    for(const prop in transfer){
      if(transfer[prop] === null) denulls.push(prop);
    }

    denulls.forEach((prop) => {
      delete(transfer[prop]);
    });

    // conditionally map ordered_qty to transfer_qty
    transfer.lines = transfer.lines.map((line) => {
      line.update_sales_order_line = 1;
      if(line.update_sales_order_line_quantity !== undefined) {
        if (line.update_sales_order_line_quantity === 1) {
          line.transfer_qty = line.ordered_qty;
        }
      }
      line.inventory = (line.inventory || []).map(inventory => {
        const temp = inventory.hybrid_location_id.split('_');
        const inventoryItemId = parseInt(temp[0]);
        return {
          ...inventory,
          inventory_item_id: inventoryItemId, // Specifically for prepacks but used for all, gets id from hybrid_location_id (HOTFIX: 07/03/2018 on 1.2.3)
          inventory_location_id: temp[1],
        };
      });
      return line;
    });
    if(result.status === 'open' && result.date_ordered){
      result.status = 'out_for_delivery';
    }

    const inventoryItemIds = [];
    const inventoryItemPackageCode = {};
    result.lines.forEach(line => line.inventory.forEach(item => {
      if (item.inventory_item_id && line.is_medicated) {
        inventoryItemIds.push(item.inventory_item_id);
        inventoryItemPackageCode[item.inventory_item_id] = item.package_code;
      }
    }));

    const hasMedicated = inventoryItemIds.length > 0;

    const sendTransferRequest = () => this.props.actions.putItem(`/api/transfers/${id}`,
      transfer,
      itemNames.transfer,
      {success:'transfers.create.success', failed:'transfers.create.fail'},
      undefined,
      () => {
        const status = result.status;

        if (status === 'open' || status === 'out_for_delivery') {
          this.props.actions.push('/transfers');
        } else if (status === 'completed') {
          this.props.actions.push('/transfers/inactive');
        } else {
          this.props.actions.goBack();
        }
      }
    );

    const checkLabResults = () => {
      if (inventoryItemIds.length > 0) {
        const url = '/api/lab_results/by_item_id/multiple';
        const payload = {ids: inventoryItemIds};
        const messages = {failed: 'cultivation.transfers.checkLabResults.failed'};
        if (this.props.integrationState.isLeaf) payload.state_integration_source = 'leaf';

        return this.props.actions.postItem(url, payload, null, messages)
          .then((results) => {

            const labResultPassed = (result) => {
              if(this.props.integrationState.isLeaf) {
                return result.lab_result.status
                  && typeof result.lab_result.status === 'string'
                  && result.lab_result.state_integration_source === 'leaf'
                  && result.lab_result.status.toLowerCase() === 'passed';
              } else { // future proof if included for other cases
                return result.lab_result.status
                  && typeof result.lab_result.status === 'string'
                  && result.lab_result.status.toLowerCase() === 'passed';
              }
            };

            if (Array.isArray(results)) {
              if (!recheckUpdatedLabResSetMsgError(results,labResultPassed)){
                return Promise.reject();
              }
            }
          });
      }
      else {
        return Promise.resolve();
      }
    };

    const recheckUpdatedLabResSetMsgError = (results, labResultPassed) => {
        let valid = true;
        if (!this.props.isForLabPartner && this.props.needTestResults) {
          results.forEach(result => {
            if(this.props.integrationState.isWaLeaf){
              const testResultsMessage = testResultsMessageWA(results,result.item_id);
              if(testResultsMessage){
                  valid = false;
                  this.props.actions.addMessage(
                    'error', ['cultivation.transfers.checkLabResults.waValidateTransfer.' + testResultsMessage, {packageCode: inventoryItemPackageCode[result.item_id]}]
                  );
              }
            }
            else {
              if (!result.lab_result) {
                this.props.actions.addMessage(
                  'error', ['cultivation.transfers.checkLabResults.packageMissingLabResults', {packageCode: inventoryItemPackageCode[result.item_id]}]
                );
                valid = false;
              }
              if (!this.props.integrationState.isPaLeaf && !labResultPassed(result)) {
                this.props.actions.addMessage(
                  'error', ['cultivation.transfers.checkLabResults.packageFailedLabResults', {packageCode: inventoryItemPackageCode[result.item_id]}]
                );
                valid = false;
              }
            }
          });
        }
        return valid;
    };

    const testResultsMessageWA = (results,currentItemId) => {
      let inventoryItemForItemMaster = {};
      const selectedItem = this.props.formValues.lines.filter(line => {
        return line.inventory.find(inventory => inventory.inventory_item_id === currentItemId);
      });
      if (this.props.inventoryItems[selectedItem[0].item_master_id]) {
        inventoryItemForItemMaster = (this.props.inventoryItems[selectedItem[0].item_master_id].find(item => item.id.toString() === currentItemId.toString()));
      }
      const packageData = {
        testResults: results,
        meta: selectedItem[0].meta,
        productSubTypes,
        salesOrder: this.props.salesOrders.length > 0 ? this.props.salesOrders[0] : {},
        item: inventoryItemForItemMaster,
        selectedItem: selectedItem[0].inventory[0]
      };
      return checkPackageForLeafWaAndPa(packageData);
    };

    const validateLeafTransfer = () => this.props.actions.postItem('/api/leaf/validate/transfer',
      transfer, null, undefined, undefined, undefined , {silenceErrors: true});


    const getPartnerFacilityType = (partnerFacilityId) => {
      return this.props.actions.getItem(`/api/partner_facilities/facility_details/${partnerFacilityId}`, itemNames.partnerFacilityDetails)
        .then((partnerFacility) => {
          return partnerFacility.type;
        });
    };

    /* eslint-disable */
    const checkTransferFacility = async () => {
      // Any facility type can transfer to a lab partner
      if (this.props.isForLabPartner) {
        return Promise.resolve();
      }

      const transferType = get(transfer, 'sales_orders[0].transfer_type', 'wholesale');

      // Returns can be made from a dispensary to a manufacturing and a internal dispensary
      const isReturn = get(transfer, 'sales_orders[0].is_return', false);
      if (isReturn) {
        for (const destination of transfer.destinations) {
          if (destination.partner_facility_id) {
            const partnerFacilityType = await getPartnerFacilityType(destination.partner_facility_id);
            if (!isValidReturn(this.props.currentFacilityType, partnerFacilityType, transferType)) {
              Promise.reject({message: 'cultivation.transfers.form.transferReturnLimitation'})
            }
          }
        }
        return Promise.resolve();
      }

      for (const destination of transfer.destinations) {
        if (destination.partner_facility_id) {
          const partnerFacilityType = await getPartnerFacilityType(destination.partner_facility_id);
          if (!isValidTransfer(this.props.currentFacilityType, partnerFacilityType, transferType)) {
            Promise.reject({message: 'cultivation.transfers.form.transferLimitation'});
          }
        }
      }
      return Promise.resolve();
    };
    /* eslint-enable */

    if(this.props.integrationState.isLeaf && hasMedicated) {
      return validateLeafTransfer()
        .then(() => {
           Promise.all([
            this.validateNegativeInventory(transfer),
            checkTransferFacility(),
            checkLabResults(),
          ])
            .then(sendTransferRequest)
            .catch((error) => {
              if(error.response && error.response.data && error.response.data.errors){
                const {errors} = error.response.data;
                errors.map(e => {
                  if(e.inventory_item_id){
                    this.props.actions.addMessage('error', ['cultivation.transfers.integrationError', e]);
                  }else{
                    this.props.actions.addMessage('error', e);
                  }
                });
              }else{
                this.props.actions.addMessage('error', error.message);
              }
            });
        })
        .catch((error) => {
          if(error.response && error.response.data && error.response.data.errors){
            const {errors} = error.response.data;
            errors.map(e => {
              if(e.inventory_item_id){
                this.props.actions.addMessage('error', `${e.message} for package ${e.package_code}`);
              }else{
                this.props.actions.addMessage('error', e);
              }
            });
          }
        });
    } else {
      return this
        .validateNegativeInventory(transfer)
        .then(sendTransferRequest)
        .catch(() => {});
    }
  }

  createDriver() {
    this.props.actions.push('/driver/create');
  }

  handleScanSearch(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      const {scanField, inventoryItems, childItemMasters, lines} = this.props;
      const found = Object.keys(inventoryItems).map(itemMaster => {
        if (inventoryItems[itemMaster]) {
          const inventoryItem = inventoryItems[itemMaster].find(item => item.package_code === scanField);
          if(inventoryItem){
            const lineIndex = lines.findIndex(line => line.item_master_id.toString() === itemMaster);
            this.props.actions.change(formName, `lines[${lineIndex}].inventory_item_id`, inventoryItem.id);
            this.props.actions.change(formName, `lines[${lineIndex}].qty_available`, inventoryItem.qty);
            this.props.actions.change(formName, `lines[${lineIndex}].lot_number`, inventoryItem.lot_number);
            this.props.actions.change(formName, `scanField`, '');
            return 1;
          }
        }
      });
      if(!found.filter(Boolean).length){
        Object.keys(childItemMasters).map(parentItemMaster => {
          childItemMasters[parentItemMaster].map((child, index) => {
            if (child.items && child.items.length) {
              const inventoryItem = child.items.find(item => item.package_code === scanField);
              if(inventoryItem){
                const lineIndex = lines.findIndex(line => line.item_master_id.toString() === parentItemMaster);
                this.props.actions.change(formName, `lines[${lineIndex}].subitems[${index}].inventory_item_id`, inventoryItem.id);
                this.props.actions.change(formName, `lines[${lineIndex}].subitems[${index}].qty_available`, inventoryItem.qty);
                this.props.actions.change(formName, `scanField`, '');
                return 1;
              }
            }
          });
        });
      }
    }
  }

  startProcessing(processingMessage = '') {
    this.setState({
      processing: true,
      processingMessage,
    });
  }

  endProcessing() {
    this.setState({
      processing: false,
      processingMessage: '',
    });
  }

  redirect() {
    this.props.actions.goBack();
  }

  onPrintLabels(id = false) {
    this.setState({showPrinter: true, payload: {id: id ? id : this.props.transfer.id}, params: {source: 'transfer'}});
  }

  onHidePrinter(){
    this.setState({showPrinter: false});
  }

  render() {
    const { showNegativeInvConfirmation } = this.state;
    const {
      lines,
      drivers,
      timezone,
      vehicles,
      transfer,
      locations,
      formValues,
      sentTotals,
      initValues,
      lineTotals,
      isMedicated,
      itemMasters,
      salesOrders,
      destinations,
      prepackWeights,
      inventoryItems,
      isWasteDisposal,
      isDriverCompany,
      sharedLocations,
      transferStatuses,
      integrationState,
      isOutForDelivery,
      childItemMasters,
      partnerFacilities,
      isReceivedTransfer,
      transferLinesLimit,
      isAllowNegativeInventory,
      inventoryItemsTestResults,
      prepackItemMasterChildren,
      metrcIsImported,
      constants,
      sentNetTotals,
      integrationTransferTypes,
      isSplitPackageRedirectToggled,
      distributionSettings,
      isPaRemediationLabelsToggled
    } = this.props;
    const isMVP = true;
    const formData = {
      ...this.props.formData,
      lines,
      prepackWeights,
      inventoryItems,
      orders: salesOrders,
      drivers,
      transfer,
      vehicles,
      destinations,
      itemMasters,
      initValues,
      partner_facilities: partnerFacilities,
      transferTypes: [{text: 'Wholesale', value: '1'}],
      locations,
      sharedLocations,
      childItemMasters,
      prepackItemMasterChildren,
    };
    initValues.form_type = 'modify_form';
    initValues.initial_status = initValues.status;

    const salesOrder = salesOrders ? salesOrders[0] ? salesOrders[0] : null : null;
    const salesOrderNumber = salesOrder ? salesOrder.sales_order_number : '';

    return (
      <FormWrapper className='create-transfer-page' title='cultivation.transfers.modify' goBack={this.redirect} titleDescription={this.props.transfer.transfer_number}>
        <LabelPrinter
          showModal={this.state.showPrinter}
          onHide={this.onHidePrinter}
          labelTag='supply_inventory_receipt_all'
          payload={this.state.payload}
          params={this.state.params}
        />
        <InProgressOverlay
          isActive={this.state.processing}
          message={this.state.processingMessage}
          showOk={false}
          showLoader={true}
          translate={true} />
        <TransferForm
          onDontRestock={this.onDontRestock}
          orders={salesOrders}
          lineTotals={lineTotals}
          sentTotals={sentTotals}
          sentNetTotals={sentNetTotals}
          isMVP={isMVP}
          form={formName}
          formName={formName} /*not a mistake, passing down to package selections and want it to be clear what it is*/
          onSubmit={this.onSubmit}
          onPrintLabels={this.onPrintLabels}
          handleKeyPress={this.handleScanSearch}
          formData={formData}
          showNegativeInvConfirmation={showNegativeInvConfirmation}
          enableReinitialize={true}
          keepDirtyOnReinitialize={false} // was true up until 3/22/2018 and not sure why, was breaking things
          isEditMode={true}
          initialValues={initValues}
          isMedicated={isMedicated}
          isComplete={this.state.isComplete}
          onCancel={this.onCancel}
          prepackExpendedInventory={this.state.prepackExpendedInventory}
          expendedInventory={this.state.expendedInventory}
          integrationState={integrationState}
          transferId={this.props.params.id}
          createDriver={this.createDriver}
          timezone={timezone}
          isDriverCompany={isDriverCompany}
          isWasteDisposal={isWasteDisposal}
          isReceivedTransfer={isReceivedTransfer}
          isOutForDelivery={isOutForDelivery}
          transferStatuses={transferStatuses}
          isAllowNegativeInventory={isAllowNegativeInventory}
          drivers={drivers}
          transferLinesLimit={transferLinesLimit}
          completingTransfer={formValues && formValues.status === 'completed'}
          handleRestockAndCompleteTransfer={this.handleRestockAndCompleteTransfer}
          inventoryItemsTestResults={inventoryItemsTestResults}
          metrcIsImported={metrcIsImported}
          integrationTransferTypes={integrationTransferTypes}
          constants={constants}
          onCreateMetrcTemplate={this.onCreateMetrcTemplate}
          currentTransferStatus={formValues && formValues.status}
          isSplitPackageRedirectToggled={isSplitPackageRedirectToggled}
          moveAllInventoryChecked={formValues && formValues.move_all_inventory}
          distributionSettings={distributionSettings}
          isPaRemediationLabelsToggled={isPaRemediationLabelsToggled}
          prepackWeights={prepackWeights}
        />
        <ModalWrapper
          Component={false}
          version={2}
          onHide={() => this.setState({ showCancelTransferModal: false })}
          showModal={this.state.showCancelTransferModal}
          title='cultivation.transfers.cancelModal.title'
          okayButton={{
            show: true,
            variant: 'primary',
            text: 'Confirm',
            onClick: () => {
              this.processCancelTransfer();
              this.setState({ showCancelTransferModal: false });
            }
          }}
          cancelButton={{
            show: true,
            text: 'Close',
            variant: 'default',
            onClick: () => {
              this.setState({ showCancelTransferModal: false });
            }
          }}
        >
          <div>
            <div className='alert alert-warning'>{I18n.t('cultivation.transfers.cancelModal.body.1')}</div>
            <strong>{I18n.t('cultivation.transfers.cancelModal.body.2')}</strong><br/>
            <br/>
            {I18n.t('cultivation.transfers.cancelModal.body.3')}<br/>
            <br/>
            {I18n.t('cultivation.transfers.cancelModal.body.4')}<br/>
            <br/>
            <div className='alert-info' style={{padding: '10px', marginBottom: '5px'}}>
              {I18n.t('cultivation.transfers.cancelModal.body.5', {salesOrderNumber: salesOrderNumber})}&nbsp;
              <strong>
                <a
                  href='#'
                  className='float-right'
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    this.onOpenSalesOrder(salesOrder.id);
                  }}
                >
                  {I18n.t('cultivation.transfers.cancelModal.openSalesOrder', {salesOrderNumber: salesOrderNumber})}
                </a>
              </strong>
            </div>
            <br/>
            {I18n.t('cultivation.transfers.cancelModal.additionalInformation.text')}<br/>
            <a target='_blank' href='https://support.mjfreeway.com/hc/en-us/articles/4454334723866'>
              {I18n.t('cultivation.transfers.cancelModal.additionalInformation.linkTitle')}
            </a>
          </div>
        </ModalWrapper>
      </FormWrapper>
    );
  }
}

ModifyTransferPage.propTypes = {
  salesOrders: PropTypes.array.isRequired,
  transfer: PropTypes.object.isRequired,
  drivers: PropTypes.array.isRequired,
  vehicles: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  sharedLocations: PropTypes.array.isRequired,
  destinations: PropTypes.array.isRequired,
  lines: PropTypes.array.isRequired,
  formValues: PropTypes.shape({
    lines: PropTypes.array.isRequired,
    transfer_fee: PropTypes.string.isRequired,
    excise_tax_due: PropTypes.string.isRequired,
    partner_discount: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }),
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  initValues: PropTypes.object.isRequired,
  inventoryItems: PropTypes.object.isRequired,
  selectedSalesOrders: PropTypes.array.isRequired,
  partnerFacilities: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  prepackWeights: PropTypes.array,
  totals: PropTypes.object.isRequired,
  itemMasters: PropTypes.array.isRequired,
  childItemMasters: PropTypes.object.isRequired,
  prepackItemMasterChildren: PropTypes.object.isRequired,
  scanField: PropTypes.string,
  sentTotals: PropTypes.array.isRequired,
  lineTotals: PropTypes.array.isRequired,
  editableLinePrices: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  isForLabPartner: PropTypes.bool.isRequired,
  isDriverCompany: PropTypes.bool.isRequired,
  isWasteDisposal: PropTypes.bool,
  isReceivedTransfer: PropTypes.bool,
  isMedicated: PropTypes.bool,
  isOutForDelivery: PropTypes.bool,
  transferStatuses: PropTypes.array,
  needTestResults: PropTypes.bool,
  isAllowNegativeInventory: PropTypes.bool,
  transferLinesLimit: PropTypes.number.isRequired,
  inventoryItemsTestResults: PropTypes.array,
  metrcIsImported: PropTypes.bool,
  constants: PropTypes.object,
  formData: PropTypes.object.isRequired,
  isSplitPackageRedirectToggled: PropTypes.bool,
  distributionSettings: PropTypes.object.isRequired,
};

function mapDispatchToProps (dispatch) {
  const submitForm = () => dispatch => dispatch(submit(formName));

  const changeToOutForDelivery = () => dispatch => {
    return new Promise((resolve) => {
      dispatch(change(formName, 'status', 'out_for_delivery'));
      resolve();
    });
  };

  const actions = {goBack, getUnpaginatedData, getItem, postData, putItem, unsetData, setData, addSelectedData,
    unsetItem, postItem, change, addMessage, push, batchActions, getDataByPost, pushData, submit: submitForm, changeToOutForDelivery,
    loadTransferById, loadInventoryForSalesOrders
  };
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}
const selector = formValueSelector(formName);

function mapStateToProps(state) {
  const {transfer, salesOrders, vehicles, selectedSalesOrders, partnerFacilities,
    itemMasters, timezone, prepackWeights} = state;
  const childItemMasters = getChildItemMastersForFacility(state);
  const prepackItemMasterChildren = getModifyTransferPrepackItemMasterChildren(state);
  let newSalesOrders = salesOrders;
  if(salesOrders){
    newSalesOrders = salesOrders.map(salesOrder => ({...salesOrder,
    date_ordered: convertDbDateToFormInputDate(salesOrder.date_ordered, timezone) || moment().format('MM/DD/YYYY'),
    date_expected: convertDbDateToFormInputDate(salesOrder.date_expected, timezone) || moment().format('MM/DD/YYYY')
   }));
  }

  const integrationState = getIntegrationState(state);
  const metrcIsImported = integrationState.isMetrc && get(salesOrders, '0.is_imported');

  const lines = getSalesOrdersLines(state);
  const initValues = getModifyTransferInitialValues(state);
  const distributionSettings = getDistributionSettings(state);
  return {
    packageStore: new PackageStore(state),
    lineTotals: getCreateTransferLineTotals(state),
    sentTotals: getCreateTransferSentTotals(state),
    sentNetTotals: getCreateTransferSentNetTotals(state),
    inventoryItems: getFilteredInventoryItems(state),
    selectedSalesOrders: selectedSalesOrders.length ? selectedSalesOrders : [],
    destinations: getSalesOrdersDestinations(state),
    distributionSettings,
    lines,
    isMedicated: isMedicatedOrder(state),
    initValues: initValues,
    prepackItemMasterChildren,
    totals: getSalesOrdersTotals(state),
    childItemMasters,
    formValues:getModifyTransferFormValues(state),
    scanField: selector(state, 'scanField'),
    prepackWeights,
    partnerFacilities,
    salesOrders: newSalesOrders,
    transfer,
    drivers: getValidDrivers(state),
    vehicles,
    itemMasters,
    locations: getFlattenedStorageLocations(state),
    sharedLocations: getLocationsForSharedProducts(state),
    editableLinePrices: getCreateTransferEditablePrices(state),
    timezone,
    metrcIsImported,
    integrationState,
    isForLabPartner: isForLabPartner(state),
    isDriverCompany: isDriverCompany(state, {drivers: selector(state, 'drivers')}),
    isWasteDisposal: selector(state, 'is_waste_disposal'),
    currentFacilityType: getFacilityType(state),
    isReceivedTransfer: isReceivedTransfer(state),
    isOutForDelivery: isOutForDeliveryTransfer(state),
    transferStatuses: getTransferStatuses(state),
    needTestResults: needTestResults(state),
    isAllowNegativeInventory: complianceSettings.isAllowNegativeInventory(state),
    transferLinesLimit: getTransferLinesLimit(state),
    inventoryItemsTestResults: labResultsSelector.getList(state),
    constants: state[itemNames.constants],
    integrationTransferTypes: state[dataNames.metrcTransferTypes],
    formData : {
      medicatedTransfersRequireEntireQuantity: get(distributionSettings, 'distributions_transfer_requires_full_quantity.value', false)
    },
    isSplitPackageRedirectToggled: isFeatureEnabled(state)('feature_metrc_split_package_redirect'),
    isPaRemediationLabelsToggled: isFeatureEnabled(state)('feature_pa_hb_1024_remediation_labels'),
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (ModifyTransferPage);
