import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { I18n } from 'react-redux-i18n';
import { Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import { change } from 'redux-form';
import * as dataNames from '../../../../constants/dataNames';
import * as itemNames from '../../../../constants/itemNames';
import { PA_MED_CAREGIVER, PA_MED_PATIENT } from '../../../../constants/idTypes';
import { formatDate } from '../../../../util/dateHelpers';
import { getOraclePatient, getOracleCaregiver, getNormalizedOracleData } from '../../../../selectors/oracleSelectors';
import { getDataUpdateAvailable, dataUpdateSetAvailable } from '../../../../selectors/dataUpdateSelectors';
import { addMessage } from '../../../../actions/systemActions';
import { setItem, unsetItem } from '../../../../actions/itemActions';
import { setData } from '../../../../actions/dataActions';
import { getConsumers } from '../../../../actions/consumerActions';
import {
  postItem,
  getItem,
  putItem,
  getUnpaginatedData,
  getSearchData,
  getDataByPost
} from '../../../../actions/apiActions';
import InProgressOverlay from '../../../common/InProgressOverlay';
import ScanInputModal from '../../../scan-input/ScanInputModal';
import CheckinLeafPAFormWrapper from './CheckinLeafPAFormWrapper';
import ModalWrapper from '../../../common/ModalWrapper';
import PatientDetailsLeafPA from './PatientDetailsLeafPA';
import CaregiverDetailsLeafPA from './CaregiverDetailsLeafPA';
import * as messageTypes from '../../../../constants/messageTypes';
import {isFeatureEnabled} from '../../../../selectors/featureToggles';

const getAt = get;
const form = 'checkinLeafPA';

export class CheckinLeafPAPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: false,
      showScanModal: false,
      license_type: null,
      expanded: {},
      expirationDate: undefined,
      patientUpdatePayload: null,
      showCardError: false,
      cardErrorMessage: false,
    };
    this.onDetailsExpand = this.onDetailsExpand.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.validateCard = this.validateCard.bind(this);
    this.getOracleDetails = this.getOracleDetails.bind(this);
    this.loadCustomers = this.loadCustomers.bind(this);
    this.onScanComplete = this.onScanComplete.bind(this);
    this.showScanModal = this.showScanModal.bind(this);
    this.hideScanModal = this.hideScanModal.bind(this);
    this.onRegister = this.onRegister.bind(this);
    this.showCardError = this.showCardError.bind(this);
    this.hideCardError = this.hideCardError.bind(this);
    this.onShowUpdateModal = this.onShowUpdateModal.bind(this);
    this.onHideUpdateModal = this.onHideUpdateModal.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onUpdatePatient = this.onUpdatePatient.bind(this);
    this.validateSelectedPatientData = this.validateSelectedPatientData.bind(this);
  }

  componentWillMount() {
    this.props.actions.getItem('/api/csrs', itemNames.budTendersObject, {
      failed: I18n.t('customers.getBudTenders.failed')
    });
    this.props.actions.getUnpaginatedData('/api/queue/visit_reasons', dataNames.visitReasons);
    this.props.actions.unsetItem(itemNames.oraclePatient);
    this.props.actions.unsetItem(itemNames.oracleCaregiver);
  }

  componentWillReceiveProps(nextProps) {
    if (dataUpdateSetAvailable(nextProps.dataUpdateAvailable, this.props.dataUpdateAvailable)) {
      setTimeout(() => this.loadCustomers(), 100);
    }
  }

  onDetailsExpand(consumerId, isExpanded) {
    this.setState((state) => ({
      expanded: {
        ...state.expanded,
        [consumerId]: isExpanded
      }
    }));
  }

  onSearch(formData) {
    this.setState({ searchFormData: formData });
    this.setState({ caregiverdetails: null });
    this.setState({ loading: true });
    formData = getNormalizedOracleData(formData);

    return this.validateCard(formData)
      .then(() => this.getOracleDetails(formData))
      .then(() => this.loadCustomers(formData.license_type))
      .catch(() => this.setState({ loading: false }));
  }

  getOracleDetails(formData) {
    const type = formData.license_type === PA_MED_CAREGIVER ? 'caregiver_details' : 'patient_details';
    const url = `/api/leaf/oracle/${type}`;
    const payload = {
      registry_id: formData.registry_id,
      card_number: formData.card_number,
      dob: formData.dob ? formatDate(formData.dob) : undefined
    };
    const name = formData.license_type === PA_MED_CAREGIVER ? itemNames.oracleCaregiver : itemNames.oraclePatient;
    const failedHandler = (error) => {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.BADDOB
      ) {
        return { message: error.response.data.errors.BADDOB[0] };
      }
      return { message: 'checkin.getPatient.failed' };
    };

    this.setState({ license_type: formData.license_type, dataName: name });
    this.props.actions.setItem(formData, itemNames.checkInFormValues);

    return new Promise((resolve, reject) => {
      this.setState({ patientUpdatePayload: null });

      this.props.actions
        .postItem(url, payload, name, { failedHandler })
        .then((details) => {
          // For patients, check card expiration and add new if needed
          const mjpId = get(details, 'consumer.id', false);

          if (type === 'caregiver_details' || !mjpId) {
            this.setState({ details: details });
            resolve();
            return true;
          }

          // Check if Oracle patient State ID and MMID are in sync with MJP patient
          const foreignIdNumber = get(details, 'patient_card_number');
          const idNumberKey = 'identification_number';
          const foreignIdFound = []
            .concat(get(details, 'consumer.ids', []))
            .concat(get(details, 'consumer.expired_ids', []))
            .reduce((acc, id) => {
              return acc ? acc : get(id, idNumberKey) === foreignIdNumber;
            }, false);
          const hasSameStateId = get(details, 'patient_id') === get(details, 'consumer.state_integration_tracking_id');
          if (foreignIdFound && hasSameStateId) {
            resolve();
            return true;
          }

          // Populate update payload with new MMID or with new state ID
          const patientUpdatePayload = {
            id: get(details, 'consumer.id', 0)
          };
          if (!foreignIdFound) {
            const ids = get(details, 'consumer.ids', []);
            const newMMID = {
              [idNumberKey]: foreignIdNumber,
              type: 'med',
              file_type: 'image',
              file: null,
              effective_at: null,
              expired_at: null
            };
            patientUpdatePayload.ids = [...ids, newMMID];
          }
          if (!hasSameStateId) {
            const newStateId = get(details, 'patient_id');
            if (newStateId) {
              patientUpdatePayload.state_integration_tracking_id = newStateId;
            }
          }

          // Display modal with update confirmation
          this.setState({
            showUpdateModal: true,
            patientUpdatePayload,
            details,
            loading: false
          });
          reject();
        })
        .catch(() => {
          this.setState({ loading: false });
          reject();
        });
    });
  }

  loadCustomers(license_type = this.state.license_type) {
    this.setState({ license_type, loading: false });

    const { patient, caregiver } = this.props;
    let customerIds = [];
    if (license_type === PA_MED_PATIENT) {
      if (patient.consumer && patient.consumer.id) {
        customerIds.push(patient.consumer.id);
      }
    } else if (license_type === PA_MED_CAREGIVER) {
      customerIds = (caregiver.patients || [])
        .map((patient) => patient.consumer && patient.consumer.id)
        .filter(Boolean);
    }
    if (customerIds.length) {
      const highPageSize = 10000; // No paging UI on leaf check in page so we can't paginate in scope of story; would be a rare case.
      const anyActiveStatus = -1;
      this.props.actions.getConsumers(undefined, highPageSize, 0, anyActiveStatus, customerIds);
    }
  }

  validateCard(formData) {
    const payload = {
      registry_id: formData.registry_id,
      card_number: formData.card_number,
      contact_type: formData.card_type
    };
    return new Promise((resolve, reject) => {
      this.props.actions
        .postItem('/api/leaf/oracle/validate_card', payload, null, { failed: 'suppress' })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          this.showCardError(error.response && error.response.data ? error.response.data : []);

          // PA patient's card is in a restricted study that is not in my permit/org, they are still able to check in.
          const errors = get(error.response.data, 'errors', []);
          if (errors.hasOwnProperty('PA_ORG_NOT_IN_STUDY') && Object.keys(errors).length == 1) {
            resolve();
            return;
          }
          reject();
        });
    });
  }

  showCardError (data) {
    const handleBadCardError = (data) => {
      const message = get(data, 'errors.BADCard.0', false);
      if (!message) {
        return false;
      }
      // This is intentional.  We are showing a friendly message to customers and pointing to KB.
      // This gives support easy reference to the underlying message, should they need it.
      console.warn(`Message Returned For Card: ${message}`); //eslint-disable-line
      this.setState({
        showCardError: true,
        cardErrorMessage: false, // Show default
      });
      return true;
    };

    /**
     * Patients may be in a study which requires they use a specific organization.  This is used if the current
     * organization is not part of that study.
     * @param data
     * @returns {boolean}
     */
    const handleRestrictedStudyError = (data) => {
      const message = get(data, 'errors.PA_ORG_NOT_IN_STUDY.0', false);
      if (!message) {
        return false;
      }
      const lines = message.split('\n').map((line, i) =>
        <p key={i} dangerouslySetInnerHTML={{ __html: line }} />
      );
      this.setState({
        showCardError: true,
        cardErrorMessage: lines
      });
      return true;
    };

    if(handleBadCardError(data)){
      return true;
    }
    return handleRestrictedStudyError(data);
  }

  hideCardError () {
    this.setState({showCardError: false});
  }

  onScanComplete(parsed) {
    if (parsed) {
      const { change } = this.props.actions;
      change(form, 'registry_id', parsed.registry_id.trim());
      change(form, 'card_number', parsed.card_id.trim());
      this.hideScanModal();
    }
  }

  showScanModal() {
    this.setState({ showScanModal: true });
  }

  hideScanModal() {
    this.setState({ showScanModal: false });
  }

  onShowUpdateModal() {
    this.setState({ showUpdateModal: true });
  }

  onHideUpdateModal() {
    this.setState({ showUpdateModal: false });
  }

  onDateChange(e) {
    if (typeof e !== 'object' || !e._isAMomentObject) e = undefined;
    this.setState({ expirationDate: e });
  }

  onUpdatePatient() {
    return new Promise((resolve, reject) => {
      const payload = this.state.patientUpdatePayload;
      this.props.actions
        .putItem(`/api/customers/${payload.id}/overwrite_mmid`, payload, 'noOp')
        .then(() => {
          this.setState({ patientUpdatePayload: null });
          this.props.actions.setItem(this.state.details, this.state.dataName).then(() => {
            if (this.state.license_type == PA_MED_CAREGIVER) {
              this.onSearch(this.state.searchFormData).then(() => {
                this.props.actions.addMessage(messageTypes.success, 'consumers.checkin.updatePatientProfileSuccess');
              });
            } else {
              this.loadCustomers().then(() => {
                this.props.actions.addMessage(messageTypes.success, 'consumers.checkin.updatePatientProfileSuccess');
              });
            }
          });
        })
        .catch(() => {
          this.props.actions.addMessage(messageTypes.error, 'consumers.checkin.updatePatientProfileFailure');
        });
      resolve();
    });
  }

  onRegister(patient) {
    const { caregiver, actions, paOraclePatientSyncFeature } = this.props;
    const routerCaregiver = {
      ...caregiver,
      state_integration_tracking_id: caregiver.caregiver_id,
      medical_id: caregiver.caregiver_card_number
    };

    const asyncOps = [
      this.props.actions.setItem(patient, itemNames.oraclePatient)
    ];

    if (paOraclePatientSyncFeature) {
      asyncOps.push(actions.postItem(
        '/api/leaf/oracle/full_patient_profile',
        {registry_id: patient.patient_id},
        itemNames.oraclePatientSupplementalInfo,
        {fail: 'customers.create.fullPatientProfile.fail'}
      ));
    }

    return Promise.all(asyncOps).then(() =>
      this.props.actions.push({
        pathname: '/patients/create',
        state: {
          caregiver: routerCaregiver
        }
      })
    );
  }

  validateSelectedPatientData(patient, callOnSuccess) {
    //lets make sure we have valid caregiver search details stored
    if (typeof this.state.details !== 'undefined' && this.state.details !== null) {
      let caregiverMatched = false;
      const patientCaregivers = patient.caregivers;

      const caregiverPayload = {
        state_integration_tracking_id: this.state.details.caregiver_id,
        medical_id: this.state.details.caregiver_card_number
      };

      for (let i = 0; i < patientCaregivers.length; i++) {
        const caregiver = patientCaregivers[i];
        if (
          this.state.details.caregiver_id === caregiver.state_integration_tracking_id ||
          this.state.details.caregiver_card_number === caregiver.medical_id
        ) {
          caregiverMatched = true;
          //We have at least a partial match now lets see if it is a full match or we need to perform an update
          if (
            !(
              this.state.details.caregiver_id === caregiver.state_integration_tracking_id &&
              this.state.details.caregiver_card_number === caregiver.medical_id
            )
          ) {
            //we need to perform a caregiver update
            caregiverPayload.id = caregiver.id;
            caregiverMatched = false;
            break;
          }
        }
      }

      if (!caregiverMatched) {
        const patientUpdatePayload = {
          id: patient.id,
          caregivers: [caregiverPayload]
        };

        const details = this.state.details;
        //we need to create a new caregiver record for this patient
        // Display modal with update confirmation
        this.setState({
          showUpdateModal: true,
          patientUpdatePayload,
          details,
          loading: false
        });
      } else {
        callOnSuccess(patient);
      }
    }
  }

  render() {
    const { license_type, showScanModal, loading, expanded } = this.state;
    const { patient, caregiver, budTenders, timezone, allowSalesAtWill } = this.props;
    const checkinHandlerOverride = this.state.patientUpdatePayload ? this.onShowUpdateModal : null;
    return (
      <div>
        <InProgressOverlay message='common.searching' isActive={loading} translate={true} />
        <h1>{I18n.t('consumers.checkin.checkIn')}</h1>
        <ScanInputModal
          showModal={showScanModal}
          scanType='barcode'
          onScanComplete={this.onScanComplete}
          hideModal={this.hideScanModal}
        />
        <ModalWrapper
          showModal={this.state.showUpdateModal}
          onHide={this.onHideUpdateModal}
          title={I18n.t('consumers.checkin.updatePatientProfile')}
          version={2}
          headerClass='bg-info-dark'
          widthClass='modal-sm'
          cancelButton={{ show: true, text: I18n.t('general.cancel') }}
          okayButton={{
            show: true,
            onClickHasPromise: true,
            onClick: this.onUpdatePatient,
            text: I18n.t('consumers.checkin.updatePatientProfile')
          }}
        >
          <div>{I18n.t('consumers.checkin.updatePatientProfileText')}</div>
        </ModalWrapper>
        <ModalWrapper
          showModal={this.state.showCardError}
          onHide={this.hideCardError}
          title={I18n.t('consumers.checkin.cardErrorTitle')}
          version={2}
          headerStyle={{fontWeight: 'bold'}}
          headerClass='bg-white'
          widthClass='modal-md'
          okayButton={{
            show: true,
            onClick: this.hideCardError,
            text: I18n.t('common.actions.close')
          }}
        >
          <div style={{fontSize: 'larger'}}>
            {
              this.state.cardErrorMessage
                ? this.state.cardErrorMessage
                : I18n.t('consumers.checkin.cardErrorReason')
            }
            <div style={{height:'12px'}} />
            {I18n.t('consumers.checkin.cardErrorKbPrefix')}
            <a href='https://mjplatform.com/patientcheckin' target='_blank'>
              {I18n.t('consumers.checkin.cardErrorKbLinkText')}
            </a> {I18n.t('consumers.checkin.cardErrorKbSuffix')}
          </div>
        </ModalWrapper>
        <CheckinLeafPAFormWrapper
          testOnFail={this.testOnFail}
          onSubmit={this.onSearch}
          showScanModal={this.showScanModal}
          form={form}
        />
        {license_type === PA_MED_PATIENT ? (
          <Row>
            <PatientDetailsLeafPA
              patient={patient}
              onRegister={this.onRegister}
              budTenders={budTenders}
              timezone={timezone}
              expanded={expanded}
              checkinHandlerOverride={checkinHandlerOverride}
              allowSalesAtWill={allowSalesAtWill}
              onDetailsExpand={this.onDetailsExpand}
            />
          </Row>
        ) : null}
        {license_type === PA_MED_CAREGIVER ? (
          <CaregiverDetailsLeafPA
            caregiver={caregiver}
            onRegister={this.onRegister}
            validateSelectedPatientData={this.validateSelectedPatientData}
            budTenders={budTenders}
            timezone={timezone}
            expanded={expanded}
            allowSalesAtWill={allowSalesAtWill}
            onDetailsExpand={this.onDetailsExpand}
          />
        ) : null}
      </div>
    );
  }
}

CheckinLeafPAPage.propTypes = {
  patient: PropTypes.object.isRequired,
  caregiver: PropTypes.object.isRequired,
  budTenders: PropTypes.array.isRequired,
  timezone: PropTypes.string,
  actions: PropTypes.shape({
    postItem: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    setItem: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired,
    setData: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    addMessage: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    getSearchData: PropTypes.func.isRequired,
    getDataByPost: PropTypes.func.isRequired
  }).isRequired
};

function mapStateToProps(state) {
  const budTendersObject = state[itemNames.budTendersObject];
  const budTenders = Object.keys(budTendersObject).map((id) => ({ id, name: budTendersObject[id] }));
  return {
    patient: getOraclePatient(state),
    caregiver: getOracleCaregiver(state),
    timezone: state.timezone,
    dataUpdateAvailable: [getDataUpdateAvailable(state, { name: dataNames.customers, core: 'customers' })],
    budTenders,
    allowSalesAtWill: parseInt(
      getAt(state[itemNames.salesComplianceSettings], 'order_allow_leafPA_sales_at_will.value', 0)
    ),
    notifications: state[dataNames.notifications],
    user: state[itemNames.currentFacilityUser],
    facility: state[itemNames.facility],
    paOraclePatientSyncFeature: isFeatureEnabled(state)('feature_pa_oracle_patient_sync')
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    push,
    setItem,
    unsetItem,
    setData,
    postItem,
    getItem,
    putItem,
    change,
    addMessage,
    getUnpaginatedData,
    getSearchData,
    getDataByPost,
    getConsumers,
  };
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckinLeafPAPage);
