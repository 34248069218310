import React from 'react';
import { connect } from 'react-redux';
import { Col, Row, Table, Button } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import { push } from 'react-router-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faPlus, faSyncAlt } from '@fortawesome/free-solid-svg-icons';

import { AuthRequest } from './../../../managers/request';
import Loading from '../../common/loading';
import Status from '../../common/status';

class MerchantTerminalsList extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: false,
      terminals: [],
      pagination: {
        current_page: 1,
        from: null,
        last_page: 1,
        per_page: this.props.per_page || 1,
        to: null,
        total: 0
      }
    };
    this.load = this.load.bind(this);
  }

  componentDidMount() {
    this.load();
  }

  addTerminal() {
    const { profile } = this.props;
    let path = '/setup/merchant-terminal/create';
    if (profile) {
      path += '?merchant_profile_id=' + profile.id;
    }
    this.props.push(path);
  }

  editTerminal(terminal) {
    this.props.push('/setup/merchant-terminal/modify/' + terminal.id);
  }

  load() {
    const { profile, per_page } = this.props;
    const { pagination } = this.state;
    const params = Object.assign({}, { per_page: per_page });

    if (profile) {
      params['merchant_profile_id'] = profile.id;
    }

    this.setState({
      loading: true
    });

    this.search(params).then((body) => {
      this.setState({
        loading: false,
        terminals: body.data,
        pagination: Object.assign(pagination, {
          current_page: body.current_page,
          total: body.total,
          to: body.to,
          from: body.from
        })
      });
    });
  }

  search(params) {
    const request = AuthRequest.create();
    return request.get('/api/payment/merchant_terminals/search', {
      params: params
    })
      .then((response) => {
        return response.data || null;
      });
  }

  render() {
    const { terminals, loading, pagination } = this.state;

    return (
      <div>
        <div className='margin-bottom-md'>
          <Button onClick={this.addTerminal.bind(this)} className='margin-bottom-sm' size='sm' variant='primary'><FontAwesomeIcon icon={faPlus} /> {I18n.t('common.actions.add')}</Button>
        </div>
        <Table responsive>
          <thead>
            <tr>
              <th><Button onClick={this.load} className='rounded' size='sm' variant='primary'><FontAwesomeIcon icon={faSyncAlt} /></Button></th>
              <th>{I18n.t('common.form.name')}</th>
              <th>{I18n.t('facility.setup.merchantTerminal.form.reference')}</th>
              <th>{I18n.t('common.status')}</th>
            </tr>
          </thead>
          <tbody>
            {loading &&
              <tr>
                <td colSpan='4'><Loading>{I18n.t('general.loading')}</Loading></td>
              </tr>
            }
            {(terminals.length > 0) && !loading &&
              terminals.map((item, i) => {
                return (<tr key={item.id}>
                  <td>
                    <Button onClick={() => { this.editTerminal(item); }} className='rounded' variant='primary' size='sm'><FontAwesomeIcon icon={faPencilAlt} /></Button>
                  </td>
                  <td>
                    {item.name}
                  </td>
                  <td>
                    {item.reference}
                  </td>
                  <td>
                    <Status status={item.status} />
                  </td>
                </tr>);
              })
            }
          </tbody>
        </Table>
        <Row>
          {pagination.total > 0 &&
            <Col md={6}>
              {pagination.current_page > 1 &&
                <Button variant='primary' onClick={(e) => this.changePage(pagination.current_page - 1)}>Prev</Button>
              }
              &nbsp;
              <Button disabled={true}>Viewing {pagination.from}-{pagination.to} of {pagination.total}</Button>
              &nbsp;
              {pagination.last_page && pagination.current_page < pagination.last_page &&
                <Button variant='primary' onClick={(e) => this.changePage(pagination.current_page + 1)}>Next</Button>
              }
            </Col>
          }
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = ({
  push
});

export default connect(mapStateToProps, mapDispatchToProps)(MerchantTerminalsList);
