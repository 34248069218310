import get from 'lodash.get';
import {pick} from 'lodash';
import {createSelector} from 'reselect';
import {distributionSettings} from '../../constants/itemNames';

const getHomeDeliverySettings = state => get(state, `${distributionSettings}.distributions_home_delivery.value`);

export const getEnableManifestGeneration = createSelector(
  [getHomeDeliverySettings],
  homeDeliverySettings => get(homeDeliverySettings, 'enable_manifest_generation') == true
);

export const getEnableDeliveryWorkflow = createSelector(
  [getHomeDeliverySettings],
  homeDeliverySettings => get(homeDeliverySettings, 'enable_workflow') == true
);

export const getInitialValues = createSelector([getHomeDeliverySettings], settings => {
  return {
    ...settings,
  };
});

export const getPayload = (formData, facility) => {
  const value = {
    ...pick(formData, [
      'enable_workflow',
    ]),
    enable_manifest_generation: '0',
  };

  const payload = {
    settings: [
      {
        value,
        scope: 'facility',
        scope_id: facility.id,
        setting_key: 'distributions_home_delivery',
      }
    ]
  };

  return payload;
};