import React from 'react';
import PropTypes from 'prop-types';
import RefundItem from './RefundItem';

const RefundItems = (props) => {

  const productId = 'refunded_order_product_id';
  const inventoryId = 'refunded_order_product_inventory_id';

  const combineRefundsOnSameItem = () => {

    const iterateProducts = props.refundOrder.products.map( (product) => { return product; });

    const products = iterateProducts.reduce( (acc, product) => {

      const productAndItem = acc.find( (p) => {
        return p[productId] === product[productId] && p[inventoryId] === product[inventoryId];
      });

      if(productAndItem){
        productAndItem.quantity += product.quantity;
      } else {
        const p = Object.assign({}, product, {mutate: true});
        acc.push(p);
      }

      return acc;

    }, []);

    const o = Object.assign({}, props.refundOrder, {});
    return Object.assign({}, o, {products});

  };

  const deleteRefundItem = (item) => {

    const firstItem = props.refundOrder.products.find( (product) => {
      return item[productId] === product[productId] && item[inventoryId] === product[inventoryId];
    });

    if(!firstItem) return alert('Hey, I no findie!');

    props.onDeleteRefund(firstItem);

  };

  const getRefundOrder = () => {

    if(Object.keys(props.refundOrder.length > 0)){
      if(props.refundOrder.products !== undefined){
        if(props.refundOrder.products.length > 0){
          return combineRefundsOnSameItem();
        }
      }
    }
    return false;
  };

  const refundOrder = getRefundOrder();

  return (
    <tbody>
    {
      !refundOrder
        ? (<tr><td colSpan={4} style={{textAlign: 'center'}}>No Active Refund</td></tr>)
        : refundOrder.products.map( (product, index) => {
          return (<RefundItem deleteRefundItem={deleteRefundItem} key={index} data={product} />);
        })
    }
    </tbody>
  );

};

RefundItems.propTypes = {
  onDeleteRefund: PropTypes.func.isRequired,
  refundOrder: PropTypes.object.isRequired
};

export default RefundItems;
