import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { I18n } from 'react-redux-i18n';
import {formValueSelector, reset, change, touch} from 'redux-form';
import { Row, Col, Alert } from 'react-bootstrap';
import get from 'lodash.get';
import * as apiActions from '../../actions/apiActions';
import {setData, unsetData} from '../../actions/dataActions';
import {setItem, unsetItem} from '../../actions/itemActions';
import * as permissions from '../../constants/permissions';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import {INVENTORY_SYNC_PREPACK_MODAL, INVENTORY_SYNC_SEARCH_FORM} from '../../constants/forms';
import PageTitle from '../common/PageTitle';
import FormWrapper from '../common/form/FormWrapper';
import TablePageWrapper from '../common/grid/TablePageWrapper';
import InventorySyncSearchForm from './InventorySyncSearchForm';
import {getCurrentUser, userHasPermission} from '../../selectors/usersSelectors';
import InventorySyncIntegratorModal from './InventorySyncIntegratorModal';
import InventorySyncMjpModal from './InventorySyncMjpModal';
import InventorySyncPrepackModal from './InventorySyncPrepackModal';
import {handleComplexSelectRow} from '../../actions/helpers/selectedDataHelper';
import {getSelectedCategoriesIds} from '../../selectors/inventorySyncSelectors';
import { getIntegrationAdjustmentReasonOptions } from '../../selectors/integration/adjustmentReasonsSelectors';
import InventorySyncPackageResult from './InventorySyncPackageResult';
import InventorySyncAdjustmentsReport from './InventorySyncAdjustmentsReport';
import WillRender from '../common/concealers/WillRender';
import {getMetrcSettings} from '../../selectors/integration/metrcSelectors';
import {getBiotrackSettings} from '../../selectors/integration/biotrackSelectors';
import {getIntegrationState} from '../../selectors/integration/integrationSelectors';

export class InventorySyncPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      saving: false,
      ready: false,
      page: 0,
      showIntegratorModal: false,
      showMjpModal: false,
      showPrepackModal: false,
      prepackModalInitialValues: {},
      payload: {},
      showConfirmModal: false,
      selectedItem: {},
      packageAdjustments: [], // for adjustments reporting
      errorType: undefined, // error type can be stateTrackingFailed, invalidAdjustmentReason, adjustmentReasonsNotFound
    };

    this.submitSearch = this.submitSearch.bind(this);
    this.adjustIntegratorLevel = this.adjustIntegratorLevel.bind(this);
    this.adjustMjpLevel = this.adjustMjpLevel.bind(this);
    this.adjustPrepack = this.adjustPrepack.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleItemSelect = this.handleItemSelect.bind(this);
    this.handlePrepackItemSelect = this.handlePrepackItemSelect.bind(this);
    this.ref = React.createRef();
  }

  componentDidMount() {
    this.props.actions.getUnpaginatedData('/api/categories', dataNames.categories, { failed: 'categories.get.failed' });
    this.props.actions.getUnpaginatedData('/api/locations/child_locations', dataNames.sections);
    this.props.actions.getUnpaginatedData('/api/integration/adjustment_reasons', dataNames.integrationAdjustmentReasons, { failed: 'packages.modify.getIntegrationAdjustmentReasons.failed' });
    this.props.actions.getUnpaginatedData('/api/adjustment_reasons', dataNames.adjustmentReasons, { failed: 'adjustmentReasons.get.failed' });
  }

  clearSearch() {
    this.props.actions.reset(INVENTORY_SYNC_SEARCH_FORM);
    this.props.actions.unsetData(dataNames.inventorySyncDiscrepantPackages);
    this.props.actions.unsetItem('inventorySyncDataStatus');
    this.setState({ packageAdjustments: [] });
  }

  submitSearch(formValues) {
    this.props.actions.unsetData(dataNames.inventorySyncDiscrepantPackages);
    this.props.actions.setItem({status: I18n.t('inventory.sync.status.searching')}, itemNames.inventorySyncDataStatus);
    const payload = {
      category_ids: formValues.category_ids.map((cat) => cat.value),
      location_ids: formValues.locations.map((loc) => loc.value),
      // For larger data sets data collection needs to be done as a background job
      // This depends on pub/sub via the gateway using the publish_to_client helper
      // This doesn't work on local. To bypass this, add '?test' to the url before triggering the search.
      // This will skip the background job
      test: this.props.location.search === '?test'
    };
    const url = this.props.integrationState.isBiotrack ? '/api/biotrack/inventory_sync/discrepant_packages' : '/api/metrc/inventory_sync/discrepant_packages';
    this.props.actions.postData(url, payload, null, {failed: 'inventory.sync.search.failed'})
      .then((result) => {
        // If the result contains the data (i.e. ?test request param) set it, so we can use it
        if (result.hasOwnProperty('discrepantPackages')) {
          this.props.actions.setData(result, dataNames.inventorySyncDiscrepantPackages);
          this.props.actions.setItem({status: I18n.t('inventory.sync.status.dataCollectionReady')}, itemNames.inventorySyncDataStatus);
          return;
        }
        this.props.actions.setItem(
          {
            status: get(result, 'jobStatus', I18n.t('inventory.sync.status.dataCollectionInProgress')),
            message: get(result, 'message', '')
          }, itemNames.inventorySyncDataStatus);
      })
      .catch((error) => {
        this.props.actions.setItem({status: I18n.t('inventory.sync.status.failed')}, itemNames.inventorySyncDataStatus);
      });
  }

  adjustIntegratorLevel(formValues) {
    // Handle Integrator level adjustment
    const { integrator_tag, package_id, qty, uom } = this.state.selectedItem;
    const { reason, notes } = formValues;
    const payload = {
      integrator_tag: integrator_tag,
      qty: qty,
      uom: uom,
      reason: reason,
      notes: notes
    };
    const adjustment =  {integrator_id: integrator_tag, target: 'integrator', package_id: package_id, value: qty, adjustment_reason: reason, adjustment_notes: notes};

    // Adjust Integrator level
    const url = this.props.integrationState.isBiotrack ? '/api/biotrack/adjust_package' : '/api/metrc/adjust_package';
    this.props.actions.postItem(
      url,
      payload,
      itemNames.metrcReconciliation,
      {success: 'reconciliation.adjust.success', failed: 'reconciliation.adjust.failed'},
      {}, () => {
        this.state.packageAdjustments.push(adjustment);
        this.setState({
          selectedItem: {},
          showIntegratorModal: false
        });
      }
    ).catch(() => this.setState({saving: false}));
  }

  adjustMjpLevel(formValues){
    // Handle MJP level adjustment
    const { integrator_tag, package_id, item_id, quantity, transacted_qty } = this.state.selectedItem;
    const { adjustment_reason, notes } = formValues;
    const final_qty = quantity + transacted_qty;
    const payload = {
      item_id: item_id,
      transacted_qty: transacted_qty,
      over_qty: final_qty,
      quantity: final_qty,
      adjustment_reason: adjustment_reason,
      notes: notes
    };
    const adjustment =  {integrator_id: integrator_tag, target: 'mjp', package_id: package_id, value: transacted_qty, adjustment_reason: adjustment_reason, adjustment_notes: notes};
    this.props.actions.postItem(
      '/api/reconciliations/adjust',
      {adjustments: [payload]},
      itemNames.reconciliation,
      { success: 'reconciliation.adjust.success', failed: 'reconciliation.adjust.failed' },
      {},
      () => {
        this.state.packageAdjustments.push(adjustment);
        this.setState({
          selectedItem: {},
          showMjpModal: false
        });
      }
    ).catch(() => this.setState({ saving: false }));
  }

  adjustPrepack(formValues){
    // Handle MJP level adjustment for prepack packages
    const { integrator_id, mjpPackages } = this.state.selectedItem;
    const { notes } = formValues;

    // transform for adjust payload
    const payloads = mjpPackages.map((pkg, index) => ({
      item_id: get(pkg, 'item_id'),
      transacted_qty: Number(get(formValues, `adjustment_qty.${index}`, 0)),
      over_qty: Number(pkg['quantity']) + Number(get(formValues, `adjustment_qty.${index}`, 0)),
      quantity: Number(pkg['quantity']) + Number(get(formValues, `adjustment_qty.${index}`, 0)),
      adjustment_reason: get(formValues, `adjustment_reason.${index}`, ''),
      notes: notes
    }));

    // transform for csv or excel download
    const prepackPackageAdjustments = mjpPackages.map((pkg, index) => ({
      id: integrator_id,
      target: 'mjp',
      package_id: get(pkg, 'package_id'),
      value: Number(get(formValues, `adjustment_qty.${index}`, 0)),
      adjustment_reason: get(formValues, `adjustment_reason.${index}`, ''),
      adjustment_notes: notes
    }));

    this.props.actions.postItem(
      '/api/reconciliations/adjust',
      {adjustments: payloads},
      itemNames.reconciliation,
      { success: 'reconciliation.adjust.success', failed: 'reconciliation.adjust.failed' },
      {},
      () => {
        const updatedPackageAdjustments = this.state.packageAdjustments.concat(prepackPackageAdjustments);
        this.setState({
          packageAdjustments: updatedPackageAdjustments,
          selectedItem: {},
          showMjpModal: false
        });
      }
    ).catch(() => this.setState({ saving: false }));
  }

  handleItemSelect(discrepantPackage, target) {
    let selectedItem = {};
    if (target === 'metrc' || target === 'biotrack') {
      selectedItem = {
        integrator_tag: get(discrepantPackage, 'integrator_id'),
        package_id: get(discrepantPackage, 'mjpPackages[0].package_id'),
        qty: -get(discrepantPackage, 'discrepancy'),
        uom: get(discrepantPackage, 'integrator_uom'),
        reason: '',
        notes: ''
      };
    } else if (target === 'mjp') {
      selectedItem = {
        integrator_tag: get(discrepantPackage, 'integrator_id'),
        package_id: get(discrepantPackage, 'mjpPackages[0].package_id'),
        item_id: get(discrepantPackage, 'mjpPackages[0].item_id'),
        transacted_qty: get(discrepantPackage, 'discrepancy'),
        quantity: get(discrepantPackage, 'mjpPackages[0].quantity'),
        uom: get(discrepantPackage, 'mjpPackages[0].uom'),
        adjustment_reason: '',
        notes: ''
      };
    }
    this.setState({
      selectedItem,
      showIntegratorModal: target === 'metrc' || target === 'biotrack',
      showMjpModal: target === 'mjp'
    });
  }

  handlePrepackItemSelect(item) {
    this.setState({
      selectedItem: item,
      showPrepackModal: true
    });
  }

  handleSelect(isSelected, rows) {
    this.props.actions.handleComplexSelectRow(rows, dataNames.selectedCategories, isSelected);
  }

  render() {
    const { selectedCategories, hasSyncInventoryPermission, inventorySyncDiscrepantPackages, inventorySyncDataStatus,
      adjustmentReasons, integrationAdjustmentReasons, getPrepackFormValue, user, integrationState, metrcSettings, biotrackSettings } = this.props;
    const { showIntegratorModal, showMjpModal, showPrepackModal, selectedItem, packageAdjustments, errorType } = this.state;

    const { isBiotrack, isMetrc } = integrationState;
    const integrator = isBiotrack ? 'Biotrack' : isMetrc ? 'Metrc' : 'unknown';

    const discrepantPackages = get(inventorySyncDiscrepantPackages, 'discrepantPackages');

    const summary = get(inventorySyncDiscrepantPackages, 'summary');

    const columns = [
      {
        name: 'id',
        dataId: 'category_id',
        hidden: true
      },
      {
        name: 'inventory.sync.category',
        dataId: 'category',
        width: '25%',
        dataSort: 'false'
      },
      {
        name: 'inventory.sync.packageWithDiscrepancies',
        dataId: 'discrepant_packages_ratio',
        width: '25%',
        formatter: (cell, row) => {
          const discrepant_packages = row.discrepant_packages;
          const total_packages = row.total_packages;
          const discrepantValueColor = (discrepant_packages === 0) ? 'green' : 'red';
          return (
            <span>
              <span style={{color: discrepantValueColor}}>{discrepant_packages} </span>
              /
              <span> {total_packages}</span>
            </span>
          );
        }
      },
      {
        name: 'inventory.sync.totalDiscrepancyEach',
        dataId: 'total_discrepancy_each',
        width: '25%'
      },
      {
        name: 'inventory.sync.totalDiscrepancyGrams',
        dataId: 'total_discrepancy_grams',
        width: '25%'
      }
    ];

    return (
        <div>
          {!hasSyncInventoryPermission ? (
            <React.Fragment>
              <PageTitle primaryText={I18n.t('inventory.sync.title')} />
              <Alert variant='warning'>
                {I18n.t('inventory.sync.error.noPermission')}
              </Alert>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Row>
                <Col md={6}>
                    <PageTitle primaryText={I18n.t('inventory.sync.title')} />
                    <FormWrapper>
                      <InventorySyncSearchForm
                        initialValues={undefined}
                        submitSearch={this.submitSearch}
                        clearSearch={this.clearSearch}
                      />
                    </FormWrapper>
                </Col>
                <Col md={6}>
                  <div className='retail-group-listing'>
                    <PageTitle primaryText={I18n.t('inventory.sync.searchResults')} />
                    {summary ?
                    <TablePageWrapper
                      ref={this.ref}
                      settingKey='inventory-sync'
                      columns={columns}
                      data={summary}
                      selectedRows={selectedCategories}
                      handleSelect={this.handleSelect}
                      hideExport={true}
                      hideScanSearch={true}
                      hideSearch={true}
                      className='retail-group-table'
                    /> :
                      <div>
                        <h4>{get(inventorySyncDataStatus, 'status', I18n.t('inventory.sync.status.noResults'))}</h4>
                        {get(inventorySyncDataStatus, 'message', '')}
                      </div>}
                  </div>
                </Col>
              </Row>
              <WillRender ifTrue={summary}>
                <span>{I18n.t('inventory.sync.search.helper')}</span>
                <InventorySyncPackageResult
                  discrepantPackages={discrepantPackages}
                  handleItemSelect={this.handleItemSelect}
                  handlePrepackItemSelect={this.handlePrepackItemSelect}
                  selectedCategories={selectedCategories}
                  adjustedItems={this.state.packageAdjustments}
                  integrationState={integrationState}
                  integrator={integrator}
                />
                <WillRender ifTrue={packageAdjustments && packageAdjustments.length > 0}>
                  <div style={{ position: 'relative', float: 'right' }}>
                    <InventorySyncAdjustmentsReport
                      discrepantPackages={discrepantPackages}
                      packageAdjustments={packageAdjustments}
                      integrationState={integrationState}
                      metrcSettings={metrcSettings}
                      biotrackSettings={biotrackSettings}
                      user={user}
                    />
                  </div>
                </WillRender>
              </WillRender>
              <div>
                <InventorySyncIntegratorModal
                  showModal={showIntegratorModal}
                  hideModal={() => this.setState({showIntegratorModal: false, selectedItem: {}, errorType: undefined })}
                  integrationAdjustmentReasons={integrationAdjustmentReasons}
                  integator={integrator}
                  adjustIntegratorLevel={this.adjustIntegratorLevel}
                  initialValues={selectedItem}
                  enableReinitialize={true}
                  errorType={errorType}
                />
                <InventorySyncMjpModal
                  showModal={showMjpModal}
                  hideModal={() => this.setState({showMjpModal: false, selectedItem: {}, errorType: undefined })}
                  integrator={integrator}
                  adjustmentReasons={adjustmentReasons}
                  adjustMjpLevel={this.adjustMjpLevel}
                  initialValues={selectedItem}
                  enableReinitialize={true}
                  errorType={errorType}
                />
                <InventorySyncPrepackModal
                  showModal={showPrepackModal}
                  hideModal={() => this.setState({showPrepackModal: false, selectedItem: {}, prepackModalInitialValues: {}})}
                  adjustPrepack={this.adjustPrepack}
                  integrator={integrator}
                  adjustmentReasons={adjustmentReasons}
                  item={selectedItem}
                  getFormValue={getPrepackFormValue}
                  enableReinitialize={true}
                  change={this.props.actions.change}
                  touch={this.props.actions.touch}
                />
              </div>
            </React.Fragment>
          )}
        </div>
    );
  }
}

InventorySyncPage.propTypes = {
  actions: PropTypes.shape({
    getUnpaginatedData: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    handleComplexSelectRow: PropTypes.func.isRequired,
    //clearSelectedData: PropTypes.func.isRequired,
    setData: PropTypes.func.isRequired,
    setItem: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    touch: PropTypes.func.isRequired,
  }),
  selectedCategories: PropTypes.array.isRequired,
  hasSyncInventoryPermission: PropTypes.bool.isRequired,
  inventorySyncDiscrepantPackages: PropTypes.array.isRequired,
  inventorySyncDataStatus: PropTypes.object,
  adjustmentReasons: PropTypes.array.isRequired,
  integrationAdjustmentReasons: PropTypes.array.isRequired,
  getPrepackFormValue: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  integrationState: PropTypes.object.isRequired,
  metrcSettings: PropTypes.object.isRequired,
  biotrackSettings: PropTypes.object.isRequired,
  location: PropTypes.object,
};

const prepackSelector = formValueSelector(INVENTORY_SYNC_PREPACK_MODAL);

function mapStateToProps(state) {
  return {
    selectedCategories: getSelectedCategoriesIds(state),
    inventoryCategories: state[dataNames.categories] ? state[dataNames.categories] : [],
    inventorySubcategories: state[dataNames.subcategories] ? state[dataNames.subcategories] : [],
    inventoryLocations: state[dataNames.inventoryLocations] ? state[dataNames.inventoryLocations] : [],
    inventoryVendors: state[dataNames.vendorItemMasters] ? state[dataNames.vendorItemMasters] : [],
    hasSyncInventoryPermission: userHasPermission(state, {permissions: [permissions.manage_state_integrations]}),
    inventorySyncDiscrepantPackages: state[dataNames.inventorySyncDiscrepantPackages],
    inventorySyncDataStatus: state[itemNames.inventorySyncDataStatus] ? state[itemNames.inventorySyncDataStatus] : {},
    integrationAdjustmentReasons: getIntegrationAdjustmentReasonOptions(state),
    adjustmentReasons: state[dataNames.adjustmentReasons],
    getPrepackFormValue: (name) => prepackSelector(state, name),
    user: getCurrentUser(state),
    integrationState: getIntegrationState(state),
    metrcSettings: getMetrcSettings(state),
    biotrackSettings: getBiotrackSettings(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...apiActions,
    handleComplexSelectRow,
    setData,
    setItem,
    unsetData,
    unsetItem,
    reset,
    change,
    touch
  };
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InventorySyncPage);
