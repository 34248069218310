/* eslint-disable import/no-mutable-exports*/
import React from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {connect} from 'react-redux';
import { reduxForm, FieldArray, Field } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { FormGroup , Col, Row } from 'react-bootstrap';
import {FaExclamationTriangle} from 'react-icons/fa';
import DriverAndVehicleInformation from '../common/DriverAndVehicleInformation';// eslint-disable-line
import SalesOrders from '../create/SalesOrders';
import TransferDestinations from '../common/TransferDestinations'; // eslint-disable-line import/no-named-as-default
import LineItems from '../create/LineItems'; // eslint-disable-line import/no-named-as-default
import OrderTotals from '../create/OrderTotals';
import SubmitSection from '../../common/form/SubmitSection';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import InlineCheckBox from '../../common/form/InlineCheckBox';
import PackageScanInput from '../common/PackageScanInput';
import ReturnedItems from './ReturnedItems';
import { validate } from '../create/validate';
import TextAreaInput from '../../common/form/TextAreaInput';
import ModalWrapper from '../../common/ModalWrapper';
import {isFeatureEnabled} from '../../../selectors/featureToggles';

const disposalReasonOptions = [
  {text: I18n.t('orderDisposalReasons.contaminated'), value: 'contaminated'},
  {text: I18n.t('orderDisposalReasons.damaged'), value: 'damaged'},
  {text: I18n.t('orderDisposalReasons.defective'), value: 'defective'},
  {text: I18n.t('orderDisposalReasons.expired'), value: 'expired'},
];

export const TransferForm = (props) => {
  const {handleSubmit, onDontRestock, onCreateMetrcTemplate, onPrintLabels,  isReceiptForm, reset, sentTotals, lineTotals,
    change, invalid, formData: {drivers, initValues, vehicles, orders, locations, prepackWeights, containerType, inventoryItems, lines, childItemMasters}, formData, isComplete,
    prepackExpendedInventory, expendedInventory, onCancel, integrationState, submitting, transferId, createDriver,
    isDriverCompany, isWasteDisposal, isReceivedTransfer, transferStatuses, isOutForDelivery, formName, isMedicated,
    completingTransfer, handleRestockAndCompleteTransfer, metrcIsImported, sentNetTotals, integrationTransferTypes,
    currentTransferStatus, moveAllInventoryChecked, showMoveAllInventoryFeature} = props;

  const {isBiotrack, isMetrc, isMetrcTransfersEnabled} = integrationState;
  const isCancelled = initValues && initValues.status === 'cancelled';
  const shouldShowReturnedItems = initValues && initValues.returnedItems && initValues.returnedItems.length && completingTransfer && isBiotrack;
  const shouldShowSaveAndSubmitMetrcTemplate = isMetrcTransfersEnabled && !metrcIsImported && !isCancelled && !isComplete;

  const renderConfirmModal = () => { //eslint-disable-line
    const { showNegativeInvConfirmation } = props;

    const okayButtonProps = {
      show: true,
      onClick: showNegativeInvConfirmation.onConfirm,
      text: I18n.t('general.yes')
    };

    const cancelButtonProps = {
      show: true,
      onClick: showNegativeInvConfirmation.onHide,
      text: I18n.t('general.no')
    };

    return (
      <ModalWrapper
        Component={false}
        title={I18n.t('inventory.negativeInventoryAlert')}
        headerClass='bg-info-dark'
        onHide={showNegativeInvConfirmation.onHide}
        showModal={showNegativeInvConfirmation.show}
        okayButton={okayButtonProps}
        cancelButton={cancelButtonProps}
        dialogClassName='modal-sm'
        version={2}
      >
        <p>{I18n.t('inventory.confirmToGoNegative')}</p>
      </ModalWrapper>
    );
  };

  const submitSectionSettings = {
    actionSettings: {
      cancel: {
        text: I18n.t('salesOrders.form.cancelTransfer'),
        action: onCancel,
        type: 'button',
        style: 'danger',
        submitting,
      },
      reset: {
        text: I18n.t('common.form.reset'),
        action: reset,
        style: 'default'
      },
      print: {
        text: I18n.t('common.form.printLabels'),
        type: 'button',
        style: 'info',
        action: () => {
          onPrintLabels();
        }
      }
    },
    align: 'right'
  };

  if (shouldShowReturnedItems) {
    submitSectionSettings.actionSettings.returns = {
      text: I18n.t('common.form.restockAndCompleteTransfer'),
      type: 'button',
      style: 'primary',
      action: () => {
        handleRestockAndCompleteTransfer();
      }
    };

    submitSectionSettings.actionSettings.submit = {
      text: I18n.t('common.form.dontRestock'),
      type: 'button',
      style: 'primary',
      action: () => {
        onDontRestock();
      },
      invalid: invalid && !isCancelled
    };
  } else {
    submitSectionSettings.actionSettings.submit = {
      text: shouldShowSaveAndSubmitMetrcTemplate ? I18n.t('salesOrders.form.saveAndCreateMetrcTemplate') : I18n.t('common.form.save'),
      type: 'button',
      style: 'primary',
      action: () => {
        handleSubmit();
      },
      invalid: invalid && !isCancelled,
    };
  }

  const completedTransferSubmitSectionSettings = {
    actionSettings: {
      //
    }
  };

  if (isMetrcTransfersEnabled && !metrcIsImported && isComplete) {
    completedTransferSubmitSectionSettings.actionSettings.metrc_template = {
      text: I18n.t('salesOrders.form.resubmitTemplate'),
      type: 'button',
      style: 'primary',
      action: () => {
        onCreateMetrcTemplate();
      }
    };
  }

  if (isCancelled) {
    delete(submitSectionSettings.actionSettings.cancel);
  }

  const atLeastOnePartnerIsNotLab = orders.some(order => order.order_type !== 'lab');

  return (
    <form className='transfer-form' onSubmit={handleSubmit}>
      <DriverAndVehicleInformation
        drivers={drivers}
        vehicles={vehicles}
        change={change}
        isReadOnly={isCancelled || isReceivedTransfer || metrcIsImported || (isMetrcTransfersEnabled && isComplete)}
        createDriver={createDriver}
        isDriverCompany={isDriverCompany}
        integrationState={integrationState}
      />
      {!isReceiptForm ? <FieldArray name='sales_orders' component={SalesOrders} orders={orders}/> : ''}
      {!isReceiptForm ?
        <FormGroup className='transfer-destinations'>
          <FieldArray
            name='destinations'
            component={TransferDestinations}
            formName={formName}
            isReadOnly={(isCancelled || isReceivedTransfer || metrcIsImported || (isMetrcTransfersEnabled && isComplete)) ? true : false}
          />
          {isMetrc && isMetrcTransfersEnabled &&
            <Row>
              <Col xs={12} sm={2}>
                <Field
                  name='integration_transfer_type'
                  component={ReactSelectInput}
                  disabled = {isComplete ? true : false}
                  props={{
                    label: I18n.t('cultivation.transfers.form.integrationTransferType', {integration: I18n.t('metrc.form.name')}),
                    options: integrationTransferTypes.map( (item) => { return { value: item.name, text: item.name }; })
                  }}
                />
              </Col>
            </Row>
          }
        </FormGroup> : ''
      }

      <FormGroup className='transfer-packages'>
        <Row>
          <Col md={8}>
            <h3>{I18n.t('cultivation.transfers.form.transferPackages')}</h3>
          </Col>
          <Col md={4}>
            <PackageScanInput
              formName={formName}
            />
          </Col>
        </Row>
        {isBiotrack && isMedicated ?
          <Row>
            <Col xs={12} style={{margin: '0px 0px 12px 0px'}} className='text-info'>
              <FaExclamationTriangle style={{marginRight: '8px'}} />
              {I18n.t('cultivation.supplyChain.wholeLotMustBeTransfered')}
            </Col>
            <Col xs={12} style={{margin: '0px 0px 12px 0px'}} className='text-danger'>
              <FaExclamationTriangle style={{marginRight: '8px'}} />
              {I18n.t('cultivation.supplyChain.cancelTranferBioTrackPackage')}
            </Col>
          </Row>
          : null}
        <div style={{height: '12px'}} />
        <FieldArray
          name='lines'
          component={LineItems}
          lines={lines}
          locations={locations}
          packages={inventoryItems}
          change={change}
          formData={formData}
          isReceiptForm={isReceiptForm}
          containerType={containerType}
          prepackWeights={prepackWeights}
          childItemMasters={childItemMasters}
          sentTotals={sentTotals}
          lineTotals={lineTotals}
          isEditMode={true}
          isMedicated={isMedicated}
          prepackExpendedInventory={prepackExpendedInventory}
          expendedInventory={expendedInventory}
          isComplete={isComplete || isReceivedTransfer || isOutForDelivery || metrcIsImported}
          isCancelled={isCancelled}
          integrationState={integrationState}
          transferId={transferId}
          disposalReasonOptions={disposalReasonOptions}
          formName={formName}
          sentNetTotals={sentNetTotals}
        />
      </FormGroup>

      <OrderTotals
        isReceiptForm={isReceiptForm}
        isReadOnly={isCancelled || isReceivedTransfer || metrcIsImported || (isMetrcTransfersEnabled && isComplete)}
        integrationState={integrationState}
        atLeastOnePartnerIsNotLab={atLeastOnePartnerIsNotLab}
      />
      {/*<FormGroup>
        <h3>{I18n.t('cultivation.transfers.form.documentManagement')}</h3>

        <UploadFile fieldName='invoice_file' fieldLabel='cultivation.transfers.form.uploadInvoice'/>
      </FormGroup>*/}

      {
        showMoveAllInventoryFeature &&
        <FormGroup className='storage-location'>
          <h3>{I18n.t('cultivation.transfers.form.storageLocation')}</h3>
          <Row>
            <Col xs={6} md={3}>
              <Field
                name='move_all_inventory'
                component={InlineCheckBox}
                props={{
                  label: I18n.t('cultivation.transfers.form.applyStorageLocation'),
                  className: 'form-inline',
                  disabled: (currentTransferStatus !== 'open') || isCancelled || isReceivedTransfer || isOutForDelivery || (isMetrcTransfersEnabled && isComplete),
                }}
              />
              <div>{I18n.t('cultivation.transfers.form.applyStorageLocationHint')}</div>
            </Col>
            <Col xs={2} sm={3}>
              <Field
                name='new_location_id'
                component={ReactSelectInput}
                props={{
                  label: I18n.t('cultivation.transfers.form.storageLocation'),
                  options: locations,
                  disabled: isCancelled || isReceivedTransfer || isOutForDelivery || metrcIsImported || (isMetrcTransfersEnabled && isComplete),
                }}
              />
            </Col>
          </Row>
        </FormGroup>
      }

      <FormGroup className='storage-location'>
        <h3>{I18n.t('cultivation.transfers.form.notes')}</h3>
        <Row>
          <Col xs={12} md={6}>
            <Field
              name='notes'
              component={TextAreaInput}
              props={{
                className: 'form-inline',
                maxLength: 2500,
                rows: 5,
                disabled: (isMetrcTransfersEnabled && isComplete),
              }}
            />
          </Col>
        </Row>
      </FormGroup>

      {isWasteDisposal ?
        <FormGroup className='waste-disposal'>
          <h3>{I18n.t('salesOrders.form.disposalReason')}</h3>
          <Row>
            <Col xs={6} md={3} lg={2}>
              <Field
                name='disposal_reason'
                component={ReactSelectInput}
                props={{
                  options: disposalReasonOptions,
                  label: I18n.t('salesOrders.form.applyDisposalReason'),
                }}
              />
            </Col>
          </Row>
        </FormGroup>
        :
        null
      }

      <FormGroup className='order-totals'>
        <h3>{I18n.t('cultivation.transfers.form.transferStatus')}</h3>
        <Row>
          <Col xs={2} sm={2} md={2}>
            <Field
              name='status'
              component={ReactSelectInput}
              props={{
                disabled: isComplete || metrcIsImported,
                label: I18n.t('cultivation.transfers.form.status'),
                options: transferStatuses,
              }}
            />
            {
              moveAllInventoryChecked &&
              <div>{I18n.t('cultivation.transfers.form.transferStatusHint')}</div>
            }
          </Col>
          {/*<Col xs={12} id='transferIsCompleteWarning'>
            <div className='text-danger' style={{textAlign: 'right', fontSize: 'larger'}}>
              Warning: Saving with a status of complete will remove the inventory and is <b>not</b> reversible.
            </div>
          </Col>*/}
        </Row>
        {renderConfirmModal()}
      </FormGroup>

      {shouldShowReturnedItems ?
        <ReturnedItems
          returnedItems={initValues.returnedItems}
          locations={locations}
        />
      : null}

      {
        isComplete ? (Object.keys(completedTransferSubmitSectionSettings.actionSettings).length ? <SubmitSection settings={completedTransferSubmitSectionSettings}/> : null) : <SubmitSection settings={submitSectionSettings}/>
      }

    </form>
  );
};

TransferForm.defaultProps = {
  showNegativeInvConfirmation: {},
};

TransferForm.propTypes = {
  form: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onPrintLabels: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  isReceiptForm: PropTypes.bool,
  reset: PropTypes.func,
  change: PropTypes.func,
  handleKeyPress: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  driver: PropTypes.object,
  vehicle: PropTypes.object,
  partner: PropTypes.array,
  array: PropTypes.object,
  formData: PropTypes.shape({
    drivers: PropTypes.array.isRequired,
    returnedItems: PropTypes.array,
  }).isRequired,
  orders: PropTypes.array.isRequired,
  sentTotals: PropTypes.array.isRequired,
  lineTotals: PropTypes.array.isRequired,
  createDriver: PropTypes.func.isRequired,
  isMVP: PropTypes.bool,
  isComplete: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  prepackExpendedInventory: PropTypes.array.isRequired,
  expendedInventory: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
  transferId: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  isDriverCompany: PropTypes.bool.isRequired,
  isWasteDisposal: PropTypes.bool,
  isReceivedTransfer: PropTypes.bool,
  isOutForDelivery: PropTypes.bool,
  isMedicated: PropTypes.bool,
  transferStatuses: PropTypes.array,
  formName: PropTypes.string.isRequired,
  hasReturnedItems: PropTypes.bool,
  returnedItems: PropTypes.array,
  completingTransfer: PropTypes.bool.isRequired,
  handleRestockAndCompleteTransfer: PropTypes.func.isRequired,
  onDontRestock: PropTypes.func.isRequired,
  showNegativeInvConfirmation: PropTypes.object,
  metrcIsImported: PropTypes.bool,
  sentNetTotals: PropTypes.array,
  onCreateMetrcTemplate: PropTypes.func.isRequired,
  currentTransferStatus: PropTypes.string,
  integrationTransferTypes: PropTypes.array,
  moveAllInventoryChecked: PropTypes.bool,
  showMoveAllInventoryFeature: PropTypes.bool
};

const mapStateToProps = (state) => ({
  showMoveAllInventoryFeature: isFeatureEnabled(state)('feature_transfers_move_all_inventory_to_new_location')
});

const WrappedComponent = compose(
  connect(mapStateToProps),
  reduxForm({
    validate,
  })
)(TransferForm);

export default WrappedComponent;
