/* eslint-disable import/no-named-as-default*/
import get from 'lodash.get';
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goBack, push } from 'react-router-redux';
import { change, formValueSelector } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { getHarvestPlantsHarvests } from '../../../selectors/harvests/harvestsSelectors';
import { getHarvestStorageLocations } from '../../../selectors/locationsSelectors';
import { addMessage } from '../../../actions/systemActions';
import * as apiActions from '../../../actions/apiActions';
import * as harvestActions from '../../../actions/harvestActions';
import * as selectedDataActions from '../../../actions/selectedDataActions';
import { setData } from '../../../actions/dataActions';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import { handleComplexSelectRow } from '../../../actions/helpers/selectedDataHelper';
import { convertFormInputDateToDbDate } from '../../../util/dateHelpers';
import FormWrapper from '../../common/form/FormWrapper';
import HarvestPlantsFormWrapper from './HarvestPlantsFormWrapper';
import PrinterModal from '../../printer/PrinterModal';
import InProgressOverlay from '../../common/InProgressOverlay';
import ScanInputFormWrapper from '../../scan-input/ScanInputFormWrapper';
import * as messageTypes from '../../../constants/messageTypes';
import {plantStages} from '../../../constants/plants';
import {getIntegrationState} from '../../../selectors/integration/integrationSelectors';
import {getUomPrecision} from '../../../selectors/uomsSelectors';
import {saveDistribution} from '../../../util/mathHelpers';
import {HARVEST_PLANTS_FORM} from '../../../constants/forms';
import {GR} from '../../../constants/uoms';
import {isFeatureEnabled} from '../../../selectors/featureToggles';


export class HarvestPlantsFormPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    [
      'saveAndPrint',
      'redirect',
      'printLabel',
      'hidePrinter',
      'onSubmit',
      'splitPlantInfo',
      'toggleOverall',
      'showLoadingMessage',
      'hideLoadingMessage',
      'createPlantsForHarvestBatch',
      'scanFieldKeyPressed',
      'searchPlants',
      'calculateAvgRating'
    ].forEach((method) => (this[method] = this[method].bind(this)));

    this.state = {
      today: new Date(),
      showLoadingMessage: false,
      showLoader: true,
      showOk: false,
      loadingMessage: 'harvest.create.creating',
      onDismiss: () => {},
      ignorePromise: false,
      saved: false,
      harvestBatchId: false,
      plantsLimit: 1000, // the point at which individual plant adjustments cannot be done
      isBatchIdSearch: false
    };
  }

  componentWillMount() {
    const {
      actions: { getItem, getUnpaginatedData },
      integrationState: { isBiotrack, isMetrc },
      selectedPlants
    } = this.props;

    const strain_id = selectedPlants && selectedPlants[0] ? selectedPlants[0].strain_id : null;

    if (strain_id) {
      this.loadHarvests(strain_id);
      getItem(`/api/strains/${strain_id}`, itemNames.strain);
    }

    if (isBiotrack) {
      getUnpaginatedData('/api/cultivation/sections', dataNames.sections, { failed: 'locations.get.failed' });
    } else if (isMetrc) {
      getUnpaginatedData('/api/integration/harvest-waste-types', dataNames.harvestWasteTypes, {
        failed: 'harvest.form.getHarvestWasteTypes.failed'
      });
      getUnpaginatedData('/api/location_hierarchy', dataNames.locations, { failed: 'locations.get.failed' });
    } else {
      getUnpaginatedData('/api/location_hierarchy', dataNames.locations, { failed: 'locations.get.failed' });
    }

    getItem(
      '/api/settings/plants',
      itemNames.complianceSettings,
      { failed: 'cultivation.complianceSettings.failed' },
      {
        ids: [
          'cult_plants_on_hand',
          'cult_plants_in_phases',
          'cult_distribute_harvest_weights',
          'cult_track_plants_as_groups'
        ]
      }
    );
  }

  componentWillUnmount() {
    this.props.actions.setData([], dataNames.plants);
  }

  // NOTE: Leaving this here for page performance debugging (unnecessary re-renders)
  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   const diffs = Object.keys(prevProps).filter(k => prevProps[k] !== this.props[k]);
  //
  //   console.log('props diffs', diffs);
  // }

  showLoadingMessage(plants) {
    const message = 'harvest.create.creating';
    this.setState({
      showLoadingMessage: true,
      showLoader: true,
      onDismiss: () => {},
      showOk: false,
      loadingMessage: message
    });
  }

  hideLoadingMessage(addToState) {
    let newState = { showLoadingMessage: false };
    if (addToState !== undefined) {
      newState = Object.assign({}, newState, addToState);
    }
    this.setState(newState);
  }

  loadHarvests(strain_id) {
    this.props.actions.getUnpaginatedData('/api/harvests', dataNames.harvests, undefined, {
      active: 1,
      strain_id,
      final_weight_recorded: 0
    });
  }

  splitPlantInfo(formValues) {
    const {
      plants,
      wet_weight_harvest,
      total_waste_recorded,
      rating_avg_pest_resistance,
      rating_avg_mold,
      rating_avg_mildew,
      rating_avg_bud_rot,
      rating_avg_stretching,
      rating_avg_airy_buds,
      rating_avg_total_quality,
      wet_other_material_weight,
      total_other_material_waste
    } = formValues;

    const precision = getUomPrecision(this.props.formValues.uom, this.props.integrationState);

    const {even: dividedWW, odd: topWW} = saveDistribution(wet_weight_harvest, plants.length, precision);
    const {even: dividedOMWW, odd: topOMWW} = saveDistribution(wet_other_material_weight, plants.length, precision);
    const {even: dividedTWR, odd: topTWR} = saveDistribution(total_waste_recorded, plants.length, precision);
    const {even: dividedOMWR, odd: topOMWR} = saveDistribution(total_other_material_waste, plants.length, precision);

    return plants.map((plant, plantIndex) => {
      const wetWeightHarvest = !plantIndex ? topWW : dividedWW;
      const totalWasteRecorded = !plantIndex ? topTWR : dividedTWR;
      const wetOtherMaterialWeight = !plantIndex ? topOMWW : dividedOMWW;
      const totalOtherMaterialWaste = !plantIndex ? topOMWR : dividedOMWR;

      return {
        plant_id: plant.plant_id,
        tracking_id: plant.tracking_id,
        strain_id: plant.strain_id,
        strain_name: plant.strain_name,
        plant_batch_name: plant.plant_batch_name,
        wet_weight_harvest: wetWeightHarvest,
        wet_other_material_weight: wetOtherMaterialWeight,
        total_waste_recorded: totalWasteRecorded,
        total_other_material_waste: totalOtherMaterialWaste,
        remaining_wet_weight_harvest: wetWeightHarvest - totalWasteRecorded,
        remaining_wet_other_material_weight: wetOtherMaterialWeight - totalOtherMaterialWaste,
        uom: plant.uom,
        rating_avg_pest_resistance,
        rating_avg_mold,
        rating_avg_mildew,
        rating_avg_bud_rot,
        rating_avg_stretching,
        rating_avg_airy_buds,
        rating_avg_total_quality,
      };
    });
  }

  /**
   * This splits the selected plants in chunks of no more that 5000 plants (can be adjusted if needed).
   * and split the total weights (wet and waste) of each chunk among its plants
   * @param formValues
   * @returns {any[]}
   */
  chunkAndsplitPlantInfo(formValues) {

    const {
      use_overall,
      plants,
      num_plants,
      wet_weight_harvest,
      total_waste_recorded,
      rating_avg_pest_resistance,
      rating_avg_mold,
      rating_avg_mildew,
      rating_avg_bud_rot,
      rating_avg_stretching,
      rating_avg_airy_buds,
      rating_avg_total_quality,
      wet_other_material_weight,
      total_other_material_waste,
    } = formValues;

    if (!Array.isArray(plants) || !plants.length) {
      return [];
    }

    const numPlants = num_plants ? num_plants : plants.length;

    // Distribute weights to each individual plant
    const precision = getUomPrecision(this.props.formValues.uom, this.props.integrationState);
    const {even: dividedWW, odd: topWW} = saveDistribution(wet_weight_harvest, numPlants, precision);
    const {even: dividedOMWW, odd: topOMWW} = saveDistribution(wet_other_material_weight, numPlants, precision);
    const {even: dividedTWR, odd: topTWR} = saveDistribution(total_waste_recorded, numPlants, precision);
    const {even: dividedOMWR, odd: topOMWR} = saveDistribution(total_other_material_waste, numPlants, precision);


    const harvestedPlants = plants.slice(0, numPlants);
    let plantChunks = [];
    let i = 0;
    while(i < harvestedPlants.length){
      const nextChunk = harvestedPlants.slice(i, i += 5000);
      if (nextChunk.length > 0) {
        plantChunks.push(nextChunk);
      }
    }

    plantChunks = plantChunks.map((plants, chunkIndex) => {
      return plants.map((plant, plantIndex) => {
        return {
          plant_id: plant.plant_id,
          tracking_id: plant.tracking_id,
          strain_id: plant.strain_id,
          strain_name: plant.strain_name,
          plant_batch_name: plant.plant_batch_name,
          wet_weight_harvest: !use_overall ? (!plantIndex && !chunkIndex ? topWW : dividedWW) : plant.wet_weight_harvest,
          wet_other_material_weight: !use_overall ? (!plantIndex && !chunkIndex ? topOMWW : dividedOMWW) : plant.wet_other_material_weight,
          total_waste_recorded: !use_overall ? (!plantIndex && !chunkIndex ? topTWR : dividedTWR) : plant.total_waste_recorded,
          total_other_material_waste: !use_overall ? (!plantIndex && !chunkIndex ? topOMWR : dividedOMWR) : plant.total_other_material_waste,
          uom: plant.uom,
          rating_avg_pest_resistance,
          rating_avg_mold,
          rating_avg_mildew,
          rating_avg_bud_rot,
          rating_avg_stretching,
          rating_avg_airy_buds,
          rating_avg_total_quality,
        };
      });
    });
    return plantChunks;
  }

  //@TODO: Move this to harvestPlantsMiddleware with its splitPlantInfo method when not in regression.
  toggleOverall(event) {
    const { formValues } = this.props;
    this.props.actions.change('harvest_plants', 'use_overall', event.target.checked);
    this.props.actions.change('harvest_plants', 'plants', this.splitPlantInfo(formValues));
  }

  createPlantsForHarvestBatch(data, harvest) {
    if (data.id) {
      const payload = { ...harvest, existing_harvest_batch_id: data.id, ids: harvest.plants };
      return this.props.actions.postItem('/api/harvests/create_plants', payload, null, {
        success: null,
        failed: 'harvest.create.fail'
      });
    } else {
      return new Promise((res, rej) => res(data));
    }
  }

  calculateAvgRating(plants) {
    const plantsCount = plants.length;
    const avgRating = {};
    [
      'rating_avg_pest_resistance',
      'rating_avg_mold',
      'rating_avg_mildew',
      'rating_avg_bud_rot',
      'rating_avg_stretching',
      'rating_avg_airy_buds',
      'rating_avg_total_quality'
    ].forEach((field) => {
      const totalRating = plants.reduce((acc, plant) => acc + parseInt(plant[field], 10), 0);
      avgRating[field] = Math.floor(totalRating / plantsCount);
    });
    return avgRating;
  }

  onSubmit(formValues) {
    if (this.state.saved) {
      if (formValues.afterSubmit === 'print') {
        this.printLabel();
      }
      return false;
    }

    const allSelectedPlants = this.props.selectedPlants;
    const numPlants = get(formValues, 'num_plants', allSelectedPlants.length);
    const filterPlantsByNumPlants = (plant, index) => {
      return numPlants !== 0 ? index < numPlants : true;
    };

    const getPlantsFromSelectedPlants = (formValues) => {
      return formValues.plants.filter(filterPlantsByNumPlants).map((plant) => {
        if (!plant.plant_id && plant.id) {
          plant.plant_id = plant.id;
        }
        if (selectedPlants.length > this.state.plantsLimit) {
          return { id: plant.id, plant_id: plant.plant_id };
        }
        return plant;
      });
    };

    // As of 9/6/2019 the use_overall flag ACTUALLY means use individual.  It is used for presentation logic
    // so am adding a properly named variable to make this easier to read but one day when we aren't trying to get
    // out of regression like we are right now - someone please fix this so its consistently readable.
    const useIndividualValues = formValues.use_overall;

    const { strain, selectedPlants, isMetrc } = this.props;

    if (this.props.isHarvestBatchRefactorEnabled) {
      var harvestedPlantsChunks = [];
      // This needs to be here because formvalues gets overwritten on the following line.
      harvestedPlantsChunks = this.chunkAndsplitPlantInfo(formValues);
    }

    formValues.plants = !useIndividualValues ? this.splitPlantInfo(formValues) : formValues.plants;
    const harvest_weight = formValues.plants
      .filter(filterPlantsByNumPlants)
      .reduce((previous, current) => parseFloat(current.wet_weight_harvest) + previous, 0);
    const other_weight = formValues.plants
      .filter(filterPlantsByNumPlants)
      .reduce((previous, current) => parseFloat(current.wet_other_material_weight) + previous, 0);
    const harvest = Object.assign({}, formValues, {
      existing_harvest_batch_id: formValues.harvests ? formValues.harvests : null,
      event_date: formValues.harvested_at,
      wet_weight_harvest: !useIndividualValues ? formValues.wet_weight_harvest : harvest_weight,
      wet_other_material_weight: !useIndividualValues ? formValues.wet_other_material_weight : other_weight,
      plants: getPlantsFromSelectedPlants(formValues)
    });
    if (isMetrc) {
      harvest.harvest_waste_type = formValues.harvest_waste_type;
    }
    if (isNaN(harvest.wet_other_material_weight)) {
      delete harvest.total_other_material_waste;
      delete harvest.wet_other_material_weight;
    }
    const harvested_at = convertFormInputDateToDbDate(harvest.harvested_at, this.props.timezone);
    if (harvest.final_weight_recorded) {
      harvest.final_weight = harvest.wet_weight_harvest;
      harvest.completed_at = harvested_at;
    }
    const phenotypeIds = selectedPlants.map((selectedPlant) => selectedPlant.phenotype_id).filter(Boolean);
    const strainId =
      strain && selectedPlants
        ? selectedPlants.length > 1
          ? phenotypeIds.length === selectedPlants.length
            ? phenotypeIds.filter((phenotypeId) => phenotypeId === phenotypeIds[0]).length === selectedPlants.length
              ? selectedPlants[0].phenotype_id
              : null
            : selectedPlants[0].phenotype_id || null
          : selectedPlants[0].phenotype_id || null
        : null;
    const trackingId = selectedPlants.length ? selectedPlants[0].state_integration_tracking_id : null;
    const rating = this.calculateAvgRating(harvest.plants);

    this.showLoadingMessage(formValues.plants);
    harvest.harvested_at = harvested_at;
    harvest.phenotype_id = strainId;
    harvest.trackingId = trackingId;
    harvest.modality = get(selectedPlants, `[0].modality`);
    harvest.cupo_id = get(selectedPlants, `[0].cupo_id`);
    const createHarvestPayload = {
      ...harvest,
      plants: [],
      plants_count: harvest.plants.length,
      total_plants_waste: !useIndividualValues
        ? formValues.total_waste_recorded
        : formValues.plants
          .filter(filterPlantsByNumPlants)
          .reduce((previous, current) => parseFloat(current.total_waste_recorded || 0) + previous, 0),
      total_plants_other_material_waste: !useIndividualValues
        ? formValues.total_other_material_waste
        : formValues.plants
          .filter(filterPlantsByNumPlants)
          .reduce((previous, current) => parseFloat(current.total_other_material_waste || 0) + previous, 0),
      ...rating
    };

    //Two methods of sending the data depending upon feature toggle
    if (this.props.isHarvestBatchRefactorEnabled) {
      //Need to put plants back on.
      // However, putting individual plants in the payload causes 413 problem with 9999 plants.
      // Therefore we have to chunk the harvest calls, or refactor the refactoring.

      const createHarvestOptimizedChunkedPayloads = harvestedPlantsChunks.map(plants => {
        return {
          ...harvest,
          wet_weight_harvest: plants.reduce((previous, current) => parseFloat(current.wet_weight_harvest) + previous, 0),
          wet_other_material_weight: plants.reduce((previous, current) => parseFloat(current.wet_other_material_weight) + previous, 0),
          ...rating,
          plants: plants,
          plants_count: plants.length,
          total_plants_waste: plants.reduce((previous, current) => parseFloat(current.total_waste_recorded || 0) + previous, 0),
          total_plants_other_material_waste: plants.reduce((previous, current) => parseFloat(current.total_other_material_waste || 0) + previous, 0),
          use_individual_waste: true
        };
      });

      const postHarvest = (payload) => {
        return new Promise((resolve, reject) => {
          this.props.actions.postItem('/api/harvests', payload, null, {success: null, failed: 'harvest.create.fail'})
            .then(response => resolve(response))
            .catch((error) => reject(error));
        });
      };

      const postAllHarvests = (payloads) => {
        return payloads.reduce((promise, payload) => {
          return promise
            .then((response) => {
              if (response) {
                // re-use the same batch id for the next call
                payload.existing_harvest_batch_id = get(response, 'id');
              }
              return postHarvest(payload);
            });
        }, Promise.resolve(null));
      };

      postAllHarvests(createHarvestOptimizedChunkedPayloads)
        .then(response => {
          if (!response) {
            return;
          }
          this.setState({ harvestBatchId: response.id });
          this.hideLoadingMessage({ saved: true });
          this.props.actions.addMessage(
            messageTypes.success,
            ['harvest.create.success', { batch_name: response.batch_name }],
            false
          );
          if (formValues.afterSubmit === 'print') {
            this.printLabel(response.id);
            this.hideLoadingMessage();
          } else {
            this.props.actions.clearSelectedData(dataNames.plants);
            this.hideLoadingMessage();
            this.props.actions.push('/harvests/active');
          }
          this.hideLoadingMessage();
        })
        .catch(this.hideLoadingMessage);

    } else {
      this.props.actions
        .postItem(
          '/api/harvests',
          createHarvestPayload,
          null,
          { success: null, failed: 'harvest.create.fail' },
          null,
          (response) => {
            this.setState({ harvestBatchId: response.id });
            const payload = {
              ...harvest,
              use_individual_waste: true
            };

            this.createPlantsForHarvestBatch(response, payload)
              .then(() => {
                this.hideLoadingMessage({ saved: true });
                this.props.actions.addMessage(
                  messageTypes.success,
                  ['harvest.create.success', { batch_name: response.batch_name }],
                  false
                );
                if (formValues.afterSubmit === 'print') {
                  this.printLabel(response.id);
                  this.hideLoadingMessage();
                } else {
                  this.props.actions.clearSelectedData(dataNames.plants);
                  this.hideLoadingMessage();
                  this.props.actions.push('/harvests/active');
                }
              })
              .catch(this.hideLoadingMessage);
          }
        )
        .catch(this.hideLoadingMessage);
    }
  }

  redirect() {
    if (this.props.selectedPlants.length > this.state.plantsLimit) {
      this.props.actions.setData([], dataNames.plants);
    }
    this.props.actions.goBack();
  }

  saveAndPrint(event) {
    this.printLabel();
  }

  printLabel(harvestBatchId = false) {
    if (!harvestBatchId) harvestBatchId = this.state.harvestBatchId;
    this.setState({
      showPrinter: true,
      labelUrl: `/api/labels/generate/harvestBatchPlantLabel/for/${harvestBatchId}`,
      httpAction: 'GET',
      redirect: false
    });
  }

  hidePrinter(event) {
    this.setState({ showPrinter: false });
  }

  searchPlants(data) {
    if (data.length) {
      const searchIdsArray = data.split(/[, ;\n]+/g);
      const searchIdsString = searchIdsArray.join('" "');
      const stageId = plantStages.flowering.value;
      const params = {
        filter:
          `is_destroyed:0 AND code:"flower" AND is_harvested:0 AND is_packaged:0 AND ` +
          `(plant_id:("${searchIdsString}") ` +
          `OR external_identifier:("${searchIdsString}") ` +
          `OR state_integration_tracking_id:("${searchIdsString}") ` +
          `OR (batch_name:("${searchIdsString}") AND stage_id:("${stageId}")))`,
        sort: 'strain_name asc,planting_date asc,plant_id asc',
        size: 100000,
        query: 'matchall'
      };
      this.props.actions.getDataByPost(`/api/search/plants`, params, dataNames.plants, null).then((response) => {
        let plants = [];
        if (response.length) {
          const batchIdLookup = response.reduce((acc, plant) => {
            const batchName = get(plant, 'batch_name', '');
            if (!acc[batchName]) {
              acc[batchName] = null;
            }
            return acc;
          }, {});
          const isBatchIdSearch = (searchIdsArray, plants) => {
            return searchIdsArray.reduce((acc, id) => {
              if (acc) return acc;
              return batchIdLookup[id] === null;
            }, false);
          };

          this.setState({
            isBatchIdSearch: isBatchIdSearch(searchIdsArray, response)
          });
          const strain_id = response[0].strain_id;
          this.loadHarvests(strain_id);
          this.props.actions.getItem(`/api/strains/${strain_id}`, itemNames.strain);
          plants = response.filter((plant) => plant.strain_id === strain_id);
          this.props.actions.handleComplexSelectRow(plants, dataNames.plants, 'set');
          if (plants.length !== response.length) {
            this.props.actions.addMessage(messageTypes.warning, [
              'harvest.create.searchPlantsError',
              {
                other_plants: response.length - plants.length,
                strain_name: response[0].strain_name
              }
            ]);
          }
        } else {
          this.props.actions.handleComplexSelectRow([], dataNames.plants, 'set');
        }
        const numPlants = response.length > plants.length ? plants.length : response.length;
        const selectedPlants = response.length > plants.length ? plants : response;
        const firstPlant = selectedPlants.length > 0 ? selectedPlants[0] : false;
        const multiplier = firstPlant ? get(firstPlant, 'qty', 1) : 1;
        const totalPlants = selectedPlants.length * multiplier;
        this.props.actions.change(HARVEST_PLANTS_FORM, 'num_plants', numPlants);
        this.props.actions.change(HARVEST_PLANTS_FORM, 'max_plants', numPlants);
        this.props.actions.change(HARVEST_PLANTS_FORM, 'total_plants', totalPlants);
        if (numPlants > this.state.plantsLimit) {
          this.props.actions.change(HARVEST_PLANTS_FORM, 'use_overall', true);
        }
      });
    }
  }

  scanFieldKeyPressed(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.searchPlants(event.target.value.trim());
    }
  }

  render() {
    const { showPrinter, labelUrl } = this.state;
    const {
      selectedPlants,
      locations,
      harvests,
      complianceSettings,
      integrationState,
      harvestWasteTypes,
      formValues
    } = this.props;
    const allowDistributedWeights = get(complianceSettings, 'cult_distribute_harvest_weights.value', false);
    if (selectedPlants.length) {
      const initialValues = {
        plants: selectedPlants.map((plant) => ({
          plant_id: plant.id,
          plant_name: plant.plant_id,
          plant_batch_name: plant.batch_name,
          tracking_id: plant.tracking_id,
          strain_id: plant.strain_id,
          strain_name: plant.strain_name,
          rating_avg_pest_resistance: 0,
          rating_avg_mold: 0,
          rating_avg_mildew: 0,
          rating_avg_bud_rot: 0,
          rating_avg_stretching: 0,
          rating_avg_airy_buds: 0,
          rating_avg_total_quality: 0
        })),
        rating_avg_pest_resistance: 0,
        rating_avg_mold: 0,
        rating_avg_mildew: 0,
        rating_avg_bud_rot: 0,
        rating_avg_stretching: 0,
        rating_avg_airy_buds: 0,
        rating_avg_total_quality: 0,
        strain_id: selectedPlants[0].strain_id,
        strain_name: selectedPlants[0].strain_name,
        harvested_at: this.state.today,
        use_overall: formValues.use_overall || (selectedPlants.length === 1 || !allowDistributedWeights),
        num_plants: formValues.num_plants || 0,
        max_plants: formValues.max_plants,
        total_plants: formValues.total_plants,
        uom: GR
      };

      return (
        <FormWrapper title='harvest.create.title' goBack={this.redirect} className='harvest-plants-page'>
          <InProgressOverlay
            isActive={this.state.showLoadingMessage}
            onDismiss={this.state.onDismiss}
            showOk={this.state.showOk}
            showLoader={this.state.showLoader}
            message={this.state.loadingMessage}
            translate={true}
          />
          <HarvestPlantsFormWrapper
            toggleOverall={this.toggleOverall}
            saved={this.state.saved}
            locations={locations}
            harvests={harvests}
            selectedPlants={selectedPlants}
            initialValues={initialValues}
            harvestWasteTypes={harvestWasteTypes}
            allowDistributedWeights={allowDistributedWeights}
            integrationState={integrationState}
            onSubmit={this.onSubmit}
            trackPlantsAsGroups={get(complianceSettings, 'cult_track_plants_as_groups.value', false)}
            enableReinitialize={false}
            plantsLimit={this.state.plantsLimit}
            isBatchIdSearch={this.state.isBatchIdSearch}
          />
          <PrinterModal
            ref='printerModal'
            showPrinter={showPrinter}
            hidePrinter={this.hidePrinter}
            labelUrl={labelUrl}
            httpAction={this.state.httpAction}
            payload={this.state.payload}
            goToAfter={'/harvests'}
          />
        </FormWrapper>
      );
    } else {
      return (
        <FormWrapper title='harvest.create.title' goBack={this.redirect} className='harvest-plants-page'>
          <InProgressOverlay
            isActive={this.state.showLoadingMessage}
            onDismiss={this.state.onDismiss}
            showOk={this.state.showOk}
            showLoader={this.state.showLoader}
            message={this.state.loadingMessage}
            translate={true}
          />
          <ScanInputFormWrapper
            handleKeyPress={this.scanFieldKeyPressed}
            onSubmit={this.searchPlants}
            placeholder={I18n.t('plants.modify.idsSearchPrompt')}
          />
        </FormWrapper>
      );
    }
  }
}

HarvestPlantsFormPage.propTypes = {
  integrationState: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  harvests: PropTypes.array.isRequired,
  strain: PropTypes.object,
  harvest: PropTypes.object,
  selectedPlants: PropTypes.array.isRequired,
  complianceSettings: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  isHarvestBatchRefactorEnabled: PropTypes.bool
};

const selector = formValueSelector('harvest_plants');

function mapStateToProps(state) {
  const { selectedPlants, complianceSettings, strain, timezone } = state;
  const formAttrs = [
    'plants',
    'wet_weight_harvest',
    'total_waste_recorded',
    'rating_avg_pest_resistance',
    'rating_avg_mold',
    'rating_avg_mildew',
    'rating_avg_bud_rot',
    'rating_avg_stretching',
    'rating_avg_airy_buds',
    'rating_avg_total_quality',
    'max_plants',
    'num_plants',
    'total_plants',
    'uom',
  ];
  return {
    formValues: selector(state, ...formAttrs),
    locations: getHarvestStorageLocations(state),
    harvests: getHarvestPlantsHarvests(state),
    harvestWasteTypes: state[dataNames.harvestWasteTypes],
    strain,
    selectedPlants,
    complianceSettings,
    timezone,
    integrationState: getIntegrationState(state),
    isHarvestBatchRefactorEnabled: isFeatureEnabled(state)('feature_harvest_batch_refactor'),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, harvestActions, apiActions, selectedDataActions, {
    push,
    goBack,
    addMessage,
    change,
    setData,
    handleComplexSelectRow
  });
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HarvestPlantsFormPage);
