import React from 'react';
import PropTypes from 'prop-types';

import {Row, Col, FormGroup, FormControl} from 'react-bootstrap';

const ScanInputForm = ({handleKeyPress, placeholder, scanInputRef}) => {
  placeholder = placeholder || 'Raw Barcode Data';
  return (
      <Row>
        <Col md={12}>
          <FormGroup controlId='formControlsTextarea'>
            <FormControl as='textarea' placeholder={placeholder} autoFocus={true} onKeyPress={handleKeyPress} ref={scanInputRef}/>
          </FormGroup>
        </Col>
      </Row>
  );
};

ScanInputForm.propTypes = {
  handleKeyPress: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  scanInputRef: PropTypes.func.isRequired
};

export default ScanInputForm;
