import get from 'lodash.get';
import find from 'lodash.find';
import sortBy from 'lodash.sortby';
import {createSelector} from 'reselect';
import {formValueSelector} from 'redux-form';
import * as dataNames from '../constants/dataNames';
import {BT_FLOWER_LOT, BT_OTHER_MATERIAL_LOT} from '../constants/integration/biotrack/biotrackInventoryTypes';
import {getCureSubcategoryMappings} from './integration/cureApiSelectors';
import {isPaLeafIntegrator} from './integration/leafSelectors';
import {getMetrcCategories, getOhMetrcCategoryMappings, isMetrcIntegrator} from './integration/metrcSelectors';
import isRetailFacility from './facility/isRetailFacility';
import {getIntegrationState} from './integration/integrationSelectors';
import {getApplicablePlatformSubcategoryIds, getBiotrackMappings} from './integration/biotrackCategoriesSelectors';
import {getApisicpaMappings} from './integration/apisicpaCategoriesSelectors';
import {flowerCombined} from '../constants/subcategoryGroups';
import {isOklahoma} from './facility/getCurrentFacility';
import {getCategorySettings, getSubCategorySettings} from './settingsSelectors';
import {getDisplayName} from '../components/categories/helpers';

export const getCategories = (state) => state.categories;
const getCategoryId = (_, props) => get(props, 'category_id');
const labelRequirementsFormSelector = formValueSelector('labelRequirements');
const getFromCategories = (state) => labelRequirementsFormSelector(state, 'category');
const getProducts = (state) => state.products;
const getItemMasters = (state) => state[dataNames.itemMasters];
const getCategoriesData = (state) => [...state[dataNames.categoriesData]];
const getMetrcMappings = (state) => state[dataNames.metrcCategoryMappings];
const getLeafMappings = (state) => state[dataNames.leafCategoryMappings];
const getWeedmapsMappings = (state) => state[dataNames.weedmapsCategoryMappings];
export const getLeafCategories = (state) => state[dataNames.leafCategories];
export const getWeedmapsCategories = (state) => state[dataNames.weedmapsCategories];
const getLeaflyMappings = (state) => state[dataNames.leaflyCategoryMappings];
export const getLeaflyCategories = (state) => state[dataNames.leaflyCategories];
const getIsolocityMappings = (state) => state[dataNames.isolocityCategoryMappings];
export const getIsolocityCategories = (state) => state[dataNames.isolocityCategories];
export const getCCACategories = state => state[dataNames.ccaCategories];
export const getCCAMappings = state => state[dataNames.ccaCategoryMappings];

export const getCategoryOptions = createSelector(
  getCategories,
  (categories) => categories
);

export const getSubCategoryOptions = createSelector(
  [getCategories, getFromCategories],
  (categories, formCategories) => {
    const subCategories = [];
    if (formCategories) {
      formCategories.forEach((category) => {
        categories.forEach((initialCategory) => {
          if (initialCategory.id === category.id) {
            initialCategory.subcategories.map((subCat) => {
              return subCat.active > 0;
            });
            subCategories.push(...initialCategory.subcategories);
          }
        });
      });
    }
    return subCategories;
  }
);

export const getCategoriesDataSelector = createSelector(
  getCategoriesData,
  (categories) => {
    return categories;
  }
);

export const getProductsOptions = createSelector(
  [getFromCategories, getItemMasters],
  (categories, items) => {
    return items.filter((item) => categories.some((category) => category.id === item.category_id));
  }
);

export const getCategoryManagementCategories = createSelector(
  [
    getCategoriesData,
    getCategories,
    getProducts,
    getBiotrackMappings,
    getApisicpaMappings,
    getMetrcMappings,
    getOhMetrcCategoryMappings,
    getLeafCategories,
    getLeafMappings,
    getLeaflyCategories,
    getLeaflyMappings,
    getCureSubcategoryMappings,
    getMetrcCategories,
    getWeedmapsMappings,
    getIsolocityCategories,
    getIsolocityMappings,
    isRetailFacility,
    getIntegrationState,
    getCCACategories,
    getCCAMappings,
    getCategorySettings,
    getSubCategorySettings
  ],
  (
    categoriesData,
    categories,
    products,
    biotrackMappings,
    apisicpaMappings,
    metrcMappings,
    ohmetrcMappings,
    leafCategories,
    leafMappings,
    leaflyCategories,
    leaflyMappings,
    cureMappings,
    metrcCategories,
    weedmapsMappings,
    isolocityCategories,
    isolocityMappings,
    isRetail,
    integrationState,
    ccaCategories,
    ccaMappings,
    categorySettings,
    subCategorySettings
  ) => {
    const {
      isLeaf,
      isMetrc,
      isOhMetrc,
      isCure,
      isBiotrack,
      isApisicpa,
      isPaLeaf,
      isLeafly,
      isWeedmaps,
      isIsolocity,
      isCca
    } = integrationState;

    // Category source option to support using categories loaded in products
    const useCategories = Array.isArray(categoriesData) && categoriesData.length > 0 ? categoriesData : categories;
    const metrcCategoryIds = Array.isArray(metrcCategories) ? metrcCategories.map((c) => c.id) : [];

    const isSubCategoryLocked = (sub) => {
      if (!isMetrc) return false;
      const tests = [
        // Writing so it can support multi though only one now.
        {
          type: 'metrc',
          isLockedTest(sub) {
            const id = parseInt(sub.metrc_item_category_id);
            return !isNaN(id) && metrcCategoryIds.indexOf(id) !== -1;
          }
        }
      ];
      return tests.reduce((acc, test) => {
        if (acc) return acc;
        if (test.isLockedTest(sub)) return true;
        return acc;
      }, false);
    };

    return useCategories && useCategories.length
      ? useCategories.map((category) => {
        const displayName = getDisplayName(category.category_code, categorySettings, category.name);
        const updatedCategory = Object.assign({}, category, {name: displayName});
        return {
          ...updatedCategory,
          subcategories: get(category, 'subcategories', []).map((subcategory) => {
            let metrc_item_category_id = undefined;
            let ohmetrc_category_id = undefined;
            let leaf_category_id = undefined;
            let is_finished_flower = false;
            let biotrack_category_id = undefined;
            let apisicpa_category_id = undefined;
            let weedmaps_category_id = undefined;
            let leafly_category_id = undefined;
            let specification_id = undefined;
            let cca_category_id = undefined;
            const subcategoryCureMappings =
              isCure && cureMappings.filter((mapping) => mapping.subcategory_id === subcategory.id);
            const cure_form_ids = subcategoryCureMappings && subcategoryCureMappings.map((mapping) => mapping.form_id);
            const cure_order_type_id =
              subcategoryCureMappings && subcategoryCureMappings[0] && subcategoryCureMappings[0].order_type_id;
            if (isMetrc && metrcMappings && metrcMappings.length) {
              const mapping = metrcMappings.find((metrcMapping) => metrcMapping.subcategory_id === subcategory.id);
              //check if exist mapping and metrc_subcategory can be selected for category
              if (
                mapping &&
                metrcCategories.find(
                  (mc) =>
                    mc.mjp_category_ids.indexOf(subcategory.category_id) !== -1 &&
                    mc.id === mapping.metrc_item_category_id
                )
              ) {
                metrc_item_category_id = mapping.metrc_item_category_id;
              }
            } else if (isLeaf && leafMappings && leafMappings.length) {
              const mapping = leafMappings.find((leafMapping) => leafMapping.subcategory_id === subcategory.id);
              if (mapping) {
                leaf_category_id = mapping.leaf_category_id;
                is_finished_flower = subcategory.subcategory_code === 'FINISHED_FLOWER';
              }
            } else if (
              isPaLeaf &&
              !leafMappings.length &&
              leafCategories.length &&
              subcategory.subcategory_code === 'FINISHED_FLOWER'
            ) {
              let leafCategory;
              if (isRetail) {
                leafCategory = leafCategories.find((category) => category.code === 'vaporizer_or_nebulizer');
              } else {
                leafCategory = leafCategories.find((category) => category.code === 'finished_flower');
              }
              leaf_category_id = leafCategory.id;
              is_finished_flower = true;
            } else if (isBiotrack && Array.isArray(biotrackMappings)) {
              const mapping = biotrackMappings.find(
                (biotrackMapping) => biotrackMapping.subcategory_id === subcategory.id
              );
              biotrack_category_id = (mapping && mapping.biotrack_category_id) || null;
            } else if (isApisicpa && Array.isArray(apisicpaMappings)) {
              const mapping = apisicpaMappings.find(
                (apisicpaMapping) => apisicpaMapping.subcategory_id === subcategory.id
              );
              apisicpa_category_id = (mapping && mapping.apisicpa_category_id) || null;
            }

            if (isOhMetrc && ohmetrcMappings && ohmetrcMappings.length) {
              const mapping = find(ohmetrcMappings, {subcategory_id: subcategory.id});

              if (mapping) {
                ohmetrc_category_id = mapping.ohmetrc_category_id;
              }
            }

            if (isLeafly && leaflyMappings && leaflyMappings.length) {
              const mapping = leaflyMappings.find((leaflyMapping) => leaflyMapping.subcategory_id === subcategory.id);
              if (mapping) {
                leafly_category_id = mapping.leafly_category_id;
              }
            }

            if (isCca && ccaMappings && ccaMappings.length) {
              const mapping = ccaMappings.find(ccaMapping => ccaMapping.subcategory_id === subcategory.id);
              if (mapping) {
                cca_category_id = mapping.cca_category_id;
              }
            }

            if (isWeedmaps && weedmapsMappings && weedmapsMappings.length) {
              const mapping = weedmapsMappings.find((mapping) => mapping.subcategory_id === subcategory.id);
              if (mapping) {
                weedmaps_category_id = mapping.weedmaps_category_id;
              }
            }
            if (isIsolocity && isolocityMappings && isolocityMappings.length) {
              const mapping = isolocityMappings.find(
                (isolocityMapping) => isolocityMapping.subcategory_id === subcategory.id
              );
              if (mapping) {
                specification_id = mapping.specification_id;
              }
            }
            const currentName = subcategory.display_name || subcategory.name;
            const sub = {
              ...subcategory,
              display_name: getDisplayName(`${category.category_code}_${subcategory.subcategory_code}`, subCategorySettings, currentName),
              biotrack_category_id,
              apisicpa_category_id,
              metrc_item_category_id,
              ohmetrc_category_id,
              weedmaps_category_id,
              leaf_category_id,
              cure_form_ids,
              cure_order_type_id,
              leafly_category_id,
              disabledMapping: isPaLeaf && is_finished_flower,
              specification_id,
              cca_category_id,
            };
            return Object.assign({}, sub, {locked: isSubCategoryLocked(sub)});
          })
        };
      })
      : [];
  }
);

export const getCategoryManagementCategoriesByCode = createSelector(
  [getCategoryManagementCategories],
  (categories) => {
    return categories.reduce((categoriesByCode, category) => {
      categoriesByCode[category.category_code] = category;
      return categoriesByCode;
    }, {});
  }
);

export const getNonMedicatedCategoryId = createSelector(
  [getCategories],
  (categories) => {
    const nonMedicated = categories.find(isCategoryNonMedicated);
    return nonMedicated && nonMedicated.id;
  }
);

export function isCategoryFlower(category) {
  return Boolean(category && category.category_code === 'FLOWER');
}

export function isCategoryConcentrate(category) {
  return Boolean(category && category.category_code === 'CONCENTRATE');
}

export function isCategoryCartridgesPens(category) {
  return Boolean(category && category.category_code === 'CARTPENS');
}

export function isCategoryInfusedEdible(category) {
  return Boolean(category && category.category_code === 'EDIBLE');
}

export function isCategoryInfusedNonEdible(category) {
  return Boolean(category && category.category_code === 'INFUSED');
}

export function isCategoryPlants(category) {
  return Boolean(category && category.category_code === 'PLANTS');
}

export function isCategoryNonMedicated(category) {
  return Boolean(category && category.category_code === 'UNMEDICATED');
}

export function isCategoryHarvest(category) {
  return Boolean(category && category.category_code === 'HARVESTS');
}

export function isCategoryCbdflower(category) {
  return Boolean(category && category.category_code === 'CBD_FLOWER');
}

export function isCategoryHempWaste(category) {
  return Boolean(category && category.category_code === 'HEMP_WASTE');
}

export function isSubCategorySeeds(subcategory) {
  return Boolean(subcategory && subcategory.subcategory_code === 'SEED');
}

export function isSubCategoryMature(subcategory) {
  return Boolean(subcategory && subcategory.subcategory_code === 'MATURE_PLANT');
}

export function isSubCategoryTissue(subcategory) {
  return Boolean(subcategory && subcategory.subcategory_code === 'PLANT_TISSUE');
}

export function isSubCategoryTissueCulture(subcategory) {
  return Boolean(subcategory && subcategory.subcategory_code === 'TISSUE_CULTURE');
}

export function isSubCategoryClones(subcategory) {
  return Boolean(subcategory && subcategory.subcategory_code === 'CLONE');
}

export function isSubCategoryWholesaleClones(subcategory) {
  return Boolean(subcategory && subcategory.subcategory_code === 'WHOLESALE_CLONES');
}

export function isSubCategoryWholesaleSeeds(subcategory) {
  return Boolean(subcategory && subcategory.subcategory_code === 'WHOLESALE_SEEDS');
}

export function isSubCategoryWholesaleTissueCultures(subcategory) {
  return Boolean(subcategory && subcategory.subcategory_code === 'WHOLESALE_TISSUE_CULTURES');
}

export function isSubCategoryWholesalePlantTissues(subcategory) {
  return Boolean(subcategory && subcategory.subcategory_code === 'WHOLESALE_PLANT_TISSUES');
}

export function isSubCategoryWaste(subcategory) {
  return Boolean(subcategory && subcategory.subcategory_code === 'WASTE');
}

export function isSubCategoryOil(subcategory) {
  return Boolean(subcategory && subcategory.subcategory_code === 'OIL');
}

export function isSubCategoryFinishedFlower(subcategory) {
  return Boolean(subcategory && subcategory.subcategory_code === 'FINISHED_FLOWER');
}

export function isSubCategoryIngredient(subcategory) {
  return Boolean(subcategory && subcategory.subcategory_code === 'INGREDIENT');
}

export function isPASubCategory(subcategory) {
  return Boolean(subcategory && (!isSubCategoryWaste(subcategory) || isSubCategoryFinishedFlower(subcategory)));
}

export function isNotPASubCategory(subcategory) {
  return Boolean(subcategory && (isSubCategoryWaste(subcategory) || !isSubCategoryFinishedFlower(subcategory)));
}

export function isCategoryMarijuana(category) {
  return Boolean(category && category.category_code === 'MARIJUANA');
}

export const getLeafSubCategories = createSelector(
  [getLeafCategories, isPaLeafIntegrator],
  (leafCategories, isPaLeaf) => {
    return sortBy(leafCategories.filter((leafCategory) => isPaLeaf || leafCategory.parent_id), 'name');
  }
);

export const getLeaflySubCategories = createSelector(
  [getLeaflyCategories],
  (leaflyCategories) => {
    return sortBy(leaflyCategories, 'name');
  }
);

export const getIsolocitySubCategories = createSelector(
  [getIsolocityCategories],
  (isolocityCategories) => {
    return sortBy(isolocityCategories, 'name');
  }
);

export const getCategoryById = createSelector(
  [getCategories, getCategoryId],
  (categories, category_id) => {
    return categories.find((category) => category.id === category_id) || {};
  }
);

export const getSubCategoriesByCategoryId = createSelector(
  [getCategoryById],
  (category) => {
    const subCategories = category.subcategories || [];
    return subCategories.map((sub) => ({...sub, display_name: sub.display_name ? sub.display_name : sub.name}));
  }
);

const getBiotrackFlowerSubcategoryIds = getApplicablePlatformSubcategoryIds([BT_FLOWER_LOT]);
export const getMappedFlowerSubcategoryIds = createSelector(
  [getIntegrationState, getLeafSubCategories, getLeafMappings, getBiotrackFlowerSubcategoryIds],
  ({isLeaf, isBiotrack}, leafCategories, leafMappings, btSubcategoryIds) => {
    if (isLeaf) {
      return getPlatformSubcategoryIdsByLeafMapping(leafCategories, leafMappings, [
        'flower',
        'flower_lots',
        'fresh_frozen'
      ]);
    } else if (isBiotrack) {
      return btSubcategoryIds;
    }
    return [];
  }
);

export const getMappedFinishFlowerSubcategoryIds = createSelector(
  [getIntegrationState, getLeafSubCategories, getLeafMappings],
  ({isLeaf}, leafCategories, leafMappings) => {
    if (isLeaf) {
      return getPlatformSubcategoryIdsByLeafMapping(leafCategories, leafMappings, ['finish_flower']);
    }
    return [];
  }
);

const getBiotrackOtherMaterialSubcategoryIds = getApplicablePlatformSubcategoryIds([BT_OTHER_MATERIAL_LOT]);
export const getMappedOtherMaterialSubcategoryIds = createSelector(
  [getIntegrationState, getLeafSubCategories, getLeafMappings, getBiotrackOtherMaterialSubcategoryIds],
  ({isLeaf, isBiotrack}, leafCategories, leafMappings, btSubcategoryIds) => {
    if (isLeaf) {
      return getPlatformSubcategoryIdsByLeafMapping(leafCategories, leafMappings, [
        'other_material',
        'other_material_lots'
      ]);
    } else if (isBiotrack) {
      return btSubcategoryIds;
    }
    return [];
  }
);

export const getMetrcFilteredCategories = createSelector(
  [getCategories, isMetrcIntegrator, getMetrcCategories, isOklahoma],
  (categories, isMetrc, metrcCategories, isOklahoma) => {
    if (isMetrc) {
      return categories.filter(
        (category) =>
          isCategoryNonMedicated(category) ||
          isCategoryHarvest(category) ||
          metrcCategories.find((mc) => mc.mjp_category_ids && mc.mjp_category_ids.indexOf(category.id) !== -1)
      );
    }

    return categories.map((category) => {
      if (isCategoryFlower(category)) {
        category.subcategories.filter(
          (subcategory) =>
            (isOklahoma && subcategory.subcategory_code === flowerCombined) ||
            subcategory.subcategory_code !== flowerCombined
        );
      }
      return category;
    });
  }
);

/**
 * Get list of MJP Subcategory IDs matching provided Leaf category codes
 * @param {Array} leafCategories
 * @param {Array} leafMappings
 * @param {Array} leafDesiredCodes
 * @return {Number[]}
 */
export const getPlatformSubcategoryIdsByLeafMapping = (leafCategories, leafMappings, leafDesiredCodes) => {
  const internalIds = leafDesiredCodes.map((code) => {
    const leafCategory = leafCategories.find((c) => c.code === code);
    return leafCategory && leafCategory.id;
  });

  return leafMappings
    .filter((btSubcategory) => internalIds.indexOf(btSubcategory.leaf_category_id) > -1)
    .map((btSubcategory) => btSubcategory.subcategory_id);
};

/**
 * returns the subcategoryId for the specified categoryCode / subcategoryCode
 * @param {array} categories
 * @param {string} categoryCode
 * @param {string} subcategoryCode
 */
export const getSubcategoryId = (categories, categoryCode, subcategoryCode) => {
  const category = categories.find(cat => cat.category_code === categoryCode);
  if (category) {
    const subcategory = category.subcategories.find(subCat => subCat.subcategory_code === subcategoryCode);
    if (subcategory) {
      return subcategory.id;
    }
  }
  return null;
};
