import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';
import {bindActionCreators} from 'redux';
import {Col, Row, Button} from 'react-bootstrap';

import TextInput from '../../../../common/form/TextInput';
import SubmitSection from '../common/SubmitSection';
import ReactSelectInput from '../../../../common/form/ReactSelectInput';
import * as dataNames from '../../../../../constants/dataNames';
import {getUnpaginatedData, postItem} from '../../../../../actions/apiActions';
import {areAllProductsMappedToCategories} from '../../../../../selectors/integration/thirdPartyIntegrationSelectors';

export class Weedmaps extends React.PureComponent {
  constructor (props, context) {
    super(props, context);

    this.syncMenu = this.syncMenu.bind(this);
    this.navigateToCategories = this.navigateToCategories.bind(this);

    this.pricingTypeOptions = [
      { text: 'Recreational', value: 'recreational' },
      { text: 'Medical', value: 'medical' },
    ];

    this.liveModeOptions = [
      { text: 'Yes', value: true },
      { text: 'No', value: false },
    ];

    this.inventoryPreferenceOptions = [
      { text: 'Exclude out of stock', value: 'exclude_out_of_stock' },
      { text: 'Everything', value: 'everything' },
    ];
  }

  componentWillMount(){
    this.props.actions.getUnpaginatedData('/api/categories?include_product_counts=1&integration=weedmaps&paginate=0', dataNames.categories);
    this.props.actions.getUnpaginatedData('/api/weedmaps/item_categories/mapping', dataNames.integrationCategoryMappings);
  }

  navigateToCategories(){
    this.props.actions.push('/category/management/weedmaps');
  }

  syncMenu (event) {
    event.preventDefault();

    const responses = {
      success: 'facility.setup.thirdPartyIntegration.weedmaps.resyncJobDispatched',
      failed: 'facility.setup.thirdPartyIntegration.weedmaps.resyncJobFailed'
    };

    this.props.actions.postItem('/api/weedmaps/menu/resync', null, '', responses);
  }

  render () {
    return (
      <div className='weedmaps-container'>
        {
          this.props.integration && this.props.integration.value.active ?
          <Row>
            <Col sm={12} md={3} lg={3}>
            {
              this.props.productsFullyMapped ?
              <Button variant='primary' onClick={this.syncMenu}>
                Dump Weedmaps Menu and re-sync
              </Button>
              : (<div>
                <div className='text-danger' style={{textAlign: 'center'}}>
                  Some products belong to categories that are not mapped to Weedmaps categories.
                </div>
                <Button variant='primary' className='btn-block' onClick={this.navigateToCategories}>
                  Map Categories
                </Button>
                <div className='text-muted' style={{textAlign: 'center', fontSize: 'smaller'}}>
                  Once these products are mapped you can synchronize the menu.
                </div>
              </div>)
            }
            </Col>
          </Row>
          : null
        }
        <Row>
          <Col sm={6} md={6} lg={6}>
            <Field name='api_key' component={TextInput} props={{
              label: 'Weedmaps API Key',
              isRequired: true,
            }} />
          </Col>
          <Col sm={6} md={6} lg={6}>
            <Field name='dispensary_id' component={TextInput} props={{
              label: 'Dispensary ID',
              isRequired: true,
            }} />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={3} lg={3}>
            <Field name='active' component={ReactSelectInput} props={{
              label: 'Active',
              isRequired: true,
              options: this.liveModeOptions,
              dataId: 'value'
            }} />
          </Col>
          <Col sm={6} md={3} lg={3}>
            <Field name='is_live_mode' component={ReactSelectInput} props={{
              label: 'Live Mode',
              isRequired: true,
              options: this.liveModeOptions,
              dataId: 'value'
            }} />
          </Col>
          <Col sm={6} md={3} lg={3}>
            <Field name='inventory_preference' component={ReactSelectInput} props={{
              label: 'Inventory Preference',
              isRequired: true,
              options: this.inventoryPreferenceOptions,
              dataId: 'value'
            }} />
          </Col>
          <Col sm={6} md={3} lg={3}>
            <Field name='pricing_type' component={ReactSelectInput} props={{
              label: 'Pricing Type',
              isRequired: true,
              options: this.pricingTypeOptions,
              dataId: 'value'
            }} />
          </Col>
        </Row>
        <SubmitSection />
      </div>
    );
  }
}

function mapStateToProps(state){
  return {
    productsFullyMapped: areAllProductsMappedToCategories(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, {getUnpaginatedData, postItem, push});
  return {
    actions: bindActionCreators(actions, dispatch, push)
  };
}

Weedmaps.propTypes = {
  actions: PropTypes.func.isRequired,
  integration: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(Weedmaps);
