import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field, FieldArray} from 'redux-form';
import {Card} from 'react-bootstrap';
import * as formData from '../formData';
import * as dataNames from '../../../../../../constants/dataNames';
import InternationalDecimalInput from '../../../../../common/form/InternationalDecimalInput';
import TextInput from '../../../../../common/form/TextInput';
import ReactSelectInput from '../../../../../common/form/ReactSelectInput';
import RemovePointsOnDaysBetweenVisits from './RemovePointsOnDaysBetweenVisits';
import RewardPointsApplication from './RewardPointsApplication';
import RewardPointsAsDollars from './RewardPointsAsDollars';
import CheckboxSettingsRow from './CheckboxSettingsRow';
import DayTimePointWindows from './DayTimePointWindows';

const RewardSettingsPanel  = (props) => {

  const booleanOptions = [
    {value: 1, text: 'Yes'},
    {value: 0, text: 'No'}
  ];

  const componentMap = {
    reward_remove_points_after_days_between_visits: RemovePointsOnDaysBetweenVisits,
    reward_one_point_is_worth: RewardPointsAsDollars,
    reward_apply_rewards_as: RewardPointsApplication
  };

  const rowComponentMap = {
    reward_accrue_points_by_category: {
      component: CheckboxSettingsRow,
      config: {
        dataName: dataNames.categories,
        nameField: 'name'
      }
    },
    reward_eligible_reward_groups: {
      component: CheckboxSettingsRow,
      config: {
        staticCollection: props.customerGroups,
        dataName: '',
        nameField: 'group_name',
        footerText: I18n.t(`retail.rewardSetup.customerGroupsFooterText`),
      }
    },
    reward_allow_rewards_for_order_type: {
      component: CheckboxSettingsRow,
      config: {
        staticCollection: formData.orderTypes,
        dataName: '',
        nameField: 'name'
      }
    },
  };

  const hints = [
    'reward_record_points_based_on_sales',
    'reward_one_point_is_worth',
    'reward_remove_points_after_days_between_visits',
    'reward_apply_rewards_as',
  ];

  const hasRowBasedComponent = (setting) => {
    return (setting.field_type === 'custom' && rowComponentMap[setting.text]);
  };

  const hasCustomComponent = (setting) => {
    return (setting.field_type === 'custom' && componentMap[setting.text]);
  };

  /* eslint-disable react/no-multi-comp */
  const getComponent = (setting) => {

    if(setting.field_type === 'custom' && componentMap[setting.text] !== undefined) {
      return componentMap[setting.text];
    }

    //Numbers are structured a bit differently
    if(setting.field_type === 'number') {
      return (<InternationalDecimalInput
        fractionPartSize={2}
        style={{width: '100%'}}
        name={setting.text}
        props={{clearable: false}}
      />);
    }

    //Basic component
    let thisComponent = TextInput;
    if(setting.field_type === 'boolean') thisComponent = ReactSelectInput;

    return (<Field
      style={{width: '100%'}}
      name={setting.text}
      component={thisComponent}
      props={setting.field_type !== 'boolean' ? {clearable: false} : {options: booleanOptions, clearable: false}}
    />);
  };

  const getDisabledClassIfDisabled = (settingText = false) => {
    if(settingText){
      return props.disabled && settingText !== 'reward_record_points_based_on_sales' ? 'disable-contents' : '';
    }
    return props.disabled ? 'disable-contents' : '';
  };

  return(
    <Card>
      <Card.Header>
        {I18n.t(`retail.rewardSetup.ui.${props.group}`)}
      </Card.Header>
      <Card.Body>
        {
          props.group !== 'reward_application'
          ? null
            : <div className='text-muted' style={{marginBottom: '12px'}}>
            If "Customers Earn Points Based On Sales" is set to no - rewards will not be available at all.<br />
            If rewards are enabled and "Points Are Used As Currency" is enabled, then points may be applied only as currency.<br />
            If rewards are enabled and "Points Are Used As Currency" is disabled, then points are used to "buy" discounts that are then applied to an order.  While a bit more work, this model is also much more flexible.
          </div>
        }
        <table className='table table-bordered table-striped'>
          <tbody className={props.group !== 'reward_application' && props.disabled ? 'disable-contents' : ''}>
          {
            props.settings.map((setting, index) => {

              if(setting.text === 'reward_timeframe_based_accrual_rate'){
                return <FieldArray key={index} name={setting.text} component={DayTimePointWindows} props={Object.assign({}, {setting})} />;
              }

              // Checkboxes on various collections
              if(hasRowBasedComponent(setting)){
                return <Field key={index} name={setting.text} component={rowComponentMap[setting.text].component} props={Object.assign({}, rowComponentMap[setting.text].config, {setting})} />;
              }

              // Gen purpose
              return (
                <tr key={index} className={getDisabledClassIfDisabled(setting.text)}>
                  <td style={{verticalAlign: 'middle', width: '75%'}}>
                    <div>{I18n.t(`retail.rewardSetup.fields.${setting.text}`)}</div>
                    {
                      hints.indexOf(setting.text) === -1
                      ? null
                      : <div className='text-muted' style={{fontSize: 'smaller'}}>{I18n.t(`retail.rewardSetup.fields.${setting.text}_hint`)}</div>
                    }
                  </td>
                  <td className='form-group-no-margin'>
                    {
                      hasCustomComponent(setting)
                      ? React.createElement(getComponent(setting), {setting, change: props.change, formSelector: props.formSelector})
                        : getComponent(setting)
                    }
                  </td>
                </tr>
              );
            })
          }
          </tbody>
        </table>
      </Card.Body>
    </Card>
  );
};

RewardSettingsPanel.propTypes = {
  group: PropTypes.string.isRequired,
  settings: PropTypes.array.isRequired,
  change: PropTypes.func.isRequired,
  formSelector: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  customerGroups: PropTypes.array.isRequired,
};

export default RewardSettingsPanel;
