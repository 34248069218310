import React from 'react';
import PropTypes from 'prop-types';
import {FormControl} from 'react-bootstrap';

import ReduxBootstrapInput from './ReduxBootstrapInput';

const TextInput = ({ label, size, input, meta, children, isRequired, rightAddon, leftAddon, ...props }) => {

  meta.dirty = meta.dirty ? 'true' : 'false';
  return (
    <ReduxBootstrapInput Control={FormControl}
                         label={label} size={size}
                         input={input} meta={meta} autoComplete='off'
                         name={input.name} isRequired={isRequired}
                         rightAddon={rightAddon} leftAddon={leftAddon}
                         type='text' {...props} >
      {children}
    </ReduxBootstrapInput>
  );
};

TextInput.propTypes = {
  label: PropTypes.string,
  size: PropTypes.oneOf(['lg', 'sm', 'large', 'small']),
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func
  }).isRequired,
  meta: PropTypes.object.isRequired,
  children: PropTypes.node,
  isRequired: PropTypes.bool,
  leftAddon: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.node]),
  rightAddon: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.node]),
};

export default TextInput;
