import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';

const LabelRowsReport = (props) => {

  const labelStyle = {margin: '8px 0px', width: `${props.label.width}in`};
  const overflowStyle = Object.assign({}, labelStyle, {backgroundColor: '#ccc'});

  const noValidationsFilter = (ex) => {
    return ex.type.toLowerCase() !== 'validation';
  };
  const validationsFilter = (ex) => {
    return ex.type.toLowerCase() === 'validation';
  };

  return (<Row>
    <div style={{marginTop: '15px'}}>
      <Col md={6}>
        <div><strong>Label Compliance And Configuration Rules Summary</strong></div>
        <div style={{margin: '12px 0px'}}>Rows listed here are on your label but were hidden due to compliance settings or configuration settings of a label row.</div>
          <table className='table table-sm table-bordered table-striped'>
            <thead>
              <tr>
                <th>Row Name</th>
                <th>Type</th>
                <th>Reason</th>
              </tr>
            </thead>
            <tbody>
            {
              props.label.exclusions.filter(noValidationsFilter).length === 0
                ? <tr><td colSpan={3} className='text-muted' style={{textAlign: 'center', fontSize: 'smaller'}}>No Excluded Rows Found For This Label</td></tr>
                : null
            }
            {
              props.label.exclusions.filter(noValidationsFilter).map((ex, index) => {
                return (<tr key={index}>
                  <td>{ex.label}</td>
                  <td>{ex.type}</td>
                  <td>{ex.reason}</td>
                </tr>);
              })
            }
            </tbody>
          </table>
      </Col>
      <Col md={6}>
        <div><strong>Label Validation Summary</strong></div>
        <div style={{margin: '12px 0px'}}>Rows listed here have values that are invalid or empty.</div>
        <table className='table table-sm table-bordered table-striped' style={{marginBottom: '8px'}}>
          <thead>
          <tr>
            <th>Row Name</th>
            <th>Type</th>
            <th>Reason</th>
          </tr>
          </thead>
          <tbody>
          {
            props.label.exclusions.filter(validationsFilter).length === 0
              ? <tr><td colSpan={3} className='text-muted' style={{textAlign: 'center', fontSize: 'smaller'}}>No Validation Issues Found For This Label</td></tr>
              : null
          }
          {
            props.label.exclusions.filter(validationsFilter).map((ex, index) => {
              return (<tr key={index}>
                <td>{ex.label}</td>
                <td>{ex.type}</td>
                <td>{ex.reason}</td>
              </tr>);
            })
          }
          </tbody>
        </table>
        <div style={{fontSize: 'smaller'}} className='text-muted'>
          <strong>These are not system errors.  In many cases you should set the field to display only when a value is present.</strong>
        </div>
      </Col>
      <div style={{clear:'both'}} />
      <hr />
      <Col md={6}>
        <div><strong>Label Printing Detail</strong></div>
        <div>
          {
            props.label.image.base64_collection.length < 2
              ? <div>All rows defined are included on your label.</div>
              : <div>
              <strong><span className='text-danger'>Some rows for your label will not print!</span></strong>  Gray image(s) below show rows which fall outside the boundary of your label.  Consider adjusting the configuration of your label.
              <br /><br />
              <div className='text-muted' style={{fontSize: 'smaller'}}>NOTE: We force anchored bar codes onto the label so your original configuration may have the bar code in a different location.</div>
            </div>
          }
        </div>
      <div style={{width: `${props.label.width}in`}}>
        {
          props.label.image.base64_collection.map((img, index) => {
            return <div key={index} style={index === 0 ? labelStyle : overflowStyle}><img key={index} style={{border: '1px solid #ccc', width: `${props.label.width}in`, height: `${props.label.height}in`, padding:'0'}} src={['data:image/png;base64', img]}/></div>;
          })
        }
      </div>
      </Col>
      <div style={{clear:'both'}} />
    </div>
    <div style={{clear:'both'}} />
  </Row>);
};

LabelRowsReport.propTypes = {
  label: PropTypes.object.isRequired,
};

export default LabelRowsReport;
