/* eslint-disable import/no-mutable-exports*/
import { connect } from 'react-redux';
import {reduxForm, formValueSelector} from 'redux-form';
import { PARTNER_CONTACTS } from '../../../constants/forms';
import {validateContacts} from './validate';
import ContactsForm from './ContactsForm';

const WrappedComponent = reduxForm({
  form: PARTNER_CONTACTS,
  validate: validateContacts,
})(ContactsForm);

const selector = formValueSelector(PARTNER_CONTACTS);

export default connect(
  (state) => {
    const contacts = selector(state, 'contacts') || [];
    const joinToFullName = contact => (
       '' + (contact.first_name ? contact.first_name + ' ' : '') + (contact.last_name ? contact.last_name + ' ' : '')
     );

    return {
      contactNames: contacts.map(joinToFullName),
    };
  }
)(WrappedComponent);
