export const normal = 'NORMAL';
export const leaf = 'LEAF';
export const leafPA = 'LEAF_PA';
export const leafWA = 'LEAF_WA';
export const metrcMd = 'METRC_MD';
export const metrcOregonRec = 'METRC_OR_REC';
export const metrcDc = 'METRC_DC';
export const metrc = 'METRC';
export const isOhMetric = 'isOhMetrc';
export const isColombia = 'isColombia';
export const cure = 'CURE';
export const canada = 'CANADA';
export const leafly = 'LEAFLY';
export const biotrack = 'BIOTRACK';
