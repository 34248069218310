import {createSelector} from 'reselect';
import * as dataNames from '../../constants/dataNames';
import {ensureSelector} from '../../util/callbackHelpers';
import {getIntegrationState} from './integrationSelectors';
import {
  BT_PR_CONVERSION_PRODUCTS,
  BT_PR_END_PRODUCTS,
  BT_PR_INFUSION_RULES,
  BT_PR_PACKAGING_RULES,
  BT_PR_PROCESSING_RULES
} from '../../constants/integration/biotrack/biotrackPRConversionRules';
import {
  BT_IL_CONVERSION_PRODUCTS,
  BT_IL_END_PRODUCTS,
  BT_IL_INFUSION_RULES,
  BT_IL_PACKAGING_RULES,
  BT_IL_PROCESSING_RULES
} from '../../constants/integration/biotrack/biotrackILConversionRules';

export const getBiotrackMappings = state => state[dataNames.biotrackCategoryMappings];
export const getBiotrackCategories = state => state[dataNames.biotrackCategories];


/**
 * Get Biotrack inventory type matching the given subcategory id
 * @param {number} subcategoryId
 * @param {Array} btCategories
 * @param {Array} btMappings
 * @return {number|undefined}
 */
export function getBiotrackInventoryTypeByPlatformSubcategoryId(btCategories, btMappings, subcategoryId) {
  const btMapping = btMappings.find(mapping => mapping.subcategory_id === subcategoryId);
  const btCategory = btMapping && btCategories.find(btCategory => btCategory.id === btMapping.biotrack_category_id);
  return btCategory && Number(btCategory.inventory_type_id);
}


/**
 * Get list of MJP subcategory ids matching provided Biotrack inventory types
 * @param {Array} btCategories
 * @param {Array} btMappings
 * @param {number[]} inventoryTypes
 * @return {number[]}
 */
export function getPlatformSubcategoryIdsByBiotrackInventoryTypes (btCategories, btMappings, inventoryTypes) {
  const internalIds = inventoryTypes.map(id => {
    const btCategory = btCategories.find(c => Number(c.inventory_type_id) === id);
    return btCategory && btCategory.id;
  });

  return btMappings
    .filter(btSubcategory => internalIds.indexOf(btSubcategory.biotrack_category_id) > -1)
    .map(btSubcategory => btSubcategory.subcategory_id);
}


/**
 * Selector Creator for applicable subcategory Ids
 * @param {Function|Array} getInventoryTypes
 * @return {Function}
 */
export const getApplicablePlatformSubcategoryIds = getInventoryTypes => createSelector(
  [getBiotrackCategories, getBiotrackMappings, ensureSelector(getInventoryTypes)],
  getPlatformSubcategoryIdsByBiotrackInventoryTypes
);

/**
 * Selector creator for inventory type
 * @param {Function|Array} getSubcategoryId
 * @return {Function}
 */
export const getInventoryType = getSubcategoryId => createSelector(
  [getBiotrackCategories, getBiotrackMappings, ensureSelector(getSubcategoryId)],
  getBiotrackInventoryTypeByPlatformSubcategoryId
);

/**
 * Selector creator for inventory types by subcategory ids
 * @param {Function|Array} getSubcategoryIds
 * @return {Function}
 */
export const getInventoryTypesBySubcategoryIds = getSubcategoryIds => createSelector(
  [getBiotrackCategories, getBiotrackMappings, ensureSelector(getSubcategoryIds)],
  (btCategories, btMappings, subcategoryIds) => {
    return subcategoryIds
      .map(subcategoryId => getBiotrackInventoryTypeByPlatformSubcategoryId(btCategories, btMappings, subcategoryId))
      .filter(Boolean);
  }
);

export const addBiotrackInventoryType = getSubcategoryId => getItems => createSelector(
  [getBiotrackCategories, getBiotrackMappings, ensureSelector(getItems)],
  (btCategories, btMappings, items) => items.map((item) => {
    const subcategoryId = ensureSelector(getSubcategoryId)(item);
    return {
      ...item,
      biotrack_inventory_type: getBiotrackInventoryTypeByPlatformSubcategoryId(btCategories, btMappings, subcategoryId),
    };
  })
);

export const getPackagingOutputRules = createSelector(
  [getIntegrationState],
  ({isPrBiotrack, isIlBiotrack}) => {
    if (isPrBiotrack) {
      return BT_PR_PACKAGING_RULES;
    }
    if (isIlBiotrack) {
      return BT_IL_PACKAGING_RULES;
    }
    return {};
  }
);

export const getProcessingOutputRules = createSelector(
  [getIntegrationState],
  ({isPrBiotrack, isIlBiotrack}) => {
    if (isPrBiotrack) {
      return BT_PR_PROCESSING_RULES;
    }
    if (isIlBiotrack) {
      return BT_IL_PROCESSING_RULES;
    }
    return {};
  }
);

export const getInfusionOutputRules = createSelector(
  [getIntegrationState],
  ({isPrBiotrack, isIlBiotrack}) => {
    if (isPrBiotrack) {
      return BT_PR_INFUSION_RULES;
    }
    if (isIlBiotrack) {
      return BT_IL_INFUSION_RULES;
    }
    return {};
  }
);

export const getAssemblyInventoryTypes = createSelector(
  [getInfusionOutputRules],
  (btInfusionRules) => Object.keys(btInfusionRules).reduce(
    (types, key) => btInfusionRules[key].reduce(
      (acc, type) => acc.indexOf(type) > -1 ? acc : acc.concat(type),
      types
    ),
    []
  )
);

export const getMedicatedEndProducts = createSelector(
  [getIntegrationState],
  ({isPrBiotrack, isIlBiotrack}) => {
    if (isPrBiotrack) {
      return BT_PR_END_PRODUCTS;
    }
    if (isIlBiotrack) {
      return BT_IL_END_PRODUCTS;
    }
    return [];
  }
);

export const getPOSMedicatedSubcategoryIds = getApplicablePlatformSubcategoryIds(getMedicatedEndProducts);

const getConversionInventoryTypes = createSelector(
  [getIntegrationState],
  ({isPrBiotrack, isIlBiotrack}) => {
    if (isPrBiotrack) {
      return BT_PR_CONVERSION_PRODUCTS;
    }
    if (isIlBiotrack) {
      return BT_IL_CONVERSION_PRODUCTS;
    }
    return [];
  }
);

export const getConversionSubcategoryIds = getApplicablePlatformSubcategoryIds(getConversionInventoryTypes);
