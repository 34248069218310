/* eslint-disable import/no-named-as-default*/
import React from 'react';
import { I18n } from 'react-redux-i18n';
import get from 'lodash.get';
import find from 'lodash.find';

import {convertDbDateTimeToDaysAgo} from '../../../util/dateHelpers';
import * as p from '../../../constants/permissions';
import PermissionLink from '../../common/PermissionLink.js';
import DaysInPhaseHeaderColumn from './DaysInPhaseHeaderColumn';
import InternationalDecimalStatic from '../../common/form/InternationalDecimalStatic';
import InternationalDateTimeStatic from '../../common/form/InternationalDateTimeStatic';

const getColumns = (timezone, trackPlantsAsGroup, { isBiotrack, isColombia }, strains, cupoNumbers, stage, isServiceFirst = false) => {
  const columns = {
    propagationColumns: []
  };

  if (isColombia) {
    columns.propagationColumns.push({
      name: stage === 'inactive' ? 'plants.table.cupoInactive' : 'plants.table.cupo',
      dataId: 'cupo_id',
      hidden: false,
      formatter: (cell, row) => {
        return get(find(cupoNumbers, { id: row.cupo_id }), 'cupo_number');
      }
    });
  }

  columns.propagationColumns.push({
    name: 'plants.table.strain',
    dataId: 'strain_name',
    dataSort: !isServiceFirst,
    hidden: false
  });

  if (isColombia) {
    columns.propagationColumns.push({
      name: 'strains.typeOfCannabis',
      dataId: 'type_of_cannabis',
      hidden: false,
      scanSearchColumn: false,
      dataSort: false,
      formatter: (cell, row) => {
        const strain = strains.find((strain) => strain.id === row.strain_id);
        return strain && strain.type_of_cannabis
          ? I18n.t(`strains.edit.typeOfCannabisOptions.${strain.type_of_cannabis}`)
          : 'n/a';
      }
    });
    columns.propagationColumns.push({
      name: I18n.t('plants.form.modality'),
      dataId: 'modality',
      hidden: false,
      dataSort: true,
      scanSearchColumn: false,
      formatter: (cell, row) => (row.modality ? I18n.t(`modalities.list.${row.modality}`) : ''),
      width: '150px'
    });
  }
  columns.propagationColumns.push(
    {
      name: 'plants.table.phenotype',
      dataId: 'phenotype_name',
      dataSort: !isServiceFirst,
      hidden: false
    },
    {
      name: trackPlantsAsGroup ? 'plants.table.groupId' : 'plants.table.plantId',
      columnClassName: 'no-ellipsis',
      dataId: 'plant_id',
      hidden: false,
      scanSearchColumn: true
    },
    {
      name: 'plants.table.quantity',
      dataId: 'qty',
      hidden: false,
      width: '50px'
    },
    {
      name: 'plants.table.batch',
      columnClassName: 'no-ellipsis',
      dataId: 'batch_name',
      dataSort: !isServiceFirst,
      hidden: false,
      scanSearchColumn: true
    },
    {
      name: 'plants.table.type',
      dataId: 'type',
      hidden: false
    },
    {
      name: 'plants.table.isMother',
      dataId: 'is_mother',
      hidden: !isBiotrack,
      formatter: (val) => I18n.t(`cultivation.complianceSettings.${val ? 'yes' : 'no'}`),
      width: '50px'
    },
    {
      name: 'plants.table.location',
      removeForStage: ['inactive'],
      dataId: 'location_name',
      dataSort: !isServiceFirst,
      hidden: false
    },
    {
      name: 'plants.table.plantingDate',
      dataId: 'planting_date',
      hidden: false,
      formatter: (cell, row) => <InternationalDateTimeStatic useSystemDate={false} displayTime={false}>{cell}</InternationalDateTimeStatic>
    },
    {
      name: 'plants.table.daysInPhase',
      removeForStage: ['inactive'],
      dataId: 'last_stage_move_date',
      hidden: false,
      width: '80px',
      formatter: (cell, row) => convertDbDateTimeToDaysAgo(cell, timezone),
      childComponent: DaysInPhaseHeaderColumn
    },
    {
      name: 'plants.table.wasteReported',
      removeForStage: ['inactive'],
      dataId: 'waste_reported',
      dataSort: !isServiceFirst,
      hidden: false,
      /* eslint-disable react/no-multi-comp */
      formatter: (cell, row) => <InternationalDecimalStatic fractionPartSize={2} suffix={' GR'}>{cell}</InternationalDecimalStatic>,
    },
    {
      name: 'plants.table.motherPlant',
      columnClassName: 'no-ellipsis',
      dataId: 'mother_plant_name',
      permissions: [p.manage_plant_lineage],
      scanSearchColumn: true
    },
    {
      name: 'common.history',
      dataId: 'history',
      hidden: false,
      dataSort: false,
      permissions: [p.view_plant_history],
      /* eslint-disable react/no-multi-comp */
      formatter: (cell, row) => {
        // eslint-disable-line react/display-name
        return (
          <PermissionLink
            permissions={[p.view_plant_history]}
            props={{
              title: I18n.t('common.history'),
              to: `/plants/${row.id}/history`,
              className: 'btn btn-default',
              target: '_blank',
              onClick: (event) => {
                // stop click on row
                event.stopPropagation();
              }
            }}
          >
            {I18n.t('common.history')}
          </PermissionLink>
        );
      }
    }
  );
  return columns;
};

export default getColumns;
