import { change, formValueSelector } from 'redux-form';
import get from 'lodash.get';
import { PLANT_SPLIT_FORM } from '../../constants/forms';
import { isChangeActionOnField, isChangeAction, isArrayPush, isFormInitializing, isArrayRemove } from './utils';
import { getComplianceSettings } from '../../selectors/complianceSettingsSelectors';

const plantSplitForm = (store) => (next) => (action) => {
  const result = next(action);
  if (
    (isChangeAction(action, [PLANT_SPLIT_FORM]) ||
      isArrayPush(action, [PLANT_SPLIT_FORM]) ||
      isArrayRemove(action, [PLANT_SPLIT_FORM]) ||
      isFormInitializing(action, [PLANT_SPLIT_FORM])) &&
    !isChangeActionOnField(action, [PLANT_SPLIT_FORM], 'newQty')
  ) {
    const state = store.getState();
    const trackPlantsAsGroups = get(getComplianceSettings(state), 'cult_track_plants_as_groups', false);
    const selector = formValueSelector(PLANT_SPLIT_FORM);
    const currentQty = selector(state, 'qty') || 0;
    const splits = selector(state, 'splits');
    const usedQty = splits.reduce((acc, split) => {
      const multiplier = trackPlantsAsGroups ? split.number_of_groups : 1;
      if ([multiplier, split.qty].every((item) => !isNaN(parseInt(item)))) acc += parseInt(split.qty) * multiplier;
      return acc;
    }, 0);
    store.dispatch(change(PLANT_SPLIT_FORM, 'newQty', currentQty - usedQty));
  }

  return result;
};

export default plantSplitForm;
