import React from 'react';
import PropTypes from 'prop-types';
import {FieldArray} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';

import DenominationBlock from './DenominationBlock';
import EmployeeCountFields from './EmployeeCountFields';
import VerificationWithoutEmployeeCountFields from './VerificationWithoutEmployeeCountFields';
import VerificationWithResetBalanceFields from './VerificationWithResetBalanceFields';
import VerificationWithoutResetBalanceFields from './VerificationWithoutResetBalanceFields';
import SubmitSection from '../../common/form/SubmitSection';

import MODES from '../../../constants/registerReconcliliationModes';


const fieldsComponentMap = {
  EmployeeCount: EmployeeCountFields,
  VerificationWithoutEmployeeCount: VerificationWithoutEmployeeCountFields,
  VerificationWithResetBalance: VerificationWithResetBalanceFields,
  VerificationWithoutResetBalance: VerificationWithoutResetBalanceFields
};


const RegisterReconciliationForm = (props) => {
  const {
    reset,
    change,
    actions,
    pristine,
    isSaving,
    onCancel,
    handleSubmit,
    dropRegisters,
    pageConfiguration,
    formValues,
    closingScenario,
    mode
  } = props;

  const submitSection = {
    actionSettings: {
      reset: {
        text: I18n.t('common.form.reset'),
        action: reset,
        style: 'default',
        disabled: (pristine || isSaving),
      },
      cancel: {
        text: I18n.t('common.general.cancel'),
        action: () => onCancel(pristine),
        style: 'default',
        disabled: isSaving,
      },
      print: {
        text: I18n.t('common.actions.saveAndPrint'),
        action: handleSubmit(actions.saveAndPrint),
        style: 'primary',
        disabled: (pristine || isSaving),
      },
      submit: {
        text: I18n.t('common.form.save'),
        action: handleSubmit(actions.save),
        style: 'primary',
        disabled: (pristine || isSaving),
      }
    },
    align: 'right'
  };

  const submitSectionReadOnly = {
    actionSettings: {
      goBack: {
        text: I18n.t('common.actions.goBack'),
        action: () => onCancel(true),
        style: 'default'
      },
      print: {
        text: I18n.t('common.print'),
        action: () => alert('Print is not yet implemented'),
        style: 'default',
      },
    },
    align: 'right'
  };

  /**
   * Determine whether it's the Employee Count Scenario
   *
   * @returns {boolean}
   */
  const isCountScenario = () => mode === MODES.COUNT;

  /**
   * Determine whether it's the Verification Scenario
   *
   * @returns {boolean}
   */
  const isVerifyScenario = () => mode === MODES.VERIFICATION;

  /**
   * Updates the proper total, drop, over_short, and any other fields that need to be recalculated when the counts on any denominations are changed
   *
   * @param newValue
   * @param fieldIndex
   */
  const onChangeDenomination = (newValue, fieldIndex) => {
    const fieldNamePrefix = isCountScenario() ? 'counted' : 'verified';
    let denominationsTotal = 0;
    const {denominationCounts, reset_balance_to, balance_before_closing, counted_total, counted_drop} = formValues;

    denominationCounts && denominationCounts.forEach((item, index) => {
      const denomination = item.value;
      const isUpdatedIndex = index === fieldIndex;
      const count = isUpdatedIndex ?
        newValue :
        (isCountScenario() ?
          item.employeeCount :
          item.verifiedCount
        );

      if (!count) return;

      denominationsTotal += (denomination * parseFloat(count));
    });

    const total =
      isVerifyScenario() && counted_drop ?
        denominationsTotal + parseFloat(reset_balance_to) :
        denominationsTotal;
    const overShort = total - balance_before_closing;

    change(`${fieldNamePrefix}_total`, total.toFixed(2));
    change(`${fieldNamePrefix}_over_short`, overShort.toFixed(2));

    if (reset_balance_to) {
      const drop = total - reset_balance_to;
      change(`${fieldNamePrefix}_drop`, drop.toFixed(2));
    }

    if (isVerifyScenario() && counted_total) {
      const countedVerifiedDifference = total - counted_total;
      change('counted_verified_difference', countedVerifiedDifference.toFixed(2));
    }
  };

  /**
   * Updates the appropriate drop field with a recalculation
   *
   * @param newValue
   */
  const onChangeResetBalanceTo = (newValue) => {
    const {counted_total, verified_total} = formValues;
    const total = isVerifyScenario() ? verified_total : counted_total;
    const drop = total - newValue;
    const dropFieldName = `${isVerifyScenario() ? 'verified' : 'counted'}_drop`;

    change(dropFieldName, drop.toFixed(2));
  };

  const {columns, resetAndDrop, isActionsAvailable} = pageConfiguration;
  const FieldsComponent = fieldsComponentMap[closingScenario];

  return (
    <div className='facilities-form'>
      <div className='closing-till-note'>{I18n.t('retail.registerReconciliation.note')}</div>
      <form>
        <div className='closing-till-block'>
          <Row>
            <Col xs={8} sm={8} md={8}>
              <FieldArray
                name='denominationCounts'
                component={DenominationBlock}
                props={{columns}}
                change={change}
                onChangeDenomination={onChangeDenomination}
              />
            </Col>
            <Col xs={4} sm={4} md={4} className='count-verification-column'>
              <Row className='closing-till-block-header' />
              <hr />
              <FieldsComponent
                dropRegisters={dropRegisters}
                onChangeResetBalanceTo={onChangeResetBalanceTo}
                resetAndDrop={resetAndDrop}
              />
              <hr />
            </Col>
          </Row>
        </div>
        <hr />
        <SubmitSection
          settings={isActionsAvailable ? submitSection : submitSectionReadOnly}
        />
      </form>
    </div>
  );
};

RegisterReconciliationForm.propTypes = {
  mode: PropTypes.string.isRequired,
  pageConfiguration: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dropRegisters: PropTypes.array.isRequired,
  isSaving: PropTypes.bool,
  reset: PropTypes.func,
  change: PropTypes.func,
  onCancel: PropTypes.func,
  pristine: PropTypes.bool,
  actions: PropTypes.object.isRequired,
};

export default RegisterReconciliationForm;
