import {createSelector} from 'reselect';

import * as dataNames from '../constants/dataNames';
import getCurrentFacility from './facility/getCurrentFacility';

const getCategoryId = (_, params) => params.categoryId;

export const getTaxes = state => state[dataNames.taxes];

export const getCategoryTaxRate = createSelector(
  [getTaxes, getCategoryId, getCurrentFacility],
  (taxes, categoryId, currentFacility) => {
    return taxes.length && currentFacility 
      ? taxes.filter(tax => Array.isArray(tax.facility_ids) ? ~tax.facility_ids.indexOf(currentFacility.id) : false)
        .reduce((rate, tax) => tax.item_category_ids && tax.item_category_ids.length && tax.item_category_ids.indexOf(categoryId) > -1
          ? rate + Number(tax.amount) : rate, 0)
      : 0;
  }
);
