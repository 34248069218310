import isEmpty from 'lodash.isempty';
import {createSelector} from 'reselect';
import map from 'lodash.map';
import find from 'lodash.find';
import filter from 'lodash.filter';
import forEach from 'lodash.foreach';
import round from 'lodash.round';
import intersection from 'lodash.intersection';
import get from 'lodash.get';
import {formValueSelector} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import moment from 'moment';
import * as dataNames from '../constants/dataNames';
import * as itemNames from '../constants/itemNames';
import * as productionRunTypes from '../constants/productionRunTypes';
import * as statuses from '../constants/statuses';
import * as UOMS from '../constants/uoms';
import {START_INFUSION_FORM, COMPLETE_INFUSION_FORM} from '../constants/forms';
import {convertDbTimeToClientTzMoment} from '../util/dateHelpers';
import {getAssembly, getAssemblies} from './assembliesSelectors';
import {getFoundItemOptions} from './checkoutScanSelectors';
import {getIngredientsWithCosting} from './ingredientsSelectors';
import {isReserved, isOnHold, isAvailable} from './inventoryItemsSelectors';
import {getIntegrationState} from './integration/integrationSelectors';
import {getInfusionOutputRules, getInventoryType} from './integration/biotrackCategoriesSelectors';
import {isWeight, isVolume, isMedicated, isIngredient, getPackageId} from './itemMastersSelectors';
import {getItemOptions, isSelectedOption} from './processingSelectors';
import {getCurrentFacilityUserOptions} from './usersSelectors';
import {getSelectedProducts} from './productsSelectors';
import {getProductionRunPackageCodes, mapProductionJobForListing} from './productionRunsSelectors';
import {biotrackItemHasValidLabResult, getTestResults} from './testResultsSelectors';
import {CANNABINOID_POTENCY} from '../constants/potencies';
import {nonQASampleInventoryTypes} from '../constants/integration/biotrack/biotrackInventoryTypes';
import {getFlattenedLocations, getLocationsForSharedProducts} from './locationsSelectors';
import {getBiotrackInvTypesWithTitles} from './integration/biotrackSelectors';
import {convertFromBase} from '../util/uomHelpers';
import {getItemsAvailability} from './itemsAvailabilitySelectors';
import {getIsLabelEnabledForNoCompletedProcess, isAllowNegativeInventory} from './complianceSettingsSelectors';
import {formatValueByUOM} from '../util/uomHelper';
import {hasPackagesTags} from './integrationSelectors';
import {displayQty, roundQty} from './uomsSelectors';

export const getAssemblyJobs = (state) => state[dataNames.assemblyJobs];
export const getAssemblyJob = (state) => state[itemNames.infusion];
export const getItemMaster = (state) => state[itemNames.itemMaster];
const getInventoryItems = (state) => state.inventoryItems;
const getInfusionJob = (state) => state.infusion;
const getReservations = (state) => state[dataNames.reservations];

const {EA, UOM_VALUE_PRECISION} = UOMS;

const getInventoryItemsWithReservation = createSelector(
  [getInventoryItems, getReservations],
  (items, reservations) =>
    items.map((item) => ({
      ...item,
      reservations: reservations.filter((reservation) => reservation.item_id === item.id)
    }))
);

const startInfusionFormSelector = formValueSelector(START_INFUSION_FORM);
export const getStartInfusionFormInputs = (state) => startInfusionFormSelector(state, 'inputs');
export const getSelectedAssemblyId = (state) => startInfusionFormSelector(state, 'assembly_id');
export const getSelectedAssemblyQty = (state) => startInfusionFormSelector(state, 'assembly_qty');

const completeInfusionFormSelector = formValueSelector(COMPLETE_INFUSION_FORM);
export const getFormInputs = (state) => completeInfusionFormSelector(state, 'inputs');

const getCurrentTimezone = (state) => state.timezone;

export const getActiveAssemblyJobs = createSelector(
  [getAssemblyJobs, getCurrentFacilityUserOptions],
  (jobs, users) => {
    return jobs
      .filter((job) => job.run_type === productionRunTypes.assembly && job.status === statuses.open)
      .map((job) => mapProductionJobForListing(job, users));
  }
);

export const getCompleteInfusionInitialValues = createSelector(
  [
    getAssemblyJob,
    getAssembly,
    getItemMaster,
    getItemOptions,
    getIngredientsWithCosting,
    getCurrentTimezone,
    getIntegrationState,
    getPackageId,
    getIsLabelEnabledForNoCompletedProcess,
    hasPackagesTags,
    displayQty,
    roundQty
  ],
  (
    assemblyJob,
    assembly,
    itemMaster,
    itemOptions,
    ingredients,
    timezone,
    integrationState,
    itemMasterPackageId,
    isLabelEnabledForNoCompletedProcess,
    packagesTags,
    displayQty,
    roundQty
  ) => {

    if (!assemblyJob.id || !assembly.id || !assemblyJob.employees) {
      return {
        outputs: [],
        inputs: [],
        ingredients: [],
        equipments: [],
        employees: []
      };
    }

    const initialValues = {
      ...assemblyJob,
      employees: assemblyJob.employees.map((employee) => employee.user_id),
      employee: assemblyJob.employees.reduce((acc, employee) => {
        if (acc > 0) return acc;
        return employee.user_id;
      }, 0),
      equipments: assemblyJob.equipment.map((equipment) => equipment.equipment.id),
      expected_completion_time: convertDbTimeToClientTzMoment(assemblyJob.expected_completion_time, timezone),
      actual_completion_time: convertDbTimeToClientTzMoment(assemblyJob.expected_completion_time, timezone),
      assemblyName: assembly.name,
      ingredientsStandardCost: assembly.ingredients
        .reduce((acc, material) => {
          const ingredient = ingredients.find((i) => i.id === material.item_master_id);
          return (
            acc +
            (ingredient && ingredient.averageCost
              ? formatValueByUOM(material.uom, material.qty, ingredient.averageCost, ingredient.averageCostUOM)
              : 0)
          );
        }, 0)
        .toFixed(UOM_VALUE_PRECISION)
    };

    initialValues.inputs = assemblyJob.inputs.reduce((acc, input) => {
      const itemOption = itemOptions.find((io) => io.item_id === input.item_id);
      const isIngredient = assembly.ingredients.some((i) => i.item_master_id === input.item_master_id);
      let qtyOnHand = 0;

      if (itemOption) {
        const invReservation = find(itemOption.reservations, {entity_id: assemblyJob.id}) || {};
        qtyOnHand = itemOption.maxQty;
        if (invReservation.uom_display) {
          qtyOnHand += convertFromBase(invReservation.qty_base, invReservation.uom_display);
        }
      }
      if (!isIngredient && itemOption) {
        acc.push({
          ...itemOption,
          item_id: get(input, 'item_id', ''),
          qty: Number(input.qty).toFixed(2),
          id: input.id,
          maxQty: round(qtyOnHand, 2),
        });
      }
      return acc;
    }, []);

    if (assemblyJob.status === statuses.completed) {
      initialValues.outputs = (assemblyJob.outputs || []).map((output, index) => ({
        ...output,
        item_master_id: itemMaster.id,
        productName: itemMaster.name,
        qty: parseInt(output.qty),
        packaged_at: moment.utc(),
        package_created_at: moment.utc(),
        package_expires_at: null,
        medically_compliant:
          initialValues.inputs[index] && initialValues.inputs[index]['medically_compliant_status'] === 'yes'
      }));

      initialValues.ingredients = assemblyJob.inputs.reduce((acc, input) => {
        const ingredient = ingredients.find((i) => i.id === input.item_master_id);
        const material = assembly.ingredients.find((m) => m.item_master_id === input.item_master_id);
        if (material && ingredient) {
          acc.push({
            ...input,
            name: ingredient.name,
            qtyPlanned: displayQty(material.qty),
            unitCost: (ingredient && ingredient.averageCost) || 0
          });
        }
        return acc;
      }, []);
    } else {
      initialValues.inputs = assemblyJob.inputs.map((input) => {
        const itemOption = itemOptions.find((io) => io.item_id === input.item_id) || {};
        const invReservation = find(itemOption.reservations, {entity_id: assemblyJob.id}) || {};

        let qtyOnHand = itemOption.maxQty;
        if (invReservation.uom_display) {
          qtyOnHand += convertFromBase(invReservation.qty_base, invReservation.uom_display);
        }

        return {
          ...itemOption,
          qty: roundQty(input.qty),
          maxQty: displayQty(qtyOnHand),
          id: input.id
        };
      });

      const isNoMedicallyCompliant = initialValues.inputs.find((input) => {
        return input.medically_compliant_status !== 'yes';
      });
      const itemMasterUom = get(itemMaster, 'default_uom', UOMS.GR );
      const defaultUomForSelectables = itemMasterUom !== EA ? itemMasterUom : UOMS.GR;

      initialValues.outputs = get(assemblyJob, 'outputs.length', 0) === 0
        ? [{
          item_master_id: itemMaster.id,
          qty: parseInt(assemblyJob.assembly_qty),
          uom: itemMaster.default_uom,
          medicated_weight: itemMaster.medicated_weight,
          productName: itemMaster.name,
          packaged_at: convertDbTimeToClientTzMoment(assemblyJob.expected_completion_time, timezone),
          package_code: !isLabelEnabledForNoCompletedProcess ? null : itemMasterPackageId,
          package_created_at: moment.utc(assemblyJob.expected_completion_time), // NO TZ required
          package_expires_at: null,                                             // NO TZ required
          medically_compliant: isNoMedicallyCompliant ? 0 : 1,
          tag_requested: packagesTags ? 1 : 0,
          usable_mmj_weight_uom: defaultUomForSelectables,
          concentrate_weight_uom: defaultUomForSelectables,
        }]
        : get(assemblyJob, 'outputs', []).map((output, index) => ({
          ...output,
          //batchName: processingJob.name,
          item_master_id: itemMaster.id,
          medicated_weight: itemMaster.medicated_weight,
          uom: output.uom,
          productName: itemMaster.name,
          tag_requested: packagesTags ? 1 : 0,
          qty: roundQty(output.qty),
          medically_compliant: initialValues.inputs[index] && initialValues.inputs[index]['medically_compliant_status'] === 'yes',
          packaged_at: convertDbTimeToClientTzMoment(assemblyJob.expected_completion_time, timezone),
          package_created_at: moment.utc(assemblyJob.start_time),                 // NO TZ required
          package_expires_at: moment.utc(assemblyJob.expected_complection_time),  // NO TZ required
          package_code: output.package_code,
        }));

      initialValues.ingredients = assembly.ingredients.map((material) => {
        const ingredient = ingredients.find(i => i.id === material.item_master_id) || {};
        const input = assemblyJob.inputs.find(input => input.item_master_id === ingredient.id);
        return {
          id: get(input, 'id', null),
          item_master_id: material.item_master_id,
          item_id: get(input, 'item_id', ''),
          name: get(ingredient, 'name', ''),
          qtyPlanned: displayQty(material.qty),
          qty: input ? roundQty(input.qty) : roundQty(material.qty),
          unitCost: get(ingredient, 'averageCost', 0),
          unitUOM: get(ingredient, 'averageCostUOM'),
          uom: material.uom,
        };
      });

      if (integrationState.isBiotrack) {
        // TODO Remove it when waste field will come back to infusion form
        const MANUALLY_REPORTED_WASTE = 0;
        initialValues.waste = calculateWaste(initialValues.inputs, MANUALLY_REPORTED_WASTE, initialValues.outputs[0], roundQty);
      }
      initialValues.notes = get(assemblyJob, 'notes', []).sort((a, b) => {
        return a.id < b.id ? -1 : 1;
      });
      initialValues.waste = roundQty(get(assemblyJob, 'waste', 0)) || '';
      initialValues.waste_uom = get(assemblyJob, 'waste_uom', UOMS.GR);
    }

    return initialValues;
  }
);

/**
 *
 * @param inputItems - incoming items for building a new item
 * @param waste - waste which was menually entered by user from Infusion form
 * @param outputItem - item which should be created by infusion
 * @returns {number}
 */
export const calculateWaste = (inputItems, waste, outputItem, roundFunc) => {
  const medNetWeight = convertFromBase(outputItem.medicated_weight, I18n.t('uoms.MG.abbrev'));
  const inputQty = map(inputItems, 'qty').reduce(
    (accumulator, value) => parseFloat(accumulator) + parseFloat(value),
    0
  );
  const result = inputQty - waste - outputItem.qty * medNetWeight;

  return roundFunc(result);
};

export const getStartInfusionInitialValues = createSelector(
  [getSelectedProducts, getItemOptions, isAllowNegativeInventory, getProductionRunPackageCodes],
  (items, itemOptions, isAllowedNegativeInventory, productionRunPackageCodes) => {
    const values = {
      employee_ids: [],
      equipment_ids: [],
      productionRunPackageCodes
    };

    if (items.length) {
      values.inputs = items.reduce((acc, item) => {
        const itemOption = itemOptions.find((io) => io.item_id === item.item_id);
        if (isValidInfusionOption(itemOption, undefined, isAllowedNegativeInventory)) {
          acc.push({
            item_id: item.item_id,
            ...itemOption,
            qty: 0 // itemOption.qty > 0 ? itemOption.qty : 0
          });
        }
        return acc;
      }, []);
    } else {
      values.inputs = [];
    }
    values.start_time = moment();
    return values;
  }
);

export const getInfusionPotencyTableData = createSelector(
  [getStartInfusionFormInputs, getTestResults, getAssemblies, getSelectedAssemblyId, getSelectedAssemblyQty, roundQty],
  (inputs, testResults, assemblies, assemblyId, assemblyQty, roundQty) => {
    inputs = inputs ? inputs.filter((input) => !isEmpty(input)) : [];

    const required = {};
    const results = {};
    const totals = {};

    const assembly = assemblies.find((item) => item.id === assemblyId);

    const batches = inputs.map((input) => {
      const testResult = testResults.find((testResult) => testResult.package_id === input.package_id);
      const batchTestResult = {};
      const packageTestResult = {};

      CANNABINOID_POTENCY.forEach((key) => {
        if (testResult) {
          const valueMg = parseFloat(testResult[`${key}_mg_g`]) || 0;
          const valuePercent = parseFloat(testResult[`${key}_percent`]) || 0;

          packageTestResult[key] = valueMg > 0 ? valueMg : valuePercent * 10;

          batchTestResult[key] = valueMg > 0 ? valueMg : valuePercent * 10;
          batchTestResult[key] *= parseFloat(input.qty || 0);
          batchTestResult[key] = +roundQty(batchTestResult[key]) || 0;
        } else {
          batchTestResult[key] = 0;
        }
      });

      return {
        ...batchTestResult,
        packageTestResult,
        optionName: input.optionName
      };
    });

    const batchesResults = batches.reduce((all, batch) => {
      CANNABINOID_POTENCY.forEach((key) => {
        all[key] = (all[key] || 0) + batch[key];
      });

      return all;
    }, {});

    if (assembly) {
      CANNABINOID_POTENCY.forEach((key) => {
        required[key] = parseFloat(assembly[`potency_${key}`]).toFixed(UOM_VALUE_PRECISION) * assemblyQty;
        results[key] = batchesResults[key] >= required[key];
        totals[key] = batchesResults[key];
        return required;
      });
    }

    return {batches, required, results, totals};
  }
);

/**
 * Returning a list of valid output inventory types that we will be able to use
 * in time
 * @type {Reselect.Selector<any, Array> | Reselect.Selector<any, any>}
 */
export const getInfusionInvTypes = createSelector(
  [getInfusionOutputRules, getItemOptions, getBiotrackInvTypesWithTitles],
  (BT_INFUSION_RULES, lotItems, inventoryTypes) => {
    const rules = [];
    forEach(lotItems, ({integration_type, ...props}) => {
      rules.push(BT_INFUSION_RULES[parseInt(integration_type)] || []);
    });

    const outputInvTypes = intersection(...rules);

    return filter(inventoryTypes, ({value}) => outputInvTypes.includes(value)) || [];
  }
);

export const getAssemblyItemOptions = createSelector(
  [getFoundItemOptions, getStartInfusionFormInputs, getIntegrationState],
  (options, inputs, integrationState) =>
    options.filter((option) => isValidInfusionOption(option, integrationState) && !isSelectedOption(option, inputs))
);

export function isValidInfusionOption(option, integrationState = {}, isAllowNegativeInventory = false) {
  return (
    option &&
    !isReserved(option) &&
    !isOnHold(option) &&
    isAvailable(option, isAllowNegativeInventory) &&
    !isIngredient(option.itemMaster) &&
    (isWeight(option.itemMaster) || isVolume(option.itemMaster)) &&
    isMedicated(option.itemMaster) &&
    (!integrationState.isBiotrack || biotrackItemHasValidLabResult(option, integrationState.isPrBiotrack))
  );
}

export const getSplitPackageSourceUomType = createSelector(
  [getSelectedProducts],
  (products) => {
    const selectedProduct = products && products.length !== 0 && products[0];
    return selectedProduct.uom_type;
  }
);

export const getSelectedProduct = createSelector(
  getSelectedProducts,
  (products) => products[0] || {}
);
const getExistingQaSamples = createSelector(
  [getSelectedProduct, getInventoryItems],
  (product, items) => {
    return items.filter((item) => item.is_test_package && item.parent_package_id === product.package_id);
  }
);
const getSelectedProductSubcategoryId = createSelector(
  getSelectedProduct,
  (product) => product && product.subcategory_id
);
const getSelectedProductInventoryType = getInventoryType(getSelectedProductSubcategoryId);
export const isQASampleSplitDisabled = createSelector(
  [getSelectedProductInventoryType, getExistingQaSamples, getIntegrationState],
  (type, samples, {isIlBiotrack}) =>
    nonQASampleInventoryTypes.indexOf(type) > -1 && (!isIlBiotrack || !samples.length)
);

export const getSplitPackageInitialValues = createSelector(
  [getSelectedProduct, getIntegrationState],
  (selectedProduct, integrationState) => {
    const dateFields = {};
    const fields = ['packaged_at', 'package_created_at', 'package_expires_at'];
    fields.forEach((field) => {
      // Plain jane dates.  These do not need time so no timezone
      dateFields[field] = selectedProduct && selectedProduct[field] ? moment.utc((selectedProduct[field])) : null;
    });

    const initialValues = {
      package_id: selectedProduct && selectedProduct.id ? selectedProduct.package_code : '',
      current_location: selectedProduct && selectedProduct.id ? selectedProduct.location_name : '',
      qty: selectedProduct && selectedProduct.id ? selectedProduct.qty : '',
      new_packages: [
        Object.assign(
          {},
          {
            packaged_at: dateFields.packaged_at,
            package_created_at: dateFields.package_created_at,
            package_expires_at: dateFields.package_expires_at,
            is_test_package: selectedProduct && selectedProduct.is_test_package,
            tag_requested: 1,
            number_of_splits: 1,
          },
          integrationState.isCanada ? {finished: 1} : {}
        )
      ],
      source_item_id: selectedProduct && selectedProduct.id ? selectedProduct.id : null,
      item_master: selectedProduct && selectedProduct.id ? selectedProduct.name : '',
      itemMaster: selectedProduct,
      qty_available: selectedProduct && selectedProduct.id ? selectedProduct.qty - selectedProduct.qty_reserved : '',
      remainder: selectedProduct && selectedProduct.id ? selectedProduct.qty - selectedProduct.qty_reserved : '',
      phase_id: selectedProduct && selectedProduct.phase_id ? selectedProduct.phase_id : '',
      lab_results_id: selectedProduct && selectedProduct.lab_results_id ? selectedProduct.lab_results_id : null,
      packaged_at: dateFields.packaged_at,
      package_created_at: dateFields.package_created_at,
      package_expires_at: dateFields.package_expires_at,
    };

    // this is to adjust for the difference in payload that depends on if the item was selected from the previous page,
    // or fetched using a package_id
    initialValues.item_master = initialValues.item_master ? initialValues.item_master : get(selectedProduct, 'item_name', '');
    initialValues.qty_available = !isNaN(initialValues.qty_available) ? initialValues.qty_available : get(selectedProduct, 'qty') - convertFromBase(get(selectedProduct, 'qty_base_reserved', 0), get(selectedProduct,'uom_display', 'GR'));
    initialValues.remainder =  initialValues.qty_available;
    initialValues.phase_id = initialValues.phase_id ? initialValues.phase_id : get(selectedProduct, 'manufacturing_phase_id');

    return initialValues;
  }
);

export const getSplitPackageLocations = createSelector(
  [getSelectedProduct, getFlattenedLocations, getLocationsForSharedProducts],
  (selectedProduct, locations, sharedLocations) => {
    const item_master_is_shared = !!(selectedProduct && selectedProduct.is_shared_item);
    return item_master_is_shared ? sharedLocations : locations;
  }
);

export const infusionCanBeCompleted = createSelector(
  [
    getInventoryItemsWithReservation,
    getInfusionJob,
    getItemsAvailability,
    getAssemblyJob
  ],
  (
    inventoryItems,
    processingJob,
    itemsAvailability,
    assemblyJob
  ) => {
    return inventoryItems.reduce((acc, item) => {
      if (!item.reservations || !Array.isArray(item.reservations) || (item.reservations && !item.reservations.length)) {
        const itemAvailability = itemsAvailability.find((availability) => availability.item_id === item.id);
        const input = get(assemblyJob, 'inputs', []).find((input) => input.item_id === item.id);

        //If we don't have any reservations for this item but available qty is sufficient enough we're OK
        //If we don't have any reservations for this item we're not OK
        return !!(itemAvailability && (input && input.qty <= itemAvailability.qty_available));
      }

      //If we have reservations but they're not owned by this job, we're not OK
      return item.reservations.some(
        (reservation) => reservation.entity_type === 'infusion_run' && processingJob.id === reservation.entity_id
      );
    }, true);
  }
);

export const getAssemblyProductIds = createSelector(
  [getAssemblies],
  (assemblies) =>
    assemblies.reduce(
      (itemMasterIds, assembly) =>
        itemMasterIds.indexOf(assembly.item_master_id) > -1
          ? itemMasterIds
          : itemMasterIds.concat(assembly.item_master_id),
      []
    )
);

/**
 * 5. Filter assemblies based on output product subcategory id
 * @return {Array}
 */
export const getAssemblyOptions = createSelector(
  [getAssemblies],
  (assemblies) => assemblies
);
