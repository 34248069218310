import React from 'react';
import PropTypes from 'prop-types';
import {Table} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import QuantityByUomInput from '../../common/form/QuantityByUomInput';
import {getTranslationLabel} from '../../../util/translationHelpers';

const getLabel = getTranslationLabel('harvest.form.');

const IndividualPlantInformationNoRating = ({fields, selectedPlants, use_overall, tracking, integrationState,
  trackPlantsAsGroups, uom}) => {
  const {isWaLeaf} = integrationState;
  const {isMetrc} = integrationState;
  const quantityFieldCommonProps = {
    max: 999999,
    min: 0,
    uom,
    placeholder: I18n.t('plants.form.quantityPlaceholder')
  };

  return (
    <Table className='harvest-plants-table reduced-table' striped hover>
      <thead>
      <tr>
        <th>
          {I18n.t(trackPlantsAsGroups ? 'harvest.form.plantGroupId' : 'harvest.form.plantId')}
        </th>
        {isMetrc && use_overall ? <th>
          {I18n.t('harvest.form.metrcId')}
        </th> : null}
        {tracking ? <th>
          {I18n.t('harvest.form.additionalTracking')}
        </th> : null}
        <th className='align-center wet-weight'>
          {getLabel('totalWetWeight', integrationState)}
        </th>
        <th className='align-center waste'>
          {I18n.t('harvest.form.waste')}
        </th>
        <th className='align-center wet-weight'>
          {getLabel('remainingWetWeight', integrationState)}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr className='overall-values plant-row' hidden={use_overall}>
        <td>
          <label>{I18n.t('plants.form.overallValues')}</label>
        </td>
        {tracking ? <td /> : null}
        <td className='align-center wet-weight'>
          <QuantityByUomInput
            {...quantityFieldCommonProps}
            name={`wet_weight_harvest`}
          />
        </td>
        {isWaLeaf ? <td className='align-center wet-weight'>
          <QuantityByUomInput
            {...quantityFieldCommonProps}
            name={`wet_other_material_weight`}
          />
        </td> : null}
        <td className='align-center waste'>
          <QuantityByUomInput
            {...quantityFieldCommonProps}
            name={`total_waste_recorded`}
          />
        </td>
        {isWaLeaf ? <td className='align-center waste'>
          <QuantityByUomInput
            {...quantityFieldCommonProps}
            name={`total_other_material_waste`}
          />
        </td> : null}
        <td className='align-center wet-weight'>
          <QuantityByUomInput
            {...quantityFieldCommonProps}
            name={`remaining_wet_weight_harvest`}
          />
        </td>
        {isWaLeaf ? <td className='align-center wet-weight'>
          <QuantityByUomInput
            {...quantityFieldCommonProps}
            name={`remaining_wet_other_material_weight`}
          />
        </td> : null}
      </tr>

      {use_overall ? fields.map((plant, index) => {
        return (<tr key={index} className='plant-row'>
        <td>{selectedPlants[index].plant_id}</td>
        {isMetrc ? <td title={selectedPlants[index].state_integration_tracking_id}>{selectedPlants[index].state_integration_tracking_id?'...'+selectedPlants[index].state_integration_tracking_id.substr(selectedPlants[index].state_integration_tracking_id.length - 5):null}</td> : null}
        {tracking ? <td>{selectedPlants[index].tracking_id}</td> : null}
        <td className='align-center wet-weight'>
          <QuantityByUomInput
            {...quantityFieldCommonProps}
            name={`${plant}.wet_weight_harvest`}
          />
        </td>
        {isWaLeaf ? <td className='align-center wet-weight'>
          <QuantityByUomInput
            {...quantityFieldCommonProps}
            name={`${plant}.wet_other_material_weight`}
          />
        </td> : null}
        <td className='align-center waste'>
          <QuantityByUomInput
            {...quantityFieldCommonProps}
            name={`${plant}.total_waste_recorded`}
          />
        </td>
        {isWaLeaf ? <td className='align-center waste'>
          <QuantityByUomInput
            {...quantityFieldCommonProps}
            name={`${plant}.total_other_material_waste`}
          />
        </td> : null}
        <td className='align-center wet-weight'>
          <QuantityByUomInput
            {...quantityFieldCommonProps}
            name={`${plant}.remaining_wet_weight_harvest`}
          />
        </td>
        {isWaLeaf ? <td className='align-center wet-weight'>
          <QuantityByUomInput
            {...quantityFieldCommonProps}
            name={`${plant}.remaining_wet_other_material_weight`}
          />
        </td> : null}
      </tr>);}) : null}
      </tbody>
    </Table>
  );
};

IndividualPlantInformationNoRating.propTypes = {
  use_overall: PropTypes.bool,
  fields: PropTypes.object.isRequired,
  selectedPlants: PropTypes.array.isRequired,
  change: PropTypes.func.isRequired,
  tracking: PropTypes.bool.isRequired,
  integrationState: PropTypes.object.isRequired,
  remaining_wet_weight_harvest: PropTypes.number,
  remaining_wet_other_material_weight: PropTypes.number,
  trackPlantsAsGroups: PropTypes.bool,
  uom: PropTypes.string.isRequired
};

export default IndividualPlantInformationNoRating;
