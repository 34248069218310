import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Modal} from 'react-bootstrap';

import Message from './Message';

const MessageModal = (props) => {

  const {Component, title, children, dialogClassName, ...restProps} = props;

  const {showModal, onHide} = restProps;

  const titleClassLookup = {
    warning: 'text-warning bg-warning',
    error: 'text-danger bg-danger',
    success: 'text-success bg-success',
    info: 'text-info bg-info'
  };

  const titleClass = (titleClassLookup[props.modalType] !== undefined) ? titleClassLookup[props.modalType] : '';

  // close button was on both header and Modal but not defined... not sure what it was doing.

  return (
    <Modal className='modal-messages' show={showModal} onHide={onHide} dialogClassName={dialogClassName}>
      <Modal.Header className={titleClass} closeButton>
        {
          !props.translate
          ? <Modal.Title>{props.title}</Modal.Title>
            : <Modal.Title>{I18n.t(title)}</Modal.Title>
        }
      </Modal.Header>
      <Message message={props.message} />
      {
        props.Component !== undefined
          ? <Component {...restProps}/>
          : null
      }

      {children}
      <div style={{clear: 'both'}}></div>
      <Modal.Footer>
        <button className='btn btn-primary' onClick={(event) => onHide()}>{I18n.t('common.actions.okay')}</button>
      </Modal.Footer>
    </Modal>
  );

};

MessageModal.propTypes = {
  Component: PropTypes.oneOfType([
    PropTypes.node.isRequired,
    PropTypes.func.isRequired
  ]),
  modalType: PropTypes.string,
  title: PropTypes.string,
  onHide: PropTypes.func.isRequired,
  dialogClassName: PropTypes.string,
  showModal: PropTypes.bool.isRequired,
  translate: PropTypes.bool,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node
  ])
};

export default MessageModal;
