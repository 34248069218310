import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {bindActionCreators} from 'redux';
import {browserHistory} from 'react-router';

import * as dataNames from '../../../../constants/dataNames';
import * as apiActions from '../../../../actions/apiActions';

import PageTitle from '../../../common/PageTitle';// eslint-disable-line import/no-named-as-default
import SingleActionColumn from '../../../common/grid/columns/SingleActionColumn';
import TablePageWrapper from '../../../common/grid/TablePageWrapper';

import {handleComplexSelectRow} from '../../../../actions/helpers/selectedDataHelper';
import {getSelectedSuppliesIds} from '../../../../selectors/suppliesSelectors';

class SuppliesListingPage extends React.PureComponent{
  constructor(props){
    super(props);
    const columns = [
      {
        name: 'retail.supplies.table.supplyName',
        dataId: 'supply_name',
        width: '100px',
        hidden: false,
        dataSort: true
      },
      {
        name: 'retail.supplies.table.supplier',
        dataId: 'supplier',
        width: '100px',
        hidden: false,
        dataSort: true
      },
      {
        name: 'retail.supplies.table.containerSize',
        dataId: 'container_size',
        width: '100px',
        hidden: false,
        dataSort: true
      },
      {
        name: 'retail.supplies.table.currentLevel',
        dataId: 'current_level',
        width: '100px',
        hidden: false,
        dataSort: true
      },
      {
        name: 'retail.supplies.table.uom',
        dataId: 'uom',
        width: '100px',
        hidden: false,
        dataSort: true
      },
      {
        name: 'retail.supplies.table.costUnit',
        dataId: 'cost_unit',
        width: '100px',
        hidden: false,
        dataSort: true
      },
      {
        name: 'retail.supplies.table.sku',
        dataId: 'sku',
        width: '100px',
        hidden: false,
        dataSort: true
      },
      {
        name: 'retail.supplies.table.storageLocation',
        dataId: 'storage_location',
        width: '100px',
        hidden: false,
        dataSort: true
      },
      {
        hidden: false,
        csvFormatter: (cell) => { return I18n.t('retail.supplies.actions.edit'); },
        formatter: SingleActionColumn({
          label: 'retail.supplies.actions.edit',
          action: this.editSupplier
        }),
        width: '200px',
        dataSort: false
      }

    ];
    this.state = {
      columns,
      activeTab: 'uniforms'
    };
    this.switchTab = this.switchTab.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.editSupplier = this.editSupplier.bind(this);
    this.orderSupplies = this.orderSupplies.bind(this);
  }

  componentWillMount(){
    //this.props.actions.getUnpaginatedData('', dataNames.suppliers);
  }

  handleSelect(isSelected, rows) {
    this.props.actions.handleComplexSelectRow(rows, dataNames.suppliers, isSelected);
  }

  switchTab(activeTab) {
    this.setState({activeTab});
  }

  editSupplier(){

  }

  orderSupplies(){
    browserHistory.push('/purchase/create-order');
  }

  render(){
    const {columns} = this.state;
    //const {suppliers} = this.props;
    const {selectedSuppliers} = this.props;
    const data = [
      {
        id: 1,
        supply_name: 'Scrubs',
        supplier: 'Cintas',
        container_size: '55',
        current_level: '35',
        uom: 'ea',
        cost_unit: '$3.00',
        sku: '39284923849238492834',
        storage_location: 'Grown Room 1'
      },
      {
        id: 2,
        supply_name: 'Scrubs',
        supplier: 'Cintas',
        container_size: '55',
        current_level: '35',
        uom: 'ea',
        cost_unit: '$3.00',
        sku: '39284923849238492834',
        storage_location: 'Grown Room 1'
      },
      {
        id: 3,
        supply_name: 'Scrubs',
        supplier: 'Cintas',
        container_size: '55',
        current_level: '35',
        uom: 'ea',
        cost_unit: '$3.00',
        sku: '39284923849238492834',
        storage_location: 'Grown Room 1'
      },
      {
        id: 4,
        supply_name: 'Scrubs',
        supplier: 'Cintas',
        container_size: '55',
        current_level: '35',
        uom: 'ea',
        cost_unit: '$3.00',
        sku: '39284923849238492834',
        storage_location: 'Grown Room 1'
      },
      {
        id: 5,
        supply_name: 'Scrubs',
        supplier: 'Cintas',
        container_size: '55',
        current_level: '35',
        uom: 'ea',
        cost_unit: '$3.00',
        sku: '39284923849238492834',
        storage_location: 'Grown Room 1'
      },
      {
        id: 6,
        supply_name: 'Scrubs',
        supplier: 'Cintas',
        container_size: '55',
        current_level: '35',
        uom: 'ea',
        cost_unit: '$3.00',
        sku: '39284923849238492834',
        storage_location: 'Grown Room 1'
      },
      {
        id: 7,
        supply_name: 'Scrubs',
        supplier: 'Cintas',
        container_size: '55',
        current_level: '35',
        uom: 'ea',
        cost_unit: '$3.00',
        sku: '39284923849238492834',
        storage_location: 'Grown Room 1'
      }
    ];

    const tabs = [
      {
        id: 'uniforms',
        eventKey: 'uniforms',
        title: 'retail.supplies.table.uniforms',
        actions: [
          {
            id: 'orderSupplies',
            func: this.orderSupplies,
            text: 'retail.supplies.actions.orderSupplies',
            requireSelect: true
          }
        ]
      },
      {
        id: 'cleaningSupplies',
        eventKey: 'cleaningSupplies',
        title: 'retail.supplies.table.cleaningSupplies',
        actions: [
          {
            id: 'orderSupplies',
            func: this.orderSupplies,
            text: 'retail.supplies.actions.orderSupplies',
            requireSelect: true
          }
        ]
      },
      {
        id: 'medicalSupplies',
        eventKey: 'medicalSupplies',
        title: 'retail.supplies.table.medicalSupplies',
        actions: [
          {
            id: 'orderSupplies',
            func: this.orderSupplies,
            text: 'retail.supplies.actions.orderSupplies',
            requireSelect: true
          }
        ]
      }
    ];
    return(
      <div className='supplies-listing'>
        <PageTitle primaryText={I18n.t('retail.title')} secondaryText={I18n.t('retail.supplies.title')}/>
        <TablePageWrapper
          ref={this.ref}
          settingKey='retail-supplies-listing'
          columns={columns}
          data={data}
          activeTab={this.state.activeTab}
          tabs={tabs}
          switchTab={this.switchTab}
          className='tab-listing'
          selectedRows = {selectedSuppliers}
          handleSelect={this.handleSelect}
          hideScanSearch = {true}
          bstProps = {{
            ignoreSinglePage: true
          }}
        />
      </div>
    );
  }
}

SuppliesListingPage.propTypes = {
  selectedSuppliers: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    handleComplexSelectRow: PropTypes.func.isRequired,
    //getUnpaginatedData: PropTypes.func.isRequired,
  }),
};


function mapStateToProps(state){
  //const {suppliers} = state;
  return {
    state,
    selectedSuppliers: getSelectedSuppliesIds(state)
    //suppliers
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {handleComplexSelectRow});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (SuppliesListingPage);
