import React from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {reduxForm, Field, FieldArray } from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Button, Col, Image, Row } from 'react-bootstrap';
import TextInput from '../../common/form/TextInput';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import MultiselectInput from '../../common/form/MultiselectInput';
import FileInput from '../../common/form/FileInput';
import TextAreaInput from '../../common/form/TextAreaInput';
import ReduxTagField from '../../common/form/redux-form/ReduxTagField';
import RatingInput from '../../common/form/RatingInput';
import {MAX_UPLOAD_SIZE_DISPLAY, ALLOWED_FILE_TYPES} from '../../../constants/fileUploads';
import {PARTNER_FORM} from '../../../constants/forms';
import {validate} from './validate';
import Facilities from './Facilities'; //eslint-disable-line
import {isLeafPaConfigPackClosedLoopFacility} from '../../../selectors/facilitiesSelectors';
import * as itemNames from '../../../constants/itemNames';
//import FormWrapper from '../../common/form/FormWrapper';

export const PartnerForm = (props) => {
  const {
    partnerGroups,
    paymentTerms,
    handleSubmit,
    imageUrl,
    isLabPartner,
    isWasteTransferAvailable,
    addImageRef,
    changeUploadStatus,
    uploading,
    submitting,
    pristine,
    disable,
    change,
    arrayPushAction,
    facilities,
    integrationState: { isLeaf, isPaLeaf, isBiotrack },
    isIntegratedPartner,
    isCanada,
    isModifyForm,
    includeFacilities,
    integrationState,
    partner,
    editMode,
    currentFacility,
    isOrgFacility,
    currentValues,
    isLeafPaConfigPackClosedLoopFacility
  } = props;

  const partnerTypes = [];

  if (isLabPartner || !(isLeaf || isBiotrack)) {
    partnerTypes.push({ text: I18n.t('partners.createPartner.lab'), value: 'lab' });
  }

  //for PA don't show other types if current partner is lab
  if (!(isLabPartner && isIntegratedPartner && isLeaf && isPaLeaf)) {
    partnerTypes.push({ text: I18n.t('partners.createPartner.sellTo'), value: 'sellTo' });
    partnerTypes.push({ text: I18n.t('partners.createPartner.purchaseFrom'), value: 'purchaseFrom' });
  }

  if (isWasteTransferAvailable) {
    partnerTypes.push({ text: I18n.t('partners.createPartner.wasteDisposal'), value: 'wasteDisposal' });
  }

  if (isCanada) {
    partnerTypes.push({ text: I18n.t('partners.createPartner.isImport'), value: 'isImport' });
    partnerTypes.push({ text: I18n.t('partners.createPartner.isExport'), value: 'isExport' });
  }

  const statuses = [
    { text: I18n.t('partners.createPartner.Active'), value: 1 },
    { text: I18n.t('partners.createPartner.Inactive'), value: 0 }
  ];

  const closedLoopPartner =
    partner && partner.connect_organization_code && partner.connect_organization_code ? true : false;


  return (
    <div className='partner-form'>
      <form onSubmit={handleSubmit}>
        <Col md={12}>
          <Row>
            <Col md={6}>
              <h4>{I18n.t('partners.createPartner.organization')}</h4>
              <Row>
                <Col md={8}>
                  <Field
                    name='name'
                    component={TextInput}
                    props={{
                      label: I18n.t('common.form.name'),
                      placeholder: I18n.t('partners.createPartner.organizationName'),
                      isRequired: true,
                      disabled: closedLoopPartner
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={8}>
                  <Field
                    name='marketing_email_address'
                    component={TextInput}
                    props={{
                      label: I18n.t('partners.createPartner.MarketingEmailAddress'),
                      placeholder: I18n.t('partners.createPartner.MarketingEmailAddress'),
                      disabled: closedLoopPartner
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <ReduxTagField
                    name='group_ids'
                    props={{
                      options: partnerGroups,
                      label: I18n.t('partners.createPartner.partnerGroup'),
                      textKey: 'group_name',
                      valueKey: 'id',
                      placeholder: I18n.t('common.form.select')
                    }}
                  />
                </Col>
                <Col md={4}>
                  <div className='rating-wrapper'>
                    <Field
                      name='rating'
                      component={RatingInput}
                      props={{
                        label: I18n.t('partners.createPartner.partnerRating'),
                        maxStars: 5,
                        starSize: 25,
                        disabled: closedLoopPartner
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <h4>{I18n.t('partners.createPartner.logo')}</h4>
              <Row>
                <Col md={4}>
                  <Image thumbnail src={imageUrl} />
                </Col>
                <Col md={8}>
                  <p>{I18n.t('common.form.sizeLimit', { limit: MAX_UPLOAD_SIZE_DISPLAY })}</p>
                  <p>{I18n.t('common.form.typeLimit', { extensions: ALLOWED_FILE_TYPES.PARTNER_LOGO.extensions })}</p>
                  <Field
                    name='logoImage'
                    itemName={itemNames.image}
                    component={FileInput}
                    props={{
                      disabled: uploading || closedLoopPartner,
                      btnContent: uploading ? I18n.t('products.form.uploading') : I18n.t('products.form.uploadImage'),
                      handleRef: addImageRef,
                      onUploadStart: () => changeUploadStatus(true),
                      onChangeCb: (image) => {
                        change('image_file_id', image.id);
                        changeUploadStatus(false);
                      },
                      accept: ALLOWED_FILE_TYPES.PARTNER_LOGO.mimeTypes,
                      type: 'image',
                      file_type: 'PARTNER_LOGO',
                      btnProps: {
                        className: 'btn btn-default'
                      }
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Field
                name='payment_terms'
                component={ReactSelectInput}
                props={{
                  label: I18n.t('partners.createPartner.paymentTerms'),
                  options: paymentTerms,
                  textKey: 'name',
                  valueKey: 'code',
                  placeholder: I18n.t('common.form.select')
                }}
              />
            </Col>
            {/*<Col md={3}>*/}
            {/*<Field name='discount' component={NumericInput} props={{*/}
            {/*label: I18n.t('partners.createPartner.flatDiscountPercentage'),*/}
            {/*className: 'discount-percentage'*/}
            {/*}}/>*/}
            {/*</Col>*/}
            <Col md={3}>
              <Field
                name='partnerTypes'
                component={MultiselectInput}
                props={{
                  label: I18n.t('partners.createPartner.partnerType'),
                  options: partnerTypes,
                  isRequired: true,
                  disabled: closedLoopPartner || (isModifyForm && isIntegratedPartner && (isLeaf || isBiotrack)),
                  readOnly: closedLoopPartner || (isModifyForm && isIntegratedPartner && (isLeaf || isBiotrack))
                }}
              />
            </Col>
            <Col md={3}>
              <Field
                name='active'
                component={ReactSelectInput}
                props={{
                  label: I18n.t('partners.createPartner.organizationStatus'),
                  options: statuses,
                  clearable: false,
                  searchable: false,
                  backspaceRemoves: false,
                  deleteRemoves: false,
                  placeholder: I18n.t('common.form.select'),
                  disabled: closedLoopPartner || (isLeafPaConfigPackClosedLoopFacility && !partner.active)
                }}
              />
            </Col>
            <Col md={8}>
              <Field
                name='notes'
                component={TextAreaInput}
                props={{
                  label: I18n.t('partners.createPartner.note'),
                  placeholder: I18n.t('partners.createPartner.typeNote'),
                  rows: 3
                }}
              />
            </Col>
          </Row>
        </Col>

        <Row>
          <Col md={{span: 2, offset: 8}}>
            <Button
              disabled={submitting || disable}
              variant='primary'
              type='submit'
              className='submit-button btn-block'
            >
              {I18n.t('general.save')}
            </Button>
          </Col>
        </Row>

        <div style={{ clear: 'both', height: '18px' }} />
        <div>
          {!includeFacilities ? null : (
            <FieldArray
              name='facilities'
              component={Facilities}
              formName={PARTNER_FORM}
              props={{
                includeFacilities,
                isOrgFacility, // enables timezone when true
                integrationState,
                editMode,
                disable: disable,
                closedLoopPartner: closedLoopPartner,
                pristine,
                submitting,
                handleSubmit,
                facilities,
                currentFacility,
                change,
                partner,
                arrayPushAction,
                currentValues,
                isModifyForm
              }}
            />
          )}
        </div>
      </form>
    </div>
  );
};

PartnerForm.propTypes = {
  partnerGroups: PropTypes.array.isRequired,
  paymentTerms: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  disable: PropTypes.bool,
  change: PropTypes.func.isRequired,
  addImageRef: PropTypes.func,
  changeUploadStatus: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired,
  imageUrl: PropTypes.string.isRequired,
  integrationState: PropTypes.object.isRequired,
  isLabPartner: PropTypes.bool,
  isIntegratedPartner: PropTypes.bool,
  isWasteTransferAvailable: PropTypes.bool,
  isCanada: PropTypes.bool,
  isModifyForm: PropTypes.bool,
  includeFacilities: PropTypes.bool,
  partner: PropTypes.object.isRequired,
  editMode: PropTypes.bool,
  currentFacility: PropTypes.object.isRequired,
  isOrgFacility: PropTypes.bool,
  arrayPushAction: PropTypes.func.isRequired,
  facilities: PropTypes.array.isRequired,
  currentValues: PropTypes.object.isRequired,
  isLeafPaConfigPackClosedLoopFacility: PropTypes.bool
};


function mapStateToProps(state) {
  return {
    isLeafPaConfigPackClosedLoopFacility: isLeafPaConfigPackClosedLoopFacility(state)
  }
}

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: PARTNER_FORM,
    validate
  })
)(PartnerForm);
