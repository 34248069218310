import get from 'lodash.get';
import {createSelector} from 'reselect';
import {solr, service} from '../constants/listingSources';
import * as itemNames from '../constants/itemNames';

const getListingSource = state => get(state, `${itemNames.listingSource}.source`, undefined);

export const isListingSourceSolr = createSelector(
  [getListingSource],
  listingSource => listingSource === solr
);

export const isListingSourceService = createSelector(
  [getListingSource],
  listingSource => listingSource === undefined || listingSource === service
);
