import React from 'react';
import PropTypes from 'prop-types';
import {Field, FieldArray} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Button, ButtonToolbar, Col, Row, Form} from 'react-bootstrap';
import { FaAsterisk } from 'react-icons/fa';
import InlineCheckBox from '../../common/form/InlineCheckBox';
import NumericInput from '../../common/form/NumericInput';
import TextInput from '../../common/form/TextInput';
import MultiselectInput from '../../common/form/MultiselectInput';
import IngredientList from './IngredientList';
import AddItem from '../../common/form/AddItem';
import InternationalCurrencyInput from '../../common/form/InternationalCurrencyInput';
import InternationalDecimalInput from '../../common/form/InternationalDecimalInput';

export const FeedingScheduleForm = (props) => {
  const {
    array: {push},
    handleSubmit,
    submitting,
    pristine,
    change,
    reset,
    stages,
    locations,
    ingredientsList,
    formValues,
    isEditMode,
  } = props;

  return (
    <form className='feeding-schedule-form' onSubmit={handleSubmit}>
      <Col xs={12} key={`feeding-schedule-item`}>
        <Row>
          <Col md={6} lg={3}>
            <Field name={`schedule_name`} component={TextInput} props={{
              label: I18n.t('feedingSchedules.form.name'),
              placeholder: I18n.t('feedingSchedules.form.namePlaceholder'),
              isRequired: true,
            }}/>
          </Col>
          <Col md={6} lg={3}>
            <Field name={`stages`} component={MultiselectInput} props={{
              label: I18n.t('feedingSchedules.form.phase'),
              options: stages,
              textKey: 'stage_name',
              valueKey: 'stage_id',
              isRequired: true,
            }}/>
          </Col>
          <Col md={6} lg={3}>
            <Field name={`rooms`} component={MultiselectInput} props={{
              label: I18n.t('feedingSchedules.form.location'),
              options: locations,
              textKey: 'name',
              valueKey: 'id',
              isRequired: true,
            }}/>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} lg={3}>
            <InternationalDecimalInput
              name={`amount_per_feeding`}
              props={{
                fractionPartSize: 3,
                label: I18n.t('feedingSchedules.form.litersPerFeeding'),
                placeholder: I18n.t('feedingSchedules.form.litersPerFeedingPlaceholder'),
                isRequired: true,
              }}/>
          </Col>
          <Col sm={12} md={6} lg={3}>
          <Form.Label><FaAsterisk />{I18n.t('feedingSchedules.form.frequencyOfFeeding')}</Form.Label>
            <div className='layout-row layout-align-start-start'>
              <Field name={`frequency`} component={NumericInput} props={{
                placeholder: I18n.t('feedingSchedules.form.frequencyPlaceholder'),
              }}/>
              <label>{I18n.t('feedingSchedules.form.per')}</label>
              <Field name={`time`} component={NumericInput} props={{
                placeholder: I18n.t('feedingSchedules.form.daysPlaceholder'),
              }}/>
            </div>
          </Col>
          <Col sm={12} md={6} lg={3} className='checkbox-group'>
            <Field name='is_repeating' component={InlineCheckBox} props={{
              label: I18n.t('feedingSchedules.form.isRepeatingOccurrence'),
              groupClassName: 'inline-checkbox checkbox-vertical-align',
            }}/>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <h3>{I18n.t('feedingSchedule.form.ingredients')}</h3>
            <hr/>
          </Col>
        </Row>
        <FieldArray
          name='ingredients'
          component={IngredientList}
          props = {{
            ingredientsList,
            change,
            formValues
          }}/>

        <Row>
          <AddItem
            textMode='right'
            alignMode='left'
            addAction={() => push('ingredients', {})}
            text={I18n.t('feedingSchedules.form.addIngredient')}/>
        </Row>
        <Row>
          <Col xs={12}>
            <hr />
          </Col>
          <Col md={6} lg={3} className='ingredients-total'>
            <InternationalCurrencyInput
              name={`last_applied_cost_per_liter`}
              props={{
                fractionPartSize: 2,
                label: I18n.t('feedingSchedules.form.calculatedIngredientsCost'),
                disabled: true
              }}/>
          </Col>
          <Col md={6} lg={3}>
            <InternationalCurrencyInput
              name={`calculated_cost`}
              props={{
                fractionPartSize: 2,
                label: I18n.t('feedingSchedules.form.calculatedCost'),
                disabled: true
              }}/>
          </Col>
        </Row>
        <Row className='padding-top'>
          <Col xs={12}>
            <ButtonToolbar className='float-right'>
              <Button type='reset'
                      onClick={reset}>
                {I18n.t('feedingSchedules.form.reset')}
              </Button>
              {/* [NOTE] will be done in other release
               <PermissionButton
                permissions={[p.print_labels]}
                props={{variant: 'info'}}>
                {I18n.t('feedingSchedules.form.print')}
              </PermissionButton>
              <Button >
                {I18n.t('feedingSchedules.form.summary')}
              </Button> */}
              {isEditMode ? null : <Button variant='success'
                type='submit'
                onClick={() => change('redirect', false)}
                disabled={submitting || pristine}>
                  {I18n.t('plants.form.saveAndAddMore')}
              </Button>}
              <Button variant='primary'
                      type='submit'
                      onClick={() => change('redirect', true)}
                      disabled={submitting || pristine}>
                {I18n.t('plants.form.saveAndReturn')}
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>
      </Col>
    </form>
  );
};

FeedingScheduleForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  stages: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  array: PropTypes.object.isRequired,
  ingredientsList: PropTypes.array.isRequired,
  formValues: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool,
};

export default  FeedingScheduleForm;
