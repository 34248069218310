import React from 'react';
import PropTypes from 'prop-types';
import {Collapse} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import {FaMinus, FaPlus} from 'react-icons/fa';

class LotLineageNode extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      expanded: {
        node: props.defaultExpanded,
        packages: props.defaultExpanded,
        parents: props.defaultExpanded,
      }
    };
    this.toggle = this.toggle.bind(this);
    this.toggleNode = this.toggleNode.bind(this);
    this.togglePackages = this.togglePackages.bind(this);
    this.toggleParents = this.toggleParents.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.defaultExpanded !== this.props.defaultExpanded) {
      this.setState({
        expanded: {
          node: newProps.defaultExpanded,
          packages: newProps.defaultExpanded,
          parents: newProps.defaultExpanded,
        }
      });
    }
  }

  toggle(name) {
    this.setState(state => ({
      expanded: {
        ...state.expanded,
        [name]: !state.expanded[name],
      },
    }));
  }

  toggleNode() {
    return this.toggle('node');
  }

  togglePackages() {
    return this.toggle('packages');
  }

  toggleParents() {
    return this.toggle('parents');
  }

  getGlyph(name) {
    return this.state.expanded[name] ? <FaMinus style={{fontSize: '14px'}}/> : <FaPlus style={{fontSize: '14px'}}/>;
  }

  render() {
    const {node, defaultExpanded} = this.props;
    const {expanded} = this.state;
    return (
      <div className='lot-lineage-item'>
        <div className='lot-details expandable' onClick={this.toggleNode}>
          {this.getGlyph('node')} &nbsp;
          <span className='lot-number'>{node.lot_number}</span>
          {node.vendor_name ? (
            <span>
              <span className='separator'>-</span>
              <span className='vendor-name'>{node.vendor_name}</span>
            </span>
            )
           : null}
        </div>

        <Collapse in={expanded.node}>
          <div className='lot-components'>
            <div className='packages-section'>
              <div className='packages-node' onClick={this.togglePackages}>
                {this.getGlyph('packages')} &nbsp;
                <span className='packages-title'>{I18n.t('lotLineage.packages')}</span>
              </div>
              <Collapse in={expanded.packages}>
                <div className='package-items'>
                  {node.packages.map((item, index) => (
                    <div className='package-item' key={`package-${index}`}>
                      <span className='package-code'>{item.package_code}</span>
                      <span className='separator'>-</span>
                      <span className='item-master'>{item.product_name}</span>
                    </div>
                  ))}
                </div>
              </Collapse>
            </div>

            {node.parents.length > 0 ?
              <div className='parents-section'>
                <div className='parents-node' onClick={this.toggleParents}>
                  {this.getGlyph('parents')} &nbsp;
                  <span className='parents-title'>{I18n.t('lotLineage.parents')}</span>
                </div>
                <Collapse in={expanded.parents}>
                  <div className='parent-items'>
                    {node.parents.map((parentNode, index) => (
                      <LotLineageNode
                        key={`parent-${index}`}
                        node={parentNode}
                        defaultExpanded={defaultExpanded}
                      />
                    ))}
                  </div>
                </Collapse>
              </div> : null}
          </div>
        </Collapse>
      </div>
    );
  }
}

LotLineageNode.propTypes = {
  node: PropTypes.object.isRequired,
  defaultExpanded: PropTypes.bool,
};

export default LotLineageNode;
