import React from 'react';
import InternationalDisplayQty from '../../common/InternationalDisplayQty';

const defines = {
  tableColumns: [
    {
      name: 'inventory.preferredMixListing.mixName',
      dataId: 'name',
      width:'10%'
    },
    {
      name: 'inventory.preferredMixListing.item',
      dataId: 'item_master_name',
      width: '10%',
    },
    {
      name: 'inventory.preferredMixListing.ingredient1',
      dataId: 'ingredient1',
      width:'10%',
    },
    {
      name: 'inventory.preferredMixListing.quantity',
      dataId: 'inputs',
      width:'5%',
      /* eslint-disable react/no-multi-comp */
      formatter: (cell, row) => row && row.inputs && row.inputs[0] ? <InternationalDisplayQty qty={row.inputs[0].qty} uom={row.inputs[0].uom_display} displayUom={true}/> : '-'
    },
    {
      name: 'inventory.preferredMixListing.ingredient2',
      dataId: 'ingredient2',
      width:'10%',
      formatter: (cell, row) => cell ? cell : '-'
    },
    {
      name: 'inventory.preferredMixListing.quantity',
      dataId: 'inputs',
      width:'5%',
      /* eslint-disable react/no-multi-comp */
      formatter: (cell, row) => row && row.inputs && row.inputs[1] ? <InternationalDisplayQty qty={row.inputs[1].qty} uom={row.inputs[1].uom_display} displayUom={true}/> : '-'
    },
    {
      name: 'inventory.preferredMixListing.ingredient3',
      dataId: 'ingredient3',
      width:'10%',
      formatter: (cell, row) => cell ? cell : '-'
    },
    {
      name: 'inventory.preferredMixListing.quantity',
      dataId: 'inputs',
      width:'5%',
      /* eslint-disable react/no-multi-comp */
      formatter: (cell, row) => row && row.inputs && row.inputs[2] ? <InternationalDisplayQty qty={row.inputs[2].qty} uom={row.inputs[1].uom_display} displayUom={true}/> : '-'
    }
  ]
};

export default defines;
