import { I18n } from 'react-redux-i18n';
import * as dataNames from '../constants/dataNames';
import * as itemNames from '../constants/itemNames';
import { addMessage } from '../actions/systemActions';
import { setData } from '../actions/dataActions';
import { setItem } from '../actions/itemActions';


const inventorySync = (store) => (next) => (action) => {
  const result = next(action);

  /*** Inventory Sync Data ready ***/
  if (action.type === 'INVENTORY_SYNC_DATA_READY') {
    store.dispatch(setData(action.payload, dataNames.inventorySyncDiscrepantPackages));
    store.dispatch(addMessage('warning', I18n.t('inventory.sync.status.message.ready'), true));
    store.dispatch(setItem({status: I18n.t('inventory.sync.status.dataCollectionReady'), message: ''}, itemNames.inventorySyncDataStatus));
  }

  /*** Inventory Sync Failed ***/
  if (action.type === 'INVENTORY_SYNC_DATA_FAILED') {
    store.dispatch(addMessage('error', action.payload));
  }

  return result;
};

export default inventorySync;
