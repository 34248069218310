import { I18n } from 'react-redux-i18n';
import { betweenValidation, minValidation } from '../../common/form/redux-form/validations';

export const validate = (values, props) => {
  const errors = {};
  errors.splits = [];
  const usedQty = values.splits.reduce((acc, split) => {
    const multiplier = props.trackPlantsAsGroups ? split.number_of_groups : 1;
    if (!isNaN(parseInt(split.qty))) acc += parseInt(split.qty) * multiplier;
    return acc;
  }, 0);
  values.splits.forEach((split) => {
    const error = {};
    error.qty = minValidation(split.qty, 'plants.split.newPlantQty', 1);
    if (!error.qty && usedQty > values.qty) {
      error.qty = I18n.t('plants.split.exceedQuantityError');
    }

    if (props.trackPlantsAsGroups) {
      error.number_of_groups = betweenValidation(split.number_of_groups, 'plants.split.numberOfGroups', 1, 300);
    }

    errors.splits.push(error);
  });
  if (values.splits.length >= 10) {
    errors._error =
      'You are only able to split your Plant Group up to 10 times. Please adjust your split batches in order to move forward.';
  }
  return errors;
};

export default validate;
