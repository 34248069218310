/* eslint-disable indent */
import get from 'lodash.get';
import find from 'lodash.find';
import values from 'lodash.values';
import round from 'lodash.round';
import map from 'lodash.map';
import {createSelector} from 'reselect';
import * as itemNames from '../constants/itemNames';
import {eqFields} from '../constants/salesSettings'; // Used by equivalency display rules
import * as applicationModes from '../constants/applicationModes';
import {getApplicationMode} from './applicationModesSelectors';
import {getIntegrationState} from '../selectors/integration/integrationSelectors';
import {getSettings} from './salesSettingsSelectors';
import getRulesCategories from './compliance/sales/getRulesCategories';
import {getCategoriesDataSelector, getCategories} from './categorySelectors';
import {getFacilitySalesSettingsForCustomer} from './customersSelectors';

import {getOrder} from './orderSelectors';

export const getCustomerLimits = (state, ownProps) => {
  // If ownProps stats limits present we are getting them for each customer on patient list, not result of api call to limits
  if (ownProps.stats && ownProps.stats.limits) {
    return ownProps.stats;
  }

  if (ownProps.useLocalStateCustomerLimits) {
    return ownProps.customerLimits;
  }
  // Else return those in redux as result of api call for specific customer
  return state[itemNames.customerLimits];
};

const getOrderComplianceError = (state, ownProps) => ownProps[itemNames.orderComplianceError] !== undefined ? ownProps[itemNames.orderComplianceError] : {};

const flowerCategory = 1;
const skipSubCategory = [flowerCategory]; // Used in getLimitRuleSet

const getRulesBasedCompliance = (compliance, rulesCategories, limits) => {
  const rules = (compliance.order_sales_limit_category_options) ? compliance.order_sales_limit_category_options : {value: []};

  //use limits if we have them?
  return {...rules}.value.reduce((acc, rule) => {
    const ruleName = rulesCategories.find((r) => r.value === rule.sales_limit_category_key);
    if (!ruleName) return acc;

    //Check for any overrides available
    const limit_categories =  get(limits, 'limits.limit_categories');
    const qty_from_limits = (limit_categories) ? limit_categories.find((c) => c.sales_limit_category_key === rule.sales_limit_category_key) : null;
    const qty = (qty_from_limits) ? get(qty_from_limits, 'qty') : rule.qty;

    acc[rule.sales_limit_category_key] = {
      name: `${ruleName.text} (${rule.limit_using.replace('_', ' ')})`,
      rule: `${qty} ${rule.uom.toUpperCase()}`,
      rule_qty: parseFloat(qty),
      rule_uom: rule.uom,
      order: 0,
      previous_orders: 0,
      available: get(limits, `limits.external_totals.remaining.${rule.sales_limit_category_key}`, '?'),
      error: 0,
    };
    return acc;
  }, {});
};


//@TODO: Replace applicationMode with integrationState... but have a pile of bugs for hot fix so not today
const getLimitRuleSet = (applicationMode, integrationState, compliance, rulesCategories, baseCategories, limits) => {
  let displayRules = [];

  if(Object.keys(compliance).length === 0){
    return displayRules;
  }

  const plantCategoryCode = 'PLANTS';

  // Leaving case here until we get through 1.4.5 in case there's a reversal to this more strict interpretation
  // case integrationState.isOrRecMetrc && compliance.order_sales_limit_method.value === 'categories':
  //   displayRules = getRulesBasedCompliance(compliance, rulesCategories);
  //   break;
  const canadaOnlyRulesCategory = 'MARIJUANA';
  switch(true){
    case get(compliance, 'order_sales_limit_method.value', null) === 'categories':
      displayRules = getRulesBasedCompliance(compliance, rulesCategories, limits);
      break;
    case get(compliance, 'order_sales_limit_method.value', null) === 'equivalency': // eslint-disable-line no-case-declarations
      const eqRules = values(get(compliance, 'order_sales_limit_equivalency.value', []));
      displayRules = eqRules.reduce((acc, rule) => {
        if(rule.category_code === canadaOnlyRulesCategory && !integrationState.isCanada) return acc;
        if (rule.subcategory_id === 0) skipSubCategory.push(rule.category_id);

        let subcategoryId = rule.subcategory_id;

        if(rule.category_code === 'CLONE'){
          const plantsCategory = baseCategories.find((category) => category.category_code === plantCategoryCode);
          if(plantsCategory) {
            const cloneSubcategory = plantsCategory.subcategories.find((sub) => sub.subcategory_code === rule.category_code);
            if (cloneSubcategory) {
              subcategoryId = cloneSubcategory.id;
            }
          }
        }

        const eqField = find(eqFields, { category_code: rule.category_code });
        acc[`${rule.category_id}_${subcategoryId}`] = {
          name: (eqField) ? eqField.text : rule.category_code,
          rule: `${rule.flower_weight_value} GR = ${rule.weight_value} ${rule.uom}`,
          category_id: rule.category_id,
          subcategory_id: subcategoryId,
          order: 0,
          previous_orders: 0,
        };
        return acc;
      }, {
        '1_0': {
          name: 'Flower',
          rule: '1 GR = 1 GR',
          order: 0,
          previous_orders: 0,
          category_id: 1,
        }
      });
      break;
    default: // Only to document not ignored
      break;
  }
  return displayRules;
};

const mapWeightsToDisplayRuleProperty = (displayRules, categories, field, categoriesData) => {
  const getSalesLimitCategoryKey = (categoryId, subId) => {
    const category = categoriesData.find((category) => category.id === categoryId);
    if(category){
      const subCategory = category.subcategories.find((sub) => sub.id === subId);
      if(subCategory) return subCategory.sales_limit_category_key;
    }
    return '';
  };

  if(Object.keys(displayRules).length === 0) return displayRules;
  const isCategoryBased = (displayRules[Object.keys(displayRules)[0]].category_id !== undefined);
  categories.forEach((category) => {
    category.subcategories.forEach((sub) => {
      const key = (isCategoryBased)
        ? (skipSubCategory.indexOf(sub.category_id) === -1) ? `${sub.category_id}_${sub.id}` : `${sub.category_id}_0`
        : getSalesLimitCategoryKey(sub.category_id, sub.id);
      if (displayRules[key] === undefined) return false;
      displayRules[key][field] += sub.compliance_weight_grams;
    });
  });
  return map(displayRules, rule => {
    rule[field] = round(rule[field], 3);
    return rule;
  });
};

/***
 * Returns base set of display rules.  Consumed by other selectors
 * @type {Reselect.Selector<TInput, TOutput>}
 */
export const getBaseDisplayRules = createSelector([getApplicationMode, getIntegrationState, getSettings], (applicationMode, integrationState, compliance) => {
  return getLimitRuleSet(applicationMode, integrationState, compliance);
});

const mapProductsToCategoryArray = (products, complianceValue) => {
  // In the case of limits -> order_products it is an object containing objects - convert to array
  if(products === undefined) return [];
  if(!Array.isArray(products)) {
    products = Object.keys(products).reduce((acc, key) => {
      acc.push(products[key]);
      return acc;
    }, []);
  }

  // Reduce products array heirarchy of categories and subcategories where compliance_weight_grams is cumulative per sub category
  return products.reduce((categories, product) => {
    let category = categories.find((c) => c.id === product.category_id);
    if (!category) {
      category = {id: product.category_id, subcategories: []};
      categories.push(category);
    }
    let subCategory = category.subcategories.find((s) => s.id === product.subcategory_id);
    if (!subCategory) {
      subCategory = {id: product.subcategory_id, compliance_weight_grams: 0, category_id: category.id};
      category.subcategories.push(subCategory);
    }
    const hasItems = Array.isArray(product.items);
    const uom = product.uom || product.sold_weight_uom || 'EA';
    const isBulk = uom !== 'EA' && hasItems && product.items.find(d => d.prepack_weight_id == null);
    const weight = (isBulk && complianceValue == 1 && hasItems) ? product.items.reduce((a,b) => a + b.qty, 0) : parseFloat(product.compliance_weight_grams);
    subCategory.compliance_weight_grams += isNaN(weight) ? 0 : weight;
    return categories;
  }, []);
};

export const getDisplayRules = createSelector(
  [getApplicationMode, getIntegrationState, getFacilitySalesSettingsForCustomer, getOrder, getCustomerLimits, getRulesCategories, getCategoriesDataSelector, getOrderComplianceError, getCategories],
  (applicationMode, integrationState, compliance, order, limits, rulesCategories, categoriesData, complianceError, baseCategories) => {

  if(!limits.limits) return {};
  const displayRules = getLimitRuleSet(applicationMode, integrationState, compliance, rulesCategories, baseCategories, limits);

  // Handle previous orders
  const categories = mapProductsToCategoryArray(limits.limits.order_products);

  //Initialize previous orders values to any external values we might have
  const externalCategoryTotals = get(limits, 'limits.external_totals');
  if (externalCategoryTotals) {
    Object.keys(displayRules).forEach((key, index) => {
      displayRules[key]['previous_orders'] = get(externalCategoryTotals, key, 0);
    });
  }

  mapWeightsToDisplayRuleProperty(displayRules, categories, 'previous_orders', categoriesData);

  // id is only present on limits from stats which means we are in patient list
  if(limits.id) return displayRules;
  if(!order && !Array.isArray(order.products)) return displayRules;

  // Handle current order if present
  mapWeightsToDisplayRuleProperty(displayRules, mapProductsToCategoryArray(order.products, get(compliance, 'order_enable_weigh_on_hand.value', true)), 'order', categoriesData);

  // Handle error on current order if present and applies
  if(applicationMode === applicationModes.metrcOregonRec && compliance.order_sales_limit_method.value === 'categories'){
    const productProperties = ['current_order_products', 'new_order_products'];
    productProperties.forEach((productsKey) => {
      if(Object.keys(complianceError).length > 0 && complianceError.errors && complianceError.errors[productsKey]){
        const products = complianceError.errors[productsKey];
        if(Array.isArray(products)) {
          // Due to the shape of the error returned - the collection of products is contained within a single array element
          mapWeightsToDisplayRuleProperty(displayRules, mapProductsToCategoryArray(products[0]), 'error', categoriesData);
        }
      }
    });

  }
  return displayRules;
});
