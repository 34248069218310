import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {connect} from 'react-redux';
import moment from 'moment';
import get from 'lodash.get';
import InternationalDatePickerInput from './InternationalDatePickerInput';
import TextInput from './TextInput';
import {getInternationalDateFormat, getInternationalTimeFormat, getDateTimeFieldValidator, getDateFieldValidator, getTimeFieldValidator } from '../../../selectors/InternationalOperationsSelectors';

const InternationalDateTimePickerInput = ({ name, props, internationalDateFormat, internationalTimeFormat, dateTimeFieldValidator, dateFieldValidator, timeFieldValidator, ...rest }) => {

  // Date Formatter
  const dateFormatter = value => value ? moment(value).format('MM/DD/YYYY') : '';

  // NOTE to self:  You cannot put the validator function inside render(), otherwise it will
  // loop indefinitely re-rendering itself for eternity.  And that is long.


  const isDateFormat = get(props, 'dateFormat', false);
  const isTimeFormat = get(props, 'timeFormat', false);

  return !get(props, 'readOnly', false) ? (
    <Field
      name={name}
      component={InternationalDatePickerInput}
      validate={(isTimeFormat && isDateFormat) ? dateTimeFieldValidator : isTimeFormat ? timeFieldValidator : dateFieldValidator}
      props={{
        ...props,
        ...rest
      }}
    />
  ) :
  <Field
    name={name}
    component={TextInput}
    readOnly={true}
    format={
      dateFormatter
    }
    props={{
      ...props,
      ...rest
    }}
  />
  ;
};

InternationalDateTimePickerInput.propTypes = {
  name: PropTypes.string.isRequired,
  internationalDateFormat: PropTypes.string,
  internationalTimeFormat: PropTypes.string,
  dateTimeFieldValidator: PropTypes.func,
  dateFieldValidator: PropTypes.func,
  timeFieldValidator: PropTypes.func,
  timeFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  dateFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  props: PropTypes.object,
  readOnly: PropTypes.bool,
};

function mapStateToProps(state){
  return {
    internationalDateFormat: getInternationalDateFormat(state),
    internationalTimeFormat: getInternationalTimeFormat(state),
    dateTimeFieldValidator:  getDateTimeFieldValidator(state),
    dateFieldValidator:  getDateFieldValidator(state),
    timeFieldValidator:  getTimeFieldValidator(state),
  };
}



export default connect(mapStateToProps)(InternationalDateTimePickerInput);
