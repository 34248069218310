import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import InternationalQuantityByUomInput from '../../../common/form/InternationalQuantityByUomInput';
import AddItem from '../../../common/form/AddItem';
import InternationalCurrencyInput from '../../../common/form/InternationalCurrencyInput';

const Ingredients = ({fields, change, getFormValue, ingredients, getPossibleUom, integrationState, calculateTotalCost}) => {

  return (
    <div>
      {fields.map((line, index) => {
        const item = getFormValue(line) || {};
        const {isMetrc, isNormal} = integrationState;
        return (
          <Row key={line}>
            <Col xs={4} md={3}>
              <Field name={`${line}.id`} component={ReactSelectInput}
                     props={{
                       label: I18n.t('ei.processingTypes.form.materialsUsed'),
                       options: ingredients,
                       textKey: 'name',
                       valueKey: 'id',
                     }}/>
            </Col>
            <Col xs={3} md={2}>
              <InternationalQuantityByUomInput
                name={`${line}.qty`}
                label={I18n.t('ei.processingTypes.form.materialsQty')}
                allowNegativeNumber={false}
                uom={item.default_uom}
                hideUom={true}
              />
            </Col>
            <Col xs={3} md={2}>
              <Field name={`${line}.uom`} component={ReactSelectInput}
                     props={{
                       label: I18n.t('ei.processingTypes.form.materialsUom'),
                       options: getPossibleUom(item.default_uom),
                       textKey: 'uom_code',
                       valueKey: 'uom_code',
                       disabled: (isMetrc || isNormal),
                     }}/>
            </Col>
            <Col xs={3} md={2}>
              <InternationalCurrencyInput name={`${line}.cost_per_line`}
                 props={{
                   label: I18n.t('ei.processingTypes.form.materialsUnitCost'),
                   disabled: true,
                 }}/>
            </Col>
            <Col xs={2} md={1}>
              <AddItem
                length={fields.length}
                textMode='left'
                alignMode='right'
                showPlus={fields.length - 1 === index}
                addAction={() => {
                  return fields.push({cost_per_line: '0.00'});
                }}
                removeAction={() => {
                  const deleteMaterials = getFormValue('delete_materials');
                  const saveId = getFormValue(`${line}.save_id`);
                  if (saveId) {
                    deleteMaterials.push(getFormValue(`${line}.save_id`));
                  }
                  change('delete_materials', deleteMaterials);
                  fields.remove(index);
                }}
              />
            </Col>
          </Row>
        );
      })}
      <hr />
      <Row>
        <Col xs={3} md={2} offset={7}>
          <InternationalCurrencyInput
            name='total_cost'
            props={{
              value: calculateTotalCost(),
              label: I18n.t('ei.processingTypes.form.totalCost'),
              disabled: true
            }}/>
        </Col>
      </Row>
    </div>
  );
};

Ingredients.propTypes = {
  fields: PropTypes.object.isRequired,
  getFormValue: PropTypes.func.isRequired,
  ingredients: PropTypes.array.isRequired,
  getPossibleUom: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  integrationState: PropTypes.object.isRequired,
  calculateTotalCost: PropTypes.func.isRequired
};

export default Ingredients;
