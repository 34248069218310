import React from 'react';
import PropTypes from 'prop-types';

import AccordionPanel from '../AccordionPanel';
import {ConnectedLeafPaPhysicianNote} from './LeafPaPhysicianNote';


class LeafPaPhysicianNoteAccordion extends AccordionPanel {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false
    };

    this.toggleExpand = this.toggleExpand.bind(this);
  }

  toggleExpand() {
    this.setState({expanded: !this.state.expanded});
  }

  render() {
    return (
      <AccordionPanel title='consumers.checkin.limitationsAndRestrictions' defaultExpanded={false} onChangeEvent={this.toggleExpand}>
        <div className={'customer-page'} >
          {this.state.expanded && <ConnectedLeafPaPhysicianNote customer={this.props.customer} />}
        </div>
      </AccordionPanel>
    );
  }
}

LeafPaPhysicianNoteAccordion.propTypes = {
  customer: PropTypes.shape({
    state_integration_tracking_id: PropTypes.number.isRequired,
    birth_date: PropTypes.string.isRequired
  })
};


export default LeafPaPhysicianNoteAccordion;
