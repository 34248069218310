import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

const SourcesTable = (props) => {

  return (
    <div style={{height: '300px', overflow: 'scroll', marginTop: '16px'}}>
    <table className='table table-sm table-striped table-bordered'>
      <thead>
        <tr>
          <th>{I18n.t('labels.custom.productCode')}</th>
          <th>{I18n.t('labels.custom.lotNumber')}</th>
          <th>{I18n.t('labels.custom.name')}</th>
        </tr>
      </thead>
      <tbody>
      {
        props.items.length === 0
        ? null
          : props.items.reduce( (acc, item) => {

            if(item.children === undefined || item.children.length === 0) return acc;

            const children = item.children.map( (child, index) => {
              return (
                <tr key={index + acc.length}>
                  <td>
                    <a href onClick={(event) => props.onSelectActiveSource(event, child)}>
                      {child.package_code}
                    </a>
                  </td>
                  <td>{child.lot_id}</td>
                  <td>{child.name}</td>
                </tr>
              );
            });
            acc = acc.concat(children);
            return acc;
          }, [])
      }
      </tbody>
    </table>
    </div>
  );

};

SourcesTable.propTypes = {
  onSelectActiveSource: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired
};

export default SourcesTable;
