import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import {Col, Row} from 'react-bootstrap';
import {Field} from 'redux-form';
import moment from 'moment';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import InternationalDateTimePickerInput from '../../../common/form/InternationalDateTimePickerInput';

const isInitialLoadingOptions = [
  {text: 'Yes', value: true},
  {text: 'No', value: false},
];

const isInitialLoading = (integrator) => {
  // If _is_initial_loading flag is set, check if date is not passed. If passed set is_initial_loading_to_false
  if (get(integrator, 'value.is_initial_loading')) {
    // Check date
    const date = moment(get(integrator, 'value.initial_inventory_by_date', '0000-00-00'));
    const now = moment();
    return (now <= date);
  }
  return false;
};

const LeafIntegratorSettings = ({integrator}) => {
  const isLeafPa = integrator.value && integrator.value.state === 'pa' && integrator.integrator === 'leaf';
  return (
    <Col xs={12}>
      <h3>{I18n.t('stateIntegrators.form.leafConfiguration')}</h3>
      {isLeafPa ?
        <React.Fragment>
          <Row>
            <Col sm={6} md={4} lg={3}>
              <Field name='is_initial_loading' component={ReactSelectInput} props={{
                label: I18n.t('stateIntegrators.form.initialLoading'),
                disabled: true,
                options: isInitialLoadingOptions,
                value: isInitialLoading(integrator)
              }}/>
            </Col>
          </Row>
          <Row>
            <Col sm={6} md={4} lg={3}>
              <InternationalDateTimePickerInput
                name='initial_inventory_by_date'
                props={{
                  label: 'Initial Inventory By',
                  dateFormat: true,
                  disabled: true,
                  inputProps: {
                    disabled: true
                  },
                  enableTodayButton: false,
                  value: get(integrator, 'value.initial_inventory_by_date', '')
                }}/>
            </Col>
          </Row>
        </React.Fragment>
        : null
      }
    </Col>
  );
};

LeafIntegratorSettings.propTypes = {
  integrator: PropTypes.object
};

export default LeafIntegratorSettings;
