import React from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';

import MerchantCompanyHeader from './MerchantCompanyHeader';
import MerchantProfilesList from './MerchantProfilesList';

import {getCompany} from '../../../selectors/merchantConfigurationSelectors';


class MerchantConfigurationPage extends React.Component {
  render() {
    const {company} = this.props;

    return (
     <div>
      <h1>{I18n.t('facility.setup.merchantConfiguration')}</h1>
      <MerchantCompanyHeader />
      {company && <MerchantProfilesList />}
     </div>
    );
  }
}

const mapStateToProps = (state) => ({
  company: getCompany(state)
});

export default connect(mapStateToProps)(MerchantConfigurationPage);
