import React from 'react';

import BaseFormFields from '../common/BaseFormFields';
import SubmitSection from '../common/SubmitSection';


const Aeropay = () => {
  return (
    <div>
      <BaseFormFields integrator='aeropayintegrated' />
      <SubmitSection />
    </div>
  );
};


export default Aeropay;
