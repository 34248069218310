import PropTypes from 'prop-types';
import {formValueSelector, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {PHYSICIAN_FORM} from '../../../constants/forms';
import validate from './validate';
import PhysicianForm from './PhysicianForm';
import {getIntegrationState} from '../../../selectors/integration/integrationSelectors';


const PhysicianReduxForm = reduxForm({
  form: PHYSICIAN_FORM,
  validate,
  enableReinitialize: true,
})(PhysicianForm);

const selector = formValueSelector(PHYSICIAN_FORM);

function mapStateToProps(state) {
  const licenses = selector(state, 'licenses') || [];
  const integrationState = getIntegrationState(state);
  return {
    integrationState,
    licenses
  };
}

const PhysicianFormWrapper = connect(mapStateToProps)(PhysicianReduxForm);

PhysicianFormWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  clinics: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  provinces: PropTypes.array.isRequired,
  initialValues: PropTypes.object,
  primaryAddressIndex: PropTypes.number.isRequired,
  activeLicenseIndex: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  licenses: PropTypes.array,
};

export default connect(mapStateToProps)(PhysicianFormWrapper);
