import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {change, formValueSelector, reset} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {goBack, push} from 'react-router-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import {getItem, getUnpaginatedData, getSearchData, postItem, putItem} from '../../../actions/apiActions';
import {addMessage} from '../../../actions/systemActions';
import {setData, unsetData} from '../../../actions/dataActions';
import {unsetItem} from '../../../actions/itemActions';
import FormWrapper from '../../common/form/FormWrapper';
import ProductListForm from './ProductListForm';
import {PRODUCT_LIST_FORM} from '../../../constants/forms';

class ProductListPage extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.onSelectedItemMasterChange = this.onSelectedItemMasterChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      isModify: false,
      selectedItemMasterIds: []
    };

    this.defaultSearchParams = {
      sort: 'name asc, display_name asc',
      query: 'matchall',
      size: '100000',
      start: '0',
      fields: ['id', 'name', 'display_name', 'is_medicated', 'category_id', 'subcategory_id', 'lot_tracked',
        'is_prepack', 'item_master_parent_id', 'prepack_weight_id', 'is_sales_item', 'category_code',
        'subcategory_code', 'uom_type', 'default_uom', 'active', 'is_inventory_item', 'item_active', 'vendor_id'],
    };
  }

  componentDidMount() {
    // Load data
    const promises = [];

    promises.push(
      this.props.actions.getUnpaginatedData('/api/categories?is_only_active=1', dataNames.categories),
    );

    const id = this.props.params.id || '';
    // If modify (id exists) load item_master
    if (id) {
      this.setState({ isModify: true }); // eslint-disable-line react/no-did-mount-set-state
      promises.push(
        this.props.actions.getItem(`/api/product_lists/${id}`, itemNames.productList, undefined, { with_relations: ['products'] })
          .then((product_list) => {
            const selectedItemMastersIds = product_list.products.map((product) => product.item_master_id);
            this.setState({selectedItemMasterIds: selectedItemMastersIds}); // eslint-disable-line react/no-did-mount-set-state
          }),
      );
    }

    promises.push(
      this.props.actions.getUnpaginatedData('/api/partners', dataNames.partners, {failed:'partners.get.failed'}, {sell_to: 1, purchase_from: 1}),
    );

    Promise.all(promises);
  }

  componentWillUnmount() {
    this.props.actions.reset(PRODUCT_LIST_FORM);
    this.props.actions.unsetData(itemNames.productList);
    this.props.actions.unsetData(dataNames.categories);
    this.props.actions.unsetData(dataNames.partners);
  }

  onSelectedItemMasterChange(selectedItemMasterIds) {
    this.setState({selectedItemMasterIds});
  }

  redirect () {
    this.props.actions.push('/retail/product-lists/active');
  }

  onSubmit(formValues) {
    const {selectedItemMasterIds} = this.state;

    const payload = {
      name: formValues.name,
      description: formValues.description,
      item_master_ids: selectedItemMasterIds
    };

    const mode = formValues.id ? 'modify' : 'create';

    const messages = {
      success: `retail.productList.actions.${mode}.success`,
      failure: `retail.productList.actions.${mode}.failed`,
    };

    const next = () => {
      if (formValues.afterSubmit === 'redirect') {
        this.redirect();
      }
    };

    const id = get(payload, 'id', get(this.props.params, 'id'));
    if (id) {
      return this.props.actions.putItem(`/api/product_lists/${id}`, payload, itemNames.productList, messages, {}, next);
    }
    return this.props.actions.postItem('/api/product_lists', payload, itemNames.productList, messages, {}, next);
  }

  render() {
    const {actions: {change}, initialValues, getFormValue, categories, vendors} = this.props;
    const {isModify, selectedItemMasterIds} = this.state;
    return (
      <div className='product-list-wrapper'>
      <FormWrapper title = {this.state.isModify ? I18n.t('retail.productList.page.modifyTitle') : I18n.t('retail.productList.page.createTitle')} goBack={this.props.actions.goBack}>
        <ProductListForm
          change={change}
          defaultSearchParams={this.defaultSearchParams}
          onSubmit={this.onSubmit}
          initialValues={initialValues}
          getFormValue={getFormValue}
          categories={categories}
          vendors={vendors}
          selectedItemMasterIds={selectedItemMasterIds}
          onSelectedItemMasterChange={this.onSelectedItemMasterChange}
          isModify={isModify}
        />
      </FormWrapper>
      </div>
    );
  }
}

ProductListPage.propTypes = {
  actions: PropTypes.shape({
    getItem: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    getSearchData: PropTypes.func.isRequired,
    setData: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    putItem: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired
  }),
  params: PropTypes.object,
  initialValues: PropTypes.object.isRequired,
  getFormValue: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  vendors: PropTypes.array.isRequired,
};

function mapStateToProps(state, ownProps) {
  const selector = formValueSelector(PRODUCT_LIST_FORM);
  const getFormValue = (...names) => selector(state, ...names);
  const prodListInitialValues = ownProps.params.id ? state[itemNames.productList] : {};
  const initialValues = {
    ...prodListInitialValues,
    ...{category_ids: [], vendor_ids: []} // Add or overwrite with these properties
  };
  return {
    initialValues: initialValues,
    categories: state[dataNames.categories] ? state[dataNames.categories].filter((category) => category.category_code !== 'HARVESTS') : [],
    vendors: state[dataNames.partners],
    getFormValue
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {getItem, getUnpaginatedData, getSearchData, addMessage, setData, unsetData, unsetItem, postItem, putItem, goBack, push, change, reset};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductListPage);
