import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {I18n} from 'react-redux-i18n';
import {Row, Col} from 'react-bootstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack, push} from 'react-router-redux';
import * as apiActions from '../../../actions/apiActions';
import * as dataNames from '../../../constants/dataNames';
import PageTitle from '../../common/PageTitle';
import * as itemNames from '../../../constants/itemNames';
import {unsetItem} from '../../../actions/itemActions';
import {unsetData} from '../../../actions/dataActions';
import RegisterReconciliationFormWrapper from './RegisterReconciliationFormWrapper';
import {
  getDropRegisters,
  getReconciliationPageConfiguration,
  getRegisterClosing,
  getRegisterClosingMode,
  getRegisterClosingScenario,
  getRegisterClosingSettings,
  getRegisterReconciliationInitialValues,
} from '../../../selectors/registerClosingSelectors';
import ModalWrapper from '../../common/ModalWrapper';
import {userHasPermission} from '../../../selectors/usersSelectors';
import * as p from '../../../constants/permissions';
import MODES from '../../../constants/registerReconcliliationModes';


class RegisterReconciliationPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showCancelModal: false,
      isSaving: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.hideCancelPopup = this.hideCancelPopup.bind(this);
    this.goToClosedRegistersListing = this.goToClosedRegistersListing.bind(this);
  }

  componentWillMount() {
    this.props.actions.getItem(`/api/closings/${this.props.params.id}`, itemNames.registerClosing);
    this.props.actions.getUnpaginatedData('/api/closings/denominations', dataNames.closingDenominations);
    this.props.actions.getUnpaginatedData('/api/registers', dataNames.vaultRegisters, {failed: 'registers.get.failed'}, {
      active: 1,
      type: 'vault',
      detailed: 1,
    });
    this.props.actions.getItem('/api/order_settings/sales_limit', itemNames.salesComplianceSettings);
  }

  componentWillUnmount() {
    this.props.actions.unsetData(dataNames.vaultRegisters);
    this.props.actions.unsetData(dataNames.closingDenominations);
    this.props.actions.unsetData(itemNames.registerClosing);
  }

  goToClosedRegistersListing() {
    this.props.actions.push('/retail/sales-report/closing-report/closed');
  }

  onCancel(pristine) {
    if (pristine) {
      this.goToClosedRegistersListing();
    } else {
      this.setState({showCancelModal: true});
    }
  }

  hideCancelPopup() {
    this.setState({showCancelModal: false});
  }

  handleSubmit(formValues) {
    const {initialValues, mode} = this.props;
    const notEmpty = (paramName) => (item) => (item[paramName] !== '');
    const getDenominations = (paramName) => (item) => ({
      denomination_type: item.denomination_code,
      denomination_value: item.value,
      denomination_count: parseInt(item[paramName]) || 0,
    });

    const payload = {};

    if (mode === MODES.COUNT) {
      const paramName = 'employeeCount';
      const notEmptyRows = formValues.denominationCounts.filter(notEmpty(paramName));
      payload.employeeDenominations = notEmptyRows.map(getDenominations(paramName));
      payload.counted_total = parseFloat(formValues.counted_total);
    }

    if (mode === MODES.VERIFICATION) {
      const paramName = 'verifiedCount';
      const notEmptyRows = formValues.denominationCounts.filter(notEmpty(paramName));
      payload.verificationDenominations = notEmptyRows.map(getDenominations(paramName));
      payload.verified_total = parseFloat(formValues.verified_total);
    }

    // Allow the Register Reset and Drop Register to be set when doing the Employee Count
    // or if the Reset Balance started as null, indicating the Employee Count didn't specify a Reset
    if (
      formValues.reset_balance_to &&
      (mode === MODES.COUNT || initialValues.reset_balance_to === null))
    {
      payload.reset_balance_to = parseFloat(formValues.reset_balance_to);
      payload.drop_register_id = parseInt(formValues.drop_register_id);
    }

    const actionUrl = `/api/closings/${this.props.params.id}/${this.props.mode}`;
    const messages = {
      success: 'retail.registerReconciliation.successPost',
      failed: 'retail.registerReconciliation.failPost'
    };

    this.setState({isSaving: true});
    return this.props.actions.postItem(
      actionUrl,
      payload,
      itemNames.registerClosing,
      messages,
      null,
      () => {
        this.setState({isSaving: false});
      }
    );
  }


  render() {
    const {
      mode, registerClosing, initialValues, pageConfiguration, registerClosingSettings,
      dropRegisters, resetBalanceValue, hasPermission, closingScenario
    } = this.props;
    const {showCancelModal, isSaving} = this.state;
    const currentDate = moment().format('MM/DD/YYYY');
    const register = registerClosing && registerClosing.register;

    const renderWithPageTitle = (children) => {
      return (<div className='closing-till'>
        <PageTitle primaryText={I18n.t('retail.title')} secondaryText={I18n.t('retail.registerReconciliation.title')}/>
        <div>{children}</div>
      </div>);
    };

    if (!registerClosing || !register || !initialValues.denominationCounts.length || !registerClosingSettings) {
      return renderWithPageTitle(I18n.t('general.loading'));
    }

    if (!registerClosingSettings.is_allowed) {
      return renderWithPageTitle(I18n.t('retail.registerReconciliation.reconciliationNotAllowed'));
    }

    if (!hasPermission) {
      return renderWithPageTitle(I18n.t('retail.registerReconciliation.noPermission'));
    }

    if (!mode) {
      return renderWithPageTitle(I18n.t('retail.registerReconciliation.modeNotAllowed', {mode: this.props.params.mode}));
    }

    if (!register.is_closed || !register.current_closing_id) {
      return renderWithPageTitle(I18n.t('retail.registerReconciliation.registerIsOpenNow', {register: register.name}));
    }

    if (register.current_closing_id !== registerClosing.id) {
      return renderWithPageTitle(I18n.t('retail.registerReconciliation.closingIsNotCurrent'));
    }

    return renderWithPageTitle((
      <div>
        <Row>
          <Col xs={12} sm={12} md={12} className='closing-till-header'>
            <h3>{`${currentDate}: ${register.name}`}</h3>
          </Col>
        </Row>
        <ModalWrapper
          showModal={showCancelModal}
          onHide={this.hideCancelPopup}
          title={I18n.t('retail.registerReconciliation.cancelTitle')}
          version={2}
          dialogClassName='modal-sm'
          headerClass='bg-info-dark'
          cancelButton={{show: true, text: I18n.t('general.no')}}
          okayButton={{show: true, text: I18n.t('general.yes'), onClick: this.goToClosedRegistersListing}}
        >
          <div>{I18n.t('retail.registerReconciliation.cancelAlert')}</div>
        </ModalWrapper>
        <RegisterReconciliationFormWrapper
          pageConfiguration={pageConfiguration}
          registerClosing={registerClosing}
          mode={mode}
          isSaving={isSaving}
          dropRegisters={dropRegisters}
          initialValues={initialValues}
          onSubmit={this.handleSubmit}
          onCancel={this.onCancel}
          resetBalanceValue={resetBalanceValue}
          closingScenario={closingScenario}
        />
      </div>
    ));
  }
}

RegisterReconciliationPage.propTypes = {
  mode: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired,
  pageConfiguration: PropTypes.object.isRequired,
  registerClosing: PropTypes.object,
  registerClosingSettings: PropTypes.object,
  dropRegisters: PropTypes.array,
  hasPermission: PropTypes.bool,
  params: PropTypes.shape({
    mode: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  }).isRequired,
  actions: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired,
  })
};

function mapStateToProps(state, ownProps) {
  const mode = getRegisterClosingMode(state, ownProps);
  const permissions = (mode === MODES.VERIFICATION) ? [p.verify_register_count] : [];

  return {
    mode,
    initialValues: getRegisterReconciliationInitialValues(state, ownProps),
    pageConfiguration: getReconciliationPageConfiguration(state, ownProps),
    registerClosing: getRegisterClosing(state),
    registerClosingSettings: getRegisterClosingSettings(state),
    dropRegisters:  getDropRegisters(state),
    hasPermission: userHasPermission(state, {permissions}),
    closingScenario: getRegisterClosingScenario(state, ownProps)
  };
}


function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {goBack, push, unsetItem, unsetData});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterReconciliationPage);
