import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {ButtonToolbar} from 'react-bootstrap';

const ConfirmReleaseReservations = (props) => {

  return (
    <div className='row' style={{marginTop: '12px'}}>
      <div className='col-md-12'>
        <ButtonToolbar className='float-right'>
          <button
            onClick={(event) => {
              props.onHide();
            }}
            className='btn btn-danger'
          >
            {I18n.t('cultivation.finishedProduct.reservations.cancel')}
          </button>
          <button
            onClick={(event) => {
              props.confirm();
            }}
            className='btn btn-primary'
          >
            {I18n.t('cultivation.finishedProduct.reservations.confirm')}
          </button>
        </ButtonToolbar>
      </div>
    </div>
  );
};

ConfirmReleaseReservations.defaultProps = {
  outputsInViolation: [],
};

ConfirmReleaseReservations.propTypes = {
  outputsInViolation: PropTypes.array.isRequired,
  confirm: PropTypes.func.isRequired,
};

export default ConfirmReleaseReservations;
