import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {ButtonToolbar, Button} from 'react-bootstrap';
import RouteButton from '../common/RouteButton';
import PermissionWrapper from '../common/PermissionWrapper';
import * as p from '../../constants/permissions';

const PackagingButtons = ({job, cancelPackaging, completePackaging, pending, checkValidInventory, isLocked, disableButtons = false}) => {
  const start = !job.packaging_job_id;
  return (start ?
    <RouteButton path={`/packages/start/${job.id}`}
                 props={{
                   variant: 'success',
                   size: 'sm',
                   disabled: (pending || isLocked || disableButtons),
                   onClick: checkValidInventory
                 }}
    >
      {I18n.t('cultivation.finishedProduct.startPackaging')}
    </RouteButton>
    :
    <ButtonToolbar>
      <RouteButton path={`/packages/complete/${job.packaging_job_id}`}
                   props={{
                     variant: 'primary',
                     size: 'sm',
                     disabled: isLocked || disableButtons,
                     onClick: completePackaging
                   }}
      >
        {I18n.t('cultivation.finishedProduct.completePackaging')}
      </RouteButton>
      <PermissionWrapper permissions={[p.manage_inventory]} >
        <Button
          variant='danger'
          size='sm'
          onClick={() => cancelPackaging(job.packaging_job_id)}
          disabled={pending || isLocked}
        >
          {I18n.t('cultivation.finishedProduct.cancelPackaging')}
        </Button>
      </PermissionWrapper>
    </ButtonToolbar>);
};

PackagingButtons.propTypes = {
  pending: PropTypes.bool.isRequired,
  job: PropTypes.object.isRequired,
  cancelPackaging: PropTypes.func.isRequired,
  completePackaging: PropTypes.func.isRequired,
  checkValidInventory: PropTypes.func.isRequired
};

export default PackagingButtons;
