import {I18n} from 'react-redux-i18n';
import {EMAIL_REG_EXP} from '../../common/form/validationRegExs';
import * as validations from '../../common/form/redux-form/validations';
import {getFacilityTypesForPartner} from '../../../selectors/partnersSelectors';

export const validate = (values, props) => {
  const errors = {
    facilities: []
  };

  // Partner/Organization Validation
  if (!values.name) {
    errors.name = I18n.t('partners.create.enterName');
  }
  if (values.marketing_email_address && !EMAIL_REG_EXP.test(values.marketing_email_address)) {
    errors.marketing_email_address = I18n.t('common.form.invalidFormat');
  }
  errors.partnerTypes = validations.required(values.partnerTypes);

  // Facility Validation
  const {isCanada, isOklahoma, isMetrc} = props.integrationState;

  (values.facilities || []).map((facility, index) => {
    const facilityErrors = {};
    if (!facility.facility_name) {
      facilityErrors.facility_name = I18n.t('partners.create.enterName');
    }

    if (facility.facility_email && !EMAIL_REG_EXP.test(facility.facility_email)) {
      facilityErrors.facility_email = I18n.t('common.form.invalidFormat');
    }

    // Validate facility types
    if (isCanada || isOklahoma) {
      facilityErrors.facility_types = validations.requiredFieldValidation(facility.facility_types && facility.facility_types.length);
    }

    const facilityTypesForRegion = getFacilityTypesForPartner(props.partner, props.currentFacility);
    const fTypeErrors = [];
    if (facilityTypesForRegion.length > 0 && facility.facility_types) {
      (facility.facility_types || []).forEach((fT) => {
        const val = fT.value || fT;
        const ftValid = !!facilityTypesForRegion.find(regionType => regionType.value === val);
        if (!ftValid) {
          fTypeErrors.push(`${val} ${I18n.t('partners.form.isNotValidForRegion')}`);
        }
      });
      facilityErrors.facility_types = fTypeErrors.length ? fTypeErrors.join('. ') : facilityErrors.facility_types;
    }

    if (isCanada) {
      facilityErrors.street_address_1 = validations.requiredFieldValidation(facility.street_address_1);
      facilityErrors.city = validations.requiredFieldValidation(facility.city);
      facilityErrors.province_code = validations.requiredFieldValidation(facility.province_code);
      facilityErrors.country_code = validations.requiredFieldValidation(facility.country_code);
    }

    if (isMetrc) {
      facilityErrors.name_on_integrator = validations.requiredFieldValidation(facility.name_on_integrator);
    }

    errors.facilities[index] = facilityErrors;
  });

  return errors;
};

export const validateContacts = (values) => {
  const errors = {contacts: []};
  errors.contacts = values.contacts.map((contact) => {
    return {
      first_name: validations.required(contact.first_name),
      last_name: validations.required(contact.last_name),
      facilities: validations.required(contact.facilities),
      email_address: validations.matchEmail(contact.email_address),
    };
  });
  return errors;
};

export default validate;
