import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Col, FormGroup, Row} from 'react-bootstrap';
import find from 'lodash.find';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import TextInput from '../../../common/form/TextInput';
import InternationalReduxPhoneInput from '../../../common/form/redux-form/InternationalReduxPhoneInput';
import ContentConcealer from '../../../common/concealers/ContentConcealer';

const DriverAndVehicleDetail = ({drivers, vehicles, locked, getFormValue}) => {
  const driverId = getFormValue('driver.id');
  const driver = find(drivers, {id: driverId});
  const showVehicleBlock = !(driver && driver.is_licensed_transporter);
  return (
    <FormGroup className='driver-info'>
      <strong>{I18n.t('inventoryReceipt.form.driverInfo')}</strong>
      <Row>
        <Col xs={6} sm={4} md={3}>
          <Field
            name='driver.id'
            component={ReactSelectInput}
            props={{
              label: I18n.t('inventoryReceipt.form.driverName'),
              options: drivers,
              textKey: 'name',
              valueKey: 'id',
              disabled: locked
            }}
          />
        </Col>
        <Col xs={6} sm={4} md={3}>
          <Field
            name='driver.state_compliance_number'
            component={TextInput}
            props={{
              label: I18n.t('inventoryReceipt.form.driverStateLicense'),
              disabled: true
            }}
          />
        </Col>
        <ContentConcealer show={showVehicleBlock}>
          <Col xs={6} sm={4} md={3}>
            <Field
              name='driver.drivers_license_number'
              component={TextInput}
              props={{
                label: I18n.t('inventoryReceipt.form.driverLicense'),
                disabled: true
              }}
            />
          </Col>
        </ContentConcealer>
        <Col xs={6} sm={4} md={3}>
          <InternationalReduxPhoneInput
            name='driver.phone_number'
            props={{
              label: I18n.t('inventoryReceipt.form.driverPhoneNumber'),
              disabled: true
            }}
          />
        </Col>
      </Row>
      <ContentConcealer show={showVehicleBlock}>
        <div>
          <strong>{I18n.t('inventoryReceipt.form.vehicleInfo')}</strong>
          <Row>
            <Col xs={6} sm={4} md={3}>
              <Field
                name='vehicle.id'
                component={ReactSelectInput}
                props={{
                  label: I18n.t('inventoryReceipt.form.vehicleDescription'),
                  options: vehicles,
                  textKey: 'nickname',
                  valueKey: 'id',
                  disabled: locked
                }}
              />
            </Col>
            <Col xs={6} sm={4} md={3}>
              <Field
                name='vehicle.make'
                component={TextInput}
                props={{
                  label: I18n.t('inventoryReceipt.form.vehicleMake'),
                  disabled: true
                }}
              />
            </Col>
            <Col xs={6} sm={4} md={3}>
              <Field
                name='vehicle.model'
                component={TextInput}
                props={{
                  label: I18n.t('inventoryReceipt.form.vehicleModel'),
                  disabled: true
                }}
              />
            </Col>
            <Col xs={6} sm={4} md={3}>
              <Field
                name='vehicle.license_number'
                component={TextInput}
                props={{
                  label: I18n.t('inventoryReceipt.form.vehicleLicensePlate'),
                  disabled: true
                }}
              />
            </Col>
          </Row>
        </div>
      </ContentConcealer>

    </FormGroup>
  );
};

DriverAndVehicleDetail.propTypes = {
  drivers: PropTypes.array.isRequired,
  vehicles: PropTypes.array.isRequired,
  locked: PropTypes.bool.isRequired,
  getFormValue: PropTypes.func.isRequired
};

export default DriverAndVehicleDetail;
