import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Button, Col, Row} from 'react-bootstrap';
import {FaMinus} from 'react-icons/fa';
import InternationalQuantityByUomInput from '../../../common/form/InternationalQuantityByUomInput';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import AddItem from '../../../common/form/AddItem';
import InternationalCurrencyInput from '../../../common/form/InternationalCurrencyInput';

const IngredientsFieldArray = (props) => {
  const {
    fields: {map, push, remove, length},
    ingredients,
    getPossibleUom,
    getFormValue,
    integrationState: {isMetrc, isNormal}
  } = props;

  return (
    <div>
      {
        map((item, index) => {
          const ingredient = getFormValue(item) || {};
          const ingredientOriginal = ingredients.find(i => i.id === ingredient.item_master_id);
          return (
            <Row key={index}>
              <Col xs={12} md={4}>
                <Field
                  name={`${item}.item_master_id`}
                  component={ReactSelectInput}
                  props={{
                    label: I18n.t('ei.assemblies.form.ingredientName'),
                    options: ingredients,
                    textKey: 'name',
                    valueKey: 'id',
                  }}
                />
              </Col>
              <Col xs={3} md={2}>
                <InternationalQuantityByUomInput
                  name={`${item}.qty`}
                  label={I18n.t('ei.assemblies.form.quantityCol')}
                  uom={ingredient.uom}
                  hideUom={true}
                />
              </Col>
              <Col xs={4} md={2}>
                <Field
                  name={`${item}.uom`}
                  component={ReactSelectInput}
                  props={{
                    label: I18n.t('ei.assemblies.form.unitsCol'),
                    options: getPossibleUom((ingredientOriginal && ingredientOriginal.default_uom) || ''),
                    textKey: 'uom_code',
                    valueKey: 'uom_code',
                    disabled: (isMetrc || isNormal),
                  }}
                />
              </Col>
              <Col xs={4} md={3}>
                <InternationalCurrencyInput
                  name={`${item}.cost`}
                  props={{
                    fractionPartSize: 2,
                    label: I18n.t('ei.assemblies.form.costCol'),
                    disabled: true
                  }}
                />
              </Col>
              <Col xs={1} md={1}>
                <div className='float-right ingredients-minus-wrapper'>
                  <Button variant='primary' size='sm' onClick={() => remove(index)} disabled={length < 2}>
                    <FaMinus/>
                  </Button>
                </div>
              </Col>
            </Row>
          );
        })
      }
      <Row>
        <AddItem
          textMode='left'
          alignMode='right'
          addAction={() => push({})}
        />
      </Row>
    </div>
  );
};

IngredientsFieldArray.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    pop: PropTypes.func.isRequired,
    length: PropTypes.number,
  }),
  change: PropTypes.func.isRequired,
  getFormValue: PropTypes.func.isRequired,
  getPossibleUom: PropTypes.func.isRequired,
  ingredients: PropTypes.array.isRequired,
  addMessage: PropTypes.func.isRequired,
  integrationState: PropTypes.object.isRequired,
};

export default IngredientsFieldArray;
