import {createSelector} from 'reselect';
import {pick} from 'lodash';
import find from 'lodash.find';
import orderBy from 'lodash.orderby';
import get from 'lodash.get';
import {mappedValues} from '../constants/ratingOptions';

import * as dataNames from '../constants/dataNames';
import * as itemNames from '../constants/itemNames';
import {convertFromBase, convertWeightFromAndTo, getSmallestUomInCollection, getUomTypeFromCollection} from '../util/uomHelpers';
import * as UOMS from '../constants/uoms';

import {getIntegrationState} from './integration/integrationSelectors';
import {isFeatureEnabled} from './featureToggles';
import {getUserHasPermissionFunc} from './usersSelectors';
import * as permissions from '../constants/permissions';
import {getCoreSettings} from './coreSettingsSelectors';
import {getHighestRankedEntityLock, getIntegrationEntityLocks, parseEntityLocksFromJson} from '../util/entity_locks';
import {getUnsortedPartners} from './partnersSelectors';

export const getPackage = (state) => state[itemNames.pkg];

const getSelectedProducts = (state) => state.selectedProducts;
const getProducts = (state) => state.products;
export const getChildProducts = (state) => {
  return state[dataNames.childProducts].map((item) => item);
};

const getUpdatedItemExternalIdentifier = (state) => state[itemNames.updatedItemExternalIdentifier];

const getIngredientsCategory = (state) => state[dataNames.ingredientCategories];
const getLabResults = (state) => state[dataNames.testResults];
const getBiotrackItemDestructions = state => state[dataNames.biotrackItemDestructions];
export const getIntegrationMappings = state => state[dataNames.integrationMappings];
export const getInventoryLocks = state => state[dataNames.inventoryLocks];
const isLeafPaConfigPackEnabled = state => isFeatureEnabled(state)('feature_leaf_pa_configuration_pack');

const ensureProducts = createSelector(
  [getProducts],
  products => products.filter(p => p && p.doclist && p.doclist.docs && p.doclist.docs[0])
);

const getGroupByPackage = (_, props) => props.groupByPackage;
const getGroupField = (_, props) => props.groupField ? props.groupField : 'group_id';

const getTrackingId = (product, {isLeaf, isBiotrack}) => {
  return isLeaf || isBiotrack ? product.external_identifier : product.state_integration_tracking_id;
};

const getLabInfo = (item, labResults) => {
  const labResult = labResults.find(labResult => labResult.package_id === item.package_id);
  if (labResult) {
    return {lab_results_id: labResult.lab_results_id, status: labResult.status, extraction_eligible: labResult.extraction_eligible};
  }
  return {};
};

const getInactivationReason = (item, destructions) => {
  if (item.qty > 0) {
    return '';
  }
  if (item.is_destroyed) {
    const destruction = destructions.find(d => d.item_id === item.id);
    if (destruction && !destruction.destroyed_at) {
      return 'Scheduled for destruction';
    }
    return 'Destroyed';
  }

  switch(item.last_transaction_type) {
  case 'RECONCILE':
    return 'Reconciled';
  case 'MANUAL':
    return 'Modified';
  default:
    return 'Used';
  }
};

/**
 * If values is an array of 1 value it returns that, else returns the default value.
 * @param values - array of values
 * @param defaultValue
 * @returns {*}
 */
export const getValueOrDefault = (values, defaultValue = 'Multiple') => {
  if (!Array.isArray(values)) {
    return defaultValue;
  }
  return values.length > 1 ? defaultValue : values[0];
};

/**
 * Returns an array of items updated with uoms correct for prepacks (and left alone otherwise).
 * @param items
 * @param uomField
 */
export const getItemsWithUomUpdatedForPrepacks = (items, uomField) => {
  return items.map((item) => {
    const isPrepack = get(item, 'is_prepack', 0);
    if (isPrepack) {
      item[uomField] = get(item, 'prepack_weight_uom');
    }
    return item;
  });
};

/**
 * Gets commonUom and accounts for prepacks which are EA but have a weight field for their prepack weight.
 * @param items
 * @param uomField
 * @returns {any}
 */
export const getCommonUomForCollection = (items, uomField) => {
  items = getItemsWithUomUpdatedForPrepacks(items, uomField);
  return getSmallestUomInCollection(items, uomField);
};

/**
 * Returns weight in base quantity and accounts for prepacks.
 * @param item
 * @param weightField
 * @returns {*}
 */
export const getWeightBase = (item, weightField) => {
  return get(item, 'is_prepack', 0)
    ? get(item, 'prepack_weight_base', 1) * get(item, weightField)
    : get(item, weightField, 0);
};

/**
 * Returns a weight sum for the provided items using the passed in uomField and weight field.
 * @param items
 * @param uomField
 * @param weightField
 * @param isBaseWeightField
 * @param outputUom
 * @returns {*}
 */
export const getWeight = (items, uomField, weightField, isBaseWeightField = true, outputUom = 'GR') => {
  if (!Array.isArray(items) || !items.length) {
    return 0;
  }
  const commonUom = getCommonUomForCollection(items, uomField);
  return items.reduce((acc, item) => {
    const weightBase = getWeightBase(item, weightField);
    const uom = get(item, uomField, UOMS.MG);
    const weightFromBase = convertFromBase(weightBase, uom);
    const convertedWeight = convertWeightFromAndTo(weightFromBase, uom, get(commonUom, 'code', UOMS.MG));
    acc += convertedWeight;
    return acc;
  }, 0);
};

/**
 * Returns universal weight reserved value regardless of product type.
 * @param items
 * @returns {*}
 */
export const getWeightReserved = (items) => {
  if (!Array.isArray(items)) {
    return 0;
  }
  return items.reduce((acc, item) => {
    const uom = get(item, 'uom_display_reserved', UOMS.GR);
    acc += convertFromBase(parseInt(get(item, 'qty_base_reserved', 0)), uom || UOMS.GR);
    return acc;
  }, 0);
};

/**
 * Returns calculated quantity for prepacks, else just the raw qty_reserved value.
 * @param item
 */
export const getQuantityReserved = (item) => {
  const isPrepack = get(item, 'is_prepack', 0);
  if (!isPrepack) {
    return parseFloat(get(item, 'qty_reserved', 0));
  }
  const quantityBaseReserved = parseInt(get(item, 'qty_base_reserved', 0));
  const prepackWeightBase = parseInt(get(item, 'prepack_weight_base', 0));
  return isNaN(quantityBaseReserved) || isNaN(prepackWeightBase) ? 0 : quantityBaseReserved / prepackWeightBase;
};

/**
 * Sums the specified field on the items passed in.
 * @param items
 * @param field
 * @returns {*}
 */
export const getSum = (items, field) => {
  return items.reduce((acc, item) => {
    acc += parseFloat(get(item, field, 0));
    return acc;
  }, 0);
};

/**
 * Convert the flat items array into an object where the first level is lot and id and the second level is item master id
 * @param itemsArray
 * @returns {boolean|*}
 */
const getItemsByLotIdAndProduct = (itemsArray) => {
  if (!itemsArray.length) {
    return false;
  }
  return itemsArray.reduce((acc, item) => {
    const lotId = get(item, 'lot_id');
    const isPrepack = get(item, 'is_prepack', 0);
    const itemMasterIdField = isPrepack ? 'item_master_parent_id' : 'item_master_id';
    const itemMasterId = get(item, itemMasterIdField);
    if (!acc[lotId]) {
      acc[lotId] = {};
    }
    if (!acc[lotId][itemMasterId]) {
      acc[lotId][itemMasterId] = {items: []};
    }
    acc[lotId][itemMasterId].items.push(item);
    return acc;
  }, {});
};

/**
 * Returns collection of lots with items as children where a number of the parent properties are selected from the children.
 * @param lotsArray
 * @param itemsArray
 * @param itemsByLotIdAndItemMasterId
 * @returns {*}
 */
export const getInventory = (lotsArray, itemsArray, itemsByLotIdAndItemMasterId) => {
  return lotsArray.map((lot) => {
    const lotId = lot.lot_id;
    const itemIds = get(lot, 'item_ids', '').split(',').map((id) => parseInt(id));
    const itemMasterId = get(lot, 'item_master_id', 0);
    const dataKey = `${lotId}.${itemMasterId}`;
    const items = get(itemsByLotIdAndItemMasterId, `${dataKey}.items`, []);
    const itemLocksForLot = items.map(item => item.integration_entity_locks).flat();
    const rollupValuesForLot = get(itemsByLotIdAndItemMasterId, `${dataKey}.summaryData`, {});
    const commonUom = getSmallestUomInCollection(items, rollupValuesForLot.uomField);

    return Object.assign({}, lot, {
      id: itemIds.length > 0 ? itemIds.shift() : lotId,
      location_name: getValueOrDefault(get(rollupValuesForLot, 'location_name', [])),
      state_integration_tracking_id: getValueOrDefault(get(rollupValuesForLot, 'state_integration_tracking_id', [])),
      is_reserved: getValueOrDefault(get(rollupValuesForLot, 'is_reserved', []), -1),
      on_hold: getValueOrDefault(get(rollupValuesForLot, 'on_hold', []), -1),
      purpose: getValueOrDefault(get(rollupValuesForLot, 'purpose', [])),
      inactivation_reason: getValueOrDefault(get(rollupValuesForLot, 'inactivation_reason', [])),
      // For legacy reasons next 2 properties use same target; one day maybe we can change this.
      lot_created_at: getValueOrDefault(get(rollupValuesForLot, 'created_at', [])),
      package_created_at: getValueOrDefault(get(rollupValuesForLot, 'created_at', [])),
      test_results_status: getValueOrDefault(get(rollupValuesForLot, 'status', [])),
      is_locked: items.some(item => item.is_locked),
      integration_entity_locks: itemLocksForLot,
      highest_ranked_entity_lock: getHighestRankedEntityLock(itemLocksForLot),
      inv_total_qty: get(rollupValuesForLot, 'inv_total_qty', 0),
      weight_gr_total: get(rollupValuesForLot, 'weight_gr_total', 0),
      weight_reserved_total: get(rollupValuesForLot, 'weight_reserved_total', 0),
      children: items,
      rating_avg_total_quality: 'no source', //@TODO: get source for this
      uom_display: get(commonUom, 'code', UOMS.MG),
      vendor_name: getValueOrDefault(get(rollupValuesForLot, 'vendor_name', [])),
      renderData: rollupValuesForLot.renderData,
    });
  });
};

/**
 * Fetches external IDs from the store (call to /api/integration-mapping/multiple) and index them using internal IDs
 * @type {Reselect.Selector<any, any>}
 */
const getExternalIdentifierByItemsId = createSelector([getIntegrationMappings],
  (mapping) => {
    return mapping.reduce((map, item) => {
      return {...map, [item.internal_identifier] : item.external_identifier};
    }, {});
  });

/**
 * Rolls up values on items into arrays on lots or weight.  If a lot has more than one element in the array, then it
 * will display an indication of that (eg. Multiple) or a default value in some cases.
 * This should save several thousand calculations per page load.
 * @param _
 * @param state
 */
const getLotRollupValues = createSelector(
  [getChildProducts, getInventoryLocks, isLeafPaConfigPackEnabled, getIntegrationState, getBiotrackItemDestructions, getExternalIdentifierByItemsId, getLabResults, getUnsortedPartners, getUpdatedItemExternalIdentifier],
  (items, inventoryLocks, isLeafPaConfigPackEnabled, integrationState, destructions, externalIdentifiers, labResults, partners, updatedItemExternalIdentifier) => {

    const partnersLookupByOrgId = {};
    const partnersLookupById = partners.reduce((acc, partner) => {
      acc[partner.id] = partner;
      if(partner.is_internal_partner){
        partnersLookupByOrgId[partner.organization_id] = partner;
      }
      return acc;
    }, {});

    const partnerNotPresent = {name: 'Unknown'};

    const getPartnerName = (partnerId, organizationId) => {
      return partnerId
        ? get(partnersLookupById, partnerId, partnerNotPresent).name
        : get(partnersLookupByOrgId, organizationId, partnerNotPresent).name;
    };

    const labResultsLookup = labResults.reduce((acc, result) => {
      acc[result.reference_id] = result;
      return acc;
    }, {});

    // Creates arrays of unique values for each field.
    const fieldsToRollUp = [
      'location_name',
      'state_integration_tracking_id',
      'global_id',
      'is_reserved',
      'on_hold',
      'purpose',
      'inactivation_reason',
      'created_at',
      'package_created_at',
      'is_locked',
      'status',
      'vendor_name',
    ];

    // shape where 1 is lot id, 2 is item master id which itself has an object including items array: [1][2] = {items: []}
    const itemsByLotIdAndItemMasterId = getItemsByLotIdAndProduct(items);
    const {isBiotrack, isLeaf} = integrationState;

    const getValue = (item, field) => {
      let value = get(item, field);
      switch(field){
        case 'state_integration_tracking_id': //eslint-disable-line
        const external_id = get(externalIdentifiers, get(item, 'id'), '');
        const global_id = get(item, 'global_id', '');
        const trackingId = isLeafPaConfigPackEnabled
          ? external_id
            ? external_id
            : global_id
          : external_id;
        value = isBiotrack || isLeaf
          ? !trackingId && updatedItemExternalIdentifier && updatedItemExternalIdentifier.id === item.id
            ? updatedItemExternalIdentifier.new_external_identifier
            : trackingId
          : get(item, 'state_integration_tracking_id', ''); //eslint-disable-line
        break; //eslint-disable-line

        case 'inactivation_reason': //eslint-disable-line
        value = getInactivationReason(item, destructions); //eslint-disable-line
      }
      return value;
    };

    // Iterate our items and get summary data at the product level
    Object.keys(itemsByLotIdAndItemMasterId).forEach((lotId) => {
      const itemsByItemMasterId = itemsByLotIdAndItemMasterId[lotId];
      Object.keys(itemsByItemMasterId).forEach((itemMasterId) => {

        // Add summaryData property defaulted to empty arrays:  eg {location_name: []}
        itemsByItemMasterId[itemMasterId].summaryData = fieldsToRollUp.reduce((acc, field) => {
          return Object.assign({}, acc, {[field]: []});
        }, {});
        const summaryData = itemsByItemMasterId[itemMasterId].summaryData;
        const items = itemsByItemMasterId[itemMasterId].items;
        // Populate summary data with unique values from items and set the item value
        const isPrepack = get(items, '0.is_prepack', 0);
        const uomField = isPrepack ? 'uom_display' : 'uom';
        const uomType = getUomTypeFromCollection(items, uomField);
        items.forEach((item) => {

          // Find and apply lab result data
          const labResult = labResultsLookup[item.package_id];
          if(labResult){
            Object.keys(labResult).forEach((key) => {
              item[key] = labResult[key];
            });
          }

          // Attributes that are calculated and rolled up go here before the rollup fields
          item.vendor_name = getPartnerName(item.producer_id, item.organization_id);

          // These are the fields that on the parent are either specific of default (eg. Multiple)
          fieldsToRollUp.forEach((field) => {
            const fieldArray = summaryData[field];
            const value = getValue(item, field);
            item[field] = value;
            if (fieldArray.indexOf(value) === -1) {
              fieldArray.push(value);
            }
          });

          // INSERT ANY ITEM LEVEL MUTATIONS HERE - eg - merging reservation data, lab results, locks etc.
          const itemLocks = inventoryLocks && inventoryLocks.filter(lock => lock.entity_id === item.id);
          item.integration_entity_locks = getIntegrationEntityLocks(itemLocks);
          item.highest_ranked_entity_lock = getHighestRankedEntityLock(itemLocks);
          item.weight_reserved = getWeightReserved([item]);
          item.weight_gr = getWeight([item], uomField, 'qty_base');
          item.uom_type = isPrepack ? 'discrete' : uomType;
          item.qty_reserved = getQuantityReserved(item);
          item.item_id = item.id; // For legacy reasons - it is used at least once in inventory page.
          item.package_active = item.active; // For legacy reasons - used in inventory page.
          item.external_identifier = item.state_integration_tracking_id; // For legacy reasons - used in inventory page.
        });
        // INSERT ANY PARENT LEVEL SUMMARY DATA HERE and fetch out in the getInventory function.
        // Get our weight data sums now to save iterations later.
        summaryData.weight_gr_total = getWeight(items, uomField, 'qty_base');
        summaryData.weight_reserved_total = getWeightReserved(items);
        summaryData.inv_total_qty = getSum(items, 'qty_base');
        summaryData.uomField = uomField;
        summaryData.renderData = true;
      });
    });

    return itemsByLotIdAndItemMasterId;
  });



/**
 * Returns inventory data by lot and item
 * See
 * @type {Reselect.Selector<unknown, *>}
 */
export const getInventorySelector = createSelector(
  [getProducts, getChildProducts, getLotRollupValues],
  (lots, items, rollupValues) => {

    return getInventory(lots, items, rollupValues);
  });


export const normalizeFinishedProducts = createSelector(
  [ensureProducts, getChildProducts, getGroupByPackage, getGroupField, getIntegrationState, getLabResults, getBiotrackItemDestructions],
  (products, childProducts, groupByPackage, groupField, integrationState, labResults, destructions) => {
    return products.map((group) => {
      const product = childProducts.find(child => `${child.lot_id}.${child.item_master_id}` === group.groupValue) || group.doclist.docs[0];
      const itemLocks = [];
      const children = childProducts
        .filter((child) => {
          if (child.is_prepack && !child.parent_item_master_id) {
            return false;
          }
          return child[groupField] === group.groupValue;
        })
        .reduce(
          (acc, child) => {
            const entity_locks = parseEntityLocksFromJson(child.entity_locks);
            const highestRankedEntityLock = getHighestRankedEntityLock(entity_locks);
            // Put the most important item locks for each item item_locks so we have less locks to filter for when finding the most important for the lot
            if (highestRankedEntityLock) {
              itemLocks.push(highestRankedEntityLock);
            }
            const labResult = getLabInfo(child, labResults);
            const mappedChild = {
              ...child,
              integration_entity_locks: getIntegrationEntityLocks(entity_locks),
              highest_ranked_entity_lock: highestRankedEntityLock,
              state_integration_tracking_id: getTrackingId(child, integrationState),
              weight_reserved: convertFromBase(child.qty_reserved_base, get(child, 'uom_display', UOMS.GR)),
              ...labResult,
              purpose: child.purpose_name,
              inactivation_reason: getInactivationReason(child, destructions)
            };
            if (groupByPackage) {
              let pkg = acc.find(p => p.package_id === child.package_id);
              if (!pkg) {
                pkg = {
                  ...mappedChild,
                  items: [],
                };
                acc.push(pkg);
              }
              pkg.items.push(mappedChild);
            } else {
              acc.push(mappedChild);
            }
            return acc;
          },
          []
        );

      const isPrepack = Array.isArray(children) ? get(children[0], 'is_prepack', 0) : false;
      const uomField = isPrepack ? 'uom_display' : 'uom';
      const commonUom = getSmallestUomInCollection(children, uomField);
      const computed = children.reduce(
        (acc, child) => {
          let {location_name, weight_gr_total, weight_reserved_total, is_reserved, inv_total_qty, on_hold, state_integration_tracking_id, purpose, lot_created_at, test_results_status, package_created_at, inactivation_reason, is_locked} = acc;
          const weightInLowestCommonUom = convertWeightFromAndTo(get(child, 'weight_gr', 0), get(child, uomField, UOMS.MG), get(commonUom, 'code', UOMS.MG));
          location_name = child.location_name === location_name ? location_name : 'Multiple';
          state_integration_tracking_id = child.state_integration_tracking_id === state_integration_tracking_id ? state_integration_tracking_id : 'Multiple';
          weight_gr_total += weightInLowestCommonUom;
          weight_reserved_total += child.weight_reserved;
          is_reserved = child.is_reserved === is_reserved ? is_reserved : -1;
          on_hold = child.on_hold === on_hold ? on_hold : -1;
          inv_total_qty += parseFloat(child.qty_default) || 0;
          purpose = child.purpose === purpose ? purpose : 'Multiple';
          inactivation_reason = inactivation_reason && inactivation_reason !== child.inactivation_reason ? 'Multiple' : child.inactivation_reason;
          //Lot creation date is considered as the earliest date of the group item
          lot_created_at = lot_created_at == child.created_at ? lot_created_at : 'Multiple';
          package_created_at = package_created_at !== 'Multiple' && package_created_at === child.package_created_at ? package_created_at : 'Multiple';
          test_results_status = (!test_results_status || child.status === test_results_status) ? child.status : 'Multiple';
          is_locked = is_locked || child.is_locked;
          return {
            location_name,
            weight_gr_total,
            weight_reserved_total,
            is_reserved,
            inv_total_qty,
            on_hold,
            state_integration_tracking_id,
            purpose,
            lot_created_at,
            test_results_status,
            package_created_at,
            inactivation_reason,
            is_locked,
            renderData: true // Used by service first display of search results; ignored by solr first inventory components.
          };
        },
        {
          location_name: product.location_name,
          state_integration_tracking_id: getTrackingId(product, integrationState),
          weight_gr_total: 0,
          weight_reserved_total: 0,
          is_reserved: product.is_reserved,
          on_hold: product.on_hold,
          inv_total_qty: 0,
          purpose:  product.purpose_name,
          lot_created_at: product.created_at,
          lot_created_on: product.lot_created_on,
          test_results_status: '',
          package_created_at: product.package_created_at,
          inactivation_reason: '',
          renderData: false,
        }
      );

      return {
        ...product,
        ...computed,
        highest_ranked_entity_lock: getHighestRankedEntityLock(itemLocks),
        children,
        name: product.parent_item_master_name ? product.parent_item_master_name : product.name,
        rating_avg_total_quality: mappedValues[product.rating_avg_total_quality] || product.rating_avg_total_quality,
        uom_display: get(commonUom, 'code', UOMS.MG),
      };
    });
  }
);

export const normalizeIngredients = createSelector([normalizeFinishedProducts, getIngredientsCategory], (products, categories) => {
  return products.map(product => {
    return {
      ...product,
      category_title: categories.reduce((acc, category) => {
        if(product.ingredient_category_id === category.id) {
          acc = category.name;
        }
        return acc;
      }, ''),
      renderData: true,
      children: product.children.map((child) => {
        return {...child, qty: child.qty_default, uom: child.default_uom, uom_display: child.default_uom, weight_gr: child.qty_default};})
    };
  });
});

export const normalizeRetailProducts = createSelector([ensureProducts, getChildProducts], (products, childProducts) => {
  return products.map((group, index) => {

    const children = childProducts ? childProducts.filter(
          child => child.retail_group_id === group.groupValue) : [];
    const product = childProducts ? childProducts.find(child => child.id === group.groupValue) || group.doclist.docs[0] : group.doclist.docs[0];
    const computed = children.reduce(
      (acc, child) => {
        let {location_name, weight_gr_total} = acc;
        location_name = child.location_name === location_name ? location_name : 'Multiple';
        weight_gr_total += parseFloat(child.weight_gr) || 0;
        return {location_name, weight_gr_total};
      },
      {location_name:product.location_name, weight_gr_total: 0}
    );
    return {
      ...product,
      children,
      name: product.parent_item_master_name ? product.parent_item_master_name : product.name,
      uom_display: product.parent_item_master_name ? 'GR' : product.uom_display,
      location_name: computed.location_name,
      weight_gr_total: computed.weight_gr_total,
      rating_avg_total_quality: mappedValues[product.rating_avg_total_quality] || product.rating_avg_total_quality,
    };
  });
});

export const getSelectedProductIds = createSelector(getSelectedProducts, selectedProducts => selectedProducts.map(selectedProduct => selectedProduct.id));


/**
 * Can user see "internal transfers" functionality on current facility.
 */
export const getCanManageInternalTransfers = createSelector(
  [getUserHasPermissionFunc(permissions.manage_internal_transfers), getCoreSettings],
  (hasPermission, coreSettings) => {
    const core_internal_transfers_enable = get(coreSettings, 'core_internal_transfers_enable.value', true);
    return Boolean(hasPermission && core_internal_transfers_enable);
  }
);

/**
 * Getting products for internal transfer from list of children for sync up quantity that might be changed
 * when an internal transfer has been executed
 * @type {Reselect.Selector<any, any>}
 */
export const getProductsForInternalTransfers = createSelector(
  [getSelectedProducts, getChildProducts],
  (selectedProducts, children) => {
    const result = [];
    selectedProducts.forEach(({ id }) => {
      const product = find(children, { id });
      if (product) {
        result.push(product);
      }
    });

    return result;
  }
);

export const getActivationItemOptions = createSelector(
  [getSelectedProducts],
  groups => {
    const items = groups.reduce(
      (acc, group) => {
        if (!group.lot_id) {
          return acc;
        }
        return acc.concat((group.items || []).map((item) => {
          const option_name = `${item.lot_number} - ${item.package_code} - ${item.name}`;
          return {...item, option_name};
        }));
      },
      []
    );
    return orderBy(items, 'option_name');
  }
);

export const getActivateInitialValues = createSelector(
  [getSelectedProducts],
  (products) => {
    return {
      items: products
        .filter(product => product.lot_id)
        .map(product => pick(product, ['id', 'lot_number', 'package_code', 'inventory_location_id', 'uom_display', 'uom'])),
    };
  }
);
