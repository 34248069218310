import React from 'react';
import {I18n} from 'react-redux-i18n';

const ProductsTableHeader = () => {
  return (
    <thead className='items-table-header'>
      <tr>
        <th className='product-image'></th>
        <th className='description'>{I18n.t('cart.items.itemDescription')}</th>
        <th className='item-id'>{I18n.t('cart.items.itemId')}</th>
        <th className='medicated-weight'>{I18n.t('cart.items.medicatedWeight')}</th>
        <th className='pricing-weight'>{I18n.t('cart.items.pricingWeight')}</th>
        <th className='unit-price'>{I18n.t('cart.items.unitPrice')}</th>
        <th className='quantity'>{I18n.t('cart.items.quantity')}</th>
        <th className='line-total'>{I18n.t('cart.items.lineTotal')}</th>
        <th className='last'></th>
      </tr>
    </thead>
  );
};

export default ProductsTableHeader;