import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Button} from 'react-bootstrap';
import {FaAngleLeft, FaAngleRight} from 'react-icons/fa';

const CureApiOrderSelector = (props) => {
  const {activeGroup, groupCount, onGroupChange, orders} = props;
  return (
    <div className='layout-row layout-align-start-stretch order-selector'>
      {groupCount > 1 ?
        <div className='select-button'>
          <Button variant='primary' size='sm' disabled={activeGroup === 0} onClick={() => onGroupChange(activeGroup - 1)}>
            <FaAngleLeft/>
          </Button>
        </div>
        : null
      }
      <div className='flex orders'>
        {orders.map((order, index) => (
          <div className='order-details' key={index}>
            <div className='layout-row layout-align-start-center detail-row'>
              <div className='row-title flex-50'>{I18n.t('curePurchasableAmount.physicianName')}</div>
              <div>{order.physician_name}</div>
            </div>
            <div className='layout-row layout-align-start-center detail-row'>
              <div className='row-title flex-50'>{I18n.t('curePurchasableAmount.startDate')}</div>
              <div>{order.start_date}</div>
            </div>
          </div>
        ))}
      </div>
      {groupCount > 1 ?
        <div className='select-button'>
          <Button variant='primary' size='sm' disabled={groupCount - activeGroup <= 1} onClick={() => onGroupChange(activeGroup + 1)}>
            <FaAngleRight/>
          </Button>
        </div>
        : null
      }
    </div>
  );
};

CureApiOrderSelector.propTypes = {
  activeGroup: PropTypes.number.isRequired,
  groupCount: PropTypes.number.isRequired,
  onGroupChange: PropTypes.func.isRequired,
  orders: PropTypes.array.isRequired,
};

export default CureApiOrderSelector;
