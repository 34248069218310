import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import ModalWrapper from '../../common/ModalWrapper';
import FacilityGroupsPage from './FacilityGroupsPage'; //eslint-disable-line import/no-named-as-default

export class FacilityGroupsModal extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.onHide = this.onHide.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);

    this.state = {
      showModal: true,
      modalTitle: 'Hi',
    };

  }

  onClick(id){
    const ids = this.state.selectedIds.map((id) => id);
    const index = ids.indexOf(id);
    if(index === -1) {
      ids.push(id);
    } else {
      ids.splice(index, 1);
    }
    this.setState({selectedIds: ids});
  }

  onSave(){
    if(typeof this.props.onSave === 'function') this.props.onSave(this.state.selectedIds, this.props.modalCollection);
    this.onHide();
  }

  onCancel(){
    this.onHide();
  }

  onHide(){
    this.setState({showModal: false});
    if(typeof this.props.onHide === 'function') this.props.onHide();
  }

  render(){

    const {showModal} = this.props;
    const modalTitle = 'Facility Groups Manager';

    return (
      <ModalWrapper
        onHide={this.onHide}
        showModal={showModal}
        Component='div'
        title={I18n.t('common.select')}
        noTranslationTitle={modalTitle}
        dialogClassName='bg-success'
      >
        <FacilityGroupsPage {...this.props} clearGroupsOnUnmount={false} />
      </ModalWrapper>
    );
  }

}

FacilityGroupsModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onSave: PropTypes.func,
  onHide: PropTypes.func.isRequired,
  settingsReduxName: PropTypes.string.isRequired,
};

export default FacilityGroupsModal;
