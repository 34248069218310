import {I18n} from 'react-redux-i18n';

export const validate = (values, props) => {
  const errors = {};
  const ids_item_id = values ? values.productsMapping.map(product => product.item_id).filter(Boolean) : [];
  const ids_product_id = values ? values.productsMapping.map(product => product.product_id).filter(Boolean) : [];
  const productsArrayErrors = [];
  values.productsMapping.forEach((productName, productIndex) => {
    const productErrors = {};
    const activeMjpProduct = props.mjpProducts.some(item => item.id === productName.product_id);

    if (ids_item_id.filter(productid => productid === productName.item_id).length > 1) {
      productErrors.item_id = I18n.t('METRCProductManagement.form.validateMETRCProductsMultiple');
      productsArrayErrors[productIndex] = productErrors;
    }

    if (ids_product_id.filter(productid => productid === productName.product_id).length > 1) {
      productErrors.product_id = I18n.t('METRCProductManagement.form.validateMETRCProductsMultiple');
      productsArrayErrors[productIndex] = productErrors;
    }

    if ((productName.item_id) && (!(productName.product_id) || !activeMjpProduct)) {
      productErrors.product_id = I18n.t('METRCProductManagement.form.validateMJPProducts');
      productsArrayErrors[productIndex] = productErrors;
    }

    if (!(productName.item_id) && (productName.product_id)) {
      productErrors.item_id = I18n.t('METRCProductManagement.form.validateMETRCProducts');
      productsArrayErrors[productIndex] = productErrors;
    }
  });
  if (productsArrayErrors.length) {
    errors.productsMapping = productsArrayErrors;
  }
  return errors;
};

export default validate;
