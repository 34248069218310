import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {Button, Col, Row} from 'react-bootstrap';
import {FaMinus, FaPlus} from 'react-icons/fa';
import {I18n} from 'react-redux-i18n';
import TextInput from '../../common/form/TextInput';
import CountryField from '../../common/form/redux-form/CountryField';
import PostalCodeField from '../../common/form/redux-form/PostalCodeField';

export const NewDeliveryAddressFormGroup = (props) => {
  const {fields: {push, pop, length, map, get}, change, integrationState: {isOhMetrc}, addressFieldsDisabled} = props;
  const addressIsDisabled = !!addressFieldsDisabled;
  return (
    <Row>
      {map((address, index) => {
        const isLast = index + 1 === length;
        const field = get(index);
        const paddingBottom = index ? '30px' : '';
        const addressIsRequired = !index && isOhMetrc;
        return (
          <Col md={6} key={`address-${index}`}>
            <div style={{paddingBottom}}>
              <h4>{I18n.t('customers.create.addressNumber', {number: index + 1})}</h4>
              {!index && <div style={{position:'relative', top: '-8px'}} className='text-muted'>{I18n.t('customers.create.useForMailingAddress')}</div>}
            </div>
            <Row>
              <Col md={10}>
                <Field name={`${address}.street_address_1`} component={TextInput} props={{
                  label: I18n.t('customers.create.street'),
                  placeholder: addressFieldsDisabled ? '' : I18n.t('customers.placeholders.street'),
                  isRequired: addressIsRequired,
                  disabled: addressIsDisabled
                }}/>
              </Col>
              <Col md={2}>
                <Field name={`${address}.street_address_2`} component={TextInput} props={{
                  label: I18n.t('customers.create.apartment'),
                  placeholder: addressFieldsDisabled ? '' : I18n.t('customers.placeholders.apartment'),
                  disabled: addressIsDisabled
                }}/>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Field name={`${address}.city`} component={TextInput} props={{
                  label: field.country_code === 'AU' ? I18n.t('common.form.suburb') : I18n.t('common.form.city'),
                  placeholder: addressFieldsDisabled ? '' : (field.country_code === 'AU' ? I18n.t('common.form.suburb') : I18n.t('common.form.city')),
                  isRequired: addressIsRequired,
                  disabled: addressIsDisabled
                }}/>
              </Col>
              <CountryField
                name={`${address}.country_code`}
                stateFieldName={`${address}.province_code`}
                change={change}
                countryFieldSize={2}
                stateFieldSize={3}
                currentCountry={field.country_code}
                isRequired={addressIsRequired}
                disabled={addressIsDisabled}
                stateProps={{
                  isRequired: addressIsRequired
                }}
              />
              <Col md={3}>
                <PostalCodeField
                  name={`${address}.postal_code`}
                  countryCode={field.country_code}
                  isRequired={addressIsRequired}
                  disabled={addressIsDisabled}
                  canUseUsZipPlusFour={true}
                />
              </Col>
            </Row>
            {isLast &&
            <Col md={12} className='add-new-address-button-wrapper' >
              <div className='add-button-wrapper float-right'>
                <span>{I18n.t('customers.create.addAddress')}</span>
                <Button variant='primary' size='sm' disabled={addressIsDisabled} onClick={() => push({})}><FaPlus/></Button>
                {length > 1 &&
                <Button variant='primary' size='sm' disabled={addressIsDisabled} onClick={() => pop()}><FaMinus/></Button>
                }
              </div>
            </Col>
            }
          </Col>
        );
      })}
    </Row>
  );
};

NewDeliveryAddressFormGroup.propTypes = {
  fields: PropTypes.shape({
    push: PropTypes.func.isRequired,
    pop: PropTypes.func.isRequired,
    map: PropTypes.func.isRequired,
    length: PropTypes.number.isRequired,
    get: PropTypes.func.isRequired
  }),
  change: PropTypes.func.isRequired,
  integrationState: PropTypes.object.isRequired,
  addressFieldsDisabled: PropTypes.bool
};

export default NewDeliveryAddressFormGroup;
