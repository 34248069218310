import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row, Table} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import ContentConcealer from '../../common/concealers/ContentConcealer';

const PricePreviewSection = (props) => {
  const {sectionData} = props;
  const uom = I18n.t(`common.units.${get(sectionData, 'pricing_weight') === 'weight' ? 'gram' : 'unit'}`);
  return (
    <Row>
      <Col md={12} className='price-section'>
        <Row>
          <ContentConcealer show={[0, 1].indexOf(sectionData.is_non_taxable) !== -1}>
            <Col md={6} className='price-field'>
              <Row>
                <Col md={12}>
                  <label>{I18n.t('products.form.nonTaxableProduct')}</label>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  {sectionData.is_non_taxable ? 'Yes' : 'No'}
                </Col>
              </Row>
            </Col>
          </ContentConcealer>
          <ContentConcealer show={!!(sectionData.default_price && parseFloat(sectionData.default_price))}>
            <Col md={6} className='price-field'>
              <Row>
                <Col md={12}>
                  <label>{I18n.t('products.form.basePrice', {uom})}</label>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  {sectionData.default_price}
                </Col>
              </Row>
            </Col>
          </ContentConcealer>
          <ContentConcealer show={!!sectionData.pricing_class_name}>
            <Col md={6} className='price-field'>
              <Row>
                <Col md={12}>
                  <label>{I18n.t('products.massModify.productListing.pricingClass')}</label>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  {sectionData.pricing_class_name}
                </Col>
              </Row>
            </Col>
          </ContentConcealer>
          <ContentConcealer show={[0, 1].indexOf(sectionData.inherit_pricing_class_updates) !== -1}>
            <Col md={6} className='price-field'>
              <Row>
                <Col md={12}>
                  <label>{I18n.t('products.massModify.inherit_pricing_class')}</label>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  {sectionData.inherit_pricing_class_updates ? 'Yes' : 'No'}
                </Col>
              </Row>
            </Col>
          </ContentConcealer>
          <ContentConcealer show={!!sectionData.pricing_group_name}>
            <Col md={6} className='price-field'>
              <Row>
                <Col md={12}>
                  <label>{I18n.t('products.massModify.productListing.pricingGroup')}</label>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  {sectionData.pricing_group_name}
                </Col>
              </Row>
            </Col>
          </ContentConcealer>
          <ContentConcealer show={[0, 1].indexOf(sectionData.inherit_pricing_group_updates) !== -1}>
            <Col md={6} className='price-field'>
              <Row>
                <Col md={12}>
                  <label>{I18n.t('products.form.inheritGroupChanges')}</label>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  {sectionData.inherit_pricing_group_updates ? 'Yes' : 'No'}
                </Col>
              </Row>
            </Col>
          </ContentConcealer>
          <ContentConcealer show={!!sectionData.applicable_facilities}>
            <Col md={6} className='price-field'>
              <Row>
                <Col md={12}>
                  <label>{I18n.t('taxes.form.applicableFacilities')}</label>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  {sectionData.applicable_facilities}
                </Col>
              </Row>
            </Col>
          </ContentConcealer>
          <ContentConcealer show={sectionData.weight_prices.length > 0}>
            <Col md={6} className='price-field'>
              <Row>
                <Col md={10}>
                  <Table condensed striped bordered hover>
                    <thead>
                    <tr>
                      <th className='size'>{I18n.t('products.form.unit')}</th>
                      <th>{I18n.t('products.form.defaultPrice')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {sectionData.weight_prices.map((weight, index) => parseFloat(weight.default_price) ? (
                      <tr key={index}>
                        <td>{weight.name}</td>
                        <td>{parseFloat(weight.default_price).toFixed(2)}</td>
                      </tr>) : null)}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Col>
          </ContentConcealer>
        </Row>
      </Col>
    </Row>
  );
};

PricePreviewSection.propTypes = {
  sectionData: PropTypes.object.isRequired,
};

export default PricePreviewSection;
