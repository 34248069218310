import * as types from '../../constants/actionTypes';
import * as itemNames from '../../constants/itemNames';
import * as dataNames from '../../constants/dataNames';
import {getFacilityGroupsWithMemberDetails} from '../../selectors/forms/facilityGroupFormSelectors';

import {deleteData, getItem, getUnpaginatedData, postItem} from '../../actions/apiActions';

const facilityGroupForm = store => next => action => {
  const result = next(action);
  const {type, facilityGroup, formName} = action;

  if(type === types.SETTINGS_BIND_TO_FACILITY_GROUP) { // Either bind the facility group to these settings or remove
    if (facilityGroup.controlledSettings.length > 0) { // Remove any previously bound settings from facility group
      const deleteSettingIds = facilityGroup.controlledSettings.reduce((acc, setting) => {
        acc.push(setting.id);
        return acc;
      }, []);
      //@TODO: Makes this settings agnostic by getting the action or at least def of the action on the settings page
      store.dispatch(deleteData(`/api/facility_groups/${facilityGroup.id}/settings`, deleteSettingIds, null, null, {}, () => {
        store.dispatch(getUnpaginatedData('/api/facility_groups', dataNames.facilityGroups)); // Reload
        store.dispatch(getItem('/api/order_settings/sales_limit', itemNames.salesComplianceSettings)); // Reload
      }));

    } else { // Adding current setting values as defaults for group and bind the group to those settings
      const formData = store.getState().form[formName];
      formData.values.facilityId = store.getState().facility.id;
      const payload = action.getPayload(formData.values);
      const facilityGroupPayload = Object.assign({}, payload);
      facilityGroupPayload.settings = facilityGroupPayload.settings.map((setting) => {
        return Object.assign({}, setting, {scope: 'facilityGroup', scope_id: facilityGroup.id});
      });
      store.dispatch(postItem('/api/order_settings/sales_limit', facilityGroupPayload, null, null, {}, () => {
        store.dispatch(getUnpaginatedData('/api/facility_groups', dataNames.facilityGroups)); // Reload
      }));
    }
  }

  // Catch call to saving settings to update those that are facilityGroup locked to facility group and those that are over-rideable to the facility
  if(type === types.SETTINGS_SAVE) {
    const {path, payload, name, message, params, next, config} = action;
    // Call from config if present
    const onThen = (data) => {
      if(typeof config.onThen === 'function') config.onThen(data);
    };
    const onCatch = (e) => {
      if(typeof config.onCatch === 'function') config.onCatch(e);
    };

    const facilityGroups = getFacilityGroupsWithMemberDetails(store.getState(), {itemName: config.settingsReduxName});
    const group = facilityGroups.find((group) => group.controlsSettings);
    if(group){ // Split payload into those that can and cannot be over-ridden
      const payloads = group.controlledSettings.reduce((acc, setting) => {
        const key = (setting && setting.setting_value) ? setting.setting_value.setting_key : undefined;
        const payloadSetting = payload.settings.find((setting) => setting.setting_key === key);
        const index = payload.settings.indexOf(payloadSetting);
        payload.settings.splice(index, 1);
        if(setting.is_locked){
          payloadSetting.scope = 'facilityGroup';
          payloadSetting.scope_id = group.id;
        }
        acc[setting.is_locked ? 'facilityGroup' : 'facility'].push(payloadSetting);
        return acc;
      }, {facility: [], facilityGroup: []});
      //@TODO: Need a permission check here to see if the user can update the facilityGroupSettings
      store.dispatch(postItem(path, {settings: payloads.facilityGroup}))
        .then((data) => {
          if(payloads.facility.length === 0) onThen(data);
        })
        .catch((e) => {
          if(payloads.facility.length === 0) onThen(e);
        });
      if(payloads.facility.length > 0) { // Only dispatch if we have settings
        store.dispatch(postItem(path, {settings: payloads.facility}, name, message, params, next))
          .then(onThen)
          .catch(onCatch);
      }
    } else {
      store.dispatch(postItem(path, payload, name, message, params, next)).then(onThen).catch(onCatch);
    }
  }

  return result;
};

export default facilityGroupForm;
