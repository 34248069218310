import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Row, Col, Button} from 'react-bootstrap';
import {Field} from 'redux-form';
import AccordionPanel from '../../../common/AccordionPanel';
import SubmitSection from '../../../common/form/SubmitSection';
import TextInput from '../../../common/form/TextInput';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import { getOrderFulfillmentOptions } from '../../../../constants/orderFulfillmentMethods';
import {MED_CAREGIVER, MED_PATIENT} from '../../../../constants/idTypes';
import WillRender from '../../../common/concealers/WillRender';
import DatePickerInput from '../../../common/form/DatePickerInput';

const options = [
  {text: 'Caregiver', value: MED_CAREGIVER},
  {text: 'Patient', value: MED_PATIENT},
];

const fulfillment_options = getOrderFulfillmentOptions().filter((method) => {
  return !method.not_fulfillment_method;
});

const CheckinLeafMJPForm = (props) => {
  const {showScanModal, invalid, pristine, submitting, reset, handleSubmit, visitReasons, isUtahEvsEnabled} = props;
  return (
    <form onSubmit={handleSubmit} noValidate={true}>
      <AccordionPanel title='consumer.checkin.filter'>
        <Row>
          <Col xs={12}>
            <Button type='button' variant='success' onClick={showScanModal}>
              {I18n.t('customers.create.scanLicense')}
            </Button>
          </Col>
          <Col xs={12}><hr/></Col>
        </Row>
        <Row>
          <Col xs={6} sm={4} md={3}>
            <Field name='license_type' component={ReactSelectInput} props={{
              options,
              isRequired: true,
              label: I18n.t('consumer.checkin.licenseType'),
            }}/>
          </Col>
          <Col xs={6} sm={4} md={3}>
            <Field name='card_number' component={TextInput} props={{
              label: I18n.t('consumer.checkin.cardNumber'),
              isRequired: true,
            }}/>
          </Col>
          <WillRender ifTrue={isUtahEvsEnabled}>
            <Col xs={6} sm={4} md={2}>
              <Field name='dob' component={DatePickerInput} props={{
                label: I18n.t('consumer.checkin.dateOfBirth'),
                isRequired: isUtahEvsEnabled,
              }}/>
            </Col>
          </WillRender>
          <WillRender ifTrue={props.isCourierLicenseesToggled}>
            <Col xs={6} sm={4} md={2}>
              <Field name='fulfillment_method' component={ReactSelectInput} props={{
                options: fulfillment_options,
                isRequired: true,
                label: I18n.t('consumer.checkin.fulfillment_method'),
                placeholder: I18n.t('customer.title.inStore')
              }}/>
            </Col>
            <Col xs={6} sm={4} md={2}>
              <Field name='visit_reason_id' component={ReactSelectInput} props={{
                options: visitReasons,
                isRequired: true,
                label: I18n.t('consumer.checkin.queue'),
                textKey: 'name',
                valueKey: 'id',
              }}/>
            </Col>
          </WillRender>
        </Row>
        <SubmitSection settings={{actionSettings: {
          reset: {
            pristine,
            submitting,
            action: reset,
            type: 'reset',
            text: I18n.t('consumer.checkin.reset')
          },
          submit: {
            invalid,
            pristine,
            submitting,
            type: 'submit',
            style: 'primary',
            text: I18n.t('consumer.checkin.search')
          }
        }
        }}/>
      </AccordionPanel>
    </form>
  );
};

CheckinLeafMJPForm.propTypes = {
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  isCourierLicenseesToggled: PropTypes.bool,
  reset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  showScanModal: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  visitReasons: PropTypes.array.isRequired,
};

export default CheckinLeafMJPForm;
