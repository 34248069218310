import {requiredFieldValidation} from '../../../common/form/redux-form/validations';

export default function validate(values, ownProps) {
  const validation = {
    license_type: requiredFieldValidation(values.license_type),
    card_number: requiredFieldValidation(values.card_number)
  };

  if (ownProps.isCourierLicenseesToggled) {
    validation.visit_reason_id = requiredFieldValidation(values.visit_reason_id);
  }

  return validation;
}
