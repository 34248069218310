import React from 'react';
import PropTypes from 'prop-types';

const RenderPrevWeightHistory = (props) => {
  const {field} = props;
  return (Array.isArray(field.prev) && Array.isArray(field.current) ? field.prev.map(item1 => {
    const itter_item = field.current.find(item2 => item2.pricing_weight_id === item1.pricing_weight_id);
    if (itter_item && itter_item.default_price !== item1.default_price) {
      return (<tr key={item1.title}>
        <td>{item1.title}</td>
        <td>{parseFloat(item1.default_price).toFixed(2)} → {parseFloat(itter_item.default_price).toFixed(2)}</td>
      </tr>);
    } else if (!itter_item) {
      return (<tr key={item1.title}>
        <td>{item1.title}</td>
        <td>{parseFloat(item1.default_price).toFixed(2)} → null</td>
      </tr>);
    }
  }) : null);
};

RenderPrevWeightHistory.propTypes = {
  field: PropTypes.object.isRequired,
};

export default RenderPrevWeightHistory;
