import {createSelector} from 'reselect';
import get from 'lodash.get';
import sortby from 'lodash.sortby';
import {findRouteDefinition, findRoutePermissions} from '../util/routeHelper';
import {hasStateIntegrator, hasThirdPartyIntegrator} from './integration/commonSelectors';
import {APPS, reportingApp} from '../constants/apps';
import {getIntegrationState} from './integration/integrationSelectors';
import {isFeatureEnabled} from './featureToggles';
import {getFacilityHasModule, getFacility, isAppValidForRegulatorFacility} from './facilitiesSelectors';
import {getUserHasOneOfPermissionsFunction} from './usersSelectors';
import {getEnableDeliveryWorkflow} from './forms/homeDeliverySetupFormSelectors';
import * as permissions from '../constants/permissions';
import getCurrentFacility from './facility/getCurrentFacility';


/**
 * Get the env variables stored in the Redux state
 *
 * @param state
 */
const getEnv = (state) => state.env;


/**
 * Generates an array of all possible Platform "Apps"
 *
 * @return array
 */
const getApps = createSelector(getCurrentFacility, getEnv, (currentFacility, env) => {
  const sortedApps = sortby(APPS,[`sortIndex.${get(currentFacility,'type','dispensary')}`]);
  sortedApps.push(reportingApp(env));

  return sortedApps;
});


/**
 * Gets a list of Platform "Apps" w/ permissions and accessibility properties included
 *
 * @return array
 */
export const getAppsWithPermissions = createSelector(getApps, getIntegrationState, isFeatureEnabled, (apps, {isWaLeaf}, isFeatureEnabled) => {
  return apps.map(app => {
    if(app.path) {
      app.permissions = findRoutePermissions(app.path) || [];
      app.accessibility = findRouteDefinition(app.path).accessibility;

      if(isWaLeaf && app.id === 'plants'){
        app.path = '/plants/vegetation';
      }
    }

    // Build eligible nav paths (sub-navigation) for the App
    if (app.paths) {
      const pathsWithPermissions = app.paths.map(pathObj => {
        const routeDefinition = findRouteDefinition(pathObj.path);
        return {
          ...pathObj,
          permissions: routeDefinition.permissions,
          accessibility: routeDefinition.accessibility
        };
      });

      app.subPaths = pathsWithPermissions;
    }

    return app;
  });
});

export const getFilteredAppsWithPermissions = createSelector(
  [
    getAppsWithPermissions,
    hasStateIntegrator,
    hasThirdPartyIntegrator,
    isFeatureEnabled,
    getUserHasOneOfPermissionsFunction,
    getEnableDeliveryWorkflow,
    getFacilityHasModule,
    getFacility,
  ],
  (
    apps,
    hasIntegrator,
    hasThirdPartyIntegrator,
    isFeatureEnabled,
    getUserHasOneOfPermissionsFunction,
    enableHomeDeliveryWorkflow,
    facilityHasModule,
    facility
  ) => {
    return apps.filter(app => {
      if (
        !isAppValidForRegulatorFacility(app, facility) ||
        isIntegrationAppAndNoIntegration(app, hasIntegrator, hasThirdPartyIntegrator) ||
        isAnalyticsAppAndFeatureToggleDisabled(app, isFeatureEnabled) ||
        isDeliveryAppAndFeatureToggleDisabled(app, isFeatureEnabled) ||
        isReportingAppAndFeatureToggleDisabled(app, isFeatureEnabled) ||
        isSalesReportsAppAndUserLacksPermission(app, isFeatureEnabled, getUserHasOneOfPermissionsFunction) ||
        isDeliveryAppAndWorkflowIsDisabled(app, enableHomeDeliveryWorkflow) ||
        !facilityHasModules(app, facilityHasModule)
      ) {
        return false;
      }

      return true;
    });
  }
);



const isSalesReportsAppAndUserLacksPermission = (app, isFeatureEnabled, getUserHasOneOfPermissions) => {
  if (app.id !== 'salesReport') return false;

  const featureToggledPermissions = {
    [permissions.manage_report_sales_summary]: 'feature_sales_summary_report',
    [permissions.manage_report_cash_on_hand]: 'feature_show_bi_platform_reports',
    [permissions.manage_report_inventory_transactions]: 'feature_show_bi_platform_reports'
  };

  const filteredPermissions = app.permissions.filter((permission) => {
    if (featureToggledPermissions[permission]) {
      return isFeatureEnabled(featureToggledPermissions[permission]);
    }

    return true;
  });

  return !getUserHasOneOfPermissions(filteredPermissions);
};

const isDeliveryAppAndWorkflowIsDisabled = (app, enableHomeDeliveryWorkflow) =>
  (app.id === 'delivery' && enableHomeDeliveryWorkflow == false);

const isIntegrationAppAndNoIntegration = (app, hasIntegrator, hasThirdPartyIntegrator) =>
  // Do not include the Integration link if a state integrator like Leaf or
  // METRC is not configured.
  (app.id === 'integration' && !(hasIntegrator || hasThirdPartyIntegrator));

const isAnalyticsAppAndFeatureToggleDisabled = (app, isFeatureEnabled) =>
  (app.id === 'analytics' && !isFeatureEnabled('feature_analytics_reporting'));

const isDeliveryAppAndFeatureToggleDisabled = (app, isFeatureEnabled) =>
  (app.id === 'delivery' && !isFeatureEnabled('feature_courier_licensees'));

const isReportingAppAndFeatureToggleDisabled = (app, isFeatureEnabled) =>
  (app.id === 'reporting' && !isFeatureEnabled('feature_qlik_reporting'));

const facilityHasModules = (app, facilityHasModule) => {
  if (app.modules && app.modules.length) {
    for (const key in app.modules) {
      if (facilityHasModule(app.modules[key]) == false) {
        return false;
      }
    }
  }

  return true;
};
