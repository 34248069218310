import {getData} from '../actions/apiActions';
import {FEATURE_TOGGLE_UPDATED} from '../constants/actionTypes';
import * as dataNames from '../constants/dataNames';

// eslint-disable-next-line
export const featureToggleUpdated = store => next => action => {
  const result = next(action);

  if (action.type === FEATURE_TOGGLE_UPDATED) {
    store.dispatch(getData('/api/feature_toggles', dataNames.featureToggles));
  }

  return result;
};
