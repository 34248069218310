/* eslint-disable indent */
// Disabled for switch statements that linting never seems to like.
import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import {FaPrint} from 'react-icons/fa';
import receiptStatusMessages from '../../../../constants/receiptStatusMessages'; //eslint-disable-line import/no-named-as-default
import * as itemNames from '../../../../constants/itemNames';
import * as dataNames from '../../../../constants/dataNames';
import * as apiActions from '../../../../actions/apiActions';
import {convertDbDateToFormInputDate} from '../../../../util/dateHelpers';
import {setItem, unsetItem} from '../../../../actions/itemActions';
import {setData, unsetData} from '../../../../actions/dataActions';
import {getQzTray, signQzRequest} from '../../../../actions/printerActions';
import MessageModal from '../../../common/modal-messages/MessageModal';
import SetPrinterModal from '../../../printer/setprinter/SetPrinterModal'; //eslint-disable-line import/no-named-as-default
import {PERSON} from '../../../../constants/imageUrls';
import {getReceiptOrderWithTaxes} from '../../../../selectors/ordersSelectors';
import escpos from '../../../../util/escpos/escpos';
import {getUrlParam} from '../../../../util/routeHelper';


class ReceiptPage extends React.PureComponent{

  constructor(props, context) {

    super(props, context);

    this.state = {
      // Currently Unused
      printHtml: false,
      printZpl : true,

      // View and Print Receipt
      showReceipt     : true,
      modalReceipt    : false,
      modalReceiptOpen: false,

      // Print Receipt Only
      printReceipt: false,

      // Modal messages
      showModal      : false, // Status
      modalMessage   : false,
      modalTitle     : 'Status',
      modalType      : 'info',
      progressMessage: false,

      // Internal
      buildingReceiptId  : false,
      receiptReady       : false,
      detailedOrder      : false,
      categorizedProducts: false,

      receiptCategories: [],

      smallFontSize: '12px',
      largeFontSize: '12px',
      topOffset: '50px',
      printer      : false,
      printParams: {},
      testPrint: false, // testPrint flag only for arbitrary property testing to be removed
      base64: false,

      receipt: {},
      receiptImage: false,
    };

    this.getOrder = this.getOrder.bind(this);
    this.qzPrint = this.qzPrint.bind(this);
    this.hideModal = this.hideModal.bind(this);

  }

  componentWillMount(){

    this.resetState();
    this.props.actions.getQzTray(this.props.actions.signQzRequest); // loads qz into redux
    this.props.actions.getUnpaginatedData('/api/prepack_weights', dataNames.prepackWeights, {failed:'packaging.getPrepackWeights.failed'});
    if(!this.props.embedded) this.buildReceipt(); // Only when on test page
    this.parseConfigurationString();

  }

  componentWillReceiveProps(nextProps){
    // Print Receipt - Pops Modals For Progress
    if(nextProps.printReceipt && !this.state.buildingReceiptId && !this.state.receiptReady) {
      this.setState({
        printReceipt: true,
        buildingReceiptId: nextProps.orderId
      }, () => {
        this.getOrder(nextProps);
      });
    }

    // Show Receipt - Pops single modal with receipt and print option
    if(nextProps.showReceipt && !this.state.buildingReceiptId && !this.state.receiptReady){
      this.setState({
        showReceipt: nextProps.showReceipt,
        showModal: false,
        receiptReady: false,
        modalReceipt: nextProps.showReceipt,
        buildingReceiptId: nextProps.orderId,
        modalReceiptOpen: true
      }, () => {
        this.getOrder(nextProps);
      });
    }

  }

  parseConfigurationString(configString = false){

    const getParamMethod = (configString) ? this.getStringParam : getUrlParam;

    // Passing configurables as comma delimited list of params tell us to look for these in particular
    const configurables = ['configParams', 'dataParams', 'outputParams'];

    // Default values we will always check for
    const configParams = [
      'width',
      'height',
      'colorType',
      'interpolation',
      'scaleContent',
      'units',
      'orientation'
    ];

    const calculateHeightValue = 1.33;

    const printParams = {
      configParams: {},
      dataParams: {},
      outputParams: {
        type: 'pdf'
      },
    };

    const setCalculatedHeight = (object, width) => {
      return object['height'] = parseFloat(width) * calculateHeightValue;
    };

    // Helper flag to calculate height from width as odd ratios produce odd results
    const calculateHeight = (getParamMethod('calculateHeight', configString) !== undefined);

    // Get the adhoc injected params if any
    configurables.forEach((type) => {
      const temp = getParamMethod(type, configString);
      if(typeof temp !== 'string') return false;
      const params = temp.split(',');
      params.forEach((p) => {
        const value = getParamMethod(p, configString);
        if(value) {
          if(p === 'width' && calculateHeight) setCalculatedHeight(printParams[type], value);
          printParams[type][p] = value;
        }
      });
    });

    // Get standard config params if present
    configParams.forEach((p) => {
      const value = getParamMethod(p, configString);
      if(value) {
        if(p === 'width' && calculateHeight) setCalculatedHeight(printParams['configParams'], value);
        printParams['configParams'][p] = value;
      }
    });

    return new Promise((resolve) => {
      this.setState({printParams, testPrint: true}, () => {
        resolve(printParams);
      });
    });

  }

  getStringParam(param, string){
    const params = {};
    const parts = string.split('&');
    for (let i = 0; i < parts.length; i++) {
      const nv = parts[i].split('=');
      if (!nv[0]) continue;
      params[nv[0]] = nv[1] || true;
    }
    return (params[param] !== undefined) ? params[param] : undefined;
  }

  // Build Receipt
  getOrder(props = false){

    if(!props) props = this.props;

    const labelKey = 'RETAIL_RECEIPT';
    const printer = SetPrinterModal.getPrinterForLabel(labelKey);
    this.setState({printer: printer});

    if(this.props.embedded) this.statusMessage(receiptStatusMessages.building);

    const messages = {failed: 'retail.receipt.failed'};
    const id       = (this.props.embedded) ? this.state.buildingReceiptId : this.props.params.id;


    if(this.state.testPrint && this.state.printParams.outputParams.type === 'escpos') {
      this.props.actions.getItem(`/api/orders/receipt/${id}`, itemNames.receiptOrder, messages, {detailed: true}, () => {
        this.getOrderCategories();
      });
    } else {
      this.getReceipt().then(() => {
        if(props.printReceipt) this.qzPrint();
      });
    }

  }

  getReceipt(){
    const messages = {failed: 'retail.receipt.failed'};
    const id       = this.props.orderId;
    return new Promise((resolve) => {
      const params = (Object.keys(this.state.printParams.outputParams).length > 0) ? this.state.printParams.outputParams : {};
      this.props.actions.getItem(`/api/orders/receipt/${id}/pdf`, 'noOp', messages, params, (receipt) => {
        this.setState({base64: receipt.base64, receipt: receipt, receiptImage: receipt.image}, () => {
          resolve(receipt);
          this.statusMessage(receiptStatusMessages.buildingComplete);
        });
      });
    });
  }

  getOrderCategories(){
    if(Object.keys(this.props.order).length === 0) return false;

    const categories    = [];

    this.props.order.order.products.forEach( (product) => {
      const foundCategory = categories.find( (category) => {
        return category.id === product.category_id && category.subId === product.subcategory_id;
      });
      if(!foundCategory){
        const cat = {id: product.category_id, name: product.category_name, subId: product.subcategory_id, subName: product.subcategory_name};
        categories.push(cat);
      }

    });

    this.setState({receiptCategories: categories});

    if(!this.props.embedded) return true;

    if(!this.state.showReceipt) return this.qzPrint();

    this.statusMessage(receiptStatusMessages.buildingComplete, {receiptReady: true, buildingReceiptId: false});

  }

  printBySelectedFormat(printer) {
    const method = (this.state.printParams.outputParams.type === 'escpos') ? 'printEscPosFormat' : 'printPdfFormat';
    this[method](printer);
  }

  printEscPosFormat(printer){

    //@TODO:  Extract all this to the escpos lib and integrate somehow with html version to minimize duplication this creates
    const qz = this.props.qzTray;
    const pos = new escpos();

    pos.setCommands();
    pos.setCharactersPerLine((printer.characters_per_line !== undefined) ? parseInt(printer.characters_per_line) : 48);

    const columns = [60,40]; // default columns

    if(printer.first_column_width !== undefined){ // adjust columns
      const firstColumnWidth = parseInt(printer.first_column_width);
      columns[0] = firstColumnWidth < 90 ? firstColumnWidth : 60;
      columns[1] = 100 - firstColumnWidth;
    }

    pos.setColumn(columns[0]);
    pos.setColumn(columns[1]);

    const getProductsForCategory = (category) => {
      let products = [];
      if (this.props.order.order.products !== undefined) {
        products = (this.props.order.order.products.length === 0 || Object.keys(category).length === 0)
          ? this.props.order.order.products
          : this.props.order.order.products.reduce((acc, product) => {

            if (product.category_id !== category.id || product.subcategory_id !== category.subId) return acc;

            if (product.items === undefined) {
              acc.push(product);
              return acc;
            }

            if (product.items.length === 0) {
              acc.push(product);
              return acc;
            }

            acc.push(product);
            return acc;

          }, []);
      }
      return products;
    };

    const getProductPrice = (product) => {
      const discountTotal = parseFloat(product.discount_total);
      if (!discountTotal) {
        return `$${(parseFloat(product.unit_price) * parseInt(product.quantity)).toFixed(2)}`;
      }
      return `$${(parseFloat(product.unit_price) * parseInt(product.quantity) - parseFloat(product.discount_total)).toFixed(2)}`;
    };

    const getWeight = (item) => {
      if (item.prepack_weight_id === null) return '';
      const weight = this.props.prepackWeights.find((weight) => {
        return weight.id === item.prepack_weight_id;
      });
      return (weight) ? weight.name : '';
    };

    const sortPayments = (payments) => {
      const orderBy = ['cash', 'credit', 'debit', 'giftcard', 'check'];
      if (!payments.length) return [];
      const filteredPayments = payments.filter((payment) => {
        return payment.payment_name.toLowerCase() !== 'change';
      });
      return filteredPayments.sort((a, b) => {
        return orderBy.indexOf(a.payment_type) > orderBy.indexOf(b.payment_type);
      });
    };

    const getChangeDue = (payments) => {
      if (payments === undefined) return 0;
      let changeDue = 0;
      payments.forEach((payment) => {
        if (payment.payment_type.toLowerCase() === 'cash' && payment.payment_name.toLowerCase() === 'change') {
          changeDue = payment.amount;
        }
      });
      return changeDue;
    };

    const isNotValidString = (value) => {
      if (typeof value !== 'string') return true;
      return value.trim() === '';
    };

    // Header
    const facility = this.props.facility;
    const currentDate = moment(new Date()).format('M/D/Y');
    const orderDate = (Object.keys(this.props.order.order).length > 0)
      ? this.props.order.order.order_status_date !== null
        ? convertDbDateToFormInputDate(this.props.order.order.order_status_date, this.props.timezone)
        : currentDate
      : currentDate;

    pos.startLine().rightText().text(orderDate).lf();
    if(!isNotValidString(facility.name)) pos.startLine().rightText().text(facility.name).lf();
    if(!isNotValidString(facility.street_address_1)) pos.startLine().rightText().text(facility.street_address_1).lf();
    if (!isNotValidString(facility.street_address_2)) {
      pos.startLine().rightText().text(facility.street_address_2).lf();
    }
    if(!isNotValidString(facility.city) && !isNotValidString(facility.province_code) && !isNotValidString(facility.postal_code)) {
      pos.startLine().rightText().text(`${facility.city}, ${facility.province_code}  ${facility.postal_code}`).lf();
    }
    pos.startLine().drawLine().lf();
    pos.startLine().centerText().text(`Order ID: ${this.props.order.order.name}`).lf();
    pos.startLine().centerText().text(`Customer ID: ${this.props.order.customer.customer_id}`).lf();
    pos.startLine().centerText().text(`Employee ID: ${this.props.order.employee.employee_id}`).lf();
    pos.startLine().drawLine().leftText().lf();
    pos.startLine().text('Order Summary').lf();
    pos.startLine().drawLine().lf();
    this.state.receiptCategories.forEach((cat) => {
      pos.startLine().text(`${cat.name} ${cat.subName}`).lf(2);
      const products = getProductsForCategory(cat);
      products.forEach((product) => {
        pos.startLine().text([`${product.name}`, `${getProductPrice(product)}`]).lf();
        product.items.forEach((item) => {
          pos.startLine().text(`   ${item.package_code} ${item.qty} ${item.uom_display} ${getWeight(item)}`).lf();
        });
      });
    });
    pos.startLine().drawLine().lf();

    const footerFields = {
      order_subtotal: 'Sub Total',
      discount_total: 'Discounts',
      coupon_total: 'Coupons',
      taxes: false,
      order_total: 'Total',
      payments: false,
    };

    for (const field in footerFields) {
      if (footerFields[field]) {
        pos.startLine().text([`${footerFields[field]}:`, `$${parseFloat(this.props.order.order[field]).toFixed(2)}`]).lf();
      } else {
        if (field === 'payments') {
          const payments = sortPayments(this.props.order.order.payments);
          payments.forEach((payment) => {
            pos.startLine().text([`${I18n.t('cart.payment.' + payment.payment_type)}:`, `$${parseFloat(payment.amount).toFixed(2)}`]).lf();
          });
          pos.startLine().drawLine().lf();
          pos.startLine().text(['Change Due:', `$${parseFloat(getChangeDue(this.props.order.order.payments)).toFixed(2)}`]).lf();
        } else {
          this.props.order.order[field].forEach((line) => {
            pos.startLine().text([
              `${I18n.t('cart.totals.taxDetailed', {
                name: line.tax && line.tax.tax_name || '',
                rate: (line.tax && line.tax.amount * 100 || 0).toFixed(2)
              })}`,
              `$${parseFloat(line.amount).toFixed(2)}`
            ]).lf();
          });
        }
      }
    }

    const footerText = (this.props.salesComplianceSettings
    && this.props.salesComplianceSettings.order_receipt_footer_text
    && this.props.salesComplianceSettings.order_receipt_footer_text.value)
      ? this.props.salesComplianceSettings.order_receipt_footer_text.value
      : '';

    if(typeof this.props.order.order.note === 'string'){
      if(this.props.order.order.note.trim() !== ''){
        pos.startLine().drawLine('+').lf();
        pos.startLine().text(`Notes: ${this.props.order.order.note}`).lf();
        pos.startLine().drawLine('+').lf();
      }
    }

    pos.startLine().lf(2);
    if(footerText !== ''){
      pos.startLine().centerText().text(footerText);
    }
    pos.startLine().lf(10);
    pos.partialCut();

    this.qzConnect().then(() => {

      this.statusMessage(receiptStatusMessages.printing);

      if(!this.state.testPrint || this.state.printParams.outputParams.type === 'escpos') {
        const pData = pos.getData();
        const config = qz.configs.create(printer.name);
        qz.print(config, pData)
          .then(() => {
            this.statusMessage(receiptStatusMessages.printingComplete);
          })
          .catch(() => {
            this.statusMessage(receiptStatusMessages.noTray);
          });
        return true;
      }

      this.printPdfFormat(printer); // safety

    });

  }

  printPdfFormat(printer){

    const qz = this.props.qzTray;
    this.qzConnect().then(() => {

      this.statusMessage(receiptStatusMessages.printing);

      const defaultConfig = {
        scaleContent: true,
        copies: 1,
        margins: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0
        },
        size: {
          width: 2.8,
          height: 10
        },
        units: 'in'
      };

      if(this.state.printParams.configParams !== undefined) {
        for (const p in this.state.printParams.configParams) {
          if((p === 'width' || p === 'height') && this.state.printParams.configParams[p]){
            defaultConfig.size[p] = parseFloat(this.state.printParams.configParams[p]);
            continue;
          }
          if(this.state.printParams.configParams[p]) defaultConfig[p] = this.state.printParams.configParams[p];
        }
      }

      let data;
      if(this.state.receipt.images === undefined) {
        data = [{
          type: (this.state.printParams.outputParams.type === 'image') ? 'image' : 'pdf',
          format: 'base64',
          data: this.state.base64
        }];
      } else { // If the receipt is images and multiple pages, add all
        data = [];
        this.state.receipt.images.forEach((image) => {
          data.push({
            type: 'image',
            format: 'base64',
            data: image
          });
        });
      }

      const config = qz.configs.create(printer.name, defaultConfig);

      qz.print(config, data)
        .then(() => {
          this.statusMessage(receiptStatusMessages.printingComplete);
        })
        .catch(() => {
          this.statusMessage(receiptStatusMessages.noTray);
        });
      return true;

    });

  }

  qzConnect(){

      const qz = this.props.qzTray;

      return new Promise((resolve, reject) => {
        this.statusMessage(receiptStatusMessages.connectingQzTray);
        if (qz.websocket.isActive()) resolve();
        qz.websocket.connect().then(() => {
          resolve();
        }).catch(() => {
          reject();
        });
      });

  }

  qzPrint(event){
    if(event) {
      event.stopPropagation();
      event.preventDefault();
      event.target.blur();
    }
    const labelKey = 'RETAIL_RECEIPT';
    const printer = SetPrinterModal.getPrinterForLabel(labelKey);
    if(!printer){
      this.statusMessage(receiptStatusMessages.noPrinter);
      return true;
    }

    const printParams = (printer.printParams === undefined)
      ? false
      : printer.printParams.trim() === '' ? false : printer.printParams;
    this.parseConfigurationString(printParams)
      .then((printParams) => {
        if(printParams.outputParams.type === 'escpos') return this.printBySelectedFormat(printer);
        this.setState({printer: printer}, () => {
          this.printBySelectedFormat(printer);
        });
      });
  }

  // Helpers

  resetState(){
    this.props.actions.unsetItem(itemNames.receiptOrder);
    this.props.actions.unsetData(dataNames.receiptCategories);
    this.setState({receiptReady: false, modalReceiptOpen: false, buildingReceiptId: false, showReceipt: false, printReceipt: false});
  }

  statusMessage(type, addState){

    if(addState === undefined) addState = {};

    let newState = {showModal: true};

    switch(type){

      case receiptStatusMessages.building:
        newState = Object.assign({}, newState, {
          progressMessage: I18n.t('orderReceipt.printing.buildingReceiptProgress'),
          modalTitle     : (this.props.showReceipt) ? 'Receipt' : I18n.t('orderReceipt.printing.buildingReceiptTitle'),
          modalMessage   : false,
          modalType      : ''
        });
        break;
      case receiptStatusMessages.buildingComplete:
        newState = Object.assign({}, newState, {
          progressMessage: false,
          modalTitle: 'Receipt'
        });
        break;
      case receiptStatusMessages.fetching:
        newState = Object.assign({}, newState, {
          progressMessage: I18n.t('orderReceipt.printing.fetchingReceipt'),
          modalTitle     : (this.props.showReceipt) ? 'Receipt' : I18n.t('orderReceipt.printing.fetching'),
          modalMessage   : false,
          modalType      : ''
        });
        break;
      case receiptStatusMessages.connectingQzTray:
        newState = Object.assign({}, newState, {
          progressMessage: 'Connecting To QZ Tray'
        });
        break;

      case receiptStatusMessages.printing:
        newState = Object.assign({}, newState, {
          progressMessage: I18n.t('orderReceipt.printing.printingReceiptProgress'),
          modalTitle     : I18n.t('orderReceipt.printing.printingReceiptTitle'),
          modalMessage   : false,
          modalType      : ''
        });
        break;
      case receiptStatusMessages.printingComplete:
        newState = Object.assign({}, newState, {
          progressMessage: false,
          modalTitle: 'Sent To Printer',
          modalMessage: 'Printing should be complete shortly.',
          modalType: 'success'
        });
        break;

      case receiptStatusMessages.noTray:
        newState = Object.assign({}, newState, {
          progressMessage: false,
          modalTitle: I18n.t('orderReceipt.printing.noQzTrayTitle'),
          modalMessage: I18n.t('orderReceipt.printing.noQzTrayMessage'),
          modalType: 'error'
        });
        break;
      case receiptStatusMessages.noPrinter:
        newState = Object.assign({}, newState, {
          progressMessage: false,
          modalTitle: I18n.t('orderReceipt.printing.noDefaultPrinterTitle'),
          modalMessage: I18n.t('orderReceipt.printing.noDefaultPrinterMessage'),
          modalType: 'danger'
        });
        break;
      case receiptStatusMessages.error:
        newState = Object.assign({}, newState, {
          progressMessage: false,
          modalTitle: 'Error',
          modalMessage: 'An unhandled error has occurred.  Please try again.',
          modalType: 'danger'
        });
        break;

    }

    if(Object.keys(newState).length === 1) return true;

    newState = Object.assign({}, newState, addState);

    this.setState(newState);

  }

  hideModal(){
    this.resetState();
    this.setState({showModal: false, modalReceipt: false, modalReceiptOpen: false, detailedOrder: false});
  }
  // END HELPERS

  render(){

    const receiptStyle = (!this.props.embedded) ? {} : {display: 'none'};

    return (
      <div style={{width: '300px'}}>
        {
          this.props.embedded
          ? null
            : (<button onClick={(event) => {this.qzPrint(event);}} className='btn btn-primary btn-block'>Print</button>)
        }
        <div id='orderReceiptPrintablexxx' style={receiptStyle}>
          <MessageModal
            translate={false}
            onHide={this.hideModal}
            dialogClassName='modal-dialog modal-md'
            showModal={this.state.showModal}
            message={this.state.modalMessage}
            title={this.state.modalTitle}
            modalType={this.state.modalType}>
            {
              !this.state.progressMessage
              ? null
                : (
                  <div className='col-md-12'>
                    <div className='progress progress-striped active'>
                      <div style={{width: '100%'}} className='progress-bar progress-bar-warning'  role='progressbar' aria-valuenow='100' aria-valuemin='0' aria-valuemax='100'>
                        {this.state.progressMessage}
                      </div>
                    </div>
                  </div>
                )
            }
            {
              !this.state.modalReceipt
              ? null
                : (
                <div className='col-md-12' >
                  {
                    this.state.receiptImage
                      ? (<div style={{textAlign:'center'}}>
                      <button style={{margin: '5px auto', width: '72mm'}} onClick={(event) => {this.qzPrint(event);}} className='btn btn-primary'><FaPrint style={{marginRight: '5px'}} />
                        Print
                      </button>
                      <div style={{textAlign:'center'}}>
                      <img alt='receipt' style={{width:'72mm', border: '1px solid #ccc', padding: '5px'}} src={`data:image/png;base64,${this.state.receiptImage}`} />
                    </div>
                      <button style={{margin: '5px auto', width: '72mm'}} onClick={(event) => {this.qzPrint(event);}} className='btn btn-primary'><FaPrint style={{marginRight: '5px'}} />
                        Print
                      </button>
                      {
                        !this.state.progressMessage
                          ? null
                          : (
                          <div className='col-md-12'>
                            <div className='progress progress-striped active'>
                              <div style={{width: '100%'}} className='progress-bar progress-bar-warning'  role='progressbar' aria-valuenow='100' aria-valuemin='0' aria-valuemax='100'>
                                {this.state.progressMessage}
                              </div>
                            </div>
                          </div>
                        )
                      }
                      {
                        !this.state.modalMessage
                          ? null
                          : <div>{this.state.modalMessage}</div>
                      }
                    </div>)
                      : null
                  }
                </div>
              )
            }
          </MessageModal>

        </div>
      </div>
    );

  }

}


ReceiptPage.propTypes = {
  prepackWeights: PropTypes.array.isRequired,
  showReceipt: PropTypes.bool,
  facility: PropTypes.object.isRequired,
  embedded: PropTypes.bool,
  orderId: PropTypes.number,
  qzTray: PropTypes.object.isRequired,
  order: PropTypes.object,
  params: PropTypes.object,
  actions: PropTypes.object,
  customer: PropTypes.object.isRequired,
  facilityImageUrl: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  salesComplianceSettings: PropTypes.object.isRequired,
};

// ReceiptPage.contextTypes = {
//   router: React.PropTypes.object.isRequired
// };

ReceiptPage.defaultProps = {
  facilityImageUrl: PERSON
};

function mapStateToProps(state) {

  const {customer, facility, categories, orderType,
     receiptCategories, receiptCats, qzTray, prepackWeights, image, timezone} = state;
  const facilityImageUrl = image && image.url ? image.url.original : undefined;
  return {
    timezone,
    customer: customer,
    order: getReceiptOrderWithTaxes(state),
    categories: categories,
    facility,
    orderType,
    receiptCategories,
    receiptCats,
    qzTray,
    prepackWeights,
    facilityImageUrl,
    salesComplianceSettings: state[itemNames.salesComplianceSettings]
  };

}


function mapDispatchToProps(dispatch) {

  const actions = Object.assign({}, apiActions, {setItem, setData, getQzTray, signQzRequest, unsetItem, unsetData});

  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptPage);
