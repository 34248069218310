import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import keyToPermissionsMap from '../../../constants/gridSettingKeyToPermMaps';
import TableComponent from './TableComponent';
import {userHasPermission} from '../../../selectors/permissionsSelectors';

function mapStateToProps(state, ownProps) {
  const permissionKey = ownProps.settingKey && keyToPermissionsMap[ownProps.settingKey] !== undefined
    ? keyToPermissionsMap[ownProps.settingKey]
    : false;
  const showSelectionMode = (permissionKey) // Determines if selection checkbox/radio shown by perms
    ? Array.isArray(permissionKey) && !permissionKey.length
      ? true // If key is present and empty array is the value, no permission is required
      : userHasPermission(state, {permissionKey})
    : false;
  return {...ownProps, columns: ownProps.columns, showSelectionMode};
}

const Table = connect(mapStateToProps, null, null, {withRef: true})(TableComponent);

Table.propTypes = {
  bstProps: PropTypes.object,
  keyField: PropTypes.string,
  sizePerPage: PropTypes.number,
  remoteOptions: PropTypes.shape({
    onPageChange: PropTypes.func,
    onSortChange: PropTypes.func,
    dataTotalSize: PropTypes.number,
    page: PropTypes.number,
  }),
  searchString: PropTypes.string,
  selectedRows: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  dataState: PropTypes.string,
  columns: PropTypes.array.isRequired,
  handleSelect: PropTypes.func.isRequired,
  noSelectionMode: PropTypes.bool,
};

export default Table;
