import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import {requiredFieldValidation, checkDateOfBirthValidation} from '../../../common/form/redux-form/validations';

export const validate = (values, props) => {
  const errors = {
    birth_date: props.requireBirthdateForAnonymousOrders && requiredFieldValidation(values.birth_date),
    type: requiredFieldValidation(values.type)
  };

  if (values.birth_date) {
    errors.birth_date = checkDateOfBirthValidation(values.birth_date, values.type, props.ageLimit);
  }

  // check if DL is valid
  if(values.type === 'recreational'){
    const now = new moment();
    if(!props.allowRecWithExpiredDriversLicense) {
      errors.expiration_date = requiredFieldValidation(values.expiration_date);
      if (values.expiration_date && typeof values.expiration_date === 'object' && values.expiration_date.isBefore(now)) {
        errors.expiration_date = I18n.t('orders.anonymous.recDlInvalidError');
      }
    }
  }

  return errors;
};

export default validate;
