import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import * as ReactIcons from 'react-icons/fa';
import * as ReactBiIcons from 'react-icons/bi';
import * as ReactPiIcons from 'react-icons/pi';
import * as ReactFa6Icons from 'react-icons/fa6';
import * as ReactMdIcons from 'react-icons/md';
import PermissionWrapper from '../common/PermissionWrapper';
import AppNavigationCard from './AppNavigationCard';
import {getFilteredAppsWithPermissions} from '../../selectors/appsSelectors';


class AppNavigationCardList extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {availableApps} = this.props;

    return (
      <React.Fragment>
        {availableApps.map((app, id) => {
          // Mapping from Glyphicon to ReactIcon
          const iconMapping = [
            {key: 'briefcase', icon: ReactIcons.FaBriefcase},
            {key: 'check', icon: ReactIcons.FaCheck},
            {key: 'cog', icon: ReactIcons.FaCog},
            {key: 'edit', icon: ReactIcons.FaEdit},
            {key: 'flag', icon: ReactIcons.FaFlag},
            {key: 'folder-open', icon: ReactIcons.FaFolderOpen},
            {key: 'leaf', icon: ReactIcons.FaCannabis},
            {key: 'link', icon: ReactIcons.FaLink},
            {key: 'list', icon: ReactIcons.FaList},
            {key: 'object-align-left', icon: ReactPiIcons.PiAlignLeftBold},
            {key: 'object-align-vertical', icon: ReactPiIcons.PiAlignCenterHorizontalBold},
            {key: 'option-horizontal', icon: ReactIcons.FaEllipsisH},
            {key: 'road', icon: ReactIcons.FaRoad},
            {key: 'scale', icon: ReactFa6Icons.FaWeightScale},
            {key: 'search', icon: ReactIcons.FaSearch},
            {key: 'share', icon: ReactIcons.FaShareSquare},
            {key: 'signal', icon: ReactIcons.FaSignal},
            {key: 'stats', icon: ReactIcons.FaChartBar},
            {key: 'th-large', icon: ReactMdIcons.MdInventory},
            {key: 'th-list', icon: ReactIcons.FaList},
            {key: 'tint', icon: ReactBiIcons.BiTestTube},
            {key: 'user', icon: ReactIcons.FaUserFriends},
          ];

          const IconComponent = iconMapping.find((icon) => icon.key === app.glyph);

          return (
            <PermissionWrapper key={id} permissions={app.permissions} accessibility={app.accessibility}>
              {app.url ?
                // NOTE: This app.url and <a> tag rendering logic was copied from the LandingPage and may require
                // some tweaks when this component is actually implemented in REBEL-521, to jive with
                // the new AppNavigationCard styling
                <a href={app.url} target='_blank' className='layout-column layout-align-start-center app-tile'>
                  <IconComponent.icon width='50px' height='50px'/>
                  <span className='app-name'>{I18n.t(`nav.${app.name}`)}</span>
                </a> :

                <AppNavigationCard
                  app={app}
                />
              }
            </PermissionWrapper>
          );
        })}
      </React.Fragment>
    );
  }
}

AppNavigationCardList.propTypes = {
  availableApps: PropTypes.array
};

const mapStateToProps = (state) => ({
  availableApps: getFilteredAppsWithPermissions(state)
});

export default connect(mapStateToProps)(AppNavigationCardList);
