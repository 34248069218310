import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import {Field, formValueSelector} from 'redux-form';
import get from 'lodash.get';
import * as dataNames from '../../../../constants/dataNames';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import TextInput from '../../../common/form/TextInput';
import {needTestResults} from '../../../../selectors/transfersSelectors';
import {getIntegrationState} from '../../../../selectors/integration/integrationSelectors';
import * as labResults from '../../../../selectors/lab-results';
import CheckBoxInput from '../../../common/form/CheckBoxInput';
import {WA_OTHER_MATERIAL} from '../../../../constants/integration/leafWa/leafWaInventoryTypes';
import InternationalDecimalInput from '../../../common/form/InternationalDecimalInput';



export class PackageSelection extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.showBackOrder = this.showBackOrder.bind(this);
    this.state = {
      originalPackages: [],
      packageLocations: [],
      localValuesSet: false,
      packages: [],
      packageId: false,
      inventoryLine: false,
      linePackage: false,
    };
  }

  componentWillReceiveProps(newProps) {
    const {isEditMode, getFormValues} = this.props;
    const formValues = getFormValues();
    const packageCode = formValues.package_code;
    if (isEditMode && newProps.locations && newProps.locations.length &&
      newProps.packages && newProps.packages.length) {
      if (packageCode) {
        this.setPackageLocations(packageCode, newProps);
      }
    }
    this.setLocalValues();
  }

  setLocalValues(){
    if(!Array.isArray(this.props.packages) || typeof this.props.fields.get !== 'function' || this.state.localValuesSet) return false;
    const inventoryLine = this.props.fields.get(this.props.index);
    this.setState({
      originalPackages: this.props.packages,
      localValuesSet: true,
      inventoryLine: (inventoryLine.inventory_transaction_id !== undefined) ? inventoryLine : false,
      packageId: inventoryLine.package_id,
      packages: this.props.packages
    }, () => {
      this.setLinePackage();
    });
  }

  setLinePackage(){
    if(!this.state.inventoryLine) return false;
    const inventoryLine = this.state.inventoryLine;
    const pkg = this.state.packages.find( (pkg) => {
      return pkg.package_id === this.state.packageId;
    });
    if(!pkg){
      const packages = this.state.packages.map((pkg) => pkg);
      const linePackage = {
        id: inventoryLine.inventory_item_id,
        qty_available: inventoryLine.qty,
        lot_number: inventoryLine.lot_number,
        item_master_id: inventoryLine.item_master_id,
        package_code: inventoryLine.package_code,
        package_id: inventoryLine.package_id,
        qty: inventoryLine.qty,
        inventory_location_id: 0,
        transactionId: inventoryLine.inventory_transaction_id,
      };
      packages.push(linePackage);
      this.setState({
        linePackage: linePackage,
        packages: packages
      });
    }
  }

  showBackOrder(){
    return (!this.state.inventoryLine && this.state.packages.length === 0);
  }

  setPackageLocations(packageCode, props = false) {
    if(!props) props = this.props;
    const {packages, locations} = props;
    if (packages &&  packages.length && locations && locations.length) {
      const packageLocations = packages.filter(pkg => pkg.package_code === packageCode).map(pkg => {
        const packageLocation = locations.find(location => location.value === pkg.inventory_location_id);
        return {
          value: pkg.inventory_location_id,
          text: packageLocation && packageLocation.text,
          id: pkg.id
        };
      });
      this.setState({packageLocations});
    }
  }

  render () {

    const {
      fieldName, isApplyStorageLocation, children, isLotTracked, isComplete, isCancelled, isNonMedicatedNonLotTracked,
      integrationState, isDisabledExtraction, packages,
    } = this.props;
    const {isWaLeaf} = integrationState;

    const isPackageCodeValid = () => {
      if (this.props.isNonMedicatedNonLotTracked) return true;
      if(typeof this.props.packageCode !== 'string') return false;
      return (this.props.packageCode.trim() !== '');
    };

    const getLocationsByPackageCode = () => {
      const {packages, packageCode, locationsById, isNonMedicatedNonLotTracked, assignedLocations, index} = this.props;

      const getLocationById = (inventoryLocationId) => {
        return locationsById[inventoryLocationId] || {};
      };

      /**
       * Returns true if assigned somewhere else, false if assigned here.
       * @param pkg
       * @returns {boolean}
       */
      const locationAlreadyAssigned = (pkg) => {
        const formInventoryIndex = index;
        const inventoryLocationId = get(pkg, 'inventory_location_id');
        const location = getLocationById(inventoryLocationId);
        const packageId = get(pkg, 'id', '');
        const locationValue = get(location, 'value', '');
        const locationAssignedIndex = assignedLocations[`${packageId}_${locationValue}`];
        if(locationAssignedIndex === undefined){
          return false;
        }
        return locationAssignedIndex !== formInventoryIndex;
      };

      return packages
        .filter(pkg => {
          if(locationAlreadyAssigned(pkg)){ // Only include a location if it is not assigned elsewhere
            return false;
          }
          return !isPackageCodeValid() || pkg.package_code === packageCode || isNonMedicatedNonLotTracked;
        })
        .map(pkg => {
          const packageLocation = locationsById[pkg.inventory_location_id] || {};
          return {
            value: isPackageCodeValid() ? `${pkg.id}_${packageLocation.value}` : packageLocation.value,
            text: packageLocation && packageLocation.text,
          };
        });
    };
    const checkInventoryTypeForExtractionWA = () => {
      const {packages, packageCode} = this.props;
      const currentPackages = packages.filter(pkg => pkg.package_code === packageCode).shift();
      if(currentPackages) {
        return currentPackages.integration_type === WA_OTHER_MATERIAL;
      }
      return false;
    };

    const locations = getLocationsByPackageCode();

    // remove duplicated package_codes
    const packageCodes = packages.map(item => item.package_code);
    const packageCodesWithoutDuplicates = [...new Set(packageCodes)];
    const packagesOptions = packageCodesWithoutDuplicates.map(package_code => ({package_code}));

    return (
      <Row className='package-selection'>
        {(!this.state.inventoryLine && packages.length === 0) &&
        (
          <Col xs={6} md={12} style={{margin: '0px 0px 12px 0px'}}>
            <div className='text-danger'>
              {I18n.t('salesOrders.form.inventoryUnavailable')}
            </div>
          </Col>
        )}
        <Col xs={2} md={3} lg={2}>

          <Field
            name={`${fieldName}.package_code`}
            component={ReactSelectInput}
            disabled={isComplete || isCancelled || isNonMedicatedNonLotTracked}
            props={{
              label: I18n.t('transfers.form.packageId'),
              options: packagesOptions,
              textKey: 'package_code',
              valueKey: 'package_code',
              isRequired: true,
            }}
          />

        </Col>
        <Col xs={2} md={3} lg={2}>
          <Field
            name={`${fieldName}.hybrid_location_id`}
            component={ReactSelectInput}
            disabled={isApplyStorageLocation || isComplete || isCancelled || !isPackageCodeValid()}
            props={{
              label: I18n.t('transfers.form.storageLocation'),
              options: locations,
              placeholder: (locations.length > 1 && isPackageCodeValid()) ? 'Has Multiple Locations' : '',
              textKey: 'text',
              valueKey: 'value',
              isRequired: true,
            }}
          />
        </Col>

        <Col xs={6} md={3} lg={2}>
          <InternationalDecimalInput name={`${fieldName}.qty_available`} disabled={isComplete} props={{
            label: I18n.t('cultivation.transfers.form.qtyAvailable'),
            readOnly: true
          }}/>
        </Col>
        <Col xs={6} md={3} lg={2}>
          <div className={!isLotTracked ? 'hide' : ''}>
          <Field name={`${fieldName}.lot_number`} component={TextInput} disabled={isComplete} props={{
            label: I18n.t('cultivation.transfers.form.batch'),
            readOnly: true
          }}/>
          </div>
        </Col>
        {children}
        {isWaLeaf ? <Col xs={6} md={4} lg={2}>
          <Field
            name={`${fieldName}.is_for_extraction`}
            component={CheckBoxInput}
            props={{
              groupClassName: 'inline-checkbox is-for-extraction',
              label: I18n.t('cultivation.transfers.form.isForExtraction'),
              disabled: isDisabledExtraction(fieldName) || checkInventoryTypeForExtractionWA(),
            }}
          />
        </Col> : null}
      </Row>
    );
  }
}

PackageSelection.propTypes = {
  fields: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  change: PropTypes.func.isRequired,
  getFormValues: PropTypes.func.isRequired,
  packages: PropTypes.array,
  fieldName: PropTypes.string.isRequired,
  packageCode: PropTypes.string,
  isApplyStorageLocation: PropTypes.bool,
  locations: PropTypes.array,
  locationsById: PropTypes.object,
  children: PropTypes.node,
  isEditMode: PropTypes.bool,
  isLotTracked: PropTypes.number.isRequired,
  isComplete: PropTypes.bool.isRequired,
  isCancelled: PropTypes.bool,
  transferId: PropTypes.string,
  isNonMedicatedNonLotTracked: PropTypes.bool,
  integrationState: PropTypes.object,
  isDisabledExtraction:PropTypes.func,
};

PackageSelection.defaultProps = {
  isEditMode: false,
  isNonMedicatedNonLotTracked: false
};

function mapStateToProps(state, ownProps){
  const allLineInventoryKey = `${ownProps.fieldName.split('.').shift()}.inventory`;
  const selector = formValueSelector(ownProps.formName);
  const salesOrders = state[dataNames.salesOrders];
  const isDisabledExtraction = (fieldName) => {
    const integrationType = selector(state,fieldName + '.integration_type');
    return integrationType === WA_OTHER_MATERIAL;
  };

  //@TODO: Move to a selector one day when we aren't against a deadline
  const inventory = selector(state, allLineInventoryKey);
  const assignedLocations = inventory.reduce((acc, item, index) => {
    const locationValue = get(item, 'hybrid_location_id', false);
    if(locationValue){
      acc[locationValue] = index;
    }
    return acc;
  }, {});

  return {
    packageCode: selector(state, `${ownProps.fieldName}.package_code`),
    meta: selector(state, `${ownProps.fieldName.split('.').shift()}.meta`), // get line meta
    inventoryItemsTestResults: labResults.getList(state),
    needTestResults: needTestResults(state),
    salesOrder: salesOrders.length > 0 ? salesOrders[0] : {},
    integration_type: `${ownProps.fieldName}.integration_type`,
    integrationState: getIntegrationState(state),
    assignedLocations: assignedLocations,
    isDisabledExtraction,
  };
}

export default connect(mapStateToProps)(PackageSelection);
