import {createSelector} from 'reselect';
import map from 'lodash.map';
import * as itemNames from '../../constants/itemNames';

export const getApisicpaStates = state => state[itemNames.apisicpaStates];

export const getApisicpaStateOptions = createSelector(
    [getApisicpaStates],
    apisicpaStates => map(apisicpaStates, (text, value) => ({text, value}))
);
