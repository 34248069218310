import * as imageUrls from './imageUrls';
import {PRODUCT_IMAGE, COMMON_PRIVATE_IMAGE, MAX_UPLOAD_SIZE_DISPLAY} from './fileUploads';

export const patient = {
  name: 'customers.create.patientPhoto',
  fileType: COMMON_PRIVATE_IMAGE,
  limit: MAX_UPLOAD_SIZE_DISPLAY,
  thumbnail: imageUrls.PERSON,
  sizes: ['original', '30x30', '100x100', '150x150'],
};

export const product = {
  name: 'products.form.productImage',
  fileType: PRODUCT_IMAGE,
  limit: MAX_UPLOAD_SIZE_DISPLAY,
  thumbnail: imageUrls.LEAF,
  sizes: ['original', '30x30', '50x50', '75x75', '100x100', '150x150', '400x400'],
};

export const alternateProduct = {
  name: 'products.form.alternateProductImage',
  fileType: PRODUCT_IMAGE,
  limit: MAX_UPLOAD_SIZE_DISPLAY,
  thumbnail: imageUrls.LEAF,
  sizes: ['original', '150x150', '400x400'],
};

export const user = {
  name: 'userAccounts.profileImage',
  fileType: COMMON_PRIVATE_IMAGE,
  limit: MAX_UPLOAD_SIZE_DISPLAY,
  thumbnail: imageUrls.PERSON,
  sizes: ['original', '50x50', '150x150'],
};
