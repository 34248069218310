/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table-cve';
import { saveAs } from 'file-saver';
import get from 'lodash.get';
import * as apiActions from '../../../actions/apiActions';
import * as systemActions from '../../../actions/systemActions';
import * as dataActions from '../../../actions/dataActions';
import * as itemActions from '../../../actions/itemActions';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import {formatDate, formatMetrcDateTime} from '../../../util/dateHelpers';
import {getIntegrationState} from '../../../selectors/integration/integrationSelectors';
import {flattenSalesExportData, getMetrcSendExportPayload, getSalesExportColumns, isOhMetrcIntegrator, isCaMetrcIntegrator, isMetrcIntegrator} from '../../../selectors/integration/metrcSelectors';
import TablePageWrapper from '../../common/grid/TablePageWrapper';

import SalesExportForm from './SalesExportForm';
import SalesExportButton from './SalesExportButton';
import * as messageTypes from '../../../constants/messageTypes';


export class SalesExportPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSendExport = this.onSendExport.bind(this);
    this.handleExportMetrcFormattedCsv = this.handleExportMetrcFormattedCsv.bind(this);
  }

  componentWillMount() {
    this.props.actions.unsetItem(itemNames.integrationSettings);
    this.props.actions.unsetData(dataNames.salesExportData);
    this.props.actions.getItem('/api/integration-settings', itemNames.integrationSettings, {failed: 'stateIntegrators.settings.get.failed'});
  }

  componentDidUpdate(prevProps) {
    const METRC_LIMIT_OF_ROWS = get(this.props, 'location.query.limit', 500);

    if (
      (this.props.integrationState.isMetrc) &&
      (prevProps.data !== this.props.data) &&
      (this.props.data.length >= METRC_LIMIT_OF_ROWS)
    ) {
      this.props.actions.addMessage(messageTypes.warning, I18n.t('metrc.form.salesExportLimits', { limit: METRC_LIMIT_OF_ROWS }), 'en');
    }
  }

  onSubmit(formValues) {
    const negativePrice = formValues.negativePrice ? 1 : 0;
    return this.props.actions.getUnpaginatedData(
      `/api/orders/metrc/${formatDate(formValues.date)}`,
      dataNames.salesExportData,
      {failed: 'stateIntegrators.salesExport.getData.failed'},
      {utc: true, exclude_refunds: 0, exclude_restocks: 0, negative_price : negativePrice}
    );
  }

  onSendExport() {
    const {sendExportPayload} = this.props;
    if (sendExportPayload.data && sendExportPayload.data.length) {
      this.props.actions.postItem(
        '/api/metrc/post_sales_receipts',
        sendExportPayload,
        undefined,
        {failed: 'stateIntegrators.salesExport.sendExport.failed', success: 'stateIntegrators.salesExport.sendExport.success'}
      );
    }
  }

  handleExportMetrcFormattedCsv() {
    // get the table data
    const data = this.table.store.data;
    // convert data (excluding the header row) to CSV format
    const columns = ['salesDateTime', 'salesCustomerType', 'customerNumber', 'caregiverNumber', 'identificationMethod', 'packageLabel', 'quantity', 'uom', 'unitThcContent', 'unitThcContentUnitOfMeasure', 'unitThcPercent', 'unitWeight', 'unitWeightUnitOfMeasure', 'totalAmount'];
    let csv = '';
    data.forEach(row => {
      csv += columns.map(col => {
        switch (col) {
        case 'salesDateTime':
          return formatMetrcDateTime(row[col]);
        case 'quantity':
          return Number(row[col]).toFixed(2);
        case 'totalAmount':
          return Number(row[col]).toFixed(2);
        default:
          return row[col];
        }
      }).join(',') + '\n';
    });
    // save the CSV file
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'metrc_sales_export.csv');
  }

  render() {
    const {columns, data, isMetrcOh, isMetrcCa, isMetrc} = this.props;

    // columns in this array will not be shown in the table by default, but can be enabled by the user using the
    // "Column Visibility" dropdown on the UI
    const defaultHiddenColumns = columns.map((column) => {
      if (column.hidden) {
        return column.dataId;
      }
    });

    let className = 'sales-export-table';
    if (isMetrcCa) {
      className += ' metrc-ca-sales-export-table';
    }
    return (
      <div className='sales-export-page'>
        <h1>{I18n.t('stateIntegrators.salesExport.title')}</h1>
        <p>{I18n.t('stateIntegrators.salesExport.clickUpdateButton')}</p>
        <SalesExportForm
            onSubmit={this.onSubmit}
        />
        <TablePageWrapper
          ref={this.ref}
          settingKey='integration-sales-export'
          columns={columns}
          defaultHiddenColumns={defaultHiddenColumns}
          data={data}
          className={className}
          hideSearch={true}
          noSelectionMode={true}
          bstProps={{pagination: false}}
          updateColumns={true}
          actions={[]}
          hideScanSearch={true}
          hidePagination={true}
        />
        <SalesExportButton data={data} onSendExport={this.onSendExport} isMetrcOh={isMetrcOh}/>
        <hr />
        {isMetrc && <BootstrapTable
          version='4'
          ref={(ref) => { this.table = ref; }}
          data={data}
          options={{
            exportCSVText: 'Download Metrc formatted CSV',
            onExportToCSV: this.handleExportMetrcFormattedCsv
          }}
          exportCSV
        >
          <TableHeaderColumn dataField='id' isKey hidden>id</TableHeaderColumn>
          <TableHeaderColumn dataField='salesDateTime' csvHeader>salesDateTime</TableHeaderColumn>
          <TableHeaderColumn dataField='salesCustomerType'>salesCustomerType</TableHeaderColumn>
          <TableHeaderColumn dataField='customerNumber'>customerNumber</TableHeaderColumn>
          <TableHeaderColumn dataField='caregiverNumber'>CaregiverNumber</TableHeaderColumn>
          <TableHeaderColumn dataField='identificationMethod'>identificationMethod</TableHeaderColumn>
          <TableHeaderColumn dataField='packageLabel'>packageLabel</TableHeaderColumn>
          <TableHeaderColumn dataField='quantity'>quantity</TableHeaderColumn>
          <TableHeaderColumn dataField='uom'>uom</TableHeaderColumn>
          <TableHeaderColumn dataField='unitThcContent'>unitThcContent</TableHeaderColumn>
          <TableHeaderColumn dataField='unitThcContentUnitOfMeasure'>unitThcContentUnitOfMeasure</TableHeaderColumn>
          <TableHeaderColumn dataField='unitThcPercent'>unitThcPercent</TableHeaderColumn>
          <TableHeaderColumn dataField='unitWeight'>unitWeight</TableHeaderColumn>
          <TableHeaderColumn dataField='unitWeightUnitOfMeasure'>unitWeightUnitOfMeasure</TableHeaderColumn>
          <TableHeaderColumn dataField='totalAmount'>totalAmount</TableHeaderColumn>
        </BootstrapTable>}
      </div>
    );

  }
}

SalesExportPage.propTypes = {
  data: PropTypes.array.isRequired,
  timezone: PropTypes.string.isRequired,
  sendExportPayload: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    getUnpaginatedData: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired
  }),
  columns: PropTypes.array.isRequired,
  isMetrcOh: PropTypes.bool.isRequired,
  integrationState: PropTypes.object
};

function mapStateToProps(state, ownProps) {
  return {
    timezone: state.timezone,
    data: flattenSalesExportData(state),
    sendExportPayload: getMetrcSendExportPayload(state),
    columns: getSalesExportColumns(state),
    isMetrcOh: isOhMetrcIntegrator(state),
    isMetrcCa: isCaMetrcIntegrator(state),
    isMetrc: isMetrcIntegrator(state),
    integrationState: getIntegrationState(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...apiActions,
      ...dataActions,
      ...itemActions,
      ...systemActions,
    }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesExportPage);
