import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import find from 'lodash.find';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Button, Accordion, Card } from 'react-bootstrap';
import * as p from '../../constants/permissions';
import { getRatingLabel } from '../../constants/ratingOptions';
import PermissionWrapper from '../common/PermissionWrapper';
import RouteButton from '../common/RouteButton';
import ChildForms from './ChildForms';
import {getBiotrackInvTypesWithTitles} from '../../selectors/integration/biotrackSelectors';
import {isFeatureEnabled} from '../../selectors/featureToggles';
import IndeterminateCheckbox from '../common/form/IndeterminateCheckbox';
import InternationalDisplayQty from '../common/InternationalDisplayQty';
import IngredientAdjustFormWrapper from './IngredientAdjustFormWrapper';
import MetrcConcealer from '../common/concealers/MetrcConcealer';
import ContentConcealer from '../common/concealers/ContentConcealer';
import ItemIntegrationTrackingStatus from './ItemIntegrationTrackingStatus';
import {getUseEntityLocksForItems} from '../../selectors/coreSettingsSelectors';

export class ModifyItems extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      activeKey: -1
    };

    this.collapse = this.collapse.bind(this);
    this.toggle = this.toggle.bind(this);
    this.renderPanelBody = this.renderPanelBody.bind(this);
    this.renderPanelHeader = this.renderPanelHeader.bind(this);
    this.onPanelGroupSelect = this.onPanelGroupSelect.bind(this);
    this.getIntegrationType = this.getIntegrationType.bind(this);
  }

  componentWillUpdate(nextProps) {
    if (nextProps.id !== this.props.id) {
      this.collapse();
    }
  }

  collapse() {
    this.setState({ activeKey: -1 });
  }

  toggle(index) {
    if (index === this.state.activeKey) {
      this.collapse();
    } else {
      this.setState({ activeKey: index });
    }
  }

  onPanelGroupSelect(activeKey, event) {
    if (event.target.type !== 'checkbox') {
      if (activeKey !== this.state.activeKey) {
        return this.setState({ activeKey });
      }

      return this.collapse();
    }
  }

  getIntegrationType(value) {
    const { integrationTypes } = this.props;
    const integrationType = find(integrationTypes, { value: parseInt(value) });

    return get(integrationType, 'text');
  }

  renderPanelHeader(item, index) {
    const {
      partners,
      printLabel,
      showSelect,
      isIngredient,
      handleSelect,
      showTrackingId,
      integrationState,
      hasPackagesTags,
      reasonIsDisplayed,
      selectedProductIds,
      onIngredientAdjust,
      userHasEditPermission,
      canUserManageReservationAndIsAllowedInComplianceSettings,
      showModality,
      showCupo,
      cupos,
      isMetrcStemHoldingsToggled,
      useEntityLocks,
      isUtahGlobalIdGenerationFeatureEnabled
    } = this.props;

    const checked = selectedProductIds.indexOf(item.id) > -1;
    const uomDisplay = item.uom_display || 'GR';

    let formattedWeight = null;
    let formattedDiscrete = null;

    if (canUserManageReservationAndIsAllowedInComplianceSettings) {
      formattedWeight =
        item.weight_gr < 0 ? (
          <span>
            <span className='danger'>{item.weight_gr}</span> (<InternationalDisplayQty qty={item.weight_reserved} uom={uomDisplay} />)
            <span className='danger'><InternationalDisplayQty qty={item.weight_gr} uom={uomDisplay} /></span> (<InternationalDisplayQty qty={item.weight_reserved} uom={uomDisplay} />)
          </span>
        ) : (
          <span><InternationalDisplayQty qty={item.weight_gr} uom={uomDisplay} /> (<InternationalDisplayQty qty={item.weight_reserved} uom={uomDisplay} />)</span>
        );
    } else {
      const internationalWeight = <InternationalDisplayQty qty={item.weight_gr} />;
      formattedWeight = item.weight_gr < 0 ? <span className='danger'>{internationalWeight}</span> : internationalWeight;
    }

    if (canUserManageReservationAndIsAllowedInComplianceSettings) {
      formattedDiscrete =
        item.qty < 0 ? (
          <span>
            <span className='danger'>{item.qty}</span> ({item.qty_reserved})
          </span>
        ) : (
          `${item.qty} (${item.qty_reserved})`
        );
    } else {
      formattedDiscrete = item.qty < 0 ? <span className='danger'>{item.qty}</span> : `${item.qty}`;
    }

    const trackingId = showTrackingId ? item.state_integration_tracking_id : null;
    const destinationPartner = partners && partners.find((partner) => partner.id === item.destination_partner_id);
    const modality = showModality ? item.modality : null;
    const cupo = showCupo ? get(cupos.find((cupo) => cupo.id === item.cupo_id), 'cupo_number') : null;
    const isItemLocked = useEntityLocks && item.is_locked;
    return (
      <Card.Header as='div'>
        <div className='layout-row layout-align-start-stretch' onClick={() => this.toggle(index)}>

          {showSelect && (
            <div className='flex-col checkbox-col'>
              <ContentConcealer show={userHasEditPermission}>
                <IndeterminateCheckbox
                  checked={checked}
                  disabled={isItemLocked}
                  onChange={(event) => {
                    handleSelect(checked ? 'remove' : 'add', [item]);
                    //I know this is hacky but it looks like we have a react bug there. After the render and even after the componentDidUpdate even checkbox state is being changed somehow
                    setTimeout(() => this.forceUpdate());
                  }}
                />
              </ContentConcealer>
            </div>
          )}

          {!isIngredient ? (
            <div className='flex-col flex' title={item.package_code}>
              {item.package_code}
            </div>
          ) : null}

          {showTrackingId && hasPackagesTags && item.tag_requested ? (
            <div className='flex-col flex' title={trackingId}>
              <ItemIntegrationTrackingStatus item={item} />
              {!isUtahGlobalIdGenerationFeatureEnabled ? (
                <span>
                  <br />
                  <em style={{ color: 'lightgrey' }}>{I18n.t('plants.table.tagNeedsActivation')}</em>
                </span>
              ) : null}
            </div>
          ) : null}

          {showTrackingId && hasPackagesTags && !item.tag_requested ? (
            <div className='flex-col flex' title={trackingId}>
              <ItemIntegrationTrackingStatus item={item} />
            </div>
          ) : null}

          {showTrackingId && !hasPackagesTags ? (
            <div className='flex-col flex' title={trackingId}>
              <ItemIntegrationTrackingStatus item={item} />
            </div>
          ) : null}

          {showCupo && (
            <div className='flex-col xs-col text-cut' title={cupo}>
              {cupo}
            </div>
          )}

          {showModality && (
            <div className='flex-col xs-col text-cut' title={modality && I18n.t(`modalities.list.${modality}`)}>
              {modality && I18n.t(`modalities.list.${modality}`)}
            </div>
          )}

          {!isIngredient && (
            <PermissionWrapper permissions={[p.rate_products]}>
              <div className='flex-col compact xs-col' title={item.rating_avg_total_quality}>
                {getRatingLabel(item.rating_avg_total_quality)}
              </div>
            </PermissionWrapper>
          )}

          {!isIngredient && (
            <div className='flex-col xs-col compact' title={item.prepack_name}>
              {item.prepack_name}
            </div>
          )}

          <div className={`flex-col text-cut ${isIngredient ? 'normal-col' : 'xs-col'}`}>
            {item.uom_type === 'discrete' ? formattedDiscrete : formattedWeight}
          </div>

          <div className={`flex-col text-center ${isIngredient ? 'small-col' : 'xs-col'}`}>
            {item.uom}
          </div>

          {isIngredient ? (
            <div className={`flex-col lg-col`}>
              <PermissionWrapper permissions={[p.manage_inventory]}>
                <IngredientAdjustFormWrapper
                  onSubmit={onIngredientAdjust}
                  item={item}
                  initialValues={item}
                  form={`adjust-${item.item_id}`}
                />
              </PermissionWrapper>
            </div>
          ) : (
            <div className={`flex-col xs-col text-cut`}>
              {formattedWeight}
            </div>
          )}

          <div className={`flex-col text-center ${isIngredient ? 'small-col' : 'xs-col'}`}>
            {item.uom_display}
          </div>

          <div className={`flex-col ${isIngredient ? 'normal-col' : 'small-col'}`}>
            {item.location_name}
          </div>

          {!isIngredient ? (
            <PermissionWrapper permissions={[p.print_labels]}>
              <div className='flex-col xs-col'>
                <Button variant='info' size='sm' value={item.item_id} onClick={printLabel}>
                  {I18n.t('plants.table.Label')}
                </Button>
              </div>
            </PermissionWrapper>
          ) : null}

          <PermissionWrapper permissions={[p.view_inventory_history]}>
            <div className={`flex-col ${isIngredient ? 'small-col' : 'xs-col'}`}>
              <RouteButton props={{size: 'sm'}} path={`/items/${item.id}/history`}>{I18n.t('common.history')}</RouteButton>
            </div>
          </PermissionWrapper>

          {!isIngredient ? (
            <div className='flex-col xs-col'>{item.on_hold ? 'Yes' : 'No'}</div>
          ) : null}

          {integrationState.isWaLeaf && (
            <div className='flex-col xs-col'>
              {item.integration_type.toString().toLowerCase() !== 'null' && (
                <React.Fragment>
                  {I18n.t(`harvestPackages.form.leafWa.inventoryTypes.${item.integration_type}`)}
                </React.Fragment>
              )}
            </div>
          )}

          <div className='flex-col small-col'>
            {integrationState['isBiotrack']
              ? this.getIntegrationType(get(item, 'integration_type'))
              : get(destinationPartner, 'name', '')}
          </div>

          {!isIngredient ? (
            <div className='flex-col xs-col'>{item.is_test_package ? 'Yes' : 'No'}</div>
          ) : null}

          <div className='flex-col small-col'>{item.purpose}</div>

          {!isIngredient ? (
            <MetrcConcealer>
              <div className='flex-col xs-col7'>{item.is_produced ? 'Yes' : 'No'}</div>
            </MetrcConcealer>
          ) : null}

          {!isIngredient && isMetrcStemHoldingsToggled ? (
            <MetrcConcealer>
              <div className='flex-col xs-col'>{item.is_trade_sample ? 'Yes' : 'No'}</div>
            </MetrcConcealer>
          ) : null}

          {reasonIsDisplayed ? (
            <div className='flex-col xs-col'>{item.inactivation_reason}</div>
          ) : null}

        </div>
      </Card.Header>
    );
  }

  renderPanelBody(item, index) {
    const {
      active,
      onSubmit,
      locations,
      isIngredient,
      sharedLocations,
      isManufacturing,
      integrationState,
      quickMoveDisabled,
      activeTab,
      showProductionRunsModal,
      fetchChildrenAndLabResults,
      canUserManageReservationAndIsAllowedInComplianceSettings,
      showReservationsModal,
      allowPackageLocationSplit,
      useEntityLocks
    } = this.props;

    const initialValues = {
      ...item,
      qty: item.qty - item.qty_reserved,
      qty_available: item.qty,
    };
    const quickMoveQtyDisabled = [false, 0, '0'].includes(allowPackageLocationSplit);

    return index === this.state.activeKey ? (
      <Card.Body>
        <PermissionWrapper permissions={[p.manage_inventory]}>
          <ChildForms
            locations={locations}
            sharedLocations={sharedLocations}
            item={item}
            enableReinitialize={true}
            quickMoveDisabled={quickMoveDisabled || !active}
            quickMoveQtyDisabled={quickMoveQtyDisabled}
            activeTab={activeTab}
            collapse={this.collapse}
            onSubmit={onSubmit}
            isManufacturing={isManufacturing}
            showProductionRunsModal={showProductionRunsModal}
            initialValues={initialValues}
            isIngredient={isIngredient}
            form={`quickmove-${item.item_id}`}
            fetchChildrenAndLabResults={fetchChildrenAndLabResults}
            integrationState={integrationState}
            showReservationsModal={showReservationsModal}
            canUserManageReservationAndIsAllowedInComplianceSettings={
              canUserManageReservationAndIsAllowedInComplianceSettings
            }
            useEntityLocks={useEntityLocks}
          />
        </PermissionWrapper>
      </Card.Body>
    ) : null;
  }

  render() {
    const { activeKey } = this.state;
    const { total, children, isIngredient, commonUom } = this.props;
    return (
      <div>
        {children.map((item, index) => (
          <Accordion
            id={`package-panel-${index}`}
            key={index}
            activeKey={activeKey}
            accordion={true}
            onSelect={this.onPanelGroupSelect}
          >
            <Card eventKey={index} className='modify-item'>
              {this.renderPanelHeader(item, index)}
              {this.renderPanelBody(item, index)}
            </Card>
          </Accordion>
        ))}
        {!isIngredient ? (
          <Card body>
            <div className='layout-row layout-align-start-start'>
              <div className='flex' />
              <div className={`flex-col ${isIngredient ? 'small-col' : 'xs-col'}`}>
                {I18n.t('cultivation.finishedProduct.form.total')}
              </div>
              <div className={`flex-col ${isIngredient ? 'normal-col' : 'small-col'}`}><InternationalDisplayQty qty={total} uom={commonUom}/></div>
              <div className={`flex-col ${isIngredient ? 'small-col text-center' : 'xs-col'}`}>
                {I18n.t(`uoms.${commonUom}.wordPlural`)}
              </div>
              <div className={`flex-col ${isIngredient ? 'normal-col' : 'small-col'}`} />
              <PermissionWrapper permissions={[p.print_labels]}>
                <div className={`flex-col ${isIngredient ? 'normal-col' : 'small-col'}`} />
              </PermissionWrapper>
              <PermissionWrapper permissions={[p.view_inventory_history]}>
                <div className={`flex-col ${isIngredient ? 'normal-col' : 'small-col'}`} />
              </PermissionWrapper>
              <div className={`flex-col ${isIngredient ? 'normal-col' : 'small-col'}`} />
            </div>
          </Card>
        ) : null}
      </div>
    );
  }
}

ModifyItems.propTypes = {
  locations: PropTypes.array.isRequired,
  sharedLocations: PropTypes.array,
  children: PropTypes.array.isRequired,
  total: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  printLabel: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  showSelect: PropTypes.bool.isRequired,
  selectedProductIds: PropTypes.array.isRequired,
  handleSelect: PropTypes.func,
  showProductionRunsModal: PropTypes.func,
  isManufacturing: PropTypes.bool,
  isIngredient: PropTypes.bool,
  onIngredientAdjust: PropTypes.func,
  quickMoveDisabled: PropTypes.bool,
  activeTab: PropTypes.object,
  integrationState: PropTypes.object.isRequired,
  partners: PropTypes.array,
  showTrackingId: PropTypes.bool,
  hasPackagesTags: PropTypes.bool,
  fetchChildrenAndLabResults: PropTypes.func,
  reasonIsDisplayed: PropTypes.bool,
  userHasEditPermission: PropTypes.bool,
  showReservationsModal: PropTypes.func.isRequired,
  canUserManageReservationAndIsAllowedInComplianceSettings: PropTypes.bool,
  allowPackageLocationSplit: PropTypes.bool,
  commonUom: PropTypes.string,
  isMetrcStemHoldingsToggled: PropTypes.bool,
};

export default connect((state) => ({
  integrationTypes: getBiotrackInvTypesWithTitles(state),
  isMetrcStemHoldingsToggled: isFeatureEnabled(state)('feature_metrc_stem_holdings'),
  useEntityLocks: getUseEntityLocksForItems(state),
  isUtahGlobalIdGenerationFeatureEnabled: isFeatureEnabled(state)('feature_utah_global_id_generation')
}))(ModifyItems);
