import React from 'react';
import PropTypes from 'prop-types';
import {Badge} from 'react-bootstrap';

const MyPermissions = ({permissions, user}) => {
  const perms = Object.keys(permissions).sort();
  return (
    <div>
      {(user && user.isMasterUser) ?
        <h1><Badge variant='primary'>Master User</Badge></h1> :
        null}
      {perms.map((perm) =>
        <h2 key={perm}><Badge variant='info'>{perm}</Badge></h2>
      )}
    </div>
  );
};

MyPermissions.propTypes = {
  permissions: PropTypes.object,
  user: PropTypes.object,
};

export default MyPermissions;
