import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import {Field} from 'redux-form';
import SubmitSection from '../../common/form/SubmitSection';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import ImportFileElement from './ImportFileElement';
import NumericInput from '../../common/form/NumericInput';


const ImportOrdersForm = (props) => {
  const {handleSubmit, change, registers, pristine, invalid, submitting} = props;

  const settings = {
    actionSettings: {
      submit: {
        submitting,
        pristine,
        invalid,
        text: I18n.t('common.form.save'),
        action: handleSubmit
      }
    }
  };

  return (
    <form noValidate={true} onSubmit={handleSubmit}>
      <Row>
        <Col xs={12}>
          {I18n.t('ordersImport.form.introductionText')}
        </Col>
      </Row>
      <Row>
        <Col xs={5} sm={4} md={3}>
          <a href='/api/static/OrderImportTemplate.xlsx' target='_blank'>{I18n.t('ordersImport.form.sampleFile')}</a>
        </Col>
      </Row>
      <Row>
        <Col xs={5} sm={4} md={3}>
          <Field name='uploadFile' component={ImportFileElement} props={{
            label: I18n.t('ordersImport.form.startFromRow'),
            isRequired: true,
            change: change,
          }}/>
        </Col>
      </Row>
      <Row>
        <Col xs={5} sm={4} md={3}>
          <Field name='startFromRow' component={NumericInput} props={{
            label: I18n.t('ordersImport.form.startFromRow'),
            fractionPartSize: 0,
            isRequired: true,
            type: 'text',
            max: 9999,
            min: 2
          }}/>
        </Col>
      </Row>
      <Row>
        <Col xs={5} sm={4} md={3}>
          <Field name='register' component={ReactSelectInput} props={{
            label: I18n.t('ordersImport.form.register'),
            options: registers,
            labelKey: 'name',
            valueKey: 'id',
            isRequired: true,
          }}/>
        </Col>
      </Row>

      <SubmitSection settings={settings}/>
    </form>
  );
};

ImportOrdersForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  registers: PropTypes.array.isRequired,
  initialValues: PropTypes.array.isRequired,
  change: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired
};

export default ImportOrdersForm;
