import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {goBack, push} from 'react-router-redux';
import get from 'lodash.get';
import {Tabs, Tab} from 'react-bootstrap';
import PageTitle from '../../../common/PageTitle';
import {getDataByPost, getUnpaginatedData} from '../../../../actions/apiActions';
import {unsetData} from '../../../../actions/dataActions';
import * as dataNames from '../../../../constants/dataNames';
import{getInventoryReport} from '../../../../selectors/inventoryReportSelectors';
import FormWrapper from '../../../common/form/FormWrapper';
import TablePageWrapper from '../../../common/grid/TablePageWrapper';
import InventorySearchForm from './InventorySearchForm';
import {inventoryReportTabs, inventoryReportColumns} from './tableDefinitions';
import getSalesReportTabs from '../common/tabDefinitions';
import {getEnabledTabs} from '../../../../actions/reportActions';


export class InventoryReportPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.submitSearch = this.submitSearch.bind(this);
    this.switchTab = this.switchTab.bind(this);
    this.state = {
      tabs: [],
      activeTab: 'currentInventoryReport'
    };

  }

  componentWillMount() {
    const {actions: {getUnpaginatedData, unsetData}} = this.props;
    unsetData(dataNames.inventoryReport);
    // partners
    getUnpaginatedData('/api/partners', dataNames.partners, {failed:'partners.get.failed'});
    // Categories
    this.props.actions.getUnpaginatedData('/api/categories', dataNames.categories);
    //Locations
    this.props.actions.getUnpaginatedData('/api/location_hierarchy', dataNames.locations, undefined, {});

    this.setState({tabs: this.props.actions.getEnabledTabs(getSalesReportTabs)});
  }

  // Search form submit handler
  submitSearch(formValues) {
    const reportParams = {
      vendor_id: get(formValues,'vendor', null),
      item_master_name_like: get(formValues,'item_name', null),
      category_id: get(formValues,'category_id', null),
      inventory_location_id: get(formValues,'storage_location', null)
    };

    this.props.actions.getDataByPost(
      '/api/items/current_inventory_report',
      reportParams,
      dataNames.inventoryReport,
      {failed: I18n.t('retail.inventoryReport.failed')}
    );
  }

  switchTab(activeTab) {
    const tab = this.state.tabs.find(tab => tab.eventKey === activeTab) || this.state.tabs[0];
    this.setState({activeTab});
    this.props.actions.push(tab.path);
  }

  render() {
    return (
      <div>
        <PageTitle primaryText={I18n.t('retail.salesReport.title')}/>
        <Tabs id='filterTabs' activeKey={this.state.activeTab} onSelect={this.switchTab}>
          {this.state.tabs.map((tab, index) =>
            <Tab key={index} eventKey={tab.eventKey} title={I18n.t(tab.title)} />
          )}
        </Tabs>
        <br />
        <FormWrapper
          className='transaction-report-form'
          goBack={this.props.actions.goBack}
          titleDescription={I18n.t('retail.inventoryReport.title')}
        >
          <InventorySearchForm submitSearch={this.submitSearch} />
        </FormWrapper>
        <div className='hoverable-overflow'>
          <TablePageWrapper
            activeTab='default'
            bstProps={{
              defaultSortName: 'order_id',
              defaultSortOrder: 'asc'
            }}
            columns={inventoryReportColumns}
            defaultHiddenColumns={[
              'product_type',
              'base_price_per_gram_retail',
              'base_price_per_gram_wholesale',
              'package_created_date',
              'package_expiration_date',
              'batch_id',
              'product_online_availability',
              'item_medicated_weight_total',
              'item_medicated_weight_uom',
              'item_net_weight_total',
              'item_net_weight_uom',
              'item_unit_weight_total',
              'item_unit_weight_uom',
              'item_number',
              'reserved_qty',
              'reserved_uom',
            ]}
            data={this.props.inventoryReport}
            dataTotalSize={this.props.inventoryReport.length}
            external={false}
            hideExport={false}
            hideScanSearch={true}
            hideSearch={true}
            ref={this.ref}
            settingKey='inventoryReport'
            tabs={inventoryReportTabs}
          />
        </div>

      </div>
    );
  }
}

InventoryReportPage.propTypes = {
  actions: PropTypes.shape({
    getUnpaginatedData: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    getEnabledTabs: PropTypes.func,
  }),
  transactionReport: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  return {
    inventoryReport: getInventoryReport(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {getDataByPost, getUnpaginatedData, goBack, push, unsetData, getEnabledTabs};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryReportPage);
