import React from 'react';
import PropTypes from 'prop-types';

import TestThisLabel from './TestThisLabel';

const TestBoxes = (props) => {

  //const passProps = {loadModal, onPrintLabel, onFieldChange, onTestLabel} = props;

  //console.log(props, 'props in TestBoxes');

  return (
    <div className='row'>
      {
        props.tests.map((test, index) => {
          if (index > 1 && index % 3 === 0) {
            return (<div key={index} style={{clear:'both'}}>
              <TestThisLabel
                key={index}
                test={test}
                loadPrinterModal={props.loadPrinterModal}
                onPrintLabel={props.onPrintLabel}
                onFieldChange={props.onFieldChange}
                onTestLabel={props.onTestLabel}/>
            </div>);
          } else {
            return (<TestThisLabel
              key={index}
              test={test}
              loadPrinterModal={props.loadPrinterModal}
              onPrintLabel={props.onPrintLabel}
              onFieldChange={props.onFieldChange}
              onTestLabel={props.onTestLabel}/>);
          }
        })
      }
    </div>
  );

};

TestBoxes.propTypes = {
  tests        : PropTypes.array.isRequired,
  testIds      : PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onTestLabel  : PropTypes.func.isRequired,
  onPrintLabel : PropTypes.func.isRequired,
  loadPrinterModal: PropTypes.func.isRequired
};

export default TestBoxes;
