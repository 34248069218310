import {createSelector} from 'reselect';
import * as itemNames from '../../../constants/itemNames';
import {getIntegrationState} from '../../integration/integrationSelectors';
import {getRequireWastePackageTracking, getRequireWasteTransfer} from '../../../selectors/complianceSettingsSelectors';

const getSettingValue = key => settings => settings && settings[key] && settings[key].value || null;

const createOption = value => ({value, text: value});

export const getWasteComplianceRaw = (state) => state[itemNames.wasteCompliance];

export const getWasteCompliance = createSelector(
  [getWasteComplianceRaw],
  (raw) => {
    const waste = getSettingValue('cult_waste_destruction_compliance_settings')(raw);
    const transfer = getSettingValue('inv_waste_packaging')(raw);
    if (waste) {
      waste.track_waste_packages = getSettingValue('inv_track_waste_packages')(raw);
      waste.waste_package_location_id = getSettingValue('inv_track_waste_location_id')(raw);
    }
    return {...waste, ...transfer};
  }
);

export const isWasteDisposalEnabledSelector = createSelector([getWasteCompliance], (wasteCompliance) => {
  if(!wasteCompliance) return false;
  if(typeof wasteCompliance.track_waste_packages !== 'boolean') {
    wasteCompliance.track_waste_packages = parseInt(wasteCompliance.track_waste_packages) === 1;
  }
  return Boolean(wasteCompliance && wasteCompliance.track_waste_packages);
});

export const isWasteTransferAvailableSelector = createSelector(
  [getIntegrationState, getWasteCompliance],
  (modes, wasteCompliance) => Boolean(modes.isPaLeaf || modes.isMetrc || modes.isNormal) && wasteCompliance && wasteCompliance.transferring_waste
);


export const getStageOptions = createSelector(
  [getWasteCompliance],
  settings => (settings && settings.trackable_waste_stages && settings.trackable_waste_stages.options || []).map(createOption)
);

export const getDayOptions = createSelector(
  [getWasteCompliance],
  settings => (settings && settings.transferring_waste_days && settings.transferring_waste_days.options || []).map(createOption)
);

export const getWasteComplianceInitialValues = createSelector(
  [getWasteCompliance, getIntegrationState, getRequireWastePackageTracking, getRequireWasteTransfer],
  (wasteCompliance, {integrationState}, requireWastePackageTracking, requireWasteTransfer) => {
    const isBiotrack = false;
    if (!wasteCompliance) {
      return {
        trackable_waste_stages: {
          options: [],
          values: [],
        },
        transferring_waste_days: {
          options: [],
          values: [],
        },
        transferring_waste: '0',
        track_waste_packages: isBiotrack ? '1' : '0',
      };
    }

    const trackWaste = (requireWastePackageTracking && 1) || ((isBiotrack && 1) || wasteCompliance.track_waste_packages || 0);

    const initial_transfer_waste = ((requireWasteTransfer == null) ? wasteCompliance.transferring_waste : Boolean(requireWasteTransfer.value));
  
    const initialValues = {
      ...wasteCompliance,
      transferring_waste: Number(initial_transfer_waste || 0).toString(),
      track_waste_packages: Number(trackWaste).toString(),
    };
    if (wasteCompliance.trackable_waste_stages) {
      initialValues.trackable_waste_stages = {
        ...wasteCompliance.trackable_waste_stages,
        values: wasteCompliance.trackable_waste_stages.values || [],
      };
    }
    if (wasteCompliance.transferring_waste_days) {
      initialValues.transferring_waste_days = {
        ...wasteCompliance.transferring_waste_days,
        values: wasteCompliance.transferring_waste_days.values || [],
      };
    }

    return initialValues;
  }
);
