import {I18n} from 'react-redux-i18n';

export const getIntegrationPrefix = (integrationState = {}) => {
  if (integrationState.isWaLeaf) {
    return 'leafWa.';
  }
  if (integrationState.isBiotrack) {
    return 'biotrack.';
  }
  return '';
};

export const getTranslationLabel = base => (label, integrationState = {}) => {
  const extraPath = getIntegrationPrefix(integrationState);
  return I18n.t(`${base}${extraPath}${label}`);
};
