import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field, reduxForm, getFormValues} from 'redux-form';
import {connect} from 'react-redux';
import {Row, Col, Button} from 'react-bootstrap';
import validate from './validate';
import InternationalDatePickerInput from '../../../common/form/InternationalDatePickerInput';
import Tills from './Tills';
import * as p from '../../../../constants/permissions';
import PermissionWrapper from '../../../common/PermissionWrapper';
import {isPickableDate} from '../../../../selectors/salesReportSelectors';
import {isFeatureEnabled} from '../../../../selectors/featureToggles';

export const ClosingReportForm = ({
  formValues,
  closeRegister,
  openRegister,
  openAllRegisters,
  verifyCount,
  data,
  dateChange,
  registerClosingSettings,
  isOpenTab,
  isWarnings,
  isPickableDate,
  featureAeropayEnabled,
  featureAeropayIntegrationEnabled,
  featureHypurEnabled,
  featurePosabitPaymentIntegrationEnabled,
  featureAlleavesPaymentIntegrationEnabled,
}) => {
  const closedRegistersExist = data && data.length;
  const closingOpeningAllowed = closedRegistersExist && registerClosingSettings && registerClosingSettings.is_allowed;

  const paymentOptions = [
    { feature: featureAeropayEnabled, option: 'aeropay' },
    { feature: featureAeropayIntegrationEnabled, option: 'aeropay_integrated' },
    { feature: featureHypurEnabled, option: 'hypur' },
    { feature: featurePosabitPaymentIntegrationEnabled, option: 'posabit' },
    { feature: featureAlleavesPaymentIntegrationEnabled, option: 'alleaves' }
  ];

  const enabledPayments = paymentOptions.reduce((acc, { feature, option }) => {
    if (feature) {
      acc.push(option);
    }
    return acc;
  }, []);

  return (
    <form>
      <Row>
        <Col sm={1} md={2} xs={1}>
          <Field
            name='date'
            component={InternationalDatePickerInput}
            onChange={(e, value) => {
              dateChange(value);
            }}
            props={{
              label: I18n.t('retail.closingReport.date'),
              isRequired: true,
              enableTodayButton: false,
              isValidDate: isPickableDate,
              closeOnSelect: true
            }}
          />
        </Col>
        {!isWarnings && !isOpenTab && closingOpeningAllowed ?
          <PermissionWrapper permissions={[p.reopen_closed_register]}>
            <Col sm={1} md={10} xs={1} className='close-all-registers'>
              <Button
                variant='primary'
                onClick={() => {
                  openAllRegisters();
                }}
              >
                {I18n.t('retail.closingReport.openAllRegisters')}
              </Button>
            </Col>
          </PermissionWrapper>
          : null
        }
      </Row>

      {!isWarnings && data && data.length ?
        <Tills
          openRegister={openRegister}
          closeRegister={closeRegister}
          verifyCount={verifyCount}
          data={data}
          enabledPayments={enabledPayments}
          formValues={formValues}
          registerClosingSettings={registerClosingSettings}
        /> : null}
    </form>
  );
};

ClosingReportForm.propTypes = {
  data: PropTypes.array.isRequired,
  closeRegister: PropTypes.func.isRequired,
  openRegister: PropTypes.func.isRequired,
  openAllRegisters: PropTypes.func.isRequired,
  verifyCount: PropTypes.func.isRequired,
  dateChange: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  registerClosingSettings: PropTypes.object,
  isOpenTab: PropTypes.bool.isRequired,
  isWarnings: PropTypes.bool.isRequired,
  isPickableDate: PropTypes.bool.isRequired,
  featureAeropayEnabled: PropTypes.bool.isRequired,
  featureAeropayIntegrationEnabled: PropTypes.bool.isRequired,
  featureHypurEnabled: PropTypes.bool.isRequired,
  featurePosabitPaymentIntegrationEnabled: PropTypes.bool.isRequired,
  featureAlleavesPaymentIntegrationEnabled: PropTypes.bool.isRequired,
};

const wrapper = reduxForm({})(ClosingReportForm);

function mapStateToProps(state) {
  const form = 'closingReportPage';
  return {
    validate,
    form,
    state,
    formValues: getFormValues(form)(state) || {},
    isPickableDate: isPickableDate(state),
    featureAlleavesPaymentIntegrationEnabled: isFeatureEnabled(state)('feature_alleaves_payment_integration'),
    featurePosabitPaymentIntegrationEnabled: isFeatureEnabled(state)('feature_posabit_payment_integration'),
    featureAeropayEnabled: isFeatureEnabled(state)('feature_aeropay'),
    featureAeropayIntegrationEnabled: isFeatureEnabled(state)('feature_aeropay_integration'),
    featureHypurEnabled: isFeatureEnabled(state)('feature_hypur'),
  };
}

export default connect(mapStateToProps)(wrapper);
