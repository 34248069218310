import {createSelector} from 'reselect';
import map from 'lodash.map';
import * as dataNames from '../constants/dataNames';
import getSelectedDataName from '../constants/helpers/getSelectedDataName';

export const getSelectedCategories = state => state[getSelectedDataName(dataNames.selectedCategories)];

export const getSelectedCategoriesIds = createSelector(
  getSelectedCategories,
  categories => map(categories, 'id')
);
