import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field, reduxForm} from 'redux-form';
import {Modal, Row, Col} from 'react-bootstrap';
import ReactSelectInput from '../common/form/ReactSelectInput';
import TextAreaInput from '../common/form/TextAreaInput';
import SubmitSection from '../common/form/SubmitSection';
import NumericInput from '../common/form/NumericInput';
import {INVENTORY_SYNC_PREPACK_MODAL} from '../../constants/forms';
import {validatePrepackModalForm as validate} from './validate';

const InventorySyncPrepackModal = (props) => {

  const { submitting, pristine, valid, reset, change, touch, handleSubmit, integrator,
    adjustPrepack, item, getFormValue, showModal, hideModal, adjustmentReasons } = props;

  if (!item.mjpPackages) {
    return null;
  }

  const settings = {
    actionSettings: {
      submit: {
        action: handleSubmit(adjustPrepack),
        text: I18n.t('inventory.sync.actions.completePackageSync'),
        pristine,
        submitting,
        disabled: submitting || !valid,
        style: 'primary'
      },
      reset: {
        action: reset,
        text: I18n.t('common.form.reset'),
        style: 'default',
        pristine
      },
    }
  };

  const currentMjpTotalQuantity = item.mjpPackages.reduce((acc, mjpPackage) => acc + (mjpPackage.quantity * mjpPackage.prepack_weight), 0) + ' GR';

  const adjustedMjpTotalQuantity = item.mjpPackages.reduce((acc, mjpPackage, index) => {
    const adjustment_qty = getFormValue(`adjustment_qty[${index}]`) ? parseInt(getFormValue(`adjustment_qty[${index}]`)) : 0;
    return acc + ((mjpPackage.quantity + adjustment_qty) * mjpPackage.prepack_weight);
  }, 0) + ' GR';

  const adjustedDiscrepancy = item.mjpPackages.reduce((acc, mjpPackage, index) => {
    const adjustment_qty = getFormValue(`adjustment_qty[${index}]`) ? parseInt(getFormValue(`adjustment_qty[${index}]`)) : 0;
    return acc - ((mjpPackage.quantity + adjustment_qty) * mjpPackage.prepack_weight);
  }, item.integrator_quantity);

  return (
    <Modal show={showModal} className='inventory-sync-confirmation' onHide={hideModal}>

      <Modal.Header className='inventory-sync-modal-header-mjp text-center' closeButton>
        <h2>{I18n.t(`inventory.sync.adjustMjp`)}</h2>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit(adjustPrepack)}>
          <Row>
            <Col className='text-center'>
              <h2>{item.integrator_name}</h2>
              <h4>{I18n.t(`inventory.sync.table.columns.metrc_id`)}: {item.integrator_id}</h4>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <div>
                <h4>{I18n.t(`inventory.sync.modal.current`)}</h4>
                <strong>
                  {I18n.t('inventory.sync.modal.qtyInIntegrator', {integrator})}: {item.integrator_quantity} {item.integrator_uom}<br/>
                  {I18n.t('inventory.sync.modal.qtyInMjp')}: {currentMjpTotalQuantity}<br/>
                  {I18n.t('inventory.sync.modal.discrepancy')}: {item.discrepancy} {item.integrator_uom}
                </strong>
              </div>
            </Col>
            <Col sm={6}>
              <div>
                <h4>{I18n.t(`inventory.sync.modal.afterAdjustment`)}</h4>
                <strong>
                  {I18n.t('inventory.sync.modal.qtyInIntegrator', {integrator})}: {item.integator_quantity} {item.integrator_uom}<br/>
                  {I18n.t('inventory.sync.modal.qtyInMjpAfterAdjustment')}: {adjustedMjpTotalQuantity}<br/>
                  {I18n.t('inventory.sync.modal.discrepancy')}: <span className={adjustedDiscrepancy === 0 ? 'success' : 'danger'}>{adjustedDiscrepancy}  {item.integrator_uom}</span>
                </strong>
              </div>
            </Col>
          </Row>

          <Row>
            <Col sm={12}><hr/></Col>
          </Row>

          <Row>
            <Col sm={2}><strong>{I18n.t(`inventory.sync.table.columns.prepack_name`)}</strong></Col>
            <Col sm={2}><strong>{I18n.t(`inventory.sync.table.columns.package_id`)}</strong></Col>
            <Col sm={2}><strong>{I18n.t(`inventory.sync.table.columns.location`)}</strong></Col>
            <Col sm={1}><strong>{I18n.t(`inventory.sync.table.columns.unit_size`)}</strong></Col>
            <Col sm={1}><strong>{I18n.t(`inventory.sync.table.columns.current_quantity`)}</strong></Col>
            <Col sm={1}><strong>{I18n.t(`inventory.sync.table.columns.quantity_after_adjustment`)}</strong></Col>
            <Col sm={1}><strong>{I18n.t(`inventory.sync.table.columns.adjust_by`)}</strong></Col>
            <Col sm={2}><strong>{I18n.t(`inventory.sync.table.columns.adjustment_reason`)}</strong></Col>
          </Row>
          {item.mjpPackages ? item.mjpPackages.map((mjpPackage, index) => {
            return (
              <Row key={index}>
                <Col sm={2}>{mjpPackage.name}</Col>
                <Col sm={2}>{mjpPackage.package_code}</Col>
                <Col sm={2}>{mjpPackage.location}</Col>
                <Col sm={1}>{mjpPackage.prepack_weight} GR</Col>
                <Col sm={1}>{mjpPackage.quantity}</Col>
                <Col sm={1}>{getFormValue(`adjustment_qty[${index}]`) ? parseInt(getFormValue(`adjustment_qty[${index}]`)) + mjpPackage.quantity : mjpPackage.quantity}</Col>
                <Col sm={1}>
                  <Field
                    name={`adjustment_qty[${index}]`}
                    component={NumericInput}
                    props={{
                      allowNegativeNumber: true,
                      fractionPartSize: 0,
                      min: -9999999,
                      value: getFormValue(`adjustment_qty[${index}]`) ? getFormValue(`adjustment_qty[${index}]`) : 0,
                      onChange: (value) => {
                        // change and touch to force validation error to show up
                        change(`adjustment_qty[${index}]`, value);
                        touch(`adjustment_reason[${index}]`);
                      }
                    }}
                  />
                </Col>
                <Col sm={2}>
                  <Field
                    name={`adjustment_reason[${index}]`}
                    component={ReactSelectInput}
                    props={{
                      options: adjustmentReasons,
                      // The logic below works, but messes up the layout
                      //isRequired: getFormValue(`adjustment_qty[${index}]`) !== undefined && getFormValue(`adjustment_qty[${index}]`) != 0,
                      textKey: 'name',
                      valueKey: 'code',
                    }}
                  />
                </Col>
              </Row>
            );
          }) : null}
          <Row>
            <Col sm={12}>
              <Field
                name='notes'
                component={TextAreaInput}
                style={{width:'100%', height: '200px'}}
                props={{
                  label: I18n.t(`inventory.sync.table.columns.adjustment_notes`) + ':',
                  placeholder: I18n.t(`inventory.sync.table.columns.adjustment_notes_placeholder`)
                }}
              />
            </Col>
          </Row>
        </form>
      </Modal.Body>

      <Modal.Footer>
        <Row>
          <Col>
            <SubmitSection settings={settings} />
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

InventorySyncPrepackModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  adjustmentReasons: PropTypes.array.isRequired,
  item: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  adjustPrepack: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  touch: PropTypes.func.isRequired,
  getFormValue: PropTypes.func.isRequired,
  integrator: PropTypes.string.isRequired
};


export default reduxForm({
  validate,
  form: INVENTORY_SYNC_PREPACK_MODAL, // a unique identifier for this form
})(InventorySyncPrepackModal);
