import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, FormControl, Row} from 'react-bootstrap';
import Search from '../../common/grid/Search';

const Toolbar = ({searchString, updateSearch, sort, updateSort}) => {

  return (
    <Row className='toolbar'>
      <Col md={12}>
        <FormControl as='select' onChange={updateSort} value={sort} style={{float: 'left', width: '25%'}}>
          <option value='name asc'>{I18n.t('productMenu.sortNameAsc')}</option>
          <option value='name desc'>{I18n.t('productMenu.sortNameDesc')}</option>
          {/*<option value='sortPrice asc'>{I18n.t('productMenu.sortPriceAsc')}</option>*/}
          {/*<option value='sortPrice desc'>{I18n.t('productMenu.sortPriceDesc')}</option>*/}
          <option value='subcategory_name asc'>{I18n.t('productMenu.sortCategoryAsc')}</option>
          <option value='subcategory_name desc'>{I18n.t('productMenu.sortCategoryDesc')}</option>
        </FormControl>
        <Search
          className='float-right'
          searchString={searchString}
          updateSearch={updateSearch}
          scanSearchEnabled={false}
          autoFocus={true} />
      </Col>
    </Row>
  );
};

Toolbar.propTypes = {
  searchString: PropTypes.string.isRequired,
  updateSearch: PropTypes.func.isRequired,
  updateSort: PropTypes.func.isRequired,
  sort: PropTypes.string.isRequired,
};

export default Toolbar;
