import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import SingleFieldInfo from '../common/SingleFieldInfo';

const status = 'residual_solvents_status';

const ResidualSolvents = ({row, dimensions, editable, disabled, isIsolocity, isolocityTestResult, requiredDimensions,
                            isLabApplyProfiles, displayCompletionStatus}) => (
  <SingleFieldInfo
    row={row || {}}
    status={status}
    columns={get(dimensions, 'residual_solvents', [])}
    requiredDimensions={requiredDimensions}
    editable={editable}
    disabled={disabled}
    isIsolocity={isIsolocity}
    isolocityStatus={get(isolocityTestResult, `inspection.sub_statuses.${status}`, 'In Progress')}
    isLabApplyProfiles={isLabApplyProfiles}
    displayCompletionStatus={displayCompletionStatus}
  />
);

ResidualSolvents.propTypes = {
  row: PropTypes.object,
  dimensions: PropTypes.object,
  editable: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  isIsolocity: PropTypes.bool,
  isolocityTestResult: PropTypes.object,
  requiredDimensions: PropTypes.array,
  isLabApplyProfiles: PropTypes.bool,
  displayCompletionStatus: PropTypes.bool
};

ResidualSolvents.defaultProps = {
  disabled: false,
};

export default ResidualSolvents;
