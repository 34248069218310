import React from 'react';
import {Image, Row, Col, Button} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import { reduxForm, FieldArray } from 'redux-form';
import FormWrapper from '../../common/form/FormWrapper';

import {ListProducts} from './ListProducts';

class MergeOutNetworkPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onSave = this.onSave.bind(this);
  }

  onSave() {

  }

  render() {
    const partner = {
      name: 'Johny Rockets',
      stateLicense: 'CO-45602635',
      address: '3456 South West Ave., Suite 302 Denver, CO 80003',
      phone: '720-233-1555',
      products: [
        {id: 1, inNetworkDescription: 'Bubba Kush BK-2643'},
        {id: 2, inNetworkDescription: 'Bubba Kush BK-2643'},
        {id: 3, inNetworkDescription: 'Bubba Kush BK-2643'},
        {id: 4, inNetworkDescription: 'Bubba Kush BK-2643'}
      ]
    };

    const productOptions = [
      {text: 'choose product to match', value: ''},
      {text: 'Some Option', value: '1'},
      {text: 'Other Option', value: '2'}
    ];

    return (
      <FormWrapper title={I18n.t('partners.title')} goBack={this.redirect}>
      <form className='merge-network partners-page'>
        <h1>{I18n.t('partners.mergeTitle')}</h1>
        <Row>
          <Image className='merge-network-img' src='/images/partners-merge-icon.png'/>
        </Row>
        <Row className='merge-network-info'>
          <Col xs={3} md={3} className='merge-network-contacts'>
            <h4>{partner.name}</h4>

            <h5>{I18n.t('partner.table.stateLicense')}</h5>
            <p>{partner.stateLicense}</p>

            <h5>{I18n.t('partner.table.address')}</h5>
            <p>{partner.address}</p>

            <h5>{I18n.t('partner.table.phone')}</h5>
            <p>{partner.phone}</p>
          </Col>

          <Col xs={8} md={9}>
            <Row>
              <Col xs={12}>
                <h4>{I18n.t('partner.productList')}</h4>
              </Col>
            </Row>
            <Row>
              <Col xs={6} md={6}>
                <h5>{I18n.t('partner.outOfNetworkProductDescription')}</h5>
              </Col>
              <Col xs={6} md={6}>
                <h5>{I18n.t('partner.inNetworkProductDescription')}</h5>
              </Col>
            </Row>
            <FieldArray name='products' products={partner.products} productOptions={productOptions} component={ListProducts} />

          </Col>
        </Row>
        <div className='save-merge-button'>
          <Button variant='primary' onClick={this.onSave}>Save and Merge</Button>
        </div>
      </form>
      </FormWrapper>
    );
  }
}

export default reduxForm({
  form: 'mergePartner'
})(MergeOutNetworkPage);
