import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {I18n} from 'react-redux-i18n';
import {go} from 'react-router-redux';
import * as systemActions from '../../actions/systemActions';

export class NoPermission extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.redirect = this.redirect.bind(this);
  }

  redirect() {
    this.props.actions.go(-2);
  }

  render() {
    return (
      <div>
        <h1>{I18n.t('common.nopermission.title')}</h1>
        <a onClick={() => {this.redirect();}}>{I18n.t('common.nopermission.text')}</a>
      </div>
    );
  }
}

NoPermission.propTypes = {
  actions: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired
};

function mapStateToProps(state){
  return {
    state
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({},
    {go}, systemActions
  );
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (NoPermission);
