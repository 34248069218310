import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { goBack, push } from 'react-router-redux';
import { reset } from 'redux-form';
import { unsetItem, unsetItemAction } from '../../../../actions/itemActions';
import { getModifyPricingClassInitialValues } from '../../../../selectors/pricingClassSelectors';

import * as apiActions from '../../../../actions/apiActions';
import * as dataNames from '../../../../constants/dataNames';
import * as itemNames from '../../../../constants/itemNames';

import FormWrapper from '../../../common/form/FormWrapper';
import ModifyPricingClassFormWrapper from './ModifyPricingClassFormWrapper';
import getPayloadForPricingClasses from '../common/getPayloadForPricingClasses';

export class ModifyPricingClassPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.onSubmit = this.onSubmit.bind(this);
    this.redirect = this.redirect.bind(this);
    this.resetForm = this.resetForm.bind(this);
  }

  componentWillMount() {
    const {
      params: {id}
    } = this.props;

    this.props.actions.getItem(`/api/pricing_classes/${id}`, itemNames.pricingClass, {
      failed: 'retail.pricingClass.get.failed'
    });

    this.props.actions.getUnpaginatedData('/api/consumer_groups', dataNames.customerGroups, {
      failed: 'retail.customerGroupListing.loadGroupsFailed'
    });
  }

  componentWillUnmount() {
    this.props.actions.unsetItem(itemNames.pricingClass);
  }

  onSubmit(formValues) {
    const payload = getPayloadForPricingClasses(formValues);

    const {
      params: {id}
    } = this.props;

    const responses = {
      success: 'retail.pricingClass.modify.success',
      failed: 'retail.pricingClass.modify.failed',
    };

    this.props.actions.putItem(`/api/pricing_classes/${id}`, payload, itemNames.pricingClass, responses, {}, () => {
      this.resetForm();
      this.redirect();
    });
  }

  redirect() {
    this.props.actions.push('/retail/pricing-classes/active');
  }

  resetForm() {
    this.props.actions.reset('modifyPricingClassForm');
  }

  render() {
    const { customerGroups, initialValues } = this.props;

    return (
      <FormWrapper className='modify-pricing-class' title='retail.pricingClass.modify.title' goBack={this.redirect}>
        {initialValues && <ModifyPricingClassFormWrapper
          onSubmit={this.onSubmit}
          customerGroups={customerGroups}
          initialValues={initialValues}/>}
      </FormWrapper>
    );
  }
}

ModifyPricingClassPage.propTypes = {
  actions: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    reset: PropTypes.func,
    getUnpaginatedData: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    putItem: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired,
  }).isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  customerGroups: PropTypes.array.isRequired,
  initialValues: PropTypes.object,
};

function mapStateToProps(state) {
  const customerGroups = state[dataNames.customerGroups];
  const initialValues = getModifyPricingClassInitialValues(state);

  return {
    customerGroups,
    initialValues
  };
}

function mapDispatchToProps (dispatch) {
  const actions = Object.assign({}, apiActions, { goBack, reset, unsetItem, unsetItemAction, push });

  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModifyPricingClassPage);
