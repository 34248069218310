import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { goBack, push } from 'react-router-redux';
import { I18n } from 'react-redux-i18n';
import { formValueSelector, change, arrayRemoveAll, arrayPush } from 'redux-form';

import FormWrapper from '../../../common/form/FormWrapper';
import ModifyEquipmentFormWrapper from './ModifyEquipmentFormWrapper';
import { getUnpaginatedData, getItem, postItem, putData, postData } from '../../../../actions/apiActions';
import * as dataNames from '../../../../constants/dataNames';
import * as itemNames from '../../../../constants/itemNames';
import { getUomsTypeByType } from '../../../../selectors/uomsSelectors';
import { getPartnersForPurchase } from '../../../../selectors/partnersSelectors';
import { getCreatePayload, getModifyPayload } from '../../../../selectors/equipmentSelectors';

const formName = 'EquipmentForm';

export class ModifyEquipmentPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = { uploading: false, images: [], ready: false, saving: false };
    this.changeUploadStatus = this.changeUploadStatus.bind(this);
    this.addImageRef = this.addImageRef.bind(this);
    this.onAddVendor = this.onAddVendor.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.redirect = this.redirect.bind(this);
    this.setImage = this.setImage.bind(this);
  }

  componentWillMount() {
    const equipmentId = this.props.params.id;

    Promise.all([
      this.props.actions.getItem(`/api/equipment/${equipmentId}`,
        itemNames.equipment,
        null,
        { detailed: 1 },
        (response) => {
          const {equipment_photo_file_id} = response;
          equipment_photo_file_id ? this.props.actions.getItem(`/api/images/${equipment_photo_file_id}`,
            itemNames.image,
            null,
            null,
            (image) => {
              const imageUrl = image && image.url ? image.url.original : undefined;
              this.setState({images: [imageUrl]});
            })
            : null;
        }),
      this.props.actions.getUnpaginatedData('/api/uoms', dataNames.uoms),
      this.props.actions.getUnpaginatedData('/api/equipment/equipment_types', dataNames.equipmentTypes),
      this.props.actions.getUnpaginatedData('/api/partners/details', dataNames.partners),
      this.props.actions.getUnpaginatedData('/api/equipment/equipment_service_tags', dataNames.tags)
    ]).then(() => this.setState({ ready: true }))
      .catch(() => this.setState({ ready: true }));
  }

  onSubmit(formData) {
    event.preventDefault();

    const { store_many } = getCreatePayload(formData);
    const equipmentId = this.props.params.id;

    if (store_many.length) {
      this.props.actions.putData(
        `/api/equipment/${equipmentId}`,
        store_many[0],
        null,
        { failed: 'equipment.modify.failed', success: 'equipment.modify.activateSuccess' },
        null,
        () => {
          this.redirect();
        }
      );
    }
  }

  redirect() {
    this.props.actions.goBack();
  }

  addImageRef(input) {
    this.imageInput = input;
  }

  changeUploadStatus(uploading) {
    this.setState({ uploading });
  }

  onAddVendor() {
    this.props.actions.push('/partners/create');
  }

  setImage(imageUrl, index) {
    const images = this.state.images;
    images[index] = imageUrl;
    this.setState({ images: images });
  }

  render() {
    const { getFormValue, uomOptions, vendors, serviceTypes, equipmentTypes, equipment } = this.props;
    const { uploading, images } = this.state;
    const initialValues = {
      items: [equipment]
    };

    return (
      <FormWrapper goBack={this.redirect} title={I18n.t('equipment.actions.modifyEquipment')}>
        <ModifyEquipmentFormWrapper
          form={formName}
          uomOptions={uomOptions}
          onSubmit={this.onSubmit}
          initialValues={initialValues}
          onAddVendor={this.onAddVendor}
          keepDirtyOnReinitialize={true}
          enableReinitialize={true}
          getFormValue={getFormValue}
          changeUploadStatus={this.changeUploadStatus}
          uploading={uploading}
          images={images}
          addImageRef={this.addImageRef}
          setImage={this.setImage}
          vendors={vendors}
          serviceTypes={serviceTypes}
          equipmentTypes={equipmentTypes}
          change={change}
          modifyMode={true}
        />
      </FormWrapper>
    );
  }
}

ModifyEquipmentPage.propTypes = {
  actions: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  getFormValue: PropTypes.func.isRequired,
  uomOptions: PropTypes.array.isRequired,
  vendors: PropTypes.array.isRequired,
  serviceTypes: PropTypes.array.isRequired,
  equipmentTypes: PropTypes.array.isRequired,
  params: PropTypes.object.isRequired,
  equipment: PropTypes.object.isRequired,
};

const selector = formValueSelector(formName);

function mapStateToProps(state) {
  const { tags, equipmentTypes } = state;
  return {
    getFormValue: name => selector(state, name),
    state,
    vendors: getPartnersForPurchase(state),
    uomOptions: getUomsTypeByType(state, { uom_type: 'time' }),
    serviceTypes: tags,
    equipmentTypes,
    equipment: getModifyPayload(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    goBack,
    push,
    getUnpaginatedData,
    getItem,
    postItem,
    putData,
    postData,
    change,
    arrayRemoveAll,
    arrayPush
  };
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModifyEquipmentPage);
