import moment from 'moment';
import React from 'react';
import {Col,Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Field, reduxForm, reset} from 'redux-form';
import {I18n} from 'react-redux-i18n';

import {TRANSACTION_SEARCH_FORM} from '../../../../constants/forms';
import {
  getCsrOptions,
  getFulfillmentOptions,
  getOrderTypeOptions,
  getInitialValues,
  getPatientTypeOptions,
  getPaymentOptions,
  getRegisterOptions
} from '../../../../selectors/transactionReportSelectors';

import InternationalDatePickerInput from '../../../common/form/InternationalDatePickerInput';
import MultiselectInput from '../../../common/form/MultiselectInput';
import TextInput from '../../../common/form/TextInput';
import SubmitSection from '../../../common/form/SubmitSection';
import {isPickableDate} from '../../../../selectors/salesReportSelectors';

//
// Base form component for transaction report search
//
const SearchForm = (props) => {
  const {
    csrOptions,
    fulfillmentOptions,
    handleSubmit,
    orderTypeOptions,
    patientTypeOptions,
    paymentOptions,
    pristine,
    registerOptions,
    reset,
    submitSearch,
    isPickableDate
  } = props;

  const submitSettings = {
    actionSettings: {
      reset: {
        action: reset,
        text: I18n.t('common.form.reset'),
        pristine
      },
      submit: {
        action: handleSubmit(submitSearch),
        text: I18n.t('common.actions.search'),
      }
    }
  };

  return (
  <form onSubmit={handleSubmit(submitSearch)}>
    <Row>
      <Col sm={6} md={4}>
        <Field
          name='report_date'
          component={InternationalDatePickerInput}
          props={{
            label: I18n.t('retail.transactionReport.reportDateLabel'),
            isRequired: true,
            isValidDate: isPickableDate,
            closeOnSelect: true
          }}
        />
      </Col>

      <Col sm={6} md={4}>
        <Field
          name='registers'
          component={MultiselectInput}
          props={{
            label: I18n.t('retail.transactionReport.registersLabel'),
            options: registerOptions,
          }}
        />
      </Col>

      <div className='d-none d-sm-block d-md-none clearfix'></div>

      <Col sm={6} md={4}>
        <Field
          name='order_types'
          component={MultiselectInput}
          props={{
            label: I18n.t('retail.transactionReport.orderTypesLabel'),
            options: orderTypeOptions,
          }}
        />
      </Col>

      <div className='d-none d-md-block d-xl-none clearfix'></div>

      <Col sm={6} md={4}>
        <Field
          name='patient_types'
          component={MultiselectInput}
          props={{
            label: I18n.t('retail.transactionReport.patientTypesLabel'),
            options: patientTypeOptions,
          }}
        />
      </Col>

      <div className='d-none d-sm-block d-md-none clearfix'></div>

      <Col sm={6} md={4}>
        <Field
          name='payment_methods'
          component={MultiselectInput}
          props={{
            label: I18n.t('retail.transactionReport.paymentMethodsLabel'),
            options: paymentOptions,
          }}
        />
      </Col>


      <Col sm={6} md={4}>
        <Field
          name='fulfillment_methods'
          component={MultiselectInput}
          props={{
            label: I18n.t('retail.transactionReport.fulfillmentMethodsLabel'),
            options: fulfillmentOptions,
          }}
        />
      </Col>

      <div className='d-block d-sm-block d-none d-md-block d-none d-lg-block clearfix'></div>

      <Col sm={6} md={4}>
        <Field
          name='csrs'
          component={MultiselectInput}
          props={{
            label: I18n.t('retail.transactionReport.csrsLabel'),
            options: csrOptions,
          }}
        />
      </Col>

      <Col sm={6} md={4}>
        <Field
          name='name'
          component={TextInput}
          props={{
            label: I18n.t('retail.transactionReport.orderIdLabel'),
            leftAddon: <span>ORD.</span>,
          }}
        />
      </Col>

      <div className='d-block d-sm-block d-none d-md-block d-none d-lg-block clearfix'></div>

      <Col xs={12}>
        <SubmitSection settings={submitSettings} />
      </Col>
    </Row>
  </form>
  );
};

SearchForm.propTypes = {
  csrOptions:         PropTypes.array.isRequired,
  fulfillmentOptions: PropTypes.array.isRequired,
  handleSubmit:       PropTypes.func.isRequired,
  orderTypeOptions:   PropTypes.array.isRequired,
  patientTypeOptions: PropTypes.array.isRequired,
  paymentOptions:     PropTypes.array.isRequired,
  pristine:           PropTypes.func.isRequired,
  registerOptions:    PropTypes.array.isRequired,
  reset:              PropTypes.func.isRequired,
  submitSearch:       PropTypes.func.isRequired,
};


// SearchForm validation
const validate = (values) => {
  const errors = {};

  // require report_date
  if(!values.report_date){
    errors.report_date = I18n.t('common.form.isRequired',{
      fieldName: I18n.t('retail.transactionReport.reportDateLabel')
    });
  }

  if(!moment.isMoment(values.report_date)){
    errors.report_date = I18n.t('common.form.invalidFormat');
  } else {
    // require valid date format
    if(!values.report_date.isValid()){
      errors.report_date = I18n.t('common.form.invalidFormat');
    }

    // require past date
    if(+values.report_date.format('YYYYMMDD') > +moment().format('YYYYMMDD')){
      errors.report_date = I18n.t('retail.transactionReport.invalidReportDate');
    }
  }

  if(values.name && values.name.match(/\D/)){
    errors.name = I18n.t('common.form.invalidFormat',{
      fieldName: I18n.t('retail.transactionReport.orderIdLabel')
    });
  }

  return errors;
};

// SearchForm store connection
const mapStateToProps = (state) => {
  return {
    csrOptions:         getCsrOptions(state),
    fulfillmentOptions: getFulfillmentOptions(state),
    orderTypeOptions:   getOrderTypeOptions(state),
    patientTypeOptions: getPatientTypeOptions(state),
    paymentOptions:     getPaymentOptions(state),
    registerOptions:    getRegisterOptions(state),
    reset: reset,
    initialValues: getInitialValues(state),
    isPickableDate: isPickableDate(state)
  };
};

const ReduxSearchForm = reduxForm({
  form: TRANSACTION_SEARCH_FORM,
  validate,
})(SearchForm);

const TransactionSearchForm = connect(mapStateToProps)(ReduxSearchForm);

TransactionSearchForm.propTypes = {
  submitSearch:       PropTypes.func.isRequired,
};

export default TransactionSearchForm;
