import moment from 'moment';
import get from 'lodash.get';
import { isValidDate } from '../../../util/dateHelpers';

export const dateRangeConstraints = (field, current, formValues) => {
  const inverseDateField = field === 'testing_date_from' ? 'testing_date_to' : 'testing_date_from';
  const today = moment();
  if(field === 'testing_date_from') {
    // Start date is valid from end date less 1 year if end date less than today or today less 1 year
    // up until end date.
    const toDate = get(formValues, inverseDateField);
    const endDate = isValidDate(toDate) ? moment(toDate) : today.clone();
    const startDate = isValidDate(toDate) ? (endDate.clone()).subtract('years', 1) : false;
    return startDate
      ? current.unix() >= startDate.unix() && current.unix() <= endDate.unix() // bounded both sides
      : current.unix() <= endDate.unix(); // bounded only on end date side
  } else {
    // End date is valid from start date up to start date + 1 year if start date plus 1 year is less than
    // today... else up until today
    const fromDate = get(formValues, inverseDateField);
    const startDate = isValidDate(fromDate) ? moment(fromDate) : false;
    const endDate = isValidDate(fromDate) ? (startDate.clone()).add('years', 1) : today.clone();
    const getEndDateUnix = () => {
      return endDate.unix() <= today.unix() ? endDate.unix() : today.unix();
    };
    return startDate
      ? current.unix() >= startDate.unix() && current.unix() <= getEndDateUnix() // bounded both sides
      : current.unix() <= getEndDateUnix(); // bounded only on end date side
  }
};

export const handleErrors = (data) => {
  const errorsArray = get(data, 'response.data.errors', []);
  if(!Array.isArray(errorsArray)){
    return false;
  }
  // Iterate the errors by object key
  const errorMessages = errorsArray.reduce((acc, errorMessage) => {
    acc.push(errorMessage);
    return acc;
  }, []);
  return errorMessages.length
    ? {message: errorMessages.join(' | ')}
    : false;
};

export default dateRangeConstraints;
