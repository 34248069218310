import React from 'react';
import PropTypes from 'prop-types';
import NestedRecursiveList from './RecursiveList';

class RecursiveListComponent extends React.PureComponent{

  constructor(props, context) {

    super(props, context);

    this.onExpand = this.onExpand.bind(this);
    this.isExpanded = this.isExpanded.bind(this);

    this.state = {
      collapsed: []
    };

  }

  onExpand(key){
    const collapsed = this.state.collapsed.map( (item) => item);
    if(this.isExpanded(key)) {
      collapsed.push(key);
    } else {
      const index = collapsed.indexOf(key);
      collapsed.splice(index, 1);
    }
    this.setState({collapsed: collapsed});
  }

  isExpanded(key){
    return (this.state.collapsed.indexOf(key) === -1);
  }

  render() {

    const props = this.props;
    const classes = (!props.isListRoot) ? 'list-group list-group-collapse' : 'list-group list-group-collapse list-group-root well';

    // Exists here and at child component recursive list.  They are similar but not the same.
    const wildCardRegex = /^is_|_(at|id)$/;
    const excludeFilter = ['package_id', 'fulfillment_units'];
    const includeFilter = ['packaged_at', 'package_created_at', 'package_expires_at', 'package_use_by_at', 'state_integration_tracking_id'];

    const skipThisProp = (prop, regex, excludeFilter, includeFilter) => {
      const skip = prop.match(regex);
      if(skip && !includeFilter.includes(prop)){
        return true;
      }
      return excludeFilter.includes(prop);
    };

    return (
      <div className={classes}>
        {
          props.node.reduce((acc, node) => {
            if (node === undefined) return acc;
            if (Object.keys(node).length === 0) return acc;
            Object.keys(node).forEach((prop) => {

              const skip = skipThisProp(prop, wildCardRegex, excludeFilter, includeFilter);
              if(skip){
                return null;
              }

              const indent     = 15;
              const numIndents = !props.prefix ? 1 : props.prefix.split('.').length + 1;

              const style = {
                paddingLeft: (indent * numIndents) + 'px'
              };

              const propPrefix = !props.prefix ? '' : props.prefix + '.';

              if (typeof node[prop] === 'object' && node[prop] !== null) {

                const nodePrefix = !props.prefix ? prop : props.prefix + '.' + prop;

                style.cursor = 'pointer';

                acc.push(
                  <div key={acc.length}>
                    <div
                      onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        this.onExpand(prop);
                      }}
                      style={style}
                      key={acc.length}
                      className='list-group-item'>
                      <span
                        style={{position: 'relative', left: '-5px'}}
                        className={this.isExpanded(prop) ? 'glyphicon glyphicon-chevron-down' : 'glyphicon glyphicon-chevron-right'}>
                      </span>
                      <em><strong>{propPrefix}</strong>{prop}
                    </em>
                    </div>
                    <NestedRecursiveList
                      expandedKey={prop}
                      isExpanded={this.isExpanded}
                      onExpand={this.onExpand}
                      isListRoot={false}
                      prefix={nodePrefix}
                      node={[node[prop]]}
                      skipThisProp={skipThisProp}
                      wildCardRegex={wildCardRegex}
                    />
                  </div>
                );

              } else {

                acc.push(
                  <a
                    style={style}
                    key={acc.length}
                    className='list-group-item'>
                    <em><strong>{propPrefix}</strong>{prop}</em>
                  <span className='float-right'>
                    {node[prop]}
                  </span>
                  </a>
                );

              }
            });
            return acc;
          }, [])
        }
      </div>
    );
  }
}

RecursiveListComponent.propTypes = {
  isListRoot: PropTypes.bool,
  node: PropTypes.array.isRequired,
  prefix: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ])
};

export default RecursiveListComponent;
