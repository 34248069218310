import React from 'react';
import PropTypes from 'prop-types';
import {Field, FieldArray} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {
  Form,
  Button,
  ButtonToolbar,
  Col,
  Row,
} from 'react-bootstrap';
import * as p from '../../../constants/permissions';
import PermissionButton from '../../common/PermissionButton';
import PermissionWrapper from '../../common/PermissionWrapper';
import AccordionPanel from '../../common/AccordionPanel';
import InternationalDateTimePickerInput from '../../common/form/InternationalDateTimePickerInput';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import AddNoteSection from '../common/AddNoteSection';
import IndividualPlantInformation from './IndividualPlantInformation';
import IndividualPlantInformationNoRating from './IndividualPlantInformationNoRating';
import WillRender from '../../common/concealers/WillRender';
import NumericInput from '../../common/form/NumericInput';
import InlineCheckBox from '../../common/form/InlineCheckBox';


const HarvestPlantsForm = (props) => {

  const {
    selectedPlants, locations, harvests, use_overall, allowDistributedWeights,
    integrationState, handleSubmit, pristine, reset, submitting,
    change, tracking, toggleOverall, saved, trackPlantsAsGroups,
    remaining_wet_weight_harvest, remaining_wet_other_material_weight,
    harvestWasteTypes, plantsLimit, isBatchIdSearch, selectableUoms, uom
  } = props;

  const {isMetrc, isBiotrack, isHemp} = integrationState;
  const plantsExceedLimit = selectedPlants && selectedPlants.length > plantsLimit;
  const maxPlants = parseInt(props.maxPlants);

  const useOverallField = (
    <Field
      name='use_overall'
      style={{width: '18px'}}
      onChange={toggleOverall}
      className='inline-checkbox'
      component={InlineCheckBox}
      props={{
        label: I18n.t('harvest.form.useIndividualValues'),
        groupClassName: 'inline-checkbox'
      }} />
  );

  return (
    <Form onSubmit={handleSubmit}>
      <AccordionPanel title={`${selectedPlants.length} ${selectedPlants[0].strain_name}`}>
        {
          selectedPlants.length === 1 || !allowDistributedWeights || plantsExceedLimit
            ? null :
            useOverallField
        }
        <WillRender ifTrue={isHemp}>
          <Field
            component={ReactSelectInput}
            name='uom'
            props={{
              label: I18n.t('common.form.uomPlaceholder'),
              options: selectableUoms,
              textKey: 'name',
              valueKey: 'code'
            }}/>
        </WillRender>

        <PermissionWrapper
          permissions={[p.rate_products]}
          alternateComponent={
            <FieldArray name='plants' component={IndividualPlantInformationNoRating}
              props={{
                selectedPlants,
                use_overall,
                change,
                tracking,
                integrationState,
                remaining_wet_weight_harvest,
                remaining_wet_other_material_weight,
                trackPlantsAsGroups,
                uom
              }}/>
          }>
          <FieldArray name='plants' component={IndividualPlantInformation} props={{
            selectedPlants,
            use_overall,
            change,
            tracking,
            allowDistributedWeights,
            integrationState,
            remaining_wet_weight_harvest,
            remaining_wet_other_material_weight,
            trackPlantsAsGroups,
            uom
          }}/>
        </PermissionWrapper>

        <WillRender ifTrue={plantsExceedLimit}>
          <div>
            {I18n.t('harvest.form.tooManyPlantsNotice', {plantsLimit})}
          </div>
        </WillRender>
      </AccordionPanel>
      <Row>

        <Col lg={3} xs={12}>
          <Field
            name='num_plants'
            component={NumericInput}
            props={Object.assign({}, {
              label: trackPlantsAsGroups ? I18n.t('plants.modify.groupCount') : I18n.t('harvest.form.totalPlants'),
              readOnly: isNaN(maxPlants) || !isBatchIdSearch,
              onBlur: (e) => { // Cannot access the field event in middleware so...
                const value = parseInt(e.currentTarget.value);
                if(isNaN(value)){
                  change('num_plants', 1);
                }
              }
            }, isNaN(maxPlants) ? {value: selectedPlants.length} : {})}/>
          <WillRender ifTrue={!isNaN(maxPlants)}>
            <div style={{fontSize: 'smaller', position: 'relative', top: '-5px'}}>
              {I18n.t('plants.modify.minMax', {min: 1, max: maxPlants})}<br />
              {I18n.t('plants.modify.numPlantsNote')}
            </div>
          </WillRender>
        </Col>
        <WillRender ifTrue={trackPlantsAsGroups}>
          <Col lg={3} xs={12}>
            <Field
              name='total_plants'
              component={NumericInput}
              props={{
                label: I18n.t('harvest.form.totalPlants'),
                readOnly: true,
              }}/>
          </Col>
        </WillRender>

        {isMetrc || isBiotrack ?
          null :
          <Col lg={3} xs={12}>
            <Field
              name='harvests' component={ReactSelectInput}
              props={{
                label: I18n.t('harvest.form.selectHarvestBatch'),
                options: harvests,
                valueKey: 'id',
                textKey: 'batch_name',
                placeholder: I18n.t('common.form.selectPlaceholder')
              }}/>
          </Col>
        }
        <Col lg={3} xs={12}>
          <InternationalDateTimePickerInput
            name='harvested_at'
            props={{
              label: I18n.t('harvest.form.harvestDate'),
              placeholder: I18n.t('plants.form.quantityPlaceholder'),
              enableTodayButton: false,
            }}/>
        </Col>
        {isMetrc && harvestWasteTypes && harvestWasteTypes.length > 0 ?
          <Col lg={3} xs={12}>
            <Field
              name='waste_type' component={ReactSelectInput}
              props={{
                label: I18n.t('harvest.form.harvestWasteType'),
                options: harvestWasteTypes,
                valueKey: 'name',
                textKey: 'name'
              }}/>
          </Col> : null
        }
      </Row>
      <Row>
        <Col className='form-fields-col' xs={12} md={6}>
          <Field
            name={isBiotrack ? 'section_id' : 'inventory_location_id'}
            component={ReactSelectInput}
            props={{
              label: I18n.t('plants.form.storageArea'),
              options: locations,
              placeholder: I18n.t('common.form.selectPlaceholder'),
              isRequired: true,
            }}/>
        </Col>
        <Col xs={12} md={6}>
          <AddNoteSection/>
        </Col>
      </Row>
      <ButtonToolbar className='float-right'>
        <Button type='reset'
                onClick={reset}
                disabled={submitting || pristine || saved}>
          {I18n.t('common.actions.reset')}
        </Button>
        <PermissionButton
          permissions={[p.print_labels]}
          props={{
            variant: 'info',
            type: 'submit',
            onClick: () => change('afterSubmit', 'print')
          }}>
          {
            !saved
              ? I18n.t('plants.form.saveAndPrintLabel')
              : I18n.t('common.printLabel')
          }
        </PermissionButton>
        <Button
          variant='primary'
          className={saved ? 'hide' : ''}
          disabled={submitting}
          type='submit'
          onClick={() => change('afterSubmit', 'redirect')}
        >
          {I18n.t('common.form.save')}
        </Button>
      </ButtonToolbar>
    </Form>);
};

HarvestPlantsForm.propTypes = {
  saved: PropTypes.bool.isRequired,
  toggleOverall: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  use_overall: PropTypes.bool,
  integrationState: PropTypes.object.isRequired,
  plants: PropTypes.array,
  selectedPlants: PropTypes.array.isRequired,
  harvests: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  saveAndPrint: PropTypes.func,
  reset: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  tracking: PropTypes.bool.isRequired,
  allowDistributedWeights: PropTypes.bool.isRequired,
  remaining_wet_weight_harvest: PropTypes.number,
  remaining_wet_other_material_weight: PropTypes.number,
  trackPlantsAsGroups: PropTypes.bool.isRequired,
  harvestWasteTypes: PropTypes.array,
  maxPlants: PropTypes.string,
  plantsLimit: PropTypes.number.isRequired,
  isBatchIdSearch: PropTypes.bool.isRequired,
  selectableUoms: PropTypes.array.isRequired,
  uom: PropTypes.string.isRequired
};

HarvestPlantsForm.defaultProps = {
  tracking: false,
};

export default HarvestPlantsForm;
