import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Button} from 'react-bootstrap';

const MetrcIdChangeConfirm = (props) => {

  const onConfirm = (event) => {
    const formValues = Object.assign({}, props.formValues, {integration_id_platform_correction_confirmed: true});
    props.onSubmit(formValues);
  };

  return (<div>
      <Col md={12}>
        <div style={{marginTop: '12px'}}>
          {I18n.t('packages.form.idChangeConfirm.text')}
          <div style={{height: '12px'}} />
          <table className='table table-striped table-bordered'>
            <tbody>
              <tr>
                <td>
                  {I18n.t('packages.form.idChangeConfirm.originalTagValue')}:
                </td>
                <td>
                  {props.formValues.packages[0].initial_state_integration_tracking_id}
                </td>
              </tr>
              <tr>
                <td>
                  {I18n.t('packages.form.idChangeConfirm.newTagValue')}:
                </td>
                <td>
                  {props.formValues.packages[0].state_integration_tracking_id}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Col>
      <div style={{clear:'both'}} />
      <hr />
      <Col md={12}>
        <Button variant='warning' style={{float:'left'}} onClick={(e) => props.onHide()}>
          {I18n.t('packages.form.idChangeConfirm.cancel')}
        </Button>
        <Button variant='primary' style={{float:'right'}} onClick={onConfirm}>
          {I18n.t('packages.form.idChangeConfirm.confirm')}
        </Button>
        <div style={{clear:'both'}} />
      </Col>
      <div style={{clear:'both'}} />
  </div>);

};

MetrcIdChangeConfirm.propTypes = {
  formValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default MetrcIdChangeConfirm;
