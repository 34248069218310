import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Row, Col, Button} from 'react-bootstrap';
import {Field} from 'redux-form';

import AccordionPanel from '../../common/AccordionPanel';
import SubmitSection from '../../common/form/SubmitSection';
import TextField from '../../common/form/TextInput';
import InternationalReduxPhoneInput from '../../common/form/redux-form/InternationalReduxPhoneInput';

const CustomerFilterPanel = ({pristine, submitting, reset, showScanModal, isPALeafIntegrator}) => {
  return (<AccordionPanel title='consumer.checkin.filter'>
      <Row>
        <Col xs={12}>
          <span>
            <Button type='button' className='float-right' onClick={showScanModal('scan')}>
              {I18n.t('customers.create.scanLicense')}
            </Button>
          </span>
        </Col>
        <Col xs={12}><hr/></Col>
      </Row>
      <Row>
        <Col xs={12} sm={6} lg={2}>
          <Field component={TextField} name={'first_name'} props={{
            label: I18n.t('consumer.checkin.firstName')
          }}/>
        </Col>
        <Col xs={12} sm={6} lg={2}>
          <Field component={TextField} name={'last_name'} props={{
            label: I18n.t('consumer.checkin.lastName')
          }}/>
        </Col>
        <Col xs={12} sm={6} lg={2}>
          <Field component={TextField} name={'email_address'} props={{
            label: I18n.t('consumer.checkin.emailAddress')
          }}/>
        </Col>
        <Col xs={12} sm={6} lg={2}>
          <InternationalReduxPhoneInput name={'phone_number'} props={{
            label: I18n.t('consumer.checkin.phoneNumber')
          }}/>
        </Col>
        <Col xs={12} sm={6} lg={2}>
          <Field component={TextField} name={'consumer_code'} props={{
            label: I18n.t('consumer.checkin.customerCode')
          }}/>
        </Col>
        <Col xs={12} sm={6} lg={2}>
          <Field component={TextField} name={'identification_number'} props={{
            label: I18n.t('consumer.checkin.ids')
          }}/>
        </Col>
      </Row>
      {isPALeafIntegrator ?
        <Row>
          <Col xs={12} sm={6} lg={2}>
            <Field component={TextField} name={'med_license_number'} props={{
              label: I18n.t('consumer.checkin.medicalMarijuanaID')
            }}/>
          </Col>
          <Col xs={12} sm={6} lg={2}>
            <Field component={TextField} name={'patient_state_id'} props={{
              label: I18n.t('consumer.checkin.stateMedicalID')
            }}/>
          </Col>
          <Col xs={12} sm={6} lg={2}>
            <Field component={TextField} name={'caregiver_state_id'} props={{
              label: I18n.t('consumer.checkin.caregiverStateMedicalID')
            }}/>
          </Col>
          <Col xs={12} sm={6} lg={2}>
            <Field component={TextField} name={'drivers_license_number'} props={{
              label: I18n.t('consumer.checkin.driversLicense')
            }}/>
          </Col>
        </Row>
        : null}
      <Row>
        <Col xs={12}>
          <SubmitSection settings={{actionSettings: {
            reset: {
              pristine,
              submitting,
              action: reset,
              type: 'reset',
              text: I18n.t('consumer.checkin.reset')
            },
            submit: {
              pristine,
              submitting,
              type: 'submit',
              style: 'primary',
              text: I18n.t('consumer.checkin.filter')
            }
          }
        }}/>
        </Col>
      </Row>
    </AccordionPanel>);
};

CustomerFilterPanel.propTypes = {
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  showScanModal: PropTypes.func.isRequired,
  isPALeafIntegrator: PropTypes.bool.isRequired,
};

export default CustomerFilterPanel;
