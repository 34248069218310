import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, Field, getFormValues, reset} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Row, Col, Button} from 'react-bootstrap';
import {bindActionCreators} from 'redux';
import {postItem, getItem} from '../../../actions/apiActions';
import {setItem} from '../../../actions/itemActions';
import {setData, unsetData} from '../../../actions/dataActions';
import { setAdjustmentInRedux } from '../../../actions/rewardsActions';
import * as itemNames from '../../../constants/itemNames';
import * as dataNames from '../../../constants/dataNames';
import {getRewardsEnabledState} from '../../../selectors/rewardsSelectors';
import {hasViewPermission, hasAdjustPermission} from '../../../selectors/forms/rewardsFormSelectors';
import {requiredFieldValidation, integerFieldValidation, minValidation, alphaNumericValidation} from '../../common/form/redux-form/validations';
import TextInput from '../../common/form/TextInput';
import { hasPermAdjustRewardPoints } from '../../../selectors/permissionsSelectors';

class RewardsEditorPOS extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.setPoints = this.setPoints.bind(this);
    this.commitAdjustments = this.commitAdjustments.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      pointAdjustment: 0,
      pointAdjustmentInitialValue : this.props.customer.total_points || 0,
      enablePointAdjustmentButtons: true
    };
  }

  // fetch reward settings from api if needed
  componentWillMount() {
    if(Object.keys(this.props[itemNames.rewardSettings]).length === 0) {
      this.props.actions.getItem('/api/rewards/setup', itemNames.rewardSettings, null);
    }
  }

  componentWillReceiveProps(nextProps, nextState){
    if (nextProps.customer.total_points != this.props.customer.total_points) {
      this.setState({pointAdjustmentInitialValue: nextProps.customer.total_points || 0});
    }
  }

  // point adjustment button handler
  setPoints(points, e){
    // swallow click event
    if(e){
      e.stopPropagation();
      e.preventDefault();
      e.target.blur();
    }

    // update local state
    this.setState({pointAdjustment: this.state.pointAdjustment + points});
  }

  // point adjustment button handler
  commitAdjustments(){
    this.setState({enablePointAdjustmentButtons: false});
    const url = `/api/customers/${this.props.customer.id}/rewards/adjust`;
    this.props.actions.postItem(url, {points: this.state.pointAdjustment})
      .then((data) => {
        this.setState({enablePointAdjustmentButtons: true, pointAdjustment: 0, pointAdjustmentInitialValue: data.total_points});
        this.props.actions.reset(this.props.form);
        if (this.props.reloadCustomerAfterSave) {
          this.props.actions.getItem(`/api/customers/${this.props.customer.id}`, itemNames.customer);
        }
      })
      .catch(() => {
        this.setState({enablePointAdjustmentButtons: true});
      });
  }

  onSubmit () {
    const {formValues} = this.props;

    this.setState({
      pointAdjustment: formValues.reward_points - this.state.pointAdjustmentInitialValue,
    }, () => {
      this.commitAdjustments();
    });
  }

  render(){
    if(!this.props.hasViewPermission){
      return (
        <div style={{textAlign:'center'}}>
          {I18n.t('customers.details.rewards_view_not_permitted')}
        </div>
      );
    }

    if(!this.props.rewardsEnabled){
      return (
        <div style={{textAlign:'center'}}>
          {I18n.t('customers.details.rewards_disabled')}
        </div>
      );
    }

    return (
      <form onSubmit={this.onSubmit}>
        <div style={{border:'1px solid #ccc', padding: '5px', margin: '12px 0'}}>
          <Row>
            <Col style={{textAlign: 'center'}}>Reward Points Total: {this.state.pointAdjustmentInitialValue}</Col>
          </Row>
          {this.props.canAdjustRewardPoints &&
          <div>
            <Row>
              <Col xs={12}>
                <Field
                  name='reward_points'
                  component={TextInput}
                  key={this.props.customer.id}
                  placeholder = {I18n.t('customers.details.new_points_total')}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} style={{textAlign: 'center', paddingTop: '8px'}}>
                <Button
                  variant='primary'
                  className='btn-block'
                  onClick={this.onSubmit}
                  disabled={this.props.invalid || !this.state.enablePointAdjustmentButtons}>
                  {I18n.t('common.form.save')}
                </Button>
              </Col>
            </Row>
          </div>
          }
        </div>
      </form>
    );
  }
}

RewardsEditorPOS.propTypes = {
  customer: PropTypes.object.isRequired,
  invalid: PropTypes.bool.isRequired,
  formValues: PropTypes.object,
  form: PropTypes.string.isRequired,
  reset: PropTypes.func,
  reloadCustomerAfterSave: PropTypes.bool,
};

const RewardsEditorForm = reduxForm({
  validate: values => {
    const invalid = requiredFieldValidation(values.reward_points) || integerFieldValidation(values.reward_points) || minValidation(values.reward_points, undefined, 0) || !alphaNumericValidation(values.reward_points);

    if (invalid) {
      return {
        reward_points: I18n.t('customers.details.rewards_invalid'),
      };
    }

    return {};
  },
  destroyOnUnmount: false
})(RewardsEditorPOS);

function mapStateToProps(state, ownProps){
  return {
    hasViewPermission: hasViewPermission(state),
    hasAdjustPermission: hasAdjustPermission(state),
    rewardsEnabled: getRewardsEnabledState(state, ownProps),
    rewardSettings: state[itemNames.rewardSettings],
    rewardPointsAdjustments: state[dataNames.rewardPointsAdjustments],
    formValues: getFormValues(ownProps.form)(state),
    canAdjustRewardPoints: hasPermAdjustRewardPoints(state)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {postItem, setItem, getItem, setData, unsetData, setAdjustmentInRedux, reset};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RewardsEditorForm);
