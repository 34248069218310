import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {goBack} from 'react-router-redux';
import {Button, Col, Row} from 'react-bootstrap';

import * as apiActions from '../../actions/apiActions';
import StrainsTable from './StrainsTable';
import OrganizationStrainListing from './OrganizationStrainListing';
import ModalWrapper from '../common/ModalWrapper';
import  * as dataNames from '../../constants/dataNames';
import * as selectedDataActions from '../../actions/selectedDataActions';
import {setData, unsetData} from '../../actions/dataActions';
import {getOrderedOrganizationStrains, getAvailableOrganizationStrains} from '../../selectors/organizationStrainsSelectors';
import {getFacilityStrains} from '../../selectors/facilityStrainsSelectors';
import InProgressOverlay from '../common/InProgressOverlay';

class StrainsPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      searchString: '',
      searchLetter: '',
      itemsPerPage: 40,
      activePage: 1,
      showModal: false,
      selectedStrainIds: [],
      selectedOrgStrainIds: [],
      removedOrgStrainIds: [],
      allStrainsSelected: false,
      inProgress: true,
      inProgressText: 'Loading Strains',
    };

    this.isFacilityLevel = this.isFacilityLevel.bind(this);
    this.updateSelectedItems = this.updateSelectedItems.bind(this);
    this.search = this.search.bind(this);
    this.showOrganizationStrainListing = this.showOrganizationStrainListing.bind(this);
    this.searchAlphabetically = this.searchAlphabetically.bind(this);
    this.switchPage = this.switchPage.bind(this);
    this.toggleSelect = this.toggleSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleNewSubmit = this.handleNewSubmit.bind(this);
    this.selectStrains = this.selectStrains.bind(this);
  }

  componentWillMount() {
    this.setState({
      selectedStrainIds: [],
      selectedOrgStrainIds: [],
      removedOrgStrainIds: [],
    });
    const {getUnpaginatedData, unsetData} = this.props.actions;
    unsetData(dataNames.organizationStrains);
    unsetData(dataNames.facilityStrains);
    unsetData(dataNames.organizationStrains);
    if (this.isFacilityLevel()) {
      getUnpaginatedData('/api/strains/by_organization', dataNames.organizationStrains);
      getUnpaginatedData('/api/strains/by_facility_names', dataNames.facilityStrains, {failed: 'strains.get.failed'}).then(() => {
        const selectedStrainIds = this.props.facilityStrains.map(strain => strain.id);
        this.setState({selectedStrainIds});
        this.setState({inProgress: false});
      });
    } else {
      getUnpaginatedData('/api/strains/global', dataNames.globalStrains);
      getUnpaginatedData('/api/strains/by_organization', dataNames.organizationStrains).then(() => {
        this.updateSelectedItems();
        this.setState({inProgress: false});
      });
    }
  }

  isFacilityLevel() {
    return this.props.routeParams.level === 'facility';
  }

  updateSelectedItems() {
    const {selectedStrainIds, selectedOrgStrainIds, removedOrgStrainIds} = this.state;
    const selectedIds = this.props.organizationStrains.reduce(
      (acc, orgStrain) => {
        if (orgStrain.global_strain_id) {
          acc.selectedStrainIds.indexOf(orgStrain.global_strain_id) > -1 || acc.selectedStrainIds.push(orgStrain.global_strain_id);
        } else {
          acc.selectedOrgStrainIds.indexOf(orgStrain.id) > -1 || acc.selectedOrgStrainIds.push(orgStrain.id);
          acc.removedOrgStrainIds.indexOf(orgStrain.id) > -1 && acc.removedOrgStrainIds.splice(acc.removedOrgStrainIds.indexOf(orgStrain.id), 1);
        }
        return acc;
      },
      {
        selectedStrainIds: [...selectedStrainIds],
        selectedOrgStrainIds: [...selectedOrgStrainIds],
        removedOrgStrainIds: [...removedOrgStrainIds],
      }
    );
    this.setState(selectedIds);
  }

  search(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      searchString: event.target.value,
      activePage: 1
    });
  }

  searchAlphabetically(event, letter) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      searchLetter: letter === this.state.searchLetter ? '' : letter,
      searchString: '',
      activePage: 1
    });
  }

  switchPage(pageNumber) {
    this.setState({activePage: pageNumber});
  }

  showOrganizationStrainListing(){
    this.setState({showModal: true});
  }

  toggleSelect(strain) {
    const {selectedOrgStrainIds, selectedStrainIds, removedOrgStrainIds} = this.state;
    if (strain.id) {
      if (selectedStrainIds.indexOf(strain.id) > -1) {
        this.setState({
          selectedStrainIds: selectedStrainIds.filter(x => x !== strain.id),
        });
      } else {
        this.setState({
          selectedStrainIds: selectedStrainIds.concat(strain.id),
        });
      }
    } else {
      //Organization specific strains
      if (selectedOrgStrainIds.indexOf(strain.organization_strain_id) > -1) {
        this.setState({
          selectedOrgStrainIds: selectedOrgStrainIds.filter(x => x !== strain.organization_strain_id),
          removedOrgStrainIds: removedOrgStrainIds.concat(strain.organization_strain_id),
        });
      } else {
        if(strain.global_strain_id !== null || this.isFacilityLevel())
        {
          this.setState({
            selectedOrgStrainIds: selectedOrgStrainIds.concat(strain.organization_strain_id),
            removedOrgStrainIds: removedOrgStrainIds.filter(x => x !== strain.organization_strain_id),
          });
        }
      }
    }
  }

  hideActiveModal() {
    this.setState({showModal: false});
  }

  // Can pass the toggle in for cases where we switch to the filtered view ie. Select all visible strains
  selectStrains(strainIds, toggle = false) {
    const allStrainsSelected = toggle ? !this.state.allStrainsSelected : this.state.allStrainsSelected;
    const selectedStrainIds = allStrainsSelected ? strainIds : [];
    this.setState({allStrainsSelected, selectedStrainIds});
  }

  handleNewSubmit(formData) {
    const globalStrainIds = formData.organizationStrains.map(group => group.id).filter(Boolean);
    const newStrainNames = formData.organizationStrains.filter(group => typeof group === 'string');
    this.hideActiveModal();
    this.props.actions
      .postData(
        '/api/strains/organization_strains',
        {add_global_strain_ids: globalStrainIds, create_custom_strains: newStrainNames},
        dataNames.organizationStrains,
        {success:'strains.edit.success', failed: 'strains.edit.fail'},
        null,
        data => {
          this.props.actions.setData(data, dataNames.organizationStrains);
        }
      )
      .then(this.updateSelectedItems);
  }

  handleSubmit() {
    //When on the facility strain screen we will end up filtering only ones that need to be added and removed
    const newIds = this.isFacilityLevel() ? this.props.facilityStrains.map(item => item.id) : null;
    const postConfig = this.isFacilityLevel() ? {
      url: '/api/strains/facility_strains',
      payload: {ids: this.state.selectedStrainIds, prevIds: newIds, facility_screen: 1},
      dataName: dataNames.facilityStrains
    } : {
      url: '/api/strains/organization_strains',
      payload:  {global_strain_ids: this.state.selectedStrainIds, remove_custom_strain_ids: this.state.removedOrgStrainIds},
      dataName: dataNames.organizationStrains
    };
    const {url, payload, dataName} = postConfig;
    this.props.actions.postData(url,
      payload,
      dataName,
      {success:'strains.edit.success'},
      null,
      data => {
        this.props.actions.setData(data, dataName);
      }
    );
  }

  render() {
    const {selectedStrainIds, selectedOrgStrainIds, searchString, showModal, searchLetter, itemsPerPage, activePage, allStrainsSelected} = this.state;
    const {availableStrains} = this.props;
    const params = this.props.routeParams;
    const initialValues = {
      organizationStrains: [],
    };

    return (
      <div className='strains-page'>
        <h1>{I18n.t(`strains.${params.level}.strainsTitle`)}</h1>
        <InProgressOverlay
          isActive={this.state.inProgress}
          message={this.state.inProgressText}
        />
        {this.isFacilityLevel() ? null
        : (<Row><Col xs={4}>
          <Button className='float-right button-wrapper strain-create-btn button' variant='primary' onClick={this.showOrganizationStrainListing}>
            {I18n.t(`strains.${params.level}.createNew`)}
          </Button>
          </Col></Row>)}
        <ModalWrapper
          Component={OrganizationStrainListing}
          onHide={() => this.setState({showModal: false})}
          enableReinitialize={true}
          initialValues={initialValues}
          dialogClassName='newStrainModal'
          showModal={showModal}
          strains={availableStrains}
          onSubmit={this.handleNewSubmit}
          title={I18n.t(`strains.${params.level}.strainModalTitle`)}
        />
      <StrainsTable
          selectedStrainIds={selectedStrainIds}
          selectedOrgStrainIds={selectedOrgStrainIds}
          searchString={searchString}
          searchLetter={searchLetter}
          itemsPerPage={itemsPerPage}
          activePage={activePage}
          level={params.level}
          search={this.search}
          allStrainsSelected={allStrainsSelected}
          searchAlphabetically={this.searchAlphabetically}
          switchPage={this.switchPage}
          toggleSelect={this.toggleSelect}
          selectStrains={this.selectStrains}
          handleSubmit={this.handleSubmit}/>
      </div>
    );
  }
}

StrainsPage.propTypes = {
  actions: PropTypes.object.isRequired,
  routeParams: PropTypes.object.isRequired,
  facilityStrains: PropTypes.array,
  organizationStrains: PropTypes.array,
  availableStrains: PropTypes.array,
  allStrainsSelected: PropTypes.bool,
};

// StrainsPage.contextTypes = {
//   router: React.PropTypes.object.isRequired
// };

function mapStateToProps(state, ownProps) {
  const routeParams = ownProps.route.params;

  if (routeParams.level === 'facility') {
    return {
      routeParams,
      facilityStrains: getFacilityStrains(state),
    };
  }

  const organizationStrains = getOrderedOrganizationStrains(state);
  const availableStrains = getAvailableOrganizationStrains(state, ownProps);
  return {
    routeParams,
    organizationStrains,
    availableStrains,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({},
    apiActions,
    selectedDataActions,
    {setData,
    goBack,
    unsetData}
  );
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StrainsPage);
