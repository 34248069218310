import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {Col, Row} from 'react-bootstrap';
import get from 'lodash.get';
import omit from 'lodash.omit';
import find from 'lodash.find';
import filter from 'lodash.filter';
import map from 'lodash.map';
import {Field} from 'redux-form';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import AddItem from '../../common/form/AddItem';

class ProductsFieldArray extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      mjpProduct: {},
      metrcProduct: {},
    };

    this.setMJPProductFilter = this.setMJPProductFilter.bind(this);
    this.setMetrcProductFilter = this.setMetrcProductFilter.bind(this);
  }

  setMJPProductFilter(product, key) {
    if (product == 'link') {
      this.props.actions.openCreateProductPage();
    }
    this.setState({
      mjpProduct: {
        ...this.state.mjpProduct,
        [key]: product
      },
    });
  }

  setMetrcProductFilter(product, key) {
    this.setState({
      metrcProduct: {
        ...this.state.metrcProduct,
        [key]: product
      },
    });
  }

  getFilteredMetrcProducts(key) {
    const {integratorProducts, mjpProducts, metrcCategoryMappings} = this.props;
    const mjpProductId = get(this.state.mjpProduct, key, false);


    // ID MJP product
    if (mjpProductId && mjpProducts.length) {
      const mjpProduct = find(mjpProducts, {id: mjpProductId});
      const strainMjp = get(mjpProduct, 'strain_name');

      const mjpSubcategoryId = get(mjpProduct, 'subcategory_id');
      const mjpSubcategoryCode = get(metrcCategoryMappings.find(v => v.subcategory_id == mjpSubcategoryId), 'name');

      return filter(integratorProducts, (metrcProduct) => {
        return (strainMjp == metrcProduct.name_strain || metrcProduct.name_strain == null)
          && metrcProduct.product_category_name == mjpSubcategoryCode;
      });
    }
    return integratorProducts;
  }

  getFilteredMjpProducts(key) {
    const {integratorProducts, mjpProducts, metrcCategoryMappings} = this.props;
    const metrcProductId = get(this.state.metrcProduct, key, false);
    // ID METRC product
    if (metrcProductId && integratorProducts.length) {
      const metrcProduct = find(integratorProducts, {id: metrcProductId});
      const strainMetrc = get(metrcProduct, 'name_strain', null);

      const metrcSubcategoryCode = get(metrcProduct, 'product_category_name');
      const metrcSubcategoryIds = map(metrcCategoryMappings.filter(v => v.name == metrcSubcategoryCode), 'subcategory_id');

      return filter(mjpProducts, (product) => {
        return (metrcSubcategoryIds.includes(product.subcategory_id)
          && ((strainMetrc != null && (product.strain_name == strainMetrc || product.strain_name == '')) || strainMetrc == null))
          || product.id == 'link';
      });
    }
    return mjpProducts;
  }

  componentWillUpdate() {
    // touch all invalid fields to prompt error displays
    if (!this.props.valid && this.props.formErrors && !get(this.state, 'isTouchedProductsMapping', false)) {
      const productsMappingKeys = (this.props.formErrors.productsMapping || []).map((el, index) => {
        return Object.keys(el).map(key => `productsMapping.${index}.${key}`);
      }).flat();
      this.props.touch(...productsMappingKeys);
      this.setState({
        isTouchedProductsMapping: true
      });
    }
  }

  render() {
    const {fields: {map, remove, length, push}, change} = this.props;
    return (
      <div className='group packages'>
        {map((productName, productIndex) => {
          return (
            <Row key={productName}>
              <Col md={5}>
                <Field
                  name={`${productName}.product_id`}
                  component={ReactSelectInput}
                  props={{
                    options: this.getFilteredMjpProducts(productIndex),
                    textKey: 'name',
                    valueKey: 'id',
                    placeholder: I18n.t('common.form.selectPlaceholder'),
                    optionRenderer: (option) => (option.template) ? option.template(option) : option.name,
                    onChange: (product) => {
                      change(`${productName}.product_id`, product);
                      this.setMJPProductFilter(product, productIndex);
                    },
                  }}/>
              </Col>
              <Col md={5}>
                <Field
                  name={`${productName}.item_id`}
                  component={ReactSelectInput}
                  props={{
                    options: this.getFilteredMetrcProducts(productIndex),
                    textKey: 'name',
                    valueKey: 'id',
                    placeholder: I18n.t('common.form.selectPlaceholder'),
                    onChange: (product) => {
                      change(`${productName}.item_id`, product);
                      this.setMetrcProductFilter(product, productIndex);
                    },
                  }}/>
              </Col>
              <Col md={2}>
                <AddItem
                  alignMode='right'
                  length={length}
                  addAction={() => push({value: ''})}
                  removeAction={() => {
                    this.setState({
                      mjpProduct: omit(this.state.mjpProduct, productIndex),
                      metrcProduct: omit(this.state.metrcProduct, productIndex)
                    });
                    remove(productIndex);
                  }}
                  showPlus={length - 1 === productIndex}
                  classItem='add-item-without-padding'
                />
              </Col>
            </Row>
          );
        })}
      </div>
    );
  }
}

ProductsFieldArray.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func,
    remove: PropTypes.func,
    push: PropTypes.func,
    length: PropTypes.number,
  }),
  products: PropTypes.array,
  mjpProducts: PropTypes.array,
  integratorProducts: PropTypes.array,
  change: PropTypes.func,
};

export default connect(
  null,
  (dispatch) => ({
    actions: {
      openCreateProductPage() {
        // TODO Change it to work with default react-router lib;
        window.open('/products/create', '_blank');
      }
    }
  })
)(ProductsFieldArray);
