import React from 'react';
import PropTypes from 'prop-types';
//import {I18n} from 'react-redux-i18n';

const NewLabelDefinition = (props) => {

  const dataIsValid = () => {
    if(props.modalData.name === null) return false;
    if(props.modalData.name === undefined) return false;
    if(props.definitions.length > 1) {
      if (props.modalData.name.trim() === '') return false;
      if (props.modalData.based_on === null) return false;
      if (props.modalData.based_on === undefined) return false;
    } else {
      if(props.modalData.based_on === undefined){ // default it if only one
        // Note that this makes the save handler consistent but there's some race condition on setting checked
        // so the props.definitions.length test for checked is required
        props.onChangeModalData('based_on', 0);
      }
    }
    return true;
  };

  return (
    <div className='row' style={{marginTop: '12px'}}>
      <div className='col-md-12'>
        <div className='form-group'>
          <label>Name Your Definition</label>
          <input
            type='text'
            className='form-control form-control-sm'
            onChange={(event) => {
              props.onChangeModalData('name', event.target.value);
            }}
            value={props.modalData.name}
          />
        </div>
        <div className='form-group'>
          <label style={{width: '100%'}}>Based On</label>
          {
            props.definitions.map((def, index) => {
              return (
                <div key={index}>
                  <input
                    onChange={(event) => {
                      props.onChangeModalData('based_on', index);
                    }}
                    checked={props.modalData.based_on === index || props.definitions && props.definitions.length === 1}
                    style={{marginRight: '5px', width: '24px', height: '24px'}}
                    type='radio'
                    name='based_on'
                    value={index} />
                  <span style={{position: 'relative', top: '-6px'}}>{def.name}</span>
                </div>
              );
            })
          }
        </div>
        <div>
          <hr />
          <button
            onClick={(event) => {
              props.onHide();
            }}
            className='btn btn-danger'
          >
            Cancel
          </button>
          <button
            onClick={(event) => {
              props.onSaveModalData();
            }}
            className='btn btn-primary float-right'
            disabled={!dataIsValid()}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

NewLabelDefinition.propTypes = {
  definitions: PropTypes.array.isRequired,
  currentDefinitionIndex: PropTypes.number.isRequired,
  modalData: PropTypes.object.isRequired,
  onChangeModalData: PropTypes.func.isRequired,
  onSaveModalData: PropTypes.func.isRequired,
};

export default NewLabelDefinition;
