import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import { Col, Row, Button } from 'react-bootstrap';

import * as dataNames from '../../../../constants/dataNames';
import * as itemNames from '../../../../constants/itemNames';
import {unsetData} from '../../../../actions/dataActions';
import * as apiActions from '../../../../actions/apiActions';
import {getMappingFormInitialValues} from '../../../../selectors/supplyChainMappingSelectors';
import FormWrapper from '../../../common/form/FormWrapper';
import {getIntegrationState} from '../../../../selectors/integration/integrationSelectors';
import MetrcVendorProductMappingForm from './MetrcVendorProductMappingForm';


/*
* FUNCTION - INIT - SCOPE - NOTE
* function = () => {} style creates an automatic binding to the class it's defined inside otherwise manual binding is required
* I.E. {e => this.onItemUpdated(e)} or {this.onItemUpdated.bind(this)} or in the contructor this.onItemUpdated = this.onItemUpdated.bind(this)
*/
const MAPPED_ITEMS = 'mapped';
const UNMAPPED_ITEMS = 'unmapped';

class MetrcVendorProductMappingPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      tab: UNMAPPED_ITEMS,
      reload: false,
      items: [],
      productDefaults: [],
      pagination: {
        current_page: 1,
        from: null,
        last_page: 1,
        per_page: 20,
        to: null,
        total: 0
      },
    };
    this.onItemUpdated = this.onItemUpdated.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.changePage = this.changePage.bind(this);
  }

  // Event Hook for when an item is Updated (this will occur within the child item forms)
  onItemUpdated(item) {
    const {tab} = this.state;
    this.setState({reload: true});
    this.fetchMappings(tab).then((body) => {
      const data = body.response || [];
      if(data.length === 0 && tab === UNMAPPED_ITEMS)
        this.changeTab(MAPPED_ITEMS);
    });
  }

  componentWillMount() {
    const {tab} = this.state;
    this.props.actions.unsetData(itemNames.metrcVendorProductMappings);
    this.loadUnmapped().then((body) => {
      const data = body.response || [];
      if(data.length === 0 && tab === UNMAPPED_ITEMS)
        this.changeTab(MAPPED_ITEMS);
      return body;
    });
    this.fetchPartnerFacilities();
  }

  changeTab(name) {
    this.setState({tab: name});
    if(name === UNMAPPED_ITEMS)
      this.loadUnmapped({page: 1});
    else
      this.loadMapped({page: 1});
  }

  // In order to properly display the mapped items we need to include
  // a default listing of products which contain every mapped item master
  loadMapped(params = {}) {
    return this.fetchMappings(MAPPED_ITEMS, params).then((body) => {
      // when we retrieve the mapping data, we pull out all item_master_ids
      // and then retrieve the full dataset for each item_master from the backend
      const data = body.response || [];
      const ids = data.map((item, index) => {
        return item.item_master_id;
      });
      this.fetchProductDefaults(ids);
      return body;
    });
  }

  loadUnmapped(params = {}) {
    return this.fetchMappings(UNMAPPED_ITEMS, params).then((body) => {
      this.setState({defaultProducts: []});
      return body;
    });
  }

  changePage(new_page) {
    const { tab } = this.state;
    this.fetchMappings(tab, {page: new_page});
  }

  componentWillUnmount() {
    this.props.actions.unsetData(dataNames.metrcVendorProductMappings);
  }

  componentDidUpdate() {}

  fetchMappings(tab, params = {}) {
    const { pagination } = this.state;

    // setup the request parameters, the default page will be the current page
    // in the pagination state, but can be overwritten when a param object is
    // specified
    const request_params = Object.assign({page: pagination.current_page}, params);
    if(tab === UNMAPPED_ITEMS) {
      request_params.item_master_is_null = true;
    } else {
      request_params.item_master_is_not_null = true;
    }
    request_params.per_page = pagination.per_page;

    return this.props.actions.getPaginatedData(
      '/api/metrc/vendor_product_mappings',
      dataNames.metrcVendorProductMappings,
      {failed: 'Failed to get Vendor Product Mappings'}, request_params, null, null,
      {resolveRaw: true}
    ).then( (response) => {
      // extract the body of the response
      return response.data || {};
    }).then( (body) => {
      // set the pagination data returned into the state of the page
      if(body.pagination)
        this.setState({pagination: body.pagination});
      return body;
    });
  }

  // Method to submit backend request and retrieve the full
  // dataset for each item master, and set into the page state
  // which will be passed down to each form component as a default
  // item_master set so the dropdown doesn't show empty
  fetchProductDefaults(ids = []) {
    return this.props.actions.getDataByPost(
      '/api/item_masters/multiple',
      {ids: ids},
      'itemMasters',
      {failed: 'Cannot retrieve Item Masters'}
    ).then( (data) => {
      this.setState({productDefaults: data});
    });
  }

  fetchPartnerFacilities() {
    return this.props.actions.getUnpaginatedData(
      '/api/partner_facilities',
      'partnerFacilities',
      {failed: 'Cannot retrieve Partner Facilities'}
    );
  }

  render() {
    const { partnerFacilities} = this.props;
    const { tab, pagination, productDefaults } = this.state;
    const products = (tab === UNMAPPED_ITEMS) ? [] : productDefaults;
    return (
      <div>
        <ul className='nav nav-tabs' style={{marginBottom: '10px'}}>
          <li
            className={tab === MAPPED_ITEMS ? 'active' : ''}
            onClick={e => { this.changeTab(MAPPED_ITEMS); }}>
            <a href='#'>Mapped</a>
          </li>
          <li
            className={tab === UNMAPPED_ITEMS ? 'active' : ''}
            onClick={e => { this.changeTab(UNMAPPED_ITEMS); }}>
            <a href='#'>Unmapped</a>
          </li>
        </ul>
        <div className='tab-content'>
          <FormWrapper title={I18n.t('cultivation.supplyChain.metrcVendorProductMapping')} titleDescription={I18n.t('cultivation.supplyChain.forms.mapMetrcProducts.titleDescription')}>
            <div style={{marginBottom: '5px'}}>
              <Row className='hidden-xs hidden-sm'>
                <Col md={2}><strong>{I18n.t('cultivation.supplyChain.forms.mapMetrcProducts.fields.metrc_partner_license')}</strong></Col>
                <Col md={2}><strong>{I18n.t('cultivation.supplyChain.forms.mapMetrcProducts.fields.metrc_product_name')}</strong></Col>
                <Col md={1}><strong>{I18n.t('cultivation.supplyChain.forms.mapMetrcProducts.fields.metrc_product_uom')}</strong></Col>
                <Col md={2}><strong>{I18n.t('cultivation.supplyChain.forms.mapMetrcProducts.fields.metrc_product_strain')}</strong></Col>
                <Col md={2}><strong>{I18n.t('cultivation.supplyChain.forms.mapMetrcProducts.fields.partner_facility_id')}</strong></Col>
                <Col md={2}><strong>{I18n.t('cultivation.supplyChain.forms.mapMetrcProducts.fields.item_master_id')}</strong></Col>
                <Col md={1} />
              </Row>
            </div>

            {/* Ensure product defaults have been retrieved or showing unmapped entries */}
            {(productDefaults.length > 0 || tab === UNMAPPED_ITEMS) && partnerFacilities &&
              this.props.mappings.map((item, i) => {
                return <div key={item.id} style={{marginBottom: '5px'}}><MetrcVendorProductMappingForm onUpdate={this.onItemUpdated} item={item} productDefaults={products} partnerFacilities={partnerFacilities} /></div>;
              })
            }

            <Row>
              {pagination.total > 0 &&
                <Col md={6}>
                  {pagination.current_page > 1 &&
                    <Button variant='primary' onClick={(e) => this.changePage(pagination.current_page - 1)}>Prev</Button>
                  }
                  &nbsp;
                  <Button disabled={true}>Viewing {pagination.from}-{pagination.to} of {pagination.total}</Button>
                  &nbsp;
                  {pagination.last_page && pagination.current_page < pagination.last_page &&
                    <Button variant='primary' onClick={(e) => this.changePage(pagination.current_page + 1)}>Next</Button>
                  }
                </Col>
              }
            </Row>
          </FormWrapper>
        </div>
      </div>
    );
  }
}

MetrcVendorProductMappingPage.propTypes = {};

function mapStateToProps(state) {
  return {
    initialProducts: null,
    mappings: state.metrcVendorProductMappings,
    partnerFacilities: state.partnerFacilities,
    integrationState: getIntegrationState(state),
    initialValues: getMappingFormInitialValues(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {unsetData});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MetrcVendorProductMappingPage);
