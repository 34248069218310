import {I18n} from 'react-redux-i18n';
import isEmpty from 'lodash.isempty';
import moment from 'moment';
import {formatClientDateTime, isMoment} from '../../../../util/dateHelpers';
import {
  PACKAGE_CODE_REG_EXP,
  LOT_NUMBER_REG_EXP,
  URL_REG_EXP,
  ALPHA_NUMERIC_REG_EXP,
  PHONE_NUMBER_REG_EXP, EMAIL_REG_EXP
} from '../validationRegExs';
import * as consumerTypes from '../../../../constants/consumerTypes';

const getFieldName = (label) => {
  return label ? I18n.t(label) : 'This field';
};

export const requiredNonEmptyFieldValidation = (value, label) => {
  return (value || value === 0) ? undefined : I18n.t('common.form.isRequired', {fieldName: getFieldName(label)});
};

export const requiredFieldValidation = (value, label) => {
  return value ? undefined : I18n.t('common.form.isRequired', {fieldName: getFieldName(label)});
};

export const notEmptyArrayValidation = (value, label) => {
  return Array.isArray(value) && value.length === 0 ? I18n.t('common.form.isRequired', {fieldName: getFieldName(label)}) : undefined;
};

export const urlValidation = (value, label) => {
  if (value && value.match(URL_REG_EXP)) {
    return undefined;
  }
  else {
    return I18n.t('common.form.urlError', {fieldName: getFieldName(label)});
  }
};

export const lotNumberValidation = (value, label, isRequired = true) => {
  return isRequired
    ? (value && value.match(LOT_NUMBER_REG_EXP)) ? undefined : I18n.t('common.form.lotNumberError', {fieldName: getFieldName(label)})
    : !value || (value && value.match(LOT_NUMBER_REG_EXP)) ? undefined : I18n.t('common.form.lotNumberError', {fieldName: getFieldName(label)});
};

export const packageCodeValidation = (value, label) => {
  return value && !value.match(PACKAGE_CODE_REG_EXP) ? I18n.t('common.form.packageCodeError', {fieldName: getFieldName(label)}) : undefined;
};

/*
 * A Tracking ID must contain at least 24 characters.
 * All characters must be alphanumeric.
 */
export const metrcTrackingIdValidation = (value, label = 'common.form.trackingId') => {
  return !value || (
    typeof value === 'string' && value.match(/^[A-Za-z0-9]{0,24}$/)
  ) ? undefined : I18n.t('common.form.metrcTrackingIdError', {fieldName: getFieldName(label)});
};

// A production run must have 24 or fewer characters.
// All characters must be alphanumeric or ".".
// The value is not required. The back end should automatically set a value if it is null.
export const productionRunValidation = (value, label) => {
  return !value || value === '' || (value && value.match(/^[A-Za-z0-9\.]{0,24}$/))
    ? undefined
    : I18n.t('common.form.productionRunError', {fieldName: getFieldName(label)});
};

export const betweenValidation = (value, label, start, end) => {
  return value >= start && value <= end ? undefined : I18n.t('common.form.isBetween', {start, end, fieldName: getFieldName(label)});
};

export const minLengthValidation = (value, label, min) => {
  return value && value.length >= min ? undefined : I18n.t(
    `common.form.minLengthValidation`,
    {min, fieldName: getFieldName(label)}
  );
};

export const maxLengthValidation = (value, label, max) => {
  return value && value.length <= max ? undefined : I18n.t(
    `common.form.maxLengthValidation`,
    {max, fieldName: getFieldName(label)}
  );
};

export const minValidation = (value, label, min, strict) => {
  return (strict && value > min) || (!strict && value >= min) ? undefined :  I18n.t(
    `common.form.min${strict ? 'Strict' : ''}`,
    {min, fieldName: getFieldName(label)}
    );
};


export const maxValidation = (value, label, max, strict) => {
  return (strict && value < max) || (!strict && value <= max) ? undefined : I18n.t(
    `common.form.max${strict ? 'Strict' : ''}`,
    {max, fieldName: getFieldName(label)}
  );
};

export const dateFieldValidation = value => !value || moment(value, 'MM/DD/YYYY', true).isValid() ? undefined : I18n.t('common.form.wrongDateFormat');
export const dateTimeValidation = value => !value || moment(value, 'MM/DD/YYYY h:mm A', true).isValid() ? undefined : I18n.t('common.form.wrongDateTimeFormat');
export const isDate = (value) => {
  const isMomentObject = isMoment(value);
  const isValidDate = moment(value, 'MM/DD/YYYY', true).isValid();
  return isMomentObject || isValidDate;
};

export const integerFieldValidation = value => Number.isInteger(value);
export const validNumericFieldValidation = value => (!isNaN(parseFloat(value)));

export const isInteger = (value, failedMessage = false, translate = false) => {
  value = value === '' ? '' : +value;
  const result = integerFieldValidation(value);
  return (!failedMessage)
    ? result // No message return boolean
    : result
      ? undefined // Undefined because if getting message assumed in validation and undefined does not trigger errors
      : translate ? I18n.t(failedMessage) : failedMessage;
};

export const isPositiveInteger = (value, failedMessage = false, translate = false) => {
  value = value === '' ? '' : +value;
  const result = Number.isInteger(value) && parseInt(value) >= 0;
  return (!failedMessage)
    ? result // No message return boolean
    : result
      ? undefined // Undefined because if getting message assumed in validation and undefined does not trigger errors
      : translate ? I18n.t(failedMessage) : failedMessage;
};

export const isFloat = (value, failedMessage = false, translate = false) => {
  const result = validNumericFieldValidation(value);
  return (!failedMessage)
    ? result // No message return boolean
    : result
      ? undefined // Undefined because if getting message assumed in validation and undefined does not trigger errors
      : translate ? I18n.t(failedMessage) : failedMessage;
};

export const minDateTimeValidation = (value, label, min, strict) => {
  const valueMoment = moment(value);
  const minMoment = moment(min);
  if (valueMoment.isValid() && minMoment.isValid() && minMoment[strict ? 'isSameOrAfter' : 'isAfter'](valueMoment, 'minute')) {
    return I18n.t(
      `common.form.min${strict ? 'Strict' : ''}`,
      {min: formatClientDateTime(minMoment), fieldName: getFieldName(label)}
    );
  }
};

export const alphaNumericValidation = (value) => {
  return value && value.match(ALPHA_NUMERIC_REG_EXP);
};

/***
 * This range is determined by the db columns on the BE and is already validated there.  These are used for any
 * transation that ultimately will be recorded as a register transaction.
 * @param value
 * @param errorRangeType = full, lower, upper
 * @returns {undefined}
 */
export const registerTransactionIsInRange = (value, errorRangeType = 'full') => {
  const v = parseFloat(value);
  const min = -1000000, max = 1000000;
  return !isNaN(v)
    ? v < min || v > max
      ? I18n.t(`registers.rangeErrors.${errorRangeType}`, {min: min.toFixed(2), max: max.toFixed(2)})
      : undefined
    : undefined;
};

/***
 * POS range is arbitrarily smaller than register transactions as of 07/16/2018
 * @param value
 * @param errorRangeType = full, lower, upper
 * @returns {undefined}
 */
export const posTransactionIsInRange = (value, errorRangeType = 'full') => {
  const v = parseFloat(value);
  const min = -100000, max = 100000;
  return !isNaN(v)
    ? v < min || v > max
      ? I18n.t(`registers.rangeErrors.${errorRangeType}`, {min: min.toFixed(2), max: max.toFixed(2)})
      : undefined
    : undefined;
};

/**
 * Checking validation rules for a Date of Birth field
 * @param date
 * @param customerType
 * @param ageLimit
 * @returns {*}
 */
export const checkDateOfBirthValidation = (date, customerType, ageLimit) => {
  const now = moment();
  const MAX_PATIENT_AGE = 200;

  if (date > now) {
    return I18n.t('customers.create.futureDateOfBirth');
  }

  if (moment().diff(date, 'years') >= MAX_PATIENT_AGE) {
    return I18n.t('customers.create.pastDateOfBirth');
  }

  if (customerType === consumerTypes.RECREATIONAL && ageLimit > now.diff(date, 'years')) {
    return I18n.t('customers.create.recreationalAgeTwentyOne').replace('{AGE}', ageLimit);
  }

  if (typeof date === 'string') {
    return I18n.t('customers.create.dateHasWrongFormat');
  }

  return null;
};

/**
 * Validation rule which is checking is the incoming value should be require
 * Most appropriate using in Field-level validation:
 * @example:
 * Field
 *  name='email'
 *  validate={[required]}
 * />
 *
 * @param value
 * @param label
 * @returns {*}
 */
export const required = (value, label = undefined) => {
  return requiredFieldValidation(isEmpty(value) ? undefined :  value, label);
};

/**
 * Validation rule which is checking is the incoming value matches with phone format.
 * Most appropriate using in Field-level validation:
 * @example:
 * <ReduxPhoneField
 *   validate={[matchPhoneNumber, required]}
 * />
 *
 * @param value
 * @returns {*}
 */
export const matchPhoneNumber = (value) => {
  if (value && !PHONE_NUMBER_REG_EXP.test(value)) {
    return I18n.t('common.form.invalidPhone');
  }
};

/**
 * Validation rule which is checking is the incoming value matches with email format.
 * Most appropriate using in Field-level validation:
 * @example:
 * Field
 *  name='email'
 *  validate={[matchEmail, required]}
 * />
 *
 * @param value
 * @returns {*}
 */
export const matchEmail = (value) => {
  if (value && !EMAIL_REG_EXP.test(value)) {
    return I18n.t('common.form.invalidEmail');
  }
};

/**
 *
 * @param value
 * @param exceeded
 * @param message
 * @returns {*}
 */
export const maxBetween = (value, exceeded, message = null) => {
  if (parseFloat(value) > parseFloat(exceeded)) {
    return message ? message : I18n.t('common.form.exceededBy', { value, exceeded });
  }
};
