import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash.get';
import { I18n } from 'react-redux-i18n';
import { Row, Col } from 'react-bootstrap';
import { detect } from 'detect-browser';

import Spinner from '../../common/Spinner';
import { getHealthCheck } from '../../../actions/systemActions';
import { postLogin, unsetUser, clearLoginMessage } from '../../../actions/userActions';
import { getItem } from '../../../actions/apiActions';
import LoginForm from './LoginForm';
import LoginHeader from './LoginHeader';
import LoginFooter from './LoginFooter';
import BrowserData from './BrowserData';
import ContentConcealer from '../../common/concealers/ContentConcealer';

const browser = detect();
const ssoLoginRoute = (prerelease) => {
  return (prerelease ? '/api/auth/saml2/utah_prerelease/login' : '/api/auth/saml2/utah/login');
};

export class LoginPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    let title = '';
    let titleAddOnClass = '';

    if (
      !get(props, 'user.loggingOut', false) &&
      !window.location.search.includes('error') &&
      window.location.hostname.includes('leafdatasystems') && this.props.ssoEnabled
    ) {
      title = I18n.t('nav.titleLeafLoading');
      titleAddOnClass = 'disabled';
    } else {
      title = I18n.t('nav.titleLeaf');
    }

    if (this.props.ssoEnabled) {

      // If we have not just logged out and we are not on the login/token, we redirect to Leaf SSO automatically based on the hostname
      if (
        !get(props, 'user.loggingOut', false) &&
        window.location.hostname.includes('leafdatasystems') &&
        window.location.pathname.split('/').length < 3 &&
        !window.location.search.includes('error')
      ) {
        window.location.replace(ssoLoginRoute(window.location.hostname.includes('pre')));
      }

      // log out of Utah SSO
      if (window.location.hostname.includes('leafdatasystems') &&
        window.location.search.includes('error')) {
        const position = screen.height - 100;
        const params = `width=200,height=100,top=${position}`;
        const openedWindow = window.open(this.props.utahLogoutUrl, 'Logout', params);
        if (openedWindow) {
          setTimeout(() => {
            openedWindow.close();
          }, 5000);
        }
      }
    }

    this.state = {
      user: {
        name: '',
        password: ''
      },
      title,
      titleAddOnClass,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount() {
    this.props.actions.unsetUser();
    this.props.actions.getHealthCheck();
  }

  onChange(event) {
    const { id, value } = event.target;
    const user = Object.assign({}, this.state.user, { [id]: value });
    this.setState({ user });
  }

  onSubmit(event) {
    event.preventDefault();
    const { user } = this.state;
    this.props.actions
      .postLogin(user)
      .then(() => {})
      .catch(() => {}); // To see if it impacts UnhandledRejection in bugsnag.
  }

  render() {
    const { errors } = this.props.user;
    const { user } = this.state;
    const { spinner } = this.props;
    const active = spinner > 0;



    return (
      <Row>
        <Col md={{ span: 6, offset: 3}} xs={{span: 8, offset: 2}}>
          <LoginHeader />
          {window.location.hostname.includes('leafdatasystems') && this.props.ssoEnabled ? (
            <div>
              <Row className='login-form'>
                <a className={`btn btn-primary btn-block ${this.state.titleAddOnClass}`} href={ssoLoginRoute(window.location.hostname.includes('pre'))} >
                  {this.state.title}
                </a>
              </Row>

              <ContentConcealer show={window.location.search.includes('error')}>
                <div className='danger' style={{ margin: '5px' }}>
                  {I18n.t('nav.errorAccess')}
                </div>
              </ContentConcealer>
            </div>
          ) : (
            <Row className='login-form'>
              <LoginForm
                user={user}
                onChange={this.onChange}
                onSubmit={this.onSubmit}
                onDismiss={this.props.actions.clearLoginMessage}
                errors={errors}
                touched={active}
              />
            </Row>
          )}
          <Spinner active={active} />

          {window.location.hostname.includes('leafdatasystems') && this.props.ssoEnabled  ? null : <LoginFooter />}
          <div style={{ height: '24px' }} />
          <BrowserData browser={browser} />
        </Col>
      </Row>
    );
  }
}

LoginPage.propTypes = {
  actions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  spinner: PropTypes.number,
  ssoEnabled: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    spinner: state.spinner,
    user: state.user,
    utahLogoutUrl: state.env.utahLogoutUrl,
    ssoEnabled: state.env.ssoEnabled,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ postLogin, unsetUser, clearLoginMessage, getItem, getHealthCheck }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage);
