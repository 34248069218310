import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import {
  Button,
  ButtonToolbar,
  Col,
  Row
} from 'react-bootstrap';
import TextInput from '../../common/form/TextInput';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import SelectInput from '../../common/form/SelectInput';
import TextAreaInput from '../../common/form/TextAreaInput';
import InternationalCurrencyInput from '../../common/form/InternationalCurrencyInput';


export const TransferCashForm = ({handleSubmit, submitting, pristine, registers, reasons, invalid, showModal}) => (
  <form onSubmit={handleSubmit}>
    <Row>
      <Col xs={4} sm={3} md={2}>
        <Field name='register_name' component={TextInput}
               props={{
                 label: I18n.t('registers.transferCash.registerName'),
                 disabled: true
               }}/>
      </Col>
      <Col xs={4} sm={3} md={2}>
        <Field name='transfer_to' component={ReactSelectInput}
               props={{
                 label: I18n.t('registers.transferCash.transferTo'),
                 options: registers,
                 textKey: 'name',
                 valueKey: 'id',
               }}/>
      </Col>
    </Row>
    <Row>
      <Col offset={2} smOffset={3} xs={4} sm={3} md={2}>
        <InternationalCurrencyInput
          name='transacted_amount'
          props={{
            label: I18n.t('registers.transferCash.amount'),
            fractionPartSize: 2,
            allowNegativeNumber: false,
            step: 0.01
          }}/>
      </Col>
      <Col xs={4} sm={3} md={2}>
        <Field
          name='reason_code' component={SelectInput}
          props={{
            label: I18n.t('registers.transferCash.reason'),
            placeholder: I18n.t('common.form.selectPlaceholder'),
            options: reasons,
            textKey: 'text',
            valueKey: 'value',
          }}/>
      </Col>
      <Col xs={4} sm={3} md={2}>
        <InternationalCurrencyInput
          name='amount_remain'
          props={{
            label: I18n.t('registers.transferCash.amountRemain'),
            fractionPartSize: 2,
            allowNegativeNumber: true,
            disabled: true
          }}/>
      </Col>
    </Row>
    <Row>
      <Col offset={2} smOffset={3} xs={8} sm={6} md={4}>
        <Field  name='notes' component={TextAreaInput} props={{
          label: I18n.t('registers.transferCash.notes'),
          rows: 2,
        }} />
      </Col>
    </Row>
    <Row className='padding-top'>
      <Col xs={12}>
        <ButtonToolbar className='float-right'>
          <Button variant='default'
                  type='button'
                  onClick={showModal}
                  disabled={submitting || pristine}>
            {I18n.t('common.actions.cancel')}
          </Button>
          <Button variant='primary'
                  type='submit'
                  disabled={submitting || pristine || invalid}>
            {I18n.t('common.actions.save')}
          </Button>
        </ButtonToolbar>
      </Col>
    </Row>
  </form>
);

TransferCashForm.propTypes = {
  registers: PropTypes.array.isRequired,
  reasons: PropTypes.array.isRequired,
  showModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired
};

export default TransferCashForm;
