/* eslint-disable import/no-named-as-default*/
import sortBy from 'lodash.sortby';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {getFormValues, reset} from 'redux-form';
import { bindActionCreators } from 'redux';
import {goBack} from 'react-router-redux';
import * as apiActions  from '../../../actions/apiActions';
import * as dataNames from '../../../constants/dataNames';
import * as UOMS from '../../../constants/uoms';
import {FEEDING_SCHEDULE_FORM} from '../../../constants/forms';
import FormWrapper from '../../common/form/FormWrapper';
import FeedingScheduleFormWrapper from '../common/FeedingScheduleFormWrapper';
import {getIngredientsFofFeedingSchedules} from '../../../selectors/ingredientsSelectors';
import {getStagesSortedByPhase} from '../../../selectors/stagesSelectors';

export class  CreateFeedingScheduleFormPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount(){
    this.props.actions.getUnpaginatedData('/api/cultivation/sections', dataNames.sections);
    this.props.actions.getUnpaginatedData('/api/stages', dataNames.stages);
    this.props.actions.getUnpaginatedData('/api/ingredient_items/by_facility', dataNames.ingredients);
  }

  onSubmit(formData, dispatch) {
    this.props.actions.postData('/api/schedules',
      formData,
      dataNames.feedingSchedules,
      {success:'feedingSchedules.createFeedingSchedule.success', failed: 'feedingSchedules.createFeedingSchedule.failed'},
      null,
      () => {
        if (formData.redirect) {
          this.props.actions.goBack();
        } else {
          this.props.actions.reset('FeedingScheduleForm');
        }
      }
    );
  }

  render() {
    const {initialValues, sections, stages, ingredients, formValues} = this.props;

    return (
      <FormWrapper title={'feedingSchedules.create.title'} goBack={this.props.actions.goBack}>
        <FeedingScheduleFormWrapper
          initialValues={initialValues}
          locations={sections}
          enableReinitialize={true}
          stages={stages || []}
          ingredientsList={ingredients || []}
          formValues={formValues}
          onSubmit={this.onSubmit}
          />
      </FormWrapper>);
  }
}

CreateFeedingScheduleFormPage.propTypes = {
  initialValues: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  sections: PropTypes.array.isRequired,
  stages: PropTypes.array.isRequired,
  ingredients: PropTypes.array.isRequired,
  formValues: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps){
  const {sections} = state;
  const initialValues = {
    ingredients : [],
    rooms:[],
    stages: [],
    last_applied_cost_per_liter: '0.00',
    calculated_cost: '0.00',
    is_repeating: true,
    amount_uom: UOMS.L,
  };
  const formValues = getFormValues(FEEDING_SCHEDULE_FORM)(state) || initialValues;

  return {
    sections: sortBy(sections, ['name', 'asc']),
    stages: getStagesSortedByPhase(state).map(stage => ({...stage, stage_id: stage.id})),
    ingredients: getIngredientsFofFeedingSchedules(state),
    initialValues,
    formValues,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({},
    apiActions,
    {goBack, reset}
  );
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps) ( CreateFeedingScheduleFormPage);
