/* eslint-disable import/no-named-as-default*/
import {reduxForm} from 'redux-form';
import {I18n} from 'react-redux-i18n';

import CreateRegisterForm from './CreateRegisterForm';
import {REGISTER_FORM} from '../../constants/forms';

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = I18n.t('registers.form.enterName');
  }
  if (!values.type) {
    errors.type = I18n.t('registers.form.selectType');
  }
  if (!values.active) {
    errors.active = I18n.t('registers.form.selectStatus');
  }
  return errors;
};

const ReduxCreateRegisterForm = reduxForm({
  form: REGISTER_FORM,
  validate,
})(CreateRegisterForm);

export default ReduxCreateRegisterForm;