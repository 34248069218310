import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Row, Button} from 'react-bootstrap';
import {FaTrash} from 'react-icons/fa';
import {FieldArray} from 'redux-form';
import InternationalQuantityByUomInput from '../../../common/form/InternationalQuantityByUomInput';
import InternationalCurrencyInput from '../../../common/form/InternationalCurrencyInput';
import InternationalCurrencyStatic from '../../../common/form/InternationalCurrencyStatic';
import InternationalDecimalStatic from '../../../common/form/InternationalDecimalStatic';
import PrePackSubItems from './PrePackSubItems';

class PrePackLineItem extends React.PureComponent {

  constructor(props, context) {
    super(props, context);

    this.state = {
    };

  }

  render() {
    const {
      line, item, change, showIdColumn, isActive, lineItem, transferRecord,
      removeFromPo, index, uom, label, metrcIsImported
    } = this.props;
    const { abbrev } = I18n.t(`uoms.${uom}`);
    if (!lineItem.showEditors && lineItem.showEditors !== undefined){

      return (
        <Col lg={8}>
          <Row>
            <Col lg={2}>
              <label>{I18n.t('purchaseOrders.form.requestedAmount')}</label>
              <InternationalDecimalStatic>{lineItem.qty}</InternationalDecimalStatic>
            </Col>
            <Col lg={2}>
              <label>{I18n.t('purchaseOrders.form.pricePer', {label})}</label>
              <InternationalCurrencyStatic>{lineItem.unit_price}</InternationalCurrencyStatic>
            </Col>
            <Col lg={3} offset={4}>
              <label>{I18n.t('purchaseOrders.form.itemPrice')}</label>
              <InternationalCurrencyStatic>{lineItem.line_item_price}</InternationalCurrencyStatic>
            </Col>
          </Row>
          <FieldArray
            name={`${line}.subitems`}
            component={PrePackSubItems}
            subItems={item.subitems || []}
            showIdColumn={showIdColumn}
            isActive={isActive}
            change={change}
            setSubItemChange={this.setSubItemChange}
            lineItem={lineItem}
            metrcIsImported={metrcIsImported}
          />
        </Col>);
    }


    return (
      <Col lg={8}>
        <Row>
          <Col lg={3}>
            <InternationalQuantityByUomInput
              name={`${line}.qty`}
              uom={uom}
              fractionPartSize={2}
              label={I18n.t('purchaseOrders.form.requestedAmount')}
              readOnly={true}
              isRequired={true}
            />
          </Col>
          <Col lg={3}>
            <InternationalCurrencyInput name={`${line}.unit_price`}
                                        props={{
                                          label: (lineItem && lineItem.editableLinePrice !== undefined && lineItem.editableLinePrice)
                                            ? I18n.t('purchaseOrders.form.approxPricePerGram')
                                            : I18n.t('purchaseOrders.form.pricePer', {label: abbrev }),
                                          disabled:(
                                            !isActive
                                              ? true
                                              : lineItem && lineItem.editableLinePrice !== undefined
                                                ? lineItem.editableLinePrice
                                                : false
                                          ),
                                          isRequired: true
                                        }}
            />
          </Col>
          <Col lg={3}>
            <InternationalCurrencyInput
              name={`${line}.line_item_price`}
              props={{
                label: I18n.t('purchaseOrders.form.itemPrice'),
                disabled: (lineItem && lineItem.editableLinePrice !== undefined ? !lineItem.editableLinePrice : true),
                rightAddon: !isActive
                  ? null
                  : <Button
                      style={{cursor: 'pointer'}}
                      onClick={(event) => {
                        change(`${line}.editableLinePrice`, lineItem.editableLinePrice !== undefined ? !lineItem.editableLinePrice : true);
                      }}
                    >{lineItem && lineItem.editableLinePrice !== undefined && lineItem.editableLinePrice ? 'Calc' : 'Edit'}</Button>
              }}
            />
          </Col>
          <Col lg={1}>
            <div className='form-input form-group'>
              <label className='control-label' style={{width: '100%'}}>&nbsp;</label>
              <button disabled={transferRecord} className='btn btn-small btn-block' onClick={(event) => removeFromPo(index)}>
                <FaTrash style={{marginRight: '4px'}} />
              </button>
            </div>
          </Col>
        </Row>
        <FieldArray
          name={`${line}.subitems`}
          component={PrePackSubItems}
          subItems={item.subitems || []}
          showIdColumn={showIdColumn}
          isActive={isActive}
          change={change}
          setSubItemChange={this.setSubItemChange}
          lineItem={lineItem}
          metrcIsImported={metrcIsImported}
          uom={uom}
        />
      </Col>);

  }
}

PrePackLineItem.propTypes = {
  line: PropTypes.string.isRequired,
  uom: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  showIdColumn: PropTypes.bool,
  isActive: PropTypes.bool,
  lineItem: PropTypes.object.isRequired,
  transferRecord: PropTypes.bool.isRequired,
  removeFromPo: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  metrcIsImported: PropTypes.bool,
};

PrePackLineItem.defaultProps = {
  showIdColumn: true,
  isActive: true
};
export default PrePackLineItem;
