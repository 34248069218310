import React from 'react';
import PropTypes from 'prop-types';
import {FaRegClock} from 'react-icons/fa';
import DateTimeField from 'react-datetime';
import ReduxBootstrapInput from './ReduxBootstrapInput';

const TimeInput = ({ label, size, input, meta, children, isRequired, rightAddon, ...props }) => {
  return (
    <ReduxBootstrapInput Control={DateTimeField}
                         label={label} size={size}
                         input={input} meta={meta}
                         name={input.name} isRequired={isRequired}
                         dateFormat={false} viewMode='time'
                         onFocus={() => {}} rightAddon={rightAddon || (<FaRegClock/>)}
      {...props} >
      {children}
    </ReduxBootstrapInput>
  );
};

TimeInput.propTypes = {
  label: PropTypes.string,
  size: PropTypes.oneOf(['lg', 'sm', 'large', 'small']),
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  children: PropTypes.node,
  rightAddon: PropTypes.node,
  isRequired: PropTypes.bool
};

export default TimeInput;
