/* eslint-disable indent */
// Disabled for switch statement that never passes

import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Modal, Button, Row, Col} from 'react-bootstrap';
import {getQzTray, signQzRequest} from '../../../actions/printerActions';
import * as apiActions from '../../../actions/apiActions';
import {setItem} from '../../../actions/itemActions';
import {setData} from '../../../actions/dataActions';
import * as itemNames from '../../../constants/itemNames';
import {LABEL_TYPES} from '../../../constants/labelTypes'; //eslint-disable-line import/prefer-default-export
import {getPrintServers} from '../../../selectors/labelsSelectors';
import PrinterList from './components/PrinterList';
import QzError from './components/QzError';
import LoadingBar from '../LoadingBar';


export class SetPrinterModal extends React.PureComponent{

  constructor (props, context){

    super(props, context);

    this.onEnter = this.onEnter.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onExit = this.onExit.bind(this);

    this.onTogglePreferencesHelp = this.onTogglePreferencesHelp.bind(this);
    this.preventDefault = this.preventDefault.bind(this);
    this.onToggleSelected = this.onToggleSelected.bind(this);
    this.onChangeDpi = this.onChangeDpi.bind(this);
    this.onChangeFontSize = this.onChangeFontSize.bind(this);
    this.onChangeField = this.onChangeField.bind(this);
    this.removePrinter = this.removePrinter.bind(this);
    this.onSaveForMachine = this.onSaveForMachine.bind(this);
    this.onSaveForSession = this.onSaveForSession.bind(this);
    this.onToggleSetting = this.onToggleSetting.bind(this);

    this.getQzPrinters = this.getQzPrinters.bind(this);

    this.state = {
      show: false,
      showPreferencesHelp: false,
      machinePrinters: [],
      printersChanged: false,
      qzLoaded: false,
      connecting: false,
      showError: false,
      title: '',
      message: '',
      secondsUntilQzIsInvalid: 10,
      savedMessage: '',
      savedMessageTimeout: 5, // seconds
      importantMessage: ''
    };

  }

  componentWillMount(){

    // This is a recent change from the 1.1.5 branch supporting changes to labels in general; commented out here with zero impact and will address there.
    // this.props.actions.getItem('/api/labels/compliance_settings', itemNames.complianceSettings);

    if(Object.keys(this.props.qzTray).length > 0) this.getPrintersForMachine();
  }

  componentDidUpdate(prevProps){
    if(Object.keys(this.props.qzTray).length > 0 && Object.keys(prevProps.qzTray).length === 0){
      this.getQzPrinters();
    }
  }

  getPrintersForMachine(){

    //const allSessionPrinters = sessionStorage.getItem('printerSettings');
    const allPrinters = localStorage.getItem('printerSettings');

    //let sessionPrinters = null;
    let printers = null;
    let parsedPrinters = null;

    const newState = {};
    const labelKey = this.props.setForLabel;

    if(allPrinters !== null){
      parsedPrinters = JSON.parse(allPrinters);
      if(parsedPrinters[labelKey] !== undefined) printers = parsedPrinters[labelKey];
    }

    if(printers !== null) newState.machinePrinters = printers;

    this.setState(newState);

  }

  selectedPrinterIsAvailable(){

    // Do we have a selected printer?
    let haveSelectedPrinter = this.state.machinePrinters.reduce( (acc, printer) => {
      if(acc > 0) return acc;
      if(printer.selected){
        acc++;
      }
      return acc;
    }, 0);

    if(!haveSelectedPrinter) this.setState({importantMessage: I18n.t('common.noSelectedPrinter')});

    if(haveSelectedPrinter) {

      // Is that printer visible to Qz Tray?
      haveSelectedPrinter = this.state.machinePrinters.reduce((acc, printer) => {
        if (!acc) return acc;
        if (printer.selected && !printer.status) return false;
        return acc;
      }, true);

      if(!haveSelectedPrinter) this.setState({importantMessage: I18n.t('common.selectedPrinterNotFoundByQzTray')});

    }

    return haveSelectedPrinter;

  }

  getFromLocalStorage(key, isSession){
    const printers = (isSession) ? sessionStorage.getItem(key) : localStorage.getItem(key);
    if(printers === null) return {};
    return JSON.parse(printers);
  }

  saveToLocalStorage(key, isSession){

    const savedPrinters = this.getFromLocalStorage(key, isSession);
    const printers = this.state.machinePrinters;

    savedPrinters[this.props.setForLabel] = printers;

    const selectedPrinter = printers.reduce((acc, p) => {
      if(acc) return acc;
      if(p.selected) acc = p;
      return acc;
    }, false);

    this.props.actions.setItem(selectedPrinter, itemNames.selectedPrinter);

    if(isSession){
      sessionStorage.setItem(key, JSON.stringify(savedPrinters));
    } else {
      localStorage.setItem(key, JSON.stringify(savedPrinters));
    }

    return true;

  }

  onSaveForSession(){
    if(!this.selectedPrinterIsAvailable()) return false;
    this.saveToLocalStorage('printerSettings', true);
    this.setSavedMessage(true); // true indicates session
  }

  onSaveForMachine(){
    //if(!this.selectedPrinterIsAvailable()) return false;
    this.saveToLocalStorage('printerSettings', false);
    this.setSavedMessage(false); // false indicates for machine
  }

  setSavedMessage(isSession){

    const message = (isSession) ? I18n.t('common.settingsSavedForSession') : I18n.t('common.settingsSavedForMachine');

    this.setState({importantMessage: message, printersChanged: false});

  }

  removePrinter(printer){

    const {printers, selectedPrinter} = this.getSelectedPrinterAndPrinters(printer);

    const index = printers.indexOf(selectedPrinter);

    const newPrinters = printers.filter( (printer, idx) => {
      return idx !== index;
    });

    this.setState({machinePrinters: newPrinters, printersChanged: true, importantMessage: I18n.t('common.youHaveUnsavedChanges')});

  }

  updateMachinePrintersWithQzPrinters(printers){

    // Zero status... can change in session
    const machinePrinters = this.state.machinePrinters.map((printer) => {
      printer.status = 0;
      return printer;
    });

    // Update status... add any not in list
    printers.forEach((p) => {
      const printer = machinePrinters.find((printer) => {
        return printer.name === p.name;
      });
      if(printer) {
        printer.status = 1;
        return true;
      }
      machinePrinters.push({
        name: p.value,
        status: 1,
        dpi: 203,
        smallFontSize: '12px',
        largeFontSize: '14px',
        selected: false
      });
    });

    this.setState({machinePrinters});

  }

  // QZ Methods

  /***
   * Test for active status... if not active... then connect
   */
  qzTrayIsRunning() {

    this.setState({qzLoaded: true});

    return new Promise( (resolve, reject) => {

      this.setState({connecting: true});

      if(this.props.qzTray.websocket.isActive()){
        resolve();
      } else {
        this.catchQzTrayHung(); // Qz if hung never completes promise, trap with timeout
        this.props.qzTray.websocket.connect()
          .then(() => {
            this.clearQzTrayHung();
            resolve();
          })
          .catch((e) => {
            this.clearQzTrayHung();
            reject(e);
          });
      }

    });

  }

  /***
   * QZ Tray can hang in which cases promises never resolve and leaves us permanently loading.
   */
  catchQzTrayHung(){
    const connectTimeout = setTimeout(() => {
      this.showQzError('qzTraySuspended');
      this.setState({connectTimeout: false});
    }, this.state.secondsUntilQzIsInvalid * 1000);
    this.setState({connectTimeout: connectTimeout});
  }

  /***
   * Clear the timeout for hung state
   */
  clearQzTrayHung(){
    if(!this.state.connectTimeout) return true;
    clearTimeout(this.state.connectTimeout);
    this.setState({connectTimeout: false});
  }

  getQzPrinters(event){

    if(event !== undefined){
      event.stopPropagation();
      event.preventDefault();
      event.target.blur();
    }

    const getPrinters = () => {
      this.props.qzTray.printers.find().then((data) => {
        const printers = [];
        for (let i = 0; i < data.length; i++) {
          printers.push({name: data[i], value: data[i]});
        }
        if(printers.length === 0){
          return this.showQzError('noPrinters');
        }
        this.updateMachinePrintersWithQzPrinters(printers);
        this.qzSuccess();
      }).catch(() => {
        return this.showQzError('noPrinters');
      });
    };

    this.qzTrayIsRunning()
      .then(getPrinters)
      .catch(() => {
        this.showQzError('noQzTray');
      });

  }

  showQzError(error){

    let newState = {};

    switch(error){
      case 'noQzTray':
        newState = {
          showError : true,
          title: I18n.t('common.errorConnectingQzTrayTitle'),
          message : I18n.t('common.errorConnectingQzTrayMessage'),
          connecting: false
        };
        break;
      case 'noPrinters':
        newState = {
          showError : true,
          title: I18n.t('common.errorNoPrintersFoundTitle'),
          message : I18n.t('common.errorNoPrintersFound'),
          connecting: false
        };
        break;
      case 'qzTraySuspended':
        newState = {
          showError : true,
          title: I18n.t('common.errorQzTrayNotResponding'),
          message : I18n.t('common.errorQzTrayNotResponding'),
          connecting: false
        };
        break;
    }
    this.setState(newState);

  }

  qzSuccess(addState){
    const newState = {
      showError: false,
      connecting: false
    };
    const fullState = (addState !== undefined) ? Object.assign({}, newState, addState) : newState;
    this.setState(fullState);
  }


  // END QZ

  getSelectedPrinterAndPrinters(printer){

    //const string = JSON.stringify(printer);
    const printers = this.state.machinePrinters.map( (printer) => printer);

    const selectedPrinter = printers.find((p) => {
      return p.name === printer.name;
    });

    // const selectedPrinter = printers.find( (printer) => {
    //   const printerString = JSON.stringify(printer);
    //   return (string === printerString);
    // });
    if(selectedPrinter) selectedPrinter.language_type = 'escpos';

    return {
      printers,
      selectedPrinter
    };

  }

  onChangeDpi(printer, value){
    const {printers, selectedPrinter} = this.getSelectedPrinterAndPrinters(printer);
    selectedPrinter.dpi = value;
    this.setState({machinePrinters: printers, printersChanged: true, importantMessage: I18n.t('common.youHaveUnsavedChanges')});
  }

  onChangeFontSize(printer, field, value){
    const {printers, selectedPrinter} = this.getSelectedPrinterAndPrinters(printer);
    selectedPrinter[field] = value;
    this.setState({machinePrinters: printers, printersChanged: true, importantMessage: I18n.t('common.youHaveUnsavedChanges')});
  }

  onChangeField(printer, field, value){
    const {printers, selectedPrinter} = this.getSelectedPrinterAndPrinters(printer);
    selectedPrinter[field] = value;
    this.setState({machinePrinters: printers, printersChanged: true, importantMessage: I18n.t('common.youHaveUnsavedChanges')});
  }

  onToggleSelected(printer){
    const {printers, selectedPrinter} = this.getSelectedPrinterAndPrinters(printer);
    printers.forEach((printer) => {
      printer.selected = false;
    });
    if(!selectedPrinter) {
      printer.remote = true;
      printers.push(printer);
    }
    const sPrinter = (selectedPrinter) ? selectedPrinter : printer;
    sPrinter.selected = true;
    sPrinter.language_type = 'escpos';
    this.setState({machinePrinters: printers, printersChanged: true, importantMessage: I18n.t('common.youHaveUnsavedChanges')});
  }

  onToggleSetting(field, value){


    const printer = this.state.machinePrinters.find((printer) => printer.selected);

    const {printers, selectedPrinter} = this.getSelectedPrinterAndPrinters(printer);

    selectedPrinter[field] = value;

    if(field === 'printWidthUom' && value === 'default') delete(selectedPrinter.printWidth);

    this.setState({machinePrinters: printers, printersChanged: true, importantMessage: I18n.t('common.youHaveUnsavedChanges')});

  }

  // State Change Methods

  preventDefault(event){
    if(event !== undefined) return true;
    event.stopPropagation();
    event.preventDefault();
    return true;
  }

  onTogglePreferencesHelp(event){
    this.preventDefault(event);
    const newState = {showPreferencesHelp: !this.state.showPreferencesHelp};
    this.setState(newState);
  }

  initState(){
    const newState = {
      qzLoaded: false
    };
    this.setState(newState);
  }

  onEnter(){
    this.getPrintersForMachine();
    this.initState();
    if(Object.keys(this.props.qzTray).length === 0) {
      this.props.actions.getQzTray(this.props.actions.signQzRequest); // Printers load once this is in componentWillReceiveProps
    } else {
      this.getQzPrinters();
    }
    if(this.props.onShowCallback !== undefined) this.props.onShowCallback();
  }

  onHide(){
    this.onExit();
  }

  onExit(){
    this.setState({show: false, qzLoaded: false});
    if(this.props.onHideCallback !== undefined) this.props.onHideCallback();
  }

  render() {

    return (
      <Modal onEnter={this.onEnter} show={this.props.show} onExit={this.onExit} onHide={this.onHide} className='printer-modal'>
        <Modal.Header closeButton>
          <Modal.Title>
            <div style={{float: 'left'}}>
              {I18n.t('common.dummy.printerSettingsFor')}:&nbsp;
              {LABEL_TYPES[this.props.setForLabel] !== undefined ? LABEL_TYPES[this.props.setForLabel].name : ''}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(!this.state.qzLoaded)
            ? <div>Connecting...</div>
              : (this.state.showError)
                ? <QzError onTryAgain={this.getQzPrinters} title={this.state.title} message={this.state.message}/>
              : (this.state.machinePrinters.length > 0)
                ? (<PrinterList
                     printServers={this.props.printServers}
                     labelKey={this.props.setForLabel}
                     qzPrinters={[]}
                     showFontSize={this.props.showFontSize}
                     printersChanged={this.state.printersChanged}
                     savedMessage={this.state.savedMessage}
                     onToggleSetting={this.onToggleSetting}
                     onSaveForMachine={this.onSaveForMachine}
                     onSaveForSession={this.onSaveForSession}
                     onChangeDpi={this.onChangeDpi}
                     onChangeFontSize={this.onChangeFontSize}
                     onChangeField={this.onChangeField}
                     machinePrinters={this.state.machinePrinters}
                     onToggleSelected={this.onToggleSelected}
                     removePrinter={this.removePrinter}
                     onTogglePreferencesHelp={this.onTogglePreferencesHelp}
                     showPreferencesHelp={this.state.showPreferencesHelp}
                  />)
                : <div>Loading Printers...</div>
          }
          <div style={{clear: 'both', margin: '12px 0px'}}>
            {this.state.connecting ? <LoadingBar type='warning' message='common.inProgress'/> : null}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col md='1'>
              <Button
                onClick={this.onHide}
                className='save-button btn-block'
                variant='warning'>
                {I18n.t('common.actions.close')}
              </Button>
            </Col>
            <Col md='11'>
              <div
                className={this.state.printersChanged ? 'text-danger' : 'text-success'}
                style={{textAlign: 'left', fontSize: '14px', paddingTop: '8px', fontWeight: 'bold'}}>
                {this.state.importantMessage}
              </div>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );

  }

}

SetPrinterModal.getPrinterForLabel = (labelKey) => {

  //const allSessionPrinters = sessionStorage.getItem('printerSettings');
  const allPrinters = localStorage.getItem('printerSettings');

  let parsedPrinters = [];
  //let sessionPrinters = null;
  let printers = null;

  /*if(allSessionPrinters !== null){
    parsedPrinters = JSON.parse(allSessionPrinters);
    if(parsedPrinters[labelKey] !== undefined) sessionPrinters = parsedPrinters[labelKey];
  }*/

  if(allPrinters !== null){
    parsedPrinters = JSON.parse(allPrinters);
    if(parsedPrinters[labelKey] !== undefined) printers = parsedPrinters[labelKey];
  }

  //const printerArray = (sessionPrinters !== null) ? sessionPrinters : (printers !== null) ? printers : [];
  const printerArray = (printers !== null) ? printers : [];

  const usePrinter = printerArray.reduce((acc, printer) => {
    if(acc) return acc;
    if(printer.selected) return printer;
    return acc;
  }, false);

  return usePrinter;

  //this.setState({currentPrinter: usePrinter.name, usePrinter, labelName, labelKey});

};

SetPrinterModal.propTypes = {
  show: PropTypes.bool.isRequired,
  showDpis: PropTypes.bool,
  showFontSize: PropTypes.bool,
  setForLabel: PropTypes.string,
  qzTray: PropTypes.object.isRequired,
  onHideCallback: PropTypes.func,
  onShowCallback: PropTypes.func,
  actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    qzTray: state.qzTray,
    printServers: getPrintServers(state),
  };
}

function mapDispatchToProps(dispatch) {
  //const actions = Object.assign({}, apiActions, itemActions, {push}, {getQzTray, signQzRequest});
  const actions = Object.assign({}, {getQzTray, signQzRequest, setItem, setData}, apiActions);
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SetPrinterModal);
