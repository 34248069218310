import {change, formValueSelector} from 'redux-form';
import get from 'lodash.get';
import {isFormChangeAction} from './utils';
import * as itemNames from '../../constants/itemNames';

const taxFormMiddleware = store => next => action => {
  const result = next(action);
  const state = store.getState();
  const facility = state[itemNames.facility];
  const provinceCode = (get(facility, 'province_code') || '').trim().toLowerCase();

  if (isFormChangeAction(action, ['tax']) && provinceCode === 'ok') {
    if(action.payload && get(action, 'meta.field', '').indexOf('gross_receipts_ok') !== -1) {
      const temp = action.meta.field.split('.');
      const currentIndex = parseInt(temp[0].replace('taxRates[', '').replace(']', ''));
      const selector = formValueSelector('tax');
      const taxRates = selector(state, 'taxRates');
      for(let i = 0; i < taxRates.length; i++){
        if(i !== currentIndex){
          store.dispatch(change('tax', `taxRates[${i}].gross_receipts_ok`, 0));
        }
      }
    }
  }

  return result;
};


export default taxFormMiddleware;
