import React from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import {push} from 'react-router-redux';
import { Card, Button, ButtonToolbar } from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import { FaExclamation } from 'react-icons/fa';
// import {notifyBugsnag} from '../../util/bugsnag';


class ErrorBoundry extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true , error, info});
    // You can also log the error to an error reporting service
    // notifyBugsnag(error);
    console.log(error.message, info);// eslint-disable-line
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI onClick={goBack}
      return (<Card className='form-page' variant='danger'>
        <Card.Header>
          <h2 className='panel-title'><FaExclamation className='float-right'/>
            {I18n.t('error.unhandled.title')}
          </h2>
        </Card.Header>
        <Card.Body>
          {this.state.error.message}
          <hr/>
          {I18n.t('error.unhandled.instructions')}
          <ButtonToolbar className='float-right'>
            <Button  onClick={this.props.actions.push}>{I18n.t('common.actions.goBack')}</Button>
            <Button  onClick={() => window.location.reload(true)}>{I18n.t('common.actions.refresh')}</Button>
          </ButtonToolbar>
        </Card.Body>
      </Card>);
    }
    return this.props.children;
  }
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({push}, dispatch),
  };
}

export default connect(state => ({}), mapDispatchToProps)(ErrorBoundry);
