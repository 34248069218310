import React from 'react';
import PropTypes from 'prop-types';
import {MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank} from 'react-icons/md';

const StatusCheckBox = (props) => {

  const iconStyle = {fontSize: '22px', paddingRight: '5px'};
  let icon = <MdOutlineCheckBoxOutlineBlank style={iconStyle}/>;
  let text = 'Indicates there is no custom label. Default is in use.';

  switch (props.status) {
  case 0:
    icon = <MdOutlineCheckBox className='danger' style={iconStyle}/>;
    text = 'Indicates there is an inactive custom label. Default is in use.';
    break;
  case 1:
    icon = <MdOutlineCheckBox className='success' style={iconStyle}/>;
    text = 'Indicates there is an active custom label and it is in use.';
    break;
  }

  return (
    <div style={props.withText ? {} : {display: 'inline'}}>
      {icon}
      {props.withText ? text : null}
    </div>
  );

};

StatusCheckBox.propTypes = {
  status: PropTypes.number.isRequired,
  withText: PropTypes.bool
};

export default StatusCheckBox;
