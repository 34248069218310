const rules = {
  'is_prepack': ['FLOWER', 'CONCENTRATE'],
  'medicated_weight': ['CARTPENS', 'INFUSED', 'EDIBLE'],
  'medicated_weight_uom': ['CARTPENS', 'INFUSED', 'EDIBLE'],
  'net_weight': ['CARTPENS', 'INFUSED', 'EDIBLE'],
  'net_weight_uom': ['CARTPENS', 'INFUSED', 'EDIBLE'],
  'unit_weight': ['CARTPENS', 'INFUSED', 'EDIBLE'],
  'unit_weight_uom': ['CARTPENS', 'INFUSED', 'EDIBLE'],
  'medicated_volume': ['CARTPENS', 'INFUSED', 'EDIBLE'],
  'activation_time': ['FLOWER', 'CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'PLANTS', 'HARVESTS', 'MARIJUANA'],
  'serving_size': ['FLOWER', 'CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'HARVESTS', 'MARIJUANA'],
  'servings_per_container': ['FLOWER', 'CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'HARVESTS', 'MARIJUANA'],
  'solvent': ['CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'HARVESTS', 'MARIJUANA'],
  'pieces_per_unit': ['EDIBLE', 'INFUSED', 'PLANTS'],
  'dilution_method': ['CARTPENS'],
  'online_availability.organization.available_online': ['FLOWER', 'CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'PLANTS', 'MARIJUANA', 'UNMEDICATED'],
  'online_availability.facilities.available_online': ['FLOWER', 'CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'PLANTS', 'MARIJUANA', 'UNMEDICATED'],
  'online_availability.facilities.facility_ids': ['FLOWER', 'CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'PLANTS', 'MARIJUANA', 'UNMEDICATED'],
  'prices.organization.retail.medical.default_price': ['FLOWER', 'CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'PLANTS', 'MARIJUANA', 'UNMEDICATED'],
  'prices.organization.retail.medical.is_non_taxable': ['FLOWER', 'CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'PLANTS', 'MARIJUANA', 'UNMEDICATED'],
  'prices.organization.retail.medical.pricing_group_id': ['FLOWER', 'CONCENTRATE'],
  'prices.organization.retail.medical.inherit_pricing_group_updates': ['FLOWER', 'CONCENTRATE'],
  'prices.organization.retail.medical.pricing_class_id': ['FLOWER', 'CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'PLANTS', 'MARIJUANA', 'UNMEDICATED'],
  'prices.organization.retail.medical.inherit_pricing_class_updates': ['FLOWER', 'CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'PLANTS', 'MARIJUANA', 'UNMEDICATED'],
  'prices.organization.retail.medical.weight_prices.default_price': ['FLOWER', 'CONCENTRATE'],
  'prices.organization.retail.medical.facility_ids': ['FLOWER', 'CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'PLANTS', 'MARIJUANA', 'UNMEDICATED'],
  'prices.facilities.retail.medical.default_price': ['FLOWER', 'CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'PLANTS', 'MARIJUANA', 'UNMEDICATED'],
  'prices.facilities.retail.medical.is_non_taxable': ['FLOWER', 'CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'PLANTS', 'MARIJUANA', 'UNMEDICATED'],
  'prices.facilities.retail.medical.pricing_group_id': ['FLOWER', 'CONCENTRATE'],
  'prices.facilities.retail.medical.inherit_pricing_group_updates': ['FLOWER', 'CONCENTRATE'],
  'prices.facilities.retail.medical.pricing_class_id': ['FLOWER', 'CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'PLANTS', 'MARIJUANA', 'UNMEDICATED'],
  'prices.facilities.retail.medical.inherit_pricing_class_updates': ['FLOWER', 'CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'PLANTS', 'MARIJUANA', 'UNMEDICATED'],
  'prices.facilities.retail.medical.weight_prices.default_price': ['FLOWER', 'CONCENTRATE'],
  'prices.facilities.retail.medical.facility_ids': ['FLOWER', 'CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'PLANTS', 'MARIJUANA', 'UNMEDICATED'],
  'prices.organization.retail.recreational.default_price': ['FLOWER', 'CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'PLANTS', 'MARIJUANA', 'UNMEDICATED'],
  'prices.organization.retail.recreational.is_non_taxable': ['FLOWER', 'CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'PLANTS', 'MARIJUANA', 'UNMEDICATED'],
  'prices.organization.retail.recreational.pricing_group_id': ['FLOWER', 'CONCENTRATE'],
  'prices.organization.retail.recreational.inherit_pricing_group_updates': ['FLOWER', 'CONCENTRATE'],
  'prices.organization.retail.recreational.pricing_class_id': ['FLOWER', 'CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'PLANTS', 'MARIJUANA', 'UNMEDICATED'],
  'prices.organization.retail.recreational.inherit_pricing_class_updates': ['FLOWER', 'CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'PLANTS', 'MARIJUANA', 'UNMEDICATED'],
  'prices.organization.retail.recreational.weight_prices.default_price': ['FLOWER', 'CONCENTRATE'],
  'prices.organization.retail.recreational.facility_ids': ['FLOWER', 'CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'PLANTS', 'MARIJUANA', 'UNMEDICATED'],
  'prices.facilities.retail.recreational.default_price': ['FLOWER', 'CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'PLANTS', 'MARIJUANA', 'UNMEDICATED'],
  'prices.facilities.retail.recreational.is_non_taxable': ['FLOWER', 'CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'PLANTS', 'MARIJUANA', 'UNMEDICATED'],
  'prices.facilities.retail.recreational.pricing_group_id': ['FLOWER', 'CONCENTRATE'],
  'prices.facilities.retail.recreational.inherit_pricing_group_updates': ['FLOWER', 'CONCENTRATE'],
  'prices.facilities.retail.recreational.pricing_class_id': ['FLOWER', 'CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'PLANTS', 'MARIJUANA', 'UNMEDICATED'],
  'prices.facilities.retail.recreational.inherit_pricing_class_updates': ['FLOWER', 'CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'PLANTS', 'MARIJUANA', 'UNMEDICATED'],
  'prices.facilities.retail.recreational.weight_prices.default_price': ['FLOWER', 'CONCENTRATE'],
  'prices.facilities.retail.recreational.facility_ids': ['FLOWER', 'CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'PLANTS', 'MARIJUANA', 'UNMEDICATED'],
  'prices.organization.wholesale.default_price': ['FLOWER', 'CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'PLANTS', 'MARIJUANA', 'UNMEDICATED'],
  'prices.organization.wholesale.is_non_taxable': ['FLOWER', 'CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'PLANTS', 'MARIJUANA', 'UNMEDICATED'],
  'prices.organization.wholesale.pricing_group_id': ['FLOWER', 'CONCENTRATE'],
  'prices.organization.wholesale.inherit_pricing_group_updates': ['FLOWER', 'CONCENTRATE'],
  'prices.organization.wholesale.pricing_class_id': ['FLOWER', 'CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'PLANTS', 'MARIJUANA', 'UNMEDICATED'],
  'prices.organization.wholesale.inherit_pricing_class_updates': ['FLOWER', 'CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'PLANTS', 'MARIJUANA', 'UNMEDICATED'],
  'prices.organization.wholesale.weight_prices.default_price': ['FLOWER', 'CONCENTRATE'],
  'prices.organization.wholesale.facility_ids': ['FLOWER', 'CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'PLANTS', 'MARIJUANA', 'UNMEDICATED'],
  'prices.facilities.wholesale.default_price': ['FLOWER', 'CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'PLANTS', 'MARIJUANA', 'UNMEDICATED'],
  'prices.facilities.wholesale.is_non_taxable': ['FLOWER', 'CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'PLANTS', 'MARIJUANA', 'UNMEDICATED'],
  'prices.facilities.wholesale.pricing_group_id': ['FLOWER', 'CONCENTRATE'],
  'prices.facilities.wholesale.inherit_pricing_group_updates': ['FLOWER', 'CONCENTRATE'],
  'prices.facilities.wholesale.pricing_class_id': ['FLOWER', 'CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'PLANTS', 'MARIJUANA', 'UNMEDICATED'],
  'prices.facilities.wholesale.inherit_pricing_class_updates': ['FLOWER', 'CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'PLANTS', 'MARIJUANA', 'UNMEDICATED'],
  'prices.facilities.wholesale.weight_prices.default_price': ['FLOWER', 'CONCENTRATE'],
  'prices.facilities.wholesale.facility_ids': ['FLOWER', 'CONCENTRATE', 'CARTPENS', 'EDIBLE', 'INFUSED', 'PLANTS', 'MARIJUANA', 'UNMEDICATED']
};

export default rules;
