import get from 'lodash.get';
import {formValueSelector, arrayRemove, arrayInsert} from 'redux-form';
import * as types from '../../constants/actionTypes';
import {uomTypes, VOLUME, WEIGHT} from '../../constants/uomTypes';
import { PREPACK_WEIGHT_FACILITY_FORM } from '../../constants/forms';
import { getSelectedUom } from '../../selectors/forms/prepackWeightsFacilitySelectors';
import { getFormArrayIndexFromString, isField } from './utils';

const selectPackageFacilityWeightForm = store => next => action => {

  const isFacilityPrepackWeightFormChange = (action) => {
    return get(action, 'type') === types.REDUX_FORM_CHANGE && get(action, 'meta.form') === PREPACK_WEIGHT_FACILITY_FORM;
  };

  if(isFacilityPrepackWeightFormChange(action)){
    const {meta, payload} = action;
    if (meta.field && isField(meta.field, 'active')) {
      const selector = formValueSelector(PREPACK_WEIGHT_FACILITY_FORM);
      const isActive = payload;
      const selectedUom = getSelectedUom(store.getState());
      const uomType = get(uomTypes, selectedUom.toUpperCase(), {});
      const index = getFormArrayIndexFromString(meta.field);
      const rowKey = meta.field.split('.').shift();
      const rowData = selector(store.getState(), rowKey);

      if(isActive && get(uomType, 'type', WEIGHT) === VOLUME){
        const newRowData = Object.assign({}, rowData, {active: true, categories: [2]});
        store.dispatch(arrayRemove(PREPACK_WEIGHT_FACILITY_FORM, 'sizes', index));
        store.dispatch(arrayInsert(PREPACK_WEIGHT_FACILITY_FORM, 'sizes', index, newRowData));
      }
    }
  }

  return next(action);

};

export default selectPackageFacilityWeightForm;



