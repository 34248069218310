import {createSelector} from 'reselect';
import * as itemNames from '../constants/itemNames';
import {userHasPermission} from './usersSelectors';

const getColumnVisibilitySettings = state => state[itemNames.columnVisibilitySettings];
const getColumns = (_, params) => params.columns;
const getState = (_, params) => params.state;
const getTabs = (_, params) => params.tabs;

const getColumnVisibilityData = createSelector(
  [getColumnVisibilitySettings],
  settings => settings && settings.data || []
);
const getColumnsWithVisibility = createSelector(
  [getColumns, getColumnVisibilityData],
  (columns, visibilitySettings) => columns.map((column) => {
    const visibilitySetting = visibilitySettings.find(setting => setting.name === column.name) || {};
    delete(visibilitySetting.dataSort);
    return {
      ...column,
      ...visibilitySetting,
    };
  })
);

export const getFilteredColumns = createSelector([getColumnsWithVisibility, getState], (columns, state) =>
  columns.filter(column => userHasPermission(state, column))
);

export const getFilteredTabs = createSelector([getTabs, getState], (tabs, state) =>
  tabs.filter(tab => userHasPermission(state, tab))
);

const getColumnsWithPercentWidths = createSelector([getFilteredColumns], (columns) => {
  // Get width of first table on page - for the moment this works since we only have one
  const tables = document.getElementsByClassName('react-bs-table-container');
  const tableWidth = tables.length && tables[0].clientWidth ? tables[0].clientWidth : 500;

  // Group by type - used as lookup and convenient for debugging
  const columnsByWidthType = columns.reduce((acc, c) => {
    const column = Object.assign({}, c);
    // Convert fixed width columns to percents
    if(column.fixedWidth) column.width = `${(parseInt(column.fixedWidth) / tableWidth) * 100}%`;
    const key = column.hidden
      ? 'hidden'
      : column.width && column.width.indexOf('%') !== -1
        ? 'percent'
        : (column.width && column.width.indexOf('px') !== -1)
          ? 'pixel'
          : 'unassigned';

    if(key === 'pixel') {
      column.originalWidth = column.width; // Convenience in debugging
      column.width = `${(parseInt(column.width) / tableWidth) * 100}%`; // make percent
    }
    acc[key].push(column);
    return acc;
  }, {pixel: [], percent: [], unassigned: [], hidden: []});

  // Return only the visible columns with all widths expressed in percent
  return columns.filter((column) => !column.hidden).map((c) => {
    const column = Object.assign({}, c);
    const getPercentColumn = (column) => {
      let percentColumn = columnsByWidthType.percent.find((col) => col.dataId === column.dataId);
      if(!percentColumn){
        percentColumn = columnsByWidthType.pixel.find((col) => col.dataId === column.dataId);
      }
      return percentColumn ? percentColumn : false;
    };
    const percentColumn = getPercentColumn(column);
    if(percentColumn) column.width = percentColumn.width;
    return column;
  });
});

export default getColumnsWithPercentWidths;
