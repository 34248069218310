import { reduxForm } from 'redux-form';

import SplitPackageForm from './SplitPackageForm';
import { validate } from './validate';

const SplitPackageFormWrapper = reduxForm({
  validate,
  enableReinitialize: true,
})(SplitPackageForm);

export default SplitPackageFormWrapper;
