import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button, Col, Row} from 'react-bootstrap';
import startCase from 'lodash.startcase';

import * as itemNames from '../../../../constants/itemNames';
import * as apiActions from '../../../../actions/apiActions';
import {setItemValue} from '../../../../actions/itemActions';
import {formatDate} from '../../../../util/dateHelpers';
import validate from './validatePurchasedAmounts';
import {getPurchasedAmountsInitialValues, getPurchasedAmountsData} from '../../../../selectors/ordersSelectors';
import ModalWrapper from '../../../common/ModalWrapper';
import purchasedAmountsForm from './PurchasedAmountsForm';

const formName = 'purchasedAmounts';

class PurchasedAmounts extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.fetchPurchasedAmounts = this.fetchPurchasedAmounts.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.state = {showModal: false};
  }

  componentWillMount() {
    const {customer} = this.props;
    const {date_from, date_to} = this.props.initialValues;
    this.fetchPurchasedAmounts(customer, formatDate(date_from), formatDate(date_to));
  }

  componentWillReceiveProps(newProps) {
    const {customer} = this.props;
    if (customer && newProps.customer && customer.id !== newProps.customer.id) {
      const {date_from, date_to} = this.props.initialValues;
      this.fetchPurchasedAmounts(newProps.customer, formatDate(date_from), formatDate(date_to));
    }
  }

  fetchPurchasedAmounts(customer, date_from, date_to) {
    if (customer.id) {
      const payload = {
        consumer_id: customer.id
      };
      if (date_from) {
        payload.date_from = date_from;
      }
      if (date_to) {
        payload.date_to = date_to;
      }
      this.props.actions.postItem(
        '/api/consumer_orders/sales_history',
        payload,
        null,
        {failed: 'orders.purchasedAmounts.get.failed'},
        {},
        result => {
          this.props.actions.setItemValue(result, itemNames.customersPurchasedAmounts, customer.id);
        }
      );
    }
  }

  onSubmit(formValues) {
    const {customer} = this.props;
    const date_from = formatDate(formValues.date_from);
    const date_to = formatDate(formValues.date_to);
    this.hideModal();
    this.fetchPurchasedAmounts(customer, date_from, date_to);
  }

  showModal() {
    this.setState({showModal: true});
  }

  hideModal() {
    this.setState({showModal: false});
  }

  render() {
    const {customer, initialValues, purchasedAmountsData: {date_from, date_to, sales, item_masters_needing_correction}} = this.props;
    const {showModal} = this.state;

    return (<div className='leaf-pa-purchased-amounts'>
      <ModalWrapper
        Component={purchasedAmountsForm}
        onHide={this.hideModal}
        showModal={showModal}
        title='orders.purchasedAmounts.title'
        customer={customer}
        form={formName}
        validate={validate}
        initialValues={initialValues}
        onSubmit={this.onSubmit}
      />
      <Row className='leaf-pa-purchased-amounts-heading'>
        <Col md={6}>
          <h4>{I18n.t('orders.purchasedAmounts.title')}</h4>
        </Col>
        <Col md={6}>
          <Button variant='primary' style={{fontSize: '10px', margin: '2px 4px'}} onClick={this.showModal}>
            {I18n.t('common.form.update')}
          </Button>
        </Col>
      </Row>
      {date_from && date_to ?
        <Row>
          <Col md={12}>{date_from} - {date_to}</Col>
        </Row>
        : null}
      {sales.length ?
        <Row>
          {sales.map((amount, index) =>
            <Col md={6} key={index}>
              <strong>{amount.category ? startCase(amount.category) : I18n.t('orders.purchasedAmounts.noCategory')}:</strong>
              {!amount.needs_correction ?
                <span>
                  &nbsp;{amount.qty} {amount.uom}
                </span>
                :
                <span>
                  &nbsp;{amount.count} EA <sup>*</sup>
                </span>
              }
            </Col>
          )}
        </Row>
        : null}
      {item_masters_needing_correction && item_masters_needing_correction.length ?
        <Row style={{clear: 'both', border: '1px dotted #ccc', marginTop: '6px'}}>
          <Col md={12}>
            * {I18n.t('orders.purchasedAmounts.noMedicatedWeight')}
            <ul>
              {item_masters_needing_correction.map((item_master_needing_correction, index) =>
                <li key={index}>{item_master_needing_correction.display_name ? item_master_needing_correction.display_name : item_master_needing_correction.name}</li>
              )}
            </ul>
          </Col>
        </Row>
        : null}
        </div>);
  }
}

PurchasedAmounts.propTypes = {
  actions: PropTypes.shape({
    postItem: PropTypes.func.isRequired,
    setItemValue: PropTypes.func.isRequired,
  }).isRequired,
  customer: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  initialValues: PropTypes.shape({
    date_from: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    date_to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  }).isRequired,
  purchasedAmountsData: PropTypes.shape({
    date_from: PropTypes.string.isRequired,
    date_to: PropTypes.string.isRequired,
    sales: PropTypes.array.isRequired,
    item_masters_needing_correction: PropTypes.array,
  }).isRequired,
};

function mapStateToProps(state, props) {
  return {
    initialValues: getPurchasedAmountsInitialValues(state, props.customer),
    purchasedAmountsData: getPurchasedAmountsData(state, props.customer)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {setItemValue});
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchasedAmounts);
