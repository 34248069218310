import {createSelector} from 'reselect';
import orderBy from 'lodash.orderby';
import get from 'lodash.get';
import * as dataNames from '../constants/dataNames';
import * as itemNames from '../constants/itemNames';
import {convertFromBase} from '../util/uomHelpers';
import {PRODUCT_MASTER_FORM} from '../constants/forms';

export const getPricingWeights = (state, props) => {
  if (get(props, 'form') === PRODUCT_MASTER_FORM) {
    return state[dataNames.productMasterPricingWeights];
  }
  return state[dataNames.pricingWeights];
};
const getPricingWeightId = (_, props) => props.pricing_weight_id;
const getFulfillmentUnits = (_, props) => props.fulfillment_units;
export const getChildItemMasters = (state) => state[dataNames.childItemMasters];
const getPrepackFacilityWeights = (state) => state[dataNames.prepackFacilityWeights];
const getItem = (state) => state[itemNames.inventoryItem];

export const getPricingWeightById = createSelector(
  getPricingWeights, getPricingWeightId,
  (weights, id) => weights.find(weight => weight.id === id)
);

export const getPricingWeightByFulfillmentUnits = createSelector(
  getPricingWeights, getFulfillmentUnits,
  (weights, units) => weights.find(weight => weight.fulfillment_units === units)
);

export const getPricingWeightsByUom = (uom, pricingWeights, orderAttr = 'weight_base', order = 'asc') => {
  const pricingWeightsForUom = pricingWeights.reduce((result, weight) => {
    if (weight.uom_display !== uom) return result;

    result.push({
      pricing_weight_id: weight.id,
      weight_base: weight.weight_base,
      uom_display: weight.uom_display,
      weight: convertFromBase(weight.weight_base, weight.uom_display)
    });

    return result;
  }, []);

  return orderBy(pricingWeightsForUom, orderAttr, order);
};

export const getQuantities = createSelector(
  [getChildItemMasters, getPrepackFacilityWeights, getItem], (childItemMasters, prePackFacilityWeights, item) => {
    if (!childItemMasters || !childItemMasters.length) return [];

    return childItemMasters.reduce((acc, childItemMaster) => {
      const prePackWeight = prePackFacilityWeights.find(weight => {
        return (weight.id !== null
          && weight.prepack_weight_id === childItemMaster.prepack_weight_id
          && weight.categories.find(
            prepackWeightCategory => prepackWeightCategory.category_id === childItemMaster.category_id
          )
        );
      });
      if (prePackWeight) {
        return acc.concat({
          package_code: '',
          qty: '',
          item_master_id: childItemMaster.item_master_id,
          isChanged: false,
          weightBase: prePackWeight.weight_base,
          medically_compliant: item.medically_compliant_status === 'yes',
          name: childItemMaster.name,
          uom: prePackWeight.uom
        });
      }
      return acc;
    }, []).sort((a, b) => {
      return a.weightBase < b.weightBase ? -1 : 1;
    });
  }
);
