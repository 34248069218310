/* eslint block-scoped-var: 0 */
/* eslint vars-on-top: 0 */
/* eslint no-var: 0 */
/* eslint no-unused-vars: 0 */

if (typeof window !== 'undefined' && typeof window.document !== 'undefined') {
  const filesaver = require('./filesaver');

  var saveAs = filesaver.saveAs;
}

const saveStringAsFile = function(string, filename, mimeType) {
  if (typeof window !== 'undefined') {
    saveAs(new Blob([ string ],
      { type: mimeType }),
      filename, true);
  }
};

export default saveStringAsFile;
