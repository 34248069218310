import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import {Alert, Button, Col, Row} from 'react-bootstrap';
import {FaExclamationTriangle, FaMinus} from 'react-icons/fa';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import InternationalDateTimePickerInput from '../../../common/form/InternationalDateTimePickerInput';
import InternationalCurrencyInput from '../../../common/form/InternationalCurrencyInput';
import AddItem from '../../../common/form/AddItem';

const Payments = ({fields, paymentTypes, registersByType, errors, meta, canEditPayments, oddMoney, users}) => {
  return (
    <div>
      {meta && meta.error ? <Row>
        <Col md={11}>
          <Alert variant='danger'>
            <FaExclamationTriangle />
            <span className='padding-left'>{meta.error}</span>
          </Alert>
        </Col>
      </Row> : null}
      {
        fields.map((line, index) => {
          const data = fields.get(index); // Supports defaulting of amount on change of other fields if not set for 0 validation
          const registers = (data.payment_type === undefined || data.payment_type === 'cash') ? registersByType.cash : registersByType.others;
          return (
          <Row key={index}>
            <Col xs={4} md={2}>
              <Field name={`${line}.payment_type`} component={ReactSelectInput} props={{
                label: I18n.t('purchaseOrders.form.paymentType'),
                defaultOption: I18n.t('common.placeHolders.choose'),
                options: paymentTypes,
                textKey: 'name',
                valueKey: 'code',
                disabled: !canEditPayments,
                isRequired: Boolean(errors && errors[index]),
              }}
              />
            </Col>
            <Col xs={4} md={2}>
              <InternationalCurrencyInput
                name={`${line}.amount`}
                props={{
                  label: I18n.t('purchaseOrders.form.amount'),
                  placeholder:'',
                  disabled: !canEditPayments,
                  isRequired: Boolean(errors && errors[index]),
                }}
              />
            </Col>
            <Col xs={4} md={2}>
              <Field name={`${line}.register_id`} component={ReactSelectInput} props={{
                label: I18n.t('purchaseOrders.form.selectRegister'),
                defaultOption: I18n.t('common.placeHolders.choose'),
                options: registers,
                //options:
                isRequired: Boolean(errors && errors[index]),
                disabled: !canEditPayments,
                valueKey: 'id',
                textKey: 'name',
              }}/>
            </Col>
            <Col xs={4} md={2}>
              <Field name={`${line}.user_id`} component={ReactSelectInput} props={{
                label: I18n.t('cultivation.drivers.form.selectUser'),
                options: users,
                valueKey: 'id',
                textKey: 'displayName',
                isRequired: Boolean(errors && errors[index]),
                disabled: !canEditPayments,
              }}/>
            </Col>
            <Col xs={4} md={3} lg={2}>
              <InternationalDateTimePickerInput name={`${line}.payment_date`} props={{
                label: I18n.t('purchaseOrders.form.paymentDate'),
                isRequired: Boolean(errors && errors[index]),
                inputProps: {
                  disabled: !canEditPayments,
                },
                closeOnSelect: true
              }}/>
            </Col>
            {
              !canEditPayments
              ? null
                : (
                <Col xs={1} className='add-item'>
                  <Button variant='primary' size='sm' onClick={() => fields.remove(index)}>
                    <FaMinus />
                  </Button>
                </Col>
              )
            }

          </Row>
          );
        })
      }
      {
        !canEditPayments
        ? null
          : (
          <AddItem
            allowEmpty={true}
            addAction={() => fields.push({})}
          />
        )
      }

      {oddMoney < 0 ?
        <Row>
          <Col xs={12}><hr/></Col>
          <Col xs={12}>
            <h4>{I18n.t('purchaseOrders.form.changeDue')}</h4>
          </Col>
          <Col xs={4} md={2}>
            <Field name='oddMoney.payment_type' component={ReactSelectInput} props={{
              label: I18n.t('purchaseOrders.form.paymentType'),
              defaultOption: I18n.t('common.placeHolders.choose'),
              options: paymentTypes,
              textKey: 'name',
              valueKey: 'code',
              disabled: true,
              isRequired: true,
            }}
            />
          </Col>
          <Col xs={4} md={2}>
            <InternationalCurrencyInput name='oddMoney.amount'
              props={{
                label: I18n.t('purchaseOrders.form.amount'),
                disabled: true,
                isRequired: true,
              }}
            />
          </Col>
          <Col xs={4} md={2}>
            <Field name='oddMoney.register_id' component={ReactSelectInput} props={{
              label: I18n.t('purchaseOrders.form.selectRegister'),
              defaultOption: I18n.t('common.placeHolders.choose'),
              options: registersByType.cash,
              isRequired: true,
              disabled: !canEditPayments,
              valueKey: 'id',
              textKey: 'name',
            }}/>
          </Col>
          <Col xs={4} md={2}>
            <Field name='oddMoney.user_id' component={ReactSelectInput} props={{
              label: I18n.t('cultivation.drivers.form.selectUser'),
              options: users,
              valueKey: 'id',
              textKey: 'displayName',
              isRequired: true,
              disabled: !canEditPayments,
            }}/>
          </Col>
          <Col xs={6} md={3} lg={2}>
            <InternationalDateTimePickerInput name='oddMoney.payment_date' props={{
              label: I18n.t('purchaseOrders.form.paymentDate'),
              isRequired: true,
              inputProps: {
                disabled: !canEditPayments,
              },
              closeOnSelect: true,
            }}/>
          </Col>
        </Row>
        : null
      }
    </div>
  );
};

Payments.propTypes = {
  fields: PropTypes.object.isRequired,
  paymentTypes: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  registersByType: PropTypes.object,
  meta: PropTypes.object,
  errors: PropTypes.array,
  change: PropTypes.func,
  blur: PropTypes.func,
  canEditPayments: PropTypes.bool,
  oddMoney: PropTypes.number,
};

export default Payments;
