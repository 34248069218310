import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import {I18n} from 'react-redux-i18n';
import { Col, Row, ButtonToolbar, Button } from 'react-bootstrap';
import TextInput from '../../../../common/form/TextInput';
import ReactSelectInput from '../../../../common/form/ReactSelectInput';

const FutuCare = props => {
  const activeOptions = [
    { text: 'Yes', value: true },
    { text: 'No', value: false },
  ];

  return (
    <div className='futucare-container'>
      <Row>
        <Col sm={4}>
          <Field name='username' component={TextInput} props={{
            label: I18n.t('facility.setup.thirdPartyIntegration.futucare.username'),
            isRequired: true,
          }} />
        </Col>
        <Col sm={4}>
          <Field name='password' component={TextInput} props={{
            label: I18n.t('facility.setup.thirdPartyIntegration.futucare.password'),
            isRequired: true,
          }} />
        </Col>
        <Col sm={4}>
          <Field name='active' component={ReactSelectInput} props={{
            label: I18n.t('facility.setup.thirdPartyIntegration.futucare.active'),
            isRequired: true,
            options: activeOptions,
            dataId: 'value'
          }} />
        </Col>
      </Row>
      <Row className='submit-section'>
        <Col xs={12}>
          <div className='button-wrapper'>
            <ButtonToolbar className='float-right'>
              <Button variant='primary' type='submit'>
                Save
              </Button>
            </ButtonToolbar>
          </div>
        </Col>
      </Row>
    </div>
  );
};

FutuCare.propTypes = {
  actions: PropTypes.func.isRequired
};

export default FutuCare;
