//Discrete
export const EA = 'EA';
//Weight
export const MG = 'MG';
export const GR = 'GR';
export const KG = 'KG';
//Volume
export const ML = 'ML';
export const TSP = 'TSP';
export const TBSP = 'TBSP';
export const FLOZ = 'FLOZ';
export const L = 'L';
export const T = 'T';
export const OZ = 'OZ';
export const LB = 'LB';
export const KL = 'KL';
export const PT = 'PT';
export const QT = 'QT';
export const GAL = 'GAL';

//Time
export const SEC = 'SEC';
export const MIN = 'MIN';
export const HR = 'HR';
export const DAY = 'DAY';
export const WEEK = 'WEEK';
//Currency
export const USD = 'USD';

// Precisions
export const UOM_VALUE_PRECISION = 3;
export const UOM_VALUE_PRECISION_NON_HEMP = 2;
export const UOM_UNIT_VALUE_PRECISION = 0;
export const UOM_POS_VALUE_PRECISION = 2;
