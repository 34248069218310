import React from 'react';
import PropTypes from 'prop-types';
import {FaTimesCircle} from 'react-icons/fa';

const RestockItem = (props) => {

  const {data, disableItemRemoval} = props;

  /*const getPerUnitPrice = () => {
    return (parseFloat(data.original_unit_price)).toFixed(2);
  };

  const getRefundedLineItem = () => {
    return (parseFloat(data.original_unit_price) * parseInt(data.quantity)).toFixed(2);
  };*/

  return (
    <tr >
      {disableItemRemoval ?
        (
          <td>
            {data.name}
          </td>
        )
        :
        (
          <td style={{cursor: 'pointer'}} onClick={(event) => {
            props.onRemoveItemFromRestocking(event, data);
          }}>
            <FaTimesCircle className='text-danger'/> {data.name}
          </td>
        )
      }
      <td>
        {data.order_name}
      </td>
      <td style={{textAlign: 'right'}}>{data.quantity}</td>
    </tr>
  );

};

RestockItem.propTypes = {
  onRemoveItemFromRestocking: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

export default RestockItem;
