import {connect} from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import {requiredFieldValidation} from '../../common/form/redux-form/validations';
import HarvestPlantsForm from './HarvestPlantsForm';
import { HARVEST_PLANTS_FORM } from '../../../constants/forms';
import {getSelectableUomsByTypes} from '../../../selectors/uomsSelectors';


const validate = (values, props) => {
  const errors = {};
  if (values.option !== 3) {
    errors.option = I18n.t('some.form.selectThirdOption');
  }
  if (values.use_overall) {
    const plants = get(values, 'plants', []);
    plants.forEach((plant, index) => {
      if (!plant.wet_weight_harvest || plant.wet_weight_harvest == 0) {
        if (!errors.plants) { errors.plants = []; }
        errors.plants[index] = {wet_weight_harvest: I18n.t('plants.form.wetWeightZero')};
      }
    });
  } else {
    if (!values.wet_weight_harvest || values.wet_weight_harvest <= 0) {
      errors.wet_weight_harvest = I18n.t('plants.form.wetWeightZero');
    }
  }

  if (props && props.integrationState && props.integrationState.isMetrc) {
    if (values.total_waste_recorded && values.total_waste_recorded > 0) {
      errors.waste_type = requiredFieldValidation(values.waste_type, 'Harvest Waste Type');
    } else {
      for (const key in values.plants) {
        const plant = values.plants[key];
        if (plant && plant.total_waste_recorded && plant.total_waste_recorded > 0) {
          errors.waste_type = requiredFieldValidation(values.waste_type, 'Harvest Waste Type');
          break;
        }
      }
    }
  }

  errors.inventory_location_id = requiredFieldValidation(values.inventory_location_id, I18n.t('plants.form.storageArea'));
  return errors;
};

export const FormWrapper = reduxForm({
  form: HARVEST_PLANTS_FORM,
  validate
})(HarvestPlantsForm);


const selector = formValueSelector('harvest_plants');

export default connect (state => {
  const use_overall = selector(state, 'use_overall');
  const plants = selector(state, 'plants');
  const numPlants = plants ? plants.length : 1;
  const maxPlants = selector(state, 'max_plants');

  const uom = selector(state, 'uom');
  return {
    numPlants,
    use_overall: !!use_overall,
    maxPlants,
    selectableUoms: getSelectableUomsByTypes(state, {types: ['weight']}),
    uom
  };
})(FormWrapper);
