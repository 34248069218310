/* eslint-disable import/no-named-as-default */
import React from 'react';
import { Route } from 'react-router';
import get from 'lodash.get';
import * as p from '../constants/permissions';
import * as facilityTypes from '../constants/facilityTypes';
import * as applicationModes from '../constants/applicationModes';
import {
  FacilityHasAccess,
  AccessByPredicate,
  buildRouteAccessibilityObject,
  AccessByFeatureEnabled,
  AccessByFeatureEnabledWithFailureComponent,
  AccessByFeatureAndModuleEnabled,
  HomeDeliveryWorkflowEnabled,
  HomeDeliveryManifestGenerationEnabled
} from '../util/auth';
import {NOT} from '../util/predicates';
import {
  getModeAndFacilityType,
  facilityCanPackage,
  facilityHasModules,
  areNeedlesInHaystack,
  areNeedlesNotInHaystack,
  isCanadianDispensary,
  labResultsHasEditPermission,
  labResultsHasCreatePermission,
  inventorySyncPermission,
  isFeatureEnabledProductMasters,
} from '../selectors/accessSelectors';
import {
  getActiveFacility,
  isActiveFacilityDispensary,
  isNotRegulatorFacilitySelector,
  isRegulatorFacilitySelector,
  isLeafUtahRegulatorFacility
} from '../selectors/facilitiesSelectors';
import {getIntegrationState, isIncomingTransferMappingEnabled} from '../selectors/integration/integrationSelectors';
import {isWasteDisposalEnabledSelector} from '../selectors/compliance/waste/wasteComplianceSelectors';
import {
  isSalesSummaryReportFeatureEnabled,
  isShowBiPlatformReportsFeatureEnabled,
  isRegulationConsoleUserApprovalFeatureEnabled, isNewPackagingJobsFeatureEnabled,
} from '../selectors/featureToggles';
import {isMappingRequiredForSupply} from '../selectors/supplySelectors';
import ComponentByModeWrapper from '../components/common/ComponentByModeWrapper';
import ProductReservations from '../components/reservations/ProductReservations';

//CultivationRoutes
import PlantPage from '../components/plants/PlantPage';
import CreatePlantPage from '../components/plants/create/CreatePlantPage';
import ModifyPlantPage from '../components/plants/modify/ModifyPlantPage';
import HarvestsPage from '../components/harvests/HarvestsPage';
import HarvestBatchHistoryPage from '../components/harvests/history/HarvestBatchHistoryPage';
import HarvestBatchSplitPage from '../components/harvests/split/HarvestBatchSplitPage';
import CreatePlantCostingPage from '../components/plants/CreatePlantCostingPage';
import ModifyPackagesPage from '../components/packages/modify/ModifyPackagesPage';
import DestroyPackagesPage from '../components/packages/destroy/DestroyPackagesPage';

import CompletePackagePage from '../components/packages/CompletePackagePage';
import CompleteEachPackagesPage from '../components/packages/package-each/CompleteEachPackagesPage';
import CompleteSeedPackagesPage from '../components/packages/package-seeds/CompleteSeedPackagesPage';
import DestroyPlantPage from '../components/plants/destroy/DestroyPlantPage';
import FeedingSchedulesPage from '../components/feeding-schedules/FeedingSchedulesPage';
import CreateFeedingScheduleFormPage from '../components/feeding-schedules/create/CreateFeedingScheduleFormPage';
import ModifyFeedingSchedulePage from '../components/feeding-schedules/modify/ModifyFeedingSchedulePage';
import ProductsPage from '../components/products/ProductsPage';
import ProductMastersPage from '../components/product-masters/ProductMastersPage';
import ProductHistoryPage from '../components/products/history/ProductHistoryPage';
import SetupStorageLocationsPage from '../components/storage-locations/SetupStorageLocationsPage';
import SetupCultivationLocationsPage from '../components/cultivation-locations/SetupCultivationLocationsPage';
import ModifyHarvestBatchPage from '../components/harvests/modify/ModifyHarvestBatchPage';
import CreateHarvestPackagesPage from '../components/harvests/create-package/CreateHarvestPackagesPage';
import CreateHarvestPlantsPage from '../components/plants/harvest/HarvestPlantsFormPage';
import StartPackagingPage from '../components/packages/StartPackagingPage';
import IngredientsPage from '../components/ingredients/IngredientsPage';
import CreateIngredientsFormPage from '../components/ingredients/create/CreateIngredientsFormPage';
import ModifyIngredientsFormPage from '../components/ingredients/modify/ModifyIngredientsFormPage';
import PartnerListingPage from '../components/partners/PartnerListingPage';
import PartnerEditorPage from '../components/partners/PartnerEditorPage';
import ConnectsRequestPage from '../components/partners/connects/requests/ConnectsRequestPage';
import ConnectsProductMappingPage from '../components/partners/connects/product-mapping/ConnectsProductMappingPage';
import StrainsPage from '../components/strains/StrainsPage';
import ModifyStrainsPage from '../components/strains/ModifyStrainsPage';
import StrainsListingPage from '../components/strains/StrainsListingPage';
import FinishedProductPage from '../components/finished-products/FinishedProductPage';
import CultivationIngredientsInventoryPage from '../components/finished-products/CultivationIngredientsInventoryPage';
import PackageHistoryPage from '../components/packages/history/PackageHistoryPage';
import ItemHistoryPage from '../components/packages/history/ItemHistoryPage';
//import RetailProductPage from '../components/finished-products/RetailProductPage';
import EIInventoryPage from '../components/finished-products/EIInventoryPage';
import EIIngredientInventoryPage from '../components/finished-products/EIIngredientInventoryPage';
import CreateContainerPage from '../components/containers/create/CreateContainerPage';
import ViewTestResultsPage from '../components/test-results/view/ViewTestResultsPage';
import ModifyTestResultsPage from '../components/test-results/modify/ModifyTestResultsPage';
import AddTestResultsPage from '../components/test-results/add/AddTestResultsPage';
import TestResultsPage from '../components/test-results/TestResultsPage';
import PlantHistoryPage from '../components/plants/history/PlantHistoryPage';
import PlantSplitPage from '../components/plants/split/PlantSplitPage';

// Service first components (temporary)
import ServiceFirstFinishedProductPage from '../components/finished-products/ServiceFirstFinishedProductPage';
import ServiceFirstEIInventoryPage from '../components/finished-products/ServiceFirstEIInventoryPage';
import ServiceFirstEIIngredientInventoryPage from '../components/finished-products/ServiceFirstEIIngredientInventoryPage';
import ServiceFirstCultivationIngredientsInventoryPage from '../components/finished-products/ServiceFirstCultivationIngredientsInventoryPage';

//Admin Routes
import UserRolesPage from '../components/users/roles/UserRolesPage';
import CreateUserAccountPage from '../components/users/CreateUserAccountPage';
import ModifyUserAccountPage from '../components/users/ModifyUserAccountPage';
import UserListPage from '../components/users/UserListPage';
import CreateOrganizationPage from '../components/partners/CreateOrganizationPage';
import LinkPage from '../components/landing/LinkPage';
import InternalTransfersSettingPage from '../components/settings/internal-transfers/InternalTransfersSettingPage';
import UomConversionFactorPage from '../components/settings/uom/UomConversionFactorPage';
import InternationalOperationsPage from '../components/settings/international-operations/InternationalOperationsPage';

// import FacilitiesPage from '../components/facilities/FacilitiesPage';
import FacilitySetupPage from '../components/facilities/setup/FacilitySetupPage';
import AdvancedSettingsPage from '../components/facilities/setup/AdvancedSettingsPage';
import AlertsSettingsPage from '../components/alerts/AlertsSettingsPage';
import FacilityGroupsPage from '../components/settings/facility-groups/FacilityGroupsPage';

//Inventory Routes
import MassModifyProductPage from '../components/products/mass-modify/MassModifyProductPage';
import PreviewPage from '../components/products/mass-modify/PreviewPage';
import CreateProductPage from '../components/products/create/CreateProductPage';
import ModifyProductPage from '../components/products/modify/ModifyProductPage';
import ProductMasterPage from '../components/product-masters/product-master/ProductMasterPage';
import PurchaseOrderPageListing from '../components/transfers/purchase-orders/PurchaseOrderListing'; // eslint-disable-line import/no-named-as-default
import PurchaseOrderHistoryPage from '../components/transfers/purchase-orders/history/PurchaseOrderHistoryPage';
import PurchaseOrderPage from '../components/transfers/purchase-orders/PurchaseOrderPage';
import CreateSalesOrderPage from '../components/transfers/sales-orders/create/CreateSalesOrderPage';
import ModifySalesOrderPage from '../components/transfers/sales-orders/modify/ModifySalesOrderPage';
import SalesOrderPage from '../components/transfers/sales-orders/SalesOrderListing';
import CreateTransferPage from '../components/transfers/create/CreateTransferPage';
import ModifyTransferPage from '../components/transfers/modify/ModifyTransferPage';
import TransfersPage from '../components/transfers/TransfersPage';
import CategoryManagementPage from '../components/categories/CategoryManagementPage';
import InventoryReceiptListingPage from '../components/inventory-receipts/InventoryReceiptListingPage';
import AdjustReconciliationsPage from '../components/reconciliations/adjust/AdjustReconciliationsPage';
import SplitPackagePage from '../components/packages/split/SplitPackagePage';
import CreatePropagationMaterialPage from '../components/packages/propagation-material/CreatePropagationMaterialPage';
import ProductManagementPage from '../components/products/products-management/ProductManagementPage';

import FillPurchaseOrderPage from '../components/supply-chain/transfers-in/fill-purchase-order/FillPurchaseOrderPage';

//Retail Routes
import ProductDetailPage from '../components/products/product-details/ProductDetailPage';
import CreditCardsSettingsPage from '../components/credit-cards/CreditCardsSettingsPage';
import CreatePhysicianPage from '../components/physicians/create/CreatePhysicianPage';
import ModifyPhysicianPage from '../components/physicians/modify/ModifyPhysicianPage';
import PhysiciansPage from '../components/physicians/PhysiciansPage';
import ImportOrdersPage from '../components/retail/import/ImportOrdersPage';
import ImportOrdersListPage from '../components/retail/import/ImportOrdersListPage';
import ImportOrdersViewTable from '../components/retail/import/ImportOrdersViewTable';
import DeliveryOrderPage from '../components/delivery-orders/DeliveryOrderPage';
import ModifyDeliveryOrderPage from '../components/delivery-orders/modify/ModifyDeliveryOrderPage';
import DeliveryOrderSetupPage from '../components/delivery-orders/setup/DeliveryOrderSetupPage';
import GenerateManifestPage from '../components/delivery-orders/GenerateManifestPage';

// Courier Routes
import CourierDeliveryOrderPage from '../components/delivery-orders/CourierDeliveryOrderPage';

//Accounting routes
import AccountingSettingsPage from '../components/accounting/AccountingSettingsPage';

//import ProductsPage from '../components/products/ProductsPage';
import QueuePage from '../components/customers/QueuePage';
import CartPage from '../components/cart/CartPage';
import ProductMenuPage from '../components/products/product-menu/ProductMenuPage';
import PaymentPage from '../components/cart/PaymentPage';
import QuickMoveProducts from '../components/products/QuickMoveProducts';
import CustomerPage from '../components/customers/CustomerPage';
import ServiceFirstCustomerPage from '../components/customers/ServiceFirstCustomerPage';
import CustomerHistoryPage from '../components/customers/CustomerHistoryPage';
import OrderView from '../components/orders/order/view/OrderView';
import CreateCustomerPage from '../components/customers/create-edit/CreateCustomerPage';
import ModifyCustomerPage from '../components/customers/create-edit/ModifyCustomerPage';
import CreateRegisterPage from '../components/registers/CreateRegisterPage';
import ModifyRegisterPage from '../components/registers/ModifyRegisterPage';
import RegisterPage from '../components/registers/RegisterPage';
import RegisterHistoryPage from '../components/registers/RegisterHistoryPage';
import TransferCashPage from '../components/registers/transfer-cash/TransferCashPage';
import TaxPage from '../components/taxes/TaxPage';
import CreateTaxPage from '../components/taxes/create/CreateTaxPage';
import ModifyTaxPage from '../components/taxes/modify/ModifyTaxPage';
import TaxProfileEditorPage from '../components/taxes/profiles/editor/TaxProfileEditorPage';
import TaxProfilesPage from '../components/taxes/TaxProfilesPage';
import ReorderTaxProfilesPage from '../components/taxes/reorder/ReorderTaxProfilesPage';
import SupplyChainPage from '../components/supply-chain/SupplyChainPage';
import SupplyChainMappingPage from '../components/supply-chain/mapping/SupplyChainMappingPage';
import {default as SupplyChainMappingPageMetrc} from '../components/supply-chain/mapping/metrc/SupplyChainMappingPage';
import {default as InventoryReceiptPageMetrc} from '../components/supply-chain/receipt/metrc/InventoryReceiptPage';
import MetrcVendorProductMappingPage from '../components/supply-chain/mapping/metrc/MetrcVendorProductMappingPage';

import CustomerOrderHistoryPage from '../components/orders/customer/CustomerPage';
import PatientCompliancePage from '../components/retail/patient-settings/PatientCompliancePage';
import ReceiptPage from '../components/orders/order/receipt/ReceiptPage';
import CheckinCustomerPage from '../components/customers/checkin/CheckinCustomerPage';
import CheckinLeafPAPage from '../components/customers/checkin/leaf-pa/CheckinLeafPAPage';
import CheckinCureApiPage from '../components/customers/checkin/cure-api/CheckinCureApiPage';

// Manufacturing Routes
import AssemblyListingPage from '../components/ei/assemblies/AssemblyListingPage';
import CreateAssemblyPage from '../components/ei/assemblies/create/CreateAssemblyPage';
import ModifyAssemblyPage from '../components/ei/assemblies/modify/ModifyAssemblyPage';
import PreferredMixesListingPage from '../components/ei/preferred-mixes/PreferredMixesListingPage';
import CreatePreferredMixPage from '../components/ei/preferred-mixes/create/CreatePreferredMixPage';
import ModifyPreferredMixPage from '../components/ei/preferred-mixes/modify/ModifyPreferredMixPage';
import StartProcessingPage from '../components/ei/processing/start/StartProcessingPage';
import CompleteProcessingPage from '../components/ei/processing/complete/CompleteProcessingPage';
import ActiveProcessingPage from '../components/ei/active-listings/ActiveProcessingPage';
import CreateProcessingTypesPage from '../components/ei/processing-types/create/CreateProcessingTypePage';
import ModifyProcessingTypesPage from '../components/ei/processing-types/modify/ModifyProcessingTypePage';
import ProcessingTypesListingPage from '../components/ei/processing-types/ProcessingTypesListingPage';
import StartInfusionPage from '../components/ei/infusions/start/StartInfusionPage';
import CompleteInfusionPage from '../components/ei/infusions/complete/CompleteInfusionPage';
import ActiveInfusionsPage from '../components/ei/active-listings/ActiveInfusionsPage';
import PackagingJobsListingPage from '../components/packaging_jobs/PackagingJobsListingPage';

//Wireframe Routes
import PricingWeightsPage from '../components/wireframes/cultivation/pricing-weights/PricingWeightsPage';
import MergeOutNetworkPage from '../components/wireframes/partners/MergeOutNetworkPage';
import ProductInfoModificationPage from '../components/wireframes/products/ProductInfoModificationPage';
import CreateSuppliesPage from '../components/wireframes/retail/supplies/CreateSuppliesPage';
import SuppliesListingPage from '../components/wireframes/retail/supplies/SuppliesListingPage';
import AlertSettingsPage from '../components/wireframes/cultivation/alert-settings/AlertSettingsPage';
import AutoPricePage from '../components/auto-price/AutoPricePage';
import RewardSetupPage from '../components/wireframes/retail/coupons/setup/RewardSetupPage';

import RewardsPage from '../components/wireframes/retail/rewards/RewardsPage';
import RewardsManager from '../components/retail/rewards/RewardsManager';
import PricingGroupListingPage from '../components/retail/pricing-groups/PricingGroupListingPage';
import CreatePricingGroupPage from '../components/retail/pricing-groups/create/CreatePricingGroupPage';
import ModifyPricingGroupPage from '../components/retail/pricing-groups/modify/ModifyPricingGroupPage';
import PricingMultiplierListingPage from '../components/retail/pricing-multipliers/PricingMultiplierListingPage';
import PricingMultiplierPage from '../components/retail/pricing-multipliers/PricingMultiplierPage';
import CustomerGroupPage from '../components/retail/customer-groups/CustomerGroupPage';
import MinimumDaysSupplyPage from '../components/retail/minimum-days-supply/MinimumDaysSupplyPage';
import SalesSettingPage from '../components/sales-settings/SalesSettingPage';
import ReceiptSettingsPage from '../components/receipt-settings/ReceiptSettingsPage';
import CreateEquipmentPage from '../components/ei/equipment/create/CreateEquipmentPage';
import ModifyEquipmentPage from '../components/ei/equipment/modify/ModifyEquipmentPage';
import EquipmentListingPage from '../components/ei/equipment/EquipmentListingPage';
import TransactionReportPage from '../components/retail/sales-reports/transaction-report/TransactionReportPage';
import ClosingReportPage from '../components/retail/sales-reports/closing-report/ClosingReportPage';
import CashOnHandReportPage from '../components/retail/sales-reports/cash-on-hand-report/CashOnHandReportPage';
import InventoryReportPage from '../components/retail/sales-reports/inventory-report/InventoryReportPage';
import InventoryTransactionsReportPage
  from '../components/retail/sales-reports/inventory-transactions-report/InventoryTransactionsReportPage';
import ProductSalesReportPage from '../components/retail/sales-reports/products-report/ProductSalesReportPage';
import MetrcSalesReportPage from '../components/retail/sales-reports/metrc-sales-report/MetrcSalesReportPage';
import RegisterReconciliationPage from '../components/retail/register-reconciliation/RegisterReconciliationPage';
import ManageClosingPage from '../components/retail/ManageClosingPage';
import SalesReportsLandingPage from '../components/retail/SalesReportsLandingPage';
import SalesReportPage from '../components/retail/SalesReportPage';
import MonthlyReportExport from '../components/retail/sales-reports/monthly-exports/MonthlyReportExport';
import CouponListingPage from '../components/retail/coupons/CouponListingPage';
import CreateCouponPage from '../components/retail/coupons/create/CreateCouponPage';
import ModifyCouponPage from '../components/retail/coupons/modify/ModifyCouponPage';
import ProductListsListingPage from '../components/retail/product-lists/ProductListsListingPage';
import ProductListPage from '../components/retail/product-lists/ProductListPage';
import SetupSalesLocationsFormPage from '../components/retail/sales-locations/SetupSalesLocationsFormPage';
import SelectPackagesFacilityPage from '../components/prepack-weights/SelectPackagesFacilityPage';
import PlantComplianceSettingsPage from '../components/settings/plant-compliance/PlantComplianceSettingsPage';
import PackagePlantsPage from '../components/plants/package/PackagePlantsPage';
import PackageMotherPlantsPage from '../components/plants/package-mother/PackageMotherPlantsPage';
import LabelStatementsPage from '../components/labels/statements/LabelStatementsPage';
import LabelsSettingsPage from '../components/labels/settings/LabelsSettingsPage';
import LabelsCustomListPage from '../components/labels/custom/LabelsCustomListPage';
import LabelsCustomEditor from '../components/labels/custom/LabelsCustomEditor';
import LabelsManagementPage from '../components/labels/manager/LabelsManagementPage';
import LabelEditorPage from '../components/labels/manager/LabelEditorPage';
import PrintServersPage from '../components/print-services/servers/PrintServersPage';
import InventoryComplianceSettingsPage from '../components/settings/inventory/InventoryComplianceSettingsPage';
import WasteCompliancePage from '../components/settings/waste-compliance/WasteCompliancePage';
import VehicleListingPage from '../components/vehicles/VehicleListingPage';
import CreateVehiclePage from '../components/vehicles/CreateVehiclePage';
import ModifyVehiclePage from '../components/vehicles/ModifyVehiclePage';
import DriversListingPage from '../components/drivers/DriversListingPage';
import CreateDriverPage from '../components/drivers/CreateDriverPage';
import ModifyDriverPage from '../components/drivers/ModifyDriverPage';
import TestingCompliancePage from '../components/wireframes/cultivation/testing-compliance/TestingCompliancePage';
import ScanInputTestPage from '../components/scan-input/ScanInputTestPage';
import AccountingExportPage from '../components/accounting/AccountingExportPage';
import PricingClassesListingPage from '../components/retail/pricing-classes/PricingClassesListingPage';
import CreatePricingClassPage from '../components/retail/pricing-classes/create/CreatePricingClassPage';
import ModifyPricingClassPage from '../components/retail/pricing-classes/modify/ModifyPricingClassPage';

import TestLabels from '../components/testing-components/Labels';

import ServiceFirstBenchmark from '../components/system/ServiceFirstBenchmark';

// Notification Routes
import NotificationPage from '../components/notifications/NotificationPage';

//Integration Routes
import TransactionListingPage from '../components/integration/api-transactions/TransactionListingPage';
import TransactionDetailsPage from '../components/integration/api-transactions/TransactionDetailsPage';
import TrackingIdsPage from '../components/integration/tracking/TrackingIdsPage';
import ReceiveTrackingIdsPage from '../components/integration/tracking/ReceiveTrackingIdsPage';
import IntegrationPage from '../components/integration/IntegrationPage';
import SalesExportPage from '../components/integration/sales-export/SalesExportPage';
import IntegrationSettingsPage from '../components/settings/integration/IntegrationSettingsPage';
import ActivateDeactivateTrackingIdsPage from '../components/integration/tracking/ActivateDeactivateTrackingIdsPage';
import SharedFacilityGroupsPage from '../components/settings/shared-facility-groups/SharedFacilityGroupsPage';
import CreateSharedFacilityGroupPage from '../components/settings/shared-facility-groups/CreateSharedFacilityGroupPage';
import ModifySharedFacilityGroupPage from '../components/settings/shared-facility-groups/ModifySharedFacilityGroupPage';

// Third Party Integrations
import ThirdPartyIntegrationsListingPage from '../components/settings/third-party-integrations/ThirdPartyIntegrationsListingPage';
import ManageThirdPartyIntegrationsPage from '../components/settings/third-party-integrations/manage/ManageThirdPartyIntegrationsPage';

// Closed-Loop Admin Module Routes
import ManageQuarantinedInventoryPage from '../components/closed-loop-admin/quarantined-inventory/ManageQuarantinedInventoryPage';
import FacilitiesListingPage from '../components/closed-loop-admin/FacilitiesListingPage';
import FacilityInitialInventoryPage from '../components/closed-loop-admin/FacilityInitialInventoryPage';
import RegulatedReceiptsListingPage from '../components/closed-loop-admin/inventory-receipts/RegulatedReceiptsListingPage';
import RegulatedUsersListingPage from '../components/closed-loop-admin/regulated-users/RegulatedUsersListingPage';
import MmuLimitsPage from '../components/closed-loop-admin/mmu-limits/MmuLimitsPage';

import SetupMode from '../components/system/SetupMode';
import {CULTIVATION, EXTRACTION, INFUSION, RETAIL, DISTRIBUTION, CLOSED_LOOP_REGULATOR_ACCESS
} from '../constants/moduleKeys';
import PlatformSupplyChainMappingPage from '../components/supply-chain/mapping/platform/PlatformSupplyChainMappingPage';
import ModalityListingPage from '../components/settings/modality/ModalityListingPage';
import ManageCuposPage from '../components/settings/cupos/ManageCuposPage';
import CreateCupoPage from '../components/settings/cupos/create/CreateCupoPage';
import ModifyCupoPage from '../components/settings/cupos/modify/ModifyCupoPage';
import ManageCupoSettingsPage from '../components/settings/cupos/cupo-settings/ManageCupoSettingsPage';
import CheckinPageComponentByModeWrapper from '../components/common/CheckinPageComponentWrapper';
import CSVReportPage from '../components/reporting/csv/CSVReportPage';
import AnalyticsListingPage from '../components/reporting/AnalyticsListingPage';
import AnalyticsViewPage from  '../components/reporting/AnalyticsViewPage';
import MerchantConfigurationPage from '../components/settings/merchant-configuration/MerchantConfigurationPage';
import MerchantProfilePage from '../components/settings/merchant-configuration/MerchantProfilePage';
import MerchantProfileManagePage from '../components/settings/merchant-configuration/MerchantProfileManagePage';
import MerchantTerminalPage from '../components/settings/merchant-configuration/MerchantTerminalPage';
import RegisterActivityReport from '../components/retail/sales-reports/closing-report/RegisterActivityReport';
import {isMetrcIntegrator} from '../selectors/integration/metrcSelectors';
import {isNotUtahSelector} from '../selectors/integration/utahSelectors';
import isRetailFacility from '../selectors/facility/isRetailFacility';
import RegulatedUser from '../components/closed-loop-admin/regulated-users/RegulatedUser';
import InventoryAuditPage from '../components/inventory-audit/InventoryAuditPage';
import InventorySyncPage from '../components/inventory-sync/InventorySyncPage';
import PackagingJobPage from '../components/packaging_jobs/packaging_job/PackagingJobPage';


/**
 * NOTE: Enable this function for https://akerna.atlassian.net/browse/REBEL-2043
 *
 * Evaluates accessibility for the specific .../queue/medical and .../queue/recreational routes
 *
 * @param enabledModules
 * @param currentFacility
 * @returns {boolean}
 */
// const evaluateAccessibilityForQueueSpecificity = (enabledModules, currentFacility) => {
//   // NOTE that the /medical routes (using this function) also validate with the 'recreational' logic below because we're only showing the specific .../medical and .../recreational routes if the facility has both, otherwise these path parts aren't used
//   return enabledModules.includes(RETAIL) && currentFacility.retail_type === 'recreational';
// };

//Status is one of [wireframe, hardcoded, production]
const routeDefinitions = {
  // Examples of Premium content (linking to ProductMasterPage for demo purposes)
  // /premium_test_one will always show and redirect to /premium-content if user (organization) doesn't have access to Premium Content
  // /premium_test_two will only show if user (organization) has access to Premium Content. If link is attempted to open directly then user is redirected to /premium-content
  // premium: {
  //   premiumTest: {
  //     path: '/premium_test_one',
  //     component: AccessByPredicate(getActiveFacility, hasPremiumAccess, '/premium-content')(ProductMasterPage),
  //     permissions: [],
  //     status: 'Production'
  //   },
  //   premiumTestTwo: {
  //     path: '/premium_test_two',
  //     component: AccessByPredicate(getActiveFacility, hasPremiumAccess, '/premium-content')(ProductMasterPage),
  //     accessibility: {selectors: [hasPremiumContentSubscription]},
  //     permissions: [],
  //     status: 'Production'
  //   },
  // },
  admin: {
    serviceFirstBenchmark: {
      path: '/service-first-benchmark',
      component: ServiceFirstBenchmark,
      permissions: ['p.super_admin_only'],
      status: 'wireframe',
    },
    setupMode: {
      path: '/setup-mode',
      component: SetupMode,
      permissions: [],
      status: 'production'
    },
    scanTest: {
      path: '/scan-test',
      component: ScanInputTestPage,
      permissions: [
        /*p.super_admin_only*/
      ],
      status: 'production'
    },
    links: {
      path: '/links',
      component: LinkPage,
      permissions: [
        /*p.super_admin_only*/
      ],
      status: 'production'
    },
    setup: {
      path: '/setup',
      component: FacilitySetupPage,
      permissions: [
        p.manage_system_configuration,
        p.manage_facility_users,
        p.manage_facility,
        p.manage_organization,
        p.manage_partners,
        p.view_partners,
        p.manage_roles,
        p.manage_compliance_settings,
        p.manage_cultivation_compliance,
        p.manage_retail_compliance,
        p.manage_manufacturing_compliance,
        p.manage_alerts,
        p.manage_custom_fields,
        p.manage_organization_strains,
        p.manage_facility_strains,
        p.manage_feeding_schedules,
        p.manage_cultivation_locations,
        p.manage_package_sizes,
        p.manage_ingredients,
        p.manage_product_subcategories,
        p.manage_storage_locations,
        p.manage_credit_card_integration
      ],
      status: 'production'
    },
    advanced_setup: {
      path: '/setup/advanced',
      component: AdvancedSettingsPage,
      permissions: [
        p.manage_organization,
        p.manage_compliance_settings,
        p.manage_cultivation_compliance,
        p.manage_retail_compliance,
        p.manage_manufacturing_compliance
      ],
      status: 'production'
    },
    merchant_configuration: {
      path: '/setup/merchant-configuration',
      component: MerchantConfigurationPage,
      permissions: [p.manage_merchant_accounts],
      status: 'production'
    },
    merchant_profile_create: {
      path: '/setup/merchant-profile/create',
      component: MerchantProfilePage,
      permissions: [p.manage_merchant_accounts],
      status: 'production'
    },
    merchant_profile_modify: {
      path: `/setup/merchant-profile/modify/:id`,
      component: MerchantProfilePage,
      permissions: [p.manage_merchant_accounts],
      status: 'production'
    },
    merchant_terminal_create: {
      path: '/setup/merchant-terminal/create',
      component: MerchantTerminalPage,
      permissions: [p.manage_merchant_accounts],
      status: 'production'
    },
    merchant_terminal_modify: {
      path: `/setup/merchant-terminal/modify/:id`,
      component: MerchantTerminalPage,
      permissions: [p.manage_merchant_accounts],
      status: 'production'
    },
    merchant_profile_manage: {
      path: `/setup/merchant-profile/manage/:id`,
      component: MerchantProfileManagePage,
      permissions: [p.manage_merchant_accounts],
      status: 'production'
    },
    alerts: {
      path: '/alerts',
      component: AlertsSettingsPage,
      permissions: [p.manage_alerts],
      status: 'production'
    },
    list_user: {
      path: '/users',
      component: UserListPage,
      permissions: [p.manage_facility_users, p.manage_users],
      status: 'production'
    },
    create_user: {
      path: '/users/create',
      component: CreateUserAccountPage,
      permissions: [p.manage_facility_users],
      status: 'production'
    },
    modify_user: {
      path: '/users/modify/:id',
      component: ModifyUserAccountPage,
      permissions: [p.manage_facility_users],
      status: 'production'
    },
    user_roles: {
      path: '/users/roles',
      component: UserRolesPage,
      permissions: [p.manage_roles],
      status: 'production'
    },
    user_status: {
      path: '/users/:status',
      component: UserListPage,
      permissions: [p.manage_facility_users, p.manage_users],
      status: 'production'
    },
    create_organization: {
      path: '/organization/create',
      component: CreateOrganizationPage,
      permissions: [p.manage_organization],
      status: 'production'
    },
    sales_export: {
      path: '/integration/sales-export',
      component: FacilityHasAccess([facilityTypes.dispensary])(SalesExportPage),
      permissions: [p.manage_state_integrations],
      accessibility: {
        selectors: [isMetrcIntegrator, isActiveFacilityDispensary]
      },
      status: 'production'
    },
    integration_page: {
      path: '/integration',
      component: IntegrationPage,
      permissions: [p.manage_state_integrations, p.manage_tracking, p.manage_third_party_integrations],
      status: 'production'
    },
    integration_settings: {
      path: '/setup/integration',
      component: AccessByPredicate(getIntegrationState, (integrationState) => {
        //Check settings, if allowAccessToStateIntegratorPage is false, don't allow access
        const {allowAccessToStateIntegratorPage} = integrationState;
        return allowAccessToStateIntegratorPage;
      }, '/no-facility-permission')(IntegrationSettingsPage),

      permissions: [p.manage_state_integrations],
      status: 'production'
    },
    international_operations_settings: {
      path: '/settings/international',
      component: AccessByFeatureAndModuleEnabled('feature_spain_parity', 'INTERNATIONAL_OPERATIONS')(
        InternationalOperationsPage
      ),
      permissions: [p.manage_international_settings],
      status: 'production'
    },
    third_party_integrations_listing: {
      path: '/setup/third-party-integrations',
      component: ThirdPartyIntegrationsListingPage,
      permissions: [p.manage_third_party_integrations],
      status: 'production'
    },
    manage_third_party_integration: {
      path: '/setup/third-party-integrations/:integration',
      component: ManageThirdPartyIntegrationsPage,
      permissions: [p.manage_third_party_integrations],
      status: 'production'
    },
    list_facility_groups: {
      path: '/facility-groups',
      component: FacilityGroupsPage,
      permissions: [p.manage_organization],
      status: 'production'
    },
    edit_facility_groups: {
      path: '/facility-groups/:id', // id = 'create' on new
      component: FacilityGroupsPage,
      permissions: [p.manage_organization],
      status: 'production'
    },
    list_facility_groups_sharing: {
      path: '/shared-facility-groups',
      component: SharedFacilityGroupsPage,
      permissions: [p.manage_shared_inventory],
      status: 'production'
    },
    create_shared_facility_group: {
      path: '/shared-facility-groups/create',
      component: CreateSharedFacilityGroupPage,
      //permissions: [p.manage_shared_inventory],
      status: 'production'
    },
    modify_shared_facility_group: {
      path: '/shared-facility-groups/:id',
      component: ModifySharedFacilityGroupPage,
      //permissions: [p.manage_shared_inventory],
      status: 'production'
    },
    csv_reports: {
      path: '/cca',
      status: 'production',
      component: AccessByPredicate(
        getIntegrationState,
        (integrationState) => integrationState.isCca,
        '/no-facility-permission'
      )(CSVReportPage),
    }
  },
  closed_loop_admin: {
    quarantined_inventory: {
      path: '/closed-loop/quarantined-inventory',
      status: 'production',
      component: AccessByPredicate(
        facilityHasModules([CLOSED_LOOP_REGULATOR_ACCESS]),
        areNeedlesInHaystack,
        '/no-facility-permission'
      )(ManageQuarantinedInventoryPage),
      accessibility: buildRouteAccessibilityObject([CLOSED_LOOP_REGULATOR_ACCESS]),
      permissions: [p.manage_quarantined_inventory]
    },
    view_test_results: {
      path: '/closed-loop/test-results/view/:id',
      status: 'production',
      component: AccessByPredicate(
        facilityHasModules([CLOSED_LOOP_REGULATOR_ACCESS]),
        areNeedlesInHaystack,
        '/no-facility-permission'
      )(ViewTestResultsPage),
      permissions: [p.manage_quarantined_inventory]
    },
    facility_listing: {
      path: '/closed-loop/facilities',
      status: 'production',
      accessibility: buildRouteAccessibilityObject([CLOSED_LOOP_REGULATOR_ACCESS]),
      permissions: [p.view_regulated_facility_list],
      component: AccessByPredicate(
        facilityHasModules([CLOSED_LOOP_REGULATOR_ACCESS]),
        areNeedlesInHaystack,
        '/no-facility-permission'
      )(FacilitiesListingPage)
    },
    facility: {
      path: '/closed-loop/facilities/:id',
      status: 'production',
      accessibility: buildRouteAccessibilityObject([CLOSED_LOOP_REGULATOR_ACCESS]),
      permissions: [p.manage_initial_inventory_settings],
      component: AccessByPredicate(
        facilityHasModules([CLOSED_LOOP_REGULATOR_ACCESS]),
        areNeedlesInHaystack,
        '/no-facility-permission'
      )(FacilityInitialInventoryPage)
    },
    regulated_receipts_listing: {
      path: '/closed-loop/receipts(/:tab)',
      status: 'production',
      accessibility: {selectors: [isRegulatorFacilitySelector, isLeafUtahRegulatorFacility]},
      permissions: [p.view_regulated_inventory_receipts_list],
      component: AccessByPredicate(
        isLeafUtahRegulatorFacility,
        (isLeafUtahRegulatorFacility) => isLeafUtahRegulatorFacility === true,
        '/no-facility-permission'
      )(
        AccessByFeatureEnabled('feature_ut_hemp_waste')(RegulatedReceiptsListingPage)
      )
    },
    regulated_users_listing: {
      path: '/closed-loop/users(/:tab)',
      status: 'production',
      accessibility: {selectors: [isRegulatorFacilitySelector, isRegulationConsoleUserApprovalFeatureEnabled]},
      permissions: [p.view_regulated_users_list],
      component: AccessByPredicate(facilityHasModules([CLOSED_LOOP_REGULATOR_ACCESS]), areNeedlesInHaystack, '/no-facility-permission')(
        AccessByFeatureEnabled('feature_regulation_console_user_approval')(
          RegulatedUsersListingPage
        )
      )
    },
    user: {
      path: '/closed-loop/user-details/:id',
      status: 'production',
      accessibility: buildRouteAccessibilityObject([CLOSED_LOOP_REGULATOR_ACCESS]),
      permissions: [p.manage_regulated_users],
      component: RegulatedUser,
    },
    mmu_limits: {
      path: '/closed-loop/mmu-limits',
      status: 'production',
      permissions: [p.manage_facility], // TODO: required new permission view_regulated_mmu_limits
      component: AccessByPredicate(facilityHasModules([CLOSED_LOOP_REGULATOR_ACCESS]), areNeedlesInHaystack, '/no-facility-permission')(
        AccessByFeatureEnabled('feature_leaf_pa_mmu_limits')(
          MmuLimitsPage
        )
      )
    }
  },
  courier: {
    setup_courier_delivery_orders: {
      path: '/courier/delivery-orders/setup',
      component: AccessByFeatureAndModuleEnabled('feature_courier_licensees', 'COURIER_SERVICE')(
        DeliveryOrderSetupPage
      ),
      permissions: [p.manage_courier_delivery_orders],
      status: 'production'
    },
    courier_delivery_order: {
      path: '/courier/delivery-orders(/:status)',
      component: AccessByFeatureAndModuleEnabled('feature_courier_licensees', 'COURIER_SERVICE')(
        CourierDeliveryOrderPage
      ),
      accessibility: buildRouteAccessibilityObject(['COURIER_SERVICE']),
      permissions: [p.manage_courier_delivery_orders],
      status: 'production'
    },
    modify_courier_delivery_order: {
      path: '/courier/delivery-orders/modify/:id',
      component: AccessByFeatureAndModuleEnabled('feature_courier_licensees', 'COURIER_SERVICE')(
        ModifyDeliveryOrderPage
      ),
      accessibility: buildRouteAccessibilityObject(['COURIER_SERVICE']),
      permissions: [p.manage_courier_delivery_orders],
      status: 'production'
    },
    generate_manifest: {
      path: '/courier/generate-manifest',
      component: AccessByFeatureAndModuleEnabled('feature_courier_licensees', 'COURIER_SERVICE')(HomeDeliveryManifestGenerationEnabled(GenerateManifestPage)),
      accessibility: buildRouteAccessibilityObject(['COURIER_SERVICE']),
      permissions: [p.manage_courier_delivery_orders],
      status: 'production'
    }
  },
  cultivation: {
    list_plant: {
      path: '/plants',
      component: PlantPage,
      permissions: [p.view_plants],
      status: 'production'
    },
    create_plant: {
      path: '/plants/create',
      component: CreatePlantPage,
      permissions: [p.create_plants],
      status: 'production',
      blockOnSetup: true
    },
    package_plants: {
      path: '/plants/package',
      component: PackagePlantsPage,
      permissions: [p.process_plants],
      status: 'production',
      blockOnSetup: true
    },
    package_mother_plants: {
      path: '/plants/package-mother-plants',
      component: PackageMotherPlantsPage,
      permissions: [p.process_plants],
      status: 'production',
      blockOnSetup: true
    },
    modify_plant: {
      path: '/plants/modify',
      component: ModifyPlantPage,
      permissions: [p.manage_plants],
      status: 'production',
      blockOnSetup: true
    },
    destroy_plant: {
      path: '/plants/destroy',
      component: DestroyPlantPage,
      permissions: [p.manage_plants],
      status: 'production',
      blockOnSetup: true
    },
    create_plant_harvest: {
      path: '/plants/harvest',
      component: CreateHarvestPlantsPage,
      permissions: [p.process_plants],
      status: 'production',
      blockOnSetup: true
    },
    costing_plant: {
      path: '/plants/create-plant-costing',
      component: CreatePlantCostingPage,
      permissions: [p.manage_plant_costing],
      status: 'hardcoded'
    },
    plant_settings: {
      path: '/plants/compliance-settings',
      component: PlantComplianceSettingsPage,
      permissions: [p.manage_cultivation_compliance],
      status: 'production'
    },
    modality: {
      path: '/settings/modality',
      component: ModalityListingPage,
      permissions: [p.manage_cultivation_compliance],
      status: 'production'
    },
    create_cupo: {
      path: '/settings/cupos/create',
      component: CreateCupoPage,
      permissions: [p.manage_cultivation_compliance],
      status: 'production'
    },
    cupos: {
      path: '/settings/cupos(/:status)',
      component: ManageCuposPage,
      permissions: [p.manage_cultivation_compliance],
      status: 'production'
    },
    modify_cupo: {
      path: '/settings/cupos/modify/:id',
      component: ModifyCupoPage,
      permissions: [p.manage_cultivation_compliance],
      status: 'production'
    },
    manage_cupo_settings: {
      path: '/settings/cupos/:id/modality/:modality_key',
      component: ManageCupoSettingsPage,
      permissions: [p.manage_cultivation_compliance],
      status: 'production'
    },
    list_stage_plant: {
      path: '/plants/:stage',
      component: PlantPage,
      accessibility: buildRouteAccessibilityObject([CULTIVATION]),
      permissions: [p.view_plants],
      status: 'production'
    },
    plant_history: {
      path: '/plants/:id/history',
      component: PlantHistoryPage,
      permissions: [p.view_plant_history],
      status: 'production'
    },
    split_plant: {
      path: '/plants/:id/split',
      component: PlantSplitPage,
      permissions: [p.manage_plants],
      status: 'production',
      blockOnSetup: true
    },
    list_product_masters: {
      path: '/product-masters',
      component: AccessByFeatureEnabled('feature_new_product_master')(ProductMastersPage),
      permissions: [p.view_products],
      status: 'production',
      accessibility: {selectors: [isFeatureEnabledProductMasters]},
    },
    list_products: {
      path: '/products',
      component: ProductsPage,
      permissions: [p.view_products],
      status: 'production'
    },
    modify_package: {
      path: 'packages/modify(/:id)',
      component: ModifyPackagesPage,
      permissions: [p.manage_inventory],
      status: 'production',
      blockOnSetup: true
    },
    product_history: {
      path: 'products/:id/history',
      component: ProductHistoryPage,
      status: 'production'
    },
    modify_packages: {
      path: '/packages/modify',
      component: ModifyPackagesPage,
      permissions: [p.manage_inventory],
      status: 'production',
      blockOnSetup: true
    },
    destroy_packages: {
      path: '/packages/destroy',
      component: DestroyPackagesPage,
      permissions: [p.manage_inventory],
      status: 'production',
      blockOnSetup: true
    },
    prepack_weights_facilities: {
      path: '/prepack-weights/facility',
      component: SelectPackagesFacilityPage,
      permissions: [p.manage_package_sizes],
      status: 'production'
    },
    list_packaging_jobs: {
      link: '/packaging_jobs',
      path: '/packaging_jobs(/:tab)',
      component: AccessByPredicate(getModeAndFacilityType, facilityCanPackage, '/no-facility-permission')(
        AccessByFeatureEnabled('feature_new_packaging_jobs')(PackagingJobsListingPage)
      ),
      permissions: [p.manage_inventory],
      status: 'production',
      accessibility: {selectors: [isNewPackagingJobsFeatureEnabled]},
    },
    modify_packaging_job: {
      path: '/packaging_jobs/packaging_job/:id',
      component: AccessByPredicate(getModeAndFacilityType, facilityCanPackage, '/no-facility-permission')(
        AccessByFeatureEnabled('feature_new_packaging_jobs')(PackagingJobPage)
      ),
      permissions: [p.manage_inventory],
      status: 'production',
      accessibility: {selectors: [isNewPackagingJobsFeatureEnabled]},
    },
    create_packaging_job: {
      path: '/packaging_jobs/packaging_job/new',
      component: AccessByPredicate(getModeAndFacilityType, facilityCanPackage, '/no-facility-permission')(
        AccessByFeatureEnabled('feature_new_packaging_jobs')(PackagingJobPage)
      ),
      permissions: [p.manage_inventory],
      status: 'production',
      accessibility: {selectors: [isNewPackagingJobsFeatureEnabled]},
    },
    complete_seed_package: {
      path: '/packages/complete/seed/:id',
      component: CompleteSeedPackagesPage,
      permissions: [p.manage_inventory],
      status: 'production',
      blockOnSetup: true
    },
    complete_each_package: {
      path: '/packages/complete/each/:id',
      component: CompleteEachPackagesPage,
      permissions: [p.manage_inventory],
      accessibility: buildRouteAccessibilityObject([CULTIVATION], undefined, [applicationModes.metrc]),
      status: 'production',
      blockOnSetup: true
    },
    complete_package: {
      path: '/packages/complete/:id',
      component: AccessByPredicate(getModeAndFacilityType, facilityCanPackage, '/no-facility-permission')(
        CompletePackagePage
      ),
      permissions: [p.manage_inventory],
      status: 'production',
      blockOnSetup: true
    },
    start_package: {
      path: '/packages/start/:id',
      component: AccessByPredicate(getModeAndFacilityType, facilityCanPackage, '/no-facility-permission')(
        StartPackagingPage
      ),
      permissions: [p.manage_inventory],
      status: 'production',
      blockOnSetup: true
    },
    create_feeding_schedule: {
      path: '/feeding-schedules/create',
      component: CreateFeedingScheduleFormPage,
      permissions: [p.manage_feeding_schedules],
      status: 'production'
    },
    modify_feeding_schedule: {
      path: '/feeding-schedules/modify/:id',
      component: ModifyFeedingSchedulePage,
      permissions: [p.manage_feeding_schedules],
      status: 'production'
    },
    list_feeding_schedule: {
      path: '/feeding-schedules/:active',
      component: FeedingSchedulesPage,
      permissions: [p.manage_feeding_schedules],
      status: 'production'
    },
    create_package: {
      path: '/harvests/create-package',
      component: CreateHarvestPackagesPage,
      permissions: [p.manage_harvests],
      status: 'production',
      blockOnSetup: true
    },
    modify_harvest: {
      path: '/harvests/modify',
      component: ModifyHarvestBatchPage,
      permissions: [p.manage_harvests],
      status: 'production',
      blockOnSetup: true
    },
    list_harvest: {
      path: '/harvests(/:active)',
      component: HarvestsPage,
      accessibility: buildRouteAccessibilityObject([CULTIVATION]),
      permissions: [p.view_harvests],
      status: 'production'
    },
    split_harvest: {
      path: '/harvests/:id/split',
      component: HarvestBatchSplitPage,
      permissions: [p.view_harvests, p.manage_harvests, p.view_harvest_history],
      status: 'production',
      blockOnSetup: true
    },
    harvest_batch_history: {
      path: '/harvests/:id/history',
      component: HarvestBatchHistoryPage,
      permissions: [p.view_harvest_history],
      status: 'production'
    },

    setup_cultivation_locations: {
      path: '/cultivation-locations/setup',
      component: SetupCultivationLocationsPage,
      permissions: [p.manage_cultivation_locations],
      status: 'production'
    },
    setup_storage_locations: {
      path: '/storage-locations/setup',
      component: SetupStorageLocationsPage,
      permissions: [p.manage_storage_locations],
      status: 'production'
    },
    create_container: {
      path: '/containers/create',
      component: CreateContainerPage,
      permissions: [p.manage_inventory],
      status: 'production'
    },
    create_partner: {
      path: '/partners/create',
      component: PartnerEditorPage,
      permissions: [p.manage_partners],
      status: 'production'
    },
    update_partner: {
      path: '/partners/:id/modify',
      component: PartnerEditorPage,
      permissions: [p.manage_partners],
      status: 'production'
    },
    list_partners: {
      path: '/partners(/:status)',
      component: PartnerListingPage,
      permissions: [p.view_partners],
      status: 'production'
    },
    partner_connects_request: {
      path: '/partners/connects/requests/:code',
      component: ConnectsRequestPage,
      permissions: [p.view_partners],
      status: 'production'
    },
    partner_connects_respond: {
      path: '/partners/connects/respond/:id',
      component: ConnectsRequestPage,
      permissions: [p.view_partners],
      status: 'production'
    },
    partner_connects_product_mapping: {
      path: '/partners/:id/product_mapping',
      component: ConnectsProductMappingPage,
      permissions: [p.manage_partners],
      status: 'production'
    },
    create_ingredient: {
      path: '/ingredients/create',
      component: CreateIngredientsFormPage,
      permissions: [p.manage_ingredients],
      status: 'production'
    },
    modify_ingredient: {
      path: '/ingredients/:id/modify',
      component: ModifyIngredientsFormPage,
      permissions: [p.manage_ingredients],
      status: 'production'
    },
    list_ingredient: {
      path: '/ingredients/:status(/:category)',
      component: IngredientsPage,
      permissions: [p.manage_ingredients],
      status: 'production'
    },
    modify_strain: {
      path: '/strains/modify',
      component: ModifyStrainsPage,
      permissions: [p.manage_facility_strains],
      status: 'production'
    },
    modify_organization_strain: {
      path: '/strains/organization',
      component: StrainsPage,
      permissions: [p.manage_organization_strains],
      status: 'production',
      params: {
        level: 'organization'
      }
    },
    modify_facility_strain: {
      path: '/strains/facility',
      component: StrainsPage,
      permissions: [p.manage_facility_strains],
      status: 'production',
      params: {
        level: 'facility'
      }
    },
    list_strain: {
      path: '/strains',
      component: StrainsListingPage,
      permissions: [p.manage_organization_strains, p.manage_facility_strains],
      status: 'production'
    },
    inventory_audit: {
      path: '/inventory/audit',
      component: InventoryAuditPage,
      permissions: [],
      status: 'production',
    },
    inventory_sync: {
      path: '/inventory/sync',
      component: AccessByPredicate((state) => {return {hasInventorySyncPermission : inventorySyncPermission(state)};}, (result) => result.hasInventorySyncPermission, '/no-facility-permission')(InventorySyncPage),
      permissions: [p.manage_inventory_sync],
      status: 'production',
      accessibility: {selectors: [inventorySyncPermission]},
    },
    cultivation_ingredients_inventory_list: {
      path: '/inventory/ingredients/:status',
      component: AccessByFeatureEnabledWithFailureComponent('feature_service_first_solr_refactor', CultivationIngredientsInventoryPage)(ServiceFirstCultivationIngredientsInventoryPage),
      permissions: [p.view_inventory, p.manage_inventory],
      accessibility: buildRouteAccessibilityObject([CULTIVATION]),
      status: 'wireframe'
    },
    list_ei_ingredient_inventory: {
      path: '/ei/inventory/ingredients/:status',
      component: AccessByPredicate(facilityHasModules(['MANUFACTURING', 'EXTRACTION', 'INFUSION']), areNeedlesInHaystack, '/inventory')(
        AccessByFeatureEnabledWithFailureComponent('feature_service_first_solr_refactor', EIIngredientInventoryPage)(
          ServiceFirstEIIngredientInventoryPage
        )
      ),
      permissions: [p.view_inventory, p.manage_inventory],
      accessibility: buildRouteAccessibilityObject([EXTRACTION, INFUSION]),
      status: 'production'
    },
    list_ei_inventory_status: {
      path: '/ei/inventory/:status',
      component: AccessByFeatureEnabledWithFailureComponent('feature_service_first_solr_refactor', EIInventoryPage)(
        ServiceFirstEIInventoryPage
      ),
      accessibility: buildRouteAccessibilityObject([EXTRACTION, INFUSION]),
      permissions: [p.view_inventory, p.manage_inventory],
      status: 'production'
    },
    list_ei_inventory: {
      path: '/ei/inventory',
      component: AccessByPredicate(facilityHasModules([EXTRACTION, INFUSION]), areNeedlesInHaystack, '/inventory/active')(
        AccessByFeatureEnabledWithFailureComponent('feature_service_first_solr_refactor', EIInventoryPage)(
          ServiceFirstEIInventoryPage
        )
      ),
      permissions: [p.view_inventory, p.manage_inventory],
      status: 'production'
    },
    list_waste: {
      path: '/inventory/waste#',
      component: AccessByFeatureEnabledWithFailureComponent('feature_service_first_solr_refactor', FinishedProductPage)(ServiceFirstFinishedProductPage),
      permissions: [p.view_inventory, p.manage_inventory],
      accessibility: {selectors: [isWasteDisposalEnabledSelector]},
      status: 'production'
    },
    list_basic_inventory: {
      path: '/inventory(/:status)',
      component: AccessByPredicate(facilityHasModules([CLOSED_LOOP_REGULATOR_ACCESS]), areNeedlesNotInHaystack, '/closed-loop/quarantined-inventory')(
        AccessByFeatureEnabledWithFailureComponent('feature_service_first_solr_refactor', FinishedProductPage)(ServiceFirstFinishedProductPage)
      ),
      accessibility: buildRouteAccessibilityObject(
        [EXTRACTION, INFUSION, CLOSED_LOOP_REGULATOR_ACCESS],
        undefined,
        undefined,
        NOT
      ),
      permissions: [p.view_inventory, p.manage_inventory],
      status: 'production'
    },
    package_history: {
      path: '/packages/:id/history',
      component: PackageHistoryPage,
      permissions: [p.view_inventory_history],
      status: 'production'
    },
    item_history: {
      path: '/items/:id/history',
      component: ItemHistoryPage,
      permissions: [p.view_inventory_history],
      status: 'production'
    },
    plants_add_test_results: {
      path: '/plants/test-results/add',
      component: AddTestResultsPage,
      status: 'production'
    },
    add_test_results: {
      path: '/test-results/add',
      // fight against warnings
      component: AccessByPredicate((state) => {return {hasCreatePermission : labResultsHasCreatePermission(state)};}, (result) => result.hasCreatePermission, '/no-facility-permission')(AddTestResultsPage),
      status: 'production'
    },
    view_test_results: {
      path: '/test-results/view/:id',
      component: ViewTestResultsPage,
      permissions: [p.view_testing, p.manage_testing],
      status: 'production'
    },
    plants_modify_test_results: {
      path: '/plants/test-results/modify/:id',
      // fight against warnings
      component: AccessByPredicate((state) => {return {hasEditPermission : labResultsHasEditPermission(state)};}, (result) => result.hasEditPermission, '/no-facility-permission')(
        ModifyTestResultsPage
      ),
      status: 'production'
    },
    modify_test_results: {
      path: '/test-results/modify/:id',
      // fight against warnings
      component: AccessByPredicate((state) => {return {hasEditPermission : labResultsHasEditPermission(state)};}, (result) => result.hasEditPermission, '/no-facility-permission')(
        ModifyTestResultsPage
      ),
      status: 'production'
    },
    list_test_results: {
      link: '/test-results',
      path: '/test-results(/:tab)',
      component: TestResultsPage,
      permissions: [p.view_testing, p.manage_testing],
      status: 'production'
    },
    list_plant_test_results: {
      path: '/plants/test-results(/:tab)',
      component: TestResultsPage,
      permissions: [p.view_testing, p.manage_testing],
      status: 'production'
    },
    waste_compliance_page: {
      path: '/settings/waste-compliance',
      component: WasteCompliancePage,
      permissions: [p.manage_compliance_settings],
      status: 'production'
    }
  },
  ei: {},
  supplyChain: {
    //@todo move these out of wireframes change paths to the proper version
    vehicle_listing_page: {
      path: '/vehicles',
      component: VehicleListingPage,
      permissions: [p.manage_vehicles],
      status: 'production'
    },
    create_vehicle_page: {
      path: '/vehicles/create',
      component: CreateVehiclePage,
      permissions: [p.manage_vehicles],
      status: 'production'
    },
    modify_vehicle_page: {
      path: '/vehicles/modify/:id',
      component: ModifyVehiclePage,
      permissions: [p.manage_vehicles],
      status: 'production'
    },
    create_driver_page: {
      path: '/driver/create',
      component: CreateDriverPage,
      permissions: [p.manage_drivers],
      status: 'production'
    },
    modify_driver_page: {
      path: '/drivers/modify/:id',
      component: ModifyDriverPage,
      permissions: [p.manage_drivers],
      status: 'production'
    },
    drivers: {
      path: '/drivers(/:status)',
      component: DriversListingPage,
      permissions: [p.manage_drivers],
      status: 'production'
    },
    supply_chain: {
      path: '/supply-chain',
      component: SupplyChainPage,
      accessibility: buildRouteAccessibilityObject([DISTRIBUTION]),
      permissions: [
        p.manage_inventory_transfers,
        p.manage_purchase_orders,
        p.manage_sales_orders,
        p.manage_inventory_receipts
      ],
      status: 'production'
    },
    supply_chain_mapping_metrc: {
      path: '/supply-chain-mapping/metrc',
      component: AccessByFeatureEnabled('feature_new_product_master')(SupplyChainMappingPageMetrc),
      status: 'production',
      permissions: [
        p.manage_inventory_transfers,
        p.manage_purchase_orders,
        p.manage_sales_orders,
        p.manage_inventory_receipts
      ],
      accessibility: {selectors: [isMetrcIntegrator, isFeatureEnabledProductMasters]},
    },
    supply_chain_mapping: {
      path: '/supply-chain-mapping/:mode',
      component: ComponentByModeWrapper,
      permissions: [
        p.manage_inventory_transfers,
        p.manage_purchase_orders,
        p.manage_sales_orders,
        p.manage_inventory_receipts
      ],
      accessibility: {
        evaluate: (enabledModules, currentFacility, integrationState, featureToggles, state) => {
          return isMappingRequiredForSupply(state) ||
            isIncomingTransferMappingEnabled(state) ||
            (currentFacility.type === 'lab' && (currentFacility.has_leaf_pa_config_pack_and_closed_loop || currentFacility.has_leaf_utah_config_pack_and_closed_loop));
        }
      },
      status: 'production',
      components: {
        [applicationModes.leafPA]: PlatformSupplyChainMappingPage,
        [applicationModes.leafWA]: PlatformSupplyChainMappingPage,
        [applicationModes.leaf]: PlatformSupplyChainMappingPage,
        [applicationModes.metrcMd]: SupplyChainMappingPage,
        [applicationModes.metrcOregonRec]: SupplyChainMappingPage,
        [applicationModes.metrc]: SupplyChainMappingPage,
        [applicationModes.biotrack]: SupplyChainMappingPage,
        [applicationModes.normal]: PlatformSupplyChainMappingPage
      },
      blockOnSetup: true
    },
    metrc_vendor_product_mapping: {
      path: '/metrc-vendor-product-mapping',
      component: MetrcVendorProductMappingPage
    },
    create_purchase_order: {
      path: '/purchase-orders/create',
      component: PurchaseOrderPage,
      permissions: [p.manage_purchase_orders],
      status: 'production',
      blockOnSetup: true
    },
    modify_purchase_order: {
      path: '/purchase-orders/modify/:id',
      component: PurchaseOrderPage,
      permissions: [p.manage_purchase_orders],
      status: 'production',
      blockOnSetup: true
    },
    purchase_order_history: {
      path: '/purchase-orders/:id/history',
      component: PurchaseOrderHistoryPage,
      permissions: [p.manage_purchase_orders],
      status: 'production'
    },
    list_purchase_order_with_medicated_status: {
      path: '/purchase-orders/:status/:medicatedStatus',
      component: PurchaseOrderPageListing,
      permissions: [p.manage_purchase_orders],
      accessibility: {selectors: [isIncomingTransferMappingEnabled]},
      status: 'production'
    },
    list_purchase_order: {
      link: '/purchase-orders/active',
      path: '/purchase-orders(/:status)',
      component: PurchaseOrderPageListing,
      permissions: [p.manage_purchase_orders],
      status: 'production'
    },
    create_sales_order: {
      path: '/sales-orders/create',
      component: CreateSalesOrderPage,
      permissions: [p.manage_sales_orders],
      status: 'production',
      blockOnSetup: true
    },
    modify_sales_order: {
      path: '/sales-orders/modify/:id',
      component: ModifySalesOrderPage,
      permissions: [p.manage_sales_orders],
      status: 'production',
      blockOnSetup: true
    },
    list_sales_order: {
      path: '/sales-orders(/:status)',
      component: SalesOrderPage,
      permissions: [p.manage_sales_orders, p.manage_inventory_transfers],
      status: 'production'
    },
    create_transfer: {
      path: '/transfers/create',
      component: CreateTransferPage,
      permissions: [p.manage_inventory_transfers],
      status: 'production',
      blockOnSetup: true
    },
    modify_transfer: {
      path: '/transfers/modify/:id',
      component: ModifyTransferPage,
      permissions: [p.manage_inventory_transfers],
      status: 'production',
      blockOnSetup: true
    },
    list_transfer: {
      path: '/transfers(/:status)',
      component: TransfersPage,
      permissions: [p.manage_inventory_transfers],
      status: 'production'
    },
    receive_transfer: {
      path: '/inventory-receipts/create',
      component: FillPurchaseOrderPage,
      permissions: [p.manage_inventory_receipts],
      status: 'production',
      blockOnSetup: true
    },
    inventory_receipt_metrc: {
      path: '/inventory-receipts/metrc(/:id)',
      component: AccessByFeatureEnabled('feature_new_product_master')(InventoryReceiptPageMetrc),
      status: 'production',
      permissions: [
        p.manage_inventory_transfers,
        p.manage_purchase_orders,
        p.manage_sales_orders,
        p.manage_inventory_receipts
      ],
      accessibility: {selectors: [isMetrcIntegrator, isFeatureEnabledProductMasters]},
    },
    list_inventory_receipts: {
      path: '/inventory-receipts(/:status)',
      component: InventoryReceiptListingPage,
      permissions: [p.manage_inventory_receipts],
      status: 'production'
    },
    edit_inventory_receipt: {
      path: '/inventory-receipts/modify/:id',
      component: FillPurchaseOrderPage,
      permissions: [p.manage_inventory_receipts],
      status: 'production',
      blockOnSetup: true
    }
  },
  inventory: {
    product_reservations: {
      path: '/products/:id/reservations',
      component: ProductReservations,
      permissions: [p.manage_products],
      status: 'production'
    },
    product_reservations_by_type: {
      path: '/products/:id/reservations/:type',
      component: ProductReservations,
      permissions: [p.manage_products],
      status: 'production'
    },
    create_product: {
      path: '/products/create',
      component: CreateProductPage,
      permissions: [p.manage_products],
      status: 'production'
    },
    modify_product: {
      path: '/products/modify/:id',
      component: ModifyProductPage,
      permissions: [p.manage_products],
      status: 'production'
    },
    modify_product_master: {
      path: '/product-masters/product-master/:id',
      component: ProductMasterPage,
      permissions: [p.manage_products],
      status: 'production'
    },
    create_product_master: {
      path: '/product-masters/product-master',
      component: ProductMasterPage,
      permissions: [p.manage_products],
      status: 'production'
    },
    mass_modify_product: {
      path: '/products/mass_modify',
      component: MassModifyProductPage,
      permissions: [p.mass_update_products],
      status: 'production'
    },
    mass_modify_product_preview: {
      path: '/products/mass_modify/preview',
      component: PreviewPage,
      permissions: [p.mass_update_products],
      status: 'production'
    },
    list_category_management: {
      path: '/category/management(/:integration)',
      component: CategoryManagementPage,
      permissions: [p.manage_product_subcategories, p.manage_categories],
      status: 'production'
    },
    adjust_reconciliations: {
      path: '/reconciliations/adjust',
      component: AdjustReconciliationsPage,
      permissions: [p.manage_reconciliations],
      status: 'production',
      blockOnSetup: true
    },
    split_package: {
      path: '/split_package',
      component: SplitPackagePage,
      permissions: [p.manage_inventory],
      status: 'production',
      blockOnSetup: true
    },
    create_propagation_material: {
      path: '/propagation-material',
      component: CreatePropagationMaterialPage,
      permissions: [p.manage_inventory],
      status: 'production',
      blockOnSetup: true
    },
    list_product_management: {
      path: '/product/management',
      component: ProductManagementPage,
      permissions: [
        p.manage_sales_orders,
        p.manage_purchase_orders,
        p.manage_inventory_transfers,
        p.manage_inventory_receipts
      ],
      status: 'production'
    }
  },
  manufacturing: {
    start_processing: {
      path: '/ei/processing/start',
      component: StartProcessingPage,
      permissions: [p.manage_processing, p.manage_infusions],
      accessibility: buildRouteAccessibilityObject([EXTRACTION]),
      status: 'production',
      blockOnSetup: true
    },
    complete_processing: {
      path: '/ei/processing/complete/:id',
      component: CompleteProcessingPage,
      permissions: [p.manage_processing],
      status: 'production',
      blockOnSetup: true
    },
    create_assembly: {
      path: '/ei/assemblies/create',
      component: CreateAssemblyPage,
      permissions: [p.manage_assemblies],
      status: 'production',
      blockOnSetup: true
    },
    modify_assembly: {
      path: '/ei/assemblies/modify/:id',
      component: ModifyAssemblyPage,
      permissions: [p.manage_assemblies],
      status: 'production',
      blockOnSetup: true
    },
    list_assembly: {
      path: '/ei/assemblies(/:status)',
      component: AssemblyListingPage,
      permissions: [p.manage_assemblies],
      accessibility: buildRouteAccessibilityObject([EXTRACTION, INFUSION]),
      status: 'production'
    },
    create_preferred_mix: {
      path: '/ei/preferred-mixes/create',
      component: CreatePreferredMixPage,
      permissions: [p.manage_preferred_mix],
      status: 'production'
    },
    modify_preferred_mix: {
      path: '/ei/preferred-mixes/modify/:id',
      component: ModifyPreferredMixPage,
      permissions: [p.manage_preferred_mix],
      status: 'production'
    },
    list_preferred_mixes: {
      path: '/ei/preferred-mixes(/:stage)',
      component: PreferredMixesListingPage,
      permissions: [p.manage_preferred_mix],
      status: 'production'
    },
    create_processing_type: {
      path: '/ei/processing-types/create',
      component: CreateProcessingTypesPage,
      status: 'production',
      permissions: [p.define_processing]
    },
    list_processing_type: {
      path: '/ei/processing-types(/:status)',
      component: ProcessingTypesListingPage,
      status: 'production',
      permissions: [p.define_processing],
      accessibility: buildRouteAccessibilityObject([EXTRACTION, INFUSION])
    },
    modify_processing_type: {
      path: '/ei/processing-types/modify/:id',
      component: ModifyProcessingTypesPage,
      status: 'production',
      permissions: [p.define_processing]
    },
    start_infusion: {
      path: '/ei/infusions/start',
      component: StartInfusionPage,
      permissions: [p.manage_infusions],
      accessibility: buildRouteAccessibilityObject([INFUSION]),
      status: 'production',
      blockOnSetup: true
    },
    complete_infusion: {
      path: '/ei/infusions/complete/:id',
      component: CompleteInfusionPage,
      permissions: [p.manage_infusions],
      status: 'production',
      blockOnSetup: true
    },
    // The active_processing route is duplicated, because we want to show it under a different App in the menu.
    // The 'feature_new_packaging_jobs' determines what route to show
    list_active_processing: { // Show is Feature Toggle IS disabled
      path: '/ei/active-processing',
      component: ActiveProcessingPage,
      permissions: [p.manage_processing],
      accessibility: {
        evaluate: (enabledModules, currentFacility, integrationState, featureToggles, state) => {
          return enabledModules.includes('EXTRACTION') && !get(featureToggles, 'feature_new_packaging_jobs.is_enabled', false);        }
      },
      status: 'production'
    },
    active_processing: { // Show is Feature Toggle IS enabled
      path: 'active-processing',
      component: AccessByFeatureAndModuleEnabled('feature_new_packaging_jobs', 'EXTRACTION')(ActiveProcessingPage),
      permissions: [p.manage_processing],
      accessibility: {
        evaluate: (enabledModules, currentFacility, integrationState, featureToggles, state) => {
          return enabledModules.includes('EXTRACTION') && get(featureToggles, 'feature_new_packaging_jobs.is_enabled', false);
        }
      },
      status: 'production'
    },
    // The active_infusions route is duplicated, because we want to show it under a different App in the menu.
    // The 'feature_new_packaging_jobs' determines what route to show
    list_active_infusion: { // Show is Feature Toggle IS disabled
      path: '/ei/active-infusions',
      component: ActiveInfusionsPage,
      permissions: [p.manage_infusions],
      accessibility: {
        evaluate: (enabledModules, currentFacility, integrationState, featureToggles, state) => {
          return enabledModules.includes('INFUSION') && !get(featureToggles, 'feature_new_packaging_jobs.is_enabled', false);
        }
      },
      status: 'production'
    },
    active_infusion: { // Show is Feature Toggle IS enabled
      path: 'active-infusions',
      component: AccessByFeatureAndModuleEnabled('feature_new_packaging_jobs', 'INFUSION')(ActiveInfusionsPage),
      permissions: [p.manage_infusions],
      accessibility: {
        evaluate: (enabledModules, currentFacility, integrationState, featureToggles, state) => {
          return enabledModules.includes('INFUSION') && get(featureToggles, 'feature_new_packaging_jobs.is_enabled', false);
        }
      },
      status: 'production'
    },
    add_equipment: {
      path: '/equipment/create',
      component: CreateEquipmentPage,
      permissions: [p.manage_equipment],
      status: 'production'
    },
    modify_equipment: {
      path: '/equipment/modify/:id',
      component: ModifyEquipmentPage,
      permissions: [p.manage_equipment],
      status: 'production'
    },
    list_equipment: {
      path: '/equipment/:status(/:type)',
      component: EquipmentListingPage,
      permissions: [p.manage_equipment],
      status: 'production'
    },
  },
  retail: {
    setup_delivery_orders: {
      path: '/retail/delivery-orders/setup',
      component: AccessByFeatureAndModuleEnabled('feature_courier_licensees', 'HOME_DELIVERY')(DeliveryOrderSetupPage),
      permissions: [p.manage_retail_delivery_orders],
      status: 'production'
    },
    delivery_order: {
      path: '/retail/delivery-orders(/:status)',
      component: HomeDeliveryWorkflowEnabled(DeliveryOrderPage),
      accessibility: buildRouteAccessibilityObject([RETAIL]),
      permissions: [p.manage_retail_delivery_orders],
      status: 'production'
    },
    modify_delivery_order: {
      path: '/retail/delivery-orders/modify/:id',
      component: HomeDeliveryWorkflowEnabled(ModifyDeliveryOrderPage),
      permissions: [p.manage_retail_delivery_orders],
      status: 'production'
    },
    generate_manifest: {
      path: '/retail/generate-manifest',
      component: HomeDeliveryWorkflowEnabled(HomeDeliveryManifestGenerationEnabled(GenerateManifestPage)),
      permissions: [p.manage_retail_delivery_orders],
      status: 'production'
    },
    auto_price: {
      path: '/retail/auto-price',
      component: AutoPricePage,
      permissions: [p.manage_autoprice],
      status: 'production'
    },
    customer_order_history: {
      path: '/order-history/customers/:id(/:order_id)',
      component: CustomerOrderHistoryPage,
      permissions: [p.manage_orders],
      status: 'production'
    },
    orders_order_receipt: {
      path: '/orders/:id/receipt',
      component: ReceiptPage,
      permissions: [p.create_orders],
      status: 'production'
    },
    customer_groups: {
      path: '/retail/customer-groups(/:status)',
      component: CustomerGroupPage,
      permissions: [p.manage_customer_groups],
      status: 'production'
    },
    minimum_days_supply: {
      path: '/retail/minimum-days-supply',
      component: MinimumDaysSupplyPage,
      accessibility: buildRouteAccessibilityObject([RETAIL], undefined, [applicationModes.isOhMetric]),
      permissions: [p.manage_orders],
      status: 'production'
    },
    // TODO: Implement these and other specific routes for this ticket: https://akerna.atlassian.net/browse/REBEL-2043
    // list_queue_in_store_medical: {
    //   path: '/queue/in_store/medical',
    //   component: QueuePage,
    //   permissions: [p.view_queue, p.manage_queue],
    //   accessibility: {
    //     evaluate: evaluateAccessibilityForQueueSpecificity
    //   },
    //   status: 'production',
    //   blockOnSetup: true
    // },
    // list_queue_in_store_recreational: {
    //   path: '/queue/in_store/recreational',
    //   component: QueuePage,
    //   permissions: [p.view_queue, p.manage_queue],
    //   accessibility: {
    //     evaluate: evaluateAccessibilityForQueueSpecificity
    //   },
    //   status: 'production',
    //   blockOnSetup: true
    // },
    list_queue_usage: {
      path: '/queue/:orderType/:usageType',
      component: QueuePage,
      permissions: [p.view_queue, p.manage_queue],
      accessibility: buildRouteAccessibilityObject([RETAIL]),
      status: 'production',
      blockOnSetup: true
    },
    list_queue_type: {
      path: '/queue/:orderType',
      component: QueuePage,
      accessibility: buildRouteAccessibilityObject([RETAIL]),
      permissions: [p.view_queue, p.manage_queue],
      status: 'production',
      blockOnSetup: true
    },
    create_patient: {
      path: '/patients/create',
      // AccessByPredicate is added temporary to block Utah accessing the URL directly as accessibility doesn't do that. Refer to CBYTES-58
      // TODO:: AccessByPredicate should be removed when REBEL-4056 is completed. Sept 10, 2021
      component: AccessByPredicate(isNotUtahSelector, (isNotUtah) => {
        return isNotUtah;
      }, '/no-facility-permission')(
        CreateCustomerPage
      ),
      accessibility: {selectors: [isNotUtahSelector, isRetailFacility]},
      permissions: [p.manage_customers, p.create_customers],
      status: 'production',
      blockOnSetup: true
    },
    modify_patient: {
      path: '/patients/modify/:id',
      component: ModifyCustomerPage,
      permissions: [p.manage_customers, p.edit_customers],
      status: 'production',
      blockOnSetup: true
    },
    patient_history: {
      path: '/patients/:id/history',
      component: CustomerHistoryPage,
      permissions: [p.manage_customers, p.edit_customers],
      status: 'production'
    },
    list_patient_status: {
      path: '/patients/:status',
      component: AccessByFeatureEnabledWithFailureComponent('feature_service_first_patient_listing', CustomerPage)(ServiceFirstCustomerPage),
      permissions: [p.view_customers, p.manage_customers, p.create_customers, p.edit_customers],
      status: 'production'
    },
    list_patient: {
      path: '/patients',
      component: AccessByFeatureEnabledWithFailureComponent('feature_service_first_patient_listing', CustomerPage)(ServiceFirstCustomerPage),
      accessibility: buildRouteAccessibilityObject([RETAIL]),
      permissions: [p.view_customers, p.manage_customers, p.create_customers, p.edit_customers],
      status: 'production'
    },
    CheckinCustomerPage: {
      path: '/check-in',
      component: CheckinPageComponentByModeWrapper,
      accessibility: buildRouteAccessibilityObject([RETAIL]),
      permissions: [p.view_customers, p.manage_customers, p.create_customers, p.edit_customers],
      status: 'production',
      components: {
        [applicationModes.leafPA]: CheckinLeafPAPage,
        [applicationModes.cure]: CheckinCureApiPage,
        [applicationModes.normal]: CheckinCustomerPage
      },
      blockOnSetup: true
    },
    create_register: {
      path: '/registers/create',
      component: CreateRegisterPage,
      permissions: [p.manage_registers],
      status: 'production'
    },
    history_register: {
      path: '/registers/history/:id',
      component: RegisterHistoryPage,
      permissions: [p.manage_registers],
      status: 'production'
    },
    modify_register: {
      path: '/registers/modify/:id',
      component: ModifyRegisterPage,
      permissions: [p.manage_registers],
      status: 'production'
    },
    list_register_status: {
      path: '/registers/:status',
      component: RegisterPage,
      permissions: [p.manage_registers, p.view_registers],
      status: 'production'
    },
    list_register: {
      path: '/registers',
      component: RegisterPage,
      permissions: [p.manage_registers, p.view_registers],
      status: 'production'
    },
    transfer_cash: {
      path: '/transfer_cash',
      component: TransferCashPage,
      permissions: [p.manage_registers, p.view_registers],
      status: 'production'
    },
    quick_move_product: {
      path: '/products/quick-move',
      component: QuickMoveProducts,
      permissions: [p.manage_products],
      status: 'production',
      blockOnSetup: true
    },
    list_product_detail: {
      path: '/products/:id',
      component: ProductDetailPage,
      permissions: [p.create_orders],
      status: 'production'
    },
    list_product_masters: {
      path: '/product-masters',
      component: AccessByFeatureEnabled('feature_new_product_master')(ProductMastersPage),
      permissions: [p.view_products],
      status: 'production',
      accessibility: {selectors: [isFeatureEnabledProductMasters]},
    },
    list_product: {
      path: '/products',
      component: ProductsPage,
      permissions: [p.manage_products, p.view_products],
      status: 'production'
    },
    show_checkout_type: {
      path: '/checkout/:type',
      component: CartPage,
      permissions: [p.create_orders],
      status: 'production',
      blockOnSetup: true
    },
    show_checkout: {
      path: '/checkout',
      component: CartPage,
      permissions: [p.create_orders],
      status: 'production',
      blockOnSetup: true
    },
    show_payment: {
      path: 'orders/:id/payment',
      component: PaymentPage,
      permissions: [p.create_orders],
      status: 'production',
      blockOnSetup: true
    },
    show_fill_order: {
      path: '/orders/:id/printing',
      component: PaymentPage,
      permissions: [p.create_orders],
      status: 'production',
      blockOnSetup: true
    },
    show_out_for_delivery: {
      path: '/orders/:id/delivery',
      component: PaymentPage,
      permissions: [p.create_orders],
      status: 'production',
      blockOnSetup: true
    },
    show_cart: {
      path: '/cart',
      component: CartPage,
      permissions: [p.create_orders],
      status: 'production',
      blockOnSetup: true
    },
    show_product_menu: {
      path: '/product-menu',
      component: ProductMenuPage,
      permissions: [p.create_orders],
      status: 'production',
      blockOnSetup: true
    },
    show_product_menu_order_id: {
      path: '/product-menu/:orderId',
      component: ProductMenuPage,
      permissions: [p.create_orders],
      status: 'production',
      blockOnSetup: true
    },

    /*** TAXES UPDATE PATHS ****/
    taxes_landing: {
      path: '/taxes',
      component: FacilityHasAccess([facilityTypes.dispensary])(TaxPage), // to be replaced
      permissions: [p.manage_taxes],
      status: 'production'
    },
    taxes_rules: {
      path: '/taxes/rules',
      component: FacilityHasAccess([facilityTypes.dispensary])(TaxPage),
      permissions: [p.manage_taxes],
      status: 'production'
    },
    taxes_rules_active: {
      path: '/taxes/rules/active',
      component: FacilityHasAccess([facilityTypes.dispensary])(TaxPage),
      permissions: [p.manage_taxes],
      status: 'production'
    },
    taxes_rules_inactive: {
      path: '/taxes/rules/inactive',
      component: FacilityHasAccess([facilityTypes.dispensary])(TaxPage),
      permissions: [p.manage_taxes],
      status: 'production'
    },
    taxes_rules_create: {
      path: '/taxes/rules/create',
      component: FacilityHasAccess([facilityTypes.dispensary])(CreateTaxPage),
      permissions: [p.manage_taxes],
      status: 'production'
    },
    taxes_crud: {
      path: '/taxes/rules/:id',
      component: FacilityHasAccess([facilityTypes.dispensary])(ModifyTaxPage),
      permissions: [p.manage_taxes],
      status: 'production'
    },
    taxes_profiles: {
      path: '/taxes/profiles',
      component: FacilityHasAccess([facilityTypes.dispensary])(TaxProfilesPage),
      permissions: [p.manage_taxes],
      status: 'production'
    },
    taxes_profiles_active: {
      path: '/taxes/profiles/active',
      component: FacilityHasAccess([facilityTypes.dispensary])(TaxProfilesPage),
      permissions: [p.manage_taxes],
      status: 'production'
    },
    taxes_profiles_inactive: {
      path: '/taxes/profiles/inactive',
      component: FacilityHasAccess([facilityTypes.dispensary])(TaxProfilesPage),
      permissions: [p.manage_taxes],
      status: 'production'
    },
    reorder_tax_profiles: {
      path: 'taxes/profiles/reorder',
      component: FacilityHasAccess([facilityTypes.dispensary])(ReorderTaxProfilesPage),
      permissions: [p.manage_taxes],
      status: 'production'
    },
    crud_tax_profiles: {
      path: '/taxes/profiles/:id',
      component: FacilityHasAccess([facilityTypes.dispensary])(TaxProfileEditorPage),
      permissions: [p.manage_taxes],
      status: 'production'
    },
    create_pricing_classes: {
      path: '/retail/pricing-classes/create',
      component: CreatePricingClassPage,
      permissions: [p.manage_pricing_classes],
      status: 'production'
    },
    list_pricing_classes: {
      path: '/retail/pricing-classes(/:active)',
      component: PricingClassesListingPage,
      permissions: [p.manage_pricing_classes],
      status: 'production'
    },
    modify_pricing_classes: {
      path: '/retail/pricing-classes/modify/:id',
      component: ModifyPricingClassPage,
      permissions: [p.manage_pricing_classes],
      status: 'wireframe'
    },
    create_pricing_groups: {
      path: '/retail/pricing-groups/create',
      component: CreatePricingGroupPage,
      permissions: [p.manage_pricing_groups],
      status: 'production'
    },
    modify_pricing_groups: {
      path: '/retail/pricing-groups/modify/:id',
      component: ModifyPricingGroupPage,
      permissions: [p.manage_pricing_groups],
      status: 'wireframe'
    },
    list_pricing_groups: {
      path: '/retail/pricing-groups(/:status)',
      component: PricingGroupListingPage,
      permissions: [p.manage_pricing_groups],
      status: 'production'
    },
    modify_pricing_multiplier: {
      path: '/retail/pricing-multipliers/modify/:id',
      component: AccessByFeatureEnabled('feature_new_product_master')(PricingMultiplierPage),
      permissions: [p.manage_pricing_multipliers],
      status: 'production'
    },
    create_pricing_multiplier: {
      path: '/retail/pricing-multipliers/create',
      component: AccessByFeatureEnabled('feature_new_product_master')(PricingMultiplierPage),
      permissions: [p.manage_pricing_multipliers],
      status: 'production'
    },
    list_pricing_multipliers: {
      path: '/retail/pricing-multipliers(/:status)',
      component: AccessByFeatureEnabled('feature_new_product_master')(PricingMultiplierListingPage),
      permissions: [p.manage_pricing_multipliers],
      status: 'production'
    },
    create_sales_locations: {
      path: '/retail/sales-locations/setup',
      component: SetupSalesLocationsFormPage,
      permissions: [p.manage_sales_locations],
      status: 'production'
    },
    sales_settings: {
      path: '/retail/sales-settings',
      component: SalesSettingPage,
      permissions: [p.manage_facility],
      status: 'production'
    },
    patient_settings: {
      path: '/retail/patient-settings',
      component: FacilityHasAccess([facilityTypes.dispensary])(PatientCompliancePage),
      permissions: [p.manage_retail_compliance],
      status: 'production'
    },
    credit_cards_settings: {
      path: '/retail/credit-cards-settings',
      component: AccessByPredicate(getActiveFacility, isCanadianDispensary, '/no-facility-permission')(
        CreditCardsSettingsPage
      ),
      permissions: [p.manage_credit_card_integration],
      status: 'Production'
    },
    inventory_compliance_settings: {
      path: '/inv/compliance-settings',
      component: InventoryComplianceSettingsPage,
      permissions: [p.manage_cultivation_compliance, p.manage_retail_compliance, p.manage_manufacturing_compliance],
      status: 'production'
    },
    receipt_settings: {
      path: '/retail/receipt-settings',
      component: ReceiptSettingsPage,
      permissions: [p.manage_facility],
      status: 'production'
    },
    create_coupon_page: {
      path: '/retail/coupons/create',
      component: CreateCouponPage,
      permissions: [p.manage_coupons],
      status: 'production'
    },
    modify_coupon_page: {
      path: '/retail/coupons/modify/:id',
      component: ModifyCouponPage,
      permissions: [p.manage_coupons],
      status: 'production'
    },
    list_coupon_page: {
      path: '/retail/coupons(/:active)',
      component: CouponListingPage,
      permissions: [p.manage_coupons],
      accessibility: buildRouteAccessibilityObject([RETAIL]),
      status: 'production'
    },
    product_lists_listing_page: {
      path: '/retail/product-lists(/:active)',
      component: ProductListsListingPage,
      permissions: [p.manage_coupons],
      accessibility: buildRouteAccessibilityObject([RETAIL]),
      status: 'production'
    },
    product_list_page: {
      path: '/retail/product-list(/:id)',
      component: ProductListPage,
      permissions: [p.manage_coupons],
      accessibility: buildRouteAccessibilityObject([RETAIL]),
      status: 'production'
    },
    create_physician_page: {
      path: '/retail/physicians/create',
      component: CreatePhysicianPage,
      permissions: [p.manage_physicians]
    },
    modify_physician_page: {
      path: '/retail/physicians/modify/:id',
      component: ModifyPhysicianPage,
      permissions: [p.manage_physicians]
    },
    list_physician_page: {
      path: '/retail/physicians/:status',
      component: PhysiciansPage,
      accessibility: {selectors: [isNotRegulatorFacilitySelector]},
      permissions: [p.manage_physicians]
    },
    import_orders_form: {
      path: 'import/orders/import',
      component: ImportOrdersPage,
      permissions: [p.manual_sales_import]
    },
    import_orders_page: {
      path: 'import/orders',
      component: ImportOrdersListPage,
      permissions: [p.manual_sales_import]
    },
    import_order_view: {
      path: 'import/orders/view/:id',
      component: ImportOrdersViewTable,
      permissions: [p.manual_sales_import]
    }
  },
  accounting: {
    accounting_export_page: {
      path: 'accounting/export',
      component: AccountingExportPage,
      permissions: [p.manage_accounting],
      status: 'production'
    },
    accounting_settings: {
      path: 'accounting/setup/:group',
      component: AccountingSettingsPage,
      permissions: [p.manage_accounting],
      status: 'production'
    }
  },
  reporting: {
    analytics: {
      path: '/reporting/analytics(/:tab)',
      component: AccessByFeatureEnabled('feature_analytics_reporting')(AnalyticsListingPage),
      permissions: [p.analytics_reporting]
    },
    analytics_view: {
      path: '/reporting/analytics/view/:id',
      component: AccessByFeatureEnabled('feature_analytics_reporting')(AnalyticsViewPage),
      permissions: [p.analytics_reporting]
    }
  },
  wireframe: {
    testing_compliance: {
      path: '/testing-compliance',
      component: TestingCompliancePage,
      permissions: [p.manage_compliance_settings],
      status: 'hardcoded'
    },
    alert_settings: {
      path: '/cultivation/alert-settings',
      component: AlertSettingsPage,
      permissions: [p.manage_alerts],
      status: 'wireframe'
    },
    list_pricing_weight: {
      path: '/cultivation/pricing-weights',
      component: PricingWeightsPage,
      permissions: [p.manage_pricing_weights],
      status: 'wireframe'
    },
    list_supplies: {
      path: '/retail/supplies/create',
      component: CreateSuppliesPage,
      permissions: [p.manage_supplies],
      status: 'wireframe'
    },
    supplies_listing_page: {
      path: '/retail/supplies-listing',
      component: SuppliesListingPage,
      permissions: [p.manage_supplies],
      status: 'wireframe'
    },
    modify_harvest_batch: {
      path: '/cultivation/modify-batch/:id',
      component: ModifyHarvestBatchPage,
      permissions: [p.view_inventory],
      status: 'wireframe',
      blockOnSetup: true
    },
    create_plant_costing: {
      path: '/cultivation/plants/create-plant-costing',
      component: CreatePlantCostingPage,
      permissions: [p.manage_plant_costing],
      status: 'wireframe'
    },
    mergeout: {
      path: '/mergeout',
      component: MergeOutNetworkPage,
      permissions: [p.manage_partners],
      status: 'wireframe'
    },
    modify_product_information: {
      path: '/product/product-information-modification',
      component: ProductInfoModificationPage,
      permissions: [p.manage_products],
      status: 'wireframe'
    },
    rewards_setup: {
      path: '/retail/rewards/setup',
      component: RewardsManager,
      permissions: [p.manage_rewards],
      status: 'production'
    },
    rewards_list: {
      path: '/retail/rewards',
      component: RewardsManager,
      permissions: [p.manage_rewards],
      accessibility: buildRouteAccessibilityObject([RETAIL]),
      status: 'production'
    },
    rewards_list_active: {
      path: '/retail/rewards/active',
      component: RewardsManager,
      permissions: [p.manage_rewards],
      accessibility: buildRouteAccessibilityObject([RETAIL]),
      status: 'production'
    },
    rewards_list_inactive: {
      path: '/retail/rewards/inactive',
      component: RewardsManager,
      permissions: [p.manage_rewards],
      status: 'production'
    },
    rewards_create: {
      path: '/retail/rewards/create',
      component: RewardsManager,
      permissions: [p.manage_rewards],
      status: 'production'
    },
    rewards_edit: {
      path: '/retail/rewards/:id',
      component: RewardsManager,
      permissions: [p.manage_rewards],
      status: 'production'
    },
    coupon_setup: {
      path: '/retail/wf/reward-setup',
      component: RewardSetupPage,
      permissions: [p.manage_rewards],
      status: 'wireframe'
    },
    list_rewards: {
      path: '/retail/wf/rewards',
      component: RewardsPage,
      permissions: [p.manage_rewards],
      status: 'wireframe'
    },
    retail_closing_till: {
      path: '/retail/register-reconciliation/:mode/:id',
      component: RegisterReconciliationPage,
      permissions: [p.manage_report_closing],
      status: 'production'
    },
    labels_settings: {
      path: '/labels/settings',
      component: LabelsSettingsPage,
      permissions: [p.manage_labels],
      status: 'production'
    },
    labels_custom: {
      path: '/labels/custom',
      component: LabelsCustomListPage,
      permissions: [p.manage_labels],
      status: 'production'
    },
    labels_custom_editor: {
      path: '/labels/custom/modify',
      component: LabelsCustomEditor,
      permissions: [p.manage_labels],
      status: 'production'
    },
    labels_statements: {
      path: '/labels/statements',
      component: LabelStatementsPage,
      permissions: [p.manage_compliance_settings],
      status: 'production'
    },
    labels_manager: {
      path: '/labels/manager',
      component: LabelsManagementPage,
      permissions: [p.manage_labels],
      status: 'production'
    },
    labels_editor: {
      path: '/labels/editor/:tag',
      component: LabelEditorPage,
      permissions: [p.manage_labels],
      status: 'production'
    },
    manage_closing: {
      path: '/retail/manage-closing',
      component: ManageClosingPage,
      permissions: [p.manage_report_closing],
      status: 'production'
    },
    sales_reports: {
      path: '/retail/sales-report',
      component: SalesReportsLandingPage,
      permissions: [
        p.manage_report_sales_summary,
        p.manage_report_daily_transactions,
        p.manage_report_current_inventory,
        p.manage_report_product_sales,
        p.manage_report_closing,
        p.manage_report_cash_on_hand,
        p.manage_report_inventory_transactions,
      ],
      status: 'production'
    },
    sales_report: {
      path: '/retail/sales-report/sales-summary',
      component: AccessByFeatureEnabled('feature_sales_summary_report')(SalesReportPage),
      accessibility: {selectors: [isSalesSummaryReportFeatureEnabled]},
      permissions: [p.manage_report_sales_summary],
      status: 'production'
    },
    transaction_report: {
      path: '/retail/sales-report/transactions',
      component: TransactionReportPage,
      permissions: [p.manage_report_daily_transactions]
    },
    inventory_report: {
      path: '/retail/sales-report/inventory',
      component: InventoryReportPage,
      permissions: [p.manage_report_current_inventory]
    },
    product_sales_report: {
      path: '/retail/sales-report/products',
      component: ProductSalesReportPage,
      permissions: [p.manage_report_product_sales],
    },
    metrc_sales_report: {
      path: '/retail/sales-report/metrc',
      component: MetrcSalesReportPage,
      permissions: [p.manage_report_metrc_sales],
      accessibility: {selectors: [isMetrcIntegrator]},
    },
    register_closing_activity_report: {
      path: '/retail/sales-report/register-activity',
      component: RegisterActivityReport,
      permissions: [p.manage_report_closing],
      status: 'production'
    },
    closing_report: {
      path: '/retail/sales-report/closing-report(/:status)',
      component: ClosingReportPage,
      permissions: [p.manage_report_closing],
      status: 'production'
    },
    cash_on_hand_report: {
      path: '/retail/sales-report/cash-on-hand',
      component: AccessByFeatureEnabled('feature_show_bi_platform_reports')(CashOnHandReportPage),
      permissions: [p.manage_report_cash_on_hand],
      accessibility: {selectors: [isShowBiPlatformReportsFeatureEnabled]},
      status: 'production'
    },
    inventory_transactions_report: {
      path: '/retail/sales-report/inventory-transactions',
      component: AccessByFeatureEnabled('feature_show_bi_platform_reports')(InventoryTransactionsReportPage),
      permissions: [p.manage_report_inventory_transactions],
      accessibility: {selectors: [isShowBiPlatformReportsFeatureEnabled]},
      status: 'production'
    },
    manage_report_ca: {
      path: '/retail/sales-report/export-ca',
      component: MonthlyReportExport,
      permissions: [p.manage_report_canada],
      status: 'production'
    },
    mange_report_ok: {
      path: '/retail/sales-report/export-ok',
      component: MonthlyReportExport,
      permissions: [p.manage_report_oklahoma],
      status: 'production'
    },
    order_view: {
      path: '/orders/:id',
      component: OrderView,
      permissions: [p.create_orders],
      status: 'production'
    },
    test_labels: {
      path: '/testlabels',
      component: TestLabels,
      permissions: [p.super_admin_only],
      status: 'wireframe'
    },
    print_servers: {
      path: '/print-servers',
      component: PrintServersPage,
      permissions: [p.super_admin_only],
      status: 'production'
    },
    printer_servers_crud: {
      path: '/print-servers/:printServerId',
      component: PrintServersPage,
      permissions: [p.super_admin_only],
      status: 'production'
    }
  },
  notifications: {
    notifications: {
      path: '/notifications',
      component: NotificationPage,
      permissions: [p.view_notifications],
      status: 'production'
    }
  },
  integration: {
    api_transactions: {
      path: '/integration/api-transactions',
      component: TransactionListingPage,
      permissions: [p.manage_state_integrations, p.manage_tracking],
      status: 'production'
    },
    api_transactions_details: {
      path: '/integration/api-transactions/details/:id',
      component: TransactionDetailsPage,
      permissions: [p.manage_state_integrations, p.manage_tracking],
      status: 'production'
    },
    list_tracking: {
      path: '/integration/tracking',
      component: TrackingIdsPage,
      permissions: [p.manage_state_integrations, p.manage_tracking],
      accessibility: {selectors: [isMetrcIntegrator]},
      status: 'production'
    },
    list_tracking_group: {
      path: '/integration/tracking/activate-deactivate/:id',
      component: ActivateDeactivateTrackingIdsPage,
      permissions: [p.manage_state_integrations, p.manage_tracking],
      status: 'production'
    },
    receive_tracking: {
      path: '/integration/tracking/receive',
      component: ReceiveTrackingIdsPage,
      permissions: [p.manage_state_integrations, p.manage_tracking],
      status: 'production'
    },
    internal_transfer_settings: {
      path: 'internal-transfer-settings',
      permissions: [p.manage_internal_transfers],
      status: 'production',
      component: AccessByPredicate(getIntegrationState, (integrationState) => {
        // Block access for leaf, biotrack, and metrc
        const {isLeaf, isBiotrack, isMetrc} = integrationState;
        return !isLeaf && !isBiotrack && !isMetrc;
      }, '/no-facility-permission')(InternalTransfersSettingPage),
    },
    uom: {
      path: 'uom',
      permissions: [p.manage_products, p.manage_inventory, p.mass_update_products],
      status: 'production',
      component: AccessByPredicate(
        getIntegrationState,
        (integrationState) => integrationState.isHemp,
        '/no-facility-permission'
      )(UomConversionFactorPage),
    }
  },
};

export const routes = () => {
  const allRoutes = [];
  if (routeDefinitions) {
    Object.keys(routeDefinitions).forEach((type) => {
      Object.keys(routeDefinitions[type]).forEach((key) => {
        const route = routeDefinitions[type][key];
        allRoutes.push(<Route key={key} {...route} />);
      });
    });
    return allRoutes;
  }
};

export default routeDefinitions;
