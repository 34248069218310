const rules = [
  {
    event: {  // becomes the testable property of the renderRules
      type: 'canCreateMatchingOrder',
      falseValue: 0,
      trueValue: 1
    },
    conditions: {
      all: [
        {
          fact: 'canBeLinked',
          operator: 'equal',
          value: true
        },
        {
          fact: 'isLinked',
          operator: 'equal',
          value: false
        }
      ]
    },
  },
  {
    event: {
      type: 'hasMatchingOrder',
      falseValue: 0,
      trueValue: 1
    },
    conditions: {
      all: [
        {
          fact: 'isLinked',
          operator: 'equal',
          value: true
        }
      ]
    },
  },
  {
    event: {
      type: 'hasTransfer',
    },
    conditions: {
      all: [
        {
          fact: 'hasTransfer',
          operator: 'equal',
          value: true
        }
      ]
    },
  },
  {
    event: {
      type: 'hasCompletedTransfer',
    },
    conditions: {
      all: [
        {
          fact: 'hasCompletedTransfer',
          operator: 'equal',
          value: true
        }
      ]
    },
  }
];

export default rules;
