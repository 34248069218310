/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Row, Col} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import {push} from 'react-router-redux';
import {Link} from 'react-router';
import {formValueSelector} from 'redux-form';

import MessageBlock from '../../common/MessageBlock';
import Spinner from '../../common/Spinner';
import {clearMessage, setCsrfToken} from '../../../actions/systemActions';
import {postItem, getItem} from '../../../actions/apiActions';
import ResetPasswordWithTokenForm from './ResetPasswordWithTokenForm';
import ResetPasswordHeader from './ResetPasswordHeader';
import validate from './validate';
import checkPasswordRules from '../common/passwordRules';
import InProgressOverlay from '../../common/InProgressOverlay';

export class ResetPasswordPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.state = {
      tokenIsValid: null,
      isSubmitting: false,
      isSaved: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
  }

  componentWillMount() {
    const token = this.props.params.id;
    this.props.actions.getItem('/api/password/check_token/' + token, null, null, null, (data) => {
      this.setState({
        tokenIsValid: Boolean(data.is_valid)
      });

      this.props.actions.getItem('/api/auth/csrf', null, null, null, (data) => {
        this.props.actions.setCsrfToken({
          token: data.csrfToken
        });
      });
    });
  }

  onSubmit(user) {
    const payload = {
      ...user,
      token: this.props.params.id
    };
    this.setState({isSubmitting: true});
    this.props.actions.postItem('/api/password/reset', payload, 'nop', {failed: 'user.password.resetFailed'})
      .then(() => {
        this.setState({isSubmitting: false, isSaved: true});
        setTimeout(() => this.props.actions.push('/login'), 5000);
      })
      .catch(() => this.setState({isSubmitting: false}));
  }

  onDismiss(id) {
    this.props.actions.clearMessage(id);
  }

  renderTokenInvalid() {
    const isCreateMode = (this.props.params.mode === 'create');
    return (
      <Row>
        <Col md={{span: 6, offset: 3}} xs={{span: 8, offset: 2}}>
          <ResetPasswordHeader isCreateMode={isCreateMode}/>
          <Row>
            <Col>
              <div className='alert-danger' style={{border: '1px solid #a94442', padding: '20px'}}>
                {I18n.t('user.password.linkExpired')}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  renderLoading() {
    const isCreateMode = (this.props.params.mode === 'create');
    return (
      <Row>
        <Col md={{span: 6, offset: 3}} xs={{span: 8, offset: 2}}>
          <ResetPasswordHeader isCreateMode={isCreateMode}/>
          <div>{I18n.t('general.loading')}</div>
        </Col>
      </Row>
    );
  }

  renderSaved() {
    const isCreateMode = (this.props.params.mode === 'create');
    return (
      <Row>
        <Col md={{span: 6, offset: 3}} xs={{span: 8, offset: 2}}>
          <ResetPasswordHeader isCreateMode={isCreateMode}/>
          <Row>
            <Col>
              <div className='alert-success' style={{border: '1px solid #c3e6cb', padding: '20px'}}>
                {I18n.t('user.password.saved')}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  render() {
    const isCreateMode = (this.props.params.mode === 'create');
    if (this.state.tokenIsValid === null) {
      return this.renderLoading();
    }
    if (this.state.isSaved) {
      return this.renderSaved();
    }
    if (this.state.tokenIsValid === false) {
      return this.renderTokenInvalid();
    }
    return (
      <Row>
        <Col md={{span: 6, offset: 3}} xs={{span: 8, offset: 2}}>
          <ResetPasswordHeader isCreateMode={isCreateMode}/>
          <MessageBlock
            style={{borderTop: '10px'}}
            messages={this.props.messages}
            dismiss={this.onDismiss}
          />
          <Row>
            <InProgressOverlay isActive={this.state.isSubmitting} message={I18n.t('common.form.saving')}/>
            <ResetPasswordWithTokenForm
              onSubmit={this.onSubmit}
              validate={validate}
              isCreateMode={isCreateMode}
              checkPasswordRules={checkPasswordRules}
              password={this.props.password}
              username={this.props.username}
            />
            <br/>
            <span> <Link to='/login'>{I18n.t('user.password.returnToLogin')}</Link> </span>
          </Row>
          <Spinner active={this.props.spinner > 0}/>
        </Col>
      </Row>
    );
  }
}

ResetPasswordPage.propTypes = {
  actions: PropTypes.object.isRequired,
  password: PropTypes.string,
  username: PropTypes.string,
  messages: PropTypes.array,
  spinner: PropTypes.number,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  })
};

const valueSelector = formValueSelector('resetPassword');
function mapStateToProps(state) {
  return {
    messages: state.messages || [],
    spinner: state.spinner,
    password: valueSelector(state, 'password'),
    username: valueSelector(state, 'name'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({postItem, getItem, push, clearMessage, setCsrfToken}, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
