import { I18n } from 'react-redux-i18n';

export default [
  { category_code: 'FLOWER', name: 'flower', label: I18n.t('productType.label.flower'), glyphicon: 'grain' },
  { category_code: 'CONCENTRATE', name: 'concentrate', label: I18n.t('productType.label.concentrate'), glyphicon: 'tint' },
  { category_code: 'CARTPENS', name: 'cartridges_pens', label: I18n.t('productType.label.cartridges_pens'), glyphicon: 'fire' },
  { category_code: 'EDIBLE', name: 'infused_edible', label: I18n.t('productType.label.infused_edible'), glyphicon: 'apple' },
  { category_code: 'INFUSED', name: 'infused_non_edible', label: I18n.t('productType.label.infused_non_edible'), glyphicon: 'oil' },
  { category_code: 'PLANTS',  name: 'plants', label: I18n.t('productType.label.plants'), glyphicon: 'leaf' },
  { category_code: 'UNMEDICATED', name: 'non_medicated', label: I18n.t('productType.label.non_medicated'), glyphicon: 'cd' },
  { category_code: 'HARVESTS',  subcategory_code: 'TEST_SAMPLE', name: 'harvest', label: I18n.t('productType.label.harvest_test_sample'), glyphicon: 'scale' },
  { category_code: 'MARIJUANA', name: 'marijuana', label: I18n.t('productType.label.marijuana'), glyphicon: 'tree-deciduous' },
  { category_code: 'CBD_FLOWER', name: 'cbd_flower', label: I18n.t('productType.label.cbd_flower'), glyphicon: 'certificate' },
  { category_code: 'HEMP_WASTE', name: 'hemp_waste', label: I18n.t('productType.label.hemp_waste'), glyphicon: 'certificate' }
];
