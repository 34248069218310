import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, getFormValues} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import {validate} from './validate';
import MinimumDaysSupplyItem from './MinimumDaysSupplyItem';
import {MINIMUM_DAYS_SUPPLY_FORM} from '../../../../constants/forms';
import SubmitSection from '../../../common/form/SubmitSection';

const MinimumDaysSupplyForm = (props) => {
  const {
    submitting,
    supply,
    formValues,
    handleSubmit
  } = props;

  const submitSectionSettings = {
    actionSettings: {
      submit: {
        submitting,
        text: I18n.t('common.actions.save'),
      }
    },
  };

  return (
    <form className='minimum-days-supply-form' onSubmit={handleSubmit}>
      <div className='row'>
        <MinimumDaysSupplyItem
          type='non_terminal'
          patients={get(supply, 'non_terminal', [])}
          initialValues={formValues}
        />
        <MinimumDaysSupplyItem
          type='terminal'
          patients={get(supply, 'terminal', [])}
          initialValues={formValues}
        />
      </div>
      <SubmitSection settings={submitSectionSettings} />
    </form>
  );
};

MinimumDaysSupplyForm.propTypes = {
  title: PropTypes.string,
  settings: PropTypes.array,
  formValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  valid: PropTypes.bool,
  supply: PropTypes.object,
};

export const MDSForm = reduxForm({
  enableReinitialize: true,
})(MinimumDaysSupplyForm);

function mapStateToProps(state, ownProps) {
  return {
    validate,
    form: MINIMUM_DAYS_SUPPLY_FORM,
    formValues: getFormValues(MINIMUM_DAYS_SUPPLY_FORM)(state) || ownProps.supply,
    initialValues: ownProps.supply,
  };
}

export default connect(mapStateToProps)(MDSForm);
