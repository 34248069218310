import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {FaTimes} from 'react-icons/fa';

const ProductStatement = (props) => {

  const isInherited = () => {
    const s = props.statement;
    return (s.source_item !== s.statement_source_type);
  };

  const inheritedText = () => {
    if(props.statement.statement_source_type === null) return I18n.t(`productStatements.notSet`);
    return 'Inherited from: ' + I18n.t(`productStatements.${props.statement.statement_source_type}`);
  };

  const categories = props.categories !== undefined
  ? props.categories
    : false;

  // eslint-disable-next-line react/no-multi-comp
  const getDeleteIcon = () => {
    return (props.statement.remove) === 1 ? <FaTimes className='danger'/> : <FaTimes className='text-muted'/>;
  };


  return (
    <div className='form-group'>
      {!props.showLabel
        ? null
        : (
          <label
            style={{width: '100%'}}
          >
            {I18n.t(`productStatements.${props.type}`)}
            {!categories
              ? (<div style={{float: 'right', fontSize: '18px', cursor: 'pointer'}}
                   onClick={() => {props.onToggleDelete(props.type, props.index);}}
                 >
                  {getDeleteIcon()}
                </div>
              )
              : null
            }

          </label>
        )
      }
      <label className={categories ? 'text-muted' : ''} style={categories ? {width: '100%'} : {width: '100%', display: 'none'}}>
        {!categories
          ? null
          : props.categories.map((category, index) => {
            return (props.categories.length === 1)
              ? category.name
              : (<a
                  key={index}
                  href='#'
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    props.extractCategoryId(props.type, category.id);
                  }}
                >
                  {category.name}{index < props.categories.length - 1 ? ', ' : ''}
                </a>);
          })
        }
        <div style={{float: 'right', fontSize: '18px', cursor: 'pointer'}}
          onClick={() => {props.onToggleDelete(props.type, props.index);}}
        >
          {getDeleteIcon()}
        </div>
      </label>
      <textarea
        rows={4}
        name='name'
        value={props.value}
        onChange={(event) => props.onStatementChange(props.type, props.index, event.target.value)}
        className='form-control form-control-sm'
      />
      {
        !isInherited()
        ? null
          : <div className='text-muted' style={{fontSize: 'smaller'}}>{inheritedText()}</div>
      }
    </div>
  );

};

ProductStatement.propTypes = {
  type: PropTypes.string.isRequired,
  productStatements: PropTypes.array,
  index: PropTypes.number.isRequired,
  statement: PropTypes.object,
  extractCategoryId: PropTypes.func,
  onToggleDelete: PropTypes.func.isRequired,
};

export default ProductStatement;
