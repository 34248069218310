import {I18n} from 'react-redux-i18n';
import {requiredFieldValidation} from '../../common/form/redux-form/validations';
import {
  AU_POSTAL_CODE_REG_EXP,
  CA_POSTAL_CODE_REG_EXP, CO_POSTAL_CODE_REG_EXP,
  NZ_POSTAL_CODE_REG_EXP, UY_POSTAL_CODE_REG_EXP, ZA_POSTAL_CODE_REG_EXP,
  ZIPCODE_REG_EXP
} from '../../common/form/validationRegExs';
import {stateIsNotRequiredFor} from '../../partners/common/validateOrganization';
import {DEA_NUMBER} from '../../../constants/physicianLicenseTypes';

function validate(values, {integrationState: {isOhMetrc}}) {
  const addresses = (values.addresses || []).map(address => ({
    country_code: requiredFieldValidation(address.country_code),
    province_code: !stateIsNotRequiredFor.includes(address.country_code) && requiredFieldValidation(address.province_code),
    postal_code: requiredFieldValidation(address.postal_code) || validatePostalCode(address.postal_code, address.country_code),
    city: requiredFieldValidation(address.city),
    active: requiredFieldValidation(address.active),
    primary: requiredFieldValidation(address.primary),
    street_address_1: requiredFieldValidation(address.street_address_1),
  }));
  const hasPrimaryAddress = !values.addresses || values.addresses.some(address => address.primary === '1');
  addresses._error = hasPrimaryAddress ? null : I18n.t('physicians.form.primaryAddressError');

  const hasDeaNumberLicense = !values.licenses || !isOhMetrc || values.licenses.some(
    license => license.license_type === DEA_NUMBER && Number(license.active)
  );
  const licenses =  (values.licenses || []).map(license => ({
    license_number: requiredFieldValidation(license.license_number),
    active: requiredFieldValidation(license.active),
  }));
  licenses._error = hasDeaNumberLicense ? null : I18n.t('physicians.form.deaNumberIsRequired');

  return {
    first_name: requiredFieldValidation(values.first_name),
    last_name: requiredFieldValidation(values.last_name),
    active: requiredFieldValidation(values.active),
    clinic_id: requiredFieldValidation(values.clinic_id),
    addresses,
    licenses,
  };
}

function validatePostalCode(value, countryCode) {
  let validationRule = ZIPCODE_REG_EXP;
  switch (countryCode) {
  case 'CA':
    validationRule = CA_POSTAL_CODE_REG_EXP;
    break;
  case 'AU':
    validationRule = AU_POSTAL_CODE_REG_EXP;
    break;
  case 'NZ':
    validationRule = NZ_POSTAL_CODE_REG_EXP;
    break;
  case 'UY':
    validationRule = UY_POSTAL_CODE_REG_EXP;
    break;
  case 'CO':
    validationRule = CO_POSTAL_CODE_REG_EXP;
    break;
  case 'ZA':
    validationRule = ZA_POSTAL_CODE_REG_EXP;
    break;
  case 'US':
  case 'IT':
  case 'ES':
  default:
    validationRule = ZIPCODE_REG_EXP;
  }
  if(value && !value.match(validationRule)){
    return I18n.t('common.form.postalCodeIncorrectFormat');
  }
}

export default validate;
