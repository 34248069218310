import * as types from './actionTypes';

export default [
  // {type: '@@redux-form/CHANGE', wait:1},
  {type: '@@redux-form/UNREGISTER_FIELD', wait: 50},
  {type: '@@redux-form/REGISTER_FIELD', wait: 200}, // will be applied only for "setupCultivationLocations" form
  {type: types.GET_DATA_SUCCESS, wait: 500},
  {type: types.GET_ITEM_SUCCESS, wait: 500},
  {type: types.SET_DATA, wait: 1},
  {type: types.SET_ITEM, wait: 1},
  {type: types.DELETE_DATA, wait: 1},
  {type: types.EDIT_DATA, wait: 1},
  {type: types.UNION_DATA, wait: 1},
  {type: types.PUSH_DATA, wait: 1},
  {type: types.ADD_SELECTED_DATA, wait: 1},
  {type: types.REMOVE_SELECTED_DATA, wait: 1},
  {type: types.CLEAR_SELECTED_DATA, wait: 1},
  {type: types.SET_SELECTED_DATA, wait: 1},
  {type: types.ADD_SELECTED_ITEMS, wait: 1},
  {type: types.REMOVE_SELECTED_ITEMS, wait: 1},
  {type: types.CLEAR_SELECTED_ITEMS, wait: 1},
  {type: types.SET_SELECTED_ITEMS, wait: 1},
  {type: types.SERVER_DATA_UPDATE_AVAILABLE, wait: 500},
];
