import {createSelector} from 'reselect';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import find from 'lodash.find';
import map from 'lodash.map';
import {LEAF} from '../../constants/integrations';
import {getIntegrationSettings, getStateIntegrator} from './commonSelectors';
import {getConstants} from '../constantsSelectors';
import {WA_OTHER_MATERIAL} from '../../constants/integration/leafWa/leafWaInventoryTypes';
import {leafWaNonMedCompliantCategories} from '../../constants/leaf';

const getActiveFacility = state => state && state.facility || {};

// CBYTES-1232 - LEAF PA logic check front end selectors
// use has_leaf_pa_config_pack_and_closed_loop instead of integrator === LEAF
export const isLeafIntegrator = createSelector(
  [getStateIntegrator, getActiveFacility],
  (integrator, facility) => facility.has_leaf_pa_config_pack_and_closed_loop || integrator === LEAF
);

export const getLeafSettings = createSelector(
  [getIntegrationSettings, isLeafIntegrator],
  (settings, isLeaf) => isLeaf && settings.integration_leaf_settings && settings.integration_leaf_settings.value || null
);

// CBYTES-1232 - LEAF PA logic check front end selectors
// use has_leaf_pa_config_pack_and_closed_loop instead of settings.state === 'pa'
export const isPaLeafIntegrator = createSelector(
  [getActiveFacility],
  (facility) => facility.has_leaf_pa_config_pack_and_closed_loop || false
);
// export const isPaLeafIntegrator = createSelector(
//   [getLeafSettings],
//   settings => Boolean(settings && settings.state === 'pa')
// );

export const isWaLeafIntegrator = createSelector(
  [getLeafSettings],
  settings => Boolean(settings && settings.state === 'wa')
);

export const getWaHarvestTypeOptions = createSelector(
  [getConstants],
  (constants) => {
    const types = get(constants, `integration.leaf.WA.harvest_lot_types`, {});

    return map(types, (key, value) => ({
      text: I18n.t(`harvestPackages.form.leafWa.${value}`),
      code: value,
      value: key,
    }));
  }
);

export const getWaMaterialTypeOptions = createSelector(
  [getConstants],
  (constants) => {
    const types = get(constants, `integration.leaf.WA.material_types`, {});

    return map(types, (key, value) => ({
      text: I18n.t(`harvestPackages.form.leafWa.${value}`),
      code: value,
      value: key,
    }));
  }
);

export const isLeafSetupModeEnabled = createSelector(
  [isLeafIntegrator, getLeafSettings],
  // CBYTES-1232 - LEAF PA logic check front end selectors
  // Leaf PA no longer use setup mode flag, so we flag it as false
  (isLeaf, settings) => Boolean(false)
  // (isLeaf, settings) => Boolean(isLeaf && settings && settings.setup_mode)
);

// Check for existing and eligible test results for various cases
export const checkPackageForLeafWaAndPa = (inputObject, props) => {
  const {item, testResults, meta, productSubTypes, salesOrder, selectedItem, itemMaster} = inputObject;
  const {isLeafPaBypassTransferValidationForSeedsAndClonesEnabled, currentFacilityType, isPaRemediationLabelsToggled, isReturn, integrationState} = props;
  const isRetail = () => {
    if (!salesOrder || !salesOrder.partner_facility) return true;
    return ['dispensary'].indexOf(salesOrder.partner_facility.state_integration_type) > -1;
  };
  const isLab = () => {
    if (!salesOrder || !salesOrder.partner_facility) return true;
    return ['lab'].indexOf(salesOrder.partner_facility.state_integration_type) > -1;
  };

  const isInternalManufacturer = () => {
    const is_internal = get(salesOrder, 'partner_facility.partner.is_internal_partner') === 1;
    const is_manufacturer = get(salesOrder, 'partner_facility.facility.type') === 'manufacturing';
    return  is_internal && is_manufacturer;
  };
  const isExEl = (testResult) => {
    return testResult && testResult.lab_result && testResult.lab_result.extraction_eligible;
  };
  const isRetestEl = (testResult) => {
    return testResult && testResult.lab_result && testResult.lab_result.retest_eligible;
  };
  const isSubcategoryExemptFromLabResults = (itemMaster) => {
    if (!(itemMaster && itemMaster.category && itemMaster.subcategory)) return false;

    return itemMaster.category.category_code === 'PLANTS' &&
      ['SEED', 'CLONE', 'PLANT_TISSUE', 'TISSUE_CULTURE'].includes(itemMaster.subcategory.subcategory_code);
  };

  // Allow LeafPA to transfer Seeds and Clones without lab results
  if (
    isLeafPaBypassTransferValidationForSeedsAndClonesEnabled &&
    currentFacilityType !== 'dispensary' &&
    !isRetail() &&
    isSubcategoryExemptFromLabResults(itemMaster)) {
    return false;
  }

  //if this is a return to GP and the test results no longer exist or have fallen off this allows a return without lab passing lab results CBYTES-2418
  if (
    isReturn &&
    currentFacilityType == 'dispensary' &&
    integrationState.isPaLeaf) {
    return false;
  }

  // Allow those with lab result status === passed or is set and package not on hold
  const isInValidTestState = (testResult) => {
    const hasTestResult = testResult && testResult.lab_result && testResult.lab_result.status;
    return (hasTestResult && testResult.lab_result.status === 'passed') || (hasTestResult && item && item.on_hold !== undefined && !item.on_hold);
  };
  const isFlower = (item) => {
    return (item.item_category_code === 'FLOWER');
  };
  const isNonMedicatedItem = (item) => {
    // Intentionally did not use is_mediacted - found case where that was set
    return (item.item_category_code === 'UNMEDICATED');
  };

  if (isNonMedicatedItem(item)) return false;
  if (item.is_waste === 1) return false;
  if (selectedItem.is_for_extraction && item.integration_type == WA_OTHER_MATERIAL) return false;// if for extraction and other_material or other_material_lots  you can transfer it
  const isOnHold = item && item.on_hold !== undefined && item.on_hold;

  let testResult = find(testResults, ['item_id', item.id]);

  if (typeof testResult === 'undefined') {
    testResult = Object.values(testResults[0]).find((result) => result.item_id === item.id);
  }
  // subtype for leaf is assigned on sales order.  props.meta holds only the sub_type (extraction or finished)
  // productSubTypes are the tests for either extract or finished
  // extract always returns true, finished requires a passed lab result which implies that any product
  // being sent for testing must be of sub type extraction because it will never satisfy the transfer rules
  const productSubType = meta && meta.product_sub_type ? productSubTypes.find((type) => type.value === meta.product_sub_type) : false;
  const isItemValid = (testResult && productSubType)
    ? (typeof productSubType.comparison === 'function')
      ? productSubType.comparison(testResult)
      : testResult[productSubType.comparison] === meta.product_sub_type
    : testResult && !meta;

  const testPassed = isInValidTestState(testResult);
  const exEl = isExEl(testResult);
  const retestEl = isRetestEl(testResult);
  // Grow can send failed Flower to internal Manufacturing for extraction to a remediated concentrate
  const canReceiveFailedFlower = isFlower(item) && currentFacilityType === 'grow' && isInternalManufacturer() && isPaRemediationLabelsToggled;
  // secondary check to blocks all on hold transfers not going to a lab or Flower transfers from Grow to Internal manufacturers
  if (isOnHold && !(isLab() || canReceiveFailedFlower)) return 'onHold';
  if (!isItemValid) return 'noPassedTest';
  if (isRetail() && !testPassed) return 'noPassedTest'; // Retail doesn't get those not passed
  if (!isLab() && !isRetail() && !canReceiveFailedFlower && !testPassed && !exEl) return 'noPassedTest'; // Cult/Prod gets Passed and failed but extraction eligible
  if (isLab() && testPassed) return 'forLabAlreadyPassed'; // Lab doesn't get packages that passed tests
  if (isLab() && !retestEl) return 'forLabNoRetest'; // Lab doesn't get packages that can't be retested

  return false;
};

export const isValidPackageForLeafWaAndPa = (inputObject) => {
  return !checkPackageForLeafWaAndPa(inputObject);
};
//Leaf WA non medically compliant categories
export const isNonMedicallyCompliantCategories = (item) => {
  return leafWaNonMedCompliantCategories.includes(item.item_category_code);
};
