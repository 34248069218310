import React from 'react';
import {I18n} from 'react-redux-i18n';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {FaQuestionCircle} from 'react-icons/fa';

const DaysInPhaseHeaderColumn = () => {
  return (
    <span>
        {I18n.t('plants.table.daysInPhase')}&nbsp;
      <OverlayTrigger
        placement='top'
        overlay={<Tooltip id='plants-table-days'>{I18n.t('plants.table.daysInPhaseDescription')}</Tooltip>}
      >
          <FaQuestionCircle/>
      </OverlayTrigger>
    </span>
  );
};

export default DaysInPhaseHeaderColumn;
