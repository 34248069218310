import {requiredFieldValidation, minValidation} from '../../../common/form/redux-form/validations';

export const validate = (values, props) => {
  const errors = {};
  errors.splits = [];
  const usedWeight = values.splits.reduce((acc, split) => {
    if(!isNaN(parseFloat(split.weight))) acc += parseFloat(split.weight);
    return acc;
  }, 0);
  values.splits.forEach((split, index) => {
    const error = {};
    error.weight = requiredFieldValidation(split.weight, 'weight');
    if(!error.weight){
      error.weight = minValidation(split.weight, 'weight', 0.01);
    }
    if(!error.weight && usedWeight > values.current_weight){
      error.weight = 'Sum of weights may not exceed original weight.';
    }
    error.location = requiredFieldValidation(split.location, 'location');
    errors.splits.push(error);
  });
  return errors;
};

export default validate;
