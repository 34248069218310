import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import get from 'lodash.get';
import {Row, Col, Alert, Card} from 'react-bootstrap';
import {FaExclamationTriangle} from 'react-icons/fa';
import InlineCheckBox from '../../../common/form/InlineCheckBox';

const SubCategories = (props) => {
  const {fields, meta, isTouch, onChangeSubcategories, integrationState, validInputAndOutputJurisdictionCategories} = props;
  return (
    <div>
      {meta && isTouch && meta.error ? <Row>
        <Col md={11}>
          <Alert variant='danger'>
            <FaExclamationTriangle />
            <span className='padding-left'>{meta.error}</span>
          </Alert>
        </Col>
      </Row> : null}
      <Card className='p-2' style={{backgroundColor: '#eee'}} body>
        <Row>
          {fields.map((line, index) => {
            const subcategory = fields.get(index);
            if (integrationState.isWaLeaf && subcategory.display_name === 'Plants: Waste') return null;
            if (integrationState.isWaLeaf && subcategory.display_name === 'Harvests: Test Sample') return null;
            if (integrationState.isWaLeaf && subcategory.display_name === 'Concentrate: Oil') return null;

            const validityType = fields.name.indexOf('input') !== -1 ? 'inputs' : 'outputs';
            const validCategoryNames = get(validInputAndOutputJurisdictionCategories, validityType, []);
            const isValid = validInputAndOutputJurisdictionCategories.disabled
              ? true
              : validCategoryNames.length
                ? validCategoryNames.indexOf(subcategory.jurisdiction_category_code) !== -1
                : false;
            return (
              <Col key={line} xs={6} sm={4} md={3}>
                <Field
                  name={`${line}.checked`}
                  component={InlineCheckBox}
                  props={{
                    label: subcategory.display_name,
                    groupClassName:'inline-checkbox',
                    onClick: () => onChangeSubcategories(fields.name),
                    disabled: subcategory.disabled || !isValid
                  }}
                />
              </Col>);
          })}
        </Row>
      </Card>
    </div>
  );
};

SubCategories.propTypes = {
  fields: PropTypes.object.isRequired,
  getFormValue: PropTypes.func.isRequired,
  meta: PropTypes.object.isRequired,
  isTouch: PropTypes.bool.isRequired,
  onChangeSubcategories: PropTypes.func.isRequired,
  integrationState: PropTypes.object.isRequired,
  validInputAndOutputJurisdictionCategories: PropTypes.object.isRequired,
};

export default SubCategories;

