import React from 'react';
import PropTypes from 'prop-types';
import InternationalDateTimeStatic from './InternationalDateTimeStatic';
import {isMoment} from '../../../util/dateHelpers';

const InternationalDateStatic = (props) => {
  const datetimeProps = {
    displayTime: false,
    displayDate: true,
    useSystemDate: false,
    adjustForClientTimezone: true,
    ...props};
  const isMomentObject = isMoment(props.children);
  const isValid = isMomentObject ? props.children.isValid() : false;
  return (<InternationalDateTimeStatic {...datetimeProps}>
    {
      (isMomentObject && isValid) || !isMomentObject
        ? props.children
        : null
    }
  </InternationalDateTimeStatic>);
};

InternationalDateStatic.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default InternationalDateStatic;
