import get from 'lodash.get';


export const isFunctionallyEmpty = (data) => {
  if(data === undefined || data === null) return true;
  if(!Array.isArray(data) && typeof data === 'object'){
    if(Object.keys(data).length === 0) return true;
  }
  if(Array.isArray(data)){
    if(data.length === 0) return true;
  }
  return false;
};

export const functionallyEmpty = (data, pathArray) => {
  const value = get(data, pathArray);
  return isFunctionallyEmpty(value);
};

/***
 * bigram = adjacent items eg adjacent letters in string
 * Used by getStringSimilarityRating function
 * @param string
 * @returns {any[]}
 */
const getBigrams = (string) => {
  let ref, i, j;
  const s = string.toLowerCase();
  const v = new Array(s.length - 1);
  for (i = j = 0, ref = v.length; j <= ref; i = j += 1) {
    v[i] = s.slice(i, i + 2);
  }
  return v;
};

/***
 * Returns number where the higher the value the more closely related 2 strings are
 * Am using 0.5 as the cutoff for useful comparison
 * @param str1
 * @param str2
 * @returns {number}
 */
export const getStringSimilarityRating = (str1, str2) => {
  let hit_count, j, k, len, len1, pairs1, pairs2, union, x, y;
  if (str1.length > 0 && str2.length > 0) {
    pairs1 = getBigrams(str1);
    pairs2 = getBigrams(str2);
    union = pairs1.length + pairs2.length;
    hit_count = 0;
    for (j = 0, len = pairs1.length; j < len; j++) {
      x = pairs1[j];
      for (k = 0, len1 = pairs2.length; k < len1; k++) {
        y = pairs2[k];
        if (x === y) {
          hit_count++;
        }
      }
    }
    if (hit_count > 0) {
      return (2.0 * hit_count) / union;
    }
  }
  return 0.0;
};

export const makeQueryString = (name, ids) => {
  return '( ' + ids.reduce((acc, val) => {
    const filterString = `${name}: "${val}"`;
    return acc === '' ? filterString : acc + ' OR ' + filterString;
  }, '') + ')';
};


/**
 * Handles case where passed in string might not be a string or may be invalid json.
 * @param jsonString
 * @returns {*}
 */
export const safelyParseJsonString = (jsonString) => {
  if(typeof jsonString !== 'string') return {};
  if(jsonString.trim() === '') return {};
  try{
    return JSON.parse(jsonString);
  } catch(e) {
    return {};
  }
};



export const getHashFromString = (string) => {
  let hash = 0, i, chr;
  if (string.length === 0) return hash;
  for (i = 0; i < string.length; i++) {
    chr   = string.charCodeAt(i);
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export const getArrayIntersection = (arrayOfArrays) => {
  if(!Array.isArray(arrayOfArrays)){
    return [];
  }
  try{
    return arrayOfArrays.reduce((a, b) => a.filter(c => b.includes(c)));
  } catch(e) {
    console.warn(e); //eslint-disable-line
    return [];
  }

};

export const arrayIsWithinTargetArray = (inputArray, targetArray) => {
  if(!Array.isArray(inputArray)){
    return false;
  }
  try{
    return inputArray.every(v => targetArray.includes(v));
  } catch(e){
    console.warn(e); //eslint-disable-line
    return false;
  }
};


