import { createSelector } from 'reselect';
import {formValueSelector, getFormValues} from 'redux-form';
import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';
import moment from 'moment';
import {
  convertDbDateToFormInputDate,
  convertDbToClientTZ,
  formatInputDate
} from '../util/dateHelpers';
import * as dataNames from '../constants/dataNames';
import * as itemNames from '../constants/itemNames';
import { getPartners, getPartnerFacilities } from './partnersSelectors';
import { getSelectedProducts, getFoundPackages } from './productsSelectors';
import { getSelectedPlants } from './plantsSelectors';
import { isMedicated } from './itemMastersSelectors';
import { getTimezone } from './timezoneSelectors';
import { isLeafIntegrator } from './integration/leafSelectors';
import { nonTestableInventoryTypes } from '../constants/integration/biotrack/biotrackInventoryTypes';
import { getIntegrationState } from './integration/integrationSelectors';
import {
  getFacilityHasModule,
  isLeafPaConfigPackClosedLoopFacility,
} from './facilitiesSelectors';
import {completionStatuses} from '../components/test-results/common/completionStatuses';
import { safelyParseJsonString } from '../util/dataHelpers';
import {getComplianceSettings} from './complianceSettingsSelectors';
import {getCategories} from './categorySelectors';
import {getSubcategories} from './subcategoriesSelectors';
import {isFeatureEnabled} from './featureToggles';
import {getSettingsHash, getSubCategorySettingsArray} from './settingsSelectors';
import {
  BASE_DEFAULT_DIMENSIONS,
  GMP_PESTICIDES_DEFAULT_DIMENSIONS, TERPENE_PROFILE_DEFAULT_DIMENSIONS,
  THICKENING_AGENTS_DEFAULT_DIMENSIONS, TOTAL_TERPENE_KEY
} from '../constants/testResults';

export const getTestResultGroups = (state) => state[dataNames.testResultGroups];
export const getTestResults = (state) => state[dataNames.testResults];
export const getAverageTestResults = (state) => state[dataNames.averageTestResults];
export const getTestResult = (state) => state[itemNames.testResult];
export const getFoundPlants = (state) => state[dataNames.plants];
export const getTestResultMappingRaw = (state) => state[itemNames.testResultMapping];
export const getTestResultConfig = (state) => state[itemNames.testResultConfiguration];
const getTestedPlants = (state) => state[dataNames.testedPlants];
const getTestedPackages = (state) => state[dataNames.testedPackages];
const getTestResultId = (_, props) => props.params ? Number(props.params.id) : Number(props.item.lab_results_id);
const getReferences = (state, props) => formValueSelector(props.form)(state, 'references') || [];
const getInheritedLabResult = (state, props) => state[itemNames.inheritedLabResult];
const getPackageId = (_, props) => props.package_id;
const getPackageIds = (_, props) => (Array.isArray(props) ? props.map((prop) => prop.package_id) : props.package_id);
const getReferenceItem = (_, props) => props.item;

const getTestResultFormInContext = (_, props) => props.form;
const getTestResultFormValues = (state, props) => {
  const formName = getTestResultFormInContext(state, props);
  return getFormValues(formName)(state);
};

const getSelectedTestType = createSelector([getTestResultFormValues],(formValues) => {
  if (!formValues) {
    return false;
  }
  return formValues[`test_type`];
});

export const isPickableDate = createSelector(
  [isFeatureEnabled],
  (isFeatureEnabled) => {
    return (currentDate) => {
      if (!isFeatureEnabled('feature_pa_hb_1024_lab_enhancements')) return true;
      return currentDate.isAfter(moment()); // stability testing date validation
    };
  }
);

export const isTestTypeEditingDisable = createSelector(
  [getTestResult],
  (testResult) => {
    if (testResult.test_type && testResult.completion_status === 'completed') {
      return true;
    }
    return false;
  }
);

export const getTestResultTestTypes = createSelector(
  [getComplianceSettings],
  settings => Object.values(get(settings, 'inv_lab_test_result_test_types.value', [])).map(test_type => ({value: test_type, text: I18n.t(test_type)}))
);

export const getEnableTestResultCreationJurisdictionCategoryWhitelist = createSelector(
  [getSettingsHash],
  settings => get(settings, 'inv_lab_enable_test_result_creation_jurisdiction_category_whitelist.value', false) == true
);

export const getTestResultCreationJurisdictionCategoryWhitelist = createSelector(
  [getSettingsHash],
  settings => get(settings, 'inv_lab_test_result_creation_jurisdiction_category_whitelist.value')
);

export const getDisallowTestResultCreation = createSelector(
  [getEnableTestResultCreationJurisdictionCategoryWhitelist, getTestResultCreationJurisdictionCategoryWhitelist, getSubCategorySettingsArray, isFeatureEnabled],
  (enableTestResultCreationJurisdictionCategoryWhitelist, testResultCreationJurisdictionCategoryWhitelist, subcategorySettings, isFeatureEnabled) => (selectedProducts) => {
    if (isFeatureEnabled('feature_ut_qa_change_request') && selectedProducts && selectedProducts.length && enableTestResultCreationJurisdictionCategoryWhitelist && subcategorySettings.length) {
      return selectedProducts.some(selectedProduct => {
        const subcategorySetting = subcategorySettings.find(subcategorySetting => {
          return subcategorySetting.subcategory_code === selectedProduct.subcategory_code;
        });
        return testResultCreationJurisdictionCategoryWhitelist.indexOf(get(subcategorySetting, 'jurisdiction_category_code')) < 0;
      });
    }

    return false;
  }
);

export const getTestResultMapping = createSelector(
  [getTestResultMappingRaw],
  (mapping) => ({
    ...mapping,
    internal_identifier: mapping.internal_identifier && Number(mapping.internal_identifier)
  })
);

export const isIsolocityStatusPassed = createSelector(
  [getTestResult, getIntegrationState],
  (testResult, integrationState) => {
    if (integrationState.isIsolocity && testResult.isolocity_status === 'passed') {
      return true;
    }
  }
);

export const isTestResultEditingDisabled = createSelector(
  [getTestResult, getTestResultMapping, getTestResultId, getIntegrationState],
  (testResult, testResultMapping, id, integrationState) => {
    if (testResult && testResult.state_integration_source !== null) {
      return true;
    }

    if (!integrationState.isLeaf) {
      return false;
    }
    return (
      id === testResult.id &&
      id === testResultMapping.internal_identifier &&
      testResultMapping.external_identifier !== ''
    );
  }
);

export const transformTestResults = createSelector(
  [getTestResults],
  (testResults) => {
    if (getConfigurableDimensionsEnabled) {
      return testResults.map((testResult) => {
        Object.assign(testResult, safelyParseJsonString(get(testResult, 'dimension_results')));
        return testResult;
      });
    }
    return testResults;
  }
);

export const normalizeTestResults = createSelector(
  [getTestResultGroups, getTestResults, getTimezone],
  (groups, testResults, timezone) => {
    return groups.map((group) => {
      const testingDate = get(group, 'testing_date', false);
      const docList = get(group, 'doclist', false);
      if(!docList){
        return Object.assign({}, group, {
          testing_date:  testingDate ? convertDbDateToFormInputDate(testingDate, timezone) : ''
        });
      }
      if (getConfigurableDimensionsEnabled) {
        group.doclist.docs.map((result) => {
          Object.assign(result, safelyParseJsonString(get(result, 'dimension_results')));
          return result;
        });
      }
      const references = testResults.filter((testResult) => testResult.lab_results_id === group.groupValue);
      return {
        ...group.doclist.docs[0],
        groupValue: group.groupValue,
        references,
        testing_date: convertDbDateToFormInputDate(group.doclist.docs[0].testing_date, timezone)
      };
    });
  }
);

export const getAverageTableData = createSelector(
  [getAverageTestResults],
  (testResults) =>
    testResults.map((test) => ({
      ...test,
      id: test.product_id
    }))
);

export const getTestResultDetails = createSelector(
  [getTestResult, getTestResultId, getPartners, getPartnerFacilities, getTimezone],
  (testResult, id, partners, partnerFacilities, timezone) => {

    if (testResult.id !== id) {
      return {
        references: []
      };
    }

    const partner = partners.find((partner) => partner.id === testResult.lab_partner_id);
    const forPartner  = partnerFacilities.find((partner) => partner.id === get(testResult, 'for_partner_facility_id'));

    return {
      ...testResult,
      partner_name: partner && partner.name,
      for_partner_name: get(forPartner, 'facility_name'),
      testing_date: convertDbDateToFormInputDate(testResult.testing_date, timezone),
      // Stability test date is stored without time, so convert with UTC timezone
      stability_test_date: convertDbDateToFormInputDate(testResult.stability_test_date, 'UTC'),
    };
  }
);

export const getTestResultData = createSelector(
  [getTestResult, getPartners, getTimezone],
  (testResult, partners, timezone) => {
    const partner = partners.find((partner) => partner.id === testResult.lab_partner_id);
    return {
      ...testResult,
      partner_name: partner && partner.name,
      testing_date: convertDbDateToFormInputDate(testResult.testing_date, timezone),
      // Stability test date is stored without time, so convert with UTC timezone
      stability_test_date: convertDbDateToFormInputDate(testResult.stability_test_date, 'UTC'),
    };
  }
);

const getIsPlantsProp = (_, props) => get(props, 'isPlants', false);

const mapTestResultsTargets = (sourceObject, property, isPlants) => {

  const plantMap = {
    reference_id: 'id',
    reference_type: '--plant_group', // double dash denotes literal - not a key
    lot_number: 'planting_batch_id',
    package_code: 'plant_id',
    stage_id: 'stage_id',
    reference_is_active: (sourceObject) => {
      const keyFields = ['is_destroyed', 'is_harvested', 'is_packaged'];
      return keyFields.reduce((acc, field) => {
        if(!acc) return acc;
        if(sourceObject[field]){
          return false;
        }
        return acc;
      }, true);
    },
    reference_name: 'plant_id',
  };

  const packageMap = {
    reference_id: 'package_id',
    reference_type: '--package', // double dash denotes literal - not a key
    lot_number: 'lot_number',
    package_code: 'package_code',
    reference_name: 'package_code',
    // NOTE: we should use item_name instead of complicated logic for production name showing
    product_name: 'item_name',
    subcategory_id: 'subcategory_id',
    tag_requested: 'tag_requested',
    state_integration_tracking_id: 'state_integration_tracking_id',
    category_id: 'category_id',
    reference_is_active: 'active',
  };

  const useMap = isPlants ? plantMap : packageMap;

  const key = get(useMap, property, null);
  return !key
    ? null
    : typeof key === 'function'
      ? key(sourceObject)
      : key.indexOf('--') !== -1
        ? key.replace('--', '')
        : get(sourceObject, key);
};

export const getAddTestResultsInitialValues = createSelector(
  [getSelectedProducts, getSelectedPlants, getFacilityHasModule, getIsPlantsProp, getInheritedLabResult, getPartners, isLeafIntegrator],
  (selectedProducts, selectedPlants, facilityHasModule, isForPlants, inheritedLabResult, partners, isLeaf) => {

    const collection = isForPlants ? selectedPlants : selectedProducts;

    const reduceHandler = (acc, object) => {
      if ((!isMedicated(object) || !object.lot_id) && !isForPlants) return acc;
      if (!acc.some((reference) => reference.reference_id === object[isForPlants ? 'id' : 'package_id'])) {
        acc.push({
          reference_id: mapTestResultsTargets(object, 'reference_id', isForPlants),
          reference_type: mapTestResultsTargets(object, 'reference_type', isForPlants),
          reference_name: mapTestResultsTargets(object, 'reference_name', isForPlants),
          reference_is_active: mapTestResultsTargets(object, 'reference_is_active', isForPlants),
          plant_phase_id: mapTestResultsTargets(object, 'stage_id', isForPlants),
          lot_number: mapTestResultsTargets(object, 'lot_number', isForPlants),
          package_code: mapTestResultsTargets(object, 'package_code', isForPlants),
          product_name: mapTestResultsTargets(object, 'product_name', isForPlants),
          subcategory_id: mapTestResultsTargets(object, 'subcategory_id', isForPlants),
          tag_requested: mapTestResultsTargets(object, 'tag_requested', isForPlants),
          state_integration_tracking_id: mapTestResultsTargets(object, 'state_integration_tracking_id', isForPlants),
          category_id: mapTestResultsTargets(object, 'category_id', isForPlants),
        });
      }
      return acc;
    };
    const cleanInheritedLabResult = Object.assign({}, inheritedLabResult, {inherited_categories: undefined});


    const result = {
      medical_conditions: [],
      completion_status: facilityHasModule('TESTING_LAB') ? completionStatuses.inProgress.value : undefined,
      references: collection.reduce(reduceHandler, []),
      ...cleanInheritedLabResult,
    };
    //This is a request from Leaf PA where all lab_partner_id has to have a value, even if it is from the lab. This adds the lab itself as the lab_partner_id
    if(isLeaf) {
      const setSelfAsLabPartnerId = partners.find((partner) => partner.is_internal_partner === 1 && partner.active === 1);
      const lab_partner_id = setSelfAsLabPartnerId ? setSelfAsLabPartnerId.id : null;
      result.lab_partner_id = lab_partner_id;
    }

    return result;
  }
);

export const getModifyTestResultsInitialValues = createSelector(
  [getTestResult, getTestResultId, getTimezone, getTestedPlants, getTestedPackages, getIsPlantsProp, getInheritedLabResult],
  (testResult, id, timezone, testedPlants, testedPackages, isForPlants, inheritedLabResult) => {
    if (testResult.id !== id) return;

    const testedRecords = isForPlants ? testedPlants : testedPackages;
    const statusFields = [
      'pesticide_status',
      'water_activity_status',
      'microbial_status',
      'mycotoxin_status',
      'heavy_metals_status',
      'foreign_materials_status',
      'residual_solvents_status'
    ];
    const statuses = statusFields.reduce(
      (acc, field) => ({
        ...acc,
        [field]: testResult[field] === null ? null : String(testResult[field])
      }),
      {}
    );
    const medicalConditions = get(testResult, 'medical_conditions', "[]"); //eslint-disable-line
    const cleanInheritedLabResult = Object.assign({}, inheritedLabResult, {inherited_categories: undefined});
    return {
      ...testResult,
      medical_conditions: medicalConditions ? JSON.parse(medicalConditions) : [],
      ...statuses,
      testing_date: convertDbToClientTZ(testResult.testing_date, timezone),
      stability_test_date: formatInputDate(testResult.stability_test_date), // No need to convert to client timezone
      ...makeExpandedLabResultReferences(testResult, testedRecords, isForPlants),
      ...cleanInheritedLabResult,
    };
  }
);

/**
 *
 * Expand the lab result references by concatenating related record data,
 * then group expanded references by current/historical
 *
 * @param testResult
 * @param recordsForReferences
 * @param isForPlants
 * @returns {{historicalReferences: [], references: []}}
 */
const makeExpandedLabResultReferences = (testResult, recordsForReferences, isForPlants) => {
  const groupedReferences = {
    references: [],
    historicalReferences: []
  };

  if (!(testResult && testResult.all_references && recordsForReferences.length)) return groupedReferences;

  // Transform records array into object for faster selection
  const testedRecordsById = recordsForReferences.reduce((testedRecordsById, testedRecord) => {
    testedRecordsById[testedRecord.id] = testedRecord;
    return testedRecordsById;
  }, {});

  // Expand the references by concatenating related record data, and group by current/historical
  return testResult.all_references.reduce((groupedReferences, labResultReference) => {
    const testedRecord = testedRecordsById[labResultReference.reference_id];

    if (!testedRecord) return groupedReferences;

    const expandedReference = {
      ...labResultReference,
      lot_number: isForPlants ? testedRecord.planting_batch_id : get(testedRecord, 'lot.lot_number'),
      package_code: mapTestResultsTargets(testedRecord, 'package_code', isForPlants),
      product_name: isForPlants ? null : get(testedRecord, 'item_master.name'),
      category_id: isForPlants ? null : (labResultReference.category_id) ? labResultReference.category_id : get(testedRecord, 'item_master.category_id'),
      subcategory_id: isForPlants ? null : (labResultReference.subcategory_id) ? labResultReference.subcategory_id : get(testedRecord, 'item_master.subcategory_id')
    };

    const referenceType = labResultReference.reference_is_active === 1 ? 'references' : 'historicalReferences';
    groupedReferences[referenceType].push(expandedReference);

    return groupedReferences;
  }, groupedReferences);
};

export const getModifyTestResultsPayload = (formValues, initialValues) => {
  const { references, historicalReferences, ...testResult } = formValues;
  const remainingReferences = [...references, ...historicalReferences];
  const removed_reference_ids = initialValues.references
    .filter((r) => !remainingReferences.some((p) => p.id === r.id))
    .map((r) => r.id);

  return {
    ...testResult,
    removed_reference_ids,
    references: remainingReferences
  };
};

const getAllFromProps = (_, props) => get(props, 'getAll', false);

export const getPackageOptions = createSelector(
  [getFoundPackages, getFoundPlants, getReferences, getIsPlantsProp, getAllFromProps],
  (packages, plants, references, isForPlants, getAll) => {
    const testTargetKeys = ['reference_id', 'reference_type', 'lot_number', 'package_code', 'product_name',
      'subcategory_id', 'tag_requested', 'state_integration_tracking_id', 'category_id', 'stage_id'];

    // Sometimes we don't want to filter out those already on the form.  Default is to filter.
    const filter = getAll ? () => true : (plant) => !references.some((ref) => ref.reference_id === plant.id);

    if(isForPlants){
      return plants
        .filter(filter)
        .reduce((acc, plant) => {
          if(acc.some((ref) => ref.reference_id === plants.id)){
            return acc;
          }
          const target = testTargetKeys.reduce((acc, key) => {
            acc[key] = mapTestResultsTargets(plant, key, isForPlants);
            return acc;
          }, {});
          acc.push(target);
          return acc;
        }, []);
    }

    return packages.filter((p) => !references.some((r) => r.reference_id === p.package_id));
  }
);

export const getTestResultsByPackageId = createSelector(
  [getTestResults, getPackageId],
  (results, package_id) => results.filter((result) => package_id === result.package_id)
);

export const getTestResultsByPackageIds = createSelector(
  [getTestResults, getPackageIds],
  (results, package_ids) => results.filter((result) => package_ids.includes(result.package_id))
);

export const isValidIsolocityTestResult = (testResult, constants) => {
  const isolocityStatus = get(testResult, 'lab_result.isolocity_status');
  const isolocity_status_passed = get(constants, `integration.isolocity.statuses.passed`);

  return isolocityStatus === isolocity_status_passed;
};

export const getIsolocityErrorMessage = (option) => {
  const isolocityStatus = get(option, 'integration_test_results_status');
  const isolocity_status_in_progress = 'in progress';
  const isolocity_status_passed = 'passed';

  let errorText;

  if (!isolocityStatus) {
    errorText = I18n.t('ei.searchItem.isolocity.noLabResults');
  } else if (isolocityStatus === isolocity_status_in_progress) {
    errorText = I18n.t('ei.searchItem.isolocity.pendingLabResults');
  } else if (isolocityStatus !== isolocity_status_passed) {
    errorText = I18n.t('ei.searchItem.isolocity.failedLabResults');
  }

  return errorText;
};

export const getTestResultsByReferenceId = createSelector(
  [getTestResults, getReferenceItem, getPartners],
  (results, item, partners) => {
    const partnersHash = partners.reduce((acc, partner) => {
      acc[partner.id] = partner;
      return acc;
    }, {});
    const referenceId = get(item, 'reference_id');
    return results.filter((result) => {
      return (referenceId === result.reference_id) || (referenceId === result.package_id);
    }).map((result) => {
      // Solr has this baked in so we only need it when not present.
      if(!result.lot_number){
        result.partner_name = get(partnersHash, `${result.lab_partner_id}`, {}).name;
        result.lot_number = get(item, 'lot_number');
        result.package_code = get(item, 'package_code');
        result.product_name = get(item, 'item_name');
      }
      return result;
    });
  }
);

export const getActiveTestResult = createSelector(
  [getTestResultsByReferenceId, isLeafIntegrator, isLeafPaConfigPackClosedLoopFacility],
  (results, isLeaf, isSunsetLeaf) => {
    return results.reduce(
      (acc, val) => {
        if (isLeaf && !isSunsetLeaf) {
          if (val.state_integration_source === 'leaf' && val.testing_date >= acc.testing_date) {
            return val;
          }
        } else {
          if (val.testing_date >= acc.testing_date) {
            return val;
          }
        }
        return acc;
      },
      { testing_date: '0' }
    );
  }
);

export const biotrackItemHasValidLabResult = (option, isPrBiotrack) => {
  return option && (nonTestableInventoryTypes.indexOf(parseInt(option.integration_type)) !== -1 || isPrBiotrack ? option.test_results_status !== 'failed' : option.test_results_status === 'passed');
};

export const isolocityItemHasValidLabResult = (option) => {
  return option &&
  (nonTestableInventoryTypes.indexOf(parseInt(option.integration_type)) !== -1 ||
    option.integration_test_results_status === 'passed');
};

const getConfigurableDimensionsEnabled = createSelector (
  [getTestResultConfig],
  (config) => {
    return (get(config, 'enabled') === 1);
  });

export const canRenderTestResultSection = createSelector([getTestResultConfig, getSubcategories], (config, subcategories) => {
  return (section, references) => {
    const restrictions = get(config, `dimension_group_subcategory_restrictions.${section}`);
    const reference = references && references[0];

    if (restrictions && reference) {
      const subcategory = subcategories.find(subcategory => subcategory.id === reference.subcategory_id);
      return subcategory && ~ restrictions.indexOf(subcategory.subcategory_code);
    }

    return true;
  };
});

export const getTestResultRequiredDimensions = createSelector(
  [getTestResultConfig, getReferences, getCategories, getSubcategories, getComplianceSettings, getSelectedTestType, isFeatureEnabled],
  (config, selectedPackages, categories, subcategories, complianceSettings, selectedTestType, isFeatureEnabled) => {
    const isProfilesApplied = get(complianceSettings, 'inv_lab_apply_profiles.value', false);
    const profiles = get(complianceSettings, 'inv_lab_testing_profiles.value', []);
    if (!selectedPackages.length || !isProfilesApplied || !Array.isArray(categories) || !Array.isArray(profiles)) {
      return [];
    }

    const selectedCategory = categories.find(cat => cat.id === selectedPackages[0].category_id);
    const selectedCategoryCode = get(selectedCategory, 'category_code', null);
    const selectedSubcategory = subcategories.find(subcategory => subcategory.id === selectedPackages[0].subcategory_id);
    const selectedSubcategoryCode = get(selectedSubcategory, 'subcategory_code', null);

    const profile = profiles.find(profile => profile.apply_to_category_keys && profile.apply_to_category_keys.includes(selectedCategoryCode));
    if (!profile || !profile.testing_categories) {
      return [];
    }
    const testing_category_restrictions = get(config, 'dimension_group_subcategory_restrictions');
    const mapDimensionKey = (dimension) => dimension.dimension_key;
    const iterateSections = (section) => (section.dimensions || []).flatMap(mapDimensionKey);
    return profile.testing_categories.filter(testing_category => {
      const restrictions = get(testing_category_restrictions, testing_category.category_key);

      if (testing_category.except_subcategories_code && testing_category.except_subcategories_code.includes(selectedSubcategoryCode)){
        return false;
      }

      if (isFeatureEnabled('feature_pa_hb_1024_lab_enhancements')) {
        // If applicable_for_test_types is specified for testing category and test type is NOT in the list of applicable test types, don't show category
        if (selectedTestType && testing_category.applicable_for_test_types && !testing_category.applicable_for_test_types.includes(selectedTestType)) {
          return false;
        }
      }

      if (restrictions) {
        return ~ restrictions.indexOf(selectedSubcategoryCode);
      }

      return true;
    }).flatMap(iterateSections);
  }
);

export const getTestResultDimensions = createSelector(
  [getTestResultConfig, getIntegrationState, isFeatureEnabled],
  (config, {isPaLeaf, isIsolocity}, isFeatureEnabled) => {

    //If we have configured values, return them
    const configDimensionsEnabled = get(config, 'enabled', false);
    if (configDimensionsEnabled) {
      // this is a ref
      const dimensionsByCategory = get(config, 'dimensions_by_category', null);

      if (dimensionsByCategory) {

        const normalizedDimensionsByCategories = {};

        Object.keys(dimensionsByCategory).forEach(category => {

          // list sorting categories
          const sortingCategories = ['water_activity', 'terpene_profile', 'heavy_metals', 'microbial', 'mycotoxin', 'pesticides'];
          // sorting by key
          if(sortingCategories.includes(category)){
            dimensionsByCategory[category].sort((a, b) => (a.dimension_key > b.dimension_key) && !b.dimension_key.includes('total') ? 1 : -1);
          }

          const normalizedDimensions = dimensionsByCategory[category].map(test => {
            return {
              name: get(test, 'display_name'),
              display_name: get(test, 'display_name'),
              key: get(test, 'dimension_key'),
              unit: get(test, 'display_uom'),
              display_unit: get(test, 'display_uom', ''),
              precision: get(test, 'precision', 3)
            };
          });

          // so we don't overwrite the ref
          normalizedDimensionsByCategories[category] = normalizedDimensions;
        });
        return normalizedDimensionsByCategories;
      }
    }

    //Otherwise the default list
    const defaultDimensions = {...BASE_DEFAULT_DIMENSIONS};
    const gmpPesticidesFeature = isFeatureEnabled('feature_gmp_pesticides');

    if (gmpPesticidesFeature && isIsolocity) {
      defaultDimensions.pesticides = defaultDimensions.pesticides.concat(GMP_PESTICIDES_DEFAULT_DIMENSIONS);
    }

    if (!isPaLeaf) {
      defaultDimensions.thickening_agents = THICKENING_AGENTS_DEFAULT_DIMENSIONS;
    }

    return defaultDimensions;
  });

export const areTerpeneTotalsEditable = createSelector(
  [getTestResultFormValues],
  (formValues) => {
    // Form isn't fully initialized yet
    if (!formValues) {
      return false;
    }

    for (const dimension of TERPENE_PROFILE_DEFAULT_DIMENSIONS) {
      if (dimension.key === TOTAL_TERPENE_KEY) {
        continue;
      }

      const weightValue = parseFloat(formValues[`${dimension.key}_mg_g`]);
      const percentValue = parseFloat(formValues[`${dimension.key}_percent`]);

      if (weightValue || percentValue) {
        return false;
      }
    }

    return true;
  });

export const getInheritedDimensionCategories = createSelector([getInheritedLabResult], (inheritedLabResult) => {
  return get(inheritedLabResult, 'inherited_categories', []);
});
