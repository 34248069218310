import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import {Row, Col} from 'react-bootstrap';
import * as dataNames from '../../../constants/dataNames';
import * as apiActions from '../../../actions/apiActions';
import FormWrapper from '../../common/form/FormWrapper';
import {unsetItem} from '../../../actions/itemActions';
import * as settingsActions from '../../../actions/core/settingsActions';
import {getVolumeUoms, getWeightUoms} from '../../../selectors/uomsSelectors';
import NumericInput from '../../common/form/NumericInput';
import {UOM_VALUE_PRECISION} from '../../../constants/uoms';
import UomTable from './UomTable';

export class UomConversionFactorPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.redirect = this.redirect.bind(this);
    this.onChangeSampleNumber = this.onChangeSampleNumber.bind(this);

    this.state = {
      mounted: false,
      sampleNumber: 1,
    };
  }

  componentWillMount() {
    Promise.all([
      this.props.actions.getUnpaginatedData('/api/uoms/converted', dataNames.uoms)
    ]).then(
      () => this.setState({mounted: true})
    );
  }

  onChangeSampleNumber(number){
    const value = parseFloat(number);
    const newValue = !isNaN(value) ? value : null;
    this.setState({
      sampleNumber: newValue
    });
  }

  redirect() {
    this.props.actions.goBack();
  }

  render() {
    const {volumeUoms, weightUoms} = this.props;
    return (
      <div>
        <FormWrapper title='uoms.title' goBack={this.redirect}>
          <div
            className='text-muted'
            style={{marginBottom: '1rem'}}
          >
            {I18n.t(`uoms.notes.aboutMicrograms`)}
          </div>
          <Row>
            <Col xs={2}>
              <b>Number For Sample Calculations:</b>&nbsp;&nbsp;
              <NumericInput
                type='number'
                onChange={(value) => {
                  this.onChangeSampleNumber(value);
                }}
                input={{name: ''}}
                meta={{}}
                value={this.state.sampleNumber}
                fractionPartSize={UOM_VALUE_PRECISION}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div
                className='text-muted'
                style={{marginBottom: '2rem'}}
              >
                {I18n.t(`uoms.notes.hint`)}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <UomTable
                uoms={weightUoms}
                sampleNumber={this.state.sampleNumber}
                type='weight'
                unit='GR'
                style={{width: '100%'}}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <UomTable
                uoms={volumeUoms}
                sampleNumber={this.state.sampleNumber}
                type='volume'
                unit='ML'
              />
            </Col>
          </Row>
        </FormWrapper>
      </div>
    );
  }
}

UomConversionFactorPage.propTypes = {
  actions: PropTypes.shape({
    getItem: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired
  }).isRequired,
  uoms: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    volumeUoms: getVolumeUoms(state),
    weightUoms: getWeightUoms(state),
  };
}

function mapDispatchToProps (dispatch) {
  const actions = {
    ...apiActions,
    ...settingsActions,
    goBack,
    unsetItem
  };

  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UomConversionFactorPage);
