import {I18n} from 'react-redux-i18n';

export const TAX_TYPE_CODES = [
  {
    id: '',
    name: '',
  },
  {
    id: 'EXCISE',
    name: I18n.t('taxes.taxTypeCode.excise'),
  },  {
    id: 'STATE',
    name: I18n.t('taxes.taxTypeCode.state'),
  },
  {
    id: 'COUNTY',
    name: I18n.t('taxes.taxTypeCode.county'),
  },
  {
    id: 'MUNICIPAL',
    name: I18n.t('taxes.taxTypeCode.municipal'),
  },
];

export default TAX_TYPE_CODES;
