import React from 'react';
import PropTypes from 'prop-types';
import {FieldArray} from 'redux-form';
import {Row} from 'react-bootstrap';
import AccordionPanel from '../../../common/AccordionPanel';

import OnlineAvailabilityAttributes from './OnlineAvailabilityAttributes';
import FacilitySalesAttributesFieldArray from './FacilitySalesAttributesFieldArray';


const OnlineAvailabilityPanel = (props) => {
  const {onlineAvailabilityOptions, facilityOptions, isRequired, fieldCanModifiedMap} = props;
  return (
    <AccordionPanel defaultExpanded={true} title='products.form.onlineAvailability'>
      <AccordionPanel defaultExpanded={true} title='products.form.organization'>
        <Row>
          <OnlineAvailabilityAttributes
            isOrganizationMode={true}
            fieldName='online_availability.organization'
            fieldLabel='products.form.onlineAvailability'
            onlineAvailabilityOptions={onlineAvailabilityOptions}
            fieldCanModifiedMap={fieldCanModifiedMap}
          />
        </Row>
      </AccordionPanel>
      <AccordionPanel title='products.form.byFacility'>
        <FieldArray name='online_availability.facilities' component={FacilitySalesAttributesFieldArray} props={{
          facilityOptions,
          pushTemplate: {is_default_for_org: 0, available_online: 1, facility_ids: []},
          AttributesComponent: OnlineAvailabilityAttributes,
          attributesComponentProps: {
            onlineAvailabilityOptions,
            isRequired,
            fieldCanModifiedMap,
            fieldLabel: 'products.form.onlineAvailability',
          }
        }}/>
      </AccordionPanel>
    </AccordionPanel>
  );
};

OnlineAvailabilityPanel.propTypes = {
  onlineAvailabilityOptions: PropTypes.array.isRequired,
  facilityOptions: PropTypes.array.isRequired,
  isRequired: PropTypes.bool,
  fieldCanModifiedMap: PropTypes.object
};

OnlineAvailabilityPanel.defaultProps = {
  isRequired: true,
};

export default OnlineAvailabilityPanel;
