import {requiredFieldValidation} from '../../../common/form/redux-form/validations';

export default function (values) {
  const anySelected = values.discount_amount > 0 || values.coupon_applies_to;
  const isLineMode = values.coupon_applies_to === 'line';
  return {
    coupon_applies_to: anySelected && requiredFieldValidation(values.coupon_applies_to),
    discount_amount: anySelected && requiredFieldValidation(values.discount_amount > 0),
    item_master_ids: isLineMode && requiredFieldValidation(values.item_master_ids && values.item_master_ids.length > 0),
  };
}
