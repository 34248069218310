import React from 'react';

const PricingColumn = (cell) => (
  <span>
    {cell.medical ? `Med: ${cell.medical}` : ''}
    {cell.medical && cell.recreational ? <br/> : ''}
    {cell.recreational ? `Rec: ${cell.recreational}` : ''}
  </span>
);

export default PricingColumn;