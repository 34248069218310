import {change, formValueSelector} from 'redux-form';

import {getUnpaginatedData, putItem} from '../../actions/apiActions';
import * as types from '../../constants/actionTypes';
import * as dataNames from '../../constants/dataNames';
import {MODIFY_PACKAGES} from '../../constants/forms';
import {getFormLots} from '../../selectors/forms/modifyPackageFormSelectors';
import {isFormChangeOnField} from './utils';
import { convert } from '../../util/uomHelpers';

const modifyPackageForm = store => next => action => {

  const result = next(action);
  const state = store.getState();
  const {name, type, payload, meta} = action;
  if(state.form[MODIFY_PACKAGES]){
    if(type === types.GET_DATA_SUCCESS && name === dataNames.inventoryItems){
      const lots = payload.data.reduce((acc, item) => {
        if(!acc.find(i => i === item.lot_id)){
          acc.push(item.lot_id);
        }
        return acc;
      }, []);
      if(lots.length){
        store.dispatch(getUnpaginatedData('/api/lots',
          dataNames.lots, null,
          {
            ids: lots,
            partner_facility_source: 'import'
          }
        ));
      }

    }
    if(type === types.GET_DATA_SUCCESS && name === dataNames.lots){
      if(state.lots.length){
        store.dispatch(getUnpaginatedData('/api/partner_facilities',
          dataNames.partnerFacilities,
          {
            buy_from: 1,
            active: 1
          }
        ));
      }
    }

    if (type === types.CHANGE_ITEM_MASTER) {
      const { newId, newName, newUom, currentPackageName } = action;
      const selector = formValueSelector(MODIFY_PACKAGES);
      const { qty, newQty, transacted_qty, uom } = selector(state, currentPackageName);

      store.dispatch(
        change(MODIFY_PACKAGES, `${currentPackageName}.new_item_master_id`, newId));
      store.dispatch(
        change(MODIFY_PACKAGES, `${currentPackageName}.new_item_master_display_name`, newName));

      if (newUom !== uom) {
        store.dispatch(
          change(MODIFY_PACKAGES, `${currentPackageName}.uom`, newUom));
        store.dispatch(
          change(MODIFY_PACKAGES, `${currentPackageName}.qty`, convert(qty, uom, newUom)));
        store.dispatch(
          change(MODIFY_PACKAGES, `${currentPackageName}.itemMasterChanged`, 1));
        store.dispatch(
          change(MODIFY_PACKAGES, `${currentPackageName}.newQty`, convert(newQty, uom, newUom)));
        store.dispatch(
          change(MODIFY_PACKAGES, `${currentPackageName}.transacted_qty`, convert(transacted_qty, uom, newUom)));
      }
    }

    if(action.type === types.REDUX_FORM_CHANGE && meta.form === MODIFY_PACKAGES && meta.field.indexOf('.newQty') !== -1){
      const field = meta.field.replace('newQty', 'qtyChanged');
      store.dispatch(change(MODIFY_PACKAGES, field, 1));
    }

    if (isFormChangeOnField(action, [MODIFY_PACKAGES], 'newQty', true)) {
      const formSelector = formValueSelector(MODIFY_PACKAGES);
      const origQty = formSelector(state, meta.field.replace('newQty', 'qty'));
      const transactQty = Math.round(10000 * (payload - origQty)) / 10000;
      store.dispatch(change(meta.form, meta.field.replace('newQty', 'transacted_qty'), transactQty));
    }

    if(isFormChangeOnField(action, [MODIFY_PACKAGES], 'submitted')){
      const lots = getFormLots(state);
      const lot = lots.find(l => l.id == payload);
      if(lot.partner_facility_id){
        store.dispatch(putItem(`/api/lots/${lot.id}`, {partner_facility_id: lot.partner_facility_id}, null, {success:'packages.form.lotSuccess', failed:'packages.form.lotFailed'}));
      }else{
        return false;
      }
    }
  }
  return result;
};

export default modifyPackageForm;
