import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import CureApiProductFilterReduxForm from './CureApiProductFilterReduxForm';
import {isCureIntegrator, getCurePatientOrderTypeOptions, getCurePatientFormOptions} from '../../../../selectors/integration/cureApiSelectors';

const form = 'cureApiProductFilter';

const CureApiProductFilterComponent = ({isCure, orderTypeOptions, formOptions}) => {
  if (!isCure) {
    return null;
  }
  return (
    <CureApiProductFilterReduxForm
      form={form}
      orderTypeOptions={orderTypeOptions}
      formOptions={formOptions}
    />
  );
};

CureApiProductFilterComponent.propTypes = {
  isCure: PropTypes.bool,
  orderTypeOptions: PropTypes.array,
  formOptions: PropTypes.array,
  customer: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    isCure: isCureIntegrator(state),
    orderTypeOptions: getCurePatientOrderTypeOptions(state, ownProps),
    formOptions: getCurePatientFormOptions(state, ownProps),
  };
}

const CureApiProductFilter = connect(mapStateToProps)(CureApiProductFilterComponent);

export default CureApiProductFilter;
