import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, FieldArray, Field } from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import SubmitSection from '../common/form/SubmitSection';
import {PREPACK_WEIGHT_FACILITY_FORM} from '../../constants/forms';
import validate from './validate';
import PrepackSizes from './PrepackSizes';
import InlineCheckBox from '../common/form/InlineCheckBox';
import WillRender from '../common/concealers/WillRender';
import {uomIsWeight} from '../../util/uomHelpers';

export const SelectPackagesFacilityForm = (props) => {
  const {
    handleSubmit, formValues, submitting, pristine, addPrepackWeight, newWeight, categories,
    appliesToOptions, selectedUom, reset, isFeatureEnabledProductMasters
  } = props;
  const settings = {
    actionSettings: {
      reset: {
        text: I18n.t('common.actions.reset'),
        action: reset,
        type: 'button',
      },
      submit: {
        submitting,
        pristine,
        invalid: false, // always allow to click Save to see all error messages,
        text: I18n.t('prepackWeights.form.save')
      }
    }
  };
  return (
    <form className='prepack-weights-form' onSubmit={handleSubmit} noValidate>
      <Row>
        <Col>
          <FieldArray
            name='sizes'
            component={PrepackSizes}
            categories={categories}
            appliesToOptions={appliesToOptions}
            addPrepackWeight={addPrepackWeight}
            handleSubmit={handleSubmit}
            selectedUom={selectedUom}
            formValues={formValues}
            newWeight={newWeight}
            isFeatureEnabledProductMasters={isFeatureEnabledProductMasters}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className='text-muted'>
            <hr />
            {I18n.t('prepackWeights.form.settingsNotice')}
          </div>
        </Col>
      </Row>
      <WillRender ifTrue={uomIsWeight(selectedUom, false)} >
        <Row>
          <Col md={12}>
            <div>
              <Field
                name={'packaging_seeds'}
                component={InlineCheckBox}
                props={{
                  checked: true,
                  label: I18n.t('prepackWeights.form.packagingSeeds')
                }}
              />
            </div>
            <div>
              <Field
                name={'display_inactivation_reason'}
                component={InlineCheckBox}
                props={{
                  label: I18n.t('prepackWeights.form.displayInactivationReason')
                }}
              />
            </div>
          </Col>
        </Row>
      </WillRender>
      <SubmitSection settings={settings}/>
    </form>
  );
};

SelectPackagesFacilityForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  addPrepackWeight: PropTypes.func,
  newWeight: PropTypes.string,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  categories: PropTypes.array,
  appliesToOptions: PropTypes.array,
  formValues: PropTypes.object,
  selectedUom: PropTypes.string,
  isFeatureEnabledProductMasters: PropTypes.bool,
};

export default reduxForm({
  form: PREPACK_WEIGHT_FACILITY_FORM,
  enableReinitialize: true,
  validate
})(SelectPackagesFacilityForm);
