/* eslint-disable import/no-named-as-default*/
import {connect} from 'react-redux';
import {reduxForm, formValueSelector} from 'redux-form';
import validate from './validateDestruction';
import DestroyPlantForm from './DestroyPlantForm';


export const FormWrapper = reduxForm({
  form: 'destroy-plants',
  validate: validate
})(DestroyPlantForm);

const selector = formValueSelector('destroy-plants');

export default connect (state => {
  const use_overall = selector(state, 'use_overall');
  const plants = selector(state, 'plants');
  const destroyed_weight = selector(state, 'destroyed_weight');

  if(use_overall && plants && destroyed_weight) {
    const dividedValue = Math.floor((destroyed_weight / plants.length) * 100) / 100;
    const topValue = +(destroyed_weight - dividedValue * (plants.length - 1)).toFixed(2);


    plants.forEach((item, index, array) => {
      array[index].destroyed_weight = (index == 0) ? topValue : dividedValue;
    });
  }

  return {
    use_overall,
    plants,
  };
})(FormWrapper);
