import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isPaLeafIntegrator } from '../../../selectors/integration/leafSelectors';
import ContentConcealer from './ContentConcealer';

function mapStateToProps(state, ownProps) {
  const show = ownProps.show !== false;
  return {
    show: isPaLeafIntegrator(state) && show,
  };
}

const LeafPaConcealer = connect(mapStateToProps)(ContentConcealer);

LeafPaConcealer.propTypes = {
  force: PropTypes.bool,
  show: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
  ]),
  alternateComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
  ]),
};

export default LeafPaConcealer;
