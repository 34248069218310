import * as facilityTypes from './facilityTypes';

export const APISICPA = 'apisicpa';
export const LEAF = 'leaf';
export const METRC = 'metrc';
export const CURE_API = 'cureapi';
export const BIOTRACK = 'biotrack';
export const NONE = 'none';

export const integrationPlatforms = {
  METRC,
  LEAF,
  PLATFORM: 'platform'
};

export const showReverseToParentConfig = {
  facilities: [facilityTypes.manufacturing],
  integrations: [integrationPlatforms.METRC], // eg. ['metrc'], ['platform'], ['metrc', 'platform']
};
