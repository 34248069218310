import {SETUP_STORAGE_LOCATIONS_FORM} from '../../constants/forms';
import * as types from '../../constants/actionTypes';

const setChildValue = (fieldName, currentLevel, parentLevel, value, values, indexMap) => {
  if (!values || currentLevel >= 4) {
    return values;
  }
  if (currentLevel > parentLevel) {
    values = values.map((obj, index) => {
      obj[fieldName] = value;
      return obj;
    });
  }
  if (currentLevel === parentLevel) {
    values[indexMap[currentLevel]][fieldName] = value;
  }
  currentLevel++;
  values = values.map((child, index) => {
    if (child && (indexMap[currentLevel - 1] === index || indexMap.length < currentLevel)) {
      return {...child, child_locations: setChildValue(fieldName, currentLevel, parentLevel, value, child.child_locations, indexMap)};
    } else {
      return child;
    }
  });

  return values;
};

const setupStorageLocations = (state, action) => {
  const {payload, type, meta} = action;
  if(meta && meta.form === SETUP_STORAGE_LOCATIONS_FORM){
    if(type === types.REDUX_FORM_CHANGE ){
      if(meta.field.indexOf('location_type') !== -1) {
        const nestedLevel = (meta.field.match(/\./g) || []).length - 1;
        const indexMap = (meta.field.match(/\d/g) || []).map(i => parseInt(i));
        const locations = state.values;
        const value = payload ? payload : false;
        const fields = (state.fields && state.fields.child_locations) ? state.fields : {child_locations: Array(locations.child_locations.length).fill({location_type: {visited: true, touched: true}}, indexMap[0], 1)};
        locations.child_locations = setChildValue('location_type', 0, nestedLevel, value, locations.child_locations, indexMap);
        return {...state, values: locations, fields};
      }
    }
  }
  return state;
};

export default setupStorageLocations;