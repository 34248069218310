import { createSelector } from 'reselect';
import get from 'lodash.get';
import { formValueSelector } from 'redux-form';
import { ANONYMOUS_CUSTOMER_FORM } from '../../constants/forms';
import * as itemNames from '../../constants/itemNames';

const getSalesComplianceSettings = (state) => state[itemNames.salesComplianceSettings];
const getSelectedCustomerType = (state) => formValueSelector(ANONYMOUS_CUSTOMER_FORM)(state, 'type');

const getInitialValues = createSelector(
  [getSalesComplianceSettings, getSelectedCustomerType],
  (salesComplianceSettings, selectedCustomerType) => {
    const hasRecType = (types) => types.indexOf('recreational') !== -1;
    const anonymousCustomerTypes = get(salesComplianceSettings, 'order_anonymous_customer_types.value', []);
    const type = Array.isArray(anonymousCustomerTypes)
      ? anonymousCustomerTypes.length > 1
        ? hasRecType(anonymousCustomerTypes)
          ? 'recreational'
          : anonymousCustomerTypes[0]
        : anonymousCustomerTypes[0]
      : undefined;
    return  {
      gender: 'unspecified',
      birth_date: null,
      type: selectedCustomerType
        ? selectedCustomerType
        : type
    };
  });

export default getInitialValues;
