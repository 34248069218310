import {reduxForm, getFormValues} from 'redux-form';
import {connect} from 'react-redux';
import {TestingComplianceForm} from './TestingComplianceForm';

export const wrapper = reduxForm({
})(TestingComplianceForm);

function mapStateToProps(state) {
  const form = 'testingCompliance';
  const initialValues = {tests: [{}]};
  return {
    form,
    initialValues,
    formValues: getFormValues(form)(state) || initialValues
  };
}

const TestingComplianceFormWrapper = connect(mapStateToProps)(wrapper);

export default TestingComplianceFormWrapper;
