import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {FieldArray} from 'redux-form';
import {Button, ButtonToolbar, Col, Row} from 'react-bootstrap';

import AccountingExportFieldArray from './AccountingExportFieldArray';



const AccountingExportForm = ({handleSubmit, onRedirect, onAdjust, facilityOptions, quickbooksAccountsTypes, exportFormats, change, getFormValue, valid, pristine, submitting}) => (
  <form className='accounting-export-page' onSubmit={handleSubmit}  noValidate={true}>
    <FieldArray
      name='categories'
      component={AccountingExportFieldArray}
      exportFormats={exportFormats}
      facilityOptions={facilityOptions}
      quickbooksAccountsTypes={quickbooksAccountsTypes}
      onAdjust={onAdjust}
      change={change}
      getFormValue={getFormValue}/>
    <Row className='submit-section'>
      <Col xs={12}>
        <div className='button-wrapper'>
          <ButtonToolbar className='float-right'>
            <Button variant='danger' type='submit'>
                {I18n.t('accounting.export.submitButton')}
            </Button>
          </ButtonToolbar>
        </div>
      </Col>
    </Row>
  </form>
);

AccountingExportForm.propTypes = {
  change: PropTypes.func.isRequired,
  getFormValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onRedirect: PropTypes.func.isRequired,
  onAdjust: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  exportFormats: PropTypes.array.isRequired,
  facilityOptions: PropTypes.array.isRequired,
  quickbooksAccountsTypes:PropTypes.array.isRequired,
};

export default AccountingExportForm;
