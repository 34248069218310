import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import {Alert, Col, Row, Button} from 'react-bootstrap';
import {FaExclamationTriangle, FaTrash} from 'react-icons/fa';
import {getProvincesByCountryCode} from '../../../selectors/countrySelectors';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import TextInput from '../../common/form/TextInput';
import AddItem from '../../common/form/AddItem';
import AccordionPanel from '../../common/AccordionPanel';
import {getPostalCodeLabel, getStateLabel} from '../../../util/labelHelpers';
import {stateIsNotRequiredFor} from '../../partners/common/validateOrganization';

const no = [
  { text: I18n.t('customers.create.no'), value: '0' },
];

const yesNo = [
  { text: I18n.t('customers.create.no'), value: '0' },
  { text: I18n.t('customers.create.yes'), value: '1' },
];



const PhysicianAddressesFieldArray = (props) => {
  const {countries, provinces, statuses, inactiveStatuses, primaryAddressIndex, fields, meta, loading, touch, change} = props;

  return (
    <AccordionPanel title={'physicians.form.physicianAddresses'}>
      {!loading && meta && meta.error ?
        <Row>
          <Col xs={12}>
            <Alert variant='danger'>
              <FaExclamationTriangle/>
              <span className='padding-left'>{meta.error}</span>
            </Alert>
          </Col>
        </Row>
        :
        null
      }
      {fields.map((fieldName, fieldIndex) => {
        const values = fields.get(fieldIndex);
        const currentCountry = values && values.country_code;
        const provinceOptions = getProvincesByCountryCode(provinces, currentCountry);
        const yesNoOptions = primaryAddressIndex === -1 || primaryAddressIndex === fieldIndex || values.active !== '1' ? yesNo : no;
        const statusOptions = primaryAddressIndex === -1 || primaryAddressIndex === fieldIndex || values.primary !== '1' ? statuses : inactiveStatuses;

        return (
          <div key={fieldName}>
            <Row>
              <Col xs={6} sm={5} md={3} lg={2}>
                <Field name={`${fieldName}.country_code`} component={ReactSelectInput} props={{
                  label: I18n.t('physicians.form.country'),
                  placeholder: I18n.t('physicians.form.select'),
                  options: countries,
                  textKey: 'name',
                  valueKey: 'country_code',
                  isRequired: true,
                  autoBlur: true,
                  onChange: (value) => {
                    change(`${fieldName}.country_code`,value);
                    touch(`${fieldName}.postal_code`);
                  }
                }}/>
              </Col>
              <Col xs={4} md={3} lg={2}>
                <Field name={`${fieldName}.province_code`} component={ReactSelectInput} props={{
                  label: I18n.t(getStateLabel(currentCountry)),
                  options: provinceOptions,
                  textKey: 'province_code',
                  valueKey: 'province_code',
                  placeholder: I18n.t('common.form.select'),
                  isRequired: !stateIsNotRequiredFor.includes(currentCountry),
                }}/>
              </Col>
              <Col xs={4} sm={3} md={2}>
                <Field name={`${fieldName}.postal_code`} component={TextInput} props={{
                  label: I18n.t(getPostalCodeLabel(currentCountry)),
                  placeholder: currentCountry === 'CA' ? I18n.t('physicians.form.postalCodePlaceholder') : I18n.t('physicians.form.zipcodePlaceholder'),
                  isRequired: true,
                }}/>
              </Col>
              <Col xs={6} sm={5} md={3} lg={2}>
                <Field name={`${fieldName}.city`} component={TextInput} props={{
                  label: I18n.t('physicians.form.city'),
                  placeholder: I18n.t('physicians.form.cityPlaceholder'),
                  isRequired: true,
                }}/>
              </Col>
              <Col xs={4} sm={3} md={2}>
                <Field name={`${fieldName}.primary`} component={ReactSelectInput} props={{
                  label: I18n.t('physicians.form.primary'),
                  options: yesNoOptions,
                  placeholder: I18n.t('common.form.select'),
                  isRequired: true,
                }}/>
              </Col>
              <Col xs={4} sm={3} md={2}>
                <Field name={`${fieldName}.active`} component={ReactSelectInput} props={{
                  label: I18n.t('physicians.form.status'),
                  options: statusOptions,
                  placeholder: I18n.t('common.form.select'),
                  isRequired: true,
                }}/>
              </Col>
            </Row>
            <Row>
              <Col xs={6} sm={5} md={3}>
                <Field name={`${fieldName}.street_address_1`} component={TextInput} props={{
                  label: I18n.t('physicians.form.address', {n: 1}),
                  placeholder: I18n.t('physicians.form.addressPlaceholder'),
                  isRequired: true,
                }}/>
              </Col>
              <Col xs={6} sm={5} md={3}>
                <Field name={`${fieldName}.street_address_2`} component={TextInput} props={{
                  label: I18n.t('physicians.form.address', {n: 2}),
                  placeholder: I18n.t('physicians.form.addressPlaceholder'),
                }}/>
              </Col>
              <Col xs={2} style={{paddingTop: '32px'}}>
                <Button size='sm' variant='danger' onClick={() => fields.remove(fieldIndex)} disabled={fields.length < 2}>
                  <FaTrash/>
                </Button>
              </Col>
            </Row>
          </div>
        );
      })}
      <Row>
        <AddItem addAction={() => fields.push({})} alignMode='right' text='physicians.form.addAddress'/>
      </Row>
    </AccordionPanel>
  );
};

PhysicianAddressesFieldArray.propTypes = {
  fields: PropTypes.shape({
    length: PropTypes.number.isRequired,
    get: PropTypes.func.isRequired,
    map: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
  }),
  clinics: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  provinces: PropTypes.array.isRequired,
  statuses: PropTypes.array.isRequired,
  inactiveStatuses: PropTypes.array.isRequired,
  primaryAddressIndex: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  touch: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
};

export default PhysicianAddressesFieldArray;
