import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isMetrcIntegrator } from '../../../selectors/integration/metrcSelectors';
import ContentConcealer from './ContentConcealer';

function mapStateToProps(state) {
  return {
    show: isMetrcIntegrator(state),
  };
}

const MetrcConcealer = connect(mapStateToProps)(ContentConcealer);

MetrcConcealer.propTypes = {
  force: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
  ]),
  alternateComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
  ]),
};

export default MetrcConcealer;
