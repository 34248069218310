/* eslint-disable import/no-named-as-default */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack} from 'react-router-redux';
import {formValueSelector} from 'redux-form';
import moment from 'moment';
import get from 'lodash.get';

import * as dataNames from '../../../../constants/dataNames';
import * as itemNames from '../../../../constants/itemNames';
import {unsetItem} from '../../../../actions/itemActions';
import {ensureGetUnpaginatedData, getUnpaginatedData, getDataByPost, getItem, postItem} from '../../../../actions/apiActions';
import {fetchMetrcTrackingIdsForSelectInput} from '../../../../actions/integrationActions';
import {getIntegrationState} from '../../../../selectors/integration/integrationSelectors';
import {getAssembly, checkIngredientsUom} from '../../../../selectors/assembliesSelectors';
import {getCompleteInfusionInitialValues, getAssemblyJob, infusionCanBeCompleted} from '../../../../selectors/assemblyJobsSelectors';
import {getEquipments} from '../../../../selectors/equipmentSelectors';
import {getIngredientOptions} from '../../../../selectors/ingredientsSelectors';
import {getFlattenedLocations} from '../../../../selectors/locationsSelectors';
import {getPhases} from '../../../../selectors/phasesSelectors';
import {
  getInventoryItems,
  getIngredientsCost,
  getCompleteProcessingPayload,
  getCurrentTimezone
} from '../../../../selectors/processingSelectors';
import {getCurrentFacilityUserOptions, getCurrentUserPermissions} from '../../../../selectors/usersSelectors';
import FormWrapper from '../../../common/form/FormWrapper';
import CompleteInfusionFormWrapper from './CompleteInfusionFormWrapper';
import InProgressOverlay from '../../../common/InProgressOverlay';
import PrinterModal from '../../../printer/PrinterModal';
import ModalWrapper from '../../../common/ModalWrapper';
import reduxMetrcIdAvailability, {setUnavailableTrackingIdFieldArrayErrors} from '../../../common/form/redux-form/reduxMetrcIdAvailability';
import {addMessage} from '../../../../actions/systemActions';
import * as messageTypes from '../../../../constants/messageTypes';
import {COMPLETE_INFUSION_FORM} from '../../../../constants/forms';
import IntegrationInvalidWarning from '../../processing/common/IntegrationInvalidWarning';
import {getModulesState} from '../../../../selectors/modulesSelectors';
import {
  getIsLabelEnabledForNoCompletedProcess,
  getInventoryComplianceSettings,
  isAllowNegativeInventory
} from '../../../../selectors/complianceSettingsSelectors';
import {doNothing} from '../../../../util/callbackHelpers';
import showNegativeAlert from '../../../common/negative-inventory/showNegativeAlert';
import NegativeInventoryAlert from '../../../common/negative-inventory/NegativeInventoryAlert';
import {hasPackagesTags} from '../../../../selectors/integrationSelectors';
import {roundQtyCurried} from '../../../../selectors/uomsSelectors';
import {isLeafPaConfigPackClosedLoopFacility} from '../../../../selectors/facilitiesSelectors';
import {isFeatureEnabled} from '../../../../selectors/featureToggles';

export class CompleteInfusionPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.showPrinter = this.showPrinter.bind(this);
    this.hidePrinter = this.hidePrinter.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.completeSave = this.completeSave.bind(this);
    this.setNegativeConfirmationState = this.setNegativeConfirmationState.bind(this);
    this.printLabel = this.printLabel.bind(this);

    this.state = {
      formData: {},
      showWarning: false,
      submitDraft: false,
      outputsInViolation: [],
      showNegativeInvConfirmation: {
        show: false,
        onHide: null,
        onConfirm: null,
      },
    };

  }

  componentWillMount() {
    const {
      params: {id},
      actions: {
        getItem,
        unsetItem,
        getDataByPost,
        getUnpaginatedData,
        ensureGetUnpaginatedData
      },
      integrationState: {isWaLeaf, isMetrc}
    } = this.props;

    this.setState({loading: true, submitting: false, showPrinter: false});
    unsetItem(itemNames.assembly);
    unsetItem(itemNames.infusion);

    Promise
      .all([
        //Load Assembly Job Details
        getItem(`/api/assembly_jobs/${id}`, itemNames.infusion, {failed: 'ei.infusions.get.itemFailed'}, {detailed: 1})
          .then(() => {
            const {infusion} = this.props;
            const ids = infusion.inputs.map(input => input.item_id);
            return Promise.all([
              //Load Assembly Details
              getItem(`/api/assemblies/${infusion.assembly_id}`, itemNames.assembly, {failed: 'ei.assemblies.get.itemFailed'}, {detailed: 1})
                .then(() => {
                  const {assembly} = this.props;
                  //Load Assembly Product
                  const promises = [
                    getItem(
                      `/api/item_masters/${assembly.item_master_id}`,
                      itemNames.itemMaster,
                      {failed: 'ei.processingTypes.form.failed'}
                    ).then((itemMaster) => {
                      getItem(`/api/item_masters/${itemMaster.id}/generate_id`, itemNames.itemMasterPackageId, null);
                    }),
                  ];
                  const ids = assembly.ingredients.map(m => m.item_master_id);
                  if (ids.length) {
                    //Load Ingredients, Costings and Ingredient Items
                    promises.push(...[
                      getDataByPost(
                        '/api/costing/item_masters/multiple',
                        {ids},
                        dataNames.costings,
                        {failed: 'ei.processingTypes.form.failed'}
                      ),
                      getDataByPost(
                        '/api/item_masters/multiple',
                        {ids},
                        dataNames.ingredients,
                        {failed: 'ei.processingTypes.form.failed'},
                        {detailed: 1}
                      ),
                      getDataByPost(
                        '/api/items/by_item_master_ids',
                        {ids},
                        dataNames.ingredientItems,
                        {failed: 'ei.processingTypes.form.failed'},
                        {active_only: 1}
                      ),
                    ]);
                  }
                  if (isWaLeaf) {
                    promises.push(...[
                      getUnpaginatedData(`/api/leaf/get_wa_inventory_mapping/${assembly.item_master_id}`, dataNames.waInfusionInventoryTypes)
                    ]);
                  }
                  return Promise.all(promises);
                }),
              //Load Used Items
              getDataByPost('/api/items/multiple', {ids, detailed: 1}, dataNames.inventoryItems, {failed: 'products.get.failed'}, {detailed: 1})
                .then(() => {
                  const {items} = this.props;
                  const ids = items.map(item => item.item_master_id);
                  getUnpaginatedData('/api/item_reservations', dataNames.reservations, {failed: 'reservations.failed'},{in_item_ids: items.map(item => item.id)});
                  //Load Used Products
                  return getDataByPost(
                    '/api/item_masters/multiple',
                    {ids},
                    dataNames.itemMasters,
                    {failed: 'ei.processingTypes.form.failed'}
                  );
                }),
              getDataByPost('/api/item_availability/multiple', {ids}, dataNames.itemsAvailability), {failed: 'itemsAvailability.get.failed'},
            ]);
          }),
        getUnpaginatedData('/api/partners', dataNames.partners, {failed: 'partners.get.failed'}),
        getUnpaginatedData('/api/users/current_facility', dataNames.currentFacilityUsers, {failed: 'packaging.getUsers.failed'}),
        ensureGetUnpaginatedData('/api/phases', dataNames.phases, {failed: 'phases.get.failed'}),
        getUnpaginatedData('/api/equipment', dataNames.equipmentItems, {failed: 'equipment.get.failed'}),
        getUnpaginatedData('/api/production_runs', dataNames.productionRuns, {failed: 'productionRuns.get.failed'}),
        getUnpaginatedData('/api/location_hierarchy', dataNames.locations, {failed: 'locations.get.failed'}, {is_sales_location: 0}),
        getItem('/api/compliance_settings', itemNames.complianceSettings),
        // Integration settings are downloaded to see if Metrc intergation settings exist. If the Metrc settings exist,
        // then the METRC Tracking ID input is displayed.
        this.props.actions.getItem(
          '/api/integration-settings',
          itemNames.integrationSettings,
          {failed: 'stateIntegratorSettings.get.failed'}
        ).then(() => {
          if (isMetrc) {
            this.props.actions.fetchMetrcTrackingIdsForSelectInput();
          }
        })
      ])
      .then(() => this.setState({loading: false}))
      .catch(() => this.setState({loading: false}));
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.isValidAssembly && this.props.isValidAssembly) {
      this.props.actions.addMessage(messageTypes.error, 'ei.assemblies.uomChanged');
    }
  }

  onSubmit(formData) {
    const isInventoryNegative = formData.inputs.some(item => (typeof item.packageCode !== 'undefined') && item.packageCode != null && item.qty > item.maxQty);
    showNegativeAlert(isInventoryNegative, this.setNegativeConfirmationState)
      .then(() => this.sendRequest(this.getSubmitData(formData)))
      .catch(doNothing);
  }

  sendRequest(submitData) {
    this.setState({submitting: true});

    // MetrcMd uses a single select employee drop down instead of multiple so handles case in selector
    const {isMdMetrc, isMetrc} = this.props.integrationState;
    const {params: {id}, afterSubmit, submitDraft, validateTrackingIdsAvailability, roundQty} = this.props;
    const payload = getCompleteProcessingPayload(submitData, this.props.timezone, isMdMetrc, isMetrc, roundQty);

    return validateTrackingIdsAvailability(submitData, setUnavailableTrackingIdFieldArrayErrors('outputs')).then(() => {
      if (!submitDraft) {
        return this.props.actions
          .postItem(
            `/api/assembly_jobs/${id}/complete`,
            payload,
            itemNames.infusion,
            {success: 'ei.infusions.complete.success', failed: 'ei.infusions.complete.failed'}
          )
          .then(() => {
            this.setState({submitting: false});
            if (afterSubmit === 'print') {
              this.showPrinter();
            } else {
              this.props.actions.goBack();
            }
          })
          .catch(() => this.setState({submitting: false}));
      } else {
        return this.props.actions
          .postItem(
            `/api/assembly_jobs/${id}/save_draft`,
            payload,
            itemNames.infusion,
            {success: 'ei.infusions.draft.success', failed: 'ei.infusions.draft.failed'}
          )
          .then(() => {
            this.setState({
              submitting: false,
              submitDraft: false,
            });
            this.showPrinter();
          })
          .catch(() => this.setState({submitting: false}));
      }
    })
      .catch(e => {
        this.setState({submitting: false});
        throw e;
      });
  }

  getSubmitData (formData) {
    if(this.props.integrationState.isMetrc) { // If using metric, warning if missing tags
      if (formData) { // Check for missing state identifiers and warn
        const outputsInViolation = formData.outputs.reduce((acc, output) => {
          if (output.state_integration_tracking_id === undefined) acc.push(output);
          if (output.state_integration_tracking_id && output.state_integration_tracking_id.trim() === '') acc.push(output);
          return acc;
        }, []);
        if (outputsInViolation.length) { // Launch warning and save formData
          this.setState({showWarning: true, outputsInViolation, formData});
          return false;
        }
      } else { // Load formData from state on return from warning
        formData = this.state.formData;
      }
    }
    // Get plant data
    const { items, infusion } = this.props;
    const inputItems = items.filter(item => infusion.inputs.some(input => input.item_id === item.id));
    let source_strain = [];
    let plant_batch_number = [];
    let harvest_batch_number = [];
    let harvested_at = [];
    inputItems.forEach((item) => {
      source_strain = source_strain.concat(item.source_strain ? item.source_strain.split(',') : []);
      plant_batch_number = plant_batch_number.concat(item.plant_batch_number ? item.plant_batch_number.split(',') : []);
      harvest_batch_number = harvest_batch_number.concat(item.harvest_batch_number ? item.harvest_batch_number.split(',') : []);
      harvested_at = harvested_at.concat(item.harvested_at ? item.harvested_at.split(',') : []);
    });
    // Dedupe and stringify
    source_strain = [...new Set(source_strain.filter((y) => y))].join(',');
    plant_batch_number = [...new Set(plant_batch_number.filter((y) => y))].join(',');
    harvest_batch_number = [...new Set(harvest_batch_number.filter((y) => y))].join(',');
    harvested_at = [...new Set(harvested_at.filter((y) => y))].join(',');

    source_strain = source_strain ? source_strain : null;
    plant_batch_number = plant_batch_number ? plant_batch_number : null;
    harvest_batch_number = harvest_batch_number ? harvest_batch_number : null;
    harvested_at = harvested_at ? harvested_at : null;

    const outputs = formData.outputs.map((outputs) => {
      return {
        ...outputs,
        source_strain,
        plant_batch_number,
        harvest_batch_number,
        harvested_at,
        finished_at: outputs.finished && outputs.finished_dirty ? moment().format('YYYY-MM-DD HH:mm:ss') : undefined,
      };
    });

    return {
      ...formData,
      outputs,
    };
  }

  setNegativeConfirmationState(state) {
    this.setState({
      showNegativeInvConfirmation: state
    });
  }

  showPrinter() {
    this.printLabel();
    this.setState({showPrinter: true});
  }

  /**
   *
   * @param data
   */
  printLabel(data) {
    const labelTag = this.props.submitDraft ? 'production_inv_package_tag_label_all' : 'inv_package_tag';
    const printerParam = {procId: this.props.infusion.id};
    this.setState({labelTag: labelTag, params: printerParam});
  }


  hidePrinter() {
    this.setState({showPrinter: false});
  }

  completeSave(){
    this.setState({showWarning: false});
    this.onSubmit();
  }

  hideModal(){
    this.setState({showWarning: false});
  }

  render() {

    const {
      notes,
      phases,
      status,
      equipment,
      employees,
      locations,
      trackingIds,
      ingredients,
      initialValues,
      outputItemIds,
      productionRuns,
      userPermissions,
      ingredientsCost,
      isManufacturing,
      integrationState,
      waInventoryTypes,
      ingredientOptions,
      infusionCanBeCompleted,
      ingredientsStandardCost,
      isAllowNegativeInventory,
      isLabelEnabledForNoCompletedProcess,
      hasPackagesTags,
      complianceSettings,
      isPaRemediationLabelsEnabled
    } = this.props;

    const {loading, submitting, showPrinter, showNegativeInvConfirmation, labelTag, params} = this.state;
    const message = `common.form.${submitting ? 'saving' : 'loadingData'}`;

    return (
      <FormWrapper
        title='ei.inventory.actions.completeInfusion'
        className='complete-infusion-page'
        goBack={this.props.actions.goBack}
      >
        <ModalWrapper
          title='Invalid Tags Warning!'
          onHide={this.hideModal}
          completeSave={this.completeSave}
          showModal={this.state.showWarning}
          outputsInViolation={this.state.outputsInViolation}
          products={[]}
          Component={IntegrationInvalidWarning}
        />
        <NegativeInventoryAlert confirmationState={showNegativeInvConfirmation}/>
        <InProgressOverlay isActive={loading || submitting} translate={true} message={message}/>
        <CompleteInfusionFormWrapper
          form={COMPLETE_INFUSION_FORM}
          notes={notes}
          onSubmit={this.onSubmit}
          loading={loading}
          initialValues={initialValues}
          infusionCanBeCompleted={infusionCanBeCompleted}
          equipment={equipment}
          employees={employees}
          hasPackagesTags={hasPackagesTags}
          phases={phases}
          productionRuns={productionRuns}
          integrationState={integrationState}
          isManufacturing={isManufacturing}
          trackingIds={trackingIds}
          locations={locations}
          ingredients={ingredients}
          ingredientOptions={ingredientOptions}
          ingredientsCost={ingredientsCost}
          ingredientsStandardCost={ingredientsStandardCost}
          status={status}
          outputItemIds={outputItemIds}
          showPrinter={this.showPrinter}
          userPermissions={userPermissions}
          isAllowNegativeInventory={isAllowNegativeInventory}
          waInventoryTypes={waInventoryTypes}
          isLabelEnabledForNoCompletedProcess={isLabelEnabledForNoCompletedProcess}
          complianceSettings={complianceSettings}
          isPaRemediationLabelsEnabled={isPaRemediationLabelsEnabled}
        />
        <PrinterModal
          ref='printerModal'
          showPrinter={showPrinter}
          hidePrinter={this.hidePrinter}
          params={params}
          labelTag={labelTag}
          labelIds={outputItemIds}
        />
      </FormWrapper>
    );
  }
}

CompleteInfusionPage.propTypes = {
  actions: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    ensureGetUnpaginatedData: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    getDataByPost: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired,
    fetchMetrcTrackingIdsForSelectInput: PropTypes.func.isRequired,
    addMessage: PropTypes.func.isRequired
  }).isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  initialValues: PropTypes.object,
  assembly: PropTypes.object.isRequired,
  infusion: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  phases: PropTypes.array.isRequired,
  productionRuns: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
  trackingIds: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  employees: PropTypes.array.isRequired,
  equipment: PropTypes.array.isRequired,
  ingredients: PropTypes.array.isRequired,
  submitDraft: PropTypes.bool,
  ingredientOptions: PropTypes.object.isRequired,
  ingredientsCost: PropTypes.number,
  isManufacturing: PropTypes.bool,
  hasPackagesTags: PropTypes.bool,
  ingredientsStandardCost: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  outputItemIds: PropTypes.array.isRequired,
  status: PropTypes.string,
  afterSubmit: PropTypes.string,
  validateTrackingIdsAvailability: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
  isValidAssembly: PropTypes.bool.isRequired,
  infusionCanBeCompleted: PropTypes.bool.isRequired,
  userPermissions: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  complianceSettings: PropTypes.object,
  isPaRemediationLabelsEnabled: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  const selector = formValueSelector(COMPLETE_INFUSION_FORM);
  const {
    ingredients,
    ingredientsStandardCost,
    afterSubmit,
    submitDraft
  } = selector(
    state,
    'ingredients',
    'ingredientsStandardCost',
    'afterSubmit',
    'submitDraft'
  );
  const initialValues = getCompleteInfusionInitialValues(state);
  const isValidAssembly = checkIngredientsUom(state);
  const {hasManufacturing} = getModulesState(state);
  const outputs = get(initialValues, 'outputs', []);

  return {
    notes: get(initialValues, 'notes', []),
    submitDraft,
    afterSubmit,
    ingredientsStandardCost,
    initialValues,
    ingredients: initialValues.ingredients || [],
    assembly: getAssembly(state),
    infusion: getAssemblyJob(state),
    equipment: getEquipments(state),
    items: getInventoryItems(state),
    phases: getPhases(state),
    employees: getCurrentFacilityUserOptions(state),
    productionRuns: state.productionRuns,
    integrationState: getIntegrationState(state),
    isManufacturing: hasManufacturing,
    trackingIds: state.trackingIds,
    locations: getFlattenedLocations(state),
    ingredientOptions: getIngredientOptions(state),
    ingredientsCost: getIngredientsCost(ingredients || []),
    outputItemIds: (outputs).map(o => o.item_id),
    status: initialValues.status,
    timezone: getCurrentTimezone(state),
    infusionCanBeCompleted: infusionCanBeCompleted(state),
    userPermissions: getCurrentUserPermissions(state),
    isValidAssembly,
    hasPackagesTags: hasPackagesTags(state),
    isAllowNegativeInventory: isAllowNegativeInventory(state),
    waInventoryTypes: state[dataNames.waInfusionInventoryTypes],
    isLabelEnabledForNoCompletedProcess: getIsLabelEnabledForNoCompletedProcess(state),
    complianceSettings: getInventoryComplianceSettings(state),
    roundQty: roundQtyCurried(state),
    isPaRemediationLabelsEnabled: isLeafPaConfigPackClosedLoopFacility(state) && isFeatureEnabled(state)('feature_pa_hb_1024_remediation_labels')
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    goBack,
    unsetItem,
    ensureGetUnpaginatedData,
    getUnpaginatedData,
    getDataByPost,
    getItem,
    postItem,
    fetchMetrcTrackingIdsForSelectInput,
    addMessage
  };
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default reduxMetrcIdAvailability(connect(mapStateToProps, mapDispatchToProps)(CompleteInfusionPage));
