import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {FaTimes} from 'react-icons/fa';

const PrinterListItem = (props) => {

  const getResolutionClass = (dpi) => {
    if(props.printer && props.printer.dpi === dpi) return 'glyphicon glyphicon-check';
    return 'glyphicon glyphicon-unchecked';
  };

  const getAutoPrintClass = () => {
    return props.printer.autoPrint ? 'glyphicon glyphicon-check' : 'glyphicon glyphicon-unchecked';
  };

  // Handlers on both td and radio to make it a bit more mobile friendly
  // especially on browsers that don't let you resize radio buttons (looking at you firefox)
  return (
    <tr>
      <td onClick={(event) => {
        //if(event.target.checked !== undefined) return props.onToggleSelected(props.printer, event.target.checked);

        //const radio = event.target.getElementsByTagName('input')[0];
        //if(!radio.checked) props.onToggleSelected(props.printer, radio.checked);

      }}>
        <input
          style={{width:'16px', height: '16px', outline: 'none'}}
          type='radio'
          disabled={props.printer.status === 0}
          name='selected'
          checked={props.printer.selected ? 'checked' : ''}
          onChange={(event) => {
            props.onToggleSelected(props.printer, event.target.checked);
          }}
          value='1'
        />
      </td>
      <td>{props.printer.name}</td>
      <td>
        {
          props.printer.status === 1 || (props.statusField && props.printer[props.statusField])
          ? <div>{I18n.t('common.printerPresent')}</div>
            : props.printer.selected // selected printer cannot be deleted
          ? <div>{I18n.t('common.printerNotFound')}</div>
            : (
            <div onClick={(event) => props.removePrinter(props.printer, event)}>{I18n.t('common.printerNotFound')}
              <FaTimes className='text-danger' style={{float:'right', fontSize: '2rem'}} />
            </div>
            )
        }
      </td>
      {
        props.showFontSize ? null : (
          <td style={{textAlign: 'center', cursor: 'pointer'}} onClick={(event) => props.onChangeField(props.printer, 'autoPrint', !props.printer.autoPrint)}>
            <span className={getAutoPrintClass()}></span>
          </td>
        )
      }
      {
        props.showFontSize ? null : (
          <td style={{textAlign: 'center', cursor: 'pointer'}} onClick={(event) => props.onChangeDpi(props.printer, 203)}>
            <span className={getResolutionClass(203)}></span>
          </td>
        )
      }
      {
        props.showFontSize ? null : (
          <td style={{textAlign: 'center', cursor: 'pointer'}} onClick={(event) => props.onChangeDpi(props.printer, 300)}>
            <span className={getResolutionClass(300)}></span>
          </td>
        )
      }
      {
        props.showFontSize
        ? null
          : (
          <td style={{textAlign: 'center', cursor: 'pointer'}} onClick={(event) => props.onChangeDpi(props.printer, 600)}>
            <span className={getResolutionClass(600)}></span>
          </td>
        )
      }
    </tr>
  );

};

PrinterListItem.propTypes = {
  onChangeFontSize: PropTypes.func.isRequired,
  showFontSize: PropTypes.bool,
  removePrinter: PropTypes.func.isRequired,
  onChangeDpi: PropTypes.func.isRequired,
  onToggleSelected: PropTypes.func.isRequired,
  printer: PropTypes.object.isRequired,
  onChangeField: PropTypes.func.isRequired,
};

export default PrinterListItem;
