import {createSelector} from 'reselect';
import {getActiveFacility} from './facilitiesSelectors';
import {getCurrentUser} from './usersSelectors';

//@TODO: automate these - eg hasAdjustRewardsPointsPermission gets the correct permission if present

export const hasPermAdjustRewardPoints = createSelector([getCurrentUser, getActiveFacility], (user, facility) => {
  return hasPermission(user, facility, 'adjust_reward_points');
});

export const hasPermViewRewardPoints = createSelector([getCurrentUser, getActiveFacility], (user, facility) => {
  return hasPermission(user, facility, 'view_reward_points');
});

export const hasPermApplyRewardCoupons = createSelector([getCurrentUser, getActiveFacility], (user, facility) => {
  return hasPermission(user, facility, 'apply_reward_coupons');
});

export const hasPermApplyManualDiscounts = createSelector([getCurrentUser, getActiveFacility], (user, facility) => {
  return hasPermission(user, facility, 'apply_manual_discounts');
});

export const hasPermission = (user, facility, permissionKey) => {
  if(!facility || !facility.key) return false;
  return Boolean(user && user.permissions
    && user.permissions[facility.key]
    && user.permissions[facility.key].permissions
    && user.permissions[facility.key].permissions[permissionKey]);
};

const getPermissionKey = (_, props) => props && props.permissionKey ? props.permissionKey : '';

/***
 * Pass permission key in props as {permissionKey: PERMISSION_KEY}
 * Breaks memoization though
 * @type {Reselect.Selector<TInput, TOutput>}
 */
export const userHasPermission = createSelector([getCurrentUser, getActiveFacility, getPermissionKey], (user, facility, permissionKey) => {
  if(!facility || !facility.key) return false;
  const permissionKeys = (Array.isArray(permissionKey)) ? permissionKey : [permissionKey]; // Support an array of perm keys where first true wins
  return permissionKeys.reduce((acc, permissionKey) => {
    if(acc) return acc;
    return Boolean(user && user.permissions
      && user.permissions[facility.key]
      && user.permissions[facility.key].permissions
      && user.permissions[facility.key].permissions[permissionKey]);
  }, false);
});

export default userHasPermission;
