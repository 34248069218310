import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import {Button} from 'react-bootstrap';
import {FaMinus, FaPlus} from 'react-icons/fa';
import TimeInput from '../../../../../common/form/TimeInput';
import NumericInput from '../../../../../common/form/NumericInput';
import TextInput from '../../../../../common/form/TextInput';
import MultiselectInput from '../../../../../common/form/MultiselectInput';

const DayTimePointWindows  = (props) => {

  //const {fields, meta: {error, submitFailed}} = props;
  const {fields} = props;

  const dayOptions = [
    {text: 'Monday', value: 'monday'},
    {text: 'Tuesday', value: 'tuesday'},
    {text: 'Wednesday', value: 'wednesday'},
    {text: 'Thursday', value: 'thursday'},
    {text: 'Friday', value: 'friday'},
    {text: 'Saturday', value: 'saturday'},
    {text: 'Sunday', value: 'sunday'},
  ];

  const defaultTimeWindow = {
    multiplier: 1,
    on_days: [],
    start_time: '8:00 AM',
    end_time: '11:00 PM',
  };

  return (<tr>
    <td colSpan={2}>
      <Button variant='primary' size='sm' type='button' className='float-right' onClick={(e) => {
        e.target.blur();
        fields.push(defaultTimeWindow);
      }}>
        <FaPlus className='btn-icon' />
        {I18n.t('retail.rewardSetup.ui.addPointsBonusWindow')}
      </Button>
      <div style={{clear: 'both', height: '8px'}} />
      <table className='table table-sm table-striped table-bordered'>
        <thead>
        <tr>
          <th style={{width: '30px'}}>&nbsp;</th>
          <th style={{width: '20%'}}>Name</th>
          <th style={{width: '20%'}}>Points</th>
          <th>Days</th>
          <th style={{width: '20%'}}>From</th>
          <th style={{width: '20%'}}>Until</th>
        </tr>
        </thead>
        <tbody>
        {
          fields.length === 0
            ? <tr><td colSpan={6} style={{textAlign: 'center'}}>{I18n.t('retail.rewardSetup.noBonusPointWindowsFound')}</td></tr>
            : null
        }
        {
          fields.map((item, index) => {
            return (<tr key={index}>
              <td>
                <Button
                  variant='danger'
                  size='sm'
                  type='button'
                  title={I18n.t('retail.rewardSetup.ui.removePointsBonusWindow')}
                  className='btn btn-sm btn-danger'
                  onClick={() => fields.remove(index)}
                >
                  <FaMinus />
                </Button></td>
              <td>
                <Field
                  name={`${item}.name`}
                  typex='number'
                  component={TextInput}
                  placeholder='Short and descriptive name.'
                  classNamex='form-control'
                />
              </td>
              <td>
                <Field
                  name={`${item}.multiplier`}
                  component={NumericInput}
                  classNamex='form-control'
                />
              </td>
              <td>
                <Field name={`${item}.on_days`} component={MultiselectInput} props={{
                  options: dayOptions,
                  valueKey: 'value',
                  textKey: 'text',
                  allOption: true,
                }} />
              </td>
              <td style={{position:'relative'}}>
                <Field
                  name={`${item}.start_time`}
                  component={TimeInput}
                  props={{
                    timeFormat: 'h:mm A',
                    inputProps: {readonly: 'readonly', style: {backgroundColor:'white'}},
                  }}
                />
              </td>
              <td style={{position:'relative'}}>
                <Field
                  name={`${item}.end_time`}
                  component={TimeInput}
                  props={{
                    timeFormat: 'h:mm A',
                    inputProps: {readonly: 'readonly', style: {backgroundColor:'white'}},
                  }}
                />
              </td>
            </tr>);
          })
        }
        </tbody>
      </table>
    </td>
  </tr>);

};

DayTimePointWindows.propTypes = {
  fields: PropTypes.object,
  meta: PropTypes.object,
};

function mapStateToProps() {
  return {
    //collection: getCollection(state, ownProps)
  };
}

export default connect(mapStateToProps)(DayTimePointWindows);

