import React from 'react';
import PropTypes from 'prop-types';
import {FaStar, FaRegStar} from 'react-icons/fa';

const RatingStarsControl = ({onChange, value, maxStars, starSize}) => {
  const stars = [];
  for(let i = 0; i < maxStars; i++) {
    stars[i] = 'star';
  }

  return (
    <div className='layout-row stars'>
      {stars.map((item, index) => {
        return (
          <span key={`${index}`} style={{fontSize: `${starSize}px`}}>
            {index < value && <FaStar className='active' key={`active-${index}`} onClick={() => {onChange(index + 1);}}/>}
            {index >= value && <FaRegStar className='inactive' key={`inactive-${index}`} onClick={() => {onChange(index + 1);}}/>}
          </span>
        );
      })}
    </div>
  );
};

RatingStarsControl.propTypes = {
  onChange: PropTypes.func.isRequired,
  maxStars: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  starSize: PropTypes.number
};

export default RatingStarsControl;
