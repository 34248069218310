import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Button, Col, Row, Card} from 'react-bootstrap';
import {Link} from 'react-router';
import Select from 'react-select';
import get from 'lodash.get';

import {isMedicated} from '../../../selectors/itemMastersSelectors';
import BootstrapFormGroup from '../../common/form/BootstrapFormGroup';
import InternationalQuantityByUomInput from '../../common/form/InternationalQuantityByUomInput';
import ProductPrice from '../common/ProductPrice';
import {addTooltip} from '../../../util/tooltipHelper';

class Product extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    const form_id = props.formOptions && props.formOptions.length === 1 ? props.formOptions[0].value : undefined;
    this.state = {
      form_id,
      quantity: '',
      fastTrack: false
    };
    this.onFormIdChange = this.onFormIdChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.fastTrack = this.fastTrack.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.formOptions && nextProps.formOptions.length === 1) {
      this.onFormIdChange(nextProps.formOptions[0]);
    }

    // the best way to handle onChange events
    const {qtyInput} = nextProps;
    if (qtyInput !== this.props.qtyInput) {
      this.setState({quantity : qtyInput});
    }
  }

  onFormIdChange(value) {
    this.setState({form_id: (value && value.value) || value});
  }

  onSubmit(event, fastTrack = false) {
    event.preventDefault();

    const {quantity, form_id} = this.state;
    const {itemMasterType, itemMaster, handleSubmit} = this.props;
    const formValues = {
      form_id,
      quantity,
      fastTrack,
      itemMasterType,
      item_master_id: itemMaster.id,
      item_master_uom: itemMaster.default_uom,
      available_quantity: parseFloat(itemMaster.qty) || parseFloat(itemMaster.prepack_weight_base),
      appending_quantity: true
    };

    handleSubmit(formValues);
    this.setState({quantity: ''});
    this.props.change(`quantity-input${itemMaster.id}`, '');
  }

  fastTrack(event) {
    this.onSubmit(event, true);
  }

  render() {
    const {itemMaster, itemMasterType, isCure, formOptions, disablingAddProduct, newProductMasterIsEnabled, addToCartEnabled} = this.props;
    const quantityLabel = itemMasterType === 'unit' ? I18n.t('common.form.quantity') : I18n.t('common.form.weight');
    const isProductMedicated = isMedicated(itemMaster);
    const productType = get(itemMaster, 'product_type');
    const isProductTypeMedical = !!(productType === 'med,rec' || productType === 'med');
    const isProductTypeRecreational = !!(productType === 'med,rec' || productType === 'rec');
    const disableButton =
      disablingAddProduct || !parseFloat(this.state.quantity) || (isCure && isProductMedicated && !this.state.form_id) || !addToCartEnabled;
    return (
      <Card className={`product text-center ${isCure ? 'cure' : ''}`} style={{minWidth: '250px', maxWidth: '250px', margin: '6px', border: 'solid 1px #dee2e6'}}>
        <Card.Body>
          <div className={'product-link'}>
            <div style={{float: 'left', width: '10%', color: '#CC4E00'}}>
              {newProductMasterIsEnabled && isProductTypeMedical
                ? addTooltip('MED', I18n.t('productMenu.productIntendedForMedicalUse'))
                : <span>&nbsp;</span>
              }
            </div>
            <div style={{float: 'left', width: '80%'}}>
              <Link to={`/products/${itemMaster.id}`} onlyActiveOnIndex={false}>
                <div className='square'>
                  <img src={itemMaster.imageUrl} alt={itemMaster.display_name || itemMaster.name} className='img-100x100 scale-down'/>
                </div>
                <div className='text-center product-type'>
                  {itemMaster.subcategory_display_name || itemMaster.subcategory_name}
                </div>
                <h4>{itemMaster.display_name || itemMaster.name}</h4>
              </Link>
            </div>
            <div style={{float: 'right', width: '10%', color: '#82B2EC'}}>
              {newProductMasterIsEnabled && isProductTypeRecreational
                ? addTooltip('REC', I18n.t('productMenu.productIntendedForRecreationalUse'))
                : <span>&nbsp;</span>
              }
            </div>
          </div>
          <form onSubmit={this.onSubmit} style={{backgroundColor: '#f5f5f5', border: 'solid 1px #dee2e6', margin: '-10px'}}>
            <Row>
              <Col xs={12} md={6}>
                <ProductPrice itemMaster={itemMaster}/>
              </Col>
              <Col xs={12} md={6} style={{paddingLeft: '2px', paddingRight: '20px'}}>
                <InternationalQuantityByUomInput
                  name={`quantity-input${itemMaster.id}`}
                  label={quantityLabel}
                  uom={itemMaster.default_uom}
                  props={{
                    className: 'input-number-without-arrows',
                    id : `quantity-group-${itemMaster.id}`
                  }}
                />
              </Col>
              <Col xs={12} style={{paddingLeft: '20px', paddingRight: '20px'}}>
                {isCure && isProductMedicated ? (
                  <BootstrapFormGroup
                    id={`quantity-group-${itemMaster.id}-form`}
                    Control={Select}
                    label={I18n.t('categoryManagement.deliveryMethod')}
                    controlProps={{
                      options: formOptions,
                      valueKey: 'value',
                      labelKey: 'text',
                      onChange: this.onFormIdChange,
                      value: this.state.form_id
                    }}
                  />
                ) : isCure ? (
                  <div style={{height: '75px'}}/>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Button variant='primary' block type='submit' disabled={disableButton} style={{margin: '6px', width: '-webkit-fill-available'}}>
                  {I18n.t('productMenu.addToCart')}
                </Button>
              </Col>
            </Row>
          </form>
        </Card.Body>
      </Card>
    );
  }
}

Product.propTypes = {
  qtyInput: PropTypes.string,
  pricingWeights: PropTypes.array,
  handleSubmit: PropTypes.func.isRequired,
  itemMaster: PropTypes.object.isRequired,
  itemMasterType: PropTypes.string.isRequired,
  formOptions: PropTypes.array,
  disablingAddProduct: PropTypes.bool,
  isCure: PropTypes.bool
};

export default Product;
