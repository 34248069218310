import  *  as types from '../constants/actionTypes';

export function getDataSuccess (payload, name, message) {
  return {type: types.GET_DATA_SUCCESS, payload, name, message};
}

export function getDataFailed (payload, name, message) {
  return {type: types.GET_DATA_FAILED, payload, name, message};
}

export function getDataBatchSuccess(payload, name, message, keyField = 'id') {
  return {type: types.GET_DATA_BATCH_SUCCESS, payload, name, message, keyField};
}

export function getDataBatchFailed(payload, name, message) {
  return {type: types.GET_DATA_BATCH_FAILED, payload, name, message};
}

export function addDataSuccess (payload, name, message) {
  return {type: types.ADD_DATA_SUCCESS, payload, name, message};
}

export function addDataFailed (payload, name, message) {
  return {type: types.ADD_DATA_FAILED, payload, name, message};
}

export function editDataSuccess (payload, name, message) {
  return {type: types.EDIT_DATA_SUCCESS, payload, name, message};
}

export function editData (payload, name) {
  return {type: types.EDIT_DATA, payload, name};
}

export function editDataFailed (payload, name, message) {
  return {type: types.EDIT_DATA_FAILED, payload, name, message};
}

export function deleteDataSuccess (payload, name, message) {
  return {type: types.DELETE_DATA_SUCCESS, payload, name, message};
}

export function removeData (payload, name, keyField) {
  return {type: types.DELETE_DATA, payload, name, keyField};
}

export function deleteDataFailed (payload, name, message) {
  return {type: types.DELETE_DATA_FAILED, payload, name, message};
}

export function setData (payload, name, message) {
  return {type: types.SET_DATA, name, payload, message};
}

export function unsetData (name) {
  return {type: types.UNSET_DATA, name};
}

export function unionData(payload, name, keyField = 'id'){
  return {type: types.UNION_DATA, name, payload, keyField};
}

export function pushData(payload, name){
  return {type: types.PUSH_DATA, name, payload};
}

export function filterDataBatchByKey(payload, name, keyField = 'id'){
  return {type: types.FILTER_DATA_BATCH_BY_KEY, name, payload, keyField};
}
