import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';
import { reduxForm, Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';

import InlineCheckBox from '../../common/form/InlineCheckBox';
import NumericInput from '../../common/form/NumericInput';
import MultiselectInput from '../../common/form/MultiselectInput';
import SubmitSection from '../../common/form/SubmitSection';
import HoverTooltip from '../../common/HoverTooltip';

import validate from './validate';

export const PatientSettingsForm = (props) => {
  const {submitting, invalid, facilities} = props;
  const settings = {
    actionSettings: {
      submit: {
        submitting,
        invalid,
        text: I18n.t('common.form.save')
      }
    }
  };

  const goesByTooltipText = <div className='goes-by-tooltip'>
      <span className='goes-by-tooltip--strong'>{I18n.t('retail.patientPreferences.replacedWithGoesByHeader')}</span>
      <ul>
        <li>{I18n.t('retail.patientPreferences.replacedWithGoesByListItem1')}</li>
        <li>{I18n.t('retail.patientPreferences.replacedWithGoesByListItem2')}</li>
      </ul>
      <span className='goes-by-tooltip--strong'>{I18n.t('retail.patientPreferences.shownWithGoesByHeader')}</span>
      <ul>
        <li>{I18n.t('retail.patientPreferences.shownWithGoesByListItem1')}</li>
        <li>{I18n.t('retail.patientPreferences.shownWithGoesByListItem2')}</li>
        <li>{I18n.t('retail.patientPreferences.shownWithGoesByListItem3')}</li>
        <li>{I18n.t('retail.patientPreferences.shownWithGoesByListItem4')}</li>
      </ul>
    </div>;

  return(
    <form onSubmit = {props.handleSubmit} className='patient-settings-form' noValidate={true}>
      <Row>
        <Col sm={12} xs={12} md={12}>
          <h3>{I18n.t('retail.patientSettings.header')}</h3>
        </Col>
      </Row>
      <Row>
        <Col sm={12} xs={12} md={12}>
          <Row className='spec-row'>
            <Col sm={10} xs={10} md={10} className='spec-col'>
              <b>{I18n.t('retail.patientSettings.facilityCount')}</b>
              <Field
                name='max_patients_warn'
                component={NumericInput}
                props={{
                  allowNegativeNumber:false,
                  fractionPartSize: 0,
                  label: I18n.t('retail.patientSettings.maxWarn')
                }}
              />
              <Field
                name='max_patients_block'
                component={NumericInput}
                props={{
                  allowNegativeNumber:false,
                  fractionPartSize: 0,
                  label: I18n.t('retail.patientSettings.maxblock')
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={10} xs={10} md={10} className='spec-col total-plants'>
              <Field
                name='facility_coupling'
                component={MultiselectInput}
                props={{
                  label: I18n.t('retail.patientSettings.facilityCoupled'),
                  options: facilities,
                  textKey: 'name',
                  valueKey: 'id'
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={10} xs={10} md={10} className='spec-col default-veg'>
              <Field
                name='max_patient_plants_veg_block'
                component={NumericInput}
                props={{
                  label: I18n.t('retail.patientSettings.patientsDefault'),
                  rightAddon: I18n.t('retail.patientSettings.plantsVeg'),
                  fractionPartSize: 0,
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={10} xs={10} md={10} className='spec-col default-flower'>
              <Field
                name='max_patient_plants_flower_block'
                component={NumericInput}
                props={{
                  label: I18n.t('retail.patientSettings.patientsDefault'),
                  rightAddon: I18n.t('retail.patientSettings.plantsFlower'),
                  fractionPartSize: 0,
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={10} xs={10} md={10} className='spec-col total-plants'>
              <Field
                name='max_patient_plants_total_block'
                component={NumericInput}
                props={{
                  label: I18n.t('retail.patientSettings.patientsDefault'),
                  rightAddon: I18n.t('retail.patientSettings.plantsTotal'),
                  fractionPartSize: 0,
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={10} xs={10} md={10} className='spec-col total-plants'>
              <Field
                name='max_inventory_weight_on_hands'
                component={NumericInput}
                props={{
                  label: I18n.t('retail.patientSettings.onHandDefault'),
                  rightAddon: I18n.t('retail.patientSettings.perPatient'),
                  fractionPartSize: 2,
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col sm={12} xs={12} md={12}>
          <h3 className='patient-preference-header'>{I18n.t('retail.patientPreferences.header')}</h3>
        </Col>
      </Row>
      <Row className='goes-by-row'>
        <Col sm={10} xs={10} md={10}>
          <Field name='goes_by_enabled' component={InlineCheckBox} props={{
            label: I18n.t('retail.patientPreferences.goesByEnabled'),
            rightAddon:  <HoverTooltip text={goesByTooltipText} id={'goes_by_enabled_tooltip'} type={'info'} />,
          }}/>
        </Col>
      </Row>
      <SubmitSection settings = {settings}/>
    </form>
  );
};

PatientSettingsForm.propTypes = {
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  facilities: PropTypes.array
};

export default reduxForm({
  form: 'PatientSettingsForm',
  validate,
  enableReinitialize: true
})(PatientSettingsForm);
