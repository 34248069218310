import {createSelector} from 'reselect';
import get from 'lodash.get';
import * as itemNames from '../constants/itemNames';
import * as dataNames from '../constants/dataNames';
import {convertFromBase} from '../util/uomHelpers';

const getEntityType = state => state[itemNames.entityType];
const getEntityId = state => state[itemNames.entityId];
export const getItemReservations = state => state[dataNames.hardReservations];
export const getItemAvailabilityRaw = state => state[itemNames.itemAvailability];
export const getItemsAvailabilityRaw = state => state[dataNames.itemsAvailability];
export const getItemsAvailabilitySearchRaw = state => state[dataNames.itemsAvailabilitySearch];

const mapItemAvailability = item => ({
  ...item,
  qty_onhand: item.uom_display ? convertFromBase(item.qty_onhand_base, item.uom_display) : 0,
  qty_reserved: item.uom_display ? convertFromBase(item.qty_reserved_base, item.uom_display) : 0,
  qty_available: item.uom_display ? convertFromBase(item.qty_onhand_base - item.qty_reserved_base, item.uom_display) : 0,
  uom: item.uom_display,
});

export const getItemAvailability = createSelector(
  [getItemAvailabilityRaw],
  item => {
    if (item.uom_display) {
      return mapItemAvailability(item);
    }
    //Single item request returns array
    return item[0] ? mapItemAvailability(item[0]) : null;
  }
);

export const getItemAvailabilityExcludingEntity = createSelector(
  [getItemReservations,getItemAvailabilityRaw,getEntityType,getEntityId], 
  (reservations,availability,entityType,entityId) => {
    const itemAvailability = get(availability,'0',null);
    if(!itemAvailability){ return null; }
    if(reservations){
      const reservedByEntity = reservations.reduce( (acc,res) => {
        if(res.entity_type == entityType && res.entity_id == entityId){
          return acc + res.qty_base;
        }
        return acc;
      },0);
      if (itemAvailability.qty_reserved_base > 0) {
        itemAvailability.qty_reserved_base -= reservedByEntity;
      }
    }
    return mapItemAvailability(itemAvailability);
  }
);

export const getItemsAvailabilityExcludingEntity = createSelector(
  [getItemReservations,getItemsAvailabilityRaw,getEntityType,getEntityId], 
  (reservations,availability,entityType,entityId) => {
    return availability.map(el => {
      const itemAvailability = {...el};
      if(reservations){
        const reservedByEntity = reservations.reduce( (acc,res) => {
          if(res.entity_type == entityType && res.entity_id == entityId && res.item_id == itemAvailability.item_id){
            return acc + res.qty_base;
          }
          return acc;
        },0);
        itemAvailability.qty_reserved_base -= reservedByEntity;
      }
      return mapItemAvailability(itemAvailability);
    });
  }
);

export const getItemsAvailability = createSelector(
  [getItemsAvailabilityRaw],
  availability => availability.map(mapItemAvailability)
);

export const getItemsAvailabilitySearch = createSelector(
  [getItemsAvailabilitySearchRaw],
  availability => availability.map(mapItemAvailability)
);

