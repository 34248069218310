import {change} from 'redux-form';
import get from 'lodash.get';
import {PREFERRED_MIX_FORM} from '../../constants/forms';
import {isFormChangeAction} from './utils';
import * as dataNames from '../../constants/dataNames';


const processingMixFormMiddleware = store => next => action => {
  const result = next(action);
  const {meta, payload} = action;

  /*
    If the form has changed...
   */
  if (isFormChangeAction(action, [PREFERRED_MIX_FORM])) {

    const onChangeIngredient = (prefix, selectedId) => {
      const products = get(store.getState(), dataNames.products, []);
      const product = products.find(p => p.id === selectedId);
      store.dispatch(change(meta.form, `${prefix}.uom`, product && product.default_uom || ''));
      store.dispatch(change(meta.form, `${prefix}.qty`, ''));
    };

    /**
     * Ingredient Name change
     */
    const match = get(meta, 'field').match(/(inputs\[\d*])\.id/);
    if (match && match.length > 1) {
      const prefix = match[1];
      onChangeIngredient(prefix, payload);
    }
  }
  return result;
};

export default processingMixFormMiddleware;
