import {setItem, unsetItem} from './itemActions';
import {solr, service} from '../constants/listingSources';
import {listingSource} from '../constants/itemNames';

export function setListingSourceSolr () {
  return setItem({ source: solr }, listingSource);
}

export function setListingSourceService () {
  return setItem({ source: service }, listingSource);
}

export function unsetListingSource () {
  return unsetItem(listingSource);
}
