import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Col, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';

import ReactSelectInput from '../../common/form/ReactSelectInput';
import TextInput from '../../common/form/TextInput';
import {getIntegrationState} from '../../../selectors/integration/integrationSelectors';


const PackageMappings = (props) => {
  const {fields: {map}, itemMasterOptions, isBiotrack} = props;
  return (
    <Row>

      <Col md={8} lg={6}>
        <Row>
          <Col md={isBiotrack ? 4 : 6} xs={12}>
            <label>{I18n.t('supplyChainMapping.form.partnerProducts')}</label>
          </Col>
          {isBiotrack &&
            <Col md={4} xs={12}>
              <label>{I18n.t('supplyChainMapping.form.partnerStrains')}</label>
            </Col>
          }
          <Col md={isBiotrack ? 4 : 6} xs={12}>
            <label>{I18n.t('supplyChainMapping.form.yourProducts')}</label>
          </Col>
        </Row>
        <ListGroup>
          {map((pkg, pkgIndex) => {
            return (
              <ListGroupItem key={`mapping-${pkgIndex}`}>
                <Row key={`mapping-${pkgIndex}`}>
                  <Col md={isBiotrack ? 4 : 6} xs={12}>
                    <Field
                      name={`${pkg}.product_label`} component={TextInput}
                      props={{
                        readOnly: true
                      }}
                    />
                    <div className='hide'> {/*hidden to keep clean in post while above is more informational*/}
                    <Field
                      name={`${pkg}.product_name`} component={TextInput}
                      props={{
                        readOnly: true
                      }}
                    />
                    </div>
                  </Col>
                  {isBiotrack &&
                    <Col md={4} xs={12}>
                      <Field
                        name={`${pkg}.strain_name`} component={TextInput}
                        props={{
                          readOnly: true
                        }}
                      />
                    </Col>
                  }
                  <Col md={isBiotrack ? 4 : 6} xs={12}>
                    <Field
                      name={`${pkg}.item_master_id`}
                      component={ReactSelectInput}
                      props={{
                        options: itemMasterOptions
                      }}
                    />
                  </Col>
                </Row>
              </ListGroupItem>
            );
          })}
        </ListGroup>
      </Col>
    </Row>
  );
};

PackageMappings.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired
  }),
  itemMasterOptions: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  const {isBiotrack} = getIntegrationState(state);
  return {
    isBiotrack,
  };
}

export default connect(mapStateToProps)(PackageMappings);
// export default PackageMappings;
