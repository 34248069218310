import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {push} from 'react-router-redux';
import {change} from 'redux-form';
import * as dataNames from '../../../../constants/dataNames';
import * as itemNames from '../../../../constants/itemNames';

import {getOraclePatient, getOracleCaregiver} from '../../../../selectors/oracleSelectors';
import {getDataUpdateAvailable, dataUpdateSetAvailable} from '../../../../selectors/dataUpdateSelectors';
import {addMessage} from '../../../../actions/systemActions';
import {setItem, unsetItem} from  '../../../../actions/itemActions';
import {setData} from  '../../../../actions/dataActions';
import {postItem, getItem, putItem, getUnpaginatedData, getSearchData, getDataByPost} from '../../../../actions/apiActions';
import InProgressOverlay from '../../../common/InProgressOverlay';
import ScanInputModal from '../../../scan-input/ScanInputModal';
import CheckinLeafMJPFormWrapper from './CheckinLeafMJPFormWrapper';
import ModalWrapper from '../../../common/ModalWrapper';
import DatePickerInput from '../../../common/form/DatePickerInput';
import WillRender from '../../../common/concealers/ContentConcealer';
import {isFeatureEnabled} from '../../../../selectors/featureToggles';
import {getVisitReasons, getDefaultVisitReason} from '../../../../selectors/queueSelectors';

const getAt = get;
const form = 'checkinLeafMJP';

class CheckinLeafMJPPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {loading: false, showScanModal: false, license_type: null, fulfillment_method: 'in_store', expanded: {}, expirationDate: undefined};
    this.onDetailsExpand = this.onDetailsExpand.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.validateCard = this.validateCard.bind(this);
    this.goToQueue = this.goToQueue.bind(this);
    this.onScanComplete = this.onScanComplete.bind(this);
    this.showScanModal = this.showScanModal.bind(this);
    this.hideScanModal = this.hideScanModal.bind(this);
    this.onRegister = this.onRegister.bind(this);
    this.dispatchNotification = this.dispatchNotification.bind(this);
    this.onHideModal = this.onHideModal.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onSaveNewCard = this.onSaveNewCard.bind(this);

  }

  componentWillMount() {
    this.props.actions.getItem('/api/csrs', itemNames.budTendersObject, {failed: I18n.t('customers.getBudTenders.failed')});
    this.props.actions.getUnpaginatedData('/api/queue/visit_reasons', dataNames.visitReasons);
  }

  componentWillReceiveProps(nextProps) {
    if(dataUpdateSetAvailable(nextProps.dataUpdateAvailable, this.props.dataUpdateAvailable)) {
      setTimeout(() => this.loadCustomers(), 100);
    }
  }

  onDetailsExpand(consumerId, isExpanded) {
    this.setState(state => ({
      expanded: {
        ...state.expanded,
        [consumerId]: isExpanded,
      },
    }));
  }

  onSearch(formData) {
    this.setState({loading: true});

    return this.validateCard(formData)
      .then(() => this.goToQueue())
      .catch(() => this.setState({loading: false}));
  }

  goToQueue() {
    this.props.actions.push({
      pathname: '/queue/in_store',
      state: {}
    });
  }


  validateCard(formData) {

    const payload = {
      license_type: formData.license_type,
      card_number: formData.card_number,
      dob: formData.dob,
    };

    if (this.props.isCourierLicenseesToggled) {
      payload.fulfillment_method = formData.fulfillment_method;
      payload.visit_reason_id = formData.visit_reason_id;
    }

    return new Promise((resolve, reject) => {
      const url = this.props.isUtahEvsEnabled
        ? '/api/utah_patient/validate_card_and_checkin'
        : '/api/leaf_mjp/validate_card_and_checkin';
      this.props.actions
        .postItem(url, payload, null, {failed: 'suppress'})
        .then(() => {
          resolve();
        }).catch((error) => {
          this.dispatchNotification();
          reject();
        });
    });

  }

  dispatchNotification(){
    const message = I18n.t('consumer.checkin.validationFailed');

    const notification = { // This does produce an in your face and notifications will subsequently overwrite it.
      active: 1,
      alert_id: (new Date().getTime()) * -1,
      alert_type: 'priority',
      body: message,
      created_at: new Date(),
      starts_at: new Date(),
      subject: 'State Registry returned an error for this patient.',
      user_id: this.props.user.id,
      organization_id: this.props.facility.organization_id,
      facility_id: this.props.facility.id,
      viewed_timestamp: null
    };

    const newNotifications = this.props.notifications.map((n) => n);
    newNotifications.push(notification);
    this.props.actions.setData(newNotifications, dataNames.notifications);

  }

  onScanComplete(parsed) {
    if(parsed){
      const {change} = this.props.actions;
      change(form, 'registry_id', parsed.registry_id);
      change(form, 'card_number', parsed.card_id);
      this.hideScanModal();
    }
  }

  showScanModal() {
    this.setState({showScanModal: true});
  }

  hideScanModal() {
    this.setState({showScanModal: false});
  }

  onHideModal(){
    this.setState({showModal: false});
  }

  onDateChange(e){

    if(typeof e !== 'object' || !e._isAMomentObject) e = undefined;
    this.setState({expirationDate: e});
  }

  onSaveNewCard(){
    return new Promise((resolve, reject) => {
      if(this.state.expirationDate){
        const payload = Object.assign({}, this.state.newIdPayload);
        const newId = Object.assign({}, this.state.newId);
        newId.expired_at = this.state.expirationDate.format('YYYY-MM-DD');
        payload.ids.push(newId);
        this.props.actions.putItem(`/api/customers/${payload.id}`, payload, 'noOp')
          .then(() => {
            this.props.actions.setItem(this.state.details, this.state.dataName)
              .then(() => {
                this.loadCustomers();
              });
          });
        resolve();
      } else {
        reject();
      }
    });
  }

  onRegister(patient) {
    const {caregiver} = this.props;
    const routerCaregiver = {
      ...caregiver,
      state_integration_tracking_id: caregiver.caregiver_id,
      medical_id: caregiver.caregiver_card_number
    };
    return this.props.actions.setItem(patient, itemNames.oraclePatient)
      .then(() => this.props.actions.push({
        pathname: '/patients/create',
        state: {
          caregiver: routerCaregiver
        },
      }));
  }

  render() {
    const {showScanModal, loading} = this.state;
    const {visitReasons, initialValues} = this.props;
    return (
      <div>
        <InProgressOverlay message='common.searching' isActive={loading} translate={true}/>
        <h1>{I18n.t('consumers.checkin.checkIn')}</h1>
        <ScanInputModal
          showModal={showScanModal}
          scanType='barcode'
          onScanComplete={this.onScanComplete}
          hideModal={this.hideScanModal}
        />
        <ModalWrapper
          showModal={this.state.showModal}
          onHide={this.onHideModal}
          title={`Set Expiration Date For New Card ID`}
          version={2}
          headerClass='bg-info-dark'
          widthClass='modal-sm'
          cancelButton={{show: true, text: 'Cancel'}}
          okayButton={{show: true, onClickHasPromise: true, onClick: this.onSaveNewCard, text: 'Save With Expiration Date'}}
        >
          <div>
            This customer's scanned card information does not match any cards in your files.  Enter the expiration date
            on the card and click Save With Expiration Date to save the new card to the customer profile.  If you cancel you will
            not be able to check in this patient.<br />
            <DatePickerInput
              name='expirationDate'
              label='Expiration Date'
              meta={{}}
              input={{
                name: 'expirationDate',
                value: this.state.expirationDate ? this.state.expirationDate : null,
                onChange: (e) => {
                  this.onDateChange(e);
                },
                meta: {}
              }}
            />
            <WillRender show={!this.state.expirationDate}>
              <div className='text-danger'>
                Expiration Date is required in order to save the new id.
              </div>
            </WillRender>
          </div>
        </ModalWrapper>
        <CheckinLeafMJPFormWrapper
          testOnFail={this.testOnFail}
          onSubmit={this.onSearch}
          isCourierLicenseesToggled={this.props.isCourierLicenseesToggled}
          showScanModal={this.showScanModal}
          visitReasons={visitReasons}
          form={form}
          initialValues={initialValues}
          isUtahEvsEnabled={this.props.isUtahEvsEnabled}
        />

      </div>
    );
  }
}

CheckinLeafMJPPage.propTypes = {
  patient: PropTypes.object.isRequired,
  caregiver: PropTypes.object.isRequired,
  budTenders: PropTypes.array.isRequired,
  timezone: PropTypes.string,
  isCourierLicenseesToggled: PropTypes.bool,
  visitReasons: PropTypes.array.isRequired,
  initialValues: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    postItem: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    setItem: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired,
    setData: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    addMessage: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    getSearchData: PropTypes.func.isRequired,
    getDataByPost: PropTypes.func.isRequired,
  }).isRequired,
  isUtahEvsToggled: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  const budTendersObject = state[itemNames.budTendersObject];
  const budTenders = Object.keys(budTendersObject).map(id => ({id, name: budTendersObject[id]}));
  const defaultVisitReason = getDefaultVisitReason(state);
  return {
    patient: getOraclePatient(state),
    caregiver: getOracleCaregiver(state),
    visitReasons: getVisitReasons(state),
    timezone: state.timezone,
    dataUpdateAvailable: [getDataUpdateAvailable(state, {name: dataNames.customers, core: 'customers'})],
    budTenders,
    allowSalesAtWill: parseInt(getAt(state[itemNames.salesComplianceSettings], 'order_allow_leafPA_sales_at_will.value', 0)),   // todo EVS integration
    notifications: state[dataNames.notifications],
    user: state[itemNames.currentFacilityUser],
    facility: state[itemNames.facility],
    isCourierLicenseesToggled: isFeatureEnabled(state)('feature_courier_licensees'),
    initialValues: {
      fulfillment_method: 'in_store',
      visit_reason_id: defaultVisitReason && defaultVisitReason.id
    },
    isUtahEvsEnabled: isFeatureEnabled(state)('feature_utah_evs'),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {push, setItem, unsetItem, setData, postItem, getItem, putItem, change, addMessage, getUnpaginatedData, getSearchData, getDataByPost};
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckinLeafMJPPage);
