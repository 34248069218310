import {requiredFieldValidation, maxValidation} from '../../common/form/redux-form/validations';

export default function validate(values, props) {
  const isCaMetrc = props.integrationState && props.integrationState.isCaMetrc;

  return {
    plants: values.plants.map(plant => ({
      packages: plant.packages.map(pkg => ({
        item_master_id: requiredFieldValidation(pkg.item_master_id),
        qty: requiredFieldValidation(pkg.qty) || (isCaMetrc && maxValidation(pkg.qty, undefined, 500)),
        inventory_location_id: requiredFieldValidation(pkg.inventory_location_id),
        integration_type: requiredFieldValidation(pkg.integration_type),
        state_integration_tracking_id: (isCaMetrc && requiredFieldValidation(pkg.state_integration_tracking_id)) || undefined
      })),
    })),
  };
}
