import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field, FieldArray, reduxForm} from 'redux-form';
import {FormLabel, Col, Row} from 'react-bootstrap';
import {FaDollarSign} from 'react-icons/fa';
import CurrencyInput from '../../../../common/form/CurrencyInput';
import NumericInput from '../../../../common/form/NumericInput';
import ReactSelectInput from '../../../../common/form/ReactSelectInput';
import SubmitSection from '../../../../common/form/SubmitSection';
import RemovePointsFieldArray from './RemovePointsFieldArray';

const options = [
  {text: 'Yes', value: '1'},
  {text: 'No', value: '-1'}
];

const byOptions = [
  {text: 'Option 1', value: '1'},
  {text: 'Option 2', value: '2'}
];

export const RewardSetupForm = ({onSubmit}) => (
<form onSubmit={onSubmit}>
  <Row className='input-row'>
    <Col xs={12} className='form-horizontal layout-row'>
      <FormLabel>{I18n.t('retail.rewardSetup.recordPoints')}</FormLabel>
      <Field name='recordPoints' component={ReactSelectInput} props={{
        options
      }}/>
    </Col>
  </Row>
  <Row className='input-row'>
    <Col xs={12} className='form-horizontal layout-row'>
      <FormLabel>{I18n.t('retail.rewardSetup.accruePoints')}</FormLabel>
      <Field name='accruePoints' component={ReactSelectInput} props={{
        options
      }}/>
    </Col>
  </Row>
  <Row className='input-row'>
    <Col xs={12} className='form-horizontal layout-row'>
      <FormLabel>{I18n.t('retail.rewardSetup.accrue')}</FormLabel>
      <Field name='points' component={NumericInput}/>
      <FormLabel>{I18n.t('retail.rewardSetup.points')}</FormLabel>
      <Field name='amount' component={CurrencyInput} props={{
        leftAddon: <FaDollarSign />
      }}/>
      <FormLabel>{I18n.t('retail.rewardSetup.spent')}</FormLabel>
    </Col>
  </Row>
  <Row className='input-row'>
    <Col xs={12} className='form-horizontal layout-row'>
      <FormLabel>{I18n.t('retail.rewardSetup.selectGroups')}</FormLabel>
      <Field name='groups' component={ReactSelectInput} props={{
        options
      }}/>
    </Col>
  </Row>
  <Row className='input-row'>
    <Col xs={12} className='form-horizontal layout-row'>
      <FormLabel>{I18n.t('retail.rewardSetup.patientsAccrue')}</FormLabel>
      <Field name='patientsAccrue' component={ReactSelectInput} props={{
        options
      }}/>
    </Col>
  </Row>
  <Row className='input-row'>
    <Col xs={12} className='form-horizontal layout-row'>
      <FormLabel>{I18n.t('retail.rewardSetup.includeTax')}</FormLabel>
      <Field name='includeTax' component={ReactSelectInput} props={{
        options
      }}/>
    </Col>
  </Row>
  <Row className='input-row'>
    <Col xs={12} className='form-horizontal layout-row'>
      <FormLabel>{I18n.t('retail.rewardSetup.allowEdit')}</FormLabel>
      <Field name='allowEdit' component={ReactSelectInput} props={{
        options
      }}/>
      <FormLabel>{I18n.t('retail.rewardSetup.by')}</FormLabel>
      <Field name='allowEditBy' component={ReactSelectInput} props={{
        options: byOptions
      }}/>
    </Col>
  </Row>
  <FieldArray name='removePoints' component={RemovePointsFieldArray}/>
  <Row className='input-row'>
    <Col xs={12} className='form-horizontal layout-row'>
      <FormLabel>{I18n.t('retail.rewardSetup.worthRate')}</FormLabel>
      <Field name='worthRate' component={NumericInput} props={{
        leftAddon: (<FaDollarSign />)
      }}/>
    </Col>
  </Row>
  <SubmitSection handleSubmit={onSubmit}/>
</form>
);

RewardSetupForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object
};

export default reduxForm({form: 'rewardSetup'})(RewardSetupForm);
