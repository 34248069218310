import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import {Col, Row} from 'react-bootstrap';
import TextInput from '../common/form/TextInput';
import AddItem from '../common/form/AddItem';

const Phenotypes = ({fields}) => {
  return (
    <React.Fragment>
      {fields.map(arrayName =>
      <Row key={arrayName}>
        <Col md={6} >
          <Field name={`${arrayName}.name`} component={TextInput} props={{
            label: I18n.t('strains.form.phenotype')
          }}/>
        </Col>
        <Col md={6} >
          <Field name={`${arrayName}.strain_code`} component={TextInput} props={{
            label: I18n.t('strains.form.abbreviation')
          }}/>
        </Col>
      </Row>
    )}
    <AddItem length={fields.length} addAction={() => fields.push({})} removeAction={fields.pop} allowEmpty={true} text={I18n.t('strains.form.addPhenotype')}/>
  </React.Fragment>);
};

Phenotypes.propTypes = {
  fields: PropTypes.object.isRequired
};

export default Phenotypes;
