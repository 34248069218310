/* eslint-disable import/no-named-as-default */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack} from 'react-router-redux';

import FormWrapper from '../../../common/form/FormWrapper';
import PricingWeightsForm from './PricingWeightsForm';

export class PricingWeightsPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.addGroup = this.addGroup.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  componentWillMount() {
  }

  addGroup(event) {

  }

  onSubmit(event) {
    event.preventDefault();
    this.redirect();
  }

  redirect() {
    this.props.actions.goBack();
  }

  render() {
    const {pricingWeights} = this.props;
    return (
      <FormWrapper className='pricing-weights-page' title='cultivation.pricingWeights.title' goBack={this.redirect}>
        <PricingWeightsForm
          addGroup={this.addGroup}
          handleSubmit={this.onSubmit}
          pricingWeights={pricingWeights}/>
      </FormWrapper>
    );
  }
}

PricingWeightsPage.propTypes = {
  actions: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }),
  pricingWeights: PropTypes.array
};

function mapStateToProps(state) {
  const pricingWeights = [
    {id: 1, size: '.25', uom: 'G', checked: false},
    {id: 2, size: '.50', uom: 'G', checked: false},
    {id: 3, size: '1.0', uom: 'G', checked: false},
    {id: 4, size: '1.75', uom: 'G', checked: false},
    {id: 5, size: '3.5', uom: 'G', checked: false},
    {id: 6, size: '7.0', uom: 'G', checked: false},
    {id: 7, size: '14.0', uom: 'G', checked: false},
    {id: 8, size: '28.0', uom: 'G', checked: false},
    {id: 9, size: '56.0', uom: 'G', checked: false},
    {id: 10, size: '112.0', uom: 'G', checked: true},
    {id: 11, size: '224.0', uom: 'G', checked: false},
    {id: 12, size: '448.0', uom: 'G', checked: false},
    {id: 13, size: '453.0', uom: 'G', checked: false}
  ];
  return {
    pricingWeights
  };
}

function mapDispatchToProps (dispatch) {
  const actions = {goBack};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PricingWeightsPage);
