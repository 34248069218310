import {createSelector} from 'reselect';
import {getUoms} from '../uomsSelectors';
import {getIntegrationState} from '../integration/integrationSelectors';
import {DISCRETE, VOLUME, WEIGHT, excludeForNonHempIngredients} from '../../constants/uomTypes';

const getUomTypesForSelect = (_, props) =>
  props && props.types ? props.types : [WEIGHT, VOLUME, DISCRETE];

export const getUomOptionsForSelect = createSelector(
  [getUoms, getUomTypesForSelect, getIntegrationState],
  (uoms, uomTypes, integration) => {
    return prepareUomOptions(integration, uoms, uomTypes);
  }
);

const prepareUomOptions = (integration, uomOptions, allowedUomTypes) => {
  const isHemp = integration.isHemp;

  return uomOptions.filter(uomOption => {
    if (!isHemp && excludeForNonHempIngredients.includes(uomOption.uom_code)) return false;

    return allowedUomTypes.includes(uomOption.uom_type);
  });
};

export default getUomOptionsForSelect;
