import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from 'react-bootstrap';

const LabelPrinterStatusBlock = (props) => {

  return (<Row>
    {
      React.Children.map(props.children, (child, index) => {
        return <Col md={12}>{child}</Col>;
      })
    }
  </Row>);

};

LabelPrinterStatusBlock.propTypes = {
  children: PropTypes.array,
};

export default LabelPrinterStatusBlock;
