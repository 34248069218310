/* eslint-disable import/no-named-as-default */
import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import {goBack, push} from 'react-router-redux';
import {change} from 'redux-form';
import get from 'lodash.get';
import SimplePagination from '../../common/SimplePagination';
import {unsetData, setData} from '../../../actions/dataActions';
import {setItem} from '../../../actions/itemActions';
import {getUnpaginatedData, postItem, getItem, deleteItem, getSearchData, postData, getDataByPost} from '../../../actions/apiActions';
import {checkinConsumers} from '../../../selectors/customersSelectors';
import {getPaginationFromSearch} from '../../../selectors/paginationSelectors';
import {getDataUpdateAvailable, dataUpdateSetAvailable} from '../../../selectors/dataUpdateSelectors';
import {isPaLeafIntegrator} from '../../../selectors/integration/leafSelectors';
import {getCurrentFacilityCountry} from '../../../selectors/facilitiesSelectors';
import {getAllowAnonymousOrders} from '../../../selectors/salesSettingsSelectors';
import {getInternationalPhoneCountryCode, getInternationalPhoneNumber} from '../../../util/internationalHelpers';
import { addMessage } from '../../../actions/systemActions';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import * as messageTypes from '../../../constants/messageTypes';
import AnonymousOrderBundle from '../../orders/order/anonymous/AnonymousOrderBundle';
import CheckinCustomerForm from './CheckinCustomerForm';
import ScanInputModal from '../../scan-input/ScanInputModal';

const formName = 'checkin';
export class CheckinCustomerPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      ready: false,
      filtered: false,
      showScanModal: false,
      scanType: '',
      customersDetails: {},
      selectedCustomers: [],
      formValues: {},
      reset: false
    };

    this.onSubmit = this.onSubmit.bind(this);

    this.resetList = this.resetList.bind(this);

    this.getData = this.getData.bind(this);
    this.showScanModal = this.showScanModal.bind(this);
    this.hideScanModal = this.hideScanModal.bind(this);
    this.onScanComplete = this.onScanComplete.bind(this);
    this.onSelectCustomer = this.onSelectCustomer.bind(this);
  }

  componentWillMount() {
    this.props.actions.unsetData(dataNames.consumers);
    this.props.actions.unsetData(dataNames.customerStats);
    this.props.actions.unsetData(dataNames.customerRecentHistories);
    this.props.actions.getUnpaginatedData(
      `/api/consumer_groups`,
      dataNames.customerGroups,
      {failed: I18n.t('customers.getGroups.failed')}
    );
    this.props.actions.getItem(
      '/api/csrs',
      itemNames.budTendersObject,
      {failed: I18n.t('customers.getBudTenders.failed')}
    );
    this.props.actions.getUnpaginatedData(
      '/api/queue/visit_reasons',
      dataNames.visitReasons,
      {});
  }

  componentWillReceiveProps(nextProps){
    if(dataUpdateSetAvailable(nextProps.dataUpdateAvailable, this.props.dataUpdateAvailable ) && !this.state.reset) {
      setTimeout(() => this.onSubmit(this.state.formValues), 100);
    }
  }

  onSubmit(formValues) {
    if(!Object.keys(formValues).length) return false;
    this.setState({ready: false, formValues, reset: false});
    this.getData(1, formValues);
  }

  onSelectCustomer(id) {
    this.setState(
      {selectedCustomers: this.state.selectedCustomers
        .map((id) => id)
        .concat([id])
        .filter((id, index, self) => self.indexOf(id) === index)
      });
  }

  getData(page, formValues){
    if(!page){
      page = 0;
    }else{
      page = page - 1;
    }

    if(!formValues){
      formValues = this.state.formValues;
    }

    const isFiltered = ['first_name', 'last_name', 'email_address', 'phone_number', 'consumer_code', 'identification_number'].reduce((acc,field) => {
      if(acc) return acc;
      return formValues[field] !== undefined;
    }, false);

    if(!isFiltered) this.setState({filtered: false});

    const phoneCountryCode = getInternationalPhoneCountryCode(formValues.phone_number);
    const phone = getInternationalPhoneNumber(formValues.phone_number);

    let filter = `active: 1`;
    filter += formValues.first_name ? ` AND first_name: *${formValues.first_name}*` : '';
    filter += formValues.last_name ? ` AND last_name: *${formValues.last_name}*` : '';
    filter += formValues.email_address ? ` AND email_address: *${formValues.email_address.replace('@', ' ')}*` : '';
    filter += phone ? ` AND default_phone_number: *${phone}*` : '';
    filter += phoneCountryCode ? ` AND default_phone_country_code: *${phoneCountryCode}*` : '';
    filter += formValues.consumer_code ? ` AND consumer_code: *${formValues.consumer_code}*` : '';
    const escaped_identification_number = get(formValues,'identification_number','').replace('  ', '\\ ');
    filter +=  escaped_identification_number ?
      ` AND (drivers_license_number: *${escaped_identification_number}* OR med_license_number: *${escaped_identification_number}* OR temp_license_number: *${escaped_identification_number}*)` : '';

    if (this.props.isPALeafIntegrator) {
      filter += formValues.med_license_number ? ` AND med_license_number: *${formValues.med_license_number}*` : '';
      filter += formValues.patient_state_id ? ` AND patient_state_id: *${formValues.patient_state_id}*` : '';
      filter += formValues.caregiver_state_id ? ` AND caregiver_state_id: *${formValues.caregiver_state_id}*` : '';
      filter += formValues.drivers_license_number ? ` AND drivers_license_number: *${formValues.drivers_license_number}*` : '';
    }

    const params = {sort: 'id asc',
      query: 'matchall',
      size: 5,
      start: (page * 5),
      filter};

    this.props.actions.getSearchData('/api/search/customers', dataNames.consumers,
    {failed: 'consumer.checkin.getCustomersFailed'}, params,
      (data) => {
        const ids = [];
        const in_consumer_ids = data.map(cust => {
          if(cust.image_file_id){
            ids.push(cust.image_file_id);
          }
          return cust.id;
        });
        const markAsReady = () => {
          this.setState({filtered: true, ready: true});
        };
        const stats = [];
        const histories = [];
        const statPromises = [];
        const histPromises = [];
        in_consumer_ids.map(id => {
          statPromises.push(this.props.actions.getItem(`/api/consumer_orders/${id}/stats`, itemNames.customerStat,
            undefined, undefined, (stat) => {
              stats.push({...stat, id});
              markAsReady();
            }));
          histPromises.push(this.props.actions.getItem(`/api/consumer_orders/${id}/recent_history`, itemNames.customerRecentHistory,
            undefined, undefined, (history) => {
              histories.push({...history, id});
              markAsReady();
            }));
        });

        Promise.all(statPromises).then(() => {
          markAsReady();
          this.props.actions.setData(stats, dataNames.customerStats);
        });
        Promise.all(histPromises).then(() => {
          markAsReady();
          this.props.actions.setData(histories, dataNames.customerRecentHistories);
        });

        if(ids.length){
          this.props.actions.getDataByPost('/api/images/multiple', {ids}, dataNames.images);
        }


        if (!data.length) {
          this.props.actions.addMessage(messageTypes.info, 'userAccounts.grid.noResults');
        }
      });
    if(!isFiltered) window.scrollTo(0, 0);

  }
  resetList() {
    this.setState({reset: true}, () => {
      this.props.actions.unsetData(dataNames.consumers);
      this.props.actions.unsetData(dataNames.customersQueue);
    });
  }

  showScanModal(scanType) {
    return () => {
      this.setState({showScanModal: true, scanType});
    };
  }

  hideScanModal() {
    this.setState({showScanModal: false});
  }

  onScanComplete(parsedData) {
    if(parsedData){
      this.props.actions.change(formName, 'identification_number', parsedData.dl);
      this.setState({showScanModal: false, searchString: parsedData.dl});
    }
  }

  render() {
    const {ready, filtered,customersDetails} = this.state;
    const {consumers, customerStats, customerRecentHistories, pagination, timezone,
      budTenders, isPALeafIntegrator, countryCode, allowAnonymousOrders} = this.props;

    return (<div>
        <h1>{I18n.t('consumers.checkin.checkIn')}</h1>
        {allowAnonymousOrders ? <AnonymousOrderBundle /> : null}
        <ScanInputModal
          showModal={this.state.showScanModal}
          scanType={this.state.scanType}
          onScanComplete={this.onScanComplete}
          hideModal={this.hideScanModal} />
        <CheckinCustomerForm
          form = {formName}
          consumers = {consumers}
          budTenders = {budTenders}
          resetList={this.resetList}
          onSubmit={this.onSubmit}
          onSelectCustomer={this.onSelectCustomer}
          selectedCustomers={this.state.selectedCustomers}
          customerDetails={customersDetails}
          ready={ready}
          customerStats={customerStats}
          customerRecentHistories={customerRecentHistories}
          filtered={filtered}
          timezone={timezone}
          showScanModal={this.showScanModal}
          isPALeafIntegrator={isPALeafIntegrator}
          countryCode={countryCode}
          />
        {consumers.length > 0 && pagination.last_page > 1 &&
          <SimplePagination
            items={pagination.last_page}
            activePage={pagination.current_page}
            maxButtons={10}
            onSelect={(page) => this.getData(page)}
            next prev first last ellipsis/>}
        </div>
    );
  }
}

CheckinCustomerPage.propTypes = {
  actions: PropTypes.shape({
    getDataByPost: PropTypes.func.isRequired,
    setData: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    getItem:PropTypes.func.isRequired,
    setItem: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired,
    deleteItem: PropTypes.func.isRequired,
    getSearchData: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    addMessage: PropTypes.func.isRequired
  }),
  consumers: PropTypes.array,
  pagination: PropTypes.object,
  timezone: PropTypes.string.isRequired,
  budTenders: PropTypes.array.isRequired,
  isPALeafIntegrator: PropTypes.bool.isRequired,
  countryCode: PropTypes.string,
};

function mapStateToProps(state) {
  const {customerRecentHistories,
  customerStats, timezone, budTendersObject} = state;
  const budTenders = Object.keys(budTendersObject).map(id => ({
    id,
    name: budTendersObject[id]
  }));

  return {
    allowAnonymousOrders: getAllowAnonymousOrders(state),
    budTenders,
    consumers: checkinConsumers(state),
    customerRecentHistories,
    customerStats,
    pagination: getPaginationFromSearch(state, {size:5, name: dataNames.customers}),
    dataUpdateAvailable: [getDataUpdateAvailable(state, {name: dataNames.consumers, core: 'customers'})],
    timezone,
    isPALeafIntegrator: isPaLeafIntegrator(state),
    countryCode: getCurrentFacilityCountry(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, {goBack, push, getUnpaginatedData, postItem, getItem, unsetData,
    setItem, deleteItem,  getSearchData, postData, getDataByPost,
    change, addMessage, setData});
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CheckinCustomerPage);
