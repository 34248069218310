import {reservationLinkMap} from '../../constants/reservationSources';

export const getLinkForSourceString = (entityType) => {
  return reservationLinkMap[entityType] || false;
};

export const getReservationSourceLink = (entityType, id) => {
  const linkString = getLinkForSourceString(entityType);
  return typeof linkString === 'string' ? linkString.replace(':id', id) : false;
};
