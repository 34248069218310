import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { I18n } from 'react-redux-i18n';
import map from 'lodash.map';
import values from 'lodash.values';

import { getItem, getUnpaginatedData, getDataByPost } from '../../../actions/apiActions';
import {unsetItem} from '../../../actions/itemActions';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import * as itemTransactionTypes from '../../../constants/itemTransactionTypes';
import * as p from '../../../constants/permissions';
import {getInventoryItem, getItemExternalId} from '../../../selectors/inventoryItemsSelectors';
import {
  getItemHistoryWithDelta,
  getPackageHistoryWithLabResults
} from '../../../selectors/itemTransactionsSelectors';
import {getPartners} from '../../../selectors/partnersSelectors';
import {getTestResultData} from '../../../selectors/testResultsSelectors';

import ModalWrapper from '../../common/ModalWrapper';
import ContentConcealer from '../../common/concealers/ContentConcealer';
import PermissionLink from '../../common/PermissionLink';
import HistoryNotes from './HistoryNotes';
import TablePageWrapper from '../../common/grid/TablePageWrapper';
import InProgressOverlay from '../../common/InProgressOverlay';
import defineColumns from './columnDefinitions';
import ItemCreatedDetails from './details/ItemCreatedDetails';
import QuickMoveNewItemDetails from './details/QuickMoveNewItemDetails';
import QuickMoveOldItemDetails from './details/QuickMoveOldItemDetails';
import PackageSplittedOldItemDetails from './details/PackageSplittedOldItemDetails';
import PackageSplittedNewItemDetails from './details/PackageSplittedNewItemDetails';
import PackageTaggingHistory from './details/PackageTaggingHistory';
import ItemMasterChangedDetails from './details/ItemMasterChangedDetails';
import ItemDeltaDetails from './details/ItemDeltaDetails';
import ItemUsedForFeedingScheduleDetails from './details/ItemUsedForFeedingScheduleDetails';
import LabResultDetails from './details/LabResultDetails';
import {getIntegrationState} from '../../../selectors/integration/integrationSelectors';
import {getIntegrationAdjustmentReasonOptions} from '../../../selectors/integration/adjustmentReasonsSelectors';
import PlantCreated from './details/PlantCreated';

export class ItemHistoryPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      ready: false,
      showNotesModal: false,
      displayedNotes: null,
      tabs: [
        {
          id: 'item',
          eventKey: 'item',
          title: 'itemTransactions.item.title',
          actions: [],
        },
      ],
      activeTab: 'item',
    };

    this.switchTab = this.switchTab.bind(this);
    this.openNotesModal = this.openNotesModal.bind(this);
    this.expandComponent = this.expandComponent.bind(this);
    this.renderHarvestInfo = this.renderHarvestInfo.bind(this);
    this.renderModalHistoryNotes = this.renderModalHistoryNotes.bind(this);
    this.columns = defineColumns(this);
    this.adjustmentReasonsKeyValues = {};
  }

  componentWillMount() {
    const id = this.props.params.id || '';
    const {actions: {getUnpaginatedData, getItem, getDataByPost}} = this.props;
    const promises = [
      this.props.actions.getItem(`/api/items/${id}`, itemNames.inventoryItem, {failed: 'packages.getPackage.failed'}, {detailed: 1})
        .then(() => {
          const {item: {package_id}, item} = this.props;
          if (package_id) {
            this.setState(state => ({
              tabs: state.tabs.concat({
                id: 'package',
                eventKey: 'package',
                title: 'itemTransactions.package.title',
                actions: [],
              }),
            }));

            const promises = [
              getUnpaginatedData(`/api/packages/${package_id}/log`, dataNames.packageEvents, {failed: 'harvests.getHistory.failed'}),
              getItem(`/api/lab_results/by_package/${package_id}`, itemNames.testResult, {failed: 'cultivation.testResults.get.failed'}),
              getUnpaginatedData('/api/item_transactions', dataNames.itemTransactions, {failed: 'itemTransactions.get.failed'}, {package_id}),
              getUnpaginatedData('/api/adjustment_reasons', dataNames.adjustmentReasons, {failed: 'adjustmentReasons.get.failed'}),              
              getUnpaginatedData(
                '/api/integration/adjustment_reasons',
                dataNames.integrationAdjustmentReasons,
                {failed: 'packages.modify.getIntegrationAdjustmentReasons.failed'}
              )
            ];

            if (this.props.isLeaf) {
              promises.push(
                getDataByPost('/api/items/by_package_ids', {ids: [package_id]}, dataNames.inventoryItems, {failed: 'items.get.failed'})
                  .then(items => {
                    const ids = items.map(i => i.id);
                    getUnpaginatedData('/api/integration-mapping', dataNames.inventoryItemsMapping, null, {key: 'inv_item', in_internal_identifier: ids});
                  })
              );
            }

            if(item.package && item.package.lot && item.package.lot.source_type === 'live_waste') {
              promises.push(
                getUnpaginatedData('/api/waste', dataNames.waste, {}, {source_type: 'location', detailed: 1}),
                getUnpaginatedData('/api/cultivation/sections', dataNames.sections)
              );
            }

            return Promise.all(promises);
          } else {
            return this.props.actions.getUnpaginatedData('/api/item_transactions', dataNames.itemTransactions, {failed: 'itemTransactions.get.failed'}, {item_id: id});
          }
        }),
      this.props.actions.getUnpaginatedData(`/api/items/${id}/log`, dataNames.itemEvents, {failed: 'harvests.getHistory.failed'}),
      this.props.actions.getUnpaginatedData(`/api/phases`, dataNames.phases, {failed: 'phases.get.failed'}),
      this.props.actions.getUnpaginatedData(`/api/partners`, dataNames.partners, {failed: 'partners.get.failed'}),
      this.props.actions.getUnpaginatedData(`/api/packages/purposes`, dataNames.purposes, {failed: 'purposes.get.failed'}),
    ];
    Promise.all(promises)
      .then(() => this.setState({ready: true}))
      .catch(() => this.setState({ready: true}))
    ;

    this.createAdjustmentReasonHash();
  }

  componentWillUnmount() {
    this.props.actions.unsetItem(itemNames.testResult);
  }

  switchTab(activeTab) {
    this.setState({activeTab});
  }

  openNotesModal(event, displayedNotes) {
    event.stopPropagation();
    this.setState({ showNotesModal: true, displayedNotes });
  }

  static expandableRow(row) {
    return row.model;
  }

  createAdjustmentReasonHash(){
    const { integrationAdjustmentReasons, adjustmentReasons } = this.props; 
    const ar = (integrationAdjustmentReasons.length > 0) ? integrationAdjustmentReasons : adjustmentReasons;
    this.adjustmentReasonsKeyValues = map(ar, values).reduce((a,b) => {    
      a[b[0]] = b[1];    
      return a;    
    }, {});
  }

  expandComponent(row) {
    switch (row.transaction_type) {
    case itemTransactionTypes.create:
    case itemTransactionTypes.receipt:
      return <ItemCreatedDetails item={Object.assign({}, row.model, {notes: row.notes, state_integration_tracking_id: this.props.tracking_id})}/>;
    case itemTransactionTypes.manual:
    case itemTransactionTypes.merge:
    case itemTransactionTypes.mfgProduction:
    case itemTransactionTypes.newLevel:
    case itemTransactionTypes.none:
    case itemTransactionTypes.packaging:
    case itemTransactionTypes.reconcile:
    case itemTransactionTypes.restock:
    case itemTransactionTypes.sale:
    case itemTransactionTypes.transfer:
    case itemTransactionTypes.cancelTransfer:
    case itemTransactionTypes.sharedItemClone:
    case itemTransactionTypes.sharedItemTransactionReplication:
      if (!row.delta) {
        return I18n.t('itemTransactions.noEventRecorded');
      }
      return <ItemDeltaDetails adjustmentReasons={this.adjustmentReasonsKeyValues}  delta={row.delta} model={row.model}/>;
    case itemTransactionTypes.split:
      if (!row.delta) {
        return <QuickMoveNewItemDetails item={row.model}/>;
      }
      if (row.model.transaction && row.model.transaction.new_item) {
        return <QuickMoveOldItemDetails delta={row.delta} model={row.model}/>;
      }
      return <ItemDeltaDetails adjustmentReasons={this.adjustmentReasonsKeyValues}  delta={row.delta}/>;
    case itemTransactionTypes.packageSplit:
      if (!row.delta) {
        return <PackageSplittedNewItemDetails model={row.model} partners={this.props.partners}/>;
      }
      if (row.model.transaction && row.model.transaction.new_item) {
        return <PackageSplittedOldItemDetails delta={row.delta}  model={row.model}/>;
      }
      return <ItemDeltaDetails adjustmentReasons={this.adjustmentReasonsKeyValues} delta={row.delta}/>;
    case itemTransactionTypes.newItemMaster:
      return <ItemMasterChangedDetails item={row.model}/>;
    case itemTransactionTypes.feedingSchedule:
      return <ItemUsedForFeedingScheduleDetails delta={row.delta} model={row.model}/>;
    case 'LAB_RESULT_ASSIGNED':
    case 'LAB_RESULT_UNASSIGNED':
      return <LabResultDetails model={row.model} eventType={row.transaction_type}/>;
    case itemTransactionTypes.createPlant:
      return <PlantCreated item={row.model}/>;
    case itemTransactionTypes.tagUpdate:
      return <PackageTaggingHistory message={row.message} model={row.model}/>;
    default:
      return null;
    }
  }

  getData() {
    return (this.state.activeTab === 'package')
      ? this.props.packageHistory
      : this.props.itemHistory;
  }

  getColumns() {
    return this.columns[this.state.activeTab];
  }

  render() {
    const { tabs, activeTab, ready } = this.state;
    this.createAdjustmentReasonHash();

    return (
      <div className='harvest-history-page history-page'>
        {(!ready) && (<InProgressOverlay isActive={true} />)}
        {this.renderModalHistoryNotes()}
        {this.renderHarvestInfo()}
        <TablePageWrapper
          ref={this.ref}
          settingKey='packages-history'
          columns={this.getColumns()}
          tabs={tabs}
          activeTab={activeTab}
          switchTab={this.switchTab}
          data={this.getData()}
          selectedRows={[]}
          handleSelect={() => {}}
          showHistory={false}
          hideExport={true}
          hideScanSearch={true}
          bstProps={{
            selectRow: {
              clickToSelect: false,
              clickToExpand: true,
              hideSelectColumn: true,
            },
            expandableRow: ItemHistoryPage.expandableRow,
            expandComponent: this.expandComponent,
            options : {
              defaultSortName: 'event_date',
              defaultSortOrder: 'desc',
            },
          }}
        />
      </div>
    );
  }

  renderHarvestInfo() {
    const {item, testResult, tracking_id} = this.props;
    const linkProps = {
      title: I18n.t('common.history'),
      to: `/test-results/view/${testResult.id}`,
      className: 'btn btn-sm btn-primary',
      target: '_blank',
    };

    return (
      <div className='harvest-history-page-harvest-info'>
        <ContentConcealer show={!!item.package_code}>
          <p>{I18n.t('harvests.packageId')}: {item.package_code}</p>
        </ContentConcealer>
        <ContentConcealer show={!!item.lot_number}>
          <p>{I18n.t('harvests.lotId')}: {item.lot_number}</p>
        </ContentConcealer>
        <p>{I18n.t('harvests.product')}: {item.item_name || ''}</p>
        <p>{I18n.t('harvests.location')}: {item.location_name || ''}</p>
        <ContentConcealer show={Boolean(tracking_id)}>
          <p>{I18n.t('harvestPackages.form.trackingId')}: {tracking_id}</p>
        </ContentConcealer>
        <ContentConcealer show={!!testResult.id}>
          <div>
            <p>{I18n.t('cultivation.testResults.form.testingId')}: {testResult.testing_id || ''}</p>
            <p>{I18n.t('cultivation.testResults.table.testingDate')}: {testResult.testing_date || ''}</p>
            <PermissionLink permissions={[p.view_testing]} props={linkProps}>
              {I18n.t('common.viewTestResult')}
            </PermissionLink>
          </div>
        </ContentConcealer>
      </div>
    );
  }

  renderModalHistoryNotes() {
    const { showNotesModal, displayedNotes } = this.state;
    const onHide = () => this.setState({ showNotesModal: false });

    return (
      <ModalWrapper
        title='history.notes'
        notes={displayedNotes}
        onHide={onHide}
        Component={HistoryNotes}
        showModal={showNotesModal}
      />
    );
  }
}

ItemHistoryPage.propTypes = {
  item: PropTypes.object.isRequired,
  testResult: PropTypes.object.isRequired,
  itemHistory: PropTypes.array.isRequired,
  packageHistory: PropTypes.array.isRequired,
  partners: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    getItem: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    getDataByPost: PropTypes.func.isRequired,
  }).isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  isLeaf: PropTypes.bool,
  adjustmentReasons: PropTypes.array,
  integrationAdjustmentReasons: PropTypes.array,
  tracking_id: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
  const {isLeaf, isMetrc} = getIntegrationState(state);
  const externalId = getItemExternalId(state);
  const item = getInventoryItem(state);
  const tracking_id = isMetrc && item.state_integration_tracking_id || externalId;
  return {
    item,
    adjustmentReasons: state[dataNames.adjustmentReasons],
    integrationAdjustmentReasons: getIntegrationAdjustmentReasonOptions(state),
    itemHistory: getItemHistoryWithDelta(state, ownProps),
    packageHistory: getPackageHistoryWithLabResults(state),
    partners: getPartners(state),
    testResult: getTestResultData(state),
    isLeaf,
    tracking_id
  };
}

function mapDispatchToProps(dispatch) {
  const actions = { getItem, getUnpaginatedData, unsetItem, getDataByPost };
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemHistoryPage);
