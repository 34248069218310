import {
  maxLengthValidation,
  notEmptyArrayValidation,
  requiredFieldValidation
} from '../../../common/form/redux-form/validations';

export const validate = (values) => {
  const errors = {
    cupo_number:
      requiredFieldValidation(values.cupo_number, 'cupos.form.cupoNumber') ||
      maxLengthValidation(String(values.cupo_number), 'cupos.form.cupoNumber', 10)
  };
  errors.modalities = values.modalities.map((modality) => ({
    strains: modality.selected && notEmptyArrayValidation(modality.strains)
  }));

  return errors;
};

export default validate;
