const productTabs = [
  {
    id: 'softReservations',
    eventKey: 'softReservations',
    title: 'reservations.labels.softReservations',
    path: '/products/:id/reservations/soft',
    actions: [
      {
        id: 'releaseAll',
        variant: 'danger',
        text: 'reservations.labels.releaseAllProducts',
      }
    ]
  },
  {
    id: 'hardReservations',
    eventKey: 'hardReservations',
    title: 'reservations.labels.hardReservations',
    path: '/products/:id/reservations/hard',
    actions: [
      {
        id: 'releaseAll',
        variant: 'danger',
        text: 'reservations.labels.releaseAllPackages',
      }
    ]
  }
];

export const packageTabs = [
  {
    id: 'hardReservations',
    eventKey: 'hardReservations',
    title: 'reservations.labels.hardReservations',
    actions: [
      {
        id: 'releaseAll',
        variant: 'danger',
        text: 'reservations.labels.releaseAllPackages',
      }
    ]
  }
];

export default productTabs;
