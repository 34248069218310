import {createSelector} from 'reselect';
import get from 'lodash.get';
import * as dataNames from '../constants/dataNames';
import {leafWaHiddenSubcategories} from '../constants/leaf';
import {UNMEDICATED_CATEGORY_CODES} from '../constants/categories';
import {getCategories} from './categorySelectors';
import {isOklahoma} from './facility/getCurrentFacility';
import {finishedFlower, oklahomaSubcategories} from '../constants/subcategoryGroups';
import {isPALeafIntegrator} from './integrationSelectors';
import { isUtahSelector } from './integration/utahSelectors';
import { getSubCategorySettings } from './settingsSelectors';


export const getSubcategories = (state) => state[dataNames.subcategories];
export const getSubCategoryId = (_, props) => props.subcategory_id;

export const getOrderedSubcategories = createSelector(
  [getSubcategories, getCategories, getSubCategorySettings],
  (subcategories, categories, subcategorySettings) => {
    return categories.reduce((acc, category) => {
      const categoryName = get(category, 'name');
      const categoryCode = get(category, 'category_code');
      const haveSubcategorySettings = Object.keys(subcategorySettings).length;
      const subcategories = get(category, 'subcategories', []).map((subcategory) => {
        const subcategory_name = subcategory.setting && subcategory.setting.display_name || subcategory.display_name || subcategory.name;
        const displayName = `${categoryName}: ${subcategory_name}`;
        const key = `${categoryCode}_${subcategory.subcategory_code}`;
        const jurisdiction_category_code = haveSubcategorySettings
          ? get(subcategorySettings[key], 'jurisdiction_category_code', false)
          : undefined;
        return {
          ...subcategory,
          category,
          category_name: categoryName,
          subcategory_name,
          category_code: categoryCode,
          display_name: displayName,
          jurisdiction_category_code,
        };
      });
      acc = acc.concat(subcategories);
      return acc;
    }, []).sort((a, b) => a.display_name.localeCompare(b.display_name));
  });

export const medicatedSubcategoryFilter = (subcategory, isOklahoma, isPaLeaf, isUtah) => {
  const isMedicated = (subcategory) => {
    return UNMEDICATED_CATEGORY_CODES.indexOf(subcategory.category_code) === -1;
  };

  if(!isMedicated(subcategory)){
    return false; // return false to exclude
  }

  if(isUtah){ // Utah is filtered on back end.  One day we remove this.
    return true;
  }

  // Original code I did not want to break down.  :-)
  return (
    (isOklahoma && oklahomaSubcategories.indexOf(get(subcategory, 'subcategory_code')) !== -1)
      || oklahomaSubcategories.indexOf(get(subcategory, 'subcategory_code')) === -1
    )
    && (
      (isPaLeaf && subcategory.subcategory_code === finishedFlower)
      || subcategory.subcategory_code !== finishedFlower
    );
};

export const getMedicatedSubcategories = createSelector(
  [getOrderedSubcategories, isOklahoma, isPALeafIntegrator, isUtahSelector],
  (subcategories, isOklahoma, isPaLeaf, isUtah) => {
    return subcategories.filter((subcategory) => {
      return medicatedSubcategoryFilter(subcategory, isOklahoma, isPaLeaf, isUtah);
    });
  });


export const getSubCategoryById = createSelector([getSubcategories, getSubCategoryId], (subCategories, subcategory_id) => {
  return subCategories.find(subCategory => subCategory.id === subcategory_id) || {};
});

export const getNonmedicatedSubcategories = createSelector(
  [getOrderedSubcategories],
  (subcategories) => subcategories.filter(
    subcategory => subcategory.category && subcategory.category.category_code === 'UNMEDICATED'
  )
);

export const getNonMedicatedSubcategoryIds = createSelector(
  [getNonmedicatedSubcategories],
  subcategories => subcategories.map(s => s.id)
);

export const isDisabledSubcategoryForOklahoma = (isOklahoma, subcategoryCode) =>
  isOklahoma && ['PLANT_TISSUE', 'TISSUE_CULTURE'].indexOf(subcategoryCode) !== -1;

export const isLeafWaHiddenSubcategory = (isWa, subcategories) => subcategories.filter(subcategory =>
    isWa ? leafWaHiddenSubcategories.indexOf(subcategory.subcategory_code) === -1 : true);

