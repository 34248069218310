import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Button, Col, Row } from 'react-bootstrap';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import TextInput from '../../common/form/TextInput';
import ContentConcealer from '../../common/concealers/ContentConcealer';
import InternationalReduxPhoneInput from '../../common/form/redux-form/InternationalReduxPhoneInput';

const DriverInformation = (props) => {
  const {isReadOnly, isDriverCompany, drivers, createDriver, fields, integrationState: {isBiotrack, isPaLeaf, isPrBiotrack, isIlBiotrack}} = props;
  const useOneDriver = !(isPaLeaf || isBiotrack);

  return (<div>
    {

      fields.map((driver, index) => {
        if(useOneDriver && index > 0) return null;

        const isRequired = isPaLeaf || isIlBiotrack || (isPrBiotrack && index === 0);
        return (<Row key={index}>
          <Col xs={6} sm={4} md={3}>
            <Field
              name={`${driver}.id`}
              component={ReactSelectInput}
              props={{
                label: useOneDriver ? I18n.t(`cultivation.transfers.form.name`) : I18n.t(`cultivation.transfers.form.driver_${index + 1}_name`),
                options: drivers,
                valueKey: 'id',
                textKey: 'full_name',
                disabled: isReadOnly,
                isRequired,
              }}
            >
              <Button
                variant='link'
                className={index === 0 ? 'label-link' : 'hide'}
                onClick={createDriver}>
                {I18n.t('cultivation.transfers.form.createDriver')}
              </Button>
            </Field>
          </Col>
          <Col xs={6} sm={4} md={3}>
            <Field
              name={`${driver}.state_license_number`}
              component={TextInput}
              props={{
                label: I18n.t('cultivation.transfers.form.stateLicense'),
                disabled: true
              }}
            />
          </Col>
          <ContentConcealer show={!isDriverCompany}>
            <Col xs={6} sm={4} md={3}>
              <Field
                name={`${driver}.license_number`}
                component={TextInput}
                props={{
                  label: I18n.t('cultivation.transfers.form.driverLicense'),
                  disabled: true
                }}
              />
            </Col>
          </ContentConcealer>
          <Col xs={6} sm={4} md={3}>
            <InternationalReduxPhoneInput
              name={`${driver}.phone_number`}
              countryCode={`${driver}.country_code`}
              props={{
                label: I18n.t('cultivation.transfers.form.phoneNumber'),
                disabled: true
              }}
            />
          </Col>
        </Row>);
      })
    }
  </div>);

};

DriverInformation.propTypes = {
  getDriverOptions: PropTypes.func,
  isReadOnly: PropTypes.bool,
  isDriverCompany: PropTypes.bool,
  drivers: PropTypes.array,
  createDriver: PropTypes.func,
  fields: PropTypes.object,
  integrationState: PropTypes.object.isRequired,
};

export default DriverInformation;
