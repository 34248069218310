import get from 'lodash.get';
import map from 'lodash.map';
import find from 'lodash.find';
import forEach from 'lodash.foreach';
import {addMessage} from '../systemActions';
import {
  getInventoryReceipt,
  getReassignItemMasters,
  getPurchaseOrder
} from '../../selectors/fillPurchaseOrderSelectors';
import * as messageTypes from '../../constants/messageTypes';
import {getProductionRuns} from '../../selectors/productionRunsSelectors';
import * as dataNames from '../../constants/dataNames';
import {getTransfers, getOpenOrders} from '../../selectors/inventoryItemsSelectors';
import * as apiActions from '../apiActions';
import {updateInventoryReceiptLine} from './index';

/**
 * Applying changed products in Inventory Receipt together with
 * reassign package to another product.
 * @param receiptId
 * @param payload
 * @returns {function(*): *}
 */
export const applyReassignPackaging = (receiptId, payload) => (dispatch) => {
  return dispatch(apiActions.postData(`/api/receipts/${receiptId}/reassign`, payload));
};

/**
 * Checking product/inventory to the possibility of reassigning
 * @param newItemMasterId
 * @param currentItemMaster
 * @param inventories
 * @returns {Function}
 */
export const validateReassignProduct = (newItemMasterId, currentItemMaster, inventories) => (dispatch) => {
  if (
    (dispatch(_validateUomType(newItemMasterId, currentItemMaster)) === false) ||
    (dispatch(_validateProductionRuns(inventories)) === false) ||
    (dispatch(_validatePackagingJobs(inventories)) === false) ||
    (dispatch(_validateOpenTransfers(inventories)) === false) ||
    (dispatch(_validateOpenOrders(inventories)) === false)
  ) {
    return false;
  }

  return true;
};


/**
 * Collecting all package codes into one array
 * @param objects
 * @returns {string[]}
 * @private
 */
const _groupAllPackageCodes = (objects) => {
  const result = [];
  objects.forEach((object) => {
    result.push({
      id: object.id,
      name: object.name,
      packages: map(object.inputs, 'package_code'),
    });
  });

  return result;
};

/**
 * Checking UOM type consistency for product
 * @param newItemMasterId
 * @param currentItemMaster
 * @returns {Function}
 * @private
 */
const _validateUomType = (newItemMasterId, currentItemMaster) => (dispatch, getState) => {
  const reassignPackages = getReassignItemMasters(getState());
  const newItemMaster = find(reassignPackages, {id: newItemMasterId}) || {};

  if (
    get(newItemMaster, 'uom_type') !== get(currentItemMaster, 'uom_type') ||
    get(newItemMaster, 'inventory_attributes.is_prepack') !== get(currentItemMaster, 'inventory_attributes.is_prepack')
  ) {
    return !dispatch(addMessage(messageTypes.error, 'supplyChain.reassignValidateProductUom'));
  }
};

/**
 * Checking that inventories don't use in production runs
 * @param inventories
 * @returns {function(*=, *): boolean}
 * @private
 */
const _validateProductionRuns = (inventories) => (dispatch, getState) => {
  const productionRuns = getProductionRuns(getState());
  const packageCodesUsedInProduction = _groupAllPackageCodes(productionRuns);

  return !inventories.some((inventory) => {
    return packageCodesUsedInProduction.some((packageGroup) => {
      if (packageGroup.packages.includes(inventory.package_code)) {
        return dispatch(addMessage(messageTypes.error, ['supplyChain.reassignValidateInProductionJob', { packageCode: inventory.package_code, id: packageGroup.name }]));
      }
    });
  });
};

/**
 * Checking that inventories don't use in packaging jobs
 * @param inventories
 * @returns {function(*=, *): boolean}
 * @private
 */
export const _validatePackagingJobs = (inventories) => (dispatch, getState) => {
  const packagingJobs = getState()[dataNames.packagingJobs];

  return !inventories.some((inventory) => {
    return packagingJobs.some((packagingJob) => {
      if (inventory.inventory_item_id === packagingJob.source_item_id) {
        return dispatch(addMessage(messageTypes.error, ['supplyChain.reassignValidateInPackagingJob', { packageCode: inventory.package_code, id: packagingJob.id }]));
      }
    });
  });
};

/**
 * Checking that inventories don't contain in open transfers
 * @param inventories
 * @returns {function(*=, *): boolean}
 * @private
 */
export const _validateOpenTransfers = (inventories) => (dispatch, getState) => {
  const openTransfers = getTransfers(getState());

  return !inventories.some((inventory) => {
    return openTransfers.some((transfer) => {
      return transfer.lines.some((line) => {
        return line.inventory.some((lineInventory) => {
          const prepack_rows = get(inventory, 'prepack_inventory_rows', []);
          if (prepack_rows.length > 0) {
            return prepack_rows.some((row) => {
              if (lineInventory.package_code === row.package_code) {
                return dispatch(addMessage(messageTypes.error, ['supplyChain.reassignValidateInTransfers', { packageCode: row.package_code, id: transfer.transfer_number }]));
              }
            });
          }

          if (lineInventory.package_code === inventory.package_code) {
            return dispatch(addMessage(messageTypes.error, ['supplyChain.reassignValidateInTransfers', { packageCode: inventory.package_code, id: transfer.transfer_number }]));
          }
        });
      });
    });
  });
};

/**
 * Checking that inventories don't exist in open orders.
 * @param inventories
 * @returns {function(*=, *): boolean}
 * @private
 */
export const _validateOpenOrders = (inventories) => (dispatch, getState) => {
  const openOrders = getOpenOrders(getState());

  return !inventories.some(inventory => {
    return openOrders.some(order => {
      return order.products.some(product => {
        return product.items.some(item => {
          const prepack_rows = get(inventory, 'prepack_inventory_rows', []);
          if (prepack_rows.length > 0) {
            return prepack_rows.some((row) => {
              if (item.package_code === row.package_code) {
                return dispatch(addMessage(messageTypes.error, ['supplyChain.reassignValidateInOpenOrders', {
                  packageCode: row.package_code,
                  id: order.name
                }]));
              }
            });
          }
          //check to see if the package is in an order and has a package code (aka tracked in lots). We use the package first because there could be multiple items for one package
          if (item.package_code === inventory.package_code && item.package_code) {
            return dispatch(addMessage(messageTypes.error, ['supplyChain.reassignValidateInOpenOrders', {
              packageCode: inventory.package_code,
              id: order.name
            }]));
          }
          //if the item does not have a package code check to see if the item is in the order. This prevents the system from checking if the package id of "null" is in an order, which all items without a package id would come up
          if (item.item_id === inventory.inventory_item_id) {
            return dispatch(addMessage(messageTypes.error, ['supplyChain.reassignValidateInOpenOrdersItemOnly', {
              itemId: inventory.inventory_item_id,
              id: order.name
            }]));
          }
        });
      });
    });
  });
};

/**
 * Action that allows do changes on UI side for Inventory Receipt
 * @param value
 * @param ownProps
 * @returns {Function}
 */
export const reassignPackage = (value, ownProps) => (dispatch) => {
  const { itemMaster, index } = ownProps;
  let newLine = {};

  if (get(itemMaster, 'inventory_attributes.is_prepack')) {

    // Working with current item master
    const inventoryItems = _getCurrentInventoryLines(ownProps);

    // Working with new item master
    const newInventoryItems = _getNewInventoryLines(value, inventoryItems, ownProps);

    newLine = dispatch(_updatePrepackInventoryLine({
      index,
      newInventoryItems,
      item_master_id: value,
      prev_item_master_id: get(itemMaster, 'id'),
      itemMasterChildren: ownProps.itemMasterChildren,
      inv : ownProps.inventory
    }));
  } else {
    newLine = dispatch(_updateBulkInventoryLine({
      index,
      item_master_id: value,
      prev_item_master_id: get(itemMaster, 'id'),
    }));
  }

  return dispatch(updateInventoryReceiptLine(index, newLine));
};

/**
 *
 * @param props
 * @private
 */
const _getCurrentInventoryLines = (props) => {
  const { itemMaster, inventory, itemMasterChildren } = props;
  const allPrepackItemMasters = get(itemMasterChildren, get(itemMaster, 'id'), []);
  const inventoryItems = {};
  forEach(inventory, (inv) => {
    forEach(inv.prepack_inventory_rows, ({ item_master_id, id }) => {
      inventoryItems[id] = find(allPrepackItemMasters, { item_master_id });
    });
  });

  return inventoryItems;
};

/**
 * Looping through inventory and update item masters
 * @param props
 * @returns {function(*, *)}
 * @private
 */
const _updatePrepackInventoryLine = (props) => (dispatch, getState) => {
  const { newInventoryItems, item_master_id, prev_item_master_id, itemMasterChildren, inv } = props;
  let newLine = {};
  const inventoryReceipt = getInventoryReceipt(getState());
  const purchaseOrder = getPurchaseOrder(getState());
  const poLines = get(purchaseOrder, 'lines', []);

  forEach(inventoryReceipt.lines, (line, key) => {
    if (props.index === key) {
      const poLine = find(poLines);

      forEach(line.inventory, (inv, index) => {
        line.inventory[index] = {
          ...inv,
          item_master_id: get(newInventoryItems, `[${inv.id}].id`),
        };
      });

      const prepackChildren = get(itemMasterChildren, item_master_id, []);

      newLine = {
        ...line,
        item_master_id,
        prev_item_master_id,
        po_subitems: map(get(poLine, 'subitems', []), (subItem, index) => {

          return ({
            ...subItem,
            item_master_id: get(prepackChildren, `[${index}].item_master_id`),
          });
        }),
      };
    }
  });
  return updatePoSubItem(newLine, inv);

};

const _updateBulkInventoryLine = (props) => (dispatch, getState) => {
  const { item_master_id, prev_item_master_id } = props;
  let newLine = {};
  const inventoryReceipt = getInventoryReceipt(getState());

  forEach(inventoryReceipt.lines, (line, key) => {
    if (props.index === key) {
      forEach(line.inventory, (inv, index) => {
        line.inventory[index] = {
          ...inv,
          item_master_id: item_master_id,
        };
      });

      newLine = {
        ...line,
        item_master_id,
        prev_item_master_id,
      };
    }
  });

  return newLine;
};

/**
 *
 * @param value
 * @param inventoryItems
 * @param props
 * @private
 */
const _getNewInventoryLines = (value, inventoryItems, props) => {
  const { itemMasterChildren } = props;
  const newPrepackChildren = get(itemMasterChildren, value, []);
  const newInventoryItems = {};

  forEach(inventoryItems, (inventory, line_id) => {
    const weight = get(inventory, 'itemWeight.weight', 0);
    const newItem = find(newPrepackChildren, (prepackChild) => weight === get(prepackChild, 'itemWeight.weight'));
    newInventoryItems[line_id] = newItem;
  });

  return newInventoryItems;
};

const updatePoSubItem = (newLine, prevInventory) => {
  newLine.inventory.forEach(newInventory => {
    const matchedPrevInv = prevInventory.find(prevInv =>
      prevInv.prepack_inventory_rows.some(ppRows => ppRows.id === newInventory.id)
    );

    if (matchedPrevInv) {
      const matchedPPRow = matchedPrevInv.prepack_inventory_rows.find(ppRows => ppRows.id === newInventory.id);
      newInventory.unit_cost = matchedPPRow.unit_cost;
      newInventory.po_subitem_index = matchedPPRow.po_subitem_index;
    }
  });

  return newLine;
};
