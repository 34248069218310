import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import {Button, ButtonToolbar, Col, Row} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import {getFacilitiesWithTypeEqualToCurrentFacility} from '../../../../../selectors/facilitiesSelectors';
import {getFacilityFilters, getFilteredFacilities, getInitialValues,
  getSelectedFacilityIds, getAfterSubmitValue} from '../../../../../selectors/forms/facilityGroupFormSelectors';
import {FACILITY_GROUP_FORM} from '../../../../../constants/forms';
import FormWrapper from '../../../../common/form/FormWrapper';
import ReactSelectInput from '../../../../common/form/ReactSelectInput';
import TextInput from '../../../../common/form/TextInput';
import ReduxButton from '../../../../common/form/redux-form/ReduxButton';
import validate from './validate';

const FacilityGroupEditor = (props) => {
  const {invalid, submitting, pristine, reset} = props;
  const validDeleteStates = ['startDelete', 'delete'];

  //@TODO: Move this to a selector once we know we have the behavior dialed in
  const getGroupsForFacility = (facility) => {
    return props.facilityGroups.reduce((acc, group) => {
      if(group.members.find((member) => member.id === facility.id)) acc.push(group.name);
      return acc;
    }, []).sort();
  };
  const getFacilityIdsWithTheseSettingsInOtherGroups = () => {
    return props.facilities.reduce((acc, facility) => {
      const facilityInOtherGroupWithSettings = props.facilityGroups.filter((group) => {
        return group.controlledSettings.length > 0 && group.id !== props.group.id;
      }).reduce((acc, group) => {
        if(acc) return acc;
        if(group.members.find((m) => m.id === facility.id)) acc = true;
        return acc;
      }, false);
      if(facilityInOtherGroupWithSettings) acc.push(facility.id);
      return acc;
    }, []);
  };
  const disableFacilityIds = getFacilityIdsWithTheseSettingsInOtherGroups();

  const facilityGroupForm = (
    <form onSubmit={props.handleSubmit}>
      <div>
        <div style={{marginTop: '8px', marginBottom: '8px', fontSize: '14px'}}>
          <Row>
            <Col md={4}>
              <Field
                name='name'
                component={TextInput}
                props={{
                  label: 'Facility Group Name'
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <strong>Select Facilities To Include In This Group</strong>
            </Col>
          </Row>
          <div style={{margin: '8px 0px', border: '1px solid #ccc', borderRadius: '2px', padding: '5px'}}>
            <Row>
              <Col md={12}>
                <div className='text-muted'>Filter Facilities: {props.filteredFacilities.length} of {props.facilities.length} Facilities shown</div>
              </Col>
              {
                props.filters.map((filter, index) => {
                  return (<Col md={parseInt(12 / props.filters.length)} key={index}>
                    <Field
                      name={filter.type}
                      component={ReactSelectInput}
                      props={{
                        bsSize: 'small',
                        options: filter.options,
                        textKey: 'text',
                        valueKey: 'text',
                        clearable: false,
                      }}
                    />
                  </Col>);
                })
              }
            </Row>
          </div>
          <table className='table table-striped table-bordered table-sm'>
            <thead>
            <tr>
              <th>&nbsp;</th>
              <th>Facility</th>
              <th>Location</th>
              <th>Type / Subtype</th>
              <th>Groups</th>
            </tr>
            </thead>
            <tbody>
            {
              props.filteredFacilities.length > 0
                ? null
                : <tr><td colSpan={6} style={{textAlign:'center'}}>No Facilities Found For Current Filters!</td></tr>
            }
            {
              props.filteredFacilities.sort((a,b) => a.name.localeCompare(b.name)).map((facility, index) => {
                return (
                  <tr key={index}>
                    <td style={{textAlign: 'center', width: '30px'}}>
                      <Field
                        name={`facility_${facility.id}`}
                        component='input'
                        disabled={disableFacilityIds.indexOf(facility.id) !== -1}
                        type='checkbox'
                      />
                    </td>
                    <td>
                      <div>{facility.name}</div>
                      {
                        disableFacilityIds.indexOf(facility.id) === -1
                          ? null
                          : <div className='text-muted'>Facility Already Has These Settings Through Another Group</div>
                      }
                    </td>
                    <td>
                      <div>{facility.city}, {facility.province_code}</div>
                    </td>
                    <td>
                      <div>{facility.type} {facility.type !== 'dispensary' ? null : ` / ${facility.retail_type}`}</div>
                    </td>
                    <td>
                      {getGroupsForFacility(facility).map((facility, idx) => <div key={idx}>{facility}</div>)}
                    </td>
                  </tr>
                );
              })
            }
            </tbody>
          </table>
        </div>
        {
          props.selectedFacilityIds.length > 0
            ? null
            : <div style={{textAlign: 'right', marginBottom: '8px'}} className='text-danger'>You have not assigned any facilities to this facility group.  You can save it without facilities.  This is just a notice.</div>
          }
        <div style={{clear: 'both'}}>
          <ReduxButton variant={validDeleteStates.indexOf(props.afterSubmitValue) === -1 ? 'default' : 'danger'}
                       size='sm'
                       name={'afterSubmit'}
                       type='submit'
                       props={{
                         value: validDeleteStates.indexOf(props.afterSubmitValue) === -1 ? 'startDelete' : 'delete'
                       }}
                       className={props.mode === 'create' ? 'hide' : 'float-left'}
                       disabled={submitting}>
            {validDeleteStates.indexOf(props.afterSubmitValue) === -1 ? 'Delete' : 'Confirm Delete'}
          </ReduxButton>
          <ButtonToolbar className='float-right'>
            <Button type='reset'
                    onClick={(e) => {
                      reset();
                      e.target.blur();
                    }}>
              {I18n.t('packages.form.reset')}
            </Button>
            <ReduxButton variant='primary'
                         size='sm'
                         name={'afterSubmit'}
                         type='submit'
                         props={{value:'redirect'}}
                         disabled={invalid || submitting || pristine}>
              Save And Return
            </ReduxButton>
          </ButtonToolbar>
        </div>
      </div>
      <div style={{clear: 'both'}} />
    </form>
  );

  return (!props.inModal)
    ? <FormWrapper title={props.mode === 'edit' ? 'Edit Facility Group' : 'Create Facility Group'} goBack={props.onGoBack}>{facilityGroupForm}</FormWrapper>
    : <div>{facilityGroupForm}</div>;

};

FacilityGroupEditor.propTypes = {
  facilities: PropTypes.array,
  filteredFacilities: PropTypes.array,
  filters: PropTypes.array,
  inModal: PropTypes.bool,
  onGoBack: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  selectedFacilityIds: PropTypes.array,
  handleSubmit: PropTypes.func,
  facilityGroups: PropTypes.array,
  group: PropTypes.object.isRequired,
  afterSubmitValue: PropTypes.string,
  invalid: PropTypes.boolean,
  submitting: PropTypes.boolean,
  pristine: PropTypes.boolean,
  reset: PropTypes.func,
};

const FacilityGroupEditorForm = reduxForm({
  form: FACILITY_GROUP_FORM,
  validate,
  enableReinitialize: true,
})(FacilityGroupEditor);

function mapStateToProps(state) {
  return {
    facilities: getFacilitiesWithTypeEqualToCurrentFacility(state),
    filteredFacilities: getFilteredFacilities(state),
    filters: getFacilityFilters(state),
    initialValues: getInitialValues(state),
    selectedFacilityIds: getSelectedFacilityIds(state),
    afterSubmitValue: getAfterSubmitValue(state), // Used on two part delete process
  };
}

export default connect(mapStateToProps)(FacilityGroupEditorForm);


