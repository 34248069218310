import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field, reduxForm} from 'redux-form';
import get from 'lodash.get';
import {Button, ButtonToolbar, Col, Row} from 'react-bootstrap';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import {SUPPLY_CHAIN_MAPPING} from '../../../../constants/forms';
import validate from './validate'; //eslint-disable-line
import ProductsMap from '../platform/ProductsMap';
import AutoProductsMap from '../platform/AutoProductsMap';
import ContentConcealer from '../../../common/concealers/ContentConcealer';
import CheckBoxInput from '../../../common/form/CheckBoxInput';

export const PlatformSupplyChainMappingForm = (props) => {
  const {
    change,
    error,
    submitting,
    pristine,
    invalid,
    handleSubmit,
    onIncomingTransferChange,
    onUnreceivedPurchaseOrderChange,
    onAutoGeneratePurchaseOrderChange,
    incomingTransferOptions,
    purchaseOrderOptions,
    incomingTransferProductsOptions,
    formErrors,
    purchaseOrderInfo,
    selectedIncomingTransfer,
    canGenerateAutomaticPO,
    compatibleItemMasters,
    itemMasterMappings,
    formValues,
    isActiveFacilityLab,
    transferIsBeingLoaded
  } = props;

  const disableAutoPO = ((!compatibleItemMasters || Object.keys(compatibleItemMasters).length === 0) && !isActiveFacilityLab);

  // Incoming Transfer is not selected
  let someProductsAreNotMapped = get(formValues, 'incoming_transfer_id') <= 0;

  // Unreceived PO is not selected
  someProductsAreNotMapped = someProductsAreNotMapped || (get(formValues, 'purchase_order_id', 0) <= 0 && !get(formValues, `auto_generate_po`, false));

  // None of the PO Product are mapped (Product Mapping)
  someProductsAreNotMapped = someProductsAreNotMapped || get(purchaseOrderInfo, 'lines', []).some((line) => {
    return ((!get(formValues, `auto_generate_po`, false)) && get(formValues, `product_map_${line.line_id}`, []).length <= 0);
  });

  // None of the Incoming Transfer Items are mapped (Auto PO product mapping)
  someProductsAreNotMapped = someProductsAreNotMapped || incomingTransferProductsOptions.some((item) => {
    return ((get(formValues, `auto_generate_po`, false)) && get(formValues, `auto_po_product_map_${item.id}`, 0) <= 0 && !get(formValues, `auto_po_product_map_auto_${item.id}`, false));
  });

  return (
    <form className='supply-chain-mapping-form' onSubmit={handleSubmit}>
      <Row className='padding-top'>
        <Col xs={12} md={8} lg={6}>
          <Field
            name='incoming_transfer_id' component={ReactSelectInput}
            props={{
              placeholder: I18n.t('supplyChainMapping.form.selectIncomingTransfer'),
              label: I18n.t('supplyChainMapping.form.incomingTransfers'),
              options: incomingTransferOptions,
              isRequired: true,
              onChange: (value) => {
                onIncomingTransferChange(value);
                change('incoming_transfer_id', value);
              }
            }}
          />
        </Col>
        <Col xs={12} md={8} lg={6}>
        {selectedIncomingTransfer > 0 ?
          <div>
            <Field
              name='purchase_order_id' component={ReactSelectInput}
              props={{
                disabled: get(formValues, 'auto_generate_po', false) || transferIsBeingLoaded,
                label: I18n.t('supplyChainMapping.form.unreceivedPurchaseOrders'),
                options: purchaseOrderOptions,
                isRequired: true,
                onChange: (value) => {
                  onUnreceivedPurchaseOrderChange(value, (value) => change('purchase_order_id', value));
                }
              }}
            />
          <ContentConcealer show={canGenerateAutomaticPO}>
            <Field
              name='auto_generate_po'
              style={{width:'18px'}}
              className='inline-checkbox'
              component={CheckBoxInput}
              disabled={transferIsBeingLoaded || disableAutoPO}
              props={{
                label: I18n.t('supplyChainMapping.form.autoGenerateAPurchaseOrder'),
                groupClassName: 'inline-checkbox',
                onChange: (event) => {
                  const {target: {checked}} = event;
                  if (checked) {
                    onUnreceivedPurchaseOrderChange(0);
                    change('purchase_order_id', 0);
                    onAutoGeneratePurchaseOrderChange();
                  }
                  change('auto_generate_po', checked);
                }
              }}
            />
          </ContentConcealer>
          </div>
          : null
        }
      </Col>
      </Row>

      {selectedIncomingTransfer > 0 &&
        <div>
          <h3>{I18n.t('supplyChainMapping.form.productNameMapping')}</h3>
          {(get(formValues, 'auto_generate_po', false)) ? // Auto mapping is only available for labs
            <AutoProductsMap
              change={change}
              error={error}
              compatibleItemMasters={compatibleItemMasters}
              incomingTransferProducts={incomingTransferProductsOptions}
              formErrors={formErrors}
              formValues={formValues}
              isActiveFacilityLab={isActiveFacilityLab}
            />
            :
            <ProductsMap
              error={error}
              purchaseOrderInfo={purchaseOrderInfo}
              incomingTransferProductsOptions={incomingTransferProductsOptions}
              itemMasterMappings={itemMasterMappings}
              formErrors={formErrors}
            />
          }
          <Row>
            <Col xs={12}>
              <ButtonToolbar className='float-right'>
                <Button variant='primary'
                        type='submit'
                        disabled={submitting || pristine || invalid || someProductsAreNotMapped || error}>
                  {I18n.t('supplyChainMapping.form.receive')}
                </Button>
              </ButtonToolbar>
            </Col>
          </Row>
        </div>
      }
    </form>
  );
};

PlatformSupplyChainMappingForm.propTypes = {
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  error: PropTypes.string,
  change: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  incomingTransferOptions: PropTypes.array.isRequired,
  purchaseOrderOptions: PropTypes.array.isRequired,
  onIncomingTransferChange: PropTypes.func.isRequired,
  onUnreceivedPurchaseOrderChange: PropTypes.func.isRequired,
  incomingTransferProductsOptions: PropTypes.array.isRequired,
  formErrors: PropTypes.object,
  purchaseOrderInfo: PropTypes.object,
  selectedIncomingTransfer: PropTypes.number,
  canGenerateAutomaticPO: PropTypes.bool,
  compatibleItemMasters: PropTypes.object,
  formValues: PropTypes.object,
  itemMasterMappings: PropTypes.array,
  isActiveFacilityLab: PropTypes.bool.isRequired,
  onAutoGeneratePurchaseOrderChange: PropTypes.func.isRequired,
  transferIsBeingLoaded: PropTypes.bool.isRequired
};

export default reduxForm({
  form: SUPPLY_CHAIN_MAPPING,
  validate,
})(PlatformSupplyChainMappingForm);
