import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { bindActionCreators } from 'redux';
import {push} from 'react-router-redux';
import get from 'lodash.get';
import {postItem, getItem} from '../../../actions/apiActions';
import {setItem} from '../../../actions/itemActions';
import * as itemNames from '../../../constants/itemNames';
import {getSalesLimitError} from '../../../selectors/salesSettingsSelectors';
import InProgressOverlay from '../../common/InProgressOverlay';

export class OrderAndQueueActions extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {showInProgress: false, inProgressMessage: '', orderActionsData: false};

    this.goToCartPage = this.goToCartPage.bind(this);
    this.goToPaymentPage = this.goToPaymentPage.bind(this);
    this.goToCheckIn = this.goToCheckIn.bind(this);
    this.goToProductsMenu = this.goToProductsMenu.bind(this);
    this.goToReceiptPage = this.goToReceiptPage.bind(this);
    this.cancelOrderAndReload = this.cancelOrderAndReload.bind(this);

    this.hideInProgress = this.hideInProgress.bind(this);
  }

  componentWillReceiveProps(nextProps){

    if(nextProps.orderActionsData && JSON.stringify(nextProps.orderActionsData) !== JSON.stringify(this.props.orderActionsData)){
      if(this.getCustomerId() === nextProps.orderActionsData.consumer_id) {
        this.setState({orderActionsData: nextProps.orderActionsData}, () => {
          this[nextProps.orderActionsData.method]();
        });
      }
    }
  }

  // Customer calls
  getCustomerId(){
    return (typeof this.props.customer === 'object')
      ? this.props.customer.consumer_id !== undefined ? this.props.customer.consumer_id : this.props.customer.id
      : this.props.customer;
  }

  getCustomer(){
    const customerId = this.getCustomerId();
    if(!customerId){ return Promise.resolve(); }
    return new Promise((resolve, reject) => {
      const messages = {failed: I18n.t('customers.get.failed')};
      this.props.actions.getItem(`/api/customers/${customerId}`, itemNames.customer, messages, undefined, (data) => {
        this.getCustomerStats(customerId);
        this.getCustomerHistory(customerId);
        resolve(data);
      }).then(() => {}).catch((e) => reject(e));
    });

  }

  getCustomerStats(customerId){
    this.props.actions.getItem(`/api/consumer_orders/${customerId}/stats`, itemNames.customerStat);
  }

  getCustomerHistory(customerId){
    this.props.actions.getItem(`/api/consumer_orders/${customerId}/recent_history`, itemNames.customerRecentHistory);
  }

  // Order calls
  getOrderId(){
    return new Promise((resolve, reject) => {
      if(this.props.customer.order_id !== undefined && !isNaN(parseInt(this.props.customer.order_id))) {
        resolve(this.props.customer.order_id);
      } else {
        this.createOrder()
          .then((order) => {
            resolve(order.id);
          })
          .catch((e) => {
            reject(e);
          });
      }
    });

  }

  createOrder(){
    const customerId = this.getCustomerId();
    const caregiverId = get(this.props,'customer.caregiver_id',null);

    return new Promise((resolve, reject) => {
      const messages = {success: 'orders.create.success', failed: null, failedHandler: getSalesLimitError};
      const payload = (this.state.orderActionsData) ? this.state.orderActionsData : {consumer_id: customerId};
      if(caregiverId) payload.caregiver_id = caregiverId;
      this.props.actions.postItem('/api/orders', payload, itemNames.order, messages, {}, (order) => {
        resolve(order);
      })
        .then(() => {})
        .catch((e) => {
          reject(e);
        });
    });
  }

  cancelOrder(){
    const customer = Object.assign({}, this.props.customer);
    if(isNaN(parseInt(customer.order_id))) return false;
    this.props.actions.postItem(`/api/orders/${customer.order_id}/cancel_order`,
      {},
      itemNames.order,
      {success:'orders.delete.success', failed:'orders.removed.failed'},
      {},
      () => {
        this.cancelQueue();
      }
    );
  }

  cancelQueue(){
    const customer = Object.assign({}, this.props.customer);
    if(isNaN(parseInt(customer.queue_id))) return false;
    this.props.actions.deleteItem(`api/queue/customers/${customer.id}`,
      null,
      {success:'orders.delete.customerSuccess', failed:'orders.removed.customerFailed'},
      {},
      () => {
        // Not sure yet
      }
    );
  }

  customerHasOrder(){
    return (!isNaN(parseInt(this.props.customer.order_id)));
  }

  // I don't think this and related functions are actually in use
  cancelOrderAndReload(){
    if(this.customerHasOrder()) return this.cancelOrder();
    return this.cancelQueue();
  }

  // Redirects
  goToCheckIn(){ // modify order
    this.setState({showInProgress: true, inProgressMessage: 'Going To Patient Check In Page'});
    this.goToPage('checkin');
  }

  goToProductsMenu(){ // modify order
    this.setState({showInProgress: true, inProgressMessage: 'Going To Products Page'});
    this.goToPage('products');
  }

  goToCartPage(){ // fast track
    this.setState({showInProgress: true, inProgressMessage: 'Going To Cart Page'});
    this.goToPage('cart');
  }

  goToPaymentPage(){
    this.setState({showInProgress: true, inProgressMessage: 'Going To Payments Page'});
    this.goToPage('payment');
  }

  goToReceiptPage(){
    this.setState({showInProgress: true, inProgressMessage: 'Going To Receipts Page'});
    this.goToPage('receipt');
  }

  goToPage(page){
    this.props.actions.setItem({},itemNames.order);
    this.props.actions.setItem({},itemNames.customer);
    this.getOrderId()
      .then((orderId) => {
        const pages = {
          checkin: '/check-in',
          products: `/product-menu/${orderId}`,
          cart: '/cart',
          payment: `/orders/${orderId}/payment`,
          receipt: `/orders/${orderId}/payment`
        };
        const order = this.props.customer.order && orderId === this.props.customer.order.id
          ? this.props.customer.order
          : {id: orderId};

        this.props.actions.setItem(order, itemNames.order)
          .then(() => {
            this.getCustomer().then(() => {
              this.props.actions.push(pages[page]);
            });
          });
      })
      .catch((e) => {
        this.hideInProgress();
      });
  }

  goToQueue(){

  }

  hideInProgress(){
    this.setState({showInProgress: false});
  }

  render(){

    const defaultProps = { // Defaults should not collide
      goToCartPage: this.goToCartPage,
      goToCheckIn: this.goToCheckIn,
      goToProductsMenu: this.goToProductsMenu,
      goToPaymentPage: this.goToPaymentPage,
      goToReceiptPage: this.goToReceiptPage,
      cancelOrderAndReload: this.cancelOrderAndReload
    };
    return(<div>
      <InProgressOverlay onDismiss={this.hideInProgress} message={this.state.inProgressMessage} isActive={this.state.showInProgress} translate={false} />
      {
        React.Children.map(this.props.children,
          (child) => {
            const mapProps = { // Map into children if not already present to allow over-riding
              fastTrack: this.goToCartPage,
              fillOrder: this.goToCartPage,
              editOrder: this.goToProductsMenu,
              modifyOrder: this.goToProductsMenu,
              startOrder: this.goToProductsMenu,
              checkout: this.goToPaymentPage,
              customer: this.props.customer,
            };
            const props = Object.assign({}, defaultProps);
            for(const prop in mapProps){
              if(child.props[prop] !== undefined && child.props[prop] !== 'inherit') continue;
              props[prop] = mapProps[prop];
            }
            return React.cloneElement(child, props);
          })
      }
    </div>);
  }
}

OrderAndQueueActions.propTypes = {
  customer: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ])
};


function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, {setItem}, {push}, {postItem, getItem});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(null, mapDispatchToProps) (OrderAndQueueActions);
