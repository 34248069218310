import * as apiActions from '../apiActions';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import {unsetItem} from '../itemActions';

/**
 * Return
 * @param id
 * @param params
 * @returns {function(*): *}
 */
export const fetchIsolocityTestResult = (id, params = {}, messages = null) => (dispatch) => {
  return dispatch(apiActions.getItem(`/api/isolocity/lab_results/${id}`, itemNames.isolocityLabResult, messages, params));
};

/**
 * Return a list of the isolocity lab results
 * @param params
 * @returns {function(*): *}
 */
export const fetchIsolocityTestResults = (params = {}) => (dispatch) => {
  return dispatch(apiActions.getData(`/api/isolocity/lab_results`, dataNames.isolocityLabResults, null, params));
};

export const clearIsolocityTestResult = () => (dispatch) => (
    dispatch(unsetItem(itemNames.isolocityLabResult)),
    dispatch(unsetItem(itemNames.testResult)),
    dispatch(unsetItem(itemNames.testResultMapping))
);
