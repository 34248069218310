import {createSelector} from 'reselect';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';

const getItemMasters  = (state) => state[dataNames.itemMasters] ? state[dataNames.itemMasters] : [];
const getPartnerProducts  = (state) => state[dataNames.connectsPartnerItemMasters] ? state[dataNames.connectsPartnerItemMasters] : [];
const getItemMasterMappings = (state) => state[itemNames.connectsPartnerItemMasterMappings] ? state[itemNames.connectsPartnerItemMasterMappings].mappings : [];
const getPartner  = (state) => state[itemNames.partner] ? state[itemNames.partner] : [];

/**
 * Pull together initial form values
 * @type {Reselect.Selector<any, {itemMasters: any, partnerProducts: *, connect_inherit_item_masters: null}> | Reselect.Selector<any, any>}
 */
export const getInitialValues = createSelector(
  [getItemMasters, getPartnerProducts, getItemMasterMappings, getPartner],
  (itemMasters, partnerProducts, itemMasterMappings, partner) => {

    //Do we already have it linked
    const basePartnerProducts = partnerProducts.map((partnerProduct) => {

      const mapping = (itemMasterMappings) ? itemMasterMappings.find(obj => obj.partner_item_number === partnerProduct.item_number) : null;
      if (mapping)  partnerProduct = Object.assign({}, partnerProduct, {item_master_id: mapping.item_master_id, link_item_master: mapping.link_item_master});

      return partnerProduct;
    });

    return {
      itemMasters: itemMasters,
      partnerProducts: basePartnerProducts,
      connect_inherit_item_masters: (partner) ? partner.connect_inherit_item_masters : null
    };

  });



export const buildSubcategoryOptions = (categories, externalMappings) => {
  const result = [];
  categories.forEach(category => {
    result.push({
      value: null,
      disabled: true,
      text: category.name
    });

    category.subcategories.forEach(subcategory => {
      //If it has an external name, display it as part of the name
      const external_value = externalMappings.find((mapping) => mapping.subcategory_id === subcategory.id);
      const external_name = (external_value) ? (' (' + external_value.name + ')') : '';

      result.push({
        value: subcategory.id,
        text: `   - ` + subcategory.name + external_name
      });
    });
  });
  return result;
};
