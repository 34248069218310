import {
  FORM_REGISTER_AS_OPEN,
  FORM_DISMISS_DIRTY,
  FORM_KEEP_DIRTY,
  FORM_WARN_PENDING_CHANGES,
  FORM_UNREGISTER_AS_OPEN,
  FORM_IS_SUBMITTING,
  FORM_SUBMIT_COMPLETE
} from '../../constants/actionTypes';

export const registerFormAsOpen = (formName, path) => ({
  type: FORM_REGISTER_AS_OPEN,
  payload: {
    formName,
    path
  }
});

export const unregisterFormAsOpen = (formName, path) => ({
  type: FORM_UNREGISTER_AS_OPEN,
  payload: {
    formName,
    path
  }
});

export const warnPendingFormChanges = (formName, path, nextPath) => ({
  type: FORM_WARN_PENDING_CHANGES,
  payload: {
    formName,
    path,
    nextPath,
  }
});

export const dismissOpenForm = (formName, path) => ({
  type: FORM_DISMISS_DIRTY,
  payload: {
    formName,
    path
  }
});

export const keepOpenForm = (formName, path) => ({
  type: FORM_KEEP_DIRTY,
  payload: {
    formName,
    path
  }
});

export const startSubmittingForm = (formName, path) => ({
  type: FORM_IS_SUBMITTING,
  payload: {
    formName,
    path
  }
});

export const stopSubmittingForm = (formName, path) => ({
  type: FORM_SUBMIT_COMPLETE,
  payload: {
    formName,
    path
  }
});
