//import {I18n} from 'react-redux-i18n';
import {requiredFieldValidation} from '../../../common/form/redux-form/validations';

const validate = (values, props) => {
  const errors = {};

  const fields = ['tax_profile_name', 'customer_types', 'states', 'facilities'];

  fields.forEach((field) => {
    const value = Array.isArray(values[field]) ? values[field].length === 0 ? undefined : values[field] : values[field];
    errors[field] = requiredFieldValidation(value, `taxProfiles.form.${field}`);
  });

  if(!errors['tax_profile_name']){ // Check unique if not already in error
    const matchedName = props.taxProfiles.filter((profile) => profile.tax_profile_name === values.tax_profile_name);
    if(matchedName.length > 0 && (matchedName.length > 1 || matchedName[0].id !== values.id)) errors.tax_profile_name = 'Must be unique.  This name is in use.';
  }

  return errors;
};

export default validate;
