import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';
import {requiredFieldValidation} from '../../common/form/redux-form/validations';

const validate = (values, props) => {
  const selectedIntegrator = values.selectedIntegrator && values.selectedIntegrator.value;

  const errors = {
    selectedIntegrator: {
      value: requiredFieldValidation(selectedIntegrator),
    },
    integrators: values.integrators.map((integrator) => {
      //validating only the active integrator
      if (integrator.integrator === selectedIntegrator) {
        return {
          value: validateIntegratorValue(integrator.value ? integrator.value : {}, integrator.integrator, props),
        };
      }
      return {};
    }),
  };

  if (get(values, 'sync.enabled', false) === true) {
    const modules = get(values, 'sync.selected_modules', {});

    if (Object.keys(modules).length === 0) {
      errors.sync = {
        enabled: I18n.t('stateIntegrators.form.oneSyncModuleRequired')
      };
    }
  }

  return errors;
};

function validateIntegratorValue(values, integratorType, props) {
  switch (integratorType) {
  case 'leaf':
    return {
      //api_key: requiredFieldValidation(values.api_key),
      //mme_code: requiredFieldValidation(values.mme_code),
      //is_initial_loading: requiredFieldValidation(values.is_initial_loading),
      //state: requiredFieldValidation(values.state),
    };
  case 'metrc':
    return {
      user_api_key: requiredFieldValidation(values.user_api_key),
      license_number: requiredFieldValidation(values.license_number),
      state: requiredFieldValidation(values.state)
    };
  case 'cureapi':
    return {
      subscriber_id: requiredFieldValidation(values.subscriber_id ? values.subscriber_id : null),
      dispenser_id: requiredFieldValidation(values.dispenser_id ? values.dispenser_id : null),
    };
  case 'biotrack':
    return {
      username: requiredFieldValidation(values.username),
      password: requiredFieldValidation(values.password),
      ubi: requiredFieldValidation(values.ubi),
      state: requiredFieldValidation(values.state),
      location_number: requiredFieldValidation(values.location_number),
      quarantine_location: requiredFieldValidation(values.quarantine_location),
      require_patient_card_lookup: requiredFieldValidation(values.require_patient_card_lookup),
      is_training_mode: requiredFieldValidation(values.is_training_mode),
      url: requiredFieldValidation(!values.override_environment_url || values.url),
    };
  case 'apisicpa':
    return {
      username: requiredFieldValidation(values.username),
      password: requiredFieldValidation(values.password),
      client_id: requiredFieldValidation(values.client_id),
      client_secret: requiredFieldValidation(values.client_secret),
      license_site_id: requiredFieldValidation(values.license_site_id),
      state: requiredFieldValidation(values.state),
      url: requiredFieldValidation(!values.override_environment_url || values.url),
    };
  case 'utah':
    return {
      client_id: requiredFieldValidation(values.client_id),
      client_secret: requiredFieldValidation(values.client_secret),
      source_system: requiredFieldValidation(values.source_system),
      account_name: requiredFieldValidation(values.account_name),
      facility: requiredFieldValidation(values.facility),
    };
  default:
    return {};
  }
}

export default validate;
