import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import {Col, Row, Button, FormGroup} from 'react-bootstrap';
import { Field} from 'redux-form';
import get from 'lodash.get';

import TextInput from '../../common/form/TextInput';
import NumericInput from '../../common/form/NumericInput';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import CheckBoxInput from '../../common/form/CheckBoxInput';

export class ComplianceLimitsBlock extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {
      compliance_limits: {
        facility_possession_limit: false,
        facility_sales_limit: false,
        facility_plants_veg_limit: false,
        facility_plants_flower_limit: false,
        facility_plants_total_limit: false,
      }
    };
    this.changeComplianceLimitsFieldStatus = this.changeComplianceLimitsFieldStatus.bind(this);
  }

  changeComplianceLimitsFieldStatus(fieldName){
    const {compliance_limits} = this.state;
    compliance_limits[fieldName] = !compliance_limits[fieldName];
    this.setState(compliance_limits);
    this.props.change(`limitsInfo.${fieldName}`, this.props.complianceLimits[fieldName]);
  }

  render(){
    const {complianceLimits} = this.props;
    const {compliance_limits} = this.state;

    return(
      <FormGroup>
        <Col md={6}>
          <div className='inventory-limits-inner-wrapper'>
            {complianceLimits.facility_possession_limit ?
            <Row className='row-hack'>
              {
                !compliance_limits.facility_possession_limit ?
                  <Col md={12}>
                    <span>{I18n.t('customers.create.facilityPosses', {value: (complianceLimits && complianceLimits.facility_possession_limit)})}</span>
                    <Button variant={'link'} onClick={() => this.changeComplianceLimitsFieldStatus('facility_possession_limit')}>{I18n.t('customers.create.override')}</Button>
                  </Col> :
                  <div>
                    <Col md={4}>
                      <Field
                        name='limitsInfo.facility_possession_limit'
                        component={NumericInput}
                        props={{
                          label: I18n.t('customers.create.facilityPosses', {value: ''}),
                        }}
                      />
                    </Col>
                    <Col md={2}>
                      <Button variant={'link'} onClick={() => this.changeComplianceLimitsFieldStatus('facility_possession_limit')}>{I18n.t('customers.create.doNotOverride')}</Button>
                    </Col>
                  </div>
              }
            </Row> : null}
            {complianceLimits.facility_sales_limit ?
            <Row className='row-hack'>
              {
                complianceLimits.default_values && complianceLimits.default_values.facility_sales_limit
                  ? (<Col md={12} className='text-muted' style={{fontSize: 'smaller'}}>
                    {I18n.t('customers.create.facilitySalesLimit', {grams: complianceLimits.default_values.facility_sales_limit})}
                  </Col>)
                  : null
              }

              {
                !compliance_limits.facility_sales_limit ?
                  <Col md={12}>
                    <Row>
                    <Col md={6}>{I18n.t('customers.create.facilitySell', {value: get(complianceLimits, 'facility_sales_limit')})}</Col>
                    <Col md={6}><Button variant={'primary'} onClick={() => this.changeComplianceLimitsFieldStatus('facility_sales_limit')}>{I18n.t('customers.create.override')}</Button></Col>
                    </Row>
                  </Col> :
                  <Col md={12}>
                    <Row>
                      <Col md={6}>
                        <Field
                          name='limitsInfo.facility_sales_limit'
                          component={NumericInput}
                          rightAddon={<span>g</span>}
                          props={{
                            label: I18n.t('customers.create.overrideValue'),
                            fractionPartSize: 3,
                          }}
                        /></Col>
                        <Col md={6}><Button variant={'primary'} onClick={() => this.changeComplianceLimitsFieldStatus('facility_sales_limit')}>{I18n.t('customers.create.doNotOverride')}</Button></Col>
                    </Row>
                  </Col>
              }
            </Row> : null}
            {complianceLimits.facility_plants_veg_limit ?
            <Row className='row-hack'>
              {
                !compliance_limits.facility_plants_veg_limit ?
                  <Col md={12}>
                    <span>{I18n.t('customers.create.coupledFacilitiesPlantsVeg', {value: complianceLimits && (complianceLimits.facility_plants_veg_limit)})}</span>
                    <Button variant={'link'} onClick={() => this.changeComplianceLimitsFieldStatus('facility_plants_veg_limit')}>{I18n.t('customers.create.override')}</Button>
                  </Col> :
                  <div>
                    <Col md={6}>
                      <Field
                        name='limitsInfo.facility_plants_veg_limit'
                        component={NumericInput}
                        props={{
                          label: I18n.t('customers.create.coupledFacilitiesPlantsVeg', {value: ''})
                        }}
                      />
                    </Col>
                    <Col md={2}>
                      <Button variant={'link'} onClick={() => this.changeComplianceLimitsFieldStatus('facility_plants_veg_limit')}>{I18n.t('customers.create.doNotOverride')}</Button>
                    </Col>
                  </div>
              }
            </Row> : null}
            {complianceLimits.facility_plants_flower_limit ?
            <Row className='row-hack'>
              {
                !compliance_limits.facility_plants_flower_limit ?
                  <Col md={12}>
                    <span>{I18n.t('customers.create.coupledFacilitiesPlantsFlower', {value: complianceLimits && (complianceLimits.facility_plants_flower_limit)})}</span>
                    <Button variant={'link'} onClick={() => this.changeComplianceLimitsFieldStatus('facility_plants_flower_limit')}>{I18n.t('customers.create.override')}</Button>
                  </Col> :
                  <div>
                    <Col md={7}>
                      <Field
                        name='limitsInfo.facility_plants_flower_limit'
                        component={NumericInput}
                        props={{
                          label: I18n.t('customers.create.coupledFacilitiesPlantsFlower', {value: ''})
                        }}
                      />
                    </Col>
                    <Col md={2}>
                      <Button variant={'link'} onClick={() => this.changeComplianceLimitsFieldStatus('facility_plants_flower_limit')}>{I18n.t('customers.create.doNotOverride')}</Button>
                    </Col>
                  </div>
              }
            </Row> : null}
            {complianceLimits.facility_plants_total_limit ?
            <Row className='row-hack'>
              {
                !compliance_limits.facility_plants_total_limit ?
                  <Col md={12}>
                    <span>{I18n.t('customers.create.coupledFacilitiesTotalPlants', {value: complianceLimits && (complianceLimits.facility_plants_total_limit)})}</span>
                    <Button variant={'link'} onClick={() => this.changeComplianceLimitsFieldStatus('facility_plants_total_limit')}>{I18n.t('customers.create.override')}</Button>
                  </Col> :
                  <div>
                    <Col md={6}>
                      <Field
                        name='limitsInfo.facility_plants_total_limit'
                        component={NumericInput}
                        props={{
                          label: I18n.t('customers.create.coupledFacilitiesTotalPlants', {value: ''})
                        }}
                      />
                    </Col>
                    <Col md={2}>
                      <Button variant={'link'} onClick={() => this.changeComplianceLimitsFieldStatus('facility_plants_total_limit')}>{I18n.t('customers.create.doNotOverride')}</Button>
                    </Col>
                  </div>
              }
            </Row> : null}
            {complianceLimits.facility_order_total_limit && complianceLimits.facility_order_total_limit_period ?
            <Row className='row-hack-two-fields'>
              {
                  <Col md={12}>
                    <span>{I18n.t('customers.create.limitOrderRow',
                      {
                        number: complianceLimits && (complianceLimits.facility_order_total_limit),
                        period: complianceLimits && (I18n.t(`retail.restrictSalesTimePeriods.${complianceLimits.facility_order_total_limit_period}`))
                      }
                    )}
                    </span>
                  </Col>
              }
            </Row> : null}
          </div>
        </Col>
        <Col md={6} style={{display: 'none'}}>
          <div className='form-group-border'></div>
          <div className='float-right use-profile-email'>
            <Field name='useProfileEmail' component={CheckBoxInput} props={{
              label: I18n.t('customers.create.useProfileEmail')
            }} />
          </div>
          <h3>{I18n.t('customers.create.onlineOrdering')}</h3>
          <Row>
            <Col md={6}>
              <Field name='onlineOrderingEmail' component={TextInput} props={{
                label: I18n.t('customers.create.email'),
                placeholder: I18n.t('customers.placeholders.email')
              }} />
            </Col>
            <Col md={6}>
              <Field name='onlineOrderingPassword' component={TextInput} props={{
                label: I18n.t('customers.create.password'),
                placeholder: I18n.t('customers.placeholders.password')
              }} />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Field name='onlineOrderStatus' component={ReactSelectInput} props={{
                label: I18n.t('customers.create.onlineOrderStatus'),
                options: [],
                placeholder: I18n.t('common.form.select')
              }} />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Button variant='primary' className='email-login-info-button'>
                {I18n.t('customers.create.emailLoginInformation')}
              </Button>
            </Col>
          </Row>
        </Col>
      </FormGroup>
    );
  }
}

ComplianceLimitsBlock.propTypes = {
  complianceLimits: PropTypes.object,
  change: PropTypes.func.isRequired
};

export default ComplianceLimitsBlock;
