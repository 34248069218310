import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import {getItem, postItem} from '../../../actions/apiActions';
import {distributionSettings} from '../../../constants/itemNames';
import {getInitialValues, getPayload} from '../../../selectors/forms/homeDeliverySetupFormSelectors';
import {getFacility, isActiveFacilityCourierService, isActiveFacilityDispensary} from '../../../selectors/facilitiesSelectors';
import InProgressOverlay from '../../common/InProgressOverlay';
import DeliveryOrderSetupFormWrapper from './DeliveryOrderSetupFormWrapper';

export class DeliveryOrderSetupPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);

    this.getPageTitle = this.getPageTitle.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getDistributionSettings = this.getDistributionSettings.bind(this);

    this.state = {
      saving: false,
    };
  }

  componentWillMount(){
    this.getDistributionSettings();
  }

  onSubmit(formData, dispatch) {
    this.setState({
      saving: true,
    });

    const payload = getPayload(formData, this.props.facility);

    this.props.actions.postItem('/api/settings/values/update_many', payload)
      .then(() => this.getDistributionSettings())
      .then(() => this.setState({
        loading: false,
      }))
      .then(() => this.props.actions.goBack());
  }

  getDistributionSettings () {
    return this.props.actions.getItem('/api/distribution/settings', distributionSettings, undefined, {
      ids: ['distributions_home_delivery']
    });
  }

  getPageTitle () {
    const {isCourierService} = this.props;

    if (isCourierService) {
      return I18n.t('deliveryOrders.setup.courier.title');
    }

    return I18n.t('deliveryOrders.setup.retail.title');
  }

  render() {
    const {actions: {goBack}, isCourierService, isDispensary, initialValues} = this.props;

    return (
      <div>
        <InProgressOverlay
          isActive={this.state.saving}
          message={I18n.t('common.form.saving')}
        />

        <DeliveryOrderSetupFormWrapper
          goBack={goBack}
          title={this.getPageTitle()}
          isCourierService={isCourierService}
          isDispensary={isDispensary}
          initialValues={initialValues}
          onSubmit={this.onSubmit}
        />
      </div>
    );
  }
}

DeliveryOrderSetupPage.propTypes = {
  actions: PropTypes.object.isRequired,
  isCourierService: PropTypes.bool.isRequired,
  isDispensary: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  facility: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    isDispensary: isActiveFacilityDispensary(state),
    isCourierService: isActiveFacilityCourierService(state),
    initialValues: getInitialValues(state),
    facility: getFacility(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, {
    goBack,
    getItem,
    postItem,
  });

  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (DeliveryOrderSetupPage);
