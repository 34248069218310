import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import InternationalDisplayQty from '../../../../common/InternationalDisplayQty';

const MetrcMiLimitsRow = ({areConsumerLimitsLoading, getDisplayRulesPropertySum, consumer, getStyle, customerLimits}) => (
    <tfoot>
    <tr>
      <th colSpan='2'>{I18n.t('cart.limitTable.limits')}</th>
      <th colSpan='2'></th>
    </tr>
    <tr>
      <td colSpan='2'>
        {I18n.t('cart.limitTable.metrcMiLimitPeriod')}:
        <span style={{float: 'right'}}>
          {areConsumerLimitsLoading() ? '...' :
            <InternationalDisplayQty qty={get(customerLimits, 'limits.total_weight_integrator_restriction', 0)} uom='GR' displayUom={true}/>
          }
          /
          {areConsumerLimitsLoading() ? '' :
            <InternationalDisplayQty qty={get(customerLimits, 'limits.total_weight_restriction', 0)} uom='GR' displayUom={true}/>
          }
        </span>
      </td>
      <td style={getStyle()}>
        {areConsumerLimitsLoading()
          ? '...'
          : <InternationalDisplayQty qty={getDisplayRulesPropertySum('previous_orders', true)} uom='GR' displayUom={true}/>
        }
      </td>
      {
        consumer
          ? null
          : <td style={getStyle()}><InternationalDisplayQty qty={getDisplayRulesPropertySum('order', true)} uom='GR' displayUom={true}/></td>
      }
    </tr>
    </tfoot>

);

MetrcMiLimitsRow.propTypes = {
  areConsumerLimitsLoading: PropTypes.func.isRequired,
  getDisplayRulesPropertySum: PropTypes.func.isRequired,
  consumer: PropTypes.object,
  getStyle: PropTypes.func.isRequired,
  customerLimits: PropTypes.object.isRequired
};

export default MetrcMiLimitsRow;