import PropTypes from 'prop-types';
import {reduxForm, formValueSelector} from 'redux-form';
import {connect} from 'react-redux';
import BigNumber from 'bignumber.js';
import {ReceiveTrackingIdsForm} from './ReceiveTrackingIdsForm';
import {validate} from './validate';

const selector = formValueSelector('ReceiveTrackingIdsForm');

export const wrapper =  reduxForm({
  validate
})(ReceiveTrackingIdsForm);

const createEndingId = (state) => {
  const tailPartRegExp = /\d+$/;
  const initialQuantityReceived = selector(state, 'qty_received') || '';
  const beginningId =  selector(state, 'starting_tag') || '';
  const quantityReceived = (+initialQuantityReceived).toString();//case when quantity starts with 0
  let endingTagOffset = parseInt(quantityReceived) || 0;
  if (endingTagOffset) {
    endingTagOffset--;
  }
  endingTagOffset = endingTagOffset.toString();

  const tailPart = tailPartRegExp.exec(beginningId);
  let endingId = '', newTailPart = '';

  if(tailPart) {
    if(endingTagOffset.length < tailPart[0].length && (endingTagOffset + 1).length < endingTagOffset.length) {
      const firstPieceOfTailPart = tailPart[0].slice(0, -endingTagOffset.length);
      const lastPieceOfTailPart = (+(tailPart[0].slice(-endingTagOffset.length)) + (+endingTagOffset)).toString();
      //In cases when beginningId ends with 0001 and quantity is 10 => endingId should end with 0010
      newTailPart =  firstPieceOfTailPart + lastPieceOfTailPart;
    } else {
      newTailPart = (new BigNumber(tailPart[0])).plus(endingTagOffset).toFixed();
      const lengthDifference = Math.abs(newTailPart.length - tailPart[0].length);
      if(lengthDifference) newTailPart = '0'.repeat(lengthDifference) + newTailPart;
    }
    endingId = beginningId.replace(tailPartRegExp, newTailPart);
  } else {
    endingId = '';
  }
  return {
    endingId
  };
};

function mapStateToProps(state) {
  const form = 'ReceiveTrackingIdsForm';
  return {
    form,
    generatedEndingId: createEndingId(state),
  };
}

export const ReceiveTrackingIdWrapper = connect(mapStateToProps)(wrapper);

ReceiveTrackingIdWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default ReceiveTrackingIdWrapper;
