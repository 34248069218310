import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import get from 'lodash.get';
import AccordionPanel from '../../../common/AccordionPanel';
import TextAreaInput from '../../../common/form/TextAreaInput';


const InfoPanel = ({formValues}) => {
  const required_fields = get(formValues, 'required_fields');
  const ingredientsRequired = Boolean(required_fields && required_fields.requires_ingredients);

  return (
    <AccordionPanel title='products.form.ingredientsAllergensAndNutritionalInfo' className='ingredients-panel'>
      <Row className='content'>
        <Col md={4} sm={12}>
          <Field name='ingredients' component={TextAreaInput} props={{
            label: I18n.t('products.form.ingredients'),
            placeholder: I18n.t('products.form.ingredientsPlaceholder'),
            rows: 5,
            isRequired: ingredientsRequired
          }}/>
        </Col>
        <Col md={4} sm={12}>
          <Field name='nutritional_info' component={TextAreaInput} props={{
            label: I18n.t('products.form.nutritional'),
            placeholder: I18n.t('products.form.nutritionalPlaceholder'),
            rows: 5
          }}/>
        </Col>
        <Col md={4} sm={12}>
          <Field name='allergens' component={TextAreaInput} props={{
            label: I18n.t('products.form.allergens'),
            placeholder: I18n.t('products.form.allergensPlaceholder'),
            rows: 5
          }}/>
        </Col>
        <Col md={4} sm={12}>
          <Field name='solvent' component={TextAreaInput} props={{
            label: I18n.t('products.form.solvent'),
            placeholder: I18n.t('products.form.solventPlaceholder'),
            rows: 5
          }}/>
        </Col>
        <Col md={4} sm={12}>
          <Field name='other' component={TextAreaInput} props={{
            label: I18n.t('products.form.other'),
            placeholder: I18n.t('products.form.otherPlaceholder'),
            rows: 5
          }}/>
        </Col>
      </Row>
    </AccordionPanel>
  );
};

InfoPanel.propTypes = {
  formValues: PropTypes.object,
};

export default InfoPanel;
