import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Button, Col, Row} from 'react-bootstrap';
import {FaCopy} from 'react-icons/fa';
import get from 'lodash.get';
import InternationalCurrencyInput from '../../../common/form/InternationalCurrencyInput';
import {info} from '../../../../constants/messageTypes';

const MmapPaymentForm = ({invalid, submitting, pristine, handleSubmit, order, payments, customer, addMessage, readOnly}) => {
  const submitDisabled = invalid || pristine || submitting;

  const reference = get(order, 'id');
  const patientId = parseInt(get(customer, 'state_integration_tracking_id'), 10);

  const copyToClipboard = (text) => {
    const tempInput = document.createElement('input');
    tempInput.value = text;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    tempInput.remove();
    return true;
  };

  const copyReferenceToClipboard = () => {
    copyToClipboard(reference);
    addMessage(info, ['cart.mmapPayment.copiedReference']);
  };

  const copyPatientIdToClipboard = () => {
    copyToClipboard(patientId);
    addMessage(info, ['cart.mmapPayment.copiedPatientId']);
  };

  const amount = payments.reduce(
    (sum, payment) => sum + parseFloat(payment.amount),
    0,
  );
  const mmap_payment_amount_props = {
    label: I18n.t('cart.discount.dollarAmount'),
    disabled: readOnly,
    onChange: (value) => {
      checkBalance(value);
    }
  };
  if (payments && amount) {
    mmap_payment_amount_props.value = amount;
  }
  const balance_due = order.balance_due;
  const [showWarning, setShowWarning] = useState(false);
  const checkBalance = (value) => {
    if(value >= balance_due) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  };


  return (
    <Col xs={12}>
      <h4>{I18n.t('cart.mmapPayment.title')}</h4>
      <Row>
        <Col xs={12} md={6}>
          <InternationalCurrencyInput
            name='amount'
            props={mmap_payment_amount_props}/>
        </Col>
        <Col xs={12} md={6}>
          <div style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid #dee2e6', paddingBottom: '5px'  }}>
            <div style={{ flex: '1' }}>
              <strong>{I18n.t('cart.mmapPayment.reference')}: {reference}</strong>
            </div>
            <Button
              title={`${I18n.t('cart.mmapPayment.copyToClipboard')}`}
              style={{marginLeft: '5px'}}
              onClick={copyReferenceToClipboard}>
              <FaCopy /> {I18n.t('cart.mmapPayment.copy')}
            </Button>
          </div>
          <br/>
          <div style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid #dee2e6', paddingBottom: '5px'  }}>
            <div style={{ flex: '1' }}>
              <strong>{I18n.t('cart.mmapPayment.patientId')}: {patientId}</strong>
            </div>
            <Button
              title={`${I18n.t('cart.mmapPayment.copyToClipboard')}`}
              style={{marginLeft: '5px'}}
              onClick={copyPatientIdToClipboard}>
              <FaCopy /> {I18n.t('cart.mmapPayment.copy')}
            </Button>
          </div>
          <br/>
        </Col>
        {showWarning ? 
          <Col xs={12}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', color: 'red' }}>
            <span style={{paddingRight: '10px'}}>{I18n.t('cart.payment.mmapOverTotal')}</span>
              <Button variant='primary' onClick={handleSubmit} disabled={readOnly || submitDisabled}>
              {I18n.t('cart.payment.addPayment')}
              </Button>
            </div>
          </Col> : 
          <Col xs={12}>
            <Button variant='primary' className='float-right' onClick={handleSubmit} disabled={readOnly || submitDisabled}>
              {I18n.t('cart.payment.addPayment')}
            </Button>
          </Col>}
      </Row>
    </Col>
  );
};

MmapPaymentForm.propTypes = {
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  payments: PropTypes.object,
  addMessage: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired
};

export default MmapPaymentForm;
