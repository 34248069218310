import React from 'react';
import PropTypes from 'prop-types';
import BulkLine from './BulkLine';
import PrePackLine from './PrePackLine';
import UnitLine from './UnitLine';

const LineItem = ({line, index, change, fields, prepackWeights, itemMaster, fieldName, isApplyStorageLocation,
  isEditMode, locations, packages, sentTotal, lineTotal, isComplete, isCancelled, integrationState, transferId,
  disposalReasonOptions, inventoryLocked, formName, isMedicated, sentNetTotal, formData, itemMasterIsMedicated, internationalNumberFormat}) => {

  return (
    line.line_type === 'bulk' ?
      <BulkLine
        sentTotal={sentTotal}
        lineTotal={lineTotal}
        line={line}
        itemMaster={itemMaster}
        itemMasterIsMedicated={itemMasterIsMedicated}
        isApplyStorageLocation={isApplyStorageLocation}
        locations={locations}
        packages={packages}
        fieldName={fieldName}
        isEditMode={isEditMode}
        isComplete={isComplete}
        isMedicated={isMedicated}
        isCancelled={isCancelled}
        orderedQty={typeof fields.get === 'function' ? parseFloat(fields.get(index).ordered_qty) : 0}
        editableLinePrice={typeof fields.get === 'function' ? fields.get(index).editableLinePrice : undefined}
        change={change}
        integrationState={integrationState}
        transferId={transferId}
        inventoryLocked={inventoryLocked}
        disposalReasonOptions={disposalReasonOptions}
        formName={formName}
        formData={formData}
        internationalNumberFormat={internationalNumberFormat}
      /> :
    line.line_type === 'unit' ?
      <UnitLine
        sentTotal={sentTotal}
        sentNetTotal={sentNetTotal}
        lineTotal={lineTotal}
        change={change}
        itemMaster={itemMaster}
        itemMasterIsMedicated={itemMasterIsMedicated}
        isApplyStorageLocation={isApplyStorageLocation}
        isLotTracked={itemMaster.inventory_attributes ? itemMaster.inventory_attributes.lot_tracked : 0}
        locations={locations}
        packages={packages}
        fieldName={fieldName}
        orderedQty={typeof fields.get === 'function' ? parseFloat(fields.get(index).ordered_qty) : 0}
        isEditMode={isEditMode}
        isComplete={isComplete}
        isMedicated={isMedicated}
        isCancelled={isCancelled}
        integrationState={integrationState}
        transferId={transferId}
        inventoryLocked={inventoryLocked}
        formName={formName}
        line={line}
        formData={formData}
      /> :
    line.line_type === 'prepack' ?
      <PrePackLine
        sentTotal={sentTotal}
        lineTotal={lineTotal}
        unit_price={line.unit_price || 0}
        handlePricingChange={() => {}}
        change={change}
        itemMaster={itemMaster}
        isApplyStorageLocation={isApplyStorageLocation}
        locations={locations}
        packages={packages}
        fieldName={fieldName}
        isEditMode={isEditMode}
        isComplete={isComplete}
        orderedQty={typeof fields.get === 'function' ? parseFloat(fields.get(index).ordered_qty) : 0}
        editableLinePrice={typeof fields.get === 'function' ? fields.get(index).editableLinePrice : undefined}
        formName={formName}
        integrationState={integrationState}
        line={line}
        formData={formData}
        isMedicated={isMedicated}
        internationalNumberFormat={internationalNumberFormat}
      /> : null
      );
};

LineItem.propTypes = {
  index: PropTypes.number.isRequired,
  fields: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  itemMaster: PropTypes.object.isRequired,
  itemMasterIsMedicated: PropTypes.bool,
  prepackWeights: PropTypes.array.isRequired,
  sentTotal: PropTypes.number.isRequired,
  lineTotal: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  line: PropTypes.object.isRequired,
  integrationState: PropTypes.object.isRequired,
  packages: PropTypes.array,
  isApplyStorageLocation: PropTypes.bool,
  locations: PropTypes.array,
  isEditMode: PropTypes.bool,
  isComplete: PropTypes.bool.isRequired,
  isCancelled: PropTypes.bool,
  isMedicated: PropTypes.bool,
  inventoryLocked: PropTypes.bool,
  transferId: PropTypes.string,
  disposalReasonOptions: PropTypes.array.isRequired,
  formName: PropTypes.string.isRequired,
  formData: PropTypes.object.isRequired,
  sentNetTotal: PropTypes.number.isRequired,
  internationalNumberFormat: PropTypes.string
};

LineItem.defaultProps = {
  isEditMode: false
};

export default LineItem;
