import without from 'lodash.without';
import * as types from '../constants/actionTypes';
import initialState from '../constants/initialState';

export default function selectedPackagesReducer (state = initialState.selectedPackages, action){
  const {packages} = action;
  switch (action.type) {
  case types.ADD_SELECTED_PACKAGES:
    return [...state, ...packages];
  case types.REMOVE_SELECTED_PACKAGES:
    return without(state, ...packages);
  default:
    return state;
  }
}
