import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { push } from 'react-router-redux';
import { Button, Col, Row, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faReply, faSyncAlt } from '@fortawesome/free-solid-svg-icons';

import Loading from '../../common/loading';
import Status from '../../common/status';

import MerchantCompanyHeader from './MerchantCompanyHeader';

import { getCompany } from '../../../selectors/merchantConfigurationSelectors';

import MerchantTerminalsList from './MerchantTerminalsList';
import { AuthRequest } from '../../../managers/request';

class MerchantProfileManagePage extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: null,
      profile: null
    };

    this.load = this.load.bind(this);
    this.edit = this.edit.bind(this);
    this.manageCompany = this.manageCompany.bind(this);
  }

  componentWillMount() {
    this.load();
  }

  manageCompany() {
    this.props.push('/setup/merchant-configuration');
  }

  edit() {
    const { profile } = this.state;
    this.props.push(`/setup/merchant-profile/modify/${profile.id}`);
  }

  load() {
    // Retrieve the query parameters
    const { params: { id } } = this.props;

    this.setState({
      loading: true
    });

    this.findProfile(id).then((profile) => {
      this.setState({
        loading: false,
        profile: profile
      });
    });
  }

  findProfile(id) {
    const request = AuthRequest.create();
    return request.get('/api/payment/merchant_profiles/find?id=' + id).then((response) => {
      return response.data || null;
    });
  }

  render() {
    const { loading, profile } = this.state;

    return (
      <div>
        <h1>Merchant Profile&nbsp;
          {profile &&
            <span>
              <Button onClick={this.edit} className='rounded margin-right-xs' size='sm'variant='primary'><FontAwesomeIcon icon={faPencilAlt} /></Button>
              <Button onClick={this.load} className='rounded' size='sm' variant='primary'><FontAwesomeIcon icon={faSyncAlt} /></Button>
            </span>
          }
        </h1>
        {loading ?
          <div><Loading>{I18n.t('general.loading')}</Loading></div> :
          <div>
            <Row>
              <Col md={4}>
                <Card>
                  <Card.Body>
                    <Row>
                      <Col md={6}><strong>{I18n.t('common.form.name')}:</strong></Col>
                      <Col md={6}>{profile.name}</Col>
                    </Row>
                    <Row>
                      <Col md={6}><strong>{I18n.t('facility.setup.merchantProfile.form.accountNumber')}:</strong></Col>
                      <Col md={6}>{profile.account_number}</Col>
                    </Row>
                    <Row>
                      <Col md={6}><strong>{I18n.t('facility.setup.merchantProfile.form.username')}:</strong></Col>
                      <Col md={6}>{profile.username}</Col>
                    </Row>
                    <Row>
                      <Col md={6}><strong>{I18n.t('common.status')}:</strong></Col>
                      <Col md={6}><Status status={profile.status} /></Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <h4>{I18n.t('common.company')}&nbsp;
            <Button onClick={this.manageCompany} className='rounded margin-right-xs' size='sm' variant='primary'><FontAwesomeIcon icon={faReply} /></Button>
            </h4>
            <MerchantCompanyHeader />
          </div>
        }
        {!loading && profile &&
          <div>
            <ul className='nav nav-tabs' style={{ marginBottom: '10px' }}>
              <li className='active'>
                <a href='#'>{I18n.t('common.terminals')}</a>
              </li>
            </ul>
            <div className='tab-content'>
              <MerchantTerminalsList profile={profile} />
            </div>
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  company: getCompany(state),
  headers: state.headers
});

const mapDispatchToProps = ({
  push
});

export default connect(mapStateToProps, mapDispatchToProps)(MerchantProfileManagePage);
