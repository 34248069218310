import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Col} from 'react-bootstrap';
import {FaRegCheckSquare, FaRegSquare} from 'react-icons/fa';
import {getQzTray, signQzRequest, setSelectedPrinterByTag} from '../../../actions/printerActions';
import * as apiActions from '../../../actions/apiActions';
import {setItem} from '../../../actions/itemActions';
import {setData} from '../../../actions/dataActions';
import * as itemNames from '../../../constants/itemNames';
import * as dataNames from '../../../constants/dataNames';
import {getPrintServers, getPrinters, getLabelName, getPrinterKey, updatePrinter, setSelectedPrinter} from '../../../selectors/labelsSelectors';
import QZTray from '../qztray/QZTray'; //eslint-disable-line
import ModalWrapper from '../../common/ModalWrapper';

export class SetPrinterModal extends React.PureComponent{

  constructor (props, context){
    super(props, context);
    this.onHide = this.onHide.bind(this);
    this.onChangeResolution = this.onChangeResolution.bind(this);
    this.onSelectPrinter = this.onSelectPrinter.bind(this);
    this.onEditConfig = this.onEditConfig.bind(this);
    this.onSaveConfig = this.onSaveConfig.bind(this);
    this.state = {config: false, configString: ''};
  }

  onSelectPrinter(e, printer, server){
    e.target.blur();
    this.setState({config: false});
    setTimeout(() => {
      const printers = this.props.localPrinters.map((p) => {
        if(typeof p === 'string') p = {name: p, status: 1};
        p.selected = false;
        return p;
      });
      let targetPrinter = printers.find((p) => p.name === printer.name);
      if(!targetPrinter){
        targetPrinter = Object.assign({}, printer);
        printers.push(targetPrinter);
      } else {
        targetPrinter = Object.assign({}, targetPrinter, printer);
      }
      targetPrinter.selected = true;
      targetPrinter.remote = server.id >= 1;
      targetPrinter.server = {id: server.id, name: server.name, remote: server.id >= 1};
      this.props.actions.setData(printers, dataNames.localPrinters); // This happens organically when qz first connects, we help it here
      setSelectedPrinter(targetPrinter, this.props.printerKey); // Sets local storage
      this.props.actions.setSelectedPrinterByTag(this.props.labelTag, targetPrinter); // Sets redux
    },10);
  }

  onChangeResolution(e, resolution, printer){
    e.target.blur();
    setTimeout(() => {
      const printers = this.props.localPrinters.map((p) => {
        if(typeof p === 'string') return {name: p, status: 1};
        return p;
      });
      let targetPrinter = printers.find((p) => p.name === printer.name);
      if(!targetPrinter){
        targetPrinter = Object.assign({}, printer);
        printers.push(printer);
      } else {
        targetPrinter = Object.assign({}, targetPrinter, printer);
      }
      targetPrinter.resolution = resolution;
      targetPrinter.dpi = resolution;
      this.props.actions.setData(printers, dataNames.localPrinters);
      this.props.actions.setSelectedPrinterByTag(this.props.labelTag, targetPrinter); // Sets redux
      updatePrinter(targetPrinter);
    },10);
  }

  onChangeConfig(e){
    e.persist();
    this.setState({configString: e.target.value});
  }

  onSaveConfig(e, printer){
    e.target.blur();
    this.setState({config: false});
    setTimeout(() => {
      const printers = this.props.localPrinters.map((p) => {
        if(typeof p === 'string') return {name: p, status: 1};
        return p;
      });
      let targetPrinter = printers.find((p) => p.name === printer.name);
      if(!targetPrinter){
        targetPrinter = Object.assign({}, printer);
        printers.push(printer);
      } else {
        targetPrinter = Object.assign({}, targetPrinter, printer);
      }
      targetPrinter.printParams = this.state.configString;
      this.props.actions.setData(printers, dataNames.localPrinters);
      this.props.actions.setSelectedPrinterByTag(this.props.labelTag, targetPrinter); // Sets redux
      updatePrinter(targetPrinter);
    },10);
  }

  onEditConfig(printer){
    this.setState({config: !this.state.config, configString: printer.printParams});
  }

  onHide(){
    if(typeof this.props.onHide === 'function') this.props.onHide();
  }

  render() {

    const localPrinters = this.props.localPrinters.map((printer) => {
      if(typeof printer === 'string') printer = {name: printer, status: 1, remote: 0, server: {id: 0, remote: 0}};
      if(!printer.server) printer.server = {id: 0};
      if(this.props.storedPrinters[this.props.printerKey]) {
        const printerDetail = this.props.storedPrinters[this.props.printerKey].find((p) => {
          if(!p.server) p.server = {id: 0};
          return p.server.id === printer.server.id && p.name === printer.name;
        });
        if (printerDetail) return Object.assign({}, printerDetail, printer);
      }
      return printer;
    });

    const printServers = [{active: 1, id: 0, name: 'Local Printers', printers: localPrinters}].concat(this.props.printServers);
    const selectedPrinter = this.props.selectedPrinter[this.props.labelTag];

    const getButtonClass = (resolution, printer) => {
      const className = 'btn btn-xs';
      if(parseInt(resolution) === 203 && !printer.resolution) return `${className} btn-primary`;
      if(parseInt(resolution) === parseInt(printer.resolution)) return `${className} btn-primary`;
      return `${className} btn-default`;
    };

    const isSelectedPrinter = (printer) => {
      if(!printer.server) printer.server = {id: 0}; // Local
      if(!selectedPrinter || !selectedPrinter.server) return false;
      return (selectedPrinter.server.id === printer.server.id && selectedPrinter.name === printer.name);
    };

    return (<ModalWrapper
      showModal={this.props.showModal}
      onHide={this.onHide}
      title={`Select Printer For Printing: ${this.props.labelName}`}
      version={2}
      headerClass='bg-info-dark'
      offset={this.props.offset ? this.props.offset : 0}
      cancelButton={{show: true, text: 'Close'}}
    >
      {
        printServers.map((server, index) => {
          return (<Col key={index} md={6}>
            <div className={index === 0 && !this.props.selectedPrinter.qzConnected ? 'panel panel-danger' : index > 0 && !server.active ? 'panel panel-danger' : 'panel panel-default'}>
              <div className='panel-heading'>
                {index === 0 ? null : 'Remote:'} {server.name}
                {index === 0 ? <div style={{fontSize: 'smaller'}}>Set printer resolution for local printers with buttons to the right.</div> : null}
              </div>
              <div className='panel-body'>
                <ul className='list-group'>
                  {
                    server.printers && server.printers.length > 0
                      ? null
                      : !this.props.selectedPrinter.qzConnected && index === 0
                      ? <div>Local Printers Require QZ Tray To Be Running On Your Machine<div><QZTray /></div></div>
                        : 'No Printers Found'
                  }
                  {
                    !server.printers
                      ? null
                      : server.printers.map((printer, printerIndex) => {
                        if(!printer.status) return null;
                        return (<li key={printerIndex} className='list-group-item' style={{fontSize: 'smaller'}}>
                          <div style={{width: '49%', float: 'left'}}>
                            <div style={{float: 'left'}}>
                            {
                                !isSelectedPrinter(Object.assign({}, printer, {server}))
                                ? (<button style={{marginRight: '8px'}} className='btn btn-xs btn-default' onClick={(e) => this.onSelectPrinter(e, printer, server)}>
                                    <FaRegSquare/>
                              </button>)
                                : (<button style={{marginRight: '8px'}} className='btn btn-xs btn-success' disabled={true}>
                                    <FaRegCheckSquare/>
                              </button>)
                            }
                            </div>
                            <div style={{float: 'left'}}>
                              <div>{printer.name}</div>
                              {
                                !isSelectedPrinter(Object.assign({}, printer, {server}))
                                  ? <div>{printer.config ? 'Has Configuration' : 'No Config Set'}</div>
                                  : (<div><a style={{cursor: 'pointer'}} onClick={(e) => {
                                    e.persist();
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.onEditConfig(printer);
                                  }}>
                                    <div>
                                      {printer.config ? 'Edit Receipt Printer Configuration' : 'Receipt Printer Configuration'}
                                    </div>
                                  </a>
                                </div>)
                              }
                            </div>
                          </div>
                          <div style={{width: '49%', float: 'right', textAlign: 'right'}}>
                            {
                              index !== 0
                                ? <div>{printer.resolution ? `Resolution: ${printer.resolution}` : 'Resolution: 203'}</div>
                                : (<div className='btn-group' role='group'>
                                <button type='button' className={getButtonClass(203, printer)} onClick={(e) => this.onChangeResolution(e, 203, Object.assign({}, printer, {server}))}>203</button>
                                <button type='button' className={getButtonClass(300, printer)} onClick={(e) => this.onChangeResolution(e, 300, Object.assign({}, printer, {server}))}>300</button>
                                <button type='button' className={getButtonClass(600, printer)} onClick={(e) => this.onChangeResolution(e, 600, Object.assign({}, printer, {server}))}>600</button>
                              </div>)
                            }
                          </div>
                          <div style={{clear: 'both'}}>
                            {
                              !isSelectedPrinter(Object.assign({}, printer, {server})) || !this.state.config
                                ? null
                                : <div className='input-group input-group-sm' style={{marginTop: '8px', position: 'relative', top: '8px'}}>
                                <input type='text' className='form-control' placeholder='Config String' value={this.state.configString} onChange={(e) => this.onChangeConfig(e, Object.assign({}, printer, {server}))} />
                                <span className='input-group-btn'>
                                      <button
                                        className='btn btn-default'
                                        type='button'
                                        onClick={(e) => {
                                          this.onSaveConfig(e, Object.assign({}, printer, {server}));
                                        }}
                                      >Save!
                                      </button>
                                    </span>
                              </div>
                            }
                          </div>
                          <div style={{clear:'both'}} />
                        </li>);
                      })
                  }
                </ul>
              </div>
            </div>
          </Col>);
        })
      }
      <div style={{clear: 'both'}} />
    </ModalWrapper>);
  }
}

SetPrinterModal.propTypes = {
  show: PropTypes.bool.isRequired,
  showDpis: PropTypes.bool,
  showFontSize: PropTypes.bool,
  setForLabel: PropTypes.string,
  qzTray: PropTypes.object.isRequired,
  onHideCallback: PropTypes.func,
  onShowCallback: PropTypes.func,
  actions: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    qzTray: state.qzTray,
    printServers: getPrintServers(state),
    localPrinters: state[dataNames.localPrinters],
    storedPrinters: getPrinters(),
    labelName: getLabelName(state, ownProps),
    selectedPrinter: state[itemNames.selectedPrinter],
    printerKey: getPrinterKey(state, ownProps),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, {getQzTray, signQzRequest, setItem, setData, setSelectedPrinterByTag}, apiActions);
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SetPrinterModal);
