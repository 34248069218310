import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, formValueSelector, getFormSyncErrors} from 'redux-form';
import validate from './validate';
import SalesLocationsForm from './SalesLocationsForm';
import {SETUP_SALES_LOCATIONS_FORM} from '../../../constants/forms';


const form = SETUP_SALES_LOCATIONS_FORM;
const selector = formValueSelector(form);

const SalesLocationsReduxForm = reduxForm({
  form,
  validate,
  enableReinitialize: true
})(SalesLocationsForm);

function mapStateToProps(state, props) {
  const defaults = selector(state, 'defaults') || props.initialValues.defaults;
  const locations = selector(state, 'child_locations') || props.initialValues.child_locations;
  const totalSquareFootage = locations.reduce((square, location) => square + Number(location.total_square_footage || 0), 0).toFixed(2);
  return {
    defaults,
    locations,
    totalSquareFootage,
    type: 'sales',
    formErrors: getFormSyncErrors(SETUP_SALES_LOCATIONS_FORM)(state)
  };
}

const SetupSalesLocationsFormWrapper = connect(mapStateToProps)(SalesLocationsReduxForm);

SetupSalesLocationsFormWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

export default SetupSalesLocationsFormWrapper;
