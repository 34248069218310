import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import NotificationLink from './NotificationLink';
import InternationalDateTimeStatic from '../common/form/InternationalDateTimeStatic';


const NotificationDetails = ({notification}) => {
  const htmlBody = notification.body.split('\n').map((line) => <p>{line}</p>)
  return (
    <div className='notification-details'>
      <Row>
        <Col xs={12}>
          <strong>{I18n.t('notifications.table.sent')}:</strong> <InternationalDateTimeStatic adjustForClientTimezone={false}>{notification.starts_at}</InternationalDateTimeStatic>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div className='body'>{htmlBody}</div>
        </Col>
      </Row>
      {notification.target_link ?
        <NotificationLink
          targetLink={notification.target_link}
          targetName={notification.target_name}/> : null}
    </div>
  );
};

NotificationDetails.propTypes = {
  notification: PropTypes.shape({
    subject: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    starts_at: PropTypes.string.isRequired,
    expires_at: PropTypes.string.isRequired
  }).isRequired,
};

export default NotificationDetails;
