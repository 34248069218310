import React from 'react';
import PropTypes from 'prop-types';
import {FieldArray, Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Alert, ButtonToolbar, Button, Col, Row} from 'react-bootstrap';
import {FaExclamationTriangle} from 'react-icons/fa';
import DestroyItemsFieldArray from './DestroyItemsFieldArray';
import DatePickerInput from '../../common/form/DatePickerInput';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import TextAreaInput from '../../common/form/TextAreaInput';


const DestroyPackagesForm = (props) => {
  const {goBack, biotrackDestructionReasons, handleSubmit, onShowConfirmModal, submitting, error, useEntityLocks} = props;
  return (
    <form onSubmit={handleSubmit} noValidate={true} className='destroy-packages-form'>

      <Alert variant='warning'>
        <FaExclamationTriangle/>
        <span className='padding-left'>{I18n.t('packages.destroy.destructionNotice')}</span>
      </Alert>

      <FieldArray name='items' component={DestroyItemsFieldArray} props={{
        biotrackDestructionReasons,
        useEntityLocks,
      }}/>

      <Row>
        <Col xs={8} sm={6} md={4} lg={3}>
          <Field name='integration_destroy_reason_extended' component={ReactSelectInput} props={{
            label: I18n.t('packages.form.packages.form.biotrackDestroyReason'),
            options: biotrackDestructionReasons,
          }}/>
        </Col>
        <Col xs={12} sm={6} md={5}>
          <Field name='notes' component={TextAreaInput} props={{
            label: I18n.t('packages.form.notes'),
          }}/>
        </Col>
        <Col xs={8} sm={6} md={4} lg={3} className='date-col'>
          <Field name='event_date' component={DatePickerInput} props={{
            label: I18n.t('packages.form.modificationDate'),
          }}/>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <ButtonToolbar className='float-right'>
            <Button variant='default' onClick={goBack}>
              {I18n.t('packages.destroy.cancel')}
            </Button>
            <Button variant='primary' disabled={submitting || error} onClick={onShowConfirmModal}>
              {I18n.t('packages.destroy.destroy')}
            </Button>
          </ButtonToolbar>
        </Col>
      </Row>
    </form>
  );
};

DestroyPackagesForm.propTypes = {
  goBack: PropTypes.func.isRequired,
  biotrackDestructionReasons: PropTypes.array.isRequired,
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  onShowConfirmModal: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  useEntityLocks: PropTypes.bool.isRequired,
};

export default DestroyPackagesForm;
