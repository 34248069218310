import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container } from 'react-bootstrap';
import * as systemActions from '../actions/systemActions';
import * as userActions from '../actions/userActions';

class UnAuthorized extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
  }

  render () {
    return (
      <div  className='full-height'>
        <Container>
          {this.props.children}
        </Container>
      </div>
    );
  }
}

UnAuthorized.propTypes = {
  actions: PropTypes.object.isRequired,
  children: PropTypes.element
};

function mapStateToProps(state, ownProps){
  return {
    environment: state.system.environment
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({userActions, systemActions}, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (UnAuthorized);
