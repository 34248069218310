import find from 'lodash.find';
import sortBy from 'lodash.sortby';
import toArray from 'lodash.toarray';
import {pick} from 'lodash';
import get from 'lodash.get';

import {createSelector} from 'reselect';
import * as dataNames from '../constants/dataNames';
import * as itemNames from '../constants/itemNames';
import * as itemTypes from '../constants/itemTypes';
import * as uomTypes from '../constants/uomTypes';
import {LEAF} from '../constants/imageUrls';
import {getImageUrl} from '../util/images';
import {RETAIL} from '../constants/saleTypes';

import {
  isCategoryFlower,
  isCategoryConcentrate,
  isCategoryCartridgesPens,
  isCategoryInfusedEdible,
  isCategoryInfusedNonEdible,
  isCategoryPlants,
  isCategoryNonMedicated,
  isSubCategorySeeds,
  isSubCategoryMature,
  isSubCategoryTissue,
  isSubCategoryClones,
  isSubCategoryWaste,
  isCategoryMarijuana,
  isSubCategoryTissueCulture
} from './categorySelectors';
import {isCureIntegrator, getAllowedSubcategories} from './integration/cureApiSelectors';
import {getIntegrationState} from './integration/integrationSelectors';
import {getActiveFacilityId} from './facilitiesSelectors';
import {addPricesToItemMaster} from './itemMasterPricingSelectors';
import {addBiotrackInventoryType} from './integration/biotrackCategoriesSelectors';
import {NON_MEDICATED_CATEGORY_ID} from '../constants/categories';
import {isFeatureEnabled} from './featureToggles';
import {convertTerms} from '../util/solrHelpers';

const getPrepackWeights = state => state.prepackWeights;
const getPrepackFacilityWeights = state => state.prepackFacilityWeights;

const getImages = (state) => state.images;

const getItemMastersRaw = state => state[dataNames.itemMasters];

const getPriceType = (_, props) => props.customer && props.customer.type;
const getId = (_, props) => props.id;
const getFieldsName = (_, props) => props.fields;
const getItemMasterId = (_, props) => props.item_master_id;
const getItemMasterProp = (_, props) => props || {};

const getInactiveItemMasters = state => state[dataNames.inactiveItemMasters];

const getSubcategoryId = itemMaster => itemMaster.subcategory_id;
const getCurrentFacility = (state) => state[itemNames.facility];
const getPricingGroups = state => state[dataNames.pricingGroupsWithProduct];
const getPricingClasses = state => state[dataNames.pricingClassesWithProduct];
const getPriceLists = state => state[dataNames.priceLists] || [];
export const getPackageId = state => state[itemNames.itemMasterPackageId];
export const getSellableItemMasterData = state => state[dataNames.sellableItemMasters];

export const getItemMasters = createSelector(
  [getItemMastersRaw],
  (itemMasters) => mapIsMedicatedToItemMasters(itemMasters)
);

export const filterItemMastersIsIntegrationLeafWa = createSelector(
  [getItemMastersRaw],
  itemMasters => itemMasters.filter(isIntegrationLeaf)
);

export const getItemMastersWithInventoryType = addBiotrackInventoryType(getSubcategoryId)(getItemMastersRaw);

export const getItemMasterById = createSelector(
  [getItemMasters, getId],
  (itemMasters, id) => itemMasters.find(itemMaster => itemMaster.id === id)
);

export const getOrderedItemMasters = createSelector([getItemMasters], itemMasters => sortBy(itemMasters, 'name', 'asc'));
export const getActiveItemMasters = createSelector([getItemMasters], itemMasters => itemMasters.filter(isActive));
export const getActiveItemMastersWithInventoryType = addBiotrackInventoryType(getSubcategoryId)(getActiveItemMasters);

export const getItemMaster = state => {
  const itemMaster = state.itemMaster;
  return mapIsMedicatedToItemMaster(itemMaster);
};

export const getItemMasterWithPricing = createSelector(
  [getItemMaster, getActiveFacilityId],
  (itemMaster, facility_id) => addPricesToItemMaster(itemMaster, {facility_id})
);

const getChildItemMasters = (state) => {
  const itemMasters = state.childItemMasters;
  return mapIsMedicatedToItemMasters(itemMasters);
};

const getCatalog = state => state.catalog;

export const getAvailableCatalog = createSelector(
  [getCatalog, isCureIntegrator, getAllowedSubcategories],
  (catalog, isCure, allowedSubcategories) => {

    // if (isCure) {
    //   return toArray(catalog).filter(itemMaster => !isMedicated(itemMaster) || allowedSubcategories.indexOf(itemMaster.subcategory_id) > -1);
    // }
    return catalog;
  }
);

const getSelectedItemMasters = (state) => {
  const itemMasters = state.selectedItemMasters;
  return mapIsMedicatedToItemMasters(itemMasters);
};


export const getValidateItemMaster = (state) => state[itemNames.validateItemMaster];
export const getCategories = state => state[dataNames.categories];

// is_medicated is slated to be dropped from the itemMasters as an editable field and may not be present at some point
// so a first half step is to mutate to ensure calls still work.
export const mapIsMedicatedToItemMasters = (itemMasters) => {
  return itemMasters.map((itemMaster) => mapIsMedicatedToItemMaster(itemMaster));
};

export const mapIsMedicatedToItemMaster = (itemMaster) => {
  const nonMedicatedCategory = NON_MEDICATED_CATEGORY_ID;
  itemMaster.is_medicated = (itemMaster.category_id !== nonMedicatedCategory) ? 1 : 0;
  return itemMaster;
};

export const getChildrenByItemMasterId = createSelector(
  [getChildItemMasters, getPrepackWeights],
  (childItemMasters, prepackWeights) => {
    const itemMasters = {};
    if (childItemMasters) {
      childItemMasters
        .filter(childItemMaster => prepackWeights.find(weight => weight.id === childItemMaster.prepack_weight_id))
        .forEach(child => {
          if (prepackWeights && prepackWeights.length) {
            const prepackWeight = prepackWeights.find(weight => weight.id === child.prepack_weight_id);
            if (prepackWeight) {
              child.prepack_name = prepackWeight.name;
              child.prepack_fulfillment_units = prepackWeight.fulfillment_units;
            }
          }
          if (Object.keys(itemMasters).indexOf(child.item_master_parent_id.toString()) === -1) {
            itemMasters[child.item_master_parent_id] = [child];
          } else {
            itemMasters[child.item_master_parent_id] = [...itemMasters[child.item_master_parent_id], child];
          }
        });
    }
    return itemMasters;
  }
);

export const getSellableItemMasters = createSelector(
  [getItemMasters],
  (itemMasters) => {
    const sellableItemMasters = [];
    itemMasters.forEach(itemMaster => {
      if (isActive(itemMaster) && isSold(itemMaster)) {
        sellableItemMasters.push({
          ...itemMaster,
          itemType: getProductType(itemMaster) || itemTypes.prepack,
        });
      }
    });
    return sortBy(sellableItemMasters, 'name');
  }
);

export const getSellableItemMasterById = createSelector(getId, getSellableItemMasters,
  (id, itemMasters) => find(itemMasters, ['id', id])
);

export const getSalesOrderItemMasterById = createSelector(getId, getItemMasters,
  (id, itemMasters) => {
    const itemMaster = find(itemMasters, ['id', id]);
    if (itemMaster) {
      itemMaster.itemType = getProductType(itemMaster) || itemTypes.prepack;
    }
    return itemMaster;
  }
);

export const getPrePackItemMasters = createSelector([getActiveItemMasters], itemMasters => itemMasters.filter(isPrePack));
export const getUnitPrePackItemMasters = createSelector(
  [getActiveItemMastersWithInventoryType],
  itemMasters => itemMasters.filter((itemMaster) => {
    //There is active parent item master for prePack child
    return isUnitPrePack(itemMaster)
      && itemMasters.some(i => itemMaster.inventory_attributes.item_master_parent_id === i.id);
  })
);
export const getUnitItemMasters = createSelector([getActiveItemMastersWithInventoryType], itemMasters => itemMasters.filter(isUnitNonPrePack));
export const getBulkItemMasters = createSelector([getActiveItemMastersWithInventoryType], itemMasters => itemMasters.filter(isBulk));

//Define item master properties to be matched against candidate
const itemMasterPredicates = [isMedicated, isInventory, isLotTracked, isIngredient];
const getPredicates = createSelector(
  [({integrationState}) => integrationState],
  ({isBiotrack}) => {
    if (isBiotrack) {
      return [
        ...itemMasterPredicates,
        (itemMaster) => itemMaster.strain_id,
        (itemMaster) => itemMaster.biotrack_inventory_type,
      ];
    }
    return itemMasterPredicates;
  }
);
export const getModifyPackagesItemMasterOptions = createSelector(
  [
    ({options}) => options,
    ({itemMaster}) => itemMaster,
    getPredicates,
  ],
  (options, itemMaster, predicates) => {
    return options.filter(
      option => predicates.every(predicate => predicate(option) === predicate(itemMaster))
    );
  }
);

export const getSellablePrePackItemMasters = createSelector(getItemMasters,
  itemMasters => itemMasters.filter(itemMaster => isSold(itemMaster) && isPrePack(itemMaster))
);
export const getSellableUnitItemMasters = createSelector(getItemMasters,
  itemMasters => itemMasters.filter(
    itemMaster => isSold(itemMaster) && isUnit(itemMaster)
  )
);
export const getSellableBulkItemMasters = createSelector(getItemMasters,
  itemMasters => itemMasters.filter(
    itemMaster => isSold(itemMaster) && isBulk(itemMaster)
  )
);

export const getItemMasterChildren = createSelector(
  [getId, getItemMasters, getPrepackFacilityWeights],
  (id, itemMasters, prepackFacilityWeights) =>
    itemMasters.filter(
      itemMaster =>
        itemMaster.inventory_attributes
        && id === itemMaster.inventory_attributes.item_master_parent_id
        && prepackFacilityWeights
        && prepackFacilityWeights.find(
        prepackWeight =>
          prepackWeight.applies_to_sales
          && prepackWeight.prepack_weight_id === itemMaster.prepack_weight_id
          && prepackWeight.categories.find(
          prepackWeightCategory => prepackWeightCategory.category_id === itemMaster.category_id
          )
        )
    )
);

export const hasPrePackItemMasterId = createSelector(
  [getItemMasterId, getPrePackItemMasters],
  (itemMasterId, itemMasters) => itemMasters.some(itemMaster => itemMasterId === itemMaster.id && isPrePack(itemMaster))
);

export const getItemMasterType = createSelector([getItemMaster], getProductType);

export const getCatalogItemMasterTypeById = createSelector(
  getId, getCatalog, getItemMasterProp,
  (id, catalog, itemMasterProp) => {
    let itemMasterType = null;
    const itemMaster = Array.isArray(catalog) ? catalog.find(item => item.id === id) : catalog[id];
    if(itemMaster) {
      const pricing_type = itemMaster.pricing_type || itemMasterProp.pricing_type;
      if (pricing_type === 'weight') {
        itemMasterType = itemMaster.is_prepack ? itemTypes.prepack : itemTypes.bulk;
      } else if (pricing_type === 'unit') {
        itemMasterType = itemTypes.unit;
      }
    }
    return itemMasterType;
  }
);

export const getItemMastersWithImages = createSelector([getItemMasters, getImages], (item_masters, images) => {
  if (item_masters && images) {
    return item_masters.map(item_master => {
      const image = images.find(image => image.id === item_master.primary_product_image_file_id);
      const image_url = getImageUrl(image, '50x50', LEAF);
      return {...item_master, image_url};
    });
  }
});

export const getSelectedItemMasterIdsForMassModify = createSelector(
  getSelectedItemMasters,
  selectedItemMasters => selectedItemMasters.reduce((a, b) =>
    (b.subcategory_code && b.subcategory_code.toLowerCase().indexOf('waste') != -1 ? a : a.concat(b.id)), [])
);

export const getSelectedItemMasterIds = createSelector(
  getSelectedItemMasters,
  selectedItemMasters => selectedItemMasters.map(selectedItemMaster => selectedItemMaster.id)
);

export const getCatalogItemMastersWithPrice = createSelector(
  [getAvailableCatalog, getImages, getPriceLists],
  (itemMasters, images, priceLists) => {
    return itemMasters ? toArray(itemMasters).map(itemMaster => {
      return getCatalogItemMasterWithPrice(itemMaster, images, priceLists);
    }) : [];
  });

const getItemMasterWithPrice = createSelector([getItemMaster, getPriceType, getCurrentFacility], (itemMaster, priceType, facility) => {
  if (itemMaster.pricing_details && itemMaster.pricing_details.price_lists) {
    const priceList = itemMaster.pricing_details.price_lists.filter(price => price.consumer_type === priceType && price.sale_type === RETAIL);
    const facilityPricing = priceList.find((price) => {
      return price.facility_ids.includes(facility.id);
    });

    // Pick the pricing for this facility. If we don't have it, get default organization pricing
    const pricingDetails = facilityPricing ? facilityPricing : priceList.find(price => price.is_default_for_org);
    if (pricingDetails) {
      itemMaster['pricing_details'] = pricingDetails;
    }
  }
  return itemMaster;
});

const getCatalogItemMasterWithPrice = (itemMaster, images, priceLists) => {
  const primary_image_file = images.find(image => image.id === itemMaster.primary_product_image_file_id);
  const {pricing} = priceLists.find(price => get(price, 'pricing.item_master_id') === itemMaster.id) || {};
  const displayPrice = getPrice({...itemMaster, pricing});
  const imageUrl = getImageUrl(primary_image_file, '100x100', LEAF);
  return {
    pricing,
    ...itemMaster,
    imageUrl,
    displayPrice,
    primary_image_file,
    sortPrice: Number(displayPrice) || 0,
    pricing_type: itemMaster.pricing_type ?
      itemMaster.pricing_type :
      itemMaster.uom_type === uomTypes.DISCRETE ? 'unit' : 'weight',
  };
};

export const getCatalogItemMasterWithPriceSelector = createSelector(
  [getItemMasterWithPrice, getImages, getPriceLists], getCatalogItemMasterWithPrice
);


const getPrice = (itemMaster) => {
  const pricing = itemMaster.pricing ? itemMaster.pricing : itemMaster.pricing_details;
  return pricing ? pricing.default_price : null;
};

export function getProductType(itemMaster) {
  if (isPrePack(itemMaster)) {
    return itemTypes.prepack;
  } else if (isBulk(itemMaster)) {
    return itemTypes.bulk;
  } else if (isUnit(itemMaster)) {
    return itemTypes.unit;
  }
  return null;
}

export function getParentId(itemMaster) {
  return itemMaster && itemMaster.inventory_attributes && itemMaster.inventory_attributes.item_master_parent_id || null;
}

export function isPrePack(itemMaster) {
  return Boolean((itemMaster && itemMaster.is_prepack === 1) || (itemMaster && itemMaster.inventory_attributes && itemMaster.inventory_attributes.is_prepack === 1));
}

export function isPrePackChild(itemMaster) {
  return isPrePack(itemMaster) && Boolean(itemMaster && itemMaster.inventory_attributes && itemMaster.inventory_attributes.prepack_weight_id !== 0);
}

export function isWeight(itemMaster) {
  return Boolean(itemMaster && itemMaster.uom_type === uomTypes.WEIGHT);
}

export function isVolume(itemMaster) {
  return Boolean(itemMaster && itemMaster.uom_type === uomTypes.VOLUME);
}

//PrePack Child Item
export function isUnitPrePack(itemMaster) {
  return Boolean(isUnit(itemMaster) && isPrePack(itemMaster) && itemMaster.inventory_attributes.item_master_parent_id);
}

function isBulk(itemMaster) {
  return Boolean((isWeight(itemMaster) || isVolume(itemMaster)) && !isPrePack(itemMaster));
}

export function isUnit(itemMaster) {
  return Boolean(itemMaster && itemMaster.uom_type === uomTypes.DISCRETE);
}

//"Raw" Unit item masters
export function isUnitNonPrePack(itemMaster) {
  return Boolean(isUnit(itemMaster) && !isPrePack(itemMaster));
}

export function isSold(itemMaster) {
  return Boolean(itemMaster && itemMaster.is_sales_item === 1);
}

export function isActive(itemMaster) {
  return Boolean(itemMaster && itemMaster.active === 1);
}

export function isIntegrationLeaf(itemMaster) {
  return Boolean(itemMaster && itemMaster.external_ids);
}

//As the `is_medicated` field is deprecated, use non-medicated category as the primary rule
export function isMedicated(itemMaster) {
  return Boolean(itemMaster && ((itemMaster.category_id !== undefined && itemMaster.category_id !== 6) || itemMaster.is_medicated === 1));
}

/**
 * Check whether product is medicated. If leaf is turned on, only lot-tracked items are considered
 * @param {Object} itemMaster
 * @param {boolean} isLeaf
 * @return {boolean}
 */
export function isItemMasterMedicated(itemMaster, isLeaf) {
  return isMedicated(itemMaster) && (!isLeaf || isLotTracked(itemMaster));
}

export function isIngredient(itemMaster) {
  return Boolean(itemMaster && itemMaster.inventory_attributes && itemMaster.inventory_attributes.is_ingredient === 1);
}

export function isLotTracked(itemMaster) {
  return Boolean(itemMaster && itemMaster.is_inventory_item && (itemMaster.inventory_attributes && itemMaster.inventory_attributes.lot_tracked === 1 || itemMaster.lot_tracked === 1));
}

export function isInventory(itemMaster) {
  return Boolean(itemMaster && itemMaster.is_inventory_item === 1);
}

export function isMetrcTracked(itemMaster) {
  return isMedicated(itemMaster);
}

export function isWaste(itemMaster) {
  return Boolean(itemMaster && (itemMaster.subcategory_code === 'WASTE' || itemMaster.subcategory && itemMaster.subcategory.subcategory_code === 'WASTE'));
}

export const isValidItemMaster = createSelector(
  [getValidateItemMaster, getCategories, getIntegrationState, isFeatureEnabled],
  (itemMaster, categories, modes, isFeatureEnabled) => {

    if (itemMaster && itemMaster.is_draft) {
      return true;
    }

    if (!itemMaster || !categories || !categories.length) {
      return false;
    }
    ///bypass item master validation when integrated with metrc because we can get potentially contradictary rules
    if (modes.isMetrc) {
      return true;
    }
    const category = categories.find(category => category.id === itemMaster.category_id);
    const subcategory = category && category.subcategories.find(subcategory => subcategory.id === itemMaster.subcategory_id);

    const pricingType = itemMaster.sales_attributes && itemMaster.sales_attributes.pricing_type;
    const uomType = itemMaster.uom_type;
    const isPrePack = itemMaster.inventory_attributes && itemMaster.inventory_attributes.is_prepack;
    if (!itemMaster.default_uom) {
      return false;
    }

    if (uomTypes.uomTypes[itemMaster.default_uom].type !== uomType) {
      return false;
    }

    // Disable these checks if new product master is enabled
    if (!isFeatureEnabled('feature_new_product_master')) {
      if (isCategoryFlower(category)
        && (pricingType !== 'weight' || uomType !== uomTypes.WEIGHT)) {
        return false;
      }

      if (isCategoryConcentrate(category)
        && (!['weight', 'volume'].includes(pricingType) || ![uomTypes.WEIGHT, uomTypes.VOLUME].includes(uomType))) {
        return false;
      }
    }

    if (isCategoryCartridgesPens(category)
      && (pricingType !== 'unit' || uomType !== uomTypes.DISCRETE || isPrePack)) {
      return false;
    }

    if (isCategoryInfusedEdible(category)
      && (pricingType !== 'unit' || uomType !== uomTypes.DISCRETE || isPrePack)) {
      return false;
    }

    if (isCategoryInfusedNonEdible(category)
      && (pricingType !== 'unit' || uomType !== uomTypes.DISCRETE || isPrePack)) {
      return false;
    }

    if (isCategoryPlants(category) && isSubCategorySeeds(subcategory) && isPrePack) {
      return false;
    }

    if (isCategoryPlants(category) && isSubCategoryClones(subcategory)
      && (pricingType !== 'unit' || uomType !== uomTypes.DISCRETE || isPrePack)) {
      return false;
    }

    if (isCategoryPlants(category) && (isSubCategoryMature(subcategory) || isSubCategoryTissue(subcategory))
      && (pricingType !== 'unit' || uomType !== uomTypes.DISCRETE)) {
      return false;
    }

    if (isCategoryPlants(category) && !isSubCategorySeeds(subcategory)
      && !isSubCategoryClones(subcategory) && !isSubCategoryWaste(subcategory) && !isSubCategoryMature(subcategory)
      && !isSubCategoryTissue(subcategory) && !isSubCategoryTissueCulture(subcategory)) {
      return false;
    }

    if (isCategoryNonMedicated(category) && isPrePack) {
      return false;
    }
    if (isCategoryMarijuana(category) && (pricingType !== 'weight' || uomType !== uomTypes.WEIGHT)) {
      return false;
    }

    return true;
  }
);

const makePricingNameTitle = (pricing_items, currentFacility) => {
  const messages = {recreational: '', medical: ''};
  pricing_items
    .map(item => {
      if (!messages[item.consumer_type] || (messages[item.consumer_type] && item.facility_id === currentFacility.id)) {
        messages[item.consumer_type] = item.name;
      }
    });
  return messages;
};

export const getItemMastersForListing = createSelector(
  [getItemMastersWithImages, getCurrentFacility, getPricingGroups, getPricingClasses],
  (itemMasters, facility, pricingGroups, pricingClasses) => (
    itemMasters.map(itemMaster => {
      const filteredPricingGroups = pricingGroups.filter(pricingGroup => _isValidPricingGroup(pricingGroup, itemMaster, facility));
      const filteredPricingClass = pricingClasses.filter(pricingClasse => _isValidPricingGroup(pricingClasse, itemMaster, facility));

      return {
        ...itemMaster,
        pricing_groups: filteredPricingGroups,
        pricing_classes: filteredPricingClass,
        pricing_group_name: makePricingNameTitle(filteredPricingGroups, facility),
        pricing_class_name: makePricingNameTitle(filteredPricingClass, facility)
      };
    }
  )));

/**
 * Uses for determinate right pricing groups which should be visible on
 * the product listing page (ProductsPage component)
 * @param pricingGroup
 * @param itemMaster
 * @param facility
 * @returns {boolean}
 * @private
 */
const _isValidPricingGroup = (pricingGroup, itemMaster, facility) => {
  if (
    (!pricingGroup.facility_id) &&
    (pricingGroup.item_master_id === itemMaster.id)
  ) {
    return true;
  } else if (
    (pricingGroup.facility_id === facility.id) &&
    (pricingGroup.item_master_id === itemMaster.id)
  ) {
    return true;
  }

  return false;
};

export const getItemMasterInfo = createSelector([getItemMasters, getInactiveItemMasters, getId, getFieldsName], (itemMasters, inactiveItemMasters, id, fields) =>
  fields.length
    ? pick((itemMasters.find(im => im.id === id) || inactiveItemMasters.find(im => im.id === id) || {}), fields)
    : (itemMasters.find(im => im.id === id) || inactiveItemMasters.find(im => im.id === id) || {})
);

export const getSolrCoreName = createSelector(
  [isFeatureEnabled],
  (isFeatureEnabled) => {
    return isFeatureEnabled('feature_solr_rework') ? 'new_item_masters' : 'item_masters';
  }
);

// export const getSolrReworkQueryAndFilter = (query, filter, solrReworkEnabled) => {
//   let updatedQueryString = query;
//   let updatedFilter = filter;

//   if (solrReworkEnabled) {
//     // Filter string build logic
//     const pattern = /([\!\*\+\-\=\<\>\&\|\(\)\[\]\{\}\^\~\?\:\\\/"])/g;
//     const clearedQuery = query.toLowerCase().trim().replace(pattern, '\\$1');
//     updatedQueryString = clearedQuery.split(' ').map(w => `(*${w}*)`).join(' AND ');
//     updatedFilter = `(name: (${updatedQueryString}) OR display_name: (${updatedQueryString}) ) AND ${filter}`;
//     updatedQueryString = '';
//   }

//   return { updatedQueryString, updatedFilter };
// };

export const getSolrReworkQueryAndFilter = (query, filter, solrReworkEnabled) => {
  let updatedQueryString = query;
  let updatedFilter = filter;

  if (solrReworkEnabled) {
    const clearedQuery = query.toLowerCase().trim();
    const trimQuery = query.trim();
    const convertedTerms = convertTerms(clearedQuery);
    const convertedTrimTerm = convertTerms(trimQuery);
  
    updatedQueryString = '';
    updatedFilter = `(name: (${convertedTerms}) OR item_number: (${convertedTrimTerm}) OR item_number: (${convertedTerms}) OR display_name: (${convertedTerms})) AND ${filter}`;
  }

  return { updatedQueryString, updatedFilter };
};
