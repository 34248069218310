//
// Sales Report Tabs
//
import { isGeoPermByStateExcluded, isGeoPermByCountryExcluded } from '../../../../util/permissionHelpers';
import * as permissions from '../../../../constants/permissions';

export default function getSalesReportTabs(isFeatureEnabled, userHasPermission, facility, integrations)  {
  // Let's be safe but this should now come from an action wrapping this function
  if(typeof isFeatureEnabled !== 'function'){
    isFeatureEnabled = () => false;
  }

  return [
    { id: 'salesSummaryTab',
      eventKey: 'salesSummary',
      title: 'nav.subNav.salesSummary',
      actions: [],
      path: '/retail/sales-report/sales-summary',
      permissionKeys: [permissions.manage_report_sales_summary],
      featureToggle: 'feature_sales_summary_report'
    },
    { id: 'dailyTransactionsReportTab',
      eventKey: 'dailyTransactionsReport',
      title: 'nav.subNav.dailyTransactionsReport',
      actions: [],
      path: '/retail/sales-report/transactions',
      permissionKeys: [permissions.manage_report_daily_transactions]
    },
    { id: 'currentInventoryReportTab',
      eventKey: 'currentInventoryReport',
      title: 'nav.subNav.currentInventoryReport',
      actions: [],
      path: '/retail/sales-report/inventory',
      permissionKeys: [permissions.manage_report_current_inventory]
    },
    { id: 'productSalesReportTab',
      eventKey: 'productSalesReport',
      title: 'nav.subNav.productSalesReport',
      actions: [],
      path: '/retail/sales-report/products',
      permissionKeys: [permissions.manage_report_product_sales]
    },
    { id: 'dailyClosingReportTab',
      eventKey: 'closingReport',
      title: 'nav.closingReport',
      actions: [],
      path: '/retail/sales-report/closing-report',
      permissionKeys: [permissions.manage_report_closing],
    },
    { id: 'registerActivityReportTab',
      eventKey: 'registerActivityReport',
      title: 'nav.subNav.registerActivityReport',
      actions: [],
      path: '/retail/sales-report/register-activity',
      permissionKeys: [permissions.manage_report_closing],
    },
    { id: 'cashOnHandReportTab',
      eventKey: 'cashOnHandReport',
      title: 'nav.subNav.cashOnHandReport',
      actions: [],
      path: '/retail/sales-report/cash-on-hand',
      permissionKeys: [permissions.manage_report_cash_on_hand],
      featureToggle: 'feature_show_bi_platform_reports'
    },
    { id: 'inventoryTransactionsReportTab',
      eventKey: 'inventoryTransactionsReport',
      title: 'nav.inventoryTransactionsReport',
      actions: [],
      path: '/retail/sales-report/inventory-transactions',
      permissionKeys: [permissions.manage_report_inventory_transactions],
      featureToggle: 'feature_show_bi_platform_reports'
    },
    { id: 'canadaExportTab',
      eventKey: 'canadaExport',
      title: 'nav.canadaExport',
      actions: [],
      path: '/retail/sales-report/export-ca',
      permissionKeys: [permissions.manage_report_canada]
    },
    { id: 'oklahomaExportTab',
      eventKey: 'oklahomaExport',
      title: 'nav.oklahomaExport',
      actions: [],
      path: '/retail/sales-report/export-ok',
      permissionKeys: [permissions.manage_report_oklahoma]
    },
    // TODO: enable this when report is ready for Metrc facilities
    // { id: 'metrcSalesReportTab',
    //   eventKey: 'metrcSalesReport',
    //   title: 'nav.metrcSalesReport',
    //   actions: [],
    //   path: '/retail/sales-report/metrc',
    //   permissionKeys: [permissions.manage_report_metrc_sales],
    //   integration: 'isMetrc'
    // },
  ].filter((tab) => {
    const isExcludedByGeolocation = (tab.permissionKeys || []).reduce((acc, permKey) => {
      if (acc) return acc;
      const byCountry = isGeoPermByCountryExcluded(facility, 'ca', permKey);
      const byState = isGeoPermByStateExcluded(facility, 'ok', permKey);
      return byState || byCountry;
    }, false);

    const isExcludedByFeatureToggle = tab.featureToggle && !isFeatureEnabled(tab.featureToggle);

    const isExcludedByIntegration = tab.integration && !(integrations && integrations.hasOwnProperty(tab.integration) && integrations[tab.integration]);

    return !isExcludedByGeolocation && !isExcludedByFeatureToggle && !isExcludedByIntegration && userHasPermission(tab.permissionKeys);
  });
}
