import {I18n} from 'react-redux-i18n';

const validate = (values, props) => {
  const errors = {};

  if(!values.name) {
    errors.name = I18n.t('common.error.required');
  }

  const rules = props.checkPasswordRules(values.password, values.name);
  if (rules.some(rule => !rule.isValid)) {
    // Use space to not show default error message for this field, since we show special block with rules for password
    errors.password = ' ';
  }

  if(!values.password_confirmation) {
    errors.password_confirmation = I18n.t('common.error.required');
  }

  if(values.password && values.password_confirmation && values.password !== values.password_confirmation) {
    errors.password_confirmation = I18n.t('user.password.matchInvalid');
  }

  if(values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = I18n.t('user.password.emailInvalid');
  }

  return errors;
};


export default validate;