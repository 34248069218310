/* eslint-disable indent */
/* eslint-disable  no-case-declarations */
/* eslint-disable no-unreachable */
import * as formData from '../../components/retail/rewards/components/setup/formData';
import * as actionTypes from '../../constants/actionTypes';

const rewardSettingsForm = (state, action) => {

  if(action.type !== actionTypes.REDUX_FORM_CHANGE) return state;
  if(!action.meta || !action.meta.field) return state;

  const newState = Object.assign({}, state);

  switch(true){

    // Set value when override becomes enabled
    case action.meta.field === 'reward_one_point_is_worth__override_enabled':
      if(typeof action.payload === typeof true){
        newState.values.reward_one_point_is_worth__override_value = formData.formDefaults.defaultPointsWorth; // Minimum increment and is step
        return newState;
      }
      break;

    // Button click can set value to true/false but it the real value is either numeric or in the case of defaultRemovePointInDays false
    case action.meta.field === 'reward_remove_points_after_days_between_visits':
      const defaultRemovePointsInDays = formData.formDefaults.defaultDaysPointsExpire;
      const getDaysInYears = (days) => {
        return (days / 365).toFixed(2);
      };
      if(typeof action.payload === typeof true){
        if(!action.payload){
          newState.values.reward_remove_points_after_days_between_visits = formData.formDefaults.disableDaysPointsExpire; // Effectively disabled
        } else {
          newState.values.reward_remove_points_after_days_between_visits = defaultRemovePointsInDays; // Reasonable default interval
          newState.values.reward_remove_points_after_days_between_visits_in_years = getDaysInYears(defaultRemovePointsInDays);
        }
        return newState;
      } else {
        newState.values.reward_remove_points_after_days_between_visits_in_years = getDaysInYears(newState.values.reward_remove_points_after_days_between_visits);
      }
      break;

    default: // Only so clearly not omitted by accident
      break;
  }
  return state;

};

export default rewardSettingsForm;
