import get from 'lodash.get';
import camelCase from 'lodash.camelcase';
import * as itemNames from '../../constants/itemNames';
import * as apiActions from '../apiActions';
import {setItem} from '../itemActions';


/**
 * Generic method which allows to get settings for a specific seeting key
 * @param key
 * @returns {*}
 */
export const fetchByKey = (key) => {
  return apiActions.getItem(
    '/api/settings/values/by_key',
    get(itemNames, `[${camelCase(key)}]`),
    {failed: 'internalTransfersSettings.messages.getSettingError'},
    {
      ids: [key]
    }
  );
};

/**
 * Get setting by key and store in settings object which holds multiple arbitrary settings as key/value pairs.
 * BE CAREFUL: If going for several keys get them all at once, not one at a time, or you are likely to lose some due to
 * async nature.
 * @param keys
 * @returns {Function}
 */
export const getSettingByKey = (keys = []) => {
  return (dispatch, getState) => {
    const params = {ids: keys};
    const next = (data) => {
      const settingsHash = Object.assign({}, getState()[itemNames.settingsHash]);
      keys.forEach((key) => {
        settingsHash[key] = get(data, key);
      });
      dispatch(setItem(settingsHash, itemNames.settingsHash));
    };
    return dispatch(apiActions.getItem('/api/settings/values/by_key', null, null, params, next));
  };
};


/**
 * Returning settings related to Internal Transfer functionality
 * @returns {*}
 */
export const fetchInternalTransferSettings = () => {
  return apiActions.getItem('/api/settings/internal_transfers', itemNames.coreInternalTransfersSettings);
};

/**
 * Saving settings related to Internal Transfer functionality
 * @param payload
 * @returns {*}
 */
export const saveInternalTransferSettings = (payload) => {
  return apiActions.postItem('/api/settings/internal_transfers',
    payload,
    'noOp',
    {success: 'internalTransfersSettings.messages.successSave', failed: 'internalTransfersSettings.messages.failedSave'}
  );
};

export const fetchUseEntityLocks = () => {
  return (dispatch, getState) => {
    // "use_entity_locks" now actually get "enforced" version of getUseEntityLocks from ConfigurationService
    // for unblocking leaf pa phase workflow
    dispatch(apiActions.getItem('/api/settings/use_entity_locks', itemNames.useEntityLocks));
  };
};
