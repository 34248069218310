import React from 'react';
import PropTypes from 'prop-types';
import {Field, FieldArray} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import { Col, Row } from 'react-bootstrap';
import ReactSelectInput from '../../../../common/form/ReactSelectInput';
import SubmitSection from '../common/SubmitSection';
import AlleavesTerminals from './AlleavesTerminals';

const Alleaves = props => {

  const activeOptions = [
    { text: 'No', value: false },
    { text: 'Yes', value: true },
  ];

  return (
    <div className='alleaves-container'>
      <FieldArray
        name={`terminals`}
        component={AlleavesTerminals}
      />
      <Row>
        <Col sm={4}>
          <Field name='active' component={ReactSelectInput} props={{
            label: I18n.t('facility.setup.thirdPartyIntegration.alleaves.active'),
            isRequired: true,
            options: activeOptions,
            dataId: 'value'
          }} />
        </Col>
      </Row>
      <SubmitSection />
    </div>
  );
};

Alleaves.propTypes = {
  actions: PropTypes.object.isRequired
};

export default Alleaves;
