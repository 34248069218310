import React from 'react';
import {I18n} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import ModalWrapper from '../ModalWrapper';
import {doNothing} from '../../../util/callbackHelpers';

const NegativeInventoryAlert = (props) => {
  const confirmationState = props.confirmationState || {};
  const hideHandler = confirmationState.onHide || doNothing;
  const confirmHandler = confirmationState.onConfirm || doNothing;
  return (<ModalWrapper
    showModal={confirmationState.show}
    onHide={hideHandler}
    title={I18n.t('inventory.negativeInventoryAlert')}
    version={2}
    dialogClassName='modal-sm'
    headerClass='bg-info-dark'
    cancelButton={{show: true, text: I18n.t('general.no')}}
    okayButton={{show: true, text: I18n.t('general.yes'), onClick: confirmHandler}}
  >
    <div>{I18n.t('inventory.confirmToGoNegative')}</div>
  </ModalWrapper>);
};

NegativeInventoryAlert.propTypes = {
  confirmationState: PropTypes.object,
};

export default NegativeInventoryAlert;