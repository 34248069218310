import * as types from '../constants/actionTypes';

export function setSocketUser(payload){
  return {type: types.SOCKET_SET_USER, payload:{id: payload.user.id}};
}

export function setSocketFacility(payload){
  return {type: types.SOCKET_SET_FACILITY, payload};
}

export function unsetSocketConnection(){
  return {type: types.SOCKET_UNSET_CONNECTION};
}
