import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';
import { Field } from 'redux-form';
import {Row, Col, Button} from 'react-bootstrap';
import {FaPrint, FaTimes} from 'react-icons/fa';
import TextInput from '../../../common/form/TextInput';
import InlineCheckBox from '../../../common/form/InlineCheckBox';
import CheckBoxInput from '../../../common/form/CheckBoxInput';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import WillRender from '../../../common/concealers/WillRender';
import StorageLocationType from './StorageLocationType';
import {facilityHasModule} from '../../../../selectors/facilitiesSelectors';
import {getMetrcLocationTypesOptions, hasMetrcLocationTypes} from '../../../../selectors/integration/metrcSelectors';


const text = {
  storage: {
    label: 'locations.form.sectionName'
  },
  sale: {
    label: 'locations.form.subSectionName'
  }
};

export const LevelThreeLocations = ({fields, type, printLabel, integratedLocationNames, showIntegratedLocationName,
                               isCanadaFacility, currentFacility, isLeaf, integrationMapping, disableLocationEdit,
                               onDelete, facilityHasConnects, isCultivation, hasMetrcLocationTypes, locationTypes}) => {

  return (
    <React.Fragment>
      {fields.map((section, index) => {
        const location = fields.get(index) || {};
        const trackingId = integrationMapping.find(entity => Number.parseInt(entity.internal_identifier) === location.id);
        const childItem = (!location.child_locations || location.child_locations.length === 0) ? location : location.child_locations[index];

        return (
          <React.Fragment key={section}>{
            showIntegratedLocationName || hasMetrcLocationTypes && (
            <Row className='form-fields-row'>
              <Col xs={{span: 4}}>
                <Field
                  name={`${section}.integrated_location_name`}
                  component={ReactSelectInput}
                  props={{
                    label: I18n.t('locations.form.integratedLocationName'),
                    options: hasMetrcLocationTypes ? locationTypes : integratedLocationNames,
                    textKey:'name',
                    valueKey: 'code',
                    isRequired: true,
                    readOnly: disableLocationEdit,
                    placeholder: I18n.t('locations.form.integratedLocationNamePlaceholder')
                  }} />
              </Col>
            </Row>
            )}
            <Row className='form-fields-row'>
              <Col xs={4}>
                {!disableLocationEdit && <Button
                  size='sm'
                  variant='danger'
                  className='btn-inline float-right'
                  disabled={fields.length < 2}
                  onClick={() => {
                    onDelete(fields.get(index), () => {
                      fields.remove(index);
                    });
                  }}> <FaTimes/>
                </Button>}
              </Col>
              <Col className='form-fields-col' xs={6} md={4} lg={3}>
                <Field
                  name={`${section}.name`} component={TextInput}
                  props={{
                    label: I18n.t(text[type].label),
                    isRequired: true,
                    readOnly: disableLocationEdit,
                    placeholder: I18n.t('locations.form.namePlaceholder')
                  }}
                />
              </Col>
              {(!isCultivation && !disableLocationEdit) ?
                <Col xs={2}>
                  <Field
                    name={`${section}.in_store_only`}
                    component={InlineCheckBox}
                    props={{
                      label: I18n.t('locations.form.inStoreOnly')
                    }}/>
                </Col>
                : null
              }
              {isLeaf ?
                <Row>
                  <Col className='form-fields-col' xs={8} md={4} lg={3}>
                    <Field
                      component={TextInput} name={`${section}.tracking_id`}
                      props={{
                        value: trackingId ? trackingId.external_identifier : '',
                        label: I18n.t('locations.form.trackingId'),
                        readOnly: true
                      }}
                    />
                  </Col>
                </Row> : null
              }
              <StorageLocationType name={section} isCanadaFacility={isCanadaFacility} type={type}/>
              <Col lg={1}>
                {
                  !childItem || childItem.id === undefined
                    ? null
                    : (
                    <Button
                      size='sm'
                      className='btn btn-sm btn-inline float-left'
                      style={{marginRight: '5px'}}
                      onClick={(event) => { printLabel(event, location.id ,'section');}}
                    >
                      <FaPrint/> Print
                    </Button>
                  )
                }
              </Col>
              <WillRender ifTrue={get(currentFacility, 'public_profile_enabled', false) && facilityHasConnects}>
                <Col sm={2} className='visible_in_network'>
                  <Field
                    name={`${section}.visible_in_network`}
                    props={{
                      value: location.visible_in_network,
                      label: I18n.t('locations.form.visibleInNetwork')
                    }}
                    component={CheckBoxInput} type='checkbox'/>
                </Col>
              </WillRender>
            </Row>

          </React.Fragment>
        );
      })
      }
    </React.Fragment>);

};

LevelThreeLocations.propTypes = {
  printLabel: PropTypes.func,
  fields: PropTypes.object.isRequired,
  type: PropTypes.oneOf(['storage', 'sale']).isRequired,
  integratedLocationNames: PropTypes.array,
  showIntegratedLocationName: PropTypes.bool,
  isCanadaFacility: PropTypes.bool,
  currentFacility: PropTypes.object.isRequired,
  isLeaf: PropTypes.bool,
  integrationMapping: PropTypes.array,
  disableLocationEdit: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  facilityHasConnects: PropTypes.bool,
  isCultivation: PropTypes.bool,
  hasMetrcLocationTypes: PropTypes.bool,
  locationTypes: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    facilityHasConnects: facilityHasModule(state, {moduleKey: 'CONNECTS'}),
    hasMetrcLocationTypes: hasMetrcLocationTypes(state),
    locationTypes: hasMetrcLocationTypes(state) ? getMetrcLocationTypesOptions(state) : [],
  };
}

export default connect(mapStateToProps) (LevelThreeLocations);
