import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Translate} from 'react-redux-i18n';

class PrivacyPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
  }

  render () {
    return (<Translate value='Privacy Page' />);
  }
}

PrivacyPage.propTypes = {
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps){
  return {
    state
  };
}

export default connect(mapStateToProps) (PrivacyPage);
