import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { Field, FieldArray } from 'redux-form';
import { Button, ButtonToolbar, Col, Row } from 'react-bootstrap';

import TextInput from '../../../common/form/TextInput';
import MultiselectInput from '../../../common/form/MultiselectInput';

import CustomerGroupsFieldArray from '../../pricing-groups/common/CustomerGroupsFieldArray';

const PricingClassForm = (props) => {
  const {
    handleSubmit,
    submitting,
    pristine,
    valid,
    showSaveAndReturn,
    change,
    facilities,
    customerGroups,
    getFormValue
  } = props;

  return (
    <form className='pricing-class-form' onSubmit={handleSubmit} noValidate={true}>
      <div className='limited-width'>
        <Row>
          <Col sm={3}>
            <Field name='name' component={TextInput} props={{
              label: 'Pricing Class Name',
              isRequired: true
            }}/>
          </Col>
          <Col sm={3}>
            <Field name='facilities' component={MultiselectInput} props={{
              label: 'Applicable Facilities',
              options: facilities,
              valueKey: 'id',
              textKey: 'name'
            }} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={8} md={5} offset={3}>
            <FieldArray name='customer_groups' component={CustomerGroupsFieldArray} props={{
              change,
              customerGroups,
              getFormValue,
            }}/>
          </Col>
        </Row>
      </div>
      <Row className='submit-section'>
        <Col xs={12}>
          <div className='button-wrapper'>
            <ButtonToolbar className='float-right'>
              {showSaveAndReturn ?
                <Button variant='primary' type='submit' onClick={() => change('afterSubmit', 'redirect')}
                        disabled={!valid || pristine || submitting}>
                  {I18n.t('retail.pricingClass.form.saveAndReturn')}
                </Button>
                : null}
              <Button variant='primary' type='submit' onClick={() => change('afterSubmit', '')}
                      disabled={!valid || pristine || submitting}>
                {I18n.t('common.form.save')}
              </Button>
            </ButtonToolbar>
          </div>
        </Col>
      </Row>
    </form>
  );
};


PricingClassForm.propTypes = {
  handleSubmit: PropTypes.func,
  valid: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  showSaveAndReturn: PropTypes.bool.isRequired,
  facilities: PropTypes.array.isRequired,
  initialValues: PropTypes.object,
  customerGroups: PropTypes.array.isRequired,
  getFormValue: PropTypes.func.isRequired
};

export default PricingClassForm;
