import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, getFormSyncErrors } from 'redux-form';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Col, Row, Card } from 'react-bootstrap';

import * as statuses from '../../../../constants/statuses';

import InternationalDateTimePickerInput from '../../../common/form/InternationalDateTimePickerInput';
import MultiselectInput from '../../../common/form/MultiselectInput';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import TextInput from '../../../common/form/TextInput';
import InternationalQuantityByUomInput from '../../../common/form/InternationalQuantityByUomInput';
import InternationalDecimalReadOnlyInput from '../../../common/form/InternationalDecimalReadOnlyInput';

/* eslint-disable import/no-named-as-default*/
import ProcessingInputsFieldArray from '../common/ProcessingInputsFieldArray';
import OutputsFieldArray from './OutputsFieldArray';
import IngredientsFieldArray from './IngredientsFieldArray';
import NotesFieldArray from '../../common/NotesFieldArray';
import SubmitSection from '../../../common/form/SubmitSection';
import * as p from '../../../../constants/permissions';
import { COMPLETE_PROCESSING_FORM } from '../../../../constants/forms';

export class CompleteProcessingForm extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.onChangeItem = this.onChangeItem.bind(this);

    this.state = {
      waInventoryTypes: []
    };
  }
  // touch all invalid fields to prompt error displays
  componentDidUpdate() {
    if (this.props.valid || !this.props.formErrors) {
      return;
    }
    if (this.props.formErrors.inputs && Array.isArray(this.props.formErrors.inputs)) {
      const inputKeys = this.props.formErrors.inputs
        .map((el, index) => {
          return Object.keys(el).map((key) => `inputs.${index}.${key}`);
        })
        .flat();
      this.props.touch(...inputKeys);
    }
  }

  onChangeItem(itemMasterId, prefix) {
    const { waProcessingInventoryTypes, change } = this.props;
    const inventoryTypes = waProcessingInventoryTypes.filter((item) => item.item_master_id === itemMasterId);
    if (inventoryTypes) {
      this.setState({ waInventoryTypes: inventoryTypes });
    }
    change(`${prefix}.item_master_id`, itemMasterId);
  }

  render() {
    const {
      form,
      notes,
      handleSubmit,
      change,
      reset,
      invalid,
      pristine,
      submitting,
      employees,
      equipment,
      batchName,
      products,
      locations,
      productionRuns,
      trackingIds,
      phases,
      weights,
      ingredients,
      ingredientOptions,
      ingredientsCost,
      ingredientsStandardCost,
      loading,
      status,
      isDraft,
      outputItemIds,
      showPrinter,
      arePackagesValidForProcessing,
      userPermissions,
      integrationState,
      is_cannabis_mix,
      isManufacturing,
      hasPackagesTags,
      adjustmentReasons,
      initialValues,
      isLabelEnabledForNoCompletedProcess,
      isPaRemediationLabelsEnabled
    } = this.props;
    const { waInventoryTypes } = this.state;
    const { isMdMerc } = integrationState;
    const isCompleted = status === statuses.completed;
    const submitSettings = {};
    if (isCompleted) {
      submitSettings.actionSettings = {
        print: {
          text: I18n.t(`common.actions.printLabel${outputItemIds.length > 1 ? 's' : ''}`),
          action: showPrinter
        }
      };
    } else {
      submitSettings.actionSettings = {
        saveDraftAndPrint: {
          text: I18n.t('common.actions.saveDraftAndPrint'),
          type: 'submit',
          submitting,
          pristine,
          disabled: !isLabelEnabledForNoCompletedProcess,
          action: () => change('submitDraft', true)
        },
        reset: {
          text: I18n.t('common.form.reset'),
          action: reset,
          style: 'default',
          pristine
        },
        saveAndPrint: {
          text: I18n.t('common.actions.saveAndPrint'),
          type: 'submit',
          submitting,
          invalid,
          pristine,
          // validations based on whether a process input had a reserved quantity for that process or not has been removed.
          // Reserved quantities are taken into account while computing the input quantity available field.
          disabled: !arePackagesValidForProcessing,
          display:
            [p.print_labels].filter((x) => (userPermissions[x] ? userPermissions[x] : null)).length > 0 ? '' : 'hide',
          action: () => {
            change('afterSubmit', 'print');
            change('submitDraft', false);
          }
        },
        submit: {
          text: I18n.t('common.form.save'),
          submitting,
          invalid,
          pristine,
          // validations based on whether a process input had a reserved quantity for that process or not has been removed.
          // Reserved quantities are taken into account while computing the input quantity available field.
          disabled: !arePackagesValidForProcessing,
          action: () => {
            change('afterSubmit', '');
            change('submitDraft', false);
          }
        }
      };

      if (!isLabelEnabledForNoCompletedProcess) {
        delete submitSettings.actionSettings.saveDraftAndPrint;
      }
    }

    return (
      <form className='complete-processing-form' onSubmit={handleSubmit} noValidate={true}>
        <Row>
          <Col xs={6} sm={4} md={3}>
            <Field
              name='processingTypeName'
              component={TextInput}
              props={{
                label: I18n.t('ei.processing.form.processingType'),
                disabled: true
              }}
            />
          </Col>
        </Row>

        <h4 className='section-title'>{I18n.t('ei.processing.form.processedPackages')}</h4>
        <FieldArray
          name='inputs'
          component={ProcessingInputsFieldArray}
          props={{
            isCompleteMode: true,
            isDraft: isDraft,
            isCompleted,
            loading,
            isPaRemediationLabelsEnabled
          }}
        />

        <h4 className='section-title'>{I18n.t('ei.processing.form.extractionTime')}</h4>
        <Row>
          <Col xs={6} md={3}>
            <InternationalDateTimePickerInput
              name='expected_completion_time'
              props={{
                label: I18n.t('ei.processing.form.expectedCompletionTime'),
                timeFormat: true,
                disabled: true,
                inputProps: {
                  disabled: true
                },
                enableTodayButton: false
              }}
            />
          </Col>
          <Col xs={6} md={3}>
            <InternationalDateTimePickerInput
              name='actual_completion_time'
              props={{
                label: I18n.t('ei.processing.form.completionTime'),
                timeFormat: true,
                isRequired: true,
                disabled: isCompleted,
                inputProps: {
                  disabled: isCompleted
                },
                enableTodayButton: false
              }}
            />
          </Col>
          {!isMdMerc ? (
            <Col xs={6} md={3}>
              <Field
                name='employees'
                component={MultiselectInput}
                props={{
                  label: I18n.t('ei.processing.form.employees'),
                  options: employees,
                  textKey: 'displayName',
                  valueKey: 'id',
                  isRequired: true,
                  disabled: isCompleted
                }}
              />
            </Col>
          ) : (
            <Col xs={6} md={3}>
              <Field
                name='employee'
                component={ReactSelectInput}
                props={{
                  label: I18n.t('ei.processing.form.employee'),
                  options: employees,
                  textKey: 'displayName',
                  valueKey: 'id',
                  isRequired: true,
                  disabled: isCompleted
                }}
              />
            </Col>
          )}
          <Col xs={6} md={3}>
            <Field
              name='equipments'
              component={MultiselectInput}
              props={{
                label: I18n.t('ei.processing.form.assignEquipment'),
                options: equipment,
                textKey: 'model_name',
                valueKey: 'id',
                isRequired: true,
                disabled: isCompleted
              }}
            />
          </Col>
        </Row>

        <h4 className='section-title'>{I18n.t('ei.processing.form.newItems')}</h4>
        <FieldArray
          name='outputs'
          component={OutputsFieldArray}
          products={products}
          locations={locations}
          productionRuns={productionRuns}
          trackingIds={trackingIds}
          phases={phases}
          change={change}
          batchName={batchName}
          loading={loading}
          hasPackagesTags={hasPackagesTags}
          isCompleted={isCompleted}
          isManufacturing={isManufacturing}
          integrationState={integrationState}
          adjustmentReasons={adjustmentReasons}
          initialValues={initialValues}
          onChangeItem={this.onChangeItem}
          waInventoryTypes={waInventoryTypes}
          isLabelEnabledForNoCompletedProcess={isLabelEnabledForNoCompletedProcess}
        />

        <h4 className='section-title'>{I18n.t('ei.processing.form.actualIngredientsUsed')}</h4>
        <FieldArray
          name='ingredients'
          component={IngredientsFieldArray}
          change={change}
          ingredients={ingredients}
          ingredientsCost={ingredientsCost}
          ingredientOptions={ingredientOptions}
          ingredientsStandardCost={ingredientsStandardCost}
          isCompleted={isCompleted}
        />

        <Row>
          <Col xs={12}>
            <Card body>
              <Row>
                <Col xs={4}>
                  <InternationalDecimalReadOnlyInput
                    name='total_weight_used'
                    props={{
                      label: I18n.t('ei.processing.form.totalWeightUsed'),
                      disabled: true,
                      value: initialValues.total_weight_used
                    }}
                  />
                </Col>
                <Col xs={4}>
                  <InternationalDecimalReadOnlyInput
                    name='returned_percent'
                    props={{
                      label: I18n.t('ei.processing.form.returnedPercent'),
                      value: weights.returnedPercent
                    }}
                  />
                </Col>
                <Col xs={4}>
                  <InternationalQuantityByUomInput
                    name='waste'
                    disabled={isCompleted || is_cannabis_mix}
                    label={I18n.t('ei.processing.form.reportWaste')}
                    uom='GR'
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <FieldArray name='notes' formName={form} component={NotesFieldArray} notes={notes} change={change} />
        <SubmitSection settings={submitSettings} />
      </form>
    );
  }
}

CompleteProcessingForm.propTypes = {
  form: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  formErrors: PropTypes.object.isRequired,
  notes: PropTypes.array.isRequired,
  inputs: PropTypes.object,
  reset: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  is_cannabis_mix: PropTypes.bool,
  isManufacturing: PropTypes.bool,
  hasPackagesTags: PropTypes.bool,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  employees: PropTypes.array.isRequired,
  equipment: PropTypes.array.isRequired,
  batchName: PropTypes.string,
  products: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  productionRuns: PropTypes.array.isRequired,
  trackingIds: PropTypes.array.isRequired,
  phases: PropTypes.array.isRequired,
  weights: PropTypes.object.isRequired,
  ingredients: PropTypes.array.isRequired,
  ingredientOptions: PropTypes.object.isRequired,
  ingredientsCost: PropTypes.number,
  ingredientsStandardCost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  loading: PropTypes.bool,
  status: PropTypes.string,
  isDraft: PropTypes.bool,
  outputItemIds: PropTypes.array.isRequired,
  showPrinter: PropTypes.func.isRequired,
  arePackagesValidForProcessing: PropTypes.bool,
  integrationState: PropTypes.object.isRequired,
  userPermissions: PropTypes.oneOfType([PropTypes.array.isRequired, PropTypes.object.isRequired]),
  initialValues: PropTypes.object,
  onChangeItem: PropTypes.func,
  isLabelEnabledForNoCompletedProcess: PropTypes.bool,
  isPaRemediationLabelsEnabled: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    formErrors: getFormSyncErrors(COMPLETE_PROCESSING_FORM)(state) || [],
  };
}

export default connect(mapStateToProps)(CompleteProcessingForm);
