import {createSelector} from 'reselect';
import orderBy from 'lodash.orderby';
import {I18n} from 'react-redux-i18n';
import moment from 'moment-timezone';
import * as itemNames from '../constants/itemNames';
import * as dataNames from '../constants/dataNames';
import { convertDbDateToFormInputDate, isMoment, formatTime, convertDbTimeToClientTzMoment, convertClientToDbTz, convertDbDateTimeToClientTzMoment } from '../util/dateHelpers';
import { getCurrencySymbol, getInternationalNumberFormat } from './InternationalOperationsSelectors';
import {fieldFormatHelperDecimals} from '../util/internationalHelpers';

const getIsActiveTab = (_, props) => props.params.active !== 'inactive';
const getCouponId = (_, props) => Number(props.params.id);
const mapMultiValues = (values, key) => (values || []).map(value => value && (value[key] || value[key] === 0) ? value[key] : value);
const getCurrentTimezone = state => state.timezone;

export const getCoupons = state => state[dataNames.coupons];
export const getCoupon = state => state[itemNames.coupon];
export const getProductLists = state => state[dataNames.productLists];

export const getCouponListingData = createSelector(
  [getCoupons, getIsActiveTab, getCurrentTimezone, getCurrencySymbol, getInternationalNumberFormat],
  (coupons, isActive, timezone, currencySymbol, numberFormat) => orderBy(coupons.reduce(
    (acc, coupon) => {
      const isCouponActive = coupon.date_available_to === null || moment().isSameOrBefore(
        moment.tz(coupon.date_available_to, 'UTC')
      );
      if ((isActive && isCouponActive) || (!isActive && !isCouponActive)) {
        const discount_formatted = fieldFormatHelperDecimals(coupon.discount_amount, 'discount', numberFormat);
        return acc.concat({
          ...coupon,
          expiration_date: convertDbDateToFormInputDate(coupon.date_available_to, timezone),
          amount: `${coupon.discount_type === 'value' ? currencySymbol : '%'} ${discount_formatted}`,
          redemption: coupon.max_redemptions || I18n.t('retail.coupons.table.noLimit'),
        });
      }
      return acc;
    },
    []
  ), 'name', 'asc')
);

export const getModifyCouponInitialValues = createSelector(
  [getCoupon, getCouponId, getCurrentTimezone, getProductLists],
  (coupon, couponId, timezone, productLists) => {
    if (coupon.id === couponId) {
      return {
        ...coupon,
        date_available_from: convertDbDateTimeToClientTzMoment(coupon.date_available_from, timezone),
        date_available_to:convertDbDateTimeToClientTzMoment(coupon.date_available_to, timezone),
        time_available_from_raw: coupon.time_available_from,
        time_available_to_raw: coupon.time_available_to,
        time_available_from: convertDbTimeToClientTzMoment(coupon.time_available_from, 'UTC'),
        time_available_to: convertDbTimeToClientTzMoment(coupon.time_available_to, 'UTC'),
        facility_ids: coupon.facility_ids || [],
        consumer_group_ids: coupon.consumer_group_ids || [],
        category_ids: coupon.category_ids || [],
        vendor_ids: coupon.vendor_ids || [],
        item_master_ids: coupon.item_master_ids || [],
        product_list_ids: coupon.product_list_ids ? coupon.product_list_ids.map((pl_id) => productLists.find((pl) => pl.id === pl_id.id)) : [],
        days_available: coupon.days_available || [],
      };
    }
    return {
      facility_ids: [],
      consumer_group_ids: [],
      category_ids: [],
      vendor_ids: [],
      item_master_ids: [],
      product_list_ids: [],
      days_available: [],
    };
  }
);

export const getPayload = (coupon, params) => ({
  ...coupon,
  facility_ids: mapMultiValues(coupon.facility_ids, 'id').length
    ? mapMultiValues(coupon.facility_ids, 'id')
    : params.facilities.map(f => f.id),
  days_available: mapMultiValues(coupon.days_available, 'value').length
    ? mapMultiValues(coupon.days_available, 'value')
    : [0,1,2,3,4,5,6],
  category_ids: mapMultiValues(coupon.category_ids, 'id'),
  consumer_group_ids: mapMultiValues(coupon.consumer_group_ids, 'id'),
  item_master_ids: mapMultiValues(coupon.item_master_ids, 'id'),
  product_list_id: coupon.product_list_id,
  vendor_ids: mapMultiValues(coupon.vendor_ids, 'id'),
  //time_available_from: formatTime(coupon.time_available_from) || null,
  //time_available_to: formatTime(coupon.time_available_to) || null,
  time_available_from: (isMoment(coupon.time_available_from)) ? formatTime(coupon.time_available_from) : null,
  time_available_to: (isMoment(coupon.time_available_to)) ? formatTime(coupon.time_available_to) : null,
  date_available_from: convertClientToDbTz(moment(coupon.date_available_from).startOf('day'), params.timezone) || null,
  date_available_to: convertClientToDbTz(moment(coupon.date_available_to).endOf('day'), params.timezone) || null,
  applicable_item_count: parseInt(coupon.applicable_item_count) || 0,
});
