import * as types from '../../constants/actionTypes';
import initialState from '../../constants/initialState';

export default function taxRateReducer (state = initialState.cart.taxRate, action){
  const {taxRate} = action;
  switch (action.type) {
  case types.SET_TAX_RATE:
    return taxRate;
  default:
    return state;
  }
}
