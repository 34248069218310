import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {formValueSelector, change, getFormValues} from 'redux-form';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import PageTitle from '../common/PageTitle';
import { AuthRequest } from '../../managers/request';
import Notice from '../common/notice';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import * as messageTypes from '../../constants/messageTypes';
import {getData, getItem, postItem} from '../../actions/apiActions';
import {InventoryAuditForm} from './InventoryAuditForm';
import {INVENTORY_AUDIT_FORM} from '../../constants/forms';
import {isFloat} from '../common/form/redux-form/validations';
import {unsetData} from '../../actions/dataActions';
import {addMessage} from '../../actions/systemActions';
import {convertClientToDbTz} from '../../util/dateHelpers';
import InventoryAuditListing from './InventoryAuditListing';

const initialState = {
  auditStarted: false,
  error: '',
  packageCodeScannedAt: null,
  productSearch: false
};

// Order in which user steps through the form
const formOrder = [
  'packageCode', 'product', 'count'
];

class InventoryAuditPage extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = initialState;
    this.request = AuthRequest.create();

    // Refs for autofocusing
    this.locationRef = React.createRef();
    this.packageCodeRef = React.createRef();
    this.productRef = React.createRef();
    this.countRef = React.createRef();
    this.notesRef = React.createRef();

    this.clearErrorMessage = this.clearErrorMessage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleLoadAudit = this.handleLoadAudit.bind(this);
    this.handlePackageSearch = this.handlePackageSearch.bind(this);
    this.handleStartAudit = this.handleStartAudit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.actions.getData('/api/locations/child_locations', dataNames.locations);
  }

  // we can't autofocus if the field is disabled, so we need to autofocus after the render
  componentDidUpdate(prevProps, prevState, snapshot) {
    const formValuesPrevProps = prevProps.formValues;
    const formValuesProps = this.props.formValues;

    // If no formValues in props, we can't autofocus
    if (!formValuesProps) {
      return;
    }
    // If formValues exist in props but not in prevProps, then 'location' has been completed. Set focus to 'product'
    if (!formValuesPrevProps) {
      this.autoFocusInput('packageCode');
      return;
    }

    // Find first field that has changed and change focus to next field
    formOrder.some((input) => {
      if (formValuesProps[input] !== formValuesPrevProps[input]) {
        this.autoFocusInput(this.getNextInput(input));
        return true; // We found our changed field. No need to keep evaluating
      }
    });
  }

  getNextInput(currentInput) {
    const index = formOrder.indexOf(currentInput);
    return formOrder[index + 1];
  }

  // Move cursor to specified field (if valid Ref)
  autoFocusInput(input) {
    if (input) {
      const inputRef = `${input}Ref`;
      if (this[inputRef].current) this[inputRef].current.focus();
    }
  }

  handleLoadAudit(id) {
    this.props.actions.getItem(`/api/inventory_audit/${id}`, itemNames.inventoryAudit, {failed: 'inventory.audit.load.failed'})
      .then((inventoryAudit) => {
        this.setState({auditStarted: true});
        this.props.actions.change(INVENTORY_AUDIT_FORM, 'location', inventoryAudit.inventory_location_id);
        if (this.locationRef.current) this.locationRef.current.value = inventoryAudit.inventory_location_id;
      });
  }

  handleStartAudit() {
    this.setState(initialState);
    this.setState({auditStarted: true});
  }

  handleChange(e) {
    const { name, value } = e.target;
    // Save when new audit and location is set for the first time
    if (name === 'location' && !this.state.inventoryAudit) {
      const payload = {
        inventory_location_id: value,
        audit_started_at: convertClientToDbTz(new Date())
      };
      this.props.actions.postItem(`/api/inventory_audit`, payload, itemNames.inventoryAudit, {failed: 'inventory.audit.save.failed'});
    }

    if (name === 'count') {
      const valid = isFloat(value) && parseFloat(value) >= 0;
      if (!valid) {
        this.setState({error: I18n.t('inventory.audit.error.count')});
        return;
      }
    }

    this.props.actions.change(INVENTORY_AUDIT_FORM, name, typeof value === 'string' ? value.trim() : value);

    // clear the error once the user is moving on to get it out of their face
    if (this.state.error) {
      this.setState({error: ''});
    }
  }

  handlePackageSearch(e, pastedValue = null) {
    const location = this.props.getFormValue('location');
    // When triggered by a paste action, handlePackageSearch is triggered before handleChange.
    // This means the Redux-Form is not yet updated (getFormValue would return null or the previous value).
    // If a pastedValue is present, then use that value.
    const packageCode = pastedValue || this.props.getFormValue('packageCode');
    this.setState({productSearch: true});
    this.props.actions.getItem(`/api/items?inventory_location_id=${location}&package_code=${packageCode}`, itemNames.product).then((data) => {
      if (data && data.length) {
        const product = data[0].item_name;
        this.props.actions.change(INVENTORY_AUDIT_FORM, 'product', product);
        this.setState({packageCodeScannedAt: new Date()});
      } else {
        this.setState({error: I18n.t('inventory.audit.error.packageNotFound')});
      }
      this.setState({productSearch: false});
    });
  }

  clearErrorMessage() {
    this.setState({error: initialState.error});
  }

  // Checks if sufficient information is provided to add item to payload (i.e. both product and count)
  isValid() {
    const count = this.props.getFormValue('count');
    return isFloat(count) && parseFloat(count) >= 0;
  }

  resetForm()  {
    formOrder.forEach((input) => {
      this.props.actions.change(INVENTORY_AUDIT_FORM,input,'');
      const inputRef = `${input}Ref`;
      if (this[inputRef].current) this[inputRef].current.value = '';
    });
    this.props.actions.change(INVENTORY_AUDIT_FORM,'notes','');
    if (this.notesRef.current) this.notesRef.current.value = '';
    unsetData(itemNames.product);
    this.clearErrorMessage();
  }

  endAudit() {
    // Submit and end audit
    const payload = {
      id: this.props.inventoryAudit.id,
      audit_ended_at: convertClientToDbTz(new Date())
    };
    this.props.actions.postItem(`/api/inventory_audit`, payload, null, {failed: 'inventory.audit.save.failed'})
      .then(() => {
        // Stop audit
        this.setState({auditStarted: false});
        this.props.actions.addMessage(messageTypes.info, 'inventory.audit.save.success');
        // Reset form
        this.resetForm();
        this.props.actions.change(INVENTORY_AUDIT_FORM,'location',null);
        unsetData(itemNames.inventoryAudit);
      });
  }

  handleSubmit(e, action) {
    e.preventDefault();

    // Persist inventoryAuditCounts if valid
    if (this.isValid()) {
      // Construct inventoryAuditCount
      const inventoryAuditCountPayload = {
        inventory_audit_id: this.props.inventoryAudit.id,
        item_id: this.props.productDetail.id,
        package_code: this.props.getFormValue('packageCode'),
        package_code_scanned_at: this.state['packageCodeScannedAt'],
        audit_count: parseFloat(this.props.getFormValue('count')),
        actual_count: this.props.productDetail.qty,
        notes: this.props.getFormValue('notes')
      };
      // Submit inventory audit count
      this.props.actions.postItem(`/api/inventory_audit/counts`, inventoryAuditCountPayload, null, {failed: 'inventory.audit.save.failed'});
    }

    // Continue audit
    if (action === 'next') {
      // Reset form
      this.resetForm();
      this.autoFocusInput('packageCode');
      return;
    }

    // Or finalize
    if (action === 'complete') {
      this.endAudit();
    }
  }

  render () {
    const {
      auditStarted,
      error,
      productSearch
    } = this.state;

    const {
      locations,
      getFormValue,
    } = this.props;

    return (
      <div>
        <PageTitle primaryText={I18n.t('inventory.audit.title')}/>
        <div className='container-fluid'>
          <Button
            className='btn btn-success start-audit-btn'
            onClick={this.handleStartAudit}
            disabled={auditStarted}
          >
            {I18n.t('inventory.audit.button.startAudit')}
          </Button>
          {error &&  <Notice title={error} type={'danger'}/>}
          {auditStarted && (
            <InventoryAuditForm
              locations={locations}
              locationRef={this.locationRef}
              packageCodeRef={this.packageCodeRef}
              productRef={this.productRef}
              countRef={this.countRef}
              notesRef={this.notesRef}
              handleChange={this.handleChange}
              handlePackageSearch={this.handlePackageSearch}
              handleSubmit={this.handleSubmit}
              productSearch={productSearch}
              getFormValue={getFormValue}
              allowSubmit={!error}
            />
          )}
        </div>
        <hr />
          <InventoryAuditListing
            handleLoadAudit={this.handleLoadAudit}
            locations={locations}
          />
      </div>
    );
  }
}

InventoryAuditPage.propTypes = {
  actions: PropTypes.shape({
    addMessage: PropTypes.func.isRequired,
    getData: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
  }).isRequired,
  getFormValue: PropTypes.func.isRequired,
  locations: PropTypes.array.isRequired,
  formValues: PropTypes.object,
  inventoryAudit: PropTypes.object,
  productDetail: PropTypes.object,
};

function mapStateToProps(state) {
  const selector = formValueSelector(INVENTORY_AUDIT_FORM);
  return {
    getFormValue: (name) => selector(state, name),
    formValues: getFormValues(INVENTORY_AUDIT_FORM)(state),
    locations: state[dataNames.locations] ? state[dataNames.locations] : [],
    inventoryAudit: state[itemNames.inventoryAudit] ? state[itemNames.inventoryAudit] : {},
    productDetail: state[itemNames.product] ? state[itemNames.product][0] : {},
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    addMessage,
    change,
    getData,
    getItem,
    postItem
  };
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (InventoryAuditPage);
