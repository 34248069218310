import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, FieldArray, getFormValues } from 'redux-form';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Button, ButtonToolbar, Col, Row } from 'react-bootstrap';
import {FaExclamationTriangle} from 'react-icons/fa';
import { AlphaBeticalCaseInsensitive } from '../../../util/sortHelper';
import { product } from '../../../constants/imageTypes';
import TextInput from '../../common/form/TextInput';
import TextAreaInput from '../../common/form/TextAreaInput';
import ComboboxInput from '../../common/form/ComboboxInput';
import ReduxTagField from '../../common/form/redux-form/ReduxTagField';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import InlineCheckBox from '../../common/form/InlineCheckBox';
import ImageField from '../../common/form/files/ImageField';
import ProductTypeOptionsPanel from '../common/ProductTypeOptionsPanel';
import PurchasingInfoPanel from './PurchasingInfoPanel';
import SalesInfoPanel from './sales/SalesInfoPanel';
import PotencyInfoPanel from './PotencyInfoPanel';
import TerpeneInfoPanel from './TerpeneInfoPanel';
import AdditionalInfoPanel from './AdditionalInfoPanel';
import InventorySettingsPanel from './InventorySettingsPanel';
import AlternateImages from './AlternateImages';
import validate from './validate';
import {
  isCategoryCartridgesPens,
  isCategoryInfusedEdible,
  isCategoryInfusedNonEdible,
  isCategoryPlants,
  isCategoryNonMedicated,
  isSubCategorySeeds,
  isCategoryHarvest
} from '../../../selectors/categorySelectors';
import { getIntegrationState } from '../../../selectors/integration/integrationSelectors';
import { isWeedmapsIntegrator } from '../../../selectors/integration/thirdPartyIntegrationSelectors';
import { getIsHempFacility } from '../../../selectors/coreSettingsSelectors';
import WillRender from '../../common/concealers/WillRender';
import PermissionButton from '../../common/PermissionButton';
import {
  isMedicatedWeightRequired,
  isProductNetWeightRequired,
  canUserManageReservationAndIsAllowedInComplianceSettings
} from '../../../selectors/forms/productFormSelectors';
import * as permissions from '../../../constants/permissions';
import TrackingIds from './TrackingIds';
import { isLeafPaConfigPackClosedLoopFacility } from '../../../selectors/facilitiesSelectors';


const packagingOptions = [
  { text: I18n.t('products.form.packagingOptions.bag'), value: 'Bag' },
  { text: I18n.t('products.form.packagingOptions.bottle'), value: 'Bottle' },
  { text: I18n.t('products.form.packagingOptions.box'), value: 'Box' },
  { text: I18n.t('products.form.packagingOptions.jar'), value: 'Jar' }
];

export const ProductForm = (props) => {
  const {
    handleSubmit,
    hasMetrc,
    reset,
    resetForm,
    change,
    strainOptions,
    dominanceOptions,
    categoryOptions,
    facilityOptions,
    vendorOptions,
    tagOptions,
    terpeneList,
    potencyList,
    solventOptions,
    dilutionOptions,
    onSubcategoryChange,
    activationTimeOptions,
    uomOptions,
    filteredPricingGroupOptions,
    pricingWeights,
    onlineAvailabilityOptions,
    formValues,
    submitting,
    pristine,
    isModify,
    strainLocked,
    allUoms,
    productTypeOptions,
    subcategories,
    retailFacilityOptions,
    subCategoryMapErrors,
    isSeedPackagingAllowed,
    integrationState: { isLeafly, isOhMetrc, isUtah },
    integrationState,
    medicatedWeightRequired,
    productNetWeightRequired,
    isPrepackWithInventory,
    pricingClasses,
    brands,
    isMasterFacility,
    isWeedmapsIntegrator,
    canUserManageReservationAndIsAllowedInComplianceSettings,
    isConnectsActive,
    onNavigate,
    facilityState,
    isWaInventoryMapped,
    waInventoryMapping,
    strainRequired,
    isHempFacility,
    isPaLeaf,
    isChild
  } = props;

  const pricingOptions = !isHempFacility
    ? props.pricingOptions.filter((option) => option.value !== 'volume')
    : props.pricingOptions.map((option) => option);

  let sc;
  const categoryId = formValues.category_id || 1;
  const selectedCategory = (sc = categoryOptions.find((category) => category.id === categoryId));
  const selectedSubCategory =
    sc && sc.subcategories.find((subcategory) => subcategory.id === formValues.subcategory_id);
  const selectedStrain = formValues.strain_id
    ? strainOptions.find((strain) => strain.id === formValues.strain_id)
    : null;
  const phenotypes = (selectedStrain && selectedStrain.phenotypes) || [];
  const salesItemUomOptions = uomOptions.filter((uom) => uom.uom_code === 'GR' || uom.uom_code === 'EA');
  const isEdible = (category) =>
    isCategoryInfusedEdible(category) || isCategoryInfusedNonEdible(category) || isCategoryCartridgesPens(category);
  const pricing_type = formValues.sales_attributes ? formValues.sales_attributes.pricing_type : '';
  const isLockedProduct = Boolean(formValues.has_items);
  const isCanadianSeed = isCategoryPlants(sc) && isSubCategorySeeds(selectedSubCategory) && isSeedPackagingAllowed;
  const saveIsDisabled = submitting || pristine || Array.isArray(subCategoryMapErrors);
  const isSalesItem = formValues.is_sales_item;
  const requiresDescription = hasMetrc && formValues.required_fields && formValues.required_fields.requires_description;

  // Need to initialize formValues.required_fields on page load when modifying a
  // product. onSubcategoryChange is the only place to initialize those values.
  if (formValues.required_fields === undefined && formValues.subcategory_id && !formValues.default_uom) {
    onSubcategoryChange(formValues.subcategory_id, null);
  }


  return (
    <form className='product-form' onSubmit={handleSubmit}>
      <ProductTypeOptionsPanel
        options={productTypeOptions}
        category={selectedCategory}
        subcategoryId={formValues.subcategory_id}
        onClick={(category_code, subcategory_code) => {
          const category = categoryOptions.find((category) => category.category_code === category_code);
          if (category) {
            reset();
            const subcategory = category.subcategories.find(
              (subcategory) => subcategory.subcategory_code === subcategory_code
            );
            change('category_id', category.id);
            change('is_medicated', category.id !== 6);
            if (subcategory) {
              change('subcategory_id', subcategory.id);
            }
          }
        }}
        isLockedProduct={isLockedProduct}
        isChild={isChild}
      />
      <Row style={{ display: 'none' }}>
        <Col sm={6}>
          <Field
            name='category_id'
            component={ReactSelectInput}
            props={{
              label: I18n.t('products.form.itemCategory'),
              options: categoryOptions,
              textKey: 'name',
              valueKey: 'id',
              value: categoryId,
              disabled: true,
              isRequired: true
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <h4>
            {I18n.t('common.status')}:{' '}
            <span style={{ color: formValues.active ? 'green' : 'red', fontWeight: 'bold' }}>
              {formValues.active ? 'Active' : 'Inactive'}
            </span>
          </h4>
        </Col>
      </Row>
      <Row>
        <Field
          name='has_metrc'
          component={TextInput}
          style={{ display: 'none' }}
          props={{ value: formValues.hasMetrc, disabled: true }}
        />
        <Col sm={3}>
          <Field
            name='subcategory_id'
            component={ReactSelectInput}
            props={{
              label: I18n.t('products.form.itemSubcategory'),
              options: subcategories,
              textKey: 'display_name',
              valueKey: 'id',
              isRequired: true,
              disabled:
                (selectedSubCategory && selectedSubCategory.is_shared && !isMasterFacility) ||
                isWaInventoryMapped ||
                (isLockedProduct && isUtah) || isChild
            }}
            onChange={(event, newValue, previousValue) => onSubcategoryChange(newValue, previousValue)}
          />
          {!subCategoryMapErrors ? null : (
            <div className='alert alert-danger'>
              <div>
                <FaExclamationTriangle style={{ marginRight: '5px' }} />
                {I18n.t('products.form.productSubcategoryError.reason')}
                <div style={{ clear: 'both', height: '8px' }} />
                <ul>
                  {subCategoryMapErrors.map((sub, index) => (
                    <li key={index}>{I18n.t('products.form.productSubcategoryError.category', {friendlyType: sub.friendlyType})}</li>
                  ))}
                </ul>
                <div style={{ clear: 'both', height: '8px', marginBottom: '12px' }}>
                  <Link to={{ pathname: '/category/management', state: { goBackAfterSubmit: true } }} onlyActiveOnIndex={false}>
                    <Button variant='link'>{I18n.t('products.form.productSubcategoryError.link')}</Button>
                  </Link>
                </div>
              </div>
            </div>
          )}
          {!(isLockedProduct && isUtah) ? null : (
            <div className='alert alert-warning'>
              <div>
                <FaExclamationTriangle style={{ marginRight: '5px' }} />
                {I18n.t('products.form.productSubcategoryError.locked')}
              </div>
            </div>
          )}
        </Col>
        <Col sm={3}>
          <Field
            name='strain_id'
            component={ReactSelectInput}
            props={{
              label: I18n.t('products.form.strainName'),
              options: strainOptions,
              disabled: strainLocked || isChild,
              textKey: 'strain_name',
              onChange: (value) => {
                change('strain_id', value);
                const selectedStrain = strainOptions.find((record) => {
                  return record.id === value;
                });
                const selectedDom =
                  selectedStrain &&
                  dominanceOptions.find((record) => {
                    return record.value === selectedStrain.dominance;
                  });
                if (!selectedDom) return true;
                change('dominance_id', selectedDom.id);
                change('dominance', selectedDom.value);
              },
              valueKey: 'id',
              isRequired:
                strainRequired ||
                (!isCategoryNonMedicated(sc) &&
                  ((hasMetrc && formValues.required_fields && formValues.required_fields.requires_strain) ||
                    isCanadianSeed ||
                    integrationState.isBiotrack))
            }}
          />
          {!strainLocked ? null : (
            <div style={{ position: 'relative', top: '-10px' }} className='text-muted'>
              {I18n.t('products.form.strainNotForFacility')}
            </div>
          )}
        </Col>
        <Col sm={3}>
          <Field
            name='name'
            component={TextInput}
            props={{
              label: I18n.t('products.form.itemName'),
              placeholder: I18n.t('products.form.itemNamePlaceholder'),
              isRequired: true,
              disabled: isWaInventoryMapped || isChild
            }}
          />
        </Col>
        <Col sm={3}>
          <Field
            name='display_name'
            component={TextInput}
            props={{
              label: I18n.t('products.form.displayName'),
              placeholder: I18n.t('products.form.displayNamePlaceholder'),
              disabled: isChild
            }}
          />
        </Col>
      </Row>
      <Row>
        <WillRender ifTrue={!isChild}>
          <Col sm={6}>
            <ImageField
              imageType={product}
              name='primary_product_image_file_id'
              fileName='primary_image_file'
              change={change}
              value={formValues.primary_product_image_file_id}
              fileValue={formValues.primary_image_file}
            />
            <FieldArray
              name='product_images'
              component={AlternateImages}
              images={formValues.product_images}
              change={change}
            />
          </Col>
        </WillRender>
        <Col sm={3}>
          <Field
            name='item_number'
            component={TextInput}
            props={{
              label: I18n.t('products.form.itemId'),
              placeholder: I18n.t('products.form.itemId')
            }}
          />
        </Col>
        <Col md={3}>
          <Row>
            <WillRender ifTrue={formValues.id && isOhMetrc && facilityState === 'OH'}>
              <Col md={12}>
                <Field
                  name='product_identifier'
                  component={TextInput}
                  props={{
                    disabled: true,
                    label: I18n.t('products.form.metrcProductIdentifier')
                  }}
                />
              </Col>
            </WillRender>
            <WillRender ifTrue={formValues.id && canUserManageReservationAndIsAllowedInComplianceSettings && !isChild}>
              <Col md={12}>
                <div style={{ padding: '24px 0' }}>
                  <PermissionButton
                    permissions={[permissions.manage_reservations]}
                    props={{
                      variant: 'primary',
                      className: 'btn-block',
                      onClick: () => {
                        onNavigate(`/products/${formValues.id}/reservations`);
                      }
                    }}
                  >
                    {I18n.t('products.form.reservationsHistory')}
                  </PermissionButton>
                </div>
              </Col>
            </WillRender>
          </Row>
        </Col>
        <Col sm={6}>{isWaInventoryMapped && <TrackingIds ids={waInventoryMapping} />}</Col>
      </Row>
      <WillRender ifTrue={!isChild}>
        <Row>
          <Col sm={12}>
            <ReduxTagField
              name='tags'
              props={{
                options: tagOptions,
                label: I18n.t('products.form.tags'),
                textKey: 'tag_name',
                valueKey: 'tag_name'
              }}
            />
          </Col>
          <Col sm={12}>
            <Field
              name='description'
              component={TextAreaInput}
              props={{
                label: I18n.t('products.form.productDescription'),
                isRequired: integrationState.isApisicpa || requiresDescription
              }}
            />
          </Col>
          <Col sm={12} className='product-form-brand'>
            <Field
              name='brand'
              component={ComboboxInput}
              props={{
                label: I18n.t('products.form.brand'),
                placeholder: 'Select an Item Brand or create a new Brand',
                options: brands,
                textKey: 'name',
                valueKey: 'id'
              }}
            />
          </Col>
        </Row>
        {integrationState.isApisicpa ? (
          <Row>
            <Col sm={6} md={3}>
              <Field
                name='packaging'
                component={ReactSelectInput}
                props={{
                  label: I18n.t('products.form.packaging'),
                  isRequired: true,
                  options: packagingOptions
                }}
              />
            </Col>
          </Row>
        ) : null}
        <Row style={{marginBottom: '12px'}}>
          <Col sm={12}>
            <Field
              name='is_purchasing_item'
              component={InlineCheckBox}
              props={{
                label: I18n.t('products.form.purchaseInventory'),
                groupClassName: 'inline-checkbox'
              }}
            />
          </Col>
        </Row>
        <Row style={{marginBottom: '12px'}}>
          <Col sm={12}>
            <Field
              name='is_inventory_item'
              component={InlineCheckBox}
              props={{
                label: I18n.t('products.form.trackInventory'),
                groupClassName: 'inline-checkbox',
                checked: formValues.is_inventory_item || !isCategoryNonMedicated(sc),
                disabled: isLockedProduct || !isCategoryNonMedicated(sc) || isCategoryHarvest(sc)
              }}
              onChange={(event, isInventoryItem) => change('inventory_attributes.lot_tracked', isInventoryItem)}
            />
          </Col>
        </Row>
        <Row style={{marginBottom: '12px'}}>
          <Col sm={12}>
            <Field
              name='is_sales_item'
              component={InlineCheckBox}
              props={{
                label: I18n.t('products.form.sellInventory'),
                groupClassName: 'inline-checkbox',
                onChange: (event) => {
                  const value = event.target.checked;
                  if (
                    value &&
                    !isLockedProduct &&
                    !salesItemUomOptions.some((option) => option.uom_code === formValues.default_uom)
                  ) {
                    change('default_uom', '');
                    change('sales_attributes.pricing_type', '');
                  }
                  change('is_sales_item', event.target.checked);
                },
                disabled: true
              }}
            />
          </Col>
        </Row>
        <Row style={{marginBottom: '12px'}}>
          <Col sm={12}>
            <Field
              name='is_manufactured_item'
              component={InlineCheckBox}
              props={{
                label: I18n.t('products.form.produceInventory'),
                groupClassName: 'inline-checkbox'
              }}
            />
          </Col>
        </Row>

        {!isConnectsActive ? null : (
          <Row style={{marginBottom: '12px'}}>
            <Col sm={12}>
              <Field
                name='available_to_connects'
                component={InlineCheckBox}
                props={{
                  label: I18n.t('products.form.availableToConnects'),
                  groupClassName: 'inline-checkbox',
                  disabled: !isSalesItem
                }}
              />
            </Col>
          </Row>
        )}
        <hr />
        <Row>
          <Col sm={6}>
            <Row>
              <Col sm={6}>
                <Field
                  name='default_uom'
                  component={ReactSelectInput}
                  props={{
                    label: I18n.t('products.form.inventoryUom'),
                    placeholder: I18n.t('common.form.uomPlaceholder'),
                    onChange: (value) => {
                      const selectedOption = uomOptions.find((uom) => uom.uom_code === value);
                      change('default_uom', value);
                      change('uom_type', (selectedOption && selectedOption.uom_type) || '');
                    },
                    options: uomOptions,
                    textKey: 'uom_code',
                    valueKey: 'uom_code',
                    isRequired: true,
                    disabled: isLockedProduct || isWaInventoryMapped
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Row>
              <Col sm={6}>
                <Field
                  name='phenotype_id'
                  component={ReactSelectInput}
                  props={{
                    label: I18n.t('products.form.phenotype'),
                    options: phenotypes,
                    textKey: 'name',
                    onChange: (value) => {
                      change('phenotype_id', value);
                    },
                    valueKey: 'id'
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Field
                  name='dominance_id'
                  component={ReactSelectInput}
                  props={{
                    label: I18n.t('products.form.dominanceName'),
                    options: dominanceOptions,
                    textKey: 'text',
                    valueKey: 'id',
                    onChange: (value) => {
                      change('dominance_id', value);
                      const selectedDom = dominanceOptions.find((record) => {
                        return record.id === value;
                      });
                      change('dominance', (selectedDom && selectedDom.value) || '');
                    }
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </WillRender>
      <hr />
      <WillRender ifTrue={!isChild}>
        {(formValues.is_inventory_item || !isCategoryNonMedicated(sc)) && (
          <InventorySettingsPanel
            formValues={formValues}
            isEdible={isEdible(selectedCategory)}
            allUoms={allUoms}
            isLockedProduct={isLockedProduct}
            isMultipack={formValues.inventory_attributes && formValues.inventory_attributes.multipack_enabled}
            integrationState={integrationState}
            medicatedWeightRequired={medicatedWeightRequired}
            productNetWeightRequired={productNetWeightRequired}
            isSeedPackagingAllowed={isSeedPackagingAllowed}
            selectedCategory={selectedCategory}
            selectedSubCategory={selectedSubCategory}
            isPrepackWithInventory={isPrepackWithInventory}
            isWaInventoryMapped={isWaInventoryMapped}
            waInventoryMapping={waInventoryMapping}
            isPaLeaf={isPaLeaf}
            change={change}
          />
        )}

        {formValues.is_purchasing_item ? (
          <PurchasingInfoPanel
            vendorOptions={vendorOptions}
            activeUom={formValues.uom_type}
            uom={formValues.default_uom}
            values={formValues.vendors}
          />
        ) : (
          ''
        )}

        {formValues.is_sales_item ? (
          <SalesInfoPanel
            pricingClasses={pricingClasses}
            pricingOptions={pricingOptions}
            pricingGroupOptions={filteredPricingGroupOptions}
            pricingWeights={pricingWeights}
            onlineAvailabilityOptions={onlineAvailabilityOptions}
            isLockedProduct={isLockedProduct}
            formValues={formValues}
            pricing_type={pricing_type}
            facilityOptions={facilityOptions}
            retailFacilityOptions={retailFacilityOptions}
            categoryId={categoryId}
            isLeafly={isLeafly}
            isWeedmapsIntegrator={isWeedmapsIntegrator}
          />
        ) : null}

        <PotencyInfoPanel
          activationTimeOptions={activationTimeOptions}
          potencyList={potencyList}
          requiredFields={formValues.required_fields}
        />
        <TerpeneInfoPanel terpeneList={terpeneList} />

        <AdditionalInfoPanel
          solventOptions={solventOptions}
          dilutionOptions={dilutionOptions}
          selectedCategory={selectedCategory}
          selectedSubCategory={selectedSubCategory}
          formValues={formValues}
          isSeedPackagingAllowed={isSeedPackagingAllowed}
          integrationState={integrationState}
        />
      </WillRender>
      <div className='form-actions'>
        <ButtonToolbar className='float-right'>
          <Button
            type='reset'
            onClick={(event) => {
              reset(event);
              resetForm(event);
            }}
            disabled={pristine || submitting}
          >
            {I18n.t('common.form.reset')}
          </Button>
          {isModify ? null : (
            <Button
              type='submit'
              variant='success'
              disabled={saveIsDisabled}
              onClick={() => change('afterSubmit', 'nothing')}
            >
              {I18n.t('products.form.saveAndDuplicate')}
            </Button>
          )}
          {isModify ? null : (
            <Button
              type='submit'
              variant='success'
              disabled={saveIsDisabled}
              onClick={() => change('afterSubmit', 'clear')}
            >
              {I18n.t('products.form.saveAndCreateAnother')}
            </Button>
          )}
          <Button
            type='submit'
            variant='primary'
            disabled={saveIsDisabled}
            onClick={() => change('afterSubmit', 'redirect')}
          >
            {I18n.t('products.form.saveAndReturn')}
          </Button>
        </ButtonToolbar>
      </div>
    </form>
  );
};

ProductForm.defaultProps = {
  isMasterFacility: false
};

ProductForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  isModify: PropTypes.bool,
  change: PropTypes.func.isRequired,
  strainOptions: PropTypes.array.isRequired,
  dominanceOptions: PropTypes.array.isRequired,
  categoryOptions: PropTypes.array.isRequired,
  vendorOptions: PropTypes.array.isRequired,
  solventOptions: PropTypes.array.isRequired,
  dilutionOptions: PropTypes.array.isRequired,
  tagOptions: PropTypes.array.isRequired,
  potencyList: PropTypes.array.isRequired,
  terpeneList: PropTypes.array.isRequired,
  brands: PropTypes.array.isRequired,
  pricingOptions: PropTypes.array.isRequired,
  pricingGroupOptions: PropTypes.array.isRequired,
  pricingClasses: PropTypes.array.isRequired,
  filteredPricingGroupOptions: PropTypes.array.isRequired,
  activationTimeOptions: PropTypes.array.isRequired,
  uomOptions: PropTypes.array.isRequired,
  onlineAvailabilityOptions: PropTypes.array.isRequired,
  pricingWeights: PropTypes.array.isRequired,
  formValues: PropTypes.shape({
    vendors: PropTypes.array
  }),
  hasMetrc: PropTypes.bool.isRequired,
  isSeedPackagingAllowed: PropTypes.bool.isRequired,
  strainLocked: PropTypes.bool.isRequired,
  allUoms: PropTypes.array,
  productTypeOptions: PropTypes.array.isRequired,
  onSubcategoryChange: PropTypes.func.isRequired,
  subcategories: PropTypes.array,
  facilityOptions: PropTypes.array.isRequired,
  retailFacilityOptions: PropTypes.array.isRequired,
  subCategoryMapErrors: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]).isRequired,
  integrationState: PropTypes.object.isRequired,
  isConnectsActive: PropTypes.bool,
  medicatedWeightRequired: PropTypes.bool,
  productNetWeightRequired: PropTypes.bool,
  isPrepackWithInventory: PropTypes.bool,
  isMasterFacility: PropTypes.bool,
  isWeedmapsIntegrator: PropTypes.bool.isRequired,
  weedmapsCategoryMappings: PropTypes.array,
  facilityState: PropTypes.string,
  onNavigate: PropTypes.func,
  canUserManageReservationAndIsAllowedInComplianceSettings: PropTypes.bool,
  isWaInventoryMapped: PropTypes.bool,
  waInventoryMapping: PropTypes.array,
  strainRequired: PropTypes.bool.isRequired,
  isHempFacility: PropTypes.bool,
  isPaLeaf: PropTypes.bool,
  isChild: PropTypes.bool
};

const WrappedProductForm = reduxForm({ validate })(ProductForm);

function mapStateToProps(state, ownProps) {
  const { form, initialValues, pricingGroupOptions, pricingClasses } = ownProps;
  const formValues = getFormValues(form)(state) || initialValues;
  const pricingGroupsByCategory = formValues.category_id
    ? pricingGroupOptions.filter((pGO) => pGO.category_id === formValues.category_id)
    : pricingGroupOptions;
  const filteredPricingGroup = formValues.default_uom
    ? pricingGroupsByCategory.filter((pg) => pg.uom === formValues.default_uom)
    : [];
  //if the IM is a child we only want to allow them to change the item ID
  //CBYTES-3013
  const isChild = initialValues.parent_id > 0;
  return {
    form,
    formValues,
    pricingClasses,
    filteredPricingGroupOptions: filteredPricingGroup.sort(AlphaBeticalCaseInsensitive),
    integrationState: getIntegrationState(state),
    medicatedWeightRequired: isMedicatedWeightRequired(state),
    productNetWeightRequired: isProductNetWeightRequired(state),
    isWeedmapsIntegrator: isWeedmapsIntegrator(state),
    canUserManageReservationAndIsAllowedInComplianceSettings: canUserManageReservationAndIsAllowedInComplianceSettings(
      state,
      { permissions: [permissions.manage_reservations] }
    ),
    isHempFacility: getIsHempFacility(state),
    isPaLeaf: isLeafPaConfigPackClosedLoopFacility(state),
    isChild
  };
}

export default connect(mapStateToProps)(WrappedProductForm);
