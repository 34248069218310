import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {bindActionCreators} from 'redux';
import { JsonView } from 'json-view-for-react';
import {connect} from 'react-redux';
import {goBack} from 'react-router-redux';
import {getItem, getData, postItem} from '../../../actions/apiActions';
import {unsetItem} from '../../../actions/itemActions';
import * as itemNames  from '../../../constants/itemNames';
import * as dataNames from '../../../constants/dataNames';
import {convertDbDateTimeToFormInputDateTime} from '../../../util/dateHelpers';
import {isValidJSON} from '../../../util/jsonHelpers';
import {getTransactionDetailsForDisplay} from '../../../selectors/transactionSelectors';
import FormWrapper from '../../common/form/FormWrapper';
import ModalWrapper from '../../common/ModalWrapper';

export class TransactionDetailsPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.redirect = this.redirect.bind(this);
    this.applyCorrectiveAction = this.applyCorrectiveAction.bind(this);
    this.onHideConfirmationModal = this.onHideConfirmationModal.bind(this);
    this.getCorrectiveActions = this.getCorrectiveActions.bind(this);
    this.state = {
      showConfirmationModal: false,
      applyAction: false,
      actionId: 0, // Set previous to confirmation display to be available to invoke applyCorrectiveAction on yes
      jobHistoryId: 0,
    };
  }

  componentWillMount() {
    this.props.actions.unsetItem(itemNames.transaction);
    this.props.actions.getItem(`/api/transactions/${this.props.params.id}`, itemNames.transaction)
      .then((transaction) => {
        this.setState({jobHistoryId: transaction.job_history_id}, () => {
          this.getCorrectiveActions();
        });
      });
  }

  getCorrectiveActions(){
    if(!this.state.jobHistoryId) return false;
    this.props.actions.getData('/api/integration/corrective-actions', dataNames.correctiveActions, null, {job_history_id: this.state.jobHistoryId, detailed: 1});
  }

  applyCorrectiveAction(actionId){
    if(!this.state.applyAction) {
      this.setState({showConfirmationModal: true, actionId});
      return false;
    }
    const messages = {success: 'integration.transactions.details.messages.success', failed: 'integration.transactions.details.messages.failed'};
    this.props.actions.postItem(`/api/integration/corrective-actions/${actionId}/execute`, null, 'noOp', messages)
      .then(() => {
        this.setState({applyAction: false});
        this.getCorrectiveActions();
      }).catch(() => {
        this.setState({applyAction: false});
      });
  }

  onHideConfirmationModal(event){
    this.setState({showConfirmationModal: false, applyAction: event.buttonClicked === 'okay'}, () => {
      if(event.buttonClicked === 'okay') this.applyCorrectiveAction(this.state.actionId);
    });
  }

  redirect() {
    this.props.actions.goBack();
  }

  render() {
    const  {transaction} = this.props;
    return (
      <FormWrapper
        className='API-transactions'
        title={'integration.transactions.details.title'}
        goBack={this.redirect} >
        <ModalWrapper
          version={2}
          title={I18n.t('common.confirm')}
          dialogClassName='modal-sm'
          showModal={this.state.showConfirmationModal}
          onHide={this.onHideConfirmationModal}
          headerClass='bg-warning'
          cancelButton={{show: true, text: I18n.t('common.no')}}
          okayButton={{show: true, text: I18n.t('common.yes')}}
        >
          {I18n.t('integration.transactions.details.confirmAction')}
        </ModalWrapper>
        {transaction && transaction.id ?
          <div style={{width: '100%'}}>
            <div className='transaction-details'>
              <p>{I18n.t('integration.transactions.number')} : {transaction.id}</p>
              <p>{I18n.t('integration.transactions.date')} : {transaction.date}</p>
              <p>{I18n.t('integration.transactions.api')} : {transaction.api_call}</p>
              <p>{I18n.t('integration.transactions.response')} : <span className={transaction.success !== 1 ? 'transactions-error' : 'transactions-success'}>{transaction.response}</span></p>
            </div>
            <div style={{height: '20px', clear: 'both'}} />
            <div style={{fontWeight: 'bold'}}>{I18n.t('integration.transactions.details.dataSent')}</div>
            {
              transaction.request_body && Object.keys(transaction.request_body).length > 0
                ? <JsonView
                  obj={transaction.request_body}
                />
                : I18n.t('integration.transactions.details.noRequestData')
            }
            <div style={{height: '12px', clear: 'both'}} />
            <div style={{fontWeight: 'bold'}}>{I18n.t('integration.transactions.details.dataReceived')}</div>
            {
              transaction.response_body && Object.keys(transaction.response_body).length > 0 && isValidJSON(transaction.response_body)
                ? <JsonView
                obj={transaction.response_body}
              />
                : isValidJSON(transaction.response_body) ? I18n.t('integration.transactions.details.noResponseData') : I18n.t('integration.transactions.details.noParsedResponseData')
            }
            <div style={{height: '12px', clear: 'both'}} />
            {
              transaction.success !== 1 && this.props.correctiveActions && this.props.correctiveActions.length > 0
                ? (<div><strong>{I18n.t('integration.transactions.details.correctiveActions')}</strong>
                <div className='list-group'>
                <div style={{fontSize: 'smaller'}} className='text-muted'>{I18n.t('integration.transactions.details.correctiveActionsHint')}</div>
                {this.props.correctiveActions.map((action, index) => {
                  return (<div className='list-group-item' key={index}>
                    <div style={{fontWeight: 'bold'}}>{action.name}</div>
                    <div><em>{action.description}</em></div>
                    <div>
                      {
                        action.attempted_at && action.attempted_at !== null
                          ? <div className='text-warning' style={{fontWeight: 'bold'}}>{I18n.t('integration.transactions.details.appliedActionOn')} {convertDbDateTimeToFormInputDateTime(action.attempted_at, this.props.timezone)}</div>
                          : (<a
                          href
                          style={{cursor: 'pointer'}}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.applyCorrectiveAction(action.id);
                          }}
                        >
                          {I18n.t('integration.transactions.details.applyAction')}
                        </a>)
                      }
                    </div>
                  </div>);
                })}
              </div></div>)
                : transaction.success !== 1
                ? <div>{I18n.t('integration.transactions.details.noCorrectiveActions')}</div>
                : <div>{I18n.t('integration.transactions.details.noCorrectiveActionsRequired')}</div>
            }
          </div> : null}
      </FormWrapper>
    );
  }
}

TransactionDetailsPage.propTypes = {
  actions: PropTypes.shape({
    getItem: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired
  }),
  params: PropTypes.shape({
    id: PropTypes.string
  }),
  transaction: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    transaction: getTransactionDetailsForDisplay(state),
    timezone: state.timezone,
    correctiveActions: state[dataNames.correctiveActions],
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {getItem, unsetItem, goBack, getData, postItem};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionDetailsPage);
