import {change, formValueSelector} from 'redux-form';
import get from 'lodash.get';
import {ASSEMBLY_FORM} from '../../constants/forms';
import {isFormChangeAction} from './utils';
import {getAssemblyOutputs, getIngredientsWithCosts} from '../../selectors/assembliesSelectors';
import {convertCostByUOM} from '../../util/uomHelpers';
import * as messageTypes from '../../constants/messageTypes';
import { addMessage } from '../../actions/systemActions';


const assembliesFormMiddleware = store => next => action => {
  const result = next(action);
  const {meta, payload} = action;
  if (isFormChangeAction(action, [ASSEMBLY_FORM])) {

    const ingredients = getAssemblyOutputs(store.getState());

    const selector = formValueSelector(ASSEMBLY_FORM);
    const getFormValue = (...names) => selector(store.getState(), ...names);

    const getUomForItemMaster = (ingredientId) => {
      if(!ingredientId) return null;
      const ingredient = ingredients.find((ingredient) => ingredient.id === ingredientId);
      return get(ingredient, 'default_uom', null);
    };

    if(get(meta, 'field') === 'item_master_id'){
      const uom = getUomForItemMaster(payload);
      if(uom) {
        store.dispatch(change(meta.form, 'uom_display', uom));
      }
    }

    /*
      Input Ingredients line items
     */
    const displayLineItemCostWarning = (ingredient, quantity, uom) => {
      quantity = parseFloat(quantity);

      if (quantity <= 0 || isNaN(quantity) || !ingredient || !ingredient.cost) return 0;
      const cost = ingredient
        ? convertCostByUOM(quantity, ingredient.cost, ingredient.default_uom, uom)
        : 0;
      if (cost > 0.005) return cost;
      store.dispatch(addMessage(messageTypes.warning, 'ei.assemblies.form.lessThenCent'));
      return cost;
    };

    const changeQty = (qty, prefix, ingredients) => {
      const id = getFormValue(`${prefix}.item_master_id`);
      const ingredient = ingredients.find(i => i.id === id);
      const uom = getFormValue(`${prefix}.uom`);
      const cost = displayLineItemCostWarning(ingredient, qty, uom);

      store.dispatch(change(meta.form, `${prefix}.cost`, cost));
    };


    const changeUom = (uom, prefix, ingredients) => {
      const id = getFormValue(`${prefix}.item_master_id`);
      const ingredient = ingredients.find(i => i.id === id);
      const qty = getFormValue(`${prefix}.qty`);
      const cost = displayLineItemCostWarning(ingredient, qty, uom);
      store.dispatch(change(meta.form, `${prefix}.cost`, cost));
    };


    const changeIngredient = (value, prefix, ingredients) => {
      const ingredient = ingredients.find(i => i.id === value);
      store.dispatch(change(meta.form, `${prefix}.uom`, ingredient && ingredient.default_uom || ''));
      store.dispatch(change(meta.form, `${prefix}.qty`, ingredient ? '0' : ''));
    };

    /**
     * qty change
     */
    let match = get(meta, 'field').match(/(ingredients\[\d*\])\.qty/);
    if (match && match.length > 1) {
      const prefix = match[1];
      const ingredients =  getIngredientsWithCosts(store.getState());
      changeQty(payload, prefix, ingredients);
    }

    /**
     * ingredient change
     */
    match = get(meta, 'field').match(/(ingredients\[\d*\])\.item_master_id/);
    if (match && match.length > 1) {
      const prefix = match[1];
      const ingredients =  getIngredientsWithCosts(store.getState());
      changeIngredient(payload, prefix, ingredients);
    }

    /**
     * uom change
     */
    match = get(meta, 'field').match(/(ingredients\[\d*\])\.uom/);
    if (match && match.length > 1) {
      const prefix = match[1];
      const ingredients =  getIngredientsWithCosts(store.getState());
      changeUom(payload, prefix, ingredients);
    }

  }
  return result;
};

export default assembliesFormMiddleware;
