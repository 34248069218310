/* eslint-disable import/no-named-as-default */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';

import {unsetItem} from '../../actions/itemActions';
import * as apiActions from '../../actions/apiActions';
import * as itemNames from '../../constants/itemNames';
import {setCredit, setCheck, setCash, setDebit, setGiftCard, setStoreCredit, resetCart} from '../../actions/cartActions';
import {getAmountDue, getChangeDue, getAmountTendered} from '../../selectors/cartSelectors';
import {getPaymentOptions} from '../../selectors/salesSettingsSelectors';
import Payment from './Payment';

export const ReduxPayment = ({removeFromCustomers, unSetCustomer, customer, resetCart, currentOrder, ...props}, context) => {
  const completeOrder = (payments) => {
    unsetItem(itemNames.customer);
    resetCart();
    const url = ( customer.order_type === undefined) ? 'in_store' : customer.order_type;
    this.props.actions.push(`/queue/${url}`);
  };
  return (<Payment  {...props} completeOrder={completeOrder}/>);
};

ReduxPayment.propTypes = {
  removeFromCustomers: PropTypes.func.isRequired,
  unSetCustomer: PropTypes.func.isRequired,
  resetCart: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
  currentOrder: PropTypes.object.isRequired
};

// ReduxPayment.contextTypes = {
//   router: React.PropTypes.object.isRequired
// };

function mapStateToProps(state, ownProps){
  const {credit, check, cash, debit, giftCard, storeCredit} = state.cart.payment;
  const paymentOptions = getPaymentOptions(state);
  return {
    credit,
    check,
    cash,
    debit,
    giftCard,
    storeCredit,
    currentOrder: state.cart.currentOrder,
    customer: state.customer,
    amountTendered: getAmountTendered(state.cart, {paymentOptions}),
    amountDue: getAmountDue(state.cart),
    changeDue: getChangeDue(state.cart)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    setCredit,
    setCheck,
    setCash,
    setDebit,
    setGiftCard,
    setStoreCredit,
    unsetItem,
    resetCart,
    push,
    apiActions
  };
  return bindActionCreators(actions, dispatch);
}

export default  connect(mapStateToProps, mapDispatchToProps)(ReduxPayment);
