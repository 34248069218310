import moment from 'moment';
import {I18n} from 'react-redux-i18n';

import {notEmptyArrayValidation, requiredFieldValidation} from '../common/form/redux-form/validations';

export const validate = (values) => {
  const errors = {
    selectExportFormat: requiredFieldValidation(values.selectExportFormat),
    exportStartDate: requiredFieldValidation(values.exportStartDate),
    exportEndDate: requiredFieldValidation(values.exportEndDate),
    export_locations: notEmptyArrayValidation(values.export_locations),
    export_types: notEmptyArrayValidation(values.export_types),
  };

  if (values.exportStartDate && !moment(values.exportStartDate, 'MM/DD/YYYY').isValid()) {
    errors.exportStartDate = I18n.t('tracking.receiveTrackingIds.form.dateReceivedError');
  }
  if (values.exportEndDate && !moment(values.exportEndDate, 'MM/DD/YYYY').isValid()) {
    errors.exportEndDate = I18n.t('tracking.receiveTrackingIds.form.dateReceivedError');
  }

  return errors;
};



export default validate;
