import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {reduxForm} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';

import ReduxMetrcIdField from '../../common/form/redux-form/ReduxMetrcIdField';
import SubmitSection from '../../common/form/SubmitSection';

export const ActivateDeactivateTrackingIdsForm = (props) => {
  const {
    pristine,
    submitting,
    invalid,
    handleSubmit,
    trackingIds,
    change
  } = props;

  const submitSettings = {
    actionSettings: {
      updateTrackingTagGroup: {
        text: I18n.t('tracking.activateDeactivateIds.form.updateTrackingTagGroupTypeButton'),
        submitting,
        type: 'button',
        action: () => props.handleTrackingTagGroupSave()
      },
      activate: {
        text: I18n.t('tracking.activateDeactivateIds.form.activate'),
        submitting,
        invalid,
        pristine,
        type: 'submit',
        action: () => change('action', 'activate')
      },
      deactivate: {
        text: I18n.t('tracking.activateDeactivateIds.form.deactivate'),
        submitting,
        invalid,
        pristine,
        type: 'submit',
        action: () => change('action', 'deactivate')
      },
    }
  };

  return (
    <form className='integration-settings' onSubmit={handleSubmit} noValidate={true}>
      <Row>
        <Col xs={6} sm={5} md={3}>
          <ReduxMetrcIdField
            name='starting_tag'
            props={{
              label: I18n.t('tracking.activateDeactivateIds.form.beginningId'),
              options: trackingIds
            }}
          />
        </Col>
        <Col xs={6} sm={5} md={3}>
          <ReduxMetrcIdField
            name='ending_tag'
            props={{
              label: I18n.t('tracking.activateDeactivateIds.form.endingId'),
              options: trackingIds,
              isRequired: false
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6} md={2}>
          <div className='form-input form-group'>
            <label className='control-label'>{I18n.t('tracking.activateDeactivateIds.form.trackingTagGroupTypeLabel')}</label>
            <Select
              name='type'
              options={[
                {label: I18n.t('tracking.receiveTrackingIds.form.packageType'), value: 'package'},
                {label: I18n.t('tracking.receiveTrackingIds.form.plantType'), value: 'plant'}
              ]}
              value={props.trackingTagGroupType}
              onChange={(event) => props.handleTrackingTagGroupTypeChange(event.value)}
            />
          </div>
        </Col>
      </Row>
      <SubmitSection settings={submitSettings}/>
    </form>
  );
};

ActivateDeactivateTrackingIdsForm.propTypes = {
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  trackingIds: PropTypes.array.isRequired,
  trackingTagGroupType: PropTypes.string,
  handleTrackingTagGroupTypeChange: PropTypes.func,
};

export default reduxForm({form: 'activateDeactivateTrackingIdsForm'})(ActivateDeactivateTrackingIdsForm);
