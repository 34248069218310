export const WATER_ACTIVITY_DEFAULT_DIMENSIONS = [
  {name: 'cultivation.testingCompliance.waterActivity.moistureContent', key: 'moisture_content_percent', unit: 'common.units.percent'},
  {name: 'cultivation.testingCompliance.waterActivity.moistureContentWaterActivity', key: 'moisture_content_water_activity_rate', unit: 'common.units.percent'},
];

export const CANNABINOID_POTENCY_DEFAULT_DIMENSIONS = [
  {name: 'cultivation.testingCompliance.cannabinoidPotency.thc', key: 'thc'},
  {name: 'cultivation.testingCompliance.cannabinoidPotency.thca', key: 'thca'},
  {name: 'cultivation.testingCompliance.cannabinoidPotency.thcv', key: 'thcv'},
  {name: 'cultivation.testingCompliance.cannabinoidPotency.cbd', key: 'cbd'},
  {name: 'cultivation.testingCompliance.cannabinoidPotency.cbda', key: 'cbda'},
  {name: 'cultivation.testingCompliance.cannabinoidPotency.cbdv', key: 'cbdv'},
  {name: 'cultivation.testingCompliance.cannabinoidPotency.cbn', key: 'cbn'},
  {name: 'cultivation.testingCompliance.cannabinoidPotency.cbg', key: 'cbg'},
  {name: 'cultivation.testingCompliance.cannabinoidPotency.cbc', key: 'cbc'},
  {name: 'cultivation.testingCompliance.cannabinoidPotency.cbl', key: 'cbl'},
  {name: 'cultivation.testingCompliance.cannabinoidPotency.cbv', key: 'cbv'},
  {name: 'cultivation.testingCompliance.cannabinoidPotency.cbcv', key: 'cbcv'},
  {name: 'cultivation.testingCompliance.cannabinoidPotency.cbgv', key: 'cbgv'},
  {name: 'cultivation.testingCompliance.cannabinoidPotency.cbgm', key: 'cbgm'},
  {name: 'cultivation.testingCompliance.cannabinoidPotency.cbga', key: 'cbga'},
  {name: 'cultivation.testingCompliance.cannabinoidPotency.d8thc', key: 'd8_thc'},
  {name: 'cultivation.testingCompliance.cannabinoidPotency.d9thc', key: 'd9_thc'},
  {name: 'cultivation.testingCompliance.cannabinoidPotency.d9thca', key: 'd9_thca'},
  {name: 'cultivation.testingCompliance.cannabinoidPotency.hcp', key: 'hcp'},
  {name: 'cultivation.testingCompliance.cannabinoidPotency.cbdp', key: 'cbdp'},
  {name: 'cultivation.testingCompliance.cannabinoidPotency.total_cb_profile', key: 'total_cb_profile'},
];

export const TOTAL_TERPENE_KEY = 'total_terpene';
export const TERPENE_PROFILE_DEFAULT_DIMENSIONS = [
  {name: 'cultivation.testingCompliance.terpeneProfile.bisabolol', key: 'bisabolol'},
  {name: 'cultivation.testingCompliance.terpeneProfile.humulene', key: 'humulene'},
  {name: 'cultivation.testingCompliance.terpeneProfile.pinene', key: 'pinene'},
  {name: 'cultivation.testingCompliance.terpeneProfile.terpinolene', key: 'terpinolene'},
  {name: 'cultivation.testingCompliance.terpeneProfile.bCaryophyllene', key: 'b_caryophyllene'},
  {name: 'cultivation.testingCompliance.terpeneProfile.bMyrcene', key: 'b_myrcene'},
  {name: 'cultivation.testingCompliance.terpeneProfile.bpinene', key: 'b_pinene'},
  {name: 'cultivation.testingCompliance.terpeneProfile.caryophylleneOxide', key: 'caryophyllene_oxide'},
  {name: 'cultivation.testingCompliance.terpeneProfile.limonene', key: 'limonene'},
  {name: 'cultivation.testingCompliance.terpeneProfile.linalool', key: 'linalool'},
  {name: 'cultivation.testingCompliance.terpeneProfile.geraniol', key: 'geraniol'},
  {name: 'cultivation.testingCompliance.terpeneProfile.trans_nerolidol', key: 'trans_nerolidal'},
  {name: 'cultivation.testingCompliance.terpeneProfile.guaiol', key: 'guaiol'},
  {name: 'cultivation.testingCompliance.terpeneProfile.isopulegol', key: 'isopulegol'},
  {name: 'cultivation.testingCompliance.terpeneProfile.camphene', key: 'camphene'},
  {name: 'cultivation.testingCompliance.terpeneProfile.carene', key: 'carene'},
  {name: 'cultivation.testingCompliance.terpeneProfile.y_terpinene', key: 'y_terpinene'},
  {name: 'cultivation.testingCompliance.terpeneProfile.a_terpinene', key: 'a_terpinene'},
  {name: 'cultivation.testingCompliance.terpeneProfile.eucalyptol', key: 'eucalyptol'},
  {name: 'cultivation.testingCompliance.terpeneProfile.ocimene', key: 'ocimene'},
  {name: 'cultivation.testingCompliance.terpeneProfile.p_cymene', key: 'p_cymene'},
  {name: 'cultivation.testingCompliance.terpeneProfile.total', key: TOTAL_TERPENE_KEY},
];

export const MICROBIAL_DEFAULT_DIMENSIONS = [
  {name: 'cultivation.testingCompliance.microbials.totalColiforms', key: 'total_coliforms_cfu_g', unit: 'common.units.cfug'},
  {name: 'cultivation.testingCompliance.microbials.totalAerobicBacteria', key: 'total_aerobic_bacteria_cfu_g', unit: 'common.units.cfug'},
  {name: 'cultivation.testingCompliance.microbials.gramNegativeBacteria', key: 'gram_negative_bacteria_cfu_g', unit: 'common.units.cfug'},
  {name: 'cultivation.testingCompliance.microbials.yeast', key: 'yeast_cfu_g', unit: 'common.units.cfug'},
  {name: 'cultivation.testingCompliance.microbials.mold', key: 'mold_cfu_g', unit: 'common.units.cfug'},
  {name: 'cultivation.testingCompliance.microbials.yeastMold', key: 'yeast_mold_cfu_g', unit: 'common.units.cfug'},
  {name: 'cultivation.testingCompliance.microbials.fecalContamination', key: 'fecal_contamination_cfu_g', unit: 'common.units.cfug'},
  {name: 'cultivation.testingCompliance.microbials.insectParts', key: 'insect_parts_cfu_g', unit: 'common.units.cfug'},
  {name: 'cultivation.testingCompliance.microbials.animalParts', key: 'animal_parts_cfu_g', unit: 'common.units.cfug'},
  {name: 'cultivation.testingCompliance.microbials.sclerotiniaSclerotiorum', key: 'sclerotinia_sclerotiorum_cfu_g', unit: 'common.units.cfug'},
  {name: 'cultivation.testingCompliance.microbials.epicoccumNigrum', key: 'epicoccum_nigrum_cfu_g', unit: 'common.units.cfug'},
  {name: 'cultivation.testingCompliance.microbials.cladosporiumHerbarum', key: 'cladosporium_herbarum_cfu_g', unit: 'common.units.cfug'},
  {name: 'cultivation.testingCompliance.microbials.botrytisCinerea', key: 'botrytis_cinerea_cfu_g', unit: 'common.units.cfug'},
  {name: 'cultivation.testingCompliance.microbials.aspergillusFumigatus', key: 'aspergillus_fumigatus_cfu_g', unit: 'common.units.cfug'},
  {name: 'cultivation.testingCompliance.microbials.aspergillusFlavus', key: 'aspergillus_flavus_cfu_g', unit: 'common.units.cfug'},
  {name: 'cultivation.testingCompliance.microbials.aspergillusNiger', key: 'aspergillus_niger_cfu_g', unit: 'common.units.cfug'},
  {name: 'cultivation.testingCompliance.microbials.aspergillusTerreus', key: 'aspergillus_terreus_cfu_g', unit: 'common.units.cfug'},
  {name: 'cultivation.testingCompliance.microbials.pAeruginosa', key: 'p_aeruginosa_cfu_g', unit: 'common.units.cfug'},
  {name: 'cultivation.testingCompliance.microbials.aspergillusSpp', key: 'aspergillus_spp_cfu_g', unit: 'common.units.cfug'},
  {name: 'cultivation.testingCompliance.microbials.sAureus', key: 's_aurenus_cfu_g', unit: 'common.units.cfug'},
  {name: 'cultivation.testingCompliance.microbials.coli', key: 'coli_cfu_g', unit: 'common.units.cfug'},
  {name: 'cultivation.testingCompliance.microbials.salmonella', key: 'salmonella_cfu_g', unit: 'common.units.cfug'},
  {name: 'cultivation.testingCompliance.microbials.coli_and_salmonella', key: 'coli_and_salmonella_cfu_g', unit: 'common.units.cfug'},
  {name: 'cultivation.testingCompliance.microbials.stec', key: 'stec_cfu_g', unit: 'common.units.cfug'},
];

export const MYCOTOXIN_DEFAULT_DIMENSIONS = [
  {name: 'cultivation.testingCompliance.mycotoxins.aflaToxins', key: 'aflatoxins_ppb', unit: 'common.units.ppb'},
  {name: 'cultivation.testingCompliance.mycotoxins.ochraToxin', key: 'ochratoxin_ppb', unit: 'common.units.ppb'},
  {name: 'cultivation.testingCompliance.mycotoxins.citrinin', key: 'citrinin_ppb', unit: 'common.units.ppb'},
  {name: 'cultivation.testingCompliance.mycotoxins.pptulin', key: 'patulin_ppb', unit: 'common.units.ppb'},
  {name: 'cultivation.testingCompliance.mycotoxins.ergotAlkaloids', key: 'ergotalkaloids_ppb', unit: 'common.units.ppb'},
  {name: 'cultivation.testingCompliance.mycotoxins.fusarim', key: 'fusarim_ppb', unit: 'common.units.ppb'},
  {name: 'cultivation.testingCompliance.mycotoxins.alfatoxinB1', key: 'aflatoxin_b1_ppb', unit: 'common.units.ppb'},
  {name: 'cultivation.testingCompliance.mycotoxins.alfatoxinB2', key: 'aflatoxin_b2_ppb', unit: 'common.units.ppb'},
  {name: 'cultivation.testingCompliance.mycotoxins.alfatoxinG1', key: 'aflatoxin_g1_ppb', unit: 'common.units.ppb'},
  {name: 'cultivation.testingCompliance.mycotoxins.alfatoxinG2', key: 'aflatoxin_g2_ppb', unit: 'common.units.ppb'},
  {name: 'cultivation.testingCompliance.mycotoxins.ochratoxinA', key: 'ochratoxin_a_ppb', unit: 'common.units.ppb'},
  {name: 'cultivation.testingCompliance.mycotoxins.total_mycotoxins', key: 'total_mycotoxins', unit: 'common.units.ppb'},
];

export const HEAVY_METALS_DEFAULT_DIMENSIONS = [
  {name: 'cultivation.testingCompliance.heavyMetals.arsenic', key: 'arsenic_ppm', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.heavyMetals.cadmium', key: 'cadmium_ppm', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.heavyMetals.lead', key: 'lead_ppm', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.heavyMetals.mercury', key: 'mercury_ppm', unit: 'common.units.ppm'},
];

export const PESTICIDES_DEFAULT_DIMENSIONS = [
  { name: 'cultivation.testingCompliance.pesticides.abamectin', key: 'abamectin_ppm', unit: 'common.units.ppm' },
  { name: 'cultivation.testingCompliance.pesticides.acequinocyl', key: 'acequinocyl_ppm', unit: 'common.units.ppm' },
  { name: 'cultivation.testingCompliance.pesticides.bifenazate', key: 'bifenazate_ppm', unit: 'common.units.ppm' },
  { name: 'cultivation.testingCompliance.pesticides.befentrin', key: 'befentrin_ppm', unit: 'common.units.ppm' },
  { name: 'cultivation.testingCompliance.pesticides.captan', key: 'captan_ppm', unit: 'common.units.ppm' },
  { name: 'cultivation.testingCompliance.pesticides.cypermethrin', key: 'cypermethrin_ppm', unit: 'common.units.ppm' },
  { name: 'cultivation.testingCompliance.pesticides.cyfluthrin', key: 'cyfluthrin_ppm', unit: 'common.units.ppm' },
  { name: 'cultivation.testingCompliance.pesticides.dimethomorph', key: 'dimethomorph_ppm', unit: 'common.units.ppm' },
  { name: 'cultivation.testingCompliance.pesticides.etoxazole', key: 'etoxazole_ppm', unit: 'common.units.ppm' },
  { name: 'cultivation.testingCompliance.pesticides.fenhexamid', key: 'fenhexamid_ppm', unit: 'common.units.ppm' },
  { name: 'cultivation.testingCompliance.pesticides.flonicamid', key: 'flonicamid_ppm', unit: 'common.units.ppm' },
  { name: 'cultivation.testingCompliance.pesticides.fludioxonil', key: 'fludioxonil_ppm', unit: 'common.units.ppm' },
  { name: 'cultivation.testingCompliance.pesticides.imidacloprid', key: 'imidacloprid_ppm', unit: 'common.units.ppm' },
  { name: 'cultivation.testingCompliance.pesticides.myclobutanil', key: 'myclobutanil_ppm', unit: 'common.units.ppm' },
  { name: 'cultivation.testingCompliance.pesticides.pcnb', key: 'pcnb_ppm', unit: 'common.units.ppm' },
  { name: 'cultivation.testingCompliance.pesticides.piperonylButoxide', key: 'piperonyl_butoxide_ppm',unit: 'common.units.ppm'},
  { name: 'cultivation.testingCompliance.pesticides.pyrethrin', key: 'pyrethrin_ppm', unit: 'common.units.ppm' },
  { name: 'cultivation.testingCompliance.pesticides.spinetoram', key: 'spinetoram_ppm', unit: 'common.units.ppm' },
  { name: 'cultivation.testingCompliance.pesticides.spinosad', key: 'spinosad_ppm', unit: 'common.units.ppm' },
  { name: 'cultivation.testingCompliance.pesticides.spiromesifen', key: 'spiromesifen', unit: 'common.units.ppm' },
  { name: 'cultivation.testingCompliance.pesticides.spirotetramat', key: 'spirotetramat_ppm', unit: 'common.units.ppm' },
  { name: 'cultivation.testingCompliance.pesticides.thiamethoxam', key: 'thiamethoxam_ppm', unit: 'common.units.ppm' },
  { name: 'cultivation.testingCompliance.pesticides.trifloxystrobin', key: 'trifloxystrobin_ppm', unit: 'common.units.ppm' }
];

export const GMP_PESTICIDES_DEFAULT_DIMENSIONS = [
  {name: 'cultivation.testingCompliance.pesticides.acephate', key: 'acephate_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.alachlor', key: 'alachlor_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.aldrinAndDieldrin', key: 'aldrin_dieldrin_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.azinphos-Ethyl', key: 'azinphos_ethyl_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.azinphos-Methyl', key: 'azinphos_methyl_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.bromideInorganic', key: 'bromide_inorganic_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.bromophos-Ethyl', key: 'bromophos_ethyl_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.bromophos-Methyl', key: 'bromophos_methyl_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.brompropylate', key: 'brompropylate_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.chlordane', key: 'chlordane_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.chlorfenvinphos', key: 'chlorfenvinphos_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.chlorpyriphos-Ethyl', key: 'chlorpyriphos_ethyl_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.chlorpyriphos-Methyl', key: 'chlorpyriphos_methyl_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.chlorthal-Dimethyl', key: 'chlorthal_dimethyl_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.lambda-Cyhalothrin', key: 'lambda_cyhalothrin_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.ddt', key: 'ddt_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.deltamethrin', key: 'deltamethrin_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.diazinon', key: 'diazinon_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.dichlofluanid', key: 'dichlofluanid_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.dichlorvos', key: 'dichlorvos_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.dicofol', key: 'dicofol_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.dimethoate', key: 'dimethoate_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.omethoate', key: 'omethoate_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.dithiocarbamates', key: 'dithiocarbamates_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.endosulfan', key: 'endosulfan_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.endrin', key: 'endrin_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.ethion', key: 'ethion_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.etrimphos', key: 'etrimphos_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.fenchlorophos', key: 'fenchlorophos_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.fenitrothion', key: 'fenitrothion_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.fenpropathrin', key: 'fenpropathrin_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.fensulfothion', key: 'fensulfothion_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.fenthion', key: 'fenthion_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.fenvalerate', key: 'fenvalerate_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.flucytrinate', key: 'flucytrinate_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.tau-Fluvalinate', key: 'tau_fluvalinate_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.fonophos', key: 'fonophos_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.heptachlor', key: 'heptachlor_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.hexachlorbenzene', key: 'hexachlorbenzene_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.hexachlorocyclohexane', key: 'hexachlorocyclohexane_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.lindan', key: 'lindan_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.malathionAndMalaoxon', key: 'malathion_malaoxon_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.mecarbam', key: 'mecarbam_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.methacriphos', key: 'methacriphos_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.methamidophos', key: 'methamidophos_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.methidathion', key: 'methidathion_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.methoxychlor', key: 'methoxychlor_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.mirex', key: 'mirex_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.monocrotophos', key: 'monocrotophos_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.parathion-EthylAndParaoxon-Ethyl', key: 'parathion_ethyl_paraoxon_ethyl_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.parathion-MethylAndParaoxon-Methyl', key: 'parathion_methyl_paraoxon_methyl_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.pendimethalin', key: 'pendimethalin_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.pentachloranisol', key: 'pentachloranisol_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.permethrinAndIsomers', key: 'permethrin_isomers_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.phosalone', key: 'phosalone_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.phosmet', key: 'phosmet_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.pirimiphos-Ethyl', key: 'pirimiphos_ethyl_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.pirimiphos-Methyl', key: 'pirimiphos_methyl_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.procymidone', key: 'procymidone_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.profenophos', key: 'profenophos_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.prothiophos', key: 'prothiophos_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.pyrethrum', key: 'pyrethrum_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.quinalphos', key: 'quinalphos_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.quintozene', key: 'quintozene_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.s-421', key: 's_421_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.tecnazene', key: 'tecnazene_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.tetradifon', key: 'tetradifon_mg_kg', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.pesticides.vinclozolin', key: 'vinclozolin_mg_kg', unit: 'common.units.ppm'}
];

export const RESIDUAL_SOLVENTS_DEFAULT_DIMENSIONS = [
  {name: 'cultivation.testingCompliance.residualSolvents.acetone', key: 'acetone_ppm', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.residualSolvents.butanes', key: 'butanes_ppm', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.residualSolvents.ethane', key: 'ethane_ppm', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.residualSolvents.ethanol', key: 'ethanol_ppm', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.residualSolvents.heptaneResidualSolvent', key: 'heptane_ppm', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.residualSolvents.nHexane', key: 'n_hexane_ppm', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.residualSolvents.methane', key: 'methane_ppm', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.residualSolvents.methylbutane_2', key: '2_methylbutane_ppm', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.residualSolvents.methylpropane_2', key: '2_methylpropane_ppm', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.residualSolvents.nPentane', key: 'n_pentane_ppm', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.residualSolvents.propaneResidualSolvent', key: 'propane_ppm', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.residualSolvents.propanol_2', key: '2_propanol_ppm', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.residualSolvents.componentMixInDimethylSulfoxide_5', key: '5_component_mix_in_dimethyl_sulfoxide_ppm', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.residualSolvents.componentMixInDimethylSulfoxide_15', key: '15_component_mix_in_dimethyl_sulfoxide_ppm', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.residualSolvents.componentMixInDimethylSulfoxide_8', key: '8_component_mix_in_dimethyl_sulfoxide_ppm', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.residualSolvents.componentMixInDimethylSulfoxide_24', key: '24_component_mix_in_dimethyl_sulfoxide_ppm', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.residualSolvents.componentMixInDimethylSulfoxide_2', key: '2_component_mix_in_dimethyl_sulfoxide_ppm', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.residualSolvents.pharmResidualSolvent', key: 'pharm_residual_solvent_ppm', unit: 'common.units.ppm'},
  {name: 'cultivation.testingCompliance.residualSolvents.residualSolventTotal', key: 'residual_solvent_total', unit: 'common.units.ppm'},
];

export const FOREIGN_MATERIALS_DEFAULT_DIMENSIONS = [
  {name: 'cultivation.testingCompliance.foreignMaterials.hair', key: 'hair_ea', unit: 'common.units.ea'},
  {name: 'cultivation.testingCompliance.foreignMaterials.insects', key: 'insects_ea', unit: 'common.units.ea'},
  {name: 'cultivation.testingCompliance.foreignMaterials.other', key: 'other_ea', unit: 'common.units.ea'},
  {name: 'cultivation.testingCompliance.foreignMaterials.plant_stems', key: 'plant_stems_percent', unit: 'common.units.percent'},
  {name: 'cultivation.testingCompliance.foreignMaterials.plant_other', key: 'plant_other_percent', unit: 'common.units.percent'},
];

export const THICKENING_AGENTS_DEFAULT_DIMENSIONS = [
  {name: 'cultivation.testingCompliance.thickeningAgents.vitaminEAcetate', key: 'vitamin_e_acetate_percent', unit: 'common.units.percent'},
];

export const CUSTOM_FIELDS_DEFAULT_DIMENSIONS = [
  {name: 'cultivation.testingCompliance.customFields.custom_field_1', key: 'custom_field_1', unit: ''},
  {name: 'cultivation.testingCompliance.customFields.custom_field_2', key: 'custom_field_2', unit: ''},
  {name: 'cultivation.testingCompliance.customFields.custom_field_3', key: 'custom_field_3', unit: ''},
  {name: 'cultivation.testingCompliance.customFields.custom_field_4', key: 'custom_field_4', unit: ''},
  {name: 'cultivation.testingCompliance.customFields.custom_field_5', key: 'custom_field_5', unit: ''},
];

export const BASE_DEFAULT_DIMENSIONS = {
  water_activity: WATER_ACTIVITY_DEFAULT_DIMENSIONS,
  cannabinoid_potency: CANNABINOID_POTENCY_DEFAULT_DIMENSIONS,
  terpene_profile: TERPENE_PROFILE_DEFAULT_DIMENSIONS,
  microbial: MICROBIAL_DEFAULT_DIMENSIONS,
  mycotoxin: MYCOTOXIN_DEFAULT_DIMENSIONS,
  heavy_metals: HEAVY_METALS_DEFAULT_DIMENSIONS,
  pesticides: PESTICIDES_DEFAULT_DIMENSIONS,
  residual_solvents: RESIDUAL_SOLVENTS_DEFAULT_DIMENSIONS,
  foreign_materials: FOREIGN_MATERIALS_DEFAULT_DIMENSIONS,
  custom_fields: CUSTOM_FIELDS_DEFAULT_DIMENSIONS
};
