import round from 'lodash.round';
import {I18n} from 'react-redux-i18n';
import {requiredFieldValidation} from '../../../common/form/redux-form/validations';

const getMmapPaymentsTotal = mmapPayments => mmapPayments.reduce(
  (total, mmapPayment) => {
    let payment_amount = parseFloat(mmapPayment.amount);
    payment_amount = payment_amount < 0 ? 0 : payment_amount;
    return payment_amount ? round(total +payment_amount, 2) : total;
  }, 0);

const validateMmapPayments = (values, props) => {
  const errors = {mmapPayments: []};
  if (Array.isArray(values.mmapPayments)) {
    const mmapPaymentsTotal = getMmapPaymentsTotal(values.mmapPayments);

    if (mmapPaymentsTotal < 0) {
      errors.mmapPayments.push({amount: I18n.t('cultivation.orderHistory.table.mmapPaymentsNegative')});
    }

    values.mmapPayments.forEach(mmapPayment => {
      errors.mmapPayments.push({amount: requiredFieldValidation(mmapPayment.amount)});
    });
  }
  return errors;
};

export default validateMmapPayments;
