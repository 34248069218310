import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {I18n} from 'react-redux-i18n';
import { push, goBack } from 'react-router-redux';
import { getUnpaginatedData, getItem, postItem } from '../../../../actions/apiActions';
import * as dataNames from '../../../../constants/dataNames';
import * as itemNames from '../../../../constants/itemNames';
import FormWrapper from '../../../common/form/FormWrapper';
import ConnectsProductMappingFormWrapper from './ConnectsProductMappingFormWrapper';
import {getInitialValues, buildSubcategoryOptions} from '../../../../selectors/forms/connectsProductMappingFormSelectors';
import {getIntegrationState} from '../../../../selectors/integration/integrationSelectors';


export class ConnectsProductMappingPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.redirect = this.redirect.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {};
  }

  componentWillMount() {

    if(this.props.params.id) {

      this.props.actions.getItem(`/api/partners/partner_connects/${this.props.params.id}/item_master_mapping`, itemNames.connectsPartnerItemMasterMappings);
      this.props.actions.getUnpaginatedData(`/api/partners/partner_connects/${this.props.params.id}/item_masters`, dataNames.connectsPartnerItemMasters);
      this.props.actions.getUnpaginatedData(`/api/item_masters?vendor_id=${this.props.params.id}`, dataNames.itemMasters);
      this.props.actions.getUnpaginatedData('/api/categories', dataNames.categories, {failed: 'categories.get.failed'})
        .then(() => {
          if (this.props.isMetrc) {
            this.props.actions.getUnpaginatedData('/api/metrc/item_categories', dataNames.metrcCategories);
            this.props.actions.getUnpaginatedData('/api/metrc/item_categories/mapping', dataNames.metrcCategoryMappings);
          }
        });
      this.props.actions.getItem(`/api/partners/${this.props.params.id}`, itemNames.partner);
    }
  }


  /**
   * Redirect the user
   */
  redirect(){
    this.props.actions.goBack();
  }


  /**
   * Form submit handling
   * @param form
   */
  onSubmit(form){

    const payload = {
      connect_inherit_item_masters: form.connect_inherit_item_masters,
      mappings: form.partnerProducts.map((product) => {

        const {item_number, link_item_master, item_master_id, subcategory_id, item_master_item_number} = product;
        const mapping = {link_item_master, item_master_id, subcategory_id, item_master_item_number};
        mapping.partner_item_number = item_number;

        return mapping;
      })
    };

    const redirect = () => this.props.actions.push('/partners');

    this.props.actions.postItem(`/api/partners/partner_connects/${this.props.params.id}/item_master_mapping `, payload)
      .then(redirect);
  }

  render () {

    const props = this.props;
    return (
      <div>
        <FormWrapper title={I18n.t('partners.connects.mapProducts.mapPartnerProducts')} goBack={this.redirect}>
          <div>
            <ConnectsProductMappingFormWrapper
              initialValues={props.initialValues}
              partnerProducts={props.partnerProducts}
              itemMasters={props.itemMasters}
              categories={props.categories}
              mappings={props.mappings}
              categoryMappings={props.categoryMappings}
              onSubmit={this.onSubmit}
            />
          </div>
        </FormWrapper>

      </div>
    );
  }
}

ConnectsProductMappingPage.propTypes = {
  partnerProducts: PropTypes.array,
  itemMasters: PropTypes.array,
  categories: PropTypes.array,
  categoryMappings: PropTypes.array,
  isMetrc: PropTypes.bool
};

function mapStateToProps(state){
  const integrationState = getIntegrationState(state);
  const {isMetrc} = integrationState;
  let externalMappings = [];

  //If its METRC we'll show the category names as well
  if (isMetrc) {
    externalMappings = state[dataNames.metrcCategoryMappings];
    externalMappings.map((category_map) => {
      const categoryDetails = state[dataNames.metrcCategories].find(cat => cat.id === category_map.metrc_item_category_id);
      category_map.name = (categoryDetails) ? categoryDetails.name : '';
    });
  }

  return {
    initialValues: getInitialValues(state),
    itemMasters: state[dataNames.itemMasters],
    categories: buildSubcategoryOptions(state[dataNames.categories], externalMappings),
    isMetrc: isMetrc,
    mappings: state[itemNames.connectsPartnerItemMasterMappings]
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {push, goBack, getUnpaginatedData, getItem, postItem};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (ConnectsProductMappingPage);
