import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Button} from 'react-bootstrap';
// import {I18n} from 'react-redux-i18n';
import FacilityGroupsModal from '../../FacilityGroupsModal'; //eslint-disable-line

/***
 * Displays option to manage facility group if the user has permission
 * Displays how the settings are managed if they are under a facility group and user does not have permission
 * Disabled settings form if managed in facility group and user does not have permission
 */

const SettingsIntegration = (props) => {

  const settingsAreManaged = props.facilityGroups.reduce((acc, group) => {
    if(group.controlsSettings) acc = group.name;
    return acc;
  }, false);

  return (
    <div>
      <FacilityGroupsModal
        onHide={props.onHideModal}
        showModal={props.showModal}
        {...Object.assign({}, props, {settingsIntegration: undefined})} />

      {
        !settingsAreManaged && !props.userManagesSettings
          ? null
          : (<div style={{border: '1px solid #ccc', margin: '8px 0px', padding: '5px'}}>
            <Row>
              <Col md={9} style={{padding: '5px 30px 5px 30px'}}>
                <strong>Settings Management: </strong>
                {
                  settingsAreManaged ? settingsAreManaged : 'Self Managed'
                }
                {
                  !settingsAreManaged || settingsAreManaged && props.userManagesSettings
                    ? null
                    : <div>These settings are managed as a group and require that you have organization level management
                    permissions to be edited.</div>
                }
              </Col>
              <Col md={3}>
                {
                  !props.userManagesSettings
                    ? null
                    : <Button
                    variant='primary'
                    block
                    onClick={(e) => {
                      props.onShowModal();
                      e.target.blur();
                    }}
                  >
                    Manage Facility Group Integration
                  </Button>
                }
              </Col>
            </Row>
          </div>)
      }

      <div className={settingsAreManaged && !props.userManagesSettings ? 'disable-contents' : ''}>
        {props.children}
      </div>

    </div>
  );

};

SettingsIntegration.propTypes = {
  facilityGroups: PropTypes.array,
  onShowModal: PropTypes.func,
  onHideModal: PropTypes.func,
  children: PropTypes.object,
  userManagesSettings: PropTypes.bool,
};

export default SettingsIntegration;
