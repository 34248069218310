import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import isEmpty from 'lodash.isempty';
import { bindActionCreators } from 'redux';
import { goBack, push } from 'react-router-redux';
import { getUnpaginatedData, putData } from '../../../../../actions/apiActions';
import { clearSelectedData } from '../../../../../actions/selectedDataActions';
import { handleComplexSelectRow } from '../../../../../actions/helpers/selectedDataHelper';
import * as dataNames from '../../../../../constants/dataNames';
import * as permissions from '../../../../../constants/permissions';
import { getRewardsByStatus } from '../../../../../selectors/rewardsSelectors';
import TablePageWrapper from '../../../../common/grid/TablePageWrapper';
import SingleActionColumn from '../../../../common/grid/columns/SingleActionColumn';
import { unsetData } from '../../../../../actions/dataActions';
import PageTitle from '../../../../common/PageTitle';
import InternationalDecimalStatic from '../../../../common/form/InternationalDecimalStatic';

const allowedTabs = ['active', 'inactive'];

export class RewardsList extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.redirect = this.redirect.bind(this);

    this.state = {};

    this.switchTab = this.switchTab.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.editReward = this.editReward.bind(this);
    this.loadRewards = this.loadRewards.bind(this);

    const fieldsLang = 'retail.rewards.reward.fields';
    const commonColumns = [
      {
        name: I18n.t(`${fieldsLang}.name`),
        dataId: 'name',
        hidden: false,
        width: '120px'
      },
      {
        name: I18n.t(`${fieldsLang}.reward_value`),
        dataId: 'reward_amount',
        hidden: false,
        width: '120px',
        formatter: (cell) => (<InternationalDecimalStatic>{cell}</InternationalDecimalStatic>)
      },
      {
        name: I18n.t(`${fieldsLang}.reward_type`),
        dataId: 'reward_type_string',
        hidden: false,
        width: '120px'
      },
      {
        name: I18n.t(`${fieldsLang}.points_required`),
        dataId: 'points_required',
        hidden: false,
        width: '120px'
      },
      {
        name: I18n.t(`${fieldsLang}.consumer_group_ids`),
        dataId: 'consumer_group_ids',
        hidden: false,
        width: '120px',
        formatter: (cell) => (isEmpty(cell) ? 'All' : cell.length)
      },
      {
        name: I18n.t(`${fieldsLang}.vendor_ids`),
        dataId: 'vendor_ids',
        hidden: false,
        width: '120px',
        formatter: (cell) => (isEmpty(cell) ? 'All' : cell.length)
      },
      {
        name: I18n.t(`${fieldsLang}.item_master_ids`),
        dataId: 'item_master_ids',
        hidden: false,
        width: '120px',
        formatter: (cell) => (isEmpty(cell) ? 'All' : cell.length)
      }
    ];

    const toggleStatus = (e, reward) => {
      this.props.toggleRewardStatus(e, reward).then(this.loadRewards);
    };

    const activeColumns = [
      {
        dataSort: false,
        permissions: [permissions.manage_facility, permissions.manage_coupons],
        csvFormatter: () => I18n.t('retail.coupons.actions.inactivate'),
        formatter: SingleActionColumn({
          permissions: [permissions.manage_facility, permissions.manage_coupons],
          action: toggleStatus,
          label: 'common.inactivate'
        }),
        width: '120px'
      }
    ];

    const inactiveColumns = [
      {
        dataSort: false,
        permissions: [permissions.manage_facility, permissions.manage_coupons],
        csvFormatter: (cell) => I18n.t('retail.coupons.actions.activate'),
        formatter: SingleActionColumn({
          permissions: [permissions.manage_facility, permissions.manage_coupons],
          action: toggleStatus,
          label: 'common.activate'
        }),
        width: '120px'
      }
    ];
    const tabs = [
      {
        id: 'activeRewardsTab',
        eventKey: 'active',
        title: 'common.active',
        actions: [
          {
            id: 'createReward',
            path: '/retail/rewards/create',
            text: I18n.t('retail.rewards.reward.actions.create'),
            glyph: 'plus-sign',
            requireSelect: false
          },
          {
            id: 'modifyReward',
            permissions: [permissions.manage_coupons, permissions.manage_facility],
            func: this.editReward,
            text: I18n.t('retail.rewards.reward.actions.modify'),
            glyph: 'edit',
            requireSelect: true
          }
        ]
      },
      { id: 'inactiveRewardsTab', eventKey: 'inactive', title: 'common.inactive', actions: [] }
    ];
    this.state = { commonColumns, activeColumns, inactiveColumns, tabs };
  }

  componentWillMount() {
    this.loadRewards();
    this.switchTab(this.props.mode);
  }

  loadRewards() {
    this.props.actions.getUnpaginatedData('/api/rewards', dataNames.rewards, {
      failed: 'retail.rewards.messages.get.failed'
    });
  }

  switchTab(tab) {
    this.props.actions.clearSelectedData(dataNames.coupons);
    this.props.actions.push(`/retail/rewards${allowedTabs.indexOf(tab) > -1 ? `/${tab}` : ''}`);
    const isActiveTab = tab !== 'inactive';
    this.setState((prevState) => ({
      columns: prevState.commonColumns.concat(prevState[isActiveTab ? 'activeColumns' : 'inactiveColumns'])
    }));
  }

  handleSelect(row) {
    const action = this.props.selectedRewardIds[0] === row.id ? 'remove' : 'set';
    this.props.actions.handleComplexSelectRow([row], dataNames.rewards, action);
  }

  editReward() {
    this.props.actions.push(`/retail/rewards/${this.props.selectedRewardIds[0]}`);
  }

  redirect() {
    this.props.actions.goBack();
  }

  render() {
    const { tabs, columns } = this.state;
    const { rewards, selectedRewardIds } = this.props;
    const activeTab = this.props.mode;
    return (
      <div className='coupon-listing'>
        <PageTitle primaryText={I18n.t('retail.title')} secondaryText={I18n.t('retail.rewards.title')} />
        <TablePageWrapper
          ref={this.ref}
          settingKey='retail-coupons'
          tabs={tabs}
          activeTab={activeTab}
          switchTab={this.switchTab}
          columns={columns}
          data={rewards}
          selectedRows={selectedRewardIds}
          handleSelect={this.handleSelect}
          hideExport={true}
          hideScanSearch={true}
          external={false}
          bstProps={{
            selectRow: {
              mode: 'radio',
              selected: { selectedRewardIds },
              onSelect: this.handleSelect
            }
          }}
        />
      </div>
    );
  }
}

RewardsList.propTypes = {
  actions: PropTypes.object,
  params: PropTypes.object,
  rewards: PropTypes.array,
  selectedRewards: PropTypes.array,
  selectedRewardIds: PropTypes.array
};

function mapStateToProps(state, ownProps) {
  return {
    rewards: getRewardsByStatus(state, ownProps),
    selectedRewards: state.selectedRewards,
    selectedRewardIds: Array.isArray(state.selectedRewards) ? state.selectedRewards.map((reward) => reward.id) : []
  };
}

function mapDispatchToProps(dispatch) {
  const actions = { getUnpaginatedData, putData, handleComplexSelectRow, push, goBack, clearSelectedData, unsetData };
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RewardsList);
