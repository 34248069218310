import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';

import NumericInput from '../common/form/NumericInput';

export const Cultivation = ({totalPlantLifeCycleValues, index}) => {
  return (
    <Row className='cultivation-block'>
      <Col md={4} className='bordered-block-right'>
        <h3>{I18n.t('strains.edit.growthCycleInfo')}</h3>
        <Row>
          <Col md={12}>
            <Field
              name={`prop_days`}
              component={NumericInput}
              allowNegativeNumber = {false}
              fractionPartSize = {0}
              min={0}
              props={{
                label: I18n.t('strains.propagationCycle'),
                rightAddon: <span>{I18n.t('strains.edit.days')}</span>
              }}/>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Field
              name={`clone_mortality_rate`}
              component={NumericInput}
              props={{
                label: I18n.t('strains.cloneMortalityRate'),
                rightAddon: <span>{I18n.t('common.units.percent')}</span>
              }}/>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Field
              name={`veg_days`}
              component={NumericInput}
              allowNegativeNumber = {false}
              fractionPartSize = {0}
              min={0}
              props={{
                label: I18n.t('strains.vegetativeCycle'),
                rightAddon: <span>{I18n.t('strains.edit.days')}</span>
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Field
              name={`flower_days`}
              component={NumericInput}
              allowNegativeNumber = {false}
              fractionPartSize = {0}
              min={0}
              props={{
                label: I18n.t('strains.flowerCycle'),
                rightAddon: <span>{I18n.t('strains.edit.days')}</span>
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className='border-top'></div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Field name={`total_plant_lifecycle`} component={NumericInput} props={{
              label: I18n.t('strains.totalPlantLifecycle'),
              rightAddon: <span>{I18n.t('strains.edit.days')}</span>,
              readOnly: true,
              value: totalPlantLifeCycleValues[index] || ''
            }}
            />
          </Col>
        </Row>
      </Col>
      <Col md={4}>
        <h3>{I18n.t('strains.edit.averageYield')}</h3>
        <Row>
          <Col md={12}>
            <Field name={`avg_yield_flower`} component={NumericInput} props={{
              label: I18n.t('strains.flower'),
              rightAddon: <span>{I18n.t('strains.edit.grams')}</span>,
              fractionPartSize: 2,
              step: 0.01,
            }}/>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Field name={`avg_yield_trim`} component={NumericInput} props={{
              label: I18n.t('strains.trim'),
              rightAddon: <span>{I18n.t('strains.edit.grams')}</span>,
              fractionPartSize: 2,
              step: 0.01,
            }}/>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Field name={`avg_yield_whole`} component={NumericInput} props={{
              label: I18n.t('strains.wholePlant'),
              rightAddon: <span>{I18n.t('strains.edit.grams')}</span>,
              fractionPartSize: 2,
              step: 0.01,
            }}/>
          </Col>
        </Row>
      </Col>
      <Col md={4} className='bordered-block-left'>
        <h3>{I18n.t('strains.edit.desiredWeightPerWeek')}</h3>
        <Row>
          <Col md={12}>
            <Field name={`desired_weight_flower`} component={NumericInput} props={{
              label: I18n.t('strains.flower'),
              rightAddon: <span>{I18n.t('strains.edit.grams')}</span>,
              fractionPartSize: 2,
              step: 0.01,
            }}/>
          </Col>
        </Row>
        <Row >
          <Col md={12}>
            <Field name={`desired_weight_trim`} component={NumericInput} props={{
              label: I18n.t('strains.trim'),
              rightAddon: <span>{I18n.t('strains.edit.grams')}</span>,
              fractionPartSize: 2,
              step: 0.01,
            }}/>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Field name={`desired_weight_whole`} component={NumericInput} props={{
              label: I18n.t('strains.wholePlant'),
              rightAddon: <span>{I18n.t('strains.edit.grams')}</span>,
              fractionPartSize: 2,
              step: 0.01,
            }}/>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Field name={`desired_weight_clones`} component={NumericInput} props={{
              label: I18n.t('strains.clones'),
              rightAddon: <span>{I18n.t('strains.edit.ea')}</span>
            }}/>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

Cultivation.propTypes = {
  index: PropTypes.number,
  totalPlantLifeCycleValues: PropTypes.array
};

export default Cultivation;
