import React from 'react';
import PropTypes from 'prop-types';
//import { Button } from 'react-bootstrap';
//import { I18n } from 'react-redux-i18n';
import FacilityBank from './FacilityBank';

const FacilityBankingInformation = (props) => {

  const {fields} = props;

  // const onAddBank = () => {
  //   fields.push({});
  // };

  const onRemoveBank = (index) => {
    fields.remove(index);
  };

  return (<div>
    {/*Left against the day we need multiple banks...*/}
    {/*<div className='col-md-12'>*/}
      {/*<Button variant='primary' className='float-right' onClick={(e) => {*/}
        {/*e.target.blur();*/}
        {/*onAddBank();*/}
      {/*}}>*/}
        {/*Add Bank*/}
      {/*</Button>*/}
    {/*</div>*/}
    {
      fields.map((fieldPrefix, index) => {
        return <FacilityBank fieldPrefix={fieldPrefix} key={index} bankIndex={index} onRemoveBank={onRemoveBank} isLast={index + 1 === fields.length} />;
      })
    }
  </div>);

};

FacilityBankingInformation.propTypes = {
  fields: PropTypes.object.isRequired,
};

export default FacilityBankingInformation;
