import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {FaCheck, FaExclamation, FaTimes} from 'react-icons/fa';

const blockStyles = {backgroundColor: '#eee', padding: '10px', marginBottom: '25px', marginTop: '-15px'};
const blockHeaderStyles = {marginBottom: '10px', fontSize: '0.9em', fontWeight: 'bold', padding: '5px'};

const PasswordRulesBlock = ({rules, isDirty}) => {
  return (
    <div style={blockStyles}>
      <div style={blockHeaderStyles}>{I18n.t('user.password.complexityRequirements')}</div>
      {rules.map((rule) => (
        <div key={rule.key} style={{padding: '5px'}}>
          {isDirty
            ? (rule.isValid ? <FaCheck className='success'/> : <FaTimes className='danger'/>)
            : <FaExclamation className='info'/>
          }&nbsp;
          {I18n.t(`user.password.rules.${rule.key}`)}
        </div>
      ))}
    </div>
  );
};

PasswordRulesBlock.propTypes = {
  rules: PropTypes.array.isRequired,
  isDirty: PropTypes.bool.isRequired,
};

export default PasswordRulesBlock;
