import {reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import CheckinCureApiForm from './CheckinCureApiForm';
import validate from './validate';
import {CHECK_IN_CURE_API} from '../../../../constants/forms';
import {CURE_API_PATIENT} from '../../../../constants/idTypes';

const initialValues = {
  license_type: CURE_API_PATIENT,
};

const CheckinCureApiFormWrapper = reduxForm({
  initialValues,
  validate,
  form: CHECK_IN_CURE_API,
  touchOnChange: true,
})(CheckinCureApiForm);

CheckinCureApiFormWrapper.propTypes = {
  isCaregiverMode: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CheckinCureApiFormWrapper;
