import {requiredFieldValidation, betweenValidation} from '../../../common/form/redux-form/validations';

const validate = (values) => {
  const errors = {};

  errors.name = requiredFieldValidation(values.name, 'retail.pricingGroup.form.groupName');
  errors.category_id = requiredFieldValidation(values.category_id, 'retail.pricingGroup.form.productCategory');

  errors.pricing_weights = {
    _error: (values.pricing_weights || [])
      .filter(w => w.default_price > 0)
      .map(w => w.default_price)
      .length > 0 ? null : 'retail.pricingGroup.form.defaultPriceRequiredError'
  };

  errors.customer_groups = (values.customer_groups || []).map(value => ({
    customer_group_id: requiredFieldValidation(value.customer_group_id, 'retail.pricingGroup.form.customerGroupError'),
    percentage_of_base: requiredFieldValidation(value.percentage_of_base, 'retail.pricingGroup.form.percentError')
    || betweenValidation(value.percentage_of_base, 'retail.pricingGroup.form.percentError', 0.01, 100),
  }));

  return errors;
};

export default validate;
