import {requiredFieldValidation, minValidation} from '../../common/form/redux-form/validations';

const validate = (values) => {
  const errors = {};
  errors.items = values.items.map(item => {
    const error = {};
    error.current_weight = requiredFieldValidation(item.current_weight, 'harvestBatch.form.quantity')
      || minValidation(item.current_weight, 'harvestBatch.form.quantity', 0, true);
    return error;
  });
  return errors;
};

export default validate;
