import {createSelector} from 'reselect';

import * as itemNames from '../constants/itemNames';
import * as dataNames from '../constants/dataNames';

export const getCompany = (state) => state[itemNames.merchantConfigurationCompany];
export const getMerchants = (state) => state[dataNames.merchantConfigurationMerchants];
export const getMerchantProfile = (state) => state[itemNames.merchantProfile];
export const getMerchantProfiles = (state) => state[dataNames.merchantConfigurationProfiles];

export const getMerchantProfileInitialValues = createSelector(
  [getMerchantProfile, getCompany],
  (merchantProfile, company) => {
    if (!company) return;

    return {
      company_id: company.id,
      ...merchantProfile
    };
  }
);

export const getMerchantsForSelection = createSelector(
  [getMerchants],
  (merchants) => {
    return merchants.map(merchant => ({text: merchant.name, value: merchant.key}));
  }
);
