import {change, formValueSelector} from 'redux-form';

import {MODIFY_HARVEST_BATCH_FORM} from '../../constants/forms';
import {isFormChangeOnField} from './utils';
import {isMetrcIntegrator} from '../../selectors/integration/metrcSelectors';


const selector = formValueSelector(MODIFY_HARVEST_BATCH_FORM);

const modifyHarvestBatch = store => next => action => {
  const result = next(action);
  const state = store.getState();

  //adjust weight when reporting waste
  if (isFormChangeOnField(action, [MODIFY_HARVEST_BATCH_FORM], 'reportWaste') && isMetrcIntegrator(state)) {
    const {payload, meta} = action;
    const originalWeight = selector(state, 'current_weight');
    const newWeight = (originalWeight - parseFloat(payload || 0));
    store.dispatch(change(meta.form, 'newWeight', newWeight));
  }

  return result;
};

export default modifyHarvestBatch;
