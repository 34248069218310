import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ActionButtonsCell from './ActionButtonsCell';
import {getTransactions} from '../../../../selectors/ordersSelectors';
import InternationalCurrencyStatic from '../../../common/form/InternationalCurrencyStatic';

class ProductRow extends React.PureComponent {

  constructor(props, context){
    super(props, context);

    this.getRestockTransactionQuantity = this.getRestockTransactionQuantity.bind(this);
  }

  getRestockTransactionQuantity(){
    const d = this.props.data;
    if(!d.items ||
      !Array.isArray(d.items) ||
      d.items.length === 0 ||
      d.items[this.props.inventoryIndex] === undefined ||
      !d.items[this.props.inventoryIndex].item_transaction_id) {
      return null;
    }
    const item = d.items[this.props.inventoryIndex];
    const restockTransactions = this.props.restockTransactions.filter((t) =>
      (t.reference_id === item.item_transaction_id && t.item_id === item.item_id));
    if (!restockTransactions.length) return null;
    const qty = restockTransactions
      .reduce((acc, {transacted_qty}) => acc + Number(parseFloat(transacted_qty).toFixed(0)), 0);

    return `Restocked: ${qty} ${restockTransactions[0].transacted_uom}`;
  }

  render() {

    const props = this.props;
    const data = props.data;

    const isRefunded = () => {
      return (props.refundedOrderIds.indexOf(props.data.id) !== -1);
    };
    const spreadProps = {
      refundQueue: props.refundQueue,
      restockQueue: props.restockQueue,
      onRefundClick: props.onRefundClick,
      onRestockClick: props.onRestockClick,
      refundedOrderIds: props.refundedOrderIds,
      canRefundOrder: props.canRefundOrder
    };

    const getRefundDetails = () => {
      const details = [];
      for (const name in data.refundedDetails) {
        const orderName = name.replace('_', '.');
        details.push('Refunded ' + data.refundedDetails[name] + ' through ' + orderName);
      }
      return details;
    };

    const getLineTotal = () => {
      if (data.allocated_price === undefined) {
        return data.quantity * parseFloat(data.unit_price);
      }
      return parseFloat(data.allocated_price);
    };

    const getDataLine = () => {
      if (data.refunded_order_product_inventory_id) {
        const refundProduct = props.refundedInventoryItems.find(
          (item) => item.id === data.refunded_order_product_inventory_id && item.order_id === props.order.id
        );
        return Object.assign({}, data, refundProduct);
      }
      return data;
    };

    const getQuantity = () => {
      const d = getDataLine();
      const uom = d.prepack_weight_id ? 'x ' + props.prepackWeights.find(weight => weight.id === d.prepack_weight_id).name : d.uom_display;
      return (d.qty !== undefined)
        ? d.qty + ' ' + uom
        : d.sold_weight !== undefined
          ? d.sold_weight + ' ' + uom
          : d.quantity;
    };

    const getPackageCode = () => {
      const d = getDataLine();
      return (d.package_code !== undefined) ? d.package_code : 'NA';
    };

    const getOrderName = () => {
      const d = getDataLine();
      return d.order_name ? d.order_name : '';
    };

    return (
      <tr className={isRefunded() ? 'text-danger' : ''}>
        <td>{data.name}</td>
        <td>
          <div>{getQuantity()}</div>
          <div style={{fontSize: 'smaller'}} className='text-muted'>{this.getRestockTransactionQuantity()}</div>
        </td>
        <td>{getPackageCode(props.transactions)}</td>
        <td style={{textAlign: 'right'}}>
          <InternationalCurrencyStatic>{getLineTotal()}</InternationalCurrencyStatic>
        </td>
        <td style={{textAlign: 'center'}}>
          <div>
            <ActionButtonsCell
              refundOrderInitiated={props.refundOrderInitiated}
              restockOrderInitiated={props.restockOrderInitiated}
              canPrintLabels={this.props.canPrintLabels}
              showSingleLabelModal={this.props.showSingleLabelModal}
              onRestockProduct={props.onRestockProduct}
              orderName={getOrderName()}
              integrationState={props.integrationState}
              nonMedicatedCategoryId={props.nonMedicatedCategoryId}
              refundOrders={props.refundOrders}
              data={data}
              order={props.order}
              {...spreadProps}
            />
          </div>
          {
            data.isRefunded === 0
              ? null
              : getRefundDetails().map((detail, index) => {
                return (<div key={index} className='text-muted' style={{fontSize: 'smaller', marginTop: '4px'}}>
                  {detail}
                </div>);
              })
          }
        </td>
      </tr>
    );

  }

}

ProductRow.propTypes = {
  onRestockProduct: PropTypes.func.isRequired,
  data: PropTypes.object,
  refundQueue: PropTypes.object,
  restockQueue: PropTypes.array,
  onRefundClick: PropTypes.func,
  onRestockClick: PropTypes.func,
  refundedOrderIds: PropTypes.array,
  getDetailedOrder: PropTypes.func,
  order: PropTypes.object,
  index: PropTypes.number,
  inventoryIndex: PropTypes.number.isRequired,
  refundedInventoryItems: PropTypes.array.isRequired,
  transactions: PropTypes.array,
  integrationState: PropTypes.object.isRequired,
  nonMedicatedCategoryId: PropTypes.number,
  refundOrders: PropTypes.array,
  showSingleLabelModal: PropTypes.func.isRequired,
  canPrintLabels: PropTypes.bool.isRequired,
  refundOrderInitiated: PropTypes.bool,
  restockOrderInitiated: PropTypes.bool,
  canRefundOrder: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return { restockTransactions: getTransactions(state) };
}

export default connect(mapStateToProps)(ProductRow);
