import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import debounce from 'lodash.debounce';
import get from 'lodash.get';
import TablePageWrapper from '../common/grid/TablePageWrapper';
import PageTitle from '../common/PageTitle';
import ModalWrapper from '../common/ModalWrapper';
import {ConnectedIntegrationTrackingStatusModal} from '../common/integration/IntegrationTrackingStatusModal';
import { leafPaMaxHarvestWeight } from '../../constants/leaf';
import ActivateHarvestBatchesFormWrapper from './activate/ActivateHarvestBatchesFormWrapper';
import * as p from '../../constants/permissions';
import * as selectedDataActions from '../../actions/selectedDataActions';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import * as apiActions from '../../actions/apiActions';
import {unsetData} from '../../actions/dataActions';
import { convertFormInputDateToDbDate } from '../../util/dateHelpers';
import { convertFromBase } from '../../util/uomHelpers';
import { getSolrCoreName, getHarvestSolrReworkQueryAndFilter } from '../../selectors/harvests/harvestsSelectors';

import {
  getHarvestListingData,
  getSelectedHarvests,
  getSelectedHarvestIds,
  getHarvestsPageTabs,
  getLabResultForHarvestsetting,
  getHarvestIdsWhereIsLocked
} from '../../selectors/harvests/harvestsSelectors';
import { getIntegrationState } from '../../selectors/integration/integrationSelectors';
import { userHasPermission } from '../../selectors/usersSelectors';
import getColumns from './columnDefinitions';
import { getTotalResults } from '../../selectors/paginationSelectors';
import InProgressOverlay from '../common/InProgressOverlay';
import LabsByHarvest from './LabsByHarvest';
import LabelPrinter from '../print-services/labels/LabelPrinter'; //eslint-disable-line
import { getArchiveInactiveAfter } from '../../selectors/plantsSelectors';
import { getCupos } from '../../selectors/forms/cupoFormSelectors';
import { setSolrErrorMessage } from '../../actions/solrActions';
import { isFeatureEnabled } from '../../selectors/featureToggles'; //eslint-disable-line
import {getUseEntityLocksForHarvestBatches} from '../../selectors/coreSettingsSelectors';

export class HarvestsPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.onActivateBatch = this.onActivateBatch.bind(this);
    this.switchTab = this.switchTab.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.confirmShowModal = this.confirmShowModal.bind(this);
    this.confirmSubmit = this.confirmSubmit.bind(this);
    this.showConfirmationModal = this.showConfirmationModal.bind(this);
    this.hideConfirmationModal = this.hideConfirmationModal.bind(this);
    this.onShowLabel = this.onShowLabel.bind(this);
    this.onHideLabel = this.onHideLabel.bind(this);
    this.onFinalizeHarvest = this.onFinalizeHarvest.bind(this);
    this.onCreateLot = this.onCreateLot.bind(this);
    this.onShowFinalizeHarvest = this.onShowFinalizeHarvest.bind(this);
    this.onHideFinalizeHarvest = this.onHideFinalizeHarvest.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
    this.filterStatus = this.filterStatus.bind(this);
    this.getLabResults = this.getLabResults.bind(this);
    this.canSplitHarvest = this.canSplitHarvest.bind(this);
    this.canCreateLots = this.canCreateLots.bind(this);
    this.showTestResults = this.showTestResults.bind(this);
    this.hideTestResults = this.hideTestResults.bind(this);
    this.trClassName = this.trClassName.bind(this);
    this.setDataState = this.setDataState.bind(this);

    const {useServiceFirstHarvestBatchesListing} = props;
    const columns = getColumns(this, useServiceFirstHarvestBatchesListing);
    this.ref = React.createRef();
    const filters = [];
    this.state = {
      columns,
      showModal: false,
      confirmationModal: {
        show: false,
        message: 'harvestBatch.activate.noIntegrationForThisWorkflow',
        onConfirm: this.showModal
      },
      showLabel: false,
      showFinalizeHarvest: false,
      searchText: '',
      sortField: 'batch_name',
      activePage: 1,
      itemsPerPage: 20,
      sortDirection: 'asc',
      loading: true,
      defaultSort: 'batch_name asc',
      filters,
      canSplitHarvest: false,
      canCreateLots: false,
      showResults: false,
      items: [],
      dataState: 'loading'
    };

    this.harvestBatchIds = [];
    this.lastQuery = '';
    this.searchInFlight = false;
  }

  componentWillMount() {
    const {
      integrationState: { isColombia },
      useServiceFirstHarvestBatchesListing
    } = this.props;
    this.props.actions.unsetData(dataNames.harvests);
    this.props.actions.getItem('/api/compliance_settings', itemNames.invComplianceSettings);
    this.props.actions.getUnpaginatedData('/api/location_hierarchy', dataNames.inventoryLocations);
    this.props.actions.getItem('/api/integration-settings', itemNames.integrationSettings, {
      failed: 'stateIntegratorSettings.get.failed'
    });
    isColombia &&
      this.props.actions.getData('/api/cupos', dataNames.cupos, null, null, () => {
        this.setState({
          columns: getColumns(this, useServiceFirstHarvestBatchesListing)
        });
      });
    this.props.actions.getItem('/api/settings/values/by_key', itemNames.requireLabResultForBatch, null, {
      ids: ['cult_batch_packaging_requires_passed_lab']
    });
    this.props.actions.getItem(
      '/api/settings/plants',
      itemNames.complianceSettings,
      { failed: 'cultivation.complianceSettings.failed' },
      { ids: ['cult_track_plants_as_groups'] }
    );
    this.switchTab(this.props.params.active);
  }

  componentDidMount() {
    this.filterStatus(this.props.status);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.status !== this.props.status) {
      this.filterStatus(nextProps.status);
    }
  }

  componentDidUpdate(prevProps) {
    // Reload list if we get compliance settings with different archiveInactiveAfter value
    // (only if we are on inactive tab)
    if (prevProps.archiveInactiveAfter !== this.props.archiveInactiveAfter) {
      if (this.props.status === 'inactive') {
        this.setState({ filters: [] }, () => { //eslint-disable-line
          this.filterStatus(this.props.status);
        });
      }
    }
  }

  static prepareColumnsForWa(columns) {
    const preparedColumns = columns
      .map((column) => {
        if (column.dataId === 'current_weight_base') {
          column.name = 'harvestBatch.table.flowerWeight';
        }
        return column;
      })
      .filter((column) => column.dataId !== 'calculated_moisture_loss');

    preparedColumns.push({
      name: 'harvestBatch.table.otherMaterialsWeight',
      dataId: 'current_other_material_weight_base',
      hidden: false,
      width: '70px',
      formatter: (cell, row) => {
        return (convertFromBase(cell, row.uom)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 3 });
      }
    });
    return preparedColumns;
  }

  /****************************************************************
   * MODALS MODALS EVERYWHERE!  It took me a while to figure out what this was doing... so to avoid that in the future...
   *
   * When on the inactive tab, the showModal and showConfirmationModal methods and ModalWrapper components in render
   * support activating a harvest and, if running under metrc integration, alert the user that they will have to
   * manually perform the task on metrc since there is no integration.
   *
   * The printer modal is pretty obvious.
   *
   * And the newest is the finalize modal which finalizes a batch before a lot can be created from it.  This is
   * universal and just runs the post from modify harvest batch with current values and a finalize flag set.
   * **************************************************************/

  showModal() {
    const { hasMetrcSettings } = this.props;
    if (hasMetrcSettings) {
      this.hideConfirmationModal();
    }
    this.setState({ showModal: true });
  }

  confirmShowModal() {
    const { hasMetrcSettings } = this.props;
    if (hasMetrcSettings) {
      this.showConfirmationModal();
    } else {
      this.showModal();
    }
  }

  hideModal() {
    this.setState({ showModal: false, showResults: false });
  }

  showConfirmationModal() {
    this.setState({ confirmationModal: Object.assign({}, this.state.confirmationModal, { show: true }) });
  }

  hideConfirmationModal() {
    this.setState({ confirmationModal: Object.assign({}, this.state.confirmationModal, { show: false }) });
  }

  showTestResults(row) {
    this.setState({ items: row.packages, showResults: true });
  }

  hideTestResults() {
    this.setState({ showResults: false });
  }

  confirmSubmit(formValues) {
    const { hasMetrcSettings } = this.props;
    if (hasMetrcSettings) {
      this.setState({
        confirmationModal: Object.assign({}, this.state.confirmationModal, {
          message: 'harvestBatch.activate.mustBeReplicatedInMetrc',
          show: true,
          onConfirm: () => {
            this.hideConfirmationModal();
            this.onActivateBatch(formValues);
          }
        })
      });
    } else {
      this.onActivateBatch(formValues);
    }
  }

  onActivateBatch(formData) {
    const id = formData.items[0].id;
    const payload = {
      uom: formData.items[0].uom,
      current_weight: formData.items[0].current_weight,
      inventory_location_id: formData.items[0].inventory_location_id,
      reactivated_at: convertFormInputDateToDbDate(formData.reactivated_at, this.props.timezone), //need for history only
      active: 1,
      notes: formData.notes,
      plants_count: get(this.props.selectedHarvests[0], 'plants_count')
    };
    return this.props.actions.putData(
      `/api/harvests/${id}`,
      payload,
      null,
      { failed: 'harvestBatch.activate.fail', success: 'harvestBatch.activate.success' },
      undefined,
      () => {
        this.props.actions.clearSelectedData(dataNames.harvests);
        this.hideModal();
      }
    );
  }

  switchTab(tab) {
    this.props.actions.push(`/harvests/${tab || ''}`);
    this.props.actions.clearSelectedData(dataNames.harvests);
    this.hideModal();
  }

  handleSelect(add, rows) {
    if (add) {
      this.props.actions.clearSelectedData(dataNames.harvests);
      this.props.actions.addSelectedData(rows, dataNames.harvests);
    } else {
      this.props.actions.removeSelectedData(rows, dataNames.harvests);
    }
    this.setState({ canSplitHarvest: this.canSplitHarvest(rows), canCreateLots: this.canCreateLots(rows) });
  }

  onShowLabel(harvestId) {
    this.setState({
      showLabel: true,
      payload: {
        id: harvestId
      }
    });
  }

  onHideLabel() {
    this.setState({ showLabel: false });
  }

  // FINALIZE HARVEST FROM HERE TO RENDER

  onFinalizeHarvest() {
    const harvest =
      this.props.selectedHarvests && this.props.selectedHarvests.length ? this.props.selectedHarvests[0] : false;
    const actionDate = moment().format('YYYY-MM-DD HH:mm:ss');
    const payload = Object.assign({}, harvest, {
      final_weight_recorded: true,
      completed_at: actionDate,
      action_date: actionDate,
      uom: harvest.uom_display
    });
    // Remove unnecessary property plants which introduces unnecessary validation.
    delete payload.plants;
    const messages = { failed: 'harvestBatch.modify.fail', success: 'harvestBatch.modify.success' };
    this.props.actions.putData(`/api/harvests/${harvest.id}`, payload, null, messages, null, () => {
      this.props.actions.push('/harvests/create-package');
    });
  }

  onCreateLot() {
    const harvest =
      this.props.selectedHarvests && this.props.selectedHarvests.length ? this.props.selectedHarvests[0] : false;
    if (harvest.final_weight_recorded) return this.props.actions.push('/harvests/create-package');
    this.onShowFinalizeHarvest();
  }

  onShowFinalizeHarvest() {
    this.setState({ showFinalizeHarvest: true });
  }

  onHideFinalizeHarvest() {
    this.setState({ showFinalizeHarvest: false });
  }

  onInactivateHarvest(harvest) {
    const payload = Object.assign({}, harvest, { active: 0 });
    const messages = { failed: 'harvestBatch.modify.inactivateFail', success: 'harvestBatch.modify.inactivateSuccess' };
    this.props.actions.putData(`/api/harvests/${payload.id}`, payload, dataNames.harvestBatch, messages, null, () => {
      this.switchTab(this.props.params.active);
    });
  }

  getLabResults(ids=[]) {
    const {useServiceFirstHarvestBatchesListing, harvests} = this.props;

    // NOTE: Remove this whole "if" conditional when "useServiceFirstHarvestBatchesListing" toggle is
    //  removed from Platform, but keep the subsequent "if" conditional below it, checking the ids.length
    if (!ids.length && !useServiceFirstHarvestBatchesListing) {
      ids = harvests.reduce((acc, harvest) => {
        harvest.packages.map((package_data) => package_data.is_sample && acc.push(package_data.package_id));
        return acc;
      }, []);
    }

    if (!ids.length) {
      return;
    }

    const query = 'matchall';
    const size = 10000;
    const sort = 'testing_date desc';
    const idsFilter = ids.reduce((acc, id) => {
      return acc ? acc + ` OR ${id}` : `package_id: (${id}`;
    }, '');
    const filterParams = 'is_latest_lab_result: 1';
    const filter = idsFilter !== '' ? `${idsFilter}) AND ${filterParams}` : filterParams;
    const params = {query, size, sort, filter};
    this.props.actions.getDataByPost('/api/search/lab_results', params, dataNames.testResults);
  }

  canSplitHarvest(rows) {
    const harvest = rows && rows.length ? rows[0] : false;
    const { isPaLeaf } = this.props.integrationState;
    return harvest
      ? !isPaLeaf
        ? true // Always if not PaLeaf
        : !harvest.has_sample
      : false; // Not if no harvest present
  }

  canCreateLots(rows) {
    const harvest = rows && rows.length ? rows[0] : false;
    const { isPaLeaf } = this.props.integrationState;
    const maxPackageSizeForBatch = leafPaMaxHarvestWeight;
    return (
      (harvest && !isPaLeaf) || (harvest && isPaLeaf && parseFloat(harvest.current_weight) <= maxPackageSizeForBatch)
    );
  }

  /**
   * Handle search, pagination, etc and either search or get data from service or both
   * @param sort
   * @param query
   * @param size
   * @param start
   * @param filter
   */
  handleSearch (sort, query = 'matchall', size, start, filter) {
    const { solrCoreName } = this.props;
    // const {useServiceFirstHarvestBatchesListing, solrCoreName} = this.props;
    // This if block contains the original SOLR logic, unaltered, prior to any Service First work. It was ported
    // as-is without refactoring with the other SOLR search (below this if condition) to both minimize risk of breakage
    // as well as to make it easy to yank out when the feature toggle is flipped on in Production.
    
    // NOTE: we don't have "service first" on harvest batches listing, 
    // so we can keep unlbock this code (by using solr in default) until we have that feature

    // if (!useServiceFirstHarvestBatchesListing) {
    const { defaultSort } = this.state;

    if (sort === '') {
      sort = defaultSort;
    }

    const config = {
      errorHandler: {
        message: 'cultivation.finishedProduct.table.solrError',
        action: this.props.actions.setSolrErrorMessage,
        clearOnSuccess: true,
      }
    };

    const {updatedQueryString, updatedFilter} = getHarvestSolrReworkQueryAndFilter(query, filter, solrCoreName === 'new_harvest_batches');

    this.setState({ loading: true });
    const params = { 
      sort, 
      query: updatedQueryString, 
      size, 
      start, 
      filter: updatedFilter
    };
    this.props.actions
      .getSearchData(`/api/search/${solrCoreName}`, dataNames.harvests, null, params, null, null, config)
      .then(() => this.getLabResults())
      .then(() => this.setState({ loading: false }))
      .catch(() => this.setState({ loading: false }));

    return;
    // }

    // Service First logic - service frist logic haven't implemented yet, so we should keep those code commented
    // if(this.searchInFlight){
    //   return;
    // }
    // const isSearch = Boolean(query && query !== this.lastQuery);
    // const isEmptySearch = Boolean( query && query === this.lastQuery && !this.harvestBatchIds.length);
    // if (!isSearch) {
    //   if (!query) {
    //     this.lastQuery = '';
    //     this.harvestBatchIds = [];
    //   }
    //   if(isEmptySearch){
    //     return;
    //   }
    //   this.fetchHarvestBatches(sort, size, start);
    //   return;
    // }

    // // Search and then load data
    // this.props.actions.unsetData(dataNames.harvests);
    // this.lastQuery = query;
    // const config = {
    //   errorHandler: {
    //     message: 'cultivation.finishedProduct.table.solrError',
    //     action: this.props.actions.setSolrErrorMessage,
    //     clearOnSuccess: true,
    //   }
    // };
    // this.setState({loading: true});
    // const highSizeLimitForSearch = 10000; // Arbitrary overly high search return limit;
    // const searchStart = 0;

    // const {updatedQueryString, updatedFilter} = getHarvestSolrReworkQueryAndFilter(query, filter, solrCoreName === 'new_harvest_batches');

    // const params = {
    //   sort,
    //   query: updatedQueryString,
    //   size: highSizeLimitForSearch,
    //   start: searchStart,
    //   filter: updatedFilter
    // };
    // this.searchInFlight = true;
    // this.setDataState('searching')
    //   .then(() => {
    //     this.props.actions
    //       .getSearchData(`/api/search/${solrCoreName}`, null, null, params, null, null, config)
    //       .then((harvestBatches) => {
    //         const harvestBatchIds = harvestBatches.map((batch) => batch.id);
    //         this.searchInFlight = false;
    //         if(!harvestBatchIds.length){
    //           this.setDataState('loaded');
    //           this.harvestBatchIds = [];
    //           this.setState({loading: false});
    //           return;
    //         }
    //         // Get data from service
    //         this.fetchHarvestBatches(sort, size, start, harvestBatchIds);
    //       })
    //       .catch(() => {
    //         this.searchInFlight = false;
    //         this.fetchHarvestBatches(sort, size, start);
    //       });
    //   });
  }

  /**
   * Get data from service
   * @param sort
   * @param size
   * @param start
   * @param ids - array of ids to constrain request
   */
  fetchHarvestBatches (sort, size, start, ids = []) {
    // Is this load constrained by ids?
    const harvestBatchIds = ids.length || this.harvestBatchIds
      ? ids.length
        ? ids
        : this.harvestBatchIds
      : [];

    if (ids.length) { // If ids were passed in we will be constrained on following paging, sorting, etc.
      this.harvestBatchIds = ids;
    }

    if (sort) {
      const sortParts = sort.split(',');
      sort = sortParts.map(sort => sort.trim().replace(/\s/, ':'));

    } else {
      sort = this.state.defaultSort;
    }

    const params = {
      // pagination
      page: (start ? start / size : 0) + 1,
      per_page: size,
      sort,

      select: [
        'id', 'modality', 'batch_name', 'current_weight', 'final_weight_recorded', 'uom_display',
        'calculated_moisture_loss', 'harvested_at', 'is_locked', 'section_name', 'strain_name',
        'location_name', 'tracking_id'
      ],
      queries: [
        'plantsCount',
        'totalQuality'
      ],
      with: [
        'entityLocks'
      ],
      active: this.props.params.active === 'inactive' ? 0 : 1,
    };

    if (harvestBatchIds.length) {
      params.in_ids = harvestBatchIds;
    }

    this.setDataState('loading')
      .then(() => {
        this.props.actions.getPaginatedData('/api/harvest_batches/search', dataNames.harvests, null, params)
          .then((harvestBatches) => {
            this.setDataState('loaded');
            const ids = harvestBatches.map((harvestBatch) => harvestBatch.id);
            this.fetchPackages(ids);
            this.setState({loading: false});
          })
          .catch(() => {
            this.setDataState('loaded');
            this.setState({loading: false});
          });
      });
  }

  fetchPackages (harvestBatchIds = []) {
    const params = {
      in_source_ids: harvestBatchIds,
      source_type: 'harvest_batch',
      with_relations: ['simplePackages'],
      select_columns: ['id', 'source_id', 'source_type']
    };
    this.props.actions.getDataByPost('/api/lots/multiple', params, dataNames.lots)
      .then((lots) => {
        // Get package ids that are samples and pass to lab results function.
        const packageIds = lots.reduce((acc, lot) => {
          acc = acc.concat(get(lot, 'simple_packages', []).reduce((acc, pkg) => {
            if (get(pkg, 'is_sample', 0)) {
              acc.push(pkg.package_id);
            }
            return acc;
          }, []));
          return acc;
        }, []);
        this.getLabResults(packageIds);
      });
  }

  updateSearch() {
    this.props.actions
      .postItem('/api/search/update', { core: `${this.props.solrCoreName}` }, null, {
        success: 'plants.actions.updateSearchSuccess',
        fail: 'plants.actions.updateSearchFail'
      })
      .then(() => this.setState({ loading: false }));
    this.setState({ loading: true });
  }

  filterStatus(status) {
    const { filters } = this.state;
    if (filters.find((filter) => filter === status) !== undefined) {
      this.setState({ filters: [] });
      if (this.ref.current) {
        this.ref.current.wrappedInstance.filter('');
      }
    } else if (status === 'inactive') {
      this.setState({ filters: [status] });
    } else {
      this.setState({ filters: [status] });
    }
    if (this.ref.current) {
      const archiveAfter = this.props.archiveInactiveAfter;
      const filterActive = 'active:1';
      const filterInactive =
        archiveAfter > 0 ? `(active:0 AND completed_at: [NOW-${archiveAfter}DAY TO NOW])` : `active:0`;
      this.ref.current.wrappedInstance.filter(status === 'active' ? filterActive : filterInactive);
    }
  }

  trClassName(useEntityLocks, row) {
    return useEntityLocks && row.is_locked ? 'entity-locked-table-row' : null;
  }

  setDataState(state){
    return new Promise((resolve) => {
      this.setState({dataState: state}, () => {
        resolve();
      });
    });
  }

  render() {
    const {
      harvests, selectedHarvestIds, lockedHarvestIds, getTabs, dataTotalSize, useEntityLocks
    } = this.props;

    const {
      columns,
      showModal,
      confirmationModal,
      searchText,
      defaultSort,
      canSplitHarvest,
      canCreateLots,
      showResults,
      items,
      dataState,
    } = this.state;
    const activeTab = this.props.params.active || 'active';

    const getSelectedHarvestName = () => {
      const harvest =
        this.props.selectedHarvests && this.props.selectedHarvests.length ? this.props.selectedHarvests[0] : false;
      if (!harvest) return ''; // For safety - shouldn't happen
      return harvest.batch_name;
    };
    return (
      <div>
        <LabsByHarvest showResults={showResults} items={items} hideTestResults={this.hideTestResults} />
        <InProgressOverlay isActive={this.state.loading} message={I18n.t('common.loading')} />
        <PageTitle primaryText={I18n.t('harvestPackages.title')} />
        <TablePageWrapper
          ref={this.ref}
          settingKey='harvests'
          columns={columns}
          data={harvests}
          dataState={dataState}
          tabs={getTabs(
            this.confirmShowModal,
            this.onCreateLot,
            this.updateSearch,
            canSplitHarvest,
            canCreateLots,
            selectedHarvestIds.length > 0 ? selectedHarvestIds[0] : 0
          )}
          activeTab={activeTab}
          switchTab={this.switchTab}
          selectedRows={selectedHarvestIds}
          handleSelect={this.handleSelect}
          className='harvests-page'
          hideExport={true}
          hideScanSearch={true}
          searchString={searchText}
          defaultSort={defaultSort}
          handleFilter={undefined}
          externalSearch={debounce(this.handleSearch, 100)}
          external={true}
          isSolrListing={true}
          useAutoSuggest={this.props.useAutoSuggest}
          autoSuggestPlaceholder='cultivation.finishedProduct.table.suggestPlaceholder'
          dataTotalSize={dataTotalSize}
          bstProps={{
            options: {
              onSearchChange: (searchText) => {
                if (!searchText) {
                  this.props.actions.clearSelectedData(dataNames.harvests);
                }
              }
            },
            selectRow: {
              mode: 'radio',
              unselectable: useEntityLocks ? lockedHarvestIds : []
            },
            trClassName: (row) => this.trClassName(useEntityLocks, row)
          }}
        />

        {/*This modal does the heavy lifting of activating a harvest*/}
        <ModalWrapper
          Component={ActivateHarvestBatchesFormWrapper}
          title='harvestBatch.activate.title'
          onHide={this.hideModal}
          showModal={showModal}
          onSubmit={this.confirmSubmit}
          version={2}
          headerClass='bg-info-dark'
          dialogClassName='modal-lg'
        />

        {/*This modal alerts the user that there is no metrc integration for this action AND alerts them after activating the harvest as well*/}
        <ModalWrapper
          Component={false}
          title='confirmActivateHarvestWithoutIntegration'
          headerClass='bg-info-dark'
          onHide={this.hideConfirmationModal}
          showModal={confirmationModal.show}
          okayButton={{ show: true, onClick: confirmationModal.onConfirm }}
          cancelButton={{ show: true, onClick: this.hideConfirmationModal }}
          dialogClassName='modal-sm'
          version={2}
        >
          <div>{I18n.t(confirmationModal.message)}</div>
        </ModalWrapper>

        {/*This modal allows the user to finalize a harvest that is not already finalized triggered by selecting create lot with an unfinalized harvest.*/}
        <ModalWrapper
          Component={false}
          title='harvestBatch.finalize.title'
          headerClass='bg-info-dark'
          onHide={this.onHideFinalizeHarvest}
          showModal={this.state.showFinalizeHarvest}
          okayButton={{ show: true, onClick: this.onFinalizeHarvest, text: 'harvestBatch.finalize.okayText' }}
          cancelButton={{ show: true, onClick: this.onHideFinalizeHarvest, text: 'harvestBatch.finalize.cancelText' }}
          dialogClassName='modal-sm'
          version={2}
        >
          <div>{I18n.t('harvestBatch.finalize.noticeText', { name: getSelectedHarvestName() })}</div>
        </ModalWrapper>
        <ConnectedIntegrationTrackingStatusModal />
        <LabelPrinter
          showModal={this.state.showLabel}
          onHide={this.onHideLabel}
          labelTag='harvest_batch_plant_label_all'
          payload={this.state.payload}
        />
      </div>
    );
  }
}

HarvestsPage.propTypes = {
  harvests: PropTypes.array.isRequired,
  selectedHarvests: PropTypes.array.isRequired,
  selectedHarvestIds: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  showRating: PropTypes.bool.isRequired,
  timezone: PropTypes.string.isRequired,
  getTabs: PropTypes.func.isRequired,
  integrationState: PropTypes.object.isRequired,
  dataTotalSize: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  needLabResultSetting: PropTypes.bool.isRequired,
  archiveInactiveAfter: PropTypes.number.isRequired,
  complianceSettings: PropTypes.object.isRequired,
  useAutoSuggest: PropTypes.bool,
};



function mapStateToProps(state, ownProps) {
  const status = ownProps.params && ownProps.params.active === 'inactive' ? 'inactive' : 'active';
  const selectedHarvestIds = getSelectedHarvestIds(state);
  const selectedHarvestId =
    Array.isArray(selectedHarvestIds) && selectedHarvestIds.length > 0 ? selectedHarvestIds[0] : 0;

  return {
    showRating: userHasPermission(state, { permissions: [p.rate_products] }),
    selectedHarvests: getSelectedHarvests(state),
    selectedHarvestIds,
    harvests: getHarvestListingData(state, ownProps),
    lockedHarvestIds: getHarvestIdsWhereIsLocked(state),
    timezone: state.timezone,
    getTabs: (onActivateClick, onCreateLot, onUpdateSearch, canSplitHarvest, canCreateLots) =>
      getHarvestsPageTabs(state, {
        onActivateClick,
        onCreateLot,
        onUpdateSearch,
        canSplitHarvest,
        canCreateLots,
        selectedHarvestId
      }),
    integrationState: getIntegrationState(state),
    dataTotalSize: getTotalResults(state, { name: dataNames.harvests }),
    status,
    needLabResultSetting: getLabResultForHarvestsetting(state) ? getLabResultForHarvestsetting(state) : false,
    archiveInactiveAfter: getArchiveInactiveAfter(state),
    complianceSettings: state.complianceSettings,
    cupos: getCupos(state),
    useAutoSuggest: isFeatureEnabled(state)('feature_solr_inventory_suggest'),
    useServiceFirstHarvestBatchesListing: isFeatureEnabled(state)('feature_service_first_harvest_batches_listing'),
    useEntityLocks: getUseEntityLocksForHarvestBatches(state),
    solrCoreName: getSolrCoreName(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, selectedDataActions, { push, setSolrErrorMessage, unsetData });
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HarvestsPage);
