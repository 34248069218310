const yesNo = [
  {text: 'cultivation.complianceSettings.yes', value: 1},
  {text: 'cultivation.complianceSettings.no', value: 0}
];

const fontSize = [
  {text: 'cultivation.complianceSettings.fontSizeUnit', value: 8},
  {text: 'cultivation.complianceSettings.fontSizeUnit', value: 9},
  {text: 'cultivation.complianceSettings.fontSizeUnit', value: 10},
  {text: 'cultivation.complianceSettings.fontSizeUnit', value: 11},
  {text: 'cultivation.complianceSettings.fontSizeUnit', value: 12}
];


module.exports = [
  {
    type        : 'package',
    setting_key : 'label_cult_package_compliance_settings',
    scope       : 'facility',
    name        : 'Package Label',
    configurable: true,
    fields      : [
      {
        name   : 'minimum_font_size',
        value  : 10,
        text   : 'cultivation.labelSettings.minFontSize',
        options: fontSize,
        optionsString: 'fontSize',
        type: 'behavior'
      },
      // ===============================
      {
        name   : 'require_harvest_batch',
        value  : 1,
        text   : 'cultivation.labelSettings.requireHarvestBatchNumber',
        options: yesNo,
        optionsString: 'yesNo',
        type: 'data'
      },
      {
        name   : 'require_weight',
        value  : 1,
        text   : 'cultivation.labelSettings.requireWeight',
        options: yesNo,
        optionsString: 'yesNo',
        type: 'data'
      },
      {
        name   : 'require_lot_number',
        value  : 1,
        text   : 'cultivation.labelSettings.requireLotNumber',
        options: yesNo,
        optionsString: 'yesNo',
        type: 'data'
      },
      // {
      //   name   : 'require_cannabinoid',
      //   value  : 1,
      //   text   : 'cultivation.labelSettings.requireTestResultsCannabinoidPotency',
      //   options: yesNo,
      //   optionsString: 'yesNo',
      //   tags: ['inv_package_tag_large', 'inv_package_tag_2x4'],
      //   type: 'data'
      // },
      // {
      //   name   : 'require_terpene',
      //   value  : 1,
      //   text   : 'cultivation.labelSettings.requireTestResultsTerpene',
      //   options: yesNo,
      //   optionsString: 'yesNo',
      //   tags: ['inv_package_tag_large', 'inv_package_tag_2x4'],
      //   type: 'data'
      // },
      {
        name   : 'require_package_tests',
        value  : 0,
        text   : 'cultivation.labelSettings.requirePackageTests',
        options: yesNo,
        optionsString: 'yesNo',
        tags: ['inv_package_tag_large', 'inv_package_tag_2x4'],
        type: 'data'
      },
      {
        name   : 'require_package_tests_date',
        value  : 0,
        text   : 'cultivation.labelSettings.requirePackageTestsDate',
        options: yesNo,
        optionsString: 'yesNo',
        tags: ['inv_package_tag_large', 'inv_package_tag_2x4'],
        type: 'data'
      },
      //===============================
      {
        name   : 'require_list_of_non_organic',
        value  : 1,
        text   : 'cultivation.labelSettings.requireListOfNonOrganicPesticides',
        options: yesNo,
        optionsString: 'yesNo',
        tags: ['inv_package_tag_large', 'inv_package_tag_2x4'],
        type: 'statement'
      },
      {
        name   : 'require_health_and_benefits_claims',
        value  : 1,
        text   : 'cultivation.labelSettings.requireHealthAndBenefitsClaims',
        options: yesNo,
        optionsString: 'yesNo',
        tags: ['inv_package_tag_large', 'inv_package_tag_2x4'],
        type: 'statement'
      },
      {
        name   : 'require_mandated_statements',
        value  : 1,
        text   : 'cultivation.labelSettings.requireMandatedStatements',
        options: yesNo,
        optionsString: 'yesNo',
        tags: ['inv_package_tag_large', 'inv_package_tag_2x4'],
        type: 'statement'
      },
      {
        name   : 'require_nutritional_facts',
        value  : 1,
        text   : 'cultivation.labelSettings.requireNutritionalFacts',
        options: yesNo,
        optionsString: 'yesNo',
        tags: ['inv_package_tag_large', 'inv_package_tag_2x4'],
        type: 'statement'
      },
      {
        name   : 'require_ingredients',
        value  : 1,
        text   : 'cultivation.labelSettings.requireIngredients',
        options: yesNo,
        optionsString: 'yesNo',
        tags: ['inv_package_tag_large', 'inv_package_tag_2x4'],
        type: 'statement'
      },
      {
        name   : 'require_allergens',
        value  : 1,
        text   : 'cultivation.labelSettings.requireAllergens',
        options: yesNo,
        optionsString: 'yesNo',
        tags: ['inv_package_tag_large', 'inv_package_tag_2x4'],
        type: 'statement'
      },
      {
        name   : 'require_tracking_id',
        value  : 0,
        text   : 'cultivation.labelSettings.requireTrackingID',
        options: yesNo,
        optionsString: 'yesNo',
        tags: ['inv_package_tag_large', 'inv_package_tag_2x4'],
        type: 'statement'
      },
      // =================================
      {
        name   : 'require_cultivation_facility_license',
        value  : 1,
        text   : 'cultivation.labelSettings.requireFacilityLicense',
        options: yesNo,
        optionsString: 'yesNo',
        tags: ['inv_package_tag_large', 'inv_package_tag_2x4'],
        type: 'names'
      },
      {
        name   : 'require_vendor_license',
        value  : 1,
        text   : 'cultivation.labelSettings.requireVendorLicense',
        options: yesNo,
        optionsString: 'yesNo',
        tags: ['inv_package_tag_large', 'inv_package_tag_2x4'],
        type: 'names'
      },
      {
        name   : 'require_vendor_name',
        value  : 1,
        text   : 'cultivation.labelSettings.requireVendorName',
        options: yesNo,
        optionsString: 'yesNo',
        tags: ['inv_package_tag_large', 'inv_package_tag_2x4'],
        type: 'names'
      },
      {
        name   : 'require_facility_name',
        value  : 1,
        text   : 'cultivation.labelSettings.requireFacilityName',
        options: yesNo,
        optionsString: 'yesNo',
        tags: ['inv_package_tag_large', 'inv_package_tag_2x4'],
        type: 'names'
      },
      {
        name   : 'require_facility_address',
        value  : 1,
        text   : 'cultivation.labelSettings.requireFacilityAddress',
        options: yesNo,
        optionsString: 'yesNo',
        tags: ['inv_package_tag_large', 'inv_package_tag_2x4'],
        type: 'names'
      },
      {
        name   : 'require_facility_phone',
        value  : 1,
        text   : 'cultivation.labelSettings.requireFacilityPhone',
        options: yesNo,
        optionsString: 'yesNo',
        tags: ['inv_package_tag_large', 'inv_package_tag_2x4'],
        type: 'names'
      }
    ]
  },
  {
    type        : 'patient',
    setting_key : 'label_cult_patient_compliance_settings',
    scope       : 'facility',
    name        : 'Patient Label',
    configurable: true,
    fields      : [
      {
        name   : 'minimum_font_size',
        value  : 10,
        text   : 'cultivation.labelSettings.minFontSize',
        options: fontSize,
        optionsString: 'fontSize',
        type: 'behavior'
      },
      //=============================================
      {
        name   : 'require_health_and_benefits_claims',
        value  : 1,
        text   : 'cultivation.labelSettings.requireHealthAndBenefitsClaims',
        options: yesNo,
        optionsString: 'yesNo',
        tags: ['patient_label', 'patient_label_2x4'],
        type: 'statement'
      },
      {
        name   : 'require_list_of_non_organic',
        value  : 1,
        text   : 'cultivation.labelSettings.requireListOfNonOrganicPesticides',
        options: yesNo,
        optionsString: 'yesNo',
        tags: ['patient_label', 'patient_label_2x4'],
        type: 'statement'
      },
      {
        name   : 'require_mandated_statements',
        value  : 1,
        text   : 'cultivation.labelSettings.requireMandatedStatements',
        options: yesNo,
        optionsString: 'yesNo',
        tags: ['patient_label', 'patient_label_2x4'],
        type: 'statement'
      },
      //=============================================
      // {
      //   name   : 'require_cannabinoid',
      //   value  : 1,
      //   text   : 'cultivation.labelSettings.requireTestResultsCannabinoidPotency',
      //   options: yesNo,
      //   optionsString: 'yesNo',
      //   tags: ['patient_label', 'patient_label_2x4'],
      //   type: 'data'
      // },
      // {
      //   name   : 'require_terpene',
      //   value  : 1,
      //   text   : 'cultivation.labelSettings.requireTestResultsTerpene',
      //   options: yesNo,
      //   optionsString: 'yesNo',
      //   tags: ['patient_label', 'patient_label_2x4'],
      //   type: 'data'
      // },
      {
        name   : 'require_date_of_sale',
        value  : 1,
        text   : 'cultivation.labelSettings.requireDateOfSale',
        options: yesNo,
        optionsString: 'yesNo',
        type: 'data',
      },
      {
        name   : 'require_patient_diagnosis',
        value  : 1,
        text   : 'cultivation.labelSettings.requirePatientDiagnosis',
        options: yesNo,
        optionsString: 'yesNo',
        tags: ['patient_label', 'patient_label_2x4'],
        type: 'data'
      },
      {
        name   : 'require_harvest_batch',
        value  : 1,
        text   : 'cultivation.labelSettings.requireHarvestBatchNumber',
        options: yesNo,
        optionsString: 'yesNo',
        tags: ['patient_label', 'patient_label_2x4'],
        type: 'data'
      },
      {
        name   : 'require_weight',
        value  : 1,
        text   : 'cultivation.labelSettings.requireWeight',
        options: yesNo,
        optionsString: 'yesNo',
        type: 'data'
      },
      {
        name   : 'require_lot_number',
        value  : 1,
        text   : 'cultivation.labelSettings.requireLotNumber',
        options: yesNo,
        optionsString: 'yesNo',
        type: 'data'
      },
      {
        name   : 'require_product_category',
        value  : 1,
        text   : 'cultivation.labelSettings.requireProductCategory',
        options: yesNo,
        optionsString: 'yesNo',
        type: 'data'
      },
      {
        name   : 'require_package_tests',
        value  : 0,
        text   : 'cultivation.labelSettings.requirePackageTests',
        options: yesNo,
        optionsString: 'yesNo',
        tags: ['patient_label', 'patient_label_2x4'],
        type: 'data'
      },
      {
        name   : 'require_package_tests_date',
        value  : 0,
        text   : 'cultivation.labelSettings.requirePackageTestsDate',
        options: yesNo,
        optionsString: 'yesNo',
        tags: ['patient_label', 'patient_label_2x4'],
        type: 'data'
      },
      // ==========================================
      {
        name   : 'require_vendor_name',
        value  : 1,
        text   : 'cultivation.labelSettings.requireVendorName',
        options: yesNo,
        optionsString: 'yesNo',
        tags: ['patient_label', 'patient_label_2x4'],
        type: 'names'
      },
      {
        name   : 'require_cultivation_facility_license',
        value  : 1,
        //text   : 'cultivation.labelSettings.requireRetailStoreLicense', // this mis match between name and text are intentional
        text: 'cultivation.labelSettings.requireFacilityLicense',
        options: yesNo,
        optionsString: 'yesNo',
        type: 'names'
      },
      {
        name   : 'require_vendor_license',
        value  : 1,
        text   : 'cultivation.labelSettings.requireVendorLicense',
        options: yesNo,
        optionsString: 'yesNo',
        tags: ['patient_label', 'patient_label_2x4'],
        type: 'names'
      },
      {
        name   : 'require_facility_name',
        value  : 1,
        text   : 'cultivation.labelSettings.requireFacilityName',
        options: yesNo,
        optionsString: 'yesNo',
        type: 'names'
      },
      {
        name   : 'require_facility_address',
        value  : 1,
        text   : 'cultivation.labelSettings.requireFacilityAddress',
        options: yesNo,
        optionsString: 'yesNo',
        tags: ['patient_label', 'patient_label_2x4'],
        type: 'names'
      },
      {
        name   : 'require_facility_phone',
        value  : 1,
        text   : 'cultivation.labelSettings.requireFacilityPhone',
        options: yesNo,
        optionsString: 'yesNo',
        tags: ['patient_label', 'patient_label_2x4'],
        type: 'names'
      },
      {
        name   : 'require_patient_registry_number',
        value  : 1,
        text   : 'cultivation.labelSettings.requirePatientRegistryNumber',
        options: yesNo,
        optionsString: 'yesNo',
        type: 'names'
      },
      {
        name   : 'require_physician_name',
        value  : 1,
        text   : 'cultivation.labelSettings.requirePhysicianName',
        options: yesNo,
        optionsString: 'yesNo',
        tags: ['patient_label', 'patient_label_2x4'],
        type: 'names'
      },
      {
        name   : 'require_physician_license',
        value  : 1,
        text   : 'cultivation.labelSettings.requirePhysicianLicense',
        options: yesNo,
        optionsString: 'yesNo',
        tags: ['patient_label', 'patient_label_2x4'],
        type: 'names'
      },
      {
        name   : 'require_physician_diagnosis',
        value  : 1,
        text   : 'cultivation.labelSettings.requirePhysicianDiagnosis',
        options: yesNo,
        optionsString: 'yesNo',
        tags: ['patient_label', 'patient_label_2x4'],
        type: 'names'
      }
    ]
  }
];

