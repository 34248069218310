import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { getInternationalNumberFormat } from '../../../selectors/InternationalOperationsSelectors';
import { fieldFormatHelperDecimals } from '../../../util/internationalHelpers';
import ReduxBootstrapInput from './ReduxBootstrapInput';
import InternationalNumericInputControl from './InternationalNumericInputControl';

const InternationalDecimalReadOnlyInput = (mainProps) => {

  // lets' flatten the props
  const {props, input, ...rest} = mainProps;

  const allProps = {
    ...rest,
    ...input,
    ...props,
  };

  const {value, name, label, size, children, isRequired, fractionPartSize, dirty, ...otherProps } = allProps;

  // This is how we want it displayed in the component
  const fieldFormatter = (value, name) => {
    return fieldFormatHelperDecimals(value, name, allProps.internationalNumberFormat);
  };

  const controlInput = {
    name,
    onChange: () => {},
    value : fieldFormatter(value,name),
  };

  return (
    <ReduxBootstrapInput Control={InternationalNumericInputControl}
                         dirty={dirty}
                         label={label} size={size}
                         input={controlInput} meta={{}}
                         name={name} isRequired={isRequired}
                         fractionPartSize={fractionPartSize}
                         type='text' disabled={true} {...otherProps} >
      {children}
    </ReduxBootstrapInput>
  );
};

InternationalDecimalReadOnlyInput.propTypes = {
  name: PropTypes.string,
  internationalNumberFormat: PropTypes.string.isRequired,
  fractionPartSize: PropTypes.number,
};

InternationalDecimalReadOnlyInput.defaultProps = {
  shouldRound: false,
  fractionPartSize: -1
};

function mapStateToProps(state, ownProps){
  return {
    internationalNumberFormat: getInternationalNumberFormat(state),
  };
}

export default connect(mapStateToProps)(InternationalDecimalReadOnlyInput);
