import React from 'react';
import PropTypes from 'prop-types';
import {Image} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';

import FileInput from '../../../common/form/FileInput';
import {EQUIPMENT_MAX_UPLOAD_SIZE_DISPLAY, FILE_TYPES, CULTIVATION_EQUIPMENT_PHOTO} from '../../../../constants/fileUploads';
import * as itemNames from '../../../../constants/itemNames';

const EquipmentImage = (props) => {
  const {images, change, uploading, addImageRef, changeUploadStatus, name, setImage, index} = props;
  return (
    <div className='flex layout-column layout-align-start image-section'>
      <div className='layout-row layout-align-start-start'>
        <Image thumbnail className='product-image scale-down' src={images[index]}/>
        <div style={{paddingLeft: '6px'}}>
          <h5>{I18n.t('equipment.form.equipmentImage')}</h5>
          <p>{I18n.t('common.form.sizeLimit', {limit: EQUIPMENT_MAX_UPLOAD_SIZE_DISPLAY})}</p>
          <p>{I18n.t('common.form.typeLimit', {extensions: FILE_TYPES[CULTIVATION_EQUIPMENT_PHOTO].extensions})}</p>
        </div>
      </div>
      <div className='layout-row layout-align-end-center'>
        <Field
          name={name + '_button'}
          itemName={itemNames.image}
          component={FileInput}
          props={{
            ...FILE_TYPES[CULTIVATION_EQUIPMENT_PHOTO],
            disabled: uploading,
            btnContent: uploading ? I18n.t('equipment.form.uploadingPhoto') : I18n.t('equipment.form.uploadPhoto'),
            handleRef: addImageRef,
            onUploadStart: () => changeUploadStatus(true),
            onChangeCb: (image) => {
              change(name, image.id);
              changeUploadStatus(false);
              setImage(image.url ? image.url.original : '', index);
            },
            btnProps: {
              className: 'btn btn-default'
            }
          }}/>
      </div>
    </div>
  );
};

EquipmentImage.propTypes = {
  images: PropTypes.array,
  name: PropTypes.string.isRequired,
  changeUploadStatus: PropTypes.func.isRequired,
  addImageRef: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  setImage: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired
};

export default EquipmentImage;
