import get from 'lodash.get';
import omit from 'lodash.omit';
import {pick} from 'lodash';
import round from 'lodash.round';
import moment from 'moment';
import {createSelector} from 'reselect';
import {formValueSelector} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import * as dataNames from '../constants/dataNames';
import * as itemNames from '../constants/itemNames';
import * as statuses from '../constants/orderStatuses';
import {purchaseOrderForm} from '../constants/formNames';
import {isActiveFacilityDispensary, isLeafPaConfigPackClosedLoopFacility} from './facilitiesSelectors';
import {getAvailableRegisters} from './registersSelectors';
import {isIncomingTransferMappingEnabled, getIntegrationState} from './integration/integrationSelectors';
import { getGroupedItemMasterChildren, isAllowInitialInventory } from './fillPurchaseOrderSelectors';
import {getPartnersForPurchase} from './partnersSelectors';
import {isLeafIntegrator} from './integration/leafSelectors';
import {isItemMasterMedicated} from './itemMastersSelectors';
import {convertFromBase, isBulkType} from '../util/uomHelpers';
import {
  convertFormInputDateToDbDate,
  getToday,
  convertDbDateToFormInputDate,
  convertDbTimeToClientTzMoment,
  convertDbDateTimeToClientTzMoment,
  convertFormInputDateToDbTime,
  formatClientDate
} from '../util/dateHelpers';
import {getFacilityStrains} from './facilityStrainsSelectors';
import { findRoutePermissions, getUrlParam } from '../util/routeHelper';
import {isSubCategoryClones, isSubCategorySeeds} from './categorySelectors';
import {isMetrcTransfersEnabled} from './integration/metrcSelectors';
import { getLineTotals } from '../components/transfers/common/line-items/lineItemsHelpers';
import {isFeatureEnabled, isLeafPaDisallowRetailTransferOfPlantSubtypesEnabled} from './featureToggles';
import {isNonTransferableItemMasterForLeafPa} from '../components/transfers/helpers';


export const getParentPurchaseOrder = (state) => state[itemNames.parentPurchaseOrder];
export const getPurchaseOrder = (state) => state[itemNames.purchaseOrder];
const getPartnerFacilities = (state) => state[dataNames.partnerFacilities];
export const getPartnerFacilityDetails = (state) => state[itemNames.partnerFacilityDetails];
export const getItemMasters = (state) => state[dataNames.itemMasters];
const getCategories = (state) => state[dataNames.categories];
const getVendorItemMasters = (state) => state[dataNames.vendorItemMasters];
const getCurrentTimezone = state => state.timezone;
const getPurchaseOrders = (state) => state[dataNames.purchaseOrders];
const getSelectedPurchaseOrders = (state) => state[dataNames.selectedPurchaseOrders];
const hasFeatureLeafPaConfigurationPack = (state) => isFeatureEnabled(state)('feature_leaf_pa_configuration_pack');

const mapProductForPO = strains => product => ({
  ...product,
  has_facility_strain: strains.some(strain => strain.id === product.strain_id),
  item_master_id: product.item_master_id ? product.item_master_id : product.id
});

const formSelector = formValueSelector(purchaseOrderForm);
export const getMedicatedStatus = state => formSelector(state, 'contains_medicated');
const getSelectedFacilityId = state => formSelector(state,'vendor_facility_id');
export const getInitialMode = state => formSelector(state,'is_initial_mode');
const getPaymentStatus = state => formSelector(state, 'payment_status');
const getOrderLines = state => formSelector(state, 'lines');

const getActions = (_, props) => props.actions;
const getPartner = (_, props) => props ? props.partner_id : null;

export const isMedicatedStatusSelected = createSelector(
  getMedicatedStatus, containsMedicated => containsMedicated === 0 || containsMedicated === 1
);

export const getCreatePurchaseOrderInitialValues = createSelector(
  [isAllowInitialInventory, getPartnersForPurchase],
  (allowInitialInventory, partners) => {
    const today = getToday();
    const orgData = getUrlParam('org'); // Case when po is started from partners screen
    return {
      lines: [{}],
      payments: [{}],
      date_ordered: today,
      taxes: '0.00',
      transfer_fee: '0.00',
      discount_percent: '0.00',
      order_total: '0.00',
      balance: '0.00',
      total: '0.00',
      contains_medicated: allowInitialInventory ? 1 : null,
      contains_only_cbd_flower_or_hemp_waste: false,
      title: I18n.t('purchaseOrders.form.purchaseOrder') + ' ' + formatClientDate(today),
      oddMoney: {
        payment_type: 'cash',
      },
      is_initial_mode: allowInitialInventory,
      partner_id: partners && partners.length === 1
        ? parseInt(partners[0].id)
        : orgData ? parseInt(orgData.toString().split(':').shift()) : null,
      vendor_facility_id: orgData ? parseInt(orgData.toString().split(':').pop()) : null,
    };
  });


const getVendorItemMastersSelector = createSelector(
  [getVendorItemMasters, getFacilityStrains],
  (itemMasters, strains) => itemMasters.map(mapProductForPO(strains))
);


/**
 * If vendor is a retail facility and purchaser is not then the PO should be considered
 * a return for Leaf PA integrated sites.
 *
 * @return bool
 */
export const isLeafReturn = createSelector(
  [getPartnerFacilityDetails, isLeafPaConfigPackClosedLoopFacility, isActiveFacilityDispensary,getPurchaseOrder],
  (partnerFacility, isLeafPaConfigPackClosedLoopFacility, isActiveFacilityDispensary,purchaseOrder) => {
    // If not a PA closed loop facility then return false;
    if (!hasFeatureLeafPaConfigurationPack || !isLeafPaConfigPackClosedLoopFacility) {
      return false;
    }
    const poIsReturn = get(purchaseOrder, 'is_return', false);
    const partnerFacilityIsDispensary = get(partnerFacility, 'type', '') === 'dispensary';
    if(!isActiveFacilityDispensary && poIsReturn){
      return !isActiveFacilityDispensary && poIsReturn;
    }
    return !isActiveFacilityDispensary && partnerFacilityIsDispensary;
  }
);


const getPurchaseOrderEvents = (state) => state[dataNames.purchaseOrderEvents];

/***
 * Provides a unique id based on attributes to compare one line to another to flag changes
 * @param line
 * @param fields
 * @returns {string}
 */
const getLineHash = (line, fields = []) => {
  if(!Array.isArray(fields) || !fields.length) fields = ['item_master_id', 'qty', 'line_item_price', 'unit_price'];
  return JSON.stringify(fields.reduce((acc, field) => {
    acc[field] = parseFloat(get(line, field, ''));
    return acc;
  }, {}));
};

export const getModifyPurchaseOrderInitialValues = createSelector(
  [getPurchaseOrder, getVendorItemMastersSelector, getGroupedItemMasterChildren, getPartnerFacilities, getCurrentTimezone, getIntegrationState],
  (purchaseOrder, itemMasters, groupedItemMasterChildren, partnerFacilities, timezone, {isMetrc}) => {

    if (!purchaseOrder.id || !itemMasters.length) {
      return;
    }

    const poIsReturn = get(purchaseOrder, 'is_return', false);

    const {vendor_facility_id} = purchaseOrder;
    const partnerFacility = partnerFacilities.find(facility => facility.id === vendor_facility_id);

    const getPurchaseOrderStatusFromAttributes = (returnBoolean = false) => {
      let status = 'active';
      switch(true){
        case ['ordered', 'partial'].indexOf(purchaseOrder.payment_status) !== -1: // eslint-disable-line indent
        case purchaseOrder.payment_status === 'completed' && !purchaseOrder.received:  // eslint-disable-line indent
          break;  // eslint-disable-line indent
        default:  // eslint-disable-line indent
          status = 'inactive';  // eslint-disable-line indent
      }

      return (returnBoolean)
        ? (status === 'active')
        : status;
    };

    const canStatusBeChanged = () => {
      if (isMetrc && get(purchaseOrder, 'is_imported')) return false;
      const status = getPurchaseOrderStatusFromAttributes(true);
      if(status && !purchaseOrder.received && purchaseOrder.payment_status === 'ordered') return true;
      if(!status && !purchaseOrder.received) return true;
      return false;
    };
    const oddMoney = (purchaseOrder.payments || []).find(p => p.amount < 0) || {
      payment_type: 'cash',
    };
    if (oddMoney.payment_date) {
      oddMoney.payment_date = convertDbDateToFormInputDate(oddMoney.payment_date, timezone);
      oddMoney.amount = parseFloat(oddMoney.amount || 0).toFixed(2);
    }

    return {
      ...purchaseOrder,
      partner_id: partnerFacility && partnerFacility.partner_id,
      is_return: poIsReturn,
      is_initial_mode: purchaseOrder.is_initial,
      date_ordered: convertDbDateTimeToClientTzMoment(purchaseOrder.date_ordered, timezone),
      date_expected: convertDbDateTimeToClientTzMoment(purchaseOrder.date_expected, timezone),
      time_expected: convertDbTimeToClientTzMoment(purchaseOrder.time_expected, timezone),
      taxes: parseFloat(purchaseOrder.taxes || 0).toFixed(2),
      transfer_fee: parseFloat(purchaseOrder.transfer_fee || 0).toFixed(2),
      discount_percent: parseFloat(purchaseOrder.discount_percent || 0).toFixed(2),
      order_total: parseFloat(purchaseOrder.order_total || 0).toFixed(2),
      balance: parseFloat(purchaseOrder.balance || 0).toFixed(2),
      total: parseFloat(purchaseOrder.order_total || 0).toFixed(2),
      status: getPurchaseOrderStatusFromAttributes(),
      canStatusBeChanged: canStatusBeChanged(),
      payments: (purchaseOrder.payments || []).filter(p => p.amount >= 0).map(payment => ({
        ...payment,
        payment_date: moment(payment.payment_date),
        amount: parseFloat(payment.amount || 0).toFixed(2),
      })),
      oddMoney,
      lines: (purchaseOrder.lines || []).reduce(
        (acc, line) => {
          const itemMaster = itemMasters.find(master => master.item_master_id === line.item_master_id);
          if (!itemMaster) return acc;
          const orderLine = {
            ...line,
            unit_price: parseFloat(line.unit_price || 0).toFixed(2),
            line_item_price: parseFloat(line.line_item_price || 0).toFixed(2),
            itemMaster,
          };
          if (itemMaster.inventory_attributes && itemMaster.inventory_attributes.is_prepack) {
            orderLine.lineType = 'prepack';
            const itemMasterChildren = groupedItemMasterChildren[itemMaster.item_master_id] || [];
            orderLine.subitems = line.subitems.reduce(
              (acc, subItem) => {
                const childItemMaster = (itemMasterChildren.find(i => i.item_master_id === subItem.item_master_id) || {noChild: true});
                return acc.concat([{
                  ...childItemMaster,
                  ...subItem,
                  total: childItemMaster && childItemMaster.itemWeight ? convertFromBase(childItemMaster.itemWeight.weight_base, childItemMaster.itemWeight.uom) * (subItem.qty || 0) : 0,
                  unit_price: parseFloat(subItem.unit_price || 0).toFixed(2)
                }]);
              },
              []
            );
          } else if(isBulkType(itemMaster.uom_type)) {
            orderLine.lineType = 'bulk';
          } else if (itemMaster.uom_type === 'discrete') {
            orderLine.lineType = 'unit';
          }
          return acc.concat([orderLine]);
        },
        []
      )
    };
  }
);

export const getPartnerFacility = createSelector(
  getSelectedFacilityId, getPartnerFacilities,
  (selectedPartnerFacilityId, partnerFacilities) =>
    partnerFacilities.find(facility => facility.id === selectedPartnerFacilityId)
);

/**
 * Get Purchase Order Product options
 * @return {Array}
 */
export const getProductOptions = createSelector(
  [
    getItemMasters, isIncomingTransferMappingEnabled, getIntegrationState, getMedicatedStatus, getFacilityStrains,
    getInitialMode, getPartner, getPartnerFacilityDetails, isLeafReturn, isLeafPaDisallowRetailTransferOfPlantSubtypesEnabled,
    isActiveFacilityDispensary
  ],
  (
    itemMasters, forceMedicatedMapping, integrationState, medicatedStatus, strains,
    isInitialMode, partnerId, partnerFacility, isLeafReturn, isLeafPaDisallowRetailTransferOfPlantSubtypesEnabled,
    isActiveFacilityDispensary
  ) => {
    const {isLeaf, isPaLeaf} = integrationState;
    const partnerFacilityIsDispensary = get(partnerFacility, 'type', '') === 'dispensary';
    const products = itemMasters.filter((itemMaster) => {
      if (
        isLeafPaDisallowRetailTransferOfPlantSubtypesEnabled &&
        isPaLeaf &&
        partnerFacility &&
        isNonTransferableItemMasterForLeafPa(
          isActiveFacilityDispensary,
          partnerFacilityIsDispensary,
          itemMaster.subcategory_code
        )
      ) return false;

      if (isPaLeaf && isInitialMode && !(isSubCategorySeeds(itemMaster.subcategory) || isSubCategoryClones(itemMaster.subcategory))) {
        return false;
      }

      if (forceMedicatedMapping) {
        //For Leaf and Metrc, do not show products until status is selected
        if (medicatedStatus === null) {
          return false;
        }
        //And show only medicated/non-medicated items depending on status selected
        const itemMedicatedStatus = isItemMasterMedicated(itemMaster, isLeaf);
        return medicatedStatus ? itemMedicatedStatus : !itemMedicatedStatus;
      }

      return true;
    });



    const sortedProducts = products
      .map(mapProductForPO(strains))
      .sort((first, second) => {
        return  get(first,'name','').toLowerCase() !== get(second,'name','').toLowerCase() ?
          (first.name.toLowerCase() < second.name.toLowerCase() ? -1 : 1) :
          0;
      });

    // Not filtering the list of Item Masters if is_return and leaf since that was the behavior before TGC-580
    // console.log('isPaLeaf && isLeafReturn', partnerId, isPaLeaf, isLeafReturn);
    if (partnerId && !(isPaLeaf && isLeafReturn)) {
      return sortedProducts.filter((itemMaster) => {
        return itemMaster.vendors && itemMaster.vendors.find(vendor => vendor.partner_id == partnerId);
      });
    }
    return sortedProducts;
  }
);

/**
 * Check if there are available products
 * @return {boolean}
 */
export const hasItemMasters = createSelector(getItemMasters, itemMasters => itemMasters.length > 0);

/**
 * Check whether all available products are medicated
 * @return {Array}
 */
export const hasAllMedicated = createSelector(
  [getItemMasters, isLeafIntegrator],
  (itemMasters, isLeaf) => itemMasters.every(itemMaster => isItemMasterMedicated(itemMaster, isLeaf))
);

/**
 * Check whether all available products are non-medicated
 * @return {Array}
 */
export const hasAllNonMedicated = createSelector(
  [getItemMasters, isLeafIntegrator],
  (itemMasters, isLeaf) => itemMasters.every(itemMaster => !isItemMasterMedicated(itemMaster, isLeaf))
);

/**
 * Check whether all lines on the purchase order are for CBD Flower or Hemp Waste products
 * @return {boolean}
 */
export const hasAllCbdFlowerLines = createSelector(
  [getOrderLines, getCategories], (orderLines, categories) => {
    if (!orderLines || !categories) {
      return false;
    }

    const cbdFlowerOrHempWasteCategory = categories.find(cat => (cat.category_code === 'CBD_FLOWER') || (cat.category_code === 'HEMP_WASTE'));
    if (!cbdFlowerOrHempWasteCategory) {
      return false;
    }

    return orderLines.every(orderLine =>
      Object.keys(orderLine).length && orderLine.itemMaster && orderLine.itemMaster.category_id === cbdFlowerOrHempWasteCategory.id
    );
  }
);

export const getRegistersWithNoRegisterOption = createSelector([getAvailableRegisters], (registers) => {
  if(!Array.isArray(registers)) return registers;
  if(registers.length) return registers;
  const withNoRegisterOption = registers.map((register) => register);
  withNoRegisterOption.unshift({
    name: 'No Register',
    id  : 0
  });
  return withNoRegisterOption;
});

export const getRegistersByType = createSelector([getRegistersWithNoRegisterOption], (registers) => {
  const defaultRegisters = {
    cash: [],
    others: [],
  };
  if(!Array.isArray(registers)) return defaultRegisters;
  return {
    cash: registers,
    others: registers
  };
});

export const getOrderTotals = (lines, fee, taxes, discountPercent, payments) => {

  const linesTotal = (lines || []).reduce(
    (acc, line) => {


      // Well, this code below creates problems with decimals because  qty * price is not always equal to the line_item_price if the latter is being overriden by a user;
      // even when we recalculate the price, limit on precision inherited from money fields (we cannot charge half a cent, can we?) is preventing the inverse function to be accurate.
      // Since we need only one source of truth we will forget about the editable field state and keep the line_item_price displayed in the form as the one and only source of truth.

      // const totals = (line.editableLinePrice !== undefined && line.editableLinePrice)
      //   ? parseFloat(line.line_item_price)
      //   : getLineTotals(line.subitems);
      //
      // if (totals.price) {
      //   return acc + totals.price;
      // }
      return acc + round(line.line_item_price || 0, 2);
    },
    0
  );
  const subtotal = linesTotal + round(fee, 2) + round(taxes, 2);
  const discountMultiplier = discountPercent / 100;
  const discount = round(linesTotal * discountMultiplier, 2);
  const total = round(subtotal - discount, 2);
  const totalPayments = (payments || []).reduce(
    (acc, payment) => acc + round(payment.amount || 0, 2),
    0
  );
  const oddMoney = totalPayments > total ? round(total - totalPayments, 2) : 0;
  const balance = totalPayments > total ? 0 : round(total - totalPayments, 2);

  return {
    balance,
    total,
    oddMoney,
  };
};

export const getCreatePayload = (formData, balance, total, timezone) => {

  const initialPayment = formData.oddMoney && formData.oddMoney.amount <= -0.01 ? [
    {
      ...pick(formData.oddMoney, 'id', 'amount', 'payment_type', 'register_id', 'user_id'),
      payment_date: convertFormInputDateToDbDate(formData.oddMoney.payment_date, timezone),
    },
  ] : [];

  const getDateAtMidnight = (date) => {
    if(typeof date === 'object' && date instanceof moment) {
      date = date.startOf('day');
    }
    return convertFormInputDateToDbDate(date, timezone);
  };

  return {
    ...formData,
    date_expected: getDateAtMidnight(formData.date_expected),
    date_ordered: getDateAtMidnight(formData.date_ordered),
    time_expected: convertFormInputDateToDbTime(formData.time_expected, timezone),
    balance,
    total,
    order_total: total,
    is_initial: formData.is_initial_mode,
    payments: (formData.payments || []).reduce(
      (acc, payment) => {
        if (payment.payment_type && payment.amount) {
          return acc.concat([{
            ...pick(payment, 'id'),
            amount: payment.amount,
            payment_type: payment.payment_type,
            register_id: payment.register_id || '',
            user_id: payment.user_id,
            payment_date: convertFormInputDateToDbDate(payment.payment_date, timezone) || '',
          }]);
        }
        return acc;
      },
      initialPayment
    ),
    lines: formData.lines.filter((line) => line.showEditors || line.showEditors === undefined).reduce((acc, line) => {
      if(Object.keys(line).length === 0) return acc;
      if (!get(line, 'item_master_id')) return acc;
      const orderLine = omit(line, ['lineType', 'itemMaster', 'subitems']);
      if (line.subitems && line.subitems.length) {
        const totals = getLineTotals(line.subitems);
        orderLine.qty = totals.weight;
        orderLine.subitems = line.subitems.reduce((acc, weight) => {
          // this was commented because it was preventing the unit_price for the sub item to be saved, event though there was no
          // quantity for it
          //if (weight.qty === 0) return acc;
          const item = {
            ...pick(weight, ['id', 'purchase_order_line_id', 'qty', 'unit_price', 'item_master_id']),
            unit_price: round(weight.unit_price, 2),
          };
          acc.push(item);
          return acc;
        }, []);
      } else {
        orderLine.qty = orderLine.qty || 0;
        orderLine.line_item_price = round(orderLine.line_item_price || 0, 2);
      }
      orderLine.line_item_price = orderLine.line_item_price || 0;
      orderLine.unit_price = orderLine.unit_price || 0;
      acc.push(orderLine);
      return acc;
    }, []),
  };
};

export const getModifyPayload = (formData, balance, total, timezone, purchaseOrder) => {
  const payload = getCreatePayload(formData, balance, total, timezone);
  return {
    ...omit(payload, 'po_number'),
    delete_lines: purchaseOrder.lines.filter(line => !(formData.lines || []).find(l => l.id === line.id))
      .map(line => line.id),
    delete_payments: purchaseOrder.payments
      .filter(payment => !(payload.payments || []).find(p => p.id === payment.id))
      .map(p => p.id),
  };
};

export const getPurchaseOrderListingTabs = createSelector(
  [isIncomingTransferMappingEnabled, getActions, getSelectedPurchaseOrders, getIntegrationState, isMetrcTransfersEnabled],
  (forceMedicatedMapping, actions, selectedPurchaseOrders, {isMetrc}, isMetrcTransfersEnabled) => {
    let tabs;
    const createOrderAction = {
      id: 'createOrder',
      path: '/purchase-orders/create',
      text: 'cultivation.purchaseOrders.actions.create',
      glyph: 'plus',
      requireSelect: false
    };
    const printOrderAction = {
      id: 'printOrder',
      func: actions.printOrder,
      text: 'purchaseOrders.printPurchaseOrder',
      requireSelect: true,
      glyph: 'print',
    };
    const activePurchaseOrderActions = [
      createOrderAction,
      printOrderAction,
      {
        id: 'recieve',
        func: actions.receiveTransfer,
        path: '/inventory-receipts/create',
        text: 'cultivation.purchaseOrders.actions.receive',
        requireSelect: true,
        disabled: isMetrc && get(selectedPurchaseOrders, `${selectedPurchaseOrders.length - 1}.is_imported`),
      }
    ];

    if (isMetrc && isMetrcTransfersEnabled) {
      activePurchaseOrderActions.push({
        id: 'metrcRetrieve',
        func: actions.retrieveMetrcTransfers,
        text: 'cultivation.purchaseOrders.actions.metrcRetrieve'
      });
    }

    if (forceMedicatedMapping) {
      tabs = [
        {
          id: 'purchaseMedicatedTab',
          eventKey: statuses.medicatedPurchase,
          title: 'purchaseOrders.viewMedicatedOrders',
          route: '/purchase-orders/active/medicated',
          actions: activePurchaseOrderActions,
          permissions: findRoutePermissions('/purchase-orders/active/medicated')
        },
        {
          id: 'purchaseNonMedicatedTab',
          eventKey: statuses.nonMedicatedPurchase,
          title: 'purchaseOrders.viewNonMedicatedOrders',
          route: '/purchase-orders/active/non-medicated',
          actions: activePurchaseOrderActions,
          permissions: findRoutePermissions('/purchase-orders/active/medicated')
        }
      ];
    }
    else {
      tabs = [
        {
          id: 'purchaseActiveTab',
          eventKey: statuses.activePurchase,
          title: 'purchaseOrders.viewActiveOrders',
          route: '/purchase-orders/active',
          actions: activePurchaseOrderActions,
          permissions: findRoutePermissions('/purchase-orders/active/medicated')
        }
      ];
    }
    tabs = tabs.concat([
      {
        id: 'purchaseInactiveTab',
        eventKey: statuses.inactivePurchase,
        title: 'purchaseOrders.viewInactiveOrders',
        route: '/purchase-orders/inactive',
        actions: [
          createOrderAction,
          printOrderAction
        ],
        permissions: findRoutePermissions('/purchase-orders/active/medicated')
      },
      {
        id: 'inventoryActiveTab',
        eventKey: statuses.activeInventory,
        title: 'purchaseOrders.viewActiveReceipts',
        route: '/inventory-receipts',
        apiRoute: '/api/receipts/active',
        actions: []
      },
      {
        id: 'inventoryInactiveTab',
        eventKey: statuses.inactiveInventory,
        title: 'purchaseOrders.viewInactiveReceipts',
        route: '/inventory-receipts/inactive',
        apiRoute: '/api/receipts/inactive',
        actions: []
      }
    ]);
    return tabs;
  }
);

export const getPurchaseOrderHistory = createSelector([getPurchaseOrders, getPurchaseOrderEvents, getItemMasters], (purchaseOrders, purchaseOrderEvents, itemMasters) => {

  const getFloat = (value) => parseFloat(value);
  const getInt = (value) => parseInt(value);
  const isTrue = (value) => !!value;
  const getValue = (value) => value;

  const poFieldGetters = {
    connect_status: getValue,
    discount_percent: getFloat,
    internal_sales_order_linked: isTrue,
    is_return: isTrue,
    order_total: getFloat,
    payment_status: getValue,
    payment_terms: getValue,
    received: isTrue,
    taxes: getFloat,
    time_expected: getValue,
    vendor_facility_id: getInt
  };

  const poFields = Object.keys(poFieldGetters);

  // Handle multiple purchase orders with multiple events
  const poEvents = purchaseOrderEvents.map((event) => {
    if(event.events) return event;
    return Object.assign({}, event, {events: [event], id: event.entity_id});
  });

  return poEvents.sort((a,b) => a.id - b.id)
    .reduce((acc, event) => {
      const purchaseOrder = purchaseOrders.find((po) => po.id === event.id);
      return acc.concat(!event.events ? [] : event.events.map((event) => {
        const model = JSON.parse(event.model);
        return Object.assign({}, purchaseOrder, event, model, {eventId: event.id, model: JSON.parse(event.model)});
      }));
    }, []).map((row, index, inputArray) => {

      const previousRow = index === 0 ? {} : inputArray[index - 1];
      const currentRow = row;
      const currentRowHash = getLineHash(currentRow, poFields);
      const previousRowHash = getLineHash(get(previousRow, 'model', {}), poFields);

      const modifiedFields = (Object.keys(previousRow).length && currentRowHash !== previousRowHash)
        ? poFields.reduce((acc, field) => {
          const getterFunction = poFieldGetters[field];
          const previousRowValue = getterFunction(previousRow[field]);
          if(previousRowValue === getterFunction(currentRow[field])) return acc;
          acc[field] = previousRowValue;
          return acc;
        }, {})
        : {};

      const modifiedLines = (row && row.lines || []).reduce((acc, line) => {
        const itemMasterId = line.item_master_id;
        const itemMaster = itemMasters.find((itemMaster) => itemMaster.id === line.item_master_id);
        const previousLineVersion = previousRow.lines && Array.isArray(previousRow.lines) ? previousRow.lines.find((line) => line.item_master_id === itemMasterId) : {};
        const previousLine = getLineHash(previousLineVersion);
        const currentLine = getLineHash(line);
        if(previousLine !== currentLine && get(previousLineVersion, 'qty', undefined) !== undefined){
          acc.push(Object.assign({}, line, {
            name: itemMaster ? itemMaster.name : 'Unknown',
            initial: {
              qty: get(previousLineVersion, 'qty', 0),
              unit_price: get(previousLineVersion, 'unit_price', 0),
              line_item_price: get(previousLineVersion, 'line_item_price', 0),
            }
          }));
        }
        return acc;
      }, []);

      const previousRowItemIds = (previousRow.lines || []).map((item) => item.item_master_id);
      const currentRowItemIds = (currentRow.lines || []).map((item) => item.item_master_id);
      const deletedItemIds = previousRowItemIds.filter((id) => currentRowItemIds.indexOf(id) === -1);
      const deletedLines = (previousRow.lines || []).reduce((acc, line) => {
        if(deletedItemIds.indexOf(line.item_master_id) === -1) return acc;
        const itemMaster = itemMasters.find((itemMaster) => itemMaster.id === line.item_master_id);
        acc.push(Object.assign({}, line, {name: itemMaster ? itemMaster.name : 'Unknown'}));
        return acc;
      }, []);

      const currentRowPayments = get(currentRow, 'model.payments', []);
      const previousRowPayments = get(previousRow, 'model.payments', []);
      const currentRowPaymentsString = JSON.stringify(get(currentRow, 'model.payments', []));
      const previousRowPaymentsString = JSON.stringify(get(previousRow, 'model.payments', []));

      const modifiedPayments = (previousRowPaymentsString !== currentRowPaymentsString)
        ? currentRowPayments.length >= previousRowPayments.length
          ? currentRowPayments.reduce((acc, currentPayment, index) => {
            acc.push({
              previousType: get(previousRowPayments, `${index}.payment_type`, ''),
              previousAmount: get(previousRowPayments, `${index}.amount`, ''),
              currentType: get(currentRowPayments, `${index}.payment_type`, ''),
              currentAmount: get(currentRowPayments, `${index}.amount`, ''),
            });
            return acc;
          }, [])
          : previousRowPayments.reduce((acc, currentPayment, index) => {
            acc.push({
              previousType: get(previousRowPayments, `${index}.payment_type`, ''),
              previousAmount: get(previousRowPayments, `${index}.amount`, ''),
              currentType: get(currentRowPayments, `${index}.payment_type`, ''),
              currentAmount: get(currentRowPayments, `${index}.amount`, ''),
            });
            return acc;
          }, [])
        : [];

      return modifiedLines.length || deletedLines.length || modifiedFields.length || modifiedPayments.length || index === 0
        ? Object.assign({}, row, {index, poId: row.id, id: index, modifiedLines, deletedLines, modifiedFields, modifiedPayments})
        : null;
    }).filter((event) => event !== null);

});

export const getModifiedLinesByItemMaster = createSelector([getPurchaseOrderHistory], (history) => {
  return history.reverse().reduce((idsAcc, event) => {
    event.deletedLines.forEach((line, index) => {
      if(idsAcc[line.item_master_id]) return idsAcc;
      idsAcc[line.item_master_id] = Object.assign({}, line, {deleted: true});
    });
    event.modifiedLines.forEach((line, index) => {
      if(idsAcc[line.item_master_id]) return idsAcc;
      idsAcc[line.item_master_id] = line;
    });
    return idsAcc;
  }, {});
});

export const getReceiveTransferButtonStatus = createSelector([getPurchaseOrder, getPaymentStatus], (purchaseOrder, POStatus) => {
  const {received, payment_status, connect_status, connect_outgoing} = purchaseOrder;
  let isActive = ['accepted', 'declined'].indexOf(connect_status) !== -1
    ? false
    : (['completed', 'ordered', 'partial'].indexOf(payment_status) !== -1 && !received);

  //ability to create IR from paid cancelled PO
  if (POStatus === 'ordered' && payment_status === 'cancelled') {
    isActive = true;
  }

  if (connect_status === 'requested' && connect_outgoing) {
    isActive = false;
  }
  return isActive;
});


export const getIsPurchaseOrderMedicated = createSelector([getOrderLines], (orderLines) => {
  return Boolean(orderLines && orderLines.some && orderLines.some(line => (line.itemMaster && isItemMasterMedicated(line.itemMaster))));
});
