import * as apiActions from './apiActions';
import * as dataNames from '../constants/dataNames';
import { createSearchCustomer } from './consumerActionHelpers';

export const getConsumers = (sort = 'consumer_code:asc', size = 1, start = 0, activeStatus = 1, ids = [], config) => (dispatch) => {
  const dummyFunction = () => {};
  const defaultConfig = {isSearchCustomer: false, setDataState: dummyFunction, cancelToken: false, clearCancelToken: dummyFunction};
  const {isSearchCustomer, setDataState, cancelToken, clearCancelToken} = Object.assign({}, defaultConfig, config);
  const consumersParams = {
    per_page: size,
    page: (start ? start / size : 0) + 1,
    sort,
    active: activeStatus === -1 ? undefined : activeStatus,
    in_ids: ids.length ? ids : undefined,
    paginated: true,
  };

  const ordersParams = {
    order_status: 'open',
    order_type: 'sales',
    select_columns: [
      'id',
      'consumer_id',
      'facility_id',
      'order_source',
      'fulfillment_method',
      'updated_at',
      'order_name',
      'is_submitted',
      'order_status',
      'order_type',
      'locked'
    ],
  };

  const reduxNames = !isSearchCustomer
    ? {
      customers: dataNames.customers,
      orders: dataNames.orders,
      queues: dataNames.customersQueue,
    }
    : {};

  dispatch(apiActions.getDataByPost(`/api/customers/listing`, consumersParams, reduxNames.customers, null, null, null, cancelToken))
    .then((consumers) => {
      clearCancelToken();
      const consumerIds = consumers.map((consumer) => consumer.id);
      const fileImageIds = consumers.map((consumer) => parseInt(consumer.image_file_id)).filter(Number);
      const queueParams = {in_consumer_ids: consumerIds};
      ordersParams.in_consumer_ids = consumerIds;

      const requests = [
        dispatch(apiActions.getData(`/api/orders`, reduxNames.orders, null, ordersParams)),
        dispatch(apiActions.getData('api/queue', reduxNames.queues, null, queueParams)),
        dispatch(apiActions.getDataByPost('/api/images/multiple', {ids: fileImageIds}, dataNames.images)),
      ];
      Promise.all(requests)
        .then((data) => {
          setDataState('loaded'); // Syncs inline data table messaging
          if(!isSearchCustomer){
            return;
          }
          const [orders, queues] = data; // images is present but for this we don't need it.
          createSearchCustomer(consumers[0], orders, queues, dispatch, dataNames.searchCustomer);
        });
    }).catch(() => {
      clearCancelToken();
    });
};

export const getConsumer = (consumerId) => (dispatch) => {
  dispatch(getConsumers(undefined, undefined, undefined, -1, [consumerId], {isSearchCustomer: true}));
};
