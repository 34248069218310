import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {reduxForm, FieldArray, formValueSelector} from 'redux-form';
import {connect} from 'react-redux';
import {Col} from 'react-bootstrap';
import {getInitialValues, isUsedInRewardsSetup} from '../../../selectors/forms/customerGroupFormSelectors';
import {CUSTOMER_GROUP_FORM} from '../../../constants/forms';
import validate from './validation';
import CustomerGroup from './CustomerGroup';
import SubmitSection from '../../common/form/SubmitSection';

const CustomerGroupEditor = (props) => {

  const {invalid, submitting, pristine, handleSubmit, nextAction, isEdit, isUsedInRewardsSetup} = props;

  const settings = {
    actionSettings: {
      submit: {
        disabled: submitting || invalid || pristine,
        text: I18n.t('common.form.save')
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Col md={12}>
        <FieldArray
          name='customerGroups'
          component={CustomerGroup}
          isUsedInRewardsSetup={isUsedInRewardsSetup}
          nextAction={nextAction}
          isEdit={isEdit}
        />
        <SubmitSection settings={settings} />
      </Col>
    </form>
  );
};

CustomerGroupEditor.propTypes = {
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  nextAction: PropTypes.string,
  isEdit: PropTypes.bool.isRequired,
  isUsedInRewardsSetup: PropTypes.bool.isRequired,
};


const wrapped = reduxForm({})(CustomerGroupEditor);

const selector = formValueSelector(CUSTOMER_GROUP_FORM);

function mapStateToProps(state, ownProps) {

  return {
    initialValues: {
      customerGroups: [getInitialValues(state, ownProps)] //customerGroupId is used
    },
    isUsedInRewardsSetup: isUsedInRewardsSetup(state, ownProps), //customerGroupId is used here too
    nextAction: selector(state, 'nextAction'),
    form: CUSTOMER_GROUP_FORM,
    validate
  };
}

export default connect(mapStateToProps, null)(wrapped);
