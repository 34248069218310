import React from 'react';
import PropTypes from 'prop-types';

/**
 * Content Concealer implements very simple but commonly used logic - it can show/hide inner content based on the
 * `show` property value. Can be wrapped by custom rules like permission, setting state, or any other predicate.
 */
class ContentConcealer extends React.PureComponent {
  render() {
    const {children, show, alternateComponent, force} = this.props;
    return (show && force ? children : alternateComponent);
  }
}

ContentConcealer.propTypes = {
  show: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
  force: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
  ]),
  alternateComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
  ]),
};

ContentConcealer.defaultProps = {
  alternateComponent: null,
  force: true,
};

export default ContentConcealer;
