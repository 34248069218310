import {createSelector} from 'reselect';
import {formValueSelector} from 'redux-form';
import orderBy from 'lodash.orderby';
import get from 'lodash.get';
import uniqby from 'lodash.uniqby';
import omit from 'lodash.omit';
import * as dataNames from '../../constants/dataNames';
import {getInitialPriceLists, getStrains, prepareUomOptions} from './productFormSelectors';
import {getPricingWeightsForDisplaying} from '../productsSelector';
import {getPricingGroupSorted} from '../pricingGroupsSelectors';
import {PRODUCT_MASS_MODIFY, PRODUCT_MULTI_SEARCH_FORM} from '../../constants/forms';
import rules from '../../constants/massModifyLimitedRules';
import {solvents, dilutions, onlineAvailabilityOptionsForMassPage} from '../../components/products/common/data';
import {getUoms} from '../uomsSelectors';
import {isFacilityGroupMaster} from '../facilityGroupsSharingSelectors';
import {isOklahoma} from '../facility/getCurrentFacility';
import {isDisabledSubcategoryForOklahoma} from '../subcategoriesSelectors';
import {getSubCategoriesByCategoryId} from '../categorySelectors';
import {finishedFlower, oklahomaSubcategories} from '../../constants/subcategoryGroups';
import {isPaLeafIntegrator} from '../integration/leafSelectors';
import {getIntegrationState} from '../integration/integrationSelectors';
import {getMetrcCategories, getMetrcCategoryMappings, getMetrcUoms} from '../integration/metrcSelectors';

const getCategories = (state) => state[dataNames.categories];
const getSubcategoriesBasic = (state) => state[dataNames.subcategories];
const getMassModifyProductsFiltered = (state) => state[dataNames.massModifyProductsFiltered];
const getPricingClasses = (state) => state[dataNames.pricingClasses];
const getPricingGroups = (state) => state[dataNames.pricingGroups];
const getFilteredItems = (state) => state[dataNames.filteredProducts];
const getSelectedItemMasters = (state) => state.selectedItemMasters;
const getProducts = (state) => state[dataNames.products];
export const getSelectedSearchedItemMasters = (state) => state.selectedFilteredProducts;
const getFacility = (state) => state.facility;
const getInventories = (state) => state[dataNames.inventoryItems];
const getFormValues = (state) => state[dataNames.massModifyFormData];
const getPhenotypes = (state) => state[dataNames.phenotypes];
const getPartners = (state) => state[dataNames.partners];
const getChildIitemMasters = (state) => state[dataNames.childItemMasters];

export const productFormValueSelector = new formValueSelector(PRODUCT_MASS_MODIFY);
const searchFormValueSelector = new formValueSelector(PRODUCT_MULTI_SEARCH_FORM);
const getSelectedCategories = (state) =>
  (searchFormValueSelector(state, 'categories') || []).map((category) => category.id);
const getSelectedSubCategories = (state) =>
  (searchFormValueSelector(state, 'subcategories') || []).map((subcategory) => subcategory.id);
const getSelectedProductStatuses = (state) =>
  (searchFormValueSelector(state, 'productStatuses') || []).map((status) => status.value);
const getSubcategory = (state) => productFormValueSelector(state, 'subcategory_id');

export const getSelectedSearchedItemMastersIds = createSelector(
  getSelectedSearchedItemMasters,
  (selectedSearchedItemMasters) =>
    selectedSearchedItemMasters.map((selectedSearchedItemMaster) => selectedSearchedItemMaster.id)
);

export const getFilterData = createSelector(
  [
    getCategories,
    getMassModifyProductsFiltered,
    getPricingClasses,
    getPricingGroups,
    getSelectedCategories,
    getSelectedSubCategories,
    getSelectedProductStatuses
  ],
  (
    categories,
    massModifyProductsFiltered,
    pricingClasses,
    pricingGroups,
    selectedCategories,
    selectedSubCategories,
    selectedProductStatuses
  ) => {
    return {
      categories: orderBy(categories.map((category) => ({name: category.name, id: category.id})), 'name', 'asc'),
      productsNames: massModifyProductsFiltered.filter((v, i) => {
        let returnProduct = true;
        // used to filter products by categories if and categories are selected
        if (selectedCategories.length) {
          if (!selectedCategories.filter((id) => id == v.category_id).length) {
            returnProduct = false;
          }
        }
        // used to filter products by sub categories if sub categories are selected
        if (selectedSubCategories.length) {
          if (!selectedSubCategories.filter((id) => id == v.subcategory_id).length) {
            returnProduct = false;
          }
        }
        // used to filter products that are not waste
        if (v.subcategory_name.toLowerCase().indexOf('waste') != -1) {
          returnProduct = false;
        }
        if (selectedProductStatuses.indexOf(v.active) === -1) {
          returnProduct = false;
        }
        return returnProduct;
      }),
      subcategories: orderBy(
        [].concat(
          ...categories.map((category) => [
            ...category.subcategories.map((subcategory) => ({
              name: subcategory.name,
              id: subcategory.id,
              cat_id: subcategory.category_id,
              subcategory_code: subcategory.subcategory_code
            }))
          ])
        ),
        'name',
        'asc'
      ).filter(
        (subcategory) =>
          selectedCategories.indexOf(subcategory.cat_id) !== -1 &&
          subcategory.subcategory_code.toLowerCase().indexOf('waste') === -1
      ),
      pricingGroups: orderBy(
        pricingGroups.map((pricingGroup) => ({name: pricingGroup.name, id: pricingGroup.id})),
        'name',
        'asc'
      ),
      pricingClasses: orderBy(
        pricingClasses.map((pricingClass) => ({name: pricingClass.name, id: pricingClass.id})),
        'name',
        'asc'
      )
    };
  }
);

const prepareProductData = (itemMasters, facility) => {
  return itemMasters.map((item) => ({
    ...item,
    category_name: item.category_name ? item.category_name : item.category.name,
    // Use get to prevent exception when subcategory doesn't exist. A check is in place to reload item masters with subcategories in MassModifyProductPage.js
    subcategory_name: get(item,'subcategory_name', '') ? get(item, 'subcategory_name') : get(item, 'subcategory.name'),
    pricing_groups_name: item.pricing_groups
      ? item.pricing_groups.reduce(
        (acc, group) => {
          if (group.facility_id && group.facility_id === facility.id) {
            acc[group.consumer_type] = group.name;
          } else if (!acc[group.consumer_type] && !group.facility_id) {
            acc[group.consumer_type] = group.name;
          }
          return acc;
        },
        {medical: '', recreational: ''}
      )
      : {},
    pricing_classes_name: item.pricing_classes
      ? item.pricing_classes.reduce(
        (acc, pr_class) => {
          if (pr_class.facility_id && pr_class.facility_id === facility.id) {
            acc[pr_class.consumer_type] = pr_class.name;
          } else if (!acc[pr_class.consumer_type] && !pr_class.facility_id) {
            acc[pr_class.consumer_type] = pr_class.name;
          }
          return acc;
        },
        {medical: '', recreational: ''}
      )
      : {}
  }));
};

export const getNonWasteProducts = createSelector(
  [getFilteredItems],
  (filteredItems) =>
    filteredItems.filter((a) => (a.subcategory_name.toLowerCase().indexOf('waste') != -1 ? false : true))
);

export const getProductsWithFilters = createSelector(
  [getNonWasteProducts, getFacility],
  (filteredItems, facility) => prepareProductData(filteredItems, facility)
);

export const getItemMastersForModify = createSelector(
  [getProducts, getFacility, getSelectedItemMasters],
  (products, facility, selectedItemMasters) => {
    const mergedProductData = products.map((product) => ({
      ...product,
      pricing_classes: product.pricing_classes
        ? product.pricing_classes
        : get(
          selectedItemMasters.find((selectedItemMaster) => product.id === selectedItemMaster.id),
          'pricing_classes',
          []
        ),
      pricing_groups: product.pricing_groups
        ? product.pricing_groups
        : get(
          selectedItemMasters.find((selectedItemMaster) => product.id === selectedItemMaster.id),
          'pricing_groups',
          []
        )
    }));

    return prepareProductData(mergedProductData, facility);
  }
);

export const makePricingName = (name_obj) => {
  let name = '';
  if (name_obj.medical) {
    name += `Med: ${name_obj.medical}`;
  }
  if (name_obj.medical && name_obj.recreational) {
    name += ', ';
  }
  if (name_obj.recreational) {
    name += `Rec: ${name_obj.recreational}`;
  }
  return name;
};

export const getCommonProductCategory = createSelector(
  [getItemMastersForModify],
  (products) => {
    if (!products.length) return 0;
    const category_id = products[0].category_id;
    const setOfCommonCategoryProducts = products.filter((product) => product.category_id === category_id);
    return setOfCommonCategoryProducts.length === products.length ? category_id : 0;
  }
);

const getCommonProductSubCategory = createSelector(
  [getItemMastersForModify],
  (products) => {
    if (!products.length) return 0;
    const subcategory_id = products[0].subcategory_id;
    const setOfCommonCategoryProducts = products.filter((product) => product.subcategory_id === subcategory_id);
    return setOfCommonCategoryProducts.length === products.length ? subcategory_id : 0;
  }
);

export const getCommonProductWeightType = createSelector(
  [getItemMastersForModify],
  (products) => {
    if (!products.length) return '';
    const uom_type = products[0].uom_type;
    const setOfCommonUomTypeProducts = products.filter((product) => product.uom_type === uom_type);
    return setOfCommonUomTypeProducts.length === products.length ? uom_type : 'mix';
  }
);

export const getCommonProductUom = createSelector(
  [getItemMastersForModify],
  (products) => {
    if (!products.length) return '';
    const uom = products[0].default_uom;
    const setOfCommonUomProducts = products.filter((product) => product.default_uom === uom);
    return setOfCommonUomProducts.length === products.length ? uom : '';
  }
);

export const hasInventories = createSelector(
  [getInventories, getChildIitemMasters],
  (inventories, children) => {
    return !!(inventories.length || children.filter((child) => child.items.length).length);
  }
);

export const getFilteredPricingGroupOptions = createSelector(
  [getCommonProductCategory, getPricingGroups],
  (category_id, pricingGroups) => {
    return category_id ? pricingGroups.filter((pGO) => pGO.category_id === category_id) : pricingGroups;
  }
);

export const getInitialValues = createSelector(
  [
    getCommonProductCategory,
    getPricingWeightsForDisplaying,
    getPricingGroupSorted,
    getCommonProductWeightType,
    getFormValues
  ],
  (categoryId, pricingWeights, pricingGroups, pricingType, oldFormValues) =>
    oldFormValues.form_action === 'preview'
      ? omit(oldFormValues, ['form_action'])
      : {
        tags: [],
        is_sales_item: true,
        online_availability: {
          facilities: [{is_default_for_org: 0, available_online: null, facility_ids: []}],
          organization: {is_default_for_org: 1, available_online: null, facility_ids: []}
        },
        prices: getInitialPriceLists(
          [],
          pricingType,
          categoryId,
          pricingWeights,
          pricingGroups,
          null,
          null,
          null,
          null
        ),
        ingredients: null,
        allergens: null,
        nutritional_info: null,
        subcategory_id: null
      }
);

const getAllProductsCategories = createSelector(
  [getItemMastersForModify, getCategories],
  (products, categories) =>
    uniqby(products, 'category_id').map((product) => categories.find((category) => category.id === product.category_id))
);

export const getFieldCanModifiedMap = createSelector(
  [getAllProductsCategories],
  (selected_categories) => {
    const cannotModifiedMap = {};
    Object.keys(rules).map(
      (key) =>
        (cannotModifiedMap[key] = selected_categories
          .filter((category) => category && rules[key].indexOf(category.category_code) === -1)
          .map((category) => category.name))
    );
    return cannotModifiedMap;
  }
);

export const previewPageColumnsMap = [
  {
    title: 'products.massModify.generalInformationTitle',
    anchor: 'general_section',
    fields: [
      {name: 'subcategory_id', title: 'products.form.itemSubcategory', type: 'subcategory'},
      {name: 'strain_id', title: 'products.form.strainName', type: 'strain'},
      {name: 'phenotype_id', title: 'products.form.phenotype', type: 'phenotype'},
      {name: 'dominance', title: 'products.form.dominanceName'},
      {name: 'is_purchasing_item', title: 'products.form.purchaseInventory', type: 'yesno'},
      {name: 'is_inventory_item', title: 'products.form.trackInventory', type: 'yesno'},
      {name: 'is_manufactured_item', title: 'products.form.produceInventory', type: 'yesno'},
      {name: 'available_to_connects', title: 'products.form.availableToConnects', type: 'yesno'},
      {name: 'tags', title: 'products.form.tags', type: 'tags'},
      {name: 'default_uom', title: 'products.form.inventoryUom'},
      {name: 'active', title: 'products.form.active', type: 'yesno'},
      {name: 'brand', title: 'products.form.brand', type: 'brand'}
    ]
  },
  {
    title: 'products.massModify.inventorySettingsTitle',
    anchor: 'inventory_section',
    fields: [
      {name: 'lot_tracked', title: 'products.form.trackLots', type: 'yesno'},
      {name: 'is_prepack', title: 'products.form.prePackItem', type: 'yesno'},
      {name: 'medicated_weight', title: 'products.form.medicatedNetWeight'},
      {name: 'medicated_weight_uom', title: 'products.form.uom'},
      {name: 'net_weight', title: 'products.form.productNetWeight'},
      {name: 'net_weight_uom', title: 'products.form.uom'},
      {name: 'unit_weight', title: 'products.form.grossWeight'},
      {name: 'unit_weight_uom', title: 'products.form.uom'},
      {name: 'medicated_volume', title: 'products.form.medicatedNetVolume'}
    ]
  },
  {
    title: 'products.massModify.purchasingInformationTitle',
    anchor: 'purchasing_section',
    fields: [
      {name: 'purchasing_attributes.par_level', title: 'products.form.parLevel'},
      {name: 'purchasing_attributes.auto_reorder_qty', title: 'products.form.autoReorderQuantity'},
      {name: 'vendors.0.partner_id', title: 'products.form.vendor', type: 'vendor'},
      {name: 'vendors.0.default_unit_cost', title: 'products.form.costPerUnit'},
      {name: 'purchasing_attributes.buffer', title: 'products.form.apiBuffer'}
    ]
  },
  {
    title: 'products.massModify.salesInformationTitle',
    anchor: 'sales_section',
    type: 'sales',
    fields: [
      {name: 'online_availability.organization', title: 'products.form.onlineAvailability', type: 'availability'},
      {name: 'online_availability.facilities', title: 'products.form.onlineAvailability', type: 'availability'},
      {name: 'prices.organization.retail.medical', type: 'price'},
      {name: 'prices.organization.retail.recreational', type: 'price'},
      {name: 'prices.organization.wholesale', type: 'price'},
      {name: 'prices.facilities.retail.medical', type: 'price'},
      {name: 'prices.facilities.retail.recreational', type: 'price'},
      {name: 'prices.facilities.wholesale', type: 'price'}
    ]
  },
  {
    title: 'products.massModify.potencyInformationTitle',
    anchor: 'potency_section',
    fields: [{name: 'activation_time', title: 'products.form.activationTime'}]
  },
  {
    title: 'products.massModify.leaflyAvailabilityTitle',
    anchor: 'leafly_availability_section',
    fields: [{name: 'sales_attributes.leafly_share', title: 'products.form.shareProductWithLeafly', type: 'yesno'}]
  },
  {
    title: 'products.massModify.weedmapsAvailabilityTitle',
    anchor: 'weedmaps_availability_section',
    fields: [
      {name: 'sales_attributes.weedmaps_share', title: 'products.form.shareProductWithWeedmaps', type: 'yesno'}
    ]
  },
  {
    title: 'products.massModify.additionalInformationTitle',
    anchor: 'add_info_section',
    fields: [
      {name: 'serving_size', title: 'products.form.servingSize'},
      {name: 'servings_per_container', title: 'products.form.servingsPerContainer'},
      {name: 'solvent', title: 'products.form.solvent', type: 'solvents'},
      {name: 'pieces_per_unit', title: 'products.form.piecesPerUnit'},
      {name: 'requires_refrigeration', title: 'products.form.requiresRefrigeration', type: 'yesno'},
      {name: 'dilution_method', title: 'products.form.dilutionMethod', type: 'dilutions'},
      {name: 'ingredients', title: 'products.form.ingredients', type: 'action'},
      {name: 'allergens', title: 'products.form.allergens', type: 'action'},
      {name: 'nutritional_info', title: 'products.form.nutritional', type: 'action'}
    ]
  }
];

export const showSalesSection = (sectionData) =>
  (sectionData && (sectionData.facility_ids.length > 0 || !!parseFloat(sectionData.default_price))) ||
  [0, 1].indexOf(sectionData.inherit_pricing_class_updates) !== -1 ||
  [0, 1].indexOf(sectionData.inherit_pricing_group_updates) !== -1 ||
  [0, 1].indexOf(sectionData.is_non_taxable) !== -1 ||
  !!sectionData.pricing_class_id ||
  !!sectionData.pricing_group_id ||
  (sectionData.weight_prices && sectionData.weight_prices.filter((weight) => parseFloat(weight.default_price)).length > 0);

const prepareSaleSection = (dictionary, value) => ({
  ...value,
  pricing_class_name: get(dictionary.pricingClasses.find((p_class) => p_class.id === value.pricing_class_id), 'name'),
  pricing_group_name: get(dictionary.pricingGroups.find((p_group) => p_group.id === value.pricing_group_id), 'name'),
  weight_prices: value.weight_prices ? value.weight_prices.filter((weight) => parseFloat(weight.default_price)) : [],
  applicable_facilities: value.facility_ids.map((facility) => facility.text).join(', '),
  show_section: showSalesSection(value)
});

const getValueForPreview = (value, type, dictionary, action) => {
  const actionTitle = action === 'clear' ? 'Clear all' : '';
  switch (type) {
  case 'array':
    return value && value.length ? value.join(', ') : null;
  case 'yesno':
    return value === 0 ? 'No' : value === 1 ? 'Yes' : null;
  case 'strain':
    return get(dictionary.strains.find((strain) => strain.id === value), 'strain_name');
  case 'brand':
    return typeof value === 'object' ? get(value, 'name') : value;
  case 'phenotype':
    return get(dictionary.phenotypes.find((phenotype) => phenotype.id === value), 'name');
  case 'vendor':
    return get(dictionary.partners.find((obj) => obj.id === value), 'name');
  case 'solvents':
    return get(solvents.find((obj) => obj.value === value), 'text');
  case 'dilutions':
    return get(dilutions.find((obj) => obj.value === value), 'text');
  case 'price':
    if (Array.isArray(value)) {
      return value.map((obj) => prepareSaleSection(dictionary, obj));
    } else if (value) {
      return prepareSaleSection(dictionary, value);
    }
    return null;
  case 'availability':
    if (Array.isArray(value)) {
      return value.map((obj) => ({
        availability: get(
          onlineAvailabilityOptionsForMassPage.find((option) => option.value === obj.available_online),
          'text'
        ),
        facilities: obj.facility_ids.map((facility) => facility.text).join(', ')
      }));
    } else if (value) {
      return get(onlineAvailabilityOptionsForMassPage.find((obj) => obj.value === value.available_online), 'text');
    }
    return null;
  case 'subcategory':
    return dictionary.categories.reduce(
        (acc, category) =>
          acc ? acc : get(category.subcategories.find((subcategory) => subcategory.id === value), 'name'),
        null
      );
  case 'tags':
    return actionTitle
      ? actionTitle
      : value && value.length
        ? value.map((tag) => (typeof tag === 'string' ? tag : tag.tag_name)).join(', ')
        : null;
  case 'action':
    return actionTitle ? actionTitle : value;
  }

  return value;
};

export const prepareDataForPreview = createSelector(
  [getFormValues, getStrains, getPhenotypes, getCategories, getPartners, getPricingGroups, getPricingClasses],
  (formData, strains, phenotypes, categories, partners, pricingGroups, pricingClasses) => {
    const dictionary = {
      strains,
      phenotypes,
      categories,
      partners,
      pricingGroups,
      pricingClasses
    };

    return previewPageColumnsMap
      .map((section) => ({
        ...section,
        fields: section.fields
          .map((field) => ({
            ...field,
            value: getValueForPreview(
              get(formData, field.name),
              field.type,
              dictionary,
              get(formData, `${field.name}_action`)
            )
          }))
          .filter((field) => field.value !== null && field.value !== undefined)
      }))
      .filter((section) => section.type === 'sales' || section.fields.find((field) => field.value));
  }
);

//<<<<<<< HEAD
export const getUomOptions = createSelector(
  [
    getUoms,
    getCommonProductCategory,
    getCommonProductSubCategory,
    getSubcategory,
    getCategories,
    getIntegrationState,
    getMetrcCategories,
    getMetrcCategoryMappings,
    getMetrcUoms
  ],
  (
    uoms,
    categoryId,
    subcategoryIdFromProducts,
    subcategoryIdFromForm,
    categories,
    integrationState,
    mc,
    mcm,
    muomOptions
  ) => {
    const commonCategory = categories.find((category) => category.id === categoryId);
    const sub_cat_id = subcategoryIdFromForm || subcategoryIdFromProducts;
    return prepareUomOptions(uoms, commonCategory, sub_cat_id, integrationState, mc, mcm, muomOptions);
  }
);

export const filterEmptyValue = (formValues) => {
  const filteredData = {};
  Object.keys(formValues).map((key) => (filteredData[key] = formValues[key] === null ? undefined : formValues[key]));
  filteredData.pricing_details.price_lists = formValues.pricing_details.price_lists.map((list) => ({
    ...list,
    inherit_pricing_class_updates:
      list.inherit_pricing_class_updates === null ? undefined : list.inherit_pricing_class_updates,
    inherit_pricing_group_updates:
      list.inherit_pricing_group_updates === null ? undefined : list.inherit_pricing_group_updates,
    is_non_taxable: list.is_non_taxable === null ? undefined : list.is_non_taxable,
    pricing_class_id: list.pricing_class_id === null ? undefined : list.pricing_class_id,
    pricing_group_id: list.pricing_group_id === null ? undefined : list.pricing_group_id,
    default_price: parseFloat(list.default_price) ? list.default_price : undefined,
    facility_ids: list.facility_ids.length ? list.facility_ids : undefined,
    weight_prices: list.weight_prices.filter((price) => parseFloat(price.default_price))
  }));
  filteredData.attribute_lists = formValues.attribute_lists.map((list) => ({
    ...list,
    available_online: list.available_online === null ? undefined : list.available_online,
    facility_ids: list.facility_ids.length ? list.facility_ids.length : undefined
  }));
  return filteredData;
};

export const canChangeSubcategory = createSelector(
  [isFacilityGroupMaster, getItemMastersForModify],
  (isFacilityGroupMaster, products) => isFacilityGroupMaster || !products.find((product) => product.is_shared_item)
);

export const isStrainDisabled = createSelector(
  [getIntegrationState, getItemMastersForModify],
  (integrationState, selectedProducts) => {
    const strains = uniqby(selectedProducts.map((product) => product.strain_id));
    return integrationState.isWaLeaf && strains.length > 1;
  }
);

export const isStrainRequired = createSelector(
  [getIntegrationState, getCategories, getSubcategoriesBasic, getSubcategory, getItemMastersForModify],
  (integrationState, categories, subcategories, selectedSubcategoryId, selectedProducts) => {
    let shouldRequireStrain = false;

    if (integrationState.isWaLeaf) {
      const selectedSubcategory = subcategories.find((subcategory) => selectedSubcategoryId == subcategory.id);
      const selectedSubcategoryCode = get(selectedSubcategory, 'subcategory_code');

      if (selectedSubcategoryCode == 'MATURE_PLANT') {
        return shouldRequireStrain;
      }

      selectedProducts.forEach((product) => {
        const category = categories.find((category) => product.category_id === category.id);
        const subcategory = subcategories.find((subcategory) => product.subcategory_id === subcategory.id);

        if (get(category, 'category_code') === 'PLANTS') {
          if (
            [null, undefined].indexOf(selectedSubcategoryCode) === -1 ||
            subcategory.subcategory_code != 'MATURE_PLANT'
          ) {
            shouldRequireStrain = true;
          }
        }
      });
    }

    return shouldRequireStrain;
  }
);

export const getSubcategories = createSelector(
  [isOklahoma, isPaLeafIntegrator, getSubCategoriesByCategoryId],
  (isOklahomaFacility, isPaLeafFacility, subcategories) => {
    let subcats = subcategories || [];
    if (!isPaLeafFacility) {
      subcats = subcats.filter((s) => s.subcategory_code !== finishedFlower);
    }
    const removeOklahomaOnlySubcategories = (subcategory) =>
      !oklahomaSubcategories.includes(subcategory.subcategory_code);
    const disableSomeSubcategoriesForOklahoma = (subcategory) => {
      return isDisabledSubcategoryForOklahoma(isOklahomaFacility, subcategory.subcategory_code)
        ? {...subcategory, disabled: 'yes'}
        : subcategory;
    };
    return isOklahomaFacility
      ? subcats.map(disableSomeSubcategoriesForOklahoma)
      : subcats.filter(removeOklahomaOnlySubcategories);
  }
);
