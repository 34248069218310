import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';

import {getFlattenedLocations} from '../../../selectors/locationsSelectors';
import {getActivateInitialValues} from '../../../selectors/harvests/harvestsSelectors';
import ActivateHarvestBatchesForm from './ActivateHarvestBatchesForm';
import validate from './validate';

const form = 'activateHarvestBatches';
const ActivateHarvestBatchesReduxForm = reduxForm({
  form,
  validate,
  enableReinitialize: true,
})(ActivateHarvestBatchesForm);

function mapStateToProps(state) {
  return {
    storageLocations: getFlattenedLocations({locations: state.inventoryLocations}),
    initialValues: getActivateInitialValues(state),
  };
}

const ActivateHarvestBatchesFromWrapper = connect(mapStateToProps)(ActivateHarvestBatchesReduxForm);

ActivateHarvestBatchesFromWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ActivateHarvestBatchesFromWrapper;
