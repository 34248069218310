/* eslint-disable import/no-mutable-exports*/
import {connect} from 'react-redux';
import {reduxForm, formValueSelector} from 'redux-form';
import validate from './validateCompletePackaging';
import CompletePackageForm from './CompletePackageForm'; // eslint-disable-line import/no-named-as-default
import {isFeatureEnabled} from '../../../selectors/featureToggles';
import {COMPLETE_PACKAGE_FORM} from '../../../constants/forms';
import {getInputUom, getOutputUom, getSelectedItemMaster} from '../../../selectors/forms/completePackageFormSelectors';


let WrappedComponent = reduxForm({
  form: COMPLETE_PACKAGE_FORM,
  keepDirtyOnReinitialize: false,
  enableReinitialize: true,
  validate
})(CompletePackageForm);

const selector = formValueSelector(COMPLETE_PACKAGE_FORM);
WrappedComponent = connect(
  (state, ownProps) => {
    const item_master_parent_id = selector(state, 'item_master_parent_id');
    const tag_requested = selector(state, 'tag_requested');
    const selectedItemMaster = getSelectedItemMaster(state);
    //const containerCode = selector(state, 'container_code');
    const shakePackageCode = selector(state, 'shakePackageCode');
    const shakeIsChanged = selector(state, 'shakeIsChanged');
    const inputUom = getInputUom(state);
    const outputUom = getOutputUom(state);

    const onSubmit = values => ownProps.onSubmit({...values, inputUom, outputUom});

    return {
      item_master_parent_id,
      selectedItemMaster,
      //containerCode,
      shakePackageCode,
      shakeIsChanged,
      inputUom,
      outputUom,
      onSubmit,
      didRequestTag: Boolean(tag_requested),
      isUtahGlobalIdGenerationFeatureEnabled: isFeatureEnabled(state)('feature_utah_global_id_generation'),
    };
  }
)(WrappedComponent);

export default WrappedComponent;
