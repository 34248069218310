import { I18n } from 'react-redux-i18n';
import get from 'lodash.get';
import { requiredFieldValidation } from '../../common/form/redux-form/validations';
import { leafPaMaxHarvestWeight, leafWaReconciliationReasonsRequiringReceivedBy } from '../../../constants/leaf';

const validate = (values, ownProps) => {
  const errors = {};

  const { complianceSettings } = ownProps;

  errors.categories = values.categories.map((category) => {
    const categoryError = {};
    categoryError.items = category.items.map((item) => {
      const itemError = {};
      // enforce pa leaf max package size
      if (ownProps.integrationState.isPaLeaf && parseFloat(item.quantity) > leafPaMaxHarvestWeight) {
        itemError.quantity = I18n.t('packages.form.packageSizePaError', {
          leafPaMaxHarvestWeight: leafPaMaxHarvestWeight.toFixed(2)
        });
      }

      // require adjustment reason for canada and leaf pa
      if (
        (ownProps.integrationState.isCanada || ownProps.integrationState.isPaLeaf) &&
        parseFloat(item.discrepancy) !== 0 &&
        !values.adjustment_reason
      ) {
        itemError.adjustment_reason = requiredFieldValidation(
          item.adjustment_reason,
          'reconciliation.adjustmentReason'
        );
      }

      if (ownProps.integrationState.isWaLeaf && parseFloat(item.discrepancy) != 0) {
        if (!values.integration_adjustment_reason) {
          itemError.integration_adjustment_reason = requiredFieldValidation(
            item.integration_adjustment_reason,
            'reconciliation.leafAdjustmentReason'
          );
        }

        if (!values.received_by_user_id) {
          if (leafWaReconciliationReasonsRequiringReceivedBy.includes(item.integration_adjustment_reason)) {
            itemError.received_by_user_id = requiredFieldValidation(
              item.received_by_user_id,
              'reconciliation.receivedBy'
            );
          }
        }
      }

      if (
        get(complianceSettings, 'reconciliation_requires_adjustment_reason', false) &&
        parseFloat(item.discrepancy) != 0
      ) {
        itemError.adjustment_reason = !get(values, 'adjustment_reason', false)
          ? requiredFieldValidation(get(item, 'adjustment_reason', ''), 'reconciliation.adjustmentReason')
          : undefined;
      }

      return itemError;
    });

    return categoryError;
  });

  if (ownProps.integrationState.isWaLeaf) {
    // Do require "received_by_user_id" for specified adjustments reasons:
    if (leafWaReconciliationReasonsRequiringReceivedBy.includes(values.integration_adjustment_reason)) {
      errors.received_by_user_id = requiredFieldValidation(values.received_by_user_id, 'reconciliation.receivedBy');
    }
  }

  return errors;
};

export default validate;
