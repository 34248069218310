import {createSelector} from 'reselect';
import map from 'lodash.map';
import some from 'lodash.some';
import first from 'lodash.first';
import omit from 'lodash.omit';
import get from 'lodash.get';
import {formValueSelector} from 'redux-form';
import * as dataNames from '../constants/dataNames';
import * as itemNames from '../constants/itemNames';
import getSelectedDataName from '../constants/helpers/getSelectedDataName';
import {getPhases} from './phasesSelectors';
import {getSubcategories, getMedicatedSubcategories, isDisabledSubcategoryForOklahoma} from './subcategoriesSelectors';
import {getCategories} from './categorySelectors';
import {getIngredients} from './ingredientsSelectors';
import {getUoms} from './uomsSelectors';
import {possibleToConvert} from '../util/uomHelpers';
import {getArrayIntersection, arrayIsWithinTargetArray} from '../util/dataHelpers';
import {COMPLETE_PROCESSING_FORM,PROCESSING_TYPE_FORM} from '../constants/forms';
import {isOklahoma} from './facility/getCurrentFacility';
import { getProcessingWorkflowValidOutputs, getSubCategorySettings } from './settingsSelectors';

const processingTypes = state => state[dataNames.processingTypes];
const getProcessingType = state => state[itemNames.processingType];
const getCurrentTimezone = state => state.timezone;

const formSelector = formValueSelector(COMPLETE_PROCESSING_FORM);
export const getFormInputs = state => formSelector(state, 'inputs');

const processingTypeFormSelector = formValueSelector(PROCESSING_TYPE_FORM);
const getProcessingTypeInputs = state => processingTypeFormSelector(state, 'subcategories_input');
const getProcessingTypeOutputs = state => processingTypeFormSelector(state, 'subcategories_output');

export const getSelectedProcessingTypes = state => state[getSelectedDataName(dataNames.processingTypes)];

export const getProcessingTypes = createSelector(
  [processingTypes, getPhases, getSubcategories, getCurrentTimezone] , (processingTypes, phases, subcategories, timezone) => {
    return processingTypes.map((processingType) => {
      return {
        name: processingType.name,
        id: processingType.id,
        inputs: getSubcategoriesText(subcategories, processingType.inputs),
        outputs: getSubcategoriesText(subcategories, processingType.outputs),
        standard_time: formatTime(processingType.estimated_time_base),
        starting_phase: processingType.starting_phases
          ? processingType.starting_phases.reduce((acc, starting_phase) => {
            const phase = phases.find(phase => starting_phase.phase_id === phase.id);
            phase && acc.push(phase.name);
            return acc;
          }, []).join(', ') : '',
        completion_phase: processingType.completion_phase ? processingType.completion_phase.name : ''
      };
    });
  }
);

const getSubcategoriesText = (allSubcategories, usedSubcategories) => {
  return usedSubcategories
    ? usedSubcategories.reduce((acc, useSubcategory) => {
      const subcategory = allSubcategories.find(subcategory => subcategory.id === useSubcategory.inventory_subcategory_id);
      subcategory && subcategory.name && acc.push(subcategory.name);
      return acc;
    }, []).join(', ')
    : '';
};

const formatTime = (time) => {
  const hours = parseInt(time / 3600, 10);
  const min = parseInt((time - hours * 3600) / 60, 10);
  return `${hours}:${(min / 10 > 1) ? min : '0' + min}`;
};

export const getSelectedProcessingTypesIds = createSelector(
  getSelectedProcessingTypes,
  processingTypes => map(processingTypes, 'id')
);

const getValidJurisdictionCategoriesForInputOrOutput = (validOutputs, type = 'inputs') => {
  if (!Array.isArray(validOutputs) || !validOutputs.length) {
    return false;
  }
  return validOutputs.reduce((acc, output) => {
    const categories = output[type];
    categories.forEach((input) => {
      if (acc.indexOf(input) === -1) {
        acc.push(input);
      }
    });
    return acc;
  }, []);
};

export const getValidJurisdictionInputCategories = createSelector(
  [getProcessingWorkflowValidOutputs],
  (validOutputs) => {
    return getValidJurisdictionCategoriesForInputOrOutput(validOutputs, 'inputs');
  });

export const getValidJurisdictionOutputCategories = createSelector(
  [getProcessingWorkflowValidOutputs],
  (validOutputs) => {
    return getValidJurisdictionCategoriesForInputOrOutput(validOutputs, 'outputs');
  });

export const getCreateProcessingTypeInitialValues = createSelector(
  [getMedicatedSubcategories, isOklahoma, getCategories, getValidJurisdictionInputCategories],
  (subcategories, isOklahomafacility, categories, validInputs) => {
    return {
      subcategories_input: subcategories.map(subcategory => ({
        ...subcategory,
        checked: false,
        disabled: isDisabledSubcategoryForOklahoma(isOklahomafacility, subcategory.subcategory_code) || isDisabledByValidInputs(subcategory, validInputs),
      })),
      subcategories_output: subcategories.map(subcategory => ({
        ...subcategory,
        checked: false,
        disabled: isDisabledSubcategoryForOklahoma(isOklahomafacility, subcategory.subcategory_code) || isDisabledByValidInputs(subcategory, validInputs),
      })),
      materials: [{}],
      directions: [{}],
      equipment_ids: [],
      starting_phase_ids: [],
    };
  });

export const getValidOutputsBasedOnInputs = (selectedInputs, validOutputSettings) => {
  const outputs = selectedInputs.reduce((acc, input) => {
    const setting = validOutputSettings.find((setting) => setting.inputs.indexOf(input) !== -1);
    if (setting) {
      acc.push(setting.outputs);
    }
    return acc;
  }, []);
  return getArrayIntersection(outputs);
};

export const getValidInputsBasedOnOutputs = (selectedOutputs, validOutputSettings) => {
  return validOutputSettings.reduce((acc, setting) => {
    if (arrayIsWithinTargetArray(selectedOutputs, setting.outputs)) {
      acc = acc.concat(setting.inputs);
    }
    return acc;
  }, []);
};

/**
 * Returns object representing valid input and output jurisdication categories used to disable the invalid
 * options.
 * @type {Reselect.Selector<unknown, unknown>}
 */
export const getValidInputAndOutputJurisdictionCategories = createSelector(
  [getProcessingTypeInputs, getProcessingTypeOutputs, getProcessingWorkflowValidOutputs, getValidJurisdictionInputCategories, getValidJurisdictionOutputCategories, getSubCategorySettings],
  (inputs, outputs, validOutputSettings, inputCategories, outputCategories, subCategorySettings) => {
    // If no feature toggle, no valid output settings, this won't run.  When the feature toggle is turned on
    // default is no valid outputs so returns object with disabled property so is not used.
    if (!inputs || !outputs || !validOutputSettings.length || !Object.keys(subCategorySettings).length) {
      return {disabled: true};
    }
    const selectedInputs = inputs.filter((input) => input.checked === true).map((input) => input.jurisdiction_category_code);
    const selectedOutputs = outputs.filter((output) => output.checked === true).map((input) => input.jurisdiction_category_code);
    return {
      inputs: selectedOutputs.length ? getValidInputsBasedOnOutputs(selectedOutputs, validOutputSettings) : inputCategories,
      outputs: selectedInputs.length ? getValidOutputsBasedOnInputs(selectedInputs, validOutputSettings) : outputCategories,
    };
  });

const isDisabledByValidInputs = (subcategory, validInputs) => {
  if (!validInputs && typeof validInputs === 'boolean' || subcategory.jurisdiction_category_code === undefined) { // If false then there are none
    return false;
  }
  return validInputs.indexOf(subcategory.jurisdiction_category_code) === -1;
};



export const getCreatePayload = (formData) => {
  return {
    name: formData.name.trim(),
    estimated_time: formData.default_processing_time || null,
    estimated_time_uom: 'MIN',
    starting_phase_ids: formData.starting_phase_ids.map(phase => phase.id),
    completion_phase_id: formData.completion_phase_id || null,
    directions: formData.directions.reduce((acc, direction) => {
      if (direction.text) {
        acc.push({direction: direction.text});
      }
      return acc;
    }, []
    ),
    input_inventory_subcategory_ids: formData.subcategories_input.reduce((acc, subcategory) => {
      if (subcategory.checked) {
        acc.push(subcategory.id);
      }
      return acc;
    }, []),
    output_inventory_subcategory_ids: formData.subcategories_output.reduce((acc, subcategory) => {
      if (subcategory.checked) {
        acc.push(subcategory.id);
      }
      return acc;
    }, []),
    equipment_ids: formData.equipment_ids.map((equipment) => equipment.id),
    materials: formData.materials.reduce((acc, material) => {
      if (material.id && material.qty) {
        acc.push({item_master_id: material.id, qty: material.qty, uom: material.uom});
      }
      return acc;
    }, [])
  };
};

const initialValues = () => {
  return {
    starting_phase_ids: [],
    materials: [{}],
    directions: [{}],
    equipment_ids: []
  };
};

export const getModifyProcessingTypesInitialValues = createSelector(
  [getProcessingType, getMedicatedSubcategories, getIngredients, getUoms, isOklahoma, getValidJurisdictionInputCategories],
  (processingType, subcategories, ingredients, uoms, isOklahomafacility, validInputs) => {
    if (!processingType.id) {
      return initialValues();
    }

    return {
      name: processingType.name,
      starting_phase_ids: (processingType.starting_phases || []).map(phase => {return {id: phase.phase_id};}),
      subcategories_input: subcategories.map(subcategory => ({
        ...subcategory,
        checked: some((processingType.inputs || []), {inventory_subcategory_id: subcategory.id}),
        disabled: isDisabledSubcategoryForOklahoma(isOklahomafacility, subcategory.subcategory_code) || isDisabledByValidInputs(subcategory, validInputs),
      })) ,
      subcategories_output: subcategories.map(subcategory => ({
        ...subcategory,
        checked: some((processingType.outputs || []), {inventory_subcategory_id: subcategory.id}),
        disabled: isDisabledSubcategoryForOklahoma(isOklahomafacility, subcategory.subcategory_code) || isDisabledByValidInputs(subcategory, validInputs),
      })),
      materials: processingType.materials.length ? processingType.materials.reduce((acc, item) => {
        const ingredient = first(ingredients.filter((ingredient) => ingredient.id === item.item_master_id));
        if (!ingredient) {
          return acc;
        }

        let cost = '0.00';
        const ingredient_unit_cost = Number(get(ingredient, 'vendors[0].default_unit_cost', 0));
        if (ingredient.default_uom) {
          const ingredientUom = uoms.reduce((acc, uom) => {
            if (uom.uom_code === ingredient.default_uom){
              acc = uom;
            }
            return acc;}, {});
          const itemUom = uoms.reduce((acc, uom) => {
            if (uom.uom_code === item.uom){
              acc = uom;
            }
            return acc;}, {});
          cost = ingredient_unit_cost * itemUom.conversion_ratio / ingredientUom.conversion_ratio;
        }

        const costPerLine = (cost && item.qty) ? cost * item.qty : 0;
        acc.push({
          id: item.item_master_id,
          qty: item.qty,
          uom: item.uom,
          default_uom: ingredient.default_uom,
          cost_per_line: cost ? costPerLine : '0.00',
          unit_cost: cost ? ingredient_unit_cost : '0.00',
          save_id: item.id
        });
        return acc;
      }, []) : [{}],
      directions: processingType.directions.length
        ? processingType.directions.map((direction) => {return {id: direction.id, text: direction.direction};})
        : [{}],
      equipment_ids: (processingType.equipment || []).map(item => {return {id: item.equipment_id};}),
      completion_phase_id: processingType.completion_phase_id,
      default_processing_time: processingType.estimated_time_base / 60, //now working with minutes only
      delete_materials: []
    };
  }
);

export const getModifyPayload = (formData) => {
  return {
    ...omit(getCreatePayload(formData), ['directions', 'materials']),
    directions: formData.directions.reduce((acc, direction) => {
      if (direction.text && direction.id) {
        acc.push({direction: direction.text, id: direction.id});
      } else if (direction.text) {
        acc.push({direction: direction.text});
      }
      return acc;
    }, []
    ),
    delete_materials: formData.delete_materials,
    materials: formData.materials.reduce((acc, material) => {
      if (material.id && material.qty) {
        const sendData = {item_master_id: material.id, qty: material.qty, uom: material.uom};
        if (material.save_id) {
          sendData['id'] = material.save_id;
        }
        acc.push(sendData);
      }
      return acc;
    }, [])
  };
};

export const getPossibleUoms = (defaultUom, uoms) => {
  const defaultUomData = uoms.find(uom => uom.uom_code === defaultUom);
  return defaultUomData ? uoms.filter(uom => uom.uom_type === defaultUomData.uom_type && uom.conversion_ratio <= defaultUomData.conversion_ratio) : [];
};

export const checkIngredientsUom = createSelector(
  [getProcessingType, getIngredients], (processingType, ingredients) => {
    const result = processingType.materials && processingType.materials.find(ing => {
      const itemMaster = ingredients.find(itemMasterIng => itemMasterIng.id === ing.item_master_id
        && !possibleToConvert(itemMasterIng.default_uom, ing.uom));
      return Boolean(itemMaster);
    });
    return !result;
  }
);
