import React from 'react';
import PropTypes from 'prop-types';

import {showSection} from '../../../selectors/productHistorySelector';
import {WHOLESALE} from '../../../constants/saleTypes';
import {RECREATIONAL, MEDICAL} from '../../../constants/consumerTypes';
import TrWrapper from './TrWrapper';
import SaleRowHistory from './SaleRowHistory';

const RenderSalesInfoHistory = (props) => {
  const {delta} = props;
  const showMedicalSection = showSection(MEDICAL, delta);
  const showRecreationalSection = showSection(RECREATIONAL, delta);
  const showWholesaleSection = showSection(WHOLESALE, delta);

  return (<TrWrapper>
    <SaleRowHistory
      title={'products.form.medicalPrices'}
      type={MEDICAL}
      delta={delta}
      show={showMedicalSection}
    />
    <SaleRowHistory
      title={'products.form.recreationalPrices'}
      type={RECREATIONAL}
      delta={delta}
      show={showRecreationalSection}
    />
    <SaleRowHistory
      title={'products.form.wholesalePrices'}
      type={WHOLESALE}
      delta={delta}
      show={showWholesaleSection}
    />
  </TrWrapper>);
};

RenderSalesInfoHistory.propTypes = {
  delta: PropTypes.array.isRequired
};

export default RenderSalesInfoHistory;
