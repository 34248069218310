import React from 'react';
import PropTypes from 'prop-types';
import {Field, FieldArray} from 'redux-form';
import { I18n } from 'react-redux-i18n';
import get from 'lodash.get';
import {Col, Row, Card, Alert, Button} from 'react-bootstrap';
import {FaExclamationTriangle, FaMinus} from 'react-icons/fa';
import NumericInput from '../../common/form/NumericInput';
import WillRender from '../../common/concealers/WillRender';
import ReduxMetrcIdField from '../../common/form/redux-form/ReduxMetrcIdField';
import AddItem from '../../common/form/AddItem';
import IngredientsFieldArray from './IngredientsFieldArray';
import TextInput from '../../common/form/TextInput';
import ReactSelectInput from '../../common/form/ReactSelectInput';

const PackagesFieldArray = (props) => {
  const { fields, meta, integrationState, trackingIds, itemMasterOptions, change, formValues, ingredients, ingredientsEnabled, isDisabled} = props;
  const isMetrc = get(integrationState, 'isMetrc', false);
  const {push, remove, length} = fields;

  return (
    <Card>
      <Card.Header>
        <h3 className='panel-title'>{I18n.t('packaging.form.packages')}</h3>
      </Card.Header>
      <Card.Body>
        {fields.map((fieldName, index) => (
          <div key={index}>
            <div style={{clear: 'both'}} />
            <Row key={fieldName}>
              <Col md={3}>
                <Field name={`${fieldName}.item_master_id`} component={ReactSelectInput} props={{
                  label: I18n.t('ei.processing.form.productName'),
                  options: itemMasterOptions,
                  valueKey: 'id',
                  textKey: 'name',
                  disabled: isDisabled,
                  onChange: (value) => {
                    change(`${fieldName}.item_master_id`, value);
                    const selectedItemMaster = itemMasterOptions.find((option) => option.id === value);
                    const medicatedWeight = get(selectedItemMaster, 'medicated_weight', 0);
                    const medicatedWeightUom = get(selectedItemMaster, 'medicated_weight_uom', 'GR');
                    const productPackedWeight = get(props.formValues, `${fieldName}.qty`, 0) * medicatedWeight;
                    change(`${fieldName}.medicated_weight`, medicatedWeight);
                    change(`${fieldName}.medicated_weight_uom`, medicatedWeightUom);
                    change(`${fieldName}.packaged_weight`, productPackedWeight);
                  }
                }}/>
              </Col>
              <Col md={2}>
                <Field name={`${fieldName}.package_code`} component={TextInput} props={{
                  label: I18n.t('packagingJob.form.packageCode'),
                  disabled: true,
                }}/>
              </Col>
              <Col md={2}>
                <Field name={`${fieldName}.qty`} component={NumericInput} props={{
                  label: I18n.t('packagingJob.form.quantity'),
                  fractionPartSize: 0,
                  allowNegativeNumber: false,
                  rightAddon: <span>EA</span>,
                  disabled: isDisabled,
                  onChange: (value) => {
                    change(`${fieldName}.qty`, value);
                    const medicatedWeight = get(props.formValues, `${fieldName}.medicated_weight`, 0);
                    const productPackedWeight = value * medicatedWeight;
                    change(`${fieldName}.packaged_weight`, productPackedWeight);
                  }
                }}/>
              </Col>
              <Col md={2}>
                <Field name={`${fieldName}.medicated_weight`} component={NumericInput} props={{
                  label: I18n.t('packagingJob.form.medicatedWeight'),
                  disabled: true,
                  rightAddon: <span>{get(props.formValues, `${fieldName}.medicated_weight_uom`)}</span>,
                }}/>
              </Col>
              <Col md={2}>
                <Field name={`${fieldName}.packaged_weight`} component={NumericInput} props={{
                  label: I18n.t('packagingJob.form.productPacked'),
                  disabled: true,
                  rightAddon: <span>{get(props.formValues, `${fieldName}.medicated_weight_uom`)}</span>,
                }}/>
              </Col>
              <Col md={1}>
                <Button
                  variant={length < 2 ? 'default' : 'danger'}
                  size='sm'
                  onClick={() => remove(index)}
                  disabled={length < 2 || isDisabled}
                  style={{marginTop: '30px'}}
                >
                  <FaMinus/>
                </Button>
              </Col>
              <WillRender ifTrue={isMetrc}>
                <Col md={3}>
                  <ReduxMetrcIdField
                    name={`${fieldName}.state_integration_tracking_id`}
                    props={{
                      options: trackingIds,
                      isRequired: false,
                      disabled: isDisabled
                    }}
                  />
                </Col>
              </WillRender>
            </Row>

            {ingredientsEnabled &&
              <FieldArray
                name={`${fieldName}.ingredients`}
                component={IngredientsFieldArray}
                props={{
                  change: change,
                  formValues: formValues,
                  ingredients: ingredients,
                  isDisabled: isDisabled
                }}/>
            }

          </div>
        ))}
        {meta && meta.error && (
          <Alert variant='danger'>
            <FaExclamationTriangle/>
            <span className='padding-left'>{meta.error}</span>
          </Alert>
        )}
        <AddItem
          textMode={'right'}
          alignMode={'left'}
          text={'packaging.form.addItem'}
          addAction={() => {
            push({});
          }}
          addDisabled={isDisabled}
        />
      </Card.Body>
    </Card>
  );
};

PackagesFieldArray.propTypes = {
  formValues: PropTypes.object.isRequired,
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
  }),
  integrationState: PropTypes.object.isRequired,
  trackingIds: PropTypes.array.isRequired,
  itemMasterOptions: PropTypes.array.isRequired,
  change: PropTypes.func.isRequired,
  ingredients: PropTypes.array,
  ingredientsEnabled: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default PackagesFieldArray;
