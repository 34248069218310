import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Table} from 'react-bootstrap';

function getAmountText(limit, field, compliance) {
  const options = {minimumFractionDigits:3, maximumFractionDigits:3};
  const uom = compliance && compliance.display_uom ? compliance.display_uom : 'GR';
  const value = limit[field];
  return value < 0.001 || value === undefined
    ? <span className='text-danger' style={{fontSize: 'smaller'}}>Awaiting Inventory Specification</span> //eslint-disable-line
    : uom === 'MG'
      ? `${(value * 1000).toLocaleString('en-US', options)} MG`
      : `${(value).toLocaleString('en-US', options)} GR`;
}

function getAmountClassName(limit) {
  if (!limit.grams_in_order) {
    return 'text-muted';
  }
  return limit.complianceWeight > limit.grams_remaining ? 'text-danger' : 'text-success';
}


const CureApiPurchasableAmountsTable = (props) => { //eslint-disable-line
  const {purchasableAmounts, showOrderData, compliance} = props;

  return (
    <Table bordered={true} stripped={true} size='sm' className='text-muted'>
      <thead>
      <tr>
        <th title={I18n.t('curePurchasableAmount.byCategory')}>
          {I18n.t('curePurchasableAmount.byCategory')}
        </th>
        <th>
          {I18n.t('categoryManagement.highLowTHC')}
        </th>
        <th title={I18n.t('curePurchasableAmount.limit')}>
          {I18n.t('curePurchasableAmount.limit')}
        </th>
        {showOrderData ?
          <th title={I18n.t('curePurchasableAmount.inOrder')}>
            {I18n.t('curePurchasableAmount.inOrder')}
          </th>
          : null
        }
      </tr>
      </thead>
      <tbody>
      {purchasableAmounts.map((limit, index) => (
        <tr key={index}>
          <td title={limit.form_name}>
            {limit.form_name}
          </td>
          <td title={limit.order_type_name}>
            {limit.order_type_name}
          </td>
          <td title={getAmountText(limit, 'grams_remaining')}>
            {getAmountText(limit, 'grams_remaining', compliance)}
          </td>
          {showOrderData ?
            <td
              title={getAmountText(limit, 'grams_in_order')}
              className={getAmountClassName(limit)}>
              {
                limit.complianceWeight
                  ? getAmountText(limit, 'complianceWeight', compliance)
                  : <span className='text-danger' style={{fontSize: 'smaller'}}>Awaiting Inventory Specification</span>
              }


            </td>
            : null
          }
        </tr>
      ))}
      </tbody>
    </Table>
  );
};

CureApiPurchasableAmountsTable.propTypes = {
  purchasableAmounts: PropTypes.array.isRequired,
  showOrderData: PropTypes.bool.isRequired,
  compliance: PropTypes.object.isRequired,
};

export default CureApiPurchasableAmountsTable;
