/* eslint-disable import/prefer-default-export*/
/*eslint-disable import/namespace*/
import {startSpinner} from './systemActions';
import {setData, getDataFailed} from './dataActions';
import * as dataNames from '../constants/dataNames';
import pricingGroupData from '../../data/mock/pricingGroupData';
import customerGroupData from '../../data/mock/customerGroupData';

export function getTableData(listingType) {
  return (dispatch) => {
    dispatch(startSpinner());
    return Promise
      .resolve(listingType === dataNames.pricingGroupData ? pricingGroupData : (listingType === dataNames.customerGroupData ? customerGroupData : null))
      .then(response => {
        dispatch(setData(response, dataNames[listingType]));
      })
      .catch(error => {
        if(error.response){
          dispatch(getDataFailed(error.response.data, dataNames[listingType]));
        }else{
          dispatch(getDataFailed({messages:[error]}, dataNames[listingType]));
        }
      });
  };
}
