import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {setItem} from '../../../../actions/itemActions';
import {setDefaultLabel} from '../../../../selectors/labelsSelectors';
import PrintingNotAvailable from '../../qzprinter/PrintingNotAvailable'; //eslint-disable-line
import WillRender from '../../../common/concealers/WillRender';
import * as itemNames from '../../../../constants/itemNames';
import * as dataNames from '../../../../constants/dataNames';

export const LabelPreviews = (props) => {
  if(!Array.isArray(props.printLabels)) return null;
  if(props.printLabels.length === 0) return null;

  const setLabelAsDefault = () => {
    const newDefaultLabel = props.defaultLabel === props.selectedTag ? null : props.selectedTag;
    setDefaultLabel(props.facility.id, props.labelTag, newDefaultLabel);
    props.setDefaultLabel(newDefaultLabel);
  };

  const isSelectedPrinterAvailable = (selectedPrinter) => {
    if(!selectedPrinter) return false;
    if(!selectedPrinter.server) return false;
    if(selectedPrinter.server.remote) {
      const printServer = !selectedPrinter ? false : props.printServers.find((s) => s.id === selectedPrinter.server.id);
      if (printServer && printServer.active) return true;
    } else {
      const localPrinter = props.localPrinters.find((printer) => {
        if(typeof printer === 'string') return printer === selectedPrinter.name;
        return printer.name === selectedPrinter.name;
      });
      return localPrinter !== undefined;
    }
    return false;
  };

  const selectedPrinter = props.selectedPrinter[props.labelTag] ? props.selectedPrinter[props.labelTag] : false;
  const selectedPrinterAvailable = isSelectedPrinterAvailable(selectedPrinter);

  return (
    <div>
      {
        props.printLabels
          .sort((a,b) => (a.label.width + a.label.height) - (b.label.width + b.label.height))
          .map((item, index) => {
            if(!item.label || !Object.keys(item.label).length || (Object.keys(item.label).length === 1 && item.label.call)) return null;

            const isError = !item.label.image;
            const displayWidth = `${(!isError ? item.label.width : 2) * 1.5}in`;
            const imageDivStyle = (props.printLabels.length > 1)
              ? {borderTop: '1px solid #ccc'}
              : {border: '1px solid #ccc', width: displayWidth, margin: 'auto', marginBottom: '12px'};

            const headerDivStyle = (props.printLabels.length > 1)
              ? {margin: '0 15px 0 15px', float: 'left', width: displayWidth}
              : {textAlign: 'center'};

            const imageCollection = props.printLabels.length === 1 && get(item, 'label.multiLabel', false) && get(item, 'label.image.base64_collection', false)
              ? get(item, 'label.image.base64_collection')
              : [get(item, 'label.image.base64')];

            const isSingleLabel = props.printLabels.length === 1;
            const isMultiLabel = get(item, 'label.multiLabel', false) && get(item, 'label.image.base64_collection', []).length > 1;
            const base64Images = props.printToScreenQueue.length && props.printLabels.length === 1 ? props.printToScreenQueue : imageCollection;

            return (<div
              key={index}
              style={headerDivStyle}>
              <div
                style={{
                  border: '1px solid #ccc',
                  cursor: 'pointer',
                  marginTop: '8px',
                  marginBottom: '8px'
                }}
              >
                <div style={{backgroundColor: '#fff'}}>
                  {
                    isError
                      ? (<div style={{padding: '8px'}} className='text-danger'>
                        <div>An error occurred while generating the label for <strong>{item.tag}</strong></div>
                        <div>
                          If this is a configured label, review the fields you have used to set up the label and remove them
                          one at a time to identify the row causing the label generation error.
                        </div>
                      </div>)
                      : (<div>
                        <div style={{padding: '8px'}}>
                          <div>{item.label.name}</div>
                          <div>
                            Size: {item.label.width} w X {item.label.height} h
                            Version: {(item.label.label_types && item.label.label_types.primary === 'image') ? 'V2' : 'V1'}
                          </div>
                          <div className={props.printLabels.length === 1 ? '' : 'hide'}>
                            Distinct Labels: {props.printJobStats.distinctLabels}&nbsp;
                            Total Labels: {props.printJobStats.totalLabels}&nbsp;
                            <a
                              href
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                e.target.blur();
                                props.onPrintSample();
                              }}
                            >
                              Print Sample
                            </a>
                          </div>
                          <div>
                            {
                              props.printLabels.length === 1
                                ? null
                                : <a
                                  href
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    e.target.blur();
                                    if(props.printLabels.length === 1) return false;
                                    props.setSelectedTag(item.tag)
                                      .then(() => {
                                        props.loadSelectedTag();
                                      });
                                  }}
                                >
                                  Load For Printing
                                </a>
                            }
                          </div>
                          <div>
                            {
                              !props.selectedTag
                                ? null
                                : props.printLabels.length === 1
                                ? (<a
                                  href
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    e.target.blur();
                                    props.loadLabel();
                                  }}
                                >
                                  Load All Previews
                                </a>)
                                : null
                            }
                            <div style={{clear: 'both'}} />
                            {
                              !props.selectedTag
                                ? null
                                : props.printLabels.length === 1
                                ? (<a
                                  href
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    e.target.blur();
                                    setLabelAsDefault();
                                  }}
                                >
                                  {
                                    props.selectedTag === props.defaultLabel
                                      ? 'Unset Default Label'
                                      : 'Mark As Default Label'
                                  }
                                </a>)
                                : null
                            }
                          </div>
                          {
                            props.printLabels.length > 1
                              ? null
                              : (<div style={{width: '200px', margin: 'auto', marginTop: '8px'}}>
                                <div className='input-group'>
                        <span className='input-group-btn'>
                          <button className='btn btn-default' type='button'>Copies:</button>
                        </span>
                                  <input
                                    type='text'
                                    className='form-control'
                                    value={props.printX}
                                    onBlur={(e) => {
                                      const value = e.target.value;
                                      if(typeof value === 'string') value.trim();
                                      if(!value) {
                                        props.onChangePrintX(1);
                                      }
                                    }}
                                    onChange={(e) => {
                                      props.onChangePrintX(e.target.value);
                                    }}
                                  />
                                  <span className='input-group-btn'>
                          <button
                            disabled={!selectedPrinter || !selectedPrinterAvailable || Object.keys(props.printJob).length > 0}
                            className='btn btn-primary'
                            onClick={(e) => {
                              e.target.blur();
                              props.print();
                            }}
                            type='button'>
                            Print
                          </button>
                        </span>
                                </div>
                              </div>)
                          }
                          <PrintingNotAvailable labelTag={props.labelTag} />
                          <WillRender ifTrue={isMultiLabel}>
                            <div style={{fontSize: 'smaller'}}>
                              <strong>* Prints onto multiple physical labels.</strong>
                            </div>
                          </WillRender>
                          <WillRender ifTrue={props.labelTag !== 'RETAIL_RECEIPT' && isSingleLabel}>
                            <div style={{marginTop: '8px'}}>
                              <button
                                className='btn btn-primary btn-sm'
                                onClick={(e) => {
                                  e.target.blur();
                                  props.printToScreen();
                                }}
                                type='button'>
                                Print To Screen
                              </button>
                            </div>
                          </WillRender>
                        </div>
                        <WillRender ifTrue={props.printToScreenQueue.length && props.printLabels.length === 1}>
                          <div>
                            <h5>Print To Screen Results</h5>
                          </div>
                        </WillRender>
                        {
                          base64Images.map((item, index) => {
                            return (<div style={imageDivStyle} key={index}>
                              <img
                                style={{width: '100%'}}
                                src={['data:image/png;base64', item.base64 || item]}
                              />
                            </div>);
                          })
                        }
                      </div>)
                  }
                </div>
              </div>
            </div>);
          })
      }
    </div>
  );

};

LabelPreviews.propTypes = {
  defaultLabel: PropTypes.string,
  selectedTag: PropTypes.string,
  printLabels: PropTypes.array,
  printX: PropTypes.number,
  loadLabel: PropTypes.func,
  setDefaultLabel: PropTypes.func,
  facility: PropTypes.object,
  labelTag: PropTypes.string,
  print: PropTypes.func,
  localPrinters: PropTypes.array.isRequired,
  printServers: PropTypes.array.isRequired,
  printToScreen: PropTypes.func.isRequired,
  printToScreenQueue: PropTypes.array,
};

function mapStateToProps(state){
  return {
    facility: state[itemNames.facility],
    printToScreenQueue: state[dataNames.printToScreenQueue],
  };
}

function mapDispatchToProps(dispatch){
  const actions = Object.assign({}, {setItem});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LabelPreviews);
