import React from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { I18n } from 'react-redux-i18n';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'react-bootstrap';
import TablePageWrapper from '../../common/grid/TablePageWrapper';
import { getData, postItem, getPaginatedData } from '../../../actions/apiActions';
import * as itemActions from '../../../actions/itemActions';
import { addMessage } from '../../../actions/systemActions';
import * as dataNames from '../../../constants/dataNames';
import { getClosedLoopRegulatedReceipts } from '../../../selectors/closedLoopsSelectors';
import { getTotalResults } from '../../../selectors/paginationSelectors';
import { getTabs, getColumns } from './tabDefinitions';
import { getTimezone } from '../../../selectors/timezoneSelectors';
import ReceiptDetail from './ReceiptDetail';
import {convertDbDateTimeToFormInputDateTime} from '../../../util/dateHelpers';

class RegulatedReceiptsListingPage extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.switchTab = this.switchTab.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.receiptDetail = this.receiptDetail.bind(this);
    this.retrieveAuditLog = this.retrieveAuditLog.bind(this);
    this.handleShowAuditLogModal = this.handleShowAuditLogModal.bind(this);
    this.handleCloseAuditLogModal = this.handleCloseAuditLogModal.bind(this);
    this.ref = React.createRef();

    this.state = {
      activeTab: 'pending',
      selectedReceipts: [],
      showModal: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.params.tab !== prevProps.params.tab) {
      this.getData();
    }
  }

  getData() {
    if (this.ref.current) {
      this.ref.current.wrappedInstance.ref.current.debouncedExternalSearch();
    }
  }

  switchTab(tab) {
    this.props.actions.push(`/closed-loop/receipts/${tab}`);
  }

  handleSearch(sort, search, size = 20, start, filter) {
    const approval_status = !this.props.params.tab
      ? 'pending' // Default
      : this.props.params.tab === 'inactive'
        ? 'cancelled,denied'      // inactive includes both cancelled and denied
        : this.props.params.tab;  // otherwise default to tab value

    const params = { approval_status };
    if (search) {
      params.search = search;
    }
    params.sort = sort || 'created_at desc';
    if (size) {
      params.per_page = size;
    }
    if (start) {
      params.page = (start ? start / size : 0) + 1;
    }

    this.props.actions.getPaginatedData('/api/closed_loops/inventory_receipts',
      dataNames.closedLoopRegulatedReceipts,
      null,
      params,
    );
  }

  handleSelect (isSelected, rows) {
    if(rows.length){
      this.props.actions.setItem(rows[0].id, dataNames.selectedReceiptId);
    }
    this.setState({selectedReceipts: isSelected ? rows.map((row) => row.id) : []});
  }

  receiptDetail(row) {

    return (
      <ReceiptDetail
        receiptId={row.id}
        loadData={this.state.selectedReceipts.indexOf(row.id) !== -1}
      />
    );
  }

  retrieveAuditLog(receiptId) {
    const params = {
      type: 'inventory_receipt_approval',
      entity_type: 'inventory_receipt',
      entity_id: receiptId
    };

    return this.props.actions.getData('/api/audit_logs', null, null, params, (auditLogs) => {
      this.setState({
        auditLogs
      });
    });
  }

  handleShowAuditLogModal(receiptId) {
    this.retrieveAuditLog(receiptId);
    this.setState({
      showModal: true,
    });
  }

  handleCloseAuditLogModal() {
    this.setState({
      showModal: false,
    });
  }

  renderAuditLogModal(timezone) {
    const { showModal, auditLogs } = this.state;

    if (!showModal) {
      return null;
    }

    return (
      <Modal show={showModal} onHide={this.handleCloseAuditLogModal}>
        <ModalHeader closeButton>
          <ModalTitle>Log</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <AuditLogs
            logs={auditLogs}
            timezone={timezone}/>
        </ModalBody>
        <ModalFooter>
          <Button variant='secondary' onClick={this.handleCloseAuditLogModal}>
            {I18n.t('common.actions.close')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  render() {
    const { params, dataTotalSize, receipts, actions, timezone } = this.props;
    const activeTab = params.tab || 'pending';

    return (
      <React.Fragment>
        <h1>{I18n.t('closedLoop.regulatedReceipt.listingPageTitle')}</h1>

        <TablePageWrapper
          ref={this.ref}
          settingKey='closed-loop-regulated-receipts'
          columns={getColumns(timezone, actions, activeTab, this.handleShowAuditLogModal)}
          data={receipts}
          dataTotalSize={dataTotalSize}
          activeTab={activeTab}
          tabs={getTabs()}
          switchTab={this.switchTab}
          externalSearch={this.handleSearch}
          hideSearch={false}
          hideScanSearch={true}
          hideExport={true}
          external={true}
          bstProps={{
            selectRow: {
              clickToSelect: true,
              clickToExpand: true,
              selected: this.state.selectedReceipts,
            },
            options: {
              onRowClick: (row) => this.handleSelect(true, [row]),
              expandBy: 'column'
            },
            expandableRow: () => true,
            expandComponent: this.receiptDetail,
          }}
        />
        {this.renderAuditLogModal(timezone)}
      </React.Fragment>
    );
  }
}

const AuditLogs = ({logs, timezone}) => {
  if (!logs || !logs.length) {
    return <span>No history</span>;
  }

  return logs.map((log, index) => {
    return (
      <AuditLogRow
        key={index}
        createdAt={convertDbDateTimeToFormInputDateTime(log.created_at, timezone)}
        action={log.action}
        by={`${log.user.first_name} ${log.user.last_name}`}
      />
    );
  });
};

const AuditLogRow = ({ createdAt, action, by }) => {
  return (
    <div>
      <span>{`${createdAt} - ${action} by ${by}`}</span>
    </div>
  );
};

RegulatedReceiptsListingPage.propTypes = {
  receipts: PropTypes.array.isRequired,
  dataTotalSize: PropTypes.number,
  params: PropTypes.object.isRequired,
  timezone: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    getData: PropTypes.func.isRequired,
    getPaginatedData: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    setItem: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  receipts: getClosedLoopRegulatedReceipts(state),
  timezone: getTimezone(state),
  dataTotalSize: getTotalResults(state, { name: dataNames.closedLoopRegulatedReceipts }),
});

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, itemActions,
    { getData, getPaginatedData, postItem, push, addMessage });
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegulatedReceiptsListingPage);
