import {notEmptyArrayValidation, requiredFieldValidation} from '../../common/form/redux-form/validations';

export const validate = (values) => {
  const errors = {
    taxRates: []
  };
  values.taxRates.map((taxRate) => {
    const tempObj = {};
    tempObj.tax_name = requiredFieldValidation(taxRate.tax_name,'taxes.form.taxName');
    tempObj.amount = requiredFieldValidation(taxRate.amount,'taxes.form.taxRate');

    // check that we have selected subcategories for each selected category
    tempObj.categorySubcategories = [];
    taxRate.categorySubcategories.forEach(category => {
      if (!category.apply_to_all_subcategories) {
        tempObj.categorySubcategories.push({
          subcategories: notEmptyArrayValidation(category.subcategories)
        });
      }
    });

    errors.taxRates.push(tempObj);
  });

  return errors;
};

export default validate;
