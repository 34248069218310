import React from 'react';
import PropTypes from 'prop-types';
import {Alert, Button, Col, Row} from 'react-bootstrap';
import {FaExclamationTriangle, FaMinus} from 'react-icons/fa';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import lodashGet from 'lodash.get';
import TextInput from '../../common/form/TextInput';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import QuantityByUomInput from '../../common/form/QuantityByUomInput';
import {leafWaReconciliationReasonsRequiringReceivedBy} from '../../../constants/leaf';
import * as UOMS from './../../../constants/uoms';

const HarvestItemsFieldArray = ({
  fields: { map, get, push, remove, getAll },
  meta,
  itemOptions,
  storageLocations,
  integrationAdjustmentReasons,
  integrationState,
  facilityUsers
}) => {
  const { isMetrc, isBiotrack, isPaLeaf, isWaLeaf } = integrationState;
  const prefix = isBiotrack ? 'biotrack' : isMetrc ? 'metrc' : 'leaf';
  const values = getAll();
  const options = itemOptions.filter((option) => !(values || []).some((value) => value.id === option.id));
  return (
    <div>
      {meta && meta.error ? (
        <Row>
          <Col xs={12}>
            <Alert variant='danger'>
              <FaExclamationTriangle />
              <span className='padding-left'>{meta.error}</span>
            </Alert>
          </Col>
        </Row>
      ) : (
        map((itemName, itemIndex) => {
          const currentItem = get(itemIndex);
          const shouldDisplayReceivedByDropdown = leafWaReconciliationReasonsRequiringReceivedBy.includes(get(itemIndex)['integration_adjustment_reason']);
          return (<Row key={itemName}>
            <Col xs={2}>
              <Field name={`${itemName}.lot_number`} component={TextInput} props={{
                readOnly: true,
                label: I18n.t('cultivation.finishedProduct.form.lotId'),
              }}/>
            </Col>
            <Col xs={2}>
              <Field name={`${itemName}.package_code`} component={TextInput} props={{
                readOnly: true,
                label: I18n.t('cultivation.finishedProduct.form.harvestBatch'),
              }}/>
            </Col>
            <Col xs={2}>
              <QuantityByUomInput
                name={`${itemName}.transacted_qty`}
                isRequired={true}
                label={I18n.t('cultivation.finishedProduct.form.quantity')}
                uom={lodashGet(currentItem, 'uom', UOMS.GR)}
              />
            </Col>
            <Col xs={2}>
              <Field name={`${itemName}.inventory_location_id`} component={ReactSelectInput} props={{
                label: I18n.t('cultivation.finishedProduct.form.storageLocation'),
                options: storageLocations,
              }}/>
            </Col>
              {isBiotrack || isMetrc || isPaLeaf || isWaLeaf ? (
                <Col xs={2}>
                  <Field
                    name={`${itemName}.integration_adjustment_reason`}
                    component={ReactSelectInput}
                    props={{
                      label: I18n.t(`packages.form.${prefix}AdjustmentReason`),
                      options: integrationAdjustmentReasons,
                      isRequired: true
                    }}
                  />
                </Col>
              ) : null}
              {isWaLeaf && shouldDisplayReceivedByDropdown && (
                <Col xs={4} md={2}>
                  <Field
                    name={`${itemName}.received_by_user_id`}
                    component={ReactSelectInput}
                    props={{
                      label: I18n.t(`packages.form.receivedBy`),
                      options: facilityUsers,
                      textKey: 'name',
                      valueKey: 'id',
                      isRequired: true
                    }}
                  />
                </Col>
              )}
              <Col xs={2} className='remove-button'>
                <Button size='sm' variant='primary' onClick={() => remove(itemIndex)}>
                  <FaMinus />
                </Button>
              </Col>
            </Row>
          );
        })
      )}

      {options.length ? (
        <Row>
          <Col xs={8} sm={6} md={5} lg={4}>
            <Field
              name='add_item'
              component={ReactSelectInput}
              props={{
                options,
                label: I18n.t('packages.form.selectPackage'),
                valueKey: 'id',
                textKey: 'option_name',
                onChange: (id) => {
                  const option = options.find((option) => option.id === id);
                  const { lot_number, package_code, inventory_location_id, uom_display, uom } = option;
                  push({ id, lot_number, package_code, inventory_location_id, uom_display, uom });
                }
              }}
            />
          </Col>
        </Row>
      ) : null}
    </div>
  );
};

HarvestItemsFieldArray.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    getAll: PropTypes.func.isRequired
  }).isRequired,
  itemOptions: PropTypes.array.isRequired,
  storageLocations: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
  integrationAdjustmentReasons: PropTypes.array.isRequired,
  meta: PropTypes.object,
  facilityUsers: PropTypes.array
};

export default HarvestItemsFieldArray;
