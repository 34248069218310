import React from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {Col, Row} from 'react-bootstrap';
import get from 'lodash.get';
import AccordionPanel from '../../../common/AccordionPanel';
import {getCategoryTaxRates} from '../../../../selectors/taxProfilesSelectors';
import InlineCheckBox from '../../../common/form/InlineCheckBox';

const TaxesPanel = (props) => {

  const { formValues, taxRates } = props;
  const isTaxExempt = get(formValues, 'is_non_taxable', false);

  const formatAsPercentage = (num) => {
    return new Intl.NumberFormat('default', {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(num);
  };

  return (
    <React.Fragment>
      <AccordionPanel title='products.form.taxes'>
        <Row>
          <Col sm={4}>
            <Field name='is_non_taxable' component={InlineCheckBox} props={{
              label: I18n.t('products.form.nonTaxableProduct'),
              groupClassName: 'inline-checkbox',
            }}/>
          </Col>
        </Row>
        {!isTaxExempt && taxRates &&
          <React.Fragment>
            <Row>
              <Col sm={4}/>
              <Col sm={2}>
                <div style={{fontWeight: 'bold', float: 'right'}}>{I18n.t('products.form.inState')}</div>
              </Col>
              <Col sm={2}>
                <div style={{fontWeight: 'bold', float: 'right'}}>{I18n.t('products.form.outOfState')}</div>
              </Col>
              <Col sm={4}/>
            </Row>
            {taxRates.map((taxRate, index) => {
              return (
                <React.Fragment key={index}>
                  <Row>
                    <Col sm={12}>
                      <span style={{fontWeight: 'bold'}}>{taxRate.facility_name}</span>
                    </Col>
                  </Row>
                  {(taxRate.med_in_state || taxRate.med_out_of_state)
                    ? (
                      <Row>
                        <Col sm={4}>
                          <span style={{paddingLeft: '24px'}}>{I18n.t('products.form.medical')}</span>
                        </Col>
                        <Col sm={2}>
                          {taxRate.med_in_state ? <div style={{float: 'right'}}>{formatAsPercentage(taxRate.med_in_state)}</div> : null}
                        </Col>
                        <Col sm={2}>
                          {taxRate.med_out_of_state ? <div style={{float: 'right'}}>{formatAsPercentage(taxRate.med_out_of_state)}</div> : null}
                        </Col>
                        <Col sm={4}/>
                      </Row>
                    )
                    : null
                  }
                  {(taxRate.rec_in_state || taxRate.rec_out_of_state)
                    ? (
                      <Row>
                        <Col sm={4}>
                          <span style={{paddingLeft: '24px'}}>{I18n.t('products.form.recreational')}</span>
                        </Col>
                        <Col sm={2}>
                          {taxRate.rec_in_state ? <div style={{float: 'right'}}>{formatAsPercentage(taxRate.rec_in_state)}</div> : null}
                        </Col>
                        <Col sm={2}>
                          {taxRate.rec_out_of_state ? <div style={{float: 'right'}}>{formatAsPercentage(taxRate.rec_out_of_state)}</div> : null}
                        </Col>
                        <Col sm={4}/>
                      </Row>
                    )
                    : null
                  }
                </React.Fragment>
              );})
            }
          </React.Fragment>
        }
      </AccordionPanel>
    </React.Fragment>
  );
};

TaxesPanel.propTypes = {
  formValues: PropTypes.object.isRequired,
  taxRates: PropTypes.array,
};

function mapStateToProps(state, ownProps) {
  const { formValues } = ownProps;
  const categoryId = get(formValues, 'category_id');
  return {
    taxRates: categoryId ? getCategoryTaxRates(state, {categoryId}) : null
  };
}

export default connect(mapStateToProps)(TaxesPanel);
