import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import PermissionButton from '../../common/PermissionButton';
import * as dataNames from '../../../constants/dataNames';
import * as p from '../../../constants/permissions';
import {getPhysicianPayload} from '../../../selectors/forms/physicianFormSelectors';
import {executeCallback} from '../../../util/callbackHelpers';
import ModalWrapper from '../../common/ModalWrapper';
import CreatePhysicianModal from './CreatePhysicianModal';


class AddPhysicianButtonComponent extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.ensureClinic = this.ensureClinic.bind(this);
    this.onAddButtonClick = this.onAddButtonClick.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
    this.state = {saving: false, open: false};
  }

  componentWillMount() {
    this.props.actions.ensureGetUnpaginatedData('/api/clinics', dataNames.clinics);
    this.props.actions.ensureGetUnpaginatedData('/api/countries', dataNames.countries);
    this.props.actions.ensureGetUnpaginatedData('/api/provinces', dataNames.provinces);
  }

  onAddButtonClick() {
    this.setState({open: true});
  }

  onModalClose() {
    this.setState({open: false});
  }

  onSubmit(formValues) {
    this.setState({saving: true});
    return this.ensureClinic(formValues.clinic_id)
      .then(clinic_id => {
        return this.props.actions
          .postData(
            '/api/physicians',
            getPhysicianPayload({...formValues, clinic_id}),
            dataNames.physicians,
            {failed: 'physicians.create.failed', success: 'physicians.create.success'}
          );
      })
      .then((physician) => executeCallback(this.props.onPhysicianAdded, physician))
      .then(() => this.setState({saving: false, open: false}))
      .catch(() => this.setState({saving: false, open: false}));
  }

  ensureClinic(clinic) {
    if (clinic && typeof clinic === 'object') {
      return Promise.resolve(clinic.id);
    } else if (clinic && Number.isNaN(Number(clinic)) && typeof clinic === 'string' && clinic.trim().length) {
      return this.props.actions.postData('/api/clinics', {name: clinic}, dataNames.clinics, {failed: 'clinics.create.failed'})
        .then(clinic => clinic.id);
    } else if (Number(clinic)) {
      return Promise.resolve(clinic);
    }
    return Promise.resolve(null);
  }

  render() {
    const {initialValues, clinics, countries, provinces, activeLicenseIndex, primaryAddressIndex} = this.props;
    const {open, saving} = this.state;

    return (
      <div>
        <PermissionButton permissions={[p.manage_physicians]} props={{
          variant: 'link',
          onClick: this.onAddButtonClick,
          className: 'label-link'
        }}>
          {I18n.t('physicians.actions.create')}
        </PermissionButton>
        <ModalWrapper
          Component={CreatePhysicianModal}
          onHide={this.onModalClose}
          showModal={open}
          title='physicians.create.title'
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          clinics={clinics}
          countries={countries}
          provinces={provinces}
          activeLicenseIndex={activeLicenseIndex}
          primaryAddressIndex={primaryAddressIndex}
          saving={saving}
        />
      </div>
    );
  }
}

AddPhysicianButtonComponent.propTypes = {
  actions: PropTypes.shape({
    ensureGetUnpaginatedData: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
  }).isRequired,
  initialValues: PropTypes.object,
  clinics: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  provinces: PropTypes.array.isRequired,
  primaryAddressIndex: PropTypes.number.isRequired,
  activeLicenseIndex: PropTypes.number.isRequired,
  onPhysicianAdded: PropTypes.func,
};

export default AddPhysicianButtonComponent;
