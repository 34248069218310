import {I18n} from 'react-redux-i18n';
import {requiredFieldValidation} from '../../common/form/redux-form/validations';



const validate = (values) => {
  const errors = {selectedPlants: []};
  if (!values.modification_date) {
    errors.modification_date = requiredFieldValidation(values.modification_date, 'plants.modify.modificationDate');
  }

  if(values.hasOwnProperty('beginning_tag')){
    if(!values.beginning_tag){
      errors.beginning_tag = requiredFieldValidation(values.beginning_tag, 'plants.modify.beginningTag');
    } else if (values.beginning_tag.length < 2){
      errors.beginning_tag = requiredFieldValidation(values.beginning_tag, 'plants.modify.beginningTag');
    }
  }

  if(values.hasOwnProperty('split_plant_batch_tag')){
    if(!values.split_plant_batch_tag){
      errors.split_plant_batch_tag = requiredFieldValidation(values.split_plant_batch_tag, 'plants.modify.splitPlantBatchTag');
    } else if (values.split_plant_batch_tag.length < 2){
      errors.split_plant_batch_tag = requiredFieldValidation(values.split_plant_batch_tag, 'plants.modify.splitPlantBatchTag');
    }
  }

  if(values.hasOwnProperty('propagation_catch')){
    errors.propagation_catch = I18n.t('plants.modify.propagationMixError');
  }

  values.selectedPlants.map(plant => {
    errors.selectedPlants.push();
    if (parseInt(plant.qty, 10) > 9999999) {
      errors.selectedPlants.push({qty: I18n.t('plants.form.mustBeSmallEnoughErrorForGroup')});
    } else if (!parseInt(plant.qty, 10)) {
      errors.selectedPlants.push({qty: I18n.t('plants.form.enterQuantityError')});
    } else {
      errors.selectedPlants.push({qty: undefined});
    }
  });

  /***
   * Return the numeric value of the plant_id (enumeration) for the range selector passed in or false if not present
   * @param fieldKey
   * @returns {any}
   */
  const getPlantNumber = (fieldKey) => {
    const plant_id = values[fieldKey];
    return plant_id
      ? parseInt(plant_id.split('.').pop())
      : false;
  };

  // Ensure plant ranges are valid
  const fieldKeys = {start: 'plant_range_start', end: 'plant_range_end'};
  if(values.selected_batch_name && Array.isArray(values.selected_batch_name)){
    const batches = values.selected_batch_name.map((batch) => batch.batch_name);
    batches.forEach((batch) => {
      const start = getPlantNumber(`${fieldKeys.start}${batch}`);
      const end = getPlantNumber(`${fieldKeys.end}${batch}`);
      if(start && end && start > end) {
        errors[`${fieldKeys.start}${batch}`] = 'Range start must be less than or equal to the range end.';
        errors[`${fieldKeys.end}${batch}`] = 'Range end must be greater than or equal to the range start.';
      }
      if(!start) errors[`${fieldKeys.start}${batch}`] = 'Range start is required.';
      if(!end) errors[`${fieldKeys.end}${batch}`] = 'Range end is required.';
    });
  }
  return errors;
};

export default validate;
