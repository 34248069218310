import  React from 'react';
import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Field } from 'redux-form';
import {Col,Row} from 'react-bootstrap';
import {INVENTORY_SYNC_SEARCH_FORM} from '../../constants/forms';
import {getCategoryOptions} from '../../selectors/productSalesReportSelectors';
import {getLocationSectionOptions} from '../../selectors/locationsSelectors';
import MultiselectInput from '../common/form/MultiselectInput';
import SubmitSection from '../common/form/SubmitSection';

const SearchForm = (props) => {
  const {
        categoryOptions,
        sectionOptions,
        pristine,
        handleSubmit,
        submitSearch
    } = props;

  const settings = {
    actionSettings: {
      submit: {
        action: handleSubmit(submitSearch),
        text: I18n.t('inventory.sync.actions.submit'),
        pristine,
        disabled: categoryOptions.length < 1
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(submitSearch)}>
      <Row>
        <Col sm={12}>
          <Field
              name='category_ids'
              component={MultiselectInput}
              props={{
                label: I18n.t('inventory.sync.forms.category'),
                options: categoryOptions,
                allOption: true,
                isRequired: true,
                valueKey: 'value',
                textKey: 'text',
              }}
          />
          <Field
              name='locations'
              component={MultiselectInput}
              props={{
                label: I18n.t('inventory.sync.forms.location'),
                allOption: true,
                options: sectionOptions,
                valueKey: 'value',
                textKey: 'text',
              }}
          />
          <SubmitSection settings={settings} />
        </Col>
      </Row>
    </form>
  );
};

SearchForm.propTypes = {
  categoryOptions: PropTypes.array.isRequired,
  sectionOptions: PropTypes.array.isRequired,
  initialValues: PropTypes.object,
  submitSearch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  hasSyncInventoryPermission: PropTypes.bool,
  reset: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
};

// SearchForm store connection
const mapStateToProps = (state) => {
  return {
    categoryOptions: getCategoryOptions(state),
    sectionOptions: getLocationSectionOptions(state),
  };
};

// SearchForm initial values
const initialValues = {
  category_ids: [],
  locations: [],
};

const ReduxSearchForm = reduxForm({
  form: INVENTORY_SYNC_SEARCH_FORM,
  initialValues,
  // validate
})(SearchForm);

const InventorySyncSearchForm = connect(mapStateToProps)(ReduxSearchForm);

InventorySyncSearchForm.propTypes = {
  submitSearch:       PropTypes.func.isRequired,
};

export default InventorySyncSearchForm;