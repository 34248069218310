//eslint-disable indent
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';

class PlatformMmuEquivalencyDisplay extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
  }

  getOrderedMmuTotal(customerLimits) {
    const external_totals = Object.values(get(customerLimits, 'limits.external_totals', {})).reduce((value, subtotal) => value + subtotal, 0);
    const order_mmus = get(customerLimits, 'limits.order_mmus', []).map(order => order.mmus).reduce((value, subtotal) => value + subtotal, 0);
    return external_totals + order_mmus;
  }

  render() {
    const {salesLimits, customerLimits} = this.props;

    const limit = get(salesLimits, 'limit_to.mmus');
    const interval = get(salesLimits, 'limit_to.interval');
    const time_period = get(salesLimits, 'limit_to.time_period');

    const current_order_mmus = get(customerLimits, 'limits.current_order_mmus');
    const current_order_mmus_default_zero = get(customerLimits, 'limits.current_order_mmus');
    const ordered_mmu_total = customerLimits ? this.getOrderedMmuTotal(customerLimits) - current_order_mmus_default_zero : null;
    const remaining = get(customerLimits, 'limits.remaining');

    return (
      <table className='table table-sm table-bordered text-muted' style={{fontSize: 'smaller', backgroundColor: 'inherit'}}>
        <thead>
        <tr>
          <th style={{color: '#337ab7'}}>{I18n.t('cart.limitTable.purchaseLimit')}</th>
          <th style={{color: '#337ab7'}}>{I18n.t('cart.limitTable.previouslyPurchased')}</th>
          <th style={{color: '#337ab7'}}>{I18n.t('cart.limitTable.inOrder')}</th>
          <th style={{color: '#337ab7'}}>{I18n.t('cart.limitTable.remaining')}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>{limit} {I18n.t('retail.salesSettings.mmu')} / {interval} {time_period}{(interval > 1) ? 's' : ''}</td>
          <td>{Object.keys(customerLimits).length !== 0 ? ordered_mmu_total.toFixed(2) : <span className='blink'>?</span>}</td>
          <td>{current_order_mmus != null ? current_order_mmus.toFixed(2) : <span className='blink'>?</span>}</td> {/* Used abstract equality operator on purpose! */}
          <td>{remaining ? remaining.toFixed(2) : <span className='blink'>?</span>}</td>
        </tr>
        </tbody>
      </table>
    );
  }
}

PlatformMmuEquivalencyDisplay.propTypes = {
  consumer: PropTypes.object,
  limits: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(PlatformMmuEquivalencyDisplay);
