/* eslint-disable import/no-named-as-default */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack} from 'react-router-redux';
import {formValueSelector} from 'redux-form';
import {getHarvestStorageLocations} from '../../../selectors/locationsSelectors';
import {getIntegrationState} from '../../../selectors/integration/integrationSelectors';
import {getSelectedHarvest} from '../../../selectors/harvests/harvestsSelectors';
import {convertDbDateTimeToClientTzMoment} from '../../../util/dateHelpers';
import * as apiActions from '../../../actions/apiActions';
import * as selectedDataActions from '../../../actions/selectedDataActions';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import FormWrapper from '../../common/form/FormWrapper';
import ModifyHarvestBatchFormWrapper from './ModifyHarvestBatchFormWrapper';
import getPayload from '../../../selectors/forms/modifyHarvestBatchFormSelector';
import * as messageTypes from '../../../constants/messageTypes';
import {addMessage} from '../../../actions/systemActions';
import {MODIFY_HARVEST_BATCH_FORM} from '../../../constants/forms';

export class ModifyHarvestBatchPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  componentWillMount() {
    this.props.actions.getItem('/api/integration-settings', itemNames.integrationSettings, {failed: 'stateIntegratorSettings.get.failed'});
    const {integrationState: {isBiotrack, isMetrc}} = this.props;
    if (isBiotrack) {
      this.props.actions.getUnpaginatedData('/api/cultivation/sections', dataNames.sections, {failed: 'locations.get.failed'});
    } else if (isMetrc) {
      this.props.actions.getUnpaginatedData('/api/integration/harvest-waste-types', dataNames.harvestWasteTypes, {failed: 'harvest.form.getHarvestWasteTypes.failed'});
      this.props.actions.getUnpaginatedData('/api/location_hierarchy', dataNames.locations, {failed: 'locations.get.failed'}, {is_sales_location:0});
    } else {
      this.props.actions.getUnpaginatedData('/api/location_hierarchy', dataNames.locations, {failed: 'locations.get.failed'}, {is_sales_location:0});
    }
  }

  redirect() {
    this.props.actions.goBack();
  }

  onSubmit(harvest) {
    const payload = getPayload(harvest);
    const messages = {failed: 'harvestBatch.modify.fail', success: 'harvestBatch.modify.success'};
    this.props.actions.putData(`/api/harvests/${harvest.id}`, payload, dataNames.harvestBatch, messages, null,
      () => {
        this.props.actions.goBack();
        this.props.actions.clearSelectedData(dataNames.harvests);
      }
    ).then((result) => {
      if ((this.props.integrationState.isMetrc) && (payload.weight_before !== (payload.waste + result.current_weight))) {
        this.props.actions.addMessage(messageTypes.warning, ['metrc.form.noSpecificApiCall']);
      }
    });
  }

  render() {
    const {harvestBatch, sections, integrationState, harvestWasteTypes, formValues} = this.props;
    const completedAt = harvestBatch && harvestBatch.completed_at
      ? convertDbDateTimeToClientTzMoment(harvestBatch.completed_at)
      : '';
    const hasFinalWeight = Boolean(completedAt);
    const initialValues = Object.assign({}, harvestBatch, {
      newWeight: hasFinalWeight && harvestBatch.current_weight ? harvestBatch.current_weight : '',
      newOtherMaterialWeight: hasFinalWeight && harvestBatch.current_other_material_weight ? harvestBatch.current_other_material_weight : '',
      inventory_location_id: harvestBatch && harvestBatch.location_name ? harvestBatch.inventory_location_id : null,
      reportWaste: '0.00',
      otherMaterialWaste: '0.00',
      action_date: completedAt,
      finalWeight: hasFinalWeight,
      hasFinalWeight
    });

    return (
      <FormWrapper
        className='modify-harvest-batch-page'
        title={'harvestBatch.modify.title'}
        goBack={this.redirect} >
        <ModifyHarvestBatchFormWrapper
          handleSave={this.onSubmit}
          initialValues={initialValues}
          enableReinitialize={true}
          sections={sections}
          integrationState={integrationState}
          harvestWasteTypes={harvestWasteTypes}
          harvestBatch={harvestBatch}
          formValues={formValues}
        />
      </FormWrapper>
    );
  }
}

ModifyHarvestBatchPage.propTypes = {
  actions: PropTypes.object.isRequired,
  harvestBatch: PropTypes.object.isRequired,
  sections: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
};

const selector = formValueSelector(MODIFY_HARVEST_BATCH_FORM);

function mapStateToProps(state) {
  return {
    formValues : {
      newWeight: selector(state, 'newWeight')
    },
    sections: getHarvestStorageLocations(state),
    harvestBatch: getSelectedHarvest(state),
    integrationState: getIntegrationState(state),
    harvestWasteTypes: state[dataNames.harvestWasteTypes],
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({},
    apiActions,
    selectedDataActions,
    {
      goBack,
      addMessage,
    }
  );
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModifyHarvestBatchPage);
