import {reduxForm} from 'redux-form';
import AssemblyForm from './../common/AssemblyForm';
import validate from './../common/validate';

const AssemblyFormWrapper = reduxForm({
  validate,
  enableReinitialize: true,
})(AssemblyForm);


export default AssemblyFormWrapper;
