import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';
import ProcessingTypeForm from '../common/ProcessingTypeForm';
import {validateProcessingTypeForm as validate} from '../common/validate';

const CreateProcessingTypeFormWrapper = reduxForm({
  validate
})(ProcessingTypeForm);

CreateProcessingTypeFormWrapper.propTypes = {
  form: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  getFormValue: PropTypes.func.isRequired,
  phases: PropTypes.array.isRequired,
  saving: PropTypes.bool.isRequired,
  equipmentItems: PropTypes.array.isRequired,
  uoms: PropTypes.array.isRequired,
  ingredients: PropTypes.array.isRequired,
  getPossibleUom: PropTypes.func.isRequired,
  calculateTotalCost: PropTypes.func.isRequired,
  onChangeSubcategories: PropTypes.func.isRequired,
  isTouch: PropTypes.object.isRequired
};

export default CreateProcessingTypeFormWrapper;
