import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';

import { displayQtyCurried } from '../../selectors/uomsSelectors';
import InternationalDecimalStatic from './form/InternationalDecimalStatic';



const InternationalDisplayQty = ({displayQty, qty, uom, displayUom }) => {
  const suffix = (displayUom && uom) ?  ' ' + I18n.t(`uoms.${uom}.abbrev`) : '';
  return <InternationalDecimalStatic suffix={suffix}>{displayQty(qty,uom)}</InternationalDecimalStatic>;
};


InternationalDisplayQty.propTypes = {
  qty: PropTypes.number,
  uom: PropTypes.string,
  displayUom: PropTypes.bool,
  displayQty: PropTypes.func.isRequired
};

InternationalDisplayQty.defaultProps = {
  displayQty: (qty,uom) => {},
  displayUom: false,
};

const mapStateToProps = state => ({
  displayQty: displayQtyCurried(state),
});


export default connect(mapStateToProps)(InternationalDisplayQty);
