import get from 'lodash.get';
import {set} from 'lodash';
import {
  minLengthValidation,
  maxLengthValidation,
  requiredFieldValidation,
  minValidation
} from '../../common/form/redux-form/validations';

const validate = (values, props) => {

  // Delete submit actions don't need to be validated
  if (values.action === 'delete') {
    return {};
  }

  // Validate name
  const name = requiredFieldValidation(values.name, 'products.form.productName') ||
    minLengthValidation(values.name, 'products.form.productName', 3) ||
    maxLengthValidation(values.name, 'products.form.productName', 250);

  // saveProgress (draft) actions only require a Product Name
  if (values.action === 'saveProgress') {
    return {name};
  }

  const {integrationState} = props;
  const {isApisicpa, isBiotrack, isMetrc} = integrationState;
  const is_discrete = get(values, 'default_uom', '') === 'EA';

  //const selectedCategory = categoryOptions.find(category => category.id === (values.category_id || 1));
  //const selectedSubcategory = selectedCategory && selectedCategory.subcategories.find(subcategory => subcategory.id === values.subcategory_id);

  const errors = {
    name,
    category_id: requiredFieldValidation(values.category_id, 'products.form.productCategory'),
    subcategory_id: requiredFieldValidation(values.subcategory_id, 'products.form.productSubcategory'),
    default_uom: requiredFieldValidation(values.default_uom, 'products.form.productUom'),
    medicated_weight : is_discrete
      ? requiredFieldValidation(values.medicated_weight, 'products.form.medicatedNetWeight')
        || (isBiotrack && minValidation(values.medicated_weight, 'products.form.medicatedNetWeightCannotBeZero', 0, true))
      : null,
    medicated_weight_uom: is_discrete ? requiredFieldValidation(values.medicated_weight_uom, 'products.form.uom') : null,
    vendors: [{partner_id: values.vendors && values.vendors.length > 0
      ? requiredFieldValidation(values.vendors[0].partner_id, 'products.form.productPrimaryPartner')
      : null}],
    description: (isApisicpa || isMetrc)
      ? requiredFieldValidation(values.description)
      : null,
  };

  // If Metrc check the d9_thca test result value needs to exist and have a value
  if (isMetrc && get(values, 'test_results_source') === 'item_master' && values.potency_test_results) {
    const d9_thca_index = values.potency_test_results.findIndex((potency_test_result) => potency_test_result.name === 'd9_thca');
    if (d9_thca_index === -1) {
      // Value does not exist. Add error for first potency_test_results field
      set(errors, 'potency_test_results.0.name', requiredFieldValidation(null, 'products.form.d9_thca'));
    }
    else {
      // Check if the value field is not empty
      const d9_thca_value = get(values, `potency_test_results.${d9_thca_index}.value`);
      set(errors, `potency_test_results.${d9_thca_index}.value`, requiredFieldValidation(d9_thca_value, 'products.form.d9_thca'));
    }
  }




  return errors;
};

export default validate;
