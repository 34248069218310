import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { goBack } from 'react-router-redux';
import { change } from 'redux-form';
import FormWrapper from '../../../common/form/FormWrapper';
import * as apiActions from '../../../../actions/apiActions';
import * as dataNames from '../../../../constants/dataNames';
import * as itemNames from '../../../../constants/itemNames';
import { unsetItem } from '../../../../actions/itemActions';
import { getInitialValues, getPayload } from '../../../../selectors/forms/taxProfileFormSelectors';
import { getIntegrationState } from '../../../../selectors/integration/integrationSelectors';
import {TAX_PROFILE_FORM} from '../../../../constants/forms';
import TaxProfileForm from './components/TaxProfileForm';
import InProgressOverlay from '../../../common/InProgressOverlay';

export class TaxProfileEditorPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.state = {
      saving: false,
    };
    this.goBack = this.goBack.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount(){
    const {actions: {getUnpaginatedData, unsetItem, getItem}} = this.props;
    unsetItem(itemNames.taxProfile);
    getUnpaginatedData('/api/facilities', dataNames.facilities);
    getUnpaginatedData('/api/categories', dataNames.categories);
    getUnpaginatedData('/api/tax_profiles', dataNames.taxProfiles, {failed: 'taxProfiles.get.failed'}),
    getUnpaginatedData(`/api/taxes`, dataNames.taxes, null, {detailed: 1, active: 1}).then(() => {
      if(this.props.params.id !== 'create'){
        getItem(`/api/tax_profiles/${this.props.params.id}`, itemNames.taxProfile, null, {detailed: true});
      }
    });
  }

  goBack(){
    this.props.actions.goBack();
  }

  onSubmit(formData){
    this.setState({saving: true});
    const payload = getPayload(formData, this.props.taxProfiles);
    let url = '/api/tax_profiles';
    let method = 'postItem';
    if(formData.id){
      url += `/${formData.id}`;
      method = 'putItem';
    }
    this.props.actions[method](url, payload, 'noOp', {success: 'taxProfiles.successSave'})
      .then((data) => {
        this.setState({saving: false});
        if (!formData.stay) {
          return this.goBack();
        }
        this.props.actions.getItem(`/api/tax_profiles/${data.id}`, itemNames.taxProfile, null, {detailed: true});
        this.props.actions.change(TAX_PROFILE_FORM, 'stay', false);
      });
  }

  render () {
    const {initialValues, facilities, taxProfiles, integrationState, facility} = this.props;
    return (
      <FormWrapper title='taxProfile' goBack={this.goBack}>
        <InProgressOverlay isActive={this.state.saving} message='common.form.saving' translate={true}/>
        <TaxProfileForm
          initialValues={initialValues}
          facilities={facilities}
          taxProfiles={taxProfiles}
          integrationState={integrationState}
          facility={facility}
          onSubmit={this.onSubmit}
        />
      </FormWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    initialValues: getInitialValues(state),
    facilities: state[dataNames.facilities],
    taxProfiles: state[dataNames.taxProfiles],
    integrationState: getIntegrationState(state),
    facility: state[itemNames.facility],
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {goBack, unsetItem, change});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TaxProfileEditorPage);
