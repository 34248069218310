import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {reduxForm, formValueSelector, getFormValues, Field, FieldArray, getFormSyncErrors} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Col, FormGroup, ListGroup, ListGroupItem, Row, Card, Button} from 'react-bootstrap';
import * as itemNames from '../../../../constants/itemNames';
import {
  canCreateMatchingOrder,
  getSalesOrderItemMasters,
  getUnusedSalesOrderItemMasters,
  getPartnerFacilitiesForSalesOrder,
  getPartnersForSalesOrder,
  getOddMoneyFormValue
} from '../../../../selectors/forms/salesOrderFormSelectors';
import InternationalCurrencyInput from '../../../common/form/InternationalCurrencyInput';
import InternationalDecimalInput from '../../../common/form/InternationalDecimalInput';
import InternationalDateStatic from '../../../common/form/InternationalDateStatic';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import TextInput from '../../../common/form/TextInput';
import InternationalDateTimePickerInput from '../../../common/form/InternationalDateTimePickerInput';
import {SALES_ORDER_FORM} from '../../../../constants/forms';
import SubmitSection from '../../../common/form/SubmitSection';
import TextAreaInput from '../../../common/form/TextAreaInput';
import AccordionPanel from '../../../common/AccordionPanel';
import OrderLines from './OrderLines';
import Payments from './Payments';
import {shouldValidate, validateOrderForm as validate} from './validate';
import PartnerSection from './PartnerSection';
import CreateMatchingOrderOption from '../../common/CreateMatchingOrderOption';
import {getSalesOrderForModifyPage} from '../../../../selectors/salesOrdersSelectors';
import WillRender from '../../../common/concealers/WillRender';
import CheckBoxInput from '../../../common/form/CheckBoxInput';
import {getValidFacilityTypesForSupplyChain} from '../../../../selectors/partnersSelectors';
import {isMetrcTransfersEnabled} from '../../../../selectors/integration/metrcSelectors';
import {
  getFacilityType,
  isActiveFacilityDispensary,
  isLeafPaConfigPackClosedLoopFacility
} from '../../../../selectors/facilitiesSelectors';
import { isFeatureEnabled } from '../../../../selectors/featureToggles';

const medicatedStatusOptions = [
  {text: I18n.t('purchaseOrders.form.medicated'), value: 1},
  {text: I18n.t('purchaseOrders.form.nonMedicated'), value: 0},
];

export const OrderForm = (props) => {
  const {
    blur,
    reset,
    users,
    change,
    errors,
    actions,
    balance,
    oddMoney,
    partners,
    transfer,
    onCancel,
    isCanada,
    locations,
    registers,
    partnerId,
    linesCount,
    containers,
    orderTypes,
    order_type,
    formValues,
    renderFlags,
    order_total,
    itemMasters,
    orderNumber,
    paymentTerms,
    paymentTypes,
    handleSubmit,
    inventoryList,
    facilityTypes,
    paymentStatus,
    existingNotes,
    transferRecord,
    prepackWeights,
    onOpenTransfer,
    canAddPayments,
    showPartnerType,
    changeDueRecord,
    salesOrderSaved,
    selectedFacility,
    integrationState,
    unusedItemMasters,
    partnerFacilities,
    transferLinesLimit,
//  isLinkedSalesOrder,
    onChangeLocalState,
    showContainerInputs,
    showStorageLocations,
    matchingOrderWarnings,
    canCreateMatchingOrder,
    partnerFacilityOptions,
    isMetrcTransfersEnabled,
    salesOrderConnectsStatus,
    getSellableItemMasterById,
    limitUtahLabTransfersToOneProduct,
    addTouchedIM,
    touchedIM,
    isReservationEnabled,
    hasMedicatedLines,
    isMappingRequiredByPlatform,
    metrcIsImported,
    facilityDetailed,
    isLeafPaClosedLoopFacility,
    isActiveFacilityDispensary,
    categories,
  } = props;

  const { isLeaf, isPaLeaf, isBiotrack, isOklahoma, isMetrc } = integrationState;

  const isLabOrderType = (order_type === 'lab');

  const submitSectionSettings = {
    actionSettings: {},
    align: 'right'
  };

  const onPartnerFacilityChange = (partnerFacilityId) => {
    // Check if this is a return when Partner Facility is selected and a Leaf PA Closed Loop Facility
    if (partnerFacilityId && isLeafPaClosedLoopFacility) {
      // Retrieve MJP facility record for partner facility
      actions.getItem(`/api/partner_facilities/facility_details/${partnerFacilityId}`, itemNames.partnerFacilityDetails)
        .then((partnerFacilityDetails) => {
          const partnerFacilityIsDispensary = get(partnerFacilityDetails, 'type', '') === 'dispensary';
          change('is_return', isActiveFacilityDispensary && !partnerFacilityIsDispensary);
        });
    }
  };

  if(!salesOrderSaved){
    submitSectionSettings.actionSettings.reset = {
      text: I18n.t('common.actions.reset'),
      style: 'default',
      action: reset
    };
  }

  const afterSubmitAction = () => {
    onChangeLocalState('afterSubmit', 'completeTransfer');
  };

  const onConnectsResponse = (response) => {
    change('connectsResponse', response);
    onChangeLocalState('afterSubmit', response);
    return true;
  };

  const connectsSubmitSectionSettings = Object.assign({}, submitSectionSettings);

  submitSectionSettings.actionSettings = Object.assign({}, submitSectionSettings.actionSettings, {
    completeTransfer: {
      type: (!salesOrderSaved) ? 'submit' : 'button',
      text: (!salesOrderSaved) ? I18n.t('purchaseOrders.form.completeTransfer') : 'Begin Transfer',
      action: (!salesOrderSaved) ? afterSubmitAction : actions.onTransfer,
      disabled: metrcIsImported || transfer.status === 'completed'
    },
    submit: {
      type: (!salesOrderSaved) ? 'submit' : 'button',
      text: (!salesOrderSaved) ? I18n.t('common.actions.save') : 'Exit To List',
      action: (!salesOrderSaved) ? handleSubmit : actions.onBack,
    }
  });

  connectsSubmitSectionSettings.actionSettings = {
    amend: {
      type: (!salesOrderSaved) ? 'submit' : 'button',
      style: 'warning',
      text: I18n.t('purchaseOrders.form.amend'),
      action: () => onConnectsResponse('amended'),
    },
    decline: {
      type: (!salesOrderSaved) ? 'submit' : 'button',
      style: 'danger',
      text: I18n.t('purchaseOrders.form.decline'),
      action: () => onConnectsResponse('declined'),
    },
    accept: {
      type: (!salesOrderSaved) ? 'submit' : 'button',
      style: 'primary',
      text: I18n.t('purchaseOrders.form.accept'),
      action: () => onConnectsResponse('accepted'),
    },
  };

  const paymentStatuses = [
    {value: 'ordered', text: I18n.t('salesOrders.form.orderPlaced')},
    {value: 'partial', text: I18n.t('salesOrders.form.partialPayment')},
    {value: 'completed', text: I18n.t('salesOrders.form.paid')},
  ];

  const transferTypes = [
    {value: 'internal', text: I18n.t('salesOrders.form.internal')},
    {value: 'wholesale', text: I18n.t('salesOrders.form.wholesale')}
  ];
  if (isLabOrderType) {
    transferTypes.push({value: 'testing_lab', text: I18n.t('salesOrders.form.testingLab')});
  }

  const statusOptions = {
    open           : I18n.t('cultivation.transfers.form.open'),
    out_for_delivery: I18n.t('cultivation.transfers.form.outForDelivery'),
    completed      : I18n.t('cultivation.transfers.form.completed')
  };

  const orderClassOptions = [
      {value: 'recreational', text: I18n.t('salesOrders.form.orderTypes.recreational')},
      {value: 'medical', text: I18n.t('salesOrders.form.orderTypes.medical')}
  ];

  const showPartnerInvoiceNumber = Boolean(!isMappingRequiredByPlatform || !hasMedicatedLines || formValues.is_return);

  // Do not make the partner invoice required for now. The typical process is that the PO is not created until the SO is done.
  const partnerInvoiceNumberRequired = false; // formValues.is_return;

  return (
    <form className='sales-order-form' noValidate onSubmit={handleSubmit}>
      {isMetrc && isMetrcTransfersEnabled && (facilityDetailed && !facilityDetailed.state_license) &&
        <div className='alert alert-warning'>{I18n.t('supplyChain.metrcTransferWarnings.facilityLicense')} <a href='/organization/create'>{I18n.t('facility.setup.organizationProfile')}</a></div>
      }

      <FormGroup className='general'>
        <PartnerSection
          change={change}
          formValues={formValues}
          orderTypeOptions={orderTypes}
          partnerOptions={partners}
          partnerFacilityOptions={partnerFacilityOptions}
          selectedFacility={selectedFacility}
          partnerFacilities={partnerFacilities}
          showPartnerType={showPartnerType}
          partnerFacilityTypes={facilityTypes}
          disabled={Boolean(transferRecord && transferRecord.id !== undefined || canCreateMatchingOrder === -1 || metrcIsImported)}
          isOklahoma={isOklahoma}
          isMetrc={isMetrc}
          isMetrcTransfersEnabled={isMetrcTransfersEnabled}
          showPartnerInvoiceNumber={showPartnerInvoiceNumber}
          onPartnerFacilityChange={onPartnerFacilityChange}
          partnerInvoiceNumberRequired={partnerInvoiceNumberRequired}
        />
        <Row>
          {isLeaf || isBiotrack ?
            <Col xs={4} sm={3} md={3} lg={2}>
              <Field
                name='contains_medicated'
                component={ReactSelectInput}
                props={{
                  label: I18n.t('purchaseOrders.form.medicatedStatus'),
                  options: medicatedStatusOptions,
                  isRequired: true,
                  disabled: ['lab', 'waste_disposal'].indexOf(formValues.order_type) > -1 || Boolean(orderNumber),
                }}
              />
            </Col>
            : null
          }
          <Col xs={4} sm={3} md={3} lg={2}>
            <InternationalDateTimePickerInput
              name='date_ordered'
              props={{
                label: I18n.t('purchaseOrders.form.dateOrdered'),
                isRequired: true,
                timeFormat: false,
              }}
              inputProps={{
                disabled: metrcIsImported
              }}
            />
          </Col>
          <Col xs={4} sm={3} md={3} lg={2}>
            <InternationalDateTimePickerInput
              name='date_expected'
              props={{
                label: I18n.t('purchaseOrders.form.dateExpected'),
                isRequired: true
              }}
              inputProps={{
                disabled: metrcIsImported
              }}
            />
          </Col>
          {isReservationEnabled &&
          <Col xs={4} sm={3} md={3} lg={2}>
            <Field
              name='reserve_inventory'
              component={ReactSelectInput}
              props={{
                label: I18n.t('purchaseOrders.form.reserveSalesQuantity'),
                clearable: false,
                searchable: false,
                backspaceRemoves: false,
                deleteRemoves: false,
                options: [
                  {text: I18n.t('general.yes'), value: 1},
                  {text: I18n.t('general.no'), value: 0}
                ]
              }}
            />
          </Col>}
        </Row>
        <WillRender ifTrue={isCanada || isPaLeaf}>
          <Row>
            <Col sm={4}>
              <Field
                name='is_return'
                component={CheckBoxInput}
                props={{
                  label: I18n.t('salesOrders.form.return'),
                  groupClassName: 'inline-checkbox',
                  disabled: isPaLeaf
                }}
              />
            </Col>
          </Row>
        </WillRender>
        <Row>
          <Col xs={6} sm={4} md={4} lg={3}>
            <Field name='title' component={TextInput} props={{
              label: I18n.t('cultivation.salesOrders.form.soTitle'),
              readOnly: true
            }}/>
          </Col>
          {isCanada ?
          <Col xs={6} sm={4} md={4} lg={3}>
            <Field
              name='order_class'
              component={ReactSelectInput}
              props={{
                label: I18n.t('salesOrders.form.order'),
                options: orderClassOptions,
                isRequired: true,
              }}
            />
          </Col>
          : null}
          {orderNumber ? <Col xs={6} sm={4} md={4} lg={3}>
            <Field name='orderNumber' component={TextInput} props={{
              label: I18n.t('cultivation.salesOrders.form.soNumber'),
              readOnly: true,
              value: orderNumber
            }}/>
          </Col> : null}
        </Row>
        {paymentStatus !== 'cancelled' && onCancel && <Row>
          <Col xs={6} sm={4} md={6}>
            <div className={canCreateMatchingOrder === -1 ? 'alert alert-info' : ''}>
              <Button onClick={onCancel} variant='danger' type='button' disabled={transfer.status === 'completed'} style={{float:'left', margin: '0 15px 8px 0'}}>
                {I18n.t('salesOrders.form.cancelOrder')}
              </Button>
              {
                canCreateMatchingOrder === -1
                  ? <span>
                  {I18n.t('supplyChain.cancelHint', {sourceType: I18n.t('supplyChain.salesOrder'), targetType: I18n.t('supplyChain.purchaseOrder')})}&nbsp;
                  <strong>{I18n.t('supplyChain.cancelWarning', {targetType: I18n.t('supplyChain.purchaseOrder')})}</strong>
                </span>
                  : null
              }
            </div>
          </Col>
        </Row>}

          {
            !transferRecord || Object.keys(transfer).length === 0 || transfer.status === 'cancelled'
              ? null
              : (
              <Row>
                <Col xs={12}>
                  <div className='alert-info' style={{padding: '10px'}}>
                    {I18n.t('salesOrders.form.hasTransfer', {transferId: transfer.transfer_number, status: statusOptions[transfer.status]})}&nbsp;
                    <strong>
                    <a
                      className='float-right'
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        onOpenTransfer(transferRecord.transfer_id);
                      }}
                    >
                      {I18n.t('salesOrders.form.openTransfer', {transferId: transfer.transfer_number})}
                    </a>
                    </strong>
                  </div>
                  </Col>
              </Row>
            )
          }
      </FormGroup>
      <AccordionPanel title='purchaseOrders.form.orderItems' className='accordion-panel'>
        <FieldArray
          name='lines'
          component={OrderLines}
          categories={categories}
          itemMasters={itemMasters}
          unusedItemMasters={unusedItemMasters}
          change={change}
          getSellableItemMasterById={getSellableItemMasterById}
          storageLocations={locations}
          containers={containers}
          lineTypes={[]}
          selectedItemMasters={[]}
          prepackWeights={prepackWeights || {}}
          showContainerInputs={showContainerInputs}
          showStorageLocations={showStorageLocations}
          formValues={formValues}
          partnerId={partnerId}
          transferRecord={transferRecord}
          onOpenTransfer={onOpenTransfer}
          inventoryList={inventoryList}
          order_type={order_type}
          contains_medicated={formValues.contains_medicated}
          transferLinesLimit={transferLinesLimit}
          metrcIsImported={metrcIsImported}
          addTouchedIM={addTouchedIM}
          touchedIM={touchedIM}
          blur={blur}
          linesCount={linesCount}
          integrationState={integrationState}
          limitUtahLabTransfersToOneProduct={limitUtahLabTransfersToOneProduct}
        />
      </AccordionPanel>
      <Row>
        <Col md={2}>
          <h4>{I18n.t('salesOrders.form.orderStatus')}</h4>
          <Field name='payment_status' component={ReactSelectInput} props={{
            label: I18n.t('purchaseOrders.form.status'),
            isRequired: true,
            readOnly: true,
            options: paymentStatuses,
            disabled: isLabOrderType,
          }}/>
        </Col>
        <Col md={2}>
          <h4>{I18n.t('salesOrders.form.transferType')}</h4>
          <Field name='transfer_type' component={ReactSelectInput} props={{
            label: I18n.t('salesOrders.form.transferType'),
            isRequired: isLeaf,
            options: transferTypes,
            disabled: isLabOrderType,
          }}/>
        </Col>
        <WillRender ifFalse={isLabOrderType}>
        <Col md={8}>
          <h4>{I18n.t('salesOrders.form.orderTotals')}</h4>
            <FormGroup>
              <Row>
                <Col xs={7} sm={9}>
                  <Row>
                    <Col md={3}>
                      <InternationalCurrencyInput
                        name='transfer_fee'
                        props={{
                          label: I18n.t('purchaseOrders.form.transferFee'),
                          placeholder:'',
                          disabled:false,
                          isRequired:false
                        }} />
                    </Col>
                    <Col md={3}>
                      <InternationalCurrencyInput
                        name='taxes'
                        props={{
                          label: I18n.t('purchaseOrders.form.tax'),
                          placeholder:'',
                          disabled:false,
                          isRequired:false
                        }} />
                    </Col>
                    <Col md={3}>
                      <InternationalDecimalInput name='discount_percent' props={{
                        label: I18n.t('purchaseOrders.form.partnerDiscount'),
                        leftAddon: <span>%</span>,
                      }}/>
                    </Col>
                    <Col md={3}>
                      <InternationalCurrencyInput
                        name='order_total'
                        props={{
                          label: I18n.t('purchaseOrders.form.orderTotal'),
                          placeholder:'',
                          disabled:true,
                          isRequired:false
                        }} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </FormGroup>
        </Col>
        </WillRender>
      </Row>
      <WillRender ifFalse={isLabOrderType}>
      <AccordionPanel title='purchaseOrders.form.paymentInfo' className='accordion-panel'>
        <FormGroup className='payment-information'>
          <Row>
            <Col xs={4} sm={4} md={3} lg={2}>
              <Field name='payment_terms' component={ReactSelectInput} props={{
                label: I18n.t('purchaseOrders.form.terms'),
                defaultOption: I18n.t('common.placeHolders.choose'),
                options: paymentTerms,
                textKey: 'name',
                valueKey: 'code'
              }}/>
            </Col>
          </Row>
          <Card className='calculate-total-wrapper' body>
            <Row>
              <Col md={2} offset={2}>
                <label className='float-right'>{I18n.t('purchaseOrders.form.calculateTotal')}</label>
              </Col>
              <Col md={3} >
                <InternationalCurrencyInput
                  name='order_total'
                  props={{
                    label: '',
                    placeholder:'',
                    disabled:true,
                    isRequired:false
                  }} />
              </Col>
            </Row>
            <FieldArray
              name='payments'
              component={Payments}
              paymentTypes={paymentTypes}
              registers={registers}
              users={users}
              change={change}
              blur={blur}
              errors={errors && errors.payments || []}
              oddMoneyAmount={oddMoney && oddMoney.amount || 0}
              changeDueRecord={changeDueRecord}
              order_total={order_total || 0}
              canAddPayments={canAddPayments}
            />
            <br/>
            <Row>
              <Col md={2} offset={2}>
                <label className='float-right'>{I18n.t('purchaseOrders.form.balance')}</label>
              </Col>
              <Col md={3} >
                <InternationalCurrencyInput
                  name='balance'
                  props={{
                    label: '',
                    placeholder:'',
                    disabled:true,
                    isRequired:false,
                    value:balance ? balance : 0
                  }} />
              </Col>
            </Row>
          </Card>
        </FormGroup>
      </AccordionPanel>
      </WillRender>
      <AccordionPanel title='purchaseOrders.form.notes' className='accordion-panel'>
        <FormGroup className='notes'>
          {existingNotes && existingNotes.length ?
            <ListGroup>
              {existingNotes.map((note, index) =>
                <ListGroupItem key={index}>
                  <b><InternationalDateStatic>{note.created_at}</InternationalDateStatic></b> {note.note}
                </ListGroupItem>)}
            </ListGroup>
            : null}
          <div>
            <Field name='new_note' component={TextAreaInput} props={{
              placeholder: I18n.t('purchaseOrders.form.notePlaceholder'),
              rows: 3,
              label: ''
            }}/>
          </div>
        </FormGroup>
      </AccordionPanel>
      {/*

      Temporary hide "connects" notes to partner section.
      When "connects" will be ready, delete accordion panel above and uncomment this one:

      <AccordionPanel title='purchaseOrders.form.notes' className='accordion-panel'>
        <FormGroup className='notes'>
          {existingNotes && existingNotes.length ?
            <ListGroup>
              {existingNotes.map((note, index) =>
                <ListGroupItem key={index}>
                  <b>{convertDbDateToFormInputDate(note.created_at)}</b> {note.note}
                </ListGroupItem>)}
            </ListGroup>
            : null}
          <Row>
            <Col xs={12}>
              <Field
                name='new_note'
                component={TextAreaInput}
                props={{
                  rows: 3,
                  label: I18n.t('purchaseOrders.form.myNote'),
                }}
              />
              <Field
                name='partner_note'
                component={TextAreaInput}
                props={{
                  rows: 3,
                  label: I18n.t('purchaseOrders.form.noteToMyPartner')
                }}
              />
              {(partnerNote) && (isLinkedSalesOrder && (!salesOrderConnectsStatus || salesOrderConnectsStatus === 'requested')) && (
                <div>
                  <br />
                  <Field
                    name='note_from_partner'
                    component={TextAreaInput}
                    props={{
                      value: partnerNote,
                      rows: 3,
                      disabled: true,
                      label: I18n.t('purchaseOrders.form.noteFromMyPartner')
                    }}
                  />
                </div>
              )}
            </Col>
          </Row>
        </FormGroup>
      </AccordionPanel>
      */}
      <div>
        <WillRender ifTrue={canCreateMatchingOrder}>
          <Row>
            <Col md={{offset: 8, span: 4}}>
              <CreateMatchingOrderOption
                canCreateMatchingOrder={canCreateMatchingOrder}
                sourceOrderType='salesOrder'
                matchingOrderWarnings={matchingOrderWarnings}
                connectsStatus={salesOrderConnectsStatus}
                transferStatus={transfer.status}
              />
            </Col>
          </Row>
        </WillRender>
      </div>
      {
        // This will have another condition to indicate it has been accepted at some point.
        renderFlags.isLinked && renderFlags.hasManageConnectsPermission && (!salesOrderConnectsStatus || salesOrderConnectsStatus === 'requested')
          ? <SubmitSection settings={connectsSubmitSectionSettings}/>
          : <SubmitSection settings={submitSectionSettings}/>
      }
    </form>
  );
};

OrderForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func,
  change: PropTypes.func,
  invalid: PropTypes.bool,
  getSellableItemMasterById: PropTypes.func.isRequired,
  formValues: PropTypes.object,
  initialValues: PropTypes.object,
  orderTypes: PropTypes.array.isRequired,
  partners: PropTypes.array.isRequired,
  partnerFacilities: PropTypes.array.isRequired,
  partnerFacilityOptions: PropTypes.array.isRequired,
  containers: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  itemMasters: PropTypes.array.isRequired,
  unusedItemMasters: PropTypes.array.isRequired,
  registers: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  paymentTerms: PropTypes.array.isRequired,
  paymentTypes: PropTypes.array.isRequired,
  partnerNote: PropTypes.string,
  form: PropTypes.string.isRequired,
  orderNumber: PropTypes.string,
  prepackWeights: PropTypes.object,
  selectedPrePackChildren: PropTypes.array.isRequired,
  inventoryList: PropTypes.array,
  existingNotes: PropTypes.array,
  showContainerInputs: PropTypes.bool,
  showStorageLocations: PropTypes.bool,
  partnerId: PropTypes.number,
  transferRecord: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ]).isRequired,
  onCancel: PropTypes.func,
  onOpenTransfer: PropTypes.func,
  transfer: PropTypes.object,
  errors: PropTypes.object,
  paymentStatus: PropTypes.string,
  isLeaf: PropTypes.bool,
  onChangeLocalState: PropTypes.func.isRequired,
  oddMoney: PropTypes.object,
  changeDueRecord: PropTypes.object,
  order_total: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  canAddPayments: PropTypes.bool,
  partnerSource: PropTypes.string,
  requireSalesOrder: PropTypes.bool,
  order_type: PropTypes.string,
  integrationState: PropTypes.object.isRequired,
  canCreateMatchingOrder: PropTypes.number,
  blur: PropTypes.func,
  salesOrderSaved: PropTypes.bool.isRequired,
  actions: PropTypes.object.isRequired,
  matchingOrderWarnings: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]).isRequired,
  transferLinesLimit: PropTypes.number,
  // isLinkedSalesOrder: PropTypes.bool.isRequired,
  salesOrderConnectsStatus: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  isReservationEnabled: PropTypes.bool,
  isMappingRequiredByPlatform: PropTypes.bool,
  hasMedicatedLines: PropTypes.bool,
  renderFlags: PropTypes.object.isRequired,
  addTouchedIM: PropTypes.func,
  selectedFacility: PropTypes.object,
  activeFacilityType: PropTypes.string,
  facilityTypes: PropTypes.array.isRequired,
  facilityDetailed: PropTypes.object,
  isCanada: PropTypes.bool.isRequired,
  showPartnerType: PropTypes.bool,
  touchedIM: PropTypes.object,
  metrcIsImported: PropTypes.bool,
  isMetrcTransfersEnabled: PropTypes.bool,
  balance: PropTypes.number.isRequired,
  linesCount: PropTypes.number,
  limitUtahLabTransfersToOneProduct: PropTypes.bool,
  isLeafPaClosedLoopFacility: PropTypes.bool,
  isActiveFacilityDispensary: PropTypes.bool,
  categories: PropTypes.array,
};

OrderForm.defaultProps = {
  showContainerInputs: true,
  showStorageLocations: true,
  existingNotes: [],
};

const WrappedComponent = reduxForm({
  validate,
  shouldValidate,
})(OrderForm);

const selector = formValueSelector(SALES_ORDER_FORM);

// NOTE: TGC-140 mapStateToProps inlined here. Spin up a story for this form component, same goal as TGC-140
const OuterComponent = connect(
  (state, props) => {
    const salesOrder = getSalesOrderForModifyPage(state);
    const formValues = getFormValues(SALES_ORDER_FORM)(state) || {};
    const linesCount = get(formValues, 'lines', []).length;
    const payments = selector(state, 'payments');
    const partnerId = selector(state, 'partner_id');
    const order_type = selector(state, 'order_type');
    const order_total = isNaN(parseFloat(selector(state, 'order_total'))) ? parseFloat(0.00) : parseFloat(selector(state, 'order_total'));
    const selectedFacility = props.partnerFacilities.find(f => f.id === selector(state, 'partner_facility_id'));
    const { isCanada, isOklahoma, isMetrc } = props.integrationState;
    const metrcIsImported = isMetrc && get(formValues, 'is_imported', false);
    const activeFacilityType = getFacilityType(state);

    let sumPayments = 0;
    if(payments && Array.isArray(payments)) {
      sumPayments = payments.reduce((acc, payment) => {
        const amount = parseFloat(payment.amount);
        acc += (isNaN(amount)) ? 0 : amount;
        return acc;
      }, 0);
    }
    const balance = order_total ? parseFloat(order_total - sumPayments - get(props, 'oddMoney', 0)) : parseFloat(0 - sumPayments - get(props, 'oddMoney', 0));

    const canAddPayments = (order_total > sumPayments) || metrcIsImported;

    const inputs = {
      formName: props.form,
      facilityIdField: 'partner_facility_id',
      partnerFacilities: props.partnerFacilities,
      activeFacilityType: activeFacilityType,
    };
    const facilityTypes = getValidFacilityTypesForSupplyChain(state, inputs);

    const showPartnerType =  isOklahoma || isCanada;

    if (facilityTypes && facilityTypes.length === 1) {
      props.initialValues.partner_facility_type = facilityTypes[0].value;
    }

    return {
      partnerId,
      formValues,
      linesCount,
      selectedFacility,
      partnerNote: get(salesOrder, 'partner_note[0].note', ''),
      itemMasters: order_type ? getSalesOrderItemMasters(state, {with_inactive: true}) : [],
      unusedItemMasters: getUnusedSalesOrderItemMasters(state),
      transfer: state.transfer,
      changeDueRecord: selector(state, 'oddMoney'),
      order_total: isNaN(parseFloat(selector(state, 'order_total'))) ? parseFloat(0.00) : parseFloat(selector(state, 'order_total')),
      canAddPayments,
      showPartnerType,
      facilityTypes,
      canCreateMatchingOrder: canCreateMatchingOrder(state),
      order_type,
      isCanada,
      metrcIsImported,
      balance: isNaN(balance) ? 0 : balance,
      isMetrcTransfersEnabled: isMetrcTransfersEnabled(state),
      partnerFacilityOptions: getPartnerFacilitiesForSalesOrder(state),
      partners: getPartnersForSalesOrder(state),
      errors: getFormSyncErrors(SALES_ORDER_FORM)(state),
      activeFacilityType: activeFacilityType,
      oddMoney: getOddMoneyFormValue(state),
      limitUtahLabTransfersToOneProduct: isFeatureEnabled(state)('feature_limit_utah_lab_transfers_to_one_product'),
      isLeafPaClosedLoopFacility: isFeatureEnabled(state)('feature_leaf_pa_configuration_pack') && isLeafPaConfigPackClosedLoopFacility(state),
      isActiveFacilityDispensary: isActiveFacilityDispensary(state)
    };
  }
)(WrappedComponent);

export default OuterComponent;
