import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {getItemReservations} from '../../../selectors/productionRunsSelectors';
import ItemProductionRunsComponent from './ItemProductionRunsComponent';

function mapStateToProps(state, ownProps) {
  return {
    productionRuns: getItemReservations(state, ownProps.item),
  };
}

const ItemProductionRuns = connect(mapStateToProps)(ItemProductionRunsComponent);

ItemProductionRuns.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

export default ItemProductionRuns;
