import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {goBack} from 'react-router-redux';
import {change} from 'redux-form';
import sortby from 'lodash.sortby';
import {Alert} from 'react-bootstrap';

import saveStringAsFile from '../../util/saveStringAsFile';
import * as dataNames from '../../constants/dataNames';
import {formatDate} from '../../util/dateHelpers';
import {getUnpaginatedData, postItem} from '../../actions/apiActions';
import {getQuickbooksAccountsTypes, getFacilitiesOptions} from '../../selectors/accountingSelectors';
import FormWrapper from '../common/form/FormWrapper';
import InProgressOverlay from '../common/InProgressOverlay';
import AccountingExportFormWrapper from './AccountingExportFormWrapper';

export class AccountingExportPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {saving: false, ready: false, quickbooksTransactionTypes:[]};
    this.redirect = this.redirect.bind(this);
    this.onAdjust = this.onAdjust.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount() {
    Promise.all([
      this.props.actions.getUnpaginatedData('/api/quickbooks_accounts', dataNames.quickbooksAccounts, {failed: 'orders.get.itemFail'}),
      this.props.actions.getUnpaginatedData('/api/quickbooks_accounts/types', dataNames.quickbooksAccountsTypes, {failed: 'orders.get.itemFail'}),
      this.props.actions.getUnpaginatedData('/api/quickbooks_accounts/trans_types', dataNames.quickbooksTransactionTypes, {failed: 'orders.get.itemFail'}, null, (data) => {
        const transactionTypes = Object.keys(data).map((e) => {
          const value = e;
          const text = data[e];
          return {text,value};
        });
        this.setState({quickbooksTransactionTypes:sortby(transactionTypes, 'text').filter(type => type.value !== 'all')});
      }),
      this.props.actions.getUnpaginatedData('/api/quickbooks_accounts/org_accounts', dataNames.quickbooksAccountsOrganizations, {failed: 'orders.get.itemFail'}),
    ]).then(() => {
      this.setState({ready: true});
    }).catch(() => this.setState({ready: true}));

  }

  redirect() {
    this.props.actions.goBack();
  }

  onAdjust(adjustments) {
    //
  }

  onSubmit(formData) {
    this.setState({saving: true});
    const payload = {
      date_start: formatDate(formData.exportStartDate),
      date_end: formatDate(formData.exportEndDate),
      export_types: formData.export_types.map(option => option.value),
      export_locations: formData.export_locations.map(option => option.value),
      export_format: formData.selectExportFormat,
      display_class: formData.display_class,
      display_vendor: formData.display_vendor,
      display_gross: formData.display_gross
    };
    this.props.actions.postItem(
      '/api/quickbooks_accounts/export',
      payload,
      dataNames.accountingExportPayload,
      {success: 'accounting.export.success', failed: 'accounting.export.failed'},
      {},
      result => {
        const fileExtension = payload.export_format === 'csv' ? 'csv' : 'iif';
        saveStringAsFile(result, `accounting-export.${fileExtension}`, 'text/plain;charset=utf-8');
        this.setState({saving: false});
      }
    ).catch(() => this.setState({saving: false}));
  }

  render() {
    const {facilityOptions} = this.props;
    const {saving, ready, quickbooksTransactionTypes} = this.state;
    const overlayMessage = I18n.t(`reconciliation.${saving ? 'save' : 'load'}`);
    const initialValues = {
      export_locations: [],
      export_types: [],
    };
    const exportFormats = [
      { value: 'csv', text: 'CSV' },
      { value: 'iif', text: 'IIF' }
    ];

    return (
      <FormWrapper title='accounting.export.header' goBack={this.redirect}>
        <InProgressOverlay isActive={saving || !ready} message={overlayMessage}/>
        <Alert variant='warning'>
          Starting 2/14/22, Exports will only be available for previous 90 days. Please use Analytics module for Exports
          older than 90 days.
        </Alert>
        <AccountingExportFormWrapper
          onRedirect={this.redirect}
          onSubmit={this.onSubmit}
          onAdjust={this.onAdjust}
          exportFormats={exportFormats}
          quickbooksAccountsTypes={quickbooksTransactionTypes}
          facilityOptions={facilityOptions}
          initialValues={initialValues}/>
      </FormWrapper>
    );
  }
}

AccountingExportPage.propTypes = {
  actions: PropTypes.shape({
    getUnpaginatedData: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  initialValues: PropTypes.object,
  exportFormats: PropTypes.array,
  facilityOptions: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    quickbooksAccountsTypes:getQuickbooksAccountsTypes(state),
    facilityOptions:getFacilitiesOptions(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {goBack, getUnpaginatedData, postItem, change};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountingExportPage);
