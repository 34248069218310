import {createSelector} from 'reselect';
import get from 'lodash.get';
import {openForms} from '../../constants/dataNames';


export const getOpenFormPathMappings = (state) => state[openForms];

/**
 * Get the current browser path from the redux router state
 *
 * @param state
 * @returns string
 */
export const getCurrentPathFromState = (state) =>
  get(state, 'routing.locationBeforeTransitions.pathname');

/**
 * For the current path, loops through open forms and returns the first form it finds
 * that should trigger a warning
 *
 * @type {Reselect.Selector<unknown, unknown>}
 */
export const getFormWarning = createSelector(
  [getCurrentPathFromState, getOpenFormPathMappings],
  (currentPath, openFormMappings) => {

    if (!openFormMappings[currentPath]) return null;

    const openForms = openFormMappings[currentPath];

    for (const formName in openForms.forms) {
      if (!openForms.forms.hasOwnProperty(formName)) continue;
      const openForm = openForms.forms[formName];

      if (openForm.warn && !openForm.dismissed && !openForm.submitting) {
        return openForm;
      }
    }

    return null;
  });
