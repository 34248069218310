/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Row, Col } from 'react-bootstrap';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import NumericInput from '../../../common/form/NumericInput';
import InternationalDecimalInput from '../../../common/form/InternationalDecimalInput';
import TextInput from '../../../common/form/TextInput';
import InternationalDateTimePickerInput from '../../../common/form/InternationalDateTimePickerInput';
import MultiSelectInput from '../../../common/form/MultiselectInput';
import InternationalCurrencyInput from '../../../common/form/InternationalCurrencyInput';

const amountTypes = {
  dollar: 'value',
  percent: 'percent'
};

const amountOptions = [
  {text: 'Currency Amount', value: '0', type: amountTypes.dollar},
  {text: 'Percentage Discount', value: '1', type: amountTypes.percent}
];

const appliesToOptions = [
  {text: 'A Single Line Item', value: 'line'},
  {text: 'The Subtotal', value: 'order'},
];

const typeOptions = [
  {text: 'Retailer Standard', value: 'retailer'},
  {text: 'Manufacturer Standard', value: 'manufacturer'}
];

const validDaysOptions = [
  {text: 'Sunday', value: 0},
  {text: 'Monday', value: 1},
  {text: 'Tuesday', value: 2},
  {text: 'Wednesday', value: 3},
  {text: 'Thursday', value: 4},
  {text: 'Friday', value: 5},
  {text: 'Saturday', value: 6}
];

const setMax = (discountType) => {
  if (discountType === amountTypes.percent) {
    return 100;
  } else {
    return null;
  }
};

const onDiscountApplicationChange = (value, change) => {
  change('coupon_applies_to', value);

  if (value !== 'line') {
    change('applicable_item_count', 0);
  }
};

const RetailCouponCol = ({discountType, couponAppliesTo, couponType, facilities, change}) => {
  return(
    <div>
      <h3>{I18n.t('retail.createCoupon.retailCoupon')}</h3>
      <Row>
        <Col xs={12} sm={11} md={10}>
          <Field
            name='coupon_type'
            component={ReactSelectInput}
            props={{
              label: I18n.t('retail.createCoupon.type'),
              options: typeOptions,
              valueKey: 'value',
              textKey: 'text',
              isRequired: true,
              helpTextBelow: couponType ? I18n.t(`retail.createCoupon.typeHelp.${couponType}`) : ''
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={11} md={10}>
          <Field
            name='name'
            component={TextInput}
            props={{
              label: I18n.t('retail.createCoupon.name'),
              isRequired: true
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={11} md={10}>
          <Field
            name='facility_ids'
            component={MultiSelectInput}
            props={{
              label: I18n.t('retail.createCoupon.facilities'),
              options: facilities,
              valueKey: 'id',
              textKey: 'name',
              allOption: true,
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={11} md={5}>
          <InternationalDateTimePickerInput
            name='date_available_from'
            props={{
              label: I18n.t('retail.createCoupon.effectiveDate'),
              isRequired: true
            }}
          />
        </Col>
        <Col xs={12} sm={11} md={5}>
          <InternationalDateTimePickerInput
            name='date_available_to'
            props={{
              label: I18n.t('retail.createCoupon.date'),
              isRequired: true
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={11} md={5}>
          {discountType === amountTypes.dollar ?
            <InternationalCurrencyInput
              name='discount_amount'
              fractionPartSize={2}
              props={{
                label: I18n.t('retail.createCoupon.amount'),
                isRequired: true,
                max: setMax(discountType)
              }}
            /> :
            <InternationalDecimalInput
              name='discount_amount'
              fractionPartSize={2}
              props={{
                label: I18n.t('retail.createCoupon.amount'),
                isRequired: true,
                leftAddon: I18n.t('common.units.percent'),
                max: setMax(discountType)
              }}
            />
          }
        </Col>
        <Col xs={12} sm={11} md={5} className='hide-asterisk'>
          <Field
            name='discount_type'
            component={ReactSelectInput}
            props={{
              label: I18n.t('retail.createCoupon.discountType'),
              options: amountOptions,
              valueKey: 'type',
              textKey: 'text',
              isRequired: true,
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6} sm={8} md={6}>
          <Field
            name='coupon_applies_to'
            component={ReactSelectInput}
            props={{
              label: I18n.t('retail.createCoupon.discountApplication'),
              options: appliesToOptions,
              valueKey: 'value',
              textKey: 'text',
              isRequired: true,
              onChange: (value) => onDiscountApplicationChange(value, change),
            }}
          />
        </Col>
        {couponAppliesTo === 'line' ? <Col xs={8} sm={8} md={4}>
          <Field
            name='max_application_qty'
            component={NumericInput}
            props={{
              label: I18n.t('retail.createCoupon.applicableItemCount')
            }}
          />
        </Col> : null}
      </Row>
      <Row>
        <Col xs={8} sm={8} md={5} lg={6}>
          <Field
            label={I18n.t('retail.createCoupon.valid')}
            name='days_available'
            component={MultiSelectInput}
            props={{
              options: validDaysOptions,
              valueKey: 'value',
              textKey: 'text'
            }}
          />
        </Col>
        <Col xs={8} sm={8} md={3} lg={3} >
          <InternationalDateTimePickerInput
            name='time_available_from'
            props={{
              dateFormat: false,
              timeFormat: true,
              enableTodayButton: false,
              label: I18n.t('retail.createCoupon.between')
            }}
          />
        </Col>
        <Col xs={8} sm={8} md={3} lg={3} >
          <InternationalDateTimePickerInput
            name='time_available_to'
            props={{
              dateFormat: false,
              timeFormat: true,
              enableTodayButton: false,
              label: I18n.t('retail.createCoupon.and')
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

RetailCouponCol.propTypes = {
  change: PropTypes.func.isRequired,
  facilities: PropTypes.array.isRequired,
  discountType: PropTypes.string,
  couponAppliesTo: PropTypes.string,
  couponType: PropTypes.string
};

export default RetailCouponCol;
