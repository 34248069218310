import {createSelector} from 'reselect';
import map from 'lodash.map';
import * as itemNames from '../constants/itemNames';

const getMetrcStates = state => state[itemNames.metrcStates];
export const getStateIntegrators = state => state[itemNames.stateIntegrators];

export const getMetrcStateOptions = createSelector(
  getMetrcStates, metrcStates => Object.keys(metrcStates).map(
    value => ({
      text: metrcStates[value],
      value
    })
  )
);

export const getStateIntegratorOptions = createSelector(
  [getStateIntegrators],
  stateIntegrators => map(stateIntegrators, (text, value) => ({text, value}))
);
