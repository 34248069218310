import {I18n} from 'react-redux-i18n';
import {requiredFieldValidation} from '../../common/form/redux-form/validations';

const validate = (values) => {
  const errors = {
    register: requiredFieldValidation(values.register),
    file_name: requiredFieldValidation(values.file_name),
    startFromRow: requiredFieldValidation(values.startFromRow),
  };

  if (values.startFromRow) {
    if (!Number.isInteger(Number(values.startFromRow))) {
      errors.startFromRow = I18n.t('common.mustBeWholeNumber');
    } else if (values.startFromRow < 2) {
      errors.startFromRow = I18n.t('common.errorLessThan', {number: 2});
    }
  }


  return errors;
};

export default validate;
