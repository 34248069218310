import {LOCATION_CHANGE} from 'react-router-redux';
import map from 'lodash.map';

import * as types from '../constants/actionTypes';
import * as messageTypes from '../constants/messageTypes';
import initialState from '../constants/initialState';
import {guid} from '../actions/helpers/guid';

export default function loginReducer (state = initialState.messages, action){
  const {id, messageType, message, localization} = action;
  switch (action.type) {
  case types.HEALTH_CHECK_FAILED:
    return [...state, {id: guid(), messageType: messageTypes.error, message}];
  case LOCATION_CHANGE:
    return state.filter(message => message.messageType !== messageTypes.error);
  case types.CLEAR_MESSAGE:
    return state.filter(message => message.id !== id);
  case types.ADD_MESSAGE:
    return [...state, {id: guid(), messageType, message, localization}];
  case types.SET_FACILITY_FAILED:
    return [
      ...state,
      ...action.error.messages.map(message => ({id: guid(), messageType: messageTypes.error, message}))
    ];
  case types.LOCATION_CHANGE_WITHOUT_FACILITY:
    return [...state, {id: guid(), messageType: messageTypes.error, message: 'error.chooseFacility'}];
  case types.HARVEST_PLANTS_MULTIPLE_STRAINS:
    return [...state, {id: guid(), messageType: messageTypes.error, message: 'error.harvestMultipleStrains'}];
  case types.GET_DATA_BATCH_FAILED:  // Added 5/1/2018
  case types.DELETE_DATA_FAILED:
  case types.GET_DATA_FAILED:
  case types.ADD_DATA_FAILED:
  case types.EDIT_DATA_FAILED:
  case types.GET_ITEM_FAILED:
  case types.EDIT_ITEM_FAILED:
  case types.ADD_ITEM_FAILED:
  case types.REMOVE_ITEM_FAILED:
  case types.GET_FILE_FAILED:
    if (action.data && action.data.messages) {
      return [
        ...state,
        {id: guid(), messageType: messageTypes.error, message},
        ...action.data.messages.map(message => ({id: guid(), messageType: messageTypes.error, message}))
      ];
    } else if (action.data && action.data.errors) {
      if (action.data.errors.VALIDATION) {
        const messages = Object.keys(action.data.errors.VALIDATION).reduce((messages, field) => {
          return [
            ...messages,
            ...map(action.data.errors.VALIDATION[field], message => ({
              id: guid(),
              messageType: messageTypes.error,
              message,
              localization: true,
            }))
          ];
        }, []);
        return [...state, ...messages];
      } else if (action.data.errors.failed_items && action.data.errors.failed_items.length) { // good
        const messages = action.data.errors.failed_items.reduce( // good
          (acc, row) => acc.concat(Object.keys(row.errors).reduce(
            (messages, field) => messages.concat(row.errors[field].map(
              message => {
                const isLocalized = message.toString().indexOf(' ') !== -1;
                return {id: guid(), messageType: messageTypes.error, message, localization: isLocalized};
              }
            )),
            []
          )),
          []
        );
        return [...state, ...messages];
      }
      return [
        ...state,
        ...map(action.data.errors, message => ({id: guid(), messageType: messageTypes.error, message, localization: true}))
      ];
    } else if (message) {
      const isLocalized = message.toString().indexOf(' ') !== -1; // localized = already translated
      return [...state, {id: guid(), messageType: messageTypes.error, message, localization: isLocalized}];
    }
    return state;
  case types.GET_ITEM_SUCCESS: // Added 5/1/2018
  case types.GET_DATA_BATCH_SUCCESS: // Added 5/1/2018
  case types.GET_DATA_SUCCESS:  // Added 5/1/2018
  case types.ADD_DATA_SUCCESS:  // Added 5/1/2018
  case types.EDIT_DATA_SUCCESS:
  case types.DELETE_DATA_SUCCESS:
  case types.ADD_ITEM_SUCCESS:
  case types.EDIT_ITEM_SUCCESS:
  case types.REMOVE_ITEM_SUCCESS:
  case types.ADD_DESTRUCTION_SUCCESS:
  case types.GET_FILE_SUCCESS:
    return message ? [...state, {id: guid(), messageType: messageTypes.success, message}] : state;
  default:
    return state;
  }
}
