import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field } from 'redux-form';
import { push } from 'react-router-redux';
import { Col } from 'react-bootstrap';
import ReactSelectInput from '../../../common/form/ReactSelectInput';

const ConnectsPartnerData  = (props) => {

  // const onNavigate = (destination) => {
  //   const partnerId = props.partner.id.split(':').shift();
  //   const map = {
  //     inventory: `/partners/${partnerId}/inventory`,
  //     map_products: `/partners/${partnerId}/inventory`,
  //     modify: `/partners/modify/${partnerId}`,
  //   };
  //   props.actions.push(map[destination]);
  // };

  const sortLicensesByTimestamp = (licenses) => {
    if(!Array.isArray(licenses)) return [];
    return licenses.map((license) => {
      license.timestamp = new Date(license.expires_on).getTime();
      return license;
    }).sort((a,b) => b.timestamp - a.timestamp);
  };

  const getMostRecentLicenseByType = (data, type) => {
    const licenses = sortLicensesByTimestamp(data.licenses);
    const license = licenses.find((license) => license.type === type);
    return license ? license.name : 'N/A';
  };

  // Replace this with passed in values that can also indicate columns
  const dataRows = [
    {key: (data) => getMostRecentLicenseByType(data, 'state') , text: 'State License Number'},
    {key: (data) => getMostRecentLicenseByType(data, 'tax'), text: 'Sales Tax ID'},
    {key: (data) => getMostRecentLicenseByType(data, 'wholesale'), text: 'Wholesale Tax ID'},
    {key: 'address', text: 'address'},
    {key: 'contact_1', text: 'Contact:'},
    {key: 'contact_2', text: 'Contact:'},
  ];

  const partnerId = parseInt(props.partnerId);

  const nonMappingOptions = [
    {id: -1, facility_name: 'Do Not Connect This Facility'},
    {id: 0, facility_name: 'Create One'},
  ];

  return (<div>
    {
      props.fields.map((name, index) => {

        const mapPartnerOptions = [].concat(nonMappingOptions, props.partnerFacilities
          .filter((facility) => {
            if(props.partnerId !== facility.partner_id && [0,-1].indexOf(facility.id) === -1) return false;
            return props.facilities[index].map_to_partner_facility_id === facility.id
              || props.mappedPartnerFacilityIds.indexOf(facility.id) === -1;
          }).sort((a,b) => {
            if(a.partner_id === b.partner_id){
              return a.facility_name.localeCompare(b.facility_name);
            }
            return a.partner_id - b.partner_id;
          }));
        return (<div key={index}>
          <Col xs={12} md={9}>
          <strong>{props.facilities[index].name}</strong>
            <table className='table'>
            <tbody>
            {
              dataRows.map((row, rowIndex) => {
                return (<tr key={rowIndex}>
                  <td style={{padding: '0', width: '200px'}}>
                    {row.text}:
                  </td>
                  <td style={{padding: '0'}}>
                    {typeof row.key === 'string' ? props.facilities[index][row.key] : row.key(props.facilities[index])}
                  </td>
                </tr>);
              })
            }
            </tbody>
          </table>

          </Col>
          <Col xs={12} md={3}>
            <Field
              name={`${name}.existing_facility_id`}
              component={ReactSelectInput}
              props={{
                label: 'Select Existing Partner Facility, Create One, or Skip',
                options: mapPartnerOptions,
                textKey: 'facility_name',
                valueKey: 'id',
                disabledx: !partnerId || isNaN(partnerId),
                placeholder: 'Select An Option...'
              }}
            />
          </Col>
          <div style={{clear: 'both'}} />
        </div>);
      })
    }
  </div>);
};

ConnectsPartnerData.propTypes = {
  fields: PropTypes.object,
  actions: PropTypes.object,
  partnerId: PropTypes.number.isRequired,
  partner: PropTypes.object.isRequired,
  partnerFacilities: PropTypes.array.isRequired,
  facilities: PropTypes.array.isRequired,
  mappedPartnerFacilityIds: PropTypes.array.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {};
}

function mapDispatchToProps(dispatch) {
  const actions = bindActionCreators({push}, dispatch);
  return {
    actions
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectsPartnerData);

