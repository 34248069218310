/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {goBack} from 'react-router-redux';
import { bindActionCreators } from 'redux';

import * as itemNames from '../../../constants/itemNames';
import * as dataNames from '../../../constants/dataNames';
import * as facilityTypes from '../../../constants/facilityTypes';
import {getUnpaginatedData, getItem, putItem} from '../../../actions/apiActions';

import PageTitle from '../../common/PageTitle';
import PatientSettingsForm from './PatientSettingsForm'; //eslint-disable-line

export class PatientCompliancePage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount() {
    this.props.actions.getItem('/api/customers/compliance_settings', itemNames.patientSettings);
    this.props.actions.getUnpaginatedData('/api/facilities/details', dataNames.detailedFacilities, {failed: 'facilities.get.failed'}, {type: facilityTypes.grow});
  }

  onSubmit(data){
    const sendData = {
      settings: [{
        setting_key: 'partner_retail_patient_compliance_settings',
        scope: 'facility',
        scope_id: this.props.currentFacility.id,
        value: {
          max_patients_warn: data.max_patients_warn || false,
          max_patients_block: data.max_patients_block || false,
          max_patient_plants_prop_warn: data.max_patient_plants_prop_warn || false,
          max_patient_plants_prop_block: data.max_patient_plants_prop_block || false,
          max_patient_plants_veg_warn: data.max_patient_plants_veg_warn || false,
          max_patient_plants_veg_block: data.max_patient_plants_veg_block || false,
          max_patient_plants_flower_warn: data.max_patient_plants_flower_warn || false,
          max_patient_plants_flower_block: data.max_patient_plants_flower_block || false,
          max_patient_plants_total_warn: data.max_patient_plants_total_warn || false,
          max_patient_plants_total_block: data.max_patient_plants_total_block || false,
          facility_coupling: data.facility_coupling.length ? data.facility_coupling.map(i => i.id || i) : false,
          max_inventory_weight_on_hands: data.max_inventory_weight_on_hands || false,
          goes_by_enabled: data.goes_by_enabled || false,
        }
      }]
    };
    this.props.actions.putItem(
      '/api/customers/compliance_settings',
      sendData,
      itemNames.patientSettings,
      {
        success: 'retail.patientSettings.success',
        failed: 'retail.patientSettings.fail'
      },
      null,
      this.props.actions.goBack
    );
  }

  render(){
    const {patientsSettings:{partner_retail_patient_compliance_settings}, facilities} = this.props;
    const initialValues = partner_retail_patient_compliance_settings ? {
      ...partner_retail_patient_compliance_settings.value,
      facility_coupling: partner_retail_patient_compliance_settings.value && partner_retail_patient_compliance_settings.value.facility_coupling ?
        partner_retail_patient_compliance_settings.value.facility_coupling.map(facility_coupled => {
          const facility_whole = facilities.find(facility => facility.id === facility_coupled);
          return facility_whole ? {
            name: facility_whole.name,
            id: facility_whole.id
          } : [];
        }) : []
    } : null;

    return (
      <div className='patient-compliance-page'>
        <PageTitle primaryText={I18n.t('retail.title')} secondaryText={I18n.t('retail.patientSettings.title')} />
        <PatientSettingsForm
          facilities={facilities}
          onSubmit = {this.onSubmit}
          initialValues={initialValues}
        />
      </div>
    );
  }
}

PatientCompliancePage.propTypes = {
  actions: PropTypes.shape({
    getUnpaginatedData: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    putItem: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired
  }),
  patientsSettings: PropTypes.object,
  facilities: PropTypes.array,
  currentFacility: PropTypes.shape({
    id: PropTypes.number
  })
};

function mapStateToProps(state, ownProps){
  return {
    patientsSettings: state[itemNames.patientSettings],
    facilities: state[dataNames.detailedFacilities],
    currentFacility: state.facility
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {getUnpaginatedData, getItem, putItem, goBack};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (PatientCompliancePage);
