import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack, push, replace} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import {Button, ButtonToolbar} from 'react-bootstrap';
import omit from 'lodash.omit';
import get from 'lodash.get';
import {set} from 'lodash';
import map from 'lodash.map';

import {unsetData} from '../../../actions/dataActions';
import {postItem} from '../../../actions/apiActions';
import {getItemMastersForModify, prepareDataForPreview} from '../../../selectors/forms/massModifyFormSelector';
import PreviewForm from './PreviewForm';
import FormWrapper from '../../common/form/FormWrapper';
import * as dataNames from '../../../constants/dataNames';
import InProgressOverlay from '../../common/InProgressOverlay';
import PreviewProductList from './PreviewProductList';

class PreviewPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    const columns = [
      {
        name: 'products.massModify.productListing.name',
        dataId:'name',
        dataSort: true,
        hidden: false,
        width: '250px',
      },
      {
        name: 'products.massModify.productListing.productNumber',
        dataId:'item_number',
        dataSort: true,
        hidden: false,
        width: '150px',
      },
      {
        name: 'products.massModify.productListing.category',
        dataId:'category_name',
        dataSort: true,
        hidden: false,
        width: '200px',
      },
      {
        name: 'products.massModify.productListing.subcategory',
        dataId:'subcategory_name',
        dataSort: true,
        hidden: false,
        width: '200px',
      },
      {
        name: 'products.massModify.productListing.uom',
        dataId:'default_uom',
        dataSort: true,
        hidden: false,
        width: '200px',
      },
    ];

    this.state = {
      showLoading: true,
      columns,
    };

    this.handleEdit = this.handleEdit.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentWillMount() {
    this.setState({showLoading: false});
  }

  handleEdit(data) {
    this.props.actions.replace(`/products/mass_modify#${data.anchor}`);
  }

  handleSave() {
    this.setState({showLoading: true});
    const formValues = this.props.initialData.payload;
    const payload = {
      ...formValues,
      tags: map(get(formValues, 'tags', []), tag => typeof tag === 'string' ? tag : tag.tag_name),
    };

    // removed pricing list from payload that have not been edited
    set(payload, 'pricing_details.price_lists', get(payload, 'pricing_details.price_lists', []).reduce((a,b) => {
      const priceListsKeys = Object.keys(omit(b, [
        'consumer_type',
        'pricing_type',
        'sale_type',
        'is_default_for_org',
      ]));

      const check = priceListsKeys.some((k,v) => {
        return (b[k] !== undefined && b[k] !== null && b[k] !== '0.00' && !Array.isArray(b[k])) || (Array.isArray(b[k]) && b[k].length > 0);
      });

      if (check) {
        return a.concat([b]);
      }

      return a;
    }, []));

    // prevent strain removal (MJP-13187)
    if(!payload.strain_id){ delete payload.strain_id; }


    this.props.actions.postItem('/api/item_masters/mass_update', payload, 'noOp', {success: 'products.modify.success', failed: 'products.modify.failed'})
      .then(() => {
        this.props.actions.unsetData(dataNames.massModifyFormData);
        this.setState({showLoading: false});
        this.props.actions.push('/products');
      })
      .catch(() => {this.setState({showLoading: false});});
  }

  render() {
    const {products, formData} = this.props;
    const {columns} = this.state;
    return (
      <FormWrapper className='preview-form' title={'products.massModify.previewTitle'} goBack={this.props.actions.goBack}>
        <InProgressOverlay
          isActive={this.state.showLoading}
          message={this.state.loadingMessage}
          showOk={false}
          showLoader={true}
          translate={true} />
        <PreviewProductList
          ref = {this.ref}
          products = {products}
          columns = {columns}
        />
        {products.length ? <div>
          <PreviewForm
            formData={formData}
            handleEdit={this.handleEdit}
          />
          <div className='form-actions'>
            <ButtonToolbar className='float-right'>
              <Button type='button' onClick={this.handleSave} variant='primary'>{I18n.t('products.massModify.save')}</Button>
            </ButtonToolbar>
          </div>
        </div> : null}
      </FormWrapper>
    );
  }
}

PreviewPage.propTypes = {
  formData: PropTypes.array.isRequired,
  initialData: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  return {
    formData: prepareDataForPreview(state),
    products: getItemMastersForModify(state),
    initialData: state[dataNames.massModifyFormData],
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, {goBack, unsetData, postItem, push, replace});
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewPage);
