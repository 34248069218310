import { formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';
import get from 'lodash.get';
import map from 'lodash.map';
import { getBatchName } from '../batchesSelectors';
import { getFilteredFeedingSchedules } from '../feedingSchedulesSelectors';
import { getPhenotypesByStrain, getTypeOfCannabisByStrain } from '../strainsSelectors';
import { getLSectionsByFeedingSchedule } from '../locationsSelectors';
import { createPlantFromInventory, getMotherPlantById } from '../plantsSelectors';
import { CREATE_PLANT_FORM } from '../../constants/forms';
import { getApplicablePlatformSubcategoryIds } from '../integration/biotrackCategoriesSelectors';
import { getIntegrationState } from '../integration/integrationSelectors';
import {
  BT_CLONE,
  BT_MATURE_PLANT,
  BT_PLANT_TISSUE,
  BT_SEED
} from '../../constants/integration/biotrack/biotrackInventoryTypes';
import { getItemMasters } from '../itemMastersSelectors';
import { getInventoryItems } from '../processingSelectors';
import { evaluatePredicate } from '../../util/callbackHelpers';
import { convertFromBase } from '../../util/uomHelpers';
import { getSubcategories } from '../subcategoriesSelectors';
import {
  isSubCategoryClones,
  isSubCategoryMature,
  isSubCategorySeeds,
  isSubCategoryTissueCulture,
  isSubCategoryTissue
} from '../categorySelectors';

const selector = formValueSelector(CREATE_PLANT_FORM);

const getStrainId = (state) => selector(state, 'strain_id');
const getStartingType = (state) => selector(state, 'starting_type');
const typeMap = {
  Clone: ['CLONE', 'WHOLESALE_CLONES'],
  Seed: ['SEED', 'WHOLESALE_SEEDS'],
  Tissue: ['TISSUE_CULTURE', 'WHOLESALE_TISSUE_CULTURES'],
  Mature: ['MATURE_PLANT'],
  Plant: ['PLANT_TISSUE', 'WHOLESALE_PLANT_TISSUES']
};

const typeMapBiotrack = {
  Clone: [BT_CLONE],
  Seed: [BT_SEED],
  Tissue: [BT_PLANT_TISSUE],
  Mature: [BT_MATURE_PLANT]
};

export const getFormBatchName = (state) =>
  getBatchName(state, selector(state, 'strain_id', 'phenotype_id', 'planting_date'));

export const getFormPhenotypes = (state) => {
  const strain_id = selector(state, 'strain_id');
  const result = [];
  const phenotypes = getPhenotypesByStrain(state, { strain_id }) || [];

  map(phenotypes, (phenotype) => {
    if (phenotype.name != '') {
      result.push(phenotype);
    }
  });

  return result;
};

export const getFormFilteredFeedingSchedules = (state) => {
  const stage = state.stages ? state.stages.find((stage) => stage.code === 'prop') : undefined;
  const filters = {};
  filters.stage_id = stage ? stage.id : 2;
  const location_id = selector(state, 'starting_section_id');
  if (location_id) {
    filters.location_id = location_id;
  }
  return getFilteredFeedingSchedules(state, { filters }) || [];
};

export const getFormFilteredLocations = (state) => {
  const feeding_schedule_id = selector(state, 'starting_feeding_schedule_id');
  return getLSectionsByFeedingSchedule(state, { feeding_schedule_id }) || [];
};

export const getFormMatriarchName = (state) => {
  const mother_plant_id = selector(state, 'starting_mother_plant_id');
  const motherPlant = getMotherPlantById(state, { mother_plant_id });
  if (motherPlant) {
    return motherPlant.matriarch_plant_name ? motherPlant.matriarch_plant_name : motherPlant.plant_id;
  } else {
    return '';
  }
};

const getFormMotherPlants = (state) => {
  return state.motherPlants;
};

export const getFilteredMotherPlants = createSelector(
  [getIntegrationState, getFormMotherPlants],
  ({ isMetrc, isWaLeaf }, motherPlants) => {
    if (isMetrc && motherPlants && motherPlants.length) {
      return motherPlants.filter(
        (motherPlant) => motherPlant.is_mother && motherPlant.state_integration_tracking_id && (motherPlant.code === 'veg' || motherPlant.code === 'flower')
      );
    } else if (isWaLeaf && motherPlants && motherPlants.length) {
      return motherPlants.filter(
        (motherPlant) => motherPlant.is_mother && (motherPlant.code === 'veg' || motherPlant.code === 'flower')
      );
    } else {
      return motherPlants.filter(
        (motherPlant) => motherPlant.is_mother
      );
    }
  }
);

const getBTSourceSubcategoryIds = getApplicablePlatformSubcategoryIds([
  BT_CLONE,
  BT_SEED,
  BT_PLANT_TISSUE,
  BT_MATURE_PLANT
]);

export const getSubcategoryIds = createSelector(
  [getIntegrationState, getBTSourceSubcategoryIds, createPlantFromInventory, getSubcategories],
  ({ isBiotrack, isWaLeaf }, btSourceSubcategoryIds, createPlantFromInventory, subCategories) => {
    if (isBiotrack) {
      return btSourceSubcategoryIds;
    } else if (isWaLeaf) {
      return subCategories
        .filter(
          (subcategory) =>
            isSubCategorySeeds(subcategory) ||
            isSubCategoryTissueCulture(subcategory) ||
            isSubCategoryTissue(subcategory) ||
            isSubCategoryClones(subcategory)
        )
        .map((subcategory) => subcategory.id);
    } else if (createPlantFromInventory) {
      return subCategories
        .filter(
          (subcategory) =>
            isSubCategorySeeds(subcategory) ||
            isSubCategoryMature(subcategory) ||
            isSubCategoryTissueCulture(subcategory) ||
            isSubCategoryClones(subcategory)
        )
        .map((subcategory) => subcategory.id);
    }
    return [];
  }
);

const getStartingTypes = (typeMap) =>
  createSelector(
    [getStartingType],
    (type) => typeMap[type] || []
  );
const getApplicableSubcategoryIds = createSelector(
  [getSubcategories, getStartingTypes(typeMap)],
  (subCategories, inventoryTypes) => {
    return inventoryTypes.map((type) => {
      const subCategory = subCategories.find((c) => c.subcategory_code === type);
      return subCategory && subCategory.id;
    });
  }
);
const getApplicableSubcategoryIdsBiotrack = createSelector(
  getStartingTypes(typeMapBiotrack),
  (inventoryTypes) => inventoryTypes
);

const filteredInventoryItems = createSelector(
  [getInventoryItems, getIntegrationState, getApplicableSubcategoryIdsBiotrack],
  (items, { isBiotrack }, inventoryTypes) =>
    isBiotrack ? items.filter((item) => inventoryTypes.indexOf(parseInt(item.integration_type, 10)) !== -1) : items
);

const getApplicableItemMasters = createSelector(
  [getItemMasters, getStrainId, getApplicableSubcategoryIds, getIntegrationState],
  (itemMasters, strainId, subcategoryIds, { isBiotrack }) => {
    const subcategoriesFilter = (subcategory_id) => (isBiotrack ? 1 : subcategoryIds.indexOf(subcategory_id));
    return itemMasters.filter((im) => im.strain_id === strainId && subcategoriesFilter(im.subcategory_id) > -1);
  }
);

const getStartingQuantity = (state) => get(state, 'form.createPlantForm.values.starting_qty', 0);
const getSelectedItemId = (state) => get(state, 'form.createPlantForm.values.source_item_id', null);

export const getApplicableItems = createSelector(
  [filteredInventoryItems, getApplicableItemMasters, getStartingQuantity, getSelectedItemId],
  (items, itemMasters, startingQty, selectedItemId) =>
    items
      .filter((item) => itemMasters.some((itemMaster) => itemMaster.id === item.item_master_id))
      .map((item) => {
        const remainingQtyCalculated =
          selectedItemId === item.id ? parseInt(item.qty) - parseInt(startingQty) : item.qty;
        const remainingQty = remainingQtyCalculated > 0 ? remainingQtyCalculated : 0;
        const reservedQty = item.uom_display_reserved ? convertFromBase(item.qty_base_reserved, item.uom_display_reserved) : 0;
        const itemMaster = itemMasters.find(itemMaster => itemMaster.id === item.item_master_id);
        return {
          ...item,
          display_name: `${item.lot_number} - ${item.package_code} - ${itemMaster.name} - ${remainingQty}(${reservedQty}) ${item.uom}`
        };
      })
);

export const getPlantTypes = createSelector(
  [getIntegrationState],
  (integrationState) => {
    const plantTypes = [
      { text: 'Clone', value: 'Clone' },
      { text: 'Seedling', value: 'Seed' },
      { text: 'Tissue Culture', value: 'Tissue', predicate: ({ isCaMetrc }) => !isCaMetrc },
      { text: 'Plant Tissue', value: 'Plant', predicate: ({ isWaLeaf }) => isWaLeaf },
      { text: 'Mature Plant', value: 'Mature', predicate: ({ isBiotrack, isNormal }) => isBiotrack || isNormal }
    ];

    return plantTypes.filter((type) => evaluatePredicate(type.predicate || true, integrationState));
  }
);

export const getPlantPropagationTypes = createSelector(
  [getIntegrationState],
  (integrationState) => {
    const plantTypes = [
      { text: 'Clone', value: 'Clone' },
      { text: 'Seedling', value: 'Seed' },
      { text: 'Tissue Culture', value: 'Tissue' },
      { text: 'Mature Plant', value: 'Mature', predicate: ({ isBiotrack }) => isBiotrack }
    ];

    return plantTypes.filter((type) => evaluatePredicate(type.predicate || true, integrationState));
  }
);

export const getFormTypeOfCannabis = (state) => {
  const strain_id = selector(state, 'strain_id');
  return getTypeOfCannabisByStrain(state, { strain_id }) || null;
};
