import {change} from 'redux-form';
import get from 'lodash.get';
import {getItem, postData, putItem} from '../apiActions';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';


/**
 * Returning a list of lab results, requesting by list inventory(item) IDs
 * @param ids
 * @returns {function(*): *}
 */
export const fetchLabResultsByIDs = (ids) => (dispatch) => (
  dispatch(postData('/api/lab_results/by_item_id/multiple', {ids }, dataNames.inventoryItemsTestResults))
);

/**
 * Returning lab result detailed info
 * @param id
 * @returns {function(*): *}
 */
export const fetchLabResult = (id) => (dispatch) => (
  dispatch(getItem(`/api/lab_results/${id}`, itemNames.testResult, {failed: 'cultivation.testResults.get.failed'}))
);

/**
 * Call to backend and create a new Test Result
 * @param payload
 * @returns {function(*): *}
 */
export const save = (payload) => (dispatch) => {
  const messages = {
    success: 'cultivation.testResults.add.success',
    failed: 'cultivation.testResults.add.failed'
  };

  return dispatch(postData('/api/lab_results', payload, dataNames.testResults, messages));
};

/**
 * Call to backend and update Test Result
 * @param id
 * @param payload
 * @returns {function(*): *}
 */
export const update = (id, payload) => (dispatch) => {
  const messages = {
    success: 'cultivation.testResults.modify.success',
    failed: 'cultivation.testResults.modify.failed'
  };

  return dispatch(putItem(`/api/lab_results/${id}`, payload, itemNames.testResult, messages));
};

/**
 * Call to backend to get and set the partner facility ID from which a package originated (from package lot)
 */
export const setPartnerFromPackage = (id, formName) => (dispatch) => {
  if (id) {
    return dispatch(getItem(`/api/packages/${id}?detailed=1`, itemNames.pkg, {failed: 'cultivation.testResults.get.failed'}, {}, (data) => {
      dispatch(change(formName, 'for_partner_facility_id', get(data, 'lot.partner_facility_id')));
    }));
  }
};


/**
 * Call to backend to get and set the partner facility ID from which a package originated (from receipt's purchase order vender facility)
 */
export const setPartnerFromPackageCode = (package_code, formName) => (dispatch) => {
  if (package_code) {
    return dispatch(getItem(`/api/receipts/package/by_package_code/${package_code}`, itemNames.pkg, {failed: 'cultivation.testResults.get.failed'}, {}, (data) => {
      dispatch(change(formName, 'for_partner_facility_id', get(data, 'vendor_facility_id')));
    }));
  }
};
