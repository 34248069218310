import React from 'react';
import PropTypes from 'prop-types';
import {ListGroup, ListGroupItem} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';

const Groups = ({groups}) => {
  return (

    <ListGroup>
      {groups.map((group, index) =>
        <ListGroupItem key={index}>
          {group.group_name} {group.active ? null : '(' + I18n.t('customers.table.inactiveGroup') + ')'}
        </ListGroupItem>)
      }
    </ListGroup>
  );

};

Groups.propTypes = {
  groups: PropTypes.array.isRequired
};

export default Groups;
