import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { Table } from 'react-bootstrap';
import InternationalCurrencyStatic from '../common/form/InternationalCurrencyStatic';
import InternationalCurrencyInput from '../common/form/InternationalCurrencyInput';

const CartTotalsTable = ({
  subTotal,
  couponsTotal,
  taxableTotal,
  taxes = [],
  total,
  coupons = [],
  manualDiscountsTotal,
  manualDiscountForm = false,
  payments = [],
}) => {

  const mmapPaymentsTotal = payments.reduce((total, payment) => payment.payment_type === 'mmap' && payment.is_cancelled === 0
    ? total + parseFloat(payment.amount)
    : total, 0);

  return (
    <Table>
      <tbody>
        <tr>
          <td>{I18n.t('cart.totals.subTotal')}:</td>
          <td className='text-right'>
            <InternationalCurrencyStatic>{subTotal ? parseFloat(subTotal).toFixed(2) : '0.00'}</InternationalCurrencyStatic>
          </td>
        </tr>
        {coupons.map((coupon, index) => (
          <tr key={index} className='coupon'>
            <td className='coupon-name'>{coupon.name}:</td>
            <td className='text-right'>
              -<InternationalCurrencyStatic>{coupon.amount}</InternationalCurrencyStatic>
            </td>
          </tr>
        ))}
        {couponsTotal > 0 && (
          <tr>
            <td>{I18n.t('cart.totals.coupons')}:</td>
            <td className='text-right'>
              -<InternationalCurrencyStatic>{parseFloat(couponsTotal).toFixed(2)}</InternationalCurrencyStatic>
            </td>
          </tr>
        )}
        {manualDiscountsTotal > 0 && (
          <tr>
            <td>{I18n.t('cart.totals.manualDiscount')}:</td>
            <td className='text-right'>
              -<InternationalCurrencyStatic>{parseFloat(manualDiscountsTotal).toFixed(2)}</InternationalCurrencyStatic>
            </td>
          </tr>
        )}
        {manualDiscountForm && (
          <tr>
            <td>{I18n.t('cart.totals.manualDiscount')}:</td>
            <td className='text-right'>
              <InternationalCurrencyInput name='manualDiscount' props={{ className: 'form-control' }} />
            </td>
          </tr>
        )}
        {mmapPaymentsTotal > 0 && (
          <tr>
            <td>{I18n.t('cart.totals.mmapPayment')}:</td>
            <td className='text-right'>
              -<InternationalCurrencyStatic>{parseFloat(mmapPaymentsTotal).toFixed(2)}</InternationalCurrencyStatic>
            </td>
          </tr>
        )}
        {taxes.length > 0 && couponsTotal > 0 && (
          <tr>
            <td>{I18n.t('cart.totals.taxableTotal')}:</td>
            <td className='text-right'>
              <InternationalCurrencyStatic>{parseFloat(taxableTotal).toFixed(2)}</InternationalCurrencyStatic>
            </td>
          </tr>
        )}
        {taxes.length > 0 ? (
          taxes.map((orderTax, index) => (
            <tr key={`tax-${index}`}>
                <td>
                  {I18n.t('cart.totals.taxDetailed', {
                    name: (orderTax.tax && orderTax.tax.tax_name) || '',
                    rate: ((orderTax.tax && orderTax.tax.amount * 100) || 0).toFixed(3)
                  })}
                </td>
              <td className='text-right'>
                <InternationalCurrencyStatic>{orderTax.amount}</InternationalCurrencyStatic>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td>{I18n.t('cart.totals.taxDetailed', { name: '', rate: (0).toFixed(3) })}</td>
            <td className='text-right'><InternationalCurrencyStatic>0</InternationalCurrencyStatic></td>
          </tr>
        )}
        <tr>
          <td>{I18n.t('cart.totals.total')}:</td>
          <td className='text-right'>
            <InternationalCurrencyStatic>{total ? parseFloat(total).toFixed(2) - mmapPaymentsTotal : '0.00'}</InternationalCurrencyStatic>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

CartTotalsTable.propTypes = {
  subTotal: PropTypes.number,
  coupons: PropTypes.array,
  couponsTotal: PropTypes.number,
  manualDiscountsTotal: PropTypes.number,
  payments: PropTypes.array,
  taxableTotal: PropTypes.number,
  taxes: PropTypes.array,
  total: PropTypes.number,
  manualDiscountForm: PropTypes.bool
};

export default CartTotalsTable;
