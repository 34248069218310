import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {formValueSelector} from 'redux-form';
import productSubTypes from '../../../../constants/salesOrderMetaData';

const ProductSubTypeDisplay = (props) => {

  const activeSubType = productSubTypes.find((type) => props.meta && props.meta.product_sub_type && type.value === props.meta.product_sub_type);

  if(!activeSubType) return null;

  return (<div style={{padding: '0 8px', position: 'relative', top: '-8px'}}>
    <div>{I18n.t('transfers.productSubType')}: {I18n.t(`transfers.${activeSubType.translationKey}`)}</div>
    <div className='text-muted' style={{fontSize: 'smaller'}}>{I18n.t('transfers.packageFilterNotice')}</div>
  </div>);

};

ProductSubTypeDisplay.propTypes = {
  meta: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
};

function mapStateToProps(state, ownProps){
  const selector = formValueSelector(ownProps.formName);
  return {
    meta: selector(state, `${ownProps.fieldName}.meta`)
  };
}

export default connect(mapStateToProps)(ProductSubTypeDisplay);
