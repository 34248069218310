import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import { Card } from 'react-bootstrap';
import TextInput from '../../../common/form/TextInput';

const MinimumDaysSupplyItem = (props) => {
  const {type, patients} = props;

  const fields = Object.keys(patients);

  return (
    <div className='col-md-6' key={type} style={type === 'non_terminal' ? {clear: 'both'} : {}}>
      <Card>
        <Card.Header>
          <Card.Title>
            {
              type === 'non_terminal'
                ? (
                  I18n.t('retail.minimumDaysSupply.nonTerminalPatient')
                ) : (
                  I18n.t('retail.minimumDaysSupply.terminalPatient')
                )
            }
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <table className='table table-bordered table-striped'>
            <thead>
            <tr>
              <th>{I18n.t('retail.minimumDaysSupply.category')}</th>
              <th>{I18n.t('retail.minimumDaysSupply.minimumWholeDayUnit')}</th>
              <th>{I18n.t('retail.minimumDaysSupply.uom')}</th>
            </tr>
            </thead>
            <tbody>
            {fields.map((category, itemIndex) => {
              return (
                <tr key={itemIndex}>
                  <td>{I18n.t(`retail.minimumDaysSupply.${category}`)}</td>
                  <td>
                    <Field
                      name={`${type}.${category}`}
                      component={TextInput}
                      props={{
                        disabled: false,
                      }}
                    />
                  </td>
                  <td>GR</td>
                </tr>
              );
            })}
            </tbody>
          </table>
        </Card.Body>
      </Card>
    </div>
  );

};

MinimumDaysSupplyItem.propTypes = {
  type: PropTypes.string.isRequired,
  patients: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(MinimumDaysSupplyItem);
