import {Engine} from 'json-rules-engine';
import get from 'lodash.get';
import {setItem} from './itemActions';
import * as itemNames from '../constants/itemNames';

const engine = new Engine([], {allowUndefinedFacts: true});
let rulesApplied = false;
let rulesObject = false;

/*********************
 *  Begin simple wrapper of Engine
 *********************/

const rules = function(name = false, dispatch, getState){
  this.name = name ? name : 'default';
  this.engine = engine;
  this.rules = [];
  this.dispatch = dispatch;
  this.getState = getState;
};

rules.prototype.setRules = function(rules){ // Only set once - be aware
  this.rules = rules;
  if(!rulesApplied) {
    rulesApplied = true;
    if (Array.isArray(rules)) {
      rules.forEach((rule) => {
        this.engine.addRule(rule);
      });
      return this;
    }
    this.engine.addRule(rules); // Object
  }
  return this;
};

rules.prototype.setFacts = function(facts){ // Run with each new set of facts
  const ruleName = this.name;
  this.engine
    .run(facts)
    .then((events) => {
      const results = this.rules.map((rule) => { // Get base with everything false
        return {type: rule.event.type, result: get(rule, 'event.falseValue', false)};
      });
      events.forEach((event) => {
        const rule = this.rules.find((rule) => rule.event.type === event.type); // override those true
        const result = results.find((result) => result.type === event.type); // override those true
        result.result = get(rule, 'event.trueValue', true);
      });
      if(itemNames.rules){ //eslint-disable-line
        const currentRules = this.getState()[itemNames.rules];
        const newRules = results.reduce((acc, result) => {
          acc[result.type] = result.result;
          return acc;
        }, {});
        if(!currentRules[ruleName] || JSON.stringify(newRules) !== JSON.stringify(currentRules[ruleName])) {
          const payload = Object.assign({}, currentRules, {[ruleName]: newRules}); //eslint-disable-line
          this.dispatch(setItem(payload, itemNames.rules)); //eslint-disable-line
        }
      }
    });
  return this;
};

/*********************
 *  END SIMPLE WRAPPER OF ENGINE
 *********************/

const getRulesObject = (name = false) => { //eslint-disable-line
  return (dispatch, getState) => new Promise ((resolve) => {
    resolve(new rules(name, dispatch, getState));
  });
};

export const setRules = (rulesArray, name = 'default') => {
  return (dispatch, getState) => new Promise ((resolve) => {
    if(!rulesObject){
      rulesObject = new rules(name, dispatch, getState);
    }
    rulesObject.setRules(rulesArray);
    resolve(rulesObject);
  });
};

export const setFacts = (facts) => {
  return (dispatch) => new Promise ((resolve) => {
    if(!rulesObject) {
      dispatch(setRules([]))
        .then((rulesObject) => {
          rulesObject.setFacts(facts);
        });
    }
    rulesObject.setFacts(facts);
    resolve(rulesObject);
  });
};
