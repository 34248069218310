import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {reduxForm, FieldArray} from 'redux-form';
import ReleaseQuarantinePackagesFieldArray from './ReleaseQuarantinePackagesFieldArray';

const ReleaseQuarantineForm = ({ onRemovePackage }) => {
  return (
    <form onSubmit={() => {}}>
      <p>{I18n.t('closedLoop.admin.releaseQuarantineConfirm')}</p>
      <FieldArray
        name='packages'
        component={ReleaseQuarantinePackagesFieldArray}
        onRemovePackage={onRemovePackage}
      />
    </form>
  );
};

ReleaseQuarantineForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onRemovePackage: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'releaseQuarantineForm'
})(ReleaseQuarantineForm);
