import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack, push} from 'react-router-redux';
import {Col, Row, Modal, Button} from 'react-bootstrap';
import {reset, submit, change, formValueSelector} from 'redux-form';
import {getCatalogItemMastersWithPrice} from '../../selectors/itemMastersSelectors';
import * as apiActions from '../../actions/apiActions';
import * as cartActions from '../../actions/cartActions';
import {addMessage} from '../../actions/systemActions';
import {closeCustDetails} from '../../actions/menuActions';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import {handleCureApiValidationErrors, isValidLimits} from '../../selectors/integration/cureApiSelectors';
import {LEAF} from '../../constants/imageUrls';
import {unsetItem, setItem} from '../../actions/itemActions';
import PaymentItems from './PaymentItems';
import OrderTransactions from './OrderTransactions';
import Coupons from './Coupons';
import Payment from './Payment';  // eslint-disable-line import/no-named-as-default
import PaymentTotals from './PaymentTotals';  // eslint-disable-line import/no-named-as-default
import InProgressOverlay from '../common/InProgressOverlay';
import ConnectedOrderMetaForm from './OrderMetaForm';
import {
  getAlleavesMode,
  isAlleavesIntegrator,
  isOnfleetIntegrator,
  isPosabitIntegrator
} from '../../selectors/integration/thirdPartyIntegrationSelectors';
import {getSalesLimitError, getPaymentOptions} from '../../selectors/salesSettingsSelectors';
import getIsOrderAnonymous from '../../selectors/anonymousOrderSelectors';
import {getRewardsEnabledState, getRewardsMode, getRewardsPointsToCurrencyConversion} from '../../selectors/rewardsSelectors';
import {getMetrcSalesCustomerTypesOptions} from '../../selectors/integration/metrcSelectors';
import {isFeatureEnabled} from '../../selectors/featureToggles';
import {
  getIsMonerisProcessor,
  isCreditCardPaymentEnabled
} from '../../selectors/credit-cards/creditCardSettingsSelectors';

import {getImageUrl} from '../../util/images';
import ProductsHeader from '../header/ProductsHeader';
import {getAppliedCouponsById, getAmountDue, getChangeDue} from '../../selectors/cartSelectors';
import {
  couponIsAppliedToAllProducts,
  getOrderMmapPayments,
  getOrderProductCoupons,
  getOrderTaxes,
  getOrderWithRichProducts,
  isOrderFulfilled
} from '../../selectors/ordersSelectors';
import {getOrderSubtotal, getTaxableTotal} from '../../selectors/orderSelectors';
import {getIntegrationState} from '../../selectors/integration/integrationSelectors';
import {getLabelTags, isAutoPrintEnabled} from '../../selectors/labelsSelectors';
import CancelOrder from '../orders/order/cancel/CancelOrder'; // eslint-disable-line import/no-named-as-default
import LabelPrinter from '../print-services/labels/LabelPrinter'; // eslint-disable-line import/no-named-as-default
import {hasPermApplyRewardCoupons} from "../../selectors/permissionsSelectors"; //eslint-disable-line
import {parseCreditCardDetails} from './card/utils';
import * as messageTypes from '../../constants/messageTypes';
import ModalWrapper from '../common/ModalWrapper';
import {getOrderSettings} from '../../selectors/orderSettingsSelectors';
import {doNothing} from '../../util/callbackHelpers';

const initialState = {
  credit: '',
  check: '',
  cash: '',
  debit: '',
  giftCard: '',
  credit_card: null,
  charge_amount: ''
};
const formName = 'payment-form';

export class PaymentPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.state = {
      showActivateModal: false,
      printReceipt: false,
      viewReceipt: false,
      showPrinter: false,
      orderId: null,
      printAll: 0,
      paymentComplete: false,
      confirmCancel: false,
      submitting: false,
      showPrinterSettings: false,
      showCancelConfirm: false,
      showCart: true,
      showInProgress: false,
      inProgressMessage: '',
      showAmountDueWarning: false,
      showInactiveLabelWarning: false,

      cancelOrderEvent: 'noop',
      cancelOrderCustomerId: 0,
      cancelOrderOrderId: 0,
      cancelOrderEvenListeners: {},
      printTrigger: 0, // When autoPrint enabled this value triggers printing and increments each time

      showModal: false,
      payload: {},
      autoPrint: 0,
      autoPrintReceipt: 0,
      onAutoPrintComplete: null,
      disableCheckedInUsers: false,

      showHypurPACModal: false,
      showAeropayintegratedModal: false,

      isPosabitOnline: false,
      pollingActive: false,
      pollInterval: 1000, // 1 second for polling unresolved payment interval
      maxPollingDuration: 5 * 60 * 1000, // 5 minutes for max polling duration
    };

    this.checkUnresolvedPayments = this.checkUnresolvedPayments.bind(this);
    this.editOrder = this.editOrder.bind(this);
    this.editFulfillment = this.editFulfillment.bind(this);
    this.cancelOrder = this.cancelOrder.bind(this);
    this.printAllLabels = this.printAllLabels.bind(this);
    this.printLabel = this.printLabel.bind(this);
    this.printReceipt = this.printReceipt.bind(this);
    this.viewReceipt = this.viewReceipt.bind(this);
    this.onCloseReceipt = this.onCloseReceipt.bind(this);
    this.hidePrinter = this.hidePrinter.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancelOrder = this.onCancelOrder.bind(this);
    this.preventDefault = this.preventDefault.bind(this);
    this.onPaymentOrderClick = this.onPaymentOrderClick.bind(this);
    this.showPrinterSettings = this.showPrinterSettings.bind(this);
    this.showPrinterSettingsCallback = this.showPrinterSettingsCallback.bind(this);
    this.hidePrinterSettingsCallback = this.hidePrinterSettingsCallback.bind(this);
    this.afterPaymentComplete = this.afterPaymentComplete.bind(this);
    this.onFallbackToModal = this.onFallbackToModal.bind(this);
    this.receiptAutoPrinting = this.receiptAutoPrinting.bind(this);
    this.labelsAutoPrinting = this.labelsAutoPrinting.bind(this);
    this.redirectToQueue = this.redirectToQueue.bind(this);

    this.validateOrderAgainstCureApi = this.validateOrderAgainstCureApi.bind(this);
    this.onDismissInProgress = this.onDismissInProgress.bind(this);

    this.onSubmitOrderMeta = this.onSubmitOrderMeta.bind(this);

    this.onHideModal = this.onHideModal.bind(this);
    this.onScanComplete = this.onScanComplete.bind(this);
    this.onHypurPaymentChange = this.onHypurPaymentChange.bind(this);
    this.onHideHypurPACModal = this.onHideHypurPACModal.bind(this);
    this.onSubmitHypurPayment = this.onSubmitHypurPayment.bind(this);
    this.onRememberCheckedInUser = this.onRememberCheckedInUser.bind(this);
    this.onHypurSelectedUserChange = this.onHypurSelectedUserChange.bind(this);
    this.showAeropayintegratedModal = this.showAeropayintegratedModal.bind(this);
    this.hideAeropayintegratedModal = this.hideAeropayintegratedModal.bind(this);
    this.onSubmitAeropayintegratedAmount = this.onSubmitAeropayintegratedAmount.bind(this);
    this.verifyTerminal = this.verifyTerminal.bind(this);
    this.hasUnresolvedPayments = this.hasUnresolvedPayments.bind(this);
    this.pollingCheckUnresolvedPayments = this.pollingCheckUnresolvedPayments.bind(this);
    this.removeMmapPayment = this.removeMmapPayment.bind(this);
    this.hasNewCancelledPayments = this.hasNewCancelledPayments.bind(this);
  }

  componentDidMount() {

    const {order: {products}, isLeaf, customer, isPatientTypeDropdownToggled} = this.props;
    this.props.actions.unsetItem(itemNames.printReceipt);
    this.props.actions.unsetItem(itemNames.printLabel);
    this.props.actions.getUnpaginatedData(
      '/api/registers',
      dataNames.registers,
      { failed: 'registers.getRegisters.fail' },
      { detailed: 1, active: 1 });
    this.props.actions.getItem('/api/credit_cards/settings', itemNames.creditCardsSettings);
    this.props.actions.getItem(
      '/api/catalog',
      itemNames.catalog,
      {failed: 'productMenu.catalog.get.failed'},
      {with_images: 0, include_pricing: 0, having_lab_results_only: Number(isLeaf), consumer_type: customer.type},
      (data) => {
        const ids = products.reduce((acc, p) => {
          // If inventory for item_master_id is fully reserved it is not returned in catalog list
          if (data[p.item_master_id] === undefined) return acc;
          acc.push(data[p.item_master_id].primary_product_image_file_id);
          return acc;
        }, []).filter(v => v != null);
        if (ids.length) {
          this.props.actions.getDataByPost('/api/images/multiple', {ids}, dataNames.images);
        }
      },
      {debounce: true}
    );
    if (isPatientTypeDropdownToggled) {
      this.props.actions.getUnpaginatedData('/api/metrc/sales_customer_types', dataNames.metrcSalesCustomerTypes);
    }
    this.props.actions.getUnpaginatedData('/api/prepack_weights', dataNames.prepackWeights, undefined, undefined, undefined, {debounce: true});
    this.props.actions.getUnpaginatedData('/api/taxes', dataNames.taxes, {failed: 'taxes.get.failed'}, undefined, undefined, {debounce: true});

    this.props.actions.getItem('/api/order_settings', itemNames.orderSettings, undefined, undefined, (data) => {
      if (get(data, 'order_auto_printing.value.is_enabled')) {
        this.props.actions.getData('/api/labels/tags/patient_label', dataNames.labelTags);
      }
    });

    // Load delivery dependent values if appropriate
    if(this.props.route.path.indexOf('delivery') !== -1){
      this.props.actions.getUnpaginatedData('/api/drivers/details', dataNames.drivers, undefined, {active: 1});
      this.props.actions.getUnpaginatedData('/api/vehicles/', dataNames.vehicles);
      this.setState({
        deliveryDetails: {
          delivery_address_id: this.props.order.delivery_address_id || 0,
          driver_id: this.props.order.driver_id || 0,
        },
      });
    }

    this.props.actions.getItem(`/api/orders/details/${this.props.order.id}`, itemNames.order, {failed: 'orders.get.fail'},
      undefined, undefined, {debounce: true});

    // Only do this check if:
    // - POSaBIT feature toggle is enabled
    // - POSaBIT integration is enabled
    // - Current register has Terminal ID specified
    const {isPosabitToggled, terminalId, isPosabitIntegratorEnabled} = this.props;
    if (isPosabitToggled && isPosabitIntegratorEnabled && terminalId) {
      this.verifyTerminal(terminalId).then((isPosabitOnline) => this.setState({isPosabitOnline}));
    }
  }

  componentDidUpdate(prevProps, prevState, snapShot){
    const newState = {};

    if (!this.state.pollingActive && this.hasUnresolvedPayments(this.props.order)) {
      newState.pollingActive = true;
      this.pollingCheckUnresolvedPayments(this.props.order);
    }

    // Check for changed payment status
    if (this.hasNewCancelledPayments(prevProps, this.props)) {
      this.props.actions.addMessage(messageTypes.error, 'registers.form.paymentFailed');
    }

    if(this.props.order.note !== undefined && !this.state.orderNote) newState.orderNote = this.props.order.note;
    if(this.props.order.locked && ! this.state.paymentComplete) newState.paymentComplete = true;
    if(Object.keys(newState).length > 0) this.setState(newState);
  }

  componentWillUnmount() {
    this.props.actions.closeCustDetails();
    clearInterval(this.poll);
    this.poll = null;
  }

  hasNewCancelledPayments(prevProps, currentProps) {
    const paymentTypes = ['alleaves']; // check only payments of these types
    const prevPendingPaymentIds = get(prevProps, 'order.payments', [])
      .filter((payment) => paymentTypes.includes(payment.payment_type) && payment.is_pending)
      .map((payment) => payment.id);
    const currentCancelledPaymentIds = get(currentProps, 'order.payments', [])
      .filter((payment) => paymentTypes.includes(payment.payment_type) && payment.is_cancelled)
      .map((payment) => payment.id);
    // Loop over all prevPendingPaymentsIds. If any of them appear in currentCancelledPaymentIds, ity is a new cancelled payment
    return prevPendingPaymentIds.some((paymentId) => currentCancelledPaymentIds.includes(paymentId));
  }

  // helper function to check if there are any unresolved payments
  hasUnresolvedPayments(order) {
    const unresolvedPayments = get(order, 'payments', []).filter((payment) => payment.is_pending === 1 && payment.is_cancelled === 0);
    // Add check for balance_due and payment_completed if no unresolvedPayments
    // Explanation: When the debit payment is completed and there is 'change due' then we want to reload the order until the change payment has been added and the order is marked as completed
    const negative_balance_due = get(order, 'balance_due', 0) < -0.01;
    const payment_completed = get(order, 'payment_completed', '0') === '1';
    return unresolvedPayments.length > 0 || (negative_balance_due && !payment_completed);
  }

  // helper function to poll checking unresolved payments until there are none
  pollingCheckUnresolvedPayments(order) {
    if (!this.poll) {
      this.poll = setInterval(this.checkUnresolvedPayments, this.state.pollInterval, order);
      this.stopPollingTimeout = setTimeout(() => {
        clearInterval(this.poll);
        this.poll = null;
      }, this.state.maxPollingDuration);
    }
  }

  checkUnresolvedPayments(order) {
    const id = get(order, 'id', null);
    this.props.actions.getItem(`/api/orders/details/${id}`,
      null,
      {failed: 'orders.get.fail'},
      undefined,
      (order) => {
        if (order && !this.hasUnresolvedPayments(order)) {
          this.props.actions.setItem(order, itemNames.order);
          this.afterPaymentComplete(order);
          this.setState({showAmountDueWarning: false});
          clearInterval(this.poll);
          this.poll = null;
        }
      });
  }

  verifyTerminal(terminalId) {
    return new Promise((resolve, reject) => {
      this.props.actions.getItem('/api/posabit/terminal_status', null, { failed: 'registers.posabit_terminal_id_invalid' }, { terminal_id: terminalId })
        .then((response) => {
          if (get(response, 'status') === 'online' || get(response, 'status') === 'offline') {
            resolve(get(response, 'status') === 'online');
          }
          else {
            this.props.actions.addMessage(messageTypes.error, 'registers.posabit_terminal_id_invalid');
            reject();
          }
        })
        .catch((err) => {
          reject();
        });
    });
  }

  // Order methods

  editOrder() {
    this.props.actions.push(`/product-menu/${this.props.order.id}`);
  }

  onPaymentOrderClick() {
    this.props.actions.push(`/orders/${this.props.order.id}/payment`);
  }

  editFulfillment(){
    this.props.actions.push(`/cart`);
  }

  onCancelOrder(){
    if(this.props.order.payments && this.props.order.payments.length){
      this.setState({confirmCancel: true, showCancelConfirm: true});
    }else{
      this.setState({confirmCancel: true});
    }
  }

  cancelOrder() {
    const that = this;
    this.setState({
      cancelOrderEvent: 'cancel',
      cancelOrderCustomerId: this.props.customer.id,
      cancelOrderOrderId: this.props.order.id,
      cancelOrderEventListeners: {
        cancel: {
          onBeforeCancel(option, value){
            that.setState({submitting: true});
          },
          onError(e){
            that.setState({submitting: false});
          },
          onAfterCancel(option, value, data){
            that.props.actions.push('/queue/in_store');
          }
        }
      }
    });
  }

  postPayments(orderId, payments, then = () => {}) {
    const {isMoneris,user} = this.props;

    if(!payments.length){
      // for $0 orders, the order can't be completed unless a $0 payment is posted (MJP-16491)
      payments.push({
        payment_type: 'cash',
        register_id: get(user,'currentRegister',null),
        amount: 0
      });
    }

    const [payment, ...remaining] = payments;
    const next = remaining.length ? () => this.postPayments(orderId, remaining, then) : then;

    return this.props.actions.postItem(
      `/api/orders/${orderId}/payments`,
      payment,
      itemNames.order,
      {
        failed: 'cart.payment.post.failed',
        success: 'cart.payment.post.success',
        failedHandler: errors => {
          if (!errors || !errors.response) {
            this.setState({showInProgress: false});
            return {
              message: I18n.t('cart.payment.post.emptyResponse'),
            };
          }
          const salesLimitError = getSalesLimitError(errors);
          if (salesLimitError) {
            return salesLimitError;
          }
          const response = errors.response.data;
          if (response && response.errors && response.errors.order_id && response.errors.order_id[0]) {
            return {
              message: I18n.t('cart.payment.post.orderIdError', {serverError: response.errors.order_id[0]})
            };
          }
          if (get(response, 'errors.VALIDATION.ORDER_ERROR.0')) {
            this.props.actions.change(formName, 'hypur_payment.pac', '');

            return {
              message: get(response, 'errors.VALIDATION.ORDER_ERROR.0'),
            };
          }
          const paymentCreditCardErrorMessage = get(response, 'errors.payment_credit_card');
          if(isMoneris && get(response, 'errors.payment_credit_card')) {
            this.setState({paymentCreditCardErrorMessage, showInProgress: false});
          }

        }
      },
      {},
      next)
      .then(() => {})
      .catch(() => this.setState({submitting: false, showInProgress: false}));
  }

  afterPaymentComplete(order) {
    if (order.order_status === 'completed') {
      Promise.resolve()
        .then(this.receiptAutoPrinting)
        .then(this.labelsAutoPrinting)
        .then(() => this.setState({showInProgress: false}))
        .then(this.redirectToQueue)
        .catch(doNothing);
    } else {
      this.setState({showAmountDueWarning: true});
      // Set start time (for pending payment modal only)
      this.setState({showAmountDueWarningStart: Date.now()});
    }
  }

  removeMmapPayment(id) {
    const {order} = this.props;
    this.props.actions.deleteItem(`/api/orders/${order.id}/mmap_payments/${id}`,
      null, //delete removes the item, so I'm removing itemNames.order from here
      {failed: 'orders.delete.mmapPaymentFailed'},
      undefined,
      (order) => {
        this.props.actions.setItem(order, itemNames.order);
      }
    );
  }

  /**
   * Does auto-printing of labels right after payment is completed.
   * Only if auto-printing is configured on Sales settings.
   */
  labelsAutoPrinting() {
    return new Promise((resolve, reject) => {
      const {order_auto_printing} = this.props.orderSettings;
      if (!order_auto_printing
        || !order_auto_printing.value
        || !order_auto_printing.value.is_enabled
        || !order_auto_printing.value.print_labels
        || this.state.autoPrint) {
        return resolve();
      }

      // Check if label, selected for auto-printing, exists
      if (!this.props.labelTags.some(label => label.tag === order_auto_printing.value.default_label)) {
        this.setState({
          showInactiveLabelWarning: true,
          onAutoPrintComplete: null,
          showInProgress: false,
          inProgressMessage: '',
        });
        return reject();
      }

      this.setState({
        onAutoPrintComplete: resolve,
        showInProgress: true,
        inProgressMessage: I18n.t('cart.payment.autoPrintingLabels'),
      });
      this.printAllLabels(null, true);
    });
  }

  /**
   * Does auto-printing of receipt right after payment is completed.
   * Only if auto-printing is configured on Sales settings.
   */
  receiptAutoPrinting() {
    return new Promise((resolve, reject) => {
      const {order_auto_printing} = this.props.orderSettings;
      if (!order_auto_printing
        || !order_auto_printing.value
        || !order_auto_printing.value.is_enabled
        || !order_auto_printing.value.print_receipts
        || this.state.autoPrintReceipt) {
        return resolve();
      }
      this.setState({
        onAutoPrintComplete: resolve,
        showInProgress: true,
        inProgressMessage: I18n.t('cart.payment.autoPrintingReceipt'),
      });
      this.printReceipt(null);
    });
  }


  /**
   * Redirect to Queue page after payment (and auto-printing if applied) is completed.
   */
  redirectToQueue() {
    // Temporary disabled - allowPrintWithoutPayment should not be in the conditional.
    // Support also says that with autoPrint off selecting print still results in redirect to queue which I don't see how - but the choice was made to disable this for now.
    // const {orderSettings} = this.props;
    // if (orderSettings) {
    //   const {order_print_labels_and_receipts_without_payment, order_auto_printing} = orderSettings;
    //   const autoPrintingEnabled = Boolean(order_auto_printing && order_auto_printing.value && order_auto_printing.value.is_enabled);
    //   const allowPrintWithoutPayment = Boolean(order_print_labels_and_receipts_without_payment && order_print_labels_and_receipts_without_payment.value === '1');
    //   if (autoPrintingEnabled || allowPrintWithoutPayment) {
    //     this.props.actions.push('queue/in_store');
    //   }
    // }
  }


  /**
   * Hide auto-printing status messages if auto-printing headless process fell-back to Modal Window.
   */
  onFallbackToModal() {
    this.setState({
      showInProgress: false,
      inProgressMessage: '',
    });
  }


  onHypurPaymentChange () {
    const {customer} = this.props;

    this.setState({
      showInProgress: true,
      inProgressMessage: I18n.t('cart.loadingMessage.fetchingHypurUsers'),
    });

    this.props.actions.getUnpaginatedData('/api/hypur/users', dataNames.hypurCheckedInUsers, {
      failed: 'general.serviceUnavailable'
    }).then(users => {
      this.onDismissInProgress();

      if (users.length === 0) {
        return this.props.actions.addMessage(messageTypes.warning, 'cart.payment.noCheckedInHypurUsers');
      }

      if (get(customer, 'extra_data.hypur_id')) {
        const mappedUser = users.find(user => user.is_mapped && get(user, 'individualISN') == get(customer, 'extra_data.hypur_id') && get(user, 'consumer_id') == get(customer, 'id'));

        if (!mappedUser) {
          this.props.actions.addMessage(messageTypes.warning, ['cart.payment.hypurMappedUserNotCheckedIn', {
            user: get(customer, 'extra_data.hypur_name')
          }]);
        } else {
          this.props.actions.change(formName, 'remember_hypur_user', true);
          this.props.actions.change(formName, 'hypur_payment.individual_isn', mappedUser.individualISN);
        }

        this.setState({
          disableCheckedInUsers: true,
        });
      }
    }).catch(() => {
      this.onDismissInProgress();
      this.props.actions.change(formName, 'hypur', null);
    });
  }

  onRememberCheckedInUser (remember, checkedInUserISN) {
    this.setState({
      showInProgress: true,
      inProgressMessage: I18n.t(remember ? 'cart.payment.rememberingHypurUser' : 'cart.payment.forgettingHypurUser'),
      disableCheckedInUsers: remember,
    });

    const {order, hypurCheckedInUsers} = this.props;

    const hypurUser = hypurCheckedInUsers.find(hypurCheckedInUser => hypurCheckedInUser.individualISN === checkedInUserISN);

    // If the Hypur User we're mapping the current customer to is mapped
    // to a different patient, we first want to destroy the prior mapping.
    if (remember && hypurUser.is_mapped) {
      this.props.actions.postItem('/api/hypur/users/forget', {
        hypur_id: checkedInUserISN,
        consumer_id: hypurUser.consumer_id
      }).then(() => {
        this.props.actions.postItem(`/api/hypur/users/${remember ? 'remember' : 'forget'}`, {
          hypur_id: checkedInUserISN,
          consumer_id: get(order, 'consumer_id'),
          hypur_name: get(hypurUser, 'individualName'),
        }).then(() => {
          this.onDismissInProgress();
        }).catch(() => {
          this.onDismissInProgress();
        });
      });
    } else {
      this.props.actions.postItem(`/api/hypur/users/${remember ? 'remember' : 'forget'}`, {
        hypur_id: checkedInUserISN,
        consumer_id: get(order, 'consumer_id'),
        hypur_name: get(hypurUser, 'individualName'),
      }).then(() => {
        this.onDismissInProgress();
      }).catch(() => {
        this.onDismissInProgress();
      });
    }

    this.props.actions.change(formName, 'remember_hypur_user', Boolean(remember));
  }

  onHypurSelectedUserChange (value) {
    const user = this.props.hypurCheckedInUsers.find(user => user.individualISN == value);

    if (user && user.is_mapped) {
      this.props.actions.addMessage(messageTypes.warning, ['cart.payment.hypurUserAlreadyMapped', {
        user: user.individualName
      }]);
    }

    this.props.actions.change(formName, 'hypur_payment.individual_isn', value);
  }

  onSubmit (formValues) {
    const {order, user, paymentOptions, hypurCheckedInUsers, isAnonymousOrder, isHypur, register} = this.props;

    if (isHypur && formValues.hypur > 0 && get(formValues, 'hypur_payment.pac', '').length !== 4) {
      this.props.actions.addMessage(messageTypes.warning, 'cart.payment.hypurPleaseEnterValidPAC');

      return this.setState({
        showHypurPACModal: true,
      });
    }

    this.setState({
      showInProgress: true,
      inProgressMessage: I18n.t('cart.loadingMessage.savingPayments'),
      submitting: true
    });

    const getOrderAndDismiss = () => {
      this.props.actions.getItem(`/api/orders/details/${order.id}`, itemNames.order, {failed: 'orders.get.fail'}, {}, () => {
        this.onDismissInProgress({submitting: false});
      });
    };

    this.validateOrderAgainstCureApi(order.id)
      .then(() => {
        const paymentTypes = paymentOptions.map(paymentOption => paymentOption.value).filter(x => formValues[x] != '0.00' && formValues[x]);
        const registerId = user.currentRegister ? user.currentRegister : null;
        const payments = paymentTypes.map(paymentType => {
          const payment = {
            payment_type: (paymentType === 'giftCard') ? 'gift' : paymentType,
            register_id: registerId,
            amount: parseFloat(formValues[paymentType]),
            ...(paymentType === 'credit' && formValues.credit_card && {credit_card: formValues.credit_card})
          };

          if (paymentType === 'hypur' && isHypur && !isAnonymousOrder && parseFloat(formValues.hypur) > 0 && formValues.hypur_payment) {
            payment.hypur_user = hypurCheckedInUsers.find(hypurCheckedInUser => hypurCheckedInUser.individualISN === formValues.hypur_payment.individual_isn);
            payment.hypur_user.PAC = get(formValues, 'hypur_payment.pac');
          }
          return payment;
        });

        const doPayments = (overridePayments = false) => {

          const {isMoneris} = this.props;

          const failedHandler = (errors) => {

            if (!errors || !errors.response) {
              this.setState({showInProgress: false});
              return {
                message: I18n.t('cart.payment.post.emptyResponse'),
              };
            }
            const salesLimitError = getSalesLimitError(errors);
            if (salesLimitError) {
              return salesLimitError;
            }
            const response = errors.response.data;
            if (response && response.errors && response.errors.order_id && response.errors.order_id[0]) {
              return {
                message: I18n.t('cart.payment.post.orderIdError', {serverError: response.errors.order_id[0]})
              };
            }
            const paymentCreditCardErrorMessage = get(response, 'errors.payment_credit_card');
            if(isMoneris && get(response, 'errors.payment_credit_card')) {
              this.setState({paymentCreditCardErrorMessage, showInProgress: false});
            }
            if(get(response, 'errors.VALIDATION.ALLEAVES_SALE.0')) {
              return {
                message: get(response, 'errors.VALIDATION.ALLEAVES_SALE.0')
              };
            }
            const orderValidationError = get(response, 'errors.VALIDATION.ORDER_ERROR.0');
            if (orderValidationError) {
              this.props.actions.change(formName, 'hypur_payment.pac', '');
              return {
                message: orderValidationError,
              };
            }
          };

          const messages = {
            failed: 'cart.payment.post.failed',
            success: 'cart.payment.post.success',
            failedHandler,
          };

          const usePayments = overridePayments
            ? overridePayments
            : payments.length
              ? payments
              : [{
                payment_type: 'cash',
                register_id: registerId,
                amount: 0
              }];

          /***
           * Dedupe because for some reason hypur payments are getting double payments in an array.  After chasing
           * it for way too long I switched to this approach since in a single call we should not be submitting two
           * of the same type.
           * @TODO: Find where this duplication is happening.  It's probably obvious with fresh eyes.
           */
          const deduplicatePaymentsByType = (payments) => {
            return payments.reduce((acc, payment) => {
              const paymentExistsForType = acc.find((item) => item.payment_type === payment.payment_type);
              if(paymentExistsForType){
                return acc;
              }
              if (payment.payment_type === 'alleaves') {
                payment.alleaves_register_id = get(register, 'alleaves_register_id');
              }
              acc.push(payment);
              return acc;
            }, []);
          };

          const paymentsPayload = deduplicatePaymentsByType(usePayments);

          this.props.actions.postItem(`/api/orders/${order.id}/all_payments`, {payments: paymentsPayload}, itemNames.allPayments, messages)
            .then((order) => {
              this.props.actions.setItem(order, itemNames.order);
              this.props.actions.getItem(`/api/consumer_orders/${order.consumer_id}/recent_history`, itemNames.customerRecentHistory);
              this.setState({submitting: false, showCart: false}); // originally there was a return to queue here
              this.props.actions.reset(formName);
              this.onDismissInProgress();
              // if order has unresolved payments, start polling for checking
              if (this.hasUnresolvedPayments(order)) {
                this.pollingCheckUnresolvedPayments(order);
              }
              this.afterPaymentComplete(order);
            })
            .catch(() => {
              this.setState({submitting: false, showInProgress: false});
            });
        };

        if(+formValues.points){
          const payload = {points_used: formValues.points};
          this.props.actions.postItem(`/api/orders/${order.id}/redemptions`, payload, 'redemption', null, {}, (data) => {
            if(data.balance_due === 0){ // If zero we send a payment of 0 to close out the order
              doPayments([{
                amount: 0,
                payment_type: 'cash',
                register_id: registerId
              }]);
            } else {
              getOrderAndDismiss();
            }
          });
        } else {
          doPayments();
        }

        if (formValues.integration_consumer_type) {
          const data = {
            integration_consumer_type: formValues.integration_consumer_type,
          };

          this.props.actions.putItem(`/api/orders/${order.id}`, data);
        }
      })
      .catch(() => this.setState({submitting: false, showInProgress: false}));
  }

  // Printing methods
  printLabel(event, orderProductId, itemId){
    this.preventDefault(event);
    this.doPrint(orderProductId, itemId, false);
  }

  printAllLabels(event = null, withAutoPrint = false) {
    this.preventDefault(event);
    this.doPrint(undefined, undefined, withAutoPrint);
  }

  doPrint(orderProductId, itemId, withAutoPrint = false){
    const newState = Object.assign({}, {
      payload: Object.assign({}, {
        ids: [this.props.order.id],
        is_order: 1
      },
        orderProductId ? {order_product_id: orderProductId} : {},
        itemId ? {inventory_item_id: itemId} : {})
    }, withAutoPrint ? {showModal: false, autoPrint: new Date().getTime()} : {showModal: true, autoPrint: false});
    this.setState(newState);
  }

  onHideModal(){
    this.setState({
      showModal: false,
      viewReceipt: false,
    });
  }

  onSubmitHypurPayment () {
    this.setState({
      showHypurPACModal: false,
    });

    this.props.actions.submit();
  }

  onHideHypurPACModal () {
    this.setState({
      showHypurPACModal: false,
    });
  }

  showAeropayintegratedModal() {
    this.setState({
      showAeropayintegratedModal: true,
    });
  }

  hideAeropayintegratedModal() {
    this.setState({
      showAeropayintegratedModal: false,
    });
  }

  onSubmitAeropayintegratedAmount() {
    this.setState({
      showInProgress: true,
      inProgressMessage: I18n.t('cart.payment.aeropayintegrated.preauthorizingPayment')
    });

    // Submit revised aeropayintegrated amount
    const payload = {revised_authorization_amount: this.props.getFormValue('revised_aeropayintegrated')};

    this.props.actions.postItem(`/api/orders/${this.props.order.id}/revise_preauth`, payload, itemNames.order, {}, null, null, {silenceErrors: true})
      .then((order) => {
        this.setState({
          showAeropayintegratedModal: false,
        });
        // Show confirmation to the user
        this.props.actions.addMessage(messageTypes.info, ['cart.payment.aeropayintegrated.preauthorizationSuccessful']);
      })
      .finally(() => {
        this.setState({
          showInProgress: false,
        });
      });

  }

  printReceipt(event){
    this.setState({
      autoPrintReceipt: new Date().getTime(),
      viewReceipt: false,
    });
  }

  viewReceipt(event){
    this.preventDefault(event);
    this.setState({
      viewReceipt: true,
      autoPrintReceipt: false,
    });
  }

  onCloseReceipt(){
    this.setState({viewReceipt: false, printReceipt: false, keepReceipt: true});
  }

  hidePrinter() {
    this.setState({showPrinter: false});
  }

  // Printer settings
  showPrinterSettings(){
    this.setState({showPrinterSettings: true});
  }

  showPrinterSettingsCallback(){
    // nothing yet
  }

  hidePrinterSettingsCallback(){
    this.setState({showPrinterSettings: false});
  }

  validateOrderAgainstCureApi(orderId){
    return new Promise((resolve, reject) => {
      if (this.props.isCure) {
        this.props.actions.postItem('/api/cureapi/validate_order', {order_id: orderId}, null, {}, null, null, {silenceErrors: true})
          .then(() => {
            resolve();
          }).catch((e) => {
            handleCureApiValidationErrors(e, this.props.actions.addMessage);
            reject(e);
          });
        return true;
      } else {
        resolve();
      }
    });
  }

  // Helpers
  preventDefault(event){
    if(!event) return true;
    event.stopPropagation();
    event.preventDefault();
    event.target.blur();
    return true;
  }

  onDismissInProgress(stateObject = false){
    const stateChange = {showInProgress: false};
    const newState = (stateObject) ? Object.assign({}, stateObject, stateChange) : stateChange;
    this.setState(newState);
  }

  onSubmitOrderMeta(formData){
    this.setState({showInProgress: true, inProgressMessage: I18n.t('cart.loadingMessage.savingDelivery')});
    const fields = ['delivery_address_id', 'driver_id', 'onfleet_phone_number'];
    fields.forEach((field) => {
      if(formData[field] === 0) formData[field] = null;
    });
    this.props.actions.putItem(`/api/orders/${this.props.order.id}`, formData, itemNames.order, null, {})
      .then(() => {
        if(this.props.isCure){
          return this.props.actions.postItem('/api/cureapi/assign_driver', {order_id: this.props.order.id}, undefined, {failed: 'cart.assignDriver.driverError'})
            .catch(() => {
              this.props.actions.putItem(`/api/orders/${this.props.order.id}`, {driver_id: null, delivery_address_id: null}, itemNames.order);}
            );
        } else if (this.props.isOhMetrc) {
          return this.props.actions.getItem(`/api/orders/details/${this.props.order.id}`, itemNames.order, {
            failed: 'orders.get.fail',
          });
        } else {
          return Promise.resolve();
        }
      })
      .then(this.onDismissInProgress).catch(this.onDismissInProgress);
  }

  onScanComplete(value) {
    if (value && value.length > 5) {
      const details = parseCreditCardDetails(value);
      if (details) {
        const {form} = this.props;
        this.props.actions.change(form, 'credit_card.number', details.card_number);
        this.props.actions.change(form, 'credit_card.exp_month', details.exp_month);
        this.props.actions.change(form, 'credit_card.exp_year', details.exp_year);
      } else {
        this.props.actions.addMessage(messageTypes.warning, 'cart.payment.card.scanFailed');
      }
      return true;
    }
  }

  render() {

    const {submitting, isPosabitOnline} = this.state;
    const {
      prepackWeights, order, getOrderProductCoupons, couponIsAppliedToAllProducts, salesComplianceSettings, customer, hasApplyRewardCouponsPermission,
      allowCreditCard, rewardsEnabled, rewardsMode, isOverride, isOnfleetEnabled, initialValues, isMoneris, isHypur, isAeropayIntegrated, hypurCheckedInUsers,
      isAnonymousOrder, orderSettings, isMetrc, metrcSalesCustomerTypes, isPatientTypeDropdownToggled, isAeroPayToggled, isAeropayIntegratedToggled,
      isPosabitToggled, isAlleavesIntegratorEnabled, isAlleavesToggled, isHypurToggled, getFormValue, register, alleavesMode
    } = this.props;

    const printBeforePayment = (salesComplianceSettings && salesComplianceSettings.order_print_labels_and_receipts_without_payment)
    ? parseInt(salesComplianceSettings.order_print_labels_and_receipts_without_payment.value) === 1
      : false;

    // Conditionally render payments or out for delivery or pickup
    const path = this.props.route.path;
    const pageDisplayType = (path.indexOf('payment') !== -1)
    ? 'payment'
      : (path.indexOf('delivery') !== -1)
        ? 'delivery'
        : 'pickup';

    const labelForAutoPrint = get(orderSettings, 'order_auto_printing.value.default_label');

    const containsPendingPosabitPayment = order && order.payments
      ? !!order.payments.find((payment) => payment.payment_type === 'posabit' && payment.is_pending && !payment.is_cancelled)
      : false;

    const time = Date.now() - this.state.showAmountDueWarningStart;
    const minutes = Math.floor((time / 1000 / 60) % 60).toString();
    const seconds = Math.floor((time / 1000) % 60).toString().padStart(2, '0');
    const pendingPosabitPaymentTimer = minutes + ':' + seconds;

    return (<div className='payment-page'>
      <Modal show={this.state.showCancelConfirm} dialogClassName='cancel-modal'
             onHide={() => this.setState({showCancelConfirm: false, confirmCancel: false})}>
        <Modal.Header closeButton>
          <Modal.Title>
            {I18n.t('cart.partialPaymentCancelConfirm.header')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {I18n.t('cart.partialPaymentCancelConfirm.body')}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='danger' onClick={this.cancelOrder}>
            {I18n.t('cart.partialPaymentCancelConfirm.confirm')}
          </Button>
          <Button onClick={() => this.setState({showCancelConfirm: false, confirmCancel: false})}>
            {I18n.t('cart.partialPaymentCancelConfirm.cancel')}
          </Button>
        </Modal.Footer>
      </Modal>

      <CancelOrder
        event={this.state.cancelOrderEvent}
        customerId={this.state.cancelOrderCustomerId}
        orderId={this.state.cancelOrderOrderId}
        eventListeners={this.state.cancelOrderEventListeners}
      />

      <LabelPrinter
        showModal={this.state.showModal}
        onHide={this.onHideModal}
        labelTag='patient_label_large'
        labelForAutoPrint={labelForAutoPrint}
        payload={this.state.payload}
        autoPrint={this.state.autoPrint}
        onAutoPrintComplete={this.state.onAutoPrintComplete}
        onFallbackToModal={this.onFallbackToModal}
      />

      {/*Actually this is the receipt printer - these should be renamed*/}
      <LabelPrinter
        showModal={this.state.viewReceipt}
        onHide={this.onHideModal}
        labelTag='RETAIL_RECEIPT'
        autoPrint={this.state.autoPrintReceipt}
        onAutoPrintComplete={this.state.onAutoPrintComplete}
        onFallbackToModal={this.onFallbackToModal}
        qzTrayOff={true}
      />

      <InProgressOverlay
        isActive={this.state.showInProgress}
        message={this.state.inProgressMessage}
        onDismiss={this.onDismissInProgress}
        translate={false} />

      <ProductsHeader
        props={this.props}
        context={this.context}
        showProfile={!!get(this.props,'customer.id',false)}
        showCart={false}
        orderProducts={order.products}
        paymentComplete={this.props.order.payment_complete === '1'}
      />

      <h1>{I18n.t('cart.payment.payment')}</h1>
      <Row>
        <Col md={9} xs={12}>
          <PaymentItems
            items={this.props.order.products}
            pricingWeights={this.props.pricingWeights}
            prepackWeights={prepackWeights}
            order={this.props.order}
            paymentComplete={this.state.paymentComplete || this.props.order.payment_complete === '1'}
            getOrderProductCoupons={getOrderProductCoupons}
            couponIsAppliedToAllProducts={couponIsAppliedToAllProducts}
            printBeforePayment={printBeforePayment}
            printLabel={this.printLabel}/>


          <Row className='coupons-cart-totals'>
            <Col xs={12} md={6} lg={4}>
              {this.props.appliedCoupons.length ? (
                <Coupons removeCoupon={this.props.actions.removeCoupon}
                         coupons={this.props.appliedCoupons}
                         removeMmapPayment={this.removeMmapPayment}
                         mmapPayments={this.props.mmapPayments}
                />
              ) : null}
              {this.props.order.payments && this.props.order.payments.length ?
                <OrderTransactions
                  timezone={this.props.timezone}
                  payments={this.props.order.payments}
                  alleavesMode={this.props.alleavesMode}/> :
                null}
            </Col>
            <Col xs={12} md={6} lg={8}>
              <PaymentTotals
                order={order}
                viewReceipt={this.viewReceipt}
                printJob={this.props.printJob}
                showPrinterSettings={this.showPrinterSettings}
                paymentComplete={this.state.paymentComplete || this.props.order.payment_complete === '1'}
                printReceipt={this.printReceipt}
                printAllLabels={this.printAllLabels}
                subTotal={this.props.order.order_subtotal}
                tax={parseFloat(this.props.order.tax_total)}
                taxableTotal={this.props.order.taxable_total}
                manualDiscountsTotal={parseFloat(this.props.order.discount_total)}
                total={this.props.order.order_total}
                couponsTotal={this.props.order.coupon_total}
                taxes={order.taxes}
                printBeforePayment={printBeforePayment}
                editOrder={this.editOrder}
                onCancelOrder={this.onCancelOrder}
                cancelOrder={this.cancelOrder}
                confirmCancel={this.state.confirmCancel}
                pageDisplayType={pageDisplayType}
                validCureLimits={this.props.validCureLimits}
                isOrderFulfilled={this.props.isOrderFulfilled}
                onPaymentOrderClick={this.onPaymentOrderClick}
                editFulfillment={this.editFulfillment}
                submitInProgress={submitting}
                taxRate={parseFloat(this.props.order.tax_total) / parseFloat(this.props.order.order_subtotal)}
              />
            </Col>
          </Row>
        </Col>
        {
          pageDisplayType === 'pickup'
          ? <ConnectedOrderMetaForm onSubmit={this.onSubmitOrderMeta} />
            : <Col md={3} xs={12}>
            {
              pageDisplayType === 'payment'
                ? <div><Payment
                form={formName}
                customer={customer}
                submitInProgress={submitting}
                order={this.props.order}
                paymentComplete={this.props.order.payment_complete === '1'}
                initialValues={initialValues}
                editOrder={this.editOrder}
                showHypurPACModal={this.state.showHypurPACModal}
                confirmCancel={this.state.confirmCancel}
                onCancelOrder={this.onCancelOrder}
                cancelOrder={this.cancelOrder}
                paymentOptions={this.props.paymentOptions}
                allowCreditCard={allowCreditCard}
                isMoneris={isMoneris}
                isHypur={isHypur}
                isMetrc={isMetrc}
                isAeropayIntegrated={isAeropayIntegrated}
                hypurCheckedInUsers={hypurCheckedInUsers}
                showPoints={rewardsEnabled && rewardsMode === 'currency' && hasApplyRewardCouponsPermission}
                isOverride={isOverride}
                onScanComplete={this.onScanComplete}
                onSubmit={this.onSubmit}
                onHideHypurPACModal={this.onHideHypurPACModal}
                onHypurPaymentChange={this.onHypurPaymentChange}
                onSubmitHypurPayment={this.onSubmitHypurPayment}
                onRememberCheckedInUser={this.onRememberCheckedInUser}
                onHypurSelectedUserChange={this.onHypurSelectedUserChange}
                disableCheckedInUsers={this.state.disableCheckedInUsers}
                metrcSalesCustomerTypes={metrcSalesCustomerTypes}
                isAnonymousOrder={isAnonymousOrder}
                isPatientTypeDropdownToggled={isPatientTypeDropdownToggled}
                isAeroPayToggled={isAeroPayToggled}
                isAeropayIntegratedToggled={isAeropayIntegratedToggled}
                isPosabitToggled={isPosabitToggled}
                isPosabitOnline={isPosabitOnline}
                isAlleavesToggled={isAlleavesToggled}
                isAlleavesIntegratorEnabled={isAlleavesIntegratorEnabled}
                isHypurToggled={isHypurToggled}
                isShowAeropayintegratedModal={this.state.showAeropayintegratedModal}
                showAeropayintegratedModal={this.showAeropayintegratedModal}
                hideAeropayintegratedModal={this.hideAeropayintegratedModal}
                onSubmitAeropayintegratedAmount={this.onSubmitAeropayintegratedAmount}
                orderSettings={orderSettings}
                getFormValue={getFormValue}
                change={change}
                register={register}
                alleavesMode={alleavesMode}
              />
                <div style={{maxWidth: '300px'}}>
                  <ConnectedOrderMetaForm onSubmit={this.onSubmitOrderMeta} isOnfleetEnabled={isOnfleetEnabled} />
                </div>
              </div>
                : <ConnectedOrderMetaForm onSubmit={this.onSubmitOrderMeta} customer={customer} pageDisplayType={pageDisplayType} required={['driver_id', 'delivery_address_id', 'onfleet_phone_number']} isOnfleetEnabled={isOnfleetEnabled} />

            }


          </Col>
        }
        <ModalWrapper
          Component={false}
          version={2}
          onHide={() => this.setState({paymentCreditCardErrorMessage: false})}
          showModal={this.state.paymentCreditCardErrorMessage}
          title='cart.payment.paymentErrors'
          okayButton={{show: true, text: 'common.close', variant: 'default'}}
        >
          <div>{this.state.paymentCreditCardErrorMessage}</div>
        </ModalWrapper>
        <ModalWrapper
          showModal={this.state.showAmountDueWarning}
          Component={false}
          version={2}
          onHide={() => this.setState({showAmountDueWarning: false})}
          title={containsPendingPosabitPayment ? 'cart.payment.pendingPayment' : 'cart.payment.payment'}
          okayButton={{show: true, text: 'common.ok', variant: 'primary'}}
        >
          <div>
            {containsPendingPosabitPayment
              ? I18n.t('cart.payment.pendingPaymentWarning')
              : I18n.t('cart.payment.amountDueWarning')
            }
            {containsPendingPosabitPayment
              ? (<div style={{fontWeight: 'bold'}}>{pendingPosabitPaymentTimer}</div>)
              : null
            }
          </div>
        </ModalWrapper>
        <ModalWrapper
          showModal={this.state.showInactiveLabelWarning}
          Component={false}
          version={2}
          onHide={() => this.setState({showInactiveLabelWarning: false})}
          title='cart.payment.autoPrinting'
          okayButton={{show: true, text: 'cart.payment.proceedToPrint', variant: 'primary', onClick: () => this.printAllLabels(null, false)}}
          cancelButton={{show: true, text: 'common.close', variant: 'default'}}
        >
          <div>{I18n.t('cart.payment.inactiveLabelWarning')}</div>
        </ModalWrapper>
      </Row>
    </div>);
  }
}

PaymentPage.propTypes = {
  coupons: PropTypes.array.isRequired,
  appliedCoupons: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  inventoryItems: PropTypes.array.isRequired,
  order: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  pricingWeights: PropTypes.array.isRequired,
  prepackWeights: PropTypes.array.isRequired,
  amountTendered: PropTypes.number.isRequired,
  amountDue: PropTypes.number.isRequired,
  changeDue: PropTypes.number.isRequired,
  paymentOptions: PropTypes.array.isRequired,
  productsWithComplianceWeight: PropTypes.array,
  timezone: PropTypes.string.isRequired,
  getOrderProductCoupons: PropTypes.func.isRequired,
  couponIsAppliedToAllProducts: PropTypes.func.isRequired,
  salesComplianceSettings: PropTypes.object.isRequired, // this should be in config of receipt and pos but until we have that, settings are in sales
  allowCreditCard: PropTypes.bool,
  isMoneris: PropTypes.bool,
  isLeaf: PropTypes.bool,
  isCure: PropTypes.bool,
  isOhMetrc: PropTypes.bool,
  isMetrc: PropTypes.bool,
  rewardsEnabled: PropTypes.bool,
  rewardsMode: PropTypes.string,
  rewardsPointsToCurrency: PropTypes.number,
  hasApplyRewardCouponsPermission: PropTypes.bool,
  initialValues: PropTypes.object,
  metrcSalesCustomerTypes: PropTypes.array,
  isPatientTypeDropdownToggled: PropTypes.bool,
  isAeroPayToggled: PropTypes.bool,
  isAeropayIntegratedToggled: PropTypes.bool,
  isPosabitToggled: PropTypes.bool,
  isAlleavesIntegratorEnabled: PropTypes.bool,
  isAlleavesToggled: PropTypes.bool,
  isHypurToggled: PropTypes.bool,
  getFormValue: PropTypes.func,
  terminal_id: PropTypes.number,
  register: PropTypes.object,
  alleavesMode: PropTypes.string,
};

const selector = formValueSelector('payment-form');

function mapStateToProps(state) {
  const {coupons, items, pricingWeights} = state.cart;
  const {inventoryItems, customer, prepackWeights, timezone} = state;
  const taxes = getOrderTaxes(state);
  const catalog = getCatalogItemMastersWithPrice(state);
  const order = getOrderWithRichProducts(state);
  const updatedItems = items ? items.map((item) => {
    const catalogItem = catalog[item.item_master_id];
    item.src = getImageUrl(catalogItem && catalogItem.primary_image_file, '50x50', LEAF);
    return item;
  }) : [];

  order.order_subtotal = order.order_subtotal ? order.order_subtotal : getOrderSubtotal(state);
  order.taxable_total = order.taxable_total ? order.taxable_total : getTaxableTotal(state);

  order.products = order.products ? order.products.map((product, index) => {
    const catalogProd = catalog.find(c => c.id === product.item_master_id);
    product.src = getImageUrl(catalogProd && catalogProd.primary_image_file, '50x50', LEAF);
    if(product.items.length > 0){
      product.package_id = product.items[0].package_id;
    }
    return product;
  }) : [];

  const hypurCheckedInUsers = state[dataNames.hypurCheckedInUsers];

  const rewardsEnabled = getRewardsEnabledState(state);
  const rewardsMode = getRewardsMode(state);
  const rewardsPointsToCurrency = getRewardsPointsToCurrencyConversion(state);
  const paymentOptions = getPaymentOptions(state);
  const {isLeaf, isCure, isOhMetrc, isHypur, isMetrc, isAeropayIntegrated} = getIntegrationState(state);
  const isOnfleetEnabled = isOnfleetIntegrator(state);
  const isMoneris = getIsMonerisProcessor(state);
  // Use the revised authorization amount for default value if it exists
  let authorization_amount = get(order, 'revised_authorization_amount')
    ? get(order, 'revised_authorization_amount', 0)
    : get(order, 'authorization_amount', 0);
  // If the actual order total is less than the authorization amount, reduce the authorization_amount to the order_total
  authorization_amount = parseFloat(order.order_total) < parseFloat(authorization_amount)
    ? order.order_total
    : authorization_amount;
  const initialValues = {
    ...initialState,
    ...(isMoneris && {
      credit_card: {
        street_name: get(customer, 'addresses.0.street_address_1'),
        street_number: get(customer, 'addresses.0.street_address_2')
      }
    }),
    ...(isHypur && {
      remember_hypur_user: false,
    }),
    // preload AeroPay Integrated amount if AeroPay Integrated is enabled and an auth code and auth amount is present
    ...(isAeropayIntegrated
      && (
        get(order, 'authorization_code') && get(order, 'authorization_amount')
        || (get(order, 'revised_authorization_code') && get(order, 'revised_authorization_amount'))
      ) && {
        // Take the lowest of taxable_total and authorization_amount as initial value (in case the order was reduced)
        aeropayintegrated: authorization_amount
      })
  };
  const getFormValue = (...names) => selector(state, ...names);

  const currentRegisterId = get(state.user, 'currentRegister');
  const register = state[dataNames.registers].find(r => r.id === currentRegisterId);

  return {
    timezone,
    coupons,
    appliedCoupons: getAppliedCouponsById(state.cart),
    mmapPayments: getOrderMmapPayments(state),
    updatedItems,
    inventoryItems,
    order: {...order, taxes},
    user: state.user,
    customer,
    pricingWeights,
    prepackWeights,
    hypurCheckedInUsers,
    isAnonymousOrder: getIsOrderAnonymous(state),
    isOrderFulfilled: isOrderFulfilled(state),
    validCureLimits: isValidLimits(state, {customer, mode: 'customer'}),
    amountTendered: 0, // initialize to 0 so when the default payment methods load the user can't input an invalid payment method
    amountDue: getAmountDue(state.cart),
    changeDue: getChangeDue(state.cart),
    paymentOptions,
    salesComplianceSettings: state[itemNames.salesComplianceSettings],
    getOrderProductCoupons: product => getOrderProductCoupons(state, product),
    couponIsAppliedToAllProducts: coupon => couponIsAppliedToAllProducts(state, coupon),
    drivers: state[dataNames.drivers],
    allowCreditCard: isCreditCardPaymentEnabled(state),
    isLeaf,
    isCure,
    isOhMetrc,
    isHypur,
    isMetrc,
    isAeropayIntegrated,
    rewardsEnabled,
    rewardsMode,
    rewardsPointsToCurrency,
    hasApplyRewardCouponsPermission: hasPermApplyRewardCoupons(state),
    isAutoPrintEnabled: isAutoPrintEnabled(state),
    printJob: state[itemNames.printJob],
    isOnfleetEnabled,
    isMoneris,
    orderSettings: getOrderSettings(state),
    labelTags: getLabelTags(state),
    metrcSalesCustomerTypes: getMetrcSalesCustomerTypesOptions(state),
    isPatientTypeDropdownToggled: isFeatureEnabled(state)('feature_patient_type_dropdown_retail'),
    isAeroPayToggled: isFeatureEnabled(state)('feature_aeropay'), // for manual aeropay payment
    isAeropayIntegratedToggled: isFeatureEnabled(state)('feature_aeropay_integration'), // for aeropayintegrated payment
    isPosabitToggled: isFeatureEnabled(state)('feature_posabit_payment_integration'),
    isAlleavesToggled: isFeatureEnabled(state)('feature_alleaves_payment_integration'),
    isHypurToggled: isFeatureEnabled(state)('feature_hypur'),
    initialValues,
    getFormValue: getFormValue,
    terminalId: get(register, 'terminal_id'),
    isPosabitIntegratorEnabled: isPosabitIntegrator(state),
    isAlleavesIntegratorEnabled: isAlleavesIntegrator(state),
    register,
    alleavesMode: getAlleavesMode(state)(register)
  };
}

function mapDispatchToProps(dispatch) {

  const submitForm = () => dispatch => dispatch(submit(formName));
  const actions = Object.assign({}, cartActions, apiActions, {
    goBack,
    push,
    unsetItem,
    setItem,
    reset,
    addMessage,
    change,
    submit: submitForm,
    closeCustDetails,
  });

  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPage);
