/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import map from 'lodash.map';
import { connect } from 'react-redux';
import {I18n} from 'react-redux-i18n';
import { bindActionCreators } from 'redux';
import {goBack, push} from 'react-router-redux';
import {change} from 'redux-form';
import {addMessage} from '../../../../actions/systemActions';
import {getItemMasterChildren as getItemMasterChildrenAction} from '../../../../actions/itemMasterActions';
import {setRules} from '../../../../actions/ruleActions';
import rules from '../../purchase-orders/RenderRules';
import {getUnpaginatedData, getItem, postItem, putItem, getSearchData, getDataBatchByPost, getDataByPost, getDataBatch} from '../../../../actions/apiActions';
import {setData, unsetData, unionData} from '../../../../actions/dataActions';
import {unsetItem} from '../../../../actions/itemActions';
import {handleComplexSelectRow} from '../../../../actions/helpers/selectedDataHelper';
import {
  getItemMasterChildren,
  getItemMasterWithPricing,
  getSalesOrderItemMasterById,
  getItemMasterInfo
} from '../../../../selectors/itemMastersSelectors';
import {getPartnerFacilities} from '../../../../selectors/partnersSelectors';
import {getAvailableRegisters, getRegisterById} from '../../../../selectors/registersSelectors';
import {
  getOrderTypeOptions,
  getSalesOrderForModifyPage,
  getTransferLinesLimit,
  getIsSalesOrderMedicated
} from '../../../../selectors/salesOrdersSelectors';
import {getCurrentFacilityId} from '../../../../selectors/facilitiesSelectors';
import {
  getRenderFacts,
  isLinkedSalesOrder,
  salesOrderConnectsStatus,
  getInitialValues,
  getOrderType,
  getPartnerFacility, getIsActivePartner,
} from '../../../../selectors/forms/salesOrderFormSelectors';
import {getCurrentFacilityUserOptions} from '../../../../selectors/usersSelectors';
import * as p from '../../../../constants/permissions';
import flattenLocations from '../../../../util/flattenLocations';
import * as dataNames from '../../../../constants/dataNames';
import * as itemNames from '../../../../constants/itemNames';
import * as messageTypes from '../../../../constants/messageTypes';
import {SALES_ORDER_FORM} from '../../../../constants/forms';
import OrderForm from '../common/OrderForm'; //eslint-disable-line import/no-named-as-default
import FormWrapper from '../../../common/form/FormWrapper';
import InProgressOverlay from '../../../common/InProgressOverlay';
import {getIntegrationState} from '../../../../selectors/integration/integrationSelectors';
import {makeQueryString} from '../../../../util/dataHelpers';
import * as inventory from '../../../../selectors/inventory';
import * as complianceSettings from '../../../../selectors/complianceSettingsSelectors';
import ModalWrapper from '../../../common/ModalWrapper';
import {getReservationsQtyByItemMaster} from '../../../../selectors/reservationsSelectors';
import {isMappingRequiredForSupply} from '../../../../selectors/supplySelectors';
import {preparePayload} from '../common/helpers';
import DraftManager from '../../../../managers/draft';



export class ModifySalesOrderPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.state = {
      partner: null,
      itemFromSolr: false,
      isReady: false,
      saving: false,
      showInactivePartnerMessage: false,
      salesOrderSaved: false,
      matchingOrderWarnings: false,
      touchedIM: {},
      showNegativeInvConfirmation: {},
    };
    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onOpenTransfer = this.onOpenTransfer.bind(this);
    this.getItemsInventories = this.getItemsInventories.bind(this);
    this.onChangeLocalState = this.onChangeLocalState.bind(this);
    this.showInactivePartnerMessage = this.showInactivePartnerMessage.bind(this);
    this.renderConfirmModal = this.renderConfirmModal.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.showConfirmationPopup = this.showConfirmationPopup.bind(this);
    this.validateNegativeInventory = this.validateNegativeInventory.bind(this);
    this.onTransfer = this.onTransfer.bind(this);
    this.onConnectsResponse = this.onConnectsResponse.bind(this);
    this.onBack = this.onBack.bind(this);
    this.addTouchedIM = this.addTouchedIM.bind(this);
  }

  componentWillMount() {
    const {params: {id}} = this.props;
    this.setState({hasSelectedItemMasters: false});
    this.props.actions.unsetData(dataNames.itemMasters);
    this.props.actions.unsetItem(itemNames.salesOrder);

    const normalizeItemMasters = (itemMasters) => {
      return itemMasters.map((itemMaster) => {
        itemMaster.inventory_attributes = {
          is_prepack: itemMaster.is_prepack,
          item_master_parent_id: itemMaster.item_master_parent_id,
          prepack_weight_id: itemMaster.prepack_weight_id,
          lot_tracked: itemMaster.lot_tracked,
          is_ingredient: get(itemMaster, 'is_ingredient', 0),
        };
        itemMaster.net_weight_base = get(itemMaster, 'net_weight_base', 0).toFixed(2);
        itemMaster.default_cost = get(itemMaster, 'default_cost', 0).toFixed(2);
        return itemMaster;
      });
    };

    Promise.all([
      this.props.actions.handleComplexSelectRow(undefined, dataNames.itemMasters, 'clear'),
      this.props.actions.getItem(
        '/api/cultivation/settings',
        itemNames.wasteCompliance,
        {failed: 'cultivation.wasteCompliance.get.failed'},
        {ids: ['cult_waste_destruction_compliance_settings', 'inv_waste_packaging', 'distributions_transfer_line_limit']}
      ),
      this.props.actions.getItem(`/api/sales_orders/${id}`, itemNames.salesOrder, {failed: 'salesOrders.getSalesOrder.failed'}, {detailed: true}, (salesOrder) => {
        if(salesOrder.transfer_records.length) { // Get transfer so we can provide some detail
          const messages = {failed: 'cultivation.transfers.getTransfer.failed'};
          const last_transfer = salesOrder.transfer_records[salesOrder.transfer_records.length - 1];
          const url = `/api/transfers/${last_transfer.transfer_id}`;
          this.props.actions.getItem(url, itemNames.transfer, messages, {});
        }

        const collectIDs = (acc, line) => acc.concat(map(get(line, 'subitems'), 'item_master_id'), [line.item_master_id]);
        const salesOrderItemMasterIds = get(salesOrder, 'lines', []).reduce(collectIDs, []);

        if(salesOrderItemMasterIds.length) {
          this.getItemsInventories(salesOrderItemMasterIds);
        }

        this.props.actions.getDataBatch('/api/product_reservations', dataNames.reservations, null, {in_item_master_ids: salesOrderItemMasterIds});

        // Get itemMasters from solar
        const params = {
          sort: 'name asc, display_name asc',
          query: '',
          size: '100000',
          start: '0',
          filter: '(active:1 OR item_active:true) AND is_draft:0 AND item_master_parent_id:0',
          fields: [
            'id',
            'name',
            'display_name',
            'is_medicated',
            'category_id',
            'lot_tracked',
            'is_prepack',
            'item_master_parent_id',
            'prepack_weight_id',
            'is_sales_item',
            'subcategory_code',
            'uom_type',
            'default_uom',
            'active',
            'is_inventory_item',
            'item_active',
          ],
        };

        this.props.actions.getSearchData('/api/search/item_masters', null, null, params, (itemMasters) => {
          if(itemMasters.length === 0) return this.props.actions.addMessage(messageTypes.error, 'products.get.failed');
          // Shape solr response to match inventory response for item_masters
          const masters = normalizeItemMasters(itemMasters);

          // Get item master children for prepacks
          const prepackParentIds = salesOrderItemMasterIds.filter((itemMasterId) => {
            const itemMaster = masters.find((master) => master.id === itemMasterId);
            return get(itemMaster, 'is_prepack');
          });

          // Set the item masters manually
          this.props.actions.setData(masters, dataNames.itemMasters).then(() => {
            // Get the prepack children
            if(prepackParentIds.length) {
              this.props.actions.getItemMasterChildrenAction(prepackParentIds)
                .then(() => {
                    const itemMasterIds = salesOrderItemMasterIds.concat(prepackParentIds.reduce((acc, parentId) => {
                      return acc.concat(this.props.getItemMasterChildren({id: parentId}).map((child) => child.id));
                    }, []));
                    this.getItemsInventories(itemMasterIds);
                });
            } else {
              this.getItemsInventories(salesOrderItemMasterIds);
            }

            const params = {filter: makeQueryString('id', salesOrderItemMasterIds), size: 100000, query: ''};
            this.props.actions.getSearchData('/api/search/item_masters', dataNames.inactiveItemMasters, null, params, (rawItemMasters) => {
              const itemMasters = normalizeItemMasters(rawItemMasters);
              this.props.actions.unionData(itemMasters, dataNames.itemMasters);
            });
          });
        });
      }, {cache: false}),

      this.props.actions.getUnpaginatedData('/api/partners', dataNames.partners, {failed:'partners.get.failed'}, {exclude_internal_duplicates: 1}, undefined, {debounce: true}),
      this.props.actions.getUnpaginatedData('/api/payment_terms/payment_terms_list', dataNames.paymentTerms, {failed:'paymentTerms.get.failed'}, undefined, undefined, {debounce: true}),
      this.props.actions.getUnpaginatedData('/api/payment_types', dataNames.paymentTypes, {failed:'paymentTypes.get.failed'}, undefined, undefined, {debounce: true}),
      this.props.actions.getDataByPost('/api/registers/ensure', {type: 'ap_ar'}, dataNames.registers, {failed:'registers.getRegisters.fail'}, null, () => {}, null, {debounce: true}),
      this.props.actions.getItem('/api/compliance_settings', itemNames.complianceSettings),
      this.props.actions.getItem('/api/supply/settings', itemNames.supplySettings),
      this.props.actions.getItem('/api/distribution/settings', itemNames.distributionSettings),
      this.props.actions.getUnpaginatedData('/api/partner_facilities', dataNames.partnerFacilities, {failed:'partnerFacilities.get.failed'}, undefined, undefined, {debounce: true}),
      this.props.actions.getUnpaginatedData('/api/location_hierarchy', dataNames.locations, {failed:'locations.getLocations.failed'}, undefined, undefined, {debounce: true}),
      this.props.actions.getUnpaginatedData('/api/prepack_weights/facility', dataNames.prepackFacilityWeights, {failed:'packaging.getPrepackWeights.failed'}, {active: 1}, undefined, {debounce: true}),
      this.props.actions.getUnpaginatedData('/api/prepack_weights', dataNames.prepackWeights, {failed:'packaging.getPrepackWeights.failed'}, undefined, undefined, {debounce: true}),
      this.props.actions.getItem('/api/integration-settings', itemNames.integrationSettings, {failed: 'stateIntegratorSettings.get.failed'}, undefined, undefined, {debounce: true}),
      this.props.actions.getUnpaginatedData('/api/categories', dataNames.categories),
      this.props.actions.getItem('/api/facilities/' + this.props.currentFacilityId + '/details', itemNames.facilityDetailed).then((data) => {
        const has_leaf_pa_config_pack_and_closed_loop = (typeof data.has_leaf_pa_config_pack_and_closed_loop === 'undefined') ? false : data.has_leaf_pa_config_pack_and_closed_loop;

        this.props.actions.setData(has_leaf_pa_config_pack_and_closed_loop, dataNames.has_leaf_pa_config_pack_and_closed_loop);

        const has_leaf_utah_config_pack_and_closed_loop = (typeof data.has_leaf_utah_config_pack_and_closed_loop === 'undefined') ? false : data.has_leaf_utah_config_pack_and_closed_loop;

        this.props.actions.setData(has_leaf_utah_config_pack_and_closed_loop, dataNames.has_leaf_utah_config_pack_and_closed_loop);

        const vendor_facility_type = (typeof data.type === 'undefined') ? '' : data.type;

        const format_vendor_facility_type = (vendor_facility_type) => {
          if(vendor_facility_type === 'grow') {
            return 'cultivator';
          }else if (vendor_facility_type === 'manufacturing') {
            return 'processor';
          }else {
            return vendor_facility_type;
          }
        };

        const formatted_vendor_facility_type = format_vendor_facility_type(vendor_facility_type);

        this.props.actions.setData(formatted_vendor_facility_type, dataNames.vendor_facility_type);
    }),
      this.props.actions.getUnpaginatedData('/api/users/current_facility', dataNames.currentFacilityUsers, {failed: 'packaging.getUsers.failed'}),
      this.props.actions.getUnpaginatedData('/api/sales_orders/sample_types', dataNames.sampleTypes),
      this.props.actions.getUnpaginatedData('/api/sales_orders/product_sample_types', dataNames.productSampleTypes),
      this.props.actions.getUnpaginatedData('/api/closed_loops/own', dataNames.closedLoops),
    ]).then(() => this.setState({isReady: true}))
      .then(() => this.showInactivePartnerMessage())
      .catch(() => this.setState({isReady: true}));
  }

  componentWillReceiveProps(nextProps){
    this.props.actions.setRules(rules)
      .then((engine) => {
        engine.setFacts(nextProps.renderFacts);
      });
  }

  componentWillUnmount(){
    this.props.actions.unsetItem(itemNames.salesOrder);
    this.props.actions.unsetData(dataNames.itemMasters);
  }

  addTouchedIM(id) {
    const currentState = this.state.touchedIM;
    currentState[id] = true;
    this.setState({touchedIM: currentState});
  }


  showInactivePartnerMessage() {
    if (this.props.isActivePartner === 0 && !this.state.showInactivePartnerMessage) {
      this.props.actions.addMessage(messageTypes.error, 'salesOrders.form.inactivePartner');
      this.setState({showInactivePartnerMessage: true});
    }
  }


  getItemsInventories(ids){
    this.props.actions.getDataBatchByPost(
      '/api/items/by_item_master_ids',
      {ids},
      dataNames.inventoryItems,
      {failed: 'salesOrders.form.failedToGetInventory'},
      null,
      () => {},
      null,
      'id' // was item_master_id which caused some data to be discarded
    );
  }


  onCancel() {
    const {params: {id}} = this.props;
    this.props.actions.putItem(`/api/sales_orders/${id}`,
      {payment_status: 'cancelled'},
      'noOp',
      {failed: 'salesOrders.modify.failed', success: 'salesOrders.modify.cancelled'},
      null,
      () => {
        this.props.actions.push('/sales-orders');
      }
    );
  }

  onOpenTransfer(id){
    this.props.actions.push(`/transfers/modify/${id}`);
  }

  onChangeLocalState(field, value){
    return new Promise((resolve) => {
      this.setState(Object.assign({}, this.state, {[field]: value}), () => {
        resolve();
      });
    });
  }

  onTransfer(){
    const {salesOrder} = this.props;
    const transfers = get(salesOrder, 'transfers', []);
    if (Array.isArray(transfers)) {
      if (transfers.some(t => t.status !== 'cancelled')) {
        this.props.actions.addMessage(messageTypes.error, 'salesOrders.form.transferAlreadyCreatedOnCompleteTransfer');
        return false;
      }
    }
    this.props.actions.unsetData(dataNames.salesOrders);
    this.props.actions.push({pathname: '/transfers/create', query: {so_num: [this.props.salesOrder.id]}});
  }

  onBack(){
    this.props.actions.goBack();
  }

  onSubmit(formData) {
    if (this.state['afterSubmit'] === 'completeTransfer') {
      const {salesOrder} = this.props;
      const transfers = get(salesOrder, 'transfers', []);
      if (Array.isArray(transfers)) {
        if (transfers.some(t => t.status !== 'cancelled')) {
          this.props.actions.addMessage(messageTypes.error, 'salesOrders.form.transferAlreadyCreatedOnCompleteTransfer');
          return false;
        }
      }
    }

    const payload = preparePayload(formData, false, this.props);

    return Promise.resolve(payload)
      .then(this.validateNegativeInventory)
      .then(this.sendRequest);
  }

  validateNegativeInventory(payload) {
    return new Promise((resolve, reject) => {
      const next = () => resolve(payload);
      const handleOrderLines = (result, orderLine) => {
        const inventories = orderLine.subitems && orderLine.subitems.length
          ? this.props.getInventoryBy('item_master_parent_id', orderLine.item_master_id)
          : this.props.getInventoryBy('item_master_id', orderLine.item_master_id);
        const requestedQnty = parseFloat(orderLine.qty);
        const availableQnty = inventories.reduce((amount, { qty }) => amount + qty, 0);
        const reservedQty = this.props.getQtyReserved(orderLine.item_master_id, this.props.salesOrder.id);
        if (requestedQnty > availableQnty - reservedQty) {
          result = true;
        }

        return result;
      };

      const showPopup = payload.lines.reduce(handleOrderLines, false);

      if (showPopup && this.props.isAllowNegativeInventory) {
        return this.showConfirmationPopup(next, reject);
      }

      return next();
    });
  }

  sendRequest(payload, connectsResponseHandled = false) {
    const { params } = this.props;

    if(payload.connectsResponse && typeof connectsResponseHandled !== typeof true){ // Second arg is func when not forced
      this.onConnectsResponse(payload);
      return false;
    }

    this.setState({saving: true});


    return this.props.actions.putItem(`/api/sales_orders/${params.id}`,
      payload,
      'noOp',
      {failed: 'salesOrders.modify.failed', success: 'salesOrders.modify.success'},
      undefined,
      (data) => {
        this.props.actions.handleComplexSelectRow([data], dataNames.salesOrders, 'set');
        if(data.warnings){
          this.setState({saving: false, matchingOrderWarnings: data.warnings, salesOrderSaved: true});
          return false;
        }
        this.setState({saving: false});

        // Bust the cached DraftManager here with any changes to the Sales Order
        DraftManager.clearDraft('transfer', data.sales_order_number);

        if(this.state.afterSubmit === 'completeTransfer'){
          this.props.actions.unsetData(dataNames.salesOrders);
          this.props.actions.push({pathname: '/transfers/create', query: {so_num: data.id}});
        } else {
          this.props.actions.goBack();
        }
      }
    ).catch(() => this.setState({saving: false}));
  }

  showConfirmationPopup(resolve, reject) {
    const hidePopup = (cb) => () => {
      this.setState({
        showNegativeInvConfirmation: { show: false },
      });
      cb();
    };
    this.setState({
      showNegativeInvConfirmation: {
        show: true,
        onConfirm: hidePopup(resolve),
        onHide: hidePopup(reject),
      }
    });
  }

  getTitle() {
    switch (this.props.orderType) {
      case 'lab':
        return 'salesOrders.modify.labTitle';
      case 'waste_disposal':
        return 'salesOrders.modify.wasteDisposalTitle';
      default:
        return 'salesOrders.modify.title';
    }
  }

  onConnectsResponse(formData){
    formData.lines.map((line) => {
      line.item_number = get(line, 'itemMaster.item_number', 0);
      return line;
    });
    const payload = {
      response: formData.connectsResponse,
      new_note: formData.new_note,
      partner_note: formData.partner_note,
      countered_po: formData.connectsResponse === 'amended' ?
        (({lines, order_total, discount_percent, transfer_fee, taxes}) => ({lines, order_total,  discount_percent, transfer_fee, taxes}))(formData) :
        undefined
    };
    this.props.actions.postItem(
      `/api/sales_orders/${this.props.params.id}/respond`,
      payload,
      'noOp',
      {failed: 'salesOrders.modify.failed', success: 'salesOrders.modify.success'})
      .then(() => {
        this.props.actions.goBack();
      });
  }

  renderConfirmModal() {
    const { showNegativeInvConfirmation } = this.state;

    const okayButtonProps = {
      show: true,
      onClick: showNegativeInvConfirmation.onConfirm,
      text: I18n.t('general.yes')
    };

    const cancelButtonProps = {
      show: true,
      onClick: showNegativeInvConfirmation.onHide,
      text: I18n.t('general.no')
    };

    return (
      <ModalWrapper
        Component={false}
        title={I18n.t('inventory.negativeInventoryAlert')}
        headerClass='bg-info-dark'
        onHide={showNegativeInvConfirmation.onHide}
        showModal={showNegativeInvConfirmation.show}
        okayButton={okayButtonProps}
        cancelButton={cancelButtonProps}
        dialogClassName='modal-sm'
        version={2}
      >
        <p>{I18n.t('inventory.confirmToGoNegative')}</p>
      </ModalWrapper>
    );
  }

  render () {
    const {partners, containers, locations, registers, paymentTerms, salesOrder,
      paymentTypes, partnerFacilities, getRegisterById, integrationState,
      selectedPrePackChildren, prePackTotalWeights, getSellableItemMasterById, inventoryList,
      orderTypes, users, transferLinesLimit, isLinkedSalesOrder, isMappingRequiredByPlatform, hasMedicatedLines,
      salesOrderConnectsStatus, renderFlags, isReservationEnabled, initialValues,transferStatus, has_leaf_pa_config_pack_and_closed_loop, has_leaf_utah_config_pack_and_closed_loop, vendor_facility_type, categories} = this.props;

    if(!this.state.isReady || !initialValues || !Object.keys(initialValues).length) return (
      <FormWrapper title={'salesOrders.modify.title'} goBack={this.props.actions.goBack} className='create-purchase-order'>
        <InProgressOverlay translate={true} isActive={!this.state.isReady} message='salesOrders.form.loading' />
      </FormWrapper>
    );

    return (
    <FormWrapper title={this.getTitle()} goBack={this.props.actions.goBack} className='create-purchase-order'>
      <InProgressOverlay translate={true} isActive={this.state.saving} message='salesOrders.form.saving' />
      <OrderForm
        form={SALES_ORDER_FORM}
        keepDirtyOnReinitialize={true}
        enableReinitialize={true}
        orderNumber={salesOrder.sales_order_number}
        transferRecord={salesOrder.transfer_record === null || transferStatus === 'cancelled' ? false : salesOrder.transfer_record}
        onSubmit={this.onSubmit}
        initialValues={initialValues}
        paymentTerms={paymentTerms}
        paymentTypes={paymentTypes}
        orderTypes={orderTypes}
        partnerFacilities={partnerFacilities}
        partners={partners}
        containers={containers}
        locations={locations}
        registers={registers}
        users={users}
        change={this.props.actions.change}
        actions={{getItem: this.props.actions.getItem, onTransfer: this.onTransfer, onBack: this.onBack}}
        facilityDetailed={this.props.facilityDetailed}
        selectedPrePackChildren={selectedPrePackChildren}
        prePackTotalWeights={prePackTotalWeights}
        showContainerInputs={false}
        showStorageLocations={false}
        showExistingNotes={true}
        getSellableItemMasterById={getSellableItemMasterById}
        getRegisterById={getRegisterById}
        existingNotes={salesOrder.notes}
        onCancel={this.onCancel}
        onOpenTransfer={this.onOpenTransfer}
        inventoryList={inventoryList}
        paymentStatus={salesOrder.payment_status}
        integrationState={integrationState}
        requireSalesOrder={true}
        onChangeLocalState={this.onChangeLocalState}
        matchingOrderWarnings={this.state.matchingOrderWarnings}
        salesOrderSaved={this.state.salesOrderSaved}
        transferLinesLimit={transferLinesLimit}
        isLinkedSalesOrder={isLinkedSalesOrder}
        salesOrderConnectsStatus={salesOrderConnectsStatus}
        renderFlags={renderFlags}
        addTouchedIM={this.addTouchedIM}
        touchedIM={this.state.touchedIM}
        isReservationEnabled={isReservationEnabled}
        isMappingRequiredByPlatform={isMappingRequiredByPlatform}
        hasMedicatedLines={hasMedicatedLines}
        has_leaf_pa_config_pack_and_closed_loop ={has_leaf_pa_config_pack_and_closed_loop}
        has_leaf_utah_config_pack_and_closed_loop = {has_leaf_utah_config_pack_and_closed_loop}
        vendor_facility_type = {vendor_facility_type}
        categories={categories}
      />
      {this.renderConfirmModal()}
    </FormWrapper>);
  }
}

ModifySalesOrderPage.propTypes = {
  actions: PropTypes.shape({
    addMessage: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired,
    setData: PropTypes.func.isRequired,
    getSearchData:PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    putItem: PropTypes.func.isRequired,
    handleComplexSelectRow: PropTypes.func.isRequired,
    getDataBatchByPost: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    unionData: PropTypes.func.isRequired,
    getDataBatch: PropTypes.func.isRequired,
    getItemMasterChildrenAction: PropTypes.func.isRequired
  }),
  params: PropTypes.shape({
    id: PropTypes.string.isRequired
  }),
  orderTypes: PropTypes.array.isRequired,
  partners: PropTypes.array.isRequired,
  partner: PropTypes.object.isRequired,
  itemMasterWithPricing: PropTypes.object.isRequired,
  partnerFacility: PropTypes.object.isRequired,
  partnerFacilities: PropTypes.array.isRequired,
  partnerFacilityOptions: PropTypes.array.isRequired,
  containers: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  registers: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  paymentTerms: PropTypes.array.isRequired,
  paymentTypes: PropTypes.array.isRequired,
  inventoryList: PropTypes.array.isRequired,
  salesOrder: PropTypes.object.isRequired,
  prePackTotalWeights: PropTypes.array,
  selectedPrePackChildren: PropTypes.array.isRequired,
  getSellableItemMasterById: PropTypes.func.isRequired,
  getPartnerFacilityByPartnerFacilityId: PropTypes.func.isRequired,
  getRegisterById: PropTypes.func.isRequired,
  itemMasters: PropTypes.array.isRequired,
  isReservationEnabled: PropTypes.bool,
  isMappingRequiredByPlatform: PropTypes.bool,
  hasMedicatedLines: PropTypes.bool,
  integrationState: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  isActivePartner: PropTypes.number,
  transferLinesLimit: PropTypes.number.isRequired,
  renderFlags: PropTypes.object,
  renderFacts: PropTypes.object,
  getItemMasterName: PropTypes.func.isRequired,
  getQtyReserved: PropTypes.func.isRequired,
  has_leaf_pa_config_pack_and_closed_loop: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  has_leaf_utah_config_pack_and_closed_loop: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  vendor_facility_type: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};


function mapStateToProps(state) {
  const {containers, locations, paymentTerms, paymentTypes,
    selectedItemMasters, itemMasters, timezone, has_leaf_pa_config_pack_and_closed_loop, has_leaf_utah_config_pack_and_closed_loop, vendor_facility_type} = state;

  return {
    orderType: getOrderType(state),
    orderTypes: getOrderTypeOptions(state),
    partnerFacilities: getPartnerFacilities(state),
    partnerFacility: getPartnerFacility(state) || {},
    containers,
    locations: flattenLocations(locations),
    registers: getAvailableRegisters(state),
    paymentTerms,
    paymentTypes,
    salesOrder: getSalesOrderForModifyPage(state),
    itemMasters,
    selectedPrePackChildren: selectedItemMasters,
    inventoryList: inventory.getInventoryList(state),
    getInventoryBy: inventory.getInventoryBy(state),
    isAllowNegativeInventory: complianceSettings.isAllowNegativeInventory(state),
    isReservationEnabled: complianceSettings.getIsReservationEnabled(state),
    isMappingRequiredByPlatform: isMappingRequiredForSupply(state),
    hasMedicatedLines: getIsSalesOrderMedicated(state),
    getItemMasterChildren: itemMaster => getItemMasterChildren(state, itemMaster),
    getRegisterById: payment => getRegisterById(state, payment),
    getSellableItemMasterById: itemMaster => getSalesOrderItemMasterById(state, itemMaster),
    integrationState: getIntegrationState(state),
    timezone,
    isActivePartner: getIsActivePartner(state),
    users: getCurrentFacilityUserOptions(state),
    itemMasterWithPricing: getItemMasterWithPricing(state),
    transferLinesLimit: getTransferLinesLimit(state),
    isLinkedSalesOrder: isLinkedSalesOrder(state),
    salesOrderConnectsStatus: salesOrderConnectsStatus(state),
    renderFacts: getRenderFacts(state, {permissionKey: 'manage_connects'}),
    renderFlags: state[itemNames.rules].default || {},
    getItemMasterName: props => getItemMasterInfo(state, props),
    // NOTE: TGC-54 - would like to move this to OrderForm, but there's a dependency in this component yet
    initialValues: getInitialValues(state),
    getQtyReserved: (item_master_id, excluded_so_id) => getReservationsQtyByItemMaster(state, {item_master_id, excluded_so_id}),
    currentFacilityId: getCurrentFacilityId(state),
    facilityDetailed: state.facilityDetailed,
    transferStatus: get(state[itemNames.transfer],'status'),
    has_leaf_pa_config_pack_and_closed_loop,
    has_leaf_utah_config_pack_and_closed_loop,
    vendor_facility_type,
    categories: state[dataNames.categories],
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    handleComplexSelectRow,
    addMessage,
    goBack,
    push,
    getUnpaginatedData,
    getSearchData,
    getItem,
    postItem,
    putItem,
    setData,
    getDataBatchByPost,
    getDataByPost,
    unsetData,
    unsetItem,
    setRules,
    unionData,
    getItemMasterChildrenAction,
    getDataBatch,
    change};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (ModifySalesOrderPage);
