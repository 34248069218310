export const inventoryTransactionReportTabs = [
  {
    id: 'default',
    eventKey: 'default',
    title: 'retail.inventoryTransactionsReport.default_tab_title',
    actions: []
  }
];

const defaultCsvFormatter = (cell) => cell || '';


export const inventoryTransactionReportColumns = [
  {
    name: 'retail.inventoryTransactionsReport.columns.facility',
    dataId: 'facilityName',
    csvFormatter: defaultCsvFormatter,
    dataSort: false
  },
  {
    name: 'retail.inventoryTransactionsReport.columns.inventoryTransactionId',
    dataId: 'item_transaction_id',
    csvFormatter: defaultCsvFormatter,
    dataSort: false
  },
  {
    name: 'retail.inventoryTransactionsReport.columns.eventDate',
    dataId: 'eventDate',
    csvFormatter: defaultCsvFormatter,
    dataSort: false
  },
  {
    name: 'retail.inventoryTransactionsReport.columns.employee',
    dataId: 'user_name',
    csvFormatter: defaultCsvFormatter,
    dataSort: false
  },
  {
    name: 'retail.inventoryTransactionsReport.columns.category',
    dataId: 'category_code',
    csvFormatter: defaultCsvFormatter,
    dataSort: false
  },
  {
    name: 'retail.inventoryTransactionsReport.columns.subcategory',
    dataId: 'subcategory_code',
    csvFormatter: defaultCsvFormatter,
    dataSort: false
  },
  {
    name: 'retail.inventoryTransactionsReport.columns.packageId',
    dataId: 'package_code',
    csvFormatter: defaultCsvFormatter,
    dataSort: false
  },
  {
    name: 'retail.inventoryTransactionsReport.columns.itemNumber',
    dataId: 'item_number',
    csvFormatter: defaultCsvFormatter,
    dataSort: false
  },
  {
    name: 'retail.inventoryTransactionsReport.columns.itemName',
    dataId: 'item_name',
    csvFormatter: defaultCsvFormatter,
    dataSort: false
  },
  {
    name: 'retail.inventoryTransactionsReport.columns.productType',
    dataId: 'productType',
    csvFormatter: defaultCsvFormatter,
    dataSort: false
  },
  {
    name: 'retail.inventoryTransactionsReport.columns.transactedQty',
    dataId: 'transacted_qty',
    csvFormatter: defaultCsvFormatter,
    dataSort: false
  },
  {
    name: 'retail.inventoryTransactionsReport.columns.transactedUom',
    dataId: 'transacted_uom',
    csvFormatter: defaultCsvFormatter,
    dataSort: false
  },
  {
    name: 'retail.inventoryTransactionsReport.columns.startingLevel',
    dataId: 'startingLevel',
    csvFormatter: defaultCsvFormatter,
    dataSort: false
  },
  {
    name: 'retail.inventoryTransactionsReport.columns.newLevel',
    dataId: 'newLevel',
    csvFormatter: defaultCsvFormatter,
    dataSort: false
  },
  {
    name: 'retail.inventoryTransactionsReport.columns.itemIsActive',
    dataId: 'itemIsActive',
    csvFormatter: defaultCsvFormatter,
    dataSort: false
  },
  {
    name: 'retail.inventoryTransactionsReport.columns.itemId',
    dataId: 'item_id',
    csvFormatter: defaultCsvFormatter,
    dataSort: false
  },
  {
    name: 'retail.inventoryTransactionsReport.columns.lotNumber',
    dataId: 'lot_number',
    csvFormatter: defaultCsvFormatter,
    dataSort: false
  },
  {
    name: 'retail.inventoryTransactionsReport.columns.sectionName',
    dataId: 'section_name',
    csvFormatter: defaultCsvFormatter,
    dataSort: false
  },
  {
    name: 'retail.inventoryTransactionsReport.columns.transactionType',
    dataId: 'transaction_type',
    csvFormatter: defaultCsvFormatter,
    dataSort: false
  },
  {
    name: 'retail.inventoryTransactionsReport.columns.adjustmentReason',
    dataId: 'adjustment_reason',
    csvFormatter: defaultCsvFormatter,
    dataSort: false
  },
  {
    name: 'retail.inventoryTransactionsReport.columns.transactionNotes',
    dataId: 'notes',
    csvFormatter: defaultCsvFormatter,
    dataSort: false
  },
  {
    name: 'retail.inventoryTransactionsReport.columns.dominance',
    dataId: 'dominance',
    csvFormatter: defaultCsvFormatter,
    dataSort: false
  },
  {
    name: 'retail.inventoryTransactionsReport.columns.strain',
    dataId: 'strainName',
    csvFormatter: defaultCsvFormatter,
    dataSort: false
  },
  {
    name: 'retail.inventoryTransactionsReport.columns.phenotype',
    dataId: 'phenotype_name',
    csvFormatter: defaultCsvFormatter,
    dataSort: false
  },
  {
    name: 'retail.inventoryTransactionsReport.columns.unitCost',
    dataId: 'unit_cost',
    csvFormatter: defaultCsvFormatter,
    dataSort: false
  },
];
