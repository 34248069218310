import React from 'react';
import PropTypes from 'prop-types';
import {Field, FieldArray} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Col,Row} from 'react-bootstrap';

import TextInput from '../../common/form/TextInput';
import SubmitSection from '../../common/form/SubmitSection';

import SupplierRow from './SupplierRow';
import CreatableSelectInput from '../../common/form/CreatableSelectInput';

const  IngredientsForm = (props) => {

  const {
    handleSubmit, submitting, pristine, invalid,  reset, change, suppliers, ingredientCategories, uoms, submitButtonText
  } = props;

  const submitSettings = {
    actionSettings: {
      reset: {
        text: I18n.t('common.form.reset'),
        action: reset,
        style: 'default'
      },
      submit: {
        submitting,
        invalid,
        pristine,
        text: submitButtonText,
        action: () => change('afterSubmit', 'redirect')
      }
    },
    align: 'right'
  };

  return (
    <form className='create-ingredients-form' onSubmit={handleSubmit} noValidate={true}>
      <Row className='form-fields-row'>
        <Col className='form-fields-col' xs={8} md={3} lg={3}>
          <Field
            name='name' component={TextInput}
            props={{
              label: I18n.t('ingredients.form.name'),
              isRequired: true,
              placeholder: I18n.t('ingredients.placeholder.name')
            }} />
        </Col>
        <Col className='form-fields-col' xs={8} md={3} lg={3}>
          <Field
            name='ingredient_category_id'
            component={CreatableSelectInput}
            props={{
              label: I18n.t('ingredients.form.category'),
              options: ingredientCategories,
              isRequired: true,
              textKey: 'name',
              valueKey: 'id',
              placeholder: I18n.t('ingredients.placeholder.choose')
            }} />
        </Col>
      </Row>
      <FieldArray
        name='vendors'
        component={SupplierRow}
        uomsOptions={uoms}
        suppliers={suppliers}
      />
      <SubmitSection settings={submitSettings}/>
    </form>
  );
};

IngredientsForm.propTypes = {
  submitButtonText: PropTypes.string.isRequired,
  suppliers: PropTypes.array.isRequired,
  ingredientCategories: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  uoms: PropTypes.array.isRequired
};

export default  IngredientsForm;
