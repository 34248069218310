import { I18n } from 'react-redux-i18n';
import { requiredFieldValidation, betweenValidation } from '../../../common/form/redux-form/validations';

const validate = (values) => {
  const errors = {
    name: requiredFieldValidation(values.name, 'retail.pricingClass.form.className'),
    facilities: requiredFieldValidation(values.facilities, 'retail.pricingClasses.form.facilities')
  };
  
  if (!/^[a-z 0-9]+$/i.test(values.name) || (values.name && values.name.length > 30)) {
    errors.name = I18n.t('retail.pricingClass.form.nameValidationError');
  }

  errors.customer_groups = (values.customer_groups || []).map(value => ({
    customer_group_id: requiredFieldValidation(value.customer_group_id, 'retail.pricingGroup.form.customerGroupError'),
    percentage_of_base: requiredFieldValidation(value.percentage_of_base, 'retail.pricingGroup.form.percentError')
    || betweenValidation(value.percentage_of_base, 'retail.pricingGroup.form.percentError', 0.01, 100),
  }));

  return errors;
};

export default validate;
