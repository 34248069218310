/* eslint-disable react/no-multi-comp */
import React from 'react';
import {I18n} from 'react-redux-i18n';
import {Link} from 'react-router';
import InternationalDecimalStatic from '../../../common/form/InternationalDecimalStatic';
import InternationalDateTimeStatic from '../../../common/form/InternationalDateTimeStatic';

//
// Daily Transaction Report table definition props
//
export const transactionReportTabs = [
  {
    id: 'default',
    eventKey: 'default',
    title: 'retail.transactionReport.transactions',
    actions: []
  }
];

export function getTransactionReportColumns(options = {includeMmap: false, featureAlleavesPaymentIntegrationEnabled:false, featurePosabitPaymentIntegrationEnabled: false, featureAeropayEnabled: false, featureAeropayIntegrationEnabled: false, featureHypurEnabled: false}) {
  const {includeMmap, featureAlleavesPaymentIntegrationEnabled, featurePosabitPaymentIntegrationEnabled, featureAeropayEnabled, featureAeropayIntegrationEnabled, featureHypurEnabled} = options;
  const columns = [
    {
      name: 'retail.transactionReport.columns.orderId',
      dataId: 'order_name',
      csvFormatter: (cell) => cell,
      formatter: (cell, row) => {
        if (cell !== 'TOTALS') {
          const url = row.consumer_id
            ? `/order-history/customers/${row.consumer_id}/${row.order_id}`
            : `/orders/${row.order_id}`;

          return (<Link to={url} target='_blank'>{cell}</Link>);
        }
        return cell;
      }
    },
    {
      name: 'retail.transactionReport.columns.refundedOrderNumber',
      dataId: 'refunded_order_name',
      csvFormatter: (cell) => cell || '',
    },
    {
      name: 'retail.transactionReport.columns.orderDate',
      dataId: 'order_date',
      csvFormatter: (cell) => cell || '',
      formatter: (cell) => <InternationalDateTimeStatic
        adjustForClientTimezone={false}>{cell}</InternationalDateTimeStatic>,
    },
    {
      name: 'retail.transactionReport.columns.orderType',
      dataId: 'order_type',
      csvFormatter: (cell) => cell || '',
    },
    {
      name: 'retail.transactionReport.columns.fulfillmentMethod',
      dataId: 'fulfillment_method',
      csvFormatter: (cell) => cell || '',
    },
    {
      name: 'retail.transactionReport.columns.register',
      dataId: 'register_name',
      csvFormatter: (cell) => cell || '',
    },
    {
      name: 'retail.transactionReport.columns.csr',
      dataId: 'csr_name',
      csvFormatter: (cell) => cell || '',
    },
    {
      name: 'retail.transactionReport.columns.patientName',
      dataId: 'consumer_name',
      csvFormatter: (cell) => {
        if (typeof cell === 'string') cell = cell.trim();
        if (cell) {
          return cell;
        }
        return I18n.t('retail.transactionReport.anonymousConsumer');
      },
      formatter: (cell) => {
        if (typeof cell === 'string') cell = cell.trim();
        if (cell) {
          return cell;
        }
        return I18n.t('retail.transactionReport.anonymousConsumer');
      },
    },
    {
      name: 'retail.transactionReport.columns.patientType',
      dataId: 'consumer_type',
      csvFormatter: (cell) => cell || '',
    },
    {
      name: 'retail.transactionReport.columns.cash',
      dataId: 'cash_amount',
      formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
      csvFormatter: (cell) => cell || '',
    },
    {
      name: 'retail.transactionReport.columns.credit',
      dataId: 'credit_amount',
      formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
      csvFormatter: (cell) => cell || '',
    },
    {
      name: 'retail.transactionReport.columns.debit',
      dataId: 'debit_amount',
      formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
      csvFormatter: (cell) => cell || '',
    },
    {
      name: 'retail.transactionReport.columns.gift_card',
      dataId: 'gift_amount',
      formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
      csvFormatter: (cell) => cell || '',
    },
    {
      name: 'retail.transactionReport.columns.check',
      dataId: 'check_amount',
      formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
      csvFormatter: (cell) => cell || '',
    },
  ];

  if (featureAlleavesPaymentIntegrationEnabled) {
    columns.push(
      {
        name: 'retail.transactionReport.columns.alleaves',
        dataId: 'alleaves_amount',
        formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
        csvFormatter: (cell) => cell || '',
      }
    );
  }

  if (featurePosabitPaymentIntegrationEnabled) {
    columns.push(
      {
        name: 'retail.transactionReport.columns.posabit',
        dataId: 'posabit_amount',
        formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
        csvFormatter: (cell) => cell || '',
      },
    );
  }

  if (featureAeropayEnabled) {
    columns.push(
      {
        name: 'retail.transactionReport.columns.aeroPay',
        dataId: 'aeropay_amount',
        formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
        csvFormatter: (cell) => cell || '',
      },
    );
  }

  if (featureAeropayIntegrationEnabled) {
    columns.push(
      {
        name: 'retail.transactionReport.columns.aeroPayIntegrated',
        dataId: 'aeropayintegrated_amount',
        formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
        csvFormatter: (cell) => cell || '',
      },
    );
  }

  if (featureHypurEnabled) {
    columns.push(
      {
        name: 'retail.transactionReport.columns.hypur',
        dataId: 'hypur_amount',
        formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
        csvFormatter: (cell) => cell || '',
      },
    );
  }

  if (includeMmap) {
    columns.push(
      {
        name: 'retail.transactionReport.columns.mmap',
        dataId: 'mmap_amount',
        formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
        csvFormatter: (cell) => cell || '',
      },
      {
        name: 'retail.transactionReport.columns.mmapReference', // MMAP reference is the order_id. For returns, it is the original order_id
        dataId: 'order_id',
        formatter: (cell, row) => (row.mmap_amount !== '0.00' ? row.order_type === 'Refunds' ? row.refunded_order_id : cell : ''),
        csvFormatter: (cell, row) => (row.mmap_amount !== '0.00' ? row.order_type === 'Refunds' ? row.refunded_order_id : cell : ''),
      });
    //${row.order_id}
  }
  columns.push(
    {
      name: 'retail.transactionReport.columns.other',
      dataId: 'other_amount',
      formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
      csvFormatter: (cell) => cell || '',
    },
    {
      name: 'retail.transactionReport.columns.subtotal',
      dataId: 'subtotal_amount',
      formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
      csvFormatter: (cell) => cell || '',
    },
    {
      name: 'retail.transactionReport.columns.tax',
      dataId: 'tax_amount',
      formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
      csvFormatter: (cell) => cell || '',
    },
    {
      name: 'retail.transactionReport.columns.discountCoupons',
      dataId: 'discount_amount_coupons',
      formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
      csvFormatter: (cell) => cell || '',
    },
    {
      name: 'retail.transactionReport.columns.discountRedemption',
      dataId: 'discount_amount_redemption',
      formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
      csvFormatter: (cell) => cell || '',
    },
    {
      name: 'retail.transactionReport.columns.discountRewards',
      dataId: 'discount_amount_rewards',
      formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
      csvFormatter: (cell) => cell || '',
    },
    {
      name: 'retail.transactionReport.columns.discountManual',
      dataId: 'discount_amount_manual',
      formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
      csvFormatter: (cell) => cell || '',
    }
  );
  columns.push(
    {
      name: 'retail.transactionReport.columns.discount',
      dataId: 'discount_amount',
      formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
      csvFormatter: (cell) => cell || '',
    },
    {
      name: 'retail.transactionReport.columns.total',
      dataId: 'total_amount',
      formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
      csvFormatter: (cell) => cell || '',
    },
    {
      name: 'retail.transactionReport.columns.change',
      dataId: 'change_amount',
      formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
      csvFormatter: (cell) => cell || '',
    },
    {
      name: 'retail.transactionReport.columns.startedByUser',
      dataId: 'started_by_user_name',
      csvFormatter: (cell) => cell || '',
    },
    {
      name: 'retail.transactionReport.columns.completedByUser',
      dataId: 'completed_by_user_name',
      csvFormatter: (cell) => cell || '',
    }
  );

  return columns;
}

export function getTransactionTotalColumns(options = {includeMmap: false, featureAlleavesPaymentIntegrationEnabled:false, featurePosabitPaymentIntegrationEnabled: false, featureAeropayEnabled: false, featureAeropayIntegrationEnabled: false, featureHypurEnabled: false}) {
  const {includeMmap, featureAlleavesPaymentIntegrationEnabled, featurePosabitPaymentIntegrationEnabled, featureAeropayEnabled, featureAeropayIntegrationEnabled, featureHypurEnabled} = options;

  const columns = [
    {
      name: 'retail.transactionReport.columns.cash',
      dataId: 'cash_amount',
      formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
    },
    {
      name: 'retail.transactionReport.columns.credit',
      dataId: 'credit_amount',
      formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
    },
    {
      name: 'retail.transactionReport.columns.debit',
      dataId: 'debit_amount',
      formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
    },
    {
      name: 'retail.transactionReport.columns.gift_card',
      dataId: 'gift_amount',
      formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
    },
    {
      name: 'retail.transactionReport.columns.check',
      dataId: 'check_amount',
      formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
    },
  ];
  if (featureAlleavesPaymentIntegrationEnabled) {
    columns.push(
      {
        name: 'retail.transactionReport.columns.alleaves',
        dataId: 'alleaves_amount',
        formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
        csvFormatter: (cell) => cell || '',
      }
    );
  }
  if (featurePosabitPaymentIntegrationEnabled) {
    columns.push(
      {
        name: 'retail.transactionReport.columns.posabit',
        dataId: 'posabit_amount',
        formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
        csvFormatter: (cell) => cell || '',
      }
    );
  }
  if (featureAeropayEnabled) {
    columns.push(
      {
        name: 'retail.transactionReport.columns.aeroPay',
        dataId: 'aeropay_amount',
        formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
      }
    );
  }
  if (featureAeropayIntegrationEnabled) {
    columns.push(
      {
        name: 'retail.transactionReport.columns.aeroPayIntegrated',
        dataId: 'aeropay_integrated_amount',
        formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
      }
    );
  }
  if (featureHypurEnabled) {
    columns.push(
      {
        name: 'retail.transactionReport.columns.hypur',
        dataId: 'hypur_amount',
        formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
      }
    );
  }
  if (includeMmap) {
    columns.push(
      {
        name: 'retail.transactionReport.columns.mmap',
        dataId: 'mmap_amount',
        formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
        csvFormatter: (cell) => cell || '',
      }
    );
  }
  columns.push(
    {
      name: 'retail.transactionReport.columns.other',
      dataId: 'other_amount',
      formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
    },
    {
      name: 'retail.transactionReport.columns.subtotal',
      dataId: 'subtotal_amount',
      formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
    },
    {
      name: 'retail.transactionReport.columns.tax',
      dataId: 'tax_amount',
      formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
    },
    {
      name: 'retail.transactionReport.columns.discountCoupons',
      dataId: 'discount_amount_coupons',
      formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
      csvFormatter: (cell) => cell || '',
    },
    {
      name: 'retail.transactionReport.columns.discountRedemption',
      dataId: 'discount_amount_redemption',
      formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
      csvFormatter: (cell) => cell || '',
    },
    {
      name: 'retail.transactionReport.columns.discountRewards',
      dataId: 'discount_amount_rewards',
      formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
      csvFormatter: (cell) => cell || '',
    },
    {
      name: 'retail.transactionReport.columns.discountManual',
      dataId: 'discount_amount_manual',
      formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
      csvFormatter: (cell) => cell || '',
    },
    {
      name: 'retail.transactionReport.columns.discount',
      dataId: 'discount_amount',
      formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
    },
    {
      name: 'retail.transactionReport.columns.total',
      dataId: 'total_amount',
      formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
    },
    {
      name: 'retail.transactionReport.columns.change',
      dataId: 'change_amount',
      formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
    }
  );

  return columns;
}
