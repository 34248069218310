import {change, getFormValues, formValueSelector} from 'redux-form';
import moment from 'moment';
import {CUSTOMER_FORM} from '../../constants/forms';
import {ADD_DATA_SUCCESS} from '../../constants/actionTypes';
import * as dataNames from '../../constants/dataNames';
import {isFormChangeOnField, isChangeActionOnField} from './utils';
import {getOrderedPhysicians} from '../../selectors/physiciansSelectors';

const customerForm = store => next => action => {
  const result = next(action);
  const {payload, meta} = action;
  const state = store.getState();

  //Set physician info to be displayed in read-only form fields
  if (isFormChangeOnField(action, [CUSTOMER_FORM], 'physician_id')) {
    const physicians = getOrderedPhysicians(state);
    const physician = physicians.find(physician => physician.id === payload);
    store.dispatch(change(meta.form, 'customer.physician', physician || null));
  }

  // If new id and marked is_renwal and date is moved to expired, set is_renewal false
  if(isChangeActionOnField(action, [CUSTOMER_FORM], 'expired_at', true)){
    const selector = formValueSelector(CUSTOMER_FORM);
    const today = moment();
    const isExpired = !action.payload || action.payload === null
      ? false
      : typeof action.payload === 'object'
        ? action.payload.isBefore(today)
        : moment(action.payload).isBefore(today);
    if(isExpired){
      const fieldPrefix = action.meta.field.split('.').shift();
      const isRenewal = selector(state, `${fieldPrefix}.is_renewal`);
      const hasId = selector(state, `${fieldPrefix}.id`);
      if(!hasId && isRenewal){
        store.dispatch(change(CUSTOMER_FORM, `${fieldPrefix}.is_renewal`, false));
      }
    }
  }

  //If physician was added through the modal, need to set him on the form
  const values = getFormValues(CUSTOMER_FORM)(state);
  if (action.type === ADD_DATA_SUCCESS && action.name === dataNames.physicians && values) {
    const id = payload && payload.data && payload.data.id || null;
    store.dispatch(change(CUSTOMER_FORM, 'customer.physician_id', id));
  }

  return result;
};

export default customerForm;
