import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Tab, Tabs, Col} from 'react-bootstrap';
import {push} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import {get} from 'lodash';
import AnalyticsReportsSearchForm from './AnalyticsReportsSearchForm';
import LargeButton from '../common/LargeButton';
import PageTitle from '../common/PageTitle';
import {getData, getItem, postItem} from '../../actions/apiActions';
import {analyticsReports, domoCustomReports,aLaCartAvailableForOrg} from '../../constants/dataNames';
import {getAnalyticsReportsForActiveTabSelector, getTabs} from '../../selectors/reportingAnalyticsSelectors';
import PermissionButton from '../common/PermissionButton';
import * as perms from '../../constants/permissions';
import * as itemNames from '../../constants/itemNames';
import {getCustomReportLink} from './helpers';
import {isFeatureEnabled} from '../../selectors/featureToggles';
import {addMessage} from '../../actions/systemActions';
import * as messageTypes from '../../constants/messageTypes';

class AnalyticsListingPage extends React.Component {

  constructor(props) {
    super(props);
    this.switchTab = this.switchTab.bind(this);
    this.getReports = this.getReports.bind(this);
    this.onCustomReportClick = this.onCustomReportClick.bind(this);
    this.renderCustomReportButton = this.renderCustomReportButton.bind(this);
    this.onFavoriteClick = this.onFavoriteClick.bind(this);
    this.showNoSubscriptionMessage = this.showNoSubscriptionMessage.bind(this);
  }

  componentDidMount() {
    const {getData, getItem} = this.props;
    this.getReports();
    this.getOrgReports();
    getData('/api/domo_reports/custom', domoCustomReports);
    getItem('/api/organizations/current_organization', itemNames.currentOrganization);
  }

  componentWillReceiveProps(nextProps){
    // Handles case where you remove all items from favorites tab
    const tabExists = nextProps.tabs.find((tab) => tab.id === nextProps.params.tab);
    if(!tabExists){
      this.switchTab('all');
    }
  }

  getReports(){
    this.props.getData('/api/domo_reports', analyticsReports);
  }

  getOrgReports(){
    this.props.getData(`/api/domo_reports/${this.props.facility.organizationId}/a_la_cart`, aLaCartAvailableForOrg);
  }

  onFavoriteClick (e, reportId) {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.focus();
    this.props.postItem(`/api/domo_reports/${reportId}/toggle_favorite`)
      .then(() => {
        this.getReports();
      });
  }

  switchTab(tab) {
    const {push} = this.props;
    push(`/reporting/analytics/${tab}`);
  }

  onCustomReportClick () {
    const {customReports, facility} = this.props;
    window.open(getCustomReportLink(customReports, facility.id), 'Custom Report');
  }

  renderCustomReportButton () {
    const {customReports, facility} = this.props;
    return !getCustomReportLink(customReports, facility.id)
      ? null
      : (<Col offset={9} md={3} style={{position: 'absolute', top: '-10px'}}>
        <PermissionButton
          permissions={[perms.analytics_custom_reporting]}
          props={{
            variant: 'primary',
            className: 'btn-block',
            disabled: false,
            onClick: this.onCustomReportClick
          }}
        >
          {I18n.t('reporting.analytics.customReportingButton')}
        </PermissionButton>
      </Col>);
  }

  showNoSubscriptionMessage() {
    this.props.addMessage(messageTypes.warning, 'reporting.analytics.noActiveSubscription');
  }

  render() {
    const {tabs, analyticsReports, params: {tab}, isQlikReportingFeatureEnabled, organization} = this.props;
    const activeTab = analyticsReports.length ? tab : 'all';

    const sortedAnalyticsReports = analyticsReports.sort((a, b) => {
      const reportA = a.name.toLowerCase();
      const reportB = b.name.toLowerCase();
      if (reportA < reportB) {
        return -1;
      }
      if (reportA > reportB) {
        return 1;
      }
      return 0;
    });

    const pageTitleKey = isQlikReportingFeatureEnabled ? 'pageTitleBeta' : 'pageTitle';

    const mandatoryReportsOnly = !get(organization, 'bi_base_subscription', false);

    return (
      <React.Fragment>
        <PageTitle primaryText={I18n.t(`reporting.analytics.${pageTitleKey}`)} />
        <AnalyticsReportsSearchForm />
        <div style={{position:'relative'}}>
          <Tabs id='filterTabs' activeKey={activeTab} onSelect={this.switchTab}>
          {activeTab === 'for_purchase'
            ? <div style={{textAlign:'center'}}><u><h1>{I18n.t('reporting.analytics.forPurchaseInfo')}</h1></u>
          <h4>{I18n.t('reporting.analytics.contactAMForPricing')}</h4>
          <br></br></div> : []}
            {tabs.map((tab, index) => {
              return <Tab key={index} eventKey={tab.eventKey} title={tab.title} />;
            })}
          </Tabs>
          {
            this.renderCustomReportButton()
          }
        </div>
        {sortedAnalyticsReports.map((report, index) => {
          return (
            report.for_purchase
              ? <LargeButton
                  key={index}
                  isDisabled={report.for_purchase}
                >
                  <u><h1>{report.name}</h1></u>
                  {report.description}
                </LargeButton>
              : <LargeButton
                  key={index}
                  onFavoriteClick={(e) => this.onFavoriteClick(e, report.id)}
                  isFavorite={report.is_favorite}
                  iconName={report.image_name}
                  path={`/reporting/analytics/view/${report.id}`}
                  onClick={mandatoryReportsOnly && !report.mandatory ? this.showNoSubscriptionMessage : null}
                  isDisabled={mandatoryReportsOnly && !report.mandatory}
                >
                  {report.name}
                </LargeButton>
          );
        })}
      </React.Fragment>
    );
  }
}

AnalyticsListingPage.propTypes = {
  getItem: PropTypes.func.isRequired,
  tabs: PropTypes.array.isRequired,
  analyticsReports: PropTypes.array.isRequired,
  getData: PropTypes.func.isRequired,
  postItem: PropTypes.func.isRequired,
  addMessage: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  customReports: PropTypes.array,
  facility: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  aLaCartAvailableForOrg: PropTypes.array,
  isQlikReportingFeatureEnabled: PropTypes.bool.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {
    analyticsReports: getAnalyticsReportsForActiveTabSelector(state, {tab: ownProps.params.tab}),
    customReports: state[domoCustomReports],
    tabs: getTabs(state),
    facility: state[itemNames.facility],
    organization: state[itemNames.currentOrganization],
    isQlikReportingFeatureEnabled: isFeatureEnabled(state)('feature_qlik_reporting'),
    aLaCartAvailableForOrg:state[aLaCartAvailableForOrg]
  };
};

const mapDispatchToProps = {
  getData,
  getItem,
  push,
  postItem,
  addMessage
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsListingPage);
