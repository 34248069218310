import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { Button, Col, ListGroupItem, Row } from 'react-bootstrap';
import {FaTimes} from 'react-icons/fa';
import Select from 'react-select';
import InternationalQuantityByUomInput from '../common/form/InternationalQuantityByUomInput';
import InternationalCurrencyStatic from '../common/form/InternationalCurrencyStatic';

const DrawerItem = ({
  description,
  category,
  id,
  updateCartProductProperty,
  removeItem,
  src,
  itemTotal,
  itemMasterType,
  form_id,
  isCure,
  formOptions,
  itemMediated
}) => {
  const handleRemoveItemClick = () => {
    removeItem(id);
  };

  return (
    <ListGroupItem className='drawer-item'>
      <Row>
        <Col xs={3}>
          <div className='square'>
            <img src={src} className='img-75x75 scale-down' alt='item'/>
          </div>
        </Col>
        <Col xs={9}>
          <Row style={{marginBottom: '6px'}}>
            <Col md={10}>
              <h4>{description}</h4>
            </Col>
            <Col md={2}>
              <Button size='sm' onClick={handleRemoveItemClick} className={'float-right'}>
                <FaTimes />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <InternationalQuantityByUomInput
                name={`sold-weight-${id}`}
                hideUom={true}
                className='quantity float-right form-control input-number-without-arrows'
                uom={itemMasterType === 'unit' ? 'EA' : 'GR'}
              />
              {category}
              <br />
              {I18n.t('cart.drawer.itemTotal')}: <InternationalCurrencyStatic>{itemTotal}</InternationalCurrencyStatic>
            </Col>
          </Row>
        </Col>
      </Row>
      {isCure && itemMediated ? (
          <Row>
            <Col xs={12} style={{marginTop: '6px'}}>
              <Select
                options={formOptions}
                labelKey='text'
                valueKey='value'
                value={form_id}
                onChange={(option) => updateCartProductProperty(id, 'form_id', (option && option.value) || option)}
                clearable={false}
              />
            </Col>
          </Row>
      ) : null}
    </ListGroupItem>
  );
};

DrawerItem.propTypes = {
  description: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  src: PropTypes.string.isRequired,
  updateCartProductProperty: PropTypes.func,
  removeItem: PropTypes.func.isRequired,
  itemTotal: PropTypes.number,
  itemMasterType: PropTypes.string.isRequired,
  form_id: PropTypes.number,
  isCure: PropTypes.bool,
  formOptions: PropTypes.array,
  itemMediated: PropTypes.bool
};

export default DrawerItem;
