import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { Link } from 'react-router';
import {goBack} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import FormWrapper from '../../common/form/FormWrapper';
import LabelsListPage from './LabelsListPage';

class LabelsManagementPage extends React.PureComponent {

  render() {
    return (
      <FormWrapper title={I18n.t('facility.labelsManager')} goBack={this.props.actions.goBack}>
        <div>
          <ul className='list-group'>
            <li className='list-group-item'>
              <h3>Statements</h3>
              <p>
                Statements for labels allow you to quickly build out required fields such as mandated statements,
                health benefits by category, as well as warnings such as pesticides, fungicides, and herbicides.
                These are typically used for package and patient labels.
              </p>
              <p>
                <strong><Link to={'/labels/statements'} onlyActiveOnIndex={false}>Click Here to manage your label statements.</Link></strong>
              </p>
            </li>
            <li className='list-group-item'>
              <h3>Label Settings And Configuration</h3>
              <p>
                Configuring multiple labels derived from a base label is a fairly simple way to build a custom
                label without requiring any knowledge of ZPL (or a software product to build it).
              </p>
              <p>
                Click a base label below to configure one or more labels based on it.
              </p>
              <hr />
              <LabelsListPage />
            </li>
            <li className='list-group-item'>
              <h3>Custom ZPL Labels</h3>
              <p>
                If you have previously created a custom label with pure ZPL you can replace a default
                label with that pure ZPL label.
              </p>
              <p>
                <strong><Link to={'/labels/custom'} onlyActiveOnIndex={false}>Click Here to work with Custom Labels.</Link></strong>
              </p>
            </li>
            <li className='list-group-item'>
              <h3>Print Server Management For Remote Printing</h3>
              <p>
                Print Servers allow printers connected to a central server to be accessed from any
                MJP client operating within the same facility allowing you to implement a clean network
                topology and support wireless printing from most devices.
              </p>
              <p>
                <strong><Link to={'/print-servers'} onlyActiveOnIndex={false}>Click Here to Manage Your Print Servers.</Link></strong>
              </p>
            </li>
          </ul>

        </div>
      </FormWrapper>
    );
  }
}

LabelsManagementPage.propTypes = {
  actions: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  })
};

function mapDispatchToProps (dispatch) {
  const actions = {goBack};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(null, mapDispatchToProps)(LabelsManagementPage);
