/* eslint-disable import/prefer-default-export */
import round from 'lodash.round';
import find from 'lodash.find';
import map from 'lodash.map';
import get from 'lodash.get';
import {createSelector} from 'reselect';

import * as itemNames from '../constants/itemNames';
import * as dataNames from '../constants/dataNames';
import getSelectedDataName from '../constants/helpers/getSelectedDataName';
import {getImageUrl} from '../util/images';
import {LEAF} from '../constants/imageUrls';
import {getCategoriesDataSelector} from './categorySelectors';
import {getCureOrderProducts} from './integration/cureApiSelectors';
import {CART_DRAWER} from '../constants/forms';

const getPartnerFacilities = state => state[dataNames.partnerFacilities];

export const getOrder = state => state[itemNames.order];
export const getCatalog = state => state[itemNames.catalog];
export const getImages = state => state[dataNames.images];
export const getPurchaseOrders = state => state[dataNames.purchaseOrders];
export const getOrderImportHistory = state => state[dataNames.orderImportHistory];
export const getOrderImportItem = state => state[itemNames.orderImportItem];
export const getActivePurchaseOrders = state => state[dataNames.purchaseOrdersActive];
export const getInActivePurchaseOrders = state => state[dataNames.purchaseOrdersInActive];
export const getOrderStatus = (_, props) => props.params.status;
export const getDrivers = state => state[dataNames.drivers];

export const getInventoryReceipts = state => state[dataNames.inventoryReceipts];
export const getSelectedSalesOrders = state => state[getSelectedDataName(dataNames.salesOrders)];
export const getSelectedPurchaseOrders = state => state[getSelectedDataName(dataNames.purchaseOrders)];
export const getSelectedInventoryReceipts = state => state[getSelectedDataName(dataNames.inventoryReceipts)];

export const getPurchaseOrdersByStatus = createSelector(
  [getPurchaseOrders, getOrderStatus] , (purchaseOrders,  status) => {
    return purchaseOrders.filter((order) => {
      return status === 'active' ?
        order.received === 0 || order.payment_status !== 'completed' :
        order.received === 1 && order.payment_status === 'completed';
    });
  }
);

export const getOrderProducts = createSelector([getOrder], order => Array.isArray(order && order.products) ? order.products : []);

export const getOrderProductItemMasterIds = createSelector([getOrderProducts], products => products.map(p => p.item_master_id));

export const getOrderProductCount = createSelector(
  getOrderProducts, products => products.length
);

export const getOrderSubtotal = createSelector(
  getOrder, order => order && order.products && order.products.length
    ? order.products.reduce((previous, current) =>
        previous + (current.unit_price && current.quantity
          ? round(parseFloat(current.unit_price) * parseInt(current.quantity), 2)
          : 0), 0)
    : 0
);

export const getTaxableTotal = createSelector(
  [getOrder, getOrderSubtotal],
  (order, subTotal) => {
    const {discountTotal, taxableDiscountTotal} = order.products ? order.products.reduce((obj, product) => {
      obj.discountTotal += product.discount_total;
      obj.taxableDiscountTotal += product.taxable_discount_total;
      return obj;
    }, {discountTotal: 0.0, taxableDiscountTotal: 0.0}) : {discountTotal: 0.0, taxableDiscountTotal: 0.0};

    return subTotal - (discountTotal - taxableDiscountTotal);
  }
);

export const getSelectedSalesOrdersIds = createSelector(
  getSelectedSalesOrders,
  salesOrders => map(salesOrders, 'id')
);

export const getSelectedPurchaseOrdersIds = createSelector(
  getSelectedPurchaseOrders,
  purchaseOrders => map(purchaseOrders, 'id')
);

export const getSelectedInventoryReceiptsIds = createSelector(
  getSelectedInventoryReceipts,
  inventoryReceipts => map(inventoryReceipts, 'id')
);

export const getReceiptListingData = createSelector(
  [getInventoryReceipts, getPartnerFacilities, getDrivers],
  (inventoryReceipts, partnerFacilities, drivers) => inventoryReceipts.map(
    receipt => {
      const partnerFacility = find(partnerFacilities, {id: receipt.vendor_facility_id});
      const contact = partnerFacility && partnerFacility.first_contact;
      const driver = drivers.find(driver => driver.id === receipt.driver_id);
      return {
        ...receipt,
        partner_facility: partnerFacility,
        partner_name: partnerFacility && partnerFacility.partner ? partnerFacility.partner.name : '',
        partner_contact: contact ? `${contact.first_name} ${contact.last_name} ${contact.phone_number}` : '',
        driver_name: driver && driver !== null ? (driver.is_licensed_transporter ? driver.name : `${driver.first_name} ${driver.last_name}`) : ''
      };
    }
  )
);

export const getCartDrawerProducts = createSelector(
  [getOrder, getCatalog, getImages, getCureOrderProducts],
  (order, catalog, images, cureOrderProducts) => {
    const products = Array.isArray(order.products) ? order.products.map(product => {
      const cure_order_product = cureOrderProducts.find(cureProduct => Number(cureProduct.order_product_id) === product.id && Number(cureProduct.order_id) === order.id);
      const package_id = product.items && product.items.length > 0 ? product.items[0].package_id : undefined;
      const catalogProduct = catalog[product.item_master_id];
      const image = catalogProduct && catalogProduct.primary_product_image_file_id && images.find(
        image => image.id === catalogProduct.primary_product_image_file_id
      );
      const src = getImageUrl(image, '75x75', LEAF);
      return {...product, package_id, src, cure_order_product};
    }) : [];
    return products;
  }
);

export const getCartDrawerOrder = createSelector(
  getOrder, getCartDrawerProducts, (order, products) => Object.assign({}, order, {products})
);

const getCategoriesAndSubCategories = (categoriesData) => {
  const categories = {};
  const subCategories = {};
  categoriesData.forEach((category) => {
    const simpleCategory = Object.assign({}, category, {subcategories: []});
    categories[simpleCategory.id] = simpleCategory;
    category.subcategories.forEach((subCategory) => {
      subCategories[subCategory.id] = subCategory;
    });
  });
  return {categories, subCategories};
};

export const getOrderWithCategoryWeights = createSelector([getOrder, getCategoriesDataSelector], (order, categoriesData) => {
  if(Object.keys(order).length === 0 || categoriesData.length === 0 || order.products === undefined) return {};
  const {categories, subCategories} = getCategoriesAndSubCategories(categoriesData);
  order.weightsByCategory = [];
  const fields = ['quantity', 'compliance_weight_grams', 'sold_weight', 'sold_weight_uom_display'];
  order.products.forEach((product) => {
    let orderCategory = order.weightsByCategory.find((orderCategory) => orderCategory.id === product.category_id);
    if(!orderCategory){
      orderCategory = categories[product.category_id];
      order.weightsByCategory.push(orderCategory);
    }
    let orderSubCategory = orderCategory.subcategories.find((orderSubCategory) => orderSubCategory.id === product.subcategory_id);
    if(!orderSubCategory){
      orderSubCategory = Object.assign({}, subCategories[product.subcategory_id], {quantity: 0, compliance_weight_grams: 0, sold_weight: 0});
      orderCategory.subcategories.push(orderSubCategory);
    }
    fields.forEach((field) => {
      if(field.indexOf('display') !== -1) return orderSubCategory[field] = product[field];
      const value = parseFloat(product[field]);
      orderSubCategory[field] += (isNaN(value)) ? 0 : value;
    });
  });
  return order;
});

export const isReadyStatus = createSelector([getOrder], (order) => {
  return order.fulfillment_status === 'ready';
});


export const soldWeightsSelector = (state) => {

  let selectedValues = {};
  if (get(state, `form.${CART_DRAWER}.values`, false)) {
    const values = get(state, `form.${CART_DRAWER}.values`, []);
    selectedValues = Object.keys(values).reduce((acc, key) => {
      const match = key.match(/sold-weight-(\d+)/);
      if (match && match.length > 1) {
        const id = match[1];
        return {...acc, [id]: values[key]};
      }
      else {
        return {...acc};
      }
    }, {});
  }
  return selectedValues;
};
