import {change} from 'redux-form';

import {isFormChangeAction} from './utils';
import {PRODUCT_MASS_MODIFY, PRODUCT_MULTI_SEARCH_FORM} from '../../constants/forms';
import {getPhenotypesByStrain} from '../../selectors/strainsSelectors';
import {setData} from '../../actions/dataActions';
import * as dataNames from '../../constants/dataNames';
import {
  generateWeightPrices,
  getCategoryId
} from '../../selectors/forms/productFormSelectors';
import {productFormValueSelector} from '../../selectors/forms/massModifyFormSelector';
import {getPricingGroups} from '../../selectors/pricingGroupsSelectors';
import {getPricingWeights} from '../../selectors/pricingWeightsSelectors';

const productMassModifyForm = store => next => action => {
  const result = next(action);

  if (isFormChangeAction(action, [PRODUCT_MASS_MODIFY])) {

    const state = store.getState();
    const {meta, payload} = action;
    if (meta.field === 'strain_id') {
      const phenotypes = getPhenotypesByStrain(state, {strain_id: payload});
      store.dispatch(setData(phenotypes, dataNames.phenotypes));
    }

    if (meta.field.indexOf('.pricing_group_id') > -1) {
      const path = meta.field.replace('.pricing_group_id', '');
      const state = store.getState();
      const categoryId = getCategoryId(state);
      const value = productFormValueSelector(state, path);
      const pricingGroups = getPricingGroups(state);
      const selectedPricingGroup = pricingGroups.find(pricingGroup => pricingGroup.id === value.pricing_group_id);

      if (selectedPricingGroup && selectedPricingGroup.pricing_class_id) {
        value.pricing_class_id = selectedPricingGroup.pricing_class_id;
      }

      store.dispatch(change(meta.form, path, {
        ...value,
        pricing_group_id: payload,
        weight_prices: generateWeightPrices({pricing_group_id: payload}, categoryId, getPricingWeights(state), pricingGroups),
      }));
    }
  }

  if (isFormChangeAction(action, [PRODUCT_MULTI_SEARCH_FORM])) {
    const {meta} = action;
    if (meta.field === 'categories') {
      store.dispatch(change(meta.form, 'subcategories', []));
    }
  }

  return result;
};

export default productMassModifyForm;