import {createSelector} from 'reselect';
import {pick} from 'lodash';
import * as dataNames from '../constants/dataNames';
import * as itemNames from '../constants/itemNames';


export const getPreferredMix = state => state[itemNames.preferredMix];
export const getPreferredMixes = state => state[dataNames.preferredMixes];
export const getSelectedPreferredMixes = state => state.selectedPreferredMixes;
export const getSelectedPreferredMixesIds = createSelector(
  [getSelectedPreferredMixes],
  selectedPreferredMixes => selectedPreferredMixes.map(selectedPreferredMix => selectedPreferredMix.id)
);

export const getPreferredMixCreatePayload = (formData) => {
  return {
    name: formData.name,
    output_item_master_id: formData.output_item_master_id,
    active: 1,
    inputs: formData.inputs.map(input => pick(input, ['item_master_id', 'qty', 'uom'])),
  };
};

export const getPreferredMixUpdatePayload = (formData) => {
  return {
    name: formData.name,
    output_item_master_id: formData.output_item_master_id,
    inputs: formData.inputs.map(input => pick(input, ['id', 'item_master_id', 'qty', 'uom'])),
  };
};

