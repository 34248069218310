/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'redux-form';

import Strain from './Strain';

export const ModifyStrainsForm = (props) => {
  const { handleSubmit, integrationState, isCanada, totalPlantLifeCycleValues, error, isColombia } = props;
  return (
    <form className='modify-strains-form' onSubmit={handleSubmit}>
      <FieldArray
        component={Strain}
        name='selectedStrains'
        integrationState={integrationState}
        isCanada={isCanada}
        isColombia={isColombia}
        totalPlantLifeCycleValues={totalPlantLifeCycleValues}
        errors={error || []}
      />
    </form>
  );
};

ModifyStrainsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  integrationState: PropTypes.object.isRequired,
  isCanada: PropTypes.bool.isRequired,
  isColombia: PropTypes.bool,
  totalPlantLifeCycleValues: PropTypes.array.isRequired,
  error: PropTypes.array
};

export default ModifyStrainsForm;
