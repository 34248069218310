import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {Button, Col, Row} from 'react-bootstrap';
import {FaMinus, FaPlus} from 'react-icons/fa';
import {I18n} from 'react-redux-i18n';
import CountryField from '../../common/form/redux-form/CountryField';
import PostalCodeField  from '../../common/form/redux-form/PostalCodeField';
import TextInput from '../../common/form/TextInput';

const CaregiverAddresses = (props) => {
  const {arrayPush, fields: {push, remove, map, get}, change} = props;

  return (
    <React.Fragment>
      {map((fieldNamePrefix, index) => {
        const address = get(index);
        return (
          <Row>
            <Col md={12} key={`fieldNamePrefix-${index}`}>
              <div>
                <h5>{I18n.t('customers.create.caregiverAddress', {number: index + 1})}</h5>
              </div>
              <Row>
                <Col md={10}>
                  <Field name={`${fieldNamePrefix}.street_address_1`} component={TextInput} props={{
                    label: I18n.t('customers.create.street'),
                    placeholder: I18n.t('customers.placeholders.street')
                  }}/>
                </Col>
                <Col md={2}>
                  <Field name={`${fieldNamePrefix}.street_address_2`} component={TextInput} props={{
                    label: I18n.t('customers.create.apartment'),
                    placeholder: I18n.t('customers.placeholders.apartment')
                  }}/>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Field name={`${fieldNamePrefix}.city`} component={TextInput} props={{
                    label: address.country_code === 'AU' ? I18n.t('common.form.suburb') : I18n.t('common.form.city'),
                    placeholder: address.country_code === 'AU' ? I18n.t('common.form.suburb') : I18n.t('common.form.city'),
                  }}/>
                </Col>
                <CountryField
                  name={`${fieldNamePrefix}.country_code`}
                  stateFieldName={`${fieldNamePrefix}.province_code`}
                  change={change}
                  countryFieldSize={3}
                  stateFieldSize={3}
                  currentCountry={address.country_code}
                />
                <Col md={3}>
                  <PostalCodeField name={`${fieldNamePrefix}.postal_code`} countryCode={address.country_code}/>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className='add-button-wrapper float-right'>
                    <span>{I18n.t('customers.create.removeCaregiverAddress', {number: index + 1})}</span>
                    <Button
                      variant='danger'
                      size='sm'
                      onClick={() => {
                        remove(index);
                        const address = get(index);
                        if (address.id) {
                          arrayPush('delete_address_ids', address.id);
                        }
                      }}
                    >
                      <FaMinus/>
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        );
      })}
      <Row>
        <Col md={12} className='add-new-address-button-wrapper' style={{paddingRight: '14px'}}>
          <div className='add-button-wrapper float-right'>
            <span>{I18n.t('customers.create.addCaregiverAddress')}</span>
            <Button variant='primary' size='sm' onClick={() => push({})}><FaPlus/></Button>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

CaregiverAddresses.propTypes = {
  fields: PropTypes.shape({
    push: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    map: PropTypes.func.isRequired,
    get: PropTypes.func.isRequired
  }),
  arrayPush: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired
};

export default CaregiverAddresses;
