import React from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import { Col, Row, Card } from 'react-bootstrap';
import Status from '../../common/status';
import Loading from '../../common/loading';

import * as itemNames from '../../../constants/itemNames';
import {getItem, postItem} from '../../../actions/apiActions';
import {unsetItem} from '../../../actions/itemActions';
import {getCompany} from '../../../selectors/merchantConfigurationSelectors';
import {getCurrentFacilityId, getCurrentFacilityName} from '../../../selectors/facilitiesSelectors';

class MerchantCompanyHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };

    this.companyCreate = this.companyCreate.bind(this);
    this.setLoadingState = this.setLoadingState.bind(this);
  }

  setLoadingState(value) {
    this.setState({loading: value});
  }

  componentDidMount() {
    const {getItem, facility_id} = this.props;

    getItem('/api/payment/companies/find', itemNames.merchantConfigurationCompany, null, {reference: facility_id})
      .then(() => this.setLoadingState(false));
  }

  componentWillUnmount() {
    const {unsetItem} = this.props;

    unsetItem(itemNames.merchantConfigurationCompany);
  }

  companyCreate() {
    const {postItem, facility_id, facility_name} = this.props;
    const payload = {
      name: facility_name,
      reference: facility_id.toString(),
      status: 'active'
    };

    postItem('/api/payment/companies/save', payload, itemNames.merchantConfigurationCompany);
  }

  render() {
    const {loading} = this.state;
    const {company} = this.props;

    if (loading) {
      return <Loading message={I18n.t('facility.setup.merchantCompany.loading')} />;
    }

    if (!company) {
      return (
        <React.Fragment>
          <div>{I18n.t('facility.setup.merchantCompany.noCompany')}</div>
          <button
            type='button'
            onClick={this.companyCreate}
            className='btn btn-primary'
          >
            {I18n.t('facility.setup.merchantCompany.register')}
          </button>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Row>
          <Col md={4}>
            <Card>
              <Card.Body>
                <Row>
                  <Col md={6}><strong>{I18n.t('facility.setup.merchantCompany.name')}:</strong></Col>
                  <Col md={6}>{company.name}</Col>
                </Row>
                <Row>
                  <Col md={6}><strong>{I18n.t('facility.setup.merchantCompany.status')}:</strong></Col>
                  <Col md={6}><Status status={company.status} /></Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  company: getCompany(state),
  facility_id: getCurrentFacilityId(state),
  facility_name: getCurrentFacilityName(state)
});

const mapDispatchToProps = ({ getItem, unsetItem, postItem });

export default connect(mapStateToProps, mapDispatchToProps)(MerchantCompanyHeader);
