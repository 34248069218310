import get from 'lodash.get';
import React from 'react';
import PropTypes from 'prop-types';
import SingleFieldInfo from '../common/SingleFieldInfo';

const status = 'pesticide_status';

const Pesticides = ({ row, dimensions, editable, disabled, isIsolocity, isolocityTestResult, requiredDimensions,
                      isLabApplyProfiles, displayCompletionStatus}) => (
  <SingleFieldInfo
    row={row || {}}
    status={status}
    columns={get(dimensions, 'pesticides', [])}
    requiredDimensions={requiredDimensions}
    editable={editable}
    disabled={disabled}
    isIsolocity={isIsolocity}
    isolocityStatus={get(isolocityTestResult, `inspection.sub_statuses.pesticides_status`, 'In Progress')}
    isLabApplyProfiles={isLabApplyProfiles}
    displayCompletionStatus={displayCompletionStatus}
  />
);

Pesticides.propTypes = {
  row: PropTypes.object,
  dimensions: PropTypes.object,
  editable: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  isIsolocity: PropTypes.bool,
  isolocityTestResult: PropTypes.object,
  requiredDimensions: PropTypes.array,
  isLabApplyProfiles: PropTypes.bool,
  displayCompletionStatus: PropTypes.bool,
};

Pesticides.defaultProps = {
  disabled: false
};

export default Pesticides;
