import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import {Field} from 'redux-form';
import PrePackPackageSelection from './PrePackPackageSelection'; // eslint-disable-line import/no-named-as-default
import InternationalCurrencyInput from '../../../common/form/InternationalCurrencyInput';
import InternationalDecimalInput from '../../../common/form/InternationalDecimalInput';
import TextInput from '../../../common/form/TextInput';
import AddItem from '../../../common/form/AddItem';
import ContentConcealer from '../../../common/concealers/ContentConcealer';

const PrePackPackages = ({fields, change, isApplyStorageLocation, locations, childItemMasters, initialItemMasters,
                           isEditMode, line, editableLinePrice, unitPrice, prepackExpendedInventory, isComplete,
                           integrationState, transferId, inventoryLocked, formName, isMedicated, formData,
                           itemMasterIsMedicated, itemMaster}) => {

  const {isMetrc, isBiotrack, isMetrcTransfersEnabled} = integrationState;
  const medicatedTransfersRequireEntireQuantity = get(formData, 'medicatedTransfersRequireEntireQuantity', false);
  const entireQtyRequired = Boolean(medicatedTransfersRequireEntireQuantity && itemMasterIsMedicated);

  const selectedPackageCodes = fields.map((fieldName, index) => {
    const inventoryLine = fields.get ? fields.get(index) : {};
    return get(inventoryLine, 'package_code', 0);
  });

  return (
    <React.Fragment>
      {fields.map((fieldName, index) => {

        const initialItemMaster = initialItemMasters && initialItemMasters[index] ? initialItemMasters[index] : {};

        if(Array.isArray(prepackExpendedInventory)) {
          const expendedItems = prepackExpendedInventory.filter((item) => item.item_master_id === initialItemMaster.id);
          if (expendedItems.length) {
            initialItemMaster.items = initialItemMaster.items.concat(expendedItems);
          }
        }

        return (
          <div key={index}>
            <Row>
              <PrePackPackageSelection
                isApplyStorageLocation={isApplyStorageLocation}
                locations={locations}
                fieldName={`${fields.name}[${index}]`}
                fields={fields}
                unitPrice={unitPrice}
                editableLinePrice={editableLinePrice}
                change={change}
                index={index}
                initialItemMaster={initialItemMaster}
                childItemMasters={childItemMasters}
                isEditMode={isEditMode}
                getFormValues={() => fields.get(index)}
                line={line}
                selectedPackageCodes={selectedPackageCodes}
                isComplete={isComplete}
                transferId={transferId}
                formName={formName}
              />
            </Row>
            <Row>
              <ContentConcealer show={!line.is_lab_partner}>
                <Col xs={6} md={4} lg={3}>
                  <InternationalCurrencyInput name={`${fieldName}.unit_price`}
                    props={{
                      label: I18n.t('purchaseOrders.form.unitPrice'),
                      readOnly: editableLinePrice,
                      disabled: isComplete
                    }}
                  />
                </Col>
              </ContentConcealer>
              <Col xs={6} md={4} lg={3}>
                <InternationalDecimalInput name={`${fieldName}.qty`}
                 disabled={isComplete || entireQtyRequired}
                 fractionPartSize={0}
                 props={{
                   readOnly: isBiotrack || (isMetrcTransfersEnabled && isComplete),
                   label: I18n.t('purchaseOrders.form.unitsTransfered')
                 }}
                />
              </Col>
              {isMetrc ? <Col xs={6} md={5} lg={4}>
                <Field
                  name={`${fieldName}.state_integration_tracking_id`}
                  component={TextInput}
                  props={{
                    label:I18n.t('common.form.trackingId'),
                    readOnly: true
                  }}
                />
              </Col> : null}
            </Row>
            <hr/>
          </div>
        );
      })}
      <AddItem
        addAction={() => fields.push({item_master_id: itemMaster.id})}
        removeAction={fields.pop}
        length={fields.length}
        addDisabled={inventoryLocked || entireQtyRequired}
        showMinus={!inventoryLocked && !entireQtyRequired}
      />
    </React.Fragment>
  );
};

PrePackPackages.propTypes = {
  unitPrice: PropTypes.number.isRequired,
  editableLinePrice: PropTypes.bool,
  fields: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  isApplyStorageLocation: PropTypes.bool,
  locations: PropTypes.array,
  childItemMasters: PropTypes.array.isRequired,
  initialItemMasters: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool,
  line: PropTypes.object.isRequired,
  prepackExpendedInventory: PropTypes.array.isRequired,
  isComplete: PropTypes.bool.isRequired,
  integrationState: PropTypes.object.isRequired,
  transferId: PropTypes.string,
  inventoryLocked: PropTypes.bool,
  formName: PropTypes.string.isRequired,
  isMedicated: PropTypes.bool,
  itemMasterIsMedicated: PropTypes.bool,
  formData: PropTypes.object.isRequired,
  itemMaster: PropTypes.object,
};

PrePackPackages.defaultProps = {
  isEditMode: false
};

export default PrePackPackages;
