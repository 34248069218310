/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {I18n} from 'react-redux-i18n';
import { bindActionCreators } from 'redux';
import {goBack, push} from 'react-router-redux';
import get from 'lodash.get';
import QuickMovePanel from './QuickMovePanel';
import {handleComplexSelectRow} from '../../actions/helpers/selectedDataHelper';
import {
  getCanManageInternalTransfers,
  getProductsForInternalTransfers,
  getSelectedProductIds,
  normalizeIngredients,
  getInventorySelector
} from '../../selectors/finishedProductsSelectors';
import {getFlattenedLocations} from '../../selectors/locationsSelectors';
import {unsetData, setData} from '../../actions/dataActions';
import {addMessage} from '../../actions/systemActions';
import {setListingSourceSolr, setListingSourceService, unsetListingSource} from '../../actions/listingActions';
import * as apiActions from '../../actions/apiActions';
import * as selectedDataActions from '../../actions/selectedDataActions';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import TablePageWrapper from '../common/grid/TablePageWrapper';
import PrinterModal from '../printer/PrinterModal';
import PageTitle from '../common/PageTitle';
import {getTotalResults} from '../../selectors/paginationSelectors';
import ServiceFirstAbstractInventoryPage from './common/ServiceFirstAbstractInventoryPage';
import InProgressOverlay from '../common/InProgressOverlay';
import {getIntegrationState} from '../../selectors/integration/integrationSelectors';
import { getUserHasPermissionFunc } from '../../selectors/usersSelectors';
import * as p from '../../constants/permissions';
import InternalTransfers from '../transfers/internal/InternalTransfers';
import ModalWrapper from '../common/ModalWrapper';
import { isFeatureEnabled } from '../../selectors/featureToggles';
import {
  isListingSourceService as isListingSourceServiceSelector,
  isListingSourceSolr as isListingSourceSolrSelector,
} from '../../selectors/listingSelectors';
import {getSolrCoreName} from '../../selectors/inventoryItemsSelectors';


const userHasEditPerm = getUserHasPermissionFunc(p.manage_internal_transfers);

export class ServiceFirstCultivationIngredientsInventoryPage extends ServiceFirstAbstractInventoryPage {

  constructor(props, context) {
    super(props, context);

    this.loadTabs = this.loadTabs.bind(this);
    this.setTabs = this.setTabs.bind(this);
    this.setTabsLoaded = this.setTabsLoaded.bind(this);
    this.ensureToken = this.ensureToken.bind(this);
    this.clearTokens = this.clearTokens.bind(this);
    this.cancelTokens = this.cancelTokens.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
    this.dataUpdated = this.dataUpdated.bind(this);
    this.reload = this.reload.bind(this);
    this.setActiveTab = this.setActiveTab.bind(this);
    this.filter = this.filter.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.unsetSearchData = this.unsetSearchData.bind(this);
    this.hideActivateModal = this.hideActivateModal.bind(this);
    this.getLabResults = this.getLabResults.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.getTableDataSize = this.getTableDataSize.bind(this);
    this.refreshActiveTab = this.refreshActiveTab.bind(this);

    this.switchTab = this.switchTab.bind(this);
    this.onTabChanged = this.onTabChanged.bind(this);
    this.isActiveTab = this.isActiveTab.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onIngredientAdjust = this.onIngredientAdjust.bind(this);
    this.productDetails = this.productDetails.bind(this);
    this.printLabel = this.printLabel.bind(this);
    this.hidePrinter = this.hidePrinter.bind(this);
    this.showTransferModal = this.showTransferModal.bind(this);
    this.hideTransferModal = this.hideTransferModal.bind(this);
    this.ref = React.createRef();
    this.state = {
      tabsLoaded: false,
      activeTab: null,
      tabs: [],
      ready: false,
      cancelTokens: {},
      showPrinter: false,
      item: {},
      reindexing: false,
      dataState: 'loading', // loaded, loading, or searching - actually driven by abstract page.
    };
  }

  componentWillMount() {
    this.unsetSearchData();
    this.props.actions.clearSelectedData(dataNames.products);

    this.props.actions.getUnpaginatedData(
      '/api/location_hierarchy',
      dataNames.locations,
      {failed: 'locations.getLocations.failed'},
      {is_sales_location: 0}
    );
    this.props.actions.getUnpaginatedData(
      '/api/ingredient_categories',
      dataNames.ingredientCategories
    );
    this.loadTabs().then(() => {
      //If url is not being changed, we still need to trigger initial tab change
      const tab = `ingredients/${this.props.params.status}`;
      this.switchTab(tab) || this.onTabChanged(tab);
    });
    this.props.actions.setData([], dataNames.itemMastersWithPricing);
    this.props.actions.getItem('/api/settings/values/by_key', itemNames.coreSettings, null,
      {ids: ['core_internal_transfers_enable']})
      .then(() => this.setTabs());
  }

  componentWillReceiveProps(nextProps) {
    //Tab has been changed
    if(this.state.tabsLoaded && (nextProps.params.status !== this.props.params.status)) {
      this.onTabChanged(`ingredients/${nextProps.params.status}`);
    }
  }

  switchTab(category) {
    this.setDataState('loading');
    const {tabs} = this.state;
    const isExistingTab = tabs && tabs.some(tab => tab.eventKey === category);
    if (`ingredients/${this.props.params.status}` !== category) {
      this.props.actions.push(`/inventory${isExistingTab ? `/${category}` : ''}`);
      return true;
    }
    return false;
  }

  onTabChanged(category) {
    const activeTab = this.state.tabs.find(tab => tab.eventKey === category);
    this.setActiveTab(activeTab, () => {
      this.filter(category);
    });
  }

  isActiveTab(status) {
    return (arguments.length ? status : this.props.params.status) !== 'inactive';
  }

  onSubmit(formData) {
    const item = {
      id: formData.item_id,
      inventory_location_id: formData.inventory_location_id,
      transacted_qty: formData.qty,
    };
    this.props.actions.postData(`/api/items/${item.id}/move`,
      item,
      dataNames.items,
      {success:'cultivation.finishedProduct.form.quickmoveSuccess', failed: 'cultivation.finishedProduct.form.quickmoveFailed'},
      null,
      () => {
        // Refresh the listing
        this.filter(this.state.activeTab.eventKey, false);
        this.reload();
      }
    );
  }

  onIngredientAdjust(formData) {
    const item = {
      id: formData.item_id,
      inventory_location_id: formData.inventory_location_id,
      transacted_qty: formData.qty,
      transaction_type: 'NEW_LEVEL',
      uom: formData.uom
    };
    this.props.actions.postData(`/api/items/${item.id}/set_new_level`,
      item,
      dataNames.items,
      {success:'cultivation.finishedProduct.form.adjustSuccess', failed: 'cultivation.finishedProduct.form.adjustFailed'},
      null,
      () => this.reload()
    );
  }

  productDetails(row) {
    const {locations, selectedProductIds, integrationState} = this.props;
    return (
      <QuickMovePanel
        showSelect={true}
        userHasEditPermission={true}
        active={this.isActiveTab()}
        handleSelect={this.handleSelect}
        locations={locations}
        selectedProductIds={selectedProductIds}
        row={row}
        onSubmit={this.onSubmit}
        printLabel={this.printLabel}
        isManufacturing={true}
        isIngredient={true}
        onIngredientAdjust={this.onIngredientAdjust}
        integrationState={integrationState}
      />
    );
  }

  printLabel(event) {}

  hidePrinter() {
    this.setState({showPrinter: false});
  }

  render() {
    const {activeTab, tabs, showPrinter, labelTag, labelIds, dataState} = this.state;
    const {selectedProductIds} = this.props;
    return (
      <div>
          <PageTitle
            primaryText={I18n.t('cultivation.finishedProduct.inventory')}
            secondaryText={I18n.t('cultivation.finishedProduct.finishedProduct')}
          />
        {activeTab ?
          <TablePageWrapper
            ref={this.ref}
            settingKey='finished-products-ingredients'
            columns={activeTab.columns}
            tabs={tabs}
            activeTab={activeTab.eventKey}
            switchTab={this.switchTab}
            data={this.getTableData()}
            dataState={dataState}
            useRenderDataFlag={true}
            handleSelect={this.handleSelect}
            selectedRows={selectedProductIds}
            scanSearchCol='item_number'
            hideScanSearch={false}
            dataTotalSize={this.getTableDataSize()}
            externalSearch={this.handleSearch}
            showHistory={true}
            hideExport={true}
            external={true}
            useAutoSuggest={this.props.useAutoSuggest && this.props.useInventoryAutoSuggest}
            autoSuggestPlaceholder='cultivation.finishedProduct.table.suggestPlaceholder'
            sort={'lot_created_at desc'}
            className='finished-product-page'
            bstProps={{
              options: {
                onSearchChange: this.onSearchChange,
                hideSizePerPage: true,
                expandBy: 'column'
              },
              selectRow: {
                clickToSelect: false,
                hideSelectColumn: true,
                clickToExpand: true
              },
              expandableRow: () => true,
              expandComponent: this.productDetails,
              ignoreSinglePage: true
            }}
          /> :
          <InProgressOverlay isActive={true} message={I18n.t('common.form.loading')}/>
        }
        <PrinterModal
          ref='printerModal'
          showPrinter={showPrinter}
          hidePrinter={this.hidePrinter}
          labelTag={labelTag}
          labelIds={labelIds}
        />
        <ModalWrapper
          title={`Internal Transfer`}
          onHide={this.hideTransferModal}
          showModal={this.state.showTransferModal}
          headerClass='bg-info-dark'
          dialogClassName='modal-xl'
          version={2}
        >
          <InternalTransfers
            selectedProducts={this.props.internalTransferProducts}
            handleSelect={this.handleSelect}
            onCancel={this.hideTransferModal}
            isIngredient={true}
          />
        </ModalWrapper>
      </div>
    );
  }
}

ServiceFirstCultivationIngredientsInventoryPage.propTypes = {
  products: PropTypes.array.isRequired,
  selectedProductIds: PropTypes.array.isRequired,
  internalTransferProducts: PropTypes.array,
  locations: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
  ingredientCategories: PropTypes.array.isRequired,
  dataTotalSize: PropTypes.number.isRequired,
  actions: PropTypes.shape({
    ensureGetUnpaginatedData: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    getSearchData: PropTypes.func.isRequired,
    getData: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired,
    setData: PropTypes.func.isRequired,
    handleComplexSelectRow: PropTypes.func.isRequired,
    clearSelectedData: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    addMessage: PropTypes.func.isRequired,
    setListingSourceSolr: PropTypes.func.isRequired,
    setListingSourceService: PropTypes.func.isRequired,
    unsetListingSource: PropTypes.func.isRequired
  }).isRequired,
  params: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  isListingSourceService: PropTypes.bool.isRequired,
  isListingSourceSolr: PropTypes.bool.isRequired,
  inventoryByLots: PropTypes.array,
  useAutoSuggest: PropTypes.bool,
  useInventoryAutoSuggest: PropTypes.bool,
  pageSize: PropTypes.number,
};

function mapStateToProps(state, ownProps) {

  const isListingSourceService = isListingSourceServiceSelector(state);
  const isListingSourceSolr = isListingSourceSolrSelector(state);

  const inventoryByLots = isListingSourceService
    ? getInventorySelector(state)
    : [];

  const products = isListingSourceSolr
    ? normalizeIngredients(state, {groupByPackage: ownProps.params.status === 'inactive'})
    : [];

  return {
    state,
    selectedProducts: state[dataNames.selectedProducts],
    internalTransferProducts: getProductsForInternalTransfers(state),
    selectedProductIds: getSelectedProductIds(state),
    ingredientCategories: state[dataNames.ingredientCategories],
    locations: getFlattenedLocations(state),
    dataTotalSize: getTotalResults(state, {name: dataNames.products}),
    integrationState: getIntegrationState(state),
    canManageInternalTransfers: getCanManageInternalTransfers(state),
    userHasEditPermission: userHasEditPerm(state),
    useAutoSuggest: isFeatureEnabled(state)('feature_solr_inventory_suggest'),
    useInventoryAutoSuggest: isFeatureEnabled(state)('feature_solr_inventory_suggest_inv_page_enable'),
    products,
    inventoryByLots,
    isListingSourceService,
    isListingSourceSolr,
    pageSize: get(state, `${itemNames.columnVisibilitySettings}.per_page`, false),
    solrCoreName: getSolrCoreName(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...apiActions,
    ...selectedDataActions,
    handleComplexSelectRow,
    goBack,
    push,
    unsetData,
    setData,
    addMessage,
    setListingSourceSolr,
    setListingSourceService,
    unsetListingSource
  };
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceFirstCultivationIngredientsInventoryPage);
