import React from 'react';
import PropTypes from 'prop-types';

import AppNavigationList from '../navigation/AppNavigationList';


const NavigationPane = ({id}) => {
  return (
    <div id={id}>
        <AppNavigationList />
    </div>
  );
};

NavigationPane.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default NavigationPane;
