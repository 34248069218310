/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {I18n} from 'react-redux-i18n';
import {goBack} from 'react-router-redux';
import {Alert} from 'react-bootstrap';
import InternationalDateTimeStatic from '../common/form/InternationalDateTimeStatic';
import {getItem, getPaginatedData} from '../../actions/apiActions';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import FormWrapper from '../common/form/FormWrapper';
import TablePage from '../common/grid/TablePage';
import InternationalCurrencyStatic from '../common/form/InternationalCurrencyStatic';
import registerTransactionTypes from '../../constants/registerReasons';
import {getCurrencySymbol} from '../../selectors/InternationalOperationsSelectors';
import { getTotalResults } from '../../selectors/paginationSelectors';

export class RegisterHistoryPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    const searchString = '';
    const sizePerPage = 50;

    const columns = [
      {name: 'registers.table.id',
        dataId:'id',
        hidden: false,
        width: '70px'},
      {name: 'registers.table.originalDate',
        dataId:'event_date',
        hidden: false,
        formatter: (cell) => <InternationalDateTimeStatic>{cell}</InternationalDateTimeStatic>,
        width: '100px'},
      {name: 'registers.table.dateOfAdjustment',
        dataId:'created_at',
        hidden: false,
        formatter: (cell) => <InternationalDateTimeStatic>{cell}</InternationalDateTimeStatic>,
        width: '100px'},
      {name: 'registers.table.type',
        dataId:'type',
        hidden: false,
        dataAlign: 'center',
        width: '100px'},
      {name: 'registers.table.amount',
        dataId:'transacted_amount',
        hidden: false,
        formatter: (cell, row) => <InternationalCurrencyStatic>{cell}</InternationalCurrencyStatic>,
        dataAlign: 'center',
        width: '100px'},
      {name: 'registers.table.newLevel',
        dataId:'balance_after',
        hidden: false,
        formatter: (cell, row) => <InternationalCurrencyStatic>{cell}</InternationalCurrencyStatic>,
        dataAlign: 'center',
        width: '100px'},
      {name: 'registers.table.user',
        dataId:'user_name',
        hidden: false,
        dataAlign: 'center',
        width: '100px'},
      {name: 'registers.table.action',
        dataId:'action_type',
        hidden: false,
        formatter: (cell, row) => I18n.t(`registers.table.${cell}`),
        dataAlign: 'center',
        width: '100px'},
      {name: 'registers.table.reason',
        dataId:'reason_code',
        hidden: false,
        formatter: cell => {
          const reason = registerTransactionTypes.find(reason => cell && reason.value === cell);
          return (reason && reason.text) || cell.replace(/_/g, ' ');
        },
        dataAlign: 'center',
        width: '100px'},
      {name: 'registers.table.notes',
        dataId:'notes',
        hidden: false,
        dataAlign: 'center',
        width: '100px'},
    ];

    this.state = {columns, searchString, sizePerPage};
    this.exportCSV = this.exportCSV.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
    this.setSizePerPage = this.setSizePerPage.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.updateColumnSettings = this.updateColumnSettings.bind(this);
    this.ref = React.createRef();
  }

  componentWillMount() {
    if (this.props.params.id) {
      this.props.actions.getPaginatedData(`/api/registers/${this.props.params.id}/transactions`,
        dataNames.registerTransactions, {failed:'registers.getTransactions.fail'}, {per_page: 10});
      this.props.actions.getItem(`/api/registers/${this.props.params.id}`,
        itemNames.register, {failed: 'registers.getRegister.fail'});
    }
  }

  setSizePerPage(sizePerPage) {
    this.setState({sizePerPage});
  }

  exportCSV () {
    this.refs.table.handleExportCSV();
  }

  setVisibleColumns (event) {
    const {columns} = this.state;
    const {id} = event.target;
    columns[id].hidden = !columns[id].hidden;
    this.setState({columns:[...columns]});
  }

  updateSearch (event) {
    const {value} = event.target;
    this.setState({searchString:value});
    this.refs.table.handleSearch(value);
  }

  handleSearch(sort, search, per_page, start, filter) {

    //currently the sort function is not enabled for this page. If it gets enabled then follow the instructions on RegistersRepository.php
    const params = { sort: sort || 'created_at desc' };

    if(sort){
      params.sort = sort;
    }
    if(search){
      params.search = search;
    }
    if(per_page){
      params.per_page = per_page;
    }
    if(start){
      params.page = (start ? start / per_page : 0) + 1;
    }
    if (this.props.params.id) {
      this.props.actions.getPaginatedData(`/api/registers/${this.props.params.id}/transactions`,
        dataNames.registerTransactions, { failed: 'registers.getTransactions.fail' }, params);
    }
  }

  updateColumnSettings({data, per_page}) {
    this.handleSearch(null, null, per_page, 0, null);
  }

  render () {
    const {register, transactions, dataTotalSize} = this.props;
    const {goBack} = this.props.actions;
    const {columns} = this.state;

    return (
      <div className='register-history-page'>
        <FormWrapper title={'registers.history.title'} titleVariables={{register: register.name}} goBack={goBack}>
          <Alert variant='warning'>
            Starting 6/15/23, Register Transactions will only be available for previous 90 days. Please use Analytics module
            for Register Transactions older than 90 days.
          </Alert>
          <TablePage
            ref={this.ref}
            actions={[]}
            columns={columns}
            data={transactions}
            handleSelect={() => {}}
            settingKey='register-history-page'
            dataState='loaded'
            selectedRows={[]}
            className='register-page'
            externalSearch={this.handleSearch}
            handleSearch={this.handleSearch}
            dataTotalSize={dataTotalSize}
            updateColumnSettings={this.updateColumnSettings}
            bstProps={{
              options: {
                hideSizePerPage: true,
                onRowClick: row => {}
              }
            }}
            settings={{
              per_page: 10
            }}
            external={true}
          />
        </FormWrapper>
      </div>
    );
  }
}

RegisterHistoryPage.propTypes = {
  register: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  transactions: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    getPaginatedData: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }),
  params: PropTypes.object.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  dataTotalSize: PropTypes.number.isRequired,
};

function mapStateToProps(state, ownProps){
  const { register, registerTransactions, timezone } = state;
  return {
    register,
    timezone,
    currencySymbol: getCurrencySymbol(state),
    dataTotalSize: getTotalResults(state, { name: dataNames.registerTransactions }),
    transactions: registerTransactions.map(transaction => {
      const type = transaction.transacted_amount < 0
        ? I18n.t('registers.table.debit')
        :  I18n.t('registers.table.credit');
      return Object.assign({}, transaction, {
        type
      });
    })
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {goBack, getItem, getPaginatedData};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (RegisterHistoryPage);
