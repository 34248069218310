import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Table, Card} from 'react-bootstrap';

import ContentConcealer from '../../../common/concealers/ContentConcealer';

const ItemUsedForFeedingScheduleDetails = ({delta, model}) => {
  const feedingScheduleName = model && model.transaction && model.transaction.feeding_schedule && model.transaction.feeding_schedule.schedule_name;
  return (
    <Card>
      <Table striped className='item-details'>
        <thead>
        <tr>
          <th>{I18n.t('itemTransactions.field')}</th>
          <th>{I18n.t('itemTransactions.valueAdjustment')}</th>
        </tr>
        </thead>
        <tbody>
        <ContentConcealer show={Boolean(feedingScheduleName)}>
          <tr>
            <td>{I18n.t('itemTransactions.item.feedingSchedule')}</td>
            <td>{feedingScheduleName}</td>
          </tr>
        </ContentConcealer>
        {(delta || []).map((field) =>
          <tr key={field.name}>
            <td>{I18n.t(`itemTransactions.item.${field.name}`)}</td>
            <td>{field.prev} -> {field.current}</td>
          </tr>
        )}
        </tbody>
      </Table>
    </Card>
  );
};

ItemUsedForFeedingScheduleDetails.propTypes = {
  delta: PropTypes.array.isRequired,
  model: PropTypes.object.isRequired,
};

export default ItemUsedForFeedingScheduleDetails;
