// Factory Actions
//Rest Factory
export const GET_DATA_SUCCESS = 'GET_DATA_SUCCESS';
export const GET_DATA_FAILED = 'GET_DATA_FAILED';
export const GET_DATA_BATCH_SUCCESS = 'GET_DATA_BATCH_SUCCESS';
export const GET_DATA_BATCH_FAILED = 'GET_DATA_BATCH_FAILED';
export const DELETE_DATA = 'DELETE_DATA';
export const DELETE_DATA_SUCCESS = 'DELETE_DATA_SUCCESS';
export const DELETE_DATA_FAILED = 'DELETE_DATA_FAILED';
export const ADD_DATA_SUCCESS = 'ADD_DATA_SUCCESS';
export const ADD_DATA_FAILED = 'ADD_DATA_FAILED';
export const EDIT_DATA = 'EDIT_DATA';
export const EDIT_DATA_SUCCESS = 'EDIT_DATA_SUCCESS';
export const EDIT_DATA_FAILED = 'EDIT_DATA_FAILED';
export const UNSET_DATA = 'UNSET_DATA';
export const SET_DATA = 'SET_DATA';
export const START_API_REQUEST = 'START_API_REQUEST';
export const COMPLETE_API_REQUEST = 'COMPLETE_API_REQUEST';
export const GET_PAGINATION_SUCCESS = 'GET_PAGINATION_SUCCESS';
export const GET_PAGINATION_FAILED = 'GET_PAGINATION_FAILED';
export const UNION_DATA = 'UNION_DATA';
export const PUSH_DATA = 'PUSH_DATA';
export const FILTER_DATA_BATCH_BY_KEY = 'FILTER_DATA_BATCH_BY_KEY';

//Item Factory
export const SET_ITEM = 'SET_ITEM';
export const SET_ITEM_VALUE = 'SET_ITEM_VALUE';
export const UNSET_ITEM = 'UNSET_ITEM';
export const GET_ITEM_SUCCESS = 'GET_ITEM_SUCCESS';
export const GET_ITEM_FAILED = 'GET_ITEM_FAILED';
export const ADD_ITEM_SUCCESS = 'ADD_ITEM_SUCCESS';
export const ADD_ITEM_FAILED = 'ADD_ITEM_FAILED';
export const EDIT_ITEM_SUCCESS = 'EDIT_ITEM_SUCCESS';
export const EDIT_ITEM_FAILED = 'EDIT_ITEM_FAILED';
export const REMOVE_ITEM_SUCCESS = 'REMOVE_ITEM_SUCCESS';
export const REMOVE_ITEM_FAILED = 'REMOVE_ITEM_FAILED';

//Selected Items Factory
export const ADD_SELECTED_DATA = 'ADD_SELECTED_DATA';
export const REMOVE_SELECTED_DATA = 'REMOVE_SELECTED_DATA';
export const CLEAR_SELECTED_DATA = 'CLEAR_SELECTED_DATA';
export const SET_SELECTED_DATA = 'SET_SELECTED_DATA';
export const SET_SELECTED_DATA_IMMEDIATE = 'SET_SELECTED_DATA_IMMEDIATE';
export const ADD_SELECTED_ITEMS = 'ADD_SELECTED_ITEMS';
export const REMOVE_SELECTED_ITEMS = 'REMOVE_SELECTED_ITEMS';
export const CLEAR_SELECTED_ITEMS = 'CLEAR_SELECTED_ITEMS';
export const SET_SELECTED_ITEMS = 'SET_SELECTED_ITEMS';

//System Actions
export const HEALTH_CHECK_SUCCESS = 'HEALTH_CHECK_SUCCESS';
export const HEALTH_CHECK_FAILED = 'HEALTH_CHECK_FAILED';
export const CHECK_DEV_ENVIRONMENT = 'CHECK_DEV_ENVIRONMENT';
export const USER_VALIDATE_IN_PROGRESS = 'USER_VALIDATE_IN_PROGRESS';
export const USER_VALIDATE_SUCCESS = 'USER_VALIDATE_SUCCESS';
export const USER_VALIDATE_FAILED = 'USER_VALIDATE_FAILED';
export const LOAD_CSRF_TOKEN = 'LOAD_CSRF_TOKEN';
export const LOAD_TRANSLATIONS_FAILED = 'LOAD_TRANSLATIONS_FAILED';
export const START_SPINNER = 'START_SPINNER';
export const STOP_SPINNER = 'STOP_SPINNER';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const SET_INITIAL_VERSION = 'SET_INITIAL_VERSION';
export const SET_CURRENT_VERSION = 'SET_CURRENT_VERSION';
export const SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS';

//Menu Actions
export const TOGGLE_MAIN_NAV_MENU = 'TOGGLE_MAIN_NAV_MENU';
export const CLOSE_MAIN_NAV_MENU = 'CLOSE_MAIN_NAV_MENU';
export const TOGGLE_USER_OPTS_MENU = 'TOGGLE_USER_OPTS_MENU';
export const OPEN_USER_OPTS_MENU = 'OPEN_USER_OPTS_MENU';
export const TOGGLE_FACILITY_MENU = 'TOGGLE_FACILITY_MENU';
export const TOGGLE_CUST_DETAILS = 'TOGGLE_CUST_DETAILS';
export const CLOSE_CUST_DETAILS = 'CLOSE_CUST_DETAILS';
export const TOGGLE_CART_DETAILS = 'TOGGLE_CART_DETAILS';
export const CLOSE_CART_DETAILS = 'CLOSE_CART_DETAILS';
export const TOGGLE_APP_BAR = 'TOGGLE_APP_BAR';
export const TOGGLE_LIMITS_DETAILS = 'TOGGLE_LIMITS_DETAILS';

//User Actions
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const SET_TOKEN = 'SET_TOKEN';
export const UNSET_USER = 'UNSET_USER';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';
export const CLEAR_LOGIN_MESSAGE = 'CLEAR_LOGIN_MESSAGE';
export const SET_RETAIL_LOCATION = 'SET_RETAIL_LOCATION';
export const SET_RETAIL_REGISTER = 'SET_RETAIL_REGISTER';
export const UPDATE_USER = 'UPDATE_USER';
export const SET_USER = 'SET_USER';
export const SEARCH_USER_ROLES = 'SEARCH_USER_ROLES';

//Plant Actions
export const ADD_SELECTED_PLANTS = 'ADD_SELECTED_PLANTS';
export const REMOVE_SELECTED_PLANTS = 'REMOVE_SELECTED_PLANTS';
export const SET_PLANTING_BATCH = 'SET_PLANTING_BATCH';
export const SET_GRANDMOTHER_PLANT = 'SET_GRANDMOTHER_PLANT';
export const SET_ENDING_TAG = 'SET_ENDING_TAG';
export const DEFERRED_PLANT_ACTION_COMPLETE = 'DEFERRED_PLANT_ACTION_COMPLETE';
export const PLANT_PHASE_LIMIT_EXCEEDED = 'PLANT_PHASE_LIMIT_EXCEEDED';
export const LOAD_PLANT_SOURCE_INVENTORY_ITEMS = 'LOAD_PLANT_SOURCE_INVENTORY_ITEMS';

//Batch Actions
export const ADD_SELECTED_BATCHES = 'ADD_SELECTED_BATCHES';
export const REMOVE_SELECTED_BATCHES = 'REMOVE_SELECTED_BATCHES';

//Drestruction Actions
export const GET_DESTRUCTION_SUCCESS = 'GET_DESTRUCTION_SUCCESS';
export const GET_DESTRUCTION_FAILED = 'GET_DESTRUCTION_FAILED';
export const GET_DESTRUCTIONS_SUCCESS = 'GET_DESTRUCTIONS_SUCCESS';
export const GET_DESTRUCTIONS_FAILED = 'GET_DESTRUCTIONS_FAILED';
export const ADD_DESTRUCTION_SUCCESS = 'ADD_DESTRUCTION_SUCCESS';
export const ADD_DESTRUCTION_FAILED = 'ADD_DESTRUCTION_FAILED';
export const EDIT_DESTRUCTION_SUCCESS = 'EDIT_DESTRUCTION_SUCCESS';
export const EDIT_DESTRUCTION_FAILED = 'EDIT_DESTRUCTION_FAILED';
export const EDIT_DESTRUCTIONS_SUCCESS = 'EDIT_DESTRUCTIONS_SUCCESS';
export const EDIT_DESTRUCTIONS_FAILED = 'EDIT_DESTRUCTIONS_FAILED';

// Batch Actions
export const GET_BATCH_SUCCESS = 'GET_BATCH_SUCCESS';
export const GET_BATCH_FAILED = 'GET_BATCH_FAILED';
export const GET_BATCHES_SUCCESS = 'GET_BATCHES_SUCCESS';
export const GET_BATCHES_FAILED = 'GET_BATCHES_FAILED';
export const ADD_BATCH_SUCCESS = 'ADD_BATCH_SUCCESS';
export const ADD_BATCH_FAILED = 'ADD_BATCH_FAILED';
export const EDIT_BATCH_SUCCESS = 'EDIT_BATCH_SUCCESS';
export const EDIT_BATCH_FAILED = 'EDIT_BATCH_FAILED';
export const EDIT_BATCHES_SUCCESS = 'EDIT_BATCHES_SUCCESS';
export const EDIT_BATCHES_FAILED = 'EDIT_BATCHES_FAILED';

//Product Actions
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAILED = 'GET_PRODUCT_FAILED';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILED = 'GET_PRODUCTS_FAILED';
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
export const ADD_PRODUCT_FAILED = 'ADD_PRODUCT_FAILED';
export const EDIT_PRODUCT_SUCCESS = 'EDIT_PRODUCT_SUCCESS';
export const EDIT_PRODUCT_FAILED = 'EDIT_PRODUCT_FAILED';
export const EDIT_PRODUCTS_SUCCESS = 'EDIT_PRODUCTS_SUCCESS';
export const EDIT_PRODUCTS_FAILED = 'EDIT_PRODUCTS_FAILED';
export const SET_PRODUCT_FILTER = 'SET_PRODUCT_FILTER';

// Facility Actions
export const GET_FACILITIES_SUCCESS = 'GET_FACILITIES_SUCCESS';
export const GET_FACILITIES_FAILED = 'GET_FACILITIES_FAILED';
export const UPDATE_FACILITY = 'UPDATE_FACILITY';
export const SET_FACILITY_SUBMISSION_STATUS = 'SET_FACILITY_SUBMISSION_STATUS';
export const UPDATE_FACILITY_ORGANIZATION = 'UPDATE_FACILITY_ORGANIZATION';
export const FACILITY_SWITCH_IN_PROGRESS = 'FACILITY_SWITCH_IN_PROGRESS';
export const SET_FACILITY_SUCCESS = 'SET_FACILITY_SUCCESS';
export const SET_FACILITY_FAILED = 'SET_FACILITY_FAILED';
export const LOCATION_CHANGE_WITHOUT_FACILITY = 'LOCATION_CHANGE_WITHOUT_FACILITY';
export const SET_CUSTOMER_FILTERS = 'SET_CUSTOMER_FILTERS';
export const SET_TIMEZONE = 'SET_TIMEZONE';

// Cart Actions
export const RESET_CART = 'RESET_CART';
export const GET_COUPONS_SUCCESS = 'GET_COUPONS_SUCCESS';
export const GET_COUPONS_FAILED = 'GET_COUPONS_FAILED';
export const ADD_APPLIED_COUPON = 'ADD_APPLIED_COUPON';
export const REMOVE_COUPON = 'REMOVE_COUPON';
export const SET_COUPON = 'SET_COUPON';
export const SET_CHECKOUT = 'SET_CHECKOUT';
export const SET_ITEMS = 'SET_ITEMS';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const ADD_ITEM = 'ADD_ITEM';
export const GET_PRICING_WEIGHTS_SUCCESS = 'GET_PRICING_WEIGHTS_SUCCESS';
export const GET_PRICING_WEIGHTS_FAILED = 'GET_PRICING_WEIGHTS_FAILED';
export const GET_PACKAGE_IDS_SUCCESS = 'GET_PACKAGE_IDS_SUCCESS';
export const GET_PACKAGE_IDS_FAILED = 'GET_PACKAGE_IDS_FAILED';
export const SET_ITEM_PRICING_WEIGHT_ID = 'SET_ITEM_PRICING_WEIGHT_ID';
export const SET_ITEM_PACKAGE_ID = 'SET_ITEM_PACKAGE_ID';
export const SET_ITEM_QUANTITY = 'SET_ITEM_QUANTITY';
export const SET_TAX_RATE = 'SET_TAX_RATE';
export const SET_MANUAL_DISCOUNT = 'SET_MANUAL_DISCOUNT';
export const SET_MMAP_DISCOUNT = 'SET_MMAP_DISCOUNT';
export const SET_CREDIT = 'SET_CREDIT';
export const SET_CHECK = 'SET_CHECK';
export const SET_CASH = 'SET_CASH';
export const SET_DEBIT = 'SET_DEBIT';
export const SET_GIFT_CARD = 'SET_GIFT_CARD';
export const SET_STORE_CREDIT = 'SET_STORE_CREDIT';
export const SET_AMOUNT_TENDERED = 'SET_AMOUNT_TENDERED';
export const SET_CURRENT_ORDER = 'SET_CURRENT_ORDER';
export const CLOSE_ASSIGN_DRIVER = 'CLOSE_ASSIGN_DRIVER';
export const OPEN_ASSIGN_DRIVER = 'OPEN_ASSIGN_DRIVER';
export const REFUND_MAY_DIFFER = 'REFUND_MAY_DIFFER';

//customer Actions
export const SET_TOTAL_POINTS = 'SET_TOTAL_POINTS';

// Search Actions
export const GET_SEARCH_RESULTS_SUCCESS = 'GET_SEARCH_RESULTS_SUCCESS';
export const GET_SEARCH_RESULTS_FAILED = 'GET_SEARCH_RESULTS_FAILED';
export const CLEAR_SEARCH_META_DATA = 'CLEAR_SEARCH_META_DATA';

//Purchase Order Actions
export const ADD_SELECTED_PURCHASE_ORDERS = 'ADD_SELECTED_PURCHASE_ORDERS';
export const REMOVE_SELECTED_PURCHASE_ORDERS = 'REMOVE_SELECTED_PURCHASE_ORDERS';

//Transfer Actions
export const ADD_SELECTED_TRANSFERS = 'ADD_SELECTED_TRANSFERS';
export const REMOVE_SELECTED_TRANSFERS = 'REMOVE_SELECTED_TRANSFERS';

//Package Actions
export const ADD_SELECTED_PACKAGES = 'ADD_SELECTED_PACKAGES';
export const REMOVE_SELECTED_PACKAGES = 'REMOVE_SELECTED_PACKAGES';
export const SET_PACKAGE_INFO = 'SET_PACKAGE_INFO';
export const CHANGE_ITEM_MASTER = 'CHANGE_ITEM_MASTER';

//Finished Product Actions
export const ADD_SELECTED_PRODUCTS = 'ADD_SELECTED_PRODUCTS';
export const REMOVE_SELECTED_PRODUCTS = 'REMOVE_SELECTED_PRODUCTS';

//Harvest Actions
export const HARVEST_PLANTS_MULTIPLE_STRAINS = 'HARVEST_PLANTS_MULTIPLE_STRAINS';

//File actions
export const GET_FILE_SUCCESS = 'GET_FILE_SUCCESS';
export const GET_FILE_FAILED = 'GET_FILE_FAILED';

//Printer Actions
export const SET_QZ_TRAY = 'SET_QZ_TRAY';

//api metadata actions
export const SET_ETAG = 'SET_ETAG';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_REQUEST_START = 'SET_REQUEST_START';
export const SET_REQUEST_STOP = 'SET_REQUEST_STOP';
export const SET_REQUEST_HASH = 'SET_REQUEST_HASH';
export const SET_FACETS = 'SET_FACETS';
export const SET_META = 'SET_META';
export const UNSET_META = 'UNSET_META';
export const CLEAR_META = 'CLEAR_META';

//socket io actions
//server dispatched actions
export const SERVER_SET_SOCKET_ID = 'SERVER_SET_SOCKET_ID';
export const SERVER_UPDATE_AVAILABLE = 'SERVER_UPDATE_AVAILABLE';
export const SERVER_DATA_UPDATE_AVAILABLE = 'SERVER_DATA_UPDATE_AVAILABLE'; //breaking out this  update from SERVER_UPDATE_AVAILABLE as they are handled differently
export const USER_ALERT_CREATED = 'USER_ALERT_CREATED';

//client dispatched actions
export const SOCKET_SET_USER = 'SOCKET_SET_USER';
export const SOCKET_SET_FACILITY = 'SOCKET_SET_FACILITY';
export const SOCKET_UNSET_CONNECTION = 'SOCKET_UNSET_CONNECTION';

//Redux Form Actions
export const REDUX_FORM_CHANGE = '@@redux-form/CHANGE';
export const REDUX_FORM_FOCUS = '@@redux-form/FOCUS';
export const REDUX_FORM_INITIALIZE = '@@redux-form/INITIALIZE';
export const REDUX_FORM_REGISTER_FIELD = '@@redux-form/REGISTER_FIELD';
export const REDUX_FORM_UNREGISTER_FIELD = '@@redux-form/UNREGISTER_FIELD';
export const REDUX_FORM_BLUR = '@@redux-form/BLUR';
export const REDUX_FORM_ARRAY_PUSH = '@@redux-form/ARRAY_PUSH';
export const REDUX_FORM_ARRAY_INSERT = '@@redux-form/ARRAY_INSERT';
export const REDUX_FORM_ARRAY_REMOVE = '@@redux-form/ARRAY_REMOVE';
export const REDUX_FORM_SUBMIT_FAILED = '@@redux-form/SET_SUBMIT_FAILED';
export const REDUX_FORM_SUBMIT_SUCCESS = '@@redux-form/SET_SUBMIT_SUCCEEDED';
export const REDUX_FORM_SUBMIT_START = '@@redux-form/START_SUBMIT';
export const REDUX_FORM_SUBMIT_STOP = '@@redux-form/STOP_SUBMIT';

//Router Actions
export const ROUTER_LOCATION_CHANGE = '@@router/LOCATION_CHANGE';
export const ROUTER_CALL_HISTORY_METHOD = '@@router/CALL_HISTORY_METHOD';

//Custom Form Actions
export const FORM_CASCADE_FIELD_ARRAY_VALUE = 'FORM_CASCADE_FIELD_ARRAY_VALUE';
export const FORM_REGISTER_AS_OPEN = 'FORM_REGISTER_AS_OPEN';
export const FORM_UNREGISTER_AS_OPEN = 'FORM_UNREGISTER_AS_OPEN';
export const FORM_WARN_PENDING_CHANGES = 'FORM_WARN_PENDING_CHANGES';
export const FORM_DISMISS_DIRTY = 'FORM_DISMISS_DIRTY';
export const FORM_KEEP_DIRTY = 'FORM_KEEP_DIRTY';
export const FORM_IS_SUBMITTING = 'FORM_IS_SUBMITTING';
export const FORM_SUBMIT_COMPLETE = 'FORM_SUBMIT_COMPLETE';

//FacilityGroup Actions for settings predominantly
export const SETTINGS_BIND_TO_FACILITY_GROUP = 'SETTINGS_BIND_TO_FACILITY_GROUP';
export const SETTINGS_SAVE = 'SETTINGS_SAVE';

export const HANDLE_PRINT_JOB = 'HANDLE_PRINT_JOB';
export const HANDLE_PRINT_JOBS = 'HANDLE_PRINT_JOBS';
export const PRINT_PRINT_JOB = 'PRINT_PRINT_JOB';
export const QUEUE_PRINT_JOB = 'QUEUE_PRINT_JOB';
export const SET_PRINT_JOB = 'SET_PRINT_JOB';
export const REMOTE_PRINT_JOB = 'REMOTE_PRINT_JOB';
export const PRINT_JOB_MESSAGE = 'PRINT_JOB_MESSAGE';
export const IMPORT_LOCAL_PRINTERS = 'IMPORT_LOCAL_PRINTERS';
export const SET_SELECTED_PRINTER = 'SET_SELECTED_PRINTER';

export const PRINT_JOB_DISPATCH = 'PRINT_JOB_DISPATCH';
export const PRINT_JOB_RECEIVE = 'PRINT_JOB_RECEIVE';
export const PRINT_JOB_QUEUE = 'PRINT_JOB_QUEUE';
export const PRINT_JOB_PRINT = 'PRINT_JOB_PRINT';
export const PRINT_JOB_GET_BLOCK = 'PRINT_JOB_GET_BLOCK';

// ProductLookup actions
export const PRODUCT_LOOKUP_SHOW = 'PRODUCT_LOOKUP_SHOW';
export const PRODUCT_LOOKUP_HIDE = 'PRODUCT_LOOKUP_HIDE';
export const PRODUCT_LOOKUP_SELECT = 'PRODUCT_LOOKUP_SELECT';
export const PRODUCT_LOOKUP_CONFIRM = 'PRODUCT_LOOKUP_CONFIRM';
export const PRODUCT_LOOKUP_SET_CATEGORY = 'PRODUCT_LOOKUP_SET_CATEGORY';
export const PRODUCT_LOOKUP_SET_SUBCATEGORY = 'PRODUCT_LOOKUP_SET_SUBCATEGORY';
export const PRODUCT_LOOKUP_SEARCH = 'PRODUCT_LOOKUP_SEARCH';

// Feature Toggle actions
export const FEATURE_TOGGLE_UPDATED = 'FEATURE_TOGGLE_UPDATED';

// batch actions (redux internal optimization)
export const REDUX_BATCH_ACTION = 'BATCHING_REDUCER.BATCH';

// Supply Chain Actions
export const SALES_ORDER_LISTING_UPDATED = 'SALES_ORDER_LISTING_UPDATED';
export const PURCHASE_ORDER_LISTING_UPDATED = 'PURCHASE_ORDER_LISTING_UPDATED';

// Ad-hoc actions
export const PATIENT_LIMITS_EXCEEDED = 'PATIENT_LIMITS_EXCEEDED';
