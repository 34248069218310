/* eslint-disable import/no-mutable-exports*/
import { connect } from 'react-redux';
import {reduxForm, formValueSelector, change} from 'redux-form';
import CreateOrganizationForm from './CreateOrganizationForm';
import {validate} from './validateOrganization';
import {getIntegrationState} from '../../../selectors/integration/integrationSelectors';

let WrappedComponent = reduxForm({
  form: 'create-organization',
  change,
  validate
})(CreateOrganizationForm);

const selector = formValueSelector('create-organization');
WrappedComponent = connect(
  (state, ownProps) => {
    const facilities = selector(state, 'facilities');

    return {
      facilities: facilities || ownProps.initialValues.facilities || [],
      formSelector: (field) => selector(state, field),
      integrationState: getIntegrationState(state),
      retailFacilityTypes: ownProps.retailFacilityTypes || [],
    };
  }
)(WrappedComponent);

export default WrappedComponent;
