import React from 'react';
import PropTypes from 'prop-types';
import {getFormValues, reduxForm, Field} from 'redux-form';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {Row, Col} from 'react-bootstrap';
import Barcode from 'react-barcode';

import SubmitSection from '../../../common/form/SubmitSection';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import MultiselectInput from '../../../common/form/MultiselectInput';
import NumericInput from '../../../common/form/NumericInput';
import TextInput from '../../../common/form/TextInput';
import {validate} from './validate';

export class RewardsForm extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.populateForSelectedProductsOptions = this.populateForSelectedProductsOptions.bind(this);

    this.state = {
      productOptions: this.props.allProducts
    };
  }

  populateForSelectedProductsOptions(vendors) {
    const {change, allProducts} = this.props;
    const result = [];
    if (vendors.length) {
      //ToDo: Yet there are no partnerList items from backend, to properly map and populate products for dropdown...
      //ToDo: ...below is a test implementation. products data mocked
      vendors.forEach(vendor => allProducts.forEach(product => {
        if (!product.vendor) {
          return;
        } else if (product.vendor === vendor.vendor) {
          result.push(product);
        }
      }));
      this.setState({productOptions: result});
    } else {
      this.setState({productOptions: allProducts});
    }
    change('limitedToSelectedVendor', vendors);
  }

  render() {
    const {handleSubmit, valid, formValues, customerGroupData, partnerFacilities} = this.props;
    const limitRedemptionOptions = [
      {text: 'Visit', value: 1},
      {text: 'Hour', value: 2},
      {text: 'Day', value: 3},
      {text: 'Week', value: 4},
      {text: 'Month', value: 4},
      {text: 'Year', value: 4}
    ];
    const rewardOptions = [
      {text: I18n.t('retail.rewards.dollarAmount'), value: 1},
      {text: I18n.t('retail.rewards.percentageAmount'), value: 2}
    ];
    const dollarDiscountOptions = [
      {text: I18n.t('retail.rewards.singeLineItem'), value: 1},
      {text: I18n.t('retail.rewards.subtotal'), value: 3}
    ];
    const percentageDiscountOptions = [
      {text: I18n.t('retail.rewards.singeLineItem'), value: 1},
      {text: I18n.t('retail.rewards.indicatedItems'), value: 2},
      {text: I18n.t('retail.rewards.subtotal'), value: 3}
    ];
    const settings = {
      actionSettings: {
        submit: {
          invalid: !valid,
          text: I18n.t('common.form.submit'),
          action: event => handleSubmit(event, formValues)
        }
      }
    };
    return (
      <form onSubmit={handleSubmit}>
        <Row>
          <Col md={6} lg={5} offset={1} className='left-pane'>
            <h3>{I18n.t('retail.rewards.createReward')}</h3>
            <Row>
              <Col md={11} lg={10}>
                <Field name='name' component={TextInput} props={{
                  label: I18n.t('retail.rewards.name'),
                  isRequired: true
                }}/>
              </Col>
            </Row>
            <Row className='bar-code'>
              <Col lg={5} md={6}>
                <p className='bar-code-title'>{I18n.t('retail.rewards.rewardCode')}</p>
                <div className='bar-code-wrapper'>
                  <Barcode value={formValues.rewardCode}/>
                </div>
              </Col>
              <Col md={4}>
                <Field name='pointsRequired' component={NumericInput} props={{
                  label: I18n.t('retail.rewards.pointsRequired'),
                  isRequired: true
                }}/>
              </Col>
            </Row>
            <Row className='double-field-block'>
              <Col md={3} lg={3}>
                <Field name='rewardAmount' component={TextInput} props={{
                  label: I18n.t('retail.rewards.rewardAmount'),
                  isRequired: true
                }}/>
              </Col>
              <Col md={4} lg={4} className='second-field'>
                <Field name='rewardAmountSelect' component={ReactSelectInput} props={{
                  options: rewardOptions,
                  isRequired: true
                }}/>
              </Col>
            </Row>
            <Row>
              <Col md={7} lg={7}>
                <Field name='ifDollarAmount' component={ReactSelectInput} props={{
                  label: I18n.t('retail.rewards.ifDollarAmount'),
                  options: dollarDiscountOptions
                }}/>
              </Col>
            </Row>
            <Row>
              <Col md={7} lg={7}>
                <Field name='ifPercentageDiscount' component={ReactSelectInput} props={{
                  label: I18n.t('retail.rewards.ifPercentageDiscount'),
                  options: percentageDiscountOptions
                }}/>
              </Col>
            </Row>
          </Col>
          <Col md={6} lg={5} offset={1}>
            <h3>{I18n.t('retail.rewards.createReward')}</h3>
            <Row className='double-field-block'>
              <p className='double-field-label'>{I18n.t('retail.rewards.limitRedemption')}</p>
              <Col md={3}>
                <Field name='limitRedemption' component={NumericInput}/>
              </Col>
              <Col md={4} className='second-field'>
                <Field name='limitRedemptionSelect' component={ReactSelectInput}
                       props={{options: limitRedemptionOptions}}/>
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <Field name='limitedToSelectedGroups' component={MultiselectInput} props={{
                  label: I18n.t('retail.rewards.limitedToSelectedGroups'),
                  options: customerGroupData,
                  valueKey: 'id',
                  textKey: 'groupName'
                }}/>
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <Field name='limitedToSelectedVendor' component={MultiselectInput} props={{
                  label: I18n.t('retail.rewards.limitedToSelectedVendor'),
                  options: partnerFacilities,
                  valueKey: 'id',
                  textKey: 'partner_name',
                  onChange: this.populateForSelectedProductsOptions
                }}/>
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <Field name='isForTheSelectedProduct' component={MultiselectInput} props={{
                  label: I18n.t('retail.rewards.isForTheSelectedProduct'),
                  options: this.state.productOptions,
                  valueKey: 'id',
                  textKey: 'for_selected_product'
                }}/>
              </Col>
            </Row>
          </Col>
        </Row>
        <SubmitSection settings={settings}/>
      </form>
    );
  }
}

RewardsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  valid: PropTypes.bool,
  formValues: PropTypes.object,
  initialValues: PropTypes.object,
  customerGroupData: PropTypes.array,
  partnerFacilities: PropTypes.array,
  allProducts: PropTypes.array
};

const reduxRewardsForm = reduxForm({
  validate
})(RewardsForm);

function mapStateToProps(state) {
  const form = 'retailRewardsForm';
  const initialValues = {
    rewardCode: '0912663784999',
    isForTheSelectedProduct: [],
    limitedToSelectedGroups: [],
    limitedToSelectedVendor: []
  };
  return {
    form,
    formValues: getFormValues(form)(state) || initialValues,
    initialValues
  };
}

export default connect(mapStateToProps)(reduxRewardsForm);
