import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import get from 'lodash.get';
import { requiredFieldValidation, registerTransactionIsInRange } from '../../../common/form/redux-form/validations';

export const validateOrderForm = (values, props) => {
  const {integrationState: { isLeaf, isBiotrack, isOklahoma, isCanada}, partnerFacilityOptions} = props;
  const errors = {lines: []};
  const linesArrayErrors = [];

  errors.order_type = requiredFieldValidation(values.order_type, 'salesOrders.form.partnerName');
  errors.partner_id = requiredFieldValidation(values.partner_id, 'salesOrders.form.partnerName');
  if (!partnerFacilityOptions.length && values.partner_id) {
    errors.partner_facility_id = I18n.t('salesOrders.form.partnerFacilityEmpty');
  } else {
    errors.partner_facility_id = requiredFieldValidation(values.partner_facility_id, 'purchaseOrders.form.partnerFacility');
  }

  if (isOklahoma || isCanada) {
    errors.partner_facility_type = requiredFieldValidation(
      values.partner_facility_type,
      isOklahoma ? 'purchaseOrders.form.partnerFacilityType' : 'purchaseOrders.form.partnerType'
    );
    if (values.partner_id && values.partner_facility_id && (!props.facilityTypes || props.facilityTypes.length === 0)) {
      errors.partner_facility_type = isOklahoma ?
        I18n.t('purchaseOrders.form.noPartnerFacilityTypeAssigned') :
        I18n.t('purchaseOrders.form.noPartnerTypeAssigned');
    }
  }

  errors.payment_status = requiredFieldValidation(values.payment_status, 'purchaseOrders.form.status');
  errors.contains_medicated = (isLeaf || isBiotrack) && values.contains_medicated !== 0 ? requiredFieldValidation(values.contains_medicated) : null;

  errors.date_ordered = requiredFieldValidation(values.date_ordered, 'purchaseOrders.form.dateOrdered');
  errors.date_expected = requiredFieldValidation(values.date_expected, 'purchaseOrders.form.dateExpected');

  errors.order_class = requiredFieldValidation(values.order_class, 'salesOrders.form.order');

  if (isLeaf) {
    errors.transfer_type = requiredFieldValidation(values.transfer_type, 'salesOrders.form.transferType');
  }

  // Do not make the partner invoice required for now. The typical process is that the PO is not created until the SO is done.
  // if (values.is_return && !values.partner_invoice_number) {
  //   errors.partner_invoice_number = I18n.t('salesOrders.form.partnerInvoiceNumberRequiredError');
  // }

  if (values.lines && values.lines.length) {
    values.lines.forEach((line, index) => {
      const lineErrors = {};

      if (line && line.itemMaster && line.itemMaster.itemType === 'prepack') {
        // Only prevents prepacks from being validated separately... could be combined below but this seems easier to read
      }
      else {
        if( (!line || !line.qty) && (line.validate === undefined || line.validate) && line.itemMaster){
          lineErrors.qty = requiredFieldValidation(line.qty, `purchaseOrders.form.${line.itemMaster.itemType === 'unit' ? 'numUnits' : 'requestedAmount'}`);
        }
      }
      if (line.is_sample && !line.sample_type) {
        lineErrors.sample_type =  I18n.t('salesOrders.form.sampleTypeError');
      }
      if (line.is_sample && line.sample_type && !line.product_sample_type) {
        lineErrors.product_sample_type =  I18n.t('salesOrders.form.productSampleTypeError');
      }
      if (
        get(props, 'transfer.status') !== 'completed' &&
        line.itemMaster && !line.itemMaster.active &&
        !(line.itemMaster.item_active || line.itemMaster.has_items)
      ) {
        lineErrors.item_master_id = I18n.t('salesOrders.form.inactiveItemMasterError', {item_master: line.itemMaster.name});
      }
      linesArrayErrors[index] = lineErrors;
    });
  }
  else {
    errors.item_master_id = I18n.t('salesOrders.form.selectItemError');
  }
  if (linesArrayErrors.length) {
    errors.lines = linesArrayErrors;
  }
  if (values.lines && values.lines.length > props.transferLinesLimit && props.transferLinesLimit) {
    errors.lines._error = I18n.t('cultivation.transfers.form.maxItemsWithIntegratedSoError', {maxLine: props.transferLinesLimit});
  }

  errors.payments = {};

  if(values.payments && values.payments[0] && values.payments[0].payment_type && values.date_ordered){
    const date_ordered =  typeof values.date_ordered !== 'string' ? values.date_ordered : (moment(values.date_ordered).isValid ? moment(values.date_ordered) : null);
    errors.payments = values.payments.map(payment => {
      const date_diff = date_ordered && payment.payment_date && date_ordered.startOf('day').diff(moment(payment.payment_date).startOf('day'));
      const errors_line = {
        payment_type: requiredFieldValidation(payment.payment_type, 'purchaseOrders.form.paymentType'),
        register_id: requiredFieldValidation(payment.register_id, 'purchaseOrders.form.selectRegister'),
        user_id:  requiredFieldValidation(payment.user_id),
        amount:  requiredFieldValidation(payment.amount && payment.amount > 0, 'purchaseOrders.form.amount'),
      };
      if(!errors_line.amount){
        errors_line.amount = registerTransactionIsInRange(payment.amount, 'upper');
      }
      errors_line.payment_date = payment.payment_date && date_diff > 0 ? I18n.t('purchaseOrders.form.paymentDateError') : requiredFieldValidation(payment.payment_date, 'purchaseOrders.form.paymentDate');
      return errors_line;
    });
  }

  errors.payments._error = errors.payments && errors.payments[0] && errors.payments[0].register_id && errors.payments.some(error => error && Object.keys(error).some(key => error[key]))
    ? I18n.t('purchaseOrders.form.paymentPopulated')
    : undefined;

  if (values.oddMoney && values.oddMoney.amount <= -0.01) {
    errors.oddMoney = {
      register_id: requiredFieldValidation(values.oddMoney.register_id),
      payment_date: requiredFieldValidation(values.oddMoney.payment_date, 'purchaseOrders.form.paymentDate'),
    };
  }

  return errors;
};

export const validateTransferForm = values => {
  const errors = {
    driver: {}
  };
  if (!values.driver || !values.driver.id) {
    errors.driver.id = I18n.t('cultivation.purchaseOrders.form.driverError');
  }
  return errors;
};

export const shouldValidate = ({
                          values,
                          nextProps,
                          props,
                          initialRender,
                          structure
                        }) => {
  if (initialRender) {
    return true;
  }
  return (
      initialRender ||
      !structure.deepEqual(values, nextProps.values) ||
      props.partnerFacilityOptions !== nextProps.partnerFacilityOptions
  ); // must specifically check prop we know might change
};

export default {
  validateOrderForm
};
