export default  [
  {text: '(GMT-11:00) Pago Pago', value: 'Pacific/Pago_Pago'},
  {text: '(GMT-10:00) Hawaii Time', value: 'Pacific/Honolulu'},
  {text: '(GMT-09:00) Alaska Time', value: 'US/Alaska'},
  {text: '(GMT-08:00) Pacific Time', value: 'America/Los_Angeles'},
  {text: '(GMT-08:00) Pacific Time - Tijuana', value: 'America/Tijuana'},
  {text: '(GMT-07:00) Mountain Time', value: 'America/Denver'},
  {text: '(GMT-07:00) Mountain Time - Arizona', value: 'America/Phoenix'},
  {text: '(GMT-07:00) Mountain Time - Chihuahua, Mazatlan', value: 'America/Mazatlan'},
  {text: '(GMT-06:00) Central Time', value: 'America/Chicago'},
  {text: '(GMT-06:00) Central Time - Mexico City', value: 'America/Mexico_City'},
  {text: '(GMT-06:00) Central Time - Regina', value: 'America/Regina'},
  {text: '(GMT-06:00) Guatemala', value: 'America/Guatemala'},
  {text: '(GMT-05:00) Bogota', value: 'America/Bogota'},
  {text: '(GMT-05:00) Eastern Time', value: 'America/New_York'},
  {text: '(GMT-05:00) Lima', value: 'America/Lima'},
  {text: '(GMT-04:30) Caracas', value: 'America/Caracas'},
  {text: '(GMT-04:00) Atlantic Time - Halifax', value: 'America/Halifax'},
  {text: '(GMT-04:00) Guyana', value: 'America/Guyana'},
  {text: '(GMT-04:00) La Paz', value: 'America/La_Paz'},
  {text: '(GMT-04:00) Atlantic Standard Time - Puerto Rico', value: 'America/Puerto_Rico'},
  {text: '(GMT-03:00) Buenos Aires', value: 'America/Argentina/Buenos_Aires'},
  {text: '(GMT-03:00) Godthab', value: 'America/Godthab'},
  {text: '(GMT-03:00) Montevideo', value: 'America/Montevideo'},
  {text: '(GMT-03:30) Newfoundland Time - St. Johns', value: 'America/St_Johns'},
  {text: '(GMT-03:00) Santiago', value: 'America/Santiago'},
  {text: '(GMT-02:00) Sao Paulo', value: 'America/Sao_Paulo'},
  {text: '(GMT-02:00) South Georgia', value: 'Atlantic/South_Georgia'},
  {text: '(GMT-01:00) Azores', value: 'Atlantic/Azores'},
  {text: '(GMT-01:00) Cape Verde', value: 'Atlantic/Cape_Verde'},
  {text: '(GMT+00:00) Casablanca', value: 'Africa/Casablanca'},
  {text: '(GMT+00:00) Dublin', value: 'Europe/Dublin'},
  {text: '(GMT+00:00) Lisbon', value: 'Europe/Lisbon'},
  {text: '(GMT+00:00) London', value: 'Europe/London'},
  {text: '(GMT+00:00) Monrovia', value: 'Africa/Monrovia'},
  {text: '(GMT+01:00) Algiers', value: 'Africa/Algiers'},
  {text: '(GMT+01:00) Amsterdam', value: 'Europe/Amsterdam'},
  {text: '(GMT+01:00) Berlin', value: 'Europe/Berlin'},
  {text: '(GMT+01:00) Brussels', value: 'Europe/Brussels'},
  {text: '(GMT+01:00) Budapest', value: 'Europe/Budapest'},
  {text: '(GMT+01:00) Central European Time - Belgrade', value: 'Europe/Belgrade'},
  {text: '(GMT+01:00) Central European Time - Prague', value: 'Europe/Prague'},
  {text: '(GMT+01:00) Copenhagen', value: 'Europe/Copenhagen'},
  {text: '(GMT+01:00) Madrid', value: 'Europe/Madrid'},
  {text: '(GMT+01:00) Paris', value: 'Europe/Paris'},
  {text: '(GMT+01:00) Rome', value: 'Europe/Rome'},
  {text: '(GMT+01:00) Stockholm', value: 'Europe/Stockholm'},
  {text: '(GMT+01:00) Vienna', value: 'Europe/Vienna'},
  {text: '(GMT+01:00) Warsaw', value: 'Europe/Warsaw'},
  {text: '(GMT+02:00) Athens', value: 'Europe/Athens'},
  {text: '(GMT+02:00) Bucharest', value: 'Europe/Bucharest'},
  {text: '(GMT+02:00) Cairo', value: 'Africa/Cairo'},
  {text: '(GMT+02:00) Jerusalem', value: 'Asia/Jerusalem'},
  {text: '(GMT+02:00) Johannesburg', value: 'Africa/Johannesburg'},
  {text: '(GMT+02:00) Helsinki', value: 'Europe/Helsinki'},
  {text: '(GMT+02:00) Kiev', value: 'Europe/Kiev'},
  {text: '(GMT+02:00) Moscow-01 - Kaliningrad', value: 'Europe/Kaliningrad'},
  {text: '(GMT+02:00) Riga', value: 'Europe/Riga'},
  {text: '(GMT+02:00) Sofia', value: 'Europe/Sofia'},
  {text: '(GMT+02:00) Tallinn', value: 'Europe/Tallinn'},
  {text: '(GMT+02:00) Vilnius', value: 'Europe/Vilnius'},
  {text: '(GMT+03:00) Istanbul', value: 'Europe/Istanbul'},
  {text: '(GMT+03:00) Baghdad', value: 'Asia/Baghdad'},
  {text: '(GMT+03:00) Nairobi', value: 'Africa/Nairobi'},
  {text: '(GMT+03:00) Minsk', value: 'Europe/Minsk'},
  {text: '(GMT+03:00) Riyadh', value: 'Asia/Riyadh'},
  {text: '(GMT+03:00) Moscow+00 - Moscow', value: 'Europe/Moscow'},
  {text: '(GMT+03:30) Tehran', value: 'Asia/Tehran'},
  {text: '(GMT+04:00) Baku', value: 'Asia/Baku'},
  {text: '(GMT+04:00) Moscow+01 - Samara', value: 'Europe/Samara'},
  {text: '(GMT+04:00) Tbilisi', value: 'Asia/Tbilisi'},
  {text: '(GMT+04:00) Yerevan', value: 'Asia/Yerevan'},
  {text: '(GMT+04:30) Kabul', value: 'Asia/Kabul'},
  {text: '(GMT+05:00) Karachi', value: 'Asia/Karachi'},
  {text: '(GMT+05:00) Moscow+02 - Yekaterinburg', value: 'Asia/Yekaterinburg'},
  {text: '(GMT+05:00) Tashkent', value: 'Asia/Tashkent'},
  {text: '(GMT+05:30) Colombo', value: 'Asia/Colombo'},
  {text: '(GMT+06:00) Almaty', value: 'Asia/Almaty'},
  {text: '(GMT+06:00) Dhaka', value: 'Asia/Dhaka'},
  {text: '(GMT+06:30) Rangoon', value: 'Asia/Rangoon'},
  {text: '(GMT+07:00) Bangkok', value: 'Asia/Bangkok'},
  {text: '(GMT+07:00) Jakarta', value: 'Asia/Jakarta'},
  {text: '(GMT+07:00) Moscow+04 - Krasnoyarsk', value: 'Asia/Krasnoyarsk'},
  {text: '(GMT+08:00) China Time - Beijing', value: 'Asia/Shanghai'},
  {text: '(GMT+08:00) Hong Kong', value: 'Asia/Hong_Kong'},
  {text: '(GMT+08:00) Kuala Lumpur', value: 'Asia/Kuala_Lumpur'},
  {text: '(GMT+08:00) Moscow+05 - Irkutsk', value: 'Asia/Irkutsk'},
  {text: '(GMT+08:00) Singapore', value: 'Asia/Singapore'},
  {text: '(GMT+08:00) Taipei', value: 'Asia/Taipei'},
  {text: '(GMT+08:00) Ulaanbaatar', value: 'Asia/Ulaanbaatar'},
  {text: '(GMT+08:00) Western Time - Perth', value: 'Australia/Perth'},
  {text: '(GMT+09:00) Moscow+06 - Yakutsk', value: 'Asia/Yakutsk'},
  {text: '(GMT+09:00) Seoul', value: 'Asia/Seoul'},
  {text: '(GMT+09:00) Tokyo', value: 'Asia/Tokyo'},
  {text: '(GMT+09:30) Central Time - Darwin', value: 'Australia/Darwin'},
  {text: '(GMT+10:00) Eastern Time - Brisbane', value: 'Australia/Brisbane'},
  {text: '(GMT+10:00) Guam', value: 'Pacific/Guam'},
  {text: '(GMT+10:00) Moscow+07 - Magadan', value: 'Asia/Magadan'},
  {text: '(GMT+10:00) Moscow+07 - Yuzhno-Sakhalinsk', value: 'Asia/Vladivostok'},
  {text: '(GMT+10:00) Port Moresby', value: 'Pacific/Port_Moresby'},
  {text: '(GMT+10:30) Central Time - Adelaide', value: 'Australia/Adelaide'},
  {text: '(GMT+11:00) Eastern Time - Hobart', value: 'Australia/Hobart'},
  {text: '(GMT+11:00) Eastern Time - Melbourne, Sydney', value: 'Australia/Sydney'},
  {text: '(GMT+11:00) Guadalcanal', value: 'Pacific/Guadalcanal'},
  {text: '(GMT+11:00) Noumea', value: 'Pacific/Noumea'},
  {text: '(GMT+12:00) Majuro', value: 'Pacific/Majuro'},
  {text: '(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy', value: 'Asia/Kamchatka'},
  {text: '(GMT+13:00) Auckland', value: 'Pacific/Auckland'},
  {text: '(GMT+13:00) Fakaofo', value: 'Pacific/Fakaofo'},
  {text: '(GMT+13:00) Fiji', value: 'Pacific/Fiji'},
  {text: '(GMT+13:00) Tongatapu', value: 'Pacific/Tongatapu'},
  {text: '(GMT+14:00) Apia', value: 'Pacific/Apia'}
];
