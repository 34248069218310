import React from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';

import * as itemNames from '../../constants/itemNames';
import * as apiActions from '../../actions/apiActions';
import {isIncomingTransferMappingEnabled, getIntegrationState} from '../../selectors/integration/integrationSelectors';
import RouteButton from '../common/RouteButton';
import {isMappingRequiredForSupply} from '../../selectors/supplySelectors';
import {isFeatureEnabledProductMasters} from '../../selectors/accessSelectors';

export class SupplyChainPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
  }

  componentWillMount() {
    this.props.actions.getItem('/api/supply/settings', itemNames.supplySettings, {failed: 'stateIntegrators.settings.get.failed'});
    this.props.actions.getItem('/api/integration-settings', itemNames.integrationSettings, {failed: 'stateIntegrators.settings.get.failed'});
  }

  render() {
    const {forceMedicatedMapping, isMappingRequiredByPlatform, isMetrc, isMetrcTransfersEnabled, isFeatureEnabledProductMasters} = this.props;
    const showMappingMenu = forceMedicatedMapping || isMappingRequiredByPlatform;
    const showSupplyChainMappingMetrcMenu = isFeatureEnabledProductMasters && isMetrc;

    return (
      <div className='supply-chain'>
        <h1>{I18n.t('cultivation.supplyChain.title')}</h1>
        <Row>
          <Col xs={12} sm={6}>
            <h2>
              <span>{I18n.t('cultivation.supplyChain.outgoingInventory')}</span>
            </h2>
            <RouteButton path='/sales-orders/active' props={{block: true}}>
              <h3>{I18n.t('cultivation.supplyChain.salesOrders')}</h3>
            </RouteButton>
            <RouteButton path='/transfers/active' props={{block: true}}>
              <h3>{I18n.t('cultivation.supplyChain.transfers')}</h3>
            </RouteButton>
            {isMetrc && isMetrcTransfersEnabled &&
              <RouteButton path='/product/management' props={{block: true}}>
                <h3>{I18n.t('cultivation.supplyChain.metrcProductMapping')}</h3>
              </RouteButton>
            }
          </Col>
          <Col xs={12} sm={6}>
            <h2>
              <span>{I18n.t('cultivation.supplyChain.incomingInventory')}</span>
            </h2>
            <RouteButton path='/purchase-orders/active' props={{block: true}}>
              <h3>{I18n.t('cultivation.supplyChain.purchaseOrders')}</h3>
            </RouteButton>
            <RouteButton path='/inventory-receipts/active' props={{block: true}}>
              <h3>{I18n.t('cultivation.supplyChain.inventoryReceipts')}</h3>
            </RouteButton>
            {showMappingMenu ? <RouteButton path='/supply-chain-mapping/incoming' props={{block: true}}>
              <h3>{I18n.t('cultivation.supplyChain.supplyChainMapping')}</h3>
            </RouteButton> : null}
            {showSupplyChainMappingMetrcMenu ? <RouteButton path='/supply-chain-mapping/metrc' props={{block: true}}>
              <h3>{I18n.t('nav.subNav.supplyChainMappingMetrc')}</h3>
            </RouteButton> : null}
            {isMetrc && isMetrcTransfersEnabled &&
              <RouteButton path='/metrc-vendor-product-mapping' props={{block: true}}>
                <h3>{I18n.t('cultivation.supplyChain.metrcPartnerProductMapping')}</h3>
              </RouteButton>
            }
          </Col>
        </Row>
      </div>
    );
  }
}

SupplyChainPage.propTypes = {
  actions: PropTypes.shape({
    getItem: PropTypes.func.isRequired
  }).isRequired,
  forceMedicatedMapping: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  const {isMetrc, isMetrcTransfersEnabled} = getIntegrationState(state);
  return {
    forceMedicatedMapping: isIncomingTransferMappingEnabled(state),
    isMappingRequiredByPlatform: isMappingRequiredForSupply(state),
    isMetrc: isMetrc,
    isMetrcTransfersEnabled: isMetrcTransfersEnabled,
    isFeatureEnabledProductMasters: isFeatureEnabledProductMasters(state)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({},
    apiActions
  );
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplyChainPage);
