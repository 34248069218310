import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Button, ButtonToolbar, Col, Row} from 'react-bootstrap';

import MultiselectInput from '../../../common/form/MultiselectInput';
import TextInput from '../../../common/form/TextInput';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import {MODIFY_FACILITY_GROUP_FORM} from '../../../../constants/forms';

export const SharedFacilityGroupForm = ({facilities, submitting, pristine, handleSubmit, selectedFacilities, invalid, disabled, form, masterFacility}) => {
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col md={3}>
            <Field
              name='name'
              component={TextInput}
              props={{
                label: I18n.t('common.form.name'),
                placeholder: I18n.t('common.form.groupName'),
                isRequired: true,
                disabled
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Field
              name='facilities'
              component={MultiselectInput}
              props={{
                label: I18n.t('facilityGroupsSharing.form.selectFacilities'),
                options: facilities,
                textKey: 'name',
                valueKey: 'id',
                isRequired: true,
                disabled: disabled || [masterFacility]
              }}
            />
          </Col>
          <Col md={3}>
            <Field
              name='masterFacilityId'
              component={ReactSelectInput}
              props={{
                label: I18n.t('facilityGroupsSharing.form.masterFacility'),
                options: selectedFacilities,
                textKey: 'name',
                valueKey: 'id',
                isRequired: true,
                disabled: disabled || form === MODIFY_FACILITY_GROUP_FORM
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} className='add-item'>
            <ButtonToolbar className='float-right'>
              <Button
                type='submit'
                disabled={submitting || pristine || invalid}
                variant='primary'>
                {I18n.t('common.form.save')}
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>
      </form>
    </div>
  );
};

SharedFacilityGroupForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  masterFacility: PropTypes.object,
  facilities: PropTypes.array.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  selectedFacilities: PropTypes.array.isRequired,
  invalid: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  form: PropTypes.string.isRequired
};

export default SharedFacilityGroupForm;
