import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import {Field, FieldArray} from 'redux-form';
import get from 'lodash.get';
import * as dataNames from '../../../../constants/dataNames';
import { AlphaBeticalCaseInsensitive } from '../../../../util/sortHelper';
import AccordionPanel from '../../../common/AccordionPanel';
import VendorsFieldArray from '../common/VendorsFieldArray';
import UnitPriceAttributes from '../common/UnitPriceAttributes';
import WeightPriceAttributes from '../common/WeightPriceAttributes';
import FacilitySalesAttributesFieldArray from '../common/FacilitySalesAttributesFieldArray';

import { getRetailFacilityOptions } from '../../../../selectors/facilitiesSelectors';
import { getFacilitiesOptions } from '../../../../selectors/accountingSelectors';
import {getPricingGroupSorted} from '../../../../selectors/pricingGroupsSelectors';
import { generateUnitPriceListItem, generateWeightPriceListItem } from '../../../../selectors/forms/productFormSelectors';
import {getPricingWeights} from '../../../../selectors/pricingWeightsSelectors';

import { RETAIL, WHOLESALE } from '../../../../constants/saleTypes';
import { RECREATIONAL, MEDICAL } from '../../../../constants/consumerTypes';
import { GR } from '../../../../constants/uoms';
import {PRODUCT_MASTER_FORM} from '../../../../constants/forms';
import ReactSelectInput from '../../../common/form/ReactSelectInput';

const PricingPanel = (props) => {
  const {
    formValues, partners,
    facilityOptions, retailFacilityOptions, pricingWeights, pricingGroupSorted, change, pricingMultipliers
  } = props;

  const sales_uom = get(formValues, 'default_uom'); // GR or EA
  const categoryId = get(formValues, 'category_id');
  const product_type = get(formValues, 'product_type');

  const uomLabel = sales_uom ? I18n.t(`uoms.${sales_uom}.word`) : '<UOM>';
  const PriceComponent = sales_uom === GR ? WeightPriceAttributes : UnitPriceAttributes;
  // for facility mode sales attributes
  const generator = sales_uom === GR ? generateWeightPriceListItem : generateUnitPriceListItem;
  // TODO: confirm with Product team if we still need to support inherit_pricing_group_updates?
  const generatorRetailMedProps = { MEDICAL, RETAIL, inherit_pricing_group_updates: false, inherit_pricing_class_updates: null };
  const generatorRetailRecProps = { RECREATIONAL, RETAIL, inherit_pricing_group_updates: false, inherit_pricing_class_updates: null };
  const generatorWholesaleProps = { MEDICAL, WHOLESALE, inherit_pricing_group_updates: false, inherit_pricing_class_updates: null };
  // pricing group options
  const pricingGroupOptions = pricingGroupSorted.filter((pricingGroup) => pricingGroup.category_id === categoryId);

  return (
    <React.Fragment>
      <AccordionPanel  title='products.form.purchasingInfo' className= 'form-panel purchasing-info'>
        <FieldArray name='vendors' component={VendorsFieldArray} props={{partners, sales_uom}}/>
      </AccordionPanel>
      <AccordionPanel title='products.form.salesInfo' className='form-panel sales-info'>
        <AccordionPanel title='products.form.retailPricing' className='form-panel sales-info'>
          <Row>
            <Col sm={4}>
              <PriceComponent
                isWholesale={false}
                fieldName={`prices.organization.${RETAIL}.${MEDICAL}`}
                fieldLabel={['products.form.basePriceSaleType', { saleType: 'Medical', uom: uomLabel }]}
                facilityOptions={[]} // organization has no facility options
                pricingGroupOptions={pricingGroupOptions}
                pricingWeights={pricingWeights}
                disablePriceBlock={product_type === 'rec'}
                formValues={formValues}
                formName={PRODUCT_MASTER_FORM}
                change={change}
              />
            </Col>
            <Col sm={4}>
              <PriceComponent
                isWholesale={false}
                fieldName={`prices.organization.${RETAIL}.${RECREATIONAL}`}
                fieldLabel={['products.form.basePriceSaleType', { saleType: 'Recreational', uom: uomLabel }]}
                facilityOptions={[]} // organization has no facility options
                pricingGroupOptions={pricingGroupOptions}
                pricingWeights={pricingWeights}
                disablePriceBlock={product_type === 'med'}
                formValues={formValues}
                formName={PRODUCT_MASTER_FORM}
                change={change}
              />
            </Col>
            <Col sm={4}>
              {sales_uom === 'EA' &&
                <Field
                  name='prices.pricing_multiplier'
                  component={ReactSelectInput}
                  props={{
                    label: I18n.t('products.form.pricingMultiplier'),
                    options: pricingMultipliers,
                    textKey: 'name',
                    valueKey: 'id',
                  }}
                />
              }
            </Col>
          </Row>
          <AccordionPanel title='products.form.facilityPricing' className='form-panel sales-info' defaultExpanded={false}>
            <Row>
              <Col sm={12}>
                <FieldArray
                  name={`prices.facilities.${RETAIL}.${MEDICAL}`}
                  component={FacilitySalesAttributesFieldArray}
                  props={{
                    facilityOptions: retailFacilityOptions,
                    pushTemplate: generator(generatorRetailMedProps, categoryId, pricingWeights, pricingGroupOptions, sales_uom),
                    AttributesComponent: PriceComponent,
                    attributesComponentProps: {
                      isWholesale: false,
                      fieldLabel: ['products.form.basePriceSaleType', { saleType: 'Medical', uom: uomLabel }],
                      pricingGroupOptions: pricingGroupOptions,
                      pricingWeights: pricingWeights,
                      disablePriceBlock: product_type === 'rec',
                      formValues: formValues,
                      formName: PRODUCT_MASTER_FORM,
                      change: change,
                    }
                  }}
                />
                <FieldArray
                  name={`prices.facilities.${RETAIL}.${RECREATIONAL}`}
                  component={FacilitySalesAttributesFieldArray}
                  props={{
                    facilityOptions: retailFacilityOptions,
                    pushTemplate: generator(generatorRetailRecProps, categoryId, pricingWeights, pricingGroupOptions, sales_uom),
                    AttributesComponent: PriceComponent,
                    attributesComponentProps: {
                      isWholesale: false,
                      fieldLabel: ['products.form.basePriceSaleType', { saleType: 'Recreational', uom: uomLabel }],
                      pricingGroupOptions: pricingGroupOptions,
                      pricingWeights: pricingWeights,
                      disablePriceBlock: product_type === 'med',
                      formValues: formValues,
                      formName: PRODUCT_MASTER_FORM,
                      change: change,
                    }
                  }}
                />
              </Col>
            </Row>
          </AccordionPanel>
        </AccordionPanel>
        <AccordionPanel title='products.form.wholesalePricing' className='form-panel sales-info'>
          <Row>
            <Col sm={4}>
              <UnitPriceAttributes
                isWholesale={true}
                fieldName={`prices.organization.${WHOLESALE}`}
                fieldLabel={['products.form.basePriceSaleType', { saleType: 'Wholesale', uom: uomLabel }]}
                facilityOptions={[]} // organization has no facility options
                pricingWeights={pricingWeights}
                formValues={formValues}
                formName={PRODUCT_MASTER_FORM}
                change={change}
              />
            </Col>
          </Row>
          <AccordionPanel title='products.form.facilityPricing' className='form-panel sales-info' defaultExpanded={false}>
            <Row>
              <Col sm={12}>
                <h4>Wholesale Pricing</h4>
                <FieldArray
                  name={`prices.facilities.${WHOLESALE}`}
                  component={FacilitySalesAttributesFieldArray}
                  props={{
                    facilityOptions: facilityOptions,
                    pushTemplate: generator(generatorWholesaleProps, categoryId, pricingWeights, pricingGroupOptions, sales_uom),
                    AttributesComponent: UnitPriceAttributes,
                    attributesComponentProps: {
                      isWholesale: true,
                      fieldLabel: ['products.form.basePriceSaleType', { saleType: 'Wholesale', uom: uomLabel }],
                      pricingGroupOptions: pricingGroupOptions,
                      pricingWeights: pricingWeights,
                      formValues: formValues,
                      formName: PRODUCT_MASTER_FORM,
                      change: change,
                    }
                  }}
                />
              </Col>
            </Row>
          </AccordionPanel>
        </AccordionPanel>
      </AccordionPanel>
    </React.Fragment>
  );
};

PricingPanel.propTypes = {
  formValues: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  partners: PropTypes.array.isRequired,
  pricing_type: PropTypes.string,
  retailFacilityOptions: PropTypes.array.isRequired,
  facilityOptions: PropTypes.array.isRequired,
  pricingWeights: PropTypes.array.isRequired,
  pricingGroupSorted: PropTypes.array.isRequired,
  pricingMultipliers: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    partners: state[dataNames.partners],
    retailFacilityOptions: getRetailFacilityOptions(state),
    facilityOptions: getFacilitiesOptions(state),
    pricingWeights: getPricingWeights(state, {form: PRODUCT_MASTER_FORM}),
    pricingGroupSorted: getPricingGroupSorted(state).sort(AlphaBeticalCaseInsensitive),
    pricingMultipliers: state[dataNames.pricingMultipliers],
  };
}

export default connect(mapStateToProps)(PricingPanel);
