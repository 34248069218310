import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {goBack, push, go} from 'react-router-redux';
import { change, arrayPush, formValueSelector } from 'redux-form';
import get from 'lodash.get';
import omit from 'lodash.omit';
import * as itemNames from '../../constants/itemNames';
import * as dataNames from '../../constants/dataNames';
import {unsetItem} from '../../actions/itemActions';
import {PARTNER_FORM, PARTNER_CONTACTS} from '../../constants/forms';
import {postItem, getUnpaginatedData, getItem, putItem} from '../../actions/apiActions';
import {getPartnerFacilitiesByPartner} from '../../selectors/partnersSelectors';
import PartnerForm from './common/PartnerForm'; // eslint-disable-line import/no-named-as-default
import ContactsReduxForm from './common/ContactsReduxForm';
import FormWrapper from '../common/form/FormWrapper';
import {PERSON} from '../../constants/imageUrls';
import {getIntegrationState} from '../../selectors/integration/integrationSelectors';
import {isWasteTransferAvailableSelector} from '../../selectors/compliance/waste/wasteComplianceSelectors';
import {hasCanadianFacilities} from '../../selectors/facilitiesSelectors';
import {getInitialValues, getPayload} from '../../selectors/forms/partnerFormSelector';
import {getInternationalPhoneCountryCode, getInternationalPhoneNumber} from '../../util/internationalHelpers';
import {buildCompleteInternationalPhoneNumber}  from '../../util/formatHelper';
import {getPhoneShouldIncludeCountryCode} from '../../selectors/InternationalOperationsSelectors';

export class PartnerEditorPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.goBack = this.goBack.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.saveContacts = this.saveContacts.bind(this);
    this.changeUploadStatus = this.changeUploadStatus.bind(this);
    this.state = {
      isReady: false,
      partnerId: undefined,
      uploading: false,
      goBackAfterSubmit: props.location && props.location.state ? props.location.state.goBackAfterSubmit : false,
      goBackDepth: 1,
    };

  }

  componentWillMount() {
    const partnerId = this.props.params.id;
    this.componentInit(partnerId);
    this.props.actions.loadDataWhenUpdating(partnerId);
  }

  componentInit(partnerId){
    const isReady = !partnerId;
    this.setState({partnerId, isReady});
    this.props.actions.unsetItem(itemNames.image);
    this.props.actions.unsetItem(itemNames.partner);
    this.props.actions.getItem(
      '/api/cultivation/settings',
      itemNames.wasteCompliance,
      {failed: 'cultivation.wasteCompliance.get.failed'},
      {ids: ['cult_waste_destruction_compliance_settings', 'inv_waste_packaging']}
    );
    this.props.actions.getUnpaginatedData('/api/partner_groups', dataNames.partnerGroups);
    this.props.actions.getUnpaginatedData('/api/payment_terms/payment_terms_list', dataNames.paymentTerms);
  }

  componentWillReceiveProps(nextProps){
    if(this.state.isReady) return;
    if(this.props.params.id && Object.keys(nextProps.partner).length){
      this.setState({isReady: true});
    }
  }

  changeUploadStatus(uploading){
    this.setState({uploading});
  }

  onSubmit(formValues) {
    const {image, timezone, partner, params} = this.props;
    const {goBackAfterSubmit, goBackDepth} = this.state;


    formValues.facilities.forEach( (facility, index) => {
      formValues.facilities[index].facility_phone_country_code = getInternationalPhoneCountryCode(formValues.facilities[index].facility_phone);
      formValues.facilities[index].facility_phone = getInternationalPhoneNumber(formValues.facilities[index].facility_phone);
    });

    const payload = getPayload(formValues, image, timezone, partner);

    return (params.id > 0)
      ? this.props.actions.updatePartner(params.id, payload)
      : this.props.actions.createPartner(payload, goBackAfterSubmit, goBackDepth);
  }

  saveContacts (formValues, then) {
    const partnerId = this.props.partner.id;
    const messages = {
      success: 'contact.create.success',
      failed: 'contact.create.fail',
    };

    const getPayload = (data) => {
      return Object.assign({}, {
        ...omit(data, 'facilities'),
        facility_ids: data.facilities.map(facility => facility.id)
      },
      data.id ? {} : {partner_id: partnerId});
    };

    const handleContact = (data, index) => {
      const temp = Object.assign({}, data);
      temp.phone_country_code = getInternationalPhoneCountryCode(temp.phone_number);
      temp.phone_number = getInternationalPhoneNumber(temp.phone_number);

      return temp.id
        ? this.props.actions.putItem(`/api/partner_contacts/${temp.id}`, getPayload(temp), itemNames.partnerContact, messages)
        : this.props.actions.postItem('/api/partner_contacts', getPayload(temp), itemNames.partnerContact, messages, null, (temp) => {
          this.props.actions.change(PARTNER_CONTACTS, `contacts[${index}].id`, temp.id);
          this.props.actions.change(PARTNER_CONTACTS, `contacts[${index}].partner_id`, temp.partner_id);
        });
    };

    const promises = formValues.contacts.map(handleContact);

    return Promise.all(promises).then(() => {
      if (typeof then === 'function') then();
    });
  }

  goBack() {
    if (this.state.goBackAfterSubmit) {
      this.props.actions.go(-this.state.goBackDepth);
    } else {
      this.props.actions.push('/partners');
    }
  }

  render() {
    if(!this.state.isReady){
      return <div style={{fontSize: '32px', textAlign: 'center', marginTop: '100px'}}>Loading . . .</div>;
    }

    const {
      partnerGroups, paymentTerms, partnerFacilities, imageUrl, integrationState, isWasteTransferAvailable, isCanada,
      initialValues, isIntegratedPartner, isLabPartner, partner, facilities, currentValues, currentFacility,
      contactsInitialValues } = this.props;


    return (
      <div className='create-partner-page'>
        <FormWrapper
          title={this.state.partnerId ? 'partners.updateTitle' : 'partners.createTitle'}
          goBack={this.goBack}
        >
          <PartnerForm
            includeFacilities={true}
            partnerGroups={partnerGroups}
            paymentTerms={paymentTerms}
            changeUploadStatus={this.changeUploadStatus}
            uploading={this.state.uploading}
            imageUrl={imageUrl}
            integrationState={integrationState}
            isWasteTransferAvailable={isWasteTransferAvailable}
            isCanada={isCanada}
            isIntegratedPartner={isIntegratedPartner}
            isLabPartner={isLabPartner}
            onSubmit={this.onSubmit}
            isModifyForm={this.props.params.id}
            initialValues={initialValues}
            enableReinitialize={true}
            isOrgFacility={false}
            editMode={true}
            partner={partner}
            arrayPushAction={this.props.actions.arrayPush}
            facilities={facilities}
            currentValues={currentValues}
            currentFacility={currentFacility}
          />
          {
            !this.props.params.id && Object.keys(this.props.partner).length
              ? null
              : (<div>
                <hr/>
                <ContactsReduxForm
                  initialValues={contactsInitialValues}
                  enableReinitialize={true}
                  partnerFacilities={partnerFacilities}
                  handleSaveContact={this.saveContacts}
                  handleSaveAndAddContact={this.saveContacts}
                  disable={!this.props.partner.facilities || !this.props.partner.facilities.length}
                />
              </div>)
          }
        </FormWrapper>
      </div>
    );
  }
}

PartnerEditorPage.propTypes = {
  partner: PropTypes.object.isRequired,
  partnerGroups: PropTypes.array.isRequired,
  paymentTerms: PropTypes.array.isRequired,
  partnerFacilities: PropTypes.array.isRequired,
  imageUrl: PropTypes.string,
  image: PropTypes.object,
  timezone: PropTypes.string.isRequired,
  integrationState: PropTypes.object.isRequired,
  isWasteTransferAvailable: PropTypes.bool,
  isCanada: PropTypes.bool,
  initialValues: PropTypes.object,
  actions: PropTypes.shape({
    unsetItem: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }),
  isIntegratedPartner: PropTypes.bool,
  isLabPartner: PropTypes.bool,
  facilities: PropTypes.array,
  currentValues: PropTypes.object,
  currentFacility: PropTypes.object,
};

PartnerEditorPage.defaultProps = {
  imageUrl: PERSON
};

const selector = formValueSelector(PARTNER_FORM);

function mapStateToProps(state) {
  const {partnerGroups, paymentTerms, partner, image, timezone} = state;
  const imageUrl = image && image.url ? image.url.original : undefined;
  const isIntegratedPartner = (partner.facilities || []).some(facility => facility.state_integration_id);
  const isLabPartner = Boolean(partner.is_lab);
  const currentValues = get(state, `form.${PARTNER_FORM}.values`, {});
  const includeCountryCode = getPhoneShouldIncludeCountryCode(state);

  const contacts_details = get(state[itemNames.partner], 'contacts', []);
  contacts_details.forEach((contact, index) => {
    contacts_details[index].phone_number = buildCompleteInternationalPhoneNumber(contact.phone_number, contact.phone_country_code, includeCountryCode);
  });

  return {
    initialValues: getInitialValues(state),
    contactsInitialValues: {contacts: contacts_details},
    currentValues,
    partner,
    isWasteTransferAvailable: isWasteTransferAvailableSelector(state), // Used in building initial values
    isCanada: hasCanadianFacilities(state), // Used in building initial values
    isIntegratedPartner,
    isLabPartner,
    currentFacility: state[itemNames.facility],
    facilities: selector(state, 'facilities') ? selector(state, 'facilities') : [],
    image,
    imageUrl,
    partnerGroups,
    paymentTerms,
    partnerFacilities: getPartnerFacilitiesByPartner(state, {partner_id: partner.id}),
    timezone,
    integrationState: getIntegrationState(state),
  };
}

function mapDispatchToProps(dispatch) {

  const actions = bindActionCreators({
    unsetItem,
    getUnpaginatedData,
    postItem,
    putItem,
    getItem,
    change,
    arrayPush,
    goBack,
    push,
    go
  }, dispatch);


  return {
    actions: {
      ...actions,

      /**
       * Uses when organization already created
       * @param id
       * @param payload
       * @returns {*}
       */
      updatePartner(id, payload) {
        const messages = {
          success: `partners.modify.${get(payload, 'facilities', []).length === 0 ? 'success' : 'successWithFacilities'}`,
          failed:'partners.create.failed',
        };

        return dispatch(putItem(`/api/partners/${id}`, payload, 'noOp', messages)).then(this.fetchPartner);
      },

      /**
       * Uses at once when we're creating a partner
       * @param payload
       * @param goBackAfterSubmit
       * @param goBackDepth
       * @returns {*}
       */
      createPartner(payload, goBackAfterSubmit, goBackDepth) {
        const messages = {
          success: 'partners.create.success',
          failed:'partners.create.failed'
        };

        const redirectAfterCreate = partner => (
          goBackAfterSubmit
            ? dispatch(go(-goBackDepth))
            : dispatch(push(`/partners/${get(partner, 'id', 0)}/modify`))
        );

        return dispatch(postItem('/api/partners', payload, 'noOp', messages)).then(partner => {
          this.fetchPartner(partner);
          redirectAfterCreate(partner);
        });
      },

      fetchPartner(partner) {
        if (!partner.id) return;
        return dispatch(getItem(`/api/partners/${partner.id}`, itemNames.partner, {failed: 'partners.getPartner.failed'}, {detailed: 1}));
      },

      /***
       * Only loads when partner id is populated which comes from the route
       * @param partnerId
       */
      loadDataWhenUpdating(partnerId) {
        if(!partnerId) return;

        const fetchPartnerImage = (partner) => {
          if(!partner.image_file_id) return;
          dispatch(getItem(`/api/images/${partner.image_file_id}`, itemNames.image));
        };

        this.fetchPartner({ id: partnerId }).then(fetchPartnerImage);

        dispatch(getUnpaginatedData('/api/partner_facilities', dataNames.partnerFacilities));
        dispatch(getItem('/api/integration-settings', itemNames.integrationSettings, {failed: 'stateIntegratorSettings.get.failed'}));
      }
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (PartnerEditorPage);
