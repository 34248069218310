import React from 'react';
import PropTypes from 'prop-types';
import {reduxForm, Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Row, Col, Image, FormGroup, Form} from 'react-bootstrap';

import {FILE_TYPES} from '../../constants/fileUploads';
import TextInput from '../common/form/TextInput';
import FileInput from '../common/form/FileInput';
import NumericInput from '../common/form/NumericInput';
//import MultiSelectInput from '../common/form/MultiselectInput';
import SubmitSection from '../common/form/SubmitSection';
import {validate} from './validate';
import InternationalReduxPhoneInput from '../common/form/redux-form/InternationalReduxPhoneInput';
import * as itemNames from '../../constants/itemNames';

export const CreateVehicleForm = (props) => {
  const {
    onImageChange,
    onDocChange,
    imageUrl,
    invalid,
    submitting,
    integrationState: {isLeaf, isBiotrack},
    change,
    changeUploadStatus,
    uploading,
    documentUrl,
  } = props;

  const settings = {
    actionSettings: {
      submit: {
        submitting,
        invalid,
        text: I18n.t('common.form.save')
      }
    }
  };
  return(
    <form onSubmit = {props.handleSubmit} className='vehicle-form'>
      <Row>
        <Col sm={12} xs={12} md={12}>
          <Row>
            <Col sm={8} xs={8} md={8}>
              <Row>
                <Col sm={12} xs={12} md={12}>
                  <h4>{I18n.t('cultivation.vehicles.vehicleInfo')}</h4>
                </Col>
              </Row>
              <Row>
                <Col sm={6} xs={6} md={6}>
                  <Field
                    name='make'
                    component={TextInput}
                    props={{
                      label: I18n.t('cultivation.vehicles.table.make'),
                      placeholder: I18n.t('cultivation.vehicles.table.make'),
                      isRequired: true
                    }}
                  />
                </Col>
                <Col sm={6} xs={6} md={6}>
                  <Field
                    name='model'
                    component={TextInput}
                    props={{
                      label: I18n.t('cultivation.vehicles.table.model'),
                      placeholder: I18n.t('cultivation.vehicles.table.model'),
                      isRequired: true
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} xs={6} md={6}>
                  <Field
                    name='license_number'
                    component={TextInput}
                    props={{
                      label: I18n.t('cultivation.vehicles.table.licensePlate'),
                      placeholder: I18n.t('cultivation.vehicles.table.plateNumber'),
                      isRequired: true
                    }}
                  />
                </Col>
                <Col sm={6} xs={6} md={6}>
                  <Field
                    name='nickname'
                    component={TextInput}
                    props={{
                      label: I18n.t('cultivation.vehicles.table.nicknameVehicle'),
                      placeholder: I18n.t('cultivation.vehicles.table.nickname'),
                      isRequired: true
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} xs={6} md={6}>
                  <Field
                    name='color'
                    component={TextInput}
                    props={{
                      label: I18n.t('cultivation.vehicles.table.color'),
                      placeholder: I18n.t('cultivation.vehicles.table.color'),
                      isRequired: isLeaf || isBiotrack
                    }}
                  />
                </Col>
                <Col sm={6} xs={6} md={6}>
                  <Field
                    name='vin'
                    component={TextInput}
                    props={{
                      label: I18n.t('cultivation.vehicles.table.vin'),
                      placeholder: I18n.t('cultivation.vehicles.table.vin'),
                      isRequired: isLeaf || isBiotrack
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} xs={6} md={6}>
                  <Field
                    name='year'
                    component={NumericInput}
                    allowNegativeNumber = {false}
                    fractionPartSize = {0}
                    min={0}
                    max={new Date().getFullYear() + 1}
                    props={{
                      label: I18n.t('cultivation.vehicles.table.year'),
                      placeholder: I18n.t('cultivation.vehicles.table.year'),
                      isRequired: isLeaf || isBiotrack
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col sm={8} xs={8} md={4}>
              <Row>
                <Col sm={12} xs={12} md={12}>
                  <h4>{I18n.t('cultivation.vehicles.profileImage')}</h4>
                </Col>
              </Row>
              <Row>
                <Col sm={12} xs={12} md={5}>
                  {imageUrl ? <Image thumbnail src={imageUrl}/> : <Image thumbnail/>}

                  <p>{I18n.t('common.imageUpload.description')}</p>
                  <p>{I18n.t('common.imageUpload.limits')}</p>
                  <Field
                    name='image'
                    itemName={itemNames.image}
                    component={FileInput}
                    props={{
                      ...FILE_TYPES['COMMON_PUBLIC_IMAGE'],
                      disabled: uploading,
                      btnContent: I18n.t('common.form.upload'),
                      onChangeCb: (f) => {
                        onImageChange(f);
                        change('photo_file_id', f.id);
                        changeUploadStatus(false);
                      },
                      onUploadStart: () => { changeUploadStatus(true);},
                      btnProps: {
                        className: 'btn btn-default'
                      }
                    }}/>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col sm={12} xs={12} md={12}>
          <Row>
            <Col sm={8} xs={8} md={8}>
              <Row>
                <Col sm={12} xs={12} md={12}>
                  <h4>{I18n.t('cultivation.vehicles.insuranceInfo')}</h4>
                </Col>
              </Row>
              <Row>
                <Col sm={6} xs={6} md={6}>
                  <Field
                    name='insurance_company'
                    component={TextInput}
                    props={{
                      label: I18n.t('cultivation.vehicles.table.insurance'),
                      placeholder: I18n.t('cultivation.vehicles.table.insurance'),
                      isRequired: true
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <Field
                    name='insurance_policy_number'
                    component={TextInput}
                    props={{
                      label: I18n.t('cultivation.vehicles.table.policyNumber'),
                      placeholder: I18n.t('cultivation.vehicles.table.policyNumber'),
                      isRequired: true
                    }}
                  />
                </Col>
                <Col xs={6}>
                  <InternationalReduxPhoneInput
                    name='insurance_phone_number'
                    props={{
                      label: I18n.t('cultivation.vehicles.table.phone'),
                      isRequired: true
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <Form.Label>&nbsp;</Form.Label>
                    <p>
                      {!documentUrl ? null : <a target='_new' href={documentUrl}>{I18n.t('vehicles.viewInsuranceDocument')}</a>}
                    </p>
                  </FormGroup>
                </Col>

                <Col xs={6} className='file-upload'>
                  <Field
                    name='insurance_doc'
                    itemName={itemNames.document}
                    component={FileInput}
                    props={{
                      ...FILE_TYPES['COMMON_PUBLIC_DOCUMENT'],
                      label: '\u00A0',
                      btnContent: I18n.t('cultivation.vehicles.add.doc'),
                      onChangeCb: (f) => {
                        onDocChange(f);
                        change('insurance_file_id', f.id);
                        changeUploadStatus(false);
                      },
                      onUploadStart: () => { changeUploadStatus(true);},
                      btnProps: {
                        className: 'btn btn-primary'
                      }
                    }}/>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <SubmitSection settings = {settings}/>
    </form>
  );
};

CreateVehicleForm.propTypes = {
  handleSubmit: PropTypes.func,
  onImageChange: PropTypes.func.isRequired,
  onDocChange: PropTypes.func.isRequired,
  imageUrl: PropTypes.string,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  integrationState: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  changeUploadStatus: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired,
  documentUrl: PropTypes.string,
};

export default reduxForm({
  form: 'createVehicleForm',
  validate,
})(CreateVehicleForm);
