import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import {set} from 'lodash';
import {minValidation, requiredFieldValidation} from '../../common/form/redux-form/validations';
import {isCategoryPlants, isSubCategorySeeds, isCategoryNonMedicated} from '../../../selectors/categorySelectors';
import {EA, GR} from '../../../constants/uoms';

const _hasApplicableFacilities = (pricingItem) => {
  if (pricingItem.facility_ids && Array.isArray(pricingItem.facility_ids) && pricingItem.facility_ids.length > 0) return true;
  return false;
};

const _hasDefinedPricingData = (pricingItem) => {
  if (Math.ceil(pricingItem.default_price) !== 0) return true;
  if (pricingItem.is_non_taxable || pricingItem.pricing_class_id || pricingItem.pricing_group_id) return true;
  if (pricingItem.weight_prices && Array.isArray(pricingItem.weight_prices)) {
    if (pricingItem.weight_prices.some(item => (item.default_price && Math.ceil(item.default_price) !== 0))) return true;
  }
  return false;
};

const _checkPricingByFacility = (values, errors, path) => {
  const pricingItems = get(values, path);
  if (Array.isArray(pricingItems)) {
    pricingItems.forEach((item,index) => {
      if (!_hasApplicableFacilities(item) && _hasDefinedPricingData(item)) {
        const errorNode = get(errors, path);
        errorNode[index] = {facility_ids: I18n.t('products.form.missingApplicableFacilities')};
      }
    });
  }
};


const validate = (values, props) => {
  const {categoryOptions, isSeedPackagingAllowed, medicatedWeightRequired, integrationState} = props;
  const sc = categoryOptions.find(category => category.id === (values.category_id || 1));
  const ssc = sc && sc.subcategories.find(subcategory => subcategory.id === values.subcategory_id);
  const isCanadianSeed = isCategoryPlants(sc) && isSubCategorySeeds(ssc) && isSeedPackagingAllowed;
  const isCanadianEASeed = isCanadianSeed && values.default_uom === EA;
  const {isBiotrack, isApisicpa, isWeedmaps, isCanada, isMiMetrc} = integrationState;

  const errors = {
    purchasing_attributes: {},
    pieces_per_unit: isCanadianEASeed ? requiredFieldValidation(values.pieces_per_unit) : null,
    prices: {
      facilities: {
        retail: {
          medical: [{}],
          recreational: [{}],
        },
        wholesale: []
      },
      organization: {
        retail: {
          medical: {},
          recreational: {},
        }
      }
    },
  };

  if (props.strainRequired && !values.strain_id) {
    errors.strain_id = I18n.t('products.form.strainRequired');
  }

  if (!values.name) {
    errors.name = I18n.t('products.validation.name');
  }

  const oklahomaRequiresNetWeightAndUom = {
    CARTPENS: 'all',
    EDIBLE: 'all',
    INFUSED: ['SUPPOSITORY'],
  };

  const province_code = get(props.facility, 'province_code', '');
  if(province_code && province_code.toLowerCase() === 'ok') {
    const category = (props.categories || []).find((category) => category.id === values.category_id);
    if(category && category.category_code && oklahomaRequiresNetWeightAndUom[get(category, 'category_code')]){
      const subcategory = category ? category.subcategories.find((sub) => sub.id === values.subcategory_id) : {};
      const requiresNetWeightAndUom = !Array.isArray(oklahomaRequiresNetWeightAndUom[get(category, 'category_code')])
          ? true
          : subcategory
            ? oklahomaRequiresNetWeightAndUom[get(category, 'category_code')].indexOf(subcategory.subcategory_code) !== -1
            : false;
      const netWeight = typeof values.net_weight === 'string' ? values.net_weight.trim() : values.net_weight;
      if(requiresNetWeightAndUom) errors.net_weight = requiredFieldValidation(netWeight !== '' ? netWeight : undefined, 'products.validation.net_weight');
      if(requiresNetWeightAndUom) errors.net_weight_uom = requiredFieldValidation(values.net_weight, 'products.validation.net_weight');
    }
  }

  if (values.purchasing_attributes && values.purchasing_attributes.buffer) {
    if (!/^(0|[.1-9][.0-9]*)$/.test(values.purchasing_attributes.buffer)) {
      errors.purchasing_attributes.buffer = I18n.t('products.form.invalidApiBuffer');
    }

    if (values.default_uom === 'EA' && (values.purchasing_attributes.buffer % 1 !== 0 || String(values.purchasing_attributes.buffer).indexOf('.') > -1)) {
      errors.purchasing_attributes.buffer = I18n.t('products.form.invalidApiBuffer');
    }
  }

  errors.default_uom = requiredFieldValidation(values.default_uom, 'products.validation.uom');

  if (isApisicpa) {
    errors.packaging = requiredFieldValidation(values.packaging);
    errors.description = requiredFieldValidation(values.description);
  }

  if (!values.subcategory_id) {
    errors.subcategory_id = I18n.t('products.validation.subcategory');
  }
  if (!isCategoryNonMedicated(sc) && isBiotrack && !values.strain_id) {
    errors.strain_id = I18n.t('products.validation.strain');
  }
  if ((values.required_fields && values.required_fields.requires_unit_thc_content) || medicatedWeightRequired) {
    errors.medicated_weight = requiredFieldValidation(values.medicated_weight, 'products.form.medicatedNetWeight');
    errors.medicated_weight_uom = requiredFieldValidation(values.medicated_weight_uom, 'products.form.uom');
  }
  if (isBiotrack && values.inventory_attributes && values.inventory_attributes.is_prepack) {
    errors.medicated_weight = requiredFieldValidation(values.medicated_weight, 'products.form.medicatedNetWeight');
  }
  if (isBiotrack && medicatedWeightRequired && values.medicated_weight && parseFloat(values.medicated_weight) === 0) {
    errors.medicated_weight = I18n.t('products.form.medicatedNetWeightCannotBeZero');
  }
  if(values.required_fields && values.required_fields.requires_unit_weight) {
    errors.net_weight = requiredFieldValidation(values.net_weight, 'products.form.productNetWeight') || minValidation(values.net_weight, 'products.form.productNetWeight', 0, true);
    errors.net_weight_uom = requiredFieldValidation(values.net_weight_uom, 'products.form.uom');
  }
  if(!isCategoryNonMedicated(sc) && (values.hasMetrc && values.required_fields && values.required_fields.requires_strain || (isCanada && isCanadianSeed))) {
    errors.strain_id = requiredFieldValidation(values.strain_id, 'products.validation.strainName');
  }

  if(values.hasMetrc && values.required_fields && values.required_fields.requires_ingredients ) {
    errors.ingredients = requiredFieldValidation(values.ingredients, 'products.form.ingredients');
  }
  if(values.hasMetrc && values.required_fields && values.required_fields.requires_serving_size ) {
    errors.serving_size = requiredFieldValidation(values.serving_size, 'products.form.servingSize');
  }
  if(values.hasMetrc && values.required_fields && values.required_fields.requires_administration_method ) {
    errors.administration_method = requiredFieldValidation(values.administration_method, 'products.form.administrationMethod');
  }

  if(values.hasMetrc && values.required_fields && values.required_fields.requires_unit_thc_percent ) {
    errors.d9_thca_percent = requiredFieldValidation(values.d9_thca_percent, 'products.form.d9_thca');
  }

  if(values.hasMetrc && values.required_fields && values.required_fields.requires_unit_cbd_content ) {
    errors.cbd_weight = requiredFieldValidation(values.cbd_weight, 'products.form.cbdWeight');
  }

  if(values.hasMetrc && values.required_fields && values.required_fields.requires_unit_cbd_percent ) {
    errors.cbd_percent = requiredFieldValidation(values.cbd_percent, 'products.form.cbdPercent');
  }

  if(values.hasMetrc && values.required_fields && values.required_fields.requires_supply_duration_days ) {
    errors.supply_duration_days = requiredFieldValidation(values.supply_duration_days, 'products.form.supplyDurationDays');
  }

  if (values.hasMetrc && values.required_fields && values.required_fields.requires_description) {
    errors.description = requiredFieldValidation(values.description, 'products.form.productDescription');
  }

  if(isMiMetrc || isCanada) {
    errors.medicated_volume = (isMiMetrc && requiredFieldValidation(values.medicated_volume, 'products.form.medicatedVolume')) ||
      (values.medicated_volume && minValidation(values.medicated_volume, 'products.form.medicatedNetVolume', 0, true));
    errors.medicated_weight_uom = isMiMetrc && requiredFieldValidation(values.medicated_volume_uom, 'products.form.uom');
  }

  if (isWeedmaps && (values.sales_attributes && values.sales_attributes.weedmaps_share == true) && values.category_id === 1) {
    errors.dominance_id = requiredFieldValidation(values.dominance_id, 'products.form.dominanceName');
  }

  if (values.sales_attributes) {
    if (!errors.sales_attributes) {
      errors.sales_attributes = {};
    }
    // errors.sales_attributes.pricing_type = requiredFieldValidation(values.sales_attributes.pricing_type, 'products.validation.pricingType');
  }

  _checkPricingByFacility(values, errors, 'prices.facilities.retail.medical');
  _checkPricingByFacility(values, errors, 'prices.facilities.retail.recreational');
  _checkPricingByFacility(values, errors, 'prices.facilities.wholesale');

  /**
   * Only for Leafly for now, as we don't want conflicting prices to show up on the Leafly side
   * Individual / Each products don't have weighted prices
   * sales_attributes.leafly_share is coming in as a string "0" or "1" for some reason, hence the parseInt
   */
  const shareWithLeafly = parseInt(get(values, 'sales_attributes.leafly_share', 0));
  if (shareWithLeafly && get(values, 'prices') && get(values, 'net_weight_uom', '') === GR) {

    // check if the base price per gram is different from the pricing table's default price of 1 gram
    const pricingWeightLookUpTable = createPricingWeightLookUpTable(props.pricingWeights);
    const conflictingPricePerGramPaths = validateWeightPricesAndBasePricePerGram(values.prices, pricingWeightLookUpTable);

    // set errors, if there were any
    if (conflictingPricePerGramPaths.length) {
      conflictingPricePerGramPaths.forEach((path) => {
        set(errors, path, I18n.t('products.validation.differentPricesFor1Gram'));
      });
    }
  }

  errors.net_weight_uom = requiredFieldValidation(values.net_weight_uom, 'products.validation.defaultUom');
  errors.unit_weight_uom = requiredFieldValidation(values.unit_weight_uom, 'products.validation.defaultUom');
  return errors;
};

// Use the pricing_weight_id as the key, similar to Laravel's Collection keyBy to save some computation time later
const createPricingWeightLookUpTable = (pricingWeights) => {
  const lookUpTable = {};
  pricingWeights.forEach((pricingWeight) => {
    lookUpTable[pricingWeight.id] = pricingWeight;
  });
  return lookUpTable;
};

/**
 * Used when the product is shared with Leafly
 * Base price per gram and the weighted / tiered price of 1 gram should be the same, if not we have conflicting prices
 * exported for testing purposes, isn't currently used anywhere but here
 */
export const validateWeightPricesAndBasePricePerGram = ((prices, pricingWeightLookUpTable) => {
  // structure for the item_master.prices object, see corresponding test for an example.
  // The ending data structure of paths starting with facilities are arrays, hence the .0
  const paths = ['facilities.retail.medical.0', 'facilities.retail.recreational.0', 'organization.retail.recreational', 'organization.retail.medical'];
  const errors = [];
  paths.forEach((path) => {
    const pricingList = get(prices, path, false);
    if (pricingList) {
      const invalid = hasDifferentPricesFor1Gram(pricingList, pricingWeightLookUpTable);
      if (invalid) {
        errors.push(`prices.${path}.default_price`);
      }
    }
  });
  return errors;
});

// Find the weighted price of 1g (if applicable) and compare it to the base price per gram
const hasDifferentPricesFor1Gram = (pricingLists, pricingWeightLookUpTable) => {
  const callbackFn = (pricingList) => {
    const weightPrices = get(pricingList, 'weight_prices', []);

    if (weightPrices.length) {
      // use the lookup table to find the pricing weight that corresponds to 1 GR
      const pricingWeightFor1Gram = weightPrices.filter((weight_price) => {
        const pricing_weight_id = get(weight_price, 'pricing_weight_id', '');
        if (pricing_weight_id) {
          const weight = get(pricingWeightLookUpTable, `${pricing_weight_id}.weight`);
          return weight === 1;
        }
      });

      const defaultPrice = parseFloat(get(pricingList, 'default_price', '0.00'));
      const weightPriceFor1G = parseFloat(get(pricingWeightFor1Gram, '0.default_price', '0.00'));
      return defaultPrice !== weightPriceFor1G;
    }
    return false;
  };

  // Comes in as an array for prices.facilities
  const different = Array.isArray(pricingLists) ? pricingLists.some(callbackFn) : callbackFn(pricingLists);
  return different;
};

export default validate;
