import {change, formValueSelector} from 'redux-form';
import get from 'lodash.get';
import {PROCESSING_TYPE_FORM} from '../../constants/forms';
import {isFormChangeAction} from './utils';
import {getSortedIngredients} from '../../selectors/ingredientsSelectors';


const processingTypeFormMiddleware = store => next => action => {
  const result = next(action);
  const {meta, payload} = action;

  /*
    If the form has changed...
   */
  if (isFormChangeAction(action, [PROCESSING_TYPE_FORM])) {

    const selector = formValueSelector(PROCESSING_TYPE_FORM);
    const getFormValue = (...names) => selector(store.getState(), ...names);

    const reCalculateUnitCost = (prefix, selectedId, newUom, newQty = 0) => {
      const item = getFormValue(prefix) || {};
      const uom = newUom || item.uom;
      const qty = parseFloat(newQty || item.qty || 0);
      const ingredients = getSortedIngredients(store.getState());
      const itemMaster = ingredients.find((master) => master.id === selectedId);

      const {uoms} = store.getState();

      const newUomMaster = uoms.find((master) => master.uom_code === uom);
      const oldUomMaster = uoms.find((master) => master.uom_code === item.default_uom);

      if (itemMaster && item.unit_cost && newUomMaster && qty && oldUomMaster) {
        const costPerLine = (parseFloat(item.unit_cost) * newUomMaster.conversion_ratio * qty) / oldUomMaster.conversion_ratio;
        store.dispatch(change(meta.form, `${prefix}.cost_per_line`, costPerLine));
      } else {
        store.dispatch(change(meta.form, `${prefix}.cost_per_line`, 0));
      }
    };

    const onChangeUom = (prefix, uom, selectedId) => {
      reCalculateUnitCost(prefix, selectedId, uom);
    };

    const onChangeQty = (prefix, qty, selectedId) => {
      reCalculateUnitCost(prefix, selectedId, undefined, qty);
    };

    const OnChangeIngredient = (prefix, selectedId) => {
      const setCostValue = (cost, uom) => {
        store.dispatch(change(meta.form, `${prefix}.unit_cost`, cost));
        store.dispatch(change(meta.form, `${prefix}.unit_cost_view`, cost));
        store.dispatch(change(meta.form, `${prefix}.uom`, uom));
      };

      if (selectedId) {
        const ingredients = getSortedIngredients(store.getState());
        const itemMaster = ingredients.find((master) => master.id === selectedId);
        const cost = Number(get(itemMaster, 'vendors[0].default_unit_cost', 0));
        setCostValue(cost, itemMaster.default_uom);
        store.dispatch(change(meta.form, `${prefix}.default_uom`, itemMaster.default_uom));
        store.dispatch(change(meta.form, `${prefix}.qty`, ''));
      }

      else {
        store.dispatch(change(meta.form, `${prefix}.uom`, ''));
        store.dispatch(change(meta.form, `${prefix}.qty`, ''));
        store.dispatch(change(meta.form, `${prefix}.default_uom`, ''));
        store.dispatch(change(meta.form, `${prefix}.unit_cost`, 0));
        store.dispatch(change(meta.form, `${prefix}.unit_cost_view`, 0));
        store.dispatch(change(meta.form, `${prefix}.cost_per_line`, 0));
      }
    };

    /**
     * Materials used change
     */
    match = get(meta, 'field').match(/(materials\[\d*])\.id/);
    if (match && match.length > 1) {
      const prefix = match[1];
      OnChangeIngredient(prefix, payload);
    }

    /**
     * qty change
     */
    let match = get(meta, 'field').match(/(materials\[\d*])\.qty/);
    if (match && match.length > 1) {
      const prefix = match[1];
      const selectedId = getFormValue(`${prefix}.id`);
      onChangeQty(prefix, payload, selectedId);
    }

    /**
     * uom change
     */
    match = get(meta, 'field').match(/(materials\[\d*])\.uom/);
    if (match && match.length > 1) {
      const prefix = match[1];
      const selectedId = getFormValue(`${prefix}.id`);
      onChangeUom(prefix, payload, selectedId);
    }

  }
  return result;
};

export default processingTypeFormMiddleware;
