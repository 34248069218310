import React from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import {Field, formValueSelector, getFormValues, reduxForm} from 'redux-form';
import {Button, ButtonToolbar, Col, Row} from 'react-bootstrap';
import get from 'lodash.get';
import {SUPPLY_CHAIN_MAPPING_FORM_METRC} from '../../../../constants/forms';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import ProductMapping from './ProductMapping';
import CheckBoxInput from '../../../common/form/CheckBoxInput';
import {validate} from './validate';
import * as mappingSelectors from '../../../../selectors/MetrcSupplyChainMappingSelectors';

export const SupplyChainMappingForm = (props) => {
  const {
    getFormValue,
    formValues,
    change,
    error,
    invalid,
    submitting,
    reset,
    handleSubmit,
    incomingTransferOptions,
    onIncomingTransferChange,
    onAutoGeneratePurchaseOrderChange,
    purchaseOrdersOptions,
    onPurchaseOrderChange,
    incomingTransferDetails,
    itemMasters,
    itemMasterMappings,
    partnerFacilities,
    purchaseOrder
  } = props;

  const showMapping = incomingTransferDetails && Object.keys(incomingTransferDetails).length !== 0;

  // Reset form if incoming_transfer_id has changed
  if (!!getFormValue('incoming_transfer_id') && getFormValue('incoming_transfer_id') !== get(incomingTransferDetails, 'metrc_transfer_id')) {
    reset();
  }

  // Reset will clear the incoming_transfer_id, so set it to the loaded transfer if empty
  if (!getFormValue('incoming_transfer_id') && showMapping) {
    change('incoming_transfer_id', get(incomingTransferDetails, 'metrc_transfer_id'));
  }

  return (
    <form className='supply-chain-mapping-form' onSubmit={handleSubmit}>
      {/* Incoming Transfers */}
      <Row>
        <Col xs={6}>
          <Field
            name='incoming_transfer_id' component={ReactSelectInput}
            props={{
              placeholder: I18n.t('supplyChainMapping.form.selectIncomingTransfer'),
              label: I18n.t('supplyChainMapping.form.incomingTransfers'),
              options: incomingTransferOptions,
              isRequired: true,
              onChange: (value) => {
                onIncomingTransferChange(value);
                change('incoming_transfer_id', value);
              }
            }}
          />
        </Col>
        <Col xs={6}>
          {showMapping &&
            <Field
              name='auto_generate_po'
              style={{width:'18px'}}
              className='inline-checkbox'
              component={CheckBoxInput}
              props={{
                label: I18n.t('supplyChainMapping.form.autoGenerateAPurchaseOrder'),
                groupClassName: 'inline-checkbox',
                onChange: (event) => {
                  const {target: {checked}} = event;
                  if (checked) {
                    change('purchase_order_id', 0);
                  }
                  change('auto_generate_po', checked);
                  onAutoGeneratePurchaseOrderChange(checked);
                }
              }}
            />
          }
        </Col>
      </Row>
      <Row className='padding-top'>
        <Col xs={6}>
          {showMapping &&
            <Field
              name='vendor_facility_id' component={ReactSelectInput}
              props={{
                placeholder: I18n.t('supplyChainMapping.form.selectPartnerFacility'),
                label: I18n.t('purchaseOrders.form.partnerFacility'),
                options: mappingSelectors.getPartnerFacilitiesOptions(partnerFacilities),
                textKey: 'text',
                valueKey: 'value',
                isRequired: true,
              }}
            />
          }
        </Col>
        <Col xs={6}>
          {!getFormValue('auto_generate_po') &&
            <Field
              name='purchase_order_id' component={ReactSelectInput}
              props={{
                label: I18n.t('supplyChainMapping.form.unreceivedPurchaseOrders'),
                options: purchaseOrdersOptions,
                labelKey: 'text',
                valueKey: 'id',
                isRequired: true,
                onChange: (value) => {
                  onPurchaseOrderChange(value);
                  change('purchase_order_id', value);
                }
              }}
            />
          }
        </Col>
      </Row>

      {/* Generic error message */}
      {error &&
        <Row>
          <Col xs={12}>
            <div className='alert alert-danger' style={{width: '100%', margin: '6px 6px'}}>
              {error}
            </div>
          </Col>
        </Row>
      }

      {/* Product Mapping */}
      {showMapping &&
        <React.Fragment>
          <Row className='margin-top margin-bottom padding-all'>
            <Col xs={12} className='product-mapping-header'>
              {I18n.t('supplyChainMapping.form.productMapping')}
            </Col>
          </Row>

          <ProductMapping
            formValues={formValues}
            getFormValue={getFormValue}
            change={change}
            incomingTransferDetails={incomingTransferDetails}
            itemMasters={itemMasters}
            itemMasterMappings={itemMasterMappings}
            purchaseOrder={purchaseOrder}
          />

          {/* Submit section */}
          <br/>
          <Row>
            <Col xs={12}>
              <ButtonToolbar className='float-right'>
                <Button variant='primary' type='submit' disabled={(submitting || invalid)}>
                  {I18n.t('supplyChainMapping.form.nextInventoryReceipt')}
                </Button>
              </ButtonToolbar>
            </Col>
          </Row>

        </React.Fragment>
      }

    </form>
  );
};

SupplyChainMappingForm.propTypes = {
  formValues: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  getFormValue: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  error: PropTypes.string,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  incomingTransferOptions: PropTypes.array.isRequired,
  onIncomingTransferChange: PropTypes.func.isRequired,
  onAutoGeneratePurchaseOrderChange: PropTypes.func.isRequired,
  purchaseOrdersOptions: PropTypes.array.isRequired,
  onPurchaseOrderChange: PropTypes.func.isRequired,
  incomingTransferDetails: PropTypes.object,
  itemMasters: PropTypes.array,
  itemMasterMappings: PropTypes.array,
  purchaseOrder: PropTypes.object,
  partnerFacilities: PropTypes.array.isRequired,
};

function mapStateToProps(state, ownProps) {
  const selector = formValueSelector(SUPPLY_CHAIN_MAPPING_FORM_METRC);
  const getFormValue = (...names) => selector(state, ...names);
  return {
    formValues: getFormValues(SUPPLY_CHAIN_MAPPING_FORM_METRC)(state) || ownProps.initialValues,
    getFormValue
  };
}

const ReduxSupplyChainMappingForm = reduxForm({
  form: SUPPLY_CHAIN_MAPPING_FORM_METRC,
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(SupplyChainMappingForm);

export default connect(mapStateToProps)(ReduxSupplyChainMappingForm);
