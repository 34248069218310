import get from 'lodash.get';
import {createSelector} from 'reselect';
import {I18n} from 'react-redux-i18n';
import {formValueSelector} from 'redux-form';
import {isBulkType} from '../util/uomHelpers';
import {isItemMasterMedicated, isLotTracked} from './itemMastersSelectors';
import {isLeafIntegrator} from './integration/leafSelectors';
import {isWasteTransferAvailableSelector} from './compliance/waste/wasteComplianceSelectors';
import * as itemNames from '../constants/itemNames';
import {SALES_ORDER_FORM} from '../constants/forms';
import {convertDbDateToFormInputDate} from '../util/dateHelpers';
// eslint-disable-next-line import/no-named-as-default
import getTimezone from './timezoneSelectors';
import {getPartnerIntegrations} from './partnersSelectors';

export const getSalesOrder = (state) => state[itemNames.salesOrder];
export const getSalesOrders = (state) => state.salesOrders;
export const getSelectedSalesOrders = state => Array.isArray(state.selectedSalesOrders) ? state.selectedSalesOrders : [];

const getPartnerFacilities = (state) => state.partnerFacilities;
const getItemMasters = (state) => state.itemMasters;
const getTransfer = (state) => state.transfer;
const getDistributionSettings = state => state[itemNames.distributionSettings];

const formSelector = formValueSelector(SALES_ORDER_FORM);
const getOrderLines = state => formSelector(state, 'lines');

export const getPartnerFacilitiesWithIntegrationDetails = createSelector([getPartnerFacilities, getPartnerIntegrations],
  (partnerFacilities, partnerIntegrations) => {
    // Merge leaf integration data into partners
    const partners = partnerFacilities.map( f => {
      const leaf = partnerIntegrations.find( i => {
        return i.code === f.state_integration_id;
      }) || {};
      return {...f, leaf};
    });
    return partners;
  });

export const getSalesOrdersDestinations = createSelector([getSalesOrders, getPartnerFacilities, getTransfer],
  (salesOrders, partnerFacilities, transfer) => {

    const isValidString = (value) => {
      if(value === undefined || value === null) return false;
      if(value.trim() === '') return false;
      return true;
    };

    return salesOrders.length ? salesOrders.map(order => {

      if(partnerFacilities.length > 0 ){
        const facility = partnerFacilities.find((facility) => facility.id === order.partner_facility_id);
        let facilityLicense = undefined;
        if(!Object.keys(transfer).length) {
          facilityLicense = (facility && Array.isArray(facility.licenses))
            ? facility.licenses.find((license) => {
              return license.license_type === 'state';
            })
            : undefined;
        } else {
          facilityLicense = (transfer.destinations !== undefined)
            ? {license_number: transfer.destinations.length > 0 ? transfer.destinations[0].partner_license_number : undefined}
            : undefined;
        }
        return (facility)
          ? {
            partner_facility_id: order.partner_facility_id,
            partner_name: get(facility, 'partner.name'),
            partner_license_number: (facilityLicense && isValidString(facilityLicense.license_number))
              ? facilityLicense.license_number
              : '',
            partner_dba: facility.facility_name,
            partner_facility_type: get(facility, 'state_integration_type')
          }
          : {};
      }
    }) : [];
  });

export const getSalesOrdersLines = createSelector([getSalesOrders, getItemMasters, isLeafIntegrator], (salesOrders, itemMasters, isLeaf) => {
  const lines = [];
  if(salesOrders.length){
    salesOrders.map(order => order.lines ? order.lines.map(line => {
      const itemMaster = itemMasters.find(im => im.id === line.item_master_id);
      let line_type = '';
      let uom = 'GR';
      if(itemMaster){
        if(itemMaster.inventory_attributes && itemMaster.inventory_attributes.is_prepack && !itemMaster.item_master_parent_id){
          line_type = 'prepack';
        }else if(isBulkType(itemMaster.uom_type)){
          line_type = 'bulk';
        }else if (itemMaster.uom_type === 'discrete') {
          line_type = 'unit';
          uom = 'EA';
        }
      }

      // required because when a prepack is removed from a sales order in ModifySalesOrder by setting to 0
      // it also creates the item as 0 on the sales order... but we don't need to show those with zero values
      // here.
      line.subitems = get(line, 'subitems', []);
      const filteredSubItemsWithNonZeroQuantity = line.subitems.length > 0
      ? line.subitems.filter((item) => {
        return item.qty > 0;
      })
      : [];

      lines.push({
        id: line.id,
        sales_order_id: line.sales_order_id,
        item_master_id: line.item_master_id,
        container_type_id: line.container_type_id,
        container_count: line.container_count,
        transfer_uom: line.uom_display  || uom,
        unit_price: parseFloat(line.unit_price),
        line_item_price: parseFloat(line.line_item_price).toFixed(2),
        source_sales_order_id: line.sales_order_id,
        source_sales_order_line_id: line.source_sales_order_line_id,
        source_sales_order_subitems: filteredSubItemsWithNonZeroQuantity,
        ordered_qty: line.qty,
        qty:line.qty,
        ordered_uom: line.uom_display,
        name: itemMaster ? itemMaster.name : '',
        default_cost: get(itemMaster, 'default_cost') ? parseFloat(itemMaster.default_cost) : '0.00',
        default_uom: get(itemMaster, 'default_uom') ? itemMaster.default_uom : 'GR',
        unit_weight: get(itemMaster, 'unit_weight') ? itemMaster.unit_weight : '',
        unit_weight_uom: get(itemMaster, 'unit_weight_uom') ? itemMaster.unit_weight_uom : 'GR',
        uom_type: get(itemMaster, 'uom_type') ? itemMaster.uom_type : '',
        is_medicated: isItemMasterMedicated(itemMaster, isLeaf),
        is_waste_disposal: order.order_type === 'waste_disposal',
        is_lab_partner: order.order_type === 'lab',
        line_type,
        lot_tracked: isLotTracked(itemMaster),
        meta: (line.meta && typeof line.meta === 'string' && typeof JSON.parse(line.meta) === 'object') ? JSON.parse(line.meta) : false,
        net_weight: get(itemMaster, 'net_weight'),
        sent_net_uom: get(itemMaster, 'net_weight_uom', 'GR'),
        sent_net: get(itemMaster, 'net_weight') ? parseFloat(get(itemMaster, 'net_weight') * line.qty).toFixed(2) : '',
        sent_gross: get(itemMaster, 'unit_weight') ? parseFloat(get(itemMaster, 'unit_weight') * line.qty).toFixed(2) : '',
        sent_gross_uom: get(itemMaster, 'unit_weight_uom', 'GR'),
        itemMaster});
    }) : null
    );
  }
  return lines;
});

export const isMedicatedOrder = createSelector([getSalesOrdersLines], lines => lines.some(line => line.is_medicated));

export const getSalesOrdersTotals = createSelector([getSalesOrders], salesOrders => {
  return salesOrders.reduce((accumulator, order) => {
    return {
      //date_ordered: order.date_ordered,
      excise_tax_due: accumulator.excise_tax_due + parseFloat(order.taxes),
      partner_discount: accumulator.partner_discount + parseFloat(order.discount_percent),
      transfer_fee: accumulator.transfer_fee + parseFloat(order.transfer_fee),
      destination_total: accumulator.destination_total + parseFloat(order.order_total),
    };
  }, { excise_tax_due:0, partner_discount:0, transfer_fee:0, destination_total:0});
});

export const getOrderTypeOptions = createSelector(
  [isWasteTransferAvailableSelector],
  (isWasteTransferAvailable) => {
    const options = [
      {text: I18n.t('salesOrders.form.partnerTypes.salesPartners'), value: 'sales'},
      {text: I18n.t('salesOrders.form.partnerTypes.labPartners'), value: 'lab'},
    ];
    const wasteDisposalOption = {text: I18n.t('salesOrders.form.partnerTypes.wasteDisposalPartners'), value: 'waste_disposal'};
    return isWasteTransferAvailable ? options.concat(wasteDisposalOption) : options;
  }
);

export const getSalesOrdersIsLab = createSelector(
  [getSalesOrders],
  salesOrders => salesOrders.some(order => order.order_type === 'lab')
);

export const getSalesOrderForModifyPage = createSelector([getSalesOrder, getTimezone], (saleOrder, timezone) => ({
  ...saleOrder,
  transfer_record: saleOrder.transfer_records && saleOrder.transfer_records.length ? saleOrder.transfer_records[saleOrder.transfer_records.length - 1] : null,
  ...saleOrder,
  date_expected: saleOrder.date_expected ? convertDbDateToFormInputDate(saleOrder.date_expected, timezone) : '',
  date_ordered: saleOrder.date_ordered ? convertDbDateToFormInputDate(saleOrder.date_ordered, timezone) : '',
  payments: saleOrder.payments ? saleOrder.payments.map(payment => ({
    ...payment,
    payment_date: convertDbDateToFormInputDate(payment.payment_date, timezone) || '',
  })) : []
}));

export const getTransferLinesLimit = createSelector(getDistributionSettings, (settings) => parseInt(get(settings, 'distributions_transfer_line_limit.value', 0)));

export const getIsSalesOrderMedicated = createSelector([getOrderLines], (orderLines) => {
  return Boolean(orderLines && orderLines.some && orderLines.some(line => (line.itemMaster && isItemMasterMedicated(line.itemMaster))));
});

//checks to see if it is a return CBYTES-2418
export const getIsReturn = createSelector(getSalesOrders, (salesOrder) => {
  if(salesOrder.length){
    return Boolean(salesOrder[0].is_return);
  }
});
