import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';

import StringFieldInfo from '../common/StringFieldInfo';

const CustomFields = ({row, dimensions, editable}) => (
  <StringFieldInfo
    row={row || {}}
    columns={get(dimensions, 'custom_fields', [])}
    editable={editable}
  />
);

CustomFields.propTypes = {
  row: PropTypes.object,
  dimensions: PropTypes.object,
  editable: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

export default CustomFields;