import React from 'react';
import {Button} from 'react-bootstrap';
import moment from 'moment';
import {I18n} from 'react-redux-i18n';
import InternationalDateStatic from '../../common/form/InternationalDateStatic';

export function getColumns (actions, activeTab) {
  const columns = [
    {
      name: 'closedLoop.regulatedUser.firstName',
      dataId: 'first_name'
    },
    {
      name: 'closedLoop.regulatedUser.lastName',
      dataId: 'last_name'
    },
    {
      name: 'closedLoop.regulatedUser.employeeTitle',
      dataId: 'employee_title'
    },
    {
      name: 'closedLoop.regulatedUser.email',
      dataId: 'email'
    },
    {
      name: 'closedLoop.regulatedUser.organizationName',
      dataId: 'organization_name'
    },
    {
      name: 'closedLoop.regulatedUser.createdAt',
      dataId: 'user_created_at',
      formatter: (cell) => <InternationalDateStatic>{moment(cell)}</InternationalDateStatic>,
    }
  ];
  if (activeTab === 'approved' || activeTab === 'revoked') {
    columns.push(
      {
        name: 'closedLoop.regulatedUser.finalApprovalDate',
        dataId: 'final_approval_date',
        formatter: (cell) => <InternationalDateStatic>{moment(cell)}</InternationalDateStatic>,
      });
  }
  columns.push(
    {
      name: 'closedLoop.regulatedUser.active',
      dataId: 'active',
      formatter: (cell) => cell ? I18n.t('general.yes') : I18n.t('general.no')
    },
    {
      name: 'closedLoop.regulatedUser.userType',
      dataId: 'user_type',
      formatter: (cell) => cell === 'platform_user' ? I18n.t('closedLoop.regulatedUser.platformUser') : I18n.t('closedLoop.regulatedUser.affiliatedOnly')
    },
    {
      name: 'closedLoop.regulatedUser.settings',
      dataId: 'settings',
      dataSort: false,
      formatter: (cell, row) => {
        const route = `/closed-loop/user-details/${row.user_id}`;
        return <Button onClick={() => actions.push(route)}>Edit</Button>;
      }
    }
  );
  return columns;
}

export function getTabs() {
  return [
    {
      id: 'pendingTab',
      eventKey: 'pending',
      title: 'closedLoop.status.pending',
      actions: []
    },
    {
      id: 'approvedTab',
      eventKey: 'approved',
      title: 'closedLoop.status.approved',
      actions: []
    },
    {
      id: 'rejectedTab',
      eventKey: 'rejected',
      title: 'closedLoop.status.rejected',
      actions: []
    },
    {
      id: 'revokedTab',
      eventKey: 'revoked',
      title: 'closedLoop.status.revoked',
      actions: []
    }
  ];
}
