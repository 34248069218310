import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

const Editor = (props) => {

  const isActive = (label) => {
    if(label === undefined) return '';
    return label.is_active === 1 ? 'checked' : '';
  };

  const getCode = (label) => {
    if(label === undefined) return '';
    if(label.code === undefined) return '';
    return label.code;
  };

  const noTestsAllowed = (label) => {
    if(!props.sourceIsSelected()) return true;

    return getCode(label) === '';
  };

  return (
    <div className='col-md-12' style={{marginTop: '16px'}}>
      <div>
        <div style={{padding: '8px 5px'}}>
        {
          !props.isSample
          ? <div><strong>{I18n.t('labels.editorFieldLabel')}</strong> {I18n.t('labels.editorFieldLabelHint')}</div>
            : 'Sample ZPL'
        }
        </div>
        <textarea
          readOnly={props.isSample}
          onChange={(event) => {
            props.onFieldChange(props.getLabel(props.resolution), 'code', event.target.value);
          }}
          className='form-control'
          style={{height: '200px'}}
          value={getCode(props.getLabel(props.resolution))}>
        </textarea>
      </div>
      <div style={{margin: '12px 0px 12px 0px', textAlign: 'right'}}>
        {props.isSample
          ? null
            : (
            <div className='float-left'>
              <input
                style={{width: '14px', height: '14px'}}
                type='checkbox'
                value='1'
                onChange={(event) => {
                  props.onFieldChange(props.getLabel(props.resolution), 'is_active', event.target.checked ? 1 : 0);
                }}
                checked={isActive(props.getLabel(props.resolution))}/>&nbsp;
              {I18n.t('labels.isActive')}
            </div>
          )
        }

        <button
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            event.target.blur();
            if(props.isSample){
              props.loadPrinterModal(props.getLabel(props.resolution), true); // force default
              return true;
            }
            props.onSave(props.getLabel(props.resolution), true);
          }}
          disabled={noTestsAllowed(props.getLabel(props.resolution))}
          className='btn btn-primary'
          style={{marginRight: '5px'}}>
          {
            props.isSample
            ? I18n.t('labels.testDefaultLabel')
              : I18n.t('labels.saveAndTestCustomLabel')
          }
        </button>
        {
          props.isSample
          ? null
            : (
            <button
              onClick={() => {props.onSave(props.getLabel(props.resolution), false);}}
              disabled={getCode(props.getLabel(props.resolution)) === ''}
              className='btn btn-primary'>
              {I18n.t('labels.saveCustomLabel')}
            </button>
          )
        }
      </div>
      <div style={{textAlign: 'center'}}>
        {
          !noTestsAllowed(props.getLabel(props.resolution))
            ? null
            : <div className='text-danger'>Testing can only be done once you've selected a source for the label.</div>
        }
      </div>
    </div>
  );

};

Editor.propTypes = {
  loadPrinterModal: PropTypes.func, // used only for samples
  isSample: PropTypes.bool,
  sourceIsSelected: PropTypes.func.isRequired,
  resolution: PropTypes.number.isRequired,
  getLabel: PropTypes.func.isRequired,
  labels: PropTypes.array
};

export default Editor;
