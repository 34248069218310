import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { Button } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import WillRender from '../../../common/concealers/WillRender';

const ActionButtonsCell = (props) => {
  const {
    order,
    nonMedicatedCategoryId,
    refundOrders,
    integrationState,
    data,
    restockOrderInitiated,
    refundOrderInitiated,
    canRefundOrder,
    restockQueue,
    refundQueue
  } = props;

  const products = (order && order.products) || [];
  let isDisabled = false;
  const { isMetrc } = integrationState;
  if (integrationState.isBiotrack) {
    //If order has either restocked or refunded medicated products, we should not allow to do it again for medicated items
    const hasMedicatedRestocks = products.some(
      (p) => p.category_id !== nonMedicatedCategoryId && p.items.some((i) => i.restock_transaction)
    );
    const hasMedicatedRefunds = (refundOrders || []).some((o) =>
      (o.products || []).some((p) => {
        const refundedProduct = products.find((product) => product.id === p.refunded_order_product_id);
        return refundedProduct && refundedProduct.category_id !== nonMedicatedCategoryId;
      })
    );
    isDisabled = hasMedicatedRefunds || (hasMedicatedRestocks && data.category_id !== nonMedicatedCategoryId);
  }

  const orderIsCancelled = () => {
    return order.order_status === 'cancelled';
  };

  const orderIsNotComplete = () => {
    return order.fulfillment_status !== 'completed';
  };

  const isBulk = props.data.uom !== 'EA' && !props.data.prepack_weight_id;
  const cannotRefund = () => {
    if (orderIsNotComplete()) return true;
    if (isDisabled || !canRefundOrder) {
      return true;
    }

    if (isMetrc) {
      if (isRestocked()) return true;
      if (restockOrderInitiated) {
        const restockIds = get(props, 'restockQueue', []).map((item) => item.id);
        const refundIds = get(props.refundQueue, 'products', []).map(
          (product) => product.refunded_order_product_inventory_id
        );
        if (restockIds.some((id) => id === data.id) && !refundIds.some((id) => id === data.id)) return true;

      }
    }

    if (isBulk) {
      return props.data.refunded > 0;
    }
    if (props.data.qty === undefined) {
      // is not an inventory tracked item
      return props.data.quantity === props.data.refunded || !props.data.refundable;
    }
    // is inventory item
    return props.data.qty <= props.data.refunded || !props.data.refundable;
  };

  const cannotRestock = () => {
    const { integrationState } = props;
    const { isMetrc } = integrationState;

    if (isMetrc && refundOrderInitiated) {
      const refundIds = get(props.refundQueue, 'products', []).map(
        (product) => product.refunded_order_product_inventory_id
      );
      if (refundIds.some((id) => id === data.id)) return true;
    }

    if (orderIsNotComplete()) return true;
    if (isDisabled || !canRefundOrder) {
      return true;
    }

    // inventory NOT tracked = quantity ELSE inventory tracked = qty
    const qty = props.data.qty === undefined ? props.data.quantity : props.data.qty;
    const restockedCount = props.data.hasOwnProperty('restock_transactions') ?
      props.data.restock_transactions.reduce((count, {transacted_qty}) => count + parseFloat(transacted_qty), 0) : 0;
    // customer ordered - already restocked <= restock queue
    return qty - restockedCount <= restockCount() || props.data.restocked === 1;
  };

  const isRestocked = () => {
    return props.data.restocked === 1;
  };

  const showButton = () => {
    return props.order.order_status !== 'cancelled' && props.data.refunded_order_product_id === null;
  };

  const orderProductId = get(data, 'items.0.order_product_id');
  const itemId = get(data, 'items.0.item_id');
  const orderStatus = get(order, 'order_status');

  /**
   * Return true if the line item is queued for restock
   * @returns {boolean}
   */
  const queuedForRestock = () => {
    if (restockQueue && restockQueue.length) {
      return restockQueue.some((item) => {
        return item.id === data.id;
      });
    }
    return false;
  };

  /**
   * Returns how many items are queued for restock
   * @returns {int}
   */
  const restockCount = () => {
    return restockQueue.reduce((total, item) => {
      total += (item.id === data.id) ? item.quantity : 0;
      return total;
    }, 0);
  };

  /**
   * Return true if the line item is queued for refund
   * @returns {boolean}
   */
  const queuedForRefund = () => {
    if (refundQueue && refundQueue.products && refundQueue.products.length) {
      return refundQueue.products.some((item) => {
        return item.refunded_inventory.id === data.id;
      });
    }
    return false;
  };

  return (
    <div>
      {!showButton() ? (
        <div>
          {props.orderName === '' || orderIsCancelled() ? (
            <div>None Available</div>
          ) : (
            <div>
              <div className='text-muted' style={{ fontSize: 'smaller' }}>
                Refund Of Order {props.orderName}
              </div>
              <Button
                title={!props.canPrintLabels ? I18n.t('orderReceipt.printing.noPermissions') : undefined}
                variant='primary'
                className='btn btn-sm btn-primary'
                style={{ marginLeft: '2px' }}
                disabled={!props.canPrintLabels}
                onClick={() => {
                  props.showSingleLabelModal(orderProductId, itemId);
                }}
              >
                <span style={{ textTransform: 'capitalize' }}>
                  {I18n.t('cart.totals.viewPrintLabel').toLowerCase()}
                </span>
              </Button>
            </div>
          )}
        </div>
      ) : (
        <div>
          <button
            disabled={cannotRefund()}
            className='btn btn-sm btn-primary'
            onClick={(event) => {
              props.onRefundClick(event, props.data);
            }}
          >
            {props.data.refundingCount === 0 ? (
              isMetrc ? (
                <span style={{ textTransform: 'capitalize' }}>
                  {I18n.t('cart.totals.refundAndRestock').toLowerCase()}
                </span>
              ) : (
                <span style={{ textTransform: 'capitalize' }}>{I18n.t('cart.totals.refund').toLowerCase()}</span>
              )
            ) : (
              <span style={{ textTransform: 'capitalize' }}>
                {I18n.t('cart.totals.refunding').toLowerCase()} ({props.data.refundingCount})
              </span>
            )}
          </button>
          {isRestocked() ? (
            <span style={{ paddingLeft: '6px', paddingRight: '6px' }}>Restocked</span>
          ) : (
            <button
              style={{ marginLeft: '2px' }}
              disabled={cannotRestock() || (isMetrc && queuedForRefund())}
              className='btn btn-sm btn-primary'
              onClick={(event) => {
                props.onRestockProduct(event, props.data);
              }}
            >
              {queuedForRestock() ? (
                <span style={{ textTransform: 'capitalize' }}>
                  {I18n.t('cart.totals.restocking').toLowerCase()} ({restockCount()})
                </span>
              ) : isMetrc ? (
                <span style={{ textTransform: 'capitalize' }}>{I18n.t('cart.totals.restockOnly').toLowerCase()}</span>
              ) : (
                <span style={{ textTransform: 'capitalize' }}>{I18n.t('cart.totals.restock').toLowerCase()}</span>
              )}
            </button>
          )}

          <WillRender ifTrue={orderStatus === 'completed'}>
            <Button
              title={!props.canPrintLabels ? I18n.t('orderReceipt.printing.noPermissions') : undefined}
              variant='primary'
              className='btn btn-sm btn-primary'
              style={{ marginLeft: '2px' }}
              disabled={!props.canPrintLabels}
              onClick={() => {
                props.showSingleLabelModal(orderProductId, itemId);
              }}
            >
              <span style={{ textTransform: 'capitalize' }}>{I18n.t('cart.totals.viewPrintLabel').toLowerCase()}</span>
            </Button>
          </WillRender>
        </div>
      )}
    </div>
  );
};

ActionButtonsCell.propTypes = {
  onRestockProduct: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  integrationState: PropTypes.object.isRequired,
  nonMedicatedCategoryId: PropTypes.number,
  refundOrders: PropTypes.array,
  showSingleLabelModal: PropTypes.func.isRequired,
  canPrintLabels: PropTypes.bool.isRequired,
  restockOrderInitiated: PropTypes.bool,
  refundOrderInitiated: PropTypes.bool,
  refundQueue: PropTypes.object,
  canRefundOrder: PropTypes.bool.isRequired
};

export default ActionButtonsCell;
