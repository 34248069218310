import { change, formValueSelector, arrayPush } from 'redux-form';
import * as actionTypes from '../../constants/actionTypes';
import * as itemNames from '../../constants/itemNames';
import { TAX_PROFILE_FORM } from '../../constants/forms';
import { isChangeAction, isArrayPush, isArrayRemove } from './utils';
import { getTaxDefinitions } from '../../selectors/forms/taxProfileFormSelectors';

const taxProfileForm = (store) => (next) => (action) => {
  const result = next(action);
  const validForms = [TAX_PROFILE_FORM];
  const isTaxProfileGet = action.type === actionTypes.GET_ITEM_SUCCESS && action.name === itemNames.taxProfile;
  if (
    isChangeAction(action, validForms) ||
    isArrayPush(action, validForms, 'groups') ||
    isArrayRemove(action, validForms) ||
    isTaxProfileGet
  ) {
    const { meta } = action;
    const state = store.getState();
    const selector = formValueSelector(TAX_PROFILE_FORM);
    const taxDefinitions = getTaxDefinitions(state);

    const getCurrentGroupsAndTaxes = () => {
      const state = store.getState();
      return {
        groups: selector(state, 'groups'),
        taxes: selector(state, 'taxes'),
        taxes_on_subtotal: selector(state, 'taxes_on_subtotal')
      };
    };
    // Updates inbound, outbound, rate, and sample values
    const updateValues = (formName = false) => {
      const defaultSubTotalValue = 100;
      const { groups, taxes, taxes_on_subtotal } = getCurrentGroupsAndTaxes();
      if (!formName) formName = meta.form;
      let sum = 0;
      const allGroups = groups.concat([taxes, taxes_on_subtotal]); // Taxes and taxes_on-subtotal are the final calcs so follow all groups
      allGroups.forEach((group, index) => {
        const groupKey =
          index + 1 === allGroups.length - 1
            ? 'taxes'
            : index + 1 === allGroups.length
            ? 'taxes_on_subtotal'
            : `groups[${index}]`;
        let inboundValue = group.inboundValue;
        if (index === 0) {
          sum = group.inboundValue;
        } else {
          store.dispatch(change(formName, `${groupKey}.inboundValue`, sum));
          inboundValue = index + 1 === allGroups.length ? defaultSubTotalValue : sum;
        }
        if (group.items && Array.isArray(group.items)) {
          group.items.forEach((item, idx) => {
            const itemKey = `items[${idx}]`;
            const definition = taxDefinitions.find((def) => def.id === item.tax_id);
            if (!definition) {
              store.dispatch(change(formName, `${groupKey}.${itemKey}.rate`, null));
              store.dispatch(change(formName, `${groupKey}.${itemKey}.sample`, null));
              return true;
            }
            const taxAmount = inboundValue * definition.rate;
            store.dispatch(change(formName, `${groupKey}.${itemKey}.rate`, definition.rate * 100));
            store.dispatch(change(formName, `${groupKey}.${itemKey}.sample`, taxAmount));
            sum += taxAmount;
          });
        }
        store.dispatch(change(formName, `${groupKey}.outboundValue`, sum));
      });
    };

    const getCountTaxDefinitionsInUse = () => {
      const { groups, taxes } = getCurrentGroupsAndTaxes();
      const allGroups = groups.concat(taxes);
      return allGroups.reduce((acc, group) => {
        if (!group.items || !Array.isArray(group.items)) return acc;
        acc += group.items.reduce((acc, item) => {
          if (item.tax_id && !isNaN(parseInt(item.tax_id))) acc++;
          return acc;
        }, 0);
        return acc;
      }, 0);
    };

    if (isArrayPush(action, validForms) || isArrayRemove(action, validForms)) {
      updateValues();
    }

    if (isChangeAction(action, validForms)) {
      if (action.meta.field.indexOf('tax_id') !== -1) {
        updateValues();
        // Add another tax line if they are not all assigned out.
        const itemsKey = `${meta.field.split('.').shift()}.items`;
        const items = selector(state, itemsKey);
        const taxDefinitionsInUse = getCountTaxDefinitionsInUse();
        const allItemsHaveDefs = items.reduce((acc, item) => {
          if (!acc) return acc;
          if (!item.tax_id || isNaN(parseInt(item.tax_id))) return false;
          return acc;
        }, true);
        // Add new item if all in this group are in use and there are some left globally
        if (allItemsHaveDefs && taxDefinitionsInUse < taxDefinitions.length) {
          store.dispatch(arrayPush(meta.form, itemsKey, { tax_id: null, rate: null, sample: null }));
        }
      }

      if (action.meta.field.indexOf('facilities') !== -1) {
        if (action.payload.length === 0) {
          store.dispatch(change(meta.form, 'customer_types', []));
        }
      }
    }

    if (isTaxProfileGet) {
      setTimeout(() => {
        // I dont like this but is a bit more than double the time required for data to be avail is redux pop, not async delay
        updateValues(TAX_PROFILE_FORM);
      }, 200);
    }
  }
  return result;
};

export default taxProfileForm;
