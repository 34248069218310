import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import { arrayRemove } from 'redux-form';
import { PARTNER_CONTACTS } from '../../../constants/forms';
import AccordionPanel from '../../common/AccordionPanel';
import Contact from './Contact';
import WillRender from '../../common/concealers/WillRender';

// eslint-disable-line import/no-named-as-default
export class Contacts extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {panels: [], clickedAddNewContact: false};
    this.handleSaveAndAddContactClick = this.handleSaveAndAddContactClick.bind(this);
    this.handleSaveContactClick = this.handleSaveContactClick.bind(this);
    this.handleAddContactClick = this.handleAddContactClick.bind(this);
    this.handleModifyContactClick = this.handleModifyContactClick.bind(this);
  }

  handleSaveAndAddContactClick(formValues) {
    this.props.handleSaveAndAddContact(formValues, () => {
      this.props.fields.push({facilities: []});
    });
  }

  handleSaveContactClick(formValues) {
    this.props.handleSaveContact(formValues);
  }

  handleAddContactClick(formValues) {
    this.props.fields.push({facilities: []});
    this.setState({clickedAddNewContact: true});
  }

  handleModifyContactClick(index) {
    return formValues => this.props.handleModifyContact(formValues.contacts[index]);
  }

  render() {
    const {fields: {map, get, length}, contactNames, partnerFacilities, disable, handleSubmit, submitting} = this.props;
    return (
      <div>
        {map((contact, index) => {
          const contactData = get(index);
          const contactName = contactNames[index]
            ? contactNames[index]
            : I18n.t('partners.createPartner.contact').replace('{0}', index + 1);
          return (
            <div key={`contact-block-${index}`} className='contact-block'>
                <AccordionPanel title={contactName}>
                  <Contact contact={contact} contactData={contactData} partnerFacilities={partnerFacilities} index={index}/>
                  <Row>
                  <WillRender ifTrue={!contactData.id}>
                    <Col md={3}>
                      <Button
                        onClick={() => {
                          this.props.actions.arrayRemove(PARTNER_CONTACTS, 'contacts', index);
                        }}
                        disabled={submitting || disable}
                        variant='primary'
                        className='btn-block'
                      >
                        {I18n.t('partners.createPartner.removeUnsavedContact')}
                      </Button>
                    </Col>
                  </WillRender>
                  </Row>
                </AccordionPanel>
            </div>
          );
        })}
        <Row>
          <Col md={2}>
            <Button
              onClick={this.handleAddContactClick}
              disabled={disable}
              style={{marginLeft: '5px'}}
              variant='primary'
              className='btn-block'
            >
              {I18n.t('partners.createPartner.addNewContact')}
            </Button>
          </Col>
          <WillRender ifTrue={length > 0}>
            <Col md={{span: 2, offset: 8}}>
              <Button
                onClick={handleSubmit(this.handleSaveContactClick)}
                disabled={submitting || disable}
                variant='primary'
                className='btn-block'
              >
                  {length === 1 ? I18n.t('partners.createPartner.saveContact') : I18n.t('partners.createPartner.saveContacts')}
              </Button>
            </Col>
          </WillRender>
        </Row>
      </div>
    );
  }
}

Contacts.propTypes = {
  contactNames: PropTypes.array.isRequired,
  partnerFacilities: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleSaveContact: PropTypes.func.isRequired,
  handleSaveAndAddContact: PropTypes.func.isRequired,
  handleModifyContact: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
  disable: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  fields: PropTypes.shape({
    push: PropTypes.func.isRequired,
    map: PropTypes.func.isRequired,
    pop: PropTypes.func.isRequired,
    length: PropTypes.number.isRequired
  }),
  editMode: PropTypes.bool
};

Contact.defaultProps = {
  editMode: false,
  handleModifyContact: () => {}
};

const mapDispatchToProps = dispatch => {
  const actions = Object.assign({}, {arrayRemove});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(null, mapDispatchToProps)(Contacts);
