import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { Row, Col } from 'react-bootstrap';

import AddItem from '../common/form/AddItem';
import InternationalReduxPhoneInput from '../common/form/redux-form/InternationalReduxPhoneInput';

const PhoneNumberRow = (props) => {
  const {fields, isRequired} = props;
  const {map,push, pop, length} = fields;

  return(
    <div>
      <Row>
        <Col xs={10} sm={10} md={10}>
          {map((item, index) => {
            return(
              <Row key={index}>
                <InternationalReduxPhoneInput
                  name={`${item}.number`}
                  props={{
                    label: I18n.t('cultivation.drivers.form.phone', {number: index + 1}),
                    isRequired,
                  }}
                />
              </Row>
            );
          })}
        </Col>

        <Col xs={2} sm={2} md={2}>
          <AddItem
            addAction={() => push({})}
            removeAction={() => pop({})}
            length={length}
            alignMode='left'
          />
        </Col>
      </Row>
    </div>
  );
};

PhoneNumberRow.propTypes = {
  isRequired: PropTypes.bool,
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }),
};

export default PhoneNumberRow;
