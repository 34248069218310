import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import {Button, ButtonToolbar, Row, Col} from 'react-bootstrap';
import TablePageWrapper from '../../common/grid/TablePageWrapper';
import Toolbar from './Toolbar';
import {makePricingName} from '../../../selectors/forms/massModifyFormSelector';


const ModalSearchProduct = (props) => {
  const {products, filterData, handleSearch, searching, addProducts, selectedSearchedItemMastersIds, handleSelect, integrationState} = props;
  // eslint-disable-next-line react/no-multi-comp
  const pricingCellColumn = (data) => (<span>{makePricingName(data)}</span>);
  const columns = [
    {
      name: 'products.massModify.productListing.name',
      dataId:'name',
      dataSort: true,
      hidden: false,
      width: '250px',
    },
    {
      name: 'products.massModify.productListing.category',
      dataId: 'category_name',
      dataSort: true,
      hidden: false,
      width: '200px',
    },
    {
      name: 'products.massModify.productListing.subcategory',
      dataId:'subcategory_name',
      dataSort: true,
      hidden: false,
      width: '250px',
    },
    {
      name: 'products.massModify.productListing.pricingGroup',
      dataId:'pricing_groups_name',
      dataSort: false,
      hidden: false,
      formatter: (cell, row) => pricingCellColumn(row.pricing_groups_name)
    },
    {
      name: 'products.massModify.productListing.pricingClass',
      dataId:'pricing_classes_name',
      dataSort: false,
      hidden: false,
      formatter: (cell, row) => pricingCellColumn(row.pricing_classes_name)
    },
  ];

  const tabs = [
    {id: 'activeTabSearchProduct', title: '', actions: []}
  ];

  return (
    <div>
      <Toolbar
        filterData={filterData}
        onSubmit={handleSearch}
        searching={searching}
        integrationState={integrationState}
      />
      <TablePageWrapper
        settingKey='mass_modify_products_search'
        data={products}
        columns={columns}
        hideScanSearch={true}
        hideExport={true}
        hideSearch={true}
        tabs={tabs}
        selectedRows={selectedSearchedItemMastersIds}
        handleSelect={handleSelect}
      />
      <Row>
        <Col md={{span: 3, offset: 9}}>
          <div className='form-actions'>
            <ButtonToolbar>
              <Button type='button' variant='primary' onClick={() => {addProducts();}}>
                {I18n.t('products.massModify.addToList')}
              </Button>
            </ButtonToolbar>
          </div>
        </Col>
      </Row>
    </div>
  );
};

ModalSearchProduct.propTypes = {
  products: PropTypes.array.isRequired,
  filterData: PropTypes.object.isRequired,
  handleSearch: PropTypes.func.isRequired,
  searching: PropTypes.bool.isRequired,
  selectedSearchedItemMastersIds: PropTypes.array.isRequired,
  handleSelect: PropTypes.func.isRequired,
  addProducts: PropTypes.func.isRequired,
  integrationState: PropTypes.object.isRequired,
};

export default ModalSearchProduct;
