import {createSelector} from 'reselect';
import get from 'lodash.get';
import map from 'lodash.map';
import filter from 'lodash.filter';
import {I18n} from 'react-redux-i18n';
import {BIOTRACK} from '../../constants/integrations';
import {
  getIntegrationSettings,
  getOrgIntegrationSettings,
  getStateIntegrationImplementation,
  getStateIntegrator
} from './commonSelectors';
import {nonTestableInventoryTypes, nonIRInventoryTypes} from '../../constants/integration/biotrack/biotrackInventoryTypes';

const getConstants = (state) => state.constants;

export const isBiotrackIntegrator = createSelector(
  [getStateIntegrator],
  integrator => integrator === BIOTRACK
);

export const getBiotrackSettings = createSelector(
  [getIntegrationSettings, isBiotrackIntegrator],
  (settings, isBiotrack) => isBiotrack && settings.integration_biotrack_settings && settings.integration_biotrack_settings.value || null
);

export const isPrBiotrackIntegrator = createSelector(
  [getBiotrackSettings],
  settings => Boolean(settings && settings.state === 'PR')
);

export const isIlBiotrackIntegrator = createSelector(
  [getBiotrackSettings],
  settings => Boolean(settings && settings.state === 'IL')
);

export const isBiotrackSetupModeEnabled = createSelector(
  [isBiotrackIntegrator, getBiotrackSettings],
  (isBiotrack, settings) => Boolean(isBiotrack && settings && settings.setup_mode)
);

export const getBiotrackFacilityIds = createSelector(
  [getOrgIntegrationSettings],
  (orgIntegrationSettings) => Object
    .keys(orgIntegrationSettings || {})
    .filter(
      (facility_id) => {
        const settings = orgIntegrationSettings[facility_id];
        return getStateIntegrationImplementation(settings) === BIOTRACK;
      }
    )
    .map(Number)
);

export const hasBiotrackFacilities = createSelector(
  [getBiotrackFacilityIds],
  (btFacilityIds) => btFacilityIds.length > 0
);

const getBiotrackInventoryTypes = createSelector([getConstants, getBiotrackSettings], (constants, settings) =>
(settings ? get(constants, `integration.biotrack.${settings.state}.inv_types`, {}) : {}));

export const getBiotrackInvTypesWithTitles = createSelector([getBiotrackInventoryTypes], (invTypes) =>
  Object.keys(invTypes).map(invType => ({
    code: invType,
    value: invTypes[invType],
    text: I18n.t(`inventory.biotrackInvTypes.${invType}`)
  }))
);

/**
 * Returning a list of inventory items without some non-produced inventory codes (nonIRInventoryTypes)
 * Applicable for Inventory Receipt page and Biotrack integration.
 * @type {Reselect.Selector<any, Array> | Reselect.Selector<any, any>}
 */
export const getBiotrackInvTypesForIRForm = createSelector(
  [getBiotrackInvTypesWithTitles],
  (invTypes) => filter(invTypes, ({ value }) => !nonIRInventoryTypes.includes(value))
);

export const getBiotrackUOMByInventoryType = createSelector(
  [getConstants, getBiotrackInventoryTypes], (constants, inventoryTypes) => {
    const uom_by_type = get(constants, 'integration.biotrack.uom_by_type', {});
    const res = {};
    map(inventoryTypes, (value, key) => {
      res[value] = uom_by_type[key];
    });
    return res;
  }
);

export const getPackageMotherPlantsInventoryTypes = createSelector(
  [getConstants, isBiotrackIntegrator, getBiotrackSettings],
  (constants, isBiotrack, settings) => {
    if (!isBiotrack) {
      return [];
    }

    const packageMotherPlants = get(constants, `integration.biotrack.${settings.state}.package_mother_plant_inv_types`, {});

    return map(packageMotherPlants, (key, value) => ({
      text:  I18n.t(`inventory.biotrackInvTypes.${value}`),
      value: key,
    }));
  }
);

export const getBiotrackHarvestTypeOptions = createSelector(
  [getConstants, getBiotrackSettings],
  (constants, settings) => {
    if(!settings) return [];
    const types = get(constants, `integration.biotrack.${settings.state}.harvest_lot_types`, {});

    return map(types, (key, value) => ({
      text: I18n.t(`harvestPackages.form.biotrack.${value}`),
      code: value,
      value: key,
    }));
  }
);

/**
 * Checking that package valid for transfer
 * means: that the package
 * has passed lab result, except isNonTestableInventoryTypes of packages
 * doesn't have on hold status
 * @param inputObject
 * @returns {*}
 */
export const checkIsPackageIsValid = (inputObject) => {
  const {item, testResults, meta, productSubTypes, salesOrder} = inputObject;
  const isLab = () => {
    if(!salesOrder || !salesOrder.partner_facility) return true;
    return salesOrder.partner_facility.partner.is_lab;
  };

  const hasTestResult = (testResult) => (
    testResult && testResult.lab_result && testResult.lab_result.status || false
  );

  // Allow those with lab result status === passed or is set and package not on hold
  const isValidTestState = (testResult) => {
    return (hasTestResult(testResult) && testResult.lab_result.status === 'passed') && (hasTestResult(testResult) && item && item.on_hold !== undefined && !item.on_hold);
  };

  const isNonTestableInventoryType = () => nonTestableInventoryTypes.indexOf(parseInt(item.integration_type, 10)) !== -1;

  const isPendingState = (testResult) => {
    const hasTestResult = testResult && testResult.lab_result;
    return (hasTestResult && testResult.lab_result.status === null);
  };

  const isNonMedicatedItem = (item) => {
    // Intentionally did not use is_mediacted - found case where that was set
    return (item.item_category_code === 'UNMEDICATED');
  };

  if(isNonMedicatedItem(item)) return true;
  if(item.is_waste === 1) return true;
  const isOnHold = item && item.on_hold !== undefined && item.on_hold;
  //const testResult = find(testResults, (result) => result.item_id === item.id) || find(get(testResults, '[0]'), (result) => result.item_id === item.id);
  const testResult = get(testResults, item.id, null);
  const productSubType = meta && meta.product_sub_type ? productSubTypes.find((type) => type.value === meta.product_sub_type) : false;
  const isItemValid = (testResult && productSubType)
    ? (typeof productSubType.comparison === 'function')
      ? productSubType.comparison(testResult)
      : testResult[productSubType.comparison] === meta.product_sub_type
    : testResult && !meta;

  const testPassed = isValidTestState(testResult);
  const isPending = isPendingState(testResult);
  const isLabPartner = isLab();

  // Check to block all on hold transfers not going to a lab
  if(!isLabPartner && isOnHold) {
    return 'onHoldPackage';
  }

  if (!isItemValid) {
    return 'noPassedTestPackage';
  }

  // Package doesn't need to have valid tests
  if (!isLabPartner && isNonTestableInventoryType()) {
    return false;
  }

  // Package wait tests results or has pending status
  if (!isLabPartner && (hasTestResult(testResult) === 'failed' || isPending)) {
    return 'noPassedTestPackage';
  }

  // Lab doesn't get packages that passed tests
  if (isLabPartner && (testPassed || isPending)) {
    return 'forLabAlreadyPassedPackage';
  }

  return false;
};
