import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack} from 'react-router-redux';
import {getUnpaginatedData, postData} from '../../../actions/apiActions';
import * as dataNames from '../../../constants/dataNames';
import {getCreatePhysicianPageProps, getPhysicianPayload} from '../../../selectors/forms/physicianFormSelectors';
import FormWrapper from '../../common/form/FormWrapper';
import InProgressOverlay from '../../common/InProgressOverlay';
import PhysicianFormWrapper from '../common/PhysicianFormWrapper';


export class CreatePhysicianPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.ensureClinic = this.ensureClinic.bind(this);
    this.state = {saving: false};
  }

  componentWillMount() {
    this.props.actions.getUnpaginatedData('/api/clinics', dataNames.clinics);
    this.props.actions.getUnpaginatedData('/api/countries', dataNames.countries);
    this.props.actions.getUnpaginatedData('/api/provinces', dataNames.provinces);
  }

  onSubmit(formValues) {
    this.setState({saving: true});
    return this.ensureClinic(formValues.clinic_id)
      .then(clinic_id => {
        return this.props.actions
          .postData(
            '/api/physicians',
            getPhysicianPayload({...formValues, clinic_id}),
            dataNames.physicians,
            {failed: 'physicians.create.failed', success: 'physicians.create.success'},
            null,
            this.props.actions.goBack
          );
      })
      .catch(() => this.setState({saving: false}));
  }

  ensureClinic(clinic) {
    if (clinic && typeof clinic === 'object') {
      return Promise.resolve(clinic.id);
    } else if (clinic && Number.isNaN(Number(clinic)) && typeof clinic === 'string' && clinic.trim().length) {
      return this.props.actions.postData('/api/clinics', {name: clinic}, dataNames.clinics, {failed: 'clinics.create.failed'})
        .then(clinic => clinic.id);
    } else if (Number(clinic)) {
      return Promise.resolve(clinic);
    }
    return Promise.resolve(null);
  }

  render() {
    const {initialValues, clinics, countries, provinces, activeLicenseIndex, primaryAddressIndex, actions: {goBack}} = this.props;
    const {saving} = this.state;

    return (
      <FormWrapper goBack={goBack} title='physicians.create.title'>
        <InProgressOverlay message='physicians.saving' isActive={saving} translate={true}/>
        <PhysicianFormWrapper
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          clinics={clinics}
          countries={countries}
          provinces={provinces}
          activeLicenseIndex={activeLicenseIndex}
          primaryAddressIndex={primaryAddressIndex}
        />
      </FormWrapper>
    );
  }
}

CreatePhysicianPage.propTypes = {
  actions: PropTypes.shape({
    getUnpaginatedData: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  initialValues: PropTypes.object,
  clinics: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  provinces: PropTypes.array.isRequired,
  primaryAddressIndex: PropTypes.number.isRequired,
  activeLicenseIndex: PropTypes.number.isRequired,
};

function mapDispatchToProps(dispatch) {
  const actions = {getUnpaginatedData, postData, goBack};

  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(getCreatePhysicianPageProps, mapDispatchToProps)(CreatePhysicianPage);
