import React from 'react';
import PropTypes from 'prop-types';
import DateTimeField from 'react-datetime';
import {FaCalendarAlt, FaRegClock} from 'react-icons/fa';
import ReduxBootstrapInput from './ReduxBootstrapInput';

const TempDatePickerInput = ({ label, size, input, meta, children, isRequired, leftAddon, timeFormat, inputProps, ...props }) => {
  const leftIcon = leftAddon || (timeFormat ? <FaRegClock/> : <FaCalendarAlt/>);

  return (
    <ReduxBootstrapInput Control={DateTimeField}
                         label={label} size={size}
                         input={input} meta={meta}
                         name={input.name} isRequired={isRequired}
                         timeFormat={timeFormat} onFocus={() => {}} leftAddon={leftIcon}
                         inputProps={inputProps}
                         className='date-picker-field' {...props} >
      {children}
    </ReduxBootstrapInput>
  );
};

TempDatePickerInput.propTypes = {
  label: PropTypes.string,
  size: PropTypes.oneOf(['lg', 'sm', 'large', 'small']),
  timeFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func
  }).isRequired,
  inputProps: PropTypes.object,
  meta: PropTypes.object.isRequired,
  children: PropTypes.node,
  leftAddon: PropTypes.node,
  isRequired: PropTypes.bool
};

TempDatePickerInput.defaultProps = {
  timeFormat: false
};

export default TempDatePickerInput;
