import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';

import CreditCardsSettingsForm from './CreditCardsSettingsForm';
import validate from './validate';

const CreditCardsSettingsFormWrapper = reduxForm({
  enableReinitialize: true,
  validate,
})(CreditCardsSettingsForm);

CreditCardsSettingsFormWrapper.propTypes = {
  creditCardProcessors: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  processor: PropTypes.string,
};

export default CreditCardsSettingsFormWrapper;
