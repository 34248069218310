import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import {Col} from 'react-bootstrap';
import {Field} from 'redux-form';
import PackageSelection from './PackageSelection';  //eslint-disable-line import/no-named-as-default
import InternationalQuantityByUomInput from '../../../common/form/InternationalQuantityByUomInput';
import TextInput from '../../../common/form/TextInput';
import AddItem from '../../../common/form/AddItem';
import {UOM_UNIT_VALUE_PRECISION} from '../../../../constants/uoms';

const UnitPackages = (props) => {

  const {
    fields, change, packages, isApplyStorageLocation, locations, isEditMode, isLotTracked, itemMaster,
    isComplete, isCancelled, integrationState, transferId, inventoryLocked, formName, isMedicated, formData,
    itemMasterIsMedicated,
  } = props;

  const {isBiotrack} = integrationState;
  const disablePackageField = !isMedicated && !get(itemMaster, 'inventory_attributes.lot_tracked');
  const isIngredient = get(itemMaster, 'inventory_attributes.is_ingredient', 0);
  const medicatedTransfersRequireEntireQuantity = get(formData, 'medicatedTransfersRequireEntireQuantity', false);
  const entireQtyRequired = Boolean(medicatedTransfersRequireEntireQuantity && itemMasterIsMedicated);

  const locationsById = {};
  (locations || []).forEach(location => locationsById[location.value] = location);

  return (
    <div>
      {fields.map((fieldName, index) => {
        const inventoryLine = fields.get ? fields.get(index) : {};
        const itemId = get(inventoryLine, 'inventory_item_id', 0);
        const packageForItemId = packages.find((pkg) => pkg.id === itemId);
        if(isIngredient && packageForItemId){ // Ingredients get here through internal transfers and display item number if not lot tracked
          packageForItemId.package_code = get(itemMaster, 'item_number');
        }

        const field = fields.get(index);
        return (
          <div key={index}>
            <PackageSelection
              isApplyStorageLocation={isApplyStorageLocation}
              locations={locations}
              locationsById={locationsById}
              packages={packages}
              fieldName={`${fields.name}[${index}]`}
              fields={fields}
              index={index}
              change={change}
              isEditMode={isEditMode}
              getFormValues={() => fields.get(index)}
              isLotTracked={isLotTracked}
              isComplete={isComplete}
              isCancelled={isCancelled}
              transferId={transferId}
              formName={formName}
              isNonMedicatedNonLotTracked={disablePackageField}
            >
              <Col xs={6} md={3} lg={2}>
                <InternationalQuantityByUomInput
                  name={`${fieldName}.qty`}
                  disabled={isComplete || isCancelled || isMedicated && medicatedTransfersRequireEntireQuantity || entireQtyRequired}
                  readOnly={isBiotrack && isMedicated}
                  fractionPartSize={UOM_UNIT_VALUE_PRECISION}
                  label={I18n.t('purchaseOrders.form.unitsTransfered')}
                  onChange={(value) => {
                    change(`${fieldName}.qty`, value);
                    change(`${fieldName}.sent_net`, field.net_weight ? (field.net_weight * value).toFixed(2) : '');
                    change(`${fieldName}.sent_gross`, field.unit_weight ? (field.unit_weight * value).toFixed(2) : '');
                  }}
                  // TODO: uom - replace hardcoded value with itemMaster default_uom value
                  uom='EA'
                />
              </Col>
              {integrationState.isNormal &&
              <React.Fragment>
                <Col xs={6} md={3} lg={1}>
                  <InternationalQuantityByUomInput
                    name={`${fieldName}.sent_net`}
                    disabled={isComplete || isCancelled || isMedicated && medicatedTransfersRequireEntireQuantity  || entireQtyRequired}
                    allowNegativeNumber={false}
                    label={I18n.t('purchaseOrders.form.sentNet')}
                    uom={field.sent_net_uom}
                  />
                  <Field
                    name={`${fieldName}.sent_net_uom`}
                    component={TextInput}
                    props={{
                      type: 'hidden',
                      value: field.sent_net_uom
                    }}
                  />
                </Col>
                <Col xs={6} md={3} lg={1}>
                  <InternationalQuantityByUomInput
                    name={`${fieldName}.sent_gross`}
                    disabled={isComplete || isCancelled || isMedicated && medicatedTransfersRequireEntireQuantity || entireQtyRequired}
                    allowNegativeNumber={false}
                    label={I18n.t('purchaseOrders.form.sentGross')}
                    uom={field.sent_gross_uom}
                  />
                  <Field
                    name={`${fieldName}.sent_gross_uom`}
                    component={TextInput}
                    props={{
                      type: 'hidden',
                      value: field.sent_gross_uom
                    }}
                  />
                </Col>
              </React.Fragment>
              }

              {integrationState.isMetrc ? <Col xs={6} md={4} lg={3}>
                <Field
                  name={`${fieldName}.state_integration_tracking_id`}
                  component={TextInput}
                  props={{
                    label: I18n.t('common.form.trackingId'),
                    readOnly: true
                  }}
                />
              </Col> : null}
            </PackageSelection>
          </div>
        );
      })}
      <AddItem
        addAction={() => {
          const lastPackage = fields.get(fields.length - 1);
          const newPackage = Object.assign({}, {}, {
            sent_net_uom: lastPackage.sent_net_uom,
            sent_gross_uom: lastPackage.sent_gross_uom,
            net_weight: lastPackage.net_weight,
            unit_weight: lastPackage.unit_weight,
            item_master_id: itemMaster.id
          });
          fields.push(newPackage);
        }}
        removeAction={fields.pop}
        length={fields.length}
        addDisabled={inventoryLocked || isMedicated && medicatedTransfersRequireEntireQuantity || entireQtyRequired}
        showMinus={!inventoryLocked && !(isMedicated && medicatedTransfersRequireEntireQuantity)}
      />
    </div>
  );
};

UnitPackages.propTypes = {
  fields: PropTypes.shape({
    push: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    map: PropTypes.func.isRequired,
    length: PropTypes.number.isRequired
  }).isRequired,
  change: PropTypes.func.isRequired,
  packages: PropTypes.array,
  isApplyStorageLocation: PropTypes.bool,
  locations: PropTypes.array,
  isEditMode: PropTypes.bool,
  isLotTracked: PropTypes.number.isRequired,
  isComplete: PropTypes.bool.isRequired,
  isCancelled: PropTypes.bool,
  inventoryLocked: PropTypes.bool,
  isMedicated: PropTypes.bool,
  itemMasterIsMedicated: PropTypes.bool,
  integrationState: PropTypes.object.isRequired,
  transferId: PropTypes.string,
  formName: PropTypes.string.isRequired,
  itemMaster: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired
};

UnitPackages.defaultProps = {
  isEditMode: false
};

export default UnitPackages;
