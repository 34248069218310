/* eslint-disable import/no-named-as-default */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {goBack} from 'react-router-redux';
import {Col, Row, Card} from 'react-bootstrap';
import get from 'lodash.get';
import * as itemNames from '../../../constants/itemNames';
import * as dataNames from '../../../constants/dataNames';
import {unsetItem} from '../../../actions/itemActions';
import * as apiActions from '../../../actions/apiActions';
import MmuLimitsPanel from './MmuLimitsPanel';
import getRulesCategories from '../../../selectors/compliance/sales/getRulesCategories';
import {getApplicationMode} from '../../../selectors/applicationModesSelectors';
import {getIntegrationState} from '../../../selectors/integration/integrationSelectors';
import {getAllowAnonymousOrders} from '../../../selectors/salesSettingsSelectors';
import getSalesSettings from '../../../util/salesSettings';
import FormWrapper from '../../common/form/FormWrapper';
import {getClosedLoopMmuOrders} from '../../../selectors/closedLoopsSelectors';
import TablePageWrapper from '../../common/grid/TablePageWrapper';
import MmuLimit from '../../customers/checkin/MmuLimit';
import InternationalDateStatic from '../../common/form/InternationalDateStatic';

const getAt = get;

const columns = [
  {
    name: 'closedLoop.salesSettings.order.completedDate',
    dataId: 'completed_date',
    hidden: false,
    width: '100px',
    dataSort: false,
    formatter: (cell) => cell ? <InternationalDateStatic adjustForClientTimezone={true}>{cell}</InternationalDateStatic> : '-'
  },
  {
    name: 'closedLoop.salesSettings.order.organizationName',
    dataId: 'organization_name',
    hidden: false,
    width: '100px',
    dataSort: false,
  },
  {
    name: 'closedLoop.salesSettings.order.facilityName',
    dataId: 'facility_name',
    width: '100px',
    hidden: false,
    dataSort: false,
  },
  {
    name: 'closedLoop.salesSettings.order.mmuPurchased',
    dataId: 'limit_data',
    hidden: false,
    width: '100px',
    dataSort: false,
    formatter: (cell, row) => {
      const data = getAt(JSON.parse(cell), 'categories');
      return data ? Number(Object.values(data).reduce((a, b) => a + b, 0)).toFixed(2) : 0;
    }
  },
  {
    name: 'closedLoop.salesSettings.order.mmuRemaining',
    dataId: 'limit_data',
    hidden: false,
    width: '100px',
    dataSort: false,
    formatter: (cell, row) => {
      const data = getAt(JSON.parse(cell), 'remaining');
      return data ? Number(data).toFixed(2) : '-';
    }
  }
];

export class MmuLimitsPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.redirect = this.redirect.bind(this);
    this.getComplianceSettings = this.getComplianceSettings.bind(this);
    this.salesSettings = getSalesSettings();
    this.setComplianceType = this.setComplianceType.bind(this);
    this.getIntegrationSettings = this.getIntegrationSettings.bind(this);
    this.getConfiguredComplianceType = this.getConfiguredComplianceType.bind(this);
    this.getConfiguredComplianceMmusLimit = this.getConfiguredComplianceMmusLimit.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.ref = React.createRef();

    this.state = {
      loading: true,
      saving: false,
      message: 'loading',
      complianceType: 'equivalency',
      complianceSet: false,
      integrationState: this.props.integrationState,
    };
  }

  componentWillMount() {
    this.props.actions.unsetItem(itemNames.salesComplianceSettings);
    this.getIntegrationSettings();
    this.getComplianceSettings();
    this.getCategories(); // For category based rules
  }

  getIntegrationSettings () {
    this.props.actions.getItem('/api/integration-settings', itemNames.integrationSettings);
  }

  getComplianceSettings() {
    this.setState({saving: false});
    const loadingComplete = () => this.setState({loading: false});
    this.salesSettings.getSettings(this.props.actions.getItem, true).then(loadingComplete).catch(loadingComplete);
    this.props.actions.getItem('/api/compliance_settings', itemNames.complianceSettings);
  }

  getCategories(){
    this.props.actions.getUnpaginatedData('/api/categories', dataNames.categories); // Ensures cats available for rules based settings
  }

  getConfiguredComplianceType(patientType){
    const rulesPath = 'order_sales_limits_by_consumer_type.value';
    const settings = getAt(this.props.salesComplianceSettings, `${rulesPath}.${patientType}.method_configuration`, []);
    return settings.length
      ? settings[0].category_code !== undefined ? 'equivalency' : 'categories'
      : 'equivalency';
  }

  getConfiguredComplianceMmusLimit(patientType){
    const rulesPath = 'order_sales_limits_by_consumer_type.value';
    const settings = getAt(this.props.salesComplianceSettings, `${rulesPath}.${patientType}.limit_to`, []);
    return settings.length
      ? settings.mmus
      : 192;
  }

  setComplianceType(complianceType){
    this.setState({complianceType});
  }

  redirect() {
    this.props.actions.goBack();
  }

  handleSearch (sort, search) {
    const params = {};
    const consumer_license = search.toString().padStart(10, '0');

    if (search) {
      params.consumer_license = consumer_license;
    }
    this.props.actions.getUnpaginatedData('/api/closed_loops/orders',
      dataNames.mmuOrders,
      null,
      params,
    );
  }

  render() {
    const {mmuOrders} = this.props;
    const customer_id = mmuOrders && get(mmuOrders, '[0].consumer_id', null);
    const retailType = getAt(this.props.facility, 'retail_type', 'medical');
    const patientType = (retailType.indexOf('co_located') !== -1) ? 'medical' : retailType;

    return (
      <FormWrapper title={I18n.t('closedLoop.salesSettings.title')} goBack={(event) => this.props.actions.goBack()}>
        <div className='retail-sales-settings'>
          <MmuLimitsPanel patientType={patientType}/>
        </div>
        <hr/>
        <h3>{I18n.t('closedLoop.salesSettings.patientLookup.title')}</h3>
        <Card>
          <Card.Body>
            <Row>
              <Col md={4}>
                <h4>{I18n.t('closedLoop.salesSettings.patientLookup.summary')}</h4>
                <span className='text-warning'>
                  {I18n.t('closedLoop.salesSettings.patientLookup.clarification')}
                </span>
              </Col>
              <Col md={1}>
                { customer_id && <MmuLimit customerId={customer_id} /> }
              </Col>
            </Row>
            <Row>
              <Col>
                <TablePageWrapper
                  ref={this.ref}
                  settingKey='closed-loop-mmu-orders'
                  columns={columns}
                  data={mmuOrders}
                  dataTotalSize={mmuOrders.length}
                  externalSearch={this.handleSearch}
                  hideSearch={false}
                  hideScanSearch={true}
                  bstProps={{pagination: false}}
                  hidePagination={true}
                  hideExport={true}
                  external={true}
                  searchPlaceholder={I18n.t('closedLoop.salesSettings.patientId')}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </FormWrapper>
    );
  }
}

MmuLimitsPage.propTypes = {
  actions: PropTypes.shape({
    getItem: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired,
  }).isRequired,
  salesComplianceSettings: PropTypes.object,
  facility: PropTypes.object,
  rulesCategories: PropTypes.array,
  integrationState: PropTypes.object,
};

function mapStateToProps(state) {
  const {salesComplianceSettings, facility} = state;
  const rulesCategories = getRulesCategories(state);
  return {
    salesComplianceSettings,
    facility,
    allowAnonymousOrders: getAllowAnonymousOrders(state),
    rulesCategories: rulesCategories,
    applicationMode: getApplicationMode(state),
    integrationState: getIntegrationState(state),
    mmuOrders: getClosedLoopMmuOrders(state),
  };
}

function mapDispatchToProps (dispatch) {
  const actions = Object.assign({}, apiActions, {goBack, unsetItem});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MmuLimitsPage);
