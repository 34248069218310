import {change} from 'redux-form';

import {INTERNAL_TRANSFERS_SETTINGS_FORM} from '../../constants/forms';
import {isChangeAction} from './utils';


const internalTransfersSettingsForm = store => next => action => {
  const {meta, payload} = action;

  if (isChangeAction(action, [INTERNAL_TRANSFERS_SETTINGS_FORM])) {
    if (action.meta.field.indexOf('checked') !== -1 ) {
      const key = meta.field.split('.').shift();
      if (payload) {
        store.dispatch(change(meta.form, `${key}.require_ir`, 1));
        store.dispatch(change(meta.form, `${key}.require_payment`, 0));
      } else {
        store.dispatch(change(meta.form, `${key}.require_ir`, null));
        store.dispatch(change(meta.form, `${key}.require_payment`, null));
      }
    }
  }

  return next(action);
};

export default internalTransfersSettingsForm;