/* eslint-disable import/no-named-as-default*/
import {change,reduxForm} from 'redux-form';
import get from 'lodash.get';

import {FEEDING_SCHEDULE_FORM} from '../../../constants/forms';
import FeedingScheduleForm from './FeedingScheduleForm';
import validate from './validate';

//
// Recalculate total schedule costs on form changes
//
const updateCalculatedCosts = (values,dispatch,props) => {
  const {ingredientsList} = props;
  const ingredients = get(values,'ingredients',[]).map(ing => {
    const itemMaster = ingredientsList.find(item => item.id === ing.item_master_id);
    if (itemMaster && itemMaster.vendors) {
      ing.unit_cost = itemMaster.vendors.length ? itemMaster.vendors.reduce((acc, vendor) => {
        acc += parseFloat(vendor.default_unit_cost || 0);
        return acc;
      }, 0) / itemMaster.vendors.length : 0;
    }
    return ing;
  });

  const totalIngredientCost = ingredients.reduce((acc, ing) => {
    acc = parseFloat(acc) + (parseFloat(ing.qty || 0) * parseFloat(ing.unit_cost || 0));
    return acc.toFixed(2);
  }, 0);

  const amountPerFeedingBase = get(values,'amount_per_feeding',0);
  const calculatedCost = parseFloat(amountPerFeedingBase * totalIngredientCost).toFixed(2);

  dispatch(change(FEEDING_SCHEDULE_FORM, 'last_applied_cost_per_liter', totalIngredientCost));
  dispatch(change(FEEDING_SCHEDULE_FORM, 'calculated_cost', calculatedCost));
};

export const FeedingScheduleFormWrapper = reduxForm({
  form: FEEDING_SCHEDULE_FORM,
  validate,
  onChange: updateCalculatedCosts,
})(FeedingScheduleForm);

export default FeedingScheduleFormWrapper;
