import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';

const ValueButtonInput = ({ label, input, children, ...props }) => {
  const {name, onChange} = input;
  return (
    <Button id={name}
      name={name} onClick={(event) => onChange(event.target.value)}
      {...props} >
        {children}
    </Button>
  );
};

ValueButtonInput.propTypes = {
  label: PropTypes.string,
  size: PropTypes.oneOf(['lg', 'sm', 'large', 'small']),
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func
  }).isRequired,
  meta: PropTypes.object.isRequired,
  children: PropTypes.node,
  isRequired: PropTypes.bool,
  leftAddon: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.node]),
  rightAddon: PropTypes.object
};

export default ValueButtonInput;
