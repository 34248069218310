import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import WillRender from '../common/concealers/WillRender';

const CustomerTileButton = ({goToCheckIn, goToProductsMenu, goToCartPage, goToPaymentPage, customer, isLockPreCheckinOnlineOrder}) => {
  //customer, fastTrack, orderType, startOrder, fillOrder, checkout, editOrder,  still needed elsewhere?

  const isShowPatientCheckIn = (get(customer, 'order.order_source') === 'online' &&
                                                get(customer, 'order.locked') && isLockPreCheckinOnlineOrder);
  return (
    <div style={{marginLeft:'5px', marginRight: '5px', fontSize: '14px'}}>
      <WillRender ifTrue={isShowPatientCheckIn}>
        <Button
          block
          variant='primary'
          onClick={() => goToCheckIn()}
        >
          {I18n.t('customer.CheckIn')}
        </Button>
      </WillRender>
      <Button
        block
        size='sm'
        variant='primary'
        onClick={() => goToProductsMenu()}
      >
        {I18n.t('customer.productsMenu')}
      </Button>
      <div className='btn-group' style={{width:'100%'}}>
        <Button
          block
          size='sm'
          variant='primary' style={{width:'49%', float: 'left', marginTop: '2px', marginRight: '2px'}}
          onClick={() => goToCartPage()}
        >
          {I18n.t('customer.fastTrack')}
        </Button>
        <Button
          block
          size='sm'
          variant='primary'
          style={{width:'49%', float: 'right', marginTop: '2px', marginLeft: '2px'}}
          disabled={customer.fulfillment_status !== 'assigned' || isShowPatientCheckIn}
          onClick={() => goToPaymentPage()} >
          {I18n.t('customer.payment')}
        </Button>
        <div style={{clear: 'both'}} />
      </div>
    </div>
  );

};

CustomerTileButton.propTypes = {
  customer: PropTypes.object,
  orderType: PropTypes.string,
  goToCheckIn: PropTypes.func,
  goToProductsMenu: PropTypes.func,
  goToPaymentPage: PropTypes.func,
  goToCartPage: PropTypes.func,
  isLockPreCheckinOnlineOrder: PropTypes.bool,
};

export default CustomerTileButton;
