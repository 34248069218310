import React from 'react';
import StatusCheckBox from './StatusCheckBox';

const StatusLegend = () => {

  return (
    <div>
      <div>
        <StatusCheckBox status={-1} withText={true} />
      </div>
      <div>
        <StatusCheckBox status={0} withText={true} />
      </div>
      <div>
        <StatusCheckBox status={1} withText={true} />
      </div>
    </div>
  );

};

export default StatusLegend;
