import SVGWarning from '../../components/common/images/SVGWarning';
import SVGClock from '../../components/common/images/SVGClock';

export const INTEGRATION_ERROR_INTEGRATOR = 'integration_error_integrator';
export const INTEGRATION_ERROR_PLATFORM = 'integration_error_platform';
export const INTEGRATION_PENDING = 'integration_pending';
export const INTEGRATION_ERROR_DUPE_ID = 'integration_bio_track_dupe_id';

export const INTEGRATION_LOCK_RANKINGS = {
  [INTEGRATION_ERROR_INTEGRATOR]: 1,
  [INTEGRATION_ERROR_PLATFORM]: 2,
  [INTEGRATION_PENDING]: 3,
  [INTEGRATION_ERROR_DUPE_ID]: 4,
};

export const INTEGRATION_LOCK_STYLES = {
  [INTEGRATION_ERROR_DUPE_ID]: {
    svgComponent: SVGWarning,
    fillColor: '#F07241',
  },
  [INTEGRATION_ERROR_INTEGRATOR]: {
    svgComponent: SVGWarning,
    fillColor: '#F07241',
  },
  [INTEGRATION_ERROR_PLATFORM]: {
    svgComponent: SVGWarning,
    fillColor: '#CC0000'
  },
  [INTEGRATION_PENDING]: {
    svgComponent: SVGClock,
    fillColor: '#f0ad4e'
  },
};
