/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';

import * as apiActions from '../../actions/apiActions';
import * as dataNames from '../../constants/dataNames';
import {getPriorityNotifications} from '../../selectors/notificationsSelectors';
import {convertDbDateTimeToFormInputDateTime} from '../../util/dateHelpers';
import ModalWrapper from '../common/ModalWrapper';
import PriorityNotifications from './PriorityNotifications';

const formatDateTime = date => convertDbDateTimeToFormInputDateTime(date);

export class PriorityNotificationModal extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.state = {processing: false};
    this.markAsRead = this.markAsRead.bind(this);
  }

  markAsRead() {
    this.setState({processing: true});
    const {notifications, user} = this.props;
    const ids = notifications.map(notification => notification.alert_id);
    if(ids && ids.length) {
      const {actions: {postData, getUnpaginatedData}} = this.props;
      const act = 'mark_viewed' ;
      postData(
        `/api/notifications/${act}`,
        {ids},
        null,
        {failed: 'notifications.markViewed.failed'},
        null,
        (data) => {
          getUnpaginatedData(
            `/api/notifications/active/user/${user.id}`, dataNames.notifications, {failed: 'notifications.get.failed'}, null,
            () => this.setState({processing: false})
          );
        }
      );
    }
  }

  render() {
    const {notifications} = this.props;

    return (
      <div className='priority-notifications'>
        <ModalWrapper
          Component={PriorityNotifications}
          onHide={this.markAsRead}
          showModal={Boolean(notifications.length)}
          title={I18n.t('notifications.priority')}
          notifications={notifications}
          formatDateTime={formatDateTime}
          markAsRead={this.markAsRead}
          processing={this.state.processing}
        />
      </div>
    );
  }
}

PriorityNotificationModal.propTypes = {
  notifications: PropTypes.array,
  actions: PropTypes.shape({
    getUnpaginatedData: PropTypes.func.isRequired,
    getData: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
};

function mapStateToProps(state) {
  const notifications = getPriorityNotifications(state);
  return {
    notifications,
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...apiActions,
  };
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PriorityNotificationModal);
