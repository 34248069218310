import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

const TestThisLabel = (props) => {

  //console.log(props, 'props in testThisLabel');

  const onInputChange = (event, field) => {
    props.onFieldChange(props.test, field, event.target.value);
  };

  const testImage = !props.test.image ?
    (<div style={{margin:'1rem'}}><em>Test Image Will Display Here</em></div>) :
    (<div><img style={{width: '100%'}} src={['data:image/png;base64', props.test.image.image.base64]}/></div>);

  return (
    <div className='col-md-4'>
      <Card>
        <Card.Body>
          <h4>{props.test.name}</h4>
          <div>
            {
              !props.test.loadingSample
                ? null
                : (<div>
                <div className='progress progress-striped active'>
                  <div style={{width: '100%'}} className='progress-bar progress-bar-warning'  role='progressbar' aria-valuenow='100' aria-valuemin='0' aria-valuemax='100'>
                    Loading Sample Label...
                  </div>
                </div>
              </div>)
            }
            {
              !props.test.loadingError
                ? null
                : (<div className='text-danger'><strong>
                An error occurred while loading... most likely the input values are incorrect.
              </strong></div>)
            }
            {testImage}
          </div>
          {
            props.test.fields.map( (field,index) => {
              return (
                <div key={index} className='form-group'>
                  <label style={{width: '100%'}}>
                    {field.label}
                    {props.test.type === 'POST'
                      ? <span style={{float: 'right'}}>(Post Field: {field.postField})</span>
                      : null
                    }
                  </label>
                  <input type='text' className='form-control form-control-sm' value={field.value} placeholder={field.placeholder}
                         onChange={(event) => onInputChange(event, field)}/>
                  {field.description ? (<div className='text-muted' style={{fontSize: 'smaller'}}>{field.description}</div>) : null}
                </div>
              );
            })
          }
          <div className='form-group'>
            <div>URL: {props.test.url}</div>
            <div>Tag Name: {props.test.tagName}</div>
            <div>Request: {props.test.type}</div>
          </div>
          {
            !props.test.connecting
            ? null
              : (<div>
                  <div className='progress progress-striped active'>
                    <div style={{width: '100%'}} className='progress-bar progress-bar-warning'  role='progressbar' aria-valuenow='100' aria-valuemin='0' aria-valuemax='100'>
                      Connecting To Printer...
                    </div>
                  </div>
                </div>)
          }
          {
            !props.test.printing
              ? null
              : (<div>
              <div className='progress progress-striped active'>
                <div style={{width: '100%'}} className='progress-bar progress-bar-success'  role='progressbar' aria-valuenow='100' aria-valuemin='0' aria-valuemax='100'>
                  Printing...
                </div>
              </div>
            </div>)
          }
          <div className='form-group'>
            <div className='row'>
            <div className='col-md-4'>
              <button className='btn btn-primary btn-block' onClick={(event) => {props.onTestLabel(props.test);}}>Load Label</button>
            </div>
            <div className='col-md-4'>
              <button className='btn btn-primary btn-block' onClick={(event) => {props.loadPrinterModal(props.test);}}>Load Modal</button>
            </div>
            <div className='col-md-4'>
              <button disabled={props.test.image === undefined || props.test.connecting || props.test.printing}
                      className='btn btn-primary btn-block' style={{float:'right'}} onClick={(event) => {props.onPrintLabel(props.test);}}>
                Print
              </button>
            </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );

};

TestThisLabel.propTypes = {
  test         : PropTypes.object.isRequired,
  testId       : PropTypes.number,
  onFieldChange: PropTypes.func.isRequired,
  onTestLabel  : PropTypes.func.isRequired,
  loadPrinterModal: PropTypes.func.isRequired
};

export default TestThisLabel;
