import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {connect} from 'react-redux';
import {Alert, Button, Col, FormLabel, Row} from 'react-bootstrap';
import {FaExclamationTriangle, FaMinus} from 'react-icons/fa';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import * as productionRunTypes from '../../../../constants/productionRunTypes';
import * as messageTypes from '../../../../constants/messageTypes';
import {getOptionsErrorMessages} from '../../common/productionRunMessages';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import TextInput from '../../../common/form/TextInput';
import ProductSearch from '../../../common/scan-searches/ProductSearch';
import TestResultsCannabinoid from '../../../finished-products/TestResultsCannabinoid'; // eslint-disable-line import/no-named-as-default
import {getIntegrationState} from '../../../../selectors/integration/integrationSelectors';
import {getBiotrackInvTypesWithTitles} from '../../../../selectors/integration/biotrackSelectors';
import {getInfusionInvTypes} from '../../../../selectors/assemblyJobsSelectors';
import InternationalQuantityByUomInput from '../../../common/form/InternationalQuantityByUomInput';
import {getUseEntityLocksForItems} from '../../../../selectors/coreSettingsSelectors';

export class InfusionInputsFieldArray extends React.PureComponent {

  constructor(props) {
    super(props);

    this.renderTableRow = this.renderTableRow.bind(this);
    this.onSearchComplete = this.onSearchComplete.bind(this);
    this.renderTableHeaders = this.renderTableHeaders.bind(this);
    this.renderIntegrationField = this.renderIntegrationField.bind(this);
    this.onSelectedProductChange = this.onSelectedProductChange.bind(this);
  }

  onSelectedProductChange(itemId) {
    const { itemOptions, clearSearch, fields } = this.props;

    const item = itemOptions.find(item => item.item_id === itemId);
    if (item) {
      fields.push({...item});
      clearSearch();
    }
  }

  onSearchComplete(packageId, items, options) {
    const { loadTestResults, itemOptions, fields, clearSearch, addMessage, integrationState, allowNegativeInventory } = this.props;
    const currentOptions = fields.getAll().map((item) => item.package_id);
    const reducedOptions = options.reduce(
      (acc, option) => acc.indexOf(option.package_id) > -1 ? acc : acc.concat([option.package_id]),
      []
    );
    options.length && loadTestResults([...currentOptions, ...reducedOptions]);
    if (options.length === 1) {
      const newItemOption = {...itemOptions[0]};
      newItemOption.qty = 0;
      fields.push({...newItemOption});
      clearSearch();
    } else if (itemOptions.length === 0 && options.length > 0) {
      const errors = getOptionsErrorMessages(options, productionRunTypes.assembly, integrationState, allowNegativeInventory);
      if (errors.length) {
        errors.forEach(error => addMessage(messageTypes.warning, [`ei.searchItem.${error}`, {packageId}]));
      } else {
        addMessage(messageTypes.warning, [`ei.searchItem.errorAlreadySelected`, {packageId}]);
      }
    }
  }

  renderIntegrationField(inputName) {
    const { integrationState, biotrackInvTypesWithTitles } =  this.props;

    return (integrationState.isBiotrack) && (
      <Field
        name={`${inputName}.integration_type`}
        component={ReactSelectInput}
        props={{
          label: I18n.t('inventory.form.inventoryType'),
          disabled: true,
          options: biotrackInvTypesWithTitles,
        }}
      />
    );
  }

  renderInfusionMessage(message) {
    return (
      <Col xs={12}>
        <Alert variant='danger'>
          <FaExclamationTriangle />
          <span className='padding-left'>{message}</span>
        </Alert>
      </Col>
    );
  }

  renderTableHeaders() {
    const {isCompleteMode} = this.props;

    return (
      <thead>
      <tr>
        <th><FormLabel>{I18n.t('ei.processing.form.lot')}</FormLabel></th>
        <th><FormLabel>{I18n.t('ei.processing.form.testResult')}</FormLabel></th>
        <th><FormLabel>{I18n.t('ei.processing.form.location')}</FormLabel></th>
        <th><FormLabel>{I18n.t('ei.processing.form.productName')}</FormLabel></th>
        <th><FormLabel>{I18n.t('ei.processing.form.vendor')}</FormLabel></th>
        <th><FormLabel>{I18n.t('ei.assemblies.form.qtyAvailable')}</FormLabel></th>
        <th><FormLabel>{I18n.t('ei.assemblies.form.qtyUsed')}</FormLabel></th>
        {isCompleteMode ? null : <th/>}
      </tr>
      </thead>
    );
  }

  renderTableRow(inputName, inputIndex) {
    const {fields, isCompleteMode, isCompleted} = this.props;
    const row = [];

    const item = fields.get(inputIndex);
    if ((typeof item.packageCode !== 'undefined') && item.packageCode != null) {
      row.push(
        <tr key={inputName}>
          <td>
            <Field name={`${inputName}.packageCode`} component={TextInput} props={{disabled: true}}/>
            {this.renderIntegrationField(inputName)}
          </td>
          <td>
            <TestResultsCannabinoid item={{reference_id: item.package_id, ...item}}/>
          </td>
          <td>
            <Field name={`${inputName}.locationName`} component={TextInput} props={{disabled: true}}/>
          </td>
          <td>
            <Field name={`${inputName}.productName`} component={TextInput} props={{disabled: true}}/>
          </td>
          <td>
            <Field name={`${inputName}.vendorName`} component={TextInput} props={{disabled: true}}/>
          </td>
          <td>
            <InternationalQuantityByUomInput
              name={`${inputName}.maxQty`}
              disabled={true}
              uom={fields.get(inputIndex).uom}
            />
          </td>
          <td>
            <InternationalQuantityByUomInput
              name={`${inputName}.qty`}
              disabled={isCompleted}
              uom={fields.get(inputIndex).uom}
            />
          </td>
          {isCompleteMode ? null :
            <td className='remove-button'>
              <Button variant='primary' size='sm' onClick={() => fields.remove(inputIndex)}>
                <FaMinus/>
              </Button>
            </td>
          }
        </tr>
      );

      // In PA, you cannot infuse failed product
      if (this.props.isPaRemediationLabelsEnabled && this.isLabResultsFailed(item)) {
        row.push(
          <tr key={`${inputName}-error`}>
            <td colSpan={isCompleteMode ? 8 : 7}>
              <div className='alert alert-danger'>
                <span className={'help-block'}>
                {I18n.t('ei.processing.form.failedTestResult')}
                </span>
              </div>
            </td>
          </tr>
        );
      }

      if (!this.props.allowNegativeInventory && fields.get(inputIndex).maxQty === 0) {
        row.push(
          <tr key={`${inputName}-error`}>
            <td colSpan={isCompleteMode ? 8 : 7}>
              <div className={'alert alert-warning'}>
              <span className={'help-block'}>
              {I18n.t('ei.processing.form.notEnoughInventory')}
              </span>
              </div>
            </td>
          </tr>
        );
      }

      return row;
    }
  }

  isLabResultsFailed(input) {
    return get(input, 'lab_result_status') === 0;
  }

  render() {

    const {
      fields,
      itemOptions,
      initialValues,
      isCompleteMode,
      integrationState,
      hasActiveReservations,
      integrationTypeOptions,
      meta,
      useEntityLocks
    } = this.props;

    return (
      <div>
        {meta && meta.error ?
          <Row>
            <Col xs={12}>
              <Alert variant='danger'>
                <FaExclamationTriangle />
                <span className='padding-left'>{meta.error}</span>
              </Alert>
            </Col>
          </Row> : null
        }
      <Row>
        {(!hasActiveReservations) && (
          this.renderInfusionMessage(I18n.t('ei.cantCompleteInfusion'))
        )}
        {(isCompleteMode && integrationTypeOptions.length === 0 && integrationState.isBiotrack) && (
          this.renderInfusionMessage(I18n.t('ei.notCompatibleInventories'))
        )}
        {fields.length === 0
          ? this.renderInfusionMessage(I18n.t('ei.inputsRequired'))
          : (
            <Col xs={12}>
              <table className='inputs-table'>
                {this.renderTableHeaders()}
                <tbody>
                {fields.map(this.renderTableRow)}
                </tbody>
              </table>
            </Col>
          )
        }
        {isCompleteMode ? null :
          <Col xs={12}>
            <Row>
              <Col xs={6} md={3}>
                <ProductSearch
                  initialValues={initialValues}
                  label={I18n.t('ei.processing.form.searchProduct')}
                  useEntityLocks={useEntityLocks}
                  filterEntityLockedItems={true}
                  warnEntityLockedItems={true}
                  onSearchComplete={this.onSearchComplete}
                />
              </Col>
              <Col xs={6} md={3}>
                {(itemOptions && itemOptions.length > 0) && (
                  <Field name='selectProduct' component={ReactSelectInput} props={{
                    options: itemOptions,
                    label: I18n.t('ei.processing.form.selectProduct'),
                    textKey: 'optionName',
                    valueKey: 'item_id',
                    onChange: this.onSelectedProductChange
                  }}/>
                )}
              </Col>
            </Row>
          </Col>
        }
      </Row>
      </div>
    );
  }
}

InfusionInputsFieldArray.defaultProps = {
  integrationTypeOptions: [],
  hasActiveReservations: true,
};

InfusionInputsFieldArray.propTypes = {
  fields: PropTypes.shape({
    get: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    map: PropTypes.func.isRequired,
  }).isRequired,
  addMessage: PropTypes.func,
  clearSearch: PropTypes.func,
  loadTestResults: PropTypes.func,
  itemOptions: PropTypes.array,
  isCompleteMode: PropTypes.bool,
  loading: PropTypes.bool,
  isCompleted: PropTypes.bool,
  initialValues: PropTypes.object,
  hasActiveReservations: PropTypes.bool,
  integrationTypeOptions: PropTypes.array,
  infusionCanBeCompleted: PropTypes.bool,
  allowNegativeInventory: PropTypes.bool,
  isLabelEnabledForNoCompletedProcess: PropTypes.bool,
  meta: PropTypes.object,
  integrationState: PropTypes.object,
  isPaRemediationLabelsEnabled: PropTypes.bool.isRequired
};

export default connect(
  (state) => ({
    integrationState: getIntegrationState(state),
    integrationTypeOptions: getInfusionInvTypes(state),
    biotrackInvTypesWithTitles: getBiotrackInvTypesWithTitles(state),
    useEntityLocks: getUseEntityLocksForItems(state)
  })
)(InfusionInputsFieldArray);
