import * as types from '../../constants/actionTypes';
import initialState from '../../constants/initialState';

export default function manualDiscountReducer (state = initialState.cart.manualDiscount, action){
  const {manualDiscount} = action;
  switch (action.type) {
  case types.SET_MANUAL_DISCOUNT:
    return manualDiscount;
  default:
    return state;
  }
}
