import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Modal, Button, ProgressBar} from 'react-bootstrap';
import moment from 'moment';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import jwt_decode from 'jwt-decode';
import {getUrlParam} from '../../util/routeHelper';

import * as userActions from '../../actions/userActions';

export class TimeoutModalContainer extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.timer = null;
    this.state = {
      disableButton: false,
      showModal: false,
      allowTesting: true,
    };

    this.updateTimer = this.updateTimer.bind(this);
    this.keepWorking = this.keepWorking.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentWillMount() {
    this.updateTimer();
  }

  componentDidUpdate() {
    this.updateTimer();
  }

  updateTimer() {
    clearInterval(this.timer);
    this.timer = setInterval(this.tick.bind(this), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  logout() {
    this.props.actions.logout();
  }

  showModal(percent, countdown) {
    if (percent >= 100) {
      this.logout();
    } else {
      this.setState({showModal: true, percent, countdown});
    }

  }

  keepWorking() {
    this.setState({disableButton: true});
    this.props.actions.getUserValid().then(() =>
      this.setState({showModal: false, disableButton: false})
    );

  }

  tick() {
    // To allow testing where we don't have to wait an hour
    const forceTimeout = this.state.allowTesting ? parseInt(getUrlParam('timeout')) : 0; // Set to a forcePeriod key
    const forcePeriods = {
      20: 3580, // 59 minutes 40 seconds remaining
      60: 3540, // 59 minutes remaining (so if you want to change language or something)
      defaultPeriod: 300, // Five minutes remaining
    };
    const threshold = forceTimeout && forcePeriods[forceTimeout] ? forcePeriods[forceTimeout] : forcePeriods['defaultPeriod'];
    const timestamp = moment.utc();
    const {exp} = jwt_decode(this.props.headers.Authorization);
    const mexp = moment.unix(exp).utc();
    const diff = mexp.diff(timestamp, 'seconds');

    if(diff < threshold) {
      this.showModal((threshold - diff) / (threshold / 100), exp - timestamp);
    } else if(this.state.showModal) {
      this.setState({showModal: false});
    }
  }

  render() {
    const {percent, disableButton} = this.state;
    const status = percent < 50 ? 'success' : (percent < 75 ? 'warning' : 'danger');
    return (
      <div>
        <Modal show={this.state.showModal}>
          <Modal.Header>
            <Modal.Title>
              {I18n.t('timeoutModal.header')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{I18n.t('timeoutModal.modalBody')}</p>
            <ProgressBar now={percent} variant={status} active/>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={disableButton} onClick={this.keepWorking}>{I18n.t('timeoutModal.keepWorkingButton')}</Button>
            <Button onClick={this.logout}>{I18n.t('timeoutModal.logoutButton')}</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

TimeoutModalContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  headers: PropTypes.object.isRequired
};

function mapStateToProps(state){
  const {headers} = state;
  return {
    headers
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(userActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (TimeoutModalContainer);
