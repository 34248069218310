import get from 'lodash.get';

/**
 * Parses error data looking for specific error conditions in failed_items.  If found returns message to display.
 * If not returns false which allows other error handling to run.
 * @param data
 * @returns {boolean}
 */
export const handleErrors = (data) => {
  const failedItems = get(data, 'response.data.errors.failed_items', []);
  // Iterate the failed items and collect all errors
  const allErrorMessages = failedItems.reduce((acc, item) => {
    const errorsObject = get(item, 'errors', {});
    // Iterate the errors by object key
    const errorMessages = Object.keys(errorsObject).reduce((acc, key) => {
      const errorsArray = errorsObject[key];
      acc = acc.concat(errorsArray);
      return acc;
    }, []);
    acc = acc.concat(errorMessages);
    return acc;
  }, []);
  return allErrorMessages.length
    ? {message: allErrorMessages.join(' | ')}
    : false;
};

export default handleErrors;



