import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

const LoadingBar = (props) => {

  const barType = props.type || 'warning';
  const classes = `progress-bar progress-bar-${barType}`;
  const message = props.message || 'common.inProgress';

  return (
    <div>
      <div className='progress progress-striped active'>
        <div style={{width: '100%'}} className={classes}  role='progressbar' aria-valuenow='100' aria-valuemin='0' aria-valuemax='100'>
          {I18n.t(message)}
        </div>
      </div>
    </div>
  );

};

LoadingBar.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
};

export default LoadingBar;
