import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';

export const validate = (values, props) => {
  const errors = {
    terminal: {},
    non_terminal: {}
  };

  for (const patient_type in values) {
    for (const product_type in get(values, patient_type)) {
      const value = get(values, `${patient_type}.${product_type}`);

      if (!value || !/^\d*\.?\d{0,4}$/.test(value)) {
        errors[patient_type][product_type] = I18n.t('retail.minimumDaysSupply.validSetting');
      }
    }
  }

  return errors;
};

export default validate;
