/* eslint-disable import/no-mutable-exports*/
import React from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { reduxForm, FieldArray, Field } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { FormGroup , Col, Row } from 'react-bootstrap';
import {FaExclamationTriangle, FaInfoCircle} from 'react-icons/fa';
import DriverAndVehicleInformation from '../common/DriverAndVehicleInformation'; //eslint-disable-line
import SalesOrders from './SalesOrders';
import TransferDestinations from '../common/TransferDestinations'; // eslint-disable-line import/no-named-as-default
import LineItems from './LineItems'; // eslint-disable-line import/no-named-as-default
import OrderTotals from './OrderTotals';
import SubmitSection from '../../common/form/SubmitSection';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import InlineCheckBox from '../../common/form/InlineCheckBox';
import PackageScanInput from '../common/PackageScanInput';
import { validate } from './validate';
import TextAreaInput from '../../common/form/TextAreaInput';
import ModalWrapper from '../../common/ModalWrapper';
import {isFeatureEnabled} from '../../../selectors/featureToggles';
import DraftManager from '../../../managers/draft';
import { getCreateTransferDefaults } from '../../../selectors/transfersSelectors';
import {isActiveFacilityGrow, isLeafUtahConfigPackClosedLoopFacility} from '../../../selectors/facilitiesSelectors';

const disposalReasonOptions = [
  {text: I18n.t('orderDisposalReasons.contaminated'), value: 'contaminated'},
  {text: I18n.t('orderDisposalReasons.damaged'), value: 'damaged'},
  {text: I18n.t('orderDisposalReasons.defective'), value: 'defective'},
  {text: I18n.t('orderDisposalReasons.expired'), value: 'expired'},
];

let draftTimer = null;

// Tap into ReduxForms onChange to allow saving all changes happening on this component or
// sub-components without attaching events to every Field in the form
const onChange = (values, dispatch, props, previousValues) => {
  const salesOrder = props.orders[0] || null;
  const sales_order_number = salesOrder ? salesOrder.sales_order_number : null;

  // To prevent spam saving on rapid keyup events, we will only save after 500ms
  // of no additional user input.
  clearTimeout(draftTimer);
  draftTimer = setTimeout(() => {
    if (sales_order_number) {
      DraftManager.setDraftData('transfer', sales_order_number, values);
    }
  }, 500);
};

export const TransferForm = (props) => {

  const {handleSubmit, isReceiptForm, defaults, reset, sentTotals, lineTotals, isMedicated,
    change, formData: {drivers, vehicles, orders, locations, sharedLocations, prepackWeights,
     containerType, inventoryItems, lines, childItemMasters, itemMasters}, formData, createDriver,
    expendedInventory, prepackExpendedInventory, isComplete, integrationState, isDriverCompany,
    isWasteDisposal, formName, hideCompletedStatus, sentNetTotals, internationalNumberFormat, integrationTransferTypes,
    currentTransferStatus, moveAllInventoryChecked, showMoveAllInventoryFeature, isSubmitClicked} = props;

  const {isBiotrack, isMetrc, isMetrcTransfersEnabled} = integrationState;

  // Implementing a clear function to wipe out the draft for the `SalesOrder` and reset initial values
  // back to the defaults, redux `reset()` will only reset to the initialValues not to an empty form so
  // we need to call the instance method `initialize()` with the defaults to ensure triggering `reset()`
  // will cause the form to go back to a default state.
  const clear = () => {
    const sales_order = orders[0] || {};
    const so_number = sales_order['sales_order_number'] || null;
    DraftManager.setDraftData('transfer', so_number, defaults);
    props.initialize(defaults);

    reset();
  }

  const statusOptions = [
    {value: 'open', text: I18n.t('cultivation.transfers.form.open')}
  ];

  if (!moveAllInventoryChecked) {
    statusOptions.push({
      value: 'out_for_delivery',
      text: I18n.t('cultivation.transfers.form.outForDelivery')
    });

    if (!hideCompletedStatus) {
      statusOptions.push({
        value: 'completed',
        text: I18n.t('cultivation.transfers.form.completed')}
      );
    }
  }

  const all_item_masters_are_shared = Array.isArray(itemMasters) ? itemMasters.reduce((result, im) => (result && !!im.is_shared_item), true) : false;
  const locations_for_move_all = all_item_masters_are_shared ?  sharedLocations : locations;

  const renderConfirmModal = () => { //eslint-disable-line
    const { showNegativeInvConfirmation } = props;

    const okayButtonProps = {
      show: true,
      onClick: showNegativeInvConfirmation.onConfirm,
      text: I18n.t('general.yes')
    };

    const cancelButtonProps = {
      show: true,
      onClick: showNegativeInvConfirmation.onHide,
      text: I18n.t('general.no')
    };

    return (
      <ModalWrapper
        Component={false}
        title={I18n.t('inventory.negativeInventoryAlert')}
        headerClass='bg-info-dark'
        onHide={showNegativeInvConfirmation.onHide}
        showModal={showNegativeInvConfirmation.show}
        okayButton={okayButtonProps}
        cancelButton={cancelButtonProps}
        dialogClassName='modal-sm'
        version={2}
      >
        <p>{I18n.t('inventory.confirmToGoNegative')}</p>
      </ModalWrapper>
    );
  };

  const submitSectionSettings = {
    actionSettings: {
      reset: {
        text: I18n.t('common.form.reset'),
        action: clear,
        style: 'default'
      },
      // Hide this for now, until this button can be used to change the order status. Adding a "Status" input
      // with "Completed" as an option gives users an alternative way to set the status.
      // saveAndComplete: {
      //   text: I18n.t('transfers.form.saveAndCompleteTransfer'),
      //   action: () => {
      //     change('status', 'completed');
      //     handleSubmit();
      //   },
      //   invalid
      // },
      saveAndStay: {
        text: I18n.t('common.form.saveAndPrint'),
        type: 'button',
        style: 'primary',
        action: () => {
          change('afterSubmit', 'goToModify');
          setTimeout(() => { // lets the afterSubmit value actually populate
            handleSubmit();
          }, 1);
        }
      },
      submit: {
        text: isMetrcTransfersEnabled ? I18n.t('salesOrders.form.saveAndCreateMetrcTemplate') : I18n.t('common.form.save'),
        type: 'button',
        style: 'primary',
        disabled: isSubmitClicked,
        action: () => {
          handleSubmit();
        }
      }
    },
    align: 'right'
  };
  const atLeastOnePartnerIsNotLab = orders.some(order => order.order_type !== 'lab');

  return (
    <form className='transfer-form' onSubmit={handleSubmit} noValidate={true}>
      <div className='alert alert-info' style={{width:'60%'}}>
        <div style={{display: 'inline-block', width:'20px', verticalAlign: 'top'}}>
          <FaInfoCircle/>
        </div>
        <div style={{display: 'inline-block', width: '80%'}}>
          This form saves your changes into a draft so you don't lose changes when you navigate away.<br/>
          If you wish to clear your pending changes and start over, use the "Reset" button on the bottom of the page.
        </div>
      </div>
      {isBiotrack ?  <div className='alert alert-danger' style={{width:'60%'}}>
        <div style={{display: 'inline-block', width:'20px', verticalAlign: 'top'}}>
          <FaInfoCircle/>
        </div>
        <div style={{display: 'inline-block', width: '80%'}}>
          {I18n.t('cultivation.supplyChain.cancelTranferBioTrack')}
        </div>
      </div> : ''}

      <DriverAndVehicleInformation
        createDriver={createDriver}
        drivers={drivers}
        vehicles={vehicles}
        change={change}
        isDriverCompany={isDriverCompany}
        integrationState={integrationState}
      />
      {!isReceiptForm ? <FieldArray name='sales_orders' component={SalesOrders} orders={orders}/> : ''}
      {!isReceiptForm ?
        <FormGroup className='transfer-destinations'>
          <FieldArray
            name='destinations'
            formName={formName}
            component={TransferDestinations}
          />
          {
            isMetrc && isMetrcTransfersEnabled &&
            <Row>
              <Col xs={12} sm={2}>
                <Field
                  name='integration_transfer_type'
                  component={ReactSelectInput}
                  props={{
                    label: I18n.t('cultivation.transfers.form.integrationTransferType', {integration: I18n.t('metrc.form.name')}),
                    options: integrationTransferTypes.map( (item) => { return { value: item.name, text: item.name }; })
                  }}
                />
              </Col>
            </Row>
          }
        </FormGroup> : ''
      }

      <FormGroup className='transfer-packages'>
        <Row>
          <Col md={8}>
            <h3>{I18n.t('cultivation.transfers.form.transferPackages')}</h3>
          </Col>
          <Col md={4}>
            <PackageScanInput
              formName={formName}
            />
          </Col>
        </Row>
        {isBiotrack && isMedicated ?
          <Row>
            <Col xs={12} style={{margin: '0px 0px 12px 0px'}} className='text-info'>
              <FaExclamationTriangle style={{marginRight: '8px'}} />
              {I18n.t('cultivation.supplyChain.wholeLotMustBeTransfered')}
            </Col>
          </Row>
          : null}
        <div style={{height: '12px'}} />
        <FieldArray
          name='lines'
          component={LineItems}
          lines={lines}
          locations={locations}
          packages={inventoryItems}
          change={change}
          formData={formData}
          isReceiptForm={isReceiptForm}
          containerType={containerType}
          prepackWeights={prepackWeights}
          childItemMasters={childItemMasters}
          sentTotals={sentTotals}
          lineTotals={lineTotals}
          prepackExpendedInventory={prepackExpendedInventory}
          expendedInventory={expendedInventory}
          isComplete={isComplete}
          isMedicated={isMedicated}
          integrationState={integrationState}
          disposalReasonOptions={disposalReasonOptions}
          formName={formName}
          drivers={drivers}
          sentNetTotals={sentNetTotals}
          internationalNumberFormat={internationalNumberFormat}
        />
      </FormGroup>
      <OrderTotals isReceiptForm={isReceiptForm} integrationState={integrationState} atLeastOnePartnerIsNotLab={atLeastOnePartnerIsNotLab}/>
      {/*<FormGroup>
        <h3>{I18n.t('cultivation.transfers.form.documentManagement')}</h3>

        <UploadFile fieldName='invoice_file' fieldLabel='cultivation.transfers.form.uploadInvoice'/>
      </FormGroup>*/}

      {
        showMoveAllInventoryFeature &&
        <FormGroup className='storage-location'>
          <h3>{I18n.t('cultivation.transfers.form.storageLocation')}</h3>
          <Row>
            <Col xs={6} md={3}>
              <Field
                name='move_all_inventory'
                component={InlineCheckBox}
                props={{
                  label: I18n.t('cultivation.transfers.form.applyStorageLocation'),
                  className: 'form-inline',
                  disabled: (currentTransferStatus !== 'open')
                }}
              />
              <div>{I18n.t('cultivation.transfers.form.applyStorageLocationHint')}</div>
            </Col>
            <Col xs={2} sm={3}>
              <Field
                name='new_location_id'
                component={ReactSelectInput}
                props={{
                  label: I18n.t('cultivation.transfers.form.storageLocation'),
                  options: locations_for_move_all
                }}
              />
            </Col>
          </Row>
        </FormGroup>
      }

      <FormGroup className='storage-location'>
        <h3>{I18n.t('cultivation.transfers.form.notes')}</h3>
        <Row>
          <Col xs={12} md={6}>
            <Field
              name='notes'
              component={TextAreaInput}
              props={{
                className: 'form-inline',
                maxLength: 2500,
                rows: 5
              }}
            />
          </Col>
        </Row>
      </FormGroup>

      {isWasteDisposal ?
        <FormGroup className='waste-disposal'>
          <h3>{I18n.t('salesOrders.form.disposalReason')}</h3>
          <Row>
            <Col xs={6} md={3} lg={2}>
              <Field
                name='disposal_reason'
                component={ReactSelectInput}
                props={{
                  options: disposalReasonOptions,
                  label: I18n.t('salesOrders.form.applyDisposalReason'),
                }}
              />
            </Col>
          </Row>
        </FormGroup>
        :
        null
      }

      <FormGroup className='order-totals'>
        <h3>{I18n.t('cultivation.transfers.form.transferStatus')}</h3>
        <Row>
          <Col xs={2} sm={2} md={2}>
            <Field
              name='status'
              component={ReactSelectInput}
              props={{
                label: I18n.t('cultivation.transfers.form.status'),
                options: statusOptions
              }}
            />
            {
              moveAllInventoryChecked &&
              <div>{I18n.t('cultivation.transfers.form.transferStatusHint')}</div>
            }
          </Col>
        </Row>
      </FormGroup>
      {renderConfirmModal()}
      <SubmitSection settings={submitSectionSettings}/>
    </form>
  );
};

TransferForm.defaultProps = {
  showNegativeInvConfirmation: {},
};

TransferForm.propTypes = {
  form: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isReceiptForm: PropTypes.bool,
  reset: PropTypes.func,
  change: PropTypes.func,
  handleKeyPress: PropTypes.func,
  invalid: PropTypes.bool,
  driver: PropTypes.object,
  vehicle: PropTypes.object,
  partner: PropTypes.array,
  array: PropTypes.object,
  formData: PropTypes.object.isRequired,
  drivers: PropTypes.array.isRequired,
  orders: PropTypes.array.isRequired,
  sentTotals: PropTypes.array.isRequired,
  lineTotals: PropTypes.array.isRequired,
  isMVP: PropTypes.bool,
  createDriver: PropTypes.func.isRequired,
  prepackExpendedInventory: PropTypes.array.isRequired,
  expendedInventory: PropTypes.array.isRequired,
  isComplete: PropTypes.bool.isRequired,
  integrationState: PropTypes.object.isRequired,
  isDriverCompany: PropTypes.bool.isRequired,
  isWasteDisposal: PropTypes.bool,
  isMedicated: PropTypes.bool,
  formName: PropTypes.string.isRequired,
  needTestResults: PropTypes.bool,
  showNegativeInvConfirmation: PropTypes.object,
  inventoryItemsTestResults: PropTypes.object,
  hideCompletedStatus: PropTypes.bool,
  isMetrcTransfersEnabled: PropTypes.bool,
  sentNetTotals: PropTypes.array,
  internationalNumberFormat: PropTypes.string.isRequired,
  currentTransferStatus: PropTypes.string,
  integrationTransferTypes: PropTypes.array,
};

// Retrieving the `default values` and stashing into a property of the component to allow
// resetting to the `default` state, used in `clear()`.  Beyond this, retrieving a draft if it exists and using
// the `draft` data as the initial values if there is a draft that is found and comparing
// the `draft` to the `defaults` to see if we have any changes and need to show a warning
const mapStateToProps = (state, props) => {
  const { orders } = props;
  const sales_order = orders[0] || {};
  const so_number = sales_order['sales_order_number'] || null;

  const defaults = getCreateTransferDefaults(state);

  let draft = null;
  if (so_number) {
    draft = DraftManager.getDraft('transfer', so_number);
  }

  return {
    showMoveAllInventoryFeature: isFeatureEnabled(state)('feature_transfers_move_all_inventory_to_new_location'),
    defaults: defaults,
    enableReinitialize: draft ? false : true, //Turning this off after draft is set to stop form from always being `pristine`
    keepDirtyOnReinitialize: true,
    initialValues: draft ? draft.getData() : defaults,
    isLeafPaBypassTransferValidationForSeedsAndClonesEnabled:
      isFeatureEnabled(state)('feature_leaf_pa_bypass_transfer_validation_for_seeds_and_clones'),
    utahCultivatorEnhancementsEnabled:
      isActiveFacilityGrow(state) &&
      isLeafUtahConfigPackClosedLoopFacility(state) &&
      isFeatureEnabled(state)('feature_utah_cultivator_enhancements')
  };
};

const WrappedComponent = compose(
  connect(mapStateToProps),
  reduxForm({
    validate,
    touchOnChange: true,
    onChange: onChange
  })
)(TransferForm);

export default WrappedComponent;
