import React from 'react';
import PropTypes from 'prop-types';
import {Col, Button} from 'react-bootstrap';
import {FaCopy, FaMinus, FaPlus} from 'react-icons/fa';
import {I18n} from 'react-redux-i18n';

const AddItem = ({length, textMode, alignMode, addAction, removeAction, copyAction, text, addDisabled, allowEmpty, showPlus, showMinus, classItem}) => {
  const alignment = alignMode ? `float-${alignMode}` : '';
  const textSide = textMode ? `${textMode}-text` : '';
  const className = `${alignment} ${textSide}`;
  const classNameItem = classItem ? classItem : `add-item`;

  if (showMinus !== false) {
    showMinus = !!(length > 1 || (length && allowEmpty));
  }

  return (
    <Col xs={12} className={classNameItem}>
      <div className={className}>
        {(textMode === 'left') &&
          <label>{I18n.t(text)}</label>
        }

        {copyAction &&
          <Button onClick={copyAction} size='sm' variant='primary'>
            <FaCopy/>
          </Button>
        }

        {showPlus &&
          <Button onClick={addAction} size='sm' variant='primary' disabled={addDisabled}>
            <FaPlus/>
          </Button>
        }

        {showMinus &&
          <Button variant='primary' size='sm' onClick={removeAction}>
            <FaMinus/>
          </Button>
        }

        {(textMode === 'right') &&
          <label>{I18n.t(text)}</label>
        }
      </div>
    </Col>
  );
};

AddItem.propTypes = {
  textMode: PropTypes.string,
  alignMode: PropTypes.string,
  text: PropTypes.string,
  addAction: PropTypes.func,
  removeAction: PropTypes.func,
  copyAction: PropTypes.func,
  length: PropTypes.number,
  addDisabled: PropTypes.bool,
  allowEmpty: PropTypes.bool,
  showPlus: PropTypes.bool,
  showMinus: PropTypes.bool,
  classItem: PropTypes.string,
};

AddItem.defaultProps = {
  length: 0,
  textMode: 'left',
  alignMode: 'right',
  text: '',
  allowEmpty: false,
  showPlus: true,
  classItem: 'add-item',
};

export default AddItem;
