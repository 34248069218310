import PropTypes from 'prop-types';
import {reduxForm, formValueSelector} from 'redux-form';
import {connect} from 'react-redux';
import validate from './validate';
import RewardForm from './RewardForm';

const form = 'rewardForm';
const selector = formValueSelector(form);

const RewardReduxForm = reduxForm({
  form,
  validate,
  enableReinitialize: true,
})(RewardForm);

function mapStateToProps(state, ownProps) {
  const isCreatePage = window.location.pathname.includes('/create');

  const initialValues = {
    consumer_group_ids: [],
    vendor_ids: [],
    item_master_ids: [],
    product_list_ids: []
  };
  // Define default initialValues based on whether the current page is /create
  if (isCreatePage) {
    return {
      showModal: selector(state, 'showModal'),
      modalCollection: selector(state, 'modalCollection'),
      restricted_items: selector(state, 'restricted_items'),
      initialValues,
    };
  } else {
    return {
      showModal: selector(state, 'showModal'),
      modalCollection: selector(state, 'modalCollection'),
      restricted_items: selector(state, 'restricted_items'),
      vendor_ids: selector(state, 'vendor_ids'),
      item_master_ids: selector(state, 'item_master_ids'),
      consumer_group_ids: selector(state, 'consumer_group_ids'),
      product_list_ids: selector(state, 'product_list_ids'),
    };
  }
}

const RewardFormWrapper = connect(mapStateToProps)(RewardReduxForm);

RewardFormWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

export default RewardFormWrapper;
