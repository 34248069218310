import {createSelector} from 'reselect';
import get from 'lodash.get';
import {getOrders, getRewardPointsOnOrder} from './ordersSelectors';
import * as itemNames from '../constants/itemNames';
import {caregivers,rewardPointsAdjustments} from '../constants/dataNames';
import {getCurrentFacilityId} from './facilitiesSelectors';
import {saveParse} from '../util/jsonHelpers';
import {getPrepackWeights} from './inventoryItemsSelectors';

export const getCustomerRecord = (state) => state[itemNames.customer];
export const getCustomer = (state) => state.searchCustomer;
export const getCustomerCaregivers = (state) => state[caregivers];
export const getRewardPointsAdjustments = (state) => state[rewardPointsAdjustments];

export const getOrdersStats = createSelector(getCustomerRecord, (customer) => {
  return {
    ordersTotal: parseFloat(customer.total_spent),
    ordersCount: customer.total_orders - customer.total_refunds,
    refunds: customer.total_refunds,
    restocks: customer.total_restocks,
  };
});

// Used for ProductRows on refunded orders to show refund information
export const refundedInventoryItems = createSelector(
  [getOrders, getPrepackWeights], (orders, prepackWeights) => {
    if (!Array.isArray(prepackWeights)) prepackWeights = [];
    return orders
      .filter(order => order.order_type === 'refund' && order.order_status === 'completed')
      .reduce((acc, order) => {
        if (!Array.isArray(order.products)) return acc;
        order.products.forEach((refundedProduct) => {
          if(!refundedProduct.hasOwnProperty('refunded_inventory')
            && !refundedProduct.hasOwnProperty('refunded_order')) {
            return;
          }

          // Original order may be null due to archiving
          const originalOrder = refundedProduct.refunded_order ? refundedProduct.refunded_order.order : null;
          const refundedItem = refundedProduct.refunded_inventory;

          let qty = refundedProduct.quantity;
          if (refundedItem && refundedItem.prepack_weight_id) {
            const prepack_weight_base = prepackWeights.find(weight => weight.id === refundedItem.prepack_weight_id).weight_base;
            if (prepack_weight_base) {
              qty = parseInt( refundedProduct.sold_weight_base / parseFloat(prepack_weight_base) );
            }
          }

          if (refundedItem) {
            refundedItem.qty = qty;
          }

          acc.push(
            Object.assign({}, refundedProduct, refundedItem, { order_name: originalOrder ? originalOrder.name : null })
          );
        });
        return acc;
      }, []);
  }
);

export const getCaregivers = createSelector([getCustomerCaregivers], (caregivers) => {
  return caregivers.reduce((acc, caregiver) => {
    if(caregiver.first_name === null && caregiver.last_name === null) return acc;
    caregiver.full_name = `${caregiver.first_name} ${caregiver.last_name}`;
    acc.push(caregiver);
    return acc;
  }, []);
});

/***
 * Returns customer points less expended points on current order
 * @type {Reselect.Selector<TInput, TOutput>}
 */
export const getAdjustedCustomerPoints = createSelector([getCustomerRecord, getRewardPointsOnOrder, getRewardPointsAdjustments], (customer, rewardPointsOnOrder, rewardPointsAdjustments) => {
  const customerPoints = +get(customer,'total_points',0);
  const pointAdjustments = rewardPointsAdjustments.reduce( (acc,adj) => {
    if(adj.customerId == get(customer,'id',null)){
      return acc + adj.points;
    }
    return acc;
  }, 0);
  return customerPoints + pointAdjustments - parseInt(rewardPointsOnOrder);
});

export const getDetailedCustomer = createSelector ([getCustomer, getCurrentFacilityId], (customers, facility_id) => {
  if(customers) {
    return customers.map(customer => getDCustomer(customer, facility_id));
  }
});

const getDCustomer = (customer, facility_id) => {
  const queues = saveParse(customer.queues, []);
  const orders = saveParse(customer.orders, []);
  const queue = queues.length ? queues.find(q => q.facility_id === facility_id) : {};
  const order = orders.length ? orders.find(o => {
    if(queue && queue.order_id){
      return o.order_id === queue.order_id && o.facility_id === facility_id;
    }else{
      return o.facility_id === facility_id;
    }
  }) : {};
  return {...customer, ...order, ...queue};
};
