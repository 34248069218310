import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLeafDataSystemsBranding } from '../../selectors/integrationSelectors';

class Branding extends React.PureComponent {
  render() {
    if (this.props.brandingClassName) {
      // Add the branding class Name to the body
      document.body.classList.add(this.props.brandingClassName);
      if (this.props.brandingClassName == 'branding-leaf-data-systems') {
        document.querySelector("link[rel*='icon']").href = '/images/leaf-favicon.ico';
        document.querySelector('title').innerHTML = 'Leaf Data Systems';
      }
    } else {
      // Remove any branding class starting with 'branding'
      const classes = document.body.className.split(' ').filter((className) => {
        return className.lastIndexOf('branding', 0) !== 0;
      });
      document.body.className = classes.join(' ').trim();
      document.querySelector("link[rel*='icon']").href = '/favicon.ico';
      document.querySelector('title').innerHTML = 'MJ Platform';
    }
    return null;
  }
}

Branding.propTypes = {
  brandingClassName: PropTypes.string
};

function mapStateToProps(state) {
  const useLeafBranding = useLeafDataSystemsBranding(state) || window.location.hostname.includes('leafdatasystems');
  const brandingClassName = useLeafBranding ? 'branding-leaf-data-systems' : undefined;

  return {
    brandingClassName
  };
}

export default connect(
  mapStateToProps,
  null
)(Branding);
