import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

const IntegrationInvalidWarning = (props) => {

  return (
    <div className='row' style={{marginTop: '12px'}}>
      <div className='col-md-12'>
        <div className='col-md-12' style={{marginBottom: '12px'}}>
          <div className='text-danger'>{I18n.t('ei.processing.form.warnings.warning')}</div>
        </div>
        <div className='col-md-12' style={{height: '400px', overflow: 'auto'}}>
          <div>{I18n.t('ei.processing.form.warnings.tableText')}</div>
          <table className='table table-bordered table-striped'>
            <thead>
              <tr>
                <th>{I18n.t('ei.processing.form.warnings.product')}</th>
                <th>{I18n.t('ei.processing.form.warnings.quantity')}</th>
              </tr>
            </thead>
            <tbody>
            {
              props.outputsInViolation.map((output, index) => {
                return (<tr key={index}>
                  {
                    props.products.length > 0
                    ? <td>{props.products.find((product) => product.id === output.item_master_id).name}</td>
                      : <td>{output.productName}</td>
                  }
                  <td>{output.qty}</td>
                </tr>);
              })
            }
            </tbody>
          </table>
          <div style={{textAlign: 'center', padding: '12px'}} className='alert-info'>
            {I18n.t('ei.processing.form.warnings.hint')}
          </div>
        </div>
        <div className='col-md-12'>
          <hr />
          <button
            onClick={(event) => {
              props.onHide();
            }}
            className='btn btn-danger'
          >
            {I18n.t('ei.processing.form.warnings.cancel')}
          </button>
          <button
            onClick={(event) => {
              props.completeSave();
            }}
            className='btn btn-primary float-right'
          >
            {I18n.t('ei.processing.form.warnings.proceed')}
          </button>
        </div>
      </div>
    </div>
  );
};

IntegrationInvalidWarning.propTypes = {
  outputsInViolation: PropTypes.array.isRequired,
  completeSave: PropTypes.func.isRequired,
};

export default IntegrationInvalidWarning;
