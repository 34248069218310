import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import lodashGet from 'lodash.get';
import {Field, formValueSelector} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';

import ComboboxInput from '../../../common/form/ComboboxInput';
import ReduxMetrcIdField from '../../../common/form/redux-form/ReduxMetrcIdField';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import TextInput from '../../../common/form/TextInput';
import InternationalDateTimePickerInput  from '../../../common/form/InternationalDateTimePickerInput';
import InlineCheckBox from '../../../common/form/InlineCheckBox';
import PackagePurposeField from '../../../common/form/redux-form/PackagePurposeField';
import MetrcConcealer from '../../../common/concealers/MetrcConcealer';
import {getInfusionInvTypes} from '../../../../selectors/assemblyJobsSelectors';
import {isFeatureEnabled} from '../../../../selectors/featureToggles';
import CheckBoxInput from '../../../common/form/CheckBoxInput';
import {COMPLETE_INFUSION_FORM} from '../../../../constants/forms';
import {getWaInventoryTypesWithName} from '../../../../selectors/integrationSelectors';
import WillRender from '../../../common/concealers/WillRender';
import InternationalQuantityByUomInput from '../../../common/form/InternationalQuantityByUomInput';
import { getUomsOfLesserOrEqualValue } from '../../../../util/uomHelpers';
import * as UOMS from '../../../../constants/uoms';
import * as dataNames from '../../../../constants/dataNames';

const InfusionOutputsFieldArray = (props) => {
  const {
    uoms,
    fields,
    phases,
    locations,
    trackingIds,
    isCompleted,
    initialValues,
    productionRuns,
    integrationState,
    waInventoryTypes,
    integrationTypeOptions,
    isMedicallyComplaintCheckedFor,
    isAllInputsAreMedicallyComplaint,
    isLabelEnabledForNoCompletedProcess,
    hasPackagesTags,
    isMetrcStemHoldingsToggled,
    isMetrcCaDonationsToggled,
    isUtahGlobalIdGenerationFeatureEnabled,
  } = props;
  const {isMetrc, isCaMetrc, isBiotrack, isCanada, isWaLeaf, isHemp} = integrationState;
  const {get, map} = fields;
  const waInventoryTypesOptions = getWaInventoryTypesWithName(waInventoryTypes);

  return (
    <div className='output-items'>
      {map((outputName, outputIndex) => {

        const output = get(outputIndex);
        const uom = lodashGet(output, 'uom', UOMS.GR);
        const viableUoms = (uoms || []).filter((uom) => isHemp ? true : uom.uom_code === UOMS.GR);
        const uomOptions = getUomsOfLesserOrEqualValue(viableUoms || [], uom);

        return (
          <div className='output-item' key={outputName}>
            <Row>
              <Col md={3}>
                <Field
                  name={`${outputName}.productName`}
                  component={TextInput}
                  props={{
                    label: I18n.t('ei.processing.form.newItem'),
                    disabled: true,
                  }}
                />
              </Col>

              <Col md={3}>
                <InternationalQuantityByUomInput
                  label={I18n.t('ei.processing.form.newQuantity')}
                  name={`${outputName}.qty`}
                  disabled={isCompleted}
                  uom={uom}
                  props={{
                    isRequired: true,
                  }}
                />
              </Col>

              <Col xs={4} md={3}>
                <Field
                  name={`${outputName}.storage_location_id`}
                  component={ReactSelectInput}
                  props={{
                    label: I18n.t('ei.processing.form.storageLocation'),
                    options: locations,
                    isRequired: true,
                    disabled: isCompleted,
                  }}
                />
              </Col>

              {
                isMetrcStemHoldingsToggled && (
                  <MetrcConcealer>
                    <Col md={3}>
                      <Field
                        name={`${outputName}.is_trade_sample`}
                        component={ReactSelectInput}
                        props={{
                          label: I18n.t('metrc.form.isTradeSample'),
                          options: [
                            {text: 'Yes', value: 1},
                            {text: 'No', value: 0}
                          ]
                        }}
                      />
                    </Col>
                  </MetrcConcealer>
                )
              }

              {
                (isCaMetrc && isMetrcCaDonationsToggled) && (
                  <MetrcConcealer>
                    <Col md={3}>
                      <Field
                        name={`${outputName}.is_donation`}
                        component={ReactSelectInput}
                        props={{
                          label: I18n.t('metrc.form.donation'),
                          options: [
                            {text: 'Yes', value: 1},
                            {text: 'No', value: 0}
                          ]
                        }}
                      />
                    </Col>
                  </MetrcConcealer>
                )
              }

              <WillRender ifTrue={isLabelEnabledForNoCompletedProcess}>
                <Col md={3}>
                  <Field name={`${outputName}.package_code`} component={TextInput} props={{
                    label: I18n.t('ei.processing.form.packageID'),
                    disabled: true,
                  }}/>
                </Col>
              </WillRender>

            </Row>
            <Row>

              <Col xs={3}>
                <Field
                  name={`${outputName}.lot_name`}
                  component={ComboboxInput}
                  props={{
                    label: I18n.t('ei.processing.form.productionRunLotName'),
                    options: productionRuns,
                    textKey: 'name',
                    valueKey: 'name',
                    isRequired: false,
                    disabled: isCompleted,
                  }}
                  // ComboboxInput may result in a string or an object.
                  normalize={value => typeof value === 'object' ? value.name : value}
                />
              </Col>

              <Col md={3}>
                <Field
                  name={`${outputName}.phase_id`}
                  component={ReactSelectInput}
                  props={{
                    label: I18n.t('ei.processing.form.newPhase'),
                    options: phases,
                    textKey: 'name',
                    valueKey: 'id',
                    isRequired: true,
                    disabled: isCompleted,
                  }}
                />
              </Col>

              <WillRender ifTrue={!isUtahGlobalIdGenerationFeatureEnabled && hasPackagesTags}>
                <Col md={3}>
                  <Field
                    name={`${outputName}.tag_requested`}
                    component={ReactSelectInput}
                    props={{
                      label: I18n.t('plants.modify.needsTag'),
                      isRequired: false,
                      options: [{text: I18n.t('common.yes'), value: 1 } , {text: I18n.t('common.no'), value:  0}],
                      textKey: 'text',
                      valueKey: 'value',
                      defaultValue: {text : I18n.t('common.yes'), value : 1 },
                      placeholder: I18n.t('common.common.yes')
                    }}
                  />
                </Col>
              </WillRender>

              <WillRender ifTrue={isCanada}>
                <Col md={3}>
                  <Field name={`${outputName}.finished`} component={InlineCheckBox} props={{
                    label: I18n.t('common.form.finishedInventoryCheckbox'),
                  }}/>
                </Col>
              </WillRender>

              <WillRender ifTrue={isMetrc}>
                <Col md={3}>
                  <ReduxMetrcIdField
                    name={`${outputName}.state_integration_tracking_id`}
                    props={{
                      options: trackingIds,
                      disabled: isCompleted,
                      isRequired: false,
                    }}
                  />
                </Col>
              </WillRender>

              <WillRender ifTrue={isWaLeaf}>
                <Col md={3}>
                  <Field
                    name={`${outputName}.integration_type`}
                    component={ReactSelectInput}
                    props={{
                      label: I18n.t('inventory.form.inventoryType'),
                      isRequired: true,
                      options: waInventoryTypesOptions,
                    }}
                  />
                </Col>
              </WillRender>

              <WillRender ifTrue={isBiotrack}>
                <Col md={3}>
                  <Field
                    name={`${outputName}.integration_type`}
                    component={ReactSelectInput}
                    props={{
                      label: I18n.t('inventory.form.inventoryType'),
                      isRequired: true,
                      options: integrationTypeOptions,
                    }}
                  />
                </Col>
              </WillRender>

              <MetrcConcealer>
                <Col md={3}>
                  <Field
                    name={`${outputName}.is_produced`}
                    component={ReactSelectInput}
                    props={{
                      label: I18n.t('metrc.form.isProductionBatch'),
                      options: [
                        {text: 'Yes', value: 1},
                        {text: 'No', value: 0}
                      ]
                    }}
                  />
                </Col>
              </MetrcConcealer>

            </Row>

            <Row>
              <Col md={3}>
                <InternationalDateTimePickerInput
                  name={`${outputName}.packaged_at`}
                  props={{
                    label: I18n.t('packaging.form.actualCompletionTime'),
                    timeFormat: true,
                    isRequired: false,
                  }}/>
              </Col>
              <Col md={3}>
                <InternationalDateTimePickerInput
                  name={`${outputName}.package_created_at`}
                  props={{
                    label: I18n.t('packaging.form.packageCreatedAt'),
                    timeFormat: false,
                  }}/>
              </Col>
              <Col md={3}>
                <InternationalDateTimePickerInput
                  name={`${outputName}.package_expires_at`}
                  props={{
                    label: I18n.t('packaging.form.packageExpiresAt'),
                    timeFormat: false,
                    isRequired: Boolean(output.tag_requested),
                    enableTodayButton: false,
                  }}/>
              </Col>
              <Col md={3}>
                <PackagePurposeField name={`${outputName}.purpose`}/>
              </Col>
            </Row>

            {(isWaLeaf) && (
              <Row>
                <Col xs={3} md={2}>
                  <Field
                    name={`${outputName}.product_not_altered`}
                    component={CheckBoxInput}
                    props={{
                      groupClassName: 'inline-checkbox',
                      label: I18n.t('packaging.form.leafWa.productNotAltered'),
                      disabled: initialValues.inputs && initialValues.inputs.length > 1,
                    }}
                  />
                </Col>
                <Col sm={3} md={12} xs={3}>
                  <div className='alert alert-info' style={{padding: '5px'}}>
                    {I18n.t('packaging.form.leafWa.productNotAlteredNote')}
                  </div>
                </Col>
                <Col xs={3} md={2}>
                  <Field
                    name={`${outputName}.medically_compliant`}
                    component={CheckBoxInput}
                    props={{
                      groupClassName: 'inline-checkbox',
                      label: I18n.t('packaging.form.leafWa.medicallyCompliant'),
                      disabled: !isAllInputsAreMedicallyComplaint
                    }}
                  />
                </Col>
                {(isAllInputsAreMedicallyComplaint && !isMedicallyComplaintCheckedFor(outputIndex)) && (
                  <Col sm={3} md={12} xs={3}>
                    <div className='alert alert-info' style={{padding: '5px'}}>
                      {I18n.t('packaging.form.leafWa.medicallyCompliantNote')}
                    </div>
                  </Col>
                )}
              </Row>
            )}

            <Row>
              <Col xs={1} md={1}>
                <Field
                  name={`${outputName}.id`}
                  component={TextInput}
                  style={{display: 'none'}}
                  props={{
                    groupClassName: 'hidden'
                  }}
                />
              </Col>
            </Row>

            <WillRender ifTrue={isLabelEnabledForNoCompletedProcess}>
              <Row>
                <Col md={3}>
                  <InternationalQuantityByUomInput
                    name={`${outputName}.usable_mmj_weight`}
                    label={I18n.t('ei.processing.form.usableMMJWeight')}
                    disabled={isCompleted}
                    props={{
                      options: uomOptions,
                      selectable: true,
                      selectableName: `${outputName}.usable_mmj_weight_uom`,
                    }}
                  />
                </Col>
                <Col md={3}>
                  <InternationalQuantityByUomInput
                    name={`${outputName}.concentrate_weight`}
                    label={I18n.t('ei.processing.form.concentrateWeight')}
                    disabled={isCompleted}
                    props={{
                      options: uomOptions,
                      selectable: true,
                      selectableName: `${outputName}.concentrate_weight_uom`,
                    }}
                  />
                </Col>
              </Row>
            </WillRender>

          </div>
        );
      })}
    </div>
  );
};

InfusionOutputsFieldArray.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    get: PropTypes.func.isRequired,
  }).isRequired,
  integrationTypeOptions: PropTypes.array,
  productionRuns: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
  trackingIds: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  phases: PropTypes.array.isRequired,
  isCompleted: PropTypes.bool,
  initialValues: PropTypes.object,
  waInventoryTypes: PropTypes.array,
  isMedicallyComplaintCheckedFor:PropTypes.func,
  isAllInputsAreMedicallyComplaint:PropTypes.bool,
  isLabelEnabledForNoCompletedProcess:PropTypes.bool,
  hasPackagesTags: PropTypes.bool,
  uoms: PropTypes.array,
  isMetrcStemHoldingsToggled: PropTypes.bool,
  isMetrcCaDonationsToggled: PropTypes.bool,
  isUtahGlobalIdGenerationFeatureEnabled: PropTypes.bool,
};
const form = COMPLETE_INFUSION_FORM;
export default connect(
  (state, ownProps) => {
    const selector = formValueSelector(form);
    const outputs = selector(state, 'outputs');
    return {
      isMetrcStemHoldingsToggled: isFeatureEnabled(state)('feature_metrc_stem_holdings'),
      isMetrcCaDonationsToggled: isFeatureEnabled(state)('feature_metrc_ca_donations'),
      isUtahGlobalIdGenerationFeatureEnabled: isFeatureEnabled(state)('feature_utah_global_id_generation'),
      integrationTypeOptions: getInfusionInvTypes(state),
      isMedicallyComplaintCheckedFor: (index) => lodashGet(outputs, `[${index}].medically_compliant`) === 1,
      isAllInputsAreMedicallyComplaint: lodashGet(ownProps, 'initialValues.inputs', []).every((input) => input.medically_compliant_status === 'yes'),
      uoms: state[dataNames.uoms],
    };
  }
)(InfusionOutputsFieldArray);
