import React from 'react';
import {Button, Form, FormControl, InputGroup} from 'react-bootstrap';
import {FaBarcode} from 'react-icons/fa';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

// Pressing "Enter" and clicking the "enter" button should behave the same
function handleKeyDown(e, handleEnterClick) {
  if (e.key === 'Enter') {
    handleEnterClick(e);
  }
}

function ScanPackage(props) {
  const {
    handleChange,
    handleEnterClick,
    value,
    inputDisabled,
    buttonDisabled,
    innerRef
  } = props;

  return (
    <React.Fragment>
      <Form.Label>Scan Package</Form.Label>
      <div className='package-row'>
        <InputGroup>
          <FormControl
            type='input'
            name='packageCode'
            value={value}
            disabled={inputDisabled}
            placeholder={I18n.t('inventory.audit.form.packageCode')}
            inputRef={innerRef}
            ref={innerRef}
            onChange={handleChange}
            onPaste={(e) => {
              // Remove line breaks and whitespaces from data
              const value = e.clipboardData.getData('text/plain').trim();
              handleEnterClick(e, value);
            }}
            onKeyDown={(e) => handleKeyDown(e, handleEnterClick)}
          />
          <InputGroup.Append>
            <Button onClick={handleEnterClick} disabled={true}><FaBarcode/></Button>
          </InputGroup.Append>
        </InputGroup>

        <Button onClick={handleEnterClick}
                disabled={buttonDisabled}>{I18n.t('inventory.audit.button.enter')}</Button>
      </div>
    </React.Fragment>);
}

ScanPackage.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleEnterClick: PropTypes.func.isRequired,
  value: PropTypes.string,
  inputDisabled: PropTypes.bool,
  buttonDisabled: PropTypes.bool,
  innerRef: PropTypes.object
};

export default React.forwardRef((props, ref) =>
  <ScanPackage innerRef={ref} {...props} />,
);
