import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field, reduxForm} from 'redux-form';
import {Button, Col, Row} from 'react-bootstrap';
import DatePickerInput from '../../common/form/DatePickerInput';
import InlineCheckBox from '../../common/form/InlineCheckBox';

export const SalesExportForm = (props) => {
  const {handleSubmit, submitting, pristine} = props;

  return (
    <form className='sales-export-form' onSubmit={handleSubmit}>
      <Row className='padding-top'>
        <Col xs={12} sm={6} md={3} lg={2}>
          <Field
            name='date'
            component={DatePickerInput}
            props={{
              label: I18n.t('common.form.date')
            }}/>
        </Col>
      </Row>
      <Row className='padding-top'>
        <Col xs={12} sm={6} md={3} lg={2}>
          <Field
            name='negativePrice'
            component={InlineCheckBox}
            props={{
              label: I18n.t('stateIntegrators.form.showNegative')
            }}/>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={2}>
          <Button
            variant='primary'
            type='submit'
            disabled={submitting || pristine}
          >
            {I18n.t('stateIntegrators.form.updateMetrcExport')}
          </Button>
        </Col>
      </Row>
      <br/>
    </form>
  );
};

SalesExportForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
};

export default reduxForm({form: 'salesExportForm'})(SalesExportForm);
