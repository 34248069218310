import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Table, Card} from 'react-bootstrap';

const PlantSplitDetails = ({model}) => {
  return (
    <Card>
      <Table striped className='plant-details'>
        <thead>
          <tr>
            <th>{I18n.t('plantHistory.field')}</th>
            <th>{I18n.t('plantHistory.value')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{I18n.t('plantHistory.plant.plantId')}</td>
            <td>{model.new_plant.plant_id}</td>
          </tr>
          <tr>
            <td>{I18n.t('plantHistory.plant.location')}</td>
            <td>{model.new_plant_section_name}</td>
          </tr>
          <tr>
            <td>{I18n.t('plantHistory.plant.qty')}</td>
            <td>{parseInt(model.split_qty) + parseInt(model.qty)} -> {model.qty}</td>
          </tr>
        </tbody>
      </Table>
    </Card>
  );
};

PlantSplitDetails.propTypes = {
  model: PropTypes.object.isRequired,
};

export default PlantSplitDetails;
