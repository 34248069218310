import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {push} from 'react-router-redux';
import {findRoutePermissions} from '../../util/routeHelper';
import PermissionWrapper from './PermissionWrapper';

class RoutePermissionWrapper extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
  }

  render () {
    const {children, permissions, accessibility} = this.props;

    return (<PermissionWrapper permissions={permissions} accessibility={accessibility}>
        {children}
      </PermissionWrapper>);
  }
}

RoutePermissionWrapper.propTypes = {
  permissions: PropTypes.array.isRequired,
  accessibility: PropTypes.object,
  children: PropTypes.node,
};

function mapStateToProps(state, ownProps){
  const {path} = ownProps;
  const permissions = findRoutePermissions(path);
  return {
    permissions
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({push}, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (RoutePermissionWrapper);
