import React from 'react';
import PropTypes from 'prop-types';
import {Field, FormSection} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import * as p from '../../../../constants/permissions';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import TextInput from '../../../common/form/TextInput';
import PermissionWrapper from '../../../common/PermissionWrapper';
import InlineCheckBox from '../../../common/form/InlineCheckBox';


const ApisicpaIntegratorSettings = (props) => {
  const {integrator, integratorName, apisicpaStates} = props;
  const override = integrator.value && integrator.value.override_environment_url || false;
  return (
    <Col xs={12}>
      <h3>{I18n.t('stateIntegrators.form.apisicpaConfiguration')}</h3>
      <FormSection name={`${integratorName}.value`}>
        <Row>
          <Col sm={6} md={4} lg={3}>
            <Field name='username' component={TextInput} props={{
              label: I18n.t('stateIntegrators.form.username'),
              isRequired: true,
            }}/>
          </Col>
          <Col sm={6} md={4} lg={3}>
            <Field name='password' component={TextInput} props={{
              label: I18n.t('stateIntegrators.form.password'),
              isRequired: true,
            }}/>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={4} lg={3}>
            <Field name='client_id' component={TextInput} props={{
              label: I18n.t('stateIntegrators.form.client_id'),
              isRequired: true,
            }}/>
          </Col>
          <Col sm={6} md={4} lg={3}>
            <Field name='client_secret' component={TextInput} props={{
              label: I18n.t('stateIntegrators.form.client_secret'),
              isRequired: true,
            }}/>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={4} lg={3}>
            <Field name='license_site_id' component={TextInput} props={{
              label: I18n.t('stateIntegrators.form.license_site_id'),
              isRequired: true,
            }}/>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={4} lg={3}>
            <Field name='state' component={ReactSelectInput} props={{
              label: I18n.t('stateIntegrators.form.state'),
              isRequired: true,
              options: apisicpaStates,
            }}/>
          </Col>
          <Col sm={6} md={4} lg={3}>
            <Field name='setup_mode' component={ReactSelectInput} props={{
              label: I18n.t('stateIntegrators.form.setupMode'),
              isRequired: true,
              options: [
                {text: 'Yes', value: true},
                {text: 'No', value: false},
              ]
            }}/>
          </Col>
        </Row>
        <PermissionWrapper
          permissions={[p.super_admin_only]}
          alternateComponent={override ?
            <Row>
              <Col sm={6} md={4} lg={3}>
                <Field name='url' component={TextInput} props={{
                  label: I18n.t('stateIntegrators.form.url'),
                  disabled: true
                }}/>
              </Col>
            </Row>
            : null
          }>
          <Row>
            <Col sm={8} md={4} lg={3}>
              <Field name='url' component={TextInput} props={{
                label: I18n.t('stateIntegrators.form.url'),
                disabled: !override,
                isRequired: override,
              }}/>
            </Col>
            <Col sm={4} md={4} lg={3}>
              <Field name='override_environment_url' component={InlineCheckBox} props={{
                label: I18n.t('stateIntegrators.form.overrideEnvironmentUrl'),
              }}/>
            </Col>
          </Row>
        </PermissionWrapper>
      </FormSection>
    </Col>
  );
};

ApisicpaIntegratorSettings.propTypes = {
  integrator: PropTypes.object.isRequired,
  integratorName: PropTypes.string.isRequired,
  apisicpaStates: PropTypes.array.isRequired,
};

export default ApisicpaIntegratorSettings;
