import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Card} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import WillRender from '../../common/concealers/WillRender';
import FormWrapper from '../../common/form/FormWrapper';
import SubmitSection from '../../common/form/SubmitSection';
import RetailHomeDeliverySettings from './partials/RetailHomeDeliverySettings';
import CourierHomeDeliverySettings from './partials/CourierHomeDeliverySettings';

const DeliveryOrderSetupForm = props => {
  const {
    title,
    goBack,
    isDispensary,
    isCourierService,
    valid,
    invalid,
    pristine,
    submitting,
    handleSubmit
  } = props;

  const settings = {
    actionSettings: {
      submit: {
        submitting,
        valid,
        invalid,
        pristine,
        text: I18n.t('common.form.save'),
        style: 'primary',
      }
    }
  };

  return (
    <FormWrapper className='home-delivery-setup' title={title} goBack={goBack}>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col sm={6}>
            <Card>
              <Card.Header>
                <h3 className='panel-title'>
                  {I18n.t('deliveryOrders.setup.courier.form.homeDelivery')}
                </h3>
              </Card.Header>

              <WillRender ifTrue={isCourierService}>
                <CourierHomeDeliverySettings />
              </WillRender>

              <WillRender ifTrue={isDispensary}>
                <RetailHomeDeliverySettings />
              </WillRender>
            </Card>
          </Col>
        </Row>
        <SubmitSection settings={settings} />
      </form>
    </FormWrapper>
  );
};

DeliveryOrderSetupForm.propTypes = {
  goBack: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isCourierService: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isDispensary: PropTypes.bool.isRequired,
  submitting: PropTypes.bool,
  valid: PropTypes.bool,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
};

export default DeliveryOrderSetupForm;