import {I18n} from 'react-redux-i18n';
import {requiredFieldValidation} from '../common/form/redux-form/validations';


export const validate = (values, props) => {
  const errors = {};
  const maxYear = new Date().getFullYear() + 1;
  const minYear = 1900;
  const year = parseInt(values.year);
  const vinMinLength1981 = 17;
  const {integrationState: {isLeaf, isBiotrack}} = props;
  if (isLeaf || isBiotrack) {
    errors.color = requiredFieldValidation(values.color, 'cultivation.vehicles.table.color');
    errors.vin = requiredFieldValidation(values.vin, 'cultivation.vehicles.table.vin');
    errors.year = requiredFieldValidation(values.year, 'cultivation.vehicles.table.year');
  }
  if(isBiotrack && !errors.vin && values.vin.length !== vinMinLength1981 && values.year >= 1981){
    errors.vin = I18n.t('cultivation.vehicles.vinLengthValidation');
  }
  if(year > maxYear){
    errors.year = I18n.t('cultivation.vehicles.yearMaxValidation', {year: maxYear});
  }
  if(year < minYear){
    errors.year = I18n.t('cultivation.vehicles.yearMinValidation', {year: minYear});
  }
  errors.make = requiredFieldValidation(values.make, 'cultivation.vehicles.table.make');
  errors.model = requiredFieldValidation(values.model, 'cultivation.vehicles.table.model');
  errors.license_number = requiredFieldValidation(values.license_number, 'cultivation.vehicles.table.plateNumber');
  errors.nickname = requiredFieldValidation(values.nickname, 'cultivation.vehicles.table.nicknameVehicle');

  errors.insurance_policy_number = requiredFieldValidation(values.insurance_policy_number, 'cultivation.vehicles.table.insurance_policy_number');
  errors.insurance_company = requiredFieldValidation(values.insurance_company, 'cultivation.vehicles.table.insurance');
  errors.insurance_phone_number = requiredFieldValidation(values.insurance_phone_number, 'cultivation.vehicles.table.phone');
  errors.insured_drivers = requiredFieldValidation(values.insured_drivers, 'cultivation.vehicles.table.insureDrivers');


  return errors;
};

export default validate;
