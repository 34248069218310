
const checkUppercase = (str) => {
  return {
    key: 'uppercase',
    isValid: str && (/[a-z]/.test(str) && /[A-Z]/.test(str))
  };
};

const checkNumber = (str) => {
  return {
    key: 'oneNumber',
    isValid: str && (/[0-9]/.test(str))
  };
};

const checkSpecialChar = (str) => {
  const chars = "'*$#!^%&@#~-_".split('');
  return {
    key: 'specialChar',
    isValid: str && chars.some(char => str.includes(char))
  };
};

const checkLength = (str) => {
  return {
    key: 'minLength',
    isValid: str && str.length >= 8
  };
};

const checkThreeInARow = (str) => {
  return {
    key: 'threeChars',
    isValid: str && !(/(.)\1{2}/.test(str))
  };
};

// check that there is no common parts of username and password with length 4 or more
const checkPartOfUsername = (password, username) => {
  const minCommonLength = 4;
  const pwd = password ? password.toLowerCase() : '';
  const name = username ? username.toLowerCase() : '';
  let valid = true;
  if (pwd.length >= minCommonLength && name.length >= minCommonLength) {
    for (let i = 0; i <= name.length - minCommonLength; i++) {
      const namePart = name.slice(i, i + minCommonLength);
      if (pwd.includes(namePart)) {
        valid = false;
        break;
      }
    }
  }
  return {
    key: 'partsOfUsername',
    isValid: password && valid,
  };
};

const checkPasswordRules = (password, username = '') => {
  return [
    checkUppercase(password),
    checkNumber(password),
    checkSpecialChar(password),
    checkLength(password),
    checkPartOfUsername(password, username),
    checkThreeInARow(password),
  ];
};

export default checkPasswordRules;