import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';

import AccordionPanel from '../../common/AccordionPanel';
import MeasurementList from './MeasurementList';
import ReactSelectInput from '../../common/form/ReactSelectInput';

const PotencyInfoPanel = ({activationTimeOptions, potencyList, requiredFields}) => {
  return (
    <AccordionPanel title='products.form.potencyInfo' className='form-panel potency-info'>
      <Row>
        <Col md={3} sm={6}>
          <Field name='activation_time' component={ReactSelectInput} props={{
            label: I18n.t('products.form.activationTime'),
            options: activationTimeOptions
          }}/>
        </Col>
      </Row>
      <MeasurementList measurements={potencyList} requiredFields={requiredFields}/>
    </AccordionPanel>
  );
};

PotencyInfoPanel.propTypes = {
  potencyList: PropTypes.array.isRequired,
  activationTimeOptions: PropTypes.array.isRequired,
  requiredFields: PropTypes.object
};

export default PotencyInfoPanel;
