import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {goBack} from 'react-router-redux';
import {Col, Row} from 'react-bootstrap';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import {getItem, getUnpaginatedData} from '../../../actions/apiActions';
import {unsetItem} from '../../../actions/itemActions';
import {getCertificateDocument} from '../../../selectors/filesSelectors';
import {labResultsHasEditPermission} from '../../../selectors/accessSelectors';
import {getTestResultDetails, getTestResultDimensions, canRenderTestResultSection} from '../../../selectors/testResultsSelectors';
import {fetchPartnerFacilities} from '../../../actions/partnerActions';

import AccordionPanel from '../../common/AccordionPanel';
import FormWrapper from '../../common/form/FormWrapper';
import RouteButton from '../../common/RouteButton';
import WillRender from '../../common/concealers/WillRender';
import TestResultProducts from '../common/TestResultProducts';
import WaterActivity from '../panels/WaterActivity';
import CannabinoidPotency from '../panels/CannabinoidPotency';
import HeavyMetals from '../panels/HeavyMetals';
import Microbials from '../panels/Microbials';
import Mycotoxins from '../panels/Mycotoxins';
import Pesticides from '../panels/Pesticides';
import ResidualSolvents from '../panels/ResidualSolvents';
import TerpeneProfile from '../panels/TerpeneProfile';
import ForeignMaterials from '../panels/ForeignMaterials';
import CustomFields from '../panels/CustomFields';
import {isWaLeafIntegrator} from '../../../selectors/integration/leafSelectors';
import {isIsolocityIntegrator} from '../../../selectors/integration/thirdPartyIntegrationSelectors';
import {clearIsolocityTestResult, fetchIsolocityTestResult} from '../../../actions/integrations/isolocity';
import * as isolocitySelectors from '../../../selectors/integration/isolocitySelectors';
import {
  getFacilityHasModule,
  isActiveFacilityClosedLoopAdmin,
  isLeafPaConfigPackClosedLoopFacility,
  isLeafUtahConfigPackClosedLoopFacility
} from '../../../selectors/facilitiesSelectors';
import {getTextForStatus} from '../common/completionStatuses';
import {getPartnerFacilities} from '../../../selectors/partnersSelectors';
import {isFeatureEnabled} from '../../../selectors/featureToggles';
import TestResultsTemplate from '../common/TestResultsTemplate';
import {addMessage} from '../../../actions/systemActions';

export class ViewTestResultsPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: false,
      referenceType: 'package'
    };

    this.getCertificateDocument = this.getCertificateDocument.bind(this);
  }

  componentWillMount() {

    const {params: {id}, isIsolocity, isClosedLoopAdmin} = this.props;
    const {getUnpaginatedData, getItem} = this.props.actions;
    this.setState({loading: true});

    const promises = [
      getUnpaginatedData('/api/partners', dataNames.partners, {failed: 'partners.get.failed'}, {is_lab: 1}),
      getUnpaginatedData('/api/subcategories', dataNames.subcategories),
      this.props.actions.fetchPartnerFacilities(),
      this.props.actions.getItem('/api/compliance_settings', itemNames.complianceSettings),
    ];

    if (isClosedLoopAdmin) {
      promises.push(getItem(`/api/closed_loops/lab_results/${id}`, itemNames.testResult));
    } else {
      promises.push(
        getItem(`/api/lab_results/${id}`, itemNames.testResult, {failed: 'cultivation.testResults.get.failed'}).then((testResult) => {
          const referenceType = get(testResult, 'references.0.reference_type', 'package');
          this.setState({
            referenceType
          });
          const coaFileId = get(testResult, 'coa_file_id', null);
          if (coaFileId !== null) {
            this.getCertificateDocument(coaFileId);
          }
        })
      );
    }



    if (isIsolocity) {
      promises.push(this.props.actions.fetchIsolocityTestResult(id));
    }

    Promise
      .all(promises)
      .then(() => this.setState({loading: false}))
      .catch(() => this.setState({loading: false}));
  }

  componentDidMount() {
    this.props.actions.getItem('/api/lab_results/configuration', itemNames.testResultConfiguration);
  }

  componentWillUnmount() {
    this.props.actions.clearIsolocityTestResult();
  }

  getCertificateDocument(id) {
    this.props.actions.unsetItem(itemNames.certificateOfAnalysisDocument);
    return this.props.actions.getItem(`/api/documents/${id}`, itemNames.certificateOfAnalysisDocument);
  }

  render() {
    const {
      isWaLeaf,
      testResult,
      isIsolocity,
      isolocityTestResult,
      facilityHasLabModule,
      labResultDimensions,
      hasEditPermission,
      canRenderDimensionGroup,
      isUtahQaChangeRequestEnabled,
      isPaLabEnhancementEnabled,
      isLeafPaConfigPackClosedLoopFacility,
      isLeafUtahConfigPackClosedLoopFacility,
      addMessage
    } = this.props;

    const {referenceType} = this.state;
    const documentUrl = this.props.certificateDocument ? (this.props.certificateDocument.url ? this.props.certificateDocument.url : null) : null;
    const isIntegrationSource = testResult.state_integration_source;
    const medicalConditions = !isIsolocity || isEmpty(testResult.medical_conditions)
      ? null
      : JSON.parse(testResult.medical_conditions);
    const medicalConditionValues = !isIsolocity || !medicalConditions
      ? null
      : (medicalConditions && medicalConditions.length > 1
        ? I18n.t('cultivation.testResults.isolocity.multiple')
        : medicalConditions[0]);

    let isolocityStatus = get(testResult, 'isolocity_status');
    isolocityStatus = isolocityStatus ? isolocityStatus : 'In Progress';

    const test_type = testResult.test_type ? testResult.test_type.charAt(0).toUpperCase() + testResult.test_type.slice(1) : 'Standard';

    const isPerServingMode = get(testResult, 'test_results_value_type', 'percentage') === 'serving';
    const servingsUom = get(testResult, 'servings_uom', 'mg');

    return (
      <FormWrapper goBack={this.props.actions.goBack} title='cultivation.testResults.viewTitle' className='test-results-details'>
        <Row className='general-info'>
          <Col xs={8}>
            <table className='table table-striped'>
              <thead>
              <tr>
                {facilityHasLabModule && <th>{I18n.t('cultivation.testResults.form.Partner')}</th>}
                {!facilityHasLabModule && <th>{I18n.t('cultivation.testResults.form.labPartner')}</th>}
                <th>{I18n.t('cultivation.testResults.form.testingId')}</th>
                <th>{I18n.t('cultivation.testResults.table.testingDate')}</th>
                <th>{I18n.t('cultivation.testResults.table.status')}</th>
                <th>{I18n.t('cultivation.testResults.table.certificateUpload.certTitle')}</th>
                {isPaLabEnhancementEnabled && <th>{I18n.t('cultivation.testResults.table.stabilityTestDate')}</th>}
                {isLeafPaConfigPackClosedLoopFacility && isPaLabEnhancementEnabled && <th>{I18n.t('cultivation.testResults.testType')}</th>}
                {facilityHasLabModule && <th>{I18n.t('cultivation.testResults.form.completionStatus')}</th>}
                {isWaLeaf && isIntegrationSource && <th>{I18n.t('cultivation.testResults.table.eligibleForExtraction')}</th>}
                {isWaLeaf && isIntegrationSource && <th>{I18n.t('cultivation.testResults.table.eligibleForRetest')}</th>}
                {isIsolocity && <th>{I18n.t('cultivation.testResults.isolocity.analystName')}</th>}
                {isIsolocity && <th>{I18n.t('cultivation.testResults.isolocity.medicalConditions')}</th>}
                {isIsolocity && <th>{I18n.t('cultivation.testResults.isolocity.status')}</th>}
                <td rowSpan={2}>
                  <TestResultsTemplate
                    labResultDimensions={labResultDimensions}
                    testResult={testResult}
                    addMessage={addMessage}
                    show={isLeafUtahConfigPackClosedLoopFacility}
                    downloadOnly={true}
                  />
                </td>
              </tr>
              </thead>
              <tbody>
              <tr>
                {facilityHasLabModule && <td>{get(testResult, 'for_partner_name')}</td>}
                {!facilityHasLabModule && <td>{testResult.partner_name}</td>}
                <td>{testResult.testing_id}</td>
                <td>
                  {
                    testResult.testing_date
                      ? <span>{testResult.testing_date}</span>
                      : <span>&nbsp;</span>
                  }
                </td>
                <td className={testResult.status === 'failed' ? 'text-danger capitalize' : 'text-success capitalize'}>{testResult.status}</td>
                <td>{!documentUrl ? null : <a target='_new' href={documentUrl}><button>{I18n.t('cultivation.testResults.table.certificateUpload.viewCOA')}</button></a>}</td>
                {isPaLabEnhancementEnabled && <td>
                  {
                    testResult.stability_test_date
                      ? <span>{testResult.stability_test_date}</span>
                      : <span>&nbsp;</span>
                  }
                </td>}
                {isLeafPaConfigPackClosedLoopFacility && isPaLabEnhancementEnabled && <td>{test_type}</td>}
                {facilityHasLabModule && <td>{getTextForStatus(testResult.completion_status)}</td>}
                {isWaLeaf && isIntegrationSource && <td className={testResult.extraction_eligible ? 'text-success' : 'text-danger '}>
                  {testResult.extraction_eligible ? 'Yes' : 'No'}
                </td>}
                {isWaLeaf && isIntegrationSource && <td className={testResult.retest_eligible ? 'text-success' : 'text-danger'}>
                  {testResult.retest_eligible ? 'Yes' : 'No'}
                </td>}
                {isIsolocity && <td>{testResult.analyst_name}</td>}
                {isIsolocity && <td>{medicalConditionValues}</td>}
                {isIsolocity && <td>{isolocityStatus}</td>}
              </tr>
              </tbody>
            </table>
            {hasEditPermission &&
            <RouteButton
              path={`/test-results/modify/${testResult.id}`}
              disabled={!get(testResult, 'references.0.is_active_package')}
              props={{variant: 'primary'}}
            >
              {I18n.t('cultivation.testResults.edit')}
            </RouteButton>}
          </Col>
        </Row>

        <AccordionPanel title={'cultivation.testResults.form.packages'}>
          <TestResultProducts row={testResult} referenceType={referenceType}/>
        </AccordionPanel>

        <WillRender ifTrue={isUtahQaChangeRequestEnabled ? canRenderDimensionGroup('water_activity', testResult.references) && labResultDimensions.hasOwnProperty('water_activity') : labResultDimensions.hasOwnProperty('water_activity')}>
          <AccordionPanel title={'cultivation.testingCompliance.waterActivity.title'} defaultExpanded={false}>
            <WaterActivity
              row={testResult}
              editable={false}
              isIsolocity={isIsolocity}
              isolocityTestResult={isolocityTestResult}
              dimensions={labResultDimensions}
            />
          </AccordionPanel>
        </WillRender>

        <WillRender ifTrue={isUtahQaChangeRequestEnabled ? canRenderDimensionGroup('cannabinoid_potency', testResult.references) : true}>
          <AccordionPanel title={'cultivation.testResults.table.cannabinoidPotency'} defaultExpanded={false}>
            <CannabinoidPotency
              row={testResult}
              editable={false}
              isIsolocity={isIsolocity}
              isolocityTestResult={isolocityTestResult}
              dimensions={labResultDimensions}
              isPerServingMode={isPerServingMode}
              servingsUom={servingsUom}
            />
          </AccordionPanel>
        </WillRender>

        <WillRender ifTrue={isUtahQaChangeRequestEnabled ? canRenderDimensionGroup('terpene_profile', testResult.references) : true}>
          <AccordionPanel title={'cultivation.testingCompliance.terpeneProfile.title'} defaultExpanded={false}>
            <TerpeneProfile
              row={testResult}
              editable={false}
              isIsolocity={isIsolocity}
              isolocityTestResult={isolocityTestResult}
              dimensions={labResultDimensions}
              isPerServingMode={isPerServingMode}
              servingsUom={servingsUom}
            />
          </AccordionPanel>
        </WillRender>

        <WillRender ifTrue={isUtahQaChangeRequestEnabled ? canRenderDimensionGroup('microbial', testResult.references) : true}>
          <AccordionPanel title={'cultivation.testingCompliance.microbials.title'} defaultExpanded={false}>
            <Microbials
              row={testResult}
              editable={false}
              isIsolocity={isIsolocity}
              isolocityTestResult={isolocityTestResult}
              dimensions={labResultDimensions}
            />
          </AccordionPanel>
        </WillRender>

        <WillRender ifTrue={isUtahQaChangeRequestEnabled ? canRenderDimensionGroup('residual_solvents', testResult.references) : true}>
          <AccordionPanel title={'cultivation.testingCompliance.residualSolvents.title'} defaultExpanded={false}>
            <ResidualSolvents
              row={testResult}
              editable={false}
              isIsolocity={isIsolocity}
              isolocityTestResult={isolocityTestResult}
              dimensions={labResultDimensions}
            />
          </AccordionPanel>
        </WillRender>

        <WillRender ifTrue={isUtahQaChangeRequestEnabled ? canRenderDimensionGroup('heavy_metals', testResult.references) : true}>
          <AccordionPanel title={'cultivation.testingCompliance.heavyMetals.title'} defaultExpanded={false}>
            <HeavyMetals
              row={testResult}
              editable={false}
              isIsolocity={isIsolocity}
              isolocityTestResult={isolocityTestResult}
              dimensions={labResultDimensions}
            />
          </AccordionPanel>
        </WillRender>

        <WillRender ifTrue={isUtahQaChangeRequestEnabled ? canRenderDimensionGroup('mycotoxin', testResult.references) : true}>
          <AccordionPanel title={'cultivation.testingCompliance.mycotoxins.title'} defaultExpanded={false}>
            <Mycotoxins
              row={testResult}
              editable={false}
              isIsolocity={isIsolocity}
              isolocityTestResult={isolocityTestResult}
              dimensions={labResultDimensions}
            />
          </AccordionPanel>
        </WillRender>

        <WillRender ifTrue={isUtahQaChangeRequestEnabled ? canRenderDimensionGroup('pesticides', testResult.references) : true}>
          <AccordionPanel title={'cultivation.testingCompliance.pesticides.title'}  defaultExpanded={false}>
            <Pesticides
              row={testResult}
              editable={false}
              isIsolocity={isIsolocity}
              isolocityTestResult={isolocityTestResult}
              dimensions={labResultDimensions}
            />
          </AccordionPanel>
        </WillRender>

        <WillRender ifTrue={isUtahQaChangeRequestEnabled ? canRenderDimensionGroup('foreign_materials', testResult.references) && labResultDimensions.hasOwnProperty('foreign_materials') : labResultDimensions.hasOwnProperty('foreign_materials')}>
          <AccordionPanel title={'cultivation.testingCompliance.foreignMaterials.title'} defaultExpanded={false}>
            <ForeignMaterials
              row={testResult}
              editable={false}
              isIsolocity={isIsolocity}
              isolocityTestResult={isolocityTestResult}
              dimensions={labResultDimensions}
            />
          </AccordionPanel>
        </WillRender>

        <WillRender ifTrue={labResultDimensions.hasOwnProperty('custom_fields')}>
          <AccordionPanel title={'cultivation.testingCompliance.customFields.title'} defaultExpanded={false}>
            <CustomFields
              row={testResult}
              editable={false}
              dimensions={labResultDimensions}
            />
          </AccordionPanel>
        </WillRender>

      </FormWrapper>
    );
  }
}

ViewTestResultsPage.propTypes = {
  actions: PropTypes.shape({
    getUnpaginatedData: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    fetchPartnerFacilities: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired,
  }).isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  testResult: PropTypes.object.isRequired,
  isWaLeaf: PropTypes.bool,
  labResultDimensions: PropTypes.object,
  partnerFacilities: PropTypes.array.isRequired,
  hasEditPermission: PropTypes.bool,
  canRenderDimensionGroup: PropTypes.func.isRequired,
  isUtahQaChangeRequestEnabled: PropTypes.bool.isRequired,
  isClosedLoopAdmin: PropTypes.bool.isRequired,
  isPaLabEnhancementEnabled: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    isWaLeaf: isWaLeafIntegrator(state),
    testResult: getTestResultDetails(state, ownProps),
    isIsolocity: isIsolocityIntegrator(state),
    isolocityTestResult: isolocitySelectors.getTestResult(state),
    certificateDocument: getCertificateDocument(state),
    facilityHasLabModule: getFacilityHasModule(state)('TESTING_LAB'),
    labResultDimensions: getTestResultDimensions(state),
    partnerFacilities: getPartnerFacilities(state),
    hasEditPermission: labResultsHasEditPermission(state),
    canRenderDimensionGroup: canRenderTestResultSection(state),
    isUtahQaChangeRequestEnabled: isFeatureEnabled(state)('feature_ut_qa_change_request'),
    isClosedLoopAdmin: isActiveFacilityClosedLoopAdmin(state),
    isPaLabEnhancementEnabled: isFeatureEnabled(state)('feature_pa_hb_1024_lab_enhancements'),
    isLeafPaConfigPackClosedLoopFacility: isLeafPaConfigPackClosedLoopFacility(state),
    isLeafUtahConfigPackClosedLoopFacility: isLeafUtahConfigPackClosedLoopFacility(state),
  };
}

function mapDispatchToProps (dispatch) {
  const actions = {
    getItem,
    unsetItem,
    getUnpaginatedData,
    fetchIsolocityTestResult,
    clearIsolocityTestResult,
    fetchPartnerFacilities,
    goBack,
    addMessage
  };
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewTestResultsPage);
