import React from 'react';
import PropTypes from 'prop-types';
import {Field, FieldArray, formValueSelector} from 'redux-form';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import TextInput from '../../../common/form/TextInput';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import SubmitSection from '../../../common/form/SubmitSection';
import IngredientsFieldArray from './../common/IngredientsFieldArray';
import DirectionsRow from './../common/DirectionsRow';
import InternationalQuantityByUomInput from '../../../common/form/InternationalQuantityByUomInput';
import {ASSEMBLY_FORM} from '../../../../constants/forms';
import InternationalCurrencyInput from '../../../common/form/InternationalCurrencyInput';
import InternationalDecimalInput from '../../../common/form/InternationalDecimalInput';

const AssemblyForm = (props) => {
  const {
    products,
    ingredients,
    handleSubmit,
    invalid,
    submitting,
    change,
    totalCost,
    getFormValue,
    getPossibleUom,
    addMessage,
    integrationState,
    uomDisplay,
  } = props;

  const submitSectionSettings = {
    actionSettings: {
      // printAssembly: {
      //   invalid,
      //   submitting,
      //   text: I18n.t('ei.assemblies.form.printAssembly'),
      //   style: 'default',
      //   action: () => {
      //     handleSubmit();
      //   }
      // },
      // emailAssembly: {
      //   invalid,
      //   submitting,
      //   text: I18n.t('ei.assemblies.form.emailAssembly'),
      //   style: 'default',
      //   action: () => {
      //     handleSubmit();
      //   },
      // },
      saveAndDuplicate: {
        invalid,
        submitting,
        style: 'default',
        text: I18n.t('ei.assemblies.form.saveAndDuplicateAssembly'),
        action: () => change('saveAndDuplicate', true),
        type: 'submit'
      },
      submit: {
        invalid,
        submitting,
        text: I18n.t('ei.assemblies.form.saveAssembly'),
        action: handleSubmit
      }
    },
    align: 'right'
  };
  const hasRuns = getFormValue && getFormValue('active_production_runs_count.active_production_runs_count') > 0;

  return (
    <form className='assembly-form' onSubmit={handleSubmit}>
          <Row>
            <Col xs={12} sm={8} md={4}>
              <Field
                name='name'
                component={TextInput}
                props={{
                  label: I18n.t('ei.assemblies.form.name'),
                  isRequired: true
                }}/>
            </Col>
            <Col xs={12} sm={8} md={4}>
              <Field
                name='item_master_id'
                component={ReactSelectInput}
                props={{
                  label: I18n.t('ei.assemblies.form.item'),
                  isRequired: true,
                  options: products,
                  textKey: 'name',
                  valueKey: 'id',
                  disabled: hasRuns
                }}/>
            </Col>
            <Col xs={12} sm={8} md={4}>
              <InternationalQuantityByUomInput
                name='qty'
                label={I18n.t('ei.assemblies.form.qty')}
                isRequired={true}
                disabled={hasRuns}
                uom={uomDisplay}
              />
            </Col>
          </Row>
          <Row className='cannabinoid-table'>
            <Col xs={12} sm={12} md={12}>
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <p>{I18n.t('ei.assemblies.form.cannabinoidTableTitle')}</p>
                </Col>
              </Row>
              <Row className='table'>
                <div>
                  <InternationalDecimalInput
                    name='potency_thc'
                    fractionPartSize={3}
                    props={{
                      label: I18n.t('ei.assemblies.form.thc'),
                      disabled: hasRuns,
                    }}
                  />
                </div>
                <div>
                  <InternationalDecimalInput
                    name='potency_thca'
                    fractionPartSize={3}
                    props={{
                      label: I18n.t('ei.assemblies.form.thca'),
                      disabled: hasRuns,
                    }}
                  />
                </div>
                <div>
                  <InternationalDecimalInput
                    name='potency_thcv'
                    fractionPartSize={3}
                    props={{
                      label: I18n.t('ei.assemblies.form.thcv'),
                      disabled: hasRuns,
                    }}
                  />
                </div>
                <div>
                  <InternationalDecimalInput
                    name='potency_cbd'
                    fractionPartSize={3}
                    props={{
                      label: I18n.t('ei.assemblies.form.cbd'),
                      disabled: hasRuns,
                    }}
                  />
                </div>
                <div>
                  <InternationalDecimalInput
                    name='potency_cbdv'
                    fractionPartSize={3}
                    props={{
                      label: I18n.t('ei.assemblies.form.cbdv'),
                      disabled: hasRuns,
                    }}
                  />
                </div>
                <div>
                  <InternationalDecimalInput
                    name='potency_cbg'
                    fractionPartSize={3}
                    props={{
                      label: I18n.t('ei.assemblies.form.cbg'),
                      disabled: hasRuns,
                    }}
                  />
                </div>
                <div>
                  <InternationalDecimalInput
                    name='potency_cbc'
                    fractionPartSize={3}
                    props={{
                      label: I18n.t('ei.assemblies.form.cbc'),
                      disabled: hasRuns,
                    }}
                  />
                </div>
                <div>
                  <InternationalDecimalInput
                    name='potency_cbn'
                    fractionPartSize={3}
                    props={{
                      label: I18n.t('ei.assemblies.form.cbn'),
                      disabled: hasRuns,
                    }}
                  />
                </div>
                <div>
                  <InternationalDecimalInput
                    name='potency_total_cb_profile'
                    fractionPartSize={0}
                    props={{
                      label: I18n.t('ei.assemblies.form.totalCannabinoidProfile'),
                      disabled: hasRuns,
                    }}
                  />
                </div>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h3>{I18n.t('ei.assemblies.form.ingredients')}</h3>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FieldArray
                name='ingredients'
                component={IngredientsFieldArray}
                ingredients={ingredients}
                change={change}
                getFormValue={getFormValue}
                getPossibleUom={getPossibleUom}
                addMessage={addMessage}
                integrationState={integrationState}
              />
            </Col>
          </Row>
          <Row className='total-cost'>
            <Col sm={{span: 4, offset: 7}} md={{span: 3, offset: 8}} className={'float-right'}>
              <InternationalCurrencyInput
                name='total_cost'
                props={{
                  fractionPartSize: 2,
                  label: I18n.t('ei.assemblies.form.totalCost'),
                  disabled: true,
                  value: totalCost
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <h3>{I18n.t('ei.assemblies.form.directions')}</h3>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <FieldArray
                name='directions'
                component={DirectionsRow}
              />
            </Col>
          </Row>
      <SubmitSection settings={submitSectionSettings}/>
    </form>
  );
};

AssemblyForm.propTypes = {
  products: PropTypes.array.isRequired,
  ingredients: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  getFormValue: PropTypes.func.isRequired,
  getPossibleUom: PropTypes.func.isRequired,
  addMessage: PropTypes.func.isRequired,
  totalCost: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  integrationState: PropTypes.object.isRequired,
  uomDisplay: PropTypes.string,
};

const selector = formValueSelector(ASSEMBLY_FORM);

const mapStateToProps = state => ({
  uomDisplay: selector(state, 'uom_display'),
});

export default connect(mapStateToProps)(AssemblyForm);
