import moment from 'moment-timezone';
import get from 'lodash.get';
import {set} from 'lodash';

class Draft {
  constructor(data) {
    this.data = data;
  }

  getProperty(property) {
    return get(this.data['values'] || {}, property);
  }

  setProperty(property, value) {
    if (!this.getData()) {
      this.data['values'] = {};
    }
    set(this.data['values'], property, value);
    return this;
  }

  setDefault(defaults) {
    if (!this.data['defaults']) {
      this.data['defaults'] = {};
    }
    this.data['defaults'] = defaults;
  }

  isDirty(defaults) {
    if (!defaults) {
      defaults = this.getDefault() || {};
    }
    return JSON.stringify(defaults) !== JSON.stringify(this.getData());
  }

  initDate(property) {
    const date = this.getProperty(property);
    if (date) {
      this.setProperty(property, moment(date));
    }
    return this;
  }

  initDates(properties) {
    const _this = this;
    if(properties.length > 1) {
      properties.forEach(property => {
        _this.initDate(property);
      });
    }
    return _this;
  }

  compare(data) {
    const current = this.getData();
    return JSON.stringify(current) === JSON.stringify(data);
  }

  getData() {
    return this.data['values'] || null;
  }

  getDefault() {
    return this.data['defaults'] || null;
  }
}

class TransferDraft extends Draft {
  constructor(data) {
    super(data);

    // Due to serialization we lose class specific data
    // such as `moment` objects that the form is expecting.
    // in order to correct this we have to unserialize the data,
    // dates are required to be converted to `moment` objects
    this.dateFields = [
      'date_transferred',
      'sales_orders.0.date_expected',
      'sales_orders.0.date_ordered',
      'destinations.0.arrival_time',
      'destinations.0.departure_time'
    ];

    this.init();
  }

  init() {
    this.initDates(this.dateFields);
  }
}

// Defining static properties inside a class violates the linting rules we have
// likely cause it's a narrowly supported feature in chrome. As a result, I have to
// define these as module globals the static class will reference
const namespace = 'drafts';
const classMap = {
  transfer: TransferDraft
};

export default class DraftManager {
  static getClass(type) {
    return get(classMap, type) || Draft;
  }

  static getDraftCollection(type) {
    const drafts = this.getDrafts();
    return drafts[type] || {};
  }

  static getDraft(type, key) {
    const collection = this.getDraftCollection(type);
    const data = collection[key];

    const className = this.getClass(type);
    let draft = data ? new className(data) : null;

    // if the data key is empty, the draft is messed up and we
    // should wipe it out and return empty
    if (draft && !draft.getData()) {
      this.clearDraft(type, key);
      draft = null;
    }

    return draft;
  }

  static clearDraft(type, key) {
    const drafts = this.getDrafts();
    const collection = drafts[type] || {};
    delete collection[key];
    drafts[type] = collection;
    localStorage.setItem(namespace, JSON.stringify(drafts));
  }

  static getDrafts() {
    let drafts = null;
    try {
      drafts = JSON.parse(localStorage.getItem(namespace)) || {};
    } catch (err) {
      drafts = {};
      localStorage.setItem(namespace, JSON.stringify(drafts));
    }
    return drafts;
  }

  static setDraft(type, key, draft) {
    this.setDraftData(type, key, draft.getData(), draft.getDefault());
  }

  static setDraftData(type, key, data, defaults) {
    const drafts = this.getDrafts();
    const collection = drafts[type] || {};
    const draftData = collection[key] || {};
    draftData['values'] = data;
    draftData['defaults'] = defaults || {};
    collection[key] = draftData;
    drafts[type] = collection;
    localStorage.setItem(namespace, JSON.stringify(drafts));
  }
}
