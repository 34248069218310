import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {Container, Row} from 'react-bootstrap';

import * as systemActions from '../actions/systemActions';
import {getUserValid} from '../actions/userActions';
import * as apiActions from '../actions/apiActions';
import {isFeatureEnabled} from '../selectors/featureToggles';
import MessageBlock from './common/MessageBlock';
import Header from './header/Header';
import Spinner from './common/Spinner';
import InProgressOverlay from './common/InProgressOverlay';
import PriorityNotificationModal from './notifications/PriorityNotificationModal'; // eslint-disable-line import/no-named-as-default
import TimeoutModalContainer from './system/TimeoutModalContainer'; // eslint-disable-line import/no-named-as-default
import ErrorBoundry from './common/ErrorBoundry';
import WillRender from './common/concealers/WillRender';
import IntercomWidget from './widgets/IntercomWidget';
import DelightedWidget from './widgets/DelightedWidget';
import NavigationPane from './navigation/NavigationPane';


class Authorized extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
  }

  render () {
    const {
      spinner, messages, intercomEnabled, facility, facilitySwitch, menus: {mainNavMenuOpen}
    } = this.props;
    const active = spinner > 0;

    return (
      <div className='full-height'>
        <InProgressOverlay isActive={facilitySwitch.inProgress} message={facilitySwitch.message} />
        <TimeoutModalContainer/>
        <PriorityNotificationModal/>
        <WillRender ifTrue={intercomEnabled}>
          <IntercomWidget/>
        </WillRender>
        <WillRender ifTrue={Object.keys(facility).length > 0}>
          <DelightedWidget/>
        </WillRender>
        <Row className='header-offset'>
          <Header>
            {this.props.children}
          </Header>
        </Row>

        <Container id='app-container'>
          <ErrorBoundry>
            <MessageBlock className='container' messages={messages} dismiss={this.props.actions.clearMessage} timeout={3000}/>
            <div id='left-side-content' className={mainNavMenuOpen ? '' : 'left-side-content-hidden'}>
              <NavigationPane id='left-side-navigation' />
            </div>
            <div id='right-side-content'>
              <Container>
                {this.props.children}
              </Container>
            </div>
          </ErrorBoundry>
        </Container>

        <Spinner active={active}/>
      </div>
    );
  }
}


Authorized.propTypes = {
  actions: PropTypes.object.isRequired,
  spinner: PropTypes.number.isRequired,
  messages: PropTypes.array.isRequired,
  environment: PropTypes.string.isRequired,
  routerPath: PropTypes.string,
  user: PropTypes.object.isRequired,
  facility: PropTypes.object.isRequired,
  children: PropTypes.element,
  intercomEnabled: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  spinner: state.spinner,
  messages: state.messages,
  environment: state.system.environment,
  version: state.system.initialVersion,
  user: state.user,
  menus: state.menus,
  facility: state.facility,
  intercomEnabled: isFeatureEnabled(state)('feature_intercom_widget'),
  facilitySwitch: state.facilitySwitch
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({...systemActions, ...apiActions, getUserValid}, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (Authorized);
