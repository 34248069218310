import React from 'react';
import {Button, Col, Row, FormControl, FormGroup} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import {goBack} from 'react-router-redux';
import Select from 'react-select';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {AuthRequest} from '../../../managers/request';
import Loading from '../../common/loading';
import Notice from '../../common/notice';

class MerchantTerminalForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initializing: true,
      terminal: props.terminal,
      submitting: false,
      invalid: false,
      profiles: [],
      error: '',
      validations: {},
      failed: false,
      failures: []
    };

    this.request = AuthRequest.create();
  }

  componentDidMount() {
    this.searchProfiles().then((profiles) => {
      this.setState({profiles: profiles});
    }).catch(error => {
      this.setState({failed:true, failures: ['Failed to load profiles: ' + error.message]});
    }).then(() => {
      this.setState({initializing: false});
    });
  }

  componentWillUnmount() {}

  searchProfiles(options) {
    const defaults = {
      per_page: 0
    };
    const params = Object.assign({}, defaults, options);

    this.setState({
      initializing: true
    });

    return this.request.get('/api/payment/merchant_profiles/search', {
      params: params
    }).then(AuthRequest.getBody);
  }

  validate() {
    const { terminal } = this.state;
    const errors = {};

    if(!terminal.merchant_profile_id)
      errors.merchant_profile_id = I18n.t('common.form.isRequired', {fieldName: I18n.t('facility.setup.merchantTerminal.form.profile')});

    if(!terminal.name)
      errors.name =  I18n.t('common.form.isRequired', {fieldName: I18n.t('common.form.name')});

    if(!terminal.reference)
      errors.reference =  I18n.t('common.form.isRequired', {fieldName: I18n.t('facility.setup.merchantTerminal.form.reference')});

    const valid = Object.keys(errors).length ? false : true;
    this.setState({validations: errors, invalid: !valid});
    return valid;
  }

  onMerchantProfileChange(profile) {
    const { terminal } = this.state;
    terminal.merchant_profile_id = profile ? profile.id : null;
    this.validate();
  }

  onInputChange(event) {
    const { terminal } = this.state;
    const field = event.target.name;
    const value = event.target.value;
    terminal[field] = value;
    this.validate();
  }

  submit(e) {
    e.preventDefault();
    const {terminal} = this.state;
    const {goBack} = this.props;

    if(!this.validate()) {
      return;
    }
    this.setState({submitting: true});

    let request = null;
    if (terminal.id) {
      request = this.request.put(`/api/payment/merchant_terminals/${terminal.id}`, terminal);
    } else {
      request = this.request.post('/api/payment/merchant_terminals', terminal);
    }

    request.then(goBack)
      .catch(response => {
        this.setState({error: response.message, validations: response.errors});
      }).then(() => {
        this.setState({submitting: false});
      });
  }

  getForm() {
    const {submitting, invalid, terminal, error, validations, profiles} = this.state;
    console.log(terminal);
    return (
      <form onSubmit={this.submit.bind(this)}>
        {error &&
          <Notice type='danger' title={error} validations={validations} />
        }

        <Row>
          <Col sm={3}>
            <FormGroup validationState={validations.merchant_profile_id ? 'error' : null}>
              <Form.Label>{I18n.t('facility.setup.merchantTerminal.form.profile')}</Form.Label>
              <Select
                name='merchant_profile_id'
                options={profiles}
                labelKey='name'
                valueKey='id'
                value={terminal.merchant_profile_id}
                onChange={this.onMerchantProfileChange.bind(this)}
              />
              {validations.merchant_profile_id &&
                <Form.Text className='text-muted'>{validations.merchant_profile_id}</Form.Text>
              }
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <FormGroup validationState={validations.name ? 'error' : null}>
              <Form.Label>{I18n.t('common.form.name')}</Form.Label>
              <FormControl
                name='name'
                value={terminal.name}
                onChange={this.onInputChange.bind(this)}
              />
              {validations.name &&
                <Form.Text className='text-muted'>{validations.name}</Form.Text>
              }
            </FormGroup>

          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <FormGroup validationState={validations.reference ? 'error' : null}>
                <Form.Label>{I18n.t('facility.setup.merchantTerminal.form.reference')}</Form.Label>
                <FormControl
                  name='reference'
                  value={terminal.reference}
                  onChange={this.onInputChange.bind(this)}
                />
                {validations.reference &&
                  <Form.Text className='text-muted'>{validations.reference}</Form.Text>
                }
              </FormGroup>
          </Col>
        </Row>

        <Button type='submit' className='btn btn-primary' disabled={submitting || invalid}>
          {submitting ?
            <Loading>{I18n.t('common.form.saving')}</Loading> :
            I18n.t('common.form.save')
          }
        </Button>
      </form>
    );
  }

  render() {
    const {initializing, failed, failures} = this.state;

    let Content;

    if (initializing) {
      Content = <Loading>{I18n.t('general.loading')}</Loading>;
    } else if (failed) {
      Content = (<Notice type='danger' title={I18n.t('general.pageError')} messages={failures}>
        <Button onClick={() => {window.location.reload();}}>{I18n.t('common.actions.refresh')}</Button>
      </Notice>);
    } else {
      Content = this.getForm();
    }

    return (
      <React.Fragment>
        <h1>{I18n.t('facility.setup.merchantTerminal.header')}</h1>
        {Content}
      </React.Fragment>
    );
  }
}

MerchantTerminalForm.propTypes = {
  terminal: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = ({
  goBack
});

export default connect(mapStateToProps, mapDispatchToProps)(MerchantTerminalForm);