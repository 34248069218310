import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack} from 'react-router-redux';
import {reset} from 'redux-form';
import sortby from 'lodash.sortby';
import uniqby from 'lodash.uniqby';
import get from 'lodash.get';

import {
  getModifyPricingGroupInitialValues,
  getCategoriesForPricingGroups,
} from '../../../../selectors/pricingGroupsSelectors';
import {getPricingWeights} from '../../../../selectors/pricingWeightsSelectors';
import getPayloadForPricingGroups from '../common/getPayloadForPricingGroups';
import {unsetItem, unsetItemAction} from '../../../../actions/itemActions';
import * as apiActions from '../../../../actions/apiActions';
import * as dataNames from '../../../../constants/dataNames';
import * as itemNames from '../../../../constants/itemNames';
import FormWrapper from '../../../common/form/FormWrapper';
import ModifyPricingGroupFormWrapper from './ModifyPricingGroupFormWrapper';
import {getUomByCategoryId} from '../../../../selectors/uomsSelectors';
import {getIntegrationState} from '../../../../selectors/integration/integrationSelectors';

export class ModifyPricingGroupPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      pricingClasses: []
    };
    this.onCategoryChange = this.onCategoryChange.bind(this);
    this.onPricingWeightAdded = this.onPricingWeightAdded.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.redirect = this.redirect.bind(this);
    this.resetForm = this.resetForm.bind(this);
  }

  componentWillMount() {
    const {params: {id}} = this.props;
    this.props.actions.getItem(`/api/pricing_groups/${id}`, itemNames.pricingGroup, {failed: 'retail.pricingGroup.get.failed'});
    this.props.actions.getUnpaginatedData('/api/pricing_weights', dataNames.pricingWeights, {failed: 'pricingWeights.get.failed'});
    this.props.actions.getUnpaginatedData('/api/categories', dataNames.categories, {failed: 'categories.get.failed'});
    this.props.actions.getUnpaginatedData('/api/pricing_classes', null, {failed: 'retail.pricingClasses.get.failed'}, null, (data) => {
      // Sort and filter
      const sorted = sortby(data, (value) => value.name.toLowerCase()).filter(pricing_class => pricing_class.active === 1);
      this.setState({pricingClasses: sorted});
    });
    this.props.actions.getUnpaginatedData('/api/taxes', dataNames.taxes, {failed: 'taxes.get.failed'}, {detailed: 1});
    this.props.actions.getUnpaginatedData('/api/tax_profiles', dataNames.taxProfiles, {failed: 'taxes.get.failed'}, {
      detailed: 1,
      active: 1
    });
    this.props.actions.getUnpaginatedData('/api/uoms/converted', dataNames.uoms);
  }

  componentWillUnmount() {
    this.props.actions.unsetItem(itemNames.pricingGroup);
  }

  onSubmit(formValues) {
    const payload = getPayloadForPricingGroups(formValues, this.props.initialValues);
    const {params: {id}} = this.props;
    this.props.actions.putItem(
      `/api/pricing_groups/${id}`,
      payload,
      itemNames.pricingGroup,
      {success: 'retail.pricingGroup.modify.success', failed: 'retail.pricingGroup.create.failed'},
      {},
      () => {
        this.resetForm();
        this.redirect();
      }
    );
  }

  onPricingWeightAdded(pricingWeight, callback) {
    return this.props.actions.postData(
      '/api/pricing_weights',
      pricingWeight,
      dataNames.pricingWeights,
      {success: 'cultivation.pricingWeights.post.success', failed: 'cultivation.pricingWeights.post.failed'},
      {},
      callback
    );
  }

  redirect() {
    this.props.actions.goBack();
  }

  resetForm() {
    this.props.actions.reset('modifyPricingGroupForm');
  }

  onCategoryChange(event, id) {
    const {taxProfiles} = this.props;

    if (taxProfiles.length === 0) {
      return;
    }

    if (!id) {
      this.setState({taxProfiles: taxProfiles});
      return;
    }

    const data = [];

    taxProfiles.forEach(taxProfile => {
      taxProfile.taxes.forEach(taxMaster => {
        taxMaster.tax.categories.forEach(category => {
          if (category.item_category_id === id) {
            data.push(taxProfile);
          }
        });
      });
    });

    this.setState({
      taxProfiles: uniqby(data, 'tax_profile_name')
    });
  }

  render() {
    const {categories, pricingWeights, initialValues, taxProfiles, uoms, integrationState} = this.props;

    if (initialValues && initialValues.category_id && !this.state.taxProfiles) {
      this.onCategoryChange(null, initialValues.category_id);
    }

    const {pricingClasses} = this.state;
    const filteredTaxProfiles = this.state.taxProfiles ? this.state.taxProfiles : taxProfiles;

    return (
      <FormWrapper className='coupon-setup' title='retail.pricingGroup.modify.title' goBack={this.redirect}>
        {initialValues && <ModifyPricingGroupFormWrapper
          uoms={uoms}
          onSubmit={this.onSubmit}
          onCategoryChange={this.onCategoryChange}
          integrationState={integrationState}
          onPricingWeightAdded={this.onPricingWeightAdded}
          pricingWeights={pricingWeights}
          categories={categories}
          taxProfiles={filteredTaxProfiles}
          pricingClasses={pricingClasses}
          initialValues={initialValues}/>}
      </FormWrapper>
    );
  }
}

ModifyPricingGroupPage.propTypes = {
  actions: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    reset: PropTypes.func,
    getUnpaginatedData: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    putItem: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired,
  }).isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  uoms: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  taxProfiles: PropTypes.array.isRequired,
  pricingClasses: PropTypes.array.isRequired,
  pricingWeights: PropTypes.array.isRequired,
  initialValues: PropTypes.object,
};

function mapStateToProps(state) {
  const initialValues = getModifyPricingGroupInitialValues(state);
  const category_id = get(initialValues, 'category_id');

  return {
    uoms: getUomByCategoryId(state, {category_id}),
    categories: getCategoriesForPricingGroups(state),
    taxProfiles: state[dataNames.taxProfiles],
    initialValues,
    pricingClasses: state[dataNames.pricingClasses],
    pricingWeights: getPricingWeights(state),
    integrationState: getIntegrationState(state)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {goBack, reset, unsetItem, unsetItemAction});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModifyPricingGroupPage);
