import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import {Table, Col} from 'react-bootstrap';
import InternationalCurrencyInput from '../../../common/form/InternationalCurrencyInput';
import TextInput from '../../../common/form/TextInput';
import InternationalQuantityByUomInput from '../../../common/form/InternationalQuantityByUomInput';
import * as UOMS from '../../../../constants/uoms';
import {convertFromBase} from '../../../../util/uomHelpers';
import InternationalDecimalStatic from '../../../common/form/InternationalDecimalStatic';
import InternationalCurrencyStatic from '../../../common/form/InternationalCurrencyStatic';

const PrePackSubItems = (props) => {

  const {fields, subItems, showIdColumn, isActive, lineItem, metrcIsImported, uom} = props;

  return (
    <Col lg={8} offset={2}>
      <Table condensed>
        <thead>
        <tr>
          <th style={{width: '25%'}}>{`${I18n.t(`uoms.${uom || UOMS.GR}.word`)}s`}</th>
          {showIdColumn ? <th>{I18n.t('purchaseOrders.form.id')}</th> : null}
          <th style={{width: '25%'}}>{I18n.t('purchaseOrders.form.qty')}</th>
          <th style={{width: '25%'}}>{I18n.t('purchaseOrders.form.total')}</th>
          <th style={{width: '25%'}}>{I18n.t('purchaseOrders.form.unitCost')}</th>
        </tr>
        </thead>
        {
          !lineItem.showEditors && lineItem.showEditors !== undefined
            ? (<tbody>
            {fields.map((subItemName, index) => {
              const subItem = subItems[index];
              const total = subItem && subItem.itemWeight ? convertFromBase(subItem.itemWeight.weight_base, subItem.itemWeight.uom) * (subItem.qty || 0) : 0;
              return (
                <tr key={index}>
                  <td>{subItem && subItem.displayName ? subItem.displayName : ''}</td>
                  {showIdColumn ?
                    <td><Field name={`${subItemName}.id`} component={TextInput} props={{readOnly:true}}/></td>
                    : null}
                  <td>
                    <InternationalDecimalStatic fractionPartSize={2}>{parseFloat(subItem.qty)}</InternationalDecimalStatic>
                  </td>
                  <td>
                    <InternationalCurrencyStatic>{total}</InternationalCurrencyStatic>
                  </td>
                  <td>
                    <InternationalCurrencyStatic>{subItem.unit_price}</InternationalCurrencyStatic>>
                  </td>
                </tr>
              );
            })}
            </tbody>)
            : (<tbody>
            {fields.map((subItemName, index) => {
              const subItem = subItems[index];
              return (
                <tr key={index}>
                  <td>{subItem && subItem.displayName ? subItem.displayName : ''}</td>
                  {showIdColumn ?
                    <td><Field name={`${subItemName}.id`} component={TextInput} props={{readOnly:true}}/></td>
                    : null}
                  <td>
                    <InternationalQuantityByUomInput
                      name={`${subItemName}.qty`}
                      allowNegativeNumber={false}
                      disabled={metrcIsImported || !isActive}
                      hideUom={true}
                      uom={UOMS.EA}
                    />
                  </td>
                  <td>
                    <InternationalQuantityByUomInput
                      name={`${subItemName}.total`}
                      readOnly={true}
                      hideUom={true}
                    />
                  </td>
                  <td>
                    <InternationalCurrencyInput
                      name={`${subItemName}.unit_price`}
                      props={{
                        readOnly: true
                      }}
                    />
                  </td>
                </tr>
              );
            })}
            </tbody>)
        }
      </Table>
    </Col>
  );
};

PrePackSubItems.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
  }).isRequired,
  subItems: PropTypes.array.isRequired,
  showIdColumn: PropTypes.bool,
  isActive: PropTypes.bool,
  change: PropTypes.func,
  lineItem: PropTypes.object.isRequired,
  metrcIsImported: PropTypes.bool,
  uom: PropTypes.string.isRequired,
};

PrePackSubItems.defaultProps = {
  showIdColumn: true,
  isActive: true
};

export default PrePackSubItems;
