import {createSelector, createStructuredSelector} from 'reselect';
import {formValueSelector} from 'redux-form';
import {PHYSICIAN_FORM} from '../../constants/forms';
import {getOrderedClinics} from '../clinicsSelectors';
import {getCountries, getProvinces} from '../countrySelectors';
import {getPhysician} from '../physiciansSelectors';
import {getIntegrationState} from '../integration/integrationSelectors';
import {DEA_NUMBER} from '../../constants/physicianLicenseTypes';

const formSelector = formValueSelector(PHYSICIAN_FORM);
const getAddresses = state => formSelector(state, 'addresses') || [];
const getLicenses = state => formSelector(state, 'licenses') || [];
const getPrimaryAddressIndex = createSelector(
  [getAddresses],
    addresses => addresses.findIndex(address => address.active === '1' && address.primary === '1')
);
const getActiveLicenseIndex = createSelector(
  [getLicenses],
  licenses => licenses.findIndex(license => license.active === '1')
);
const getPhysicianId = (_, props) => props && props.params && Number(props.params.id);

const getCreatePhysicianInitialValues = createSelector(
  [getIntegrationState],
  ({isOhMetrc}) => ({
    active: '1',
    addresses: [
      {
        active: '1',
        primary: '1',
      },
    ],
    licenses: [
      {
        active: '1',
        ...(isOhMetrc && {license_type: DEA_NUMBER})
      },
    ],
  })
);

const getModifyPhysicianInitialValues = createSelector(
  [getPhysician, getPhysicianId],
  (physician, physicianId) => {
    if (physician.id !== physicianId) {
      return {};
    }
    return {
      ...physician,
      active: String(physician.active),
      addresses: (physician.physician_addresses || []).map(address => ({
        ...address,
        active: String(address.active),
        primary: String(address.primary),
      })),
      licenses: (physician.physician_licenses || []).map(license => ({
        ...license,
        active: String(license.active),
      })),
    };
  }
);

export const getPhysicianPayload = (formValues) => {
  return {
    ...formValues,
    active: Number(formValues.active),
    addresses: (formValues.addresses || []).map(address => ({
      ...address,
      active: Number(address.active),
      primary: Number(address.primary),
    })),
    licenses: (formValues.licenses || []).map(license => ({
      ...license,
      active: Number(license.active),
    })),
  };
};

export const getCreatePhysicianPageProps = createStructuredSelector({
  initialValues: getCreatePhysicianInitialValues,
  clinics: getOrderedClinics,
  countries: getCountries,
  provinces: getProvinces,
  primaryAddressIndex: getPrimaryAddressIndex,
  activeLicenseIndex: getActiveLicenseIndex,
});

export const getModifyPhysicianPageProps = createStructuredSelector({
  initialValues: getModifyPhysicianInitialValues,
  clinics: getOrderedClinics,
  countries: getCountries,
  provinces: getProvinces,
  primaryAddressIndex: getPrimaryAddressIndex,
  activeLicenseIndex: getActiveLicenseIndex,
});
