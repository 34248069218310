import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Table} from 'react-bootstrap';
import ContentConcealer from '../../../common/concealers/ContentConcealer';
import HoverTooltip from '../../../common/HoverTooltip';
import InternationalCurrencyInput from '../../../common/form/InternationalCurrencyInput';

const WeightTable = ({fields, fieldCanModifiedMap, isMassModifyForm, keyForMap, disablePriceBlock}) => (
  <ContentConcealer show={Boolean(fields.length)}>
    <div className='table-wrapper'>
      <Table condensed striped bordered hover>
        <thead>
        <tr>
          <th className='size'>{I18n.t('products.form.unit')}</th>
          <th>{I18n.t('products.form.defaultPrice')}</th>
        </tr>
        </thead>
        <tbody>
        {fields.map((unit, index) => {
          const field = fields.get(index);
          return (
            <tr key={index}>
              <td className='name'>{field.name}</td>
              <td>
                <InternationalCurrencyInput
                  name={`${unit}.default_price`}
                  props={{
                    placeholder: '0.00',
                    fractionPartSize: 2,
                    step: 'any',
                    allowNegativeNumber: false,
                    disabled: disablePriceBlock,
                    rightAddon: isMassModifyForm && fieldCanModifiedMap[`${keyForMap}.default_price`].length ?
                      <HoverTooltip text={I18n.t('products.massModify.errors.diff_categories_help', {field: I18n.t('products.form.defaultPrice'), categories: fieldCanModifiedMap[`${keyForMap}.default_price`].join(' ,')})} id={`${unit}_tooltip`}/> : null

                  }}
                />
              </td>
            </tr>
          );
        })}
        </tbody>
      </Table>
    </div>
  </ContentConcealer>
);


WeightTable.propTypes = {
  fields: PropTypes.object.isRequired,
  keyForMap: PropTypes.string,
  fieldCanModifiedMap: PropTypes.object,
  isMassModifyForm: PropTypes.bool,
  disablePriceBlock: PropTypes.bool
};

export default WeightTable;
