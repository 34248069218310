import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Button} from 'react-bootstrap';
import {FaTrash} from 'react-icons/fa';
import InternationalQuantityByUomInput from '../../../common/form/InternationalQuantityByUomInput';
import InternationalCurrencyInput from '../../../common/form/InternationalCurrencyInput';
import {getPossibleUomsOptions} from '../../../../selectors/uomsSelectors';


const IngredientBulkLineItem = (props) => {
  const {
    line, change, isActive, lineItem, index, removeFromPo, transferRecord,
    uoms
  } = props;

  const default_uom = lineItem.itemMaster.default_uom;
  return (
    <Col lg={8}>
      <Col lg={3}>
        <InternationalQuantityByUomInput
          name={`${line}.qty`}
          disabled={!isActive}
          label={I18n.t('purchaseOrders.form.requestedAmount')}
          isRequired={true}
          uom={lineItem.uom}
          props={{
            options: getPossibleUomsOptions(default_uom, uoms),
            selectable: true,
            whiteBackground: true,
            selectableName: `${line}.uom`,
          }}
        />
      </Col>
      <Col lg={3} />
      <Col lg={3}>
        <InternationalCurrencyInput
          name={`${line}.line_item_price`}
          props={{
            disabled: lineItem && lineItem.editableLinePrice !== undefined ? !lineItem.editableLinePrice : true,
            label: I18n.t('purchaseOrders.form.itemPrice'),
            rightAddon: transferRecord
              ? null
              : (
                <span>
                  <Button
                    style={{cursor: 'pointer'}}
                    onClick={(event) => {
                      const newEditableValue = lineItem.editableLinePrice !== undefined ? !lineItem.editableLinePrice : true;
                      change(`${line}.editableLinePrice`, newEditableValue);
                    }}>
                    {lineItem && lineItem.editableLinePrice !== undefined && lineItem.editableLinePrice ? 'Calc' : 'Edit'}
                  </Button>
                </span>
              )
          }}
        />
      </Col>
      <Col lg={1}>
        <div className='form-input form-group'>
          <label className='control-label' style={{width: '100%'}}>&nbsp;</label>
          <button disabled={transferRecord} className='btn btn-small btn-block'
                  onClick={(event) => removeFromPo(index)}>
            <FaTrash style={{marginRight: '4px'}} />
          </button>
        </div>
      </Col>
    </Col>);
};

IngredientBulkLineItem.propTypes = {
  //itemMaster : PropTypes.object.isRequired,
  line: PropTypes.string.isRequired,
  uom: PropTypes.string,
  change: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  lineItem: PropTypes.object.isRequired,
  removeFromPo: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  transferRecord: PropTypes.bool.isRequired,
  uoms: PropTypes.array.isRequired
};

IngredientBulkLineItem.defaultProps = {
  isActive: true
};

export default IngredientBulkLineItem;
