import React from 'react';
import {push} from 'react-router-redux';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';

import {Tab, Tabs} from 'react-bootstrap';

import getSalesReportTabs from './common/tabDefinitions';
import {getEnabledTabs} from '../../../actions/reportActions';

/**
 *
 */
class SalesReportPageTabs extends React.Component {
  constructor(props) {
    super(props);
    const {getEnabledTabs} = this.props;

    this.tabs = getEnabledTabs(getSalesReportTabs);
    this.switchTab = this.switchTab.bind(this);
  }

  switchTab(activeTab) {
    const {push} = this.props;
    const tab = this.tabs.find(tab => tab.eventKey === activeTab) || this.tabs[0];
    push(tab.path);
  }

  render() {
    const {activeKey} = this.props;

    return (
      <Tabs id='filterTabs' activeKey={activeKey} onSelect={this.switchTab}>
        {this.tabs.map((tab, index) =>
          <Tab key={index} eventKey={tab.eventKey} title={I18n.t(tab.title)} />
        )}
      </Tabs>
    );
  }
}

const mapDispatchToProps = {
  getEnabledTabs,
  push
};

export default connect(null, mapDispatchToProps)(SalesReportPageTabs);
