import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {submit} from 'redux-form';
import {goBack} from 'react-router-redux';
import {postData} from '../../../actions/apiActions';
import {DESTROY_PACKAGES_FORM} from '../../../constants/forms';
import {getDestroyPackagesPageProps, getDestroyPackagesPayload} from '../../../selectors/forms/destroyPackagesFormSelectors';
import FormWrapper from '../../common/form/FormWrapper';
import InProgressOverlay from '../../common/InProgressOverlay';
import ModalWrapper from '../../common/ModalWrapper';
import DestroyPackagesFormWrapper from './DestroyPackagesFormWrapper';
import {handleErrors} from './helpers';

class DestroyPackagesPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showConfirmModal: false,
      saving: false,
    };

    this.onShowConfirmModal = this.onShowConfirmModal.bind(this);
    this.onHideConfirmModal = this.onHideConfirmModal.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onShowConfirmModal() {
    this.setState({showConfirmModal: true});
  }

  onHideConfirmModal() {
    this.setState({showConfirmModal: false});
  }

  onConfirm() {
    this.onHideConfirmModal();
    this.props.actions.submit();
  }

  onSubmit(formData) {
    const {timezone} = this.props;
    this.setState({saving: true});
    return this.props.actions
      .postData(
        '/api/items/multi_actions',
        getDestroyPackagesPayload(formData, timezone),
        null,
        {success: 'packages.destroy.success', failed: 'packages.destroy.failed', failedHandler: handleErrors}
      )
      .then(() => {
        this.setState({saving: false}, () => this.props.actions.goBack());
      })
      .catch(() => this.setState({saving: false}));
  }

  render() {
    const {showConfirmModal, saving} = this.state;
    const {actions: {goBack}, biotrackDestructionReasons, initialValues, useEntityLocks} = this.props;

    return (
      <FormWrapper goBack={goBack} title='packages.destroy.title'>
        <InProgressOverlay isActive={saving} message='common.form.saving' translate={true}/>

        <ModalWrapper
          Component={false}
          title='packages.destroy.confirmDestruction'
          headerClass='bg-info-dark'
          onHide={this.onHideConfirmModal}
          showModal={showConfirmModal}
          okayButton={{show: true, onClick: this.onConfirm, text: 'packages.destroy.destroy'}}
          cancelButton={{show: true, onClick: this.onHideConfirmModal, text: 'packages.destroy.cancel'}}
          dialogClassName='modal-sm'
          version={2}
        >
          <div>{I18n.t('packages.destroy.confirmationMessage')}</div>
        </ModalWrapper>

        <DestroyPackagesFormWrapper
          biotrackDestructionReasons={biotrackDestructionReasons}
          goBack={goBack}
          initialValues={initialValues}
          onShowConfirmModal={this.onShowConfirmModal}
          onSubmit={this.onSubmit}
          useEntityLocks={useEntityLocks}
        />

      </FormWrapper>
    );
  }
}

DestroyPackagesPage.propTypes = {
  actions: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
  }).isRequired,
  biotrackDestructionReasons: PropTypes.array.isRequired,
  initialValues: PropTypes.object,
  timezone: PropTypes.string,
};

function mapDispatchToProps(dispatch) {
  const submitForm = () => dispatch => dispatch(submit(DESTROY_PACKAGES_FORM));

  const actions = {goBack, postData, submit: submitForm};
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(getDestroyPackagesPageProps, mapDispatchToProps)(DestroyPackagesPage);
