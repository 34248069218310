import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import keyBy from 'lodash.keyby';
import union from 'lodash.union';
import isEqual from 'lodash.isequal';
import {I18n} from 'react-redux-i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {goBack, push, replace} from 'react-router-redux';
import {change, formValueSelector, reset} from 'redux-form';
import { batchActions } from 'redux-batched-actions';
import moment from 'moment-timezone';
import {setSelectedData, clearSelectedData} from '../../actions/selectedDataActions';
import * as cartActions from '../../actions/cartActions';
import * as apiActions from '../../actions/apiActions';
import {addMessage} from '../../actions/systemActions';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import {unsetData, setData, unionData} from '../../actions/dataActions';
import {setItem, unsetItem} from '../../actions/itemActions';
import {closeCustDetails} from '../../actions/menuActions';
import {getApplicationMode} from '../../selectors/applicationModesSelectors';
import {getPricingWeightByFulfillmentUnits} from '../../selectors/pricingWeightsSelectors';
import {getFlatTopLocations} from '../../selectors/locationsSelectors';
import {
  getInventoryItem,
  getProductFulfillmentStatusById,
  getOrderProductItems,
  getFilteredItems,
  couponIsAppliedToAllProducts,
  getOrderProductCoupons,
  getOrderWithRichProducts,
  isOrderFulfilled,
  getDetailedOrderCoupons,
  getRewardPointsOnOrder, getOrderMmapPayments
} from '../../selectors/ordersSelectors';
import {getNonMedicatedSubcategoryIds} from '../../selectors/subcategoriesSelectors';
import {getIntegrationState} from '../../selectors/integration/integrationSelectors';
import {getItemMasterType} from '../../selectors/itemMastersSelectors';
import {
  getSalesLimitError,
  getInventoryAvailableError,
  getOrderEnableWeighOnHand,
  getOrderWeighOnHandMarginError,
  hasMetrcSalesLimits,
} from '../../selectors/salesSettingsSelectors';
import {isReadyStatus} from '../../selectors/orderSelectors';
import Cart from './Cart'; //eslint-disable-line import/no-named-as-default
import AssignDriver from './AssignDriver';
import ProductsHeader from '../header/ProductsHeader';
import InProgressOverlay from '../common/InProgressOverlay';
import {PACKAGE_CODE_REG_EXP} from '../common/form/validationRegExs';
import {getFormOptionsBySubcategory, isValidLimits} from '../../selectors/integration/cureApiSelectors';
import {hasPermApplyManualDiscounts} from '../../selectors/permissionsSelectors';
import CancelOrder from '../orders/order/cancel/CancelOrder'; // eslint-disable-line import/no-named-as-default
import * as messageTypes from '../../constants/messageTypes';
import {getMedicatedEndProducts} from '../../selectors/integration/biotrackCategoriesSelectors';
import {getIsRefillNumberRequired, ohMetrcSubcategoriesForSale} from '../../selectors/integration/metrcSelectors';
import showNegativeAlert from '../common/negative-inventory/showNegativeAlert';
import NegativeInventoryAlert from '../common/negative-inventory/NegativeInventoryAlert';
import {doNothing} from '../../util/callbackHelpers';
import {getBlockExpiredProductAction, isAllowNegativeInventory} from '../../selectors/complianceSettingsSelectors';
import {getTestResults} from '../../selectors/testResultsSelectors';
import {getConstants} from '../../selectors/constantsSelectors';
import * as statuses from '../../constants/statuses';
import {getProductionRunPackageCodes} from '../../selectors/productionRunsSelectors';
import {convert} from '../../util/uomHelpers';
import * as UOMS from '../../constants/uoms';
import {uomTypes, VOLUME, WEIGHT} from '../../constants/uomTypes';
import {isCouponCode} from './helpers';
import {isLeafPaConfigPackClosedLoopFacility} from '../../selectors/facilitiesSelectors';
import {isFeatureEnabled} from '../../selectors/featureToggles';
import LabelPrinter from '../print-services/labels/LabelPrinter';

const {GR} = UOMS;
const formName = 'checkoutPage';
const inventoryLevelMessage = 'The inventory selected is no longer available';

export class CartPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.state = {
      scanField: '',
      assignDriverOpen: false,
      showLoader: false,
      message: '',
      statusMessage: I18n.t('cart.statusMessage.default'), // DEP
      fromScan: false, // DEP
      showOk: false,
      showSpinner: true,
      messageDeferred: false,
      couponUpdateRequired: true,
      rewardUpdateRequired: true,
      disableCouponApplyButton: true,
      makePayment: false,
      complianceWeightInGrams: 0, // set on fail
      loadedCouponsAndRewards: false,
      scanMode: true,
      cancelOrderEvent: 'noop',
      cancelOrderCustomerId: 0,
      cancelOrderOrderId: 0,
      cancelOrderEventListeners: {},
      loadedItemMasterIds: [],
      loadedInventoryItemIds: [],
      loadedLabResultItemIds: [],
      scanQueue: [],
      itemMasterRequestOut: false,
      itemRequestOut: false,
      processing: false,
      showNegativeInvConfirmation: {
        show: false,
        onHide: null,
        onConfirm: null,
      },
      lineItemCouponScanned: null,
      showPrinter: false,
      printAll: 0,
      showModal: false,
      payload: {},
    };

    this.openAssignDriver = this.openAssignDriver.bind(this);
    this.closeAssignDriver = this.closeAssignDriver.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.fillOrder = this.fillOrder.bind(this);
    this.editOrder = this.editOrder.bind(this);
    this.changeFulfillmentStatus = this.changeFulfillmentStatus.bind(this);
    this.cancelOrder = this.cancelOrder.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.removeProduct = this.removeProduct.bind(this);
    this.handleScanChange = this.handleScanChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleItemsChanged = this.handleItemsChanged.bind(this);
    this.removeCoupon = this.removeCoupon.bind(this);
    this.removeMmapPayment = this.removeMmapPayment.bind(this);
    this.hideLoader = this.hideLoader.bind(this);
    this.showLoader = this.showLoader.bind(this);
    this.onDismissLoader = this.onDismissLoader.bind(this);
    this.updateRequestedAmount = this.updateRequestedAmount.bind(this);
    this.handleSalesLimitError = this.handleSalesLimitError.bind(this);
    this.handleWarningMinLimits = this.handleWarningMinLimits.bind(this);
    this.handleInventoryError = this.handleInventoryError.bind(this);
    this.handleAddProductToOrder = this.handleAddProductToOrder.bind(this);
    this.changeMakePayment = this.changeMakePayment.bind(this);
    this.performBatchActions = this.performBatchActions.bind(this);
    this.updateCureInfo = this.updateCureInfo.bind(this);
    this.deleteCureInfo = this.deleteCureInfo.bind(this);
    this.postCureInfoForScannedItem = this.postCureInfoForScannedItem.bind(this);
    this.getInventoryItemsByItemMasterIds = this.getInventoryItemsByItemMasterIds.bind(this);
    this.getLabResultsForInventoryItemIds = this.getLabResultsForInventoryItemIds.bind(this);
    this.addScannedItem = this.addScannedItem.bind(this);
    this.updateRefillNumber = this.updateRefillNumber.bind(this);
    this.setNegativeConfirmationState = this.setNegativeConfirmationState.bind(this);
    this.goToPage = this.goToPage.bind(this);
    this.toggleScanMode = this.toggleScanMode.bind(this);
    this.focusBarcode = this.focusBarcode.bind(this);
    this.completeLineItemCouponScan = this.completeLineItemCouponScan.bind(this);
    this.printAllLabels = this.printAllLabels.bind(this);
    this.onHideModal = this.onHideModal.bind(this);
    this.scanInputRef = React.createRef();
  }

  componentWillMount() {
    const {customer, order, isBiotrack, isOhMetrc} = this.props;
    if(!order.id) {
      this.props.actions.addMessage(messageTypes.warning, 'cart.statusMessage.noCurrentOrder');
      this.props.actions.replace('/');
      return false;
    }
    if(!customer.order_id) customer.order_id = order.id;
    this.props.actions.batchActions([
      clearSelectedData(dataNames.itemMasters),
      clearSelectedData(dataNames.childItemMasters),
      unsetData(dataNames.itemMasters),
      unsetData(dataNames.childItemMasters),
      unsetData(dataNames.inventoryItems),
      unsetItem(itemNames.complianceSettings),
      unsetItem(itemNames.order),
    ]);
    this.props.actions.getUnpaginatedData('/api/pricing_weights', dataNames.pricingWeights,
      undefined, undefined, undefined, {debounce: true});
    this.props.actions.getUnpaginatedData('/api/prepack_weights', dataNames.prepackWeights,
      undefined, undefined, undefined, {debounce: true});
    this.props.actions.getUnpaginatedData('/api/taxes', dataNames.taxes, {failed: 'taxes.get.failed'},
      undefined, undefined, {debounce: true});
    this.props.actions.getUnpaginatedData('/api/location_hierarchy', dataNames.locations,
      undefined, undefined, undefined, {debounce: true});
    this.props.actions.getUnpaginatedData(
      `/api/consumer_groups`,
      dataNames.customerGroups,
      {failed: I18n.t('customers.getGroups.failed')},
      undefined, undefined, {debounce: true}
    );

    this.props.actions.getItem(`/api/orders/details/${customer.order_id}`, itemNames.order, {failed: 'orders.get.fail'},
      undefined, undefined,  {debounce: true});
    this.props.actions.getItem('/api/compliance_settings', itemNames.complianceSettings,
      undefined, undefined, undefined, {debounce: true});
    this.handleWarningMinLimits();
    const promises = [
      this.props.actions.getUnpaginatedData('/api/categories', dataNames.categories),
      this.props.actions.getUnpaginatedData(
        '/api/subcategories',
        dataNames.subcategories,
        {failed: 'categories.get.failed'},
        undefined,
        undefined,
        {debounce: true}
      ),
      this.props.actions.getUnpaginatedData(
        '/api/production_runs',
        dataNames.productionRuns,
        {failed: 'productionRuns.get.failed'},
        {detailed: 1, status: statuses.open}
      )

    ];
    if (isBiotrack) {
      promises.push(
        this.props.actions.getUnpaginatedData('/api/biotrack/categories', dataNames.biotrackCategories),
        this.props.actions.getUnpaginatedData('/api/biotrack/categories/mapping', dataNames.biotrackCategoryMappings)
      );
    }
    if (isOhMetrc) {
      promises.push(
        this.props.actions.getUnpaginatedData('/api/ohmetrc/item_categories', dataNames.ohMetrcCategories),
        this.props.actions.getUnpaginatedData('/api/ohmetrc/item_categories/mapping', dataNames.ohMetrcCategoryMappings)
      );
    }
    Promise.all(promises);
  }

  componentDidMount(){
    this.focusBarcode();
  }

  componentWillReceiveProps(nextProps) {
    if(!isEqual(this.props.order, nextProps.order)){
      if(nextProps.order.products.length && !isEqual(this.props.order.products, nextProps.order.products)){
        if(!nextProps.itemMasters || !nextProps.itemMasters.length ){
          this.getItemMastersForOrder(nextProps.order);
        }else{
          const lines_missing_masters = nextProps.order.products.filter(product => !nextProps.itemMasters.find(im => im.id === product.item_master_id));
          if(lines_missing_masters.length){
            this.getItemMastersForOrder(nextProps.order);
          }else{
            const itemMasters = (nextProps.itemMasters && Array.isArray(nextProps.itemMasters) ? nextProps.itemMasters.map(im => im.id) : []);
            this.getInventoryItemsByItemMasterIds(itemMasters, 'componentWillReceiveProps');
          }
        }
        this.setState({couponUpdateRequired: true});
        this.handleFulfillmentExceedsRequest(nextProps.order);
      }else{
        if(!nextProps.itemMasters || !nextProps.itemMasters.length ){
          this.getItemMastersForOrder(nextProps.order);
        }
      }
    }

  }

  componentWillUnmount() {
    this.props.actions.batchActions([
      clearSelectedData(dataNames.itemMasters),
      clearSelectedData(dataNames.childItemMasters),
      unsetData(dataNames.inventoryItems),
      unsetData(dataNames.coupons),
      unsetData(dataNames.taxes),
      closeCustDetails()
    ]);
  }

  printAllLabels(event = null, withAutoPrint = false) {
    this.preventDefault(event);
    this.doPrint(undefined, undefined, withAutoPrint);
  }

  preventDefault(event){
    if(!event) return true;
    event.stopPropagation();
    event.preventDefault();
    event.target.blur();
    return true;
  }

  doPrint(orderProductId, itemId, withAutoPrint = false){
    const newState = Object.assign({}, {
      payload: Object.assign({}, {
        ids: [this.props.order.id],
        is_order: 1
      },
      orderProductId ? {order_product_id: orderProductId} : {},
      itemId ? {inventory_item_id: itemId} : {})
    }, {showModal: true, autoPrint: false});
    this.setState(newState);
  }

  onHideModal(){
    this.setState({
      showModal: false,
    });
  }

  getInventoryItemsByItemMasterIds(itemMasterIds, source){
    const unloadedItemMasterIds = (itemMasterIds ? itemMasterIds : []).filter((id) => this.state.loadedItemMasterIds.indexOf(id) === -1);
    const {isBiotrack, isLeaf} = this.props;
    const having_lab_results_only = isLeaf || isBiotrack;
    let chain = Promise.resolve();
    if(unloadedItemMasterIds.length) {
      chain = chain.then(() => {
        return this.props.actions.getDataByPost('/api/items/by_item_master_ids',
          { ids: unloadedItemMasterIds, having_lab_results_only},
          null,
          {failed: 'orders.get.itemFail'},
          {on_hold: 0}
        );
      }).then((inventoryItems) => {
        this.props.actions.unionData(inventoryItems, dataNames.inventoryItems);
        this.setState({
          loadedItemMasterIds: union(inventoryItems.map( i => i.item_master_id ), this.state.loadedItemMasterIds),
          loadedInventoryItemIds: union(inventoryItems.map( i => i.id ), this.state.loadedInventoryItemIds),
        });
        return this.getLabResultsForInventoryItemIds(inventoryItems.map((item) => item.id));
      });
    }
    // after loading any new inventory items, get availability levels for all inventory items
    chain = chain.then(() => {
      const itemIds = this.state.loadedInventoryItemIds;
      if(!itemIds) return Promise.resolve();
      // to give the reservation queues time to update, wait 2 seconds before requesting availability
      setTimeout( () => {
        this.props.actions.getDataByPost('/api/item_availability/multiple',
          {ids: itemIds, with_details_for_order_id: get(this.props, 'order.id')},
          dataNames.itemsAvailability,
          null
        );
      },2000);
      return Promise.resolve();
    });
    return chain;
  }

  getLabResultsForInventoryItemIds(ids){
    const {isCure, isOhMetrc, isIsolocity} = this.props.integrationState;
    if(!isCure && !isOhMetrc && !isIsolocity) {
      return Promise.resolve();
    }
    const unloadedLabResultItemIds = ids.filter((id) => this.state.loadedLabResultItemIds.indexOf(id) === -1);
    if(unloadedLabResultItemIds.length){
      this.setState({loadedLabResultItemIds: this.state.loadedLabResultItemIds.concat(unloadedLabResultItemIds)}, () => {
        const payload = {ids: unloadedLabResultItemIds};
        const messages = {failed: 'cultivation.transfers.checkLabResults.failed'};
        return this.props.actions.postItem('/api/lab_results/by_item_id/multiple', payload, null, messages)
          .then(data => {
            const itemsWithTestResults = Array.isArray(data) ? data : Object.values(data);
            this.props.actions.unionData(itemsWithTestResults.filter((item) => item.lab_result), dataNames.testResults, 'item_id');
          });
      });
    }
  }

  updateRequestedAmount(orderProductId, payload) {
    return this.props.actions.putItem(
      `/api/orders/${this.props.order.id}/products/${orderProductId}`,
      payload,
      itemNames.order,
      {failed: 'orders.products.create.failed', failedHandler: this.handleSalesLimitError},
      {},
      () => {
        this.clearSalesLimitError();
      }
    );

  }

  removeCoupon(id) {
    const {order} = this.props;
    this.props.actions.deleteItem(`/api/orders/${order.id}/coupons/${id}`,
      null, //delete removes the item, so I'm removing itemNames.order from here
      {failed: 'orders.delete.couponFailed'},
      undefined,
      (order) => {
        this.props.actions.setItem(order, itemNames.order);
      }
    );
  }

  removeMmapPayment(id) {
    const {order} = this.props;
    this.props.actions.deleteItem(`/api/orders/${order.id}/mmap_payments/${id}`,
      null, //delete removes the item, so I'm removing itemNames.order from here
      {failed: 'orders.delete.mmapPaymentFailed'},
      undefined,
      (order) => {
        this.props.actions.setItem(order, itemNames.order);
      }
    );
  }

  getItemMastersForOrder(order){
    const selectedItemMasters = this.props.selectedItemMasters;
    const selectedBulkAndUnitItemMasterIds = selectedItemMasters && selectedItemMasters.length
      ? selectedItemMasters.reduce(
        (ids, itemMaster) => getItemMasterType({itemMaster}) === 'prepack' ? ids : ids.concat(itemMaster.id)
        , [])
      : [];

    const getIdsForPrepacksAndBulkAndUnitIds = (masters) => {

      const prepackIds = [], bulkAndUnitIds = [], imageIds = [];

      masters.forEach(master => {
        if (master && master.inventory_attributes && master.inventory_attributes.is_prepack && !master.item_master_parent_id) {
          prepackIds.push(master.id);
        }
        else {
          bulkAndUnitIds.push(master.id);
        }
        if (master.primary_product_image_file_id) {
          imageIds.push(master.primary_product_image_file_id);
        }
      });

      if (prepackIds.length) {
        if(!this.state.itemRequestOut){
          this.setState({ itemRequestOut: true });
          this.props.actions.getUnpaginatedData('/api/item_masters/children/items',
            dataNames.childItemMasters,
            {failed: 'orders.get.itemFail'},
            {ids: prepackIds},
            childItemMasters => {
              this.setState({ itemRequestOut: false });
              const inventoryItemIds = childItemMasters.reduce((acc, itemMaster) => {
                if(!itemMaster.items || !Array.isArray(itemMaster.items) || !itemMaster.items.length) return acc;
                return acc.concat(itemMaster.items.map((item) => item.id));
              }, []);
              this.getLabResultsForInventoryItemIds(inventoryItemIds);
              this.props.actions.setSelectedData(childItemMasters, dataNames.childItemMasters);
            }
          );
        }
      }

      if (bulkAndUnitIds.length) {
        this.getInventoryItemsByItemMasterIds(union(bulkAndUnitIds, selectedBulkAndUnitItemMasterIds), 'getItemMastersForOrder');
      }

    };

    const newItemMasterIds = order.products
      ? order.products.map(product => product.item_master_id)
      : [];

    if (newItemMasterIds.length) {
      // NOTE: keep original params[ids] for reference in case we need to revert (Original search is SOLR search query params))
      // const ids = newItemMasterIds.reduce((a,b) => {
      //   return `${a} ${b}`;
      // }, '');

      // NOTE: keep original params for reference in case we need to revert (Original search is SOLR search query params))
      // const params = {sort: 'name asc, display_name asc', query: 'matchall', size: '100000', start: '0', filter: `(id: (${ids}) OR item_master_parent_id:(${ids})) AND is_draft:0 AND is_ingredient:0`};
      const params = {
        // select columns are for service search
        select_columns: [
          'id',
          'name',
          'item_number',
          'description',
          'display_name',
          'category_id',
          'uom_type',
          'default_uom',
          'lot_tracked',
          'is_prepack',
          'item_master_parent_id',
          'prepack_weight_id',
          'is_inventory_item',
          'is_sales_item',
          'active',
          'is_draft',
          'subcategory_code',
          'subcategory_display_name',
          'category_display_name',
          'medicated_weight_base',
          'medicated_weight_uom_display',
          'is_medicated'
        ],
        // filters
        // solr query ids
        // id: ids,
        in_ids: newItemMasterIds,
        // solr query item_master_parent_ids
        // item_master_parent_id: ids,
        // TODO: remove this when we migrate to new product master
        or_in_parent_ids: newItemMasterIds,
        active: 1,
        is_draft: 0,
        is_ingredient: 0,

        // additional data
        include_images: 1,

        // pagination
        page: 1,
        per_page: 100000,
      };

      if(!this.state.itemMasterRequestOut){

        this.setState({ itemMasterRequestOut: true });
        // NOTE: keep original get search data for reference in case we need to revert (Original search is SOLR search request)))
        // this.props.actions.getSearchData('/api/search/item_masters', dataNames.itemMasters, null, params)
        this.props.actions.getPaginatedData('/api/item_masters/search', dataNames.itemMasters, null, params)
          .then((itemMasters) => {
            const mappedItemMasters = itemMasters.map((itemMaster) => {
              return Object.assign({}, itemMaster, {item_master_id: itemMaster.id}, {
                inventory_attributes: {
                  lot_tracked: itemMaster.lot_tracked,
                  is_ingredient: itemMaster.is_ingredient,
                  is_prepack: itemMaster.is_prepack,
                  item_master_parent_id: itemMaster.item_master_parent_id,
                  prepack_weight_id: itemMaster.prepack_weight_id
                }
              });
            });

            getIdsForPrepacksAndBulkAndUnitIds(mappedItemMasters);
            this.props.actions.setData(mappedItemMasters, dataNames.itemMasters);
            this.props.actions.setSelectedData(mappedItemMasters, dataNames.itemMasters);
            this.setState({ itemMasterRequestOut: false });
          });
      }

    }

  }

  handleAddProductToOrder(errors) {
    const { customer, hasMetrcSalesLimits } = this.props;
    this.hideLoader();
    const gateResponse = get(errors, 'response.data.errors.VALIDATION',[]);
    const gateErrors = gateResponse && gateResponse['order_check_order.items'];
    if (gateErrors && gateErrors.length) {
      gateErrors.forEach(err => this.props.actions.addMessage(messageTypes.error, err, 'en'));
    }
    const errorString = get(errors, 'response.data.errors.ORDER_ERROR', false);
    const errorMessage = get(errors, 'response.data.errors.MESSAGE', false);
    const errorValidationMessage = get(errors, 'response.data.errors.VALIDATION.ORDER_ERROR', false);

    if(errorMessage || (errorValidationMessage[0] && errorValidationMessage[0] !== 'sales_restriction_weight_exceeded') ) {
      return {
        message: errorMessage || errorValidationMessage[0],
        translate: false,
      };
    }

    const showIntegrationLimitError = get(customer, 'id') && hasMetrcSalesLimits;
    const verboseErrorString = !showIntegrationLimitError && (get(errors, 'response.data.errors.verbose_error', false) ||
      get(errors, 'response.data.errors.VALIDATION.verbose_error', false));
    const limitMethod = get(errors, 'response.data.errors.limit_method.0','');
    const maxLimits = get(errors, 'response.data.errors.VALIDATION.max_limits.0', false);
    const minLimits = get(errors, 'response.data.warnings.min_limits.0', false);
    const isLabResultError = (limitMethod === 'lab_results_based');
    if(!isLabResultError && (errorString || verboseErrorString || minLimits || maxLimits)){
      return {
        message: verboseErrorString || minLimits || errorString || maxLimits,
        translate: false,
      };
    }
    const refillNumberError = get(errors, 'response.data.errors.VALIDATION.refill_number.0');
    if (refillNumberError) {
      return {
        message: refillNumberError
      };
    }
    return this.handleSalesLimitError(errors) || this.handleInventoryError(errors);
  }

  handleInventoryError(errors){
    const response = get(errors, 'response.data', []);
    if(response.errors !== undefined){
      if(response.errors.order_id !== undefined) {
        return {
          message: response.errors.order_id[0]
        };
      }else if(response.errors.item_master_id !== undefined) {
        return {
          message: response.errors.item_master_id[0]
        };
      }
    }
    return false;
  }

  handleSalesLimitError(errors){
    this.hideLoader();
    const result = getSalesLimitError(errors) || getInventoryAvailableError(errors);
    if(result){
      this.setState({complianceWeightInGrams: result.current_order_weight});
    } else {
      this.setState({complianceWeightInGrams: 0});
    }
    return result;
  }

  clearSalesLimitError(){
    this.setState({complianceWeightInGrams: 0});
  }

  handleWarningMinLimits(){
    const warning_min_limit = get(this.props, 'order.limit_check_response.warnings.min_limits');
    if (warning_min_limit) {
      this.props.actions.addMessage(messageTypes.warning, warning_min_limit, 'en');
    }
  }

  handleFulfillmentExceedsRequest(order) {

    const {pricingWeights, prepackWeights, selectedItemMasters} = this.props;

    if(!order.products || order.products === undefined) return false;
    if(order.products.length === 0) return false;

    order.products.forEach(product => {
      const fulfillmentStatus = getProductFulfillmentStatusById({order, pricingWeights, prepackWeights}, product);
      const itemMaster = selectedItemMasters.find(itemMaster => itemMaster.id === product.item_master_id);
      const itemMasterType = itemMaster && getItemMasterType({itemMaster});
      // Do not increase the requested amount for bulk items.
      if (
        (fulfillmentStatus.isFulfilled || fulfillmentStatus.needLimitValidation) &&
        fulfillmentStatus.amountFulfilled > fulfillmentStatus.amountRequested &&
        itemMasterType && itemMasterType !== 'bulk'
      ) {
        const payload = {
          item_master_id: product.item_master_id,
        };
        if (itemMasterType !== 'unit') {
          payload.sold_weight = fulfillmentStatus.amountFulfilled;
          payload.sold_weight_uom = product.sold_weight_uom;
        } else {
          payload.quantity = fulfillmentStatus.amountFulfilled;
        }
        this.props.actions.putItem(
          `/api/orders/${this.props.order.id}/products/${product.id}`,
          payload,
          itemNames.order,
          {failed: 'orders.products.create.failed', failedHandler: this.handleSalesLimitError},
          undefined,
          () => {
            this.hideLoader();
            return this.clearSalesLimitError();
          }
        );
      }
    });

  }

  handleItemsChanged(items, order_product_id) {
    return this.props.actions.putItem(`/api/orders/${this.props.order.id}/products/${order_product_id}/inventory`,
      {items},
      itemNames.order,
      {failedHandler: this.handleAddProductToOrder},
      undefined,
      () => {
        this.hideLoader();
        this.handleWarningMinLimits();
        return this.clearSalesLimitError();
      }
    );
  }

  /*
    If negative inventory is allowed, then we do first attempt with forced inventory-level validation.
    Then if we get error response and the error is about inventory-level - we show confirmation modal "Should we go negative"?
    If user chooses to go negative, we make same call without inventory-level validation.
    It is wrapped in Promise since cart submit works as a chain of promises.
   */
  performBatchActions(payload) {
    return new Promise((resolve, reject) => {

      const makeCallToBackend = (force_negative_inventory_validation) => {
        const payloadWithFlags = payload.map(payloadItem => ({...payloadItem, force_negative_inventory_validation}));
        const handlers = force_negative_inventory_validation
          ? {failedHandler: this.handleSalesLimitError}
          : {failedHandler: this.handleAddProductToOrder};
        return this.props.actions
          .putItem(
            `/api/orders/${this.props.order.id}/products`,
            payloadWithFlags,
            itemNames.order,
            handlers
          );
      };

      const final = (cb) => (value) => {
        this.hideLoader();
        this.handleWarningMinLimits();
        this.clearSalesLimitError();
        cb(value);
      };

      const forceNegativeValidation = this.props.negativeInventoryAllowed ? 1 : undefined;
      makeCallToBackend(forceNegativeValidation)
        .then(final(resolve))
        .catch(err => {
          if (forceNegativeValidation) {
            const productLevelErrorText = get(err, 'response.data.errors.VALIDATION.item_master_id.0', '');
            const isProductLevelError = productLevelErrorText.match && !!productLevelErrorText.match(/Quantity exceeds available inventory/);
            const itemLevelErrorText = get(err, 'response.data.errors.VALIDATION.order_id.0', '');
            const isItemLevelError = itemLevelErrorText.match && !!itemLevelErrorText.match(/inventory selected is no longer available/);
            if (isProductLevelError || isItemLevelError) {
              showNegativeAlert(true, this.setNegativeConfirmationState)
                .then(makeCallToBackend)
                .then(final(resolve))
                .catch(final(reject));
            } else {
              final(reject)(err);
            }
          } else {
            final(reject)(err);
          }
        });
    });
  }

  updateCureInfo(data) {
    const {isCure} = this.props;
    const {oldProduct, product} = data;
    const oldFormId = (oldProduct.cure_order_info && oldProduct.cure_order_info.form_id) ? oldProduct.cure_order_info.form_id : false;
    if (isCure
      && oldProduct
      && product.cure_order_info
      && product.cure_order_info.form_id
      && product.cure_order_info.id
      && oldFormId !== product.cure_order_info.form_id
      && product.quantity > 0
    ) {
      const curePayload = {
        form_id: product.cure_order_info.form_id,
        order_id: product.cure_order_info.order_id,
        order_product_id: product.cure_order_info.order_product_id
      };
      return () => this.props.actions.putData(`/api/cureapi/order_products/${product.cure_order_info.id}`, curePayload,
        dataNames.cureOrderProducts,
        {failed: 'cureOrderProducts.save.failed'}
      );
    }
    return () => new Promise(resolve => resolve());
  }

  deleteCureInfo(id) {
    const {isCure} = this.props;
    if (isCure) {
      return () => this.props.actions.deleteData(`/api/cureapi/order_products`, id,
        dataNames.cureOrderProducts
      );
    }
    return () => new Promise(resolve => resolve());
  }

  updateRefillNumber(refill_number) {
    const {isOhMetrc, order} = this.props;
    if (isOhMetrc) {
      const id = get(order, 'id', null);
      return () => this.props.actions.putData(`/api/orders/${id}`, {refill_number});
    }
    return () => new Promise(resolve => resolve());
  }

  changeFulfillmentStatus(fulfillment_status) {
    const {order} = this.props;
    const {putItem} = this.props.actions;
    if (order.id) {
      this.setState({processing: true});
      putItem(
        `/api/orders/${order.id}`,
        {fulfillment_status},
        null,
        {failed: 'orders.products.remove.failed', failedHandler: getSalesLimitError},
        {},
        () => {
          this.props.actions.getItem(`/api/orders/details/${order.id}`, itemNames.order, {failed: 'orders.get.fail'},
            undefined, undefined,  {debounce: true})
            .then(() => this.setState({processing: false}))
            .catch(() => this.setState({processing: false}));
        }
      ).catch(() => this.setState({processing: false}));
    }
  }

  setNegativeConfirmationState(state) {
    this.setState({
      showNegativeInvConfirmation: state
    });
  }

  onSubmit(formValues) {
    this.showLoader('updatingOrder');
    const {order: {products}, inventoryItems, selectedItemMasters, order, isCure, isRefillNumberRequired, orderEnableWeighOnHand, orderWeighOnHandMarginError, isMmuEnabled, actions: {getItem}} = this.props;
    const promises = [];
    if(this.state.makePayment){
      promises.push(() => new Promise(resolve => resolve(this.props.actions.push(`/orders/${order.id}/payment`))));
    }
    promises.push(() => this.hideLoader());
    const batchPayload = [];
    formValues.products.forEach((product, index) => {

      const oldProduct = products.find(p => p.id === product.id);
      const itemMaster = selectedItemMasters.find(itemMaster => itemMaster.id === product.item_master_id);
      let itemType;
      if (itemMaster && itemMaster.inventory_attributes && itemMaster.inventory_attributes.is_prepack && !itemMaster.item_master_parent_id) {
        itemType = 'prepack';
      }else {
        if (itemMaster && [WEIGHT, VOLUME].includes(itemMaster.uom_type)) {
          itemType = 'bulk';
        }
        else if (itemMaster && itemMaster.uom_type === 'discrete') {
          itemType = 'unit';
        }
      }
      //find changed product quantities,
      const oldAmt = parseFloat(itemType === 'unit' ? oldProduct.quantity : oldProduct.sold_weight);
      const newAmt = parseFloat(itemType === 'unit' ? product.quantity : product.sold_weight);

      if(oldProduct && oldAmt !== newAmt) {
        if(newAmt > 0){
          const fieldName = itemType === 'unit' ? 'quantity' : 'sold_weight';
          const payload = {
            item_master_id: product.item_master_id,
            [fieldName]: product[fieldName]
          };
          batchPayload.push({action: 'update_product', payload, product_id: product.id});
          // promises.push(() => this.updateRequestedAmount(product.id, payload));
        }else{
          //remove product and maybe also items
          batchPayload.push({action: 'delete_product', payload: {}, product_id: product.id});
          if (isCure && product.cure_order_info && product.cure_order_info.form_id) {
            promises.push(this.deleteCureInfo(product.cure_order_info.id));
          }
        }
      }
      promises.push(this.updateCureInfo({product, oldProduct}));

      //find changed inventory quantities
      let items = [];
      let itemsQuantity = 0;
      if(product.items){
        items = product.items.map(item => {
          const oldItem = oldProduct.items.find(i => i.id === item.id);
          let payload = null;

          if (oldItem && item.qty > 0) {
            payload = {
              id: oldItem.id,
              qty: item.qty,
              item_id: oldItem.item_id,
              item_master_id: oldItem.item_master_id,
              uom: oldItem.uom_display
            };
          }

          return payload;
        });

        product.items.forEach((item, index) => {
          itemsQuantity += parseFloat(item.qty);
        });
      }

      //add new inventory to the order
      if(product.addItem){

        const {item_id, qty} = product.addItem;

        if(item_id && qty) {

          if (product.id && product.item_master_id) {
            let inventoryItem = {};
            const inventoryItemsKeys = Object.keys(inventoryItems);
            inventoryItemsKeys.forEach((inventoryItemKey) => {
              inventoryItems[inventoryItemKey].forEach((item) => {
                if(item.id === item_id){
                  inventoryItem = item;
                }
              });
            });

            if (inventoryItem) {
              const item = {
                qty: itemType === 'bulk' ? parseFloat(qty) : parseInt(qty),
                item_id: item_id,
                item_master_id: inventoryItem.item_master_id,
                uom: inventoryItem.uom
              };
              const fieldName = `products[${index}].addItem`;

              change(`${fieldName}.prepack_weight_id`, '');
              change(`${fieldName}.item_id`, '');
              change(`${fieldName}.qty`, '');
              change(`${fieldName}.qty_available`, '');
              items.push(item);
            }
          }

          itemsQuantity += parseFloat(qty);
        }
      }

      // Check enable weigh on hand setting
      const currentUom = get(itemMaster, 'default_uom', UOMS.GR);
      const isPrepack = get(itemMaster, 'is_prepack', false);

      if (!isPrepack) {
        const isWeightBased = uomTypes[currentUom].type === WEIGHT;

        if (orderEnableWeighOnHand && isWeightBased && currentUom !== UOMS.EA) {
          const qtyOverageInGrams = convert((itemsQuantity - newAmt), currentUom, GR, -1);
          if (qtyOverageInGrams > orderWeighOnHandMarginError) {
            this.props.actions.addMessage(messageTypes.error, 'retail.salesSettings.errors.weigh_on_hand_margin_error');
            return false;
          }
        } else {
          if (itemsQuantity > newAmt) {
            this.props.actions.addMessage(messageTypes.error, 'retail.salesSettings.errors.overweight_error');
            return false;
          }
        }
      }

      batchPayload.push({
        action: 'update_product_inventory',
        payload: {items: items.filter(Boolean)},
        product_id: product.id,
        partial_fill_indicator: get(product, 'partial_fill_indicator', null),
        days_supply: get(product, 'days_supply', null),
        consumer_id: get(order, 'consumer_id', null)
      });
    });
    // if an MMAP payment is added that covers the entire balance it will complete the order, so we do not need to update the products
    // Batch actions (i.e. fulfill can be executed for online orders that are locked (i.e. patient hasn't checked in yet))
    if (batchPayload.length && (!this.props.order.locked || (this.props.order.locked && get(order, 'order_source') === 'online'))) {
      promises.push(() => this.performBatchActions(batchPayload));
    }
    if(this.props.isOhMetrc && isRefillNumberRequired){
      promises.push(this.updateRefillNumber(formValues.refill_number));
    }

    return this.processPromises(promises)
      .then(() => {
        // If Leaf PA and MMU enabled, update customer limits
        if (isMmuEnabled) {
          getItem(`/api/consumer_orders/${order.consumer_id}/limits`, itemNames.customerLimits);
        }
      });
  }

  processPromises(promises) {
    return new Promise ((resolve, reject) => {
      const func = promises.pop();
      if(func){
        return func().then(() => this.processPromises(promises))
          .then(() => resolve())
          .catch(() => reject(() => {
            this.props.actions.reset(formName)
              .then(() => {})
              .catch(() => {});
          }));
      }else{
        return resolve();
      }
    });
  }

  fillOrder() {
    this.closeAssignDriver();
    const customer = this.props.customer;
    customer.order_status = 'filled';
    this.props.actions.unsetItem(itemNames.customer);
    this.props.actions.push('/queue/delivery');
  }

  cancelOrder() {
    const that = this;
    this.setState({
      cancelOrderEvent: 'cancel',
      cancelOrderCustomerId: this.props.customer.id,
      cancelOrderOrderId: this.props.order.id,
      cancelOrderEventListeners: {
        cancel: {
          onBeforeCancel(option, value){
            that.showLoader('cancellingOrder');
          },
          onError(e){
            that.hideLoader();
          },
          onAfterCancel(option, value, data){
            if(that.props.isCure) {
              that.props.actions.deleteData(`/api/cureapi/order_products/by_order_id`, that.props.order.id, dataNames.cureOrderProducts);
            }
            that.props.actions.push('/queue/in_store');
          }
        }
      }
    });
  }

  editOrder() {
    this.props.actions.push(`/product-menu/${this.props.customer.order_id || this.props.order.id}`);
  }

  removeProduct(product) {
    this.showLoader('removingProduct');
    this.clearSalesLimitError();
    const {actions: {getItem, deleteItem}, isCure, isMmuEnabled} = this.props;
    if (this.props.order.id && product && product.id) {
      return deleteItem(
        `/api/orders/${this.props.order.id}/products/${product.id}`,
        null, //delete removes the item, so I'm removing itemNames.order from here
        {failed: 'orders.delete.productFail'},
        undefined,
        (order) => {
          if (isCure && product.cure_order_info && product.cure_order_info.id) {
            this.props.actions.deleteData(`/api/cureapi/order_products`, product.cure_order_info.id,
              dataNames.cureOrderProducts
            );
          }
          this.props.actions.setItem(order, itemNames.order);
          this.hideLoader();
        }
      )
        .then((order) => {
          if (isMmuEnabled) {
            getItem(`/api/consumer_orders/${order.consumer_id}/limits`, itemNames.customerLimits);
          }
        });
    }
  }

  removeItem(item) {

    this.showLoader('removingItem');

    const {getOrderProductItems} = this.props;
    if (this.props.order.id && item.order_product_id) {
      const productItems = getOrderProductItems({id: item.order_product_id});
      const items = productItems
        .map(productItem => ({
          id: productItem.id,
          qty: productItem.id === item.id ? null : productItem.qty,
          item_id: productItem.item_id,
          item_master_id: productItem.item_master_id,
          uom: productItem.uom_display
        }));

      this.props.actions.putItem(`/api/orders/${this.props.order.id}/products/${item.order_product_id}/inventory`,
        {items},
        itemNames.order,
        {failed: 'orders.delete.itemFail'},
        undefined,
        this.hideLoader
      );

    }
  }

  handleScanChange(event) {
    const {target: {value}} = event;
    if (PACKAGE_CODE_REG_EXP.test(value.trim()) || (typeof value === 'string' && value.trim() === '')) {
      this.setState({scanField: value});
    } else {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  postCureInfoForScannedItem(order) {
    const {formProducts, isCure, subcategoryFormOptions} = this.props;
    if (isCure) {
      const addItem = order.products.find(product => !formProducts.find(formProduct => formProduct.id === product.id));
      if (addItem) {
        const formOptions = subcategoryFormOptions[addItem.subcategory_id] || [];
        const payload = {
          order_id: order.id,
          order_product_id: addItem.id,
          form_id: formOptions.length ? formOptions[0].value : null
        };
        this.props.actions.postData(`/api/cureapi/order_products/`, payload,
          dataNames.cureOrderProducts,
          {failed: 'cureOrderProducts.save.failed'}
        );
      }
    }
  }

  handleKeyPress(event){
    if(event && event.key !== 'Enter') return false;
    if(event.preventDefault) event.preventDefault();

    const code = get(this.state, 'scanField', '');
    // If code is not string or empty string abort
    if(typeof code !== 'string' || code.trim() === ''){
      this.setState({showLoader: true, showOk: true, showSpinner: false, message: I18n.t('cart.loadingMessage.invalid')});
      return true;
    }

    const {productionRunPackageCodes, actions: {addMessage}} = this.props;

    if (productionRunPackageCodes.includes(code)) {
      return addMessage(messageTypes.warning,['cart.getScannedItem.notFound', {packageId: code}]);
    }

    setTimeout(() => { // Yield execution so quick scans have a chance to queue in state
      // Queue the code and then scan if possible
      this.setState({scanQueue: this.state.scanQueue.concat(code)}, () => {
        this.addScannedItem();
      });
    }, 100);
  }

  isCouponActive(coupon) {
    return coupon.date_available_to === null || moment().isSameOrBefore(
      moment.tz(coupon.date_available_to, 'UTC')
    );
  }

  handleInvalidCouponEntry(scannedValue) {
    this.props.actions.getUnpaginatedData('/api/coupons', null, {failed: 'retail.coupons.get.failed'})
      .then((allCoupons) => {
        let i18nKey;
        const coupon = allCoupons.find(coupon => coupon.coupon_sku === scannedValue);

        if (!coupon) {
          i18nKey = 'retail.coupons.scanWarnings.notFound';
        } else if (!this.isCouponActive(coupon)) {
          i18nKey = 'retail.coupons.scanWarnings.inactive';
        } else {
          i18nKey = 'retail.coupons.scanWarnings.other';
        }

        this.props.actions.addMessage(messageTypes.error, i18nKey);
      });
  }

  addScannedItem() {
    const {order, currentLocation, allowedSubcategories, negativeInventoryAllowed} = this.props;

    const getNextCode = () => {
      if(get(this.state, 'addingScannedItem', false)) return false;
      const scanQueue = this.state.scanQueue.slice(0);
      if(!scanQueue.length) return false;
      const code = scanQueue.shift();
      this.setState({scanQueue, addingScannedItem: true}, () => {
        postScannedItem(code);
      });
    };

    const hideLoader = () => {
      this.setState({addingScannedItem: false}, () => {
        if(this.state.scanQueue.length) {
          getNextCode();
        } else {
          this.hideLoader();
        }
      });
    };

    // If negative inventory is allowed, then we do first attempt with forced inventory-level validation.
    // Then if we get error response and the error is about inventory-level - we show confirmation modal "Should we go negative"?
    // If user chooses to go negative, we make same call without inventory-level validation.
    const postScannedItem = (code) => {
      const payload = {
        scanned_value: code.trim(),
        inventory_location_id: currentLocation,
        filters: {in_subcategories: allowedSubcategories},
      };

      const final = () => {
        hideLoader();
      };

      const makeCallToBackend = (force_negative_inventory_validation) => {
        this.showLoader('lookup');
        this.setState({scanField: ''});

        const getCouponsForOrder = () => {
          const couponsInState = this.props.coupons;
          if (couponsInState && couponsInState.length > 0) {
            return Promise.resolve(couponsInState);
          } else {
            return new Promise((resolve, reject) => {
              this.props.actions.getUnpaginatedData(`/api/orders/${order.id}/available_coupons`,
              dataNames.coupons, {failed: 'orders.coupons.failed'}, undefined, (coupons) => {
                resolve(coupons);
              });
            });
          }
        };

        // Handle a coupon
        if(isCouponCode(payload.scanned_value)) {
          return getCouponsForOrder().then((coupons) => {
            const coupon = coupons.find(coupon => coupon.coupon_sku == payload.scanned_value);

            if (!coupon) {
              this.handleInvalidCouponEntry(payload.scanned_value);
              return;
            }

            if (coupon.coupon_applies_to === 'line') {
              this.setState({
                lineItemCouponScanned: coupon
              });
              return;
            }

            return this.props.actions
              .postItem(`/api/orders/${order.id}/coupons`, {coupon_id: coupon.id}, itemNames.order)
              .finally(() => {
                this.setState({couponUpdating: false});
                this.props.couponsUpdated();
                this.props.hideLoader();
              });
          });
        // Handle product items
        } else {
          const handlers = force_negative_inventory_validation
            ? {failedHandler: doNothing}
            : {failed: 'cart.getScannedItem.failed', failedHandler: this.handleAddProductToOrder};
          return this.props.actions
            .postItem(`/api/orders/${order.id}/add_scanned`,
              {...payload, force_negative_inventory_validation},
              itemNames.order,
              handlers,
              undefined,
              (response) => {
                this.postCureInfoForScannedItem(response);
                this.clearSalesLimitError();
              }
            );
        }
      };

      const forceNegativeValidation = negativeInventoryAllowed ? 1 : undefined;
      makeCallToBackend(forceNegativeValidation)
        .then(final)
        .catch(err => {
          if (forceNegativeValidation) {
            const isInventoryLevelError = (get(err, 'response.data.errors.VALIDATION.order_id.0') === inventoryLevelMessage);
            if (isInventoryLevelError) {
              // In case of inventory level error - display special alert:
              showNegativeAlert(isInventoryLevelError, this.setNegativeConfirmationState)
                .then(makeCallToBackend)
                .then(final)
                .catch(final);
            } else {
              // For all other errors apart from inventory level - display normal validation errors.
              // To do so - repeat call to the backend without "force_negative_inventory_validation".
              // This should be a rare case (both "Negative inventory" and "Isolocity" are enabled and lab results check failed for this package),
              // so overhead of second call is not significant.
              makeCallToBackend()
                .then(final)
                .catch(final);
            }
          } else {
            final();
          }
        });
    };

    getNextCode();
  }

  // Helpers

  showLoader(messageType){
    const messages = [
      'addingItem',
      'addingProduct',
      'removingItem',
      'removingProduct',
      'updatingOrder',
      'cancellingOrder',
      'lookup',
      'fetchingCoupons'
    ];
    if(messages.indexOf(messageType) === -1) messageType = 'updatingOrder';
    this.setState({showLoader: true, showSpinner: true, showOk: false, message: I18n.t(`cart.loadingMessage.${messageType}`)});

  }

  showStatus(messageType){
    // For reference
    // notFound, multiplesFound, notYourLocation, productAlreadyInOrder
    this.setState({showSpinner: false, showOk: true, message: I18n.t(`cart.statusMessage.${messageType}`)});
  }

  onDismissLoader(){
    this.setState({showLoader: false, showSpinner: true, showOk: false, messageDeferred: false});
  }

  terminate(){
    // only so we can chain to catch
  }

  hideLoader(addState){
    this.focusBarcode();
    return new Promise (resolve => {
      if(this.state.showOk) return resolve(false);
      const loaderState = {showLoader: false, showOk: false, showSpinner: true, messageDeferred: false};
      const state = (addState) ? Object.assign({}, addState, loaderState) : loaderState;
      this.setState(state);
      resolve();
    });
  }

  closeAssignDriver(){
    this.setState({assignDriverOpen: false});
  }

  openAssignDriver() {
    this.setState({assignDriverOpen: true});
  }

  changeMakePayment() {
    this.setState({makePayment: true});
  }

  goToPage(page){
    const loaders = {
      hideLoader: () => { this.setState({showLoader: false});},
      cureLoader: () => this.setState({showLoader: true, message: I18n.t('cart.loadingMessage.cureValidation'), showSpinner: true}),
      metrMiLoader: () => this.setState({showLoader: true, message: I18n.t('inProgressOverlay.message'), showSpinner: true})
    };
    this.props.actions.validateSalesLimit(page, loaders);
  }

  focusBarcode(){
    if(this.state.scanMode && this.props.coupons.length === 0 && this.props.rewards.length === 0) {
      this.scanInputRef.current.focus();
    }
  }

  toggleScanMode(){
    const {scanMode} = this.state;
    this.setState({scanMode: !scanMode});
    setTimeout(() => this.focusBarcode());
  }

  completeLineItemCouponScan() {
    this.setState({
      lineItemCouponScanned: null
    });
  }

  render() {
    const {taxRate, coupons, rewards, checkout, prepackWeights, selectedItemMasters, customer,
      packageCodes, inventoryItems, order, pricingWeights, getProductFulfillmentStatus, getOrderProductCoupons,
      couponIsAppliedToAllProducts, quantityTotals, salesLocations, currentLocation, isOrderFulfilled,
      subcategoryFormOptions, isCure, validCureLimits, hasPermApplyManualDiscounts, detailedOrderCoupons, rewardPointsOnOrder,
      orderPackagingWorkflow, disablingAddInventory, selectedChildItemMasters, isOhMetrc, isRefillNumberRequired,
      negativeInventoryAllowed, blockExpiredProductAction, salesComplianceSettings, orderCategoriesDisplayCategories, integrationState, testResults,
      constants, isLeafPaConfigPackClosedLoopFacility, featureLeafPaMmapEnabled, mmapPayments, printJob
    } = this.props;

    const {showNegativeInvConfirmation, lineItemCouponScanned} = this.state;

    const printBeforePayment = parseInt(get(salesComplianceSettings, 'order_print_labels_and_receipts_without_payment.value', '0')) === 1;

    const initialValues = {...order, orderPackagingWorkflow};
    return (<div className='cart-page'>
      <InProgressOverlay
        isActive={this.state.showLoader}
        message={this.state.message}
        onDismiss={this.onDismissLoader}
        showOk={this.state.showOk}
        showLoader={this.state.showSpinner}
        translate={false} />

      <NegativeInventoryAlert confirmationState={showNegativeInvConfirmation}/>

      <LabelPrinter
        showModal={this.state.showModal}
        onHide={this.onHideModal}
        labelTag='patient_label_large'
        payload={this.state.payload}
      />

      <ProductsHeader
        props={this.props}
        context={this.context}
        showCart={false}
        showProfile={!!get(customer,'id',false)}
        orderProducts={order.products}
        complianceWeightInGrams={this.state.complianceWeightInGrams}
        salesComplianceSettings={salesComplianceSettings}
        displayCategories={orderCategoriesDisplayCategories}
      />

      <CancelOrder
        event={this.state.cancelOrderEvent}
        customerId={this.state.cancelOrderCustomerId}
        orderId={this.state.cancelOrderOrderId}
        eventListeners={this.state.cancelOrderEventListeners}
      />

      <AssignDriver
        show={this.state.assignDriverOpen}
        close={this.closeAssignDriver}
        fillOrder={this.fillOrder}
        taxRate={taxRate}
        setTaxRate={this.props.actions.setTaxRate}
      />

      <Cart
        scanMode={this.state.scanMode}
        toggleScanMode={this.toggleScanMode}
        initialValues={initialValues}
        enableReinitialize={true}
        keepDirtyOnReinitialize={false}
        order={order}
        form={formName}
        scanField={this.state.scanField}
        onSubmit={this.onSubmit}
        inventoryItems={inventoryItems || []}
        packageCodes={packageCodes || []}
        pricingWeights={pricingWeights || []}
        prepackWeights={prepackWeights || []}
        quantityTotals={quantityTotals}
        itemMasters={keyBy(selectedItemMasters, 'id')}
        taxRate={taxRate}
        fillOrder={this.fillOrder}
        toggleCheckout={this.props.actions.toggleCheckout}
        cancelOrder={this.cancelOrder}
        editOrder={this.editOrder}
        changeFulfillmentStatus={this.changeFulfillmentStatus}
        removeCoupon={this.removeCoupon}
        handleRemoveItem={this.removeItem}
        handleRemoveProduct={this.removeProduct}
        checkout={checkout}
        openAssignDriver={this.openAssignDriver}
        handleKeyPress={this.handleKeyPress}
        handleScanChange={this.handleScanChange}
        push={this.props.actions.push}
        getProductFulfillmentStatus={getProductFulfillmentStatus}
        getOrderProductCoupons={getOrderProductCoupons}
        couponIsAppliedToAllProducts={couponIsAppliedToAllProducts}
        coupons={coupons}
        rewards={rewards}
        constants={constants}
        salesLocations={salesLocations}
        currentLocation={currentLocation}
        couponUpdateRequired={this.state.couponUpdateRequired}
        couponsUpdated={() =>  this.setState({couponUpdateRequired: false})}
        rewardUpdateRequired={this.state.rewardUpdateRequired}
        rewardsUpdated={() => this.setState({rewardUpdateRequired: false})}
        showLoader={this.showLoader}
        hideLoader={this.hideLoader}
        meetsComplianceLimits={this.state.complianceWeightInGrams === 0} // only non zero when over limit
        isOrderFulfilled={isOrderFulfilled}
        changeMakePayment={this.changeMakePayment}
        goToPage={this.goToPage}
        customer={customer}
        isCure={isCure}
        subcategoryFormOptions={subcategoryFormOptions}
        validCureLimits={validCureLimits}
        hasPermApplyManualDiscounts={hasPermApplyManualDiscounts}
        detailedOrderCoupons={detailedOrderCoupons}
        rewardPointsOnOrder={rewardPointsOnOrder}
        focusBarcode={this.focusBarcode}
        disablingAddInventory={disablingAddInventory}
        orderPackagingWorkflow={orderPackagingWorkflow}
        selectedChildItemMasters={selectedChildItemMasters}
        processing={this.state.processing}
        isOhMetrc={isOhMetrc}
        integrationState={integrationState}
        testResults={testResults}
        isRefillNumberRequired={isRefillNumberRequired}
        negativeInventoryAllowed={negativeInventoryAllowed}
        blockExpiredProductAction={blockExpiredProductAction}
        lineItemCouponScanned={lineItemCouponScanned}
        completeLineItemCouponScan={this.completeLineItemCouponScan}
        isLeafPaConfigPackClosedLoopFacility={isLeafPaConfigPackClosedLoopFacility}
        featureLeafPaMmapEnabled={featureLeafPaMmapEnabled}
        mmapPayments={mmapPayments}
        removeMmapPayment={this.removeMmapPayment}
        scanInputRef={this.scanInputRef}
        printJob={printJob}
        printAllLabels={this.printAllLabels}
        printBeforePayment={printBeforePayment}
      />
    </div>);
  }
}

CartPage.propTypes = {
  inventoryItems: PropTypes.object.isRequired,
  selectedItemMasters: PropTypes.array.isRequired,
  selectedChildItemMasters: PropTypes.array.isRequired,
  order: PropTypes.object.isRequired,
  pricingWeights: PropTypes.array.isRequired,
  prepackWeights: PropTypes.array.isRequired,
  quantityTotals: PropTypes.array.isRequired,
  coupons: PropTypes.array.isRequired,
  rewards: PropTypes.array.isRequired,
  checkout: PropTypes.bool.isRequired,
  packageCodes: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  assignDriverOpen : PropTypes.bool,
  taxRate: PropTypes.number.isRequired,
  customer: PropTypes.object.isRequired,
  constants: PropTypes.object,
  scanField: PropTypes.string,
  salesLocations: PropTypes.array,
  getPricingWeightByFulfillmentUnits: PropTypes.func.isRequired,
  getOrderInventoryItem: PropTypes.func.isRequired,
  getProductFulfillmentStatus: PropTypes.func.isRequired,
  getOrderProductItems: PropTypes.func.isRequired,
  couponIsAppliedToAllProducts: PropTypes.func.isRequired,
  getOrderProductCoupons: PropTypes.func.isRequired,
  currentLocation: PropTypes.number,
  formProducts: PropTypes.array,
  productsWithComplianceWeight: PropTypes.array,
  isOrderFulfilled: PropTypes.bool.isRequired,
  isLeaf: PropTypes.bool,
  isCure: PropTypes.bool,
  subcategoryFormOptions: PropTypes.object,
  validCureLimits: PropTypes.bool,
  applicationMode: PropTypes.string.isRequired,
  hasPermApplyManualDiscounts: PropTypes.bool,
  orderPackagingWorkflow: PropTypes.number,
  allowedSubcategories: PropTypes.array.isRequired,
  isBiotrack: PropTypes.bool.isRequired,
  isOhMetrc: PropTypes.bool.isRequired,
  integrationState: PropTypes.object,
  testResults: PropTypes.array,
  isRefillNumberRequired: PropTypes.bool,
  orderCategoriesDisplayCategories: PropTypes.array,
  isDcMetrc: PropTypes.bool,
  isMiMetrc: PropTypes.bool,
  isMtMetrc: PropTypes.bool,
  isMdMetrc: PropTypes.bool,
  productionRunPackageCodes: PropTypes.array,
  hasMetrcSalesLimits: PropTypes.bool,
  isLeafPaConfigPackClosedLoopFacility: PropTypes.bool,
  featureLeafPaMmapEnabled: PropTypes.bool
};

const selector = formValueSelector(formName);
function mapStateToProps(state, ownProps){
  const {customer, packageCodes, pricingWeights, coupons, selectedItemMasters,
    prepackWeights, itemMasters, selectedChildItemMasters, user, rewards, salesComplianceSettings: { order_packaging_workflow }} = state;
  const {checkout, taxRate} = state.cart;
  const formProducts = selector(state, 'products') || [];
  const quantityTotals = formProducts.map(product => product.items
    ? product.items.reduce((total, item) => total + parseFloat(item.qty), 0)
    : []
  );
  const order = getOrderWithRichProducts(state);
  const validCureLimits = isValidLimits(state, {customer, mode: 'customer'});
  const integrationState = getIntegrationState(state);
  const {isCure, isLeaf, isBiotrack, isOhMetrc, isMdMetrc, isDcMetrc, isMiMetrc, isMtMetrc} = getIntegrationState(state);
  const orderPackagingWorkflow = order_packaging_workflow && parseInt(order_packaging_workflow.value);
  let allowedSubcategories = [];
  if (isBiotrack) {
    const btSubcategories = getMedicatedEndProducts(state);
    const medicatedSubcategoryIds = isBiotrack ? btSubcategories : [];
    const nonMedicatedSubcategoryIds = isBiotrack ? getNonMedicatedSubcategoryIds(state) : [];
    allowedSubcategories = union(medicatedSubcategoryIds, nonMedicatedSubcategoryIds);
  }
  if (isOhMetrc) {
    allowedSubcategories = union(ohMetrcSubcategoriesForSale(state), getNonMedicatedSubcategoryIds(state));
  }

  return {
    currentLocation: user && user.currentLocation ? user.currentLocation : null,
    itemMasters,
    selectedItemMasters,
    selectedChildItemMasters,
    inventoryItems: getFilteredItems(state),
    order,
    coupons,
    rewards,
    checkout,
    taxRate,
    customer,
    constants: getConstants(state),
    packageCodes,
    pricingWeights,
    prepackWeights,
    quantityTotals,
    orderCategoriesDisplayCategories: state[dataNames.categories],
    salesLocations: getFlatTopLocations(state),
    getPricingWeightByFulfillmentUnits: prepackWeight => getPricingWeightByFulfillmentUnits(state, prepackWeight),
    getOrderInventoryItem: item => getInventoryItem(state, item),
    getProductFulfillmentStatus: product => getProductFulfillmentStatusById(state, product),
    getOrderProductItems: product => getOrderProductItems(state, product),
    couponIsAppliedToAllProducts: coupon => couponIsAppliedToAllProducts(state, coupon),
    getOrderProductCoupons: product => getOrderProductCoupons(state, product),
    scanField: selector(state, 'scanField'),
    formProducts,
    compliance: state[itemNames.complianceSettings],
    isOrderFulfilled: isOrderFulfilled(state),
    isLeaf,
    isCure,
    subcategoryFormOptions: getFormOptionsBySubcategory(state, {customer, mode: 'customer'}),
    validCureLimits,
    applicationMode: getApplicationMode(state),
    hasPermApplyManualDiscounts: hasPermApplyManualDiscounts(state),
    rewardPointsOnOrder: getRewardPointsOnOrder(state),
    detailedOrderCoupons: getDetailedOrderCoupons(state),
    orderPackagingWorkflow,
    disablingAddInventory: (!!orderPackagingWorkflow && !isReadyStatus(state)),
    allowedSubcategories,
    isBiotrack,
    isOhMetrc,
    integrationState,
    testResults: getTestResults(state),
    isDcMetrc,
    isMdMetrc,
    isMiMetrc,
    isMtMetrc,
    negativeInventoryAllowed: isAllowNegativeInventory(state),
    blockExpiredProductAction: getBlockExpiredProductAction(state),
    isRefillNumberRequired: getIsRefillNumberRequired(state, {order}),
    productionRunPackageCodes: getProductionRunPackageCodes(state),
    orderEnableWeighOnHand: getOrderEnableWeighOnHand(state),
    orderWeighOnHandMarginError: getOrderWeighOnHandMarginError(state),
    hasMetrcSalesLimits: hasMetrcSalesLimits(state),
    isLeafPaConfigPackClosedLoopFacility: isLeafPaConfigPackClosedLoopFacility(state),
    featureLeafPaMmapEnabled: isFeatureEnabled(state)('feature_leaf_pa_mmap'),
    isMmuEnabled: isLeafPaConfigPackClosedLoopFacility(state) && isFeatureEnabled(state)('feature_leaf_pa_mmu_limits'),
    mmapPayments: getOrderMmapPayments(state),
    printJob: state[itemNames.printJob],
    salesComplianceSettings: state[itemNames.salesComplianceSettings],
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({},
    cartActions,
    apiActions,
    {setSelectedData, unsetData, setData, clearSelectedData, goBack, push, replace, reset,
      unionData, change, setItem, unsetItem, batchActions, addMessage, closeCustDetails});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CartPage);
