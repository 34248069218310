import React from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import RouteButton from '../common/RouteButton';
import {evaluateRouteAccessibility} from '../../selectors/accessSelectors';
import {findRouteDefinition} from '../../util/routeHelper';


const IntegrationPage = ({eligibleLinks}) => {
  return (
    <div className='integration-page'>
      <h1>{I18n.t('stateIntegrators.integrationPage.title')}</h1>
      <Row>
        {eligibleLinks.map(link => {
          return (
            <Col xs={12} sm={6}>
              <RouteButton path={link.path} props={{block:true}}>
                <h3>{I18n.t(`stateIntegrators.integrationPage.${link.name}`)}</h3>
              </RouteButton>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

const links = [
  {
    name: 'APITransactions',
    path: '/integration/api-transactions'
  },
  // {
  //   name: 'taxDocuments',
  //   path: '/integration/tax-notifications'
  // },
  {
    name: 'salesExport',
    path: '/integration/sales-export'
  },
  {
    name: 'trackingIds',
    path: '/integration/tracking'
  }
];

const mapStateToProps = (state) => {
  /*
    NOTE: "eligibleLinks" is a workaround due to the current way evaluateRouteAccessibility() is constructed
     with the entire Redux state being included in it. A better long-term solution is in the works, and in this
     component we should be able to remove the "connect" to Redux altogether, because the RouteButton calls on
     the RoutePermissionWrapper, which will be fixed here: https://akerna.atlassian.net/browse/REBEL-2014

     Head spinning yet?
  */

  const eligibleLinks = links.filter(linkObj => {
    const routeDefinition = findRouteDefinition(linkObj.path);
    const accessibility = routeDefinition.accessibility;
    return Boolean(evaluateRouteAccessibility(accessibility, linkObj.path)(state));
  });

  return {
    eligibleLinks
  };
};

export default connect(mapStateToProps)(IntegrationPage);
