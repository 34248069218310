import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';
import {COMPLETE_EACH_PACKAGES_FORM} from '../../../constants/forms';
import EachPackagesForm from './EachPackagesForm';
import validate from './validateCompleteEachPackaging';

const EachPackagesReduxForm = reduxForm({
  form: COMPLETE_EACH_PACKAGES_FORM,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(EachPackagesForm);

EachPackagesReduxForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  saveComplete: PropTypes.bool.isRequired,
  employees: PropTypes.array.isRequired,
  itemMasters: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  packagedWeight: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isEachPackaging: PropTypes.bool.isRequired,
  isMetrcStemHoldingsToggled: PropTypes.bool,
  isMetrcCaDonationsToggled: PropTypes.bool,
  trackingIds: PropTypes.array
};

export default EachPackagesReduxForm;
