/* eslint-disable import/no-named-as-default*/
import {reduxForm} from 'redux-form';
import {DELIVERY_ORDER_FORM} from '../../../constants/forms';
import DeliveryOrderForm from './DeliveryOrderForm';
import validate from './validate';

export const DeliveryOrderFormWrapper = reduxForm({
  form: DELIVERY_ORDER_FORM,
  validate,
})(DeliveryOrderForm);

export default DeliveryOrderFormWrapper;
