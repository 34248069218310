import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { Field } from 'redux-form';
import { Col, Row, Button, Alert } from 'react-bootstrap';
import {FaExclamationTriangle, FaTrash} from 'react-icons/fa';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import TextInput from '../../../common/form/TextInput';
import InternationalQuantityByUomInput from '../../../common/form/InternationalQuantityByUomInput';
import {getPreviouslyMappedPackagesForItemMaster, sortProductOptions} from '../../../../selectors/PlatformSupplyChainMappingSelectors';


const IncomingProductsFieldArray = ({ formErrors, meta, fields, incomingTransferProductsOptions, lineName, lineId, line, itemMasterMappings }) => {
  // instead of removing items from the incomingTransferProductsOptions, we just filter them out.
  const fieldsLookup = fields.map((n, i) => fields.get(i).id);

  // Determine sorted remainingOptions
  const previouslyMappedPackages = getPreviouslyMappedPackagesForItemMaster(line.item_master_id, itemMasterMappings, incomingTransferProductsOptions);
  const remainingOptions = incomingTransferProductsOptions.filter((item) => fieldsLookup.indexOf(item.id) === -1);
  const sortedOptions = sortProductOptions(remainingOptions, previouslyMappedPackages, line);

  return (
    <div>
      {meta && meta.error ? (
        <Row>
          <Col xs={12}>
            <Alert variant='danger'>
              <FaExclamationTriangle />
              <span className='padding-left'>{meta.error}</span>
            </Alert>
          </Col>
        </Row>
      ) : null}
      {fields.map((fieldName, fieldIndex) => {
        const inventory = fields.get(fieldIndex);
        return (
          <Col xs={12} key={fieldIndex}>
            <Row>
              <Col xs={6} sm={4}>
                <Field
                  name={`${fieldName}.item_master_name`}
                  component={TextInput}
                  props={{
                    label: I18n.t('supplyChainMapping.form.inventoryName'),
                    disabled: true
                  }}
                />
              </Col>
              <Col xs={6} sm={3}>
                <Field
                  name={`${fieldName}.package_code`}
                  component={TextInput}
                  props={{
                    label: I18n.t('supplyChainMapping.form.packageID'),
                    disabled: true
                  }}
                />
              </Col>
              <Col xs={6} sm={4}>
                <InternationalQuantityByUomInput
                  name={`${fieldName}.qty_display`}
                  label={I18n.t('supplyChainMapping.form.quantity')}
                  disabled={true}
                  uom={inventory.uom_display}
                />
              </Col>
              <Col xs={2} sm={1} style={{ paddingTop: 28 }}>
                <Button
                  variant='danger'
                  size='sm'
                  onClick={() => {
                    fields.remove(fieldIndex);
                  }}
                >
                  <FaTrash />
                </Button>
              </Col>
            </Row>

            {formErrors &&
            formErrors[lineName] &&
            formErrors[lineName][fieldIndex] &&
            formErrors[lineName][fieldIndex]['item_master_name'] ? (
              <Row>
                <Col xs={12}>
                  <Alert variant='danger'>
                    <FaExclamationTriangle />
                    <span className='padding-left'>{formErrors[lineName][fieldIndex]['item_master_name']}</span>
                  </Alert>
                </Col>
              </Row>
            ) : null}
          </Col>
        );
      })}
      <Col xs={12} md={10} lg={8}>
        <Field
          name={`add_item_${lineId}`}
          component={ReactSelectInput}
          props={{
            label: I18n.t('supplyChainMapping.form.addInventory'),
            options: sortedOptions,

            onChange: (value) => {
              const index = sortedOptions.findIndex((option) => {
                return option.id === value;
              });
              if (index >= 0) {
                fields.push({
                  line_id: lineId,
                  ...sortedOptions[index]
                });
              }
            }
          }}
        />
      </Col>
    </div>
  );
};

IncomingProductsFieldArray.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    get: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired
  }).isRequired,
  meta: PropTypes.object,
  incomingTransferProductsOptions: PropTypes.array.isRequired,
  lineName: PropTypes.string.isRequired,
  lineId: PropTypes.string,
  formErrors: PropTypes.object,
  line: PropTypes.object,
  itemMasterMappings: PropTypes.array
};

export default IncomingProductsFieldArray;
