import PropTypes from 'prop-types';
import { reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

import validate from '../common/validate';
import PricingClassForm from '../common/PricingClassForm';

import * as dataNames from '../../../../constants/dataNames';
import * as itemNames from '../../../../constants/itemNames';

const form = 'createPricingClassForm';
const getFormValue = formValueSelector(form);

const PricingClassReduxForm = reduxForm({
  form,
  validate,
})(PricingClassForm);

function mapStateToProps (state) {
  const currentFacility = state[itemNames.facility];

  return {
    showSaveAndReturn: true,
    facilities: state[dataNames.facilities].filter(facility => facility.type === currentFacility.type),
    customerGroups: state[dataNames.customerGroups],
    getFormValue: (name) => getFormValue(state, name)
  };
}

const CreatePricingClassFormWrapper = connect(mapStateToProps)(PricingClassReduxForm);

CreatePricingClassFormWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

export default CreatePricingClassFormWrapper;
