import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Row, Table, Card} from 'react-bootstrap';

import RenderCommonRowHistory from './RenderCommonRowHistory';
import RenderSalesInfoHistory from './RenderSalesInfoHistory';

const ProductUpdateDetails = (props) => {
  const {delta} = props;
  return (delta.length ?
      <Card>
        <Row>
          <Col xs={12}>
            <h3>{I18n.t('products.productHistory.productDetails')}</h3>
            <Table striped>
              <thead>
              <tr>
                <th>{I18n.t('itemTransactions.field')}</th>
                <th>{I18n.t('itemTransactions.value')}</th>
              </tr>
              </thead>
              <tbody>
              {delta.map((field, index) =>
                <RenderCommonRowHistory key={index} field={field} />)
              }
              <RenderSalesInfoHistory delta={delta} />
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card> : null
  );
};

ProductUpdateDetails.propTypes = {
  product: PropTypes.object.isRequired,
  delta: PropTypes.array.isRequired
};

export default ProductUpdateDetails;
