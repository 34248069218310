import React from 'react';
import PropTypes from 'prop-types';
import {reduxForm, Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import { Col, Row } from 'react-bootstrap';
import SubmitSection from '../common/form/SubmitSection';
import TextInput from '../common/form/TextInput';
import ReactSelectInput from '../common/form/ReactSelectInput';

export const AccountingSettingsForm = ({handleSubmit, invalid, submitting, pristine, reset, accountingSettingsFields, accountingAccountTypes, accountingSettings}) => {
  const settings = {
    actionSettings: {
      reset: {
        action: reset,
        text: I18n.t('common.form.reset'),
        style: 'default',
        pristine
      },
      submit: {
        submitting,
        pristine,
        invalid,
        text: I18n.t('common.form.save'),
        style: 'primary'
      }
    }
  };
  return (
    <form className='accounting-settings-form' onSubmit={handleSubmit}>
      <Row className='account-settings-headers'>
        <Col md={{span:3, offset:3}}><strong>{I18n.t('accounting.form.account')}</strong></Col>
        <Col md={3}><strong>{I18n.t('accounting.form.generalLedgerAccountName')}</strong></Col>
        <Col md={3}><strong>{I18n.t('accounting.form.generalLedgerAccountType')}</strong></Col>
      </Row>
      {accountingSettingsFields.map(field => <Row key={field.id}>
        <Col md={{span:3, offset:3}}>{field.account_name}</Col>
        <Col md={3}>
          <Field
            name={`${field.account_key}.account_name`}
            component={TextInput}
            props={{
              label: ''
            }}
          />
        </Col>
        <Col md={3}>
          <Field
            name={`${field.account_key}.account_type_id`}
            component={ReactSelectInput}
            props={{
              label: '',
              options: accountingAccountTypes,
              textKey:'name',
              valueKey: 'id',
              placeholder: I18n.t('common.form.select')
            }}/>
        </Col>
      </Row>)}
      <SubmitSection settings={settings}/>
    </form>
  );
};

AccountingSettingsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  accountingSettingsFields: PropTypes.array.isRequired,
  accountingAccountTypes: PropTypes.array.isRequired,
  accountingSettings: PropTypes.array.isRequired
};

export default reduxForm({
  form: 'stateIntegrationSettings',
  enableReinitialize: true
})(AccountingSettingsForm);
