/* eslint-disable import/no-named-as-default */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as dataNames from '../../constants/dataNames';
import * as apiActions from '../../actions/apiActions';
import * as itemNames from '../../constants/itemNames';
import testData from './testDataForLabels';

import MessageModal from '../common/modal-messages/MessageModal';
import TestBoxes from './TestBoxes';
import SetPrinterModal from '../printer/setprinter/SetPrinterModal';

import QzTrayConfig from '../printer/QzTrayConfig';
import PrinterModal from '../printer/PrinterModal';

export class LocationPage extends React.PureComponent {

  constructor(props, context) {

    super(props, context);

    this.state = {
      activeTest: false,
      tests     : testData,
      qz        : QzTrayConfig(),
      showModal : false,
      modalText : '',
      modalTitle: '',
      modalType : undefined,

      printerModalShow      : false,
      printerModalUrl       : false,
      printerModalPayload   : {},
      printerModalHttpAction: 'GET',
      labelTag              : undefined,
      labelIds              : undefined,
      forceLabelBlocks      : false,

      showSetPrinter: false

    };

    this.getLabelFromServer = this.getLabelFromServer.bind(this);
    this.loadPrinterModal   = this.loadPrinterModal.bind(this);
    this.hidePrinterModal   = this.hidePrinterModal.bind(this);

    this.onFieldChange     = this.onFieldChange.bind(this);
    this.onPrintLabelClick = this.onPrintLabelClick.bind(this);
    this.hideModal         = this.hideModal.bind(this);

    this.onShowSetPrinter = this.onShowSetPrinter.bind(this);
    this.onHideSetPrinter = this.onHideSetPrinter.bind(this);


  }

  componentWillMount() {

    this.props.actions.getData('/api/cultivation/buildings', dataNames.buildings);
    this.props.actions.getData('/api/cultivation/rooms', dataNames.rooms);
    this.props.actions.getData('/api/cultivation/zones', dataNames.zones);
    this.props.actions.getData('/api/cultivation/sections', dataNames.sections);

  }

  // Field change, not test change... cannot use changeActiveFieldState
  onFieldChange(test, field, value) {

    const updatedTests = this.state.tests.concat([]);
    const curTest      = updatedTests.find(t => {
      return JSON.stringify(t) === JSON.stringify(test);
    });
    const curField     = curTest.fields.find(f => {
      return JSON.stringify(f) === JSON.stringify(field);
    });

    curField.value = value;

    this.setState({
      tests: updatedTests
    });

  }

  getLabelFromServer(test) {

    const that     = this;
    const messages = {
      success: false,
      failed : 'An error occurred while loading the label.  Check that the provided inputs are valid.'
    };
    let success    = false;

    const onSuccess = (data) => {
      success = true;
      this.changeActiveTestState(test, {image: data, loadingSample: false, loadingError: false});
    };

    const onFailure = () => {
      that.changeActiveTestState(test, {connecting: false, printing: false, loadingSample: false});
      if (!success) {
        that.changeActiveTestState(test, {loadingError: true});
      }
    };

    that.changeActiveTestState(test, {
      connecting   : false,
      printing     : false,
      loadingSample: true,
      loadingError : false,
      image        : false
    });

    const url = this.getParsedUrl(test);

    if (test.type === 'GET') {
      this.props.actions.getItem(url, itemNames.label, messages, {}, onSuccess).then(onFailure);
      return true;
    }

    const params = this.getParams(test);

    this.props.actions.postItem(url, params, itemNames.label, messages, {}, onSuccess).then(onFailure);

  }

  loadPrinterModal(test) {

    let newState = {};
    let params   = {};

    if (!test.legacy) {
      const url = this.getParsedUrl(test);
      params    = (test.type === 'GET') ? {} : this.getParams(test);
      newState = {
        printerModalUrl       : url,
        printerModalPayload   : params,
        printerModalHttpAction: test.type,
        printerModalShow      : true,
        labelTag              : undefined,
        labelIds              : undefined
      };

    } else {

      params   = this.getParams(test);
      newState = {
        printerModalUrl       : undefined,
        printerModalPayload   : undefined,
        printerModalHttpAction: undefined,
        printerModalShow      : true,
        labelTag              : test.tagName,
        labelIds              : params.ids
      };
    }

    this.setState(newState);

  }

  hidePrinterModal() {
    this.setState({
      printerModalShow: false
    });
  }

  /***
   * changes one or more params on the current test being run
   * @param test
   * @param fieldsAndValuesObject
   */
  changeActiveTestState(test, fieldsAndValuesObject) {

    const newState     = {};
    const tests        = this.state.tests.concat([]);
    const selectedTest = tests.find((t) => {
      return JSON.stringify(test) === JSON.stringify(t);
    });
    if (selectedTest) {
      for (const prop in fieldsAndValuesObject) {
        selectedTest[prop] = fieldsAndValuesObject[prop];
      }
    }
    newState.tests = tests;
    this.setState(newState);

  }

  getParsedUrl(test) {

    let url = test.url;
    url     = url.replace('[TAGNAME]', test.tagName);
    if (test.type === 'GET') {
      url = url.replace('[ID]', test.fields[0].value);
    }
    return url;

  }

  /***
   * Supports comma delimited conversion to arrays, forcing fields with the postField "ids" to arrays.
   * @param test
   * @returns {{}}
   */
  getParams(test) {

    if (test.type === 'GET') return {};

    const that   = this;
    const params = {};

    if(test.testNamedBlocks){
      this.setState({forceLabelBlocks: true});
      params['namedBlocks'] = test.namedBlocks;
      return params;
    }
    this.setState({forceLabelBlocks: false});
    test.fields.forEach((field) => {

      if (field.value === undefined) return true;

      if (field.postField === 'ids' && field.value.indexOf(',') === -1) {
        params[field.postField] = [].concat([field.value]);
        return true;
      }

      if (that.isInt(field.value)) {
        params[field.postField] = field.value;
        return true;
      }

      params[field.postField] = (field.value.indexOf(',') === -1) ? field.value : field.value.split(',');

    });

    return params;
  }

  isInt(value) {
    return !isNaN(value) &&
      parseInt(Number(value)) == value && !isNaN(parseInt(value, 10));
  }

  hideModal() {
    this.setState({showModal: false});
  }

  onPrintLabelClick(test) {

    const that = this;
    const qz   = this.state.qz;

    that.changeActiveTestState(test, {connecting: true, printing: false});

    qz.websocket.connect().then(() => {

      qz.printers.getDefault().then((printer) => {
        that.changeActiveTestState(test, {connecting: false, printing: true});
        // Pass the printer name into the next Promise
        const config = qz.configs.create(printer);
        // Create a default config for the found printer
        //const data = [this.props.label.zpl];
        const data = [test.image.zpl];
        // Raw ZPL
        return qz.print(config, data);

      }).catch((e) => {
        that.changeActiveTestState(test, {connecting: false});
        const message = (
          <div>We cannot find your printer. Please set a default printer on your computer or device and ensure it is
            both connected and turned on.</div>);
        this.setState({
          showModal : true,
          modalType : 'warning',
          modalTitle: 'No default printer found!',
          modalText : message
        });
      }).then(() => {
        that.changeActiveTestState(test, {printing: false});
        qz.websocket.disconnect();
      });
    }).catch((error) => {
      that.changeActiveTestState(test, {connecting: false, printing: false});
      const message = (
        <div>We cannot connect to your QZ Tray<br />Is it installed on your computer or device?<br />Is it running?
        </div>);
      this.setState({
        showModal : true,
        modalType : 'error',
        modalText : message,
        modalTitle: 'Connection Error With QZ Tray!'
      });
    });

  }

  onHideSetPrinter(){
    this.setState({showSetPrinter: false});
  }

  onShowSetPrinter(){
    this.setState({showSetPrinter: true});
  }

  render() {

    return (
      <div className='labels-test-page locations table-page'>
        <SetPrinterModal
          show={this.state.showSetPrinter}
          onHideCallback={this.onHideSetPrinter}
          setForLabel='PLANT_LABELS'
        />
        <PrinterModal
          forceLabelBlocks={this.state.forceLabelBlocks}
          labelUrl={this.state.printerModalUrl}
          httpAction={this.state.printerModalHttpAction}
          payload={this.state.printerModalPayload}
          showPrinter={this.state.printerModalShow}
          hidePrinter={this.hidePrinterModal}
          labelTag={this.state.labelTag}
          labelIds={this.state.labelIds}
        />
        <MessageModal
          title={this.state.modalTitle}
          onHide={this.hideModal}
          dialogClassName='modal-sm'
          showModal={this.state.showModal}
          modalType={this.state.modalType}
          message={this.state.modalText}/>

        <h3>Labels Tests</h3>
        <button className='btn btn-primary' onClick={this.onShowSetPrinter}>Show Set Printer</button>
        <TestBoxes
          loadPrinterModal={this.loadPrinterModal}
          onPrintLabel={this.onPrintLabelClick}
          tests={this.state.tests}
          onFieldChange={this.onFieldChange}
          testIds={this.state}
          onTestLabel={this.getLabelFromServer}/>
      </div>
    );
  }
}

LocationPage.propTypes = {
  sections: PropTypes.array.isRequired,
  actions : PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {

  const {rooms, zones, sections, label} = state;

  const collection = rooms.concat(zones).concat(sections);

  return {
    collection,
    rooms,
    zones,
    sections,
    label
  };

}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions);
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationPage);
