/* eslint-disable import/no-named-as-default */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack} from 'react-router-redux';
import {formValueSelector} from 'redux-form';
import moment from 'moment';
import uniqBy from 'lodash.uniqby';
import get from 'lodash.get';
import * as UOM from '../../../../constants/uoms';
import * as dataNames from '../../../../constants/dataNames';
import * as itemNames from '../../../../constants/itemNames';
import {setItem, unsetItem} from '../../../../actions/itemActions';
import {unsetData} from '../../../../actions/dataActions';
import {
  getItem,
  ensureGetUnpaginatedData,
  getUnpaginatedData,
  postItem,
  getDataByPost
} from '../../../../actions/apiActions';
import {fetchMetrcTrackingIdsForSelectInput} from '../../../../actions/integrationActions';
import {getEquipments} from '../../../../selectors/equipmentSelectors';
import {getIngredientOptions} from '../../../../selectors/ingredientsSelectors';
import {getIntegrationState} from '../../../../selectors/integration/integrationSelectors';
import {getFlattenedLocations} from '../../../../selectors/locationsSelectors';
import {
  getCompleteProcessingInitialValues,
  getWeights,
  getIngredientsCost,
  getCompleteProcessingPayload,
  getCurrentTimezone,
  getProcessingJobOutputSubcategoryIds,
  areValidPackagesForProcessing,
  isDraftJob
} from '../../../../selectors/processingSelectors';
import {getCurrentFacilityUserOptions, getCurrentUserPermissions} from '../../../../selectors/usersSelectors';
import {
  checkIngredientsUom,
} from '../../../../selectors/processingTypesSelectors';
import FormWrapper from '../../../common/form/FormWrapper';
import CompleteProcessingFormWrapper from './CompleteProcessingFormWrapper';
import InProgressOverlay from '../../../common/InProgressOverlay';
import PrinterModal from '../../../printer/PrinterModal';
import ModalWrapper from '../../../common/ModalWrapper';
import IntegrationInvalidWarning from '../common/IntegrationInvalidWarning';
import reduxMetrcIdAvailability, {setUnavailableTrackingIdFieldArrayErrors} from '../../../common/form/redux-form/reduxMetrcIdAvailability';
import * as messageTypes from '../../../../constants/messageTypes';
import {addMessage} from '../../../../actions/systemActions';
import {COMPLETE_PROCESSING_FORM} from '../../../../constants/forms';
import {getModulesState} from '../../../../selectors/modulesSelectors';

import {
  isAllowNegativeInventory,
  getIsReservationEnabled,
  getIsLabelEnabledForNoCompletedProcess,
  getInventoryComplianceSettings,
} from '../../../../selectors/complianceSettingsSelectors';

import {doNothing} from '../../../../util/callbackHelpers';
import NegativeInventoryAlert from '../../../common/negative-inventory/NegativeInventoryAlert';
import showNegativeAlert from '../../../common/negative-inventory/showNegativeAlert';
import {hasPackagesTags} from '../../../../selectors/integrationSelectors';
import {roundQtyCurried} from '../../../../selectors/uomsSelectors';
import {isFeatureEnabled} from '../../../../selectors/featureToggles';
import {isLeafPaConfigPackClosedLoopFacility} from '../../../../selectors/facilitiesSelectors';

const form = COMPLETE_PROCESSING_FORM;
const selector = formValueSelector(form);

export class CompleteProcessingPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.onSubmit = this.onSubmit.bind(this);
    this.showPrinter = this.showPrinter.bind(this);
    this.hidePrinter = this.hidePrinter.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.completeSave = this.completeSave.bind(this);
    this.setNegativeConfirmationState = this.setNegativeConfirmationState.bind(this);
    this.printLabel = this.printLabel.bind(this);

    this.state = {
      submitting: false,
      loading: false,
      showPrinter: false,
      submitDraft: false,
      showNegativeInvConfirmation: {
        show: false,
        onHide: null,
        onConfirm: null,
      },
      formData: {},
      showWarning: false,
      outputsInViolation: [],
    };

  }

  componentWillMount() {
    this.setState({loading: true});
    const {
      actions: {
        getItem,
        ensureGetUnpaginatedData,
        getUnpaginatedData,
        getDataByPost,
        unsetItem,
        unsetData,
        fetchMetrcTrackingIdsForSelectInput,
        setItem,
      },
      params: {id},
      integrationState: {isMetrc, isBiotrack, isLeaf, isWaLeaf},
    } = this.props;
    unsetItem(itemNames.processingJob);
    unsetItem(itemNames.processingType);
    unsetData(dataNames.itemMasters);
    unsetData(dataNames.products);
    setItem('processing_run', itemNames.entityType);
    setItem(id, itemNames.entityId);


    Promise
      .all([
        getItem('/api/compliance_settings', itemNames.complianceSettings),
        getItem(`/api/processing_jobs/${id}`, itemNames.processingJob, {failed: 'ei.processing.get.itemFailed'}, {detailed: 1})
          .then(() => {
            const {processingJob} = this.props;
            const ids = processingJob.inputs.map(input => input.item_id);

            return Promise.all([
              getDataByPost('/api/items/multiple', {
                ids,
                detailed: 1
              }, dataNames.inventoryItems, {failed: 'products.get.failed'}, {detailed: 1}),
              getDataByPost('/api/item_availability/multiple', {ids}, dataNames.itemsAvailability, {failed: 'itemsAvailability.get.failed'}),
            ]);
          })
          .then(() => {
            const {processingJob, inventoryItems} = this.props;
            const items = inventoryItems.filter(item => processingJob.inputs.some(input => input.item_id === item.id));
            const itemMasterIds = uniqBy(items, 'item_master_id').map(item => item.item_master_id);
            const producerIds = items.map(item => item.producer_id);

            const promises = [
              getUnpaginatedData('/api/partners', dataNames.partners, {failed: 'partners.get.failed'}, {ids: producerIds}),
              getUnpaginatedData('/api/item_reservations', dataNames.hardReservations, {failed: 'reservations.failed'}, {in_item_ids: items.map(item => item.id)}),
              getItem(`/api/processing_types/${processingJob.processing_type.id}`, itemNames.processingType, {failed: 'ei.processingTypes.get.itemFailed'}, {detailed: 1}),
              getDataByPost('/api/item_masters/multiple', {ids: itemMasterIds}, dataNames.itemMasters, {failed: 'products.get.failed'}, {detailed: 1}),
            ];

            if (isMetrc) {
              promises.push(fetchMetrcTrackingIdsForSelectInput());
            }

            if (isBiotrack) {
              promises.push(getUnpaginatedData('/api/integration/adjustment_reasons', dataNames.adjustmentReasons, {failed: 'ei.adjustmentReasonsFailed'}));
            }

            return Promise.all(promises);
          })
          .then(() => {
            const {processingType, outputSubcategoryIds, itemMasterServiceFirstIsEnabled} = this.props;
            const ids = processingType.materials.map(m => m.item_master_id);
            const promises = [];

            if (outputSubcategoryIds.length) {
              if (itemMasterServiceFirstIsEnabled) {
                const params = {
                  select_columns: ['id', 'category_id', 'subcategory_id', 'name', 'default_uom'],
                  per_page: 0,
                  sort: ['name:asc'],
                  active: 1,
                  is_draft: 0,
                  is_manufactured_item: 1,
                  is_prepack: 0,
                  in_subcategory_ids: outputSubcategoryIds
                };

                if (isBiotrack || isLeaf || isMetrc) {
                  params.default_uom = UOM.GR;
                }

                promises.push(
                  getUnpaginatedData('/api/item_masters/search', dataNames.products, {failed: 'products.get.failed'}, params)
                );
              } else {
                let filter = `(subcategory_id:${outputSubcategoryIds.join(' OR subcategory_id:')}) AND is_draft:0 AND is_prepack:0 AND active:1 AND is_manufactured_item:1`;
                if (isBiotrack || isLeaf || isMetrc) {
                  filter += ` AND default_uom:${UOM.GR}`;
                }

                const payload = {
                  query: 'matchall',
                  size: 10000,
                  sort: 'name asc',
                  filter,
                  fields: ['id', 'category_id', 'subcategory_id', 'name', 'default_uom']
                };

                promises.push(
                  getDataByPost('/api/search/item_masters', payload, dataNames.products, {failed: 'products.get.failed'})
                );
              }
            }

            if (ids.length) {
              promises.push(
                getDataByPost('/api/costing/item_masters/multiple', {ids}, dataNames.costings, {failed: 'ei.processingTypes.form.failed'}),
                getDataByPost('/api/item_masters/multiple', {ids}, dataNames.ingredients, {failed: 'ei.processingTypes.form.failed'}, {detailed: 1}),
                getDataByPost('/api/items/by_item_master_ids', {ids}, dataNames.ingredientItems, {failed: 'ei.processingTypes.form.failed'})
              );
            }
            return Promise.all(promises);
          }).then(() => {
            const productIds = this.props.products.map(product => product.id);
            if (isWaLeaf) {
              getDataByPost('/api/leaf/get_wa_inventory_processing', {ids: productIds}, dataNames.waProcessingInventoryTypes);
            }
          }),
        getUnpaginatedData('/api/users/current_facility', dataNames.currentFacilityUsers, {failed: 'packaging.getUsers.failed'}),
        ensureGetUnpaginatedData('/api/phases', dataNames.phases, {failed: 'phases.get.failed'}),
        getUnpaginatedData('/api/equipment', dataNames.equipmentItems, {failed: 'equipment.get.failed'}),
        getUnpaginatedData('/api/production_runs', dataNames.productionRuns, {failed: 'productionRuns.get.failed'}),
        getUnpaginatedData('/api/strains/by_organization', dataNames.organizationStrains, {failed: 'strains.get.failed'}, {with_trashed: 1}),
        getUnpaginatedData('/api/location_hierarchy', dataNames.locations, {failed: 'locations.get.failed'}, {is_sales_location: 0}),
      ])
      .then(() => this.setState({loading: false}))
      .catch(() => this.setState({loading: false}));
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.isValidProcessingType && this.props.isValidProcessingType) {
      this.props.actions.addMessage(messageTypes.error, 'ei.processingTypes.uomChanged');
    }
  }

  onSubmit(formData) {
    const isInventoryNegative = formData.inputs.some(item => ((typeof item.packageCode !== 'undefined') && item.packageCode != null && item.qty > item.maxQty));
    showNegativeAlert(isInventoryNegative, this.setNegativeConfirmationState)
      .then(() => this.sendRequest(formData))
      .catch(doNothing);
  }

  sendRequest(formData) {
    const submitData = this.getSubmitData(formData);
    this.setState({submitting: true});

    // MetrcMd uses a single select employee drop down instead of multiple so handles case in selector
    const {isMetrc, isMdMetrc} = this.props.integrationState;
    const {params: {id}, afterSubmit, submitDraft, validateTrackingIdsAvailability, roundQty} = this.props;
    const payload = getCompleteProcessingPayload(submitData, this.props.timezone, isMdMetrc, isMetrc, roundQty);

    return validateTrackingIdsAvailability(formData.outputs, setUnavailableTrackingIdFieldArrayErrors('outputs'))
      .then(() => {
        if (!submitDraft) {
          return this.props.actions
            .postItem(
              `/api/processing_jobs/${id}/complete`,
              payload,
              itemNames.processingJob,
              {success: 'ei.processing.complete.success', failed: 'ei.processing.complete.failed'}
            )
            .then(() => {
              this.setState({submitting: false});
              if (afterSubmit === 'print') {
                this.showPrinter();
              } else {
                this.props.actions.goBack();
              }
            })
            .catch(() => this.setState({submitting: false}));
        } else {
          return this.props.actions
            .postItem(
              `/api/processing_jobs/${id}/save_draft`,
              payload,
              itemNames.processingJob,
              {success: 'ei.processing.draft.success', failed: 'ei.processing.draft.failed'}
            )
            .then(() => {
              this.setState({
                submitting: false,
                submitDraft: false,
              });
              this.showPrinter();
            })
            .catch(() => this.setState({submitting: false}));
        }
      })
      .catch(e => {
        this.setState({submitting: false});
        throw e;
      });
  }

  getSubmitData (formData) {
    if (this.props.integrationState.isMetrc) { // If using metric, warning if missing tags
      if (formData) { // Check for missing state identifiers and warn
        const outputsInViolation = formData.outputs.reduce((acc, output) => {
          if (output.state_integration_tracking_id === undefined) acc.push(output);
          if (output.state_integration_tracking_id && output.state_integration_tracking_id.trim() === '') acc.push(output);
          return acc;
        }, []);
        formData.outputs.forEach((output) => {
          output.finished_at = output.finished ? moment().format('YYYY-MM-DD HH:mm:ss') : undefined;
        });
        if (outputsInViolation.length) { // Launch warning and save formData
          this.setState({showWarning: true, outputsInViolation, formData});
          return false;
        }
      } else { // Load formData from state on return from warning
        formData = this.state.formData;
      }
    }

    // Get plant data
    const { inventoryItems, processingJob } = this.props;
    const inputItems = inventoryItems.filter(item => processingJob.inputs.some(input => input.item_id === item.id));
    let source_strain = [];
    let plant_batch_number = [];
    let harvest_batch_number = [];
    let harvested_at = [];

    inputItems.forEach((item) => {
      source_strain = source_strain.concat(item.source_strain ? item.source_strain.split(',') : []);
      plant_batch_number = plant_batch_number.concat(item.plant_batch_number ? item.plant_batch_number.split(',') : []);
      harvest_batch_number = harvest_batch_number.concat(item.harvest_batch_number ? item.harvest_batch_number.split(',') : []);
      harvested_at = harvested_at.concat(item.harvested_at ? item.harvested_at.split(',') : []);
    });

    // Dedupe and stringify
    source_strain = [...new Set(source_strain.filter((y) => y))].join(',');
    plant_batch_number = [...new Set(plant_batch_number.filter((y) => y))].join(',');
    harvest_batch_number = [...new Set(harvest_batch_number.filter((y) => y))].join(',');
    harvested_at = [...new Set(harvested_at.filter((y) => y))].join(',');

    source_strain = source_strain ? source_strain : null;
    plant_batch_number = plant_batch_number ? plant_batch_number : null;
    harvest_batch_number = harvest_batch_number ? harvest_batch_number : null;
    harvested_at = harvested_at ? harvested_at : null;

    const outputs = formData.outputs.map((output) => {
      return {
        ...output,
        source_strain,
        plant_batch_number,
        harvest_batch_number,
        harvested_at,
        finished_at: output.finished && output.finished_dirty ? moment().format('YYYY-MM-DD HH:mm:ss') : undefined,
      };
    });

    return {
      ...formData,
      outputs,
    };
  }

  setNegativeConfirmationState(state) {
    this.setState({
      showNegativeInvConfirmation: state
    });
  }

  showPrinter() {
    this.printLabel();
    this.setState({showPrinter: true});
  }

  printLabel(data) {
    const labelTag = this.props.submitDraft ? 'production_inv_package_tag_label_all' : 'inv_package_tag';
    const printerParam = {procId: this.props.processingJob.id};
    this.setState({labelTag: labelTag, params: printerParam});
  }

  hidePrinter() {
    this.setState({showPrinter: false});
  }

  hideModal() {
    this.setState({showWarning: false});
  }

  completeSave() {
    this.setState({showWarning: false});
    this.onSubmit();
  }

  render() {
    const {
      notes,
      phases,
      status,
      isDraft,
      weights,
      products,
      locations,
      batchName,
      employees,
      equipment,
      trackingIds,
      ingredients,
      outputItemIds,
      initialValues,
      productionRuns,
      userPermissions,
      is_cannabis_mix,
      isManufacturing,
      ingredientsCost,
      integrationState,
      adjustmentReasons,
      ingredientOptions,
      isReservationEnabled,
      ingredientsStandardCost,
      isAllowNegativeInventory,
      waProcessingInventoryTypes,
      arePackagesValidForProcessing,
      isLabelEnabledForNoCompletedProcess,
      hasPackagesTags,
      complianceSettings,
      isPaRemediationLabelsEnabled
    } = this.props;
    const {loading, submitting, showPrinter, showNegativeInvConfirmation, labelTag, params} = this.state;
    const message = `common.form.${submitting ? 'saving' : 'loadingData'}`;

    return (
      <FormWrapper
        title='ei.inventory.actions.completeProcessing'
        goBack={this.props.actions.goBack}
        className='complete-processing-page'>
        <InProgressOverlay isActive={loading || submitting} translate={true} message={message}/>
        <ModalWrapper
          title='Invalid Tags Warning!'
          onHide={this.hideModal}
          completeSave={this.completeSave}
          showModal={this.state.showWarning}
          outputsInViolation={this.state.outputsInViolation}
          products={products}
          Component={IntegrationInvalidWarning}
        />
        <NegativeInventoryAlert confirmationState={showNegativeInvConfirmation}/>
        <CompleteProcessingFormWrapper
          form={form}
          notes={notes}
          arePackagesValidForProcessing={arePackagesValidForProcessing}
          onSubmit={this.onSubmit}
          initialValues={initialValues}
          is_cannabis_mix={is_cannabis_mix}
          isManufacturing={isManufacturing}
          hasPackagesTags={hasPackagesTags}
          employees={employees}
          equipment={equipment}
          batchName={batchName}
          products={products}
          loading={loading}
          locations={locations}
          productionRuns={productionRuns}
          integrationState={integrationState}
          trackingIds={trackingIds}
          phases={phases}
          weights={weights}
          ingredients={ingredients}
          ingredientOptions={ingredientOptions}
          ingredientsCost={ingredientsCost}
          ingredientsStandardCost={ingredientsStandardCost}
          status={status}
          isDraft={isDraft}
          outputItemIds={outputItemIds}
          showPrinter={this.showPrinter}
          userPermissions={userPermissions}
          adjustmentReasons={adjustmentReasons}
          isAllowNegativeInventory={isAllowNegativeInventory}
          waProcessingInventoryTypes={waProcessingInventoryTypes}
          isReservationEnabled={isReservationEnabled}
          isLabelEnabledForNoCompletedProcess={isLabelEnabledForNoCompletedProcess}
          complianceSettings={complianceSettings}
          isPaRemediationLabelsEnabled={isPaRemediationLabelsEnabled}
        />
        <PrinterModal
          ref='printerModal'
          showPrinter={showPrinter}
          hidePrinter={this.hidePrinter}
          labelTag={labelTag}
          labelIds={outputItemIds}
          params={params}
        />
      </FormWrapper>
    );
  }
}

CompleteProcessingPage.propTypes = {
  actions: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    ensureGetUnpaginatedData: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    getDataByPost: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired,
    fetchMetrcTrackingIdsForSelectInput: PropTypes.func.isRequired,
    addMessage: PropTypes.func.isRequired
  }).isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  employees: PropTypes.array.isRequired,
  equipment: PropTypes.array.isRequired,
  batchName: PropTypes.string,
  processingJob: PropTypes.object.isRequired,
  processingType: PropTypes.object.isRequired,
  inventoryItems: PropTypes.array.isRequired,
  is_cannabis_mix: PropTypes.bool,
  isManufacturing: PropTypes.bool,
  hasPackagesTags: PropTypes.bool,
  outputSubcategoryIds: PropTypes.array.isRequired,
  outputItemIds: PropTypes.array.isRequired,
  initialValues: PropTypes.object,
  products: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  productionRuns: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
  trackingIds: PropTypes.array.isRequired,
  phases: PropTypes.array.isRequired,
  weights: PropTypes.object.isRequired,
  isDraft: PropTypes.bool,
  ingredients: PropTypes.array.isRequired,
  ingredientOptions: PropTypes.object.isRequired,
  ingredientsCost: PropTypes.number,
  ingredientsStandardCost: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  status: PropTypes.string,
  submitDraft: PropTypes.bool,
  afterSubmit: PropTypes.string,
  validateTrackingIdsAvailability: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
  isValidProcessingType: PropTypes.bool.isRequired,
  arePackagesValidForProcessing: PropTypes.bool,
  userPermissions: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.object.isRequired,
  ]),
  adjustmentReasons: PropTypes.array,
  isLabelEnabledForNoCompletedProcess: PropTypes.bool,
  complianceSettings: PropTypes.object,
  isPaRemediationLabelsEnabled: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  const {
    batchName,
    inputs,
    outputs,
    ingredients,
    ingredientsStandardCost,
    submitDraft,
    afterSubmit,
    is_cannabis_mix,
  } = selector(
    state,
    'batchName',
    'inputs',
    'outputs',
    'ingredients',
    'ingredientsStandardCost',
    'afterSubmit',
    'submitDraft',
    'is_cannabis_mix'
  );
  const initialValues = getCompleteProcessingInitialValues(state);
  const roundQty = roundQtyCurried(state);
  const weights = getWeights(inputs, outputs, roundQty);
  initialValues.total_weight_used = weights.inputWeight;
  const isValidProcessingType = checkIngredientsUom(state);
  return {
    notes: get(initialValues, 'notes', []),
    submitDraft,
    afterSubmit,
    batchName,
    ingredientsStandardCost,
    initialValues,
    ingredients: initialValues.ingredients || [],
    is_cannabis_mix,
    isManufacturing: getModulesState(state).hasManufacturing,
    adjustmentReasons: state[dataNames.adjustmentReasons],
    processingJob: state[itemNames.processingJob],
    processingType: state[itemNames.processingType],
    inventoryItems: state[dataNames.inventoryItems],
    products: state[dataNames.products],
    phases: state[dataNames.phases],
    productionRuns: state.productionRuns,
    integrationState: getIntegrationState(state),
    trackingIds: state.trackingIds,
    locations: getFlattenedLocations(state),
    weights,
    ingredientOptions: getIngredientOptions(state),
    ingredientsCost: getIngredientsCost(ingredients || []),
    employees: getCurrentFacilityUserOptions(state),
    equipment: getEquipments(state),
    outputItemIds: (initialValues.outputs || []).map(o => o.item_id),
    status: initialValues.status,
    timezone: getCurrentTimezone(state),
    isDraft: isDraftJob(outputs),
    isAllowNegativeInventory: isAllowNegativeInventory(state),
    isValidProcessingType,
    arePackagesValidForProcessing: areValidPackagesForProcessing(state)(initialValues.inputs),
    outputSubcategoryIds: getProcessingJobOutputSubcategoryIds(state),
    userPermissions: getCurrentUserPermissions(state),
    waProcessingInventoryTypes: state[dataNames.waProcessingInventoryTypes],
    isReservationEnabled: getIsReservationEnabled(state),
    isLabelEnabledForNoCompletedProcess: getIsLabelEnabledForNoCompletedProcess(state),
    hasPackagesTags: hasPackagesTags(state),
    complianceSettings: getInventoryComplianceSettings(state),
    roundQty: roundQty,
    itemMasterServiceFirstIsEnabled: isFeatureEnabled(state)('feature_item_master_listing_service_first'),
    isPaRemediationLabelsEnabled: isLeafPaConfigPackClosedLoopFacility(state) && isFeatureEnabled(state)('feature_pa_hb_1024_remediation_labels'),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    goBack, ensureGetUnpaginatedData, getUnpaginatedData, getItem, getDataByPost, postItem, setItem, unsetItem,
    fetchMetrcTrackingIdsForSelectInput, addMessage, unsetData,
  };
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default reduxMetrcIdAvailability(connect(mapStateToProps, mapDispatchToProps)(CompleteProcessingPage));
