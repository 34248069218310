import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import {requiredFieldValidation} from '../../common/form/redux-form/validations';
import {isPrePack} from '../../../selectors/itemMastersSelectors';
import {GR, EA} from '../../../constants/uoms';

const validate = (values, props) => {
  const errors = {};
  const {integrationState: {isBiotrack}} = props;
  // We want all products to be selected and we want each product to be selected once (except for BioTrack).
  // Also, we want the UOM of the selected product to match the UOM of the integrated transfer product.
  if (values.products && values.products.length) {
    errors.products = [];
    const usedItemMasters = [];
    const usedItemMasterIds = [];
    const packagesCount = values.products.reduce((acc, product) => {
      if (!acc[product.product_name]) {
        acc[product.product_name] = {count: 0};
      }
      acc[product.product_name].count++;
      return acc;
    }, {});
    values.products.forEach((product, index) => {
      errors.products[index] = {};
      const itemMasterId = product.item_master_id;
      const productName = product.product_name;
      if (isBiotrack && !product.transfer_uom) {
        const integrationInventoryType = get(product,'packages.0.inventory_type');
        product.transfer_uom = get(props.uomByInventoryType, integrationInventoryType);
      }
      if (itemMasterId) {
        if (!isBiotrack && packagesCount[productName].item_master_id && packagesCount[productName].item_master_id !== itemMasterId) {
          errors.products[index].item_master_id = I18n.t('supplyChainMapping.form.alreadyMappedForAnother');
        }
        else if (!isBiotrack && usedItemMasters.filter(itemMaster => itemMaster.itemMasterId === itemMasterId
            && (productName && itemMaster.productName !== productName)).length
            ) {
          errors.products[index].item_master_id = I18n.t('supplyChainMapping.form.uniqueProductError');
        }
        else if (packagesCount[productName].item_master_id && !packagesCount[productName].count) {
          errors.products[index].item_master_id = I18n.t('supplyChainMapping.form.quantityMismatchError');
        }
        else if (Array.isArray(props.itemMasterOptions)) {
          const itemMasterOption = props.itemMasterOptions.find(option => option.value === itemMasterId);
          if (itemMasterOption) {
            let default_uom = itemMasterOption.default_uom;

            //LEAF PrePack case: use GR
            if (values.isLeaf && isPrePack(itemMasterOption)) default_uom = GR;

            //BioTrack PrePack case: use EA
            if (isBiotrack && isPrePack(itemMasterOption)) default_uom = EA;

            if (isBiotrack && product.transfer_uom.includes(default_uom)) return true;
            if (default_uom !== product.transfer_uom && product.transfer_uom) {
              switch (true) {
              case isBiotrack && default_uom === EA && !product.transfer_uom.includes(EA):
                errors.products[index].item_master_id = I18n.t('supplyChainMapping.form.biotrackUomMismatchErrorBulk');
                break;
              default:
                errors.products[index].item_master_id = I18n.t('supplyChainMapping.form.uomMismatchError', {
                  internalUom: default_uom,
                  partnerUom: product.transfer_uom
                });
              }
            }
          }
        }
        packagesCount[productName] = {item_master_id: itemMasterId, count: packagesCount[productName].count--};
        usedItemMasters.push({itemMasterId, productName});
        if (usedItemMasterIds.findIndex(id => (id === itemMasterId)) === -1) {
          usedItemMasterIds.push(itemMasterId);
        }
      }
      else {
        errors.products[index].item_master_id = requiredFieldValidation(itemMasterId);
      }
    });
    const allLinesWasFilled = (usedItemMasters.length === values.products.length);
    const allProductsWasUsed = (usedItemMasterIds.length === props.itemMasterOptions.length);
    if (isBiotrack && allLinesWasFilled && !allProductsWasUsed) {
      errors._error = I18n.t('supplyChainMapping.form.allPOProductsHaveToBeUsed');
    }
  }

  return errors;
};

export default validate;
