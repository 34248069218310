import React from 'react';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import PropTypes from 'prop-types';
import ReactSelectInput from '../common/form/ReactSelectInput';
import CheckBoxInput from '../common/form/CheckBoxInput';

const AutoPrintSetting = (props) => {
  const {options, labelTags, autoprintIsEnabled, autoprintLabelsChecked} = props;

  const tagsWithSize = labelTags.map(tag => ({
    value: tag.tag,
    text: tag.name + ' ' + tag.width + 'x' + tag.height
  }));

  const disableBlock = (autoprintIsEnabled !== '1');
  const disableLabelSelect = disableBlock || !autoprintLabelsChecked;
  const blockClassName = disableBlock ? 'sub-table disabled-row' : 'sub-table';

  return (
    <React.Fragment>
      <table style={{width: '100%'}}>
        <tbody>
        <tr>
          <td>
            {I18n.t('retail.salesSettings.enableAutoPrinting')}
            <p>
              <small className='text-muted'>{I18n.t('retail.salesSettings.autoPrintingDescription')}</small>
            </p>
          </td>
          <td style={{width: '142px', paddingLeft: '8px'}}>
            <Field
              name='order_auto_printing_is_enabled'
              component={ReactSelectInput}
              props={{
                options: options,
                valueKey: 'value',
                textKey: 'text'
              }}
            />
          </td>
        </tr>
        </tbody>
      </table>
      <table className={blockClassName}>
        <tbody>
        <tr>
          <td style={{width: '36px'}}>
            <Field
              name='order_auto_printing_receipts'
              component={CheckBoxInput}
              props={{
                size: 'lg',
                disabled: disableBlock,
              }}
            />
          </td>
          <td style={{paddingLeft: 0}}>
            <label htmlFor='order_auto_printing_receipts'>{I18n.t('retail.salesSettings.receipt')}</label>
          </td>
          <td> </td>
          <td> </td>
        </tr>
        <tr>
          <td>
            <Field
              name='order_auto_printing_labels'
              component={CheckBoxInput}
              props={{
                size: 'lg',
                disabled: disableBlock,
              }}
            />
          </td>
          <td style={{paddingLeft: 0}}>
            <label htmlFor='order_auto_printing_labels'>{I18n.t('retail.salesSettings.patientLabels')}</label>
          </td>
          <td style={{fontSize: '13px', textAlign: 'right'}}>{I18n.t('retail.salesSettings.selectDefaultLabel')}</td>
          <td style={{width: '320px', paddingRight: 0}}>
            <Field
              name='order_auto_printing_default_label'
              component={ReactSelectInput}
              props={{
                options: tagsWithSize,
                valueKey: 'value',
                textKey: 'text',
                disabled: disableLabelSelect,
              }}
            />
          </td>
        </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};


AutoPrintSetting.propTypes = {
  options: PropTypes.array.isRequired,
  labelTags: PropTypes.array,
  autoprintIsEnabled: PropTypes.string,
  autoprintLabelsChecked: PropTypes.bool,
};

export default AutoPrintSetting;