import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack} from 'react-router-redux';

import {getFlattenedLocations} from '../../../selectors/locationsSelectors';
import * as itemNames from '../../../constants/itemNames';
import * as dataNames from '../../../constants/dataNames';
import * as apiActions from '../../../actions/apiActions';
import * as dataActions from '../../../actions/dataActions';
import {groupedByLot} from '../../../selectors/inventoryItemsSelectors';
import FormWrapper from '../../common/form/FormWrapper';
import CreateContainerForm from './CreateContainerForm'; // eslint-disable-line import/no-named-as-default

export class CreateContainerPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {now: new Date()};
  }

  componentWillMount() {
    const {selectedLotIds} = this.props;
    this.props.actions.unsetData(dataNames.inventoryItems);
    this.props.actions.postData(
      '/api/items/by_lot_ids',
      {},
      dataNames.inventoryItems,
      {failed: 'packages.modify.getPackages.failed'},
      {ids: selectedLotIds}
    );
    this.props.actions.getUnpaginatedData(
      '/api/location_hierarchy',
      dataNames.locations,
      {failed: 'locations.getLocations.failed'}
    );
    this.props.actions.getItem(
      '/api/containers/generate_id',
      itemNames.containerCode,
      {failed: 'containers.generateCode.failed'},
      null,
      () => {},
      (code) => ({code})
    );
  }

  handleSubmit(formData) {
    const {postItem, goBack} = this.props.actions;
    const items = [];
    formData.lots.forEach(lot =>
      lot.packages.forEach(pkg => {
        if (pkg.transacted_qty) {
          items.push({
            id: pkg.id,
            transacted_qty: pkg.transacted_qty
          });
        }
      })
    );
    const container = {
      container_code: formData.container_code,
      inventory_location_id: formData.inventory_location_id,
      items
    };
    postItem(
      '/api/containers',
      container,
      itemNames.container,
      {failed: 'containers.create.failed', success: 'containers.create.success'},
      {},
      () => goBack()
    );
  }

  render() {
    const {locations, lots, containerCode, actions: {goBack}} = this.props;
    const initialValues = {
      lots,
      creationDate: this.state.now,
      container_code: containerCode && containerCode.code || ''
    };

    return (
      <FormWrapper className='create-container-page' title={'containers.create.title'} goBack={goBack}>
        <CreateContainerForm
          onSubmit={this.handleSubmit}
          initialValues={initialValues}
          enableReinitialize={true}
          lots={lots}
          locations={locations}/>
      </FormWrapper>
    );
  }
}

CreateContainerPage.propTypes = {
  lots: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  selectedProductIds: PropTypes.array.isRequired,
  selectedLotIds: PropTypes.array.isRequired,
  containerCode: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    getItem: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired
  }).isRequired
};

function mapStateToProps(state, ownProps) {
  const {containerCode, selectedProducts} = state;

  return {
    lots: groupedByLot(state),
    locations: getFlattenedLocations(state),
    containerCode,
    selectedProductIds: selectedProducts.map(selectedProduct => selectedProduct.id),
    selectedLotIds: selectedProducts.map(selectedProduct => selectedProduct.lot_id),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, dataActions, {goBack});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateContainerPage);
