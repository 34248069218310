import React from 'react';
import PropTypes from 'prop-types';
import { Field, FormSection } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { Col, Row } from 'react-bootstrap';
import { WHOLESALE, RETAIL } from '../../../../constants/saleTypes';
import { RECREATIONAL, MEDICAL } from '../../../../constants/consumerTypes';

import AccordionPanel from '../../../common/AccordionPanel';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import OnlineAvailabilityPanel from './OnlineAvailabilityPanel';
import PricingPanel from './PricingPanel';
import ContentConcealer from '../../../common/concealers/ContentConcealer';

const yesNoOptions = [{ text: I18n.t('general.yes'), value: '1' }, { text: I18n.t('general.no'), value: '0' }];

const SalesInfoPanel = ({
  pricingOptions,
  formValues,
  pricingGroupOptions,
  pricing_type,
  onlineAvailabilityOptions,
  pricingWeights,
  facilityOptions,
  retailFacilityOptions,
  categoryId,
  pricingClasses,
  isLeafly,
  isWeedmapsIntegrator,
  isLockedProduct
}) => {
  return (
    <AccordionPanel title='products.form.salesInfo' className='form-panel sales-info'>
      <FormSection name='sales_attributes'>
        <Row>
          <Col xs={12}>
            <Row>
              <Col xs={6} sm={4} md={3}>
                <Field
                  name='pricing_type'
                  component={ReactSelectInput}
                  props={{
                    options: pricingOptions,
                    label: I18n.t('products.form.pricingType'),
                    disabled: true
                  }}
                />
              </Col>
            </Row>
            <hr />
          </Col>
        </Row>
      </FormSection>

      <OnlineAvailabilityPanel
        onlineAvailabilityOptions={onlineAvailabilityOptions}
        facilityOptions={retailFacilityOptions}
      />

      <PricingPanel
        title='products.form.medicalPrices'
        sale_type={RETAIL}
        consumer_type={MEDICAL}
        pricing_type={pricing_type}
        pricingWeights={pricingWeights}
        pricingClasses={pricingClasses}
        pricingGroupOptions={pricingGroupOptions}
        facilityOptions={facilityOptions}
        retailFacilityOptions={retailFacilityOptions}
        categoryId={categoryId}
        unitWeightUom={formValues.default_uom}
      />

      <ContentConcealer show={isLeafly}>
        <AccordionPanel defaultExpanded={true} title='products.form.leaflyAvailability'>
          <Row>
            <Col sm={3}>
              <Field
                name={'sales_attributes.leafly_share'}
                component={ReactSelectInput}
                props={{
                  options: yesNoOptions,
                  label: I18n.t('products.form.shareProductWithLeafly')
                }}
              />
            </Col>
          </Row>
        </AccordionPanel>
      </ContentConcealer>

      <ContentConcealer show={isWeedmapsIntegrator}>
        <AccordionPanel defaultExpanded={true} title='products.form.weedmapsAvailability'>
          <Row>
            <Col sm={3}>
              <Field
                name={'sales_attributes.weedmaps_share'}
                component={ReactSelectInput}
                props={{
                  options: yesNoOptions,
                  label: I18n.t('products.form.shareProductWithWeedmaps')
                }}
              />
            </Col>
          </Row>
        </AccordionPanel>
      </ContentConcealer>

      <PricingPanel
        title='products.form.recreationalPrices'
        sale_type={RETAIL}
        consumer_type={RECREATIONAL}
        pricing_type={pricing_type}
        pricingWeights={pricingWeights}
        pricingClasses={pricingClasses}
        pricingGroupOptions={pricingGroupOptions}
        facilityOptions={facilityOptions}
        retailFacilityOptions={retailFacilityOptions}
        categoryId={categoryId}
        unitWeightUom={formValues.default_uom}
      />

      <PricingPanel
        title='products.form.wholesalePrices'
        sale_type={WHOLESALE}
        consumer_type={null}
        pricing_type={pricing_type}
        pricingWeights={pricingWeights}
        pricingGroupOptions={pricingGroupOptions}
        facilityOptions={facilityOptions}
        retailFacilityOptions={retailFacilityOptions}
        categoryId={categoryId}
        pricingClasses={[]}
        unitWeightUom={formValues.default_uom}
      />
    </AccordionPanel>
  );
};

SalesInfoPanel.propTypes = {
  pricingOptions: PropTypes.array,
  pricingClasses: PropTypes.array.isRequired,
  pricingGroupOptions: PropTypes.array.isRequired,
  pricingWeights: PropTypes.array.isRequired,
  onlineAvailabilityOptions: PropTypes.array.isRequired,
  facilityOptions: PropTypes.array.isRequired,
  retailFacilityOptions: PropTypes.array.isRequired,
  pricing_type: PropTypes.oneOf(['', 'unit', 'weight', 'volume']),
  formValues: PropTypes.object.isRequired,
  categoryId: PropTypes.number,
  isLeafly: PropTypes.bool,
  isWeedmapsIntegrator: PropTypes.bool.isRequired,
  isLockedProduct: PropTypes.bool
};

export default SalesInfoPanel;
