import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { Col, Row } from 'react-bootstrap';
import timezoneOptions from '../../../constants/timeZoneOptions';
import { getFacilityTypesForPartner } from '../../../selectors/partnersSelectors';
import { biotrackLicenseOptions } from '../../../constants/integration/biotrack/biotrackLocationTypes';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import MultiselectInput from '../../common/form/MultiselectInput';
import TextInput from '../../common/form/TextInput';
import CountryField from '../../common/form/redux-form/CountryField';
import PostalCodeField from '../../common/form/redux-form/PostalCodeField';
import InternationalReduxPhoneInput from '../../common/form/redux-form/InternationalReduxPhoneInput';
import ContentConcealer from '../../common/concealers/ContentConcealer';
import { getLeafWaLicenseTypesOptions } from '../../../selectors/integrationSelectors';

const FacilityMainInfo = (props) => {
  const {
    change,
    facilityPath,
    integrationState,
    isOrgFacility,
    partner,
    currentFacility,
    initialCountry,
    currentValues,
    isModifyForm,
    closedLoopPartner
  } = props;
  const { isLeaf, isWaLeaf, isBiotrack, isCanada, isOklahoma, isMetrc } = integrationState;
  const integrationLabel = isBiotrack ? 'locationNumber' : 'stateIntegrationId';
  const filteredFacilityTypes = getFacilityTypesForPartner(currentValues, currentFacility);
  const leafWaLicenseOptions = getLeafWaLicenseTypesOptions();

  return (
    <div className='form-panel facility-main-info'>
      <Row>
        <Col md={4}>
          <Field
            name={`${facilityPath}.facility_name`}
            component={TextInput}
            props={{
              label: I18n.t('partners.createPartner.partnerNameDba'),
              placeholder: I18n.t('partners.createPartner.partnerName'),
              disabled: closedLoopPartner || (partner && partner.is_internal_partner),
              isRequired: true
            }}
          />
        </Col>
        <Col md={4}>
          <Field
            name={`${facilityPath}.legal_name`}
            component={TextInput}
            props={{
              label: I18n.t('partners.createPartner.legalBusinessEntity'),
              placeholder: I18n.t('partners.createPartner.entityName'),
              disabled: closedLoopPartner || (partner && partner.is_internal_partner)
            }}
          />
        </Col>
        <ContentConcealer show={isCanada || isOklahoma}>
          <Col md={4}>
            <Field
              name={`${facilityPath}.facility_types`}
              component={MultiselectInput}
              props={{
                label: I18n.t('partners.createPartner.partnerFacilityType'),
                options: filteredFacilityTypes,
                textKey: 'text',
                valueKey: 'value',
                defaultValue: [],
                isRequired: true
              }}
            />
          </Col>
        </ContentConcealer>
        {isLeaf || isBiotrack ? (
          <Col md={4}>
            <Field
              name={`${facilityPath}.state_integration_id`}
              component={TextInput}
              props={{
                label: I18n.t(`partners.createPartner.${integrationLabel}`),
                placeholder: I18n.t(`partners.createPartner.${integrationLabel}`),
                disabled: true
              }}
            />
          </Col>
        ) : (
          ''
        )}
        {isMetrc && (
          <Col md={4}>
            <Field
              name={`${facilityPath}.name_on_integrator`}
              component={TextInput}
              props={{
                label: I18n.t(`partners.createPartner.metrcPartnerName`),
                isRequired: true
              }}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col md={4}>
          <InternationalReduxPhoneInput
            name={`${facilityPath}.facility_phone`}
            countryCode={initialCountry}
            props={{
              label: I18n.t('partners.createPartner.phone'),
              disabled: closedLoopPartner || (partner && partner.is_internal_partner)
            }}
          />
        </Col>
        <Col md={4}>
          <Field
            name={`${facilityPath}.facility_email`}
            component={TextInput}
            props={{
              label: I18n.t('common.form.email'),
              placeholder: I18n.t('common.form.email'),
              disabled: closedLoopPartner || (partner && partner.is_internal_partner)
            }}
          />
        </Col>
        {isWaLeaf && (
          <Col md={4}>
            <Field
              name={`${facilityPath}.state_integration_type`}
              component={ReactSelectInput}
              props={{
                label: I18n.t('partners.createPartner.leafLicenseeType'),
                placeholder: I18n.t('partners.createPartner.leafLicenseeType'),
                options: leafWaLicenseOptions,
                disabled: closedLoopPartner || isModifyForm || (partner && partner.is_internal_partner)
              }}
            />
          </Col>
        )}
        {isBiotrack ? (
          <Col md={4}>
            <Field
              name={`${facilityPath}.state_integration_type`}
              component={ReactSelectInput}
              props={{
                label: I18n.t('partners.createPartner.biotrackLocationType'),
                placeholder: I18n.t('partners.createPartner.biotrackLocationType'),
                options: biotrackLicenseOptions,
                disabled: true
              }}
            />
          </Col>
        ) : (
          ''
        )}
      </Row>
      <Row>
        <Col md={4}>
          <Field
            name={`${facilityPath}.street_address_1`}
            component={TextInput}
            props={{
              label: I18n.t('common.form.address'),
              placeholder: I18n.t('common.form.address'),
              disabled: closedLoopPartner || (partner && partner.is_internal_partner),
              isRequired: isCanada
            }}
          />
        </Col>
        <Col md={4}>
          <Field
            name={`${facilityPath}.street_address_2`}
            component={TextInput}
            props={{
              label: I18n.t('common.form.address2'),
              placeholder: I18n.t('common.form.address2'),
              disabled: closedLoopPartner || (partner && partner.is_internal_partner)
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <Field
            name={`${facilityPath}.city`}
            component={TextInput}
            props={{
              label: initialCountry === 'AU' ? I18n.t('common.form.suburb') : I18n.t('common.form.city'),
              placeholder: initialCountry === 'AU' ? I18n.t('common.form.suburb') : I18n.t('common.form.city'),
              disabled: closedLoopPartner || (partner && partner.is_internal_partner),
              isRequired: isCanada
            }}
          />
        </Col>
        <CountryField
          change={change}
          name={`${facilityPath}.country_code`}
          stateFieldName={`${facilityPath}.province_code`}
          countryFieldSize={2}
          stateFieldSize={2}
          currentCountry={initialCountry}
          disabled={closedLoopPartner || (partner && partner.is_internal_partner)}
          isRequired={isCanada}
          stateProps={{ isRequired: isCanada }}
        />
        <Col md={2}>
          <PostalCodeField
            name={`${facilityPath}.postal_code`}
            countryCode={initialCountry}
            disabled={closedLoopPartner || (partner && partner.is_internal_partner)}
          />
        </Col>
        {isOrgFacility && (
          <Col md={3}>
            <Field
              name={`${facilityPath}.timezone`}
              component={ReactSelectInput}
              props={{
                label: I18n.t('common.form.timezone'),
                options: timezoneOptions,
                placeholder: I18n.t('customers.create.select'),
                disabled: closedLoopPartner || (partner && partner.is_internal_partner)
              }}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

FacilityMainInfo.propTypes = {
  facilityPath: PropTypes.string.isRequired,
  facility: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isOrgFacility: PropTypes.bool.isRequired,
  integrationState: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  initialCountry: PropTypes.string,
  currentFacility: PropTypes.object,
  partner: PropTypes.object.isRequired,
  currentValues: PropTypes.object.isRequired,
  isModifyForm: PropTypes.bool,
  disable: PropTypes.bool,
  closedLoopPartner: PropTypes.bool.isRequired,
};

FacilityMainInfo.defaultProps = {
  isOrgFacility: true,
};

export default FacilityMainInfo;
