import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import get from 'lodash.get';
import { change } from 'redux-form';
import { Dropdown, InputGroup } from 'react-bootstrap';

const ReduxUomSelect = (props) => {
  const onItemChange = get(props, 'onItemChange', () => {});
  const options = get(props, 'options', []);
  const value = get(props, 'input.value');
  const defaultUom = get(props, 'defaultUom', 'GR');
  const fieldName = get(props, 'input.name');
  const form = get(props, 'meta.form');
  const selectedValue = typeof value === 'string' && value.trim() !== '' ? value : defaultUom;

  return (
    <Dropdown as={InputGroup.Prepend}>
      <Dropdown.Toggle id={`dropdown_button_id_${fieldName}`} disabled={props.disabled}>
        {I18n.t(`uoms.${selectedValue}.abbrev`)}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {options.map((option, index) => (
          <Dropdown.Item
            key={index}
            onClick={() => {
              props.meta.dispatch(change(form, fieldName, option.value));
              onItemChange(option, index);
            }}
          >
            {option.text}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

ReduxUomSelect.propTypes = {
  meta: PropTypes.object,
  disabled: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  onItemChange: PropTypes.func,
  defaultUom: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

ReduxUomSelect.defaultProps = {
  onItemChange: () => {},
  options: [
    { text: 'KG', value: 'KG' },
    { text: 'LB', value: 'LB' },
    { text: 'OZ', value: 'OZ' },
    { text: 'GR', value: 'GR' },
    { text: 'MG', value: 'MG' },
  ],
  defaultUom: 'GR',
  disabled: false,
};

export default ReduxUomSelect;
