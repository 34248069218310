import React from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import get from 'lodash.get';
import {set} from 'lodash';
import {connect} from 'react-redux';
import {push, goBack} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import {clearSelectedDataAction} from '../../../actions/selectedDataActions';
import {getUnpaginatedData, getItem, postItem, putData, postData} from '../../../actions/apiActions';
import {handleComplexSelectRow} from '../../../actions/helpers/selectedDataHelper';
import {getSelectedCustomerGroupsIds} from '../../../selectors/customerGroupsSelectors';
import {getPayload} from '../../../selectors/forms/customerGroupFormSelectors';
import * as dataNames  from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import FormWrapper from '../../common/form/FormWrapper';
import InternationalDecimalStatic from '../../common/form/InternationalDecimalStatic';
import CustomerGroupList from './CustomerGroupList';
import ConnectedCustomerGroupEditor from './CustomerGroupEditor';
import {getRewardSettings} from '../../../selectors/rewardsSelectors';

import SingleActionColumn from '../../common/grid/columns/SingleActionColumn';
import * as p from '../../../constants/permissions';
import ModalWrapper from '../../common/ModalWrapper';

const getAt = get;
const tabStatuses = {inactive: 'inactive', active: 'active'};

export class CustomerGroupListingPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.goToCreateGroup = this.goToCreateGroup.bind(this);
    this.goToEditGroup = this.goToEditGroup.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.goBack = this.goBack.bind(this);
    this.switchTab = this.switchTab.bind(this);
    this.getSectionTitle = this.getSectionTitle.bind(this);
    this.renderConfirmModal = this.renderConfirmModal.bind(this);
    this.inactivateCustomerGroup = this.inactivateCustomerGroup.bind(this);

    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.onCreate = this.onCreate.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.beforeSubmit = this.beforeSubmit.bind(this);

    const tabs = [
      {
        id: 'activeTab',
        eventKey: 'active',
        title: 'retail.customerGroupListing.active',
        path: '/retail/customer-groups/active',
        actions: [
          {
            id: 'createCustomerGroup',
            func: this.goToCreateGroup,
            text: 'retail.customerGroupListing.create',
            requireSelect: false
          },
          {
            id: 'editCustomerGroup',
            func: this.goToEditGroup,
            text: 'retail.customerGroupListing.edit',
            requireSelect: true
          }
        ]
      },
      {
        id: 'inactiveTab',
        eventKey: 'inactive',
        title: 'retail.customerGroupListing.inactive',
        path: '/retail/customer-groups/inactive',
        actions: [
          {
            id: 'createCustomerGroup',
            func: this.goToCreateGroup,
            text: 'retail.customerGroupListing.create',
            requireSelect: false
          },
          {
            id: 'editCustomerGroup',
            func: this.goToEditGroup,
            text: 'retail.customerGroupListing.edit',
            requireSelect: true
          }
        ]
      }
    ];

    const columns = [
      {
        name: I18n.t('retail.customerGroupListing.groupName'),
        dataId: 'group_name',
        width: '250px',
        headerAlign: 'left',
        dataAlign: 'left',
      },
      {
        name: I18n.t('retail.customerGroupListing.standard'),
        dataId: 'standard_discount_percent',
        formatter: (cell, row) => {
          return (
            <InternationalDecimalStatic
              fractionPartSize={2}
              suffix='%'>{row.standard_discount_percent}
              </InternationalDecimalStatic>);
        }
      },
      {
        name: I18n.t('retail.customerGroupListing.type'),
        dataId: 'is_rewards_program_group',
        formatter: (cell, row) => {
          if (row.is_rewards_program_group) {
            return I18n.t('retail.customerGroupListing.typeReward');
          }
          return I18n.t('retail.customerGroupListing.typeStandard');
        }
      },
      {
        name: I18n.t('retail.customerGroupListing.visitFrequencyToQualify'),
        dataId: 'standard_discount_percent',
        formatter: (cell, row) => {
          if(!row.is_rewards_program_group) return 'NA';
          return `${row.min_visit_frequency} / ${row.min_visit_frequency_days}`;
        }
      },
      {
        name: I18n.t('retail.customerGroupListing.pointsToQualify'),
        dataId: 'standard_discount_percent',
        formatter: (cell, row) => {
          if(!row.is_rewards_program_group) return 'NA';
          return row.min_accrued_points;
        }
      },
      {
        name: I18n.t('retail.customerGroupListing.spendingToQualify'),
        dataId: 'standard_discount_percent',
        formatter: (cell, row) => {
          if(!row.is_rewards_program_group) return 'NA';
          return row.min_total_spend;
        }
      },
    ];

    const activeColumns = [
      ...columns,
      {
        hidden: false,
        dataSort: false,
        permissions: [p.manage_customer_groups],
        formatter: SingleActionColumn({
          isDisabled: (row) => row.isActiveRewardGroup,
          permissions: [p.manage_customer_groups],
          label: 'retail.customerGroupListing.inactivate.title',
          action: (e, row) => this.inactivateCustomerGroup(e, row)
        }),
        columnClassName: 'actions-column',
        width: '170px',
      }
    ];
    const inactiveColumns = [
      ...columns,
      {
        hidden: false,
        dataSort: false,
        permissions: [p.manage_customer_groups],
        formatter: SingleActionColumn({
          permissions: [p.manage_customer_groups],
          label: 'retail.customerGroupListing.activate.title',
          action: (e, row) => this.inactivateCustomerGroup(e, row)
        }),
        columnClassName: 'actions-column',
        width: '170px',
      }
    ];

    this.state = {
      mode: false,
      activeTab: props.params.status === 'inactive' ? tabStatuses.inactive : tabStatuses.active,
      tabs,
      columns,
      activeColumns,
      inactiveColumns,
      loading: false,
      showConfirmation: {
        show: false,
        onHide: null,
        onConfirm: null,
      },
    };

  }

  componentWillMount() {
    this.setPageMode(this.props);
  }

  componentWillReceiveProps(nextProps){
    this.setPageMode(nextProps);
  }

  setPageMode(props){
    const {status} = props.params;
    let mode = 'list';
    if (status && status === 'create') {
      mode = 'create';
    } else if (status && parseInt(status)) {
      mode = 'edit';
    }
    if(this.state.mode === mode) return false;
    this.setState({mode});
    if(mode === 'list') this.loadGroups();
    if(mode === 'edit') this.loadGroup(status);
  }

  /***
   * LOADERS
   */

  loadGroups(){
    this.setState({loading: true});
    this.props.actions.handleComplexSelectRow([], dataNames.customerGroups, 'clear');
    const messages = {failed: 'retail.customerGroupListing.loadGroupsFailed'};
    const active = this.state.activeTab === tabStatuses.inactive ? 0 : 1;
    this.props.actions.getItem('/api/rewards/setup', itemNames.rewardSettings, null, null, () => {
      this.props.actions.getUnpaginatedData('/api/consumer_groups', dataNames.customerGroups, messages, {active})
        .then(() => {this.setState({loading: false});}).catch(() => {this.setState({loading: false});});
    });


  }

  loadGroup(id){
    // moved to load groups since action is no activate/inactivate.
  }

  /***
   * ROUTE METHODS
   */

  goToCreateGroup() {
    this.props.actions.push('/retail/customer-groups/create');
  }

  goToEditGroup() {
    this.props.actions.push(`/retail/customer-groups/${this.props.selectedCustomerGroupsIds.shift()}`);
  }

  /****
   * CRUD METHODS
   */

  onCreate(formData){
    const payload = getPayload(formData);
    const messages = {success: 'retail.createCustomerGroups.success', failed: 'retail.createCustomerGroups.failed'};
    this.props.actions.postData('/api/consumer_groups/multiple', payload, 'noOp', messages, null, () => {
      this.props.actions.push('/retail/customer-groups/active');
      this.setState({activeTab: tabStatuses.active}, this.loadGroups);
    });
  }

  onUpdate(formData){
    const payload = getPayload(formData);
    const messages = {success: 'retail.modifyCustomerGroups.success', failed: 'retail.modifyCustomerGroups.failed'};
    this.props.actions.putData('/api/consumer_groups/multiple', payload, 'noOp', messages, null, () => {
      clearSelectedDataAction(dataNames.customerGroups);
      this.props.actions.push('/retail/customer-groups/active');
      this.setState({activeTab: tabStatuses.active}, this.loadGroups);
    });
  }

  /***
   * BEHAVIOR
   */

  onSubmit(formData) {
    return this
      .beforeSubmit(formData)
      .then(this.sendRequest);
  }

  sendRequest(formData) {
    return this[formData.customerGroups[0].id ? 'onUpdate' : 'onCreate'](formData);
  }

  beforeSubmit(formData) {
    return new Promise((resolve) => {
      const showPopup = (get(formData, 'customerGroups[0].is_rewards_program_group'));

      const onHide = () => {
        this.setState({ showConfirmation: { show: false }});
        resolve(formData);
      };

      const onConfirm = () => {
        this.setState({ showConfirmation: { show: false }});
        set(formData, 'customerGroups[0].assign_new_reward_group', true);
        resolve(formData);
      };

      if (!showPopup) {
        return resolve(formData);
      }

      this.setState({
        showConfirmation: {
          show: true,
          onHide: onHide,
          onConfirm: onConfirm,
        },
      });
    });
  }

  handleSelect(row) {
    const {selectedCustomerGroupsIds} = this.props;
    const action = selectedCustomerGroupsIds[0] === row.id ? 'remove' : 'set';
    this.props.actions.handleComplexSelectRow([row], dataNames.customerGroups, action);
  }

  goBack(){
    this.props.actions.goBack();
  }

  switchTab(activeTab) {
    const tab = this.state.tabs.find(tab => tab.eventKey === activeTab) || this.state.tabs[0];
    this.setState({activeTab}, this.loadGroups);
    this.props.actions.handleComplexSelectRow([], dataNames.customerGroups, 'clear');
    this.props.actions.push(tab.path);
  }

  inactivateCustomerGroup(event, data) {
    event.stopPropagation();
    const payload = {id: data.id, active: data.active === 1 ? 0 : 1};
    this.setState({loading: true});
    this.props.actions.putData(
      '/api/consumer_groups/multiple',
      [payload],
      dataNames.customerGroups,
      {
        success: data.active === 1 ? I18n.t('retail.customerGroupListing.inactivate.success') : I18n.t('retail.customerGroupListing.activate.success'),
        fail: data.active === 1 ? I18n.t('retail.customerGroupListing.inactivate.failed') : I18n.t('retail.customerGroupListing.activate.failed')
      }
    ).then(() => {this.loadGroups();}).catch(() => {this.loadGroups();});
  }

  getSectionTitle() {
    const {mode} = this.state;
    switch(mode) {
    case 'list':
      return 'retail.customerGroupListing.title';
    case 'create':
      return 'retail.customerGroupListing.create';
    default:
      return 'editCustomerGroup';
    }
  }

  renderConfirmModal() {
    const {showConfirmation} = this.state;

    const okayButtonProps = {
      show: true,
      onClick: showConfirmation.onConfirm,
      text: I18n.t('general.yes')
    };

    const cancelButtonProps = {
      show: true,
      onClick: showConfirmation.onHide,
      text: I18n.t('general.no')
    };

    return (
      <ModalWrapper
        Component={false}
        title={I18n.t('retail.createCustomerGroups.new_reward_group_title')}
        headerClass='bg-info-dark'
        onHide={showConfirmation.onHide}
        showModal={showConfirmation.show}
        okayButton={okayButtonProps}
        cancelButton={cancelButtonProps}
        dialogClassName='modal-sm'
        version={2}
      >
        <p>{I18n.t('retail.createCustomerGroups.assign_new_reward_group')}</p>
      </ModalWrapper>
    );
  }

  render() {
    const {activeTab, tabs, activeColumns, inactiveColumns, loading} = this.state;

    return (
      <FormWrapper title={this.getSectionTitle()} goBack={this.goBack} className='modify-customer-groups-page'>
        {
          this.state.mode === 'list'
            ? <div><CustomerGroupList
            handleSelect={this.handleSelect}
            activeTab={activeTab}
            switchTab={this.switchTab}
            tabs={tabs}
            columns={activeTab === tabStatuses.active ? activeColumns : inactiveColumns}
            loading={loading}
          />
            <div className='text-muted' style={{fontSize: 'smaller', textAlign: 'right'}}>Groups with inactive buttons disabled are groups in use as rewards earnings groups.  Remove them from rewards setup to be able to inactivate them.</div>
          </div>
            : <ConnectedCustomerGroupEditor customerGroupId={this.props.params.status} onSubmit={this.onSubmit} />
        }
        {this.renderConfirmModal()}
      </FormWrapper>
    );
  }
}

CustomerGroupListingPage.propTypes = {
  actions: PropTypes.shape({
    getUnpaginatedData: PropTypes.func.isRequired,
    handleComplexSelectRow: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    clearSelectedDataAction: PropTypes.func.isRequired,
  }),
  customerGroups: PropTypes.array.isRequired,
  selectedCustomerGroupsIds: PropTypes.array.isRequired,
  params: PropTypes.object,
};

function mapStateToProps(state) {
  const rewardSettings = getRewardSettings(state);
  const customerGroups = state[dataNames.customerGroups].map((group) => {
    group.isActiveRewardGroup = get(group, 'is_rewards_program_group', false) || getAt(rewardSettings, 'reward_eligible_reward_groups.value', []).indexOf(group.id) !== -1;
    return group;
  });
  const selectedCustomerGroupsIds = getSelectedCustomerGroupsIds(state);
  return {
    customerGroups,
    selectedCustomerGroupsIds,
    rewardSettings,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {getUnpaginatedData, handleComplexSelectRow, push, goBack, clearSelectedDataAction, getItem, postItem, postData, putData};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerGroupListingPage);
