import omit from 'lodash.omit';
import get from 'lodash.get';

import * as uoms from '../../../../constants/uoms';
import {formatCurrencyDecimal} from '../../../../util/formatHelper';
import {convertFormInputDateToDbDate} from '../../../../util/dateHelpers';


export const preparePayload = (formData, isCreating, {
  partnerFacility, timezone, salesOrder, isMappingRequiredByPlatform, hasMedicatedLines, isReservationEnabled, vendor_facility_type, integrationState
}) => {
  const excludeFromFormData = ['balance','partner_id','bulk_items','prepackItems','total','bulkItems','prePackItems','unitItems', 'payment_type', 'amount', 'register_id', 'payment_date', 'oddMoney'];

  if (isCreating) {
    excludeFromFormData.push('transfer_type');
  } else {
    excludeFromFormData.push('sales_order_number');
  }

  const {contactName, contactPhone, contactEmail} = getContactInformation(partnerFacility);

  const payload = Object.assign({},
    omit(formData, excludeFromFormData),
    {
      is_medicated_order: formData.contains_medicated,
      order_total: formData.order_total ? formatCurrencyDecimal(formData.order_total) : '0.00',
      date_ordered: convertFormInputDateToDbDate(formData.date_ordered, timezone),
      date_expected: convertFormInputDateToDbDate(formData.date_expected, timezone),
      payment_date: convertFormInputDateToDbDate(formData.payment_date, timezone),
      partner_facility_name: partnerFacility && partnerFacility.facility_name ? partnerFacility.facility_name : undefined,
      partner_contact_id: partnerFacility && partnerFacility.first_contact ? partnerFacility.first_contact.id : undefined,
      partner_contact_name: contactName,
      partner_contact_phone: contactPhone,
      partner_contact_email: contactEmail,
      payments: getPayments(formData, timezone),
      lines: getLineItems(formData, isCreating)
    }
  );

  if (integrationState.isCanada || integrationState.isOklahoma) {
    payload.vendor_facility_type = vendor_facility_type;
  } 

  if (isCreating) {
    payload.transfer_type = formData.transfer_type ? formData.transfer_type : undefined;
  } else {
    payload.delete_payments = getPaymentsForDeletion(formData, salesOrder);
    payload.delete_lines = getLineItemsForDeletion(formData, salesOrder);
  }

  if (!isReservationEnabled) {
    payload.reserve_inventory = 0;
  }

  // Delete partner_invoice_number from payload if it shouldn't be shown
  const showPartnerInvoiceNumber = !(isMappingRequiredByPlatform && hasMedicatedLines);

  if (!showPartnerInvoiceNumber) {
    delete payload.partner_invoice_number;
  }
  
  return payload;
};


const formatBulkLineForSubmission = (lineItem) => {
  // NOTE: Below comment ported from SalesOrderPage(s) on this commit
  // @TODO: We need to figure out why "NaN" is being submitted as line_item_price for bulk and unit-priced items. For now, we just convert "NaN" to undefined.
  return Object.assign({}, omit(lineItem, 'itemMaster'), {
    uom: get(lineItem, 'itemMaster.default_uom', uoms.GR),
    item_master_id: lineItem.itemMaster.id,
    line_item_price: lineItem.line_item_price && !isNaN(lineItem.line_item_price) ? lineItem.line_item_price : 0,
    unit_price: lineItem.unit_price && !isNaN(lineItem.unit_price) ? lineItem.unit_price : 0
  });
};


const formatUnitLineForSubmission = (lineItem) => {
  return Object.assign({}, omit(lineItem, 'itemMaster'), {
    uom: 'EA',
    item_master_id: lineItem.itemMaster.id,
    line_item_price: lineItem.line_item_price && !isNaN(lineItem.line_item_price) ? lineItem.line_item_price : 0,
    unit_price: lineItem.unit_price && !isNaN(lineItem.unit_price) ? lineItem.unit_price : 0
  });
};


const formatPrepackLineForSubmission = (lineItem, isCreating) => {
  // NOTE: Below comment ported from SalesOrderPage(s) on this commit
  // In the UI we show a rounded unit price when it is derived from line_item_price / quantity
  // The BE needs a non rounded number for COGS so we recalc here to get an unrounded number
  const quantity = parseFloat(lineItem.qty);
  const calculatedUnitPrice = lineItem.line_item_price == null ? 0 : parseFloat(lineItem.line_item_price) / parseFloat(quantity);
  const unitPrice = (calculatedUnitPrice !== parseFloat(lineItem.unit_price))
    ? calculatedUnitPrice
    : lineItem.unit_price;

  const prepackItem = {
    uom: get(lineItem, 'itemMaster.default_uom', uoms.GR),
    item_master_id: lineItem.itemMaster.id,
    unit_price: unitPrice,
    line_item_price: lineItem.line_item_price == null ? 0 : lineItem.line_item_price,
    qty: parseFloat(lineItem.qty),
    meta: (lineItem.meta) ? lineItem.meta : undefined,
  };

  if (isCreating) {
    prepackItem.subitems = getSubItemsForPrepackOnCreate(lineItem);
  } else {
    prepackItem.id = lineItem.id;
    prepackItem.subitems = getSubItemsForPrepackOnModify(lineItem);
    prepackItem.subitems = getSubItemsForPrepackOnModify(lineItem);
  }

  return prepackItem;
};


// NOTE: the difference between this function and its "Modify" counterpart below it, is that create only collects (and sends) subitems (aka weights) that have quantities specified. Perhaps the backend could handle either way, allowing us to consolidate?
const getSubItemsForPrepackOnCreate = (lineItem) => {
  return lineItem.weights && lineItem.weights.length
    ? lineItem.weights.reduce((previous, current, j) => {
      //we had a bug that would create duplicate item master children, i.e 2 76g item master childrens
      //Sev 3 would inactive one of the item master children, so a filter is added here so the inactive one does not get sent in the payload
      const child_item_master = lineItem.itemMaster.children
        .filter(child => child.active)
        .find(child => current.prepack_weight.id === child.prepack_weight_id);

      return previous.concat(
        current.qty && parseInt(current.qty) && child_item_master
          ? {qty: current.qty, item_master_id: child_item_master.id, unit_price: current.unit_cost == null ? 0 : current.unit_cost}
          : []);}, [])
    : [];
};


const getSubItemsForPrepackOnModify = (lineItem) => {
  return lineItem.weights && lineItem.weights.length
    ? lineItem.weights.reduce((previous, current, j) => {
      const child_item_master = lineItem.itemMaster.children.find(child => current.item_master_id === child.id);
      const item_master_id = child_item_master && child_item_master.id;

      return previous.concat({id: current.id, qty: current.qty, item_master_id, unit_price: current.unit_cost == null ? 0 : current.unit_cost});
    }, [])
    : [];
};


export const getContactInformation = (partnerFacility) => {
  let contactName = partnerFacility && partnerFacility.first_contact
    ? partnerFacility.first_contact.first_name + ' ' + partnerFacility.first_contact.last_name
    : undefined;

  if (partnerFacility) {
    if(partnerFacility.facility_name) {
      contactName = partnerFacility.facility_name;
    } else if (partnerFacility.partner && partnerFacility.partner.name) {
      contactName = partnerFacility.partner.name;
    }
  }

  let contactPhone = partnerFacility && partnerFacility.first_contact
    ? partnerFacility.first_contact.phone_number : undefined;
  if (partnerFacility && partnerFacility.facility_phone) {
    contactPhone = partnerFacility.facility_phone;
  }
  let contactEmail = partnerFacility && partnerFacility.first_contact
    ? partnerFacility.first_contact.email_address : undefined;
  if (partnerFacility && partnerFacility.facility_email) {
    contactEmail = partnerFacility.facility_email;
  }

  return {
    contactName,
    contactPhone,
    contactEmail
  };
};

const getLineItems = (formData, isCreating) => {
  const lines = formData.lines && formData.lines.length ? formData.lines.map((item, i) => {
    // NOTE: Below comment ported from SalesOrderPage(s) on this commit
    // Handles case where item removed but not completely and unsure why
    if(item.itemMaster === undefined) return {deleteMe: 1};

    if (get(item, 'itemMaster.inventory_attributes.is_ingredient')) {
      return {
        ...omit(item, 'itemMaster'),
      };
    }

    if (item.itemMaster.itemType === 'bulk') return formatBulkLineForSubmission(item);
    if (item.itemMaster.itemType === 'unit') return formatUnitLineForSubmission(item);
    return formatPrepackLineForSubmission(item, isCreating);
  }) : [];

  // NOTE: Below comment ported from SalesOrderPage(s) on this commit
  //@TODO:  Clear up removal so it is clean.  When item removed it's not completely removed
  return lines.filter( (line) => line.deleteMe === undefined);
};


const getLineItemsForDeletion = (formData, salesOrder) => {
  const newLineIds = formData.lines.map(line => line.id);

  return salesOrder.lines.filter(line => newLineIds.indexOf(line.id) === -1).map(line => line.id);
};


const getPayments = (formData, timezone) => {
  if (!(formData.payments && formData.payments.length)) return [];

  const payments = formData.payments.reduce((result, payment) => {
    if (isNaN(parseFloat(payment.amount)) || !payment.amount) return result;

    result.push({
      ...payment,
      payment_date: convertFormInputDateToDbDate(payment.payment_date, timezone)
    });

    return result;
  }, []);

  if (formData.oddMoney && formData.oddMoney.amount <= -0.01) {
    payments.push({
      ...formData.oddMoney,
      payment_date: convertFormInputDateToDbDate(formData.oddMoney.payment_date, timezone),
    });
  }

  return payments;
};


const getPaymentsForDeletion = (formData, salesOrder) => {
  const newPaymentIds = formData.payments.map(payment => payment.id);

  if (formData.oddMoney && formData.oddMoney.amount <= -0.01 && formData.oddMoney.id) {
    newPaymentIds.push(formData.oddMoney.id);
  }

  return salesOrder.payments.filter(payment => newPaymentIds.indexOf(payment.id) === -1).map(payment => payment.id);
};

