import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import ReactSelectInput from '../ReactSelectInput';
import {ensureGetUnpaginatedData} from '../../../../actions/apiActions';
import * as dataNames from '../../../../constants/dataNames';
import {isCanadaFacility} from '../../../../selectors/facility/getFacilityCountry';
import {requiredFieldValidation} from './validations';

class PackagePurposeField extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
  }

  componentWillMount() {
    this.props.actions.ensureGetUnpaginatedData('/api/packages/purposes', dataNames.purposes);
  }

  render() {
    const {name, purposes, isCanadaFacility, ...props} = this.props;
    return (
      <Field
        name={name}
        component={ReactSelectInput}
        validate={(value) => isCanadaFacility ? requiredFieldValidation(value, 'common.form.purposes') : false}
        isRequired={isCanadaFacility}
        props={{
          label: I18n.t('common.form.purposes'),
          options: purposes,
          textKey: 'name',
          valueKey: 'code',
          placeholder: I18n.t('common.form.select'),
          ...props
        }}
      />
    );
  }
}

PackagePurposeField.propTypes = {
  name: PropTypes.string.isRequired,
  purposes: PropTypes.array,
  isCanadaFacility: PropTypes.bool,
  props: PropTypes.object
};

PackagePurposeField.defaultProps = {
  name: 'purpose',
  props: {}
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ensureGetUnpaginatedData}, dispatch)
  };
}

function mapStateToProps(state, ownProps) {
  const {purposes} = state;
  return {
    purposes,
    isCanadaFacility: isCanadaFacility(state)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (PackagePurposeField);
