import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';
import {Alert, Button, Col, Row} from 'react-bootstrap';
import {FaExclamationTriangle, FaMinus} from 'react-icons/fa';
import {Field} from 'redux-form';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import BulkLineItem from './BulkLineItem';
import UnitLineItem from './UnitLineItem';
import PrePackLineItem from './PrePackLineItem';
import ProductTypeFields from '../common/ProductTypeFields';

const hasAvailableAmount = (item) => {
  const available_qty = (item.reservations || []).filter(r => r.active === 1)
    .reduce((acc, reservation) => {
      const reservedQuantityBase = (reservation.qty_base) ? parseFloat(reservation.qty_base) : 0;
      return acc - reservedQuantityBase;
    }, parseFloat(item.qty_base));
  return available_qty > 0;
};

const OrderLines = (props) => {

  const {
    fields, change, itemMasters, storageLocations, prepackWeights, showContainerInputs, showStorageLocations,
    formValues, partnerId, transferRecord, inventoryList, unusedItemMasters, order_type, contains_medicated,
    meta, transferLinesLimit, addTouchedIM, touchedIM, blur, metrcIsImported, linesCount, integrationState,
    limitUtahLabTransfersToOneProduct, categories
  } = props;

  // Yucky.  There is a problem in utah auto supply chain mapping with multiple items that is a big lift; this is the stop gap
  const utahLabsLimitToOneProduct = linesCount > 0 && integrationState.isUtah && order_type === 'lab' && limitUtahLabTransfersToOneProduct;

  // If there are more than xx itemMaster require to select a category first, before showing item masters dropdown options
  const requireCategory = itemMasters.length > 2000;

  const getFormValue = (fieldName) => {
    return get(formValues, fieldName);
  };

  const isEditableLineTotal = (index) => {
    const currentField = fields.get(index);
    return currentField.editableLineTotal;
  };

  //Get non-reserved inventory by item master and child item masters
  const getInventory = (itemMaster) => {
    const ids = [itemMaster.id].concat((itemMaster.children || []).map(child => child.id));
    return inventoryList.filter(item => ids.indexOf(item.item_master_id) > -1 && hasAvailableAmount(item));
  };

  const onLineItemRemoved = (line, index) => {
    const weights = get(fields.get(index), 'weights', []);

    if (weights.length) {
      weights.forEach((weight, index) => {
        if (weight.qty > 0) {
          change(`${line}.weights[${index}].qty`, 0);
        }
      });
    }

    change(`${line}.qty`, 0);

    fields.remove(index);
  };

  const hideItemPrices = (order_type === 'lab');

  const getFilteredItemMasters = (categoryField, itemMasterOptions) => {
    if (requireCategory) {
      const category_id = getFormValue(categoryField);
      itemMasterOptions = itemMasterOptions.filter(itemMaster => itemMaster.category_id === category_id);
    }
    return itemMasterOptions.map((itemMaster) => {
      return {id: itemMaster.id, name: itemMaster.name};
    });
  };

  return (
    <div>
      <Row>
        <Col xs={12}>
          {fields.map((line, index) => {
            const currentField = fields.get(index);
            const uom = get(currentField, 'itemMaster.default_uom');
            const itemMasterType = get(currentField, 'itemMaster.itemType');
            const isPrePack = itemMasterType === 'prepack';
            if(itemMasterType === undefined) return null;
            const inventory = getInventory(currentField.itemMaster);
            const hasInventory = inventory.length > 0;
            const numberOfLinesIsNotValid = transferLinesLimit && index >= transferLinesLimit;
            const hasEachInventory = isPrePack && currentField.itemMaster.children && currentField.itemMaster.children
              .filter(child => prepackWeights && child.prepack_weight_id && prepackWeights[child.prepack_weight_id])
              .every(child => inventory.some(item => item.item_master_id === child.id));
            //need for show validation message
            if (touchedIM && !touchedIM[currentField.itemMaster.id]) {
              addTouchedIM(currentField.itemMaster.id);
              blur(`${line}.item_master_id`);
            }
            const disabled = metrcIsImported;

            return (<Row key={index}>
              <Col lg={4}>
                <Field name={`${line}.item_master_id`} component={ReactSelectInput} props={{
                  label: I18n.t('purchaseOrders.form.item'),
                  options: itemMasters,
                  textKey: 'name',
                  valueKey: 'id',
                  disabled: true
                }}
                />

                {(showStorageLocations) && (
                  <Field
                    name={`${line}.expected_storage_location_id`}
                    component={ReactSelectInput}
                    props={{
                      label: I18n.t('purchaseOrders.form.storageLocation'),
                      options: storageLocations
                    }}
                  />
                )}
                {isPrePack && !hasInventory ? <p className='has-item-warning'>{I18n.t('salesOrders.noInventoryPrePack')}</p> : null}
                {isPrePack && hasInventory && !hasEachInventory ? <p className='has-item-warning'>{I18n.t('salesOrders.partialInventoryPrePack')}</p> : null}
                {!isPrePack && !hasInventory ? <p className='has-item-warning'>{I18n.t('salesOrders.noInventory')}</p> : null}
                {numberOfLinesIsNotValid ?
                  <Row>
                    <Col md={11}>
                      <Alert variant='danger'>
                        <FaExclamationTriangle/>
                        <span className='padding-left has-error'>{meta.error}</span>
                      </Alert>
                    </Col>
                  </Row> : null}
              </Col>
              {!numberOfLinesIsNotValid && partnerId && itemMasterType === 'bulk' ?
                <BulkLineItem
                  onEditableToggle={(value) => {
                    change(`${line}.editableLineTotal`, value);
                  }}
                  change={change}
                  editableLineTotal={isEditableLineTotal(index)}
                  transferRecord={transferRecord}
                  line={line}
                  disabled={disabled}
                  uom={uom}
                  hideItemPrices={hideItemPrices}
                /> : null}
              {!numberOfLinesIsNotValid && partnerId && itemMasterType === 'unit'  ?
                <UnitLineItem
                  showContainerInputs={showContainerInputs}
                  change={change}
                  transferRecord={transferRecord}
                  disabled={disabled}
                  uom={uom}
                  hideItemPrices={hideItemPrices}
                  line={line}
                /> : null}
              {!numberOfLinesIsNotValid && partnerId && itemMasterType === 'prepack' ?
                <PrePackLineItem
                  showContainerInputs={showContainerInputs}
                  itemMasterChildren={currentField.itemMaster.children}
                  formValues={formValues}
                  prepackWeights={prepackWeights}
                  unit_price={parseFloat(currentField.unit_price) || 0}
                  change={change}
                  index={index}
                  fields={fields}
                  disabled={disabled}
                  uom={uom}
                  onEditableToggle={(value) => change(`${line}.editableLineTotal`, value)}
                  editableLineTotal={isEditableLineTotal(index)}
                  transferRecord={transferRecord}
                  hideItemPrices={hideItemPrices}
                  line={line}
                /> : null}

              <ProductTypeFields orderType={order_type} line={line} change={change} field={currentField} contains_medicated={contains_medicated}/>
              {
                (transferRecord || metrcIsImported)
                ? null
                  : (
                  <Col xs={12} className='add-item'>
                    <div className='float-right'>
                      <Button variant='primary' size='sm' onClick={() => onLineItemRemoved(line, index)}><FaMinus/></Button>
                    </div>
                  </Col>
                )
              }


              <Col xs={12}><hr/></Col>
            </Row>);
          })}
          {
            transferRecord || utahLabsLimitToOneProduct
            ? null
              : (
              <Col sm={6} md={4} lg={4}>
                {linesCount ? <p style={{ color: 'red' }}>{I18n.t('salesOrders.warning',{size:linesCount, items:linesCount > 1 ? 'items' : 'item', articulo:linesCount > 1 ? 'articulos' : 'articulo'})}</p> : ''}
                {requireCategory &&
                  <Field name={`category_id`} component={ReactSelectInput} props={{
                    label: I18n.t('purchaseOrders.form.category'),
                    options: categories,
                    textKey: 'name',
                    valueKey: 'id',
                    onChange: (id) => {change(`category_id`, id);}
                  }}/>
                }
                <Field
                  name='item_master_id'
                  component={ReactSelectInput}
                  props={{
                    label: I18n.t('purchaseOrders.form.item'),
                    options: getFilteredItemMasters(`category_id`, unusedItemMasters),
                    textKey: 'name',
                    valueKey: 'id',
                    onChange: (itemMasterId) => {
                      // Row is overwritten by middleware when pricing is pulled.  Set to defaults here for perceived system speed.
                      setTimeout(() => { // Let the select close before starting next block of work.
                        const itemMaster = Object.assign({}, itemMasters.find((itemMaster) => itemMaster.id === itemMasterId, {itemType: 'bulk'}));
                        fields.push({
                          item_master_id: itemMasterId,
                          itemMaster,
                          line_item_price: '0.00',
                          unit_price: '0.00',
                          editableLineTotal: false,
                          qty: undefined,
                        });
                        // Reset category_id field
                        change('category_id', null);
                      }, 20);
                    },
                    disabled: requireCategory && !getFormValue('category_id')
                  }}
                />
                {requireCategory && !getFormValue('category_id') &&
                  <div style={{ color: 'red' }}>Select a category first</div>
                }
              </Col>
            )
          }
          {
            !utahLabsLimitToOneProduct
              ? null
              : (<Row><Col md={12}>
                  <Alert variant='info'>
                    {I18n.t('cultivation.purchaseOrders.form.utahTempLimit')}
                  </Alert>
              </Col></Row>)
          }
      </Col>
      </Row>
    </div>);
};

OrderLines.propTypes = {
  itemMasters: PropTypes.array.isRequired,
  unusedItemMasters: PropTypes.array.isRequired,
  storageLocations: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  lineTypes: PropTypes.array.isRequired,
  selectedItemMasters: PropTypes.array.isRequired,
  prepackWeights: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  showContainerInputs: PropTypes.bool,
  showStorageLocations: PropTypes.bool,
  formValues: PropTypes.object.isRequired,
  inventoryList: PropTypes.array.isRequired,
  partnerId : PropTypes.number,
  transferRecord: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ]).isRequired,
  order_type: PropTypes.string,
  contains_medicated: PropTypes.number,
  meta: PropTypes.object.isRequired,
  transferLinesLimit: PropTypes.number,
  blur: PropTypes.func,
  addTouchedIM: PropTypes.func,
  touchedIM: PropTypes.object,
  metrcIsImported: PropTypes.bool,
  linesCount: PropTypes.number.isRequired,
  integrationState: PropTypes.object.isRequired,
  limitUtahLabTransfersToOneProduct: PropTypes.bool.isRequired,
  categories: PropTypes.array
};

OrderLines.defaultProps = {
  showContainerInputs: true,
  showStorageLocations: true,
};

export default OrderLines;
