import forEach from 'lodash.foreach';
import {I18n} from 'react-redux-i18n';
import * as validations from '../../common/form/redux-form/validations';

const validate = (values, props) => {
  let errors = validators.nonIntegration(values, props);

  if (props.integrationState) {
    forEach(props.integrationState, (isEnabled, integrator) => {
      if (isEnabled && typeof validators[integrator] === 'function') {
        errors = validators[integrator](values, props, errors);
      }
    });
  }

  return errors;
};

const validators = {
  nonIntegration: (values) => {
    const errors = {};

    if (!(values.newWeight)) {
      errors.newWeight = I18n.t('harvestBatch.form.newWeightError');
    }

    if (!errors.newWeight) {
      errors.newWeight = validations.maxValidation(values.newWeight, 'harvestBatch.modify.newWeight', values.wet_weight_harvest);
    }

    errors.reportWaste = validations.maxValidation(values.reportWaste, 'harvestBatch.modify.reportWaste', values.wet_weight_harvest);
    errors.newOtherMaterialWeight = values.newOtherMaterialWeight ? undefined : I18n.t('harvestBatch.form.newWeightError');
    errors.inventory_location_id = validations.requiredFieldValidation(values.inventory_location_id, I18n.t('plants.form.storageArea'));

    return errors;
  },

  isMetrc: (values, props, errors) => {
    if (values.reportWaste > 0) {
      errors.waste_type = validations.requiredFieldValidation(values.waste_type, I18n.t('plants.form.harvestWasteType'));
    }

    return errors;
  },

  isWaLeaf: (values, props, errors) => {

    if (!errors.newOtherMaterialWeight) {
      errors.newOtherMaterialWeight = validations.maxBetween(
        values.newOtherMaterialWeight,
        values.current_other_material_weight,
        I18n.t('plants.form.otherMaterialExceeded', { new_weight: parseFloat(values.newOtherMaterialWeight), current_weight: parseFloat(values.current_other_material_weight) })
      );
    }

    if (!errors.newWeight) {
      errors.newWeight = validations.maxBetween(
        values.newWeight,
        values.current_weight,
        I18n.t('plants.form.flowerWeightExceeded', { new_weight: parseFloat(values.newWeight), current_weight: parseFloat(values.current_weight) })
      );
    }

    return errors;
  }
};

export default validate;
