module.exports =  [
  {
    id: 1,
    groupName: 'Group 1',
    category:'Flower'
  },
  {
    id: 2,
    groupName: 'Group 2',
    category:'Flower'
  },
  {
    id: 3,
    groupName: 'Group 3',
    category:'Flower'
  },
  {
    id: 4,
    groupName: 'Group 4',
    category:'Flower'
  },
  {
    id: 5,
    groupName: 'Group 5',
    category:'Flower'
  },
  {
    id: 6,
    groupName: 'Group 6',
    category:'Flower'
  }
];
