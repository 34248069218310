import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import {Col, Row, Table, Card} from 'react-bootstrap';
import WillRender from '../../common/concealers/WillRender';
import NumericInput from '../../common/form/NumericInput';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import MultiselectInput from '../../common/form/MultiselectInput';
import CheckBoxInput from '../../common/form/CheckBoxInput';
import SubmitSection from '../../common/form/SubmitSection';

const ComplianceSettingsForm = (props) => {
  const formName = 'inv_compliance_settings';

  const {
    handleSubmit, submitting, pristine, facility, facilities, disableEnableReservationsOption, disableNegativeInventoryOption, hasIntegrator,
    hasManufacturing, isNormal, isMetrc, showPendingStatesSetting, newProductMasterIsEnabled, newPackagingJobsIsEnabled
  } = props;

  const settings = {
    actionSettings: {
      submit: {
        submitting,
        pristine,
        text: I18n.t('common.form.save')
      }
    }
  };

  const hasPermission = () => {
    return hasManufacturing && isNormal;
  };

  const yesNoOptions = [
    {text: I18n.t('general.yes'), value: '1'},
    {text: I18n.t('general.no'), value: '0'},
  ];

  const warnBlockOptions = [
    {text: I18n.t('retail.complianceSettings.warn'), value: 'warn'},
    {text: I18n.t('retail.complianceSettings.block'), value: 'block'},
  ];

  const warnBlockExpiredProductOptions = [
    {text: I18n.t('retail.complianceSettings.warn'), value: 'warn'},
    {text: I18n.t('retail.complianceSettings.block'), value: 'block'},
  ];

  const archiveDaysOptions = [
    {text: I18n.t('general.no'), value: '0'},
    {text: I18n.t('retail.complianceSettings.afterDays', {days: 60}), value: '60'},
    {text: I18n.t('retail.complianceSettings.afterDays', {days: 90}), value: '90'},
  ];

  // Name is used as key to text for labeling
  const onHandSettings = [
    {name: 'grams_on_hand_warn', inputType: 'number'},
    {name: 'grams_on_hand_block', inputType: 'number'},
    {name: 'coupled_grams_on_hand_warn', inputType: 'number'},
    {name: 'coupled_grams_on_hand_block', inputType: 'number'},
    {name: 'require_on_hand_product_expire_date', inputType: yesNoOptions},
    {name: 'product_on_hold_action', inputType: warnBlockOptions},
    {name: 'block_expired_product_action', inputType: warnBlockExpiredProductOptions},
    {name: 'include_infused_weight_on_hand', inputType: yesNoOptions},
    {name: 'allow_label_without_completing', inputType: yesNoOptions, isVisibleSetting : hasPermission()},
    {name: 'archive_inactive_inventory_after', inputType: archiveDaysOptions},
    {name: 'medicated_weight_auto_suffix', inputType: yesNoOptions, isVisibleSetting : newProductMasterIsEnabled},
    {name: 'append_brand_to_item_master_name', inputType: yesNoOptions, isVisibleSetting : newProductMasterIsEnabled},
  ];

  const packagingSettings = [
    {name: 'inv_packaging_ingredients', inputType: yesNoOptions, isVisibleSetting : newPackagingJobsIsEnabled}
  ];

  const reservationsSettings = [
    {name: 'enable_reservations', inputType: yesNoOptions, disabled: disableEnableReservationsOption},
    {name: 'allow_negative_inventory', inputType: yesNoOptions, disabled: disableNegativeInventoryOption},
  ];

  const inventoryItemLocks = [
    {name: 'enable_inventory_item_locks', inputType: yesNoOptions}
  ];


  const getSettingType = (setting) => {
    return typeof setting.inputType === 'string' ? setting.inputType : 'select';
  };

  const getComponent = (setting) => {
    const settingtype = getSettingType(setting);
    switch(settingtype) {
    case 'numeric':
      return NumericInput;
    case 'number':
      return NumericInput;
    case 'checkbox':
      return CheckBoxInput;
    default:
      return ReactSelectInput;
    }
  };

  const getComponentProps = (setting) => {
    const props = getSettingType(setting) === 'numeric'
      ? {
        disabled: Boolean(setting.disabled),
      }
      : {
        options: setting.inputType,
        clearable: false,
        groupClassName: 'thin',
        disabled: Boolean(setting.disabled),
      };

    if (setting.onChange) {
      props['onChange'] = setting.onChange;
    }
    return props;
  };

  const getSettingName = (setting) => {
    return facility && facility.type
      ? facility.type === 'manufacturing'
        ? `inv_compliance_settings.${setting.name}`
        : setting.name
      : setting.name;
  };

  const plantDataChange = (v) => {
    const val = Number(v);
    props.actions.change(formName, getSettingName({name: 'update_transfer_manifest_inventory_receipt_with_additional_data'}), val);

    props.actions.change(formName, getSettingName({name: 'display_source_strain_source_batch_harvest_date_for_lots_on_transfer_manifest_and_inventory_receipt'}), val);
    props.actions.change(formName, getSettingName({name: 'display_sublines_with_a_total_for_each_uom_group_of_items_transferred_on_transfer_manifest'}), val);

    return val;
  };

  const enablePlantDataSettings = () => {
    return !parseInt(get(props.formValues, getSettingName({name: 'update_transfer_manifest_inventory_receipt_with_additional_data'})));
  };

  const plantDataSettings = [
    {name: 'update_transfer_manifest_inventory_receipt_with_additional_data', inputType: yesNoOptions, onChange: plantDataChange},
    {name: 'display_source_strain_source_batch_harvest_date_for_lots_on_transfer_manifest_and_inventory_receipt', inputType: 'checkbox', disabled: enablePlantDataSettings() },
    {name: 'display_sublines_with_a_total_for_each_uom_group_of_items_transferred_on_transfer_manifest', inputType: 'checkbox', disabled: enablePlantDataSettings() },
  ];

  return(
    <form onSubmit={handleSubmit} className='alert-settings-page'>
      <Row>

        {/*Left Hand Card*/}
        <Col xs={12} md={6}>
          <Card>
            <Card.Header>
              <h3 className='panel-title'>{I18n.t('complianceSettings.inventory.onHandTitle')}</h3>
            </Card.Header>
            <Table striped={true} bordered={true}>
              <tbody>
              {
                onHandSettings.map((setting, index) => {
                  return get(setting, 'isVisibleSetting', true) && (
                    <tr key={index}>
                      <td style={{verticalAlign: 'middle'}}>
                        {I18n.t(`complianceSettings.inventory.${setting.name}`)}:
                      </td>
                      <td className='form-group-no-margin'>
                        <Field
                          name={getSettingName(setting)}
                          component={getComponent(setting)}
                          props={getComponentProps(setting)}
                        />
                      </td>
                    </tr>
                  );
                })
              }
              {
                packagingSettings.map((setting, index) => {
                  return get(setting, 'isVisibleSetting', true) && (<tr key={index}>
                    <td style={{verticalAlign: 'middle'}}>
                      {I18n.t(`complianceSettings.inventory.${setting.name}`)}:
                    </td>
                    <td className='form-group-no-margin'>
                      <Field
                        name={getSettingName(setting)}
                        component={getComponent(setting)}
                        props={getComponentProps(setting)}
                      />
                    </td>
                  </tr>);
                })
              }
              </tbody>
            </Table>
          </Card>
        </Col>

        {/*Right hand panel*/}
        <Col xs={12} md={6}>
          <Card>
            <Card.Header>
              <h3 className='panel-title'>{I18n.t('complianceSettings.inventory.reservationsTitle')}</h3>
            </Card.Header>
            <Table striped={true} bordered={true}>
              <tbody>
              {
                reservationsSettings.map((setting, index) => {
                  return (<tr key={index}>
                    <td style={{verticalAlign: 'middle'}}>
                      {I18n.t(`complianceSettings.inventory.${setting.name}`)}:
                    </td>
                    <td className='form-group-no-margin'>
                      <Field
                        name={getSettingName(setting)}
                        component={getComponent(setting)}
                        props={getComponentProps(setting)}
                      />
                    </td>
                  </tr>);
                })
              }
              </tbody>
            </Table>
          </Card>

          <WillRender ifTrue={isMetrc && showPendingStatesSetting}>
            <Card>
              <Card.Header>
                <h3 className='panel-title'>{I18n.t('complianceSettings.inventory.inventoryLockSettings')}</h3>
              </Card.Header>
              <Table striped={true} bordered={true}>
                <tbody>
                {
                  inventoryItemLocks.map((setting, index) => {
                    return (<tr key={index}>
                      <td style={{verticalAlign: 'middle'}}>
                        {I18n.t(`complianceSettings.inventory.${setting.name}`)}:
                        {
                          setting === 'enable_inventory_item_locks'
                            ? null
                            : (<div className='text-muted' style={{fontSize: 'smaller', marginTop: '5px'}}>
                              {I18n.t(`complianceSettings.inventory.inventoryLockWarning`)}
                            </div>)
                        }
                      </td>
                      <td className='form-group-no-margin' style={{minWidth: '120px'}}>
                        <Field
                          name={getSettingName(setting)}
                          component={getComponent(setting)}
                          props={getComponentProps(setting)}
                        />
                      </td>
                    </tr>);
                  })
                }
                </tbody>
              </Table>
            </Card>
          </WillRender>

          <WillRender ifTrue={!hasIntegrator}>
            <Card>
              <Card.Header>
                <h3 className='panel-title'>{I18n.t('complianceSettings.inventory.plantDataTitle')}</h3>
              </Card.Header>
              <Table striped={true} bordered={true}>
                <tbody>
                {
                  plantDataSettings.map((setting, index) => {
                    return (<tr key={index}>
                      <td style={{verticalAlign: 'middle'}}>
                        {I18n.t(`complianceSettings.inventory.${setting.name}`)}:
                      </td>
                      <td className='form-group-no-margin' width='30%'>
                        <Field
                          name={getSettingName(setting)}
                          component={getComponent(setting)}
                          props={getComponentProps(setting)}
                        />
                      </td>
                    </tr>);
                  })
                }
                </tbody>
              </Table>
            </Card>
          </WillRender>

          {/*Manufacturing only facility coupling*/}
          <WillRender ifTrue={facility.type === 'manufacturing'}>
            <Card>
              <Card.Header>
                <h3 className='panel-title'>{I18n.t('complianceSettings.inventory.facilityCoupling')}</h3>
              </Card.Header>
              <div>

                  <Col md={12}>
                    <div style={{padding: '15px'}}>
                    For warning and blocking purposes in compliance, this facility is coupled with the facilities selected below.  (Leave blank for none)
                    </div>
                    <div>
                      <Field
                        name='core_facility_coupling'
                        className='coupled_facility'
                        component={MultiselectInput}
                        props={{
                          options: facilities,
                          valueKey: 'id',
                          textKey: 'name'
                        }}
                      />
                    </div>
                  </Col>
                  <div style={{clear:'both'}} />
              </div>
            </Card>
          </WillRender>

        </Col>
      </Row>
      <SubmitSection settings={settings}/>
    </form>
    );
};

ComplianceSettingsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  facility: PropTypes.object.isRequired,
  facilities: PropTypes.array.isRequired,
  actions: PropTypes.array,
  formValues: PropTypes.array,
  disableEnableReservationsOption: PropTypes.bool.isRequired,
  disableNegativeInventoryOption: PropTypes.bool.isRequired,
  hasIntegrator: PropTypes.bool.isRequired,
  hasManufacturing: PropTypes.bool,
  isNormal: PropTypes.bool,
  isMetrc: PropTypes.bool.isRequired,
  showPendingStatesSetting: PropTypes.bool.isRequired,
  newProductMasterIsEnabled: PropTypes.bool.isRequired,
  newPackagingJobsIsEnabled: PropTypes.bool.isRequired
};

export default ComplianceSettingsForm;
