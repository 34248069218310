import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {Row, Col} from 'react-bootstrap';
import {getCollection} from '../../../../../../selectors/configurableSelectors';

const CheckboxSettingsRow  = (props) => {

  const currentValue = props.input && props.input.value && Array.isArray(props.input.value) ? props.input.value.map((item) => item) : [];
  const collection = props.staticCollection ? props.staticCollection.map(item => item) : props.collection.map(item => item);
  if(!collection.find((item) => item[props.nameField] === 'All')) collection.unshift({
    [props.nameField]: 'All',
    id: 0
  });

  const onChange = (item) => {
    if (item.id === 0) {
      return currentValue.length === collection.length - 1
        ? props.input.onChange([])
        : props.input.onChange(collection.filter((item) => item.id !== 0).map((item) => item.id));
    }
    const index = currentValue.indexOf(item.id);
    if (index !== -1) {
      currentValue.splice(index, 1);
    } else {
      currentValue.push(item.id);
    }
    props.input.onChange(currentValue);
  };

  const isChecked = (item) => {
    if(item.id === 0) return currentValue.length === (collection.length - 1);
    return currentValue.indexOf(item.id) !== -1;
  };

  return (<tr>
    <td colSpan={2}>
      <Row>
        <Col md={12} style={{marginBottom: '8px'}}>
          {I18n.t(`retail.rewardSetup.fields.${props.setting.text}`)}:
        </Col>
        {
          collection.length === 1
            ? <Col md={12} style={{textAlign: 'center'}}><div className='alert-warning' style={{padding: '5px'}}>No patient groups found.  Rewards will apply to all patients.</div></Col>
            : collection.map((item, index) => {
              return (
                  <Col key={index} md={collection.length > 4 ? 6 : 12}>
                    <label style={{width: '100%', cursor: 'pointer'}}>
                      <input type='checkbox' checked={isChecked(item)} onClick={() => onChange(item)}/>
                      &nbsp;{item[props.nameField]}
                    </label>
                  </Col>
                );
            })
        }
        {
          !props.footerText ? null : <Col md={12} className='text-muted'>{props.footerText}</Col>
        }
        {
          !props.meta.invalid
          ? null
            : <Col md={12} style={{marginBottom: '8px'}}><div className='text-danger'>
            {props.meta.error}
          </div></Col>
        }
      </Row>
    </td>
  </tr>);

};

CheckboxSettingsRow.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  nameField: PropTypes.string.isRequired,
  dataName: PropTypes.string.isRequired,
  setting: PropTypes.object.isRequired,
  collection: PropTypes.array,
  staticCollection: PropTypes.array,
  footerText: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
  return {
    collection: getCollection(state, ownProps),
  };
}

export default connect(mapStateToProps)(CheckboxSettingsRow);

