import PropTypes from 'prop-types';
import {reduxForm, formValueSelector} from 'redux-form';
import {connect} from 'react-redux';
import {CONNECTS_REQUEST_FORM} from '../../../../constants/forms';
import validate from './validation';
import ConnectsRequestForm from './ConnectsRequestForm';

const selector = formValueSelector(CONNECTS_REQUEST_FORM);

const ConnectsRequestReduxForm = reduxForm({
  form: CONNECTS_REQUEST_FORM,
  validate,
  enableReinitialize: true,
})(ConnectsRequestForm);

function mapStateToProps(state) {
  const facilities = selector(state, 'facilities');
  const allowedFields = [
    'place_orders',
    'view_inventory',
    'inherit_product_images',
    'inherit_product_pricing',
    'receive_communication'
  ];
  return {
    partnerId: selector(state, 'existing_partner_id'),
    facilities: facilities,
    allowedValues: allowedFields.reduce((acc, field) => {
      const fullField = `allowed_${field}`;
      acc[fullField] = selector(state, fullField);
      return acc;
    }, {}),
    requestedValues: allowedFields.reduce((acc, field) => {
      const fullField = `requested_${field}`;
      acc[fullField] = selector(state, fullField);
      return acc;
    }, {}),
    atLeastOneOptionChecked: allowedFields.reduce((acc, field) => {
      if(acc) return acc;
      const value = selector(state, `requested_${field}`);
      return (value) ? value : false;
    }, false),
    emailIsRequired: selector(state, 'requested_receive_communication'),
    mappedPartnerFacilityIds: facilities && Array.isArray(facilities)
      ? facilities.reduce((acc, facility) => {
        const mappedPartnerFacilityId = parseInt(facility.map_to_partner_facility_id);
        if(!isNaN(mappedPartnerFacilityId) && mappedPartnerFacilityId > 0){
          acc.push(mappedPartnerFacilityId);
        }
        return acc;
      }, [])
      : []
  };
}

const ConnectsRequestFormWrapper = connect(mapStateToProps)(ConnectsRequestReduxForm);

ConnectsRequestFormWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

export default ConnectsRequestFormWrapper;
