import PropTypes from 'prop-types';
import {reduxForm, formValueSelector} from 'redux-form';
import {connect} from 'react-redux';
import {formName} from '../formData';
import validate from './validate';
import RewardSettingsForm from './RewardSettingsForm';

const form = formName;
const selector = formValueSelector(form);

const RewardSettingsReduxForm = reduxForm({
  form,
  validate,
  enableReinitialize: true,
})(RewardSettingsForm);

function mapStateToProps(state, ownProps) {
  return {
    formSelector(field){
      return selector(state, field);
    },
    disabled: parseInt(selector(state, 'reward_record_points_based_on_sales')) === 0,
  };
}

const SettingsFormWrapperXYZ = connect(mapStateToProps)(RewardSettingsReduxForm);

SettingsFormWrapperXYZ.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  onSaveAndStay: PropTypes.func.isRequired,
};

export default SettingsFormWrapperXYZ;
