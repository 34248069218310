import {requiredFieldValidation} from '../../../common/form/redux-form/validations';

const validate = ({ qty_base, item_master_id, name, ingredients }) => {
  const errors = {
    name: requiredFieldValidation(name, 'ei.assemblies.form.name'),
    qty_base: requiredFieldValidation(qty_base, 'ei.assemblies.form.qty'),
    item_master_id: requiredFieldValidation(item_master_id, 'ei.assemblies.form.item')
  };

  return errors;
};

export default validate;
