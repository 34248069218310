import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import get from 'lodash.get';
import {Link} from 'react-router';
import {I18n} from 'react-redux-i18n';
import moment from 'moment';
import {getItem, getUnpaginatedData} from '../../../actions/apiActions';
import {setItem} from '../../../actions/itemActions';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import {convertDbDateToFormInputDate} from '../../../util/dateHelpers';
import RewardsEditorPOS from '../../retail/rewards/RewardsEditorPOS'; //eslint-disable-line
import PermissionWrapper from '../../common/PermissionWrapper';
import * as p from '../../../constants/permissions';
import {REWARD_POINTS_FORM} from '../../../constants/forms';
import {getGroupedCustomerFiles} from '../../../selectors/customersSelectors';
import OrderCategoryDisplay from '../../orders/order/category-display/OrderCategoryDisplay';
import CureApiPurchasableAmount from '../../common/cure-api-purchasable-amount/CureApiPurchasableAmount';
import BiotrackPurchasableAmount from '../../common/integration/biotrack/purchasable-amount/BiotrackPurchasableAmount';
import {getIntegrationState} from '../../../selectors/integration/integrationSelectors';
import InternationalDateTimeStatic from '../../common/form/InternationalDateTimeStatic';
import PurchasedAmounts from '../../orders/customer/components/PurchasedAmounts';

class CustomerDetail extends React.PureComponent {

  constructor(props, context) {
    super(props, context);

    this.loadCustomerStats = this.loadCustomerStats.bind(this);
    this.renderFileGroup = this.renderFileGroup.bind(this);

    this.state = {
      customer: {},
      stats: {},
      history: {},
      loadingCustomerData: false,
    };
  }

  componentWillMount() {
    //handle case when customer details are open automatically
    if (this.props.loadCustomerData) {
      this.loadCustomerStats();
    }
  }

  componentWillReceiveProps(nextProps) {
    if(!nextProps.loadCustomerData) return true; // Don't load unless true
    this.loadCustomerStats();
  }

  loadCustomerStats() {
    if(!this.state.loadingCustomerData){
      this.setState({loadingCustomerData: true});
    } else {
      return true;
    }
    if(Object.keys(this.state.customer).length > 0) return true; // Don't load already loaded data

    const customerId = this.props.customerId;
    const messages = {failed: 'customers.getCustomer.failed'};
    const urls = {
      customer: `/api/customers/${customerId}`,
      stats: `/api/consumer_orders/${customerId}/stats`,
      history: `/api/consumer_orders/${customerId}/recent_history`
    };
    const setInLocalState = (property, data) => {
      this.setState({[property]: data});
    };

    this.props.actions.getItem(urls.customer, itemNames.customer, messages, {
      with_relations: ['categorySalesLimits'],
    }, (customer) => setInLocalState('customer', customer));
    this.props.actions.getItem(urls.stats, null, undefined, undefined, (data) => setInLocalState('stats', data));
    this.props.actions.getItem(urls.history, null, undefined, undefined, (data) => setInLocalState('history', data));
    this.props.actions.getUnpaginatedData('/api/categories', dataNames.categoriesData);
  }

  renderFileGroup(group, index) {
    const file = group.files[0];
    const fileExpiredAt = moment.isMoment(file.expired_at) ? file.expired_at.format('MM/DD/YY') : file.expired_at;
    return (
      <div key={index}>
        {get(file, 'file.url') && (
          <div className='layout-row layout-align-start detail-row' key={file.id}>
            <strong className='row-title'>
              <a href={get(file, 'file.url.original', file.file.url)} target='_blank'>{group.name}</a>
            </strong>
            {(fileExpiredAt !== undefined) && (
              <div>
                <strong>{I18n.t(`customers.details.expiration`)}:</strong>
                <OverlayTrigger placement='bottom' overlay={(
                  <Tooltip id={`id-file-${file.id}`}>
                    <InternationalDateTimeStatic useSystemDate={false} displayTime={false}>{fileExpiredAt}</InternationalDateTimeStatic>
                  </Tooltip>
                )}>
                  <strong><InternationalDateTimeStatic useSystemDate={false} displayTime={false}>{fileExpiredAt}</InternationalDateTimeStatic></strong>
                </OverlayTrigger>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  render() {
    const {customer: customerData, stats: customerStat, history: customerRecentHistory} = this.state;
    const {timezone, curePatient, customerId, integrationState, displayCategories} = this.props;
    const fileGroups = getGroupedCustomerFiles({customer: customerData, timezone});
    if(Object.keys(customerRecentHistory).length === 0){
      return (
        <div>
          <div className='progress progress-striped active'>
            <div style={{width: '100%'}} className='progress-bar progress-bar-info'  role='progressbar' aria-valuenow='100' aria-valuemin='0' aria-valuemax='100'>
              Loading Details....
            </div>
          </div>
        </div>
      );
    }

    let orderCategoryDisplay = null;
    if(customerId){
      orderCategoryDisplay = <OrderCategoryDisplay multiViews={true} consumerId={customerId} showRows={true} stats={customerStat} displayCategories={displayCategories} />;
    }

    return (
      <div className='layout-row layout-wrap'>
        <div className='details-block'>
          <h4>{I18n.t('customers.details.favorites')}</h4>
          <div className='layout-row layout-align-start-start detail-row'>
            <strong className='row-title'>
              {I18n.t('customers.details.flower')}
            </strong>
            <div className='row-value'>
              {customerStat.favorite_flower_item_name || ''}
            </div>
          </div>
          <div className='layout-row layout-align-start-start detail-row'>
            <strong className='row-title'>
              {I18n.t('customers.details.edible')}
            </strong>
            <div className='row-value'>
              {customerStat.favorite_edible_item_name || ''}
            </div>
          </div>
          <div className='layout-row layout-align-start-start detail-row'>
            <strong className='row-title'>
              {I18n.t('customers.details.concentrate')}
            </strong>
            <div className='row-value'>
              {customerStat.favorite_concentrate_item_name || ''}
            </div>
          </div>
          <div className='layout-row layout-align-start-start detail-row'>
            <strong className='row-title'>
              {I18n.t('customers.details.topical')}
            </strong>
            <div className='row-value'>
              {customerStat.favorite_topical_item_name || ''}
            </div>
          </div>
        </div>
        <div className='details-block'>
          <h4>{I18n.t('customers.details.orders')}</h4>
          <div className='layout-row'>
            <div className='last-order'>
              <div className='row-title'>
                <strong>
                  {customerRecentHistory.last_order ? `${I18n.t('customers.details.lastOrder')} ${convertDbDateToFormInputDate(customerRecentHistory.last_order.completed_date, timezone)}` : ''}
                </strong>
              </div>
              {customerRecentHistory.last_order && customerRecentHistory.last_order.products.map((product, index) =>
                <div key={index} className='detail-row'>{product.name}</div>)
              }
            </div>
            <div className='order-history'>
              <div className='row-title'>
                <strong>
                  {customerRecentHistory.recent_orders && customerRecentHistory.recent_orders.length ?
                    <PermissionWrapper permissions={[p.manage_orders]} alternateComponent={<span>{I18n.t('customers.details.viewOrderHistory')}</span>}>
                      <Link
                        to={`/order-history/customers/${customerData.id}`}>{I18n.t('customers.details.viewOrderHistory')}</Link>
                    </PermissionWrapper>
                    : I18n.t('customers.details.viewOrderHistory')}
                </strong>
              </div>
              {customerRecentHistory.recent_orders && customerRecentHistory.recent_orders.map((order, index) =>
                <div key={index} className='detail-row'>{`${I18n.t('customers.details.order')}: ${order.name}`}</div>)
              }
            </div>
          </div>
        </div>
        <div className='details-block'>
          <h4>{I18n.t('customers.details.visitFrequency')}</h4>
          <div className='layout-row layout-align-start-start detail-row'>
            <strong className='row-title'>
              {I18n.t(`customers.details.visits_90_days`)}
            </strong>
            <div className='row-value'>
              {customerStat.last_90_days && `${customerStat.last_90_days.order_count} visit${(customerStat.last_90_days.order_count % 10) === 1 ? '' : 's'}`}
            </div>
          </div>
          <div className='layout-row layout-align-start-start detail-row'>
            <strong className='row-title'>
              {I18n.t(`customers.details.visits_last_month`)}
            </strong>
            <div className='row-value'>
              {customerStat.month && `${customerStat.month.order_count} visit${(customerStat.month.order_count % 10) === 1 ? '' : 's'}`}
            </div>
          </div>
          <div className='layout-row layout-align-start-start detail-row'>
            <strong className='row-title'>
              {I18n.t(`customers.details.visits_last_week`)}
            </strong>
            <div className='row-value'>
              {customerStat.week && `${customerStat.week.order_count} visit${(customerStat.week.order_count % 10) === 1 ? '' : 's'}`}
            </div>
          </div>
        </div>
        <div className='details-block'>
          {(integrationState.isBiotrack) && (
            <BiotrackPurchasableAmount customer={customerData} />
          )}
          {(get(customerData, 'id', false)) ?
            <PurchasedAmounts customer={customerData}/>
            : null
          }
          <CureApiPurchasableAmount
            customer={customerData}
            curePatient={curePatient}
            mode={curePatient ? 'patient' : 'customer'}
          />
          {orderCategoryDisplay}
        </div>
        <div className='details-block'>
          <h4>{I18n.t('customers.details.fileUploads')}</h4>
          {fileGroups.map(this.renderFileGroup)}
        </div>
        {
          customerData && customerData.id && (
            <div className='details-block'>
              <h4>{I18n.t('customers.details.rewardsPoints')}</h4>
              <RewardsEditorPOS
                key={customerData.id}
                form={`${REWARD_POINTS_FORM}.${customerData.id}`}
                customer={customerData}
              />
            </div>
          )
        }
      </div>
    );
  }
}

CustomerDetail.propTypes = {
  customerId: PropTypes.number.isRequired,
  loadCustomerData: PropTypes.bool.isRequired,
  timezone: PropTypes.string.isRequired,
  curePatient: PropTypes.object,
  displayCategories: PropTypes.array.isRequired,
};

function mapStateToProps(state){
  return {
    integrationState: getIntegrationState(state),
    displayCategories: state[dataNames.categoriesData],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      getItem,
      getUnpaginatedData,
      setItem,
    }, dispatch)
  };
}
export default connect(mapStateToProps, mapDispatchToProps) (CustomerDetail);
