/* eslint-disable import/no-named-as-default */
import PropTypes from 'prop-types';
import {reduxForm, formValueSelector, getFormSyncErrors} from 'redux-form';
import {connect} from 'react-redux';
import get from 'lodash.get';
import {ANONYMOUS_CUSTOMER_FORM} from '../../../../constants/forms';
import * as itemNames from '../../../../constants/itemNames';
import AnonymousCustomerForm from './AnonymousCustomerForm';
import validate from './validate';

/**
 * Redux wrapper for AnonymousCustomerForm
 */
const CustomerForm = reduxForm({
  form: ANONYMOUS_CUSTOMER_FORM,
  validate,
})(AnonymousCustomerForm);

function mapStateToProps(state) {
  const allowRecWithExpiredDriversLicense = parseInt(get(state[itemNames.salesComplianceSettings], 'order_allow_expired_dl_on_rec.value', 1));
  const requireBirthdateForAnonymousOrders = get(state[itemNames.salesComplianceSettings], 'order_birthdate_for_anonymous_orders.value', 1);
  const customerType = formValueSelector(ANONYMOUS_CUSTOMER_FORM)(state, 'type');
  const expirationDate = formValueSelector(ANONYMOUS_CUSTOMER_FORM)(state, 'expiration_date');
  return {
    customerType,
    expirationDate,
    allowRecWithExpiredDriversLicense,
    requireBirthdateForAnonymousOrders,
    currentFacility: state[itemNames.facility],
    formErrors: getFormSyncErrors(ANONYMOUS_CUSTOMER_FORM)(state)
  };
}

const AnonymousCustomerFormWrapper = connect(mapStateToProps)(CustomerForm);

AnonymousCustomerFormWrapper.propTypes = {
  ageLimit: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  customerTypes: PropTypes.array.isRequired,
  referralSources: PropTypes.array.isRequired,
  genders: PropTypes.array.isRequired,
  onFastTrack: PropTypes.func.isRequired,
  onStartOrder: PropTypes.func.isRequired,
  onScanId: PropTypes.func.isRequired,
};

export default AnonymousCustomerFormWrapper;
