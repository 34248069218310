import React from 'react';
import get from 'lodash.get';
import { Field } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import ReactSelectInput from '../../../../../common/form/ReactSelectInput';

class ItemMasterDropDown extends React.PureComponent {

  constructor(props, context) {
    super(props, context);

    this.toggleEditableMode = this.toggleEditableMode.bind(this);
    this.renderEditableButton = this.renderEditableButton.bind(this);
    this.state = {
      editable: false,
    };
  }

  toggleEditableMode() {
    this.setState({ editable: !this.state.editable });
  }

  renderEditableButton() {
    return (
      <a onClick={this.toggleEditableMode} className={'add-on-button'}>
        {this.state.editable ? 'Save' : 'Edit'}
      </a>
    );
  }

  render() {
    const { fieldName, fieldProps, editable } = this.props;

    return (
      <Field
        name={fieldName}
        component={ReactSelectInput}
        props={{
          ...fieldProps,
          disabled: get(fieldProps, 'disabled') || !this.state.editable,
          rightAddon: editable && this.renderEditableButton()
        }}
      />
    );
  }
}

ItemMasterDropDown.defaulProps = {
  editable: false,
  label: I18n.t('cultivation.transfers.form.item')
};

export default ItemMasterDropDown;
