import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {push} from 'react-router-redux';
import * as apiActions from '../../../actions/apiActions';
import * as itemActions from '../../../actions/itemActions';
import {getDefaultLabelType, getLabelName} from '../../../selectors/labelsSelectors';
import ModalWrapper from '../../common/ModalWrapper';
import LabelPrinterStatusBlock from './components/LabelPrinterStatusBlock';
import QZTray from '../../print-services/qztray/QZTray'; //eslint-disable-line
import * as itemNames from '../../../constants/itemNames';
import Label from './components/Label';  //eslint-disable-line
import AutoPrintLabel from './components/AutoPrintLabel';  //eslint-disable-line
import PrinterSelector from '../printers/PrinterSelector';


class LabelPrinter extends React.PureComponent {

  constructor (props, context) {
    super(props, context);
    this.onHide = this.onHide.bind(this);
    this.onHidePrinters = this.onHidePrinters.bind(this);
    this.onShowPrinters = this.onShowPrinters.bind(this);
    this.onShowModalRequired = this.onShowModalRequired.bind(this);
    this.state = {
      showPrinters: false,
      showModalRequired: false,
    };
  }

  onHide(){
    this.setState({showModalRequired: false});
    this.props.actions.unsetItem(itemNames.printJob);
    if(typeof this.props.onHide === 'function') this.props.onHide();
  }

  onShowPrinters(){
    this.setState({showPrinters: true});
  }

  onHidePrinters(){
    this.setState({showPrinters: false});
  }

  onShowModalRequired(){
    if (this.props.onFallbackToModal) {
      this.props.onFallbackToModal();
    }
    this.setState({showModalRequired: true});
  }

  render(){

    const selectedPrinter = this.props.selectedPrinter[this.props.labelTag]
      ? this.props.selectedPrinter[this.props.labelTag].server
        ? this.props.selectedPrinter[this.props.labelTag]
        : Object.assign({}, this.props.selectedPrinter[this.props.labelTag], {server: {id: 0, name: 'Local Printers'}})
      : false;

    return (<div>
      <ModalWrapper
        showModal={this.props.showModal || this.state.showModalRequired}
        onHide={this.onHide}
        title={`Printing: ${this.props.labelName}`}
        version={2}
        headerClass='bg-info-dark'
        cancelButton={{show: true, text: 'Close'}}
      >
        <LabelPrinterStatusBlock>
          <div><QZTray label='QZ Tray Status:' labelTag={this.props.labelTag} uiOnly={true} /></div>
          <div>
            Selected Printer:&nbsp;
            <a href onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              e.target.blur();
              this.onShowPrinters();
            }}>
              {
                this.props.selectedPrinter[this.props.labelTag]
                  ? `${selectedPrinter.server.name ? selectedPrinter.server.name : 'Local Printers'} - ${selectedPrinter.name} (Click To Change)`
                  : 'Select A Printer'
              }

            </a>
          </div>
          <PrinterSelector
            labelTag={this.props.labelTag}
            showModal={this.state.showPrinters}
            setForLabel={this.state.labelTag}
            onHide={this.onHidePrinters}
            offset={{x: 1, y: -3}}
          />
        </LabelPrinterStatusBlock>
        <hr />
        <Label
          labelTag={this.props.labelTag}
          payload={this.props.payload}
          params={this.props.params}
          autoPrint={this.props.autoPrint}
          isAutoPrinter={false}
          onShowModalRequired={this.onShowModalRequired}
          defaultLabelType={this.props.defaultLabelType}
        />
        <div style={{clear: 'both'}} />
      </ModalWrapper>
      <QZTray headless={true} labelTag={this.props.labelTag} uiOnly={this.props.qzTrayOff !== undefined ? this.props.qzTrayOff : false} />
      <AutoPrintLabel
        labelTag={this.props.labelTag}
        payload={this.props.payload}
        params={this.props.params}
        autoPrint={this.props.autoPrint}
        onShowModalRequired={this.onShowModalRequired}
        showModalRequired={this.showModalRequired}
        defaultLabelType={this.props.defaultLabelType}
        labelForAutoPrint={this.props.labelForAutoPrint}
        onAutoPrintComplete={this.props.onAutoPrintComplete}
      />
    </div>);

  }

}

LabelPrinter.propTypes = {
  labelTag: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  showModal: PropTypes.bool,
  payload: PropTypes.object,
  facility: PropTypes.object,
  qzTray: PropTypes.object,
  defaultLabelType: PropTypes.string,
  selectedPrinter: PropTypes.object,
  labelForAutoPrint: PropTypes.string,
  onAutoPrintComplete: PropTypes.func,
  onFallbackToModal: PropTypes.func,
};


function mapStateToProps(state, ownProps) {
  return {
    facility: state.facility,
    qzTray: state[itemNames.qzTray],
    defaultLabelType: getDefaultLabelType(state),
    labelName: getLabelName(state, ownProps),
    selectedPrinter: state[itemNames.selectedPrinter],
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, itemActions, {push});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LabelPrinter);
