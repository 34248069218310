import React from 'react';
import PropTypes from 'prop-types';
import {Card} from 'react-bootstrap';
import {FaAngleDown, FaAngleRight} from 'react-icons/fa';
import ErrorBoundry from './ErrorBoundry';

/**
 * Bootstrap panel component that collapses on header click or prop change.
 */
class CollapsiblePanel extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {expanded: props.expanded, rendered: props.expanded};
    this.toggle = this.toggle.bind(this);
  }

  /**
   * Allow changes to props.expanded to override current state
   */
  componentDidUpdate(prevProps) {
    if(prevProps.expanded !== this.props.expanded){
      this.toggle();
    }
  }

  /**
   * Toggle event handler
   */
  toggle() {
    this.setState({expanded: !this.state.expanded, rendered: !this.state.rendered});
  }

  /**
   * Render primary component
   * @return {React.Element}
   */
  render() {
    return (
      <ErrorBoundry>
        <Card expanded={this.state.expanded}
          className={this.props.className}
          onToggle={() => {}}>
          <Card.Header>
              <h3 className='panel-title' onClick={() => this.toggle()}>
                {this.state.expanded ? <FaAngleDown/> : <FaAngleRight/>}
                {this.props.heading}
              </h3>
          </Card.Header>
          {this.state.expanded &&
            <Card.Body collapsible>
              {this.state.rendered && this.props.body}
            </Card.Body>
          }
        </Card>
      </ErrorBoundry>
    );
  }
}

CollapsiblePanel.propTypes = {
  /**
   * @param {boolean} expanded - Open/close panel
   */
  expanded: PropTypes.bool.isRequired,

  /**
   * @param {string} className - CSS class
   */
  className: PropTypes.string.isRequired,

  /**
   * @param {React.Element} heading - Header element
   */
  heading: PropTypes.object.isRequired,

  /**
   * @param {React.Element} body - Body element
   */
  body: PropTypes.object.isRequired,
};

CollapsiblePanel.defaultProps = {
  expanded: true,
  heading: null,
  body: null,
  className: ''
};

export default CollapsiblePanel;
