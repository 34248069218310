import React from 'react';
import PropTypes from 'prop-types';
import {Row} from 'react-bootstrap';

import LeafIntegratorSettings from './LeafIntegratorSettings';
import MetrcIntegratorSettings from './MetrcIntegratorSettings';
import CureIntegratorSettings from './CureIntegratorSettings';
import BiotrackIntegratorSettings from './BiotrackIntegratorSettings';
import ApisicpaIntegratorSettings from './ApisicpaIntegratorSettings';
import UtahIntegratorSettings from './UtahIntegratorSettings';

const IntegratorsFieldArray = ({fields, selectedIntegrator, metrcStates, biotrackStates, apisicpaStates,
                                 isRetailFacility, selectedMetrcState, features}) => {

  return (
    <Row>
      {fields.map((integratorName, integratorIndex) => {
        const integrator = fields.get(integratorIndex);
        if (integrator.integrator !== selectedIntegrator) {
          return null;
        }
        switch (selectedIntegrator) {
        case 'leaf':
          return (
            <LeafIntegratorSettings
              key={integratorIndex}
              integrator={integrator}
            />
          );
        case 'metrc':
          return (
            <MetrcIntegratorSettings
              key={integratorIndex}
              integratorName={integratorName}
              metrcStates={metrcStates}
              features={features}
              isRetailFacility={isRetailFacility}
              selectedMetrcState={selectedMetrcState}
              integrator={integrator}
            />
          );
        case 'cureapi':
          return (
            <CureIntegratorSettings
              key={integratorIndex}
              integratorName={integratorName}
            />
          );
        case 'biotrack':
          return (
            <BiotrackIntegratorSettings
              key={integratorIndex}
              biotrackStates={biotrackStates}
              integrator={integrator}
              integratorName={integratorName}
            />
          );
        case 'apisicpa':
          return (
            <ApisicpaIntegratorSettings
              key={integratorIndex}
              apisicpaStates={apisicpaStates}
              integrator={integrator}
              integratorName={integratorName}
            />
          );
        case 'utah':
          return (
            <UtahIntegratorSettings
              key={integratorIndex}
              integratorName={integratorName}
              features={features}
            />
          );
        default:
          return null;
        }
      })}
    </Row>
  );
};

IntegratorsFieldArray.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    get: PropTypes.func.isRequired,
  }).isRequired,
  metrcStates: PropTypes.array.isRequired,
  biotrackStates: PropTypes.array.isRequired,
  apisicpaStates: PropTypes.array.isRequired,
  selectedIntegrator: PropTypes.string,
  isRetailFacility: PropTypes.bool.isRequired,
  selectedMetrcState: PropTypes.string,
  features: PropTypes.object,
  isLeafUtahConfigPackClosedLoopFacility: PropTypes.bool,
  change: PropTypes.func.isRequired
};

export default IntegratorsFieldArray;
