import get from 'lodash.get';
import {createSelector} from 'reselect';
import * as itemNames from '../constants/itemNames';

export const getSupplySettings = state => state[itemNames.supplySettings];

export const isMappingRequiredForSupply = createSelector(
  [getSupplySettings], (supplySettings) => {
    return get(supplySettings, 'supply_medicated_receipt_requires_mapping.value', false);
  }
);