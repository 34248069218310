import React from 'react';
import PropTypes from 'prop-types';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table-cve';

const SimpleTable = ({columns, data, keyField, striped, bordered, trClassName}) => {
  return (
    <BootstrapTable
      data={data}
      keyField={keyField || 'id'}
      striped = {striped || true}
      bordered={bordered || false}
      trClassName={trClassName}
      version='4'>
      {columns.map((column, index) => (
          <TableHeaderColumn
            key={index}
            dataField={column.dataId}
            width={column.width}
            dataFormat={column.formatter}>
            {column.name}
          </TableHeaderColumn>
        )
      )}
    </BootstrapTable>
  );
};

SimpleTable.propTypes = {
  keyField: PropTypes.string,
  striped: PropTypes.bool,
  bordered: PropTypes.bool,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  trClassName: PropTypes.func
};

export default SimpleTable;
