import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';

import {getEnabledTabs} from '../../actions/reportActions';
import getSalesReportTabs from './sales-reports/common/tabDefinitions';


/**
 * NOTE: This component is merely a forwarding component due our routing architecture seemingly lacking a clean way
 * to chain redirects in a way that would land the user on the first Sales Reports tab they have access to
 */
class SalesReportsLandingPage extends React.Component {

  componentDidMount() {
    const {actions} = this.props;
    const tabs = actions.getEnabledTabs(getSalesReportTabs);
    const pushPath = (tabs && tabs.length) ? tabs[0].path : '/nopermission';

    actions.push(pushPath);
  }

  render() {
    return <div></div>;
  }
}

function mapDispatchToProps(dispatch) {
  const actions = { push, getEnabledTabs };
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(null, mapDispatchToProps)(SalesReportsLandingPage);
