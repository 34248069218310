import React from 'react';
import PropTypes from 'prop-types';
import * as ReactIcons from 'react-icons/fa';


const UserOptsLink = ({glyph, displayText, count = 0}) => {
  let countDisplay;
  if (count > 0) {
    countDisplay = <span className='count'>{count}</span>;
  }

  // Mapping from Glyphicon to ReactIcon
  const iconMapping = [
    {key: 'alert', icon: ReactIcons.FaExclamationTriangle},
    {key: 'off', icon: ReactIcons.FaPowerOff},
    {key: 'info-sign', icon: ReactIcons.FaInfoCircle},
    {key: 'remove-sign', icon: ReactIcons.FaTimesCircle},
    {key: 'envelope', icon: ReactIcons.FaEnvelope},
  ];

  const IconComponent = iconMapping.find((icon) => icon.key === glyph);

  return (
    <div className='user-opts-link'>
      {IconComponent ? <IconComponent.icon /> : null}
      {displayText}
      {countDisplay}
    </div>
  );
};

UserOptsLink.propTypes = {
  glyph: PropTypes.string.isRequired,
  displayText: PropTypes.string.isRequired,
  count: PropTypes.number
};

export default UserOptsLink;
