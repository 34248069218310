import get from 'lodash.get';
import { createSelector } from 'reselect';
import orderBy from 'lodash.orderby';
import * as dataNames from '../constants/dataNames';
import {getFeatureTogglesByName} from './featureToggles';

export const getPermissions = state => state[dataNames.permissions];
const getRolesRaw = state => state[dataNames.roles];

export const getRoles = createSelector(
  [getRolesRaw],
  (roles) => roles.map(role => ({
    ...role,
    role_name: get(role, 'role_display_name') || get(role, 'role_name'),
    role_name_original: get(role, 'role_name'),
  }))
);

export const getOrderedPermissions = createSelector([getPermissions],  permissions => orderBy(permissions, 'permission_name'));
export const getOrderedRoles = createSelector([getRoles], roles => orderBy(roles, 'role_name'));

export const getRolesForUserPage = createSelector([getRoles, getFeatureTogglesByName], (roles, featureToggles) => {
  return roles
    .filter(role => {
      return Boolean(role.permissions.length);
    })
    .filter(role => {
      if (role.role_name === 'Courier: Organization Administrator') {
        return get(featureToggles, 'feature_courier_licensees.is_enabled');
      }

      return true;
    });
});
