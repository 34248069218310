import React from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {goBack, push} from 'react-router-redux';
import * as itemNames from '../../../../../constants/itemNames';
import * as dataNames from '../../../../../constants/dataNames';
import {orderTypes} from './formData';
import {getItem, getUnpaginatedData, putData, putItem, postItem, getSearchData} from '../../../../../actions/apiActions';
import {unsetItem} from '../../../../../actions/itemActions';
import {getGroupedRewardSettings, getRewardSettingsInitialValues, getPayload} from '../../../../../selectors/rewardsSelectors';
import {getRewardAssignableCustomerGroups} from '../../../../../selectors/forms/rewardsFormSelectors';
import SettingsFormWrapper from './components/SettingsFormWrapper';
import InProgressOverlay from '../../../../common/InProgressOverlay';

export class RewardsSetup extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSaveAndStay = this.onSaveAndStay.bind(this);

    this.state = {
      orderTypes: orderTypes.map((type) => type),
      saveAndStay: false,
      message: '',
      showLoader: false,
    };
  }

  componentWillMount() {
    this.getSettings();
    this.props.actions.getUnpaginatedData('/api/categories', dataNames.categories, {failed: 'categories.get.failed'});
    this.props.actions.getUnpaginatedData('/api/consumer_groups', dataNames.customerGroups, {failed: 'customers.groups.get.failed'});
  }

  componentWillUnmount(){
    this.props.actions.unsetItem(itemNames.rewardSettings);
  }

  getSettings(){
    this.props.actions.getItem('/api/rewards/setup', itemNames.rewardSettings, null);
  }

  onSaveAndStay(){
    this.setState({saveAndStay: true});
  }

  onSubmit(formData) {
    const payload = getPayload(formData);
    const url = '/api/rewards/setup';
    const messages = null;
    this.setState({showLoader: true, message: I18n.t('retail.rewardSetup.messages.saving')});
    this.props.actions.postItem(url, payload, null, messages, null, () => {
      this.setState({showLoader: false});
      if(this.state.saveAndStay){
        this.setState({saveAndStay: false});
        this.getSettings();
      } else {
        this.props.actions.goBack();
      }
    });
  }

  render() {
    const {initialValues, settings, customerGroups} = this.props;
    return (<div>
      <InProgressOverlay message={this.state.message} isActive={this.state.showLoader} />
      <SettingsFormWrapper
        settings={settings}
        initialValues={initialValues}
        customerGroups={customerGroups}
        onSaveAndStay={this.onSaveAndStay}
        onSubmit={this.onSubmit}
      />
    </div>);
  }
}

RewardsSetup.propTypes = {
  actions: PropTypes.object,
  initialValues: PropTypes.object,
  settings: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    settings: getGroupedRewardSettings(state),
    initialValues: getRewardSettingsInitialValues(state),
    categories: state[dataNames.categories],
    customerGroups: getRewardAssignableCustomerGroups(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {getItem, getUnpaginatedData, goBack, push, putData, unsetItem, putItem, postItem, getSearchData};

  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RewardsSetup);
