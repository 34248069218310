import React from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {push} from 'react-router-redux';

import TablePageWrapper from '../../common/grid/TablePageWrapper';
import SingleActionColumn from '../../common/grid/columns/SingleActionColumn';
import Status from '../../common/status';

import * as dataNames from '../../../constants/dataNames';
import {getPaginatedData} from '../../../actions/apiActions';
import {unsetData} from '../../../actions/dataActions';
import {getMerchantProfiles} from '../../../selectors/merchantConfigurationSelectors';


class MerchantProfilesList extends React.Component {
  componentDidMount() {
    const {getPaginatedData} = this.props;
    const params = {
      with: ['merchant']
    };

    getPaginatedData('/api/payment/merchant_profiles/search', dataNames.merchantConfigurationProfiles, null, params);
  }

  componentWillUnmount() {
    const {unsetData} = this.props;

    unsetData(dataNames.merchantConfigurationProfiles);
  }

  columns() {
    const {push} = this.props;

    return [
      {
        name: I18n.t('facility.setup.merchantProfilesList.name'),
        dataId: 'name',
        hidden: false,
        dataSort: true,
      },
      {
        name: I18n.t('facility.setup.merchantProfilesList.merchant_name'),
        dataId: 'merchant_name', // NOTE: Not sure about this name yet, endpoint response details needed
        hidden: false,
        dataSort: true,
      },
      {
        name: I18n.t('facility.setup.merchantProfilesList.status'),
        dataId: 'status',
        hidden: false,
        dataSort: true,
        formatter: value => <Status status={value}/>
      },
      {
        name: I18n.t('facility.setup.merchantProfilesList.default'),
        dataId: 'default',
        hidden: false,
        dataSort: true,
        formatter: value => value ? I18n.t('general.yes') : I18n.t('general.no')
      },
      {
        formatter: SingleActionColumn({
          label: I18n.t('facility.setup.merchantProfilesList.manage'),
          action: (event, row) => push(`/setup/merchant-profile/manage/${row.id}`)
        }),
        dataSort: false
      },
      {
        formatter: SingleActionColumn({
          label: I18n.t('facility.setup.merchantProfilesList.edit'),
          action: (event, row) => push(`/setup/merchant-profile/modify/${row.id}`)
        }),
        dataSort: false
      },
    ];
  }

  actions() {
    return [
      {id: 'createMerchantProfile', path: '/setup/merchant-profile/create', text: I18n.t('facility.setup.merchantProfile.create')}
    ];
  }

  render() {
    const {merchant_profiles} = this.props;

    return (
      <TablePageWrapper
        settingKey='merchant_profiles'
        columns={this.columns()}
        data={merchant_profiles}
        hideSearch={true}
        actions={this.actions()}
        hideScanSearch={true}
        hideExport={true}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  merchant_profiles: getMerchantProfiles(state)
});

const mapDispatchToProps = ({
  getPaginatedData, unsetData, push
});

export default connect(mapStateToProps, mapDispatchToProps)(MerchantProfilesList);
