import React from 'react';
import PropTypes from 'prop-types';

const Message = (props) => {

  if(props.message === undefined || props.message === '' || !props.message){
    return <div style={{marginTop: '8px'}}></div>;
  }

  return (
    <div className='message'>
      {props.message}
    </div>
  );

};

Message.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]).isRequired
};

export default Message;
