import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { I18n } from 'react-redux-i18n';
import { push } from 'react-router-redux';
import omit from 'lodash.omit';
import {pick} from 'lodash';
import { CancelToken } from 'axios';

import { getUnpaginatedData, getDataByPost, getSearchData, postItem, getItem } from '../../actions/apiActions';
import { unsetData } from '../../actions/dataActions';
import { normalizeTestResults, getTestResultDimensions } from '../../selectors/testResultsSelectors';
import { getFacilityTitle } from '../../selectors/facilitiesSelectors';
import { isLeafIntegrator } from '../../selectors/integration/leafSelectors';
import { isBiotrackIntegrator } from '../../selectors/integration/biotrackSelectors';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import TablePageWrapper from '../common/grid/TablePageWrapper';
import AbstractTestResultListing from './common/AbstractTestResultListing';
import getColumns from './common/columns';
import { getTotalResults } from '../../selectors/paginationSelectors';
import { getDataUpdateAvailable } from '../../selectors/dataUpdateSelectors';
import { getIntegrationState } from '../../selectors/integration/integrationSelectors';
import ModalWrapper from '../common/ModalWrapper';
import { fetchIsolocityTestResults } from '../../actions/integrations/isolocity';
import { labResultsHasEditPermission } from '../../selectors/accessSelectors';

export class TestResultListingPageForPlants extends AbstractTestResultListing {
  constructor(props, context) {
    super(props, context);
    this.handleSearch = this.handleSearch.bind(this);
    this.switchTab = this.switchTab.bind(this);
    this.onTabChanged = this.onTabChanged.bind(this);
    this.filter = this.filter.bind(this);
    this.reload = this.reload.bind(this);
    this.ensureToken = this.ensureToken.bind(this);
    this.clearTokens = this.clearTokens.bind(this);
    this.cancelTokens = this.cancelTokens.bind(this);
    this.viewResultsButton = this.viewResultsButton.bind(this);
    this.toggleViewDetails = this.toggleViewDetails.bind(this);
    this.expandComponent = this.expandComponent.bind(this);
    this.setBodyState = this.setBodyState.bind(this);
    this.expandComponent = this.expandComponent.bind(this);
    this.importResults = this.importResults.bind(this);
    this.importBiotrackResults = this.importBiotrackResults.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
    this.onHide = this.onHide.bind(this);

    const tabs = [
      {
        id: 'activeTestResultsTab',
        eventKey: 'active',
        title: 'nav.active',
        actions: [
          {
            id: 'importResults',
            func: this.importResults,
            text: 'cultivation.testResults.actions.importResults',
            requireSelect: false,
            hidden: () => !this.props.isLeaf
          },
          {
            id: 'importResults',
            func: this.importBiotrackResults,
            text: 'cultivation.testResults.actions.importResults',
            requireSelect: false,
            hidden: () => !this.props.isBiotrack
          },
          {
            id: 'updateSearch',
            func: this.updateSearch,
            text: 'plants.actions.updateSearch',
            glyph: 'arrow-up',
            variant: 'warning',
            requireSelect: false,
            disabled: () => this.state.reindexing
          }
        ]
      },
      {
        id: 'historicalTestResultsTab',
        eventKey: 'historical',
        title: 'nav.historical',
        actions: [
          {
            id: 'updateSearch',
            func: this.updateSearch,
            text: 'plants.actions.updateSearch',
            glyph: 'arrow-up',
            variant: 'warning',
            requireSelect: false,
            disabled: () => this.state.reindexing
          }
        ]
      },
      { id: 'averagesTestResultsTab', eventKey: 'averages', title: 'nav.averages', actions: [] }
    ];

    const expanded = [];
    this.state = {
      tabs,
      expanded,
      cancelTokens: {},
      reindexing: false,
      showModal: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.labResultDimensions !== this.props.labResultDimensions) {
      const { activeColumns, historicalColumns, averagesColumns } = getColumns(
        this,
        this.props.integrationState,
        this.props.hasEditPermission,
        this.props.labResultDimensions
      );

      this.setState({ //eslint-disable-line
        activeColumns: activeColumns,
        historicalColumns: historicalColumns,
        averagesColumns: averagesColumns,
      });
    }
  }

  componentDidMount() {
    const { integrationState, params } = this.props;
    this.switchTab(params.tab) || this.onTabChanged(params.tab, true);

    if (integrationState.isIsolocity) {
      this.props.actions.fetchIsolocityTestResults();
    }

    Promise.all([
      this.props.actions.getItem(`/api/lab_results/configuration`, itemNames.testResultConfiguration, null),
      this.props.actions.getItem(`/api/compliance_settings`, itemNames.complianceSettings, null)
    ])
      .then(() => {
        const { activeColumns, historicalColumns, averagesColumns } = getColumns(
          this,
          this.props.integrationState,
          this.props.hasEditPermission,
          this.props.labResultDimensions
        );

        this.setState({ //eslint-disable-line
          activeColumns: activeColumns,
          historicalColumns: historicalColumns,
          averagesColumns: averagesColumns,
          ready: true,
        });

      })
      .catch(() => this.setState({ ready: true })); //eslint-disable-line
  }

  componentWillReceiveProps(newProps) {
    if (newProps.params.tab !== this.props.params.tab) {
      this.onTabChanged(newProps.params.tab || '', true);
    }
  }

  componentWillUnmount() {
    this.cancelTokens('search', 'fetchChildren');
  }

  onEdit(event, id) {
    event.stopPropagation();
    this.props.actions.push(`/plants/test-results/modify/${id}`);
  }

  onTabChanged(activeTab, clearExpand) {
    this.filter(activeTab);
    if (clearExpand) {
      this.setState({ expanded: [] });
      this.setBodyState({ expanding: [] });
    }
  }

  filter(tab) {
    if (tab === 'historical') {
      this.ref.current &&
      this.ref.current.wrappedInstance.filter(
        '(reference_type:plant_group AND (is_active_package:0 OR is_latest_lab_result:0) AND NOT (deleted_at:[* TO *]))'
      );
    } else {
      this.ref.current &&
      this.ref.current.wrappedInstance.filter(
        '(reference_type:plant_group AND is_active_package:1 AND is_latest_lab_result:1 AND NOT deleted_at:[* TO *])'
      );
    }
    this.reload(true);
  }

  reload(unsetData) {
    unsetData && this.props.actions.unsetData(dataNames.testResultGroups);
    unsetData && this.props.actions.unsetData(dataNames.testResults);
    // this.ref.current && this.ref.current.wrappedInstance.getBaseRef().handleExportCSV();
  }

  importResults() {
    this.setState({ showModal: true });
    return this.props.actions
      .getUnpaginatedData('/api/leaf/import_lab_results', '', {
        success: (data) => {
          if (!data) {
            return 'cultivation.testResults.get.leafEmpty';
          }
        },
        failed: 'cultivation.testResults.get.leafFailed'
      })
      .then(() => this.reload());
  }

  importBiotrackResults() {
    this.setState({ showModal: true });
    return this.props.actions
      .getDataByPost('/api/biotrack/import_lab_results_manual', '', {
        failed: 'cultivation.testResults.get.biotrackFailed'
      })
      .then(() => this.reload());
  }

  ensureToken(name) {
    const oldToken = this.state.cancelTokens[name];
    oldToken && oldToken.cancel('Concurrency request');
    const newToken = CancelToken.source();
    this.setState({
      cancelTokens: {
        ...this.state.cancelTokens,
        [name]: newToken
      }
    });
    return newToken;
  }

  clearTokens(...names) {
    this.setState({ cancelTokens: omit(this.state.cancelTokens, names) });
  }

  cancelTokens(...names) {
    const tokens = pick(this.state.cancelTokens, names);
    tokens && Object.keys(tokens).forEach((key) => tokens[key].cancel('Cancel request'));
    this.clearTokens(...names);
  }

  handleSearch(sort, query = 'matchall', size, start, filter) {
    //Skip searching before the tab filter is set
    if (!filter) {
      return;
    }
    const group = { //eslint-disable-line
      'group': 'true',
      'group.format': 'grouped',
      'group.field': 'lab_results_id',
      'group.ngroups': 'true'
    };
    const params = { sort: sort || 'lab_results_id desc', query, size, start, filter, group };
    const cancelToken = this.ensureToken('search');
    this.props.actions
      .getSearchData(
        '/api/search/lab_results_on_plants',
        dataNames.testResultGroups,
        null,
        params,
        (groups) => {
          this.clearTokens('search');
          this.fetchChildren(groups, filter);
        },
        cancelToken.token
      )
      .catch((error) => {
        if (!error.__CANCEL__) {
          return Promise.reject(error);
        }
      });
  }

  fetchChildren(testResults = this.props.testResults, tabFilter) {
    const cancelToken = this.ensureToken('fetchChildren');
    const query = 'matchall';
    const size = 10000;
    const sort = 'lot_number asc, package_code asc';
    const filter =
      '( ' + testResults.map((group) => `lab_results_id: ${group.groupValue}`).join(' OR ') + ' ) AND ' + tabFilter;
    const params = { query, size, sort, filter };
    if (testResults.length) {
      this.props.actions
        .getDataByPost(
          '/api/search/lab_results_on_plants',
          params,
          dataNames.testResults,
          null,
          null,
          () => {
            this.clearTokens('fetchChildren');
          },
          cancelToken.token
        )
        .catch((error) => {
          if (!error.__CANCEL__) {
            return Promise.reject(error);
          }
        });
    }
  }

  updateSearch() {
    this.props.actions.postItem('/api/search/update', { core: 'lab_results_on_plants' }, null, {
      success: 'plants.actions.updateSearchSuccess',
      fail: 'plants.actions.updateSearchFail'
    });
    this.setState({ reindexing: true });
  }

  onHide() {
    this.setState({ showModal: false });
  }

  render() {
    const { tabs, activeColumns, historicalColumns, expanded } = this.state;
    const expanding = expanded.map((row) => row.id);
    const activeTab = this.props.params.tab || 'active';
    const { dataTotalSize, dataUpdateAvailable, testResults } = this.props;

    return (
      <div className='test-results-wrapper'>
        <ModalWrapper
          Component={false}
          title={I18n.t('cultivation.testResults.importingTestResults')}
          headerClass='bg-info-dark'
          onHide={this.onHide}
          showModal={this.state.showModal}
          okayButton={{ show: true, onClick: this.onHide }}
          dialogClassName='modal-sm'
          version={2}
        >
          <div>{I18n.t('cultivation.testResults.importTestNotification')}</div>
        </ModalWrapper>

        <TablePageWrapper
          ref={this.ref}
          settingKey='test-results'
          handleSelect={() => {}}
          selectedRows={[]}
          dataUpdateAvailable={dataUpdateAvailable}
          dataUpdated={() => this.setState({ reindexing: false })}
          data={testResults}
          dataTotalSize={dataTotalSize}
          tabs={tabs}
          activeTab={activeTab}
          switchTab={this.switchTab}
          hideScanSearch={true}
          columns={activeTab === 'historical' ? historicalColumns : activeColumns}
          externalSearch={this.handleSearch}
          external={true}
          autoRefresh={10000}
          hideExport={true}
          bstProps={{
            selectRow: {
              clickToSelect: false,
              clickToExpand: false,
              hideSelectColumn: true,
              selected: []
            },
            expandableRow: () => true,
            expandComponent: (row) => {
              const dataConfig = this.props.labResultDimensions;
              return this.expandComponent(row, dataConfig);
            },
            options: {
              //After setState the array is mutating so workaround is to use slice
              onRowClick: () => this.setBodyState({ expanding: expanding.slice() })
            }
          }}
        />
      </div>
    );
  }
}

TestResultListingPageForPlants.propTypes = {
  testResults: PropTypes.array.isRequired,
  facilityTitle: PropTypes.string.isRequired,
  dataTotalSize: PropTypes.number,
  params: PropTypes.shape({
    tab: PropTypes.string
  }).isRequired,
  actions: PropTypes.shape({
    push: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    getSearchData: PropTypes.func.isRequired,
    getDataByPost: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired
  }).isRequired,
  labResultDimensions: PropTypes.object,
  isLeaf: PropTypes.bool,
  dataUpdateAvailable: PropTypes.array.isRequired,
  hasEditPermission: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    facilityTitle: getFacilityTitle(state),
    testResults: normalizeTestResults(state),
    dataTotalSize: getTotalResults(state, { name: dataNames.testResultGroups }),
    dataUpdateAvailable: [getDataUpdateAvailable(state, { name: dataNames.testResults, core: 'lab_results_on_plants' })],
    isLeaf: isLeafIntegrator(state),
    isBiotrack: isBiotrackIntegrator(state),
    integrationState: getIntegrationState(state),
    labResultDimensions: getTestResultDimensions(state),
    hasEditPermission: labResultsHasEditPermission(state)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    push,
    unsetData,
    getUnpaginatedData,
    getItem,
    getSearchData,
    getDataByPost,
    postItem,
    fetchIsolocityTestResults
  };
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TestResultListingPageForPlants);
