import get from 'lodash.get';
import {createSelector} from 'reselect';
import {getSettingsHash} from '../coreSettingsSelectors';
import {facility} from '../../constants/itemNames';

const getCurrentFacility = (state) => state[facility];

export const getFacilityState = createSelector(getCurrentFacility, facility => facility && facility.province_code ? facility.province_code.toUpperCase() : '');

export const getFacilityUSState = createSelector(
  getCurrentFacility,
  (facility) => {
    return facility && facility.province_code && facility.country_code && facility.country_code.toUpperCase() === 'US'
      ? facility.province_code.toUpperCase()
      : '';
  });

export const isFlorida = createSelector([getFacilityUSState], (state) => state === 'FL');
export const isOklahoma = createSelector([getFacilityUSState], (state) => state === 'OK');

export const isCCA = createSelector([getSettingsHash], (settings) => {
  return get(settings, 'core_is_cca_facility.value', get(settings, 'core_is_cca_facility', 0));
});

export default getCurrentFacility;
