import {I18n} from 'react-redux-i18n';
import {getDuplicateNamesByLevel,setLocationErrors} from '../storage-locations/validate';

export const validate = (values, props) => {
  const errors = {};
  if (!values.facility_square_footage) {
    errors.facility_square_footage = I18n.t('locations.form.facilitySquareFootageRequired');
  }
  if(!values.facility_total_rent) {
    errors.facility_total_rent = I18n.t('locations.form.facilityTotalRentRequired');
  }

  if(values.child_locations)  {
    const total_cultivation_square_footage = values.child_locations.reduce((acc, building) => acc + building.child_locations.reduce((accumulator, rooms) => accumulator + parseInt(rooms.cultivation_square_footage),0),0);
    if(total_cultivation_square_footage > values.facility_square_footage) {
      errors.facility_square_footage = I18n.t('locations.form.facilitySquareFootageToLow');
    }
    const duplicates = getDuplicateNamesByLevel(values.child_locations);
    setLocationErrors(values, errors, duplicates, 0, null, props);
  
    // require cultivation type
    values.child_locations.forEach( (loc,idx) => {
      if(!loc.location_type){
        errors.child_locations[idx].location_type = I18n.t('common.form.isRequired', {
          fieldName: I18n.t('locations.form.cultivationType')
        });
      }
    });
  }

  return errors;
};

export default validate;
