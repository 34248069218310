import {reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import CheckinLeafMJPForm from './CheckinLeafMJPForm';
import validate from './validate';

const CheckinLeafMJPFormWrapper = reduxForm({
  validate,
  touchOnChange: true,
  enableReinitialize: true,
})(CheckinLeafMJPForm);

CheckinLeafMJPFormWrapper.propTypes = {
  form: PropTypes.string.isRequired,
  showScanModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isCourierLicenseesToggled: PropTypes.bool,
  initialValues: PropTypes.object,
  isUtahEvsEnabled: PropTypes.bool,
};

export default CheckinLeafMJPFormWrapper;
