import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';
import {Button, ButtonToolbar} from 'react-bootstrap';
import {Field} from 'redux-form';

import FieldArrayIncrement from '../../../common/form/FieldArrayIncrement';
import InlineCheckBox from '../../../common/form/InlineCheckBox';
import NumericInput from '../../../common/form/NumericInput';
import ReactSelectInput from '../../../common/form/ReactSelectInput';

class Payments extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    const {fields, registers, payments, invalid, mayChangePayment, displayChangePayment, allowNegativeNumber} = this.props;

    // Add I18n value to paymentTypes
    const paymentTypes = this.props.paymentTypes.map(paymentType => ({text: I18n.t(paymentType.text), value: paymentType.value}));


    return (
      <tbody className='order-payments'>
      {fields.map((fieldNamePrefix, index) => {
        const isHypur = get(payments, `${index}.payment_type`) == 'hypur';

        // Do not display payments with negative amounts while in edit mode.
        return mayChangePayment && payments[index] && payments[index].amount && (parseFloat(payments[index].amount) < 0 && !allowNegativeNumber)
          ? null
          : (
            <tr key={index}>
              <td>
                <div style={{display: 'block', overflow: 'none'}}>
                  <Field
                    name={`${fieldNamePrefix}.payment_type`}
                    component={ReactSelectInput}
                    props={{
                      options: !isHypur ? paymentTypes.filter(paymentType => paymentType.value !== 'hypur') : paymentTypes,
                      disabled: !mayChangePayment || isHypur,
                      textKey: 'text',
                      valueKey: 'value'
                    }}
                  />
                  {isHypur ?
                    <div
                      className='help-block text-muted'
                      style={{display: 'inline-block', whiteSpace: 'initial'}}
                    >
                      {I18n.t('cart.payment.hypurDisabledPaymentType')}
                    </div>
                  : null}
                </div>
              </td>
              <td className={isHypur && 'align-top'}>
                <Field
                  name={`${fieldNamePrefix}.amount`}
                  component={NumericInput}
                  props={{
                    allowNegativeNumber: allowNegativeNumber,
                    fractionPartSize: 2,
                    step: .01,
                    min: -Infinity,
                    disabled: !mayChangePayment || isHypur || payments[index].amount < 0,
                  }}
                />
              </td>
              <td className={isHypur && 'align-top'}>
                <Field
                  name={`${fieldNamePrefix}.register_id`}
                  component={ReactSelectInput}
                  props={{
                    options: registers.filter((r) => r.type === 'sales'),
                    valueKey: 'id',
                    textKey: 'name',
                    disabled: !mayChangePayment
                  }}
                />
              </td>
            </tr>
          );
      })}
      {displayChangePayment ? <tr>
        <td>
          <Field
            name='mayChangePayment'
            component={InlineCheckBox}
            props={{
              label: I18n.t('cultivation.orderHistory.table.changePayment')
            }}
          />
        </td>
        <td colSpan={2}>
          {mayChangePayment ?
            <div className='float-right layout-row'>
              <ButtonToolbar>
                <Button variant='primary'
                        type='submit'
                        disabled={invalid}>
                  {I18n.t('common.form.save')}
                </Button>
              </ButtonToolbar>
              <FieldArrayIncrement
                fields={fields}
                showDecrement={true}
                minLength={1}
              />
            </div>
          : null}
        </td>
      </tr> : null}
      </tbody>
    );
  }
}

Payments.propTypes = {
  fields: PropTypes.object.isRequired,
  registers: PropTypes.array.isRequired,
  paymentTypes: PropTypes.array.isRequired,
  payments: PropTypes.array,
  invalid: PropTypes.bool.isRequired,
  mayChangePayment: PropTypes.bool,
  displayChangePayment: PropTypes.bool,
  allowNegativeNumber: PropTypes.bool
};

export default Payments;
