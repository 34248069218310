import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Col, Row} from 'react-bootstrap';

import {postItem} from '../../../actions/apiActions';
import {getCustomerRecord} from '../../../selectors/customerSelectors';

class LeafPaPhysicianNote extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      patient: {},
      loading: false
    };
  }

  componentDidMount() {
    this.loadPatient();
  }

  componentDidUpdate(prevProps) {
    if (this.isLoadable() && (this.customerDidChange(prevProps) || (!this.state.patient && !this.state.loading))) {
      this.loadPatient();
    }
  }

  isLoadable() {
    const {customer} = this.props;

    return Boolean(customer && customer.state_integration_tracking_id && customer.birth_date);
  }

  customerDidChange(prevProps) {
    const {customer} = this.props;

    return Boolean(
      prevProps.customer &&
      prevProps.customer.state_integration_tracking_id !== customer.state_integration_tracking_id
    );
  }

  loadPatient() {
    const {customer} = this.props;

    if (!this.isLoadable()) return;

    this.setState({loading: true});
    this.props.actions.postItem(
      '/api/leaf/oracle/patient_details_for_caregiver',
      {
        registry_id: customer.state_integration_tracking_id,
        dob: customer.birth_date
      }
    ).then((result) => {
      this.setState({patient: result, loading: false});
      return result;
    }).catch(() => this.setState({loading: false}));
  }

  render() {

    if (this.state.loading) {
      return (
        <div className='progress progress-striped active'>
          <div style={{width: '100%'}} className='progress-bar progress-bar-info'  role='progressbar' aria-valuenow='100' aria-valuemin='0' aria-valuemax='100'>
            Loading...
          </div>
        </div>
      );
    }

    const {patient} = this.state;
    const considerations = (patient && patient.dispensary_considerations || '').trim();
    const mmForms = patient.mm_forms || {};
    const forms = Object.keys(mmForms)
      .reduce(
        (acc, key) => {
          if (mmForms[key]) {
            acc.push(mmForms[key]);
          }
          return acc;
        },
        []
      )
      .join(', ');

    return (<Row className='physician-note'>
      <Col xs={12}>
        <p>
          <label>{I18n.t('consumers.checkin.limitationsAndRestrictions')}</label><br/>
          {patient.limitations_restrictions && patient.limitations_restrictions.trim()
            ? patient.limitations_restrictions
            : (patient.allow_dispensary_recommendation
              ? I18n.t('consumers.checking.allowDispensaryRecommendation')
              : ''
            )
          }
        </p>
        {considerations ?
          <p>
            <label>{I18n.t('consumers.checkin.dispensaryConsiderations')}</label>
            <br/>
            <span>{considerations}</span>
          </p>
          :
          null
        }
        {forms ?
          <p>
            <label>{I18n.t('consumers.checking.recommendedForms')}</label>
            <br/>
            <span>{forms}</span>
          </p>
          :
          null
        }
      </Col>
    </Row>);
  }
}

LeafPaPhysicianNote.propTypes = {
  customer: PropTypes.shape({
    state_integration_tracking_id: PropTypes.number.isRequired,
    birth_date: PropTypes.string.isRequired
  })
};

function mapStateToProps(state, props) {
  return {
    customer: props.customer || getCustomerRecord(state)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({
    postItem
  });
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export const ConnectedLeafPaPhysicianNote = connect(mapStateToProps, mapDispatchToProps)(LeafPaPhysicianNote);
export default LeafPaPhysicianNote;
