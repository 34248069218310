import {createSelector} from 'reselect';
import { getFacility } from '../facilitiesSelectors';
import { cashOnHandReport } from '../../constants/dataNames';

export const getCashOnHandReportRaw = state => state[cashOnHandReport];

export const getCashOnHandReport = createSelector([getCashOnHandReportRaw, getFacility], (cashOnHandReport, facility) => {
  const facilityData = {
    facilityName: facility.name,
    address: facility.street_address_1 + ' ' + facility.street_address_2,
    city: facility.city,
    postalCode: facility.postal_code,
    province: facility.province_code,
    country: facility.country_code,
  };
  return cashOnHandReport.map((register) => {
    const currentLevel = parseFloat(register.current_level);
    register.current_level = isNaN(currentLevel) ? 0 : currentLevel;
    return Object.assign({}, register, facilityData);
  });
});
