/* eslint-disable react/no-multi-comp */
import sortBy from 'lodash.sortby';
import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {push} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import {Button, Alert} from 'react-bootstrap';

import TablePageWrapper from '../common/grid/TablePageWrapper';
import * as apiActions from '../../actions/apiActions';
import {unsetData} from '../../actions/dataActions';
import  * as dataNames from '../../constants/dataNames';
import {handleComplexSelectRow} from '../../actions/helpers/selectedDataHelper';
import {clearSelectedData} from '../../actions/selectedDataActions';
import InProgressOverlay from '../common/InProgressOverlay';
import {getSelectedIngredientsIds, getIngredientsForListing} from '../../selectors/ingredientsSelectors';
import {getSortedIngredientsCategoriesWithTypes} from '../../selectors/ingredientCategoriesSelectors';
import PageTitle from '../common/PageTitle';
import InternationalDecimalStatic from '../common/form/InternationalDecimalStatic';
import InternationalCurrencyStatic from '../common/form/InternationalCurrencyStatic';

export class IngredientsPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);

    const commonColumns = [
      {
        name: 'ingredients.table.name',
        dataId:'name',
        hidden: false,
        formatter: (cell, row, ee, tt) => {
          const title = cell && cell.length > 35 ? cell : null;

          return <span className='truncatedSpan' title={title}>{cell}</span>;
        }
      },
      {
        name: 'ingredients.table.suppliers',
        dataId:'suppliers_title',
        hidden: false,
      },
      {
        name: 'ingredients.table.uom',
        dataId:'default_uom',
        hidden: false,
        width: '85px'
      },
      {
        name: 'ingredients.table.costUnit',
        dataId:'avg_vendors_price',
        hidden: false,
        width: '80px',
        formatter: (cell, row) => <InternationalCurrencyStatic>{cell}</InternationalCurrencyStatic>
      },
      {
        name: 'ingredients.table.currentLevel',
        dataId:'qty',
        hidden: false,
        width: '80px',
        formatter:(cell) => {
          return (
            <InternationalDecimalStatic>{cell}</InternationalDecimalStatic>
          );
        }
      },
      {
        name: 'common.edit',
        formatter: (cell, row) => <Link to={`/ingredients/${row.item_master_id}/modify`} className='btn btn-primary'>{I18n.t('common.edit')}</Link>,
        dataSort: false,
      },
    ];
    const searchString = '';
    const sizePerPage = 50;

    const activeColumns = {
      name: '',
      hidden: false,
      width: '10%',
      dataSort: false,
      dataId: 'item_master_id',
      status: 'active',
      formatter: (id, row) => (
        <Button type='button' variant='primary' onClick={(event) => {this.inactivateIngredient(event, id);}}>
          {I18n.t('ingredients.actions.inactivate')}
        </Button>
      )
    };
    const inactiveColumns = {
      name: '',
      hidden: false,
      width: '10%',
      dataId: 'item_master_id',
      dataSort: false,
      formatter: (id, row) => (
        <Button type='button' variant='primary' onClick={(event) => {this.activateIngredient(event, id);}}>
          {I18n.t('ingredients.actions.activate')}
        </Button>
      )
    };

    this.state = {
      commonColumns,
      activeColumns,
      inactiveColumns,
      searchString,
      sizePerPage,
      status: props.params.status,
      activeTab: props.params.category,
      tabsLoaded: false
    };

    this.switchTab = this.switchTab.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.inactivateIngredient = this.inactivateIngredient.bind(this);
    this.activateIngredient = this.activateIngredient.bind(this);
    this.getIngredientItems = this.getIngredientItems.bind(this);
    this.isActiveStatus = this.isActiveStatus.bind(this);
    this.editIngredient = this.editIngredient.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.ref = React.createRef();
  }

  componentWillMount() {
    this.props.actions.getUnpaginatedData('/api/ingredient_categories', dataNames.ingredientCategories, null, null, (response) => {
      let categoryId = this.props.params.category;

      if (!response.length) {
        this.setState({tabsLoaded: true});
      }

      if (!categoryId) {
        const sortedResponse = sortBy(response, ['name', 'asc']);
        if (sortedResponse.length) {
          categoryId = sortedResponse[0].id;
        }
      }

      if (categoryId) {
        this.setState({activeTab: +categoryId});
        this.getIngredientItems(categoryId);
      }
    });
    this.props.actions.getUnpaginatedData('/api/partners', dataNames.partners, undefined, {purchase_from: 1});
  }

  componentDidUpdate(nextProps, nextState) {
    if(this.props.params.category != nextProps.params.category || this.props.params.status != nextProps.params.status){
      this.props.actions.unsetData(dataNames.ingredients);
      this.getIngredientItems();
    }
  }

  getIngredientItems(categoryId) {
    const {category, status} = this.props.params;
    this.props.actions.clearSelectedData(dataNames.ingredients);
    if (categoryId || category) {
      this.setState({status, tabsLoaded: false});
      this.props.actions.getUnpaginatedData('/api/ingredient_items',
        dataNames.ingredients,
        undefined,
        {ingredient_category_id: categoryId || category, active: status === 'active' ? 1 : 0, detailed: 1},
        (data) => {
          this.props.actions.getDataByPost('/api/items/by_item_master_ids',
            {ids: data.map((ingredient) => ingredient.id)},
            dataNames.inventoryItems,
            undefined,
            {detailed: 1}
          );
          this.setState({tabsLoaded: true});
        });
    }
  }


  handleSelect (action, rows) {
    this.props.actions.handleComplexSelectRow(rows, dataNames.ingredients, action);
  }

  switchTab(activeTab) {
    this.setState({activeTab});
    this.props.actions.push(`/ingredients/${this.state.status}/${activeTab}`);
  }

  inactivateIngredient(e, id){
    this.props.actions.putItem(
      `/api/ingredient_items/${id}`,
      {active: 0},
      dataNames.ingredients,
      {
        success: data => ['ingredients.inactive.success', {name: data.name}],
        failed: data => ['ingredients.inactive.fail', {name: data.name}],
      },
      null,
      data => this.getIngredientItems(data.ingredient_category_id || this.state.activeTab)
    );
  }

  activateIngredient(e, id){
    this.props.actions.putItem(
      `/api/ingredient_items/${id}`,
      {active: 1},
      dataNames.ingredients,
      {
        success: data => ['ingredients.active.success', {name: data.name}],
        failed: data => ['ingredients.active.fail', {name: data.name}],
      },
      null,
      () => this.getIngredientItems()
    );
  }

  isActiveStatus() {
    return this.state.status === 'active';
  }

  editIngredient() {
    this.props.actions.push(`/ingredients/modify/${this.props.selectedIngredients[0]}`);
  }

  onSearchChange(query) {
    if (!query) {
      this.props.actions.clearSelectedData(dataNames.ingredients);
    }
  }

  render () {
    const {ingredients, selectedIngredients, ingredientCategories} = this.props;
    const {commonColumns, activeTab, activeColumns, inactiveColumns} = this.state;
    const actions = [
        {id: 'createIngredient', path: '/ingredients/create' , text: 'ingredients.actions.createIngredients' , glyph: 'th-list', requireSelect: false},
        {id: 'viewInactiveIngredients', text: 'ingredients.actions.viewInactive' , requireSelect: false, hide: !this.isActiveStatus(), path: `/ingredients/inactive/${activeTab}`},
        {id: 'viewActiveIngredients', text: 'ingredients.actions.viewActive' , requireSelect: false, hide: this.isActiveStatus(), path: `/ingredients/active/${activeTab}`}
    ];
    const tabs =  ingredientCategories.map(category => ({id: category.id, eventKey: category.id, title: category.name, actions}));
    const columns = this.isActiveStatus() ? commonColumns.concat(activeColumns) : commonColumns.concat(inactiveColumns);
    const info = !ingredientCategories.length ? <Alert variant='warning'>{I18n.t('ingredients.ingredientCategoriesWereNotImported')}</Alert> : '';

    return (
      <div>
        <PageTitle primaryText={I18n.t('ingredients.listing')}/>
        <InProgressOverlay isActive={!this.state.tabsLoaded} message='Loading Ingredients' />
        {info}
        <TablePageWrapper
          ref={this.ref}
          settingKey='ingredients'
          columns = {columns}
          data = {ingredients}
          activeTab = {this.state.activeTab}
          tabs = {ingredientCategories.length ? tabs : null}
          actions = {ingredientCategories.length ? null : actions}
          switchTab = {this.switchTab}
          selectedRows = {selectedIngredients}
          handleSelect = {this.handleSelect}
          className = 'ingredients-page'
          hideScanSearch={true}
          hideExport = {true}
          pageSizeList={[5, 10, 20 ,50]}
          noSelectionMode={!this.isActiveStatus()}
          bstProps = {{
            options : {
              defaultSortName: ['name'],
              defaultSortOrder: ['asc'],
              onSearchChange: this.onSearchChange,
            },
          }}
        />
      </div>
    );

  }
}

IngredientsPage.propTypes = {
  ingredientCategories: PropTypes.array.isRequired,
  ingredients: PropTypes.array.isRequired,
  selectedIngredients: PropTypes.array.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  params: PropTypes.shape({
    category: PropTypes.string,
    status: PropTypes.string.isRequired
  })
};

// IngredientsPage.contextTypes = {
//   router: React.PropTypes.object.isRequired
// };

function mapStateToProps(state){
  return {
    ingredientCategories: getSortedIngredientsCategoriesWithTypes(state),
    selectedIngredients: getSelectedIngredientsIds(state),
    ingredients: getIngredientsForListing(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {handleComplexSelectRow, push, unsetData, clearSelectedData});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (IngredientsPage);
