import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {reset} from 'redux-form';
import {goBack, push} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';

import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import * as apiActions from '../../../actions/apiActions';
import FormWrapper from '../../common/form/FormWrapper';
import IngredientsFormWrapper from '../common/IngredientsFormWrapper';
import {getFlattenedLocations} from '../../../selectors/locationsSelectors';
import InProgressOverlay from '../../common/InProgressOverlay';
import {getSortedIngredientsCategoriesWithTypes} from '../../../selectors/ingredientCategoriesSelectors';
import getUomOptionsForSelect from '../../../selectors/forms/ingredientFormSelectors'; //eslint-disable-line

const initialValues = {
  uom_type: 'weight',
  vendors: [{}]
};

const formName = 'createIngredient';

export class CreateIngredientsFormPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.state = {
      processing: true,
      processingMessage: '',
    };
    this.endProcessing = this.endProcessing.bind(this);
    this.startProcessing = this.startProcessing.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount(){
    const promises = [
      this.props.actions.getUnpaginatedData('/api/ingredient_categories', dataNames.ingredientCategories),
      this.props.actions.getUnpaginatedData('/api/partners', dataNames.partners, undefined, {purchase_from: 1}),
      this.props.actions.getUnpaginatedData('/api/location_hierarchy', dataNames.locations, {failed: 'locations.getLocations.failed'}),
      this.props.actions.getUnpaginatedData('/api/uoms', dataNames.uoms),
    ];
    Promise.all(promises)
      .then(() => {this.endProcessing();})
      .catch(() => {this.endProcessing();});
  }

  startProcessing(processingMessage = '') {
    this.setState({
      processing: true,
      processingMessage,
    });
  }

  endProcessing() {
    this.setState({
      processing: false,
      processingMessage: '',
    });
  }

  onSubmit(formData) {
    //todo this should be not required once backend is fixed
    const selectedUom = this.props.uoms.reduce((acc, uom) => {
      if (uom.uom_code === formData.vendors[0].default_uom) {
        acc = uom;
      }
      return acc;
    }, {});
    const data = Object.assign({}, formData, {
      default_uom: selectedUom.uom_code,
      uom_type: selectedUom.uom_type,
    });
    this.props.actions.postItem(
      '/api/ingredient_items',
      data,
      itemNames.ingredient,
      {
        success: data => ['ingredients.create.success', {name: data.name, item_number: data.item_number}],
        failed: 'ingredients.create.fail', failedHandler: (errors) => {
          const validation = errors.response.data.errors.VALIDATION;
          return validation.name ? { message:  I18n.t('ingredients.create.fail') + ': ' + I18n.t('ingredients.create.failName') } : null;
        }
      },
      null,
      () => {
        this.props.actions.reset('CreateIngredients');
        if (formData.afterSubmit === 'redirect'){
          const ingredientCategoryId = formData.ingredient_category_id;
          if (ingredientCategoryId > 0) {
            this.props.actions.push('/ingredients/active/' + ingredientCategoryId);
          } else {
            this.props.actions.goBack();
          }
        }
      }
    );
  }

  render() {
    const {partners, ingredientCategories, locations, uoms} = this.props;

    return (
      <FormWrapper title={'ingredients.form.title'} goBack={this.props.actions.goBack}>
        <InProgressOverlay
          isActive={this.state.processing}
          message={this.state.processingMessage}
          showOk={false}
          showLoader={true}
          translate={true} />
        <IngredientsFormWrapper
          form={formName}
          initialValues={initialValues}
          ingredientCategories={ingredientCategories}
          suppliers={partners}
          locations={locations}
          uoms={uoms}
          onSubmit={this.onSubmit}
          submitButtonText={I18n.t('common.form.addIngredient')}
        />
      </FormWrapper>);
  }
}

CreateIngredientsFormPage.propTypes = {
  actions: PropTypes.object.isRequired,
  partners: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  ingredientCategories: PropTypes.array.isRequired,
  uoms: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  const {partners} = state;

  return {
    partners,
    ingredientCategories: getSortedIngredientsCategoriesWithTypes(state),
    locations: getFlattenedLocations(state),
    uoms: getUomOptionsForSelect(state)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({},
    apiActions,
    {goBack,  reset, push}
  );
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps) ( CreateIngredientsFormPage);
