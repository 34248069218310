module.exports =  [
  {
    id: 1,
    groupName: 'Group 1',
    standard:'100%'
  },
  {
    id: 2,
    groupName: 'Group 2',
    standard:'100%'
  },
  {
    id: 3,
    groupName: 'Group 3',
    standard:'100%'
  },
  {
    id: 4,
    groupName: 'Group 4',
    standard:'100%'
  },
  {
    id: 5,
    groupName: 'Group 5',
    standard:'100%'
  },
  {
    id: 6,
    groupName: 'Group 6',
    standard:'100%'
  }
];
