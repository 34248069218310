import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Table, Card} from 'react-bootstrap';
import ContentConcealer from '../../../common/concealers/ContentConcealer';

const WasteReportedDetails = ({model}) => {
  const {waste_weight, uom} = model;
  return (
    <Card>
      <Table striped className='plant-details'>
        <thead>
        <tr>
          <th>{I18n.t('plantHistory.field')}</th>
          <th>{I18n.t('plantHistory.value')}</th>
        </tr>
        </thead>
        <tbody>
        <ContentConcealer show={Boolean(waste_weight)}>
          <tr>
            <td>{I18n.t('plantHistory.plant.wasteReported')}</td>
            <td>{waste_weight} {uom || 'GR'}</td>
          </tr>
        </ContentConcealer>
        </tbody>
      </Table>
    </Card>
  );
};

WasteReportedDetails.propTypes = {
  model: PropTypes.object.isRequired,
};

export default WasteReportedDetails;
