import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {goBack} from 'react-router-redux';

import {
  Button,
  Col,
  Row,
  Card } from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import FormWrapper from '../common/form/FormWrapper';

const selected_product = {
  id: 'BUK1G000001',
  master_item: 'Bubba Kush',
  variant: '1 Gram',
  qty: 250,
  uom1: 'EA',
  total: 250,
  uom2: 'G',
  location: 'Location 1'
};

const products = [
  {
    id: 'BUK175G00001',
    master_item: 'Bubba Kush',
    variant: '1/16 oz',
    qty: 100,
    uom1: 'EA',
    total: 175,
    uom2: 'G',
    location: 'Location 1'
  },
  {
    id: 'BUK35G000001',
    master_item: 'Bubba Kush',
    variant: '1/8 oz',
    qty: 100,
    uom1: 'EA',
    total: 350,
    uom2: 'G',
    location: 'Location 2'
  },
  {
    id: 'BUK7G0000001',
    master_item: 'Bubba Kush',
    variant: '1/4 oz',
    qty: 60,
    uom1: 'EA',
    total: 420,
    uom2: 'G',
    location: 'Location 3'
  },
  {
    id: 'BUK14G000001',
    master_item: 'Bubba Kush',
    variant: '1/2 oz',
    qty: 0,
    uom1: 'EA',
    total: 0,
    uom2: 'G',
    location: 'Location 1'
  },
  {
    id: 'BUK280000001',
    master_item: 'Bubba Kush',
    variant: '1 oz',
    qty: 0,
    uom1: 'EA',
    total: 0,
    uom2: 'G',
    location: 'Location 1'
  }
];

export class QuickMoveProducts extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.redirect = this.redirect.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount(){
    //this.props.actions.getProducts();
  }

  redirect() {
    this.props.actions.goBack();
  }

  onSubmit() {
    this.props.actions.goBack();
  }

  render () {
    return (<FormWrapper className='quick-move-products-page' title={'products.quickMove'} goBack={this.redirect}>
      <form className='quick-move-products-form' onSubmit={this.onSubmit}>
        <Row>
          <Col xs={2}><strong>{I18n.t('products.form.variantId')}</strong></Col>
          <Col xs={2}><strong>{I18n.t('products.form.masterItem')}</strong></Col>
          <Col xs={1}><strong>{I18n.t('products.form.variant')}</strong></Col>
          <Col xs={1}><strong>{I18n.t('products.form.quantity')}</strong></Col>
          <Col xs={1}><strong>{I18n.t('products.form.uom')}</strong></Col>
          <Col xs={1}><strong>{I18n.t('products.form.total')}</strong></Col>
          <Col xs={1}><strong>{I18n.t('products.form.uom')}</strong></Col>
          <Col xs={1}><strong>{I18n.t('products.form.location')}</strong></Col>
          <Col xs={2}><strong>{I18n.t('products.form.editLoction')}</strong></Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Card body>
              <Row>
                <Col xs={2}>{selected_product.id}</Col>
                <Col xs={2}>{selected_product.master_item}</Col>
                <Col xs={1}>{selected_product.variant}</Col>
                <Col xs={1}>{selected_product.qty}</Col>
                <Col xs={1}>{selected_product.uom1}</Col>
                <Col xs={1}>{selected_product.total}</Col>
                <Col xs={1}>{selected_product.uom2}</Col>
                <Col xs={1}>{selected_product.location}</Col>
              </Row>
              <Row>
                <Col xs={{span: 1, offset: 5}}>
                  <div className='form-group'>
                    <input type='number' id='product[0].qty' className='form-control' />
                  </div>
                </Col>
                <Col xs={1}>{selected_product.uom1}</Col>
                <Col xs={1}>{50}</Col>
                <Col xs={1}>{selected_product.uom2}</Col>
                <Col xs={3}>
                  <div className='form-input form-group'>
                    <select name='starting_section_id' id='new_location' className='form-control'>
                      <option value='0'>Select...</option>
                      <option value='1'>Budtender Station 1</option>
                      <option value='2'>Budtender Station 2</option>
                      <option value='3'>Budtender Station 3</option>
                      <option value='4'>Budtender Station 4</option>
                      <option value='5'>Budtender Station 5</option>
                    </select>
                  </div>
                </Col>
              </Row>
              <Row className='border-top'>
                <Col xs={{span: 2, offset: 10}}>
                  <strong className='padding-right'>{I18n.t('products.form.addAnotherStation')}</strong>
                  <Button variant='primary' type='button' onClick={() => {}}>+</Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        {products.map((product, index) => {
          return (<Row key={index}>
            <Col xs={2}>{product.id}</Col>
            <Col xs={2}>{product.master_item}</Col>
            <Col xs={1}>{product.variant}</Col>
            <Col xs={1}>{product.qty}</Col>
            <Col xs={1}>{product.uom1}</Col>
            <Col xs={1}>{product.total}</Col>
            <Col xs={1}>{product.uom2}</Col>
            <Col xs={1}>{product.location}</Col>
          </Row>);
        })}
        <Row>
          <Col className='border-box-left' xs={{span: 1, offset: 6}}><h2>{I18n.t('products.form.total')}</h2></Col>
          <Col className='border-box-mid' xs={1}><h2>{'1195'}</h2></Col>
          <Col className='border-box-right' xs={1}><h2>{'G'}</h2></Col>
        </Row>
        <Row className='border-top'>
          <Col xs={{span: 2, offset: 10}}>
            <Button variant='primary' type='submit'>{I18n.t('products.form.save')}</Button>
          </Col>
        </Row>
      </form>
    </FormWrapper>);
  }
}

QuickMoveProducts.propTypes = {
  actions: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps){
  return {
    state
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, {goBack});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (QuickMoveProducts);
