import React from 'react';
import PropTypes from 'prop-types';

const RenderCurrWeightHistory = (props) => {
  const {field} = props;
  return (Array.isArray(field.prev) && Array.isArray(field.current)
    ? field.current.map(item2 => {
      const itter_item = field.prev.find(item1 => item1.pricing_weight_id === item2.pricing_weight_id);
      if (!itter_item) {
        return (<tr key={item2.title}>
          <td>{item2.title}</td>
          <td>null → {parseFloat(item2.default_price).toFixed(2)}</td>
        </tr>);
      }
    }) : null);
};

RenderCurrWeightHistory.propTypes = {
  field: PropTypes.object.isRequired,
};

export default RenderCurrWeightHistory;
