import {createSelector} from 'reselect';
import get from 'lodash.get';
import moment from 'moment-timezone';


export const getSelectedDeliveryOrders = (state) => state.selectedDeliveryOrders || [];
export const getSelectedCourierDeliveryOrders = (state) => state.selectedCourierDeliveryOrders || [];
export const getDeliveryOrder = (state) => state.deliveryOrder || {};
export const getCourierDeliveryOrders = (state) => state.courierDeliveryOrders || {};
export const getCourierDeliveryOrder = (state) => state.courierDeliveryOrder || {};
export const getCourierPartners = (state) => state.courierPartners || {};
export const getTimeZone = (state) => state.timezone || null;

export const getSelectedDeliveryOrdersId = createSelector(
  [getSelectedDeliveryOrders],
  (selectedDeliveryOrders) => selectedDeliveryOrders.map((selectedDeliveryOrder) => selectedDeliveryOrder.id)
);

export const getSelectedCourierDeliveryOrdersId = createSelector(
  [getSelectedCourierDeliveryOrders],
  (selectedDeliveryOrders) => {
    return selectedDeliveryOrders.map((selectedDeliveryOrder) => selectedDeliveryOrder.id);
  }
);

export const mapCourierDeliveryOrders = createSelector(
  [getCourierDeliveryOrders, getTimeZone],
  (courierDeliveryOrders, tz) => {
    return courierDeliveryOrders.map((deliveryOrder) => {
      const momentDate = get(deliveryOrder, 'delivery_expected_at', null) ? moment.utc(get(deliveryOrder, 'delivery_expected_at', null)).tz(tz) : null;
      return {
        ...deliveryOrder,
        delivery_method: get(deliveryOrder, 'delivery_method', 'in_house'),
        partner_facility_id: get(deliveryOrder, 'partner_facility_id', ''),
        delivery_status: get(deliveryOrder, 'delivery_status', 'pending'),
        delivery_expected_at: momentDate
      };
    });
  }
);

export const getDeliveryOrdersInitialValues = createSelector(
  [getDeliveryOrder, getTimeZone],
  (deliveryOrder, tz) => {

    const momentDate = get(deliveryOrder, 'delivery.delivery_expected_at', null) ? moment.utc(get(deliveryOrder, 'delivery.delivery_expected_at', null)).tz(tz) : null;
    return {
      delivery_method: get(deliveryOrder, 'delivery.delivery_method', 'in_house'),
      partner_facility_id: get(deliveryOrder, 'delivery.partner_facility_id', ''),
      delivery_status: get(deliveryOrder, 'delivery.delivery_status', 'pending'),
      delivery_expected_at: momentDate
    };
  }
);

export const getCourierDeliveryOrdersInitialValues = createSelector(
  [getCourierDeliveryOrder, getTimeZone],
  (deliveryOrder, tz) => {
    const momentDate = get(deliveryOrder, 'delivery_expected_at', null) ? moment.utc(get(deliveryOrder, 'delivery_expected_at', null)).tz(tz) : null;
    return {
      delivery_method: get(deliveryOrder, 'delivery_method', 'in_house'),
      partner_facility_id: get(deliveryOrder, 'partner_facility_id', ''),
      delivery_status: get(deliveryOrder, 'delivery_status', 'pending'),
      delivery_expected_at: momentDate
    };
  }
);

export const getCourierOptions = createSelector(
  [getCourierPartners],
  (couriersPartners) => couriersPartners.map((courierPartner) => ({
    text: `${courierPartner.facility_name} (${courierPartner.legal_name})`,
    value: courierPartner.id
  }))
);

export const getConsumerInfo = createSelector(
  [getDeliveryOrder],
  (deliveryOrder) => {
    return {
      consumer_name: get(deliveryOrder, 'consumer_name', ''),
      consumer_address_line_1: `${get(deliveryOrder, 'delivery_address.street_address_1', '') || ''}, ${get(deliveryOrder, 'delivery_address.street_address_2', '') || ''}`,
      consumer_address_line_2: `${get(deliveryOrder, 'delivery_address.city', '') || ''}, ${get(deliveryOrder, 'delivery_address.province_code', '') || ''}  ${get(deliveryOrder, 'delivery_address.postal_code', '') || ''}`
    };
  }
);

export const getCourierOrderDetails = createSelector(
  [getCourierDeliveryOrder],
  (deliveryOrder) => {
    return {
      categorized_products: get(deliveryOrder, 'categorized_products', []),
      order_total: get(deliveryOrder, 'order_total', 0)
    };
  }
);

export const getCourierConsumerInfo = createSelector(
  [getCourierDeliveryOrder],
  (deliveryOrder) => {
    return {
      consumer_name: get(deliveryOrder, 'consumer_name', ''),
      consumer_address_line_1: `${get(deliveryOrder, 'delivery_address.street_address_1', '') || ''}, ${get(deliveryOrder, 'delivery_address.street_address_2', '') || ''}`,
      consumer_address_line_2: `${get(deliveryOrder, 'delivery_address.city', '') || ''}, ${get(deliveryOrder, 'delivery_address.province_code', '') || ''}  ${get(deliveryOrder, 'delivery_address.postal_code', '') || ''}`
    };
  }
);

export const getOrderDetails = createSelector(
  [getDeliveryOrder],
  (deliveryOrder) => {
    return {
      categorized_products: get(deliveryOrder, 'categorized_products', []),
      order_total: get(deliveryOrder, 'order_total', 0)
    };
  }
);




