/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';
import {bindActionCreators} from 'redux';
import {batchActions} from 'redux-batched-actions';
import moment from 'moment-timezone';
import get from 'lodash.get';
import isEqual from 'lodash.isequal';
import {change, touch, formValueSelector} from 'redux-form';
import * as formNames from '../../../constants/formNames';
import * as itemNames from '../../../constants/itemNames';
import * as dataNames from '../../../constants/dataNames';
import * as statuses from '../../../constants/statuses';
import * as labResultsSelector from '../../../selectors/lab-results';
import {getUnpaginatedData, postData, getItem, getData, postItem, getDataByPost} from '../../../actions/apiActions';
import {addSelectedData} from '../../../actions/selectedDataActions';
import {unsetData} from '../../../actions/dataActions';
import {unsetItem} from '../../../actions/itemActions';
import {addMessage} from '../../../actions/systemActions';
import * as messageTypes from '../../../constants/messageTypes';
import * as complianceSettings from '../../../selectors/complianceSettingsSelectors';
import { loadInventoryForSalesOrders } from '../../../actions/transfer';
import FormWrapper from '../../common/form/FormWrapper';
import InProgressOverlay from '../../common/InProgressOverlay';
import TransferForm from './TransferForm'; // eslint-disable-line import/no-named-as-default
import TransferFormReact from './TransferFormReact';
import {getFlattenedStorageLocations} from '../../../selectors/storageLocationsSelectors';
import {
  getChildItemMastersForFacility,
  getCreateTransferEditablePrices,
  getCreateTransferFormData,
  getCreateTransferFormValues,
  getCreateTransferLineTotals,
  getCreateTransferSalesOrders,
  getCreateTransferSentTotals,
  getFilteredInventoryItems,
  isDriverCompany,
  isForLabPartner,
  needTestResults,
  getCreateTransferSentNetTotals,
  getDistributionSettings
} from '../../../selectors/transfersSelectors';
import {
  getSalesOrdersDestinations,
  getSalesOrdersLines,
  getSalesOrdersTotals,
  getSelectedSalesOrders,
  getTransferLinesLimit,
  isMedicatedOrder,
  getIsReturn
} from '../../../selectors/salesOrdersSelectors';
import {
  getValidDrivers,
  mapDriversArrayToDbFields
} from '../../../selectors/forms/salesTransferFormSelectors';
import {convertFormInputDateToDbDate, convertFormInputDateToDbTime, isMoment} from '../../../util/dateHelpers';
import {getIntegrationState} from '../../../selectors/integration/integrationSelectors';
import {roundFloat} from '../../../util/mathHelpers';
import {getFacilityType} from '../../../selectors/facilitiesSelectors';
import {checkPackageForLeafWaAndPa} from '../../../selectors/integration/leafSelectors';
import productSubTypes from '../../../constants/salesOrderMetaData';
import {isMetrcTransfersEnabled} from '../../../selectors/integration/metrcSelectors';
import {isMappingRequiredForSupply} from '../../../selectors/supplySelectors';
import {getInternationalNumberFormat} from '../../../selectors/InternationalOperationsSelectors';
import {isFeatureEnabled} from '../../../selectors/featureToggles';
import {isValidReturn, isValidTransfer} from '../helpers';
import PackageStore from '../../../selectors/inventory/package';

const formName = formNames.createTransfer;
class CreateTransferPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.calcTotals = this.calcTotals.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.goBack = this.goBack.bind(this);
    this.createDriver = this.createDriver.bind(this);
    this.loadInventory = this.loadInventory.bind(this);
    this.startProcessing = this.startProcessing.bind(this);
    this.endProcessing = this.endProcessing.bind(this);
    this.showConfirmationPopup = this.showConfirmationPopup.bind(this);
    this.validateNegativeInventory = this.validateNegativeInventory.bind(this);

    this.state = {
      processing: true,
      processingMessage: 'Loading...',
      prepackExpendedInventory: [],
      expendedInventory: [],
      salesOrdersLoaded: false,
      showNegativeInvConfirmation: {
        show: false,
        onHide: () => null,
      },
      dataLoaded: false,
      changesLoaded: false,
      isSubmitClicked: false
    };
  }

  componentWillMount() {
    const { integrationState } = this.props;
    const { isMetrc, isMetrcTransfersEnabled } = integrationState;

    this.getSalesOrderData(this.props);

    if (isMetrc && isMetrcTransfersEnabled) {
      this.props.actions.getUnpaginatedData('/api/metrc/transfers/types', dataNames.metrcTransferTypes);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { packageStore, actions, childItemMasters, itemMasters } = this.props;
    const { changesLoaded } = this.state;

    // Retrieve the query parameters
    const query = this.props.location ? this.props.location.query : {};
    const { package_key, package_code } = query;

    // Check if any of the item_masters are prepacks, if so we need the prepack children available as well
    const hasPrepack = itemMasters.filter((item) => { return get(item, 'inventory_attributes.is_prepack') === 1 }).length;
    const productsLoaded = (itemMasters.length && ( !hasPrepack || Object.keys(childItemMasters).length ) )

    // Ensure the product data for matching the packages is loaded into the component properties before trying
    // to set the package for the inventory_line when passing in package adjustments via the query string
    // this will allow the change event in the salesTransferFormMiddleware to process the change event for the
    // package_code
    if (productsLoaded) {
      if (package_key && package_code && !changesLoaded) {
        const item = packageStore.findTransferPackageByField(package_code, 'package_code', 'create');
        if (item) {
          actions.change(formName, package_key, package_code);
          this.setState({changesLoaded: true});
        }
      }
    }

    if (!isEqual(this.props.salesOrderIds, nextProps.salesOrderIds)) {
      this.getSalesOrderData(nextProps);
    }
  }

  loadInventory() {
    const {salesOrders} = this.props;
    this.props.actions.loadInventoryForSalesOrders(salesOrders);
  }

  getSalesOrderData({ salesOrderIds }){
    if(!Array.isArray(salesOrderIds)) {
      salesOrderIds = [salesOrderIds];
    }

    // From here down using this.props if for no other reason than that it can be easily moved if needed without a props arg dep
    this.props.actions.batchActions([
      unsetData(dataNames.childItemMasters),
      unsetData(dataNames.salesOrders),
      unsetData(dataNames.inventoryItems),
      unsetItem(itemNames.transfer),
      unsetData(dataNames.inventoryItemsTestResults),
      unsetData(dataNames.reservations),
      unsetData(dataNames.hardReservations),
      unsetData(dataNames.itemMasters),
    ]);

    const promises = [
      this.props.actions.postData(`/api/sales_orders/multiple`, {ids: salesOrderIds}, dataNames.salesOrders, {failed: 'transfers.get.salesOrdersFail'}, {detailed:'true', with_partner_details: 1 }),
      this.props.actions.getItem('/api/integration-settings', itemNames.integrationSettings, {failed: 'stateIntegratorSettings.get.failed'}, undefined, undefined, {debounce: true}),
      this.props.actions.getUnpaginatedData('/api/location_hierarchy', dataNames.storageLocations, {failed: 'failedToGetLocations'}, undefined, undefined, {debounce: true}),
      this.props.actions.getUnpaginatedData('/api/drivers/details', dataNames.drivers, {failed: 'failedToGetDrivers'}, {active: 1}, undefined, {debounce: true})
        .then((data) => {
          if(this.props.integrationState.isPaLeaf) {
            const params = {in_ids: data.map((driver) => driver.user_id).filter(driver => driver)};
            this.props.actions.getDataByPost('/api/users/current_facility', params, dataNames.users, {}, {paginate: 0});
          }
        }),
      this.props.actions.getUnpaginatedData('/api/partners', dataNames.partners, {failed:'partners.get.failed'}, {exclude_internal_duplicates: 1}, undefined, {debounce: true}),
      this.props.actions.getUnpaginatedData('/api/vehicles', dataNames.vehicles,undefined, undefined, undefined, {debounce: true}),
      this.props.actions.getUnpaginatedData('/api/partner_facilities', dataNames.partnerFacilities,undefined, undefined, undefined, {debounce: true}),
      this.props.actions.getUnpaginatedData('/api/prepack_weights/facility', dataNames.prepackFacilityWeights, undefined, {active: 1}, undefined, {debouce: true}),
      this.props.actions.getUnpaginatedData('/api/prepack_weights', dataNames.prepackWeights, undefined, undefined, undefined, {debouce: true}),
      this.props.actions.getUnpaginatedData('/api/production_runs', dataNames.productionRuns, {failed: 'productionRuns.get.failed'}, {detailed: 1, status: statuses.open}),
      this.props.actions.getItem('/api/compliance_settings', itemNames.complianceSettings),
      this.props.actions.getItem('/api/supply/settings', itemNames.supplySettings),
      this.props.actions.getItem('/api/facility_groups_sharings/facility_status', itemNames.facilitySharingStatus),
      this.props.actions.getItem('/api/cultivation/settings', itemNames.wasteCompliance, null, {ids: ['distributions_transfer_line_limit']}), //?
      this.props.actions.getItem('/api/distribution/settings', itemNames.distributionSettings)
    ];

    // Take a beat and let the loading message show
    setTimeout(() => {
      Promise.all(promises)
        .then(this.loadInventory)
        .then(() => {
          this.endProcessing();
        })
        .catch(() => {
          this.endProcessing();
        });
    }, 1);

  }


  componentDidUpdate(prevProps) {
    const { formValues,childItemMasters } = this.props;
    const { lines, partner_discount, excise_tax_due, transfer_fee } = formValues;

    // prevent all calculations from running until we've set the editableLinePrice state
    let canRun = false;
    if(lines.length > 0){
      if(lines[0].editableLinePrice !== undefined) canRun = true;
    }

    if(prevProps && canRun){
      // If the line has en editable line item price (in which case unit_price is locked out)... set unit_price and line_item_price
      // Note that setting line_item price just manipulates the form object so we have it available on a switch from editable to calculated
      if(lines.length > 0) {
        if (lines[0].editableLinePrice !== undefined) {
          lines.map((line, lineIndex) => {
            const index =  get(line, 'item_master_id', false);
            const itemMaster = get(childItemMasters, index, false);
            const lineItemPrice = parseFloat(get(line, 'line_item_price', 0));

            const quantity = (line.inventory)
              ? line.inventory.reduce((acc, item) => {
                const inventoryItemMaster = (itemMaster)
                  ? itemMaster.find( (master) => master.id === item.item_master_id)
                  : false;

                if (item.qty === undefined) return acc;
                acc += (inventoryItemMaster)
                  ? parseFloat(item.qty * (inventoryItemMaster.prepack_fulfillment_units / 100))
                    : parseFloat(item.qty);

                return acc;

              }, 0) : 0;

            let currentUnitPrice = parseFloat(line.unit_price);
            let unitPrice = (lineItemPrice !== 0 && quantity !== 0) ? roundFloat(lineItemPrice / quantity, 2) : 0;

            if (line.editableLinePrice) {

              if (isNaN(currentUnitPrice)) currentUnitPrice = 0;
              if (isNaN(unitPrice)) unitPrice = 0;

              if (currentUnitPrice !== unitPrice) {
                this.props.actions.change(formName, `lines[${lineIndex}].unit_price`, unitPrice.toFixed(2));

                if(line.inventory){

                  line.inventory.forEach( (inventory, inventoryIndex) => {
                    const inventoryItemMaster = (itemMaster) ? itemMaster.find( (master) => master.id === inventory.item_master_id) : false;
                    if(inventoryItemMaster) {
                      const prepackUnitPrice = (unitPrice * (inventoryItemMaster.prepack_fulfillment_units / 100));
                      this.props.actions.change(formName, `lines[${lineIndex}].inventory[${inventoryIndex}].unit_price`, prepackUnitPrice.toFixed(2));
                    }
                  });
                }
              }
            }
          });
        }
      }

      if(lines !== prevProps.formValues.lines ||
        partner_discount !== prevProps.formValues.partner_discount ||
        excise_tax_due !== prevProps.formValues.excise_tax_due ||
        transfer_fee !== prevProps.formValues.transfer_fee) {
        this.calcTotals();
      }
    }
  }

  showConfirmationPopup(resolve, reject) {
    const hidePopup = (cb) => () => {
      this.setState({
        showNegativeInvConfirmation: {
          show:false,
          onConfirm: null,
          onHide: null
        }
      });
      cb();
    };
    this.setState({
      showNegativeInvConfirmation: {
        show: true,
        onConfirm: hidePopup(resolve),
        onHide: hidePopup(reject),
      }
    });
  }

  validateNegativeInventory(payload) {
    return new Promise((resolve, reject) => {
      const handlePackages = (result, line) => {
        get(line, 'inventory', []).forEach((inv) => {
          if (inv.qty > inv.qty_available) {
            result =  true;
          }
        });

        return result;
      };
      const showPopup = payload.lines.reduce(handlePackages, false);

      if (!showPopup || !this.props.isAllowNegativeInventory) {
        return resolve();
      }

      this.showConfirmationPopup(resolve, reject);
    });
  }

  calcTotals() {
    const {lines, partner_discount, excise_tax_due, transfer_fee} = this.props.formValues;

    const subtotal = lines ? lines.reduce((accumulator, line) => {
      // line_item_price price value is not being updated, so we will calculate it
      const {unit_price, line_type} = line;
      let lineTotal = 0;
      if(line.editableLinePrice){ // include only once for editable line prices
        lineTotal += parseFloat(line.line_item_price);
      } else {
        line.inventory.forEach(item => {
          if (parseFloat(item.qty)) {
            if (line_type === 'prepack' && parseFloat(item.unit_price)) {
              lineTotal += parseFloat(item.unit_price) * parseFloat(item.qty);
            }
            else if (parseFloat(unit_price)) {
              lineTotal += parseFloat(unit_price) * parseFloat(item.qty);
            }
          }
        });
      }
      return accumulator + lineTotal;
    }, 0) : 0;
    const discount = ((parseFloat(partner_discount) / 100) * subtotal);
    const order_total = parseFloat(excise_tax_due) + parseFloat(transfer_fee) + subtotal - discount;
    this.props.actions.change(formName, 'destination_total', order_total.toFixed(2));
  }

  onSubmit(result) {
   this.setState({isSubmitClicked:true});
    const payload = Object.assign({}, result, {
      date_transferred: result.date_transferred && convertFormInputDateToDbDate(result.date_transferred, this.props.timezone)
    }, mapDriversArrayToDbFields(result.drivers));

    if (payload.destinations && payload.destinations.length) {
      const dateExpected = moment(get(payload, 'sales_orders[0].date_expected') || new Date());
      const dateMonthYear = {
        date: dateExpected.get('date'),
        month: dateExpected.get('month'),
        year: dateExpected.get('year')
      };
      payload.destinations = payload.destinations.map(destination => Object.assign({}, destination, {
        departure_time: isMoment(destination.departure_time) ? convertFormInputDateToDbTime(destination.departure_time.set(dateMonthYear), this.props.timezone) : undefined,
        arrival_time: isMoment(destination.arrival_time) ? convertFormInputDateToDbTime(destination.arrival_time.set(dateMonthYear), this.props.timezone) : undefined
      }));
    }

    if(payload.status === 'open' && payload.date_ordered){
      payload.status = 'out_for_delivery';
    }

    if(!payload.move_all_inventory){
      payload.new_location_id = undefined;
    }

    // Removing unnecessary values from the sales order.
    // Sales orders are updated based on transfer values - not anything in the sales order
    // request body.
    payload.sales_orders.map((order) => {
      const fields = ['order_total', 'taxes', 'transfer_fee', 'discount_percent', 'lines'];
      fields.forEach((field) => {
        delete(order[field]);
      });
      return order;
    });
    // conditionally map ordered_qty to transfer_qty
    payload.lines = payload.lines.map( (line, index) => {
      line.update_sales_order_line = 1;
      line.line_item_price = this.props.lineTotals[index];
      if(line.update_sales_order_line_quantity !== undefined) {
        if (line.update_sales_order_line_quantity === 1) {
          line.transfer_qty = line.ordered_qty;
        }
      }
      line.inventory = (line.inventory || []).map(inventory => {
        const temp = inventory.hybrid_location_id.split('_');
        const inventoryItemId = parseInt(temp[0]);
        const inventoryItems = get(this.props, `inventoryItems.${line.item_master_id}`);
        const inventoryItem = inventoryItems ? inventoryItems.find(item => item.id == inventory.inventory_item_id) : undefined;
        return {
          ...inventory,
          inventory_item_id: inventoryItemId, // Specifically for prepacks but used for all, gets id from hybrid_location_id (HOTFIX: 07/03/2018 on 1.2.3)
          disposal_reason: line.is_waste_disposal ? inventory.disposal_reason || payload.disposal_reason : undefined,
          sent_gross: inventory.sent_gross != '' ? inventory.sent_gross : null,
          sent_net:  inventory.sent_net != '' ? inventory.sent_net : null,
          medically_compliant: get(inventoryItem, 'medically_compliant') || 0,
          medically_compliant_status: get(inventoryItem, 'medically_compliant_status', null),
          plant_batch_number: get(inventoryItem, 'plant_batch_number', null),
          harvest_batch_number: get(inventoryItem, 'harvest_batch_number', null),
          source_strain: get(inventoryItem, 'source_strain', null),
          harvested_at: get(inventoryItem, 'harvested_at', null) || null,
        };
      });
      return line;
    });

    // Hotfix - this is not optimal but transfers are broken on production due to null not validating
    // Saw this in testing the hotfix when merged to release/0.4.3.  I don't know why the behavior
    // changed on hotfix... but it has... tested heavily Friday 06/30 and nulls went through on hotfix
    // but today do not.
    const denulls = [];
    for(const prop in payload){
      if(payload[prop] === null) denulls.push(prop);
    }

    denulls.forEach((prop) => {
      delete(payload[prop]);
    });

    const inventoryItemIds = [];
    const inventoryItemIdsToCheckLabResults = [];
    const inventoryItemPackageCode = {};
    const stateIntegrationTrackingIds = [];
    payload.lines.forEach(line => line.inventory.forEach(item => {
      if (item.inventory_item_id && line.is_medicated) {
        inventoryItemIds.push(item.inventory_item_id);
        stateIntegrationTrackingIds.push(item.state_integration_tracking_id);
        if(!line.is_waste_disposal) inventoryItemIdsToCheckLabResults.push(item.inventory_item_id);
        inventoryItemPackageCode[item.inventory_item_id] = item.package_code;
      }
    }));

    const hasMedicated = inventoryItemIds.length > 0;

    const validateTags = () => {
      if (this.props.inventoryComplianceSettings.inv_packages_require_tracking_id) {
        if (stateIntegrationTrackingIds.includes(null)) {
          this.props.actions.addMessage(messageTypes.error, this.props.inventoryComplianceSettings.inv_packages_require_tracking_id_message, true);
          return Promise.reject();
        }
      }

      return Promise.resolve();
    };

    const getPartnerFacilityType = (partnerFacilityId) => {
      return this.props.actions.getItem(`/api/partner_facilities/facility_details/${partnerFacilityId}`, itemNames.partnerFacilityDetails)
        .then((partnerFacility) => {
          return partnerFacility.type;
        });
    }

    /* eslint-disable */
    const checkTransferFacility = async () => {
      // Any facility type can transfer to a lab partner
      if (this.props.isForLabPartner) {
        return Promise.resolve();
      }

      const transferType = get(payload, 'sales_orders[0].transfer_type', 'wholesale');

      // Returns can be made from a dispensary to a manufacturing and a internal dispensary
      const isReturn = get(payload, 'sales_orders[0].is_return', false);
      if (isReturn) {
        for (const destination of payload.destinations) {
          if (destination.partner_facility_id) {
            const partnerFacilityType = await getPartnerFacilityType(destination.partner_facility_id);
            if (!isValidReturn(this.props.currentFacilityType, partnerFacilityType, transferType)) {
              Promise.reject({message: 'cultivation.transfers.form.transferReturnLimitation'})
            }
          }
        }
        return Promise.resolve();
      }

      for (const destination of payload.destinations) {
        if (destination.partner_facility_id) {
          const partnerFacilityType = await getPartnerFacilityType(destination.partner_facility_id);
          if (!isValidTransfer(this.props.currentFacilityType, partnerFacilityType, transferType)) {
            Promise.reject({message: 'cultivation.transfers.form.transferLimitation'});
          }
        }
      }
      return Promise.resolve();
    };
    /* eslint-enable */


    const checkLabResults = () => {
      if (inventoryItemIdsToCheckLabResults.length > 0) {
        const {isLeaf} = this.props.integrationState;
        const url = '/api/lab_results/by_item_id/multiple';
        const payload = {ids: inventoryItemIdsToCheckLabResults};
        const messages = {failed: 'cultivation.transfers.checkLabResults.failed'};

        if (isLeaf) {
          payload.state_integration_source = 'leaf';
        }

        return this.props.actions.postItem(url, payload, null, messages)
          .then((results) => {
            const labResultPassed = (result) => {
              if (isLeaf) {
                return result.lab_result.status
                  && typeof result.lab_result.status === 'string'
                  && result.lab_result.state_integration_source === 'leaf'
                  && result.lab_result.status.toLowerCase() === 'passed';
              } else { // future proof if included for other cases
                return result.lab_result.status
                  && typeof result.lab_result.status === 'string'
                  && result.lab_result.status.toLowerCase() === 'passed';
              }
            };

            if (Array.isArray(results)) {
              if (!recheckUpdatedLabResSetMsgError(results, labResultPassed)) {
                return Promise.reject();
              }
            }
          });
      }
      else {
        return Promise.resolve();
      }
    };

    const recheckUpdatedLabResSetMsgError = (results, labResultPassed) => {
      let valid = true;
      if (!this.props.isForLabPartner && this.props.needTestResults) {
        results.forEach(result => {
          if(this.props.integrationState.isWaLeaf){
            const testResultsMessage = testResultsMessageWA(results,result.item_id);
            if(testResultsMessage){
              valid = false;
              this.props.actions.addMessage(
                'error', ['cultivation.transfers.checkLabResults.waValidateTransfer.' + testResultsMessage, {packageCode: inventoryItemPackageCode[result.item_id]}]
              );
            }
          }
          else {
            if (!result.lab_result) {
              this.props.actions.addMessage(
                'error', ['cultivation.transfers.checkLabResults.packageMissingLabResults', {packageCode: inventoryItemPackageCode[result.item_id]}]
              );
              valid = false;
            }
            if (!this.props.integrationState.isPaLeaf && !labResultPassed(result)) {
              this.props.actions.addMessage(
                'error', ['cultivation.transfers.checkLabResults.packageFailedLabResults', {packageCode: inventoryItemPackageCode[result.item_id]}]
              );
              valid = false;
            }
          }
        });
      }
      return valid;
    };

    const testResultsMessageWA = (results,currentItemId) => {
      let inventoryItemForItemMaster = {};
      const selectedItem = this.props.formValues.lines.filter(line => {
        return line.inventory.find(inventory => inventory.inventory_item_id === currentItemId);
      });
      if (this.props.inventoryItems[selectedItem[0].item_master_id]) {
        inventoryItemForItemMaster = (this.props.inventoryItems[selectedItem[0].item_master_id].find(item => item.id.toString() === currentItemId.toString()));
      }
      const packageData = {
        testResults: results,
        meta: selectedItem[0].meta,
        productSubTypes,
        salesOrder: this.props.salesOrders.length > 0 ? this.props.salesOrders[0] : {},
        item: inventoryItemForItemMaster,
        selectedItem: selectedItem[0].inventory[0]
      };
      return checkPackageForLeafWaAndPa(packageData);
    };

    const validateLeafTransfer = () => this.props.actions.postItem('/api/leaf/validate/transfer',
     payload, null, undefined, undefined, undefined , {silenceErrors: true});

    const sendTransferRequest = () => this.props.actions.postItem('/api/transfers',
      payload,
      itemNames.transfer,
      {success:'transfers.create.success', failed:'transfers.create.fail'},
      undefined,
      (data) => {
        if(get(result, 'afterSubmit') === 'goToModify'){
          return this.props.actions.push(`/transfers/modify/${data.id}?print=1`);
        }
        const status = payload.status;
        if (status === 'open' || status === 'out_for_delivery') {
          this.props.actions.push('/transfers');
        } else if (status === 'completed') {
          this.props.actions.push('/transfers/inactive');
        } else {
          this.goBack();
        }
      }
    );

    if(this.props.integrationState.isLeaf && hasMedicated) {
      return validateLeafTransfer()
        .then(() => {
          Promise.all([
            this.validateNegativeInventory(payload),
            checkTransferFacility(),
            checkLabResults(),
          ])
            .then(sendTransferRequest)
            .catch((error) => {
              if(error.response && error.response.data && error.response.data.errors){
                const {errors} = error.response.data;
                errors.map(e => {
                  if(e.inventory_item_id){
                    const message = e.message === 'Quarantined' ? 'supplyChainMapping.transfers.integrationErrorQuarantined' : 'supplyChainMapping.transfers.integrationError';
                    this.props.actions.addMessage(messageTypes.error, [message, e]);
                  }else{
                    this.props.actions.addMessage(messageTypes.error, e);
                  }
                });
              }else{
                this.props.actions.addMessage(messageTypes.error, error.message);
              }
            });
        })
        .catch((error) => {
          if(error.response && error.response.data && error.response.data.errors){
            const {errors} = error.response.data;
            errors.map(e => {
              if(e.inventory_item_id){
                this.props.actions.addMessage('error', `${e.message} for package ${e.package_code}`);
              }else{
                this.props.actions.addMessage('error', e);
              }
            });
          }
        });
    } else {
      return this.validateNegativeInventory(payload)
        .then(checkLabResults)
        .then(validateTags)
        .then(sendTransferRequest)
        .catch((error) => {});
    }

  }

  createDriver(event) {
    this.props.actions.push('/driver/create');
  }

  startProcessing(processingMessage = '') {
    this.setState({
      processing: true,
      processingMessage,
    });
  }

  endProcessing() {
    this.setState({
      processing: false,
      processingMessage: '',
      dataLoaded: true,
    });
  }

  goBack() {
    this.props.actions.push('transfers/active');
  }

  render() {
    const { showNegativeInvConfirmation } = this.state;
    const {
      drivers,
      constants,
      sentTotals,
      lineTotals,
      salesOrders,
      isMedicated,
      isReturn,
      sentNetTotals,
      isDriverCompany,
      isWasteDisposal,
      needTestResults,
      integrationState,
      transferLinesLimit,
      isAllowNegativeInventory,
      isMappingRequiredByPlatform,
      inventoryItemsTestResults,
      isMetrcTransfersEnabled,
      internationalNumberFormat,
      integrationTransferTypes,
      formValues,
      isSplitPackageRedirectToggled,
      isTransferFormReactToggled,
      currentFacilityType,
      distributionSettings,
      isPaRemediationLabelsToggled,
      prepackWeights
    } = this.props;
    const isMVP = true;

    const hideCompletedStatus = integrationState.isBiotrack || (isMappingRequiredByPlatform && isMedicated);

    const getSimpleUi = () => { //eslint-disable-line
      return (<FormWrapper className='create-transfer-page' title='cultivation.transfers.create' goBack={this.goBack} >
        <InProgressOverlay
          isActive={this.state.processing}
          message={this.state.processingMessage}
          showOk={false}
          showLoader={true}
          translate={true}
        />
        <div style={{height: '100px'}}>
          &nbsp;
        </div>
      </FormWrapper>);
    };

    let form;
    if (isTransferFormReactToggled) {
      form = (<TransferFormReact
        orders={salesOrders}
        sentTotals={sentTotals}
        lineTotals={lineTotals}
        isMVP={isMVP}
        form={formName}
        formName={formName} /*not a mistake, passing down to package selections and want it to be clear what it is*/
        onSubmit={this.onSubmit}
        handleKeyPress={this.handleScanSearch}
        formData={this.props.formData}
        showNegativeInvConfirmation={showNegativeInvConfirmation}
        createDriver={this.createDriver}
        prepackExpendedInventory={this.state.prepackExpendedInventory}
        expendedInventory={this.state.expendedInventory}
        isComplete={false}
        integrationState={integrationState}
        isDriverCompany={isDriverCompany}
        isAllowNegativeInventory={isAllowNegativeInventory}
        isWasteDisposal={isWasteDisposal}
        isMedicated={isMedicated}
        isReturn={isReturn}
        isReceivedTransfer={false}
        needTestResults={needTestResults}
        inventoryItemsTestResults={inventoryItemsTestResults}
        drivers={drivers}
        transferLinesLimit={transferLinesLimit}
        constants={constants}
        isMetrcTransfersEnabled={isMetrcTransfersEnabled}
        hideCompletedStatus={hideCompletedStatus}
        sentNetTotals={sentNetTotals}
        internationalNumberFormat={internationalNumberFormat}
        integrationTransferTypes={integrationTransferTypes}
        currentTransferStatus={formValues && formValues.status}
        isSplitPackageRedirectToggled={isSplitPackageRedirectToggled}
        moveAllInventoryChecked={formValues && formValues.move_all_inventory}
        currentFacilityType={currentFacilityType}
        distributionSettings={distributionSettings}
        isSubmitClicked={this.state.isSubmitClicked}
      />);
    } else {
      form = (<TransferForm
        orders={salesOrders}
        sentTotals={sentTotals}
        lineTotals={lineTotals}
        isMVP={isMVP}
        form={formName}
        formName={formName} /*not a mistake, passing down to package selections and want it to be clear what it is*/
        onSubmit={this.onSubmit}
        handleKeyPress={this.handleScanSearch}
        formData={this.props.formData}
        showNegativeInvConfirmation={showNegativeInvConfirmation}
        createDriver={this.createDriver}
        prepackExpendedInventory={this.state.prepackExpendedInventory}
        expendedInventory={this.state.expendedInventory}
        isComplete={false}
        integrationState={integrationState}
        isDriverCompany={isDriverCompany}
        isAllowNegativeInventory={isAllowNegativeInventory}
        isWasteDisposal={isWasteDisposal}
        isMedicated={isMedicated}
        isReturn={isReturn}
        isReceivedTransfer={false}
        needTestResults={needTestResults}
        inventoryItemsTestResults={inventoryItemsTestResults}
        drivers={drivers}
        transferLinesLimit={transferLinesLimit}
        constants={constants}
        isMetrcTransfersEnabled={isMetrcTransfersEnabled}
        hideCompletedStatus={hideCompletedStatus}
        sentNetTotals={sentNetTotals}
        internationalNumberFormat={internationalNumberFormat}
        integrationTransferTypes={integrationTransferTypes}
        currentTransferStatus={formValues && formValues.status}
        isSplitPackageRedirectToggled={isSplitPackageRedirectToggled}
        moveAllInventoryChecked={formValues && formValues.move_all_inventory}
        currentFacilityType={currentFacilityType}
        distributionSettings={distributionSettings}
        isSubmitClicked={this.state.isSubmitClicked}
        isPaRemediationLabelsToggled={isPaRemediationLabelsToggled}
        prepackWeights={prepackWeights}
      />);
    }

    const getFullUi = () => { //eslint-disable-line
      return (
        <FormWrapper className='create-transfer-page' title='cultivation.transfers.create' goBack={this.goBack}>
          <InProgressOverlay
            isActive={this.state.processing}
            message={this.state.processingMessage}
            showOk={false}
            showLoader={true}
            translate={true}
          />
          {form}
        </FormWrapper>
      );
    };

    // This is a hack of the first order but these pages are coming up in the next week
    // for refactoring.  This solves a enableReinitialize race condition that is causing
    // some total fields to not populate without risking any side effects a larger fix
    // might bring.
    return (!this.state.processing) ? getFullUi() : getSimpleUi();
  }
}

CreateTransferPage.propTypes = {
  editableLinePrices: PropTypes.array.isRequired,
  salesOrders: PropTypes.array.isRequired,
  vehicles: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  destinations: PropTypes.array.isRequired,
  lines: PropTypes.array.isRequired,
  formValues: PropTypes.shape({
    lines: PropTypes.array.isRequired,
    transfer_fee: PropTypes.string.isRequired,
    excise_tax_due: PropTypes.string.isRequired,
    partner_discount: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }),
  inventoryItems: PropTypes.object.isRequired,
  partnerFacilities: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  totals: PropTypes.object.isRequired,
  sentTotals: PropTypes.array.isRequired,
  lineTotals: PropTypes.array.isRequired,
  itemMasters: PropTypes.array.isRequired,
  childItemMasters: PropTypes.object.isRequired,
  scanField: PropTypes.string,
  timezone: PropTypes.string.isRequired,
  integrationState: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  isForLabPartner: PropTypes.bool.isRequired,
  isDriverCompany: PropTypes.bool.isRequired,
  isWasteDisposal: PropTypes.bool,
  isMedicated: PropTypes.bool,
  isReturn: PropTypes.bool,
  needTestResults: PropTypes.bool,
  isAllowNegativeInventory: PropTypes.bool,
  isMappingRequiredByPlatform: PropTypes.bool,
  transferLinesLimit: PropTypes.number.isRequired,
  inventoryItemsTestResults: PropTypes.object,
  saleOrderId: PropTypes.number,
  constants: PropTypes.object,
  isMetrcTransfersEnabled: PropTypes.bool,
  sentNetTotals: PropTypes.array,
  drivers: PropTypes.array,
  inventoryComplianceSettings: PropTypes.object,
  internationalNumberFormat: PropTypes.string.isRequired,
  isSplitPackageRedirectToggled: PropTypes.bool,
  distributionSettings: PropTypes.object.isRequired,
  isSubmitClicked: PropTypes.bool,
};

function mapDispatchToProps (dispatch) {

  const actions = {
    getUnpaginatedData, getItem, getData, postData, postItem, unsetData, addSelectedData,
    unsetItem, change, push, touch, addMessage, batchActions, getDataByPost, loadInventoryForSalesOrders
  };

  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

const selector = formValueSelector(formName);
function mapStateToProps(state, ownProps) {
  const {vehicles, partnerFacilities, itemMasters, prepackWeights, timezone} = state;
  return {
    packageStore: new PackageStore(state),
    sentTotals: getCreateTransferSentTotals(state),
    sentNetTotals: getCreateTransferSentNetTotals(state),
    lineTotals: getCreateTransferLineTotals(state),
    editableLinePrices: getCreateTransferEditablePrices(state),
    inventoryItems: getFilteredInventoryItems(state),
    selectedSalesOrders: getSelectedSalesOrders(state),
    destinations: getSalesOrdersDestinations(state),
    lines: getSalesOrdersLines(state),
    isMedicated: isMedicatedOrder(state),
    isReturn: getIsReturn(state),
    totals: getSalesOrdersTotals(state),
    childItemMasters: getChildItemMastersForFacility(state),
    formValues: getCreateTransferFormValues(state),
    scanField: selector(state, 'scanField'),
    prepackWeights,
    partnerFacilities,
    salesOrders: getCreateTransferSalesOrders(state),
    drivers: getValidDrivers(state),
    vehicles,
    itemMasters,
    integrationTransferTypes: state[dataNames.metrcTransferTypes],
    locations: getFlattenedStorageLocations(state),
    timezone,
    formData: getCreateTransferFormData(state),
    isForLabPartner: isForLabPartner(state),
    isDriverCompany: isDriverCompany(state, {drivers: selector(state, 'drivers')}),
    isWasteDisposal: selector(state, 'is_waste_disposal'),
    currentFacilityType: getFacilityType(state),
    needTestResults: needTestResults(state),
    integrationState: getIntegrationState(state),
    transferLinesLimit: getTransferLinesLimit(state),
    isAllowNegativeInventory: complianceSettings.isAllowNegativeInventory(state),
    isMappingRequiredByPlatform: isMappingRequiredForSupply(state),
    inventoryItemsTestResults: labResultsSelector.getList(state),
    salesOrderIds: Array.isArray(ownProps.location.query.so_num) ? ownProps.location.query.so_num : [ownProps.location.query.so_num],
    constants: state[itemNames.constants],
    isMetrcTransfersEnabled: isMetrcTransfersEnabled(state),
    inventoryComplianceSettings: complianceSettings.getInventoryComplianceSettings(state),
    internationalNumberFormat: getInternationalNumberFormat(state),
    isSplitPackageRedirectToggled: isFeatureEnabled(state)('feature_metrc_split_package_redirect'),
    isTransferFormReactToggled: isFeatureEnabled(state)('feature_transfer_form_react_component'),
    isPaRemediationLabelsToggled: isFeatureEnabled(state)('feature_pa_hb_1024_remediation_labels'),
    distributionSettings: getDistributionSettings(state)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (CreateTransferPage);
