import { formValueSelector, getFormSyncErrors, getFormValues } from 'redux-form';
import get from 'lodash.get';
import omit from 'lodash.omit';
import sortBy from 'lodash.sortby';
import moment from 'moment';
import { createSelector } from 'reselect';
import * as dataNames from '../constants/dataNames';
import {SUPPLY_CHAIN_MAPPING_FORM_METRC} from '../constants/forms';
import * as itemNames from '../constants/itemNames';
import {getOrganizationStrains} from './organizationStrainsSelectors';
import {
  convertDbTimeToClientTzMoment,
  convertFormInputDateToDbDate,
  convertFormInputDateToDbTime
} from '../util/dateHelpers';
import {timezone} from '../../data/mock/harvestHistory';

export const formSelector = formValueSelector(SUPPLY_CHAIN_MAPPING_FORM_METRC);
export const supplyChainFormValueSelector = (state) => (value) => formSelector(state, value);
const getIncomingTransfers = (state) => state[dataNames.incomingTransfers];
export const errorSelector = getFormSyncErrors(SUPPLY_CHAIN_MAPPING_FORM_METRC);
export const formValuesSelector = getFormValues(SUPPLY_CHAIN_MAPPING_FORM_METRC);
export const getIncomingTransferDetails = (state) => state[itemNames.incomingTransferDetails];
export const getPurchaseOrders = (state) => state[dataNames.purchaseOrders];
export const getPurchaseOrder = (state) => state[itemNames.purchaseOrder];
export const getSupplyChainMapping = (state) => state[itemNames.supplyChainMapping]; // Only has a value on InventoryReceipt create
export const getInventoryReceipt = (state) => state[itemNames.inventoryReceipt];
export const getItems = (state) => state[dataNames.inventoryItems];
const getIsCreateMode = (state, props) => props.isCreateMode;

const getCreateReceiptLines = (incomingTransferDetails, supplyChainMapping, purchaseOrder, organizationStrains) => {
  if (!purchaseOrder || Object.keys(purchaseOrder).length === 0 || !supplyChainMapping || Object.keys(supplyChainMapping).length === 0) {
    return {};
  }

  const partner_id = purchaseOrder.partner_id;

  const lines = [];

  supplyChainMapping.product_mapping.forEach((pm_line, index) => {
    const pkg = incomingTransferDetails.packages[index];
    const item_master = pm_line.item_master;
    const item_master_vendor = item_master.vendors.find((vendor) => vendor.partner_id === partner_id);
    // Set strain name in item_master (needed for global_strains)
    if (item_master.strain && !item_master.strain.strain_name) {
      const strain = organizationStrains.find(strain => strain.global_strain_id === item_master.strain.global_strain_id);
      if (strain) {
        item_master.strain.strain_name = get(strain, 'strain_name');
      }
    }
    const default_unit_cost = item_master_vendor ? item_master_vendor.default_unit_cost : null;

    let transfer_qty = pkg.transfer_qty;
    if (pkg.transfer_uom === 'GR' && item_master.default_uom === 'EA') {
      const med_weight_uom = get(item_master, 'medicated_weight_uom_display');
      const med_weight = get(item_master, 'medicated_weight_base', 0) / 1000000;
      if (!med_weight_uom || !med_weight) {
        transfer_qty = null;
      }
      transfer_qty = pkg.transfer_qty / med_weight;
    }

    const line_item_price =  default_unit_cost && transfer_qty ? default_unit_cost * transfer_qty : null;

    const line = {
      item_master_id: item_master.id,
      qty: transfer_qty,
      cost_per_unit: default_unit_cost,
      line_item_price: line_item_price,
      line_item_price_edit: line_item_price,
      strain: item_master.strain,
      inventory: []
    };

    const inventory_line = {
      state_integration_package_name: pkg.product_name,
      state_integration_package_status: pkg.status,
      state_integration_tracking_id: pkg.package_code
    };
    line.inventory.push(inventory_line);

    lines.push(line);
  });
  return lines;
};

export const getReceiptInitialValues = createSelector(
  [getInventoryReceipt, getIncomingTransferDetails, getSupplyChainMapping, getPurchaseOrder, getOrganizationStrains, getItems, getIsCreateMode],
  (inventoryReceipt, incomingTransferDetails, supplyChainMapping, purchaseOrder, organizationStrains, items, isCreateMode) => {
    if (isCreateMode) {
      return {
        integrator_manifest_number: incomingTransferDetails.transfer_name,
        integrator_partner_name: incomingTransferDetails.transfer_from_name,
        integrator_partner_license: incomingTransferDetails.transfer_from_license,
        package_count: incomingTransferDetails.package_count,
        purchase_order_number: purchaseOrder.po_number,
        lines: getCreateReceiptLines(incomingTransferDetails, supplyChainMapping, purchaseOrder, organizationStrains),
        status: 'open'
      };
    }
    const lines = inventoryReceipt.lines;
    if (lines) {
      lines.forEach(line => {
        const package_id = get(line, 'inventory.0.package_id');
        const item = package_id && items ? items.find((i) => i.package_id === package_id) : null;
        const lot_number = get(line, 'inventory.0.lot_number');
        if (lot_number) {
          line.lot_number = lot_number;
        }
        const date_item_expired = get(item, 'package_expires_at');
        if (date_item_expired) {
          line.date_item_expired = moment(date_item_expired);
        }
        const date_item_use_by = get(item, 'package_use_by_at');
        if (date_item_use_by) {
          line.date_item_use_by = moment(date_item_use_by);
        }
      });
    }
    return {
      integrator_manifest_number: inventoryReceipt.integrator_manifest_number,
      integrator_partner_name: inventoryReceipt.integrator_partner_name,
      integrator_partner_license: inventoryReceipt.integrator_partner_license,
      package_count: lines ? lines.length : '',
      metrc_transfer_id: inventoryReceipt.metrc_transfer_id,
      date_received: inventoryReceipt.date_received
        ? moment(inventoryReceipt.date_received)
        : inventoryReceipt.status === 'completed'
          ? new Date()
          : null,
      estimated_departure_time: convertDbTimeToClientTzMoment(inventoryReceipt.estimated_departure_time, timezone),
      estimated_arrival_time: convertDbTimeToClientTzMoment(inventoryReceipt.estimated_arrival_time, timezone),
      status: inventoryReceipt.status,
      purchase_order_number: purchaseOrder.po_number,
      driver: {id: inventoryReceipt.driver_id},
      vehicle: {id: inventoryReceipt.vehicle_id},
      partner_invoice_number: inventoryReceipt.partner_invoice_number,
      lines
    };
  });

export const getInventoryReceiptPackageIds = (inventoryReceipt) => {
  if (!inventoryReceipt || !get(inventoryReceipt, 'lines')) {
    return [];
  }
  const package_ids = [];
  // Loop over lines
  inventoryReceipt.lines.forEach((line) => {
    // Loop over inventory
    const lineInventory = get(line, 'inventory', []);
    lineInventory.forEach((inventory) => {
      const package_id = get(inventory, 'package_id');
      if (package_id) {
        package_ids.push(package_id);
      }
    });
  });
  return package_ids;
};

export const getAutoMappedItemMaster = (metrc_item_id, itemMasterMappings, itemMasters) => {
  // See if packageCode has been previously mapped
  const itemMasterMapping = itemMasterMappings.find((mapping) => metrc_item_id.toString() === mapping.external_item_id); // external_item_id is a string so convert metrc_item_id to string for comparison
  if (!itemMasterMapping) {
    return;
  }
  // Lookup item master and return it
  const item_master = itemMasters.find((im) => itemMasterMapping.item_master_id === im.id);
  if (item_master) item_master.external_item_id = metrc_item_id;
  return item_master;
};

export const hasAutoMappedItemMasters = (metrcPackages, itemMasterMappings, itemMasters) => {
  if (!metrcPackages || !itemMasterMappings || !itemMasters) {
    return false;
  }

  const hasAutoMappedItemMasters = metrcPackages.find((pkg) => {
    return itemMasterMappings.find((mapping) => {
      return (
        // METRC item should be found in itemMasterMappings
        pkg.metrc_item_id.toString() === mapping.external_item_id &&
        // AND itemMasters should contain the itemMaster of the mapping
        itemMasters.find((im) => im.id === mapping.item_master_id)
      );
    }); // external_item_id is a string so convert metrc_item_id to string for comparison
  });

  return !!hasAutoMappedItemMasters;
};

export const getCompatibleItemMasters = (category_ids, itemMasters) => {
  const category_ids_arr = category_ids.split(',').map(Number);
  return itemMasters.filter((im) => category_ids_arr.includes(im.category_id));
};

export const getIncomingTransfersOptions = createSelector(
  [getIncomingTransfers],  (transfers) =>
    sortBy(
      transfers.map((transfer) => {
        return {
          value: transfer.metrc_transfer_id,
          text: `Manifest: ${transfer.transfer_name} - Vendor: ${transfer.transfer_from_name}`
        };
      }),
      'text'
    )
);

export const getPurchaseOrdersOptions = createSelector(
  [getPurchaseOrders],  (purchaseOrders) => sortBy(
    purchaseOrders.map((purchaseOrder) => {
      return {
        id: purchaseOrder.id,
        text: `${purchaseOrder.po_number} - ${purchaseOrder.title}`
      };
    }),
    'text'
  )
);

export const getPartnerFacilitiesOptions = (partnerFacilities) => {
  return sortBy(
    partnerFacilities.map((partnerFacility) => {
      return {
        value: partnerFacility.id,
        text: `${partnerFacility.partner.name} - ${partnerFacility.legal_name}`
      };
    }),
    'text'
  );
};

export const getInventoryReceiptPayload = (formData, timezone, purchaseOrder, itemMasters, supplyChainMapping) => {

  return {
    ...omit(formData, ['facility_id', 'created_at', 'updated_at']),
    metrc_transfer_id: supplyChainMapping ? get(supplyChainMapping, 'incoming_transfer_id') : null,
    po_number: formData.purchase_order_number,
    date_received: convertFormInputDateToDbDate(formData.date_received, timezone),
    date_ordered: convertFormInputDateToDbDate(formData.date_ordered, timezone),
    estimated_arrival_time: convertFormInputDateToDbTime(formData.estimated_arrival_time, timezone) || undefined,
    estimated_departure_time: convertFormInputDateToDbTime(formData.estimated_departure_time, timezone) || undefined,
    receipt_type: 'purchase_order',
    source_type: 'purchase_order',
    source_id: purchaseOrder.id,
    driver_id: (formData.driver && formData.driver.id) || undefined,
    vehicle_id: (formData.vehicle && formData.vehicle.id) || undefined,
    contains_medicated: 1,
    status: 'completed',
    approval_status: 'approved',
    transfer_type: 'wholesale',
    vendor_facility_name: formData.integrator_partner_name,
    vendor_name: formData.integrator_partner_name,
    license_number: formData.integrator_partner_license,
    lines: formData.lines.map((line, index) => {
      const itemMaster = itemMasters.find((im) => im.id === line.item_master_id);
      return {
        ...line,
        item_master_id: itemMaster.id,
        qty: line.qty,
        uom: itemMaster.default_uom,
        line_item_price: line.line_item_price,
        source_line_id: purchaseOrder.lines[index].id,
        inventory: line.inventory.map((inventory) => {
          return {
            ...inventory,
            source_strain: typeof line.strain === 'string' ? line.strain : line.strain.strain_name,
            item_master_id: itemMaster.id,
            storage_location_id: inventory.storage_location_id,
            qty: line.qty,
            uom: itemMaster.default_uom,
            lot_number: line.lot_number,
            date_item_created: line.date_item_created ? moment(line.date_item_created) : '', // DOES NOT NEED TIMEZONE
            date_item_expired: line.date_item_expired ? moment(line.date_item_expired) : '', // DOES NOT NEED TIMEZONE
            date_item_use_by: line.date_item_use_by ? moment(line.date_item_use_by) : '', // DOES NOT NEED TIMEZONE
          };
        })
      };
    }),
  };
};
