import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import TextInput from '../../../../../common/form/TextInput';
import ContentConcealer from '../../../../../common/concealers/ContentConcealer';

const AddInventoryRow = (props) => {

  const {partnerFacility, locked, line, push, offset, integrationState} = props;

  const addInventoryItem = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const newInventory = Object.assign({}, {...line.inventory[line.inventory.length - 1]}, {qty: 0});
    delete newInventory.id;
    if(newInventory.subitems) newInventory.subitems = [{}];
    push(newInventory);
  };

  const value = `${I18n.t('cultivation.transfers.form.producerName')}: ${partnerFacility && partnerFacility.partner && partnerFacility.partner.name}`;
  const {isBiotrack} = integrationState;

  return (
    <Row>
      <Col md={{span: 6, offset: offset !== undefined ? offset : 0}}>
        <TextInput className='rounded-input' meta={{}} style={{backgroundColor: '#fff', boxShadow: '1px 2px #ccc'}}
          id={'producer_name'}
          input={{
            name: 'producer_name',
            label: I18n.t('cultivation.transfers.form.producerName'),
            readOnly: true,
            value
          }}
        />
      </Col>
      <ContentConcealer show={!isBiotrack}>
        <Col md={{ span: 3, offset: (12 - (9 + offset))}}>
          <button type='button' disabled={locked} className='btn btn-primary btn-sm' style={{float:'right', marginRight: '8px'}} onClick={addInventoryItem}>Add Row</button>
        </Col>
      </ContentConcealer>
    </Row>
  );

};

AddInventoryRow.propTypes = {
  partnerFacility: PropTypes.object.isRequired,
  locked: PropTypes.bool.isRequired,
  line: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  offset: PropTypes.number,
  integrationState: PropTypes.object,
};

export default AddInventoryRow;
