import {createSelector} from 'reselect';
import get from 'lodash.get';
import * as dataNames from '../constants/dataNames';

import {getPartners} from './partnersSelectors';
import {getCategories} from './categorySelectors';

export const getVendorOptions = createSelector( [getPartners], vendors => {
  return vendors.map( vendor => {
    return {
      text: vendor.name,
      value: vendor.id
    };
  });
});

export const getCategoryOptions = createSelector( [getCategories], categories => {
  return categories.map( category => {
    return {
      text: category.name,
      value: category.id
    };
  });
});

export const getInventory = (state) => get(state, dataNames.inventoryReport, []);

export const getInventoryReport = createSelector(
  [getInventory],
  (inventoryReport) => {
    if(!inventoryReport.length) return [];

    return inventoryReport;
  }
);

