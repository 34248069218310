import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {FormGroup, Row, Col} from 'react-bootstrap';
import {Field} from 'redux-form';
import TextInput from '../../common/form/TextInput';
import InternationalDateTimePickerInput from '../../common/form/InternationalDateTimePickerInput';

const SalesOrders = ({fields, orders}) => {
  return (<FormGroup className='orders'>
    <h3>{I18n.t('cultivation.salesOrders.title')}</h3>
    {fields.map(order =>
      <Row key={order}>
        <Col xs={4} sm={3}>
          <Field
            name={`${order}.sales_order_number`}
            component={TextInput}
            readOnly={true}
            props={{
              label: I18n.t('cultivation.salesOrder.form.salesOrderNumber')
            }}
          />
        </Col>
        <Col xs={4} sm={3}>
          <InternationalDateTimePickerInput
            name={`${order}.date_ordered`}
            props={{
              enableTodayButton: false,
              label: I18n.t('cultivation.salesOrders.form.dateOrdered'),
            }}
            inputProps={{
              disabled: true
            }}
          />
        </Col>
        <Col xs={4} sm={3}>
          <InternationalDateTimePickerInput
            name={`${order}.date_expected`}
            props={{
              enableTodayButton: false,
              label: I18n.t('cultivation.salesOrders.form.dateExpected')
            }}
            inputProps={{
              disabled: true
            }}
          />
        </Col>
      </Row>
    )
    }
  </FormGroup>);
};

SalesOrders.propTypes = {
  fields: PropTypes.object.isRequired,
  orders: PropTypes.array.isRequired
};

export default SalesOrders;
