import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import {Col, Row, Card, Table, Alert} from 'react-bootstrap';
import {FaAsterisk, FaExclamationTriangle} from 'react-icons/fa';
import NumericInput from '../../common/form/NumericInput';
import WillRender from '../../common/concealers/WillRender';
import InlineCheckBox from '../../common/form/InlineCheckBox';


const EachPackagesFieldArray = (props) => {

  const {packagedWeight, fields, meta, integrationState, isEachPackaging} = props;

  const isCanada = get(integrationState, 'isCanada', false);

  return (
    <Card><Card.Header><h3 className='panel-title'>{I18n.t('packaging.form.packages')}</h3></Card.Header>

      <Table className='pre-pack-table'>
        <thead>
        <tr>
          <th className='pre-pack-grams'>{I18n.t('ei.processing.form.productName')}</th>
          <th className='pre-pack-quantity'>{I18n.t('packages.prePack.quantity')}</th>
          <th>{I18n.t('packages.prePack.childPackageId')}</th>
          <WillRender ifTrue={isCanada}>
            <th>Finished</th>
          </WillRender>
        </tr>
        </thead>
        <tbody>
        {fields.map((fieldName, fieldIndex) => {
          const item = fields.get(fieldIndex);
          return (
            <tr key={fieldName} className='packaging-quantity-row'>
              <td className='pre-pack-grams'>
                {item && item.name || ''}
              </td>
              <td className='pre-pack-quantity'>
                <Field name={`${fieldName}.qty`} component={NumericInput} props={{
                  fractionPartSize: 0,
                  allowNegativeNumber: false,
                  rightAddon: 'EA',
                }}/>
              </td>
              <td>
                <Field name={`${fieldName}.package_code`} component='input' props={{
                  disabled: true,
                  className: 'unit-id',
                }}/>
              </td>
              <td className='no-margin-on-checkbox'>
                <WillRender ifTrue={isCanada}>
                  <Field name={`${fieldName}.finished`} component={InlineCheckBox} props={{
                    label: I18n.t('common.form.finishedInventoryCheckbox'),
                    disabled: true,
                  }}/>
                </WillRender>
              </td>
            </tr>
          );
        })}
        </tbody>
        <tfoot>
        <tr>
          <td className='text-right'>
            <strong>{I18n.t('packages.prePack.totalPacked')}:</strong>
          </td>
          <td className='text-center'>
            <Field name='packaged_weight' component={NumericInput} props={{
              value: packagedWeight,
              disabled: true,
              rightAddon: 'EA',
            }}/>
          </td>
          <td/>
          <td/>
        </tr>

        <tr className='pre-pack-waste-row'>
          <td className='text-right'>
            <strong>{I18n.t('packages.prePack.wasteReported')}:</strong>
          </td>
          <td className='text-center'>
            <Field name='waste_reported' component={NumericInput} props={{
              fractionPartSize: 2,
              allowNegativeNumber: false,
              rightAddon: 'GR',
            }}/>
          </td>
          <td/>
          <td/>
        </tr>

        <tr className='pre-pack-waste-row'>
          <td className='text-right'>
            <strong>{I18n.t('packages.prePack.packagingLoss')}:</strong>
          </td>
          <td className='text-center'>
            <Field name='packaged_loss_amt' component={NumericInput} props={{
              fractionPartSize: 2,
              allowNegativeNumber: false,
              rightAddon: 'GR',
            }}/>
          </td>
          <td/>
          <td/>
        </tr>

        <tr className='pre-pack-waste-row'>
          <td className='text-right'>
            <FaAsterisk style={{fontSize: '.75em'}}/>
            <strong>{I18n.t(`packages.prePack.${isEachPackaging ? 'remainingWeight' : 'newWeight'}`)}:</strong>
          </td>
          <td className='text-center'>
            <Field name='remaining_weight' component={NumericInput} props={{
              fractionPartSize: 2,
              allowNegativeNumber: false,
              rightAddon: 'GR',
            }}/>
          </td>
          <td/>
          <td/>
        </tr>
        </tfoot>
      </Table>

      {meta && meta.error ?
        <Row>
          <Col xs={12}>
            <Alert variant='danger'>
              <FaExclamationTriangle/>
              <span className='padding-left'>{meta.error}</span>
            </Alert>
          </Col>
        </Row>
        :
        null
      }

    </Card>
  );
};

EachPackagesFieldArray.propTypes = {
  fields: PropTypes.shape({
    length: PropTypes.number.isRequired,
    get: PropTypes.func.isRequired,
    map: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
  }),
  itemMasters: PropTypes.array.isRequired,
  packagedWeight: PropTypes.number.isRequired,
  integrationState: PropTypes.object.isRequired,
  isEachPackaging: PropTypes.bool.isRequired,
};

export default EachPackagesFieldArray;
