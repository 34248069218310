import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Col } from 'react-bootstrap';
import { getItem} from '../../../../actions/apiActions';
import { unsetItem } from '../../../../actions/itemActions';


export class PartnerDataSummary extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      organizationExists: false,
      facilitiesExist: false,
      uploading: false,
      partner: false,
      loadedCustomerData: false,
    };
    this.loadPartnerData = this.loadPartnerData.bind(this);
    this.navigate = this.navigate.bind(this);
  }

  componentWillMount(){
    this.loadPartnerData(this.props);
  }

  componentWillReceiveProps(nextProps){
    this.loadPartnerData(nextProps);
  }

  loadPartnerData(props){
    if(props.organizationCode){
      return false;
    }
    if(!props.loadData || this.state.loadedCustomerData) return true;
    this.setState({loadedCustomerData: true}, () => {
      this.props.actions.getItem(`/api/partners/${props.partner.id.split(':').shift()}`, 'noOp')
        .then((partner) => {
          this.setState({partner});
        });
    });

  }

  navigate(destination){
    const partnerId = this.props.partner.id.split(':').shift();
    const map = {
      inventory: `/partners/${partnerId}/inventory`,
      map_products: `/partners/${partnerId}/product_mapping`,
      modify: `/partners/modify/${partnerId}`,
    };
    this.props.actions.push(map[destination]);
  }

  render() {

    const show = this.props.showElements ? this.props.showElements : {viewInventory: false, mapProducts: false, edit: false};

    const getAddress = (object) => {
      const fields = {
        street_1: 'street_address_1',
        street_2: 'street_address_2',
        city: 'city',
        state: 'province_code',
        zip: 'postal_code',
      };
      return Object.keys(fields).reduce((acc, field, index) => {
        const value = get(object, fields[field], false);
        if(!value) {
          if(index === Object.keys(fields).length - 1 && !acc.length) return acc.concat('Not Present');
          return acc;
        }
        acc.push(['state', 'zip'].indexOf(field) === -1 ? `${value},` : value);
        return acc;
      }, []).join(' ');
    };

    const getContacts = (contacts = []) => {
      const fields = {
        name: 'first_name.last_name',
        phone: 'phone_number',
        email: 'email_address'
      };
      return !Array.isArray(contacts)
        ? []
        : contacts.map((contact) => {
          return Object.keys(fields).reduce((acc, field) => {
            const value = fields[field].indexOf('.') === -1
              ? get(contact, fields[field], false)
              : fields[field].split('.').reduce((acc, field) => {
                return acc.concat(contact[field]);
              }, []).join(' ');
            if(!value) return acc;
            acc.push(['first', 'email'].indexOf(field) === -1 ? `${value}` : value);
            return acc;
          }, []).join(' | ');
        });
    };

    const dataRows = [
      {key: 'state_license_number', text: 'State License Number'},
      {key: 'sales_tax_id', text: 'Sales Tax ID'},
      {key: 'wholsale_tax_id', text: 'Wholesale Tax ID'},
      {key: 'address', text: 'Address', func: () => getAddress(this.props.partner)},
      {key: 'contact_1', text: 'Contacts', func: () => {
        const contacts = getContacts(this.props.partner && this.props.partner.contacts ? this.props.partner.contacts : []);
        if(!contacts.length) return 'None Found';
        return (<div>
          {contacts.map((contact, index) => <div key={index}>{contact}</div>)}
        </div>);
      }},
    ];

    const partnerData = dataRows.reduce((acc, row) => {
      if(!this.props.partner) return acc;
      acc[row.key] = !row.func ? get(this.props.partner, row.key, 'Not Present') : row.func();
      return acc;
    }, {});

    return this.props.organization && this.props.organization.facilities
      ? (<div>
        <h4 style={{borderBottom: '1px solid #ccc', paddingBottom: '5px', fontWeight: 'bold'}}>{this.props.organization.name}</h4>
        {
          this.props.organization.facilities.map((facility, index) => {
            return (<div key={index}>
              <div style={{fontWeight: 'bold'}}>{facility.name}</div>
              <Col xs={12} md={6}>
                <table>
                  <tbody>
                  {
                    dataRows.map((row, subIndex1) => {
                      if(subIndex1 > 3) return null;
                      return <tr key={`${index}_${subIndex1}`}><td>{row.text}: {facility[row.key]}</td></tr>;
                    })
                  }
                  </tbody>
                </table>
              </Col>
              <Col xs={12} md={6}>
                <table>
                  <tbody>
                  {
                    dataRows.map((row, subIndex2) => {
                      if(subIndex2 < 4) return null;
                      return <tr key={`${index}_${subIndex2}`}><td>{row.text}: {facility[row.key]}</td></tr>;
                    })
                  }
                  </tbody>
                </table>
              </Col>
              <div style={{clear: 'both'}} />
              {index < this.props.organization.facilities.length - 1 ? <hr style={{border: '1px solid #cecece'}} /> : null}
            </div>);
          })
        }
      </div>)
      : (<div>
        <h4>
          Partner Information
          {
            !show.viewInventory
              ? null
              : <button className='btn btn-sm btn-primary' style={{float:'right', marginLeft: '2px'}} onClick={() => this.navigate('inventory')}>View Inventory</button>
          }
          {
            !show.mapProducts
              ? null
              : <button className='btn btn-sm btn-primary' style={{float:'right', marginLeft: '2px'}} onClick={() => this.navigate('map_products')}>Map Products</button>
          }
          {
            !show.edit
              ? null
              : <button className='btn btn-sm btn-primary' style={{float:'right', marginLeft: '2px'}} onClick={() => this.navigate('modify')}>Edit</button>
          }
        </h4>
        <div style={{height: '8px', clear: 'both'}} />
        <table className='table table-sm'>
          <tbody>
          {
            dataRows.map((row, index) => {
              return (<tr key={index}>
                <td style={{padding: '0', width: '25%'}}>
                  {row.text}:
                </td>
                <td style={{padding: '0'}}>
                  {get(partnerData, row.key, row.defaultValue)}
                </td>
              </tr>);
            })
          }
          </tbody>
        </table>
      </div>);

  }
}

PartnerDataSummary.propTypes = {
  loadData: PropTypes.bool.isRequired,
  activeTab: PropTypes.string.isRequired,
  partner: PropTypes.object.isRequired,
  showElements: PropTypes.object.isRequired,
};


function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  const actions = bindActionCreators({unsetItem, getItem, push}, dispatch);
  return {
    actions
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (PartnerDataSummary);
