import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { replace } from 'react-router-redux';
import * as apiActions from '../../../../actions/apiActions';
import CupoFormWrapper from '../common/CupoFormWrapper'; //eslint-disable-line
import FormWrapper from '../../../common/form/FormWrapper';
import * as itemNames from '../../../../constants/itemNames';
import { getInitialValues } from '../../../../selectors/forms/cupoFormSelectors';
import * as dataNames from '../../../../constants/dataNames';
import { getOrderedFacilityStrains } from '../../../../selectors/facilityStrainsSelectors';

class CreateCupoPage extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount() {
    this.props.actions.getItem('/api/cultivation/settings', itemNames.colombiaModalities, null, {
      ids: ['cult_colombia_modality']
    });
    this.props.actions.getUnpaginatedData('/api/strains/by_facility', dataNames.facilityStrains);
  }

  onSubmit(formValues) {
    const modalities = formValues.modalities.flatMap((modality) =>
      modality.strains.map((strain) => ({
        id: modality.id,
        modality: modality.key,
        strain_id: strain.id
      }))
    );
    const payload = {
      ...formValues,
      modalities
    };

    return this.props.actions.postItem('/api/cupos', payload).then((data) => {
      this.props.actions.replace(`/settings/cupos/${data.id}`);
    });
  }

  render() {
    const { modalities, initialValues, strains } = this.props;
    return (
      <div>
        <FormWrapper title='cupos.createCupo'>
          <CupoFormWrapper
            modalities={modalities}
            initialValues={initialValues}
            strains={strains}
            onSubmit={this.onSubmit}
          />
        </FormWrapper>
      </div>
    );
  }
}

CreateCupoPage.propTypes = {
  actions: PropTypes.object,
  modalities: PropTypes.array,
  initialValues: PropTypes.object,
  strains: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    initialValues: getInitialValues(state),
    strains: getOrderedFacilityStrains(state)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({ replace }, apiActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateCupoPage);
