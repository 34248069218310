/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {I18n} from 'react-redux-i18n';
import { bindActionCreators } from 'redux';
import {Image} from 'react-bootstrap';
import { push } from 'react-router-redux';

import InternationalDateStatic from '../common/form/InternationalDateStatic';
import * as apiActions from '../../actions/apiActions';
import * as userActions from '../../actions/userActions';
import * as itemNames from '../../constants/itemNames';
import * as dataNames from '../../constants/dataNames';
import TablePageWrapper from '../common/grid/TablePageWrapper';
import PageTitle from '../common/PageTitle';
import {getUsersForList} from '../../selectors/usersSelectors';
import {getTotalResults} from '../../selectors/paginationSelectors';
import {getIntegrationState} from '../../selectors/integration/integrationSelectors';
import {PERSON} from '../../constants/imageUrls';

export class UserListPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);

    const sizePerPage = 50;
    const selectedCurrentFacilityUsers = [];
    const filteredCurrentFacilityUsers = [];
    const columns = [];

    this.state = {columns, sizePerPage, selectedCurrentFacilityUsers, filteredCurrentFacilityUsers};
    this.createUser = this.createUser.bind(this);
    this.modifyUser = this.modifyUser.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.setTab = this.setTab.bind(this);

    this.getData = this.getData.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.getColumns = this.getColumns.bind(this);
    this.getImages = this.getImages.bind(this);
    this.ref = React.createRef();
  }

  componentWillMount() {
    this.props.actions.getItem('/api/integration-settings', itemNames.integrationSettings);
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(nextProps, nextState) {
    if(this.props.params.status !== nextProps.params.status){
      this.getData();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.params.status !== this.props.params.status) {
      this.setState({columns: this.getColumns(nextProps.timezone, nextProps.integrationState.isPaLeaf)});
    }
    this.getImages(nextProps);
  }

  getData() {
    this.handleSelect('clear');
    // this.props.actions.unsetData(dataNames.currentFacilityUsers);
    // const active = this.props.params.status === 'inactive' ? 0 : 1;
    // this.props.actions.getUnpaginatedData('/api/users/current_facility',
    //   dataNames.currentFacilityUsers,
    //   null,
    //   {active},
    //   (response) => {
    //     const ids = response ? response.map(user => user.image_file_id).filter(Boolean) : [];
    //     if(ids.length){
    //       this.props.actions.getDataByPost('/api/images/multiple', {ids}, dataNames.images);
    //     }
    //   }
    // );
    // this.handleSearch();
    if(this.ref.current){
      this.ref.current.wrappedInstance.ref.current.debouncedExternalSearch();
    }
  }

  // When changed to proper pagination, lost images...
  // but the selectors are still in place so adding this call in should bring them back
  getImages(props = false){
    if(!props) props = this.props;
    const ids = props.currentFacilityUsers.map((user) => user.image_file_id).filter(v => v != null);
    const stringifiedIds = JSON.stringify(ids);
    if(stringifiedIds !== this.state.stringifiedIds && ids.length){
      this.setState({stringifiedIds}, () => {
        this.props.actions.getDataByPost('/api/images/multiple', {ids}, dataNames.images);
      });
    }
  }

  getColumns(timezone, isPaLeaf) {

    return [
      {name: 'users.table.id',
        dataId:'id',
        hidden: true,
        width: '60px'},
      {name: '',
        dataId:'user_image',
        hidden: false,
        width: '60px',
        dataSort: false,
        csvFormatter: (cell) => { return 'image'; },
        formatter: (cell, row) => { return <Image className='user-avatar img-circle' src={cell ? cell : PERSON} />; }},
      {name: 'users.table.firstName',
        dataId:'first_name',
        hidden: false,
        width: '120px'},
      {name: 'users.table.lastName',
        dataId:'last_name',
        hidden: false,
        width: '120px'},
      {name: 'users.table.role',
        dataId:'roles',
        hidden: false,
        width: '120px',
        dataSort: false,},
      {name: 'users.table.userName',
        dataId:'name',
        hidden: false,
        width: '80px'},
      {name: 'batches.table.stateLicense',
        dataId: (isPaLeaf ? 'state_integration_id' : 'license_number'),
        hidden: false,
        width: '80px',
        dataSort: false,},
      {name: 'users.table.expirationDate',
        dataId:'expiration_date',
        hidden: false,
        width: '80px',
        formatter: (cell, row) => (<InternationalDateStatic adjustForClientTimezone={false}>{cell}</InternationalDateStatic>),
        dataSort: false,},
      {name: 'users.table.email',
        dataId:'email',
        hidden: false,
        width: '120px'},
      {name: 'users.table.phoneNumber',
        dataId:'phone_number',
        hidden: false,
        width: '80px',
        dataSort: false,}
    ];
  }

  createUser () {
    this.props.actions.push('/users/create');
  }

  modifyUser (event) {
    if (this.state.selectedCurrentFacilityUsers) {
      this.props.actions.push();
    }
  }

  handleSearch (sort, search, size = 20, start, filter) {
    const active = this.props.params.status === 'inactive' ? 0 : 1;

    const params = {active};
    if(sort){
      params.sort = sort.replace(' ', ':');
    }
    if(search){
      params.search = search;
    }
    if(size){
      params.per_page = size;
    }
    if(start){
      params.page = (start ? start / size : 0) + 1;
    }
    this.props.actions.getPaginatedData('/api/users/current_facility',
      dataNames.currentFacilityUsers,
      null,
      params,
      (response) => {
        let ids = response && response.data ? response.data.map(user => user.image_file_id).filter(Boolean) : [];
        ids = ids.filter(v => v != null);
        if(ids.length){
          this.props.actions.getDataByPost('/api/images/multiple', {ids}, dataNames.images);
        }
      }
    );
  }

  handleSelect (isSelected, rows) {
    if (rows) {
      const ids = rows.map(row => row.id);
      if(isSelected){
        this.props.actions.clearSelectedUser();

        // here we need to wait when data of the selected row will loaded
        // only after that we can change state
        this.props.actions.getItem(`/api/users/details/${ids[0]}`, itemNames.selectedUser, null, {}, () => {
          this.setState({selectedCurrentFacilityUsers: ids});
        });
      } else {
        this.setState({selectedCurrentFacilityUsers: []});
      }
    }
    else {
      this.setState({selectedCurrentFacilityUsers: []});
    }
  }

  setTab(activeTab) {
    this.props.actions.push(`/users/${activeTab}`);
  }

  render () {
    const {currentFacilityUsers, params, timezone, integrationState, dataTotalSize} = this.props;
    const {isPaLeaf} = integrationState;

    const tabs = [
      {id: 'activeUsersTab', eventKey: 'active', title: 'nav.activeUsers', actions:[
          {id: 'createUser', path: '/users/create' , text: 'users.actions.createUser' , glyph: 'plus-sign', requireSelect: false},
          {id: 'modifyUser', path: `/users/modify/${this.state.selectedCurrentFacilityUsers[0]}` , text: 'users.actions.modifyUser' , glyph: 'th-list', requireSelect: true}
      ]},
      {id: 'inactiveUsersTab', eventKey: 'inactive', title: 'nav.inactiveUsers', actions:[
        {id: 'createUser', path: '/users/create' , text: 'users.actions.createUser' , glyph: 'plus-sign', requireSelect: false},
        {id: 'modifyUser', path: `/users/modify/${this.state.selectedCurrentFacilityUsers[0]}` , text: 'users.actions.modifyUser' , glyph: 'th-list', requireSelect: true}
      ]}
    ];
    const activeTab = params.status ? params.status : 'active';

    return (
      <div>
        <PageTitle primaryText={I18n.t('users.title')}/>
        <TablePageWrapper
          ref={this.ref}
          settingKey='users'
          columns = {this.getColumns(timezone, isPaLeaf)}
          data = {currentFacilityUsers}
          activeTab = {activeTab}
          tabs = {tabs}
          switchTab = {this.setTab}
          selectedRows = {this.state.selectedCurrentFacilityUsers}
          handleSelect = {this.handleSelect}
          hideSearch = {false}
          hideScanSearch = {true}
          className = 'user-list-page'
          hideExport={true}
          external={true}
          externalSearch={this.handleSearch}
          dataTotalSize={dataTotalSize}
          bstProps={{
            selectRow: {
              mode: 'radio',
              selected: this.state.selectedCurrentFacilityUsers,
              onSelect: (row, isSelected) => this.handleSelect(isSelected, [row])
            }
          }}
        />
      </div>
    );

  }
}

UserListPage.propTypes = {
  currentFacilityUsers: PropTypes.array.isRequired,
  selectedCurrentFacilityUsers: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    getDataByPost: PropTypes.func.isRequired,
    getPaginatedData: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    clearSelectedUser: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }).isRequired,
  params: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  integrationState: PropTypes.object.isRequired,
  dataTotalSize: PropTypes.number
};

// UserListPage.contextTypes = {
//   router: React.PropTypes.object.isRequired
// };

function mapStateToProps(state, ownProps) {
  const {selectedCurrentFacilityUsers} = state;
  const active = ownProps.params.status === 'inactive' ? 0 : 1;
  return {
    dataTotalSize: getTotalResults(state, {name: dataNames.currentFacilityUsers}),
    selectedCurrentFacilityUsers,
    currentFacilityUsers: getUsersForList(state, {active}),
    timezone: state.timezone,
    integrationState: getIntegrationState(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({...userActions, ...apiActions, push}, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (UserListPage);
