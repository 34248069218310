import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';
import {Table, Col} from 'react-bootstrap';
import InternationalQuantityByUomInput from '../../../common/form/InternationalQuantityByUomInput';
import InternationalCurrencyInput from '../../../common/form/InternationalCurrencyInput';
import { SALES_ORDER_FORM } from '../../../../constants/forms';
import WillRender from '../../../common/concealers/WillRender';
import * as UOMS from '../../../../constants/uoms';


const PrePackTable = (props) => {

  const {fields, editableLineTotal, transferRecord, hideItemPrices, uom} = props;

  if(!fields.length) return null;

  return (<Col lg={7} offset={2} xs={12}>
    <Table condensed>
      <thead>
      {
        !transferRecord
          ? null
          : <tr>
            <th style={{textAlign: 'center', fontWeight: 'normal'}}
                colSpan={4}>{I18n.t('purchaseOrders.form.transferExists')}</th>
          </tr>
      }
      <tr>
        <th>{`${I18n.t(`uoms.${uom || UOMS.GR}.word`)}s`}</th>
        <th>{I18n.t('purchaseOrders.form.qty')}</th>
        <th>{I18n.t('purchaseOrders.form.total')} ({`${I18n.t(`uoms.${uom || UOMS.GR}.abbrev`)}`})</th>
        <WillRender ifFalse={hideItemPrices}><th>{I18n.t('purchaseOrders.form.unitCost')}</th></WillRender>
      </tr>
      </thead>
      <tbody>
      {fields.map((namePrefix, index) => {
        const weight = fields.get(index);
        if (weight && isNaN(weight.unit_cost)) {
          weight.unit_cost = 0;
        }
        return (<tr key={index}>
          <td><label>{get(weight, 'prepack_weight.name', '')}</label></td>
          <td>
            <InternationalQuantityByUomInput
              name={`${namePrefix}.qty`}
              disabled={transferRecord}
              allowNegativeNumber={false}
              hideUom={true}
              uom={UOMS.EA}
            />
          </td>
          <td>
            <InternationalQuantityByUomInput
              name={`${namePrefix}.total`}
              readOnly={true}
              hideUom={true}
            />
          </td>
          <WillRender ifFalse={hideItemPrices}>
          <td>
            <InternationalCurrencyInput
              name={`${namePrefix}.unit_cost`}
              props={{
                disabled:editableLineTotal || transferRecord,
                formName:{SALES_ORDER_FORM},
              }} />
          </td>
          </WillRender>
        </tr>);
      })}
      </tbody>
    </Table>
  </Col>);
};

PrePackTable.propTypes = {
  editableLineTotal: PropTypes.bool,
  hideItemPrices: PropTypes.bool,
  fields: PropTypes.object.isRequired,
  transferRecord: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ]).isRequired,
  uom: PropTypes.string.isRequired,
};

export default PrePackTable;
