import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {push} from 'react-router-redux';
import {Button} from 'react-bootstrap';
import RoutePermissionWrapper from './RoutePermissionWrapper';
import {findRouteDefinition} from '../../util/routeHelper';
import {isSetupMode} from '../../selectors/integration/integrationSelectors';

class RouteButton extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event){
    this.props.actions.push(this.props.path);
  }

  render () {
    const {children, path, disabled} = this.props;
    // There are two disable button settings; one from props.props and the other locally by detecting a route specifically disabled (leaf rails).
    const props = Object.assign({}, this.props.props ? this.props.props : {}, (this.props.props && !this.props.props.disabled && disabled) ? {disabled} : {});
    const onClickHandler = props ? props.onClick : undefined;

    return (<RoutePermissionWrapper path={path}>
        <Button {...props} href={path} onClick={(event) => {
          if(onClickHandler){
            onClickHandler(event);
          }else{
            this.handleClick(event);
          }
          event.preventDefault();
        }}>{children}</Button>
      </RoutePermissionWrapper>);
  }
}

RouteButton.propTypes = {
  path: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  props: PropTypes.object,
  children: PropTypes.node,
};

function mapStateToProps(state, ownProps){
  const {path, props, disabled} = ownProps;
  const route = findRouteDefinition(path);
  const buttonDisabled = Boolean(isSetupMode(state) && route && route.blockOnSetup || disabled);
  return {
    path,
    props,
    disabled: buttonDisabled
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({push}, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (RouteButton);
