import { change } from 'redux-form';
import * as types from '../../constants/actionTypes';
import * as dataNames from '../../constants/dataNames';
import { CONNECTS_REQUEST_FORM } from '../../constants/forms';

const connectsRequestForm = store => next => action => {

  const result = next(action);
  const {meta, type, payload} = action;

  if(meta && meta.form === CONNECTS_REQUEST_FORM){
    if(type === types.REDUX_FORM_CHANGE){
      if(meta.field === 'existing_partner_id' && payload !== undefined){
        const partnerId = payload;
        const state = store.getState();

        const selectedOrg = state[dataNames.organizations].find((partner) => partner.id === partnerId);
        const partnerTypes = ['purchase_from', 'sell_to', 'is_lab'].filter((key) => {
          return selectedOrg && selectedOrg[key];
        });
        store.dispatch(change(CONNECTS_REQUEST_FORM, 'partner_type', partnerTypes));
      }
    }
  }

  return result;

};

export default connectsRequestForm;
