import moment from 'moment';
import get from 'lodash.get';
import map from 'lodash.map';
import isEmpty from 'lodash.isempty';
import * as actionTypes from '../constants/actionTypes';
import {postItem, getItem} from '../actions/apiActions';
import {setData, pushData} from '../actions/dataActions';
import {setItem, unsetItem} from '../actions/itemActions';
import * as itemNames from '../constants/itemNames';
import * as dataNames from '../constants/dataNames';
import * as messageTypes from '../constants/messageTypes';
import {addMessage} from '../actions/systemActions';
import * as printerActions from '../actions/printerActions';
import {getPrintServerPayload, getPrintJobStats} from '../selectors/labelsSelectors';
import {getPrintServerInitialValues} from '../selectors/forms/printServerFormSelectors';


export const printJobsHandler = store => next => action => {

  const result = next(action);

  /**********************************************
   *  COMMON FUNCTIONS FOR PRINTER MIDDLE WARE
   **********************************************/

  /***
   * Local log
   * @param payload
   * @param event
   */
  const printJobLogging = (payload, event) => {
    const item = Object.assign({}, payload, {event, createdAt: moment()});
    store.dispatch(pushData([item], dataNames.printJobsLog));
  };

  /***
   * Status for client in all cases...
   * @param payload
   * @param message
   */
  const dispatchStatusMessage = (payload, message) => {
    const data = Object.assign({}, payload, {
      zpl: null,
      message: message,
    });
    store.dispatch(postItem('/api/labels/print_job_messages', data));
  };

  /***
   * Update printServer status if we are actually on a print server and state doesn't already agree with the desired state.
   * This is triggered by failure of QZ Tray and, potentially, by recovering of QZ Tray though right now the setting of the localPrintServer
   * prevents recovery as that could become a tug of war if trying to manually turn off a print server that's in use.
   * @param activeState
   * @returns {boolean}
   */
  const setPrintServerActive = (activeState) => {
    if(!get(action, 'payload.printer.server.remote')) return false; // Not remote - don't bother
    const localPrintServer = store.getState()[itemNames.localPrintServer];
    const printServer = getPrintServerInitialValues(store.getState(), {printServerId: localPrintServer.id});
    if(localPrintServer.active === activeState && printServer.active === activeState) return false; // Already set to state, don't bother
    const getPayload = (values) => { // Get fresh settings and use to build payload to keep overwrite danger to minimum
      return new Promise((resolve) => {
        store.dispatch(getItem('/api/labels/compliance_settings', itemNames.labelsCompliance)) // Always update against fresh data
          .then((labelsCompliance) => {
            resolve(getPrintServerPayload(values, labelsCompliance, false));
          });
      });
    };
    const updatedPrintServer = Object.assign({}, printServer, {active: activeState ? 1 : 0});
    getPayload(updatedPrintServer) // Update
      .then((payload) => {
        store.dispatch(postItem('/api/labels/compliance_settings', payload, itemNames.labelsCompliance));
        store.dispatch(setItem(updatedPrintServer, itemNames.localPrintServer));
      });
  };


  /***
   * Check if we have any queued print jobs or blocks of labels to print.
   * If we have printed all - execute given "onComplete" callback.
   */
  const executeOnCompleteCallback = (store, payload) => {
    const printJobs = store.getState()[dataNames.printJobs];
    const haveJobsQueued = (printJobs.length > 0);
    const haveBlocksToProceed = (payload.blocks_remaining > 0);
    if (!haveJobsQueued && !haveBlocksToProceed && payload.onComplete) {
      payload.onComplete();
    }
  };

  /***
   * Dispatch call to print the job.  May be invoked after loading a block of labels, or immediately if only one label
   * @param data
   * @param actionPayload
   */
  const dispatchPrintJobData = (data, actionPayload) => {
    let payload = { ...actionPayload };
    const END_CODE_CHAR = '^XZ';
    const AMOUNT_OF_PRINT = 1;

    if(!actionPayload.isReceipt) {
      const source_ids = actionPayload.blocks.shift() || [];
      const quantities = map(source_ids, 'quantity');
      const blocks_remaining = actionPayload.blocks.length;
      const splitZpl = data.zpl.split(END_CODE_CHAR).map((zpl) => {
        return `${zpl}${END_CODE_CHAR}`;
      });
      splitZpl.pop(); // Remove empty element as result of split
      payload = {
        ...actionPayload,
        blocks_loaded: actionPayload.blocks_loaded.concat([source_ids]),
        zpls: actionPayload.printSample ? [splitZpl.shift()] : splitZpl,
        quantities: !isEmpty(quantities) ? quantities : [AMOUNT_OF_PRINT],
        blocks_remaining,
        images: get(data, 'image'),
        printToScreenImages: get(data, 'printToScreenImages'),
        copies: get(actionPayload, 'copies', 1),
        zpl_array: get(data, 'zpl_array', get(actionPayload, 'zpl_array')),
      };
    }
    printJobLogging(payload, 'dispatch from get block');
    store.dispatch(setItem(payload, itemNames.printJob));
    if(get(payload, 'printer.server.remote', false)) {
      printJobLogging(payload, 'dispatchToRemoteFromBlock');
      store.dispatch(postItem('/api/labels/print_jobs', payload));
      executeOnCompleteCallback(store, payload);
    } else {
      printJobLogging(payload, 'dispatchLocallyFromBlock');
      store.dispatch(printerActions.printJobQueue(payload));
    }
    if(actionPayload.blocks.length && !actionPayload.printSample && !actionPayload.isReceipt){
      store.dispatch(printerActions.printJobGetBlock(payload));
    }
  };

  const getReceiptParams = (configString) => {
    // Passing configurables as comma delimited list of params tell us to look for these in particular
    const configurables = ['configParams', 'dataParams', 'outputParams'];

    // Default values we will always check for
    const configParams = [
      'width',
      'height',
      'colorType',
      'interpolation',
      'scaleContent',
      'units',
      'orientation'
    ];

    const calculateHeightValue = 1.33;

    const printParams = {
      configParams: {},
      dataParams: {},
      outputParams: {
        type: 'pdf'
      },
    };

    const setCalculatedHeight = (object, width) => {
      return object['height'] = parseFloat(width) * calculateHeightValue;
    };

    // Helper flag to calculate height from width as odd ratios produce odd results
    const calculateHeight = (getStringParam('calculateHeight', configString) !== undefined);

    // Get the adhoc injected params if any
    configurables.forEach((type) => {
      const temp = getStringParam(type, configString);
      if(typeof temp !== 'string') return false;
      const params = temp.split(',');
      params.forEach((p) => {
        const value = getStringParam(p, configString);
        if(value) {
          if(p === 'width' && calculateHeight) setCalculatedHeight(printParams[type], value);
          printParams[type][p] = value;
        }
      });
    });
    // Get standard config params if present
    configParams.forEach((p) => {
      const value = getStringParam(p, configString);
      if(value) {
        if(p === 'width' && calculateHeight) setCalculatedHeight(printParams['configParams'], value);
        printParams['configParams'][p] = value;
      }
    });
    return printParams;
  };

  const getReceiptPrinterConfig = (printParams) => {
    const defaultConfig = {
      scaleContent: true,
      copies: 1,
      margins: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      size: {
        width: 2.8,
        height: 10
      },
      units: 'in'
    };
    if(printParams.configParams !== undefined) {
      for (const p in printParams.configParams) {
        if((p === 'width' || p === 'height') && printParams.configParams[p]){
          defaultConfig.size[p] = parseFloat(printParams.configParams[p]);
          continue;
        }
        if(printParams.configParams[p]) defaultConfig[p] = printParams.configParams[p];
      }
    }
    return defaultConfig;
  };

  const getStringParam = (param, string) => {
    const params = {};
    const parts = string.split('&');
    for (let i = 0; i < parts.length; i++) {
      const nv = parts[i].split('=');
      if (!nv[0]) continue;
      params[nv[0]] = nv[1] || true;
    }
    return (params[param] !== undefined) ? params[param] : undefined;
  };

  /***************************************
   * ACTIONS
   ***************************************/

  /***
   * All print jobs start here.  Route either remotely or handle locally
   */
  if(action.type === actionTypes.PRINT_JOB_DISPATCH){ // ALL PRINT JOBS PASS THROUGH HERE FIRST
    const printer = store.getState()[itemNames.selectedPrinter][action.payload.tag];
    const printLabel = store.getState()[action.payload.isReceipt ? itemNames.printReceipt : itemNames.printLabel];
    if (!printLabel.call && action.payload.call) {
      printLabel.call = action.payload.call;
    }
    const printJobStats = getPrintJobStats(store.getState());
    const timeoutMessage = () => {
      store.dispatch(addMessage(messageTypes.warning, 'QZ Tray may be off at the print server.', true));
      store.dispatch(unsetItem(itemNames.printJob));
    };

    const payload = {
      uid: generatePrintJobUid(),
      facilityId: store.getState()[itemNames.facility].id,
      printer,
      zpl: printLabel.zpl,
      quantity: action.payload.quantity,
      timeout: get(printer, 'server.remote') ? setTimeout(timeoutMessage, 5000) : false,
      blocks: [].concat(printLabel.blocks),
      blocks_all: printLabel.blocks,
      blocks_loaded: [],
      blocks_remaining: printLabel.blocks !== undefined ? printLabel.blocks.length : 0,
      blocks_starting: printLabel.blocks !== undefined ? printLabel.blocks.length : 0,
      isReceipt: action.payload.isReceipt !== undefined ? action.payload.isReceipt : false,
      printToScreen: get(action, 'payload.printToScreen', false),
      image: get(printLabel, 'image'),
      copies: get(action, 'payload.copies', 1),
      onComplete: get(action, 'payload.onComplete', null),
    };
    if(action.payload.isReceipt){
      payload.images = printLabel.images !== undefined ? printLabel.images : [printLabel.base64];
    }
    printJobLogging(payload, 'dispatch');
    store.dispatch(setItem(payload, itemNames.printJob));
    if(printJobStats.distinctLabels === 1 || action.payload.printSample || action.payload.isReceipt){
      if(action.payload.printSample) payload.printSample = true;
      dispatchPrintJobData({zpl: printLabel.zpl}, payload);
    } else {
      store.dispatch(printerActions.printJobGetBlock(payload));
    }

  }

  if(action.type === actionTypes.PRINT_JOB_GET_BLOCK){
    const printLabel = store.getState()[itemNames.printLabel];
    const call = Object.assign({}, printLabel.call);
    const source_ids = action.payload.blocks[0] || [];

    call.params = Object.assign({}, call.params, {is_block: 1});

    if(call.method === 'POST'){
      call.payload = Object.assign({}, call.payload, {source_ids: JSON.stringify(source_ids), printToScreen: get(action, 'payload.printToScreen')});
      store.dispatch(postItem(call.url, call.payload, null, null, call.params))
        .then((data) => dispatchPrintJobData(data, action.payload));
    } else {
      call.params = Object.assign({}, call.params, {source_ids: JSON.stringify(source_ids)});
      store.dispatch(getItem(call.url, null, null, call.params))
        .then((data) => dispatchPrintJobData(data, action.payload));
    }
  }

  /***
   * Catches print jobs from server and puts them into own queue
   */
  if(action.type === actionTypes.REMOTE_PRINT_JOB){
    printJobLogging(action.payload, 'catchRemoteJob');
    const localPrintServer = store.getState()[itemNames.localPrintServer];
    if(localPrintServer && localPrintServer.id && action.payload.printer && action.payload.printer.server && action.payload.printer.server.id && parseInt(localPrintServer.id) === parseInt(action.payload.printer.server.id)) {
      store.dispatch(printerActions.printJobQueue(action.payload));
    }
  }

  /***
   * Puts a new print job on to the queue and shifts the next one off.  Handles empty queue and is called
   * by print on completion with false to just get the next job.
   */
  if(action.type === actionTypes.PRINT_JOB_QUEUE){ // PUTS A PRINT JOB ONTO THE QUEUE AND FIRES THE NEXT PRINT JOB
    printJobLogging(action.payload, 'inPrintJobQueue');
    // I ACTUALLY THINK this was caused by multiple print servers receiving the call.  Added filtering before this but leaving this out for now; is still superfluous.
    // This test is actually introducing errors.. so for the moment its disabled.  The idea was that it would trap its own "off"
    // status but as long as QZ tray is running... it doesn't really matter.  And if QZ tray is not running it will throw an error to that
    // effect.  The one error not trapped here is if the printer is a mis match for the media (send a receipt to zpl printer)... that produces an error
    // but the recovery actually occurs on the client side in a timeout currently set at 5 seconds.  So... can live without this live status check I think.
    // if(action.payload && action.payload.printer.server.remote){ // Payload is false when triggered from printing to move queue forward
    //   const localPrintServer = store.getState()[itemNames.localPrintServer];
    //   const localPrintServer = {};
    //   if(!localPrintServer.active){
    //     dispatchStatusMessage(action.payload, 'errorPrintServerNotActive');
    //     return result;
    //   }
    // }
    const printJobs = action.payload ? store.getState()[dataNames.printJobs].concat(action.payload) : store.getState()[dataNames.printJobs];
    if(printJobs.length > 0) {
      printJobLogging(action.payload, 'havePrintJobs');
      const printJob = printJobs.shift();
      store.dispatch(setData(printJobs, dataNames.printJobs))
        .then(() => {
          printJobLogging(action.payload, 'firePrinting');
          store.dispatch(printerActions.printJobPrint(printJob));
        });
    } else {
      printJobLogging(action.payload, 'noPrintJobs');
    }
  }

  /***
   * Sends a job to the printer and then calls queue to get the next job rolling.
   */
  if(action.type === actionTypes.PRINT_JOB_PRINT){ // SENDS PRINT JOB TO PRINTER AND THEN CHECKS QUEUE

    printJobLogging(action.payload, 'inPrinting');

    const qzTray = store.getState()[itemNames.qzTray];

    const printPrintJob = () => {
      const printerName = action.payload.printer.name;
      const quantity = action.payload.printSample ? 1 : parseInt(action.payload.quantity);
      const copies = get(action, 'payload.copies');
      let printData;
      let config;
      if(!action.payload.isReceipt) {
        const zplArray = [];
        if(get(action, 'payload.quantities', []).length < 2 && get(action, 'payload.quantities', [1])[0] === 1){

          // Multi part label or single label but not multiple labels from multiple sources
          for(let i = 0; i < quantity * copies; i++){
            (action.payload.zpls || [action.payload.zpl]).forEach((zpl) => {
              zplArray.push(zpl);
            });
          }
        } else {
          const quantities = get(action, 'payload.quantities', []);
          const multiPartLabelSize = quantities.length === action.payload.zpls ? 1 : action.payload.zpls.length / quantities.length;
          quantities.forEach((quantity) => {
            const zpls = action.payload.zpls.splice(0, multiPartLabelSize);
            for(let i = 0; i < quantity * copies; i++){
              zpls.forEach((zpl) => {
                zplArray.push(zpl);
              });
            }
          });
        }
        printData = zplArray;
        config = qzTray.configs.create(printerName);
      } else { // RECEIPT PRINTING IMAGES
        const configString = action.payload.printer.printParams && typeof action.payload.printer.printParams === 'string' ? action.payload.printer.printParams : '';
        const receiptParams = getReceiptParams(configString);
        const printerConfig = getReceiptPrinterConfig(receiptParams);
        const images = action.payload.images ? action.payload.images : [action.payload.base64];
        printData = images.map((image) => {
          return {
            type: receiptParams.outputParams.type === 'image' ? 'image' : 'pdf',
            format: 'base64',
            data: image,
          };
        });
        config = qzTray.configs.create(printerName, printerConfig);
      }

      if(get(action, 'payload.printToScreen')) {
        const quantities = get(action, 'payload.quantities', []);
        const labelImages = (Array.isArray(quantities) ? quantities : []).reduce((acc, quantity, index) => {
          let temp = get(action, `payload.printToScreenImages.${index}`, false);
          if(!temp){
            temp = get(action, 'payload.image.base64_collection');
          }
          const images = Array.isArray(temp) ? temp : [temp];
          for(let n = 0; n < quantity * copies; n++){
            images.forEach((image) => {
              acc.push({
                uid: get(action, 'payload.uid'),
                base64: image,
                facilityId: get(action, 'payload.facilityId'),
              });
            });
          }
          return acc;
        }, []);
        store.dispatch(printerActions.printToScreen(labelImages));
        printJobLogging(action.payload, 'completedPrinting');
        dispatchStatusMessage(action.payload, 'completedPrinting');
        store.dispatch(printerActions.printJobQueue(false));
        return true;
      }

      qzTray.print(config, printData).then(() => {
        executeOnCompleteCallback(store, action.payload);
        printJobLogging(action.payload, 'completedPrinting');
        dispatchStatusMessage(action.payload, 'completedPrinting');
        store.dispatch(printerActions.printJobQueue(false));
      }).catch((error) => store.dispatch(addMessage(messageTypes.error, error)));
    };

    if(qzTray.websocket.isActive() || get(action, 'payload.printToScreen')){
      printPrintJob();
    } else {
      qzTray.websocket.connect()
        .then(() => {
          setPrintServerActive(true);
          printPrintJob();
        })
        .catch(() => {
          // setPrintServerActive(false); //Introduces inconsistency so while this makes sense, it makes understanding things harder
          // Left for temporary reference.
          printJobLogging(action.payload, 'errorCannotConnectQzTray');
          dispatchStatusMessage(action.payload, 'errorCannotConnectQzTray');
        });
    }


  }

  return result;

};

/***
 * Called whenever the selected printer changes by automation or selection.
 * @param store
 */
export const setSelectedPrinterByTag = store => next => action => {
  const result = next(action);
  if(action.type === actionTypes.SET_SELECTED_PRINTER){
    const printer = Object.assign({}, action.payload.printer);
    delete(printer[action.payload.tag]); // Defeat some circular reference.
    const selectedPrinter = Object.assign({}, store.getState()[itemNames.selectedPrinter]);
    selectedPrinter[action.payload.tag] = printer;
    store.dispatch(setItem(selectedPrinter, itemNames.selectedPrinter));
  }
  return result;
};

/***
 * Handle inbound print job messages
 * @param store
 */
export const handlePrintJobMessage = store => next => action => {
  const result = next(action);
  if(action.type === actionTypes.PRINT_JOB_MESSAGE){
    const printJob = store.getState()[itemNames.printJob];
    if(action.payload.uid === printJob.uid){
      setTimeout(() => {
        if(printJob.timeout) clearTimeout(printJob.timeout);
        // Clear the printJob if blocks_remaining is set and zero so all labels loaded for this...
        if((action.payload.blocks_remaining !== undefined && action.payload.blocks_remaining === 0) || action.payload.blocks === undefined || action.payload.printSample || action.payload.isReceipt){
          store.dispatch(unsetItem(itemNames.printJob));
        }
      }, 500); // Artificial delay for UX purposes only.
      const errorCodes = ['errorCannotConnectQzTray', 'errorPrintServerNotActive'];
      if(errorCodes.indexOf(action.payload.message) > -1){
        store.dispatch(addMessage(messageTypes.error, action.payload.message, false));
      }
    }
  }
  return result;
};

/***
 * Utility
 * @param separator
 * @returns {string}
 */
const generatePrintJobUid = (separator = '-') => {
  const delim = separator || '-';
  const S4 = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (S4() + S4() + delim + S4() + delim + S4() + delim + S4() + delim + S4() + S4() + S4());
};
