import {getFormValues} from 'redux-form';
import * as types from '../../constants/actionTypes';
import * as dataNames from '../../constants/dataNames';
import {CREATE_PROPAGATION_MATERIAL_FORM} from '../../constants/forms';
import {getSearchData} from '../../actions/apiActions';

const propagationMaterialForm = store => next => action => {
  const result = next(action);
  const {meta, type, payload} = action;
  const state = store.getState();
  const values = getFormValues(CREATE_PROPAGATION_MATERIAL_FORM)(state);
  /*** CREATE PROPAGATION MATERIAL ****/
  if (meta && meta.form === CREATE_PROPAGATION_MATERIAL_FORM) {
    if (type === types.REDUX_FORM_CHANGE) {
      if (meta.field === 'starting_item_master_id' && payload) {
        const {itemMasters} = state;
        const selectedItemMaster = itemMasters.find(itemMaster => itemMaster.id == values.starting_item_master_id);
        const params = {
          query: 'matchall',
          filter: `strain_id:${selectedItemMaster.strain_id} AND is_harvested:0 AND is_destroyed:0 AND is_packaged:0 `,
          fields: ['id', 'plant_id', 'matriarch_plant_name', 'state_integration_tracking_id', 'code', 'is_mother'],
          sort: 'plant_id asc',
          size: 100000
        };
        store.dispatch(getSearchData('/api/search/plants', dataNames.motherPlants, null, params));
      }
    }
  }

  return result;
};

export default propagationMaterialForm;
