import * as types from '../constants/actionTypes';
import * as dataNames from '../constants/dataNames';
import {setData} from '../actions/dataActions';
import {postItem} from '../actions/apiActions';

const qz = require('qz-tray');

export function printJobDispatch(payload){
  return {type: types.PRINT_JOB_DISPATCH, payload};
}

export function printJobQueue(payload){
  return {type: types.PRINT_JOB_QUEUE, payload};
}

export function printJobGetBlock(payload){
  return {type: types.PRINT_JOB_GET_BLOCK, payload};
}

export function printJobPrint(payload){
  return {type: types.PRINT_JOB_PRINT, payload};
}

export function setQzTray (payload) {
  return {type: types.SET_QZ_TRAY, payload};
}

export function handlePrintJob (payload){
  return {type: types.HANDLE_PRINT_JOB, payload};
}

export function importLocalPrinters(){
  return {type: types.IMPORT_LOCAL_PRINTERS, payload: {}};
}

export function setPrintJob(){
  return {type: types.SET_PRINT_JOB};
}

export function setSelectedPrinterByTag(tag, printer){
  return {type: types.SET_SELECTED_PRINTER, payload: {tag, printer}};
}

export function printToScreen(payload){
  return (dispatch, getState) => {
    const queue = getState()[dataNames.printToScreenQueue];
    const newQueue = queue.concat(payload);
    dispatch(setData(newQueue, dataNames.printToScreenQueue));
  };
}

export function clearPrintToScreen(){
  return (dispatch) => {
    dispatch(setData([], dataNames.printToScreenQueue));
  };
}

export function getQzTray(getSignature){

  return (dispatch, getState) => {

    qz.api.setPromiseType((resolver) => {
      return new Promise(resolver);
    });

    qz.security.setCertificatePromise((resolve) => {
      resolve(`-----BEGIN CERTIFICATE-----
MIIE2TCCAsGgAwIBAgIQNzkyMDI0MDUwODE5MzYzMDANBgkqhkiG9w0BAQsFADCB
mDELMAkGA1UEBhMCVVMxCzAJBgNVBAgMAk5ZMRswGQYDVQQKDBJRWiBJbmR1c3Ry
aWVzLCBMTEMxGzAZBgNVBAsMElFaIEluZHVzdHJpZXMsIExMQzEZMBcGA1UEAwwQ
cXppbmR1c3RyaWVzLmNvbTEnMCUGCSqGSIb3DQEJARYYc3VwcG9ydEBxemluZHVz
dHJpZXMuY29tMB4XDTI0MDUwODE5MzYzMFoXDTI1MDUwODIwMzIwNVowgZIxCzAJ
BgNVBAYMAlVTMREwDwYDVQQIDAhDb2xvcmFkbzEPMA0GA1UEBwwGRGVudmVyMRMw
EQYDVQQKDApNSiBGcmVld2F5MRMwEQYDVQQLDApNSiBGcmVld2F5MRMwEQYDVQQD
DApNSiBGcmVld2F5MSAwHgYJKoZIhvcNAQkBDBFkbWNAbWpmcmVld2F5LmNvbTCC
ASIwDQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEBAMZXd1DfAO1KwJN3NkH4jW3N
QTtIhKA3hT6YWZJyCf3PqhpoW8qac2+zg0Q5xd5bio59IdeuJ77eju4Riuj2B6w6
5IsB783U2vZrO0cdBVwrkOGZ9AIsVwPyhpLicl+MqGCSbfycrPEH5x87T9fIKn3a
seYu/tWp5krksrTGnXz/wJTW7vr44oNhTtWDNm61+WCTERrv67k63S+EHdSgGIUc
JhQVEeQymuRx/4EqR+0Gr0j7mjOKYNii8qP6ZkEIyYBWZZIRrOJHbyQl+HOk/4IG
S9GQFjCMzxoDWWSd4kc2C/ITB8qBJ5V930DSIJxV0gWBtdYYuS1RhWHeJf662j0C
AwEAAaMjMCEwHwYDVR0jBBgwFoAUkKZQt4TUuepf8gWEE3hF6Kl1VFwwDQYJKoZI
hvcNAQELBQADggIBAKhvmpxxKw5qatdj7Yjkm9AH8bEaSbG4lZs25JJNzl78EctM
k8bU72Oz4j2DWuJf6k7Sh1E6lDhmXdwiWGPyhjEiGyiVVLXSakB7Wlv/pPcbxEs0
yc1P/KIsnKUN0VHgEFGUPolyuBryhacXFpgRucKlM0LbmU46JrM8wLH9ey+ousNe
XABrqY6z5jzuWjNEVqlcOfkpXBobWogsFcuiceWce8DE68uOA1ED5BbOz5N3zB/O
23UCYOm/nmVLAeR5MGDQoXL4Rx31rQdEyadDUBFWjZ8U8FqOw8tVC3ulU7iO1FDC
SpB1gZ4hUm/0et0cOcD5+vob9VFtLef5sycItjYRHG8ZU9vuNJd7T8dRysmlQK8N
o0Mg9fcccGPr1c6ISih9NppK4bM5JySS7YlCjyi1hapqZTgbnt8tESO56br/yohY
spv0tCxegCIkcEx/djjOd8cFO7sjLuaZ1MGkqi1ax0O8V6j7zsTmZEH5NuK9TP7P
l9Hn+WOJmb4VqlknOKT45ltmY7H8o5cB/w49qpEc/UeBLd9zChQbFM+jmHOpobhu
cflzn7ZjQgZA4kzxSMaardi2nFW2eRmjs3U6QOq97JpXOYb12+3+ND+Q8eO/Qu2x
FWe9OhJhzcVqANk6rSf0+jI3+0JBVve5gGBsRV65FK/X1jPG0/gwxdPYpsnm
-----END CERTIFICATE-----
--START INTERMEDIATE CERT--
-----BEGIN CERTIFICATE-----
MIIFEjCCA/qgAwIBAgICEAAwDQYJKoZIhvcNAQELBQAwgawxCzAJBgNVBAYTAlVT
MQswCQYDVQQIDAJOWTESMBAGA1UEBwwJQ2FuYXN0b3RhMRswGQYDVQQKDBJRWiBJ
bmR1c3RyaWVzLCBMTEMxGzAZBgNVBAsMElFaIEluZHVzdHJpZXMsIExMQzEZMBcG
A1UEAwwQcXppbmR1c3RyaWVzLmNvbTEnMCUGCSqGSIb3DQEJARYYc3VwcG9ydEBx
emluZHVzdHJpZXMuY29tMB4XDTE1MDMwMjAwNTAxOFoXDTM1MDMwMjAwNTAxOFow
gZgxCzAJBgNVBAYTAlVTMQswCQYDVQQIDAJOWTEbMBkGA1UECgwSUVogSW5kdXN0
cmllcywgTExDMRswGQYDVQQLDBJRWiBJbmR1c3RyaWVzLCBMTEMxGTAXBgNVBAMM
EHF6aW5kdXN0cmllcy5jb20xJzAlBgkqhkiG9w0BCQEWGHN1cHBvcnRAcXppbmR1
c3RyaWVzLmNvbTCCAiIwDQYJKoZIhvcNAQEBBQADggIPADCCAgoCggIBANTDgNLU
iohl/rQoZ2bTMHVEk1mA020LYhgfWjO0+GsLlbg5SvWVFWkv4ZgffuVRXLHrwz1H
YpMyo+Zh8ksJF9ssJWCwQGO5ciM6dmoryyB0VZHGY1blewdMuxieXP7Kr6XD3GRM
GAhEwTxjUzI3ksuRunX4IcnRXKYkg5pjs4nLEhXtIZWDLiXPUsyUAEq1U1qdL1AH
EtdK/L3zLATnhPB6ZiM+HzNG4aAPynSA38fpeeZ4R0tINMpFThwNgGUsxYKsP9kh
0gxGl8YHL6ZzC7BC8FXIB/0Wteng0+XLAVto56Pyxt7BdxtNVuVNNXgkCi9tMqVX
xOk3oIvODDt0UoQUZ/umUuoMuOLekYUpZVk4utCqXXlB4mVfS5/zWB6nVxFX8Io1
9FOiDLTwZVtBmzmeikzb6o1QLp9F2TAvlf8+DIGDOo0DpPQUtOUyLPCh5hBaDGFE
ZhE56qPCBiQIc4T2klWX/80C5NZnd/tJNxjyUyk7bjdDzhzT10CGRAsqxAnsjvMD
2KcMf3oXN4PNgyfpbfq2ipxJ1u777Gpbzyf0xoKwH9FYigmqfRH2N2pEdiYawKrX
6pyXzGM4cvQ5X1Yxf2x/+xdTLdVaLnZgwrdqwFYmDejGAldXlYDl3jbBHVM1v+uY
5ItGTjk+3vLrxmvGy5XFVG+8fF/xaVfo5TW5AgMBAAGjUDBOMB0GA1UdDgQWBBSQ
plC3hNS56l/yBYQTeEXoqXVUXDAfBgNVHSMEGDAWgBQDRcZNwPqOqQvagw9BpW0S
BkOpXjAMBgNVHRMEBTADAQH/MA0GCSqGSIb3DQEBCwUAA4IBAQAJIO8SiNr9jpLQ
eUsFUmbueoxyI5L+P5eV92ceVOJ2tAlBA13vzF1NWlpSlrMmQcVUE/K4D01qtr0k
gDs6LUHvj2XXLpyEogitbBgipkQpwCTJVfC9bWYBwEotC7Y8mVjjEV7uXAT71GKT
x8XlB9maf+BTZGgyoulA5pTYJ++7s/xX9gzSWCa+eXGcjguBtYYXaAjjAqFGRAvu
pz1yrDWcA6H94HeErJKUXBakS0Jm/V33JDuVXY+aZ8EQi2kV82aZbNdXll/R6iGw
2ur4rDErnHsiphBgZB71C5FD4cdfSONTsYxmPmyUb5T+KLUouxZ9B0Wh28ucc1Lp
rbO7BnjW
-----END CERTIFICATE-----`);
    });

    qz.security.setSignaturePromise((toSign) => {

      return (resolve, reject) => {
        getSignature(resolve, reject, toSign);
      };

    });

    const createHash = require('sha.js');

    qz.api.setSha256Type((data) => {
      return createHash('sha256').update(data).digest('hex');
    });

    dispatch(setQzTray(qz));

  };
}

export function signQzRequest(resolve, reject, toSign){
  // eslint-disable-next-line
  return (dispatch, getState) => {
    const action = postItem('/api/labels/sign', {
      request: toSign
    });

    return dispatch(action).then(signature => {
      resolve(signature);
    });
  };
}
