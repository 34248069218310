import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {reduxForm, FieldArray} from 'redux-form';
import {Button, ButtonToolbar} from 'react-bootstrap';
import ProfilesFieldArray from './ProfilesFieldArray';

const ReorderForm = ({taxProfiles, handleSubmit, reset, facility, facilities}) => {
  return (<form onSubmit={handleSubmit}>
        <FieldArray component={ProfilesFieldArray} name={'tax_profiles'} facility={facility} facilities={facilities}/>
        <ButtonToolbar className='float-right'>
          <Button onClick={reset}>{I18n.t('common.form.reset')}</Button>
          <Button  type={'submit'} variant='primary'>{I18n.t('common.form.submit')}</Button>
        </ButtonToolbar>
  </form>);
};



ReorderForm.propTypes = {
  reset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  taxProfiles: PropTypes.array.isRequired,
  facility: PropTypes.object.isRequired,
  facilities: PropTypes.array.isRequired,
};


export default reduxForm({
  enableReinitialize: true,
  // initialValues,
  // validate
})(ReorderForm);
