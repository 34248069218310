import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';
import {posTransactionIsInRange, requiredFieldValidation, maxLengthValidation} from '../common/form/redux-form/validations';
import {formatCurrencyDecimal} from '../../util/formatHelper';

const validateOrderMeta = (values, ownProps) => {
  const errors = {};

  if(ownProps.required && Array.isArray(ownProps.required)) {
    if (ownProps.required.indexOf('driver_id') !== -1) {
      if (!values.driver_id || values.driver_id === null) {
        errors.driver_id = 'Driver Is Required.';
      }
      if (ownProps.isMetrcDelivery && values.driver_id) {
        // driver's and vehicle's fields are required for Oregon, Nevada, Maryland, Michigan
        // double check in the way of Metrc Transfer created drivers and vehicles
        const driver = ownProps.drivers.find(item => item.id == values.driver_id);
        const checkDriverFields = ['state_compliance_number', 'drivers_license_number'];
        const errorsDriverFields = ['driverStateComplianceLicense', 'driverDriverLicense'];
        checkDriverFields.forEach((item, index) => {
          if (!get(driver, item)) {
            errors.driver_id = I18n.t('common.form.isRequired', {fieldName: I18n.t(`cart.payment.${errorsDriverFields[index]}`)});
          }
        });

        const driverPhones = driver.driver_phones.find(item => item.driver_id == driver.id);
        if (!get(driverPhones, 'number')) {
          errors.driver_id = I18n.t('common.form.isRequired', {fieldName: I18n.t('cart.payment.driverPhone1')});
        }

        const vehicle = ownProps.vehicles.find(item => item.id == driver.default_vehicle_id);
        const checkVehicleFields = ['make', 'model', 'license_number'];
        const errorsVehicleFields = ['driverVehicleMake', 'driverVehicleModel', 'driverVehicleLicensePlate'];
        checkVehicleFields.forEach((item, index) => {
          if (!get(vehicle, item)) {
            errors.driver_id = I18n.t('common.form.isRequired', {fieldName: I18n.t(`cart.payment.${errorsVehicleFields[index]}`)});
          }
        });
      }
    }
    if (ownProps.required.indexOf('delivery_address_id') !== -1) {
      if (!values.delivery_address_id || values.delivery_address_id === null) {
        errors.delivery_address_id = 'Delivery Address Is Required.';
      }
    }
    if (ownProps.required.indexOf('onfleet_phone_number') !== -1 && ownProps.isOnfleetEnabled) {
      if (!values.onfleet_phone_number || values.onfleet_phone_number === null) {
        errors.onfleet_phone_number = 'Phone number is required.';
      }
    }

    if (values.departure_time && values.arrival_time) {
      if (new Date(values.departure_time) > new Date(values.arrival_time)) {
        errors.arrival_time = I18n.t('purchaseOrders.arrivalTimeError');
      }
    }

  }
  return errors;
};

export const paymentValidation = (values, ownProps) => {
  const errors = {};

  if (ownProps.isPosabitToggled && values.posabit) {
    const payments = get(ownProps.order, 'payments', []);
    const pendingAmountTotal = payments.reduce((total, payment) => {
      return payment.is_pending ? Number(payment.amount) + total : 0 + total;
    }, 0);
    if (formatCurrencyDecimal(ownProps.order.balance_due) - formatCurrencyDecimal(pendingAmountTotal) < formatCurrencyDecimal(values.posabit)) {
      errors.posabit = pendingAmountTotal ? I18n.t('cart.payment.posabitAmountExceedsRemaining') : I18n.t('cart.payment.posabitAmountExceedsOrderTotal');
      return errors;
    }
  }

  // This validation is only for the AeroPay Pre Authorization Modal
  if (ownProps.isShowAeropayintegratedModal && parseFloat(ownProps.order.order_total) < parseFloat(values.revised_aeropayintegrated)) {
    errors.revised_aeropayintegrated = I18n.t('cart.payment.aeropayintegrated.error');
    return errors;
  }

  if(ownProps.paymentOptions && Array.isArray(ownProps.paymentOptions)){
    ownProps.paymentOptions.forEach((option) => {
      errors[option.value] = posTransactionIsInRange(values[option.value], 'upper');
    });
  }

  if (values.hypur && ownProps.isHypur) {
    errors.hypur_payment = {
      individual_isn: requiredFieldValidation(values && values.hypur_payment && values.hypur_payment.individual_isn),
      pac: requiredFieldValidation(values && values.hypur_payment && values.hypur_payment.pac) && maxLengthValidation(get(values, 'hypur_payment.pac'), 'hypur_payment.pac', 4)
    };

    if (ownProps.amountDue !== 0) {
      errors.hypur = I18n.t('cart.payment.hypurZeroBalance');
    }
  }

  if((values.credit || values.debit) && ownProps.allowCreditCard){
    if (values.charge_amount || values.is_override) {
      return errors;
    }
    errors['credit_card'] = {
      number: requiredFieldValidation(values && values.credit_card && values.credit_card.number),
      exp_month: requiredFieldValidation(values && values.credit_card && values.credit_card.exp_month),
      exp_year: requiredFieldValidation(values && values.credit_card && values.credit_card.exp_year),
      cvv: requiredFieldValidation(values && values.credit_card && values.credit_card.cvv),
      zip: get(ownProps, 'isMoneris') &&
        requiredFieldValidation(values && values.credit_card && values.credit_card.zip),
    };
  }
  return errors;
};


export default validateOrderMeta;
