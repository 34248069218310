
import * as types from '../constants/actionTypes';
import initialState from '../constants/initialState';

export default function selectedPackageInfoReducer (state = initialState.selectedPackageInfo, action){
  switch (action.type) {
  case types.SET_PACKAGE_INFO:
    return Object.assign({}, state, {
      strain: action.packageInfo.strain,
      weight: action.packageInfo.weight,
      vendor: action.packageInfo.vendor
    });
  default:
    return state;
  }
}
