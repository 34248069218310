import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import {Col, Row} from 'react-bootstrap';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import TextInput from '../../../common/form/TextInput';

class PartnerSection extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.onPartnerFacilityChange = this.onPartnerFacilityChange.bind(this);
    this.state = {partnerFacilityLicenseValid: 1};
  }

  // NOTE: TGC-140 - move this to a selector, delete this function
  onPartnerFacilityChange(value) {
    const {change, partnerFacilities, onPartnerFacilityChange} = this.props;

    change('partner_facility_id', value);

    onPartnerFacilityChange(value);

    this.setState({partnerFacilityLicenseValid: 0});
    if (value) {
      const selected = partnerFacilities.filter(facility => facility.id === value);
      if(selected.length) {
        const licenses = selected[0].licenses;
        if(licenses && licenses.length) {
          const valid = licenses.filter(item => {
            const expires = moment(item.expires_on);
            const now = moment();
            return item.expires_on === null || now.isBefore(expires);
          });

          if(valid.length) {
            this.setState({partnerFacilityLicenseValid: 1});
          }
        }
      }
    }
  }


  render() {
    const {orderTypeOptions, partnerOptions, partnerFacilityOptions, disabled, partnerFacilityTypes, showPartnerType,
      showPartnerInvoiceNumber, isOklahoma, isCanada, isMetrc, isMetrcTransfersEnabled, partnerInvoiceNumberRequired} = this.props;

    const { partnerFacilityLicenseValid } = this.state;

    return (
      <Row>
        <Col xs={6} sm={4} md={3}>
          <Field name='order_type' component={ReactSelectInput} props={{
            options: orderTypeOptions,
            label: I18n.t('salesOrders.form.orderType'),
            isRequired: true,
            disabled,
          }}/>
        </Col>
        <Col xs={6} sm={4} md={3}>
          <Field name='partner_id' component={ReactSelectInput} props={{
            options: partnerOptions,
            label: I18n.t('salesOrders.form.partnerName'),
            textKey: 'name',
            valueKey: 'id',
            isRequired: true,
            disabled,
          }}/>
        </Col>
        <Col xs={6} sm={4} md={3}>
          <Field name='partner_facility_id' component={ReactSelectInput} props={{
            options: partnerFacilityOptions,
            onChange: this.onPartnerFacilityChange,
            label: I18n.t('purchaseOrders.form.partnerFacility'),
            textKey: 'facility_name',
            valueKey: 'id',
            isRequired: true,
            disabled,
          }}/>
          {!partnerFacilityLicenseValid && isMetrc && isMetrcTransfersEnabled &&
            <div className='alert alert-warning'>{I18n.t('supplyChain.metrcTransferWarnings.partnerFacilityLicense')}</div>
          }
        </Col>
        {showPartnerType ?
        <Col xs={6} sm={4} md={3}>
          <Field name='partner_facility_type' component={ReactSelectInput}
            props={{
              label: (isOklahoma || isCanada) ? I18n.t('purchaseOrders.form.partnerFacilityType') : I18n.t('purchaseOrders.form.partnerType'),
              options: partnerFacilityTypes,
              textKey: 'text',
              valueKey: 'value',
              isRequired: true,
            }}/>
        </Col>
        : null}
        {showPartnerInvoiceNumber &&
        <Col xs={6} sm={4} md={3}>
          <Field name='partner_invoice_number' component={TextInput} props={{
            label: I18n.t('salesOrders.form.partnerInvoiceNumber'),
            placeholder: I18n.t('purchaseOrders.form.partnerInvoicePlaceholder'),
            isRequired: partnerInvoiceNumberRequired
          }}/>
        </Col>}
      </Row>
    );
  }
}

PartnerSection.propTypes = {
  change: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  orderTypeOptions: PropTypes.array.isRequired,
  partnerOptions: PropTypes.array.isRequired,
  partnerFacilityOptions: PropTypes.array.isRequired,
  partnerFacilities: PropTypes.array.isRequired,
  partnerFacilityTypes: PropTypes.array,
  disabled: PropTypes.bool,
  isOklahoma: PropTypes.bool,
  isCanada: PropTypes.bool,
  showPartnerInvoiceNumber: PropTypes.bool,
  onPartnerFacilityChange: PropTypes.func,
  partnerInvoiceNumberRequired: PropTypes.bool
};

PartnerSection.defaultProps = {
  onPartnerFacilityChange: () => {},
  partnerInvoiceNumberRequired: false
};

export default PartnerSection;
