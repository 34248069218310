import React from 'react';
import PropTypes from 'prop-types';
import {Button, ButtonToolbar, Col, Row} from 'react-bootstrap';
import {Field, FieldArray} from 'redux-form';
import {I18n} from 'react-redux-i18n';

import DatePickerInput from '../../common/form/DatePickerInput';
import TextAreaInput from '../../common/form/TextAreaInput';
import HarvestItemsFieldArray from './HarvestItemsFieldArray';

const ActivateHarvestBatchesForm = ({form, invalid, pristine, submitting, handleSubmit, storageLocations}) => (
  <form onSubmit={handleSubmit} name={form} noValidate={true} className='activate-harvest-batch'>
    <FieldArray name='items' component={HarvestItemsFieldArray} storageLocations={storageLocations}/>
    <Row>
      <Col xs={12} sm={7} md={6} lg={4}>
        <Field name='notes' component={TextAreaInput} props={{
          label: I18n.t('packages.form.notes'),
        }}/>
      </Col>
      <Col xs={6} sm={5} md={{span: 4, offset: 2}} lg={{span: 3, offset: 5}}>
        <Field name='reactivated_at' component={DatePickerInput} props={{
          label: I18n.t('common.form.date'),
          dateFormat: 'MM/DD/YYYY',
          closeOnSelect: true,
        }}/>
      </Col>
    </Row>
    <Row className='submit-section'>
      <Col xs={12}>
        <div className='button-wrapper'>
          <ButtonToolbar className='float-right'>
            <Button variant='primary'
                    disabled={invalid || submitting}
                    type='submit'>
              {I18n.t('common.form.save')}
            </Button>
          </ButtonToolbar>
        </div>
      </Col>
    </Row>
  </form>
);

ActivateHarvestBatchesForm.propTypes = {
  form: PropTypes.string,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  storageLocations: PropTypes.array.isRequired,
};

export default ActivateHarvestBatchesForm;
