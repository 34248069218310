import {createSelector, createStructuredSelector} from 'reselect';
import {formValueSelector} from 'redux-form';
import {COMPLETE_EACH_PACKAGES_FORM} from '../../constants/forms';
import * as itemNames from '../../constants/itemNames';
import {convertFromBase} from '../../util/uomHelpers';
import {GR} from '../../constants/uoms';
import {getInventoryItem, getItemProducerName} from '../inventoryItemsSelectors';
import {getItemMasters} from '../itemMastersSelectors';
import {getStrain} from '../strainsSelectors';
import {getFlattenedLocations} from '../locationsSelectors';
import {getCurrentFacilityUserOptions} from '../usersSelectors';
import {getPartners} from '../partnersSelectors';
import {getActiveFacility} from '../facilitiesSelectors';
import {isFeatureEnabled} from '../featureToggles';
import {convertFormInputDateToDbDate, formatDBDate} from '../../util/dateHelpers';
import {getTimezone} from '../timezoneSelectors';
import {getIntegrationState} from '../integration/integrationSelectors';


const formSelector = formValueSelector(COMPLETE_EACH_PACKAGES_FORM);
const trackingIds = state => state.trackingIds || [];
const getFormPackages = state => formSelector(state, 'packages') || [];
export const getFormPackage = (state, index) => formSelector(state, `packages[${index}]`);
const getJobId = (_, props) => props && props.params && Number(props.params.id);
const getPackagingJob = state => state[itemNames.packagingJob];
const isMetrcStemHoldingsToggled = state => isFeatureEnabled(state)('feature_metrc_stem_holdings');
const isMetrcCaDonationsToggled = state => isFeatureEnabled(state)('feature_metrc_ca_donations');


const getJob = createSelector(
  [getPackagingJob, getJobId],
  (job, jobId) => job.id === jobId ? job : null
);
const getJobStrain = createSelector(
  [getJob, getStrain],
  (job, strain) => job && job.item.item_master.strain_id === strain.id ? strain : null
);
const getJobItem = createSelector(
  [getJob, getInventoryItem, getJobStrain, getActiveFacility, getPartners],
  (job, item, strain, activeFacility, partners) => {
    if (!job || job.item.id !== item.id) {
      return null;
    }
    return {
      ...item,
      strain_name: strain && strain.strain_name,
      producer_name: getItemProducerName(item, activeFacility, partners),
      qty_available: (item.reservations || []).reduce(
        (qty, reservation) => {
          return qty - convertFromBase(reservation.qty_base, item.uom_display);
        },
        item.qty || 0
      ),
    };
  }
);

const getJobEmployees = createSelector(
  [getJob, getCurrentFacilityUserOptions],
  (job, users) => {
    return (job && job.packaging_job_assigned_to_users || [])
      .map(jobUser => users.find(user => user.id === jobUser.user_id))
      .filter(Boolean);
  }
);
const getItemMasterOptions = createSelector(
  [getItemMasters, getJobStrain],
  (itemMasters, strain) => itemMasters.filter(
    im => strain && im.strain_id === strain.id
  )
);

const getInitialValues = createSelector(
  [getJob, getJobItem, getItemMasterOptions, getIntegrationState],
  (job, item, itemMasters, integrationState) => {
    if (!job || !item) {
      return {
        item: {},
        packages: [],
        waste_reported_uom: GR,
        packaged_loss_amt_uom: GR,
        transacted_uom: GR,
      };
    }

    return {
      ...job,
      item,
      packages: itemMasters.map(itemMaster => ({
        item_master_id: itemMaster.id,
        name: itemMaster.name,
        pieces_per_unit: itemMaster.pieces_per_unit,
        net_weight_base: itemMaster.net_weight_base,
        uom: itemMaster.default_uom,
        finished: integrationState && integrationState.isCanada ? 1 : 0,
      })),
      waste_reported_uom: GR,
      packaged_loss_amt_uom: GR,
      transacted_uom: GR,
    };
  }
);

const getPackagedWeight = createSelector(
  [getFormPackages],
  packages => packages.reduce(
    (weight, pkg) => weight + Number(pkg.qty || 0),
    0
  )
);

export const getCompleteEachPackagesPageProps = createStructuredSelector({
  timezone: getTimezone,
  initialValues: getInitialValues,
  employees: getJobEmployees,
  itemMasters: getItemMasterOptions,
  locations: getFlattenedLocations,
  packagedWeight: getPackagedWeight,
  integrationState: getIntegrationState,
  trackingIds: trackingIds,
  isEachPackaging: () => true,
  isMetrcStemHoldingsToggled,
  isMetrcCaDonationsToggled,
});

export const getCompleteSeedPackagesPageProps = createStructuredSelector({
  timezone: getTimezone,
  initialValues: getInitialValues,
  employees: getJobEmployees,
  itemMasters: getItemMasterOptions,
  locations: getFlattenedLocations,
  packagedWeight: getPackagedWeight,
  integrationState: getIntegrationState,
  trackingIds: trackingIds,
  isSeedPackaging: () => true
});

export const getCompleteEachPackagesPayload = (formValues, timezone) => {
  return {
    packaging_job_id: formValues.id,
    storage_location_id: formValues.storage_location_id,
    completed_at: formValues.completed_at ? convertFormInputDateToDbDate(formValues.completed_at, timezone) : null,
    package_created_at: formValues.package_created_at ? formatDBDate(formValues.package_created_at) : null,
    package_expires_at: formValues.package_expires_at ? formatDBDate(formValues.package_expires_at) : null,
    packaged_loss_amt: formValues.packaged_loss_amt,
    packaged_loss_amt_uom: formValues.packaged_loss_amt_uom,
    waste_reported: formValues.waste_reported,
    waste_reported_uom: formValues.waste_reported_uom,
    transacted_qty: formValues.remaining_weight - formValues.item.qty,
    transacted_uom: formValues.transacted_uom,
    product_not_altered: formValues.product_not_altered || '0',
    is_each_packaging: formValues.is_each_packaging || undefined,
    is_trade_sample: formValues.is_trade_sample,
    packages: formValues.packages.filter(item => item.qty).map(pkg => {
      return {
        finished: pkg.finished,
        item_master_id: pkg.item_master_id,
        package_code: pkg.package_code,
        qty: pkg.qty,
        uom: pkg.uom,
        purpose: formValues.purpose,
        state_integration_tracking_id: formValues.state_integration_tracking_id || undefined,
      };
    }),
  };
};
