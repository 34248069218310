import React from 'react';
import PropTypes from 'prop-types';
import { Modal} from 'react-bootstrap';

const ProductHistoryNotes = ({notes}) => (
  <Modal.Body>
    <div>{notes}</div>
  </Modal.Body>
);

ProductHistoryNotes.propTypes = {
  notes: PropTypes.string,
};

export default ProductHistoryNotes;
