/* eslint-disable import/prefer-default-export */
import {PERSON, PERSON_FEMALE, PERSON_MALE, PERSON_ANONYMOUS} from '../constants/imageUrls';
/*
  Try to get the best URL for a particular image. The image of the preferred size is returned, if available. If the
  preferred size is not available, the "original" size is returned. If the original size is not available, a default URL
  is returned.
 */
export const getImageUrl = (image, preferredSize, defaultUrl) => {
  if (image && image.url) {
    if (image.url[preferredSize]) {
      return image.url[preferredSize];
    }
    if (image.url.original) {
      return image.url.original;
    }
  }
  return defaultUrl;
};

export const getImgByGender = (gender) => {
  switch (gender) {
  case 'male':
    return PERSON_MALE;
  case 'female':
    return PERSON_FEMALE;
  default:
    return PERSON;
  }
};

export const getAnonymousImage = () => {
  return PERSON_ANONYMOUS;
};
