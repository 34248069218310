import {requiredFieldValidation} from '../common/form/redux-form/validations';

const validate = (values) => {
  const errors = {};

  errors.order_autoprice_allow = requiredFieldValidation(values.order_autoprice_allow);
  errors.order_autoprice_limit_to_pricing_groups = requiredFieldValidation(values.order_autoprice_limit_to_pricing_groups);
  errors.order_autoprice_limit_pricing_weights = requiredFieldValidation(values.order_autoprice_limit_pricing_weights);

  return errors;
};

export default validate;

