import React from 'react';
import { connect } from 'react-redux';

import Patient from './Patient';

export const ReduxPatient = (props) => {
  return (<Patient {...props} />);
};

function mapStateToProps(state, ownProps){
  const {customer, customerStat, customerRecentHistory, user, customerGroups} = state;
  return {
    customer, customerStat, customerRecentHistory, user, customerGroups
  };
}

export default  connect(mapStateToProps)(ReduxPatient);
