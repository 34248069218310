import get from 'lodash.get';
import {createSelector} from 'reselect';
import {getCustomer, getFacilitySalesSettingsForCustomer} from '../../customersSelectors';
import {getCustomerLimits} from '../../customerLimitSelectors';
import {getOrder} from '../../orderSelectors';
import {
  getOhMetrcCategories,
  getOhMetrcMappingByCategoryCode
} from '../../integration/metrcSelectors';


export const getDisplayRulesForMetrcOhio = createSelector(
  [getFacilitySalesSettingsForCustomer, getCustomerLimits, getOrder, getOhMetrcCategories, getOhMetrcMappingByCategoryCode('concentrate'), getCustomer],
  (compliance, limits, order, ohMetrcCategories, ohMetrcConcentrateMapping, customer) => {

    if (!limits.limits) return {};

    const displayRules = getLimitRuleSetForMetrcOh(ohMetrcCategories, compliance);

    // Set limits from server check limits response:
    const previousOrdersData = get(limits, 'limits.total_oarrs_limits.by_category.ordered', {});
    mapWeightsToDisplayRulePropertyForOhMetrc(displayRules, previousOrdersData, 'previous_orders');

    // Set limits for current order, if it exists:
    if (order && order.consumer_id === customer.id) {
      const inOrderData = get(order, 'limit_check_response.total_oarrs_limits.by_category.in_order', {});
      mapWeightsToDisplayRulePropertyForOhMetrc(displayRules, inOrderData, 'order');

      // set the flag "awaiting inventory specification" for concentrates
      // if there are concentrates in order but without inventory specified
      const concentrateProducts = getConcentrateProductsFromOrder(order, ohMetrcConcentrateMapping);
      if (displayRules['concentrate'] && concentrateProducts && concentrateProducts.length > 0 && displayRules['concentrate'].order == 0) {
        displayRules['concentrate'].awaiting_inventory = true;
      }
    }

    return displayRules;
  });


export const getLimitRuleSetForMetrcOh = (ohMetrcCategories, compliance) => {
  const res = {};
  const categoryRules = get(compliance, 'order_sales_limit_equivalency_by_oh_metrc_category.value', []);
  if (!categoryRules || !Array.isArray(categoryRules)) return res;
  categoryRules.forEach(rule => {
    const key = `${rule.category_code}`;
    const ohMetrcCategory = ohMetrcCategories.find((field) => field.code === rule.category_code);
    res[key] = {
      name: get(ohMetrcCategory, 'name', rule.category_code),
      code: rule.category_code,
      rule: `${rule.flower_weight_value} GR = ${rule.weight_value} ${rule.uom}`,
      order: 0,
      previous_orders: 0,
      awaiting_inventory: false,
    };
  });
  return res;
};


const mapWeightsToDisplayRulePropertyForOhMetrc = (displayRules, summaryByCategory, field) => {
  Object.keys(displayRules).forEach(key => {
    const rule = displayRules[key];
    if (summaryByCategory[rule.code]) {
      rule[field] += summaryByCategory[rule.code];
    }
  });
  return displayRules;
};


const getConcentrateProductsFromOrder = (order, mapping) => {
  if (Array.isArray(order.products)) {
    return order.products.filter(product => {
      return mapping.some(mappingRow => mappingRow.subcategory_id === product.subcategory_id);
    });
  }
  return [];
};
