import React from 'react';
import {I18n} from 'react-redux-i18n';
import {convertFromBase} from '../../../util/uomHelpers';
import SingleActionColumn from '../../common/grid/columns/SingleActionColumn';
import InternationalDateStatic from '../../common/form/InternationalDateStatic';

function getColumns (component) {
  return [
    {
      name: I18n.t('common.form.trackingId'),
      dataId: 'state_integration_tracking_id',
      hidden: false,
      dataSort: false
    },
    {
      name: I18n.t('finishedProduct.form.packageId'),
      dataId: 'package_code',
      hidden: false,
      dataSort: false
    },
    {
      name: I18n.t('closedLoop.admin.productionRun'),
      dataId: 'lot_number',
      hidden: false,
      dataSort: true
    },
    {
      name: I18n.t('closedLoop.admin.product'),
      dataId: 'name',
      hidden: false,
      dataSort: false
    },
    {
      name: I18n.t('common.form.quantity'),
      dataId: 'qty_base',
      hidden: false,
      dataSort: false,
      formatter: (_, row) => convertFromBase(row.qty_base, row.default_uom)
    },
    {
      name: I18n.t('common.form.uom'),
      dataId: 'default_uom',
      hidden: false,
      dataSort: false
    },
    {
      name: I18n.t('common.form.dateCreated'),
      dataId: 'created_at',
      hidden: false,
      dataSort: false
    },
    {
      name: I18n.t('common.form.facilityName'),
      dataId: 'facility_name',
      hidden: false,
      dataSort: false
    },
    {
      name: I18n.t('common.form.facilityNumber'),
      dataId: 'state_license_number',
      hidden: false,
      dataSort: false
    },
    {
      name: I18n.t('closedLoop.admin.testResults'),
      hidden: false,
      dataSort: false,
      formatter: SingleActionColumn({
        label: 'closedLoop.admin.showTestResults',
        style: 'default',
        action: (event, p) => component.fetchLabResultsByPackage(p.id)
      })
    }
  ];
}

export function getTabs (component) {
  return [
    {
      id: 'onHold',
      eventKey: 'onHold',
      title: 'onHold',
      columns: getColumns(component),
      actions: [
        {
          id: 'release',
          text: 'releaseQuarantine',
          requireSelect: true,
          variant: 'danger',
          func: () => component.showReleaseQuarantineConfirmation()
        }
      ]
    }
  ];
}

export function getLabResultColumns (component) {
  return [
    {
      name: I18n.t('common.form.status'),
      dataId: 'status'
    },
    {
      name: I18n.t('common.form.facilityName'),
      dataId: 'facility_name'
    },
    {
      name: I18n.t('closedLoop.admin.product'),
      dataId: 'name'
    },
    {
      name: I18n.t('finishedProduct.form.packageId'),
      dataId: 'package_code'
    },
    {
      name: I18n.t('testResults.form.testingId'),
      dataId: 'testing_id'
    },
    {
      name: I18n.t('cultivation.testResults.form.testingDate'),
      dataId: 'testing_date',
      formatter: cell => {
        return <InternationalDateStatic adjustForClientTimezone={true}>{cell}</InternationalDateStatic>;
      }
    },
    {
      name: I18n.t('cultivation.testResults.goToResults'),
      dataId: 'id',
      hidden: false,
      dataSort: true,
      formatter: SingleActionColumn({
        label: 'cultivation.testResults.show',
        style: 'default',
        action: (event, labResult) => component.props.actions.push(`/closed-loop/test-results/view/${labResult.id}`)
      })
    }
  ];
}
