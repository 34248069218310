import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Row, Col, Table} from 'react-bootstrap';
import DatePickerInput from '../../common/form/DatePickerInput';
import TextInput from '../../common/form/TextInput';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import * as p from '../../../constants/permissions';
import PermissionButton from '../../common/PermissionButton';

const ContainerSummary = (props) => {
  const {storageLocations, totalData} = props;
  return (
    <div>
      <div className='container-summary-table'>
        <Table>
          <thead>
          <tr>
            <th>{I18n.t('containers.form.item')}</th>
            <th>{I18n.t('containers.form.unitSize')}</th>
            <th>{I18n.t('containers.form.quantity')}</th>
            <th>{I18n.t('containers.form.uom')}</th>
            <th>{I18n.t('containers.form.weight')}</th>
            <th>{I18n.t('containers.form.uom')}</th>
          </tr>
          </thead>
          <tbody className='container-items lots'>
          {totalData.containerItems.map((item, itemIndex) => {
            return (
              <tr key={itemIndex}>
                <td className='text-left'>{item.item_name}</td>
                <td>{item.unit_size}</td>
                <td>{item.quantity || ''}</td>
                <td>{item.uom_display}</td>
                <td>{item.weight || ''}</td>
                <td>GR</td>
              </tr>
            );
          })}
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <td className='text-right'>
                <strong>{I18n.t('containers.form.totalPackages')}:</strong>
              </td>
              <td className='text-center'>{totalData.totalPackages || ''}</td>
              <td className='text-right'>
                <strong>{I18n.t('containers.form.totalContainerWeight')}:</strong>
              </td>
              <td>{totalData.totalWeight || ''}</td>
              <td></td>
            </tr>
          </tfoot>
        </Table>
      </div>
      <Row>
        <Col md={3}>
          <div>
            <Field
              name='container_code'
              component={TextInput}
              props={{
                readOnly: true,
                label: I18n.t('containers.form.containerId')
              }}
            />
          </div>
          <div className='print-wrapper'>
            <PermissionButton permissions={[p.print_labels]}
              props={{variant: 'info'}}>
              {I18n.t('common.form.print')}
            </PermissionButton>
          </div>
        </Col>
        <Col md={3} style={{display:'none'}}>
          <div>
            <Field
              name='trackingId'
              component={TextInput}
              props={{
                label: I18n.t('containers.form.assignTrackingId')
              }}
            />
          </div>
          <div className='print-wrapper'>
            <PermissionButton permissions={[p.print_labels]}
              props={{variant: 'info'}}>
              {I18n.t('common.form.print')}
            </PermissionButton>
          </div>
        </Col>
        <Col md={3}>
          <Field
            name='inventory_location_id' component={ReactSelectInput}
            props={{
              label: I18n.t('containers.form.storageLocation'),
              options: storageLocations,
              placeholder: I18n.t('common.form.selectPlaceholder')
            }} />
        </Col>
        <Col md={3}>
          <Field
            name='creationDate'
            component={DatePickerInput}
            props={{
              label: I18n.t('containers.form.creationDate')
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

ContainerSummary.propTypes = {
  totalData: PropTypes.object.isRequired,
  storageLocations: PropTypes.array.isRequired
};

export default ContainerSummary;
