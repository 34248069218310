/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Button, Col, Row} from 'react-bootstrap';
import {Field, FormSection} from 'redux-form';

import ReactSelectInput from '../../../common/form/ReactSelectInput';

const LabelSettingsForm = ({handleSubmit, valid, submitting, pristine, formFields, change}) => {

  const getFields = (section, side) => {

    const isValid = (index) => {
      return (side === 'left')
        ? index < Math.ceil(section.fields.length / 2)
        : index >= Math.ceil(section.fields.length / 2);
    };

    const internationalizeOptions = (options) => {
      return options.map( (option) => {
        if(options.length < 3) {
          option.text = I18n.t(option.text);
        } else {
          option.text = I18n.t(option.text, {size: option.value});
        }

        return option;
      });
    };

    return section.fields.map( (field, index) => {
      const inputWidth = (field.options.length > 2) ? '150px' : '100px';
      return !isValid(index)
        ? null
        : (
        <Field
          key={index}
          style={{width: inputWidth}}
          name={field.name}
          clearable={false}
          component={ReactSelectInput}
          props={{
            label: I18n.t(field.text),
            options: internationalizeOptions(field.options)
          }}
        />
      );
    });
  };

  return (
    <form onSubmit={handleSubmit} className='label-settings form-horizontal'>
      <Row>
        <Col xs={12}>
          {
            formFields.map( (section, index) => {
              return (
                <div key={index}>
                  <FormSection name={section.type}>
                    <Row>
                      <Col xs={12}>
                        <h3>{I18n.t(section.name)}</h3>
                      </Col>
                    </Row>
                    <Col xs={6}>
                      {getFields(section, 'left')}
                    </Col>
                    <Col xs={6}>
                      {getFields(section, 'right')}
                    </Col>
                  </FormSection>
                </div>
              );
            })
          }
        </Col>
        <Col xs={12}>
          <div className='float-right'>
          <Button
            variant='primary'
            type='submit'
            style={{marginRight: '5px'}}
            onClick={(event) => {
              change('saveAndStay', true);
            }}
            disabled={submitting || !valid}
          >
            {I18n.t('common.form.saveAndStay')}
          </Button>

          <Button
            variant='primary'
            type='submit'
            disabled={submitting || !valid}
          >
            {I18n.t('common.form.saveAndExit')}
          </Button>
          </div>
        </Col>
      </Row>
    </form>
  );


};

LabelSettingsForm.propTypes = {
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  formFields: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
};

export default LabelSettingsForm;
