import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from 'react-bootstrap';
import {FaDollarSign} from 'react-icons/fa';
import get from 'lodash.get';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import TextInput from '../../../common/form/TextInput';
import CurrencyInput from '../../../common/form/CurrencyInput';
import SelectInput from '../../../common/form/SelectInput';
import InternationalDateTimePickerInput from '../../../common/form/InternationalDateTimePickerInput';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import TestingSection from './TestingSection';

const InventoryReceiptPackage = (props) => {

  const {locked, change, fieldName, itemMaster, testResult, testResultDimensions, getFormValue, updateTotalInvoiceAmount, strainOptions, storageLocations, barcodeSource, isCreateMode} = props;

  // Check if package is not Accepted
  const notReceived = getFormValue(`${fieldName}.inventory.0.state_integration_package_status`) !== 'Accepted';

  const strain = getFormValue(`${fieldName}.inventory.0.source_strain`) ? getFormValue(`${fieldName}.inventory.0.source_strain`) : get(itemMaster, 'strain.strain_name');

  const barcodeSourceValue = barcodeSource === 'global_id' ? 'Will be generated by platform' : getFormValue(`${fieldName}.inventory.0.${barcodeSource}`);

  return (
    <div style={{margin: '15px 15px 30px 15px', padding: '25px'}}>
      <Row>
        <Col xs={2} className={notReceived ? 'receipt-package-tab package-error' : 'receipt-package-tab package-success'}>
          <div>{I18n.t('inventoryReceipt.form.metrcStatus')}: {getFormValue(`${fieldName}.inventory.0.state_integration_package_status`)}</div>
        </Col>
        <Col xs={10} className={'receipt-package-tab-spacer'}>
          &nbsp;
        </Col>
      </Row>
      <Row>
        <Col xs={4} className={notReceived ? 'receipt-package-border-left package-error' : 'receipt-package-border-left package-success'}>
          <Field
            name={`${fieldName}.item_master_name`}
            style={{paddingTop: '6px'}}
            component={TextInput}
            props={{
              label: I18n.t('inventoryReceipt.form.itemMasterName'),
              disabled: true,
              value: get(itemMaster, 'name')
            }}
          />
        </Col>
        <Col xs={4} className={notReceived ? 'package-error' : 'package-success'}>
          <Field
            name={`${fieldName}.inventory.0.state_integration_tracking_id`}
            style={{paddingTop: '6px'}}
            label={I18n.t('inventoryReceipt.form.metrcId')}
            component={TextInput}
            disabled={true}
          />
        </Col>
        <Col xs={4} className={notReceived ? 'receipt-package-border-right package-error' : 'receipt-package-border-right package-success'}>
          <Field
            name={`${fieldName}.inventory.0.state_integration_package_name`}
            style={{paddingTop: '6px'}}
            label={I18n.t('inventoryReceipt.form.metrcItemName')}
            component={TextInput}
            disabled={true}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={4} className={'receipt-package-border-left'}>
          <Field
            name={`${fieldName}.qty`}
            label={I18n.t('inventoryReceipt.form.quantity')}
            component={TextInput}
            disabled={true}
            rightAddon={<span>EA</span>}
          />
        </Col>
        <Col xs={4}>
          <Field
            name={`${fieldName}.cost_per_unit`}
            label={I18n.t('inventoryReceipt.form.costPerUnit')}
            component={CurrencyInput}
            disabled={true}
            leftAddon={<FaDollarSign/>}
          />
        </Col>
        <Col xs={4} className={'receipt-package-border-right'}>
          <Field
            name={`${fieldName}.line_item_price_edit`}
            label={I18n.t('inventoryReceipt.form.totalPackageCost')}
            component={CurrencyInput}
            value={getFormValue(`${fieldName}.line_item_price_edit`) || getFormValue(`${fieldName}.line_item_price`)}
            onChange={(e, value) => {
              updateTotalInvoiceAmount(`${fieldName}.line_item_price`, value);
            }}
            leftAddon={<FaDollarSign/>}
            disabled={locked}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={3} className={'receipt-package-border-left'}>
          <Field
            name={`${fieldName}.strain`}
            component={SelectInput}
            props={{
              label: I18n.t('products.form.strainName'),
              placeholder: I18n.t('common.form.strainPlaceholder'),
              options: [{strain_name: ''}].concat(strainOptions),
              textKey: 'strain_name',
              valueKey: 'strain_name',
              isRequired: true,
              disabled: locked || get(itemMaster, 'strain_id') !== 0, // Editable if ItemMaster strain is multiple
              onChange: (e) => {
                const strain = strainOptions.find((strainOption) => strainOption.strain_name === e.target.value);
                change(`${fieldName}.strain`, strain);
              },
              value: strain
            }}
          />
        </Col>
        <Col xs={3}>
          <Field
            name={`${fieldName}.lot_number`}
            component={TextInput}
            props={{
              label: I18n.t('inventoryReceipt.form.lotNumber'),
              disabled: !isCreateMode,
            }}
          />
        </Col>
        <Col xs={3}>
          <InternationalDateTimePickerInput
            name={`${fieldName}.date_item_expired`}
            props={{
              label: I18n.t('cultivation.transfers.form.expirationDate'),
              inputProps: {disabled: locked},
              enableTodayButton: false,
            }}/>
        </Col>
        <Col xs={3} className={'receipt-package-border-right'}>
          <InternationalDateTimePickerInput
            name={`${fieldName}.date_item_use_by`}
            props={{
              label: I18n.t('cultivation.transfers.form.useByDate'),
              inputProps: {disabled: locked},
              enableTodayButton: false,
            }}/>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className={'receipt-package-border-left receipt-package-border-right'}>
          <TestingSection
            itemMaster={itemMaster}
            testResult={testResult}
            testResultDimensions={testResultDimensions}
            isCreateMode={isCreateMode}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6} style={{paddingTop: '12px'}} className={'receipt-package-border-left receipt-package-border-bottom'}>
          <Field name={`${fieldName}.inventory.0.storage_location_id`}
            component={ReactSelectInput}
            props={{
              label: I18n.t('cultivation.transfers.form.storageLocation'),
              options: storageLocations,
              isRequired: true,
              disabled: locked,
            }}/>
        </Col>
        <Col xs={6} style={{paddingTop: '12px'}} className={'receipt-package-border-right receipt-package-border-bottom'}>
          <Field name={`${fieldName}.inventory.0.barcode`}
            component={TextInput}
            props={{
              label: I18n.t('cultivation.transfers.form.barcode'),
              value: barcodeSourceValue,
              isRequired: true,
              disabled: true
            }}/>
        </Col>
      </Row>
    </div>
  );
};

InventoryReceiptPackage.propTypes = {
  fieldName: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  itemMaster: PropTypes.object.isRequired,
  testResult: PropTypes.object,
  testResultDimensions: PropTypes.object.isRequired,
  getFormValue: PropTypes.func.isRequired,
  updateTotalInvoiceAmount: PropTypes.func.isRequired,
  strainOptions: PropTypes.array.isRequired,
  storageLocations: PropTypes.array.isRequired,
  barcodeSource: PropTypes.string.isRequired,
  locked: PropTypes.bool.isRequired,
  isCreateMode: PropTypes.bool.isRequired
};

export default InventoryReceiptPackage;
