
export const paymentsAvailable = [
  {text: 'Credit Card', value: 'credit', isDefault: true, i18n: 'cart.payment.credit'},
  {text: 'Check', value: 'check', isDefault: true, i18n: 'cart.payment.check'},
  {text: 'Cash', value: 'cash', isDefault: true, i18n: 'cart.payment.cash'},
  {text: 'Debit Card', value: 'debit', isDefault: true, i18n: 'cart.payment.debit'},
  {text: 'Gift Card', value: 'gift', isDefault: true, i18n: 'cart.payment.giftCard'},
  {text: 'Alleaves', value: 'alleaves', isDefault: false, i18n: 'cart.payment.alleaves', isCustom: true, setByThirdPartyIntegration: true},
  {text: 'POSaBIT', value: 'posabit', isDefault: false, i18n: 'cart.payment.posabit', isCustom: true, setByThirdPartyIntegration: true},
  {text: 'Hypur', value: 'hypur', isDefault: false, i18n: 'cart.payment.hypur', isCustom: true, setByThirdPartyIntegration: true},
  {text: 'AeroPay', value: 'aeropay', isDefault: false, i18n: 'cart.payment.aeroPay', isCustom: true},
  {text: 'AeroPayIntegrated', value: 'aeropayintegrated', isDefault: false, i18n: 'cart.payment.aeropayIntegrated', isCustom: true, setByThirdPartyIntegration: true},
  {text: 'Priority', value: 'terminal', isDefault: false, i18n: 'cart.payment.priority', isCustom: true, setByThirdPartyIntegration: true},
  {text: 'Other', value: 'other', isDefault: false, i18n: 'cart.payment.other', isCustom: true},
];

export const customPaymentMethods = paymentsAvailable.filter(paymentAvailable => paymentAvailable.isCustom).map(paymentAvailable => paymentAvailable.value);

export const eqFields = [
  {text: 'Fresh Marijuana', category_code: 'MARIJUANA', defaultValue: 1, category_id: 9, subcategory_id: 0},
  {text: 'Concentrate', category_code: 'CONCENTRATE', defaultValue: 1, category_id: 2, subcategory_id: 0},
  {text: 'Infused Edible', category_code: 'EDIBLE', defaultValue: 1, category_id: 4, subcategory_id: 0},
  {text: 'Infused Non Edible', category_code: 'INFUSED', defaultValue: 1, category_id: 5, subcategory_id: 0},
  {text: 'Cartridges / Pens', category_code: 'CARTPENS', defaultValue: 1, category_id: 3, subcategory_id: 0},
  {text: 'Plants: Clones', category_code: 'CLONE', defaultValue: 1, category_id: 7, subcategory_id: 39},
  {text: 'Plants: Seed', category_code: 'SEED', defaultValue: 1, category_id: 7, subcategory_id: 38},
];

export const metrcOhEqFields = [
  {text: 'Plant Material Tier I', category_code: 'plant_material_tier_1', defaultValue: 1},
  {text: 'Plant Material Tier II', category_code: 'plant_material_tier_2', defaultValue: 1},
  {text: 'Concentrate', category_code: 'concentrate', defaultValue: 1},
  {text: 'Edible', category_code: 'edible', defaultValue: 1},
  {text: 'Topical', category_code: 'topical', defaultValue: 1},
];

export const eqUoms = [
  {text: 'Milligrams', value: 'MG', isDefault: false, type: 'weight'},
  {text: 'Grams', value: 'GR', isDefault: true, type: 'weight'},
  {text: 'Each', value: 'EA', isDefault: false, type: 'discrete'},
];

export const restrictSalesTimePeriods = [
  {text: 'Visit', value: 'visit'},
  {text: 'Day', value: 'day'},
  {text: 'Week', value: 'week'},
  {text: 'Calendar Week', value: 'calendar_week'},
  {text: 'Month', value: 'month'},
  {text: 'Calendar Month', value: 'calendar_month'}
];

export const limitCategories = [
  {value: 'USABLE_MMJ', text: 'Usable Marijuana'},
  {value: 'SOLID_CANNABINOID', text: 'Solid Cannabinoid Product'},
  {value: 'LIQUID_CANNABINOID', text: 'Liquid Cannabinoid Product'},
  {value: 'CONCENTRATE', text: 'Cannabinoid Extract-Concentrate'},
  {value: 'PLANTS', text: 'Immature Plants'},
  {value: 'SEEDS', text: 'Seeds'},
];

export const fieldDefaults = {
  order_auto_price_allow: 1,
  order_autoprice_limit_to_pricing_groups: 0,
  order_autoprice_limit_pricing_weights: 0,
  order_sales_limit_equivalency: eqFields,
  order_restrict_sales: {
    interval: 1,
    time_period: 'visit',
    weight_value: 28
  },
  order_allow_sales_with_temp_paperwork: 0,
  order_physician_info: 0,
  order_delivery_of_product: 1,
  order_upload_of_docs: 0,
  order_select_payment_options: paymentsAvailable,
  order_auto_inactivation: 0,
  order_buffer: {
    interval: 30,
    time_period: 'days'
  },
  order_print_labels_and_receipts_without_payment: 0,
  order_receipt_footer_text: '',
  order_disable_limits_on_external_system_error: 0,
};

export default paymentsAvailable;
