import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import map from 'lodash.map';

import {unsetItemAction} from '../../actions/itemActions';
import * as apiActions from '../../actions/apiActions';
import * as itemNames from '../../constants/itemNames';
import {getModifyAutoPriceInitialValues} from '../../selectors/orderSettingsSelectors';
import AutoPriceFormWrapper from './AutoPriceFormWrapper';

export class AutoPricePage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {ready: false};
  }

  componentWillMount() {
    this.props.actions.unsetItemAction(itemNames.orderSettings);
    this.props.actions.getItem('/api/order_settings', itemNames.orderSettings)
      .then(() => this.setState({ready: true}));
  }

  onSubmit(formData) {
    const payload = {
      settings: map(formData, (value, setting_key) => ({
        setting_key,
        value: value === '1',
        scope: 'organization'
      }))
    };

    this.props.actions.postItem(
      '/api/order_settings/autoprice',
      payload,
      null,
      {success: 'retail.autoPrice.success', failed: 'retail.autoPrice.failed'},
      {},
      this.props.actions.goBack
    );
  }

  render() {
    const {ready} = this.state;
    return (
      <div className='auto-price-page'>
        <h1>{I18n.t('retail.autoPrice.title')}</h1>
        <AutoPriceFormWrapper
          initialValues={ready ? this.props.initialValues : undefined}
          onSubmit={this.onSubmit}/>
      </div>
    );
  }
}

AutoPricePage.propTypes = {
  actions: PropTypes.shape({
    getItem: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    unsetItemAction: PropTypes.func.isRequired,
  }).isRequired,
  initialValues: PropTypes.object
};

function mapStateToProps(state) {
  return {
    initialValues: getModifyAutoPriceInitialValues(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({...apiActions, goBack, unsetItemAction}, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoPricePage);
