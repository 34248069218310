import get from 'lodash.get';
import keyBy from 'lodash.keyby';
import {createSelector} from 'reselect';
import * as dataNames from '../constants/dataNames';
import {hasStateIntegrator, isWaLeaf} from './integrationSelectors';


export const getFeatureToggles = state => state[dataNames.featureToggles];


/**
 * Get all feature toggles indexed by their names
 *
 * return array
 */
export const getFeatureTogglesByName = createSelector(getFeatureToggles, featureToggles => {
  return keyBy(featureToggles, toggle => toggle.name);
});

const isFeatureEnabledViaUrl = (featureName) => {
  const urlString = window.location.href;
  const url = new URL(urlString);
  const value = url.searchParams.get(featureName);
  return parseInt(value) === 1;
};

export const getFeatureEnabledList = createSelector(
  [getFeatureTogglesByName],
  (featureToggles) => (findEnabled) => {
    const featureToggleKeys = Object.keys(featureToggles);
    return featureToggleKeys.filter((key_name) => {
      return get(featureToggles, `${key_name}.is_enabled`, false) === findEnabled;
    });
  }
);
// eslint-disable-next-line
export const isFeatureEnabled = createSelector([getFeatureTogglesByName], (featureToggles) => (name) => {
  const enabledViaUrl = isFeatureEnabledViaUrl(name);
  const enabledViaDb = get(featureToggles, `${name}.is_enabled`, false);
  return enabledViaUrl || enabledViaDb;
});

export const isAddPlantsToExistingBatchFeatureEnabled = createSelector(
  [isWaLeaf, hasStateIntegrator, isFeatureEnabled],
  (isWaLeaf, hasStateIntegrator, isFeatureEnabled) => {
    return () => isWaLeaf ||
      (!hasStateIntegrator && isFeatureEnabled('feature_add_plants_to_existing_plant_batch'));
  }
);

export const isSalesSummaryReportFeatureEnabled = createSelector(
  [isFeatureEnabled],
  (isFeatureEnabled) => isFeatureEnabled('feature_sales_summary_report')
);

export const isShowBiPlatformReportsFeatureEnabled = createSelector(
  [isFeatureEnabled],
  (isFeatureEnabled) => isFeatureEnabled('feature_show_bi_platform_reports')
);

export const isRegulationConsoleUserApprovalFeatureEnabled = createSelector(
  [isFeatureEnabled],
  (isFeatureEnabled) => isFeatureEnabled('feature_regulation_console_user_approval')
);

export const isRegulationConsoleHempTransferFeatureEnabled = createSelector(
  [isFeatureEnabled],
  (isFeatureEnabled) => isFeatureEnabled('feature_pa_hemp_transfer')
);

export const isLeafPaDisallowRetailTransferOfPlantSubtypesEnabled = state =>
  isFeatureEnabled(state)('feature_leaf_pa_disallow_retail_transfer_of_plant_subtypes');

export const isLeafPaItemMasterRequireNetWeightEnabled = state =>
  isFeatureEnabled(state)('feature_leaf_pa_item_master_require_medicated_weight');

export const isNewPackagingJobsFeatureEnabled = state =>
  isFeatureEnabled(state)('feature_new_packaging_jobs');
