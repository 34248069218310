import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {getFormValues, getFormSyncErrors, reduxForm} from 'redux-form';
import {push} from 'react-router-redux';
import ProductManagementForm from './ProductManagementForm';
import {getIntegrationState} from '../../../selectors/integration/integrationSelectors';
import * as productsSelectors from '../../../selectors/productsSelectors';
import * as metrcSelectors from '../../../selectors/integration/metrcSelectors';
import {PRODUCT_MANAGEMENT_FORM} from '../../../constants/forms';
import {saveProductManagmentPapping} from '../../../actions/integrations/metrc';
import {validate} from './validate';

const ProductsFormWrapper = reduxForm({
  enableReinitialize: true,
  validate,
})(ProductManagementForm);

/**
 *
 * @param state
 * @returns {{initialValues: {productsMapping: any}, formValues: {productsMapping: any}, setProduct: shim, integratorProducts: *, integrationState: *, mjpProducts: *[], metrcCategoryMappings: *}}
 */
function mapStateToProps(state) {
  const integrationState = getIntegrationState(state);
  const initialValues = {productsMapping: metrcSelectors.getMetrcProductsMappingInitalValues(state)};
  const form = PRODUCT_MANAGEMENT_FORM;
  const mjpProducts = [
    ...productsSelectors.getProducts(state),
    {id: 'link', template: () => (<a>{I18n.t('METRCProductManagement.form.AddNewProduct')}</a>)},
  ];

  return {
    mjpProducts: mjpProducts,
    integratorProducts: metrcSelectors.getMetrcProducts(state),
    metrcCategoryMappings:metrcSelectors.getMetrcCategoryMappings(state),
    integrationState,
    initialValues,
    setProduct: PropTypes.func,
    formValues: getFormValues(form)(state) || initialValues,
    formErrors: getFormSyncErrors(form)(state)
  };
}

/**
 *
 * @param dispatch
 * @returns {{onSubmit(*): *, onCancel(): void}|*}
 */
function mapDispatchToProps(dispatch) {
  return {
    onSubmit(formValues) {
      const productsMapping = formValues.productsMapping.filter(v => (v.product_id != null || v.item_id != null));
      const payload = productsMapping ? {productsMapping: productsMapping} : {};
      return dispatch(saveProductManagmentPapping(payload));
    },
    onCancel() {
      dispatch(push('/setup'));
    }
  };
}

ProductsFormWrapper.propTypes = {
  formValues: PropTypes.object,
  change: PropTypes.func,
  touch: PropTypes.func,
  formErrors: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsFormWrapper);
