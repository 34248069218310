import React from 'react';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import PropTypes from 'prop-types';
import InternationalCurrencyInput from '../../common/form/InternationalCurrencyInput';

const PricingMultiplierPrice = ({name, showHeader, quantity, taxRate, removeButton, disabled, value, change}) => {

  const calculatePostTaxValue = (value) => {
    const convertedValue = value && taxRate ? Number((value * (1 + taxRate)).toFixed(2)) : '';
    change(`${name}_postTax`, convertedValue);
  };

  const preTaxProps = {
    fractionPartSize: 2,
    allowNegativeNumber: false,
    disabled,
    onChange: (value) => {
      calculatePostTaxValue(value);
      change(name, value);
    }
  };

  if (value) {
    preTaxProps.value = value;
  }

  const postTaxProps = {
    fractionPartSize: 2,
    disabled: true
  };

  if (taxRate && value) {
    postTaxProps.value = value + value * (taxRate / 100);
  }

  return (
    <React.Fragment>
      {showHeader &&
        <Row>
          <Col sm={3}/>
          <Col sm={4}>{I18n.t('retail.pricingMultiplier.form.preTax')}</Col>
          <Col sm={4}>{I18n.t('retail.pricingMultiplier.form.postTax')}</Col>
          <Col sm={1}/>
        </Row>
      }
      <Row >
        <Col sm={1}/>
        <Col sm={2} style={{whiteSpace: 'nowrap'}}>
          {quantity} {I18n.t('uoms.EA.abbrev')}
        </Col>
        <Col sm={4}>
          <InternationalCurrencyInput
            name={name}
            props={preTaxProps}
          />
        </Col>
        <Col sm={4}>
          <InternationalCurrencyInput
            name={`${name}_postTax`}
            props={postTaxProps}
          />
        </Col>
        <Col sm={1}>
          {removeButton}
        </Col>
      </Row>
    </React.Fragment>
  );
};

PricingMultiplierPrice.propTypes = {
  showHeader: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  change: PropTypes.func.isRequired,
  taxRate: PropTypes.number,
  disabled: PropTypes.bool,
  value: PropTypes.number,
  removeButton: PropTypes.object,
};

export default PricingMultiplierPrice;
