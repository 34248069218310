import unionBy from 'lodash.unionby';
import filter from 'lodash.filter';
import find from 'lodash.find';
import initialState from '../../constants/initialState';
import * as types from '../../constants/actionTypes';

/** Generates standard reducers for data that only uses standard
CRUD functionality*/

export default function dataReducerFactory(dataName = '') {
  return function dataReducer(state = initialState[dataName], action) {
    const {name, payload, keyField} = action;
    //data has to be an array
    const data = payload && payload.data ? [].concat(payload.data) : [];
    if(name !== dataName) {
      return state;
    }
    switch (action.type) {
    case types.GET_DATA_SUCCESS:
      return data;
    case types.ADD_DATA_SUCCESS:
      return [...state, ...data];
    case types.EDIT_DATA:
    case types.EDIT_DATA_SUCCESS:
    case types.GET_DATA_BATCH_SUCCESS:
      return unionBy(data, state, keyField || 'id');
    case types.DELETE_DATA_SUCCESS:
      return state.filter(item => data.indexOf(item.id) < 0);
    case types.SET_DATA:
      return payload;
    case types.UNION_DATA:
      return unionBy(payload, state, keyField || 'id');
    case types.PUSH_DATA:
      return [...state, ...payload];
    case types.DELETE_DATA:
      return filter(state, (item) => {
        return !find(payload, keyField && item[keyField] ? {[keyField]: item[keyField]} : item);
      });
    case types.UNSET_DATA:
      return initialState[dataName];
    case types.FILTER_DATA_BATCH_BY_KEY:
      return Array.isArray(payload)
        ? state.filter(item => !payload.includes(item[keyField]))
        : state;
    case types.DELETE_DATA_FAILED:
    case types.GET_DATA_FAILED:
    case types.ADD_DATA_FAILED:
    case types.EDIT_DATA_FAILED:
    case types.GET_DATA_BATCH_FAILED:
    default:
      return state;
    }
  };
}
