import {createSelector} from 'reselect';
import uniqBy from 'lodash.uniqby';
import {formatCustomDate} from '../util/dateHelpers';
import {getStrainById, getPhenotypeById} from './strainsSelectors';

const getPlantingDate = (_, props) => props.planting_date;
const getBatches = (state) => state.batches;
const getPlantingBatchId = (_, props) => props.planting_batch_id;

export const getStages = createSelector(getBatches,
  batches => uniqBy(
    batches.map(batch => ({field:'stage_name', value:batch.stage_name})),
    'value'
  )
);

export const getBatchIds = createSelector(getBatches, batches => batches.map(batch => ({value: batch.id, text:batch.batch_name, facility_id:batch.facility_id})));

export const getBatchById = createSelector(
  [getPlantingBatchId, getBatches],
  (batchId, batches) => batches.reduce(
    (previous, current) => {
      return current.id === batchId ? current : previous;
    }, null
  )
);


export const getBatchName = createSelector([getStrainById, getPlantingDate, getPhenotypeById], (strain, planting_date, phenotype) => {
  let batch_prefix = strain ? strain.strain_code : '';
  if(phenotype){
    batch_prefix = phenotype.strain_code ? phenotype.strain_code : batch_prefix ;
  }
  const formatDate = formatCustomDate('YYMMDD');
  const date = planting_date ? formatDate(planting_date) : '';
  if(batch_prefix || date){
    return`${batch_prefix}${date}...`;
  }else{
    return null;
  }
});
