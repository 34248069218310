export const getValidationState = field => {
  if (field.error){
    return field.touched || field.active ? 'error' : undefined;
  } else if (field.warning) {
    return field.warning ? 'warning' : undefined;
  }
  return undefined;

};
export const getValidationError = field => {
  if(field.error){
    if (field.touched || field.active) {
      return field.error ;
    }
  }
};
