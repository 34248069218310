import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import {requiredFieldValidation, minValidation, maxValidation, minDateTimeValidation} from '../../../common/form/redux-form/validations';
import {isInvPackageTrackingNotInCompliance} from '../../../../selectors/complianceSettingsSelectors';

const validate = (values, props) => {

  const isLabResultsFailed = (input) => {
    const lab_results_references = get(input, 'package.lab_results_reference', []);
    if (!lab_results_references.length) {
      return false;  // Treat no lab results as not failed!!!!
    }
    // Sort references so that most recent is first
    const sorted = lab_results_references.sort((lrr1, lrr2) => {
      return lrr1.id < lrr2.id ? 1 : -1;
    });

    const latest_lab_results = sorted[0].lab_results;
    return latest_lab_results.status === 'failed';
  };

  const errors = {
    processing_type_id: requiredFieldValidation(values.processing_type_id, 'ei.processing.form.processingType'),
    equipments: requiredFieldValidation(values.equipments && values.equipments.length > 0, 'ei.processing.form.assignEquipment'),
    employees: requiredFieldValidation(values.employees && values.employees.length > 0, 'ei.processing.form.assignEmployees'),
    start_time: requiredFieldValidation(values.start_time, 'ei.processing.form.startTime'),
    expected_completion_time: requiredFieldValidation(values.start_time, 'ei.processing.form.expectedCompletionTime')
      || minDateTimeValidation(values.expected_completion_time, 'ei.processing.form.expectedCompletionTime', values.start_time),
  };

  errors.inputs = values.inputs.map(input => {
    const inputErrors = {
      item_id: requiredFieldValidation(input.item_id, 'ei.processing.form.productionRun'),
    };
    if (input.item_id) {
      inputErrors.qty = requiredFieldValidation(input.qty, 'ei.processing.form.weight')
        || minValidation(input.qty, 'ei.processing.form.weight', 0, true)
        || (!props.allowNegativeInventory && maxValidation(input.qty, 'ei.processing.form.weight', input.maxQty));
      // Only failed flower can be processed. Any other failed items cannot
      inputErrors.packageCode = props.isPaRemediationLabelsEnabled && input.item_category_code !== 'FLOWER' && isLabResultsFailed(input)
        ? I18n.t('ei.processing.form.failedTestResult')
        : null;
    }
    return inputErrors;
  });

  if ( props.complianceSettings.inv_packages_require_tracking_id) {
    if (values.inputs.some((input) => {
      return isInvPackageTrackingNotInCompliance(input);
    })) {
      errors.inputs = {
        _error: props.complianceSettings.inv_packages_require_tracking_id_message,
      };
    }
  }

  return errors;
};

export default validate;
