import get from 'lodash.get';

/******************************************************************
 *  Validated Outputs Functions
 ******************************************************************/

/**
 * Returns array of platform category/subcategory codes based on the inventory input to a packaging job.
 * @param props
 * @param packagingJob
 * @returns {boolean|*}
 */
export const getValidSubcategoriesByValidOutputs = (props, packagingJob) => {
  const {subcategorySettings, packagingWorkflowValidOutputs} = props;
  if (!packagingWorkflowValidOutputs) {
    return false;
  }
  if (Array.isArray(packagingWorkflowValidOutputs) && !packagingWorkflowValidOutputs.length) {
    return false;
  }
  const subcategory = get(packagingJob, 'item.item_master.subcategory', {});
  const inputJurisdictionCode = getInputJurisdictionCode(subcategorySettings, subcategory);
  const validOutputs = getValidOutputs(packagingWorkflowValidOutputs, inputJurisdictionCode);
  return !validOutputs.length
    ? {error: true, message: 'packaging.complete.noOutputs'}
    : getValidCategoryCodes(subcategorySettings, validOutputs);
};

/**
 * Returns array of objects including category and subcategory code on platform to be used
 * in a filter.
 * @param subcategorySettings
 * @param validOutputs
 * @returns {*[]}
 */
export const getValidCategoryCodes = (subcategorySettings, validOutputs) => {
  return Object.keys(subcategorySettings).reduce((acc, key) => {
    const subcategorySetting = subcategorySettings[key];

    if (validOutputs.indexOf(get(subcategorySetting, 'jurisdiction_category_code')) !== -1) {
      acc.push({
        categoryCode: get(subcategorySetting, 'category_code'),
        subcategoryCode: get(subcategorySetting, 'subcategory_code'),
      });
    }
    return acc;
  }, []);
};

/**
 * Returns a jurisdiction code that matches a given platform category/subcategory code or empty string
 * @param subcategorySettings
 * @param subcategory
 * @returns {*}
 */
export const getInputJurisdictionCode = (subcategorySettings, subcategory) => {
  const inputSubcategoryCode = get(subcategory, 'subcategory_code');
  const inputCategoryCode = get(subcategory, 'category.category_code');
  const key = `${inputCategoryCode}_${inputSubcategoryCode}`;
  return get(subcategorySettings, `${key}.jurisdiction_category_code`, '');
};

/**
 * Returns array of valid outputs based in packagingWorkflowValidOutputs and the input jurisdiction code.
 * @param packagingWorkflowValidOutputs
 * @param inputJurisdictionCode
 * @returns {*}
 */
export const getValidOutputs = (packagingWorkflowValidOutputs, inputJurisdictionCode) => {
  return packagingWorkflowValidOutputs.reduce((acc, output) => {
    if (output.inputs.indexOf(inputJurisdictionCode) !== -1) {
      acc = acc.concat(output.outputs);
    }
    return acc;
  }, []);
};

/******************************************************************
 *  Filter Functions
 ******************************************************************/

/**
 * Returns false if no base filter, strings if a filter is found, and an error object in the case of validatedOutputs
 * that have a failure.  USUALLY this will be a string.
 * @param props
 * @param packagingJob
 * @returns {boolean|string}
 */
export const getFilter = (props, packagingJob) => {
  let metrcFilter;
  const baseFilter = getBaseFilter(packagingJob);

  if ((metrcFilter = getMetrcFilter(props, baseFilter)) !== false) {
    return metrcFilter;
  }

  const validatedOutputsFilter = getValidatedOutputsFilter(props, packagingJob);
  if (validatedOutputsFilter) {
    return validatedOutputsFilter;
  }

  return getDefaultFilter(packagingJob, baseFilter);
};

export const getBaseFilter = (packagingJob) => {
  const strainId = get(packagingJob, 'item.item_master.strain_id', 0);
  return `strain_id:${strainId} AND active:1 AND is_draft:0`;
};

export const getMetrcFilter = (props, baseFilter) => {
  return get(props, 'integrationState.isMetrc', false)
    ? `${baseFilter} AND ((is_prepack:1 AND item_master_parent_id:0) OR (uom_type:"discrete" AND is_prepack:0 AND category_code:("FLOWER", "CONCENTRATE")))`
    : false;
};

export const getValidatedOutputsFilter = (props, packagingJob) => {
  const validSubcategoryData = getValidSubcategoriesByValidOutputs(props, packagingJob);
  if (Array.isArray(validSubcategoryData) && !validSubcategoryData.length) {
    return false;
  }
  if (!Array.isArray(validSubcategoryData)) {
    if (validSubcategoryData.error) {
      return validSubcategoryData;
    }
    return false;
  }

  const uomType = get(packagingJob, 'item.item_master.uom_type', '');
  const categoryAndSubcategoryFilter = validSubcategoryData.reduce((acc, sub) => {
    acc.push(`(category_code:"${sub.categoryCode}" AND subcategory_code:"${sub.subcategoryCode}")`);
    return acc;
  }, []).join(' OR ');
  return `${getBaseFilter(packagingJob)} AND (${categoryAndSubcategoryFilter}) AND is_prepack:1 AND item_master_parent_id:0 AND uom_type:${uomType}`;
};

export const getDefaultFilter = (packagingJob, baseFilter) => {
  const itemMaster = get(packagingJob, 'item.item_master');
  const {subcategory, uom_type} = itemMaster;
  return `${baseFilter} AND category_id:${subcategory.category_id} AND is_prepack:1 AND item_master_parent_id:0 AND uom_type:${uom_type}`;
};

export default getValidSubcategoriesByValidOutputs;
