import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import get from 'lodash.get';
import { Button, ButtonToolbar, Col, Row, Alert } from 'react-bootstrap';
import {FaAsterisk} from 'react-icons/fa';
import InlineCheckBox from '../../common/form/InlineCheckBox';
import InternationalDateTimePickerInput from '../../common/form/InternationalDateTimePickerInput';
import InternationalQuantityByUomInput from '../../common/form/InternationalQuantityByUomInput';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import TextInput from '../../common/form/TextInput';
import TextAreaInput from '../../common/form/TextAreaInput';
import WaFormFields from './WaFormFields';
import PermissionWrapper from '../../common/PermissionWrapper';
import * as p from '../../../constants/permissions';
import {GR} from '../../../constants/uoms';
import InternationalDecimalInput from '../../common/form/InternationalDecimalInput';

const ModifyHarvestBatchForm = (props) => {

  const {
    handleSubmit, handleSave, reset, change, sections, moistureLoss, invalid, pristine, finalWeight, hasFinalWeight, initialValues,
    integrationState, increasedWeight, harvestWasteTypes, harvestBatch: {uom_display}, formValues
  } = props;
  const uom = uom_display || GR; // Without this default, the page errors out if loaded without a selected harvest batch
  const { isLeaf, isMetrc, isWaLeaf, isBiotrack, isPrBiotrack, isPaLeaf } = integrationState;
  const disableFinalWeight = hasFinalWeight && finalWeight && (isLeaf || isMetrc || isBiotrack) && !isPrBiotrack && !isPaLeaf;

  if (isWaLeaf) {
    return (
      <WaFormFields
        reset={reset}
        change={change}
        sections={sections}
        invalid={invalid}
        pristine={pristine}
        integrationState={integrationState}
        finalWeight={finalWeight}
        hasFinalWeight={hasFinalWeight}
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        handleSave={handleSave}
      />
    );
  }

  return (
    <form className='modify-harvest-batch-form'
      onSubmit={handleSubmit((formData) =>
        handleSave(Object.assign({}, formData, moistureLoss ? { calculated_moisture_loss: moistureLoss } : {}))
      )}
    >
      <Row>
        <Col md={{span:8, offset:2}}>
           <Field name='batch_name' component={TextInput} props={{
             label: I18n.t('harvestBatch.modify.name'),
             disabled: true
           }}/>
        </Col>
      </Row>

      <Row>
        <Col md={{span:4, offset:2}} className='text-align-right'>
          {I18n.t('harvestBatch.modify.originalWeight')}
        </Col>
        <Col md='4'>
          <InternationalQuantityByUomInput
            name='wet_weight_harvest'
            placeholder={I18n.t('harvestBatch.modify.originalWeightPlaceholder')}
            disabled={true}
            uom={uom}
          />
        </Col>
      </Row>

      <Row>
        <Col md={{span:4, offset:2}} className='text-align-right'>
          {I18n.t('harvestBatch.modify.currentWeight')}
        </Col>
        <Col md='4'>
          <InternationalQuantityByUomInput
            name='current_weight'
            disabled={true}
            uom={uom}
          />
        </Col>
      </Row>

      {!isBiotrack &&
        <Row>
          <Col md={{span:4, offset:2}} className='text-align-right'>
            {I18n.t('harvestBatch.modify.reportWaste')}
          </Col>
          <Col md='4'>
            <InternationalQuantityByUomInput
              name='reportWaste'
              min={0}
              disabled={hasFinalWeight && finalWeight && !isMetrc}
              uom={uom}
            />
          </Col>
        </Row>
      }

      <Row>
        <Col md={{span:4, offset:2}} className='text-align-right'>
          <FaAsterisk style={{ fontSize: '0.5em', verticalAlign: 'top', marginTop: '1px' }} />
          <span style={{fontWeight: 'bold'}}>{I18n.t('harvestBatch.modify.newWeight')}</span>
        </Col>
        <Col md='4'>
          <InternationalQuantityByUomInput
            name='newWeight'
            min={0}
            disabled={hasFinalWeight && finalWeight}
            uom={uom}
          />
        </Col>
        <Col md='2'>
          <Field
            name='finalWeight'
            component={InlineCheckBox}
            props={{
              label: I18n.t('harvestBatch.modify.finalWeight'),
              disabled: disableFinalWeight
            }}
            onChange={(event) => {
              const value = event.target.checked;
              if (value && hasFinalWeight && !get(formValues, 'newWeight', null)) {
                change('newWeight', initialValues.newWeight);
                change('reportWaste', '0.00');
                change('currentMoisture', '0.00');
              }
              change('finalWeight', value);
            }}
          />
        </Col>
      </Row>

      {isMetrc && increasedWeight &&
        <Row>
          <Col md={{span:8, offset:2}} className='text-align-right'>
             <Alert variant='warning'>{I18n.t('metrc.form.lotChangeWarning')}</Alert>
          </Col>
        </Row>
      }

      <Row>
        <Col md={{span:4, offset:2}} className='text-align-right'>
          {I18n.t('harvestBatch.modify.date')}
        </Col>
        <Col md='4'>
          <InternationalDateTimePickerInput name='action_date'/>
        </Col>
      </Row>

      <PermissionWrapper permissions={[p.manage_harvests]} >
        <Row>
          <Col md={{span:4, offset:2}} className='text-align-right'>
            {I18n.t('harvestBatch.modify.currentMoisture')}
          </Col>
          <Col md='4'>
            <InternationalDecimalInput
              name={'moisture_pct'}
              props={{
                rightAddon: <span>%</span>,
                step: 0.01,
                disabled: hasFinalWeight && finalWeight
              }} />
          </Col>
        </Row>
      </PermissionWrapper>

      <Row>
        <Col md={{span:4, offset:2}} className='text-align-right'>
          <PermissionWrapper permissions={[p.manage_harvests]}>
            {I18n.t('harvestBatch.modify.Current Calculated Moisture Loss')}
          </PermissionWrapper>
        </Col>
        <Col md='4'>
          <PermissionWrapper permissions={[p.manage_harvests]}>
            <InternationalDecimalInput
              name={'calculated_moisture_loss'}
              props={{
                rightAddon: <span>%</span>,
                disabled: true
              }} />
          </PermissionWrapper>
        </Col>
      </Row>

      <Row>
        <Col md={{span:4, offset:2}} className='text-align-right'>
          <FaAsterisk style={{ fontSize: '0.5em', verticalAlign: 'top', marginTop: '1px' }} />
          <span style={{fontWeight: 'bold'}}>{I18n.t('harvestBatch.modify.storageArea')}</span>
        </Col>
        <Col md='4'>
          <Field
            name={isBiotrack ? 'section_id' : 'inventory_location_id'}
            component={ReactSelectInput}
            props={{
              options: sections,
              placeholder: I18n.t('common.form.selectPlaceholder'),
            }}/>
        </Col>
      </Row>

      {isMetrc && harvestWasteTypes && harvestWasteTypes.length && (
        <Row>
          <Col md={{span:4, offset:2}} className='text-align-right'>
            {I18n.t('harvest.form.harvestWasteType')}
          </Col>
          <Col md='4'>
            <Field
              name='waste_type'
              component={ReactSelectInput}
              props={{
                options: harvestWasteTypes,
                valueKey: 'name',
                textKey: 'name'
              }}/>
          </Col>
        </Row>
      )}

      <Row>
        <Col md={{span:4, offset:2}} className='text-align-right'>
          {I18n.t('registers.form.notes')}
        </Col>
        <Col md='4'>
          <Field
            name='notes'
            component={TextAreaInput}
            props={{
              rows: 3,
              groupClassName: 'notes-field'
            }}/>
        </Col>
      </Row>

      <ButtonToolbar className='float-right'>
        <Button type='reset' onClick={reset}>
          {I18n.t('common.form.reset')}
        </Button>
        <Button variant='primary' disabled={invalid || pristine} type='submit'>
          {I18n.t('common.form.save')}
        </Button>
      </ButtonToolbar>

    </form>
  );
};

ModifyHarvestBatchForm.propTypes = {
  handleSave: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func,
  reset: PropTypes.func.isRequired,
  moistureLoss: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sections: PropTypes.array.isRequired,
  hasFinalWeight: PropTypes.bool,
  finalWeight: PropTypes.bool,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  initialValues: PropTypes.object,
  integrationState: PropTypes.object.isRequired,
  increasedWeight: PropTypes.bool.isRequired,
  harvestWasteTypes: PropTypes.array,
  harvestBatch: PropTypes.object.isRequired,
  formValues: PropTypes.object
};

ModifyHarvestBatchForm.defaultProps = {
  finalWeight: false
};

export default ModifyHarvestBatchForm;
