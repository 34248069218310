import React from 'react';
import PropTypes from 'prop-types';
import {reduxForm, Field, FieldArray} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Row, Col, Button} from 'react-bootstrap';
import {FaExclamationTriangle} from 'react-icons/fa';
import get from 'lodash.get';
import PermissionButton from '../../common/PermissionButton';
import AccordionPanel from '../../common/AccordionPanel';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import TextInput from '../../common/form/TextInput';
import InternationalDateTimePickerInput from '../../common/form/InternationalDateTimePickerInput';
import InlineCheckBox from '../../common/form/InlineCheckBox';
import ReduxMetrcIdField from '../../common/form/redux-form/ReduxMetrcIdField';
import WillRender from '../../common/concealers/WillRender';
import AddNoteSection from '../common/AddNoteSection';
import StrainTable from './StrainTable';
import PackageIndividualPlantsFieldArray from './PackageIndividualPlantsFieldArray';
import PackagePurposeField from '../../common/form/redux-form/PackagePurposeField';
import InternationalQuantityByUomInput from '../../common/form/InternationalQuantityByUomInput';
import CheckBoxInput from '../../common/form/CheckBoxInput';
import validate from './validate';
import * as p from '../../../constants/permissions';
import packagableUnmedicatedSubcategories from '../../../constants/packageableUnmedicatedSubcategories';
import {leafWaPlantPackageSubcategoryCode} from '../../../constants/leaf';


class PackagePlantsForm extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    const {
      handleSubmit,
      exitPage,
      strain,
      locations,
      existingItemMasters,
      lotNumber,
      valid,
      submitting,
      printSavedPackageLabels,
      printPackageLabelAfterSave,
      pristine,
      saveComplete,
      integrationState: {isNormal, isMetrc, isCaMetrc, isCanada, isWaLeaf, isPaLeaf},
      trackingIds,
      loading,
      trackPlantsAsGroups,
      selectedItemMaster,
      hasPackagesTags,
      packagingIndivialPlants,
      currentPhase,
      hasMotherPlants,
      isDonation,
      isTradeSample,
      isPaUnmedicatedWholesaleSubcategoriesEnabled,
      allowIndividualPackagingMetrcCa
    } = this.props;

    const seedSubCategoryId = 38;
    const showQtyInputField =
      isPaUnmedicatedWholesaleSubcategoriesEnabled &&
      isPaLeaf &&
      selectedItemMaster &&
      packagableUnmedicatedSubcategories.includes(selectedItemMaster.subcategory_code);
    // Right now this constant and "showQtyInputField" have the same logic, but they may diverge at some point
    const showDeactivatePlantsCheckbox = showQtyInputField;

    return (
      <div className='package-plants-form'>
        <form onSubmit={handleSubmit}>
          <AccordionPanel defaultExpanded={true} title={`${strain.type} ${strain.quantity} Plants`} className='strain-panel'>
            <WillRender ifFalse={packagingIndivialPlants}>
              <StrainTable data={strain.plants} trackPlantsAsGroups={trackPlantsAsGroups} />
            </WillRender>
            <WillRender ifTrue={packagingIndivialPlants}>
              <FieldArray
                name='individual_plants'
                component={PackageIndividualPlantsFieldArray}
                props={{
                  trackingIds: trackingIds,
                  isCaMetrc: isCaMetrc,
                  selectedItemMaster: selectedItemMaster,
                }}
              />
            </WillRender>
          </AccordionPanel>
          <Row>
            <Col md={3}>
              {existingItemMasters.length || loading ?
                <Field
                  name='item_master_id'
                  component={ReactSelectInput}
                  required={true}
                  props={{
                    label: I18n.t('plants.packagePlants.newItem'),
                    options: isWaLeaf ? existingItemMasters.filter(item => item.subcategory_code === leafWaPlantPackageSubcategoryCode) : existingItemMasters,
                    textKey: 'name',
                    valueKey: 'id',
                    isRequired: true,
                    onChange: (itemMasterId) => {
                      this.props.change('finished_inventory', 1);
                      this.props.change('item_master_id', itemMasterId);
                    }
                  }}/> :
                <div style={{padding: '5px', minHeight: '60px', marginBottom: '12px'}} className='bg-danger text-white'>
                  <FaExclamationTriangle style={{paddingRight: '8px', fontSize: '18px'}}/>
                  {I18n.t('plants.packagePlants.noValidProductsError')}
                </div>
              }
            </Col>
            <Col md={3}>
              <Field
                name='storage_location_id'
                component={ReactSelectInput}
                props={{
                  label:I18n.t('plants.packagePlants.storageLocation'),
                  options: locations,
                  textKey: 'text',
                  valueKey: 'value',
                  isRequired: true
                }}
              />
            </Col>

            <Col md={3}>
              <PackagePurposeField name='purpose' />
            </Col>

            {showQtyInputField &&
              <Col md={3}>
                <InternationalQuantityByUomInput
                  name='qty'
                  label={I18n.t('plants.packagePlants.quantity')}
                  uom={selectedItemMaster.default_uom}
                />
              </Col>
            }

            <WillRender ifTrue={isMetrc && isCaMetrc}>
              <Col xs={4} md={3}>
                <Field
                  name='is_donation'
                  component={ReactSelectInput}
                  props={{
                    label: I18n.t('common.form.isDonation'),
                    disabled: hasMotherPlants || isTradeSample,
                    options: [
                      {text: 'Yes', value: 1},
                      {text: 'No', value: 0},
                    ]
                  }}
                />
              </Col>
              <WillRender ifTrue={currentPhase !== 'flower'}>
                <Col xs={4} md={3}>
                  <Field
                    name='is_trade_sample'
                    component={ReactSelectInput}
                    props={{
                      label: I18n.t('common.form.isTradeSample'),
                      disabled: isDonation,
                      options: [
                        {text: 'Yes', value: 1},
                        {text: 'No', value: 0},
                      ]
                    }}
                  />
                </Col>
              </WillRender>
            </WillRender>

            <WillRender ifTrue={isCanada}>
              <Col md={3}>
                <div style={{padding: '18px 0'}}>
                  <Field
                    name='finished_inventory'
                    component={InlineCheckBox}
                    props={{
                      label: I18n.t('common.form.finishedInventoryCheckbox'),
                      disabled: get(selectedItemMaster, 'subcategory_id', 0) === seedSubCategoryId,
                    }}
                  />
                </div>
              </Col>
            </WillRender>

            <WillRender ifTrue={isMetrc && !allowIndividualPackagingMetrcCa}>
              <Col md={3}>
                <ReduxMetrcIdField
                  name='state_integration_tracking_id'
                  props={{
                    options: trackingIds
                  }}
                />
              </Col>
            </WillRender>

            {hasPackagesTags ?
              <Col md={3}>
                <Field
                  name='tag_requested' component={ReactSelectInput}
                  props={{
                    label: I18n.t('plants.modify.needsTag'),
                    options: [{text : I18n.t('common.yes'), value : 1} , {text : I18n.t('common.no'), value :  0}],
                    textKey: 'text',
                    valueKey: 'value',
                    placeholder: I18n.t('common.no')
                  }} />
              </Col> : null
            }

          </Row>
          <Row>
            <Col xs={8} md={6}>
              <AddNoteSection/>
            </Col>
            <Col md={3} xs={4}>
              <InternationalDateTimePickerInput
                name='datePackaged'
                props={{
                  label: I18n.t('plants.packagePlants.datePackaged'),
                  isRequired: true,
                }} />
            </Col>
            {showDeactivatePlantsCheckbox &&
              <Col md={3}>
                <Field
                  name='deactivate_plants'
                  component={CheckBoxInput}
                  props={{
                    label: I18n.t('plants.packagePlants.deactivatePlants'),
                    groupClassName: 'inline-checkbox'
                  }}
                />
              </Col>
            }
          </Row>

          {
            (isNormal) && currentPhase === 'flower' && (
              <Row>
                <Col md={3} xs={4}>
                  <Field
                    name='package_individual_plants'
                    component={InlineCheckBox}
                    props={{
                      label: I18n.t('common.form.packageIndividualPlants')
                    }}
                  />
                </Col>
              </Row>
            )
          }
          <div className='layout-row layout-align-end-center package-plants-button'>
            <Button style={{marginRight: '5px'}} variant='info' disabled={submitting || pristine || !valid} onClick={(event) => printPackageLabelAfterSave(event)} type='submit'>
              {
                saveComplete
                ? I18n.t('common.printLabel')
                  : I18n.t('common.saveAndPrintLabel')
              }
            </Button>
            <Button variant='primary' disabled={submitting || pristine || !valid || saveComplete} type='submit'>
              {I18n.t('plants.packagePlants.actions.packagePlants')}
            </Button>
          </div>
          {!lotNumber || <div className='layout-row layout-align-start-end package-plants-button submit-section' >
            <div className='submit-section-item'>
              <Field
                name='lot_number'
                component={TextInput}
                props={{
                  label: I18n.t('plants.packagePlants.lotId'),
                  readOnly: true
                }}
              />
            </div>
            <div className='submit-section-item'>
              <PermissionButton
                permissions={[p.print_labels]}
                props={{
                  variant: 'info',
                  onClick: printSavedPackageLabels
                }}
                >{I18n.t('common.form.print')}
              </PermissionButton></div>
            <div className='submit-section-item'>
              <Button variant='primary' onClick={exitPage}>{I18n.t('plants.packagePlants.actions.exitPage')}</Button></div>
          </div>}
        </form>
      </div>
    );
  }
}

PackagePlantsForm.propTypes = {
  saveComplete: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  exitPage: PropTypes.func.isRequired,
  strain: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  lotNumber: PropTypes.string.isRequired,
  valid: PropTypes.bool,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  existingItemMasters: PropTypes.array.isRequired,
  printSavedPackageLabels: PropTypes.func.isRequired,
  printPackageLabelAfterSave: PropTypes.func.isRequired,
  integrationState: PropTypes.object.isRequired,
  trackingIds: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  trackPlantsAsGroups: PropTypes.bool.isRequired,
  packagingIndivialPlants: PropTypes.bool,
  currentPhase: PropTypes.string,
  hasMotherPlants: PropTypes.bool,
  selectedItemMaster: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ]),
  isDonation: PropTypes.bool,
  isTradeSample: PropTypes.bool,
};

export default reduxForm({
  validate
})(PackagePlantsForm);
