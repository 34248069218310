import get from 'lodash.get';

/**
 * Returns true if the facility province_code is not equal to the requiredAbbr
 * and the permission key string has the state name derived from the
 * requiredAbbr in the key.  Else false since the perm applies to the facility.
 * @param facility - current facility
 * @param requiredAbbr - abbreviation we want the facility attribute to match
 * @param permissionKey - permission string
 * @returns {*}
 */
export const isGeoPermByStateExcluded = (facility, requiredAbbr, permissionKey) => {
  return isGeoPermExcluded(facility, 'state', requiredAbbr, permissionKey);
};

/**
 * Returns true if the facility country is not equal to the requiredAbbr
 * and the permission key has the country name derived from the
 * requiredAbbr in the key.  Else false since the perm applies to the facility.
 * @param facility - current facility
 * @param requiredAbbr - abbreviation we want the facility attribute to match
 * @param permissionKey - permission string
 * @returns {*}
 */
export const isGeoPermByCountryExcluded = (facility, requiredAbbr, permissionKey) => {
  return isGeoPermExcluded(facility, 'country', requiredAbbr, permissionKey);
};

const isGeoPermExcluded = (facility, type, requiredAbbr, permissionKey) => {
  requiredAbbr = (typeof requiredAbbr === 'string' ? requiredAbbr : '').toLowerCase();
  const map = {
    state: {
      attribute: 'province_code',
      values: {
        ok: 'oklahoma',
      }
    },
    country: {
      attribute: 'country_code',
      values: {
        ca: 'canada'
      }
    }
  };

  const getFacilityAttribute = () => {
    const att = get(facility, map[type].attribute, '');
    return (typeof att === 'string' ? att : '').toLowerCase();
  };
  return getFacilityAttribute() !== requiredAbbr && permissionKey.indexOf(map[type].values[requiredAbbr]) !== -1;
};
