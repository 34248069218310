import {createSelector, createStructuredSelector} from 'reselect';
import {convertFormInputDateToDbDate} from '../../util/dateHelpers';
import {getSelectedProducts} from '../productsSelectors';
import {getTimezone} from '../timezoneSelectors';
import {getUseEntityLocksForItems} from '../coreSettingsSelectors';


const biotrackDestructionReasons = [
  {text: 'Other', value: 0},
  {text: 'Waste', value: 1},
  {text: 'Unhealthy or Died', value: 2},
  {text: 'Infestation', value: 3},
  {text: 'Product Return', value: 4},
  {text: 'Mistake', value: 5},
  {text: 'Spoilage', value: 6},
  {text: 'Quality Control', value: 7},
];

const getBiotrackDestructionReasons = () => biotrackDestructionReasons;

const getInitialValues = createSelector(
  [getSelectedProducts],
  (items) => {
    return {
      items,
    };
  }
);

export const getDestroyPackagesPageProps = createStructuredSelector({
  biotrackDestructionReasons: getBiotrackDestructionReasons,
  initialValues: getInitialValues,
  timezone: getTimezone,
  useEntityLocks: getUseEntityLocksForItems,
});

export const getDestroyPackagesPayload = (formValues, timezone) => {
  const isNumber = v => typeof v === 'number';

  return {
    event_date: formValues.event_date ? convertFormInputDateToDbDate(formValues.event_date, timezone) : undefined,
    notes: formValues.notes || undefined,
    integration_destroy_reason_extended: isNumber(formValues.integration_destroy_reason_extended) ? formValues.integration_destroy_reason_extended : undefined,
    items: formValues.items.map((item) => {
      const reason = isNumber(item.integration_destroy_reason_extended) ?
        item.integration_destroy_reason_extended : formValues.integration_destroy_reason_extended;

      return {
        id: item.id,
        transacted_qty: -item.qty,
        transacted_uom: item.uom,
        is_destroyed: 1,
        notes: item.notes || undefined,
        integration_destroy_reason: item.notes || formValues.notes || '',
        integration_destroy_reason_extended: reason,
      };
    }),
  };
};
