import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import NumberInput from '../../common/form/NumericInput';
import TextInput from '../../common/form/TextInput';

const DenominationBlock = (props) => {
  const {fields: {map}, columns, onChangeDenomination} = props;

  return (
    <div>
      <Row className='closing-till-block-header'>
        <Col xs={4} sm={4} md={4}>
          {I18n.t('retail.registerReconciliation.denomination')}
        </Col>
        {columns.employee.show &&
        <Col xs={4} sm={4} md={4}>
          {I18n.t('retail.registerReconciliation.employeeCount')}
        </Col>
        }
        {columns.verification.show &&
        <Col xs={4} sm={4} md={4}>
          {I18n.t('retail.registerReconciliation.verification')}
        </Col>
        }
      </Row>
      {
        map((item, index) => {
          return (
            <Row key={index}>
              <Col xs={4} sm={4} md={4} className='denomination-col'>
                <Field
                  name={`${item}.description`}
                  component={TextInput}
                  disabled
                />
              </Col>
              {columns.employee.show &&
              <Col xs={4} sm={4} md={4}>
                <Field
                  name={`${item}.employeeCount`}
                  component={NumberInput}
                  fractionPartSize={0}
                  min={0}
                  allowNegativeNumber={false}
                  props={{
                    disabled: columns.employee.disabled
                  }}
                  onChange={(event, newValue) => onChangeDenomination(newValue, index)}
                />
              </Col>
              }
              {columns.verification.show &&
              <Col xs={4} sm={4} md={4}>
                <Field
                  name={`${item}.verifiedCount`}
                  component={NumberInput}
                  fractionPartSize={0}
                  min={0}
                  allowNegativeNumber={false}
                  props={{
                    disabled: columns.verification.disabled
                  }}
                  onChange={(event, newValue) => onChangeDenomination(newValue, index)}
                />
              </Col>
              }
            </Row>
          );
        })
      }
    </div>
  );
};

DenominationBlock.propTypes = {
  columns: PropTypes.object,
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired
  }),
};

export default DenominationBlock;
