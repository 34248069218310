import React from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {push, goBack} from 'react-router-redux';
import * as apiActions from '../../../../actions/apiActions';
import testTypes from '../../../../constants/testTypes';
import TestingComplianceFormWrapper from './TestingComplianceFormWrapper';
import FormWrapper from '../../../common/form/FormWrapper';

export class TestingCompliancePage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(event) {
    event.preventDefault();
  }

  render() {
    const testData = {
      cannabinoidPotency: [
        'd9thca',
        'd9thc',
        'd8thc',
        'thcv',
        'cbd',
        'cbda',
        'cbdv',
        'cbg',
        'cbga',
        'cbc',
        'cbn'
      ],
      terpeneProfile: [
        'bisabolol',
        'humulene',
        'pinene',
        'terpinolene',
        'bCaryophyllene',
        'bMyrcene',
        'bpinene',
        'caryophylleneOxide',
        'limonene',
        'linalool',
        'geraniol',
        'trans_nerolidal',
        'guaiol',
        'isopulegol',
        'camphene',
        'carene',
        'y_terpinene',
        'a_terpinene',
        'eucalyptol',
        'ocimene',
        'p_cymene',
      ],
      microbials: [
        'totalColiforms',
        'totalAerobicBacteria',
        'gramNegativeBacteria',
        'yeast',
        'mold',
        'fecalContamination',
        'insectParts',
        'animalParts',
        'sclerotiniaSclerotiorum',
        'epicoccumNigrum',
        'cladosporiumHerbarum',
        'botrytisCinerea',
        'aspergillusFumigatus',
        'aspergillusFlavus',
        'aspergillusNiger',
        'aspergillusTerreus'
      ],
      residualSolvents: [
        'acetone',
        'ethane',
        'nHexane',
        'methane',
        'methylbutane_2',
        'methylpropane_2',
        'nPentane',
        'propanol_2',
        'butanes',
        'componentMixInDimethylSulfoxide_5',
        'componentMixInDimethylSulfoxide_15',
        'componentMixInDimethylSulfoxide_8',
        'componentMixInDimethylSulfoxide_24',
        'componentMixInDimethylSulfoxide_2',
        'pharmResidualSolvent'
      ],
      heavyMetals: [
        'arsenic',
        'cadmium',
        'lead',
        'mercury'
      ],
      mycotoxins: [
        'aflatoxins',
        'ochratoxin',
        'citrinin',
        'pptulin',
        'ergotAlkaloids',
        'fusarim'
      ],
      pesticides: [
        'abamectin',
        'acequinocyl',
        'bifenazate',
        'befentrin',
        'captan',
        'cyfluthrin',
        'cypermethrin',
        'dimethomorph',
        'etoxazole',
        'fenhexamid',
        'flonicamid',
        'fludioxonil',
        'imidacloprid',
        'myclobutanil',
        'pcnb',
        'piperonylButoxide',
        'pyrethrin',
        'spinetoram',
        'spinosad',
        'spirotetramat',
        'thiamethoxam',
        'trifloxystrobin'
      ]
    };
    const testOptions = Object.keys(testTypes).map(typeKey => {
      return {text: testTypes[typeKey], value: typeKey};
    });
    return (
      <div className='testing-compliance-page'>
        <FormWrapper title={'cultivation.testingCompliance.title'}
                     goBack={this.props.actions.goBack}>
          <TestingComplianceFormWrapper onSubmit={this.onSubmit} testData={testData} testOptions={testOptions}/>
        </FormWrapper>
      </div>
    );
  }
}

TestingCompliancePage.propTypes = {
  actions: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }).isRequired
};


function mapStateToProps(state) {
  return {
    state
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, apiActions, {goBack, push}), dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TestingCompliancePage);

