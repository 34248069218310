import differenceBy from 'lodash.differenceby';
import * as types from '../../constants/actionTypes';
import initialState from '../../constants/initialState';

export default function selectedDataReducerFactory (selectedDataName = '') {
  return function selectedDataReducer (state = initialState[selectedDataName], action){

    const {name, selectedData} = action;
    if(name != selectedDataName) {
      return state;
    }
    switch (action.type) {
    case types.ADD_SELECTED_DATA:
      return [...state, ...selectedData];
    case types.REMOVE_SELECTED_DATA:
      return differenceBy(state, selectedData, 'id');
    case types.SET_SELECTED_DATA:
    case types.SET_SELECTED_DATA_IMMEDIATE:
      return [...selectedData];
    case types.CLEAR_SELECTED_DATA:
      return initialState[selectedDataName];
    default:
      return state;
    }
  };

}
