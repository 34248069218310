import React from 'react';
import PropTypes from 'prop-types';
import * as ReactIcons from 'react-icons/fa';
import * as ReactBiIcons from 'react-icons/bi';
import {I18n} from 'react-redux-i18n';
import RouteButton from '../RouteButton';
import PermissionButton from '../PermissionButton';

const ActionBarButton = ({hide, requireSelect, rowIsSelected, disabled, variant, glyph, text, func, path, permissions}) => {
  if (!hide) {
    const props = {
      variant,
      onClick: func,
      disabled: disabled || (requireSelect && !rowIsSelected),
    };
    const componentProps = path ? {path} : {permissions: permissions || []};
    const Component = path ? RouteButton : PermissionButton;

    // Mapping from Glyphicon to ReactIcon
    const iconMapping = [
      {key: 'plus-sign', icon: ReactIcons.FaPlus},
      {key: 'th-list', icon: ReactIcons.FaList},
      {key: 'briefcase', icon: ReactIcons.FaBriefcase},
      {key: 'gift', icon: ReactIcons.FaGift},
      {key: 'grain', icon: ReactIcons.FaCannabis},   // Not great
      {key: 'duplicate', icon: ReactIcons.FaCopy},
      {key: 'trash', icon: ReactIcons.FaTrashAlt},
      {key: 'floppy-disk', icon: ReactIcons.FaSave},
      {key: 'edit', icon: ReactIcons.FaEdit},
      {key: 'list-alt', icon: ReactIcons.FaRegListAlt},
      {key: 'tint', icon: ReactBiIcons.BiTestTube},
      {key: 'print', icon: ReactIcons.FaPrint},
      {key: 'plus', icon: ReactIcons.FaPlus},
      {key: 'arrow-up', icon: ReactIcons.FaArrowUp},
    ];

    const IconComponent = iconMapping.find((icon) => icon.key === glyph);

    return (
      <Component props={props} {...componentProps}>
        {IconComponent ? <IconComponent.icon/> : null} {I18n.t(text)}
      </Component>
    );
  } else {
    return null;
  }
};

ActionBarButton.propTypes = {
  hide: PropTypes.bool,
  requireSelect: PropTypes.bool,
  rowIsSelected: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  glyph: PropTypes.string,
  text: PropTypes.string.isRequired,
  func: PropTypes.func,
  path: PropTypes.string,
  permissions: PropTypes.array,
};

ActionBarButton.defaultProps = {
  variant: 'primary',
  disabled: false,
  requireSelect: false,
  hide: false
};

export default ActionBarButton;
