import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import AccordionPanel from '../../common/AccordionPanel';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import TextAreaInput from '../../common/form/TextAreaInput';
import NumericInput from '../../common/form/NumericInput';
import InternationalDecimalInput from '../../common/form/InternationalDecimalInput';
import {
  isCategoryFlower,
  isCategoryConcentrate,
  isCategoryCartridgesPens,
  isCategoryPlants,
  isCategoryNonMedicated,
  isSubCategorySeeds,
  isCategoryMarijuana,
} from '../../../selectors/categorySelectors';
import {EA, UOM_VALUE_PRECISION} from '../../../constants/uoms';
import InlineCheckBox from '../../common/form/InlineCheckBox';


const AdditionalInfoPanel = ({dilutionOptions, solventOptions, selectedCategory, selectedSubCategory, formValues, isSeedPackagingAllowed, integrationState}) => {
  const sc = selectedCategory;
  const servingSizeRequired = Boolean(formValues.required_fields && formValues.required_fields.requires_serving_size);
  const ingredientsRequired = Boolean(formValues.required_fields && formValues.required_fields.requires_ingredients);
  const administrationMethodRequired = Boolean(formValues.required_fields && formValues.required_fields.requires_administration_method);
  const supplyDurationRequired = Boolean(formValues.required_fields && formValues.required_fields.requires_supply_duration_days);
  const isShowServingSizeField = !isCategoryPlants(sc) && !isCategoryNonMedicated(sc) || servingSizeRequired;
  const isShowServingsPerContainerField = !isCategoryPlants(sc) && !isCategoryNonMedicated(sc);
  const isShowSolventField = !isCategoryFlower(sc) && !isCategoryPlants(sc) && !isCategoryNonMedicated(sc) && !isCategoryMarijuana(sc);
  const isShowPiecesPerUnitField = !isCategoryFlower(sc) && !isCategoryConcentrate(sc) && !isCategoryCartridgesPens(sc) && !isCategoryNonMedicated(sc) && !isCategoryMarijuana(sc);
  const isPiecesPerUnitRequired = Boolean(isCategoryPlants(sc) && isSubCategorySeeds(selectedSubCategory) && formValues.default_uom === EA && isSeedPackagingAllowed);
  const isShowDilutionMethodField = isCategoryCartridgesPens(sc);

  // Added these to get the messages correct... but then became clear the actual upload functionality was never completed
  // So commenting these out and hide upload portions below.
  //const uploadSize = {limit: '20MB'};
  //const uploadTypes = {extensions: 'CSV'};

  return (
    <AccordionPanel title='products.form.additionalInfo' className='form-panel financial-info'>
      <Row>
        {
          isShowServingSizeField
          ? <Col md={4} sm={6}>
            <InternationalDecimalInput name='serving_size'
              value={isShowServingSizeField ? formValues.serving_size : ''}
              props={{
                label: I18n.t('products.form.servingSize'),
                allowNegativeNumber: false,
                fractionPartSize: integrationState.isWaLeaf ? 0 : UOM_VALUE_PRECISION,
                isRequired: servingSizeRequired
              }}/>
        </Col>
          : null
        }
        {isShowServingsPerContainerField ? <Col md={4} sm={6}>
          <InternationalDecimalInput name='servings_per_container'
            props={{
              label: I18n.t('products.form.servingsPerContainer'),
              placeholder: I18n.t('products.form.servingsPerContainer'),
              fractionPartSize: 0,
              value: isShowServingsPerContainerField ? formValues.servings_per_container : '',
            }}/>
        </Col> : null}

        {isShowSolventField ? <Col md={4} sm={6}>
          <Field name='solvent' component={ReactSelectInput} props={{
            label: I18n.t('products.form.solvent'),
            options: solventOptions,
            value: isShowSolventField ? formValues.solvent : null,
          }}/>
        </Col> : null}
        <Col md={4} sm={6}>
          <Field name='requires_refrigeration'
              component={InlineCheckBox}
              props={{label:I18n.t('products.form.requiresRefrigeration'), groupClassName:'inline-checkbox requires-refrigeration'}}
          />
        </Col>
        {isShowPiecesPerUnitField ? <Col md={4} sm={6}>
          <Field name='pieces_per_unit' component={NumericInput} props={{
            label: I18n.t('products.form.piecesPerUnit'),
            placeholder: I18n.t('products.form.amountPlaceholder'),
            isRequired: isPiecesPerUnitRequired,
            type:'text',
            fractionPartSize: 0,
            min: 1,
          }}/>
        </Col> : null}
        {isShowDilutionMethodField ? <Col md={4} sm={6}>
          <Field name='dilution_method' component={ReactSelectInput} props={{
            label: I18n.t('products.form.dilutionMethod'),
            options: dilutionOptions,
            value: isShowDilutionMethodField ? formValues.dilution_method : null,
          }}/>
        </Col> : null}
        {supplyDurationRequired ? <Col md={4} sm={6}>
          <Field name='supply_duration_days' component={NumericInput} props={{
            label: I18n.t('products.form.supplyDurationDays'),
            placeholder: I18n.t('products.form.amountPlaceholder'),
            rightAddon: <span>Days</span>,
            isRequired: supplyDurationRequired,
          }}/>
        </Col> : null}
      </Row>
        <AccordionPanel expanded={ingredientsRequired || administrationMethodRequired} title='products.form.ingredientsAllergensAndNutritionalInfo' className='ingredients-panel'>
          <Row className='content'>
            <Col md={6} sm={12}>
              <Field name='ingredients' component={TextAreaInput} props={{
                label: I18n.t('products.form.ingredients'),
                placeholder: I18n.t('products.form.ingredientsPlaceholder'),
                rows: 5,
                isRequired: ingredientsRequired
              }}/>
              {/*The uploads for these have never been connected.  There is some question as to what should be uploaded here so */}
              {/*when this comes up again - the following is a bare minimum start.*/}
              {/*This came up when a bug was raised over the uploadSize and uploadTypes not being shown correctly but is for 0.6.5*/}
              {/*and the additional time to connect properly, show tables of files, etc... is not available*/}
              {/*<div className='layout-row layout-align-start-center'>*/}
                {/*<Field name='ingredients_file_id' itemNames={itemNames.document} component={FileInput} props={{*/}
                  {/*label: I18n.t('products.form.ingredientsFile'),*/}
                  {/*btnContent: I18n.t('common.form.browse'),*/}
                  {/*file_type: 'COMMON_PUBLIC_DOCUMENT',*/}
                  {/*type: 'document',*/}
                  {/*onUploadStart: (stuff) => {*/}
                    {/*// Probably start spinner*/}
                    {/*// There is probably another hook on exit that needs to be hooked up here.*/}
                  {/*},*/}
                  {/*btnProps: {*/}
                    {/*className: 'btn btn-default'*/}
                  {/*}*/}
                {/*}}/>*/}
                {/*<Button variant='success'>{I18n.t('common.form.upload')}</Button>*/}
              {/*</div>*/}
              {/*<p>{I18n.t('common.form.sizeLimit', uploadSize)}</p>*/}
              {/*<p>{I18n.t('common.form.typeLimit', uploadTypes)}</p>*/}
            </Col>
            <Col md={6} sm={12}>
              <Field name='allergens' component={TextAreaInput} props={{
                label: I18n.t('products.form.allergens'),
                placeholder: I18n.t('products.form.allergensPlaceholder'),
                rows: 5
              }}/>
              {/*<div className='layout-row layout-align-start-center'>*/}
                {/*<Field name='allergens_file_id' name={itemNames.document} component={FileInput} props={{*/}
                  {/*label: I18n.t('products.form.allergensFile'),*/}
                  {/*btnContent: I18n.t('common.form.browse'),*/}
                  {/*btnProps: {*/}
                    {/*className: 'btn btn-default'*/}
                  {/*}*/}
                {/*}}/>*/}
                {/*<Button variant='success'>{I18n.t('common.form.upload')}</Button>*/}
              {/*</div>*/}
              {/*<p>{I18n.t('common.form.sizeLimit', uploadSize)}</p>*/}
              {/*<p>{I18n.t('common.form.typeLimit', uploadTypes)}</p>*/}
            </Col>
            <Col md={6} sm={12}>
              <Field name='nutritional_info' component={TextAreaInput} props={{
                label: I18n.t('products.form.nutritional'),
                placeholder: I18n.t('products.form.nutritionalPlaceholder'),
                rows: 5
              }}/>
              {/*<div className='layout-row layout-align-start-center'>*/}
                {/*<Field name='nutritional_info_file_id' name={itemNames.document} component={FileInput} props={{*/}
                  {/*label: I18n.t('products.form.nutritionalFile'),*/}
                  {/*btnContent: I18n.t('common.form.browse'),*/}
                  {/*btnProps: {*/}
                    {/*className: 'btn btn-default'*/}
                  {/*}*/}
                {/*}}/>*/}
                {/*<Button variant='success'>{I18n.t('common.form.upload')}</Button>*/}
              {/*</div>*/}
              {/*<p>{I18n.t('common.form.sizeLimit', uploadSize)}</p>*/}
              {/*<p>{I18n.t('common.form.typeLimit', uploadTypes)}</p>*/}
            </Col>
            {administrationMethodRequired ? <Col md={6} sm={12}>
              <Field name='administration_method' component={TextAreaInput} props={{
                label: I18n.t('products.form.AdministrationMethod'),
                placeholder: I18n.t('products.form.AdministrationMethodsPlaceholder'),
                rows: 5,
                isRequired: administrationMethodRequired
              }}/>
          </Col> : null}
          </Row>
        </AccordionPanel>
    </AccordionPanel>
  );
};

AdditionalInfoPanel.propTypes = {
  solventOptions: PropTypes.array.isRequired,
  dilutionOptions: PropTypes.array.isRequired,
  selectedCategory: PropTypes.object,
  selectedSubCategory: PropTypes.object,
  formValues: PropTypes.object,
  isSeedPackagingAllowed: PropTypes.bool,
  integrationState: PropTypes.object.isRequired,
};

export default AdditionalInfoPanel;
