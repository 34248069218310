import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';

const Facility = ({name, street_address_1, street_address_2, city, province_code, postal_code, handleClick}) => {
  const commaLinePosition = city ? 3 : street_address_2 ? 2 : street_address_1 ? 1 : 0;
  return (
    <Button block
            onClick={handleClick}
            className='facility'
    >
      <address className='text-left'>
        <strong>{name}</strong><br/>
        {street_address_1}{commaLinePosition === 1 && ','}<br/>
        {street_address_2}{commaLinePosition === 2 && ','}{street_address_2 ? <br/> : null}
        {city}{commaLinePosition === 3 && ','} {province_code} {postal_code}
      </address>
    </Button>
   );
};

Facility.propTypes = {
  name: PropTypes.string.isRequired,
  postal_code: PropTypes.string,
  province_code: PropTypes.string,
  street_address_1: PropTypes.string,
  street_address_2: PropTypes.string,
  city: PropTypes.string,

  handleClick: PropTypes.func.isRequired
};

export default Facility;
