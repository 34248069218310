/* eslint-disable import/no-named-as-default */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack} from 'react-router-redux';

import * as itemNames from '../../../constants/itemNames';
import * as dataNames from '../../../constants/dataNames';
import * as apiActions from '../../../actions/apiActions';
import FormWrapper from '../../common/form/FormWrapper';
import InternalTransfersSettingsFormWrapper from './InternalTransfersSettingsFormWrapper';
import {getInitialValues, getPayload} from '../../../selectors/forms/internalTransfersSettingsFormSelector';
import WillRender from '../../common/concealers/WillRender';
import {unsetItem} from '../../../actions/itemActions';
import * as settingsActions from '../../../actions/core/settingsActions';

export class InternalTransfersSettingPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.getSettings = this.getSettings.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.redirect = this.redirect.bind(this);
    this.storeSettings = this.storeSettings.bind(this);

    this.state = {
      mounted: false
    };
  }

  componentDidMount() {
    this.getSettings();
  }

  componentWillUnmount() {
    this.props.actions.unsetItem(itemNames.coreInternalTransfersSettings);
  }

  getSettings() {
    Promise.all([
      this.props.actions.getUnpaginatedData(
        '/api/users/permission/facilities',
        dataNames.availableFacilitiesIds,
        null,
        {permission_keys: ['manage_internal_transfers']}
      ),
      this.props.actions.fetchInternalTransferSettings(),
    ]).then(
      () => this.setState({mounted: true})
    );

  }

  //Handle submission of form
  onSubmit(submittedSettings) {
    this.storeSettings(submittedSettings);
  }

  storeSettings(submittedSettings) {
    this.props.actions.saveInternalTransferSettings(getPayload(submittedSettings));
  }

  redirect() {
    this.props.actions.goBack();
  }


  render() {
    const {initialValues} = this.props;
    return (
      <div>
        <FormWrapper title='internalTransfersSettings.title' goBack={this.redirect}>
          <WillRender ifTrue={this.state.mounted}>
          <InternalTransfersSettingsFormWrapper
            onSubmit={this.onSubmit}
            initialValues={initialValues}
          />
          </WillRender>
        </FormWrapper>
      </div>
    );
  }
}

InternalTransfersSettingPage.propTypes = {
  actions: PropTypes.shape({
    getItem: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    initialValues: getInitialValues(state),
  };
}

function mapDispatchToProps (dispatch) {
  const actions = {
    ...apiActions,
    ...settingsActions,
    goBack,
    unsetItem
  };

  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InternalTransfersSettingPage);
