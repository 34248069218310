import get from 'lodash.get';
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack} from 'react-router-redux';
import {change, getFormValues} from 'redux-form';
import * as dataNames from '../../../constants/dataNames';
import * as messageTypes from '../../../constants/messageTypes';
import * as apiActions from '../../../actions/apiActions';
import {canRetestPackages} from '../../../actions/inventory';
import {addMessage} from '../../../actions/systemActions';
import {clearSelectedData} from '../../../actions/selectedDataActions';
import {getPartners, getPartnerFacilities} from '../../../selectors/partnersSelectors';
import {setPartnerFromPackageCode} from '../../../actions/lab-result';
import {getCertificateDocument} from '../../../selectors/filesSelectors';

import {
  getAddTestResultsInitialValues,
  getPackageOptions,
  getTestResultDimensions,
  getTestResultRequiredDimensions,
  getInheritedDimensionCategories,
} from '../../../selectors/testResultsSelectors';
import {getCategories} from '../../../selectors/categorySelectors';
import {getSelectedProducts} from '../../../selectors/productsSelectors';
import InProgressOverlay from '../../common/InProgressOverlay';
import FormWrapper from '../../common/form/FormWrapper';
import TestResultsFormWrapper from '../common/TestResultsFormWrapper';
import {fetchPartners, fetchPartnerFacilities} from '../../../actions/partnerActions';
import {clearIsolocityTestResult} from '../../../actions/integrations/isolocity';
import { getInventoryComplianceSettings} from '../../../selectors/complianceSettingsSelectors';
import * as itemNames from '../../../constants/itemNames';
import {unsetItem} from '../../../actions/itemActions';
import {getFacilityHasModule} from '../../../selectors/facilitiesSelectors';
import {ADD_TEST_RESULTS_FORM} from '../../../constants/forms';
import {getIntegrationState} from '../../../selectors/integration/integrationSelectors';
import {TERPENE_PROFILE_DEFAULT_DIMENSIONS, TOTAL_TERPENE_KEY} from '../../../constants/testResults';
import {unsetData} from '../../../actions/dataActions';
import {isFeatureEnabled} from '../../../selectors/featureToggles';

const form = ADD_TEST_RESULTS_FORM;

const isPlantsTest = (props) => {
  return get(props, 'location.pathname', '').indexOf('plants') !== -1;
};

class AddTestResultsPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      mounted: false,
      submitting: false,
      hasActivePackage: true,
      uploading: false,
      isPlants: isPlantsTest(this.props),
      perServingModeAvailable: false,
      requiredTestResultsFields: []
    };

    this.redirect = this.redirect.bind(this);
    this.onDocChange = this.onDocChange.bind(this);
    this.inheritFromItemMaster = this.inheritFromItemMaster.bind(this);
    this.changeUploadStatus = this.changeUploadStatus.bind(this);
    this.getCertificateDocument = this.getCertificateDocument.bind(this);
  }

  componentWillMount() {
    const {actions: {unsetItem}} = this.props;
    unsetItem(itemNames.certificateOfAnalysisDocument);
    this.props.actions.getItem('/api/lab_results/configuration', itemNames.testResultConfiguration)
      .then(() => {
        this.setState({mounted: true});
      });
    this.props.actions.getItem('/api/compliance_settings', itemNames.complianceSettings);
    this.props.actions.getUnpaginatedData('/api/categories', dataNames.categories);
    this.props.actions.getUnpaginatedData('/api/subcategories', dataNames.subcategories);
  }

  componentDidMount() {
    this.props.actions.unsetItem(itemNames.testResult);
    const {selectedProducts, initialValues, actions, facilityHasLabModule, actions: {getItem, setPartnerFromPackageCode}} = this.props;
    const referenceId = get(initialValues, 'references.0.reference_id', 0);
    const reference_package_code = get(initialValues, 'references.0.package_code', 0);

    selectedProducts
      .filter(product => !initialValues.references.some(reference => reference.reference_id === product.package_id))
      .forEach(product => {
        const {package_code, name} = product;
        actions.addMessage(messageTypes.warning, ['cultivation.testResults.form.invalidPackage', {package_code: package_code || name}]);
      });

    Promise.all([
      getItem(`/api/lab_results_references/inherited/${referenceId}`, itemNames.inheritedLabResult)
    ]).then(() => {
      //If we had an initial product, set facility ID off of the first
      if (facilityHasLabModule && reference_package_code) {
        setPartnerFromPackageCode(reference_package_code, form);
      }
    });

    const item_master_ids = selectedProducts
      ? selectedProducts.map(product => product.item_master_id).filter((item_master_id, index, array) => array.indexOf(item_master_id) === index)
      : [];
    this.inheritFromItemMaster(item_master_ids);
  }

  componentWillUnmount() {
    this.props.actions.clearSelectedData(dataNames.products);
    this.props.actions.clearIsolocityTestResult();
    this.props.actions.unsetItem(itemNames.product);
    this.props.actions.unsetData(dataNames.itemMasters);
  }

  // See if we should populate the test result form from item_master values
  // We can only do this if the packages are for a single item_master_id, the facility is not in PA, Utah and is not a Lab
  inheritFromItemMaster(item_master_ids) {
    const {integrationState, facilityHasLabModule, labResultDimensions, currentValues, newProductMasterIsEnabled, actions: {change, getDataByPost, getItem, unsetItem}} = this.props;

    // This functionality only works with the new product masters
    if (!newProductMasterIsEnabled) {
      return;
    }

    const {isPaLeaf, isUtah} = integrationState;
    // If any test result fields are specified in the item_master then these should be marked as required
    const requiredTestResultsFields = [];

    const addMandatoryFields = (testResults, requiredTestResultsFields) => {
      const testResultsData = JSON.parse(testResults);
      if (testResultsData) {
        testResultsData.forEach((testResult) => {
          const name = get(testResult, 'name');
          if (name) {
            requiredTestResultsFields.push(`${name}_percent`);
            requiredTestResultsFields.push(`${name}_mg_g`);
          }
        });
      }
    };

    const populatePercentageTestResults = (testResults) => {
      const testResultsData = JSON.parse(testResults);
      if (testResultsData) {
        testResultsData.forEach((testResult) => {
          const name = get(testResult, 'name');
          const value = get(testResult, 'value');
          const uom = get(testResult, 'uom');
          if (name && value && uom) {
            switch (uom) {
            case 'percentage':
              change(form, `${name}_percent`, value);
              change(form, `${name}_mg_g`, Number((value * 10).toFixed(3)));
              break;
            case 'mg_g':
              change(form, `${name}_mg_g`, value);
              change(form, `${name}_percent`, Number((value * 0.1).toFixed(3)));
              break;
            }
          }
        });
      }
    };

    const populateServingTestResults = (testResults, servingsPerContainer) => {
      const testResultsData = JSON.parse(testResults);
      if (testResultsData) {
        testResultsData.forEach((testResult) => {
          const name = get(testResult, 'name');
          const value = get(testResult, 'value');
          if (name && value) {
            change(form, `${name}_percent`, value); // _percent is used to store per serving value
            change(form, `${name}_mg_g`, Number((value * servingsPerContainer).toFixed(3))); // _mg_g is used to store per pack value
          }
        });
      }
    };

    const recalculatePercentageValues = () => {
      const cannabinoid_potency_dimensions = [...get(labResultDimensions, 'cannabinoid_potency', [])];
      const terpene_profile_dimensions = [...TERPENE_PROFILE_DEFAULT_DIMENSIONS, {key: TOTAL_TERPENE_KEY}];
      const dimensions = [...cannabinoid_potency_dimensions, ...terpene_profile_dimensions];
      dimensions.forEach(dimension => {
        const key = dimension.key;
        const percent_value =  get(currentValues, `${key}_percent`);
        if (percent_value) {
          change(form, `${key}_mg_g`, Number((percent_value * 10).toFixed(3)));
        }
      });
    };

    // This functionality is only available if there are item_masters and facility is not PA, Utah and or a lab
    if (item_master_ids.length > 0 && !isPaLeaf && !isUtah && !facilityHasLabModule) {

      // If we have only one item_master
      // 1. Set mandatory fields based on item_master test results
      // 2. Populate item_master test results if source is set to item_masters
      if (item_master_ids.length === 1) {
        getItem(`/api/item_masters/${item_master_ids[0]}`, itemNames.product)
          .then((item_master) => {
            const potency_test_results = get(item_master, 'potency_test_results', '[]');
            const terpene_test_results = get(item_master, 'terpene_test_results', '[]');
            // 1. Determine what test_results_fields should be mandatory
            addMandatoryFields(potency_test_results, requiredTestResultsFields);
            addMandatoryFields(terpene_test_results, requiredTestResultsFields);
            this.setState({requiredTestResultsFields});
            // 2. Only set test results values if 'test_results_source' is set to 'item_master'
            // Per unit test results can only be inherited if servings_per_container is set in the item_master
            const source_is_item_master = get(item_master, 'test_results_source') === 'item_master';
            const value_type = get(item_master, 'test_results_value_type');
            const servings_per_container = get(item_master, 'servings_per_container');
            if (source_is_item_master && value_type === 'percentage') {
              populatePercentageTestResults(potency_test_results);
              populatePercentageTestResults(terpene_test_results);
              change(form, 'test_results_value_type', 'percentage');
            }
            if (source_is_item_master && value_type === 'serving' && servings_per_container !== '') {
              change(form, 'test_results_value_type', 'serving');
              change(form, 'servings_per_container', servings_per_container);
              change(form, 'servings_uom', get(item_master, 'servings_uom', 'mg'));
              populateServingTestResults(potency_test_results, servings_per_container);
              populateServingTestResults(terpene_test_results, servings_per_container);
              change(form, 'test_results_value_type', 'serving');
            }
          });
      }
      // There is more than 1 unique item master. We won't clear any fields that were populated, but we won't any longer make them mandatory
      else {
        unsetItem(itemNames.product);
        this.setState({requiredTestResultsFields: []});
      }

      // Fetch item_masters for all packages
      getDataByPost('/api/item_masters/multiple', {ids: item_master_ids}, dataNames.itemMasters, {failed:'products.get.failed'}, {detailed: 1})
        .then((itemMasters) => {
          // If the sales UOM for all item masters is set to EA we can enable per serving/per unit mode for Cannabinoids & Terpenes
          const perServingModeAvailable = !itemMasters.some(itemMaster => itemMaster.default_uom === 'GR'); // True if all item_masters have the EA uom
          this.setState({perServingModeAvailable});
          change(form, 'test_results_value_type', 'percentage');
          // If we changed from 'servings' to 'percentage' value types, we have to recalculate the
          recalculatePercentageValues();
        });
    }
  }

  onDocChange(file) {
    if(file.id) this.getCertificateDocument(file.id);
  }

  changeUploadStatus(status) {
    this.setState({uploading: status});
  }

  getCertificateDocument(id) {
    this.props.actions.unsetItem(itemNames.certificateOfAnalysisDocument);
    return this.props.actions.getItem(`/api/documents/${id}`, itemNames.certificateOfAnalysisDocument);
  }

  redirect(){
    this.props.actions.goBack();
  }

  render() {
    const {
      partners, partnerFacilities, initialValues, packageOptions, actions, certificateDocument, labResultDimensions,
      facilityHasLabModule, requiredDimensions, inheritedDimensionCategories, product,
      actions: {canRetestPackages}, currentValues
    } = this.props;
    const {isPlants, requiredTestResultsFields, perServingModeAvailable} = this.state;

    // Combine required dimension from lab results configuration and required dimensions from item_master
    const combinedRequiredDimensions = [...(requiredDimensions ? requiredDimensions : []), ...(requiredTestResultsFields ? requiredTestResultsFields : [])];

    return (
      <FormWrapper className='coupon-setup' title='cultivation.testResults.add.title' goBack={this.redirect}>
        <InProgressOverlay isActive={this.state.submitting} translate={true} message='common.form.saving'/>
        <InProgressOverlay isActive={!this.state.mounted} translate={true} message='common.form.loading'/>
        <TestResultsFormWrapper
          form={form}
          partners={partners}
          partnerFacilities={partnerFacilities}
          addMessage={actions.addMessage}
          packageOptions={packageOptions}
          initialValues={initialValues}
          documentUrl={get(certificateDocument, 'url', null)}
          hasActivePackage={this.state.hasActivePackage}
          complianceSettings={this.props.complianceSettings}
          changeUploadStatus={this.changeUploadStatus}
          onDocChange={this.onDocChange}
          inheritFromItemMaster={this.inheritFromItemMaster}
          change={change}
          labResultDimensions={labResultDimensions}
          facilityHasLabModule={facilityHasLabModule}
          requiredDimensions={combinedRequiredDimensions}
          isPlants={isPlants}
          mounted={this.state.mounted}
          inheritedDimensionCategories={inheritedDimensionCategories}
          canRetestPackages={canRetestPackages}
          currentValues={currentValues}
          categories={this.props.categories}
          product={product}
          perServingModeAvailable={perServingModeAvailable}
        />
      </FormWrapper>
    );
  }
}

AddTestResultsPage.propTypes = {
  actions: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    clearSelectedData: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    addMessage: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired,
    canRetestPackages: PropTypes.func.isRequired,
  }).isRequired,
  selectedProducts: PropTypes.array.isRequired,
  partners: PropTypes.array.isRequired,
  partnerFacilities: PropTypes.array.isRequired,
  packageOptions: PropTypes.array.isRequired,
  initialValues: PropTypes.object,
  complianceSettings: PropTypes.object,
  labResultDimensions: PropTypes.object,
  facilityHasLabModule: PropTypes.bool,
  requiredDimensions: PropTypes.array,
  inheritedDimensionCategories: PropTypes.array,
  categories: PropTypes.array,
  product: PropTypes.object,
  newProductMasterIsEnabled: PropTypes.bool.isRequired
};

function mapStateToProps(state, ownProps) {
  const isPlants = isPlantsTest(ownProps);
  return {
    partners: getPartners(state),
    partnerFacilities: getPartnerFacilities(state) || [],
    initialValues: getAddTestResultsInitialValues(state, {isPlants}),
    currentValues: getFormValues(form)(state),
    packageOptions: getPackageOptions(state, {form, isPlants}),
    selectedProducts: getSelectedProducts(state),
    complianceSettings : getInventoryComplianceSettings(state),
    certificateDocument: getCertificateDocument(state),
    facilityHasLabModule: getFacilityHasModule(state)('TESTING_LAB'),
    labResultDimensions: getTestResultDimensions(state, {form}),
    requiredDimensions: getTestResultRequiredDimensions(state, {form}),
    inheritedDimensionCategories: getInheritedDimensionCategories(state),
    categories: getCategories(state),
    integrationState: getIntegrationState(state),
    product: state[itemNames.product],
    newProductMasterIsEnabled: isFeatureEnabled(state)('feature_new_product_master')
  };
}


function mapDispatchToProps (dispatch) {
  const actions = {
    ...apiActions,
    addMessage,
    fetchPartners,
    fetchPartnerFacilities,
    clearSelectedData,
    clearIsolocityTestResult,
    setPartnerFromPackageCode,
    unsetItem,
    unsetData,
    goBack,
    change,
    canRetestPackages
  };
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddTestResultsPage);
