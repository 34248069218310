import {createSelector} from 'reselect';
import filter from 'lodash.filter';
import union from 'lodash.union';

import {getCustomCheckinForm} from './integrationSelectors';
import {getCurrentUser} from './usersSelectors';
import * as methods from '../constants/orderFulfillmentMethods';
import * as consumerTypes from '../constants/consumerTypes';

const getOrders = (state) => state.orders;
const getImages = (state) => state.images;
const getCustomers = (state) => state.customersQueue;
const getOrderType = (_, props) => props.params.orderType || 'in_store';
const getUsageType = (state) => state.usageType;
const getCustomerFilters = (state) => state.customerFilters;
const getConsumers = (state) => state.consumers;

const customerOrderType = (customer, customCheckinFom = null) => {
  if (customCheckinFom == 'leaf_mjp' && customer.fulfillment_status === 'pending' && (customer.fulfillment_method == 'delivery' || customer.fulfillment_method == 'pickup')) {
    return customer.fulfillment_method;
  }

  if(customer.fulfillment_status === 'assigned'){
    return 'payment';
  } else if(customer.fulfillment_status === 'ready'){
    return 'packaging';
  } else if(customer.order){
    const method = methods.getOrderFulfillmentOptions().find(m => customer.order.fulfillment_method == m.fulfillment_method &&
        Boolean(m.order_sources.find(source => source == customer.order.order_source)));
    return method.value;
  }else if(!customer.order_id){
    return 'in_store';
  }else{
    return false;
  }

};

export const getDetailedQueueCustomers = createSelector (
  [getCurrentUser, getCustomers, getImages, getOrders, getConsumers],
  (currentUser, customers, images, orders, consumers) => {
    if(customers) {
      return customers.map(customer => {
        const order = customer.order_id
            ? orders.reduce((previous, current) => current.id === customer.order_id ? current : previous, null)
            : null;
        const image_file = images.find(image => image.id === customer.image_file_id);
        const consumer = consumers.find(consumer => consumer.id === customer.consumer_id);
        const prefersCurrentUser = (currentUser.id === customer.preferred_csr);
        return {...customer, image_file, order, prefersCurrentUser, gender: consumer && consumer.gender, goes_by: consumer && consumer.goes_by};
      });
    }
    return [];
  });


export const filterObjects = createSelector(getCustomerFilters, filters => filters.map(filter => {
  return {[filter.field]:filter.value};
}));

export const filteredCustomers = createSelector([getCustomers, filterObjects], (customers, filters) => {
  if(filters.length){
    return union(...filters.map(filterObj => filter(customers, filterObj)));
  }else{
    return customers;
  }
});

export const customersWithOrder = createSelector(
  getCustomers, getOrders, (customers, orders) => customers.map(customer => {
    return Object.assign({}, customer, {
      order: customer.order_id
        ? orders.reduce((previous, current) => current.id === customer.order_id ? current : previous, null)
        : null
    });
  })
);

export const customersWithOrderOfType = createSelector(
  [getOrderType, getUsageType, getDetailedQueueCustomers, getCustomCheckinForm],
  (orderType, usageType, customers, customCheckinForm) => customers.filter(customer => {
    const customerOrderTypeValue = customerOrderType(customer, customCheckinForm);
    const isCurbside = (customerOrderTypeValue === 'curbside' && orderType === 'pickup');
    if (!isCurbside) {
      if (orderType != customerOrderTypeValue) {
        return false;
      }
    }

    switch (usageType.usageType) {
    case consumerTypes.RECREATIONAL:
      return consumerTypes.RECREATIONAL === (customer.type || customer.consumer_type);
    case consumerTypes.MEDICAL:
      return consumerTypes.MEDICAL === (customer.type || customer.consumer_type);
    default:
      return true;
    }
  })
);

export const getFulfillmentOptions = createSelector(getOrderType,
  type => methods.getOrderFulfillmentOptions().find(option => option.value === type ));
