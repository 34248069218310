import {change, formValueSelector, touch} from 'redux-form';
import get from 'lodash.get';
import * as types from '../../constants/actionTypes';
import * as dataNames from '../../constants/dataNames';
import * as messageTypes from '../../constants/messageTypes';
import {createTransfer, modifyTransfer} from '../../constants/formNames';
import {getDistributionSettings} from '../../selectors/transfersSelectors';
import {getAvailableQuantityFromPackage} from '../../selectors/forms/salesOrderFormSelectors';
import {getIntegrationState} from '../../selectors/integration/integrationSelectors';
import {isMedicatedOrder} from '../../selectors/salesOrdersSelectors';
import {isField, lineFieldString} from './salesOrderFormMiddleware';
import getFormArrayIndexFromString from '../../util/formHelpers';
import {WA_OTHER_MATERIAL} from '../../constants/integration/leafWa/leafWaInventoryTypes';
import {getProductionRunPackageCodes} from '../../selectors/productionRunsSelectors';
import {addMessage} from '../../actions/systemActions';

import PackageStore from '../../selectors/inventory/package';

const salesTransferForm = store => next => action => {
  const package_store = new PackageStore(store.getState());

  const result = next(action);
  const {meta, type, payload} = action;
  if(meta && [createTransfer, modifyTransfer].indexOf(meta.form) !== -1){

    if(type === types.REDUX_FORM_BLUR){
      if (meta.field.endsWith('.unit_price')) {
        //Lets update the Price Per field if it is not nicely formatted with dollars and cents when we click out of it.
        const state = store.getState();
        const selector = formValueSelector(meta.form);
        const temp = meta.field.split('.');
        const prefix = temp.shift();
        const unitPrice = parseFloat(selector(state, `${prefix}.unit_price`));

        store.dispatch(change(meta.form, `${prefix}.unit_price`, unitPrice.toFixed(2)));
      }
    }

    if(type === types.REDUX_FORM_CHANGE){
      const state = store.getState();
      // Populate driver fields by lookup for create and modify forms
      if(meta.field.indexOf('drivers') !== -1 && meta.field.indexOf('.id') !== -1){
        const key = meta.field.split('.').shift();
        const driver = store.getState()[dataNames.drivers].find((driver) => driver.id === payload);
        const getDataByFormField = {
          state_license_number: 'state_compliance_number',
          license_number: 'drivers_license_number',
          phone_number: (driver) => driver.driver_phones && Array.isArray(driver.driver_phones)
            ? driver.driver_phones.length === 0 ? '' : driver.driver_phones[0].number
            : '',
          name: (driver) => `${driver.first_name} ${driver.last_name}`,
          type: 'Unknown', //TODO: Figure out what this is supposed to be
        };

        const setVehicleInformation = (id) => {
          const vehicle = store.getState()[dataNames.vehicles].find(v => v.id === id);
          store.dispatch(change(meta.form, 'vehicle_id', id));
          store.dispatch(change(meta.form, 'vehicle_make', vehicle ? vehicle.make : null));
          store.dispatch(change(meta.form, 'vehicle_model', vehicle ? vehicle.model : null));
          store.dispatch(change(meta.form, 'vehicle_license_plate', vehicle ? vehicle.license_number : null));
        };

        if(driver){
          Object.keys(getDataByFormField).forEach((field) => {
            const value = typeof getDataByFormField[field] === 'function'
              ? getDataByFormField[field](driver)
              : driver[getDataByFormField[field]];
            store.dispatch(change(meta.form, `${key}.${field}`, value ? value : ''));
            setVehicleInformation(driver.default_vehicle_id);
          });
        } else {
          Object.keys(getDataByFormField).forEach((field) => {
            store.dispatch(change(meta.form, `${key}.${field}`, null));
          });
          setVehicleInformation(null);
        }
      }

      /***
       * Common functions for package code updates
       */

      const getTransfer = () => {
        return store.getState().form[meta.form].values;
      };

      const getSubItems = () => {
        return store.getState().form[meta.form].initial;
      };

      const isCreate = getTransfer().transferId === 0;
      const type = isCreate ? 'create' : 'modify';

      const selector = formValueSelector(meta.form);

      const isPackageCodeValid = (packageCode) => {
        if(typeof packageCode !== 'string') return false;
        return (packageCode.trim() !== '');
      };

      const updateLineItemPrice = (index, line) => {

        const state = store.getState();
        const selector = formValueSelector(meta.form);
        const temp = meta.field.split('.');
        const prefix = temp.shift();

        const isEditingLineItemPrice = selector(state,`${prefix}.editableLinePrice`);

        if (!isEditingLineItemPrice) {
          const newLineItemPrice = parseFloat(get(line, 'ordered_qty')) * parseFloat(get(line, 'unit_price'));
          store.dispatch(change(meta.form, lineFieldString(index, 'line_item_price'), newLineItemPrice));
        }
      };

      const getUpdatedLines = (lineIndex = false, field = false, value = false) => {
        const lines = selector(state, 'lines').map((line) => line);
        lines[lineIndex] = Object.assign({}, lines[lineIndex], {[field]: value});
        return lines;
      };

      const isPrepack = line => get(line, 'line_type', false) === 'prepack';

      // Line Item Changes
      if(isField(meta.field, 'unit_price')){
        const index = getFormArrayIndexFromString(meta.field);
        const lines = getUpdatedLines(index, 'unit_price', payload);
        const line = lines[index];
        if (!isPrepack(line)) {
          updateLineItemPrice(index, line);
        }
      }

      /***
       * Handle Scanning on Transfer Form (create or modify)
       */
      if(meta.field === 'processScan'){

        const getScannedPackage = () => {
          const packageCode = selector(store.getState(), 'scannedPackageCode');
          const state = store.getState();
          const productionRunPackageCodes = getProductionRunPackageCodes(state);

          if (productionRunPackageCodes.includes(packageCode)) {
            store.dispatch(addMessage(messageTypes.warning,['cart.getScannedItem.notFound', {packageId: packageCode}]));
          }

          return packageCode ? package_store.findTransferPackageByField(packageCode, 'package_code', type) : false;
        };

        // returns index as a string or boolean false
        const getReduxLineAndInventoryKey = (scannedPackage) => {
          return getTransfer().lines.reduce((acc, line, index) => {
            if(acc) return acc;
            if(scannedPackage.item_master_parent_id !== null){ // handle prepacks
              if(line.item_master_id === scannedPackage.item_master_parent_id){
                const subitems = getSubItems().lines[index].subitems;
                const subitem = subitems.find((sub) => sub.item_master_id === scannedPackage.item_master_id);
                const subIndex = subitems.filter(sub => sub.id).indexOf(subitem);
                return `lines[${index}].inventory[${subIndex}]`;
              }
            }
            if(line.item_master_id === scannedPackage.item_master_id){ // handle bulk and discrete
              const packageCodeAlreadyPresent = line.inventory.reduce((acc, item) => {
                if(acc) return acc;
                if(item.package_code === scannedPackage.package_code) return true;
              }, false);
              if(packageCodeAlreadyPresent) return false;
              // Get the first available index knowing it could be after all of them
              const inventoryIndex = line.inventory.reduce((acc, item, idx) => {
                if(acc !== null) return acc;
                if(!isPackageCodeValid(item.package_code)) return idx;
                return acc;
              }, null);
              return `lines[${index}].inventory[${(inventoryIndex !== null) ? inventoryIndex : line.inventory.length}]`;
            }
          }, false);
        };

        const setSelectedTransferPackageByScannedPackage = () => {
          const scannedPackage = getScannedPackage();

          if (scannedPackage) {
            const reduxKey = getReduxLineAndInventoryKey(scannedPackage);

            if (reduxKey) return store.dispatch(change(meta.form, `${reduxKey}.package_code`, scannedPackage.package_code, true));
          }
          return false;
        };

        /***
         * MAIN
         */
        if(!setSelectedTransferPackageByScannedPackage()){
          //@TODO: Dispatch message that a product line was not found for the scanned code
        }

        // Reset to empty string when done.
        store.dispatch(change(meta.form, `scannedPackageCode`, ''));

      }

      /***
       * Handle change of package_code (scan or select from drop down)
       */

      if(meta.field.indexOf('package_code') !== -1) {
        const getHybridLocationId = (item) => {
          const packagesFound = package_store.getPackagesFound();
          if(packagesFound.length > 1) return null;
          return `${item.id}_${item.inventory_location_id}`;
        };

        const fieldMap = { // keys = destination field, values = source field
          hybrid_location_id: getHybridLocationId,
          state_integration_tracking_id: 'state_integration_tracking_id',
          uom: 'uom',
          gross_weight_uom: 'uom',
          lot_number: 'lot_number',
          integration_type : 'integration_type',
        };

        const fieldKey = meta.field.replace('.package_code', '');
        const index = getFormArrayIndexFromString(meta.field);
        const lines = selector(state, 'lines').map((line) => line);
        const line = lines[index];

        const updatePackageFields = (packageCode) => {
          const transfer = getTransfer();
          const selectedPackage = package_store.findTransferPackageByField(packageCode, 'package_code', type);
          if(selectedPackage){
            Object.keys(fieldMap).forEach((destinationField) => {
              const sourceField = fieldMap[destinationField];
              const value = (typeof sourceField === 'function')
                ? sourceField(selectedPackage, transfer.transferId)
                : sourceField === null
                  ? null
                  : selectedPackage[sourceField];
              const field = `${fieldKey}.${destinationField}`;
              store.dispatch(change(meta.form, field, value));
            });

            // If this setting is enable,  just force the Sent Net field to be equal to the Qty Available fiekd
            const distributions_transfer_requires_full_quantity = get(
              getDistributionSettings(state), 'distributions_transfer_requires_full_quantity.value', false
            );
            if (distributions_transfer_requires_full_quantity && line['is_medicated']) {
              store.dispatch(change(meta.form, `${fieldKey}.qty`, selectedPackage['qty']));
            }

            return true;
          }
          return false;
        };

        const clearPackageFields = () => {
          Object.keys(fieldMap).forEach((destinationField) => {
            store.dispatch(change(meta.form, `${fieldKey}.${destinationField}`, null));
          });
          return true;
        };

        /***
         * MAIN
         */
        if(typeof payload === 'string' && payload.trim().length){
          updatePackageFields(payload);
        } else {
          clearPackageFields();
        }
      }

      /***
       * Handle change of hybrid_location_id setting related values
       */

      if(meta.field.indexOf('hybrid_location_id') !== -1){

        const fieldKey = meta.field.replace('.hybrid_location_id', '');
        const fields = ['inventory_item_id', 'inventory_location_id', 'qty_available', 'qty_total', 'integration_type'];

        if (payload === null) {
          fields.forEach((field) => {
            store.dispatch(change(meta.form, `${fieldKey}.${field}`, null));
          });
        } else { // Set related values
          const state = store.getState();
          const {isBiotrack, isWaLeaf} = getIntegrationState(state);
          const isMedicated = isMedicatedOrder(state);
          const packageId = payload.split('_').shift();
          const selectedPackage = package_store.findTransferPackageByField(parseInt(packageId), 'id', type);
          const transfer = getTransfer();
          const qtyAvailable = getAvailableQuantityFromPackage(selectedPackage, transfer.transferId);
          const index = getFormArrayIndexFromString(meta.field);
          const lines = selector(state, 'lines').map((line) => line);
          const line = lines[index];

          const inventory = get(line, 'inventory', []).find(
            inventory => inventory.item_master_id === selectedPackage.item_master_id
          ) || {};

          if(qtyAvailable < inventory.qty) {
            store.dispatch(touch(meta.form, `${fieldKey}.qty`));
          }

          store.dispatch(change(meta.form, `${fieldKey}.qty_available`, qtyAvailable));
          store.dispatch(change(meta.form, `${fieldKey}.lot_number`, selectedPackage.lot_number));
          store.dispatch(change(meta.form, `${fieldKey}.inventory_item_id`, payload.split('_').shift()));
          store.dispatch(change(meta.form, `${fieldKey}.inventory_location_id`, payload.split('_').pop()));
          store.dispatch(change(meta.form, `${fieldKey}.uom`, selectedPackage.uom));
          store.dispatch(change(meta.form, `${fieldKey}.gross_weight_uom`, selectedPackage.uom));
          store.dispatch(change(meta.form, `${fieldKey}.state_integration_tracking_id`, selectedPackage.state_integration_tracking_id));
          if(isWaLeaf) {
            const isForExtraction = selectedPackage.integration_type !== undefined && selectedPackage.integration_type === WA_OTHER_MATERIAL;
            store.dispatch(change(meta.form, `${fieldKey}.integration_type`, selectedPackage.integration_type));
            store.dispatch(change(meta.form, `${fieldKey}.is_for_extraction`, isForExtraction));
          }
          if (isBiotrack && isMedicated) {
            store.dispatch(change(meta.form, `${fieldKey}.qty`, qtyAvailable));
            store.dispatch(touch(meta.form, `${fieldKey}.qty`));
            store.dispatch(change(meta.form, `${fieldKey}.qty_total`, selectedPackage.qty));
          }
        }
      }
    }
  }
  return result;
};

export default salesTransferForm;
