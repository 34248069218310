import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

const RestockFooter = (props) => {

  const {integrationState: {isBiotrack, isMetrc}, queue, refundOrder, isRefundAndRestockUnificationToggled} = props;
  const showButton = isBiotrack && (queue.length > 0 || (refundOrder && refundOrder.products && refundOrder.products.length > 0));

  const getQuantity = () => {
    return props.queue.reduce( (acc, item) => {
      acc = acc + parseFloat(item.quantity);
      return acc;
    }, 0).toFixed(2);
  };

  return (
    <tfoot>
    {
      queue.length === 0
        ? null
        : (
        <tr>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
          <th style={{textAlign: 'right'}}>{getQuantity()}</th>
        </tr>
      )
    }
    {
      isRefundAndRestockUnificationToggled || queue.length === 0 || isBiotrack || (isMetrc && props.refundOrderInitiated)
        ? null
        : (
        <tr>
          <th colSpan={3}>
            <button
              className='btn btn-primary btn-block'
              onClick={(event) => { props.onApplyRestock(event);}}>
              {!isMetrc  ? I18n.t('cart.totals.completeRestock') : I18n.t('cart.totals.completeRestockOnly')}
            </button>
          </th>
        </tr>
      )
    }
    {showButton && !isRefundAndRestockUnificationToggled ?
      <tr>
        <th colSpan={3}>
          <button
            className='btn btn-primary btn-block'
            onClick={(event) => { props.onApplyRestock(event);}}>
            {I18n.t('cart.totals.completeRefundAndRestock')}
          </button>
        </th>
      </tr>
      : null
    }
    </tfoot>
  );

};

RestockFooter.propTypes = {
  onApplyRestock: PropTypes.func.isRequired,
  queue: PropTypes.array.isRequired,
  onApplyClick: PropTypes.func,
  refundOrder: PropTypes.object,
  integrationState: PropTypes.object.isRequired,
  isRefundAndRestockUnificationToggled: PropTypes.bool.isRequired,
};


export default RestockFooter;
