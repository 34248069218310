import { I18n } from 'react-redux-i18n';
import {requiredFieldValidation, integerFieldValidation} from '../../../common/form/redux-form/validations';

export const validateProcessingTypeForm = values => {
  const errors = {};
  errors.name = requiredFieldValidation(values.name, 'ei.processingTypes.form.name');
  errors.starting_phase_ids = requiredFieldValidation((values.starting_phase_ids || []).length, 'ei.processingTypes.form.startingPhaseName');
  errors.completion_phase_id = requiredFieldValidation(values.completion_phase_id, 'ei.processingTypes.form.processCompletionsPhase');
  const outputSubcategoriesLength = (values.subcategories_output || []).filter(subcategory => subcategory.checked).length;
  const inputSubcategoriesLength = (values.subcategories_input || []).filter(subcategory => subcategory.checked).length;
  if (!outputSubcategoriesLength) {
    errors.subcategories_output = {};
    errors.subcategories_output._error = I18n.t('ei.processingTypes.form.subcategoriesBlockError');
  }
  if (!inputSubcategoriesLength) {
    errors.subcategories_input = {};
    errors.subcategories_input._error = I18n.t('ei.processingTypes.form.subcategoriesBlockError');
  }
  if (+values.default_processing_time) {
    errors.default_processing_time = !integerFieldValidation(+values.default_processing_time) ? I18n.t('ei.processingTypes.form.defaultProcessingTimeInvalid') : '';
  }
  return errors;
};

export default {
  validateProcessingTypeForm,
};
