import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FieldArray, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {FaMinus} from 'react-icons/fa';
import { TAX_PROFILE_FORM } from '../../../../../constants/forms';
import ConnectedTaxGroupItems from './TaxGroupItems';
import InternationalCurrencyStatic from '../../../../common/form/InternationalCurrencyStatic';

const TaxGroup = (props) => {
  const {idx, onRemoveGroup, showDivider, group, subtax} = props;

  const getFloat = (value) => {
    if(!value || isNaN(parseFloat(value))) return 0;
    return parseFloat(value);
  };

  if(!group) return null; // Is undefined on early pass

  return (
    <div className='tax-group'>

      <table className='table table-bordered table-striped table-sm'>
        <thead>
        {subtax ? (
          <Fragment>
            <tr>
              <td colSpan={3} style={{textAlign: 'right', borderLeftWidth: '0'}}>
                <div style={{float: 'left', display: !props.hideTaxGroupName ? 'block' : 'none'}} >
                  <FaMinus
                    className='text-danger'
                    style={{marginRight: '12px', cursor: 'pointer'}}
                    onClick={() => {
                      onRemoveGroup(idx);
                    }}
                  />
                  <strong>
                    {I18n.t('taxProfiles.form.taxGroupName', {number: idx + 1})}
                  </strong>
                </div>
                <div style={{float: 'right'}}>
                  {
                    props.hideTaxGroupName
                      ? I18n.t(`taxProfiles.form.${props.inboundLabel ? props.inboundLabel : 'subTotalAmountNoGroups'}`)
                      : idx === 0
                        ? I18n.t('taxProfiles.form.subTotalAmountTaxGroupOne')
                        : I18n.t('taxProfiles.form.subTotalAmount')
                  }
                  :</div>
              </td>
              <td style={{width: '25%', textAlign: 'right'}} className='border-cell'><InternationalCurrencyStatic>{getFloat(group.inboundValue).toFixed(2)}</InternationalCurrencyStatic></td>
            </tr>
            <tr>
              <td colSpan={3} style={{textAlign: 'right', borderLeftWidth: '0'}}>
                <div style={{float: 'left', display: !props.hideTaxGroupName ? 'block' : 'none'}} >
                  <FaMinus
                    className='text-danger'
                    style={{marginRight: '12px', cursor: 'pointer'}}
                    onClick={() => {
                      onRemoveGroup(idx);
                    }}
                  />
                  <strong>
                    {I18n.t('taxProfiles.form.taxGroupName', {number: idx + 1})}
                  </strong>
                </div>
                <div style={{float: 'right'}}>
                  Sub total
                  :</div>
              </td>
              <td style={{width: '25%', textAlign: 'right'}} className='border-cell'><InternationalCurrencyStatic>{getFloat(100).toFixed(2)}</InternationalCurrencyStatic></td>
            </tr>
          </Fragment>
        ) : (
          <tr>
            <td colSpan={3} style={{textAlign: 'right', borderLeftWidth: '0'}}>
              <div style={{float: 'left', display: !props.hideTaxGroupName ? 'block' : 'none'}} >
                <FaMinus
                  glyph='minus-sign'
                  className='text-danger'
                  style={{marginRight: '12px', cursor: 'pointer'}}
                  onClick={() => {
                    onRemoveGroup(idx);
                  }}
                />
                <strong>
                  {I18n.t('taxProfiles.form.taxGroupName', {number: idx + 1})}
                </strong>
              </div>
              <div style={{float: 'right'}}>
                {
                  props.hideTaxGroupName
                    ? I18n.t(`taxProfiles.form.${props.inboundLabel ? props.inboundLabel : 'subTotalAmountNoGroups'}`)
                    : idx === 0
                      ? I18n.t('taxProfiles.form.subTotalAmountTaxGroupOne')
                      : I18n.t('taxProfiles.form.subTotalAmount')
                }
                :</div>
            </td>
            <td style={{width: '25%', textAlign: 'right'}} className='border-cell'><InternationalCurrencyStatic>{getFloat(group.inboundValue).toFixed(2)}</InternationalCurrencyStatic></td>
        </tr>
        )
      }
          <tr style={{fontWeight: 'bold'}}>
            <td style={{width: '30px'}} />
            <td>{I18n.t('taxProfiles.form.taxRateName')}</td>
            <td>{I18n.t('taxProfiles.form.taxPercent')}</td>
            <td style={{textAlign: 'right'}}>{I18n.t('taxProfiles.form.sampleTaxAmount')}</td>
          </tr>
        </thead>
        <FieldArray name={`${props.groupKey}.items`} component={ConnectedTaxGroupItems} props={{groupKey: props.groupKey}} />
        <tfoot>
        <tr>
          <td colSpan={3} style={{textAlign: 'right', borderLeftWidth: '0', borderBottomWidth: '0'}}>
            {
              props.hideTaxGroupName
                ? props.totalLabel ? I18n.t(`taxProfiles.form.${props.totalLabel}`) : I18n.t('taxProfiles.form.taxedResultGrandTotal')
                : I18n.t('taxProfiles.form.taxedResult')
            }:
          </td>
          <td style={{width: '25%', textAlign: 'right'}} className='border-cell'><InternationalCurrencyStatic>{getFloat(group.outboundValue).toFixed(2)}</InternationalCurrencyStatic></td>
        </tr>
        </tfoot>
      </table>
      {showDivider ? <hr style={{marginTop: '40px'}} /> : null}
    </div>
  );
};

TaxGroup.propTypes = {
  idx: PropTypes.number.isRequired,
  onRemoveGroup: PropTypes.func.isRequired,
  showDivider: PropTypes.bool.isRequired,
  subtax: PropTypes.bool,
  groupKey: PropTypes.string.isRequired,
  group: PropTypes.object,
  hideTaxGroupName: PropTypes.bool,
  inboundLabel: PropTypes.string,
  totalLabel: PropTypes.string,
};

const selector = formValueSelector(TAX_PROFILE_FORM);

function mapStateToProps(state, ownProps){
  return {
    group: selector(state, `${ownProps.groupKey}`),
    items: selector(state, `${ownProps.groupKey}.items`)
  };
}

export default connect(mapStateToProps)(TaxGroup);
