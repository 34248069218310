import React from 'react';
import {getFormValues, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {PRODUCT_MASTER_FORM} from '../../../constants/forms';
import validate from './validate';
import {isMedicatedWeightRequired, getCurrentItemMaster} from '../../../selectors/forms/productFormSelectors';
import ProductPanel from './panels/ProductPanel';
import PricingPanel from './panels/PricingPanel';
import ActionsPanel from './panels/ActionsPanel';
import MenuPanel from './panels/MenuPanel';
import TestingPanel from './panels/TestingPanel';
import InfoPanel from './panels/InfoPanel';
import TaxesPanel from './panels/TaxesPanel';

const ProductMasterForm = (props) => {
  const {
    handleSubmit,
    formValues,
    isWaInventoryMapped,
    productTypeOptions,
    categories,
    subcategories,
    subCategoryMapErrors,
    onSubcategoryChange,
    integrationState,
    isMasterFacility,
    allUoms,
    uoms,
    medicatedWeightRequired,
    brands,
    change,
    isDirty,
    isValid,
    itemMaster,
    isMedicatedWeightAutoSuffix,
    isAppendBrandToItemMasterName
  } = props;

  const categoryId = get(formValues, 'category_id', 1);
  const selectedCategory = categories.find((category) => category.id === categoryId);
  const selectedSubCategory = selectedCategory && selectedCategory.subcategories.find((subcategory) => subcategory.id === get(formValues, 'subcategory_id'));

  const {isPaLeaf, isUtah} = integrationState;

  return (
    <form className='product-form' onSubmit={handleSubmit}>
      <ProductPanel
        formValues={formValues}
        isWaInventoryMapped={isWaInventoryMapped}
        productTypeOptions={productTypeOptions}
        categories={categories}
        subcategories={subcategories}
        subCategoryMapErrors={subCategoryMapErrors}
        selectedSubCategory={selectedSubCategory}
        onSubcategoryChange={onSubcategoryChange}
        integrationState={integrationState}
        isMasterFacility={isMasterFacility}
        allUoms={allUoms}
        uomOptions={uoms}
        brands={brands}
        change={change}
        medicatedWeightRequired={medicatedWeightRequired}
        isMedicatedWeightAutoSuffix={isMedicatedWeightAutoSuffix}
        isAppendBrandToItemMasterName={isAppendBrandToItemMasterName}
      />
      <PricingPanel
        formValues={formValues}
        change={change}
      />
      <TaxesPanel
        formValues={formValues}
      />
      <MenuPanel
        formValues={formValues}
        integrationState={integrationState}
        brands={brands}
        change={change}
      />
      {/* Testing panel is not relevant for PA and Utah */}
      {!isPaLeaf && !isUtah &&
        <TestingPanel
          formValues={formValues}
          change={change}
        />
      }
      <InfoPanel
        formValues={formValues}
      />
      <ActionsPanel
        formValues={formValues}
        change={change}
        isDirty={isDirty}
        isValid={isValid}
        itemMaster={itemMaster}
      />
    </form>
  );
};

ProductMasterForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  isWaInventoryMapped: PropTypes.bool.isRequired,
  productTypeOptions: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  subcategories: PropTypes.array.isRequired,
  subCategoryMapErrors: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
  onSubcategoryChange: PropTypes.func.isRequired,
  integrationState: PropTypes.object,
  isMasterFacility: PropTypes.bool.isRequired,
  allUoms: PropTypes.array.isRequired,
  uoms: PropTypes.array.isRequired,
  brands: PropTypes.array.isRequired,
  medicatedWeightRequired: PropTypes.bool,
  itemMaster: PropTypes.object,
  isMedicatedWeightAutoSuffix: PropTypes.bool.isRequired,
  isAppendBrandToItemMasterName: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  isDirty: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    formValues: getFormValues(PRODUCT_MASTER_FORM)(state) || ownProps.initialValues,
    medicatedWeightRequired: isMedicatedWeightRequired(state, {form: PRODUCT_MASTER_FORM}),
    itemMaster: getCurrentItemMaster(state),
  };
}

const ReduxProductMasterForm = reduxForm({
  form: PRODUCT_MASTER_FORM,
  validate,
})(ProductMasterForm);

export default connect(mapStateToProps)(ReduxProductMasterForm);

