import React from 'react';
import PropTypes from 'prop-types';
import {reduxForm, Field} from 'redux-form';
import {Button} from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import TextInput from '../../common/form/TextInput';

const ResetPasswordForm = ({handleSubmit}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Field name='name' component={TextInput}
        props={{
          label: I18n.t('user.password.name'),
          required: true
        }}
      />
      <Button type='submit' variant='primary' block>
        {I18n.t('user.password.send')}
      </Button>
    </form>
  );
};

ResetPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'requestPassword'
})(ResetPasswordForm);
