import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Row, Col} from 'react-bootstrap';
import ImportOrdersViewTableOrderInfoProducts from './ImportOrdersViewTableOrderInfoProducts';

class ImportOrdersViewTableOrderInfo extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
  }

  render() {
    const order = this.props.order;
    return (
      <div className='child-section col-md-12'>
        <Row>
          <Col md={12}>
            <div style={{fontWeight: 'bold', marginBottom: '0.5rem'}}>
              {order.orderNumber ? I18n.t('importOrders.viewTable.innerTable.productsIn') + ': #' + order.orderNumber : ''}
            </div>
            <table className='table table-bordered table-striped'>
              <thead>
              <tr>
                <th>{I18n.t('importOrders.viewTable.innerTable.product')}</th>
                <th>{I18n.t('importOrders.viewTable.innerTable.itemMasterId')}</th>
                <th>{I18n.t('importOrders.viewTable.innerTable.packageId')}</th>
                <th>{I18n.t('importOrders.viewTable.innerTable.unit')}</th>
                <th>{I18n.t('importOrders.viewTable.innerTable.location')}</th>
                <th>{I18n.t('importOrders.viewTable.innerTable.price')}</th>
                <th>{I18n.t('importOrders.viewTable.innerTable.note')}</th>
              </tr>
              </thead>

              <ImportOrdersViewTableOrderInfoProducts
                products={order.products}
              />

              <thead>
              <tr>
                <th colSpan={5} className={'text-right'}>{I18n.t('importOrders.viewTable.innerTable.subTotal')}</th>
                <td>${order.subtotal}</td>
                <td/>
              </tr>
              <tr>
                <th colSpan={5} className={'text-right'}>{I18n.t('importOrders.viewTable.innerTable.taxes')}</th>
                <td>
                  {order.taxes.map((tax, i) =>
                    (<div key={`tax-${i}`}>
                      <span>{I18n.t('cart.totals.taxDetailed', {name: tax.tax_name, rate: (parseFloat(tax.rate) * 100).toFixed(2) })}</span>{' '}
                      <span>${tax.amount.toFixed(2)}</span>
                    </div>)
                  )}
                </td>
                <td/>
              </tr>
              <tr>
                <th colSpan={5} className={'text-right'}>{I18n.t('importOrders.viewTable.innerTable.total')}</th>
                <td>${order.totalOrderPrice}</td>
                <td/>
              </tr>
              </thead>

            </table>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <div style={{fontWeight: 'bold', marginBottom: '0.5rem', marginTop: '30px'}}>
              {I18n.t('importOrders.viewTable.innerTable.payments')}
            </div>

            <table className='table table-bordered'>
              <thead>
              <tr>
                <th>{I18n.t('importOrders.viewTable.innerTable.paymentType')}</th>
                <th>{I18n.t('importOrders.viewTable.innerTable.amount')}</th>
              </tr>
              </thead>
              <tbody>
              {order.payments.map((p, index) => {
                return (
                  <tr key={index}>
                    <td>{p.type}</td>
                    <td>${p.amount}</td>
                  </tr>
                );
              })}
              </tbody>
            </table>

          </Col>
        </Row>

      </div>

    );
  }
}

ImportOrdersViewTableOrderInfo.propTypes = {
  order: PropTypes.array.isRequired,
};

export default ImportOrdersViewTableOrderInfo;
