import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Col, Row, Button} from 'react-bootstrap';
import { FaSave, FaTimes, FaMinus } from 'react-icons/fa';
import {FILE_TYPES, types} from '../../../../../../constants/fileUploads';
import FileInput from '../../../../../common/form/FileInput';
import TextInput from '../../../../../common/form/TextInput';
import AddItem from '../../../../../common/form/AddItem';
import {inventory_receipt_document} from '../../../../../../constants/fileTypes';
import * as itemNames from '../../../../../../constants/itemNames';

class ReceiptFilesComponent extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      uploading: {},
    };
    this.changeUploadStatus = this.changeUploadStatus.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
  }

  changeUploadStatus(key, status) {
    this.setState(state => ({
      uploading: {...state.uploading, [key]: status},
    }));
  }

  deleteFile(id, fieldName, type) {
    const uri = type === types.image ? 'images' : 'documents';
    return this.props.actions
      .deleteItem(
        `/api/${uri}/${id}`,
        null,
        null,
        {id}
      )
      .then(() => {
        if (fieldName) {
          this.props.change(`${fieldName}.file_id`, null);
          this.props.change(`${fieldName}.file`, null);
        }
      });
  }

  render() {
    const {fileOptions, groupedFields, fields, change, locked} = this.props;
    const {uploading} = this.state;

    return (
      <div className='object-files'>
        <div className='files-groups'>
          {groupedFields.map((group, groupIndex) => (
            <div className='file-group' key={groupIndex}>
              <h5>{group.name}</h5>
              {group.fields.length ? null : <div className='alert alert-warning'>
                {I18n.t('common.objectFile.noFilesAdded')}
              </div>}
              {group.fields.map((field) => {
                const {fieldName, fieldIndex, file} = field;
                const hasFile = Boolean(file.file && file.file.url);
                return (
                  <div className='object-file layout-row layout-align-start-start' key={fieldIndex}>
                    <div className={`file-thumb ${hasFile ? ' active' : ''}`}>
                    {
                      hasFile ?
                        <div>
                          <a href={file.file.url.original || file.file.url} target='_blank' rel='noopener noreferrer'
                            title={I18n.t('common.objectFile.downloadFile')}>
                            <FaSave className='thumb' />
                          </a>
                          <FaTimes
                            disabled={locked}
                            className='remove-button'
                            onClick={() => this.deleteFile(file.file_id, fieldName, FILE_TYPES[group.file_type].type)}
                            title={I18n.t('common.objectFile.removeFile')}
                          />
                        </div>
                        : <FaSave className='thumb' />
                    }
                    </div>

                    <div>
                      <Field
                        name={`${fieldName}.document`}
                        itemName={itemNames.document}
                        component={FileInput}
                        props={{
                          ...FILE_TYPES[group.file_type],
                          itemName: null,
                          disabled: uploading[fieldName] || locked,
                          btnContent: uploading[fieldName] ? I18n.t('common.form.uploading') : I18n.t('common.objectFiles.uploadFile'),
                          onUploadStart: () => this.changeUploadStatus(fieldName, true),
                          onChangeCb: (f) => {
                            change(`${fieldName}.file_id`, f.id);
                            change(`${fieldName}.file`, f);
                            this.changeUploadStatus(fieldName, false);
                          },
                          btnProps: {
                            className: 'btn btn-default',
                          },
                        }}/>
                    </div>

                    <div className='flex-30 field'>
                      <Field name={`${fieldName}.name`} component={TextInput} props={{
                        label: group.fieldLabel,
                        placeholder: I18n.t('common.objectFiles.fileName'),
                        disabled: locked,
                      }}/>
                    </div>

                    <div className='remove-row-button'>
                      <Button
                        variant='primary'
                        size='sm'
                        onClick={() => fields.remove(fieldIndex)}
                        disabled={!!file.file_id || locked}
                      >
                        <FaMinus />
                      </Button>
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
        </div>

        <Row>
          <Col md={4}>
            <AddItem
              addDisabled={locked}
              text={I18n.t('common.objectFiles.addFile')}
              addAction={() => {
                const type = inventory_receipt_document;
                const selectedOption = fileOptions.find(option => option.type === type);
                fields.push({
                  type,
                  file_type: FILE_TYPES[selectedOption.file_type].type,
                  file: null,
                });
              }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

ReceiptFilesComponent.propTypes = {
  actions: PropTypes.shape({
    deleteItem: PropTypes.func.isRequired,
  }).isRequired,
  fileOptions: PropTypes.array.isRequired,
  groupedFields: PropTypes.array.isRequired,
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
  }).isRequired,
  change: PropTypes.func.isRequired,
  types: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  locked: PropTypes.bool.isRequired,
};

export default ReceiptFilesComponent;
