import PropTypes from 'prop-types';
import { reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { CUPO_FORM } from '../../../../constants/forms';
import validate from './validate'; //eslint-disable-line
import { CupoForm } from './CupoForm';

export const CupoFormWrapper = reduxForm({
  form: CUPO_FORM,
  validate,
  enableReinitialize: true
})(CupoForm);

function mapStateToProps(state) {
  return {
    formValues: getFormValues(CUPO_FORM)(state)
  };
}

CupoForm.propTypes = {
  formValues: PropTypes.object
};

export default connect(mapStateToProps)(CupoFormWrapper);
