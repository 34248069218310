import {I18n} from 'react-redux-i18n';

const validate = (values, props) => {
  const errors = {};

  if (Math.abs(parseFloat(values.reset_balance_to)) > 0 && !values.drop_register_id) {
    errors.drop_register_id = I18n.t('retail.registerReconciliation.selectDropRegister');
  }
  if (values.drop_register_id && !values.reset_balance_to) {
    errors.reset_balance_to = I18n.t('retail.registerReconciliation.enterResetBalanceAmount');
  }
  if (Math.abs(parseFloat(values.reset_balance_to)) > 9999999.99) {
    errors.reset_balance_to = I18n.t('retail.registerReconciliation.registerAmountExceed');
  }

  return errors;
};

export default validate;
