import {createSelector} from 'reselect';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import {pick} from 'lodash';
import {customerHistory} from '../constants/dataNames';

const getQualifyingConditions = (state) => state.qualifyingConditions;
// shared delta formatter functions
const deltaFormatters = {
  boolean:  (value) => {
    return value ? I18n.t('general.yes') : I18n.t('general.no');
  },
  address: (value) => {
    const add = pick(value,[
      'street_address_1',
      'street_address_2',
      'city',
      'province_code',
      'postal_code',
      'country'
    ]);
    let addStr = Object.values(add).filter( v => !!v ).join(', ');
    if(!addStr) addStr = '-';
    return addStr;
  }
};

// caregiver field comparison definitions
const caregiverDeltaConfig = {
  _default: {
    formatter: (value) => value || '-',
    match: (a,b) => a === b,
    valueType: 'default'
  },
  email: {
    name: I18n.t('customers.create.caregiverEmail'),
  },
  phone: {
    name: I18n.t('customers.create.caregiverPhone'),
  },
  medical_id: {
    name: I18n.t('customers.create.caregiverMedicalId'),
  },
  state_integration_tracking_id: {
    name: I18n.t('customers.create.caregiverStateId'),
  },
  birth_date: {
    name: I18n.t('customers.create.caregiverBirthDate'),
  },
  first_name: {
    name: I18n.t('customers.create.caregiverFirstName'),
  },
  middle_name: {
    name: I18n.t('customers.create.caregiverMiddleName'),
  },
  last_name: {
    name: I18n.t('customers.create.caregiverLastName'),
  },
  addresses: {
    name: I18n.t('customers.create.caregiverAddr'),
    formatter: deltaFormatters['address'],
    valueType: 'array'
  },
};

// customer field comparison definitions
const customerDeltaConfig = {
  _default: {
    formatter: (value) => value || '-',
    match: (a,b) => a === b,
    valueType: 'default'
  },
  active: {
    name: I18n.t('customers.create.active'),
    formatter: deltaFormatters['boolean'],
  },
  addresses: {
    name: I18n.t('customers.create.address'),
    formatter: deltaFormatters['address'],
    valueType: 'array'
  },
  ids: {
    name: I18n.t('customers.create.documents'),
    formatter: (value) => {
      const id = pick(value,[
        'type',
        'identification_number',
        'active',
        'state',
        'effective_at',
        'expired_at',
      ]);
      let idStr = Object.keys(id).map( field => {
        return field + ': ' + id[field];
      }).join(', ');
      if(!idStr) idStr = '-';
      return idStr;
    },
    valueType: 'array'
  },
  birth_date: {
    name:  I18n.t('customers.create.dateOfBirth'),
  },
  email_address: {
    name:  I18n.t('customers.create.email'),
  },
  first_name: {
    name:  I18n.t('customers.create.firstName'),
  },
  middle_name: {
    name:  I18n.t('customers.create.middleName'),
  },
  last_name: {
    name:  I18n.t('customers.create.lastName'),
  },
  preferred_contact: {
    name:  I18n.t('customers.create.preferredContact'),
  },
  preferred_csr: {
    name:  I18n.t('customers.create.preferredService'),
  },
  gender: {
    name:  I18n.t('customers.create.gender'),
  },
  frequency_of_use: {
    name:  I18n.t('customers.create.frequency'),
  },
  type: {
    name:  I18n.t('customers.create.type'),
  },
  in_state: {
    name:  I18n.t('customers.create.inState'),
    formatter: deltaFormatters['boolean'],
  },
  referral_source_info: {
    name: I18n.t('customers.create.referralSource'),
    formatter: (value) => get(value,'name','-'),
    match: (a,b) => {
      return get(a,'name',null) === get(b,'name',null);
    }
  },
  tax_exempt: {
    name: I18n.t('customers.create.taxExempt'),
    formatter: deltaFormatters['boolean'],
  },
  primary_facility_id: {
    name: I18n.t('customers.create.primaryFacility'),
  },
  date_provider_can_switch: {
    name: I18n.t('customers.create.dateAvailableToSwitch'),
  },
  qualifying_condition_id: {
    name: I18n.t('customers.create.qualifyingCondition'),
  },
  diagnosis: {
    name: I18n.t('customers.create.diagnosis'),
  },
  physician_notes: {
    name: I18n.t('customers.create.physicianNotes'),
  },
  groups: {
    name: I18n.t('customers.create.customerGroups'),
    valueType: 'array',
    formatter: (value) => get(value,'group_name','-'),
  },
  notes: {
    name: I18n.t('customers.create.notes'),
    valueType: 'array',
    formatter: (value) => get(value,'note','-')
  },
  tags: {
    name: I18n.t('customers.create.tags'),
    formatter: (value) => {
      const tags = (value ? value : []).map( t => {
        return get(t,'tag_name',null);
      });
      return tags.join(', ');
    },
    match: (a,b) => {
      const aTags = (a ? a : []).map( t => {
        return get(t,'tag_name',null);
      }).join(', ');
      const bTags = (b ? b : []).map( t => {
        return get(t,'tag_name',null);
      }).join(', ');
      return aTags === bTags;
    }
  },
  caregivers: {
    valueType: 'caregivers'
  },
  facility_sales_limit: {
    name: I18n.t('customers.create.complianceLimits'),
  }
};

// Get unmodified /api/customers/{id}/log results
export const getCustomerHistory = (state) => state[customerHistory];

// Get customer history formatted for CustomerHistoryPage table
export const getCustomerHistoryTableData = createSelector(
  [getCustomerHistory, getQualifyingConditions], (history, qualifyingConditions) => {
    if(!Array.isArray(qualifyingConditions)) qualifyingConditions = [];
    let previousModel = null;
    const rows = history.map( h => {
      let row = null;
      const currentModel = JSON.parse(get(h,'model','null'));
      if (currentModel !== 'null') {
        currentModel['qualifying_condition_id'] = get(qualifyingConditions.find(condition => condition.id === currentModel.qualifying_condition_id), 'name');
      }

      if(h.event_type === 'create_consumer'){
        previousModel = {addresses: [], ids: []};
      }
      if(previousModel){
        const delta = calculateHistoryDelta(previousModel,currentModel,customerDeltaConfig);
        const fieldsChanged = '(' + delta.length + ') ' + delta.map( d => d.field ).join(', ');
        if(delta.length){
          row = {
            id: h.id,
            event_date: h.event_date,
            user_name: h.user_name,
            fields_changed: fieldsChanged,
            change_details: delta
          };
        }
      }
      previousModel = currentModel;
      return row;
    });
    return rows.filter( r => !!r );
  }
);

// Calculate diffs for customer event log
const calculateHistoryDelta = (previous,current,config) => {
  const delta = [];
  const supportedFields = Object.keys(config);

  Object.keys(current)
    .filter( field => supportedFields.indexOf(field) !== -1)
    .forEach( field => {
      const a = get(previous,field,null);
      const b = get(current,field,null);

      const handler = Object.assign(
        {},
        config['_default'],
        config[field]
      );

      if(handler.valueType === 'default'){
        // compare standard single-value fields:
        if(!handler.match(a,b)){
          delta.push({
            field:    handler.name,
            previous: handler.formatter(a),
            current:  handler.formatter(b)
          });
        }
      } else if(handler.valueType === 'array'){
        // compare special nested/array fields:
        b.forEach( currentObj => {
          const currentMatch = handler.formatter(currentObj);
          const previousObj = Array.isArray(a) && a.find( n => n.id === currentObj.id ) || false;
          const previousMatch = previousObj ? handler.formatter(previousObj) : '';
          if(!handler.match(previousMatch,currentMatch)){
            delta.push({
              field:    handler.name,
              previous: previousMatch,
              current:  currentMatch
            });
          }
        });
      } else if(handler.valueType === 'caregivers'){
        // compare caregivers by calculating deltas separately and
        // appending results to parent customer delta set
        b.forEach( currentObj => {
          const previousObj = (Array.isArray(a) && a.find( n => n.id === currentObj.id )) || {addresses: []};
          const cgDelta = calculateHistoryDelta(previousObj, currentObj, caregiverDeltaConfig);
          cgDelta.forEach( d => delta.push(d) );
        });
      }
    });

  return delta;
};
