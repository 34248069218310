/* eslint-disable import/prefer-default-export*/
import {pick} from 'lodash';
import get from 'lodash.get';
import union from 'lodash.union';
import find from 'lodash.find';
import moment from 'moment-timezone';
import {createSelector} from 'reselect';
import {formValueSelector} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {convertDbTimeToClientTzMoment} from '../util/dateHelpers';
import * as itemNames from '../constants/itemNames';
import {getPartnerFacilities, getFacilities} from './facilitiesSelectors';
import {
  getSalesOrders,
  getSalesOrdersTotals,
  getSalesOrdersLines,
  getSalesOrdersDestinations,
  getSalesOrdersIsLab, isMedicatedOrder
} from './salesOrdersSelectors';
import {getTimezone} from './timezoneSelectors';
import {getChildrenByItemMasterId} from './itemMastersSelectors';
import {getInventoryItemsWithReservation, groupedByItemMasterId} from './inventoryItemsSelectors';
import {getFlattenedStorageLocations} from './storageLocationsSelectors';
import * as formNames from '../constants/formNames';
import {
  getCategories,
  getMappedFlowerSubcategoryIds,
  getMappedOtherMaterialSubcategoryIds,
  isCategoryHarvest,
  getMappedFinishFlowerSubcategoryIds,
  isCategoryFlower
} from './categorySelectors';
import {mapDbFieldsToDriversArray, getValidDrivers} from './forms/salesTransferFormSelectors';
import {getIntegrationState} from './integration/integrationSelectors';
import {getAvailableQuantityFromPackage} from './forms/salesOrderFormSelectors';
import {getLocationsForSharedProducts} from './locationsSelectors';
import {
  btProcessorLocationTypes,
  btProducerLocationTypes, btRetailerLocationTypes, BT_PROCESSOR, BT_PRODUCER_TIER_1, BT_RETAILER
} from '../constants/integration/biotrack/biotrackLocationTypes';
import {
  btProcessorInventoryTypes, btLabInventoryTypes,
  btProducerInventoryTypes, btRetailerInventoryTypes
} from '../constants/integration/biotrack/biotrackInventoryTypes';
import * as dataNames from '../constants/dataNames';
import {isMappingRequiredForSupply} from './supplySelectors';
import {roundQty} from './uomsSelectors';
import {convertFromBase} from '../util/uomHelpers';
import * as UOMS from '../constants/uoms';

const getSelectedTransfers = state => state.selectedTransfers;
const getTransfers = (state, activeType) => state[activeType];
const getTransfer = state => state.transfer;
export const getPartnerFacilityDetails = state => state[itemNames.partnerFacilityDetails];
const getChildItemMasters = state => state.childItemMasters;
const getPrepackWeights = state => state.prepackWeights;
const getInventoryItems = state => state.inventoryItems;
const getLabResults = state => get(state, 'inventoryItemsTestResults', []);
const getHarvestBatch = state => state.selectedHarvests[0];
const getItemMastersWeighted = state => state.itemMastersWeighted;
const getCurrentTimezone = state => state.timezone;
const getDrivers = state => state.drivers;
const getVehicles = state => state.vehicles;
const getItemMasters = state => state.itemMasters;
const getPrepackFacilityWeights = state => state.prepackFacilityWeights;

// TODO: clean up related code for leaf transfer status check
// const getDestributionTransferStatus = state => state.leafTransferStatus;
const getCurrentDrivers = (_, props) => props.drivers || [];
const getMmePartnersCertNumber = state => state.mmePartnersCertNumber;
const getIntegratedTransfers = state => state.integrationMapping;
const leafWaHarvestTypes = state => state[dataNames.leafWaHarvestTypes];
export const getDistributionSettings = state => state[itemNames.distributionSettings];
export const getReturnedItems = state => state.returnedItems;

const getTransferItems = createSelector([getTransfer], transfer => {
  const ids = [];
  if (transfer.lines) {
    transfer.lines.map(line => ids.push(...line.inventory.map(inv => inv.inventory_item_id)));
  }
  return ids;
});

const selector = formValueSelector(formNames.createTransfer);
const getCreateTransferFormSelectorValues = state => selector(state, 'lines', 'transfer_fee', 'excise_tax_due', 'partner_discount', 'status', 'move_all_inventory');

const selectorModifyForm = formValueSelector(formNames.modifyTransfer);
const getModifyTransferFormSelectorValues = state => selectorModifyForm(state, 'drivers', 'lines', 'transfer_fee', 'excise_tax_due', 'partner_discount', 'status', 'returnedItems', 'move_all_inventory');
export const moveAllInventoryFormValue = state => selectorModifyForm(state, 'move_all_inventory');

const getOrderPartners = createSelector(
  [getPartnerFacilities, getSalesOrders],
  (partnerFacilities, salesOrders) => salesOrders.map(saleOrder => partnerFacilities.find(pf => pf.id === saleOrder.partner_facility_id))
);
const intersection = (array1, array2) => array1.length ? union(array1.filter(value => -1 !== array2.indexOf(value))) : array2;

const getApplicableBiotrackInventoryTypes = createSelector(
  [getOrderPartners, getFacilities],
  (partners, facilities) => {
    let inventoryTypes = [];
    const btMappingPartner = {
      grow: BT_PRODUCER_TIER_1,
      manufacturing: BT_PROCESSOR,
      dispensary: BT_RETAILER
    };
    partners.map(partner => {
      if (!partner) return;
      let type = partner && Number(partner.state_integration_type);
      if (!type && partner.facility_id) {
        type = btMappingPartner[get(facilities.find(facility => facility.id === partner.facility_id), 'type')];
      }
      if (btProducerLocationTypes.indexOf(type) > -1) {
        inventoryTypes = intersection(inventoryTypes, btProducerInventoryTypes);
      } else if (btProcessorLocationTypes.indexOf(type) > -1) {
        inventoryTypes = intersection(inventoryTypes, btProcessorInventoryTypes);
      } else if (btRetailerLocationTypes.indexOf(type) > -1) {
        inventoryTypes = intersection(inventoryTypes, btRetailerInventoryTypes);
      } else if (get(partner, 'partner.is_lab')) {
        inventoryTypes = intersection(inventoryTypes, btLabInventoryTypes);
      }
    });
    return inventoryTypes;
  }
);

const itemsFilterByIntegrationType = createSelector([getChildrenByItemMasterId, getApplicableBiotrackInventoryTypes, getIntegrationState, getTransferItems],
  (itemMasters, applicableInventoryTypes, integrationState, itemIds) => {
    const {isBiotrack} = integrationState;
    if (isBiotrack) {
      Object
        .keys(itemMasters)
        .map(objKey =>
          itemMasters[objKey] = itemMasters[objKey]
            .map(im => (
              {
                ...im,
                items: im.items.filter(item =>
                  itemIds.indexOf(item.id) !== -1 || applicableInventoryTypes.indexOf(parseInt(item.integration_type)) !== -1)
              }))
        );
    }
    return itemMasters;
  });

const getChildItemMastersWithInventory = createSelector(
  [getChildItemMasters, getSalesOrdersIsLab, getIntegrationState, getApplicableBiotrackInventoryTypes, getTransferItems],
  (childItemMasters, isLab, integrationState, applicableInventoryTypes, transferLines) => {
    if (integrationState.isBiotrack) {
      childItemMasters = childItemMasters
        .map(itemMaster => ({
          ...itemMaster,
          items: (itemMaster.items || [])
            .filter(item => transferLines.indexOf(item.id) !== -1 || applicableInventoryTypes.indexOf(parseInt(item.integration_type)) !== -1)
        }));
    }
    if (!isLab && childItemMasters) {
      return childItemMasters.map(itemMaster => ({
        ...itemMaster,
        items: (itemMaster.items || []).filter(item => !item.on_hold)
      }));
    }
    return childItemMasters;
  }
);

export const getProductsForHarvestBatchFilteredByPhenotypes = createSelector(
  [getHarvestBatch, getItemMastersWeighted, getCategories, getMappedFlowerSubcategoryIds, getMappedOtherMaterialSubcategoryIds, getMappedFinishFlowerSubcategoryIds, leafWaHarvestTypes, getIntegrationState],
  (harvestBatch, itemMastersWeighted, categories, flowerIds, otherMaterialIds, finishFlowerIds, leafWaHarvestTypes, integrationState) => {
    const harvestBatchCategory = categories.find(isCategoryHarvest) || {};
    const flowerCategory = categories.find(isCategoryFlower) || {};
    const itemMasters = itemMastersWeighted.filter(
      im => !im.phenotype_id || !harvestBatch || !harvestBatch.phenotype_id || harvestBatch.phenotype_id === im.phenotype_id
    );
    //Washington inventory types
    const waInventoryHarvestTypes = [];
    for (const index in leafWaHarvestTypes) {
      waInventoryHarvestTypes.push(leafWaHarvestTypes[index].item_master_id);
    }

    return itemMasters.reduce(
      (acc, im) => {
        if (im.category_id === harvestBatchCategory.id) {
          acc.test.push(im);
        } else if(im.category_id === flowerCategory.id) {
          acc.regular.push(im);
        }
        if (flowerIds.indexOf(im.subcategory_id) > -1 || waInventoryHarvestTypes.includes(im.id)) {
          acc.flower.push(im);
        }
        if (otherMaterialIds.indexOf(im.subcategory_id) > -1 || waInventoryHarvestTypes.includes(im.id)) {
          acc.other_material.push(im);
        }
        if (finishFlowerIds.indexOf(im.subcategory_id) > -1) {
          acc.finish_flower.push(im);
        }
        if (integrationState.isWaLeaf && waInventoryHarvestTypes.includes(im.id)) {
          acc.marijuana_mix.push(im);
        }
        return acc;
      },
      {test: [], regular: [], flower: [], other_material: [], finish_flower: [], marijuana_mix: []}
    );
  }
);

export const getTransformedTransfers = createSelector(getPartnerFacilities, getTransfers, getDrivers, getIntegratedTransfers,
  (partnerFacilities, transfers, drivers, integratedTransfers) => {
    return transfers.map(transfer => {

      transfer.statusString = I18n.t('cultivation.transfers.form.' + transfer.status);
      transfer.date_ordered = transfer && transfer.sales_orders && transfer.sales_orders.length && transfer.sales_orders[0].sales_order ? transfer.sales_orders[0].sales_order.date_ordered : '';
      transfer.partner_contact_name = transfer && transfer.sales_orders && transfer.sales_orders.length && transfer.sales_orders[0].sales_order ? transfer.sales_orders[0].sales_order.partner_contact_name : '';
      transfer.partner_contact_phone = transfer && transfer.sales_orders && transfer.sales_orders.length && transfer.sales_orders[0].sales_order ? transfer.sales_orders[0].sales_order.partner_contact_phone : '';

      transfer.order_total = get(transfer, 'sales_orders', []).reduce((acc, order) => {
        const orderTotal = parseFloat(get(order, 'sales_order.order_total', get(order, 'order_total', 0)));
        acc += isNaN(orderTotal) ? 0 : orderTotal;
        return acc;
      }, 0);

      const partner_facility = partnerFacilities.find(partnerFacility => {
        if (partnerFacility && transfer) return partnerFacility.id === transfer.facility_id;
      });
      const driver = drivers.find(driver => driver.id === transfer.driver_id);
      transfer.driver_name = driver ? (driver.is_licensed_transporter ? driver.name : `${driver.first_name} ${driver.last_name}`) : '';
      const leafTransfer = integratedTransfers.find(leafTransfer => parseInt(leafTransfer.internal_identifier) === transfer.id);
      transfer.leaf_global_id = leafTransfer && leafTransfer.external_identifier;
      // Combine leaf_global_id and global_id into a single field for display, sort and search
      transfer.display_global_id = transfer.leaf_global_id || transfer.global_id;
      if (partner_facility && partner_facility.facility_name) {
        return Object.assign({}, transfer, {partner_facility: partner_facility.facility_name});
      } else {
        return transfer;
      }
    });
  }
);

export const getSelectedTransferIds = createSelector(getSelectedTransfers, selectedTransfers => selectedTransfers.map(selectedTransfer => selectedTransfer.id));

const transferFields = ['driver_id', 'driver_type', 'driver_name', 'driver_license_number', 'date_transferred',
  'driver_state_license_number', 'driver_phone_number', 'vehicle_id', 'vehicle_name', 'vehicle_make', 'vehicle_model',
  'vehicle_license_plate', 'files', 'status', 'integration_transfer_type'
];

export const getChildItemMastersForFacility = createSelector(
  [itemsFilterByIntegrationType, getPrepackFacilityWeights, getSalesOrdersLines],
  (itemMasters, prepackFacilityWeights, lines) => {
    const linesItemMasterIds = [].concat(...lines.map(line => [].concat(...(line.source_sales_order_subitems || []).map(subItem => subItem.item_master_id))));
    const itemMastersMap = {};
    Object.entries(itemMasters).map(itemMaster => {
      itemMastersMap[itemMaster[0]] = (itemMaster[1] || []).filter(child =>
        prepackFacilityWeights.find(weight =>
          !!get(weight, 'applies_to_sales', false)
          && child.prepack_weight_id === weight.prepack_weight_id
          && weight.categories.find(
          prepackWeightCategory => prepackWeightCategory.category_id === child.category_id
          )
        ) || linesItemMasterIds.indexOf(child.id) !== -1
      ).sort((weight1, weight2) => weight1.prepack_fulfillment_units - weight2.prepack_fulfillment_units);
    });

    return itemMastersMap;
  }
);

export const isDriverCompany = createSelector([getCurrentDrivers, getDrivers], (currentDrivers, drivers) => {
  let isDriverCompany = true;
  currentDrivers.map(currentDriver => {
    const driver = drivers.find(driver => driver.id === currentDriver.id);
    isDriverCompany = isDriverCompany && !!driver && !!driver.is_licensed_transporter;
  });
  return isDriverCompany;
});

export const getModifyTransferInitialValues = createSelector(
  [getTransfer, getSalesOrders, getSalesOrdersTotals, getSalesOrdersLines, getChildItemMasters, getPrepackWeights,
  getInventoryItemsWithReservation, getPartnerFacilities, getCurrentTimezone, getIntegrationState, getReturnedItems, getItemMasters, roundQty],
  (transfer, salesOrders, totals, lines, children, weights, inventoryItems, partnerFacilities, timezone, integrationState, returnedItems, itemMasters, roundQty) => {
    const transferLines = transfer.lines && transfer.lines.length ? transfer.lines : [];
    const salesOrderLines = transfer.sales_orders && transfer.sales_orders.length ? transfer.sales_orders : [];
    const transferLineFields = ['container_count', 'container_type_id', 'source_sales_order_id', 'source_sales_order_line_id',
      'item_master_id', 'ordered_qty', 'ordered_uom', 'transfer_qty', 'transfer_uom', 'unit_price', 'line_item_price',
      'inventory_item_id', 'id', 'storage_location_id', 'gross_weight', 'notes', 'is_for_extraction'
    ];

    const formData = Object.assign({}, pick(transfer, transferFields), {
      lines: lines.length && transfer.lines
        ? lines.map((line, index) => {
          if (transfer.lines[index]) {
            const subitems = transferLines[index] && transferLines[index].subitems && weights && weights.length && children && children.length
              ? weights.map(weight => {
                let subItem = {};
                if (children && children.length) {
                  const child = children.find(product => product.prepack_weight_id === weight.id);
                  if (child) {
                    const transferSubitem = transferLines[index].subitems.find(item => item.item_master_id === child.item_master_id);
                    if (transferSubitem) {
                      subItem = Object.assign({}, transferSubitem, {
                        unit_price: transferSubitem.unit_price ? parseFloat(transferSubitem.unit_price).toFixed(2) : '0.00',
                        line_total: transferSubitem.unit_price && parseFloat(transferSubitem.unit_price) && transferSubitem.qty
                          ? (parseFloat(transferSubitem.unit_price) * transferSubitem.qty).toFixed(2)
                          : '0.00',
                        total: weight ? transferSubitem.qty * (weight.fulfillment_units / 100) : 0
                      });
                    }
                  }
                }
                return subItem;
              })
              : [];
            const inventory = transfer.lines[index].inventory.map(inventoryLine => {
              if (line.line_type === 'prepack') {
                const childItemMaster = children && children.length
                  ? children.find(child => child.id === inventoryLine.item_master_id)
                  : undefined;
                const inventoryItem = childItemMaster && childItemMaster.items && childItemMaster.items.length
                  ? childItemMaster.items.find(item => item.id === inventoryLine.inventory_item_id)
                  : inventoryItems.find(item => item.id === inventoryLine.inventory_item_id);
                return Object.assign({}, inventoryLine, {
                  unit_price: inventoryLine.unit_price ? parseFloat(inventoryLine.unit_price).toFixed(2) : '0.00',
                  prepack_name: childItemMaster && childItemMaster.prepack_name
                    ? childItemMaster.prepack_name
                    : undefined,
                  prepack_fulfillment_units: childItemMaster && childItemMaster.prepack_fulfillment_units
                    ? childItemMaster.prepack_fulfillment_units
                    : undefined,
                  storage_location_id: inventoryItem ? inventoryItem.inventory_location_id : undefined,
                  lot_number: inventoryItem && inventoryItem.lot_number ? inventoryItem.lot_number : inventoryLine.lot_number,
                  qty_available: inventoryItem && inventoryItem.qty ? getAvailableQuantityFromPackage(inventoryItem, transfer.id) : '',
                  hybrid_location_id: `${inventoryItem && inventoryItem.id ? inventoryItem.id : '0'}_${inventoryItem && inventoryItem.inventory_location_id ? inventoryItem.inventory_location_id : '0'}`,
                  inventory_location_id: inventoryItem && inventoryItem.inventory_location_id ? inventoryItem.inventory_location_id : 0,
                  state_integration_tracking_id: get(inventoryItem, 'state_integration_tracking_id', null),
                });
              }
              else {
                const inventoryItem = inventoryItems.find(item => item.id === inventoryLine.inventory_item_id);

                const useItemNumberAsPackageIdForUntrackedIngredients = (line, itemMasters, inventoryLine) => {
                  if(line.lot_tracked){
                    return inventoryLine;
                  }
                  const itemMaster = itemMasters.find((itemMaster) => itemMaster.id === get(line, 'item_master_id'));
                  if(get(itemMaster, 'inventory_attributes.is_ingredient')){
                    inventoryLine.package_code = get(itemMaster, 'item_number');
                  }
                  return inventoryLine;
                };

                useItemNumberAsPackageIdForUntrackedIngredients(line, itemMasters, inventoryLine);
                const package_code = inventoryLine.package_code !== null
                  ? inventoryLine.package_code
                  : (!line.lot_tracked && !line.is_medicated) ? '' : `${inventoryLine.inventory_item_id}.${inventoryLine.item_master_id}`;
                const qty_available = inventoryItem && inventoryItem.qty // if we have it then it should qty + inventoryLine qty if we have package_code
                  ? getAvailableQuantityFromPackage(inventoryItem, transfer.id)
                  : inventoryLine.inventory_transaction_id
                    ? String(inventoryLine.qty)
                    : '';
                return Object.assign({}, inventoryLine, {
                  package_code,
                  unit_price: inventoryLine.unit_price ? parseFloat(inventoryLine.unit_price).toFixed(2) : '0.00',
                  storage_location_id: inventoryItem ? inventoryItem.inventory_location_id : undefined,
                  lot_number: inventoryItem && inventoryItem.lot_number ? inventoryItem.lot_number : inventoryLine.lot_number,
                  qty_available: qty_available,
                  hybrid_location_id: `${inventoryItem && inventoryItem.id ? inventoryItem.id : '0'}_${inventoryItem && inventoryItem.inventory_location_id ? inventoryItem.inventory_location_id : '0'}`,
                  inventory_location_id: inventoryItem && inventoryItem.inventory_location_id ? inventoryItem.inventory_location_id : 0,
                  state_integration_tracking_id: get(inventoryItem, 'state_integration_tracking_id', null),
                  unit_weight: line.unit_weight,
                  net_weight: line.net_weight,
                  sent_net: inventoryLine.sent_net >= 0 ? inventoryLine.sent_net :
                    line.net_weight ? roundQty(line.net_weight * inventoryLine.qty) : '',
                  sent_net_uom: inventoryLine.sent_net_uom ? inventoryLine.sent_net_uom : line.sent_net_uom,
                  sent_gross: inventoryLine.sent_gross >= 0 ? inventoryLine.sent_gross :
                    line.unit_weight ? roundQty(line.unit_weight * inventoryLine.qty) : '',
                  sent_gross_uom: inventoryLine.sent_gross_uom ? inventoryLine.sent_gross_uom : line.unit_weight_uom,
                });
              }
            });

            return Object.assign({}, line,
              {
                inventory,
                subitems
              },
              pick(transfer.lines[index], transferLineFields),
              {
                unit_price: transfer.lines[index] ? parseFloat(transfer.lines[index].unit_price).toFixed(2) : '0.00',
                line_item_price: transfer.lines[index] ? parseFloat(transfer.lines[index].line_item_price).toFixed(2) : '0.00',
                line_total: transfer.lines[index].unit_price && parseFloat(transfer.lines[index].unit_price) && transfer.lines[index].qty
                  ? (parseFloat(transfer.lines[index].unit_price) * transfer.lines[index].qty).toFixed(2)
                  : '0.00',
                //lot_number: inventoryItem && inventoryItem.lot_number ? inventoryItem.lot_number : '',
                //qty_available: inventoryItem && inventoryItem.qty ? inventoryItem.qty : ''
              }
            );
          }
          else {
            return line;
          }
        })
        : [],
      sales_orders: salesOrders.length
        ? salesOrders.map((salesOrder, index) => Object.assign({}, salesOrder, salesOrderLines[index], {
          date_ordered: moment.utc(salesOrder.date_ordered).tz(timezone),
          date_expected: moment.utc(salesOrder.date_expected).tz(timezone)
        }))
        : [],
      destinations: transfer.destinations && transfer.destinations.length
        ? transfer.destinations.map(destination => {
          const partnerFacility = partnerFacilities && partnerFacilities.length
            && partnerFacilities.find(facility => facility.id === destination.partner_facility_id);
          const transportFacility = partnerFacilities && partnerFacilities.length && partnerFacilities.find(facility => facility.id === destination.integration_transporter_id);
          const partner_name = (partnerFacility && partnerFacility.partner && partnerFacility.partner.name) || '';
          let {arrival_time, departure_time} = destination;
          arrival_time = integrationState.isBiotrack || (arrival_time && arrival_time !== '00:00:00') ? convertDbTimeToClientTzMoment(arrival_time, timezone) : '';
          departure_time = integrationState.isBiotrack || (departure_time && departure_time !== '00:00:00') ? convertDbTimeToClientTzMoment(departure_time, timezone) : '';

          return {
            ...destination,
            arrival_time,
            departure_time,
            partner_name,
            partner_facility_type: get(partnerFacility, 'state_integration_type'),
            transporter_partner_id: transportFacility ? transportFacility.partner_id : null
          };
        })
        : [],
      date_transferred: moment.utc(transfer.date_transferred).tz(timezone),
      excise_tax_due: totals.excise_tax_due.toFixed(2) || '0.00',
      partner_discount: totals.partner_discount.toFixed(2) || '0.00',
      transfer_fee: totals.transfer_fee.toFixed(2) || '0.00',
      destination_total: totals.destination_total.toFixed(2) || '0.00',
      drivers: mapDbFieldsToDriversArray(transfer),
      returnedItems: returnedItems ? returnedItems : [],
      transferId: transfer.id, // Used in middleware an nowhere else
      is_waste_disposal: salesOrders.some(o => o.order_type === 'waste_disposal'),
      notes: transfer.notes
    });
    return formData;
  }
);

export const getModifyTransferPrepackItemMasterChildren = createSelector(
  getModifyTransferInitialValues, getChildItemMastersWithInventory, getPrepackWeights,
  (formData, childItemMasters, prepackWeights) => {
    const prepackItemMasterChildren = {};
    if (formData && childItemMasters && childItemMasters.length && prepackWeights && prepackWeights.length) {
      formData.lines.forEach((line, lineIndex) => {
        prepackItemMasterChildren[lineIndex] = {};
        line.inventory.forEach((inventoryLine, inventoryLineIndex) => {
          const child = childItemMasters.find(product => product.id == inventoryLine.item_master_id);
          if (child) {
            prepackItemMasterChildren[lineIndex][inventoryLineIndex] = child;
          }
        });
      });
    }
    return prepackItemMasterChildren;
  }
);

export const getCreateTransferFormValues = createSelector(
  getCreateTransferFormSelectorValues, formValues => ({
    status: formValues.status || '',
    transfer_fee: formValues.transfer_fee || '',
    excise_tax_due: formValues.excise_tax_due || '',
    partner_discount: formValues.partner_discount || '',
    lines: Array.isArray(formValues.lines) ? formValues.lines : [],
    move_all_inventory: formValues.move_all_inventory
  })
);

export const getModifyTransferFormValues = createSelector(
  getModifyTransferFormSelectorValues, formValues => ({
    status: formValues.status || '',
    transfer_fee: formValues.transfer_fee || '',
    excise_tax_due: formValues.excise_tax_due || '',
    partner_discount: formValues.partner_discount || '',
    lines: Array.isArray(formValues.lines) ? formValues.lines : [],
    move_all_inventory: formValues.move_all_inventory,
    returnedItems: formValues.returnedItems || [],
  })
);

export const getCreateTransferSalesOrders = createSelector(
  getSalesOrders, getTimezone, (salesOrders, timezone) => {
    return salesOrders.map((salesOrder) => Object.assign({}, salesOrder, {
      date_ordered: moment.utc(salesOrder.date_ordered).tz(timezone),
      date_expected: moment.utc(salesOrder.date_expected).tz(timezone)
    }));
  }
);

const getCreateTransferTotalsAndPrices = createSelector(
  [getCreateTransferFormValues, getModifyTransferFormValues, itemsFilterByIntegrationType, getPrepackWeights, roundQty],
  (formValuesCreate, formValuesModify, childItemMastersObject, prepackWeights, roundQty) => {
    const totalsAndPrices = {
      sentTotals: [],
      lineTotals: [],
      editableLinePrices: [],
      sentNetTotals: []
    };

    const prepackWeightsHash = (prepackWeights || []).reduce((acc, prepackWeight) => {
      acc[`${prepackWeight.id}`] = prepackWeight;
      return acc;
    }, {});

    const formValues = formValuesCreate.lines.length ? formValuesCreate : formValuesModify;
    if (Array.isArray(formValues.lines) && childItemMastersObject) {
      formValues.lines.forEach(line => {
        if (line.line_type === 'prepack') {

          const itemMaster = childItemMastersObject[line.item_master_id] !== undefined ? childItemMastersObject[line.item_master_id] : false;
          const childItemMastersHash = (itemMaster || []).reduce((acc, childItemMaster) => {
            acc[get(childItemMaster, 'id')] = childItemMaster;
            return acc;
          }, {});

          const totalQty = (line.inventory ? line.inventory : []).reduce((total, current) => {
            const childItemMaster = childItemMastersHash[`${current.item_master_id}`];
            const prepackWeight = get(prepackWeightsHash, get(childItemMaster, 'prepack_weight_id'), {});
            const uom = get(prepackWeight, 'uom', UOMS.GR);
            const itemWeightBase = parseInt(get(prepackWeight, 'weight_base', 0));
            const quantity = parseInt(get(current, 'qty', 0));
            const value = convertFromBase(quantity * itemWeightBase, uom);
            return total + value;
          }, 0);

          const calculatedLineItemPrice = (line.inventory ? line.inventory : []).reduce((total, current) => current.qty && current.unit_price && parseFloat(current.unit_price)
            ? total + (parseFloat(current.unit_price) * parseInt(current.qty))
            : total, 0);

          const lineItemPrice = line.line_item_price ? (parseFloat(line.line_item_price)) : 0;

          const editableLinePrice = (line.editableLinePrice !== undefined)
            ? line.editableLinePrice
            : (calculatedLineItemPrice !== lineItemPrice); // set on first pass

          const lineTotal = editableLinePrice
            ? lineItemPrice.toFixed(2)
            : calculatedLineItemPrice.toFixed(2);

          totalsAndPrices.editableLinePrices.push(editableLinePrice); // this array sets the formValue for the line in render
          totalsAndPrices.lineTotals.push(lineTotal);
          totalsAndPrices.sentTotals.push(totalQty);

        } else {
          if (line.line_type === 'bulk') {
            const totalQty = (line.inventory ? line.inventory : []).reduce((total, current) => current.qty ? total + parseFloat(current.qty) : total, 0);
            const calculatedTotal = line.unit_price ? (parseFloat(line.unit_price) * totalQty) : 0;
            const lineItemPrice = line.line_item_price ? (parseFloat(line.line_item_price)) : 0;

            // Commented out because to force every bulk to be editable which accomodates the overweigh need without code changes
            const editableLinePrice = (line.editableLinePrice !== undefined)
              ? line.editableLinePrice
              : (calculatedTotal !== 0 && lineItemPrice !== 0 && calculatedTotal !== lineItemPrice); // set on first pass

            const lineTotal = editableLinePrice
              ? lineItemPrice.toFixed(2)
              : calculatedTotal.toFixed(2);


            totalsAndPrices.editableLinePrices.push(editableLinePrice); // this array sets the formValue for the line in render
            totalsAndPrices.lineTotals.push(lineTotal);

            totalsAndPrices.sentTotals.push(roundQty(totalQty));

          } else if (line.line_type === 'unit') { // unit
            const totalQty = (line.inventory ? line.inventory : []).reduce((total, current) => current.qty ? total + parseFloat(current.qty) : total, 0);
            const totalSentNetQty = (line.inventory ? line.inventory : []).reduce((total, current) => current.sent_net ? total + parseFloat(current.sent_net) : total, 0);
            const calculatedTotal = line.unit_price ? (parseFloat(line.unit_price) * totalQty) : 0;
            totalsAndPrices.editableLinePrices.push(false);
            totalsAndPrices.lineTotals.push(calculatedTotal.toFixed(2));
            totalsAndPrices.sentTotals.push(totalQty);
            totalsAndPrices.sentNetTotals.push(totalSentNetQty);
          }
        }
      });
    }
    return totalsAndPrices;
  }
);

export const getCreateTransferSentTotals = createSelector(
  getCreateTransferTotalsAndPrices, totalsAndPrices => totalsAndPrices.sentTotals
);

export const getCreateTransferSentNetTotals = createSelector(
  getCreateTransferTotalsAndPrices, totalsAndPrices => totalsAndPrices.sentNetTotals
);

export const getCreateTransferLineTotals = createSelector(
  getCreateTransferTotalsAndPrices, totalsAndPrices => totalsAndPrices.lineTotals
);

export const getCreateTransferEditablePrices = createSelector(
  getCreateTransferTotalsAndPrices, totalsAndPrices => totalsAndPrices.editableLinePrices
);

const getCreateTransferInitialLineValues = createSelector(
  getSalesOrdersLines, getPrepackFacilityWeights, itemsFilterByIntegrationType, getCreateTransferEditablePrices, roundQty,
  (lines, prepackWeights, childItemMasters, editableLinePrices, roundQty) => {
    const prepackItemMasterChildren = {};
    return lines.map((line, lineIndex) => {
      prepackItemMasterChildren[lineIndex] = {};
      const subitems = line.source_sales_order_subitems.length && prepackWeights && prepackWeights.length
        ? prepackWeights.map(weight => {
          let subItem = {};
          if (childItemMasters && childItemMasters[line.item_master_id]) {
            const child = childItemMasters[line.item_master_id].find(product =>
              !!get(weight, 'applies_to_sales', false)
              && product.prepack_weight_id === weight.prepack_weight_id
              && weight.categories.find(
              prepackWeightCategory => prepackWeightCategory.category_id === product.category_id
              )
            );

            if (child) {
              const salesOrderSubitem = line.source_sales_order_subitems.find(item => item.item_master_id == child.item_master_id);
              if (salesOrderSubitem) {
                subItem = Object.assign({}, salesOrderSubitem, {
                  source_subitem_id: salesOrderSubitem.id,
                  unit_price: salesOrderSubitem.unit_price ? parseFloat(salesOrderSubitem.unit_price).toFixed(2) : '0.00',
                  line_total: salesOrderSubitem.unit_price && parseFloat(salesOrderSubitem.unit_price) && salesOrderSubitem.qty
                    ? (parseFloat(salesOrderSubitem.unit_price) * salesOrderSubitem.qty).toFixed(2)
                    : '0.00',
                  total: weight ? salesOrderSubitem.qty * (weight.fulfillment_units / 100) : 0
                });
              }
            }
          }
          return subItem;
        })
        : [];

      const inventory = line.source_sales_order_subitems.length && prepackWeights && prepackWeights.length
        ? line.source_sales_order_subitems.map((subItem, subItemIndex) => {
          if (childItemMasters && childItemMasters[line.item_master_id]) {
            const child = childItemMasters[line.item_master_id].find(product => product.id == subItem.item_master_id);
            if (child) {
              subItem.prepack_fulfillment_units = child.prepack_fulfillment_units;
              prepackItemMasterChildren[lineIndex][subItemIndex] = child;
            }
          }
          return Object.assign({}, subItem, {
            uom: 'EA',
            unit_price: subItem.unit_price ? parseFloat(subItem.unit_price).toFixed(2) : '0.00',
          });
        })
        : [
          {
            qty: line.qty,
            item_master_id: line.item_master_id,
            sent_net: line.sent_net ? line.sent_net : (line.net_weight ? roundQty(get(line, 'net_weight') * line.qty) : ''),
            sent_net_uom: line.sent_net_uom,
            sent_gross: line.sent_gross ? line.sent_gross : (line.unit_weight ? roundQty(get(line, 'unit_weight') * line.qty) : ''),
            sent_gross_uom: line.sent_gross_uom,
            unit_weight: line.unit_weight,
            net_weight: line.net_weight,
          }
        ];
      let transfer_qty = undefined;
      if (subitems.length) {
        transfer_qty = subitems.reduce((total, current) => {
          return current.total ? total + current.total : total;
        }, 0);

      }

      return {
        id: line.id,
        transfer_qty,
        sales_order_id: line.sales_order_id,
        item_master_id: line.item_master_id,
        // container_type_id: line.container_type_id,
        // container_count: line.container_count,
        transfer_uom: line.transfer_uom,
        unit_price: line.unit_price,
        line_item_price: line.line_item_price,
        // test
        editableLinePrice: editableLinePrices[lineIndex],
        // end test
        source_sales_order_id: line.sales_order_id,
        source_sales_order_line_id: line.id,
        subitems,
        ordered_qty: line.ordered_qty,
        qty: line.qty,
        ordered_uom: line.ordered_uom,
        name: line.name,
        default_cost: line.default_cost,
        default_uom: line.default_uom,
        unit_weight: line.unit_weight,
        unit_weight_uom: line.unit_weight_uom,
        uom_type: line.uom_type,
        line_type: line.line_type,
        is_medicated: line.is_medicated,
        is_waste_disposal: line.is_waste_disposal,
        inventory,
        meta: (line.meta && typeof line.meta === 'string' && typeof JSON.parse(line.meta) === 'object') ? JSON.parse(line.meta) : line.meta,
        lot_tracked: line.lot_tracked
      };
    });
  }
);

export const getCreateTransferDefaults = createSelector(
  [getCreateTransferSalesOrders, getSalesOrdersDestinations, getSalesOrdersTotals, getCreateTransferInitialLineValues, isMedicatedOrder, getDistributionSettings],
  (salesOrders, destinations, totals, lines, contains_medicated, distributionSettings) => {

    return {
      status: 'open',
      sales_orders: salesOrders,
      lines,
      destinations,
      excise_tax_due: totals.excise_tax_due.toFixed(2) || '0.00',
      partner_discount: totals.partner_discount.toFixed(2) || '0.00',
      transfer_fee: totals.transfer_fee.toFixed(2) || '0.00',
      destination_total: totals.destination_total.toFixed(2) || '0.00',
      drivers: [{}, {}],
      is_waste_disposal: salesOrders.some(o => o.order_type === 'waste_disposal'),
      transferId: 0, // Used in middleware and no where else
    };
  }
);

export const getCreateTransferPrepackItemMasterChildren = createSelector(
  getCreateTransferDefaults, getChildItemMastersWithInventory, getPrepackWeights,
  (formData, childItemMasters, prepackWeights) => {
    const prepackItemMasterChildren = {};
    if (formData && childItemMasters && childItemMasters.length && prepackWeights && prepackWeights.length) {
      formData.lines.forEach((line, lineIndex) => {
        prepackItemMasterChildren[lineIndex] = {};
        line.inventory.forEach((inventoryLine, inventoryLineIndex) => {
          const child = childItemMasters.find(product => product.id == inventoryLine.item_master_id);
          if (child) {
            prepackItemMasterChildren[lineIndex][inventoryLineIndex] = child;
          }
        });
      });
    }
    return prepackItemMasterChildren;
  }
);

export const getCreateTransferFormData = createSelector(
  [getSalesOrdersLines, getPrepackWeights, groupedByItemMasterId, getValidDrivers, getCreateTransferSalesOrders,
  getVehicles, getItemMasters, getPartnerFacilities, getFlattenedStorageLocations, getLocationsForSharedProducts,
  getChildItemMastersForFacility, getCreateTransferPrepackItemMasterChildren, getDistributionSettings],
  (lines, prepackWeights, inventoryItems, drivers, salesOrders, vehicles, itemMasters, partnerFacilities,
   locations, sharedLocations, childItemMasters, prepackItemMasterChildren, distributionSettings) => {

    return {
      lines,
      prepackWeights,
      inventoryItems,
      orders: salesOrders,
      drivers,
      vehicles,
      itemMasters,
      partner_facilities: partnerFacilities,
      transferTypes: [{text: 'Wholesale', value: '1'}],
      locations,
      sharedLocations,
      childItemMasters,
      prepackItemMasterChildren,
      medicatedTransfersRequireEntireQuantity: get(distributionSettings, 'distributions_transfer_requires_full_quantity.value', false)
    };
  }
);

export const isForLabPartner = createSelector(
  getCreateTransferSalesOrders, salesOrders =>
    Array.isArray(salesOrders) && salesOrders.length
      ? salesOrders.reduce(
      (acc, salesOrder) => Boolean(acc && salesOrder.partner_facility && salesOrder.partner_facility.partner
        && salesOrder.partner_facility.partner.is_lab)
      , true)
      : false
);

//Generic setting for whether to require lab results on transferred items
export const isLabResultRequired = createSelector(
  [getDistributionSettings],
  (settings) => {
    return [1,'1',true].includes(get(settings, 'distributions_transfer_requires_lab_results.value'));
  }
);

export const getPrepackWeightsForFacility = createSelector(
  [getPrepackFacilityWeights, getPrepackWeights, getModifyTransferPrepackItemMasterChildren],
  (facilityWeights, prepackWeights, lineItems) => {
    const linesItemIds = [].concat(...Object.entries(lineItems).map(line => line.prepack_weight_id));
    return prepackWeights.filter(weight => linesItemIds.indexOf(weight.id) !== -1 || facilityWeights.find(facilityWeight => facilityWeight.prepack_weight_id === weight.id));
  }
);

export const isReceivedTransfer = createSelector(
  [getTransfer],
  (transfer) => transfer && transfer.status && transfer.status === 'completed'
);

export const isOutForDeliveryTransfer = createSelector(
  [getTransfer],
  (transfer) => transfer.status === 'out_for_delivery'
);

export const getTransferStatuses = createSelector(
  [isReceivedTransfer, isOutForDeliveryTransfer, isMappingRequiredForSupply, isMedicatedOrder, moveAllInventoryFormValue],
  (isReceived, isOutForDelivery, isMappingRequired, isMedicated, moveAllInventory) => {

    const transferStatuses = [
      {value: 'open', text: I18n.t('cultivation.transfers.form.open')},
      {value: 'out_for_delivery', text: I18n.t('cultivation.transfers.form.outForDelivery')},
      {value: 'completed', text: I18n.t('cultivation.transfers.form.completed')}
    ];
    
    if (moveAllInventory) return transferStatuses.filter(status => status.value === 'open');

    const statuses = (isMappingRequired && isMedicated && !isReceived)
      ? transferStatuses.filter(status => status.value !== 'completed')
      : transferStatuses;
    return statuses.filter(status => (isReceived || isOutForDelivery) ? status.value !== 'open' : true);
  }
);

export const needTestResults = createSelector([getSalesOrders, getIntegrationState, getMmePartnersCertNumber, isForLabPartner, isLabResultRequired],
  (salesOrders, integrationState, mmePartnersCertNumber, isForLabPartner, isLabResultRequired) => {

    const {isWaLeaf, isPaLeaf, isBiotrack} = integrationState;
    if (isForLabPartner) return false;
    if (isLabResultRequired) return true;
    if (isBiotrack) return true;
    if (isWaLeaf) {
      const salesOrdersInThisOrg = salesOrders.filter(sales_order => {
        return sales_order.partner_facility && sales_order.partner_facility.state_integration_id
          && mmePartnersCertNumber.some(data => data.code === sales_order.partner_facility.state_integration_id && data.belong_to_one_org_with_current_facility);
      });
      if (salesOrdersInThisOrg.length === salesOrders.length) {
        return false;
      } else {
        return true;
      }
    }
    return isPaLeaf; // If not integrated with leaf as wa or pa tests not yet required
  }
);

export const getInventory = createSelector(
  [getInventoryItems, getLabResults],
  (inventory, labResults) => ({
    ...inventory,
    getLabResults: () => labResults,
  })
);

export const getFilteredInventoryItems = createSelector(
  [groupedByItemMasterId],
  (inventoryItems) => inventoryItems
);

// Check for existing and eligible test results for transfers when "require lab results for transferred packages" is true
export const checkPackageForLabResults = (inputObject) => {

  const {item, testResults, salesOrder, distributionSettings, utahCultivatorEnhancementsEnabled} = inputObject;

  //can always transfer to a lab
  const isLab = () => {
    if (!salesOrder || !salesOrder.partner_facility) return true;
    return (salesOrder.partner_facility.is_lab);
  };

  if (isLab()) return false;

  //Can always transfer non-medicated items & plants (considered non-medicated for this purpose)
  const isNonMedicatedItem = (item) => {
    return (item.item_category_code === 'UNMEDICATED' || item.item_category_code === 'PLANTS');
  };
  if (isNonMedicatedItem(item)) return false;

  // If we've got a whitelist of subcategory codes excluded from the test requirement
  const exemptSubcategories = get(distributionSettings, 'distributions_transfer_requires_lab_results_exempt_subcategories.value');
  if (exemptSubcategories && ~ exemptSubcategories.indexOf(item.subcategory_code)) return false;

  //Can always transfer waste
  if (item.is_waste === 1) return false;

  //All other cases require a lab result in a 'passed' status
  let testResult = find(testResults, ['item_id', item.id]);

  if (typeof testResult === 'undefined') {
    testResult = Object.values(testResults[0]).find((result) => result.item_id === item.id);
  }
  const isInValidTestState = (testResult) => {
    const hasTestResult = (testResult !== undefined && testResult.lab_result);
    // If utahCultivatorEnhancements enabled (Grow facility, Utah Config Pack and Feature Cultivator Enhancements) Test results are not required
    // Note: if a test result does exist then it still has to be valid
    return (hasTestResult && testResult.lab_result.status === 'passed') || utahCultivatorEnhancementsEnabled;
  };

  const testPassed = isInValidTestState(testResult);
  if (!testPassed) return 'noPassedTest';

  return false;
};
