import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import round from 'lodash.round';
import {I18n} from 'react-redux-i18n';
import {getDisplayRulesForMetrcOhio} from '../../selectors/compliance/sales/metrcOhSalesLimits';

const divStyles = {
  color: 'green',
  paddingRight: '4px',
  position: 'relative'
};

class OhMetrcComplianceWeight extends React.PureComponent {

  render() {
    const {rulesLoaded, total, isAwaitingInventory} = this.props;

    if (!rulesLoaded) return null;

    return (
      <div style={divStyles}>
      {
        isAwaitingInventory
          ? <span>Compliance Weight Awaiting Inventory Specification</span>
          : <span>{I18n.t('retail.salesSettings.flowerEquivalenceStatement')}:&nbsp; {total}</span>
      }
    </div>);
  }
}

OhMetrcComplianceWeight.propTypes = {
  rulesLoaded: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
  isAwaitingInventory: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
  const rules = getDisplayRulesForMetrcOhio(state, ownProps);
  const rulesArray = Object.values(rules);
  const rulesLoaded = rulesArray.length > 0;
  const total = round( rulesArray.reduce((acc, rule) => acc + rule.order, 0), 3);
  const isAwaitingInventory = rulesArray.some(rule => rule.awaiting_inventory);
  return {
    rulesLoaded,
    total,
    isAwaitingInventory,
  };
}

export default connect(mapStateToProps)(OhMetrcComplianceWeight);
