import React from 'react';
import PropTypes from 'prop-types';
import ContentConcealer from './ContentConcealer';

/***
 * Purely for semantic reasons and because I want to be able to use IfRender on pages where ContentConcealer is already in use.
 */
class WillRender extends React.PureComponent{
  render(){
    // Alias ifTrue and ifFalse tests to show for readability in JSX
    const props = Object.assign({}, this.props, {
      show: this.props.ifTrue
         ? this.props.ifTrue
         : this.props.ifFalse === false
           ? true
           : this.props.show
    });
    return <ContentConcealer {...props} />;
  }
}

WillRender.defaultProps = {
  alternateComponent: null,
  force: true,
};

WillRender.propTypes = {
  ifTrue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
  ifFalse: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
  show: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
  force: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
  ]),
  alternateComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
  ]),
};

export default WillRender;
