import React from 'react';
import PropTypes from 'prop-types';
import {reduxForm, FieldArray, getFormValues} from 'redux-form';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import SubmitSection from '../common/form/SubmitSection';
import Category from './Category';//eslint-disable-line import/no-named-as-default
import { userHasPermission } from '../../selectors/usersSelectors';
import { validateCategoryManagementForm as validate } from './validate';
import {CATEGORY_MANAGEMENT_FORM} from '../../constants/forms';

const CMForm = (props) => {
  const {
    onShowConfirmSharedModal, formValues, change, pristine, reset, lengthBan, setCategory, metrcCategories, leafCategories,
    leaflyCategories, rulesCategories, isRetailFacility, cureFormOptions, cureOrderTypeOptions, isCanadaFacility,
    reportingMapping, hasSalesRulesEnabled, salesRulesCategoriesArePreset, biotrackCategories, apisicpaCategories, changeSharedList, hasManagedSharedInventory, isMasterFacility,
    weedmapsCategories, activeIntegrations, integrationState, isolocityCategories, ccaCategories, categorySettings, subCategorySettings
  } = props;

  const submitSettings = {
    actionSettings: {
      reset: {
        action: reset,
        text: I18n.t('common.form.reset'),
        pristine
      },
      submit: {
        action: onShowConfirmSharedModal,
        text: I18n.t('common.create.update'),
        pristine,
      }
    }
  };
  return (
    <div className='category-management-form'>
      <FieldArray
        name='categoriesData'
        component={Category}
        formValues={formValues.categoriesData}
        change={change}
        lengthBan={lengthBan}
        setCategory={setCategory}
        activeIntegrations={activeIntegrations}
        metrcCategories={metrcCategories}
        biotrackCategories={biotrackCategories}
        apisicpaCategories={apisicpaCategories}
        leafCategories={leafCategories}
        leaflyCategories={leaflyCategories}
        rulesCategories={rulesCategories}
        isRetailFacility={isRetailFacility}
        cureFormOptions={cureFormOptions}
        cureOrderTypeOptions={cureOrderTypeOptions}
        isCanadaFacility={isCanadaFacility}
        reportingMapping={reportingMapping}
        hasSalesRulesEnabled={hasSalesRulesEnabled}
        salesRulesCategoriesArePreset={salesRulesCategoriesArePreset}
        isMasterFacility={isMasterFacility}
        changeSharedList={changeSharedList}
        hasManagedSharedInventory={hasManagedSharedInventory}
        weedmapsCategories={weedmapsCategories}
        integrationState={integrationState}
        isolocityCategories={isolocityCategories}
        ccaCategories={ccaCategories}
        categorySettings={categorySettings}
        subCategorySettings={subCategorySettings}
      />
      <SubmitSection settings={submitSettings}/>
    </div>
  );
};

CMForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  pristine: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  activeIntegrations: PropTypes.object.isRequired,
  lengthBan: PropTypes.func.isRequired,
  setCategory: PropTypes.func.isRequired,
  biotrackCategories: PropTypes.array.isRequired,
  apisicpaCategories: PropTypes.array.isRequired,
  leafCategories: PropTypes.array.isRequired,
  leaflyCategories: PropTypes.array.isRequired,
  metrcCategories: PropTypes.object.isRequired,
  rulesCategories: PropTypes.array,
  isRetailFacility: PropTypes.bool.isRequired,
  cureFormOptions: PropTypes.array.isRequired,
  cureOrderTypeOptions: PropTypes.array.isRequired,
  isCanadaFacility: PropTypes.bool.isRequired,
  reportingMapping: PropTypes.array.isRequired,
  hasSalesRulesEnabled: PropTypes.bool.isRequired,
  salesRulesCategoriesArePreset: PropTypes.bool.isRequired,
  isMasterFacility: PropTypes.bool,
  onShowConfirmSharedModal: PropTypes.func.isRequired,
  changeSharedList: PropTypes.func.isRequired,
  weedmapsCategories: PropTypes.array.isRequired,
  hasManagedSharedInventory: PropTypes.bool,
  integrationState: PropTypes.object.isRequired,
  isolocityCategories: PropTypes.array,
  ccaCategories: PropTypes.array,
  categorySettings: PropTypes.object.isRequired,
  subCategorySettings: PropTypes.object.isRequired,
};

export const CategoryManagementForm = reduxForm({})(CMForm);

function mapStateToProps(state, ownProps) {
  const initialValues = { categoriesData: ownProps.categoriesData};
  const form = CATEGORY_MANAGEMENT_FORM;
  return {
    initialValues,
    form,
    validate,
    hasManagedSharedInventory: userHasPermission(state, {permissions: ['manage_shared_inventory']}),
    enableReinitialize: true,
    formValues: getFormValues(form)(state) || initialValues
  };
}


export default connect(mapStateToProps)(CategoryManagementForm);
