import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';
import {Col, Row, Table, Card} from 'react-bootstrap';
import ContentConcealer from '../../../common/concealers/ContentConcealer';
import FeedingScheduleDetails from './FeedingScheduleDetails';

const PlantCreatedDetails = (props) => {
  const {plant, trackPlantsAsGroup} = props;
  return (
    <Card>
      <Row>
        <Col xs={6}>
          <h3>{I18n.t('plantHistory.plant.plantDetails')}</h3>
          <Table striped className='item-details'>
            <thead>
            <tr>
              <th>{I18n.t('itemTransactions.field')}</th>
              <th>{I18n.t('itemTransactions.value')}</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              {trackPlantsAsGroup ?
                <td>{I18n.t('plantHistory.plantGroupId')}</td> :
                <td>{I18n.t('plantHistory.plantId')}</td>
              }
              <td>{plant.plant_id}</td>
            </tr>
            <tr>
              <td>{I18n.t('plantHistory.plant.plantBatch')}</td>
              <td>{plant.batch_name}</td>
            </tr>
            <tr>
              <td>{I18n.t('plantHistory.plant.plantType')}</td>
              <td>{plant.type}</td>
            </tr>
            <ContentConcealer show={Boolean(plant.strain_name)}>
              <tr>
                <td>{I18n.t('plantHistory.plant.strain')}</td>
                <td>{plant.strain_name}</td>
              </tr>
            </ContentConcealer>
            <ContentConcealer show={Boolean(plant.phenotype_name)}>
              <tr>
                <td>{I18n.t('plantHistory.plant.phenotype')}</td>
                <td>{plant.phenotype_name}</td>
              </tr>
            </ContentConcealer>
            <tr>
              <td>{I18n.t('plantHistory.plant.location')}</td>
              <td>{plant.section_name}</td>
            </tr>
            <tr>
              <td>{I18n.t('plantHistory.plant.phase')}</td>
              <td>{plant.stage_name}</td>
            </tr>
            <ContentConcealer show={Boolean(plant.feeding_schedule_name)}>
              <tr>
                <td>{I18n.t('plantHistory.plant.feedingSchedule')}</td>
                <td>{plant.feeding_schedule_name}</td>
              </tr>
            </ContentConcealer>
            <ContentConcealer show={Boolean(plant.mother_plant_id)}>
              <tr>
                <td>{I18n.t('plantHistory.plant.motherPlant')}</td>
                <td>{plant.mother_plant_id}</td>
              </tr>
            </ContentConcealer>
            <ContentConcealer show={Boolean(plant.state_integration_tracking_id && plant.state_integration_tracking_id !== 'NULL' || get(plant, 'current_plant.state_integration_tracking_id', ''))}>
              <tr>
                <td>{I18n.t('plantHistory.plant.trackingId')}</td>
                <td>{get(plant, 'current_plant.state_integration_tracking_id', '') ? plant.current_plant.state_integration_tracking_id : plant.state_integration_tracking_id}</td>
              </tr>
            </ContentConcealer>
            <ContentConcealer show={Boolean(plant.external_id)}>
              <tr>
                <td>{I18n.t('plantHistory.plant.trackingId')}</td>
                <td>{plant.external_id}</td>
              </tr>
            </ContentConcealer>
            <ContentConcealer show={Boolean(plant.qty)}>
              <tr>
                <td>{I18n.t('plantHistory.plant.qty')}</td>
                <td>{plant.qty}</td>
              </tr>
            </ContentConcealer>
            <ContentConcealer show={Boolean(get(plant, 'current_plant.source_tracking_id', ''))}>
              <tr>
                <td>Source Tracking Id</td>
                <td>{get(plant, 'current_plant.source_tracking_id', '')}</td>
              </tr>
            </ContentConcealer>
            <ContentConcealer show={Boolean(plant.source_item_package_code)}>
              <tr>
                <td>{I18n.t('plantHistory.plant.sourceID')}</td>
                <td>{plant.source_item_package_code}</td>
              </tr>
            </ContentConcealer>
            </tbody>
          </Table>
        </Col>
        <ContentConcealer show={Boolean(plant.feedingSchedule)}>
          <Col xs={6}>
            <h3>{I18n.t('plantHistory.feedingScheduleDetails')}</h3>
            <FeedingScheduleDetails model={plant.feedingSchedule}/>
          </Col>
        </ContentConcealer>
      </Row>
    </Card>
  );
};

PlantCreatedDetails.propTypes = {
  plant: PropTypes.object.isRequired,
  trackPlantsAsGroup: PropTypes.bool
};

export default PlantCreatedDetails;
