import {createSelector} from 'reselect';
import * as dataNames from '../constants/dataNames';
import * as itemNames from '../constants/itemNames';
import {getCurrentFacilityUserOptions} from './usersSelectors';
import {getInventoryItem, getItemProducerName} from './inventoryItemsSelectors';
import {getActiveFacility} from './facilitiesSelectors';
import {getPartners} from './partnersSelectors';
import {
  convertDbDateTimeToFormInputDateTime,
  convertFormInputDateToDbDate,
  formatDBDate,
  formatInputDate
} from '../util/dateHelpers';
import {timezone} from '../../data/mock/harvestHistory';
import {getStrain} from './strainsSelectors';
import {getItemMasters} from './itemMastersSelectors';

export const getPackagingJobs = state => state[dataNames.packagingJobs];
export const getPackagingJob = state => state[itemNames.packagingJob] || {};
export const getPackagingJobItem = createSelector(
  [getInventoryItem, getActiveFacility, getPartners, getPackagingJob],
  (item, activeFacility, partners, job) => {
    if (!item || !item.id) {
      return {};
    }

    const originalWeight = job.status === 'completed' ? job.input_product.qty : item.qty;
    const availableWeight = job.status === 'completed' ? job.input_product.qty_available : item.qty - (item.qty_reserved || 0);

    return {
      ...item,
      producer_name: getItemProducerName(item, activeFacility, partners),
      qty: originalWeight,
      qty_available: availableWeight,
    };
  }
);

const getJobStrain = createSelector(
  [getPackagingJobItem, getStrain],
  (job, strain) => {
    if (job && job.strain_id) {
      return job.strain_id === strain.id ? strain.id : null;
    }
    return null;
  }
);

export const getItemMasterOptions = createSelector(
  [getItemMasters, getJobStrain],
  (itemMasters, strain) => {
    return itemMasters.filter(
      im => strain && im.strain_id === strain
    );
  }
);

export const getPackagingJobsWithUsers = createSelector(
  [getPackagingJobs, getCurrentFacilityUserOptions],
  (jobs, users) => {
    return jobs
      .map((job) => {
        if (users && job && job.packaging_job_assigned_to_users) {
          job.employees = job.packaging_job_assigned_to_users.map((assigned_user) => {
            const user = users.find((user) => user.id === assigned_user.user_id);
            if (user) {
              return {key: user.id, value: user.displayName};
            }
          });
        }
        return {...job};
      });
  }
);

export const getModifyPackagingJobInitialValues = createSelector(
  [getPackagingJob, getItemMasterOptions], (job, itemMasters) => {
    const estimatedCompletionDate = job && job.estimated_completion_at
      ? convertDbDateTimeToFormInputDateTime(job.estimated_completion_at, timezone)
      : '';

    const totalPackedWeight = job.output_products && job.output_products.reduce((acc, p) => {
      const itemMaster = itemMasters.find(im => im.id === p.item_master_id);
      const medicatedWeight = itemMaster && itemMaster.medicated_weight;
      return acc + (p.qty * medicatedWeight);
    }, 0);

    return {
      ...job,
      packages: job.output_products && job.output_products.map((p) =>
      {
        const itemMaster = itemMasters.find(im => im.id === p.item_master_id);
        const medicatedWeight = itemMaster && itemMaster.medicated_weight;
        return {
          item_master_id: p.item_master_id,
          package_code: p.package_code,
          qty: p.qty,
          medicated_weight: medicatedWeight,
          medicated_weight_uom: itemMaster && itemMaster.medicated_weight_uom,
          packaged_weight: p.qty * medicatedWeight,
          state_integration_tracking_id: p.state_integration_tracking_id || undefined,
        };
      }),
      estimated_completion_at: estimatedCompletionDate,
      completed_at: estimatedCompletionDate,
      package_created_at: formatInputDate(estimatedCompletionDate),
      user_ids: job.packaging_job_assigned_to_users && job.packaging_job_assigned_to_users.map(user => ({id: user.user_id})) || [],
      purpose: job.output_products && job.output_products[0].purpose,
      total_packed_weight: totalPackedWeight,
      // TODO: need to add packaged_loss_amt, waste_reported, and remaining_weight with correct calc logic
      // packaged_loss_amt: 0,
      // waste_reported: 0,
      // remaining_weight: totalPackedWeight,
    };
  }
);

export const getPackagingJobDetailInitialValues = () => ({
  packages: [{}],
  total_packed_weight: 0,
  waste_reported: 0,
  packaged_loss_amt: 0,
  remaining_weight: 0,
  user_ids: [],
});

export const getInitJobPayload = (formValues, timezone) => {
  return {
    packaging_location_id: formValues.packaging_location_id,
    assigned_amount: 0,
    assigned_amount_uom: 'GR',
    waste_reported: 0,
    waste_reported_uom: 'GR',
    packaged_loss_amt: 0,
    packaged_loss_amt_uom: 'GR',
    returned_percentage: 0,
    started_at: formValues.package_created_at ? convertFormInputDateToDbDate(formValues.package_created_at, timezone) : null,
    estimated_completion_at: formValues.estimated_completion_at ? convertFormInputDateToDbDate(formValues.estimated_completion_at, timezone) : null,
    source_item_id: formValues.item.id,
    packaging_job_assigned_to_users: formValues.user_ids.map(user => ({user_id: user.id})),
    output_products: formValues.packages.map(p => ({
      item_master_id: p.item_master_id,
      package_code: p.package_code,
      qty: p.qty,
      state_integration_tracking_id: p.state_integration_tracking_id || undefined,
      purpose: formValues.purpose,
      finished: 1,
      uom: 'EA',
    }))
  };
};

export const getCompleteJobPayload = (formValues, job, timezone, item) => {
  const transacted_qty = formValues.remaining_weight - formValues.item.qty;
  const output_products = formValues.packages.filter(p => p.qty).map(p => ({
    item_master_id: p.item_master_id,
    package_code: p.package_code,
    qty: p.qty,
    state_integration_tracking_id: p.state_integration_tracking_id || undefined,
    purpose: formValues.purpose,
    finished: 1,
    uom: 'EA',
  }));

  const inputProduct = {
    item_master_id: item.id,
    package_code: item.package_code,
    item_name: item.item_name,
    producer_name: item.producer_name,
    strain_name: item.source_strain,
    qty_available: item.qty_available,
    qty: item.qty,
    uom: 'GR',
  };

  return {
    packaging_job_id: job.id,
    storage_location_id: formValues.packaging_location_id,
    completed_at: formValues.completed_at ? convertFormInputDateToDbDate(formValues.completed_at, timezone) : null,
    package_created_at: formValues.package_created_at ? formatDBDate(formValues.package_created_at) : null, // always just date without timezone
    package_expires_at: formValues.package_expires_at ? formatDBDate(formValues.package_expires_at) : null, // always just date without timezone
    packaged_loss_amt: formValues.packaged_loss_amt,
    packaged_loss_amt_uom: 'GR',
    waste_reported: formValues.waste_reported,
    waste_reported_uom: 'GR',
    transacted_qty: transacted_qty,
    transacted_uom: 'GR',
    product_not_altered: formValues.product_not_altered || 0,
    is_each_packaging: true,
    packages: output_products,
    output_products: output_products,
    input_product: inputProduct,
  };
};

export const getModifyJobPayload = (formValues, job, timezone) => {
  return {
    packaging_job_id: job.id,
    packaging_location_id: formValues.packaging_location_id,
    started_at: formValues.package_created_at ? convertFormInputDateToDbDate(formValues.package_created_at, timezone) : null,
    estimated_completion_at: formValues.estimated_completion_at ? convertFormInputDateToDbDate(formValues.estimated_completion_at, timezone) : null,
    source_item_id: formValues.item.id,
    assigned_amount_uom: 'GR',
    packaged_loss_amt: formValues.packaged_loss_amt,
    packaged_loss_amt_uom: 'GR',
    waste_reported: formValues.waste_reported,
    waste_reported_uom: 'GR',
    packaging_job_assigned_to_users: formValues.user_ids.map(user => ({user_id: user.id})),
    output_products: formValues.packages.map(p => ({
      item_master_id: p.item_master_id,
      package_code: p.package_code,
      qty: p.qty,
      state_integration_tracking_id: p.state_integration_tracking_id || undefined,
      purpose: formValues.purpose,
      finished: 1,
      uom: 'EA',
    }))
  };
};


