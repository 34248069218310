import React from 'react';
import {I18n} from 'react-redux-i18n';

const ManageClosingPage = () => {
  return (
    <div className='manage-closing-page'>
      <h1>{I18n.t('retail.manageClosing.title')}</h1>

    </div>
  );
};

export default ManageClosingPage;

