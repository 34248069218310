import {formValueSelector} from 'redux-form';
import omit from 'lodash.omit';
import sortBy from 'lodash.sortby';
import get from 'lodash.get';
import {createSelector} from 'reselect';
import * as dataNames from '../constants/dataNames';
import * as itemNames from '../constants/itemNames';
import * as p from '../constants/permissions';
import {isPaLeafIntegrator, isWaLeafIntegrator} from './integration/leafSelectors';
import {getActiveFacility, getFacilities} from './facilitiesSelectors';
import {userHasPermission} from './usersSelectors';
import {oklahomaSupplyChainRules, partnerFacilityTypes} from '../constants/partnerFacilityTypes';
import {getIntegrationState} from './integration/integrationSelectors';
import {buildCompleteInternationalPhoneNumber}  from '../util/formatHelper';
import { getPhoneShouldIncludeCountryCode } from '../selectors/InternationalOperationsSelectors';
import {SALES_ORDER_FORM} from '../constants/forms';

export const getUnsortedPartners = (state) => state[dataNames.partners];
export const getPartnerFacilities = state => state[dataNames.partnerFacilities] || [];
export const getPartnerIntegrations = state => state[dataNames.partnerIntegrations] || [];
const getPartnerId = (_, props) => props && props.partner_id;
const getPartnerIdFormValue = (state) => formValueSelector(SALES_ORDER_FORM)(state, 'partner_id');
const getPartnerStatus = (_, props) => props.status !== 'inactive' ? 1 : 0;
export const getPartner = (state) => state[itemNames.partner];
const getFormName = (_, props) => get(props, 'formName', '');
const getSelectedFacilityIdField = (_, props) => get(props, 'facilityIdField', '');
const getActiveFacilityType = (_, props) => get(props, 'activeFacilityType', '');
const getState = (state) => state;

export const getPartners = createSelector(
  [getUnsortedPartners],
  (partners) => sortBy(partners, [partner => get(partner, 'name', '').toLowerCase()], ['asc'])
);

export const getPartnersByPermission = createSelector(
  [getPartners, (state) => state],
  (partners, state) => {
    const externalEnabled = userHasPermission(state, {permissions: [p.view_partners, p.manage_partners]});
    return externalEnabled ? partners : partners.filter(partner => partner.is_internal_partner === 1);
  }
);

export const getPartnerFacilitiesByPartner = createSelector(
  [getPartnerId, getPartnerIdFormValue, getPartnerFacilities],
  (partnerIdPropValue, partnerIdFormValue, partnerFacilities) => {
    const partnerId = partnerIdPropValue || partnerIdFormValue;

    return partnerFacilities.filter(facility => facility.partner_id === partnerId);
  }
);

export const getPartnersForPurchase = createSelector(
  [getPartners],
  (partners) => {
    return partners.filter(partner => partner.purchase_from === 1);
  }
);

export const denormalizePartnersByFacility = createSelector(
  getPartners, partners => {
    const partnerFacilities = [];
    partners.forEach(partner => {
      const newPartner = Object.assign({}, omit(partner, ['name', 'facilities']), {
        id: '' + partner.id,
        partner_id: partner.id,
        partner_name: partner.name,
        rating: partner.rating ? parseInt(partner.rating) : 0,
        state_integration_id: partner.state_integration_id,
      });
      if (partner.facilities && partner.facilities.length) {
        partner.facilities.forEach(facility => {
          partnerFacilities.push(Object.assign({}, newPartner, {
            facility_id: facility.id,
            ...facility
          }, {id: `${partner.id}:${facility.id}`}));
        });
      }
      else {
        partnerFacilities.push(newPartner);
      }
    });
    return partnerFacilities;
  }
);

export const filterPartnersForLeafIntegration = createSelector([getPartnerFacilitiesByPartner, isWaLeafIntegrator, getActiveFacility, isPaLeafIntegrator, getFacilities],
  (partners, isWaLeaf, currentFacility, isPaLeaf, facilities) => {
    if (isWaLeaf || isPaLeaf) {
      const searchParams = {};

      if (currentFacility.type === 'grow') {
        searchParams.leaf_type_exclude = 'dispensary';
        searchParams.platform_type_exclude = 'dispensary';

      } else if (currentFacility.type === 'dispensary') {
        searchParams.leaf_type_exclude = 'cultivator';
        searchParams.platform_type_exclude = 'grow';
      }

      const isLocalPartner = partners.every(partner => !partner.state_integration_id);
      if (searchParams.leaf_type_exclude) {
        const availableInternalFacilities = facilities
          .filter(facility => facility.type !== searchParams.platform_type_exclude)
          .map(facility => facility.id);
        return partners.filter(partner => availableInternalFacilities.indexOf(partner.facility_id) !== -1
          || (partner.state_integration_type && partner.state_integration_type !== searchParams.leaf_type_exclude)
          || isLocalPartner);
      }
    }
    return partners;
  });


export const getPartnerFacilitiesForPurchaseOrder = createSelector([filterPartnersForLeafIntegration],
  (partners) => partners
);

export const getPartnerOrganization = createSelector([getPartners, getActiveFacility], (partners, facility) =>
  partners.filter(partner => partner.organization_id === facility.organizationId)
);

export const getPartnersByStatus = createSelector([denormalizePartnersByFacility, getPartnerStatus, getPhoneShouldIncludeCountryCode],
  (partnersByFacility ,status, includeCountryCode) => {
    return partnersByFacility
      .filter(partnerByFacility => partnerByFacility.active === status)
      .map((partner) => {
        let address = partner.street_address_1;
        if (partner.street_address_2) address += ` - ${partner.street_address_2}`;

        return {
          ...partner,
          facility_phone: buildCompleteInternationalPhoneNumber(partner.facility_phone, partner.facility_phone_country_code, includeCountryCode),
          address
        };
      });
  }
);

export function getFacilityTypesForPartner(partner, facility) {
  const filteredFacilityTypes =  facility && partnerFacilityTypes
    .filter(fT => fT.politicalRegion.find(pr => pr.country === facility.country_code && (pr.province === facility.province_code ||  pr.province === '*')));

  // Oklahoma Filtering
  if (typeof facility !== 'undefined' && facility.country_code === 'US' && facility.province_code === 'OK') {
    // TODO: Consolidate purchase_from, sell_to to partnerTypes mapping for all partner components
    const partnerTypes = partner.partnerTypes ? partner.partnerTypes.map(partnerType =>
      typeof partnerType === 'object' ? partnerType.value : partnerType
    ) : [];
    const purchaseFrom = partnerTypes.indexOf('purchaseFrom') > -1 ? 1 : 0;
    const sellTo = partnerTypes.indexOf('sellTo') > -1 ? 1 : 0;

    if (sellTo && !purchaseFrom) {
      return filteredFacilityTypes.filter(type => type.value === 'dispensary' || type.value === 'processor' || type.value === 'researcher');
    } else if (!sellTo && purchaseFrom) {
      return filteredFacilityTypes.filter(type => type.value === 'cultivator' || type.value === 'processor');
    }
  }
  return filteredFacilityTypes;
}

export const getValidFacilityTypesForSupplyChain = createSelector(
  [getIntegrationState, getFormName, getSelectedFacilityIdField, getPartnerFacilities, getActiveFacilityType, getState],
  (integrationState, formName, facilityIdField, partnerFacilities, activeFacilityType, state) => {
    const selector = formValueSelector(formName);

    const getSelectedFacility = () => {
      const selectedPartnerFacilityId = selector(state, facilityIdField);
      return partnerFacilities.find((facility) => facility.id === selectedPartnerFacilityId);
    };

    const selectedFacility = getSelectedFacility();
    if (!selectedFacility) return [];

    // Reduce the facility types to only those that are valid for the selected partner
    const selectedFacilityTypes = selectedFacility
      ? partnerFacilityTypes.filter(type => selectedFacility[`is_${type.value}`])
      : [];

    // Reduce the facility types to those available to the current facility type
    if (integrationState.isOklahoma) {
      const allowedTypes = oklahomaSupplyChainRules[activeFacilityType];
      return selectedFacilityTypes.filter((facilityType) => allowedTypes.find((allowedType) => allowedType === facilityType.value));
    }

    return selectedFacilityTypes;

  });
