/* eslint-disable import/prefer-default-export*/
import * as actionTypes from '../constants/actionTypes';
import {setSocketUser, setSocketFacility, unsetSocketConnection} from '../actions/socketActions';

export default store => next => action => {
  const result = next(action);
  if(action.type === actionTypes.SERVER_SET_SOCKET_ID) {
    const {facility, user} = store.getState();
    if(user && user.id){
      store.dispatch(setSocketUser({user}));
    }
    if(facility && facility.id){
      store.dispatch(setSocketFacility({organization_id: facility.organizationId, facility_id: facility.id}));
    }
  }else if (action.type === actionTypes.LOGOUT_SUCCESS) {
    store.dispatch(unsetSocketConnection());
  }
  return result;
};
