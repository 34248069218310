import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import get from 'lodash.get';
import ReduxUomSelect from './redux-form/ReduxUomSelect';
import InternationalNumericInput from './InternationalNumericInput';
import {getDecimalPrecisionForUom} from '../../../util/uomHelpers';
import {getUomInputPrecision} from '../../../selectors/uomsSelectors';
import {UOM_VALUE_PRECISION} from '../../../constants/uoms';
import {getInternationalNumberFormat} from '../../../selectors/InternationalOperationsSelectors';
import {fieldFormatHelperDecimals, fieldParseHelperDecimals} from '../../../util/internationalHelpers';

const InternationalQuantityByUomInput = (props) => {
  const allProps = Object.assign({}, props, get(props, 'props', {}));
  const {name, label, disabled, uom, readOnly, selectable, whiteBackground, selectableName, isRequired, onItemChange, defaultUom, hideUom, precision, internationalNumberFormat } = allProps;
  let {fractionPartSize} = allProps;
  const {...restProps} = props;
  const abbrev = uom ? I18n.t(`uoms.${uom}.abbrev`) : I18n.t('uoms.UNIT.abbrev');
  const addOn = selectable && whiteBackground && !disabled ? 'dropdownButton' : 'rightAddon';

  if (isNaN(fractionPartSize)) {
    fractionPartSize = precision !== undefined
      ? precision
      : uom
        ? getDecimalPrecisionForUom(uom)
        : UOM_VALUE_PRECISION;
  }

  delete restProps.selectable;
  delete restProps.selectableName;
  delete restProps.whiteBackground;
  delete restProps.hideUom;
  delete restProps.defaultUom;

  const inputProps = {
    name,
    label,
    readOnly,
    isRequired,
    ...restProps,
    fractionPartSize,
    internationalNumberFormat,
    [addOn]: selectable
      ? <Field
        disabled={allProps.disabled}
        defaultUom={defaultUom}
        name={selectableName}
        options={allProps.options}
        component={ReduxUomSelect}
        onItemChange={onItemChange}
      />
      : hideUom
        ? null
        : <span style={{fontSize: '14px'}}>{abbrev}</span>
  };

  // This is how we want it in the store
  const fieldParser = (value, name) => {
    return fieldParseHelperDecimals(value, name, internationalNumberFormat);
  };

  // This is how we want it displayed in the component
  const fieldFormatter = (value, name) => {
    return fieldFormatHelperDecimals(value, name, internationalNumberFormat);
  };

  return (
    <Field
      parse={fieldParser}
      format={fieldFormatter}
      name={name}
      type='text'
      component={InternationalNumericInput}
      disabled={disabled}
      props={inputProps}
    />
  );
};

InternationalQuantityByUomInput.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string,
  uom: PropTypes.string,
  selectable: PropTypes.bool,
  selectableName: PropTypes.string,
  noBackground: PropTypes.bool,
  onItemChange: PropTypes.func,
  defaultUom: PropTypes.string,
  precision: PropTypes.number,
  shouldRound: PropTypes.bool,
  internationalNumberFormat: PropTypes.string.isRequired
};

InternationalQuantityByUomInput.defaultProps = {
  disabled: false,
  selectable: false,
  selectableName: 'uom_display',
  whiteBackground: false,
  readOnly: false,
  shouldRound: true
};

function mapStateToProps(state, ownProps){
  return {
    precision: getUomInputPrecision(state, ownProps),
    internationalNumberFormat: getInternationalNumberFormat(state),
  };
}

export default connect(mapStateToProps, null)(InternationalQuantityByUomInput);
