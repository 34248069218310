import React from 'react';
import moment from 'moment';
import {Col,Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Field,reduxForm} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {METRC_SALES_SEARCH_FORM} from '../../../../constants/forms';
import {getProductOptions, getCategoryOptions} from '../../../../selectors/productSalesReportSelectors';
import MultiselectInput from '../../../common/form/MultiselectInput';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import TextInput from '../../../common/form/TextInput';
import SubmitSection from '../../../common/form/SubmitSection';
import InternationalDatePickerInput from '../../../common/form/InternationalDatePickerInput';
import {isPickableDate} from '../../../../selectors/salesReportSelectors';

//
// Base form component for product sales report search
//
const SearchForm = (props) => {
  const {
    handleSubmit,
    categoryOptions,
    productOptions,
    pristine,
    reset,
    submitSearch,
    isPickableDate
  } = props;

  const submitSettings = {
    actionSettings: {
      reset: {
        action: reset,
        text: I18n.t('common.form.reset'),
        pristine
      },
      submit: {
        action: handleSubmit(submitSearch),
        text: I18n.t('common.actions.search'),
      }
    }
  };

  return (
  <form onSubmit={handleSubmit(submitSearch)}>
    <Row>
      <Col sm={6} md={4}>
        <Field
          name='report_date'
          component={InternationalDatePickerInput}
          props={{
            label: I18n.t('retail.productSalesReport.reportDateLabel'),
            isRequired: true,
            isValidDate: isPickableDate,
            closeOnSelect: true
          }}
        />
      </Col>

      <Col sm={6} md={4}>
        <Field
          name='order_number'
          component={TextInput}
          props={{
            label: I18n.t('retail.productSalesReport.orderNumberLabel')
          }}
        />
      </Col>

      <div className='d-none d-sm-block d-md-none clearfix'></div>

      <Col sm={6} md={4}>
        <Field
          name='product'
          component={ReactSelectInput}
          props={{
            label: I18n.t('retail.productSalesReport.productNameLabel'),
            options: productOptions,
          }}
        />
      </Col>

      <div className='d-none d-md-block d-xl-none clearfix'></div>

      <Col sm={6} md={4}>
        <Field
          name='category_ids'
          component={MultiselectInput}
          props={{
            label: I18n.t('retail.productSalesReport.categoryLabel'),
            options: categoryOptions,
            allOption: true,
            placeholder: I18n.t('common.form.selectPlaceholder')
          }}
        />
      </Col>

      <div className='d-block d-sm-block d-none d-md-block d-none d-lg-block clearfix'></div>

      <Col xs={12}>
        <SubmitSection settings={submitSettings} />
      </Col>
    </Row>
  </form>
  );
};

SearchForm.propTypes = {
  handleSubmit:       PropTypes.func.isRequired,
  categoryOptions:    PropTypes.array.isRequired,
  productOptions:     PropTypes.array.isRequired,
  pristine:           PropTypes.func.isRequired,
  reset:              PropTypes.func.isRequired,
  submitSearch:       PropTypes.func.isRequired,
  isPickableDate:     PropTypes.bool.isRequired
};


// SearchForm validation
const validate = (values) => {
  const errors = {};

// require report_date
  if(!values.report_date){
    errors.report_date = I18n.t('common.form.isRequired',{
      fieldName: I18n.t('retail.productSalesReport.reportDateLabel')
    });
  }

  if(!moment.isMoment(values.report_date)){
    errors.report_date = I18n.t('common.form.invalidFormat');
  } else {
    // require valid date format
    if(!values.report_date.isValid()){
      errors.report_date = I18n.t('common.form.invalidFormat');
    }

    // require past date
    if(+values.report_date.format('YYYYMMDD') > +moment().format('YYYYMMDD')){
      errors.report_date = I18n.t('retail.productSalesReport.invalidReportDate');
    }
  }
  return errors;
};


// SearchForm store connection
const mapStateToProps = (state) => {
  return {
    productOptions:     getProductOptions(state),
    categoryOptions:    getCategoryOptions(state),
    isPickableDate: isPickableDate(state)
  };
};

// SearchForm initial values
const initialValues = {
  category_ids: [],
  vendor: null,
};

const ReduxSearchForm = reduxForm({
  form: METRC_SALES_SEARCH_FORM,
  initialValues,
  validate
})(SearchForm);

const MetrcSalesSearchForm = connect(mapStateToProps)(ReduxSearchForm);

MetrcSalesSearchForm.propTypes = {
  submitSearch:       PropTypes.func.isRequired,
};

export default MetrcSalesSearchForm;
