import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Card } from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import TaxGroup from './TaxGroup';

const TaxGroups = (props) => {

  const {fields} = props;

  const onAddGroup = () => {
    const defaultGroup = {items: [{}]};
    if(fields.length === 0){
      defaultGroup.inboundValue = 100;
      defaultGroup.outboundValue = 100;
    }
    fields.push(defaultGroup);
  };

  const onRemoveGroup = (index) => {
    fields.remove(index);
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>
          <Row>
            <Col md={9}>
              <div>{I18n.t('taxProfiles.form.compoundedTaxesHeading')}:</div>
              <div style={{fontSize: 'smaller', marginTop: '5px'}} className='text-muted'>
                {I18n.t('taxProfiles.form.compoundedTaxesHeadingSub')}
              </div>
            </Col>
            <Col md={3}>
              <Button
                className='btn btn-block'
                variant='primary'
                onClick={(e) => {
                  e.target.blur();
                  onAddGroup();
                }}
              >
                {I18n.t('taxProfiles.form.addTaxGroup')}
              </Button>
            </Col>
          </Row>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <div>
          {fields.map((group, index) => {
            return (<TaxGroup idx={index} key={index} groupKey={group} onRemoveGroup={onRemoveGroup} showDivider={fields.length > 1 && (index + 1) !== fields.length} />);
          })}
        </div>
    </Card.Body>
  </Card>
  );
};

TaxGroups.propTypes = {
  fields: PropTypes.object.isRequired,
};

export default TaxGroups;
