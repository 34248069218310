/* eslint-disable react/no-multi-comp */
// Don't like this - will ask Alexander why did mount vs will mount before putting in willReceiveProps - thinking timings.
/* eslint-disable react/no-did-mount-set-state */

import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Button} from 'react-bootstrap';
import {formValueSelector, reset} from 'redux-form';

import {getUnpaginatedData, postData, getItem} from '../../../actions/apiActions';
import {unsetData} from '../../../actions/dataActions';
import {formatClientDate} from '../../../util/dateHelpers';
import * as dataNames  from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import TablePageWrapper from '../../common/grid/TablePageWrapper';
import {getTransactionsForDisplay} from '../../../selectors/transactionSelectors';
import {getIntegrationState} from '../../../selectors/integration/integrationSelectors';
import {getTotalResults} from '../../../selectors/paginationSelectors';
import {transactionsIntegratorsWitoutInactive} from '../../../selectors/integration/commonSelectors';
import {API_TRANSACTIONS_FORM} from '../../../constants/forms';

import PageTitle from '../../common/PageTitle';
import TransactionPageForm from './TransactionPageForm';


export class TransactionListingPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.getTransactions = this.getTransactions.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.viewDetails = this.viewDetails.bind(this);
    this.getFormValues = this.getFormValues.bind(this);

    const okCodes = ['200', '201'];

    const columns = [
      {
        name: 'integration.transactions.number',
        dataId: 'id',
        hidden: false,
        width: '120px',
      },
      {
        name: 'integration.transactions.date',
        dataId: 'date',
        hidden: false,
        width: '180px'
      },
      {
        name: 'integration.transactions.api',
        dataId: 'api_call',
        columnClassName: 'allow-wrap',
        hidden: false,
      },
      {
        name: 'integration.transactions.response',
        dataId: 'response',
        hidden: false,
        width: '120px',
        formatter: (cell, row) =>
          <p className={okCodes.indexOf(row.code) === -1 ? 'transactions-error' : 'transactions-success'}>{row.response}</p>
      },
      {
        name: 'integration.transactions.data_show',
        dataId: 'id',
        hidden: false,
        width: '140px',
        formatter: (cell, row) =>
          <Button
            onClick={() => this.viewDetails(cell)}
            size='sm'>
            {I18n.t('integration.transactions.viewDetails')}
          </Button>

      }
    ];
    this.state = {
      columns,
      loadingInProgress: false
    };
  }

  componentWillMount() {
    const {getItem, getUnpaginatedData} = this.props.actions;
    getItem(
      '/api/integration-settings',
      itemNames.integrationSettings,
      {failed: 'stateIntegratorSettings.get.failed'}
    );
    getUnpaginatedData('/api/transactions/end_points', dataNames.transactionsEndPoints);
    getUnpaginatedData('/api/transactions/integrators', dataNames.transactionsIntegrators);
    getUnpaginatedData('/api/third-party-integrations', dataNames.thirdPartyIntegrations, undefined, {with_active: 1});
  }

  componentDidMount() {
    const data = this.getFormValues();
    this.setState({
      dateFrom: data.date_from ? formatClientDate(data.date_from) : undefined,
      apiCall: data.api_call,
      dateTo: data.date_to ? formatClientDate(data.date_to) : undefined,
      responseType: data.response_type ? +data.response_type : null
    });
  }

  componentWillUnmount() {
    this.props.actions.reset(API_TRANSACTIONS_FORM);
  }

  // Fetch paginated transaction sets from server.  Used directly as
  // onSubmit handler for transaction search form, indirectly as
  // handler for TableComponent events.
  getTransactions(data){
    this.props.actions.unsetData(dataNames.transactions);

    // ensure that we always have actual filters from form
    data = {...data, ...this.getFormValues()};

    // avoid concurrent requests
    if(this.state.loadingInProgress) {
      return;
    }
    this.setState({loadingInProgress: true});

    // import+sanitize post data, merging with state values to
    // keep the table and the form values in sync

    // Important:
    // default sort here is set to "created_at" instead of "id",
    // because MySQL is very slow in some cases on this table when sorting by id (details in Jira)
    const payload = {
      paginate: 1,
      sort: 'created_at:desc'
    };
    const newState = {};

    if('api_call' in data){
      payload.api_call = newState.apiCall = data.api_call;
    } else {
      payload.api_call = this.state.apiCall;
    }

    if('date_from' in data){
      payload.date_from = newState.dateFrom = formatClientDate(data.date_from);
    } else {
      payload.date_from = this.state.dateFrom;
    }

    if('date_to' in data){
      payload.date_to = newState.dateTo = formatClientDate(data.date_to);
    } else {
      payload.date_to = this.state.dateTo;
    }

    if('integrator' in data){
      payload.integrator = newState.integrator = data.integrator;
    } else {
      payload.integrator = this.state.integrator;
    }

    if('page' in data){
      payload.page = +data.page;
    }

    if('per_page' in data){
      payload.per_page = newState.perPage = +data.per_page;
    } else {
      payload.per_page = this.state.perPage;
    }

    if('response_type' in data){
      if(!data.response_type){ // '' value
        newState.responseType = null;
      } else { // '1' or '0' value
        payload.response_type = newState.responseType = +data.response_type;
      }
    } else {
      payload.response_type = this.state.responseType;
    }

    if('sort' in data){
      payload.sort = newState.sort = data.sort;
    } else if (this.state.sort) {
      payload.sort = this.state.sort;
    }

    this.setState(newState);

    this.props.actions.postData(
      `/api/transactions`,
      payload,
      dataNames.transactions,
      {
        fail: 'integration.transactions.fail',
      },
      null,
      () => {
        this.setState({loadingInProgress: false});
      }
    );
  }

  // Callback for TableComponent requests to update results.
  // Proxies to this.getTransactions() for server-side handling
  handleSearch (sort, search, size, start, filter) {
    const data = {};
    if(sort){
      data.sort = sort
        .replace(' ',':')
        .replace(/^api_call/,'end_point')
        .replace(/^date/,'id');
    }
    if(size){
      data.per_page = size;
    }
    if(start){
      data.page = (start ? start / size : 0) + 1;
    }

    this.getTransactions(data);
  }

  viewDetails(id) {
    window.open(`/integration/api-transactions/details/${id}`);
  }

  getFormValues() {
    const {getFormValue} = this.props;
    return {
      integrator: getFormValue('integrator'),
      api_call: getFormValue('api_call'),
      date_from: getFormValue('date_from'),
      date_to: getFormValue('date_to'),
      response_type: getFormValue('response_type')
    };
  }


  render() {
    const  {dataTotalSize, transactions, integrators, endPoints, hasMetrcSettings, hasLeafSettings} = this.props;
    const initialValues = {
      date_from: new Date(),
      date_to: new Date(),
    };
    const {columns, loadingInProgress} = this.state;
    return (
      <div>
        <PageTitle primaryText={I18n.t('integration.transactions.title')}/>
        <div style={{clear: 'both', height: '5px'}} />
        <TransactionPageForm
          transactions={transactions}
          integrators={integrators}
          endPoints={endPoints}
          onSubmit={this.getTransactions}
          viewDetails={this.viewDetails}
          hasMetrcSettings={hasMetrcSettings}
          hasLeafSettings={hasLeafSettings}
          initialValues={initialValues}
          submitting={loadingInProgress}
        />
        <TablePageWrapper
          columns = {columns}
          data={transactions}
          dataTotalSize={dataTotalSize}
          external={true}
          externalSearch={this.handleSearch}
          searchOnInitialization={false}
          hideExport = {true}
          hideScanSearch={true}
          noSelectionMode={true}
          hideSearch={true}
          className='transactions-table'
          pageSizeList={[5, 10, 20 ,50]}
          actions={[]}
          settingKey='api-transactions'
          bstProps={{
            selectRow: {
              hideSelectColumn: true
            }}}
        />
      </div>
    );
  }
}

TransactionListingPage.propTypes = {
  actions: PropTypes.shape({
    getUnpaginatedData: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
  }),
  transactions: PropTypes.array,
  integrators: PropTypes.array,
  endPoints: PropTypes.array,
  hasMetrcSettings: PropTypes.bool,
  hasLeafSettings: PropTypes.bool,
  dataTotalSize: PropTypes.number,
  getFormValue: PropTypes.func.isRequired,
};
const selector = formValueSelector(API_TRANSACTIONS_FORM);

function mapStateToProps(state) {
  const {isLeaf: hasLeafSettings, isMetrc: hasMetrcSettings} = getIntegrationState(state);
  return {
    dataTotalSize: getTotalResults(state, {name: dataNames.transactions}),
    transactions: getTransactionsForDisplay(state),
    integrators: transactionsIntegratorsWitoutInactive(state),
    endPoints: state[dataNames.transactionsEndPoints],
    hasMetrcSettings,
    hasLeafSettings,
    getFormValue: name => selector(state, name),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({},
    {getUnpaginatedData, postData, getItem, unsetData, reset}
  );
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(TransactionListingPage);
