import { I18n } from 'react-redux-i18n';

const validate = (values) => {
  const errors = {};
  if (values.max_patients_warn && values.max_patients_block) {
    if (parseInt(values.max_patients_warn) > parseInt(values.max_patients_block)) {
      errors.max_patients_block = I18n.t('retail.patientSettings.blockSize');
    }
  }
  return errors;
};

export default validate;
