import React from 'react';
import PropTypes from 'prop-types';
import {Field, FieldArray} from 'redux-form';
import {Button, Card} from 'react-bootstrap';
import ReactSelectInput from '../common/form/ReactSelectInput';
import * as applicationModes from '../../constants/applicationModes';
import EquivalencySettings from './EquivalencySettings';
import RulesSettings from './RulesSettings';
import MmuSettings from './MmuSettings';
import AdditionalEquivalencySettings from './AdditionalEquivalencySettings';

const LimitsPanel = (props) => {
  const {
    patientType, isCoLocated, facility, getConfiguredComplianceType, integrationState,
    disableResetPatientSalesLimit, isHempFacility, isLeafPaMmuToggled, isLeafPaConfigPackClosedLoopFacility,
    isSalesLimitsAppliedAcrossLoop, salesLimitsByConsumerType, change
  } = props;

  if(isHempFacility){
    return null;
  }

  const complianceTypes =
    integrationState.isCure || integrationState.isFlorida
      ? [{text: 'Base Sales Limits Off Lab Results', value: 'lab_results_based'}]
      : isLeafPaMmuToggled && isLeafPaConfigPackClosedLoopFacility
        ? [{text: 'Medical Marijuana Units', value: 'mmus'}]
        : [
            {text: 'Category Based Equivalency', value: 'equivalency'},
            {text: 'Category Based Rules', value: 'categories'}
        ];

  const options = [
    {text: 'Yes', value: '1'},
    {text: 'No', value: '0'}
  ];

  const limitMethod = props.formSelector(`${patientType}_limit_method`);
  const currentLimitsMethod = limitMethod === 'disabled' ? getConfiguredComplianceType(patientType) : limitMethod;
  const isIntegrated = ['isPaLeaf', 'isCure'].find((integrationType) => integrationState[integrationType]);
  const limitOptions = [
    {text: 'Medical Patients', value: 'medical'},
    {text: 'Recreational Patients', value: 'recreational'}
  ];

  // Enable/Disable Limits Button
  const toggleLimitsButton = (
    <div className={limitMethod === 'disabled' ? 'col-md-4 col-md-offset-8 no-margin' : 'col-md-4 no-margin'}>
      <div className='form-group'>
        <Button
          style={{marginTop: '25px'}}
          disabled={!props.settingsMethodIsOverridable}
          onClick={(event) => {
            change('afterSubmit', 'stay');
            const newValue = (limitMethod === 'disabled') ? getConfiguredComplianceType(patientType) : 'disabled';
            change(`${patientType}_limit_method`, newValue);
            if(newValue === 'disabled'){
              props.setComplianceType(newValue);
            }
            event.target.blur();
          }}
        >
          {
            limitMethod === 'disabled'
              ? <div>Enable Limits</div>
              : <div>Disable Limits</div>
          }
        </Button>
      </div>
    </div>
  );

  // disable external limits setting
  const disableLimitsOnExternalError = (
    <div>
      <div className='text-info' style={{marginBottom: '12px'}}>
        <strong>About Disabling External Limits On System Error:</strong>  This
        option is provided for periods when the external authority system is
        experiencing systemic failures.  The default MJ Platform response to
        external system failures impacting compliance is to regard the response
        as a limit of zero effectively disabling sales.  This is done to keep you
        in compliance with your jurisdictions' oversight authority.
      </div>

      <div className='text-danger' style={{marginBottom: '12px'}}>
        <strong>Disabling limits validation may put you in violation of their rules and regulations.</strong>
      </div>

      <table className='table table-striped'>
        <tbody>
        <tr>
          <td style={{paddingRight:'8px'}}>
            Disable Limits On External System Error
          </td>
          <td style={{width: '150px'}}>
            <Field
              name='order_disable_limits_on_external_system_error'
              component={ReactSelectInput}
              clearable={false}
              props={{
                options: options,
                valueKey: 'value',
                textKey: 'text'
              }}
            />
          </td>
        </tr>
        {
          integrationState.isCure || integrationState.isFlorida
            ? (<tr>
              <td style={{paddingRight:'8px'}}>
                UOM Display Preference
              </td>
              <td style={{width: '150px'}}>
                <Field
                  name={`${patientType}_display_uom`}
                  component={ReactSelectInput}
                  clearable={false}
                  props={{
                    options: [{value: 'GR', text: 'Grams'}, {value: 'MG', text: 'Milligrams'}],
                    valueKey: 'value',
                    textKey: 'text'
                  }}
                />
              </td>
            </tr>)
            : null
        }
        </tbody>
      </table>
    </div>
  );

  // Leaf PA MMU category equivalency settings
  const mmuSettings = (
    <div className={limitMethod === 'disabled' ? 'disable-contents' : ''}>
      <MmuSettings
        isSalesLimitsAppliedAcrossLoop={isSalesLimitsAppliedAcrossLoop}
        salesLimitsByConsumerType={salesLimitsByConsumerType}
        patientType={patientType}
      />
    </div>
  );

  // category equivalency settings
  const equivalencySettings = (
    <div className={limitMethod === 'disabled' ? 'disable-contents' : ''}>
      <Field
        name='equivalencySettings'
        eqFields={props.eqFields}
        metrcOhEqFields={props.metrcOhEqFields}
        integrationState={props.integrationState}
        eqUoms={props.eqUoms}
        component={EquivalencySettings}
        formSelector={props.formSelector}
        change={change}
        disableFields={currentLimitsMethod === 'disabled'}
        patientType={patientType}
        facility={props.facility}
        applicationMode={props.applicationMode}
      />
      <AdditionalEquivalencySettings
        facility={facility}
        patientType={patientType}
        integrationState={props.integrationState}
        disableResetPatientSalesLimit={disableResetPatientSalesLimit}
      />
    </div>
  );

  // category rules
  const rulesSettings = (
    <div className={limitMethod === 'disabled' ? 'disable-contents' : ''}>
      <FieldArray
        name='rulesSettings'
        eqFields={props.eqFields}
        eqUoms={props.eqUoms}
        component={RulesSettings}
        rulesCategories={props.rulesCategories}
        patientType={patientType}
        isReadOnly={!props.settingsMethodIsOverridable}
      />
    </div>
  );

  return (
    <Card>
      <Card.Header>
        <div className='row'>
          <div className='col-md-6 no-margin'>
            <Field
              name='patient_type'
              component={ReactSelectInput}
              clearable={false}
              props={{
                disabled: !isCoLocated,
                label: 'Settings For: ',
                options: limitOptions,
              }}
            />
          </div>
          {
            [applicationModes.canada].indexOf(props.applicationMode) !== -1
              ? null // Canada gets this
              : (
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-8'>
                    <div className={limitMethod === 'disabled' ? 'hide' : 'no-margin'}>
                      <Field
                        name={`${props.patientType}_limit_method`}
                        component={ReactSelectInput}
                        clearable={false}
                        props={{
                          label: 'Settings Method:',
                          options: complianceTypes,
                          disabled: disableResetPatientSalesLimit || !props.settingsMethodIsOverridable
                        }}
                      />
                    </div>
                  </div>
                  {integrationState.isCure || integrationState.isFlorida || (isLeafPaMmuToggled && isLeafPaConfigPackClosedLoopFacility)
                    ? null
                    : toggleLimitsButton
                  }
                </div>
              </div>
            )
          }
        </div>
      </Card.Header>
      <Card.Body>
        {isIntegrated && ((!isLeafPaMmuToggled || !isLeafPaConfigPackClosedLoopFacility)) ? disableLimitsOnExternalError : null}
        {(isLeafPaMmuToggled && isLeafPaConfigPackClosedLoopFacility) ? mmuSettings : null}
        {(!isIntegrated && currentLimitsMethod !== 'categories') ? equivalencySettings : null}
        {(!isIntegrated && currentLimitsMethod === 'categories') ? rulesSettings : null}
      </Card.Body>
    </Card>
  );
};

LimitsPanel.propTypes = {
  fields: PropTypes.shape({
    push: PropTypes.func.isRequired,
    map: PropTypes.func.isRequired,
  }),
  eqFields: PropTypes.array.isRequired,
  metrcOhEqFields: PropTypes.array.isRequired,
  getConfiguredComplianceType: PropTypes.func,
  integrationState: PropTypes.object,
  eqUoms: PropTypes.array.isRequired,
  disableFields: PropTypes.bool,
  change: PropTypes.func.isRequired,
  setComplianceType: PropTypes.func.isRequired,
  applicationMode: PropTypes.string.isRequired,
  complianceType: PropTypes.string.isRequired,
  showRulesBasedCompliance: PropTypes.bool.isRequired,
  formSelector: PropTypes.func.isRequired,
  rulesCategories: PropTypes.array.isRequired,
  isCoLocated: PropTypes.bool.isRequired,
  facility: PropTypes.object.isRequired,
  patientType: PropTypes.string,
  isHempFacility: PropTypes.bool.isRequired,
  settingsMethodIsOverridable: PropTypes.bool.isRequired,
  disableResetPatientSalesLimit: PropTypes.bool,
};

export default LimitsPanel;
