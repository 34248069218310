import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import { requiredFieldValidation, integerFieldValidation } from '../../../common/form/redux-form/validations';

const checkCouponValue = (value, discountType) => discountType === 'percent' && parseFloat(value) > 100;

const validate = (values, props) => {
  const errors = {};

  errors.coupon_type = requiredFieldValidation(values.coupon_type, 'retail.createCoupon.type');
  errors.name = requiredFieldValidation(values.name, 'retail.createCoupon.name');
  errors.discount_type = requiredFieldValidation(values.discount_type, 'retail.createCoupon.discountType');
  errors.discount_amount = requiredFieldValidation(values.discount_amount, 'retail.createCoupon.amount');
  errors.coupon_applies_to = requiredFieldValidation(values.coupon_applies_to, 'retail.createCoupon.applyToBasePrice');
  errors.date_available_from = requiredFieldValidation(values.date_available_from, 'retail.createCoupon.effectiveDate');
  errors.date_available_to = requiredFieldValidation(values.date_available_to, 'retail.createCoupon.date');
  errors.discount_amount = checkCouponValue(values.discount_amount, props.discountType) ? I18n.t('retail.createCoupon.maxPercentage') : undefined;

  if (values.date_available_from && values.date_available_to && moment(values.date_available_from).isAfter(values.date_available_to)) {
    errors.date_available_to = I18n.t('retail.createCoupon.expirationDateShouldBeAfterEffectiveDate');
  }

  if (+values.applicable_item_count) {
    errors.applicable_item_count = !integerFieldValidation(+values.applicable_item_count) ? I18n.t('retail.createCoupon.applicableItemCountWrong') : '';
  }



  return errors;
};

export default validate;
