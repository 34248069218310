import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Field, FieldArray } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import TextInput from '../common/form/TextInput';
import { canShowOARRSColumnForCategory, isOARRSReportingEnabled } from '../../selectors/integration/metrcSelectors';
import { canShowIsolocityColumnForCategory } from '../../selectors/integration/thirdPartyIntegrationSelectors';
import { getFacilityType } from '../../selectors/facilitiesSelectors';
import SubCategory from './SubCategory'; //eslint-disable-line import/no-named-as-default
import {getCategoryManagementCategoriesByCode, isCategoryPlants} from '../../selectors/categorySelectors';
import { canEditDisplayName, canAddSubCategories, categorizationIsHidden } from './helpers';


export const Category = (props) => {
  const {
    fields,
    formValues,
    change,
    lengthBan,
    setCategory,
    activeIntegrations,
    metrcCategories,
    leafCategories,
    leaflyCategories,
    isRetailFacility,
    cureFormOptions,
    cureOrderTypeOptions,
    isCanadaFacility,
    reportingMapping,
    hasSalesRulesEnabled,
    salesRulesCategoriesArePreset,
    biotrackCategories,
    apisicpaCategories,
    changeSharedList,
    hasManagedSharedInventory,
    weedmapsCategories,
    isMasterFacility,
    canShowOARRSColumnForCategory,
    isOARRSReportingEnabled,
    integrationState,
    isolocityCategories,
    canShowIsolocityColumnForCategory,
    ccaCategories,
    categorySettings,
    subCategorySettings,
    categoriesByCode
  } = props;
  const {
    isLeaf,
    isBiotrack,
    isApisicpa,
    isMetrc,
    isCure,
    isLeafly,
    isWeedmaps,
    isPaLeaf,
    isIsolocity,
    isCca
  } = activeIntegrations;

  // Column widths by number of columns required
  const columnMap = {
    [3]: [4, 4, 4],
    [4]: [3, 2, 3, 3, 1],
    [5]: [2, 1, 3, 3, 3],
    [6]: [2, 1, 2, 2, 2, 2]
  };

  // Number of columns required by an integration not including sales which is added in getColumnWidth
  const integrationCols = {
    none: 3,
    metrc: 4,
    leaf: 4,
    leafly: 4,
    biotrack: 4,
    apisicpa: 4,
    cure: 5,
    canada: 4,
    shared: 4,
    weedmaps: 4,
    isolocity: 4,
    cca: 4,
  };

  const getColumnWidth = (index, isMedicated) => {
    const rules = isOARRSReportingEnabled || (hasSalesRulesEnabled && !salesRulesCategoriesArePreset) ? 1 : 0;
    if (isCanadaFacility) return columnMap[integrationCols.canada + rules][index];
    if (isBiotrack) return columnMap[integrationCols.biotrack + rules][index];
    if (isApisicpa) return columnMap[integrationCols.apisicpa + rules][index];
    if (isLeaf) return columnMap[integrationCols.leaf + rules][index];
    if (isLeafly) return columnMap[integrationCols.leafly + rules][index];
    if (isMetrc) return columnMap[integrationCols.metrc + rules][index];
    if (isCure) return columnMap[integrationCols.cure + rules][index];
    if (isWeedmaps) return columnMap[integrationCols.weedmaps + rules][index];
    if (isCca) return columnMap[integrationCols.cca + rules][index];
    if (!isMedicated) return columnMap[integrationCols.shared + rules][index];
    if (isIsolocity) return columnMap[integrationCols.isolocity + rules][index];
    return columnMap[integrationCols.none + rules][index];
  };

  const getSalesRulesIndex = () => {
    if (isCure) return 5;
    if (isCanadaFacility || isLeaf || isMetrc) return 4;
    return 3;
  };

  const isLeafExcludesCategoryCodes = ['HARVESTS'];
  const nonMedicatedCategoryCodes = ['UNMEDICATED'];
  const onlyCanadaCategory = ['MARIJUANA'];

  return (
    <div>
      {fields.map((category, index) => {
        const cat = fields.get(index);
        if(categorizationIsHidden(cat.category_code, categorySettings)){
          return null;
        }
        if (isLeaf && isLeafExcludesCategoryCodes.indexOf(cat.category_code) !== -1) {
          return null;
        }
        if (!isCanadaFacility && onlyCanadaCategory.indexOf(cat.category_code) !== -1) {
          return null;
        }
        const isMedicated = !(nonMedicatedCategoryCodes.indexOf(cat.category_code) !== -1);
        const isHarvest = cat.category_code === 'HARVESTS';
        const isPlant = isCategoryPlants(cat);

        const cats = metrcCategories[formValues[index].id] || metrcCategories[0];
        if (isMetrc && isMedicated && cats.length === 0) {
          return null;
        }

        const categoryData = categoriesByCode[cat.category_code];
        const categoryName = categoryData && categoryData.name;

        return (
          <Row className='category' key={`category-${index}`}>
            <Col md={3} className='category-name-block'>
              <h3>{categoryName}</h3>
              <Field
                name={`${category}.name`}
                component={TextInput}
                props={{
                  label: I18n.t('categoryManagement.displayCategoryName'),
                  onChange: (e) => lengthBan(e, `${category}.name`, change),
                  disabled: !canEditDisplayName(cat.category_code, categorySettings),
                }}
              />
            </Col>
            <Col md={9} className='subcategories'>
              <Row>
                <Col md={getColumnWidth(0, isMedicated)}>
                  {' '}
                  {/*was 3*/}
                  <h4>{I18n.t('categoryManagement.subCategoryName')}</h4>
                </Col>
                <Col md={getColumnWidth(1, isMedicated)}>
                  {' '}
                  {/*was 2*/}
                  <h4 style={{ textAlign: 'center' }}>{I18n.t('categoryManagement.productsAmount')}</h4>
                </Col>
                <Col md={getColumnWidth(2, isMedicated)}>
                  {' '}
                  {/*was 3*/}
                  <h4>{I18n.t('categoryManagement.displaySubCategoryName')}</h4>
                </Col>
                {isCure && isMedicated ? (
                  <Col md={getColumnWidth(3)}>
                    <h4>{I18n.t('categoryManagement.deliveryMethod')}</h4>
                  </Col>
                ) : null}
                {isCure && isMedicated ? (
                  <Col md={getColumnWidth(4)}>
                    <h4>{I18n.t('categoryManagement.highLowTHC')}</h4>
                  </Col>
                ) : null}
                {isPaLeaf && isMedicated ? (
                  <Col md={getColumnWidth(3)}>
                    <h4>{I18n.t('categoryManagement.leafCategory')}</h4>
                  </Col>
                ) : null}
                {isLeafly ? (
                  <Col md={getColumnWidth(3)}>
                    <h4>{I18n.t('categoryManagement.leaflyCategory')}</h4>
                  </Col>
                ) : null}
                {
                  isCca && (
                    <Col md={getColumnWidth(3)}>
                      <h4>{I18n.t('categoryManagement.ccaCategory')}</h4>
                    </Col>
                  )
                }
                {isBiotrack && isMedicated ? (
                  <Col md={getColumnWidth(3)}>
                    <h4>{I18n.t('categoryManagement.biotrackCategory')}</h4>
                  </Col>
                ) : null}
                {isApisicpa && isMedicated ? (
                  <Col md={getColumnWidth(3)}>
                    <h4>{I18n.t('categoryManagement.apisicpaCategory')}</h4>
                  </Col>
                ) : null}
                {isMetrc && isMedicated && (integrationState.isCoMetrc || integrationState.isAkMetrc || !isHarvest) ? (
                  <Col md={getColumnWidth(3)}>
                    {' '}
                    {/*was 3*/}
                    <h4>{I18n.t('categoryManagement.metrcCategory')}</h4>
                  </Col>
                ) : null}
                {canShowOARRSColumnForCategory(cat.category_code) && (
                  <Col md={getColumnWidth(3)}>
                    <h4>{I18n.t('categoryManagement.rulesCategory')}</h4>
                  </Col>
                )}
                {isWeedmaps ? (
                  <Col md={getColumnWidth(3)}>
                    {' '}
                    {/*was 3*/}
                    <h4>{I18n.t('categoryManagement.weedmapsCategory')}</h4>
                  </Col>
                ) : null}
                {(isCanadaFacility && isMedicated && !isLeafly) ? (
                  <Col md={getColumnWidth(3)}>
                    <h4>{I18n.t('categoryManagement.reportingMapping')}</h4>
                  </Col>
                ) : null}
                {hasSalesRulesEnabled && isMedicated && !salesRulesCategoriesArePreset ? (
                  <Col md={getColumnWidth(getSalesRulesIndex())}>
                    <h4>Sales Rules Categories</h4>
                  </Col>
                ) : null}
                {isWeedmaps ? (
                  <Col md={getColumnWidth(3)}>
                    {' '}
                    {/*was 3*/}
                    <h4>{I18n.t('categoryManagement.weedmapsCategory')}</h4>
                  </Col>
                ) : null}
                {!isMedicated ? (
                  <Col md={getColumnWidth(4, isMedicated)}>
                    <h4>{I18n.t('categoryManagement.shared')}</h4>
                  </Col>
                ) : null}
                {canShowIsolocityColumnForCategory(cat.category_code) ? (
                  <Col md={getColumnWidth(3)}>
                    <h4>{I18n.t('categoryManagement.isolocityCategory')}</h4>
                  </Col>
                ) : null}
              </Row>
              <FieldArray
                name={`${category}.subcategories`}
                category={category}
                component={SubCategory}
                change={change}
                setCategory={setCategory}
                subcategoriesValues={formValues[index]}
                lengthBan={lengthBan}
                activeCategory={cat}
                activeIntegrations={activeIntegrations}
                biotrackCategories={biotrackCategories}
                apisicpaCategories={apisicpaCategories}
                weedmapsCategories={weedmapsCategories}
                leafCategories={leafCategories}
                leaflyCategories={leaflyCategories}
                metrcCategories={metrcCategories[formValues[index].id] || metrcCategories[0]}
                getColumnWidth={getColumnWidth}
                getSalesRulesIndex={getSalesRulesIndex}
                isMedicated={isMedicated}
                isRetailFacility={isRetailFacility}
                hasSalesRulesEnabled={hasSalesRulesEnabled && isMedicated && !salesRulesCategoriesArePreset}
                salesRulesCategoriesArePreset={salesRulesCategoriesArePreset}
                cureFormOptions={cureFormOptions}
                cureOrderTypeOptions={cureOrderTypeOptions}
                reportingMapping={reportingMapping}
                isHarvest={isHarvest}
                isPlant={isPlant}
                isMasterFacility={isMasterFacility}
                changeSharedList={changeSharedList}
                hasManagedSharedInventory={hasManagedSharedInventory}
                integrationState={integrationState}
                isolocityCategories={isolocityCategories}
                ccaCategories={ccaCategories}
                canAddSubCategories={canAddSubCategories(cat.category_code, categorySettings)}
                subCategorySettings={subCategorySettings}
              />
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

Category.propTypes = {
  fields: PropTypes.object.isRequired,
  formValues: PropTypes.array.isRequired,
  change: PropTypes.func.isRequired,
  setCategory: PropTypes.func.isRequired,
  lengthBan: PropTypes.func.isRequired,
  activeIntegrations: PropTypes.object.isRequired,
  biotrackCategories: PropTypes.array.isRequired,
  apisicpaCategories: PropTypes.array.isRequired,
  leaflyCategories: PropTypes.array.isRequired,
  leafCategories: PropTypes.array.isRequired,
  metrcCategories: PropTypes.object.isRequired,
  isRetailFacility: PropTypes.bool.isRequired,
  cureFormOptions: PropTypes.array.isRequired,
  cureOrderTypeOptions: PropTypes.array.isRequired,
  isCanadaFacility: PropTypes.bool.isRequired,
  reportingMapping: PropTypes.array.isRequired,
  hasSalesRulesEnabled: PropTypes.bool.isRequired,
  salesRulesCategoriesArePreset: PropTypes.bool,
  isMasterFacility: PropTypes.bool,
  isOARRSReportingEnabled: PropTypes.bool,
  canShowOARRSColumnForCategory: PropTypes.func,
  changeSharedList: PropTypes.func,
  hasManagedSharedInventory: PropTypes.bool,
  weedmapsCategories: PropTypes.array,
  integrationState: PropTypes.object,
  isolocityCategories: PropTypes.array,
  canShowIsolocityColumnForCategory: PropTypes.func,
  ccaCategories: PropTypes.array,
  categorySettings: PropTypes.object,
  subCategorySettings: PropTypes.object,
  categoriesByCode: PropTypes.array
};

function mapStateToProps(state) {
  return {
    isOARRSReportingEnabled: isOARRSReportingEnabled(state),
    canShowOARRSColumnForCategory: (category) => canShowOARRSColumnForCategory(state)(category),
    canShowIsolocityColumnForCategory: (category) => canShowIsolocityColumnForCategory(state)(category),
    facilityType: getFacilityType(state),
    categoriesByCode: getCategoryManagementCategoriesByCode(state)
  };
}

export default connect(mapStateToProps)(Category);
