import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import InlineCheckBox from '../../form/InlineCheckBox';
import * as p from '../../../../constants/permissions';
import PermissionWrapper from '../../PermissionWrapper';

const LocationSharing = ({locationName, onIsSharedChange, isDisabled, isShared}) => {
  const className = 'float-right location-sharing-checkbox' + (isDisabled ? ' location-sharing-disabled' : '');
  const altForNotPermitted = isShared ? (
    <Field
      name={`${locationName}.is_shared`}
      component={InlineCheckBox}
      props={{
        label: I18n.t('locations.form.isShared'),
        disabled: true,
      }}/>
    ) : '';

  return (
    <div onClick={event => event.stopPropagation()} className={className}>
      <PermissionWrapper permissions={[p.manage_shared_inventory]} alternateComponent={altForNotPermitted}>
        <Field
          name={`${locationName}.is_shared`}
          component={InlineCheckBox}
          props={{
            label: I18n.t('locations.form.isShared'),
            disabled: isDisabled,
          }}
          onChange={event => onIsSharedChange(locationName, event)}/>
      </PermissionWrapper>
    </div>
  );
};

LocationSharing.propTypes = {
  locationName: PropTypes.string.isRequired,
  isShared: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onIsSharedChange: PropTypes.func.isRequired,
};

export default LocationSharing;