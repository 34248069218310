import {formValueSelector} from 'redux-form';
import {createSelector} from 'reselect';
import {MODIFY_PACKAGES} from '../../constants/forms';
import {getModifyPackagesInitialValues} from '../inventoryItemsSelectors';
import {getCategories, isCategoryNonMedicated} from '../categorySelectors';

const selector = formValueSelector(MODIFY_PACKAGES);

export const canSetSourceFacility = (value) => Boolean(!value.partner_facility_id && value.partner_facility_source === 'import');

export const getFormLots = (state) => selector(state, 'lots') || [];
export const getFormPackages = (state, props) => {
  const {packages} = getModifyPackagesInitialValues(state, props);
  return selector(state, 'packages') || packages;
};

const isMedicatedPackage = pkg => Boolean(pkg.itemMaster && pkg.itemMaster.is_medicated === 1);

export const hasPackageWithoutReason = (packages) => packages.some(
  item => parseFloat(item.transacted_qty) && !item.integration_adjustment_reason && isMedicatedPackage(item)
);

/**
 * Return true if at least one package, selected for modification, is from medicated category.
 */
export const hasMedicatedPackages = createSelector([getFormPackages, getCategories], (packages, categories) => {
  if (!Array.isArray(packages) || !Array.isArray(categories)) {
    return false;
  }
  return packages.some(pkg => {
    const cat_id = pkg.itemMaster && pkg.itemMaster.category_id;
    if (cat_id) {
      const category = categories.find(cat => cat.id == cat_id);
      if (category && !isCategoryNonMedicated(category)) {
        return true;
      }
    }
    return false;
  });
});


/**
 * Return true if at least one package, selected for modification, is waste.
 */
export const hasWastePackages = createSelector([getFormPackages], (packages) => {
  if (!Array.isArray(packages)) {
    return false;
  }

  return packages.some(pkg => {
    if (pkg.is_waste == 1) {
      return true;
    }

    return false;
  });
});