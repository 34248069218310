import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {InputGroup, FormControl, Col, DropdownButton, Dropdown} from 'react-bootstrap';
import {FaCheck, FaBarcode, FaSearch} from 'react-icons/fa';

const Search = (props) => {
  const {
    searchString, updateSearch, scanSearchEnabled, columns, scanSearchCol, setScanSearchCol, searchPlaceholder,
    autoFocus, autoSuggestInput, hintClass, hintText, className
  } = props;
  let {handleKeyPress} = props;
  const colName = scanSearchEnabled && scanSearchCol ? I18n.t(columns.find(column => scanSearchCol === column.dataId).name || scanSearchCol) : '' ;
  if (!handleKeyPress) {
    handleKeyPress = () => {};
  }
  return (
    <Col xs={12} md={{ span: 6, offset: 6 }} className={className ? `${className} search-wrapper` : 'search-wrapper'}>
      <InputGroup>
          {scanSearchEnabled ?
          <InputGroup.Append>
            <DropdownButton id='setScanSearchCol' size='sm' variant='link' title={colName} onSelect={setScanSearchCol}>
              {columns.filter(col => col.scanSearchColumn).map((col, index) =>
                <Dropdown.Item id={index} key={index} eventKey={col.dataId} >
                  {(col.dataId === scanSearchCol ? <FaCheck/> : null)} {I18n.t(col.name)}
                </Dropdown.Item>
              )}
            </DropdownButton>
          </InputGroup.Append> :
          null}
        {
          autoSuggestInput
            ? autoSuggestInput
            : (<FormControl
              type='text'
              autoFocus={autoFocus || scanSearchEnabled}
              placeholder={searchPlaceholder ? searchPlaceholder : I18n.t('search')}
              value={searchString}
              onChange={updateSearch}
              onKeyPress={handleKeyPress}
            />)
        }
        <InputGroup.Append>
          <InputGroup.Text>
            {scanSearchEnabled ? <FaBarcode/> : <FaSearch/>}
          </InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
      {
        !autoSuggestInput
          ? null
          : (<div style={{fontSize: 'smaller', paddingLeft: '2px'}} className={hintClass}>
            {I18n.t(hintText)}
          </div>)
      }
    </Col>);
};

Search.propTypes = {
  searchString: PropTypes.string.isRequired,
  updateSearch: PropTypes.func.isRequired,
  scanSearchEnabled: PropTypes.bool.isRequired,
  columns: PropTypes.array,
  scanSearchCol: PropTypes.string,
  setScanSearchCol: PropTypes.func,
  handleKeyPress: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  autoSuggestInput: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  hintText: PropTypes.string,
  hintClass: PropTypes.string,
  className: PropTypes.string,
};

export default Search;
