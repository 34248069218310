/* eslint-disable import/prefer-default-export */
import {createSelector} from 'reselect';
import get from 'lodash.get';
import * as itemNames from '../constants/itemNames'; //eslint-disable-line
import * as dataNames from '../constants/dataNames'; //eslint-disable-line
import {LABEL_TYPES} from '../constants/labelTypes'; //eslint-disable-line import/prefer-default-export
import {getActiveFacilityId} from './facilitiesSelectors';

export const getLabelsCompliance = state => state[itemNames.labelsCompliance];
export const getLabelTags = state => state[dataNames.labelTags];
const getLabelsConfiguration = state => state[dataNames.labelsConfiguration];
const getLabel = state => state.labelTag;
const getPrintLabel = state => state[itemNames.printLabel];
const getPrintJob = state => state[itemNames.printJob];

const getInitialValues = (complianceSettings, formConfiguration) => {

  return formConfiguration.reduce( (acc, label) => {

    if(!label.configurable) return acc;

    // Use saved settings if they exist.
    let settings = false;
    if(complianceSettings !== undefined && complianceSettings !== null) {
      if (Object.keys(complianceSettings).length > 0) {
        if (complianceSettings[label.setting_key] !== undefined) {
          if(Array.isArray(complianceSettings[label.setting_key])) {
            settings = complianceSettings[label.setting_key][0];
          } else {
            settings = complianceSettings[label.setting_key].value[0];
          }
        }
      }
    }

    acc[label.type] = label.fields.reduce( (ac, field) => {

      ac[field.name] = (settings) // have settings?
        ? (settings[field.name] !== undefined) // have field?
          ? settings[field.name] // yes - use
          : field.value // no field use default
        : field.value; // no settings use default

      return ac;
    }, {});
    return acc;
  }, {afterSubmit: ''});

};

export const getComplianceInitialValues = createSelector(
  [getLabelsCompliance, getLabelsConfiguration],
    (complianceSettings, formConfiguration) => {
      return getInitialValues(complianceSettings, formConfiguration);
    });

export const getLabelComplianceInitialValues = createSelector(
  [getLabelsCompliance, getLabelsConfiguration, getLabel],
  (complianceSettings, formConfiguration, currentLabel) => {

    // Updated method to build the initial values (which itself includes a builder object 'fields').  One day this WHOLE page should be refactored.  Very non standard.
    const settingsKey = get(currentLabel, 'compliance_settings_key', '');
    const complianceKey = get(currentLabel, 'compliance_key', '');
    const settings = get(complianceSettings, `${settingsKey}.value`, {});
    // Get settings whether in an object in an array, just object, or not present
    const labelSettings = Array.isArray(settings)
      ? get(settings, `0.${complianceKey}`, get(settings, '0'))
      : get(settings, complianceKey, settings);

    if(Object.keys(labelSettings).length){
      return {
        fields: get(complianceSettings, `defaults.${settingsKey}`, {}),
        package: Object.assign({}, labelSettings, {[complianceKey]: labelSettings, afterSubmit: ''}),
      };
    }

    // Tried and true method that is difficult to read but has always worked so left as fall through in case there's
    // a settings shape I missed.
    //@TODO: Figure out why these want to be in package top level item for the form
    if(Object.keys(complianceSettings).length > 0 && currentLabel && currentLabel.compliance_settings_key){
      if(complianceSettings[currentLabel.compliance_settings_key]){
        const settings = complianceSettings[currentLabel.compliance_settings_key];
        let userSettings = {};
        if(Array.isArray(settings.value)) {
          if (settings.value[0][currentLabel.compliance_key]) {
            userSettings = {package: settings.value[0][currentLabel.compliance_key]};
          } else {
            userSettings = {package: settings.value[0]};
          }
        } else {
          userSettings = {package: settings.value};
        }
        userSettings.package.afterSubmit = '';
        userSettings.fields = complianceSettings.defaults[currentLabel.compliance_settings_key];
        return userSettings;
      }
    }

    return {};

  });

export const getDefaultLabelType = createSelector([getLabelsCompliance], (settings) => {
  if(settings === undefined || !settings || Object.keys(settings).length === 0 || !settings.label_global_settings) return 'image';
  return (settings.label_global_settings && settings.label_global_settings.value &&  settings.label_global_settings.value.default_label_type)
    ? settings.label_global_settings.value.default_label_type
    : settings.defaults ? settings.defaults.label_global_settings.default_label_type.default : 'image';
});

export const getPrinters = () => {
  const printers = localStorage.getItem('printerSettings');
  if(printers) return JSON.parse(printers);
  return {};
};

const setPrinters = (printers) => {
  printers = JSON.stringify(printers);
  return localStorage.setItem('printerSettings', printers);
};

export const updatePrinter = (updatedPrinter) => {
  const printers = getPrinters();
  // Get a reference to this printer in every saved label type.  This is inefficient and local printers shoulud be stored separately but until
  // we can remove all usage of the original printer setttings, that can't be done...
  const printerReferences = [];
  if(!updatedPrinter.server) updatedPrinter.server = {id: 0};
  Object.keys(printers).forEach((key) => {
    const p = printers[key].find((p) => {
      if(!p.server) p.server = {id: 0};
      return p.name === updatedPrinter.name && p.server.id === updatedPrinter.server.id;
    });
    if(p) {
      printerReferences.push(p);
    } else {
      const newPrinter = Object.assign({}, updatedPrinter);
      printers[key].push(newPrinter);
      printerReferences.push(newPrinter);
    }
  });
  // Update the printer in each location
  printerReferences.forEach((p) => {
    Object.keys(updatedPrinter).forEach((key) => {
      p[key] = updatedPrinter[key];
    });
  });
  return setPrinters(printers);
};

export const setSelectedPrinter = (updatedPrinter, printerKey) => {
  const printers = getPrinters();
  if(!printers[printerKey]){
    printers[printerKey] = [updatedPrinter];
  } else {
    printers[printerKey] = printers[printerKey].map((p) => {
      p.selected = false;
      return p;
    });
  }
  setPrinters(printers);
  return updatePrinter(updatedPrinter);
};

// Returns the default label printer based on the tag passed in
export const getDefaultLabelPrinter = (state, props) => {
  const tag = props.tag ? props.tag : '';
  if(!tag) return false;
  const printerKey = Object.keys(LABEL_TYPES).reduce((acc, key) => {
    if(acc) return acc;
    const tagIndex = get(LABEL_TYPES[key], 'tags', []).indexOf(tag);
    if(tagIndex !== -1) acc = key;
    return acc;
  }, false);

  const printers = getPrinters();
  return printerKey
    ? printers[printerKey]
      ? printers[printerKey].find((p) => p.selected) ? printers[printerKey].find((p) => p.selected) : false
      : false
    : false;
};

export const getSelectedPrinter = (state) => state[itemNames.selectedPrinter];

export const isAutoPrintEnabled = createSelector([getSelectedPrinter], (printer) => {
  return printer && printer.autoPrint;
});

export const getPrintLabels = createSelector([getPrintLabel], (label) => {
  return label.labels ? label.labels : [{label: label, success: true}];
});

const getAllDefaultLabels = () => {
  const defaultLabels = localStorage.getItem('defaultLabels');
  if(defaultLabels) return JSON.parse(defaultLabels);
  return {};
};

const getFacilityDefaultLabels = (facilityId) => {
  const defaultLabels = getAllDefaultLabels();
  const facilityDefaultLabels = defaultLabels[`facility_${facilityId}`];
  return facilityDefaultLabels ? facilityDefaultLabels : false;
};

export const getDefaultLabels = createSelector([getActiveFacilityId], (facilityId) => {
  return getFacilityDefaultLabels(facilityId);
});

export const getDefaultLabel = (facilityId, currentTag) => {
  const defaultLabels = getFacilityDefaultLabels(facilityId);
  if(!defaultLabels) return false;
  const defaultLabel = defaultLabels[currentTag];
  return defaultLabel ? defaultLabel : false;
};


export const setDefaultLabel = (facilityId, tag, defaultTag) => {
  const defaultLabels = getAllDefaultLabels();
  let facilityDefaultLabels;
  const facilityKey = `facility_${facilityId}`;
  if(defaultLabels[facilityKey]){
    facilityDefaultLabels = defaultLabels[facilityKey];
  } else {
    facilityDefaultLabels = {};
    defaultLabels[facilityKey] = facilityDefaultLabels;
  }
  facilityDefaultLabels[tag] = defaultTag;
  localStorage.setItem('defaultLabels', JSON.stringify(defaultLabels));
  return true;
};

export const getPrintServers = createSelector([getLabelsCompliance], (settings) => {
  return get(settings, 'label_global_settings.value.print_servers', get(settings, 'defaults.label_global_settings.print_servers.default', []));
});

const getPrintServerId = (_, props) => props.printServerId;

export const getPrintServer = createSelector([getPrintServers, getPrintServerId], (printServers, printServerId) => {
  const defaultServer = {printers: [], active: 0};
  if(!Array.isArray(printServers)) return defaultServer;
  const server = printServers.find((server) => server.id === parseInt(printServerId));
  return server ? server : defaultServer;
});

export const getPrintServerPayload = (values, settings, destroy = false) => {
  // To support existing post shapes send all existing global values plus new printer settings
  const globalKey = 'label_global_settings';
  const fields = Object.keys(settings.defaults[globalKey]);
  const template = {
    settings: [
      {
        setting_key: globalKey,
        scope: 'facility',
        target: 0,
        value: {},
      }
    ]
  };
  fields.forEach((field) => {
    const source = get(settings, `${globalKey}.value.${field}`, get(settings, `defaults.${globalKey}.${field}.default`));
    if(Array.isArray(source)){
      template.settings[0].value[field] = source.map((item) => item);
    } else {
      template.settings[0].value[field] = (typeof source === 'object') ? Object.assign({}, source) : source;
    }
  });
  if(values.printers && Array.isArray(values.printers)){
    values.printers = values.printers.map((printer) => {
      delete(printer.present);
      delete(printer.saved);
      return printer;
    });
  }
  if(values.id){
    const index = template.settings[0].value.print_servers.findIndex((server) => server.id === values.id);
    if(destroy){
      template.settings[0].value.print_servers = template.settings[0].value.print_servers.filter((server, serverIndex) => serverIndex !== index);
    } else {
      template.settings[0].value.print_servers[index] = values;
    }
  } else {
    values.id = template.settings[0].value.print_servers.reduce((acc, server) => {
      if(parseInt(server.id) > acc) acc = server.id;
      return acc;
    }, 0) + 1;
    template.settings[0].value.print_servers.push(values);
  }
  return template;
};

const getLabelTag = (_, props) => props.labelTag ? props.labelTag : undefined;

export const getLabelName = createSelector([getLabelTag], (tag) => {
  return Object.keys(LABEL_TYPES).reduce((acc, key) => {
    if(acc) return acc;
    const tagIndex = get(LABEL_TYPES[key], 'tags', []).indexOf(tag);
    if(tagIndex !== -1) acc = LABEL_TYPES[key].name;
    return acc;
  }, null);
});

export const getPrinterKey = createSelector([getLabelTag], (tag) => {
  return Object.keys(LABEL_TYPES).reduce((acc, key) => {
    if(acc) return acc;
    const tagIndex = get(LABEL_TYPES[key], 'tags', []).indexOf(tag);
    if(tagIndex !== -1) acc = key;
    return acc;
  }, null);
});

export const getPrintJobStats = createSelector([getPrintJob, getPrintLabel], (printJob, printLabel) => {
  return {
    distinctLabels: !Object.keys(printLabel).length || !printLabel.blocks ? 0 : printLabel.blocks.reduce((acc, block) => {
      acc += block.length;
      return acc;
    }, 0),
    totalLabels: !Object.keys(printLabel).length || !printLabel.blocks ? 0 : printLabel.blocks.reduce((acc, block) => {
      acc += block.reduce((acc, item) => {
        acc += parseInt(item.quantity);
        return acc;
      }, 0);
      return acc;
    }, 0),
    totalBlocks: !Object.keys(printJob).length || !printJob.blocks_all ? 0 : printJob.blocks_all.length,
    loadedBlocks: !Object.keys(printJob).length || !printJob.blocks_loaded ? 0 : printJob.blocks_loaded.length,
  };
});
