import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import {formValueSelector, reduxForm, change} from 'redux-form';
import {connect} from 'react-redux';
import {PLANT_COMPLIANCE_FORM} from '../../../constants/forms';

import PlantComplianceSettingsForm from './PlantComplianceSettingsForm';
import { validate } from './validate';

const form = PLANT_COMPLIANCE_FORM;
const PlantComplianceReduxForm = reduxForm({
  form,
  validate,
  enableReinitialize: true
})(PlantComplianceSettingsForm);

const selector = formValueSelector(form);
function mapStateToProps(state) {
  const trackPlantsAsGroup = selector(state, 'cult_track_plants_as_groups');
  return { trackPlantsAsGroup };
}

function mapDispatchToProps(dispatch) {
  const actions = {change};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

const PlantComplianceFormWrapper = connect(mapStateToProps, mapDispatchToProps)(PlantComplianceReduxForm);

PlantComplianceFormWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default PlantComplianceFormWrapper;
