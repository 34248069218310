import PropTypes from 'prop-types';
import {reduxForm, formValueSelector} from 'redux-form';
import {connect} from 'react-redux';
import {getCategories} from '../../../../selectors/categorySelectors';
import {getCustomerGroups} from '../../../../selectors/customerGroupsSelectors';
import {getPartnerOrganization} from '../../../../selectors/partnersSelectors';
import {getProducts} from '../../../../selectors/productsSelector';
import {getProductLists} from '../../../../selectors/couponsSelectors';
import validate from '../common/validate';
import CouponForm from '../common/CouponForm';
import {CREATE_COUPON_FORM} from '../../../../constants/forms';

const formSelector = formValueSelector(CREATE_COUPON_FORM);

export const CreateCouponReduxForm = reduxForm({
  form: CREATE_COUPON_FORM,
  validate,
})(CouponForm);

function mapStateToProps(state) {
  const initialValues = {
    facility_ids: [],
    consumer_group_ids: [],
    category_ids: [],
    vendor_ids: [],
    item_master_ids: [],
    product_list_ids: [],
    days_available: [],
  };
  return {
    initialValues,
    discountType: formSelector(state, 'discount_type'),
    couponAppliesTo: formSelector(state, 'coupon_applies_to'),
    couponType: formSelector(state, 'coupon_type'),
    categories: getCategories(state),
    customerGroups: getCustomerGroups(state),
    products: getProducts(state),
    productLists: getProductLists(state),
    vendors: getPartnerOrganization(state),
  };
}

const CreateCouponFormWrapper = connect(mapStateToProps)(CreateCouponReduxForm);

CreateCouponFormWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default CreateCouponFormWrapper;
