import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import ContentConcealer from '../../common/concealers/ContentConcealer';

import {RECREATIONAL, MEDICAL} from '../../../constants/consumerTypes';

const PricingBlockHistory = ({pricing, uom}) => {
  const weights = pricing.weight_prices.filter(weight => parseFloat(weight.default_price));
  const showBlock = parseFloat(pricing.default_price) || pricing.facility_ids.length || pricing.pricing_class_id || pricing.pricing_group_id
  || weights.length;
  const titleLevel = pricing.is_default_for_org ? I18n.t('products.form.organization') : I18n.t('products.form.byFacility');
  const titleType = pricing.consumer_type === MEDICAL ? I18n.t('products.form.medicalPrices')
    : pricing.consumer_type === RECREATIONAL ? I18n.t('products.form.recreationalPrices') : I18n.t('products.form.wholesalePrices');
  return (<ContentConcealer show={showBlock}>
      <tr>
        <td colSpan={2}>{titleType} {titleLevel}</td>
      </tr>
      <ContentConcealer show={!!parseFloat(pricing.is_non_taxable)}>
        <tr>
          <td style={{paddingLeft: '30px'}}>{I18n.t('products.form.nonTaxableProduct')}</td>
          <td>{pricing.is_non_taxable ? 'Yes' : 'No'}</td>
        </tr>
      </ContentConcealer>
      <ContentConcealer show={!!parseFloat(pricing.default_price)}>
        <tr>
          <td>{I18n.t('products.form.basePrice', {uom})}</td>
          <td>{pricing.default_price}</td>
        </tr>
      </ContentConcealer>
      <ContentConcealer show={!!pricing.group_name}>
        <tr>
          <td style={{paddingLeft: '30px'}}>{I18n.t('products.form.pricingGroup')}</td>
          <td>{pricing.group_name}</td>
        </tr>
      </ContentConcealer>
      <ContentConcealer show={!!parseFloat(pricing.inherit_pricing_group_updates)}>
        <tr>
          <td style={{paddingLeft: '30px'}}>{I18n.t('products.form.inheritGroupChanges')}</td>
          <td>{pricing.inherit_pricing_group_updates ? 'Yes' : 'No'}</td>
        </tr>
      </ContentConcealer>
      <ContentConcealer show={!!pricing.class_name}>
        <tr>
          <td style={{paddingLeft: '30px'}}>{I18n.t('products.form.pricingClass')}</td>
          <td>{pricing.class_name}</td>
        </tr>
      </ContentConcealer>
      <ContentConcealer show={!!parseFloat(pricing.inherit_pricing_class_updates)}>
        <tr>
          <td style={{paddingLeft: '30px'}}>{I18n.t('products.form.inheritClassChanges')}</td>
          <td>{pricing.inherit_pricing_class_updates ? 'Yes' : 'No'}</td>
        </tr>
      </ContentConcealer>
      <ContentConcealer show={!!weights.length}>
        <tr>
          <td style={{paddingLeft: '30px'}}>{I18n.t('products.form.unit')}</td>
          <td >{I18n.t('products.form.defaultPrice')}</td>
        </tr>
        {weights.map(weight => (
          <tr key={weight.title}>
            <td style={{paddingLeft: '30px'}}>{weight.title}</td>
            <td>{weight.default_price}</td>
          </tr>
        ))}
      </ContentConcealer>
    </ContentConcealer>
    );
};

PricingBlockHistory.propTypes = {
  pricing: PropTypes.object.isRequired,
  uom: PropTypes.string.isRequired
};

export default PricingBlockHistory;