export const reservationSources = {
  order: 'POS',
  processing_run: 'Manufacturing',
  infusion_run: 'Manufacturing',
  packaging_job: 'Packaging',
  transfer: 'Transfer',
  sales_order: 'Sales Order',
};

export const reservationLinkMap = {
  order: '/product-menu/:id',
  processing_run: '/ei/processing/complete/:id',
  infusion_run: '/ei/infusions/complete/:id',
  packaging_job: '/packages/complete/:id',
  transfer: '/transfers/modify/:id',
  sales_order: '/sales-orders/modify/:id',
};
