import {
  BT_BUBBLE_HASH,
  BT_CANNABIS_EXTRACT_FOR_INHALATION,
  BT_CANNABIS_INFUSED_TOPICALS,
  BT_CANNABIS_MIX,
  BT_CANNABIS_MIX_INFUSED,
  BT_CANNABIS_MIX_PACKAGED,
  BT_CO2_HASH_OIL,
  BT_FLOWER_LOT,
  BT_FOOD_GRADE_SOLVENT_EXTRACT,
  BT_HASH,
  BT_HYDROCARBON_WAX,
  BT_INFUSED_COOKING_OIL,
  BT_INFUSED_DAIRY_BUTTER,
  BT_KIEF,
  BT_LIQUID_CANNABIS_INFUSED_EDIBLE,
  BT_OTHER_MATERIAL_LOT,
  BT_SAMPLE_JAR,
  BT_SOLID_CANNABIS_INFUSED_EDIBLE,
  BT_USABLE_CANNABIS
} from './biotrackInventoryTypes';


const BT_IL_INTERMEDIATE_PRODUCTS = [
  BT_BUBBLE_HASH, BT_CO2_HASH_OIL, BT_FOOD_GRADE_SOLVENT_EXTRACT, BT_HASH, BT_HYDROCARBON_WAX, BT_INFUSED_COOKING_OIL,
  BT_INFUSED_DAIRY_BUTTER, BT_KIEF
];

export const BT_IL_ASSEMBLY_PRODUCTS = [
  BT_LIQUID_CANNABIS_INFUSED_EDIBLE,
  BT_CANNABIS_INFUSED_TOPICALS,
  BT_CANNABIS_MIX_INFUSED,
  BT_SOLID_CANNABIS_INFUSED_EDIBLE,
];

/**
 * Package `flower lot` to `usable marijuana` or `sample jar`
 * Package `marijuana mix` to `packaged marijuana mix`
 * Package `intermediate products` to `marijuana extract for inhalation`
 * @type {Object}
 */
export const BT_IL_PACKAGING_RULES = {
  [BT_FLOWER_LOT]: [BT_USABLE_CANNABIS, BT_SAMPLE_JAR],
  [BT_CANNABIS_MIX]: [BT_CANNABIS_MIX_PACKAGED],
  [BT_BUBBLE_HASH]: [BT_CANNABIS_EXTRACT_FOR_INHALATION],
  [BT_CO2_HASH_OIL]: [BT_CANNABIS_EXTRACT_FOR_INHALATION],
  [BT_FOOD_GRADE_SOLVENT_EXTRACT]: [BT_CANNABIS_EXTRACT_FOR_INHALATION],
  [BT_HASH]: [BT_CANNABIS_EXTRACT_FOR_INHALATION],
  [BT_HYDROCARBON_WAX]: [BT_CANNABIS_EXTRACT_FOR_INHALATION],
  [BT_INFUSED_COOKING_OIL]: [BT_CANNABIS_EXTRACT_FOR_INHALATION],
  [BT_INFUSED_DAIRY_BUTTER]: [BT_CANNABIS_EXTRACT_FOR_INHALATION],
  [BT_KIEF]: [BT_CANNABIS_EXTRACT_FOR_INHALATION],
};

/**
 * Process `flower lot` or `other material lot` to `marijuana mix`
 * Process lot product to intermediate product
 * @type {Object}
 */
export const BT_IL_PROCESSING_RULES = {
  [BT_FLOWER_LOT]: [...BT_IL_INTERMEDIATE_PRODUCTS, BT_CANNABIS_MIX],
  [BT_OTHER_MATERIAL_LOT]: [...BT_IL_INTERMEDIATE_PRODUCTS, BT_CANNABIS_MIX],
  [BT_CANNABIS_MIX]: BT_IL_INTERMEDIATE_PRODUCTS,
};


/**
 * Infuse `flower lot` or `marijuana mix` to `marijuana mix infused`
 * Infuse `intermediate products` to `assembly products`
 * @type {Object}
 */
export const BT_IL_INFUSION_RULES = {
  [BT_FLOWER_LOT]: [BT_CANNABIS_MIX_INFUSED],
  [BT_CANNABIS_MIX]: [BT_CANNABIS_MIX_INFUSED],
  [BT_BUBBLE_HASH]: BT_IL_ASSEMBLY_PRODUCTS,
  [BT_CO2_HASH_OIL]: BT_IL_ASSEMBLY_PRODUCTS,
  [BT_FOOD_GRADE_SOLVENT_EXTRACT]: BT_IL_ASSEMBLY_PRODUCTS,
  [BT_HASH]: BT_IL_ASSEMBLY_PRODUCTS,
  [BT_HYDROCARBON_WAX]: BT_IL_ASSEMBLY_PRODUCTS,
  [BT_INFUSED_COOKING_OIL]: BT_IL_ASSEMBLY_PRODUCTS,
  [BT_INFUSED_DAIRY_BUTTER]: BT_IL_ASSEMBLY_PRODUCTS,
  [BT_KIEF]: BT_IL_ASSEMBLY_PRODUCTS,
};

export const BT_IL_END_PRODUCTS = [
  BT_SOLID_CANNABIS_INFUSED_EDIBLE,
  BT_LIQUID_CANNABIS_INFUSED_EDIBLE,
  BT_CANNABIS_EXTRACT_FOR_INHALATION,
  BT_CANNABIS_INFUSED_TOPICALS,
  BT_USABLE_CANNABIS,
  BT_CANNABIS_MIX_PACKAGED,
  BT_CANNABIS_MIX_INFUSED,
];

export const BT_IL_CONVERSION_PRODUCTS = [
  BT_KIEF, BT_BUBBLE_HASH, BT_HASH, BT_HYDROCARBON_WAX, BT_CO2_HASH_OIL, BT_FOOD_GRADE_SOLVENT_EXTRACT,
  BT_INFUSED_DAIRY_BUTTER, BT_INFUSED_COOKING_OIL, BT_SOLID_CANNABIS_INFUSED_EDIBLE, BT_LIQUID_CANNABIS_INFUSED_EDIBLE,
  BT_CANNABIS_EXTRACT_FOR_INHALATION, BT_CANNABIS_INFUSED_TOPICALS, BT_SAMPLE_JAR, BT_USABLE_CANNABIS,
  BT_CANNABIS_MIX, BT_CANNABIS_MIX_PACKAGED, BT_CANNABIS_MIX_INFUSED,
];

export const BT_PACKAGING_TYPES = [
  BT_CANNABIS_MIX_PACKAGED, BT_USABLE_CANNABIS, BT_SAMPLE_JAR, BT_CANNABIS_EXTRACT_FOR_INHALATION
];
