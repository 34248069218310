import { createSelector } from 'reselect';
//import {getSettings} from '../salesSettingsSelectors';
import {isOklahoma} from '../facility/getCurrentFacility';
import {isDisabledSubcategoryForOklahoma} from '../subcategoriesSelectors';
import {salesComplianceSettings} from '../../constants/itemNames';

const getSubcategoryCode = (_, props) => props.subcategory_code;

export const getSettings = (state) => state[salesComplianceSettings];

/***
 * Returns array of text/value objects representing the available sales rules categories
 * base on those returns with sales limit call.
 */
export const getSalesRulesCategories = createSelector([getSettings], (settings) => {
  const categories = (settings && settings.order_sales_limit_categories)
    ? settings.order_sales_limit_categories
    : {};
  return categories.value && !Array.isArray(categories.value) && Object.keys(categories.value).length
    ? Object.keys(categories.value).reduce((acc, key) => {
      acc.push({value: key, text: categories.value[key]});
      return acc;
    }, [])
    : [];
});

export const isDisabledSubcategory = createSelector([isOklahoma, getSubcategoryCode], isDisabledSubcategoryForOklahoma);

export default getSalesRulesCategories;
