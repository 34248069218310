import { formValueSelector, getFormSyncErrors, getFormValues } from 'redux-form';
import get from 'lodash.get';
import sortBy from 'lodash.sortby';
import { createSelector } from 'reselect';
import * as dataNames from '../constants/dataNames';
import * as itemNames from '../constants/itemNames';
import { SUPPLY_CHAIN_MAPPING } from '../constants/forms';
import * as uoms from '../constants/uoms';
import { convertFromBase } from '../util/uomHelpers';
import {getInternationalDateFormat, getInternationalNumberFormat} from './InternationalOperationsSelectors';
import { fieldFormatHelperDecimals } from '../util/internationalHelpers';
import {formatInternationalInputDate, convertDbDateToFormInputDate} from '../util/dateHelpers';
import {isFeatureEnabled} from './featureToggles';
import { getSupplySettings } from './supplySelectors';
import {getFacilityHasModule, isLeafPaConfigPackClosedLoopFacility} from './facilitiesSelectors';
import {compareTwoStrings} from '../util/sortHelper';

export const formSelector = formValueSelector(SUPPLY_CHAIN_MAPPING);
export const supplyChainFormValueSelector = (state) => (value) => formSelector(state, value);
const getPlatformTransfers = (state) => state[dataNames.platformIncomingTransfers];
const getPlatformPurchaseOrders = (state) => state[dataNames.platformPurchaseOrders];
const getItemMasters = (state) => state[dataNames.itemMasters];
export const getItemMasterMappings = (state) => state[dataNames.itemMasterMappings];
export const getPlatformIncomingTransferDetails = (state) => state[itemNames.platformIncomingTransferDetails];
const getPlatformPurchaseOrderDetails = (state) => state[itemNames.platformPurchaseOrderDetails];
export const errorSelector = getFormSyncErrors(SUPPLY_CHAIN_MAPPING);
export const formValuesSelector = getFormValues(SUPPLY_CHAIN_MAPPING);
export const getTimeZone = (state) => state.facility['timezone'];
const isAutoPoGenerationForFacilitiesFeatureEnabled = (state) => isFeatureEnabled(state)('feature_auto_po_generation_for_facilities');

export const getPlatformIncomingTransfersOptions = createSelector(
  [getPlatformTransfers, getTimeZone, getInternationalDateFormat],
  (transfers, timezone, dateFormat) =>
    sortBy(
      transfers.map((transfer) => {
        const date_ordered = formatInternationalInputDate(convertDbDateToFormInputDate(get(transfer, 'date_ordered', ''), timezone), dateFormat);
        return {
          value: transfer.transfer_id,
          text: `${transfer.transfer_from_name} - ${date_ordered} - ${transfer.transfer_name}`
        };
      }),
      'text'
    )
);

export const getPlatformPurchaseOrdersOptions = createSelector(
  [getPlatformPurchaseOrders, getTimeZone, getInternationalDateFormat],
  (purchaseOrders, timezone, dateFormat) =>
    sortBy(
      purchaseOrders.map((purchaseOrder) => {
        const date_ordered = formatInternationalInputDate(convertDbDateToFormInputDate(get(purchaseOrder, 'date_ordered', ''), timezone), dateFormat);
        const text = `${purchaseOrder.partner_facility} - ${date_ordered} - ${purchaseOrder.po_number}`;
        return {
          value: purchaseOrder.id,
          text
        };
      }),
      'text'
    )
);

const getPlatformPurchaseOrderLines = createSelector(
  [getPlatformPurchaseOrderDetails],
  (order) => order.lines || []
);

export const purchaseOrderInfo = createSelector(
  [getPlatformPurchaseOrderLines, getItemMasters],
  (orderLines, itemMasters) => {
    if (!itemMasters || !itemMasters.length) {
      return; // Early exit in case item masters haven't loaded yet
    }
    const lines = orderLines.reduce((lines, orderLine) => {
      if (orderLine && orderLine.subitems && orderLine.subitems.length) {
        return orderLine.subitems.reduce((lines, subItem) => {
          if (subItem.qty > 0) {
            const itemMaster = itemMasters.find((im) => im.id === subItem.item_master_id);

            // If new item master(s) are created, they may not be found on the first iteration. No problem, just exit out. They will be there on the next one.
            if (!itemMaster) {
              return;
            }

            return lines.concat({
              line_id: `${orderLine.id}_${subItem.id}`,
              item_master_id: subItem.item_master_id,
              item_master_name: itemMaster.name,
              item_master_category_id: itemMaster.category_id,
              item_master_category_name: itemMaster.category.name,
              qty_display: subItem.qty,
              uom_display: get(itemMaster, 'inventory_attributes.is_prepack', false) ? uoms.EA : uoms.GR,
              is_prepack: get(itemMaster, 'inventory_attributes.is_prepack', false),
              unit_price: subItem.unit_price,
              product_map: 'product_map_' + `${orderLine.id}_${subItem.id}`
            });
          }

          return lines;
        }, lines);
      }

      const itemMaster = itemMasters.find((im) => im.id === orderLine.item_master_id);

      // If new item master(s) are created, they may not be found on the first iteration. No problem, just exit out. They will be there on the next one.
      if (!itemMaster) {
        return;
      }

      return lines.concat({
        line_id: orderLine.id,
        item_master_name: itemMaster.name,
        item_master_category_id: itemMaster.category_id,
        item_master_category_name: itemMaster.category.name,
        item_master_id: orderLine.item_master_id,
        qty_display: orderLine.qty,
        uom_display: orderLine.uom,
        is_prepack: get(itemMaster, 'inventory_attributes.is_prepack', false)
      });
    }, []);

    return {
      lines
    };
  }
);

export const getPlatformPurchaseOrderItemMasterIds = createSelector(
  [getPlatformPurchaseOrderLines],
  (lines) =>
    lines.reduce((ids, line) => {
      //Handle pre-packs
      if (line && line.subitems && line.subitems.length) {
        return line.subitems.reduce(
          (ids, subitem) => (subitem && subitem.qty > 0 ? ids.concat(subitem.item_master_id) : ids),
          ids
        );
      }
      return ids.concat(line.item_master_id);
    }, [])
);

export const getPlatformIncomingTransfersProductsOptions = createSelector(
  [getPlatformIncomingTransferDetails, getInternationalNumberFormat],
  (transferDetails, numberFormat) => {
    if (!Array.isArray(transferDetails.lines)) {
      return [];
    }

    const dropdownOptions = transferDetails.lines.reduce((options, line) => {
      if (!Array.isArray(line.inventory) || !line.inventory.length) {
        return [];
      }
      const lineOptions = line.inventory.map((pkg, index) => {
        pkg.qty_display = convertFromBase(pkg.qty_base, pkg.uom_display);
        const qty_display_inline = fieldFormatHelperDecimals(pkg.qty_display, 'inline_quantity', numberFormat);
        return {
          ...pkg,
          value: pkg.id,
          text: `${pkg.item_master_name} - ${pkg.package_code} - ${qty_display_inline} ${pkg.uom_display}`,
        };
      });

      return options.concat(lineOptions);
    }, []);

    return dropdownOptions;
  }
);

/**
 * For each inventory item in the incoming transfer, get a list of compatible item master from the receiving facility
 * The list is indexed by inventory item id.
 */
export const getCompatibleItemMasters = createSelector(
  [getPlatformIncomingTransfersProductsOptions, getItemMasters],
  (incomingTransferDetails, itemMasters) => {

    if (!incomingTransferDetails || !Array.isArray(incomingTransferDetails) || !incomingTransferDetails.length) {
      return {};
    }

    if (!itemMasters || !Array.isArray(itemMasters) || !itemMasters.length) {
      return {};
    }

    const compatiblesItemMastersByLine = incomingTransferDetails.reduce((acc, item) => {

      const lineCompatibleItemMasters = itemMasters.filter((itemMaster) => {
        const matchingCategory = get(item, 'item_master_category_id') ===  get(itemMaster, 'category_id');
        const matchingSubcategory = get(item, 'item_master_subcategory_id') === get(itemMaster, 'subcategory_id');
        const matchingUOM = get(item, 'uom_display') === get(itemMaster, 'default_uom');

        // Match prepack
        const matchingPrepack = get(itemMaster, 'inventory_attributes.item_master_parent_id') ? true : false === get(item, 'is_prepack');

        // Match weight (relevant for prepacks only)
        let matchingWeight = true;
        if (get(item, 'is_prepack')) {
          const itemWeight = get(item, 'item_master_name').includes(': ') ? get(item, 'item_master_name').split(': ').pop() : '';
          const itemMasterWeight = itemMaster['name'].includes(': ') ? itemMaster['name'].split(': ').pop() : '';
          matchingWeight = itemWeight === itemMasterWeight;
        }

        // found a suitable match for a preexisting item master
        return matchingCategory && matchingSubcategory && matchingUOM && matchingPrepack && matchingWeight;
      })
        .map((itemMaster) => {
          const {id, active, item_number, name, subcategory_id, category, category_id, inventory_attributes, default_uom} = itemMaster;
          return {id, active, item_number, name, subcategory_id, category, category_id, inventory_attributes, default_uom};
        });

      return {
        ...acc,
        [item.id]: lineCompatibleItemMasters,
      };
    }, {});

    return compatiblesItemMastersByLine;
  });

/**
 * Replace all item_master_id and item_master_name with values, manually mapped from Purchase Order.
 */
export const mapItemMasters = (formValues, purchaseOrder) => {
  let mappedValues = { ...formValues };
  if (Array.isArray(purchaseOrder.lines)) {
    purchaseOrder.lines.forEach((line) => {
      const key = `product_map_${line.line_id}`;
      if (Array.isArray(mappedValues[key])) {
        mappedValues = {
          ...mappedValues,
          [key]: mappedValues[key].map((item) => ({
            ...item,
            item_master_id: line.item_master_id,
            item_master_name: line.item_master_name
          }))
        };
      }
    });
  }
  return mappedValues;
};


export const canGenerateAutomaticPO = createSelector(
  [getSupplySettings, isAutoPoGenerationForFacilitiesFeatureEnabled, getFacilityHasModule, isLeafPaConfigPackClosedLoopFacility],
  (supplyChainSettings, isAutoPoGenerationForFacilitiesFeatureEnabled, hasModule, isLeafPaConfigPackClosedLoopFacility) => {

    // Has to have the proper setting (Set to false if not PA or Utah)
    // This setting name is a bit misleading. It is valid for non-labs as well if FT enabled and part of PA closed loop
    if (!get(supplyChainSettings, 'supply_allow_autogenerated_po_for_lab.value', false)) {
      return false;
    }

    // Has to be a lab, or PA and feature enabled
    if (hasModule('TESTING_LAB') || (isLeafPaConfigPackClosedLoopFacility && isAutoPoGenerationForFacilitiesFeatureEnabled)) {
      return true;
    }

    return false;
  }
);


// Get matches made by previous Supply Chain Mappings
export const getPreviouslyMappedPackagesForItemMaster = (itemMasterId, itemMasterMappings, incomingTransferProductsOptions) => {
  // Find previous mappings
  const previousMappings = itemMasterMappings.filter(mapping => mapping.item_master_id === itemMasterId);
  // return matching product options
  return incomingTransferProductsOptions.filter((incomingTransferProductsOption) => {
    const isPreviouslyMapped = previousMappings.filter(mapping => {
      return mapping.partner_item_master_id === incomingTransferProductsOption.item_master_id;
    });
    return isPreviouslyMapped.length || incomingTransferProductsOption.item_master_id === itemMasterId;   // Potential package when it has a previous mapping, or it has the same item master id
  });
};


export const getInventoryPackages = (potentialPackages, targetQuantity) => {
  if(!Array.isArray(potentialPackages)){
    return [];
  }
  if(potentialPackages.length > 1){
    const quantities = potentialPackages.map((pkg) => pkg.qty_display);
    const result = getFirstValidCombination(quantities, targetQuantity);
    if(result && result.length) {
      return potentialPackages.filter((pkg) => {
        return result.indexOf(pkg.qty_display) !== -1;
      });
    }
  }
  return potentialPackages; // one or none return original
};


/**
 * Finds the first combination of numbers that equal the target returns those as an array.
 * @param numbers
 * @param target
 * @param partial
 * @returns {*}
 */
const getFirstValidCombination = (numbers, target, partial = []) => {
  // Sum
  const s = partial.reduce((a, b) => {
    return a + b;
  }, 0);

  // Is the partial a winner?
  if (s === target) {
    return partial;
  }

  // If we're over there are no matches
  if (s >= target) {
    return [];
  }

  for (let i = 0; i < numbers.length; i++) {
    const n = numbers[i];
    const remaining = numbers.slice(i + 1);
    const result = getFirstValidCombination(remaining, target, partial.concat([n]));
    if(result && result.length){
      return result;
    }
  }
};


/**
 * Sort product options for mapping.
 * Previously mapped packages are listed first, followed by remaining packages based on name similarity
 * @param productOptions
 * @param previouslyMappedPackages
 * @param line  Purchase Order Line these product options are used for
 * @returns {*[]|*}
 */
export const sortProductOptions = (productOptions, previouslyMappedPackages, line) => {
  // Get the indexes of the definite matches (i.e. previously mapped) from the default product options
  const previouslyMappedPackageIndexes = previouslyMappedPackages.map((pkg) => {
    //return productOptions.findIndex((option) => option.inventory_name === pkg.inventory_name);
    return productOptions.findIndex((option) => option.id === pkg.id);
  });

  // Use the indexes to group the product options; top = most likely matches to the po item master based on mapping
  const grouped = productOptions.reduce((acc, option, index) => {
    const group = previouslyMappedPackageIndexes.indexOf(index) !== -1 ? 'top' : 'balance';
    acc[group].push(option);
    return acc;
  }, {top: [], balance: []});

  // Sort remaining options on item master name similarity
  grouped.balance = grouped.balance.sort((first, second) => (compareTwoStrings(line.item_master_name, first.item_master_name) < compareTwoStrings(line.item_master_name, second.item_master_name)) ? 1 : -1);

  if(!grouped.top.length){ // Handle case where not top items exist
    return grouped.balance;
  }

  // Return a drop down list with best options at the top and an inline hint
  const divider = [{text: '=== Options Above This Line Are More Likely Matches ===', value: 'none', disabled: true}];
  return [].concat(grouped.top, divider, grouped.balance);
};
