import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { Button, ButtonToolbar, Col, Row } from 'react-bootstrap';
import PackagesFieldArray from './PackagesFieldArray';
import LotsFieldArray from './LotsFieldArray';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import InternationalDateTimePickerInput  from '../../common/form/InternationalDateTimePickerInput';
import TextAreaInput from '../../common/form/TextAreaInput';
import * as p from '../../../constants/permissions';
import PermissionWrapper from '../../common/PermissionWrapper';
import ProductLookup from '../../products/common/ProductLookup';
import WillRender from '../../common/concealers/WillRender';
import {leafWaReconciliationReasonsRequiringReceivedBy} from '../../../constants/leaf';

const holdOptions = [{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }];

const PackagesForm = (props) => {
  const {
    handleSubmit,
    reset,
    touch,
    change,
    submitting,
    pristine,
    invalid,
    showPrinter,
    saved,
    adjustmentReasons,
    packages,
    locations,
    phases,
    showPhases,
    integrationState,
    integrationAdjustmentReasons,
    trackingIds,
    hasGlobalAdjustmentReason,
    hasGlobalIntegrationAdjustmentReason,
    hasAllIntegrationAdjustmentReasons,
    hasPackagesTags,
    hasGlobalValues,
    partnerFacilities,
    lots,
    hasMedicatedPackages,
    hasWastePackages,
    hasPackageWithoutReason,
    showProductLookup,
    isModalOpen,
    filterBy,
    inventoryItems,
    inventoryTypes,
    showModalMedical,
    waPackagingInventoryTypes,
    facilityUsers,
    integrationAdjustmentReason,
    isFailingTrackingRequirements,
    isFailingTrackingRequirementsMessage,
    complianceSettings,
    form,
    cupos,
    hasManufacturing,
    validateProductCanBeChanged,
    productCanBeChanged,
    isMetrcStemHoldingsToggled,
    isMetrcCaDonationsToggled,
    isUtahGlobalIdGenerationFeatureEnabled,
    isFeatureToggleEditInventoryItemCost,
    requireOnHandProductExpireDate
  } = props;

  const { isMetrc, isPaLeaf, isWaLeaf, isBiotrack } = integrationState;
  const prefix = isBiotrack ? 'biotrack' : isMetrc ? 'metrc' : 'leaf';
  const show_tags = !isUtahGlobalIdGenerationFeatureEnabled && (hasMedicatedPackages && hasPackagesTags && !hasWastePackages);

  return (
    <form className='packages-form' onSubmit={handleSubmit}>
      <ProductLookup
        isModalOpen={isModalOpen}
        filterBy={filterBy}
      />
      <FieldArray
        name='packages'
        form={form}
        cupos={cupos}
        component={PackagesFieldArray}
        change={change}
        touch={touch}
        packages={packages}
        inventoryItems={inventoryItems}
        inventoryTypes={inventoryTypes}
        adjustmentReasons={adjustmentReasons}
        integrationState={integrationState}
        integrationAdjustmentReasons={integrationAdjustmentReasons}
        trackingIds={trackingIds}
        showReverseInventoryOption={props.showReverseInventoryOption}
        hasGlobalAdjustmentReason={hasGlobalAdjustmentReason}
        partnerFacilities={partnerFacilities}
        hasGlobalIntegrationAdjustmentReason={hasGlobalIntegrationAdjustmentReason}
        hasPackageWithoutReason={hasPackageWithoutReason}
        showProductLookup={showProductLookup}
        isModalOpen={isModalOpen}
        filterBy={filterBy}
        showModalMedical={showModalMedical}
        waPackagingInventoryTypes={waPackagingInventoryTypes}
        facilityUsers={facilityUsers}
        hasPackagesTags={hasPackagesTags}
        isFailingTrackingRequirements={isFailingTrackingRequirements}
        isFailingTrackingRequirementsMessage={isFailingTrackingRequirementsMessage}
        complianceSettings={complianceSettings}
        hasManufacturing={hasManufacturing}
        validateProductCanBeChanged={validateProductCanBeChanged}
        productCanBeChanged={productCanBeChanged}
        isMetrcStemHoldingsToggled={isMetrcStemHoldingsToggled}
        isMetrcCaDonationsToggled={isMetrcCaDonationsToggled}
        isFeatureToggleEditInventoryItemCost={isFeatureToggleEditInventoryItemCost}
        requireOnHandProductExpireDate={requireOnHandProductExpireDate}
      />
      <hr />
      <h4>{I18n.t('packages.form.packageAdjustments')}</h4>
      <Row>
        <Col xs={8} sm={4} md={3}>
          <Field
            name='inventory_location_id'
            component={ReactSelectInput}
            props={{
              label: I18n.t('packages.form.storageLocation'),
              options: locations,
              disabled: isFailingTrackingRequirements
            }}
          />
        </Col>
        {showPhases ? (
          <Col xs={4} sm={2}>
            <Field
              name='new_phase_id'
              component={ReactSelectInput}
              props={{
                label: I18n.t('inventory.form.newPhase'),
                options: phases,
                textKey: 'name',
                valueKey: 'id',
                disabled: isFailingTrackingRequirements
              }}
            />
          </Col>
        ) : null}
        {isPaLeaf ? null : (
          <Col xs={4} sm={2}>
            <Field
              name='on_hold'
              component={ReactSelectInput}
              props={{
                label: I18n.t('packages.form.hold'),
                options: holdOptions,
                disabled: isFailingTrackingRequirements
              }}
            />
          </Col>
        )}
        <Col xs={4} sm={2}>
          <Field
            name='is_reserved'
            component={ReactSelectInput}
            props={{
              label: I18n.t('packages.form.markAsReserved'),
              options: holdOptions,
              disabled: isFailingTrackingRequirements
            }}
          />
        </Col>
        <Col xs={4} md={2}>
          <Field
            name='adjustment_reason'
            component={ReactSelectInput}
            props={{
              label: I18n.t('packages.form.adjustmentReason'),
              options: adjustmentReasons,
              textKey: 'name',
              valueKey: 'code',
              isRequired: isWaLeaf && hasGlobalValues,
              disabled: isFailingTrackingRequirements
            }}
          />
        </Col>

        <WillRender ifTrue={show_tags}>
          <Col xs={2}>
            <Field
              name='tag_requested'
              component={ReactSelectInput}
              props={{
                label: I18n.t('plants.modify.needsTag'),
                isRequired: false,
                options: [{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }],
                onChange: (value) => {
                  change('tag_requested', value);
                  change('tag_requested_dirty', 1);
                }
              }}
            />
          </Col>
        </WillRender>

        {isWaLeaf || isMetrc || (isBiotrack && hasMedicatedPackages) ? (
          <Col xs={4} md={2}>
            <Field
              name='integration_adjustment_reason'
              component={ReactSelectInput}
              props={{
                label: I18n.t(`packages.form.${prefix}AdjustmentReason`),
                options: integrationAdjustmentReasons,
                isRequired:
                  (!isWaLeaf && !hasAllIntegrationAdjustmentReasons) ||
                  (!isBiotrack && !hasAllIntegrationAdjustmentReasons) ||
                  (isBiotrack && hasPackageWithoutReason && !hasAllIntegrationAdjustmentReasons)
              }}
            />
          </Col>
        ) : null}
        {isWaLeaf && leafWaReconciliationReasonsRequiringReceivedBy.includes(integrationAdjustmentReason) && (
          <Col xs={4} md={2}>
            <Field
              name={`received_by_user_id`}
              component={ReactSelectInput}
              props={{
                label: I18n.t(`packages.form.receivedBy`),
                options: facilityUsers,
                textKey: 'name',
                valueKey: 'id',
                isRequired: true
              }}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col xs={{span: 12, offset: 1}} sm={{span: 6, offset: 1}} md={{span: 5, offset: 1}}>
          <Field
            name='notes'
            component={TextAreaInput}
            props={{
              label: I18n.t('packages.form.notes'),
              disabled: isFailingTrackingRequirements
            }}
          />
        </Col>
      </Row>
      <FieldArray name='lots' component={LotsFieldArray} partnerFacilities={partnerFacilities} lots={lots} />
      <Row className='date-row'>
        <Col xs={{span: 8, offset: 4}} sm={{span: 6, offset: 6}} md={{span: 4, offset: 8}} lg={{span: 3, offset: 9}} className='date-col'>
          <InternationalDateTimePickerInput
            name='event_date'
            props={{
              label: I18n.t('packages.form.modificationDate')
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <ButtonToolbar className='float-right'>
            <Button type='reset' onClick={reset} disabled={submitting || pristine || saved}>
              {I18n.t('packages.form.reset')}
            </Button>
            <PermissionWrapper permissions={[p.print_labels]}>
              <Button type='submit' variant='info' onClick={showPrinter} disabled={submitting || pristine || invalid}>
                {packages !== undefined
                  ? packages.length === 1
                    ? !saved
                      ? I18n.t('packages.form.modifyPackageAndPrint')
                      : I18n.t('common.printLabel')
                    : !saved
                    ? I18n.t('packages.form.modifyPackagesAndPrint')
                    : I18n.t('common.printLabels')
                  : null}
              </Button>
            </PermissionWrapper>
            <Button
              variant='primary'
              className={packages === undefined || saved ? 'hide' : ''}
              type='submit'
              disabled={submitting || pristine}
            >
              {packages !== undefined
                ? packages.length === 1
                  ? I18n.t('packages.form.modifyPackage')
                  : I18n.t('packages.form.modifyPackages')
                : null}
            </Button>
          </ButtonToolbar>
        </Col>
      </Row>
    </form>
  );
};

PackagesForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  saved: PropTypes.bool.isRequired,
  showPrinter: PropTypes.func.isRequired,
  adjustmentReasons: PropTypes.array.isRequired,
  hasPackagesTags: PropTypes.bool,
  packages: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  phases: PropTypes.array.isRequired,
  showPhases: PropTypes.bool.isRequired,
  integrationState: PropTypes.object.isRequired,
  integrationAdjustmentReasons: PropTypes.array.isRequired,
  trackingIds: PropTypes.array.isRequired,
  showReverseInventoryOption: PropTypes.bool.isRequired,
  hasGlobalAdjustmentReason: PropTypes.bool.isRequired,
  hasGlobalValues: PropTypes.bool.isRequired,
  hasGlobalIntegrationAdjustmentReason: PropTypes.bool,
  hasAllIntegrationAdjustmentReasons: PropTypes.bool,
  partnerFacilities: PropTypes.array,
  lots: PropTypes.array.isRequired,
  hasPackageWithoutReason: PropTypes.bool,
  hasMedicatedPackages: PropTypes.bool,
  hasWastePackages: PropTypes.bool,
  showProductLookup: PropTypes.func,
  isModalOpen: PropTypes.bool,
  filterBy: PropTypes.func,
  inventoryItems: PropTypes.array,
  inventoryTypes: PropTypes.array,
  showModalMedical: PropTypes.func,
  waPackagingInventoryTypes: PropTypes.array,
  facilityUsers: PropTypes.array,
  isFailingTrackingRequirements: PropTypes.bool,
  isFailingTrackingRequirementsMessage: PropTypes.string,
  integrationAdjustmentReason: PropTypes.string,
  complianceSettings: PropTypes.object,
  form: PropTypes.object,
  cupos: PropTypes.array,
  hasManufacturing: PropTypes.bool,
  touch: PropTypes.func,
  validateProductCanBeChanged: PropTypes.func,
  productCanBeChanged: PropTypes.bool,
  isMetrcStemHoldingsToggled: PropTypes.bool,
  isMetrcCaDonationsToggled: PropTypes.bool,
  isUtahGlobalIdGenerationFeatureEnabled: PropTypes.bool,
  isFeatureToggleEditInventoryItemCost: PropTypes.bool.isRequired,
  requireOnHandProductExpireDate: PropTypes.bool.isRequired
};

export default PackagesForm;
