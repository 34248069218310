import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { Col, Row } from 'react-bootstrap';
import SubmitSection from '../../../common/form/SubmitSection';
import ModalitySettings from './ModalitySettings';

export const CupoSettingsForm = (props) => {
  const { handleSubmit, submitting, invalid, pristine } = props;

  const submitSectionSettings = {
    actionSettings: {
      submit: {
        submitting,
        invalid,
        pristine,
        text: I18n.t('common.actions.save')
      }
    },
    align: 'right'
  };

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col md={12}>
          <FieldArray name='modalities' component={ModalitySettings} />
        </Col>
      </Row>
      <SubmitSection settings={submitSectionSettings} />
    </form>
  );
};

CupoSettingsForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
};

export default CupoSettingsForm;
