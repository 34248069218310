/* eslint-disable react/display-name */
import React from 'react';
import {Button} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import PermissionWrapper from '../../PermissionWrapper';
import RouteButton from '../../RouteButton';

const SingleActionColumn = config => (cell, row) => {
  const {path} = config;
  return (<PermissionWrapper permissions={config.permissions}>
      {path ?
        <RouteButton path={typeof path === 'function' ? path(row) : path}
          props={{
            variant: config.style || 'primary',
            onClick: event => config.action(event, row)
          }}
          disabled={typeof config.isDisabled === 'function' ? config.isDisabled(row) : false}>
            {I18n.t(config.label)}
        </RouteButton>
        :
        <Button variant={config.style || 'primary'} disabled={typeof config.isDisabled === 'function' ? config.isDisabled(row) : false}
              onClick={event => config.action(event, row)}>
              {I18n.t(config.label)}
        </Button>}
    </PermissionWrapper>);
};

export default SingleActionColumn;
