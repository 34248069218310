import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { bindActionCreators } from 'redux';
import { I18n } from 'react-redux-i18n';
import PageTitle from '../../common/PageTitle';
import TablePageWrapper from '../../common/grid/TablePageWrapper';
import * as itemNames from '../../../constants/itemNames';
import * as apiActions from '../../../actions/apiActions';
import { getColombiaModalitySettings } from '../../../selectors/settingsSelectors';
import * as dataNames from '../../../constants/dataNames';
import { handleComplexSelectRow } from '../../../actions/helpers/selectedDataHelper';

class ModalityListingPage extends React.Component {
  constructor(props) {
    super(props);
    this.saveChanges = this.saveChanges.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.fetchSettings = this.fetchSettings.bind(this);
  }

  componentWillMount() {
    this.fetchSettings();
  }

  fetchSettings() {
    this.props.actions
      .getItem('/api/cultivation/settings', itemNames.colombiaModalities, null, { ids: ['cult_colombia_modality'] })
      .then((settings) => {
        const modalities = get(settings, 'cult_colombia_modality.value', []);
        this.props.actions.handleComplexSelectRow(
          this.transformModalities(modalities.filter((modality) => modality.is_active)),
          dataNames.modalities,
          'set'
        );
      });
  }

  transformModalities(modalities) {
    return modalities.map((modality) => ({ ...modality, id: modality.key }));
  }

  saveChanges() {
    const { modalities, selectedItemKeys } = this.props;
    const payload = {
      settings: [
        {
          setting_key: 'cult_colombia_modality',
          scope: 'facility',
          scope_id: this.props.facility.id,
          value: modalities.map((modality) => ({
            ...modality,
            is_active: selectedItemKeys.includes(modality.key)
          }))
        }
      ]
    };
    this.props.actions
      .postItem('/api/cultivation/settings', payload, 'noOp', {
        success: 'modalities.save.success'
      })
      .then(this.fetchSettings);
  }

  getColumns() {
    return [
      {
        name: 'Modality',
        dataId: 'key',
        width: '300px',
        headerAlign: 'left',
        dataAlign: 'left',
        formatter: (key) => I18n.t('modalities.list.' + key)
      }
    ];
  }

  handleSelect(action, rows) {
    this.props.actions.handleComplexSelectRow(rows, dataNames.modalities, action);
  }

  render() {
    const { modalities, selectedItemKeys } = this.props;

    return (
      <div>
        <PageTitle primaryText={I18n.t('modalities.title')} />
        <TablePageWrapper
          columns={this.getColumns()}
          data={this.transformModalities(modalities)}
          actions={[{ id: 'save', func: this.saveChanges, text: 'common.actions.save', glyph: 'floppy-disk' }]}
          handleSelect={this.handleSelect}
          hideExport={true}
          hideScanSearch={true}
          hideTableControls={true}
          hideSearch={true}
          selectedRows={selectedItemKeys}
          settingKey='modalities'
        />
      </div>
    );
  }
}

ModalityListingPage.propTypes = {
  actions: PropTypes.shape({
    handleComplexSelectRow: PropTypes.func,
    getItem: PropTypes.func,
    postItem: PropTypes.func,
  }),
  modalities: PropTypes.array,
  selectedItemKeys: PropTypes.array,
  facility: PropTypes.array
};

function mapStateToProps(state) {
  const selectedItemKeys = (state.selectedModalities || []).map((item) => item.key);
  return {
    modalities: getColombiaModalitySettings(state),
    selectedItemKeys,
    facility: state.facility
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, { handleComplexSelectRow });

  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalityListingPage);
