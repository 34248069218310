import React from 'react';
import PropTypes from 'prop-types';
import {FieldArray, Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import { Col, Row, Card} from 'react-bootstrap';
import ConnectsPartnerProduct from './ConnectsPartnerProduct';
import SubmitSection from '../../../common/form/SubmitSection';

const ConnectsProductMappingForm  = (props) => {

  const {submitting} = props;

  const submitSettings = {
    actionSettings: {
      submit: {
        submitting,
        text: I18n.t('common.form.save')
      }
    }
  };

  const textPrefix = 'partners.connects.mapProducts';

  return(
    <form onSubmit={props.handleSubmit} noValidate={true}>

      <div>
        <Card body>
          <label style={{fontWeight: 'normal'}}>
            <Field
              name={`connect_inherit_item_masters`}
              component='input'
              style={{marginRight: '8px', marginTop: '5px'}}
              type='checkbox'
            />
            {I18n.t(`${textPrefix}.allowInheritProducts`)}
          </label>
        </Card>

        <div id='connects_products'>

          <Row className={"header_row"}>
            <Col xs={12} md={3}>
              <strong>{I18n.t(`${textPrefix}.availablePartnerProducts`)}</strong>
            </Col>
            <Col xs={12} md={3}>
              <strong>{I18n.t(`${textPrefix}.yourProduct`)}</strong>
            </Col>
            <Col xs={12} md={3}>
              <strong>{I18n.t(`${textPrefix}.mapSubcategory`)}</strong>
            </Col>
            <Col xs={12} md={3}>
              <strong>{I18n.t(`${textPrefix}.selectItemId`)}</strong>
            </Col>
          </Row>


          <FieldArray
            component={ConnectsPartnerProduct}
            name='partnerProducts'
            props={{
              itemMasters: props.itemMasters,
              categories: props.categories,
              partnerProducts: props.partnerProducts,
              mappings: props.mappings,
              mappedItemMasterIds: props.mappedItemMasterIds,
            }}
          />
        </div>
      </div>
      <div>
          <SubmitSection settings={submitSettings}/>

      </div>
    </form>
  );
};

ConnectsProductMappingForm.propTypes = {
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  partnerProducts: PropTypes.array,
  itemMasters: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  mappings: PropTypes.array,
  mappedItemMasterIds: PropTypes.array.isRequired
};

export default ConnectsProductMappingForm;
