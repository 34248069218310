import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';
import {Card, Col, Row, Table} from 'react-bootstrap';
import ContentConcealer from '../../../common/concealers/ContentConcealer';

const PackageSplittedNewItemDetails = ({model, partners}) => {
  const {source_item} = model.transaction;
  const destination_id = model.package && model.package.destination_partner_id;
  const partner = destination_id && partners.find(p => p.id === destination_id);
  return (
    <Card>
      <Row>
        <Col xs={6}>
          <h3>{I18n.t('itemTransactions.newPackageDetails')}</h3>
          <Table striped className='item-details'>
            <thead>
            <tr>
              <th>{I18n.t('itemTransactions.field')}</th>
              <th>{I18n.t('itemTransactions.value')}</th>
            </tr>
            </thead>
            <tbody>
            <ContentConcealer show={Boolean(model.package_code)}>
              <tr>
                <td>{I18n.t('itemTransactions.item.packageId')}</td>
                <td>{model.package_code}</td>
              </tr>
            </ContentConcealer>
            <ContentConcealer show={Boolean(source_item)}>
              <tr>
                <td>{I18n.t('itemTransactions.item.parentPackageId')}</td>
                <td>{source_item && source_item.package_code}</td>
              </tr>
            </ContentConcealer>
            <ContentConcealer show={Boolean(get(model, 'current_package.state_integration_tracking_id', false))}>
              <tr>
                <td>{I18n.t('itemTransactions.item.trackingId')}</td>
                <td>{model.current_package.state_integration_tracking_id}</td>
              </tr>
            </ContentConcealer>
            <ContentConcealer show={Boolean(get(model, 'current_package.source_tracking_ids', false))}>
              <tr>
                <td>{I18n.t('itemTransactions.item.sourceTrackingId')}</td>
                <td title={model.current_package.source_tracking_ids}>{model.current_package.source_tracking_ids}</td>
              </tr>
            </ContentConcealer>
            <tr>
              <td>{I18n.t('itemTransactions.item.product')}</td>
              <td>{model.item_name}</td>
            </tr>
            <ContentConcealer show={Boolean(model.lot_number)}>
              <tr>
                <td>{I18n.t('itemTransactions.item.lotId')}</td>
                <td>{model.lot_number}</td>
              </tr>
            </ContentConcealer>
            <tr>
              <td>{I18n.t('itemTransactions.item.location')}</td>
              <td>{model.location_name}</td>
            </tr>
            <tr>
              <td>{I18n.t('itemTransactions.item.qty')}</td>
              <td>{model.qty} {model.uom}</td>
            </tr>
            <ContentConcealer show={Boolean(model.package && model.package.state_integration_tracking_id)}>
              <tr>
                <td>{I18n.t('itemTransactions.item.trackingId')}</td>
                <td>{model.package && model.package.state_integration_tracking_id}</td>
              </tr>
            </ContentConcealer>
            <ContentConcealer show={Boolean(partner)}>
              <tr>
                <td>{I18n.t('itemTransactions.item.partnerDestination')}</td>
                <td>{partner && partner.name}</td>
              </tr>
            </ContentConcealer>
            <tr>
              <td>{I18n.t('itemTransactions.item.onHold')}</td>
              <td>{model.on_hold ? 'Yes' : 'No'}</td>
            </tr>
            <tr>
              <td>{I18n.t('itemTransactions.item.reserved')}</td>
              <td>{model.is_reserved ? 'Yes' : 'No'}</td>
            </tr>
            <ContentConcealer show={Boolean(model.purpose_name)}>
              <tr>
                <td>{I18n.t('itemTransactions.item.purpose')}</td>
                <td>{model.purpose_name}</td>
              </tr>
            </ContentConcealer>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Card>
  );
};

PackageSplittedNewItemDetails.propTypes = {
  model: PropTypes.object.isRequired,
  partners: PropTypes.array.isRequired,
};

export default PackageSplittedNewItemDetails;
