/* eslint-disable import/no-mutable-exports*/
import {getFormValues, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';
import validate from './validate';
import RegisterReconciliationForm from './RegisterReconciliationForm';
import {REGISTER_RECONCILIATION_FORM} from '../../../constants/forms';
import * as apiActions from '../../../actions/apiActions';
import * as itemNames from '../../../constants/itemNames';


const mapStateToProps = (state) => ({
  formValues: getFormValues(REGISTER_RECONCILIATION_FORM)(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  actions: {
    save(payload) {
      ownProps
        .onSubmit(payload)
        .then(() => dispatch(push('/retail/sales-report/closing-report/closed')));
    },

    saveAndPrint(payload) {
      const goToClosedRegistersListing = () => (
        dispatch(push('/retail/sales-report/closing-report/closed'))
      );
      const fetchRegisterReconciliation = (result) => (
        dispatch(apiActions.getItem(`/api/closings/${result.id}/print`, itemNames.registerReconciliation))
      );
      const showInNewTab = (response) => {
        const pdfWindow = window.open('about:blank', 'Register Reconciliation');

        // decode base64 string
        const binary = atob(response.replace(/\s/g, ''));
        const len = binary.length;
        const buffer = new ArrayBuffer(len);
        const view = new Uint8Array(buffer);
        for (let i = 0; i < len; i++) {
          view[i] = binary.charCodeAt(i);
        }

        const file = new Blob([view], { type: 'application/pdf' });
        pdfWindow.location.href = URL.createObjectURL(file);
        return Promise.resolve();
      };

      ownProps
        .onSubmit(payload)
        .then(fetchRegisterReconciliation)
        .then(showInNewTab)
        .then(goToClosedRegistersListing);
    },
  },
});

const RegisterReconciliationFormWrapper = reduxForm({
  form: REGISTER_RECONCILIATION_FORM,
  enableReinitialize: true,
  validate
})(RegisterReconciliationForm);

export default connect(mapStateToProps, mapDispatchToProps)(RegisterReconciliationFormWrapper);
