import React from 'react';
import {createSelector} from 'reselect';
import {Col, Row} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import * as itemNames from '../constants/itemNames';
import * as dataNames from '../constants/dataNames';
import {getAdjustedCustomerPoints} from './customerSelectors';
import {getOrder} from './ordersSelectors';
import {getRewardAssignableCustomerGroups} from './forms/rewardsFormSelectors';

const getCustomer = (state) => state[itemNames.customer];
export const getRewardSettings = (state) => state[itemNames.rewardSettings];
export const getRewards = (state) => state[dataNames.rewards];
export const getCurrentReward = (state) => (state[itemNames.reward] !== undefined) ? state[itemNames.reward] : {};
const getListingMode = (_, props) => (props.mode !== undefined) ? props.mode : 'active';

///// REWARD SELECTORS /////

/***
 * Returns either active or inactive list of rewards.
 * @type {Reselect.Selector<TInput, TOutput>}
 */
export const getRewardsByStatus = createSelector([getRewards, getListingMode], (rewards, listingMode) => {
  const valueType = I18n.t('retail.rewards.reward.fields.reward_type_value');
  const percentType = I18n.t('retail.rewards.reward.fields.reward_type_percent');
  const selectStatus = (listingMode === 'active') ? 1 : 0;
  return rewards.filter((reward) => {
    return reward.is_active === selectStatus;
  }).map((reward) => {
    reward.reward_type_string = (reward.reward_type === 'value') ? valueType : percentType; // translate here so table search works correctly
    return reward;
  });
});

/***
 * Returns defaults or active reward which over-writes defaults and adds optionals
 * @type {Reselect.Selector<TInput, TOutput>}
 */
export const getRewardInitialValues = createSelector([getCurrentReward], (reward) => {
  const rewardData = {};
  const defaults = {reward_amount: 0, points_required: 0, reward_type: 'value', reward_applies_to: 'order'};
  Object.keys(defaults).forEach((field) => rewardData[field] = defaults[field]);
  Object.keys(reward).forEach((field) => rewardData[field] = reward[field]);
  return rewardData;
});



///// REWARD SETTINGS SELECTORS /////

/***
 * Groups the rewards by "group" property from BE
 * @type {Reselect.Selector<TInput, TOutput>}
 */
export const getGroupedRewardSettings = createSelector([getRewardSettings], (rewardSettings) => {
  return Object.keys(rewardSettings).reduce((acc, key) => {
    const setting = rewardSettings[key];
    let group = acc.find((item) => item.group === setting.group);
    if(!group){
      group = {
        group: setting.group,
        settings: []
      };
      acc.push(group);
    }
    group.settings.push(Object.assign({}, setting, {text: key, value: (setting.value) ? setting.value : setting.default_value}));
    return acc;
  }, []);
});

/***
 * Returns saved and default values where no facility value set
 * @type {Reselect.Selector<TInput, TOutput>}
 */
export const getRewardSettingsInitialValues = createSelector([getRewardSettings, getRewardAssignableCustomerGroups], (rewardSettings, validGroups) => {

  // takes a setting such as {..., my_setting: {a: 1, b: 2}, ...} and returns two key/values {..., my_setting__a: 1, my_setting__b: 2, ...}
  const expandObjectsToValues = (key, value) => {
    if(Array.isArray(value)) return {[key]: value};
    if(typeof value !== 'object') return {[key]: value};
    const key_values = {};
    for(const prop in value){
      key_values[`${key}__${prop}`] = value[prop];
    }
    return key_values;
  };

  const settings = Object.keys(rewardSettings).reduce((acc, key) => {
    const setting = rewardSettings[key];
    if(setting.value){
      acc = Object.assign({}, acc, expandObjectsToValues(key, setting.value));
    } else {
      acc = Object.assign({}, acc, expandObjectsToValues(key, setting.default_value));
    }
    return acc;
  }, {});

  // Remove any groups that are not valid (eg. groups deleted that had been included in reward groups - this should clean up previous issues organically)
  if(validGroups && Array.isArray(validGroups) && settings.reward_eligible_reward_groups && Array.isArray(settings.reward_eligible_reward_groups.value)) {
    settings.reward_eligible_reward_groups = settings.reward_eligible_reward_groups.value.filter((group) => {
      return validGroups.find((g) => g.id === group.id);
    });
  }
  return settings;

});

/***
 * Creates post shape
 * @param formData  object representing form
 * @param functions  array of functions accepting and returning payload
 * @returns {{settings: *}}
 */
export const getPayload = (formData, functions = []) => {

  // Recombines setting objects that were split on get initial settings
  const setKeyValue = (acc, key) => {
    const temp = key.split('__');
    let value = temp.length === 1 ? formData[key] : {[temp[1]]: formData[key]};
    if (value === undefined) value = '';
    const useKey = temp.length === 1 ? key : temp[0];
    let setting = acc.find((item) => item.setting_key === useKey);
    const scope = (key === 'reward_remove_points_after_days_between_visits') ? 'organization' : 'facility';

    if(!setting){
      setting = {scope: scope, setting_key: useKey, value: false};
      acc.push(setting);
    }

    if (key === 'reward_timeframe_based_accrual_rate') {
      value = formatTimePickerValues(value);
    }

    setting.value = typeof value === 'object' && !Array.isArray(value) ? Object.assign({}, setting.value, value) : value;
    return acc;
  };

  let payload = {
    settings: Object.keys(formData).reduce((acc, key) => {
      acc = setKeyValue(acc, key); // split out in event there are other changes to make here to shape
      return acc;
    }, [])
  };

  functions.forEach((func) => {
    payload = func(payload);
  });

  return payload;
};


const formatTimePickerValues = (value) => {
  const timeFormat = 'h:mm A';
  const timeFormatter = (momentObj) => (momentObj && momentObj.format) ? momentObj.format(timeFormat) : momentObj;
  if (Array.isArray(value)) {
    value = value.map(row => ({
      ...row,
      start_time: timeFormatter(row.start_time),
      end_time: timeFormatter(row.end_time),
    }));
  }
  return value;
};


/***
 * Filters rewards by adjusted customer points as BE does not account for points expended in current order.
 * @type {Reselect.Selector<TInput, TOutput>}
 */
export const getAvailableRewards = createSelector([getAdjustedCustomerPoints, getRewards], (customerPoints, rewards) => {
  return rewards.filter((reward) => {
    return reward.points_required <= customerPoints;
  });
});

/***
 * Returns filtered rewards suitable for select presentation with multiple rows
 * @type {Reselect.Selector<TInput, TOutput>}
 */
export const getRewardsForSelectInput = createSelector([getAvailableRewards], (rewards) => {
  return rewards.map((reward) => {
    return Object.assign({}, reward, {
      html: (<Row>
        <Col sm={9}>{reward.name}</Col>
        <Col sm={3}>{reward.points_required} Pts.</Col>
        <Col sm={12}>Value: {reward.reward_type === 'value' ? `$${reward.reward_amount}` : `${reward.reward_amount}%`}</Col>
        <div style={{clear:'both'}} />
      </Row>)
    });
  });
});

/***
 * Returns enabled state based on inputs, customer, and order.
 * If propsCustomer is provided (through props.customer in the call in mapStateToProps) then the eval is only for customer.  This determines if the
 * point adjustment displays for a customer.
 * If not propsCustomer then it is assumed this is for an order and it will be evaluated for order type and customer group type.
 */
const getPropCustomer = (_, props) => props && props.customer ? props.customer : false;
export const getRewardsEnabledState = createSelector([getRewardSettings, getCustomer, getOrder, getPropCustomer], (settings, customer, order, propCustomer) => {
  const validByCustomer = () => {
    const customerRecord = Object.keys(propCustomer).length > 0 ? propCustomer : customer;
    const validGroupIds = settings.reward_eligible_reward_groups.value;
    if(!customerRecord.id) return false; // no rewards for anonyomous orders
    if(!Array.isArray(validGroupIds)) return true;
    if(validGroupIds.length === 0) return true; // Empty = all
    if(!customerRecord.groups || !Array.isArray(customerRecord.groups)) return false;
    return customerRecord.groups.reduce((acc, group) => {
      if(acc) return acc;
      if(validGroupIds.indexOf(group.id) !== -1) acc = true;
      return acc;
    }, false);
  };

  const getOrderType = () => {
    if(order.order_source === 'in_store') return 'in_store';
    if(order.fulfillment_method === 'in_store') return 'pickup';
    return 'delivery';
  };

  const validByOrderType = () => {
    const validOrderTypes = settings.reward_allow_rewards_for_order_type.value;
    if(!Array.isArray(validOrderTypes)) return false;
    if(validOrderTypes.length === 0) return true; // Empty = all
    return validOrderTypes.indexOf(getOrderType()) !== -1;
  };

  if(typeof settings !== 'object') return false;
  if(Object.keys(settings).length === 0) return false;
  return Object.keys(propCustomer).length > 0
    ? settings.reward_record_points_based_on_sales.value === 1 && validByCustomer()
    : settings.reward_record_points_based_on_sales.value === 1 && validByCustomer() && validByOrderType();
});

export const getRewardsMode = createSelector([getRewardSettings], (settings) => {
  if(typeof settings !== 'object') return 'coupons';
  if(Object.keys(settings).length === 0) return 'coupons';
  return settings.reward_one_point_is_worth.value && settings.reward_one_point_is_worth.value.override_enabled !== undefined
    ? settings.reward_one_point_is_worth.value.override_enabled ? 'currency' : 'coupons'
    : settings.reward_one_point_is_worth.default_value.override_enabled ? 'currency' : 'coupons';
});

export const getRewardsPointsToCurrencyConversion = createSelector([getRewardSettings], (settings) => {
  if(typeof settings !== 'object') return 0;
  if(Object.keys(settings).length === 0) return 0;
  return settings.reward_one_point_is_worth.value && settings.reward_one_point_is_worth.value.override_value !== undefined
    ? parseFloat(settings.reward_one_point_is_worth.value.override_value)
    : parseFloat(settings.reward_one_point_is_worth.default_value.override_value);
});

