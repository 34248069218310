import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {I18n} from 'react-redux-i18n';

import * as dataNames from '../../../constants/dataNames';
import { getSearchData, getData } from '../../../actions/apiActions';
import { setData, unsetData } from '../../../actions/dataActions';
import { addMessage } from '../../../actions/systemActions';
import { getFoundPackages } from '../../../selectors/productsSelectors';
import ScanSearchComponent from './ScanSearchComponent';

function mapStateToProps(state) {
  return {
    foundItems: getFoundPackages(state)
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const internalActions = bindActionCreators({getSearchData, setData, getData, unsetData}, dispatch);

  const notifyLockedItems = (lockedItems) => {
    let key;
    const payload = {};

    if (lockedItems.length === 1) {
      key = 'lockedItem';
      payload.packageCode = lockedItems[0].package_code;
    } else {
      key = 'lockedItems';
      payload.number = lockedItems.length;
    }

    dispatch(addMessage('warning', I18n.t(`cart.getScannedItem.${key}`, payload)));
  };

  const performSearch = (value) => {

    internalActions.unsetData(dataNames.products);
    const {useEntityLocks = false, filterEntityLockedItems = false, warnEntityLockedItems = false} = ownProps;

    if (!useEntityLocks || !filterEntityLockedItems) {
      return internalActions.getData('/api/items', dataNames.products, undefined, {package_code: value, detailed : 1});
    }

    const lockedItems = [];
    const filteredItems = [];

    return internalActions.getData('/api/items', dataNames.products, undefined, {
      package_code: value,
      detailed: 1,
    }).then((items) => {
      items.forEach((item) => {
        item.is_locked ? lockedItems.push(item) : filteredItems.push(item);
      });
      if (lockedItems.length && warnEntityLockedItems) {
        notifyLockedItems(lockedItems);
      }
      return internalActions.setData(filteredItems, dataNames.products);
    }).then(() => {
      return {unavailableItems: lockedItems, suppressNotFoundMessage: warnEntityLockedItems};
    });
  }

  return {
    actions: {
      performSearch,
      ...bindActionCreators({addMessage}, dispatch),
    },
  };
}

const PackageSearch = connect(mapStateToProps, mapDispatchToProps)(ScanSearchComponent);

PackageSearch.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.node,
  ]),
  showLoader: PropTypes.bool,
  loadingMessage: PropTypes.string,
  onSearchStart: PropTypes.func,
  onSearchComplete: PropTypes.func,
  onSearchFailed: PropTypes.func,
  disabled: PropTypes.bool,
  useEntityLocks: PropTypes.bool,
  filterEntityLockedItems: PropTypes.bool,
  warnEntityLockedItems: PropTypes.bool,
};

export default PackageSearch;
