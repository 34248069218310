import {I18n} from 'react-redux-i18n';

export const failed = 100;
export const compromised = 101;

const extraOptions = [
  {text: I18n.t('inventory.table.compromised'), value: compromised},
  {text: I18n.t('inventory.table.failed'), value: failed},
];

//Simple 0-10 options
export const ratingOptions = Array.apply(null, new Array(11)).map((_, i) => ({text: String(i), value: i}));

//extended options
export const extendedRatingOptions = ratingOptions.concat(extraOptions);

export const mappedValues = extraOptions.reduce((values, item) => ({...values, [item.value]: item.text}), {});

export const getRatingLabel = (rating) => {
  let convertedRating = rating;

  extendedRatingOptions.forEach((r) => {
    if (r.value === parseInt(rating)) {
      convertedRating = r.text;
    }
  });

  return convertedRating;
};