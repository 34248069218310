import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Field, formValueSelector} from 'redux-form';
import { connect } from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {Col} from 'react-bootstrap';

import CheckBoxInput from '../../common/form/CheckBoxInput';
import InternationalDateTimePickerInput from '../../common/form/InternationalDateTimePickerInput';

class CanadianCompliance extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {disabled: props.isRenewal};
  }

  render() {
    const {isCanadaFacility, show, fieldName, isRenewal, isMostRecent, isExpired, idRow, change} = this.props;
    const {disabled} = this.state;

    const showRenewal = () => {
      if(isRenewal) return true;
      return (isMostRecent && !isExpired) || (!idRow.id && idRow.expired_at && !isExpired) || (idRow.id && !isExpired);
    };

    return ((isCanadaFacility && show) ?
        <div className='flex-25 field'>
          <Col md={9}>
            <InternationalDateTimePickerInput
              name={`${fieldName}.effective_at`}
              props={{
                label: I18n.t('customers.create.effectiveDate'),
                closeOnSelect: true
              }} />
          </Col>
          <Col md={3}>
            <div className={showRenewal() ? '' : 'hide'}>
              <Field
                name={`${fieldName}.is_renewal`}
                component={CheckBoxInput}
                props={{
                  disabled: disabled,
                  label: I18n.t('customer.create.renewal'),
                  onChange: (event) => {
                    change(`${fieldName}.is_renewal`, event.target.checked);
                    this.setState({disabled: false});
                  }
                }}
              />
            </div>
          </Col>
        </div> : null
    );
  }
}

CanadianCompliance.propTypes = {
  isCanadaFacility: PropTypes.bool.isRequired,
  show: PropTypes.bool,
  fieldName: PropTypes.string.isRequired,
  isRenewal: PropTypes.bool,
  isMostRecent: PropTypes.bool.isRequired,
  idRow: PropTypes.object,
  isExpired: PropTypes.bool,
  change: PropTypes.func
};

CanadianCompliance.defaultProps = {
  show: true,
};

function mapStateToProps(state, ownProps){
  const selector = formValueSelector(ownProps.formName);
  const expired_at = selector(state, `${ownProps.fieldName}.expired_at`);
  const expiredAt = (typeof expired_at === 'object') ? expired_at : typeof expired_at === 'string' ? moment(expired_at) : false;
  const today = moment();
  return {
    isExpired: typeof expiredAt === 'object' && expiredAt !== null ? expiredAt.isBefore(today) : false,
    isRenewal: !!(selector(state, `${ownProps.fieldName}.is_renewal`) && selector(state, `${ownProps.fieldName}.id`) !== undefined),
    idRow: selector(state, ownProps.fieldName),
  };
}

export default connect(mapStateToProps, null)(CanadianCompliance);
