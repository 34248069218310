import {createSelector} from 'reselect';
import {formValueSelector} from 'redux-form';
import {TAX_PROFILE_FORM} from '../../constants/forms';
import * as itemNames from '../../constants/itemNames';
import {getIntegrationState} from '../integration/integrationSelectors';
import {getActiveFacility, getFacilities} from '../facilitiesSelectors';

const selector = formValueSelector(TAX_PROFILE_FORM);
const getTaxGroups = (state) => selector(state, 'groups');
const getProfileTaxes = (state) => selector(state, 'taxes');
const getTaxes = (state) => state.taxes;
const getTaxProfile = (state) => state[itemNames.taxProfile];

export const getTaxDefinitionIdsInUse = createSelector([getTaxGroups, getProfileTaxes], (groups, taxes) => {
  if(!groups || !Array.isArray(groups)) return [];
  const allGroups = groups.concat(taxes);
  return allGroups.reduce((acc, group) => {
    if(!group.items || !Array.isArray(group.items)) return acc;
    return acc.concat(group.items.reduce((acc, item) => {
      const taxId = parseInt(item.tax_id);
      if(!isNaN(taxId)) return acc.concat([taxId]);
      return acc;
    }, []));
  }, []);
});

export const getTaxDefinitions = createSelector([getTaxes], (taxes) => {
  return (Array.isArray(taxes)) ? taxes.map((tax) => {
    const rate = parseFloat(tax.amount);
    tax.rate = !isNaN(rate) ? rate : 0;
    return tax;
  }) : [];
});

export const getInitialValues = createSelector([getTaxProfile, getActiveFacility, getIntegrationState, getFacilities], (taxProfile, facility, integrationState, facilities) => {
  if(Object.keys(taxProfile).length === 0){
    return {
      tax_profile_name: null,
      customer_types: [],
      states: [],
      facilities: [],
      groups: [
        {
          items: [{}],
          init: 1,
          inboundValue: 100,
          outboundValue: 100,
        }
      ],
      taxes: {
        inboundValue: 100,
        init: 1,
        outboundValue: 100,
        items: [{}],
      },
      taxes_on_subtotal: {
        inboundValue: 100,
        init: 1,
        outboundValue: 100,
        items: [{}],
      }
    };
  }
  return Object.assign({}, taxProfile, {
    customer_types: ['recreational', 'medical', 'non_mmic', 'disabled_veterans'].reduce((acc, type) => {
      if(taxProfile[`applies_to_${type}`]) {
        const provinceCode = facility && facility.province_code ? facility.province_code.toLowerCase() : '';
        if(type === 'non_mmic' && provinceCode !== 'ca') return acc;
        if(type === 'disabled_veterans' && provinceCode !== 'ok') return acc;
        acc.push({value: `applies_to_${type}`});
      }
      return acc;
    }, []),
    states: ['in_state', 'out_of_state'].reduce((acc, state) => {
      if(taxProfile[`applies_${state}`]) acc.push({value: `applies_${state}`});
      return acc;
    }, []),
    facilities: taxProfile.facility_ids && Array.isArray(taxProfile.facility_ids)
      ? taxProfile.facility_ids.map((id) => {
        const facility = facilities.find((facility) => facility.id === id);
        return facility ? facility : {id};
      })
      : [],
    groups: taxProfile.taxes && Array.isArray(taxProfile.taxes) ? taxProfile.taxes.reduce((acc, tax) => {
      if(!tax.adjusts_taxable_total) return acc;
      let group = acc.find((a) => a.application_order === tax.application_order);
      if(!group){
        group = {
          application_order: tax.application_order,
          items: [],
          inboundValue: 100,
          outboundValue: 100
        };
        acc.push(group);
      }
      group.items.push({tax_id: tax.tax_id});
      return acc;
    }, []).sort((a,b) => a.application_order - b.application_order) : [],
    taxes: taxProfile.taxes && Array.isArray(taxProfile.taxes) ? taxProfile.taxes.reduce((acc, tax) => {
      if(tax.adjusts_taxable_total) return acc;
      if(tax.calculate_from_subtotal) return acc;
      if(acc.items.length === 1 && Object.keys(acc.items[0]).length === 0) acc.items = [];
      acc.items.push({tax_id: tax.tax_id});
      return acc;
    }, {inboundValue: 100, outboundValue: 100, items: [{}]}) : {inboundValue: 100, outboundValue: 100, items: [{}]},
    taxes_on_subtotal: taxProfile.taxes && Array.isArray(taxProfile.taxes) ? taxProfile.taxes.reduce((acc, tax) => {
      if(tax.adjusts_taxable_total) return acc;
      if(!tax.calculate_from_subtotal) return acc;
      if(acc.items.length === 1 && Object.keys(acc.items[0]).length === 0) acc.items = [];
      acc.items.push({tax_id: tax.tax_id});
      return acc;
    }, {inboundValue: 100, outboundValue: 100, items: [{}]}) : {inboundValue: 100, outboundValue: 100, items: [{}]},
  });
});

const getHeighestWeight = tax_profiles => {
  return [...tax_profiles]
    .filter( profiles => profiles.active === 1 )
    .reduce( (acc, profile) => {
      return profile.weight > acc ? profile.weight : acc;
    }, 0);
};

export const getPayload = (formData, taxProfiles) => {
  const payload = {
    tax_profile_name: formData.tax_profile_name,
  };
  const customerTypes = ['recreational', 'medical', 'non_mmic', 'disabled_veterans'];
  customerTypes.forEach((type) => {
    const key = `applies_to_${type}`;
    const hasType = formData.customer_types.find((t) => t.value === key);
    payload[key] = hasType ? 1 : 0;
  });
  const states = ['in_state', 'out_of_state'];
  states.forEach((state) => {
    const key = `applies_${state}`;
    const hasState = formData.states.find((s) => s.value === key);
    payload[key] = hasState ? 1 : 0;
  });

  payload.facility_ids = formData.facilities.map((facility) => facility.id);
  if (!formData.weight) {
    payload.weight = (getHeighestWeight(taxProfiles) + 1);
  }

  const taxes = [];
  let counter = 1;
  let incrementCounter = false;
  const taxesCompoundingGroup = Object.assign({}, formData.taxes, {application_order: 0, calculate_from_subtotal: 0});
  const taxesOnSubtotalGroup = Object.assign({}, formData.taxes_on_subtotal, {application_order: 0, calculate_from_subtotal: 1});
  const allGroups = formData.groups.concat([taxesCompoundingGroup, taxesOnSubtotalGroup]);
  allGroups.forEach((group, index) => {
    const items = group.items && Array.isArray(group.items) ? group.items : [];
    incrementCounter = false;
    items.forEach((item) => {
      if(item.tax_id && !isNaN(parseInt(item.tax_id))){
        taxes.push({
          tax_id: item.tax_id,
          application_order: group.application_order !== undefined ? group.application_order : counter,
          adjusts_taxable_total: group.application_order !== undefined && !group.application_order ? 0 : 1,
          calculate_from_subtotal: group.calculate_from_subtotal !== undefined ? group.calculate_from_subtotal : 0,
        });
        incrementCounter = true;
      }
    });
    if(incrementCounter) counter++;
  });
  payload.taxes = taxes;
  return payload;
};
