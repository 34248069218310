import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Row, Table, Card} from 'react-bootstrap';
import ContentConcealer from '../../../common/concealers/ContentConcealer';
import FeedingScheduleDetails from './FeedingScheduleDetails';

const PlantUpdatedDetails = ({delta, model}) => {
  return ((delta.length || model.feedingSchedule) ?
    <Card>
      <Row>
        <Col xs={12}>
          <h3>{I18n.t('plantHistory.plant.plantDetails')}</h3>
          <Table striped>
            <thead>
            <tr>
              <th>{I18n.t('plantHistory.field')}</th>
              <th>{I18n.t('plantHistory.valueAdjustment')}</th>
            </tr>
            </thead>
            <tbody>
            {delta.map((field) =>
              <tr key={field.name}>
                <td>{I18n.t(`plantHistory.plant.${field.name}`)}</td>
                <td>{field.prev} -> {field.current}</td>
              </tr>
            )}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <ContentConcealer show={model.feedingSchedule}>
          <Col xs={12}>
            <h3>{I18n.t('plantHistory.feedingScheduleDetails')}</h3>
            <FeedingScheduleDetails model={model.feedingSchedule}/>
          </Col>
        </ContentConcealer>
      </Row>
    </Card> : null
  );
};

PlantUpdatedDetails.propTypes = {
  delta: PropTypes.array.isRequired,
  model: PropTypes.object.isRequired,
};

export default PlantUpdatedDetails;
