import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {FieldArray} from 'redux-form';
import { Nav, Tab, Col } from 'react-bootstrap';
import AccordionPanel from '../../common/AccordionPanel';
import * as filesSelectors from '../../../selectors/filesSelectors';
import CreateFacilityMainInfo from './CreateFacilityMainInfo';
import ObjectFilesFieldArray from '../../common/form/files/ObjectFilesFieldArray';
import FacilityBankingInformation from './FacilityBankingInformation';
import { state } from '../../../constants/fileTypes';

class OrganizationFacilitiesFieldArray extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.renderBankingTab = this.renderBankingTab.bind(this);
    this.renderDocumentationTab = this.renderDocumentationTab.bind(this);
  }

  onTabSelect(e, prefix, value){
    e.preventDefault();
    e.stopPropagation();
    e.target.blur();
    this.props.change(`${prefix}.selectedTab`, value);
  }

  renderDocumentationTab(facility, facilityPath) {
    const { change, getLicenseDocsFilesForFacility } = this.props;
    const fieldName = `${facilityPath}.files`;
    const shouldDisableStateLicenseField = facility['has_leaf_pa_config_pack_and_closed_loop'];

    return (
      <FieldArray
        name={`${facilityPath}.files`}
        component={ObjectFilesFieldArray}
        props={{
          change,
          types: getLicenseDocsFilesForFacility(facility),
          files: facility && facility.files || [],
          name: fieldName,
          facilityState: facility.province_code,
          isFieldRequired: (type) => type === 'dea_number',
          isFieldReadOnly: (type) => (shouldDisableStateLicenseField && type === state),
        }}
      />
    );
  }

  renderBankingTab(facility, facilityPath) {
    const { change } = this.props;

    return (
      <FieldArray
        name={`${facilityPath}.banking`}
        component={FacilityBankingInformation}
        props={{
          change,
          name:`${facilityPath}.banking`
        }}
      />
    );
  }

  render() {
    const {fields: {map}, facilities, change, retailFacilityTypes, facilitiesWithWaLeaf, formSelector, touch} = this.props;
    return (
      <div>
        {map((facilityPath, index) => {
          const facility = facilities[index];
          const selectedTab = formSelector(`${facilityPath}.selectedTab`);
          return (
            <div key={`facility-block-${index}`} className='facility-block'>
                <AccordionPanel title={() => facility && facility.name}> {/* don't touch title function! see MJP-14715 comments */}
                  <CreateFacilityMainInfo
                    initialCountry={facility.country_code}
                    change={change}
                    touch={touch}
                    type={facility.type}
                    formSelector={formSelector}
                    retailFacilityTypes={retailFacilityTypes}
                    facilityPath={facilityPath}
                    facilitiesWithWaLeaf={facilitiesWithWaLeaf}
                    facilityId={facility.id}
                  />
                  <Col md={12}>
                    <Tab.Container id='left-tabs-example' defaultActiveKey='documentation' activeKey={selectedTab}>
                      <Nav variant='tabs' style={{marginLeft: 0, marginRight: 0}}>
                        <Nav.Item>
                          <Nav.Link eventKey='documentation' onClick={(e) => this.onTabSelect(e, facilityPath, 'documentation')}>License Documentation</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey='banking' onClick={(e) => this.onTabSelect(e, facilityPath, 'banking')}>Banking</Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content style={{padding: '12px 30px', border: '1px solid #ccc', position: 'relative', top: '12px', borderTopWidth: '0'}}>
                        <Tab.Pane eventKey='documentation'>
                          {this.renderDocumentationTab(facility, facilityPath)}
                        </Tab.Pane>
                        <Tab.Pane eventKey='banking'>
                          {this.renderBankingTab(facility, facilityPath)}
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </Col>
                </AccordionPanel>
            </div>
          );
        })}
      </div>
    );
  }
}

OrganizationFacilitiesFieldArray.propTypes = {
  facilities: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    push: PropTypes.func.isRequired,
    map: PropTypes.func.isRequired,
    pop: PropTypes.func.isRequired,
    length: PropTypes.number.isRequired
  }),
  retailFacilityTypes: PropTypes.array.isRequired,
  facilitiesWithWaLeaf: PropTypes.array.isRequired,
  valid: PropTypes.bool.isRequired,
};


export default connect(
  (state) => ({
    getLicenseDocsFilesForFacility: filesSelectors.getLicenseDocsFilesForFacility(state)
  })
)(OrganizationFacilitiesFieldArray);
