import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {goBack} from 'react-router-redux';
import get from 'lodash.get';

import * as apiActions from '../../actions/apiActions';
import {unsetItem} from '../../actions/itemActions';
import * as itemNames from '../../constants/itemNames';
import * as dataNames from '../../constants/dataNames';
import {getDrivers} from '../../selectors/driversSelectors';
import {getImage,getInsuranceDocument} from '../../selectors/filesSelectors';
import {getIntegrationState} from '../../selectors/integration/integrationSelectors';
import {getVehicle} from '../../selectors/vehiclesSelectors';
import FormWrapper from '../common/form/FormWrapper';
import CreateVehicleForm from './CreateVehicleForm'; //eslint-disable-line
import {getInternationalPhoneCountryCode, getInternationalPhoneNumber} from '../../util/internationalHelpers';

export class ModifyVehiclePage extends React.PureComponent{
  constructor(props){
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.onDocChange = this.onDocChange.bind(this);
    this.changeUploadStatus = this.changeUploadStatus.bind(this);
    this.getVehicleImage = this.getVehicleImage.bind(this);
    this.getInsuranceDocument = this.getInsuranceDocument.bind(this);

    this.state = { uploading: false };
  }

  componentWillMount() {
    const {actions: {unsetItem,getItem,getUnpaginatedData}} = this.props;
    unsetItem(itemNames.vehicle);
    unsetItem(itemNames.image);
    unsetItem(itemNames.insuranceDocument);

    getItem(`/api/vehicles/${this.props.params.id}`, itemNames.vehicle).then(
      (vehicle) => {
        if (vehicle.photo_file_id) {
          this.getVehicleImage(vehicle.photo_file_id);
        }
        if (vehicle.insurance_file_id) {
          this.getInsuranceDocument(vehicle.insurance_file_id);
        }
      }
    );

    if(!this.props.drivers){
      getUnpaginatedData('/api/drivers', dataNames.drivers);
    }

    if(!this.props.integrationSettings){
      getItem(
        '/api/integration-settings',
        itemNames.integrationSettings,
        {failed: 'stateIntegratorSettings.get.failed'}
      );
    }
  }

  componentWillUnmount() {
    this.props.actions.unsetItem(itemNames.vehicle);
    this.props.actions.unsetItem(itemNames.image);
    this.props.actions.unsetItem(itemNames.insuranceDocument);
  }

  getVehicleImage(id) {
    this.props.actions.unsetItem(itemNames.image);
    return this.props.actions.getItem(`/api/images/${id}`, itemNames.image);
  }

  getInsuranceDocument(id) {
    this.props.actions.unsetItem(itemNames.insuranceDocument);
    return this.props.actions.getItem(`/api/documents/${id}`, itemNames.insuranceDocument);
  }

  onSubmit(data){

    data.insurance_phone_country_code = getInternationalPhoneCountryCode(data.insurance_phone_number);
    data.insurance_phone_number = getInternationalPhoneNumber(data.insurance_phone_number);

    this.props.actions.putItem(
      `/api/vehicles/${data.id}`,
      data,
      itemNames.vehicle,
      {
        success: 'cultivation.vehicles.edit.success',
        failed: 'cultivation.vehicles.edit.fail'
      },
      null,
      this.props.actions.goBack
    );
  }

  onImageChange(file) {
    if(file.id) this.getVehicleImage(file.id);
  }

  onDocChange(file) {
    if(file.id) this.getInsuranceDocument(file.id);
  }

  changeUploadStatus(status) {
    this.setState({uploading: status});
  }

  render(){
    const {drivers, image, insuranceDocument, integrationState, vehicle} = this.props;

    vehicle.insured_drivers = get(vehicle,'insured_drivers',[]);

    const namedDrivers = drivers.map((driver) => {
      driver.name = `${driver.first_name}` + ' ' + `${driver.last_name}`;
      return driver;
    });

    return(
      <FormWrapper title={I18n.t('cultivation.vehicles.editTitle')} goBack={this.props.actions.goBack}>
        {vehicle.id ? <CreateVehicleForm
          changeUploadStatus={this.changeUploadStatus}
          documentUrl={get(insuranceDocument,'url',null)}
          drivers={namedDrivers}
          imageUrl={get(image,'url.original',null)}
          initialValues={vehicle}
          integrationState={integrationState}
          onDocChange={this.onDocChange}
          onImageChange={this.onImageChange}
          onSubmit = {this.onSubmit}
          uploading={this.state.uploading}
        /> : null}
      </FormWrapper>
    );
  }
}

ModifyVehiclePage.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string
  }),
  actions: PropTypes.shape({
    getItem: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    putItem: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired,
  }),
  drivers: PropTypes.array.isRequired,
  image: PropTypes.object.isRequired,
  insuranceDocument: PropTypes.object.isRequired,
  integrationState: PropTypes.object.isRequired,
  vehicle: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    drivers: getDrivers(state),
    image: getImage(state),
    insuranceDocument: getInsuranceDocument(state),
    integrationState: getIntegrationState(state),
    vehicle: getVehicle(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {goBack, unsetItem});
  return {actions: bindActionCreators(actions, dispatch)};
}

export default connect(mapStateToProps, mapDispatchToProps) (ModifyVehiclePage);
