import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import get from 'lodash.get';
import {Field} from 'redux-form';
import { Col, Row } from 'react-bootstrap';
import InternationalDisplayQty from '../../../common/InternationalDisplayQty';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import CheckBoxInput from '../../../common/form/CheckBoxInput';

const AutoProductsMap = ({
  incomingTransferProducts,
  compatibleItemMasters,
  change,
  formValues,
  error,
  formErrors,
  isActiveFacilityLab
}) => {

  const handleSelectAllChange = (event) => {
    const {target: {checked}} = event;
    if (checked) {
      change(`select_all_products_automap`, 0);
    }
    change(`select_all_products_automap`, checked);
    incomingTransferProducts.forEach((item, itemIndex) => {
      if (checked) {
        change(`auto_po_product_map_${item.id}`, 0);
      }
      change(`auto_po_product_map_auto_${item.id}`, checked);
    });
  };

  return (
    <React.Fragment>
      <Row>
        {isActiveFacilityLab &&
          <Col xs={12}>
            <Row>
              <Col xs={4}>
                <h4>{I18n.t('supplyChainMapping.form.incomingProducts')}</h4>
              </Col>
              <Col xs={6}>
                <h4>{I18n.t('supplyChainMapping.form.purchaseOrderProducts')}</h4>
              </Col>
              <Col xs={2}>
                <Field
                  name='select_all_products_automap'
                  style={{width:'18px'}}
                  component={CheckBoxInput}
                  className='inline-checkbox'
                  props={{
                    label: I18n.t('supplyChainMapping.form.selectAllToAutomap'),
                    disabled: get(formValues, 'auto_generate_po', false) ? false : true,
                    groupClassName: 'inline-checkbox nudge-to-baseline',
                    isRequired: false,
                    onChange: handleSelectAllChange
                  }}
                />
              </Col>
            </Row>
          </Col>
        }

        <Col xs={12}>
          {incomingTransferProducts.map((item, itemIndex) => {
            return (
              <Row key={itemIndex}>
                <Col xs={4}>
                  <Row>
                    <Col md={8}>
                      <h4>{I18n.t('supplyChainMapping.form.productName')}</h4>
                      {item.item_master_name}
                    </Col>
                    <Col md={4}>
                      <h4>{I18n.t('supplyChainMapping.form.quantity')}</h4>
                      <InternationalDisplayQty qty={item.qty_display} uom={item.uom_display} displayUom={true}/>
                    </Col>
                  </Row>
                </Col>
                <Col xs={6}>
                  <Field
                    name={`auto_po_product_map_${item.id}`}
                    component={ReactSelectInput}
                    textKey='name'
                    valueKey='id'
                    props={{
                      disabled: get(formValues,`auto_po_product_map_auto_${item.id}`, false),
                      placeholder: I18n.t('supplyChainMapping.form.selectItemMaster'),
                      label: I18n.t('supplyChainMapping.form.productMapping'),
                      options: compatibleItemMasters[item.id],
                      isRequired: !get(formValues,`auto_po_product_map_auto_${item.id}`, false),
                    }}
                  />
                </Col>
                <Col xs={2}>
                  {isActiveFacilityLab &&
                    <Field
                      name={`auto_po_product_map_auto_${item.id}`}
                      style={{width:'18px'}}
                      className='inline-checkbox'
                      component={CheckBoxInput}
                      props={{
                        label: I18n.t('supplyChainMapping.form.automaticallyMapProduct'),
                        groupClassName: 'inline-checkbox nudge-to-baseline',
                        onChange: (event) => {
                          const {target: {checked}} = event;
                          if (checked) {
                            change(`auto_po_product_map_${item.id}`, 0);
                          }
                          change(`auto_po_product_map_auto_${item.id}`, checked);
                        }
                      }}
                    />
                  }
                </Col>
                <Col xs={12}>
                  <hr />
                </Col>
              </Row>
            );
          })}
        </Col>
      </Row>
    </React.Fragment>
  );
};

AutoProductsMap.propTypes = {
  meta: PropTypes.object,
  incomingTransferProducts: PropTypes.array,
  compatibleItemMasters: PropTypes.object,
  formErrors: PropTypes.object,
  error: PropTypes.string,
  change: PropTypes.func,
  formValues: PropTypes.object,
  isActiveFacilityLab: PropTypes.bool.isRequired
};

export default AutoProductsMap;
