import React from 'react';
import PropTypes from 'prop-types';
import {Field, reduxForm} from 'redux-form';
import {DAILY_WASTE_FORM} from '../../../constants/forms';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import NumericInput from '../../common/form/NumericInput';
import validate from './validate';

const DailyWasteForm = (props) => {

  const {handleSubmit, storageLocations} = props;
  return (<form onSubmit={handleSubmit} name={DAILY_WASTE_FORM} noValidate={true}>
    <div>
      <p>
        Note: It is possible that the waste source location and destination location are the same.  The difference is that
        the loose waste material is converted to a waste package for disposal or destruction.
      </p>
      <p className='text-muted'>
        * All fields are required.
      </p>
    </div>
    <div>
      <Field
        name='source_id'
        component={ReactSelectInput}
        props={{
          label: 'Source Location',
          options: [...storageLocations].map( location => {
            return location.text.indexOf('----') === -1 ? ({...location, disabled: true}) : ({...location, disabled: false});
          }),
          clearable: false,
          placeholder: 'Select The Source Of Waste Product'
        }}
      />
    </div>
    {/*Original story called for this field... leaving it to see if it comes up*/}
    {/*<div>*/}
      {/*<Field*/}
        {/*name='storage_location_id'*/}
        {/*component={ReactSelectInput}*/}
        {/*props={{*/}
          {/*label: 'Pre Destruction Location',*/}
          {/*options: props.storageLocations,*/}
          {/*clearable: false,*/}
          {/*placeholder: 'Select a storage location.'*/}
        {/*}}*/}
      {/*/>*/}
    {/*</div>*/}
    <div>
      <Field
        name='waste_weight'
        component={NumericInput}
        props={{
          label: 'Weight (In Grams)'
        }}
      />
    </div>
  </form>);

};

DailyWasteForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  storageLocations: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func,
};

export default reduxForm({
  form: DAILY_WASTE_FORM,
  validate,
  enableReinitialize: true,
})(DailyWasteForm);
