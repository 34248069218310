import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {Col, Row} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import TextInput from '../../common/form/TextInput';

const ReturnedItems = (props) => {
  const {fields, locations} = props;

  return (<div>
    {
      fields.map((returnedItem, index) => {
        return (<Row key={index}>
          <Col xs={6} sm={4} md={2}>
            <Field
              name={`${returnedItem}.package_code`}
              component={TextInput}
              props={{
                label: 'Package Code',
                readOnly: true,
              }}
            />
          </Col>
          <Col xs={6} sm={4} md={2}>
            <Field
              name={`${returnedItem}.quantity`}
              component={TextInput}
              props={{
                label: 'Ordered Quantity',
                disabled: true
              }}
            />
          </Col>
          <Col xs={6} sm={4} md={2}>
            <Field
              name={`${returnedItem}.received_quantity`}
              component={TextInput}
              props={{
                label: 'Received Quantity',
                disabled: true
              }}
            />
          </Col>
          <Col xs={6} sm={4} md={2}>
            <Field
              name={`${returnedItem}.inventory_type`}
              component={TextInput}
              props={{
                label: 'Inventory Type',
                disabled: true
              }}
            />
          </Col>
          <Col xs={6} sm={4} md={2}>
            <Field
              name={`${returnedItem}.inventory_location_id`}
              component={ReactSelectInput}
              props={{
                options: locations,
                label: I18n.t('transfers.form.storageLocation'),
                textKey: 'text',
                valueKey: 'value',
              }}
            />
          </Col>
        </Row>);
      })
    }
  </div>);
};

ReturnedItems.propTypes = {
  fields: PropTypes.object,
  returnedItems: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired
};

export default ReturnedItems;
