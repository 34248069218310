/* eslint-disable import/prefer-default-export*/
import {createSelector} from 'reselect';
import get from 'lodash.get';
import * as dataNames from '../constants/dataNames';
import * as itemNames from '../constants/itemNames';
import {reservationSources} from '../constants/reservationSources';
import {convertFromBase} from '../util/uomHelpers';
import {convertDbDateTimeToFullDateTime} from '../util/dateHelpers';
import {EA} from '../constants/uoms';
import {displayQty} from './uomsSelectors';

const getApiReservations = (state) => state[dataNames.reservations];
const getItemMasterId = (_, props) => props.item_master_id;
const getExcludedSO = (_, props) => props.excluded_so_id;
const getTimezone = (state) => state.timezone;
const getInventory = (state) => state[dataNames.inventoryItems];
const getItemMaster = (state) => state[itemNames.itemMaster];
const getPrepackWeights = (state) => state[dataNames.prepackWeights];
const getItemMasterChildren = (state) => state[dataNames.itemMasterChildren];

export const getReservations = createSelector(
  [getApiReservations, getTimezone, getInventory, getItemMaster, getItemMasterChildren, getPrepackWeights, displayQty],
  (reservations, timezone, inventory, itemMaster, itemMasterChildren, prepackWeights, displayQty) => {

    const mapEntityType = (type) => {
      return reservationSources[type] ? reservationSources[type] : type;
    };

    const getPrepackWeight = (itemMaster) => {
      if(!prepackWeights.length) return undefined;
      return prepackWeights.find((weight) => weight.id === get(itemMaster, 'prepack_weight_id', get(itemMaster, 'inventory_attributes.prepack_weight_id', false)));
    };

    const convertWeightToPrepackEach = (childItemMaster, reservation) => {
      const prepackWeight = getPrepackWeight(childItemMaster);
      if(!prepackWeight) return 0;

      return displayQty(reservation.qty_base / prepackWeight.weight_base, EA);
    };

    const isPrepack = get(itemMaster, 'inventory_attributes.is_prepack', 0);

    return reservations.map( (reservation) => {
      const item = inventory.find((item) => {
        const itemId = get(item, 'id', 0);
        return itemId === reservation.item_id;
      });
      const currentItemMaster = isPrepack
        ? itemMasterChildren.find((child) => {
          return child.items.find((item) => {
            return item.id === reservation.item_id;
          });
        })
        : itemMaster;

      const entityType = get(reservation, 'entity_type');

      const qty = isPrepack ?
        convertWeightToPrepackEach(currentItemMaster || itemMaster, reservation) :
        displayQty(convertFromBase(reservation.qty_base, reservation.uom_display), reservation.uom_display);

      return {
        ...reservation,
        timezone,
        qty: qty,
        fulfilled: get(reservation, 'fulfilled', 0),
        prepackWeight: isPrepack ? get(getPrepackWeight(currentItemMaster || itemMaster), 'name', '-') : '-',
        item,
        package_id: get(item, 'package_code', get(item, 'lot_number')),
        batch_id: get(item, 'lot_number'),
        location: get(item, 'location_name'),
        entity_type: mapEntityType(entityType),
        entityType,
        created_at: convertDbDateTimeToFullDateTime(get(reservation, 'created_at'), get(reservation, 'timezone')),
      };
    });
  }
);

export const getReservationsQtyByItemMaster = createSelector(
  [getReservations, getItemMasterId, getExcludedSO], (reservations, item_master_id, excluded_so_id) =>
    reservations
      .filter(reservation =>
        reservation.item_master_id === item_master_id
        && !(reservation.entity_id === excluded_so_id && reservation.entity_type === 'sales_order'))
      .reduce((amount, { qty }) => amount + qty, 0)
);
