import get from 'lodash.get';
import { createStructuredSelector, createSelector } from 'reselect';
import { hasStateIntegrator, isNormal } from './commonSelectors';
import { isCureIntegrator } from './cureApiSelectors';
import { isCanadaFacility, isColombiaFacility, isLesothoFacility } from '../facility/getFacilityCountry';
import { isFlorida, isOklahoma, isCCA } from '../facility/getCurrentFacility';
import { isLeafIntegrator, isLeafSetupModeEnabled, isPaLeafIntegrator, isWaLeafIntegrator } from './leafSelectors';
import { isUtahSelector } from './utahSelectors';

import {
  isMetrcIntegrator,
  isAlMetrcIntegrator,
  isCoMetrcIntegrator,
  isMdMetrcIntegrator,
  isOrMetrcIntegrator,
  isOregonRecMetrc,
  isDcMetrcIntegrator,
  isCaMetrcIntegrator,
  isMiMetrcIntegrator,
  isOhMetrcIntegrator,
  isAkMetrcIntegrator,
  isMoMetrcIntegrator,
  isMtMetrcIntegrator,
  isMetrcTransfersEnabled
} from './metrcSelectors';
import {
  isBiotrackIntegrator,
  isIlBiotrackIntegrator,
  isPrBiotrackIntegrator,
  isBiotrackSetupModeEnabled,
  hasBiotrackFacilities,
  getBiotrackSettings
} from './biotrackSelectors';
import { isApisicpaIntegrator, isApisicpaSetupModeEnabled, isIlApisicpaIntegrator } from './apisicpaSelectors';
import {
  isLeaflyIntegrator,
  isWeedmapsIntegrator,
  isOnfleetIntegrator,
  isIsolocityIntegrator,
  isHypurIntegrator,
  isFutuCareIntegrator,
  isMxMerchantIntegrator,
  isAeropayIntegratedIntegrator,
  isPosabitIntegrator,
  isAlleavesIntegrator
} from './thirdPartyIntegrationSelectors';
import {getIsHempFacility, getIsGlobalIdEnabled} from '../coreSettingsSelectors';
import {allowAccessToStateIntegratorPage} from '../integrationSelectors';

/**
 * Single generic selector which knows everything about the integration state
 * @typedef {Object} IntegratorState
 * @property {bool} hasIntegrator
 * @property {bool} isCure
 * @property {bool} isLeaf
 * @property {bool} isPaLeaf
 * @property {bool} isWaLeaf
 * @property {bool} isMetrc
 * @property {bool} isAlMetrc
 * @property {bool} isCoMetrc
 * @property {bool} isMdMetrc
 * @property {bool} isOrMetrc
 * @property {bool} isMiMetrc
 * @property {bool} isOrRecMetrc
 * @property {bool} isOhMetrc
 * @property {bool} isBiotrack
 * @property {bool} isPrBiotrack
 * @property {bool} isIlBiotrack
 * @property {bool} isApisicpa
 * @property {bool} isIlApisicpa
 * @property {bool} isLeafly
 * @property {bool} isWeedmaps
 * @property {bool} isUtah
 * @return {IntegratorState}
 */
export const getIntegrationState = createStructuredSelector({
  hasIntegrator: hasStateIntegrator,
  isNormal,

  isCure: isCureIntegrator,
  isBiotrack: isBiotrackIntegrator,
  hasBiotrack: hasBiotrackFacilities,
  isPrBiotrack: isPrBiotrackIntegrator,
  isIlBiotrack: isIlBiotrackIntegrator,
  isApisicpa: isApisicpaIntegrator,
  isIlApisicpa: isIlApisicpaIntegrator,
  isLeaf: isLeafIntegrator,
  isPaLeaf: isPaLeafIntegrator,
  isWaLeaf: isWaLeafIntegrator,
  isMetrc: isMetrcIntegrator,
  isMetrcTransfersEnabled: isMetrcTransfersEnabled,
  isCaMetrc: isCaMetrcIntegrator,
  isAlMetrc: isAlMetrcIntegrator,
  isCoMetrc: isCoMetrcIntegrator,
  isAkMetrc: isAkMetrcIntegrator,
  isMdMetrc: isMdMetrcIntegrator,
  isOrMetrc: isOrMetrcIntegrator,
  isMoMetrc: isMoMetrcIntegrator,
  isOrRecMetrc: isOregonRecMetrc,
  isDcMetrc: isDcMetrcIntegrator,
  isMiMetrc: isMiMetrcIntegrator,
  isOhMetrc: isOhMetrcIntegrator,
  isMtMetrc: isMtMetrcIntegrator,
  isCanada: isCanadaFacility,
  isLesotho: isLesothoFacility,
  isColombia: isColombiaFacility,
  isFlorida: isFlorida,
  isOklahoma: isOklahoma,
  isLeafly: isLeaflyIntegrator,
  isWeedmaps: isWeedmapsIntegrator,
  isUtah: isUtahSelector,
  isOnfleet: isOnfleetIntegrator,
  isIsolocity: isIsolocityIntegrator,
  isHypur: isHypurIntegrator,
  isAeropayIntegrated: isAeropayIntegratedIntegrator,
  isPosabit: isPosabitIntegrator,
  isAlleaves: isAlleavesIntegrator,
  isMxMerchant: isMxMerchantIntegrator,
  isHemp: getIsHempFacility,
  isGlobalIdEnabled: getIsGlobalIdEnabled,
  allowAccessToStateIntegratorPage: allowAccessToStateIntegratorPage,
  isFutuCare: isFutuCareIntegrator,
  isCca: isCCA,
});

/**
 * Check whether need to force Incoming Transfer Mapping
 * @return {boolean}
 */
export const isIncomingTransferMappingEnabled = createSelector(
  [getIntegrationState],
  ({ isLeaf, isBiotrack, isApisicpa }) => isLeaf || isBiotrack || isApisicpa
);

export const isIncomingTransferMappingRequired = createSelector(
  [getIntegrationState, getBiotrackSettings],
  ({ isLeaf, isBiotrack, isApisicpa }, biotrackSettings) =>
    isLeaf || (isBiotrack && !get(biotrackSettings, 'is_fifteen_day_window')) || isApisicpa
);

/**
 * Check if setup mode is turned on
 * @return {boolean}
 */
export const isSetupMode = createSelector(
  [isApisicpaSetupModeEnabled, isBiotrackSetupModeEnabled, isLeafSetupModeEnabled],
  (...setupModes) => setupModes.some(Boolean)
);

export const needToValidateOnCheckin = createSelector(
  [getIntegrationState],
  (isPaLeaf) => {
    return Boolean(isPaLeaf);
  }
);
