import React from 'react';
import PropTypes from 'prop-types';

import {I18n} from 'react-redux-i18n';
import {Modal, Button, Row, Col} from 'react-bootstrap';
import {reduxForm, Field} from 'redux-form';

import DatePickerInput from '../../common/form/DatePickerInput';

const BirthdateModal = ({showModal, hideModal, handleSubmit}) => {
  return (
    <Modal show={showModal} onHide={hideModal} className='birthdate-modal'>
      <Modal.Header closeButton>
        <h3>{I18n.t('modal.enterBirthdate')}</h3>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12} sm={6} md={4}>
              <Field name='birth_date' component={DatePickerInput} props={{
                label: I18n.t('customers.create.dateOfBirth'),
                isRequired: true
              }} />
            </Col>
            <Col xs={12} sm={6} md={2}>
              <Button type='submit' className='btn-inline'>{I18n.t('setDOB')}</Button>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
};

BirthdateModal.propTypes = {
  showModal: PropTypes.bool,
  hideModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func
};



const BirthdateReduxForm = reduxForm({
  form: 'birthdate'
})(BirthdateModal);


export default BirthdateReduxForm;
