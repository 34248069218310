import React from 'react';
import map from 'lodash.map';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {Field, FieldArray, formValueSelector} from 'redux-form';
import {Button, Col, Row} from 'react-bootstrap';
import {FaTrash} from 'react-icons/fa';
import WeightTable from './WeightTable';
import ConsumerGroupsTable from './ConsumerGroupsTable';

import MultiselectInput from '../../../common/form/MultiselectInput';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import HoverTooltip from '../../../common/HoverTooltip';
import {PRODUCT_FORM} from '../../../../constants/forms';
import {getPricingClassForFacilities, getConsumerGroupsByPricingClass} from '../../../../selectors/pricingClassSelectors';
import InternationalCurrencyInput from '../../../common/form/InternationalCurrencyInput';
import InlineCheckBox from '../../../common/form/InlineCheckBox';

const WeightPriceAttributes = (props) => {
  const {
    fields,
    fieldName,
    showError,
    fieldIndex,
    fieldLabel,
    isWholesale,
    statusOptions,
    pricingClasses,
    consumerGroups,
    facilityOptions,
    isMassModifyForm,
    disablePriceBlock,
    isOrganizationMode,
    fieldCanModifiedMap,
    pricingGroupOptions,
  } = props;

  const keyForMap = fieldName.replace(/(\[\d+])/, '');
  return (
    <Col xs={12}>
      <Row>
        {isMassModifyForm ?
          <React.Fragment>
            <Col xs={4}>
              <Field name={`${fieldName}.is_non_taxable`} component={ReactSelectInput} props={{
                label: I18n.t('products.form.nonTaxableProduct'),
                options: statusOptions,
                rightAddon: fieldCanModifiedMap[`${keyForMap}.is_non_taxable`].length ?
                  <HoverTooltip text={I18n.t('products.massModify.errors.diff_categories_help', {field: I18n.t('products.form.nonTaxableProduct'), categories: fieldCanModifiedMap[`${keyForMap}.is_non_taxable`].join(' ,')})} id={`${fieldName}_tooltip`}/> : null

              }}/>
            </Col>
            <Col xs={8}>
              <InternationalCurrencyInput
                name={`${fieldName}.default_price`}
                props={{
                  fractionPartSize: 2,
                  placeholder: '0.00',
                  label: I18n.t(...fieldLabel),
                  isRequired: !isMassModifyForm && isOrganizationMode,
                  rightAddon: fieldCanModifiedMap[`${keyForMap}.default_price`].length ?
                  <HoverTooltip text={I18n.t('products.massModify.errors.diff_categories_help', {field: I18n.t(...fieldLabel), categories: fieldCanModifiedMap[`${keyForMap}.default_price`].join(' ,')})} id={`${fieldName}_tooltip`}/> : null
                }}/>
            </Col>
          </React.Fragment>
          :
          <Col xs={12} style={{marginBottom: '20px'}}>
            <Field name={`${fieldName}.is_non_taxable`} component={InlineCheckBox} props={{
              label: I18n.t('products.form.nonTaxableProduct'),
              groupClassName: 'inline-checkbox',
            }}/>
          </Col>
        }
      </Row>
      <Row>
        <Col xs={6} md={4}>
          {isWholesale ?
            null :
            <Row>
              <Col xs={12} md={8}>
                <Field name={`${fieldName}.pricing_group_id`} component={ReactSelectInput} props={{
                  label: I18n.t('products.form.pricingGroup'),
                  options: pricingGroupOptions,
                  textKey: 'name',
                  valueKey: 'id',
                  disabled: showError || disablePriceBlock,
                  rightAddon: showError
                    ? <HoverTooltip id={`${fieldName}.pricing_group_id_tooltip`} text={I18n.t('products.massModify.errors.diff_categories_error', {field: I18n.t('products.form.pricingGroup')})}/>
                    : (isMassModifyForm && fieldCanModifiedMap[`${keyForMap}.pricing_group_id`].length)
                      ? <HoverTooltip text={I18n.t('products.massModify.errors.diff_categories_help', {field: I18n.t('products.form.pricingGroup'), categories: fieldCanModifiedMap[`${keyForMap}.pricing_group_id`].join(' ,')})} id={`${fieldName}_tooltip`}/> : null
                }}/>
              </Col>
            </Row>
          }
          {isWholesale ?
            null :
            <Row>
              {isMassModifyForm ?
                <Col xs={8}>
                  <Field name={`${fieldName}.inherit_pricing_group_updates`} component={ReactSelectInput} props={{
                    label: I18n.t('products.form.inheritGroupChanges'),
                    options: statusOptions,
                    disabled: showError || disablePriceBlock,
                    rightAddon: showError ?
                      <HoverTooltip id={`${fieldName}.inherit_pricing_group_updates`} text={I18n.t('products.massModify.errors.diff_categories_error', {field: I18n.t('products.form.inheritGroupChanges')})}/>
                      : fieldCanModifiedMap[`${keyForMap}.inherit_pricing_group_updates`].length
                        ? <HoverTooltip text={I18n.t('products.massModify.errors.diff_categories_help', {field: I18n.t('products.form.inheritGroupChanges'), categories: fieldCanModifiedMap[`${keyForMap}.inherit_pricing_group_updates`].join(' ,')})} id={`${fieldName}_tooltip`}/> : null
                  }}/>
                  <FieldArray
                    name={`${fieldName}.weight_prices`}
                    component={WeightTable}
                    props={{
                      isMassModifyForm,
                      disablePriceBlock,
                      fieldCanModifiedMap,
                      keyForMap: `${keyForMap}.weight_prices`
                    }}
                  />
                </Col>
                :
                <Col xs={12}>
                  <Field name={`${fieldName}.inherit_pricing_group_updates`} component={InlineCheckBox} props={{
                    label: I18n.t('products.form.inheritGroupChanges'),
                    groupClassName: 'inline-checkbox',
                  }}/>
                  <FieldArray
                    name={`${fieldName}.weight_prices`}
                    component={WeightTable}
                    props={{
                      isMassModifyForm,
                      disablePriceBlock,
                      fieldCanModifiedMap,
                      keyForMap: `${keyForMap}.weight_prices`
                    }}
                  />
                </Col>}
            </Row>
          }
          {isOrganizationMode ?
            null :
            <Row>
              <Col xs={10} md={8}>
                <Field name={`${fieldName}.facility_ids`} component={MultiselectInput} props={{
                  label: I18n.t('taxes.form.applicableFacilities'),
                  options: facilityOptions,
                  isRequired: !isMassModifyForm,
                  allOption: true,
                  rightAddon: isMassModifyForm && fieldCanModifiedMap[`${keyForMap}.facility_ids`].length ?
                    <HoverTooltip text={I18n.t('products.massModify.errors.diff_categories_help', {field: I18n.t('taxes.form.applicableFacilities'), categories: fieldCanModifiedMap[`${keyForMap}.facility_ids`].join(' ,')})} id={`${fieldName}_tooltip`}/> : null

                }}/>
              </Col>
              <Col xs={2} style={{paddingTop: '28px'}}>
                <Button variant='danger' size='sm' onClick={() => fields.remove(fieldIndex)}>
                  <FaTrash/>
                </Button>
              </Col>
            </Row>
          }
        </Col>
        <Col xs={6} md={4}>
          {(!isMassModifyForm) && (
            <Row>
              <Col xs={12} md={8}>
                <InternationalCurrencyInput
                  name={`${fieldName}.default_price`}
                  props={{
                    fractionPartSize: 2,
                    label: I18n.t(...fieldLabel),
                    isRequired: isOrganizationMode,
                  }}/>
              </Col>
            </Row>
          )}
          {(!isWholesale) && (
            <Row>
              <Col xs={12} md={8}>
                <Field name={`${fieldName}.pricing_class_id`} component={ReactSelectInput} props={{
                  label: I18n.t('products.form.pricingClass'),
                  options: pricingClasses,
                  textKey: 'name',
                  valueKey: 'id',
                  disabled: disablePriceBlock,
                  rightAddon: isMassModifyForm && fieldCanModifiedMap[`${keyForMap}.pricing_class_id`].length ?
                    <HoverTooltip
                      text={I18n.t('products.massModify.errors.diff_categories_help', {field: 'Pricing Class', categories: fieldCanModifiedMap[`${keyForMap}.pricing_class_id`].join(' ,')})}
                      id={`${fieldName}_tooltip`}
                    /> : null
                }}/>
              </Col>
              {(isMassModifyForm)
                ? (
                  <Col xs={8}>
                    <Field name={`${fieldName}.inherit_pricing_class_updates`} component={ReactSelectInput} props={{
                      label: I18n.t('products.form.inheritClassChanges'),
                      options: statusOptions,
                      disabled:disablePriceBlock,
                      rightAddon: fieldCanModifiedMap[`${keyForMap}.inherit_pricing_class_updates`].length ?
                        <HoverTooltip
                          text={I18n.t('products.massModify.errors.diff_categories_help', {field: 'Inherit Pricing Class Updates', categories: fieldCanModifiedMap[`${keyForMap}.inherit_pricing_class_updates`].join(' ,')})}
                          id={`${fieldName}_tooltip`}
                        /> : null
                    }}/>
                  </Col>
                )
                : (
                  <Col xs={12}>
                    <Field name={`${fieldName}.inherit_pricing_class_updates`} component={InlineCheckBox} props={{
                      label: I18n.t('products.form.inheritClassChanges'),
                      groupClassName: 'inline-checkbox',
                    }}/>
                  </Col>
                )}
              <Col xs={12}>
                <FieldArray
                  name={`${fieldName}.pricing_class_consumer_groups`}
                  component={ConsumerGroupsTable}
                  props={{
                    rows: consumerGroups,
                  }}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Col>
  );
};

WeightPriceAttributes.propTypes = {
  isOrganizationMode: PropTypes.bool.isRequired,
  isWholesale: PropTypes.bool.isRequired,
  fields: PropTypes.shape({
    remove: PropTypes.func,
  }),
  fieldName: PropTypes.string.isRequired,
  fieldLabel: PropTypes.array.isRequired,
  fieldIndex: PropTypes.number,
  facilityOptions: PropTypes.array,
  pricingGroupOptions: PropTypes.array.isRequired,
  consumerGroups: PropTypes.array.isRequired,
  pricingClasses: PropTypes.array.isRequired,
  disablePriceBlock: PropTypes.bool.isRequired,
  isMassModifyForm: PropTypes.bool.isRequired,
  fieldCanModifiedMap: PropTypes.object,
  statusOptions: PropTypes.array,
  showError: PropTypes.bool,
  formName: PropTypes.string
};


export default connect((state, ownProps) => {
  const selector = formValueSelector(ownProps.formName ? ownProps.formName : PRODUCT_FORM);
  const pricingClassId = get(selector(state, ownProps.fieldName), 'pricing_class_id');
  const facilityList = get(selector(state, ownProps.fieldName), 'facility_ids', []);
  const applicableFacilitiesIds = (typeof facilityList[0] === 'object') ? map(facilityList, 'value') : facilityList;

  return {
    pricingClasses: getPricingClassForFacilities(state)(applicableFacilitiesIds),
    consumerGroups:  getConsumerGroupsByPricingClass(state)(pricingClassId),
  };
})(WeightPriceAttributes);
