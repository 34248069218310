import React from 'react';
import PropTypes from 'prop-types';
import mappings from './integrations/mappings';

const ThirdPartyIntegrationForm = (props) => {
  const { integration, handleSubmit, actions } = props;
  const IntegrationComponent = mappings[integration.name];

  if (!IntegrationComponent) {
    return null;
  }

  return (
    <form className='third-party-integration-form' onSubmit={handleSubmit} noValidate={true}>
      <IntegrationComponent
        integration={integration}
        actions={actions}
      />
    </form>
  );
};

ThirdPartyIntegrationForm.propTypes = {
  integration: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired
};

export default ThirdPartyIntegrationForm;
