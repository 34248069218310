import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import InternationalQuantityByUomInput from '../../../../common/form/InternationalQuantityByUomInput';
import InternationalCurrencyInput from '../../../../common/form/InternationalCurrencyInput';


const UnitPackage = ({line, component, lineItemQuantity, lineOrderedQuantity, fieldsLine, editableLinePrice, change, locked,
                       integrationState: {isLeaf, isBiotrack}, metrcIsImported, onlyEntireQuantityCanBeTransferred,
                       isReassignMode, changeUnitCostAdjustAll, changeLineUnitPriceAndTotal, getValue, hasCostEditPermission, isFeatureToggleEditInventoryItemCost}) => {
  let quantityValidatorResult;
  if (isBiotrack) {
    quantityValidatorResult = parseFloat(lineItemQuantity) > parseFloat(lineOrderedQuantity);
  } else if (metrcIsImported) {
    quantityValidatorResult = parseFloat(lineItemQuantity) > parseFloat(lineOrderedQuantity);
  } else {
    quantityValidatorResult = parseFloat(lineItemQuantity) !== parseFloat(lineOrderedQuantity);
  }


  const leftAddon = isLeaf || onlyEntireQuantityCanBeTransferred ? {} :
    {leftAddon: (
    <div style={{cursor: 'pointer'}} onClick={(event) => {
      const input  = event.target.parentNode.parentNode.getElementsByTagName('input');
      const newState = !input[0].readOnly;
      input[0].readOnly = newState;
      event.target.innerHTML = (!newState) ? 'Lock' : 'Edit';
    }}>
      Edit
    </div>
  )};
  const [editButtonClick, setEditButtonClicked] = useState(true);
  const toggleEditButtonClick = () => {
    changeUnitCostAdjustAll();
    setEditButtonClicked(!editButtonClick);
  };

  return (
    <Col lg={12}>
      <Row>
      {component}
        <Col lg={2}>
          <InternationalQuantityByUomInput
            name={`${line}.ordered_qty`}
            label={I18n.t('cultivation.transfers.form.unitsOrdered')}
            readOnly={true}
            // TODO: uom - replace hardcoded value with itemMaster default_uom value
            uom='EA'
            {...leftAddon}
          />
        </Col>
        <Col lg={2}>
          <InternationalQuantityByUomInput
            name={`${line}.qty`}
            readOnly={true}
            label={I18n.t('cultivation.transfers.form.unitsReceived')}
            // TODO: uom - replace hardcoded value with itemMaster default_uom value
            uom='EA'
            value={lineItemQuantity}
          />
        </Col>
        <Col lg={2}>
        {!fieldsLine.is_ingredient && (
          <InternationalCurrencyInput
            name={`${line}.unit_price`}
            props={{
              label: I18n.t('purchaseOrders.form.pricePerUnit'),
              disabled: editButtonClick || !hasCostEditPermission || !isReassignMode || !isFeatureToggleEditInventoryItemCost,
              onBlur: (e) => changeLineUnitPriceAndTotal(e.target.value, line),
              rightAddon: (
                isReassignMode && hasCostEditPermission && isFeatureToggleEditInventoryItemCost && (
                  <span className={`btn btn-primary ${!isReassignMode || !hasCostEditPermission || !isFeatureToggleEditInventoryItemCost ? 'disabled' : ''}`} onClick={() => {
                    toggleEditButtonClick();
                  }}>
                    {editButtonClick ? 'Edit' : 'Calc'}
                  </span>
                )
              )
            }}
           />
         )
        }
        </Col>
        <Col lg={2}>
          <InternationalCurrencyInput name={`${line}.line_item_price`}
            props={{
              label: I18n.t('purchaseOrders.form.itemPrice'),
              value: getValue(`${line}.line_item_price`),
              disabled: !editableLinePrice || isReassignMode,
              rightAddon: locked
                ? null
                : (
                  <span className={'btn btn-primary'} onClick={() => {
                    const newEditableValue = !editableLinePrice;
                    change(`${line}.editableLinePrice`, newEditableValue);
                  }}>
                    {editableLinePrice ? 'Calc' : 'Edit'}
                  </span>
                )
            }}
          />
        </Col>
        </Row>
      <Row className={quantityValidatorResult ? '' : 'hide'}>
        <Col md={12}>
          <div className='text-danger' style={{marginBottom: '14px'}}>
            {
              I18n.t(
                `receipts.${metrcIsImported ? 'metrcImportedOrderedQuantityNotEqualFulfilledQuantity' : 'orderedQuantityNotEqualFulfilledQuantity'}`,
                {
                  orderedQuantityField: I18n.t('cultivation.transfers.form.unitsOrdered'),
                  fulfilledQuantityField: I18n.t('cultivation.transfers.form.unitsReceived')
                })
            }
          </div>
        </Col>
      </Row>
    </Col>
  );
};

UnitPackage.propTypes = {
  component: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  line: PropTypes.string.isRequired,
  lineItemPrice: PropTypes.string.isRequired,
  lineItemQuantity: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  locked: PropTypes.bool.isRequired,
  lineOrderedQuantity: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  fieldsLine: PropTypes.object.isRequired,
  integrationState: PropTypes.object.isRequired,
  metrcIsImported: PropTypes.bool,
  onlyEntireQuantityCanBeTransferred: PropTypes.bool,
  isReassignMode: PropTypes.bool,
  changeUnitCostAdjustAll: PropTypes.func.isRequired,
  changeLineUnitPriceAndTotal: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  hasCostEditPermission: PropTypes.bool.isRequired,
  editableLinePrice: PropTypes.bool.isRequired,
  isFeatureToggleEditInventoryItemCost: PropTypes.bool.isRequired
};

export default UnitPackage;
