import round from 'lodash.round';

const generateInitialValues = (tax, categories) => {
  // init categories
  let initialCategories = [];
  if (Array.isArray(tax.item_category_ids) && Array.isArray(categories)) {
    initialCategories = categories.filter(cat => tax.item_category_ids.includes(cat.id));
  }
  // init sub-categories
  const categorySubcategories = [];
  if (Array.isArray(tax.item_category_ids) && Array.isArray(categories)) {
    tax.item_category_ids.forEach(category_id => {
      const currentCategory = categories.find(cat => cat.id === category_id);
      const apply_to_all_subcategories = tax.whole_category_ids.includes(category_id);
      const allSubcategories = (currentCategory && currentCategory.subcategories) || [];
      const subcategories = allSubcategories.filter(subcat => tax.item_subcategory_ids.includes(subcat.id) || apply_to_all_subcategories);
      categorySubcategories.push({category_id, subcategories, apply_to_all_subcategories});
    });
  }
  return {
    taxRates: [
      Object.assign({},
        {
          ...tax,
          amount: tax && tax.amount && round(parseFloat(tax.amount) * 100, 3).toFixed(3),
          categories: initialCategories,
          categorySubcategories,
          is_active:tax.active,
          fulfillment_methods: tax.fulfillment_methods || [],
          zipcodes: tax.zipcodes ? tax.zipcodes.join(',') : ''
        }
      )
    ]
  };
};

export default generateInitialValues;