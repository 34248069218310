import React from 'react';
import {I18n} from 'react-redux-i18n';
import {Row} from 'react-bootstrap';
import PropTypes from 'prop-types';

const ResetPasswordHeader = ({isCreateMode = false}) => {
  return (
    <Row>
      <h1 className='align-center'>
        {isCreateMode
          ? I18n.t('user.password.createHeader')
          : I18n.t('user.password.resetHeader')
        }
      </h1>
    </Row>
  );
};

ResetPasswordHeader.propTypes = {
  isCreateMode: PropTypes.bool,
};

export default ResetPasswordHeader;
