import {createSelector, createStructuredSelector} from 'reselect';
import {getActiveSalesRegisters} from '../registersSelectors';
import {getCurrentRegister} from '../usersSelectors';

const getInitialValues = createSelector(
  [getCurrentRegister],
  (registerId) => {
    return {
      register: registerId,
      startFromRow: 2,
    };
  }
);


export const getImportOrdersPageProps = createStructuredSelector({
  registers: getActiveSalesRegisters,
  initialValues: getInitialValues,
});

export const getImportOrdersPagePayload = (form) => {
  return {
    registerId: form.register,
    startFromRow: form.startFromRow,
    fileId: form.fileId,
    fileUrl: form.fileUrl,
    fileMimeType: form.fileMimeType,
    fileName: form.fileName,
  };
};
