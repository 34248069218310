/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {bindActionCreators} from 'redux';
import {push} from 'react-router-redux';
import find from 'lodash.find';
import {Button} from 'react-bootstrap';


import SingleActionColumn from '../../common/grid/columns/SingleActionColumn';

import ModalWrapper from '../../common/ModalWrapper';

import * as p from '../../../constants/permissions';
import * as statuses from '../../../constants/orderStatuses';
import * as itemNames from '../../../constants/itemNames';
import * as dataNames from '../../../constants/dataNames';
import * as messageTypes from '../../../constants/messageTypes';
import {addMessage} from '../../../actions/systemActions';
import {getItem, postItem, getUnpaginatedData, putItem, getFile} from '../../../actions/apiActions';
import {setItem, unsetItem} from '../../../actions/itemActions';
import {validateCurrentOnHandWeight, validateInventoryReceiptExists} from '../../../actions/complianceSettingsActions';
import {clearSelectedDataAction} from '../../../actions/selectedDataActions';
import {
  getIntegrationState,
  isIncomingTransferMappingRequired
} from '../../../selectors/integration/integrationSelectors';
import {getSelectedPurchaseOrdersIds, getReceiptListingData} from '../../../selectors/orderSelectors';
import {getPurchaseOrderListingTabs} from '../../../selectors/purchaseOrdersSelectors';
import {handleComplexSelectRow} from '../../../actions/helpers/selectedDataHelper';
import PageTitle from '../../common/PageTitle';
import TablePageWrapper from '../../common/grid/TablePageWrapper';
import {isAllowInitialInventory} from '../../../selectors/fillPurchaseOrderSelectors';
import {facilityHasModule, isLeafPaConfigPackClosedLoopFacility, isLeafUtahConfigPackClosedLoopFacility} from '../../../selectors/facilitiesSelectors';
import {isReceivablePO} from '../../../actions/purchaseOrderActions';
import {isMetrcIntegrator, isMetrcTransfersEnabled} from '../../../selectors/integration/metrcSelectors';
import InProgressOverlay from '../../common/InProgressOverlay';
import {isMappingRequiredForSupply} from '../../../selectors/supplySelectors';
import InternationalCurrencyStatic from '../../common/form/InternationalCurrencyStatic';
import InternationalDateStatic from '../../common/form/InternationalDateStatic';

export class PurchaseOrderListing extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.handleSelect = this.handleSelect.bind(this);
    this.receiveTransfer = this.receiveTransfer.bind(this);
    this.retrieveMetrcTransfers = this.retrieveMetrcTransfers.bind(this);
    this.editOrder = this.editOrder.bind(this);
    this.activateOrder = this.activateOrder.bind(this);
    this.switchTab = this.switchTab.bind(this);
    this.getTabs = this.getTabs.bind(this);
    this.onHideAcknowledgeDecline = this.onHideAcknowledgeDecline.bind(this);
    this.onClickShowAcknowledgeDecline = this.onClickShowAcknowledgeDecline.bind(this);
    this.onConfirmAcknowledgeDecline = this.onConfirmAcknowledgeDecline.bind(this);
    this.printOrder = this.printOrder.bind(this);
    this.hideLoader = this.hideLoader.bind(this);

    this.state = {
      activeTab: statuses.activePurchase,
      showAcknowledgeDeclineModal: false,
      declinedPurchaseOrder: null
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {status, medicatedStatus} = this.props.params;
    const eventKey = this.getEventKey(status, medicatedStatus);
    const prevActiveTab = this.state.activeTab;

    if (eventKey !== prevActiveTab) {
      this.switchTab(eventKey);
    }
  }

  componentWillMount() {
    this.props.actions.setItem('purchase-orders', itemNames.currentPage);

    this.props.actions.getItem('/api/compliance_settings', itemNames.complianceSettings);
    this.props.actions.getItem('/api/compliance_settings/on_hand_weight/facility', itemNames.onHandWeightFacility);
    this.props.actions.getItem('/api/compliance_settings/on_hand_weight/organization', itemNames.onHandWeightOrganization);
    this.props.actions.getItem('/api/supply/settings', itemNames.supplySettings);
    this.props.actions.getItem('/api/integration-settings', itemNames.integrationSettings, {failed: 'stateIntegrators.settings.get.failed'});
    this.props.actions.getUnpaginatedData('/api/receipts', dataNames.inventoryReceipts);

    const {status, medicatedStatus} = this.props.params;
    const eventKey = this.getEventKey(status, medicatedStatus);
    this.switchTab(eventKey);
  }

  componentWillUnmount() {
    this.props.actions.unsetItem(itemNames.currentPage);
    this.props.actions.unsetItem(itemNames.currentParams);
    this.props.actions.unsetItem(itemNames.currentRequest);
  }

  getEventKey(status, medicatedStatus) {
    let eventKey = statuses.activePurchase;
    if (status === 'inactive') {
      eventKey = statuses.inactivePurchase;
    }
    else if (medicatedStatus === 'medicated') {
      eventKey = statuses.medicatedPurchase;
    }
    else if (medicatedStatus === 'non-medicated') {
      eventKey = statuses.nonMedicatedPurchase;
    }
    return eventKey;
  }

  switchTab(activeTab) {
    const tabs = this.getTabs();
    const tab = find(tabs, {eventKey: activeTab}) || tabs[0];
    this.props.actions.clearSelectedDataAction(dataNames.purchaseOrders);
    this.props.actions.push(tab.route);
    this.setState({activeTab: tab.eventKey});

    let url = '/api/purchase_orders/active';
    const messages = {failed: 'purchaseOrders.getListing.failed'};
    const params = {status: 'active'};
    if (tab.eventKey === statuses.inactivePurchase) {
      url = '/api/purchase_orders/inactive';
      params['status'] = 'inactive';
    }
    else if (tab.eventKey === statuses.medicatedPurchase) {
      params.contains_medicated = 1;
    }
    else if (tab.eventKey === statuses.nonMedicatedPurchase) {
      params.contains_medicated = 0;
    }
    this.props.actions.getUnpaginatedData(url, dataNames.purchaseOrders, messages, params);
    this.props.actions.setItem(params, itemNames.currentParams);
  }

  editOrder(event, row) {
    this.props.actions.push(`/purchase-orders/modify/${row.id}`);
  }

  receiveTransfer() {
    const {purchaseOrders, selectedPurchaseOrders, isInitialMode, integrationState, isLeafPaConfigPackClosedLoopFacility, isLeafUtahConfigPackClosedLoopFacility} = this.props;
    const purchaseOrder = purchaseOrders.find(el => el.id === selectedPurchaseOrders[selectedPurchaseOrders.length - 1]);
    const {isBiotrack} = integrationState;

    // Determine conditions that require supply chain mapping
    let requireSupplyChainMapping = purchaseOrder.contains_medicated && this.props.isMappingRequiredByPlatform;
    if (isLeafPaConfigPackClosedLoopFacility || isLeafUtahConfigPackClosedLoopFacility) {
      requireSupplyChainMapping = requireSupplyChainMapping && !(purchaseOrder.is_initial || (purchaseOrder.contains_only_cbd_flower_or_hemp_waste && !purchaseOrder.connect_facility_code));
    } else if (isBiotrack) {
      requireSupplyChainMapping = purchaseOrder.is_initial ? 0 : requireSupplyChainMapping;
    }

    if (requireSupplyChainMapping) {
      this.props.actions.addMessage(messageTypes.error, 'purchaseOrders.onlySupplyChainMessage');
      return;
    }
    this.props.actions.isReceivablePO(purchaseOrder.id)
      .then(() => {
        if (this.props.params.medicatedStatus === 'medicated' && this.props.forceMedicatedMapping && !purchaseOrder.is_initial && !purchaseOrder.contains_only_cbd_flower_or_hemp_waste) {
          this.props.actions.addMessage(messageTypes.error, 'purchaseOrders.leafIRMedicatedMessage');
        } else if(purchaseOrder.is_initial && !isInitialMode) {
          this.props.actions.addMessage(messageTypes.error, 'purchaseOrders.leafPaInitialInventoryCreationWarning');
        } else{
          this.props.actions.validateCurrentOnHandWeight()
          && this.props.actions.validateInventoryReceiptExists()
          && this.props.actions.push(`/inventory-receipts/create?po_num=${purchaseOrder.id}`);
        }
      })
      .catch(() => {
        this.props.actions.push(`/purchase-orders/modify/${purchaseOrder.id}`);
        this.props.actions.addMessage(messageTypes.error, 'purchaseOrders.table.hasInactiveItemMasters');
      });
  }

  retrieveMetrcTransfers() {
    this.props.actions.postItem('/api/metrc/transfers/retrieve', {type: 'incoming'})
      .then(() => this.props.actions.addMessage(messageTypes.success, 'cultivation.purchaseOrders.actions.metrcRetrieveSuccess'));
  }

  activateOrder(event, row) {
    event.preventDefault();
    event.stopPropagation();
    this.props.actions.updatePurchaseOrderStatus(row).then((order) => {
      // const message = `${order.title} ${I18n.t('cultivation.purchaseOrders.movedToActive')}`;
      // this.props.actions.setItemToActiveSuccess(message);
    });
  }

  getTabs() {
    const {getTabs} = this.props;
    return getTabs(this.receiveTransfer, this.printOrder, this.retrieveMetrcTransfers);
  }

  handleSelect(isSelected, rows) {
    const activeData = (this.state.activeTab.indexOf('Purchase') !== -1) ?
      dataNames.purchaseOrders :
      dataNames.inventoryReceipts;
    this.props.actions.handleComplexSelectRow(rows, activeData, isSelected);
  }

  printOrder() {
    const {purchaseOrders, selectedPurchaseOrders} = this.props;
    const purchaseOrder = purchaseOrders.find(el => el.id === selectedPurchaseOrders[selectedPurchaseOrders.length - 1]);
    if (purchaseOrder && purchaseOrder.id) {
      this.setState({showLoader: true, message: I18n.t('purchaseOrders.form.generatingFileForPrinting')});
      this.props.actions.getFile(
        `/api/purchase_orders/${purchaseOrder.id}/print`,
        `PurchaseOrder.pdf`,
        {failed: 'purchaseOrders.form.printingFailed'},
        {}
      ).then(this.hideLoader).catch(this.hideLoader);
    }
  }

  hideLoader(){
    this.setState({showLoader: false, message: ''});
  }

  //Show the modal to acknowledge a Connected PO was declined
  onClickShowAcknowledgeDecline(row) {
    this.setState({declinedPurchaseOrder: row});
    this.setState({showAcknowledgeDeclineModal: true});
  }

  //User cancelled or closed the confirmation modal
  onHideAcknowledgeDecline() {
    this.setState({showAcknowledgeDeclineModal: false});
  }

  //User confirmed they wish to acknowledge a declined PO
  onConfirmAcknowledgeDecline() {

    //Make a call to the backend to  mark the PO as acknowledged
    const {declinedPurchaseOrder} = this.state;

    if (declinedPurchaseOrder) {
      this.props.actions.postItem(`/api/partner_connects/purchase_orders/${declinedPurchaseOrder.id}/respond`,
        {connect_status: 'acknowledged'},
        null,
        {failed: 'purchaseOrder.modify.failed', success: 'purchaseOrder.modify.success'},
        null,
        this.switchTab(this.state.activeTab)
      );
    }

    this.setState({showAcknowledgeDeclineModal: false});
  }

  render() {
    const {selectedPurchaseOrders, purchaseOrders, facilityHasConnects, isMetrc, isMetrcTransfersEnabled} = this.props;
    const {activeTab} = this.state;
    const columns = [
      {
        name: 'cultivation.purchaseOrders.table.title',
        dataId: 'title',
        hidden: false,
        dataSort: true,
        width: '200px',
      },
      {
        name: 'purchaseOrders.table.paymentStatus',
        dataId: 'payment_status',
        hidden: false,
        dataSort: true,
        // width: '140px',
        formatter: status => I18n.t(`purchaseOrders.statuses.${status}`),
      },
      {
        name: 'purchaseOrders.table.received',
        dataId: 'received',
        hidden: false,
        dataSort: true,
        // width: '120px',
        formatter: received => I18n.t(`cultivation.complianceSettings.${received ? 'yes' : 'no'}`),
      },
      {
        name: 'cultivation.purchaseOrders.table.dateExpected',
        dataId: 'date_expected',
        formatter: cell => <InternationalDateStatic useSystemDate={false}>{cell}</InternationalDateStatic>,
        hidden: false,
        dataSort: true,
        // width: '150px'
      },
      {
        name: 'cultivation.purchaseOrders.table.orderedBy',
        dataId: 'ordered_by_user_name',
        hidden: false,
        dataSort: true,
        // width: '160px'
      },
      {
        name: 'cultivation.purchaseOrders.table.numberOfItems',
        dataId: 'item_count',
        hidden: false,
        dataSort: true,
        // width: '100px'
      },
      {
        name: 'cultivation.purchaseOrders.table.orderTotal',
        dataId: 'order_total',
        /* eslint-disable react/no-multi-comp */
        csvFormatter: (cell) => <InternationalCurrencyStatic>{cell}</InternationalCurrencyStatic>,
        /* eslint-disable react/no-multi-comp */
        formatter: (cell, row) => <InternationalCurrencyStatic>{cell}</InternationalCurrencyStatic>,
        hidden: false,
        dataSort: true,
        // width: '100px'
      },
      {
        name: 'cultivation.purchaseOrders.table.purchaseOrder',
        dataId: 'po_number',
        hidden: false,
        dataSort: true,
        // width: '160px'
      },
      {
        name: 'cultivation.purchaseOrders.table.partnerInvoice',
        dataId: 'vendor_invoice_number',
        hidden: false,
        dataSort: true,
        // width: '160px'
      }];

    if (facilityHasConnects) {
      columns.push({
        name: 'purchaseOrders.table.connectStatus',
        dataId: 'connect_status',
        hidden: false,
        dataSort: true,
        columnClassName: (fieldValue) => {
          const classByStatus = {accepted: 'text-success', declined: 'text-danger', acknowledged: 'text-danger'};
          return (classByStatus[fieldValue]) || 'text-secondary';
        },
        /* eslint-disable react/no-multi-comp */
        formatter: (connect_status, row) => {
          //If declined show an Acknowledge Button
          if (connect_status === 'declined' && row.internal_sales_order_linked && row.connect_outgoing) {
            return (<Button variant='error' bsSize='xsmall' onClick={event => {
              event.stopPropagation();
              this.onClickShowAcknowledgeDecline(row);
            }}>
              {I18n.t('purchaseOrders.connect_actions.declined')}
            </Button>);
          }
          //Otherwise just the status
          return (connect_status) ? I18n.t(`cultivation.purchaseOrders.connect_statuses.${connect_status}`) : '';
        }
      });
    }
    if (isMetrc && isMetrcTransfersEnabled) {
      columns.push({
        name: 'cultivation.salesOrders.table.metrcIntegrated',
        dataId: 'is_imported',
        formatter: cell => cell ? I18n.t('general.yes') : I18n.t('general.no'),
        hidden: false,
        dataSort: true,
        width: '100px'
      });
    }
    columns.push({
      hidden: false,
      dataSort: false,
      permissions: [p.manage_purchase_orders],
      csvFormatter: (cell) => I18n.t('cultivation.purchaseOrders.actions.edit'),
      formatter: SingleActionColumn({
        path: (row) => `/purchase-orders/modify/${row.id}`,
        label: 'cultivation.purchaseOrders.actions.edit',
        action: this.editOrder
      }),
      columnClassName: 'actions-column'
    });


    // NOTE: PurchaseOrders was built so multiple POs could be combined on one inventory receipt.  The back end, however,
    // does not currently support this model.  At least, not in any clean way.  The pulling of the most recent selectedPurchaseOrders
    // result below leaves the base intact while preventing users from running into errors.  It may even work... but there is
    // more testing to be done which doesn't seem wise just this moment.

    return (
      <div>
        <PageTitle primaryText={I18n.t('purchaseOrders.header')}/>
        <InProgressOverlay
          isActive={this.state.showLoader}
          message={this.state.message}
          onDismiss={false}
          showOk={false}
          showLoader={this.state.showLoader}
          translate={false} />
        <TablePageWrapper
          ref={this.ref}
          settingKey='purchase-orders'
          columns={columns}
          data={Array.isArray(purchaseOrders) ? purchaseOrders : []}
          activeTab={activeTab}
          tabs={this.getTabs()}
          switchTab={this.switchTab}
          selectedRows={selectedPurchaseOrders.length ? [selectedPurchaseOrders[selectedPurchaseOrders.length - 1]] : []}
          handleSelect={this.handleSelect}
          hideScanSearch = {true}
          hideExport={true}
          bstProps={{
            selectRow: {
              mode: 'radio'
            }
          }}
        />
        <ModalWrapper
          Component={false}
          title='purchaseOrders.connectActions.acknowledgeDecline.title'
          headerClass='bg-info-dark'
          onHide={this.onHideAcknowledgeDecline}
          showModal={this.state.showAcknowledgeDeclineModal}
          okayButton={{show: true, onClick: this.onConfirmAcknowledgeDecline, text: 'purchaseOrders.connectActions.acknowledgeDecline.okayText'}}
          cancelButton={{show: true, onClick: this.onHideAcknowledgeDecline, text: 'purchaseOrders.connectActions.acknowledgeDecline.cancelText'}}
          dialogClassName='modal-sm'
          version={2}
        >
          <div>{I18n.t('purchaseOrders.connectActions.acknowledgeDecline.description', {})}</div>
        </ModalWrapper>
      </div>
    );
  }
}

PurchaseOrderListing.propTypes = {
  actions: PropTypes.shape({
    push: PropTypes.func.isRequired,
    handleComplexSelectRow: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    updatePurchaseOrderStatus: PropTypes.func,
    // setItemToActiveSuccess: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    validateCurrentOnHandWeight: PropTypes.func.isRequired,
    validateInventoryReceiptExists: PropTypes.func.isRequired,
    clearSelectedDataAction: PropTypes.func.isRequired,
    isReceivablePO: PropTypes.func.isRequired
  }).isRequired,
  getTabs: PropTypes.func.isRequired,
  selectedPurchaseOrders: PropTypes.array.isRequired,
  purchaseOrders: PropTypes.array.isRequired,
  inventoryReceipts: PropTypes.array.isRequired,
  timezone: PropTypes.string.isRequired,
  toggleCreateOrder: PropTypes.func,
  params: PropTypes.shape({
    status: PropTypes.oneOf(['', 'active', 'inactive']),
    medicatedStatus: PropTypes.oneOf(['', 'medicated', 'non-medicated'])
  }).isRequired,
  forceMedicatedMapping: PropTypes.bool,
  isMappingRequiredByPlatform: PropTypes.bool,
  facilityHasConnects: PropTypes.bool,
  isMetrc: PropTypes.bool,
  isMetrcTransfersEnabled: PropTypes.bool,
  currencySymbol: PropTypes.string.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    selectedPurchaseOrders: getSelectedPurchaseOrdersIds(state),
    purchaseOrders: state.purchaseOrders,
    timezone: state.timezone,
    inventoryReceipts: getReceiptListingData(state),
    getTabs: (receiveTransfer, printOrder, retrieveMetrcTransfers) => getPurchaseOrderListingTabs(state, {actions: {receiveTransfer, printOrder, retrieveMetrcTransfers}}),
    forceMedicatedMapping: isIncomingTransferMappingRequired(state),
    isMappingRequiredByPlatform: isMappingRequiredForSupply(state),
    isInitialMode: isAllowInitialInventory(state),
    facilityHasConnects: facilityHasModule(state, {moduleKey: 'CONNECTS'}),
    isMetrc: isMetrcIntegrator(state),
    isMetrcTransfersEnabled: isMetrcTransfersEnabled(state),
    integrationState : getIntegrationState(state),
    isLeafPaConfigPackClosedLoopFacility: isLeafPaConfigPackClosedLoopFacility(state),
    isLeafUtahConfigPackClosedLoopFacility: isLeafUtahConfigPackClosedLoopFacility(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = { handleComplexSelectRow, push, getItem, postItem, putItem, getFile, getUnpaginatedData,
    validateCurrentOnHandWeight, validateInventoryReceiptExists, clearSelectedDataAction, addMessage, isReceivablePO, setItem, unsetItem };

  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderListing);
