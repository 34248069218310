import React from 'react';
import PropTypes from 'prop-types';
import {Button, ListGroupItem} from 'react-bootstrap';
import {FaTimes} from 'react-icons/fa';
import {I18n} from 'react-redux-i18n';
import InternationalCurrencyStatic from '../common/form/InternationalCurrencyStatic';

const MmapPayment = ({mmapPayment, removeMmapPayment}) => {

  return (
    <ListGroupItem className='coupon clearfix'>
      <div className='float-left'>
        Type: {I18n.t('cart.mmapPayment.title')}
      </div>
      <div className='float-right'>
        <Button size='sm' onClick={() => removeMmapPayment(mmapPayment.id)}>
          <FaTimes/>
        </Button>
      </div>
      <div className='float-right' style={{marginRight: '8px'}}>
        <InternationalCurrencyStatic>{mmapPayment.amount}</InternationalCurrencyStatic>
      </div>
    </ListGroupItem>
  );
};

MmapPayment.propTypes = {
  removeMmapPayment: PropTypes.func.isRequired,
  mmapPayment: PropTypes.object.isRequired,
};

export default MmapPayment;
