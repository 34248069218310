import { createSelector } from 'reselect';
import get from 'lodash.get';
import {pick} from 'lodash';
import omit from 'lodash.omit';
import filter from 'lodash.filter';
import round from 'lodash.round';
import groupBy from 'lodash.groupby';
import moment from 'moment';
import {
  betweenDates,
  convertDbDateTimeToClientTzMoment,
  convertDbTimeToClientTzMoment,
  convertFormInputDateToDbDate,
  convertFormInputDateToDbTime,
  formatDBDate,
} from '../util/dateHelpers';

import * as dataNames from '../constants/dataNames';
import * as itemNames from '../constants/itemNames';

import {getDriverByLicenseNumber} from './driversSelectors';
import {isActiveFacilityGrow, isLeafPaConfigPackClosedLoopFacility} from './facilitiesSelectors';
import {getVehicleByLicenseNumber} from './vehiclesSelectors';
import {getIntegrationState} from './integration/integrationSelectors';
import {isMedicated, isWaste} from './itemMastersSelectors';
import {getLeafSettings, isLeafIntegrator} from './integration/leafSelectors';
import {isMetrcIntegrator} from './integration/metrcSelectors';
import {inventory_receipt_document} from '../constants/fileTypes';
import {getBiotrackSettings} from './integration/biotrackSelectors';
import {convertFromBase, convert} from '../util/uomHelpers';
import * as UOMS from '../constants/uoms';
import {getPhoneShouldIncludeCountryCode} from './InternationalOperationsSelectors';
import {buildCompleteInternationalPhoneNumber} from '../util/formatHelper';
import {getInventoryComplianceSettings} from './complianceSettingsSelectors';

export const getPONumber = state => state[itemNames.purchaseOrder].po_number;
export const getPurchaseOrder = state => state[itemNames.purchaseOrder];
export const getInventoryReceipt = state => state[itemNames.inventoryReceipt];
export const getItemMasters = state => state[dataNames.itemMasters];
export const getChildItemMasters = state => state[dataNames.childItemMasters];
export const getVendorChildItemMasters = state => state[dataNames.vendorChildItemMasters];
export const getPartnerFacilities = state => state[dataNames.partnerFacilities];
export const getPrePackWeights = state => state[dataNames.prepackWeights];
export const getTranactions = state => state[dataNames.transactions];
export const getReassignMode = (state) =>  state[itemNames.reassignPackages];

const getDrivers = (state) => state[dataNames.drivers];
const getVehicles = (state) => state[dataNames.vehicles];
const getFormLines = (state) => state.lines;
const getFormPartnerDiscount = (state) => state.partner_discount;
const getFormExciseTax = (state) => state.excise_tax_due;
const getFormTransferFee = (state) => state.transfer_fee;
const getCurrentTimezone = (state) => state.timezone;
const getSupplyChainMapping = (state) => state[itemNames.supplyChainMapping];
const getPricingGroups = (state) => state.pricingGroups;
const getPricingWeights = (state) => state.pricingWeights;
export const isEditMode = (_, props) => get(props, 'route.path', '').indexOf('create') === -1;
const getDistributionSettings = (state) => state[itemNames.distributionSettings];
export const getSupplySettings = (state) => state[itemNames.supplySettings];

export const getPurchaseOrderItemIds = createSelector(
  [getPurchaseOrder],
  (purchaseOrder) =>
    purchaseOrder.lines
      ? purchaseOrder.lines.reduce(
          (acc, line) =>
            acc.concat(line.subitems.reduce((ids, item) => ids.concat([item.item_master_id]), [line.item_master_id])),
          []
        )
      : []
);

export const getItemMasterIds = createSelector(
  [getItemMasters],
  (itemMasters) =>
    itemMasters.reduce(
      (acc, im) =>
        im &&
        im.inventory_attributes &&
        im.inventory_attributes.is_prepack &&
        !im.inventory_attributes.item_master_parent_id
          ? acc.concat(im.id)
          : acc,
      []
    )
);

export const getPartnerFacility = createSelector(
  [getPartnerFacilities, getPurchaseOrder],
  (partnerFacilities, purchaseOrder) =>
    partnerFacilities.find((facility) => facility.id === purchaseOrder.vendor_facility_id)
);

export const getPurchaseOrderForInventoryReceipt = createSelector(
  [getPurchaseOrder, getPartnerFacility, getChildItemMasters, getPrePackWeights],
  (purchaseOrder, partnerFacility, childItemMasters, prepackWeights) => ({
    ...purchaseOrder,
    partnerFacility,
    lines: (purchaseOrder.lines || []).map((line) => {
      const lineTotals = line.subitems.reduce(
        (acc, subItem) => {
          const itemMaster = childItemMasters.find(master => master.item_master_id === subItem.item_master_id) || {};
          const prepackWeight = prepackWeights.find(weight => weight.prepack_weight_id === itemMaster.prepack_weight_id);
          const baseWeight = get(prepackWeight, 'weight_base', 0);
          const uom = get(prepackWeight, 'uom', UOMS.GR);
          const mult = convertFromBase(baseWeight, uom);
          const lineTotal = parseFloat(subItem.unit_price) * subItem.qty;
          acc.totalWeight += subItem.qty * mult;
          acc.totalCost += lineTotal;
          acc.subitems.push({
            name: prepackWeight && prepackWeight.name,
            total: lineTotal.toFixed(2),
            qty: subItem.qty,
            unitCost: parseFloat(subItem.unit_price).toFixed(2),
            weight: (subItem.qty * mult).toFixed(2)
          });
          return acc;
        },
        { totalWeight: 0, totalCost: 0, subitems: [] }
      );
      return {
        ...line,
        lineTotals
      };
    })
  })
);

function getLineTypeAndUom(line, itemMasters) {
  const itemMaster =
    itemMasters && itemMasters.find && itemMasters.find((itemMaster) => itemMaster.id === line.item_master_id);
  let line_type = 'bulk';
  let uom = 'EA';
  if (itemMaster) {
    if (
      itemMaster.inventory_attributes &&
      itemMaster.inventory_attributes.is_prepack &&
      !itemMaster.item_master_parent_id
    ) {
      line_type = 'prepack';
      uom = get(line, 'uom', UOMS.GR);
    } else if(itemMaster.uom_type === 'weight') {
      line_type = 'bulk';
      uom = line.uom;
    } else if (itemMaster.uom_type === 'volume') {
      line_type = 'bulk';
      uom = line.uom;
    } else if (itemMaster.uom_type === 'discrete') {
      line_type = 'unit';
      uom = 'EA';
    }
  }

  return { line_type, uom, itemMaster };
}

export const getInventoryReceiptLines = createSelector(
  [getPurchaseOrder, getInventoryReceipt, getItemMasters, getChildItemMasters, getCurrentTimezone, isEditMode],
  (purchaseOrder, inventoryReceipt, itemMasters, childItemMasters, timezone, editMode) => {
    const lines = editMode ? inventoryReceipt.lines : purchaseOrder.lines;
    if (!lines) {
      return [];
    }
    return lines.map((line, index) => {
      const { line_type, uom, itemMaster } = getLineTypeAndUom(line, itemMasters);
      let inventory = [];
      if (line_type === 'prepack' && line.inventory && line.inventory.length) {
        const po_source_line = purchaseOrder.lines.filter(lines => lines.id == line.source_line_id);
        if (line.po_item_master_id === line.item_master_id) {
          line.po_subitems = po_source_line[0].subitems;
        }
        line.po_item_master_id = po_source_line[0].item_master_id;

        const lotInventory = groupBy(line.inventory, 'lot_number');
        Object.keys(lotInventory).forEach((lotNumber) => {
          let formInventoryRow;
          lotInventory[lotNumber].forEach((inventoryRow, index) => {
            const packageCode = inventoryRow.package_code !== null ? inventoryRow.package_code : '';
            if (index === 0) {
              formInventoryRow = {
                id: inventoryRow.id,
                date_item_created: inventoryRow.date_item_created ? moment(inventoryRow.date_item_created) : '', // DOES NOT NEED TIMEZONE
                date_item_expired: inventoryRow.date_item_expired ? moment(inventoryRow.date_item_expired) : '', // DOES NOT NEED TIMEZONE
                date_item_use_by: inventoryRow.date_item_use_by ? moment(inventoryRow.date_item_use_by) : '', // DOES NOT NEED TIMEZONE
                packaged_at: inventoryRow.packaged_at ? moment(inventoryRow.packaged_at) : null, // DOES NOT NEED TIMEZONE
                lot_number: inventoryRow.lot_number,
                state_integration_tracking_id: inventoryRow.state_integration_tracking_id,
                producer_id: inventoryRow.producer_id,
                prepack_inventory_rows: []
              };
            }
            formInventoryRow.prepack_inventory_rows.push(
              Object.assign({}, inventoryRow, {
                po_subitem_index: getPoSubItemIndex(line.po_subitems, inventoryRow),
                unit_cost: parseFloat(inventoryRow.unit_cost) ? parseFloat(inventoryRow.unit_cost).toFixed(2) : '0.00',
                package_code_key: `${inventoryRow.item_master_id}__${inventoryRow.lot_number}__${packageCode}`,
                date_item_created: inventoryRow.date_item_created ? moment(inventoryRow.date_item_created) : '', // DOES NOT NEED TIMEZONE
                date_item_expired: inventoryRow.date_item_expired ? moment(inventoryRow.date_item_expired) : '', // DOES NOT NEED TIMEZONE
                date_item_use_by: inventoryRow.date_item_use_by ? moment(inventoryRow.date_item_use_by) : '', // DOES NOT NEED TIMEZONE
                packaged_at: inventoryRow.packaged_at ? moment(inventoryRow.packaged_at) : null, // DOES NOT NEED TIMEZONE
                harvested_at: convertDbDateTimeToClientTzMoment(inventoryRow.packaged_at, timezone)
              })
            );
          });
          inventory.push(formInventoryRow);
        });
      } else {
        inventory =
          (line.inventory &&
            line.inventory.length &&
            line.inventory.map((item) => {
              const packageCode = item.package_code !== null ? item.package_code : '';
              const childItemMaster =
                item.item_master_id === line.item_master_id
                  ? undefined
                  : childItemMasters.find((master) => {
                    return master.id === item.item_master_id;
                  });
              return Object.assign({}, item, {
                date_item_created: item.date_item_created ? moment(item.date_item_created) : '', // DOES NOT NEED TIMEZONE
                date_item_expired: item.date_item_expired ? moment(item.date_item_expired) : '', // DOES NOT NEED TIMEZONE
                date_item_use_by: item.date_item_use_by ? moment(item.date_item_use_by) : '', // DOES NOT NEED TIMEZONE
                packaged_at: item.packaged_at ? moment(item.packaged_at) : null, // DOES NOT NEED TIMEZONE
                harvested_at: convertDbDateTimeToClientTzMoment(item.harvested_at),
                unit_cost: parseFloat(item.unit_cost) ? parseFloat(item.unit_cost).toFixed(2) : '0.00',
                package_code_key: `${item.item_master_id}__${item.lot_number}__${packageCode}`,
                name:
                  childItemMaster !== undefined
                    ? childItemMaster.name
                    : itemMaster === undefined
                    ? null
                    : itemMaster.name
              });
            })) ||
          [];
      }
      let date_item_created = '',
        lot_number = undefined,
        date_item_expired = '',
        date_item_use_by = '';
      const storage_location_id =
        line.storage_location_id ||
        (purchaseOrder.lines && purchaseOrder.lines[index] && purchaseOrder.lines[index].expected_storage_location_id);

      if (editMode && inventory && inventory.length) {
        date_item_created = inventory[0].date_item_created ? moment(inventory[0].date_item_created) : '';
        lot_number = inventory[0].lot_number;
        date_item_expired = inventory[0].date_item_expired ? moment(inventory[0].date_item_expired) : '';
        date_item_use_by = inventory[0].date_item_use_by ? moment(inventory[0].date_item_use_by) : '';
      }
      const ordered_qty = (purchaseOrder.lines && purchaseOrder.lines[index] && purchaseOrder.lines[index].qty) || 0;
      const total = getLineTotal(line, line_type);
      const editableLinePrice = (total !== parseFloat(line.line_item_price));
      return {
        id: line.id,
        date_item_created,
        lot_number,
        date_item_expired,
        date_item_use_by,
        purchase_order_id: editMode ? line.source_id : line.purchase_order_id,
        item_master_id: line.item_master_id,
        transfer_uom: line.uom_display || uom,
        po_unit_price:
          purchaseOrder.lines && purchaseOrder.lines[index]
            ? parseFloat(purchaseOrder.lines[index].unit_price).toFixed(2)
            : '0.00',
        po_subitems: get(line, 'po_subitems', undefined),
        po_item_master_id: get(line, 'po_item_master_id', undefined),
        prev_item_master_id: get(line, 'prev_item_master_id', undefined),
        unit_price: parseFloat(line.unit_price).toFixed(2),
        po_line_item_price:
          purchaseOrder.lines && purchaseOrder.lines[index]
            ? parseFloat(purchaseOrder.lines[index].line_item_price).toFixed(2)
            : '0.00',
        line_item_price: parseFloat(line.line_item_price).toFixed(2),
        editableLinePrice,
        //subitems: subitems,
        inventory: inventory || [
          {
            item_master_id: line.item_master_id,
            storage_location_id: line.storage_location_id,
            uom
            //subitems
          }
        ],
        itemMaster,
        ordered_qty,
        qty: (line && line.qty) || 0,
        storage_location_id,
        is_ingredient:
          itemMaster && itemMaster.inventory_attributes ? itemMaster.inventory_attributes.is_ingredient : false,
        ordered_uom:
          purchaseOrder.lines && purchaseOrder.lines[index] ? purchaseOrder.lines[index].uom_display : line.uom_display,
        name: itemMaster ? itemMaster.name : '',
        default_cost: itemMaster ? parseFloat(itemMaster.default_cost).toFixed(2) : '0.00',
        default_uom: itemMaster ? itemMaster.default_uom : 'GR',
        unit_weight: itemMaster ? itemMaster.unit_weight : '0.00',
        unit_weight_uom: itemMaster ? itemMaster.unit_weight_uom : uom,
        uom_type: get(itemMaster, 'uom_type', 'kablooey'),
        line_type,
        uom,
        is_medicated: isMedicated(itemMaster),
        is_waste: isWaste(itemMaster),
        source_line_id: line.source_line_id
      };
    });
  }
);

function getPOLineInventory(
  line,
  line_type,
  uom,
  partnerId,
  itemMaster,
  childItemMasters,
  supplyChainMapping,
  { isLeaf, isBiotrack },
  linkedTransferLines,
  isAllowInitialInventory,
  lineIndex,
  purchaseOrder
) {
  // We are coming from the supplyChainMapping page and all our lines are mapped already
  if (get(supplyChainMapping, 'platform_supply_chain_map', false)) {
    return getPlatformPOLineInventory(line, line_type, partnerId, supplyChainMapping);
  }

  const isItemMasterCategoryCbdFlower = get(itemMaster, 'category.category_code') === 'CBD_FLOWER';

  if (isLeaf && isMedicated(itemMaster) && (!isAllowInitialInventory || !purchaseOrder.is_initial) && !isItemMasterCategoryCbdFlower) {
    return getLeafPOLineInventory(line, line_type, uom, partnerId, itemMaster, childItemMasters, supplyChainMapping);
  }

  const mappedProduct =
    Array.isArray(supplyChainMapping.products) &&
    supplyChainMapping.products.reduce(
      (acc, product) => {
        if (product.item_master_id === itemMaster.id) {
          const packages = [...product.packages, ...acc.packages];
          acc = { ...product, packages };
        }
        return acc;
      },
      { packages: [] }
    );

  const getInventoryArrayByIndex = () => {
    return get(linkedTransferLines, `${lineIndex}.inventory`, [0]);
  };

  const getPrepackInventoryItems = (item_master_id, child_item_master_id) => {
    const inventory = getInventoryArrayByIndex();
    const items = inventory.filter((item) => item.item_master_id === child_item_master_id);
    return items ? items : [];
  };

  const inventoryArray = getInventoryArrayByIndex();

  if (line_type === 'prepack') {
    if (mappedProduct.item_master_id && mappedProduct.packages && mappedProduct.packages[0]) {
      return mappedProduct.packages.map((pkg) => {
        let lot_number = pkg.batch_name;
        let package_code, item_master_id, qty;
        if (isBiotrack) {
          const inventory =
            inventoryArray.find((inventory) => inventory.state_integration_tracking_id === pkg.external_id) || {};
          lot_number = inventory.lot_number;
          package_code = inventory.package_code;
          item_master_id = inventory.item_master_id;
          qty = pkg.transfer_qty;
        }
        return {
          producer_id: partnerId,
          state_integration_tracking_id: pkg.package_code,
          date_item_created: moment(),
          prepack_inventory_rows: [{
            packaged_at: moment(),
            integration_type: pkg.inventory_type,
            package_code,
            item_master_id,
            qty,
          }],
          lot_number,
        };
      });
    } else {
      return line.subitems
        .filter((subItem) => subItem.qty) // Exclude items with no quantity
        .map((prepack) => {
          const prepackInventoryRow = {
            ...pick(prepack, ['item_master_id', 'purchase_order_line_id', 'qty']),
            unit_cost: parseFloat(prepack.unit_price).toFixed(2),
            storage_location_id: line.expected_storage_location_id,
            date_item_created: moment(),
            packaged_at: moment(),
            package_code: null
          };

          // if Purchase Order was automatically created from partner Sales Order, it has inventory items:
          const inventoryItems = getPrepackInventoryItems(line.item_master_id, prepack.item_master_id);
          const prepackInventoryRows = inventoryItems.map((inventoryItem) => {
            const harvestedAt = get(inventoryItem, 'harvested_at', null);
            return Object.assign({}, prepackInventoryRow, {
              package_code: inventoryItem.package_code,
              state_integration_tracking_id: inventoryItem.state_integration_tracking_id,
              qty: inventoryItem.qty,
              plant_batch_number: get(inventoryItem, 'plant_batch_number', null),
              harvest_batch_number: get(inventoryItem, 'harvest_batch_number', null),
              harvested_at: convertDbDateTimeToClientTzMoment(harvestedAt),
              source_strain: get(inventoryItem, 'source_strain', null)
            });
          });

          return {
            producer_id: partnerId,
            date_item_created: moment(),
            lot_number: inventoryItems[0] ? inventoryItems[0].lot_number : null,
            prepack_inventory_rows: prepackInventoryRows.length > 0 ? prepackInventoryRows : [prepackInventoryRow]
          };
        });
    }
  } else {
    const receiptLine = {
      date_item_created: moment(),
      packaged_at: moment(),
      gross_weight_uom_display: uom,
      inventory_lot_id: null,
      item_master_id: line.item_master_id,
      state_integration_tracking_id: null,
      qty: line.qty,
      non_medicated: !isMedicated(itemMaster),
      lot_number: null,
      package_code: null,
      storage_location_id: line.expected_storage_location_id,
      unit_cost: line.unit_price,
      uom,
      uom_display: uom,
      producer_id: partnerId,
      name: itemMaster !== undefined ? itemMaster.name : null,
      integration_type: line.integration_type
    };

    const receiptLines = inventoryArray.map((inventory) => {
      const harvestedAt = get(inventory, 'harvested_at', null);
      return Object.assign({}, receiptLine, {
        lot_number: inventory.lot_number ? inventory.lot_number : null,
        package_code: inventory.package_code ? inventory.package_code : null,
        qty: inventory.qty ? inventory.qty : line.qty,
        gross_weight: inventory.gross_weight ? inventory.gross_weight : null,
        state_integration_tracking_id: inventory.state_integration_tracking_id
          ? inventory.state_integration_tracking_id
          : null,
        plant_batch_number: get(inventory, 'plant_batch_number', null),
        harvest_batch_number: get(inventory, 'harvest_batch_number', null),
        harvested_at: convertDbDateTimeToClientTzMoment(harvestedAt),
        source_strain: get(inventory, 'source_strain', null)
      });
    });

    if (mappedProduct.item_master_id) {
      return mappedProduct.packages.map((pkg) => {
        let lot_number = pkg.batch_name;
        let package_code;
        if (isBiotrack) {
          const inventory =
            inventoryArray.find((inventory) => inventory.state_integration_tracking_id === pkg.external_id) || {};
          lot_number = inventory.lot_number;
          package_code = inventory.package_code;
        }

        return {
          ...receiptLine,
          qty: pkg.transfer_qty,
          state_integration_tracking_id: pkg.package_code,
          integration_type: pkg.inventory_type,
          lot_number,
          package_code
        };
      });
    } else {
      return receiptLines;
    }
  }
}

/**
 * Distinguish prepacks if they came from different lots
 * line is from the current facilities purchase order
 * supplyChainMapping contains lines is from the incoming transfer
 */
export function getLeafInventoryFromPrepacks(line, supplyChainMapping, childItemMasters, partnerId, itemMaster) {
  // get prepacks that are applicable for the order, as sometimes the subitems contains ALL prepack sizes
  const linePrepacks = line.subitems.filter(subItem => subItem.qty > 0);

  const prepackInventory = [];
  linePrepacks.forEach((linePrepack) => {
    // find the associated incoming transfer line
    const incomingLine = supplyChainMapping.lines.find(line => line.item_master_id === linePrepack.item_master_id);

    // find the prepack item master from the list of all prepack item masters
    const childItemMaster = childItemMasters.find((im) => im.id === linePrepack.item_master_id);

    // separate prepacks by lot_number to prevent incoming prepacks with the same
    // item master and prepack size from being lumped together as one inventory item
    const incomingPrepacks = groupBy(incomingLine.inventory, 'lot_number');

    // generating part of the prepack_inventory_row payload
    // here so it is more obvious what data is unique
    const commonPrepackProperties = {
      ...pick(linePrepack, ['item_master_id', 'purchase_order_line_id', 'uom']),
      storage_location_id: line.expected_storage_location_id,
      unit_cost: parseFloat(linePrepack.unit_price).toFixed(2),
      name: (childItemMaster && childItemMaster.name) || (itemMaster && itemMaster.name),
      date_item_created: moment(),
      packaged_at: moment(),
    };

    for (const prepack_lot_number in incomingPrepacks) {
      prepackInventory.push({
        producer_id: partnerId,
        lot_number: prepack_lot_number,
        prepack_inventory_rows: incomingPrepacks[prepack_lot_number].map((prepack) => {
          return {
            ...commonPrepackProperties,
            package_code: prepack.package_code,
            qty: Math.round(prepack.transfer_qty / incomingLine.item_weight),
            state_integration_tracking_id: prepack.external_id,
          };
        }),
      });
    }
  });

  return prepackInventory;
}

//Supply Chain mapping for LEAF
function getLeafPOLineInventory(line, line_type, uom, partnerId, itemMaster, childItemMasters, supplyChainMapping) {
  if (line_type === 'prepack') {
    return getLeafInventoryFromPrepacks(line, supplyChainMapping, childItemMasters, partnerId, itemMaster);
  }

  const mappedLine = (supplyChainMapping.lines || []).find((line) => line.item_master_id === itemMaster.id);

  return ((mappedLine && mappedLine.inventory) || []).map((inventory) => {
    // NOTE: For consistency, prevent auto populate of package code if lot number not present (auto map off)
    const isLotNumberPresent = get(inventory, 'lot_number', false);
    return {
      ...pick(inventory, ['package_code', 'lot_number']),
      producer_id: partnerId,
      item_master_id: line.item_master_id,
      uom,
      qty: inventory.transfer_qty,
      storage_location_id: line.expected_storage_location_id,
      unit_cost: line.unit_price,
      date_item_created: moment(),
      packaged_at: moment(),
      gross_weight_uom_display: uom,
      state_integration_tracking_id: inventory.external_id,
      uom_display: uom,
      name: itemMaster !== undefined ? itemMaster.name : null,
      package_code: isLotNumberPresent ? get(inventory, 'package_code', '') : '',
    };
  });
}

function getPlatformPOLineInventory(incomingLine, line_type, partnerId, supplyChainMapping) {
  if (line_type === 'prepack') {
    return incomingLine.subitems
      .filter((subItem) => subItem.qty > 0)
      .map((subItem) => {
        const lineInventory = get(supplyChainMapping, `product_map_${incomingLine.id}_${subItem.id}`, []);
        return {
          producer_id: partnerId,
          prepack_inventory_rows: lineInventory.map((inventory) => {
            return {
              item_master_id: inventory.item_master_id,
              name: inventory.item_master_name,
              uom: inventory.uom_display,
              qty: inventory.qty_display,
              unit_cost: inventory.unit_price,
              package_code: inventory.package_code,
              gross_weight_uom_display: inventory.uom_display,
              state_integration_tracking_id: inventory.state_integration_tracking_id,
              uom_display: inventory.uom_display,
              lot_number: inventory.lot_number
            };
          })
        };
      });
  }

  const lineInventory = get(supplyChainMapping, `product_map_${incomingLine.id}`, []);

  return Array.isArray(lineInventory)
    ? lineInventory.map((inventory) => {
      return {
        producer_id: partnerId,
        item_master_id: inventory.item_master_id,
        name: inventory.item_master_name,
        uom: inventory.uom_display,
        qty: inventory.qty_display,
        unit_cost: inventory.unit_price,
        package_code: inventory.package_code,
        gross_weight_uom_display: inventory.uom_display,
        state_integration_tracking_id: inventory.state_integration_tracking_id,
        uom_display: inventory.uom_display,
        lot_number: inventory.lot_number
      };
    })
    : [];
}

export const isAllowInitialInventory = createSelector(
  [getLeafSettings, getBiotrackSettings, getIntegrationState, isActiveFacilityGrow],
  (leafSettings, biotrackSettings, integrationState, isActiveFacilityGrow) => {
    const { isPaLeaf, isBiotrack } = integrationState;
    if (isPaLeaf) {
      return (
        isActiveFacilityGrow &&
        Boolean(
          get(leafSettings, 'allow_initial_inventory', false) &&
            betweenDates('', moment.utc(get(leafSettings, 'initial_inventory_by_date')).endOf('day'))
        )
      );
    }
    if (isBiotrack) {
      return Boolean(get(biotrackSettings, 'is_fifteen_day_window'));
    }
    return false;
  }
);

export const getLinesFromPO = createSelector(
  [
    getPurchaseOrder,
    getItemMasters,
    getPartnerFacility,
    getChildItemMasters,
    getSupplyChainMapping,
    getIntegrationState,
    isAllowInitialInventory
  ],
  (
    purchaseOrder,
    itemMasters,
    partnerFacility,
    childItemMasters,
    supplyChainMapping,
    integrationState,
    allowInitialInventory
  ) => {
    if (!(purchaseOrder.lines && itemMasters && itemMasters.length && (partnerFacility || allowInitialInventory))) {
      return [];
    }

    const linkedTransferLines =
      purchaseOrder.linked_transfer_details &&
      purchaseOrder.linked_transfer_details.lines &&
      Array.isArray(purchaseOrder.linked_transfer_details.lines)
        ? purchaseOrder.linked_transfer_details.lines.map((line) => line)
        : [];

    const getLinkedOrPoPrice = (item_master_id, po_price) => {
      const line = linkedTransferLines.find((line) => line.item_master_id === item_master_id);
      return line ? parseFloat(line.line_item_price).toFixed(2) : po_price ? parseFloat(po_price).toFixed(2) : '0.00';
    };

    const getLinkedOrPoUnitPrice = (item_master_id, po_unit_price) => {
      const line = linkedTransferLines.find((line) => line.item_master_id === item_master_id);
      return (line)
        ? parseFloat(line.unit_price)
        : po_unit_price ? parseFloat(po_unit_price) : '0.00';
    };

    const getLinkedOrPoQuantity = (item_master_id, quantity) => {
      const line = linkedTransferLines.find((line) => line.item_master_id === item_master_id);
      return line ? parseFloat(line.ordered_qty) : quantity ? parseFloat(quantity) : 0;
    };

    return purchaseOrder.lines.map((line, lineIndex) => {
      const { line_type, uom, itemMaster } = getLineTypeAndUom(line, itemMasters);
      const unitPrice = getLinkedOrPoUnitPrice(line.item_master_id, line.unit_price);
      const lineItemPrice = getLinkedOrPoPrice(line.item_master_id, line.line_item_price);
      const quantity = getLinkedOrPoQuantity(line.item_master_id, line.qty);
      const total = quantity * unitPrice;
      const isIngredient = get(line, 'isIngredient', false);
      const editableLinePrice = (total !== parseFloat(lineItemPrice) && !isIngredient);
      return {
        ...pick(line, ['item_master_id', 'line_discount_percent', 'qty', 'uom_display']),
        editableLinePrice,
        line_type,
        uom,
        source_line_id: line.id,
        itemMaster,
        name: itemMaster ? itemMaster.name : '',
        default_cost: itemMaster ? parseFloat(itemMaster.default_cost).toFixed(2) : '0.00',
        unit_price: unitPrice,
        po_unit_price: unitPrice,
        default_uom: itemMaster ? itemMaster.default_uom : 'GR',
        inventory: getPOLineInventory(
          line,
          line_type,
          uom,
          partnerFacility && partnerFacility.partner_id,
          itemMaster,
          childItemMasters,
          supplyChainMapping,
          integrationState,
          linkedTransferLines,
          allowInitialInventory,
          lineIndex,
          purchaseOrder
        ),
        ordered_uom: line.uom_display,
        //ordered_qty: line.qty,
        ordered_qty: quantity,
        line_item_price: lineItemPrice,
        po_line_item_price: lineItemPrice,
        is_ingredient:
          itemMaster && itemMaster.inventory_attributes ? itemMaster.inventory_attributes.is_ingredient : false,
        is_medicated: isMedicated(itemMaster),
        is_waste: isWaste(itemMaster)
      };
    });
  }
);

/***
 * Gets driver from supply chain mapping (leaf) or from transfer linked to SO to PO
 * @type {Reselect.Selector<TInput, TOutput>}
 */
const getExternallyProvidedDriver = createSelector(
  [getSupplyChainMapping, getDrivers, getPurchaseOrder, getIntegrationState],
  (supplyChainMapping, drivers, purchaseOrder, { isBiotrack }) => {
    return supplyChainMapping && supplyChainMapping.external_id && !isBiotrack
      ? getDriverByLicenseNumber(
          { drivers },
          { licenseNumber: [supplyChainMapping.driver_state_license_number, supplyChainMapping.driver_license_number] }
        )
      : purchaseOrder.linked_transfer_details
      ? drivers.find((driver) => driver.id === purchaseOrder.linked_transfer_details.driver_id)
      : {};
  }
);

/***
 * Gets vehicle from supply chain mapping (leaf) or from transfer linked to SO to PO
 * @type {Reselect.Selector<TInput, TOutput>}
 */
const getExternallyProvidedVehicle = createSelector(
  [getSupplyChainMapping, getVehicles, getPurchaseOrder, getIntegrationState],
  (supplyChainMapping, vehicles, purchaseOrder, { isBiotrack }) => {
    return supplyChainMapping && supplyChainMapping.external_id && !isBiotrack
      ? getVehicleByLicenseNumber({ vehicles }, { licenseNumber: supplyChainMapping.vehicle_license_plate })
      : purchaseOrder.linked_transfer_details
      ? vehicles.find((vehicle) => vehicle.id === purchaseOrder.linked_transfer_details.vehicle_id)
      : {};
  }
);

export const receiptHasMedicatedLines = (lines) => lines.some((line) => line.is_medicated);

export const getReceiveInventoryReceiptInitialValues = createSelector(
  [
    getPurchaseOrder,
    getPartnerFacility,
    getLinesFromPO,
    getCurrentTimezone,
    getExternallyProvidedDriver,
    getExternallyProvidedVehicle,
    isMetrcIntegrator,
    isLeafIntegrator,
    getDistributionSettings,
    getSupplySettings,
    getSupplyChainMapping,
    isLeafPaConfigPackClosedLoopFacility,
    getInventoryComplianceSettings
  ],
  (
    purchaseOrder,
    partnerFacility,
    lines,
    timezone,
    driver,
    vehicle,
    isMetrc,
    isLeaf,
    distributionSettings,
    supplySettings,
    supplyChainMapping,
    isLeafPaConfigPackClosedLoopFacility,
    inventoryComplianceSettings
  ) => {
    const license =
      partnerFacility && partnerFacility.licenses && partnerFacility.licenses.length
        ? partnerFacility.licenses.find((license) => license.license_type === 'state')
        : null;
    const connect_facility_code = get(partnerFacility, 'connect_facility_code');
    const license_number = license && license.license_number;
    const hasMedicated = receiptHasMedicatedLines(lines);
    const lock_status = purchaseOrder.contains_only_cbd_flower_or_hemp_waste ? ((isLeafPaConfigPackClosedLoopFacility) ? false : true) : hasMedicated && isLeaf;
    const onlyEntireQuantityCanBeTransferred =
      hasMedicated && get(distributionSettings, 'distributions_transfer_requires_full_quantity.value', false);

    // If we are coming from platform supply chain mapping, fill out the Inbound transfer details from th parner's SO
    const loadedFromSupplyChainMapping = get(supplyChainMapping, 'loaded_from_supply_chain_mapping', false);

    return {
      estimated_departure_time: loadedFromSupplyChainMapping
        ? get(supplyChainMapping, 'platform_incoming_transfer_details.departure_time', '')
        : '',
      estimated_arrival_time: loadedFromSupplyChainMapping
        ? get(supplyChainMapping, 'platform_incoming_transfer_details.arrival_time', '')
        : '',
      po_number: purchaseOrder.po_number,
      date_ordered: convertDbDateTimeToClientTzMoment(purchaseOrder.date_ordered, timezone),
      date_received: loadedFromSupplyChainMapping
        ? get(supplyChainMapping, 'platform_incoming_transfer_details.date_expected', '')
        : purchaseOrder.date_received ? moment(purchaseOrder.date_received) : new Date(),
      receipt_type: 'purchase_order',
      source_type: 'purchase_order',
      source_id: purchaseOrder.id,
      contains_medicated: purchaseOrder.contains_medicated,
      contains_only_cbd_flower_or_hemp_waste: purchaseOrder.contains_only_cbd_flower_or_hemp_waste,
      // For CBD Flower or Hemp Waster transfers within MJP (i.e. connect_facility_code exists)
      // no Regulator approval is needed (i.e. already approved) and the status can be set to 'completed'
      status: purchaseOrder.contains_only_cbd_flower_or_hemp_waste && !connect_facility_code ? ((isLeafPaConfigPackClosedLoopFacility) ? 'completed' : 'open') : 'completed',
      approval_status: isLeafPaConfigPackClosedLoopFacility ? 'approved' : '',
      transfer_type: 'wholesale',
      excise_tax_due: purchaseOrder.linked_transfer_details
        ? parseFloat(purchaseOrder.linked_transfer_details.taxes || 0).toFixed(2)
        : parseFloat(purchaseOrder.taxes || 0).toFixed(2),

      partner_discount: purchaseOrder.linked_transfer_details
        ? parseFloat(purchaseOrder.linked_transfer_details.discount_percent || 0).toFixed(2)
        : parseFloat(purchaseOrder.discount_percent || 0).toFixed(2),

      transfer_fee: purchaseOrder.linked_transfer_details
        ? parseFloat(purchaseOrder.linked_transfer_details.transfer_fee || 0).toFixed(2)
        : parseFloat(purchaseOrder.transfer_fee || 0).toFixed(2),

      destination_total: parseFloat(purchaseOrder.order_total).toFixed(2),
      vendor_facility_name: partnerFacility ? partnerFacility.facility_name : undefined,
      vendor_name: partnerFacility && partnerFacility.partner ? partnerFacility.partner.name : undefined,
      license_number,
      lines,
      driver,
      vehicle,
      lock_status,
      onlyEntireQuantityCanBeTransferred,

      // Set up these initial values that will be used thoughout the form to set the state of controls
      medicatedReceiptRequiresMapping: get(supplySettings, 'supply_medicated_receipt_requires_mapping.value', false),
      loadedFromSupplyChainMapping: get(supplyChainMapping, 'loaded_from_supply_chain_mapping', false),
      files: [],
      require_on_hand_product_expire_date: inventoryComplianceSettings.require_on_hand_product_expire_date
    };
  }
);

export const getEditInventoryReceiptInitialValues = createSelector(
  [
    getInventoryReceipt,
    getPurchaseOrder,
    getDrivers,
    getVehicles,
    getPartnerFacility,
    getInventoryReceiptLines,
    getCurrentTimezone,
    isMetrcIntegrator,
    isLeafIntegrator,
    getPhoneShouldIncludeCountryCode
  ],
  (receipt, purchaseOrder, drivers, vehicles, partnerFacility, lines, timezone, isMetrc, isLeaf, includeCountryCode) => {
    const driver = drivers.find((driver) => driver.id === receipt.driver_id);
    if (driver && driver.driver_phones && driver.driver_phones[0]) {
      driver.phone_number = buildCompleteInternationalPhoneNumber(driver.driver_phones[0].number, driver.driver_phones[0].phone_country_code, includeCountryCode);
    }

    const vehicle = vehicles.find((vehicle) => vehicle.id === receipt.vehicle_id);
    const license =
      partnerFacility && partnerFacility.licenses && partnerFacility.licenses.length
        ? partnerFacility.licenses.find((license) => license.license_type === 'state')
        : null;
    const license_number = license && license.license_number;
    const hasMedicated = receiptHasMedicatedLines(lines);
    const lock_status = purchaseOrder.contains_only_cbd_flower_or_hemp_waste ? receipt.approval_status != 'approved' : hasMedicated && isLeaf;
    const status = receipt.status;
    const notReceivedProperly = (
      lines &&
      lines[0] &&
      lines[0].inventory &&
      lines[0].inventory[0] &&
      (
        lines[0].inventory[0].inventory_created === 0 || 
        (
          lines[0].inventory[0].prepack_inventory_rows &&
          lines[0].inventory[0].prepack_inventory_rows[0] &&
          lines[0].inventory[0].prepack_inventory_rows[0].inventory_created === 0
        )
      )
    );
    
    const canReReceive = notReceivedProperly && status === 'completed';

    return {
      estimated_arrival_time: convertDbTimeToClientTzMoment(receipt.estimated_arrival_time, timezone),
      estimated_departure_time: convertDbTimeToClientTzMoment(receipt.estimated_departure_time, timezone),
      po_number: purchaseOrder.po_number,
      contains_medicated: purchaseOrder.contains_medicated,
      contains_only_cbd_flower_or_hemp_waste: purchaseOrder.contains_only_cbd_flower_or_hemp_waste,
      //date_ordered: formatClientDate(purchaseOrder.date_ordered),
      date_received: receipt.date_received
        ? moment(receipt.date_received)
        : status === 'completed'
          ? new Date()
          : null,
      receipt_type: receipt.receipt_type,
      source_type: receipt.source_type,
      source_id: receipt.source_id,
      status: receipt.status,
      approval_status: receipt.approval_status,
      //transfer_type:  'wholesale',
      driver,
      vehicle,
      vehicle_id: receipt.vehicle_id,
      excise_tax_due: parseFloat(purchaseOrder.taxes || 0).toFixed(2),
      partner_discount: parseFloat(purchaseOrder.discount_percent || 0).toFixed(2),
      transfer_fee: parseFloat(purchaseOrder.transfer_fee || 0).toFixed(2),
      destination_total: parseFloat(purchaseOrder.order_total).toFixed(2),
      vendor_facility_name: partnerFacility ? partnerFacility.facility_name : undefined,
      vendor_name: partnerFacility && partnerFacility.partner ? partnerFacility.partner.name : undefined,
      license_number,
      lines,
      lock_status,
      files: (receipt.files || []).map((file) => ({
        ...file,
        type: inventory_receipt_document
      })),
      canReReceive: canReReceive
    };
  }
);

function getItemMasterChildrenByPrepackWeightsForPO(itemMasters, prepackWeights, order) {
  let purchaseOrderChildIds = [];
  if (order && order.lines) {
    purchaseOrderChildIds = order.lines.reduce((acc, line) => {
      return acc.concat(...line.subitems.map((subitem) => subitem.item_master_id));
    }, []);
  }

  return itemMasters
    .filter((itemMaster) => {
      return (
        prepackWeights.some(
          (weight) =>
            weight.prepack_weight_id === itemMaster.prepack_weight_id &&
            weight.categories.find(
              (prepackWeightCategory) => prepackWeightCategory.category_id === itemMaster.category_id
            )
        ) || purchaseOrderChildIds.indexOf(itemMaster.id) !== -1
      );
    })
    .sort((itemMaster1, itemMaster2) => {
      const prepackWeight1 = prepackWeights.find(
        (weight) => weight.prepack_weight_id === itemMaster1.prepack_weight_id
      );
      const prepackWeight2 = prepackWeights.find(
        (weight) => weight.prepack_weight_id === itemMaster2.prepack_weight_id
      );
      if (prepackWeight1 && prepackWeight2) {
        return prepackWeight1.fulfillment_units - prepackWeight2.fulfillment_units;
      }
      return 0;
    });
}

const getPricingWeightsByGroupsForPO = createSelector(
  [getPrePackWeights],
  (prepackWeights) => {
    return prepackWeights
      .filter((prepackWeight) => prepackWeight.applies_to_purchases)
      .sort((val1, val2) => val1.fulfillment_units - val2.fulfillment_units);
  }
);

function getItemMasterChildrenByPrepackWeights(itemMasters, prepackWeights, order) {
  let purchaseOrderChildIds = [];
  if (order && order.lines) {
    purchaseOrderChildIds = order.lines.reduce((acc, line) => {
      return acc.concat(...line.subitems.map((subitem) => subitem.item_master_id));
    }, []);
  }
  return (
    itemMasters
      .filter(
        (itemMaster) =>
          prepackWeights.some(
            (weight) =>
              weight.id === itemMaster.prepack_weight_id && weight.category_ids.indexOf(itemMaster.category_id) !== -1
          ) || purchaseOrderChildIds.indexOf(itemMaster.id) !== -1
      )
      //sort prepack weights by weight
      .sort((itemMaster1, itemMaster2) => {
        const prepackWeight1 = prepackWeights.find((weight) => weight.id === itemMaster1.prepack_weight_id);
        const prepackWeight2 = prepackWeights.find((weight) => weight.id === itemMaster2.prepack_weight_id);
        if (prepackWeight1 && prepackWeight2) {
          return prepackWeight1.fulfillment_units - prepackWeight2.fulfillment_units;
        }
        return 0;
      })
  );
}

const getPricingWeightsByGroups = createSelector(
  [getPrePackWeights, getPricingGroups, getPricingWeights],
  (prepackWeights, pricingGroups, allPricingWeights) => {
    if (!pricingGroups || (Array.isArray(pricingGroups) && !pricingGroups.length)) {
      return prepackWeights
        .filter((weight) => weight.active)
        .map((weight) => {
          return { ...weight, category_ids: [] };
        });
    }
    //set category id for weight
    const categoriesByWeight = {};
    //get all pricing weight ids from pricing groups
    const pricingWeightIds = pricingGroups.reduce((ids, pricingGroup) => {
      if (pricingGroup.pricing_weights) {
        ids = ids.concat(
          ...pricingGroup.pricing_weights.map((weight) => {
            const pricingWeight = allPricingWeights.find((value) => value.id === weight.pricing_weight_id);
            if (pricingWeight) {
              if (categoriesByWeight[pricingWeight.fulfillment_units]) {
                categoriesByWeight[pricingWeight.fulfillment_units].push(pricingGroup.category_id);
              } else {
                categoriesByWeight[pricingWeight.fulfillment_units] = [pricingGroup.category_id];
              }
            }
            return weight.pricing_weight_id;
          })
        );
      }
      return ids;
    }, []);
    //get only mapped prepack weight with pricing weight
    return prepackWeights
      .filter((prepackWeight) => {
        const pricingWeight = allPricingWeights.find(
          (pricingWeight) => pricingWeight.fulfillment_units === prepackWeight.fulfillment_units
        );
        return pricingWeight && pricingWeightIds.indexOf(pricingWeight.id) !== -1;
      })
      .map((prepackWeight) => {
        return { ...prepackWeight, category_ids: categoriesByWeight[prepackWeight.fulfillment_units] || [] };
      })
      .sort((val1, val2) => val1.fulfillment_units - val2.fulfillment_units);
  }
);

export const getGroupedItemMasterChildren = createSelector(
  [getChildItemMasters, getPricingWeightsByGroupsForPO, getPurchaseOrder, getPrePackWeights, getPricingGroups],
  groupItemMasterChildrenForPO
);

export const getGroupedVendorItemMasterChildren = createSelector(
  [getVendorChildItemMasters, getPricingWeightsByGroups, getPurchaseOrder, getPrePackWeights, getPricingGroups],
  groupItemMasterChildren
);

function groupItemMasterChildren(itemMasters, prepackWeights, purchaseOrder, allPrepackWeight, pricingGroups) {
  itemMasters = getItemMasterChildrenByPrepackWeights(itemMasters, prepackWeights, purchaseOrder, pricingGroups);
  return itemMasters && itemMasters.length && prepackWeights && prepackWeights.length
    ? groupBy(
        itemMasters.map((itemMaster) => {
          const itemWeight = prepackWeights.find((weight) => weight.id === itemMaster.prepack_weight_id);
          return {
            ...itemMaster,
            itemWeight,
            displayName: itemWeight ? itemWeight.name : itemMaster.name
          };
        }),
        'item_master_parent_id'
      )
    : {};
}

function groupItemMasterChildrenForPO(itemMasters, prepackWeights, purchaseOrder) {
  itemMasters = getItemMasterChildrenByPrepackWeightsForPO(itemMasters, prepackWeights, purchaseOrder);
  return itemMasters && itemMasters.length && prepackWeights && prepackWeights.length
    ? groupBy(
        itemMasters.map((itemMaster) => {
          const itemWeight = prepackWeights.find((weight) => weight.prepack_weight_id === itemMaster.prepack_weight_id);

          return {
            ...itemMaster,
            itemWeight,
            displayName: itemWeight ? itemWeight.name : itemMaster.name
          };
        }),
        'item_master_parent_id'
      )
    : {};
}

//@TODO: Make this a proper selector which buys memoization I believe
export const getPayLoad = (
  formData,
  lineTotals,
  itemMasters,
  itemMasterChildrenLookup,
  timezone,
  initialValues,
  supplyChainMapping,
  isReturn,
  purchaseOrder,
  integrationState
) => {
  const poIsReturn = get(purchaseOrder, 'is_return', false);

  const files = formData.files
    .filter((file) => file.file_id)
    .map((file) => pick(file, ['id', 'file_id', 'receipt_id', 'name']));

  const delete_file_ids = initialValues.files.filter((file) => !files.some((f) => f.id === file.id)).map((f) => f.id);
  return {
    ...omit(formData, ['facility_id', 'created_at', 'updated_at']),
    date_received: convertFormInputDateToDbDate(formData.date_received, timezone),
    date_ordered: convertFormInputDateToDbDate(formData.date_ordered, timezone),
    estimated_arrival_time: convertFormInputDateToDbTime(formData.estimated_arrival_time, timezone) || undefined,
    estimated_departure_time: convertFormInputDateToDbTime(formData.estimated_departure_time, timezone) || undefined,
    driver_id: (formData.driver && formData.driver.id) || undefined,
    vehicle_id: (formData.vehicle && formData.vehicle.id) || undefined,
    is_return: poIsReturn,
    lines: formData.lines.map((line, index) => {
      const { line_type } = getLineTypeAndUom(line, itemMasters);
      const itemMasterChildren = itemMasterChildrenLookup[line.item_master_id];
      const quantity = Array.isArray(line.inventory)
        ? line.inventory.reduce((acc, item) => {
          let quantity = parseFloat(item.qty);
          if (isNaN(quantity)) quantity = 0;
          if (line_type === 'prepack') {
            const itemMaster =
                itemMasterChildren &&
                itemMasterChildren.find &&
                itemMasterChildren.find((master) => master.id === item.item_master_id);
            quantity = itemMaster ? itemMaster.itemWeight.weight * quantity : quantity;
          }
          acc += quantity;
          return acc;
        }, 0)
        : parseFloat(line.qty);

      line.update_purchase_order_line = 1;

      const inventoryFields = [
        'id',
        'item_master_id',
        'package_code',
        'producer_id',
        'storage_location_id',
        'unit_cost',
        'gross_weight',
        'state_integration_tracking_id',
        'purpose',
        'integration_type',
        'finished',
        'plant_batch_number',
        'harvest_batch_number',
        'source_strain',
        'harvested_at'
      ];

      let producer_id = '';
      let inventory = [];

      const getHarvestedAt = (data) => {
        const harvestedAt = get(data, 'harvested_at', null);
        return !harvestedAt ? harvestedAt : formatDBDate(harvestedAt);
      };

      if (line.line_type === 'prepack') {
        line.inventory.forEach((item) => {
          producer_id = item.producer_id ? item.producer_id : producer_id;
          item.prepack_inventory_rows.forEach((subItem) => {
            if (typeof subItem.po_subitem_index !== 'undefined' && subItem.unit_cost_all_adjust) {
              line.po_subitems[subItem.po_subitem_index].unit_price = subItem.unit_cost;
            }
            const modifiedInventoryItem = {
              ...pick(item, inventoryFields.filter((item) => item !== 'id')),
              ...pick(subItem, inventoryFields),
              qty: subItem.qty,
              is_trade_sample: integrationState.isMetrc ? (item.is_trade_sample || false) : null,
              lot_number: get(item, 'lot_number') || get(subItem, 'lot_number'),
              date_item_created: item.date_item_created ? formatDBDate(item.date_item_created) : formatDBDate(moment()), // DOES NOT NEED TIMEZONE
              date_item_expired: subItem.date_item_expired ? subItem.date_item_expired : (item.date_item_expired ? item.date_item_expired : undefined), // DOES NOT NEED TIMEZONE
              date_item_use_by: item.date_item_use_by ? formatDBDate(item.date_item_use_by) : undefined, // DOES NOT NEED TIMEZONE
              harvested_at: getHarvestedAt(subItem),
              packaged_at: subItem.packaged_at
                ? convertFormInputDateToDbDate(subItem.packaged_at, timezone)
                : undefined,
              producer_id,
              uom: 'EA'
            };
            inventory.push(modifiedInventoryItem);
          });
        });
      } else {
        inventory = line.inventory.map((item) => {
          producer_id = item.producer_id ? item.producer_id : producer_id;
          let qty = 0;
          try {
            qty = convert(item.qty, item.uom, line.default_uom);
          } catch (exception) {
            qty = item.qty;
          }
          const resultingLine = {
            ...pick(item, inventoryFields),
            qty,
            uom: line.default_uom,
            lot_number: get(item, 'lot_number'),
            is_trade_sample: integrationState.isMetrc ? (item.is_trade_sample || false) : null,
            date_item_created: item.date_item_created ? formatDBDate(item.date_item_created) : formatDBDate(moment()), // DOES NOT NEED TIMEZONE
            date_item_expired: item.date_item_expired ? formatDBDate(item.date_item_expired) : undefined, // DOES NOT NEED TIMEZONE
            date_item_use_by: item.date_item_use_by ? formatDBDate(item.date_item_use_by) : undefined, // DOES NOT NEED TIMEZONE
            packaged_at: item.packaged_at ? convertFormInputDateToDbDate(item.packaged_at, timezone) : undefined,
            harvested_at: getHarvestedAt(item),
            //gross_weight: parseFloat(line.gross_weight) || undefined,
            gross_weight_uom: parseFloat(item.gross_weight) && line.default_uom ? line.default_uom : undefined,
            producer_id
          };

          // band aid fix for bug where item_master_id is being dropped from the inventory line
          if (!resultingLine.item_master_id) {
            resultingLine.item_master_id = line.item_master_id;
          }

          return resultingLine;
        });
      }

      return {
        ...line,
        inventory,
        qty: quantity,
        line_item_price: lineTotals[index]
      };
    }),
    files,
    delete_file_ids,
    integration_supply_chain_external_id: get(supplyChainMapping, 'external_id', undefined)
  };
};

export const getLineItemPrices = createSelector(
  getFormLines,
  (formLines) => {
    return formLines && formLines.length
      ? formLines.map(line => {
        if(line.editableLinePrice || get(line, 'is_ingredient', false)) return line.line_item_price;
        if (line.line_type === 'prepack') {
          const lineTotal = line.inventory.reduce((total, current) => {
            let currentTotal = 0;
            if (Array.isArray(current.prepack_inventory_rows)) {
              current.prepack_inventory_rows.forEach((inventoryRow) => {
                if (inventoryRow.qty && inventoryRow.unit_cost) {
                  currentTotal += parseFloat(inventoryRow.unit_cost) * parseInt(inventoryRow.qty);
                }
              });
            }
            return total + currentTotal;
          }, 0);
          return lineTotal ? lineTotal.toFixed(2) : '0.00';
        } else {
          const totalQty = line.inventory.reduce(
              (total, current) => (current.qty ? total + parseFloat(current.qty) : total),
              0
            );
          return line.unit_price ? (parseFloat(line.unit_price) * round(totalQty, 4)).toFixed(2) : '0.00';
        }
      })
      : [];
  }
);

export const getLineTotal = (line, lineType) => {
  // ON PO the collection is subitems, on IR the collection is inventory
  // PO is used on create, IR is used on edit
  const packagesField = line.subitems !== undefined ? 'subitems' : 'inventory';

  if (lineType === 'prepack') {
    const unitPriceField = packagesField === 'subitems' ? 'unit_price' : 'unit_cost';
    return line[packagesField].reduce((total, current) => {
      return current.qty && current[unitPriceField] && parseFloat(current[unitPriceField])
        ? total + parseFloat(current[unitPriceField]) * parseInt(current.qty)
        : total;
    }, 0);
  } else {
    const quantity = parseFloat(line.qty);
    return line.unit_price ? parseFloat(line.unit_price) * quantity : 0;
  }
};

export const getLineItemQuantities = createSelector(
  getFormLines,
  getGroupedItemMasterChildren,
  (formLines, itemMasters) => {
    return formLines && formLines.length
      ? formLines.map((line) => {
        if (line.line_type === 'prepack') {
          const children = (itemMasters && itemMasters[line.item_master_id]) || [];
          const totalQty = line.inventory.reduce((total, current) => {
            let currentTotal = 0;
            if (Array.isArray(current.prepack_inventory_rows)) {
              current.prepack_inventory_rows.forEach(inventoryRow => {
                const child = children && children.find(itemMaster => itemMaster.id === inventoryRow.item_master_id);
                const uom = get(child, 'itemWeight.uom', UOMS.GR);
                const itemWeightBase = parseInt(get(child, 'itemWeight.weight_base', 0));
                const quantity = parseInt(get(inventoryRow, 'qty', 0));
                currentTotal += convertFromBase(quantity * itemWeightBase, uom);
              });
            }
            return total + currentTotal;
          }, 0);

          return totalQty || '0.00';
        }
        else {
          return line.inventory.reduce((total, current) => current.qty ? total + parseFloat(current.qty) : total, 0);
        }
      })
      : [];
  }
);

export const getSummaryTotals = createSelector(
  getLineItemPrices,
  getFormPartnerDiscount,
  getFormExciseTax,
  getFormTransferFee,
  (prices, partnerDiscount, tax, fee) => {
    const productTotal = prices.reduce((total, price) => (total += parseFloat(price)), 0);
    const discountTotal = productTotal ? productTotal * (1 - parseFloat(partnerDiscount) / 100) : 0;
    const exciseTax = parseFloat(tax) || 0;
    const transferFee = parseFloat(fee) || 0;
    const receiptTotal = productTotal * (1 - parseFloat(partnerDiscount) / 100) + exciseTax + transferFee;

    return {
      productTotal: productTotal.toFixed(2),
      discountTotal: discountTotal.toFixed(2),
      exciseTax: exciseTax.toFixed(2),
      transferFee: transferFee.toFixed(2),
      receiptTotal: receiptTotal.toFixed(2)
    };
  }
);

export const getReassignItemMasters = createSelector(
  [getItemMasters],
  (itemMasters) => {
    return filter(itemMasters, (item) => get(item, 'inventory_attributes.is_ingredient') !== 1);
  }
);


function getPoSubItemIndex(po_subitems, inventoryRow) {
  if (po_subitems) {
    const po_subitem_index = po_subitems.findIndex(po_subs => {
      return inventoryRow.item_master_id === po_subs.item_master_id;
    });
    return po_subitem_index;
  }
  return null;
}
