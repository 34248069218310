import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import {Table} from 'react-bootstrap';
import NumericInput from '../../../common/form/NumericInput';
import ContentConcealer from '../../../common/concealers/ContentConcealer';

class ConsumerGroupsTable extends React.PureComponent {

  constructor(props) {
    super(props);

    this.renderTableRow = this.renderTableRow.bind(this);
  }

  renderField(filedName, props) {
    return (
      <Field
        name={filedName}
        component={NumericInput}
        props={props}
      />
    );
  }

  renderTableRow(item, index) {
    const row = this.props.rows[index] || {};
    const props = {
      type: 'hidden',
      readOnly: true,
    };

    return (
      <tr key={index}>
        <td className='name'>{row.group_name}</td>
        <td>
          <div className='hidden'>
            {this.renderField(`${item}.price_list_id`, props)}
            {this.renderField(`${item}.consumer_group_id`, props)}
          </div>
          {this.renderField(`${item}.percentage_of_base`, { readOnly: true })}
        </td>
      </tr>
    );
  }

  render() {
    const { fields } = this.props;

    return (
      <ContentConcealer show={Boolean(fields.length)}>
        <div className='table-wrapper'>
          <Table condensed striped bordered hover>
            <thead>
            <tr>
              <th className='size'>{I18n.t('products.form.customerGroup')}</th>
              <th>{I18n.t('products.form.percentOfBasePrice')}</th>
            </tr>
            </thead>
            <tbody>
            {fields.map(this.renderTableRow)}
            </tbody>
          </Table>
        </div>
      </ContentConcealer>
    );
  }
}


ConsumerGroupsTable.propTypes = {
  rows: PropTypes.array,
  fields: PropTypes.object.isRequired,
};

ConsumerGroupsTable.defaultProps = {
  rows: [],
};

export default ConsumerGroupsTable;
