import {createSelector, createStructuredSelector} from 'reselect';
import {I18n} from 'react-redux-i18n';
import * as dataNames from '../constants/dataNames';
import getSelectedDataName from '../constants/helpers/getSelectedDataName';
import {getFacilities} from './facilitiesSelectors';
import getCurrentFacility from './facility/getCurrentFacility';

export const getTaxProfiles = state => state[dataNames.taxProfiles];
const getCategoryId = (_, params) => params.categoryId;
export const getSelectedTaxProfiles = state => state[getSelectedDataName(dataNames.taxProfiles)];
export const getSelectedTaxProfileIds = createSelector(getSelectedTaxProfiles, profiles => profiles.map(p => p.id));

//const getStatus = (_, props) => props && props.params && props.params.status;
const getStatus = (_, props) => props && props.route && props.route.path && props.route.path.indexOf('inactive') !== -1 ? 'inactive' : 'active';

const getActiveTab = createSelector([getStatus], status => status === 'inactive' ? status : 'active');

const makeCustomerTypes = (taxProfile, facilityState = '') => {
  const types = [];
  taxProfile.applies_to_medical && types.push(I18n.t(String(facilityState).toLowerCase() === 'ca' ? 'taxes.form.mmic' : 'taxes.form.medical')); // Looks backward but is correct see MJP-9836
  taxProfile.applies_to_non_mmic && types.push(I18n.t(String(facilityState).toLowerCase() === 'ca' ? 'taxes.form.medical' : 'taxes.form.mmic'));  // Looks backward but is correct see MJP-9836
  taxProfile.applies_to_recreational && types.push(I18n.t('taxes.form.recreational'));
  taxProfile.applies_to_disabled_veterans && types.push(I18n.t('taxes.form.disabledVeterans'));
  return types.join(', ');
};

const makeTaxState = (taxProfile) => {
  const types = [];
  taxProfile.applies_in_state && types.push(I18n.t('taxes.form.inState'));
  taxProfile.applies_out_of_state && types.push(I18n.t('taxes.form.outOfState'));
  return types.join(', ');
};

const getTableData = createSelector(
  [getTaxProfiles, getActiveTab, getFacilities],
  (taxProfiles, activeTab, facilities) => {
    return taxProfiles
      .filter(taxProfile => taxProfile.active ? taxProfile.active === (activeTab === 'inactive' ? 0 : 1) : (activeTab === 'active' ? 0 : 1))
      .map(taxProfile => {
        const firstFacility = facilities.find((facility) => facility.id === taxProfile.facility_ids[0]);
        return {
          ...taxProfile,
          customer_types: makeCustomerTypes(taxProfile, firstFacility ? firstFacility.province_code : ''),
          facilities: taxProfile.facility_ids.reduce((acc, facility_id) => {
            const matchedFacility = facilities.find(facility => facility.id === facility_id);
            if (matchedFacility) {
              acc.push(matchedFacility.name);
            }
            return acc;
          }, []).sort().join(', '),
          in_state: makeTaxState(taxProfile)
        };
      });
  }
);

export const getCategoryTaxRate = createSelector(
  [getTaxProfiles, getCategoryId, getCurrentFacility],
  (taxProfiles, categoryId, currentFacility) => {
    let rate = 0;
    if(taxProfiles.length && currentFacility){
      rate = taxProfiles
        .filter(profile => Array.isArray(profile.facility_ids) ? ~profile.facility_ids.indexOf(currentFacility.id) : false)
        .filter(profile => (Array.isArray(profile.taxes) && profile.taxes.length) ? true : false)
        .sort( (a,b) => a.weight - b.weight )
        .slice(0,1)
        .reduce((rate, taxProfile) => {
          return Array.isArray(taxProfile.taxes) && (rate + taxProfile.taxes.reduce((prev, item) => {
            return item.tax.categories.some((category) => category.item_category_id === categoryId) ? prev + Number(item.tax.amount) : prev;
          }, 0));
        }, 0);
    }
    return rate;
  }
);


const getFilteredTaxRate = (taxProfiles, categoryId, facilityId, customerType, inState) => {
  let rate = 0;
  rate = taxProfiles
    .filter(profile => Array.isArray(profile.facility_ids) ? ~profile.facility_ids.indexOf(facilityId) : false)
    .filter(profile => profile[`applies_to_${customerType}`] === 1)
    .filter(profile => profile[`applies_${inState}`] === 1)
    .filter(profile => !!(Array.isArray(profile.taxes) && profile.taxes.length))
    .reduce((rate, taxProfile) => {
      return Array.isArray(taxProfile.taxes) && (rate + taxProfile.taxes.reduce((prev, item) => {
        return item.tax.categories.some((category) => category.item_category_id === categoryId) ? prev + Number(item.tax.amount) : prev;
      }, 0));
    }, 0);
  return rate;
};

// Returns taxRates for specified category ID for all facilities of the organization
export const getCategoryTaxRates = createSelector(
  [getTaxProfiles, getCategoryId, getFacilities],
  (taxProfiles, categoryId, facilities) => {
    const taxRates = [];
    if(taxProfiles.length && facilities.length) {
      facilities.forEach(facility => {
        const facilityId = facility.id;
        const facilityName = facility.name;
        const rec_in_state = getFilteredTaxRate(taxProfiles, categoryId, facilityId, 'recreational', 'in_state');
        const rec_out_of_state = getFilteredTaxRate(taxProfiles, categoryId, facilityId, 'recreational', 'out_of_state');
        const med_in_state = getFilteredTaxRate(taxProfiles, categoryId, facilityId, 'medical', 'in_state');
        const med_out_of_state = getFilteredTaxRate(taxProfiles, categoryId, facilityId, 'medical', 'out_of_state');
        taxRates.push({facility_id: facilityId, facility_name: facilityName, rec_in_state, rec_out_of_state, med_in_state, med_out_of_state});
      });
    }
    return taxRates;
  }
);

export const getTaxProfilesPageProps = createStructuredSelector({
  activeTab: getActiveTab,
  data: getTableData,
  selectedRows: getSelectedTaxProfileIds,
});
