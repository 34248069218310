/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {goBack} from 'react-router-redux';
import FormWrapper from '../../common/form/FormWrapper';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import {getUnpaginatedData, putItem, getItem} from '../../../actions/apiActions';
import {
  getAllowedFacilitiesForSelecting,
  getModifySharedFacilityGroupInitialValues
} from '../../../selectors/facilityGroupsSharingSelectors';
import SharedFacilityGroupFormWrapper from './common/SharedFacilityFormWrapper';
import {MODIFY_FACILITY_GROUP_FORM} from '../../../constants/forms';
import {getActiveFacilityId} from '../../../selectors/facilitiesSelectors';
import * as messageTypes from '../../../constants/messageTypes';
import {addMessage} from '../../../actions/systemActions';

export class ModifySharedFacilityGroupPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount() {
    const {id} = this.props.params;
    Promise.all([
      this.props.actions.getUnpaginatedData('/api/facilities', dataNames.facilities),
      this.props.actions.getItem(`/api/facility_groups_sharings/${id}`, itemNames.sharedFacilityGroup, null, {detailed: 1}),
      this.props.actions.getUnpaginatedData('/api/facility_groups_sharings', dataNames.sharedFacilityGroups, {failed: 'failedToGetFacilityGroups'})
    ]);
  }

  onSubmit(formValues) {
    const {params: {id}, initialValues} = this.props;
    const payload = {
      ...formValues,
      facility_id: formValues.masterFacilityId,
      facilities: formValues.facilities.map(facility => ({facility_id: facility.id})),
    };
    this.props.actions.putItem(
      `/api/facility_groups_sharings/${id}`,
      payload,
      itemNames.sharedFacilityGroup,
      {
        success: 'common.success',
        fail: 'common.failed'
      },
      null,
      () => {
        if (formValues.facilities.length < initialValues.facilities.length) {
          this.props.actions.addMessage(messageTypes.warning, ['facilityGroupsSharing.modify.removeFacilityFromGroupWarning']);
        }
        this.props.actions.goBack();
      }

    );
  }

  render() {
    const {facilities, initialValues, currentFacilityId} = this.props;
    return (
      <FormWrapper title={'facilityGroupsSharing.modify.modifyFacilityGroup'} goBack={this.props.actions.goBack}>
        <SharedFacilityGroupFormWrapper
          onSubmit={this.onSubmit}
          facilities={facilities}
          initialValues={initialValues}
          form={MODIFY_FACILITY_GROUP_FORM}
          disabled={currentFacilityId !== initialValues.masterFacilityId}
        />
      </FormWrapper>
    );
  }
}

ModifySharedFacilityGroupPage.propTypes = {
  actions: PropTypes.shape({
    putItem: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired
  }),
  facilities: PropTypes.array.isRequired,
  initialValues: PropTypes.object.isRequired,
  currentFacilityId: PropTypes.number.isRequired
};

function mapStateToProps(state) {
  return {
    facilities: getAllowedFacilitiesForSelecting(state),
    initialValues: getModifySharedFacilityGroupInitialValues(state),
    currentFacilityId: getActiveFacilityId(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({getUnpaginatedData, putItem, goBack, getItem, addMessage}, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (ModifySharedFacilityGroupPage);
