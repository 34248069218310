import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import { Col, Row} from 'react-bootstrap';
import {Field} from 'redux-form';
import ReactSelectInput from '../common/form/ReactSelectInput';
import MultiselectInput from '../common/form/MultiselectInput';
import InternationalDateTimePicker from '../common/form/InternationalDateTimePickerInput';
import InlineCheckBox from '../common/form/InlineCheckBox';


const AccountingExportFieldArray = ({exportFormats, quickbooksAccountsTypes, facilityOptions}) => (
    <div className='account-export-form'>
      <Row className='export-option-row'>
        <Col className='form-fields-col' xs={12} md={4} lg={4}>
          <Field name={`selectExportFormat`} component={ReactSelectInput} props={{
            label: I18n.t('accounting.export.selectExportFormat'),
            options: exportFormats,
            isRequired: true,
          }}/>
        </Col>
        <Col className='form-fields-col' xs={12} md={3} lg={3}>
          <InternationalDateTimePicker name={`exportStartDate`} props={{
            label: I18n.t('accounting.export.startDate'),
            isRequired: true,
          }}/>
        </Col>
        <Col className='form-fields-col' xs={12} md={3} lg={3}>
          <InternationalDateTimePicker name={`exportEndDate`} props={{
            label: I18n.t('accounting.export.endDate'),
            isRequired: true,
          }}/>
        </Col>
      </Row>
      <Row className='export-option-row'>
        <Col className='form-fields-col' xs={12} md={6} lg={6}>
          <Field
            name='export_locations'
            component={MultiselectInput}
            props={{
              label: I18n.t('accounting.export.selectFacility'),
              options: facilityOptions,
              isRequired: true,
              allOption: true
            }}
          />
        </Col>
        <Col className='form-fields-col' xs={12} md={6} lg={6}>
          <Field name='export_types' component={MultiselectInput} props={{
            label: I18n.t('accounting.export.selectTransactionType'),
            options: quickbooksAccountsTypes,
            isRequired: true,
            allOption: true
          }}/>
        </Col>
      </Row>
      <Row>
        <Col xs={12} style={{marginBottom: '6px'}}>
          <Field
            name={`display_class`}
            component={InlineCheckBox}
            props={{
              label: I18n.t('accounting.export.displayClass'),
              groupClassName: 'inline-checkbox',
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} style={{marginBottom: '6px'}}>
          <Field
            name={`display_vendor`}
            component={InlineCheckBox}
            props={{
              label: I18n.t('accounting.export.displayVendor'),
              groupClassName: 'inline-checkbox',
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} style={{marginBottom: '6px'}}>
          <Field
            name={`display_gross`}
            component={InlineCheckBox}
            props={{
              label: I18n.t('accounting.export.displayGross'),
              groupClassName: 'inline-checkbox',
            }}
          />
        </Col>
      </Row>

    </div>
);

AccountingExportFieldArray.propTypes = {
  fields: PropTypes.shape({
    get: PropTypes.func.isRequired,
    map: PropTypes.func.isRequired,
  }).isRequired,
  facilityOptions: PropTypes.array.isRequired,
  quickbooksAccountsTypes: PropTypes.array.isRequired,
  exportFormats: PropTypes.array
};

export default AccountingExportFieldArray;
