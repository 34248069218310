import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { Field } from 'redux-form';
import { Button, ButtonToolbar, Col, Row } from 'react-bootstrap';
import get from 'lodash.get';
import { FaPencilAlt } from 'react-icons/fa';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import TablePageWrapper from '../../common/grid/TablePageWrapper';
import InternationalQuantityByUomInput from '../../common/form/InternationalQuantityByUomInput';
import InternationalDisplayQty from '../../common/InternationalDisplayQty';
import { leafPaMaxHarvestWeight, leafWaReconciliationReasonsRequiringReceivedBy } from '../../../constants/leaf';
import AdjustTemplate from './AdjustTemplate';
import NotesModal from './NotesModal';
import addTooltip from '../../../util/tooltipHelper'; // Import the NotesModal component

class ReconciliationForm extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showModal: false,
      currentNotes: '',
      currentRow: null
    };

    this.getReduxFormIndex = this.getReduxFormIndex.bind(this);
    this.getReduxFormItemName = this.getReduxFormItemName.bind(this);
    this.getReduxFormItem = this.getReduxFormItem.bind(this);
    this.getRowTotal = this.getRowTotal.bind(this);
    this.disableAdjustButton = this.disableAdjustButton.bind(this);
    this.getColumns = this.getColumns.bind(this);
    this.handleNotesSave = this.handleNotesSave.bind(this);
    this.handleNotesClose = this.handleNotesClose.bind(this);
    this.handleNotesOpen = this.handleNotesOpen.bind(this);
  }

  getReduxFormIndex(row) {
    const itemInFormArray = this.props.itemsLookup.find((item) => item.id === row.id);
    return this.props.itemsLookup.indexOf(itemInFormArray);
  }

  getReduxFormItemName(row) {
    return `categories[0].items[${this.getReduxFormIndex(row)}]`;
  }

  getReduxFormItem(row) {
    return this.props.itemsLookup[this.getReduxFormIndex(row)];
  }

  getRowTotal(row) {
    if (!row) return 0;
    return row.qty * (row.prepack_weight || 1) || 0;
  }

  disableAdjustButton(item) {
    const { isCanada, isMetrc, isPaLeaf, isWaLeaf } = this.props.integrationState;
    const { integration_adjustment_reason, complianceSettings } = this.props;
    if (!item) return true;

    return (
      this.itemIsLocked(item) ||
      item.quantity === undefined ||
      item.quantity === '' ||
      item.discrepancy === '' ||
      parseFloat(item.discrepancy) === 0 ||
      (isMetrc && !item.integration_adjustment_reason && !integration_adjustment_reason) ||
      ((isPaLeaf || isCanada) && !item.adjustment_reason) ||
      (isPaLeaf && parseFloat(item.quantity) > leafPaMaxHarvestWeight) ||
      (isWaLeaf && (!item.integration_adjustment_reason && !integration_adjustment_reason)) ||
      (isWaLeaf &&
        (leafWaReconciliationReasonsRequiringReceivedBy.includes(item.integration_adjustment_reason) &&
          !item.received_by_user_id)) ||
      (get(complianceSettings, 'reconciliation_requires_adjustment_reason', false) && !item.adjustment_reason)
    );
  }

  itemIsLocked(item) {
    const { useEntityLocks } = this.props;

    return Boolean(useEntityLocks && item.is_locked);
  }

  handleNotesSave(notes) {
    const { currentRow } = this.state;
    // Update the notes for the currentRow
    const currentItem = this.props.getFormValue(this.getReduxFormItemName(currentRow));
    const quantity = get(this.props.getFormValue(this.getReduxFormItemName(currentRow)), 'quantity');
    const adjustment_reason = get(this.props.getFormValue(this.getReduxFormItemName(currentRow)), 'adjustment_reason');
    const updatedItem = {...currentItem, quantity, physical_count: quantity, adjustment_reason, notes};

    // this.props.actions.setData(updatedItem, `${this.getReduxFormItemName(currentRow)}`);
    // Simulate redux-form update
    this.props.change(this.getReduxFormItemName(currentRow), updatedItem);
    this.setState({ showModal: false, currentNotes: '', currentRow: null });
  }

  handleNotesClose() {
    this.setState({ showModal: false, currentNotes: '', currentRow: null });
  }

  handleNotesOpen(row) {
    const notesFieldValue = get(this.props.getFormValue(this.getReduxFormItemName(row)), 'notes');
    const notesValue = (notesFieldValue) ? notesFieldValue : row.notes;
    this.setState({ showModal: true, currentNotes: notesValue, currentRow: row });
  }

  getColumns() {
    const {
      onAdjust,
      adjustmentReasons,
      integrationAdjustmentReasons,
      integrationState,
      adjustment_reason,
      integration_adjustment_reason,
      shouldDisplayReceivedByDropdown,
      facilityUsers,
      complianceSettings,
    } = this.props;

    const { isBiotrack, isMetrc, isWaLeaf, isNormal } = integrationState;
    const prefix = isBiotrack ? 'biotrack' : isMetrc ? 'metrc' : 'leaf';

    const getUseUom = (row) => {
      const prepackUom = get(row, 'prepack_uom');
      const uom = get(row, 'uom');
      return prepackUom !== null ? prepackUom : uom;
    };

    const cols = [
      {
        name: I18n.t('reconciliation.itemName'),
        dataId: 'item_name',
        hidden: false,
        dataSort: true,
        scanSearchColumn: true,
        columnClassName: 'allow-td-wrap',
        width: '10%',
        formatter: (cell, row) => { // eslint-disable-line react/no-multi-comp
          const value = get(row, 'item_name');
          if (typeof value !== 'string') {
            return '';
          }
          if (value.indexOf(' ') !== -1) return row.item_name;
          return <div title={value}>{value.match(/.{1,25}/g).join(' ')}</div>;
        }
      },
      {
        name: I18n.t('reconciliation.vendorName'),
        dataId: 'vendor_name',
        hidden: false,
        dataSort: true,
        width: '10%',
        scanSearchColumn: true
      },
      {
        name: I18n.t('reconciliation.packageIdUnitId'),
        dataId: 'package_code',
        hidden: false,
        dataSort: true,
        scanSearchColumn: true,
        className: 'allow-th-wrap',
        width: '10%',
      },
      {
        name: I18n.t('reconciliation.storageLocation'),
        dataId: 'location_name',
        hidden: false,
        dataSort: true,
        scanSearchColumn: true,
        className: 'allow-th-wrap',
        width: '10%',
      },
      {
        name: I18n.t('reconciliation.unitSize'),
        dataId: 'prepack_weight',
        hidden: false,
        dataSort: false,
        scanSearchColumn: true,
        width: '5%',
        formatter: (cell, row) => {
          if (!row.prepack_uom) return 'N/A';
          return `${row.prepack_weight} ${row.prepack_uom}`;
        }
      },
      {
        name: I18n.t('reconciliation.quantity'),
        dataId: 'quantity',
        hidden: false,
        dataSort: false,
        columnClassName: 'allow-wrap allow-overflow form-group-no-margin',
        scanSearchColumn: true,
        width: '5%',
        formatter: (cell, row) => { //eslint-disable-line
          //const item = row;
          const itemName = this.getReduxFormItemName(row);
          return (<InternationalQuantityByUomInput
            name={`${itemName}.quantity`}
            uom={get(row, 'uom')}
            hideUom={true}
            disabled={this.itemIsLocked(row)}
          />);
        },
      },
      {
        name: I18n.t('reconciliation.uom'),
        dataId: 'uom',
        hidden: false,
        dataSort: true,
        scanSearchColumn: true,
        width: '5%',
      },
      {
        name: I18n.t('reconciliation.total'),
        dataId: 'total',
        hidden: false,
        dataSort: false,
        scanSearchColumn: true,
        width: '5%',
        /* eslint-disable react/no-multi-comp */
        formatter: (cell, row) => {
          const useUom = getUseUom(row);
          const total = this.getRowTotal(this.getReduxFormItem(row));
          return (<span><InternationalDisplayQty qty={total} uom={useUom} displayUom={true} /></span>);  // `${total} ${useUom}`;
        },
      },
      {
        name: I18n.t('reconciliation.discrepancy'),
        dataId: 'discrepancy',
        hidden: false,
        dataSort: false,
        scanSearchColumn: true,
        width: '5%',
        formatter: (cell, row) => { //eslint-disable-line
          //eslint-disable-line
          const itemName = this.getReduxFormItemName(row);
          const useUom = getUseUom(row);
          return (<Field name={`${itemName}.discrepancy`} component={(props) => { //eslint-disable-line
            return <div style={{ color: props.input.value >= 0 ? 'green' : 'red' }}><InternationalDisplayQty qty={props.input.value} uom={useUom} /></div>; //eslint-disable-line
          }} />);
        },
      },
      {
        name:
          I18n.t('reconciliation.adjustmentReason') +
          (get(complianceSettings, 'reconciliation_requires_adjustment_reason', false) ? '*' : ''),
        dataId: 'adjustment_reason',
        hidden: false,
        className: 'allow-th-wrap',
        columnClassName: 'allow-wrap allow-overflow form-group-no-margin',
        dataSort: false,
        scanSearchColumn: true,
        width: '10%',
        formatter: (cell, row) => { //eslint-disable-line
          //eslint-disable-line
          const itemName = this.getReduxFormItemName(row);
          return (
            <Field
              name={`${itemName}.adjustment_reason`}
              component={ReactSelectInput}
              props={{
                options: adjustmentReasons,
                textKey: 'name',
                valueKey: 'code',
                disabled: this.itemIsLocked(row)
              }}
            />
          );
        },
      },
      {
        name: I18n.t(`reconciliation.${prefix}AdjustmentReason`),
        dataId: 'integration_adjustment_reason',
        hidden: !isMetrc && !isBiotrack && !isWaLeaf,
        className: 'allow-th-wrap',
        columnClassName: 'allow-wrap allow-overflow form-group-no-margin',
        dataSort: false,
        width: '10%',
        formatter: (cell, row) => { //eslint-disable-line
          //eslint-disable-line
          const itemName = this.getReduxFormItemName(row);
          return (
            <Field
              name={`${itemName}.integration_adjustment_reason`}
              component={ReactSelectInput}
              props={{ options: integrationAdjustmentReasons }}
              disabled={this.itemIsLocked(row)}
            />
          );
        }
      },
      {
        name: 'common.notes',
        dataId: 'notes',
        dataAlign: 'left',
        dataSort: false,
        width: '10%',
        /* eslint-disable react/no-multi-comp */
        formatter: (cell, row) => {
          const notesFieldValue = get(this.props.getFormValue(this.getReduxFormItemName(row)), 'notes');
          const notesValue = (notesFieldValue) ? notesFieldValue : row.notes;
          return (
            <div style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
              <FaPencilAlt
                style={{fontSize: '1em', color: 'darkgray', marginRight: '8px'}}
                data-toggle='tooltip'
                data-placement='top'
                title={notesValue}
                onClick={() => this.handleNotesOpen(row)}
              />
              <span onClick={() => this.handleNotesOpen(row)}>
                {addTooltip(notesValue)}
              </span>
              <Field name={`${this.getReduxFormItemName(row)}.notes`} component='input' type='hidden'/>
            </div>
          );
        },
      }];

    if (isWaLeaf && shouldDisplayReceivedByDropdown) {
      cols.push({
        name: I18n.t(`reconciliation.receivedBy`),
        dataId: 'received_by_user_id',
        hidden: false,
        className: 'allow-th-wrap',
        columnClassName: 'allow-wrap allow-overflow form-group-no-margin',
        dataSort: false,
        formatter: (cell, row) => { //eslint-disable-line
          //eslint-disable-line
          const item = this.getReduxFormItem(row);
          const itemName = this.getReduxFormItemName(row);

          if (!leafWaReconciliationReasonsRequiringReceivedBy.includes(item.integration_adjustment_reason)) {
            return null;
          }

          return (
            <Field
              name={`${itemName}.received_by_user_id`}
              component={ReactSelectInput}
              props={{
                options: facilityUsers,
                textKey: 'name',
                valueKey: 'id'
              }}
            />
          );
        }
      });
    }

    cols.push({
      dataSort: false,
      width: '5%',
      formatter: (cell, row) => { //eslint-disable-line
        //eslint-disable-line
        const total = this.getRowTotal(row);
        const itemName = this.getReduxFormItemName(row);
        const item = this.getReduxFormItem(row);
        return (
          <div>
            <Button
              className={total === 0 ? 'hide' : ''}
              variant='primary'
              size='sm'
              onClick={() => onAdjust([{ itemName, item }], { adjustment_reason, integration_adjustment_reason })}
              disabled={this.disableAdjustButton(item)}
            >
              {I18n.t('reconciliation.actions.adjust')}
            </Button>
            {total > 0 ? null : <div>{I18n.t('reconciliation.actions.zeroQuantityHint')}</div>}
          </div>
        );
      }
    });


    const columns = cols.reduce((acc, col) => {
      if (!isMetrc && !isBiotrack && !isWaLeaf && col.dataId === 'integration_adjustment_reason') return acc;
      if (isNormal && col.dataId === 'add_back_to_parent') return acc;
      acc.push(col);
      return acc;
    }, []);

    return columns;
  }

  render() {
    const {
      handleSubmit,
      onRedirect,
      valid,
      pristine,
      submitting,
      reset,
      adjustAllEnabled,
      adjustmentReasons,
      integrationAdjustmentReasons,
      integrationState,
      integration_adjustment_reason,
      tableData,
      formUpdateInProgress,
      auditImportModeEnabled,
      facilityUsers,
      isFeatureEnabledInventoryAuditImport,
      actions
    } = this.props;

    const { isBiotrack, isMetrc, isWaLeaf } = integrationState;
    const prefix = isBiotrack ? 'biotrack' : isMetrc ? 'metrc' : 'leaf';
    const shouldDisplayReceivedByForReconcileAll = leafWaReconciliationReasonsRequiringReceivedBy.includes(
      integration_adjustment_reason
    );

    const disableChanges = formUpdateInProgress && !auditImportModeEnabled;
    const disableReason = 'Under audit report import mode. Please save adjustments or reset the form so there are no discrepancies to re-enable table navigation.';

    const updateRowsWithChosenAdjustmentReason = (value) => {
      const form = 'reconciliation';
      const { itemsLookup } = this.props;
      itemsLookup.forEach((item) => {
        if (parseFloat(item.discrepancy) !== 0) {
          actions.change(form, `${this.getReduxFormItemName(item)}.adjustment_reason`, value);
        }
      });
    }

    const adjustmentReasonDropdown =
      <Row>
        <Col md={{ span: 4, offset: 8 }} lg={{ span: 3, offset: 9 }}>
          <Field
            name='adjustment_reason'
            component={ReactSelectInput}
            props={{
              label: I18n.t('packages.form.AdjustAllAdjustmentReason'),
              options: adjustmentReasons,
              textKey: 'name',
              valueKey: 'code',
              onChange: (value) => {
                const form = 'reconciliation';
                actions.change(form, 'adjustment_reason', value);
                updateRowsWithChosenAdjustmentReason(value);
              }
            }}
          />
        </Col>
      </Row>;

    return (
      <form className='reconciliation-page' onSubmit={handleSubmit} noValidate={true}>
        {isFeatureEnabledInventoryAuditImport 
        && tableData.activeTab != 0
        && <AdjustTemplate show={true} downloadOnly={false} actions={actions} changeTab={this.props.changeTab} activeTab={tableData.activeTab} />}
        <div className='button-bar-bottom-padding allow-overflow'>
          {!tableData ? null : (
            <TablePageWrapper
              ref={tableData.ref}
              settingKey='reconciliations'
              columns={this.getColumns()}
              tabs={tableData.tabs}
              activeTab={tableData.activeTab}
              switchTab={tableData.switchTab}
              onPageChange={tableData.switchTab}
              data={tableData.data}
              dataUpdateAvailable={[]}
              dataUpdated={null}
              handleSelect={null}
              selectedRows={[]}
              hideScanSearch={true}
              dataTotalSize={tableData.dataTotalSize}
              externalSearch={tableData.externalSearch}
              showHistory={false}
              external={!auditImportModeEnabled}
              className='reconciliations-page'
              hideExport={true}
              sort='name asc'
              bstProps={{
                options: {
                  onSearchChange: tableData.onSearchChange
                },
                ignoreSinglePage: true
              }}
              disableChanges={disableChanges}
              disableReason={disableReason}
              filterTabsChildren={adjustmentReasonDropdown}
            />
          )}
        </div>

        <Row className='submit-section'>
          {isBiotrack || isMetrc || isWaLeaf ? (
            <Col md={3} offset={9}>
              <Field
                name='integration_adjustment_reason'
                component={ReactSelectInput}
                props={{
                  label: I18n.t(`packages.form.${prefix}AdjustAllAdjustmentReason`),
                  options: integrationAdjustmentReasons
                }}
              />
            </Col>
          ) : null}
          {isWaLeaf && shouldDisplayReceivedByForReconcileAll && (
            <Col md={3} offset={9}>
              <Field
                name='received_by_user_id'
                component={ReactSelectInput}
                props={{
                  label: I18n.t(`reconciliation.receivedBy`),
                  options: facilityUsers.filter((u) => u.state_integration_id),
                  textKey: 'name',
                  valueKey: 'id'
                }}
              />
            </Col>
          )}

          <Col xs={12}>
            <div className='button-wrapper'>
              <ButtonToolbar className='float-right'>
                <Button variant='default' type='reset' disabled={submitting} onClick={reset}>
                  {I18n.t('reconciliation.actions.reset')}
                </Button>
                <Button variant='danger' type='submit' disabled={!valid || (!auditImportModeEnabled && pristine) || submitting || !adjustAllEnabled}>
                  {I18n.t('reconciliation.actions.adjustAll')}
                </Button>
                <Button variant='primary' onClick={onRedirect}>
                  {I18n.t('reconciliation.actions.exitPage')}
                </Button>
              </ButtonToolbar>
            </div>
          </Col>
        </Row>

        <NotesModal
          show={this.state.showModal}
          onHide={this.handleNotesClose}
          onSave={this.handleNotesSave}
          notes={this.state.currentNotes}
        />
      </form>
    );
  }
}

ReconciliationForm.propTypes = {
  change: PropTypes.func.isRequired,
  getFormValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onRedirect: PropTypes.func.isRequired,
  onAdjust: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  adjustAllEnabled: PropTypes.bool.isRequired,
  adjustmentReasons: PropTypes.array.isRequired,
  integrationAdjustmentReasons: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
  adjustment_reason: PropTypes.string,
  integration_adjustment_reason: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tableData: PropTypes.object,
  itemsLookup: PropTypes.array.isRequired,
  reset: PropTypes.func.isRequired,
  formUpdateInProgress: PropTypes.bool.isRequired,
  shouldDisplayReceivedByDropdown: PropTypes.bool.isRequired,
  facilityUsers: PropTypes.array,
  complianceSettings: PropTypes.object,
  useEntityLocks: PropTypes.bool,
  isFeatureEnabledInventoryAuditImport: PropTypes.bool,
  actions: PropTypes.object.isRequired,
};

export default ReconciliationForm;
