import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import InventoryReceiptPackage from './InventoryReceiptPackage';

const InventoryReceiptPackagesFieldArray = (props) => {
  const {
    fields,
    locked,
    change,
    formValues,
    inventoryReceipt,
    itemMasters,
    testResults,
    testResultDimensions,
    getFormValue,
    updateTotalInvoiceAmount,
    strainOptions,
    storageLocations,
    barcodeSource,
    isCreateMode
  } = props;

  return (
    <React.Fragment>
      {fields.map((line, index) => {
        const item_master_id = get(formValues, `lines.${index}.item_master_id`);
        const itemMaster = itemMasters.find((im) => im.id === item_master_id);

        const packageId = get(inventoryReceipt, `lines.${index}.inventory.0.package_id`);
        // Find test result for Package
        const testResult = packageId ? testResults.find((testResult) => testResult.package_id === packageId) : null;

        return (
          <InventoryReceiptPackage
            key={index}
            change={change}
            fieldName={`lines.${index}`}
            formValues={formValues}
            itemMaster={itemMaster}
            testResult={testResult}
            testResultDimensions={testResultDimensions}
            getFormValue={getFormValue}
            updateTotalInvoiceAmount={updateTotalInvoiceAmount}
            strainOptions={strainOptions}
            storageLocations={storageLocations}
            barcodeSource={barcodeSource}
            locked={locked}
            isCreateMode={isCreateMode}
          />
        );
      })}
    </React.Fragment>

  );
};

InventoryReceiptPackagesFieldArray.propTypes = {
  fields: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  inventoryReceipt: PropTypes.object.isRequired,
  itemMasters: PropTypes.array.isRequired,
  testResults: PropTypes.array,
  testResultDimensions: PropTypes.object.isRequired,
  getFormValue: PropTypes.func.isRequired,
  updateTotalInvoiceAmount: PropTypes.func.isRequired,
  strainOptions: PropTypes.array.isRequired,
  storageLocations: PropTypes.array.isRequired,
  barcodeSource: PropTypes.string.isRequired,
  locked: PropTypes.bool.isRequired,
  isCreateMode: PropTypes.bool.isRequired
};

export default InventoryReceiptPackagesFieldArray;
