import * as types from '../constants/actionTypes';
import getSelectedDataName from '../constants/helpers/getSelectedDataName';

export function addSelectedData (selectedData, name) {
  return {type: types.ADD_SELECTED_DATA, selectedData, name: getSelectedDataName(name)};
}

export function removeSelectedData (selectedData, name) {
  return {type: types.REMOVE_SELECTED_DATA, selectedData, name: getSelectedDataName(name)};
}

export function clearSelectedData (name) {
  return {type: types.CLEAR_SELECTED_DATA, name: getSelectedDataName(name)};
}

export function setSelectedData (selectedData, name) {
  return {type: types.SET_SELECTED_DATA, selectedData, name: getSelectedDataName(name)};
}

export function setSelectedDataImmediate(selectedData, name) {
  return {type: types.SET_SELECTED_DATA_IMMEDIATE, selectedData, name: getSelectedDataName(name)};
}

export const clearSelectedDataAction = name => dispatch => dispatch(clearSelectedData(name));
