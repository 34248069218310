import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Row, Col, Form} from 'react-bootstrap';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import {convertCostByUOM} from '../../../../util/uomHelpers';
import TextInput from '../../../common/form/TextInput';
import InternationalQuantityByUomInput from '../../../common/form/InternationalQuantityByUomInput';
import InternationalCurrencyInput from '../../../common/form/InternationalCurrencyInput';

function onSetItem(itemId, change, items, prefix) {
  change(`${prefix}.item_id`, itemId);
  const item = items.find(i => i.id === itemId);
  change(`${prefix}.maxQty`, item && item.qty || '');
}

const IngredientsFieldArray = ({fields, change, ingredientOptions, ingredientsCost, ingredients, ingredientsStandardCost, isCompleted}) => {
  const parsedIngredientsCost = !isNaN(parseFloat(ingredientsCost)) ? parseFloat(ingredientsCost) : 0;
  const parsedIngredientsStandardCost = !isNaN(parseFloat(ingredientsStandardCost)) ? parseFloat(ingredientsStandardCost) : 0;
  const variance = parsedIngredientsCost.toFixed(2) - parsedIngredientsStandardCost.toFixed(2);
  return (
    <Row>
      <Col xs={12}>
        <table className='table table-striped'>
          <thead>
          <tr>
            <th><Form.Label>{I18n.t('ei.processing.form.name')}</Form.Label></th>
            <th><Form.Label>{I18n.t('ei.processing.form.storageLocation')}</Form.Label></th>
            <th><Form.Label>{I18n.t('ei.processing.form.qtyPlanned')}</Form.Label></th>
            <th><Form.Label>{I18n.t('common.form.uom')}</Form.Label></th>
            <th><Form.Label>{I18n.t('ei.processing.form.qtyUsed')}</Form.Label></th>
            <th><Form.Label>{I18n.t('ei.processing.form.actualCost')}</Form.Label></th>
          </tr>
          </thead>
          <tbody>
          {fields.map((ingredientName, ingredientIndex) => {
            const row = ingredients[ingredientIndex];
            if (!row) {
              return;//weird case
            }
            const options = ingredientOptions[row.item_master_id] || [];
            return (
              <tr key={ingredientName}>
                <td>{row.name}</td>
                <td>
                  <Field
                    name={`${ingredientName}.item_id`}
                    component={ReactSelectInput}
                    props={{
                      options: options,
                      textKey: 'location_name',
                      valueKey: 'id',
                      onChange: value => onSetItem(value, change, options, ingredientName),
                      disabled: isCompleted,
                    }}
                  />
                </td>
                <td>{row.qtyPlanned}</td>
                <td>{row.uom}</td>
                <td>
                  <InternationalQuantityByUomInput
                    name={`${ingredientName}.qty`}
                    disabled={isCompleted}
                    uom={row.uom}
                    hideUom={true}
                  />
                </td>
                <td>
                  <InternationalCurrencyInput
                    props={{
                      fractionPartSize: 2,
                      disabled:true,
                      value:(row.unitUOM) && convertCostByUOM(row.qty || 0, row.unitCost, row.unitUOM, row.uom)
                    }}
                  />
                </td>
                <td>
                  <Field
                    name={`${ingredientName}.id`}
                    component={TextInput}
                    style={{display: 'none'}}
                    props={{
                      groupClassName: 'hidden'
                    }}
                  />
                </td>
              </tr>
            );
          })}
          </tbody>
        </table>
      </Col>
      <Col xs={12}>
        <div className='float-right'>
          <div className='layout-row layout-align-end-start'>
            <InternationalCurrencyInput name='ingredientsActualCost'
              props={{
                fractionPartSize: 2,
                className:(ingredientsCost > 0) ? 'cost-red' : 'cost-green',
                label: I18n.t('ei.processing.form.actualCost'),
                disabled: true,
                value: (ingredientsCost || 0).toFixed(2),
              }}
            />
          </div>
          <div className='layout-row layout-align-end-start'>
            <InternationalCurrencyInput name='ingredientsStandardCost'
              props={{
                fractionPartSize: 2,
                className:(ingredientsStandardCost > 0) ? 'cost-red' : 'cost-green',
                label: I18n.t('ei.processing.form.standardCost'),
                disabled: true,
                value: (Number(ingredientsStandardCost) || 0).toFixed(2),
              }}
            />
          </div>
          <div className='layout-row layout-align-end-start'>
            <InternationalCurrencyInput
              name='ingredientsVariance'
              props={{
                className:(variance > 0) ? 'cost-red' : 'cost-green',
                label: I18n.t('ei.processing.form.variance'),
                disabled: true,
                fractionPartSize: 2,
                value: variance.toFixed(2),
              }}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
};

IngredientsFieldArray.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    get: PropTypes.func.isRequired,
  }).isRequired,
  change: PropTypes.func.isRequired,
  ingredients: PropTypes.array.isRequired,
  ingredientOptions: PropTypes.object.isRequired,
  ingredientsCost: PropTypes.number,
  ingredientsStandardCost: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  isCompleted: PropTypes.bool,
};

export default IngredientsFieldArray;
