import {createSelector} from 'reselect';
import * as dataNames from '../constants/dataNames';
import {buildCompleteInternationalPhoneNumber}  from '../util/formatHelper';
import { getPhoneShouldIncludeCountryCode } from './InternationalOperationsSelectors';

export const getVehicles = (state) => {
  const vehicles = state[dataNames.vehicles];

  vehicles.forEach((vehicle, index) => {
    vehicles[index].insurance_phone_number = buildCompleteInternationalPhoneNumber(vehicle.insurance_phone_number, vehicle.insurance_phone_country_code, getPhoneShouldIncludeCountryCode(state));
  });

  return vehicles;
};
const getLicenseNumber = (_, props) => props.licenseNumber;

export const getVehicle = (state) => {
  const vehicle = Object.assign({}, state.vehicle);
  vehicle.insurance_phone_number = buildCompleteInternationalPhoneNumber(vehicle.insurance_phone_number, vehicle.insurance_phone_country_code, getPhoneShouldIncludeCountryCode(state));

  return vehicle;
};

export const getVehiclesForDropdown = createSelector(getVehicles,
  vehicles => vehicles.map(vehicle => ({ ...vehicle,
    name: `${vehicle.nickname} | ${vehicle.make} | ${vehicle.model} | ${vehicle.license_number}`})));

export const getVehicleByLicenseNumber = createSelector(
  getVehicles, getLicenseNumber, (vehicles, licenseNumber) =>
    (Array.isArray(vehicles) && vehicles.find(vehicle => vehicle.license_number === licenseNumber)) || undefined
);
