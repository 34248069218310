import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {
  Col,
  Row,
  Card} from 'react-bootstrap';
import NumericInput from '../../../common/form/NumericInput';

const labels = [
  {},
  { storage: 'locations.form.defaultNumRooms', sale: 'locations.form.defaultNumSales' },
  { storage: 'locations.form.defaultNumZones', sale: 'locations.form.defaultNumAreas' },
  { storage: 'locations.form.defaultNumSections', sale: 'locations.form.defaultNumSubSections' }
];

const QuickSetup = ({type, fields: {map}}) => {
  return (
    <Row className='quick-setup'>
      <Col xs ={6} sm={10} md={8}>
      <Card>
        <Card.Body>
          <h4>{I18n.t('locations.form.quickSetup')}</h4>
            <Row>
              {map((name, index) => {
                if (index === 0) {
                  return;
                }
                return (
                  <Col xs={12} md={4} key={name}>
                    <Field name={name} component={NumericInput} props={{
                      min: 1,
                      step: 1,
                      fractionPartSize: 0,
                      label: I18n.t(labels[index][type])
                    }}/>
                  </Col>
                );
              })}
            </Row>
          </Card.Body>
      </Card>
      </Col>
    </Row>
  );
};

QuickSetup.propTypes = {
  type: PropTypes.oneOf(['storage', 'sale']).isRequired,
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired
  }).isRequired
};

export default QuickSetup;
