import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

class DelightedWidget extends React.PureComponent {
  constructor (props, context) {
    super(props, context);
    this.runDelighted = this.runDelighted.bind(this);
  }

  runDelighted(user, facility) {
    if(!Array.isArray(user.userOrganizations) || !user.userOrganizations.length){ return false; }
    const userCurrentOrganization = user.userOrganizations.find(organization => organization.id == facility.organizationId);
    // const createdAt = new Date().toISOString();
    window.delightedNps2.survey({
      email: user.email, // customer email (Required)
      name: `${user.firstName} ${user.lastName}`, // customer name (Required)
      // createdAt: createdAt,  // time subscribed (optional)
      properties: {
        Brand: 'MJ Platform',
        Organization: userCurrentOrganization.name,
        Facility: facility.name,
        State: facility.province_code,
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.facility !== nextProps.facility) {
      if (window.delightedNps2) {
        this.runDelighted(this.props.user, nextProps.facility);
      }
    }
  }

  componentDidMount () {
    const {app_id, user, facility} = this.props;

    if (app_id) {
      !((e, t, r, n) => {if(!e[n]){
        const a = e[n] = [];
        let s = 0;
        const i = ['survey', 'reset', 'config', 'init', 'set', 'get', 'event', 'identify', 'track', 'page', 'screen', 'group', 'alias'];
        for(; s < i.length; s++) {
          const c = i[s];
          a[c] = a[c] || function(e) {
            return function() {
              const t = Array.prototype.slice.call(arguments);
              a.push([e,t]);
            };
          }(c);}
        a.SNIPPET_VERSION = '1.0.1';
        const o = t.createElement('script');
        o.type = 'text/javascript';
        o.async = !0;
        o.src = 'https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/' + r + '/' + n + '.js';
        const l = t.getElementsByTagName('script')[0];
        l.parentNode.insertBefore(o,l);
      }})(window,document,app_id,'delightedNps2');
      if (window.delightedNps2) {
        this.runDelighted(user, facility);
      }
    }
  }

  componentWillUnmount () {
    if (window.delightedNps2) {
      delete window.delightedNps2;
    }
  }

  render () {
    return false;
  }
}

function mapStateToProps (state) {
  return {
    user: state.user,
    facility: state.facility,
    app_id: state.env.delightedKey
  };
}

DelightedWidget.propTypes = {
  user: PropTypes.object.isRequired,
  facility: PropTypes.object.isRequired,
  app_id: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(DelightedWidget);
