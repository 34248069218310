import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field, reduxForm} from 'redux-form';
import {Modal, Row, Col, Alert} from 'react-bootstrap';
import ReactSelectInput from '../common/form/ReactSelectInput';
import TextAreaInput from '../common/form/TextAreaInput';
import TextInput from '../common/form/TextInput';
import SubmitSection from '../common/form/SubmitSection';
import {INVENTORY_SYNC_INTEGRATOR_MODAL} from '../../constants/forms';

const InventorySyncIntegratorModal = (props) => {

  const {
    submitting, pristine, reset, handleSubmit, initialValues, integrator,
    showModal, hideModal, adjustIntegratorLevel, integrationAdjustmentReasons, errorType
  } = props;

  const settings = {
    actionSettings: {
      submit: {
        action: handleSubmit(adjustIntegratorLevel),
        text: I18n.t('inventory.sync.actions.completePackageSync'),
        pristine,
        submitting,
        style: 'primary'
      },
      reset: {
        action: reset,
        text: I18n.t('common.form.reset'),
        style: 'default',
        pristine
      },
    }
  };

  if(Object.keys(initialValues).length === 0){
    return <span/>;
  }

  return (
    <Modal show={showModal} className='inventory-sync-confirmation' onHide={hideModal}>

      <Modal.Header className='inventory-sync-modal-header text-center' closeButton>
        <h2>{I18n.t('inventory.sync.adjustIntegrator', {integrator})}</h2>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit(adjustIntegratorLevel)}>
          <Row>
            <Col className='text-center'>
              <h2>{`${(initialValues.qty < 0 ? '' : '+')}${initialValues.qty} ${initialValues.uom}`}</h2>
              <small className='text-muted'>
                {I18n.t('inventory.sync.message.text', {
                  action: (initialValues.qty < 0 ? I18n.t('inventory.sync.message.reduce') : I18n.t('inventory.sync.message.increase')),
                  target: integrator,
                  quantity: Math.abs(initialValues.qty) + ' ' + initialValues.uom}
                )}
              </small>
            </Col>
          </Row>
          {errorType !== undefined && <Alert variant='danger'>{I18n.t(`inventory.sync.error.${errorType}`)}</Alert>}
          <Row>
            <Col sm={2}>
              <Field
                name='qty'
                component={TextInput}
                type='hidden'
                props={{
                  disabled: true,
                }}
              />
            </Col>
            <Col sm={2}>
              <Field
                name='uom'
                component={TextInput}
                type='hidden'
                props={{
                  disabled: true,
                }}
              />
            </Col>
            <Col sm={12}>
              <Field
                name='integrator_tag'
                component={TextInput}
                props={{
                  label: I18n.t('inventory.sync.table.columns.integrator_id', {integrator}) + ':',
                  disabled: true,
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Field
                name='reason'
                component={ReactSelectInput}
                props={{
                  label: I18n.t('inventory.sync.table.columns.adjustment_reason') + ':',
                  isRequired: true,
                  options: integrationAdjustmentReasons,
                }}
              />
            </Col>
            <Col sm={6}>
              <Field
                name='notes'
                component={TextAreaInput}
                style={{width:'100%', height: '200px'}}
                props={{
                  label: I18n.t('inventory.sync.table.columns.adjustment_notes') + ':',
                  placeholder: I18n.t('inventory.sync.table.columns.adjustment_notes_integrator', {integrator})
                }}
              />
            </Col>
          </Row>
        </form>
      </Modal.Body>

      <Modal.Footer>
        <Row>
          <Col>
            <SubmitSection settings={settings} />
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

InventorySyncIntegratorModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  integrationAdjustmentReasons: PropTypes.array.isRequired,
  adjustIntegratorLevel: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  errorType: PropTypes.string,
  integrator: PropTypes.string.isRequired
};


export default reduxForm({
  form: INVENTORY_SYNC_INTEGRATOR_MODAL, // a unique identifier for this form
})(InventorySyncIntegratorModal);
