import get from 'lodash.get';
import {set} from 'lodash';

let settings = {};

export default class Config {
  static add(key, value)
  {
    // To satisfy EsLint
    if (!settings) {
      settings = {};
    }
    set(settings, key, value);
  }

  static setting(key)
  {
    return get(settings, key);
  }
}