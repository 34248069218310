import { createSelector } from 'reselect';
import get from 'lodash.get';
import * as itemNames from '../constants/itemNames';

const getOrder = (state) => state[itemNames.order];
const getAllowAnonymousOrders = (state) => get(state[itemNames.salesComplianceSettings], 'order_allow_anonymous.value', false);
const isAnonymousOrder = (order) => get(order, 'consumer_id', false) === null; // consumer_id === null on anon orders

const getIsOrderAnonymous = createSelector([getOrder, getAllowAnonymousOrders], (order, allowAnonymousOrders) => {
  return isAnonymousOrder(order) && allowAnonymousOrders;
});

export default getIsOrderAnonymous;
