export const PHONE_NUMBER_REG_EXP = /^[0-9]*$/i;
export const EMAIL_REG_EXP = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|care|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|shop|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
export const URL_REG_EXP = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
/**
 * Package Code
 * @type {RegExp}
 */
export const PACKAGE_CODE_REG_EXP = /^(?!\s)[^^]{0,23}[^\s]$/;

export const COUPON_CODE_REG_EXP = /^CP./;
/**
 * Lot Number
 * @type {RegExp}
 */
export const LOT_NUMBER_REG_EXP = PACKAGE_CODE_REG_EXP;
export const ZIPCODE_REG_EXP = /^\d{5}$/;
export const ZIPCODE_US_PLUS_4_REG_EXP = /(^\d{5}$)|(^\d{5}-\d{4}$)/
export const ALPHA_NUMERIC_REG_EXP = /^[a-zA-Z0-9]*$/;
export const CA_POSTAL_CODE_REG_EXP = /^([A-Za-z]\d[A-Za-z][ ]\d[A-Za-z]\d[,]?)*$/;
export const AU_POSTAL_CODE_REG_EXP = /^\d{4}$/;
export const NZ_POSTAL_CODE_REG_EXP = AU_POSTAL_CODE_REG_EXP;
export const UY_POSTAL_CODE_REG_EXP = /^\d{5}$/;
export const CO_POSTAL_CODE_REG_EXP = /^\d{6}$/;
export const ZA_POSTAL_CODE_REG_EXP = /^\d{4}$/;
export const LS_POSTAL_CODE_REG_EXP = /^\d{3}$/;
