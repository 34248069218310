import * as types from '../constants/actionTypes';
import * as dataNames from '../constants/dataNames';
import {getUnpaginatedData} from '../actions/apiActions';
import {unsetData, pushData} from '../actions/dataActions';
import {addMessage} from '../actions/systemActions';
import * as messageTypes from '../constants/messageTypes';

// Download the notifications for a user after they select a facility.
export const setFacilityGetNotifications = store => next => action => {
  const result = next(action);
  if (action.type === types.SET_FACILITY_SUCCESS) {
    const state = store.getState();
    const userId = state.user && state.user.id;
    if (userId) {
      store.dispatch(unsetData(dataNames.notifications));
      store.dispatch(getUnpaginatedData(`/api/notifications/active/user/${userId}`, dataNames.notifications));
    }
  }
  return result;
};

export const receiveNewUserAlert = store => next => action => {
  const result = next(action);
  if (action.type === types.USER_ALERT_CREATED) {
    const state = store.getState();
    const newAlert = action.payload;
    const forCurrentFacility = !newAlert.facility_id || (state.facility && state.facility.id == newAlert.facility_id);
    if (forCurrentFacility) {
      if (!newAlert.alert_id) newAlert.alert_id = newAlert.id;
      store.dispatch(pushData([newAlert], dataNames.notifications));
      if(newAlert.alert_type === 'toastr_message') store.dispatch(addMessage(messageTypes.warning, newAlert.body, true));
    }
  }
  return result;
};

export const refundMayDiffer = store => next => action => {
  const result = next(action);
  if (action.type === types.REFUND_MAY_DIFFER) {
    store.dispatch(addMessage(messageTypes.error, 'cultivation.orderHistory.refundMayDiffer', false));
  }
  return result;
};

export const patientLimitsExceeded = store => next => action => {
  const result = next(action);
  if (action.type === types.PATIENT_LIMITS_EXCEEDED) {
    const {message, type, params} = action.payload;
    store.dispatch(addMessage(type, [message, params], false));
  }
  return result;
};
