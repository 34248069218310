import React from 'react';
import PropTypes from 'prop-types';

const InternationalNumericInputControl = ({dispatch, name, value, onChange, onBlur, fractionPartSize, shouldShowTrailingZeros, allowNegativeNumber, shouldRound, dirty, disabled, readOnly, internationalNumberFormat, input, ...props}) => {

  //To handle international number formats
  const decimalSeparator = internationalNumberFormat  === 'NUMERICAL_SEPARATOR_COMMA' ? '.' : ',';
  const regexNumberFormat = internationalNumberFormat === 'NUMERICAL_SEPARATOR_COMMA' ? (allowNegativeNumber ? /^-?\d*\.?\d*$/ : /^\d*\.?\d*$/) : (allowNegativeNumber ? /^-?\d*,?\d*$/ : /^\d*,?\d*$/);


  if (value !== undefined && value !== null && typeof value !== String) {
    value = value.toString();
  }

  if (value && value.length && (!dirty || disabled || readOnly) && fractionPartSize >= 0 ) {

    if (shouldRound) {
      // console.log('Rounding');
      if (value.includes(decimalSeparator)) {
        value = value.replace(decimalSeparator, '.');
        value = parseFloat(value).toFixed(fractionPartSize).replace('.', decimalSeparator);
      }
    }
    else {
      const [integerPart, decimalPart] = value.split(decimalSeparator);

      if (shouldShowTrailingZeros && (decimalPart && decimalPart.length < fractionPartSize || !decimalPart)) {
        // console.log('Showing Trailing Zeros');
        value = value.replace(decimalSeparator, '.');
        value = parseFloat(value).toFixed(fractionPartSize).replace('.', decimalSeparator);
      }
      else if (decimalPart !== undefined && decimalPart.length > 0 && fractionPartSize > 0) {
        value = `${integerPart}${decimalSeparator}${decimalPart.slice(0, fractionPartSize)}`;
      } else if(fractionPartSize === 0){
        value = `${integerPart}`;
      }
    }
  }

  const onChangeHandlers = (value) => {
    // if they're not equal, a custom onChange handler exists, and both onChange handlers need to be called
    if (input && input.onChange !== onChange) {
      input.onChange(value);
    }

    onChange(value);
  };


  const parseHandle = event => {
    const {value} = event.target;
    const [integerPart, decimalPart] = value.split(decimalSeparator);

    if ((regexNumberFormat.test(value)) || !value) {
      let transformedValue;

      if (decimalPart !== undefined && decimalPart.length > 0 && fractionPartSize > 0) {
        transformedValue = `${integerPart}${decimalSeparator}${decimalPart.slice(0, fractionPartSize)}`;
      } else if(fractionPartSize === 0){
        transformedValue = `${integerPart}`;
      } else {
        transformedValue = `${value}`;
      }

      onChangeHandlers(transformedValue);
    } else {
      event.preventDefault();
    }
  };

  const handleKeypress = event => {
    if (
      event.key.match('Enter') ||
      event.key.match(/\d{1}/) ||
      event.key == '-' && allowNegativeNumber ||
      event.key == decimalSeparator )
    {
      return true;
    }
    event.preventDefault();
  };

  const handlePaste = event => {
    const value = (event.clipboardData || window.clipboardData).getData('text');
    if(!regexNumberFormat.test(value)) {
      event.preventDefault();
    }
  };

  props.className = 'integer-part form-control' + (props.className ? ' ' + props.className : '');
  delete props.formName;

  return (
    <div className='decimal-numeric-input'>
      <input
        name={name}
        value={value !== undefined && value !== null ? value : ''}
        disabled={disabled}
        readOnly={readOnly}
        onChange={parseHandle}
        onKeyPress={handleKeypress}
        onPaste={handlePaste}
        onBlur={event => {
          onBlur(shouldRound && !dirty ? undefined : event);
        }}
        {...props}
      />
    </div>
  );
};

InternationalNumericInputControl.defaultProps = {
  fractionPartSize: -1,
  shouldRound: false,
  disabled: false,
  readOnly: false,
  shouldShowTrailingZeros: false
};

InternationalNumericInputControl.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  fractionPartSize: PropTypes.number,
  name: PropTypes.string.isRequired,
  lastChar: PropTypes.string,
  allowNegativeNumber: PropTypes.bool,
  shouldRound: PropTypes.bool,
  onBlur: PropTypes.func,
  dirty: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  internationalNumberFormat: PropTypes.string,
  shouldShowTrailingZeros: PropTypes.bool,
  dispatch: PropTypes.func,
  formName: PropTypes.string,
};

export default InternationalNumericInputControl;
