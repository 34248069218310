import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

class Loading extends React.Component {
  constructor(props, context) {
    super(props, context);
    const { message, classes, iconClasses} = this.props;

    const defaultIconClasses = ['spin'];
    if (classes && Array.isArray(iconClasses)) {
      defaultIconClasses.concat(iconClasses);
    }

    this.state = {
      wrapperClasses: classes,
      iconClasses: defaultIconClasses.join(' '),
      message: message
    };
  }

  render() {
    const Wrapper = this.props.wrapper || 'div';
    const {message, wrapperClasses, iconClasses} = this.state;
    return (
      <Wrapper className={wrapperClasses}>
        <FontAwesomeIcon icon={faSpinner} className={iconClasses}/>
        &nbsp;
        {this.props.children || message}
      </Wrapper>
    );
  }
}

export default Loading;
