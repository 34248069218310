import {requiredFieldValidation} from '../../common/form/redux-form/validations';

export const validate = (values, ownProps) => {
  const errors = {
    enable_manifest_generation: requiredFieldValidation(values.enable_manifest_generation)
  };

  if (ownProps.isDispensary) {
    errors.enable_workflow = requiredFieldValidation(values.enable_workflow);
  }

  return errors;
};

export default validate;
