import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Col, Row, Button} from 'react-bootstrap';
import MultiselectInput from '../common/form/MultiselectInput';
import SelectInput from '../common/form/SelectInput';
import TextInput from '../common/form/TextInput';
import InternationalCurrencyInput from '../common/form/InternationalCurrencyInput';
import InlineCheckBox from '../common/form/InlineCheckBox';

const RegisterFields = ({registers, statuses, registerTypes, setShowIsDefaultFlag, handlePosabitClick, showIsDefault, isPosabitIntegrator, isPosabitIntegrationEnabled, isAlleavesIntegrator, isAlleavesIntegrationEnabled, alleavesRegisterIds}) => {
  const alleavesRegisterOptions = [
    ...[{text: I18n.t('registers.form.notApplicable'), value: ''}],
    ...alleavesRegisterIds.map((terminal) => ({text: `${I18n.t('facility.setup.thirdPartyIntegration.alleaves.' + terminal.mode)} - ${terminal.register_id}`, value: terminal.register_id + '-' + terminal.mode}))
  ];
  return (
    <div className='register-fields'>
      <Row className='form-fields-row'>
        <Col className='form-fields-col' xs={8} md={3} lg={2}>
          <Field
            name='name' component={TextInput}
            props={{
              isRequired: true,
              label: I18n.t('registers.form.registerName')
            }}/>
        </Col>
        <Col className='form-fields-col' xs={8} md={3} lg={2}>
          <Field
            name='type' component={SelectInput}
            props={{
              label: I18n.t('registers.form.registerFor'),
              placeholder: I18n.t('common.form.selectPlaceholder'),
              options: registerTypes,
              isRequired: true,
              textKey: 'text',
              valueKey: 'value',
              onClick: setShowIsDefaultFlag
            }}/>
        </Col>
        <Col xs={8} md={3} lg={2}>
          <Field name='active' component={SelectInput} props={{
            label: I18n.t('registers.form.status'),
            isRequired: true,
            options: statuses
          }}/>
        </Col>
        {isPosabitIntegrationEnabled &&
          <Col xs={8} md={3} lg={2}>
            <Field name='terminal_id' component={TextInput} props={{
              label: I18n.t('registers.form.terminalId'),
              isRequired: false,
              disabled: !isPosabitIntegrator
            }}/>
            <span>
              {I18n.t('registers.posabit_info_message')}
              <Button variant='link' onClick={() => handlePosabitClick()}>
                {I18n.t('registers.posabit_info_link')}
              </Button>
            </span>
          </Col>
        }
        {isAlleavesIntegrationEnabled && isAlleavesIntegrator &&
          <Col xs={8} md={3} lg={2}>
            <Field name='alleaves_register_id' component={SelectInput} props={{
              label: I18n.t('registers.form.alleavesRegisterId'),
              isRequired: false,
              options: alleavesRegisterOptions
            }}/>
          </Col>
        }
      </Row>
      <Row className='form-fields-row currency'>
        <Col className='form-fields-col' xs={8} md={3} lg={2}>
          <InternationalCurrencyInput
            name='alert_above'
            props={{
              fractionPartSize: 2,
              label: I18n.t('registers.form.alertAbove'),
            }}/>
        </Col>
        <Col className='form-fields-col' xs={8} md={3} lg={2}>
          <InternationalCurrencyInput
            name='alert_below'
            props={{
              fractionPartSize: 2,
              label: I18n.t('registers.form.alertBelow'),
            }}/>
        </Col>
        <Col className='form-fields-col' xs={8} md={3} lg={2}>
          <Field
            name='collects_from_ids' component={MultiselectInput}
            props={{
              label: I18n.t('registers.form.collectsFrom'),
              placeholder: I18n.t('common.form.selectPlaceholder'),
              options: registers,
              textKey: 'name',
              valueKey: 'id',
            }}/>
        </Col>
        {showIsDefault
          ? <Col className='form-horizontal' xs={8} md={3} lg={2}>
            <Field name='is_default_for_pos' component={InlineCheckBox} props={{
              label: I18n.t('registers.form.isDefault'),
            }}/>
          </Col>
          : null}
      </Row>
    </div>
  );
};

RegisterFields.propTypes = {
  registers: PropTypes.array.isRequired,
  statuses: PropTypes.array.isRequired,
  registerTypes: PropTypes.array.isRequired,
  setShowIsDefaultFlag: PropTypes.func,
  handlePosabitClick: PropTypes.func,
  showIsDefault: PropTypes.bool,
  isPosabitIntegrator: PropTypes.bool,
  isPosabitIntegrationEnabled: PropTypes.bool,
  isAlleavesIntegrationEnabled: PropTypes.bool,
  isAlleavesIntegrator: PropTypes.bool,
  alleavesRegisterIds: PropTypes.array,
};

export default RegisterFields;
