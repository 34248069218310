import values from 'lodash.values';
import * as dataNames from './dataNames';
import * as itemNames from './itemNames';
import getSelectedDataName from './helpers/getSelectedDataName';
import defaultLang from '../../data/i18n/default';

const items = {};
values(itemNames).map(name => items[name] = {});
const data = {};
values(dataNames).map(name => data[name] = []);
const selectedData = {};
values(dataNames).map(name => selectedData[getSelectedDataName(name)] = []);

export default Object.assign({}, items, data, selectedData, {
  user:{},
  selectedUser:{},
  currentPlantForm: {
    planting_batch: '',
    grandmother_plant: '',
    ending_tag: ''
  },
  facilityDetailed: null,
  facility: {},
  facilitySwitch: {inProgress: false, message: null},
  facilities: [],
  pagination: {},
  partner: {},
  partnerFacilities: [],
  customersQueue: [],
  customerGroups: [],
  customerFilters:[],
  productFilter:'',
  headers:{},
  harvests: [],
  harvestBatch: {},
  packageCodes: [],
  packageCodeWeightName: {
    weight_name: ''
  },
  selectedPackageInfo: {
    strain: '',
    weight: '',
    vendor: ''
  },
  prepackWeights: [],
  pricingGroups: [],
  messages: [],
  i18n:{
    locale: Object.keys(defaultLang)[0],
    translations: defaultLang
  },
  spinner: 0,
  menus: {
    mainNavMenuOpen: false,
    appBarOpen:false,
    userOptsMenuOpen: false,
    facilityMenuOpen: false,
    custDetailsOpen: false,
    cartDetailsOpen: false,
    limitsDetailsOpen: false,
  },
  cart: {
    coupons: [],
    appliedCoupons: [],
    coupon: null,
    checkout: false,
    currentOrder: {},
    items: [],
    pricingWeights: [],
    packageIds: [],
    taxRate: .05,
    manualDiscount: 0,
    payment: {
      credit: 0,
      check: 0,
      cash: 0,
      debit: 0,
      giftCard: 0,
      storeCredit: 0
    },
    assignDriverOpen: false
  },
  // @TODO: Remove temporaryCart after cart can get order and patient tax info type from redux.
  temporaryCart: {
    order: {
      type: ''
    },
    customer: {}
  },
  system: {
    environment:''
  },
  timezone: '',
  meta: {},
  expirePasswordsSettings: {},
  dataUpdated: {},
  mmePartnersCertNumber: [],
  provinces: [],
  countries: [],
  printJob: {},
  productLookup: {
    isModalOpen: false,
    confirmed: false,
    doSearch: false,
    itemMasterId: null,
    itemMasterName: null,
    itemMasterStrainId: null,
    isMedicated: true,
    referenceId: null,
    categoryId: null,
    subCategoryId: null,
  },
  filteredProducts: [],
  massModifyFormData: {},
  inventoryTypes: [],
  featureToggles: {},
  openForms: {},
  integrationTrackingStatusModal: {},
  useEntityLocks: false,
  merchantProfile: null,
  merchantConfigurationCompany: null,
  [itemNames.closedLoopFacility]: null
});
