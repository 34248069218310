/* eslint-disable import/prefer-default-export */
import {change, getFormValues} from 'redux-form';
import {TOTAL_TERPENE_KEY} from '../../constants/testResults';

export const calculateTerpeneTotals = (formName, columns) => (dispatch, getState) => {
  const formValues = getFormValues(formName)(getState());
  let totalTerpenesPercent = 0;
  let totalTerpenesWeight = 0;

  for (const column of columns) {
    const columnWeight = parseFloat(formValues[`${column.key}_mg_g`]);
    const columnPercent = parseFloat(formValues[`${column.key}_percent`]);

    totalTerpenesWeight += isNaN(columnWeight) ? 0 : columnWeight;
    totalTerpenesPercent += isNaN(columnPercent) ? 0 : columnPercent;
  }

  dispatch(change(formName, `${TOTAL_TERPENE_KEY}_mg_g`, totalTerpenesWeight));
  dispatch(change(formName, `${TOTAL_TERPENE_KEY}_percent`, totalTerpenesPercent));
};
