import plantForm from './plantFormMiddleware';
import salesTransferForm from './salesTransferFormMiddleware';
import facilityGroupForm  from './facilityGroupFormMiddleware';
import productForm from './productFormMiddleware';
import completeProcessingForm from './completeProcessingFormMiddleware';
import completeInfusionForm from './completeInfusionFormMiddleware';
import modifyPackages from './modifyPackageFormMiddleware';
import posPaymentForm from './posPaymentFormMiddleware';
import manageUserRolesForm from './manageUserRolesFormMiddleware';
import purchaseOrderFormMiddleware from './purchaseOrderFormMiddleware';
import modifyHarvestBatchFormMiddleware from './modifyHarvestBatchFormMiddleware';
import physicianFormMiddleware from './physicianFormMiddleware';
import customerFormMiddleware from './customerFormMiddleware';
import completeSeedPackagesFormMiddleware from './completeSeedPackagesFormMiddleware';
import printServerFormsMiddelware from './printServerFormsMiddleware';
import adjustRewardPoints from './rewardSettingsMiddleware';
import taxProfileForm from './taxProfileFormMiddleware';
import orderForm from './orderFormMiddleware';
import packageMotherPlantsForm from './packageMotherPlantsFormMiddleware';
import splitPackageForm from './splitPackageFormMiddleware';
import startInfusionForm from './startInfusionFormMiddleware';
import harvestBatchSplitForm from './harvestBatchSplitFormMiddleware';
import plantSplitForm from './plantSplitFormMiddleware';
import productMassModifyForm from './productMassModifyFormMiddleware';
import connectsRequestForm from './connectsFormMiddleware';
import stateIntegrationSettingsForm from './stateIntegrationSettingsFormMiddleware';
import completePackageForm from './completePackageFormMiddleware';
import inventoryReceiptForm from './fillPurchaseOrderFormMiddleware';
import salesOrderForm from './salesOrderFormMiddleware';
import taxFormMiddleware from './taxFormMiddleware';
import propagationMaterialForm from './propagationMaterialFormMiddleware';
import setupSalesLocationsForm from './setupSalesLocationsFormMiddleware';
import reconciliationForm from './reconciliationFormMiddleware';
import registerForm from './registerFormMIddleware';
import transferCashForm from './transferCashFormMiddleware';
import internalTransfersSettingsForm from './internalTransfersSettingsFormMiddleware';
import internalTransfersForm from './internalTransfersForm';
import selectPackageFacilityWeightForm from './selectPackageFacilityWeightFormMiddleware';
import pricingGroupFormMiddleware from './pricingGroupFormMiddleware'; //eslint-disable-line
import harvestPlantsMiddleware from './harvestPlantsMiddleware';
import assembliesFormMiddleware from './assembliesFormMiddleware';
import processingTypeFormMiddleware from './processingTypeFormMiddleware';
import preferredMixFormMiddleware from './preferredMixFormMiddleware';
import startProcessingFormMiddleware from './startProcessingFormMiddleware';
import packagePlantsFormMiddleware from './packagePlantsFormMiddleware';

export default [
  plantForm,
  salesTransferForm,
  facilityGroupForm,
  productForm,
  completeProcessingForm,
  completeInfusionForm,
  modifyPackages,
  posPaymentForm,
  manageUserRolesForm,
  purchaseOrderFormMiddleware,
  modifyHarvestBatchFormMiddleware,
  physicianFormMiddleware,
  customerFormMiddleware,
  completeSeedPackagesFormMiddleware,
  printServerFormsMiddelware,
  adjustRewardPoints,
  taxProfileForm,
  orderForm,
  harvestBatchSplitForm,
  orderForm,
  splitPackageForm,
  startInfusionForm,
  packageMotherPlantsForm,
  plantSplitForm,
  productMassModifyForm,
  connectsRequestForm,
  stateIntegrationSettingsForm,
  completePackageForm,
  inventoryReceiptForm,
  salesOrderForm,
  taxFormMiddleware,
  propagationMaterialForm,
  setupSalesLocationsForm,
  reconciliationForm,
  registerForm,
  transferCashForm,
  internalTransfersSettingsForm,
  internalTransfersForm,
  selectPackageFacilityWeightForm,
  pricingGroupFormMiddleware,
  harvestPlantsMiddleware,
  assembliesFormMiddleware,
  processingTypeFormMiddleware,
  preferredMixFormMiddleware,
  startProcessingFormMiddleware,
  packagePlantsFormMiddleware
];
