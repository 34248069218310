import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {submit} from 'redux-form';
import {bindActionCreators} from 'redux';
import * as dataNames from '../../../constants/dataNames';
import {getFlattenedStorageLocations, getFlattenedCultivationLocations} from '../../../selectors/locationsSelectors';
import {getUnpaginatedData, postData} from '../../../actions/apiActions';
import DailyWasteForm from './DailyWasteForm';
import {DAILY_WASTE_FORM} from '../../../constants/forms';

class DailyWastePage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      doFormSubmit: 0,
    };
  }

  componentDidMount() {
    const { facilityType } = this.props;
    if ( facilityType && facilityType === 'grow'){
      this.props.actions.getUnpaginatedData('/api/cultivation/buildings', dataNames.cultivationLocations, {failed: 'locations.getLocations.failed'}, {active: 1, paginate: 0});
    }
  }

  componentWillReceiveProps(nextProps){
    if(this.state.doFormSubmit !== nextProps.doFormSubmit) {
      this.setState({doFormSubmit: nextProps.doFormSubmit}, () => {
        this.props.actions.submit(DAILY_WASTE_FORM);
      });
    }
  }

  onSubmit(formData) {
    const payload = Object.assign({}, formData, {
      source_type: 'location',
      source_ids: [formData.source_id],
      uom: 'GR',
    });
    if(formData.source_id === null) return false; // Occurs on second load for some reason submit does not run the redux form validator
    if(formData.waste_weight === 0) return false;
    this.props.actions.postData('/api/waste', payload, dataNames.waste, {success: 'collectWaste.create.success'})
      .then(() => {
        this.props.closeModal(); // on success of submit
      });
  }

  render() {
    return (<div>
        <DailyWasteForm initialValues={this.props.initialValues} storageLocations={this.props.storageLocations} onSubmit={this.onSubmit} doFormSubmit={this.state.doFormSubmit} />
      </div>);
  }
}

DailyWastePage.propTypes = {
  actions: PropTypes.object,
  initialValues: PropTypes.object,
  storageLocations: PropTypes.array,
  closeModal: PropTypes.func,
};

function mapStateToProps(state) {
  const facilityType = state.facility && state.facility.type || null;
  return {
    initialValues: {waste_weight: 0},
    storageLocations: facilityType === 'manufacturing' ? getFlattenedStorageLocations(state) : getFlattenedCultivationLocations(state),
    facilityType,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    getUnpaginatedData,
    postData,
    submit,
  };
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DailyWastePage);




