import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import {FaTrash} from 'react-icons/fa';
import InternationalCurrencyInput from '../../../common/form/InternationalCurrencyInput';
import InternationalQuantityByUomInput from '../../../common/form/InternationalQuantityByUomInput';
import InternationalDecimalStatic from '../../../common/form/InternationalDecimalStatic';
import {UOM_UNIT_VALUE_PRECISION} from '../../../../constants/uoms';
import InternationalCurrencyStatic from '../../../common/form/InternationalCurrencyStatic';

const UnitLineItem = ({line, change, isActive, removeFromPo, index, transferRecord, itemMaster, lineItem, metrcIsImported, uom}) => {

  const getStaticDisplayData = () => {
    return (<Col lg={8}>
      <Row>
        <Col lg={2}>
          <label>{I18n.t('purchaseOrders.form.requestedAmount')}</label>
          <InternationalDecimalStatic fractionPartSize={2}>{lineItem.qty}</InternationalDecimalStatic>
        </Col>
        <Col lg={2}>
          <label>{I18n.t('purchaseOrders.form.unitPrice')}</label>
          <InternationalCurrencyStatic>{lineItem.unit_price}</InternationalCurrencyStatic>
        </Col>q
        <Col lg={3} offset={4}>
          <label>{I18n.t('purchaseOrders.form.itemPrice')}</label>
          <InternationalCurrencyStatic>{lineItem.line_item_price}</InternationalCurrencyStatic>
        </Col>
      </Row>
    </Col>);
  };

  const getEditableData = () => { //eslint-disable-line
    return (<Col lg={8}>
      <Row>
        <Col lg={3}>
          <InternationalQuantityByUomInput
            name={`${line}.qty`}
            label={I18n.t('purchaseOrders.form.numUnits')}
            uom={uom}
            fractionPartSize={UOM_UNIT_VALUE_PRECISION}
            isRequired={true}
            disabled={!isActive || metrcIsImported}
          />
        </Col>
        <Col lg={3}>
          {
            !itemMaster.inventory_attributes.is_ingredient
              ? (
                <InternationalCurrencyInput
                  name={`${line}.unit_price`}
                  props={{
                    label: I18n.t('purchaseOrders.form.unitPrice'),
                    isRequired: true,
                    disabled: !isActive,
                  }}
                />)
              : null
          }
        </Col>
        <Col lg={3}>
          <InternationalCurrencyInput
            name={`${line}.line_item_price`}
            props={{
              label: I18n.t('purchaseOrders.form.itemPrice'),
              disabled: true
            }}
          />
        </Col>
        <Col lg={1}>
          <div className='form-input form-group'>
            <label className='control-label' style={{width: '100%'}}>&nbsp;</label>
            <button disabled={transferRecord} className='btn btn-small btn-block' onClick={(event) => removeFromPo(index)}>
              <FaTrash style={{marginRight: '4px'}} />
            </button>
          </div>
        </Col>
      </Row>
    </Col>);
  };

  return !lineItem.showEditors && lineItem.showEditors !== undefined
    ? getStaticDisplayData()
    : getEditableData();

};

UnitLineItem.propTypes = {
  change: PropTypes.func.isRequired,
  line: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  removeFromPo: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  transferRecord: PropTypes.bool.isRequired,
  itemMaster: PropTypes.object.isRequired,
  lineItem: PropTypes.object.isRequired,
  metrcIsImported: PropTypes.bool.isRequired,
};

UnitLineItem.defaultProps = {
  isActive: true
};

export default UnitLineItem;
