import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {change, reset, getFormValues} from 'redux-form';
import {goBack, push} from 'react-router-redux';
import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';
import FormWrapper from '../../../common/form/FormWrapper';
import ConnectedSupplyChainMappingForm from './SupplyChainMappingForm';
import {getDataByPost, getItem, getUnpaginatedData, postItem} from '../../../../actions/apiActions';
import * as dataNames from '../../../../constants/dataNames';
import * as itemNames from '../../../../constants/itemNames';
import * as mappingSelectors from '../../../../selectors/MetrcSupplyChainMappingSelectors';
import {getIntegrationState} from '../../../../selectors/integration/integrationSelectors';
import InProgressOverlay from '../../../common/InProgressOverlay';
import {SUPPLY_CHAIN_MAPPING_FORM_METRC} from '../../../../constants/forms';
import {setItem, unsetItem} from '../../../../actions/itemActions';
import {unsetData} from '../../../../actions/dataActions';
import {addMessage} from '../../../../actions/systemActions';
import * as messageTypes from '../../../../constants/messageTypes';

export class SupplyChainMappingPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.title = 'supplyChainMapping.title.incoming';
    this.loadingMessage = I18n.t('supplyChainMapping.form.loadingMetrc');
    this.onSubmit = this.onSubmit.bind(this);
    this.onIncomingTransferChange = this.onIncomingTransferChange.bind(this);
    this.onAutoGeneratePurchaseOrderChange = this.onAutoGeneratePurchaseOrderChange.bind(this);
    this.onPurchaseOrderChange = this.onPurchaseOrderChange.bind(this);
    this.clearInvalidMappings = this.clearInvalidMappings.bind(this);
    this.getCreatePurchaseOrderPayload = this.getCreatePurchaseOrderPayload.bind(this);

    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    // Fetch incoming transfers
    this.props.actions.getUnpaginatedData('/api/integrated_transfers/incoming', dataNames.incomingTransfers, {failed: 'supplyChainMapping.integratedTransfers.get.failed'})
      // eslint-disable-next-line react/no-did-mount-set-state
      .then(() => this.setState({loading: false}));
  }

  componentDidUpdate(nextProps, nextState) {
  }

  componentWillUnmount() {

  }

  onSubmit(formValues) {
    // Store mapping in state
    this.props.actions.setItem(formValues, itemNames.supplyChainMapping);

    // Don't create PO, redirect to Receipts page
    if (!formValues.auto_generate_po) {
      // Check if we have a PO
      const po_num = get(formValues, 'purchase_order_id', 0);
      if (po_num === 0) {
        // Thrown error
        this.props.actions.addMessage(messageTypes.error, 'supplyChainMapping.form.errorGeneratingPurchaseOrder');
        return;
      }
      // Redirect to Inventory receipt page
      this.props.actions.push(`/inventory-receipts/metrc?po_num=${po_num}`);
      return;
    }

    const payload = this.getCreatePurchaseOrderPayload(formValues);
    // Create PO
    this.props.actions.postItem('/api/purchase_orders', payload, itemNames.purchaseOrder, {failed: 'supplyChainMapping.form.errorGeneratingPurchaseOrder'}, {detailed: true})
      .then((purchaseOrder) => {
        this.props.actions.push(`/inventory-receipts/metrc?po_num=${purchaseOrder.id}`);
      });
  }

  getCreatePurchaseOrderPayload(formValues) {
    // We need some values from the partnerFacility object
    const partnerFacilities = get(this.props, 'partnerFacilities', []);
    const partnerFacility = partnerFacilities.find((pf) => pf.id === formValues.vendor_facility_id);
    const payload = {
      partner_id: partnerFacility.partner_id,
      vendor_facility_id: formValues.vendor_facility_id,
      contains_medicated: true,
    };
    // Add purchase order lines
    // TODO: see if we can clean this up a bit
    const lines = [];
    formValues.product_mapping.forEach((pm_line, index) => {

      // Calculate quantity
      let qty;
      const pkg = this.props.incomingTransferDetails.packages[index];
      if (pkg.transfer_uom === 'GR' && pm_line.item_master.default_uom === 'EA') {
        const med_weight_uom = get(pm_line.item_master, 'medicated_weight_uom_display');
        const med_weight = get(pm_line.item_master, 'medicated_weight_base', 0) / 1000000;
        // Return if no med_weight_uom or med_weight
        if (med_weight_uom && med_weight) {
          qty = pkg.transfer_qty / med_weight;
        }
      }
      qty = pkg.transfer_qty;

      // Calculate cost
      let unit_price;
      let line_item_price;
      // Get Vendor
      const vendors = get(pm_line, 'item_master.vendors', []);
      const vendor = vendors.find((vendor) => vendor.partner_id === partnerFacility.partner_id);
      if (vendor) {
        // Get unit price from item master for vendor
        unit_price = get(vendor, 'default_unit_cost');
        if (unit_price) {
          line_item_price = qty * unit_price;
        }
      }

      lines.push(
        {
          item_master_id: pm_line.item_master.id,
          uom: pm_line.item_master.default_uom,
          qty,
          unit_price,
          line_item_price
        }
      );
    });
    // Add lines to payload
    payload.lines = lines;
    return payload;
  }

  getItemMasterMappings(incomingTransferDetails) {
    // Collect metrc_item_ids from packages
    let metrc_item_ids = incomingTransferDetails.packages.map(pkg => get(pkg, 'metrc_item_id'));
    metrc_item_ids = [... new Set(metrc_item_ids)];

    const vendor_id = get(incomingTransferDetails, 'transfer_from_license');

    // Get all previous mappings for metrc_item_ids
    const params = {
      item_ids: metrc_item_ids,
      vendor_id
    };
    this.props.actions.getDataByPost('/api/integration/partner_item_master_mapping/by_external_ids', params, dataNames.itemMasterMappings);
  }

  getItemMastersForIncomingTransfer(incomingTransferDetails, partnerId) {
    // Collect mjp_category_ids
    let mjp_category_ids = [];
    incomingTransferDetails.packages.forEach(pkg => {
      const pkg_ids_string = get(pkg, 'mjp_category_ids');
      if (pkg_ids_string) {
        const pkg_ids = pkg_ids_string.split(',');
        mjp_category_ids = [...mjp_category_ids, ...pkg_ids];
      }
    });
    mjp_category_ids = [... new Set(mjp_category_ids)];

    // Get all item masters for mjp_category_ids
    const params = {
      select_columns: ['id', 'active', 'item_number', 'name', 'category', 'category_id', 'subcategory_id', 'inventory_attributes', 'default_uom', 'medicated_weight_base', 'medicated_weight_uom_display', 'strain_id', 'test_results_source', 'potency_test_results', 'terpene_test_results'],
      active: 1,
      detailed: 1,
      is_purchasing_item: 1,
      in_category_ids: mjp_category_ids,
      with: ['strain.globalStrain']
    };
    if (partnerId) {
      params.vendor_id = partnerId;
    }
    this.props.actions.getUnpaginatedData('/api/item_masters', dataNames.itemMasters, null, params)
      .then((itemMasters) => {
        // clear invalid options
        this.clearInvalidMappings(itemMasters);
      });
  }

  getItemMastersForPurchaseOrder(purchaseOrder) {
    // Get all item masters for mjp_category_ids
    const ids = purchaseOrder.lines.map((line) => line.item_master_id);

    const params = {
      active: 1,
      detailed: 1,
      is_purchasing_item: 1,
      in_ids: ids
    };
    this.props.actions.getUnpaginatedData('/api/item_masters', dataNames.itemMasters, null, params)
      .then((itemMasters) => {
        // clear invalid options
        this.clearInvalidMappings(itemMasters);
      });
  }

  onIncomingTransferChange(value) {

    this.props.actions.unsetItem(itemNames.incomingTransferDetails);
    if (!value) {
      return;
    }
    // Inform user we're loading more data from metrc
    this.loadingMessage = I18n.t('supplyChainMapping.form.loadingMetrcDetails');
    this.setState({loading: true});

    this.props.actions.getItem(`/api/integrated_transfers/incoming/${value}`, itemNames.incomingTransferDetails, {failed: 'supplyChainMapping.integratedTransfers.get.failed'})
      .then((incomingTransferDetails) => {
        //Get partner facility/facilities for vendor license
        const vendor_id = get(incomingTransferDetails, 'transfer_from_license');
        this.props.actions.getUnpaginatedData(`/api/partner_facilities/state_license/${vendor_id}`, dataNames.partnerFacilities)
          .then((partnerFacilities) => {
            // If only one partner facility, set the 'vendor_facility_id'
            if (partnerFacilities.length === 1) {
              this.props.actions.change(SUPPLY_CHAIN_MAPPING_FORM_METRC, 'vendor_facility_id', partnerFacilities[0].id);
            }

            // If no partner facility return an error message
            if (!partnerFacilities.length) {
              this.props.actions.addMessage(messageTypes.error, I18n.t('supplyChainMapping.form.errorNoPartnerFacility', { license: vendor_id }), 'en');
              // Hide loading modal
              this.setState({loading: false});
              return;
            }

            // All is good. Load Item Master Mappings and Item Masters
            this.getItemMasterMappings(incomingTransferDetails);
            const partnerIds = partnerFacilities.map((pf) => pf.partner_id);
            const partnerId = partnerIds ? partnerIds[0] : null;
            this.getItemMastersForIncomingTransfer(incomingTransferDetails, partnerId);
            // Hide loading modal
            this.setState({loading: false});
          });
      });
  }

  onAutoGeneratePurchaseOrderChange(value) {
    if (!value) {
      const vendor_id = get(this.props.incomingTransferDetails, 'transfer_from_license');
      // Get open purchase orders for vendor (in case of selecting PO instead of auto-generating one)
      this.props.actions.getUnpaginatedData(`/api/purchase_orders/state_license/${vendor_id}`, dataNames.purchaseOrders, null, {received: 0, contains_medicated: 1});
    } else {
      // Reload item masters based on categories in incoming transfer
      this.getItemMastersForIncomingTransfer(this.props.incomingTransferDetails);
    }
  }

  onPurchaseOrderChange(value) {
    const purchaseOrder = this.props.purchaseOrders.find((po) => po.id = value);
    this.props.actions.setItem(purchaseOrder, itemNames.purchaseOrder);
    // For some reason the purchaseOrder props is not passed on to the form validate function.
    // Setting the purchase order in the form, so we can use it in validation
    this.props.actions.change(SUPPLY_CHAIN_MAPPING_FORM_METRC, 'purchaseOrder', purchaseOrder);
    this.getItemMastersForPurchaseOrder(purchaseOrder);
  }

  clearInvalidMappings(itemMasters) {
    const allowedItemMasterIds = itemMasters.map((im) => im.id);
    const productMappings = get(this.props.formValues, 'product_mapping', []);
    productMappings.forEach((mapping, index) => {
      const item_master_id = get(mapping, 'item_master.id');
      if (!allowedItemMasterIds.includes(item_master_id)) {
        // Clear both auto_map and item_master field for this mapping
        this.props.actions.change(SUPPLY_CHAIN_MAPPING_FORM_METRC, `product_mapping.${index}.auto_map`, null);
        this.props.actions.change(SUPPLY_CHAIN_MAPPING_FORM_METRC, `product_mapping.${index}.item_master`, null);
      }
    });
  }

  render() {
    const {
      change,
      reset,
      formErrors,
      initialValues,
      incomingTransferOptions,
      incomingTransferDetails,
      itemMasters,
      itemMasterMappings,
      purchaseOrdersOptions,
      purchaseOrder,
      partnerFacilities
    } = this.props;

    return (
      <React.Fragment>
        {get(this.state, 'loading') &&
          <InProgressOverlay isActive={true} message={this.loadingMessage}/>
        }
        <FormWrapper title={this.title} goBack={this.props.actions.goBack}>
          <ConnectedSupplyChainMappingForm
            change={change}
            reset={reset}
            onSubmit={this.onSubmit}
            formErrors={formErrors}
            initialValues={initialValues}
            incomingTransferOptions={incomingTransferOptions}
            onIncomingTransferChange={this.onIncomingTransferChange}
            onAutoGeneratePurchaseOrderChange={this.onAutoGeneratePurchaseOrderChange}
            purchaseOrdersOptions={purchaseOrdersOptions}
            onPurchaseOrderChange={this.onPurchaseOrderChange}
            incomingTransferDetails={incomingTransferDetails}
            itemMasters={itemMasters}
            itemMasterMappings={itemMasterMappings}
            purchaseOrder={purchaseOrder}
            partnerFacilities={partnerFacilities}
          />
        </FormWrapper>
      </React.Fragment>
    );
  }
}

SupplyChainMappingPage.propTypes = {
  actions: PropTypes.shape({
    change: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    setItem: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    getDataByPost: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  incomingTransferOptions: PropTypes.array.isRequired,
  incomingTransferDetails: PropTypes.object,
  itemMasters: PropTypes.array,
  itemMasterMappings: PropTypes.array,
  initialValues: PropTypes.object,
  purchaseOrder: PropTypes.object,
  partnerFacilitiesOptions: PropTypes.array
};

function mapStateToProps(state) {
  return {
    formValues: getFormValues(SUPPLY_CHAIN_MAPPING_FORM_METRC)(state),
    formErrors: mappingSelectors.errorSelector(state),
    initialValues: {auto_generate_po: true},
    integratorState: getIntegrationState(state),
    incomingTransferOptions: mappingSelectors.getIncomingTransfersOptions(state),
    incomingTransferDetails: mappingSelectors.getIncomingTransferDetails(state),
    itemMasters: state[dataNames.itemMasters],
    itemMasterMappings: state[dataNames.itemMasterMappings],
    purchaseOrdersOptions: mappingSelectors.getPurchaseOrdersOptions(state),
    purchaseOrders: state[dataNames.purchaseOrders],
    purchaseOrder: state[itemNames.purchaseOrder],
    partnerFacilities: state[dataNames.partnerFacilities]
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    getItem,
    getUnpaginatedData,
    getDataByPost,
    postItem,
    change,
    reset,
    goBack,
    unsetItem,
    unsetData,
    setItem,
    push,
    addMessage,
  };

  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplyChainMappingPage);
