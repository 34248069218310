import React from 'react';
import get from 'lodash.get';
import InternationalDecimalStatic from '../../../common/form/InternationalDecimalStatic';

//
// Metrc Sales Report table definition props
//
export const metrcSalesReportTabs = [
  {
    id: 'default',
    eventKey: 'default',
    title: 'retail.metrcSalesReport.metrcSales',
    actions: []
  }
];

export const metrcSalesReportColumns = [
  {
    name: 'retail.productSalesReport.columns.orderNumber',
    dataId: 'order_number',
    csvFormatter: (cell) => cell,
    width: '120px'
  },
  {
    name: 'retail.productSalesReport.columns.refundedOrderNumber',
    dataId: 'refunded_order_number',
    width: '120px',
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.productSalesReport.columns.patientMmid',
    dataId: 'medical_id',
    csvFormatter: (cell) => cell || ''
  },
  {
    name: 'retail.productSalesReport.columns.productName',
    dataId: 'item_master_name',
    width: 200,
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.productSalesReport.columns.category',
    dataId: 'category_name',
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.productSalesReport.columns.subcategory',
    dataId: 'subcategory_name',
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.productSalesReport.columns.packageId',
    dataId: 'package_code',
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.productSalesReport.columns.tracking_id',
    dataId:'state_tracking_id',
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.productSalesReport.columns.quantity',
    dataId: 'sold_weight',
    width: '80px',
    formatter: (cell, row) => {
      const sold_weight_uom = get(row, 'sold_weight_uom_display');
      const uom = sold_weight_uom ? sold_weight_uom : 'EA';
      return <InternationalDecimalStatic suffix={` ${uom}`}>{cell}</InternationalDecimalStatic>;
    },
    csvFormatter: (cell, row) => {
      const sold_weight = get(row, 'sold_weight');
      if (sold_weight) {
        return sold_weight;
      } else {
        return cell;
      }
    },
  },
  {
    name: 'retail.productSalesReport.columns.unitPrice',
    dataId: 'unit_price',
    width: '80px',
    /* eslint-disable react/no-multi-comp */
    formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.productSalesReport.columns.subtotal',
    dataId:'subtotal',
    width: '80px',
    /* eslint-disable react/no-multi-comp */
    formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.productSalesReport.columns.discountTotal',
    dataId: 'discount_total',
    width: '80px',
    /* eslint-disable react/no-multi-comp */
    formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.productSalesReport.columns.taxAmount',
    dataId: 'tax_total',
    /* eslint-disable react/no-multi-comp */
    formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.productSalesReport.columns.exciseTax',
    dataId: 'excise_tax',
    /* eslint-disable react/no-multi-comp */
    formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.productSalesReport.columns.stateTax',
    dataId: 'state_tax',
    /* eslint-disable react/no-multi-comp */
    formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.productSalesReport.columns.countyTax',
    dataId: 'county_tax',
    /* eslint-disable react/no-multi-comp */
    formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.productSalesReport.columns.municipalTax',
    dataId: 'municipal_tax',
    /* eslint-disable react/no-multi-comp */
    formatter: (cell) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.productSalesReport.columns.patientId',
    dataId: 'consumer_number',
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.productSalesReport.columns.completedDate',
    dataId: 'completed_date_by_facility_timezone',
    sortBy: 'completed_date_timestamp',
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.productSalesReport.columns.completedTime',
    dataId: 'completed_time_by_facility_timezone',
    sortBy: 'completed_date_timestamp',
    csvFormatter: (cell) => cell || '',
  },
  {
    name: 'retail.productSalesReport.columns.productCount',
    dataId: 'product_count',
    csvFormatter: (cell) => cell || '',
  }
];
