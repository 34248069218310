import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import get from 'lodash.get';
import {goBack, push} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import {change} from 'redux-form';
import {getSetupInitialValues} from '../../selectors/storageLocationsSelectors';
import {getLocationsPayload} from '../../selectors/salesLocationsSelectors';
import {getUnpaginatedData, postData, putData, getItem, getPaginatedData} from '../../actions/apiActions';
import {addMessage} from '../../actions/systemActions';
import {setFacilityOrganization, setFacilitySubmissionStatus} from '../../actions/facilityActions';
import * as dataNames from '../../constants/dataNames';
import FormWrapper from '../common/form/FormWrapper';
import SetupStorageLocationsFormWrapper from './SetupStorageLocationsFormWrapper';
import PrinterModal from '../printer/PrinterModal';//eslint-disable-line import/no-named-as-default
import {isLeafIntegrator, isWaLeaf} from '../../selectors/integrationSelectors';
import {isCanadaFacility} from '../../selectors/facility/getFacilityCountry';
import InProgressOverlay from '../common/InProgressOverlay';
import {getLocationNamesInUse} from '../../selectors/locationsSelectors';
import {
  isMetrcIntegrator,
  hasMetrcLocationTypes,
} from '../../selectors/integration/metrcSelectors'
import * as messageTypes from '../../constants/messageTypes';
import ConfirmUnshareLocation from '../common/locations/create/ConfirmUnshareLocation';
import ModalWrapper from '../common/ModalWrapper';
import {SETUP_STORAGE_LOCATIONS_FORM} from '../../constants/forms';
import * as itemNames from '../../constants/itemNames';

export class SetupStorageLocationsPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);

    this.state = {
      showPrinter: false,
      labelUrl: false,
      loading: false,
      showUnshareConfirmation: false,
      showUnshareConfirmationName: '',
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.printLabel = this.printLabel.bind(this);
    this.hidePrinter = this.hidePrinter.bind(this);
    this.onIsSharedChange = this.onIsSharedChange.bind(this);
    this.onUnshareConfirm = this.onUnshareConfirm.bind(this);
    this.onUnshareConfirmationHide = this.onUnshareConfirmationHide.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  componentWillMount() {
    if (this.props.actions.setFacilitySubmissionStatus) {
      this.props.actions.setFacilitySubmissionStatus(false);
    }

    if (this.props.actions.setFacilityOrganization) {
      const currentFacility = get(this.props, 'currentFacility', undefined);
      const organization_id = get(currentFacility, 'organization_id', get(currentFacility, 'organizationId', undefined));

      this.setState({loading: true});
      Promise.resolve(
        this.props.actions.setFacilityOrganization(organization_id, I18n.t('facilities.noFacilitySelectedError'))
      )
        .then(() => {
          Promise.all([
            this.props.actions.getItem('/api/facility_groups_sharings/facility_status', itemNames.facilitySharingStatus),
            this.props.actions.getUnpaginatedData('/api/location_hierarchy?is_sales_location=0&active=1', dataNames.storageLocations),
            this.props.actions.getUnpaginatedData('/api/cultivation/sections/integrated_location', dataNames.integratedLocationNames),
            this.props.actions.getUnpaginatedData('/api/integration-mapping', dataNames.integrationMapping, null, {key: 'inv_location'}),
            // TODO: I'm preeetty sure we don't need this, double check at some point.
            // Might just have been copy and pasted from SetupCultivationLocationsPage
            this.props.actions.getUnpaginatedData('/api/cultivation/sections', dataNames.sections),
          ])
            .then(() => this.setState({loading: false}))
            .catch(() => this.setState({loading: false}));
        });
    }

    if (this.props.hasMetrcLocationTypes) {
      this.props.actions.getUnpaginatedData('/api/metrc/locations/types', dataNames.metrcLocationTypes);
    }
  }

  onDelete(location, func) {
    const getChildLocationIds = (location, childIds = [], returnObjects = false) => {
      childIds.push(returnObjects ? location : location.id);
      if (location.child_locations.length) {
        location.child_locations.forEach((location) => {
          return getChildLocationIds(location, childIds, returnObjects);
        });
      }
      return childIds;
    };

    const childObjects = getChildLocationIds(location, [], true);
    const childIds = childObjects.map(object => object.id).filter(object => object);

    if (childIds.length) {
      this.props.actions.getPaginatedData('/api/items', undefined, undefined, {
        page: 1,
        per_page: 1,
        in_inventory_location_ids: childIds
      }, (items) => {
        const locationIdsInUse = (items && items.map(item => item.inventory_location_id)) || [];
        if (locationIdsInUse.length) {
          const getLocationName = (location, names = []) => {
            names.push(get(location, 'name'));
            if (location.parent_location_id !== null) {
              const nextLocation = childObjects.find((object) => object.id === location.parent_location_id);
              if (nextLocation) return getLocationName(nextLocation, names);
            }
            return names;
          };

          locationIdsInUse.forEach((locationId) => {
            const location = childObjects.find((object) => object.id === locationId);
            const names = getLocationName(location, []);
            const name = names.reverse().join(':');
            const message = I18n.t('locations.setupStorageLocations.errorOnDelete', {location: name});
            this.props.actions.addMessage(messageTypes.error, message, true);
          });
          return false;
        }
        func();
      });
    } else {
      func();
    }
  }

  printLabel(event, id, type) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
      event.target.blur();
    }

    const tagMap = {
      room: 'inv_location_room_tag',
      section: 'inv_location_section_tag',
      zone: 'inv_location_zone_tag'
    };

    const tag = tagMap[type];

    const url = `/api/labels/generate/${tag}/for/${id}`;

    this.setState({showPrinter: true, labelUrl: url});

  }

  hidePrinter() {
    this.setState({showPrinter: false});
  }

  onSubmit(formData) {

    this.props.actions.setFacilitySubmissionStatus(true);

    const {initialValues, actions: {postData, putData}} = this.props;

    const payload = getLocationsPayload(initialValues.child_locations, formData.child_locations);

    let createPromise, modifyPromise;

    if (payload.create && payload.create.length) {
      createPromise = postData(
        '/api/location_hierarchy/multiple',
        payload.create,
        dataNames.storageLocations
      );
    }

    if (payload.modify && payload.modify.length) {
      modifyPromise = putData(
        '/api/location_hierarchy/multiple',
        payload.modify,
        dataNames.storageLocations
      );
    }

    return Promise
      .resolve(createPromise)
      .then(() => modifyPromise)
      .then(() => {
        this.props.actions.addMessage(messageTypes.success, 'locations.setupStorageLocations.success');
        this.props.actions.push('/setup');
        this.props.actions.setFacilitySubmissionStatus(false);
      })
      .catch(() => {
        this.props.actions.addMessage(messageTypes.error, 'locations.setupStorageLocations.failed');
        this.props.actions.setFacilitySubmissionStatus(false);
      });
  }


  onIsSharedChange(locationName, event) {
    const locationIndex = locationName.match(/\[(\d+)\]/)[1];
    let wasInitiallyShared = false;
    if (this.props.initialValues.child_locations[locationIndex]) {
      wasInitiallyShared = this.props.initialValues.child_locations[locationIndex].is_shared;
    }
    this.props.actions.change(SETUP_STORAGE_LOCATIONS_FORM, locationName + '.unshare', '');
    if (!event.target.checked && wasInitiallyShared) {
      event.preventDefault();
      this.setState({
        showUnshareConfirmation: true,
        showUnshareConfirmationName: locationName,
      });
    }
  }

  onUnshareConfirm() {
    this.setState({showUnshareConfirmation: false});
    this.props.actions.change(SETUP_STORAGE_LOCATIONS_FORM, this.state.showUnshareConfirmationName + '.is_shared', false);
    this.props.actions.change(SETUP_STORAGE_LOCATIONS_FORM, this.state.showUnshareConfirmationName + '.unshare', '1');
  }

  onUnshareConfirmationHide() {
    this.setState({showUnshareConfirmation: false});
    this.props.actions.change(SETUP_STORAGE_LOCATIONS_FORM, this.state.showUnshareConfirmationName + '.is_shared', true);
  }


  render() {
    const {loading} = this.state;
    const {submissionStatus, integratedLocationNames, initialValues, showIntegratedLocationName, isCanadaFacility, currentFacility, isLeaf, integrationMapping, locationsNamesInUse, isMetrc, facilitySharingStatus, hasMetrcLocationTypes} = this.props;
    return (
      <FormWrapper
        title={'locations.setupStorageLocations.title'}
        goBack={this.props.actions.goBack}
        formName={SETUP_STORAGE_LOCATIONS_FORM}
        shouldWarnDirty={true}
      >
        <InProgressOverlay isActive={loading} message={I18n.t('common.loading')}/>
        <PrinterModal
          hidePrinter={this.hidePrinter}
          showPrinter={this.state.showPrinter}
          httpAction='get'
          labelUrl={this.state.labelUrl}
        />
        <ModalWrapper
          Component={ConfirmUnshareLocation}
          showModal={this.state.showUnshareConfirmation}
          onHide={this.onUnshareConfirmationHide}
          confirm={this.onUnshareConfirm}
          title={I18n.t('locations.unshareLocation.confirmationText')}
        />
        {(!loading) && (
          <SetupStorageLocationsFormWrapper
            initialValues={initialValues}
            integratedLocationNames={integratedLocationNames}
            printLabel={this.printLabel}
            onSubmit={this.onSubmit}
            showIntegratedLocationName={showIntegratedLocationName}
            isCanadaFacility={isCanadaFacility}
            currentFacility={currentFacility}
            submissionStatus={submissionStatus}
            isLeaf={isLeaf}
            isMetrc={isMetrc}
            integrationMapping={integrationMapping}
            locationsNamesInUse={locationsNamesInUse}
            onIsSharedChange={this.onIsSharedChange}
            facilitySharingStatus={facilitySharingStatus}
            onDelete={this.onDelete}
            hasMetrcLocationTypes={hasMetrcLocationTypes}
          />
        )}
      </FormWrapper>);
  }
}

SetupStorageLocationsPage.propTypes = {
  actions: PropTypes.shape({
    getUnpaginatedData: PropTypes.func.isRequired,
    setFacilitySubmissionStatus: PropTypes.func.isRequired,
    setFacilityOrganization: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    putData: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    addMessage: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    getPaginatedData: PropTypes.func.isRequired,
  }).isRequired,
  initialValues: PropTypes.object.isRequired,
  integratedLocationNames: PropTypes.array.isRequired,
  showIntegratedLocationName: PropTypes.bool.isRequired,
  isCanadaFacility: PropTypes.bool,
  currentFacility: PropTypes.object.isRequired,
  submissionStatus: PropTypes.bool.isRequired,
  isLeaf: PropTypes.bool,
  isMetrc: PropTypes.bool,
  integrationMapping: PropTypes.array,
  locationsNamesInUse: PropTypes.array.isRequired,
  facilitySharingStatus: PropTypes.object,
  hasMetrcLocationTypes: PropTypes.bool,
};

function mapStateToProps(state) {
  const {facility, integratedLocationNames, integrationMapping, facilitySharingStatus} = state;
  const locationsNamesInUse = getLocationNamesInUse(state);

  return {
    initialValues: getSetupInitialValues(state),
    integratedLocationNames,
    currentFacility: facility,
    submissionStatus: (facility.submissionStatus || false),
    showIntegratedLocationName: isWaLeaf(state),
    isCanadaFacility: isCanadaFacility(state),
    isLeaf: isLeafIntegrator(state),
    isMetrc: isMetrcIntegrator(state),
    integrationMapping,
    locationsNamesInUse,
    facilitySharingStatus,
    hasMetrcLocationTypes: hasMetrcLocationTypes(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    setFacilityOrganization,
    setFacilitySubmissionStatus,
    addMessage,
    getUnpaginatedData,
    postData,
    putData,
    goBack,
    push,
    change,
    getItem,
    getPaginatedData,
  };
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SetupStorageLocationsPage);
