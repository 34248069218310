import {change} from 'redux-form';

import {PHYSICIAN_FORM} from '../../constants/forms';
import {isFormChangeOnField} from './utils';

const physicianForm = store => next => action => {
  const {payload, meta} = action;

  if (isFormChangeOnField(action, [PHYSICIAN_FORM], 'middle_name')) {
    if (payload && payload.length > 1) {
      return store.dispatch(change(meta.form, 'middle_name', payload && payload[0] || null));
    }
  }

  if (isFormChangeOnField(action, [PHYSICIAN_FORM], 'country_code')) {
    const key = meta.field.split('.').shift();
    store.dispatch(change(meta.form, `${key}.province_code`, null));
  }

  return next(action);
};

export default physicianForm;
