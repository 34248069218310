import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import get from 'lodash.get';
import { Row, Col } from 'react-bootstrap';
import {FaTimes} from 'react-icons/fa';
import TextAreaInput from '../../common/form/TextAreaInput';
import AddItem from '../../common/form/AddItem';
import TextInput from '../../common/form/TextInput';

const NotesFieldArray = ({ fields, notes }) => {
  return (
    <div>
      <h4>{I18n.t('ei.notesField.title')}</h4>
      <Row>
        <Col md={12} className='notes-wrapper'>
          {fields.map((name, index) => {
            const note = notes[index];
            const noteId = get(note, 'id', false);
            return (
              <div key={index}>
                <Field  name={`${name}.note`} component={TextAreaInput} props={{
                  placeholder: I18n.t('ei.notesField.notePlaceholder'),
                  rows: 1,
                  disabled: noteId,
                  rightAddon: !noteId && (
                    <FaTimes className='go-back-x' onClick={() => { fields.remove(index); }} />
                  )
                }} />
                <Field
                  name={`${name}.id`}
                  component={TextInput}
                  style={{display: 'none'}}
                  props={{
                    groupClassName: 'hidden'
                  }}
                />
              </div>
            );
          })}
        </Col>
      </Row>
      <Row>
        <AddItem
          textMode='left'
          alignMode='right'
          addAction={() => fields.push({})}
          text={'ei.notesField.add'}
        />
      </Row>
    </div>
  );
};

NotesFieldArray.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    get: PropTypes.func.isRequired,
  }).isRequired,
  formName: PropTypes.string,
  notes: PropTypes.array
};

export default NotesFieldArray;
