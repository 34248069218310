import {I18n} from 'react-redux-i18n';
import {requiredFieldValidation} from '../../common/form/redux-form/validations';

export const validate = values => {
  const errors = {};
  if (values.option !== 3) {
    errors.option = I18n.t('some.form.selectThirdOption');
  }
  errors.name = requiredFieldValidation(values.name, 'ingredients.form.name');
  errors.ingredient_category_id = requiredFieldValidation(values.ingredient_category_id, 'ingredients.form.category');
  errors.ingredient_storage_location = requiredFieldValidation(values.ingredient_storage_location, 'ingredients.form.storageLocation');
  if(values.vendors && values.vendors[0]){
    errors.vendors = values.vendors.map((vendor, index) => {
      return {
        partner_id: requiredFieldValidation(values.vendors[index].partner_id, 'ingredients.form.supplier'),
        default_uom: requiredFieldValidation(values.vendors[index].default_uom, 'ingredients.form.uom'),
        default_unit_cost: requiredFieldValidation(values.vendors[index].default_unit_cost, 'ingredients.form.unitCost')
      };
    });
  }
  return errors;

};

export default validate;
