import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import {FaCheckSquare, FaRegSquare} from 'react-icons/fa';
import * as formData from '../formData';
import InternationalDecimalInput from '../../../../../common/form/InternationalDecimalInput';
import TextInput from '../../../../../common/form/TextInput';

const RemovePointsOnDaysBetweenVisits  = (props) => {

  const disabledNumber = formData.formDefaults.disableDaysPointsExpire;
  const defaultNumber = formData.formDefaults.defaultDaysPointsExpire;  // arbitrary but safely high number to prevent clearing of points
  const currentValue = props.formSelector(props.setting.text);

  const onChange = (enable) => {
    props.change('reward_remove_points_after_days_between_visits', enable ? defaultNumber : null);
  };

  return (
    <div className='input-group'>
      {
        !currentValue || currentValue === disabledNumber
          ? <Field
              name=''
              component={TextInput}
              props={{
                placeholder: I18n.t('common.disabled'),
                rightAddon: <span><FaRegSquare aria-hidden='true' onClick={() => onChange(true)}/></span>,
                disabled: true
              }}
            />
          : <InternationalDecimalInput name='reward_remove_points_after_days_between_visits'
              fractionPartSize={0}
              props={{
                allowNegativeNumber: false,
                className: 'form-control',
                rightAddon: <span><FaCheckSquare style={{color: '#0075ff'}} aria-hidden='true' onClick={() => onChange(false)}/></span>
              }}
            />
      }
    </div>
  );

};

RemovePointsOnDaysBetweenVisits.propTypes = {
  setting: PropTypes.object.isRequired,
  formSelector: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired
};

export default RemovePointsOnDaysBetweenVisits;

