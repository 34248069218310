import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import { bindActionCreators } from 'redux';
import { Field } from 'redux-form';
import { push } from 'react-router-redux';
import { Col, Row } from 'react-bootstrap';
import ReactSelectInput from '../../../common/form/ReactSelectInput';


const ConnectsPartnerProduct = (props) => {

  const nonMappingOptions = [
    {id: 0, name: 'Create New'},
  ];


  return (<div>
    {
      props.fields.map((name, index) => {

        //Grab the product for the current row
        const product = props.fields.get(index);

        //Options for how to handle the partner's product (create a new one or associate it to one of your existing)
        let mapProductOptions = nonMappingOptions;
        if (props.itemMasters) {
          mapProductOptions =  mapProductOptions.concat(props.itemMasters.filter((itemMaster) => {
            return product.item_master_id === itemMaster.id
              || props.mappedItemMasterIds.indexOf(itemMaster.id) === -1;
          }));
        }

        //Options for categories, to be used when creating a new one
        const mapCategoryOptions = props.categories;

        //Options for item numbers (use the partners item number or use your own, if you've chosen an existing)
        const itemNumberOptions = [{value: product.item_number,  text: product.item_number + ' (' + I18n.t('theirs') + ')'}];

        if (product.item_master_id) {
          const selectedItemMaster = props.itemMasters.find(obj => obj.id === product.item_master_id);
          if (selectedItemMaster  && selectedItemMaster.item_number !== product.item_number) {
            itemNumberOptions.push({value: '', text: selectedItemMaster.item_number + ' (' + I18n.t('yours') + ')'});
          }
        }

        //Distinguish between rows with products we are linking up vs. those we are ignoring
        let rowClass = (product.link_item_master) ? 'mapped_product' : 'unmapped_product';

        //Determine which fields are disabled
        const itemNumberDisabled = (!product.item_master_id > 0 || itemNumberOptions.length <= 1);
        const mapSubcategoryDisabled = (product.item_master_id !== 0);
        const configFieldsDisabled = !product.link_item_master;


        return (
          <div key={index}>
            <Row className={rowClass}>
              <Col xs={12} md={3}>
                <Field
                  name={`${name}.link_item_master`}
                  component='input'
                  style={{marginRight: '8px', marginTop: '5px'}}
                  type='checkbox'
                />
                <em>{product.name}</em>

              </Col>
              <Col xs={12} md={3} className={"mappingData"}>
                <Field
                  name={`${name}.item_master_id`}
                  component={ReactSelectInput}
                  props={{
                    label: '',
                    options: mapProductOptions,
                    textKey: 'name',
                    valueKey: 'id',
                    placeholder: 'Select An Option...',
                    disabled: configFieldsDisabled
                  }}
                />
              </Col>
              <Col xs={12} md={3} className={"mappingData"}>
                <Field
                  name={`${name}.subcategory_id`}
                  component={ReactSelectInput}
                  props={{
                    label: '',
                    options: mapCategoryOptions,
                    placeholder: 'Select A Category...',
                    valueKey: 'value',
                    disabled: mapSubcategoryDisabled || configFieldsDisabled
                  }}
                />
              </Col>
              <Col xs={12} md={3} className={"mappingData"}>
                <Field
                  name={`${name}.item_master_item_number`}
                  component={ReactSelectInput}
                  props={{
                    label: '',
                    options: itemNumberOptions,
                    placeholder: 'Select An Item Number...',
                    disabled: itemNumberDisabled  || configFieldsDisabled
                  }}
                />
              </Col>
            <div style={{clear: 'both'}} />
            </Row>
        </div>);
      })
    }
  </div>);
};

ConnectsPartnerProduct.propTypes = {
  fields: PropTypes.object,
  actions: PropTypes.object,
  itemMasters: PropTypes.array,
  mappedItemMasterIds: PropTypes.array.isRequired,
  categories: PropTypes.array
};

function mapStateToProps(state, ownProps) {
  return {};
}

function mapDispatchToProps(dispatch) {
  const actions = bindActionCreators({push}, dispatch);
  return {
    actions
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectsPartnerProduct);

