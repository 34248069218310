import {change, getFormValues} from 'redux-form';
import get from 'lodash.get';
import map from 'lodash.map';
import {PRODUCT_FORM, PRODUCT_MASTER_FORM} from '../../constants/forms';
import {
  isCategoryHarvest, isCategoryPlants, isSubCategoryWaste,
  isCategoryFlower, isCategoryConcentrate, isCategoryMarijuana, isCategoryNonMedicated, isSubCategoryFinishedFlower
} from '../../selectors/categorySelectors';
import {
  getFormCategory, getFormSubcategories, getInitialPriceLists, productFormValueSelector, getCategoryId,
  generateWeightPrices, getUomOptions, productMasterFormValueSelector,
} from '../../selectors/forms/productFormSelectors';
import {getPricingWeights} from '../../selectors/pricingWeightsSelectors';
import {getPricingGroups, getPricingGroupSorted} from '../../selectors/pricingGroupsSelectors';
import {getPricingClassForFacilities, getSnapshotOfPricingClass} from '../../selectors/pricingClassSelectors';
import {isFormChangeAction} from './utils';
import {getPhenotypesByStrain} from '../../selectors/strainsSelectors';
import {setData} from '../../actions/dataActions';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import * as pricingTypes from '../../constants/pricingTypes';
import {DISCRETE, WEIGHT, uomTypes} from '../../constants/uomTypes';
import {getPricingWeightsForDisplaying} from '../../selectors/productsSelector';
import {oklahomaSubcategories} from '../../constants/subcategoryGroups';

const productForm = store => next => action => {
  const result = next(action);

  if (isFormChangeAction(action, [PRODUCT_FORM, PRODUCT_MASTER_FORM])) {
    // Sync pricing_type to default_uom if default_uom is present.
    const formValues = (action.meta.form === PRODUCT_FORM) ? getFormValues(PRODUCT_FORM)(store.getState()) : getFormValues(PRODUCT_MASTER_FORM)(store.getState());
    const productFormValuesSelector = (action.meta.form === PRODUCT_FORM) ? productFormValueSelector : productMasterFormValueSelector;
    // forceSet used when we receive a specific payload for default_uom vs a check for presence
    const syncPricingType = (uom, forceSet = false) => {
      const uomType = uomTypes[uom];
      const pricingType = !uomType
        ? 'weight' // original behavior
        : uomType.type === DISCRETE
          ? 'unit'
          : uomType.type === WEIGHT
            ? 'weight'
            : 'volume';
      if(formValues.sales_attributes !== pricingType || forceSet) {
        store.dispatch(change(action.meta.form, 'sales_attributes.pricing_type', pricingType));
      }
    };

    const updatePriceLists = () => {
      if (!formValues.default_uom) return;

      const state = store.getState();
      const categoryId = getCategoryId(state);
      const priceLists = get(state[itemNames.product], 'pricing_details.price_lists', []);
      const prices = getInitialPriceLists(priceLists, payload, categoryId, getPricingWeightsForDisplaying(state), getPricingGroups(state), null, null, null, null, formValues.default_uom);
      store.dispatch(change(meta.form, 'prices', prices));
    };


    if(get(formValues, 'default_uom', false) && !get(formValues, 'sales_attributes.pricing_type', false)){
      syncPricingType(formValues.default_uom);
    }

    const {meta, payload} = action;

    const facility = store.getState()[itemNames.facility];

    if (meta.field === 'category_id' && payload) {
      const state = store.getState();
      store.dispatch(change(meta.form, 'subcategory_id', null));

      if (formValues.default_uom) {
        !get(state[itemNames.product], 'id')
        && store.dispatch(
          change(
            meta.form,
            'prices',
            getInitialPriceLists([], pricingTypes.WEIGHT, payload, getPricingWeightsForDisplaying(state), getPricingGroupSorted(state), null, null, null, null, formValues.default_uom)
          )
        );
      }
    }

    if (meta.field === 'subcategory_id' && payload) {
      const state = store.getState();
      const sc = getFormCategory(state);
      const ssc = getFormSubcategories(state, meta).find(subcategory => subcategory.id === Number(payload));

      if (isCategoryFlower(sc) || isCategoryConcentrate(sc) || isCategoryHarvest(sc) || isCategoryMarijuana(sc)) {
        store.dispatch(change(meta.form, 'inventory_attributes.multipack_enabled', false));
      } else {
        store.dispatch(change(meta.form, 'inventory_attributes.is_prepack', false));
      }
      if (!isCategoryNonMedicated(sc)) {
        store.dispatch(change(meta.form, 'inventory_attributes.lot_tracked', true));
      }

      if(isSubCategoryFinishedFlower(ssc)) {
        store.dispatch(change(meta.form, 'inventory_attributes.is_prepack', true));
      }

      if (isCategoryHarvest(sc) || (isCategoryPlants(sc) && isSubCategoryWaste(ssc)) ) {
        store.dispatch(change(meta.form, 'is_sales_item', false));
        store.dispatch(change(meta.form, 'inventory_attributes.lot_tracked', true));
        store.dispatch(change(meta.form, 'inventory_attributes.is_prepack', false));
        if(isCategoryHarvest(sc)){
          store.dispatch(change(meta.form, 'is_inventory_item', true));
        }
      } else {
        store.dispatch(change(meta.form, 'is_sales_item', true));
      }
      const uoms = getUomOptions(state);
      const province_code = get(facility, 'province_code', '');
      if(province_code && province_code.toLowerCase() === 'ok' && oklahomaSubcategories.indexOf(get(ssc, 'subcategory_code'))) {
        store.dispatch(change('default_uom', 'GR'));
        store.dispatch(change('uom_type', 'weight'));
      } else {
        store.dispatch(change('default_uom', uoms.length === 1 ? uoms[0].uom_code : ''));
        store.dispatch(change('uom_type', uoms.length === 1 ? uoms[0].uom_type : ''));
      }
    }

    if (meta.field.indexOf('.pricing_class_id') > -1 ) {
      const path = meta.field.replace('.pricing_class_id', '');
      store.dispatch(change(meta.form, `${path}.pricing_class_consumer_groups`, getSnapshotOfPricingClass(store.getState(), path, payload)));
    }

    if (meta.field.indexOf('.facility_ids') > -1 ) {
      const path = meta.field.replace('.facility_ids', '');
      const priceList = productFormValuesSelector(store.getState(), path);
      const pricingClasses = getPricingClassForFacilities(store.getState())(map(payload, 'value'));

      if (!map(pricingClasses, 'id').includes(priceList.pricing_class_id)) {
        store.dispatch(change(meta.form, path, {
          ...priceList,
          pricing_class_id: null,
          pricing_class_consumer_groups: [],
        }));
      }
    }

    if (meta.field === 'sales_attributes.pricing_type' && payload) {
      updatePriceLists();
    }

    if (meta.field === 'default_uom' && payload !== undefined){
      updatePriceLists();
      syncPricingType(payload, true);
    }

    if (meta.field.indexOf('.pricing_group_id') > -1) {
      const path = meta.field.replace('.pricing_group_id', '');
      const state = store.getState();
      const categoryId = getCategoryId(state);
      const value = productFormValuesSelector(state, path);
      const pricingGroups = getPricingGroups(state);
      const pricingClasses = getPricingClassForFacilities(store.getState())(map(value.facility_ids, facility => {
        return typeof facility === 'number' ? facility : get(facility, 'value');
      }));
      const selectedPricingGroup = pricingGroups.find(pricingGroup => pricingGroup.id === value.pricing_group_id) || {};

      if (!map(pricingClasses, 'id').includes(selectedPricingGroup.pricing_class_id)) {
        value.pricing_class_id = null;
        value.pricing_class_consumer_groups = [];
      } else if (selectedPricingGroup.pricing_class_id) {
        value.pricing_class_id = selectedPricingGroup.pricing_class_id;
        value.pricing_class_consumer_groups = getSnapshotOfPricingClass(store.getState(), path, value.pricing_class_id);
      }

      const uom = get(formValues, 'default_uom', false);
      if (!get(value, 'pricing_group_id') && meta.form == PRODUCT_MASTER_FORM) {
        store.dispatch(change(meta.form, path, {
          ...value,
          pricing_group_id: payload,
          weight_prices: []
        }));
      }else{
        store.dispatch(change(meta.form, path, {
          ...value,
          pricing_group_id: payload,
          weight_prices: generateWeightPrices({pricing_group_id: payload}, categoryId, getPricingWeights(state, {form: meta.form}), pricingGroups, uom, meta.form),
        }));
      }
    }

    if (meta.field === 'strain_id') {
      const state = store.getState();
      const phenotypes = getPhenotypesByStrain(state, {strain_id: payload});
      store.dispatch(setData(phenotypes, dataNames.phenotypes));
    }
  }

  return result;
};

export default productForm;
