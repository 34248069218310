import React from 'react';
import PropTypes from 'prop-types';
import NumericInputControl from './NumericInputControl';

import ReduxBootstrapInput from './ReduxBootstrapInput';

const NumericInput = ({ label, size, input, meta, children, isRequired, fractionPartSize, type, ...props }) => {
  const inputType = type !== undefined ? type : (fractionPartSize ? 'text' : 'number');
  return (
    <ReduxBootstrapInput Control={NumericInputControl}
                         label={label} size={size}
                         input={input} meta={meta}
                         name={input.name} isRequired={isRequired}
                         fractionPartSize={fractionPartSize}
                         type={inputType} {...props} >
      {children}
    </ReduxBootstrapInput>
  );
};
NumericInput.propTypes = {
  label: PropTypes.string,
  size: PropTypes.oneOf(['lg', 'sm', 'large', 'small']),
  fractionPartSize: PropTypes.number,
  type: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func
  }).isRequired,
  meta: PropTypes.object.isRequired,
  children: PropTypes.node,
  isRequired: PropTypes.bool
};

NumericInput.defaultProps = {
  min: 0
};

export default NumericInput;
