import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';

import ActivateInventoryForm from './ActivateInventoryForm';
import validate from './validate';

const form = 'activateInventory';
const ActivateInventoryFormWrapper = reduxForm({
  form,
  validate,
  enableReinitialize: true,
})(ActivateInventoryForm);

ActivateInventoryFormWrapper.propTypes = {
  initialValues: PropTypes.object,
  itemOptions: PropTypes.array.isRequired,
  storageLocations: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
  integrationAdjustmentReasons: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  facilityUsers: PropTypes.array
};

export default ActivateInventoryFormWrapper;
