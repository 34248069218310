import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import RegisterFields from './RegisterFields';
import SubmitSection from '../common/form/SubmitSection';

export const CreateRegisterForm = ({handleSubmit, submitting, pristine, registers, statuses, registerTypes,
                                     setShowIsDefaultFlag, showIsDefault, isPosabitIntegrator, isPosabitIntegrationEnabled,
                                     handlePosabitClick, isAlleavesIntegrator, isAlleavesIntegrationEnabled, alleavesRegisterIds}) => {
  const settings = {
    actionSettings: {
      submit: {
        submitting,
        pristine,
        text: I18n.t('common.form.save')
      }
    }
  };
  return (
    <form className='create-register-form' onSubmit={handleSubmit}>
      <RegisterFields
        registers={registers}
        statuses={statuses}
        registerTypes={registerTypes}
        setShowIsDefaultFlag={setShowIsDefaultFlag}
        handlePosabitClick={handlePosabitClick}
        showIsDefault={showIsDefault}
        isPosabitIntegrator={isPosabitIntegrator}
        isPosabitIntegrationEnabled={isPosabitIntegrationEnabled}
        isAlleavesIntegrator={isAlleavesIntegrator}
        isAlleavesIntegrationEnabled={isAlleavesIntegrationEnabled}
        alleavesRegisterIds={alleavesRegisterIds}
      />
      <SubmitSection setting={settings}/>
    </form>
  );
};

CreateRegisterForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  registers: PropTypes.array.isRequired,
  statuses: PropTypes.array.isRequired,
  registerTypes: PropTypes.array.isRequired,
  setShowIsDefaultFlag: PropTypes.func.isRequired,
  handlePosabitClick: PropTypes.func.isRequired,
  showIsDefault: PropTypes.bool.isRequired,
  isPosabitIntegrator: PropTypes.bool,
  isPosabitIntegrationEnabled: PropTypes.bool.isRequired,
  isAlleavesIntegrationEnabled: PropTypes.bool.isRequired,
  isAlleavesIntegrator: PropTypes.bool,
  alleavesRegisterIds: PropTypes.array,
};

export default CreateRegisterForm;
