/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {bindActionCreators} from 'redux';
import {push} from 'react-router-redux';
import find from 'lodash.find';
import {Button} from 'react-bootstrap';
import {FaPrint} from 'react-icons/fa';
import * as messageTypes from '../../../constants/messageTypes';
import {clearSelectedData} from '../../../actions/selectedDataActions';
import * as dataNames from '../../../constants/dataNames';
import {getUnpaginatedData, putData, getFile} from '../../../actions/apiActions';
import {addMessage} from '../../../actions/systemActions';
import {getProcessingTypes, getSelectedProcessingTypesIds} from '../../../selectors/processingTypesSelectors';
import {getActiveProcessingTypeIds} from '../../../selectors/processingJobsSelectors';
import {handleComplexSelectRow} from '../../../actions/helpers/selectedDataHelper';
import InProgressOverlay from '../../common/InProgressOverlay';
import * as statuses from '../../../constants/statuses';
import TablePageWrapper from '../../common/grid/TablePageWrapper';
import PageTitle from '../../common/PageTitle';


export class ProcessingTypesListingPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.handleSelect = this.handleSelect.bind(this);
    this.editProcessingType = this.editProcessingType.bind(this);
    this.activateProcessingType = this.activateProcessingType.bind(this);
    this.switchTab = this.switchTab.bind(this);
    this.isActiveTab = this.isActiveTab.bind(this);
    this.onTabChanged = this.onTabChanged.bind(this);
    this.printProcessType = this.printProcessType.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.ref = React.createRef();
    const tabs = [
      {
        id: 'processingTypesActiveTab',
        eventKey: 'active',
        title: 'ei.common.active',
        route: '/ei/processing-types',
        actions: [
          {
            id: 'createProcessType',
            path: '/ei/processing-types/create',
            text: 'ei.processingTypes.listing.createProcessType',
            glyph: 'plus',
            requireSelect: false
          },
          {
            id: 'editProcessType',
            func: this.editProcessingType,
            text: 'ei.processingTypes.listing.editProcessType',
            requireSelect: true,
          }
        ]
      },
      {
        id: 'processingTypesInactiveTab',
        eventKey: 'inactive',
        title: 'ei.processingTypes.listing.inactiveTab',
        route: '/ei/processing-types/inactive',
        actions: [
          {
            id: 'createProcessType',
            path: '/ei/processing-types/create',
            text: 'ei.processingTypes.listing.createProcessType',
            glyph: 'plus',
            requireSelect: false
          },
        ]
      },
    ];

    const columns = [
      {
        name: 'ei.processingTypes.listing.name',
        dataId: 'name',
        hidden: false,
        dataSort: true,
        width: '200px',
      },
      {
        name: 'ei.processingTypes.listing.inputs',
        dataId: 'inputs',
        hidden: false,
        dataSort: true,
        width: '140px',
      },
      {
        name: 'ei.processingTypes.listing.outputs',
        dataId: 'outputs',
        hidden: false,
        dataSort: true,
        width: '140px',
      },
      {
        name: 'ei.processingTypes.listing.standardTime',
        dataId: 'standard_time',
        hidden: false,
        dataSort: true,
        width: '80px',
      },
      {
        name: 'ei.processingTypes.listing.startingPhase',
        dataId: 'starting_phase',
        hidden: false,
        dataSort: true,
        width: '150px'
      },
      {
        name: 'ei.processingTypes.listing.completionPhase',
        dataId: 'completion_phase',
        hidden: false,
        dataSort: true,
        width: '170px'
      },
      {
        name: 'ei.processingTypes.listing.print',
        dataId: 'name',
        hidden: false,
        dataSort: true,
        width: '80px',
        formatter: (id, row) => (
          <Button type='button' variant='link' onClick={(event) => this.printProcessType(event, row.id)}>
            <FaPrint />
            {I18n.t('ei.processingTypes.listing.printLabel')}
          </Button>
        )
      },

    ];
    const activeColumns = {
      columns: columns.concat([{
        name: '',
        hidden: false,
        width: '80px',
        dataId: 'id',
        dataSort: false,
        formatter: (id, row) => ( //eslint-disable-line react/no-multi-comp
          <Button type='button' variant='primary' onClick={(event) => this.deactivateProcessingType(event, row.id, this.props.activeProcessingTypeIds)}>
            {I18n.t('ei.processingTypes.listing.inactivate')}
          </Button>
        ),
      }])
    };
    const inactiveColumns = {
      columns: columns.concat([{
        name: '',
        hidden: false,
        width: '80px',
        dataId: 'id',
        dataSort: false,
        formatter: (id, row) => ( //eslint-disable-line react/no-multi-comp
          <Button type='button' variant='primary' onClick={(event) => this.activateProcessingType(event, row.id)}>
            {I18n.t('ei.processingTypes.listing.activate')}
          </Button>
        ),
      }])};
    this.state = {
      tabs,
      activeTab: 'active',
      activeColumns,
      inactiveColumns,
      columns,
      loading: true
    };
  }

  componentWillMount() {
    const {status} = this.props.params;
    const tab = this.isActiveTab(status) ? 'active' : 'inactive';
    this.props.actions.getUnpaginatedData('/api/phases', dataNames.phases);
    this.props.actions.getUnpaginatedData('/api/subcategories', dataNames.subcategories, null, {detailed: 1});
    this.props.actions.getUnpaginatedData('/api/processing_jobs', dataNames.processingJobs, { failed: 'ei.activeProcessing.failedLoadProcessingJobs' }, { detailed: 1, status: statuses.open });
    this.onTabChanged(tab);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.params.status !== this.props.params.status) {
      this.onTabChanged(this.isActiveTab(newProps.params.status || 'active') ? 'active' : 'inactive');
    }
  }

  isActiveTab(tab = this.props.params.status) {
    return tab !== 'inactive';
  }

  switchTab(activeTab) {
    const tab = find(this.state.tabs, {eventKey: activeTab}) || this.state.tabs[0];
    this.props.actions.push(tab.route);
  }

  onTabChanged(activeTab) {
    const {clearSelectedData, getUnpaginatedData} = this.props.actions;
    const params = {detailed: 1, active: this.isActiveTab(activeTab) ? 1 : 0};
    this.setState({activeTab, loading: true});
    clearSelectedData(dataNames.processingTypes);
    return getUnpaginatedData(
      '/api/processing_types',
      dataNames.processingTypes,
      {failed: 'ei.processingTypes.get.failed'},
      params,
      () => this.setState({loading: false})
    ).catch(() => this.setState({loading: false}));
  }

  printProcessType(event, id) {
    this.props.actions.addMessage(messageTypes.success, 'file.upload.start');
    this.props.actions.getFile(
      `/api/processing_types/${id}/print`,
      'process_type.pdf',
      {failed: 'ei.processingTypes.listing.failedPrint'}
    );
  }

  editProcessingType() {
    this.props.actions.push(`/ei/processing-types/modify/${this.props.selectedProcessingTypes[0]}`);
  }

  activateProcessingType(event, processTypeId) {
    event.stopPropagation();
    this.props.actions.putData(
      `/api/processing_types/${processTypeId}`,
      {active: 1},
      null,
      {failed: 'ei.processingTypes.activationFailed', success: 'ei.processingTypes.activationSuccess'},
      null,
      () => {
        this.onTabChanged(this.state.activeTab);
      }
    );
  }

  deactivateProcessingType(event, processTypeId, activeProcessingTypeIds) {
    event.stopPropagation();

    if (~activeProcessingTypeIds.indexOf(processTypeId)) {
      this.props.actions.addMessage(messageTypes.error, 'ei.processingTypes.inactivateFailed');
      return;
    }

    this.props.actions.putData(
      `/api/processing_types/${processTypeId}`,
      {active: 0},
      null,
      {failed: 'ei.processingTypes.deactivationFailed', success: 'ei.processingTypes.deactivationSucceed'},
      null,
      () => {
        this.onTabChanged(this.state.activeTab);
      }
    );
  }

  reload(){
    this.ref.current.wrappedInstance.getBaseRef().handleExportCSV();
  }

  handleSelect(isSelected, rows) {
    this.props.actions.handleComplexSelectRow(rows, dataNames.processingTypes, isSelected);
  }

  handleSearch(sort, query = 'matchall', size, start, filter) {
    const params = {sort, query, size, start, filter};
    params['detailed'] = 1;
    if (this.props.params.status === 'inactive') {
      params['active'] = 0;
    }
    this.props.actions.getUnpaginatedData('/api/processing_types', dataNames.processingTypes, null, params);
  }

  onSearchChange(query) {
    if (!query) {
      this.props.actions.clearSelectedData(dataNames.processingTypes);
    }
  }

  render() {
    const {selectedProcessingTypes, processingTypes, params} = this.props;
    const {activeTab, tabs, activeColumns, inactiveColumns} = this.state;
    const renderColumns = (params.status === 'inactive') ? inactiveColumns.columns : activeColumns.columns;
    return (
      <div>
        <PageTitle primaryText={I18n.t('ei.processingTypes.listing.title')}/>
        <InProgressOverlay isActive={this.state.loading} message='Loading Processing Types' />
        <TablePageWrapper
          ref={this.ref}
          settingKey='ei-processing-types'
          columns={renderColumns}
          data={processingTypes}
          activeTab={activeTab}
          tabs={tabs}
          switchTab={this.switchTab}
          selectedRows={selectedProcessingTypes}
          handleSelect={this.handleSelect}
          hideScanSearch = {true}
          sort='name asc'
          hideExport = {true}
          externalSearch = {this.handleSearch}
          noSelectionMode={params.status === 'inactive'}
          bstProps = {{
            options : {
              onSearchChange: this.onSearchChange,
              defaultSortName: 'name',
              defaultSortOrder: 'asc'
            },
          }}
        />
      </div>
    );
  }
}

ProcessingTypesListingPage.propTypes = {
  actions: PropTypes.shape({
    push: PropTypes.func.isRequired,
    handleComplexSelectRow: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    putData: PropTypes.func.isRequired,
    getFile: PropTypes.func.isRequired,
    addMessage: PropTypes.func.isRequired,
    clearSelectedData: PropTypes.func.isRequired
  }).isRequired,
  selectedProcessingTypes: PropTypes.array.isRequired,
  processingTypes: PropTypes.array.isRequired,
  params: PropTypes.shape({
    status: PropTypes.oneOf(['', 'active', 'inactive'])
  }).isRequired,
  activeProcessingTypeIds: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    processingTypes: getProcessingTypes(state),
    selectedProcessingTypes: getSelectedProcessingTypesIds(state),
    activeProcessingTypeIds: getActiveProcessingTypeIds(state)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {handleComplexSelectRow, push, getUnpaginatedData, putData, getFile, addMessage, clearSelectedData};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcessingTypesListingPage);
