import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { Button, Col, Row } from 'react-bootstrap';
import {FaPlus} from 'react-icons/fa';
import get from 'lodash.get';
import {uomTypes, VOLUME, WEIGHT} from '../../constants/uomTypes';
import InlineCheckBox from '../common/form/InlineCheckBox';
import validate from './validate';
import MultiselectInput from '../common/form/MultiselectInput';
import WillRender from '../common/concealers/WillRender';
import InternationalDecimalInput from '../common/form/InternationalDecimalInput';

const PrepackSizes = (props) => {

  const {
    handleSubmit, formValues, addPrepackWeight, categories, appliesToOptions,
    fields, selectedUom, isFeatureEnabledProductMasters
  } = props;

  const uomType = get(uomTypes, selectedUom, {});
  const selectedUomIsVolume = get(uomType, 'type', WEIGHT) === VOLUME;
  const allowedCategories = categories.filter((category) => {
    return !selectedUomIsVolume
      ? true
      : category.value === 2;
  });

  return (
    <div>
      <Row>
        <Col md={12}>
          <Row>
            <Col md={1} className='prepack-weight-header'>{I18n.t('prepackWeights.form.active')}</Col>
            <WillRender ifTrue={isFeatureEnabledProductMasters}>
              <Col md={2} className='prepack-weight-header'>{I18n.t('prepackWeights.form.applyToProductMaster')}</Col>
            </WillRender>
            <Col md={1} className='prepack-weight-header'>{I18n.t('prepackWeights.form.size')}</Col>
            <Col md={4} className='prepack-weight-header'>{I18n.t('prepackWeights.form.packageSizeType')}</Col>
            <Col md={4} className='prepack-weight-header'>{I18n.t('prepackWeights.form.category')}</Col>
          </Row>

          <WillRender ifTrue={!fields.length}>
            <Row>
              <Col md={12} style={{textAlign: 'center', margin: '2rem 0'}}>
                {I18n.t('prepackWeights.form.noWeightsNotice')}
              </Col>
            </Row>
          </WillRender>

          {fields.map((sizeKey, index) => {
            const sizeData = fields.get(index);
            const prepackName = get(sizeData, 'name', '').toUpperCase();
            const number = parseFloat(prepackName);
            const name = `${number.toFixed(2)} ${selectedUom === 'GR' ? 'G' : selectedUom}`;
            const rowEnabled = get(sizeData, 'active', 0);
            return (
              <Row key={index}
                   className={rowEnabled ? ['prepack-weight-row', 'prepack-weight-row-enabled'].join(' ') : 'prepack-weight-row'}>
                <Col md={1}>
                  <div className='prepack-checkbox'>
                    <Field
                      name={`${sizeKey}.active`}
                      component={InlineCheckBox}
                      props={{
                        disabled: false
                      }}
                    />
                  </div>
                </Col>
                <WillRender ifTrue={isFeatureEnabledProductMasters}>
                  <Col md={2}>
                    <div className='prepack-checkbox'>
                      <Field
                        name={`${sizeKey}.applies_to_product_masters`}
                        component={InlineCheckBox}
                        props={{
                          disabled: false
                        }}
                      />
                    </div>
                  </Col>
                </WillRender>
                <Col md={1}>
                  <div className={rowEnabled ? ['prepack-weight', 'prepack-weight-enabled'].join('') : 'prepack-weight'}>{name.replace(/ /g, '\u00a0')}</div>
                </Col>
                <Col md={4}>
                  <WillRender ifTrue={rowEnabled}>
                    <Field
                      name={`${sizeKey}.appliesTo`}
                      component={MultiselectInput}
                      props={{
                        options: appliesToOptions,
                        allOption: true,
                        disabled: !rowEnabled,
                        placeholder: I18n.t('prepackWeights.form.selectTypes')
                      }}
                    />
                  </WillRender>

                </Col>
                <Col md={4}>
                  <WillRender ifTrue={rowEnabled}>
                    <Field
                      name={`${sizeKey}.categories`}
                      component={MultiselectInput}
                      props={{
                        options: allowedCategories,
                        allOption: true,
                        disabled: !rowEnabled || selectedUomIsVolume,
                        placeholder: I18n.t('prepackWeights.form.selectCategories')
                      }}
                    />
                  </WillRender>
                </Col>
              </Row>
            );
          })}
        </Col>
      </Row>
      <Row className='add-prepack-weight-title'>
        <Col xs={3}>
          <strong>{I18n.t('prepackWeights.form.customPackageSize')}</strong>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <InternationalDecimalInput
            name={'newWeight'}
            rightAddon={<span>{selectedUom}</span>}
            props={{
              min: 0.01,
              step: 0.01
            }}
          />
        </Col>
        <Col md={1}>
          <div className='form-input form-group'>
            <Button
              type='button'
              variant='primary'
              size='sm'
              onClick={() => {
                const errors = validate(formValues, props);
                const haveErrors = (typeof errors === 'object' && Object.values(errors).some(Boolean));
                // If we have validation errors on the form - show these errors by simulating submit
                if (haveErrors) {
                  handleSubmit();
                } else {
                  addPrepackWeight();
                }
              }}
            >
              <FaPlus/>
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

PrepackSizes.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  addPrepackWeight: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  categories: PropTypes.array,
  appliesToOptions: PropTypes.array,
  formValues: PropTypes.object,
  fields: PropTypes.object.isRequired,
  selectedUom: PropTypes.string.isRequired,
  isFeatureEnabledProductMasters: PropTypes.bool,
};

export default PrepackSizes;
