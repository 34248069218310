import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col} from 'react-bootstrap';
import {  Field } from 'redux-form';

import SelectInput from '../../common/form/SelectInput';

export const ListProducts = (props) => {
  const {products, productOptions} = props;

  return (
    <div className='list-products'>
      {products.map((product, productIndex) => {
        return (
          <Row className='product' key={`product-${productIndex}`}>
            <Col xs={6} className='product-block'>
              <span>{product.inNetworkDescription}</span>
            </Col>
            <Col xs={6}>
              <Field
                name={products[productIndex].inNetworkDescription}
                component={SelectInput}
                props={{
                  options: productOptions
                }}/>
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

ListProducts.propTypes = {
  products: PropTypes.array.isRequired,
  productOptions: PropTypes.array.isRequired
};

export default ListProducts;
