import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import {getPrintServers} from '../../../selectors/forms/printServerFormSelectors';

export const PrintingNotAvailable = (props) => {
  const isSelectedPrinterAvailable = (selectedPrinter) => {
    if(!selectedPrinter) return false;
    if(!selectedPrinter.server) return false;
    if(selectedPrinter.server && selectedPrinter.server.remote) {
      const printServer = props.printServers.find((s) => s.id === selectedPrinter.server.id);
      if (printServer && printServer.active) return true;

    } else {
      const localPrinter = props.localPrinters.find((printer) => {
        if(typeof printer === 'string') return printer === selectedPrinter.name;
        return printer.name === selectedPrinter.name;
      });
      return localPrinter !== undefined;
    }
    return false;
  };
  const selectedPrinter = props.selectedPrinter[props.labelTag] ? props.selectedPrinter[props.labelTag] : false;
  const selectedPrinterAvailable = isSelectedPrinterAvailable(selectedPrinter);
  return (
    <div style={{marginBottom: selectedPrinter && selectedPrinterAvailable ? '0px' : '12px'}}>
      {
        selectedPrinter && selectedPrinterAvailable
          ? null
          : (<div className='text-danger' style={{width: '200px', textAlign: 'center', margin: 'auto', fontSize: 'smaller', marginTop: '8px'}}>
          <div>{!selectedPrinter ? 'No printer is selected.' : null}</div>
          <div>
            {
              selectedPrinter && selectedPrinter.server && !selectedPrinter.server.remote && !selectedPrinterAvailable
                ? 'Either QZ Tray is not running or QZ Tray does not see the selected printer.'
                : !selectedPrinter ? null : 'Either the remote print server is inactive or the remote print server no longer includes the selected printer.'
            }
          </div>
        </div>)
      }
    </div>
  );
};

PrintingNotAvailable.propTypes = {
  labelTag: PropTypes.string.isRequired,
  print: PropTypes.func,
  localPrinters: PropTypes.array,
  printServers: PropTypes.array,
  selectedPrinter: PropTypes.object,
};

function mapStateToProps(state){
  return {
    facility: state[itemNames.facility],
    selectedPrinter: state[itemNames.selectedPrinter],
    printServers: getPrintServers(state),
    localPrinters: state[dataNames.localPrinters],
  };
}

export default connect(mapStateToProps)(PrintingNotAvailable);
