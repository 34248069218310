import {createSelector} from 'reselect';
import * as applicationModes from '../constants/applicationModes';
import {getIntegrationState} from './integration/integrationSelectors';
import {isCanadaFacility} from './facility/getFacilityCountry';


export const getApplicationState = createSelector(
  [getIntegrationState, isCanadaFacility],
  (state, isCanada) => {
    return {
      ...state,
      isCanada,
    };
  }
);

export const getApplicationMode = createSelector(
  getApplicationState,
  getApplicationModeImplementation
);

export function getApplicationModeImplementation(applicationState) {
  const {isCanada, isLeaf, isMetrc, isCure, isPaLeaf, isWaLeaf, isMdMetrc, isOregonRec, isDcMetrc, isBiotrack} = applicationState;
  if (isCure) {
    return applicationModes.cure;
  }
  if (isPaLeaf) {
    return applicationModes.leafPA;
  }
  if (isWaLeaf) {
    return applicationModes.leafWA;
  }
  if (isLeaf) {
    return applicationModes.leaf;
  }
  if (isOregonRec) {
    return applicationModes.metrcOregonRec;
  }
  if (isMdMetrc) {
    return applicationModes.metrcMd;
  }
  if (isDcMetrc) {
    return applicationModes.metrcDc;
  }
  if (isMetrc) {
    return applicationModes.metrc;
  }
  if (isCanada) { // Eval last so all others take precedence
    return applicationModes.canada;
  }
  if (isBiotrack) {
    return applicationModes.biotrack;
  }
  return applicationModes.normal;
}
