import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';
import { requiredFieldValidation, minValidation, maxValidation } from '../../../common/form/redux-form/validations';
import {isInvPackageTrackingNotInCompliance} from '../../../../selectors/complianceSettingsSelectors';

export const validate = (values, props) => {

  const isLabResultsFailed = (input) => {
    return get(input, 'lab_result_status') === 0;
  };

  const errors = {};

  errors.assembly_id = requiredFieldValidation(values.assembly_id, 'ei.infusion.create.form.selectAssembly');
  errors.assembly_qty = requiredFieldValidation(values.assembly_qty, 'ei.infusion.create.form.qtyAssembly');
  errors.start_time = requiredFieldValidation(values.start_time, 'ei.infusion.create.form.startTime');
  errors.expected_completion_time = requiredFieldValidation(values.expected_completion_time, 'ei.infusion.create.form.completeTime');

  errors.inputs = values.inputs.map(input => {
    return {
      qty: requiredFieldValidation(input.qty, 'ei.infusion.create.from.quantityUsed')
        || minValidation(input.qty, 'ei.processing.form.weight', 0, true)
        || (!props.allowNegativeInventory && input.maxQty > 0 && maxValidation(input.qty, 'ei.processing.form.weight', input.maxQty)),
      // In PA, you cannot infuse failed product
      packageCode: props.isPaRemediationLabelsEnabled && isLabResultsFailed(input)
        ? I18n.t('ei.processing.form.failedTestResult')
        : null
    };
  });

  errors.addItem = requiredFieldValidation(values.inputs.length, 'ei.infusion.create.form.batch');


  if ( props.complianceSettings.inv_packages_require_tracking_id) {
    if (values.inputs.some((input) => {
      return isInvPackageTrackingNotInCompliance(input);
    })) {
      errors.inputs = {
        _error: props.complianceSettings.inv_packages_require_tracking_id_message,
      };
    }
  }

  return errors;
};

export default validate;
