import get from 'lodash.get';
import {
  buildRouteAccessibilityObject,
  evaluateAccessibilityForIngredientsPage
} from '../../../util/auth';
import {CULTIVATION, EXTRACTION, INFUSION, RETAIL, INTERNATIONAL_OPERATIONS, DISTRIBUTION, HOME_DELIVERY} from '../../../constants/moduleKeys';
import * as facilityTypes from '../../../constants/facilityTypes';
import * as applicationModes from '../../../constants/applicationModes';

export const facilitySetupLinks = [
  {
    title: 'facility.setup.assemblies',
    description: 'facility.setup.assembliesDescription',
    link: '/ei/assemblies',
    accessibility: buildRouteAccessibilityObject([EXTRACTION, INFUSION]),
  },
  {
    title: 'facility.setup.preferredMix',
    description: 'facility.setup.preferredMixDescription',
    link: '/ei/preferred-mixes',
    accessibility: buildRouteAccessibilityObject([EXTRACTION, INFUSION]),
  },
  {
    title: 'facility.setup.ingredients',
    description: 'facility.setup.ingredientsTypesDescription',
    link: '/ingredients/active',
    accessibility: {
      evaluate: evaluateAccessibilityForIngredientsPage
    },
  },
  {
    title: 'facility.setup.processingTypes',
    description: 'facility.setup.processingTypesDescription',
    link: '/ei/processing-types',
    accessibility: buildRouteAccessibilityObject([EXTRACTION, INFUSION]),
  },
  {
    title: 'facility.setup.equipment',
    description: 'facility.setup.equipmentDescription',
    link: '/equipment/active',
    accessibility: buildRouteAccessibilityObject([EXTRACTION, INFUSION]),
  },
  {
    title: 'facility.setup.autoPrice',
    description: 'facility.setup.autoPriceDescription',
    link: '/retail/auto-price',
    accessibility: buildRouteAccessibilityObject([RETAIL]),
  },
  {
    title: 'facility.setup.coupons',
    description: 'facility.setup.couponsDescription',
    link: '/retail/coupons',
    accessibility: buildRouteAccessibilityObject([RETAIL]),
  },
  {
    title: 'facility.setup.productLists',
    description: 'facility.setup.productListsDescription',
    link: '/retail/product-lists',
    accessibility: {
      evaluate: function(enabledModules, currentFacility, integrationState, featureToggles) {
        return get(featureToggles, 'feature_product_lists.is_enabled', false) && currentFacility.type === facilityTypes.RETAIL;
      }
    },
  },
  {
    title: 'facility.setup.customerGroups',
    description: 'facility.setup.customerGroupsDescription',
    link: '/retail/customer-groups',
    accessibility: buildRouteAccessibilityObject([RETAIL]),
  },
  {
    title: 'facility.setup.minimumDaysSupply',
    description: 'facility.setup.minimumDaysSupplyDescription',
    link: '/retail/minimum-days-supply',
    accessibility: buildRouteAccessibilityObject([RETAIL], undefined, [applicationModes.isOhMetric]),
  },
  {
    title: 'facility.setup.facilityStrains',
    description: 'facility.setup.facilityStrainsDescription',
    link: '/strains/facility',
  },
  {
    title: 'facility.setup.homeDelivery',
    description: 'facility.setup.homeDelivery',
    link: '/courier/delivery-orders/setup',
    accessibility: {
      evaluate: function(enabledModules, currentFacility, integrationState, featureToggles) {
        return get(featureToggles, 'feature_courier_licensees.is_enabled', false) && ~enabledModules.indexOf(DISTRIBUTION) && currentFacility.type === facilityTypes.courier_service;
      }
    },
  },
  {
    title: 'facility.setup.homeDelivery',
    description: 'facility.setup.homeDelivery',
    link: '/retail/delivery-orders/setup',
    accessibility: {
      evaluate: function(enabledModules, currentFacility, integrationState, featureToggles) {
        return get(featureToggles, 'feature_courier_licensees.is_enabled', false) && ~enabledModules.indexOf(HOME_DELIVERY) && currentFacility.type === facilityTypes.dispensary;
      }
    },
  },
  {
    title: 'facility.setup.feedingSchedules',
    description: 'facility.setup.feedingSchedulesDescription',
    link: '/feeding-schedules/active',
    accessibility: buildRouteAccessibilityObject([CULTIVATION]),
  },
  {
    title: 'facility.setup.plantCosting',
    description: 'facility.setup.plantCostingDescription',
    link: '/cultivation/plants/create-plant-costing',
    accessibility: buildRouteAccessibilityObject([CULTIVATION]),
  },
  {
    title: 'facility.setup.internationalSettings',
    description: 'facility.setup.internationalSettingsDescription',
    link: '/settings/international',
    accessibility: {
      evaluate: function(enabledModules, currentFacility, integrationState, featureToggles) {
        return get(featureToggles, 'feature_spain_parity.is_enabled', false) && enabledModules.indexOf(INTERNATIONAL_OPERATIONS) >= 0;
      }
    },
  },
  {
    title: 'facility.labelsManager',
    description: 'facility.labelsManager',
    link: '/labels/manager',
  },
  {
    title: 'facility.setup.pricingGroups',
    description: 'facility.setup.pricingGroupsDescription',
    link: '/retail/pricing-groups',
    accessibility: buildRouteAccessibilityObject([RETAIL]),
  },
  {
    title: 'facility.setup.pricingClasses',
    description: 'facility.setup.pricingClassesDescription',
    link: '/retail/pricing-classes',
    accessibility: buildRouteAccessibilityObject([RETAIL]),
  },
  {
    title: 'facility.setup.pricingMultipliers',
    description: 'facility.setup.pricingMultipliersDescription',
    link: '/retail/pricing-multipliers',
    accessibility: {
      evaluate: function(enabledModules, currentFacility, integrationState, featureToggles) {
        return get(featureToggles, 'feature_new_product_master.is_enabled', false) && currentFacility.type === facilityTypes.dispensary;
      }
    },
  },
  {
    title: 'facility.setup.salesLocations',
    description: 'facility.setup.salesLocationsDescription',
    link: '/retail/sales-locations/setup',
    accessibility: buildRouteAccessibilityObject([RETAIL]),
  },
  {
    title: 'facility.setup.storageLocations',
    description: 'facility.setup.storageLocationsDescription',
    link: '/storage-locations/setup',
  },
  {
    title: 'facility.setup.strains',
    description: 'facility.setup.strainsDescription',
    link: '/strains',
  },
  {
    title: 'facility.setup.registers',
    description: 'facility.setup.registersDescription',
    link: '/registers',
    accessibility: buildRouteAccessibilityObject([RETAIL, CULTIVATION, EXTRACTION, INFUSION]),
  },
  {
    title: 'facility.setup.rewards',
    description: 'facility.setup.rewardsDescription',
    link: '/retail/rewards',
    accessibility: buildRouteAccessibilityObject([RETAIL]),
  },
  {
    title: 'facility.setup.taxes',
    description: 'facility.setup.taxesDescription',
    link: '/taxes/rules',
    accessibility: buildRouteAccessibilityObject([RETAIL]),
  },
  {
    title: 'facility.setup.taxProfiles',
    description: 'facility.setup.taxProfilesDescription',
    link: '/taxes/profiles/active',
    accessibility: buildRouteAccessibilityObject([RETAIL]),
  },
  {
    title: 'facility.setup.cultivationLocations',
    description: 'facility.setup.cultivationLocations',
    link: '/cultivation-locations/setup',
    accessibility: buildRouteAccessibilityObject([CULTIVATION]),
  },
  {
    title: 'facility.setup.compliance.packageSizeManagement',
    description: 'facility.setup.packageSizeManagementDescription',
    link: '/prepack-weights/facility',
  },
  {
    title: 'facility.setup.stateIntegrator',
    description: 'facility.setup.stateIntegratorDescription',
    link: '/setup/integration',
    accessibility: {
      evaluate: function(enabledModules, currentFacility, integrationState) {
        return get(integrationState, 'allowAccessToStateIntegratorPage', false);
      }
    },
  },
  {
    title: 'facility.setup.manageThirdPartyIntegrations',
    description: 'facility.setup.thirdPartyIntegrationsDescription',
    link: '/setup/third-party-integrations'
  },
  {
    title: 'facility.setup.creditCardsSettings',
    description: 'facility.setup.creditCardsSettingsDescription',
    link: '/retail/credit-cards-settings',
    accessibility: {
      evaluate: function (_, currentFacility) {
        if (currentFacility.country_code === 'US') {
          return false;
        }
        return currentFacility.type === facilityTypes.RETAIL;
      }
    },
  },
  {
    title: 'facility.setup.ordersImport',
    description: 'facility.setup.ordersImportDescription',
    link: 'import/orders',
  },
  {
    title: 'facility.setup.cca',
    link: '/cca',
    accessibility: {
      evaluate: (_, currentFacility, integrationState) => {
        return integrationState.isCca;
      }
    }
  },
  {
    title: 'facility.setup.merchantConfiguration',
    description: 'facility.setup.merchantConfigurationDescription',
    link: '/setup/merchant-configuration',
    accessibility: {
      evaluate: function(enabledModules, currentFacility, integrationState, featureToggles) {
        return get(featureToggles, 'feature_manage_merchant_accounts.is_enabled', false);
      }
    },
  }
];

export const advancedSetupLinks = [
  {
    title: 'facility.setup.accounting',
    description: 'facility.setup.accounting',
    link: '/accounting/setup/sales'
  },
  {
    title: 'facility.setup.categoryManagement',
    description: 'facility.setup.categoryManagementDescription',
    link: '/category/management'
  },
  {
    title: 'facility.setup.compliance.title',
    categories: [
      {
        title: 'facility.setup.compliance.plants',
        description: 'facility.setup.compliance.plantsDescription',
        link: '/plants/compliance-settings',
        accessibility: buildRouteAccessibilityObject(undefined, [facilityTypes.GROW])
      },
      {
        title: 'facility.setup.compliance.inventory',
        link: '/inv/compliance-settings',
        accessibility: buildRouteAccessibilityObject(undefined, [facilityTypes.GROW, facilityTypes.RETAIL])
      },
      {
        title: 'facility.setup.compliance.patients',
        link: '/retail/patient-settings',
        accessibility: buildRouteAccessibilityObject(undefined, [facilityTypes.RETAIL])
      },
      {
        title: 'facility.setup.compliance.sales',
        link: '/retail/sales-settings',
        accessibility: buildRouteAccessibilityObject(undefined, [facilityTypes.RETAIL])
      },
      {
        title: 'facility.setup.compliance.receipts',
        link: '/retail/receipt-settings',
        accessibility: buildRouteAccessibilityObject(undefined, [facilityTypes.RETAIL])
      },
      {
        title: 'facility.setup.rewardsSetup',
        description: 'facility.setup.rewardsDescription',
        link: '/retail/rewards/setup',
        accessibility: buildRouteAccessibilityObject(undefined, [facilityTypes.RETAIL])
      },
      {
        title: 'facility.setup.compliance.facilityGroups',
        link: '/facility-groups',
        accessibility: buildRouteAccessibilityObject(undefined, [RETAIL])
      },
      {
        title: 'facility.setup.compliance.e&iFinishedProductCompliance',
        description: 'facility.setup.compliance.eifinishedProductCompliance',
        link: '/inv/compliance-settings',
        accessibility: buildRouteAccessibilityObject(undefined, [facilityTypes.MANUFACTURING])
      },
      {
        title: 'facility.setup.compliance.wasteDestruction',
        description: 'facility.setup.compliance.wasteDestruction',
        link: '/settings/waste-compliance'
      },
      {
        title: 'facility.setup.compliance.modality',
        link: '/settings/modality',
        accessibility: buildRouteAccessibilityObject(undefined, [facilityTypes.GROW], [applicationModes.isColombia])
      },
      {
        title: 'facility.setup.compliance.cupos',
        link: '/settings/cupos',
        accessibility: buildRouteAccessibilityObject(undefined, [facilityTypes.GROW], [applicationModes.isColombia])
      }
    ]
  },
  {
    title: 'facility.setup.organizationProfile',
    description: 'facility.setup.organizationProfileDescription',
    link: '/organization/create'
  },
  {
    title: 'facility.setup.organizationStrains',
    description: 'facility.setup.organizationStrainsDescription',
    link: '/strains/organization'
  },
  {
    title: 'facility.setup.partners',
    description: 'facility.setup.partnersDescription',
    link: '/partners'
  },
  {
    title: 'facility.setup.facilityGroups',
    link: '/shared-facility-groups'
  },
  {
    title: 'facility.setup.internalTransfer',
    description: 'facility.setup.internalTransferDescription',
    link: '/internal-transfer-settings',
    accessibility: {
      evaluate: function(_, currentFacility, integrationState) {
        // Hide for leaf, biotrack, and metrc
        const { isLeaf, isBiotrack, isMetrc } = integrationState;
        return !isLeaf && !isBiotrack && !isMetrc;
      }
    },
  },
  {
    title: 'facility.setup.uoms',
    link: '/uom',
    accessibility: {
      evaluate: function(_, currentFacility, integrationState) {
        return integrationState.isHemp;
      }
    },
  },
];
