import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack, push} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import {Tab, Tabs} from 'react-bootstrap';
import {labelTag} from '../../../constants/itemNames';
import FormWrapper from '../../common/form/FormWrapper';
import LabelSettingsPage from '../settings/LabelSettingsPage';
import LabelEditor from '../editor/LabelEditor';

class LabelEditorPage extends React.PureComponent{

  constructor(props, context) {
    super(props, context);
    this.goBack = this.goBack.bind(this);
    this.changeTabs = this.changeTabs.bind(this);

    this.state = {activeTab: 'settings'};
  }

  changeTabs(name){
    this.setState({activeTab: name});
  }

  goBack(){
    this.props.actions.goBack();
  }

  requiresSettings(){
    if (this.props.params.tag.indexOf('package') !== -1) return true;
    if (this.props.params.tag.indexOf('patient') !== -1) return true;
    return this.props.params.tag.indexOf('production') !== -1;

  }

  render() {

    const requiresTabs = this.requiresSettings();

    return (
      <FormWrapper title={`${I18n.t('labels.editor.title')}: ${this.props.labelTag.name}`} goBack={this.goBack}>
        <div className='row'>
          <div className='col-md-12'>
            {!requiresTabs
              ? (<div>
                  <p className='text-muted'>Change display names, data keys, and formatting of individual label elements.</p>
                  <LabelEditor tag={this.props.params.tag} />
                </div>)
              : (<div>
                  <p>Editing your label is as simple as applying a few adjustments through the Settings Tab below and is usually a good place to start.</p>
                  <p>For more control, changing elements on your label gives you the power to change display names, formatting, and even the source data fields that are used for a given label.</p>
                  <Tabs defaultActiveKey='settings' id='label-editor-tabs'>
                    <Tab eventKey={'settings'} title={'Change Settings For Your Label'}>
                      <p className='text-muted' style={{marginTop: '16px'}}>Change the fields present on your label and some of the behaviors; for example font size.</p>
                      <LabelSettingsPage tag={this.props.params.tag} label={this.props.labelTag} />
                    </Tab>
                    <Tab eventKey={'editor'} style={{marginTop: '16px'}} title={'Change Elements On Your Label'}>
                      <p className='text-muted'>Change display names, data keys, and formatting of individual label elements.</p>
                      <LabelEditor tag={this.props.params.tag} />
                    </Tab>
                  </Tabs>
                </div>)
            }
          </div>
        </div>
      </FormWrapper>
    );
  }
}

LabelEditorPage.propTypes = {
  route: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }),
  labelTag: PropTypes.object,
};

function mapStateToProps(state) {

  return {
    [labelTag]: state[labelTag],
  };

}

function mapDispatchToProps (dispatch) {
  const actions = {goBack, push};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LabelEditorPage);
