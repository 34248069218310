/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { goBack, push } from 'react-router-redux';
import { stopSubmit, change, formValueSelector } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { Col, Row, FormControl, InputGroup } from 'react-bootstrap';
import {FaBarcode} from 'react-icons/fa';
import get from 'lodash.get';
import {
  getPlantIds,
  getModifyPlantsInitialValues,
  maturePlantsRequireTracking,
  maturePlantsRequireTrackingErrorMessage
} from '../../../selectors/plantsSelectors';
import { unsetData, setData } from '../../../actions/dataActions';
import { fetchMetrcTrackingIdsForSelectInput } from '../../../actions/integrationActions';
import { addMessage } from '../../../actions/systemActions';
import { validatePlants } from '../../../actions/complianceSettingsActions';
import * as apiActions from '../../../actions/apiActions';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import * as messageTypes from '../../../constants/messageTypes';
import { MODIFY_PLANT_FORM } from '../../../constants/forms';
import { convertFormInputDateToDbDate } from '../../../util/dateHelpers';
import { handleComplexSelectRow } from '../../../actions/helpers/selectedDataHelper';
import { getIntegrationState } from '../../../selectors/integration/integrationSelectors';
import { getOrderedFacilityStrains } from '../../../selectors/facilityStrainsSelectors';
import { getMetrcTags } from '../../../selectors/integration/metrcSelectors';
import { getStorageLocationCollections } from '../../../selectors/storageLocationsSelectors';
import InProgressOverlay from '../../common/InProgressOverlay';
import FormWrapper from '../../common/form/FormWrapper';
import reduxMetrcIdAvailability, {
  setUnavailableTrackingIdFieldError
} from '../../common/form/redux-form/reduxMetrcIdAvailability';
import ModifyPlantFormWrapper from './ModifyPlantFormWrapper';
import PrinterModal from '../../printer/PrinterModal';
import { getFlatBuildings } from '../../../selectors/locationsSelectors';
import ScanInputFormWrapper from '../../scan-input/ScanInputFormWrapper';
import { plantStages } from '../../../constants/plants';
import { hasPlantsTags } from '../../../selectors/integrationSelectors';
import { fetchModalitiesByStrainId } from '../../../actions/integrations/colombia';
import { isFeatureEnabled } from '../../../selectors/featureToggles';

const formName = MODIFY_PLANT_FORM;

export class ModifyPlantPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showLoadingMessage: false,
      showLoader: true,
      showOk: false,
      loadingMessage: 'plants.create.creating',
      onDismiss: () => {},
      ignorePromise: false,
      itemsPerPage: 5,
      activePage: 1,
      hideRange: true,
      hideBatch: true,
      selectedStrainIds: [],
      includedPlants: [],
      showInvPlants: false,
      doPrint: false,
      saveComplete: false,
      endingTag: '',
      notEnoughTags: false,
      submitDisabled: false,
      hideBulkFields: props.selectedPlants.length,
      location_id: [],
      locationScanData: '',
      scannedLocations: [],
      selectedTags: [],
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.redirect = this.redirect.bind(this);
    this.saveAndPrint = this.saveAndPrint.bind(this);
    this.saveOnePlant = this.saveOnePlant.bind(this);
    this.printLabel = this.printLabel.bind(this);
    this.hidePrinter = this.hidePrinter.bind(this);
    this.showLoadingMessage = this.showLoadingMessage.bind(this);
    this.hideLoadingMessage = this.hideLoadingMessage.bind(this);
    this.onPlantFilterChange = this.onPlantFilterChange.bind(this);
    this.showDismissableMessage = this.showDismissableMessage.bind(this);
    this.getBatches = this.getBatches.bind(this);
    this.getRanges = this.getRanges.bind(this);
    this.filterPlants = this.filterPlants.bind(this);
    this.toggleInvPlants = this.toggleInvPlants.bind(this);
    this.switchPage = this.switchPage.bind(this);
    this.onTagChange = this.onTagChange.bind(this);
    this.moreTagsButton = this.moreTagsButton.bind(this);
    this.showTagsElement = this.showTagsElement.bind(this);
    this.validatePlantCompliance = this.validatePlantCompliance.bind(this);
    this.searchPlants = this.searchPlants.bind(this);
    this.scanFieldKeyPressed = this.scanFieldKeyPressed.bind(this);
    this.makeQueryString = this.makeQueryString.bind(this);
    this.calculatePlantsWaste = this.calculatePlantsWaste.bind(this);
    this.onChangeRequestTag = this.onChangeRequestTag.bind(this);
    this.shouldShowStrainField = this.shouldShowStrainField.bind(this);
    this.checkPlantsAreFromSameBatch = this.checkPlantsAreFromSameBatch.bind(this);
    this.isImmaturePhase = this.isImmaturePhase.bind(this);

    this.onLocationScanChange = this.onLocationScanChange.bind(this);
    this.onLocationKeyPress = this.onLocationKeyPress.bind(this);
  }

  componentWillMount() {
    const { integrationState, selectedPlants } = this.props;
    const strain_id = get(selectedPlants, '0.strain_id');
    // Integration settings are downloaded to see if Metrc intergation settings exist. If the Metrc settings exist,
    // then the Tracking ID inputs are displayed.
    this.props.actions
      .getItem('/api/integration-settings', itemNames.integrationSettings, {
        failed: 'stateIntegratorSettings.get.failed'
      })
      .then(() => {
        if (this.props.integrationState.isMetrc) {
          this.props.actions.fetchMetrcTrackingIdsForSelectInput({ type: 'plant' });
        }
      });
    this.props.actions.getItem(
      '/api/customers/compliance_settings/validate_plants',
      itemNames.patientComplianceSettings,
      { failed: 'retail.patientSettings.loadSettingsForPlants' }
    );
    this.props.actions.unsetData(dataNames.plants);
    this.props.actions.getUnpaginatedData('/api/strains/by_facility', dataNames.facilityStrains);
    this.props.actions.getUnpaginatedData('/api/schedules', dataNames.feedingSchedules, undefined, { active: 1 });

    this.props.actions.getUnpaginatedData(
      '/api/cultivation/buildings',
      dataNames.storageLocations,
      null,
      null,
      (data) => {
        this.props.actions.setData(data, dataNames.buildings);
      }
    );

    this.props.actions.getUnpaginatedData('/api/stages', dataNames.stages);
    this.props.actions.getData('/api/search/plants', dataNames.plantStats, undefined, {
      query: 'matchall',
      filter: 'is_harvested: 0 AND is_packaged:0 AND is_destroyed:0',
      size: 0,
      // group: {
      //   group: 'true',
      //   'group.format': 'grouped',
      //   'group.field': 'stage_name',
      //   'group.limit': 0
      // }
      facet: {
        'facet': 'true', //eslint-disable-line
        'facet.field': 'stage_name' //eslint-disable-line
      }
    });
    if (integrationState.isColombia && strain_id) {
      this.props.actions.fetchModalitiesByStrainId(strain_id, { with_relations: ['cupo'] });
      this.props.actions.getUnpaginatedData('/api/cupos', dataNames.cupos);
    }

    this.props.actions.getItem('/api/settings/plants', itemNames.complianceSettings, null, {
      ids: [
        'cult_track_plants_as_groups',
        'cult_distribute_harvest_weights',
        'cult_plants_in_phases',
        'cult_plants_on_hand',
        'cult_mature_plants_require_tracking_id',
        'cult_colombia_modality'
      ]
    });

    if (selectedPlants.length) {
      this.getRanges(selectedPlants, true);
    }

  }

  componentDidMount() {
    this.props.actions.unsetData(dataNames.plants);
  }

  componentWillUnmount() {
    this.props.actions.unsetData(dataNames.plants);
  }

  redirect() {
    this.props.actions.goBack();
  }

  showLoadingMessage(selectedPlants) {
    const message = 'plants.modify.modifying';
    const messageObject = {
      count: selectedPlants.length,
      plantNoun: selectedPlants.length === 1 ? 'Plant' : 'Plants'
    };
    this.setState({
      showLoadingMessage: true,
      showLoader: true,
      onDismiss: false,
      showOk: false,
      loadingMessage: message,
      messageObject: messageObject
    });
  }

  showDismissableMessage(func = () => {}) {
    const newState = {
      showLoader: false,
      showLoadingMessage: true,
      //onDismiss: this.hideLoadingMessage,
      showOk: true,
      onDismiss: func,
      loadingMessage: 'plants.modify.successBulk'
    };
    this.setState(newState);
  }

  hideLoadingMessage() {
    this.setState({ showLoadingMessage: false });
  }

  onSubmit(plants) {
    if (this.state.saveComplete && this.state.doPrint) {
      return this.printLabel();
    }

    /***
     * Returns ids of plants from selectedPlants if num_plants value not set or up to num_plants quantity from form if set
     * which means we are modifying fewer than the total number of selected plants.
     * @param formValues
     * @returns {any[]}
     */
    const getPlantIdsFromSelectedPlants = (formValues) => {
      const selectedPlants = this.props.selectedPlants;
      const numPlants = get(formValues, 'num_plants', selectedPlants.length);
      return selectedPlants
        .filter((plant, index) => (numPlants !== 0 ? index < numPlants : true))
        .map((plant) => plant.id)
        // sort ids in ascending order
        .sort((a,b) => {
          return a - b;
        });
    };

    const {
      validateTrackingIdsAvailability,
      maturePlantsRequireTracking,
      maturePlantsRequireTrackingErrorMessage,
      integrationState: { isMetrc },
      hasPlantsTags,
      isSequentialTrackingTagCheckToggled,
      isUtahGlobalIdGenerationFeatureEnabled
    } = this.props;

    const that = this;
    const plantIds = getPlantIdsFromSelectedPlants(plants);
    this.showLoadingMessage(plantIds);
    const hideMessage = this.hideLoadingMessage;

    let payload = {};
    let validateTagOptions = {
      fieldName: isSequentialTrackingTagCheckToggled
        ? 'beginning_tag'
        : 'beginning_state_integration_id',
      requireUnused: isMetrc,
    };
    const apiEndpoint = '/api/plants/update';

    payload = {
      ids: plantIds,
      modification_date: convertFormInputDateToDbDate(plants.modification_date, this.props.timezone),
      event_date: convertFormInputDateToDbDate(plants.modification_date, this.props.timezone),
      notes: plants.notes
    };
    if (this.shouldSplitPlantBatch(plants)) {
      // all the plants SHOULD have the same values, so just pull the data we need from one of them
      const defaultPlant = plants.selectedPlants[0];
      const { strain_id } = defaultPlant;

      // Only Metrc CA will have this key
      const splitPlantBatchTag = plants.split_plant_batch_tag;
      if (plants && plants.split_plant_batch_tag) {
        payload.split_batch_integration_tracking_id = splitPlantBatchTag;

        // this value is repeated to be able to validate the tracking tag in validateTrackingIdsAvailability
        payload.split_plant_batch_tag = splitPlantBatchTag;

        // make the api call to validate tracking tags aware of this field
        validateTagOptions = {
          fieldName: 'split_plant_batch_tag',
          requireUnused: isMetrc,
          fieldNames: ['split_plant_batch_tag'],
        };
      }
      payload.strain_id = plants.strain_id || strain_id;
      payload.batch_name = plants.batch_name;
    }

    if (isSequentialTrackingTagCheckToggled) {
      validateTagOptions.payloadIsTrackingIds = true;
    }

    if (plants.feeding_schedule_id) {
      payload.feeding_schedule_id = plants.feeding_schedule_id;
    }
    if (plants.section_id) {
      payload.section_id = plants.section_id;
    }
    if (plants.stage_id) {
      payload.stage_id = plants.stage_id;
    }
    if (plants.beginning_tag) {
      payload.beginning_state_integration_id =
        typeof plants.beginning_tag === 'object' ? plants.beginning_tag.text : plants.beginning_tag;
    }
    if (plants.strain_id) {
      payload.strain_id = plants.strain_id;
    }
    const setTrackingIdError = (trackingIds, fieldName = 'beginning_tag', errorText) =>
      setUnavailableTrackingIdFieldError(trackingIds, fieldName, errorText);

    if (plants.report_waste_total) {
      payload.waste_weight_total = plants.report_waste_total;
    }

    if ( plants.tag_requested !== undefined && plants.tag_requested !== null && hasPlantsTags ) {
      payload.tag_requested = plants.tag_requested === 0 ?
        0 :
        isUtahGlobalIdGenerationFeatureEnabled ? 0 : 1;  // Clear tag_requested if Utah global ID generation enabled
    }

    const someMaturePlantsDontHaveTrackingIds = this.props.selectedPlants.some((plant) => {
      if ( plants.stage_id === 2 ) return false;
      if ( plants.stage_id !== 2 && plant.stage_id !== 2 && !plant.state_integration_tracking_id ) return true;
      if ( plants.stage_id !== 2 && plant.stage_id !== 2 && plant.tag_requested ) return true;
    });

    const validateTrackingRequirements = (plantForm) => {
      return new Promise((resolve, reject) => {
        /***
         * When a mature plant is attempted to be modified and settings require tracking IDS,
         * do not proceed without an ID and display the settings' error message.  all the plants that have required
         * an ID are also considered ID-less.
         */
        if (maturePlantsRequireTracking && someMaturePlantsDontHaveTrackingIds) {
          // if any fiels has been changed -> reject
          if (
            plantForm &&
            (!!plantForm.stage_id ||
              !!plantForm.section_id ||
              !!plantForm.feeding_schedule_id ||
              !!plantForm.report_waste_total)
          ) {
            this.props.actions.addMessage('error', maturePlantsRequireTrackingErrorMessage);
            reject();
            return;
          }

          if (plantForm.tag_requested === undefined) {
            this.props.actions.addMessage('error', maturePlantsRequireTrackingErrorMessage);
            reject();
            return;
          }
        }
        resolve();
      });
    };
    const validateTrackingIdsPayload = isSequentialTrackingTagCheckToggled
      ? this.state.selectedTags
      : payload;

    return (
      validateTrackingIdsAvailability(validateTrackingIdsPayload, setTrackingIdError, validateTagOptions)
        .then(() => {
          return validateTrackingRequirements(plants);
        })
        // add any other validation to this chain of promises
        .then(() => {
          this.props.actions.putData(
            apiEndpoint,
            payload,
            dataNames.plants,
            {failed: 'plants.modify.fail'},
            null,
            (data) => {
              this.setState({ignorePromise: true, saveComplete: true});
              const callback = () => {
                this.setState({showLoadingMessage: false});
                if (this.state.doPrint) {
                  this.printLabel();
                } else {
                  this.redirect();
                }
              };

              if (plants.selectedPlants.length === 1) {
                callback();
              } else {
                this.showDismissableMessage(callback);
              }
            });
        })
        .then(() => {
          setTimeout(() => {
            if (that.state.ignorePromise) return that.setState({ignorePromise: false});
            hideMessage();
          }, 1000);
        })
        .catch(e => {
          this.setState({showLoadingMessage: false});
          throw e;
        })
    );
  }

  saveAndPrint() {
    this.setState({doPrint: true});
  }

  saveOnePlant(plant, formFieldName, formIndex) {
    const {
      validateTrackingIdsAvailability,
      integrationState: { isMetrc },
      maturePlantsRequireTracking,
      maturePlantsRequireTrackingErrorMessage
    } = this.props;

    const {
      strain_id,
      phenotype_id,
      waste_weight,
      default_strain_id,
      notes,
      state_integration_tracking_id,
      initial_state_integration_tracking_id,
      is_mother,
      qty,
      modality,
      cupo_id,
      plant_id,
      batch_name,
      planting_batch_id
    } = plant;
    const payload = {
      phenotype_id,
      waste_weight,
      notes,
      state_integration_tracking_id,
      initial_state_integration_tracking_id,
      is_mother,
      qty,
      modality,
      cupo_id,
      plant_id,
      batch_name,
      planting_batch_id
    };
    const setTrackingIdError = (trackingIds, fieldName, errorText) => {
      const errors = { selectedPlants: [] };
      trackingIds.forEach((trackingId, index) => {
        errors.selectedPlants[formIndex] =
          trackingId.isUnavailable &&
          trackingId.initial_state_integration_tracking_id !== trackingId.state_integration_tracking_id
            ? { [fieldName]: errorText }
            : undefined;
      });
      return errors;
    };

    if (plant.tag_requested !== undefined && plant.tag_requested !== null) {
      payload.tag_requested = plant.tag_requested === 0 ? 0 : 1;
    }

    const originalPlantValues = this.props.selectedPlants ? this.props.selectedPlants[formIndex] : {};
    let originalPlantValuesHaveChanged = Object.keys(plant).some((key) => {
      // Why is this field here, anyway ignore.
      if (key === 'default_strain_id') return false;

      // tag requests are allowed to be changed
      if (key === 'tag_requested') return false;

      if (
        // xored
        (plant[key] === undefined && originalPlantValues[key] !== undefined) ||
        (plant[key] !== undefined && originalPlantValues[key] === undefined)
      ) {
        return true;
      }

      if (
        plant[key] !== undefined &&
        originalPlantValues[key] !== undefined &&
        plant[key] !== originalPlantValues[key]
      ) {
        return true;
      }
    });

    originalPlantValuesHaveChanged |= plant[waste_weight] !== undefined && plant[waste_weight] > 0;

    const validateTrackingRequirements = (plant) => {
      return new Promise((resolve, reject) => {
        /***
         * When a mature plant is attempted to be modified and settings require tracking IDS,
         * do not proceed without an ID and display the settings' error message.  all the plants that have required
         * an ID are also considered ID-less.
         */
        const maturePlantDoesntHaveTrackingId =
          plant.stage_id !== 2 && (!plant.state_integration_tracking_id || plant.tag_requested);
        if (maturePlantsRequireTracking && maturePlantDoesntHaveTrackingId) {
          // if any fields has been changed -> reject
          if (originalPlantValuesHaveChanged) {
            this.props.actions.addMessage('error', maturePlantsRequireTrackingErrorMessage);
            reject();
            return;
          }

          if (plant.tag_requested === undefined) {
            this.props.actions.addMessage('error', maturePlantsRequireTrackingErrorMessage);
            reject();
            return;
          }
        }
        resolve();
      });
    };

    return validateTrackingIdsAvailability(payload, setTrackingIdError, { requireUnused: isMetrc })
      .then(() => {
        validateTrackingRequirements(plant);
      })
      .then(() => {
        this.props.actions.putItem(
          `/api/plants/${plant.id}`,
          { ...payload, strain_id: strain_id !== default_strain_id ? strain_id : undefined, adjustAll: 1, dontCheckForStrain: 1},
          itemNames.plant,
          { failed: 'plants.modify.fail', success: 'plants.modify.singleSuccess' }
        );
      })
      .catch((e) => this.props.actions.stopSubmit(formName, e.errors));
  }

  printLabel() {
    this.setState({ doPrint: false });
    const ids = this.props.selectedPlants.map((plant) => plant.id);
    this.setState({ showPrinter: true, labelTag: 'cult_plant_tag', labelIds: ids, redirect: false });
  }

  hidePrinter(event) {
    this.setState({ showPrinter: false });
  }

  getBatches(location_id = undefined) {
    const params = {
      query: 'matchall',
      filter: 'is_destroyed:0 AND is_harvested:0 AND is_packaged:0',
      fields: ['planting_batch_id', 'batch_name', 'location_name', 'planting_date', 'strain_name'],
      sort: 'batch_name asc',
      size: 100000,
      group: {
        'group': 'true', //eslint-disable-line
        'group.field': 'planting_batch_id', //eslint-disable-line
        'group.main': 'true' //eslint-disable-line
      }
    };
    const locations = location_id ? location_id.map((location) => location.id) : [];
    if (locations.length) {
      const locationFilter = this.makeQueryString('location_id', locations);
      params.filter = params.filter + ` AND ${locationFilter}`;
    }
    this.props.actions.getSearchData('/api/search/plants', dataNames.batches, null, params, (data) => {
      if (!data.length || data.length === 1) {
        this.setState({ hideRange: true, hideBatch: true });
        this.getRanges();
      } else {
        this.setState({ hideRange: true, hideBatch: false });
      }
    });
  }

  makeQueryString(name, ids) {
    return (
      '( ' +
      ids.reduce((acc, val) => {
        const filterString = `${name}: "${val}"`;
        return acc === '' ? filterString : acc + ' OR ' + filterString;
      }, '') +
      ')'
    );
  }

  getRanges(batch_name = this.state.batch_name, storeToBatches = false) {
    const location_id = this.state.location_id;
    let filter = 'is_destroyed:0 AND is_harvested:0 AND is_packaged:0';

    if (batch_name && batch_name.length ) {
      const batchFilter = this.makeQueryString('batch_name', batch_name.map((batch) => batch.batch_name));
      filter = filter + ` AND ` + batchFilter;
    }

    if (location_id && location_id.length) {
      const locationFilter = this.makeQueryString('location_id', location_id.map((location) => location.id));
      filter = filter + ` AND ` + locationFilter;
    }

    const params = {
      query: 'matchall',
      filter,
      fields: [
        'id',
        'plant_id',
        'batch_num',
        'batch_name',
        'strain_id',
        'stage_id',
        'phenotype_id',
        'location_id',
        'location_name',
        'planting_date',
        'strain_name'
      ],
      sort: 'batch_num asc',
      size: 100000
    };
    return this.props.actions.getSearchData(`/api/search/plants`, storeToBatches ? dataNames.batches : dataNames.plants, null, params).then(() => {});
  }

  filterPlants(field, value, batch) {
    const { plants } = this.props;
    const { plant_range_start, plant_range_end } = this.state;

    const data = [];

    for (const batchName in plant_range_end) {
      let start;

      if (!plant_range_start || !plant_range_start[batchName]) {
        for (const key in plants) {
          const plant = plants[key];
          if (plant.batch_name === batchName) {
            start = plant.plant_id;
            break;
          }
        }
      } else {
        start = plant_range_start[batchName];
      }

      plants
        .filter((plant) => {
          return plant.batch_name === batchName;
        })
        .filter((plant) => {
          const plantId = plant.plant_id ? parseInt(plant.plant_id.split('.')[1]) : undefined;
          const startingPlantId = start ? parseInt(start.split('.')[1]) : undefined;
          const endingPlantId = plant_range_end[batchName]
            ? parseInt(plant_range_end[batchName].split('.')[1])
            : undefined;
          return plantId >= startingPlantId && plantId <= endingPlantId;
        })
        .forEach((plant) => {
          data.push(plant);
        });
    }

    this.setState({
      includedPlants: data
    });

    this.props.actions.handleComplexSelectRow(data, dataNames.plants, 'set');
    this.props.actions.change(formName, 'selectedPlants', data);
  }

  onPlantFilterChange(field, value, batch = null) {
    this.adjustTagRequestOnStageChange(value);

    if (field !== 'plant_range_start' && field !== 'plant_range_end') {
      this.setState({
        [field]: value
      });
    }

    if (!value || value.length === 0) {
      return;
    }

    switch (field) {
    case 'location_id':
      this.getBatches(value);
      this.props.actions.handleComplexSelectRow([], dataNames.plants, 'clear');
      break;
    case 'selected_batch_name': {
      const previousSelectedPlantingBatches = this.state.selectedPlantingBatches
          ? this.state.selectedPlantingBatches
          : [];

      this.setState({
        hideRange: false
      });

      this.getRanges(value).then(() => {
        if (previousSelectedPlantingBatches.length > value.length) {
          const batches = value.map((batch) => batch.batch_name);

          const data = this.state.includedPlants.filter((plant) => {
            return batches.includes(plant.batch_name);
          });

          this.setState({
            includedPlants: data
          });

          this.props.actions.change(formName, 'selectedPlants', data);
        } else if (value.length >= previousSelectedPlantingBatches.length) {
          const batchAdded = value
            .filter((batch) => {
              return !previousSelectedPlantingBatches.map((batch) => batch.batch_name).includes(batch.batch_name);
            })
            .pop();

          if (batchAdded) {
            const { plants } = this.props;
            const endingPlant = plants
              .filter((plant) => plant.batch_name === batchAdded.batch_name)
              .sort((a, b) => {
                return a.id < b.id;
              })
              .pop();

            if (endingPlant) {
              this.onPlantFilterChange('plant_range_end', endingPlant.plant_id, batchAdded);
            }
          }
        }

        this.setState({
          selectedPlantingBatches: value
        });
      });

      break;
    }
    case 'plant_range_start':
      if (!this.state.plant_range_start) {
        this.state.plant_range_start = {};
      }
      this.state.plant_range_start[batch.batch_name] = value;
      this.filterPlants(field, value, batch);
      break;
    case 'plant_range_end':
      if (!this.state.plant_range_end) {
        this.state.plant_range_end = {};
      }
      this.state.plant_range_end[batch.batch_name] = value;
      this.filterPlants(field, value, batch);
      break;
    }
  }

  toggleInvPlants() {
    const { showInvPlants } = this.state;
    this.setState({ showInvPlants: !showInvPlants });
  }

  calculatePlantsWaste(value) {
    this.props.actions.change(formName, 'report_waste_total', value);
    const { selectedPlants } = this.props;

    const dividedTWR = (Math.floor((value / selectedPlants.length) * 100) / 100).toFixed(2) || '0.00';
    const topTWR = (value - dividedTWR * (selectedPlants.length - 1)).toFixed(2) || '0.00';
    const plants = selectedPlants.map((plant, index) => {
      return {
        ...plant,
        waste_weight: !index ? topTWR : dividedTWR
      };
    });
    this.props.actions.change(formName, 'selectedPlants', plants);
  }

  onChangeRequestTag(value) {
    this.props.actions.change(formName, 'tag_requested', value);

    if (value === undefined || value === null) {
      const { selectedPlants } = this.props;
      const plants = selectedPlants.map((plant, index) => {
        return {
          ...plant
        };
      });
      this.props.actions.change(formName, 'selectedPlants', plants);
      return;
    }

    const { selectedPlants } = this.props;
    const plants = selectedPlants.map((plant, index) => {
      return {
        ...plant,
        tag_requested: value
      };
    });
    this.props.actions.change(formName, 'selectedPlants', plants);
  }

  switchPage(pageNumber) {
    this.setState({ activePage: pageNumber });
  }

  onTagChange(tag, count, isQueriedTag, queriedTag) {
    const tracking_tag_api = this.props.isSequentialTrackingTagCheckToggled
      ? '/api/metrc/tracking_tags/sequential'
      :  '/api/metrc/tracking_tags';

    if (this.props.integrationState.isMetrc) {
      if (tag) {
        this.props.actions.getData(
          tracking_tag_api,
          dataNames.trackingIdsBegin,
          { failed: 'tracking.getTrackingIds.failed' },
          { type: 'plant', per_page: count, starting_tag: tag.text, is_inactive: 0},
          (data) => {
            // using the feature flag to make sure we don't break anything until this is vetted
            if (this.props.isSequentialTrackingTagCheckToggled) {
              const tagIds = data.tracking_tags.map((tag) => {
                return get(tag, 'tag');
              });

              if (!data.enough_sequential_tags) {
                this.props.actions.change(formName, 'beginning_tag', null);
                this.props.actions.change(formName, 'ending_tag', null);
                this.props.actions.addMessage(messageTypes.error, ['plants.modify.noSequentialTagsError', {count}]);
                this.setState({
                  notEnoughTags: true,
                  endingTag: '',
                  selectedTags: [],

                });
              } else {
                this.setState({
                  endingTag: tagIds[count - 1],
                  selectedTags: tagIds,
                });
              }
            } else {
              if (data.length > 1) {
                const tagIds = data.map((tag) => {
                  const result = /[1-9][0-9]+$/.exec(get(tag, 'tag'));
                  return parseInt(get(result, '0'));
                });

                for (let i = 0; i < tagIds.length; i++) {
                  const nextTagId = get(tagIds, i + 1);

                  // e.g. [43000001620, 43000001621, 43000001623] = false
                  if (nextTagId && tagIds[i] + 1 !== nextTagId) {
                    this.props.actions.change(formName, 'beginning_tag', null);
                    this.setState({endingTag: ''});
                    this.props.actions.addMessage(messageTypes.error, ['plants.modify.noSequentialTagsError', {count}]);
                    return;
                  }
                }
              }

              if (data.length < count) {
                this.setState({notEnoughTags: true, endingTag: ''});
              } else {
                this.setState({endingTag: data[count - 1].tag});
              }
            }
          }
        );
      } else if (isQueriedTag && queriedTag) {
        // This will fire when a user is querying a tag that isn't shown
        // in the dropdown, but might exist on the back end.
        this.props.actions.getData(
          '/api/metrc/tracking_tags',
          dataNames.trackingIds,
          { failed: 'tracking.getTrackingIds.failed' },
          { type: 'plant', per_page: 100, is_inactive: 0, is_queried_tag: true, queried_tag: queriedTag },
          () => {
            this.setState({ endingTag: '' });
          }
        );
      } else if (!tag && !isQueriedTag) {
        // This will fire when the tag or query is completely removed from
        // the search, resetting the dropdown to its original state.
        this.props.actions.getData(
          '/api/metrc/tracking_tags',
          dataNames.trackingIds,
          { failed: 'tracking.getTrackingIds.failed' },
          { type: 'plant', per_page: 100, is_inactive: 0 },
          () => {
            this.setState({ endingTag: '' });
          }
        );
      }
    }
  }

  validatePlantCompliance(stage_id) {
    const currentStage = this.props.stages.find((st) => st.id === this.props.currentStage) || {};
    const newStage = this.props.stages.find((st) => st.id === stage_id);
    const amount = this.props.selectedPlants.length;
    let submitDisabled = false;
    if (currentStage && currentStage.code === 'flower') {
      if (newStage && (newStage.code === 'prop' || newStage.code === 'veg')) {
        submitDisabled = !this.props.actions.validatePlants('immature', amount);
      }
    } else {
      if (newStage && newStage.code === 'flower') {
        submitDisabled = !this.props.actions.validatePlants('flowering', amount);
      }
    }
    this.setState({ submitDisabled });
  }

  adjustTagRequestOnStageChange(stage_id) {
    let tag_request = null;
    if (stage_id == null) {
      stage_id = this.props.currentStage;
    }
    if (this.props.currentStage === 2) {
      tag_request = stage_id !== 2 ? 1 : 0;
    } else {
      // if (tag_request = (stage_id !== 2) ? null : 0;
      if (stage_id === 2) {
        tag_request = 0;
      }
    }

    this.props.actions.change(formName, 'tag_requested', tag_request);

    if (tag_request == null) {
      // reset all the individual plants dropdown
      const { selectedPlants } = this.props;
      const plants = selectedPlants.map((plant, index) => {
        return {
          ...plant
        };
      });
      this.props.actions.change(formName, 'selectedPlants', plants);
      return;
    }

    // add all the individual plant dropdowns as well
    const { selectedPlants } = this.props;
    const plants = selectedPlants.map((plant, index) => {
      return {
        ...plant,
        tag_requested: tag_request
      };
    });
    this.props.actions.change(formName, 'selectedPlants', plants);
  }

  moreTagsButton() {
    this.props.actions.push('/integration/tracking');
  }

  showTagsElement() {
    this.setState({
      notEnoughTags: false
    });
  }

  searchPlants(data) {
    if (data.length) {
      const plantIds = data.split(/[, ;\n]+/g).join('" "');
      const stageId = plantStages[this.props.location.query.stage].value;
      const params = {
        filter:
          `is_destroyed:0 AND is_harvested:0 AND is_packaged:0 AND ` +
          `(plant_id:("${plantIds}") ` +
          `OR external_identifier:("${plantIds}") ` +
          `OR state_integration_tracking_id:("${plantIds}") ` +
          `OR (batch_name:("${plantIds}") AND stage_id:("${stageId}")))`,
        sort: 'strain_name asc,planting_date asc,plant_id asc',
        size: 100000,
        query: 'matchall'
      };
      this.props.actions.getSearchData(`/api/search/plants`, dataNames.plants, null, params).then((response) => {
        this.props.actions.handleComplexSelectRow(response, dataNames.plants, 'set');
        const numPlants = !Array.isArray(response) || response.length === 0 ? undefined : response.length;
        this.props.actions.change(formName, 'num_plants', numPlants);
        this.props.actions.change(formName, 'max_plants', numPlants);
        if (response.length) {
          this.setState({ hideBulkFields: true });
        }
      });
    }
  }

  scanFieldKeyPressed(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.searchPlants(event.target.value.trim());
    }
  }

  onLocationScanChange(event) {
    const {
      target: { value }
    } = event;
    this.setState({ locationScanData: value });
  }

  /***
   * Sets drops downs from scanned code.
   * @param event
   * @returns {boolean}
   */
  onLocationKeyPress(event) {
    // handleKeyPress
    const controlCodes = ['tab', 'enter'];
    if (controlCodes.indexOf(event.key.toLowerCase()) === -1) return true; // Not control, let onLocationScanChange handle it
    if (this.state.locationScanData.length === 0) return true; // If no string stored then let the control do what it does
    event.preventDefault();
    event.stopPropagation();
    this.setState({ showLoader: true, showLoadingMessage: true, loadingMessage: 'gettingLocationFromBarCodeScan!' });

    const findLocation = (value, field = 'name') => {
      if (!value) value = this.state.locationScanData;
      return Object.keys(this.props.locationCollections).reduce((acc, collectionKey) => {
        if (acc) return acc;
        const location = this.props.locationCollections[collectionKey].find((location) => {
          return field === 'name' ? location.name.toLowerCase() === value.toLowerCase() : location[field] === value;
        });
        if (location) acc = { ...location, collection: collectionKey };
        return acc;
      }, false);
    };

    const findLocationParents = (location) => {
      const locationsIndex = ['buildings', 'rooms', 'zones', 'sections'];
      const locations = { buildings: false, rooms: false, zones: false, sections: false };
      locations[location.collection] = location;
      let index = locationsIndex.indexOf(location.collection);
      if (index > 0) {
        while (index > -1) {
          if (location.parent_location_id !== null) {
            location = findLocation(location.parent_location_id, 'id');
            if (location) locations[location.collection] = location;
          }
          index--;
        }
      }
      return locations;
    };

    const location = findLocation();
    const locations = findLocationParents(location);
    this.setState({ showLoader: false, showLoadingMessage: false, locationScanData: '', scannedLocations: locations });
  }

  /**
   * When do we need to split a batch?
   * Every time the strain changes
   * or location changes
   *
   * The location change will only require a tag if
   * plants are from different batches
   * plants are all from same batch, but not using all the plants
   *
   * @param formValues
   * @returns {boolean}
   */
  shouldSplitPlantBatch (formValues) {
    const {currentStage, selectedStage, isSplitPlantBatchToggled, batches, integrationState, selectedPlants, selectedStrainId, selectedSectionId} = this.props;

    const plantCount = selectedPlants && selectedPlants.length ? selectedPlants.length : 0;

    const numPlants = parseInt(get(formValues, 'num_plants', selectedPlants.length));
    let notUsingAllPlantsInBatch = numPlants < plantCount;

    const allPlantsFromSameBatch = this.checkPlantsAreFromSameBatch();

    // The only time we are forced to create a new batch is when we are moving partial batches
    // of immature plants to a new location and keeping them in an immature phase, this is because metrc
    // is only aware of immature plants in entire batches, as soon as they reach maturity you can move them
    // to any location and have awareness of where they are while maintaining the original batch
    const isImmaturePhase = this.isImmaturePhase(selectedStage) && this.isImmaturePhase(currentStage);

    if (allPlantsFromSameBatch && batches && batches.length) {
      notUsingAllPlantsInBatch = plantCount !== batches.length;
    }

    return isSplitPlantBatchToggled
      && integrationState.isMetrc
      && (notUsingAllPlantsInBatch ||  Boolean(selectedStrainId))
      && allPlantsFromSameBatch
      && (Boolean(selectedSectionId) || Boolean(selectedStrainId))
      && isImmaturePhase;
  }

  /**
   * Currently only for Metrc Ca
   * Only allow if updating plants from same batch
   * @return boolean
   */
  shouldShowStrainField() {
    const {integrationState, isSplitPlantBatchToggled, selectedStage, currentStage} = this.props;
    return integrationState.isMetrc
      && isSplitPlantBatchToggled
      && this.checkPlantsAreFromSameBatch()
      && this.isImmaturePhase(selectedStage)
      && this.isImmaturePhase(currentStage);
  }

  checkPlantsAreFromSameBatch() {
    const {selectedPlants} = this.props;
    let allPlantsFromSameBatch = false;

    const plantingBatchIds = selectedPlants.map((plant) => plant.planting_batch_id);
    if (plantingBatchIds.length) {
      allPlantsFromSameBatch = new Set(plantingBatchIds).size === 1;
    }
    return allPlantsFromSameBatch;
  }

  isImmaturePhase(phase) {
    const { integrationState } = this.props;
    const maturePhases = integrationState.isMetrc && integrationState.isCaMetrc ? [plantStages.flowering] : [plantStages.flowering, plantStages.vegetation];
    return !maturePhases.find(maturePhase => maturePhase.value === phase);
  }

  render() {
    const {
      integrationState,
      metrcTags,
      locations,
      currentStage,
      stagesMinusCurrent,
      batches,
      plants,
      trackingIds,
      initialValues,
      sectionLocations,
      complianceSettings,
      hasPlantsTags,
      plantCupos,
      isUtahGlobalIdGenerationFeatureEnabled,
    } = this.props;
    const {
      showPrinter,
      labelTag,
      labelIds,
      location_id,
      endingTag,
      notEnoughTags,
      includedPlants,
      submitDisabled,
      hideBulkFields,
      scannedLocations
    } = this.state;
    const selectableLocations =
      sectionLocations &&
      sectionLocations.filter((location) => location_id.map((location) => location.id).indexOf(location.id) === -1);

    const splitPlantBatch = this.shouldSplitPlantBatch(initialValues);
    const showStrainField = this.shouldShowStrainField();

    return (
      <FormWrapper className='modify-plant-page' title={'plants.modify.title'} goBack={this.redirect}>
        <InProgressOverlay
          isActive={this.state.showLoadingMessage}
          messageObject={this.state.messageObject}
          onDismiss={this.state.onDismiss}
          showOk={this.state.showOk}
          showLoader={this.state.showLoader}
          message={this.state.loadingMessage}
          translate={true}
        />

        {hideBulkFields ? null : (
          <Row>
            <Col md={6}>
              <ScanInputFormWrapper
                handleKeyPress={this.scanFieldKeyPressed}
                onSubmit={this.searchPlants}
                placeholder={I18n.t('plants.modify.idsSearchPrompt')}
              />
            </Col>
            <Col md={6}>
              <InputGroup>
                <FormControl
                  type='text'
                  placeholder={I18n.t('cart.items.locationsScanField')}
                  onKeyDown={this.onLocationKeyPress}
                  onChange={this.onLocationScanChange}
                  value={this.state.locationScanData}
                />
                <InputGroup.Append>
                  <InputGroup.Text>
                    <FaBarcode />
                  </InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
              <div className='text-muted' style={{ fontSize: 'smaller', marginTop: '4px' }}>
                Scan a location label (Room, Zone, or Section) to auto complete the location selection fields.
              </div>
            </Col>
          </Row>
        )}
        <ModifyPlantFormWrapper
          submitDisabled={submitDisabled}
          validatePlantCompliance={this.validatePlantCompliance}
          onSubmit={this.onSubmit}
          saveAndPrint={this.saveAndPrint}
          saveOnePlant={this.saveOnePlant}
          saveComplete={this.state.saveComplete}
          strains={this.props.strains}
          selectableLocations={selectableLocations}
          initialValues={initialValues}
          enableReinitialize={true}
          keepDirtyOnReinitialize={true}
          stages={stagesMinusCurrent}
          currentStage={currentStage}
          onPlantFilterChange={this.onPlantFilterChange}
          itemsPerPage={this.state.itemsPerPage}
          activePage={this.state.activePage}
          batches={batches}
          plants={plants}
          includedPlants={includedPlants}
          showInvPlants={this.state.showInvPlants}
          toggleInvPlants={this.toggleInvPlants}
          switchPage={this.switchPage}
          showBulkFields={!hideBulkFields}
          hideRange={this.state.hideRange}
          integrationState={integrationState}
          trackingIds={trackingIds}
          metrcTags={metrcTags}
          metrcTagEnd={endingTag}
          notEnoughTags={notEnoughTags}
          moreTagsButton={this.moreTagsButton}
          showTagsElement={this.showTagsElement}
          handleTagChange={this.onTagChange}
          locations={locations}
          calculatePlantsWaste={this.calculatePlantsWaste}
          form={formName}
          scannedLocations={scannedLocations}
          complianceSettings={complianceSettings}
          hasPlantsTags={hasPlantsTags}
          onChangeRequestTag={this.onChangeRequestTag}
          plantCupos={plantCupos}
          splitPlantBatch={splitPlantBatch}
          showStrainField={showStrainField}
          isUtahGlobalIdGenerationFeatureEnabled={isUtahGlobalIdGenerationFeatureEnabled}
        />
        <PrinterModal
          ref='printerModal'
          forceLabelBlocks={true}
          showPrinter={showPrinter}
          hidePrinter={this.hidePrinter}
          labelTag={labelTag}
          labelIds={labelIds}
        />
      </FormWrapper>
    );
  }
}

ModifyPlantPage.propTypes = {
  stagesMinusCurrent: PropTypes.array,
  actions: PropTypes.object.isRequired,
  selectedPlants: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  stages: PropTypes.array.isRequired,
  batches: PropTypes.array,
  strains: PropTypes.array.isRequired,
  plants: PropTypes.array,
  includedPlants: PropTypes.array,
  metrcTags: PropTypes.array,
  trackingIds: PropTypes.array.isRequired,
  currentStage: PropTypes.number,
  timezone: PropTypes.string,
  validateTrackingIdsAvailability: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  sectionLocations: PropTypes.array.isRequired,
  complianceSettings: PropTypes.object.isRequired,
  integrationState: PropTypes.object,
  hasPlantsTags: PropTypes.bool,
  maturePlantsRequireTracking: PropTypes.bool,
  maturePlantsRequireTrackingErrorMessage: PropTypes.string,
  plantCupos: PropTypes.array,
  isSplitPlantBatchToggled: PropTypes.bool,
  isSequentialTrackingTagCheckToggled: PropTypes.bool,
  isUtahGlobalIdGenerationFeatureEnabled: PropTypes.bool,
  selectedStrainId: PropTypes.number,
  selectedSectionId:  PropTypes.number,
};

function mapStateToProps(state) {
  const currentStage = state.selectedPlants[0] ? state.selectedPlants[0].stage_id : 0;
  const stageOrder = { Propagation: 0, Vegetation: 1, Flowering: 2 };
  const schedules = state.feedingSchedules
    .filter((schedule) => {
      return schedule.facility_id === state.facility.id;
    })
    .sort((a, b) => {
      return a.schedule_name < b.schedule_name ? -1 : 1;
    });
  const stagesMinusCurrent = state.stages
    .sort((a, b) => {
      return stageOrder[a.stage_name] < stageOrder[b.stage_name] ? -1 : 1;
    })
    .filter((stage) => {
      return stage.id !== currentStage;
    });
  const locations =
    getFlatBuildings(state)
      .filter((location) => location.facility_id === state.facility.id)
      .sort((a, b) => {
        return a.name < b.name ? -1 : 1;
      }) || [];
  const timezone = state.timezone;
  const selectedPlants = state.selectedPlants.map((plant) => {
    if (plant.tag_requested === null || plant.tag_requested === undefined) {
      plant.tag_requested = 0;
    }
    return plant;
  });

  const selector = formValueSelector(MODIFY_PLANT_FORM);
  const selectedStrainId = selector(state, 'strain_id') || 0;
  const selectedSectionId = selector(state, 'section_id') || 0;
  const selectedStage = selector(state, 'stage_id') || null;

  const sectionLocations = locations.filter((location) => location.level === 3);
  return {
    stagesMinusCurrent: stagesMinusCurrent,
    plants: state.plants,
    plantCupos: state[dataNames.cupos],
    selectedPlants,
    locations,
    schedules,
    currentStage,
    sectionLocations,
    selectedStage,
    strains: getOrderedFacilityStrains(state),
    stages: state.stages,
    batches: state.batches,
    motherPlants: getPlantIds(state),
    integrationState: getIntegrationState(state),
    metrcTags: getMetrcTags(state),
    trackingIds: state.trackingIds,
    timezone,
    initialValues: getModifyPlantsInitialValues(state),
    locationCollections: getStorageLocationCollections(state),
    complianceSettings: state.complianceSettings,
    hasPlantsTags: hasPlantsTags(state),
    maturePlantsRequireTracking: maturePlantsRequireTracking(state),
    maturePlantsRequireTrackingErrorMessage: maturePlantsRequireTrackingErrorMessage(state),
    isSplitPlantBatchToggled: isFeatureEnabled(state)('feature_split_plant_batches'),
    isSequentialTrackingTagCheckToggled: isFeatureEnabled(state)('feature_sequential_tracking_tags_check'),
    isUtahGlobalIdGenerationFeatureEnabled: isFeatureEnabled(state)('feature_utah_global_id_generation'),
    selectedStrainId,
    selectedSectionId,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...apiActions,
    push,
    goBack,
    change,
    setData,
    unsetData,
    stopSubmit,
    addMessage,
    validatePlants,
    handleComplexSelectRow,
    fetchModalitiesByStrainId,
    fetchMetrcTrackingIdsForSelectInput
  };

  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default reduxMetrcIdAvailability(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ModifyPlantPage)
);
