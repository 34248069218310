import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { FieldArray } from 'redux-form';
import { Alert, Col, Row } from 'react-bootstrap';
import {FaExclamationTriangle} from 'react-icons/fa';
import get from 'lodash.get';
import IncomingProductsFieldArray from './IncomingProductsFieldArray';
import InternationalDisplayQty from '../../../common/InternationalDisplayQty';

const ProductsMap = ({
  incomingTransferProductsOptions,
  purchaseOrderInfo,
  error,
  formErrors,
  itemMasterMappings
}) => {
  let lines = get(purchaseOrderInfo, 'lines', []);
  lines = Array.isArray(lines) ? lines : [];

  return (
    <Row>
      <Col xs={12}>
        <Row>
          <Col xs={4}>
            <h4>{I18n.t('supplyChainMapping.form.purchaseOrderProducts')}</h4>
          </Col>
          <Col xs={8}>
            <h4>{I18n.t('supplyChainMapping.form.incomingProducts')}</h4>
          </Col>
        </Row>
      </Col>

      {error ? (
        <Col xs={12}>
          <Alert variant='danger'>
            <FaExclamationTriangle />
            <span className='padding-left'>{error}</span>
          </Alert>
        </Col>
      ) : (
        <Col xs={12}>
          <Alert variant='info'>
            <FaExclamationTriangle />
            <span className='padding-left'>{I18n.t('supplyChainMapping.form.allTheProductsHaveToBeMapped')}</span>
          </Alert>
        </Col>
      )}

      <Col xs={12}>
        {lines.map((line, lineIndex) => {
          return (
            <Row key={lineIndex}>
              <Col xs={4}>
                <Row>
                  <Col md={8}>
                    <h4>{I18n.t('supplyChainMapping.form.productName')}</h4>
                    {line.item_master_name}
                  </Col>
                  <Col md={4}>
                    <h4>{I18n.t('supplyChainMapping.form.quantity')}</h4>
                    <InternationalDisplayQty qty={line.qty_display} uom={line.uom_display} displayUom={true}/>
                  </Col>
                </Row>
              </Col>

              <Col xs={8}>
                <FieldArray
                  name={`product_map_${line.line_id}`}
                  component={IncomingProductsFieldArray}
                  incomingTransferProductsOptions={incomingTransferProductsOptions}
                  line={line}
                  itemMasterMappings={itemMasterMappings}
                  lineName={`product_map_${line.line_id}`}
                  lineId={String(line.line_id)}
                  formErrors={formErrors}
                />
              </Col>
              <Col xs={12}>
                <hr />
              </Col>
            </Row>
          );
        })}
      </Col>
    </Row>
  );
};

ProductsMap.propTypes = {
  meta: PropTypes.object,
  purchaseOrderInfo: PropTypes.object,
  incomingTransferProductsOptions: PropTypes.array,
  formErrors: PropTypes.object,
  error: PropTypes.string,
  selectedPurchaseOrder: PropTypes.object,
  itemMasterMappings: PropTypes.array
};

export default ProductsMap;
