import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {reset} from 'redux-form';
import {postItem} from '../../../../actions/apiActions';
import {addMessage} from '../../../../actions/systemActions';
import {getOrder, getOrderMmapPayments} from '../../../../selectors/ordersSelectors';
import MmapPaymentComponent from  './MmapPaymentComponent';

const form = 'mmapPayment';

function mapStateToProps(state) {
  const {customer} = state;
  return {
    form,
    order: getOrder(state),
    user: state.user,
    payments: getOrderMmapPayments(state),
    customer
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({reset, postItem, addMessage}, dispatch),
  };
}

const MmapPayment = connect(mapStateToProps, mapDispatchToProps)(MmapPaymentComponent);

MmapPayment.propTypes = {
  showLoader: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
};

export default MmapPayment;
