import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import { FiCheckSquare, FiSquare } from 'react-icons/fi';
import * as applicationModes from '../../constants/applicationModes';
import ReactSelectInput from '../common/form/ReactSelectInput';

import InternationalDecimalInput from '../common/form/InternationalDecimalInput';

const EquivalencySettings = (props) => {

  const canadaVolumeUoms = [
    {value: 'MG||ML', text: 'MG / ML'}
  ];
  const ccUsingCanadaVolumeUoms = ['CONCENTRATE', 'CARTPENS'];
  const key_map = props.integrationState.isOhMetrc ? 'metrc_oh_equivalency' : props.patientType;
  const mapFields = props.integrationState.isOhMetrc ? props.metrcOhEqFields : props.eqFields;

  return (
    <div className='equivalency-settings-form sales-settings-form'>
        <div style={{marginBottom: '12px'}}>
          {I18n.t('retail.salesSettings.equivalencyHint')}
          {I18n.t('retail.salesSettings.enabledHint')}
        </div>
        <table className='table table-sm table-bordered'>
          <thead>
          <tr>
            <th>{I18n.t('retail.salesSettings.enabled')}</th>
            <th style={{width:'25%'}}>
              {props.integrationState.isOhMetrc ? I18n.t('retail.salesSettings.gramsOfPlantMaterialTierI') : I18n.t('retail.salesSettings.gramsOfFlower')}
            </th>
            <th style={{textAlign: 'center'}}>=</th>
            <th style={{width:'25%'}}>{I18n.t('retail.salesSettings.quantity')}</th>
            <th style={{width:'25%'}}>{I18n.t('retail.salesSettings.ofProductType')}</th>
            <th style={{width:'25%'}}>{I18n.t('retail.salesSettings.inWeightUom')}</th>
          </tr>
          </thead>
          <tbody>
          {
            mapFields.map((field, index) => {
              const uoms = (field.category_code === 'CLONE' || field.category_code === 'SEED') ? [].concat(props.eqUoms.filter((uom) => uom.type === 'discrete')) : props.eqUoms;
              if(field.category_code === 'MARIJUANA' && (!props.facility || (props.facility && props.facility.country_code !== 'CA'))) return null;
              //if(field.category_code === 'EDIBLE' && (!props.facility || (props.facility && props.facility.country_code === 'CA'))) return null; // Removed 3/28/2018 per product
              const useCanadaVolumes = props.applicationMode === applicationModes.canada && ccUsingCanadaVolumeUoms.indexOf(field.category_code) !== -1;
              const flowerKey = `${key_map}_flower_${field.category_code}`;
              const uomKey = `${key_map}_select_${field.category_code}`;
              const weightKey = `${key_map}_weight_${field.category_code}`;
              const disabledKey = `${key_map}_disabled_${field.category_code}`;
              const flowerValue = parseFloat(props.formSelector(flowerKey));
              const uomValue = props.formSelector(uomKey);
              const weightValue = parseFloat(props.formSelector(weightKey));
              return (<tr key={index} style={(props.integrationState.isOhMetrc && field.category_code == 'plant_material_tier_1') ? {display: 'none'} : {}}>
                <td
                  onClick={(event) => {
                    const newValue = (flowerValue === 0) ? 1 : 0;
                    props.change(flowerKey, newValue);
                    props.change(disabledKey, (newValue === 0));
                    // Check that values being disabled are not invalid... if invalid default
                    if(isNaN(weightValue)) props.change(weightKey, 1);
                    let updateUom = false;
                    if(typeof uomValue === 'string'){
                      if(uomValue.trim() === '') updateUom = true;
                    } else {
                      updateUom = true;
                    }
                    if(updateUom) props.change(uomKey, uoms[0].value);
                  }}
                  style={{verticalAlign: 'middle', textAlign: 'center'}}
                >
                  {
                    flowerValue === 0
                    ? <FiSquare className='text-danger' />
                      : <FiCheckSquare className='text-success' />
                  }

                </td>
                <td style={!flowerValue ? {verticalAlign: 'middle'} : {}}>
                  {flowerValue === 0
                    ? <span>Disabled</span>
                    : <InternationalDecimalInput
                      name={`${key_map}_flower_${field.category_code}`}
                      disabled={flowerValue === 0 || props.disableFields}
                      fractionPartSize={2}
                      props={{
                        style: {width: '100%', margin: '0'},
                        placeholder: '',
                        step:'any'
                      }}
                    />
                  }
                </td>
                <th style={{textAlign: 'center', verticalAlign: 'middle'}}>=</th>
                <td>
                  <InternationalDecimalInput
                    name={`${key_map}_weight_${field.category_code}`}
                    disabled={flowerValue === 0 || props.disableFields}
                    fractionPartSize={2}
                    props={{
                      style: {width: '100%', margin: '0'},
                      placeholder: '',
                      step:'any',
                    }}
                  />
                </td>
                <td style={{verticalAlign:'middle'}}>{field.text}</td>
                <td>
                  <Field
                    name={`${key_map}_select_${field.category_code}`}
                    disabled={!flowerValue || props.disableFields}
                    clearable={false}
                    component={ReactSelectInput}
                    props={{
                      options: useCanadaVolumes ? canadaVolumeUoms : uoms
                    }}
                  />
                </td>
              </tr>);
            })
          }
        </tbody>
      </table>
    </div>
  );
};

EquivalencySettings.propTypes = {
  fields: PropTypes.shape({
    push: PropTypes.func.isRequired,
    map: PropTypes.func.isRequired,
  }),
  eqFields: PropTypes.array.isRequired,
  metrcOhEqFields: PropTypes.array.isRequired,
  eqUoms: PropTypes.array.isRequired,
  disableFields: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  applicationMode: PropTypes.string.isRequired,
};

export default EquivalencySettings;
