import {requiredFieldValidation} from '../common/form/redux-form/validations';
import {isCategoryPlants} from '../../selectors/categorySelectors';

export const validateCategoryManagementForm = (values, props) => {

  const errors = []; // Is nested before returning to create object
  values = values.categoriesData;
  const isActiveAndHasActiveProducts = (sub) => sub && sub.active && sub.product_counts ? sub.product_counts.active : false;

  const fieldValidations = {
    metrc_item_category_id: {
      preCondition: isActiveAndHasActiveProducts,
      test: 'metrc_item_category_id',
      message: 'categoryManagement.metrcCategory'
    },
    ohmetrc_category_id: {
      preCondition: isActiveAndHasActiveProducts,
      test: 'ohmetrc_category_id',
      message: 'categoryManagement.salesRulesCategory'
    },
    leaf_category_id: {
      preCondition: isActiveAndHasActiveProducts,
      test: 'leaf_category_id',
      message: 'categoryManagement.leafCategory'
    },
    leafly_category_id: {
      preCondition: isActiveAndHasActiveProducts,
      test: 'leafly_category_id',
      message: 'categoryManagement.leaflyCategory'
    },
    weedmaps_category_id: {
      preCondition: isActiveAndHasActiveProducts,
      test: 'weedmaps_category_id',
      message: 'categoryManagement.weedmapsCategory'
    },
    isolocity_category_id: {
      preCondition: isActiveAndHasActiveProducts,
      test: 'isolocity_category_id',
      message: 'categoryManagement.isolocityCategory'
    },
    cure_form_ids: {
      preCondition: (sub, category) => !isCategoryPlants(category) && isActiveAndHasActiveProducts(sub),
      test: (sub) => sub.cure_form_ids && sub.cure_form_ids.length > 0,
      message: 'categoryManagement.deliveryMethod'
    },
    cure_order_type_id: {
      preCondition: (sub, category) => !isCategoryPlants(category) && isActiveAndHasActiveProducts(sub),
      test: 'cure_order_type_id',
      message: 'categoryManagement.highLowTHC'
    },
    canada_category_id: {
      preCondition: isActiveAndHasActiveProducts,
      test: 'canada_category_id',
      message: 'categoryManagement.reportingMapping'
    },
    oklahoma_category_id: {
      preCondition: isActiveAndHasActiveProducts,
      test: 'oklahoma_category_id',
      message: 'categoryManagement.reportingMapping'
    },
    sales_limit_category_key: {
      preCondition: (sub) => props.hasSalesRulesEnabled && isActiveAndHasActiveProducts(sub),
      test: 'sales_limit_category_key',
      message: 'categoryManagement.rulesCategory',
    }
  };

  values.forEach((category, index) => {
    errors.push({subcategories: []});
    category.subcategories.forEach((sub) => {
      const subErrors = {};
      Object.keys(fieldValidations).forEach((field) => {
        const validate = fieldValidations[field];
        if(validate.preCondition && !validate.preCondition(sub, category)) return true; // skip test
        subErrors[field] = requiredFieldValidation((typeof validate.test === 'function') ? validate.test(sub) : sub[validate.test], validate.message);
      });
      errors[index].subcategories.push(subErrors);
    });
  });

  return {categoriesData: errors};
};

export default validateCategoryManagementForm;
