/* eslint-disable import/no-named-as-default */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack} from 'react-router-redux';

import FormWrapper from '../../../common/form/FormWrapper';
import AlertSettingsForm from './AlertSettingsForm';

export class AlertSettingsPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  onSubmit(event) {
    event.preventDefault();
    this.redirect();
  }

  redirect() {
    this.props.actions.goBack();
  }

  render() {
    return (
      <FormWrapper className={'alert-settings-page'} title='cultivation.alertSettings.title' goBack={this.redirect}>
        <AlertSettingsForm onSubmit={this.onSubmit}/>
      </FormWrapper>
    );
  }
}

AlertSettingsPage.propTypes = {
  actions: PropTypes.shape({
    goBack: PropTypes.func.isRequired
  }).isRequired,
  state: PropTypes.object
};

function mapStateToProps(state) {
  return {state};
}

function mapDispatchToProps (dispatch) {
  const actions = {goBack};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertSettingsPage);
