import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Row, Table, Card} from 'react-bootstrap';
import ContentConcealer from '../../../common/concealers/ContentConcealer';
import {getTranslationLabel} from '../../../../util/translationHelpers';

const getLabel = getTranslationLabel('batchHistory.model.');

const HarvestBatchUpdatedDetails = ({delta, model, integrationState}) => {

  return (
    <Card>
      <Row>
        <Col xs={6}>
          <h3>{I18n.t('batchHistory.model.harvestBatchUpdateDetails')}</h3>
          <Table striped className='plant-details'>
            <thead>
            <tr>
              <th>{I18n.t('plantHistory.field')}</th>
              <th>{I18n.t('plantHistory.valueAdjustment')}</th>
            </tr>
            </thead>
            <tbody>
            {delta.filter(field => ['otherMaterialWaste', 'waste'].indexOf(field.name) === -1).map((field) => {
              if(field.name === 'otherMaterialWeight' && !integrationState.isWaLeaf) return null;
              return (<tr key={field.name}>
                <td>{getLabel(field.name, integrationState)}</td>
                <td>{field.prev} -> {field.current}</td>
              </tr>);
            })}
            <ContentConcealer show={Boolean(model.waste_weight)}>
              <tr>
                <td>{getLabel('totalWasteReported', integrationState)}</td>
                <td>{model.waste_weight} GR</td>
              </tr>
            </ContentConcealer>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Card>
  );
};

HarvestBatchUpdatedDetails.propTypes = {
  delta: PropTypes.array.isRequired,
  model: PropTypes.object.isRequired,
  integrationState: PropTypes.object.isRequired,
};

export default HarvestBatchUpdatedDetails;
