import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Row, Col, Form, Button} from 'react-bootstrap';
import get from 'lodash.get';
import {FaMinus} from 'react-icons/fa';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import NumericInput from '../../common/form/NumericInput';
import AddItem from '../../common/form/AddItem';

const IngredientsFieldArray = ({fields, ingredients, isDisabled, formValues, change}) => {
  const {push, remove, length} = fields;

  const getIngredientOptions = (fieldName) => {
    if (!ingredients) {
      return [];
    }

    // Filter out all options that are already used
    const ingredientOptions = ingredients.filter((ingredient) => fields.map((n, i) => fields.get(i).id).indexOf(ingredient.id) === -1);

    // Add the selected option for the current field back to the front of the list
    const selectedIngredientId = get(formValues, fieldName);
    if (selectedIngredientId) {
      const selectedIngredient = ingredients.find((ingredient) => ingredient.id === selectedIngredientId);
      if (selectedIngredient) {
        return [...[selectedIngredient], ...ingredientOptions];
      }
    }
    // Otherwise just return the list of unused options
    return ingredientOptions;
  };

  return (
    <React.Fragment>
      <Row>
        <Col xs={12} style={{marginTop: '12px'}}>
          <h3 className='panel-title'>{I18n.t('packaging.form.ingredients')}</h3>
        </Col>
      </Row>
      {length > 0 &&
        <Row>
          <Col xs={6}><Form.Label>{I18n.t('packaging.form.ingredient.name')}</Form.Label></Col>
          <Col xs={2}><Form.Label>{I18n.t('packaging.form.ingredient.quantity')}</Form.Label></Col>
        </Row>
      }
      {fields.map((ingredientName, ingredientIndex) => {
        return (
          <Row key={ingredientIndex}>
            <Col xs={6}>
              <Field name={`${ingredientName}.id`} component={ReactSelectInput} props={{
                options: getIngredientOptions(`${ingredientName}.id`),
                valueKey: 'id',
                textKey: 'name',
                disabled: isDisabled,
                onChange: (value) => {
                  const ingredient = ingredients.find((option) => option.id === value);
                  change(`${ingredientName}.id`, value);
                  change(`${ingredientName}.default_uom`, get(ingredient, 'default_uom'));
                }
              }}/>
            </Col>
            <Col xs={2}>
              <Field name={`${ingredientName}.qty`} component={NumericInput} props={{
                fractionPartSize: 0,
                allowNegativeNumber: false,
                rightAddon: <span>{get(formValues, `${ingredientName}.default_uom`)}</span>,
                disabled: isDisabled,
              }}/>
            </Col>
            <Col xs={2}>
              <Button variant={'danger'} size='sm' disabled={isDisabled} onClick={() => remove(ingredientIndex)}>
                <FaMinus/>
              </Button>
            </Col>
          </Row>
        );
      })}
      <Row style={{marginTop: '-24px', paddingBottom: '6px', borderBottom: '1px solid #ccc'}}>
        <Col xs={12}>
          <AddItem
            textMode={'right'}
            alignMode={'left'}
            text={'packaging.form.addIngredient'}
            addAction={() => {
              push({});
            }}
            addDisabled={isDisabled}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

IngredientsFieldArray.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    get: PropTypes.func.isRequired,
  }).isRequired,
  ingredients: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  formValues: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired
};

export default IngredientsFieldArray;
