import React from 'react';
import {connect} from 'react-redux';
import { I18n } from 'react-redux-i18n';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {formValueSelector, getFormValues, reduxForm, Field, FieldArray} from 'redux-form';
import {Row, Col, FormGroup} from 'react-bootstrap';
import {FaCalendarAlt, FaDollarSign, FaExclamationTriangle} from 'react-icons/fa';
import {INVENTORY_RECEIPT_FORM_METRC} from '../../../../constants/forms';
import CurrencyInput from '../../../common/form/CurrencyInput';
import TextInput from '../../../common/form/TextInput';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import InventoryReceiptPackagesFieldArray from './InventoryReceiptPackagesFieldArray';
import SubmitSection from './SubmitSection';
import {userHasPermission} from '../../../../selectors/usersSelectors';
import * as p from '../../../../constants/permissions';
import InternationalDateTimePickerInput from '../../../common/form/InternationalDateTimePickerInput';
import DriverAndVehicleInformation from '../common/DriverAndVehicleDetail';

export const InventorReceiptForm = (props) => {
  const {
    locked,
    getFormValue,
    formValues,
    change,
    reset,
    submitting,
    handleSubmit,
    handleSaveAndPrintLabels,
    handlePrintLabels,
    storageLocations,
    drivers,
    vehicles,
    metrcSettings,
    inventoryReceipt,
    purchaseOrder,
    itemMasters,
    strainOptions,
    testResults,
    testResultDimensions,
    isCreateMode,
    hasPrintPermission,
  } = props;

  const updateTotalInvoiceAmount = (fieldName, value) => {
    if (!formValues || !formValues.lines)
      return;

    // Sum up line item price of packages
    // If edited (i.e. line_item_price_edit has a value, use that one, otherwise use line_item_price)
    // For specified fieldName use value
    const updatedIndex = fieldName ? parseInt(fieldName.split('.')[1]) : -1;
    let total_invoice_amount = 0;
    formValues.lines.forEach((line, index) => {
      // Only add if package is received
      const received = getFormValue(`${fieldName}.inventory.0.state_integration_package_status`) === 'Accepted';
      if (received) {
        if (updatedIndex === index) {
          total_invoice_amount += parseFloat(value);
        } else {
          total_invoice_amount += getFormValue(`lines.${index}.line_item_price_edit`)
            ? parseFloat(getFormValue(`lines.${index}.line_item_price_edit`))
            : parseFloat(getFormValue(`lines.${index}.line_item_price`));
        }
      }
    });
    change('total_invoice_amount', total_invoice_amount);
  };

  const updateToDefaultStorageLocation = (fieldName, value) => {
    if (!formValues || !formValues.lines)
      return;

    formValues.lines.forEach((line, index) => {
      change(`lines.${index}.inventory.0.storage_location_id`, value);
    });
  };

  // Determine initial total_invoice_amount
  if (getFormValue('total_invoice_amount') === undefined) {
    updateTotalInvoiceAmount('', 0);
  }

  const barcodeSource = get(metrcSettings, 'package_barcode_source', 'global_id');
  const barcodeSourceValue = barcodeSource === 'state_integration_tracking_id' ? 'State Integration Tracking ID' : 'Platform Generated Package Code';

  const statuses = [
    {value: 'open', text: I18n.t('inventoryReceipt.form.statuses.open')},
    {value: 'completed', text: I18n.t('inventoryReceipt.form.statuses.received')},
    {value: 'cancelled', text: I18n.t('inventoryReceipt.form.statuses.cancelled')}
  ];

  const submitSectionSettings = {
    actionSettings: {
      reset: {
        text: I18n.t('common.form.reset'),
        action: () => {
          reset();
        },
        style: 'default'
      },
      print: {
        text: I18n.t('inventoryReceipt.form.printLabels'),
        disabled: getFormValue('status') !== 'completed',
        display: !isCreateMode && hasPrintPermission ? null : 'hide',
        style: 'success',
        action: () => {
          handlePrintLabels();
        }
      },
      saveAndPrint: {
        text: I18n.t('inventoryReceipt.form.receiveTransferAndPrintLabels'),
        disabled: getFormValue('status') !== 'completed',
        display: isCreateMode && hasPrintPermission ? null : 'hide',
        style: 'success',
        action: () => {
          handleSaveAndPrintLabels();
        },
        invalid: submitting
      },
      submit: {
        type: 'button',
        style: 'primary',
        text: I18n.t('inventoryReceipt.form.receiveTransfer'),
        action: () => {
          handleSubmit();
        },
        invalid: submitting
      },
    },
    align: 'right'
  };

  return (
    <form className='inventory-receipt' onSubmit={handleSubmit}>
      <FormGroup>
        <Row>
          <Col xs={12} sm={4} md={4}>
            <Field
              name='integrator_manifest_number'
              component={TextInput}
              props={{
                label: I18n.t('inventoryReceipt.form.manifestNumber'),
                disabled: true,
              }}
            />
          </Col>
          <Col xs={12} sm={4} md={4}>
            <Field
              name='integrator_partner_name'
              component={TextInput}
              props={{
                label: I18n.t('inventoryReceipt.form.partner'),
                disabled: true,
              }}
            />
          </Col>
          <Col xs={12} sm={4} md={4}>
            <Field
              name='integrator_partner_license'
              disabled
              component={TextInput}
              props={{
                label: I18n.t('inventoryReceipt.form.originatingLicense'),
                disabled: true,
              }}
            />
          </Col>
          <Col xs={12} sm={4} md={4}>
            <Field
              name='package_count'
              disabled
              component={TextInput}
              props={{
                label: I18n.t('inventoryReceipt.form.numberOfPackages'),
                disabled: true,
              }}
            />
          </Col>
          <Col xs={4} sm={2} md={2} lg={2}>
            <InternationalDateTimePickerInput
              name='date_received'
              props={{
                label: I18n.t('inventoryReceipt.form.dateReceived'),
                leftAddon: (<FaCalendarAlt/>),
                inputProps: {disabled: locked},
                isRequired: getFormValue('status') === 'completed',
              }}
            />
          </Col>
          <Col xs={4} sm={2} md={2} lg={2}>
            <InternationalDateTimePickerInput
              name='estimated_departure_time'
              props={{
                dateFormat: false,
                timeFormat: true,
                enableTodayButton: false,
                label: I18n.t('cultivation.transfers.form.departureTime'),
                inputProps: {disabled: locked}
              }}
            />
          </Col>
          <Col xs={4} sm={2} md={2} lg={2}>
            <InternationalDateTimePickerInput
              name='estimated_arrival_time'
              props={{
                dateFormat: false,
                timeFormat: true,
                enableTodayButton: false,
                label: I18n.t('cultivation.transfers.form.arrivalTime'),
                inputProps: {disabled: locked}
              }}
            />
          </Col>
          <Col xs={4} sm={2} md={2} lg={2}>
            <Field name='status' component={ReactSelectInput} props={{
              label: I18n.t('inventoryReceipt.form.status'),
              options: statuses,
              disabled: true,
            }}/>
          </Col>
        </Row>
      </FormGroup>
      <hr/>
      <FormGroup>
        <DriverAndVehicleInformation
          drivers={drivers}
          vehicles={vehicles}
          locked={locked}
          getFormValue={getFormValue}
        />
      </FormGroup>
      <hr/>
      <FormGroup>
        <Row>
          <Col xs={12} sm={4} md={4}>
            <Field
              name='partner_invoice_number'
              component={TextInput}
              props={{
                label: I18n.t('inventoryReceipt.form.partnerInvoiceNumber'),
              }}
              disabled={locked}
            />
          </Col>
          <Col xs={12} sm={4} md={4}>
            <Field
              name='total_invoice_amount'
              component={CurrencyInput}
              leftAddon={<FaDollarSign/>}
              label={I18n.t('inventoryReceipt.form.totalInvoiceAmount')}
              disabled={true}
            />
          </Col>
          <Col xs={12} sm={4} md={4}>
            <Field
              name='purchase_order_number'
              disabled
              component={TextInput}
              props={{
                label: I18n.t('inventoryReceipt.form.purchaseOrderNumber'),
                disabled: true,
                value: get(purchaseOrder, 'po_number')
              }}
            />
          </Col>
        </Row>
      </FormGroup>
      <hr/>
      <FormGroup>
        <Row>
          <Col xs={12} sm={4} md={4}>
            <Field
              name='default_storage_location' component={ReactSelectInput}
              props={{
                label: I18n.t('purchaseOrders.form.applyStorageLocationToAllPackages'),
                options: [{value: null, text: I18n.t('none')}].concat(storageLocations),
                isRequired: false,
                placeholder: I18n.t('none'),
                disabled: locked,
                onChange: (value) => {
                  change('default_storage_location', value);
                  updateToDefaultStorageLocation('', value);
                }
              }}/>
            <span style={{color: 'grey'}}><FaExclamationTriangle/> {I18n.t('inventoryReceipt.info.storageLocation')}</span>
          </Col>
          <Col xs={12} sm={4} md={4}>
            <Field
              name='package_barcode_source' component={TextInput}
              props={{
                label: I18n.t('inventoryReceipt.form.packageBarcodeSourceSetting'),
                disabled: true,
                value: barcodeSourceValue,
                placeholder: I18n.t('none')
              }}/>
            <span style={{color: 'grey'}}><FaExclamationTriangle/> {I18n.t('inventoryReceipt.info.barcodeSource')}</span>
          </Col>
        </Row>
      </FormGroup>
      <hr/>
      <FormGroup>
        <Row>
          <Col xs={12}>
            <FieldArray
              name='lines'
              component={InventoryReceiptPackagesFieldArray}
              props={{
                formValues,
                change,
                inventoryReceipt,
                itemMasters,
                testResults,
                testResultDimensions,
                getFormValue,
                updateTotalInvoiceAmount,
                strainOptions,
                storageLocations,
                barcodeSource,
                locked,
                isCreateMode
              }}/>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12}>
            <SubmitSection settings={submitSectionSettings} isCreateMode={isCreateMode}/>
          </Col>
        </Row>
      </FormGroup>
    </form>
  );
};

InventorReceiptForm.propTypes = {
  formValues: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  getFormValue: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleSaveAndPrintLabels: PropTypes.func.isRequired,
  handlePrintLabels: PropTypes.func.isRequired,
  incomingTransferDetails: PropTypes.object,
  storageLocations: PropTypes.array,
  drivers: PropTypes.array.isRequired,
  vehicles: PropTypes.array.isRequired,
  metrcSettings: PropTypes.object,
  inventoryReceipt: PropTypes.object,
  purchaseOrder: PropTypes.object.isRequired,
  itemMasters: PropTypes.array.isRequired,
  testResults: PropTypes.array,
  testResultDimensions: PropTypes.object.isRequired,
  strainOptions: PropTypes.array.isRequired,
  locked: PropTypes.bool.isRequired,
  isCreateMode: PropTypes.bool.isRequired,
  hasPrintPermission: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
  const selector = formValueSelector(INVENTORY_RECEIPT_FORM_METRC);
  const getFormValue = (...names) => selector(state, ...names);
  return {
    formValues: getFormValues(INVENTORY_RECEIPT_FORM_METRC)(state) || ownProps.initialValues,
    hasPrintPermission: userHasPermission(state, {permissions: [p.print_labels]}),
    getFormValue
  };
}

const ReduxInventoryReceiptForm = reduxForm({
  form: INVENTORY_RECEIPT_FORM_METRC,
  //validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(InventorReceiptForm);

export default connect(mapStateToProps)(ReduxInventoryReceiptForm);
