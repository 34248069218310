import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { I18n } from 'react-redux-i18n';

import { getItem, getUnpaginatedData } from '../../../actions/apiActions';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import * as itemTransactionTypes from '../../../constants/itemTransactionTypes';
import { getPackage } from '../../../selectors/finishedProductsSelectors';
import {getPackageHistoryWithDelta} from '../../../selectors/itemTransactionsSelectors';

import ModalWrapper from '../../common/ModalWrapper';
import HistoryNotes from './HistoryNotes';
import TablePageWrapper from '../../common/grid/TablePageWrapper';
import InProgressOverlay from '../../common/InProgressOverlay';
import defineColumns from './columnDefinitions';
import ItemCreatedDetails from './details/ItemCreatedDetails';
import ItemMasterChangedDetails from './details/ItemMasterChangedDetails';
import ItemDeltaDetails from './details/ItemDeltaDetails';

export class PackageHistoryPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      ready: false,
      showNotesModal: false,
      displayedNotes: null
    };

    this.openNotesModal = this.openNotesModal.bind(this);
    this.expandableRow = this.expandableRow.bind(this);
    this.expandComponent = this.expandComponent.bind(this);
    this.columns = defineColumns(this);
  }

  componentWillMount() {
    const id = this.props.params.id || '';
    const promises = [
      this.props.actions.getItem(`/api/packages/${id}`, itemNames.pkg, {failed: I18n.t('packages.getPackage.failed')}, {detailed: 1}),
      this.props.actions.getUnpaginatedData('/api/item_transactions', dataNames.itemTransactions, {failed: I18n.t('itemTransactions.get.failed')}, {package_id: id}),
      this.props.actions.getUnpaginatedData(`/api/packages/${id}/log`, dataNames.packageEvents, {failed: I18n.t('harvests.getHistory.failed')}),
      this.props.actions.getUnpaginatedData(`/api/phases`, dataNames.phases, {failed: 'phases.get.failed'}),
    ];
    Promise.all(promises)
      .then(() => this.setState({ready: true}))
      .catch(() => this.setState({ready: true}))
    ;
  }

  openNotesModal(event, displayedNotes) {
    event.stopPropagation();
    this.setState({ showNotesModal: true, displayedNotes });
  }

  expandableRow(row) {
    return row.model;
  }

  expandComponent(row) {
    switch (row.transaction_type) {
    case itemTransactionTypes.create:
    case itemTransactionTypes.receipt:
      return <ItemCreatedDetails item={row.model}/>;
    case itemTransactionTypes.manual:
    case itemTransactionTypes.merge:
    case itemTransactionTypes.mfgProduction:
    case itemTransactionTypes.newLevel:
    case itemTransactionTypes.none:
    case itemTransactionTypes.packageSplit:
    case itemTransactionTypes.packaging:
    case itemTransactionTypes.reconcile:
    case itemTransactionTypes.restock:
    case itemTransactionTypes.sale:
    case itemTransactionTypes.transfer:
      if (!row.delta) {
        return I18n.t('itemTransactions.noEventRecorded');
      }
      return <ItemDeltaDetails delta={row.delta} model={row.model}/>;
    case itemTransactionTypes.split:
      if (!row.delta) {
        return <ItemCreatedDetails item={row.model}/>;
      }
      return <ItemDeltaDetails delta={row.delta}/>;
    case itemTransactionTypes.newItemMaster:
      return <ItemMasterChangedDetails item={row.model}/>;
    default:
      return null;
    }
  }

  render() {
    const {pkg, history} = this.props;

    return (
      <div className='harvest-history-page history-page'>
        {this.state.ready ? null : <InProgressOverlay isActive={true} />}
        <ModalWrapper
          Component={HistoryNotes}
          onHide={() => this.setState({ showNotesModal: false })}
          showModal={this.state.showNotesModal}
          title='history.notes'
          notes={this.state.displayedNotes}
        />
        <div className='harvest-history-page-harvest-info'>
          <p>{I18n.t('harvests.packageId')}: {pkg.package_code || ''}</p>
          <p>{I18n.t('harvests.lotId')}: {pkg.lot && pkg.lot.lot_number || ''}</p>
          <p>{I18n.t('harvests.productName')}: {pkg.item_master && pkg.item_master.name || ''}</p>
        </div>
        <TablePageWrapper
          ref={this.ref}
          settingKey='packages-history'
          columns={this.columns}
          data={history}
          selectedRows={[]}
          handleSelect={() => {}}
          showHistory={false}

          bstProps={{
            selectRow: {
              clickToSelect: false,
              clickToExpand: true,
              hideSelectColumn: true,
            },
            expandableRow: this.expandableRow,
            expandComponent: this.expandComponent,
            options : {
              defaultSortName: 'event_date',
              defaultSortOrder: 'desc',
            },
          }}
        />
      </div>
    );
  }
}

PackageHistoryPage.propTypes = {
  pkg: PropTypes.object.isRequired,
  history: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    getItem: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
  }).isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    pkg: getPackage(state),
    history: getPackageHistoryWithDelta(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = { getItem, getUnpaginatedData };
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PackageHistoryPage);
