import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Button, ButtonGroup} from 'react-bootstrap';
import PermissionWrapper from '../common/PermissionWrapper';
import * as p from '../../constants/permissions';

const OrderButtons = ({cancelOrder, editOrder, printBeforePayment, printAllLabels, printJob}) => {

  return (
      <ButtonGroup vertical style={{width: '100%'}}>
        <Button block variant='primary' onClick={editOrder} className='edit-order'>{I18n.t('cart.totals.editOrder')}</Button>
        <Button block variant='primary' onClick={cancelOrder} className='cancel-order'>{I18n.t('cart.totals.cancelOrder')}</Button>

        <PermissionWrapper permissions={[p.print_labels]}>
          <hr style={{marginBottom: '5px'}}/>
          <Button block variant='primary' style={{marginBottom: '5px'}} disabled={!printBeforePayment} onClick={(e) => printAllLabels(e, false)}>{I18n.t('cart.totals.viewPrintAllLabels')}</Button>
          <Button block variant='primary' disabled={!printBeforePayment} onClick={(e) => printAllLabels(e, true)}>{I18n.t('cart.totals.printAllLabels')}</Button>
          <hr style={{marginBottom: '5px'}}/>
          {!Object.keys(printJob).length
            ? null
            : (<div>
              <div style={{clear: 'both'}}/>
              <div className='progress progress-striped active' style={{marginBottom: '0px'}}>
                <div style={{width: '100%'}} className='progress-bar' role='progressbar' aria-valuenow='100'
                     aria-valuemin='0' aria-valuemax='100'/>
              </div>
              <div style={{textAlign: 'center', fontSize: 'smaller'}} className='text-muted'>
                <div>Sending Print Job To Printer...</div>
              </div>
            </div>)
          }
        </PermissionWrapper>

      </ButtonGroup>
  );
};

OrderButtons.propTypes = {
  editOrder: PropTypes.func.isRequired,
  cancelOrder: PropTypes.func.isRequired,
  printJob: PropTypes.object,
  printAllLabels: PropTypes.func.isRequired,
  printBeforePayment: PropTypes.bool.isRequired
};


export default OrderButtons;
