import React from 'react';
import {Col,Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Field,reduxForm} from 'redux-form';
import {I18n} from 'react-redux-i18n';

import {INVENTORY_SEARCH_FORM} from '../../../../constants/forms';

import {getVendorOptions, getCategoryOptions} from '../../../../selectors/inventoryReportSelectors';
import {getFlattenedLocations} from '../../../../selectors/locationsSelectors';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import TextInput from '../../../common/form/TextInput';
import SubmitSection from '../../../common/form/SubmitSection';
import {getToday} from '../../../..//util/dateHelpers';
import InternationalDateTimeStatic from '../../../common/form/InternationalDateTimeStatic';

//
// Base form component for transaction report search
//
const SearchForm = (props) => {
  const {
    handleSubmit,
    categoryOptions,
    locationOptions,
    pristine,
    vendorOptions,
    reset,
    submitSearch,
  } = props;

  const submitSettings = {
    actionSettings: {
      reset: {
        action: reset,
        text: I18n.t('common.form.reset'),
        pristine
      },
      submit: {
        action: handleSubmit(submitSearch),
        text: I18n.t('common.actions.search'),
      }
    }
  };


  return (
  <form onSubmit={handleSubmit(submitSearch)}>
    <Row>
      <Col sm={6} md={4}>
        <label>{I18n.t('retail.inventoryReport.reportDateLabel')}</label>
        <br />
        <InternationalDateTimeStatic displayTime={false} useSystemDate={false} adjustForClientTimezone={false}>
          {getToday()}
        </InternationalDateTimeStatic>
      </Col>

      <Col sm={6} md={4}>
        <Field
          name='item_name'
          component={TextInput}
          props={{
            label: I18n.t('retail.inventoryReport.itemNameLabel')
          }}
        />
      </Col>

      <div className='d-none d-sm-block d-md-none clearfix'></div>

      <Col sm={6} md={4}>
        <Field
          name='vendor'
          component={ReactSelectInput}
          props={{
            label: I18n.t('retail.inventoryReport.vendorLabel'),
            options: vendorOptions,
          }}
        />
      </Col>

      <div className='d-none d-md-block d-xl-none clearfix'></div>

      <Col sm={6} md={4}>
        <Field
          name='category_id'
          component={ReactSelectInput}
          props={{
            label: I18n.t('retail.inventoryReport.categoryLabel'),
            options: categoryOptions,
          }}
        />
      </Col>

      <div className='d-none d-sm-block d-md-none clearfix'></div>

      <Col sm={6} md={4}>
        <Field
          name='storage_location'
          component={ReactSelectInput}
          props={{
            label: I18n.t('retail.inventoryReport.storageLocationLabel'),
            options: locationOptions,
          }}
        />
      </Col>


      <div className='d-block d-sm-block d-none d-md-block d-none d-lg-block clearfix'></div>

      <Col xs={12}>
        <SubmitSection settings={submitSettings} />
      </Col>
    </Row>
  </form>
  );
};

SearchForm.propTypes = {
  csrOptions:         PropTypes.array.isRequired,
  fulfillmentOptions: PropTypes.array.isRequired,
  handleSubmit:       PropTypes.func.isRequired,
  categoryOptions: PropTypes.array.isRequired,
  locationOptions:     PropTypes.array.isRequired,
  pristine:           PropTypes.func.isRequired,
  vendorOptions:    PropTypes.array.isRequired,
  reset:              PropTypes.func.isRequired,
  submitSearch:       PropTypes.func.isRequired,
};


// SearchForm validation
const validate = (values) => {
  const errors = {};

  return errors;
};


// SearchForm store connection
const mapStateToProps = (state) => {
  return {
    categoryOptions:    getCategoryOptions(state),
    locationOptions:    getFlattenedLocations(state),
    vendorOptions:      getVendorOptions(state),
  };
};

// SearchForm initial values
const initialValues = {
  category_id: null,
  vendor: null
};

const ReduxSearchForm = reduxForm({
  form: INVENTORY_SEARCH_FORM,
  initialValues,
  validate
})(SearchForm);

const InventorySearchForm = connect(mapStateToProps)(ReduxSearchForm);

InventorySearchForm.propTypes = {
  submitSearch:       PropTypes.func.isRequired,
};

export default InventorySearchForm;
