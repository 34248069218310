import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack, push} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import {formValueSelector, change, arrayRemoveAll, arrayPush} from 'redux-form';

import FormWrapper from '../../../common/form/FormWrapper';
import CreateEquipmentFormWrapper from './CreateEquipmentFormWrapper';
import {getUnpaginatedData, postItem} from '../../../../actions/apiActions';
import * as dataNames from '../../../../constants/dataNames';
import {getUomsTypeByType} from '../../../../selectors/uomsSelectors';
import {getPartnersForPurchase} from '../../../../selectors/partnersSelectors';
import InProgressOverlay from '../../../common/InProgressOverlay';
import {getCreatePayload} from '../../../../selectors/equipmentSelectors';

const formName = 'EquipmentForm';

export class CreateEquipmentPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {uploading: false, images: [], ready: false, saving: false};
    this.changeUploadStatus = this.changeUploadStatus.bind(this);
    this.addImageRef = this.addImageRef.bind(this);
    this.onAddVendor = this.onAddVendor.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.redirect = this.redirect.bind(this);
    this.addImageRef = this.addImageRef.bind(this);
    this.changeUploadStatus = this.changeUploadStatus.bind(this);
    this.setImage = this.setImage.bind(this);
  }

  componentWillMount() {
    Promise.all([
      this.props.actions.getUnpaginatedData('/api/uoms', dataNames.uoms),
      this.props.actions.getUnpaginatedData('/api/equipment/equipment_types', dataNames.equipmentTypes),
      this.props.actions.getUnpaginatedData('/api/partners/details', dataNames.partners),
      this.props.actions.getUnpaginatedData('/api/equipment/equipment_service_tags', dataNames.tags)
    ]).then(() => this.setState({ready: true}))
      .catch(() => this.setState({ready: true}));
  }

  addImageRef(input) {
    this.imageInput = input;
  }

  changeUploadStatus(uploading){
    this.setState({uploading});
  }

  onAddVendor() {
    this.props.actions.push('/partners/create');
  }

  onSubmit(formData) {
    this.setState({saving: true});
    return this.props.actions.postItem('/api/equipment',
      getCreatePayload(formData),
      null,
      {failed: 'equipment.actions.failCreate', success: 'equipment.actions.successCreate'},
      {},
      () => {
        if (formData.redirect) {
          this.redirect();
        } else {
          this.props.actions.arrayRemoveAll(formName, 'items');
          this.props.actions.arrayPush(formName, 'items', {
            serviceType: []
          });
        }
        this.setState({saving: false, images: []});
      }
    ).catch(() => this.setState({saving: false}));
  }

  redirect() {
    this.props.actions.goBack();
  }

  setImage(imageUrl, index) {
    const images = this.state.images;
    images[index] = imageUrl;
    this.setState({images: images});
  }

  render() {
    const {getFormValue, uomOptions, vendors, serviceTypes,equipmentTypes} = this.props;
    const {uploading, images, saving, ready} = this.state;
    const initialValues = {
      items: [{
        serviceType: []
      }]
    };
    const overlayMessage = I18n.t(`equipment.actions.${saving ? 'saving' : 'loading'}`);
    return (
      <FormWrapper goBack={this.redirect} title={I18n.t('equipment.actions.addEquipment')}>
        <InProgressOverlay isActive={saving || !ready} message={overlayMessage}/>
        <CreateEquipmentFormWrapper
          form={formName}
          uomOptions={uomOptions}
          onSubmit={this.onSubmit}
          initialValues={initialValues}
          onAddVendor={this.onAddVendor}
          keepDirtyOnReinitialize={true}
          enableReinitialize={true}
          getFormValue={getFormValue}
          changeUploadStatus={this.changeUploadStatus}
          uploading={uploading}
          images={images}
          addImageRef={this.addImageRef}
          setImage={this.setImage}
          vendors={vendors}
          serviceTypes={serviceTypes}
          equipmentTypes={equipmentTypes}
          change={change}/>
      </FormWrapper>
    );
  }
}

CreateEquipmentPage.propTypes = {
  actions: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  getFormValue: PropTypes.func.isRequired,
  uomOptions: PropTypes.array.isRequired,
  vendors: PropTypes.array.isRequired,
  serviceTypes: PropTypes.array.isRequired,
  equipmentTypes: PropTypes.array.isRequired
};

const selector = formValueSelector(formName);

function mapStateToProps(state) {
  const {tags, equipmentTypes} = state;
  return {
    getFormValue: name => selector(state, name),
    state,
    vendors: getPartnersForPurchase(state),
    uomOptions: getUomsTypeByType(state, {uom_type: 'time'}),
    serviceTypes: tags,
    equipmentTypes
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    goBack,
    push,
    getUnpaginatedData,
    postItem,
    change,
    arrayRemoveAll,
    arrayPush
  };
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateEquipmentPage);
