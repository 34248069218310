import get from 'lodash.get';
import { I18n } from 'react-redux-i18n';

export const validate = (values) => {
  const errors = {
    modalities: []
  };

  const modalities = get(values, 'modalities', []);

  for (const key in modalities) {
    const settings = get(modalities, `${key}.setting_value`);

    if (settings && (settings.plants_on_hand || settings.plants_in_veg || settings.plants_in_flower)) {
      const plants_on_hand = parseInt(settings.plants_on_hand) || 0;
      const plants_in_veg = parseInt(settings.plants_in_veg) || 0;
      const plants_in_flower = parseInt(settings.plants_in_flower) || 0;

      if (plants_on_hand < plants_in_veg + plants_in_flower) {
        errors.modalities[key] = {
          setting_value: {
            plants_on_hand: I18n.t('cupos.form.limitsExceedOnHandValue'),
            plants_in_veg: I18n.t('cupos.form.limitsExceedOnHandValue'),
            plants_in_flower: I18n.t('cupos.form.limitsExceedOnHandValue')
          }
        };
      }
    }
  }

  return errors;
};

export default validate;
