import get from 'lodash.get';

const getFormArrayIndexFromString = (string, prefix = false) => {
  if (prefix) {
    string = string.split(prefix).pop();
  }
  const regex = /\[[0-9][0-9]?\]/;
  const found = string.match(regex);
  return parseInt(get(found, '0').replace(']', '').replace('[', ''));
};

// Exporting this as default to satisfy linting
export default getFormArrayIndexFromString;
