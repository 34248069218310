import React from 'react';
import PropTypes from 'prop-types';
import InternationalNumericInputControl from '../../common/form/InternationalNumericInputControl';
import {fieldFormatHelperDecimals, fieldParseHelperDecimals} from '../../../util/internationalHelpers';
//import {I18n} from 'react-redux-i18n';

const PropertyEditor = (props) => {

  let errorMessages = {};

  const isDefault = props.definitions.find((def, index) => def.name === 'Default' && index === props.currentDefinitionIndex);
  const setMessage = (field, message) => {
    errorMessages[field] = message;
    return false;
  };

  const dataIsValid = () => {
    errorMessages = {};
    const data = props.modalData;
    if(data.name === undefined) return setMessage('name', 'Name may not be empty.');
    if(!isDefault) {
      if (data.name.toLowerCase() === 'default') return setMessage('name', 'Name may not be Default.');
    }
    if(data.name.trim() === '') return setMessage('name', 'Name may not be empty.');
    const numericFields = ['width', 'height', 'fontSize', 'lineSpacing', 'top', 'right', 'bottom', 'left'];
    const margins = ['top', 'right', 'bottom', 'left'];
    const nonZero = ['width', 'height', 'fontSize'];
    let numericErrors = false;
    numericFields.forEach((field) => {
      const number = (margins.indexOf(field) !== -1) ? parseFloat(data.margins[field]) : parseFloat(data[field]);
      if(isNaN(number)) {
        numericErrors = true;
        setMessage(field, 'Value must be numeric');
      }
      if(number === 0 && nonZero.indexOf(field) !== -1) {
        numericErrors = true;
        setMessage(field, 'Value must be greater than zero.');
      }
    });
    return !numericErrors;
  };

  const getButtonClass = (field, matchValue, className) => {
    if(isDefault) return 'btn';
    if(className === undefined) {
      className = '';
    }
    if(props.modalData[field] === matchValue) return `btn btn-success ${className}`;
    return `btn btn-default ${className}`;
  };

  dataIsValid();

  const {internationalNumberFormat} = props;

  return (
    <div className='row' style={{marginTop: '12px'}}>
      <div className='col-md-12'>
        <div className='col-md-12'>
        {
          !isDefault
          ? null
            : (<p className='text-info' style={{fontWeight: 'bold'}}>
            Default Labels may only be set active or inactive.  All other settings are locked.  To replace a default label,
            add a defined label based off the default and adjust it's settings.
          </p>)
        }
        </div>
      <div className='col-md-6'>
        <div className='form-group'>
          <label>Definition Name</label>
          <input
            type='text'
            className='form-control form-control-sm'
            disabled={isDefault}
            onChange={(event) => {
              props.onChangeModalData('name', event.target.value);
            }}
            value={props.modalData.name}
          />
          {errorMessages.name !== undefined ? <div style={{padding: '3px', color: 'red'}}>{errorMessages.name}</div> : null}
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <div className='form-group'>
              <label>Width</label>
              <input
                type='number'
                disabled={isDefault}
                min='1'
                step='0.5'
                className='form-control form-control-sm'
                onChange={(event) => {
                  props.onChangeModalData('width', event.target.value);
                }}
                value={props.modalData.width}
              />
              {errorMessages.width !== undefined ? <div style={{padding: '3px', color: 'red'}}>{errorMessages.width}</div> : null}
            </div>
          </div>
          <div className='col-md-6'>
            <div className='form-group'>
              <label>Height</label>
              <input
                type='number'
                disabled={isDefault}
                min='1'
                step='0.5'
                className='form-control form-control-sm'
                onChange={(event) => {
                  props.onChangeModalData('height', event.target.value);
                }}
                value={props.modalData.height}
              />
              {errorMessages.height !== undefined ? <div style={{padding: '3px', color: 'red'}}>{errorMessages.height}</div> : null}
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <div className='form-group'>
              <label style={{width: '100%'}}>All Text Upper Case</label>
              <div className='btn-group btn-group-sm' style={{width: '100%'}}>
                <button
                  type='button'
                  style={{width:'50%'}}
                  disabled={isDefault}
                  onClick={event => {
                    props.onChangeModalData('upperCaseText', true);
                    event.target.blur();
                  }}
                  className={getButtonClass('upperCaseText', true)}>
                  Yes
                </button>
                <button
                  type='button'
                  style={{width:'50%'}}
                  disabled={isDefault}
                  onClick={event => {
                    props.onChangeModalData('upperCaseText', false);
                    event.target.blur();
                  }}
                  className={getButtonClass('upperCaseText', false)}>
                  No
                </button>
              </div>
            </div>
            {/*<div className='form-group'>
             <label>Line Spacing</label>
             <input
             type='number'
             disabled={isDefault}
             min='-10'
             step='1'
             max='10'
             className='form-control form-control-sm'
             onChange={(event) => {
             props.onChangeModalData('lineSpacing', event.target.value);
             }}
             value={props.modalData.lineSpacing}
             />
             {errorMessages.height !== undefined ? <div style={{padding: '3px', color: 'red'}}>{errorMessages.height}</div> : null}
             </div>*/}
          </div>
          <div className='col-md-6'>
            <div className='form-group'>
              <label>Font Size</label>
              <input
                type='number'
                disabled={isDefault}
                min='2'
                step='1'
                max='30'
                className='form-control form-control-sm'
                onChange={(event) => {
                  props.onChangeModalData('fontSize', event.target.value);
                }}
                value={props.modalData.fontSize}
              />
              {errorMessages.fontSize !== undefined ? <div style={{padding: '3px', color: 'red'}}>{errorMessages.fontSize}</div> : null}
            </div>
          </div>

        </div>
        <div className='row'>
        <div className='col-md-6'>
          <div className='form-group'>
            <label style={{width: '100%'}}>Label Rotation</label>
            <div className='btn-group btn-group-sm' style={{width: '100%'}}>
              <button
                type='button'
                style={{width:'50%'}}
                disabled={isDefault}
                onClick={event => {
                  props.onChangeModalData('rotateLabel', 0);
                  event.target.blur();
                }}
                className={getButtonClass('rotateLabel', 0)}>
                0 Degrees (Default)
              </button>
              <button
                type='button'
                style={{width:'50%'}}
                disabled={isDefault}
                onClick={event => {
                  props.onChangeModalData('rotateLabel', 90);
                  event.target.blur();
                }}
                className={getButtonClass('rotateLabel', 90)}>
                90 Degrees
              </button>
{/*              <button
                type='button'
                style={{width:'25%'}}
                disabled={isDefault}
                onClick={event => {
                  props.onChangeModalData('rotateLabel', 180);
                  event.target.blur();
                }}
                className={getButtonClass('rotateLabel', 180)}>
                180 Degrees
              </button>
              <button
                type='button'
                style={{width:'25%'}}
                disabled={isDefault}
                onClick={event => {
                  props.onChangeModalData('rotateLabel', 270);
                  event.target.blur();
                }}
                className={getButtonClass('rotateLabel', 270)}>
                270 Degrees
              </button>*/}
            </div>
          </div>
        </div>
          <div className='col-md-6'>
            <div className='form-group'>
              <label style={{width: '100%'}}>Print Across Multiple Labels</label>
              <div className='btn-group btn-group-sm' style={{width: '100%'}}>
                <button
                  type='button'
                  style={{width:'50%'}}
                  disabled={isDefault}
                  onClick={event => {
                    props.onChangeModalData('multiLabel', 1);
                    event.target.blur();
                  }}
                  className={getButtonClass('multiLabel', 1)}>
                  Yes
                </button>
                <button
                  type='button'
                  style={{width:'50%'}}
                  disabled={isDefault}
                  onClick={event => {
                    props.onChangeModalData('multiLabel', 0);
                    event.target.blur();
                  }}
                  className={getButtonClass('multiLabel', 0)}>
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-md-6'>
        <div className='row'>
          {typeof props.modalData.margins !== undefined ?
            <div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label>Top Margin</label>
                  <InternationalNumericInputControl
                    name='margin_top'
                    disabled={isDefault}
                    allowNegativeNumber={false}
                    min='0'
                    step='0.01'
                    max='1'
                    className='form-control form-control-sm'
                    internationalNumberFormat={props.internationalNumberFormat}
                    onChange={(formattedValue) => {
                      props.onChangeModalData('top', fieldParseHelperDecimals(formattedValue, '', internationalNumberFormat));
                    }}
                    value={fieldFormatHelperDecimals(props.modalData.margins.top, '', internationalNumberFormat)}
                  />
                  {errorMessages.top !== undefined ? <div style={{padding: '3px', color: 'red'}}>{errorMessages.top}</div> : null}
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label>Bottom Margin</label>
                  <InternationalNumericInputControl
                    name='margin_bottom'
                    disabled={isDefault}
                    min='0'
                    step='0.01'
                    max='1'
                    className='form-control form-control-sm'
                    internationalNumberFormat={props.internationalNumberFormat}
                    onChange={(formattedValue) => {
                      props.onChangeModalData('bottom', fieldParseHelperDecimals(formattedValue, '', internationalNumberFormat));
                    }}
                    value={fieldFormatHelperDecimals(props.modalData.margins.bottom, '', internationalNumberFormat)}
                  />
                  {errorMessages.bottom !== undefined ? <div style={{padding: '3px', color: 'red'}}>{errorMessages.bottom}</div> : null}
                </div>
              </div>
            </div>
          : null}
        </div>
        <div className='row'>
          {typeof props.modalData.margins !== undefined ?
            <div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label>Left Margin</label>
                  <InternationalNumericInputControl
                    name='margin_left'
                    disabled={isDefault}
                    min='0'
                    step='0.01'
                    max='1'
                    className='form-control form-control-sm'
                    internationalNumberFormat={props.internationalNumberFormat}
                    onChange={(formattedValue) => {
                      props.onChangeModalData('left', fieldParseHelperDecimals(formattedValue, '', internationalNumberFormat));
                    }}
                    value={fieldFormatHelperDecimals(props.modalData.margins.left, '', internationalNumberFormat)}
                  />
                  {errorMessages.left !== undefined ? <div style={{padding: '3px', color: 'red'}}>{errorMessages.left}</div> : null}
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label>Right Margin</label>
                  <InternationalNumericInputControl
                    name='margin_right'
                    disabled={isDefault}
                    min='0'
                    step='0.01'
                    max='1'
                    className='form-control form-control-sm'
                    internationalNumberFormat={props.internationalNumberFormat}
                    onChange={(formattedValue) => {
                      props.onChangeModalData('right', fieldParseHelperDecimals(formattedValue, '', internationalNumberFormat));
                    }}
                    value={fieldFormatHelperDecimals(props.modalData.margins.right, '', internationalNumberFormat)}
                  />
                  {errorMessages.right !== undefined ? <div style={{padding: '3px', color: 'red'}}>{errorMessages.right}</div> : null}
                </div>
              </div>
            </div>
          : null}
          <div className='col-md-6'>
            <div className='form-group'>
              <label style={{width: '100%'}}>Status</label>
              <input
                checked={props.modalData.is_active === 1}
                onChange={() => props.onChangeModalData('is_active', 1)}
                style={{marginRight: '5px', width: '24px', height: '24px'}}
                type='radio'
                name='is_active'
                value='1'
              />
              <span style={{paddingRight: '12px', position: 'relative', top: '-7px'}}>Active</span>
              <input
                checked={props.modalData.is_active === 0}
                onChange={() => props.onChangeModalData('is_active', 0)}
                style={{marginRight: '5px', width: '24px', height: '24px'}}
                type='radio'
                name='is_active'
                value='0'
              />
              <span style={{position: 'relative', top: '-7px'}}>Disabled</span>
            </div>
          </div>
          {
            isDefault
            ? null
              : (
              <div className='col-md-6'>
                <div className='form-group'>
                  <label style={{width: '100%'}} />
                  {
                    !props.confirmDelete
                    ? (<button
                      onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        props.onDeleteLabel();
                        event.target.blur();
                      }} className='btn btn-warning btn-block'>Delete Label
                    </button>)
                      : (
                        <div>
                        <button
                          onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            props.onConfirmDeleteLabel();
                            event.target.blur();
                          }}
                          className='btn btn-danger btn-block'>Confirm Delete?</button>
                          <div>This is permanent.  You may proceed with saving or close the window to avoid deleting.</div>
                        </div>
                    )
                  }

                </div>
              </div>
            )
          }
        </div>
        </div>
      </div>
      <div style={{clear: 'both'}} />
      <div className='col-md-12'>
        <hr />
        <button
          onClick={(event) => {
            props.onHide();
          }}
          className='btn btn-danger'
        >
          Cancel
        </button>
        <button
          onClick={(event) => {
            props.onSaveModalData();
          }}
          className='btn btn-primary float-right'
          disabled={!dataIsValid()}
        >
          Save
        </button>
      </div>
    </div>
  );
};

PropertyEditor.propTypes = {
  definitions: PropTypes.array.isRequired,
  currentDefinitionIndex: PropTypes.number.isRequired,
  onSaveModalData: PropTypes.func.isRequired,
  modalData: PropTypes.object.isRequired,
  internationalNumberFormat: PropTypes.string.isRequired
};

export default PropertyEditor;
