import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector, submit, reset } from 'redux-form';
import { push } from 'react-router-redux';
import map from 'lodash.map';
import { I18n } from 'react-redux-i18n';
import omit from 'lodash.omit';
import get from 'lodash.get';
import {set} from 'lodash';

import FormWrapper from '../../common/form/FormWrapper';
import { addMessage } from '../../../actions/systemActions';
import { warning } from '../../../constants/messageTypes';
import InProgressOverlay from '../../common/InProgressOverlay';

import { unsetData, setData } from '../../../actions/dataActions';
import * as apiActions from '../../../actions/apiActions';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import MassModifyProductForm from './MassModifyProductForm';
import { getSelectedItemMasterIdsForMassModify } from '../../../selectors/itemMastersSelectors';
import { getIntegrationCategoriesData } from '../../../actions/integrationActions';

import {
  getItemMastersForModify,
  getInitialValues,
  getCommonProductCategory,
  getFilteredPricingGroupOptions,
  getCommonProductWeightType,
  getCommonProductUom,
  hasInventories,
  getFieldCanModifiedMap,
  getUomOptions,
  filterEmptyValue,
  canChangeSubcategory,
  getSubcategories,
  isStrainRequired,
  isStrainDisabled
} from '../../../selectors/forms/massModifyFormSelector';

import {
  getProductPricingGroups,
  getSaveProductPayload,
  getStrains
} from '../../../selectors/forms/productFormSelectors';

import { getRetailFacilityOptions } from '../../../selectors/facilitiesSelectors';
import { getPricingWeightsForDisplaying } from '../../../selectors/productsSelector';
import { getFacilitiesOptions } from '../../../selectors/accountingSelectors';
import { getIntegrationState } from '../../../selectors/integration/integrationSelectors';
import { handleComplexSelectRow } from '../../../actions/helpers/selectedDataHelper';
import { PRODUCT_MASS_MODIFY } from '../../../constants/forms';
import ModalWrapper from '../../common/ModalWrapper';
import {getIsConnectsActive} from '../../../selectors/connectsSelectors';

export class MassModifyProductPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showLoading: true,
      loadingMessage: 'common.form.loading',
      form_action: '',
      showSharedModal: false,
      sharedAccept: false
    };
    this.resetForm = this.resetForm.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.hideLoading = this.hideLoading.bind(this);
    this.handleReturn = this.handleReturn.bind(this);
    this.setButtonAction = this.setButtonAction.bind(this);
    this.scrollToHashId = this.scrollToHashId.bind(this);
    this.isSharedValid = this.isSharedValid.bind(this);
    this.onConfirmShared = this.onConfirmShared.bind(this);
  }

  componentWillMount() {
    const { selectedProductsIds, products } = this.props;
    const promises = [

      this.props.actions
        .getUnpaginatedData('/api/categories?is_only_active=1', dataNames.categories)
        .then(() => this.props.actions.getIntegrationCategoriesData()),
      this.props.actions.getUnpaginatedData('/api/subcategories', dataNames.subcategories),
      this.props.actions.getUnpaginatedData('/api/strains/by_organization', dataNames.organizationStrains),
      this.props.actions.getUnpaginatedData('/api/strains/by_facility', dataNames.facilityStrains),
      this.props.actions.getUnpaginatedData('/api/tags', dataNames.tags),
      this.props.actions.getUnpaginatedData('/api/uoms/inventory', dataNames.uoms),
      this.props.actions.getUnpaginatedData('/api/partners', dataNames.partners, undefined, { purchase_from: 1 }),
      this.props.actions.getUnpaginatedData(
        '/api/pricing_classes',
        dataNames.pricingClasses,
        { failed: 'retail.pricingClass.get.failed' },
        { active: 1 }
      ),
      this.props.actions.getUnpaginatedData('/api/pricing_groups', dataNames.pricingGroups, null, { detailed: 1 }),
      this.props.actions.getUnpaginatedData('/api/pricing_weights', dataNames.pricingWeights),
      this.props.actions.getUnpaginatedData('/api/brands', dataNames.brands),
      this.props.actions.getUnpaginatedData('/api/facility_groups_sharings', dataNames.sharedFacilityGroups, null, {
        only_master: 1
      }),
      this.props.actions.getItem('/api/integration-settings', itemNames.integrationSettings, {
        failed: 'stateIntegrators.settings.get.failed'
      }),
      this.props.actions.getItem('/api/organizations/current_organization', itemNames.currentOrganization),
      this.props.actions.getDataByPost(
        '/api/items/by_item_master_ids',
        { ids: selectedProductsIds, org_level: true },
        dataNames.inventoryItems
      )
    ];
    // Products are retrieved from props. If navigated from a different page than the product listing page, the products
    // variable may miss certain details like sub_category.
    // Reload products if those details are missing
    if (!products.length || get(products, '0.subcategory_name') === undefined) {
      promises.push(
        this.props.actions.getDataByPost(
          '/api/item_masters/multiple',
          { ids: selectedProductsIds, detailed: 1 },
          dataNames.products,
          null,
          null,
          (itemMasters) => {
            const ids = itemMasters
              .filter((itemMaster) => itemMaster.inventory_attributes && itemMaster.inventory_attributes.is_prepack)
              .map((itemMaster) => itemMaster.id);
            if (ids.length) {
              this.props.actions.getUnpaginatedData(
                '/api/item_masters/children/items',
                dataNames.childItemMasters,
                undefined,
                { ids: ids, is_waste: 0, include_inactive_items: true, org_level: true }
              );
            }
          }
        )
      );
    }
    Promise.all(promises)
      .then(this.hideLoading)
      .catch(this.hideLoading);
  }

  componentWillUnmount() {
    if (this.state.form_action === 'preview') return true;
    this.props.actions.reset(PRODUCT_MASS_MODIFY);
    this.props.actions.unsetData(dataNames.massModifyFormData);
    this.props.actions.unsetData(dataNames.products);
    this.props.actions.unsetData(dataNames.pricingGroups);
    this.props.actions.unsetData(dataNames.pricingClasses);
    this.props.actions.unsetData(dataNames.inventoryItems);
  }

  componentDidMount() {
    this.scrollToHashId();
  }

  scrollToHashId() {
    const hash = window.location.hash.substring(1);
    if (hash && hash.length) {
      const el = document.getElementById(hash);
      el && el.scrollIntoView(true);
    }
  }

  resetForm() {}

  hideLoading() {
    this.setState({ showLoading: false });
  }

  isSharedValid(formData) {
    const { products, subcategories, category_id } = this.props;
    const new_subcategory_id = formData.subcategory_id;
    if (category_id && new_subcategory_id) {
      const newSubcategory = subcategories.find((subcategory) => subcategory.id === new_subcategory_id);
      if (newSubcategory.is_shared && products.find((product) => !product.is_shared_item)) {
        this.props.actions.addMessage(warning, 'products.massModify.errors.not_shared_to_shared');
        return true;
      } else if (
        !this.state.sharedAccept &&
        !newSubcategory.is_shared &&
        products.find((product) => product.is_shared_item)
      ) {
        this.setState({ showSharedModal: true });
        return false;
      }
    }
    return true;
  }

  handleClose() {
    this.props.actions.push('/products');
  }

  handleSubmit(formData) {
    const { initialValues, products } = this.props;
    if (!this.isSharedValid(formData)) return false;
    const diff = {};
    Object.entries(formData).forEach(([key, value]) => {
      if (
        (value !== null && JSON.stringify(value) !== JSON.stringify(initialValues[key])) ||
        key === 'prices' ||
        (key === 'tags' && formData['tags_action'] === 'clear')
      )
        diff[key] = value;
    });
    const inventory_attributes = {
      ...formData.inventory_attributes,
      is_prepack: formData.is_prepack,
      lot_tracked: formData.lot_tracked
    };

    if (!inventory_attributes.is_prepack && inventory_attributes.is_prepack !== 0) {
      delete inventory_attributes.is_prepack;
    }

    if (!inventory_attributes.lot_tracked && inventory_attributes.lot_tracked !== 0) {
      delete inventory_attributes.lot_tracked;
    }

    const payload = {
      ...getSaveProductPayload(diff, 'modify'),
      ids: map(products, 'id'),
      inventory_attributes
    };

    set(
      payload,
      'pricing_details.price_lists',
      get(payload, 'pricing_details.price_lists', []).reduce((a, b) => {
        const priceListsKeys = Object.keys(
          omit(b, ['consumer_type', 'pricing_type', 'sale_type', 'is_default_for_org'])
        );

        const check = priceListsKeys.some((k, v) => {
          return (
            (b[k] !== undefined && b[k] !== null && b[k] !== '0.00' && !Array.isArray(b[k])) ||
            (Array.isArray(b[k]) && b[k].length > 0)
          );
        });

        if (check) {
          return a.concat([b]);
        }

        return a;
      }, [])
    );

    this.props.actions.postData('/api/item_masters/mass_update/validate', filterEmptyValue(payload)).then(() => {
      this.props.actions.setData({ ...formData, form_action: 'preview', payload }, dataNames.massModifyFormData);
      this.props.actions.push('/products/mass_modify/preview');
    });
  }

  setButtonAction(status) {
    this.setState({ form_action: status });
  }

  handleReturn() {
    this.handleClose();
  }

  onConfirmShared() {
    this.setState({ showSharedModal: false, sharedAccept: true });
    this.props.actions.submit();
  }

  render() {
    const {
      initialValues,
      products,
      subcategories,
      strains,
      phenotypes,
      tags,
      uoms,
      partners,
      category_id,
      weight_type,
      retailFacilityOptions,
      pricingClasses,
      pricingWeights,
      filteredPricingGroupOptions,
      facilityOptions,
      integrationState,
      brands,
      showCategoryError,
      showUomError,
      showInventoryError,
      fieldCanModifiedMap,
      fieldActions,
      canChangeSubcategory,
      isConnectsActive,
      isPurchasingItem,
      isStrainRequired,
      isStrainDisabled
    } = this.props;

    return (
      <FormWrapper className='create-product-page' title={'products.massModify.title'} goBack={this.handleClose}>
        <InProgressOverlay
          isActive={this.state.showLoading}
          message={this.state.loadingMessage}
          showOk={false}
          showLoader={true}
          translate={true}
        />
        <ModalWrapper
          Component={false}
          version={2}
          onHide={() => this.setState({ showSharedModal: false })}
          showModal={this.state.showSharedModal}
          title='categoryManagement.sharedProducts'
          okayButton={{ show: true, text: 'Confirm', variant: 'default', onClick: this.onConfirmShared }}
          cancelButton={{ show: true, text: 'Close', variant: 'default' }}
        >
          <div>{I18n.t('products.massModify.errors.shared_to_not_shared')}</div>
        </ModalWrapper>
        <MassModifyProductForm
          onSubmit={this.handleSubmit}
          resetForm={this.resetForm}
          initialValues={initialValues}
          products={products}
          subcategories={subcategories}
          strains={strains}
          phenotypes={phenotypes}
          tags={tags}
          uoms={uoms}
          partners={partners}
          retailFacilityOptions={retailFacilityOptions}
          pricingClasses={pricingClasses}
          pricingWeights={pricingWeights}
          pricingGroups={filteredPricingGroupOptions}
          category_id={category_id}
          facilityOptions={facilityOptions}
          weight_type={weight_type}
          integrationState={integrationState}
          brands={brands}
          handleReturn={this.handleReturn}
          showCategoryError={showCategoryError}
          showUomError={showUomError}
          showInventoryError={showInventoryError}
          fieldCanModifiedMap={fieldCanModifiedMap}
          setButtonAction={this.setButtonAction}
          fieldActions={fieldActions}
          canChangeSubcategory={canChangeSubcategory}
          isConnectsActive={isConnectsActive}
          isPurchasingItem={isPurchasingItem}
          isStrainRequired={isStrainRequired}
          isStrainDisabled={isStrainDisabled}
        />
      </FormWrapper>
    );
  }
}

MassModifyProductPage.defaultProps = {
  isConnectsActive: false
};

MassModifyProductPage.propTypes = {
  actions: PropTypes.object.isRequired,
  selectedProductsIds: PropTypes.array.isRequired,
  initialValues: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  subcategories: PropTypes.array.isRequired,
  strains: PropTypes.array.isRequired,
  phenotypes: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  uoms: PropTypes.array.isRequired,
  partners: PropTypes.array.isRequired,
  retailFacilityOptions: PropTypes.array.isRequired,
  pricingClasses: PropTypes.array.isRequired,
  pricingGroups: PropTypes.array.isRequired,
  pricingWeights: PropTypes.array.isRequired,
  category_id: PropTypes.number.isRequired,
  facilityOptions: PropTypes.array.isRequired,
  weight_type: PropTypes.string.isRequired,
  integrationState: PropTypes.object.isRequired,
  brands: PropTypes.array.isRequired,
  showCategoryError: PropTypes.bool.isRequired,
  showUomError: PropTypes.bool.isRequired,
  showInventoryError: PropTypes.bool.isRequired,
  fieldCanModifiedMap: PropTypes.object.isRequired,
  fieldActions: PropTypes.object.isRequired,
  canChangeSubcategory: PropTypes.bool.isRequired,
  isConnectsActive: PropTypes.bool,
  isPurchasingItem: PropTypes.int,
  isStrainRequired: PropTypes.bool.isRequired,
  isStrainDisabled: PropTypes.bool.isRequired
};

const selector = formValueSelector(PRODUCT_MASS_MODIFY);

function mapStateToProps(state) {
  const { categories } = state;
  const products = getItemMastersForModify(state);
  const category_id = getCommonProductCategory(state);
  const weight_type = getCommonProductWeightType(state);
  const fieldActions = selector(
    state,
    'ingredients_action',
    'allergens_action',
    'nutritional_info_action',
    'tags_action'
  );
  const isPurchasingItem = selector(state, 'is_purchasing_item');

  return {
    selectedProductsIds: getSelectedItemMasterIdsForMassModify(state),
    initialValues: getInitialValues(state),
    products,
    categories,
    subcategories: getSubcategories(state, { category_id }),
    strains: getStrains(state),
    phenotypes: state[dataNames.phenotypes],
    tags: state[dataNames.tags],
    uoms: getUomOptions(state),
    partners: state[dataNames.partners],
    retailFacilityOptions: getRetailFacilityOptions(state),
    pricingWeights: getPricingWeightsForDisplaying(state),
    pricingClasses: state[dataNames.pricingClasses],
    pricingGroups: getProductPricingGroups(state),
    filteredPricingGroupOptions: getFilteredPricingGroupOptions(state),
    category_id,
    facilityOptions: getFacilitiesOptions(state),
    weight_type,
    integrationState: getIntegrationState(state),
    brands: state[dataNames.brands],
    showCategoryError: !!(category_id === 0 && products.length > 0),
    showUomError: !!(!getCommonProductUom(state) && products.length > 0),
    showInventoryError: !!(hasInventories(state) && products.length > 0),
    fieldCanModifiedMap: getFieldCanModifiedMap(state),
    fieldActions,
    canChangeSubcategory: canChangeSubcategory(state),
    isConnectsActive: getIsConnectsActive(state),
    isStrainRequired: isStrainRequired(state),
    isStrainDisabled: isStrainDisabled(state),
    isPurchasingItem
  };
}

function mapDispatchToProps(dispatch) {
  const submitForm = () => (dispatch) => dispatch(submit(PRODUCT_MASS_MODIFY));
  const actions = Object.assign({}, apiActions, {
    unsetData,
    push,
    setData,
    handleComplexSelectRow,
    reset,
    addMessage,
    submit: submitForm,
    getIntegrationCategoriesData
  });
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MassModifyProductPage);
