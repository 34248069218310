import React from 'react';
import PropTypes from 'prop-types';
import {Field, FormSection} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';

import TextInput from '../../../common/form/TextInput';

const LeafIntegratorSettings = ({integratorName}) => {
  return (
    <Col xs={12}>
      <h3>{I18n.t('stateIntegrators.form.cureConfiguration')}</h3>
      <FormSection name={`${integratorName}.value`}>
        <Row>
          <Col sm={6} md={4} lg={3}>
            <Field name='api_user_id' component={TextInput} props={{
              label: I18n.t('stateIntegrators.form.subscriberId'),
              isRequired: true,
            }}/>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={4} lg={3}>
            <Field name='api_security_token' component={TextInput} props={{
              label: I18n.t('stateIntegrators.form.dispenserId'),
              isRequired: true,
            }}/>
          </Col>
        </Row>
      </FormSection>
    </Col>
  );
};

LeafIntegratorSettings.propTypes = {
  integratorName: PropTypes.string.isRequired,
};

export default LeafIntegratorSettings;
