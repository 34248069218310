import {change, getFormValues} from 'redux-form';
import get from 'lodash.get';
import {STATE_INTEGRATION_SETTINGS_FORM} from '../../constants/forms';
import {isChangeAction} from './utils';

const stateIntegrationSettingsForm = store => next => action => {
  const result = next(action);
  const {meta, payload} = action;
  if(isChangeAction(action, [STATE_INTEGRATION_SETTINGS_FORM]) && meta.field.indexOf('.state') > -1){
    const state = store.getState();
    const formValues = getFormValues(STATE_INTEGRATION_SETTINGS_FORM)(state);
    if (get(formValues, 'selectedIntegrator.value') === 'metrc' && payload === 'oh') {
      const path = meta.field.replace('.state', '');
      const value = get(formValues, path);
      store.dispatch(change(meta.form, path, {
        ...value,
        enable_sales_push: false
      }));
    }
    if (get(formValues, 'selectedIntegrator.value') === 'leaf' && payload === 'wa') {
      const key = meta.field.split('.').shift();
      store.dispatch(change(meta.form, `${key}.value.is_initial_loading`, 0));
    }
  }

  return result;
};

export default stateIntegrationSettingsForm;