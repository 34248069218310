/* eslint-disable import/no-named-as-default*/
import {reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import {INTERNATIONAL_SETTINGS} from '../../../constants/forms';
import InternationalOperationsForm from './InternationalOperationsForm';

const validate = (values) => {
  const errors = {};
  return errors;
};

const InternationalOperationsFormWrapper = reduxForm({
  form: INTERNATIONAL_SETTINGS,
  validate,
})(InternationalOperationsForm);

InternationalOperationsFormWrapper.propTypes = {
  form: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default InternationalOperationsFormWrapper;
