import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {deleteItem} from '../../../../../../actions/apiActions';
import {getFileOptions, getGroupedFiles} from '../../../../../../selectors/objectFilesSelectors';
import ReceiptFilesComponent from './ReceiptFilesComponent';


function mapStateToProps(state, ownProps) {
  return {
    fileOptions: getFileOptions(state, ownProps),
    groupedFields: getGroupedFiles(state, ownProps),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({deleteItem}, dispatch),
  };
}

const ReceiptFilesFieldArray = connect(mapStateToProps, mapDispatchToProps)(ReceiptFilesComponent);

ReceiptFilesFieldArray.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
  }).isRequired,
  change: PropTypes.func.isRequired,
  types: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  files: PropTypes.array,
  locked: PropTypes.bool.isRequired,
};

export default ReceiptFilesFieldArray;
