import {I18n} from 'react-redux-i18n';

export const completionStatuses = {
  inProgress: {value: 'in_progress', text: 'common.inProgress'},
  completed: {value: 'completed', text: 'common.completed'},
};

// Replaces i18n keys with translations:
export const getCompletionStatusOptions = () => {
  return Object.values(completionStatuses).map(item => ({...item, text: I18n.t(item.text)}));
};

export const getTextForStatus = (status) => {
  const item = Object.values(completionStatuses).find(item => item.value === status);
  return item && I18n.t(item.text);
};
