import * as UOMS from './uoms';
import { NONE } from './integrations';

const { UOM_VALUE_PRECISION } = UOMS;
export const DISCRETE = 'discrete';
export const WEIGHT = 'weight';
export const VOLUME = 'volume';
export const TIME = 'time';
export const CURRENCY = 'currency';

export const uomTypes = {
  [UOMS.EA]: { type: DISCRETE, ratio: 1, fractionalSize: 0 },

  // WEIGHT
  [UOMS.MG]: { type: WEIGHT, ratio: 1000, fractionalSize: UOM_VALUE_PRECISION },
  [UOMS.GR]: { type: WEIGHT, ratio: 1000000, fractionalSize: UOM_VALUE_PRECISION},
  [UOMS.KG]: { type: WEIGHT, ratio: 1000000000, fractionalSize:UOM_VALUE_PRECISION },

  [UOMS.OZ]: { type: WEIGHT, ratio: 28349500, fractionalSize: 2},
  [UOMS.LB]: { type: WEIGHT, ratio: 453592000, fractionalSize: UOM_VALUE_PRECISION },
  [UOMS.T]: { type: WEIGHT, ratio: 907184000000, fractionalSize: UOM_VALUE_PRECISION },

  // VOLUME
  [UOMS.ML]: { type: VOLUME, ratio: 1000000, fractionalSize: UOM_VALUE_PRECISION },
  [UOMS.L]: { type: VOLUME, ratio: 1000000000, fractionalSize: UOM_VALUE_PRECISION },
  [UOMS.KL]: { type: VOLUME, ratio: 1000000000000, fractionalSize: UOM_VALUE_PRECISION },

  [UOMS.TSP]: { type: VOLUME, ratio: 4929000, fractionalSize: UOM_VALUE_PRECISION},
  [UOMS.TBSP]: { type: VOLUME, ratio: 14787000, fractionalSize: UOM_VALUE_PRECISION},
  [UOMS.FLOZ]: { type: VOLUME, ratio: 29574000, fractionalSize: UOM_VALUE_PRECISION},
  [UOMS.PT]: { type: VOLUME, ratio: 473184000, fractionalSize: UOM_VALUE_PRECISION},
  [UOMS.QT]: { type: VOLUME, ratio: 946368000, fractionalSize: UOM_VALUE_PRECISION},
  [UOMS.GAL]: { type: VOLUME, ratio: 3785472000, fractionalSize: UOM_VALUE_PRECISION },

  // TIME
  [UOMS.SEC]: { type: TIME, ratio: 1, fractionalSize: 0 },
  [UOMS.MIN]: { type: TIME, ratio: 60, fractionalSize: 1 },
  [UOMS.HR]: { type: TIME, ratio: 3600, fractionalSize: 2 },
  [UOMS.DAY]: { type: TIME, ratio: 86400, fractionalSize: 2 },
  [UOMS.WEEK]: { type: TIME, ratio: 604800, fractionalSize: 2 },

  // CURRENCY
  [UOMS.USD]: { type: CURRENCY, ratio: 1, fractionalSize: 0 },
};

export const uomFlowerConcentrate = {
  [NONE]: {
    default: [UOMS.MG, UOMS.GR, UOMS.KG, UOMS.OZ, UOMS.LB, UOMS.T]
  }
};

export const excludeNonMedical = [UOMS.OZ, UOMS.LB, UOMS.T, UOMS.KL, UOMS.PT, UOMS.QT, UOMS.GAL];
export const excludeForNonHempIngredients = [UOMS.OZ, UOMS.LB, UOMS.T, UOMS.KL, UOMS.PT, UOMS.QT, UOMS.GAL];
