import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import { Card } from 'react-bootstrap';
import RefundItems from './RefundItems';
import RefundItemsHeader from './RefundItemsHeader';
import RefundFooter from './RefundFooter';
import RestockItemsHeader from './RestockItemsHeader';
import RestockItems from './RestockItems';
import RestockFooter from './RestockFooter';
import WillRender from '../../../common/concealers/WillRender';

const RefundAndRestock = (props) => {
  const {refundOrder, onDeleteRefund, onApplyClick, integrationState, onDeleteRestockProduct, queue, onApplyRestock, isRefundAndRestockUnificationToggled, onApplyRefund, onRemoveItemFromRestocking, canRefundAlleaves} = props;
  const isRefunding = refundOrder && refundOrder.products && (refundOrder.products.length > 0);
  const isRestocking = queue.length > 0;

  const handleApplyRefundRestock = event => {
    const promises = [];

    if (isRefunding) {
      promises.push(onApplyRefund(event));
    }

    if (isRestocking) {
      promises.push(onApplyRestock(event));
    }

    Promise.all(promises);
  };

  return (
    <Card>
      <Card.Body>
        <table className='table table-sm' style={{width:'100%', marginBottom: '8px'}}>
          <RefundItemsHeader />
          <RefundItems refundOrder={refundOrder} onDeleteRefund={onDeleteRefund} />
          <RefundFooter
            onApplyClick={onApplyClick}
            refundOrder={refundOrder}
            integrationState={integrationState}
            isRefundAndRestockUnificationToggled={isRefundAndRestockUnificationToggled}
            canRefundAlleaves={canRefundAlleaves}
          />
        </table>

        <table className='table table-sm' style={{width:'100%', marginBottom: '8px'}}>
          <RestockItemsHeader />
          <RestockItems onDeleteRestockProduct={onDeleteRestockProduct} onRemoveItemFromRestocking={onRemoveItemFromRestocking} queue={queue} />
          <RestockFooter
            onApplyRestock={onApplyRestock}
            queue={queue}
            refundOrder={refundOrder}
            onApplyClick={onApplyClick}
            integrationState={integrationState}
            isRefundAndRestockUnificationToggled={isRefundAndRestockUnificationToggled}
          />
        </table>

        <WillRender ifTrue={isRefundAndRestockUnificationToggled && (isRefunding || isRestocking)}>
          <button className='btn btn-primary btn-block' onClick={(event) => handleApplyRefundRestock(event)}>
            {I18n.t(isRefunding && isRestocking ? 'cart.totals.completeRefundAndRestock' : isRefunding ? 'cart.totals.completeRefund' : 'cart.totals.completeRestock')}
          </button>
        </WillRender>
      </Card.Body>
    </Card>
  );

};

RefundAndRestock.propTypes = {
  onApplyRestock: PropTypes.func.isRequired,
  onDeleteRestockProduct: PropTypes.func.isRequired,
  onDeleteRefund: PropTypes.func.isRequired,
  refundOrder: PropTypes.object,
  queue: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  onQueueClick: PropTypes.func.isRequired,
  onApplyClick: PropTypes.func.isRequired,
  integrationState: PropTypes.object.isRequired,
  isRefundAndRestockUnificationToggled: PropTypes.bool.isRequired,
  onApplyRefund: PropTypes.func.isRequired,
  onRemoveItemFromRestocking: PropTypes.func.isRequired,
  canRefundAlleaves: PropTypes.bool.isRequired
};

export default RefundAndRestock;
