import React from 'react';
import PropTypes from 'prop-types';
import {Button, ButtonToolbar, Col, Row} from 'react-bootstrap';
import {Field, FieldArray} from 'redux-form';
import {I18n} from 'react-redux-i18n';

import DatePickerInput from '../../common/form/DatePickerInput';
import ProductItemsFieldArray from './ProductItemsFieldArray';

const ActivateInventoryForm = (props) => {
  const {form, invalid, pristine, submitting, handleSubmit, storageLocations, integrationAdjustmentReasons,
    itemOptions, integrationState, facilityUsers} = props;

  return (
    <form onSubmit={handleSubmit} name={form} noValidate={true} className='activate-harvest-batch'>
      <FieldArray
        name='items'
        component={ProductItemsFieldArray}
        storageLocations={storageLocations}
        itemOptions={itemOptions}
        integrationState={integrationState}
        integrationAdjustmentReasons={integrationAdjustmentReasons}
        rerenderOnEveryChange={true}
        facilityUsers={facilityUsers}
      />
      <Row>
        <Col xs={6} offset={6} sm={5} smOffset={7} md={4} offset={8} lg={3} offset={9}>
          <Field name='event_date' component={DatePickerInput} props={{
            label: I18n.t('common.form.date'),
            dateFormat: 'MM/DD/YYYY',
            closeOnSelect: true,
          }}/>
        </Col>
      </Row>
      <Row className='submit-section'>
        <Col xs={12}>
          <div className='button-wrapper'>
            <ButtonToolbar className='float-right'>
              <Button variant='primary'
                      disabled={invalid || pristine || submitting}
                      type='submit'>
                {I18n.t('common.form.save')}
              </Button>
            </ButtonToolbar>
          </div>
        </Col>
      </Row>
    </form>
  );
};

ActivateInventoryForm.propTypes = {
  form: PropTypes.string,
  itemOptions: PropTypes.array.isRequired,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  storageLocations: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
  integrationAdjustmentReasons: PropTypes.array.isRequired,
  facilityUsers: PropTypes.array
};

export default ActivateInventoryForm;
