/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {goBack, push} from 'react-router-redux';
import {bindActionCreators} from 'redux';
import {getUnpaginatedData, getSearchData, postItem, putItem, getItem, deleteItem} from '../../../actions/apiActions';
import {setItem} from '../../../actions/itemActions';
import {unsetData} from '../../../actions/dataActions';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import {bindSettingsToFacilityGroup} from '../../../actions/forms/facilityGroupActions';
import {getPayload, getFacilityGroupsWithMemberDetails, userManagesSettings} from '../../../selectors/forms/facilityGroupFormSelectors';
import {getItem as getReduxItem} from '../../../selectors/configurableSelectors';
import FacilityGroupList from './components/list/FacilityGroupList';
import FacilityGroupEditor from './components/editor/FacilityGroupEditor';
import SettingsIntegration from './components/settings-integration/SettingsIntegration';

export class FacilityGroupsPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.onEdit = this.onEdit.bind(this);
    this.redirect = this.redirect.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.onShowModal = this.onShowModal.bind(this);
    this.onHideModal = this.onHideModal.bind(this);

    this.onBindFacilityGroup = this.onBindFacilityGroup.bind(this);

    this.state = {
      mode: false, // edit, create - drives rendering if not driven by props.params which would be absent when in modal
      currentMode: false,
      showModal: false,
    };
  }

  componentWillMount() {
    this.setPageMode(this.props);
  }

  componentWillReceiveProps(nextProps){
    this.setPageMode(nextProps);
  }

  componentWillUnmount(){
    if(this.props.clearGroupsOnUnmount){
      this.props.actions.unsetData(dataNames.facilityGroups);
    }
  }

  getPageMode(props){
    if(props.settingsIntegration) return 'settingsIntegration';
    return props.params
      ? props.params.id
        ? props.params.id === 'create'
          ? 'create'
          : 'edit'
        : 'list'
      : !this.state.mode && !this.props.params ? 'list' : this.state.mode;
  }

  setPageMode(props){

    const currentMode = this.getPageMode(props);

    if(currentMode === this.state.currentMode) return false;

    const newState = {currentMode};
    if(!this.props.params && !this.state.mode) newState.mode = 'list';
    this.setState(newState);

    if(currentMode === 'list' || currentMode === 'settingsIntegration'){
      this.props.actions.getUnpaginatedData('/api/facility_groups', dataNames.facilityGroups, {failed: 'failedToGetFacilityGroups'});
    }
    if(currentMode === 'edit'){
      const id = (props.params && props.params.id) ? props.params.id : this.state.currentGroup.id;
      this.props.actions.getItem(`/api/facility_groups/${id}`, itemNames.facilityGroup, {failed: 'failedToGetFacilityGroup'});
    }
    if(currentMode === 'create'){
      this.props.actions.setItem({}, itemNames.facilityGroup);
    }
    this.props.actions.getUnpaginatedData('/api/facilities/details', dataNames.detailedFacilities, {failed: 'failedToGetFacilities'});
  }

  /***
   * EDITOR METHODS
   */

  onEdit(group){
    const newState = {
      currentGroup: group,
      mode: !this.props.params ? group.id === 'create' ? 'create' : 'edit' : false
    };
    if(this.props.params) {
      this.props.actions.push(`/facility-groups/${group.id}`);
    }
    this.setState(newState, () => {
      if(!this.props.params) this.setPageMode(this.props);
    });
  }

  onSubmit(formData){
    const reloadSettings = () => {
      if(typeof this.props.reloadSettings === 'function') this.props.reloadSettings();
    };
    if(formData.afterSubmit === 'startDelete') return true;
    if(formData.afterSubmit === 'delete'){
      this.props.actions.deleteItem(`/api/facility_groups/${formData.id}`, 'noOp', {success: 'successfullyDeletedFacilityGroup'}, null, (data) => {
        reloadSettings();
        this.redirect();
      });
      return true;
    }
    const method = (formData.id) ? 'putItem' : 'postItem';
    const url = (formData.id) ? `/api/facility_groups/${formData.id}` : '/api/facility_groups';
    const message = (formData.id) ? 'successfullyUpdatedFacilityGroup' : 'successfullyCreatedFacilityGroup';
    this.props.actions[method](url, getPayload(formData), 'noOp',  {success: message}, null, (data) => {
      reloadSettings();
      this.redirect();
    });
  }

  /***
   * SELECT FACILITY GROUP METHODS
   */

  onBindFacilityGroup(facilityGroup){
    this.props.actions.bindSettingsToFacilityGroup(this.props.formName, facilityGroup, this.props.getPayload);
  }

  /***
   * SETTINGS INTEGRATION METHODS
   */

  onShowModal(){
    this.setState({showModal: true});
  }

  onHideModal(){
    this.setState({showModal: false});
  }

  /***
   * STANDARD COMPONENT METHODS
   */

  redirect(){
    if(this.props.params) this.props.actions.goBack();
    this.setState({mode: 'list'}, () => {
      if(!this.props.params) this.setPageMode(this.props);
    });
  }



  render(){
    switch(this.state.currentMode){
      case 'list':
        return <FacilityGroupList inModal={!this.props.params} onGoBack={this.redirect} onEdit={this.onEdit} {...this.props} onBindFacilityGroup={this.onBindFacilityGroup} />;
      case 'create':
        return <FacilityGroupEditor inModal={!this.props.params} onGoBack={this.redirect} mode={this.state.currentMode} onSubmit={this.onSubmit} facilityGroups={this.props.facilityGroups} group={{id:0}} />;
      case 'edit':
        return <FacilityGroupEditor inModal={!this.props.params} onGoBack={this.redirect} mode={this.state.currentMode} onSubmit={this.onSubmit} facilityGroups={this.props.facilityGroups} group={this.state.currentGroup} />;
      case 'settingsIntegration':
        return <SettingsIntegration {...this.props} onShowModal={this.onShowModal} onHideModal={this.onHideModal} showModal={this.state.showModal} />;
      default:
        return <div />;
    }
  }

}

FacilityGroupsPage.propTypes = {
  clearGroupsOnUnmount: true
};

FacilityGroupsPage.propTypes = {
  facilities: PropTypes.array,
  facilityGroups: PropTypes.array,
  settingsIntegration: PropTypes.bool,
  clearGroupsOnUnmount: PropTypes.bool,
  manageSettingsPermissionKey: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
  return {
    facilities: state[dataNames.detailedFacilities],
    facilityGroups: getFacilityGroupsWithMemberDetails(state, {itemName: ownProps.settingsReduxName}),
    settings: getReduxItem(state, {itemName: ownProps.settingsReduxName}),
    userManagesSettings: userManagesSettings(state, ownProps),
  };
}

function mapDispatchToProps(dispatch) {

  const actions = Object.assign({}, {goBack, push, getUnpaginatedData, getSearchData, postItem, putItem, getItem, deleteItem, setItem, bindSettingsToFacilityGroup, unsetData});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FacilityGroupsPage);
