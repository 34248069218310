import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {Button, Col, Row} from 'react-bootstrap';
import {FaTrash} from 'react-icons/fa';
import {Field} from 'redux-form';
import * as UOM from '../../../constants/uoms';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import NumericInput from '../../common/form/NumericInput';
import AddItem from '../../common/form/AddItem';
import WillRender from '../../common/concealers/WillRender';
import ReduxMetrcIdField from '../../common/form/redux-form/ReduxMetrcIdField';
import {getIntegrationState} from '../../../selectors/integration/integrationSelectors';
import {getPackageMotherPlantsInventoryTypes} from '../../../selectors/integration/biotrackSelectors';


const MotherPlantPackagesFieldArray = (props) => {
  const {fields, itemMasters, locations, integrationState, packageMotherPlantsInventoryTypes, trackingTags} = props;

  return (
    <div>
      <h4>{I18n.t('plants.packageMothers.form.packages')}</h4>
      <Row>
        <Col xs={12}>
          {fields.map((fName, fIndex) => {
            const value = fields.get(fIndex);
            const isEach = value && value.uom === UOM.EA;
            return (
              <Row key={fIndex}>
              {(integrationState.isBiotrack) && (
                <Col xs={6} sm={4} md={3}>
                  <Field name={`${fName}.integration_type`} component={ReactSelectInput} props={{
                    label: I18n.t('inventory.form.inventoryType'),
                    options: packageMotherPlantsInventoryTypes,
                    isRequired: true,
                  }}/>
                </Col>
              )}
                <Col xs={6} sm={4} md={3}>
                  <Field name={`${fName}.item_master_id`} component={ReactSelectInput} props={{
                    label: I18n.t('plants.packageMothers.form.product'),
                    options: itemMasters,
                    textKey: 'name',
                    valueKey: 'id',
                    isRequired: true,
                  }}/>
                </Col>
                <Col xs={2} md={2}>
                  <Field name={`${fName}.qty`} component={NumericInput} props={{
                    label: I18n.t('plants.packageMothers.form.quantity'),
                    rightAddon: value && value.uom,
                    isRequired: true,
                    fractionPartSize: isEach ? 0 : 2,
                  }}/>
                </Col>
                <Col xs={6} sm={4} md={2}>
                  <Field name={`${fName}.inventory_location_id`} component={ReactSelectInput} props={{
                    label: I18n.t('plants.packageMothers.form.storageLocation'),
                    options: locations,
                    isRequired: true,
                  }}/>
                </Col>
                <WillRender ifTrue={integrationState.isCaMetrc}>
                  <Col xs={6} sm={4} md={2}>
                    <ReduxMetrcIdField
                      name={`${fName}.state_integration_tracking_id`}
                      props={{
                        options: trackingTags,
                        isRequired: true,
                        valueKey: 'text',
                        textKey: 'text',
                      }}
                    />
                  </Col>
                  <Col xs={6} sm={4} md={1}>
                    <Field
                      name={`${fName}.is_trade_sample`}
                      component={ReactSelectInput}
                      props={{
                        label: I18n.t('common.form.isTradeSample'),
                        disabled: value.is_donation === true,
                        options: [
                          {text: 'Yes', value: 1},
                          {text: 'No', value: 0},
                        ],
                      }}
                    />
                  </Col>
                  <Col xs={6} sm={4} md={1}>
                    <Field
                      name={`${fName}.is_donation`}
                      component={ReactSelectInput}
                      props={{
                        label: I18n.t('common.form.isDonation'),
                        disabled: value.is_trade_sample === true,
                        options: [
                          {text: 'Yes', value: 1},
                          {text: 'No', value: 0},
                        ],
                      }}
                    />
                  </Col>
                </WillRender>
                <Col xs={2} md={1} style={{paddingTop: '28px'}}>
                  <Button variant='danger' size='sm' onClick={() => fields.remove(fIndex)} disabled={fields.length < 2}>
                    <FaTrash/>
                  </Button>
                </Col>
              </Row>
            );
          })}
        </Col>
      </Row>
      <Row>
        <AddItem addAction={() => fields.push({})} text='plants.packageMothers.form.addPackage'/>
      </Row>
    </div>
  );
};

MotherPlantPackagesFieldArray.propTypes = {
  fields: PropTypes.shape({
    get: PropTypes.func.isRequired,
    map: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
  }).isRequired,
  itemMasters: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  packageMotherPlantsInventoryTypes: PropTypes.array,
  integrationState: PropTypes.array,
  trackingTags: PropTypes.array,
};

export default connect(
    (state) => ({
      integrationState: getIntegrationState(state),
      packageMotherPlantsInventoryTypes: getPackageMotherPlantsInventoryTypes(state),
    })
)(MotherPlantPackagesFieldArray);
