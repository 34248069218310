import * as types from '../constants/actionTypes';
import initialState from '../constants/initialState';

export default function customerFiltersReducer (state = initialState.customerFilters, action){
  const {filters} = action;
  switch (action.type) {
  case types.SET_CUSTOMER_FILTERS:
    return [...filters];
  default:
    return state;
  }
}
