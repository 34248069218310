import React from 'react';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import get from 'lodash.get';
import map from 'lodash.map';
import {set} from 'lodash';
import f_filter from 'lodash.filter';
import round from 'lodash.round';
import {Button, ButtonToolbar, Col, Row} from 'react-bootstrap';
import ReconciliationForm from './ReconciliationForm';
import TablePageWrapper from '../../common/grid/TablePageWrapper';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import MetrcPackageDetails from './MetrcPackageDetails';
import NumericInput from '../../common/form/NumericInput';
import * as itemNames from '../../../constants/itemNames';
import AdjustTemplate from './AdjustTemplate';

class MetrcReconciliationForm extends ReconciliationForm {
  constructor(props, context) {
    super(props, context);

    this.packageDetails = this.packageDetails.bind(this);
    this.getMetrcLevel = this.getMetrcLevel.bind(this);
    this.isDisabledField = this.isDisabledField.bind(this);
    this.saveToLocalStorage = this.saveToLocalStorage.bind(this);
    this.adjustMetrcLevel = this.adjustMetrcLevel.bind(this);
    this.disableMetrcAdjustButton = this.disableMetrcAdjustButton.bind(this);
    this.disableMjpAdjustButton = this.disableMjpAdjustButton.bind(this);
    this.getPrepackWeight = this.getPrepackWeight.bind(this);
    this.state = {
      disabledFields: []
    };
  }

  adjustMetrcLevel(row) {
    const item = this.getReduxFormItem(row);
    const payload = {
      metrc_tag: item.state_integration_tracking_id,
      qty: round(item.metrc_qty - row.metrc_qty, 2),
      uom: item.metrc_uom,
      reason: item.integration_adjustment_reason
    };
    //adjust only metrc level
    this.props.actions.postItem(
      '/api/metrc/adjust_package',
      payload,
      itemNames.metrcReconciliation,
      {success: 'reconciliation.adjust.success', failed: 'reconciliation.adjust.failed'},
      {},
      () => {
        //updating local storage with a new value
        const metrcItem = {
          id: item.id,
          metrc_qty: item.metrc_qty,
          metrc_uom: item.metrc_uom
        };
        this.saveToLocalStorage(metrcItem, item.id);
      }
    ).catch(() => this.setState({saving: false}));
  }


  disableMetrcAdjustButton(row) {
    const item = this.getReduxFormItem(row);
    const metrc_qty = get(item, 'metrc_qty');
    return metrc_qty === undefined
      || metrc_qty === ''
      || (metrc_qty - get(row, 'metrc_qty') === 0)
      || get(item, 'metrc_uom') === undefined
      || !item.integration_adjustment_reason;
  }

  disableMjpAdjustButton(row, index) {
    const item = this.getReduxFormItem(row);
    const childItem = get(item, `children.${index}`);
    return childItem
      ?
      (childItem.quantity === undefined
        || childItem.quantity === ''
        || (childItem.quantity - childItem.qty) === 0
        || !childItem.adjustment_reason)
      : true;
  }

  getMetrcLevel(row) {
    const {disabledFields} = this.state;
    const {actions, change} = this.props;
    const itemName = this.getReduxFormItemName(row);
    const id = row.id;
    actions.getItem(
      `/api/metrc/package/${row.state_integration_tracking_id}`,
      null,
      {failed: 'reconciliation.failed.metrcLevelFailed'},
      null
    )
      .then((metrcPackage) => {
        const metrc_qty = get(metrcPackage, 'Quantity');
        const metrc_uom = get(metrcPackage, 'UnitOfMeasureName');
        change(`${itemName}.metrc_qty`, metrc_qty);
        change(`${itemName}.metrc_uom`, metrc_uom);

        if (disabledFields.includes(id)) {
          this.setState({
            disabledFields: f_filter(disabledFields, (value) => value !== id)
          });
        }

        const metrcItem = {
          id,
          metrc_qty,
          metrc_uom
        };
        this.saveToLocalStorage(metrcItem, id);
      })
      .catch((e) => {
        this.setState({
          disabledFields: [...disabledFields, id]
        });
      });

  }

  getPrepackWeight(val) {
    const matchingPrepackWeight = this.props.prepackWeights.find((weight) => weight.id === val.prepack_weight_id);
    return get(matchingPrepackWeight, 'weight', 0);
  }

  isDisabledField(id) {
    return this.state.disabledFields.includes(id);
  }

  saveToLocalStorage(item, id) {
    const metrcReconciliationStorage = JSON.parse(localStorage.getItem('metrcReconciliations'));
    if (!metrcReconciliationStorage) {
      localStorage.setItem('metrcReconciliations', JSON.stringify([
        item
      ]));
    } else {
      const updateStorage = f_filter(metrcReconciliationStorage, (value) => value.id !== id);
      updateStorage.push(item);
      localStorage.setItem('metrcReconciliations', JSON.stringify(updateStorage));
    }
  }

  getColumns(){
    const {integrationAdjustmentReasons, integrationState, change} = this.props;
    const {isNormal} = integrationState;
    const prefix = 'metrc';
    const cols = [
      {
        name: 'reconciliation.trackingId',
        dataId: 'state_integration_tracking_id',
        hidden: false,
        dataSort: true,
        scanSearchColumn: true,
        width: '210px',
        expandable: true,
      },
      {
        name: 'reconciliation.vendorName',
        dataId: 'vendor_name',
        hidden: false,
        dataSort: true,
        scanSearchColumn: true,
        expandable: true,
      },
      {
        name: 'reconciliation.mjpTotalQuantity',
        dataId: 'mjp_total_quantity',
        hidden: false,
        dataSort: false,
        scanSearchColumn: true,
        expandable: true
      },
      {
        name: 'reconciliation.mjpUom',
        dataId: 'uom_display',
        hidden: false,
        dataSort: false,
        scanSearchColumn: true,
        expandable: true,
      },
      {
        name: 'reconciliation.metrcQuantity',
        dataId: 'metrc_qty',
        hidden: false,
        dataSort: false,
        scanSearchColumn: false,
        expandable: false,
        formatter: (cell, row) => {
          const item = row;
          const itemName = this.getReduxFormItemName(row);
          const isDisabled = this.isDisabledField(row.id);
          if (isDisabled) {
            return (<div style={{color: 'red'}} title={I18n.t('reconciliation.failed.metrcQtyFailedMessage')}>
              {I18n.t('reconciliation.failed.metrcQtyFailedMessage')}
            </div>);
          } else {
            return (<Field name={`${itemName}.metrc_qty`} component={NumericInput} props={{ //eslint-disable-line
              allowNegativeNumber: false,
              fractionPartSize: item.uom_display === 'EA' ? 0 : 2,
              onChange: (value) => {
                change(`${itemName}.metrc_qty`, value);
              },
              onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
              }
            }}/>);
          }
        }
      },
      {
        name: 'reconciliation.metrcUom',
        dataId: 'metrc_uom',
        hidden: false,
        dataSort: true,
        scanSearchColumn: true,
        expandable: true,
        formatter: (cell, row) => { //eslint-disable-line
          const itemName = this.getReduxFormItemName(row);
          return (<Field name={`${itemName}.metrc_uom`} component={(props) => { //eslint-disable-line
            return <div>{props.input.value}</div>; //eslint-disable-line
          }}/>);
        }
      },
      {
        name: 'reconciliation.discrepancy',
        dataId: 'discrepancy',
        hidden: false,
        dataSort: false,
        scanSearchColumn: true,
        expandable: true,
        formatter: (cell, row) => { //eslint-disable-line
          const itemName = this.getReduxFormItemName(row);
          const metrc_qty = get(this.getReduxFormItem(row), 'metrc_qty');
          const discrepancy = (metrc_qty !== undefined) ? (row.mjp_total_quantity - metrc_qty) : 0;
          return (<Field name={`${itemName}.discrepancy`} component={() => { //eslint-disable-line
            return <div style={{color: discrepancy === 0 ? 'green' : 'red'}}>{discrepancy.toFixed(2)}</div>;
          }}/>);
        }
      },
      {
        name: `reconciliation.${prefix}AdjustmentReason`,
        dataId: 'integration_adjustment_reason',
        hidden: false,
        className: 'allow-th-wrap',
        columnClassName: 'allow-wrap allow-overflow form-group-no-margin',
        dataSort: false,
        expandable: false,
        formatter: (cell, row) => { //eslint-disable-line
          const itemName = this.getReduxFormItemName(row);
          return (
            <Field
              name={`${itemName}.integration_adjustment_reason`}
              component={ReactSelectInput}
              props={{
                options: integrationAdjustmentReasons,
              }}
              />
          );
        }
      },
      {
        hidden: false,
        className: 'allow-th-wrap',
        columnClassName: 'allow-wrap allow-overflow form-group-no-margin',
        dataSort: false,
        expandable: false,
        formatter: (cell, row) => { //eslint-disable-line
          return (
            <div>
              <Button
                variant='primary'
                onClick={() => {
                  this.getMetrcLevel(row);
                }}>
                {I18n.t('reconciliation.actions.getMetrcLevel')}
              </Button>
            </div>
          );
        }
      },
      {
        hidden: false,
        className: 'allow-th-wrap',
        columnClassName: 'allow-wrap allow-overflow form-group-no-margin',
        dataSort: false,
        expandable: false,
        formatter: (cell, row) => { //eslint-disable-line
          return (
            <div>
              <Button
                variant='primary'
                disabled={this.disableMetrcAdjustButton(row)}
                onClick={() => {
                  this.adjustMetrcLevel(row);
                }}>
                {I18n.t('reconciliation.actions.adjustMetrcLevel')}
              </Button>
            </div>
          );
        }
      }
    ];

    const columns = cols.reduce((acc, col) => {
      if (isNormal && col.dataId === 'add_back_to_parent') return acc;
      acc.push(col);
      return acc;
    }, []);

    return columns;
  }

  packageDetails(row) {
    const prefix = this.getReduxFormItemName(row);
    return (
      <MetrcPackageDetails
        row={row}
        adjustmentReasons={this.props.adjustmentReasons}
        prefix={prefix}
        onAdjust={this.props.onAdjust}
        getReduxFormItem={this.getReduxFormItem}
        disableMjpAdjustButton={this.disableMjpAdjustButton}
      />
    );
  }

  render() {
    const {handleSubmit, onRedirect, submitting, reset, tableData, formUpdateInProgress, isFeatureEnabledInventoryAuditImport, actions} = this.props;
    const storage = JSON.parse(localStorage.getItem('metrcReconciliations'));
    const metrcData = map(tableData.data, (item) => {
      const initialValue = 0;
      const mjp_total_quantity = item.children.reduce((acc, val) => {
        if (!get(val, 'quantity')) {
          set(val, 'quantity', val.qty);
        }
        const weight = (val.is_prepack ? this.getPrepackWeight(val) : 1) * val.quantity;
        return acc + weight;
      }, initialValue);

      if (storage) {
        const metrcItem = storage.find((value) => value.id === item.id);
        if (metrcItem) {
          item.metrc_qty = metrcItem.metrc_qty;
          item.metrc_uom = metrcItem.metrc_uom;
        }
      }
      return {
        ...item,
        mjp_total_quantity
      };
    });

    return (
      <form className='reconciliation-page metrc-reconciliation' onSubmit={handleSubmit} noValidate={true}>
        {isFeatureEnabledInventoryAuditImport 
        && tableData.activeTab != 0
        && <AdjustTemplate show={true} downloadOnly={false} actions={actions} changeTab={this.props.changeTab} activeTab={tableData.activeTab} />}
        <div className='button-bar-bottom-padding allow-overflow'>
          {
            !tableData
              ? null
              : (<TablePageWrapper
                ref={tableData.ref}
                settingKey='reconciliations'
                columns={this.getColumns()}
                tabs={tableData.tabs}
                activeTab={tableData.activeTab}
                switchTab={tableData.switchTab}
                onPageChange={tableData.switchTab}
                data={metrcData}
                dataUpdateAvailable={false}
                dataUpdated={false}
                handleSelect={true}
                selectedRows={[]}
                hideScanSearch={true}
                dataTotalSize={tableData.dataTotalSize}
                externalSearch={tableData.externalSearch}
                showHistory={false}
                external={true}
                className='reconciliations-page'
                hideExport={true}
                sort='name asc'
                bstProps={{
                  options: {
                    onSearchChange: tableData.onSearchChange,
                    expandBy: 'column',
                  },
                  expandableRow: () => true,
                  expandComponent: this.packageDetails,
                  ignoreSinglePage: false
                }}
                disableChanges={formUpdateInProgress}
                disableReason='Save adjustments or reset the form so there are no discrepancies to re-enable table navigation.'
              />)
          }
        </div>

        <Row className='submit-section'>
          <Col xs={12}>
            <div className='button-wrapper'>
              <ButtonToolbar className='float-right'>
                <Button variant='default' type='reset' disabled={submitting} onClick={reset}>
                  {I18n.t('reconciliation.actions.reset')}
                </Button>
                <Button variant='primary' onClick={onRedirect}>
                  {I18n.t('reconciliation.actions.exitPage')}
                </Button>
              </ButtonToolbar>
            </div>
          </Col>
        </Row>
      </form>
    );
  }
}

export default MetrcReconciliationForm;
