import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash.omit';
import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {SubmissionError} from 'redux-form';

import * as apiActions from '../../../../actions/apiActions';
import {isMetrcIntegrator} from '../../../../selectors/integration/metrcSelectors';

export const setUnavailableTrackingIdFieldError = (trackingIds, fieldName, errorText) => {
  const errors = {};
  trackingIds.forEach((trackingId, index) => {
    errors[fieldName] = trackingId.isUnavailable ? errorText : undefined;
  });
  return errors;
};
export const setUnavailableTrackingIdFieldArrayErrors = arrayName => (trackingIds, fieldName, errorText) => {
  const errors = {[arrayName]: []};
  trackingIds.forEach((trackingId, index) => {
    errors[arrayName][index] = trackingId.isUnavailable ? {[fieldName] : errorText} : undefined;
  });
  return errors;
};

const reduxMetrcIdAvailability = ChildComponent => {

  class ParentComponent extends React.PureComponent {
    constructor(props) {
      super(props);
      this.fetchTrackingIds = this.fetchTrackingIds.bind(this);
      this.validateTrackingIdsAvailability = this.validateTrackingIdsAvailability.bind(this);
    }

    fetchTrackingIds(tags) {
      tags = tags.filter(tag => tag);
      return new Promise(resolve => {
        if(tags.length) {
          this.props.actions.postData(
            '/api/metrc/tracking_tags/by_tags',
            {tags: tags},
            undefined,
            {failed: 'tracking.getTrackingId.failed'},
            undefined,
            trackingIds => resolve(trackingIds)
          ).catch(e => resolve());
        }
        else {
          resolve(tags);
        }
      });
    }

    /**
     * If options.payloadIsTrackingIds is set, the method assumes that the trackingIds arg is an array of tracking ids,
     * not a submit payload which is the case on the ModifyPlantPage
     * This reduces this methods coupling of the tracking id with a "field" name.
     * It also allows us to validate multiple ids, rather than just the starting tag
     * If any of the tracking tags aren't available, we simply show the error on the Starting Tag input
     */
    validateTrackingIdsAvailability(trackingIds, setErrors, options = {}) {
      trackingIds = Array.isArray(trackingIds) ? trackingIds : [trackingIds];
      const {hasMetrcSettings} = this.props;
      const fieldName = options.fieldName || 'state_integration_tracking_id';
      const initialValueFieldName = options.initialValueFieldName || 'initial_state_integration_tracking_id';
      const errorText = options.errorText || I18n.t('tracking.common.form.trackingIdMustBeAvailable');
      const requireActive = options.requireActive || true;
      const requireUnused = options.requireUnused || true;
      const tags = options.payloadIsTrackingIds
        ? trackingIds
        : trackingIds.map(trackingId => trackingId[fieldName]);
      const isIdChangeOnly = (trackingIds.length === 1
        && get(trackingIds, '0.integration_id_platform_correction', false));
      return new Promise((resolve, reject) => {
        if (!hasMetrcSettings || isIdChangeOnly) {
          resolve(trackingIds);
          return;
        }

        this.fetchTrackingIds(tags).then(foundIds => {
          let allAvailable = true;
          const trackingIDsWithAvailability = trackingIds.map(trackingId => {
            // simplified version that simply checks if the tags are available without tying it to a field name
            if (options.payloadIsTrackingIds) {
              const foundId = Array.isArray(foundIds) && foundIds.find(id => id.tag === trackingId);
              const isUnavailable = Boolean((requireActive && foundId.is_inactive) || (requireUnused && foundId.is_used));
              allAvailable = allAvailable && !isUnavailable;
              return {foundId, isUnavailable};
            } else {
              const foundId = Array.isArray(foundIds) && foundIds.find(id => id.tag === trackingId[fieldName]);
              const isUnavailable = Boolean(
                // Do not mark Tracking ID as unavailable if it has no value or if it is already assigned to the current plant or package.
                trackingId[fieldName] && (trackingId[fieldName] !== trackingId[initialValueFieldName]) && (
                  !foundId || (requireActive && foundId.is_inactive) || (requireUnused && foundId.is_used)
                )
              );
              const trackingIdDoesNotExistInInitial = options.checkInInitial && !get(options, 'initialTrackingIds', []).includes(trackingId[fieldName]);
              allAvailable = allAvailable && (!isUnavailable || trackingIdDoesNotExistInInitial);
              return {...trackingId, isUnavailable};
            }
          });


          if (allAvailable) {
            resolve(trackingIDsWithAvailability);
            return;
          }

          const errors = setErrors(trackingIDsWithAvailability, fieldName, errorText);
          reject(new SubmissionError(errors));
          return;
        });
      });
    }

    render() {
      const props = omit(this.props, ['actions', 'hasMetrcSettings']);
      return <ChildComponent validateTrackingIdsAvailability={this.validateTrackingIdsAvailability} {...props} />;
    }
  }

  ParentComponent.propTypes = {
    actions: PropTypes.shape({
      postData: PropTypes.func.isRequired,
    }),
    hasMetrcSettings: PropTypes.bool.isRequired
  };

  function mapStateToProps(state, ownProps) {
    return {
      hasMetrcSettings: isMetrcIntegrator(state)
    };
  }

  function mapDispatchToProps(dispatch) {
    const actions = Object.assign({}, apiActions);
    return {
      actions: bindActionCreators(actions, dispatch)
    };
  }

  return connect(mapStateToProps, mapDispatchToProps)(ParentComponent);

};

export default reduxMetrcIdAvailability;
