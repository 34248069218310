import { createSelector } from 'reselect';
import moment from 'moment';
import * as dataNames from '../constants/dataNames';
import {isFeatureEnabled} from './featureToggles';

export const getSalesReportPatientDataFromState = (state) => state[dataNames.salesReportPatientData];
export const getSalesReportDataFromState = (state) => state[dataNames.salesReportSalesData];
export const isSalesReportNinetyDayRestrictionEnabled = (state) =>
  isFeatureEnabled(state)('feature_sales_report_ninety_day_restriction');

export const getSalesReportPatientData = createSelector(
  [getSalesReportPatientDataFromState],
  (salesReportPatientData) => {
    if (salesReportPatientData && salesReportPatientData.length > 0) {
      return {
        new_patients_today: salesReportPatientData[0].new_patients_today,
        total_active_patients: salesReportPatientData[0].total_active_patients
      };
    }
    return {
      new_patients_today: 0,
      total_active_patients: 0
    };
  }
);

export const getSalesReportData = createSelector(
  [getSalesReportDataFromState],
  (salesReportData) => {
    if (salesReportData && salesReportData.length > 0) {
      return {
        ...salesReportData,
        total_sales_today: salesReportData[0].total_sales_period[0].total_sales_today,
        total_sales_week: salesReportData[0].total_sales_period[0].total_sales_week,
        total_sales_month: salesReportData[0].total_sales_period[0].total_sales_month,
        last_ninety_days: salesReportData[0].total_sales_period[0].last_ninety_days,

        label_today: salesReportData[0].total_sales_period[0].label_today,
        label_week: salesReportData[0].total_sales_period[0].label_week,
        label_month: salesReportData[0].total_sales_period[0].label_month,
        label_year: salesReportData[0].total_sales_period[0].label_year,

        patients_seen_today: salesReportData[0].patients_seen_today[0].patients_seen_today,

        total_orders_today: salesReportData[0].total_sales[0].total_orders,
        top_sellers: salesReportData[0].top_sellers
      };
    }
    return {
      patients_seen_today: 0,
      total_sales_today: 0,
      total_sales_week: 0,
      total_sales_month: 0,
      last_ninety_days: 0,
      total_orders_today: 0,
      label_today: '',
      label_week: '',
      label_month: '',
      label_year: '',
      top_sellers: []
    };
  }
);

const getReportsForExport = (state) => state[dataNames.reportsForExport];

/**
 * Adds an value to an array in an object keyed by a first key (typekey) and a secondKey (periodKey).
 * @param object
 * @param typeKey
 * @param periodKey
 * @param value
 */
const addPeriodToPeriodTypeInObject = (object, typeKey, periodKey, value) => {
  if (!object[typeKey][periodKey]) {
    object[typeKey][periodKey] = [];
  }
  if (object[typeKey][periodKey].indexOf(value) === -1) {
    object[typeKey][periodKey].push(value);
  }
};

/**
 * Returns an object containing months listed by year and years listed by months for month year
 * drop down chains.
 * @param reportsForExport
 * @returns {*}
 */
const getMonthsAndYearsForExport = (reportsForExport) => {
  const object = reportsForExport.reduce((acc, report) => {
    const reportDate = moment(report.data_reported_from);
    const month = reportDate.month();
    const year = reportDate.year();
    addPeriodToPeriodTypeInObject(acc, 'monthsByYear', year, month);
    addPeriodToPeriodTypeInObject(acc, 'yearsByMonth', month, year);
    return acc;
  }, {monthsByYear: {}, yearsByMonth: {}});
  Object.keys(object).forEach((key) => {
    Object.keys(object[key]).forEach((k) => {
      return object[key][k].sort((a, b) => a > b ? 1 : -1);
    });
  });
  return object;
};

export const getExportReportMostRecentMonthYear = (reportsForExport) => {
  const mostRecentReport = reportsForExport.map((r) => r).sort((a, b) => {
    return moment(a.data_reported_from).unix() > moment(b.data_reported_from).unix() ? 1 : -1;
  }).pop();
  return {
    month: mostRecentReport ? moment(mostRecentReport.data_reported_from).month() : null,
    year: mostRecentReport ? moment(mostRecentReport.data_reported_from).year() : null,
  };
};

/**
 * Returns an object where years are keys and months in that year are in an ascending sorted array.
 * NOTE: Months are 0 based.
 * @param reportsForExport
 * @returns {{}}
 */
export const getExportReportMonthsByYear = (reportsForExport) => {
  return getMonthsAndYearsForExport(reportsForExport).monthsByYear;
};

/**
 * Returns an object where months are keys and years that have reports in that month are in an ascending sorted array.
 * @param reportsForExport
 * @returns {{}}
 */
export const getExportReportYearsByMonth = (reportsForExport) => {
  return getMonthsAndYearsForExport(reportsForExport).yearsByMonth;
};

export const getExportReportMonthsByYearSelector = createSelector([getReportsForExport], (reportsForExport) => {
  return getExportReportMonthsByYear(reportsForExport);
});

export const getExportReportYearsByMonthSelector = createSelector([getReportsForExport], (reportsForExport) => {
  return getExportReportYearsByMonth(reportsForExport);
});

export const getExportReportMostRecentMonthYearSelector = createSelector([getReportsForExport], (reportsForExport) => {
  return getExportReportMostRecentMonthYear(reportsForExport);
});

export const isPickableDate = createSelector(
  [isSalesReportNinetyDayRestrictionEnabled],
  (isSalesReportNinetyDayRestrictionEnabled) => {
    return (currentDate) => {
      if (!isSalesReportNinetyDayRestrictionEnabled) return true;

      const startDate = moment().subtract(90, 'days');
      const endDate = moment();

      return currentDate.isBetween(startDate, endDate);
    };
  }
);
