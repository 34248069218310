import get from 'lodash.get';
import {set} from 'lodash';
import {I18n} from 'react-redux-i18n';
import {requiredFieldValidation} from '../../../common/form/redux-form/validations';


export const validate = (values, props) => {
  const errors = {};

  const incomingTransferDetails = get(props, 'incomingTransferDetails', {});
  const purchaseOrder = get(values, 'purchaseOrder', {});

  // We need to have a transfer selected
  if (Object.keys(incomingTransferDetails).length === 0) {
    errors.incoming_transfer_id = requiredFieldValidation(values.incoming_transfer_id, 'supplyChainMapping.form.incomingTransfers');
    // No need to validate any further
    return errors;
  }

  // We need to have a partner facility selected
  if (!values.vendor_facility_id) {
    errors.vendor_facility_id = requiredFieldValidation(values.vendor_facility_id, 'purchaseOrders.form.partnerFacility');
    // No need to validate any further
    return errors;
  }

  // Check if P.O. should be auto generated or not
  const auto_generate_po = get(values, 'auto_generate_po');

  if (!auto_generate_po) {
    // Make sure a P.O. is selected
    if (!values.purchase_order_id) {
      errors.purchase_order_id = requiredFieldValidation(values.purchase_order_id, 'supplyChainMapping.form.unreceivedPurchaseOrders');
      // No need to validate any further
      return errors;
    }

    // Make sure if P.O. and transfer have the same number of items
    if (purchaseOrder.lines.length !== incomingTransferDetails.packages.length) {
      const transferCountMessage = I18n.t('supplyChainMapping.form.transferCount', {count: incomingTransferDetails.packages.length});
      const purchaseOrderCountMessage = I18n.t('supplyChainMapping.form.purchaseOrderCount', {count: purchaseOrder.lines.length});
      errors._error = I18n.t('supplyChainMapping.form.incorrectNumberOfProducts', {transferCountMessage: transferCountMessage, purchaseOrderCountMessage: purchaseOrderCountMessage});
      // No need to validate any further
      return errors;
    }
  }

  // Check if all lines are mapped
  incomingTransferDetails.packages.forEach((pkg, index) => {
    const field = `product_mapping.${index}.item_master`;
    let allMapped = true;
    if (!get(values, field + '.id')) {
      set(errors, field, I18n.t('common.form.isRequired', {fieldName: I18n.t('supplyChainMapping.form.mjpProduct')}));
      allMapped = false;
      //errors._error = I18n.t('supplyChainMapping.form.allTheProductsHaveToBeMapped');
    }
    if (!allMapped) {
      // No need to validate any further
      return errors;
    }
  });

  // Check if quantities match. We only need to do this when the PO is not auto generated
  if (!auto_generate_po) {
    incomingTransferDetails.packages.forEach((pkg, index) => {
      const field = `product_mapping.${index}.item_master`;
      const item_master = get(values, field);
      // We can only validate if we have an item_master
      if (item_master) {
        const line = purchaseOrder.lines.find((line) => line.item_master_id = item_master.id);

        // 1. If both UOMs are EA then the quantities need to be the same as well
        if (pkg.transfer_uom === 'EA' && item_master.default_uom === 'EA') {
          if (pkg.transfer_qty !== line.qty_base) {
            set(errors, field, I18n.t('supplyChainMapping.form.quantityMismatchError'));
          }

        // 2. If both UOMs are GR then convert base qty to GR and compare
        } else if (pkg.transfer_uom === 'GR' && item_master.default_uom === 'GR') {
          if (pkg.transfer_qty !== (line.qty_base / 1000000)) {
            set(errors, field, I18n.t('supplyChainMapping.form.quantityMismatchError'));
          }

        // 3. We cannot map from EA to GR
        } else if (pkg.transfer_uom === 'EA' && item_master.default_uom === 'GR') {
          set(errors, field, I18n.t('supplyChainMapping.form.invalidUomMapping'));

        // 4. Verify that converted 'EA' quantity matches the
        } else if (pkg.transfer_uom === 'GR' && item_master.default_uom === 'EA') {
          const med_weight_uom = get(item_master, 'medicated_weight_uom_display');
          const med_weight = get(item_master, 'medicated_weight_base', 0) / 1000000;
          // Return if no med_weight_uom or med_weight
          if (!med_weight_uom || !med_weight) {
            set(errors, field, I18n.t('supplyChainMapping.form.medicatedWeightMissing'));
          // Calculate EA quantity. This should match the PO line qty
          } else {
            const ea_quantity = pkg.transfer_qty / med_weight;
            if (ea_quantity !== line.qty_base) {
              set(errors, field, I18n.t('supplyChainMapping.form.quantityMismatchError'));
            }
          }
        }
      }
    });
  }

  return errors;
};


export default validate;
