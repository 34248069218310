/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {goBack} from 'react-router-redux';

import FormWrapper from '../common/form/FormWrapper';
import AlertsSettingsForm from './AlertsSettingsForm'; // eslint-disable-line import/no-named-as-defaul

export class AlertsSettingsPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  redirect() {
    this.props.actions.goBack();
  }

  onSubmit(settings, dispatch) {
    this.props.actions.goBack();
  }

  render() {
    return(<FormWrapper title={'alerts.settingsTitle'} goBack={this.redirect}>
      <AlertsSettingsForm onSubmit={this.onSubmit}/>
    </FormWrapper>);
  }
}

AlertsSettingsPage.propTypes = {
  actions: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps){
  return {
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({},
    {goBack}
  );
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (AlertsSettingsPage);
