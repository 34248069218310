import React from 'react';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import groupBy from 'lodash.groupby';
import uniqBy from 'lodash.uniqby';
import {Col, FormLabel, Row} from 'react-bootstrap';
import {FaAsterisk} from 'react-icons/fa';
import DualListBox from 'react-dual-listbox';
import InlineCheckBox from './form/InlineCheckBox';
import ReactSelectInput from './form/ReactSelectInput';
import MultiselectInput from './form/MultiselectInput';
import * as dataNames from '../../constants/dataNames';
import {setData, unsetData} from '../../actions/dataActions';
import {getDataByPost, getSearchData} from '../../actions/apiActions';

class DualListProductSelectorComponent extends React.PureComponent {

  constructor(props) {
    super(props);
    this.addItemMasters = this.addItemMasters.bind(this);
    this.updateFilterStates = this.updateFilterStates.bind(this);

    this.state = {
      searchedCategoryIds: [],
      searchedVendorIds: [],
      searchedMedicatedWeights: [],
      initialLoadTriggered: false   // Variable to keep track if initial itemMasters have been loaded or not
    };
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps, prevState, snapShot) {
    const { selectedItemMasterIds, itemMasters, actions: {getDataByPost}, defaultSearchParams } = this.props;

    if (selectedItemMasterIds.length && !itemMasters.length && !prevState.initialLoadTriggered) {
      this.setState({initialLoadTriggered: true}); // eslint-disable-line react/no-did-update-set-state
      const params = {...defaultSearchParams, ...{filter: '(' + selectedItemMasterIds.map(id => `id:${id}`).join(' OR ') + ')',}};
      getDataByPost('/api/search/item_masters', params, dataNames.itemMasters, {failed: 'products.get.failed'});
    }
  }

  componentWillUnmount() {
    const { actions: {unsetData} } = this.props;
    unsetData(dataNames.itemMasters);
  }

  groupItemMasters() {
    const { getFormValue, itemMasters } = this.props;
    // Return grouped by category
    if (getFormValue('groupByCategory')) {
      return groupBy(itemMasters, (im) => {
        return im.category_code;
      });
    }
    // Return ungrouped
    return itemMasters;
  }

  getItemMastersOptions() {
    const { categories, itemMasters, disabledItemMasterIds } = this.props;

    // We need both itemMasters and categories to continue
    if (!itemMasters || !categories) {
      return [];
    }

    const groupedItemMasters = this.groupItemMasters();
    // If value is not grouped then it is an array. Just return that array
    if (Array.isArray(groupedItemMasters)) {
      return groupedItemMasters.map((im) => {
        return {
          disabled: !!disabledItemMasterIds && disabledItemMasterIds.includes(im.id),
          value: im.id,
          label: im.name
        };
      });
    }

    if (!groupedItemMasters) {
      return [];
    }
    // Return grouped by category
    return Object.values(groupedItemMasters).map((itemMastersForCategory) => {
      const category = categories.find((category) => itemMastersForCategory[0].category_id === category.id);
      if (!category) {
        return;
      }
      return {
        label: category.name,
        options: itemMastersForCategory.map((im) => {
          return {
            disabled: disabledItemMasterIds && disabledItemMasterIds.includes(im.id),
            value: im.id,
            label: im.name
          };
        })
      };
    });
  }

  getCategoriesOptions() {
    const {categories} = this.props;
    return categories ? categories.map((c) => {
      return {value: c.id, label: c.name};
    }) : [];
  }

  getSubcategoriesOptions(category_ids) {
    const {categories} = this.props;
    if (category_ids === undefined || category_ids === null || category_ids.length === 0 || category_ids.length > 1) {
      return [];
    }
    // Look up category and return subcategories when only one category is selected
    const category = categories.find((category) => category_ids[0].value === category.id);
    return category.subcategories.map((sc) => {
      return {value: sc.id, label: sc.name};
    });
  }

  getMedicatedWeightOptions() {
    const {medicatedWeights} = this.props;
    return medicatedWeights ? medicatedWeights.map((mw) => {
      return {value: mw.medicated_weight_base + ' ' + mw.uom, label: mw.label};
    }) : [];
  }

  // Loop over all itemMasters and filter by category/subcategory/vendor/medicated_weight if needed
  getAvailable() {
    const {getFormValue, itemMasters} = this.props;

    let availableItemMasters = itemMasters;

    const category_ids = getFormValue('category_ids');
    const subcategory_id = getFormValue('subcategory_id');
    const vendor_ids = getFormValue('vendor_ids');
    const medicated_weights = getFormValue('medicated_weights');

    const categoryIds = category_ids && category_ids.map((c) => c.value);
    const vendorIds = vendor_ids && vendor_ids.map((v) => v.id);
    const medicatedWeights = medicated_weights && medicated_weights.map((mw) => mw.value);

    if (category_ids && category_ids.length > 0) {
      availableItemMasters = availableItemMasters.filter((im) => categoryIds.includes(im.category_id));
      if (subcategory_id) {
        availableItemMasters = availableItemMasters.filter((im) => im.subcategory_id === subcategory_id);
      }
    }

    if (vendor_ids && vendor_ids.length > 0) {
      availableItemMasters = availableItemMasters.filter((im) => vendorIds.includes(im.vendor_id));
    }

    if (medicated_weights && medicated_weights.length > 0) {
      availableItemMasters = availableItemMasters.filter((im) => {
        const medicatedWeightCombination = `${im.medicated_weight_base.toFixed(2)} ${im.medicated_weight_uom_display}`;
        return medicatedWeights.includes(medicatedWeightCombination);
      });
    }

    return availableItemMasters.map((im) => {
      return im.id;
    });
  }

  // Function to build the filter string based on selected filters
  buildFilterString(categoryIds, vendorIds, medicatedWeights) {
    const {defaultSearchParams} = this.props;
    // Filter default search params already contains a filter start with those otherwise start with a blank string
    let filterString = get(defaultSearchParams, 'filter') ? get(defaultSearchParams, 'filter') + ' AND ' : '';

    filterString += '(active:1 OR item_active:true) AND is_draft:0 AND item_master_parent_id:0';

    if (categoryIds) {
      filterString += ` AND category_id:(${categoryIds})`;
    }
    if (vendorIds) {
      filterString += ` AND vendor_id:(${vendorIds})`;
    }
    if (medicatedWeights.length) {
      const medicatedWeightUomCombinations = medicatedWeights.map((mw) => {
        const split = mw.split(' ');
        return `(medicated_weight_base: ${split[0]} AND medicated_weight_uom_display: ${split[1]})`;
      });
      filterString += ' AND (' + medicatedWeightUomCombinations.join(' OR ') + ')';
    }

    if (!categoryIds && !vendorIds && !medicatedWeights.length) {
      return null;
    }

    return filterString;
  }

  addItemMasters(newItemMasters) {
    const {itemMasters, actions: {setData}} = this.props;
    setData(uniqBy([...itemMasters, ...newItemMasters], 'id'), dataNames.itemMasters);
  }

  updateFilterStates(categoryIds, vendorIds, medicatedWeights) {
    const { searchedCategoryIds, searchedVendorIds, searchedMedicatedWeights} = this.state;

    if (categoryIds && !searchedCategoryIds.includes(categoryIds)) {
      let categoryIdsValue = searchedCategoryIds;
      categoryIdsValue = [...new Set([...categoryIdsValue, ...categoryIds])];
      this.setState({searchedCategoryIds: categoryIdsValue});
    }
    if (vendorIds && !searchedVendorIds.includes(vendorIds)) {
      let vendorIdsValue = searchedVendorIds;
      vendorIdsValue = [...new Set([...vendorIdsValue, ...vendorIds])];
      this.setState({searchedVendorIds: vendorIdsValue});
    }
    //searchedMedicatedWeights
    if (medicatedWeights && !searchedMedicatedWeights.includes(medicatedWeights)) {
      let medicatedWeightsValue = searchedMedicatedWeights;
      medicatedWeightsValue = [...new Set([...medicatedWeightsValue, ...medicatedWeights])];
      this.setState({searchedMedicatedWeights: medicatedWeightsValue});
    }
  }

  handleFilterChange(categoryIds, vendorIds, medicatedWeights) {

    const { vendors, change, actions: {getSearchData}, defaultSearchParams } = this.props;
    const { searchedCategoryIds, searchedVendorIds, searchedMedicatedWeights } = this.state;
    const categoriesOptions = this.getCategoriesOptions();
    const vendorsOptions = vendors;
    const medicatedWeightsOptions = this.getMedicatedWeightOptions();

    // 1. Determine categories for search
    let categoryIdsValue = [];
    if (categoryIds.some((c) => c.value === -1)) {
      categoryIdsValue = categoriesOptions.map((c) => c.value);
      change('category_ids', categoriesOptions);
    } else {
      categoryIdsValue = categoryIds.map((c) => c.value);
      change('category_ids', categoryIds);
    }
    // Clear subcategory_id when category changes
    change('subcategory_id', null);

    const includesEveryCategoryId = categoryIdsValue.every(categoryId => searchedCategoryIds.includes(categoryId));
    let categoryIdsString = '';
    if (categoryIds && !includesEveryCategoryId) {
      categoryIdsString = categoryIdsValue ? categoryIdsValue.join(' ') : null;
    }

    // 2. Determine vendors for search
    let vendorIdsValue = [];
    if (vendorIds) {
      if (vendorIds.some((v) => v.id === -1)) {
        vendorIdsValue = vendorsOptions.map((v) => v.id);
        change('vendor_ids', vendorsOptions);
      } else {
        vendorIdsValue = vendorIds.map((v) => v.id);
        change('vendor_ids', vendorIds);
      }
    }

    const includesAnyVendorId = vendorIdsValue.some(vendorId => searchedVendorIds.includes(vendorId));
    let vendorIdsString = vendorIdsValue ? vendorIdsValue.join(' ') : null;
    if (vendorIds && !includesAnyVendorId) {
      vendorIdsString = vendorIdsValue ? vendorIdsValue.join(' ') : null;
    }

    // 3. Determine medicated weights for search
    let medicatedWeightsValue = [];
    if (medicatedWeights) {
      if (medicatedWeights.some((mw) => mw.value === -1)) {
        medicatedWeightsValue = medicatedWeightsOptions.map((mw) => mw.value);
        change('medicated_weights', medicatedWeightsOptions);
      } else {
        medicatedWeightsValue = medicatedWeights.map((mw) => mw.value);
        change('medicated_weights', medicatedWeights);
      }
    }

    const includesAnyMedicatedWeight = medicatedWeightsValue.some(medicatedWeight => searchedMedicatedWeights.includes(medicatedWeight));

    // Build filter string with the current selections
    const filterString = this.buildFilterString(categoryIdsString, vendorIdsString, includesAnyMedicatedWeight ? [] : medicatedWeightsValue);

    if (!filterString) {
      return;
    }

    const params = {
      ...defaultSearchParams,
      filter: filterString,
    };

    getSearchData('/api/search/item_masters', null, null, params).then((newItemMasters) => {
      this.addItemMasters(newItemMasters);
      this.updateFilterStates(categoryIdsValue, vendorIdsValue, medicatedWeights);
    });
  }

  // Keep this in case we want to tweak the filter
  // const applyFilter = (option, filterInput) => {
  //   if (filterInput === '') {
  //     return true;
  //   }
  //   return (new RegExp(filterInput, 'i')).test(option.label);
  // };

  render() {

    const { getFormValue, change, onSelectedItemMasterChange, selectedItemMasterIds, vendors, medicatedWeights, formLabel, isMandatory, hideFormLabel} = this.props;

    return (
      <React.Fragment>
        {!hideFormLabel && !!formLabel &&
          <React.Fragment>
            {isMandatory && <FaAsterisk style={{fontSize: '0.5em', verticalAlign: 'top', marginTop: '1px'}}/>}
            <FormLabel style={{fontWeight: 'bold'}}>{formLabel}</FormLabel>
          </React.Fragment>
        }
        <Row>
          <Col md={3}>
            <Field
              name={'groupByCategory'}
              component={InlineCheckBox}
              props={{
                label: I18n.t('common.dualProductList.groupByCategory')
              }}/>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Field
              name='category_ids'
              component={MultiselectInput}
              props={{
                label: I18n.t('common.dualProductList.filterByCategory'),
                options: this.getCategoriesOptions(),
                allOption: true,
                defaultValue: [],
                textKey: 'label',
                valueKey: 'value',
                onChange: (value) => {
                  this.handleFilterChange(value, getFormValue('vendor_ids'), getFormValue('medicated_weights'));
                }
              }}
            />
          </Col>
          <Col md={3}>
            <Field
              name='subcategory_id'
              component={ReactSelectInput}
              props={{
                label: I18n.t('common.dualProductList.filterBySubcategory'),
                options: this.getSubcategoriesOptions(getFormValue('category_ids')),
                textKey: 'label',
                valueKey: 'value',
                disabled: !getFormValue('category_ids'),
                onChange: (value) => {
                  change('subcategory_id', value);
                }
              }}
            />
          </Col>
          {vendors && vendors.length > 0 &&
            <Col md={3}>
              <Field
                name='vendor_ids'
                component={MultiselectInput}
                props={{
                  label: I18n.t('common.dualProductList.filterByVendor'),
                  options: vendors,
                  allOption: true,
                  textKey: 'name',
                  valueKey: 'id',
                  onChange: (value) => {
                    this.handleFilterChange(getFormValue('category_ids'), value, getFormValue('medicated_weights'));
                  }
                }}
              />
            </Col>
          }
          {medicatedWeights && medicatedWeights.length > 0 &&
            <Col md={3}>
              <Field
                name='medicated_weights'
                component={MultiselectInput}
                props={{
                  label: I18n.t('common.dualProductList.filterByMedicatedWeight'),
                  options: this.getMedicatedWeightOptions(),
                  allOption: true,
                  textKey: 'label',
                  valueKey: 'value',
                  onChange: (value) => {
                    this.handleFilterChange(getFormValue('category_ids'), getFormValue('vendor_ids'), value);
                  }
                }}
              />
            </Col>
          }
        </Row>
        <DualListBox
          available={this.getAvailable()}
          options={this.getItemMastersOptions()}
          selected={selectedItemMasterIds}
          canFilter={true}
          // filterCallback={(option, filterInput) => {
          //   return applyFilter(option, filterInput);
          // }}
          onChange={onSelectedItemMasterChange}
        />
      </React.Fragment>
    );
  }
}

DualListProductSelectorComponent.propTypes = {
  change: PropTypes.func.isRequired,
  getFormValue: PropTypes.func.isRequired,
  defaultSearchParams: PropTypes.object.isRequired,
  itemMasters: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  vendors: PropTypes.array,
  medicatedWeights: PropTypes.array,
  selectedItemMasterIds: PropTypes.array.isRequired,
  onSelectedItemMasterChange: PropTypes.func.isRequired,
  disabledItemMasterIds: PropTypes.array,
  formLabel: PropTypes.string,
  isMandatory: PropTypes.bool,
  hideFormLabel: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    itemMasters: state[dataNames.itemMasters] ? state[dataNames.itemMasters] : [],
  };
}

function mapDispatchToProps (dispatch) {
  const actions = Object.assign({}, {getDataByPost, getSearchData, setData, unsetData});

  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DualListProductSelectorComponent);

