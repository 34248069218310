/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {push} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';

import {getUnpaginatedData, putItem} from '../../actions/apiActions';
import {getRegisterListingData} from '../../selectors/registersSelectors';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';

import TablePageWrapper from '../common/grid/TablePageWrapper';
import InternationalDecimalStatic from '../common/form/InternationalDecimalStatic';

import PageTitle from '../common/PageTitle';
import {setSelectedData} from '../../actions/selectedDataActions';


const inactiveColumns = [
  {
    name: 'registers.table.id',
    dataId:'id',
    hidden: true,
    dataAlign: 'center',
    width: '50px'
  },
  {
    name: 'registers.table.registerName',
    dataId:'name',
    hidden: false,
    dataAlign: 'center',
    width: '50px'
  },
  {
    name: 'registers.table.type',
    dataId:'type',
    hidden: false,
    dataAlign: 'center',
    width: '50px'
  }
];
const activeColumns = [
  ...inactiveColumns,
  {
    name: 'registers.table.balance',
    dataId:'current_level',
    hidden: false,
    dataAlign: 'center',
    formatter: (cell, row) => (<InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>),
    width: '50px'
  },
  {
    name: 'registers.table.alertAbove',
    dataId:'alert_above',
    hidden: false,
    dataAlign: 'center',
    formatter: (cell, row) => cell == '0.00' ? '-' : <InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>,
    width: '50px'
  },
  {
    name: 'registers.table.alertBelow',
    dataId:'alert_below',
    hidden: false,
    dataAlign: 'center',
    formatter: (cell, row) => cell == '0.00' ? '-' : <InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>,
    width: '50px'
  },
  {
    name: 'registers.table.collectsFrom',
    dataId:'collectsFrom',
    hidden: false,
    dataAlign: 'center',
    formatter: (cell, row) => cell ? cell : '-',
    width: '50px'
  },
];

export class RegisterPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    const searchString = '';
    const sizePerPage = 50;
    const columns = props.params.status == 'inactive' ? inactiveColumns : activeColumns;
    this.state = {columns, searchString, sizePerPage, selectedRegisters: []};
    this.createRegister = this.createRegister.bind(this);
    this.modifyRegister = this.modifyRegister.bind(this);
    this.activateRegister = this.activateRegister.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
    this.setSizePerPage = this.setSizePerPage.bind(this);
    this.setTab = this.setTab.bind(this);
    this.transferCash = this.transferCash.bind(this);
    this.viewHistory = this.viewHistory.bind(this);
    this.ref = React.createRef();
  }

  componentWillMount() {
    const active = this.props.params.status == 'inactive' ? 0 : 1;
    this.props.actions.getUnpaginatedData('/api/registers', dataNames.registers, {failed:'registers.get.failed'}, {detailed:1, active});
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.params.status != this.props.params.status) {
      const active = nextProps.params.status == 'inactive' ? 0 : 1;
      this.props.actions.getUnpaginatedData('/api/registers', dataNames.registers, {failed:'registers.get.failed'}, {detailed:1, active});
      this.setState({
        columns: active ? activeColumns : inactiveColumns,
        selectedRegisters: []
      });
    }
  }

  setSizePerPage(sizePerPage) {
    this.setState({sizePerPage});
  }

  setTab(activeTab) {
    this.props.actions.push(`/registers/${activeTab}`);
  }

  createRegister (event) {
    this.props.actions.push('/registers/create');
  }

  modifyRegister (event) {
    const id = this.state.selectedRegisters.length ? this.state.selectedRegisters[0] : null;
    if (id) {
      this.props.actions.push(`/registers/modify/${id}`);
    }
  }

  activateRegister () {
    const {putItem} = this.props.actions;
    const id = this.state.selectedRegisters.length ? this.state.selectedRegisters[0] : null;
    if (id) {
      const register = {id, active: 1};
      putItem(`/api/registers/${id}`,
        register,
        itemNames.register,
        {success: 'registers.modify.success', failed: 'registers.modify.fail'},
        undefined,
        () => this.setTab('active')
      );
    }
  }

  transferCash (event) {
    if (this.state.selectedRegisters.length) {
      this.props.actions.setSelectedData(this.state.selectedRegisters, dataNames.registers);
      this.props.actions.push(`/transfer_cash`);
    }
  }

  viewHistory (event) {
    if (this.state.selectedRegisters.length) {
      const registerId = this.state.selectedRegisters[0];
      this.props.actions.push(`registers/history/${registerId}`);
    }
  }

  exportCSV () {
    this.ref.current.wrappedInstance.getBaseRef().handleExportCSV();
  }

  setVisibleColumns (event) {
    const {columns} = this.state;
    const {id} = event.target;
    columns[id].hidden = !columns[id].hidden;
    this.setState({columns:[...columns]});
  }

  handleSelect (isSelected, rows) {
    const ids = rows.map(row => row.id);
    if(isSelected){
      this.setState({selectedRegisters: ids});
    }else {
      this.setState({selectedRegisters: []});
    }
  }

  updateSearch (event) {
    const {value} = event.target;
    this.setState({searchString:value});
    this.ref.current.wrappedInstance.handleSearch(value);
  }

  render () {
    const {registers, params} = this.props;
    const {columns} = this.state;
    const activeActions = [
      {id: 'createRegister', func: this.createRegister, text: 'registers.actions.createRegister', glyph: 'plus-sign', requireSelect: false},
      {id: 'modifyRegister', func: this.modifyRegister, text: 'registers.actions.modifyRegister', glyph: 'edit', requireSelect: true},
      {id: 'transferCash', func: this.transferCash, text: 'registers.actions.transferCash', glyph: 'transfer', requireSelect: true},
      {id: 'viewHistory', func: this.viewHistory, text: 'registers.actions.viewHistory', glyph: 'list', requireSelect: true}
    ];
    const inactiveActions = [
      {id: 'activate', func: this.activateRegister, text: 'registers.actions.reactivate', glyph: 'ok', requireSelect: true}
    ];
    const actions = params.status == 'inactive' ? inactiveActions : activeActions;
    const tabs = [
      {id: 'active', eventKey: 'active', title: 'registers.nav.active', actions},
      {id: 'inactive', eventKey: 'inactive', title: 'registers.nav.inactive', actions}
    ];
    const activeTab = params.status ? params.status : 'active';

    return (
      <div className='register-page'>
        <PageTitle primaryText={I18n.t('registers.title')}/>
        <TablePageWrapper
          ref={this.ref}
          settingKey='registers'
          columns={columns}
          data={registers}
          handleSelect={this.handleSelect}
          selectedRows={this.state.selectedRegisters}
          tabs={tabs}
          activeTab = {activeTab}
          switchTab={this.setTab}
          className = 'register-page'
          hideExport={true}
          hideScanSearch={true}
          bstProps={{
            selectRow: {
              mode: 'radio',
              clickToSelect: true,
              selected: this.state.selectedRegisters,
              onSelect: (row, isSelected) => this.handleSelect(isSelected, [row])
            },
            options: {
              hideSizePerPage: true,
              onRowClick: row => {}
            },
            expandableRow: () => false,
            expandComponent: () => <p>hello</p>
          }}
        />
      </div>
    );
  }
}

RegisterPage.propTypes = {
  registers: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired
};

// RegisterPage.contextTypes = {
//   router: React.PropTypes.object.isRequired
// };

function mapStateToProps(state) {
  return {
    registers: getRegisterListingData(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {push, getUnpaginatedData, putItem, setSelectedData};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (RegisterPage);
