import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';
import {FieldArray, Field, FormSection} from 'redux-form';
import {Row, Col} from 'react-bootstrap';
import NumericInput from '../../common/form/NumericInput';
import InternationalDecimalInput from '../../common/form/InternationalDecimalInput';
import TextInput from '../../common/form/TextInput';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import AccordionPanel from '../../common/AccordionPanel';
import MultiPackFieldArray from './MultiPackFieldArray';
import * as uomTypes from '../../../constants/uomTypes';

import {
  isCategoryFlower, isCategoryConcentrate, isCategoryPlants,
  isSubCategoryWaste, isCategoryMarijuana, isSubCategorySeeds,
  isCategoryCartridgesPens, isSubCategoryFinishedFlower
} from '../../../selectors/categorySelectors';
import {EA, GR, MG} from '../../../constants/uoms';
import InlineCheckBox from '../../common/form/InlineCheckBox';

/**
 *
 * @param value
 * @param change
 */
const changeUnitWeightUom = (value, change) => {
  change('net_weight_uom', value);
  change('unit_weight_uom', value);
};

const InventorySettingsPanel = (props) => {
  const {
    allUoms,
    isEdible,
    formValues,
    isMultipack,
    isLockedProduct,
    selectedCategory,
    integrationState,
    selectedSubCategory,
    isPrepackWithInventory,
    isSeedPackagingAllowed,
    productNetWeightRequired,
    medicatedWeightRequired,
    isWaInventoryMapped,
    change,
    isPaLeaf
  } = props;

  const {isWaLeaf, isLeaf, isBiotrack, isMetrc, isCanada, isMiMetrc, isOrMetrc, isUtah} = integrationState;

  const sc = selectedCategory;
  const ssc = selectedSubCategory;
  const isCanadianEASeed = isCategoryPlants(sc) && isSubCategorySeeds(ssc) && isSeedPackagingAllowed && formValues.default_uom === EA && isCanada;
  const uoms = (Array.isArray(allUoms)) ? allUoms.filter((uom) => [uomTypes.WEIGHT,uomTypes.VOLUME].indexOf(uom.uom_type) > -1) : [];
  const seedWeightUoms = uoms.filter(uom => [GR, MG].indexOf(uom.uom_code) > -1);
  const weightUoms = uoms.filter(uom => uom.uom_type === uomTypes.WEIGHT);
  const uomOptions = isCanadianEASeed ? seedWeightUoms : (isMetrc ? weightUoms : uoms);
  const showVolumeFields = (isCanada || isMiMetrc) && Boolean((isCategoryCartridgesPens(sc)) || (formValues.required_fields && formValues.required_fields.requires_unit_volume));
  // Track in Multi-Packs is being hidden for now.
  // const isShowTrackMultiPacksField = !isCategoryFlower(sc) && !isCategoryConcentrate(sc);
  const isShowTrackMultiPacksField = false;
  const isShowIsPrepackField = (isCategoryFlower(sc) || isCategoryConcentrate(sc) || isCategoryMarijuana(sc)) && ((formValues.default_uom && formValues.default_uom !== 'EA') || !formValues.default_uom);
  const isLotTrackedDisabled = isCategoryPlants(sc) && isSubCategoryWaste(ssc) || isCanadianEASeed;
  const isCanadianConcentrate = isCategoryConcentrate(sc) && isCanada;
  const isLeafPASubCategoryFinishedFlower = (isLeaf || isPaLeaf )&& isSubCategoryFinishedFlower(ssc);
  const disablePrepackForWaSelections = isWaLeaf && get(sc, 'category_code', null) === 'FLOWER' &&
    (get(ssc, 'subcategory_code', null) === 'BUD' || get(ssc, 'subcategory_code', null) === 'TRIM' || get(ssc, 'subcategory_code', null) === 'SHAKE');

  const showNetWeight = Boolean(
    (isMetrc && formValues.default_uom === 'EA') ||
    isEdible || isCanadianEASeed || isCanadianConcentrate ||
    (formValues.required_fields && formValues.required_fields.requires_unit_weight) ||
    (formValues.required_fields && formValues.required_fields.requires_unit_thc_content)
  );

  return (
    <AccordionPanel title='products.form.inventorySettings' className='form-panel container-info'>
      <Row>
        <Col sm={12}>
          <FormSection name='inventory_attributes'>
            {isShowTrackMultiPacksField
              ? <Row style={{marginBottom: '12px'}}>
                  <Col>
                    <Field name='multipack_enabled' component={InlineCheckBox}
                      props={{label:I18n.t('products.form.trackMultiPacks'), groupClassName:'inline-checkbox'}}
                      checked={isShowTrackMultiPacksField && get(formValues, 'inventory_attributes.multipack_enabled', false)}
                    />
                  </Col>
                </Row>
              : null}
            {/* Create Individual SKUs is being hidden for now.
            <Field name='require_individual_skus'
              component={CheckBoxInput}
              props={{label:I18n.t('products.form.createIndSku'), groupClassName:'inline-checkbox'}}
            />*/}
            {isShowIsPrepackField
              ? <Row style={{marginBottom: '12px'}}>
                  <Col>
                    <Field name='is_prepack' component={InlineCheckBox} props={{
                      label:I18n.t('products.form.prePackItem'),
                      groupClassName:'inline-checkbox',
                      checked: !disablePrepackForWaSelections && (isLeafPASubCategoryFinishedFlower || (isShowIsPrepackField && get(formValues, 'inventory_attributes.is_prepack', false))),
                      disabled: disablePrepackForWaSelections || isLeafPASubCategoryFinishedFlower || isLockedProduct || (isPrepackWithInventory && get(formValues, 'inventory_attributes.is_prepack', false)) || isWaInventoryMapped,
                    }}/>
                  </Col>
                </Row>
              : null}
            <Row style={{marginBottom: '12px'}}>
              <Col>
                <Field name='lot_tracked' component={InlineCheckBox} props={{
                  label:I18n.t('products.form.trackLots'),
                  groupClassName:'inline-checkbox',
                  disabled: isLockedProduct || isLotTrackedDisabled || get(formValues, 'inventory_attributes.is_prepack', false),
                  checked: (get(formValues, 'inventory_attributes.is_prepack', false) || get(formValues, 'inventory_attributes.lot_tracked', false))
                }}/>
              </Col>
            </Row>
          </FormSection>
        </Col>
      </Row>
      {showNetWeight || medicatedWeightRequired ?
        <Row>
          <Col xs={12}>
            <hr/>
          </Col>
          <Col md={4}>
            <Row>
              <Col xs={8} className='field-width-100'>
                <InternationalDecimalInput name='medicated_weight'props={{
                  label: isOrMetrc && get(ssc, 'subcategory_code') && ['NON-INFUSED PRE-ROLLS', 'INFUSED PRE-ROLLS'].indexOf(ssc.subcategory_code) > -1 && get(formValues, 'inventory_attributes.is_prepack') ? I18n.t('products.form.medicatedNetWeightPer1Gr') : I18n.t('products.form.medicatedNetWeight'),
                  fractionPartSize: 2,
                  step: 0.01,
                  isRequired: (formValues.required_fields && formValues.required_fields.requires_unit_thc_content) || medicatedWeightRequired,
                }}/>
                {(isPaLeaf && medicatedWeightRequired) && <span className='small text-danger'>{I18n.t('products.form.newMmuRegulation')}</span>}
              </Col>
              <Col xs={4}>
                <Field name='medicated_weight_uom' component={ReactSelectInput} props={{
                  label: I18n.t('products.form.uom'),
                  options: isUtah ? [{ uom_code: 'MG' }] : isMetrc || isBiotrack ? weightUoms : uoms,
                  textKey: 'uom_code',
                  valueKey: 'uom_code',
                  isRequired: formValues.required_fields && formValues.required_fields.requires_unit_thc_content || medicatedWeightRequired
                }}/>
              </Col>
            </Row>
          </Col>
          {showNetWeight ?
            <React.Fragment>
              <Col md={4}>
                <Row>
                  <Col xs={8} className='field-width-100' >
                    <InternationalDecimalInput name='net_weight' props={{
                      label: I18n.t('products.form.productNetWeight'),
                      fractionPartSize: 2,
                      step: 0.01,
                      isRequired: formValues.required_fields && formValues.required_fields.requires_unit_weight || productNetWeightRequired,
                    }}/>
                  </Col>
                  <Col xs={4}>
                    <Field name='net_weight_uom' component={ReactSelectInput} props={{
                      label: I18n.t('products.form.uom'),
                      options: uomOptions,
                      textKey: 'uom_code',
                      valueKey: 'uom_code',
                      isRequired: formValues.required_fields && formValues.required_fields.requires_unit_weight || productNetWeightRequired,
                      onChange: value => changeUnitWeightUom(value, change),
                    }}/>
                  </Col>
                </Row>
              </Col>
              <Col md={4}>
                <Row>
                  <Col xs={8} className='field-width-100' >
                    <InternationalDecimalInput name='unit_weight' props={{
                      label: I18n.t('products.form.grossWeight'),
                      fractionPartSize: 2,
                      step: 0.01
                    }}/>
                  </Col>
                  <Col xs={4}>
                    <Field
                      name='unit_weight_uom'
                      component={TextInput}
                      props={{
                        label: I18n.t('products.form.uom'),
                        disabled: true
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </React.Fragment>
            : null}
        </Row>
        : null}
      {showVolumeFields || formValues.required_fields && formValues.required_fields.requires_unit_volume
        ? <Row>
            <Col md={4}>
              <Row>
                <Col md={8} className='field-width-100' >
                  <Field name='medicated_volume' component={NumericInput} props={{
                    label: I18n.t('products.form.medicatedNetVolume'),
                    step: 0.01,
                    isRequired: isMiMetrc
                  }}/>
                </Col>
                <Col md={4}>
                  <Field name='medicated_volume_uom' component={ReactSelectInput} props={{
                    label: I18n.t('products.form.uom'),
                    options: [{uom_code: 'ML'}],
                    textKey:'uom_code',
                    valueKey:'uom_code',
                    disabled: true,
                    isRequired: isMiMetrc
                  }}/>
                </Col>
              </Row>
            </Col>
            <Col md={4}>
              <Row>
                <Col md={8} className='field-width-100' >
                  <Field name='net_volume' component={NumericInput} props={{
                    label: I18n.t('products.form.netVolume'),
                    step: 0.01,
                  }}/>
                </Col>
                <Col md={4}>
                  <Field name='net_volume_uom' component={ReactSelectInput} props={{
                    label: I18n.t('products.form.uom'),
                    options: [{uom_code: 'ML'}],
                    textKey:'uom_code',
                    valueKey:'uom_code',
                    disabled: false,
                  }}/>
                </Col>
              </Row>
            </Col>
          </Row>
        : null}
      {isMultipack && <hr/>}
      {isMultipack && <FieldArray name='container_types' component={MultiPackFieldArray}/>}
    </AccordionPanel>
  );
};

InventorySettingsPanel.propTypes = {
  integrationState: PropTypes.object.isRequired,
  isEdible: PropTypes.bool,
  isMultipack: PropTypes.bool,
  isLockedProduct: PropTypes.bool,
  isSeedPackagingAllowed: PropTypes.bool,
  allUoms: PropTypes.array,
  selectedCategory: PropTypes.object,
  selectedSubCategory: PropTypes.object,
  formValues: PropTypes.object,
  medicatedWeightRequired: PropTypes.bool,
  productNetWeightRequired: PropTypes.bool,
  isPrepackWithInventory: PropTypes.bool,
  isWaInventoryMapped: PropTypes.bool,
  isPaLeaf: PropTypes.bool,
  change: PropTypes.func
};


export default InventorySettingsPanel;
