import React from 'react';
import PropTypes from 'prop-types';

import ReduxBootstrapInput from './ReduxBootstrapInput';
import RatingStarsControl from './RatingStarsControl';// eslint-disable-line import/no-named-as-default

const RatingInput = ({label, size, input, meta, name, isRequired, ...props}) => {

  return (
    <ReduxBootstrapInput Control={RatingStarsControl}
                         label={label} size={size}
                         input={input} meta={meta}
                         name={name} isRequired={isRequired}
                         {...props}/>
  );
};

RatingInput.propTypes = {
  label: PropTypes.string,
  size: PropTypes.oneOf(['lg', 'sm', 'large', 'small']),
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  name: PropTypes.string,
  children: PropTypes.node,
  isRequired: PropTypes.bool
};

export default RatingInput;
