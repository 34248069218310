import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import {reduxForm, Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import * as p from '../../constants/permissions';
import PermissionWrapper from '../common/PermissionWrapper';
import RouteButton from '../common/RouteButton';
import InternationalQuantityByUomInput from '../common/form/InternationalQuantityByUomInput';
import ReactSelectInput from '../common/form/ReactSelectInput';
import PredicateConcealer from '../common/concealers/PredicateConcealer';
import PackagingRows from './PackagingRows';
import validate from './validate';
import {isItemProcessable, isItemTestable} from '../../selectors/inventoryItemsSelectors';
import ConnectedTestResults from './TestResults';
import MedicallyCompliantStatus from './MedicallyCompliantStatus';
import {getModeAndFacilityType, facilityCanPackage} from '../../selectors/accessSelectors';
import PermissionButton from '../common/PermissionButton';
import * as uomTypes from '../../constants/uomTypes';

const ChildForms = (props) => {

  const {
    item, handleSubmit, locations, sharedLocations, showProductionRunsModal, isManufacturing,
    isIngredient, quickMoveDisabled, activeTab, integrationState, collapse, fetchChildrenAndLabResults, showReservationsModal,
    canUserManageReservationAndIsAllowedInComplianceSettings, quickMoveQtyDisabled, useEntityLocks
  } = props;

  const {isLeaf, isPaLeaf, isWaLeaf} = integrationState;
  const isProcessableForPa = () => {
    if (!isPaLeaf) return true;
    if (isManufacturing) return true;
    if (!item.lab_results_id) return false;
    if (!item.on_hold) return true;
    return item.status === 'passed';

  };

  const isProcessable = isProcessableForPa() && isItemProcessable(item, isLeaf, isManufacturing);
  const quickMoveClassName = '';
  const availableLocations = item.is_shared_item ? sharedLocations : locations;
  const disabledForWaLeafWaste = isWaLeaf === false
    ? false
    : (activeTab !== undefined && (activeTab.id === 'activeWasteTab' || activeTab.id === 'inactiveWasteTab'));

  const moveableUom = get(item, 'uom');
  const enforceEntityLocks = useEntityLocks && item.is_locked;

  return (<form>
    <div className='layout-row layout-align-start-start'>
      {!isIngredient ?
      <div className='layout-row flex-col'>
        <div className='flex-col action-col'>
          <label>{I18n.t('cultivation.finishedProduct.modifyLot')}</label><br/>
          <RouteButton
            path={`/packages/modify/${item.id}`}
            props={{size: 'sm'}}
            disabled={quickMoveDisabled || disabledForWaLeafWaste || enforceEntityLocks}
          >
            {I18n.t('cultivation.finishedProduct.modify')}
          </RouteButton>
        </div>
        {isItemTestable(item) ?
          <div className='flex-col action-col'>
            <ConnectedTestResults item={{reference_id: item.package_id, ...item}} fetchChildrenAndLabResults={fetchChildrenAndLabResults} />
          </div>
          : null
        }
        {[uomTypes.WEIGHT, uomTypes.VOLUME].includes(item.uom_type) && isProcessable && item.package_active ?
          <PredicateConcealer selector={getModeAndFacilityType} predicate={facilityCanPackage}>
            <div className='flex-col action-col'>
              <label>{I18n.t('cultivation.finishedProduct.packaging')}</label><br/>
              <PackagingRows row={item}/>
            </div>
          </PredicateConcealer>
          : null
        }
        {
          canUserManageReservationAndIsAllowedInComplianceSettings ?
            <div className='flex-col action-col' style={{padding: '24px 5px 0 5px'}}>
              <PermissionButton
                permissions={[p.manage_reservations]}
                props={{
                  variant: 'primary',
                  size: 'sm',
                  className: 'btn-block',
                  onClick: (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    showReservationsModal(item);
                  }
                }}>
                {I18n.t('products.form.reservationsHistory')}
              </PermissionButton>
            </div> : null
        }

        {isManufacturing ? <div className='flex-col action-col'>
          <label> {I18n.t('productionRuns.title')}</label><br/>
          {item.production_run_ids ?
            <Button
              onClick={() => showProductionRunsModal(item)}
              variant='success'
              size='sm'
              disabled={quickMoveDisabled}
            >
              {I18n.t('inventory.table.view')}
            </Button>
            : I18n.t('productionRuns.noRuns')
          }
          </div> : null}
      </div>
      : <div className='flex' />}
      <div className='flex-col normal-col'>
        <label>{I18n.t('inventory.table.amount')}</label><br/>
        <InternationalQuantityByUomInput
          name='qty'
          max={item.qty - item.qty_reserved}
          disabled={quickMoveDisabled || disabledForWaLeafWaste || quickMoveQtyDisabled || enforceEntityLocks}
          placeholder={I18n.t('cultivation.finishedProduct.form.Num')}
          uom={moveableUom}
        />
      </div>
      <div className='flex-col normal-col'>
        <label>{I18n.t('inventory.table.available')} ({I18n.t('inventory.table.total')})</label><br/>
        <InternationalQuantityByUomInput
          name='qty_available'
          value={item.qty - item.qty_reserved + ' (' + item.qty + ')'}
          disabled={true}
          uom={moveableUom}
        />
      </div>
      <div className='flex-col normal-col'>
        <label>{I18n.t('inventory.table.destination')}</label><br/>
        <Field
          name={`inventory_location_id`}
          component={ReactSelectInput}
          props={{
            placeholder: I18n.t('cultivation.finishedProduct.form.storageLocation'),
            options: availableLocations,
            disabled: quickMoveDisabled || enforceEntityLocks,
            groupClassName: quickMoveClassName,
          }} />
      </div>

      {isWaLeaf && <MedicallyCompliantStatus item={item}/>}

      {!isIngredient ? <PermissionWrapper permissions={[p.print_labels]}>
        <div className={`flex-col ${isIngredient ? 'normal-col' : 'small-col'}`} />
      </PermissionWrapper> : null}
      <PermissionWrapper permissions={[p.view_inventory_history]}>
        <div className={`flex-col ${isIngredient ? 'normal-col' : 'small-col'}`} />
      </PermissionWrapper>
      <div className={`flex-col ${isIngredient ? 'normal-col' : 'small-col'}`}>
        <label>&nbsp;</label><br/>
        <RouteButton
          disabled={quickMoveDisabled || enforceEntityLocks}
          path={`/packages/modify/${item.id}`}
          props={{
            size: 'sm',
            onClick: (event) => {
              const promise = handleSubmit(event);
              if (promise && promise.then) {
                promise.then(() => collapse());
              }
            },
            className: quickMoveClassName,
            variant: 'primary'
          }}
        >
          {I18n.t('common.form.save')}
        </RouteButton>
      </div>
    </div>
    <div className='layout-row layout-align-end-start'>
      {!isIngredient ? <PermissionWrapper permissions={[p.print_labels]}>
        <div className='flex-col small-col' />
      </PermissionWrapper> : null}
      <PermissionWrapper permissions={[p.view_inventory_history]}>
        <div className={`flex-col ${isIngredient ? 'normal-col' : 'small-col'}`} />
      </PermissionWrapper>
    </div>
    </form>);
};

ChildForms.propTypes = {
  item: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  sharedLocations: PropTypes.array.isRequired,
  total: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  showProductionRunsModal: PropTypes.func,
  isManufacturing: PropTypes.bool,
  isIngredient: PropTypes.bool,
  quickMoveDisabled: PropTypes.bool,
  activeTab:  PropTypes.object,
  collapse: PropTypes.func.isRequired,
  fetchChildrenAndLabResults: PropTypes.func.isRequired,
  integrationState: PropTypes.object.isRequired,
  showReservationsModal: PropTypes.func.isRequired,
  canUserManageReservationAndIsAllowedInComplianceSettings: PropTypes.bool,
  quickMoveQtyDisabled: PropTypes.bool,
  useEntityLocks: PropTypes.bool
};


export default reduxForm({
  validate,
})(ChildForms);
