/* eslint-disable react/no-multi-comp */
import React from 'react';
import { I18n } from 'react-redux-i18n';
import get from 'lodash.get';
import { Button } from 'react-bootstrap';
import {FaSave, FaTrash} from 'react-icons/fa';
import * as p from '../../constants/permissions';
import PermissionLink from '../common/PermissionLink';
import PermissionButton from '../common/PermissionButton';
import { getTranslationLabel } from '../../util/translationHelpers';
import InternationalDecimalStatic from '../common/form/InternationalDecimalStatic';
import InternationalDateTimeStatic from '../common/form/InternationalDateTimeStatic';
import HarvestBatchIntegrationTrackingStatus from './HarvestBatchIntegrationTrackingStatus';

const getLabel = getTranslationLabel('harvestBatch.table.');

export default function getColumns(Component, isServiceFirst = false) {
  const { props } = Component;

  const {
    integrationState: { isLeaf, isWaLeaf, isMetrc, isBiotrack, isColombia },
    cupos
  } = props;
  const trackPlantsAsGroups = get(props, 'complianceSettings.cult_track_plants_as_groups.value', false);

  const columns = [
    {
      name: 'harvestBatch.table.strain',
      dataId: 'strain_name',
      dataSort: !isServiceFirst,
      hidden: false,
      width: '100px'
    }
  ];

  if (isColombia) {
    columns.push({
      name: I18n.t('plants.form.cupo'),
      dataId: 'cupo_id',
      hidden: false,
      dataSort: true,
      scanSearchColumn: false,
      formatter: (cell, row) => (row.cupo_id ? (cupos.find((cupo) => cupo.id === row.cupo_id) || {}).cupo_number : ''),
      width: '150px'
    });

    columns.push({
      name: I18n.t('plants.form.modality'),
      dataId: 'modality',
      hidden: false,
      dataSort: true,
      scanSearchColumn: false,
      formatter: (cell, row) => (row.modality ? I18n.t(`modalities.list.${row.modality}`) : ''),
      width: '150px'
    });
  }

  columns.push(
    {
      name: 'harvestBatch.table.harvestBatch',
      dataId: 'batch_name',
      hidden: false,
      width: '120px'
    },
    {
      name: trackPlantsAsGroups ? 'harvestBatch.table.groupTotal' : 'harvestBatch.table.plantTotal',
      dataId: 'plants_count',
      hidden: false,
      width: '100px'
    },
    {
      name: getLabel('currentWeight', props.integrationState),
      dataId: 'current_weight',
      hidden: false,
      width: '140px',
      formatter: (cell) => <InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>
    },
    {
      name: getLabel('otherMaterialWeight', props.integrationState),
      dataId: 'current_other_material_weight',
      hidden: false,
      width: '140px',
      available: isWaLeaf
    },
    {
      name: getLabel('finalWeight', props.integrationState),
      dataId: 'final_weight_recorded',
      hidden: false,
      formatter: (cell, row) => {
        return (
          row.final_weight_recorded
            ? <FaSave className='success' />
            : <FaTrash className='danger'/>
        );
      },
      width: '140px',
      available: props.integrationState.isPaLeaf
    },
    {
      name: 'harvestBatch.table.uom',
      dataId: 'uom_display',
      hidden: false,
      width: '70px'
    },
    {
      name: getLabel('wasteReported', props.integrationState),
      dataId: 'total_waste_recorded',
      hidden: false,
      width: '140px',
      formatter: (cell) => <InternationalDecimalStatic fractionPartSize={2}>{cell}</InternationalDecimalStatic>
    },
    {
      name: getLabel('otherMaterialWaste', props.integrationState),
      dataId: 'total_other_material_waste',
      hidden: false,
      width: '140px',
      available: isWaLeaf
    },
    {
      name: 'harvestBatch.table.moistureLoss',
      dataId: 'calculated_moisture_loss',
      hidden: false,
      width: '120px',
      formatter: (cell) => <InternationalDecimalStatic fractionPartSize={2} suffix={' %'} >{cell}</InternationalDecimalStatic> ,
      available: !isWaLeaf,
      permissions: [p.manage_harvests]
    },
    {
      name: 'plants.table.location',
      dataId: isBiotrack ? 'section_name' : 'location_name',
      dataSort: !isServiceFirst,
      hidden: false,
      width: '120px'
    },
    {
      name: 'plants.table.dateCreated',
      dataId: 'harvested_at',
      hidden: false,
      width: '120px',
      formatter: (cell) => <InternationalDateTimeStatic useSystemDate={false} displayTime={false}>{cell}</InternationalDateTimeStatic>
    },
    {
      name: 'harvestBatch.table.rating',
      dataId: 'total_quality',
      hidden: false,
      dataSort: !isServiceFirst,
      width: '70px',
      available: props.showRating
    },
    {
      name: 'harvestBatch.table.trackingId',
      dataId: 'tracking_id',
      hidden: false,
      dataSort: !isServiceFirst,
      width: '150px',
      available: isLeaf || isMetrc,
      formatter: (cell, row) =>
        <HarvestBatchIntegrationTrackingStatus harvestBatch={row} />
    },
    {
      name: I18n.t('cultivation.finishedProduct.form.testResults'),
      dataId: 'test_result_status',
      dataSort: false,
      width: '120px',
      columnClassName: 'allow-wrap',
      scanSearchColumn: false,
      available: !isBiotrack && !isWaLeaf,
      formatter: (cell, row) =>
        cell ? (
          <PermissionButton
            permissions={[p.view_testing, p.manage_testing]}
            props={{
              size: 'sm',
              onClick: (event) => {
                event.stopPropagation();
                Component.showTestResults(row);
              }
            }}
          >
            {I18n.t('cultivation.testResults.showResults')}{' '}
            {cell === 'passed' ? (
              <FaSave className='success' />
            ) : (
              <FaTrash className='danger' />
            )}
          </PermissionButton>
        ) : (
          I18n.t('cultivation.testResults.noTestResults')
        )
    },
    {
      name: 'common.history',
      dataId: 'history',
      hidden: false,
      width: '120px',
      dataSort: false,
      permissions: [p.view_harvest_history],
      formatter: (cell, row) => {
        return (
          <PermissionLink
            permissions={[p.view_harvest_history]}
            props={{
              title: I18n.t('common.history'),
              to: `/harvests/${row.id}/history`,
              className: 'btn btn-default',
              target: '_blank',
              onClick: (event) => {
                // stop click on row
                event.stopPropagation();
              }
            }}
          >
            {I18n.t('common.history')}
          </PermissionLink>
        );
      }
    },
    {
      name: 'common.label',
      dataId: 'id',
      hidden: false,
      width: '100px',
      dataSort: false,
      formatter: (cell, row) => {
        return (
          <Button
            onClick={(e) => {
              e.target.blur();
              Component.onShowLabel(row.id);
            }}
            variant='info'
          >
            {I18n.t('common.label')}
          </Button>
        );
      }
    },
    {
      name: 'harvestBatch.table.changeStatus',
      dataId: 'status',
      hidden: false,
      width: '100px',
      available: props.integrationState.isMetrc,
      formatter: (cell, row) => {
        return row.active ? (
          <Button
            onClick={(e) => {
              e.target.blur();
              Component.onInactivateHarvest(row);
            }}
            variant='warning'
          >
            {I18n.t('common.inactivate')}
          </Button>
        ) : null;
      }
    }
  );

  return columns.filter((col) => col.available !== false).map(({ available, ...col }) => col);
}
