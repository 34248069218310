import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { Field, reduxForm } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { Button, ButtonToolbar, Col, Row } from 'react-bootstrap';

import validate from '../common/validateBatch';
import * as p from '../../../constants/permissions';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import InternationalDateTimePickerInput from '../../common/form/InternationalDateTimePickerInput';
import NumericInput from '../../common/form/NumericInput';
import TextInput from '../../common/form/TextInput';
import AddNoteSection from '../common/AddNoteSection';
import ReduxButton from '../../common/form/redux-form/ReduxButton';
import ReduxMetrcIdField from '../../common/form/redux-form/ReduxMetrcIdField';
import CheckBoxInput from '../../common/form/InlineCheckBox';
import PermissionWrapper from '../../common/PermissionWrapper';
import { getSettingValue } from '../../../util/settingsHelpers';
import ModalityField from '../../integration/colombia/ModalityField';
import CupoNumberField from '../../integration/colombia/CupoNumberField';

export const CreatePlantForm = (props) => {
  const {
    batch_name,
    handleSubmit,
    reset,
    invalid,
    strains,
    phenotypes,
    locations,
    schedules,
    motherPlants,
    submitting,
    pristine,
    saved,
    doPrint,
    matriarchPlant,
    metrcTags,
    integrationState: { isCaMetrc, isBiotrack, isMetrc, isWaLeaf, isColombia },
    plantTypes,
    sourceItems,
    complianceSettings,
    form,
    sourceItemIdIsDisabled,
    startingMotherPlantIdIsDisabled,
    disableSourceItemId,
    disableStartingMotherPlantId,
    change,
    batches,
    formValues,
    typeOfCannabis,
    addPlantsToExistingBatchFeatureEnabled
  } = props;

  return (
    <form className='plant-form' onSubmit={handleSubmit}>
      <Row className='form-fields-row'>
        <Col className='form-fields-col' xs={8} md={3} lg={3}>
          <Field
            name='strain_id'
            component={ReactSelectInput}
            props={{
              label: I18n.t('plants.form.strain'),
              options: strains,
              isRequired: true,
              textKey: 'strain_name',
              valueKey: 'id',
              placeholder: I18n.t('common.form.selectPlaceholder')
            }}
          />
        </Col>
        <Col className='form-fields-col' xs={8} md={3} lg={3}>
          <Field
            name='phenotype_id'
            component={ReactSelectInput}
            props={{
              label: I18n.t('plants.form.phenotype'),
              options: phenotypes,
              isRequired: false,
              textKey: 'name',
              valueKey: 'id',
              placeholder: I18n.t('common.form.selectPlaceholder'),
              disabled: !phenotypes || !phenotypes.length
            }}
          />
        </Col>
        <Col className='form-fields-col' xs={8} md={3} lg={2}>
          <InternationalDateTimePickerInput
            name='planting_date'
            props={{
              label: I18n.t('plants.form.plantingDate'),
              isRequired: true
            }}
          />
        </Col>
        {addPlantsToExistingBatchFeatureEnabled ? (
          <React.Fragment>
            <Col style={{ marginTop: '22px', textAlign: 'center' }} xs={2} md={3} lg={2}>
              <Field
                name='isEnabledChooseBatches'
                component={CheckBoxInput}
                props={{
                  label: I18n.t('plants.form.selectExistingBatch'),
                  disabled: !batches || !batches.length
                }}
              />
            </Col>
            <Col className='form-fields-col' xs={6} md={3} lg={2}>
              <Field
                name='batch_name'
                component={ReactSelectInput}
                readOnly
                props={{
                  label: I18n.t('plants.form.batch_name'),
                  options: batches,
                  isRequired: false,
                  textKey: 'batch_name',
                  valueKey: 'id',
                  placeholder: I18n.t('plants.form.batchPlaceholder'),
                  disabled: get(formValues, 'isEnabledChooseBatches') === false
                }}
              />
            </Col>
          </React.Fragment>
        ) : (
          <Col className='form-fields-col' xs={6} md={3} lg={2}>
            <Field
              name='batch_name'
              component={TextInput}
              readOnly
              props={{
                value: batch_name || '',
                label: I18n.t('plants.form.batch_name'),
                placeholder: I18n.t('plants.form.batchPlaceholder')
              }}
            />
          </Col>
        )}
        <CupoNumberField
          formName={form}
          LayoutComponent={({ children }) => (
            <Col xs={6} md={3}>
              {children}
            </Col>
          )}
        />
        <Col className='form-fields-col' xs={8} md={3} lg={3}>
          <Field
            name='starting_type'
            component={ReactSelectInput}
            props={{
              label: I18n.t('plants.form.plantType'),
              placeholder: I18n.t('common.form.selectPlaceholder'),
              options: plantTypes,
              isRequired: true
            }}
          />
        </Col>
        {isBiotrack || getSettingValue('cult_create_plant_from_inventory')(complianceSettings) ? (
          <Col xs={12} md={5} lg={4}>
            <Field
              name='source_item_id'
              component={ReactSelectInput}
              props={{
                label: I18n.t('plants.form.sourceItem'),
                options: sourceItems,
                textKey: 'display_name',
                valueKey: 'id',
                disabled: isMetrc && sourceItemIdIsDisabled,
                onChange: (value) => {
                  if (isMetrc) {
                    if (value) {
                      disableStartingMotherPlantId(true);
                    } else {
                      disableStartingMotherPlantId(false);
                    }
                  }

                  change('source_item_id', value);
                },
                isRequired: isBiotrack || isWaLeaf
              }}
            />
          </Col>
        ) : null}
        <Col className='form-fields-col' xs={6} md={4} lg={3}>
          <Field
            name='starting_qty'
            component={NumericInput}
            props={{
              label: I18n.t('plants.form.quantity'),
              max: isCaMetrc ? 100 : 9999,
              min: 1,
              placeholder: I18n.t('plants.form.quantityPlaceholder'),
              isRequired: true
            }}
          />
        </Col>
        <Col style={{ marginTop: '22px' }} xs={6} md={3} lg={2}>
          <Field
            name='is_mother'
            component={CheckBoxInput}
            props={{
              label: I18n.t('plants.form.isMother')
            }}
          />
        </Col>
        {isCaMetrc ? (
          <Col className='form-fields-col' xs={8} md={3} lg={3}>
            <ReduxMetrcIdField
              name='batch_tracking_id'
              props={{
                label: I18n.t('plants.form.trackingId'),
                options: metrcTags,
                textKey: 'text',
                valueKey: 'text'
              }}
            />
          </Col>
        ) : null}
        {isColombia && typeOfCannabis && (
          <Col className='form-fields-col' xs={8} md={3} lg={3}>
            <Field
              name='type_of_cannabis'
              component={TextInput}
              readOnly
              props={{
                value: I18n.t(`strains.edit.typeOfCannabisOptions.${typeOfCannabis}`),
                label: I18n.t('strains.typeOfCannabis')
              }}
            />
          </Col>
        )}

        <ModalityField
          formName={form}
          required={isColombia && formValues.cupo_id}
          LayoutComponent={({ children }) => (
            <Col xs={6} md={3}>
              {children}
            </Col>
          )}
        />
      </Row>
      <Row>
        <PermissionWrapper permissions={[p.manage_plant_lineage]}>
          {!isWaLeaf && (
            <Col className='form-fields-col' xs={8} md={3} lg={3}>
              <Field
                name='starting_mother_plant_id'
                component={ReactSelectInput}
                props={{
                  label: I18n.t('plants.form.motherPlant'),
                  options: motherPlants,
                  textKey: 'plant_id',
                  valueKey: 'id',
                  disabled: (isMetrc && startingMotherPlantIdIsDisabled) || !motherPlants || !motherPlants.length,
                  onChange: (value) => {
                    if (isMetrc) {
                      if (value) {
                        disableSourceItemId(true);
                      } else {
                        disableSourceItemId(false);
                      }
                    }
                    change('starting_mother_plant_id', value);
                  }
                }}
              />
            </Col>
          )}
          <Col className='form-fields-col' xs={8} md={3} lg={3}>
            <div className='form-group'>
              <Field
                name='grandmother_plant'
                component={TextInput}
                readOnly
                props={{
                  value: matriarchPlant || '',
                  label: I18n.t('plants.form.matriarchPlant')
                }}
              />
            </div>
          </Col>
        </PermissionWrapper>
        <Col className='form-fields-col' xs={8} md={3} lg={3}>
          <Field
            name='starting_section_id'
            component={ReactSelectInput}
            props={{
              label: I18n.t('plants.form.chooseLocation'),
              options: locations,
              isRequired: true,
              textKey: 'name',
              valueKey: 'id',
              placeholder: I18n.t('common.form.selectPlaceholder')
            }}
          />
        </Col>
        <Col className='form-fields-col' xs={8} md={3} lg={3}>
          <Field
            name='starting_feeding_schedule_id'
            component={ReactSelectInput}
            props={{
              label: I18n.t('plants.form.feeding'),
              options: schedules,
              textKey: 'schedule_name',
              valueKey: 'id',
              placeholder: I18n.t('common.form.selectPlaceholder')
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <AddNoteSection />
        </Col>
      </Row>
      <Row className='padding-top'>
        <Col xs={12}>
          <ButtonToolbar className='float-right'>
            <Button type='reset' disabled={saved} onClick={reset}>
              {I18n.t('packages.form.reset')}
            </Button>
            <ReduxButton
              variant='success'
              type='submit'
              name={'afterSubmit'}
              props={{ value: '' }}
              disabled={(invalid && !isColombia) || submitting || pristine}
            >
              {!saved ? I18n.t('plants.form.saveAndCreateMore') : I18n.t('plants.form.createMore')}
            </ReduxButton>
            <ReduxButton
              name={'afterSubmit'}
              permissions={[p.print_labels]}
              variant={'info'}
              type={'submit'}
              props={{
                value: 'print',
                onClick: () => doPrint()
              }}
              disabled={invalid || submitting || pristine}
            >
              {!saved ? I18n.t('common.saveAndPrintLabels') : I18n.t('common.printLabels')}
            </ReduxButton>
            <ReduxButton
              variant='primary'
              name={'afterSubmit'}
              className={saved ? 'hide' : ''}
              type='submit'
              props={{ value: 'redirect' }}
              disabled={(invalid && !isColombia) || submitting || pristine || saved}
            >
              {I18n.t('plants.form.saveAndReturn')}
            </ReduxButton>
          </ButtonToolbar>
        </Col>
      </Row>
    </form>
  );
};

CreatePlantForm.propTypes = {
  saved: PropTypes.bool.isRequired,
  doPrint: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  strains: PropTypes.array.isRequired,
  phenotypes: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  schedules: PropTypes.array.isRequired,
  batch_name: PropTypes.string,
  motherPlants: PropTypes.array,
  matriarchPlant: PropTypes.string,
  metrcTags: PropTypes.array,
  integrationState: PropTypes.object.isRequired,
  sourceItems: PropTypes.array.isRequired,
  plantTypes: PropTypes.array.isRequired,
  complianceSettings: PropTypes.object.isRequired,
  sourceItemIdIsDisabled: PropTypes.bool,
  startingMotherPlantIdIsDisabled: PropTypes.bool,
  disableSourceItemId: PropTypes.func,
  disableStartingMotherPlantId: PropTypes.func,
  batches: PropTypes.array,
  formValues: PropTypes.object,
  typeOfCannabis: PropTypes.string,
  form: PropTypes.string,
  addPlantsToExistingBatchFeatureEnabled: PropTypes.bool
};

const CreatePlantFormWrapper = reduxForm({
  validate
})(CreatePlantForm);

export default CreatePlantFormWrapper;
