import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { Row, Col } from 'react-bootstrap';
import {FaTimes} from 'react-icons/fa';
import get from 'lodash.get';
import AddItem from '../../common/form/AddItem';
import PricingMultiplierPrice from './PricingMultiplierPrice';

const PricingMultiplierPriceFieldArray = ({fields, fieldPrefix, formValues, pricingMultiplierId, taxRate, change}) => {

  const minFields = 2;

  // Always show at least one price field (besides the '0' index field)
  // - If we don't have a pricingMultiplierId we are creating a new one. Minimum number of fields can be added.
  // - If we do have a pricingMultiplierId we are modifying. Weight until the initial values are loaded in the formValues before adding minimum number of fields.
  if (!pricingMultiplierId || get(formValues, 'id')) {
    for (let i = fields.length; i < minFields; i++) {
      fields.push({});
    }
  }

  return (
    <div>
      <h4 style={{textAlign: 'center'}}>{I18n.t('retail.pricingMultiplier.form.basePrice')}</h4>
      <Row>
        <Col md={12}>
          {fields.map((name, index) => {
            if (index === 0) {
              return null;  // Don't render the '0' index field. It is rendered by the PricingMultiplierSingleUnitPrice component instead
            }
            const fieldName = `${fieldPrefix}.${index}`;
            return (
              <div key={index}>
                <Field
                  name={`${fieldName}.tier`}
                  component={PricingMultiplierPrice}
                  props={{
                    showHeader: index === 0,
                    name: `${fieldPrefix}.${index}.price`,
                    quantity: index + 1,
                    change,
                    taxRate,
                    disabled: false,
                    removeButton: index >= minFields && index === fields.length - 1 ? <FaTimes className='go-back-x' onClick={() => { fields.remove(index); }} /> : null
                  }}
                />
              </div>
            );
          })}
        </Col>
      </Row>
      <Row>
        {(fields.length < 23) &&
          <AddItem
            textMode='left'
            alignMode='right'
            addAction={() => fields.push({})}
            text={'common.actions.add'}
          />
        }
      </Row>
    </div>
  );
};

PricingMultiplierPriceFieldArray.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    get: PropTypes.func.isRequired,
  }).isRequired,
  fieldPrefix: PropTypes.string.isRequired,
  taxRate: PropTypes.number,
  change: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  pricingMultiplierId: PropTypes.string,
};

export default PricingMultiplierPriceFieldArray;
