import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
//import {I18n} from 'react-redux-i18n';

const RowFormatEditor = (props) => {

  const dataIsValid = () => {
    return true;
  };

  const isDisabled = (field) => {

    if(field === 'rtop'){
      if(props.modalData.atop === undefined) return false;
      if(typeof props.modalData.rtop === 'string') {
        if (props.modalData.atop.trim() === '') return false;
      }
      return true;
    }

    if(field === 'atop'){
      if(props.modalData.rtop === undefined) return false;
      if(typeof props.modalData.rtop === 'string') {
        if (props.modalData.rtop.trim() === '') return false;
      }
      return true;
    }

    if(field === 'width'){
      if(props.modalData.barcode === undefined) return true;
      if(props.modalData.barcode === 'barcode') return true;
      return false;
    }

  };

  const groups = {
    presentation: ['align', 'bold', 'fontSize', 'casing', 'dateDisplay', 'convertToYesNo'],
    behavior: ['display', 'hideLabel', 'replaceLabel', 'replaceValue'],
    position: ['left', 'rtop', 'atop'],
    barcode: ['barcode', 'barcodeHeight', 'c128WidthScale', 'width', 'anchorBottom', 'noCodeProtect']
  };

  const groupNames = {
    presentation: 'Presentation Settings',
    behavior: 'Behavior Settings',
    position: 'Positioning Settings',
    barcode: 'Barcode Settings'
  };

  const usageNotes = {
    fontSize: 'Leave blank to inherit the labels default font size.',
    left: 'Measured from the left edge plus margin.  Enter in inches.  Leave blank or 0 to not use.',
    atop: 'Measured from the top edge of the label always.  Enter in inches.  Leave blank or 0 to not use.',
    rtop: 'Measured from the previous row of data on the label.  Enter in inches.  Leave blank or 0 to not use.',
    anchorBottom: 'Only used when bar code or qr code is in use.  If on, Relative and Absolute top are ignored.',
    nowrap: 'Use on rows when you know there is the chance it will overflow with unnecessary data.',
    display: 'If Present will show the field if there is a value and hide if there is not.  Always is the default.',
    noCodeProtect: 'By default we attempt to keep text from running into codes.  Can result in loss of text and make tight positioning difficult.  This option disables code protection.',
    width: 'Set in inches or fractions of inches.  Only available to QR Codes.  For QR Codes, use Left Offset in combination with Width to center.  Center alignment does not currently apply to QR Codes.',
    convertToYesNo: 'Converts 1 to Yes and 0 to No.  Has no effect on any other values.',
    c128WidthScale: 'The C128 Bar Code is a variable length code.  Keep in mind that longer data means longer bar codes so do not try to size this to the width of a label',
    barcode: 'Default = off.  Bar Code is a pure zpl barcode.  It is not scalable but will work on most zpl printers.  QR Codes are rectangular and require a 2D scanner.  S C128 is a scalable barcode similar in data storage to the pure zpl bar code and allows scaling.',
    barcodeHeight: 'Enter in inches (or more likely decimal fractions of an inch).  Defaults to 45 which is just shy of a quarter inch at 203 dpi.  Leave blank or 0 to not use.  NOTE: Setting this to a value larger than your available label height will cause it to disappear.',
    hideLabel: 'Turns of the static text for the row.  For example, if the row were Product: PRODUCT NAME and this was enabled, "Product:" would be hidden.',
    replaceLabel: 'Replaces the static text for the row with this text.  For example, if you want to rename Product to Pdct use this setting.',
    replaceValue: 'Use this to inject static text.  All fields require a valid placeholder (from data keys) but the value can be replaced with this setting.',
  };

  const panels = [];

  for(const prop in groups){
    const panel = {
      name: prop,
      formatters: []
    };
    groups[prop].forEach((formatKey) => {
      panel.formatters.push({
        type: formatKey,
        text: props.formatSettings[formatKey],
        values: props.formatValues[formatKey].values
      });
    });
    panels.push(panel);
  }

  const getButtonClass = (field, matchValue, className) => {
    if(className === undefined) className = '';
    if(props.modalData[field] === matchValue) return `btn btn-success ${className}`;
    return `btn btn-default ${className}`;
  };

  const getSelect = (options, field) => { //eslint-disable-line react/no-multi-comp
    return (
      <div className='btn-group float-right btn-group-sm' style={{width:'60%',position:'relative',top:'-3px'}}>
        <button
          type='button'
          style={{width:'25%'}}
          onClick={event => {
            props.onChangeModalData(field, undefined);
            event.target.blur();
          }}
          className={getButtonClass(field, undefined)}>
          Default
        </button>
        {
          options.map((option, index) => {
            return (
              <button
                key={index}
                type='button'
                style={{width:'25%'}}
                onClick={event => {
                  props.onChangeModalData(field, option.value);
                  event.target.blur();
                }}
                className={getButtonClass(field, option.value)}>
                {option.text}
              </button>
            );
          })
        }
      </div>
    );
  };

  const displaySetting = (formatter) => {
    const data = props.modalData;
    if(formatter.type === 'c128WidthScale'){
      if(data.barcode === undefined) return false;
      if(data.barcode !== 'c128') return false;
    }
    if(formatter.type === 'barcodeHeight'){
      if(data.barcode === undefined) return false;
      if(data.barcode !== 'c128') return false;
    }
    if(formatter.type === 'width'){
      if(data.barcode === undefined) return false;
      if(data.barcode !== 'qrcode') return false;
    }
    return true;
  };

  const getOnOff = (field) => { //eslint-disable-line react/no-multi-comp
    return (
      <div className='btn-group float-right btn-group-sm' style={{width:'60%',position:'relative',top:'-3px'}}>
        <button
          type='button'
          style={{width:'25%'}}
          onClick={event => {
            props.onChangeModalData(field, 1);
            event.target.blur();
          }}
          className={getButtonClass(field, 1, 'float-right')}>
          On
        </button>
        <button
          type='button'
          style={{width:'25%'}}
          onClick={event => {
            props.onChangeModalData(field, undefined);
            event.target.blur();
          }}
          className={getButtonClass(field, undefined, 'float-right')}>
          Off
        </button>
      </div>
    );
  };

  const fieldsUsingIsDisabled = ['atop', 'rtop', 'width'];

  return (
    <div className='row' style={{marginTop: '12px'}}>
      <div className='col-md-12'>

        {
          panels.map((panel, index) => {
            return (
              <div key={index} className='col-md-6' style={index % 2 === 0 ? {clear:'both'} : {}}>
                <Card>
                  <Card.Header>{groupNames[panel.name]}</Card.Header>
                  <Card.Body>
                    <ul className='list-group'>
                    {
                      panel.formatters.map((formatter, index) => {
                        const disabled = (fieldsUsingIsDisabled.indexOf(formatter.type) !== -1) ? isDisabled(formatter.type) : false;
                        const value = props.modalData[formatter.type];
                        const usageNote = usageNotes[formatter.type] !== undefined ? usageNotes[formatter.type] : false;
                        const itemClass = displaySetting(formatter) ? 'list-group-item' : 'hide';
                        return (
                          <li className={itemClass} key={index}>
                            <label style={{position:'relative', top: '4px'}}>{formatter.text}</label>
                            {
                              Array.isArray(formatter.values)
                              ? getSelect(formatter.values, formatter.type)
                                : formatter.values === 1
                                ? getOnOff(formatter.type)
                              : formatter.values === 'string'
                                  ? (<input
                                      type='text'
                                      value={value}
                                      onChange={event => {
                                        props.onChangeModalData(formatter.type, event.target.value);
                                      }}
                                      className='form-control float-right'
                                      style={{width: '150px',position:'relative',top:'-5px'}}
                                  />)
                                  : (<input
                                      onChange={event => {
                                        props.onChangeModalData(formatter.type, event.target.value);
                                      }}
                                      value={value}
                                      type='number'
                                      disabled={disabled}
                                      className='form-control float-right'
                                      style={{width: '150px',position:'relative',top:'-5px'}}
                                    />)
                            }
                            {
                              !usageNote ? null : <div style={{clear:'both', width: '100%'}} className='text-muted'>{usageNote}</div>
                            }
                          </li>
                        );
                      })
                    }
                    </ul>
                  </Card.Body>
                </Card>
              </div>
            );
          })
        }
      </div>
      <div className='col-md-12'>
        <div className='col-md-12'>
        <hr />
        <button
          onClick={(event) => {
            props.onHide();
          }}
          className='btn btn-danger'
        >
          Cancel
        </button>
        <button
          onClick={(event) => {
            props.onSaveModalData();
          }}
          className='btn btn-primary float-right'
          disabled={!dataIsValid()}
        >
          Save
        </button>
        </div>
      </div>
    </div>
  );
};

RowFormatEditor.propTypes = {
  definitions: PropTypes.array.isRequired,
  currentDefinitionIndex: PropTypes.number.isRequired,
  formatSettings: PropTypes.object.isRequired,
  formatValues: PropTypes.object.isRequired,
  modalData: PropTypes.object.isRequired,
  onChangeModalData: PropTypes.func.isRequired,
  onSaveModalData: PropTypes.func.isRequired,
};

export default RowFormatEditor;
