import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {push} from 'react-router-redux';
import {Tabs, Tab} from 'react-bootstrap';
import PageTitle from '../common/PageTitle';
import getSalesReportTabs from './sales-reports/common/tabDefinitions';
import * as dataNames from '../../constants/dataNames';
import {getUnpaginatedData} from '../../actions/apiActions';
import {unsetData} from '../../actions/dataActions';
import {getSalesReportPatientData, getSalesReportData} from '../../selectors/salesReportSelectors';
import {getEnabledTabs} from '../../actions/reportActions';

export class SalesReportPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      tabs: [],
    };
    this.switchTab = this.switchTab.bind(this);
  }

  switchTab(activeTab) {
    const tab = this.state.tabs.find(tab => tab.eventKey === activeTab) || this.state.tabs[0];
    this.setState({activeTab});
    this.props.actions.push(tab.path);
  }

  componentWillMount() {
    this.props.actions.getUnpaginatedData('/api/consumers/sales_report_patient_data', dataNames.salesReportPatientData, {
      failed: 'retail.salesReport.failed' }
    );
    this.props.actions.getUnpaginatedData('/api/orders/sales_report_data', dataNames.salesReportSalesData, {
      failed: 'retail.salesReport.failed'
    });
    this.setState({
      tabs: this.props.actions.getEnabledTabs(getSalesReportTabs)
    });
  }

  render() {
    const { salesReportPatientData, salesReportSalesData } = this.props;

    return (
      <div>
        <PageTitle primaryText={I18n.t('retail.salesReport.salesReportTitle')} />

        <Tabs id='filterTabs' activeKey={this.state.activeTab} onSelect={this.switchTab}>
          {this.state.tabs.map((tab, index) => (
            <Tab key={index} eventKey={tab.eventKey} title={I18n.t(tab.title)} />
          ))}
        </Tabs>

        <div>
          <PageTitle primaryText={I18n.t('retail.salesReport.summary')} />
          <label>{I18n.t('retail.salesReport.todaysDate')} </label> {salesReportSalesData.label_today}
          <table className='report-table'>
            <tbody>
              <tr>
                <td>
                  <label>{I18n.t('retail.salesReport.totalSales')}</label>
                </td>
                <td>${salesReportSalesData.total_sales_today}</td>
              </tr>
              <tr>
                <td>
                  <label>{I18n.t('retail.salesReport.totalOrders')}</label>
                </td>
                <td>{salesReportSalesData.total_orders_today}</td>
              </tr>
              <tr>
                <td>
                  <label>{I18n.t('retail.salesReport.patientsSeenToday')}</label>
                </td>
                <td>{salesReportSalesData.patients_seen_today}</td>
              </tr>
              <tr>
                <td>
                  <label>{I18n.t('retail.salesReport.newPatients')}</label>
                </td>
                <td>{salesReportPatientData.new_patients_today}</td>
              </tr>
              <tr>
                <td>
                  <label>{I18n.t('retail.salesReport.totalActivePatients')}</label>
                </td>
                <td>{salesReportPatientData.total_active_patients}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <PageTitle primaryText={I18n.t('retail.salesReport.salesRevenue')} />

          <table className='report-table'>
            <tbody>
              <tr>
                <td>
                  <label>{I18n.t('retail.salesReport.totalSalesToday')}</label><br/>
                  {salesReportSalesData.label_today}
                </td>
                <td>${salesReportSalesData.total_sales_today}</td>
              </tr>
              <tr>
                <td>
                  <label>{I18n.t('retail.salesReport.totalSalesThisWeek')}</label><br/>
                  {salesReportSalesData.label_week}
                </td>
                <td>${salesReportSalesData.total_sales_week}</td>
              </tr>
              <tr>
                <td>
                  <label>{I18n.t('retail.salesReport.totalSalesThisMonth')}</label><br/>
                  {salesReportSalesData.label_month}
                </td>
                <td>${salesReportSalesData.total_sales_month}</td>
              </tr>
              <tr>
                <td>
                  <label>{I18n.t('retail.salesReport.totalSalesForTheLastNinetyDays')}</label>
                </td>
                <td>${salesReportSalesData.last_ninety_days}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <PageTitle primaryText={I18n.t('retail.salesReport.topSellers')} />
          <table className='report-table'>
            <tbody>
              <tr>
                <th>
                  <label>{I18n.t('retail.salesReport.topSeller')}</label>
                </th>
                <th>
                  <label style={{ paddingLeft:10 }}>{I18n.t('retail.salesReport.totalSales')}</label>
                </th>
              </tr>
              {salesReportSalesData.top_sellers.map((data, index) => (
                <tr key={index}>
                  <td>{data.name}</td>
                  <td>${data.total_sales}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

SalesReportPage.propTypes = {
  actions: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  featureIsEnabled: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  const actions = { getUnpaginatedData, push, unsetData, getEnabledTabs };
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export function mapStateToProps(state, ownProps) {
  return {
    categories: state[dataNames.categories],
    salesReportPatientData: getSalesReportPatientData(state),
    salesReportSalesData: getSalesReportData(state),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesReportPage);
