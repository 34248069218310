import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Modal, Button} from 'react-bootstrap';

const ModalWrapper = (props) => {
  const {Component, title, children, dialogClassName, noTranslationTitle, headerStyle, ...restProps} = props;
  const {showModal, onHide} = restProps;
  const useOriginal = true;
  if(useOriginal && (!props.version || props.version !== 2)) {
    return (
      <Modal show={showModal} onHide={onHide} dialogClassName={dialogClassName}>
        <Modal.Header closeButton>
          {
            title && (<Modal.Title>
              {I18n.t(title)} {noTranslationTitle ? noTranslationTitle : ''}
            </Modal.Title>)
          }
        </Modal.Header>
        <Component {...restProps}/>
        {children}
      </Modal>
    );
  }

  /***
   * Version 2 - Removes component dependency, adds configurable buttons, see propTypes for documentation
   * As of 3/17/2018 used only on CancelOrder component which is only displayed for Canadian facilities.
   */
  const {headerClass, widthClass, borderClass, size, cancelButton, okayButton, translate,
    keyboard, backdrop, subTitle, component, offset, version} = props;

  const onV2Hide = () => {
    props.onHide({buttonClicked: 'cancel'});
  };

  const onExit = () => {
    if(typeof props.onExit === 'function') props.onExit('noop');
  };

  const onButtonClick = (buttonClicked, e) => {
    e = {event: e, buttonClicked};
    const button = props[`${buttonClicked}Button`];
    if(button && typeof button.onClick === 'function') {
      if(button.onClickHasPromise) {
        button.onClick(e).then(() => {
          props.onHide(e);
        });
        return true;
      }
      button.onClick(e);
      props.onHide(e);
      return true;
    }
    props.onHide(e);
  };

  const buttonTexts = {
    cancel: 'general.cancel',
    okay: 'general.okay'
  };

  /***
   * Gets title, subTitle, and button texts.  In case of button texts provides defaults if text is undefined
   * Translates if translate is undefined or true, or if object if the specified type is undefined or true
   * Translates always if the provided text is an object.
   * @param text
   * @param type
   * @returns {string}
   */
  const getText = (text, type) => {
    text = (text) ? text : buttonTexts[type] ? buttonTexts[type] : '';
    if(typeof text === 'string'){
      if(typeof translate === typeof true && !translate) return text;
      if(translate && typeof translate[type] === typeof true && !translate[type]) return text; // type set false
      return I18n.t(text); // if translate = undefined, true, or translate = object and translate[type] = undefined || translate[type] = true
    }
    return I18n.t(text.text, text.data); // Text is an object so it must be translated
  };

  /***
   * Build class name for a specific offset
   * @param number
   * @param type
   * @returns {string}
   */
  const getOffsetClassName = (number, type) => {
    const name = [type, 'offset'];
    if(type === 'x' && Math.abs(number) > 3) number = (number > 0) ? 3 : -3;
    if(type === 'y' && number < 0) number = 0;
    if(type === 'y' && number > 6) number = 6;
    if(number < 0) name.push('neg');
    name.push(Math.abs(number));
    return name.join('-');
  };

  const getOffsetClasses = () => {
    const classes = [];
    if(!offset) return classes;
    const offsets = Object.assign({}, (typeof offset === 'object') ? offset : {y: offset, x: offset});
    classes.push(getOffsetClassName(parseInt(offsets.y), 'y'));
    classes.push(getOffsetClassName(parseInt(offsets.x), 'x'));
    return classes;
  };

  const getModalWidthClass = () => {
    const classes = [];
    if(widthClass) classes.push(widthClass);
    if(dialogClassName) classes.push(dialogClassName); // Legacy support
    return classes;
  };

  const getRoundedBorderClass = () => {
    const classes = [];
    if(!borderClass) return ['rounded-border-4'];
    if(borderClass) classes.push(borderClass);
    return classes;
  };

  /***
   * Gets all classes for dialogClassName prop
   */
  const getDialogClasses = () => {
    return [].concat(getOffsetClasses())
      .concat(getRoundedBorderClass())
      .concat(getModalWidthClass())
      .join(' ');
  };

  const conditionalProps = {};
  const useHeaderStyle = headerStyle ? headerStyle : {};
  if (size && !widthClass) {
    conditionalProps.size = size;
  }

  return (
    <Modal
      className='mjp-modal-version-2'
      backdrop={backdrop ? backdrop : true}
      show={showModal}
      onHide={onV2Hide}
      onExit={onExit}
      dialogClassName={getDialogClasses()}
      keyboard={keyboard ? keyboard : true}
      {...conditionalProps}
    >
      <Modal.Header
        className={headerClass ? headerClass : (version) ? 'bg-light-gray' : 'bg-white'}
        closeButton
      >
        <Modal.Title style={useHeaderStyle}>
          {getText(title)}
          {
            !subTitle
              ? null
              : (<div style={{fontSize:'smaller', opacity: '0.7'}}>{getText(subTitle, 'subTitle')}</div>)
          }

        </Modal.Title>
      </Modal.Header>
      <div className='modal-body'>
        {
          Component || component
            ? Component ? <Component {...restProps} closeModal={onV2Hide} /> : <component {...restProps} closeModal={onV2Hide} />
            : null
        }
        {children}
      </div>
      <div style={cancelButton || okayButton ? {padding: '0 15px 15px 15px'} : {padding: '0 15px 15px 15px', display: 'none'}}>
        <hr style={{marginTop: '0'}} />
        <div>
          {
            ['cancel', 'okay'].map((buttonType, index) => {
              const button = props[`${buttonType}Button`];
              const pullClass = (buttonType === 'cancel') ? 'float-left' : 'float-right';
              const typeClass = (buttonType === 'cancel') ? 'warning' : 'primary';
              const conditionalProps = {};
              if(button && button.size) conditionalProps.size = button.size;
              return (<Button
                key={index}
                style={button && button.show
                  ? {marginRight: '6px'}
                  : {display: 'none', marginRight: '6px'}
                }
                className={
                  button && button.show && `${button.className} ${pullClass}`
                    ? `${button.className} ${pullClass}`
                    : ''
                }
                variant={button ? button.variant ? `${button.variant}` : `${typeClass}` : `${typeClass}`}
                disabled={button ? button.disabled ? button.disabled : false : false}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  e.target.blur();
                  onButtonClick(buttonType, e);
                }}
                {...conditionalProps}
              >
                {getText(button ? button.text : undefined, buttonType)}
              </Button>);
            })
          }
          <div style={{clear: 'both'}} />
        </div>
      </div>
    </Modal>
  );
};

ModalWrapper.propTypes = {
  Component: PropTypes.oneOfType([
    PropTypes.node.isRequired,
    PropTypes.func.isRequired
  ]),
  component: PropTypes.oneOfType([ // Lowercase version, uppercase one always trips me up
    PropTypes.node.isRequired,
    PropTypes.func.isRequired
  ]),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  noTranslationTitle: PropTypes.string,
  onHide: PropTypes.func,
  dialogClassName: PropTypes.string,
  showModal: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node
  ]),

  // Version 2 props plus Component/component is optional
  version: PropTypes.number,      // 2 returns version 2, anything else returns version 1
  offset: PropTypes.oneOfType([   // Purpose is to offset sub modals by multiples of 25px
    PropTypes.number,             // [-3,-2,-1,0,1,2,3] - negative are left offsets and top, positives are right and bottom - impacts x and y, zero included for object notation below
    PropTypes.object,             // {x: n, y: n} - y = vertical, x = horizontal same range as above
  ]),
  subTitle: PropTypes.oneOfType([
    PropTypes.string,             // DEFAULT: empty
    PropTypes.object,             // {text: TEXT, data: OBJECT} // only used if translate is on
  ]),
  backdrop: PropTypes.oneOfType([ // DEFAULT: true (background and click dismisses
    PropTypes.bool,               // true = grayed background click dismisses, false = no background no click dismissal
    PropTypes.string,             // 'static' = grayed background without click dismissal
  ]),
  translate: PropTypes.oneOfType([
    PropTypes.bool,               // applies to title and button text DEFAULT true
    PropTypes.object,             // {buttons: BOOL, title: BOOL}
  ]),
  headerClass: PropTypes.string,  // bootstrap classes (eg. bg-success) or bg-TYPE-dark (eg. bg-danger-dark) or custom classes.  Dark versions use the bootstrap text-TYPE color for consistency. DEFAULT: bg-light-gray
  headerStyle: PropTypes.object,  // Style object to be applied to header
  borderClass: PropTypes.string,  // one of rounded-border-15, rounded-border-8, rounded-border-4 DEFAULT rounded-border-4
  widthClass: PropTypes.string,   // css class(es) controlling width, top offset, etc.  DEFAULT: none (modal is about 80% of available width; same as v1)
  size: PropTypes.string,         // [small|large] - bsSize implementation - if size and widthClass are present, widthClass wins DEFAULT: none
  cancelButton: PropTypes.object, // {show: boolean, variant: string, style: object, className: string, text: string, onClick: func, onClickHasPromise: boolean} DEFAULT: don't show, but if shown without text, text = Cancel
  okayButton: PropTypes.object,   // same as cancel DEFAULT don't show, but if shown without text, text = Okay
  keyboard: PropTypes.bool,       // Esc key dismisses modal DEFAULT: false

};

export default ModalWrapper;
