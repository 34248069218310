import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import * as UOMS from '../../../constants/uoms';
import { postItem } from '../../../actions/apiActions';
import { unsetItem } from '../../../actions/itemActions';
import { addMessage } from '../../../actions/systemActions';
import { integrationTrackingStatusModal } from '../../../constants/itemNames';

export const DestroyPlant = (props) => {

  const onDestroy = () => {
    const url = '/api/destructions';
    const eventDate = new Date().toISOString();
    const payload = {
      biotrack_reason: 2,
      destroyed_at: eventDate,
      destroyed_weight: 1, // is min
      event_date: eventDate,
      notes: 'Unhealthy',
      plant_ids: [props.entityLock.entity_id],
      plants_destroyed: [null],
      plants_qty: [1],
      uom: UOMS.GR,
    };
    props.actions.postItem(url, payload);
    props.actions.addMessage('success', 'Destroyed Plant', false);
    props.actions.unsetItem(integrationTrackingStatusModal);
  };

  return (<div>
    <p>
      {I18n.t('entityLocks.components.destroyPlant.text')}
    </p>
    <Button onClick={(e) => onDestroy()}>
      {I18n.t('entityLocks.components.destroyPlant.buttonText')}: {props.entityLock.entity_id}
    </Button>
  </div>);
};

DestroyPlant.propTypes = {
  entityLock: PropTypes.object,
  actions: PropTypes.object,
};

function mapDispatchToProps(dispatch){
  const actions = {postItem, unsetItem, addMessage};
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(DestroyPlant);
