import {change, formValueSelector} from 'redux-form';
import {HARVEST_BATCH_SPLIT_FORM} from '../../constants/forms';
import {isChangeActionOnField, isChangeAction} from './utils';

const harvestBatchSplitForm = store => next => action => {
  const result = next(action);
  /***
   * Recalculate newWeight as current_weight - sum of splits weight.  Do not recalc on change of newWeight itself.
   */
  if(isChangeAction(action, [HARVEST_BATCH_SPLIT_FORM]) && !isChangeActionOnField(action, [HARVEST_BATCH_SPLIT_FORM], 'newWeight')){
    const state = store.getState();
    const selector = formValueSelector(HARVEST_BATCH_SPLIT_FORM);
    const currentWeight = selector(state, 'current_weight');
    const splits = selector(state, 'splits');
    const usedWeight = splits.reduce((acc, split) => {
      if(!isNaN(parseFloat(split.weight))) acc += parseFloat(split.weight);
      return acc;
    }, 0);
    store.dispatch(change(HARVEST_BATCH_SPLIT_FORM, 'newWeight', (currentWeight - usedWeight).toFixed(2)));
  }

  return result;
};

export default harvestBatchSplitForm;
