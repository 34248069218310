import React from 'react';

import SubmitSection from '../common/SubmitSection';
import BaseFormFields from '../common/BaseFormFields';


const MxMerchant = () => {
  return (
    <div>
      <BaseFormFields integrator='mxmerchant' />
      <SubmitSection />
    </div>
  );
};


export default MxMerchant;
