import React from 'react';
import PropTypes from 'prop-types';
import {FieldArray} from 'redux-form';
import {Col, Row} from 'react-bootstrap';
import LineItem from './line-items/LineItem';
import RequestedPrePacks from './line-items/RequestedPrePacks';
import PrePackPackages from './line-items/PrePackPackages';
import {UOM_VALUE_PRECISION} from '../../../constants/uoms';

const LineItems = ({fields, lines, sentTotals, lineTotals, packages, locations, change, formData,
  prepackWeights, childItemMasters, isApplyStorageLocation, isEditMode, prepackExpendedInventory, expendedInventory,
  isComplete, integrationState, isCancelled, transferId, disposalReasonOptions, formName, isMedicated, sentNetTotals, internationalNumberFormat}) => {

  const {isLeaf, isBiotrack} = integrationState;
  //Lock adding/removing inventory on modify for LEAF, also lock cancelled/complete transfers
  const inventoryLocked = Boolean(((isLeaf || isBiotrack) && transferId && isMedicated) || isCancelled || isComplete);

  return (<div>
    {
      lines.length > 0
      ? fields.map((fieldName, index) => {
        const sentTotal = sentTotals && sentTotals.length && sentTotals[index] ? parseFloat(sentTotals[index].toFixed(UOM_VALUE_PRECISION)) : 0;
        const sentNetTotal = sentNetTotals && sentNetTotals.length && sentNetTotals[index] ? parseFloat(sentNetTotals[index].toFixed(2)) : 0;
        const lineTotal = lineTotals && lineTotals.length && lineTotals[index] ? lineTotals[index] : '0.00';
        const itemMaster = lines[index] && lines[index].itemMaster ? lines[index].itemMaster : {};
        const itemMasterIsMedicated = Boolean(lines[index] && lines[index].is_medicated);
        const linePackages = lines[index] && lines[index].item_master_id && packages[lines[index].item_master_id]
          ? packages[lines[index].item_master_id]
          : [];

        if(Array.isArray(expendedInventory)) {
          const expendedItems = expendedInventory.filter((item) => item.item_master_id === lines[index].item_master_id);
          if (expendedItems.length) {
            expendedItems.forEach((item) => {
              linePackages.push(item);
            });
          }
        }

        return (
        <div key={index}>
          <Row>
            <Col xs={12}>
              {lines ?
                <LineItem
                  formData={formData}
                  index={index}
                  fieldName={fieldName}
                  line={lines[index]}
                  itemMaster={itemMaster}
                  itemMasterIsMedicated={itemMasterIsMedicated}
                  sentTotal={sentTotal}
                  sentNetTotal={sentNetTotal}
                  lineTotal={lineTotal}
                  change={change}
                  fields={fields}
                  packages={linePackages}
                  prepackWeights={prepackWeights}
                  isApplyStorageLocation={isApplyStorageLocation}
                  isEditMode={isEditMode}
                  isMedicated={isMedicated}
                  isComplete={isComplete}
                  isCancelled={isCancelled}
                  integrationState={integrationState}
                  transferId={transferId}
                  disposalReasonOptions={disposalReasonOptions}
                  locations={locations}
                  inventoryLocked={inventoryLocked}
                  formName={formName}
                  internationalNumberFormat={internationalNumberFormat}
                />
                : null
              }
            </Col>
          </Row>
          {childItemMasters[lines[index].item_master_id] && lines[index].line_type === 'prepack' ?
            <Row>
              <Col md={4}>
                <RequestedPrePacks
                  prepackWeights={prepackWeights}
                  childItemMasters={childItemMasters[lines[index].item_master_id]}
                  line={lines[index]}
                  fieldName={fieldName}
                  editableLinePrice={fields.get(index).editableLinePrice}
                />
              </Col>
              <Col md={8}>
                <FieldArray
                  name={`${fieldName}.inventory`}
                  component={PrePackPackages}
                  isApplyStorageLocation={isApplyStorageLocation}
                  locations={locations}
                  packages={packages}
                  fieldName={fieldName}
                  change={change}
                  handlePricingChange={() => {}}
                  childItemMasters={childItemMasters[lines[index].item_master_id]}
                  initialItemMasters={formData.prepackItemMasterChildren[index]}
                  itemMaster={itemMaster}
                  isEditMode={isEditMode}
                  line={lines[index]}
                  isComplete={isComplete}
                  unitPrice={typeof fields.get === 'function' ? parseFloat(fields.get(index).unit_price) : 0}
                  editableLinePrice={fields.get(index).editableLinePrice}
                  prepackExpendedInventory={prepackExpendedInventory}
                  integrationState={integrationState}
                  transferId={transferId}
                  inventoryLocked={inventoryLocked}
                  formName={formName}
                  itemMasterIsMedicated={itemMasterIsMedicated}
                  isMedicated={isMedicated}
                  formData={formData}
                />
              </Col>
            </Row> : null
          }
        <Col xs={12}><hr/></Col>
      </div>);}) : null
    }
  </div>);
};

LineItems.propTypes = {
  isApplyStorageLocation: PropTypes.bool,
  childItemMasters: PropTypes.object.isRequired,
  prepackWeights: PropTypes.array.isRequired,
  sentTotals: PropTypes.array.isRequired,
  lineTotals: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  lines: PropTypes.array.isRequired,
  packages: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool,
  prepackExpendedInventory: PropTypes.array.isRequired,
  expendedInventory: PropTypes.array.isRequired,
  isComplete: PropTypes.bool.isRequired,
  isCancelled: PropTypes.bool,
  isMedicated: PropTypes.bool,
  integrationState: PropTypes.object.isRequired,
  transferId: PropTypes.string,
  disposalReasonOptions: PropTypes.array.isRequired,
  formName: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  sentNetTotals: PropTypes.array,
  internationalNumberFormat: PropTypes.string
};

LineItems.defaultProps = {
  isEditMode: true
};

export default LineItems;
