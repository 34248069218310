import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Button } from 'react-bootstrap';
import ActionBarButton from './ActionBarButton';
import TableControls from './TableControls';
import PermissionWrapper from '../PermissionWrapper';

const evaluatePredicate = (predicate) => (typeof predicate === 'function' ? predicate() : predicate);

const ActionBar = ({
  actions,
  rowIsSelected,
  showSelectedCount,
  selectedCount,
  setVisibleColumns,
  columns,
  exportCSV,
  hideExport,
  setPageSize,
  pageSizeList,
  pageSize,
  toggleScanSearch,
  toggleScanIdSearch,
  toggleHistory,
  scanSearchEnabled,
  historyEnabled,
  hideTableControls,
  hidePagination
}) => {
  return (
    <div className='button-bar'>
      {showSelectedCount && (
        <ButtonGroup>
          <Button disabled={true} variant='primary'>
            {selectedCount || 0}
          </Button>
          &nbsp;
        </ButtonGroup>
      )}
      <ButtonGroup>
        {actions
          .filter((a) => !evaluatePredicate(a.hidden))
          .map((action, index) => (
            <PermissionWrapper key={index} permissions={action.permissions || []}>
              <ActionBarButton
                key={index}
                hide={action.hide}
                requireSelect={action.requireSelect}
                rowIsSelected={rowIsSelected}
                disabled={evaluatePredicate(action.disabled)}
                variant={action.variant}
                glyph={action.glyph}
                text={action.text}
                func={action.func}
                path={action.path}
                permissions={action.permissions}
              />
            </PermissionWrapper>
          ))}
      </ButtonGroup>
      {hideTableControls ? (
        ''
      ) : (
        <TableControls
          columns={columns}
          setVisibleColumns={setVisibleColumns}
          exportCSV={exportCSV}
          hideExport={hideExport}
          setPageSize={setPageSize}
          pageSizeList={pageSizeList}
          pageSize={pageSize}
          toggleScanSearch={toggleScanSearch}
          toggleScanIdSearch={toggleScanIdSearch}
          toggleHistory={toggleHistory}
          scanSearchEnabled={scanSearchEnabled}
          historyEnabled={historyEnabled}
          hidePagination={hidePagination}
        />
      )}
    </div>
  );
};

ActionBar.propTypes = {
  actions: PropTypes.array.isRequired,
  toggleScanSearch: PropTypes.func,
  toggleScanIdSearch: PropTypes.func,
  toggleHistory: PropTypes.func,
  scanSearchEnabled: PropTypes.bool,
  historyEnabled: PropTypes.bool,
  setVisibleColumns: PropTypes.func.isRequired,
  exportCSV: PropTypes.func,
  hideExport: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  rowIsSelected: PropTypes.bool.isRequired,
  setPageSize: PropTypes.func.isRequired,
  pageSizeList: PropTypes.array.isRequired,
  pageSize: PropTypes.number.isRequired,
  showSelectedCount: PropTypes.bool,
  selectedCount: PropTypes.number,
  hideTableControls: PropTypes.bool,
  hidePagination: PropTypes.bool
};

export default ActionBar;
