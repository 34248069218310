import {I18n} from 'react-redux-i18n';

//Order source options
export const source_in_store = 'in_store';
export const source_phone = 'phone';
export const source_online = 'online';

export const in_store = 'in_store';
export const delivery = 'delivery';
export const pickup = 'pickup';
export const payment = 'payment';
export const packaging = 'packaging';
export const curbside = 'curbside';

export const getOrderFulfillmentOptions = () => {

  const orderFulfillmentOptions = [
    {
      i18n: 'customer.title.inStore',
      value: in_store,
      fulfillment_method: in_store,
      order_sources: [source_in_store],
      set_order_source: source_in_store,
      is_submitted: 0
    },
    {
      i18n: 'customer.title.pickup',
      value: pickup,
      fulfillment_method: in_store,
      order_sources: [source_phone, source_online],
      set_order_source: source_phone,
      is_submitted: 1
    },
    {
      i18n: 'customer.title.delivery',
      value: delivery,
      fulfillment_method: delivery,
      order_sources: [source_phone, source_online],
      set_order_source: source_phone,
      is_submitted: 1
    },
    {
      i18n: 'customer.title.curbside',
      value: curbside,
      fulfillment_method: curbside,
      order_sources: [source_phone, source_online],
      set_order_source: source_phone,
      is_submitted: 1
    },
    {
      i18n: 'customer.title.delivery',
      value: packaging,
      not_fulfillment_method: true
    },
    {
      i18n: 'customer.title.delivery',
      value: payment,
      not_fulfillment_method: true
    },
  ];

  return orderFulfillmentOptions.map((option) => {
    option.text = I18n.t(option.i18n);
    return option;
  });
};
