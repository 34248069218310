import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Card} from 'react-bootstrap';
import ContentConcealer from '../../../common/concealers/ContentConcealer';

const PlantInactivatedDetails = ({model}) => {
  let reason;
  if (model.is_packaged) {
    reason = I18n.t('plants.inactivateReasons.packaged');
  } else if (model.is_harvested) {
    reason = I18n.t('plants.inactivateReasons.harvested');
  }

  return (
    <Card>
      <ContentConcealer show={reason}><span>{reason}</span></ContentConcealer>
    </Card>
  );
};

PlantInactivatedDetails.propTypes = {
  model: PropTypes.object.isRequired,
};

export default PlantInactivatedDetails;
