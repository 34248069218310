import {I18n} from 'react-redux-i18n';
import {requiredFieldValidation} from '../../common/form/redux-form/validations';

const validate = values => {
  const errors = {};
  errors.name = requiredFieldValidation(values.name, 'user.Username');
  errors.current_password = requiredFieldValidation(values.current_password, 'user.password.currentPassword');
  errors.new_password = requiredFieldValidation(values.new_password, 'user.password.newPassword');
  errors.confirm_new_password = requiredFieldValidation(values.confirm_new_password, 'user.password.confirmNewPassword');
  if (values.new_password && values.new_password.length < 8) {
    errors.new_password = I18n.t('user.password.passwordTooShort');
  }
  else if (values.new_password && values.confirm_new_password  && values.new_password !== values.confirm_new_password) {
    errors.confirm_new_password = I18n.t('user.password.matchInvalid');
  }
  return errors;
};


export default validate;
