import {requiredFieldValidation} from '../../common/form/redux-form/validations';

export default function validate(values) {
  const items = (values.items || []).map(item => {
    const reason = typeof item.integration_destroy_reason_extended === 'number' ?
      item.integration_destroy_reason_extended : values.integration_destroy_reason_extended;
    return {
      integration_destroy_reason_extended: requiredFieldValidation(typeof reason === 'number'),
      notes: requiredFieldValidation(reason !== 0 || item.notes || values.notes),
    };
  });

  return {
    items,
    _error: requiredFieldValidation(values && values.items && values.items.length),
  };
}
