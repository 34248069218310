import React from 'react';
import PropTypes from 'prop-types';
import {Button, Image} from 'react-bootstrap';
import {FaCheck, FaTimes} from 'react-icons/fa';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {getItemMasterType} from '../../selectors/itemMastersSelectors';
import InternationalQuantityByUomInput from '../common/form/InternationalQuantityByUomInput';
import ReactSelectInput from '../common/form/ReactSelectInput';
import InternationalCurrencyStatic from '../common/form/InternationalCurrencyStatic';
import InternationalDecimalStatic from '../common/form/InternationalDecimalStatic';

// weight base to ratio map for converting weight base to unit
const weightBaseToRatioMap = {
  MG: 1000,
  CG: 10000,
  GR: 1000000,
  G: 1000000,
  KG: 1000000000,
  OZ: 28349500,
  LB: 453592000,
  T: 907184000000
};

class ProductRow extends React.PureComponent {
  /**
   * Capitalize first letter because the name of item master
   * type will be the part of function name.
   * @returns {string}
   */
  getItemMasterType() {
    const value = getItemMasterType({
      itemMaster: this.props.itemMaster
    }) || '';

    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  renderUnitPrice({unit_price, sold_weight}) {
    let unitPrice = unit_price;
    if (
      (unit_price) &&
      (sold_weight) &&
      (this.getItemMasterType() !== 'unit')
    ) {
      unitPrice = (parseFloat(unit_price) / sold_weight).toFixed(2);
    }

    return (this.props.hasCoupons)
      ? <del>{unitPrice}</del>
      : unitPrice;
  }

  renderLineTotal({unit_price = 0, quantity = 0}) {
    const lineTotal = parseFloat(unit_price) && parseFloat(quantity)
      ? (unit_price * parseFloat(quantity)).toFixed(2)
      : '0.00';

    return (this.props.hasCoupons)
      ? <del>{lineTotal}</del>
      : lineTotal;
  }

  renderField() {
    const fieldName = (this.props.product.product_type === 'unit') ? `${this.props.fieldNamePrefix}.quantity` : `${this.props.fieldNamePrefix}.sold_weight`;
    const uom = this.props.product.product_type === 'unit' ? 'EA' : this.props.product.sold_weight_uom_display;
    return (
      <div className='pricing-weight-controls'>
        <InternationalQuantityByUomInput
          name={fieldName}
          uom={uom}
          {...this.getFieldProps()}
        />
      </div>
    );
  }


  getFieldProps() {
    return {
      disabled: this.props.disablingAddInventory,
      className: 'quantity-input',
      allowNegativeNumber: false,
    };
  }

  render() {
    const {product, totalQuantity, isFulfilled, handleRemoveProduct, isOhMetrc, order, itemMaster} = this.props;
    const partialFillOptions = Array.from({length: 100}, (v, k) => ({
      value: ('00' + k).substr(-2)
    }));
    const daysSupplyMax = 90;
    const daysSupplyOptions = [];

    for (let i = 1; i <= daysSupplyMax; i++) {
      daysSupplyOptions.push({
        value: i,
        text: `${i}`,
      });
    }

    const showExtraFieldsForOhio = Boolean(isOhMetrc && order && order.consumer_type === 'medical' && itemMaster && itemMaster.is_medicated);
    return (
      <tr className='product-row'>
        <td><Image className='cart-item-image' src={product.src}/></td>
        <td style={{verticalAlign: 'bottom'}}>
          <div>
            {product.name}
          </div>
          {showExtraFieldsForOhio &&
            <div className='cart-partial-fill-indicator'>
              <Field
                name={`${this.props.fieldNamePrefix}.partial_fill_indicator`}
                component={ReactSelectInput}
                props={{
                  label: I18n.t('cart.items.partialFillLabel'),
                  textKey: 'value',
                  options: partialFillOptions,
                  valueKey: 'value',
                  placeholder: I18n.t('cart.items.partialFillIndicator')
                }}/>
            </div>
          }
        </td>
        <td style={{verticalAlign: 'bottom'}}>
          <div>&nbsp;</div>
          {showExtraFieldsForOhio &&
            <div className='cart-days-supply'>
              <Field
                name={`${this.props.fieldNamePrefix}.days_supply`}
                component={ReactSelectInput}
                props={{
                  label: I18n.t('cart.items.daysSupplyLabel'),
                  options: daysSupplyOptions,
                  placeholder: I18n.t('cart.items.daysSupply')
                }}/>
            </div>
          }
        </td>
        <td style={{verticalAlign: 'bottom'}}>
          {product.product_type === 'unit' &&
            <div>
              {!itemMaster.medicated_weight_base
                ? <span className='warning-text'>{I18n.t('cart.items.medicatedWeightMissing')}</span>
                : <React.Fragment>
                    <InternationalDecimalStatic>{itemMaster.medicated_weight_base / weightBaseToRatioMap[itemMaster.medicated_weight_uom_display]}</InternationalDecimalStatic>
                    {itemMaster.medicated_weight_uom_display}/Unit
                  </React.Fragment>
              }
            </div>
          }
        </td>
        <td>{this.renderField()}</td>
        <td><InternationalCurrencyStatic>{this.renderUnitPrice(product)}</InternationalCurrencyStatic></td>
        <td><InternationalDecimalStatic>{totalQuantity}</InternationalDecimalStatic></td>
        <td><InternationalCurrencyStatic>{this.renderLineTotal(product)}</InternationalCurrencyStatic></td>
        <td>
          {isFulfilled
            ? <FaCheck className={'success'}/>
            : (
              <Button
                size='sm'
                onClick={() => handleRemoveProduct(product)}>
                <FaTimes/>
              </Button>
            )}
        </td>
      </tr>
    );
  }
}

ProductRow.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    unit_price: PropTypes.string.isRequired,
    product_type: PropTypes.string,
    sold_weight_uom_display: PropTypes.string,
    id: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
    src: PropTypes.string
  }),
  pricingWeights: PropTypes.array.isRequired,
  handleRemoveProduct: PropTypes.func.isRequired,
  isFulfilled: PropTypes.bool,
  itemMaster: PropTypes.object.isRequired,
  totalQuantity: PropTypes.number.isRequired,
  hasCoupons: PropTypes.bool.isRequired,
  fieldNamePrefix: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  disablingAddInventory: PropTypes.bool,
  isOhMetrc: PropTypes.bool,
  order: PropTypes.object,
};

export default ProductRow;
