//eslint-disable indent
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {FaAngleDown, FaAngleUp} from 'react-icons/fa';
import * as apiActions from '../../../../../actions/apiActions';
import {getDisplayRules} from '../../../../../selectors/customerLimitSelectors';
import * as dataNames from '../../../../../constants/dataNames';
import {isLeafUtahConfigPackClosedLoopFacility} from '../../../../../selectors/facilitiesSelectors';
import {isFeatureEnabled} from '../../../../../selectors/featureToggles';

class PlatformRulesDisplay extends React.PureComponent {

  constructor(props, context) {
    super(props, context);

    this.getUom = this.getUom.bind(this);
    this.getStyle = this.getStyle.bind(this);
    this.getStyle = this.getStyle.bind(this);
    this.renderTableRow = this.renderTableRow.bind(this);
    this.renderTableRows = this.renderTableRows.bind(this);
    this.renderTableHeader = this.renderTableHeader.bind(this);
    this.convertOrderQuantity = this.convertOrderQuantity.bind(this);
  }

  componentWillMount() {
    this.props.actions.getUnpaginatedData('/api/categories', dataNames.categoriesData);
  }

  convertOrderQuantity(rule, prop){
    let value = rule[prop];
    const uom = this.getUom(rule);
    if(uom !== 'GR'){
      if(uom === 'MG'){
        value = value * 1000;
      }
    }
    return value;
  }

  getUom(rule) {
    if(rule.rule_uom === undefined) return 'GR';
    return rule.rule_uom.toUpperCase();
  }

  getStyle(rule) {
    const ruleQuantity = parseFloat(rule.rule_qty);
    const inOrder = this.convertOrderQuantity(rule, 'order');
    const previousOrders = this.convertOrderQuantity(rule, 'previous_orders');
    if(ruleQuantity === (inOrder + previousOrders)){
      return {color: 'green'};
    }
    return {};
  }

  renderTableRow(rule, index) {
    const { displayRules, isUtahEvsEnabled } = this.props;

    return (
      <tr key={index}>
        <td>
          {displayRules[rule].name}
        </td>
        <td style={this.getStyle(displayRules[rule])}>
          {displayRules[rule].rule}
        </td>
        {isUtahEvsEnabled &&
          <td style={this.getStyle(displayRules[rule])}>
            {this.convertOrderQuantity(displayRules[rule], 'available')}&nbsp;
            {this.getUom(displayRules[rule])}
          </td>
        }
        {!isUtahEvsEnabled &&
          <td style={this.getStyle(displayRules[rule])}>
            {this.convertOrderQuantity(displayRules[rule], 'previous_orders')}&nbsp;
            {this.getUom(displayRules[rule])}
          </td>
        }
        {(!this.props.consumer) && (
          <td style={this.getStyle(displayRules[rule])}>
            {this.convertOrderQuantity(displayRules[rule], 'order')}&nbsp;
            {this.getUom(displayRules[rule])}

            {!displayRules[rule].error
              ? null
              : (
                <span style={{color: 'red', float: 'right'}}>
                  {this.convertOrderQuantity(displayRules[rule], 'error')}&nbsp;
                  {this.getUom(displayRules[rule])}
                </span>
              )}
          </td>
        )}
      </tr>
    );
  }

  renderTableRows() {
    const { displayRules } = this.props;

    return (
      <tbody>
      {Object.keys(displayRules).map(this.renderTableRow)}
      </tbody>
    );
  }

  renderTableHeader() {
    const { onToggleSummary, headerStyle, showRows, consumer, isUtahEvsEnabled } = this.props;

    return (
      <thead>
      <tr>
        <th style={headerStyle} onClick={onToggleSummary}>
          Purchase Limits By Category
          {showRows
            ? <FaAngleUp className='float-right'/>
            : <FaAngleDown className='float-right'/>
          }
        </th>
        <th onClick={onToggleSummary} style={{...headerStyle, width: '20%'}}>Limit</th>
        {isUtahEvsEnabled &&
          <th onClick={onToggleSummary} style={{...headerStyle, width: '20%'}}>Available</th>
        }
        {!isUtahEvsEnabled &&
          <th onClick={onToggleSummary} style={{...headerStyle, width: '20%'}}>Ordered</th>
        }
        {(!consumer) && (
          <th onClick={onToggleSummary} style={{...headerStyle, width: '20%'}}>In Order</th>
        )}
      </tr>
      </thead>
    );
  }

  render() {
    if (Object.keys(this.props.salesCompliance).length === 0) {
      return null;
    }

    return (
      <div>
        <table
          className='table table-sm table-bordered text-muted'
          style={{fontSize: 'smaller', backgroundColor: 'inherit'}}
        >
          {this.renderTableHeader()}
          {(this.props.showRows) && this.renderTableRows()}
        </table>
      </div>
    );
  }
}

PlatformRulesDisplay.propTypes = {
  consumer: PropTypes.object,
  salesCompliance: PropTypes.object,
  headerStyle: PropTypes.object.isRequired,
  displayRules: PropTypes.object,
  isUtahEvsEnabled: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    displayRules: getDisplayRules(state, ownProps),
    isUtahEvsEnabled: isLeafUtahConfigPackClosedLoopFacility(state) && isFeatureEnabled(state)('feature_utah_evs')
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions);
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PlatformRulesDisplay);
