import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from 'react-bootstrap';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';

import TextInput from '../../common/form/TextInput';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import QuantityByUomInput from '../../common/form/QuantityByUomInput';

const HarvestItemsFieldArray = ({fields: {map, get}, storageLocations}) => (
  <div>
    {map((itemName, itemIndex) => {
      const itemValues = get(itemIndex);
      const {uom} = itemValues;

      return (
        <Row key={itemName}>
          <Col xs={4}>
            <Field name={`${itemName}.batch_name`} component={TextInput} props={{
              readOnly: true,
              label: I18n.t('harvestBatch.table.harvestBatch'),
            }}/>
          </Col>
          <Col xs={4}>
            <QuantityByUomInput
              name={`${itemName}.current_weight`}
              isRequired={true}
              label={I18n.t('harvestBatch.activate.quantity')}
              allowNegativeNumber={false}
              uom={uom}
            />
          </Col>
          <Col xs={4}>
            <Field name={`${itemName}.inventory_location_id`} component={ReactSelectInput} props={{
              label: I18n.t('harvestBatch.activate.storageLocation'),
              options: storageLocations,
            }}/>
          </Col>
        </Row>
      );
    })}
  </div>
);

HarvestItemsFieldArray.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
  }).isRequired,
  storageLocations: PropTypes.array.isRequired,
};

export default HarvestItemsFieldArray;
