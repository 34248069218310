/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {push, goBack} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import {Tabs, Tab} from 'react-bootstrap';

import * as dataNames from '../../constants/dataNames';
import * as apiActions from '../../actions/apiActions';
import * as itemActions from '../../actions/itemActions';
import FormWrapper from '../common/form/FormWrapper';
import AccountingSettingsForm from './AccountingSettingsForm';
import {getAccountSettingsInitialValues, getAccountSettingsFieldsIdsByKey} from '../../selectors/accountingSelectors';

export class AccountingSettingsPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.redirect = this.redirect.bind(this);
    this.switchTab = this.switchTab.bind(this);
    const tabs = [
      {
        id: '1',
        eventKey: 'sales',
        title: 'accounting.salesAndPayments',
        route: '/accounting/setup/sales',
        actions: []
      },
      {
        id: '2',
        eventKey: 'inventory',
        title: 'accounting.inventoryAndCogs',
        route: '/accounting/setup/inventory',
        actions: []
      },
      {
        id: '3',
        eventKey: 'wholesale',
        title: 'accounting.wholesales',
        route: '/accounting/setup/wholesale',
        actions: []
      },
      {
        id: '4',
        eventKey: 'acquisitions',
        title: 'accounting.acquisitions',
        route: '/accounting/setup/acquisitions',
        actions: []
      },
      {
        id: '5',
        eventKey: 'adjustments',
        title: 'accounting.inventoryAdjustments',
        route: '/accounting/setup/adjustments',
        actions: []
      },
      {
        id: '6',
        eventKey: 'cash',
        title: 'accounting.cashManagement',
        route: '/accounting/setup/cash',
        actions: []
      }
    ];
    this.state = {tabs, activeTab: null};
  }

  componentWillMount() {
    const {group} = this.props.params;
    this.setState({activeTab: group});
    this.props.actions.getData(
      `/api/quickbooks_accounts/types`,
      dataNames.accountingAccountTypes,
      {failed: 'accounting.accountingAccountTypes.failed'}
    );
    this.props.actions.unsetItem(dataNames.accountingSettingsFields);
    this.props.actions.getData(
      `/api/quickbooks_accounts/groups/${group}`,
      dataNames.accountingSettingsFields,
      {failed: 'accounting.accountingSettingsFields.failed'}
    );
    this.props.actions.getData(
      `/api/quickbooks_accounts/org_accounts`,
      dataNames.accountingSettings,
      {failed: 'accounting.accountingSettings.failed'}
    );
  }

  switchTab(eventKey) {
    const tab = find(this.state.tabs, {eventKey}) || this.state.tabs[0];
    this.props.actions.push(tab.route);
    this.setState({activeTab: eventKey});
    this.props.actions.unsetItem(dataNames.accountingSettingsFields);
    this.props.actions.getData(
      `/api/quickbooks_accounts/groups/${eventKey}`,
      dataNames.accountingSettingsFields,
      {failed: 'accounting.accountingSettingsFields.failed'}
    );
  }

  redirect() {
    this.props.actions.goBack();
  }

  onSubmit(formValues) {
    const {accountingSettings, accountingSettingsFields} = this.props;
    const newSettings = accountingSettingsFields.map((field) => {
      let newValue = accountingSettings.find(setting => setting.quickbooks_account_id == field.id);
      if (!newValue) {
        newValue = {
          quickbooks_account_id: field.id,
          account_name: '',
          account_type_id: 0
        };
      }
      newValue.account_name = formValues[field.account_key].account_name;
      newValue.account_type_id = formValues[field.account_key].account_type_id;
      return newValue;
    });
    this.props.actions.postData(
      '/api/quickbooks_accounts/org_accounts',
      newSettings,
      dataNames.accountingSettings,
      {failed: 'stateIntegrator.modify.failed', success: 'stateIntegrator.modify.success'}
    );
  }

  render() {
    const {accountingSettingsFields, accountingAccountTypes, accountingSettings, initialValues} = this.props;

    return (
      <div>
        <Tabs id='filterTabs' activeKey={this.state.activeTab} onSelect={this.switchTab} className='accounting-export-setup'>
          {this.state.tabs.map((tab, index) =>
            <Tab key={index} eventKey={tab.eventKey} title={I18n.t(tab.title)}/>
          )}
        </Tabs>
        <FormWrapper title='accounting.setup.accountingExportSetup' goBack={this.redirect}>
          <AccountingSettingsForm
            accountingSettingsFields={accountingSettingsFields}
            accountingAccountTypes={accountingAccountTypes}
            accountingSettings={accountingSettings}
            initialValues={initialValues}
            onSubmit={this.onSubmit}
          />
        </FormWrapper>
      </div>
    );
  }
}

AccountingSettingsPage.propTypes = {
  actions: PropTypes.object.isRequired,
  accountingSettingsFields: PropTypes.array.isRequired,
  accountingAccountTypes: PropTypes.array.isRequired,
  accountingSettings: PropTypes.array.isRequired,
  initialValues: PropTypes.object.isRequired,
  fieldIdsByKey: PropTypes.object.isRequired,
  params: PropTypes.shape({
    group: PropTypes.oneOf(['cash','adjustments','inventory','sales','acquisitions','wholesale'])
  }).isRequired,
};

function mapStateToProps(state, ownProps) {
  const {accountingSettingsFields, accountingAccountTypes, accountingSettings} = state;
  return {
    accountingSettingsFields,
    accountingAccountTypes,
    accountingSettings,
    initialValues: getAccountSettingsInitialValues(state),
    fieldIdsByKey: getAccountSettingsFieldsIdsByKey(state)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({},
    apiActions,
    itemActions,
    {push, goBack}
  );
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (AccountingSettingsPage);
