import React from 'react';
import {I18n} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import InlineCheckBox from '../../common/form/InlineCheckBox';
import PricingMultiplierPrice from './PricingMultiplierPrice';

const PricingMultiplierSingleUnitPrice = ({fieldPrefix, taxRate, change, disabled, selected, value, getFormValue}) => {

  const overrideProps = {
    groupClassName: 'inline-checkbox',
    label: I18n.t('retail.pricingMultiplier.form.override'),
    disabled,
  };
  if (selected) {
    overrideProps.input = {value: true};
  }

  return (
    <React.Fragment>
      <Field
        name={`${fieldPrefix}.override`}
        component={InlineCheckBox}
        props={overrideProps}
        onChange={(e) => {
          if (!e.target.checked) {
            // Clear corresponding single unit pricing fields
            // Only clear the fields if they exist. If not the 'empty' values of the sparse array collapse
            const preTaxValue = getFormValue(`${fieldPrefix}.price`);
            if (preTaxValue) {
              change(`${fieldPrefix}.price`, '');
            }
            const postTaxValue = getFormValue(`${fieldPrefix}.price_postTax`);
            if (postTaxValue) {
              change(`${fieldPrefix}.price_postTax`, '');
            }
          }
        }}
      />
      <PricingMultiplierPrice
        showHeader={true}
        name={`${fieldPrefix}.price`}
        quantity={1}
        change={change}
        taxRate={taxRate}
        disabled={disabled || !getFormValue(`${fieldPrefix}.override`)}
        value={value}
      />
    </React.Fragment>
  );
};

PricingMultiplierSingleUnitPrice.propTypes = {
  fieldPrefix: PropTypes.string.isRequired,
  taxRate: PropTypes.number,
  change: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  value: PropTypes.number,
  getFormValue: PropTypes.func.isRequired,
};

export default PricingMultiplierSingleUnitPrice;
