import * as UOMTYPE from './uomTypes';

export const WEIGHT = 'weight';
export const UNIT = 'unit';
export const VOLUME = 'volume';

export const mapPricingTypeUomType = {
  [UOMTYPE.DISCRETE]: UNIT,
  [UOMTYPE.WEIGHT]: WEIGHT,
  [UOMTYPE.VOLUME]: WEIGHT //now system don't support volume
};
