/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-named-as-default */
import React from 'react';
import PropTypes from 'prop-types';
import {Field, FieldArray} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Col, FormGroup, Row, Card, ListGroup, ListGroupItem, Button} from 'react-bootstrap';
import {convertDbDateToFormInputDate} from '../../../../util/dateHelpers';
import AccordionPanel from '../../../common/AccordionPanel';
import InlineCheckBox from '../../../common/form/InlineCheckBox';
import InternationalDateTimePickerInput from '../../../common/form/InternationalDateTimePickerInput';
import InternationalCurrencyInput from '../../../common/form/InternationalCurrencyInput';
import InternationalDecimalInput from '../../../common/form/InternationalDecimalInput';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import SubmitSection from '../../../common/form/SubmitSection';
import TextAreaInput from '../../../common/form/TextAreaInput';
import TextInput from '../../../common/form/TextInput';
import OrderLines from './OrderLines';
import Payments from './Payments';
import CreateMatchingOrderOption from '../../common/CreateMatchingOrderOption';
import WillRender from '../../../common/concealers/WillRender';



const PurchaseOrderForm = (props) => {

  const {
    handleSubmit, doSubmit, reset, change, blur,
    partners, locations, sharedLocations, itemMasters,
    registersByType, paymentTerms, paymentTypes, received, paymentStatus, payments,
    oddMoney, isEditMode, partnerFacilities, partnerId, submitting, lines,
    balance, total, existingNotes, errors, onCancel, canStatusBeChanged, status,
    transferRecord, transfer, onOpenTransfer, canEditPayments, onEditPayments,
    timezone, uoms,medicatedStatus, showMedicatedStatusMessage,
    showNoPartnerProductsMessage, users, canCreateMatchingOrder, actions, canReceiveTransfer,
    purchaseOrderSaved, matchingOrderWarnings, canChoosePartner, integrationState,
    isMappingRequiredByPlatform, hasMedicatedLines, metrcIsImported,
    forceMedicatedMapping, isReturn, isInitialMode, allowInitialInventory, transferLinesLimit, modifiedLinesByItemMaster,
    generateInternalSalesOrder, updateInternalSalesOrder, onNavigate, renderFlags, facilityCountryCode, facilityTypes,
    isMetrcTransfersEnabled, hasAllCbdFlower, selectedFacility, categories
  } = props;

  const {isLeaf, isPaLeaf, isOklahoma, isCanada, isMetrc} = integrationState;

  const showVendorInvoiceNumber = !isMappingRequiredByPlatform || (isMappingRequiredByPlatform && (!hasMedicatedLines || isReturn));

  const isActive = isEditMode
    ? ((paymentStatus === 'ordered' || paymentStatus === 'partial' || (paymentStatus === 'completed' && parseFloat(total) === 0.00)) && !received)
    : true;

  // Prevents saving POs without any items per Product 5/21/2018
  const haveLines = Array.isArray(lines)
    ? lines.reduce((acc, line) => {
      if(Object.keys(line).length === 0) return acc;
      const allEmpty = Object.keys(line).reduce((acc, key) => {
        if (!acc) return acc;
        if (line.item_master_id === null) return true;
        const skippedKeys = ['editableLinePrice'];
        if (skippedKeys.indexOf(key) !== -1) return true;
        if (line[key] !== undefined && line[key] !== null) return false;
        return acc;
      }, true);
      if(allEmpty) return acc;
      return acc.concat(line);
    }, []).length
    : 0;

  const submitSettings = {
    align: 'right',
    actionSettings: {
      history: {
        disabled: !isEditMode,
        type: 'button',
        style: 'default',
        text: 'History',
        cssStyle: {marginRight: '60px'},
        action: () => onNavigate('history'),
      },
      reset: {
        disabled: purchaseOrderSaved,
        text: I18n.t('common.actions.reset'),
        style: 'default',
        action: reset
      },
      saveAndPrint: {
        disabled: submitting || !haveLines,
        type: 'submit',
        text: I18n.t('common.actions.saveAndPrint'),
        action: handleSubmit(formValues => doSubmit(formValues, false, true)),
        submitting,
      },
      receiveTransfer: {
        disabled: submitting || !haveLines
          || generateInternalSalesOrder
          || (selectedFacility && selectedFacility['connect_facility_code'])
          || (forceMedicatedMapping && medicatedStatus === 1 && !hasAllCbdFlower)
          || (isMappingRequiredByPlatform && hasMedicatedLines && !hasAllCbdFlower)
          || !canReceiveTransfer
          || renderFlags.hasCompletedTransfer
          || metrcIsImported,
        submitting,
        type: (!purchaseOrderSaved) ? 'submit' : 'button',
        text: (!purchaseOrderSaved) ? I18n.t('purchaseOrders.form.saveAndReceiveTransfer') : 'Receive Transfer',
        action: (!purchaseOrderSaved)
          ? handleSubmit(formValues => doSubmit(formValues, true))
          : handleSubmit(actions.onReceiveTransfer)
      },
      submit: {
        disabled: submitting || !haveLines,
        type: (!purchaseOrderSaved) ? 'submit' : 'button',
        text: (!purchaseOrderSaved) ? I18n.t('common.actions.save') : 'Exit To List',
        action: (!purchaseOrderSaved)
          ? handleSubmit(formValues => doSubmit(formValues, false))
          : handleSubmit(actions.onBack),
        submitting,
      }
    },
  };

  const statusOptions = [
    {text: 'Active', value: (status === 'active') ? paymentStatus : 'ordered'},
    {text: 'Inactive', value: (status === 'inactive' ? paymentStatus : 'cancelled')}
  ];

  const transferStatusOptions = {
    open     : I18n.t('cultivation.transfers.form.open'),
    cancelled: I18n.t('cultivation.transfers.form.canceled'), // yes - we are using two different spellings
    completed: I18n.t('cultivation.transfers.form.completed')
  };

  const medicatedStatusOptions = [
    {text: I18n.t('purchaseOrders.form.medicated'), value: 1},
    {text: I18n.t('purchaseOrders.form.nonMedicated'), value: 0}
  ];

  const orderClassOptions = [
    {value: 'recreational', text: I18n.t('salesOrders.form.orderTypes.recreational')},
    {value: 'medical', text: I18n.t('salesOrders.form.orderTypes.medical')}
  ];

  let isReturnProps = Object.assign({},{
    label: I18n.t('purchaseOrders.form.return'),
    disabled: isPaLeaf,
    groupClassName: 'inline-checkbox'
  }, isEditMode ? { checked: isReturn } : {});

  if(facilityCountryCode === 'CA'){
    delete isReturnProps['checked'];
    delete isReturnProps['disabled'];
  }

  // If there is only one facility type, automatically set it
  if (facilityTypes && facilityTypes.length === 1) {
    change('vendor_facility_type', facilityTypes[0].value);
  }

  return (
    <form className='purchase-order-form' onSubmit={handleSubmit} noValidate={true}>
      {isMetrc && !metrcIsImported && isMetrcTransfersEnabled &&
        <div className='alert alert-warning'>{I18n.t('supplyChain.metrcWarningPurchaseOrder')}</div>
      }
      <FormGroup className='general'>
        <Row>
          <WillRender ifTrue={allowInitialInventory}>
              <Col sm={4}>
                <Field
                  name='is_initial_mode'
                  component={InlineCheckBox}
                  disabled={!isActive}
                  props={{
                    label: I18n.t('purchaseOrders.form.initialInventoryCreation'),
                    groupClassName: 'inline-checkbox',
                    disabled: isEditMode
                  }}
                />
              </Col>
          </WillRender>
        </Row>
        <Row>
          <Col sm={4}>
            <Field name='partner_id' component={ReactSelectInput}
              props={{
                label: I18n.t('purchaseOrders.form.partner'),
                defaultOption: I18n.t('common.form.selectPlaceholder'),
                options: partners,
                textKey: 'name',
                valueKey: 'id',
                isRequired: !isInitialMode,
                disabled: !canChoosePartner || (isLeaf && isEditMode)
              }}/>
          </Col>
          <Col sm={4}>
            <Field name='vendor_facility_id' component={ReactSelectInput}
              props={{
                label: I18n.t('purchaseOrders.form.partnerFacility'),
                options: partnerFacilities,
                textKey: 'facility_name',
                valueKey: 'id',
                isRequired: !isInitialMode,
                disabled: !canChoosePartner || (isLeaf && isEditMode)
              }}/>
          </Col>
          <WillRender ifTrue={isOklahoma || isCanada}>
            <Col sm={4}>
              <Field name='vendor_facility_type' component={ReactSelectInput}
                props={{
                  label: isOklahoma ? I18n.t('purchaseOrders.form.partnerFacilityType') : I18n.t('purchaseOrders.form.partnerType'),
                  options: facilityTypes,
                  textKey: 'text',
                  valueKey: 'value',
                  isRequired: true,
                  disabled: metrcIsImported
                }}/>
            </Col>
          </WillRender>

          {forceMedicatedMapping ?
          <Col sm={4}>
            <Field
                name='contains_medicated'
                component={ReactSelectInput}
                props={{
                  label: I18n.t('purchaseOrders.form.medicatedStatus'),
                  options: medicatedStatusOptions,
                  isRequired: true,
                  disabled: isInitialMode || isEditMode
                }}
            />
          </Col>
          : null}
          <WillRender ifTrue={isPaLeaf || facilityCountryCode === 'CA'}>
            <Col sm={4}>
              <Field
                name='is_return'
                component={InlineCheckBox}
                props={isReturnProps}
              />
            </Col>
          </WillRender>
        </Row>
        <Row>
          <Col sm={4}>
            <InternationalDateTimePickerInput
              name='date_ordered'
              props={{
                label: I18n.t('purchaseOrders.form.dateOrdered'),
                inputProps: {disabled: !isActive}
              }}
            />
          </Col>
          <Col sm={4}>
            <InternationalDateTimePickerInput name='date_expected' props={{
              label: I18n.t('purchaseOrders.form.dateExpected'),
              inputProps: {disabled: !isActive}
            }}/>
          </Col>
          <Col sm={4}>
            <InternationalDateTimePickerInput
              name='time_expected'
              props={{
                dateFormat: false,
                timeFormat: true,
                enableTodayButton: false,
                label: I18n.t('purchaseOrders.form.timeExpected'),
                inputProps: {disabled: !isActive}
              }}/>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <Field name='title' component={TextInput} props={{
              label: I18n.t('purchaseOrders.form.poTitle'),
              readOnly: true
            }}/>
          </Col>

          <WillRender ifTrue={showVendorInvoiceNumber}>
            <Col sm={4}>
              <Field
                name='vendor_invoice_number'
                component={TextInput}
                props={{
                  label: I18n.t('purchaseOrders.form.partnerInvoice'),
                  placeholder: I18n.t('purchaseOrders.form.partnerInvoicePlaceholder'),
                  disabled: !isActive
                }}
              />
            </Col>
          </WillRender>

          {
            isEditMode
            ? (
            <Col sm={4}>
              <Field
                name='po_number'
                component={TextInput}
                props={{
                  label: I18n.t('purchaseOrders.form.poNumber'),
                  readOnly: true,
                }}
              />
            </Col>
          )
            : null
          }
          {
            isEditMode
              ? (
              <Col sm={4} md={3}>
                <Field
                  name='payment_status'
                  component={ReactSelectInput}
                  props={{
                    label: 'Status',
                    disabled: !canStatusBeChanged,
                    defaultOption: I18n.t('common.form.selectPlaceholder'),
                    options: statusOptions,
                    textKey: 'text',
                    valueKey: 'value'
                  }}
                />
              </Col>
            )
              : null
          }
          {isCanada && canCreateMatchingOrder && ((generateInternalSalesOrder || updateInternalSalesOrder) || props.purchaseOrder.internal_partner_sales_order_id) ?
          <Col xs={6} sm={4} md={4} lg={3}>
            <Field
              name='order_class'
              component={ReactSelectInput}
              props={{
                label: I18n.t('salesOrders.form.order'),
                options: orderClassOptions,
                isRequired: generateInternalSalesOrder,
              }}
            />
          </Col>
          : null}

          {
            !transferRecord
              ? null
              : Object.keys(transfer).length === 0
              ? null
              : (
                <Col xs={12}>
                  <div className='alert-info' style={{padding: '10px', marginBottom: '5px'}}>
                    {I18n.t('salesOrders.form.hasTransfer', {transferId: transfer.receipt_number, status: transferStatusOptions[transfer.status]})}&nbsp;
                    <strong>
                      <a
                        href='#'
                        className='float-right'
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          onOpenTransfer(transfer.id);
                        }}
                      >
                        {I18n.t('salesOrders.form.openTransfer', {transferId: transfer.receipt_number})}
                      </a>
                    </strong>
                  </div>
                </Col>
            )
          }


          {
            (payments && payments.length > 0)
              ? (
                <Col xs={12}>
                  <div className='alert-info' style={{padding: '10px', marginBottom: '5px'}}>
                    {I18n.t('salesOrders.form.paymentsExistMessage')}
                  </div>
                </Col>
            ) : null
          }

        </Row>

        {paymentStatus !== 'cancelled' && onCancel && <Row>
          <Col sm={4} md={3}>
            <div className={canCreateMatchingOrder === -1 ? 'alert alert-info' : ''}>
              <Button onClick={onCancel} variant='danger' type='button' disabled={received || (payments && payments.length > 0)} style={{float:'left', margin: '0 15px 8px 0'}}>
                {I18n.t('salesOrders.form.cancelOrder')}
              </Button>
              {
                canCreateMatchingOrder === -1
                  ? <span>
                  {I18n.t('supplyChain.cancelHint', {sourceType: I18n.t('supplyChain.purchaseOrder'), targetType: I18n.t('supplyChain.salesOrder')})}&nbsp;
                  <strong>{I18n.t('supplyChain.cancelWarning', {targetType: I18n.t('supplyChain.salesOrder')})}</strong>
                </span>
                  : null
              }
            </div>
          </Col>
        </Row>}

      </FormGroup>
      <AccordionPanel title='purchaseOrders.form.orderItems' className='accordion-panel'>
        <FieldArray
          name='lines'
          component={OrderLines}
          itemMasters={itemMasters}
          change={change}
          partnerId={partnerId}
          storageLocations={locations}
          sharedLocations={sharedLocations}
          lines={lines}
          isActive={isActive}
          transferRecord={transferRecord}
          uoms={uoms}
          isLeaf={isLeaf}
          showMedicatedStatusMessage={showMedicatedStatusMessage}
          showNoPartnerProductsMessage={showNoPartnerProductsMessage}
          isInitialMode={isInitialMode}
          transferLinesLimit={transferLinesLimit}
          modifiedLinesByItemMaster={modifiedLinesByItemMaster}
          metrcIsImported={metrcIsImported}
          categories={categories}
          isReturn={isReturn}
          isEditMode={isEditMode}
        />
      </AccordionPanel>
      <AccordionPanel title='purchaseOrders.form.orderTotal' className='accordion-panel'>
        <FormGroup>
          <Row>
            <Col xs={7} sm={9}>
              <Row>
                <Col md={3}>
                  <InternationalCurrencyInput name='transfer_fee'
                    props={{
                      label: I18n.t('purchaseOrders.form.transferFee'),
                      disabled: !isActive,
                    }}
                  />
                </Col>
                <Col md={3}>
                  <InternationalCurrencyInput name='taxes'
                    props={{
                      label: I18n.t('purchaseOrders.form.tax'),
                      disabled: !isActive,
                    }}
                  />
                </Col>
                <Col md={3}>
                  <InternationalDecimalInput
                    name='discount_percent'
                    fractionPartSize={2}
                    props={{
                      label: I18n.t('purchaseOrders.form.partnerDiscount'),
                      leftAddon: <span>%</span>,
                      disabled: !isActive
                    }}/>
                </Col>
                <Col md={3}>
                  <InternationalCurrencyInput
                    name='order_total'
                    props={{
                      label: I18n.t('purchaseOrders.form.orderTotal'),
                      readOnly: true,
                      value: isNaN(total) ? 0 : total,
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </FormGroup>
      </AccordionPanel>
      <AccordionPanel title='purchaseOrders.form.paymentInfo' className='accordion-panel'>
        <FormGroup className='payment-information'>
          <Row>
            <Col xs={4} sm={4} md={3} lg={2}>
              <Field name='payment_terms' component={ReactSelectInput} props={{
                label: I18n.t('purchaseOrders.form.terms'),
                defaultOption: I18n.t('common.placeHolders.choose'),
                options: paymentTerms,
                textKey: 'name',
                valueKey: 'code'
              }}/>
            </Col>
            {/*<Col xs={4} sm={3} md={3} lg={2}> // Conditionally show this on edit - but has no place on create
              <Field name='payment_status' component={TextInput} props={{
                label: I18n.t('purchaseOrders.form.orderPlaced'),
                readOnly: true
              }}/>
            </Col>*/}
          </Row>
          <Card className='calculate-total-wrapper' body>
            <Row>
              {
                canEditPayments
                  ? null
                  : (
                  <Col md={2}>
                    <button
                      className='btn btn-sm btn-primary btn-block'
                      onClick={(event) => {
                        event.target.blur();
                        onEditPayments();
                      }}
                    >
                      Edit Payments
                    </button>
                  </Col>
                )
              }
              <Col md={2} offset={canEditPayments ? 2 : 0}>
                <label className='float-right'>{I18n.t('purchaseOrders.form.calculateTotal')}</label>
              </Col>
              <Col md={3} >
                <InternationalCurrencyInput
                  name='total'
                  props={{
                    readOnly: true,
                    value: isNaN(total) ? 0 : total,
                  }}
                />
              </Col>

            </Row>
            <FieldArray
              name='payments'
              component={Payments}
              paymentTypes={paymentTypes}
              registersByType={registersByType}
              errors={(errors && Array.isArray(errors.payments)) ? errors.payments : []}
              change={change}
              blur={blur}
              status={status}
              oddMoney={oddMoney}
              canEditPayments={canEditPayments}
              users={users}
            />
            <Row>
              <Col md={2} offset={2}>
                <label className='float-right'>{I18n.t('purchaseOrders.form.balance')}</label>
              </Col>
              <Col md={3} >
                <InternationalCurrencyInput name='balance'
                  props={{
                    readOnly: true,
                    value: typeof balance === 'number' ? balance.toFixed(2) : '0.00',
                  }}
                />
              </Col>
            </Row>
          </Card>
          <AccordionPanel title='purchaseOrders.form.notes' className='accordion-panel'>
            <FormGroup className='notes'>
              {existingNotes && existingNotes.length ?
                <ListGroup>
                  {existingNotes.map((note, index) =>
                    <ListGroupItem key={index}>
                      <b>{convertDbDateToFormInputDate(note.created_at, timezone)}</b> {note.note}
                    </ListGroupItem>)}
                </ListGroup>
                : null}
              <div>
                <Field name='new_note' component={TextAreaInput} props={{
                  placeholder: I18n.t('purchaseOrders.form.notePlaceholder'),
                  rows: 3,
                  label: ''
                }}/>
              </div>
            </FormGroup>
          </AccordionPanel>
        </FormGroup>
      </AccordionPanel>
      <WillRender ifTrue={canCreateMatchingOrder && isActive}>
        <Row>
          <Col md={{offset: 8, span: 4}}>
            <CreateMatchingOrderOption
              canCreateMatchingOrder={canCreateMatchingOrder}
              sourceOrderType='purchaseOrder'
              matchingOrderWarnings={matchingOrderWarnings}
            />
          </Col>
        </Row>
      </WillRender>
      <SubmitSection settings={submitSettings}/>
    </form>
  );
};

PurchaseOrderForm.propTypes = {
  actions: PropTypes.object.isRequired,
  balance: PropTypes.number,
  blur: PropTypes.func,
  canChoosePartner: PropTypes.bool,
  canCreateMatchingOrder:  PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  canEditPayments: PropTypes.bool,
  canStatusBeChanged: PropTypes.bool.isRequired,
  categories: PropTypes.array.isRequired,
  change: PropTypes.func,
  doSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object,
  existingNotes: PropTypes.array,
  forceMedicatedMapping: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  integrationState: PropTypes.object.isRequired,
  invalid: PropTypes.bool,
  isEditMode: PropTypes.bool,
  isReturn: PropTypes.bool,
  itemMasters: PropTypes.array.isRequired,
  lines: PropTypes.array,
  locations: PropTypes.array.isRequired,
  matchingOrderWarnings: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]).isRequired,
  medicatedStatus: PropTypes.number,
  oddMoney: PropTypes.number,
  onCancel: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onEditPayments: PropTypes.func,
  onOpenTransfer: PropTypes.func,
  onSetMedicatedStatus: PropTypes.func,
  partnerFacilities: PropTypes.array.isRequired,
  partnerId: PropTypes.number,
  partners: PropTypes.array.isRequired,
  paymentStatus: PropTypes.string,
  paymentTerms: PropTypes.array.isRequired,
  paymentTypes: PropTypes.array.isRequired,
  payments: PropTypes.array,
  pristine: PropTypes.bool,
  purchaseOrderSaved: PropTypes.bool,
  received: PropTypes.number,
  registersByType: PropTypes.object,
  reset: PropTypes.func,
  sharedLocations: PropTypes.array.isRequired,
  showMedicatedStatusMessage: PropTypes.bool,
  showNoPartnerProductsMessage: PropTypes.bool,
  status: PropTypes.string.isRequired,
  submitting: PropTypes.bool,
  timezone: PropTypes.string.isRequired,
  total: PropTypes.number,
  transfer: PropTypes.object,
  transferRecord: PropTypes.bool.isRequired,
  uoms: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  isInitialMode: PropTypes.bool,
  allowInitialInventory: PropTypes.bool.isRequired,
  transferLinesLimit: PropTypes.number,
  modifiedLinesByItemMaster: PropTypes.array,
  onChangeLocalState: PropTypes.func,
  purchaseOrder: PropTypes.object,
  formModified: PropTypes.bool,
  generateInternalSalesOrder: PropTypes.bool,
  onNavigate: PropTypes.func,
  selectedFacility: PropTypes.object,
  renderFlags: PropTypes.object.isRequired,
  canReceiveTransfer: PropTypes.bool.isRequired,
  facilityCountryCode: PropTypes.string,
  facilityTypes: PropTypes.array.isRequired,
  metrcIsImported: PropTypes.bool,
  isMetrcTransfersEnabled: PropTypes.bool,
  isMappingRequiredByPlatform: PropTypes.bool,
  hasMedicatedLines: PropTypes.bool,
  updateInternalSalesOrder: PropTypes.bool,
  hasAllCbdFlower: PropTypes.bool
};

PurchaseOrderForm.defaultProps = {
  existingNotes: [],
  // onSetMedicatedStatus: () => {},
  showMedicatedStatusMessage: false,
  showNoPartnerProductsMessage: false,
};

export default PurchaseOrderForm;
