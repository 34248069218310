import get from 'lodash.get';
import find from 'lodash.find';
import * as dataNames from '../../constants/dataNames';
import {getOrderWithProductMappings} from '../../selectors/ordersSelectors';
import {isAllowNegativeInventory} from '../../selectors/complianceSettingsSelectors';
import {getItemMastersAvailability} from '../itemMasterActions';
import {getItem, postItem, putItem, putData, postData} from '../apiActions';
import * as cartActions from '../cartActions';
import * as itemNames from '../../constants/itemNames';
import {getIntegrationState as fetchIntegrationState} from '../../selectors/integration/integrationSelectors';
import {isFeatureEnabled} from '../../selectors/featureToggles';
import {isLeafPaConfigPackClosedLoopFacility} from '../../selectors/facilitiesSelectors';

/***
 * Added to use same code in ProductDetailPage and ProductMenuPage.  With small changes could be used for CartDrawer as well.
 * @TODO: Update for use in CartDrawer.
 * @param formValues
 * @returns {function(*=, *=): Promise}
 */
export const addOrUpdateSingleProductInCart = (formValues) => (dispatch, getState) => {

  return new Promise((resolve, reject) => {

    const state = getState();

    const getCurrentOrder = () => {
      return getOrderWithProductMappings(state);
    };

    const getIntegrationState = () => {
      return fetchIntegrationState(state);
    };

    const getOrderProduct = (itemMasterId) => {
      const currentOrder = getCurrentOrder();
      return find(get(currentOrder, 'products', []), {item_master_id: itemMasterId});
    };

    const getPayload = (formValues, existingProduct) => {
      const isUnitBasedProduct = () => {
        return formValues.itemMasterType === 'unit';
      };

      const getQuantity = () => {
        const quantity = parseFloat(formValues.quantity);
        return isUnitBasedProduct() ? get(existingProduct, 'quantity', 0) + quantity : 1;
      };

      const getSoldWeight = () => {
        const quantity = parseFloat(formValues.quantity);
        return isUnitBasedProduct() ? undefined : get(existingProduct, 'sold_weight', 0) + quantity;
      };

      return {
        item_master_id: get(formValues, 'item_master_id', 0),
        quantity: getQuantity(),
        sold_weight: getSoldWeight(),
        sold_weight_uom: isUnitBasedProduct() ? undefined : get(formValues, 'item_master_uom', 0),
      };
    };

    /**
     * If negative inventory is allowed then lets check to see if we have a negative inventory case and if so
     * let the user decide if they will actually go into negative based on item master.
     * @param itemMasterId
     * @param requestedQuantity
     * @returns {Promise}
     */
    const checkItemMasterAvailability = (itemMasterId, requestedQuantity) => {
      return new Promise((resolve, reject) => {
        const state = getState();
        const allowsNegative = isAllowNegativeInventory(state);
        const itemMasterAlreadyApproved = () => {
          return state[dataNames.itemMasterIds].indexOf(itemMasterId) !== -1;
        };
        if (!allowsNegative || (allowsNegative && itemMasterAlreadyApproved())) {
          resolve();
          return false;
        }
        // Pass in quantities so we get back a simple flag
        const requestedQuantities = [
          {
            item_master_id: itemMasterId,
            quantity: requestedQuantity,
          }
        ];

        dispatch(getItemMastersAvailability([itemMasterId], requestedQuantities))
          .then((data) => {
            const promptNegative = !get(data, '0.isAvailable', false);
            if (promptNegative) {
              reject(data);
              return false;
            }
            resolve(data);
          });
      });
    };

    /**
     * Submit request to add/update order products to API.
     *
     * @param {Object} payload - request payload
     * @param {Boolean} existingProduct - when true, add product; when false, update
     * @return {Promise}
     */
    const updateOrder = (payload, existingProduct) => {
      const handleCartErrors = cartActions.handleErrors;
      const currentOrder = getCurrentOrder();

      let orderProductId = null;
      let chain = Promise.resolve();
      payload.include_negative_siblings_to_check_availability = false;

      if (!existingProduct) {
        chain = chain.then(() => {
          return dispatch(postItem(
            `/api/orders/${currentOrder.id}/products`,
            payload,
            itemNames.order,
            {
              failed: 'orders.products.create.failed',
              failedHandler: handleCartErrors
            }
          )).then((order) => {
            const newProduct = order.products.find(product => payload.item_master_id === product.item_master_id);
            orderProductId = get(newProduct, 'id', null);
          });
        });
      } else {
        orderProductId = get(existingProduct, 'id', null);
        chain = chain.then(() => {
          return dispatch(putItem(
            `/api/orders/${currentOrder.id}/products/${orderProductId}`,
            payload,
            itemNames.order,
            {failed: 'orders.products.create.failed', failedHandler: handleCartErrors},
            {}
          ));
        });
      }

      // after successful order update, submit cure api update
      chain = chain.then(() => {
        const curePayload = {
          order_id: currentOrder.id,
          order_product_id: orderProductId,
          form_id: payload.form_id,
        };
        return updateCure(curePayload, existingProduct);
      });

      // If Leaf PA and MMU limits enabled, refresh sales limit
      if (isLeafPaConfigPackClosedLoopFacility(state) && isFeatureEnabled(state)('feature_leaf_pa_mmu_limits')) {
        chain.then(() => {
          return dispatch(getItem(`/api/consumer_orders/${currentOrder.consumer_id}/limits`, itemNames.customerLimits));
        });
      }

      return chain;
    };

    /**
     * Submit request to add/update Cure API.
     *
     * @param {Object} payload - request payload
     * @param {Boolean} existingProduct - when true, add product; when false, update
     * @return {Promise}
     */
    const updateCure = (payload, existingProduct) => {
      const integrationState = getIntegrationState();
      const isCure = get(integrationState, 'isCure', false);
      if (!isCure) return Promise.resolve();
      if (!existingProduct) {
        return dispatch(postData(
          '/api/cureapi/order_products/',
          payload,
          dataNames.cureOrderProducts,
          {failed: 'cureOrderProducts.save.failed'},
          null
        ));
      } else {
        return dispatch(putData(
          `/api/cureapi/order_products/${get(existingProduct, 'cure_order_product.id', 0)}`,
          payload,
          dataNames.cureOrderProducts,
          {failed: 'cureOrderProducts.save.failed'}
        ));
      }
    };


    // MAIN

    const existingProduct = getOrderProduct(formValues.item_master_id);
    const payload = getPayload(formValues, existingProduct);
    const itemMasterId = get(payload, 'item_master_id', 0);

    checkItemMasterAvailability(itemMasterId, formValues.quantity)
      .then((availabilityResults) => {
        updateOrder(payload, existingProduct)
          .then(() => {
            resolve(availabilityResults);
          })
          .catch((updateOrderResponse) => {
            // Check if Sales Restriction Weight Exceeded.
            const salesRestrictionWeightExceeded = get(updateOrderResponse, 'response.data.errors.VALIDATION.ORDER_ERROR.0') === 'sales_restriction_weight_exceeded';
            if (salesRestrictionWeightExceeded) {
              resolve(availabilityResults);
            }
            reject(availabilityResults);
          });
      })
      .catch((availabilityResults) => {
        reject(availabilityResults);
      });
  });

};

export default addOrUpdateSingleProductInCart;
