import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { goBack } from 'react-router-redux';
import {change, getFormValues} from 'redux-form';
import get from 'lodash.get';
import * as itemNames from '../../../constants/itemNames';
import * as apiActions from '../../../actions/apiActions';
import {setItem, unsetItem} from '../../../actions/itemActions';
import { addMessage } from '../../../actions/systemActions';
import {
  getInheritedDimensionCategories,
  getModifyTestResultsInitialValues,
  getPackageOptions, getTestResult,
  getTestResultDimensions, getTestResultRequiredDimensions,
  isTestResultEditingDisabled
} from '../../../selectors/testResultsSelectors';
import {getCategories} from '../../../selectors/categorySelectors';
import { isLeafIntegrator, isWaLeafIntegrator } from '../../../selectors/integration/leafSelectors';
import {getPartnerFacilities, getPartners} from '../../../selectors/partnersSelectors';
import InProgressOverlay from '../../common/InProgressOverlay';
import FormWrapper from '../../common/form/FormWrapper';
import TestResultsFormWrapper from '../common/TestResultsFormWrapper';
import {fetchPartnerFacilities, fetchPartners} from '../../../actions/partnerActions';
import {canRetestPackages} from '../../../actions/inventory';
import { fetchLabResult } from '../../../actions/lab-result';
import { clearIsolocityTestResult, fetchIsolocityTestResult } from '../../../actions/integrations/isolocity';
import { getIntegrationState } from '../../../selectors/integration/integrationSelectors';
import { unsetData } from '../../../actions/dataActions';
import {getFacilityHasModule} from '../../../selectors/facilitiesSelectors';
import {getInventoryComplianceSettings} from '../../../selectors/complianceSettingsSelectors';
import {getCertificateDocument} from '../../../selectors/filesSelectors';
import * as dataNames from '../../../constants/dataNames';
import {MOD_TEST_RESULTS_FORM} from '../../../constants/forms';
import {isFeatureEnabled} from '../../../selectors/featureToggles';

const form = MOD_TEST_RESULTS_FORM;

const isPlantsTest = (props) => {
  return get(props, 'location.pathname', '').indexOf('plants') !== -1;
};

export class ModifyTestResultsPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      loading: false,
      submitting: false,
      uploading: false,
      isPlants: isPlantsTest(this.props)
    };

    this.hasActivePackage = this.hasActivePackage.bind(this);
    this.onDocChange = this.onDocChange.bind(this);
    this.inheritFromItemMaster = this.inheritFromItemMaster.bind(this);
    this.changeUploadStatus = this.changeUploadStatus.bind(this);
    this.loadCertificateDocument = this.loadCertificateDocument.bind(this);
  }

  componentWillMount() {
    const {
      params: { id },
      integrationState
    } = this.props;
    this.setState({ loading: true });

    const {actions: {getSearchData, getData, postData, setItem}} = this.props;
    const {isPlants} = this.state;
    const childPromises = [];

    const promises = [
      this.props.actions.fetchPartners({ is_lab: 1 }),
      this.props.actions.fetchPartnerFacilities(),
      this.props.actions.getItem('/api/compliance_settings', itemNames.complianceSettings),

      this.props.actions.fetchLabResult(id)
        .then((labResult) => {
          const coaFileId = get(labResult, 'coa_file_id', null);
          if (coaFileId !== null) {
            childPromises.push(new Promise ((resolve, reject) => {
              this.props.actions.getItem(`/api/documents/${coaFileId}`, itemNames.certificateOfAnalysisDocument)
                .then(() => resolve());
            }));
          }
          const referenceIds = labResult.all_references.map(reference => reference.reference_id);
          if (isPlants) {
            childPromises.push(new Promise ((resolve, reject) => {
              getSearchData('/api/search/plants', dataNames.testedPlants, null, {query: '',filter: `id: (${referenceIds.join(' ')})`})
                .then(() => resolve());
            }));
          } else {
            childPromises.push(new Promise ((resolve, reject) => {
              getData('/api/packages', dataNames.testedPackages, null, {in_package_ids: referenceIds, detailed: true})
                .then((testedPackages) => {
                  const item_master_ids = testedPackages
                    ? testedPackages.map(product => product.item_master_id).filter((item_master_id, index, array) => array.indexOf(item_master_id) === index)
                    : [];

                  this.inheritFromItemMaster(item_master_ids);
                  resolve();
                });
            }));
          }
          childPromises.push(new Promise((resolve) => {
            postData(`/api/lab_results_references/inherited`, {referenceIds})
              .then((inheritedLabResult) => {
                setItem(inheritedLabResult, itemNames.inheritedLabResult);
                resolve();
              });
          }));
          Promise.all(childPromises);
        }),
      this.props.actions.getItem('/api/lab_results/configuration', itemNames.testResultConfiguration),
      this.props.actions.getUnpaginatedData('/api/categories', dataNames.categories),
      this.props.actions.getUnpaginatedData('/api/subcategories', dataNames.subcategories),
    ];

    if (integrationState.isIsolocity) {
      promises.push(this.props.actions.fetchIsolocityTestResult(id));
    }

    Promise.all(promises)
      .then(() => this.setState({ loading: false }))
      .catch(() => this.setState({ loading: false }));
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    this.props.actions.clearIsolocityTestResult();
    this.props.actions.unsetItem(itemNames.certificateOfAnalysisDocument);
    this.props.actions.unsetItem(itemNames.product);
    this.props.actions.unsetData(dataNames.itemMasters);
  }

  inheritFromItemMaster(item_master_ids) {
    // This functionality is only available if there are item_masters and facility is not PA, Utah and or a lab
    const {integrationState, facilityHasLabModule, newProductMasterIsEnabled, actions: {getDataByPost, getItem, unsetItem}} = this.props;

    // This functionality only works with the new product masters
    if (!newProductMasterIsEnabled) {
      return;
    }

    const {isPaLeaf, isUtah} = integrationState;

    // If any test result fields are specified in the item_master then these should be marked as required
    const requiredTestResultsFields = [];

    const addMandatoryFields = (testResults, requiredTestResultsFields) => {
      const testResultsData = JSON.parse(testResults);
      if (testResultsData) {
        testResultsData.forEach((testResult) => {
          const name = get(testResult, 'name');
          if (name) {
            requiredTestResultsFields.push(`${name}_percent`);
            requiredTestResultsFields.push(`${name}_mg_g`);
          }
        });
      }
    };

    if (item_master_ids.length > 0 && !isPaLeaf && !isUtah && !facilityHasLabModule) {

      // If we have only one item_master
      // 1. Set mandatory fields based on item_master test results
      if (item_master_ids.length === 1) {
        getItem(`/api/item_masters/${item_master_ids[0]}`, itemNames.product)
          .then((item_master) => {
            const potency_test_results = get(item_master, 'potency_test_results', '[]');
            const terpene_test_results = get(item_master, 'terpene_test_results', '[]');
            // 1. Determine what test_results_fields should be mandatory
            addMandatoryFields(potency_test_results, requiredTestResultsFields);
            addMandatoryFields(terpene_test_results, requiredTestResultsFields);
            this.setState({requiredTestResultsFields});
          });
      }
      // There is more than 1 unique item master. We won't clear any fields that were populated, but we won't any longer make them mandatory
      else {
        unsetItem(itemNames.product);
        this.setState({requiredTestResultsFields: []});
      }

      // Fetch item_masters for all packages
      getDataByPost('/api/item_masters/multiple', {ids: item_master_ids}, dataNames.itemMasters, {failed:'products.get.failed'}, {detailed: 1})
        .then((itemMasters) => {
          // If the sales UOM for all item masters is set to EA we can enable per serving/per unit mode for Cannabinoids & Terpenes
          const perServingModeAvailable = !itemMasters.some(itemMaster => itemMaster.default_uom === 'GR'); // True if all item_masters have the EA uom
          this.setState({perServingModeAvailable});
        });
    }
  }

  onDocChange(file) {
    if(file.id) this.loadCertificateDocument(file.id);
  }

  changeUploadStatus(status) {
    this.setState({uploading: status});
  }

  loadCertificateDocument(id) {
    this.props.actions.unsetItem(itemNames.certificateOfAnalysisDocument);
    return this.props.actions.getItem(`/api/documents/${id}`, itemNames.certificateOfAnalysisDocument);
  }

  hasActivePackage(labResult) {
    if (!(labResult && labResult.all_references)) return false;

    return labResult.all_references.reduce((acc, reference) => {
      if (acc) return acc;
      if (reference.reference_is_active) return true;
      return acc;
    }, []);
  }
  render() {
    const {
      partners, partnerFacilities, initialValues, packageOptions, actions, isEditingDisabled, isWaLeaf,
      certificateDocument, labResultDimensions, facilityHasLabModule, complianceSettings, requiredDimensions,
      labResult, inheritedDimensionCategories, product, actions: {canRetestPackages}, currentValues
    } = this.props;
    const { loading, submitting, isPlants, perServingModeAvailable, requiredTestResultsFields } = this.state;
    const message = `common.form.${submitting ? 'saving' : 'loadingData'}`;

    // Combine required dimension from lab results configuration and required dimensions from item_master
    const combinedRequiredDimensions = [...(requiredDimensions ? requiredDimensions : []), ...(requiredTestResultsFields ? requiredTestResultsFields : [])];

    return (
      <FormWrapper className='coupon-setup' title='cultivation.testResults.modify.title' goBack={actions.goBack}>
        <InProgressOverlay isActive={loading || submitting} translate={true} message={message} />
        <TestResultsFormWrapper
          form={form}
          mode='modify'
          loading={loading}
          addMessage={actions.addMessage}
          isEditingDisabled={isEditingDisabled}
          hasActivePackage={this.hasActivePackage(labResult) ? true : false}
          partners={partners}
          partnerFacilities={partnerFacilities}
          packageOptions={packageOptions}
          initialValues={initialValues}
          documentUrl={get(certificateDocument,'url',null)}
          changeUploadStatus={this.changeUploadStatus}
          onDocChange={this.onDocChange}
          change={change}
          labResultDimensions={labResultDimensions}
          isWaLeaf={isWaLeaf}
          complianceSettings={complianceSettings}
          facilityHasLabModule={facilityHasLabModule}
          requiredDimensions={combinedRequiredDimensions}
          isPlants={isPlants}
          inheritedDimensionCategories={inheritedDimensionCategories}
          canRetestPackages={canRetestPackages}
          currentValues={currentValues}
          categories={this.props.categories}
          product={product}
          perServingModeAvailable={perServingModeAvailable}
        />
      </FormWrapper>
    );
  }
}

ModifyTestResultsPage.propTypes = {
  actions: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    putItem: PropTypes.func.isRequired,
    addMessage: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired,
    canRetestPackages: PropTypes.func.isRequired,
  }).isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired
  }),
  partners: PropTypes.array.isRequired,
  partnerFacilities: PropTypes.array.isRequired,
  packageOptions: PropTypes.array.isRequired,
  initialValues: PropTypes.object,
  facilityHasLabModule: PropTypes.bool,
  isLeaf: PropTypes.bool.isRequired,
  isEditingDisabled: PropTypes.bool.isRequired,
  labResultDimensions: PropTypes.object,
  complianceSettings: PropTypes.object,
  requiredDimensions: PropTypes.array,
  inheritedDimensionCategories: PropTypes.array,
  categories: PropTypes.array,
  product: PropTypes.object,
  newProductMasterIsEnabled: PropTypes.bool.isRequired
};

function mapStateToProps(state, ownProps) {
  const isPlants = isPlantsTest(ownProps);
  return {
    integrationState: getIntegrationState(state),
    isLeaf: isLeafIntegrator(state),
    isWaLeaf: isWaLeafIntegrator(state),
    partners: getPartners(state),
    partnerFacilities: getPartnerFacilities(state),
    initialValues: getModifyTestResultsInitialValues(state, {...ownProps, isPlants}),
    currentValues: getFormValues(form)(state),
    packageOptions: getPackageOptions(state, {form, isPlants}),
    isEditingDisabled: isTestResultEditingDisabled(state, ownProps),
    complianceSettings : getInventoryComplianceSettings(state),
    certificateDocument: getCertificateDocument(state),
    facilityHasLabModule: getFacilityHasModule(state)('TESTING_LAB'),
    labResultDimensions: getTestResultDimensions(state, {form}),
    requiredDimensions: getTestResultRequiredDimensions(state, {form}),
    labResult: getTestResult(state),
    inheritedDimensionCategories: getInheritedDimensionCategories(state),
    categories: getCategories(state),
    product: state[itemNames.product],
    newProductMasterIsEnabled: isFeatureEnabled(state)('feature_new_product_master')
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...apiActions,
    addMessage,
    fetchLabResult,
    fetchPartners,
    fetchPartnerFacilities,
    fetchIsolocityTestResult,
    clearIsolocityTestResult,
    unsetData,
    unsetItem,
    change,
    goBack,
    setItem,
    canRetestPackages,
  };
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModifyTestResultsPage);
