import {actionTypes} from 'redux-form';
import {getItemMasterPackageId} from '../actions/itemMasterActions';

export const productChangeGeneratePackageId = store => next => action => {
  /*
   * Generate a Package ID when a Product is chosen on the Create Harvest Package page.
   */
  const result = next(action);
  if (!(action.type === actionTypes.CHANGE && action.payload && action.meta)) {
    return result;
  }
  const regex = /packages\[(\d+)\]\.item_master_id/;
  const match = regex.exec(action.meta.field);
  if (!match || action.meta.form === 'modifyPackages') {
    return result;
  }
  const packageIndex = match[1];
  store.dispatch(getItemMasterPackageId(action.payload, packageIndex));
  return result;
};

export const dummy = () => {};
