import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from 'react-bootstrap';
import {FaTimes, FaTrash} from 'react-icons/fa';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import TextInput from '../../common/form/TextInput';
import NumericInput from '../../common/form/NumericInput';
import InternationalDecimalInput from '../../common/form/InternationalDecimalInput';
import ReduxButton from '../../common/form/redux-form/ReduxButton';

const CustomerGroup = (props) => {

  const {fields: {map, get}, nextAction, isEdit, isUsedInRewardsSetup} = props;

  return (
    <div className='create-customer-group'>
      <div style={{height: '12px', clear: 'both'}} />
      {map((item, index) => {
        const data = get(index);
        const isReward = (data) ? data.is_rewards_program_group : false;
        return (
          <Col md='6'>
            <Col sm={3} md={12} xs={3}>
              <Field
                name={`${item}.group_name`}
                component={TextInput}
                props={{
                  label: I18n.t('retail.createCustomerGroups.groupName'),
                  isRequired: true
                }}
              />
            </Col>

            <Col sm={3} md={12} xs={3}>
              <Row>
                <Col md={6}>
                  <InternationalDecimalInput
                    name={`${item}.standard_discount_percent`}
                    fractionPartSize={2}
                    props={{
                      label: I18n.t('retail.createCustomerGroups.standard'),
                      isRequired: true
                    }}
                  />
                </Col>
                <Col md={6}>
                  <div className='form-group'>
                    <label style={{width: '100%'}}>&nbsp;</label>
                    <div style={{height: '8px'}}/>
                    <label>
                      <Field
                        name={`${item}.is_rewards_program_group`}
                        component='input'
                        type='checkbox'
                        onClick={(e) => e.target.blur()}
                      />
                      &nbsp;{I18n.t('retail.createCustomerGroups.is_rewards_program_group')}
                    </label>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col sm={3} md={12} xs={3}>
              <div className='alert alert-info' style={{padding: '5px'}}>
                {I18n.t('retail.createCustomerGroups.rewardsGroupsNote')}
                <br /><br />
                <strong>{I18n.t('retail.createCustomerGroups.rewardsGroupsHint')}</strong>
              </div>
            </Col>

            <Col sm={3} md={12} xs={3} className={!isReward ? 'disable-contents' : ''}>
              <Row>
                <Col md={5}>
                  <Field
                    name={`${item}.min_visit_frequency`}
                    component={NumericInput}
                    props={{
                      label: I18n.t('retail.createCustomerGroups.min_visit_frequency'),
                      isRequired: true,
                      fractionPartSize: 0,
                    }}
                  />
                </Col>
                <Col md={2}>
                  <label style={{width: '100%'}} />
                  <label style={{textAlign: 'center', position: 'relative', top: '5px'}}>
                    <div style={{position: 'relative', top: '5px'}}>Within</div>
                  </label>
                </Col>
                <Col md={5}>
                  <Field
                    name={`${item}.min_visit_frequency_days`}
                    component={NumericInput}
                    props={{
                      label: I18n.t('retail.createCustomerGroups.min_visit_frequency_days'),
                      isRequired: true,
                      fractionPartSize: 0,
                    }}
                  />
                </Col>
              </Row>
              <Field
                name={`${item}.min_accrued_points`}
                component={NumericInput}
                props={{
                  label: I18n.t('retail.createCustomerGroups.min_accrued_points'),
                  isRequired: true,
                  fractionPartSize: 0,
                }}
              />

              <Field
                name={`${item}.min_total_spend`}
                component={NumericInput}
                props={{
                  label: I18n.t('retail.createCustomerGroups.min_total_spend'),
                  isRequired: true,
                  fractionPartSize: 2,
                }}
              />

            </Col>
            <Col md={12}>
              {
                isUsedInRewardsSetup
                  ? <div style={{marginBottom: '6px'}} className='alert alert-warning'>This group is in use on Rewards Setup.  Remove it from the groups constraint in order to delete.</div>
                  : <div style={{marginBottom: '6px'}}>
                    <ReduxButton
                      variant='danger'
                      size='sm'
                      name='nextAction'
                      type='submit'
                      className={isEdit ? 'float-left' : 'hide'}
                      style={{marginRight: '3px'}}
                      props={{
                        value: nextAction === 'confirmDelete' ? 'delete' : 'confirmDelete',
                      }}
                    >
                      <FaTrash style={{marginRight: '5px'}} />
                      {I18n.t(`common.${nextAction === 'confirmDelete' ? 'confirmDelete' : 'delete'}`)}
                    </ReduxButton>
                    <ReduxButton
                      variant='primary'
                      className={nextAction === 'confirmDelete' ? 'float-right' : 'hide'}
                      size='sm'
                      name='nextAction'
                      type='button'
                      props={{
                        value: 'cancelDelete',
                      }}
                    >
                      <FaTimes style={{marginRight: '5px'}} />
                      {I18n.t('common.cancelDelete')}
                    </ReduxButton>
                  </div>
              }
            </Col>
          </Col>
        );
      })}
    </div>
  );

};

CustomerGroup.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    length: PropTypes.number.isRequired
  }),
  nextAction: PropTypes.string.isRequired,
  isEdit: PropTypes.bool.isRequired,
  isUsedInRewardsSetup: PropTypes.bool.isRequired,
};

export default CustomerGroup;
