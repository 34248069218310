import * as types from '../../constants/actionTypes';
import initialState from '../../constants/initialState';

export default function couponsReducer (state = initialState.cart.coupons, action){
  const {coupons} = action;
  switch (action.type) {
  case types.GET_COUPONS_SUCCESS:
    return [...coupons];
  case types.GET_COUPONS_FAILED:
    return state;
  default:
    return state;
  }
}
