/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {goBack} from 'react-router-redux';

import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import * as apiActions from '../../actions/apiActions';
import {getUomTypeByCode} from '../../selectors/uomsSelectors';
import {getCurrentFacilityUserOptions} from '../../selectors/usersSelectors';
import {convertFormInputDateToDbDate} from '../../util/dateHelpers';
import FormWrapper from '../common/form/FormWrapper';
import StartPackagingForm from './common/StartPackagingForm';
import {setPackageInfo} from '../../actions/packageActions';
import {addMessage} from '../../actions/systemActions';
import {unsetItem} from '../../actions/itemActions';
import * as messageTypes from '../../constants/messageTypes';
import {getStartPackagingFlattenedLocations} from '../../selectors/inventoryItemsSelectors';
import {isBulkType} from '../../util/uomHelpers';

export class StartPackagingPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.redirect = this.redirect.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {now: new Date(), hasFacilityPrepackWeights: false, noStrain: false};
  }

  componentWillMount() {
    const {getUnpaginatedData, getItem, unsetItem} = this.props.actions;
    if(typeof unsetItem === 'function') unsetItem(itemNames.strain); // To pass tests, don't know why others work and this doesn't
    const {id} = this.props.params;
    this.setState({hasFacilityPrepackWeights: false});
    getUnpaginatedData(
      '/api/prepack_weights/facility',
      dataNames.prepackFacilityWeights,
      {failed: 'packaging.getPrepackWeights.failed'},
      undefined,
      prepackWeights => {
        if (prepackWeights && prepackWeights.length) {
          prepackWeights.forEach(weight => {
            if (weight.id) {
              this.setState({hasFacilityPrepackWeights: true});
            }
          });
        }
        if (!this.state.hasFacilityPrepackWeights) {
          this.props.actions.addMessage(messageTypes.error, 'packaging.getPrepackWeights.missingPackageSizes');
        }
      }
    );

    getItem(`/api/items/${id}`, itemNames.inventoryItem, {failed: 'packaging.getItem.failed'}, undefined,
      (item) => {
        if(isNaN(parseInt(item.item_master.strain_id))){
          this.props.actions.addMessage(messageTypes.error, 'products.getStrain.strainIdInvalid');
          this.setState({noStrain: true});
          return;
        }

        getItem(`/api/strains/${item.item_master.strain_id}`, itemNames.strain, {failed: 'packaging.getStrain.failed'});
      }
    );
    getUnpaginatedData(
      '/api/location_hierarchy',
      dataNames.locations,
      {failed: 'locations.getLocations.failed'}
    );
    getUnpaginatedData(
      '/api/users/current_facility',
      dataNames.currentFacilityUsers,
      {failed: 'packaging.getUsers.failed'}
    );
    getUnpaginatedData('/api/uoms', dataNames.uoms);
  }

  redirect() {
    this.props.actions.goBack();
  }

  onSubmit(formValues) {
    const {packageableItem, actions: {postItem}} = this.props;
    if (packageableItem) {
      const packaging = {
        packaging_location_id: formValues.inventory_location_id,
        assigned_amount: 0,
        assigned_amount_uom: packageableItem.uom,
        waste_reported: 0,
        waste_reported_uom: packageableItem.uom,
        packaged_loss_amt: 0,
        packaged_loss_amt_uom: packageableItem.uom,
        returned_percentage: 0,
        started_at: convertFormInputDateToDbDate(formValues.packaging_start_time, this.props.timezone),
        estimated_completion_at: convertFormInputDateToDbDate(formValues.expected_completion_time, this.props.timezone),
        source_item_id: packageableItem.id,
        packaging_job_assigned_to_users: formValues.user_ids.map(user => ({user_id: user.id}))
      };

      postItem(
        '/api/packaging_jobs',
        packaging,
        itemNames.packagingJob,
        {success: 'packaging.start.success', failed: 'packaging.start.failed'},
        {},
        () => this.props.actions.goBack()
      );
    }
  }

  render() {
    const {locations, packageCode, currentFacilityUsers} = this.props;
    const initialValues = {
      package_code: packageCode,
      inventory_location_id: '',
      user_ids: [],
      packaging_start_time: this.state.now,
      expected_completion_time: this.state.now
    };
    return (
      <FormWrapper className='start-packaging-page' title={'packaging.start.title'} goBack={this.redirect}>
        <StartPackagingForm
          initialValues={initialValues}
          enableReinitialize={true}
          locations={locations}
          currentFacilityUsers={currentFacilityUsers}
          onSubmit={this.onSubmit}
          selectedPackageInfo={this.props.selectedPackageInfo}
          hasFacilityPrepackWeights={this.state.hasFacilityPrepackWeights}
          noStrain={this.state.noStrain}
        />
      </FormWrapper>
    );
  }
}

StartPackagingPage.propTypes = {
  actions: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  currentFacilityUsers: PropTypes.array.isRequired,
  selectedPackageInfo: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  packageableItem: PropTypes.object,
  packageCode: PropTypes.string,
  facility: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired
};

function mapStateToProps(state, ownProps){
  const {facility, inventoryItem, strain, timezone} = state;
  const uom_type = getUomTypeByCode(state, {uom_code: inventoryItem.uom});
  const locations = getStartPackagingFlattenedLocations(state);
  return {
    locations,
    currentFacilityUsers: getCurrentFacilityUserOptions(state),
    selectedPackageInfo: {
      strain: strain.strain_name,
      quantity: inventoryItem.qty,
      uom: inventoryItem.uom,
      vendor: facility.name
    },
    packageCode: inventoryItem.package_code,
    packageableItem: isBulkType(uom_type) && !inventoryItem.container_id ? inventoryItem : undefined,
    facility,
    timezone
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {goBack, setPackageInfo, addMessage, unsetItem});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (StartPackagingPage);
