import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import {Col, Row} from 'react-bootstrap';
import {FaTimes} from 'react-icons/fa';
import testTypes from '../../../../constants/testTypes';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import InlineCheckBox from '../../../common/form/InlineCheckBox';
import AddItem from '../../../common/form/AddItem';


const TestsFieldArray = ({fields: { map, push, remove}, testData, testOptions, formValues}) => {
  return (
    <div className='testing-compliance-wrapper'>
      {map((testName, testIndex) => {
        const currentTestValue = formValues.tests[testIndex].testId;
        return (
          <div className='array-row static-text test-wrapper' key={testName}>
            <div className='layout-row layout-align-space-between-center test-selection'>
              <div className='layout-row layout-align-start-center'>
                <strong>{I18n.t('cultivation.testingCompliance.shipment')}</strong>
                <Field name={`${testName}.testId`} component={ReactSelectInput} props={{
                  options: testOptions,
                  placeholder: I18n.t('cultivation.testingCompliance.selectTestPlaceHolder')
                }}/>
              </div>
              <div className='remove-row-control'>
                <FaTimes className='go-back-x' onClick={() => {remove(testIndex);}}/>
              </div>
            </div>
            {currentTestValue ?
            <div>
              <div className='layout-row form-row'>
                <strong>{I18n.t('cultivation.testingCompliance.testLabel', {title: testTypes[currentTestValue]})}</strong>
              </div>
              <Row>
                {testData[currentTestValue].map(field => (
                  <Col xs={4} sm={3} md={2} key={field}>
                    <Field name={`${testName}.${currentTestValue}.${field}`} component={InlineCheckBox} props={{
                      label: I18n.t(`cultivation.testingCompliance.${currentTestValue}.${field}`)
                    }}/>
                  </Col>
                ))}
              </Row>
            </div>
            : null}
          </div>
        );
      })
      }
      <Row>
        <AddItem
          text='cultivation.testingCompliance.addTest'
          textMode='left'
          alignMode='right'
          addAction={() => push({})}
        />
      </Row>
    </div>
  );
};

TestsFieldArray.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
  }),
  testData: PropTypes.object,
  testOptions: PropTypes.array,
  formValues: PropTypes.object
};

export default TestsFieldArray;
