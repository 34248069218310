import * as types from '../constants/actionTypes';
import initialState from '../constants/initialState';

export default function productLookupReducer (state = initialState.productLookup, action){
  switch (action.type) {
  case types.PRODUCT_LOOKUP_SHOW: //eslint-disable-line
    const defaultStateOnOpen = {
      isModalOpen: true,
      confirmed: false,
      doSearch: true,
    };
    const newState = Object.assign({}, state, action, defaultStateOnOpen);
    delete(newState.type); // No harm... but no point either
    return newState;
  case types.PRODUCT_LOOKUP_HIDE:
    return Object.assign({},state, {
      isModalOpen: false
    });
  case types.PRODUCT_LOOKUP_SELECT:
    return Object.assign({},state, {
      itemMasterId: action.itemMasterId,
      itemMasterName: action.itemMasterName,
      itemMasterDefaultUom: action.itemMasterDefaultUom
    });
  case types.PRODUCT_LOOKUP_CONFIRM:
    return Object.assign({},state, {
      confirmed: true
    });
  case types.PRODUCT_LOOKUP_SET_CATEGORY:
    return Object.assign({},state, {
      categoryId: action.categoryId,
      subCategoryId: null,
      itemMasterId: null,
      itemMasterName: null,
      itemMasterDefaultUom: null
    });
  case types.PRODUCT_LOOKUP_SET_SUBCATEGORY:
    return Object.assign({},state, {
      subCategoryId: action.subCategoryId,
      itemMasterId: null,
      itemMasterName: null,
      itemMasterDefaultUom: null
    });
  case types.PRODUCT_LOOKUP_SEARCH:
    return Object.assign({},state, {
      doSearch: false
    });
  default:
    return state;
  }
}
