import {requiredFieldValidation} from '../../../../common/form/redux-form/validations';

export const validate = (values, props) => {
  const errors = {};
  errors.name = requiredFieldValidation(values.name, 'name');

  errors.printers = [];
  values.printers.forEach((printer) => {
    if(printer.is_local && !printer.present && printer.status === 1){
      errors.printers.push({status: 'Printers not present may not be public.  Delete or switch to private.'});
    } else {
      errors.printers.push({name: undefined});
    }
  });

  return errors;
};

export default validate;
