/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { Card, Tab, Tabs } from 'react-bootstrap';
import PageTitle from '../PageTitle';
import ErrorBoundry from '../ErrorBoundry';

const TabbedFormWrapper = ({ title, children, className, tabs, activeTab, switchTab }) => {
  return (
    <ErrorBoundry>
      <PageTitle primaryText={I18n.t(title)} />
      <Tabs id='filterTabs' activeKey={activeTab} onSelect={switchTab}>
        {tabs.map((tab, index) => (
          <Tab key={index} eventKey={tab.eventKey} title={I18n.t(tab.title)} />
        ))}
      </Tabs>
      <Card className={`form-page ${className}`}>
        <Card.Header />
        <Card.Body>{children}</Card.Body>
      </Card>
    </ErrorBoundry>
  );
};

TabbedFormWrapper.propTypes = {
  title: PropTypes.string,
  localize: PropTypes.bool,
  switchTab: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
  tabs: PropTypes.array.isRequired,
  activeTab: PropTypes.string.isRequired
};

TabbedFormWrapper.defaultProps = {
  localize: true,
  className: ''
};

export default TabbedFormWrapper;
