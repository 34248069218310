import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Button, Table} from 'react-bootstrap';
import {FaTimes} from 'react-icons/fa';
import get from 'lodash.get';
import moment from 'moment';
import InternationalQuantityByUomInput  from '../common/form/InternationalQuantityByUomInput';
import {formatClientDate} from '../../util/dateHelpers';


const ItemRows = ({fields, items, prepackWeights, handleRemoveItem, blockExpiredProductAction}) => {

  const getExpirationDate = (packageCode, items) => {
    const item = items.find((item) => item.package_code === packageCode);
    const package_expires_at = get(item, 'package_expires_at');
    if (package_expires_at && package_expires_at < moment().format('YYYY-MM-DD')) {
      return formatClientDate(package_expires_at);
    }
  };

  return (
    // Nested tables are not cool, but are hard to avoid when React makes you wrap everything in a parent element.
    <tr className='item-rows'>
      <td colSpan={9}>
        <Table>
          <tbody>
          {fields.map((fieldName, index) => {
            const fieldValues = fields.get(index);
            const prepackWeight = (prepackWeights && prepackWeights.length
              && prepackWeights.find(pw => pw.id === fieldValues.prepack_weight_id)) || {name: fieldValues.uom_display};

            return (
              <tr key={index}>
                <td className='product-image'></td>
                <td className='description'></td>
                <td className='item-id'>
                  {fieldValues.package_code ? fieldValues.package_code : I18n.t('cart.items.notApplicable')}
                  { // Show expiration date if Inventory Compliance Setting is set to warn and product is expired
                    fieldValues.package_code && blockExpiredProductAction === 'warn' && getExpirationDate(fieldValues.package_code, items) &&
                    <span className='warning-text'>&nbsp;<strong>{I18n.t('cart.items.expiration_date', {expDate: getExpirationDate(fieldValues.package_code, items)})}</strong></span>
                  }
                </td>
                <td className='medicated-weight'>&nbsp;</td>
                <td className='pricing-weight'>
                  {prepackWeight.name}
                </td>
                <td className='unit-price'>
                </td>
                <td className='quantity'>
                  <div className='inventory-quantity-controls'>
                    <InternationalQuantityByUomInput
                      name={`${fieldName}.qty`}
                      uom={fieldValues.uom_display}
                      allowNegativeNumber={false}
                      className={'quantity-input'}
                      hideUom={true}
                    />
                  </div>
                </td>
                <td className='line-total'>
                </td>
                <td className='last'>
                  <Button size='sm' onClick={() => handleRemoveItem(fieldValues)}>
                    <FaTimes />
                  </Button>
                </td>
              </tr>);
          })}
          </tbody>
        </Table>
      </td>
    </tr>
  );
};

ItemRows.propTypes = {
  fields: PropTypes.object.isRequired,
  handleRemoveItem: PropTypes.func.isRequired,
  prepackWeights: PropTypes.array.isRequired,
  items: PropTypes.array,
  blockExpiredProductAction: PropTypes.func
};

export default ItemRows;
