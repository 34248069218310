import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Row, Button} from 'react-bootstrap';
import {FieldArray} from 'redux-form';
import InternationalQuantityByUomInput from '../../../common/form/InternationalQuantityByUomInput';
import InternationalCurrencyInput from '../../../common/form/InternationalCurrencyInput';
import {SALES_ORDER_FORM} from '../../../../constants/forms';
import PrePackTable from './PrePackTable';
import WillRender from '../../../common/concealers/WillRender';

const PrePackLineItem = (props) => {
  const {
    line,
    change,
    unit_price,
    editableLineTotal,
    onEditableToggle,
    transferRecord,
    hideItemPrices,
    disabled,
    uom
  } = props;

  const { abbrev } = I18n.t(`uoms.${uom}`);

  return (
  <Col lg={8}>
    <Row>
      <Col lg={3}>
        <InternationalQuantityByUomInput
          name={`${line}.qty`}
          uom={uom}
          label={I18n.t('purchaseOrders.form.requestedAmount')}
          readOnly={true} />
      </Col>
      <WillRender ifFalse={hideItemPrices}>
        {
          !editableLineTotal
            ? (
              <Col lg={3}>
                <InternationalCurrencyInput
                  name={`${line}.unit_price`}
                  props={{
                    label: I18n.t('purchaseOrders.form.pricePer', {label: abbrev}),
                    disabled:!!(transferRecord || disabled),
                    formName:{SALES_ORDER_FORM},
                  }} />
              </Col>
            )
            : (
              <Col lg={3}>
                <InternationalCurrencyInput
                  name={`${line}.unit_price`}
                  fractionPartSize={2}
                  props={{
                    label: I18n.t('purchaseOrders.form.approxPricePerGram'),
                    readOnly:true,
                  }} />
              </Col>
            )
        }
      </WillRender>
      <WillRender ifFalse={hideItemPrices}>
        {
          !editableLineTotal
            ? (
              <Col lg={3}>
                <InternationalCurrencyInput
                  name={`${line}.line_item_price`}
                  props={{
                    label: I18n.t('purchaseOrders.form.itemPrice'),
                    disabled:true,
                    rightAddon: transferRecord
                      ? null
                      : <Button onClick={() => { onEditableToggle(true); }}>Edit</Button>
                  }} />
              </Col>
            )
            : (
              <Col lg={3}>
                <InternationalCurrencyInput
                  name={`${line}.line_item_price`}
                  props={{
                    label: I18n.t('purchaseOrders.form.itemPrice'),
                    formName:{SALES_ORDER_FORM},
                    rightAddon: <Button onClick={() => { onEditableToggle(false); }}>Calc</Button>
                  }} />
              </Col>
            )
        }
      </WillRender>
    </Row>

    <FieldArray
      name={`${line}.weights`}
      component={PrePackTable}
      transferRecord={transferRecord}
      props={{
        editableLineTotal,
        line,
        change,
        hideItemPrices,
        unit_price,
        uom
      }}
    />
  </Col>);
};

PrePackLineItem.propTypes = {
  editableLineTotal: PropTypes.bool,
  onEditableToggle: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  line: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  unit_price: PropTypes.number.isRequired,
  prepackWeights: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  hideItemPrices: PropTypes.bool,
  transferRecord: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ]).isRequired,
  uom: PropTypes.string.isRequired
};

export default PrePackLineItem;
