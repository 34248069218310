import { createSelector } from 'reselect';
import getCurrentFacility from './getCurrentFacility';

export const getFacilityCountry = createSelector(
  getCurrentFacility,
  (facility) => (facility && facility.country_code) || false
);

export const isCanadaFacility = createSelector(
  getFacilityCountry,
  (country_code) => country_code && 'ca' === country_code.toLowerCase()
);

export const isUSFacility = createSelector(
  getFacilityCountry,
  (country_code) => country_code && 'us' === country_code.toLowerCase()
);

export const isColombiaFacility = createSelector(
  getFacilityCountry,
  (country_code) => country_code && 'co' === country_code.toLowerCase()
);

export const isLesothoFacility = createSelector(
  getFacilityCountry,
  (country_code) => country_code && 'ls' === country_code.toLowerCase()
);