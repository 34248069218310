/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React from 'react';
import get from 'lodash.get';
import { I18n } from 'react-redux-i18n';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import * as p from '../../../constants/permissions';
import InternationalDateStatic from '../../common/form/InternationalDateStatic';


export default (component, integrationState, hasEditPermission, labResultDimensions, displayCompletionStatus, featureEnabledList) => {
  const activeColumns = [
    {
      name: 'cultivation.testResults.table.testId',
      dataId: 'testing_id',
      hidden: false,
      dataSort: true,
      width: '150px',
      headerAlign: 'left',
      dataAlign: 'left'
    },
    {
      name: 'cultivation.testResults.table.packages',
      csvFormatter: () => I18n.t('cultivation.testResults.table.packages'),
      formatter: component.viewResultsButton('packages'),
      hidden: false,
      dataSort: false,
      width: '100px',
      headerAlign: 'left',
      dataAlign: 'left'
    }
  ]
    .concat(
      labResultDimensions.hasOwnProperty('water_activity')
        ? [
          {
            name: 'cultivation.testingCompliance.waterActivity.title',
            csvFormatter: () => {
              return I18n.t('cultivation.testResults.table.viewResults');
            },
            formatter: component.viewResultsButton('waterActivity'),
            hidden: false,
            dataSort: false,
            headerAlign: 'left',
            dataAlign: 'left',
            width: '120px'
          }
        ]
        : []
    )
    .concat([
      {
        name: 'cultivation.testResults.table.cannabinoidPotency',
        csvFormatter: () => {
          return I18n.t('cultivation.testResults.table.viewResults');
        },
        formatter: component.viewResultsButton('cannabinoidPotency'),
        hidden: false,
        dataSort: false,
        headerAlign: 'left',
        dataAlign: 'left',
        width: '120px'
      },
      {
        name: 'cultivation.testResults.table.terpeneProfile',
        csvFormatter: () => {
          return I18n.t('cultivation.testResults.table.viewResults');
        },
        formatter: component.viewResultsButton('terpeneProfile'),
        hidden: false,
        dataSort: false,
        headerAlign: 'left',
        dataAlign: 'left',
        width: '120px'
      },
      {
        name: 'cultivation.testResults.table.microbial',
        dataId: 'microbial',
        csvFormatter: () => {
          return I18n.t('cultivation.testResults.table.viewResults');
        },
        formatter: component.viewResultsButton('microbials'),
        hidden: false,
        dataSort: false,
        width: '120px',
        headerAlign: 'left',
        dataAlign: 'left'
      },
      {
        name: 'cultivation.testResults.table.mycotoxin',
        csvFormatter: () => {
          return I18n.t('cultivation.testResults.table.viewResults');
        },
        formatter: component.viewResultsButton('mycotoxins'),
        hidden: false,
        dataSort: false,
        width: '120px',
        headerAlign: 'left',
        dataAlign: 'left'
      },
      {
        name: 'cultivation.testResults.table.heavyMetal',
        csvFormatter: () => {
          return I18n.t('cultivation.testResults.table.viewResults');
        },
        formatter: component.viewResultsButton('heavyMetals'),
        hidden: false,
        dataSort: false,
        width: '120px',
        headerAlign: 'left',
        dataAlign: 'left'
      },
      {
        name: 'cultivation.testResults.table.pesticide',
        csvFormatter: () => {
          return I18n.t('cultivation.testResults.table.viewResults');
        },
        formatter: component.viewResultsButton('pesticides'),
        hidden: false,
        dataSort: false,
        width: '120px',
        headerAlign: 'left',
        dataAlign: 'left'
      },
      {
        name: 'cultivation.testResults.table.solvents',
        csvFormatter: () => {
          return I18n.t('cultivation.testResults.table.viewResults');
        },
        formatter: component.viewResultsButton('residualSolvents'),
        hidden: false,
        dataSort: false,
        width: '120px',
        headerAlign: 'left',
        dataAlign: 'left'
      }
    ])
    .concat(
      labResultDimensions.hasOwnProperty('foreign_materials')
        ? [
          {
            name: 'cultivation.testingCompliance.foreignMaterials.title',
            csvFormatter: () => {
              return I18n.t('cultivation.testResults.table.viewResults');
            },
            formatter: component.viewResultsButton('foreignMaterials'),
            hidden: false,
            dataSort: false,
            headerAlign: 'left',
            dataAlign: 'left',
            width: '120px'
          }
        ]
        : []
    )
    .concat([
      {
        name: 'cultivation.testResults.table.testingDate',
        dataId: 'testing_date',
        formatter: (cell) => <InternationalDateStatic>{moment(cell)}</InternationalDateStatic>,
        csvFormatter: () => {
          return I18n.t('cultivation.testResults.table.testingDate');
        },
        hidden: false,
        dataSort: true,
        width: '120px',
        headerAlign: 'left',
        dataAlign: 'left'
      },
      {
        name: 'cultivation.testResults.status',
        dataId: 'status',
        formatter: (status, row) => {
          if (displayCompletionStatus && get(row, 'completion_status') !== 'completed') {
            status = I18n.t('common.inProgress');
          }
          const isFailed = status === 'failed';
          if (status) {
            return (
              <span className={`${isFailed ? 'text-danger' : 'text-success'} text-capitalize bold`}>{status}</span>
            );
          }
          return status;
        },
        hidden: false,
        dataSort: true,
        width: '120px',
        headerAlign: 'left',
        dataAlign: 'left'
      },
    ])
    .concat(
      featureEnabledList.includes('feature_leaf_pa_configuration_pack') && featureEnabledList.includes('feature_pa_hb_1024_lab_enhancements')
        ? [
          {
            name: 'cultivation.testResults.table.testType',
            dataId: 'test_type',
            formatter: (test_type, row) => { return <span className='text-capitalize bold'>{test_type}</span> },
            csvFormatter: () => {
              return I18n.t('cultivation.testResults.table.testType');
            },
            hidden: false,
            dataSort: true,
            width: '120px',
            headerAlign: 'left',
            dataAlign: 'left'
          }
        ]
        : []
    )
    .concat(
      integrationState && integrationState.isWaLeaf
        ? [
          {
            name: 'cultivation.testResults.table.retestEligible',
            dataId: 'retest_eligible',
            formatter: (cell, row) => {
              if (row.status === 'passed') return 'No';
              return parseInt(cell) === 1 ? 'Yes' : 'No';
            },
            hidden: true,
            dataSort: false,
            width: '120px',
            headerAlign: 'left',
            dataAlign: 'left'
          },
          {
            name: 'cultivation.testResults.table.extractionEligible',
            dataId: 'extraction_eligible',
            formatter: (cell, row) => {
              if (row.status === 'passed') return 'No';
              return parseInt(cell) === 1 ? 'Yes' : 'No';
            },
            hidden: true,
            dataSort: false,
            width: '120px',
            headerAlign: 'left',
            dataAlign: 'left'
          }
        ]
        : [],
    [
      {
        name: 'cultivation.testResults.edit',
        dataId: 'id',
        csvFormatter: () => {
          return I18n.t('cultivation.testResults.edit');
        },
        formatter: (id, row) => {
          return (
            <Button variant='primary' onClick={(event) => component.onEdit(event, row.lab_results_id)}>
              {I18n.t('cultivation.testResults.edit')}
            </Button>
            );
        },
        hidden: !hasEditPermission,
        dataSort: false,
        width: '70px',
        headerAlign: 'left',
        dataAlign: 'left',
        permissions: [p.manage_testing]
      }
    ]
    );

  const historicalColumns = activeColumns.slice(0, 13);
  const averagesColumns = [
    {
      name: 'cultivation.testResults.table.productAverage',
      dataId: 'product_name',
      csvFormatter: (cell) => cell,
      hidden: false,
      dataSort: true,
      width: '170px',
      headerAlign: 'left',
      dataAlign: 'left'
    }
  ].concat(activeColumns.filter((_, id) => id > 1 && id < 11).map((col) => ({ ...col, name: col.name + 'Average' })));

  return {
    activeColumns,
    historicalColumns,
    averagesColumns
  };
};
