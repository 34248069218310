
import * as types from '../constants/actionTypes';
import initialState from '../constants/initialState';

export default function currentPlantFormReducer (state = initialState.currentPlantForm, action){
  switch (action.type) {
  case types.SET_PLANTING_BATCH:
    return Object.assign({}, state, {planting_batch: action.planting_batch});
  case types.SET_GRANDMOTHER_PLANT:
    return Object.assign({}, state, {grandmother_plant: action.grandmother_plant});
  case types.SET_ENDING_TAG:
    return Object.assign({}, state, {ending_tag: action.ending_tag });
  default:
    return state;
  }
}
