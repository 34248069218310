import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { Button, ButtonToolbar, Col, Row, Card } from 'react-bootstrap';
import * as p from '../../../constants/permissions';
import { CREATE_HARVEST_PACKAGES } from '../../../constants/forms';
import { extendedRatingOptions } from '../../../constants/ratingOptions';
import PermissionButton from '../../common/PermissionButton';
import HarvestPackages from './HarvestPackages';
import InternationalQuantityByUomInput from '../../common/form/InternationalQuantityByUomInput';
import TextInput from '../../common/form/TextInput';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import { getTranslationLabel } from '../../../util/translationHelpers';
import PermissionWrapper from '../../common/PermissionWrapper';
import WillRender from '../../common/concealers/WillRender';
import ModalityField from '../../integration/colombia/ModalityField';

const getLabel = getTranslationLabel('harvestPackages.form.');


const CreateHarvestPackagesForm = (props) => {

  const {
    hasPackagesTags, handleSubmit, submitting, reset, pristine, valid, locations, itemMasters, harvestBatch,
    totalQuality, doPrint, saveComplete, integrationState, trackingIds, change, packages, users, testPackageStatus,
    harvestWasteTypes, harvestTypeOptions,materialTypeOptions, designatedInventoryAsTestPackageEnabled, cupos
  } = props;

  const {isWaLeaf, isMetrc, isColombia} = integrationState;
  const uom = harvestBatch.uom_display;
  return (
    <form className='harvest-packages-form' onSubmit={handleSubmit}>
      <Row>
        <Col xs={12}>
          <Card body>
            <Row>
              <Col xs={6} md={3} lg={2}>
                <Field
                  name='harvestBatch.batch_name'
                  component={TextInput}
                  props={{
                    label: I18n.t('harvestPackages.form.harvestBatch'),
                    disabled: true
                  }}
                />
              </Col>
              <Col xs={6} md={3} lg={2}>
                <Field
                  name='harvestBatch.strain_name'
                  component={TextInput}
                  props={{
                    label: I18n.t('harvestPackages.form.strain'),
                    disabled: true
                  }}
                />
              </Col>

              <Col xs={4} md={3}>
                <InternationalQuantityByUomInput
                  name='currentWeight'
                  disabled={true}
                  label={getLabel('currentWeight', integrationState)}
                  uom={uom}
                />
              </Col>
              {isWaLeaf ? <Col xs={4} md={3}>
                <InternationalQuantityByUomInput
                  name='currentOtherMaterialWeight'
                  disabled={true}
                  label={getLabel('otherMaterialWeight', integrationState)}
                  uom={uom}
                />
              </Col> : null}
              <WillRender ifTrue={isColombia}>
                <Col xs={6} md={3} lg={2}>
                  <Field
                    name='harvestBatch.cupo_id'
                    component={ReactSelectInput}
                    props={{
                      label: I18n.t('plants.form.cupoId'),
                      disabled: true,
                      options: cupos,
                      valueKey: 'id',
                      textKey: 'cupo_number'
                    }}
                  />
                </Col>
              </WillRender>
              <ModalityField
                name='harvestBatch.modality'
                cupo_id={harvestBatch && harvestBatch.cupo_id}
                strain_id={harvestBatch && harvestBatch.strain_id}
                disabled={true}
                formName={CREATE_HARVEST_PACKAGES}
                LayoutComponent={({ children }) => (
                  <Col xs={6} md={3} lg={2}>
                    {children}
                  </Col>
                )}
              />
              {isWaLeaf ? (
                <Col xs={4} md={2}>
                  <InternationalQuantityByUomInput
                    name='currentOtherMaterialWeight'
                    disabled={true}
                    label={getLabel('otherMaterialWeight', integrationState)}
                    uom={uom}
                  />
                </Col>
              ) : null}
              <PermissionWrapper permissions={[p.rate_products]}>
                <Col xs={4} md={2}>
                  <Field
                    name='harvestBatch.total_quality'
                    component={TextInput}
                    props={{
                      disabled: true,
                      label: I18n.t('harvestPackages.form.batchQualityRating')
                    }}
                  />
                </Col>
              </PermissionWrapper>
            </Row>
          </Card>
        </Col>
      </Row>
      <FieldArray
        name='packages'
        component={HarvestPackages}
        props={{
          hasPackagesTags,
          locations,
          itemMasters,
          ratings: extendedRatingOptions,
          integrationState,
          trackingIds,
          totalQuality,
          packages,
          change,
          users,
          testPackageStatus,
          harvestTypeOptions,
          harvestBatch,
          materialTypeOptions,
          designatedInventoryAsTestPackageEnabled
        }}
      />
      <Row>
        <Col sm={{span: 10, offset: 1}} md={{span: 8, offset: 2}} lg={{span: 6, offset: 3}}>
          <Card body>
            {isMetrc ? (
              <Row>
                <Col xs={6}>
                  <label className='control-label float-right'>Harvest Waste Type</label>
                </Col>
                <Col xs={6}>
                  <Field
                    name='waste_type'
                    component={ReactSelectInput}
                    props={{
                      options: harvestWasteTypes,
                      valueKey: 'name',
                      textKey: 'name'
                    }}
                  />
                </Col>
              </Row>
            ) : null}
            <Row>
              <Col xs={6}>
                <label className='control-label float-right'>{getLabel('reportedWaste', integrationState)}</label>
              </Col>
              <Col xs={6}>
                <InternationalQuantityByUomInput
                  name='waste'
                  uom={uom}
                />
              </Col>
            </Row>
            {isWaLeaf ? <Row>
              <Col xs={6}>
                <label className='control-label float-right'>{getLabel('otherMaterialWaste', integrationState)}</label>
              </Col>
              <Col xs={6}>
                <InternationalQuantityByUomInput
                  name='waste_other_material'
                  uom={uom}
                />
              </Col>
            </Row> : null}
            <Row>
              <Col xs={6}>
                <label className='control-label float-right'>{getLabel('packagedWeight', integrationState)}</label>
              </Col>
              <Col xs={6}>
                <InternationalQuantityByUomInput
                  name='packageWeight'
                  disabled={true}
                  uom={uom}
                />
              </Col>
            </Row>
            {isWaLeaf ? <Row>
              <Col xs={6}>
                <label className='control-label float-right'>{getLabel('packagedOtherMaterialWeight', integrationState)}</label>
              </Col>
              <Col xs={6}>
                <InternationalQuantityByUomInput
                  name='packageOtherMaterialWeight'
                  disabled={true}
                  uom={uom}
                />
              </Col>
            </Row> : null}
            <Row>
              <Col xs={6}>
                <label className='control-label float-right'>{getLabel('remainingWeight', integrationState)}</label>
              </Col>
              <Col xs={6}>
                <InternationalQuantityByUomInput
                  name='remainingWeight'
                  disabled={true}
                  uom={uom}
                />
              </Col>
            </Row>
            {isWaLeaf ? <Row>
              <Col xs={6}>
                <label className='control-label float-right'>{getLabel('remainingOtherMaterialWeight', integrationState)}</label>
              </Col>
              <Col xs={6}>
                <InternationalQuantityByUomInput
                  name='remainingOtherMaterialWeight'
                  disabled={true}
                  uom={uom}
                />
              </Col>
            </Row> : null}
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <ButtonToolbar className='float-right'>
            <Button type='reset' disabled={saveComplete} onClick={reset}>
              {I18n.t('packages.form.reset')}
            </Button>
            <PermissionButton
              permissions={[p.print_labels]}
              props={{
                variant: 'info',
                type: 'submit',
                onClick: () => {
                  doPrint();
                },
                disabled: submitting || pristine || !valid || testPackageStatus.formDisabled
              }}
            >
              {saveComplete ? I18n.t('common.printLabel') : I18n.t('common.saveAndPrintLabel')}
            </PermissionButton>
            <Button
              variant='primary'
              type='submit'
              onClick={() => change('print', false)}
              disabled={submitting || pristine || !valid || saveComplete || testPackageStatus.formDisabled}
            >
              {I18n.t('harvestPackages.form.createLot')}
            </Button>
          </ButtonToolbar>
        </Col>
      </Row>
    </form>
  );
};

CreateHarvestPackagesForm.propTypes = {
  saveComplete: PropTypes.bool.isRequired,
  doPrint: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  locations: PropTypes.array.isRequired,
  itemMasters: PropTypes.shape({
    regular: PropTypes.array.isRequired,
    test: PropTypes.array.isRequired,
    flower: PropTypes.array.isRequired,
    other_material: PropTypes.array.isRequired
  }).isRequired,
  totals: PropTypes.object.isRequired,
  integrationState: PropTypes.object.isRequired,
  trackingIds: PropTypes.array.isRequired,
  totalQuality: PropTypes.number.isRequired,
  packages: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  testPackageStatus: PropTypes.object,
  harvestWasteTypes: PropTypes.array,
  harvestBatch: PropTypes.object.isRequired,
  harvestTypeOptions: PropTypes.array,
  materialTypeOptions: PropTypes.array,
  hasPackagesTags: PropTypes.bool,
  designatedInventoryAsTestPackageEnabled: PropTypes.bool,
  cupos: PropTypes.array
};

export default CreateHarvestPackagesForm;
