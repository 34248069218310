import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Row, Col, Button} from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import {push,goBack} from 'react-router-redux';
import * as apiActions from '../../../actions/apiActions';
import * as itemNames from '../../../constants/itemNames';
import * as dataNames from '../../../constants/dataNames';
import FormWrapper from '../../common/form/FormWrapper';
import PrintServerList from './components/PrintServerList'; //eslint-disable-line
import Editor from './components/editor/Editor';
import QZTray from '../qztray/QZTray'; //eslint-disable-line

export class PrintServersPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.getUiByUrl = this.getUiByUrl.bind(this);
    this.toggleHelp = this.toggleHelp.bind(this);
    this.state = {showHelp: false};
  }

  getUiByUrl(props){
    return !props.params.printServerId
      ? <PrintServerList showHelp={this.state.showHelp} />
      : <Editor printServerId={this.props.params.printServerId} showHelp={this.state.showHelp}/>;
  }

  toggleHelp(){
    this.setState({showHelp: !this.state.showHelp});
  }

  render () {
    return (<FormWrapper
      title='printServerManagement'
      goBack={this.props.actions.goBack}
    >
      <Row>
        <Col md={6}>
          <QZTray label='QZ Tray Status' />
        </Col>
        <Col md={6}>
          <Button
            variant='default'
            className='float-right'
            onClick={(e) => {
              e.target.blur();
              this.toggleHelp();
            }}
          >
            {this.state.showHelp ? 'Hide Help' : 'Show Help'}
          </Button>
        </Col>
      </Row>
      <hr />
      {this.getUiByUrl(this.props)}
    </FormWrapper>);
  }


}

PrintServersPage.propTypes = {
  selectedPrinter: PropTypes.object,
  printServerId: PropTypes.number,
  printJobsLog: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    selectedPrinter: state[itemNames.selectedPrinter],
    printJobsLog: state[dataNames.printJobsLog],
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {push,goBack});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (PrintServersPage);
