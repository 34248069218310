import React from 'react';
import PropTypes from 'prop-types';
import {FaStar} from 'react-icons/fa';

const RatingStars = ({stars, keyPrefix}) => {
  const items = [];
  while(stars > 0) {
    items.push('star');
    stars--;
  }

  return (
    <div className='layout-row stars'>
      {items.map((item, index) => <FaStar key={`${keyPrefix}-${index}`}/>)}
    </div>
  );
};

RatingStars.propTypes = {
  keyPrefix: PropTypes.string.isRequired,
  stars: PropTypes.number.isRequired
};

export default RatingStars;
