import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {connect} from 'react-redux';
import {Col, Row} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import uniqby from 'lodash.uniqby';
import InternationalQuantityByUomInput from '../../common/form/InternationalQuantityByUomInput';
import ReduxMetrcIdField from '../../common/form/redux-form/ReduxMetrcIdField';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import CheckBoxInput from '../../common/form/CheckBoxInput';
import AddItem from '../../common/form/AddItem';
import InlineCheckBox from '../../common/form/InlineCheckBox';
import PackagePurposeField from '../../common/form/redux-form/PackagePurposeField';
import MetrcConcealer from '../../common/concealers/MetrcConcealer';
import WillRender from '../../common/concealers/WillRender';
import {getConstantsByKey} from '../../../selectors/constantsSelectors';
import {isFeatureEnabled} from '../../../selectors/featureToggles';
import showFinishedInventoryFlag from '../../../selectors/rulesSelectors';
import InternationalDateTimePickerInput from '../../common/form/InternationalDateTimePickerInput';
import NumericInput from '../../common/form/NumericInput';


const SplitPackageFieldArray = (props) => {
  const {
    fields,
    actions,
    partners,
    locations,
    itemMaster,
    testSampleIndex,
    integrationState,
    partnerFacilities,
    metrcTrackingTags,
    isQASampleDisabled,
    currentFacilityUsers,
    splitPackageLimitation,
    hasPackagesTags,
    isMetrcStemHoldingsToggled,
    isMetrcCaDonationsToggled,
    isUtahGlobalIdGenerationFeatureEnabled,
  } = props;
  const {isMetrc, isCaMetrc, isBiotrack, isIlBiotrack, isCanada, isPaLeaf} = integrationState;
  const {map, length, get} = fields;

  const showFinishedFlag = showFinishedInventoryFlag({country_code: isCanada ? 'ca' : ''}, itemMaster);
  return (
    <Row>
      <Col xs={12}>
        {
          map((line, index) => {
            const packageData = get(index) || {};
            const {is_test_package, destination_partner_id} = packageData;
            const partnerOptions = partners.filter(partner => !is_test_package || partner.is_lab);
            const partnerFacilityOptions = partnerFacilities.filter(facility => facility.partner_id === destination_partner_id);
            // set package.is_test_package of the new package to 1 and also disable the field for editing.
            const inheritIsTestPackageValue = isPaLeaf && is_test_package;

            return(
              <div key={index}>
              <WillRender ifTrue={isBiotrack || isMetrc || isPaLeaf}>
                <Row>
                  <Col xs={12} sm={4} md={2} className='checkbox-col'>
                    {
                        inheritIsTestPackageValue
                            ? ( <div> {I18n.t('inventory.splitPackage.form.testPackageRequired')} </div> )
                            : null
                    }
                    <Field
                      name={`${line}.is_test_package`}
                      component={CheckBoxInput}
                      props={{
                        label: I18n.t('inventory.splitPackage.form.testPackage'),
                        disabled: isQASampleDisabled || (isIlBiotrack && index !== testSampleIndex && testSampleIndex > -1) || inheritIsTestPackageValue,
                      }}
                    />
                  </Col>
                </Row>
              </WillRender>

              <Row>
                <WillRender ifTrue={isBiotrack}>
                  <Col xs={6} sm={4} md={3}>
                    <Field
                      name={`${line}.destination_partner_id`}
                      component={ReactSelectInput}
                      props={{
                        label: I18n.t('inventory.splitPackage.form.partnerDestination'),
                        options: uniqby(partnerOptions, 'id'),
                        textKey: 'name',
                        valueKey: 'id',
                        isRequired: isBiotrack && is_test_package,
                      }}
                    />
                  </Col>
                  <Col xs={6} sm={4} md={3}>
                    <Field
                      name= {`${line}.destination_partner_facility_id`}
                      component={ReactSelectInput}
                      props={{
                        label: I18n.t('cultivation.purchaseOrders.form.partnerFacility'),
                        options: partnerFacilityOptions,
                        textKey: 'facility_name',
                        valueKey: 'id',
                        isRequired: isBiotrack && is_test_package,
                      }}
                    />
                  </Col>
                </WillRender>

                <WillRender ifTrue={showFinishedFlag}>
                  <Col xs={6} sm={4} md={3}>
                    <div style={{padding: '18px 0'}}>
                      <Field name={`${line}.finished`} component={InlineCheckBox} props={{
                        label: I18n.t('common.form.finishedInventoryCheckbox'),
                      }}/>
                    </div>
                  </Col>
                </WillRender>

                <Col xs={6} sm={4} md={2}>
                  <InternationalQuantityByUomInput
                    name= {`${line}.transacted_qty`}
                    label={I18n.t('inventory.splitPackage.form.qtyUse')}
                    isRequired={true}
                    uom={itemMaster.uom}
                  />
                </Col>

                <Col xs={6} sm={4} md={1}>
                  <Field
                    component={NumericInput}
                    name={`${line}.number_of_splits`}
                    label={I18n.t('inventory.splitPackage.form.numberOfSplits')}
                    isRequired={true}
                    props={{
                      readOnly: isMetrc,
                      value: isMetrc ? 1 : null,
                    }}
                  />
                </Col>

                <Col xs={6} sm={4} md={3}>
                  <Field
                    name= {`${line}.inventory_location_id`}
                    component={ReactSelectInput}
                    props={{
                      label: I18n.t('inventory.splitPackage.form.location'),
                      options: locations,
                      testKey: 'text',
                      valueKey: 'value',
                      isRequired: true
                    }}
                  />
                </Col>
                {isMetrc ?
                  <Col xs={6} sm={4} md={3}>
                    <ReduxMetrcIdField
                      name={`${line}.state_integration_tracking_id`}
                      props={{
                        options: metrcTrackingTags
                      }}
                    />
                  </Col> : null
                }
              </Row>
              <Row>
                <Col xs={6}>
                  <PackagePurposeField name={`${line}.purpose`}/>
                </Col>
                <MetrcConcealer>
                  <Col xs={2}>
                    <Field name={`${line}.is_produced`} component={ReactSelectInput}
                      props={{
                        label: I18n.t('metrc.form.isProductionBatch'),
                        options: [
                          {text: 'Yes', value: 1},
                          {text: 'No', value: 0}
                        ]
                      }}
                      />
                  </Col>
                  {
                    isMetrcStemHoldingsToggled && (
                      <Col xs={2}>
                        <Field name={`${line}.is_trade_sample`} component={ReactSelectInput}
                          props={{
                            label: I18n.t('metrc.form.tradeSample'),
                            options: [
                              {text: 'Yes', value: 1},
                              {text: 'No', value: 0}
                            ]
                          }}
                        />
                      </Col>
                    )
                  }

                  {
                    (isCaMetrc && isMetrcCaDonationsToggled) && (
                      <Col xs={2}>
                        <Field name={`${line}.is_donation`} component={ReactSelectInput}
                          props={{
                            label: I18n.t('metrc.form.donation'),
                            options: [
                              {text: 'Yes', value: 1},
                              {text: 'No', value: 0}
                            ]
                          }}
                        />
                      </Col>
                    )
                  }
                </MetrcConcealer>
                {/*non-medicated category_id is 6*/}
                {!isUtahGlobalIdGenerationFeatureEnabled && (hasPackagesTags && itemMaster.category_id !== 6 && !itemMaster.is_waste) ?
                  <Col xs={6} md={3}>
                    <Field
                      name={`${line}.tag_requested`} component={ReactSelectInput}
                      props={{
                        label: I18n.t('plants.modify.needsTag'),
                        options: [
                          {text: I18n.t('common.yes'), value: 1},
                          {text: I18n.t('common.no'), value: 0}
                        ],
                        textKey: 'text',
                        valueKey: 'value',
                        placeholder: I18n.t('common.common.no')
                      }}/>
                  </Col>
                  : null
                }
              </Row>
              <Row>
                  <Col xs={6} sm={4} md={3} lg={2}>
                    <InternationalDateTimePickerInput
                      name={`${line}.packaged_at`}
                      props={{
                        label: I18n.t('packaging.form.packagedAt'),
                        timeFormat: false,
                        isRequired: false,
                      }} />
                  </Col>
                  <Col xs={6} sm={4} md={3} lg={2}>
                    <InternationalDateTimePickerInput
                      name={`${line}.package_created_at`}
                      props={{
                        label: I18n.t('packaging.form.packageCreatedAt'),
                        timeFormat: false,
                      }}/>
                  </Col>
                  <Col xs={6} sm={4} md={3} lg={2}>
                    <InternationalDateTimePickerInput
                      name={`${line}.package_expires_at`}
                      props={{
                        label: I18n.t('packaging.form.packageExpiresAt'),
                        timeFormat: false,
                        isRequired: false,
                        enableTodayButton: false,
                      }} />
                  </Col>
                  <Col xs={6} sm={4} md={3} lg={2}>
                    <Field
                      name= {`${line}.created_by_user_id`}
                      component={ReactSelectInput}
                      props={{
                        label: I18n.t('inventory.splitPackage.form.splitByEmployee'),
                        options: currentFacilityUsers,
                        textKey: 'fullName',
                        valueKey: 'id'
                      }}
                    />
                  </Col>
                </Row>

                <AddItem
                  length={length}
                  copyAction={() => fields.insert(index, packageData)}
                  removeAction={() => fields.remove(index)}
                  showPlus={false}
                  showMinus={true}
                />

                <Row><Col xs={12}><hr/></Col></Row>
              </div>
            );
          })
        }
      </Col>
      <AddItem
        length={length}
        textMode='left'
        alignMode='right'
        addAction={() => actions.addItem(splitPackageLimitation)}
        showMinus={false}
      />
    </Row>
  );
};

SplitPackageFieldArray.propTypes = {
  fields: PropTypes.shape({
    get: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    map: PropTypes.func.isRequired,
  }).isRequired,
  actions: PropTypes.object,
  locations: PropTypes.array.isRequired,
  metrcTrackingTags: PropTypes.array.isRequired,
  partners: PropTypes.array.isRequired,
  partnerFacilities: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
  currentFacilityUsers: PropTypes.array.isRequired,
  isQASampleDisabled: PropTypes.bool,
  testSampleIndex: PropTypes.number,
  splitPackageLimitation: PropTypes.number,
  itemMaster: PropTypes.object.isRequired,
  hasPackagesTags: PropTypes.bool.isRequired,
  isMetrcStemHoldingsToggled: PropTypes.bool,
  isMetrcCaDonationsToggled: PropTypes.bool,
  isUtahGlobalIdGenerationFeatureEnabled: PropTypes.bool,
};

export default connect(
  (state) => ({
    splitPackageLimitation: getConstantsByKey(state, 'inv.settings.split_package_limitation'),
    isMetrcStemHoldingsToggled: isFeatureEnabled(state)('feature_metrc_stem_holdings'),
    isMetrcCaDonationsToggled: isFeatureEnabled(state)('feature_metrc_ca_donations'),
    isUtahGlobalIdGenerationFeatureEnabled: isFeatureEnabled(state)('feature_utah_global_id_generation'),
  }),
  (dispatch, ownProps) => ({
    actions: {
      addItem: (limit) => {
        const {push, get, length} = ownProps.fields;
        const lastSplit = get(length - 1) || {};

        return push({
          packaged_at: lastSplit.packaged_at,
          package_created_at: lastSplit.package_created_at,
          package_expires_at: lastSplit.package_expires_at,
          tag_requested: 1,
          is_test_package: lastSplit.is_test_package,
          number_of_splits: 1
        });
      }
    }
  })
)(SplitPackageFieldArray);
