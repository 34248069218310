/* eslint-disable import/no-mutable-exports*/
import React from 'react';
import PropTypes from 'prop-types';
import {reduxForm, Field, FieldArray} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import {FaDollarSign} from 'react-icons/fa';
import AccordionPanel from '../../common/AccordionPanel';
import TextInput from '../../common/form/TextInput';
import TextArea from '../../common/form/TextAreaInput';
import CheckBox from '../../common/form/CheckBoxInput';
import SelectInput from '../../common/form/SelectInput';
import NumberInput from '../../common/form/NumericInput';
import DatePicker from '../../common/form/DatePickerInput';
import SubmitSection from '../../common/form/SubmitSection';
import SimpleTable from '../../common/grid/SimpleTable';
import InventorySettingsRow from'./InventorySettingsRow';


class InfoModForm extends React.PureComponent{
  constructor(props, context){
    super(props, context);

    this.selectPricipingType = this.selectPricipingType.bind(this);
    this.state = {
      showSalesTable: false
    };
  }

  selectPricipingType(e){
    this.setState({showSalesTable: e.target.value == 2 || e.target.value == 3});
  }

  render(){
    const {handleSubmit, reset, invalid, pristine,submitting} = this.props;
    const productStatusOptions = [
      {text: 'Active/Inactive', value: 0},
      {text: 'Active', value: 1},
      {text: 'Inactive', value: 2}
    ];
    const selectOptions = [
      {text: 'Select: ', value: 0},
      {text: '1', value: 1}
    ];
    const pricingTypeOptions = [
      {text: 'Select: ', value: 0},
      {text: 'Unit', value: 1},
      {text: 'Bulk', value: 2},
      {text: 'Pre-Pack', value: 3},
    ];
    const solventOptions = [
      {text: 'Select Solvent', value: 0},
      {text: 'Solvent 1', value: 1}
    ];
    const requiresFrigeOptions = [
      {text: 'Yes/No: ', value: 0},
      {text: 'Yes', value: 1},
      {text: 'No', value: 2}
    ];

    const submitSettings = {
      actionSettings: {
        reset: {
          text: I18n.t('common.form.reset'),
          action: reset
        },
        submit: {
          invalid,
          pristine,
          text: I18n.t('products.infoMod.modify'),
          action: handleSubmit,
          submitting
        }
      }
    };

    const productsNames = [
      'product1'
    ];

    const columns = [
      {
        name: '',
        dataId: 'size'
      },
      {
        name: '',
        dataId: 'uom'
      },
      {
        name: '',
        dataId: 'thirdCol'
      }
    ];
    const data = [
      {
        id: 1,
        size: '.25',
        uom: 'G',
        thirdCol: ''
      },
      {
        id: 2,
        size: '.50',
        uom: 'G',
        thirdCol: ''
      },
      {
        id: 3,
        size: '1.0',
        uom: 'G',
        thirdCol: ''
      },
      {
        id: 4,
        size: '1.75',
        uom: 'G',
        thirdCol: ''
      },
      {
        id: 5,
        size: '3.5',
        uom: 'G',
        thirdCol: ''
      },
      {
        id: 6,
        size: '7.0',
        uom: 'G',
        thirdCol: ''
      },
      {
        id: 7,
        size: '14.0',
        uom: 'G',
        thirdCol: ''
      },
      {
        id: 8,
        size: '28.0',
        uom: 'G',
        thirdCol: ''
      },
      {
        id: 9,
        size: '56.0',
        uom: 'G',
        thirdCol: ''
      },
      {
        id: 10,
        size: '112.0',
        uom: 'G',
        thirdCol: ''
      },
      {
        id: 11,
        size: '224.0',
        uom: 'G',
        thirdCol: ''
      },
      {
        id: 12,
        size: '448.0',
        uom: 'G',
        thirdCol: ''
      },
      {
        id: 13,
        size: '453.0',
        uom: 'G',
        thirdCol: ''
      }
    ];

    return(
      <form className='product-info-mod-form' onSubmit={handleSubmit}>
        <Row>
          <Col md={3} sm={3} xs={3}>
            <h4>{I18n.t('products.infoMod.productName')}</h4>
            {productsNames.map((name, index) => {
              return(
                <Field
                  key={index}
                  name={`product-${name}`}
                  component={TextInput}
                  props={{
                    disabled: true,
                    value: `${name}`
                  }}
                />
              );
            })}
          </Col>
        </Row>

        <Row className='edit-product-info'>
          <h4>{I18n.t('products.infoMod.editInfo')}</h4>
          <AccordionPanel className='accordion-panel' title={I18n.t('products.infoMod.generalInfo')} defaultExpanded={true}>
            <Row>
              <Col md={3} sm={3} xs={3} className='checkbox-group'>
                <Field
                  name='medItem'
                  component={CheckBox}
                  props={{
                    label: I18n.t('products.infoMod.medItem')
                  }}
                />
                <Field
                  name='purchaseItem'
                  component={CheckBox}
                  props={{
                    label: I18n.t('products.infoMod.purchaseItem')
                  }}
                />
                <Field
                  name='trackInventory'
                  component={CheckBox}
                  props={{
                    label: I18n.t('products.infoMod.trackInventory')
                  }}
                />
                <Field
                  name='sellItem'
                  component={CheckBox}
                  props={{
                    label: I18n.t('products.infoMod.sellItem')
                  }}
                />
                <Field
                  name='trackInPacks'
                  component={CheckBox}
                  props={{
                    label: I18n.t('products.infoMod.trackInPacks')
                  }}
                />
                <Field
                  name='individSKU'
                  component={CheckBox}
                  props={{
                    label: I18n.t('products.infoMod.individSKU')
                  }}
                />
                <Field
                  name='prePackItem'
                  component={CheckBox}
                  props={{
                    label: I18n.t('products.infoMod.prePackItem')
                  }}
                />
                <Field
                  name='wholesale'
                  component={CheckBox}
                  props={{
                    label: I18n.t('products.infoMod.wholesale')
                  }}
                />
              </Col>
              <Col md={9} sm={9} xs={9}>
                <Field
                  name='tags'
                  component={TextArea}
                  props={{
                    label: I18n.t('products.infoMod.tags')
                  }}
                />
              </Col>
            </Row>
          </AccordionPanel>
          <AccordionPanel className='accordion-panel' title={I18n.t('products.infoMod.productInfo')} defaultExpanded={true}>
            <Row>
              <Col md={3} sm={3} xs={3}>
                <Field
                  name='status'
                  component={SelectInput}
                  props={{
                    label: I18n.t('products.infoMod.status'),
                    options: productStatusOptions
                  }}
                />
              </Col>
              <Col md={3} sm={3} xs={3}>
                <Field
                  name='strainName'
                  component={SelectInput}
                  props={{
                    label: I18n.t('products.infoMod.strainName'),
                    options: selectOptions
                  }}
                />
              </Col>
              <Col md={3} sm={3} xs={3}>
                <Field
                  name='inventoryUnit'
                  component={SelectInput}
                  props={{
                    label: I18n.t('products.infoMod.inventoryUnit'),
                    options: selectOptions
                  }}
                />
              </Col>
            </Row>
          </AccordionPanel>
          <AccordionPanel className='accordion-panel' title={I18n.t('products.infoMod.inventorySettings')} defaultExpanded={true}>
            <Row>
              <Col md={3} sm={3} xs={3}>
                <Row>
                  <Col md={7} sm={7} xs={7}>
                    <Field
                      name='medicatedWeight'
                      component={NumberInput}
                      props={{
                        label: I18n.t('products.infoMod.medicatedWeight')
                      }}
                    />
                  </Col>
                  <Col md={5} sm={5} xs={5}>
                    <Field
                      name='uom1'
                      component={SelectInput}
                      props={{
                        label: I18n.t('products.infoMod.uom'),
                        options: selectOptions
                      }}
                    />
                  </Col>
                </Row>
              </Col>

              <Col md={3} sm={3} xs={3}>
                <Row>
                  <Col md={7} sm={7} xs={7}>
                    <Field
                      name='productWeight'
                      component={NumberInput}
                      props={{
                        label: I18n.t('products.infoMod.productWeight')
                      }}
                    />
                  </Col>
                  <Col md={5} sm={5} xs={5}>
                    <Field
                      name='uom2'
                      component={SelectInput}
                      props={{
                        label: I18n.t('products.infoMod.uom'),
                        options: selectOptions
                      }}
                    />
                  </Col>
                </Row>
              </Col>

              <Col md={3} sm={3} xs={3}>
                <Row>
                  <Col md={7} sm={7} xs={7}>
                    <Field
                      name='grossWeight'
                      component={NumberInput}
                      props={{
                        label: I18n.t('products.infoMod.grossWeight')
                      }}
                    />
                  </Col>
                  <Col md={5} sm={5} xs={5}>
                    <Field
                      name='uom3'
                      component={SelectInput}
                      props={{
                        label: I18n.t('products.infoMod.uom'),
                        options: selectOptions
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <FieldArray
              name='packages'
              component={InventorySettingsRow}
              props={{}}
            />
          </AccordionPanel>
          <AccordionPanel className='accordion-panel' title={I18n.t('products.infoMod.salesInfo')} defaultExpanded={true}>
            <Row>
              <Col md={3} sm={3} xs={3}>
                <Field
                  name='online'
                  component={SelectInput}
                  props={{
                    label: I18n.t('products.infoMod.online'),
                    options: productStatusOptions
                  }}
                />
              </Col>
              <Col md={3} sm={3} xs={3}>
                <Field
                  name='pricingType'
                  component={SelectInput}
                  props={{
                    label: I18n.t('products.infoMod.pricingType'),
                    options: pricingTypeOptions,
                    onChange: (e) => this.selectPricipingType(e)
                  }}
                />
              </Col>
              <Col md={3} sm={3} xs={3}>
                <Field
                  name='pricePerUnit'
                  component={NumberInput}
                  props={{
                    label: I18n.t('products.infoMod.pricePerUnit'),
                    leftAddon: <FaDollarSign />,
                    placeholder: I18n.t('products.infoMod.placeholderCost')
                  }}
                />
              </Col>
              <Col md={3} sm={3} xs={3}>
                <Field
                  name='pricingGroup'
                  component={SelectInput}
                  props={{
                    label: I18n.t('products.infoMod.pricingGroup'),
                    options: selectOptions
                  }}
                />
              </Col>
              <Col md={3} sm={3} xs={3}>
                <Field
                  name='wholesalePrice'
                  component={NumberInput}
                  props={{
                    label: I18n.t('products.infoMod.wholesalePrice'),
                    leftAddon: <FaDollarSign />,
                    placeholder: I18n.t('products.infoMod.placeholderCost')
                  }}
                />
              </Col>
            </Row>
            {this.state.showSalesTable ? <SimpleTable columns={columns} data={data} striped={false} bordered={true} /> : null}
          </AccordionPanel>
          <AccordionPanel className='accordion-panel' title={I18n.t('products.infoMod.potencyInfo')} defaultExpanded={true}>
            <Row>
              <Col md={3} sm={3} xs={3}>
                <Field
                  name='activation'
                  component={SelectInput}
                  props={{
                    label: I18n.t('products.infoMod.activation'),
                    options: selectOptions
                  }}
                />
              </Col>
              <Col md={3} sm={3} xs={3}>
                <Field
                  name='dominance'
                  component={SelectInput}
                  props={{
                    label: I18n.t('products.infoMod.dominance'),
                    options: selectOptions
                  }}
                />
              </Col>
            </Row>
          </AccordionPanel>
          <AccordionPanel className='accordion-panel' title={I18n.t('products.infoMod.additionalInfo')} defaultExpanded={true}>
            <Row>
              <Col md={9} sm={9} xs={9} className='additional-info'>
                <Col md={4} sm={3} xs={3}>
                  <Field
                    name='servingSize'
                    component={TextInput}
                    props={{
                      label: I18n.t('products.infoMod.activation'),
                      placeholder: I18n.t('products.infoMod.placeholderSize')
                    }}
                  />
                </Col>
                <Col md={4} sm={3} xs={3}>
                  <Field
                    name='numberOfSizes'
                    component={NumberInput}
                    props={{
                      label: I18n.t('products.infoMod.numberOfSizes'),
                      placeholder: I18n.t('products.infoMod.placeholderNumberOfSize')
                    }}
                  />
                </Col>
                <Col md={4} sm={3} xs={3}>
                  <Field
                    name='numberOfSizes'
                    component={SelectInput}
                    props={{
                      label: I18n.t('products.infoMod.solvent'),
                      options: solventOptions
                    }}
                  />
                </Col>
                <Col md={4} sm={3} xs={3}>
                  <Field
                    name='requiresFrige'
                    component={SelectInput}
                    props={{
                      label: I18n.t('products.infoMod.requiresFrige'),
                      options: requiresFrigeOptions
                    }}
                  />
                </Col>
                <Col md={4} sm={3} xs={3}>
                  <Field
                    name='piecesUnit'
                    component={NumberInput}
                    props={{
                      label: I18n.t('products.infoMod.piecesUnit'),
                      placeholder: I18n.t('products.infoMod.placeholderCount')
                    }}
                  />
                </Col>
                <Col md={4} sm={3} xs={3}>
                  <Field
                    name='methodOfDilution'
                    component={SelectInput}
                    props={{
                      label: I18n.t('products.infoMod.methodOfDilution'),
                      options: solventOptions
                    }}
                  />
                </Col>
              </Col>
            </Row>
          </AccordionPanel>
        </Row>

        <Row>
          <Col md={3} sm={3} xs={3}  className='modification-date'>
              <Field
                name='modDate'
                component={DatePicker}
                props={{
                  label: I18n.t('products.infoMod.modDate'),
                  enableTodayButton: true
                }}
              />
          </Col>
        </Row>

        <SubmitSection settings={submitSettings}/>

      </form>
    );
  }
}

InfoModForm.propTypes = {
  onReset:PropTypes.func.isRequired,
  onSubmit:PropTypes.func.isRequired,
  change:PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
};

export default reduxForm({
  form: 'InfoModFormFields'
})(InfoModForm);
