import { createSelector } from 'reselect';
import get from 'lodash.get';
import * as itemNames from '../constants/itemNames';
import { INTEGRATION_ERROR_DUPE_ID } from '../constants/integration/entityLocks';
import { getIntegrationState } from './integration/integrationSelectors';

import DestroyPlant from '../components/entity-locks/components/DestroyPlant'; //eslint-disable-line

const getEntityLockInModal = (state) => state[itemNames.integrationTrackingStatusModal];

const entityLockRemedyComponents = {
  [INTEGRATION_ERROR_DUPE_ID]: {
    biotrack: [
      DestroyPlant
    ]
  }
};

export const getEntityLockRemedies = (entityLockErrorType, integrator) => {
  return get(entityLockRemedyComponents, `${entityLockErrorType}.${integrator}`, []);
};

export const getEntityLockRemediesSelector = createSelector(
  [getIntegrationState, getEntityLockInModal],
  (integrationState, entityLockInModal) => {
    const integrator = integrationState.isBiotrack ? 'biotrack' : '';
    const entityLocks = get(entityLockInModal, 'entityLocks', []);
    if(!entityLocks.length){
      return {};
    }

    return entityLocks.reduce((acc, entityLock) => {
      const lockReason = get(entityLock, 'lock_reason', '');
      acc[lockReason] = getEntityLockRemedies(lockReason, integrator);
      return acc;
    }, {});
  });
