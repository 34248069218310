import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {FaExclamationTriangle, FaInfoCircle} from 'react-icons/fa';
import LabelInformationPackage from './LabelInformationPackage';

const SampleImage = (props) => {

  const isSelected = () => {
    return props.selectedTag === props.label.tag;
  };

  let haveLabel = false;
  if(Object.keys(props.label).length > 0){
    if(props.label.emptyMessage === undefined) haveLabel = true;
  }

  let width = '100';
  let height = '100';
  if(haveLabel){
    width = props.label.width * 150; // wide images were earlier capped to 1100 pixels
    height = props.label.height * 150;
  }

  const labelTypeStyle = {position:'absolute', right: '5px', top: '5px', textTransform: 'uppercase', fontWeight: 'bold', color: '#ccc', backgroundColor: '#444', padding: '3px 3px 3px 5px', zIndex: '10000'};

  const getIconColor = () => {
    return (props.label.image.base64_collection.length < 2) ? 'inherit' : '#fff';
  };

  return (
      <div className={props.columnClass}>
        {
          (haveLabel)
            ? (props.labelCount === 1)
              ? (<div><strong>{props.label.name}: {props.label.width} X {props.label.height}</strong></div>)
              : (<div className='clearfix' style={{marginBottom: '8px'}}>
                  <input
                    type='radio'
                    value={props.label.tag}
                    onChange={props.setTagInState}
                    checked={isSelected()}
                    style={{width: '24px', height: '24px', float: 'left'}} />
                  <div style={{position:'relative', top: '6px', left: '5px', float:'left'}}>
                    <strong>{props.label.name}: {props.label.width} X {props.label.height}</strong>
                  </div>
                  </div>)
            : (props.label.emptyMessage !== undefined) ? (<div>{props.label.emptyMessage}</div>) : null
        }
        <div style={{border: '1px solid #ddd', borderRadius: '2px', padding: '5px', overflow: 'hidden'}}>
        {
          (haveLabel)
            ? (
              <div style={{position:'relative'}}>
                <div style={Object.assign({}, labelTypeStyle, props.label.label_types.primary === 'image' ? {cursor: 'pointer'} : {})} onClick={() => props.onToggleShowLabelDetails(props.label)}>
                  {I18n.t(`labels.${props.label.label_types.primary}_short`)}
                  {
                    props.label.label_types.primary !== 'image'
                      ? null
                      : (props.label.image.base64_collection.length > 1)
                        ? <FaExclamationTriangle style={{marginLeft: '8px', marginRight: '5px', position:'relative', top: '2px', fontWeight: 'normal', color: getIconColor()}} />
                        : <FaInfoCircle style={{marginLeft: '8px', marginRight: '5px', position:'relative', top: '2px', fontWeight: 'normal', color: getIconColor()}}/>
                  }
                </div>
                <LabelInformationPackage label={props.label} showLabelDetails={props.showLabelDetails} onToggleShowLabelDetails={props.onToggleShowLabelDetails} selectedLabel={props.selectedLabel} />
                <div style={{clear:'both'}} />

                {
                  props.labelCount > 1 || !props.label.multiLabel
                    ? (<div style={{width: `${width}px`, border: '1px solid #ccc', float: 'left', position: 'relative', height: `${height}px`}}>
                    <img style={{width: '100%'}}
                         src={['data:image/png;base64', props.label.image.base64]}
                    />
                  </div>)
                    : props.label.image.base64_collection.map((image, index) => {
                      return (<div key={index} style={{width: `${width}px`, border: '1px solid #ccc', float: 'left', position: 'relative', height: `${height}px`, clear: 'both', marginBottom: '5px'}}>
                        <img style={{width: '100%'}}
                             src={['data:image/png;base64', image]}
                        />
                      </div>);
                    })
                }

                {
                  !props.label.image.secondary
                    ? null
                    : <div style={{width: `${width}px`, border: '1px solid #ccc', float: 'left', marginLeft: '8px', position: 'relative'}}>
                    <div style={labelTypeStyle}>{I18n.t(`labels.${props.label.label_types.secondary}_short`)}</div>
                    <img
                      style={{width: '100%'}}
                      src={['data:image/png;base64', props.label.image.secondary]}
                    />
                  </div>
                }
              </div>
            )
            : null
        }
        </div>
      </div>
  );


};

SampleImage.defaultProps = {
  showLabelDetails: false,
  onToggleShowLabelDetails: () => {},
};

SampleImage.propTypes = {
  label: PropTypes.object.isRequired,
  columnClass: PropTypes.string.isRequired,
  labelCount: PropTypes.number.isRequired,
  selectedTag: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]).isRequired,
  setTagInState: PropTypes.func.isRequired,
  showLabelDetails: PropTypes.bool,
  onToggleShowLabelDetails: PropTypes.func,
  selectedLabel: PropTypes.object,
};

export default SampleImage;
