import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack} from 'react-router-redux';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import {getItem, getUnpaginatedData, postItem} from '../../../actions/apiActions';
import {getActiveFacilityId} from '../../../selectors/facilitiesSelectors';
import {getFlattenedLocations} from '../../../selectors/locationsSelectors';
import {
  getWasteComplianceInitialValues,
  getStageOptions,
  getDayOptions
} from '../../../selectors/compliance/waste/wasteComplianceSelectors';
import {getIntegrationState} from '../../../selectors/integration/integrationSelectors';
import {getWasteCompliancePayload, getTrackWastePackages, getTransferringWaste} from '../../../selectors/forms/wasteComplianceFormSelectors';
import FormWrapper from '../../common/form/FormWrapper';
import InProgressOverlay from '../../common/InProgressOverlay';
import WasteComplianceFormWrapper from './WasteComplianceFormWrapper';
import {
  getRequireWasteTransfer,
  getWastePackageTrackingIsOverrideable
} from '../../../selectors/complianceSettingsSelectors';

class WasteCompliancePage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: true,
      saving: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount() {
    const {actions: {getUnpaginatedData, getItem}} = this.props;

    Promise
      .all([
        getItem(
          '/api/cultivation/settings',
          itemNames.wasteCompliance,
          {failed: 'cultivation.wasteCompliance.get.failed'},
          //inv_waste_packaging can be removed in a post 2.8 release
          {ids:  ['cult_waste_destruction_compliance_settings', 'inv_waste_packaging', 'inv_track_waste_location_id']}
        ),
        getItem(
          '/api/compliance_settings',
          itemNames.complianceSettings,
          {failed: 'cultivation.wasteCompliance.get.failed'},
          {ids:  ['inv_require_waste_transfer', 'inv_track_waste_packages'], get_overridable_in_configpack: true}
        ),
        getUnpaginatedData(
          '/api/location_hierarchy',
          dataNames.locations,
          {failed: 'locations.getLocations.failed'},
          {is_sales_location: 0, active: 1}
        )
      ])
      .then(() => this.setState({loading: false}))
      .catch(() => this.setState({loading: false}));
  }

  onSubmit(formValues){
    const payload = getWasteCompliancePayload(formValues, this.props.facilityId);
    this.setState({saving: true});
    const promises = [
      this.props.actions.postItem(
        '/api/cultivation/settings',
        payload['cult_waste_destruction_compliance_settings'],
        null,
        {failed: 'cultivation.wasteCompliance.save.failed'}
      ),
      this.props.actions.postItem(
        '/api/inventory_settings',
        payload['inv_waste_packaging'],
        null,
        {failed: 'cultivation.wasteCompliance.save.failed'}
      )
    ];
    return Promise.all(promises)
      .then(() => this.setState({saving: false}))
      .catch(() => this.setState({saving: false}))
      .then(this.props.actions.goBack);
  }

  render() {
    const {actions: {goBack}, initialValues, dayOptions, stageOptions, locations, trackWastePackages, 
      transferringWaste, integrationState, trackingOfWastePackagesIsOverrideable, requireWasteTransfer} = this.props;
    const {loading, saving} = this.state;
    const message = I18n.t(`common.form.${loading ? 'loading' : 'saving'}`);

    return (
      <FormWrapper className='waste-compliance' title='cultivation.wasteCompliance.title' goBack={goBack} >
        <InProgressOverlay isActive={loading || saving} message={message}/>
        <WasteComplianceFormWrapper
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          integrationState={integrationState}
          dayOptions={dayOptions}
          stageOptions={stageOptions}
          locations={locations}
          trackWastePackages={trackWastePackages}
          transferringWaste={transferringWaste}
          trackingOfWastePackagesIsOverrideable={trackingOfWastePackagesIsOverrideable}
          requireWasteTransfer={requireWasteTransfer}
        />
      </FormWrapper>
    );
  }
}

WasteCompliancePage.propTypes = {
  actions: PropTypes.shape({
    getItem: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  initialValues: PropTypes.object.isRequired,
  stageOptions: PropTypes.array.isRequired,
  dayOptions: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  facilityId: PropTypes.number.isRequired,
  integrationState: PropTypes.object.isRequired,
  trackWastePackages: PropTypes.bool,
  trackingOfWastePackagesIsOverrideable: PropTypes.bool.isRequired,
  transferringWaste: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    facilityId: getActiveFacilityId(state),
    initialValues: getWasteComplianceInitialValues(state),
    stageOptions: getStageOptions(state),
    dayOptions: getDayOptions(state),
    locations: getFlattenedLocations(state),
    trackWastePackages: getTrackWastePackages(state),
    transferringWaste: getTransferringWaste(state),
    trackingOfWastePackagesIsOverrideable: getWastePackageTrackingIsOverrideable(state),
    requireWasteTransfer: getRequireWasteTransfer(state),
    integrationState: getIntegrationState(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({getItem, getUnpaginatedData, postItem, goBack}, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WasteCompliancePage);
