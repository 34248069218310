/* eslint-disable import/no-named-as-default */
import React from 'react';
import { Route, IndexRoute } from 'react-router';
import {preventDirectLinkAccess, UserIsAuthenticated, UserIsAuthorized} from '../util/auth';
import {routes} from './routeDefinitions';
import App from '../components/App';
import PermissionWrapper from '../components/common/PermissionWrapper';
import Authorized from '../components/Authorized';
import Unauthorized from '../components/UnAuthorized';
import LandingPage from '../components/landing/LandingPage';
import NoPermissionPage from '../components/landing/NoPermission';
import NoFacilityPermission from '../components/landing/NoFacilityPermission';
import LoginPage from '../components/users/login/LoginPage';
import TokenLoginPage from '../components/users/login/TokenLoginPage';
import ChangePasswordPage from '../components/users/change-password/ChangePasswordPage';
import ResetPasswordPage from '../components/users/reset/ResetPasswordPage';
import ResetPasswordWithTokenPage from '../components/users/reset/ResetPasswordWithTokenPage';
import FacilitiesPage from '../components/facilities/FacilitiesPage';
// import NotFoundPage from '../components/NotFoundPage.js';
import PrivacyPage from '../components/static/PrivacyPage';
import SupportPage from '../components/static/SupportPage';
import PublicTransferPage from '../components/transfers/public/PublicTransfesPage';
import PremiumContent from '../components/landing/PremiumContent';

export default (
    <Route component={App}>
      <Route component={Unauthorized} >
        <Route path='/login' component={LoginPage}/>
        <Route path='/login/:token' component={TokenLoginPage}/>
        <Route path='/change-password' component={preventDirectLinkAccess(ChangePasswordPage)}/>
        <Route path='/reset(/:mode)/:id' component={ResetPasswordWithTokenPage}/>
        <Route path='/reset' component={ResetPasswordPage}/>
        <Route path='/privacy' component={PrivacyPage}/>
        <Route path='/support' component={SupportPage}/>
        <Route path='/view_manifest(/:uuid)' component={PublicTransferPage}/>
      </Route>
      <Route path='/' component={UserIsAuthenticated(Authorized)}>
        <IndexRoute component={LandingPage} permissions={[]}/>
        <Route path='/nopermission' component={NoPermissionPage} permissions={[]}/>
        <Route path='/no-facility-permission' component={NoFacilityPermission} permissions={[]}/>
        <Route path='/premium-content' component={PremiumContent} permissions={[]}/>
        <Route path='/facilities' component={FacilitiesPage} permissions={[]}/>
        <Route component={UserIsAuthorized(PermissionWrapper)}>
          {routes()}
        </Route>
      </Route>
      <Route path='*' component={UserIsAuthenticated(Authorized)}>
        <IndexRoute component={LandingPage} permissions={[]}/>
      </Route>
    </Route>
  );
