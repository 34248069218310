import {createSelector} from 'reselect';
import {I18n} from 'react-redux-i18n';
import {fileOptions} from '../constants/fileTypes';

const getOptions = () => fileOptions;
const getFileTypes = (_, props) => props.types;
const getFiles = (_, props) => props.files;
const getFieldName = (_, props) => props.name;

export const getFileOptions = createSelector(
  [getOptions, getFileTypes],
  (options, fileTypes) => {
    const translatedOptions = options.map(option => {
      return {...option, name: I18n.t(option.name), fieldLabel: I18n.t(option.fieldLabel)};
    });
    return translatedOptions.filter(option => fileTypes.indexOf(option.id) > -1);
  }
);

export const getGroupedFiles = createSelector(
  [getFileOptions, getFiles, getFieldName],
  (options, files, fieldName) => {
    let index = 0;
    return (files || []).reduce(
      (groups, file) => {
        const group = groups.find(group => group.type === file.type);
        if (group) {
          group.fields.push({
            fieldName: `${fieldName}[${index}]`,
            fieldIndex: index,
            file,
          });
          index++;
        }
        return groups;
      },
      options.map(option => ({...option, fields: []}))
    );
  }
);
