import {change} from 'redux-form';
import get from 'lodash.get';

import {TRANSFER_CASH_FORM} from '../../constants/forms';
import {isFormChangeOnField} from './utils';
import {getCurrentRegister} from '../../selectors/forms/transferCashFormSelector';


const transferCashForm = store => next => action => {
  const {meta, payload} = action;

  if (isFormChangeOnField(action, [TRANSFER_CASH_FORM], 'transacted_amount')) {
    const state = store.getState();
    const currentRegister = getCurrentRegister(state);
    const remainAmount = (parseFloat(get(currentRegister, 'current_level', 0)) - parseFloat(payload || 0)).toFixed(2);
    store.dispatch(change(meta.form, 'amount_remain', remainAmount));
  }

  return next(action);
};

export default transferCashForm;