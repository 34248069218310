import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {change, getFormValues} from 'redux-form';
import {push} from 'react-router-redux';
import FormWrapper from '../../common/form/FormWrapper';
import ComplianceSettingsFormWrapper from './ComplianceSettingsFormWrapper';
import WillRender from '../../common/concealers/WillRender';
import * as apiActions from '../../../actions/apiActions';
import {unsetItem} from '../../../actions/itemActions';
import {fetchUseEntityLocks} from '../../../actions/core/settingsActions';
import * as itemNames from '../../../constants/itemNames';
import {
  getInitialValuesForEICompliance as getInitialValuesForEi,
  getInventoryComplianceSettings as getInitialValues,
  getFacilitiesToSelect, isAllowNegativeInventory
} from '../../../selectors/complianceSettingsSelectors';
import * as dataNames from '../../../constants/dataNames';
import {getIntegrationState} from '../../../selectors/integration/integrationSelectors';
import {isFeatureEnabled} from '../../../selectors/featureToggles';
import {isLeafPaConfigPackClosedLoopFacility} from '../../../selectors/facilitiesSelectors';

class InventoryComplianceSettingsPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      mounted: false,
    };
  }

  componentDidMount(){
    this.props.actions.unsetItem(itemNames.complianceSettings);
    this.props.actions.getItem('/api/inventory_status/negative_inventory', itemNames.negativeInventoryStatus);
    this.loadComplianceSettings();
  }

  loadComplianceSettings(){
    if(this.props.facility.type === 'manufacturing'){
      Promise.all([
        this.props.actions.getItem('/api/settings/manufacturing', itemNames.complianceSettings),
        this.props.actions.getUnpaginatedData('/api/categories', dataNames.categories),
      ]);
      return true;
    }
    return this.props.actions.getItem('/api/compliance_settings',itemNames.complianceSettings);
  }

  componentWillReceiveProps(nextProps){
    if(Object.keys(nextProps.complianceSettings).length){
      this.setState({mounted: true});
    }
  }

  getPayload(formData){
    const payload = {settings: []};
    if(this.props.facility.type !== 'manufacturing'){
      payload.settings.push({
        setting_key: 'inv_compliance_settings',
        scope: 'facility',
        value: [formData]
      });
      return payload;
    }
    const settings = {
      inv_compliance_settings: formData.inv_compliance_settings,
      core_facility_coupling: formData.core_facility_coupling.filter(Boolean).map(item => item.id || item),
      mf_exise_tax_settings: formData.mf_exise_tax_settings.filter(item => item.category_id).map(item => ({
        monetary_value: item.monetary_value || '',
        weight_value: item.weight_value || '',
        category_code: item.category_id.category_code || ''
      }))
    };
    for(const setting_key in settings){
      payload.settings.push({
        setting_key,
        scope: 'facility',
        scope_id: this.props.facility.id,
        value: settings[setting_key],
      });
    }
    return payload;
  }


  onSubmit(formData) {
    return this.props.actions
      .onSubmit(this.getPayload(formData), this.props.facility)
      .then(() => {
        this.props.actions.fetchSettings();
        this.props.actions.fetchUseEntityLocks(); // Metrc can change their value here for entity locks.
      })
      .then(this.props.actions.goBack);
  }

  render() {
    const {facilities, initialValues, facility, disableEnableReservationsOption, disableNegativeInventoryOption, hasIntegrator,
      actions, formValues, isMetrc, showPendingStatesSetting, newProductMasterIsEnabled, newPackagingJobsIsEnabled} = this.props;
    const titleSuffix = facility && facility.type
      ? facility.type !== 'manufacturing'
        ? 'componentTitle'
        : 'eiComponentTitle'
      : 'componentTitle';

    return (
      <FormWrapper title={`complianceSettings.inventory.${titleSuffix}`} goBack={this.props.actions.goBack}>
        <WillRender ifTrue={this.state.mounted}>
          <ComplianceSettingsFormWrapper
            onSubmit={this.onSubmit}
            initialValues={initialValues}
            facility={facility}
            facilities={facilities}
            actions={actions}
            formValues={formValues}
            disableEnableReservationsOption={disableEnableReservationsOption}
            disableNegativeInventoryOption={disableNegativeInventoryOption}
            hasIntegrator={hasIntegrator}
            isMetrc={isMetrc}
            showPendingStatesSetting={showPendingStatesSetting}
            newProductMasterIsEnabled={newProductMasterIsEnabled}
            newPackagingJobsIsEnabled={newPackagingJobsIsEnabled}
          />
        </WillRender>
      </FormWrapper>
    );
  }
}

InventoryComplianceSettingsPage.propTypes = {
  state: PropTypes.object,
  actions: PropTypes.shape({
    goBack: PropTypes.func,
    getItem: PropTypes.func,
    postItem: PropTypes.func,
    onSubmit: PropTypes.func,
    fetchSettings: PropTypes.func,
  }),
  complianceSettings: PropTypes.object.isRequired,
  facility: PropTypes.object,
  facilities: PropTypes.array,
  initialValues: PropTypes.object,
  showPendingStatesSetting: PropTypes.bool,
  newProductMasterIsEnabled: PropTypes.bool,
};

function mapStateToProps(state) {
  const formName = 'inv_compliance_settings';
  const {facility} = state;
  const complianceSettings = state[itemNames.complianceSettings];
  const integrationState = getIntegrationState(state);
  const negativeInventoryStatus = state[itemNames.negativeInventoryStatus];
  const hasNegativeInventory = Boolean(negativeInventoryStatus && negativeInventoryStatus.has_negative_inventory);
  const negativeInventoryAllowed = isAllowNegativeInventory(state);
  const disableEnableReservationsOption = isLeafPaConfigPackClosedLoopFacility(state) && isFeatureEnabled(state)('feature_pa_soft_reservations');
  const disableNegativeInventoryOption = (hasNegativeInventory && negativeInventoryAllowed) || integrationState.hasIntegrator;
  const initialValues = facility.type === 'manufacturing' ? getInitialValuesForEi(state) : getInitialValues(state);


  return {
    initialValues,
    complianceSettings,
    disableEnableReservationsOption,
    disableNegativeInventoryOption,
    facility,
    formValues: getFormValues(formName)(state) || initialValues,
    hasIntegrator: integrationState.hasIntegrator,
    facilities: getFacilitiesToSelect(state), // for EI coupling
    isMetrc: integrationState.isMetrc,
    showPendingStatesSetting: isFeatureEnabled(state)('feature_metrc_pending_states_setting'),
    newProductMasterIsEnabled: isFeatureEnabled(state)('feature_new_product_master'),
    newPackagingJobsIsEnabled: isFeatureEnabled(state)('feature_new_packaging_jobs'),
  };
}

function mapDispatchToProps (dispatch) {
  const actions = {
    ...apiActions,
    unsetItem,
    push,

    onSubmit(payload) {
      return actions.saveSettings(payload);
    },

    saveSettings(payload) {
      const messages = {
        success:'retail.complianceSettings.success',
        fail: 'retail.complianceSettings.fail'
      };
      return actions.postItem('/api/compliance_settings', payload, itemNames.complianceSettings, messages);
    },

    fetchSettings() {
      return actions.getItem('/api/compliance_settings', itemNames.complianceSettings);
    },

    goBack() {
      return actions.push('setup/advanced');
    },
    change,
    fetchUseEntityLocks,
  };
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryComplianceSettingsPage);
