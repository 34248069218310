import React from 'react';
import PropTypes from 'prop-types';
import { Modal} from 'react-bootstrap';

const HistoryNotes = ({notes}) => (
  <Modal.Body>
    <p>{notes}</p>
  </Modal.Body>
);

HistoryNotes.propTypes = {
  notes: PropTypes.string,
};

export default HistoryNotes;
