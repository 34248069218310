import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import validate from './validate';

import ComplianceSettingsForm from './ComplianceSettingsForm';
import {getIntegrationState} from '../../../selectors/integration/integrationSelectors';
import {isManufacturingEnabled} from '../../../selectors/modulesSelectors';

const ComplianceSettingsReduxForm = reduxForm({
  form: 'inv_compliance_settings',
  validate,
})(ComplianceSettingsForm);

function mapStateToProps(state) {
  const {isNormal} = getIntegrationState(state);
  return {
    isNormal: isNormal,
    hasManufacturing: isManufacturingEnabled(state),
    state
  };
}

const ComplianceSettingsFormWrapper = connect(mapStateToProps)(ComplianceSettingsReduxForm);

ComplianceSettingsFormWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  hasManufacturing: PropTypes.bool,
  isNormal: PropTypes.bool
};

export default ComplianceSettingsFormWrapper;
