import React from 'react';
import PropTypes from 'prop-types';
import {reduxForm, Field} from 'redux-form';
import {Button, Row} from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';

import PasswordInput from '../../common/form/PasswordInput';
import TextInput from '../../common/form/TextInput';
import validate from './validate';

const ChangePasswordForm = ({handleSubmit, pristine, invalid}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Field
          name='name' component={TextInput}
          props={{
            label: I18n.t('user.Username')
          }}
        />
      </Row>
      <Row>
        <Field
          name='current_password' component={PasswordInput}
          props={{
            label: I18n.t('user.password.currentPassword')
          }}
        />
      </Row>
      <Row>
        <Field
          name='new_password' component={PasswordInput}
          props={{
            label: I18n.t('user.password.newPassword')
          }}
        />
      </Row>
      <Row>
        <Field
          name='confirm_new_password' component={PasswordInput}
          props={{
            label: I18n.t('user.password.confirmNewPassword')
          }}
        />
      </Row>
      <Button type='submit' variant='primary' block disabled={pristine || invalid}>
        {I18n.t('user.password.changePassword')}
      </Button>
    </form>
  );
};

ChangePasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'changePassword',
  validate
})(ChangePasswordForm);
