import React from 'react';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import { bindActionCreators } from 'redux';
import PageTitle from '../../common/PageTitle';
import TablePageWrapper from '../../common/grid/TablePageWrapper';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import * as apiActions from '../../../actions/apiActions';

import {
  transformActiveThirdPartyIntegrations,
  getThirdPartyIntegrationPayload
} from '../../../selectors/integration/thirdPartyIntegrationSelectors';


class ThirdPartyIntegrationsListingPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.redirect = this.redirect.bind(this);
    this.switchTab = this.switchTab.bind(this);
    this.inactivateIntegration = this.inactivateIntegration.bind(this);
  }

  componentWillMount() {
    this.props.actions.getItem('/api/integration-settings', itemNames.integrationSettings, {
      failed: 'stateIntegrators.settings.get.failed'
    });
    this.props.actions.getUnpaginatedData('/api/third-party-integrations', dataNames.thirdPartyIntegrations);
    this.props.actions.getItem(
      '/api/third-party-integrations/active',
      itemNames.activeThirdPartyIntegrations
    );

    this.setState({
      activeTab: 'active'
    });
  }

  inactivateIntegration(event, integration) {
    const { facility } = this.props;
    const integrationKey = `integration_${integration}_settings`;

    this.props.actions.getItem(
      '/api/settings/values/by_key',
      null,
      null,
      {
        ids: [integrationKey]
      },
      (response) => {
        const settings = response[integrationKey];

        if (settings) {
          settings.value.integration = integrationKey;
          settings.value.facility_id = facility.id;
          settings.value.active = false;

          const payload = getThirdPartyIntegrationPayload(settings.value);

          const responses = {
            success: 'facility.setup.thirdPartyIntegration.manage.success',
            failed: 'facility.setup.thirdPartyIntegration.manage.failed'
          };

          this.props.actions.postItem('/api/integration-settings', payload, '', responses, {}, () => {
            // actions.clearMeta(); // TODO: review clearMeta again when needed.
            setTimeout(() => window.location.reload(), 2000);
          });
        }
      }
    );
  }

  redirect(event, integration) {
    this.props.actions.push(`/setup/third-party-integrations/integration_${integration}_settings`);
  }

  switchTab(tab) {
    let listedIntegrations;

    if (tab === 'active') {
      listedIntegrations = this.props.activeIntegrations;
    } else {
      listedIntegrations = this.props.availableIntegrations.filter((integration) => {
        const activeIntegrations = this.props.activeIntegrations.map((integration) => integration.name);
        return activeIntegrations.indexOf(integration.name) === -1;
      });
    }

    this.setState({
      activeTab: tab,
      listedIntegrations: listedIntegrations
    });
  }

  getTabs() {
    return [
      {
        id: 'active',
        eventKey: 'active',
        title: 'nav.active',
        actions: []
      },
      {
        id: 'inactiveTab',
        eventKey: 'inactive',
        title: 'nav.inactive',
        actions: []
      }
    ];
  }

  getColumns() {
    const { activeTab } = this.state;

    const columns = [
      {
        name: 'Integration',
        dataId: 'name',
        formatter: (cell) => I18n.t(`facility.setup.thirdPartyIntegration.integrations.${cell}`),
        width: '300px',
        headerAlign: 'left',
        dataAlign: 'left'
      },
      {
        name: 'Edit',
        // eslint-disable-next-line react/no-multi-comp
        formatter: (cell, integration) => (
          <Button variant='primary' onClick={(event) => this.redirect(event, integration.name)}>
            Edit
          </Button>
        ),
        width: '100px',
        dataSort: false
      }
    ];

    if (activeTab == 'active') {
      columns.push({
        name: 'Change State',
        // eslint-disable-next-line react/no-multi-comp
        formatter: (cell, integration) => (
          <Button variant='primary' onClick={(event) => this.inactivateIntegration(event, integration.name)}>
            Inactivate
          </Button>
        ),
        width: '100px',
        dataSort: false,
        columnClassName: 'third-party-integrations-inactivate'
      });
    }

    return columns;
  }

  render() {
    const { activeTab } = this.state;
    const listedIntegrations = this.state.listedIntegrations
      ? this.state.listedIntegrations
      : this.props.activeIntegrations;

    return (
      <div className='third-party-integrations-listing'>
        <PageTitle primaryText='Third Party Integrations' />
        <TablePageWrapper
          ref={this.ref}
          columns={this.getColumns()}
          data={listedIntegrations}
          tabs={this.getTabs()}
          switchTab={this.switchTab}
          activeTab={activeTab}
          className='third-party-integrations-table'
          hideExport={true}
          hideScanSearch={true}
        />
      </div>
    );
  }
}

ThirdPartyIntegrationsListingPage.propTypes = {
  actions: PropTypes.shape({
    push: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    clearMeta: PropTypes.func.isRequired
  }),
  activeIntegrations: PropTypes.array.isRequired,
  availableIntegrations: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  const facility = state[itemNames.facility];
  const activeIntegrations = transformActiveThirdPartyIntegrations(state);
  const availableIntegrations = state[dataNames.thirdPartyIntegrations];
  const selectedIntegration = state.selectedThirdPartyIntegrationsData;

  for (const key in availableIntegrations) {
    availableIntegrations[key].id = parseInt(key);
  }

  return {
    facility,
    activeIntegrations,
    availableIntegrations,
    selectedIntegration: selectedIntegration ? selectedIntegration[0] : null
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, { push });

  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThirdPartyIntegrationsListingPage);
