import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Button, Col,Row} from 'react-bootstrap';
import {FaTrash} from 'react-icons/fa';
import {Field, FieldArray} from 'redux-form';
import TextInput from '../../common/form/TextInput';
import MotherPlantPackagesFieldArray from './MotherPlantPackagesFieldArray';


const MotherPlantsFieldArray = (props) => {
  const {fields, itemMasters, locations, trackingTags} = props;

  return (
    <div>
      {fields.map((fName, fIndex) => {
        const value = fields.get(fIndex);
        const {strain_id} = value;
        return (
          <Row key={fIndex}>
            <Col xs={5} sm={4} md={3}>
              <Field name={`${fName}.batch_name`} component={TextInput} props={{
                label: I18n.t('plants.packageMothers.form.batch'),
                disabled: true,
              }}/>
            </Col>
            <Col xs={5} sm={4} md={3}>
              <Field name={`${fName}.plant_name`} component={TextInput} props={{
                label: I18n.t('plants.packageMothers.form.plantId'),
                disabled: true,
              }}/>
            </Col>
            <Col xs={5} sm={4} md={3}>
              <Field name={`${fName}.strain_name`} component={TextInput} props={{
                label: I18n.t('plants.packageMothers.form.strainName'),
                disabled: true,
              }}/>
            </Col>
            <Col xs={2} md={1} style={{paddingTop: '28px'}}>
              <Button variant='danger' size='sm' onClick={() => fields.remove(fIndex)} disabled={fields.length < 2}>
                <FaTrash />
              </Button>
            </Col>
            <Col xs={12}>
              <FieldArray name={`${fName}.packages`} component={MotherPlantPackagesFieldArray} props={{
                itemMasters: itemMasters[strain_id] || [],
                locations: locations,
                trackingTags,
              }}/>
            </Col>
            <Col xs={12}>
              <hr/>
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

MotherPlantsFieldArray.propTypes = {
  fields: PropTypes.shape({
    get: PropTypes.func.isRequired,
    map: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
  }).isRequired,
  itemMasters: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  trackingTags: PropTypes.array,
};

export default MotherPlantsFieldArray;
