import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Row, Button} from 'react-bootstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as apiActions from '../../actions/apiActions';

const ensureExtension = (fileName, ext = 'pdf') => {
  const reg = new RegExp(`^(.)+\\.${ext}$`);
  return reg.test(fileName) ? fileName : `${fileName}.${ext}`;
};


class NotificationLink extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {buttonDisabled: false};
    this.download = this.downloadFile.bind(this);
  }

  downloadFile(url, filename) {
    this.setState({buttonDisabled: true});
    this.props.actions.getFile(url, ensureExtension(filename), {failed: 'notification.download.failed'})
      .then(() => this.setState({buttonDisabled: false}))
      .catch(() => this.setState({buttonDisabled: false}));
  }

  render(){
    const {targetLink, targetName} = this.props;
    return (
      <Row>
        <Col xs={12}>
          {targetLink.startsWith('/api') ?
            <Button variant='success' disabled={this.state.buttonDisabled} onClick={() => {
              this.downloadFile(targetLink, targetName);
            }}>
              {targetName ? `${I18n.t('notification.table.download')} ${targetName}` : I18n.t('notification.table.download')}
            </Button>
            :
            <strong>{I18n.t('notifications.table.link')}: &nbsp;
              <a href={targetLink}>
                {targetName ? targetName : 'link'}
              </a>
            </strong>
          }
      </Col>
    </Row>);
  }

}

NotificationLink.propTypes = {
  targetLink: PropTypes.string.isRequired,
  targetName: PropTypes.string
};

function mapStateToProps(state, ownProps){
  return ownProps;
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...apiActions,
  };
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationLink);
