import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {Button} from 'react-bootstrap';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';

import * as dataNames  from '../../../constants/dataNames';
import { clearSelectedData } from '../../../actions/selectedDataActions';
import { handleComplexSelectRow } from '../../../actions/helpers/selectedDataHelper';

import {
  getSelectedPricingClass,
  getPricingClassesListingData
} from '../../../selectors/pricingClassSelectors';

import PageTitle from '../../common/PageTitle';
import TablePageWrapper from '../../common/grid/TablePageWrapper';

import {
    unsetData
} from '../../../actions/dataActions';

import {
  putData,
  getUnpaginatedData
} from '../../../actions/apiActions';

class PricingClassesListingPage extends React.PureComponent {
  constructor (props) {
    super(props);

    this.switchTab = this.switchTab.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.editPricingClass = this.editPricingClass.bind(this);
    this.createPricingClass = this.createPricingClass.bind(this);
  }

  componentWillMount () {
    this.setState({
      activeTab: 'active'
    });

    this.props.actions.getUnpaginatedData('/api/pricing_classes', dataNames.pricingClasses);
  }

  componentWillUnmount () {
    this.props.actions.unsetData(dataNames.pricingClasses);
  }

  inactivatePricingClass (event, pricingClass) {
    event.stopPropagation();

    pricingClass.active = 0;

    const responses = {
      success: 'retail.pricingClass.modify.success',
      failed: 'retail.pricingClass.modify.failed'
    };

    this.props.actions.putData(`/api/pricing_classes/${pricingClass.id}`, pricingClass, dataNames.pricingClasses, responses);
  }

  activatePricingClass (event, pricingClass) {
    event.stopPropagation();

    pricingClass.active = 1;

    const responses = {
      success: 'retail.pricingClass.modify.success',
      failed: 'retail.pricingClass.modify.failed'
    };

    this.props.actions.putData(`/api/pricing_classes/${pricingClass.id}`, pricingClass, dataNames.pricingClasses, responses);
  }

  createPricingClass () {
    this.props.actions.push('/retail/pricing-classes/create');
  }

  handleSelect (isSelected, pricingClass) {
    this.props.actions.handleComplexSelectRow(pricingClass, dataNames.pricingClassData, 'set');
  }

  switchTab (tab) {
    this.props.actions.clearSelectedData(dataNames.pricingClasses);
    this.props.actions.push(`/retail/pricing-classes/${tab}`);
    this.setState({activeTab: tab});
  }

  editPricingClass () {
    const { selectedPricingClassData } = this.props;
    this.props.actions.push(`/retail/pricing-classes/modify/${selectedPricingClassData[0]}`);
  }

  getTabs () {
    return [
      {
        id: 'active',
        eventKey: 'active',
        title: 'nav.active',
        actions: this.getActiveTabActions()
      },
      {
        id: 'inactiveTab',
        eventKey: 'inactive',
        title: 'nav.inactive',
        actions: this.getInactiveTabActions()
      }
    ];
  }

  getInactiveTabActions () {
    return [
      {
        id: 'createPricingClass',
        func: this.createPricingClass,
        text: 'Create Pricing Class',
        requireSelect: false,
        glyph: 'plus-sign'
      }
    ];
  }

  getActiveTabActions () {
    return [
      {
        id: 'createPricingClass',
        func: this.createPricingClass,
        text: 'Create Pricing Class',
        requireSelect: false,
        glyph: 'plus-sign'
      },

      {
        id: 'editPricingClass',
        func: this.editPricingClass,
        text: 'Edit',
        requireSelect: true,
        glyph: 'edit'
      }
    ];
  }

  getColumns () {
    const baseColumns = [
      {
        name: I18n.t('retail.pricingClass.className'),
        dataId: 'name',
        headerAlign: 'left',
        dataAlign: 'left',
      },
      {
        name: I18n.t('retail.pricingClass.facilities'),
        dataId: 'facility_name',
        width: '40%',
        headerAlign: 'left',
        dataAlign: 'left',
      },
    ];

    const activeTabColumn = {
      formatter: (cell, pricingClass) => ( // eslint-disable-line
        <Button variant='primary' onClick={event => this.inactivatePricingClass(event, pricingClass)}>
          {I18n.t('common.inactivate')}
        </Button>
      ),
      width: '100px',
      dataSort: false
    };

    const inactiveTabColumn = {
      formatter: (cell, pricingClass) => ( //eslint-disable-line
        <Button variant='primary' onClick={event => this.activatePricingClass(event, pricingClass)}>
          {I18n.t('common.activate')}
        </Button>
      ),
      width: '100px',
      dataSort: false
    };

    const { activeTab } = this.state;

    if (activeTab === 'active') {
      baseColumns.push(activeTabColumn);
    } else {
      baseColumns.push(inactiveTabColumn);
    }

    return baseColumns;
  }

  render () {
    const { activeTab } = this.state;
    const { selectedPricingClassData, pricingClasses} = this.props;

    return (
      <div className='pricing-classes-listing'>
        <PageTitle primaryText={I18n.t('retail.pricingClass.title')}/>
        <TablePageWrapper
          ref={this.ref}
          settingKey='retail-pricing-classes'
          columns = {this.getColumns()}
          tabs={this.getTabs()}
          switchTab={this.switchTab}
          activeTab={activeTab}
          data={pricingClasses}
          hideExport={true}
          hideScanSearch={true}
          handleSelect={this.handleSelect}
          selectedRows={selectedPricingClassData}
          external={false}
          className='retail-pricing-classes-table'
          bstProps={{
            ignoreSinglePage: true,
            selectRow: {
              mode: 'radio'
            },
            options : {
              defaultSortName: 'name',
              defaultSortOrder: 'asc',
            }
          }}
        />
      </div>
    );
  }
}

PricingClassesListingPage.propTypes = {
  actions: PropTypes.shape({
    handleComplexSelectRow: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    putData: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired
  }),
  pricingClasses: PropTypes.array.isRequired,
  selectedPricingClassData: PropTypes.array.isRequired,
};

function mapDispatchToProps (dispatch) {
  const actions = {
    push,
    putData,
    unsetData,
    clearSelectedData,
    getUnpaginatedData,
    handleComplexSelectRow
  };

  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps (state, ownProps) {
  const pricingClasses = getPricingClassesListingData(state, {status: ownProps.params.active});
  const selectedPricingClassData = getSelectedPricingClass(state);

  return {
    pricingClasses,
    selectedPricingClassData
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PricingClassesListingPage);
