/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import {FormLabel, FormControl, FormGroup, InputGroup, Card} from 'react-bootstrap';
import {FaTimes} from 'react-icons/fa';
import * as apiActions from '../../../actions/apiActions';
import * as dataNames from '../../../constants/dataNames';
import {searchUserRoles} from '../../../actions/userActions';
import {
  getManageUserRolesInitialValues, getManageUserRolesPayload,
  getManageUserRolesFormRoles,
  isAddRoleDisabled,
} from '../../../selectors/forms/manageUserRolesFormSelectors';
import InProgressOverlay from '../../common/InProgressOverlay';
import FormWrapper from '../../common/form/FormWrapper';
import ManageUserRolesFormWrapper from './ManageUserRolesFormWrapper';


export class UserRolesPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      loading: true,
      saving: false,
      searchInput: ''
    };

    this.redirect = this.redirect.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount() {
    Promise
      .all([
        this.props.actions.getUnpaginatedData('/api/permissions', dataNames.permissions),
        this.props.actions.getUnpaginatedData('/api/roles', dataNames.roles),
        this.props.actions.getUnpaginatedData('/api/modules', dataNames.modules, null, {enabled: 1}),
      ])
      .then(() => this.setState({loading: false}))
      .catch(() => this.setState({loading: false}));
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      roles: nextProps.roles
    });
  }

  redirect() {
    this.props.actions.goBack();
  }

  onSubmit(formData) {
    const payload = getManageUserRolesPayload(formData, this.props.initialValues);
    this.setState({saving: true});
    return this.props.actions.postItem('/api/roles/multiple', payload, dataNames.roles, {success: 'userRoles.save.success', failed: 'userRoles.save.failed'})
      .then(this.redirect)
      .catch(() => this.setState({saving: false}));
  }

  render() {
    const {initialValues, roles, addRoleDisabled} = this.props;
    const {loading, saving, searchInput} = this.state;
    const message = loading ? 'common.form.loading' : 'common.form.saving';

    return (
      <React.Fragment>
        <Card className='user-roles-search'>
          <Card.Body>
            <FormGroup controlId='search_user_roles'>
              <FormLabel>{I18n.t('userRoles.searchByName')}</FormLabel>
              <InputGroup>
                <FormControl type='text'
                  name='user_roles_search_str'
                  value={searchInput}
                  onChange={(evt) => {
                    const value = evt.target.value;
                    this.setState({searchInput: value});
                    if (value.length === 0 || value.length >= 3) {
                      this.props.actions.searchUserRoles(value);
                    }
                  }}
                  placeholder={I18n.t('userRoles.searchPlaceholder')}
                />
                <InputGroup.Append>
                  <FaTimes
                    onClick={() => {
                      this.setState({searchInput: ''});
                      this.props.actions.searchUserRoles('');
                    }}
                  />
                </InputGroup.Append>
              </InputGroup>
            </FormGroup>
          </Card.Body>
        </Card>

        <FormWrapper className='user-roles-form' title='userRoles.title' goBack={this.redirect}>
          <InProgressOverlay
            isActive={loading || saving}
            message={message}
            showOk={false}
            showLoader={true}
            translate={true}
          />

          <ManageUserRolesFormWrapper
            onSubmit={this.onSubmit}
            initialValues={initialValues}
            roles={roles}
            addRoleDisabled={addRoleDisabled}
          />
        </FormWrapper>
      </React.Fragment>
    );
  }
}

UserRolesPage.propTypes = {
  actions: PropTypes.shape({
    getUnpaginatedData: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    deleteItem: PropTypes.func.isRequired,
    deleteData: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    searchUserRoles: PropTypes.func.isRequired
  }),
  roles: PropTypes.array.isRequired,
  initialValues: PropTypes.object.isRequired,
  addRoleDisabled: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    initialValues: getManageUserRolesInitialValues(state),
    roles: getManageUserRolesFormRoles(state) || [],
    addRoleDisabled: isAddRoleDisabled(state)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {goBack,searchUserRoles});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserRolesPage);
