import React from 'react';
import PropTypes from 'prop-types';
//import {I18n} from 'react-redux-i18n';

const RowFormatters = (props) => {

  const currentDefinition = props.definitions[props.currentDefinitionIndex] !== undefined
    ? props.definitions[props.currentDefinitionIndex]
    : false;

  const isDefault = props.definitions.find((def, index) => def.name === 'Default' && index === props.currentDefinitionIndex);

  const getFormatValueText = (key, subkey) => {

    if(props.formatValues[key] === undefined) return currentDefinition.definition[props.rowIndex].outputFormat[key];
    if(props.formatValues[key].values === undefined) return currentDefinition.definition[props.rowIndex].outputFormat[key];
    if(!Array.isArray(props.formatValues[key].values)) return currentDefinition.definition[props.rowIndex].outputFormat[key];

    const value = props.formatValues[key].values.find((value) => {
      if(subkey !== undefined) return (value.value === subkey);
      return (value.value === currentDefinition.definition[props.rowIndex].outputFormat[key] || value.value === key);
    });

    if(value) return value.text;
    return currentDefinition.definition[props.rowIndex].outputFormat[key];

  };

  const getFormatLabel = (key) => {
    if(props.formatSettings[key] !== undefined) return props.formatSettings[key];
    const labelAndValue = Object.keys(props.formatValues).reduce((acc, fkey) => {
      if(Object.keys(acc).length !== 0) return acc;
      if(Array.isArray(props.formatValues[fkey].values)){
        const option = props.formatValues[fkey].values.find((option) => option.value === key);
        if(option){
          acc.text = getFormatLabel(fkey);
          acc.value = getFormatValueText(fkey, key);
        }
      }
      return acc;
    }, {});
    if(Object.keys(labelAndValue).length !== 0) return labelAndValue;
    return key;
  };

  const outputFormat = currentDefinition.definition.length === 0
    ? []
    : currentDefinition.definition[props.rowIndex].outputFormat !== undefined
      ? Object.keys(currentDefinition.definition[props.rowIndex].outputFormat).reduce((acc, key) => {
        const formatLabel = (props.formatSettings[key] !== undefined) ? props.formatSettings[key] : key;
        const formatValue = getFormatValueText(key);
        acc.push({text: formatLabel, value: formatValue});
        return acc;
      }, [])
      : [];

  const convertFormat = (format) => {
    const fields = format.split(',');
    return fields.reduce((acc, field) => {
      const result = getFormatLabel(field);
      if(Object.keys(result).length > 0){
        acc.push(result);
      } else {
        acc.push({text: getFormatLabel(field), value: 1});
      }
      return acc;
    }, []);
  };

  const format = !currentDefinition
    ? []
    : currentDefinition.definition[props.rowIndex].format !== undefined
      ? convertFormat(currentDefinition.definition[props.rowIndex].format)
      : [];

  const formats = outputFormat.concat(format);

  return (
    <div>
      {
        !isDefault
        ? formats.length === 0
          ? (<a onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            props.onEdit('formats', props.rowIndex);
            event.target.blur();}}>
              Edit
            </a>)
          : formats.map((format, index) => {
            const formatText = (format && typeof format !== 'string')
              ? format.text !== undefined
                ? format.text
                : 'Edit'
              : 'Edit';
            return (
              <div key={index}>
                <a onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  props.onEdit('formats', props.rowIndex);
                  event.target.blur();
                }}>
                  {formatText}
                </a>
                <span className='float-right'>{format.value}</span>
              </div>
            );
          })
          : formats.map((format, index) => {
            return (
              <div key={index}>
                  {format.text}
                <span className='float-right'>{format.value}</span>
              </div>
            );
          })
      }
    </div>
  );
};

RowFormatters.propTypes = {
  definitions: PropTypes.array.isRequired,
  currentDefinitionIndex: PropTypes.number.isRequired,
  rowIndex: PropTypes.number.isRequired,
  formatSettings: PropTypes.object.isRequired,
  formatValues: PropTypes.object.isRequired,
};

export default RowFormatters;
