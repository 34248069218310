import React from 'react';
import PropTypes from 'prop-types';
import {FieldArray, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {Button, ButtonToolbar, Col, Row} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import PackagesFieldArray from '../packages/PackagesFieldArray';
import FormWrapper from '../../common/form/FormWrapper';
import InProgressOverlay from '../../common/InProgressOverlay'; // eslint-disable-line no-unused-vars
import {PACKAGING_JOB_FORM} from '../../../constants/forms';
import InputProductPanel from './InputProductPanel';
import PackagingJobDetailPanel from './PackagingJobDetailPanel';
import PackagingJobWeightPanel from './PackagingJobWeightPanel';
import validate from './validate';


const PackagingJobForm = (props) => {

  const {actions, loading, item, onSearchComplete, integrationState, trackingIds, change, formValues, itemMasterOptions,
    ingredients, ingredientsEnabled, locations, currentFacilityUsers, isDisabled, handleSubmit, reset, submitting, pristine, valid} = props;

  return (
    <FormWrapper title={'packagingJob.title'} className='packaging-job-form-wrapper' goBack={actions.goBack} formName={PACKAGING_JOB_FORM}>
      <form onSubmit={handleSubmit} noValidate={true}>
        <InProgressOverlay isActive={loading}/>
        <InputProductPanel
          item={item}
          disabled={isDisabled}
          onSearchComplete={onSearchComplete}/>
        <FieldArray
          name='packages'
          component={PackagesFieldArray}
          props={{
            integrationState: integrationState,
            trackingIds: trackingIds,
            change: change,
            formValues: formValues,
            itemMasterOptions: itemMasterOptions,
            ingredients: ingredients,
            ingredientsEnabled: ingredientsEnabled,
            isDisabled: isDisabled,
          }}/>
        <Row>
          <Col xs={12} sm={6} style={{ borderRight: '1px solid #ccc' }}>
            <PackagingJobDetailPanel locations={locations} currentFacilityUsers={currentFacilityUsers} disabled={isDisabled} />
          </Col>
          <Col>
            <PackagingJobWeightPanel disabled={isDisabled} />
          </Col>
        </Row>
        <Row className='border-top padding-all'>
          <Col xs={12}>
            <ButtonToolbar className='float-right'>
              <Button type='reset'
                      onClick={reset}
                      disabled={submitting || pristine || !valid || isDisabled}>
                {I18n.t('packages.form.reset')}
              </Button>
              <Button variant='primary'
                      type='submit'
                      onClick={() => {
                        if (!isDisabled) {
                          change('action', 'complete')
                        } else {
                          change('action', 'print')
                        }
                      }}
                      disabled={submitting || pristine || !valid}>
                {!isDisabled ? I18n.t('common.saveAndPrintLabels') : I18n.t('common.printLabels')}
              </Button>
              <Button variant='info'
                      type='submit'
                      onClick={() => change('action', 'save')}
                      disabled={submitting || pristine || isDisabled || !valid}>
                {I18n.t('common.form.saveInProgress')}
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>
      </form>
    </FormWrapper>
  );

};

PackagingJobForm.propTypes = {
  actions: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  onSearchComplete: PropTypes.func.isRequired,
  integrationState: PropTypes.object.isRequired,
  trackingIds: PropTypes.array,
  change: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  itemMasterOptions: PropTypes.array,
  ingredients: PropTypes.array,
  ingredientsEnabled: PropTypes.bool.isRequired,
  locations: PropTypes.array.isRequired,
  currentFacilityUsers: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool.isRequired
};

const PackagingJobReduxForm = reduxForm({
  form: PACKAGING_JOB_FORM,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(PackagingJobForm);

function mapStateToProps(state, ownProps) {
  return {
  };
}

export default connect(mapStateToProps)(PackagingJobReduxForm);
