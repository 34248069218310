import React from 'react';
import PropTypes from 'prop-types';

import {I18n} from 'react-redux-i18n';
import {Modal, Button} from 'react-bootstrap';

const BirthdateModal = ({showModal, hideModal, patients, onClick}) => {
  return (
    <Modal show={showModal} onHide={hideModal} className='check-in-modal'>
      <Modal.Header closeButton>
        <h3>{I18n.t('modal.selectPatient')}</h3>
      </Modal.Header>
      <Modal.Body>
        {patients ? patients.map((patient, index) =>
          <Button block
            key={patient.patient_id}
            onClick={() => onClick(index)}>
            {`${patient.patient_id} - ${patient.patient_name} - ${patient.patient_dob_yyyy_mm_dd} - ${patient.patient_address1}`}
          </Button>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

BirthdateModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  patients: PropTypes.array.isRequired,
  onClick: PropTypes.func
};


export default BirthdateModal;
