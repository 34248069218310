/* eslint-disable import/no-named-as-default*/
import {connect} from 'react-redux';
import {reduxForm, formValueSelector} from 'redux-form';
import validate from './validate';
import SharedFacilityGroupForm from './SharedFacilityGroupForm';

export const SharedFacilityGroupFormWrapper = reduxForm({
  validate,
  enableReinitialize: true
})(SharedFacilityGroupForm);

export default connect((state, props) => {
  const formSelector = formValueSelector(props.form);
  const selectedFacilities = formSelector(state, 'facilities') || [];
  const masterFacility = selectedFacilities.find(el => el.id === formSelector(state, 'masterFacilityId'));
  return {
    selectedFacilities,
    masterFacility
  };
})(SharedFacilityGroupFormWrapper);
