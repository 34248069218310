import {change, getFormValues} from 'redux-form';
import {COMPLETE_INFUSION_FORM} from '../../constants/forms';
import {isFormChangeAction} from './utils';
import {getIntegrationState} from '../../selectors/integration/integrationSelectors';
import {calculateWaste} from '../../selectors/assemblyJobsSelectors';

const completeInfusionForm = store => next => action => {
  const result = next(action);
  const state = store.getState();
  const {isBiotrack} = getIntegrationState(state);
  const {meta} = action;



  if (isFormChangeAction(action, [COMPLETE_INFUSION_FORM]) && isBiotrack) {
    const formValues = getFormValues(COMPLETE_INFUSION_FORM)(state);

    const fieldName = meta.field.replace(/\[\d+\]/ig, '');
    if (['inputs.qty', 'outputs.qty'].includes(fieldName)) {
      const MANUALLY_REPORTED_WASTE = 0; // TODO Change after manually reported waste will be fixed
      const autoWaste = calculateWaste(formValues.inputs, MANUALLY_REPORTED_WASTE, formValues.outputs[0]);

      store.dispatch(change(action.meta.form, 'waste', autoWaste));
    }
  }



  return result;
};


export default completeInfusionForm;
