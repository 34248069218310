import React from 'react';
import PropTypes from 'prop-types';

import AccordionPanel from '../../common/AccordionPanel';
import OnlineAvailabilityPanel from '../common/sales/OnlineAvailabilityPanel';
import {RETAIL, WHOLESALE} from '../../../constants/saleTypes';
import {MEDICAL, RECREATIONAL} from '../../../constants/consumerTypes';
import PricingPanel from '../common/sales/PricingPanel';
import {PRODUCT_MASS_MODIFY} from '../../../constants/forms';

const SalesInformationSection = (props) => {
  const {retailFacilityOptions, onlineAvailabilityOptions, pricingWeights, pricingClasses, pricingGroupOptions, category_id,
    facilityOptions, weight_type, showCategoryError, fieldCanModifiedMap} = props;

  return (
    <AccordionPanel title={'products.massModify.salesInformationTitle'}>
      <span id={'sales_section'}/>
      <OnlineAvailabilityPanel
        onlineAvailabilityOptions={onlineAvailabilityOptions}
        facilityOptions={retailFacilityOptions}
        isRequired={false}
        fieldCanModifiedMap={fieldCanModifiedMap}
      />

      <PricingPanel
        title='products.form.medicalPrices'
        sale_type={RETAIL}
        consumer_type={MEDICAL}
        pricing_type={weight_type}
        pricingWeights={pricingWeights}
        pricingClasses={pricingClasses}
        pricingGroupOptions={pricingGroupOptions}
        facilityOptions={facilityOptions}
        retailFacilityOptions={retailFacilityOptions}
        categoryId={category_id}
        isMassModifyForm={true}
        showError={showCategoryError}
        fieldCanModifiedMap={fieldCanModifiedMap}
        formName={PRODUCT_MASS_MODIFY}
      />

      <PricingPanel
        title='products.form.recreationalPrices'
        sale_type={RETAIL}
        consumer_type={RECREATIONAL}
        pricing_type={weight_type}
        pricingWeights={pricingWeights}
        pricingClasses={pricingClasses}
        pricingGroupOptions={pricingGroupOptions}
        facilityOptions={facilityOptions}
        retailFacilityOptions={retailFacilityOptions}
        categoryId={category_id}
        isMassModifyForm={true}
        showError={showCategoryError}
        fieldCanModifiedMap={fieldCanModifiedMap}
        formName={PRODUCT_MASS_MODIFY}
      />

      <PricingPanel
        title='products.form.wholesalePrices'
        sale_type={WHOLESALE}
        consumer_type={null}
        pricing_type={weight_type}
        pricingWeights={pricingWeights}
        pricingGroupOptions={pricingGroupOptions}
        facilityOptions={facilityOptions}
        retailFacilityOptions={retailFacilityOptions}
        categoryId={category_id}
        pricingClasses={[]}
        isMassModifyForm={true}
        showError={showCategoryError}
        fieldCanModifiedMap={fieldCanModifiedMap}
        formName={PRODUCT_MASS_MODIFY}
      />

    </AccordionPanel>
  );
};

SalesInformationSection.propTypes = {
  retailFacilityOptions: PropTypes.array.isRequired,
  onlineAvailabilityOptions: PropTypes.array.isRequired,
  pricingWeights: PropTypes.array.isRequired,
  pricingClasses: PropTypes.array.isRequired,
  category_id: PropTypes.number.isRequired,
  pricingGroupOptions: PropTypes.array.isRequired,
  facilityOptions: PropTypes.array.isRequired,
  weight_type: PropTypes.string.isRequired,
  showCategoryError: PropTypes.bool.isRequired,
  fieldCanModifiedMap:  PropTypes.object.isRequired
};

export default SalesInformationSection;