import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';
import {FaRegCheckSquare, FaRegSquare} from 'react-icons/fa';

const FacilityMenuItem = ({facilityName, facilityAddress, isChecked = false}) => {
  return (
    <Row className='facility-menu-item' >
      <Col md={10} >
        <strong>{facilityName}</strong><br />
        <div title={facilityAddress} className='facility-address'>{facilityAddress}</div>
      </Col>
      <Col md={2}>
        {isChecked ? <FaRegCheckSquare/> : <FaRegSquare/>}
      </Col>
    </Row>
  );
};

FacilityMenuItem.propTypes = {
  facilityName: PropTypes.string.isRequired,
  facilityAddress: PropTypes.string.isRequired,
  isChecked: PropTypes.bool
};

export default FacilityMenuItem;
