/**
 * Returns an object to be set in local state with ids that may be used as params to back end requests
 * @param searchResultItems
 * @returns {({lotIdsFromSearch: *} & {itemIdsFromSearch: *}) | {lotIdsFromSearch: *} | {itemIdsFromSearch: *} | {}}
 */
export const getSearchResultIdConstraintsFromSearchResult = (searchResultItems) => {
  const {lotIds, itemIds} = searchResultItems.reduce((acc, item) => {
    if (item.lot_id) {
      acc.lotIds.push(item.lot_id);
    }
    if (item.item_id) {
      acc.itemIds.push(item.item_id);
    }
    return acc;
  }, {lotIds: [], itemIds: []});
  return {
    lotIds: Array.from(new Set(lotIds)),
    itemIds: Array.from(new Set(itemIds)),
  };
};

export default getSearchResultIdConstraintsFromSearchResult;
