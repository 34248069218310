import {createSelector} from 'reselect';
import * as dataNames from '../constants/dataNames';
import {convertDbDateToFormInputDate, formatClientDate} from '../util/dateHelpers';
import {getPhases} from './phasesSelectors';
import {getTimezone} from './timezoneSelectors';
import {getPartners} from './partnersSelectors';
import {getExternalIdByItemId, getMappingsByPackage} from './inventoryItemsSelectors';
import {getPurposes, getPurposeByKey} from './purposesSelectors';

export const getPackageEventsRaw = (state) => state[dataNames.packageEvents];
export const getItemEventsRaw = (state) => state[dataNames.itemEvents];


export const getPackageEvents = createSelector(
  [getPackageEventsRaw, getTimezone, getPhases, getPartners, getMappingsByPackage, getPurposes],
  mapEvents
);

export const getItemEvents = createSelector(
  [getItemEventsRaw, getTimezone, getPhases, getPartners, getMappingsByPackage, getPurposes],
  mapEvents
);

//Used internally so expects non-standard state object
const getEvents = state => state.events;
const getPackageId = state => state.package_id;
export const getLabResultEvents = createSelector(
  [getEvents, getPackageId],
  (events, packageId) => {
    const eventTypes = ['LAB_RESULT_ASSIGNED', 'LAB_RESULT_UNASSIGNED', 'tag_update'];
    return events
      .filter((event) => {
        return event.entity_id === packageId && eventTypes.indexOf(event.event_type) > -1;
      });
  }
);

function mapEvents(events, timezone, phases, partners, mappings, purposes) {
  return events.map((event) => {
    const model = event.model ? JSON.parse(event.model) : null;

    model.packaged_at = convertDbDateToFormInputDate(model.packaged_at, timezone);
    model.package_created_at = formatClientDate(model.package_created_at);
    model.package_expires_at = formatClientDate(model.package_expires_at);
    model.external_id = getExternalIdByItemId(mappings, model.id);

    if (model && model.transaction && model.transaction.phase_id) {
      model.phase = phases.find(phase => phase.id === model.transaction.phase_id);
    }
    model.purpose_name = getPurposeByKey(purposes, model.purpose);
    if (model && model.package && model.package.lot && model.package.lot.producer_id) {
      const partner = partners.find(partner => partner.id ===  model.package.lot.producer_id);
      model.partner_name = partner && partner.name;
    }
    if (model && model.lab_results) {
      const partner = partners.find(partner => partner.id === model.lab_results.lab_partner_id);
      model.testing_date = convertDbDateToFormInputDate(model.lab_results.testing_date, timezone);
      model.testing_id = model.lab_results.testing_id;
      model.partner_name = partner && partner.name;
    }
    if (model.transaction && model.transaction.new_item) {
      model.transaction.new_item.purpose_name = getPurposeByKey(purposes, model.transaction.new_item.purpose);
    }
    return {
      ...event,
      model,
      event_date_formatted: convertDbDateToFormInputDate(event['event_date'], timezone),
      created_at_formatted: convertDbDateToFormInputDate(event['created_at'], timezone),
      created_at_sort: `${event['created_at']} ${event['id']}`,
      event_date_sort: `${event['event_date']} ${event['id']}`,
    };
  });
}
