import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import {FaDollarSign} from 'react-icons/fa';
import NumberInput from '../../common/form/NumericInput';
import TextInput from '../../common/form/TextInput';
import AddItem from '../../common/form/AddItem';

const InventorySettingsRow = ({fields}) => {
  return(
    <div className='inventory-settings-row'>
      {fields.map((fieldName, index) => {
        return(
            <Row key={index}>
              <Col md={3} sm={3} xs={3}>
                <Field
                  name={`miltipack-${index}`}
                  component={TextInput}
                  props={{
                    label:I18n.t('products.infoMod.miltipack'),
                    placeholder:I18n.t('products.infoMod.placeholderName')
                  }}
                />
              </Col>
              <Col md={3} sm={3} xs={3}>
                <Field
                  name={`unitCount-${index}`}
                  component={TextInput}
                  props={{
                    label:I18n.t('products.infoMod.unitCount'),
                    placeholder:I18n.t('products.infoMod.placeholderCount')
                  }}
                />
              </Col>
              <Col md={3} sm={3} xs={3}>
                <Field
                  name={`requiredQuantity-${index}`}
                  component={TextInput}
                  props={{
                    label:I18n.t('products.infoMod.requiredQuantity'),
                    placeholder:I18n.t('products.infoMod.placeholderQuantity')
                  }}
                />
              </Col>
              <Col md={3} sm={3} xs={3}>
                <Field
                  name={`cost-${index}`}
                  component={NumberInput}
                  props={{
                    label:I18n.t('products.infoMod.cost'),
                    leftAddon:<FaDollarSign />,
                    placeholder:I18n.t('products.infoMod.placeholderCost')
                  }}
                />
              </Col>
            </Row>
          );
      })}
      <Row>
        <AddItem text='products.infoMod.addMultiPackInfo' addAction={() => fields.push({})} textMode='left' alignMode='right'/>
      </Row>
  </div>
  );
};

InventorySettingsRow.propTypes = {
  fields:PropTypes.object
};


export default InventorySettingsRow;
