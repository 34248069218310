import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Button} from 'react-bootstrap';
import {FaRegCopy} from 'react-icons/fa';
import {Field} from 'redux-form';
import get from 'lodash.get';
import NumericInput from '../../../common/form/NumericInput';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import {info} from '../../../../constants/messageTypes';


class Payments extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    const {fields, registers, order, reference, patientId, addMessage, isRefund} = this.props;

    const copyToClipboard = (text) => {
      const tempInput = document.createElement('input');
      tempInput.value = text;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand('copy');
      document.body.removeChild(tempInput);
      tempInput.remove();
      return true;
    };

    const copyReferenceToClipboard = () => {
      copyToClipboard(reference);
      addMessage(info, ['cart.mmapPayment.copiedReference']);
    };

    const copyPatientIdToClipboard = () => {
      copyToClipboard(patientId);
      addMessage(info, ['cart.mmapPayment.copiedPatientId']);
    };

    const mmapOriginalOrderAmount = get(order, 'mmap_details.original_order.mmap_payment_amount');
    const mmapRefundedOrdersAmount = -1 * get(order, 'mmap_details.refunded_orders', []).reduce((acc, refunded_order) => acc + get(refunded_order, 'mmap_payment_amount', 0), 0);
    const mmapCurrentOrderAmount = -1 * get(order, 'mmap_details.current_order.mmap_payment_amount');
    const mmapPaymentAfterRefund = mmapOriginalOrderAmount - mmapRefundedOrdersAmount - mmapCurrentOrderAmount;

    return (
      <tbody className='order-payments'>
      {fields.map((fieldNamePrefix, index) => {

        return (
          <React.Fragment key={index}>
            <tr>
              <td className={'align-top'}>
                <Field
                  name={`${fieldNamePrefix}.type`}
                  component={ReactSelectInput}
                  props={{
                    options: [{text: 'MMAP', value: 'mmap'}],
                    disabled: true,
                    textKey: 'text',
                    valueKey: 'value',
                    value: 'mmap'
                  }}
                />
              </td>
              <td className={'align-top'}>
                <Field
                  name={`${fieldNamePrefix}.amount`}
                  component={NumericInput}
                  props={{
                    allowNegativeNumber: isRefund,
                    fractionPartSize: 2,
                    step: .01,
                    min: -Infinity,
                    disabled: true,
                  }}
                />
              </td>
              <td className={'align-top'}  rowSpan={!isRefund ? 1 : (mmapRefundedOrdersAmount > 0 ? 4 : 3)}>
                <Field
                  name={`${fieldNamePrefix}.register_id`}
                  component={ReactSelectInput}
                  props={{
                    options: registers.filter((r) => r.type === 'sales'),
                    valueKey: 'id',
                    textKey: 'name',
                    disabled: true
                  }}
                />
                <br/>
                <div style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid #dee2e6', paddingBottom: '5px'  }}>
                  <div style={{ flex: '1' }}>
                    <strong>{I18n.t('cart.mmapPayment.reference')}: {reference}</strong>
                  </div>
                  <Button
                    title={`${I18n.t('cart.mmapPayment.copyToClipboard')}`}
                    style={{marginLeft: '5px'}}
                    onClick={copyReferenceToClipboard}>
                    <FaRegCopy/> {I18n.t('cart.mmapPayment.copy')}
                  </Button>
                </div>
                <br/>
                <div style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid #dee2e6', paddingBottom: '5px'  }}>
                  <div style={{ flex: '1' }}>
                    <strong>{I18n.t('cart.mmapPayment.patientId')}: {patientId}</strong>
                  </div>
                  <Button
                    title={`${I18n.t('cart.mmapPayment.copyToClipboard')}`}
                    style={{marginLeft: '5px'}}
                    onClick={copyPatientIdToClipboard}>
                    <FaRegCopy/> {I18n.t('cart.mmapPayment.copy')}
                  </Button>
                </div>
              </td>
            </tr>
            {isRefund &&
              <React.Fragment>
                <tr>
                  <td>
                    {I18n.t('cart.mmapPayment.mmapOriginalPayment')}
                  </td>
                  <td>
                    <Field
                      name={`${fieldNamePrefix}.original_payment_amount`}
                      component={NumericInput}
                      props={{
                        fractionPartSize: 2,
                        disabled: true,
                        value: mmapOriginalOrderAmount
                      }}
                    />
                  </td>
                </tr>
                {mmapRefundedOrdersAmount > 0 &&
                  <tr>
                    <td>
                      {I18n.t('cart.mmapPayment.mmapPreviouslyRefunded')}
                    </td>
                    <td>
                      <Field
                        name={`${fieldNamePrefix}.refunded_payment_amount`}
                        component={NumericInput}
                        props={{
                          fractionPartSize: 2,
                          disabled: true,
                          value: mmapRefundedOrdersAmount
                        }}
                      />
                    </td>
                  </tr>
                }
                <tr>
                  <td>
                    {I18n.t('cart.mmapPayment.mmapPaymentAfterRefund')}
                  </td>
                  <td>
                    <Field
                      name={`${fieldNamePrefix}.payment_after_refund_amount`}
                      component={NumericInput}
                      props={{
                        fractionPartSize: 2,
                        disabled: true,
                        value: mmapPaymentAfterRefund
                      }}
                    />
                  </td>
                </tr>
              </React.Fragment>
            }
          </React.Fragment>
        );

      })}
      </tbody>
    );
  }
}

Payments.propTypes = {
  fields: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  registers: PropTypes.array.isRequired,
  reference: PropTypes.number.isRequired,
  patientId: PropTypes.number.isRequired,
  addMessage: PropTypes.func.isRequired,
  isRefund: PropTypes.bool.isRequired
};

export default Payments;
