import get from 'lodash.get';
import {addMessage} from './systemActions';
import * as messageTypes from '../constants/messageTypes';
import {
  getInventoryComplianceSettings,
  getOnHandWeightFacility,
  getOnHandWeightOrganization,
  getPatientsComplianceSettings,
  getPlantComplianceSettings
} from '../selectors/complianceSettingsSelectors';
import {getPlantStats, getSelectedPlants} from '../selectors/plantsSelectors';
import {getSelectedPurchaseOrdersIds, getReceiptListingData} from '../selectors/orderSelectors';

export const validateCurrentOnHandWeight = () => (dispatch, getState) => {
  const state = getState();
  const settings = getInventoryComplianceSettings(state);
  const onHandWeightByFacility = getOnHandWeightFacility(state);
  const onHandWeightByOrganization = getOnHandWeightOrganization(state);

  if (settings === undefined) {
    return true;
  }

  let dispatched = false;
  let fatalError = false;

  const getSettingValue = (key) => {
    const settingValue = parseFloat(get(settings, key, null));
    return isNaN(settingValue) ? null : settingValue;
  };

  const limits = {
    block: getSettingValue('grams_on_hand_block'),
    warn: getSettingValue('grams_on_hand_warn'),
    coupledBlock: getSettingValue('coupled_grams_on_hand_block'),
    coupledWarn: getSettingValue('coupled_grams_on_hand_warn'),
  };

  const weights = {
    facility: get(onHandWeightByFacility, 'current_qty', null),
    coupledFacility: get(onHandWeightByFacility, 'qty', null),
    organization: get(onHandWeightByOrganization, 'qty', null)
  };

  // If the weights are not the same, then more than one facility is in use and has inventory
  const useCoupledFacilities = (weights.facility !== weights.coupledFacility) && (limits.coupledBlock || limits.coupledWarn);

  const weightExceedsLimit = (weightType, limitType) => {
    const harvestPackages = get(state, 'form.createHarvestPackages.values.packages', []);
    const weight = weights[weightType];
    const limit = limits[limitType];
    if ((weight === null || weight === 0) && harvestPackages.length > 0 && limit != null) {
      return harvestPackages.some(pkg => (pkg.weight_of_product > limit));
    }
    if(limit === null || weight === null) return false;
    return weight > limit;
  };

  const dispatchMessage = (weightType, limitType) => {
    dispatched = true;
    const messageType = limitType.toLowerCase().indexOf('warn') !== -1 ? 'warning' : 'error';
    const errorString = messageType === 'warning'
      ? weightType.toLowerCase().indexOf('coupled') !== -1 ? 'warningCoupledFacility' : 'warningFacility'
      : weightType.toLowerCase().indexOf('coupled') !== -1 ? 'errorCoupledFacility' : 'errorFacility';
    if(messageType === 'error') fatalError = true;
    dispatch(addMessage(messageTypes[messageType], [`retail.complianceSettings.onHand.${errorString}`, { //eslint-disable-line
      current:  parseFloat(weights[weightType]).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
      allowed: parseFloat(limits[limitType]).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
    }]));
  };

  if(weightExceedsLimit('facility', 'block') && !dispatched) dispatchMessage('facility', 'block');
  if(weightExceedsLimit('coupledFacility', 'coupledBlock') && !dispatched && useCoupledFacilities)
    dispatchMessage('coupledFacility', 'coupledBlock');
  if(weightExceedsLimit('facility', 'warn') && !dispatched) dispatchMessage('facility', 'warn');
  if(weightExceedsLimit('coupledFacility', 'coupledWarn') && !dispatched && useCoupledFacilities)
    dispatchMessage('coupledFacility', 'coupledWarn');
  return !fatalError;

  // Keeping this code short term to make sure I didn't screw up the this validation... if this is still here after 1.4.6 is released, nuke it
  // const block = settings && settings.grams_on_hand_block;
  // const coupled_block = settings && settings.coupled_grams_on_hand_block;
  // const warn = settings && settings.grams_on_hand_warn;
  // const coupled_warn = settings && settings.coupled_grams_on_hand_warn;
  //
  // const weightFacility = weightCoupledFacility && weightCurrentFacility && weightCoupledFacility === weightCurrentFacility ? weightCurrentFacility : weightCoupledFacility;
  // const blockValue = weightCoupledFacility && weightCurrentFacility && weightCoupledFacility === weightCurrentFacility ? block : coupled_block;
  // const warnValue = weightCoupledFacility && weightCurrentFacility && weightCoupledFacility === weightCurrentFacility ? warn : coupled_warn;
  //
  // if (blockValue && weightFacility && blockValue <= weightFacility) {
  //   dispatch(addMessage(messageTypes.error, ['retail.complianceSettings.onHand.errorFacility', {
  //     current: weightFacility,
  //     allowed: blockValue,
  //   }]));
  //   return false;
  // } else if (blockValue && weightOrganization && blockValue <= weightOrganization) {
  //   dispatch(addMessage(messageTypes.error, ['retail.complianceSettings.onHand.errorOrganization', {
  //     current: weightOrganization,
  //     allowed: blockValue,
  //   }]));
  //   return false;
  // } else if (warnValue && weightFacility && warnValue <= weightFacility) {
  //   dispatch(addMessage(messageTypes.warning, ['retail.complianceSettings.onHand.warningFacility', {
  //     current: weightFacility,
  //     allowed: warnValue
  //   }]));
  // } else if (warnValue && weightOrganization && warnValue <= weightOrganization) {
  //   dispatch(addMessage(messageTypes.warning, ['retail.complianceSettings.onHand.warningOrganization', {
  //     current: weightOrganization,
  //     allowed: warnValue,
  //   }]));
  // }
  // return true;
};

export const validatePatientCompliancePlants = (type) => (dispatch, getState) => {
  const state = getState();
  const settings = getPatientsComplianceSettings(state);
  let isValid = true;
  if (settings === undefined || settings.length === 0) {
    return isValid;
  }

  const selectedPlants = getSelectedPlants(state);
  const isVegetation = p => p.stage_name === 'Vegetation';
  const isFlowering = p => p.stage_name === 'Flowering';

  const vegetationAddCount = selectedPlants.reduce((acc, p) => acc + isVegetation(p) , 0);
  const floweringAddCount = selectedPlants.reduce((acc, p) => acc + isFlowering(p) , 0);

  settings.forEach((setting) => {
    const messageNumbers = {
      setting: setting.setting_count,
      plants: setting.plants_count
    };
    if (type === 'create' && setting.message_type === 'block') {
      if(setting.stage_code === 'total'){
        dispatch(addMessage(messageTypes.error, ['retail.complianceSettings.plantsError', messageNumbers]));
        isValid = false;
        return false;
      }
      if(vegetationAddCount && setting.stage_code === 'veg' && messageNumbers.plants + vegetationAddCount > messageNumbers.setting){
        messageNumbers.type = 'vegetation';
        dispatch(addMessage(messageTypes.error, ['retail.complianceSettings.plantComplianceTypeError', messageNumbers]));
        isValid = false;
      }
      if(floweringAddCount && setting.stage_code === 'flower' && messageNumbers.plants + floweringAddCount > messageNumbers.setting){
        messageNumbers.type = 'flowering';
        dispatch(addMessage(messageTypes.error, ['retail.complianceSettings.plantComplianceTypeError', messageNumbers]));
        isValid = false;
      }
    }
  });
  return isValid;
};

export const validatePlants = (type, additional = 0) => (dispatch, getState) => {
  const state = getState();
  const settings = getPlantComplianceSettings(state);
  const existing = getPlantStats(state);

  const validate = (plant_count, existing_count, additional_count, type) => {
    const messageNumbers = {
      type,
      setting: parseFloat(plant_count),
      plants: parseFloat(existing_count)
    };
    if(settings.action_type === 'block' && messageNumbers.setting &&
      (messageNumbers.setting < messageNumbers.plants + additional_count)){
      dispatch(addMessage(messageTypes.error, ['retail.complianceSettings.plantComplianceError', messageNumbers]));
      return false;
    }
    if(settings.action_type === 'warn' && messageNumbers.setting &&
      (messageNumbers.setting < messageNumbers.plants + additional_count)){
      dispatch(addMessage(messageTypes.warning, ['retail.complianceSettings.plantComplianceWarning', messageNumbers]));
      return true;
    }
    return true;
  };

  if (type === 'create') {
    const selectedPlants = getSelectedPlants(state);
    const isImmature = p => p.stage_name === 'Propagation' || p.stage_name === 'Vegetation';
    const isFlowering = p => p.stage_name === 'Flowering';

    const immatureAddCount = selectedPlants.reduce((acc, p) => acc + isImmature(p) , 0);
    const floweringAddCount = selectedPlants.reduce((acc, p) => acc + isFlowering(p) , 0);
    const onHandAddCount = immatureAddCount + floweringAddCount;

    const validationResult = [
      floweringAddCount && validate(settings.plants_in_flower, existing.flower, floweringAddCount, 'flowering'),
      immatureAddCount && validate(settings.plants_in_veg, existing.veget + existing.propag, immatureAddCount, 'immature'),
      onHandAddCount && validate(settings.plants_on_hand, existing.total, onHandAddCount, 'on hand'),
    ];

    return validationResult.every(r => r === 0 || !!r);
  }
  return  (type === 'flowering' && validate(settings.plants_in_flower, existing.flower, additional, type)) ||
  (type === 'immature' && validate(settings.plants_in_veg, existing.veget + existing.propag, additional,type)) ||
  (type === 'on hand' && validate(settings.plants_on_hand, existing.total, additional, type));
};

export const validateInventoryReceiptExists = () => (dispatch, getState) => {
  const state = getState();
  const inventoryReceipts = getReceiptListingData(state);
  const selectedPurchaseOrders = getSelectedPurchaseOrdersIds(state);

  if(inventoryReceipts === undefined || inventoryReceipts.length === 0) return true;
  if(selectedPurchaseOrders === undefined || selectedPurchaseOrders.length === 0) return true;

  const selected_po_id = selectedPurchaseOrders && selectedPurchaseOrders[selectedPurchaseOrders.length - 1];

  const existing_ir = inventoryReceipts.find(receipt => receipt.source_id === selected_po_id && receipt.status !== 'cancelled');

  if(existing_ir && existing_ir.id){
    dispatch(addMessage(messageTypes.error, ['purchaseOrders.validateIRExists', {ir_id: existing_ir.receipt_number}]));
    return false;
  }
  return true;

};

export const dummy = () => {};
