import {createSelector} from 'reselect';

const getDataUpdates = (state, props) => state.dataUpdated[props.core ? props.core : props.name];
const getMetaData = (state, props) => state.meta[props.name];

export const getDataUpdateAvailable = createSelector([getDataUpdates, getMetaData],
  (update, meta) => {
    if(meta && Object.keys(meta).length){
      if(update){
        return meta.startTime < update;
      }else{
        return false;
      }
    }else{
      return true;
    }
  });

export const dataUpdateSetAvailable = (next, current) => {
  return Array.isArray(next) && Array.isArray(current) && next.some(
    (update, index) => update && current[index] !== update
  );
};

export default getDataUpdateAvailable;
