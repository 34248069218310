import React from 'react';
import { I18n } from 'react-redux-i18n';
import {Field} from 'redux-form';
import {Row, Col} from 'react-bootstrap';
import PropTypes from 'prop-types';

import AccordionPanel from '../../common/AccordionPanel';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import NumericInput from '../../common/form/NumericInput';
import HoverTooltip from '../../common/HoverTooltip';
import InternationalDecimalInput from '../../common/form/InternationalDecimalInput';

const optionsForMedicatedWeight = [{},{value:'MG'}];

const InventorySettingsSection = (props) => {
  const {commonOptions, uomOptions, isCanada, showInventoryError, fieldCanModifiedMap} = props;
  return (
    <AccordionPanel title={'products.massModify.inventorySettingsTitle'}>
      <span id={'inventory_section'}/>
      <Row>
        <Col md={3}>
          <Field name='lot_tracked' component={ReactSelectInput} props={{
            label: I18n.t('products.form.trackLots'),
            options: commonOptions,
            disabled: showInventoryError,
            rightAddon: showInventoryError ?
              <HoverTooltip text={I18n.t('products.massModify.errors.inventory_exist', {field: I18n.t('products.form.trackLots')})} id={'lot_tracked_tooltip'}/> : null
          }}/>
        </Col>
        <Col md={3}>
          <Field name='is_prepack' component={ReactSelectInput} props={{
            label: I18n.t('products.form.prePackItem'),
            options: commonOptions,
            disabled: showInventoryError,
            rightAddon: showInventoryError ? <HoverTooltip text={I18n.t('products.massModify.errors.inventory_exist', {field: I18n.t('products.form.prePackItem')})} id={'is_prepack_tooltip'}/>
              : fieldCanModifiedMap.is_prepack.length ?
              <HoverTooltip text={I18n.t('products.massModify.errors.diff_categories_help', {field: I18n.t('products.form.prePackItem'), categories: fieldCanModifiedMap.is_prepack.join(' ,')})} id={'is_prepack_tooltip'}/>
              :  null
          }}/>
        </Col>
      </Row>
      <Row>
        <Col md={2} className='field-width-100'>
          <InternationalDecimalInput
            name='medicated_weight'
            props={{
              label: I18n.t('products.form.medicatedNetWeight'),
              step: 0.01,
              fractionPartSize: 2,
              rightAddon: fieldCanModifiedMap.medicated_weight.length ?
              <HoverTooltip text={I18n.t('products.massModify.errors.diff_categories_help', {field: I18n.t('products.form.medicatedNetWeight'), categories: fieldCanModifiedMap.medicated_weight.join(' ,')})} id={'medicated_weight_tooltip'}/> : null
            }}/>
        </Col>
        <Col md={1}>
          <Field name='medicated_weight_uom' component={ReactSelectInput} props={{
            label: I18n.t('products.form.uom'),
            options: optionsForMedicatedWeight,
            textKey: 'value',
            valueKey: 'value',
            rightAddon: fieldCanModifiedMap.medicated_weight_uom.length ?
              <HoverTooltip text={I18n.t('products.massModify.errors.diff_categories_help', {field: I18n.t('products.form.uom'), categories: fieldCanModifiedMap.medicated_weight_uom.join(' ,')})} id={'medicated_weight_uom_tooltip'}/> : null
          }}/>
        </Col>
        <Col md={2} className='field-width-100'>
          <InternationalDecimalInput
            name='net_weight'
            props={{
              label: I18n.t('products.form.productNetWeight'),
              step: 0.01,
              fractionPartSize: 2,
              rightAddon: fieldCanModifiedMap.net_weight.length ?
                <HoverTooltip text={I18n.t('products.massModify.errors.diff_categories_help', {field: I18n.t('products.form.productNetWeight'), categories: fieldCanModifiedMap.net_weight.join(' ,')})} id={'net_weight_tooltip'}/> : null
            }}
          />
        </Col>
        <Col md={1}>
          <Field name='net_weight_uom' component={ReactSelectInput} props={{
            label: I18n.t('products.form.uom'),
            options: uomOptions,
            textKey: 'value',
            valueKey: 'value',
            rightAddon: fieldCanModifiedMap.net_weight_uom.length ?
              <HoverTooltip text={I18n.t('products.massModify.errors.diff_categories_help', {field: I18n.t('products.form.uom'), categories: fieldCanModifiedMap.net_weight_uom.join(' ,')})} id={'net_weight_uom_tooltip'}/> : null
          }}/>
        </Col>
        <Col md={2} className='field-width-100'>
          <InternationalDecimalInput
            name='unit_weight'
            props={{
              label: I18n.t('products.form.grossWeight'),
              step: 0.01,
              fractionPartSize: 2,
              rightAddon: fieldCanModifiedMap.unit_weight.length ?
                <HoverTooltip text={I18n.t('products.massModify.errors.diff_categories_help', {field: I18n.t('products.form.grossWeight'), categories: fieldCanModifiedMap.unit_weight.join(' ,')})} id={'unit_weight_tooltip'}/>
                : null
            }}
          />
        </Col>
        <Col md={1}>
          <Field name='unit_weight_uom' component={ReactSelectInput} props={{
            label: I18n.t('products.form.uom'),
            options: uomOptions,
            textKey: 'value',
            valueKey: 'value',
            rightAddon: fieldCanModifiedMap.unit_weight_uom.length ? <HoverTooltip text={I18n.t('products.massModify.errors.diff_categories_help', {field: I18n.t('products.form.uom'), categories: fieldCanModifiedMap.unit_weight_uom.join(' ,')})} id={'unit_weight_uom_tooltip'}/> : null
          }}/>
        </Col>
        {isCanada ?
          <Col lg={2} sm={3} className='field-width-100'>
            <Field name='medicated_volume' component={NumericInput} props={{
              label: I18n.t('products.form.medicatedNetVolume'),
              step: 0.01,
              fractionPartSize: 3,
              rightAddon: fieldCanModifiedMap.medicated_volume.length ?
                <HoverTooltip text={I18n.t('products.massModify.errors.diff_categories_help', {field: I18n.t('products.form.medicatedNetVolume'), categories: fieldCanModifiedMap.medicated_volume.join(' ,')})} id={'medicated_volume_tooltip'}/>
                : null
            }}/>
          </Col> : null}
      </Row>
    </AccordionPanel>
  );
};

InventorySettingsSection.propTypes = {
  commonOptions: PropTypes.array.isRequired,
  uomOptions: PropTypes.array.isRequired,
  isCanada: PropTypes.bool.isRequired,
  showInventoryError: PropTypes.bool.isRequired,
  fieldCanModifiedMap: PropTypes.object.isRequired
};

export default InventorySettingsSection;
