import {requiredFieldValidation} from '../../../common/form/redux-form/validations';

export default function validate(values, {isCaregiverMode}) {
  return {
    license_type: requiredFieldValidation(values.license_type),
    patient_number: requiredFieldValidation(values.patient_number),
    first_name: isCaregiverMode && requiredFieldValidation(values.first_name) || null,
    last_name: isCaregiverMode && requiredFieldValidation(values.last_name) || null,
    dob: isCaregiverMode && requiredFieldValidation(values.dob) || null,
  };
}
