import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import InternationalDecimalInput from '../../common/form/InternationalDecimalInput';
import InternationalDecimalReadOnlyInput from '../../common/form/InternationalDecimalReadOnlyInput';

import {UOM_VALUE_PRECISION} from '../../../constants/uoms';

const MeasurementList = ({measurements, itemMaster, readOnly, translationPrefix, requiredFields}) => {
  let prefix;
  if (!translationPrefix) {
    prefix = 'products.form';
  } else {
    prefix = translationPrefix;
  }

  return (<Row className='no-gutters flex-wrap'>{measurements.map((name, index) => {
    if (readOnly) {
      const measureWeight = itemMaster && itemMaster[name + '_weight'] ? itemMaster[name + '_weight'] : '0';
      const measurePercent = itemMaster && itemMaster[name + '_percent'] ? itemMaster[name + '_percent'] : '0';
      return (
        <Col key={index} lg={3} md={4} sm={6}>
          <Row>
            <Col xs={12} style={{fontWeight: 'bold',  paddingLeft: '6px'}}>{I18n.t(`${prefix}.${name}`)}</Col>
          </Row>
          <Row>
            <Col xs={6} style={{paddingLeft: '6px', paddingRight: '6px'}}>
              <InternationalDecimalReadOnlyInput
                name={`${name}_percent`}
                props={{
                  placeholder: '0.00',
                  fractionPartSize: UOM_VALUE_PRECISION,
                  step: 'any',
                  allowNegativeNumber: false,
                  value: measurePercent,
                  rightAddon: <span>%</span>,
                }}/>
            </Col>
            <Col xs={6} style={{paddingLeft: '0', paddingRight: '24px'}}>
              <InternationalDecimalReadOnlyInput
                name={`${name}_weight`}
                props={{
                  placeholder: '0.00',
                  fractionPartSize: UOM_VALUE_PRECISION,
                  step: 'any',
                  allowNegativeNumber: false,
                  value: measureWeight,
                  rightAddon: <span>mg/g</span>,
                }}/>
            </Col>
          </Row>
        </Col>);
    } else {
      return (
        <Col key={index} lg={3} md={4} sm={6}>
          <Row>
            <Col xs={12} style={{fontWeight: 'bold',  paddingLeft: '6px'}}>{I18n.t(`${prefix}.${name}`)}</Col>
          </Row>
          <Row>
            <Col xs={6} style={{paddingLeft: '6px', paddingRight: '6px'}}>
              <InternationalDecimalInput
                name={`${name}_percent`}
                props={{
                  placeholder: '0.00',
                  fractionPartSize: UOM_VALUE_PRECISION,
                  step: 'any',
                  allowNegativeNumber: false,
                  rightAddon: <span>%</span>,
                  isRequired: Boolean(requiredFields && requiredFields[`requires_unit_${name}_content`])
                }}/>
            </Col>
            <Col xs={6} style={{paddingLeft: '0', paddingRight: '24px'}}>
              <InternationalDecimalInput
                name={`${name}_weight`}
                props={{
                  placeholder: '0.00',
                  fractionPartSize: UOM_VALUE_PRECISION,
                  step: 'any',
                  allowNegativeNumber: false,
                  rightAddon: <span>mg/g</span>,
                  isRequired: Boolean(requiredFields && requiredFields[`requires_unit_${name}_percent`])
                }}/>
            </Col>
          </Row>
        </Col>);
    }
  })}</Row>);
};

MeasurementList.propTypes = {
  measurements: PropTypes.array.isRequired,
  itemMaster: PropTypes.object,
  readOnly: PropTypes.bool,
  translationPrefix: PropTypes.string,
  requiredFields: PropTypes.object
};

MeasurementList.defaultProps = {
  readOnly: false
};

export default MeasurementList;
