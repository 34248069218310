import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash.get';

import {userCanSelectRegistersLocations, userNeedsRegistersLocations} from '../../selectors/usersSelectors';
import {getData} from '../../actions/apiActions';
import {setUserRetailLocation, setUserRetailRegister} from '../../actions/userActions';
import {openUserOptsMenu} from '../../actions/menuActions';
import * as dataNames from '../../constants/dataNames';
import * as p from '../../constants/permissions';
import LocationRegisterReduxForm from './LocationRegisterForm';
import {getActiveSalesRegisters, getDefaultSalesRegister} from '../../selectors/registersSelectors';
import {getDefaultSalesLocation} from '../../selectors/salesLocationsSelectors';

class RegisterLoader extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleRegisterChange = this.handleRegisterChange.bind(this);
  }

  componentWillMount(){
    if (this.props.showRegisters){
      this.props.actions.getData('/api/location_hierarchy', dataNames.salesLocations, undefined,{is_sales_location: 1, active: 1});
      this.props.actions.getData('/api/registers', dataNames.salesRegisters, undefined,{active: 1, type: 'sales'});
    }
  }

  componentDidMount() {
    this.props.actions.setUserRetailLocation(this.props.currentLocation);
    this.props.actions.setUserRetailRegister(this.props.currentRegister);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentLocation !== this.props.currentLocation) {
      this.props.actions.setUserRetailLocation(this.props.currentLocation);
    }
    if (prevProps.currentRegister !== this.props.currentRegister) {
      this.props.actions.setUserRetailRegister(this.props.currentRegister);
    }
  }

  handleLocationChange(event) {
    this.props.actions.setUserRetailLocation(event);
  }

  handleRegisterChange(event) {
    this.props.actions.setUserRetailRegister(event);
  }

  render () {
    const { locations, registers, currentLocation, currentRegister, showRegisters } = this.props;
    return showRegisters &&
    <LocationRegisterReduxForm onSubmit={(e) => e.preventDefault()}
      handleLocationChange={this.handleLocationChange} handleRegisterChange={this.handleRegisterChange}
      locations={locations} registers={registers} currentLocation={currentLocation} currentRegister={currentRegister} />;
  }
}

RegisterLoader.propTypes = {
  actions: PropTypes.object.isRequired,
  locations: PropTypes.array,
  registers: PropTypes.array,
  currentRegister: PropTypes.number,
  currentLocation: PropTypes.number,
  showRegisters: PropTypes.bool,
  userOptsMenuOpen: PropTypes.bool.isRequired,
  forceRegisters: PropTypes.bool.isRequired
};

RegisterLoader.default = {
  showRegisters: false
};

function mapStateToProps(state) {
  const {facility, salesLocations, user: {currentLocation, currentRegister}, menus:{userOptsMenuOpen}} = state;
  const showRegisters = userCanSelectRegistersLocations(state, {permissions: [p.view_registers]});
  const forceRegisters = userNeedsRegistersLocations(state, {permissions: [p.view_registers]});
  const defaultLocation = get(getDefaultSalesLocation(state), 'id');
  const defaultRegister = get(getDefaultSalesRegister(state), 'id');
  return {
    forceRegisters,
    userOptsMenuOpen,
    currentLocation: currentLocation || defaultLocation,
    currentRegister: currentRegister || defaultRegister,
    showRegisters,
    locations: salesLocations,
    registers: getActiveSalesRegisters(state),
    facility
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({getData, setUserRetailLocation, setUserRetailRegister, openUserOptsMenu}, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (RegisterLoader);
