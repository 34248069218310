import {formValueSelector, arrayRemove, arrayInsert, touch, focus} from 'redux-form';
import get from 'lodash.get';
import { INTERNAL_TRANSFER_FORM } from '../../constants/forms';
import * as dataNames from '../../constants/dataNames';
import * as actionTypes from '../../constants/actionTypes';


const internalTransfersForm = store => next => action => {
  const {payload} = action;

  const isSetDataOnDataName = (dataName) => {
    if(action.type === actionTypes.SET_DATA && action.name === dataName){
      return payload;
    }
    if(action.type === actionTypes.REDUX_BATCH_ACTION){
      return get((Array.isArray(payload) ? payload : []).find((payload) => {
        return payload.name === dataName && payload.type === actionTypes.SET_DATA;

      }), 'payload', false);
    }
  };

  // If some items are no longer available at the desired quantity the transfers are updated here
  // to reflect the currently available quantity.  The desired quantity is left as is.
  const transfers = isSetDataOnDataName(dataNames.updatedInternalTransfers);
  if(transfers){
    const valueSelector = formValueSelector(INTERNAL_TRANSFER_FORM);
    const currentTransfers = get(valueSelector(store.getState(), 'transfers', []), 'transfers', []);

    const quantityReservedChanged = (transfer, currentTransfer) => {
      return parseFloat(transfer.qty_reserved) !== parseFloat(currentTransfer.qty_reserved);
    };

    const isDataRefresh = (transfer, currentTransfer) => {
      return parseFloat(transfer.qty) !== parseFloat(currentTransfer.qty);
    };
    
    currentTransfers.forEach((currentTransfer, index) => {
      if(currentTransfer) {
        const transfer = transfers.find((transfer) => transfer.id === currentTransfer.id);
        const dataRefresh = isDataRefresh(transfer, currentTransfer);
        const qtyReservedChanged = quantityReservedChanged(transfer, currentTransfer);
        if (qtyReservedChanged || dataRefresh) {
          store.dispatch(arrayRemove(INTERNAL_TRANSFER_FORM, 'transfers', index));
          store.dispatch(arrayInsert(INTERNAL_TRANSFER_FORM, 'transfers', index, transfer));
        }
      }
    });

    const touchFields = [];
    currentTransfers.forEach((currentTransfer, index) => {
      const field = `transfers[${index}].transfer_qty`;
      store.dispatch(focus(INTERNAL_TRANSFER_FORM, field));
      touchFields.push(field);
    });
    store.dispatch(touch(INTERNAL_TRANSFER_FORM, touchFields));
  }

  const partnerFacilityPrepacks = isSetDataOnDataName(dataNames.partnerFacilityPrepacks);
  if(partnerFacilityPrepacks){
    const state = store.getState();
    const valueSelector = formValueSelector(INTERNAL_TRANSFER_FORM);
    const currentTransfers = get(valueSelector(state, 'transfers', []), 'transfers', []);
    const partnerFacilityId = valueSelector(state, 'partner_facility_id');

    currentTransfers.forEach((transfer, index) => {
      const prepackWeightId = get(transfer, 'prepack_weight_id', false);
      if(prepackWeightId){
        let prepackNotAvailable = false;
        const partnerFacilityPrepackWeight = partnerFacilityPrepacks.find((weight) => weight.prepack_weight_id === prepackWeightId);
        if(!partnerFacilityPrepackWeight && partnerFacilityId){
          prepackNotAvailable = true;
        }
        const newTransfer = Object.assign({}, transfer, {prepackNotAvailable});
        store.dispatch(arrayRemove(INTERNAL_TRANSFER_FORM, 'transfers', index));
        store.dispatch(arrayInsert(INTERNAL_TRANSFER_FORM, 'transfers', index, newTransfer));
      }
    });
  }

  return next(action);
};

export default internalTransfersForm;
