import * as types from '../constants/actionTypes';

export function getItemSuccess (payload, name, message) {
  return {type: types.GET_ITEM_SUCCESS, payload, name, message};
}

export function getItemFailed (payload, name, message) {
  return {type: types.GET_ITEM_FAILED, payload, name, message};
}

export function addItemSuccess (payload, name, message) {
  return {type: types.ADD_ITEM_SUCCESS, name, payload, message};
}

export function addItemFailed (payload, name, message) {
  return {type: types.ADD_ITEM_FAILED, name, payload, message};
}

export function editItemSuccess (payload, name, message) {
  return {type: types.EDIT_ITEM_SUCCESS, name, payload , message};
}

export function editItemFailed (payload, name, message) {
  return {type: types.EDIT_ITEM_FAILED, name, payload, message};
}

export function deleteItemSuccess (payload, name, message) {
  return {type: types.REMOVE_ITEM_SUCCESS, name, payload, message};
}

export function deleteItemFailed (payload, name, message) {
  return {type: types.REMOVE_ITEM_FAILED, name, payload, message};
}

export function setItem (payload, name, message) {
  return {type: types.SET_ITEM, name, payload, message};
}

export function setItemValue (payload, name, key) {
  return {type: types.SET_ITEM_VALUE, payload, name, key};
}

export function unsetItem (name) {
  return {type: types.UNSET_ITEM, name};
}

export const unsetItemAction = payloadName => dispatch => dispatch(unsetItem(payloadName));
