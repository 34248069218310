import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import {Col, Row} from 'react-bootstrap';
import {FaTimes} from 'react-icons/fa';
import AddItem from '../../../common/form/AddItem';
import InternationalDecimalInput from '../../../common/form/InternationalDecimalInput';
import ReactSelectInput from '../../../common/form/ReactSelectInput';

const getOptions = (options, map, getFormValue, currentIndex) => {
  const selectedValues = map(name => getFormValue(`${name}.customer_group_id`)).filter((value, index) => value && index !== currentIndex);
  return options.filter(option => selectedValues.indexOf(option.id) === -1);
};

const CustomerGroupsFieldArray = ({customerGroups, getFormValue, change, fields: {map, push, remove, length}}) => (
  <div className='customer-groups'>
    {map((groupName, groupIndex) => (
      <Row key={groupName}>
        <Col xs={6}>
          <Field name={`${groupName}.customer_group_id`} component={ReactSelectInput} props={{
            label: I18n.t('retail.pricingGroup.form.customerGroup'),
            options: getOptions(customerGroups, map, getFormValue, groupIndex),
            textKey: 'group_name',
            valueKey: 'id',
            isRequired: true,
            onChange: value => {
              const customerGroup = customerGroups.find(group => group.id === value);
              change(`${groupName}.customer_group_id`, value);
              change(`${groupName}.percentage_of_base`, customerGroup ? customerGroup.standard_discount_percent : '');
            }
          }}/>
        </Col>
        <Col xs={5}>
          <InternationalDecimalInput
            name={`${groupName}.percentage_of_base`}
            fractionPartSize={2}
            props={{
              label: I18n.t('retail.pricingGroup.form.percent'),
              isRequired: true
            }}/>
        </Col>
        <Col xs={1} className='remove-row-control'>
           <div>
            <FaTimes className='go-back-x' onClick={() => {remove(groupIndex);}}/>
          </div>
        </Col>
      </Row>
    ))}
    <AddItem
      addAction={() => push({})}
      text={'retail.pricingGroup.form.addCustomerGroup'}
      addDisabled={length >= customerGroups.length}
    />
  </div>
);

CustomerGroupsFieldArray.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    length: PropTypes.number.isRequired
  }).isRequired,
  customerGroups: PropTypes.array.isRequired,
  getFormValue: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
};

export default CustomerGroupsFieldArray;
