import {createSelector} from 'reselect';
import {APISICPA} from '../../constants/integrations';
import {getIntegrationSettings, getStateIntegrator} from './commonSelectors';


export const isApisicpaIntegrator = createSelector(
    [getStateIntegrator],
    integrator => integrator === APISICPA
);

export const getApisicpaSettings = createSelector(
    [getIntegrationSettings, isApisicpaIntegrator],
    (settings, isApisicpa) => isApisicpa && settings && settings.integration_apisicpa_settings && settings.integration_apisicpa_settings.value || null
);

export const isIlApisicpaIntegrator = createSelector(
    [getApisicpaSettings],
    settings => Boolean(settings && settings.state === 'IL')
);

export const isApisicpaSetupModeEnabled = createSelector(
  [isApisicpaIntegrator, getApisicpaSettings],
  (isApisicpa, settings) => Boolean(isApisicpa && settings && settings.setup_mode)
);
