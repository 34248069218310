import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import orderBy from 'lodash.orderby';
import * as itemNames from '../../constants/itemNames';
import {getItem, postItem} from '../../actions/apiActions';
import {setData} from '../../actions/dataActions';
import {unsetItem} from '../../actions/itemActions';
import FormWrapper from '../common/form/FormWrapper';
import InProgressOverlay from '../common/InProgressOverlay';
import SettingsFormWrapper from './components/SettingsFormWrapper';
import {getReceiptSettingsInitialValues} from '../../selectors/salesSettingsSelectors';

class ReceiptSettingPage extends React.PureComponent {

  constructor(props, context) {

    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.redirect = this.redirect.bind(this);
    this.hideLoader = this.hideLoader.bind(this);

    this.state = {
      showLoader: false,
      message: false,
    };

  }

  componentWillMount(){
    this.loadCompliance();
  }

  loadCompliance(){
    this.setState({showLoader: true, message: I18n.t('cultivation.complianceSettings.loadingSettings')});
    this.props.actions.getItem('/api/order_settings/sales_limit', itemNames.salesComplianceSettings)
      .then(this.hideLoader)
      .catch(this.hideLoader);
  }

  hideLoader(){
    this.setState({showLoader: false});
  }

  onSubmit(formValues) {

    this.setState({showLoader: true, message: I18n.t('cultivation.complianceSettings.savingSettings')});
    const values = Object.assign({}, formValues);
    delete(values.afterSubmit);

    const payload = {
      settings: [
        {
          setting_key: 'order_receipts_configuration',
          scope: 'facility',
          value: values,
        }
      ]
    };

    const messages = {failed: 'retail.salesComplianceSettings.failed', success: 'retail.salesComplianceSettings.salesSettingHaveBeenSaved'};
    this.props.actions.postItem('/api/order_settings/sales_limit', payload, '', messages, {}, () => {
      this.hideLoader();
      if(formValues.afterSubmit !== 'stay'){
        this.redirect();
        return false;
      }
      this.loadCompliance();
    });

  }

  redirect() {
    this.props.actions.goBack();
  }

  render() {

    let panels = [];
    if(this.props.complianceSettings){
      if(this.props.complianceSettings.order_receipts_configuration !== undefined) {
        const receiptConfig = this.props.complianceSettings.order_receipts_configuration.value;
        panels = Object.keys(receiptConfig).reduce((acc, field) => {
          const f = receiptConfig[field];
          let currentPanel = acc.find((p) => p.type === f.group);
          if(!currentPanel){
            currentPanel = {type: f.group, settings: []};
            acc.push(currentPanel);
          }
          const def = {name: field, text: `retail.receiptSettings.${field}`, optionsString: f.input_type !== undefined ? f.input_type : 'yesNo', hint_text: f.hint_text};
          currentPanel.settings.push(def);
          return acc;
        }, []);
        panels = orderBy(panels, 'type');
      }
    }

    return (
      <FormWrapper title={I18n.t('retail.title') + ' ' + I18n.t('retail.receiptSettings.title')} goBack={(event) => this.props.actions.goBack()}>
      <div>
        <InProgressOverlay message={this.state.message} isActive={this.state.showLoader} />
        <div className='row'>
          <SettingsFormWrapper
            onSubmit={this.onSubmit}
            panels={panels}
            enableReinitialize={true}
            initialValues={this.props.initialValues}
          />
        </div>
        <div style={{clear: 'both'}} />
      </div>
      </FormWrapper>
    );
  }
}

ReceiptSettingPage.propTypes = {
  initialValues: PropTypes.object.isRequired,
  complianceSettings: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    setData: PropTypes.func.isRequired
  }),
  tag: PropTypes.string.isRequired,
  label: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    complianceSettings: state.salesComplianceSettings,
    initialValues: getReceiptSettingsInitialValues(state),
  };
}

function mapDispatchToProps (dispatch) {
  const actions = {goBack, getItem, postItem, setData, unsetItem};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptSettingPage);
