

// This is how we want it in the store
export const fieldParseHelperDecimals = (value, name, internationalNumberFormat) => {

  if (value === undefined || value === null) {
    return value;
  }

  if (typeof value !== String ) value = value.toString();
  if ( internationalNumberFormat  === 'NUMERICAL_SEPARATOR_COMMA' ) { //1,000.00
    value = value.replace(/[^\d.-]/g, '');
  }
  if ( internationalNumberFormat  === 'NUMERICAL_SEPARATOR_PERIOD' ) { // 1.000,00
    value = value.replace(/[^\d,-]/g, '').replace(',', '.');
  }
  return value;
};

// This is how we want it displayed,  It will always be xxxx.xx coming from the database or the store, it also should be already rounded properly,
// all we have to do is substitute a comma for a dot in European formats
export const fieldFormatHelperDecimals = (value, name, internationalNumberFormat) => {

  if (value === undefined || value === null) {
    return value;
  }

  if (typeof value !== String) value = value.toString();
  value = value.replace(',', '');

  if ( internationalNumberFormat  === 'NUMERICAL_SEPARATOR_PERIOD' ) {
    value = value.replace('.', ',');
  }
  return value;
};

export const getInternationalPhoneCountryCode = value => {
  if (value) {
    if (value.indexOf('+') == 0) {
      const phoneCountryCode = value.indexOf('+') == 0 ? (value.substring(1, value.indexOf('-'))) : null;

      return phoneCountryCode;
    } else {
      if (value.length > 10 && value.indexOf('-') > 0 && value.match(/-/g).length == 1) {
        const phoneCountryCode = value.indexOf('-') > 0 ? (value.substring(0, value.indexOf('-'))) : '';
        return phoneCountryCode;
      }
    }
  }

  return null;
};

export const getInternationalPhoneNumber = value => {
  if (value) {
    if (value.indexOf('+') == 0) {
      let phoneNumber = ((value.indexOf('+') == 0) ?
        value.substring(value.indexOf('-'), value.length) :
        value);

      phoneNumber = phoneNumber.replace(/[^\d]/g, '');

      return phoneNumber;
    } else {
      if (value.length > 10 && value.indexOf('-') > 0 && value.match(/-/g).length == 1) {
        let phoneNumber = (value.indexOf('-') > 0 ?
          value.substring(value.indexOf('-') + 1, value.length) :
          value);

        phoneNumber = phoneNumber.replace(/[^\d]/g, '');
        return phoneNumber;
      }
    }
  }

  return value;
};


