import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import {requiredFieldValidation} from '../../common/form/redux-form/validations';
import {hasValue} from '../../../selectors/inventoryItemsSelectors';
import {leafPaMaxHarvestWeight} from '../../../constants/leaf';
import {hasPackageWithoutReason} from '../../../selectors/forms/modifyPackageFormSelectors';

const validateIntegrationAdjustmentReason = (values, props) => {
  const integrationState = props.integrationState || {};
  if (integrationState.isBiotrack && !parseFloat(values.pkg.transacted_qty)) {
    return null;
  }
  if (integrationState.isMetrc && (parseInt(values.pkg.newQty) === parseInt(values.pkg.qty) || [null, undefined].indexOf(values.integration_adjustment_reason) === -1)) {
    return null;
  }
  return requiredFieldValidation(values.integration_adjustment_reason || values.pkg.integration_adjustment_reason);
};

const validate = (values, ownProps) => {
  const errors = {};
  const {isPaLeaf, isBiotrack, isCanada, isWaLeaf} = ownProps.integrationState || {};
  if(!ownProps.hasAllIntegrationAdjustmentReasons) {
    errors.integration_adjustment_reason = isPaLeaf || (isBiotrack && !hasPackageWithoutReason(values.packages))
      ? null
      : requiredFieldValidation(values.integration_adjustment_reason);
    if (isWaLeaf) {
      errors.received_by_user_id = requiredFieldValidation(values.received_by_user_id);
    }
  }
  const {requireOnHandProductExpireDate} = ownProps;
  errors.packages = values.packages.map((pkg) => {
    const validatedStateIntegrationTrackingId = requiredFieldValidation(pkg.state_integration_tracking_id);
    let sizeError;
    if (isPaLeaf) {
      sizeError = (pkg.newQty > leafPaMaxHarvestWeight && pkg.uom !== 'EA') ? I18n.t('packages.form.packageSizePaError', {leafPaMaxHarvestWeight: leafPaMaxHarvestWeight.toFixed(2)}) : undefined;
    }
    if(!pkg.integration_id_platform_correction) {
      return {
        newQty: sizeError ? sizeError : requiredFieldValidation(pkg.newQty, 'packages.form.newQty'),
        new_item_master_id: requiredFieldValidation(pkg.new_item_master_id, 'packages.form.item'),
        integration_adjustment_reason: validateIntegrationAdjustmentReason({pkg, ...values}, ownProps),
        received_by_user_id: requiredFieldValidation(pkg.received_by_user_id),
        state_integration_tracking_id: validatedStateIntegrationTrackingId,
        adjustment_reason: (
          // LeafPA and Canada require reasons at all time
          (isPaLeaf || isCanada ) && !hasValue(pkg.adjustment_reason) && !hasValue(values.adjustment_reason)) ||
          // Otherwise the requirement is driven by the `reconciliation_requires_adjustment_reason` setting.
          (get(ownProps.complianceSettings, 'reconciliation_requires_adjustment_reason', false) && parseFloat(pkg.transacted_qty) != 0  && !hasValue(pkg.adjustment_reason) && !hasValue(values.adjustment_reason))
          ? I18n.t('common.form.isRequired', {fieldName: I18n.t('packages.form.adjustmentReason')})
          : undefined,
        packaged_at: requiredFieldValidation(pkg.packaged_at, 'packaging.form.actualCompletionTime'),
        package_expires_at: requireOnHandProductExpireDate ? requiredFieldValidation(pkg.package_expires_at, 'packaging.form.packageExpiresAt') : null
      };
    }
    return {
      state_integration_tracking_id: validatedStateIntegrationTrackingId,
    };
  });
  return errors;
};

export default validate;
