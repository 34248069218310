import { I18n } from 'react-redux-i18n';
import { addMessage } from '../actions/systemActions';


const inventoryReceipt = (store) => (next) => (action) => {
  const result = next(action);

  /*** Inventory Receipt ready ***/
  if (action.type === 'INVENTORY_RECEIPT_READY') {
    store.dispatch(addMessage('success', I18n.t('receipts.create.completed'), true));
  }

  return result;
};

export default inventoryReceipt;
