import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Table} from 'react-bootstrap';

const Information = ({isCustomerCheckout, birth_date, total_spent, total_orders, ids}) => {
  return (

    <Table className='information'>
      <thead>
        <tr>
          <th colSpan={2}>{I18n.t('cart.patient.information')}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{I18n.t('cart.patient.birthDate')}:</td>
          <td>{birth_date}</td>
        </tr>

        {!isCustomerCheckout ?
          <tr>
            <td>{I18n.t('cart.patient.totalSpent')}:</td>
            <td>{total_spent}</td>
          </tr>
          : ''
        }
        {!isCustomerCheckout ?
          <tr>
            <td>{I18n.t('cart.patient.totalOrders')}:</td>
            <td>{total_orders}</td>
          </tr>
          : ''
        }

        {ids.map((customer_id, index) => {
          return (
            <tr key={index}>
              <td>{I18n.t('cart.patient.cardNumber')}: {customer_id.identification_number}</td>
              <td>{I18n.t('cart.patient.cardExpiration')}: {customer_id.expired_at}</td>
            </tr>
          );
        })}

      </tbody>
    </Table>
  );
};


Information.propTypes = {
  birth_date: PropTypes.string,
  total_spent: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string]),
  total_orders: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string]),
  ids: PropTypes.array,
  isCustomerCheckout: PropTypes.bool,
};

Information.defaultProps = {
  birth_date: '',
  total_spent: '0',
  total_orders: 0,
  ids: [],
  isCustomerCheckout: false
};

export default Information;
