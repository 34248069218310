import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';
import ProcessingForm from './StartProcessingForm';
import validate from './validate';

const StartProcessingFormWrapper = reduxForm({
  validate,
  enableReinitialize: false,
})(ProcessingForm);

StartProcessingFormWrapper.propTypes = {
  form: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  addMessage: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  itemOptions: PropTypes.array.isRequired,
  inputs: PropTypes.array,
  employees: PropTypes.array.isRequired,
  equipments: PropTypes.array.isRequired,
  processingTypes: PropTypes.array.isRequired,
  startTime: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  estimatedTime: PropTypes.number,
  ready: PropTypes.bool,
  allowNegativeInventory: PropTypes.bool,
};

export default StartProcessingFormWrapper;
