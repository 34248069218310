import {requiredFieldValidation} from '../../../common/form/redux-form/validations';

export const validate = (values) => {
  const errors = {};

  errors.name = requiredFieldValidation(values.name);
  errors.facilities = !values.facilities || !values.facilities.length;
  errors.masterFacilityId = requiredFieldValidation(values.masterFacilityId);

  return errors;
};

export default validate;