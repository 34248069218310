import React from 'react';
import PropTypes from 'prop-types';
import InternationalCurrencyStatic from '../../../../common/form/InternationalCurrencyStatic';
import InternationalDecimalStatic from '../../../../common/form/InternationalDecimalStatic';

const RequestedPrePackRow = ({subItem}) => (
  <tr>
    <td className='weightName'><label>{subItem.name}</label></td>
    <td className='qty'>{subItem.qty}</td>
    <td className='total'>
      <InternationalDecimalStatic fractionPartSize={2}>
        {subItem.weight}
      </InternationalDecimalStatic>
    </td>
    <td className='cost'>
      <InternationalCurrencyStatic>
        {subItem.unitCost}
      </InternationalCurrencyStatic>
    </td>
    <td className='lineTotal'>
      <InternationalCurrencyStatic>
        {subItem.total}
      </InternationalCurrencyStatic>
    </td>
  </tr>
);

RequestedPrePackRow.propTypes = {
  subItem: PropTypes.object.isRequired,
};

export default RequestedPrePackRow;
