import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, FormGroup, Row} from 'react-bootstrap';
import InternationalCurrencyInput from '../../common/form/InternationalCurrencyInput';
import InternationalDecimalInput from '../../common/form/InternationalDecimalInput';
import InternationalDateTimePickerInput from '../../common/form/InternationalDateTimePickerInput';
import ContentConcealer from '../../common/concealers/ContentConcealer';

const OrderTotals = (props) => {
  const {isReceiptForm, integrationState: {isWaLeaf}, isReadOnly, atLeastOnePartnerIsNotLab} = props;
  return (
    <FormGroup className='order-totals'>
      <h3>{I18n.t('cultivation.transfers.form.orderTotals')}</h3>
      <Row>
        {
          isReceiptForm ? '' :
          <Col xs={2} sm={2} md={2}>
            <InternationalDateTimePickerInput
              name='date_transferred'
              props={{
                label: I18n.t('cultivation.transfers.form.dateFilled'),
                disabled: isReadOnly,
                enableTodayButton: !isWaLeaf,
                inputProps: {
                  disabled: isReadOnly,
                },
                isRequired: true,
              }}
            />
          </Col>
        }
        <ContentConcealer show={atLeastOnePartnerIsNotLab}>
          <Col xs={2} sm={2} md={2}>
            <InternationalCurrencyInput
              name='excise_tax_due'
              props={{
                label: I18n.t('cultivation.transfers.form.exciseTaxDue'),
                readOnly: true,
              }} />
          </Col>
          {/*<Col xs={2} sm={2} md={2}>
          <Field
            name='partner_discount'
            component={CurrencyInput}
            props={{
              label: I18n.t('cultivation.transfers.form.partnerDiscount'),
              leftAddon: <span>%</span>
            }}
          />
        </Col>*/}
          <Col xs={2} sm={2} md={2}>
            <InternationalCurrencyInput
              name='transfer_fee'
              props={{
                label: I18n.t('cultivation.transfers.form.transferFee'),
                readOnly: true,
              }} />
          </Col>
          <Col xs={2} sm={2} md={2}>
            <InternationalDecimalInput name='partner_discount' props={{
              label: I18n.t('purchaseOrders.form.partnerDiscount'),
              leftAddon: <span>%</span>,
              readOnly: true
            }}/>
          </Col>
          <Col xs={2} sm={2} md={2}>
            {
              isReceiptForm ?
                <InternationalCurrencyInput
                  name='inventory_receipt_total'
                  props={{
                    label: I18n.t('cultivation.transfers.form.inventoryReceiptTotal'),
                    readOnly: true,
                    disabled: true,
                  }} />
                :
                <InternationalCurrencyInput
                  name='destination_total'
                  props={{
                    label: I18n.t('cultivation.transfers.form.destinationTotal'),
                    readOnly: true,
                  }} />
            }
          </Col>
        </ContentConcealer>
      </Row>
    </FormGroup>
  );
};

OrderTotals.propTypes = {
  isReceiptForm: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  atLeastOnePartnerIsNotLab: PropTypes.bool,
  integrationState: PropTypes.object.isRequired,
};

export default OrderTotals;
