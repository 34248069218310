import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import {FaExclamationTriangle} from 'react-icons/fa';
import {Field, FieldArray} from 'redux-form';
import BulkPackages from './BulkPackages';
import InternationalQuantityByUomInput from '../../../common/form/InternationalQuantityByUomInput';
import InternationalCurrencyInput from '../../../common/form/InternationalCurrencyInput';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import ProductSubTypeDisplay from './ProductSubTypeDisplay';
import ContentConcealer from '../../../common/concealers/ContentConcealer';
import {fieldFormatHelperDecimals} from '../../../../util/internationalHelpers';

const BulkLine = (props) => {
  const {
    line, lineTotal, sentTotal, change, fieldName, locations, packages, isApplyStorageLocation,
    itemMaster, isEditMode, editableLinePrice, orderedQty, isComplete, isCancelled, integrationState, transferId,
    disposalReasonOptions, inventoryLocked, formName, isMedicated, formData, itemMasterIsMedicated, internationalNumberFormat
  } = props;

  const uom = itemMaster.default_uom;
  const { abbrev } = I18n.t(`uoms.${uom}`);

  return (
  <Col xs={12}>
    <Row>
      <Col lg={4}>
        <Field name={`${fieldName}.item_master_id`} component={ReactSelectInput} props={{
          label: I18n.t('cultivation.transfers.form.item'),
          options: [itemMaster],
          textKey: 'name',
          valueKey: 'id',
          disabled: true
        }}/>
        <div>
          <ProductSubTypeDisplay fieldName={fieldName} formName={formName} />
        </div>
      </Col>
      <Col lg={2}>
        <InternationalQuantityByUomInput
          name={`${fieldName}.ordered_qty`}
          label={I18n.t('purchaseOrders.form.requestedAmount')}
          readOnly={true}
          uom={uom}
          shouldRound={false}
          leftAddon={(
            <div style={{cursor: isComplete ? 'not-allowed' : 'pointer'}} onClick={(event) => {
              if (isComplete) return;
              const input  = event.target.parentNode.parentNode.parentNode.getElementsByTagName('input');
              const newState = !input[0].readOnly;
              input[0].readOnly = newState;
              input[0].shouldRound = newState;
              event.target.innerHTML = (!newState) ? 'Lock' : 'Edit';
            }}>
              Edit
            </div>
          )}
        />
      </Col>
      <Col lg={2} >
        <InternationalQuantityByUomInput
          name={`${line}.sent_total`}
          readOnly={true}
          label={I18n.t('purchaseOrders.form.sentTotal')}
          uom={uom}
          value={fieldFormatHelperDecimals(sentTotal, '', internationalNumberFormat)}
        />
      </Col>
      <ContentConcealer show={!line.is_lab_partner}>
        <Col lg={2}>
          <InternationalCurrencyInput
            name={`${fieldName}.unit_price`}
            props={{
              label: I18n.t('purchaseOrders.form.pricePer', {label: abbrev}),
              readOnly: editableLinePrice || isComplete,
              disabled: isCancelled,
            }} />
        </Col>
        {
          !editableLinePrice
          ? (
            <Col lg={2}>
              <InternationalCurrencyInput
                name={`${fieldName}.line_item_price`}
                props={{
                  label: I18n.t('purchaseOrders.form.itemPrice'),
                  readOnly: true,
                  shouldRound: false,
                  value: lineTotal,
                  disabled: isComplete,
                  rightAddon: (
                    <div className={isComplete ? 'btn btn-primary disabled' : 'btn btn-primary'} onClick={(event) => {
                      if (isComplete) return;
                      const input  = event.target.parentNode.parentNode.getElementsByTagName('input');
                      input[0].focus();  setTimeout(() => input[0].blur(), 10);
                      change(`${fieldName}.editableLinePrice`, true);
                    }}>
                      Edit
                    </div>
                  )
                }} />
            </Col>
          )
            : (
            <Col lg={2}>
              <InternationalCurrencyInput
                name={`${fieldName}.line_item_price`}
                props={{
                  label: I18n.t('purchaseOrders.form.itemPrice'),
                  shouldRound: false,
                  rightAddon: (
                    <div className={isComplete ? 'btn btn-primary disabled' : 'btn btn-primary'} onClick={(event) => {
                      const input  = event.target.parentNode.parentNode.getElementsByTagName('input');
                      input[0].focus();  setTimeout(() => input[0].blur(), 10);
                      change(`${fieldName}.editableLinePrice`, false);
                    }}>
                      Calc
                    </div>
                  )
                }}/>
            </Col>
          )
        }
      </ContentConcealer>
    </Row>
    <Row>
      <Col lg={4}>&nbsp;</Col>
      <Col lg={8} style={{margin: '0px 0px 12px 0px'}} className={orderedQty !== sentTotal ? 'text-danger' : 'hide'}>
        <FaExclamationTriangle style={{marginRight: '8px'}}/>
        {I18n.t('purchaseOrders.form.requestedAmount')} is not equal to {I18n.t('purchaseOrders.form.sentTotal')}.
        Edit <strong>{I18n.t('purchaseOrders.form.requestedAmount')}</strong> directly to update the value on your Sales Order.
      </Col>
    </Row>
    <FieldArray
      name={`${fieldName}.inventory`}
      editableLinePrice={editableLinePrice}
      component={BulkPackages}
      isApplyStorageLocation={isApplyStorageLocation}
      locations={locations}
      packages={packages}
      fieldName={fieldName}
      change={change}
      isEditMode={isEditMode}
      isComplete={isComplete}
      isMedicated={isMedicated}
      isCancelled={isCancelled}
      uom={uom}
      integrationState={integrationState}
      transferId={transferId}
      inventoryLocked={inventoryLocked}
      disposalReasonOptions={disposalReasonOptions}
      line={line}
      formName={formName}
      itemMaster={itemMaster}
      itemMasterIsMedicated={itemMasterIsMedicated}
      formData={formData}
    />
  </Col>);
};

BulkLine.propTypes = {
  orderedQty: PropTypes.number,
  editableLinePrice: PropTypes.bool,
  line: PropTypes.object.isRequired,
  lineTotal: PropTypes.string.isRequired,
  sentTotal: PropTypes.number.isRequired,
  change: PropTypes.func.isRequired,
  packages: PropTypes.array,
  fieldName: PropTypes.string.isRequired,
  isApplyStorageLocation: PropTypes.bool,
  locations: PropTypes.array,
  itemMaster: PropTypes.object.isRequired,
  itemMasterIsMedicated: PropTypes.bool,
  isEditMode: PropTypes.bool,
  isComplete: PropTypes.bool.isRequired,
  isCancelled: PropTypes.bool,
  isMedicated: PropTypes.bool,
  inventoryLocked: PropTypes.bool,
  integrationState: PropTypes.object.isRequired,
  transferId: PropTypes.string,
  disposalReasonOptions: PropTypes.array.isRequired,
  formName: PropTypes.string.isRequired,
  formData : PropTypes.object.isRequired,
  internationalNumberFormat: PropTypes.string
};

BulkLine.defaultProps = {
  isEditMode: false
};

export default BulkLine;
