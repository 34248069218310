import React from 'react';
import PropTypes from 'prop-types';
import {formValueSelector, change} from 'redux-form';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack} from 'react-router-redux';
import get from 'lodash.get';
import * as itemNames from '../../../constants/itemNames';
import * as dataNames from '../../../constants/dataNames';
import {getItem, getUnpaginatedData, postItem} from '../../../actions/apiActions';
import {unsetItem} from '../../../actions/itemActions';
import {addMessage} from '../../../actions/systemActions';
import {isActiveFacilityDispensary, isLeafPaConfigPackClosedLoopFacility, isLeafUtahConfigPackClosedLoopFacility} from '../../../selectors/facilitiesSelectors';
import {getStateIntegratorOptions, getMetrcStateOptions} from '../../../selectors/stateIntegratorsSelectors';
import {getIntegrationSettingsInitialValues, getIntegrationSettingsPayload, getMetrcState, getBiotrackState} from '../../../selectors/integrationSettingsSelectors';
import {getBiotrackStateOptions} from '../../../selectors/integration/biotrackStatesSelectors';
import {getApisicpaStateOptions} from '../../../selectors/integration/apisicpaStatesSelectors';
import {STATE_INTEGRATION_SETTINGS_FORM} from '../../../constants/forms';
import {isFeatureEnabled} from '../../../selectors/featureToggles';
import InProgressOverlay from '../../common/InProgressOverlay';
import FormWrapper from '../../common/form/FormWrapper';
import IntegrationSettingsFormWrapper from './IntegrationSettingsFormWrapper';

const selector = formValueSelector(STATE_INTEGRATION_SETTINGS_FORM);

export class IntegrationSettingsPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.getSynchronizableModules = this.getSynchronizableModules.bind(this);
    this.state = {
      loading: true,
      saving: false,
    };
  }

  // force `enable_sales_push: true` for OH Metrc integrators (MJP-12521)
  componentDidUpdate(prevProps) {
    // detect metrc state change
    if(this.props.selectedMetrcState !== prevProps.selectedMetrcState){
      if(this.props.selectedMetrcState === 'oh' && this.props.isRetailFacility){
        // extract pathspec for form field from initialValues
        const integrators = get(this.props,'initialValues.integrators',[]);
        const integratorIdx = integrators.findIndex( i => i.integrator === 'metrc' );
        const fieldPath = `integrators[${integratorIdx}].value.enable_sales_push`;
        // update form value
        this.props.actions.change(STATE_INTEGRATION_SETTINGS_FORM,fieldPath,true);
      }
    }
  }

  componentDidMount() {
    const {getItem, unsetItem} = this.props.actions;
    unsetItem(itemNames.integrationSettings);
    this.setState({loading: true, saving: false});
    Promise
      .all([
        getItem('/api/integration-settings/state_integrators', itemNames.stateIntegrators, {failed: 'stateIntegrators.get.failed'}),
        getItem('/api/integration-settings/leaf_states', itemNames.leafStates, {failed: 'stateIntegrators.leafStates.get.failed'}),
        getItem('/api/integration-settings/metrc_states', itemNames.metrcStates, {failed: 'stateIntegrators.metrcStates.get.failed'}),
        getItem('/api/biotrack/states', itemNames.biotrackStates, {failed: 'stateIntegrators.biotrackStates.get.failed'}),
        getItem('/api/apisicpa/states', itemNames.apisicpaStates, {failed: 'stateIntegrators.apisicpaStates.get.failed'}),
        getItem('/api/integration-settings', itemNames.integrationSettings, {failed: 'stateIntegrators.settings.get.failed'}, {}, (settings) => {
          const integrator = get(settings, 'integration_state_integrator.value');
          if (integrator === 'biotrack') {
            this.getSynchronizableModules(integrator);
          }
        }),
      ])
      .then(() => {
        // Force set Utah if New Utah integration is not yet enabled, but Utah EVS is. This should be temporary.
        if (this.props.isLeafUtahConfigPackClosedLoopFacility && !this.props.features.isUtahIntegrationToggled && this.props.features.isUtahEvsToggled) {
          this.props.actions.change(STATE_INTEGRATION_SETTINGS_FORM, 'selectedIntegrator.value', 'utah');
        }
        this.setState({
          loading: false,
        });
      })
      .catch(() => this.setState({loading: false}));
  }

  componentWillUnmount() {
    this.props.actions.getItem(
      '/api/integration-settings',
      itemNames.integrationSettings,
      {failed: 'stateIntegrators.settings.get.failed'}
    );
  }

  onSubmit(formValues) {
    this.setState({saving: true});
    return this.props.actions
      .postItem(
        '/api/integration-settings',
        getIntegrationSettingsPayload(formValues),
        '',
        {failed: 'stateIntegrators.settings.modify.failed', success: 'stateIntegrators.settings.modify.success'}
      )
      .then(() => {

        const {selectedModules, shouldSync, selectedBiotrackState} = this.props;

        if (shouldSync && selectedModules && Object.keys(selectedModules).length && selectedBiotrackState === 'PR') {
          const payload = {
            components: []
          };

          for (const selectedModule in selectedModules) {
            if (selectedModules[selectedModule]) {
              payload.components.push(selectedModule);
            }
          }

          this.props.actions
            .postItem('/api/integration/synchronization', payload, '', {
              //success: 'stateIntegrators.form.syncProcessingSuccess',
              failed: 'stateIntegrators.form.syncProcessingFailed',
            })

            .then(() => {
              this.props.actions.addMessage('warning', 'stateIntegrators.form.syncProcessingWarning');
            });
        }

        this.setState({saving: false}, () => {
          if (formValues.selectedIntegrator && formValues.selectedIntegrator.value && formValues.selectedIntegrator.value !== 'none') {
            this.props.actions.addMessage('warning', 'stateIntegrators.settings.modify.delayNotice');
            if(formValues.selectedIntegrator.value === 'metrc') {
              this.props.actions.getItem('/api/metrc/transfers_enabled', itemNames.metrcTransfersEnabled);
            }
          }
          this.props.actions.goBack();
        });

        // Ping settings to update the order_sales_limits_last_updated value to keep orders in sync with limits
        this.props.actions.postItem('/api/order_settings/sales_limit', {settings: []});
      })
      .catch(() => this.setState({saving: false}));
  }

  render() {
    const {initialValues, metrcStates, integrators, selectedIntegrator, isRetailFacility, biotrackStates,
      apisicpaStates, selectedMetrcState, synchronizableModules, selectedBiotrackState, features,
      isLeafPaConfigPackClosedLoopFacility, isLeafUtahConfigPackClosedLoopFacility} = this.props;
    const {loading, saving} = this.state;
    const message = `common.form.${saving ? 'saving' : 'loadingData'}`;

    return (
      <FormWrapper title='facility.setup.stateIntegratorDescription' goBack={this.props.actions.goBack}>
        <InProgressOverlay isActive={loading || saving} message={message} translate={true}/>
        <IntegrationSettingsFormWrapper
          form={STATE_INTEGRATION_SETTINGS_FORM}
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          integrators={integrators}
          biotrackStates={biotrackStates}
          apisicpaStates={apisicpaStates}
          features={features}
          metrcStates={metrcStates}
          selectedIntegrator={selectedIntegrator}
          isRetailFacility={isRetailFacility}
          selectedMetrcState={selectedMetrcState}
          getSynchronizableModules={this.getSynchronizableModules}
          synchronizableModules={synchronizableModules}
          selectedBiotrackState={selectedBiotrackState}
          isLeafPaConfigPackClosedLoopFacility={isLeafPaConfigPackClosedLoopFacility}
          isLeafUtahConfigPackClosedLoopFacility={isLeafUtahConfigPackClosedLoopFacility}
        />
      </FormWrapper>
    );
  }

  getSynchronizableModules (integrator) {
    this.props.actions.change(STATE_INTEGRATION_SETTINGS_FORM, 'selectedIntegrator.value', integrator);

    this.props.actions.getUnpaginatedData('/api/integration/synchronization/modules', dataNames.integrationSynchronizableModules, {}, {
      integrator
    });
  }
}

IntegrationSettingsPage.propTypes = {
  actions: PropTypes.shape({
    getItem: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    addMessage: PropTypes.func.isRequired,
  }).isRequired,
  initialValues: PropTypes.object,
  selectedIntegrator: PropTypes.string,
  metrcStates: PropTypes.array.isRequired,
  biotrackStates: PropTypes.array.isRequired,
  apisicpaStates: PropTypes.array.isRequired,
  integrators: PropTypes.array.isRequired,
  isRetailFacility: PropTypes.bool.isRequired,
  selectedMetrcState: PropTypes.string,
  selectedBiotrackState: PropTypes.string,
  synchronizableModules: PropTypes.array,
  selectedModules: PropTypes.object,
  shouldSync: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    initialValues: getIntegrationSettingsInitialValues(state),
    metrcStates: getMetrcStateOptions(state),
    biotrackStates: getBiotrackStateOptions(state),
    apisicpaStates: getApisicpaStateOptions(state),
    integrators: getStateIntegratorOptions(state),
    selectedIntegrator: selector(state, 'selectedIntegrator.value'),
    isRetailFacility: isActiveFacilityDispensary(state),
    features: {
      isMetrcTransfersToggled: isFeatureEnabled(state)('feature_metrc_transfers'),
      isProductMasterToggled: isFeatureEnabled(state)('feature_new_product_master'),
      isUtahIntegrationToggled: isFeatureEnabled(state)('feature_utah_integration'),
      isUtahEvsToggled: isFeatureEnabled(state)('feature_utah_evs'),
    },
    selectedMetrcState: getMetrcState(selector(state, 'integrators')),
    selectedBiotrackState: getBiotrackState(selector(state, 'integrators')),
    synchronizableModules: state[dataNames.integrationSynchronizableModules],
    selectedModules: selector(state, 'sync.selected_modules'),
    shouldSync: selector(state, 'sync.enabled'),
    isLeafPaConfigPackClosedLoopFacility: isLeafPaConfigPackClosedLoopFacility(state),
    isLeafUtahConfigPackClosedLoopFacility: isLeafUtahConfigPackClosedLoopFacility(state)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {change, getItem, postItem, unsetItem, goBack, addMessage, getUnpaginatedData};
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationSettingsPage);
