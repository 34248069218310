import React from 'react';
import PropTypes from 'prop-types';
import {reduxForm, FieldArray} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Row} from 'react-bootstrap';
import TaxRateFieldArray from './TaxRateFieldArray';
import AddItem from '../../common/form/AddItem';
import SubmitSection from '../../common/form/SubmitSection';
import {validate} from './validate';

const emptyTaxRate = {
  categories: [],
  categorySubcategories: [],
  facilities: [],
  fulfillment_methods: [],
  customerTypes: [],
  inStateOptions: []
};

const initialValues = {
  taxRates: [{...emptyTaxRate}]
};

export class TaxForm extends React.PureComponent {

  constructor(props) {
    super(props);
    this.handleCategoriesChange = this.handleCategoriesChange.bind(this);
    this.handleApplyToAllChange = this.handleApplyToAllChange.bind(this);
  }

  /**
   * Re-generate subcategories array depending on selected categories.
   */
  handleCategoriesChange(taxRateIndex, selectedCategories) {
    const {change, taxRates} = this.props;
    const tax = taxRates[taxRateIndex];
    const newSubcategories = [];
    selectedCategories.forEach(category => {
      const currentSubcategoriesValue = tax ? tax.categorySubcategories.find(subcat => subcat.category_id === category.id) : null;
      const newEmptySubcategories = {
        category_id: category.id,
        subcategories: category.subcategories,
        apply_to_all_subcategories: true,
      };
      newSubcategories.push(currentSubcategoriesValue || newEmptySubcategories);
    });
    change(`taxRates[${taxRateIndex}].categories`, selectedCategories);
    change(`taxRates[${taxRateIndex}].categorySubcategories`, newSubcategories);
  }

  /**
   * Select all sub-categories when checkbox "Apply to whole category" was checked.
   */
  handleApplyToAllChange(taxRateIndex, categoryId, isChecked) {
    const {change, taxRates} = this.props;
    const tax = taxRates[taxRateIndex];
    if (isChecked) {
      const newSubcategories = [];
      tax.categories.forEach(category => {
        let subcategoriesValue = tax ? tax.categorySubcategories.find(subcat => subcat.category_id === category.id) : null;
        if(categoryId === category.id) {
          subcategoriesValue = {
            category_id: category.id,
            subcategories: category.subcategories,
            apply_to_all_subcategories: true,
          };
        }
        if (subcategoriesValue) newSubcategories.push(subcategoriesValue);
      });
      change(`taxRates[${taxRateIndex}].categorySubcategories`, newSubcategories);
    }
    change(`taxRates[${taxRateIndex}].categorySubcategories.apply_to_all_subcategories`, isChecked);
  }

  render() {
    const {categories, handleSubmit, submitting, invalid, modifyPage, facilities, array: {push, pop}, taxRates, facility, tax} = this.props;
    const settings = {
      actionSettings: {
        submit: {
          submitting,
          invalid,
          text: I18n.t('common.form.save')
        }
      }
    };
    return (
      <form className='tax-form' onSubmit={handleSubmit}>
        <FieldArray
          name='taxRates'
          component={TaxRateFieldArray}
          categories={categories}
          facilities={facilities}
          taxRates={taxRates}
          facility={facility}
          tax={tax}
          handleCategoriesChange={this.handleCategoriesChange}
          handleApplyToAllChange={this.handleApplyToAllChange}
        />
        {
          modifyPage ? null :
            <Row>
              <AddItem
                addAction={() => push('taxRates', {...emptyTaxRate})}
                removeAction={() => pop('taxRates')}
                length={taxRates && taxRates.length}
                textMode={'right'}
                alignMode={'left'}
                text={'taxes.form.addTaxRate'}
              />
            </Row>
        }
        <SubmitSection settings={settings}/>
      </form>
    );
  }
}

TaxForm.propTypes = {
  categories: PropTypes.array.isRequired,
  facilities: PropTypes.array,
  taxRates: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  modifyPage: PropTypes.bool,
  array: PropTypes.shape({
    push: PropTypes.func.isRequired,
    pop: PropTypes.func.isRequired,
  }),
  facility: PropTypes.object.isRequired,
  tax: PropTypes.object.isRequired,
  integrationState: PropTypes.object.isRequired,
};

export default reduxForm({
  form: 'tax',
  enableReinitialize: true,
  initialValues,
  validate
})(TaxForm);
