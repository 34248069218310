/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {formValueSelector} from 'redux-form';
import {goBack} from 'react-router-redux';

import * as apiActions from '../../../actions/apiActions';
import * as dataNames from '../../../constants/dataNames';
import FormWrapper from '../../common/form/FormWrapper';
import {getIntegrationState} from '../../../selectors/integration/integrationSelectors'; // eslint-disable-line import/no-named-as-defaul
import TaxForm from '../common/TaxForm'; // eslint-disable-line import/no-named-as-defaul
import createPayload from '../common/createPayload';


export class CreateTaxPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  componentWillMount(){
    this.props.actions.getUnpaginatedData('/api/categories', dataNames.categories);
    this.props.actions.getUnpaginatedData('/api/facilities', dataNames.facilities);
  }

  redirect() {
    this.props.actions.goBack();
  }

  onSubmit(taxes) {
    const payload = createPayload(taxes);
    return this.props.actions.postData(
      '/api/taxes/multiple',
      payload,
      dataNames.taxes,
      {
        success: 'taxes.successAdd',
        fail: 'taxes.failAdd'
      },
      null,
      this.redirect
    );
  }

  render() {
    const {facilities, categories, taxRates, facility, integrationState} = this.props;
    return(
      <FormWrapper title={'taxes.form.title'} goBack={this.redirect}>
        <TaxForm
          categories={categories}
          facilities={facilities}
          taxRates={taxRates}
          facility={facility}
          onSubmit={this.onSubmit}
          integrationState={integrationState} />
     </FormWrapper>
    );
  }
}

CreateTaxPage.propTypes = {
  actions: PropTypes.shape({
    getUnpaginatedData: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired
  }),
  categories: PropTypes.array.isRequired,
  facilities: PropTypes.array.isRequired,
  taxRates: PropTypes.array.isRequired,
  facility: PropTypes.object,
  integrationState: PropTypes.object.isRequired,
};

const selector = formValueSelector('tax');

function mapStateToProps(state) {
  const taxRates = selector(state, 'taxRates') || [];
  const {categories, facilities, facility} = state;
  const integrationState = getIntegrationState(state);
  return {
    categories,
    facilities,
    taxRates,
    facility,
    integrationState,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({},apiActions, {goBack}
  );
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (CreateTaxPage);
