import {I18n} from 'react-redux-i18n';
import {getDuplicateNamesByLevel,setLocationErrors} from '../../storage-locations/validate';

const validate = (values, props) => {
  const errors = {};
  if(values.child_locations)  {
    const duplicates = getDuplicateNamesByLevel(values.child_locations); 
    setLocationErrors(values,errors,duplicates,0,['locationName','areaName','subSectionName'], props);

    // require location square footage
    values.child_locations.forEach( (loc,idx) => {
      if(!loc.total_square_footage){
        errors.child_locations[idx].total_square_footage = I18n.t('common.form.isRequired', {
          fieldName: I18n.t('locations.form.retailSqFootage')
        });
      }
    });
  }

  return errors;
};

export default validate;
