import repeat from 'lodash.repeat';

export default function flattenLocations(locations, level = 0, result = []) {
  locations.sort((a, b) =>  {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
  locations.forEach(location => {
    const hasChildren = !!(location.child_locations && location.child_locations.length);
    if (level) {
      result.push({
        value: location.id,
        disabled: hasChildren,
        is_sales_location: location.is_sales_location,
        shared_location_id: location.shared_location_id,
        text: repeat('-', 2 * (level - 1)) + ' ' + location.name
      });
    }
    if (hasChildren) {
      flattenLocations(location.child_locations, level + 1, result);
    }
  });
  return result;
}
