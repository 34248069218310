import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {Button} from 'react-bootstrap';
import {FaAngleDown, FaAngleRight} from 'react-icons/fa';
import {I18n} from 'react-redux-i18n';
import TablePageWrapper from '../common/grid/TablePageWrapper';
import {addTooltip} from '../../util/tooltipHelper';

const UOM = {
  Grams: 'GR',
  Each: 'EA'
};

class InventorySyncPackageResult extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.packageDetails = this.packageDetails.bind(this);
    this.isRowExpandable = this.isRowExpandable.bind(this);
    this.ref = React.createRef();
    this.state = {
      selectedRow: {}
    };
  }

  expandRow(row) {
    if (row.integrator_id === this.state.selectedRow.integrator_id) {
      this.setState({selectedRow: {}});
    }
    else {
      this.setState({selectedRow: row});
    }
  }

  isRowExpandable(row) {
    const { isBiotrack } = this.props.integrationState;
    const pkgs = get(row, 'mjpPackages');
    const isPrepack = pkgs[0].is_prepack === 1;
    return isPrepack && !isBiotrack && row.integrator_id === this.state.selectedRow.integrator_id;
  }

  getRowExpandIcon(row) {
    const pkgs = get(row, 'mjpPackages');
    const isPrepack = pkgs[0].is_prepack === 1;
    if (!isPrepack) {
      return null;
    }
    return this.isRowExpandable(row)
      ? (<FaAngleDown/>)
      : (<FaAngleRight/>);
  }


  packageDetails(row) {
    const pkgs = get(row, 'mjpPackages');
    return (
      <table className='table table-striped table-bordered' style={{width: '100%', marginTop: '-30px'}}>
        <thead>
          <tr>
            <th>{I18n.t(`inventory.sync.table.columns.prepack_name`)}</th>
            <th>{I18n.t(`inventory.sync.table.columns.package_id`)}</th>
            <th>{I18n.t(`inventory.sync.table.columns.unit_size`)}</th>
            <th>{I18n.t(`inventory.sync.table.columns.quantity`)}</th>
            <th>{I18n.t(`inventory.sync.table.columns.location`)}</th>
          </tr>
        </thead>
        <tbody>
          {pkgs.map((item, index) => {
            return (
              <tr key={index}>
                <td>{item.name}</td>
                <td>{item.package_code}</td>
                <td>{item.prepack_weight} g</td>
                <td>{item.quantity} {item.uom}</td>
                <td>{item.location}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  render() {
    const { discrepantPackages, handleItemSelect, handlePrepackItemSelect, selectedCategories, adjustedItems, integrationState } = this.props;

    const { isBiotrack } = integrationState;

    const integrator = isBiotrack ? 'Biotrack' : 'Metrc';

    // Adds a + in front of the value if it is a positive value
    const formatValueCell = (value, uom) => {
      if (value > 0) {
        return '+' + value + ' ' + uom;
      }
      return value + ' ' + uom;
    };
    return (
      discrepantPackages ?
        <div className='inventory-sync-packages'>
          <TablePageWrapper
            ref={this.ref}
            settingKey='inventory-sync-package'
            className='inventory-sync-package-result'
            columns={[
              {
                width: '10px',
                dataSort: false,
                formatter: (cell, row) => {
                  return this.getRowExpandIcon(row);
                },
                dataId: 'integrator_id',
                searchable: false
              },
              {
                name: 'inventory.sync.table.columns.product_name',
                dataId: 'integrator_name',
                width: '80px',
                formatter: (cell) => addTooltip(cell)
              },
              {
                name: 'inventory.sync.table.columns.vendor',
                dataId: 'vendor',
                width: '60px',
                formatter: (cell, row) => {
                  const vendors = row.mjpPackages.map(mjpPackage => mjpPackage.vendor);
                  const unique_vendors = vendors.filter((vendor, index, self) => self.indexOf(vendor) === index);
                  return unique_vendors.length === 1 ? unique_vendors[0] : 'Multiple';
                }
              },
              {
                name: isBiotrack ? 'inventory.sync.table.columns.biotrack_id' : 'inventory.sync.table.columns.metrc_id',
                dataId: 'integrator_id',
                width: '40px',
                formatter: (cell) => addTooltip(cell)
              },
              {
                name: 'inventory.sync.table.columns.location',
                dataId: 'location',
                width: '50px',
                formatter: (cell, row) => {
                  const locations = row.mjpPackages.map(mjpPackage => mjpPackage.location);
                  const unique_locations = locations.filter((location, index, self) => self.indexOf(location) === index);
                  return addTooltip(unique_locations.length === 1 ? unique_locations[0] : I18n.t('inventory.sync.table.multiple'));
                }
              },
              {
                name: 'inventory.sync.table.columns.discrepancy',
                dataId: 'discrepancy',
                width: '50px',
                className: 'discrepancy',
                formatter: (cell, row) => {
                  const uom = row.mjpPackages[0].is_prepack ? 'GR' : get(row, 'mjpPackages[0].uom');
                  return Math.abs(cell) + ' ' + uom;
                }
              },
              {
                name: 'inventory.sync.table.columns.med_weight',
                dataId: '',
                width: '60px',
                className: 'mjp',
                formatter: (cell, row) => {
                  const isPrepack = get(row, 'mjpPackages[0].is_prepack');
                  const prepackWeight = get(row, 'mjpPackages[0].prepack_weight');
                  const medWeight = get(row, 'mjpPackages[0].medicated_weight');
                  const weight = isPrepack && isBiotrack ? prepackWeight : medWeight;
                  return weight ? weight : I18n.t('inventory.sync.table.na');
                }
              },
              {
                name: 'inventory.sync.table.columns.mjp_qty',
                dataId: 'mjp_qty',
                width: '60px',
                className: 'mjp-qty',
                formatter: (cell, row) => {
                  if (row.mjpPackages[0].is_prepack) {
                    return row.mjpPackages.reduce((acc, mjpPackage) => acc + (mjpPackage.quantity * mjpPackage.prepack_weight), 0) + (isBiotrack ? get(row, 'integrator_uom') : ' GR');
                  }
                  else {
                    return row.mjpPackages.reduce((acc, mjpPackage) => acc + mjpPackage.quantity, 0) + ' ' + get(row, 'mjpPackages[0].uom');
                  }
                }
              },
              {
                name: isBiotrack ? 'inventory.sync.table.columns.biotrack_qty' : 'inventory.sync.table.columns.metrc_qty',
                dataId: 'integrator_quantity',
                width: '60px',
                className: isBiotrack ? 'biotrack' : 'metrc',
                formatter: (cell, row) => cell + ' ' + get(row, 'integrator_uom')
              },
              {
                name: isBiotrack ? 'inventory.sync.table.columns.adjust_mjp_to_biotrack' : 'inventory.sync.table.columns.adjust_mjp_to_metrc',
                dataId: 'discrepancy',
                width: '100px',
                className: isBiotrack ? 'adjust-mjp-biotrack' : 'adjust-mjp-metrc',
                formatter: (cell, row) => {
                  const isPrepack = get(row, 'mjpPackages[0].is_prepack');
                  const integrator_id = get(row, 'integrator_id');
                  const buttonDisabled = adjustedItems.some(item => item.integrator_id === integrator_id);
                  const bgColor = (buttonDisabled) ? '#ccc' : '';
                  const color = (buttonDisabled && adjustedItems.some(item => item.integrator_id === integrator_id && item.target === 'mjp')) ? 'dodgerblue' : '';
                  const mjpUom = get(row, 'mjpPackages[0].uom');
                  const prepackWeight = get(row, 'mjpPackages[0].prepack_weight');

                  if (isPrepack) {
                    return (
                      <div style={{ 'background-color': bgColor, color }}>
                        <Button
                          onClick={() => isBiotrack ? handleItemSelect(row, 'mjp') : handlePrepackItemSelect(row)}
                          style={{ 'background-color': bgColor, color }}
                          disabled={buttonDisabled}
                        >
                          {isBiotrack ? formatValueCell(cell * prepackWeight, mjpUom) : I18n.t('inventory.sync.actions.adjust')}
                        </Button>
                      </div>
                    );
                  }
                  const integratorUom = get(row, 'integrator_uom');

                  if (mjpUom === UOM[integratorUom]) {
                    return (
                      <div style={{ 'background-color': bgColor, color }}>
                        <Button
                          onClick={() => handleItemSelect(row, 'mjp')}
                          style={{ 'background-color': bgColor, color }}
                          disabled={buttonDisabled}
                        >
                          {formatValueCell(cell, mjpUom)}
                        </Button>
                      </div>
                    );
                  }
                  const medWeight = get(row, 'mjpPackages[0].medicated_weight');
                  const quantity = mjpUom === 'EA' ? cell : cell * medWeight;
                  return (
                    <div style={{ 'background-color': bgColor, color }}>
                      <Button
                        onClick={() => handleItemSelect(row, 'mjp')}
                        style={{ 'background-color': bgColor, color }}
                        disabled={buttonDisabled}
                      >
                        {
                          formatValueCell(quantity, mjpUom)}
                      </Button>
                    </div>
                  );
                }
              },
              {
                name: isBiotrack ? 'inventory.sync.table.columns.adjust_biotrack' : 'inventory.sync.table.columns.adjust_metrc',
                dataId: 'discrepancy',
                width: '100px',
                className: isBiotrack ? 'adjust-biotrack' : 'adjust-metrc',
                formatter: (cell, row) => {
                  const isPrepack = get(row, 'mjpPackages[0].is_prepack');
                  const integratorUom = get(row, 'integrator_uom');
                  const mjpUom = get(row, 'mjpPackages[0].uom');
                  const integrator_id = get(row, 'integrator_id');
                  const buttonDisabled = adjustedItems.some(item => item.integrator_id === integrator_id);
                  const bgColor = (buttonDisabled) ? '#ccc' : '';
                  const color = (buttonDisabled && adjustedItems.some(item => item.integrator_id === integrator_id && item.target !== 'mjp')) ? 'limegreen' : '';

                  if (isPrepack || mjpUom === UOM[integratorUom]) {
                    return (
                      <div style={{ 'background-color': bgColor, color }}>
                        <Button
                          onClick={() => handleItemSelect(row, integrator.toLowerCase())}
                          style={{ 'background-color': bgColor, color }}
                          disabled={buttonDisabled}
                        >
                          {formatValueCell(-cell, UOM[integratorUom])}
                        </Button>
                      </div>
                    );
                  }
                  const medWeight = get(row, 'mjpPackages[0].medicated_weight');
                  return (
                    <div style={{ 'background-color': bgColor, color }}>
                      <Button
                        onClick={() => handleItemSelect(row, integrator.toLowerCase())}
                        style={{ 'background-color': bgColor, color }}
                        disabled={buttonDisabled}
                      >
                        {formatValueCell(-cell * medWeight, UOM[integratorUom])}
                      </Button>
                    </div>
                  );
                }
              }
            ]}
            data={discrepantPackages.filter(pkg => selectedCategories.includes(get(pkg, 'mjpPackages[0].category_id')))}
            hideSearch={true}
            bstProps={{
              trClassName: 'order-history-table-row table-bordered',
              options: {
                onRowClick: (row) => { this.expandRow(row); },
              },
              expandableRow: (row) => this.isRowExpandable(row),
              expandComponent: this.packageDetails,
            }}
          />
        </div>
        : null
    );
  }
}

InventorySyncPackageResult.propTypes = {
  discrepantPackages: PropTypes.array,
  handleItemSelect: PropTypes.func.isRequired,
  handlePrepackItemSelect: PropTypes.func.isRequired,
  selectedCategories: PropTypes.array.isRequired,
  adjustedItems: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
};

export default InventorySyncPackageResult;
