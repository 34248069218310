import React from 'react';
import PropTypes from 'prop-types';
import {Button, Card} from 'react-bootstrap';


const NamedDefinitions = (props) => {

  return (
    <Card>
      <Card.Header>
        Labels Defined From This Base Label
        <Button
          size='sm'
          style={{position: 'relative', top: '-5px', borderRadius: '2px !important'}}
          className='btn btn-primary btn-sm float-right'
          onClick={(event) => {
            event.target.blur();
            props.onAdd('named');
          }}
        >
          Add
        </Button>
      </Card.Header>
      <Card.Body>
        <table className='table table-striped table-bordered'>
          <thead>
          <tr>
            <th style={{width: '80%'}}>Name</th><th>Status</th>
          </tr>
          </thead>
          <tbody>
          {props.definitions.map((definition, index) => {
            return (
              <tr key={index}>
                <td style={{fontWeight: index === props.currentDefinitionIndex ? 'bold' : 'normal'}}>
                  <a href='#'
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      props.onSelectNamedDefinition(index);
                    }}
                  >
                    {definition.name}
                  </a>
                </td>
                <td style={{color: definition.is_active ? 'green' : 'red'}}>{definition.is_active ? 'Active' : 'Disabled'}</td>
              </tr>
            );
          })}
          </tbody>
        </table>
      </Card.Body>
    </Card>
  );
};

NamedDefinitions.defaultProps = {
  onSelectNamedDefinition: () => {},
};

NamedDefinitions.propTypes = {
  definitions: PropTypes.array.isRequired,
  currentDefinitionIndex: PropTypes.number.isRequired,
  onSelectNamedDefinition: PropTypes.func,
};

export default NamedDefinitions;
