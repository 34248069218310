import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';
import {getUnpaginatedData, deleteData, putData} from '../../../actions/apiActions';
import * as selectedDataActions from '../../../actions/selectedDataActions';
import {handleComplexSelectRow} from '../../../actions/helpers/selectedDataHelper';
import {getSelectedMultipliersData, getPricingMultipliersListingData} from '../../../selectors/multiplierListingSelectors';
import * as dataNames  from '../../../constants/dataNames';
import {unsetData} from '../../../actions/dataActions';
import PageTitle from '../../common/PageTitle';
import TablePageWrapper from '../../common/grid/TablePageWrapper';
import InProgressOverlay from '../../common/InProgressOverlay';
import * as p from '../../../constants/permissions';
import SingleActionColumn from '../../common/grid/columns/SingleActionColumn';
import ModalWrapper from '../../common/ModalWrapper';

const tabStatuses = {inactive: 'inactive', active: 'active'};

class PricingMultiplierListingPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.createMultiplier = this.createMultiplier.bind(this);
    this.editMultiplier = this.editMultiplier.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.switchTab = this.switchTab.bind(this);
    this.activateMultiplier = this.activateMultiplier.bind(this);
    this.inactivateMultiplier = this.inactivateMultiplier.bind(this);
    this.setMultiplierActivity = this.setMultiplierActivity.bind(this);
    this.loadMultipliers = this.loadMultipliers.bind(this);
    this.showConfirmation = this.showConfirmation.bind(this);
    this.hideConfirmation = this.hideConfirmation.bind(this);

    this.setupTabs();
    this.setupColumns();

    this.state = {
      activeTab: props.params.status === 'inactive' ? tabStatuses.inactive : tabStatuses.active,
      confirmationModal: {
        show: false,
        multiplier: null,
      },
      loading: false,
    };
  }

  setupTabs() {
    const actions = [
      {id: 'createMultiplier', func: this.createMultiplier , text: 'retail.pricingMultiplier.createMultiplier' , requireSelect: false, glyph: 'plus-sign'},
      {id: 'EditMultiplier', func: this.editMultiplier , text: 'retail.pricingMultiplier.edit' , requireSelect: true, glyph: 'edit'}
    ];
    this.tabs = [
      {
        id: 'activeTab',
        eventKey: 'active',
        title: 'retail.pricingMultiplier.active',
        path: '/retail/pricing-multipliers/active',
        actions,
      },
      {
        id: 'inactiveTab',
        eventKey: 'inactive',
        title: 'retail.pricingMultiplier.inactive',
        path: '/retail/pricing-multipliers/inactive',
        actions,
      }
    ];
  }

  setupColumns() {
    const commonColumns = [
      {
        name: I18n.t('retail.pricingMultiplier.multiplierName'),
        dataId: 'name',
        width: '300px',
        headerAlign: 'left',
        dataAlign: 'left'
      },
      {
        name: I18n.t('retail.pricingMultiplier.selectedProducts'),
        dataId: 'selected_products',
        width: '300px',
        headerAlign: 'left',
        dataAlign: 'left'
      },
    ];
    const actionColumn = {
      hidden: false,
      dataSort: false,
      permissions: [p.manage_pricing_multipliers],
      columnClassName: 'actions-column',
      width: '170px',
    };
    this.activeColumns = [
      ...commonColumns,
      {
        ...actionColumn,
        formatter: SingleActionColumn({
          permissions: [p.manage_pricing_multipliers],
          label: 'retail.pricingMultiplier.inactivate.title',
          action: (e, row) => this.showConfirmation(row)
        }),
      }
    ];

    this.inactiveColumns = [
      ...commonColumns,
      {
        ...actionColumn,
        formatter: SingleActionColumn({
          permissions: [p.manage_pricing_multipliers],
          label: 'retail.pricingMultiplier.activate.title',
          action: (e, row) => this.activateMultiplier(row)
        }),
      }
    ];
  }

  componentDidMount() {
    this.props.actions.getUnpaginatedData('/api/categories', dataNames.categories);
    this.loadMultipliers();
  }

  componentWillUnmount() {
    this.props.actions.unsetData(dataNames.pricingMultipliers);
  }

  createMultiplier() {
    this.props.actions.push('/retail/pricing-multipliers/create');
  }

  editMultiplier() {
    const {selectedPricingMultiplierData} = this.props;
    this.props.actions.push(`/retail/pricing-multipliers/modify/${selectedPricingMultiplierData[0]}`);
  }

  handleSelect(isSelected, rows) {
    this.props.actions.handleComplexSelectRow(rows, dataNames.pricingMultiplierData, 'set');
  }

  loadMultipliers() {
    this.setState({loading: true});
    this.props.actions.handleComplexSelectRow([], dataNames.pricingMultiplierData, 'clear');
    this.props.actions.unsetData(dataNames.pricingMultipliers);
    const messages = {failed: 'retail.pricingMultiplier.loadMultipliersFailed'};
    const active = this.state.activeTab === tabStatuses.inactive ? 0 : 1;
    this.props.actions.getUnpaginatedData('/api/pricing_multipliers', dataNames.pricingMultipliers, messages, {active, with_relations: ['priceLists']})
      .then(() => {
        this.setState({loading: false});
      }).catch(() => {
        this.setState({loading: false});
      });
  }

  switchTab(activeTab) {
    const tab = this.tabs.find(tab => tab.eventKey === activeTab) || this.tabs[0];
    this.setState({activeTab}, this.loadMultipliers);
    this.props.actions.push(tab.path);
  }

  activateMultiplier(multiplier) {
    if (!multiplier) return;
    this.setMultiplierActivity(multiplier.id, 1);
  }

  inactivateMultiplier() {
    const multiplier = this.state.confirmationModal.multiplier;
    if (!multiplier) return;
    this.setMultiplierActivity(multiplier.id, 0);
  }

  setMultiplierActivity(id, active) {
    this.setState({loading: true});
    const messages = {
      success: active ? I18n.t('retail.pricingMultiplier.activate.success') : I18n.t('retail.pricingMultiplier.inactivate.success'),
      fail: active ? I18n.t('retail.pricingMultiplier.activate.failed') : I18n.t('retail.pricingMultiplier.inactivate.failed'),
    };
    this.props.actions.putData('/api/pricing_multipliers/' + id, {active}, dataNames.pricingMultipliers, messages)
      .then(() => {this.loadMultipliers();})
      .catch(() => {this.loadMultipliers();});
  }

  showConfirmation(multiplier) {
    this.setState({confirmationModal: {show: true, multiplier}});
  }

  hideConfirmation() {
    this.setState({confirmationModal: {show: false, multiplier: null}});
  }

  render() {
    const {pricingMultipliers, selectedPricingMultiplierData} = this.props;
    const {activeTab, loading, confirmationModal} = this.state;
    const modalMultiplierName = confirmationModal.multiplier ? confirmationModal.multiplier.name : '';
    const modalTitle = I18n.t('retail.pricingMultiplier.inactivate.modalTitle', {modalMultiplierName});
    return (
      <div className='pricing-multiplier-listing'>
        <PageTitle primaryText={I18n.t('retail.pricingMultiplier.title')}/>
        <InProgressOverlay isActive={loading} message={I18n.t('retail.pricingMultiplier.loading')}/>
        <ModalWrapper
          Component={false}
          title={modalTitle}
          headerClass='bg-info-dark'
          onHide={this.hideConfirmation}
          showModal={confirmationModal.show}
          okayButton={{show: true, text: 'general.yes', onClick: this.inactivateMultiplier}}
          cancelButton={{show: true, text: 'general.no', onClick: this.hideConfirmation}}
          dialogClassName='modal-sm'
          version={2}
        >
          <div>{I18n.t('retail.pricingMultiplier.inactivate.modalConfirmation')}</div>
        </ModalWrapper>
        <TablePageWrapper
          ref={this.ref}
          settingKey='retail-pricing-multipliers'
          activeTab={activeTab}
          switchTab={this.switchTab}
          tabs={this.tabs}
          columns = {activeTab === tabStatuses.active ? this.activeColumns : this.inactiveColumns}
          data = {pricingMultipliers}
          selectedRows = {selectedPricingMultiplierData}
          handleSelect = {this.handleSelect}
          hideExport = {true}
          hideScanSearch = {true}
          hideTableControls={true}
          external={false}
          bstProps={{
            ignoreSinglePage: true,
            selectRow: {
              mode: 'radio'
            }
          }}
          className='retail-multiplier-table'
        />
      </div>
    );
  }
}

PricingMultiplierListingPage.propTypes = {
  actions: PropTypes.shape({
    handleComplexSelectRow: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    deleteData: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired
  }),
  pricingMultipliers: PropTypes.array.isRequired,
  selectedPricingMultiplierData: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  const pricingMultipliers = getPricingMultipliersListingData(state);
  const selectedPricingMultiplierData = getSelectedMultipliersData(state);
  return {
    pricingMultipliers,
    selectedPricingMultiplierData
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {getUnpaginatedData, deleteData, handleComplexSelectRow, selectedDataActions, push, unsetData, putData};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PricingMultiplierListingPage);
