import {reduxForm} from 'redux-form';
import {DELIVER_ORDER_SETUP_FORM} from '../../../constants/forms';
import DeliveryOrderSetupForm from './DeliveryOrderSetupForm';
import validate from './validate';

const DeliverOrderFormWrapper = reduxForm({
  enableReinitialize: true,
  form: DELIVER_ORDER_SETUP_FORM,
  validate
})(DeliveryOrderSetupForm);

export default DeliverOrderFormWrapper;