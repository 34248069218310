import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as applicationModes from '../../constants/applicationModes';
import {getApplicationMode} from '../../selectors/applicationModesSelectors';
import CheckinLeafMJPPage from '../../components/customers/checkin/leaf-mjp/CheckinLeafMJPPage';
import {getCustomCheckinForm} from '../../selectors/integrationSelectors';


export class CheckinPageComponentByModeWrapper extends React.PureComponent {
  render() {
    const {mode, route: {components}, ...props} = this.props;

    const Component = this.props.customCheckinForm === 'leaf_mjp' ? CheckinLeafMJPPage : components[mode] || components[applicationModes.normal];


    return (
      <Component {...props}/>
    );
  }
}

CheckinPageComponentByModeWrapper.propTypes = {
  mode: PropTypes.oneOf(Object.keys(applicationModes).map((k) => applicationModes[k])), //eslint-disable-line
  components: PropTypes.object,
  customCheckinForm: PropTypes.string,
};

function mapStateToProps(state) {

  return {
    mode: getApplicationMode(state),
    customCheckinForm: getCustomCheckinForm(state),
  };
}

export default connect(mapStateToProps)(CheckinPageComponentByModeWrapper);
