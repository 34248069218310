import {createSelector} from 'reselect';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import moment from 'moment';

import * as dataNames from '../../constants/dataNames';
import {convertDbDateToFormInputDate} from '../../util/dateHelpers';
import {getTimezone} from '../timezoneSelectors';
import {getCurrentFacilityUsers} from '../usersSelectors';
import {roundQty} from '../uomsSelectors'; // this is a selector which returns a function when state is passed to it, it's not a usable rounding function til after it's been run

export const getHarvestBatchHistoryRaw = state => state[dataNames.harvestBatchHistory];

/**
 * Creates a selector for generating table-ready harvest history data
 *
 * @type {Reselect.Selector}
 */
export const getHarvestBatchHistoryTableData = createSelector(
  [getHarvestBatchHistoryRaw, getTimezone, getCurrentFacilityUsers, roundQty],
  (historyRecords, timezone, users, roundingFunc) => {
    // Sort the records so 'created' appears first and the order is clear
    const sortedHistoryRecords = sortHistory(historyRecords);

    return generateHistoryItems(sortedHistoryRecords, timezone, users, roundingFunc);
  }
);


/**
 * Reduces and reshapes raw history records while tracking starting/current values
 *
 * @param historyRecords
 * @param timezone
 * @param users
 * @returns {*}
 */
const generateHistoryItems = (historyRecords, timezone, users, roundingFunc) => {
  // Declare variables to be referenced in record processing loop
  const {beforePackagingLevels, ...startingValues} = harvestHistoryStartingValues();
  let {
    previousHistoryItem, previousModel, totalWaste
  } = startingValues;

  const historyItems = historyRecords.reduce((historyItems, historyRecord, i) => {

    // TRANSFORM THE MODEL

    const model = transformModel(historyRecord, historyRecord.model);

    // CREATE THE HISTORY ITEM

    const created_at = convertDbDateToFormInputDate(historyRecord.created_at, timezone);
    const event_date = convertDbDateToFormInputDate(historyRecord.event_date, timezone);

    const historyItem = {
      model,
      id: historyRecord.id,
      event_type: historyRecord.event_type,
      ...getStartingLevels(historyRecord, model, previousHistoryItem, beforePackagingLevels),
      new_level: model.current_weight,
      new_level_other_material: model.current_other_material_weight,
      message: generateMessage(historyRecord, model),
      created_at,
      event_date,
      created_at_sort: makeSortableDate(historyRecord, historyRecord.created_at, true),
      eventDateSort: makeSortableDate(historyRecord, historyRecord.event_date),
      user_name: getUserName(historyRecord, users),
      // delta is the information in the collapsed view
      delta: calculateDelta(previousModel, model, roundingFunc)
    };

    // Create a delta item specifically for waste
    if (isBatchWasteReportedEvent(historyRecord)) {
      // It's necessary to use the starting_level of the previousHistoryItem (which is a "Harvest Modified" record)
      // because that record actually reduces the starting/new levels. We need that information, otherwise
      // the waste record doesn't show anything in its delta information
      const starting_level = previousHistoryItem.starting_level;

      const itemWaste = parseFloat(starting_level) - parseFloat(historyItem.new_level);

      historyItem.delta.push({
        prev: totalWaste,
        current: (totalWaste += itemWaste),
        name: 'waste_reported',
        uom_display: model.uom_display
      });
    }

    // Add history item to array
    historyItems.push(historyItem);

    // CAPTURE VALUES FOR REMAINING RECORDS TO REFERENCE

    // Capture levels just before packaging so subsequent history records have access to them
    if (isPackagedEventForNonWaste(historyRecord, model)) {
      beforePackagingLevels.level = previousHistoryItem.new_level;
      beforePackagingLevels.other_material_level = previousHistoryItem.new_level_other_material;
    }

    // Capture current model if not of these event types
    if (!isBatchWasteReportedEvent(historyRecord) && !isPackagedEvent(historyRecord)) {
      previousModel = model;
    }

    // Capture current history item into previous
    previousHistoryItem = historyItem;

    return historyItems;
  }, []);

  return historyItems;
};


/**
 * Create object with key/value pairs representing things to track while looping and starting values
 *
 * @returns Object
 */
const harvestHistoryStartingValues = () => {
  return {
    previousHistoryItem: {
      new_level: 0,
      new_level_other_material: 0
    },
    previousModel: null,
    beforePackagingLevels: {
      level: 0,
      other_material_level: 0
    },
    totalWaste: 0
  };
};

/**
 * Sorts raw historical records
 *
 * @param historyRecords
 * @returns []
 */
const sortHistory = (historyRecords) => {
  return [...historyRecords].sort((a,b) => {
    const eventDateSortA = makeSortableDate(a, a.event_date);
    const eventDateSortB = makeSortableDate(b, b.event_date);

    return eventDateSortA - eventDateSortB;
  });
};

/**
 * Get the user name for a given historical record
 *
 * @param historyRecord
 * @param users
 * @returns string
 */
const getUserName = (historyRecord, users) => {
  const user = users.find(user => user.id === historyRecord.user_id);

  return user ? `${user.first_name} ${user.last_name}` : historyRecord.user_name;
};

/**
 * Generate a sortable date, prioritizing "created" event type records
 *
 * @param historyRecord
 * @param date
 * @param asString
 * @returns {string}
 */
const makeSortableDate = (historyRecord, date, asString = false) => {
  if (isBatchCreatedEvent(historyRecord)) return asString ? `0-${historyRecord.id}` : historyRecord.id;

  const dateTimestamp = moment.utc(date).valueOf();
  const prefix = isNaN(dateTimestamp) ? 0 : dateTimestamp;

  return asString ? `${prefix}-${historyRecord.id}` : prefix + historyRecord.id;
};


/**
 * Boolean helpers to determine event type
 *
 * @param historyRecord
 * @returns {boolean}
 */
const isBatchCreatedEvent = (historyRecord) => historyRecord.event_type === 'created';
const isBatchDeactivatedEvent = (historyRecord) => historyRecord.event_type === 'batch_deactivated';
const isBatchWasteReportedEvent = (historyRecord) => historyRecord.event_type === 'batch_waste_reported';
const isBatchUpdatedEvent = (historyRecord) => historyRecord.event_type === 'batch_updated';
const isPackagedEvent = (historyRecord) => historyRecord.event_type === 'batch_packaged';

/**
 * Determines whether it's a packaging event for something other than waste
 *
 * @param event
 * @param model
 * @returns {boolean|*}
 */
export function isPackagedEventForNonWaste(event, model) {
  // model.data.is_waste check added 09/12/2018 - BE is sending packages on waste events; this resolves the immediate display issue
  return event.event_type === 'batch_updated' && model && model.data && model.data.harvest_packages && !model.data.is_waste;
}


/**
 * Generates a message for the history row header
 *
 * @param historyRecord
 * @param model
 * @returns {*}
 */
const generateMessage = (historyRecord, model) => {
  if (isPackagedEventForNonWaste(historyRecord, model)) return I18n.t('batchHistory.eventTypes.harvestPackaged');
  if (isBatchDeactivatedEvent(historyRecord)) return I18n.t('batchHistory.eventTypes.harvestDeactivatedPackaged');

  const regExpHarvestBrackets = new RegExp(/\d+ Plants Harvested Into Harvest Batch \(\w+\)/);
  return regExpHarvestBrackets.test(historyRecord.message) ? historyRecord.message.replace('(', '').replace(')', '') : historyRecord.message;
};

/**
 * Generates the starting levels for a given history record
 *
 * @param historyRecord
 * @param model
 * @param previousHistoryItem
 * @param beforePackagingLevels
 * @returns {}
 */
const getStartingLevels = (historyRecord, model, previousHistoryItem, beforePackagingLevels) => {

  // This needs the package levels, so they must be passed in
  if (isBatchDeactivatedEvent(historyRecord)) {
    return {
      starting_level: beforePackagingLevels.level,
      starting_level_other_material: beforePackagingLevels.other_material_level
    };
  }

  return {
    starting_level: previousHistoryItem.new_level,
    starting_level_other_material: previousHistoryItem.new_level_other_material
  };
};


// TODO: Seems quite a bit in this function could be cleaned up and simplified. Lots of 0 value defaults and such. Could possibly use our DisplayQty component in some places as well.
const deltaConfig = (roundFunc) => [
  {
    name: 'wetWeight',
    get: model => get(model, 'wet_weight_harvest', 0),
    getViewValue: model => `${get(model,'wet_weight_harvest', 0)} ${get(model, 'uom', 'gr')}`,
  },
  {
    name: 'currentWeight',
    get: model => get(model, 'current_weight', 0),
    getViewValue: model => `${get(model, 'current_weight', 0)} ${get(model, 'uom', 'gr')}`,
  },
  {
    name: 'otherMaterialWeight',
    get: model => get(model, 'current_other_material_weight', 0),
    getViewValue: model => `${get(model, 'current_other_material_weight', 0)} ${get(model, 'uom', 'gr')}`,
  },
  {
    name: 'finalWeightRecorded',
    get: model => get(model, 'final_weight_recorded', 0),
    compare: (a, b) => Boolean(a) !== Boolean(b),
    getViewValue: model => get(model, 'final_weight_recorded', 0) ? 'Yes' : 'No',
  },
  {
    name: 'location',
    get: model => get(model, 'inventory_location_id', 0),
    getViewValue: model => get(model, 'location_name', ''),
  },
  {
    name: 'moisture',
    get: model => parseFloat(get(model, 'moisture_pct', 0)),
    getViewValue: model => `${get(model, 'moisture_pct', 0)}%`,
  },
  {
    name: 'calculatedMoistureLoss',
    get: model => parseFloat(get(model, 'calculated_moisture_loss', 0)),
    getViewValue: model => `${get(model, 'calculated_moisture_loss', 0)}%`,
  },
  {
    name: 'finalWeight',
    get: model => get(model, 'final_weight_recorded', 0) ? get(model, 'final_weight', 0) : 0,
    getViewValue: model => get(model, 'final_weight', 0) && get(model, 'final_weight_recorded', 0) ? `${get(model, 'final_weight', 0)} ${get(model, 'uom', 'gr')}` : 'None',
  },
  {
    name: 'waste',
    get: model => Number(model && get(model, 'total_waste_recorded', 0)) + Number( get(model, 'data.waste', 0)),
    getViewValue: model => `${roundFunc(Number( get(model, 'total_waste_recorded', 0)) + Number(get(model, 'data.waste', 0)), get(model, 'uom_display', get(model, 'uom')))} ${get(model, 'uom', 'gr')}`
  },
  {
    name: 'otherMaterialWaste',
    get: model => get(model, 'total_other_material_waste', 0),
    getViewValue: model => `${get(model, 'total_other_material_waste', 0)} ${get(model, 'uom', 'gr')}`,
  },
];

function getValue(model, fieldConfig) {
  if (typeof fieldConfig.get === 'function') {
    return fieldConfig.get(model);
  }
  return model[fieldConfig.name];
}

function isValueChanged(prevValue, currentValue, fieldConfig) {
  if (typeof fieldConfig.compare === 'function') {
    return fieldConfig.compare(prevValue, currentValue);
  }
  return prevValue !== currentValue;
}

function getViewValue(model, fieldConfig) {
  if (typeof fieldConfig.getViewValue === 'function') {
    return fieldConfig.getViewValue(model) || 'null';
  }
  return getValue(model, fieldConfig) || 'null';
}

function calculateDelta(prevState, currentState, roundFunc) {
  return deltaConfig(roundFunc).reduce(
    (acc, fieldConfig) => {
      const prevValue = getValue(prevState, fieldConfig);
      const currentValue = getValue(currentState, fieldConfig);
      if (isValueChanged(prevValue, currentValue, fieldConfig)) {
        acc.push({
          name: fieldConfig.name,
          prev: getViewValue(prevState, fieldConfig),
          current: getViewValue(currentState, fieldConfig),
        });
      }
      return acc;
    },
    []
  );
}

// NOTE: The few functions below involving model transformation were extracted pieces of logic from previous
//  selector incarnations. When time permits, look into these model transformations and determine what's
//  actually necessary and whether there's a better way.

/**
 * Takes a raw model and transforms it for history
 *
 * @param historyRecord
 * @param model
 * @returns {*}
 */
const transformModel = (historyRecord, model) => {
  let transformedModel = JSON.parse(model);

  // TODO: Investigate this model.data nonsense. Why wouldn't there be data? What scenario?
  if (transformedModel.data) transformedModel = gatherModelData(transformedModel);

  transformedModel = addWasteData(historyRecord, transformedModel);

  return transformedModel;
};

/**
 * Transform the waste data for a model
 *
 * @param historyRecord
 * @param model
 * @returns {*}
 */
const addWasteData = (historyRecord, model) => {
  model = {...model};

  if (isBatchWasteReportedEvent(historyRecord)) {
    if (model.data.is_waste && model.data.current_other_material_weight_base !== undefined) {
      model.data.waste_other_material = model.data.waste;
      model.data.waste = 0;
    }
  } else if (isBatchUpdatedEvent(historyRecord)) {
    model.data.waste = 0;
  }

  return model;
};

/**
 * Transforms plant and waste data for a given model
 *
 * @param model
 */
const gatherModelData = (model) => {
  const returnModel = {...model};

  if (returnModel.data.plants && returnModel.data.plants.length) {
    returnModel.plants_count = returnModel.data.plants.length;
    returnModel.plants_waste = returnModel.data.plants.reduce((acc, plant) => acc + plant.total_waste_recorded || 0, 0);
    returnModel.plants_other_material_waste = returnModel.data.plants.reduce((acc, plant) => acc + plant.total_other_material_waste || 0, 0);
  } else {
    returnModel.plants_count = returnModel.data.plants_count;
    returnModel.plants_waste = returnModel.data.plants_waste;
    returnModel.plants_other_material_waste = returnModel.data.plants_other_material_waste;
  }
  returnModel.waste = returnModel.data.waste || returnModel.plants_waste || 0;

  return returnModel;
};
