import React from 'react';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';

import ModalWrapper from './ModalWrapper';
import {getFormWarning} from '../../selectors/forms/openFormSelectors';
import {keepOpenForm, dismissOpenForm} from '../../actions/forms/openFormActions';


const DirtyFormModal = ({push, formWarning, keepOpenForm, dismissOpenForm}) => {

  const stayOnPage = () => {
    const {formName, path} = formWarning;

    keepOpenForm(formName, path);
  };

  const dismissWarning = () => {
    const {formName, path, nextPath} = formWarning;

    dismissOpenForm(formName, path);
    push(nextPath);
  };

  return (
    <ModalWrapper
      title='common.form.dirtyFormModalTitle'
      version={2}
      widthClass='modal-sm'
      okayButton={{
        show: true,
        text: 'common.form.discardChanges',
        variant: 'default',
        onClick: dismissWarning
      }}
      cancelButton={{
        show: true,
        text: 'common.form.returnToForm',
        variant: 'default'
      }}
      onHide={(e) => {
        if (e.buttonClicked !== 'okay') stayOnPage();
      }}
      showModal={formWarning && formWarning.warn}
    >
      <p>{I18n.t('common.form.dirtyFormWarning')}</p>
    </ModalWrapper>
  );
};

const mapStateToProps = (state) => ({
  formWarning: getFormWarning(state)
});

const mapDispatchToProps = {
  keepOpenForm,
  dismissOpenForm,
  push
};

export default connect(mapStateToProps, mapDispatchToProps)(DirtyFormModal);
