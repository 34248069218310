import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {Row, Col} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import {goBack} from 'react-router-redux';

import {clearMessage, addMessage} from '../../../actions/systemActions';
import Spinner from '../../common/Spinner';
import MessageBlock from '../../common/MessageBlock';
import {putItem} from '../../../actions/apiActions';
import PageTitle from '../../common/PageTitle';
import ChangePasswordForm  from './ChangePasswordForm';
import * as messageTypes from '../../../constants/messageTypes';

export class ChangePasswordPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
    this.onFailure = this.onFailure.bind(this);
  }

  onSubmit(formValues) {
    const payload = {
      current_password: formValues.current_password,
      new_password: formValues.new_password
    };
    this.props.actions.putItem(
      `/api/users/password/${formValues.name}/change`,
      payload,
      undefined,
      {success: 'user.password.changeSuccess' ,failed: 'user.password.changeFailed', failedHandler: this.onFailure},
      undefined,
      () => setTimeout(this.props.actions.goBack, 3000) // This is hackish, but gives the user a chance to see the success message before the login page loads.
    );
  }

  onDismiss(id) {
    this.props.actions.clearMessage(id);
    const message = this.props.messages.find(message => id === message.id);
    if(message && message.messageType === messageTypes.success){
      this.props.actions.goBack();
    }
  }

  onFailure(result) {
    if (result && result.response && result.response.data && result.response.data.errors) {
      Object.values(result.response.data.errors).forEach(errors => this.props.actions.addMessage('error', errors, result.response.data.locale));
    }
  }

  render () {
    return (
      <Row>
        <Col md={{span: 6, offset: 3}} xs={{span: 8, offset: 2}}>
          <Row>
          <MessageBlock style={{borderTop: '10px'}} messages={this.props.messages} dismiss={this.onDismiss} timeout={5000} />
            <PageTitle primaryText={I18n.t('user.password.changePassword')} />
            <ChangePasswordForm
              onSubmit = {this.onSubmit}
            />
          </Row>
          <Spinner active={this.props.spinner > 0}/>
        </Col>
      </Row>
    );
  }
}

ChangePasswordPage.propTypes = {
  actions: PropTypes.object.isRequired,
  messages: PropTypes.array.isRequired,
  spinner: PropTypes.number
};

function mapStateToProps(state){
  return {
    messages: state.messages || [],
    spinner: state.spinner
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({putItem, goBack, clearMessage, addMessage}, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (ChangePasswordPage);
