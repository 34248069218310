import React from 'react';
import PropTypes from 'prop-types';

const PageTitle = ({primaryText, secondaryText}) => {
  return (
    <h1>
      <span>{primaryText}</span>
      <span>{secondaryText}</span>
    </h1>
  );
};

PageTitle.propTypes = {
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string
};

export default PageTitle;
