import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import moment from 'moment';
import {Col, Row} from 'react-bootstrap';

import InternationalDatePickerInput from '../../../common/form/InternationalDatePickerInput';
import SubmitSection from '../../../common/form/SubmitSection';
import MultiselectInput from '../../../common/form/MultiselectInput';
import {REGISTER_CLOSING_ACTIVITY_FORM} from '../../../../constants/forms';


class RegisterActivityReportFilterForm extends React.Component {
  render() {
    const {handleSubmit, pristine, reset, registers, onSubmit} = this.props;

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
            <Col sm={6} md={4}>
              <Field
                name='startDate'
                component={InternationalDatePickerInput}
                props={{
                  label: I18n.t('retail.registerClosingActivityReport.filters.startDate')
                }}
              />
            </Col>

            <Col sm={6} md={4}>
              <Field
                name='endDate'
                component={InternationalDatePickerInput}
                props={{
                  label: I18n.t('retail.registerClosingActivityReport.filters.endDate')
                }}
              />
            </Col>
            <Col sm={6} md={4}>
              <Field
                name='registers'
                component={MultiselectInput}
                props={{
                  label: I18n.t('retail.registerClosingActivityReport.filters.registers'),
                  options: registers,
                }}
              />
          </Col>
        </Row>

        <div className='d-block d-sm-block d-none d-md-block d-none d-lg-block clearfix'></div>

        <SubmitSection settings={{
          actionSettings: {
            reset: {
              action: reset,
              text: I18n.t('common.form.reset'),
              pristine
            },
            submit: {
              action: handleSubmit(onSubmit),
              text: I18n.t('common.actions.search'),
            }
          }
        }} />
      </form>
    );
  }
}


export default reduxForm({
  form: REGISTER_CLOSING_ACTIVITY_FORM,
  initialValues: {
    startDate: moment(),
    endDate: moment(),
    registers: []
  }
})(RegisterActivityReportFilterForm);
