import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import ProductStatement from './ProductStatement';

class StatementsIterator extends React.PureComponent{
  constructor(props){
    super(props);

    this.buildStatementsArray = this.buildStatementsArray.bind(this);

    this.state = {
      statements: [],
      repo: false, // selectedCategories
      typeKey: 'global', // category
      extractedIds: [],
    };

  }

  componentWillMount(){
    this.setRepoAndKey(this.props);
  }

  componentWillReceiveProps(nextProps){
    this.setRepoAndKey(nextProps);
    if(Array.isArray(this.props.productStatements)) {
      this.buildStatementsArray(nextProps, false);
    }
  }

  setRepoAndKey(props){
    let repo = false;
    let typeKey = 'global';
    if(props.selectedCategories.length > 0 || props.selectedSubCategories.length > 0) {
      repo    = (props.selectedSubCategories.length > 0) ? 'selectedSubCategories' : 'selectedCategories';
      typeKey = (repo === 'selectedSubCategories') ? 'subcategory' : 'category';
    }
    this.setState({repo, typeKey});
  }

  buildStatementsArray(props){

    if(!props) props = this.props;

    const statements = [];
    const repo = this.state.repo;
    const typeKey = this.state.typeKey;

    if(repo) {
      props[repo].forEach((category) => {

        const isExtracted  = this.props.idIsInExtractedIds(this.props.type, category.id);
        const myStatements = props.productStatements.find((object) => object.id === category.id && object.type === typeKey);

        if (!myStatements) return false;

        const statement        = myStatements.statements[this.props.type];
        const statementInArray = statements.find((s) => s.statement_id === statement.statement_id && !s.extracted);

        if (!statementInArray || isExtracted) {
          statement.categories = [category];
          statement.extracted  = isExtracted;
          statements.push(statement);
          return true;
        }

        if (!isExtracted) {
          statementInArray.categories.push(category);
        }

      });
    } else {
      props.productStatements.forEach((global) => {
        statements.push(global.statements[this.props.type]);
      });
    }

    if((JSON.stringify(statements) !== JSON.stringify(this.props.boundStatements[this.props.type])) || this.props.boundStatements[this.props.type] === undefined){
      this.props.bindStatementsForType(statements, this.props.type);
      this.setState({statements});
    }

  }

  render(){

    const statements = this.state.statements;

    return (
      <div className={statements.length < 2 ? '' : 'panel panel-default'}>
        {
          statements.length < 2
            ? null
            : (
            <div>
              <h3>{I18n.t(`productStatements.${this.props.type}`)}</h3>
              <div className='text-muted'>{I18n.t(`productStatements.moreThanOneStatement`)}</div>
            </div>
          )
        }
        <div className={statements.length < 2 ? '' : 'panel-body'}>
          {

            this.props.boundStatements[this.props.type] === undefined
              ? null
              : this.props.boundStatements[this.props.type].map((statement, index) => {
                return (
                <ProductStatement
                  key={index}
                  type={this.props.type}
                  index={index}
                  onStatementChange={this.props.onStatementChange}
                  statement={statement}
                  statementId={statement.statement_id}
                  value={statement.value === null ? '' : statement.value}
                  showLabel={statements.length < 2}
                  categories={statement.categories}
                  extractCategoryId={this.props.extractCategoryId}
                  onToggleDelete={this.props.onToggleDelete}
                />
              );
              })
          }
        </div>
      </div>
    );

  }

}

StatementsIterator.propTypes = {
  productStatements: PropTypes.array,
  boundStatements: PropTypes.object,
  bindStatementsForType: PropTypes.func.isRequired,
  onStatementChange: PropTypes.func.isRequired,
  selectedCategories: PropTypes.array,
  selectedSubCategories: PropTypes.array,
  type: PropTypes.string,
  idIsInExtractedIds: PropTypes.func.isRequired,
  extractCategoryId: PropTypes.func.isRequired,
  onToggleDelete: PropTypes.func.isRequired,
};

export default StatementsIterator;
