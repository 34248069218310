import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Table, Col} from 'react-bootstrap';
import get from 'lodash.get';
import RequestedPrePackRow from './RequestedPrePackRow';
import * as UOMS from '../../../../../constants/uoms';
import InternationalCurrencyStatic from '../../../../common/form/InternationalCurrencyStatic';
import InternationalDecimalStatic from '../../../../common/form/InternationalDecimalStatic';

const RequestedPrePacks = ({orderLine, columnWidth}) => {
  const subItems = (orderLine.lineTotals && orderLine.lineTotals.subitems) || [];
  const rows = subItems.filter(subItem => subItem.qty);
  const uom = get(orderLine, 'uom', UOMS.GR);
  return (
    <Col xs={12}>
      <h4>{I18n.t('cultivation.transfers.form.itemsRequested')}</h4>
      <Table condensed className={'RequestedPrePacks'}>
        <thead>
        <tr>
          <th>{`${I18n.t(`uoms.${uom}.word`)}s`}</th>
          <th>{I18n.t('purchaseOrders.form.qty')}</th>
          <th>{I18n.t('purchaseOrders.form.total')}</th>
          <th>{I18n.t('purchaseOrders.form.unitCost')}</th>
          <th>{I18n.t('cultivation.transfers.form.lineTotal')}</th>
        </tr>
        </thead>
        <tbody>
        {rows.map((subItem, index) => <RequestedPrePackRow key={index} subItem={subItem} />)}
        </tbody>
        <tfoot>
        <tr>
          <th/>
          <th>{I18n.t('cultivation.transfers.form.totalWeight')}</th>
          <th>
            <InternationalDecimalStatic fractionPartSize={2}>
              {orderLine && orderLine.lineTotals ? orderLine.lineTotals.totalWeight.toFixed(2) : (0).toFixed(2)}
            </InternationalDecimalStatic>
          </th>
          <th>{I18n.t('cultivation.transfers.form.totalCost')}</th>

          <th>
            <InternationalCurrencyStatic>
              {orderLine.lineTotals.totalCost}
            </InternationalCurrencyStatic>
          </th>
        </tr>
        </tfoot>
      </Table>
    </Col>
  );
};

RequestedPrePacks.propTypes = {
  orderLine: PropTypes.object.isRequired,
  columnWidth: PropTypes.number,
};

export default RequestedPrePacks;
