module.exports =  [
  {
    name: 'Planting Batch Plant Label',
    fields: [
/*      {
        label: 'Planting Batch Id',
        postField: 'planting_batch_id',
        value: 1
      },*/
      {
        label: 'Plant Ids',
        postField: 'ids',
        placeholder: 'Multiple ids separated by commas.',
        description: 'Limits plants in Planting Batch to only these.',
        value: '1,2,3,4'
      }
    ],
    url: '/api/labels/generate/[TAGNAME]/for/many',
    tagName: 'cult_plant_tag',
    type: 'POST'
  },
  {
    name: 'Planting Batch Plant Label Small',
    fields: [
      /*      {
       label: 'Planting Batch Id',
       postField: 'planting_batch_id',
       value: 1
       },*/
      {
        label: 'Plant Ids',
        postField: 'ids',
        placeholder: 'Multiple ids separated by commas.',
        description: 'Limits plants in Planting Batch to only these.',
        value: '1,2,3,4'
      }
    ],
    url: '/api/labels/generate/[TAGNAME]/for/many',
    tagName: 'cult_plant_tag_small',
    type: 'POST'
  },
  {
    name: 'Multi Planting Batch Plant Label',
    fields: [
      {
        label: 'Plant Ids',
        postField: 'ids',
        placeholder: 'Multiple ids separated by commas.',
        description: 'Limits plants in Planting Batch to only these.',
        value: '1,2,3,4'
      }
    ],
    url: '/api/labels/generate/[TAGNAME]/for/many',
    tagName: 'cult_plant_tag_all',
    type: 'POST'
  },
  {
    name: 'Planting Batch Plant Label (Legacy Testing)',
    fields: [
      {
        label: 'Plant Ids',
        postField: 'ids',
        placeholder: 'Multiple ids separated by commas.',
        description: 'Limits plants in Planting Batch to only these.',
        value: '1,2,3,4'
      }
    ],
    legacy: true,
    url: '/api/labels/getMultipleByTag',
    tagName: 'cult_plant_tag',
    type: 'POST'
  },
  {
    name: 'Harvest Batch Plant ID',
    fields: [
      {
        label: 'Harvest Batch Id',
        postField: 'harvestId',
        value: 1
      },
      {
        label: 'Plant Ids',
        postField: 'ids',
        stateKeyField: 'harvest_plant_ids',
        description: 'Limits plants in Harvest Batch to only these.  Otherwise all plants are returned.',
        placeholder: 'Optional.  Multiple ids separated by commas.'
      }
    ],
    url: '/api/labels/generate/[TAGNAME]/for/many',
    tagName: 'harvestBatchPlantLabel',
    type: 'POST'
  },
  {
    name: 'Locations: Room',
    stateKeyField: 'room_id',
    fields: [
      {
        label: 'Room Id',
        stateKeyField: 'room_id',
        value: 1
      }
    ],
    url: '/api/labels/generate/[TAGNAME]/for/[ID]',
    tagName: 'location_room_tag',
    defaultId: 1,
    type: 'GET'
  },
  {
    name: 'Locations: Zone',
    fields: [
      {
        label: 'Zone Id',
        stateKeyField: 'zone_id',
        value: 1
      }
    ],
    url: '/api/labels/generate/[TAGNAME]/for/[ID]',
    tagName: 'location_zone_tag',
    type: 'GET'
  },
  {
    name: 'Locations: Section',
    fields: [
      {
        label: 'Section Id',
        stateKeyField: 'section_id',
        value: 1
      }
    ],
    url: '/api/labels/generate/[TAGNAME]/for/[ID]',
    tagName: 'location_section_tag',
    type: 'GET'
  },
  {
    name: 'Coupons: Bar Code',
    fields: [
      {
        label: 'Coupon Id',
        stateKeyField: 'coupon_id',
        value: 1
      }
    ],
    url: '/api/labels/generate/[TAGNAME]/for/[ID]',
    tagName: 'coupon_barcode',
    type: 'GET'
  },
  {
    name: 'Small Package Label',
    fields: [
      {
        label: 'Plant Item Ids',
        postField: 'ids',
        placeholder: 'Multiple ids separated by commas.',
        value: '276'
      }
    ],
    url: '/api/labels/generate/[TAGNAME]/for/many',
    tagName: 'inv_package_tag',
    type: 'POST'
  },
  {
    name: 'Large Package Label',
    fields: [
      {
        label: 'Plant Item Ids',
        postField: 'ids',
        placeholder: 'Multiple ids separated by commas.',
        value: '276'
      }
    ],
    url: '/api/labels/generate/[TAGNAME]/for/many',
    tagName: 'inv_package_tag_large',
    type: 'POST'
  },
  {
    name: 'Choice Package Label',
    fields: [
      {
        label: 'Plant Item Ids',
        postField: 'ids',
        placeholder: 'Multiple ids separated by commas.',
        value: '276'
      }
    ],
    url: '/api/labels/generate/[TAGNAME]/for/many',
    tagName: 'inv_package_tag_all',
    type: 'POST'
  },
  {
    name: 'Choice Package Label Blocks',
    testNamedBlocks: true,
    namedBlocks: [
      {
        ids: [364,364,364,364],
        name: '.25 GR'
      },
      {
        ids: [365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365,365],
        name: '.50 GR'
      },
      {
        ids: [366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366,366],
        name: '1 GR'
      }
    ],
    fields: [
      {
        label: 'Plant Item Ids',
        postField: 'ids',
        placeholder: 'Multiple ids separated by commas.',
        value: '364,365,366'
      }
    ],
    url: '/api/labels/generate/[TAGNAME]/for/many',
    tagName: 'inv_package_tag_all',
    type: 'POST'
  },
  {
    name: 'Patient Package Label',
    fields: [
      {
        label: 'Inventory Item Id',
        postField: 'ids',
        placeholder: 'Multiple ids separated by commas.',
        value: '3' // this is customer id and should not be done like this
      },
      {
        label: 'Order ID',
        postField: 'order_id',
        value: 108
      },
      {
        label: 'Order Product ID',
        postField: 'order_product_id',
        value: 202 // this is wrong it should really be the product id line
      },
      {
        label: 'print_all',
        postField: 'print_all',
        value: 0
      },
    ],
    url: '/api/labels/generate/[TAGNAME]/for/many',
    tagName: 'patient_label_large',
    type: 'POST'
  },
  {
    name: 'Inventory Receipt Iterator',
    fields: [
      {
        label: 'Receipt Id',
        stateKeyField: 'receipt_id',
        value: 8
      }
    ],
    url: '/api/labels/generate/[TAGNAME]/for/[ID]',
    tagName: 'supply_inventory_receipt_small',
    type: 'GET'
  },
  {
    name: 'Order Iterator Patient Labels',
    fields: [
      {
        label: 'Order ID Id',
        stateKeyField: 'order_id',
        value: 75
      }
    ],
    url: '/api/labels/generate/[TAGNAME]/for/[ID]',
    tagName: 'patient_label_large',
    type: 'GET'
  },
];
