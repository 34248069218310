import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {bindActionCreators} from 'redux';
import {goBack, push} from 'react-router-redux';
import {formValueSelector} from 'redux-form';

import * as apiActions from '../../../../actions/apiActions';
import {setData} from '../../../../actions/dataActions';
import * as apiItemActions from '../../../../actions/itemActions';
import * as dataNames from '../../../../constants/dataNames';
import * as itemNames from '../../../../constants/itemNames';
import {getAssemblyPayload, getCalculatedTotal, getIngredientsWithCosts,
  getAssemblyOutputs, checkIngredientsUom, getInitialValuesModifyForm} from '../../../../selectors/assembliesSelectors';
import {getPossibleUoms} from '../../../../selectors/uomsSelectors';
import {getIntegrationState} from '../../../../selectors/integration/integrationSelectors';
import FormWrapper from '../../../common/form/FormWrapper';
import AssemblyFormWrapper from './../common/AssemblyFormWrapper';
import InProgressOverlay from '../../../common/InProgressOverlay';
import {addMessage} from '../../../../actions/systemActions';
import * as messageTypes from '../../../../constants/messageTypes';
import {ASSEMBLY_FORM} from '../../../../constants/forms';

const form = ASSEMBLY_FORM;
const selector = formValueSelector(form);

class ModifyAssemblyPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      costings: [],
      ingredients: [],
      totalCost: null,
      ready: false,
      costingsReady: false,
    };
    this.getPossibleUom = this.getPossibleUom.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount() {
    const {getItem,getUnpaginatedData,postData,unsetItem} = this.props.actions;
    const id = this.props.params.id || '';
    const promises = [];

    unsetItem(itemNames.assembly);

    // get assembly
    promises.push(getItem(`/api/assemblies/${id}`, itemNames.assembly, null, {detailed: 1}));

    // get assembly output item masters
    promises.push(
      getUnpaginatedData('/api/item_masters/manufacturing', dataNames.itemMasters, null, {active: 1, is_medicated: 1})
    );

    // get ingredients + costs
    promises.push(
      getUnpaginatedData('/api/ingredient_items/by_facility', dataNames.ingredients, null, {active: 1},
        (ingredients) => {
          const ingredientsIds = ingredients.map( i => i.id );
          return postData('/api/costing/item_masters/multiple', {ids: ingredientsIds}, dataNames.costings)
            .then(() => this.setState({costingsReady: true}))
            .catch(() => this.setState({costingsReady: true}));
        }
      )
    );

    // get UOMs
    promises.push(getUnpaginatedData('/api/uoms', dataNames.uoms));

    // get categories
    promises.push(getUnpaginatedData('/api/categories', dataNames.categories));

    // get biotrack categories
    if (this.props.integrationState.isBiotrack) {
      promises.push(
        this.props.actions.getUnpaginatedData('/api/biotrack/categories', dataNames.biotrackCategories),
        this.props.actions.getUnpaginatedData('/api/biotrack/categories/mapping', dataNames.biotrackCategoryMappings)
      );
    }

    Promise
      .all(promises)
      .then(() => this.setState({ready: true}))
      .catch(() => this.setState({ready: true}));
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.isValidAssembly && this.props.isValidAssembly) {
      this.props.actions.addMessage(messageTypes.error, 'ei.assemblies.uomChanged');
    }
  }

  componentWillUnmount() {
    this.props.actions.unsetItem(itemNames.assemblyFormData);
  }

  getPossibleUom(defaultUom) {
    return getPossibleUoms(defaultUom, this.props.uoms);
  }


  onSubmit(formData) {
    const {assembly:{id}, assembly} = this.props;
    let postAction = () => this.props.actions.goBack();
    if (formData.saveAndDuplicate) {
      postAction = () => this.saveAndDuplicate(formData);
    }
    return this.props.actions.putData(
      `/api/assemblies/${id}`,
      getAssemblyPayload(formData, assembly),
      dataNames.assemblies,
      { success: 'ei.assemblies.modify.success', failed: 'ei.assemblies.modify.failed' },
      null,
      postAction
    );
  }

  saveAndDuplicate(formData) {
    formData.saveAndDuplicate = false;
    this.props.actions.setItem(formData, itemNames.assemblyFormData);
    this.props.actions.push('/ei/assemblies/create');
  }

  render() {
    const {assembly, products, ingredients, uoms, totalCost, getFormValue, integrationState} = this.props;

    if (assembly && assembly.directions && assembly.directions.length === 0) {
      assembly.directions.push({});
    }

    const allReady = ['ready', 'costingsReady'].every((stateName) => this.state[stateName], this);
    return (
      <FormWrapper title={I18n.t('ei.assemblies.editAssembly')} goBack={this.props.actions.goBack}>
        <div className='modify-assembly-page'>
          {allReady ?
            <AssemblyFormWrapper
              form={form}
              initialValues={assembly}
              onSubmit={this.onSubmit}
              products={products}
              ingredients={ingredients}
              uoms={uoms}
              totalCost={totalCost}
              getFormValue={getFormValue}
              getPossibleUom={this.getPossibleUom}
              addMessage={this.props.actions.addMessage}
              integrationState={integrationState}
            /> :
            <InProgressOverlay isActive={true} message={I18n.t('common.loading')}/>
          }
        </div>
      </FormWrapper>
    );
  }
}

ModifyAssemblyPage.propTypes = {
  actions: PropTypes.shape({
    getUnpaginatedData: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    putData: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    addMessage: PropTypes.func.isRequired,
    setItem: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }),
  params: PropTypes.shape({
    id: PropTypes.string,
  }),
  assembly: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  ingredients: PropTypes.array.isRequired,
  uoms: PropTypes.array.isRequired,
  getFormValue: PropTypes.func.isRequired,
  totalCost: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  isValidAssembly: PropTypes.bool.isRequired,
  integrationState: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const getFormValue = (...names) => selector(state, ...names);
  const formIngredients = getFormValue('ingredients');
  const totalCost = getCalculatedTotal(formIngredients);

  const ingredients = getIngredientsWithCosts(state);
  const isValidAssembly = checkIngredientsUom(state);
  return {
    assembly: getInitialValuesModifyForm(state),
    products: getAssemblyOutputs(state),
    ingredients,
    uoms: state[dataNames.uoms],
    getFormValue,
    totalCost,
    isValidAssembly,
    integrationState: getIntegrationState(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, apiItemActions, {goBack, addMessage, setData, push});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModifyAssemblyPage);
