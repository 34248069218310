import get from 'lodash.get';
import * as dataNames from '../../constants/dataNames';

/**
 * Returning a list of lab results,
 * @uses @CreateTransferPage, ModifyTransferPage
 * @param state
 * @returns {*}
 */
export const getList = (state) => get(state, `[${dataNames.inventoryItemsTestResults}][0]`);

// Linter. Remove this one after file will contain at least two methods.
export default { getList };