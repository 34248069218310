import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash.omit';
import get from 'lodash.get';
import camelCase from 'lodash.camelcase';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack, push} from 'react-router-redux';
import find from 'lodash.find';
import {change, formValueSelector, getFormValues, reset} from 'redux-form';
import {formatClientDate, formatDate} from '../../../util/dateHelpers';
import * as apiActions from '../../../actions/apiActions';
import * as itemActions from '../../../actions/itemActions';
import * as itemNames from '../../../constants/itemNames';
import * as dataNames from '../../../constants/dataNames';
import * as messageTypes from '../../../constants/messageTypes';
import {CUSTOMER_FORM} from '../../../constants/forms';
import { canadian_registration_form, extended_sales_limit, medical_id } from '../../../constants/fileTypes';
import {addMessage} from '../../../actions/systemActions';
import {getOrderedPhysicians} from '../../../selectors/physiciansSelectors';
import {getOrderedClinics} from '../../../selectors/clinicsSelectors';
import {getCaregivers} from '../../../selectors/customerSelectors';
import {
  getAddressFieldsDisabled,
  getComplianceLimits,
  getCustomerNotes,
  getFormFileTypes,
  getGroupsForCurrentCustomer,
  getLastUpdatedComment,
  getModifyCustomerInitialValues,
  getOrderedQualifyingConditions,
  setCustomerQualifyingCondition,
  getDisplaySaveAndCheckIn
} from '../../../selectors/customersSelectors';
import {getDispensaries, hasCanadianFacilities} from '../../../selectors/facilitiesSelectors';
import {getIntegrationSettings} from '../../../selectors/integration/commonSelectors';
import {isOnfleetIntegrator} from '../../../selectors/integration/thirdPartyIntegrationSelectors';
import {getIntegrationState} from '../../../selectors/integration/integrationSelectors';
import {
  getPatientSettings,
  getSalesAgeLimit,
  hasMetrcSalesLimits,
} from '../../../selectors/salesSettingsSelectors';
import {getDefaultVisitReason, getVisitReasons} from '../../../selectors/queueSelectors';
import {getInternationalPhoneCountryCode, getInternationalPhoneNumber} from '../../../util/internationalHelpers';
import CustomerForm from './CustomerForm'; // eslint-disable-line import/no-named-as-default
import FormWrapper from '../../common/form/FormWrapper';
import ScanInputModal from '../../scan-input/ScanInputModal'; // eslint-disable-line import/no-named-as-default
import BirthdateModal from '../common/BirthdateModal';
import CaregiverModal from './CaregiverModal';
import CustomerCheckInModal from '../checkin/CustomerCheckInModal'; // eslint-disable-line import/no-named-as-default
import {formatMarijuanaId} from '../common/utils';
import {getApplicationMode} from '../../../selectors/applicationModesSelectors';
import * as applicationModes from '../../../constants/applicationModes';


import {PA_MED_CAREGIVER, PA_MED_PATIENT} from '../../../constants/idTypes';
import {isCanadaFacility} from '../../../selectors/facility/getFacilityCountry';
import {getFacilityState} from '../../../selectors/facility/getCurrentFacility';
import {isFeatureEnabled} from '../../../selectors/featureToggles';

import {DISABLED_VETERANS, MEDICAL, MMIC} from '../../../constants/consumerTypes';
import {unsetData} from '../../../actions/dataActions';

export class ModifyCustomerPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      ready: false,
      blockCheckIn: false,
      showScanModal: false,
      showCheckInModal: false,
      showBirthdateModal: false,
    };
    this.ensureReferralSource = this.ensureReferralSource.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onSaveAndCheckIn = this.onSaveAndCheckIn.bind(this);
    this.saveCustomer = this.saveCustomer.bind(this);
    this.showScanModal = this.showScanModal.bind(this);
    this.hideScanModal = this.hideScanModal.bind(this);
    this.onScanComplete = this.onScanComplete.bind(this);
    this.handlePaMedPatient = this.handlePaMedPatient.bind(this);
    this.handlePaMedCaregiver = this.handlePaMedCaregiver.bind(this);
    this.getCardInfo = this.getCardInfo.bind(this);
    this.updateFormValues = this.updateFormValues.bind(this);
    this.onBirthdateSubmit = this.onBirthdateSubmit.bind(this);
    this.selectCaregiverPatient = this.selectCaregiverPatient.bind(this);
    this.setPatientAddress = this.setPatientAddress.bind(this);
    this.setPatientFormValues = this.setPatientFormValues.bind(this);
    this.setCaregiverFormValues = this.setCaregiverFormValues.bind(this);
    this.openCheckInModal = this.openCheckInModal.bind(this);
    this.closeCheckInModal = this.closeCheckInModal.bind(this);
    this.checkIn = this.checkIn.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.onViewHistory = this.onViewHistory.bind(this);
    this.syncRegistry = this.syncRegistry.bind(this);
  }

  componentWillMount() {
    const { actions: {getData,getItem,getUnpaginatedData} } = this.props;
    this.resetForm();
    const apiPromises = [];

    apiPromises.push( getUnpaginatedData(
      `/api/consumer_groups`,
      dataNames.customerGroups,
      { failed: I18n.t('customers.getGroups.failed') },
      {is_rewards_program_group: 0, activeAndInactive: true}
    ));

    apiPromises.push( getUnpaginatedData(
      `/api/referral_sources`,
      dataNames.referralSources,
      { failed: I18n.t('customers.getReferralSources.failed') }
    ));

    apiPromises.push( getItem(
      `/api/customers/${this.props.params.id}`,
      itemNames.customer,
      { failed: I18n.t('customers.getCustomer.failed') }
    ));

    apiPromises.push( getItem(
      '/api/csrs',
      itemNames.budTendersObject,
      { failed: I18n.t('customers.getBudTenders.failed') },
      {active: 1}
    ));

    apiPromises.push( getUnpaginatedData(
      `/api/physicians/details`,
      dataNames.physicians
    ));

    apiPromises.push( getUnpaginatedData(
      '/api/customers/tags', dataNames.tags
    ));

    apiPromises.push( getData(
      '/api/queue/visit_reasons',
      dataNames.visitReasons,
      {}
    ));

    apiPromises.push( getItem(
      '/api/customers/compliance_settings',
      itemNames.patientSettings
    ));

    // State integration settings are downloaded to determine whether the current facility is configured to use Leaf.
    // If the facility is configured to use Leaf, patient types are "Medical" and "Out of State", otherwise the
    // patient types are "Medical" and "Recreational".
    // The state integration settings are also downloaded to determine whether Medical ID expiration should be
    // required. The expiration date is required if the current facility is configured to use METRC for Colorado.

    apiPromises.push( getItem(
      '/api/integration-settings',
      itemNames.integrationSettings,
      {failed: 'stateIntegratorSettings.get.failed'}
    ));

    apiPromises.push( getUnpaginatedData(
      '/api/customers/qualifying_conditions',
      dataNames.qualifyingConditions
    ));

    const ready = () => this.setState({ ready: true });
    Promise.all(apiPromises).then(ready).catch(ready);
  }

  resetForm() {
    this.props.actions.unsetItem(itemNames.image);
    this.props.actions.unsetItem(itemNames.customer);
    this.props.actions.unsetItem(itemNames.oracleCaregiver);
    this.props.actions.unsetData(dataNames.qualifyingConditions);
    this.props.actions.reset(CUSTOMER_FORM);
  }

  ensureReferralSource(referralSource) {
    if(!referralSource) return Promise.resolve(null);

    if(typeof referralSource == 'number' && find(this.props.referralSources, {id: referralSource})){
      if(this.props.referralSources.find( (r) => r.id == referralSource )){
        return Promise.resolve(referralSource);
      }
    }else if (get(referralSource,'id',false)) {
      // validate selection of existing referral source:
      if(this.props.referralSources.find( (r) => r.id == referralSource.id )){
        return Promise.resolve(referralSource.id);
      }
    } else {
      // post a new referral source
      return this.props.actions.postData(
        '/api/referral_sources',
        {name: referralSource},
        dataNames.referralSources,
        {failed: 'referralSources.create.failed'}
      ).then( response => response.id);
    }
  }

  // CustomerForm save handler
  onSave(formValues) {
    return this.saveCustomer(formValues, false);
  }

  // Submit customer data to api, shared by onSave and onSaveAndCheckIn
  saveCustomer(formValues, isSaveAndCheckIn) {
    const { customer } = this.props;
    //Now we have to split files and ids on payload
    const {files, ids} = formValues.ids.reduce(
      (acc, item) => {
        if (item.type !== extended_sales_limit && item.type !== canadian_registration_form) {
          acc.ids.push(item);
        } else if (item.type === extended_sales_limit || item.type === canadian_registration_form) {
          acc.files.push(item);
        }

        return acc;
      },
      {files: [], ids: []}
    );

    const isMetrcMd = this.props.applicationMode === applicationModes.metrcMd;
    return Promise.all([this.ensureReferralSource(formValues.customer.referral_source)])
      .then(([referralSourceId]) => {
        const { params } = this.props;
        const caregivers = formValues.caregiverInfo && formValues.caregiverInfo.length
          ? formValues.caregiverInfo.map(
            caregiver => Object.assign({}, caregiver, {
              birth_date: formatDate(caregiver.birth_date),
              medical_id: caregiver.medical_id || null,
              phone_country_code: getInternationalPhoneCountryCode(caregiver.phone),
              phone: getInternationalPhoneNumber(caregiver.phone),
              state_integration_tracking_id: isMetrcMd
                ? formatMarijuanaId(caregiver.state_integration_tracking_id)
                : (caregiver.state_integration_tracking_id || null)
            })
          )
          : [{addresses: []}];
        const customerType = get(formValues, 'customer.type', undefined);
        let filesToIncludeInFiles;
        const filesToExcludeFromIds = filesToIncludeInFiles = [extended_sales_limit];
        const customerData = Object.assign({}, omit(formValues.customer, 'state_integration_tracking_id', 'physician'), {
          caregivers,
          delete_caregiver_ids: formValues.delete_caregiver_ids,
          delete_address_ids: formValues.delete_address_ids,
          birth_date: formatDate(formValues.customer.birth_date),
          type: [MMIC, DISABLED_VETERANS].includes(customerType) ? MEDICAL : customerType,
          is_disabled_veterans: customerType === DISABLED_VETERANS,
          is_not_mmic: customerType !== MMIC,
          phone_numbers: [{
            type: 'default',
            phone_country_code: getInternationalPhoneCountryCode(formValues.customer.number),
            number: getInternationalPhoneNumber(formValues.customer.number),
            id: formValues.customer.number_id ? formValues.customer.number_id : undefined
          }],
          ids: formValues.ids.filter(id => filesToExcludeFromIds.indexOf(id.type) === -1).map(file => {
            const identification_number = (file.type === 'med' && isMetrcMd)
              ? formatMarijuanaId(file.identification_number)
              : file.identification_number;
            return {
              ...file,
              identification_number,
              expired_at: formatDate(file.expired_at),
              effective_at: formatDate(file.effective_at),
            };
          }),
          delete_ids: (customer.ids || []).concat(customer.expired_ids || []).filter(file => !ids.some(f => f.id === file.id)).map(f => f.id),
          files: (formValues.ids || []).filter(id => filesToIncludeInFiles.indexOf(id.type) !== -1).map(file => ({
            ...file,
            file_type: get(file, 'file_type.accept[0]'),
            expires_on: formatDate(file.expired_at),
          })),
          registrations: formValues.registrations,
          delete_file_ids: (customer.files || []).filter(file => !files.some(f => f.id === file.id)).map(f => f.id),
          tags: formValues.customer.tags.map(tag => typeof (tag) === 'object' ? tag.tag_name : tag), // Weird behavior: New tags are string, but old tags are object.
          group_ids: formValues.customer.group_ids.map(group => typeof group === 'object' ? group.id : group).concat(formValues.rewards_group_ids), // Weird behavior: Sometimes this is an array of IDs, but other times its an array of objects
          addresses: formValues.addresses,
          date_provider_can_switch: formatDate(formValues.customer.date_provider_can_switch),
          referral_source: referralSourceId,
          facility_possession_limit: formValues.limitsInfo.facility_possession_limit || undefined,
          facility_sales_limit: formValues.limitsInfo.facility_sales_limit || null,
          facility_plants_veg_limit: formValues.limitsInfo.facility_plants_veg_limit || undefined,
          facility_plants_flower_limit: formValues.limitsInfo.facility_plants_flower_limit || undefined,
          facility_plants_total_limit: formValues.limitsInfo.facility_plants_total_limit || undefined,
          partner_state_expiration_date: formatDate(formValues.customer.partner_state_expiration_date),
          patient_certification_status: formValues.customer.patient_certification_status || undefined,
          physician_recommendation_date: formatDate(formValues.customer.physician_recommendation_date),
          personal_use_producer: Number(formValues.customer.personal_use_producer),
          clinic_id: get(formValues, 'customer.physician.clinic_id', null),
          // This craziness for tracking id handles the fact that the back end throws an error when an existing id is resubmitted
          // Obviously, the better fix would be solving it there... but at present that looks like another call to DB so
          // I did this.  JD 12/20/2017
          state_integration_tracking_id: (formValues.customer.state_integration_tracking_id === undefined)
            ? null
            : (typeof formValues.customer.state_integration_tracking_id === 'string')
              ? formValues.customer.state_integration_tracking_id.trim() === ''
                ? null
                : (formValues.customer.state_integration_tracking_id === customer.state_integration_tracking_id)
                  ? undefined // Don't resubmit same id
                  : formValues.customer.state_integration_tracking_id
              : null,
        });

        // Sets either qualifying_condition_id or new_qualifying_condition on customerData
        setCustomerQualifyingCondition(customerData, formValues, this.props.qualifyingConditions);

        this.setState({blockCheckIn: false});
        return this.props.actions.putItem(
          `/api/customers/${params.id}`,
          customerData,
          itemNames.customer,
          {success: 'customers.modify.success'},
          {}
        ).then( (newCustomer) => {
          // get new caregivers for this patient:
          this.props.actions.getData(
            `/api/customers/${params.id}/caregivers`,
            dataNames.caregivers
          );
          if (!isSaveAndCheckIn) {
            this.props.actions.unsetItem(itemNames.image);
            this.props.actions.goBack();
          }
        }).catch( err => {
          this.setState({blockCheckIn: true});
          throw err;
        });
      })
      .catch(error => this.props.actions.addMessage(messageTypes.error, 'customers.modify.failed'));
  }

  // CustomerForm onSaveAndCheckIn handler
  onSaveAndCheckIn(formValues) {
    return this.saveCustomer(formValues, true).then( (customer) => {
      if(!this.state.blockCheckIn){
        this.openCheckInModal();
      }
    });
  }

  onViewHistory() {
    this.props.actions.push('/patients/' + this.props.customer.id + '/history');
  }

  closeCheckInModal() {
    if (this.props.integrationState.isPaLeaf) {
      this.resetForm();
    }
    this.setState({showCheckInModal: false});
  }

  openCheckInModal() {
    this.setState({showCheckInModal: true});
  }

  checkIn(formValues) {
    const payload = {consumer_id: get(this.props,'customer.id',null)};
    if(formValues.visit_reason_id !== undefined) payload.visit_reason_id = formValues.visit_reason_id;
    if(formValues.preferred_csr !== undefined) payload.preferred_csr = formValues.preferred_csr;
    if(formValues.caregiver_id !== undefined) payload.caregiver_id = formValues.caregiver_id;
    return this.props.actions.postItem(
      '/api/queue/customers',
      payload,
      itemNames.queueCustomer,
      {failed: 'customers.addToQueue.failed', success: 'customers.addToQueue.success'}
    ).then( () => {
      this.props.actions.push(`/patients`);
    }).catch(() => {});
  }


  showScanModal(scanType, idType) {
    return () => {
      this.setState({showScanModal: true, scanType, idType});
    };
  }

  hideScanModal() {
    this.setState({showScanModal: false});
  }

  onBirthdateSubmit(formData){
    const {scanData, idType} = this.state;
    this.getCardInfo({
      dob: formatDate(formData.birth_date),
      registry_id: idType === PA_MED_PATIENT ?
        scanData.customer.state_integration_tracking_id :
        scanData.caregiverInfo.state_integration_tracking_id,
      card_number: idType === PA_MED_PATIENT ?
        scanData.medical_id :
        scanData.caregiverInfo.medical_id
    });
    this.setState({showBirthdateModal: false});
  }

  selectCaregiverPatient(id){
    const {patients} = this.state;

    this.setState({showCaregiverModal: false});
    this.setPatientFormValues(patients[id]);
  }

  getCardInfo(payload){
    if(this.state.idType === PA_MED_CAREGIVER){
      this.props.actions.postItem('/api/leaf/oracle/caregiver_details', payload,
        itemNames.oracleCaregiver,
        {failed: 'customers.integration.caregiverFailed'},
        undefined, (data) => {
          if(!this.props.currentFormValues.customer.state_integration_tracking_id ||
            !data.patients.find(patient => patient.patient_id == this.props.currentFormValues.customer.state_integration_tracking_id)){
            this.setState({
              patients: data.patients,
              showCaregiverModal: true});
          }
          this.setCaregiverFormValues(data, payload);
        }
      );
    }else{
      this.props.actions.postItem('/api/leaf/oracle/patient_details', payload,
        itemNames.oraclePatient, {failed: 'customers.integration.patientFailed'},
        undefined, () => {
          this.props.actions.change(CUSTOMER_FORM, 'customer.birth_date', formatClientDate(payload.dob));
        });
    }
  }

  /**
   * Method which is generated dynamically  uses in "onScanComplete" function
   * @param data
   */
  handlePaMedPatient(data) {
    const { documents, actions } = this.props;
    const scanData = {
      medical_id: get(data, 'card_id', ''),
      customer: {
        state_integration_tracking_id: get(data, 'registry_id', ''),
      },
    };

    actions.change(CUSTOMER_FORM, 'customer.state_integration_tracking_id', scanData.customer.state_integration_tracking_id);

    const medicalFile = documents.filter(document => document.type === medical_id).pop();
    const idIndex = documents.indexOf(medicalFile);
    if (idIndex > -1) {
      actions.change(CUSTOMER_FORM, `ids[${idIndex}].identification_number`, scanData.medical_id);
    }

    this.setState({
      scanData,
      showScanModal: false,
    });
  }

  /**
   * Method which is generated dynamically  uses in "onScanComplete" function
   * @param data
   */
  handlePaMedCaregiver(data) {
    this.setState({
      showBirthdateModal: true,
      showScanModal: false,
      scanData: {
        caregiverInfo: {
          medical_id: get(data, 'card_id', ''),
          state_integration_tracking_id: get(data, 'registry_id', ''),
        },
      },
    });
  }

  /**
   * The method which is running when a user scans
   * Caregiver Id or Patient Id. Working only when Leaf integration is enabled
   * @param parsedData
   * @returns {boolean}
   */
  onScanComplete(parsedData) {
    const {idType} = this.state;
    const {actions} = this.props;

    if (!parsedData) {
      return false;
    }

    try{
      this[camelCase('handle' + idType)](parsedData);
    }catch(e) {
      actions.addMessage('error', I18n.t('customers.scan.failed'));
    }
  }

  setPatientFormValues(patient) {
    const {currentFormValues} = this.props;
    const path = 'customer';
    const patientFormValues = currentFormValues.customer;

    const transformedData = {
      ...patientFormValues,
      birth_date: get(patient, 'consumer.birth_date') ? formatClientDate(get(patient, 'consumer.birth_date')) : get(patientFormValues, 'birth_date'),
      state_integration_tracking_id: get(patient, 'patient_id'),
    };

    const [firstName, lastName] = (get(patient, 'patient_name') || ' ').split(' ');
    transformedData.first_name = firstName || get(patient, 'consumer.first_name') || get(patientFormValues, 'first_name');
    transformedData.last_name = lastName || get(patient, 'consumer.last_name') || get(patientFormValues, 'last_name');
    transformedData.middle_name = get(patient, 'consumer.middle_name') || get(patientFormValues, 'middle_name');

    this.setMedicalId(patient);
    this.setPatientAddress(patient);
    this.updateFormValues(path, transformedData);
  }

  setPatientAddress(patient) {
    const {currentFormValues} = this.props;
    const index = currentFormValues.addresses.length - 1;
    const formValues = currentFormValues.addresses[index];
    const path = `addresses[${index}]`;

    const transformedData = {
      city: get(patient, 'patient_city') || get(formValues, 'city'),
      postal_code: get(patient, 'patient_zip') || get(formValues, 'postal_code'),
      province_code: get(patient, 'patient_state') || get(formValues, 'province_code'),
      street_address_1: get(patient, 'patient_address1') || get(formValues, 'street_address_1'),
      street_address_2: get(patient, 'patient_address2') || get(formValues, 'street_address_2'),
    };

    this.updateFormValues(path, transformedData);
  }

  setMedicalId(patient) {
    const {documents, currentFormValues} = this.props;
    const medicalFile = documents.filter(document => document.type === medical_id).pop();
    const index = documents.indexOf(medicalFile);
    const formValues = currentFormValues.ids[index];
    const path = `ids[${index}].identification_number`;

    const medicalId = get(patient, 'patient_card_number') || formValues.identification_number;

    if (index > -1) {
      this.updateFormValues(path, medicalId);
    }
  }

  /**
   * Function which is invoked  when we're scanning caregiver by ID
   * Uses only when Leaf PA is enabled
   * The method fills caregiver information
   * @param caregiver
   * @param payload
   */
  setCaregiverFormValues(caregiver, payload) {
    const {currentFormValues} = this.props;
    const index = currentFormValues.caregiverInfo.length - 1;
    const formValues = currentFormValues.caregiverInfo[index];
    const path = `caregiverInfo[${index}]`;
    const transformedData = {
      ...formValues,
      medical_id: caregiver.caregiver_card_number,
      birth_date: formatClientDate(payload.dob),
      state_integration_tracking_id: caregiver.caregiver_id,
      addresses: [{
        city: caregiver.caregiver_city ,
        postal_code: caregiver.caregiver_zip ,
        province_code: caregiver.caregiver_state ,
        street_address_1: caregiver.caregiver_address1 ,
      }],
    };

    if (caregiver.caregiver_name) {
      const [firstName, lastName] = caregiver.caregiver_name.split(' ');
      if (firstName) {
        transformedData.first_name = firstName;
      }

      if (lastName) {
        transformedData.last_name = lastName;
      }
    }

    this.updateFormValues(path, transformedData);
  }

  updateFormValues(path, data) {
    this.props.actions.change(CUSTOMER_FORM, path, data);
  }

  syncRegistry() {
    const {customer, actions: {getItem, postItem}} = this.props;

    postItem(
      '/api/leaf/oracle/sync_patient_to_platform',
      {
        registry_id: customer.state_integration_tracking_id
      },
      '', // No data key should be set here
      {
        success: 'customers.modify.syncWithRegistry.success',
        fail: 'customers.modify.syncWithRegistry.fail'
      }
    ).then((result) => {
      getItem(
        `/api/customers/${customer.id}`,
        itemNames.customer,
        { failed: I18n.t('customers.getCustomer.failed') }
      );
    });
  }

  render() {
    const { ready } = this.state;
    const { customerGroups, referralSources, physicians, budTenders, orgHasCanada,
      facilities, tags, documents, notes, fileTypes, customerFieldData,
      lastUpdatedComment, qualifyingConditions, patientSettings, currentFormValues,
      complianceLimits, initialValues, salesAgeLimit, medicalIdExpirationIsRequired,
      isPaLeaf, applicationMode, isCanadaFacility, isWaLeaf, facilityState,
      integrationState, onfleetIsEnabled, primaryFacilityIsRequired, visitReasons,
      defaultVisitReason, caregivers, registrations, paOraclePatientSyncFeature,
      addressFieldsDisabled, displaySaveAndCheckIn, hasMetrcSalesLimits
    } = this.props;

    return (
      <FormWrapper title={I18n.t('customers.modify.title')} goBack={this.props.actions.goBack} className='modify-customer-page'>
        {ready && <CustomerForm
          form={CUSTOMER_FORM}
          initialValues={initialValues}
          handleHistory={this.onViewHistory}
          handleSave={this.onSave}
          handleSaveAndCheckIn={this.onSaveAndCheckIn}
          customerGroups={customerGroups}
          physicians={physicians}
          medicalIdExpirationIsRequired={medicalIdExpirationIsRequired}
          referralSources={referralSources}
          budTenders={budTenders}
          facilities={facilities}
          tagOptions={tags}
          documentUploads={documents}
          registrations={registrations}
          notes={notes}
          onfleetIsEnabled={onfleetIsEnabled}
          qualifyingConditions={qualifyingConditions}
          patientSettings={patientSettings}
          salesAgeLimit={salesAgeLimit}
          currentFormValues={currentFormValues}
          complianceLimits={complianceLimits}
          fileTypes={fileTypes}
          customer={customerFieldData}
          isPaLeaf={isPaLeaf}
          orgHasCanada={orgHasCanada}
          showScanModal={this.showScanModal}
          applicationMode={applicationMode}
          isCanadaFacility={isCanadaFacility}
          isWaLeaf={isWaLeaf}
          facilityState={facilityState}
          integrationState={integrationState}
          lastUpdatedComment={lastUpdatedComment}
          primaryFacilityIsRequired={primaryFacilityIsRequired}
          canCheckIn={+get(currentFormValues,'customer.active',0)}
          paOraclePatientSyncFeature={paOraclePatientSyncFeature}
          syncRegistry={paOraclePatientSyncFeature && this.syncRegistry}
          addressFieldsDisabled={addressFieldsDisabled}
          displaySaveAndCheckIn={displaySaveAndCheckIn}
          hasMetrcSalesLimits={hasMetrcSalesLimits}
        />}
        <ScanInputModal
          showModal={this.state.showScanModal}
          scanType={this.state.scanType}
          onScanComplete={this.onScanComplete}
          hideModal={this.hideScanModal}
        />
        <BirthdateModal
          showModal={this.state.showBirthdateModal}
          onSubmit={this.onBirthdateSubmit}
          hideModal={() => this.setState({showBirthdateModal: false})}
        />
        <CaregiverModal showModal={this.state.showCaregiverModal}
          onClick={this.selectCaregiverPatient}
          patients={this.state.patients}
          hideModal={() => this.setState({showCaregiverModal: false})}/>
        <CustomerCheckInModal
          showModal={this.state.showCheckInModal}
          close={this.closeCheckInModal}
          checkIn={this.checkIn}
          visitReasons={visitReasons}
          initialValues={{visit_reason_id: get(defaultVisitReason,'id',0)}}
          customer={this.props.customer}
          budTenders={budTenders}
          caregivers={caregivers}
        />
      </FormWrapper>
    );
  }
}

ModifyCustomerPage.propTypes = {
  isPaLeaf: PropTypes.bool,
  orgHasCanada: PropTypes.bool,
  params: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  customerGroups: PropTypes.array.isRequired,
  physicians: PropTypes.array.isRequired,
  referralSources: PropTypes.array.isRequired,
  budTenders: PropTypes.array.isRequired,
  facilities: PropTypes.array.isRequired,
  documents: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  notes: PropTypes.array,
  timezone: PropTypes.string.isRequired,
  onfleetIsEnabled: PropTypes.bool,
  qualifyingConditions: PropTypes.array.isRequired,
  patientSettings: PropTypes.object.isRequired,
  medicalIdExpirationIsRequired: PropTypes.bool,
  complianceLimits: PropTypes.object.isRequired,
  currentFormValues: PropTypes.object,
  fileTypes: PropTypes.array.isRequired,
  customerFieldData: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  isCanadaFacility: PropTypes.bool.isRequired,
  isWaLeaf: PropTypes.bool,
  facilityState: PropTypes.string,
  integrationState: PropTypes.object,
  lastUpdatedComment: PropTypes.string,
  salesAgeLimit: PropTypes.number,
  primaryFacilityIsRequired: PropTypes.bool.isRequired,
  visitReasons: PropTypes.array,
  defaultVisitReason: PropTypes.object,
  caregivers: PropTypes.array,
  addressFieldsDisabled: PropTypes.bool,
  displaySaveAndCheckIn: PropTypes.bool.isRequired,
  hasMetrcSalesLimits: PropTypes.bool,
};

const selector = formValueSelector('customer');

function mapStateToProps(state) {
  const { referralSources, tags, budTendersObject, customer, timezone } = state;
  const budTenders = Object.keys(budTendersObject).map(id => ({
    id,
    name: budTendersObject[id]
  }));

  const currentFormValues = getFormValues('customer')(state) || {};
  const formValues = selector(state, 'ids', 'customer', 'limitsInfo', 'registrations');
  const documents = (formValues.ids || []);
  const registrations = formValues.registrations || [];
  const customerFieldData = formValues.customer || {};
  const complianceLimits = getComplianceLimits(state, {customer: {type: get(formValues, 'customer.type', 'medical')}});
  const integrationState = getIntegrationState(state);
  const {isCoMetrc: medicalIdExpirationIsRequired, isPaLeaf, isWaLeaf} = integrationState;
  const onfleetIsEnabled = isOnfleetIntegrator(state);
  const facilities = getDispensaries(state);
  const paOraclePatientSyncFeature = isFeatureEnabled(state)('feature_pa_oracle_patient_sync');

  const primaryFacilityIsRequired = facilities.filter(facility => {
    return facility.country_code === 'CA' && facility.type === 'dispensary';
  }).length > 0;
  return {
    isPaLeaf,
    documents,
    registrations,
    customer,
    customerFieldData,
    customerGroups: getGroupsForCurrentCustomer(state),
    complianceLimits,
    onfleetIsEnabled,
    salesAgeLimit: getSalesAgeLimit(state),
    clinics: getOrderedClinics(state),
    physicians: getOrderedPhysicians(state),
    referralSources,
    budTenders,
    facilities,
    tags,
    notes: getCustomerNotes(state),
    timezone,
    qualifyingConditions: getOrderedQualifyingConditions(state),
    patientSettings: getPatientSettings(state, {customer: {type: get(formValues, 'customer.type', 'medical')}}), // based on sales compliance
    currentFormValues,
    // fileTypes depends on patient settings to get document data which is dependent on customer type
    fileTypes: getFormFileTypes(state, {customer: {type: get(formValues, 'customer.type', 'medical')}}),
    initialValues: getModifyCustomerInitialValues(state),
    medicalIdExpirationIsRequired,
    applicationMode: getApplicationMode(state),
    isCanadaFacility: isCanadaFacility(state),
    orgHasCanada: hasCanadianFacilities(state),
    isWaLeaf,
    facilityState: getFacilityState(state),
    integrationState,
    lastUpdatedComment: getLastUpdatedComment(state),
    primaryFacilityIsRequired,
    integrationSettings: getIntegrationSettings(state),
    visitReasons: getVisitReasons(state),
    defaultVisitReason: getDefaultVisitReason(state),
    caregivers: getCaregivers(state),
    paOraclePatientSyncFeature,
    addressFieldsDisabled: getAddressFieldsDisabled(state),
    displaySaveAndCheckIn: getDisplaySaveAndCheckIn(state),
    hasMetrcSalesLimits: hasMetrcSalesLimits(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, itemActions, { addMessage, goBack, change, push, reset, unsetData });
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ModifyCustomerPage);
