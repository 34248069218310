import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import map from 'lodash.map';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {I18n} from 'react-redux-i18n';
import {goBack} from 'react-router-redux';
import {formValueSelector,touch,change} from 'redux-form';

import {formatDate} from '../../util/dateHelpers';
import * as apiActions from '../../actions/apiActions';
import {unsetItem} from '../../actions/itemActions';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import {CREATE_DRIVER_FORM} from '../../constants/forms';

import {isOnfleetIntegrator} from '../../selectors/integration/thirdPartyIntegrationSelectors';
import {getIntegratedOrApprovedUsers, defaultVehicleFormation} from '../../selectors/driversSelectors';
import {getIntegrationState} from '../../selectors/integration/integrationSelectors';
import InProgressOverlay from '../common/InProgressOverlay';

import FormWrapper from '../common/form/FormWrapper';
import CreateDriverForm from './CreateDriverForm';
import {getCurrentFacilityCountry} from '../../selectors/facilitiesSelectors';
import {getInternationalPhoneCountryCode, getInternationalPhoneNumber} from '../../util/internationalHelpers';
import { getPhoneShouldIncludeCountryCode } from '../../selectors/InternationalOperationsSelectors';
import {isMetrcDelivery} from '../../selectors/integration/metrcSelectors';

export class ModifyDriverPage extends React.PureComponent{
  constructor(props){
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.disableOnfleetTeams = this.disableOnfleetTeams.bind(this);
    this.onChangeDefaultVehicle = this.onChangeDefaultVehicle.bind(this);
    this.onUserChange = this.onUserChange.bind(this);
    this.setFormField = this.setFormField.bind(this);
    this.addLoadingOverlay = this.addLoadingOverlay.bind(this);
    this.state = {
      loading: true,
    };
  }

  componentWillMount() {
    this.props.actions.unsetItem(itemNames.driver);
    Promise.all([
      this.props.actions.getUnpaginatedData('/api/facilities', dataNames.facilities),
      this.props.actions.getUnpaginatedData('/api/vehicles', dataNames.vehicles),
      this.props.actions.getUnpaginatedData(`/api/users/organization/${this.props.organization_id}`, dataNames.users, {}, {
        with_integration_ids: get(this.props, 'integrationState.isPaLeaf') ? 1 : 0
      }),
      this.props.actions.getItem(`/api/drivers/${this.props.params.id}/details`, itemNames.driver),
      this.props.actions.getItem(`/api/onfleet/workers/${this.props.params.id}`, itemNames.onfleetWorker),
      this.props.actions.getUnpaginatedData('/api/onfleet/teams', dataNames.onfleetTeams),
      this.props.actions.getUnpaginatedData('/api/onfleet/workers', dataNames.onfleetWorkers),
      this.props.actions.getItem('/api/integration-settings', itemNames.integrationSettings, {failed: 'stateIntegratorSettings.get.failed'}),
    ])
      .then(() => {
        this.setState({loading: false});
        this.checkForOnfleetWorker();
      })
      .catch(() => {
        this.setState({loading: false});
        this.checkForOnfleetWorker();
      });
  }

  componentWillUnmount() {
    this.props.actions.unsetItem(dataNames.onfleetTeams);
    this.props.actions.unsetItem(dataNames.onfleetWorkers);
    this.props.actions.unsetItem(itemNames.onfleetWorker);
  }

  onSubmit(data){
    this.addLoadingOverlay();
    const mapPhones = (phone) => {
      return {
        id: phone.id,
        driver_id: phone.driver_id,
        number: getInternationalPhoneNumber(phone.number),
        phone_country_code: getInternationalPhoneCountryCode(phone.number),
        type: phone.type
      };
    };

    const driver_phones = get(data, 'driver_phones', []).filter(phone => phone.number);

    const putData = {
      ...data,
      birth_date: formatDate(data.birth_date),
      driver_phones: map(driver_phones, mapPhones),
    };
    return this.props.actions.putItem(
      `/api/drivers/${this.props.params.id}`,
      putData,
      itemNames.driver,
      {
        success: 'cultivation.drivers.edit.success',
        failed: 'cultivation.drivers.edit.fail'
      },
      null,
      this.props.actions.goBack
    );
  }

  addLoadingOverlay () {
    this.setState({
      loading: true
    });
  }

  setFormField(field,value) {
    this.props.actions.change(CREATE_DRIVER_FORM,field,value);
    this.props.actions.touch(CREATE_DRIVER_FORM,field);
  }

  onUserChange(value){
    let chosenUser = {};
    this.props.users.map((user) => {
      user.id === value ? chosenUser = user : chosenUser;
    });
    const birthDate = get(chosenUser,'birth_date',null) ? moment(chosenUser.birth_date) : '';
    this.setFormField('birth_date',birthDate);
    this.setFormField('first_name', chosenUser.first_name || '');
    this.setFormField('middle_initial', chosenUser.middle_name || '');
    this.setFormField('last_name', chosenUser.last_name || '');
    this.setFormField('state_compliance_number', chosenUser.state_compliance_number || '');
    this.setFormField('drivers_license_number', chosenUser.drivers_license_number || '');
    this.setFormField('street_address_1', chosenUser.street_address_1 || '');
    this.setFormField('city', chosenUser.city || '');
    this.setFormField('province_code', chosenUser.province_code || '');
    this.setFormField('postal_code', chosenUser.postal_code || '');
    this.setFormField('phone', chosenUser.phone || '');
    this.setFormField('user_id', chosenUser.id || '');
  }

  onChangeDefaultVehicle(value){
    const {vehicles} = this.props;
    this.setFormField('default_vehicle_id', value);
    let chosenVehicle = {};
    vehicles.map((vehicle) => {
      vehicle.id === value ? chosenVehicle = vehicle : chosenVehicle;
    });
    this.setFormField('make', chosenVehicle.make || '');
    this.setFormField('model', chosenVehicle.model || '');
    this.setFormField('license_number', chosenVehicle.license_number || '');
  }

  disableOnfleetTeams(value) {
    this.setState({
      shouldDisableOnfleetTeams: value
    });
  }

  checkForOnfleetWorker() {
    const { onfleetWorker } = this.props;

    if (onfleetWorker && Object.keys(onfleetWorker).length === 0) {
      this.disableOnfleetTeams(true);
    }
  }

  render() {
    const {loading, shouldDisableOnfleetTeams} = this.state;
    const {vehicles, facilities, users, isCompany, integrationState, onfleetIsEnabled, onfleetTeams, initialValues, countryCode, isMetrcDelivery, onfleetWorkers} = this.props;

    return(
      <FormWrapper title = {I18n.t('cultivation.drivers.editTitle')} goBack={this.props.actions.goBack}>
        <InProgressOverlay isActive={loading}/>
        <CreateDriverForm
          onSubmit={this.onSubmit}
          vehicles={vehicles}
          facilities={facilities}
          users={users}
          onfleetTeams={onfleetTeams}
          onfleetWorkers={onfleetWorkers}
          onfleetIsEnabled={onfleetIsEnabled}
          onChangeDefaultVehicle={this.onChangeDefaultVehicle}
          onUserChange={this.onUserChange}
          initialValues={initialValues}
          isCompany={isCompany}
          integrationState={integrationState}
          countryCode={countryCode}
          isMetrcDelivery={isMetrcDelivery}
          disableOnfleetTeams={this.disableOnfleetTeams}
          shouldDisableOnfleetTeams={shouldDisableOnfleetTeams}
          addLoadingOverlay={this.addLoadingOverlay}
        />
      </FormWrapper>
    );
  }
}

ModifyDriverPage.propTypes = {
  actions: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    putItem: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired
  }),
  params: PropTypes.shape({
    id: PropTypes.string
  }),
  facilities: PropTypes.array,
  users: PropTypes.array,
  vehicles: PropTypes.array,
  driver: PropTypes.object,
  organization_id: PropTypes.number,
  timezone: PropTypes.string.isRequired,
  isCompany: PropTypes.bool.isRequired,
  integrationState: PropTypes.object,
  onfleetTeams: PropTypes.array,
  initialValues: PropTypes.object.isRequired,
  countryCode: PropTypes.string.isRequired,
  disableOnfleetTeams: PropTypes.func,
  shouldDisableOnfleetTeams: PropTypes.bool,
};

const selector = formValueSelector(CREATE_DRIVER_FORM);

function mapStateToProps(state) {
  const {vehicles, facilities, timezone} = state;
  const isCompany = !!selector(state, 'is_licensed_transporter');
  const integrationState = getIntegrationState(state);
  const driver = defaultVehicleFormation(state);

  if (driver.driver_phones && getPhoneShouldIncludeCountryCode(state)) {
    const driver_phones = driver.driver_phones;
    driver_phones.forEach((phone, index) => {
      driver_phones[index].number = ((phone.phone_country_code && driver_phones[index].number.indexOf('-') == -1) ? phone.phone_country_code + '-' + phone.number : phone.number);
    });

    driver.driver_phones = driver_phones;
  }

  const onfleetIsEnabled = isOnfleetIntegrator(state);
  const onfleetTeams = state[dataNames.onfleetTeams];
  const onfleetWorker = state[itemNames.onfleetWorker];
  const onfleetWorkers = [...state[dataNames.onfleetWorkers], onfleetWorker];
  const initialValues = {
    ...driver,
    birth_date: driver && driver.birth_date ? moment(driver.birth_date) : '',
    onfleet_teams: onfleetWorker && onfleetWorker.teams ? onfleetTeams.filter(onfleetTeam => onfleetWorker.teams.indexOf(onfleetTeam.id) > -1) : [],
    onfleet_worker_mapping: onfleetWorker ? onfleetWorker.id : null,
  };

  return {
    vehicles,
    facilities,
    users: getIntegratedOrApprovedUsers(state),
    driver,
    organization_id: state.facility.organizationId,
    timezone,
    isCompany,
    integrationState,
    onfleetIsEnabled,
    isMetrcDelivery: isMetrcDelivery(state),
    onfleetTeams,
    onfleetWorker,
    onfleetWorkers,
    initialValues,
    countryCode: getCurrentFacilityCountry(state)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {change,goBack,touch,unsetItem});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (ModifyDriverPage);
