import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { reduxForm, Field} from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { Row, Col, Button} from 'react-bootstrap';

import {API_TRANSACTIONS_FORM} from '../../../constants/forms';
import TextInput from '../../common/form/TextInput';
import DatePickerInput from '../../common/form/DatePickerInput';
import ReactSelectInput from '../../common/form/ReactSelectInput';

import { validate } from './validate';

const TransactionPageForm = (props) => {
  const {submitting, invalid, hasMetrcSettings, endPoints, integrators} = props;
  const responseTypes = [
    {type: I18n.t('integration.transactions.responseTypes.any'), value: ''},
    {type: I18n.t('integration.transactions.responseTypes.success'), value: '1'},
    {type: I18n.t('integration.transactions.responseTypes.fail'), value: '0'},
  ];

  const formattedIntegrators = integrators.map((x) => ({ integrator: (get(x, 'integrator') || '').toUpperCase() }));

  return(
    <div className='API-transactions'>
      <form onSubmit = {props.handleSubmit}>
        <Row>
          <Col xs={12} md={12} className='transactions-form'>
            <Row>
              <Col xs={12} sm={3} md={3}>
                <Field
                  name='integrator'
                  component={ReactSelectInput}
                  props={{
                    label: I18n.t('integration.transactions.integrator'),
                    options: formattedIntegrators.sort((a, b) => a.integrator.localeCompare(b.integrator)),
                    textKey: 'integrator',
                    valueKey: 'integrator',
                    placeholder: I18n.t('integration.transactions.integratorPlaceholder')
                  }}
                />
              </Col>
              <Col xs={12} sm={6} md={6}>
                <Field
                  name='api_call'
                  component={ReactSelectInput}
                  props={{
                    label: I18n.t('integration.transactions.api'),
                    options: endPoints.sort((a, b) => a.end_point.localeCompare(b.end_point)),
                    textKey: 'end_point',
                    valueKey: 'end_point',
                    placeholder: I18n.t('integration.transactions.apiPlaceholder')
                  }}
                />
              </Col>
              <Col xs={12} sm={3} md={3}>
                <Field
                  name='response_type'
                  component={ReactSelectInput}
                  props={{
                    label: I18n.t('integration.transactions.callStatus'),
                    options: responseTypes,
                    textKey: 'type',
                    valueKey: 'value'
                  }}
                />
              </Col>
            </Row>
            <Row>
              {hasMetrcSettings ?
                <Col xs={12} sm={6} md={3}>
                  <Field
                    name='package_label'
                    component={TextInput}
                    props={{
                      label: I18n.t('integration.transactions.packageLabel'),
                      placeholder: I18n.t('integration.transactions.packageLabelPlaceholder'),
                    }}
                  />
                </Col> : null
              }
              <Col xs={12} sm={6} md={hasMetrcSettings ? 3 : 4}>
                <Field
                  name='date_from'
                  component={DatePickerInput}
                  props={{
                    label: I18n.t('integration.transactions.dateFrom'),
                    isRequired: true
                  }}
                >
                  <div className='text-muted' style={{position: 'relative', top: '5px'}}>
                    {I18n.t('integration.transactions.dateFromHint')}
                  </div>
                </Field>
              </Col>
              <Col xs={12} sm={6} md={hasMetrcSettings ? 3 : 4}>
                <Field
                  name='date_to'
                  component={DatePickerInput}
                  props={{
                    label: I18n.t('integration.transactions.dateTo'),
                  }}
                >
                  <div className='text-muted' style={{position: 'relative', top: '5px'}}>
                    {I18n.t('integration.transactions.dateToHint')}
                  </div>
                </Field>
              </Col>
              <Col xs={12} sm={6} md={hasMetrcSettings ? 3 : 4}>
                <label style={{width: '100%'}}>&nbsp;</label>
                <Button type='submit' disabled={submitting || invalid} className='btn-block' variant='primary'>
                  {I18n.t('integration.transactions.getReport')}
                </Button>
              </Col>
            </Row>
          </Col>
          <hr />
        </Row>
      </form>
    </div>
  );
};

TransactionPageForm.propTypes = {
  integrators: PropTypes.array.isRequired,
  endPoints: PropTypes.array.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  hasMetrcSettings: PropTypes.bool,
  hasLeafSettings: PropTypes.bool,
};

export default reduxForm({
  form: API_TRANSACTIONS_FORM,
  validate,
  enableReinitialize: false,
  destroyOnUnmount: false,
})(TransactionPageForm);
