const getPayload = (values, index) => {
  return {
    source_batch_id: values.id,
    split_weight: values.splits[index].weight,
    split_weight_uom: 'GR',
    inventory_location_id: values.splits[index].location,
  };
};

export default getPayload;
