import {I18n} from 'react-redux-i18n';

export const validate = (values) => {
  const errors = {};
  const requiredFields = ['name', 'rewardCode', 'pointsRequired', 'rewardAmount', 'rewardAmountSelect'];
  const integerOnlyFields = ['pointsRequired', 'rewardAmount', 'limitRedemption'];
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = I18n.t('common.form.isRequired', {fieldName: I18n.t(`retail.rewards.${field}`)});
    }
  });
  integerOnlyFields.forEach(field => {
    if (values[field] && !Number.isInteger(Number(values[field]))) {
      errors[field] = I18n.t('plants.form.mustBeIntegerError');
    }
  });

  return errors;
};

export default validate;
