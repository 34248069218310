import {createSelector} from 'reselect';
import isEmpty from 'lodash.isempty';
import moment from 'moment-timezone';
import {I18n} from 'react-redux-i18n';

import {getCategories} from './categorySelectors';
import {getSubcategories} from './subcategoriesSelectors';
import {inventoryTransactionsReport} from '../constants/dataNames';
import {getFacilities} from './facilitiesSelectors';
import {convertFromBase} from '../util/uomHelpers';
import {displayQtyCurried} from './uomsSelectors';


export const getInventoryTransactionsReportRaw = state => state[inventoryTransactionsReport];

export const getCategoryOptions = createSelector( [getCategories], categories => {
  return categories.map( category => {
    return {
      text: category.name,
      value: category.id
    };
  });
});


// NOTE: this is the simple implementation just like the other reports use, which doesn't filter by selected category.
//  We're spinning up another story to handle them all at once, likely via middleware on category_id switching
export const getSubcategoryOptions = createSelector( [getSubcategories], subcategories => {
  return subcategories.map( subcategory => {
    return {
      text: subcategory .name,
      value: subcategory .id
    };
  });
});

const getFacilitiesById = createSelector(
  [getFacilities],
  (facilities) => {
    return facilities.reduce((result, facility) => {
      result[facility.id] = facility;
      return result;
    }, {});
  }
);

export const getInventoryTransactionsReport = createSelector(
  [getInventoryTransactionsReportRaw, getFacilitiesById, displayQtyCurried],
  (inventoryTransactionsReport, facilitiesById, displayQty) => {

    if (!inventoryTransactionsReport.length || isEmpty(facilitiesById)) return [];

    return inventoryTransactionsReport.map(row => {
      return {
        ...row,
        facilityName: facilitiesById[row.facility_id].name,
        eventDate: moment(row.event_date).format('MM-DD-YYYY'),
        productType: row.is_prepack ? I18n.t('retail.inventoryTransactionsReport.prepack') : row.uom_type,
        startingLevel: displayQty(convertFromBase(row.level_before, row.transacted_uom)),
        newLevel: displayQty(convertFromBase(row.level_after, row.transacted_uom)),
        itemIsActive: row.active ? I18n.t('common.yes') : I18n.t('common.no'),
        strainName: row.organization_strain_name || row.global_strain_name
      };
    });
  }
);
