import React from 'react';
import {Button} from 'react-bootstrap';
import get from 'lodash.get';
import * as messageTypes from '../../../constants/messageTypes';
import {convertDbDateToFormInputDate} from '../../../util/dateHelpers';

const updateApprovalStatus = (actions, receipt_id, approval_status, tab) => {
  return actions.postItem('/api/closed_loops/inventory_receipts/approval', {receipt_id, approval_status})
    .then(() => actions.push(`/closed-loop/receipts/${tab}`))
    .catch((response) => {
      const error  = get(response, 'response.data.errors', 'common.failed');
      actions.addMessage(messageTypes.error, error);
    });
};

export function getColumns(timezone, actions, tab, handleShowAuditLogModal) {
  const columns = [
    {
      name: 'closedLoop.regulatedReceipt.permittee',
      dataId: 'receiver_facility_name',
    },
    {
      name: 'closedLoop.regulatedReceipt.sender',
      dataId: 'vendor_name',
    },
    {
      name: 'closedLoop.regulatedReceipt.creationDate',
      dataId: 'created_at',
      formatter: (cell, row) => convertDbDateToFormInputDate(cell, timezone)
    },
    {
      name: 'closedLoop.regulatedReceipt.receiptNumber',
      dataId: 'receipt_number',
    }
  ];
  if (tab === 'inactive') {
    columns.push(
      {
        name: 'closedLoop.regulatedReceipt.approvalStatus',
        dataId: 'approval_status',
        formatter: (cell, row) => cell ? cell.charAt(0).toUpperCase() + cell.slice(1) : '',
      }
    );
  }
  columns.push(
    {
      name: 'closedLoop.regulatedReceipt.actions',
      dataId: 'actions',
      dataSort: false,
      formatter: (cell, row) => {
        const approveEnabled = ((tab === 'pending' || tab === 'inactive') && row.status === 'open');
        const deniedEnabled = (tab !== 'inactive' && row.status === 'open');
        return (
          <div>
            {approveEnabled && <Button style={{marginRight: '8px'}} variant='success' onClick={() => updateApprovalStatus(actions, row.id, 'approved', 'approved')}>Approve</Button>}
            {deniedEnabled && <Button style={{marginRight: '8px'}} variant='danger' onClick={() => updateApprovalStatus(actions, row.id, 'denied', 'inactive')}>Deny</Button>}
            <Button onClick={(e) => {
              e.stopPropagation();
              handleShowAuditLogModal(row.id);
            }}>View history</Button>
          </div>
        );
      }
    }
  );
  return columns;
}

export function getTabs() {
  return [
    {
      id: 'pendingTab',
      eventKey: 'pending',
      title: 'closedLoop.status.pending',
      actions: [],
    },
    {
      id: 'approvedTab',
      eventKey: 'approved',
      title: 'closedLoop.status.approved',
      actions: [],
    },
    {
      id: 'inactiveTab',
      eventKey: 'inactive',
      title: 'closedLoop.status.inactive',
      actions: [],
    },
  ];
}
