import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import SettingPanels from './SettingPanels';

const SettingsReduxForm = reduxForm({
  form: 'labelSettings',
})(SettingPanels);

function mapStateToProps(state, ownProps) {
  return {
    state,
    formFields: ownProps.formFields,
    touchedFormFields: state.form && state.form.labelSettings && state.form.labelSettings.fields,
  };
}

const SettingFormWrapper = connect(mapStateToProps)(SettingsReduxForm);

SettingFormWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default SettingFormWrapper;
