import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { Link } from 'react-router';
import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';
import {push} from 'react-router-redux';
import {bindActionCreators} from 'redux';
import { Button } from 'react-bootstrap';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import {getData, getPaginatedData, getUnpaginatedData, getItem, deleteItem, getDataByPost} from '../../actions/apiActions';
import {unsetData, setData} from '../../actions/dataActions';
import {getReservations} from '../../selectors/reservationsSelectors';
import TablePageWrapper from '../common/grid/TablePageWrapper';
import PageTitle from '../common/PageTitle';
import productTabs from './common/tabDefinitions';
import {itemMasterHardReservations, itemMasterSoftReservations} from './common/columnDefinitions';
import ModalWrapper from '../common/ModalWrapper';
import { getReservationSourceLink } from '../../selectors/forms/reservationsFormSelectors';


export class ProductReservations extends React.PureComponent {

  constructor (props, context) {
    super(props, context);
    this.switchTab = this.switchTab.bind(this);
    this.setReservationsTypeAndColumns = this.setReservationsTypeAndColumns.bind(this);
    this.getColumns = this.getColumns.bind(this);
    this.releaseAllReservations = this.releaseAllReservations.bind(this);
    this.releaseReservation = this.releaseReservation.bind(this);
    this.showConfirmModal = this.showConfirmModal.bind(this);
    this.hideConfirmModal = this.hideConfirmModal.bind(this);
    this.confirmModalAction = this.confirmModalAction.bind(this);
    this.getReservations = this.getReservations.bind(this);

    const releaseAllHandler = (e) => {
      e.target.blur();
      this.releaseAllReservations();
    };

    this.state = {
      activeTab: `${get(this.props, 'params.type', 'soft')}Reservations`,
      tabs: productTabs.map((tab) => {
        return Object.assign({}, tab, {
          path: tab.path.replace(':id', get(this.props, 'params.id', 0)),
          actions: tab.actions.map((action) => {
            return action.func
              ? action
              : {...action, func: releaseAllHandler};
          })
        });
      }),
      itemMasterId: get(this.props, 'params.id', 0),
      showLoader: false,
      message: '',
      totalRows: 0,
    };

  }

  componentWillMount () {

    const getChildrenAndChildItems = () => {
      const url = '/api/item_masters/children/items';
      const messages = {failed: 'general.failed'};
      this.props.actions.getUnpaginatedData(url, dataNames.itemMasterChildren, messages, {ids: [this.state.itemMasterId], active: 1});
    };

    this.props.actions.unsetData(dataNames.inventoryItems);

    this.props.actions.getItem(`/api/item_masters/${this.state.itemMasterId}`, itemNames.itemMaster, null, {detailed: 1})
      .then((itemMaster) => {
        this.setState({itemMaster: itemMaster});
        if(!get(itemMaster, 'inventory_attributes.is_prepack', 0)) return;

        this.props.actions.getUnpaginatedData('/api/prepack_weights', dataNames.prepackWeights);
        getChildrenAndChildItems();
      });

    this.setReservationsTypeAndColumns(get(this.props, 'params.type', 'soft'));
  }

  componentWillReceiveProps (nextProps) {
    const paramsType = get(nextProps, 'params.type', 'soft');
    if (paramsType !== this.state.type) {
      this.setReservationsTypeAndColumns(paramsType);
    }
  }

  setReservationsTypeAndColumns (type) {
    if (type === this.state.type && this.state.type) return true;
    this.props.actions.unsetData(dataNames.reservations);
    setTimeout(() => {
      this.setState({type, columns: this.getColumns(type), totalRows: 0});
      this.getReservations();
    }, 1);
  }

  getReservations(){
    const reservationType = this.state.type;
    const url = `/api/${reservationType === 'soft' ? 'product_reservations' : 'item_reservations'}`;
    const params = {item_master_id: this.state.itemMasterId};
    this.props.actions.getUnpaginatedData(url, dataNames.reservations, null, params)
      .then((reservations) => {
        this.setState({totalRows: reservations.length});
        if(reservationType === 'hard'){
          const itemIds = reservations.map((reservation) => {
            return get(reservation, 'item_id', 0);
          });
          this.props.actions.getDataByPost('/api/items/multiple', {
            detailed: 1,
            ids: itemIds,
          }, dataNames.inventoryItems);
        }
      });
  }

  /***
   * Adds name and a formatter for buttons
   */
  getColumns (type) {

    const linkEntityName = (cell, row) => {
      const {entityType, entity_name, entity_id} = row;
      const link = getReservationSourceLink(entityType, entity_id);
      if(!link) return entity_name;
      return (<Link to={link} onlyActiveOnIndex={false} target='_blank'>{entity_name}</Link>);
    };

    const columns = type === 'soft' ? itemMasterSoftReservations : itemMasterHardReservations;
    return columns.map((col) => {
      if (!col.name) {
        return Object.assign({}, col, {
          name: col.dataId ? `reservations.tableColumns.${col.dataId}` : col.name,
          formatter: col.dataId === 'entity_name' ? linkEntityName : undefined,
        });
      }
      return Object.assign({}, col, {
        formatter: (cell, row) => { //eslint-disable-line
          return (<Button variant='primary' className='btn-block'
                          onClick={() => this.releaseReservation(row)}>Release</Button>);
        }
      });
    });
  }

  releaseAllReservations (isConfirmed = false) {
    if(!isConfirmed) {
      return this.showConfirmModal('releaseAllReservations');
    }
    const deleteType = `${this.state.type === 'soft' ? 'product' : 'item'}_reservations`;
    this.props.actions.deleteItem(`/api/${deleteType}`, null, null, {item_master_ids: [this.state.itemMasterId]})
      .then(() => {
        this.getReservations();
      });
  }

  releaseReservation(reservation, isConfirmed = false){
    if(!isConfirmed) return this.showConfirmModal('releaseReservation', reservation);
    const deleteType = `${this.state.type === 'soft' ? 'product' : 'item'}_reservations`;
    this.props.actions.deleteItem(`/api/${deleteType}/${reservation.id}`)
      .then(() => {
        this.getReservations();
      });
  }

  showConfirmModal(method, data){
    const isReleaseAllAction = method.indexOf('All') !== -1;
    const isReleaseAllProductAction = isReleaseAllAction && this.state.type === 'soft';

    this.setState({
      showConfirmModal: true,
      showConfirmMessage: isReleaseAllAction
        ? (isReleaseAllProductAction
          ? I18n.t('reservations.confirms.releaseAllForProduct')
          : I18n.t('reservations.confirms.releaseAllForPackage')
        )
        : I18n.t('reservations.confirms.releaseOneForProduct'),
      postConfirmMethod: method,
      postConfirmData: data
    });
  }

  confirmModalAction(event){
    if(event.buttonClicked !== 'cancel') {
      if(!this.state.postConfirmData){
        this[this.state.postConfirmMethod](true); // releaseAllReservations
      } else {
        this[this.state.postConfirmMethod](this.state.postConfirmData, true); // releaseReservation
      }
    }
  }

  hideConfirmModal() {
    this.setState({showConfirmModal: false, postConfirmMethod: undefined, postConfirmData: undefined});
  }

  switchTab(activeTab) {
    const tab = this.state.tabs.find(tab => tab.eventKey === activeTab) || this.state.tabs[0];
    this.setState({activeTab});
    this.props.actions.push(tab.path);
  }

  render() {
    const {reservations} = this.props;
    return (
      <div>
        <PageTitle primaryText={I18n.t('reservations.labels.productTitle')} secondaryText={` ${get(this.state.itemMaster, 'name', '')}`} />
        {
          ['hard', 'soft'].map((type, index) => {
            // Allows the table to re init which prevents some dumb behaviors
            if(type !== this.state.type && this.state.type) return null;
            return (<TablePageWrapper key={index}
              ref={this.ref}
              settingKey='transfers'
              columns={(this.state.columns || []).filter((c) => {
                return (!get(this.state.itemMaster, 'inventory_attributes.is_prepack', 0))
                  ? c.dataId !== 'prepackWeight'
                  : true;
              })}
              data={Array.isArray(reservations) ? reservations : []}
              activeTab={this.state.activeTab}
              tabs={this.state.tabs}
              switchTab={this.switchTab}
              hideScanSearch={true}
              hideExport={true}
              bstProps={{
                selectRow: {
                  clickToSelect: false,
                  hideSelectColumn: true,
                }
              }}
            />);
          })
        }
        <ModalWrapper
          title={`Confirm`}
          onHide={this.hideConfirmModal}
          showModal={this.state.showConfirmModal}
          headerClass='bg-info-dark'
          dialogClassName='modal-sm'
          cancelButton={{show: true}}
          okayButton={{show: true, text: 'Confirm', onClick: this.confirmModalAction}}
          version={2}
        >
          <div>
            {this.state.showConfirmMessage}
          </div>
        </ModalWrapper>
      </div>
    );
  }
}

ProductReservations.propTypes = {
  reservations: PropTypes.array,
  actions: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    reservations: getReservations(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {push, getData, getPaginatedData, unsetData, getItem, deleteItem, setData, getUnpaginatedData, getDataByPost};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductReservations);
