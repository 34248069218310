import orderBy from 'lodash.orderby';
import {RETAIL, WHOLESALE} from '../constants/saleTypes';
import {MEDICAL, RECREATIONAL} from '../constants/consumerTypes';

const checkFacilityId = (pricing, {facility_id}) => pricing.facility_ids.indexOf(facility_id) > -1 || pricing.is_default_for_org === 1;
const checkSaleType = (pricing, {sale_type}) => pricing.sale_type === sale_type;
const checkConsumerType = (pricing, {consumer_type}) => !consumer_type || pricing.consumer_type === consumer_type;

const validators = [
  checkFacilityId,
  checkSaleType,
  checkConsumerType,
];

const validatePricing = criteria => pricing => validators.every(validator => validator(pricing, criteria));

export const getApplicablePricing = (itemMaster, {facility_id, sale_type, consumer_type}) => {
  const price_lists = itemMaster && itemMaster.pricing_details && itemMaster.pricing_details.price_lists || [];
  const matches = price_lists.filter(validatePricing({facility_id, sale_type, consumer_type}));

  return orderBy(matches, 'is_default_for_org')[0];
};

export const getApplicablePrice = (itemMaster, {facility_id, sale_type, consumer_type}) => {
  const pricing = getApplicablePricing(itemMaster, {facility_id, sale_type, consumer_type});
  return pricing && pricing.default_price || '0.00';
};

export const addPricesToItemMaster = (itemMaster, {facility_id}) => {
  return {
    ...itemMaster,
    prices: {
      [WHOLESALE]: getApplicablePrice(itemMaster, {facility_id, sale_type: WHOLESALE}),
      [RETAIL]: {
        [MEDICAL]: getApplicablePrice(itemMaster, {facility_id, sale_type: RETAIL, consumer_type: MEDICAL}),
        [RECREATIONAL]: getApplicablePrice(itemMaster, {facility_id, sale_type: RETAIL, consumer_type: RECREATIONAL}),
      },
    },
  };
};
