import * as types from '../../constants/actionTypes';
import initialState from '../../constants/initialState';

export default function packageIdsReducer (state = initialState.cart.packageIds, action){
  const {packageIds} = action;
  switch (action.type) {
  case types.GET_PACKAGE_IDS_SUCCESS:
    return [...packageIds];
  case types.GET_PACKAGE_IDS_FAILED:
    return state;
  default:
    return state;
  }
}
