import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import {ButtonGroup} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import {push, goBack} from 'react-router-redux';
import get from 'lodash.get';
import {addMessage} from '../../../actions/systemActions';
import {postItem, getItem, deleteItem, getUnpaginatedData, getData} from '../../../actions/apiActions';
import {setItem, unsetItem} from '../../../actions/itemActions';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import * as p from '../../../constants/permissions';
import {getToday} from '../../../util/dateHelpers.js';
import PermissionButton from '../../common/PermissionButton';
import {getOrderFulfillmentOptions} from '../../../constants/orderFulfillmentMethods';
import CustomerCheckInModal from './CustomerCheckInModal'; // eslint-disable-line import/no-named-as-default
import {isFeatureEnabled} from '../../../selectors/featureToggles';
import ModalWrapper from '../../common/ModalWrapper';
import StartOrderFormWrapper from './StartOrderFormWrapper';
import InProgressOverlay from '../../common/InProgressOverlay';
import {userNeedsRegistersLocations} from '../../../selectors/usersSelectors';
import {getSalesLimitError} from '../../../selectors/salesSettingsSelectors';
import {getCaregivers} from '../../../selectors/customerSelectors';
import CancelOrder from '../../orders/order/cancel/CancelOrder'; // eslint-disable-line import/no-named-as-default
import {getIntegrationState, isSetupMode, needToValidateOnCheckin} from '../../../selectors/integration/integrationSelectors';
import {isOnfleetIntegrator} from '../../../selectors/integration/thirdPartyIntegrationSelectors';
import {DEA_NUMBER} from '../../../constants/physicianLicenseTypes';
import {getConsumerLimits} from '../../../actions/consumer/limits';
import {isLeafPaConfigPackClosedLoopFacility} from '../../../selectors/facilitiesSelectors';

export class CustomerActionButtons extends React.PureComponent {

  constructor(props, context){
    super(props, context);

    this.state = {
      showStartOrderModal: false,
      showOnfleetDataRequiredModal: false,
      showOhMetrcDataRequiredModal: false,
      onfleetErrorMessage: null,
      ohioMissedFields: [],
      fastTrack: false,
      orderCustomer: null,
      customer: null,
      showCheckInModal: false,
      orderActionsData: {},
      cancelOrderEventListeners: {},
      cancelOrderCustomerId: 0,
      cancelOrderOrderId: 0,
      cancelOrderEvent: 'noop',
      orderFulfillmentOptions: getOrderFulfillmentOptions(),
      disabled: false
    };

    this.getOrderId = this.getOrderId.bind(this);
    this.goToCartPage = this.goToCartPage.bind(this);
    this.goToPaymentPage = this.goToPaymentPage.bind(this);
    this.goToProductsMenu = this.goToProductsMenu.bind(this);
    this.goToReceiptPage = this.goToReceiptPage.bind(this);
    this.getCustomer = this.getCustomer.bind(this);
    this.goToQueue = this.goToQueue.bind(this);
    this.checkIn = this.checkIn.bind(this);
    this.startOrder = this.startOrder.bind(this);
    this.modifyOrder = this.modifyOrder.bind(this);
    this.fastTrack = this.fastTrack.bind(this);
    this.cancel = this.cancel.bind(this);
    this.selectOrders = this.selectOrders.bind(this);
    this.openOrderModal = this.openOrderModal.bind(this);
    this.closeOrderModal = this.closeOrderModal.bind(this);
    this.closeCheckInModal = this.closeCheckInModal.bind(this);
    this.openCheckInModal = this.openCheckInModal.bind(this);
    this.pickupCheckIn = this.pickupCheckIn.bind(this);
    this.deleteCheckIn = this.deleteCheckIn.bind(this);
    this.hideInProgress = this.hideInProgress.bind(this);
    this.createOrder = this.createOrder.bind(this);
    this.doAction = this.doAction.bind(this);
    this.reloadOrdersAndQueues = this.reloadOrdersAndQueues.bind(this);
    this.validateOhMetrcCustomerToStartOrder = this.validateOhMetrcCustomerToStartOrder.bind(this);
  }

  // Redirects
  goToProductsMenu(){ // modify order
    this.setState({showInProgress: true, inProgressMessage: 'Going To Products Page'});
    this.goToPage('products');
  }

  goToCartPage(){ // fast track
    this.setState({showInProgress: true, inProgressMessage: 'Going To Cart Page'});
    this.goToPage('cart');
  }

  goToPaymentPage(){
    this.setState({showInProgress: true, inProgressMessage: 'Going To Payments Page'});
    this.goToPage('payment');
  }

  goToReceiptPage(){
    this.setState({showInProgress: true, inProgressMessage: 'Going To Receipts Page'});
    this.goToPage('receipt');
  }

  goToPage(page){
    this.getOrderId()
      .then((orderId) => {
        const pages = {
          products: `/product-menu/${orderId}`,
          cart: '/cart',
          payment: `/orders/${orderId}/payment`,
          receipt: `/orders/${orderId}/payment`
        };
        this.props.actions.setItem({id: orderId}, itemNames.order);
        this.getCustomer().then(() => {
          this.props.actions.push(pages[page]);
        });
      })
      .catch((e) => {
        this.hideInProgress();
      });
  }

  // Customer calls
  getCustomerId(){
    return (typeof this.props.consumer === 'object')
      ? this.props.consumer.consumer_id !== undefined ? this.props.consumer.consumer_id : this.props.consumer.id
      : this.props.consumer;
  }

  getCustomer(){
    const customer = Object.assign({}, this.props.consumer);
    const customerId = this.getCustomerId();
    if(typeof customer === 'object') this.props.actions.setItem(customer, itemNames.customer); // Because old code had it - might be beating race condition?
    return new Promise((resolve, reject) => {
      const messages = {failed: I18n.t('customers.get.failed')};
      this.props.actions.getItem(`/api/customers/${customerId}`, itemNames.customer, messages, {
        with_relations: ['physician.physicianLicenses', 'categorySalesLimits'],
        select_columns: ['physicianLicenses.active', 'physicianLicenses.physician_id', 'physicianLicenses.license_type']
      }, (data) => {
        this.props.actions.getItem(`/api/consumer_orders/${customerId}/stats`, itemNames.customerStat);
        this.props.actions.getItem(`/api/consumer_orders/${customerId}/recent_history`, itemNames.customerRecentHistory);
        resolve(data);
      }).then(() => {}).catch((e) => reject(e));
    });

  }

  // Order calls
  getOrderId(){
    return new Promise((resolve, reject) => {
      if(this.props.consumer.order_id && !isNaN(parseInt(this.props.consumer.order_id)) && !this.state.cancelOrderOrderId) {
        resolve(this.props.consumer.order_id);
      } else {
        this.createOrder()
          .then((order) => {
            resolve(order.id);
          })
          .catch((e) => {
            reject(e);
          });
      }
    });

  }

  hideInProgress(){
    this.setState({showInProgress: false, disabled: false});
  }

  closeCheckInModal() {
    this.setState({showCheckInModal: false});
  }

  openCheckInModal(consumer) {
    this.setState({showCheckInModal: true, consumer});
    this.getCaregiversForConsumer(consumer);
  }

  validateConsumerOnCaregiverCheckin(consumer) {
    this.props.validateSelectedPatientData(consumer, this.openCheckInModal);
  }

  validateConsumerOnCaregiverFastTrack(consumer) {
    this.props.validateSelectedPatientData(consumer, this.fastTrack);
  }

  validateConsumerOnStartOrder(consumer) {
    this.props.validateSelectedPatientData(consumer, this.openOrderModal);
  }

  getCaregiversForConsumer(consumer){
    this.props.actions.getData(`/api/customers/${consumer.id}/caregivers`, dataNames.caregivers);
  }

  checkIn(formValues) {
    const payload = this.setCaregiverOnPayload({
      consumer_id: this.props.consumer.id,
      registry_id: this.props.consumer.state_integration_tracking_id,
      card_number: get(this.props.consumer, 'ids.0.identification_number')
    });
    if(formValues.visit_reason_id !== undefined) payload.visit_reason_id = formValues.visit_reason_id;
    if(formValues.preferred_csr !== undefined) payload.preferred_csr = formValues.preferred_csr;
    if(formValues.caregiver_id && !payload.caregiver_id) payload.caregiver_id = formValues.caregiver_id;
    this.setState({disabled: true});

    const messages = {
      failed: 'customers.addToQueue.failed',
      success: 'customers.addToQueue.success'
    };

    this.props.actions
      .postItem('/api/queue/customers', payload, itemNames.queueCustomer, messages)
      .then(() => {
        this.reloadOrdersAndQueues();
        this.props.actions.getConsumerLimits(this.props.consumer.id);
      })
      .then(() => this.setState({disabled: false}))
      .catch(() => this.setState({disabled: false}));
  }

  pickupCheckIn( consumer) {
    this.setState({disabled: true});
    if( consumer.queue_id &&  consumer.order_id &&  consumer.order_source !== 'in_store'){ // theoretically this should only be for pickup orders
      return this.deleteCheckIn( consumer);
    }

    const payload = (! consumer.order_id) ? consumer : {
      consumer_id:  consumer.id,
      order_id:  consumer.order_id
    };

    this.props.actions.postItem(
      '/api/queue/customers',
      //customer,
      payload,
      itemNames.queueCustomer,
      {failed: 'customers.addToQueue.failed', failedHandler: getSalesLimitError, success: 'customers.addToQueue.success'}
    ).then(() => {
      this.reloadOrdersAndQueues();
    })
      .then(() => this.setState({disabled: false}))
      .catch(() => this.setState({disabled: false}));
  }

  deleteCheckIn( consumer){ // Specific to removing from queue for pick up orders
    this.props.actions.deleteItem(`/api/queue/customers/${ consumer.id}`)
      .then(() => this.reloadOrdersAndQueues())
      .then(() => this.setState({disabled: false}))
      .catch(() => this.setState({disabled: false}));
    return true;
  }

  openOrderModal(orderCustomer, fastTrack = false) {
    //wait new customer info without cancelled order
    if (this.state.cancelOrderOrderId === orderCustomer.order_id) {
      this.setState({
        showStartOrderModal: true,
      });
      return true;
    }
    if(orderCustomer.queue_id){
      this.setState({fastTrack});
      const order = this.state.orderFulfillmentOptions.find(option => option.value === 'in_store');
      this.startOrder(orderCustomer, {
        consumer_id: orderCustomer.id,
        fulfillment_method_value: order.value,
        fulfillment_method: order.fulfillment_method || null,
        order_source: order.set_order_source || null,
        is_submitted: order.is_submitted || 0
      }, fastTrack);
    }else {
      this.setState({
        orderCustomer,
        showStartOrderModal: true,
        fastTrack
      });
      this.getCaregiversForConsumer(orderCustomer);
    }
  }

  closeOrderModal() {
    this.setState({orderCustomer: null, showStartOrderModal: false});
  }


  createOrder(){
    const customerId = this.getCustomerId();
    return new Promise((resolve, reject) => {
      const messages = {success: 'orders.create.success', failed: null, failedHandler: getSalesLimitError, showBEValidationMessages: true};
      const payload = this.setCaregiverOnPayload((this.state.orderActionsData) ? this.state.orderActionsData : {consumer_id: customerId});
      this.props.actions.postItem('/api/orders', payload, itemNames.order, messages, {}, (order) => {
        resolve(order);
      })
        .then(() => {})
        .catch((e) => {
          reject(e);
        });
    });
  }

  validateOhMetrcCustomerToStartOrder() {
    const {salesComplianceSettings} = this.props;
    return this.getCustomer().then((data) => {
      const ohioMissedFields = [];
      const mailingAddress = get(data, 'addresses.0', false);
      if(
        !mailingAddress ||
        !(
          mailingAddress.street_address_1 &&
          mailingAddress.city &&
          mailingAddress.country_code &&
          mailingAddress.postal_code &&
          mailingAddress.province_code
        )
      ) {
        ohioMissedFields.push(I18n.t('customers.create.mailingAddress'));
      }

      if(!data.phone_numbers.some(phone_number => phone_number.number)) {
        ohioMissedFields.push(I18n.t('customers.create.phone'));
      }
      if(!data.physician_recommendation_date) {
        ohioMissedFields.push(I18n.t('customers.create.physicianRecommendationDate'));
      }
      if(!data.refills_authorized) {
        ohioMissedFields.push(I18n.t('customers.create.refillsAuthorized'));
      }
      if(!data.physician_id) {
        ohioMissedFields.push(I18n.t('customers.create.physicianInformation'));
      }
      if(!data.ids.some(id => id.identification_number)) {
        let today = getToday();
        today = today.format('YYYY-MM-DD HH:mm:ss');
        const allowSalesOnIdExpiration = get(salesComplianceSettings, 'order_allow_sales_on_id_expiration.value', false);
        const expiredDate = get(data, 'expired_ids.0.expired_at',false);
        const allowedToUseIdExpiringToday = allowSalesOnIdExpiration && expiredDate === today;
        if (!allowedToUseIdExpiringToday) {
          ohioMissedFields.push(I18n.t('consumers.checkin.medicalMarijuanaID'));
        }
      }
      const hasDEALicense = get(data, 'physician.physician_licenses', []).some(license => license.license_type === DEA_NUMBER);
      if(!hasDEALicense) {
        ohioMissedFields.push(I18n.t('physicians.form.DEANumberLicenseType'));
      }

      return {
        valid: ohioMissedFields.length === 0,
        disabled: false,
        showStartOrderModal: false,
        showOhMetrcDataRequiredModal: true,
        ohioMissedFields
      };
    });
  }

  validateOnFleetCustomerToStartOrder() {
    return this.getCustomer().then((data) => {
      const requiredAddressFields = ['street_address_1', 'city', 'country_code', 'province_code', 'postal_code'];

      // return back required address fields that are empty or not set
      const missingAddressFields = data.addresses.map((address) => {
        return requiredAddressFields.filter(key => (!address[key] || address[key] === '' || address[key] === null) );
      });

      const invalidAddress = missingAddressFields[0].length;

      const phoneNumbers = data.phone_numbers.filter(phone => phone.number !== '' && phone.number !== null);

      let onfleetErrorMessage = '';
      if ((phoneNumbers && phoneNumbers.length === 0) && invalidAddress) {
        onfleetErrorMessage = I18n.t('customers.table.onfleetDataRequiredBody');
      } else if (invalidAddress) {
        onfleetErrorMessage = I18n.t('customers.table.onfleetAddressRequiredBody');
      } else if (phoneNumbers && phoneNumbers.length === 0) {
        onfleetErrorMessage = I18n.t('customers.table.onfleetPhoneRequiredBody');
      }
      return {
        valid: onfleetErrorMessage === '',
        disabled: false,
        showStartOrderModal: false,
        showOnfleetDataRequiredModal: true,
        onfleetErrorMessage
      };
    });
  }

  startOrder(consumer, formData = null, fastTrack) {
    this.setState({
      disabled: true,
    });

    const fulfillment_method = formData.fulfillment_method;

    Promise.all([
      this.props.integrationState.isOhMetrc && this.validateOhMetrcCustomerToStartOrder(),
      this.props.isOnfleetIntegrator && fulfillment_method === 'delivery' && this.validateOnFleetCustomerToStartOrder(),
    ]).then(validationResults => {
      const customerIsValid = validationResults.every(state => {
        if(state && !state.valid){
          this.setState(state);
        }
        return !state || state.valid;
      });

      if(customerIsValid){
        this.startOrderRedirect(consumer, formData, fastTrack);
      }
    });
  }

  startOrderRedirect(consumer, formData, fastTrack) {
    if(!fastTrack){
      fastTrack = this.state.fastTrack;
    }

    if(!this.props.registersLocationsRequired) {
      this.setState({orderActionsData: formData, showStartOrderModal: false});
      setTimeout(() => {
        if(fastTrack){
          this.goToCartPage();
        }else{
          this.goToProductsMenu();
        }
      }, 0);
    } else {
      this.props.actions.push(`/queue/${formData.fulfillment_method_value}`);
      this.setState({disabled: false});
    }
  }

  modifyOrder(consumer) {
    this.goToProductsMenu();
    this.setState({disabled: false});
  }

  fastTrack(consumer) {
    if(consumer.order_id){
      this.goToCartPage();
      this.setState({disabled: false});
    }else{
      this.openOrderModal(consumer, true);
    }
  }

  cancel(consumer) {
    const enableButtons = (useFullState = true) => {
      this.props.actions.unsetItem(itemNames.customer);
      const newState = {
        disabled: false,
      };
      const fullState = {
        cancelOrderEventListeners: {},
        cancelOrderCustomerId: 0,
        cancelOrderOrderId: 0,
        cancelOrderEvent: 'noop',
        customer: null,
        orderCustomer: null
      };
      this.setState(Object.assign({}, newState, useFullState ? fullState : {}));
    };

    this.setState({
      disabled: true
    });
    if (consumer.order_id) {
      // Triggers cancel from CancelOrder component
      const reloadOrdersAndQueues = this.reloadOrdersAndQueues;
      this.setState({
        cancelOrderEvent: 'cancel',
        cancelOrderCustomerId: consumer.id,
        cancelOrderOrderId: consumer.order_id,
        cancelOrderEventListeners: {
          cancel: {
            onError(){
              enableButtons();
            },
            onAfterCancel(){
              reloadOrdersAndQueues();
              enableButtons();
            }
          },
          onDismiss: () => {
            enableButtons();
          }
        }
      });
    } else {
      this.props.actions.deleteItem(`api/queue/customers/${consumer.id}`,
        itemNames.order,
        {success:'orders.delete.customerSuccess', failed:'orders.removed.customerFailed'})
        .then(() => {
          this.reloadOrdersAndQueues();
          enableButtons();
        })
        .catch(enableButtons);
    }
  }

  selectOrders (id) {
    this.props.actions.getUnpaginatedData(`/api/orders/consumers/${id}`, dataNames.customerOrders);
  }

  selectFavorites (id) {
    this.props.actions.getUnpaginatedData(`/api/orders/favorites/${id}`, dataNames.customerFavorites);
  }

  goToQueue(consumer) {
    let path = '/queue';
    if(!consumer.order_source || consumer.order_source === 'in_store'){
      path += '/in_store';
    }else if(consumer.fulfillment_method === 'in_store' && consumer.order_source === 'phone'){
      path += '/pickup';
    }else {
      path += '/delivery';
    }
    this.setState({disabled: false});
    this.props.actions.push(path);
  }

  reloadOrdersAndQueues(){
    return new Promise((resolve) => {
      const {serviceFirst, reloadOrdersAndQueues} = this.props;
      if(serviceFirst){
        reloadOrdersAndQueues();
      }
      resolve();
    });
  }

  /***
   * Sets caregiver id based on caregiver shape/fields.  If not present, skips.
   * @param payload
   * @returns {*}
   */
  setCaregiverOnPayload(payload){
    if(this.props.caregiver === undefined) return payload;
    if(this.props.caregiver.caregiver_card_number !== undefined && this.props.caregiver.caregiver_id !== undefined){
      return Object.assign({}, payload, {
        caregiver_id: this.props.caregiver.id,
        registry_id: this.props.caregiver.caregiver_id,
        card_number: this.props.caregiver.caregiver_card_number
      });
    }
    return Object.assign({}, payload, {caregiver_id: this.props.caregiver.id});
  }

  doAction(method, e){
    e.stopPropagation();
    e.preventDefault();
    e.target.blur();
    if (this.props.checkinHandlerOverride) {
      this.props.checkinHandlerOverride();
      return;
    }
    setTimeout(() => { // Quick pause to allow setState from previous click propagate
      if(!this.state.disabled) { // Don't execute if we are in a disabled state - trying to beat race condition
        this[method](this.props.consumer);
      }
    }, 10);
  }

  render () {
    const {consumer, bsSize, vertical, budTenders, visitReasons, isLeafPaUpdateCaregiverOnCheckinEnabled} = this.props;
    const {showStartOrderModal, showCheckInModal, showOnfleetDataRequiredModal} = this.state;
    const disabled = this.props.disabled || this.state.disabled;

    let qt = '';
    if(!consumer.order_source || consumer.order_source === 'in_store'){
      qt = 'inStore';
    }else if(consumer.fulfillment_method === 'in_store'){
      qt = 'pickup';
    }else{
      qt = 'delivery';
    }
    const isQueued = consumer.queue_id;
    const isOrdering = consumer.order_id;
    const doAction = this.doAction;

    return (<span>
      <InProgressOverlay onDismiss={this.hideInProgress} message={this.state.inProgressMessage} isActive={this.state.showInProgress} translate={false} />
      <CancelOrder
        event={this.state.cancelOrderEvent}
        customerId={this.state.cancelOrderCustomerId}
        orderId={this.state.cancelOrderOrderId}
        eventListeners={this.state.cancelOrderEventListeners}
      />
      <ModalWrapper
        disabled={disabled}
        Component={StartOrderFormWrapper}
        onHide={this.closeOrderModal}
        customerId={consumer && consumer.id}
        onSubmit={formData => this.startOrder(consumer, formData)}
        title='customers.table.startOrder'
        showModal={showStartOrderModal}
        dialogClassName='customer-order-modal'
        caregiver={this.props.caregiver}
        caregivers={this.props.caregivers}
      />
      <ModalWrapper
        showModal={showOnfleetDataRequiredModal}
        dialogClassName='onfleet-phone-required'
        title='customers.table.onfleetDataRequiredTitle'
        onHide={() => {}}
        okayButton={{
          show: true,
          text: I18n.t('general.okay'),
          variant: 'default',
          onClick: () => {
            this.setState({showOnfleetDataRequiredModal: false});
          }
        }}
        version={2}
      >
        <p>{this.state.onfleetErrorMessage}</p>
      </ModalWrapper>
      <ModalWrapper
        showModal={this.state.showOhMetrcDataRequiredModal}
        title='customers.table.ohioDataRequiredTitle'
        onHide={() => {
          this.setState({showOhMetrcDataRequiredModal: false});
        }}
        okayButton={{
          show: true,
          text: I18n.t('general.okay'),
          variant: 'default',
          onClick: () => {
            this.setState({showOhMetrcDataRequiredModal: false});
          }
        }}
        version={2}
      >
        <p>{I18n.t('customers.table.ohioDataRequiredBody')}{this.state.ohioMissedFields.join(', ')}</p>
      </ModalWrapper>
      <CustomerCheckInModal
        disabled={disabled}
        showModal={showCheckInModal}
        close={this.closeCheckInModal}
        checkIn={this.checkIn}
        visitReasons={visitReasons}
        initialValues={{visit_reason_id: Array.isArray(visitReasons) && visitReasons[0] && visitReasons[0].id || 0}}
        customer={consumer}
        budTenders={budTenders}
        caregiver={this.props.caregiver}
        caregivers={this.props.caregivers}
        isMmuEnabled={this.props.isMmuEnabled}
      />
      <div style={{clear: 'both'}} />
      <ButtonGroup style={{width: '50%'}} bsSize={bsSize} vertical={vertical}>
        {
          isQueued || isOrdering
            ? <PermissionButton
                permissions={[p.create_orders, p.manage_orders]}
                props={{
                  disabled,
                  style: {width: vertical ? '100%' : '50%', marginTop:'0'},
                  onClick: (e) => {doAction('goToQueue', e);}
                }}
              >
                {I18n.t('consumers.checkin.goToQueue.' + qt)}
              </PermissionButton>
            : <PermissionButton
                permissions={[p.manage_queue]}
                props={{
                  disabled,
                  style: {width: vertical ? '100%' : '50%'},
                  variant: 'success',
                  onClick: (e) => {
                    isLeafPaUpdateCaregiverOnCheckinEnabled
                      ? (!this.props.validateOnCheckin ? doAction('openCheckInModal', e) : ((this.props.validateSelectedPatientData && this.props.validateSelectedPatientData) ? doAction('validateConsumerOnCaregiverCheckin', e) : doAction('openCheckInModal', e)))
                      : doAction('openCheckInModal', e);
                  }
                }}
                >
                  {I18n.t('consumers.checkin.checkIn')}
              </PermissionButton>
        }
        {isOrdering ?
            <PermissionButton
              permissions={[p.create_orders, p.manage_orders]}
              props={{
                disabled,
                style: {width: vertical ? '100%' : '50%'},
                onClick: (e) => {
                  doAction('modifyOrder', e);
                }
              }}
            >
              {I18n.t('consumers.checkin.modifyOrder')}
            </PermissionButton>
          : <PermissionButton
              permissions={[p.create_orders, p.manage_orders]}
              props={{
                disabled,
                style: {width: vertical ? '100%' : '50%'},
                onClick: (e) => {
                  isLeafPaUpdateCaregiverOnCheckinEnabled
                  ? (!this.props.validateOnCheckin ? doAction('openOrderModal', e) : ((this.props.validateSelectedPatientData) ? doAction('validateConsumerOnStartOrder', e) : doAction('openOrderModal', e)))
                  : doAction('openOrderModal', e);
                }
              }}
            >
              {I18n.t('consumers.checkin.startOrder')}
            </PermissionButton>
        }
      </ButtonGroup>
      <ButtonGroup style={{width: '50%'}} bsSize={bsSize} vertical={vertical}>
        <PermissionButton
          permissions={[p.create_orders, p.manage_orders]}
          props={{
            disabled,
            style: {width: vertical ? '100%' : '50%', marginTop:'0'},
            variant: 'primary',
            onClick: (e) => {
              isLeafPaUpdateCaregiverOnCheckinEnabled
                ? (!this.props.validateOnCheckin ? doAction('fastTrack', e) : ((this.props.validateSelectedPatientData) ? doAction('validateConsumerOnCaregiverFastTrack', e) : doAction('fastTrack', e)))
                : doAction('fastTrack', e);
            }
          }}
        >
          {I18n.t('consumers.checkin.fastTrack')}
        </PermissionButton>
          {(isQueued || isOrdering) &&
            (<PermissionButton permissions={[p.manage_queue]}
                props = {{
                  disabled,
                  style: {width: vertical ? '100%' : '50%'},
                  variant: 'danger',
                  onClick: (e) => doAction('cancel', e)
                }}
              >
                  {I18n.t('consumers.checkin.cancel')}
              </PermissionButton>
          )}
      </ButtonGroup >

      <div style={{clear: 'both'}} />
      {
        qt === 'pickup'
          ? <ButtonGroup style={{width: '100%'}} bsSize={bsSize} vertical={vertical}>
          <PermissionButton className='btn-xs'
                            permissions={[p.create_orders, p.manage_orders]}
                            props={{
                              disabled,
                              style: {width: '100%', borderBottom: '0'},
                              variant: (consumer.queue_id) ? 'danger' : 'success',
                              onClick: (e) => {doAction('pickupCheckIn', e);}
                            }}

          >
            {consumer.queue_id ? 'Check Out - No Pickup' : 'Check In For Pickup'}
          </PermissionButton>
        </ButtonGroup>
          : null
      }
    </span>);
  }
}

CustomerActionButtons.propTypes = {
  consumer: PropTypes.object.isRequired,
  caregivers: PropTypes.array.isRequired,
  constomerRecentHistory: PropTypes.object,
  customerStat: PropTypes.object,
  salesComplianceSettings: PropTypes.object,
  bsSize: PropTypes.string,
  vertical: PropTypes.bool,
  isLeafPaUpdateCaregiverOnCheckinEnabled: PropTypes.bool,
  reload: PropTypes.func,
  validateSelectedPatientData: PropTypes.func,
  actions: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    deleteItem: PropTypes.func.isRequired,
    setItem: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
  }),
  isOnfleetIntegrator: PropTypes.bool.isRequired,
  validateOnCheckin: PropTypes.bool,
  reloadOrdersAndQueues: PropTypes.func.isRequired,
  serviceFirst: PropTypes.bool.isRequired,
  isMmuEnabled: PropTypes.bool.isRequired
};

CustomerActionButtons.defaultProps = {
  bsSize: 'default',
  vertical: false
};

function mapStateToProps(state) {
  const {customerRecentHistory, customerStat, visitReasons, salesComplianceSettings} = state;
  const disabled = isSetupMode(state);
  return {
    disabled,
    visitReasons,
    customerRecentHistory,
    customerStat,
    salesComplianceSettings,
    registersLocationsRequired: userNeedsRegistersLocations(state, {permissions: [p.view_registers]}),
    caregivers: getCaregivers(state),
    isOnfleetIntegrator: isOnfleetIntegrator(state),
    integrationState: getIntegrationState(state),
    validateOnCheckin: needToValidateOnCheckin(state),
    isLeafPaUpdateCaregiverOnCheckinEnabled: isFeatureEnabled(state)('feature_leaf_pa_update_caregiver_on_checkin'),
    isMmuEnabled: isLeafPaConfigPackClosedLoopFacility(state) && isFeatureEnabled(state)('feature_leaf_pa_mmu_limits')
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, {goBack, push, postItem, getItem,
     deleteItem, setItem, getUnpaginatedData, getData, unsetItem, getConsumerLimits, addMessage});
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomerActionButtons);
