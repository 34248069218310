import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import {Row, Col, Button} from 'react-bootstrap';
import {FaPlus, FaPrint, FaTimes} from 'react-icons/fa';
import TextInput from '../../../common/form/TextInput';
import StorageLocationType from './StorageLocationType';
import LevelThreeArray from './LevelThreeArray';

const text = {
  storage: {
    label: 'locations.form.zoneName',
    add: 'locations.form.addSections'
  },
  sale: {
    label: 'locations.form.areaName',
    add: 'locations.form.addSubSections'
  }
};

const LevelTwoLocations = ({fields, push, type, createLocation, defaults, level, printLabel, integratedLocationNames,
                             showIntegratedLocationName, isCanadaFacility, currentFacility, isLeaf, integrationMapping,
                             disableLocationEdit, onDelete, isCultivation}) => {

  return (
    <React.Fragment>
    {fields.map((locationName, index) => {
      const location = fields.get(index);
      const childItem = location.child_locations && location.child_locations.length > 0 ? location.child_locations[0] : false;
      return (
        <React.Fragment key={locationName}>
          <Row className='form-fields-row'>
            <Col xs={3}>
              {!disableLocationEdit &&
                <Button
                  size='sm'
                  variant='danger'
                  className='btn-inline float-right'
                  disabled={fields.length < 2}
                  onClick={() => {
                    onDelete(fields.get(index), () => {
                      fields.remove(index);
                    });
                  }}>
                  <FaTimes/>
                </Button>
              }
            </Col>
            <Col className='form-fields-col' xs={6} md={4} lg={3}>
              <Field
                name={`${locationName}.name`} component={TextInput}
                props={{
                  label: I18n.t(text[type].label),
                  isRequired: true,
                  readOnly: disableLocationEdit,
                }}/>
            </Col>
            <StorageLocationType name={locationName} isCanadaFacility={isCanadaFacility} type={type}/>
            <Col xs={3}>
              {
                !childItem || childItem.id === undefined
                  ? null
                  : (
                  <Button
                    size='sm'
                    className='btn btn-sm btn-inline float-left'
                    style={{marginRight: '5px'}}
                    onClick={(event) => { printLabel(event, location.id ,'zone');}}
                  >
                    <FaPrint/> Print
                  </Button>
                )
              }
              {!disableLocationEdit &&
                <Button size='sm'
                  variant='primary'
                  onClick={() => push(`${locationName}.child_locations`, createLocation(type, level + 1, location.child_locations.length, defaults))}
                  className='btn-inline float-left'>
                  <FaPlus/>
                </Button>
              }
              <label className='btn-inline'>{I18n.t(text[type].add)}</label>
            </Col>
          </Row>
          <LevelThreeArray
            type={type}
            printLabel={printLabel}
            integratedLocationNames={integratedLocationNames}
            showIntegratedLocationName={showIntegratedLocationName}
            isCanadaFacility={isCanadaFacility}
            currentFacility={currentFacility}
            isLeaf={isLeaf}
            integrationMapping={integrationMapping}
            disableLocationEdit={disableLocationEdit}
            onDelete={onDelete}
            locationName={locationName}
            isCultivation={isCultivation}
          />
        </React.Fragment>
      );
    })}
      </React.Fragment>
  );
};

LevelTwoLocations.propTypes = {
  printLabel: PropTypes.func,
  fields: PropTypes.object.isRequired,
  createLocation: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['storage', 'sale']).isRequired,
  level: PropTypes.number,
  push: PropTypes.func.isRequired,
  defaults: PropTypes.array.isRequired,
  integratedLocationNames: PropTypes.array,
  showIntegratedLocationName: PropTypes.bool,
  isCanadaFacility: PropTypes.bool,
  currentFacility: PropTypes.object.isRequired,
  isLeaf: PropTypes.bool,
  integrationMapping: PropTypes.array,
  disableLocationEdit: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  isCultivation: PropTypes.bool,
};

LevelTwoLocations.defaultProps = {
  level: 2
};

export default LevelTwoLocations;
