import { createSelector } from 'reselect';
import orderBy from 'lodash.orderby';
import get from 'lodash.get';
import * as dataNames from '../constants/dataNames';
import { formatCurrencyDecimal } from '../util/formatHelper';
import { convertFromBase } from '../util/uomHelpers';

export const getOrganizationPrepackWeights = (state) => state[dataNames.prepackWeights];

export const getFacilityEnabledPrepackWeights = (state) => state[dataNames.prepackFacilityWeights];

export const getPrepackWeights = createSelector([getOrganizationPrepackWeights, getFacilityEnabledPrepackWeights], (orgWeights, enabledWeights) => {
  //Only packages which applies for SO
  return enabledWeights.filter((weight) => weight.active && get(weight, 'applies_to_sales', false)).map((enabledWeight) => {
    const orgWeight = orgWeights.find((weight) => weight.id === enabledWeight.prepack_weight_id);

    return {
      ...(orgWeight),
      categories: enabledWeight.categories,
      facilityPrepackWeightId: enabledWeight.id,
    };
  });
});

const getArray = (_, props) => props.array ? props.array : [];

export const getPrepackWeightsForArray = createSelector([getOrganizationPrepackWeights, getFacilityEnabledPrepackWeights, getPrepackWeights, getArray], (orgWeights, enabledWeights, prepackWeights, array) => {
  const prepackWeightIdsInArray = array.map((item) => get(item, 'prepack_weight_id', 0));
  const prepackWeightIds = prepackWeights.map((weight) => weight.id);
  const requiredIds = prepackWeightIdsInArray.filter((idInArray) => {
    return prepackWeightIds.indexOf(idInArray) === -1;
  });
  return prepackWeights.concat(requiredIds.map((id) => {
    const orgWeight = orgWeights.find((weight) => weight.id === id);
    return {
      ...(orgWeight),
      facilityPrepackWeightId: undefined,
    };
  }));
});

export const getSalesOrderWeightsMapping = (prepackWeights, itemMaster, line = {}, subItems = {}, subItemsArray = []) => {

  const getChildItemMaster = (field, value) => {
    return get(itemMaster, 'children', []).find((itemMaster) => {
      return parseInt(get(itemMaster, field)) === parseInt(value);
    });
  };

  const prepackWeightIdCounts = subItemsArray.reduce((acc, subItem) => {
    const childItemMaster = getChildItemMaster('id', get(subItem, 'item_master_id', 0));
    const prepackWeightId = get(childItemMaster, 'prepack_weight_id');
    if(!childItemMaster || !prepackWeightId) return acc;
    if (acc[prepackWeightId]) {
      acc[prepackWeightId] = acc[prepackWeightId] + 1;
      return acc;
    }
    acc[prepackWeightId] = 1;
    return acc;
  }, {});

  const itemMasterUom = get(itemMaster, 'default_uom', 'GR');
  const availablePrepackWeights = prepackWeights.filter((weight) => {
    const weightUom = get(weight, 'uom');
    if(weightUom !== itemMasterUom) {
      return false;
    }
    return get(weight, 'categories', []).find((cat) =>
      cat.category_id === itemMaster.category_id
    );
  }).reduce((acc, weight) => {
    const count = prepackWeightIdCounts[weight.id] || 1;
    for (let i = 0; i < count; i++) {
      acc.push(weight);
    }
    return acc;
  }, []);

  return orderBy(availablePrepackWeights.map((weight) => {
    const child = get(itemMaster, 'children', []).find((itemMaster) => get(itemMaster, 'prepack_weight_id') === weight.id);
    const childId = String(get(child, 'id', 0));
    const weight_at_uom = convertFromBase(get(weight, 'weight_base', 0), weight.uom);
    const unitCost = parseFloat(get(line, 'unit_price', 0)) * weight_at_uom;
    const total = get(subItems, childId, {qty: 0}).qty * weight_at_uom;
    return {
      ...(weight),
      id: get(subItems, childId, {id: undefined}).id,
      uom: 'EA',
      itemMasterUom: itemMaster.default_uom,
      item_master_id: parseInt(childId),
      itemMaster: child,
      qty: get(subItems, childId, {qty: 0}).qty,
      unit_cost: formatCurrencyDecimal(unitCost),
      total,
      prepack_weight: weight,
    };
  }),['prepack_weight.fulfillment_units', 'asc']);
};
