/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import {Card} from 'react-bootstrap';
import {FaTimes} from 'react-icons/fa';
import {I18n} from 'react-redux-i18n';
import ErrorBoundry from '../ErrorBoundry';
import DirtyFormWrapper from './DirtyFormWrapper';

// The two divs provide a 'mask' for when Panel Heading is sticky. It masks the text from the Panel.Body to scroll above the sticky Panel.Heading
const FormWrapper = ({title, titleVariables, sticky, goBack, children, className, localize, titleDescription, formName, shouldWarnDirty}) =>
  <ErrorBoundry>
    {sticky && (
      <div style={{
        position: 'fixed',
        left: '0px',
        top: '50px',
        width: '100%',
        zIndex: '200',
        height: '10px',
        backgroundColor: 'white',
        marginLeft: '29px',
        marginRight: '30px'
      }}>
      <div style={{
        float: 'right',
        top: '50px',
        width: '30px',
        zIndex: '300',
        height: '20px',
        backgroundColor: 'white',
        marginRight: '28px'
      }}/>
    </div>
    )}
    <Card className={`form-page ${className}`}>
      <Card.Header className={sticky ? 'sticky-panel-header' : ''}>
        <Card.Title className={'panel-heading'}>
          <h2 className='panel-title' style={{cursor: 'pointer'}}>
            {goBack ? <FaTimes className='go-back-x' onClick={goBack}/> : ''}
            {title && localize ? I18n.t(title, titleVariables) : title ? title : ''}
          </h2>
          {titleDescription &&
            <h5 style={{lineHeight: '1.5'}} className='text-muted'>{titleDescription}</h5>
          }
        </Card.Title>
      </Card.Header>
      <Card.Body>
        {formName && shouldWarnDirty
          ? <DirtyFormWrapper formName={formName}>{children}</DirtyFormWrapper>
          : children
        }
      </Card.Body>
    </Card>
  </ErrorBoundry>;

FormWrapper.propTypes = {
  title: PropTypes.string,
  titleDescription: PropTypes.string,
  titleVariables: PropTypes.object,
  sticky: PropTypes.bool,
  localize: PropTypes.bool,
  goBack: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
  formName: PropTypes.string,
  shouldWarnDirty: PropTypes.bool.isRequired
};

FormWrapper.defaultProps = {
  localize: true,
  className: '',
  shouldWarnDirty: false
};

export default FormWrapper;
