import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Button, ButtonToolbar} from 'react-bootstrap';
import {FaMinus, FaPlus} from 'react-icons/fa';

const FieldArrayIncrement = ({label, fields, showDecrement, minLength, maxLength, className, defaultValue}) => {
  const min = minLength || 0;
  return (<ButtonToolbar className={`add-item ${className}`}>
    {label ? <span>&nbsp;{I18n.t(label)}</span> : null}
    <Button size='sm' variant='success' style={{marginLeft: '6px'}} disabled={!!(maxLength && fields.length >= maxLength)} onClick={() => fields.push((defaultValue ? defaultValue : {}))}>
      <FaPlus/>
    </Button>
    {showDecrement && fields.length > min ?
    <Button size='sm' variant='danger' onClick={() => fields.remove(fields.length - 1)} style={{marginLeft: '6px'}}>
      <FaMinus/>
    </Button> : null}
  </ButtonToolbar>);
};

FieldArrayIncrement.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.object,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  fields: PropTypes.object.isRequired,
  showDecrement: PropTypes.bool,
  className: PropTypes.string,
};

FieldArrayIncrement.defaultProps = {
  minLength: 0,
  className: '',
  showDecrement: false
};

export default FieldArrayIncrement;
