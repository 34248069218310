import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import {ALPHA_NUMERIC_REG_EXP} from '../common/form/validationRegExs';
import {requiredFieldValidation} from '../common/form/redux-form/validations';

export const validate = (values,props) => {
  const errors = {
    selectedStrains: [],
    _error: []
  };

  values.selectedStrains.forEach( (strain, index) => {
    const invalidStrain = strain.strain_code && !strain.strain_code.match(ALPHA_NUMERIC_REG_EXP);
    errors._error[index] = invalidStrain;

    const strainError = {};
    if(invalidStrain) {
      strainError.strain_code = I18n.t('common.form.mustBeAlphaNumeric', {fieldName: I18n.t('strains.strainCode')});
    }

    if(get(props,'integrationState.isCanada',false)){
      strainError.strain_value = requiredFieldValidation(strain.strain_value, 'strains.strainValue');
      strainError.strain_value_per_gram = requiredFieldValidation(strain.strain_value_per_gram, 'strains.strainValuePerGram');
    }

    if (get(props, 'integrationState.isMetrc')) {
      strainError.indica_percent = requiredFieldValidation(strain.indica_percent, 'strains.indicaPercent');
      strainError.sativa_percent = requiredFieldValidation(strain.sativa_percent, 'strains.sativaPercent');
    }

    if (strain.indica_percent || strain.sativa_percent) {
      if ((parseFloat(strain.indica_percent) + parseFloat(strain.sativa_percent)) != 100) {
        strainError.indica_percent = I18n.t('strains.strainIndicaSativaSumError');
      }
    }

    errors.selectedStrains[index] = Object.keys(strainError).filter((key) => strainError[key]).length > 0 ? strainError : undefined;
  });

  // Nuke completely - some oddity where if the array are present but empty still blocks submit
  if(errors.selectedStrains.filter((strain) => strain).length === 0){
    delete(errors.selectedStrains);
  }
  if(errors._error.filter((err) => err).length === 0){
    delete(errors._error);
  }

  return errors;
};

export default validate;
