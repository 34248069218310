import {createSelector} from 'reselect';
import get from 'lodash.get';
import sortBy from 'lodash.sortby';
import * as dataNames from '../constants/dataNames';

import {getCategories} from './categorySelectors';
import {getSubcategories} from './subcategoriesSelectors';
import {getSellableItemMasterData} from './itemMastersSelectors';

export const getCategoryOptions = createSelector( [getCategories], categories => {
  return categories.map( category => {
    return {
      text: category.name,
      value: category.id
    };
  });
});

export const getSubcategoryOptions = createSelector( [getSubcategories], subcategories => {
  return subcategories.map( subcategory => {
    return {
      text: subcategory .name,
      value: subcategory .id
    };
  });
});

export const getProductOptions = createSelector( [getSellableItemMasterData], item_masters => {
  item_masters = sortBy(item_masters, 'name', 'asc');
  return item_masters.map( item_master => {
    return {
      text: item_master.name,
      value: item_master.id
    };
  });
});

export const getProductSales = (state) => get(state, dataNames.productSalesReport, []);

export const getProductSalesReport = createSelector(
  [getProductSales],
  (productSalesReport) => {
    if(!productSalesReport.length) return [];
    return productSalesReport;
  }
);
export const getMetrcSales = (state) => get(state, dataNames.metrcSalesReport, []);
export const getMetrcSalesReport = createSelector(
  [getMetrcSales],
  (metrcSalesReport) => {
    if(!metrcSalesReport.length) return [];
    return metrcSalesReport;
  }
);

