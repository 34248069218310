import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col} from 'react-bootstrap';
import get from 'lodash.get';
import SubmitSection from '../../common/form/SubmitSection';
import InternationalCurrencyStatic from '../../common/form/InternationalCurrencyStatic';
import AccordionPanel from '../../common/AccordionPanel';
import InternationalDisplayQty from '../../common/InternationalDisplayQty';
import DeliveryOrderFormHeaderCourier from './DeliveryOrderFormHeaderCourier';
import DeliveryOrderFormHeaderRetail from './DeliveryOrderFormHeaderRetail';

export const DeliveryOrderForm = (props) => {

  const {
    facilityTimezone,
    handleSubmit,
    submitting,
    invalid,
    change,
    formValues,
    courierOptions,
    orderDetails,
    consumerInfo,
    isCourier,
  } = props;

  const deliveryStatusOptions = [
    {
      text: I18n.t('deliveryOrders.form.pending'),
      value: 'pending'
    },
    {
      text: I18n.t('deliveryOrders.form.outForDelivery'),
      value: 'out_for_delivery'
    },
    {
      text: I18n.t('deliveryOrders.form.Completed'),
      value: 'completed'
    },
  ];

  const submitSectionSettings = {
    actionSettings: {
      submit: {
        invalid,
        submitting,
        text: I18n.t('common.actions.save'),
        style: 'primary',
      }
    },
    align: 'right'
  };

  const renderOrderDetailsBody = () => {
    const categorizedProducts = get(orderDetails, 'categorized_products' , []);
    return Object.keys(categorizedProducts).map((category, cindex) => {
      const categoryHeader = (
        <tr key={`cat${cindex}`}>
          <td><b>{category}</b></td>
          <td></td>
          <td></td>
        </tr>
      );
      const categoryProducts = categorizedProducts[category].map((product, pindex) => (
        <tr key={`c${cindex}_p${pindex}`}>
          <td>{get(product, 'name')}</td>
          <td><InternationalDisplayQty qty={get(product, 'quantity', 0)} uom={get(product, 'uom', '')} displayUom={true}/></td>
          <td></td>
        </tr>
      ));
      return ([categoryHeader, categoryProducts]);
    });
  };

  return (
    <form className='delivery-order-form' onSubmit={handleSubmit}>
      {isCourier ?
        <DeliveryOrderFormHeaderCourier
          facilityTimezone={facilityTimezone}
          deliveryStatusOptions={deliveryStatusOptions}
          change={change}
        /> :
        <DeliveryOrderFormHeaderRetail
          facilityTimezone={facilityTimezone}
          courierOptions={courierOptions}
          deliveryStatusOptions={deliveryStatusOptions}
          change={change}
          formValues={formValues}
        />
      }
      <div>
      </div>
      <AccordionPanel title='deliveryOrders.form.sections.orderDetails' className='form-panel container-info'>
        <table className='table'>
          <thead>
            <tr>
              <th>{I18n.t('deliveryOrders.form.orderDetails.itemName')}</th>
              <th>{I18n.t('deliveryOrders.form.orderDetails.quantity')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {renderOrderDetailsBody()}
            <tr>
              <td></td>
              <td></td>
              <td><b>{I18n.t('deliveryOrders.form.orderDetails.orderTotal')}</b></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td><InternationalCurrencyStatic>{orderDetails.order_total}</InternationalCurrencyStatic></td>
            </tr>
          </tbody>
        </table>
      </AccordionPanel>
      <AccordionPanel title='deliveryOrders.form.sections.patientDetails' className='form-panel container-info'>
        <Col md={12}>
          <p>{get(consumerInfo, 'consumer_name', '')}</p>
          <p>{get(consumerInfo, 'consumer_address_line_1', '')}</p>
          <p>{get(consumerInfo, 'consumer_address_line_2', '')}</p>
        </Col>
      </AccordionPanel>
      <SubmitSection settings={submitSectionSettings} />
    </form>
  );
};

DeliveryOrderForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
};

export default  DeliveryOrderForm;
