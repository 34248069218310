import React from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import {push} from 'react-router-redux';
import {Alert} from 'react-bootstrap';
import {FaExclamationTriangle} from 'react-icons/fa';
import {I18n} from 'react-redux-i18n';
// import {notifyBugsnag} from '../../util/bugsnag';

class ErrorBoundry extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true , error, info});
    // You can also log the error to an error reporting service
    // notifyBugsnag(error);
    console.log(error.message, info);// eslint-disable-line
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI onClick={goBack}
      return (<Alert variant='danger'>
          <h3 className='panel-title'><FaExclamationTriangle className='float-right' />
            {I18n.t('error.unhandled.title')}
          </h3>
          {I18n.t('error.unhandled.instructions')}
      </Alert>);
    }
    return this.props.children;
  }
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({push}, dispatch),
  };
}

export default connect(state => ({}), mapDispatchToProps)(ErrorBoundry);
