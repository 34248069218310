import {I18n} from 'react-redux-i18n';
import {set} from 'lodash';
import find from 'lodash.find';
import * as validations from '../../common/form/redux-form/validations';
import {dispensary} from '../../../constants/facilityTypes';
import {validatePostalCode} from '../../common/form/validatePostalCode';
import {dea_number, state} from '../../../constants/fileTypes';

export const stateIsNotRequiredFor = ['ZA', 'CO', 'UY', 'IT', 'ES', 'TH'];

export const validate = (values, ownProps) => {
  const errors = {facilities: []};
  if(!values.expiration_period && values.expiration_period !== 0){
    errors.expiration_period = I18n.t('partners.createPartner.onlyNumberContain');
  }
  values.facilities.map((facility, index) => {
    const facilityErrors = {
      province_code: !stateIsNotRequiredFor.includes(facility.country_code) ? validations.required(facility.province_code, I18n.t('common.form.state')) : undefined,
      timezone: validations.required(facility.timezone, I18n.t('common.form.timezone')),
      name: validations.required(facility.name, I18n.t('partners.createPartner.facilityName')),
      retail_type: facility.type === dispensary ? validations.required(facility.retail_type, I18n.t('partners.createPartner.retailFacilityType')) : undefined,
      postal_code: validatePostalCode(facility.postal_code, facility.country_code),
    };
    if (
      facility.type === dispensary &&
      facility.province_code &&
      facility.province_code.toLowerCase() === 'oh' &&
      ownProps.integrationState.isOhMetrc
    ) {
      facilityErrors.files = undefined;

      const stateLicense = find(facility.files, { type: state });
      const deaNumber = find(facility.files, { type: dea_number });
      if (!stateLicense) {
        set(facilityErrors, `files._error[${state}]`,  validations.required(null, I18n.t('partners.createPartner.licenseNumber')));
      }

      if (!deaNumber) {
        set(facilityErrors, `files._error[${dea_number}]`,  I18n.t('partners.createPartner.deaNumberErrorMessage'));
      }

      if (stateLicense && deaNumber) {
        facilityErrors.files = facility.files.map(file => {
          if (file.type === 'state' && (!file.name || !file.name.trim())) {
            return { name: I18n.t('common.form.isRequired', {fieldName: I18n.t('partners.createPartner.licenseNumber')}) };
          } else if (file.type === 'dea_number' && (!file.name || !file.name.trim())) {
            return { name: I18n.t('partners.createPartner.deaNumberErrorMessage') };
          }
        });
      }

      facilityErrors['city'] = validations.required(facility.city);
      facilityErrors['postal_code'] = validations.required(facility.postal_code) || validatePostalCode(facility.postal_code, facility.country_code);
      facilityErrors['country_code'] = validations.required(facility.country_code);
      facilityErrors['street_address_1'] = validations.required(facility.street_address_1);
    }

    errors.facilities[index] = facilityErrors;
  });
  return errors;
};

export const normalizeBranchIdentifier = (value, previousValue) => {
  if(!value) return value;
  value = String(value).replace(/\W/g, '');
  return  (/^\w+$/.test(value))
    ? (value.length < 7) ? value : value.substr(0, 6)
    : previousValue;
};

export const formatBranchIdentifier = (value) => {
  if(!value) return value;
  value = normalizeBranchIdentifier(value);
  return String(value).split('').reduce((acc, c, i) => {
    const accIndex = Math.floor(i / 3);
    if(accIndex > 1) return acc;
    if(!acc[accIndex]) acc[accIndex] = '';
    acc[accIndex] += c;
    return acc;
  }, []).join(' ');
};
