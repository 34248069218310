import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import {ReceiveTrackingIdWrapper} from './ReceiveTrackingIdWrapper';
import FormWrapper from '../../common/form/FormWrapper';
import InProgressOverlay from '../../common/InProgressOverlay';

import * as apiActions from '../../../actions/apiActions';
import * as dataNames from '../../../constants/dataNames';

export class ReceiveTrackingIdsPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.redirect = this.redirect.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.preventEnterDefault = this.preventEnterDefault.bind(this);
    this.onHideLoader = this.onHideLoader.bind(this);

    this.state = {
      isActive: false,
      message: I18n.t('tracking.savingMessage'),
    };

  }

  redirect() {
    this.props.actions.goBack();
  }

  onHideLoader(){
    this.setState({isActive: false});
  }

  onSubmit(data) {
    this.setState({isActive: true});
    this.props.actions.postData(
      '/api/tracking/tag-groups',
      data,
      dataNames.trackingIds,
      {
        success: 'tracking.receiveTrackingIds.form.success',
        failed: 'tracking.receiveTrackingIds.form.fail'
      },
      null,
      this.props.actions.goBack
    ).then(() => this.onHideLoader()).catch(() => this.onHideLoader());
  }

  preventEnterDefault(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  render() {
    return (
      <div className='tracking-ids-page'>
        <InProgressOverlay isActive={this.state.isActive} message={this.state.message} translate={false} onDismiss={this.onHideLoader} />
        <FormWrapper title='tracking.receiveTrackingIds.title' goBack={this.redirect} className='receive-tracking-ids-form'>
          <ReceiveTrackingIdWrapper onSubmit={this.onSubmit} preventEnterDefault={this.preventEnterDefault} />
        </FormWrapper>
      </div>
    );
  }
}

ReceiveTrackingIdsPage.propTypes = {
  actions: PropTypes.shape({
    postData: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired
  })
};

function mapStateToProps(state) {
  return {
    state
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {goBack});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiveTrackingIdsPage);
