import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import ReactSelectInput from '../common/form/ReactSelectInput';

const DeliveryMethod = ({isProductMedicated, formOptions, isCure, fieldNamePrefix}) => {
  return (
    isCure && isProductMedicated ?
      <tr className='delivery-method-row'>
        <td colSpan={2}>
          <Field
            component={ReactSelectInput}
            name={`${fieldNamePrefix}.cure_order_info.form_id`}
            props={{
              options: formOptions,
              valueKey: 'value',
              textKey: 'text',
            }}
          />
        </td>
      </tr>
      : isCure ? <tr style={{height: '75px'}}/> : null
  );
};

DeliveryMethod.propTypes = {
  isProductMedicated: PropTypes.bool,
  formOptions: PropTypes.array,
  isCure: PropTypes.bool,
  fieldNamePrefix: PropTypes.string.isRequired
};

export default DeliveryMethod;
