import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import {createSelector} from 'reselect';
import {salesComplianceSettings} from '../constants/itemNames';
import {paymentsAvailable as defaultPayments} from '../constants/salesSettings';
import getSalesSettings from '../util/salesSettings';
import {getActiveFacility} from './facilitiesSelectors';
import {getOrderWithRichProducts} from './ordersSelectors';
import * as consumerTypes from '../constants/consumerTypes';
import {userHasPermission} from './usersSelectors';
import * as  p from '../constants/permissions';
import {getIntegrationState} from './integration/integrationSelectors';
import {getMetrcSettings} from './integration/metrcSelectors';
import {getOrderSettings} from './orderSettingsSelectors';
import {patientSettingsGoesByEnabled} from './complianceSettingsSelectors';
import { getCategories } from './categorySelectors';
import { isFeatureEnabled } from './featureToggles';

const getAt = get;

export const getSettings = (state) => state[salesComplianceSettings];

export const isEquivalencyLimitEnabled = createSelector(
  [getSettings],
  (settings) => {
    return !settings.order_sales_limit_method || settings.order_sales_limit_method.value === 'equivalency';
  }
);

export const isLockPreCheckinOnlineOrder = createSelector(
  [getSettings],
  (settings) => {
    return settings.lock_pre_checkin_online_order && settings.lock_pre_checkin_online_order.value;
  }
);

//@TODO: Refactor the dependency on getSalesSettings out of these... it seemed like a good idea at the time
// but just makes the code harder to maintain
export const getPaymentOptions = createSelector([getSettings, getIntegrationState], (settings, integrationState) => {

  // payment options have been stored multiple ways so this deals with the oddities
  const getNormalizedArrayOfPaymentOptions = (paymentOptions) =>  {
    if(!Array.isArray(paymentOptions)) return [];
    const pushCash = (acc) => {
      if(acc.indexOf('cash') === -1) acc.push('cash');
      return acc;
    };
    const push = (acc, value) => {
      acc.push(value);
      return acc;
    };
    return paymentOptions.reduce((acc, payment) => {
      if(typeof payment === 'string') return push(acc, payment.toLowerCase());
      if(payment === null || payment === undefined) return pushCash(acc);
      if(typeof payment === 'object'){
        if(payment.value !== undefined) return push(acc, payment.value.toLowerCase());
      }
      return pushCash(acc);
    }, []);
  };

  const salesSettings = getSalesSettings(settings);
  const paymentOptions = getNormalizedArrayOfPaymentOptions(salesSettings.getSetting('paymentOptions'));

  if (integrationState.isHypur && !paymentOptions.includes('hypur')) {
    const hypurOption = defaultPayments.find(defaultPayment => defaultPayment.value === 'hypur');
    paymentOptions.push(hypurOption && hypurOption.value);
  }

  if (integrationState.isAeropayIntegrated && !paymentOptions.includes('aeropayintegrated')) {
    const aeropayintegratedOption = defaultPayments.find(defaultPayment => defaultPayment.value === 'aeropayintegrated');
    paymentOptions.push(aeropayintegratedOption && aeropayintegratedOption.value);
  }

  if (integrationState.isPosabit && !paymentOptions.includes('posabit')) {
    const posabitOption = defaultPayments.find(defaultPayment => defaultPayment.value === 'posabit');
    paymentOptions.push(posabitOption && posabitOption.value);
  }

  if (integrationState.isAlleaves && !paymentOptions.includes('alleaves')) {
    const alleavesOption = defaultPayments.find(defaultPayment => defaultPayment.value === 'alleaves');
    paymentOptions.push(alleavesOption && alleavesOption.value);
  }

  if (paymentOptions.length === 0) {
    return defaultPayments;
  }

  const paymentHandle = (acc, option) => {
    const findHandle = (dp) => dp.value.toLowerCase() === option || dp.value.toLowerCase() === option && option.replace('_', '');
    const paymentOption = defaultPayments.find(findHandle);

    if (paymentOption) {
      paymentOption.text = paymentOption.i18n;
      acc.push(paymentOption);
    }

    return acc;
  };

  return paymentOptions.reduce(paymentHandle, []);
});

const getCustomerFromProps = (_, props) => props && props.customer ? props.customer : {};
export const getPatientSettings = createSelector([getSettings, getActiveFacility, getCustomerFromProps, patientSettingsGoesByEnabled], (settings, facility, customer, goesByEnabled) => {
  const salesSettings = getSalesSettings(settings);
  const patientType = getAt(customer, 'type', 'medical');
  const settingsByType = `order_sales_limits_by_consumer_type.value.${patientType}`;
  const useMedicalSetting = () => {
    return facility.retail_type === 'medical' || (facility.retail_type !== 'recreational' && patientType === 'medical');
  };
  return {
    goesByEnabled,
    documentUploads: parseInt(salesSettings.getSetting('uploadDocs')),
    allowTempPaperwork: useMedicalSetting() ? parseInt(getAt(salesSettings.settings, 'order_allow_sales_with_temp_paperwork.value', 0)) : 0,
    doctorRequiredForTempPaperwork: parseInt(getAt(salesSettings.settings, `${settingsByType}.order_physician_info`, '0')),
  };
});

export const getCustomerLimitsSettings = createSelector([getSettings], settings => {
  const restrict_sales =  settings.order_restrict_sales.value;
  return {
    facility_possession_limit: restrict_sales.weight_value,
    // facility_plants_veg_limit: customer.facility_plants_veg_limit,
    // facility_plants_flower_limit: customer.facility_plants_flower_limit,
    // facility_plants_total_limit: customer.facility_plants_total_limit,
    facility_order_total_limit: restrict_sales.interval,
    facility_order_total_limit_period: restrict_sales.time_period
  };
});

export const getCompliance = createSelector([getSettings], settings => {
  const salesSettings = getSalesSettings();
  salesSettings.setSettings(settings);
  return {
    settings: salesSettings.getSetting('eqSettings')
  };
});

export const isSalesLimitsAppliedAcrossLoop = createSelector([getSettings], settings => {
  const salesSettings = getSalesSettings();
  salesSettings.setSettings(settings);
  return get(salesSettings, 'settings.order_sales_limits_calculate_across_loop.value');
});

export const getSalesLimitsByConsumerType = createSelector([getSettings, getCategories], (settings, categories) => {
  const salesSettings = getSalesSettings();
  salesSettings.setSettings(settings);

  const forcedMapping = get(salesSettings, 'settings.order_sales_limit_categories_forced_mapping.value');
  const byConsumerType = get(salesSettings, 'settings.order_sales_limits_by_consumer_type.value');

  const result = [];

  if (!byConsumerType || !forcedMapping || !categories) {
    return result;
  }

  for (const [consumer_type, rules] of Object.entries(byConsumerType)) {
    const categories_rules = [];
    for (const [group, mappedCategories] of Object.entries(forcedMapping)) {
      if (group !== 'NO_SALES_LIMIT') {
        mappedCategories.forEach((mappedCategory) => {
          const category = categories.find(cat => cat.category_code === mappedCategory);
          const limits = get(rules, 'method_configuration').find(rule => rule.sales_limit_category_key === group);
          categories_rules.push({
            id: get(category, 'id'),
            category_code: get(category ,'category_code'),
            name: get(category, 'name'),
            qty: get(limits, 'qty'),
            uom: get(limits, 'uom'),
            limit_using: get(limits, 'limit_using')
          });
        });
      }
    }
    result.push({
      consumer_type,
      limit_to: rules.limit_to,
      method: rules.sales_limit_method,
      rules: categories_rules
    });
  }
  return result;
});

export const getSalesAgeLimit = createSelector(
  [getSettings], settings => {
    if(!settings['order_recreational_sales_age_limit']){
      return 999;
    }
    return parseInt(settings['order_recreational_sales_age_limit'].value);
  }
);

export const getAllowAnonymousOrders = createSelector(
  [getSettings, (state) => state], (settings, state) => {
    return !!get(settings,'order_allow_anonymous.value',false) && userHasPermission(state, {permissions: [p.manage_anonymous_orders]});
  }
);

export const getOrderRequiresCheckIn = createSelector(
  [getOrderSettings, (state) => state], (settings, state) => {
    return Boolean(get(settings,'order_requires_checkin.value',false));
  }
);

export const getAnonymousCustomerTypes = createSelector(
  [getSettings], settings => {
    const customerTypeOptions = [
      { text: I18n.t('customers.create.medical'),
        value: consumerTypes.MEDICAL },
      { text: I18n.t('customers.create.recreational'),
        value: consumerTypes.RECREATIONAL },
    ];
    const setting = get(settings,'order_anonymous_customer_types.value',[]);
    return customerTypeOptions.filter( t => setting.indexOf(t.value) != -1 );
  }
);

const convertRawMedicatedWeightToMilligrams = (quantity) => {
  quantity = parseFloat(quantity) / 1000;
  return quantity;
};

// Need to know the measure of the source... this could be a grams product in which case the conversions for mg don't work
const convertWeightByRule = (weight, quantity, byGram, rule) => {
  const eq = parseFloat(rule.flower_weight_value);
  // rule could be using grams, milligrams, or each
  if(rule.uom === 'EA') {
    return quantity / parseFloat(rule.weight_value); // In this case weight value is actually the count
  }
  if(rule.uom === 'GR' && !byGram){
    return (eq < 1)
      ? (((weight / 1000) * quantity) / parseFloat(rule.weight_value)) * eq
      : (((weight / 1000) * quantity) / ((parseFloat(rule.weight_value) / eq)));
  }
  if(rule.uom === 'GR' && byGram){
    return (eq < 1)
      ? ((weight * quantity) / parseFloat(rule.weight_value)) * eq
      : ((weight * quantity) / ((parseFloat(rule.weight_value) / eq)));
  }
  // MG
  return (eq < 1)
    ? ((weight * quantity) / parseFloat(rule.weight_value)) * eq
    : ((weight * quantity) / ((parseFloat(rule.weight_value) / eq)));
};

export const getProductsWithComplianceWeightField = createSelector([getSettings, getOrderWithRichProducts], (settings, order) => {

  if(Object.keys(settings).length === 0 || Object.keys(order).length === 0) return [];

  const salesSettings = getSalesSettings();
  salesSettings.setSettings(settings);

  const compliance = salesSettings.getSetting('eqSettings');
  const categoriesUsingMedicatedWeight = ['CARTPENS', 'EDIBLE', 'INFUSED'];

  const subCategoryIds = compliance.reduce((acc, c) => {
    if(c.subcategory_id > 0) acc.push(c.subcategory_id);
    return acc;
  }, []);
  const medicatedCategories = compliance.reduce((acc, c) => {
    if(categoriesUsingMedicatedWeight.indexOf(c.category_code) !== -1) acc.push(c.category_id);
    return acc;
  }, []);
  const nonMedicatedCategories = [6];

  return order.products.map((product) => {

    if(nonMedicatedCategories.indexOf(product.category_id) !== -1){
      product.complianceWeight = 0;
      return product;
    }

    // Get applicable rule
    const rule = compliance.find((c) => {
      if(subCategoryIds.indexOf(product.subcategory_id) !== -1){
        return c.category_id === product.category_id && c.subcategory_id === product.subcategory_id;
      }
      return c.category_id === product.category_id;
    });

    if(!rule) {
      product.complianceWeight = product.sold_weight;
      return product;
    }

    let byGram = false;

    if(medicatedCategories.indexOf(product.category_id) !== -1){
      const convertedWeight = convertRawMedicatedWeightToMilligrams(product.medicated_weight_base);
      product.complianceWeight = convertWeightByRule(convertedWeight, product.quantity, byGram, rule);
      return product;
    }

    byGram = true;
    product.complianceWeight = convertWeightByRule(product.sold_weight, product.quantity, byGram, rule);
    return product;

  });

});

export const getInventoryAvailableError = (errors) => {
  const itemError = get(errors, 'response.data.errors.VALIDATION.order_id.0');
  if (itemError) {
    return {
      message: itemError
    };
  }
  return false;
};

export const getSalesLimitError = (errors) => {
  const nextResetDate = get(errors, 'response.data.errors.VALIDATION.next_reset_date.0', 'visit');
  const resetDate = nextResetDate === 'visit' ? 'next visit' : nextResetDate;

  const maxLimits = get(errors, 'response.data.errors.VALIDATION.max_limits.0', false);
  const minLimits = get(errors, 'response.data.warnings.min_limits.0', false);
  if (minLimits || maxLimits) {
    return {
      message: minLimits || maxLimits,
    };
  }

  const itemMasterError = get(errors, 'response.data.errors.VALIDATION.item_master_id.0');
  if (itemMasterError) {
    return {
      message: itemMasterError
    };
  }

  const getEquivalencyError = (reason) => {
    const grams = Array.isArray(reason.grams_remaining) ? parseFloat(reason.grams_remaining[0]).toFixed(3) : parseFloat(reason.grams_remaining).toFixed(3);
    const weight_limit_enforced = Array.isArray(reason.weight_limit_enforced) ? reason.weight_limit_enforced[0] : reason.weight_limit_enforced;
    const overage = (Array.isArray(reason.grams_remaining) && Array.isArray(reason.current_order_weight))
      ? (parseFloat(reason.current_order_weight[0]) - parseFloat(reason.grams_remaining[0])).toFixed(3)
      : (parseFloat(reason.current_order_weight) - parseFloat(reason.grams_remaining)).toFixed(3);
    const current_order_weight = reason.current_order_weight[0];
    const translationData = {grams, weight_limit_enforced, reset: resetDate, overage, current_order_weight};
    return {
      message: I18n.t('retail.salesSettings.errors.limit_exceeded', translationData)
    };
  };

  const getMetrcMiError = (reason) => {
    const monthly_limit = reason.total_weight_integrator_restriction[0];
    if (monthly_limit === null) {
      return getEquivalencyError(reason);
    }
    const daily_limit = reason.limit_exceeded[0];
    const appliedLimit = (daily_limit > monthly_limit) ? monthly_limit : daily_limit;
    const previous_orders_weight = Array.isArray(reason.previous_orders_weight) ? parseFloat(reason.previous_orders_weight[0]) : parseFloat(reason.previous_orders_weight);
    const current_order_weight = Array.isArray(reason.current_order_weight) ? parseFloat(reason.current_order_weight[0]) : parseFloat(reason.current_order_weight);
    const exceeded_grams = (previous_orders_weight + current_order_weight - parseFloat(appliedLimit)).toFixed(3);
    const period = daily_limit > monthly_limit ? 'Monthly' : 'Daily';
    const translationData = {daily_limit, monthly_limit, exceeded_grams, period};
    return {
      message: I18n.t('retail.salesSettings.errors.metrc_mi_limit_exceeded', translationData)
    };
  };

  const getMetrcError = (reason) => {
    const limit = reason.total_weight_integrator_restriction[0];
    if (limit === null) {
      return getEquivalencyError(reason);
    }
    const overage = Array.isArray(reason.current_order_weight)
      ? (parseFloat(reason.current_order_weight[0]) - parseFloat(limit)).toFixed(3)
      : (parseFloat(reason.current_order_weight) - parseFloat(limit)).toFixed(3);
    if (overage < 0) {
      return getEquivalencyError(reason);
    }
    const translationData = {grams: limit, overage};
    return {
      message: I18n.t('retail.salesSettings.errors.metrc_limit_exceeded', translationData)
    };
  };

  const limitExceeded = get(errors, 'response.data.errors.VALIDATION.limit_exceeded.0');
  const limitMethod = get(errors, 'response.data.errors.VALIDATION.limit_method.0');
  const limitStringKey = limitMethod === 'categories' ? 'category_limit_exceeded' : 'lab_compliance_limit_exceeded';
  const integratorInfo = get(errors, 'response.data.errors.VALIDATION.integrator_info.0', {});
  const integratorName = `${integratorInfo.integrator}_${integratorInfo.state}`;
  const errorOrderCheckValidationMessage = get(errors, 'response.data.errors.VALIDATION.ORDER_ERROR.0', false);

  let getErrorMessage;
  switch (integratorName) {
  case 'metrc_mi':
    getErrorMessage = getMetrcMiError;
    break;
  case 'metrc_md':
  case 'metrc_mt':
  case 'dc':
  case 'co':
    getErrorMessage = getMetrcError;
    break;
  default:
    getErrorMessage = getEquivalencyError;
  }

  return !limitExceeded
    ? errorOrderCheckValidationMessage === 'sales_restriction_weight_exceeded'
      ? {message: I18n.t('orders.purchasedAmounts.limitExceeded')}
      : false
    : limitMethod !== 'equivalency'
      ? {message: I18n.t(`retail.salesSettings.errors.${limitStringKey}`, {reset: resetDate})}
      : getErrorMessage(get(errors, 'response.data.errors.VALIDATION'));

};

export const getReceiptSettingsInitialValues = createSelector(
  [getSettings],
  (settings) => {

    if(!settings) return {};
    if(settings.order_receipts_configuration === undefined) return {};
    if(settings.order_receipts_configuration.value === undefined) return {};

    const getValue = (field, key) => {
      return (isNaN(parseInt(field[key])) && typeof field[key] !== 'boolean')
          ? field[key]
          : (typeof field[key] === 'boolean')
            ? field[key]
              ? 1
              : 0
            : parseInt(field[key]);
    };

    const receiptSettings = settings.order_receipts_configuration.value;
    const initialValues = {};
    Object.keys(receiptSettings).forEach((field) => {
      const value = receiptSettings[field];
      initialValues[field] = (typeof value !== 'string')
        ? (typeof value.value !== 'object')
          ? getValue(value, 'value')
          : getValue(value, 'default')
        : value;
    });
    return initialValues;

  });

export const includeAddressOnAllReceipts = createSelector([getReceiptSettingsInitialValues], (settings) => {
  return Boolean(settings
    && settings.include_patient_address_option_always
    && settings.include_patient_address_option_always === 1);
});

export const hasSalesRulesEnabled = createSelector([getSettings], (settings) => {
  const patientTypes = ['medical', 'recreational'];
  const limitsKey = 'order_sales_limits_by_consumer_type.value';

  return patientTypes.reduce((acc, type) => {
    if(acc) return acc;
    const limitMethod = getAt(settings, `${limitsKey}.${type}.sales_limit_method`, 'equivalency');
    if(limitMethod === 'categories') return true;
    return acc;
  }, false);
});

//looks at the overrideable field. If its not overrideable then its preset
export const salesRulesCategoriesArePreset = createSelector([getSettings], (settings) => {
  const preset = getAt(settings, `order_sales_limit_categories.overrideable`, true);
  return !preset;
});

const isMoPatientSalesLimitsEnabled = (isFeatureEnabled) => {
  const toggle = 'feature_mo_patient_sales_limits';
  return isFeatureEnabled(toggle);
};

// Patient limits only apply to medical retail facilities in certain metrc states
export const hasMetrcSalesLimits = createSelector([getMetrcSettings, getIntegrationState, isFeatureEnabled], (metrcSettings, integrationState, isFeatureEnabled, enabledForCo) => {
  const { isMoMetrc, isMiMetrc, isMdMetrc, isDcMetrc, isMtMetrc } = integrationState;
  return ((isMoMetrc && isMoPatientSalesLimitsEnabled(isFeatureEnabled)) || isMiMetrc || isMdMetrc || isDcMetrc || isMtMetrc || enabledForCo) && metrcSettings.facility_type === 'medical';
});

// MT has special laws that opt it out of certain restrictions that the other states don't
export const isResetPatientSalesLimitDisabled = createSelector([getIntegrationState, hasMetrcSalesLimits], (integrationState, hasMetrcSalesLimits) => {
  const { isMtMetrc } = integrationState;
  return hasMetrcSalesLimits && !isMtMetrc;
});

export const getOrderEnableWeighOnHand = createSelector(
  [getSettings],
  (settings) => {
    return settings.order_enable_weigh_on_hand && parseInt(settings.order_enable_weigh_on_hand.value);
  }
);

export const getOrderWeighOnHandMarginError = createSelector(
  [getSettings],
  (settings) => {
    return settings.order_weigh_on_hand_margin_error && parseFloat(settings.order_weigh_on_hand_margin_error.value);
  }
);

export default getPaymentOptions;
