import * as productionRunTypes from '../../../constants/productionRunTypes';
import {isValidInfusionOption} from '../../../selectors/assemblyJobsSelectors';
import { isValidProcessingOption } from '../../../selectors/processingSelectors';
import {isAvailable, isOnHold, isReserved} from '../../../selectors/inventoryItemsSelectors';
import {isMedicated} from '../../../selectors/itemMastersSelectors';
import {biotrackItemHasValidLabResult} from '../../../selectors/testResultsSelectors';

export const getOptionsErrorMessages = (items, runType, integrationState = {}, allowNegativeInventory = false) => {
  return items.reduce((messages, item) => {
    switch (runType) {
    case productionRunTypes.assembly:
    case productionRunTypes.infusion:
      if (!isValidInfusionOption(item, integrationState, allowNegativeInventory)) {
        const message = getOptionErrorMessage(item, runType, integrationState, allowNegativeInventory);
        messages.indexOf(message) > -1 || messages.push(message);
      }
      break;
    case productionRunTypes.processing:
      if (!isValidProcessingOption(item, integrationState, allowNegativeInventory)) {
        const message = getOptionErrorMessage(item, runType, integrationState, allowNegativeInventory);
        messages.indexOf(message) > -1 || messages.push(message);
      }
      break;
    }


    return messages;
  }, []);
};

//Run Type is not being used for now but might be useful in the future
export const getOptionErrorMessage = (item, runType, integrationState = {}, isAllowNegativeInventory = false) => {
  if (isReserved(item)) {
    return 'errorIsReservedItem';
  } else if (integrationState.isPrBiotrack && !biotrackItemHasValidLabResult(item, true) && runType != 'processing') {
    return 'errorLabResultFailed';
  } else if (integrationState.isBiotrack && !integrationState.isPrBiotrack && !biotrackItemHasValidLabResult(item, false)) {
    return 'errorNoLabResults';
  } else if (integrationState.isUtah && (typeof item.status === undefined || item.status !== 'passed')) {
    return 'errorNoLabResults';
  } else if (!isAvailable(item, isAllowNegativeInventory)) {
    return 'errorNotAvailableItem';
  } else if (isOnHold(item)) {
    return 'errorOnHoldItem';
  } else if (!isMedicated(item.itemMaster)) {
    return 'errorNonMedicated';
  } else {
    return 'errorInvalidItem';
  }
};
