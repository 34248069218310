import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import {getComputedProductQuantity} from '../../../../selectors/ordersSelectors';
import {safeNumberConversion} from '../../../../util/mathHelpers';


const RefundFooter = (props) => {

  const {integrationState: {isMetrc}, onApplyClick, isRefundAndRestock, isRefundAndRestockUnificationToggled, canRefundAlleaves} = props;

  const haveRefundOrder = () => {
    if (props.integrationState.isBiotrack) return false;
    if(Object.keys(props.refundOrder).length === 0) return false;
    if(props.refundOrder.products === undefined) return false;
    if(props.refundOrder.products.length === 0) return false;
    return true;
  };

  const getQuantity = () => {
    if(!haveRefundOrder()) return 0;
    return props.refundOrder.products.reduce( (acc, product) => {
      const quantity = getComputedProductQuantity(product);
      acc = acc + parseFloat(quantity);
      return acc;
    }, 0);
  };

  const getTotal = () => {
    if(!haveRefundOrder()) return 0;

    const {order_total, tax_total} = props.refundOrder;

    return (safeNumberConversion(order_total) - safeNumberConversion(tax_total)).toFixed(2);
  };

  return (
    <tfoot>
    {
      !haveRefundOrder()
        ? null
        : (
          <tr>
            <th>&nbsp;</th>
            <th>{getQuantity()}</th>
            <th>&nbsp;</th>
            <th>{getTotal()}</th>
          </tr>
        )
    }
    {
      isRefundAndRestockUnificationToggled || !haveRefundOrder()
        ? null
        : (
          <tr>
            <th colSpan={4}>
              <Row>
                {canRefundAlleaves &&
                  <Col md={6} style={{paddingLeft: '3px', paddingRight: '1px'}}>
                    <button className='btn btn-primary btn-block' onClick={(event) => { onApplyClick(event, 'alleaves');}}>
                      {isMetrc && isRefundAndRestock  ? I18n.t('cart.totals.completeRefundAndRestock') : I18n.t('cart.totals.completeRefund')}
                      &nbsp;{I18n.t('cart.totals.refundAlleaves')}
                    </button>
                  </Col>
                }
                <Col md={canRefundAlleaves ? 6 : 12} style={{paddingLeft: '1px', paddingRight: '3px'}}>
                  <button className='btn btn-primary btn-block' onClick={(event) => { onApplyClick(event);}}>
                    {isMetrc && isRefundAndRestock  ? I18n.t('cart.totals.completeRefundAndRestock') : I18n.t('cart.totals.completeRefund')}
                    &nbsp;{canRefundAlleaves ? I18n.t('cart.totals.refundCash') : ''}
                  </button>
                </Col>
              </Row>
            </th>
          </tr>
        )
    }
    </tfoot>
  );

};

RefundFooter.propTypes = {
  onApplyClick: PropTypes.func.isRequired,
  integrationState: PropTypes.object.isRequired,
  refundOrder: PropTypes.object,
  isRefundAndRestock: PropTypes.bool.isRequired,
  isRefundAndRestockUnificationToggled: PropTypes.bool.isRequired,
  canRefundAlleaves: PropTypes.bool.isRequired
};


export default RefundFooter;
