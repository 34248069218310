import axios from 'axios'; // Import axios
import * as types from '../constants/actionTypes';

const { get } = axios; // Extract the get method

export function healthCheckSuccess ({status}) {
  return { type: types.HEALTH_CHECK_SUCCESS, status };
}

export function healthCheckFailed (error) {
  return {type: types.HEALTH_CHECK_FAILED, message: 'error.healthCheck'};
}

export function checkEnvironment (payload) {
  return { type: types.CHECK_DEV_ENVIRONMENT, payload };
}

export function startSpinner() {
  return {type: types.START_SPINNER};
}

export function stopSpinner() {
  return {type: types.STOP_SPINNER};
}

export function clearMessage (id) {
  return {type: types.CLEAR_MESSAGE, id};
}

export function addMessage (messageType, message, localization) {
  return {type: types.ADD_MESSAGE, messageType, message, localization};
}

export function setToken (payload) {
  if(payload){
    return {type: types.SET_TOKEN, payload};
  }else{
    return {type:''};
  }
}

export function setCsrfToken (payload) {
  return {
    type: types.LOAD_CSRF_TOKEN,
    ...payload
  };
}

export function setInitialVersion(payload) {
  return {type: types.SET_INITIAL_VERSION, payload};
}

export function setCurrentVersion(payload) {
  return {type: types.SET_CURRENT_VERSION, payload};
}


export function getHealthCheck() {
  return (dispatch) => {
    dispatch(startSpinner());
    return get('/api/')
      .then(response => {
        dispatch(checkEnvironment(response.data.env));

        dispatch(healthCheckSuccess(response.data));
        dispatch(setInitialVersion(response.headers['x-mjf-version']));
      }).catch(error => {
        dispatch(healthCheckFailed(error));
      });
  };
}
