import React from 'react';
import PropTypes from 'prop-types';
import Control from 'react-currency-input-field';

import ReduxBootstrapInput from './ReduxBootstrapInput';

const CurrencyInput = ({ label, size, input, meta, children, isRequired, ...props }) => {
  return (
    <ReduxBootstrapInput Control={Control} className='form-control' thousandSeparator=''
                         label={label} size={size}
                         input={input} meta={meta}
                         name={input.name} isRequired={isRequired}
                         {...props} >
      {children}
    </ReduxBootstrapInput>
  );
};

CurrencyInput.propTypes = {
  label: PropTypes.string,
  size: PropTypes.oneOf(['lg', 'sm', 'large', 'small']),
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func
  }).isRequired,
  meta: PropTypes.object.isRequired,
  children: PropTypes.node,
  isRequired: PropTypes.bool,
};

export default CurrencyInput;
