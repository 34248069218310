import React from 'react';
import PropTypes from 'prop-types';
import {Row} from 'react-bootstrap';
import {getSelectedFacilityIds, getFacilityOptions} from '../../../../selectors/forms/productFormSelectors';
import AddItem from '../../../common/form/AddItem';


const FacilitySalesAttributesFieldArray = ({fields, facilityOptions, pushTemplate, AttributesComponent, attributesComponentProps}) => {
  const values = fields.getAll() || [];
  const selectedFacilityIds = getSelectedFacilityIds(values);
  const addDisabled = selectedFacilityIds.length >= facilityOptions.length || fields.length >= facilityOptions.length;

  return (
    <Row className='facility-level-attributes'>
      {fields.map(
        (fieldName, fieldIndex) => (
          <AttributesComponent
            key={fieldIndex}
            fieldName={fieldName}
            fieldIndex={fieldIndex}
            fields={fields}
            isOrganizationMode={false}
            facilityOptions={getFacilityOptions(facilityOptions, values, fieldIndex)}
            {...attributesComponentProps}
          />
        )
      )}

      <AddItem
        addAction={() => fields.push(pushTemplate)}
        addDisabled={addDisabled}
      />
    </Row>
  );
};

FacilitySalesAttributesFieldArray.propTypes = {
  fields: PropTypes.shape({
    getAll: PropTypes.func.isRequired,
    map: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    length: PropTypes.number.isRequired,
  }).isRequired,
  facilityOptions: PropTypes.array.isRequired,
  pushTemplate: PropTypes.object,
  AttributesComponent: PropTypes.func.isRequired,
  attributesComponentProps: PropTypes.object,
};

FacilitySalesAttributesFieldArray.defaultProps = {
  pushTemplate: {},
  attributesComponentProps: {},
};

export default FacilitySalesAttributesFieldArray;
