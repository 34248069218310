import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import {FaExclamationTriangle} from 'react-icons/fa';
import {Field} from 'redux-form';
import InternationalCurrencyInput from '../../../common/form/InternationalCurrencyInput';
import InternationalQuantityByUomInput from '../../../common/form/InternationalQuantityByUomInput';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import ProductSubTypeDisplay from './ProductSubTypeDisplay';
import ContentConcealer from '../../../common/concealers/ContentConcealer';
import {fieldFormatHelperDecimals} from '../../../../util/internationalHelpers';

const PrePackLine = ({line, lineTotal, sentTotal, internationalNumberFormat, change, fieldName, locations, packages, isApplyStorageLocation,
  itemMaster, editableLinePrice, orderedQty, isComplete, formName}) => {

  const { default_uom } = itemMaster;

  return (
  <Col xs={12}>
    <Row>
      <Col lg={4}>
        <Field name={`${fieldName}.item_master_id`} component={ReactSelectInput} props={{
          label: I18n.t('transfers.form.item'),
          options: [itemMaster],
          textKey: 'name',
          valueKey: 'id',
          disabled: true
        }}/>
        <div>
          <ProductSubTypeDisplay fieldName={fieldName} formName={formName} />
        </div>
      </Col>
      <Col lg={2}>
        <InternationalQuantityByUomInput
          name={`${fieldName}.ordered_qty`}
          readOnly={true}
          label={I18n.t('purchaseOrders.form.totalWeightOrdered')}
          uom={default_uom}
          shouldRound={false}
          leftAddon={(
            <span style={{cursor: isComplete ? 'not-allowed' : 'pointer'}} onClick={(event) => {
              if (isComplete) return;
              const input  = event.target.parentNode.parentNode.getElementsByTagName('input');
              const newState = !input[0].readOnly;
              input[0].readOnly = newState;
              event.target.innerHTML = (!newState) ? 'Lock' : 'Edit';
            }}>
              Edit
            </span>
          )}
        />
      </Col>


      {
        !editableLinePrice
        ? (
          <Col lg={2} >
            <InternationalQuantityByUomInput
              name={`${fieldName}.sent_total`}
              readOnly={true}
              label={I18n.t('purchaseOrders.form.sentTotal')}
              value={fieldFormatHelperDecimals(sentTotal, '', internationalNumberFormat)}
              uom={default_uom}
            />
          </Col>
        )
          : (
          <Col lg={2} >
            <InternationalQuantityByUomInput
              name={`${fieldName}.sent_total`}
              readOnly={true}
              label={I18n.t('purchaseOrders.form.sentTotal')}
              value={fieldFormatHelperDecimals(sentTotal, '', internationalNumberFormat)}
              uom={default_uom}
             />
          </Col>
        )
      }
      <ContentConcealer show={!line.is_lab_partner}>
        {
          !editableLinePrice
          ? null
            : (
            <Col lg={2} >
              <InternationalCurrencyInput
                name={`${fieldName}.unit_price`}
                props={{
                  label: I18n.t('purchaseOrders.form.approxPricePerGram'),
                  readOnly: true,
                  rightAddon: <span>{default_uom}</span>
                }} />
            </Col>
          )
        }
        {
          !editableLinePrice
          ? (
            <Col lg={2} offset={2}>
              <InternationalCurrencyInput
                name={`${fieldName}.line_item_price`}
                props={{
                  label: I18n.t('purchaseOrders.form.itemPrice'),
                  readOnly: true,
                  rightAddon: (
                    <span style={{cursor: isComplete ? 'not-allowed' : 'pointer'}} onClick={(event) => {
                      if (isComplete) return;
                      const input  = event.target.parentNode.parentNode.getElementsByTagName('input');
                      input[0].focus();  setTimeout(() => input[0].blur(), 10);
                      change(`${fieldName}.editableLinePrice`, true);
                    }}>
                      Edit
                    </span>
                  ),
                  value: lineTotal
                }} />
            </Col>
          )
            : (
            <Col lg={2}>
              <InternationalCurrencyInput
                name={`${fieldName}.line_item_price`}
                props={{
                  label: I18n.t('purchaseOrders.form.itemPrice'),
                  rightAddon: (
                    <span style={{cursor: isComplete ? 'not-allowed' : 'pointer'}} onClick={(event) => {
                      if (isComplete) return;
                      const input  = event.target.parentNode.parentNode.getElementsByTagName('input');
                      input[0].focus();  setTimeout(() => input[0].blur(), 10);
                      change(`${fieldName}.editableLinePrice`, false);
                    }}>
                      Calc
                    </span>
                  ),
                }} />
            </Col>
          )
        }
      </ContentConcealer>
    </Row>
    <Row>
      <Col lg={4}>&nbsp;</Col>
      <Col lg={8} style={{margin: '0px 0px 12px 0px'}} className={orderedQty !== sentTotal ? 'text-danger' : 'hide'}>
        <FaExclamationTriangle style={{marginRight: '8px'}}/>
        {I18n.t('purchaseOrders.form.totalWeightOrdered')} is not equal to {I18n.t('purchaseOrders.form.sentTotal')}.
        Edit <strong>{I18n.t('purchaseOrders.form.totalWeightOrdered')}</strong> directly to update the value on your Sales Order.
      </Col>
    </Row>
  </Col>);
};

PrePackLine.propTypes = {
  orderedQty: PropTypes.number,
  editableLinePrice: PropTypes.bool,
  change: PropTypes.func.isRequired,
  line: PropTypes.object.isRequired,
  sentTotal: PropTypes.number.isRequired,
  lineTotal: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  itemMaster: PropTypes.object.isRequired,
  packages: PropTypes.array,
  isApplyStorageLocation: PropTypes.bool,
  locations: PropTypes.array,
  isComplete: PropTypes.bool,
  formName: PropTypes.string.isRequired,
  isMedicated: PropTypes.bool,
  formData: PropTypes.object.isRequired,
  internationalNumberFormat: PropTypes.string
};

export default PrePackLine;
