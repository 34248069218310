import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';

import CustomerPanel from './CustomerPanel';

const CustomerList = ({consumers, ready, customerStats, customerRecentHistories,
  timezone, onSelectCustomer, customerDetails, budTenders, selectedCustomers, countryCode}) => {
  return (consumers.length ? <Row>
      <Col xs={12}>
        {consumers.map(consumer =>
          <CustomerPanel
            key={consumer.id}
            budTenders = {budTenders}
            consumer={consumer}
            ready={ready}
            timezone={timezone}
            onSelectCustomer={onSelectCustomer}
            details={customerDetails[consumer.id]}
            selectedCustomers={selectedCustomers}
            history={customerRecentHistories ? customerRecentHistories.find(crh => consumer.id === crh.id) || {} : {}}
            stats={customerStats ? customerStats.find(cs => consumer.id === cs.id) || {} : {}}
            countryCode={countryCode}
          />
        )}
      </Col>
    </Row> : null);
};

CustomerList.propTypes = {
  consumers: PropTypes.array.isRequired,
  ready: PropTypes.bool.isRequired,
  customerRecentHistories: PropTypes.array,
  customerStats: PropTypes.array,
  timezone: PropTypes.string.isRequired,
  onSelectCustomer: PropTypes.func.isRequired,
  customerDetails: PropTypes.object,
  budTenders: PropTypes.array,
  selectedCustomers: PropTypes.array.isRequired,
  countryCode: PropTypes.string.isRequired,
};

export default CustomerList;
