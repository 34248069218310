import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Table} from 'react-bootstrap';

import PaymentItem from './PaymentItem'; // eslint-disable-line import/no-named-as-default

const PaymentItems = ({items, pricingWeights, order, prepackWeights, printLabel, paymentComplete,
                        getOrderProductCoupons, couponIsAppliedToAllProducts, printBeforePayment}) => {

  return (
    <div className='payment-items'>
      {(items && items.length) ? (<Table>
        <thead className='items-table-header'>
          <tr>
            <th>{I18n.t('cart.items.image')}</th>
            <th>{I18n.t('cart.items.itemDescription')}</th>
            <th>{I18n.t('cart.items.itemId')}</th>
            <th>{I18n.t('cart.items.pricingWeight')}</th>
            <th>{I18n.t('cart.items.quantity')}</th>
            <th>{I18n.t('cart.items.lineTotal')}</th>
            <th>{I18n.t('cart.items.labels')}</th>
          </tr>
        </thead>

          {items.map((item, index) => {
            const currentOrder = order ? order.products.filter(products => products.item_master_id === item.item_master_id) : [];
            const productCoupons = getOrderProductCoupons(item)
              .filter(coupon => !couponIsAppliedToAllProducts(coupon));

            return (
              <PaymentItem
                paymentComplete={paymentComplete}
                key={index}
                pricingWeight={pricingWeights.find(pw => pw.id === item.pricing_weight_id) || {name:'EA'}}
                description={item.name}
                id={item.item_master_id} unit_price={parseFloat(item.unit_price)}
                orderProductId={item.id}
                item={item}
                prepackWeights={prepackWeights}
                order={currentOrder}
                packageId={(item.items && item.items.length) ? item.items[0].package_code : ''}
                quantity={item.quantity}
                lineTotal={parseFloat(item.unit_price) * item.quantity}
                printLabel={printLabel}
                coupons={productCoupons}
                printBeforePayment={printBeforePayment}
                src={item.src} />
            );
          })}
      </Table>) : null}
    </div>
  );
};

PaymentItems.propTypes = {
  paymentComplete: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  order: PropTypes.object.isRequired,
  prepackWeights: PropTypes.array.isRequired,
  pricingWeights: PropTypes.array.isRequired,
  printLabel:PropTypes.func,
  getOrderProductCoupons: PropTypes.func.isRequired,
  couponIsAppliedToAllProducts: PropTypes.func.isRequired,
  printBeforePayment: PropTypes.bool.isRequired,
};

export default PaymentItems;
