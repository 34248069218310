import React from 'react';
import PropTypes from 'prop-types';
import {Field,} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import {FaDollarSign} from 'react-icons/fa';
import FieldArrayIncrement from '../../common/form/FieldArrayIncrement';
import CurrencyInput from '../../common/form/CurrencyInput';
import TextInput from '../../common/form/TextInput';
import NumericInput  from '../../common/form/NumericInput';

const MultiPackFieldArray = ({fields}) => (
  <div className='multipack-info-fields'>
    {fields.map(itemName => (
      <Row key={itemName}>
        <Col md={3} sm={6}>
          <Field name={`${itemName}.name`} component={TextInput} props={{
            label: I18n.t('products.form.multipackName'),
            placeholder: I18n.t('common.form.namePlaceholder')
          }}/>
        </Col>
        <Col md={3} sm={6}>
          <Field name={`${itemName}.fixed_qty`} component={NumericInput} props={{
            label: I18n.t('products.form.unitCount'),
          }}/>
        </Col>
        <Col md={3} sm={6}>
          <Field name={`${itemName}.minimum_reorder_qty`} component={NumericInput} props={{
            label: I18n.t('products.form.requiredReorderQuantity'),
          }}/>
        </Col>
        <Col md={3} sm={6}>
          <Field name={`${itemName}.default_cost`} component={CurrencyInput} props={{
            label: I18n.t('products.form.cost'),
            leftAddon: <FaDollarSign/>
          }}/>
        </Col>
      </Row>
    ))}
    <Row>
      <Col xs={12}>
        <FieldArrayIncrement fields={fields} showDecrement={true} label={'products.form.addMultiPackInfo'} className='float-right'/>
      </Col>
    </Row>
  </div>
);

MultiPackFieldArray.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    length: PropTypes.number.isRequired
  })
};

export default MultiPackFieldArray;
