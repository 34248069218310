import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Row, Form} from 'react-bootstrap';
import {Field} from 'redux-form';

import AddItem from '../../../common/form/AddItem';
import NumericInput from '../../../common/form/NumericInput';
import ReactSelectInput from '../../../common/form/ReactSelectInput';

const categoryOptions = [
  {text: 'Category 1', value: '1'},
  {text: 'Category 2', value: '2'}
];

const uomOptions = [
  {text: 'EA', value: 'EA'},
  {text: 'G', value: 'G'}
];

const CategoriesFieldArray = ({fields: {map, push, remove, length}}) => (
  <div className='layout-row'>
    <Form.Label className='primary-label'>{I18n.t('cultivation.alertSettings.onHandInventory')}</Form.Label>
    <div className='categories-container'>
      {map((categoryName, categoryIndex) => (
        <Row key={categoryName}>
          <Col xs={3}>
            <Field name={`${categoryName}.id`} component={ReactSelectInput} props={{
              label: I18n.t('cultivation.alertSettings.category'),
              options: categoryOptions
            }}/>
          </Col>
          <Col xs={2}>
            <Field name={`${categoryName}.max`} component={NumericInput} props={{
              label: I18n.t('cultivation.alertSettings.maximum')
            }}/>
          </Col>
          <Col xs={2}>
            <Field name={`${categoryName}.maxUOM`} component={ReactSelectInput} props={{
              label: I18n.t('cultivation.alertSettings.uom'),
              options: uomOptions
            }}/>
          </Col>
          <Col xs={2}>
            <Field name={`${categoryName}.min`} component={NumericInput} props={{
              label: I18n.t('cultivation.alertSettings.minimum')
            }}/>
          </Col>
          <Col xs={2}>
            <Field name={`${categoryName}.maxUOM`} component={ReactSelectInput} props={{
              label: I18n.t('cultivation.alertSettings.uom'),
              options: uomOptions
            }}/>
          </Col>
        </Row>
      ))}
      <Row>
        <AddItem
          text='cultivation.alertSettings.addCategory'
          addAction={() => push({})}
          alignMode='left'
          length={length}
          removeAction={() => remove(length - 1)}/>
      </Row>

    </div>
  </div>
);

CategoriesFieldArray.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    length: PropTypes.number.isRequired
  }).isRequired
};

export default CategoriesFieldArray;
