import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import {FaExclamationTriangle} from 'react-icons/fa';

const TestingSection = (props) => {
  const {itemMaster, testResult, testResultDimensions, isCreateMode} = props;

  /******
   * Item master test results
   ******/
  const itemMasterPotencyResultsJson = get(itemMaster, 'potency_test_results', '{}');
  const itemMasterPotencyResults = itemMasterPotencyResultsJson
    ? Object.values(JSON.parse(itemMasterPotencyResultsJson)).filter((result) => result.name && result.value)  // Filter out empties
    : [];

  const itemMasterTerpeneResultsJson = get(itemMaster, 'terpene_test_results', '{}');
  const itemMasterTerpeneResults = itemMasterTerpeneResultsJson
    ? Object.values(JSON.parse(itemMasterTerpeneResultsJson)).filter((result) => result.name && result.value)  // Filter out empties
    : [];

  const renderItemMasterTestResults = (itemMasterTestResults, i18nGroup) => {
    return itemMasterTestResults.map((itemMasterTestResult, index) => (
      <Row key={index}>
        <Col md={6}>{I18n.t('cultivation.testingCompliance.' + i18nGroup + '.' + itemMasterTestResult.name)}</Col>
        <Col md={6} style={{textAlign: 'left'}}>{itemMasterTestResult.value} {I18n.t('uoms.' + itemMasterTestResult.uom.toUpperCase() + '.abbrev')}</Col>
      </Row>));
  };

  /******
   * Package test results
   ******/
  const testResultPotencyDimensions = get(testResultDimensions, 'cannabinoid_potency', []);
  const testResultsTerpenePotencyDimensions = get(testResultDimensions, 'terpene_profile', []);

  const packagePotencyResults = testResultPotencyDimensions.map((testResultDimension) => {
    // Find dimension value
    const value_mg_g = get(testResult, testResultDimension.key + '_mg_g');
    const value_percent = get(testResult, testResultDimension.key + '_percent');
    return {key: testResultDimension.name, value_mg_g, value_percent};
  }).filter((testResultDimension) => testResultDimension.value_mg_g && testResultDimension.value_percent); // Filter out empties

  const packageTerpeneResults = testResultsTerpenePotencyDimensions.map((testResultDimension) => {
    // Find dimension value
    const value_mg_g = get(testResult, testResultDimension.key + '_mg_g');
    const value_percent = get(testResult, testResultDimension.key + '_percent');
    return {key: testResultDimension.name, value_mg_g, value_percent};
  }).filter((testResultDimension) => testResultDimension.value_mg_g && testResultDimension.value_percent); // Filter out empties

  const renderPackageTestResults = (packageTestResults) => {
    return packageTestResults.map((packageTestResult, index) => (
      <Row key={index}>
        <Col md={6}>{I18n.t(packageTestResult.key)}</Col>
        <Col md={6} style={{textAlign: 'left'}}>{packageTestResult.value_percent} {I18n.t('uoms.PERCENTAGE.abbrev')}</Col>
      </Row>));
  };

  /******
   * Test results
   ******/
  return (
    <div style={{backgroundColor: 'whitesmoke', border: '1px solid lightgrey'}}>
      <Row>
        <Col md={{span: 6, offset: 6}} style={{textAlign: 'right', color: 'grey'}}>
          <FaExclamationTriangle/> &nbsp;
          {get(itemMaster, 'test_results_source') === 'item_master'
            ? I18n.t('inventoryReceipt.form.testingSection.itemMasterTestResults')
            : I18n.t('inventoryReceipt.form.testingSection.packageTestResults')}
        </Col>
      </Row>

      {/* Package Test Results show only for NEW receipts and if test_results_source is set to item_master */}
      {!isCreateMode &&
        <Row>
          <Col md={6}>
            <strong>{I18n.t('products.form.potencyInfo')}</strong><br/>
            {packagePotencyResults.length
              ? renderPackageTestResults(packagePotencyResults)
              : I18n.t('inventoryReceipt.form.testingSection.noPotencyTestResults')}
          </Col>
          <Col md={6}>
            <strong>{I18n.t('products.form.terpeneInfo')}</strong><br/>
            {packageTerpeneResults.length
              ? renderPackageTestResults(packageTerpeneResults)
              : I18n.t('inventoryReceipt.form.testingSection.noTerpeneTestResults')}
          </Col>
        </Row>
      }

      {/* Item Master Test Results show only for NEW receipts and if test_results_source is set to item_master */}
      {isCreateMode && get(itemMaster, 'test_results_source') === 'item_master' &&
        <Row>
          <Col md={6}>
            <strong>{I18n.t('products.form.potencyInfo')}</strong><br/>
            {itemMasterPotencyResults.length
              ? renderItemMasterTestResults(itemMasterPotencyResults, 'cannabinoidPotency')
              : I18n.t('inventoryReceipt.form.testingSection.noPotencyTestResults')}
          </Col>
          <Col md={6}>
            <strong>{I18n.t('products.form.terpeneInfo')}</strong><br/>
            {itemMasterTerpeneResults.length
              ? renderItemMasterTestResults(itemMasterTerpeneResults, 'terpeneProfile')
              : I18n.t('inventoryReceipt.form.testingSection.noTerpeneTestResults')}
          </Col>
        </Row>
      }
    </div>
  );
};

TestingSection.propTypes = {
  itemMaster: PropTypes.object.isRequired,
  testResult: PropTypes.array,
  testResultDimensions: PropTypes.object.isRequired,
  isCreateMode: PropTypes.bool.isRequired
};

export default TestingSection;
