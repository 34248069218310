import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Table, Alert} from 'react-bootstrap';
import {FaExclamationTriangle, FaPrint} from 'react-icons/fa';
import {Field} from 'redux-form';
import NumericInput from '../../common/form/NumericInput';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import WillRender from '../../common/concealers/WillRender';
import InlineCheckBox from '../../common/form/InlineCheckBox';
import CheckBoxInput from '../../common/form/CheckBoxInput';
import {getWaInventoryTypesWithName} from '../../../selectors/integrationSelectors';
import {getQuantities} from '../../../selectors/pricingWeightsSelectors';
import {getInputUom, getOutputUom} from '../../../selectors/forms/completePackageFormSelectors';
import {warning} from '../../../constants/messageTypes';
import {addMessage} from '../../../actions/systemActions';
import QuantityByUomInput from '../../common/form/QuantityByUomInput';
import {GR} from '../../../constants/uoms';

class PackagingQuantities extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
  }

  componentDidUpdate(prevProps) {
    const {quantities, selectedItemMaster, addMessage} = this.props;

    // Warn user if no quantities (package sizes) exist for the selected item
    if (selectedItemMaster && quantities !== prevProps.quantities && !quantities.length) {
      const msg = I18n.t(
        'packaging.getPrepackWeights.missingPackageSizesForUom',
        {uom: I18n.t(`uoms.${selectedItemMaster.default_uom}.abbrev`)}
      );

      addMessage(warning, msg, true);
    }
  }

  render() {
    const {
      fields: {map, get}, meta, inventoryTypes, integrationState,
      waPackagingInventoryTypes, inputUom, outputUom
    } = this.props;
    const {isBiotrack, isCanada, isWaLeaf, isMiMetrc} = integrationState;
    const uomDisplayWord = I18n.t(`uoms.${outputUom || GR}.wordPlural`);
    const displayInputUomValues = inputUom && outputUom && outputUom !== inputUom;

    return (
      <div className='packaging-quantities'>
        <h3>{I18n.t('packages.prePack.packagingQuantity')}</h3>
        <Table className='pre-pack-table'>
          <thead>
          <tr>
            <th className='width10'>{uomDisplayWord}</th>
            <th className='width10'>{I18n.t('packages.prePack.quantity')}</th>
            <th className='width20'>{I18n.t('packages.prePack.childPackageId')}</th>
            <WillRender ifTrue={isCanada}>
              <th>&nbsp;</th>
            </WillRender>
            {(isBiotrack || isWaLeaf) && <th className='width20'>*{I18n.t('packages.form.inventoryType')}</th>}
            {(isWaLeaf) && <th className='width30'>{I18n.t('packaging.form.leafWa.seekingMedicalDesignation')}</th>}
          </tr>
          </thead>
          <tbody>
          {map((quantity, index) => {
            const row = get(index);
            const waInventoryTypes = waPackagingInventoryTypes ? waPackagingInventoryTypes.filter(type => row.item_master_id === type.item_master_id) : [];
            const waInventoryTypesOptions = getWaInventoryTypesWithName(waInventoryTypes);
            const isParentMed = row.medically_compliant_status === 'yes';
            return (<tr key={index} className='packaging-quantity-row'>
                <td className='pre-pack-grams'>{row.name}</td>
                <td className='pre-pack-quantity'>
                  <Field
                    name={`${quantity}.qty`}
                    component={NumericInput}
                    props={{
                      min: 0,
                      placeholder: ''
                    }}
                    normalize={value => parseInt(value)}
                  />

                </td>
                <td>
                  <Field
                    name={`${quantity}.package_code`} disabled
                    props={{
                      className: 'unit-id'
                    }}
                    component='input'/>
                </td>
                {isBiotrack ?
                  <td>
                    <Field
                      name={`${quantity}.integration_type`}
                      component={ReactSelectInput}
                      props={{
                        options: inventoryTypes,
                        placeholder: I18n.t('common.form.selectPlaceholder'),
                      }}
                    />
                  </td> : null}

                {isWaLeaf && (
                  <td>
                    <Field
                      name={`${quantity}.integration_type`}
                      component={ReactSelectInput}
                      props={{
                        options: waInventoryTypesOptions,
                        placeholder: I18n.t('common.form.selectPlaceholder'),
                      }}
                    />
                  </td>
                )}

                {isWaLeaf && (
                  <td className='no-margin-on-checkbox'>
                    <Field
                      name={`${quantity}.medically_compliant`}
                      component={CheckBoxInput}
                      props={{
                        groupClassName: 'inline-checkbox',
                        label: I18n.t('packaging.form.leafWa.seekingMedicalDesignation'),
                        disabled: !isParentMed
                      }}
                    />
                    {(isParentMed && !get(index).medically_compliant === 1) && (
                      <Alert variant='info'>
                    <span>
                      {I18n.t('packaging.form.leafWa.medicallyCompliantNote')}
                    </span>
                      </Alert>

                    )}
                  </td>
                )}

                <WillRender ifTrue={isCanada}>
                  <td className='no-margin-on-checkbox'>
                    <Field name={`${quantity}.finished`} component={InlineCheckBox} props={{
                      label: I18n.t('common.form.finishedInventoryCheckbox'),
                    }}/>
                  </td>
                </WillRender>
              </tr>
            );
          })}
          <tr style={{display: 'none'}}>
            <td className='pre-pack-grams'>
              {I18n.t('packages.prePack.shake')}
            </td>
            <td className='pre-pack-quantity'>
              <Field
                name={`shake`}
                props={{
                  className: 'form-control',
                  min: 0,
                  placeholder: ''
                }}
                component={NumericInput}/>
            </td>
            <td>
              <Field
                name={`shakePackageCode`} disabled
                props={{
                  className: 'unit-id'
                }}
                component='input'/>
            </td>
            <td><FaPrint/> {I18n.t('packages.prePack.print')}</td>
          </tr>
          </tbody>
        </Table>

        <WillRender ifTrue={outputUom}>
          <Table className='pre-pack-table'>
            <thead>
              <tr>
                <th className='width20 text-right'>
                  <WillRender ifTrue={displayInputUomValues}>{I18n.t('packages.prePack.totalsHeader')}:</WillRender>
                </th>
                <th className='width10'>
                  <WillRender ifTrue={displayInputUomValues}>{uomDisplayWord}</WillRender>
                </th>
                <th className='width10'>
                  <WillRender ifTrue={displayInputUomValues}>{I18n.t(`uoms.${inputUom}.wordPlural`)}</WillRender>
                </th>
                <th className='width20'></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  className='text-right'>
                  <strong> {I18n.t('packages.prePack.totalPacked')}
                    :</strong>
                </td>
                <td className='text-center'>
                  <QuantityByUomInput
                    name='totalPackedOutput'
                    disabled={true}
                    uom={outputUom}
                  />
                </td>
                <td>
                  <WillRender ifTrue={displayInputUomValues}>
                    <QuantityByUomInput
                      name='totalPackedInput'
                      disabled={true}
                      uom={inputUom}
                    />
                  </WillRender>
                </td>
              </tr>
              <tr className='pre-pack-waste-row'>
                <td className='text-right'>
                  <strong>{I18n.t('packages.prePack.wasteReported')}:</strong>
                </td>
                <td className='text-center'>
                  <QuantityByUomInput
                    name='wasteReportedOutput'
                    allowNegativeNumber={false}
                    uom={outputUom}
                  />
                </td>
                <td className='text-center'>
                  <WillRender ifTrue={displayInputUomValues}>
                    <QuantityByUomInput
                      name='wasteReportedInput'
                      disabled={true}
                      uom={inputUom}
                    />
                  </WillRender>
                </td>
              </tr>
              {!isMiMetrc ?
                            <tr className='pre-pack-waste-row'>
                            <td className='text-right'>
                              <strong>{I18n.t('packages.prePack.packagingLoss')}:</strong>
                            </td>
                            <td className='text-center'>
                              <QuantityByUomInput
                                name='packagingLossOutput'
                                allowNegativeNumber={false}
                                uom={outputUom}
                              />
                            </td>
                            <td className='text-center'>
                              <WillRender ifTrue={displayInputUomValues}>
                                <QuantityByUomInput
                                  name='packagingLossInput'
                                  disabled={true}
                                  uom={inputUom}
                                />
                              </WillRender>
                            </td>
                          </tr>
              : null}

              <tr className='pre-pack-waste-row'>
                <td className='text-right'>
                  <strong>{I18n.t('packages.prePack.unpackedRemainder')}:</strong>
                </td>
                <td className='text-center'>
                  {(meta && meta.error) ?
                    <Alert variant='danger'>
                      <FaExclamationTriangle/>
                      <span className='padding-left'>{I18n.t('packaging.form.unpackedRemainderError')}</span>
                    </Alert> : null}
                  <QuantityByUomInput
                    name='unpackedRemainderOutput'
                    disabled={true}
                    uom={outputUom}
                  />
                </td>
                <td className='text-center'>
                  <WillRender ifTrue={displayInputUomValues}>
                    <QuantityByUomInput
                      name='unpackedRemainderInput'
                      disabled={true}
                      uom={inputUom}
                    />
                  </WillRender>
                </td>
              </tr>
            </tbody>
          </Table>
        </WillRender>
      </div>
    );
  }
}

PackagingQuantities.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired
  }),
  selectedItemMaster: PropTypes.object,
  quantities: PropTypes.array.isRequired,
  shakePackageCode: PropTypes.string,
  shakeIsChanged: PropTypes.bool,
  generatePackageId: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  meta: PropTypes.object.isRequired,
  inventoryTypes: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired
};

PackagingQuantities.defaultProps = {
  shakePackageCode: '',
  shakeIsChanged: false
};

const mapStateToProps = state => ({
  quantities: getQuantities(state),
  inputUom: getInputUom(state),
  outputUom: getOutputUom(state)
});

const mapDispatchToProps = {
  addMessage
};

const ConnectedPackagingQuantities = connect(mapStateToProps, mapDispatchToProps)(PackagingQuantities);
export default ConnectedPackagingQuantities;
export { PackagingQuantities };
