/* eslint-disable import/prefer-default-export */
import {removeSelectedData, addSelectedData, setSelectedData, clearSelectedData} from '../../actions/selectedDataActions';

export const handleSelectedRow = (data, dataName, flag) => {
  return (dispatch) => {
    if (flag) {
      dispatch(addSelectedData(data, dataName));
    } else {
      dispatch(removeSelectedData(data, dataName));
    }
  };
};

export const handleSingleSelectedRow = (data, dataName, flag) => {
  return (dispatch) => {
    if (flag) {
      dispatch(setSelectedData(data, dataName));
    } else {
      // this is feature for 'radio' buttons: Uncheck after secondary click.
      dispatch(removeSelectedData(data, dataName));
    }
  };
};

export const handleComplexSelectRow = (data, dataName, action) => {
  return (dispatch) => {
    switch (action) {
    case 'add':
      dispatch(addSelectedData(data, dataName));
      break;
    case 'remove':
      dispatch(removeSelectedData(data, dataName));
      break;
    case 'set':
      dispatch(setSelectedData(data, dataName));
      break;
    case 'clear':
      dispatch(clearSelectedData(dataName));
      break;
    default:
      break;
    }
  };
};
