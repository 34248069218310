import {createSelector} from 'reselect';
import { formValueSelector } from 'redux-form';
import get from 'lodash.get';

import {getItemMasters} from '../itemMastersSelectors';
import {getBiotrackInvTypesWithTitles} from '../integration/biotrackSelectors';

import * as itemNames from '../../constants/itemNames';
import {COMPLETE_PACKAGE_FORM} from '../../constants/forms';
import {getActiveFacility} from '../facilitiesSelectors';
import {getPackagingOutputRules} from '../integration/biotrackCategoriesSelectors';
import {getStrain} from '../strainsSelectors';
import {getInventoryItem, getItemProducerName} from '../inventoryItemsSelectors';
import {getPartners} from '../partnersSelectors';
import {convertFromBase} from '../../util/uomHelpers';
import {convertDbDateTimeToFormInputDateTime, formatInputDate} from '../../util/dateHelpers';
import {getQuantities} from '../pricingWeightsSelectors';
import {hasPackagesTags} from '../../selectors/integrationSelectors';
import {getTimezone} from '../timezoneSelectors';

const packageFormSelector = formValueSelector(COMPLETE_PACKAGE_FORM);
const getSelectedItemMasterId = (state) => packageFormSelector(state, 'item_master_parent_id');


const getJobId = (_, props) => props && props.params && Number(props.params.id);
const getPackagingJob = state => state[itemNames.packagingJob];
const getWeight = (_, props) => props.weight;
const getPackageCode = (_, props) => props.packageCode;
const getJob = createSelector(
  [getPackagingJob, getJobId],
  (job, jobId) => job.id === jobId ? job : null
);
const getJobStrain = createSelector(
  [getJob, getStrain],
  (job, strain) => job && job.item && job.item.item_master && job.item.item_master.strain_id === strain.id ? strain : null
);
const getJobItem = createSelector(
  [getJob, getInventoryItem, getJobStrain, getActiveFacility, getPartners],
  (job, item, strain, activeFacility, partners) => {
    if (!job || !job.item || job.item.id !== item.id) {
      return null;
    }
    return {
      ...item,
      strain_name: strain && strain.strain_name,
      producer_name: getItemProducerName(item, activeFacility, partners),
      qty_available: (item.reservations || []).reduce(
        (qty, reservation) => {
          return qty - convertFromBase(reservation.qty_base, item.uom_display);
        },
        item.qty || 0
      ),
    };
  }
);

const getJobItemIntegrationType = createSelector([getJobItem], item => item && item.integration_type);

const getBiotrackInventoryTypes = createSelector(
  [getJobItemIntegrationType, getPackagingOutputRules],
  (btInventoryType, btPackagingRules) => btPackagingRules[btInventoryType] || []
);

export const getJobItemMasterOptions = createSelector([getItemMasters], (itemMasters) => itemMasters);

export const getFilteredBiotrackInvTypes = createSelector(
  [getBiotrackInvTypesWithTitles, getBiotrackInventoryTypes],
  (invTypes, applicableInvTypes) => invTypes.filter(type => applicableInvTypes.indexOf(type.value) !== -1)
);

export const getInitialValues = createSelector(getQuantities, getPackagingJob, getWeight, getPackageCode, hasPackagesTags, getTimezone,(quantities, packagingJob, weight, packageCode, hasPackagesTags, timezone) => {
  const package_tags = hasPackagesTags === true ? 1 : 0;
  const estimatedCompletionDate = packagingJob && packagingJob.estimated_completion_at
    ? convertDbDateTimeToFormInputDateTime(packagingJob.estimated_completion_at, timezone)
    : '';

  return {
    //item_master_parent_id - value is injected in render
    weight: weight,
    package_code: packageCode,
    packaging_location_id: '',
    estimated_completion_at: estimatedCompletionDate,
    completed_at: estimatedCompletionDate,
    package_created_at: formatInputDate(estimatedCompletionDate),
    create_container: false,
    container_code: '',
    quantities: quantities.map((item) => Object.assign({}, item, {finished: 1})),
    shake: '',
    shakePackageCode: '',
    shakeIsChanged: false,
    tag_requested: package_tags,
    // TODO: #27039 - these default values of '0.00' are ugly. Necessary with QuantityByUomInput usage?
    wasteReportedInput: '0.00',
    packagingLossInput: '0.00',
    unpackedRemainderInput: 0,
    totalPackedInput: 0,
    wasteReportedOutput: '0.00',
    packagingLossOutput: '0.00',
    unpackedRemainderOutput: 0,
    totalPackedOutput: 0,
  };
});

export const getSelectedItemMaster = createSelector(getItemMasters, getSelectedItemMasterId, (itemMasters, selectedItemMasterId) => {
  return itemMasters.find(itemMaster => itemMaster.id === selectedItemMasterId);
});

export const getInputUom = createSelector(
  [getPackagingJob], (packagingJob) => get(packagingJob, 'item.item_master.default_uom'));

export const getOutputUom = createSelector(
  [getSelectedItemMaster], (itemMaster) => get(itemMaster, 'default_uom'));

export default getJobItemMasterOptions;
