import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import {Card, Col, Row} from 'react-bootstrap';
import ReactSelectInput from '../../../common/form/ReactSelectInput';

const SettingsPanel = (props) => {

  const titles = {
    behavior: 'Behaviors',
    statement: 'Statements',
    names: 'Names, Addresses, and Licenses',
    data: 'Label Data Fields'
  };

  const options = {
    yesNo: [
      {text: 'cultivation.complianceSettings.yes', value: 1},
      {text: 'cultivation.complianceSettings.no', value: 0}
    ].map(yesNo => {
      yesNo.text = I18n.t(yesNo.text);
      return yesNo;
    }),
    fontSize: [
      {text: 'cultivation.complianceSettings.fontSizeUnit', value: 8},
      {text: 'cultivation.complianceSettings.fontSizeUnit', value: 9},
      {text: 'cultivation.complianceSettings.fontSizeUnit', value: 10},
      {text: 'cultivation.complianceSettings.fontSizeUnit', value: 11},
      {text: 'cultivation.complianceSettings.fontSizeUnit', value: 12}
    ].map(fontSize => {
      fontSize.text = I18n.t(fontSize.text, {size: fontSize.value});
      return fontSize;
    })
  };

  const properCase = (string) => {
    return string.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  return (
    <Card style={{width: '30rem'}}>
      <Card.Header>
        {titles[props.title]}
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={7}><strong>Setting</strong></Col>
          <Col md={5}><strong>Required</strong></Col>
        </Row>
          {props.settings.map((setting, index) => {
            return (
              <Row key={index}>
                <Col md={7} style={{verticalAlign: 'middle'}}>{properCase(I18n.t(setting.text))}</Col>
                <Col md={5}>
                  <Field
                    key={index}
                    style={{width: '100%'}}
                    name={setting.name}
                    clearable={false}
                    component={ReactSelectInput}
                    props={{
                      options: options[setting.optionsString]
                    }}
                  />
                </Col>
              </Row>
            );
          })}
      </Card.Body>
    </Card>
  );

};

SettingsPanel.propTypes = {
  title: PropTypes.string,
  settings: PropTypes.array,
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  valid: PropTypes.bool,
  touchedFormFields: PropTypes.object.isRequired,
};

export default SettingsPanel;
