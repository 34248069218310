import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack, push} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import labelsConfig from '../common/labels.configuration';
import {getData, postItem, getItem} from '../../../actions/apiActions';
import {setData} from '../../../actions/dataActions';
import {setItem} from '../../../actions/itemActions';
import {labelsConfiguration, customLabels} from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import {getDefaultLabelType} from '../../../selectors/labelsSelectors';
import InProgressOverlay from '../../common/InProgressOverlay';
import InternationalDecimalStatic from '../../common/form/InternationalDecimalStatic';

class LabelsListPage extends React.PureComponent{

  constructor(props, context) {

    super(props, context);
    this.onLabelSelect = this.onLabelSelect.bind(this);
    this.hideLoader = this.hideLoader.bind(this);
    this.onToggleLabelVersion = this.onToggleLabelVersion.bind(this);
    this.loadCompliance = this.loadCompliance.bind(this);
    this.state = {
      showLoader: false,
      message: ''
    };
  }

  componentWillMount(){
    this.setState({showLoader: true});
    this.props.actions.setData(labelsConfig, labelsConfiguration);
    this.props.actions.getData('/api/labels/custom_labels', customLabels, {}, {include_configurable: 1}, this.hideLoader);
    this.loadCompliance();
  }

  loadCompliance(){
    this.props.actions.getItem('/api/labels/compliance_settings', itemNames.labelsCompliance);
  }

  onLabelSelect(label){
    const {labelTag} = itemNames;
    this.props.actions.setItem(label, labelTag);
    this.props.actions.push(`/labels/editor/${label.compliance_key}`);
  }

  hideLoader(){
    this.setState({showLoader: false});
  }

  onToggleLabelVersion(){
    const payload = {
      settings: [
        {
          setting_key: 'label_global_settings',
          scope: 'facility',
          target: 0,
          value: {
            default_label_type: (this.props.defaultLabelType === 'image') ? 'zpl' : 'image',
          }
        }
      ]
    };
    this.setState({showLoader: true, message: 'Changing Current Label Version'});
    this.props.actions.postItem('/api/labels/compliance_settings', payload, null, {}, {}, () => {
      this.hideLoader();
      this.loadCompliance();
    });

  }

  render(){

    return (
      <div>
        <InProgressOverlay isActive={this.state.showLoader} message={this.state.message} translate={false}/>
        {this.props.defaultLabelType === 'zpl'
          ? <div className='row' style={{margin: '10px 0px'}}>
              <div className='col-md-6'>
                <div className='alert alert-info'>
                <strong>Version 2 Labels</strong>
                <div style={{marginTop: '12px', fontSize: 'smaller'}}>
                  Version 2 Labels are in use by default and are nearly identical to Version 1 when viewed or printed.
                  You can compare the versions when printing by changing the label type in the printing modal window.
                  If you need to switch back to Version 1 for some reason you can do so by clicking 'Change' below.
                  <br /><br />
                  <span className='text-danger'>Note: Version 1 Labels will be phased out.  Do not use them unless there is a significant issue with Version 2 labels for your facility.</span>
                  <br /><br />
                  <strong>Active Label Version: {this.props.defaultLabelType === 'image' ? 'Version 2' : 'Version 1'}</strong>
                    <a href='#'
                      className='float-right'
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        e.target.blur();
                        this.onToggleLabelVersion();
                      }}
                    >
                      Click To Change To {this.props.defaultLabelType === 'image' ? 'Version 1' : 'Version 2'}
                    </a>
                  <div style={{clear: 'both'}} />
                </div>
                </div>
              </div>
            </div>
          : <strong>Your facility is actively using Version 2 Labels</strong>
        }
        <div className='row' style={{margin: '10px 0px'}}>
          <div className='col-md-12'>
            <div>
              {I18n.t('labels.clickLabelToOpen')}
            </div>
          </div>
        </div>
        <table className='table table-striped table-bordered'>
          <thead>
          <tr>
            <th style={{width: '25%'}}>{I18n.t('labels.labelName')}</th>
            <th style={{width: '25%'}}>{I18n.t('labels.baseLabelSize')}</th>
            <th>Labels Based On This Base Type (Red indicated Disabled)</th>
          </tr>
          </thead>
          <tbody>
          {this.props[customLabels] === undefined
            ? null
            : this.props[customLabels].map( (label, index) => {
              return (
              <tr key={index}>
                <td>
                  <a href='#'
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      this.onLabelSelect(label);
                    }}>
                    {label.name}
                  </a>
                </td>
                <td>
                  <InternationalDecimalStatic>{label.width}</InternationalDecimalStatic> x <InternationalDecimalStatic>{label.height}</InternationalDecimalStatic>
                </td>
                <td>
                  {label.configurable.length === 0
                    ? <div style={{color:'green'}}>Default</div>
                    : label.configurable.reduce((acc, configured) => {
                      if(acc === null) return acc;
                      const data = JSON.parse(configured.code_detail.code);
                      if(data.name === 'Default') acc = null;
                      return acc;
                    }, <div style={{color:'green'}}>Default</div>)
                  }
                  {
                    label.configurable.length === 0
                    ? null
                    : label.configurable.map((configured, index) => {
                      const data = JSON.parse(configured.code_detail.code);
                      return (
                        <div key={index} style={{color: configured.is_active ? 'green' : 'red'}}>{data.name}</div>
                      );
                    })
                  }
                </td>
              </tr>
            );
            })
          }
          </tbody>
        </table>
      </div>
    );
  }
}


LabelsListPage.propTypes = {
  [customLabels]: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  labelsConfiguration: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  return {
    labelsConfiguration: state.labelsConfiguration,
    customLabels: state[customLabels],
    defaultLabelType: getDefaultLabelType(state),
    comp: state[itemNames.labelsCompliance],
  };
}

function mapDispatchToProps (dispatch) {
  const actions = {setData, setItem, goBack, push, getData, postItem, getItem};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LabelsListPage);
