import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {Field, change, formValueSelector} from 'redux-form';
import {Button, Col, Row} from 'react-bootstrap';
import get from 'lodash.get';
import {getRewardsForSelectInput, getRewardsEnabledState, getRewardsMode} from '../../selectors/rewardsSelectors';
import {getAdjustedCustomerPoints} from '../../selectors/customerSelectors';
import {hasPermApplyRewardCoupons} from '../../selectors/permissionsSelectors';
import {getRewardLines} from '../../selectors/ordersSelectors';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import {getUnpaginatedData, deleteItem, postItem, getErrorsArrayFromObject} from '../../actions/apiActions';
import {setItem, unsetItem} from '../../actions/itemActions';
import {unsetData} from '../../actions/dataActions';
import ReactSelectInput from '../common/form/ReactSelectInput';
import {addMessage} from '../../actions/systemActions';
import * as messageTypes from '../../constants/messageTypes';
import {COUPON_LIMIT} from '../../constants/coupons';

export class RewardSelect extends React.PureComponent {
  constructor(props, context){
    super(props, context);
    this.state = {
      disableCouponApplyButton: true,
      couponUpdateing: false,
      availableRewardsLoaded: false,
    };
    this.handleCouponChange = this.handleCouponChange.bind(this);
    this.applyCoupon = this.applyCoupon.bind(this);
    this.handleApplyErrors = this.handleApplyErrors.bind(this);
    this.getAvailableCouponsForOrder = this.getAvailableCouponsForOrder.bind(this);
  }

  componentWillUnmount() {
    this.props.actions.unsetData(dataNames.coupons);
    this.props.actions.unsetData(dataNames.rewards);
  }

  getAvailableCouponsForOrder(){
    this.setState({couponUpdating: true, availableRewardsLoaded: true});
    const {order_id} = this.props;
    this.props.showLoader('fetchingRewards');
    this.props.actions.getUnpaginatedData(`/api/orders/${order_id}/available_rewards`,
      dataNames.rewards, {failed: 'orders.rewards.failed'}, undefined,
      () => {
        this.setState({couponUpdating: false});
        this.props.couponsUpdated();
        this.props.hideLoader();
      }
    );
  }

  handleCouponChange(coupon){
    const {formName} = this.props;
    this.props.actions.change(formName, 'reward', coupon);
    this.props.actions.change(formName, 'target_order_product_id', undefined);
    this.setState({disableCouponApplyButton: !coupon});
  }

  handleApplyErrors(errors) {
    this.props.hideLoader();
    this.setState({disableCouponApplyButton: false});
    let validationErrors = get(errors,'response.data.errors.VALIDATION');
    if (typeof validationErrors === 'object') {
      // Remove general order_error message if we have another more grained validation messages
      if (validationErrors.ORDER_ERROR && Object.values(validationErrors).length > 1) {
        delete validationErrors.ORDER_ERROR;
      }
      validationErrors = getErrorsArrayFromObject(validationErrors);
      if(Array.isArray(validationErrors)) {
        validationErrors.forEach((error) => {
          this.props.actions.addMessage(messageTypes.error, error, true);
        });
      }
    }
    if (!errors.response) {
      this.dispatch(addMessage, I18n.t('productMenu.networkError'));
    }
  }

  applyCoupon() {
    const {coupon, target_order_product_id, order_id} = this.props;
    const payload = {coupon_id: coupon};
    this.setState({disableCouponApplyButton: true});
    if(target_order_product_id){
      payload.target_order_product_id = target_order_product_id;
    }
    this.props.showLoader();
    this.props.actions.postItem(`/api/orders/${order_id}/rewards`,
      payload,
      itemNames.order,
      {failedHandler: this.handleApplyErrors},
      undefined,
      () => {
        this.props.hideLoader();
      }
    );
  }

  render(){
    const {coupons, availableLines, products, rewards, rewardsEnabled, rewardsMode, hasApplyRewardCouponsPermission, adjustedCustomerPoints, order} = this.props;
    const {disableCouponApplyButton, availableRewardsLoaded} = this.state;
    if(!rewardsEnabled || rewardsMode !== 'coupons' || !hasApplyRewardCouponsPermission) return null;
    return (
      <Row >
        <Col xs={12} sm={6} md={6} lg={6}>
          <Field name='reward' component={ReactSelectInput} props={{
            label: I18n.t('cart.rewardName'),
            options: availableRewardsLoaded ? rewards : [],
            textKey: 'html',
            valueKey: 'id',
            disabled: !availableRewardsLoaded || (coupons.length < 1 || products.length < 1),
            onChange: (value) => this.handleCouponChange(value)
          }}>
            <div style={{marginTop: '5px'}} className='text-muted'>
              Points Available: {adjustedCustomerPoints}
            </div>
          </Field>
        </Col>
        {availableLines ? <Col xs={12} sm={6} md={6} lg={6}>
          <Field name='target_order_product_id' component={ReactSelectInput} props={{
            label: I18n.t('cart.lineItem'),
            options: availableLines,
            textKey: 'name',
            valueKey: 'id'
          }}/>
        </Col> : null}
        <Col xs={12} sm={12} md={12} lg={12} >
          {availableRewardsLoaded && coupons.length > 0 && products.length > 0 && <Button className='float-right' variant='primary' disabled={disableCouponApplyButton} onClick={this.applyCoupon}>
            {I18n.t('cart.items.applyReward')}
          </Button>}
          {!availableRewardsLoaded && <Button className='float-right' onClick={this.getAvailableCouponsForOrder} disabled={this.state.couponUpdating || !products.length || get(order, 'coupons', []).length >= COUPON_LIMIT}>{I18n.t('cart.items.fetchRewards')}</Button>}
        </Col>
      </Row>
    );
  }
}

RewardSelect.propTypes = {
  actions: PropTypes.shape({
    getUnpaginatedData: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    deleteItem: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    setItem: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired
  }),
  showLoader: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  coupon: PropTypes.number,
  coupons: PropTypes.array.isRequired,
  rewards: PropTypes.array,
  availableLines: PropTypes.array,
  couponUpdateRequired: PropTypes.bool.isRequired,
  target_order_product_id: PropTypes.number,
  order_id: PropTypes.number,
  couponsUpdated: PropTypes.func.isRequired,
  products: PropTypes.array,
  rewardsEnabled: PropTypes.bool,
  rewardsMode: PropTypes.string,
  hasApplyRewardCouponsPermission: PropTypes.bool,
  order: PropTypes.object.isRequired,
};



function mapStateToProps(state, ownProps) {
  const selector = formValueSelector(ownProps.formName);
  const {reward, target_order_product_id} = selector(state, 'reward', 'target_order_product_id');
  const availableLines = getRewardLines(state, {id: reward});
  const {
    couponUpdateRequired,
    order_id,
    formName,
    couponsUpdated,
    showLoader,
    hideLoader,
    products,
  } = ownProps;

  return {
    coupon: reward,
    coupons: state.rewards,
    rewards: getRewardsForSelectInput(state),
    rewardsEnabled: getRewardsEnabledState(state),
    rewardsMode: getRewardsMode(state),
    hasApplyRewardCouponsPermission: hasPermApplyRewardCoupons(state),
    adjustedCustomerPoints: getAdjustedCustomerPoints(state),
    availableLines,
    couponUpdateRequired,
    target_order_product_id,
    order_id,
    formName,
    couponsUpdated,
    showLoader,
    hideLoader,
    products,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({},
    {getUnpaginatedData, postItem, deleteItem,
      change, setItem, unsetItem, unsetData, addMessage});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RewardSelect);
