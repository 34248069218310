import {createSelector} from 'reselect';
import omit from 'lodash.omit';
import get from 'lodash.get';
import * as itemNames from '../constants/itemNames';

import {getStateIntegratorOptions} from './stateIntegratorsSelectors';
import {getActiveFacilityId} from './facilitiesSelectors';

export const getIntegrationSettings = state => state[itemNames.integrationSettings];
const orgFacilityStateLeaf = state => state[itemNames.orgFacilityStateLeaf];

export const hasOrgFacilityPALeaf = createSelector(orgFacilityStateLeaf, (facilities) => {
  if (facilities.pa) return Boolean(facilities.pa.length);
  return false;
});

export const getIntegrationSettingsInitialValues = createSelector(
  [getIntegrationSettings, getStateIntegratorOptions, getActiveFacilityId],
  (settings, integratorOptions, facilityId) => {
    return {
      selectedIntegrator: {
        setting_key: 'integration_state_integrator',
        scope: 'facility',
        scope_id: facilityId,
        value: settings.integration_state_integrator && settings.integration_state_integrator.value || 'none',
      },
      sync: {
        enabled: false,
        selected_modules: {},
      },
      integrators: integratorOptions.reduce(
        (acc, option) => {
          const settingKey = `integration_${option.value}_settings`;
          if (settings[settingKey]) {
            acc.push({
              integrator: option.value,
              setting_key: settingKey,
              scope: 'facility',
              scope_id: facilityId,
              value: mapValues(settings[settingKey], option.value),
            });
          }
          return acc;
        },
        []
      ),
    };
  }
);

export const getIntegrationSettingsPayload = (formValues) => {
  const payload = {
    settings: [formValues.selectedIntegrator],
  };
  const integrator = formValues.integrators.find(integrator => integrator.integrator === formValues.selectedIntegrator.value);
  if (integrator) {
    payload.settings.push(prepareIntegrator(Object.assign({}, integrator)));
  }

  // Either of these is NOT selected (eg switching biotrack to leaf or leaf to none) and setup_mode === false
  // force setup mode true
  ['leaf', 'biotrack'].forEach((integrator) => {
    if(formValues.selectedIntegrator.value !== integrator){
      const unselectedIntegrator = formValues.integrators.find((integration) => integration.integrator === integrator);
      if(unselectedIntegrator){
        const updatedIntegrator = prepareIntegrator(Object.assign({}, unselectedIntegrator));
        if(updatedIntegrator && updatedIntegrator.value && !updatedIntegrator.value.setup_mode) {
          updatedIntegrator.value.setup_mode = true;
          payload.settings.push(updatedIntegrator);
        }
      }
    }
  });
  return payload;
};

//Map integrator object to form values
function mapValues(values, integrator) {
  switch (integrator) {
  case 'leaf':
    return {
      ...values.value,
      is_initial_loading: (values.value && values.value.is_initial_loading) ? '1' : '0',
    };
  case 'biotrack':
    return {
      ...values.value,
      is_fifteen_day_window: (values.value && values.value.is_fifteen_day_window),
      require_patient_card_lookup:  (values.value && values.value.require_patient_card_lookup) ? '1' : '0',
      is_training_mode: (values.value && values.value.is_training_mode) ? '1' : '0',
    };
  default:
    return values && values.value;
  }
}

//Map form values back to integrator
function prepareIntegrator(integrator) {
  switch (integrator.integrator) {
  case 'leaf':
    return {
      ...omit(integrator, 'integrator'),
      value: {
        ...omit(integrator.value, 'value'),
        is_initial_loading: integrator.value && integrator.value.is_initial_loading === '1',
      },
    };
  case 'biotrack':
    return {
      ...omit(integrator, 'integrator'),
      value: {
        ...omit(integrator.value, 'value'),
        //is_fifteen_day_window: integrator.value && integrator.value.is_fifteen_day_window,
        require_patient_card_lookup: integrator.value && integrator.value.require_patient_card_lookup === '1' || false,
        is_training_mode: integrator.value && integrator.value.is_training_mode === '1' || false,
        override_environment_url: Boolean(integrator.value && integrator.value.override_environment_url),
      },
    };
  case 'metrc':
    return {
      ...omit(integrator, 'integrator'),
      value: {
        ...omit(integrator.value, 'value'),
        enable_sales_push: integrator.value && integrator.value.enable_sales_push === true,
        enabled_metrc_transfers: integrator.value && integrator.value.enabled_metrc_transfers === true,
      },
    };
  default:
    return omit(integrator, 'integrator');
  }
}

export const getFacilitiesWithWa = createSelector(orgFacilityStateLeaf, (facilities) => {
  return facilities.wa ? facilities.wa : [];
});

export const getMetrcState = (integrators) => get((integrators || []).find(integrator => integrator.integrator === 'metrc'), 'value.state');
export const getBiotrackState = (integrators) => get((integrators || []).find(integrator => integrator.integrator === 'biotrack'), 'value.state');
