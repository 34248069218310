import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {Link} from 'react-router';
import * as ReactIcons from 'react-icons/fa';
import * as ReactBiIcons from 'react-icons/bi';
import * as ReactPiIcons from 'react-icons/pi';
import * as ReactFa6Icons from 'react-icons/fa6';
import * as ReactMdIcons from 'react-icons/md';
import PermissionWrapper from '../common/PermissionWrapper';
import {evaluateRouteAccessibility} from '../../selectors/accessSelectors';

const AppNavigationCard = ({app, paths}) => {

  // Mapping from Glyphicon to ReactIcon
  const iconMapping = [
    {key: 'box-open', icon: ReactIcons.FaBoxOpen},
    {key: 'briefcase', icon: ReactIcons.FaBriefcase},
    {key: 'check', icon: ReactIcons.FaCheck},
    {key: 'cog', icon: ReactIcons.FaCog},
    {key: 'edit', icon: ReactIcons.FaEdit},
    {key: 'flag', icon: ReactIcons.FaFlag},
    {key: 'folder-open', icon: ReactIcons.FaFolderOpen},
    {key: 'leaf', icon: ReactIcons.FaCannabis},
    {key: 'link', icon: ReactIcons.FaLink},
    {key: 'list', icon: ReactIcons.FaList},
    {key: 'object-align-left', icon: ReactPiIcons.PiAlignLeftBold},
    {key: 'object-align-vertical', icon: ReactPiIcons.PiAlignCenterHorizontalBold},
    {key: 'option-horizontal', icon: ReactIcons.FaEllipsisH},
    {key: 'road', icon: ReactIcons.FaRoad},
    {key: 'scale', icon: ReactFa6Icons.FaWeightScale},
    {key: 'search', icon: ReactIcons.FaSearch},
    {key: 'share', icon: ReactIcons.FaShareSquare},
    {key: 'signal', icon: ReactIcons.FaSignal},
    {key: 'stats', icon: ReactIcons.FaChartBar},
    {key: 'th-large', icon: ReactMdIcons.MdInventory},
    {key: 'th-list', icon: ReactIcons.FaList},
    {key: 'tint', icon: ReactBiIcons.BiTestTube},
    {key: 'user', icon: ReactIcons.FaUserFriends},
  ];

  const IconComponent = iconMapping.find((icon) => icon.key === app.glyph);

  const iconBaseProps = {
    width: '50px',
    height: '50px'
  };

  return (
    <div className='layout-column layout-align-start-center app-card'>

      {app.path ?
        <Link to={app.path} onlyActiveOnIndex={false}>
          <IconComponent.icon {...iconBaseProps} />
        </Link> :
        <IconComponent.icon className='glyphicon-no-link' {...iconBaseProps} />
      }

      <div className='app-name'>
        {I18n.t(`nav.${app.name}`)}
      </div>

      {app.info &&
        <div>{I18n.t(app.info)}</div>
      }

      <div>
        {paths && paths.map((pathObj) => {
          const linkProps = {
            to: pathObj.path
          };

          if (pathObj.targetBlank) linkProps.target = '_blank';

          return (
            <PermissionWrapper key={pathObj.path}
                               permissions={pathObj.permissions}
                               accessibility={pathObj.accessibility}>
              <div>
                <Link {...linkProps}>
                  <span>{I18n.t(`nav.subNav.${pathObj.name}`)}</span>
                </Link>
              </div>
            </PermissionWrapper>
          );
        })}
      </div>

    </div>
  );
};


AppNavigationCard.propTypes = {
  app: PropTypes.object,
  paths: PropTypes.array
};

const mapStateToProps = (state, ownProps) => {
  // NOTE: this is a workaround due to the current way evaluateRouteAccessibility() is constructed with the entire
  // redux state being included in it. A better long-term solution is in the works. For now, this addition does
  // cause additional re-renders that it otherwise wouldn't have, but this trade-off is far more performant than
  // including this logic in another selector, where the entire "state" object would have to be included.
  const subPaths = ownProps.app.subPaths || [];
  const accessibleSubPaths = subPaths.filter(subPathObj => {
    return Boolean(evaluateRouteAccessibility(subPathObj.accessibility)(state));
  });

  return {
    paths: accessibleSubPaths
  };
};


export default connect(mapStateToProps)(AppNavigationCard);
