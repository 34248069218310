import React from 'react';
//import Loading from 'react-loading';
import {Col, Row} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';

const LoadingPage = () => {
  return (
    <Row className='loading-page'>
      <Col xs={3}>
        {/*<Loading type='spokes' delay={0} color='#000' />*/}
      </Col>
      <Col xs={9}>
        <h1>{I18n.t('loadingPage.loading')}</h1>
      </Col>
    </Row>
  );
};

export default LoadingPage;
