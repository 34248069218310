import React from 'react';
import PropTypes from 'prop-types';
import {CardGroup} from 'react-bootstrap';
import CustomerTile from './CustomerTile';
import SegmentedCustomerList from './queue/SegmentedCustomerList';

const CustomerList = ({customers, selectCustomer, selectOrders, selectProducts,
  fillOrder, checkout, orderType, usageType, pageWidth, timezone, activeSegmentId, segments, currentQueue,
  currentStateFilter, defaultVisitReason, cancelOrderAndReload, inferredScreenSize, patientComplianceSettings,
  isLockPreCheckinOnlineOrder}) => {

  const currentSegment = !Array.isArray(segments) ? [] : segments.find((segment) => {
    return segment.id === activeSegmentId;
  });

  const customerTileProps = {selectCustomer, selectOrders, selectProducts, fillOrder,
    checkout, orderType, usageType, timezone, cancelOrderAndReload, patientComplianceSettings,
    isLockPreCheckinOnlineOrder};

  //@TODO: Language all of this in properly once we get it dialed in for delivery segments
  const languageMap = {
    new: 'New',
    active: 'In Progress',
    mine: 'Mine',
    assigned: 'Ready For Payment',
    present: 'Customer In Store',
    curbside: 'Curbside'
  };

  return (activeSegmentId === 0 && currentQueue === 'in_store' && segments.length !== 1)
    ? (<SegmentedCustomerList
          customerTileProps={customerTileProps}
          pageWidth={pageWidth}
          customers={customers}
          segments={segments}
          activeSegmentId={activeSegmentId}
          defaultVisitReason={defaultVisitReason}
          currentQueue={currentQueue}
        />)
    : (customers.length === 0
          ? currentQueue === 'in_store' || currentQueue === 'pickup'
            ? <div>
                No Customers In "{currentSegment.name}" Queue
                {
                  currentStateFilter === 'all'
                    ? null
                    : ` (with current filter "${languageMap[currentStateFilter]}")`
                }
              </div>
            : <div>No Customers In Queue</div>
          : (<CardGroup>
            {customers.map((customer, index) => {
              return (
                <CustomerTile key={index}
                  {...customerTileProps}
                  cancelOrderAndReload={cancelOrderAndReload}
                  customer={customer}
                />
              );
            })}
          </CardGroup>)
    );

};

CustomerList.propTypes = {
  customers: PropTypes.array.isRequired,
  selectCustomer: PropTypes.func.isRequired,
  selectOrders: PropTypes.func.isRequired,
  selectProducts: PropTypes.func.isRequired,
  fillOrder: PropTypes.func.isRequired,
  checkout: PropTypes.func.isRequired,
  inferredScreenSize: PropTypes.string.isRequired,
  orderType: PropTypes.string,
  usageType: PropTypes.string,
  pageWidth: PropTypes.number.isRequired,
  timezone: PropTypes.string.isRequired,
  activeSegmentId: PropTypes.number,
  segments: PropTypes.array,
  defaultVisitReason: PropTypes.object.isRequired,
  currentQueue: PropTypes.string,
  currentStateFilter: PropTypes.string,
  cancelOrderAndReload: PropTypes.func.isRequired,
  patientComplianceSettings: PropTypes.object,
  isLockPreCheckinOnlineOrder: PropTypes.bool,
};

export default CustomerList;
