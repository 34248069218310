import get from 'lodash.get';

const tabs = [
  {
    id: 'activeTestResultsTab',
    eventKey: 'active',
    title: 'nav.active',
    actions: [
      {
        id: 'importResultsBiotrack',
        text: 'cultivation.testResults.actions.importResults',
        requireSelect: false,
      },
      {
        id: 'updateSearch',
        text: 'plants.actions.updateSearch',
        glyph: 'arrow-up',
        variant: 'warning',
        requireSelect: false,
      }
    ]
  },
  {
    id: 'historicalTestResultsTab',
    eventKey: 'historical',
    title: 'nav.historical',
    actions: [
      {
        id: 'updateSearch',
        text: 'plants.actions.updateSearch',
        glyph: 'arrow-up',
        variant: 'warning',
        requireSelect: false,
      }
    ]
  },
  { id: 'averagesTestResultsTab',
    eventKey: 'averages',
    title: 'nav.averages',
    actions: []
  },
  {
    id: 'exportTestResults',
    eventKey: 'export',
    title: 'nav.export',
    actions: [],
  }
];

/**
 * Adds disabled and func properties to the tabs which are set in the parent component.
 * @param config
 * @returns {({eventKey: string, id: string, title: string, actions: [{requireSelect: boolean, id: string, text: string}, {requireSelect: boolean, id: string, text: string}, {glyph: string, requireSelect: boolean, variant: string, id: string, text: string}]}|{eventKey: string, id: string, title: string, actions: [{glyph: string, requireSelect: boolean, variant: string, id: string, text: string}]}|{eventKey: string, id: string, title: string, actions: []}|{eventKey: string, id: string, title: string, actions: []})[]}
 */
export const getTabs = (config) => {
  return tabs.map((tab) => {
    tab.actions = tab.actions.map((action) => {
      const configAction = get(config, action.id, {});
      return Object.assign({}, action, configAction);
    });
    return tab;
  });
};

export default getTabs;
