import React from 'react';

import BaseFormFields from '../common/BaseFormFields';
import SubmitSection from '../common/SubmitSection';


const Hypur = () => {
  return (
    <div>
      <BaseFormFields integrator='hypur' />
      <SubmitSection />
    </div>
  );
};


export default Hypur;
