import React from 'react';
import PropTypes from 'prop-types';
import toArray from 'lodash.toarray';
import {reduxForm, getFormValues, formValueSelector, FieldArray} from 'redux-form';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {Row, Col} from 'react-bootstrap';
import SubmitSection from '../../common/form/SubmitSection';
import ContainerSummary from './ContainerSummary';
import Lot from './Lot';
import validate from './validate';

export class CreateContainerForm extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this._formValueSelector = formValueSelector(props.form);
    this.getFormValue = this.getFormValue.bind(this);
    this.getSummaryData = this.getSummaryData.bind(this);
  }

  getFormValue(field) {
    return this._formValueSelector(this.props.state, field);
  }

  getSummaryData() {
    const lots = this._formValueSelector(this.props.state, 'lots');
    const containerItems = {};
    let totalPackages = 0;
    let totalWeight = 0;
    if (lots && lots.length) {
      lots.forEach(lot => lot.packages.forEach(pkg => {
        if (!containerItems[pkg.item_master_id]) {
          containerItems[pkg.item_master_id] = {
            item_master_id: pkg.item_master_id,
            item_name: pkg.item_name,
            unit_size: pkg.prepack_name,
            uom_display: pkg.uom_display,
            quantity: 0,
            weight: 0
          };
        }
        if (pkg.transacted_qty) {
          const quantity = parseInt(pkg.transacted_qty);
          const weight = quantity && pkg.fulfillment_units
            ? (quantity * pkg.fulfillment_units) / 100
            : pkg.uom_display == 'GR'
              ? quantity
              : 0;
          containerItems[pkg.item_master_id].quantity += quantity;
          containerItems[pkg.item_master_id].weight += weight;
          totalPackages += quantity;
          totalWeight += weight;
        }
      }));
    }
    return {
      totalPackages,
      totalWeight,
      containerItems: toArray(containerItems)
    };
  }

  render() {
    const {handleSubmit, pristine, lots, locations, change, reset} = this.props;
    const summaryData = this.getSummaryData();
    const submitSettings = {
      actionSettings: {
        reset: {
          action: reset,
          text: I18n.t('common.form.reset'),
          pristine
        },
        submit: {
          text: I18n.t('containers.create.title'),
          pristine,
          invalid: !summaryData.totalPackages
        }
      }
    };
    return (
      <form onSubmit={handleSubmit}>
        <h3>{I18n.t('containers.form.lotInformation')}</h3>
        <Row>
          <Col md={12}>
            <div className='lots-section accordion'>
                <FieldArray
                  name='lots'
                  lots={lots}
                  component={Lot}
                  change={change}
                  getFormValue={this.getFormValue}
                />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h3>{I18n.t('containers.form.currentContainerSummary')}</h3>
          </Col>
          <Col md={12}>
            <ContainerSummary
              storageLocations={locations}
              totalData={summaryData}
            />
          </Col>
        </Row>
        <SubmitSection settings={submitSettings} />
      </form>
    );
  }
}

CreateContainerForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  state: PropTypes.shape({
    form: PropTypes.object.isRequired
  }).isRequired,
  pristine: PropTypes.bool.isRequired,
  lots: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
};


function mapStateToProps(state, ownProps) {

  const form = 'createContainer';
  return {
    state,
    form,
    validate,
    formValues: getFormValues(form)(state) || {}
  };
}

const formDecorator = reduxForm({})(CreateContainerForm);

export default connect(mapStateToProps)(formDecorator);
