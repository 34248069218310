import { I18n } from 'react-redux-i18n';
import {requiredFieldValidation} from '../common/form/redux-form/validations';
import { validatePostalCode } from '../common/form/validatePostalCode';


export const validate = (values, props) => {
  const errors = {
    driver_phones: [],
  };

  const postalCodeValidation = validatePostalCode(values.postal_code, props.countryCode);
  if (postalCodeValidation) {
    errors.postal_code = I18n.t(`common.form.${postalCodeValidation}`);
  }

  if (values.is_licensed_transporter) {
    errors.name = requiredFieldValidation(values.name, 'cultivation.drivers.form.name');
    errors.state_compliance_number = requiredFieldValidation(values.state_compliance_number, 'cultivation.drivers.form.stateLicense');
  } else {
    errors.first_name = requiredFieldValidation(values.first_name, 'cultivation.drivers.form.firstName');
    errors.last_name = requiredFieldValidation(values.last_name, 'cultivation.drivers.form.lastName');
    errors.birth_date = requiredFieldValidation(values.birth_date, 'cultivation.drivers.form.birthDate');
    errors.drivers_license_number = requiredFieldValidation(values.drivers_license_number, 'cultivation.drivers.form.driverLicense');
    if (props.integrationState && props.integrationState.isLeaf) {
      errors.user_id = requiredFieldValidation(values.user_id);
    }
  }
  if (props.onfleetIsEnabled || props.isMetrcDelivery) {
    for (const key in values.driver_phones) {
      if (!values.driver_phones[key].number) {
        errors.driver_phones[key] = {
          number: I18n.t('cultivation.drivers.form.phoneNumberRequired')
        };
      }
    }

    if (values.onfleet_teams.length === 0 && (!values.onfleet_worker_mapping || Object.keys(values.onfleet_worker_mapping).length > 0)) {
      errors.onfleet_teams = I18n.t('cultivation.drivers.form.onfleetTeamsRequired');
    }
  }

  //ToDo: show error on ui
  if(values.facilities){
    if(!isCheckBoxGroupValid(values.facilities)){
      if(values.facilities['North Vegas Grow LLC']) errors.facilities['North Vegas Grow LLC'] = 'error';
      if(values.facilities['Spring Valley Extraction']) errors.facilities['Spring Valley Extraction'] = 'error';
      if(values.facilities['Immerse']) errors.facilities['Immerse'] = 'error';
    }
    errors.facilities = requiredFieldValidation(values.postal_code, 'cultivation.drivers.form.authFacilities');
  }

  if (props.isMetrcDelivery) {
    errors.make = requiredFieldValidation(values.make, 'cultivation.vehicles.table.make');
    errors.model = requiredFieldValidation(values.model, 'cultivation.vehicles.table.model');
    errors.license_number = requiredFieldValidation(values.license_number, 'cultivation.vehicles.table.plateNumber');
    errors.state_compliance_number = requiredFieldValidation(values.state_compliance_number, 'cultivation.drivers.form.stateLicense');
  }
  return errors;

  function isCheckBoxGroupValid(obj){
    const arrTemp = [];
    for(const k in obj){
      if(obj.hasOwnProperty(k)){
        arrTemp.push(obj[k]);
      }
    }
    return arrTemp.some((item) => {
      return item;
    });
  }
};

export default validate;
