import React from 'react';
import {createSelector} from 'reselect';
import get from 'lodash.get';
import isEqual from 'lodash.isequal';
import uniqBy from 'lodash.uniqby';
import {getItemMaster} from './itemMastersSelectors';
import {getTimezone} from './timezoneSelectors';
import {getCurrentFacilityUsers} from './usersSelectors';
import * as dataNames from '../constants/dataNames';
import {WHOLESALE, RETAIL} from '../constants/saleTypes';
import {onlineAvailabilityOptions, terpeneList, potencyList} from '../components/products/common/data';
import {RECREATIONAL, MEDICAL} from '../constants/consumerTypes';
import {getFacilities} from './facilitiesSelectors';

export const getProductHistoryRaw = state => state[dataNames.itemMasterHistory];
const getStrains = state => state[dataNames.organizationStrains];
const getPartners = state => state[dataNames.partners];
const getPricingClasses = state => state[dataNames.pricingClasses];
const getPricingGroups = state => state[dataNames.pricingGroups];
const getPricingWeights = state => state[dataNames.pricingWeights];
const getCategories = state => state[dataNames.categories];
const getFacilityStrains = state => state[dataNames.facilityStrains];
const getSubCategories = createSelector([getCategories], categories => categories.reduce((acc, category) => [...acc, ...category.subcategories], []));
const getPhenotypes = createSelector([getFacilityStrains],strains => uniqBy([...strains.map(strain => strain.phenotypes)], 'id'));
const YES = 'YES';
const NO = 'NO';

export const getProductHistory = createSelector(
  [getProductHistoryRaw, getTimezone, getCurrentFacilityUsers],
  (history, timezone, users) => history.map((historyItem) => {
    const user = users.find(user => user.id === historyItem.user_id);
    const model = historyItem.model ? JSON.parse(historyItem.model) : null;

    return {
      ...historyItem,
      event_date: historyItem.event_date,
      model,
      user_name: user ? `${user.first_name} ${user.last_name}` : historyItem.user_name
    };
  })
);

const makeDeltaForPricing = (field, view_field, type, check_field, label) => [1, 0].reduce((acc1, org_level) => [...acc1, ...[MEDICAL, RECREATIONAL].reduce((acc2, consumer_type) => [...acc2, ...[RETAIL, WHOLESALE].reduce((acc3, sale_type) => ([...acc3, {
  name: `${org_level ? 'organization' : 'facility'}_${sale_type === RETAIL ? '' : WHOLESALE}_${sale_type === RETAIL ? consumer_type : '' }_${view_field ? view_field : field}`,
  get: (model) => {
    const value = get(model.pricing_details.price_lists.find(price_list =>
    price_list.consumer_type === consumer_type && price_list.sale_type === sale_type && price_list.is_default_for_org === org_level), field);
    switch (type) {
    case 'checkbox':
      return !!value;
    case 'number':
      if (value === undefined) return 0;
      return Number(value);
    case 'weight_prices':
      return !value ? [] : value
        .map(item => ({...item, default_price: parseFloat(item.default_price)}))
        .filter(item => parseFloat(item[check_field]));
    case 'string':
      return value ? value : '';
    }
    return value;
  },
  compare: (prev, curr) => {
    if (type === 'weight_prices') {
      if (!prev.length && !curr.length) {
        return false;
      }
      if (prev.length !== curr.length) {
        return true;
      }

      let changed = false;
      prev.map(item1 => {
        const next_itter_item = curr.find(item2 =>
          item2.pricing_weight_id === item1.pricing_weight_id);
        if (next_itter_item && next_itter_item.default_price !== item1.default_price) {
          changed = true;
        } else if (!next_itter_item) {
          changed = true;
        }
      });
      if (changed) return true;
      curr.map(item2 => {
        if (!prev.find(item1 => item2.pricing_weight_id === item1.pricing_weight_id)) {
          changed = true;
        }
      });
      return changed;
    }
    if (Array.isArray(prev) && Array.isArray(curr)) {
      return !isEqual(prev, curr);
    }
    return prev !== curr;
  },
  getViewValue: (model) => {
    const value = get(model.pricing_details.price_lists.find(price_list =>
      price_list.consumer_type === consumer_type
      && price_list.sale_type === sale_type
      && price_list.is_default_for_org === org_level), view_field ? view_field : field, type === 'weight_prices' ? [] : null);
    switch (type) {
    case 'checkbox':
      return value ? YES : NO;
    default:
      return value;
    }
  },
  type: 'sales',
  label
}]), {})], {})], {});

const getOnlineAvailabilityDelta = () => ['organization', 'facility'].map((level) => ({
  name: `${level}_onlineAvailability`,
  get: (model) => get(model.attribute_lists.find(item => level === 'facility' ? !item.is_default_for_org : item.is_default_for_org), 'title'),
  label: `OnlineAvailability${level === 'organization' ? 'OrganizationDefaults' : 'ByFacility'}`
}));

const getOnlineAvailabilityFacilityDelta = () => ({
  name: `onlineAvailability_applicableFacilities`,
  get: (model) => get(model.attribute_lists.find(item => !item.is_default_for_org), 'facility_ids', []),
  getViewValue: model => get(model.attribute_lists.find(item => !item.is_default_for_org), 'facility_title'),
  compare: (prev, curr) => !isEqual(prev, curr),
  label: `OnlineAvailabilityByFacilityApplicableFacilities`
});

const getMeasurementListDelta = () => [...terpeneList, ...potencyList].reduce((acc, name) => ([...acc, ...[
  {
    name: `products.form.${name}`,
    get: model => model[`${name}_percent`],
    getViewValue: model => model[`${name}_percent`] + ' %'
  },
  {
    name: `products.form.${name}`,
    get: model => model[`${name}_weight`],
    getViewValue: model => model[`${name}_weight`] + ' mg/g'
  },
]]), []);


export const getProductHistoryTableData = createSelector(
  [getItemMaster, getProductHistory, getStrains, getPartners, getPricingClasses, getPricingGroups, getPricingWeights, getCategories,
    getSubCategories, getFacilities, getPhenotypes],
  (product, productHistory, strains, partners, classes, groups, weights, categories, subcategories, facilities, phenotypes) => {
    const h = productHistory.reduce(
      (acc, historyItem) => {
        if (historyItem.model) {

          const isPrepack = get(historyItem.model, 'inventory_attributes.is_prepack', undefined);
          const isLotTracked = get(historyItem.model, 'inventory_attributes.lot_tracked', undefined);

          const model = {pricing_details: {price_lists: []}, ...historyItem.model};
          model.isMassModify = (model.action === 'mass_modify');

          model.name = model.name ? model.name : get(acc, 'model.name');
          model.subcategory_id = model.subcategory_id ? model.subcategory_id : get(acc, 'model.subcategory_id');
          model.category_id = model.category_id ? model.category_id : get(acc, 'model.category_id');
          model.strain_id = model.strain_id ? model.strain_id : get(acc, 'model.strain_id');
          model.is_sales_item = model.is_sales_item !== undefined ? model.is_sales_item : get(acc, 'model.is_sales_item');
          model.is_manufactured_item = model.is_manufactured_item !== undefined ? model.is_manufactured_item : get(acc, 'model.is_manufactured_item');
          model.is_inventory_item = model.is_inventory_item !== undefined ? model.is_inventory_item : get(acc, 'model.is_inventory_item');
          model.is_purchasing_item = model.is_purchasing_item !== undefined ? model.is_purchasing_item : get(acc, 'model.is_purchasing_item');
          model.default_uom = model.default_uom ? model.default_uom : get(acc, 'model.default_uom');
          model.inventory_attributes = {
            is_prepack: isPrepack !== undefined ? isPrepack : get(acc, 'model.inventory_attributes.is_prepack', undefined),
            lot_tracked: isLotTracked !== undefined ? isLotTracked : get(acc, 'model.inventory_attributes.lot_tracked', undefined),
          };
          model.category_name = get(categories.find(category => category.id === model.category_id), 'name');
          model.subcategory_name = get(subcategories.find(category => category.id === model.subcategory_id), 'name');
          model.strain_name = get(strains.find(strain => strain.id === model.strain_id), 'strain_name');
          model.phenotype_name = model.phenotype_id ? phenotypes.find(phenotype => phenotype.id === product.phenotype_id) : '';
          model.tags_name = get(model, 'tags', []).map(tag => typeof tag === 'string' ? tag : tag.tag_name).join(', ');
          model.attribute_lists = get(model, 'attribute_lists', []).map(attr => ({
            ...attr,
            title: get(onlineAvailabilityOptions.find(option => option.value === attr.available_online), 'text'),
            facility_title: get(attr, 'facility_ids', []).map(f_id => get(facilities.find(f_r => f_r.id === f_id), 'name')).join(', ')
          }));
          model.vendors = get(model, 'vendors', []).map(vendor => ({
            ...vendor,
            vendor_name: get(partners.find(p => p.id === vendor.partner_id), 'name')
          }));
          model.pricing_details = {
            price_lists: get(model, 'pricing_details.price_lists', get(acc, 'model.pricing_details.price_lists', [])).map(price_list => ({
              ...price_list,
              class_name: get(classes.find(cl => cl.id === price_list.pricing_class_id), 'name'),
              group_name: get(groups.find(gr => gr.id === price_list.pricing_group_id), 'name'),
              weight_prices: get(price_list, 'weight_prices', []).map(weight => ({
                ...weight,
                title: get(weights.find(w => w.id === weight.pricing_weight_id), 'name')
              })),
              applicable_facilities: get(price_list, 'facility_ids', []).map(f_id => get(facilities.find(r_f => r_f.id === f_id), 'name')).join(', ') || ''
            }))
          };
          model.purchasing_attributes = {
            auto_reorder_qty: get(model, 'purchasing_attributes.auto_reorder_qty'),
            par_level: get(model, 'purchasing_attributes.par_level'),
            buffer: get(model, 'purchasing_attributes.buffer'),
          };
          const delta = acc.model ? calculateDelta(acc.model, model) : null;
          acc.history.push({...historyItem, model, delta});
          acc.model = model;
        } else {
          acc.history.push(historyItem);
        }
        return acc;
      },
      {history: [], model: null}
    ).history
      .filter(history_item =>
        (history_item.event_type === 'update_item_master' && history_item.delta && history_item.delta.length)
        || (history_item.event_type === 'created_item_master'));
    return h;
  }
);

const deltaConfig = [
  {name: 'name', label: 'products.form.itemName'},
  {name: 'active',
    get: model => !!model.active,
    getViewValue: model => model.active ? 'Active' : 'Inactive',
    label: 'common.status'
  },
  {
    name: 'subcategory_id',
    get: model => model.subcategory_id,
    getViewValue: (model) => model.subcategory_name,
    label: 'products.form.itemSubcategory'
  },
  {
    name: 'category_id',
    get: model => model.category_name,
    label: 'products.form.itemCategory'
  },
  {
    name: 'strain_name',
    get: model => model.strain_id,
    getViewValue: (model) => model.strain_name,
  },
  {name: 'display_name'},
  {
    name: 'brand',
    get: model => model.brand_id,
    getViewValue: (model) => (model.brand && model.brand.name) ? model.brand.name : model.brand,
  },
  {name: 'tags_name', label: 'products.form.tags'},
  {name: 'description', label: 'products.form.productDescription'},
  {name: 'item_number', label: 'products.form.itemId'},
  {name: 'is_purchasing_item', type: 'checkbox', label: 'products.form.purchaseInventory'},
  {name: 'is_inventory_item', type: 'checkbox', label: 'products.form.trackInventory'},
  {name: 'is_sales_item', type: 'checkbox', label: 'products.form.sellInventory'},
  {name: 'is_manufactured_item', type: 'checkbox', label: 'products.form.produceInventory'},
  {name: 'default_uom', label: 'products.form.inventoryUom'},
  {name: 'medicated_weight', label: 'products.form.medicatedNetWeight', type: 'number'},
  {name: 'medicated_weight_uom', label: 'products.form.uom'},
  {name: 'net_weight',label: 'products.form.productNetWeight', type: 'number'},
  {name: 'net_weight_uom', label: 'products.form.uom'},
  {name: 'unit_weight', label: 'products.form.grossWeight', type: 'number'},
  {name: 'unit_weight_uom', label: 'products.form.uom'},
  {name: 'medicated_volume', label: 'products.form.medicatedNetVolume', type: 'number'},
  {name: 'medicated_volume_uom', label: 'products.form.uom'},
  {name: 'net_volume', label: 'products.form.netVolume', type: 'number'},
  {name: 'net_volume_uom', label: 'products.form.uom'},
  {
    name: 'parLevel',
    get: model => get(model, 'purchasing_attributes.par_level'),
    label: 'products.form.parLevel',
    type: 'number'
  },
  {
    name: 'autoReorderQuantity',
    get: model => get(model, 'purchasing_attributes.auto_reorder_qty'),
    label: 'products.form.autoReorderQuantity',
    type: 'number'
  },
  {
    name: 'apiBuffer',
    get: model => get(model, 'purchasing_attributes.buffer'),
    label: 'products.form.apiBuffer',
  },
  {
    name: 'phenotype_id',
    get: model => model.phenotype_id,
    getViewValue: (model) => model.phenotype_name,
    label: 'products.form.phenotype'
  },
  {name: 'dominance', label: 'products.form.dominanceName'},
  {
    name: 'product.is_prepack',
    get: model => !!get(model, 'inventory_attributes.is_prepack'),
    getViewValue: model => !get(model, 'inventory_attributes.is_prepack', null) ? NO : YES,
    label: 'products.form.prePackItem'
  },
  {
    name: 'product.lot_tracked',
    get: model => !!get(model, 'inventory_attributes.lot_tracked'),
    type: 'checkbox',
    label: 'products.form.trackLots'
  },
  ...makeDeltaForPricing('default_price', null, 'number', null, 'default_price'),
  ...makeDeltaForPricing('inherit_pricing_class_updates', null, 'checkbox', null, 'products.form.inheritClassChanges'),
  ...makeDeltaForPricing('inherit_pricing_group_updates', null, 'checkbox', null, 'products.form.inheritGroupChanges'),
  ...makeDeltaForPricing('is_non_taxable', null, 'checkbox', null, 'products.form.nonTaxableProduct'),
  ...makeDeltaForPricing('pricing_group_id', 'group_name', 'string', null, 'products.form.pricingGroup'),
  ...makeDeltaForPricing('pricing_class_id', 'class_name', 'string', null, 'products.form.pricingClass'),
  ...makeDeltaForPricing('weight_prices', null, 'weight_prices', 'default_price', ''),
  ...makeDeltaForPricing('applicable_facilities', null, 'string', null, 'taxes.form.applicableFacilities'),
  ...getOnlineAvailabilityDelta(),
  getOnlineAvailabilityFacilityDelta(),
  {name: 'activation_time', label: 'products.form.activationTime'},
  ...getMeasurementListDelta(),
  {name: 'serving_size', label: 'products.form.servingSize'},
  {name: 'servings_per_container', label: 'products.form.servingsPerContainer'},
  {name: 'solvent', label: 'products.form.solvent'},
  {name: 'requires_refrigeration',
    get: (model) => (model.requires_refrigeration === undefined) ? undefined : (model.requires_refrigeration ? 1 : null),
    getViewValue: model => model.requires_refrigeration ? YES : NO,
    label: 'products.form.requiresRefrigeration'
  },
  {name: 'pieces_per_unit', label: 'products.form.piecesPerUnit', type: 'number'},
  {name: 'dilution_method', label: 'products.form.dilutionMethod'},
  {name: 'supply_duration_days', label: 'products.form.supplyDurationDays', type: 'number'},
  {name: 'ingredients', label: 'products.form.ingredients'},
  {name: 'allergens', label: 'products.form.allergens'},
  {name: 'nutritional_info', label: 'products.form.nutritional'},
  {name: 'administration_method', label: 'products.form.AdministrationMethod'},
  {
    name: 'vendors',
    get: (model) => JSON.stringify(get(model, 'vendors', [])),
    getViewValue: (model, isCurrent = false) => {
      return (<div style={{width: '45%', float: isCurrent ? 'right' : 'left'}}>
        {
          model.vendors.length === 0 ? 'No Vendors' : null
        }
        {
          model.vendors.map((vendor, index) => {
            return <div key={index} style={{width: isCurrent ? '100%' : '90%', float: 'left'}}>{`Partner Name: ${vendor.vendor_name} Cost Per ${model.default_uom}: ${vendor.default_unit_cost}`}</div>;
          })
        }
      </div>);
    },
    compare: (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr),
    label: 'products.form.vendors'
  }
];

function getValue(model, fieldConfig) {
  if (typeof fieldConfig.get === 'function') {
    return fieldConfig.get(model);
  }
  if (fieldConfig.type === 'checkbox') {
    return !!model[fieldConfig.name];
  }
  if (fieldConfig.type === 'number') {
    if (!model[fieldConfig.name]) return 0;
    return Number[fieldConfig.name];
  }
  return model[fieldConfig.name];
}

function isValueChanged(prevValue, currentValue, fieldConfig) {
  if (typeof fieldConfig.compare === 'function') {
    return fieldConfig.compare(prevValue, currentValue);
  }
  if (prevValue === undefined || currentValue === undefined) {
    return false;
  }
  return prevValue !== currentValue;
}

function getViewValue(model, fieldConfig, isCurrent = false) {
  if (typeof fieldConfig.getViewValue === 'function') {
    return fieldConfig.getViewValue(model, isCurrent) || 'null';
  }
  if (fieldConfig.type === 'checkbox') {
    return getValue(model, fieldConfig) ? YES : NO;
  }
  return getValue(model, fieldConfig) || 'null';
}

function getDeltaConfig(){
  return [...deltaConfig] || [];
}

function calculateDelta(prevState, currentState) {
  return getDeltaConfig().reduce(
    (acc, fieldConfig) => {
      const prevValue = getValue(prevState, fieldConfig);
      const currentValue = getValue(currentState, fieldConfig);
      if (isValueChanged(prevValue, currentValue, fieldConfig)) {
        acc.push({
          name: fieldConfig.name,
          prev: getViewValue(prevState, fieldConfig),
          current: getViewValue(currentState, fieldConfig, true),
          label: fieldConfig.label,
          type: fieldConfig.type
        });
      }
      return acc;
    },
    []
  );
}

export const showSection = (name, data, levels = []) => {
  const fields = ['default_price', 'inherit_pricing_class_updates', 'inherit_pricing_group_updates', 'is_non_taxable', 'group_name',
    'class_name', 'weight_prices', 'applicable_facilities'];
  let fieldsFullName = [];
  if (!data) return false;
  switch (name) {
  case MEDICAL:
    fieldsFullName = (levels.length ? levels : ['organization', 'facility']).reduce((acc1, org_level) => [...acc1, ...fields.reduce((acc2, field_name) => [...acc2, `${org_level}__${MEDICAL}_${field_name}`], [])], []);
    break;
  case RECREATIONAL:
    fieldsFullName = (levels.length ? levels : ['organization', 'facility']).reduce((acc1, org_level) => [...acc1, ...fields.reduce((acc2, field_name) => [...acc2, `${org_level}__${RECREATIONAL}_${field_name}`], [])], []);
    break;
  case WHOLESALE:
    fieldsFullName = (levels.length ? levels : ['organization', 'facility']).reduce((acc1, org_level) => [...acc1, ...fields.reduce((acc2, field_name) => [...acc2, `${org_level}_${WHOLESALE}__${field_name}`], [])], []);
    break;
  }
  return !!fieldsFullName.find(field_name => data.find(row => row.name === field_name));
};
