import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import PermissionWrapper from '../../PermissionWrapper';
import ValueButtonInput from '../ValueButtonInput';

const ReduxButton = ({name, permissions, ...props}) => {
  return (<PermissionWrapper permissions={[...permissions]}>
      <Field name={name}
        component={ValueButtonInput}
        {...props}
        props={{
          ...props.props,
        }}/>
    </PermissionWrapper>);
};

ReduxButton.propTypes = {
  name: PropTypes.string.isRequired,
  props: PropTypes.object,
  permissions: PropTypes.array
};

ReduxButton.defaultProps = {
  name: 'afterSubmit',
  permissions: []
};

export default ReduxButton;
