import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Row, Col} from 'react-bootstrap';
import {Field} from 'redux-form';
import AccordionPanel from '../../../common/AccordionPanel';
import SubmitSection from '../../../common/form/SubmitSection';
import DatePickerInput from '../../../common/form/DatePickerInput';
import TextInput from '../../../common/form/TextInput';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import {CURE_API_PATIENT, CURE_API_CAREGIVER} from '../../../../constants/idTypes';

const options = [
  {text: I18n.t('consumers.checkin.caregiver'), value: CURE_API_CAREGIVER},
  {text: I18n.t('consumers.checkin.patient'), value: CURE_API_PATIENT},
];

const CheckinCureApiForm = (props) => {
  const {isCaregiverMode, invalid, pristine, submitting, reset, handleSubmit} = props;
  const labelParams = {entity: I18n.t(isCaregiverMode ? 'consumers.checkin.caregiver' : 'consumers.checkin.patient')};
  return (
    <form onSubmit={handleSubmit} noValidate={true}>
      <AccordionPanel title='consumer.checkin.filter'>
        <Row>
          <Col xs={6} sm={3} md={2}>
            <Field name='license_type' component={ReactSelectInput} props={{
              options,
              isRequired: true,
              label: I18n.t('consumers.checkin.licenseType'),
              clearable: false,
            }}/>
          </Col>
          <Col xs={6} sm={3} md={2}>
            <Field name='patient_number' component={TextInput} props={{
              label: I18n.t('consumers.checkin.patientNumber'),
              isRequired: true,
            }}/>
          </Col>
          <Col xs={6} sm={3} md={2}>
            <Field name='first_name' component={TextInput} props={{
              label: I18n.t('consumers.checkin.cureFirstName', labelParams),
              isRequired: isCaregiverMode,
            }}/>
          </Col>
          <Col xs={6} sm={3} md={2}>
            <Field name='last_name' component={TextInput} props={{
              label: I18n.t('consumers.checkin.cureLastName', labelParams),
              isRequired: isCaregiverMode,
            }}/>
          </Col>
          <Col xs={6} sm={4} md={3}>
            <Field name='dob' component={DatePickerInput} props={{
              label: I18n.t('consumers.checkin.cureDateOfBirth', labelParams),
              isRequired: isCaregiverMode,
            }}/>
          </Col>
        </Row>
        <SubmitSection settings={{
          actionSettings: {
            reset: {
              pristine,
              submitting,
              action: reset,
              type: 'reset',
              text: I18n.t('common.form.reset')
            },
            submit: {
              invalid,
              pristine,
              submitting,
              type: 'submit',
              style: 'primary',
              text: I18n.t('common.form.search')
            }
          }
        }}/>
      </AccordionPanel>
    </form>
  );
};

CheckinCureApiForm.propTypes = {
  isCaregiverMode: PropTypes.bool.isRequired,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  reset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default CheckinCureApiForm;
