import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {bindActionCreators} from 'redux';
import {Field, FieldArray} from 'redux-form';
import {Col, Row} from 'react-bootstrap';
import get from 'lodash.get';
import * as dataNames from '../../../constants/dataNames';
import InlineCheckBox from '../../common/form/InlineCheckBox';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import {getTaxProfiles} from '../../../selectors/taxProfilesSelectors';
import {getUnpaginatedData} from '../../../actions/apiActions';
import PricingMultiplierSingleUnitPrice from './PricingMultiplierSingleUnitPrice';
import PricingMultiplierPriceFieldArray from './PricingMultiplierPriceFieldArray';

export class PricingMultiplierPricesComponent extends React.PureComponent {
  constructor (props) {
    super(props);
  }

  componentDidMount() {
    // get tax profiles
    this.props.actions.getUnpaginatedData('/api/tax_profiles?active=1', dataNames.taxProfiles, {failed: 'taxProfiles.get.failed'});
  }

  render () {
    const getTaxRate = (taxProfile) => {
      const taxes = get(taxProfile, 'taxes', []);
      return taxes.reduce((acc, tax) => {
        const amount = get(tax, 'tax.amount');
        if (!amount) {
          return acc;
        }
        return parseFloat(amount) + acc;
      }, 0);
    };

    const {taxProfiles, change, getFormValue, formValues, pricingMultiplierId} = this.props;
    const medTaxProfiles = taxProfiles.filter(taxProfile => taxProfile.applies_to_medical);
    const recTaxProfiles = taxProfiles.filter(taxProfile => taxProfile.applies_to_recreational);
    const medTaxProfile = medTaxProfiles.find(taxProfile => taxProfile.id === getFormValue('pricing_tiers.med.tax_profile'));
    const recTaxProfile = recTaxProfiles.find(taxProfile => taxProfile.id === getFormValue('pricing_tiers.rec.tax_profile'));
    const medTaxRate = getTaxRate(medTaxProfile);
    const recTaxRate = getTaxRate(recTaxProfile);

    const recalcPostTaxFields = (fieldPrefix, value) => {
      const taxProfile = taxProfiles.find(taxProfile => taxProfile.id === value);
      const taxRate = getTaxRate(taxProfile);
      const tiers = get(formValues, fieldPrefix, []);
      if (taxRate === 0) {
        tiers.forEach((tier, index) => {
          // Only clear the value if it exists. If not the 'empty' values of the sparse array collapse
          const postTaxValue = getFormValue(`${fieldPrefix}.${index}.price_postTax`);
          if (postTaxValue) {
            change(`${fieldPrefix}.${index}.price_postTax`, '');
          }
        });
      } else {
        tiers.forEach((tier, index) => {
          const preTaxValue = getFormValue(`${fieldPrefix}.${index}.price`);
          // Only clear the postTax value if the preTax value exists. If not the 'empty' values of the sparse array collapse
          if (preTaxValue) {
            const postTaxValue = Number((preTaxValue * (1 + taxRate)).toFixed(2));
            change(`${fieldPrefix}.${index}.price_postTax`, postTaxValue);
          }
        });
      }
    };

    const initialPostTaxCalc = (type, taxProfile) => {
      if (pricingMultiplierId && taxProfile) {
        // check if any preTax values don't have a postTax value
        const tiers = get(formValues, `pricing_tiers.${type}.tiers`, []);
        const needsInitialCalc = tiers.some((tier, index) => {
          const preTaxValue = getFormValue(`pricing_tiers.${type}.tiers.${index}.price`);
          const postTaxValue = getFormValue(`pricing_tiers.${type}.tiers.${index}.price_postTax`);
          return preTaxValue && !postTaxValue;
        });
        if (needsInitialCalc) {
          recalcPostTaxFields(`pricing_tiers.${type}.tiers`, taxProfile.id);
        }
      }
    };

    // Initial calc of postTax fields
    initialPostTaxCalc('med', medTaxProfile);
    initialPostTaxCalc('rec', recTaxProfile);

    return (
      <React.Fragment>
        <Row>
          <Col sm={6} style={{textAlign: 'center', verticalAlign: 'middle', fontSize: '1.4em'}}>
            <Field
              name='pricing_tiers.med.active'
              component={InlineCheckBox}
              props={{
                groupClassName: 'inline-checkbox',
                label: I18n.t('retail.pricingMultiplier.form.medical'),
              }}
            />
          </Col>
          <Col sm={6} style={{textAlign: 'center', verticalAlign: 'middle', fontSize: '1.4em'}}>
            <Field
              name='pricing_tiers.rec.active'
              component={InlineCheckBox}
              props={{
                groupClassName: 'inline-checkbox',
                label: I18n.t('retail.pricingMultiplier.form.recreational'),
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Field name='pricing_tiers.med.tax_profile' component={ReactSelectInput} props={{
              label: I18n.t('retail.pricingMultiplier.form.taxProfile'),
              options: medTaxProfiles,
              valueKey: 'id',
              textKey: 'tax_profile_name',
              placeholder: I18n.t('retail.pricingMultiplier.form.taxProfilePlaceholder'),
              onChange: (value) => {
                recalcPostTaxFields('pricing_tiers.med.tiers', value);
                change('pricing_tiers.med.tax_profile', value);
              }
            }} />
          </Col>
          <Col sm={6}>
            <Field name='pricing_tiers.rec.tax_profile' component={ReactSelectInput} props={{
              label: I18n.t('retail.pricingMultiplier.form.taxProfile'),
              options: recTaxProfiles,
              valueKey: 'id',
              textKey: 'tax_profile_name',
              placeholder: I18n.t('retail.pricingMultiplier.form.taxProfilePlaceholder'),
              onChange: (value) => {
                recalcPostTaxFields('pricing_tiers.rec.tiers', value);
                change('pricing_tiers.rec.tax_profile', value);
              }
            }} />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <PricingMultiplierSingleUnitPrice
              fieldPrefix={'pricing_tiers.med.tiers.0'}
              taxRate={medTaxRate > 0 ? medTaxRate : null}
              getFormValue={getFormValue}
              change={change}
            />
          </Col>
          <Col sm={6}>
            <PricingMultiplierSingleUnitPrice
              fieldPrefix={'pricing_tiers.rec.tiers.0'}
              taxRate={recTaxRate > 0 ? recTaxRate : null}
              getFormValue={getFormValue}
              change={change}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <FieldArray
              name='pricing_tiers.med.tiers'
              component={PricingMultiplierPriceFieldArray}
              fieldPrefix={'pricing_tiers.med.tiers'}
              pricingMultiplierId={pricingMultiplierId}
              taxRate={medTaxRate > 0 ? medTaxRate : null}
              change={change}
              formValues={formValues}
            />
          </Col>
          <Col sm={6}>
            <FieldArray
              name='pricing_tiers.rec.tiers'
              component={PricingMultiplierPriceFieldArray}
              fieldPrefix={'pricing_tiers.rec.tiers'}
              pricingMultiplierId={pricingMultiplierId}
              taxRate={recTaxRate > 0 ? recTaxRate : null}
              change={change}
              formValues={formValues}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

PricingMultiplierPricesComponent.propTypes = {
  actions: PropTypes.shape({
    getUnpaginatedData: PropTypes.func.isRequired,
  }),
};

function mapStateToProps (state) {
  return {
    taxProfiles: getTaxProfiles(state)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, {getUnpaginatedData});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PricingMultiplierPricesComponent);
