import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import {Row, Col} from 'react-bootstrap';
import TextInput from '../../common/form/TextInput';
import ReduxMetrcIdField from '../../common/form/redux-form/ReduxMetrcIdField';
import WillRender from '../../common/concealers/WillRender';

const PackageIndividualPlantsFieldArray = props => {
  const {
    fields,
    isCaMetrc,
    trackingIds,
    selectedItemMaster,
  } = props;

  return (
    <div>
      {fields.map((name, index) => {
        return (
          <Row key={index}>
            <Col xs={4} md={3}>
              <Field
                name={`${name}.plant_id`}
                component={TextInput}
                props={{
                  label: I18n.t('plants.form.plantId'),
                  disabled: true
                }}
              />
            </Col>
            <Col xs={4} md={3}>
              <Field
                name={`${name}.count`}
                component={TextInput}
                props={{
                  label: I18n.t('common.form.count'),
                  isRequired: true,
                  rightAddon: selectedItemMaster && selectedItemMaster.default_uom || undefined,
                }}
              />
            </Col>
            <WillRender ifTrue={isCaMetrc}>
              <Col md={4}>
                <ReduxMetrcIdField
                  name={`${name}.state_integration_tracking_id`}
                  props={{
                    options: trackingIds
                  }}
                />
              </Col>
            </WillRender>
          </Row>
        );
      })}
    </div>
  );
};

PackageIndividualPlantsFieldArray.propTypes = {
  fields: PropTypes.shape({
    get: PropTypes.func.isRequired,
    map: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
  }).isRequired,
  isCaMetrc: PropTypes.bool.isRequired,
  trackingIds: PropTypes.array,
  selectedItemMaster: PropTypes.object,
};

export default PackageIndividualPlantsFieldArray;