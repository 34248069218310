import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Row, Card} from 'react-bootstrap';
import PatientDetailsCureApi from './PatientDetailsCureApi';


const CaregiverDetailsCureApi = (props) => {
  const {caregiver, onRegister, budTenders, timezone, expanded, onDetailsExpand} = props;
  const caregiverInfo = caregiver.caregiver || {};
  const caregiver_name = `${caregiverInfo.first_name || ''} ${caregiverInfo.last_name || ''}`;

  return (
    <Row>
      <Col xs={12}>
        <Card className='clearfix' body>
          <Row>
            <Col xs={6} sm={4} md={3}>
              <label>{I18n.t('consumers.checkin.caregiverName')}</label>
              <div>{caregiver_name}</div>
            </Col>
          </Row>
        </Card>
      </Col>
      <PatientDetailsCureApi
        caregiver={caregiver.caregiver}
        patient={caregiver}
        onRegister={onRegister}
        budTenders={budTenders}
        timezone={timezone}
        expanded={expanded}
        onDetailsExpand={onDetailsExpand}
      />
    </Row>
  );
};

CaregiverDetailsCureApi.propTypes = {
  caregiver: PropTypes.object.isRequired,
  onRegister: PropTypes.func.isRequired,
  budTenders: PropTypes.array,
  timezone: PropTypes.string,
  expanded: PropTypes.object.isRequired,
  onDetailsExpand: PropTypes.func.isRequired,
};

export default CaregiverDetailsCureApi;
