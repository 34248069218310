import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field, FieldArray} from 'redux-form';
import {Button, ButtonToolbar, Col, Row} from 'react-bootstrap';

import ReactSelectInput from '../../../common/form/ReactSelectInput';
import TextInput from '../../../common/form/TextInput';
import PricingWeightsFieldArray from './PricingWeightsFieldArray';

const PricingGroupForm = (props) => {
  const {
    uoms,
    categories,
    disableUomSelection,
    disableCategorySelection,
    pricingClasses,
    pricingWeights,
    onCategoryChange,
    onPricingWeightAdded,
    getFormValue,
    valid,
    fieldsMetadata,
    submitting,
    pristine,
    change,
    handleSubmit,
    showSaveAndReturn,
    taxProfiles,
    isHempFacility
  } = props;

  return (
    <form className='pricing-group-form' onSubmit={handleSubmit} noValidate={true}>
      <div className='limited-width'>
        <Row>
          <Col sm={3}>
            <Field name='name' component={TextInput} props={{
              label: I18n.t('retail.pricingGroup.form.groupName'),
              isRequired: true
            }}/>
          </Col>
          <Col sm={3}>
            <Field name='category_id' component={ReactSelectInput} onChange={onCategoryChange} props={{
              label: I18n.t('retail.pricingGroup.form.productCategory'),
              options: categories,
              valueKey: 'id',
              textKey: 'name',
              isRequired: true,
              disabled: disableCategorySelection
            }}/>
          </Col>
          {isHempFacility &&
            <Col sm={3}>
              <Field name='uom' component={ReactSelectInput} props={{
                label: I18n.t('retail.pricingGroup.form.uom'),
                options: uoms,
                valueKey: 'uom_code',
                isRequired: true,
                disabled: disableUomSelection
              }}/>
            </Col>
          }
          <Col sm={3}>
            <Field name='pricing_class_id' component={ReactSelectInput} props={{
              label: I18n.t('retail.pricingGroup.form.pricingClass'),
              options: pricingClasses,
              valueKey: 'id',
              textKey: 'name',
              isRequired: false
            }}/>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={8} md={5} lg={4}>
            <FieldArray name='pricing_weights' component={PricingWeightsFieldArray} props={{
              change,
              taxProfiles,
              onPricingWeightAdded,
              getFormValue,
              pricingWeights,
              fieldsMetadata: fieldsMetadata.pricing_weights || [],
            }}/>
          </Col>
        </Row>
      </div>
      <Row className='submit-section'>
        <Col xs={12}>
          <div className='button-wrapper'>
            <ButtonToolbar className='float-right'>
              {showSaveAndReturn ?
                <Button variant='primary' type='submit' onClick={() => change('afterSubmit', 'redirect')}
                        disabled={!valid || pristine || submitting}>
                  {I18n.t('retail.pricingGroup.form.saveAndReturn')}
                </Button>
                : null}
              <Button variant='primary' type='submit' onClick={() => change('afterSubmit', '')}
                      disabled={!valid || pristine || submitting}>
                {I18n.t('common.form.save')}
              </Button>
            </ButtonToolbar>
          </div>
        </Col>
      </Row>
    </form>
  );
};

PricingGroupForm.propTypes = {
  uoms: PropTypes.array.isRequired,
  disableUomSelection: PropTypes.bool.isRequired,
  disableCategorySelection: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func,
  onCategoryChange: PropTypes.func.isRequired,
  onPricingWeightAdded: PropTypes.func.isRequired,
  getFormValue: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  pricingClasses: PropTypes.array.isRequired,
  pricingWeights: PropTypes.array.isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  showSaveAndReturn: PropTypes.bool,
  fieldsMetadata: PropTypes.object.isRequired,
  taxProfiles: PropTypes.array.isRequired,
  isHempFacility: PropTypes.bool.isRequired
};

export default PricingGroupForm;
