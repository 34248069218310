import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {I18n} from 'react-redux-i18n';
import {push} from 'react-router-redux';
import debounce from 'lodash.debounce';
import {Button} from 'react-bootstrap';

import * as dataNames from '../../../constants/dataNames';
import * as apiActions from '../../../actions/apiActions';
import * as selectedDataActions from '../../../actions/selectedDataActions';
import {unsetData} from '../../../actions/dataActions';
import {addMessage} from '../../../actions/systemActions';
import {handleSingleSelectedRow} from '../../../actions/helpers/selectedDataHelper';
import {getPreferredMixes, getSelectedPreferredMixes, getSelectedPreferredMixesIds} from '../../../selectors/preferredMixesSelectors';
import defines from './PreferredMixesListingDefines';
import TablePageWrapper from '../../common/grid/TablePageWrapper';
import PageTitle from '../../common/PageTitle';

export class PreferredMixesListingPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);

    const columns = defines.tableColumns;
    const searchString = '';
    const tabs = {};
    tabs['empty'] = {
      columns: [],

    };
    tabs['active'] = {
      columns: columns.concat([{
        name: '',
        hidden: false,
        width: '80px',
        dataId: 'id',
        dataSort: false,
        formatter: (id, row) => (
          <Button type='button' variant='primary' onClick={(event) => this.inactivatePreferredMix(event, id)}>
            {I18n.t('ei.common.inactivate')}
          </Button>
        ),
      }])
    };
    tabs['inactive'] = {
      columns: columns.concat([{
        name: '',
        hidden: false,
        width: '80px',
        dataId: 'id',
        dataSort: false,
        formatter: (id, row) => ( //eslint-disable-line react/no-multi-comp
          <Button type='button' variant='primary' onClick={(event) => this.activatePreferredMix(event, id)}>
            {I18n.t('ei.common.activate')}
          </Button>
        ),
      }])
    };
    this.state = {
      searchString,
      selectedPreferredMixes: [],
      tabs,
      tableReady: false,
      costings: [],
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.switchTab = this.switchTab.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.reload = this.reload.bind(this);
    this.editPreferredMix = this.editPreferredMix.bind(this);
    this.activatePreferredMix = this.activatePreferredMix.bind(this);
    this.inactivatePreferredMix = this.inactivatePreferredMix.bind(this);
    this.ref = React.createRef();
  }

  componentWillMount() {
    this.props.actions.clearSelectedData(dataNames.preferredMixes);
  }

  componentDidMount() {
    this.reload();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.params.stage !== this.props.params.stage) {
      this.props.actions.clearSelectedData(dataNames.preferredMixes);
      this.reload();
    }
  }

  reload(){
    if(this.ref.current){
      this.ref.current.wrappedInstance.ref.current.debouncedExternalSearch();
    }
  }

  handleSelect (isSelected, rows) {
    this.props.actions.handleSingleSelectedRow(rows, dataNames.preferredMixes, isSelected);
  }

  switchTab(activeTab) {
    const previousTab = this.props.params.stage ? this.props.params.stage : 'active';
    if (previousTab !== activeTab) {
      this.props.actions.unsetData(dataNames.preferredMixes);
      this.setState({tableReady: false});
      if (activeTab === 'inactive') {
        this.props.actions.push('/ei/preferred-mixes/inactive');
      } else {
        this.props.actions.push('/ei/preferred-mixes');
      }
    } else {
      this.reload();
    }
  }

  handleSearch(sort, query = 'matchall', size, start, filter) {
    const self = this;
    const params = {sort, query, size, start, filter, detailed: 1};
    if (this.props.params.stage === 'inactive') {
      params['active'] = 0;
    }

    this.props.actions.getUnpaginatedData('/api/preferred_mixes', dataNames.preferredMixes, null, params,
      (mixes) => {
        let itemMasterIds = [];

        mixes.forEach(mix => {
          itemMasterIds.push(mix.output_item_master_id);
          if (mix.inputs && mix.inputs.length) {
            mix.inputs.forEach(input => {
              itemMasterIds.push(input.item_master_id);
            });
          }
        });

        // get only unique values
        itemMasterIds = itemMasterIds.filter((item, index) => {
          return itemMasterIds.indexOf(item) === index;
        });
        if (itemMasterIds.length) {
          this.props.actions.getDataByPost('/api/item_masters/multiple', {ids: itemMasterIds},
            dataNames.itemMasters, {failed:'products.get.failed'}, {detailed: 'true'},
            (masters) => {
              const itemMastersNamesById = masters.reduce((agregator, itemMaster) =>
                (agregator[(itemMaster.id).toString()] = itemMaster.name) && agregator, {});

              self.props.preferredMixes.forEach((mix) => {
                mix.item_master_name = itemMastersNamesById[(mix.output_item_master_id).toString()] || undefined;
                if (mix.inputs && mix.inputs.length) {
                  mix.inputs.forEach((input, inputIndex) => {
                    mix['ingredient%num'.replace('%num', inputIndex + 1)] = itemMastersNamesById[(input.item_master_id).toString()] || '';
                    mix['quantity%num'.replace('%num', inputIndex + 1)] = input.qty || '';
                  });
                }
              });
              self.setState({tableReady: true});
            }
          );
        } else {
          self.setState({tableReady: true});
        }
      }
    );
  }

  onSearchChange(query) {
    if (!query) {
      this.props.actions.clearSelectedData(dataNames.preferredMixes);
    }
  }

  editPreferredMix() {
    this.props.actions.push(`/ei/preferred-mixes/modify/${this.props.selectedPreferredMixesIds[0]}`);
  }

  activatePreferredMix(event, mixId) {
    event.stopPropagation();
    this.props.actions.putData(
      `/api/preferred_mixes/${mixId}`,
      {active: 1},
      dataNames.preferredMixes,
      {failed: 'ei.preferredMixes.activation.failed', success: 'ei.preferredMixes.activation.succeed'},
      null,
      () => {
        this.reload();
      }
    );
  }

  inactivatePreferredMix(event, mixId) {
    event.stopPropagation();
    this.props.actions.putData(
      `/api/preferred_mixes/${mixId}`,
      {active: 0},
      dataNames.preferredMixes,
      {failed: 'ei.preferredMixes.inactivation.failed', success: 'ei.preferredMixes.inactivation.succeed'},
      null,
      () => {
        this.reload();
      }
    );
  }

  render () {
    const {preferredMixes, params, selectedPreferredMixesIds, dataTotalSize} = this.props;
    const {tabs, tableReady} = this.state;
    const {columns} = params.stage ? tabs[params.stage] : (tabs['active'] ? tabs['active'] : tabs['empty']);

    const mainTabs = [
      {id: 'active', eventKey: 'active', title: 'ei.common.active', actions: [
        {id: 'createPreferredMix', path: '/ei/preferred-mixes/create', text: 'ei.preferredMixes.createMix', glyph: 'plus-sign', requireSelect: false,},
        {id: 'modifyPreferredMix', func: this.editPreferredMix, text: 'ei.preferredMixes.editMix', glyph: 'th-list', requireSelect: true,},
      ],},
      {id: 'inactive', eventKey: 'inactive', title: 'ei.common.inactive', actions: [
        {id: 'createPreferredMix', path: '/ei/preferred-mixes/create', text: 'ei.preferredMixes.createMix', glyph: 'plus-sign', requireSelect: false,},
      ],},
    ];

    return (
      <div>
        <PageTitle primaryText={I18n.t('inventory.preferredMixListing.title')}/>
        <TablePageWrapper
          ref={this.ref}
          settingKey='ei-preferred-mixes'
          className = 'preferred-mixes'
          activeTab = {params.stage ? params.stage : 'active'}
          tabs = {mainTabs}
          switchTab = {this.switchTab}
          columns = {columns}
          data = {preferredMixes}
          dataTotalSize = {dataTotalSize}
          selectedRows = {selectedPreferredMixesIds}
          sort='name asc'
          handleSelect = {this.handleSelect}
          hideScanSearch = {true}
          hideExport = {true}
          externalSearch = {debounce(this.handleSearch, 100)}
          bstProps = {{
            selectRow: {
              mode: params.stage === 'inactive' ? 'none' : 'radio',
              selected: selectedPreferredMixesIds,
              onSelect: (row, isSelected) => this.handleSelect(isSelected, [row]),
            },
            options : Object.assign({
              onSearchChange: this.onSearchChange,
              defaultSortName: 'name',
              defaultSortOrder: 'asc',
            }, tableReady ?  {} : {noDataText: I18n.t('general.loading'),}),
          }}
          noSelectionMode={params.stage === 'inactive'}
          pageSizeList={[5, 10, 20, 50]}
          autoRefresh = {10000}
        />
      </div>
    );
  }
}

PreferredMixesListingPage.propTypes = {
  preferredMixes: PropTypes.array.isRequired,
  selectedPreferredMixes: PropTypes.array.isRequired,
  selectedPreferredMixesIds: PropTypes.array.isRequired,
  dataTotalSize: PropTypes.number,
  actions: PropTypes.shape({
    clearSelectedData: PropTypes.func.isRequired,
    getDataByPost: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    handleSingleSelectedRow: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    putData: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired,
  }),
  params: PropTypes.object.isRequired
};

function mapStateToProps(state){
  const preferredMixes = getPreferredMixes(state);
  return {
    preferredMixes,
    selectedPreferredMixes: getSelectedPreferredMixes(state),
    selectedPreferredMixesIds: getSelectedPreferredMixesIds(state),
    dataTotalSize: preferredMixes && preferredMixes.length || 0,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, selectedDataActions, {handleSingleSelectedRow, addMessage, push, unsetData});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (PreferredMixesListingPage);
