/* eslint-disable import/no-named-as-default*/
import {reduxForm} from 'redux-form';
import {TRANSFER_CASH_FORM} from '../../../constants/forms';
import validate from './validate';
import TransferCashForm from './TransferCashForm';

const TransferCashFormWrapper = reduxForm({
  form: TRANSFER_CASH_FORM,
  enableReinitialize: true,
  validate}
)(TransferCashForm);

export default TransferCashFormWrapper;

