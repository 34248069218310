import React from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';
import {Button, Col, Form, FormControl, Row} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import {INVENTORY_AUDIT_FORM} from '../../constants/forms';
import ScanPackage from './ScanPackage';

export const InventoryAuditForm = (props) => {
  const {
    locations,
    handleChange,
    handlePackageSearch,
    handleSubmit,
    productSearch,
    getFormValue,
    packageCodeRef,
    locationRef,
    productRef,
    countRef,
    notesRef,
    allowSubmit
  } = props;

  const location = getFormValue('location');
  const product = getFormValue('product');

  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter' && allowSubmit) {
      handleSubmit(e, 'next');
    }
  };

  return (
    <form id='audit-form'>
      <Row>
        <Col md={4} className='form-group'>
          <Form.Label>{I18n.t('inventory.audit.form.location')}</Form.Label>
          <FormControl
            name='location'
            as='select'
            defaultValue=''
            placeholder={I18n.t('inventory.audit.form.selectLocation')}
            onChange={handleChange}
            disabled={location}
            ref={locationRef}
          >
            <option value='' disabled>{I18n.t('inventory.audit.form.selectLocation')}</option>
            {locations.map((location, key) => {
              return (<option
                value={location.id} key={key}>{location.name}</option>);
            })}
          </FormControl>
        </Col>
      </Row>
      <Row>
        <Col md={4} className='form-group'>
          <ScanPackage
            handleChange={handleChange}
            handleEnterClick={handlePackageSearch}
            inputDisabled={!location}
            buttonDisabled={!location || productSearch}
            innerRef={packageCodeRef}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4} className='form-group'>
          <Form.Label>{I18n.t('inventory.audit.form.product')}</Form.Label>
          <FormControl
            name='product'
            value={product}
            placeholder={I18n.t('inventory.audit.form.product')}
            disabled={true}
            ref={productRef}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4} className='form-group'>
          <Form.Label>{I18n.t('inventory.audit.form.count')}</Form.Label>
          <FormControl
            as='input'
            type='number'
            name='count'
            disabled={!product}
            onChange={handleChange}
            onKeyDown={handleOnKeyDown}
            ref={countRef}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4} className='form-group'>
          <Form.Label>{I18n.t('inventory.audit.form.notes')}</Form.Label>
          <FormControl
            as='textarea'
            type='text'
            rows={3}
            name='notes'
            disabled={!product}
            onChange={handleChange}
            onKeyDown={handleOnKeyDown}
            ref={notesRef}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4} className='audit-buttons'>
          <Button
            className='btn btn-primary'
            disabled={!allowSubmit}
            onClick={(e) => handleSubmit(e, 'next')}
          >
            {I18n.t('inventory.audit.button.scanAnotherItem')}
          </Button>
          <Button
            className='btn btn-success'
            disabled={!allowSubmit}
            onClick={(e) => handleSubmit(e, 'complete')}
          >
            {I18n.t('inventory.audit.button.completeAudit')}
          </Button>
        </Col>
      </Row>
    </form>
  );
};

InventoryAuditForm.propTypes = {
  locations: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  handlePackageSearch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  productSearch: PropTypes.bool.isRequired,
  getFormValue: PropTypes.func.isRequired,
  packageCodeRef: PropTypes.object.isRequired,
  locationRef: PropTypes.object.isRequired,
  productRef: PropTypes.object.isRequired,
  countRef: PropTypes.object.isRequired,
  notesRef: PropTypes.object.isRequired,
  allowSubmit: PropTypes.bool.isRequired
};

const mapStateToProps = (state, props) => {};

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: INVENTORY_AUDIT_FORM
  })
)(InventoryAuditForm);
