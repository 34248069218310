import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { I18n } from 'react-redux-i18n';
import { Button } from 'react-bootstrap';

const TotalsButtons = ({
  order,
  orderType,
  meetsComplianceLimits,
  isOrderFulfilled,
  goToPage,
  validCureLimits,
  fulfillment_method,
  delivery_address_id,
  onSubmit,
  handleSubmit,
  allowOrderUpdate
}) => {
  //openAssignDriver, fillOrder, were included - are they used elsewhere?
  if (orderType === '') return null;
  const enablePayment = fulfillment_method !== 'delivery' || delivery_address_id;
  const warning_min_limit = get(order, 'limit_check_response.warnings.min_limits');
  const isLocked = order.locked;

  return (
    <div style={{ width: '100%' }}>
      <div>
        {!isLocked &&
          <Button
            style={{ width: '49%', float: 'left', margin: '0' }}
            variant='primary'
            disabled={
              !(meetsComplianceLimits && isOrderFulfilled && validCureLimits && enablePayment && allowOrderUpdate) || warning_min_limit
            }
            onClick={handleSubmit((values) => {
              onSubmit(values).then(() => goToPage('payment'));
            })}
          >
            {I18n.t('cart.totals.paymentAndPrinting')}
          </Button>
        }
        {orderType === 'in_store' ? null : (
          <Button
            style={{ width: '49%', float: 'right', margin: '0' }}
            variant='primary'
            disabled={!(meetsComplianceLimits && isOrderFulfilled)}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              if (orderType === 'pickup') return goToPage(`${orderType}_queue`);
              goToPage(orderType);
            }}
          >
            {orderType === 'pickup' ? I18n.t('cart.totals.toQueue') : I18n.t('cart.totals.outForDelivery')}
          </Button>
        )}
        <div style={{ clear: 'both' }} />
      </div>
    </div>
  );
};

TotalsButtons.propTypes = {
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  orderType: PropTypes.string,
  openAssignDriver: PropTypes.func.isRequired,
  fillOrder: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  meetsComplianceLimits: PropTypes.bool.isRequired,
  isOrderFulfilled: PropTypes.bool.isRequired,
  goToPage: PropTypes.func,
  validCureLimits: PropTypes.bool,
  fulfillment_method: PropTypes.string,
  delivery_address_id: PropTypes.number,
  order: PropTypes.object,
  allowOrderUpdate: PropTypes.bool
};

export default TotalsButtons;
