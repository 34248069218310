import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import {Form, Col, Row} from 'react-bootstrap';

import AddItem from '../../../../common/form/AddItem';
import NumericInput from '../../../../common/form/NumericInput';
import ReactSelectInput from '../../../../common/form/ReactSelectInput';

const options = [
  {text: 'Option 1', value: '1'},
  {text: 'Option 2', value: '2'}
];

const RemovePointsFieldArray = ({fields: {map, push, remove, length}}) => (
  <Row className='input-row remove-points'>
    <Col xs={12} className='form-horizontal layout-row layout-wrap layout-align-start-center'>
      <Form.Label>{I18n.t('retail.rewardSetup.removePoints')}</Form.Label>
      {map((itemName, itemIndex) => (
        <div className='layout-row item' key={itemName}>
          {itemIndex > 0 && <Form.Label>{I18n.t('retail.rewardSetup.or')}</Form.Label>}
          <Field name={`${itemName}.count`} component={NumericInput}/>
          <Field name={`${itemName}.option`} component={ReactSelectInput} props={{
            options
          }}/>
        </div>
      ))}
      <div className='layout-row layout-align-start-center'>
        <AddItem
          length={length}
          addAction={() => push({})}
          removeAction={() => remove(length - 1)}/>
      </div>
    </Col>
  </Row>
);

RemovePointsFieldArray.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    length: PropTypes.number.isRequired
  }).isRequired
};

export default RemovePointsFieldArray;
