import {createSelector} from 'reselect';
import * as dataNames  from '../../constants/dataNames';
import * as itemNames  from '../../constants/itemNames';
import {NONE} from '../../constants/integrations';

const getThirdPartyIntegrations = state => state[itemNames.activeThirdPartyIntegrations];
const getAllThirdPartyIntegrations = state => state[dataNames.thirdPartyIntegrations];
export const getIntegrationSettings = state => state[itemNames.integrationSettings];
export const getOrgIntegrationSettings = state => state[itemNames.orgIntegrationSettings];
export const getIntegrationMapping = state => state[dataNames.integrationMapping];
export const getTransactionsIntegrators = state => state[dataNames.transactionsIntegrators];

export const getStateIntegrator = createSelector([getIntegrationSettings],  getStateIntegrationImplementation);

export const hasStateIntegrator = createSelector(
  [getStateIntegrator],
  integrator => integrator !== NONE
);

export const hasThirdPartyIntegrator = createSelector([getThirdPartyIntegrations], thirdPartyIntegrations => {
  return thirdPartyIntegrations && Object.keys(thirdPartyIntegrations).length > 0;
});

export const isNormal = createSelector([hasStateIntegrator], (integrator) => {
  return !integrator;
});

export const transactionsIntegratorsWitoutInactive = createSelector(
  [getTransactionsIntegrators, getAllThirdPartyIntegrations],
  (transactionsIntegrators, allThirdPartyIntegrations) => {
    return transactionsIntegrators.filter(({integrator}) => {
      const integrator_info = allThirdPartyIntegrations.find(item => item.name === integrator);
      return !(integrator_info && integrator_info.hasOwnProperty('active') && !integrator_info.active);
    });
  }
);

export function getStateIntegrationImplementation(settings) {
  return settings && settings.integration_state_integrator && settings.integration_state_integrator.value;
}
