import React from 'react';
import PropTypes from 'prop-types';
import {CardGroup} from 'react-bootstrap';
import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {reduxForm, getFormValues} from 'redux-form';
import SimplePagination from '../../common/SimplePagination';
import Toolbar from './Toolbar';
import Product from './Product'; // eslint-disable-line import/no-named-as-default
import {getProductsPageData} from '../../../selectors/ordersSelectors';
import {PRODUCT_MENU} from '../../../constants/forms';

class ProductGrid extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    const{searchString, sort, handleSearch, handleSort, onSubmit, getCatalogItemMasterTypeById,
      isCure, subcategoryFormOptions, page, activePage, switchPage, itemMasters, disablingAddProduct, formValues, change,
      newProductMasterIsEnabled, addToCartEnabled} = this.props;

    return (
      <div className='table-page'>
        <Toolbar
          searchString = {searchString}
          sort = {sort}
          updateSort = {handleSort}
          updateSearch = {handleSearch}
        />
        <CardGroup>
          {itemMasters.map((itemMaster, index) => {
            const itemMasterType = getCatalogItemMasterTypeById(itemMaster) || '';
            const formOptions = subcategoryFormOptions[itemMaster.subcategory_id] || [];
            return (
              <Product
                key={index}
                change={change}
                itemMaster={itemMaster}
                itemMasterType={itemMasterType}
                form={itemMaster.id}
                handleSubmit={onSubmit}
                isCure={isCure}
                formOptions={formOptions}
                disablingAddProduct={disablingAddProduct}
                qtyInput={formValues && get(formValues, `quantity-input${itemMaster.id}`, '')}
                newProductMasterIsEnabled={{newProductMasterIsEnabled}}
                addToCartEnabled={addToCartEnabled}
              />);
          })}
        </CardGroup>
        {page.pagesCount > 0 &&
        <div className='flex layout-row layout-align-space-between-center page-info'>
          <span>
            {I18n.t('grid.pagination', {start: page.start , to: page.end , total: page.total})}
          </span>
          {page.pagesCount > 1 &&
          <SimplePagination
            bsSize='medium'
            items={page.pagesCount}
            activePage={activePage}
            onSelect={switchPage}
            maxButtons={5}
            first={true}
            last={true}
            next={true}
            prev={true}/>}
        </div>}
      </div>
    );
  }
}

ProductGrid.propTypes = {
  searchString: PropTypes.string.isRequired,
  sort: PropTypes.string.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleSort: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  itemMasters: PropTypes.array.isRequired,
  disablingAddProduct: PropTypes.bool.isRequired,
  pageWidth: PropTypes.number,
  getCatalogItemMasterTypeById: PropTypes.func.isRequired,
  subcategoryFormOptions: PropTypes.object.isRequired,
  isCure: PropTypes.bool,
  page: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
    pagesCount: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired
  }),
  activePage: PropTypes.number,
  switchPage: PropTypes.func,
  dataName: PropTypes.string,
  newProductMasterIsEnabled: PropTypes.bool.isRequired,
  addToCartEnabled: PropTypes.bool.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    formValues : getFormValues(PRODUCT_MENU)(state),
    page: getProductsPageData(state, {...ownProps, mode: 'customer', name: ownProps.dataName})
  };
}

const WrappedProductGrid = reduxForm({form: PRODUCT_MENU})(ProductGrid);

export default connect(mapStateToProps)(WrappedProductGrid);
