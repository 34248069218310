import {createSelector} from 'reselect';
import * as itemNames from '../constants/itemNames';
import {getIntegrationState} from './integration/integrationSelectors';
import {state, tax, wholesale, county, AU_state, AU_wholesale, dea_number} from '../constants/fileTypes';
import * as FACILITY_TYPES from '../constants/facilityTypes';

export const getImage = (state) => state[itemNames.image];
export const getInsuranceDocument = (state) => state[itemNames.insuranceDocument];
export const getCertificateDocument = (state) => state[itemNames.certificateOfAnalysisDocument];

/**
 * Returning an appropriate list of file types for Organization profile page
 * @param facility
 * @returns {string[]}
 */
export const getLicenseDocsFilesForFacility = createSelector(
  [getIntegrationState],
  (integrationState) => (facility) => {
    if (facility.country_code === 'AU') {
      return [AU_state, AU_wholesale];
    }

    const listOfFileTypes = [state, wholesale, tax, county];

    if (_isNeededToAddDEANumber(facility, integrationState)) {
      listOfFileTypes.push(dea_number);
    }

    return listOfFileTypes;
  });

/**
 * Checking that DEA Number field is needed to add to Organization Profile Form
 * @param facility
 * @param integrationState
 * @returns {*|boolean}
 * @private
 */
const _isNeededToAddDEANumber = (facility, integrationState) => (
  (integrationState.isOhMetrc) &&
  (facility.type === FACILITY_TYPES.RETAIL) &&
  (facility.province_code === 'OH')
);
