import React from 'react';
import {Field, FormSection} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Form, Card, Table, Row, Col} from 'react-bootstrap';
import get from 'lodash.get';
import TextInput from '../../common/form/TextInput';
import ProductSearch from '../../common/scan-searches/ProductSearch';

const InputProductPanel = (props) => {

  const { item, onSearchComplete } = props;

  return (
    <FormSection name='item'>
      <Card>
        <Card.Header>
          <h3 className='panel-title'>
            {I18n.t('packagingJob.form.inputProduct')}
          </h3>
        </Card.Header>
        <Table className='inputs-table'>
          <thead>
          <tr>
            <th><Form.Label>{I18n.t('packagingJob.form.inputPackageId')}</Form.Label></th>
            <th><Form.Label>{I18n.t('ei.processing.form.productName')}</Form.Label></th>
            <th><Form.Label>{I18n.t('ei.processing.form.vendor')}</Form.Label></th>
            <th><Form.Label>{I18n.t('ei.processing.form.strain')}</Form.Label></th>
            <th><Form.Label>{I18n.t('ei.processing.form.availableWeight')}</Form.Label></th>
            <th><Form.Label>{I18n.t('ei.processing.form.weight')}</Form.Label></th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <Field name='package_code' component={TextInput} props={{
                value: item && item.package_code,
                disabled: true,
              }}/>
            </td>
            <td>
              <Field name='item_name' component={TextInput} props={{
                value: item && (item.item_name || get(item, 'item_master.name')),
                disabled: true,
              }}/>
            </td>
            <td>
              <Field name='producer_name' component={TextInput} props={{
                value: item && item.producer_name,
                disabled: true,
              }}/>
            </td>
            <td>
              <Field name='strain_name' component={TextInput} props={{
                value: item && item.source_strain,
                disabled: true,
              }}/>
            </td>
            <td>
              <Field name='qty_available' component={TextInput} props={{
                value: item && item.qty_available,
                rightAddon: <span>{item.uom || item.uom_display}</span>,
                disabled: true,
              }}/>
            </td>
            <td>
              <Field name='qty' component={TextInput} props={{
                value: item && item.qty,
                rightAddon: <span>{item.uom || item.uom_display}</span>,
                disabled: true,
              }}/>
            </td>
          </tr>
          <tr>
            <td colSpan={6}>
              <Row>
                <Col xs={6} md={3}>
                  <ProductSearch
                    label={I18n.t('ei.processing.form.searchProduct')}
                    onSearchComplete={onSearchComplete}
                  />
                </Col>
              </Row>
            </td>
          </tr>
          </tbody>
        </Table>
      </Card>
    </FormSection>
  );
}

InputProductPanel.propTypes = {
};

export default InputProductPanel;
