import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import {Field} from 'redux-form';
import get from 'lodash.get';
import PackageSelection from './PackageSelection'; //eslint-disable-line import/no-named-as-default
import InternationalQuantityByUomInput from '../../../common/form/InternationalQuantityByUomInput';
import TextInput from '../../../common/form/TextInput';
import AddItem from '../../../common/form/AddItem';
import ReactSelectInput from '../../../common/form/ReactSelectInput';

const BulkPackages = (props) => {

  const {
    fields, packages, change, isApplyStorageLocation, locations, isEditMode, isComplete, isCancelled, itemMaster,
    integrationState, transferId, disposalReasonOptions, line, inventoryLocked, formName, isMedicated, uom, formData,
    itemMasterIsMedicated,
  } = props;

  const bulkProductsAlwaysTracked = 1;
  const {isBiotrack} = integrationState;
  const disablePackageField = !isMedicated && !get(itemMaster, 'inventory_attributes.lot_tracked');
  const isIngredient = get(itemMaster, 'inventory_attributes.is_ingredient', 0);

  const medicatedTransfersRequireEntireQuantity = get(formData, 'medicatedTransfersRequireEntireQuantity', false);
  const entireQtyRequired = Boolean(medicatedTransfersRequireEntireQuantity && itemMasterIsMedicated);

  const locationsById = {};
  (locations || []).forEach(location => locationsById[location.value] = location);

  return (
    <Row>
      {fields.map((fieldName, index) => {
        const inventoryLine = fields.get ? fields.get(index) : {};
        const itemId = get(inventoryLine, 'inventory_item_id', 0);
        const packageForItemId = packages.find((pkg) => pkg.id === itemId);
        if(isIngredient && packageForItemId){ // Ingredients get here through internal transfers and display item number if not lot tracked
          packageForItemId.package_code = get(itemMaster, 'item_number');
        }

        return (
          <PackageSelection
            key={index}
            isApplyStorageLocation={isApplyStorageLocation}
            locations={locations}
            locationsById={locationsById}
            packages={packages}
            fieldName={fieldName}
            change={change}
            fields={fields}
            index={index}
            isEditMode={isEditMode}
            isComplete={isComplete}
            isCancelled={isCancelled}
            getFormValues={() => fields.get(index)}
            isLotTracked={bulkProductsAlwaysTracked}
            transferId={transferId}
            formName={formName}
            isNonMedicatedNonLotTracked={disablePackageField}
            integrationState={integrationState}
          >
            <Col xs={6} md={3} lg={2}>
              <InternationalQuantityByUomInput
                name={`${fieldName}.qty`}
                disabled={isComplete || isCancelled || isMedicated && medicatedTransfersRequireEntireQuantity || entireQtyRequired}
                readOnly={isBiotrack && isMedicated}
                allowNegativeNumber={false}
                label={I18n.t('purchaseOrders.form.sentNet')}
                uom={uom}
              />
            </Col>
            <Col xs={6} md={3} lg={2}>
              <InternationalQuantityByUomInput
                name={`${fieldName}.gross_weight`}
                disabled={isComplete || isCancelled || isMedicated && medicatedTransfersRequireEntireQuantity || entireQtyRequired}
                allowNegativeNumber={false}
                label={I18n.t('purchaseOrders.form.sentGross')}
                uom={uom}
              />
            </Col>
            {integrationState.isMetrc ? <Col xs={6} md={4} lg={3}>
              <Field
                name={`${fieldName}.state_integration_tracking_id`}
                component={TextInput}
                props={{
                  label: I18n.t('common.form.trackingId'),
                  readOnly: true
                }}
              />
            </Col> : null}
            {line && line.is_waste_disposal ?
              <Col xs={12}>
                <Row>
                  <Col xs={6} md={3} lg={2}>
                    <Field
                      name={`${fieldName}.disposal_reason`}
                      component={ReactSelectInput}
                      props={{
                        options: disposalReasonOptions,
                        label: I18n.t('salesOrders.form.disposalReason'),
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              :
              null
            }
          </PackageSelection>
        );
      })}
      <AddItem
        addAction={() => fields.push({item_master_id: itemMaster.id})}
        removeAction={fields.pop}
        length={fields.length}
        addDisabled={inventoryLocked || entireQtyRequired}
        showMinus={!inventoryLocked && !entireQtyRequired}
      />
    </Row>
  );
};

BulkPackages.propTypes = {
  fields: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  packages: PropTypes.array,
  isApplyStorageLocation: PropTypes.bool,
  locations: PropTypes.array,
  isEditMode: PropTypes.bool,
  inventoryLocked: PropTypes.bool,
  isMedicated: PropTypes.bool,
  isComplete: PropTypes.bool.isRequired,
  isCancelled: PropTypes.bool,
  integrationState: PropTypes.object.isRequired,
  transferId: PropTypes.string,
  line: PropTypes.object,
  disposalReasonOptions: PropTypes.array.isRequired,
  formName: PropTypes.string.isRequired,
  itemMaster: PropTypes.object.isRequired,
  uom: PropTypes.string.isRequired,
  itemMasterIsMedicated: PropTypes.bool,
  formData: PropTypes.object.isRequired,
};

BulkPackages.defaultProps = {
  isEditMode: false
};

export default BulkPackages;
