import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {Button} from 'react-bootstrap';
import omit from 'lodash.omit';
import { bindActionCreators } from 'redux';
import {push} from 'react-router-redux';

import TablePageWrapper from '../../common/grid/TablePageWrapper';
import PageTitle from '../../common/PageTitle';
import InProgressOverlay from '../../common/InProgressOverlay';
import * as dataNames from '../../../constants/dataNames';
import {getSelectedEquipmentIds, fillEquipmentDataForListing} from '../../../selectors/equipmentSelectors';
import {getUnpaginatedData, putData} from '../../../actions/apiActions';
import {handleComplexSelectRow} from '../../../actions/helpers/selectedDataHelper';
import {getUomByCode} from '../../../selectors/uomsSelectors';
import {clearSelectedData} from '../../../actions/selectedDataActions';
import SingleActionColumn from '../../common/grid/columns/SingleActionColumn';
import InternationalCurrencyStatic from '../../common/form/InternationalCurrencyStatic';
import InternationalDecimalStatic from '../../common/form/InternationalDecimalStatic';

export class EquipmentListingPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.loadTabs = this.loadTabs.bind(this);
    this.filter = this.filter.bind(this);
    this.onTabChanged = this.onTabChanged.bind(this);
    this.reload = this.reload.bind(this);
    this.isActiveTab = this.isActiveTab.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.switchTab = this.switchTab.bind(this);
    this.renderActionButton = this.renderActionButton.bind(this);
    this.activateEquipment = this.activateEquipment.bind(this);
    this.deactivateEquipment = this.deactivateEquipment.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.modifyEquipment = this.modifyEquipment.bind(this);
    this.ref = React.createRef();
    const commonColumns = [
      {
        name: I18n.t('equipment.listing.vendor'),
        dataId: 'vendors',
        hidden: false,
        dataSort: true,
        width: '20%',
      },
      {
        name: I18n.t('equipment.listing.modelName'),
        dataId: 'model_name',
        hidden: false,
        dataSort: true,
        width: '10%',
      },
      // {
      //   name: I18n.t('equipment.listing.onHandQuantity'),
      //   dataId: 'name',
      //   hidden: false,
      //   dataSort: true,
      //   width: '150px',
      //   formatter: (id, row) => (
      //     <Button type='button' variant='primary' onClick={(event) => {}}>
      //       {I18n.t('equipment.listing.adjustButton')}
      //     </Button>
      //   )
      // },
      {
        name: I18n.t('equipment.listing.lifeExpectancy'),
        dataId: 'life_expectancy_sec',
        hidden: false,
        dataSort: true,
        width: '10%',
        formatter: (cell, row) => {
          return `${row.life_expectancy_base} ${row.life_expectancy_uom_display}`;
        }
      },
      {
        name: I18n.t('equipment.listing.avgPurchasePrice'),
        dataId: 'purchase_price',
        hidden: false,
        dataSort: true,
        width: '10%',
        formatter: (cell, row) => <InternationalCurrencyStatic>{cell}</InternationalCurrencyStatic>,
      },
      {
        name: I18n.t('equipment.listing.energyUsage'),
        dataId: 'kwh_day',
        hidden: false,
        dataSort: true,
        width: '10%',
        /* eslint-disable react/no-multi-comp */
        formatter: (cell, row) => <InternationalDecimalStatic suffix=' kw/h' fractionPartSize={2}>{cell}</InternationalDecimalStatic>
      },
      {
        hidden: false,
        dataSort: false,
        width: '10%',
        formatter: SingleActionColumn({
          label: 'equipment.listing.edit',
          action: this.modifyEquipment
        }),
        columnClassName: 'actions-column',
      }
    ];
    const activeColumns = {
      name: '',
      hidden: false,
      width: '10%',
      dataSort: false,
      dataId: 'id',
      status: 'active',
      /* eslint-disable react/no-multi-comp */
      formatter: (id, row) => (
        <Button type='button' variant='primary' onClick={(event) => {this.deactivateEquipment(event, id);}}>
          {I18n.t('equipment.listing.inactivateButton')}
        </Button>
      )
    };
    const inactiveColumns = {
      name: '',
      hidden: false,
      width: '10%',
      dataId: 'id',
      dataSort: false,
      formatter: (id, row) => ( //eslint-disable-line react/no-multi-comp
        <Button type='button' variant='primary' onClick={(event) => {this.activateEquipment(event, id);}}>
          {I18n.t('equipment.listing.activateButton')}
        </Button>
      )
    };

    this.state = {
      commonColumns,
      activeColumns,
      inactiveColumns,
      tabsLoaded: false,
      activeStatus: 'active',
      activeTab: ''
    };
    this.props.params.type = this.props.params.type ? this.props.params.type : 'extraction_machine';
  }

  componentWillMount() {
    const {status, type} = this.props.params;
    this.setState({tabsLoaded: false,
      activeStatus: (status === 'inactive') ? 'inactive' : 'active',
      activeTab: type});
    this.props.actions.getUnpaginatedData(
      '/api/equipment',
      dataNames.equipmentItems,
      null,
      {detailed: 1}
    );
    this.props.actions.getUnpaginatedData('/api/uoms', dataNames.uoms);
    this.props.actions.getUnpaginatedData('/api/partners/details', dataNames.partners);
    this.loadTabs();
  }

  componentWillReceiveProps(nextProps) {
    //Tab has been changed
    if(this.state.tabsLoaded && (nextProps.params.type !== this.props.params.type || nextProps.params.status !== this.props.params.status)) {
      this.setState({
        activeTab: nextProps.params.type,
        activeStatus: nextProps.params.status,
        tabsLoaded: false
      }, this.renderActionButton);
    }
  }

  loadTabs() {
    this.props.actions.getUnpaginatedData(
      '/api/equipment/equipment_types',
      dataNames.equipmentTypes,
      {failed: 'equipment.listing.failedEquipmentTypes'})
      .then(this.renderActionButton);
  }

  modifyEquipment(event, row) {
    event.stopPropagation();
    this.props.actions.push(`/equipment/modify/${row.id}`);
  }

  renderActionButton() {
    const {equipmentTypes} = this.props;
    const actions = [
      {
        id: 'createEquipment',
        path: '/equipment/create',
        text: 'equipment.listing.createEquipmentButton',
        requireSelect: false,
      },
      {
        id: 'inactiveEquipment',
        path: `/equipment/inactive/${this.state.activeTab}`,
        text: 'equipment.listing.viewInactiveButton',
        requireSelect: false,
        active: true,
      },
      {
        id: 'activeEquipment',
        path: `/equipment/active/${this.state.activeTab}`,
        text: 'equipment.listing.viewActiveButton',
        requireSelect: false,
        active: false,
      },
    ];
    this.setState(
      {
        tabs: equipmentTypes
          .map(type => ({
            id: `${type.code}Tab`,
            eventKey: type.code,
            title: type.name,
            actions: actions
              .filter(action => action.active === undefined || action.active === this.isActiveTab())
              .map(action => omit(action, ['active'])),
          }))
      },
      () => {
        this.setState({tabsLoaded: true});
        this.switchTab(this.props.params.type) || this.onTabChanged();
      }
    );
  }

  activateEquipment(event, equipmentId) {
    event.stopPropagation();
    this.props.actions.putData(
      `/api/equipment/${equipmentId}`,
      {active: 1},
      null,
      {failed: 'equipment.listing.activateFailed', success: 'equipment.listing.activateSuccess'},
      null,
      () => {
        this.reload();
      }
    );
  }

  deactivateEquipment(event, equipmentId) {
    event.stopPropagation();
    this.props.actions.putData(
      `/api/equipment/${equipmentId}`,
      {active: 0},
      null,
      {failed: 'equipment.listing.deactivateFailed', success: 'equipment.listing.deactivateSuccess'},
      null,
      () => {
        this.reload();
      }
    );
  }

  switchTab(type) {
    const {tabs} = this.state;
    const isExistingTab = tabs && tabs.some(tab => tab.eventKey === type);
    if (this.props.params.type !== type) {
      this.props.actions.push(`/equipment/${this.state.activeStatus}${isExistingTab ? `/${type}` : ''}`);
    }
    return false;
  }

  onTabChanged(type) {
    this.setState(
      (state) => {
        const {activeColumns, commonColumns, inactiveColumns} = state;
        return {
          columns: this.isActiveTab() ? commonColumns.concat(activeColumns) : commonColumns.concat(inactiveColumns),
        };
      },
      () => {
        this.filter(type);
      }
    );
  }

  filter(type) {
    // wait SOLR
    // const {equipmentTypes} = this.props;
    // const equipmentType = type || this.state.activeTab;
    // const activeType = equipmentTypes.find(type => type.code === equipmentType);
    // this.ref.current.filter(`active: ${this.state.activeStatus === 'active' ? 1 : 0} AND equipment_type_code: ${activeType.code}`);
    this.reload();
  }

  reload() {
    if(this.ref.current){
      this.ref.current.wrappedInstance.ref.current.debouncedExternalSearch();
    }
  }

  isActiveTab() {
    return this.state.activeStatus !== 'inactive';
  }

  handleSelect(isSelected, rows) {
    this.props.actions.handleComplexSelectRow(rows, dataNames.equipmentItems, isSelected);
  }

  handleSearch (sort, query = 'matchall', size, start, filter) {
    const params = {sort, query, size, start, filter};
    params['detailed'] = 1;
    if (this.state.activeStatus === 'inactive') {
      params['active'] = 0;
    }
    this.props.actions.getUnpaginatedData('/api/equipment', dataNames.equipmentItems, null, params);
  }

  onSearchChange(query) {
    if (!query) {
      this.props.actions.clearSelectedData(dataNames.equipmentItems);
    }
  }

  render() {
    const {columns, tabs, activeTab} = this.state;
    const {equipmentItems, selectedEquipmentIds, dataTotalSize} = this.props;
    return (
      <div>
        <PageTitle primaryText={I18n.t('equipment.listing.title')}/>
        {columns ?
          <TablePageWrapper
            ref={this.ref}
            settingKey='equipment'
            columns={columns}
            tabs={tabs}
            activeTab={activeTab}
            switchTab={this.switchTab}
            data={equipmentItems}
            handleSelect={this.handleSelect}
            selectedRows={selectedEquipmentIds}
            hideScanSearch={true}
            dataTotalSize={dataTotalSize}
            hideExport={true}
            external={false}
            sort='vendors desc'
            externalSearch={this.handleSearch}
            pageSizeList={[5, 10, 20 ,50]}
            className='finished-product-page'
            bstProps = {{
              options : {
                onSearchChange: this.onSearchChange,
                defaultSortName: ['model_name', 'vendors'],
                defaultSortOrder: ['asc', 'asc']
              },
            }}
          /> :
          <InProgressOverlay isActive={!this.state.tabsLoaded} message={I18n.t('equipment.listing.loading')}/>
        }
      </div>
    );
  }
}
EquipmentListingPage.propTypes = {
  actions: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  equipmentItems: PropTypes.array.isRequired,
  equipmentTypes: PropTypes.array.isRequired,
  selectedEquipmentIds: PropTypes.array.isRequired,
  dataTotalSize: PropTypes.number.isRequired,
  uomDay: PropTypes.object.isRequired,
  partners: PropTypes.array.isRequired
};

function mapStateToProps(state, ownProps) {
  const {equipmentTypes, partners} = state;
  const selectedEquipmentIds = getSelectedEquipmentIds(state);
  const equipmentItems = fillEquipmentDataForListing(state, {type: ownProps.params.type, uom_type: 'time'});
  return {
    selectedEquipmentIds,
    dataTotalSize: equipmentItems && equipmentItems.length || 0,
    equipmentItems,
    equipmentTypes,
    uomDay: getUomByCode(state, {uom_code: 'DAY'}),
    partners
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, {getUnpaginatedData, handleComplexSelectRow, push, putData, clearSelectedData});
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentListingPage);
