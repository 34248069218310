const createPayload = (formValues) => {
  return formValues.taxRates.map((tax) => {
    const hasDelivery = tax.fulfillment_methods.some(m => (m.value || m) === 'delivery');

    const item_subcategories = [];
    tax.categorySubcategories.forEach(cat => {
      if (!cat.apply_to_all_subcategories) {
        cat.subcategories.forEach(subcat => item_subcategories.push({id: subcat.id}));
      }
    });

    const item_categories = [];
    tax.categories.forEach(cat => {
      const categorySubcategories = tax.categorySubcategories.find(catSubs => catSubs.category_id === cat.id);
      const apply_to_all_subcategories = (categorySubcategories && categorySubcategories.apply_to_all_subcategories) ? 1 : 0;
      item_categories.push({id: cat.id, apply_to_all_subcategories});
    });

    return {
      amount: parseFloat(tax.amount) / 100,
      tax_name: tax.tax_name,
      tax_type_code: tax.tax_type_code,
      fulfillment_methods: tax.fulfillment_methods.map(method => method.value || method),
      zipcodes: tax.zipcodes && hasDelivery ? tax.zipcodes.replace(/,\s*$/, '').split(',') : [],
      item_categories,
      item_subcategories,
      active: tax.is_active ? 1 : 0,
      tax_agency_vendor_name: tax.tax_agency_vendor_name,
      gross_receipts_ok: tax.gross_receipts_ok,
    };
  });
};

export default createPayload;
