import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import {FaDollarSign, FaEuroSign, FaPoundSign} from 'react-icons/fa';
import InternationalNumericInput from './InternationalNumericInput';
import {getCurrencySymbolName, getInternationalNumberFormat} from '../../../selectors/InternationalOperationsSelectors';
import {fieldFormatHelperDecimals, fieldParseHelperDecimals} from '../../../util/internationalHelpers';


const InternationalCurrencyInput = (props) => {

  const {internationalNumberFormat} = props;

  /* eslint-disable react/no-multi-comp */
  const getReactIcon = (symbolName) => {
    if (symbolName === 'CURRENCY_SYMBOL_EURO') {
      return <FaEuroSign/>;
    } else if (symbolName === 'CURRENCY_SYMBOL_POUND') {
      return <FaPoundSign/>;
    } else if (symbolName === 'CURRENCY_SYMBOL_RAND') {
      return <span>R</span>;
    } else if (symbolName === 'CURRENCY_SYMBOL_BAHT') {
      return <span>฿</span>;
    }
    return <FaDollarSign/>;
  };

  // This is how we want it in the store
  const fieldParser = (value, name) => {
    return fieldParseHelperDecimals(value, name, internationalNumberFormat);
  };

  // This is how we want it displayed in the component
  const fieldFormatter = (value, name) => {
    return fieldFormatHelperDecimals(value, name, internationalNumberFormat);
  };

  if (props.props.placeholder !== undefined && props.props.placeholder !== null && isNaN(props.props.placeholder) === false) {
    props.props.placeholder = fieldFormatter(props.props.placeholder);
  }

  return (
    <Field
      parse={fieldParser}
      format={fieldFormatter}
      type='text'
      name={props.name === undefined ? Math.random().toString() : props.name}
      component={InternationalNumericInput}
      props={{
        ...props.props,
        fractionPartSize : 2,
        shouldShowTrailingZeros : true,
        leftAddon : getReactIcon(props.currencySymbolName),
        internationalNumberFormat: props.internationalNumberFormat
      }}
    />
  );
};

InternationalCurrencyInput.defaultProps = {
};

InternationalCurrencyInput.propTypes = {
  props: PropTypes.object,
  name: PropTypes.string,
  currencySymbolName: PropTypes.string.isRequired,
  internationalNumberFormat: PropTypes.string.isRequired,
};

function mapStateToProps(state){
  return {
    currencySymbolName: getCurrencySymbolName(state),
    internationalNumberFormat: getInternationalNumberFormat(state),
  };
}

export default connect(mapStateToProps)(InternationalCurrencyInput);
