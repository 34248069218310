// Workaround of React errors:
// "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node."
//
// This workaround is described here:
// https://github.com/facebook/react/issues/11538#issuecomment-417504600

const removeChildWorkaround = () => {
  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function (child) {
      if (child.parentNode !== this) {
        window.console.error('Cannot remove a child from a different parent.', 'Child:', child, 'Parent:', this);
        return child;
      }
      return originalRemoveChild.apply(this, arguments);
    };
  }
};

export default removeChildWorkaround;