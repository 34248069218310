import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack, push} from 'react-router-redux';
import * as apiActions from '../../../actions/apiActions';
import * as dataActions from '../../../actions/dataActions';
import * as dataNames from '../../../constants/dataNames';
import {getCategoryOptions, getSubCategoryOptions} from '../../../selectors/categorySelectors';
import SubmitSection from '../../common/form/SubmitSection';
import FormWrapper from '../../common/form/FormWrapper';
import LabelStatementsForm from './components/LabelStatementsForm';
import InProgressOverlay from '../../common/InProgressOverlay';

class LabelStatements extends React.PureComponent{
  constructor(props){
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onCategorySelect = this.onCategorySelect.bind(this);
    this.categoryIsSelected = this.categoryIsSelected.bind(this);
    this.onSubCategorySelect = this.onSubCategorySelect.bind(this);
    this.subCategoryIsSelected = this.subCategoryIsSelected.bind(this);
    this.bindStatementsForType = this.bindStatementsForType.bind(this);
    this.onStatementChange = this.onStatementChange.bind(this);
    this.extractCategoryId = this.extractCategoryId.bind(this);
    this.idIsInExtractedIds = this.idIsInExtractedIds.bind(this);
    this.getProductStatements = this.getProductStatements.bind(this);
    this.onToggleDelete = this.onToggleDelete.bind(this);
    this.canSave = this.canSave.bind(this);
    this.goBack = this.goBack.bind(this);

    this.state = {
      selectedCategories: [],
      selectedSubCategories: [],
      statements: {},
      initCall: false,
      extractedIds: {
        health: [],
        ides: [],
        mandated: []
      },
      types: [
        'health',
        'ides',
        'mandated'
      ],
      activeType: 'global',
      inProgressActive: false,
      inProgressMessage: 'Loading',
      messages: {
        globalLoading: I18n.t('productStatements.messages.globalLoading'),
        categoryLoading: I18n.t('productStatements.messages.categoryLoading'),
        subcategoryLoading: I18n.t('productStatements.messages.subcategoryLoading'),
        globalSaving: I18n.t('productStatements.messages.globalSaving'),
        categorySaving: I18n.t('productStatements.messages.categorySaving'),
        subcategorySaving: I18n.t('productStatements.messages.subcategorySaving'),
      },
      lastStatementsCall: {},
      canSave: false,
      saving: false
    };

  }

  componentWillMount(){
    this.props.actions.unsetData(dataNames.categories);
    this.props.actions.unsetData(dataNames.productStatements);
    this.props.actions.getUnpaginatedData('/api/categories', dataNames.categories);
  }

  componentWillReceiveProps(nextProps){
    // Header x-csrf-token is not immediately available if page is refreshed
    if(!this.state.initCall && Object.keys(nextProps.headers).length > 1){
      this.getProductStatements(false, [0]); // get global
      this.setState({initCall: true});
    }
  }

  getActiveType(){
    return (this.state.selectedCategories.length === 0 && this.state.selectedSubCategories.length === 0)
      ? 'global'
      : this.state.selectedSubCategories.length > 0
        ? 'subcategory'
        : 'category';
  }

  getProductStatements(clearStatements, ids){
    const activeType = this.getActiveType();
    if(activeType === 'global') {
      ids = [0];
    }
    const callParams = {type: activeType, ids: ids};
    if(JSON.stringify(callParams) === JSON.stringify(this.state.lastStatementsCall)) return false;
    if(activeType !== this.state.activeType) clearStatements = true;
    if(clearStatements) this.props.actions.setData([], dataNames.productStatements);

    // Anti-pattern setting directly on state without setState; must be immediate and no external dependency. Prevents
    // duplicate calls on return to global when the last category is removed and it had sub categories selected.
    this.state.lastStatementsCall = callParams; //eslint-disable-line react/no-direct-mutation-state

    this.setState({activeType, inProgressActive: true, inProgressMessage: this.state.messages[`${activeType}Loading`]});
    this.props.actions.postData(`/api/statements/${activeType}/get_many`, {ids}, dataNames.productStatements, {}, {}, () => {
      this.setState({inProgressActive: false});
    });
  }

  categoryIsSelected(category){
    const haveCategory = this.state.selectedCategories.find((c) => c.id === category.id);
    return (haveCategory) ? haveCategory : false;
  }

  onCategorySelect(category){
    const categories = this.state.selectedCategories.map((category) => category);
    const haveCategory = this.categoryIsSelected(category);
    let getProductStatements = true;
    if(haveCategory){
      const index = categories.indexOf(haveCategory);
      categories.splice(index, 1);
      this.deselectSubsForDeselectedCategories(category);
      this.removeIdFromExtractedIds(category.id);
      getProductStatements = false;
    } else {
      categories.push(category);
    }

    this.setState({selectedCategories: categories}, () => {
      if(this.state.selectedSubCategories.length === 0 && getProductStatements) {
        this.getProductStatements(categories.length === 1, [category.id]);
      } else {
        if(this.state.selectedCategories.length === 0){
          this.getProductStatements(true, [0]); // get global
        }
      }
    });

  }

  deselectSubsForDeselectedCategories(category){
    const deselectIndexes = [];
    if(this.state.selectedSubCategories.length === 0) return false;
    this.state.selectedSubCategories.forEach((sub, index) => {
      if(sub.category_id === category.id) deselectIndexes.push(index);
    });
    const subs = this.state.selectedSubCategories.map((s) => s);
    deselectIndexes.reverse();
    deselectIndexes.forEach((index) => {
      subs.splice(index, 1);
    });
    this.setState({selectedSubCategories: subs}, () => {
      if(this.state.selectedSubCategories.length === 0){
        const ids = this.state.selectedCategories.map((category) => category.id);
        this.getProductStatements(true, ids);
      }
    });
    return true;
  }

  subCategoryIsSelected(subcategory){
    const haveSub = this.state.selectedSubCategories.find((s) => s.id === subcategory.id);
    return (haveSub) ? haveSub : false;
  }

  onSubCategorySelect(subcategory){
    const subcategories = this.state.selectedSubCategories.map((sub) => sub);
    const haveSub = this.subCategoryIsSelected(subcategory);
    let getProductStatements = true;
    if(haveSub){
      const index = subcategories.indexOf(haveSub);
      subcategories.splice(index, 1);
      this.removeIdFromExtractedIds(subcategory.id);
      getProductStatements = false;
    } else {
      subcategories.push(subcategory);
    }

    this.setState({selectedSubCategories: subcategories}, () => {
      if (getProductStatements) {
        this.getProductStatements(subcategories.length === 1, [subcategory.id]);
      } else {
        if(subcategories.length === 0){ // reload categories
          const ids = this.state.selectedCategories.map((category) => category.id);
          this.getProductStatements(true, ids);
        }
      }
    });

  }

  bindStatementsForType(statements, key){
    const bindStatements = statements.map((statement) => statement);
    const statementsInState = Object.assign({}, this.state.statements);
    statementsInState[key] = bindStatements;
    this.setStatementsInLocalState(statementsInState);
  }

  onStatementChange(key, index, value){
    const boundStatements = Object.assign({}, this.state.statements);
    const statement = boundStatements[key];
    if(statement === undefined) return false;
    statement[index].value = value;
    this.setStatementsInLocalState(boundStatements);
  }

  onToggleDelete(key, index){
    const boundStatements = Object.assign({}, this.state.statements);
    const statement = boundStatements[key];
    if(statement === undefined) return false;
    statement[index].remove = (statement[index].remove === 0) ? 1 : 0;
    this.setStatementsInLocalState(boundStatements);
  }

  setStatementsInLocalState(statements){
    this.setState({statements: statements}, () => {
      const canSave = this.canSave();
      this.setState({canSave});
    });
  }

  idIsInExtractedIds(key, id){
    return (this.state.extractedIds[key].indexOf(id) !== -1);
  }

  removeIdFromExtractedIds(id){
    const extractedIds = Object.assign({}, this.state.extractedIds);
    this.state.types.forEach((type) => {
      const index = extractedIds[type].indexOf(id);
      if(index === -1) return true;
      extractedIds[type].splice(index, 1);
    });
    this.setState({extractedIds});
  }

  extractCategoryId(key, id){
    const extractedIds = Object.assign({}, this.state.extractedIds);
    extractedIds[key].push(id);
    this.setState({extractedIds});
  }

  goBack(){
    this.props.actions.goBack();
  }

  getPayload(){

    const ids = this.state.activeType === 'global'
      ? [0]
      : this.state.activeType === 'category'
      ? this.state.selectedCategories.map((category) => category.id)
      : this.state.selectedSubCategories.map((category) => category.id);

    const payload = {many_statements: [], respond_with_ids: ids}; // selected ids won't necessarily be in payload so add them here
    const types = ['health', 'ides', 'mandated'];
    let isGlobal = false;
    types.forEach((type) => {
      const statementArray = this.state.statements[type];
      if(!Array.isArray(statementArray)) return false;
      statementArray.forEach((statement) => {
        if(statement.originalValue === statement.value && statement.remove === 0) return true; // skip unchanged values
        if(statement.categories !== undefined) {
          const ids = statement.categories.map((category) => category.id);
          payload.many_statements.push({
            ids       : ids,
            statements: [
              {
                type: type,
                text: statement.value,
                remove: statement.remove
              }
            ]
          });
        } else {
          isGlobal = true;
          payload.many_statements.push({
            statements: [
              {
                type: type,
                text: statement.value,
                remove: statement.remove
              }
            ]
          });
        }
      });
    });

    if(isGlobal){
      payload.respond_with_ids = [0];
    }

    return payload;

  }

  canSave(){
    const payload = this.getPayload();
    return payload.many_statements.length > 0;
  }

  handleSubmit(){
    if(!this.state.canSave) return false;
    this.setState({saving: true});
    const payload = this.getPayload();
    this.setState({inProgressActive: true, inProgressMessage: this.state.messages[`${this.state.activeType}Saving`]});
    const onComplete = (data) => {
      this.props.actions.setData(data, dataNames.productStatements);
      this.setState({inProgressActive: false, saving: false});
    };
    this.props.actions.postData(`/api/statements/${this.state.activeType}/set`, payload, null, {}, {}, onComplete);
  }

  render(){

    const {categories, categoryOptions, subCategoryOptions} = this.props;

    return(
      <div className='label-requirements-page'>
        <InProgressOverlay
          isActive={this.state.inProgressActive}
          message={this.state.inProgressMessage}
        />
        <FormWrapper title='productStatements.manageStatements' goBack={this.goBack}>
        <LabelStatementsForm
          categories={categories}
          categoryOptions={categoryOptions}
          subCategoryOptions={subCategoryOptions}
          onStatementChange={this.onStatementChange}
          onToggleDelete={this.onToggleDelete}
          boundStatements={this.state.statements}
          bindStatementsForType={this.bindStatementsForType}
          productStatements={this.props.productStatements}
          selectedCategories={this.state.selectedCategories}
          selectedSubCategories={this.state.selectedSubCategories}
          subCategoryIsSelected={this.subCategoryIsSelected}
          onSubCategorySelect={this.onSubCategorySelect}
          categoryIsSelected={this.categoryIsSelected}
          onCategorySelect={this.onCategorySelect}
          idIsInExtractedIds={this.idIsInExtractedIds}
          extractCategoryId={this.extractCategoryId}
          activeType={this.state.activeType}
        />
        <SubmitSection
          handleSubmit={this.handleSubmit}
          settings={{
            actionSettings: {
              submit: {
                text: I18n.t('productStatements.saveStatements'),
                action: this.handleSubmit,
                submitting: this.state.saving,
                pristine: !this.state.canSave
              }
            }
          }}
        />
        </FormWrapper>
      </div>
    );
  }
}

LabelStatements.propTypes = {
  categories: PropTypes.array.isRequired,
  categoryOptions: PropTypes.array.isRequired,
  subCategoryOptions: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    getUnpaginatedData: PropTypes.func.isRequired,
    postData: PropTypes.func,
    goBack: PropTypes.func,
    unsetData: PropTypes.func,
    setData: PropTypes.func
  }).isRequired,
  productStatements: PropTypes.array
};

function mapStateToProps(state) {
  const {categories} = state;
  const statementTypes = ['health', 'ides', 'mandated'];
  let productStatements = [];
  if(Array.isArray(state.productStatements)){
    productStatements = state.productStatements.map((statement) => {
      statementTypes.forEach((type) => {
        if(statement.statements[type] === undefined) return true;
        if(statement.statements[type].originalValue === undefined) { // so we can filter out unchanged values from posts
          statement.statements[type].originalValue = statement.statements[type].value;
          statement.statements[type].remove = 0;
        }
      });
      return statement;
    });
  }

  return {
    categories,
    headers: state.headers,
    subCategoryOptions: getSubCategoryOptions(state),
    categoryOptions: getCategoryOptions(state),
    productStatements
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {goBack, push}, dataActions);
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LabelStatements);
