import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Button, ButtonGroup} from 'react-bootstrap';

const OrderPackagingButtons = ({changeFulfillmentStatus, disablingAddInventory, processing, className}) => {

  return (
      <ButtonGroup className={className}>
        {disablingAddInventory &&
          <Button onClick={() => changeFulfillmentStatus('ready')} disabled={processing}>
            {I18n.t('cart.drawer.submitForPackaging')}
          </Button>
        }
        {!disablingAddInventory &&
        <Button onClick={() => changeFulfillmentStatus('pending')} disabled={processing}>
          {I18n.t('cart.drawer.release')}
        </Button>
        }
      </ButtonGroup>
  );
};

OrderPackagingButtons.propTypes = {
  changeFulfillmentStatus: PropTypes.func.isRequired,
  disablingAddInventory: PropTypes.bool.isRequired,
  processing: PropTypes.bool,
  className: PropTypes.string
};


export default OrderPackagingButtons;
