import React from 'react';
import PropTypes from 'prop-types';
import {FieldArray} from 'redux-form';

import Contacts from './Contacts';// eslint-disable-line import/no-named-as-default

const ContactsForm = (props) => {
  const {handleSubmit, handleSaveContact, handleSaveAndAddContact, submitting, pristine, disable,
    contactNames, partnerFacilities, editMode, handleModifyContact} = props;
  return (
    <div className='contacts-form'>
      <form>
        <FieldArray
          name='contacts' component={Contacts}
          props={{
            editMode,
            contactNames,
            partnerFacilities,
            disable,
            pristine,
            submitting,
            handleSubmit,
            handleSaveContact,
            handleSaveAndAddContact,
            handleModifyContact
          }}
        />
      </form>
   </div>
  );
};

ContactsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleSaveContact: PropTypes.func.isRequired,
  handleSaveAndAddContact: PropTypes.func.isRequired,
  handleModifyContact: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  contactNames: PropTypes.array.isRequired,
  partnerFacilities: PropTypes.array.isRequired,
  disable: PropTypes.bool.isRequired,
  editMode: PropTypes.bool
};

ContactsForm.defaultProps = {
  editMode: false,
  handleModifyContact: () => {}
};
export default ContactsForm;
