import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {unsetItem} from '../../../actions/itemActions';
import {getItem, getDataByPost} from '../../../actions/apiActions';
import {getLotLineageFormatted, getItemMasterIdsByLotLineage} from '../../../selectors/lotLineageSelectors';

import LotLineageComponent from './LotLineageComponent';

function mapStateToProps(state) {
  return {
    lotLineage: getLotLineageFormatted(state),
    itemMasterIds: getItemMasterIdsByLotLineage(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    unsetItem,
    getItem,
    getDataByPost,
  };

  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

const LotLineage = connect(mapStateToProps, mapDispatchToProps)(LotLineageComponent);

LotLineage.propTypes = {
  show: PropTypes.bool,
  lotId: PropTypes.number,
};

export default LotLineage;
