import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {I18n} from 'react-redux-i18n';
import { bindActionCreators } from 'redux';
import { push, goBack } from 'react-router-redux';
import * as dataNames from '../../../../constants/dataNames';
import * as itemNames from '../../../../constants/itemNames';
import { getItem, getUnpaginatedData, postItem } from '../../../../actions/apiActions';
import FormWrapper from '../../../common/form/FormWrapper';
import ConnectsRequestFormWrapper from './ConnectsRequestFormWrapper';
import {getInitialValues, getIsRequestResponse} from '../../../../selectors/forms/connectsRequestFormSelectors';

export class ConnectsRequestPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.redirect = this.redirect.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {};
  }

  componentWillMount() {
    if(this.props.params.code) {
      this.props.actions.getItem(`/api/organizations/public/${this.props.params.code}`, itemNames.organization);
    } else {
      this.props.actions.getItem(`/api/partners/partner_connect_requests/${this.props.params.id}`, itemNames.connectsRequest)
        .then((request) => {
          this.props.actions.getItem(`/api/organizations/public/${request.target_organization_code}`, itemNames.connectsTargetOrganization);
          this.props.actions.getItem(`/api/organizations/public/${request.requesting_organization_code}`, itemNames.organization);
        });

    }
    this.props.actions.getUnpaginatedData('/api/partners/details?connect_active=0', dataNames.organizations);
    this.props.actions.getUnpaginatedData('/api/partner_facilities', dataNames.partnerFacilities);
  }

  redirect(){
    this.props.actions.goBack();
  }

  onSubmit(form){

    const booleanFields = [
      'requested_place_orders',
      'requested_view_inventory',
      'requested_inherit_new_products',
      'requested_inherit_product_images',
      'requested_inherit_product_pricing',
      'requested_receive_communication',
    ];

    const payload = Object.assign({}, form, {
      facilities: form.facilities.reduce((acc, facility) => {
        if(facility.existing_facility_id < 0) return acc;
        if(!facility.existing_facility_id) {
          acc.push(Object.assign({}, facility, {existing_facility_id: null, action: 'create'}));
        } else {
          acc.push(Object.assign({}, facility, {action: 'link'}));
        }
        return acc;
      }, [])
    });

    const redirect = () => this.props.actions.push('/partners/requests');

    if(!this.props.isRequestResponse) { // sending request
      payload.target_organization_code = this.props.params.code;
      booleanFields.forEach((field) => {
        if (payload[field] === undefined) payload[field] = false;
      });
      this.props.actions.postItem('/api/partners/partner_connect_requests', payload)
        .then(redirect);
    } else { // responding to a request
      payload.request_status = 'accepted';
      this.props.actions.postItem(`/api/partners/partner_connect_requests/${this.props.params.id}/respond `, payload)
        .then(redirect);
    }
  }

  render () {
    const props = this.props;
    return (
      <div>
        <FormWrapper title={I18n.t('connectRequest')} goBack={this.redirect}>
          <div>
            <ConnectsRequestFormWrapper
              initialValues={props.initialValues}
              organizations={this.props.organizations}
              organization={this.props.organization}
              partnerFacilities={this.props.partnerFacilities}
              organizationCode={this.props.params.code}
              isRequestResponse={this.props.isRequestResponse}
              onSubmit={this.onSubmit}
            />
          </div>
        </FormWrapper>
      </div>
    );
  }
}

ConnectsRequestPage.propTypes = {
  organization: PropTypes.object
};

function mapStateToProps(state){
  return {
    organization: state[itemNames.organization],
    organizations: state[dataNames.organizations],
    partnerFacilities: state[dataNames.partnerFacilities],
    initialValues: getInitialValues(state),
    isRequestResponse: getIsRequestResponse(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {push, goBack, getItem, getUnpaginatedData, postItem};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (ConnectsRequestPage);
