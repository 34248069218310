import { I18n } from 'react-redux-i18n';
import get from 'lodash.get';
import { requiredFieldValidation } from '../../common/form/redux-form/validations';

export const validate = (values, props) => {
  const errors = {};
  const trackPlantsToGroup = get(props, 'trackPlantsAsGroups', false);
  const selectedPlants = get(props, 'selectedPlants');
  const requiresAdditionalDataForMetrc = props.requiresAdditionalDataForMetrc;

  if (requiresAdditionalDataForMetrc && !values.plant_notes) {
    errors.plant_notes = I18n.t('plants.destroy.emptyDestroyNotesError');
  }

  if (requiresAdditionalDataForMetrc && !values.mixed_materials) {
    errors.mixed_materials = I18n.t('plants.form.enterIncludedMaterials');
  }

  if (props.requiresAdditionalDataForMetrc && !values.destroy_method) {
    errors.destroy_method = I18n.t('plants.form.enterDestroyMethod');
  }

  if (!values.notes) {
    errors.notes = I18n.t('plants.form.enterDestroyReason');
  }
  if (values.use_overall) {
    if (!values.destroyed_weight) {
      errors.destroyed_weight = I18n.t('plants.form.enterDestroyWeight');
    } else {
      errors.plants = values.plants.map((value) => ({
        destroyed_weight: requiredFieldValidation(value.destroyed_weight, 'plants.table.waste')
      }));
    }
  } else {
    errors.plants = values.plants.map((value) => ({
      destroyed_weight: requiredFieldValidation(value.destroyed_weight, 'plants.table.waste')
    }));
  }

  if (trackPlantsToGroup && !values.plants_destroyed) {
    errors.plants = values.plants.map((value) => {
      const qty = get(selectedPlants.find((plant) => plant.id === value.id), 'qty');
      const plantsDestroyed = get(value, 'plants_destroyed');
      const errorPlantsDestroyed =
        plantsDestroyed && plantsDestroyed > qty
          ? I18n.t('plants.destroy.plantsDestroyedQtyError')
          : plantsDestroyed && plantsDestroyed == 0
          ? I18n.t('common.form.isRequired', { fieldName: I18n.t('plants.table.plantsDestroyed') })
          : undefined;
      return {
        plants_destroyed: errorPlantsDestroyed
          ? errorPlantsDestroyed
          : (values.use_overall ? undefined : requiredFieldValidation(value.plants_destroyed, 'plants.table.plantsDestroyed'))
      };
    });
  }

  if (!values.uom) {
    errors.uom = I18n.t('plants.form.selectUom');
  }
  if (!values.destroyed_at) {
    errors.destroyed_at = I18n.t('plants.form.enterDestroyDate');
  }
  return errors;
};

export default validate;
