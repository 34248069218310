import {createSelector} from 'reselect';
import get from 'lodash.get';
import keyby from 'lodash.keyby';
import {formValueSelector} from 'redux-form';

import {getFacilities} from '../facilitiesSelectors';
import * as itemNames from '../../constants/itemNames';
import * as dataNames from '../../constants/dataNames';
import {INTERNAL_TRANSFERS_SETTINGS_FORM} from '../../constants/forms';

const selector = new formValueSelector(INTERNAL_TRANSFERS_SETTINGS_FORM);

export const getSettings = state => get(state[itemNames.coreInternalTransfersSettings], 'core_internal_transfers_settings.value', []);

const getAvailableFacilitiesIds = state => state[dataNames.availableFacilitiesIds];

export const getInitialValues = createSelector(
  [getAvailableFacilitiesIds, getSettings, getFacilities],
  (availableFacilitiesIds, settings, facilities) =>  {
    const settingsByFacility = keyby(settings, 'facility_id');
    return {
      facilities: facilities
        .filter(facility => (availableFacilitiesIds || []).find(id => facility.id === id))
        .map(facility => {
          const isChecked = !settingsByFacility[facility.id]
            || settingsByFacility[facility.id].enabled;
          const isRestricted = settingsByFacility[facility.id] && settingsByFacility[facility.id].restricted;
          return {
            name: facility.name,
            id: facility.id,
            checked: isChecked,
            restricted: isRestricted,
            require_ir: !isChecked
              ? null
              : (settingsByFacility[facility.id]
                ? 1 * (get(settingsByFacility, `${facility.id}.require_ir`, 1))
                : 1),
            require_payment: !isChecked
              ? null
              : (settingsByFacility[facility.id]
                ? 1 * (get(settingsByFacility, `${facility.id}.require_payment`, 0))
                : 0),
          };
        }
    )};
  }
);

export const getPayload = (formData) => formData.facilities.map(setting => ({
  facility_id: setting.id,
  enabled: !!setting.checked,
  require_ir: !!setting.require_ir,
  require_payment: !!setting.require_payment
}));

export const isDisabled = (state, key) => {
  const isChecked = selector(state, `${key}.checked`);
  return !isChecked;
};