import React from 'react';
import PropTypes from 'prop-types';
import {Tabs, Tab} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import ActionBar from './ActionBar';

const FilterTabs = ({
  tabs, activeTab, pageSize, pageSizeList, columns, hideExport, showSelectedCount, selectedCount, rowIsSelected, setVisibleColumns, setPageSize,
  switchTab, exportCSV, toggleScanSearch, toggleScanIdSearch, scanSearchEnabled, hideTableControls, toggleHistory, historyEnabled, children, props}) => {
  return (
  <div>
    <Tabs id='filterTabs' activeKey={activeTab} onSelect={switchTab} {...props}>
      {tabs.map((tab, index) =>
        <Tab key={index} eventKey={tab.eventKey} title={I18n.t(tab.title)} >
          {tab.description && <div className='tab-description'>{tab.description}</div>}
          <ActionBar
            actions={tab.actions}
            setPageSize={setPageSize}
            pageSize={pageSize}
            pageSizeList={pageSizeList}
            scanSearchEnabled = {scanSearchEnabled}
            historyEnabled = {historyEnabled}
            toggleScanSearch = {toggleScanSearch}
            toggleScanIdSearch = {toggleScanIdSearch}
            toggleHistory = {toggleHistory}
            setVisibleColumns={setVisibleColumns}
            columns={columns}
            exportCSV={exportCSV}
            rowIsSelected={rowIsSelected}
            hideExport={hideExport}
            hideTableControls={hideTableControls}
            showSelectedCount={showSelectedCount}
            selectedCount={selectedCount}
          />
          {children}
        </Tab>
      )}
    </Tabs>
  </div>);
};

FilterTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  pageSize: PropTypes.number.isRequired,
  pageSizeList: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  rowIsSelected: PropTypes.bool.isRequired,
  activeTab: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  toggleScanSearch: PropTypes.func,
  toggleScanIdSearch: PropTypes.func,
  toggleHistory: PropTypes.func,
  scanSearchEnabled: PropTypes.bool,
  historyEnabled: PropTypes.bool,
  setVisibleColumns: PropTypes.func.isRequired,
  setPageSize: PropTypes.func.isRequired,
  switchTab: PropTypes.func,
  exportCSV: PropTypes.func.isRequired,
  props: PropTypes.object,
  hideExport: PropTypes.bool,
  showSelectedCount: PropTypes.bool,
  selectedCount: PropTypes.number,
  hideTableControls: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node
  ])
};

export default FilterTabs;
