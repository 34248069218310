import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { arrayRemove, formValueSelector } from 'redux-form';
import { bindActionCreators } from 'redux';
import get from 'lodash.get';
import find from 'lodash.find';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import { getItem, getUnpaginatedData, postItem, getDataByPost } from '../../../actions/apiActions';
import { setData, unsetData } from '../../../actions/dataActions';
import { INTERNAL_TRANSFER_FORM } from '../../../constants/forms';
import InternalTransferForm from './InternalTransferForm';
import { getFlattenedPartnerLocations } from '../../../selectors/locationsSelectors';
import { fetchInternalTransferSettings } from '../../../actions/core/settingsActions';
import * as internalTransferSettings from '../../../selectors/forms/internalTransfersSettingsFormSelector';
import * as registerActions from '../../../selectors/registersSelectors';
import * as usersSelectors from '../../../selectors/usersSelectors';
import { fetchEnsureRegistries } from '../../../actions/registerActions';
import { convertFromBase } from '../../../util/uomHelpers';
import InProgressOverlay from '../../common/InProgressOverlay';
import { isAllowNegativeInventory } from '../../../selectors/complianceSettingsSelectors';
import NegativeInventoryAlert from '../../common/negative-inventory/NegativeInventoryAlert';
import showNegativeAlert from '../../common/negative-inventory/showNegativeAlert';
import { fetchItemMasterAndAddToRedux } from '../../../actions/itemMasterActions';

class InternalTransfers extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.saveTransfer = this.saveTransfer.bind(this);
    this.addPayments = this.addPayments.bind(this);
    this.preparePayload = this.preparePayload.bind(this);
    this.confirmItemsAreAvailable = this.confirmItemsAreAvailable.bind(this);
    this.getPartnerStorageLocations = this.getPartnerStorageLocations.bind(this);
    this.getTransferSettingsByFacility = this.getTransferSettingsByFacility.bind(this);
    this.getPartnerTransferSettingsByFacility = this.getPartnerTransferSettingsByFacility.bind(this);
    this.inProgressDismiss = this.inProgressDismiss.bind(this);
    this.inProgressShow = this.inProgressShow.bind(this);
    this.setNegativeConfirmationState = this.setNegativeConfirmationState.bind(this);
    this.fetchItemMasters = this.fetchItemMasters.bind(this);

    this.state = {
      inProgressShow: false,
      inProgressMessage: '',
      showNegativeInvConfirmation: {
        show: false,
        onHide: null,
        onConfirm: null
      }
    };
  }

  componentWillMount() {
    this.props.actions.unsetData(dataNames.partnerLocations);
    this.props.actions.getUnpaginatedData('/api/partner_facilities', dataNames.partnerFacilities);
    this.props.actions.getUnpaginatedData(
      '/api/prepack_weights',
      dataNames.prepackWeights,
      { failed: 'packaging.getPrepackWeights.failed' },
      { active: 1 }
    );
    this.props.actions.fetchInternalTransferSettings();
    this.props.actions.fetchEnsureRegistries();
  }

  getPartnerStorageLocations(partnerId) {
    this.props.actions.getItem(`/api/sales_orders/partner_data_by_partner_id/${partnerId}`).then((data) => {
      this.props.actions.setData(get(data, 'locations', []), dataNames.partnerLocations);
      this.props.actions.setData(get(data, 'facility_prepacks', []), dataNames.partnerFacilityPrepacks);
    });
  }

  getPartnerTransferSettingsByFacility(facility_id) {
    const partnerFacility = find(this.props.partnerFacilities, { id: facility_id });
    return this.getTransferSettingsByFacility(get(partnerFacility, 'facility_id'));
  }

  getTransferSettingsByFacility(facility_id) {
    return find(this.props.internalTransferSettings, { facility_id });
  }

  saveTransfer(payload) {
    return this.props.actions.postItem('/api/sales_orders', payload, 'noOp', {
      failed: 'internalTransfers.form.onFailure',
      success: 'internalTransfers.form.onSuccess'
    });
  }

  confirmItemsAreAvailable(transfers, isDataRefresh = false) {
    const { allowNegativeInventory } = this.props;
    return new Promise((resolve, reject) => {
      const ids = transfers.map((transfer) => transfer.item_id);
      this.props.actions.getDataByPost('/api/item_availability/multiple', { ids }).then((items) => {
        const transfersWithAvailableQuantity = transfers.map((transfer) => {
          const item = items.find((item) => item.item_id === transfer.item_id);
          let availableQuantity = 0;
          let reserved = 0;
          let onHand = 0;
          if (item) {
            const uom = get(item, 'uom_display', 'GR');
            const itemMasterId = get(item, 'item_master_id');
            const parentItemMasterId = get(item, 'parent_item_master_id');
            if (itemMasterId === parentItemMasterId) {
              // not prepack
              onHand = convertFromBase(parseFloat(get(item, 'qty_onhand_base', 0)), uom);
              reserved = convertFromBase(parseFloat(get(item, 'qty_reserved_base', 0)), uom);
            } else {
              // handle prepacks which come with a prepack weight value which is the base weight value of the prepack
              const prepackWeight = parseFloat(get(item, 'prepack_weight_base', 0));
              onHand = parseFloat(get(item, 'qty_onhand_base', 0)) / prepackWeight;
              reserved = parseFloat(get(item, 'qty_reserved_base', 0)) / prepackWeight;
            }
            availableQuantity = onHand - reserved;
          }
          const transferUpdateFields = {
            available_quantity: availableQuantity,
            qty_reserved: reserved,
            qty: item || allowNegativeInventory ? onHand : get(transfer, 'qty', 0)
          };
          return Object.assign({}, transfer, transferUpdateFields);
        });
        const allTransferQuantitiesAreAvailable = transfersWithAvailableQuantity.reduce((acc, transfer) => {
          if (!acc) return acc;
          return transfer.available_quantity >= parseFloat(transfer.transfer_quantity);
        }, true);
        this.props.actions.setData(transfersWithAvailableQuantity, dataNames.updatedInternalTransfers);
        if (allTransferQuantitiesAreAvailable || (isDataRefresh && allowNegativeInventory)) {
          resolve();
          return true;
        }
        if (allowNegativeInventory) {
          return showNegativeAlert(true, this.setNegativeConfirmationState)
            .then(resolve)
            .catch(reject);
        }
        reject(transfersWithAvailableQuantity);
      });
    });
  }

  preparePayload(formValues, setPricing = false) {
    const getItemMaster = (transfer) => {
      const itemMasterId = get(transfer, 'parent_item_master_id', get(transfer, 'item_master_id', 0));
      return this.props.itemMasters.find((itemMaster) => itemMaster.id === itemMasterId);
    };

    const isIngredient = (itemMaster) => {
      return get(itemMaster, 'inventory_attributes.is_ingredient');
    };

    const getWholesalePrice = (transfer) => {
      if (!setPricing) return 0;
      const itemMaster = getItemMaster(transfer);

      if (isIngredient(itemMaster)) {
        const priceObject = get(itemMaster, 'vendors.0', {});
        return parseFloat(get(priceObject, 'default_unit_cost', 0));
      } else {
        const priceObject = get(itemMaster, 'pricing_details.price_lists', []).find(
          (priceList) => priceList.sale_type === 'wholesale'
        );
        return parseFloat(get(priceObject, 'default_price', 0));
      }
    };

    const getSubItems = (transfer) => {
      const pricePerGram = getWholesalePrice(transfer);
      const weight = getWeight(transfer);
      return [
        {
          item_master_id: get(transfer, 'item_master_id', 0),
          qty: parseFloat(get(transfer, 'transfer_quantity')),
          unit_price: pricePerGram * get(weight, 'weight', 0),
          weight: get(weight, 'weight'),
          item_id: get(transfer, 'item_id')
        }
      ];
    };

    const hasParentItemMaster = (transfer) => {
      return get(transfer, 'parent_item_master_id', 0);
    };

    const getWeight = (transfer) => {
      return this.props.prepackWeights.find((weight) => {
        return weight.id === transfer.prepack_weight_id;
      });
    };

    const getQuantity = (transfer) => {
      const hasParentItemMasterId = hasParentItemMaster(transfer);
      const quantity = parseFloat(get(transfer, 'transfer_quantity'));
      if (!hasParentItemMasterId) {
        return quantity;
      }
      const weight = this.props.prepackWeights.find((weight) => {
        return weight.id === transfer.prepack_weight_id;
      });
      return weight ? (convertFromBase(weight.weight_base, weight.uom)) * quantity : quantity;
    };

    const groupPrepackLines = (acc, line) => {
      if (!line.subitems) {
        // Do not reduce non prepacks.  There is no built in mech for handling them.
        acc.push(line);
        return acc;
      }
      const itemMasterId = get(line, 'item_master_id', 0);
      const existingLine = acc.find((line) => line.item_master_id === itemMasterId);
      if (existingLine) {
        existingLine.line_item_price += line.line_item_price;
        existingLine.qty += line.qty;
        if (existingLine.subitems) {
          const currentSubItem = get(line, 'subitems.0', {});
          existingLine.subitems.push(
            Object.assign({}, currentSubItem, { inventory: [Object.assign({}, currentSubItem)] })
          );
        }
        return acc;
      }
      if (line.subitems) {
        line.subitems[0].inventory = line.subitems.map((subitem) => Object.assign({}, subitem));
      }
      acc.push(line);
      return acc;
    };

    const sortPrepackLines = (line) => {
      if (!line.subitems) return line;
      line.subitems = line.subitems.sort((a, b) => {
        return a.weight === b.weight ? parseFloat(a.qty) > parseFloat(b.qty) : a.weight > b.weight ? 1 : -1;
      });
      return line;
    };

    return new Promise((resolve, reject) => {
      const partner_facility_id = get(formValues, 'partner_facility_id');
      const partner = this.props.partnerFacilities.find((f) => f.id === partner_facility_id);
      const payload = {
        generate_internal_purchase_order: 1,
        is_micro_transfer: 1,
        order_class: 'medical',
        title: 'Internal Transfer',
        order_type: 'sales',
        partner_facility_id,
        partner_facility_name: get(partner, 'facility_name'),
        partner_contact_name: get(partner, 'facility_name'),
        storage_location_id: get(formValues, 'storage_location_id', 0),
        status: 'completed',
        payment_status: 'ordered',
        lines: formValues.transfers
          .map((transfer) => {
            // 1 line per product allows repeating item masters
            const quantity = getQuantity(transfer);
            const itemMaster = getItemMaster(transfer);
            const wholesalePrice = getWholesalePrice(transfer);
            const lineItemPrice = wholesalePrice * quantity;
            return {
              qty: getQuantity(transfer),
              uom: get(transfer, 'uom_display'),
              item_id: get(transfer, 'item_id'),
              unit_price: wholesalePrice,
              item_master_id: itemMaster.id,
              line_item_price: lineItemPrice,
              storage_location_id: get(formValues, 'storage_location_id', 0),
              subitems: hasParentItemMaster(transfer) ? getSubItems(transfer) : undefined
            };
          })
          .reduce(groupPrepackLines, [])
          .map(sortPrepackLines)
      };
      payload.order_total = payload.lines.reduce((acc, line) => acc + line.line_item_price, 0);
      resolve(payload);
    });
  }

  addPayments(payload) {
    const { registries, user } = this.props;

    return [
      {
        amount: get(payload, 'order_total', 0),
        payment_type: 'cash',
        register_id: get(registries, '[0].id', 0),
        user_id: get(user, 'id'),
        payment_date: moment().format('YYYY-MM-DD HH:mm:ss')
      }
    ];
  }

  fetchItemMasters(formValues) {
    const promises = [];
    get(formValues, 'transfers').map((line) => {
      const id = get(line, 'parent_item_master_id', get(line, 'item_master_id'));
      promises.push(this.props.actions.fetchItemMasterAndAddToRedux(id, dataNames.itemMastersWithPricing));
    });
    return Promise.all(promises);
  }

  onSubmit(formValues) {
    const senderTransferSettings = this.getTransferSettingsByFacility(get(formValues, 'facility_id'));
    const partnerTransferSettings = this.getPartnerTransferSettingsByFacility(get(formValues, 'partner_facility_id'));
    const partnerRequiresPayment = get(partnerTransferSettings, 'require_payment');
    const senderRequiresPayment = get(senderTransferSettings, 'require_payment');
    const doNotClose = get(formValues, 'doNotClose', 0);

    this.inProgressShow('internalTransfers.form.progress.validatingQuantities');
    this.confirmItemsAreAvailable(get(formValues, 'transfers', []))
      .then(() => this.fetchItemMasters(formValues))
      .then(() => {
        this.inProgressShow('internalTransfers.form.progress.preparingPayload');
        this.preparePayload(formValues, true).then((payload) => {
          if (senderRequiresPayment) {
            payload.payments = this.addPayments(payload);
          }

          if (partnerRequiresPayment) {
            payload.partner_payment = this.addPayments(payload);
          }

          this.inProgressShow('internalTransfers.form.progress.saving');
          this.saveTransfer(payload)
            .then(() => {
              if (!doNotClose) {
                this.inProgressDismiss();
                this.props.onCancel();
                return false;
              }
              this.inProgressShow('Refreshing inventory quantities for internal transfers.');
              this.confirmItemsAreAvailable(get(formValues, 'transfers', []), true)
                .then(() => {
                  this.inProgressDismiss();
                })
                .catch(this.props.onCancel);
            })
            .catch(this.inProgressDismiss);
        });
      })
      .catch(this.inProgressDismiss);
  }

  inProgressDismiss() {
    this.setState({
      inProgressShow: false
    });
  }

  inProgressShow(message = false) {
    const newState = {
      inProgressShow: true,
      inProgressMessage: message ? message : this.state.inProgressMessage
    };
    this.setState(newState);
  }

  setNegativeConfirmationState(state) {
    this.setState({
      showNegativeInvConfirmation: state
    });
  }

  render() {
    const { showNegativeInvConfirmation } = this.state;
    return (
      <div>
        <InProgressOverlay
          isActive={this.state.inProgressShow}
          onDismiss={this.inProgressDismiss}
          showOk={false}
          showLoader={true}
          message={this.state.inProgressMessage}
          translate={true}
        />
        <InternalTransferForm
          initialValues={{
            facility_id: this.props.facility.id,
            transfers: this.props.selectedProducts.map((product) => ({
              ...product,
              transfer_quantity: product.qty - product.qty_reserved
            }))
          }}
          handleSelect={this.props.handleSelect}
          selectedProducts={this.props.selectedProducts}
          selectedPartnerFacilityId={this.props.selectedPartnerFacilityId}
          onSubmit={this.onSubmit}
          facility={this.props.facility}
          partnerFacilities={this.props.partnerFacilities}
          actions={{ arrayRemove: this.props.actions.arrayRemove }}
          getPartnerStorageLocations={this.getPartnerStorageLocations}
          locations={this.props.locations}
          onCancel={this.props.onCancel}
          isIngredient={this.props.isIngredient}
          settings={this.props.internalTransferSettings}
          allowNegativeInventory={this.props.allowNegativeInventory}
        />
        <NegativeInventoryAlert confirmationState={showNegativeInvConfirmation} />
      </div>
    );
  }
}

InternalTransfers.propTypes = {
  user: PropTypes.object,
  registries: PropTypes.array,
  selectedProducts: PropTypes.array.isRequired,
  handleSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.onCancel,
  isIngredient: PropTypes.bool,
  selectedPartnerFacilityId: PropTypes.string,
  internalTransferSettings: PropTypes.object,
  allowNegativeInventory: PropTypes.bool
};

const selector = formValueSelector(INTERNAL_TRANSFER_FORM);
function mapStateToProps(state) {
  return {
    user: usersSelectors.getCurrentUser(state),
    selectedPartnerFacilityId: selector(state, 'partner_facility_id'),
    facility: state[itemNames.facility],
    registries: registerActions.getRegisters(state),
    internalTransferSettings: internalTransferSettings.getSettings(state),
    partnerFacilities: state[dataNames.partnerFacilities],
    locations: getFlattenedPartnerLocations(state),
    prepackWeights: state[dataNames.prepackWeights],
    itemMasters: state[dataNames.itemMastersWithPricing],
    allowNegativeInventory: isAllowNegativeInventory(state)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    getItem,
    getDataByPost,
    setData,
    postItem,
    unsetData,
    arrayRemove,
    getUnpaginatedData,
    fetchEnsureRegistries,
    fetchInternalTransferSettings,
    fetchItemMasterAndAddToRedux
  };
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalTransfers);
