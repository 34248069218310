import React from 'react';
import PropTypes from 'prop-types';
import ReduxToastr from 'react-redux-toastr';
import ApplicationFooter from './system/ApplicationFooter';
import ErrorBoundry from './common/ErrorBoundry';
import Branding from './branding/Branding';
import DirtyFormModal from './common/DirtyFormModal';

const App = (props) => {
  return (
    <div className = {'full-width'}>
      <ErrorBoundry>
          <Branding/>
          <DirtyFormModal />
          <ReduxToastr
            timeOut={3000}
            newestOnTop={false}
            preventDuplicates
            position='top-left'
            transitionIn='fadeIn'
            transitionOut='fadeOut'
            progressBar/>
          {props.children}
          <div className='application-footer-buffer'>&nbsp;</div>
          <ApplicationFooter/>
      </ErrorBoundry>
    </div>
  );
};

App.propTypes = {
  children: PropTypes.element
};

export default App;
