import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import * as itemNames from '../../../constants/itemNames';
import {labelsConfiguration} from '../../../constants/dataNames';
import {getItem, postItem} from '../../../actions/apiActions';
import {setData} from '../../../actions/dataActions';
import FormWrapper from '../../common/form/FormWrapper';
import {getComplianceInitialValues} from '../../../selectors/labelsSelectors';
import LabelSettingsFormWrapper from './components/FormWrapper';
import formConfiguration from './components/formConfiguration';
import InProgressOverlay from '../../common/InProgressOverlay';

class LabelsSettingsPage extends React.PureComponent {

  constructor(props, context) {

    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.redirect = this.redirect.bind(this);
    this.hideLoader = this.hideLoader.bind(this);

    this.state = {
      showLoader: false,
      message: false
    };

  }

  componentWillMount(){

    this.props.actions.setData(formConfiguration, labelsConfiguration);

    this.setState({showLoader: true, message: I18n.t('cultivation.complianceSettings.loadingSettings')});

    this.props.actions.getItem('/api/labels/compliance_settings', itemNames.labelsCompliance)
      .then(this.hideLoader)
      .catch(this.hideLoader);
  }

  hideLoader(){
    this.setState({showLoader: false});
  }

  onSubmit(formValues) {

    this.setState({showLoader: true, message: I18n.t('cultivation.complianceSettings.savingSettings')});

    const payload = this.props.labels.reduce( (acc, label) => { // shape the payload
      if(!label.configurable) return acc;
      acc.settings.push({
        setting_key: label.setting_key,
        scope: label.scope,
        value: [formValues[label.type]]
      });
      return acc;
    }, {settings: []});

    this.props.actions.postItem('/api/labels/compliance_settings', payload, null, {}, {}, () => {
      this.hideLoader();
      if(!formValues.saveAndStay){
        this.redirect();
        return false;
      }
      formValues.saveAndStay = false;
    });

  }

  redirect() {
    this.props.actions.goBack();
  }

  render() {

    const formFields = this.props.labels.reduce( (acc, label) => {
      if(!label.configurable) return acc;
      acc.push(label);
      return acc;
    }, []);

    return (
      <FormWrapper title='cultivation.labelSettings.title' goBack={this.redirect}>
        <InProgressOverlay message={this.state.message} isActive={this.state.showLoader} />
        <LabelSettingsFormWrapper
          onSubmit={this.onSubmit}
          enableReinitialize={true}
          initialValues={this.props.initialValues}
          formFields={formFields} />
      </FormWrapper>
    );
  }
}

LabelsSettingsPage.propTypes = {
  initialValues: PropTypes.object.isRequired,
  complianceSettings: PropTypes.object.isRequired,
  labels: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    setData: PropTypes.func.isRequired
  })
};

function mapStateToProps(state) {

  return {
    complianceSettings: state[itemNames.labelsCompliance],
    labels: formConfiguration,
    initialValues: getComplianceInitialValues(state)
  };
}

function mapDispatchToProps (dispatch) {
  const actions = {goBack, getItem, postItem, setData};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LabelsSettingsPage);
