import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';

import FileInput from '../../common/form/FileInput';
import {MAX_UPLOAD_SIZE_DISPLAY, RETAIL_ORDER_IMPORT_FILE, FILE_TYPES} from '../../../constants/fileUploads';
import TextInput from '../../common/form/TextInput';
import * as itemNames from '../../../constants/itemNames';

class ImportFileElement extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.changeUploadStatus = this.changeUploadStatus.bind(this);

    this.state = {
      uploading: false,
    };
  }

  changeUploadStatus(status) {
    this.setState({uploading: status});
  }

  render() {
    const uploading = this.state.uploading;
    const change = this.props.change;

    return (
      <div className='flex layout-column layout-align-start'>
        <div className='layout-row layout-align-start-start'>
          <div>
            <h5>{I18n.t('ordersImport.form.uploadImportFile')}</h5>
            <p>{I18n.t('common.form.sizeLimit', {limit: MAX_UPLOAD_SIZE_DISPLAY})}</p>
            <p>{I18n.t('common.form.typeLimit', {extensions: FILE_TYPES[RETAIL_ORDER_IMPORT_FILE].extensions})}</p>
          </div>
        </div>
        <div className='layout-row layout-align-end-center'>
          <Field
            component={TextInput}
            name='file_name'
            props={{
              disabled: true
            }}
          />
          <Field
            name='import_file'
            itemName={itemNames.document}
            component={FileInput}
            props={{
              ...FILE_TYPES[RETAIL_ORDER_IMPORT_FILE],
              btnContent: uploading ? I18n.t('common.form.uploading') : I18n.t('common.form.upload'),
              disabled: uploading,
              onChangeCb: (file, initialFileData) => {
                change('fileId', file.id);
                change('fileUrl', file.url);
                change('fileMimeType', file.mime_type);
                change('file_name', file.original_name);
                change('fileName', initialFileData.name);
                this.changeUploadStatus(false);
              },
              onUploadStart: (event) => this.changeUploadStatus(true),
              btnProps: {
                className: 'btn btn-default'
              },
              errors: {file_type: 'ordersImport.form.file_type_error'}
            }}/>
        </div>
      </div>
    );
  }
}

ImportFileElement.propTypes = {
  onFileChanged: PropTypes.func.isRequired,
  onUploadStart: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired,
};

export default ImportFileElement;
