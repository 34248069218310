import React from 'react';
import lodashGet from 'lodash.get';
import PropTypes from 'prop-types';
import {Field, formValueSelector} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {Alert, Col, Row, Button} from 'react-bootstrap';
import {FaExclamationTriangle, FaMinus} from 'react-icons/fa';
import ComboboxInput from '../../../common/form/ComboboxInput';
import ReduxMetrcIdField from '../../../common/form/redux-form/ReduxMetrcIdField';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import AddItem from '../../../common/form/AddItem';
import {ratingOptions} from '../../../../constants/ratingOptions';
import * as dataNames from '../../../../constants/dataNames';
import InternationalDateTimePickerInput  from '../../../common/form/InternationalDateTimePickerInput';
import InlineCheckBox from '../../../common/form/InlineCheckBox';
import PackagePurposeField from '../../../common/form/redux-form/PackagePurposeField';
import MetrcConcealer from '../../../common/concealers/MetrcConcealer';
import * as p from '../../../../constants/permissions';
import PermissionWrapper from '../../../common/PermissionWrapper';
import {getProcessingInvTypes} from '../../../../selectors/processingSelectors';
import CheckBoxInput from '../../../common/form/CheckBoxInput';
import {COMPLETE_PROCESSING_FORM} from '../../../../constants/forms';
import {getWaInventoryTypesWithName} from '../../../../selectors/integrationSelectors';
import TextInput from '../../../common/form/TextInput';
import InternationalQuantityByUomInput from '../../../common/form/InternationalQuantityByUomInput';
import * as UOMS from '../../../../constants/uoms';
import {isFeatureEnabled} from '../../../../selectors/featureToggles';
import WillRender from '../../../common/concealers/WillRender';
import {getUomsOfLesserOrEqualValue} from '../../../../util/uomHelpers';

export const OutputsFieldArray = (props) => {
  const {
    fields, products, trackingIds, locations, phases, batchName, loading, isCompleted, productionRuns,
    integrationState, integrationTypeOptions, adjustmentReasons, initialValues, onChangeItem,
    waInventoryTypes, isAllInputsAreMedicallyComplaint, isMedicallyComplaintCheckedFor,
    isLabelEnabledForNoCompletedProcess, hasPackagesTags, uoms, isMetrcStemHoldingsToggled,
    isMetrcCaDonationsToggled, isUtahGlobalIdGenerationFeatureEnabled,
  } = props;

  const {isLeaf, isMetrc, isCaMetrc, isBiotrack, isCanada, isWaLeaf, isHemp} = integrationState;

  const {push, get, remove, map, length} = fields;
  const waInventoryTypesOptions = getWaInventoryTypesWithName(waInventoryTypes);
  const itemBoxStyle = {
    border: '1px solid #ccc',
    padding: '3rem 1rem 1rem 1rem',
    position: 'relative'
  };

  return (
    <div className='output-items'>
      {loading || products.length ?
        <div>
          {map((outputName, outputIndex) => {
            const output = get(outputIndex);
            const uom = lodashGet(output, 'uom', UOMS.GR);
            const viableUoms = (uoms || []).filter((uom) => isHemp ? true : uom.uom_code === UOMS.GR);
            const uomOptions = getUomsOfLesserOrEqualValue(viableUoms || [], uom);
            return (
              <div key={outputIndex} style={outputIndex > 0 ? Object.assign({}, itemBoxStyle, {marginTop: '12px'}) : itemBoxStyle}>
                <div style={{position: 'absolute', top: '2px', left: '2px'}}>
                  <WillRender ifTrue={!isLeaf && !isBiotrack}>
                    <Button
                      variant={length < 2 ? 'default' : 'danger'}
                      size='sm'
                      onClick={() => remove(outputIndex)}
                      disabled={length < 2}
                    >
                      <FaMinus/> <span>{I18n.t('packaging.form.removeItemText', {itemNumber: outputIndex + 1})}</span>
                    </Button>
                    <div style={{clear: 'both'}} />
                  </WillRender>
                </div>
              <div className='output-item' key={outputName}>
                <Row>
                  <Col md={3}>
                    <Field name={`${outputName}.item_master_id`} component={ReactSelectInput} props={{
                      label: I18n.t('ei.processing.form.newItem'),
                      options: products,
                      textKey: 'name',
                      valueKey: 'id',
                      isRequired: true,
                      disabled: isCompleted,
                      onChange: (value) => {
                        onChangeItem(value, outputName);
                      }
                    }}/>
                  </Col>

                  <Col md={3}>
                    <InternationalQuantityByUomInput
                      label={I18n.t('ei.processing.form.newQuantity')}
                      name={`${outputName}.qty`}
                      disabled={isCompleted}
                      uom={uom || UOMS.GR}
                      props={{
                        isRequired: true,
                      }}
                    />
                  </Col>

                  <Col md={3}>
                    <Field
                      name={`${outputName}.storage_location_id`}
                      component={ReactSelectInput}
                      props={{
                        label: I18n.t('ei.processing.form.storageLocation'),
                        options: locations,
                        isRequired: true,
                        disabled: isCompleted,
                      }}
                    />
                  </Col>

                  {
                    isMetrcStemHoldingsToggled && (
                      <MetrcConcealer>
                        <Col md={3}>
                          <Field
                            name={`${outputName}.is_trade_sample`}
                            component={ReactSelectInput}
                            props={{
                              label: I18n.t('metrc.form.isTradeSample'),
                              options: [
                                {text: 'Yes', value: 1},
                                {text: 'No', value: 0}
                              ]
                            }}
                          />
                        </Col>
                      </MetrcConcealer>
                    )
                  }

                  {
                    (isMetrcCaDonationsToggled && isCaMetrc) && (
                      <MetrcConcealer>
                        <Col md={3}>
                          <Field
                            name={`${outputName}.is_donation`}
                            component={ReactSelectInput}
                            props={{
                              label: I18n.t('metrc.form.donation'),
                              options: [
                                {text: 'Yes', value: 1},
                                {text: 'No', value: 0}
                              ]
                            }}
                          />
                        </Col>
                      </MetrcConcealer>
                    )
                  }

                  <WillRender ifTrue={isLabelEnabledForNoCompletedProcess}>
                    <Col md={3}>
                      <Field name={`${outputName}.package_code`} component={TextInput} props={{
                        label: I18n.t('ei.processing.form.packageID'),
                        disabled: true,
                      }}/>
                    </Col>
                  </WillRender>

                </Row>

                <Row>

                  <Col md={3}>
                    <Field
                      name={`${outputName}.lot_name`}
                      component={ComboboxInput}
                      props={{
                        label: I18n.t('ei.processing.form.productionRunLotName'),
                        options: productionRuns,
                        textKey: 'name',
                        valueKey: 'name',
                        isRequired: false,
                        disabled: isCompleted,
                      }}
                      // ComboboxInput may result in a string or an object.
                      normalize={value => typeof value === 'object' ? value.name : value}
                    />
                  </Col>

                  <Col md={3}>
                    <Field
                      name={`${outputName}.phase_id`}
                      component={ReactSelectInput}
                      props={{
                        label: I18n.t('ei.processing.form.newPhase'),
                        options: phases,
                        textKey: 'name',
                        valueKey: 'id',
                        isRequired: true,
                        disabled: isCompleted,
                      }}
                    />
                  </Col>

                  <WillRender ifTrue={!isUtahGlobalIdGenerationFeatureEnabled && hasPackagesTags}>
                    <Col md={3}>
                      <Field
                        name={`${outputName}.tag_requested`}
                        component={ReactSelectInput}
                        props={{
                          label: I18n.t('plants.modify.needsTag'),
                          isRequired: false,
                          options: [{text: I18n.t('common.yes'), value: 1 } , {text: I18n.t('common.no'), value:  0}],
                          textKey: 'text',
                          valueKey: 'value',
                          defaultValue: {text : I18n.t('common.yes'), value : 1 },
                          placeholder: I18n.t('common.common.yes')
                        }}
                      />
                    </Col>
                  </WillRender>

                  <WillRender ifTrue={isCanada}>
                    <Col md={3}>
                      <Field name={`${outputName}.finished`} component={InlineCheckBox} props={{
                        label: I18n.t('common.form.finishedInventoryCheckbox'),
                      }}/>
                    </Col>
                  </WillRender>

                  <WillRender ifTrue={isMetrc}>
                    <Col md={3}>
                      <ReduxMetrcIdField
                        name={`${outputName}.state_integration_tracking_id`}
                        props={{
                          options: trackingIds,
                          disabled: isCompleted,
                          isRequired: false,
                        }}
                      />
                    </Col>
                  </WillRender>

                  <WillRender ifTrue={isWaLeaf}>
                    <Col md={3}>
                      <Field
                        name={`${outputName}.integration_type`}
                        component={ReactSelectInput}
                        props={{
                          label: I18n.t('inventory.form.inventoryType'),
                          isRequired: true,
                          options: waInventoryTypesOptions,
                        }}
                      />
                    </Col>
                  </WillRender>

                  <WillRender ifTrue={isBiotrack}>
                    <Col md={3}>
                      <Field
                        name={`${outputName}.integration_type`}
                        component={ReactSelectInput}
                        props={{
                          label: I18n.t('inventory.form.inventoryType'),
                          isRequired: true,
                          options: integrationTypeOptions,
                        }}
                      />
                    </Col>
                  </WillRender>

                  <MetrcConcealer>
                    <Col md={3}>
                      <Field
                        name={`${outputName}.is_produced`}
                        component={ReactSelectInput}
                        props={{
                          label: I18n.t('metrc.form.isProductionBatch'),
                          options: [
                            {text: 'Yes', value: 1},
                            {text: 'No', value: 0}
                          ]
                        }}
                      />
                    </Col>
                  </MetrcConcealer>
                </Row>

                <Row>
                  <Col md={3}>
                    <InternationalDateTimePickerInput
                      name={`${outputName}.packaged_at`}
                      props={{
                        label: I18n.t('packaging.form.actualCompletionTime'),
                        timeFormat: true,
                        isRequired: false,
                      }}/>
                  </Col>
                  <Col md={3}>
                    <InternationalDateTimePickerInput
                      name={`${outputName}.package_created_at`}
                      props={{
                        label: I18n.t('packaging.form.packageCreatedAt'),
                        timeFormat: false,
                      }}/>
                  </Col>
                  <Col md={3}>
                    <InternationalDateTimePickerInput
                      name={`${outputName}.package_expires_at`}
                      props={{
                        label: I18n.t('packaging.form.packageExpiresAt'),
                        timeFormat: false,
                        isRequired: Boolean(output.tag_requested),
                        enableTodayButton: false,
                      }}/>
                  </Col>
                  <Col md={3}>
                    <PackagePurposeField name={`${outputName}.purpose`}/>
                  </Col>
                </Row>

                {(isWaLeaf) && (
                  <Row>
                    <Col xs={3} md={2} offset={1} offset={2}>
                      <Field
                        name={`${outputName}.product_not_altered`}
                        component={CheckBoxInput}
                        props={{
                          groupClassName: 'inline-checkbox',
                          label: I18n.t('packaging.form.leafWa.productNotAltered'),
                          disabled: initialValues.inputs && initialValues.inputs.length > 1,
                        }}
                      />
                    </Col>
                    <Col sm={3} md={8} xs={3} offset={2}>
                      <div className='alert alert-info' style={{padding: '5px'}}>
                        {I18n.t('packaging.form.leafWa.productNotAlteredNote')}
                      </div>
                    </Col>
                    <Col xs={3} md={2} offset={1} offset={2}>
                      <Field
                        name={`${outputName}.medically_compliant`}
                        component={CheckBoxInput}
                        props={{
                          groupClassName: 'inline-checkbox',
                          label: I18n.t('packaging.form.leafWa.medicallyCompliant'),
                        }}
                      />
                    </Col>
                    {(isAllInputsAreMedicallyComplaint && !isMedicallyComplaintCheckedFor(outputIndex)) && (
                      <Col sm={3} md={8} xs={3} offset={2}>
                        <div className='alert alert-info' style={{padding: '5px'}}>
                          {I18n.t('packaging.form.leafWa.medicallyCompliantNote')}
                        </div>
                      </Col>
                    )}
                  </Row>
                )}
                <Row>
                  {isBiotrack && (
                    <Col xs={3} md={2} offset={1} offset={2}>
                      <Field
                        name={`${outputName}.integration_adjustment_reason`}
                        component={ReactSelectInput}
                        props={{
                          label: I18n.t('inventory.form.adjustmentReason'),
                          isRequired: false,
                          options: adjustmentReasons,
                          valueKey: 'code',
                          textKey: 'name'
                        }}
                      />
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col xs={1} md={1}>
                    <Field
                      name={`${outputName}.id`}
                      component={TextInput}
                      style={{display: 'none'}}
                      props={{
                        groupClassName: 'hidden'
                      }}
                    />
                  </Col>
                </Row>

                <WillRender ifTrue={isLabelEnabledForNoCompletedProcess}>
                  <Row>
                    <Col md={3}>
                      <InternationalQuantityByUomInput
                        name={`${outputName}.usable_mmj_weight`}
                        label={I18n.t('ei.processing.form.usableMMJWeight')}
                        disabled={isCompleted}
                        props={{
                          options: uomOptions,
                          selectable: true,
                          selectableName: `${outputName}.usable_mmj_weight_uom`,
                          defaultUom: uom,
                        }}
                      />
                    </Col>
                    <Col md={3}>
                      <InternationalQuantityByUomInput
                        name={`${outputName}.concentrate_weight`}
                        label={I18n.t('ei.processing.form.concentrateWeight')}
                        disabled={isCompleted}
                        props={{
                          options: uomOptions,
                          selectable: true,
                          selectableName: `${outputName}.concentrate_weight_uom`,
                          defaultUom: uom,
                        }}
                      />
                    </Col>
                  </Row>
                </WillRender>

                <PermissionWrapper permissions={[p.rate_products]}>
                <Row>
                  <Col md={12}>
                    <div style={{border: '1px double #efefef', padding: '1rem'}}>
                      <div>Quality Ratings</div>
                        <Row>
                          <Col md={2}>
                            <Field name={`${outputName}.rating_color`} component={ReactSelectInput} props={{
                              label: I18n.t('ei.processing.form.color'),
                              options: ratingOptions,
                              disabled: isCompleted,
                            }}/>
                          </Col>
                          <Col md={2}>
                            <Field name={`${outputName}.rating_clarity`} component={ReactSelectInput} props={{
                              label: I18n.t('ei.processing.form.clarity'),
                              options: ratingOptions,
                              disabled: isCompleted,
                            }}/>
                          </Col>
                          <Col md={2}>
                            <Field name={`${outputName}.rating_aroma`} component={ReactSelectInput} props={{
                              label: I18n.t('ei.processing.form.aroma'),
                              options: ratingOptions,
                              disabled: isCompleted,
                            }}/>
                          </Col>
                          <Col md={2}>
                            <Field name={`${outputName}.rating_consistency`} component={ReactSelectInput} props={{
                              label: I18n.t('ei.processing.form.consistency'),
                              options: ratingOptions,
                              disabled: isCompleted,
                            }}/>
                          </Col>
                          <Col md={2}>
                            <Field name={`${outputName}.rating_avg_total_quality`} component={ReactSelectInput} props={{
                              label: I18n.t('ei.processing.form.totalQuality'),
                              options: ratingOptions,
                              disabled: isCompleted,
                            }}/>
                          </Col>
                        </Row>
                    </div>
                  </Col>
                </Row>
                </PermissionWrapper>
              </div>
              </div>
            );
          })}
          <Row>
            <WillRender ifTrue={!isLeaf && !isBiotrack}>
              <div style={{marginTop: '12px', marginLeft: '2px'}}>
                <AddItem
                  textMode={'right'}
                  alignMode={'left'}
                  text={'ei.processing.form.addItem'}
                  addAction={() => {
                    const lastPackage = get(length - 1);
                    const newPackage = Object.assign({}, {batchName}, {
                      packaged_at: lastPackage.packaged_at,
                      package_created_at: lastPackage.package_created_at
                    });

                    newPackage['tag_requested'] = (hasPackagesTags ? 1 : 0);

                    push(newPackage);
                  }}
                  addDisabled={isCompleted}
                />
              </div>
            </WillRender>
          </Row>
        </div> :
        <Alert variant='danger'>
          <FaExclamationTriangle className='padding-right'/>
          <span>{I18n.t('ei.processing.form.noValidProductAvailable')}</span>
        </Alert>
      }
    </div>
  );
};

OutputsFieldArray.propTypes = {
  fields: PropTypes.shape({
    push: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    map: PropTypes.func.isRequired,
    length: PropTypes.number.isRequired
  }).isRequired,
  isManufacturing: PropTypes.bool,
  products: PropTypes.array.isRequired,
  productionRuns: PropTypes.array.isRequired,
  trackingIds: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  phases: PropTypes.array.isRequired,
  integrationTypeOptions: PropTypes.array,
  batchName: PropTypes.string,
  loading: PropTypes.bool,
  isCompleted: PropTypes.bool,
  integrationState: PropTypes.object.isRequired,
  adjustmentReasons: PropTypes.array,
  initialValues: PropTypes.object,
  waInventoryTypes: PropTypes.array,
  onChangeItem: PropTypes.func.isRequired,
  isMedicallyComplaintCheckedFor:PropTypes.func,
  isAllInputsAreMedicallyComplaint:PropTypes.bool,
  isLabelEnabledForNoCompletedProcess:PropTypes.bool,
  hasPackagesTags: PropTypes.bool,
  uoms: PropTypes.array,
  isMetrcStemHoldingsToggled: PropTypes.bool,
  isMetrcCaDonationsToggled: PropTypes.bool,
  isUtahGlobalIdGenerationFeatureEnabled: PropTypes.bool,
};

const form = COMPLETE_PROCESSING_FORM;

export default connect(
  (state, ownProps) => {
    const selector = formValueSelector(form);
    const outputs = selector(state, 'outputs');
    return {
      isMetrcStemHoldingsToggled: isFeatureEnabled(state)('feature_metrc_stem_holdings'),
      isMetrcCaDonationsToggled: isFeatureEnabled(state)('feature_metrc_ca_donations'),
      isUtahGlobalIdGenerationFeatureEnabled: isFeatureEnabled(state)('feature_utah_global_id_generation'),
      integrationTypeOptions: getProcessingInvTypes(state),
      isMedicallyComplaintCheckedFor: (index) => lodashGet(outputs, `[${index}].medically_compliant`) === 1,
      isAllInputsAreMedicallyComplaint: lodashGet(ownProps, 'initialValues.inputs', []).every((input) => input.medically_compliant_status === 'yes'),
      uoms: state[dataNames.uoms],
    };
  }
)(OutputsFieldArray);
