import * as itemNames from './itemNames';
import * as dataNames from './dataNames';

export default [
  itemNames.customerRecentHistory,
  itemNames.customerStat,
  itemNames.localPrintServer,
  dataNames.registers,
  dataNames.feedingSchedules,
  dataNames.customerGroups,
  dataNames.adjustmentReasons,
  dataNames.locations,
  dataNames.inventoryLocations,
  dataNames.referralSources,
  dataNames.stages,
  dataNames.uoms,
  dataNames.modules,
  dataNames.roles,
  dataNames.roleTypes,
  dataNames.partnerGroups,
  dataNames.salesRegisters,
  dataNames.ingredientCategories,
  dataNames.buildings,
  dataNames.sections,
  dataNames.categories,
  dataNames.subcategories,
  dataNames.pricingGroups,
  dataNames.pricingWeights,
  dataNames.prepackWeights,
  dataNames.salesLocations,
  dataNames.storageLocations,
  dataNames.equipmentTypes,
  dataNames.testResultGroups,
  dataNames.zones,
  dataNames.rooms,
  dataNames.phases,
  dataNames.purposes,
  dataNames.itemPhases,
  dataNames.organizationStrains,
  dataNames.facilityStrains,
  dataNames.drivers,
  dataNames.vehicles,
  dataNames.countries,
  dataNames.provinces,
  dataNames.featureToggles,
  itemNames.integrationSettings,
  itemNames.patientComplianceSettings,
  itemNames.complianceSettings,
  itemNames.cultivationSettings,
  itemNames.salesComplianceSettings,
  itemNames.labelsCompliance,
  itemNames.activeThirdPartyIntegrations,
  itemNames.metrcTransfersEnabled,
  itemNames.settingsHash,
  itemNames.coreSettings,
  itemNames.internationalSettings,
  itemNames.distributionSettings,
];
