export const getStateLabel = (countryCode) => {
  switch (true){
  case ['UY', 'CO'].includes(countryCode):
    return 'common.form.department';
  case ['ZA', 'CA'].includes(countryCode):
    return 'common.form.province';
  case ['NZ'].includes(countryCode):
    return 'common.form.region';
  default:
    return 'common.form.state';
  }
};

export const getPostalCodeLabel = (countryCode) => {
  switch (true){
  case ['UY', 'CO'].includes(countryCode):
    return 'common.form.postCode';
  case ['NZ', 'AU', 'CA', 'ZA'].includes(countryCode):
    return 'common.form.postalCode';
  default:
    return 'common.form.zipCode';
  }
};