import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';

const SegmentedQueueTabs = ({currentQueue, customers, segments, activeSegmentId, onSelectSegment}) => {

  const validQueues = ['in_store'];
  if(validQueues.indexOf(currentQueue) === -1) return null;
  if(!Array.isArray(segments)) return null;

  const customerFieldMap = {
    in_store: 'visit_reason_id',
    delivery: 'zone_id'
  };

  const segmentIds = segments.map((segment) => segment.id);
  //if queue order doesn't have visit_reason_id it should be appear on first tab after all
  const firstSegmentColumnId = get(segments, '1.id', 1);

  return (<ul className='nav nav-tabs'>
    {
      segments.map((segment, index) => {
        const customersInSegment = customers.filter((customer) => {
          const customerSegmentId = (segmentIds.indexOf(customer[customerFieldMap[currentQueue]]) === -1) ? firstSegmentColumnId : customer[customerFieldMap[currentQueue]];
          return segment.id ? customerSegmentId === segment.id : true;
        });
        return (<li
          key={index}
          role='presentation'
          className={activeSegmentId === segment.id ? 'queue-tab-li active' : 'queue-tab-li'}
        >
          <a onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            onSelectSegment(segment.id);
          }} >
            {segment.name} <span>({customersInSegment.length})</span>
          </a>
        </li>);
      })
    }
  </ul>);

};

SegmentedQueueTabs.propTypes = {
  currentQueue: PropTypes.string,
  customers: PropTypes.array,
  segments: PropTypes.array,
  activeSegmentId: PropTypes.number,
  onSelectSegment: PropTypes.func.isRequired,
};

export default SegmentedQueueTabs;
