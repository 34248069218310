import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as dataNames from '../../../constants/dataNames';
import { getSearchData } from '../../../actions/apiActions';
import { unsetData } from '../../../actions/dataActions';
import { addMessage } from '../../../actions/systemActions';
import ScanSearchComponent from '../../common/scan-searches/ScanSearchComponent';
import { getPackageOptions } from '../../../selectors/testResultsSelectors';

function mapStateToProps(state) {
  return {
    foundItems: getPackageOptions(state, {form: 'addTestResults', isPlants: true, getAll: true})
  };
}

function mapDispatchToProps(dispatch) {
  const internalActions = bindActionCreators({getSearchData, unsetData}, dispatch);
  const performSearch = (value) => {
    internalActions.unsetData(dataNames.plants);
    const params = {
      query: value,
      size: 10000,
      start: 0,
      sort: 'plant_id asc',
      filter: 'is_harvested: 0',
    };
    return internalActions.getSearchData(
      '/api/search/plants',
      dataNames.plants,
      {failed:'cart.getScannedItem.failed'},
      params
    );
  };
  return {
    actions: {
      performSearch,
      ...bindActionCreators({addMessage}, dispatch),
    },
  };
}

const GetPlantGroupsById = connect(mapStateToProps, mapDispatchToProps)(ScanSearchComponent);

GetPlantGroupsById.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.node,
  ]),
  showLoader: PropTypes.bool,
  loadingMessage: PropTypes.string,
  onSearchStart: PropTypes.func,
  onSearchComplete: PropTypes.func,
  onSearchFailed: PropTypes.func,
  disabled: PropTypes.bool,
};

export default GetPlantGroupsById;
