import {pick} from 'lodash';
import sortBy from 'lodash.sortby';
import {createSelector} from 'reselect';
import {getIntegrationState} from './integration/integrationSelectors';
import {formatInternationalInputDate} from '../util/dateHelpers';
import {getInternationalDateFormat} from './InternationalOperationsSelectors';

const getIntegratedTransfer = state => state.integratedTransfer;
const getIntegratedTransfers = state => state.integratedTransfers;
const getInternalOrder = state => state.internalOrder;
const getInternalOrders = state => state.internalOrders;
const getItemMasters = state => state.itemMasters;

export const getIntegratedTransfersOptions = createSelector(
  [getIntegratedTransfers, getIntegrationState, getInternationalDateFormat],
  (transfers, integrationState, dateFormat) =>
    sortBy(
      transfers.map(transfer => {
        //lets format the date portion of the name into an international format
        const nameParts = transfer.transfer_name.split(' - ');
        nameParts[0] = formatInternationalInputDate(nameParts[0], dateFormat);

        transfer.transfer_name = nameParts[0] + ' - ' + nameParts[1];

        const license_number = (integrationState.isLeaf || integrationState.isBiotrack) ? ` (${transfer.transfer_from_license})` : '';
        return {
          value: transfer.external_id,
          text: `${transfer.transfer_from_name}${license_number}  - ${transfer.transfer_name}`
        };
      }),
      'text'
    )
);


export const getInternalOrdersOptions = createSelector(
  [getInternalOrders, getIntegrationState, getInternationalDateFormat],
  (orders, {isMetrc}, dateFormat) =>
    sortBy(
      orders
        .filter(order => !isMetrc || order.contains_medicated)
        .map(order => {
          //lets format the date portion of the title into an international format
          const titleParts = order.title.split(' ');
          titleParts[titleParts.length - 1] = formatInternationalInputDate(titleParts[titleParts.length - 1], dateFormat);
          order.title = titleParts.join(' ');

          const text = `${order.title} - ${order.po_number || order.sales_order_number}`;
          return {
            value: order.id,
            text
          };
        }),
      'text'
    )
);

export const getItemMastersOptions = createSelector(
  getItemMasters, itemMasters => {
    return sortBy(
      itemMasters.map(itemMaster => {
        return {
          ...itemMaster,
          value: itemMaster.id,
          text: itemMaster.name,
          default_uom: itemMaster.default_uom,
        };
      }),
      'text'
    );
  }
);

/***
 * Gets key values from the incoming package data for leaf
 * @return {Array}
 */
const getIntegratedTransferProducts = createSelector(
  getIntegratedTransfer, integratedTransfer => {
    const products = [];
    const quantityField = 'transfer_qty';
    if (Array.isArray(integratedTransfer.packages)) {
      sortBy(integratedTransfer.packages, 'product_name').forEach(pkg => {
        products.push({
          product_label: `${pkg[quantityField]} ${pkg.transfer_uom || ''} ${pkg.product_name}`,
          strain_name: pkg.strain_name || '',
          product_name: pkg.product_name,
          transfer_uom: pkg.transfer_uom,
          packages: [pkg]
        });
      });
    }
    return products;
  }
);

const getIntegratedTransferItemCount = createSelector(
  [getIntegratedTransfer],
  integratedTransfer => integratedTransfer && Number(integratedTransfer.package_count) || 0
);

const getInternalOrderItemCount = createSelector(
  [getInternalOrder, getIntegrationState],
  (order, {isLeaf, isBiotrack}) => {
    const lines = order.linked_transfer_details ? order.linked_transfer_details.lines : order.lines;
    return (lines || []).reduce(
      (itemCount, line) => {
        if (isBiotrack) {
          if (line.inventory && line.inventory.length) {
            return itemCount + line.inventory.length;
          }
          return itemCount + 1;
        } else if (!isLeaf) {
          return itemCount + 1;
        }
        //For LEAF, we handle each pre-pack as item
        const subItemCount = (line.subitems || []).filter(subItem => subItem && subItem.qty > 0).length;
        return itemCount + (subItemCount || 1);
      },
      0
    );
  }
);

export const getMappingFormInitialValues = createSelector(
  [getIntegratedTransfer, getIntegratedTransferProducts, getInternalOrderItemCount, getIntegratedTransferItemCount, getIntegrationState],
  (integratedTransfer, products, internalItemCount, externalItemCount, {isLeaf}) => {
    const values = {
      ...pick(integratedTransfer, ['driver_license_number', 'driver_state_license_number', 'vehicle_license_plate']),
      item_count: internalItemCount || '',
      product_count: externalItemCount || '', // getting value of the product total count from back-end side
      isLeaf,
    };
    if (products.length) {
      values.products = products;
    }
    return values;
  }
);

export const hasAllProductCounts = createSelector(
  [getInternalOrderItemCount, getIntegratedTransferItemCount],
  (internalItemCount, externalItemCount) => Boolean(internalItemCount && externalItemCount)
);

/**
 * Checks if product count matches.
 * @return {boolean}
 */
export const hasEqualProductCounts = createSelector(
  [getInternalOrderItemCount, getIntegratedTransferItemCount, getIntegrationState, getInternalOrder],
  (internalItemCount, externalItemCount, {isLeaf, isBiotrack}, internalOrder) => {
    if (isLeaf) return true;
    if (isBiotrack && internalOrder && !internalOrder.linked_transfer_details) return true;
    return internalItemCount === externalItemCount;
  }
);

export const hasValidProductCounts = createSelector(
  hasAllProductCounts, hasEqualProductCounts, (hasAll, hasEqual) => hasAll && hasEqual
);
