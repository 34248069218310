import { Field } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import {FaCheck, FaTimes} from 'react-icons/fa';
import get from 'lodash.get';
import NumericInput from '../../../common/form/NumericInput';
import ReactSelectInput from '../../../common/form/ReactSelectInput';

const InfusionPotencyTable = (props) => {
  const { assemblies, change, getFormValue, tableData: { required, batches, results, totals } } = props;

  const okIcon = <FaCheck aria-hidden='true'/>;
  const removeIcon = <FaTimes className='text-danger' aria-hidden='true'/>;

  /**
   * Calculate how many grams of the package specified by the index is needed to meet the required value for the specified cannabinoid key
   * @param key
   * @param index
   */
  const calculate = (key, index) => {
    // Determine gr needed of by this package to meet the required amount
    const required_mg_of_cannabinoid = get(required, key);
    const current_mg_of_cannabinoid = get(totals, key);
    const current_mg_of_cannabinoid_from_this_package = get(batches, '[' + index + '].' + key);
    const mg_needed_of_cannabinoid_from_this_package = required_mg_of_cannabinoid - current_mg_of_cannabinoid + current_mg_of_cannabinoid_from_this_package;
    const mg_of_cannabinoid_per_gram_from_this_package = get(batches, '[' + index + '].packageTestResult.' + key);
    const gr_needed_of_this_package = mg_needed_of_cannabinoid_from_this_package / mg_of_cannabinoid_per_gram_from_this_package;
    // Don't use more than there is available
    const max_qty = getFormValue('inputs[' + index + '].maxQty');
    let new_qty = gr_needed_of_this_package > max_qty ? max_qty : gr_needed_of_this_package;
    // Round up to max six decimals
    new_qty = Math.ceil(new_qty * 1000000) / 1000000;
    change('inputs[' + index + '].qty', new_qty);
  };
  const renderCalculationButton = (key) => {
    return (
      <Dropdown>
        <Dropdown.Toggle
          style={{ marginBottom: '12px' }}
          variant='primary'
          id={`dropdown-${key}`}
        >
          {I18n.t('ei.infusion.create.form.calculate')}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {batches.map((batch, index) => {
            if (!batch.packageTestResult || !batch.packageTestResult[key]) return null;
            const menuItemKey = key + '[' + index + ']';
            return (
              <Dropdown.Item
                id={menuItemKey}
                key={menuItemKey}
                onSelect={() => calculate(key, index)}
              >
                {batch.optionName}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const renderTotals = (key) => {
    return (
      <span>
        {get(results, key) && okIcon} {(get(required, key) > get(totals, key)) && removeIcon}
        &nbsp;{get(totals, key)}
        <div className='text-danger'>{get(required, key) > get(totals, key) ? '(' + (Math.round((get(required, key) - get(totals, key)) * 1000000) / 1000000) + ' ' + I18n.t('ei.infusion.create.form.remaining') + ')' : null}</div>
      </span>
    );
  };

  return (
    <table className='potency-table'>
      <thead>
        <tr>
          <th className='assembly-id'>
            <Field
              name='assembly_id'
              component={ReactSelectInput}
              props={{
                label: I18n.t('ei.infusion.create.form.selectAssembly'),
                options: assemblies,
                textKey: 'name',
                valueKey: 'id',
                isRequired: true,
                onChange: (id) => {
                  const currentAssembly = assemblies.find(assembly => assembly.id === id);

                  change('assembly_id', currentAssembly ? id : '');
                  change('assembly_qty', currentAssembly ? currentAssembly.qty_base : '');
                }
              }}
            />
          </th>
          <th className='assembly-qty'>
            <Field
              name='assembly_qty'
              component={NumericInput}
              props={{
                label: I18n.t('ei.infusion.create.form.qtyAssembly'),
                fractionPartSize: 0,
                onChange: (qty) => {
                  change('assembly_qty', qty);
                },
                isRequired: true
              }}
            />
          </th>
          <th className='potency-table-header' colSpan='8'>
            <div className='header-label'>{I18n.t('ei.infusion.create.form.cannabinoidTableTitle')}</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr className='potency-keys'>
          <td colSpan='2'/>
          <td>THC</td>
          <td>THCA</td>
          <td>THCV</td>
          <td>CBD</td>
          <td>CBDV</td>
          <td>CBG</td>
          <td>CBC</td>
          <td>CBN</td>
          <td>{I18n.t('ei.assemblies.form.totalCannabinoidProfile')}</td>
        </tr>
        <tr className='requires'>
          <td className='require' colSpan='2'>{I18n.t('ei.infusion.create.form.required')}</td>
          <td>{required.thc || 0}</td>
          <td>{required.thca || 0}</td>
          <td>{required.thcv || 0}</td>
          <td>{required.cbd || 0}</td>
          <td>{required.cbdv || 0}</td>
          <td>{required.cbg || 0}</td>
          <td>{required.cbc || 0}</td>
          <td>{required.cbn || 0}</td>
          <td>{required.total_cb_profile || 0}</td>
        </tr>
        {batches.map((batch, index) => ((
          <tr className='batches' key={index}>
            <td className='batch-name' colSpan='2'>{batch.optionName}</td>
            <td>{batch.thc}</td>
            <td>{batch.thca}</td>
            <td>{batch.thcv}</td>
            <td>{batch.cbd}</td>
            <td>{batch.cbdv}</td>
            <td>{batch.cbg}</td>
            <td>{batch.cbc}</td>
            <td>{batch.cbn}</td>
            <td>{batch.total_cb_profile}</td>
          </tr>
        )))}
        <tr className='results'>
          <td className='totals' colSpan='2'>{Object.keys(results).length !== 0 && I18n.t('ei.infusion.create.form.totals')}</td>
          <td>{renderTotals('thc')}</td>
          <td>{renderTotals('thca')}</td>
          <td>{renderTotals('thcv')}</td>
          <td>{renderTotals('cbd')}</td>
          <td>{renderTotals('cbdv')}</td>
          <td>{renderTotals('cbg')}</td>
          <td>{renderTotals('cbc')}</td>
          <td>{renderTotals('cbn')}</td>
          <td>{renderTotals('total_cb_profile')}</td>
        </tr>
        <tr className='calculate'>
          <td colSpan={2}/>
          <td>{required.thc ? renderCalculationButton('thc') : null}</td>
          <td>{required.thca ? renderCalculationButton('thca') : null}</td>
          <td>{required.thcv ? renderCalculationButton('thcv') : null}</td>
          <td>{required.cbd ? renderCalculationButton('cbd') : null}</td>
          <td>{required.cbdv ? renderCalculationButton('cbdv') : null}</td>
          <td>{required.cbg ? renderCalculationButton('cbg') : null}</td>
          <td>{required.cbc ? renderCalculationButton('cbc') : null}</td>
          <td>{required.cbn ? renderCalculationButton('cbn') : null}</td>
          <td>{required.total_cb_profile ? renderCalculationButton('total_cb_profile') : null}</td>
        </tr>
      </tbody>
    </table>
  );
};

InfusionPotencyTable.propTypes = {
  assemblies: PropTypes.array,
  change: PropTypes.func.isRequired,
  getFormValue: PropTypes.func.isRequired,
  tableData: PropTypes.shape({
    assemblyQty: PropTypes.number,
    required: PropTypes.object,
    batches: PropTypes.array,
    results: PropTypes.object,
  }),
};

export default InfusionPotencyTable;
