import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {push} from 'react-router-redux';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import moment from 'moment';

//import Search from '../../common/grid/Search';
import PageTitle from '../../common/PageTitle';
import PriceColumn from '../../common/grid/columns/PriceColumn';
import TablePageWrapper from '../../common/grid/TablePageWrapper';

import * as apiActions from '../../../actions/apiActions';
import * as dataNames from '../../../constants/dataNames';

import {handleSingleSelectedRow} from '../../../actions/helpers/selectedDataHelper';
import {getSelectedTrackingIds} from '../../../selectors/trackingIdsSelectors';

export class TrackingIdsPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.handleSelect = this.handleSelect.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
    this.activateDeactivateIds = this.activateDeactivateIds.bind(this);
    const columns = [
      {
        name: 'tracking.table.type',
        dataId: 'type',
        width: '200px',
        hidden: false,
        dataSort: true
      },
      {
        name: 'tracking.table.dateReceived',
        dataId: 'received_at',
        formatter: cell => moment(cell).format('YYYY-MM-DD'),
        width: '200px',
        hidden: false,
        dataSort: true
      },
      {
        name: 'tracking.table.orderTotal',
        dataId: 'cost',
        csvFormatter: (cell) => { return '$' + cell; },
        formatter: PriceColumn,
        width: '150px',
        hidden: false,
        dataSort: true
      },
      {
        name: 'tracking.table.quantityReceived',
        dataId: 'qty_received',
        width: '200px',
        hidden: false,
        dataSort: true
      },
      {
        name: 'tracking.table.beginningId',
        dataId: 'starting_tag',
        width: '200px',
        hidden: false,
        dataSort: true
      },
      {
        name: 'tracking.table.endingId',
        dataId: 'ending_tag',
        width: '200px',
        hidden: false,
        dataSort: true
      }
    ];
    this.state = {
      searchString: '',
      trackingIdsToView: [],
      columns
    };
  }

  componentWillMount() {
    this.props.actions.getUnpaginatedData('/api/tracking/tag-groups', dataNames.trackingIds).then(() => {
      this.setState({trackingIdsToView: this.props.trackingIds});
    });
  }

  handleSelect(isSelected, rows) {
    this.props.actions.handleSingleSelectedRow(rows, dataNames.trackingIds, isSelected);
  }


  activateDeactivateIds() {
    const {selectedTrackingIds} = this.props;
    if (selectedTrackingIds[0]) {
      this.props.actions.push(`/integration/tracking/activate-deactivate/${selectedTrackingIds[0]}`);
    }
  }

  testForInBetweenIDsRange(row, value) {
    const tailPartRegExp = /\d+$/;
    const beginningIdTailPart = tailPartRegExp.exec(row.starting_tag);
    const endingIdTailPart = tailPartRegExp.exec(row.ending_tag);
    if (beginningIdTailPart && endingIdTailPart) {
      const beginningIdBeginningPart = row.starting_tag.split(beginningIdTailPart[0])[0];
      if (value.indexOf(beginningIdBeginningPart) === 0) {
        const queryTailPart = tailPartRegExp.exec(value);
        if (queryTailPart && queryTailPart[0].length >= beginningIdTailPart[0].length) {
          return (queryTailPart[0] - beginningIdTailPart[0] >= 0) && (queryTailPart[0] - endingIdTailPart[0] <= 0);
        }
      }
    }
    return false;
  }

  updateSearch(event) {
    const {value} = event.target;
    const {trackingIds} = this.props;
    const trackingIdsToView = trackingIds
      .filter(trackingId =>
        Object.keys(trackingId)
          .some(key =>
            trackingId[key].toString().toLowerCase().indexOf(value.toLowerCase()) > -1
          ) || this.testForInBetweenIDsRange(trackingId, value)
      );
    this.setState({searchString: value, trackingIdsToView: trackingIdsToView});
  }

  render() {
    const {selectedTrackingIds} = this.props;
    const {trackingIdsToView, columns} = this.state;
    const actions = [
      {
        id: 'receiveTrackingIds',
        path: '/integration/tracking/receive',
        text: 'tracking.actions.receiveTrackingIds',
        glyph: 'plus-sign',
        requireSelect: false
      },
      {
        id: 'activateInactivateTrackingIds',
        func: this.activateDeactivateIds,
        text: 'tracking.actions.activateDeactivateIds',
        glyph: 'off',
        requireSelect: true
      }
    ];

    return (
      <div className='tracking-ids-page'>
        <PageTitle primaryText={I18n.t('tracking.title')}/>
        {/*<Search
          searchString={this.state.searchString}
          updateSearch={this.updateSearch}
          scanSearchEnabled={false}
        />*/}
        <TablePageWrapper
          ref={this.ref}
          settingKey='integration-tracking'
          columns={columns}
          data={trackingIdsToView}
          actions={actions}
          selectedRows={selectedTrackingIds}
          handleSelect={this.handleSelect}
          hideSearch={true}
          hideScanSearch={true}
          className='tab-listing'
          bstProps={{
            ignoreSinglePage: true,
            selectRow: {
              mode: 'radio'
            }
          }}
        />
      </div>
    );
  }
}

TrackingIdsPage.propTypes = {
  trackingIds: PropTypes.array.isRequired,
  selectedTrackingIds: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    handleSingleSelectedRow: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }).isRequired,
};

// TrackingIdsPage.contextTypes = {
//   router: PropTypes.object.isRequired
// };


function mapStateToProps(state) {
  return {
    trackingIds: state.trackingIds,
    selectedTrackingIds: getSelectedTrackingIds(state)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {handleSingleSelectedRow, push});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackingIdsPage);
