import React from 'react';
import PropTypes from 'prop-types';
import {push} from 'react-router-redux';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {getIntegrationState} from '../../../selectors/integration/integrationSelectors';
import TablePageWrapper from '../../common/grid/TablePageWrapper';
import * as dataNames from '../../../constants/dataNames';
import ModalWrapper from '../../common/ModalWrapper';
import ModalSearchProduct from './ModalSearchProduct';

import * as apiActions from '../../../actions/apiActions';

import {unsetData, unionData, removeData} from '../../../actions/dataActions';
import {handleComplexSelectRow} from '../../../actions/helpers/selectedDataHelper';
import {getFilterData, getProductsWithFilters, getSelectedSearchedItemMastersIds,
  getSelectedSearchedItemMasters, makePricingName} from '../../../selectors/forms/massModifyFormSelector';
import SingleActionColumn from '../../common/grid/columns/SingleActionColumn';

export class ProductsList extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.state = {
      showSearchModal: false,
      sizePerPage: 2,
      searching: false
    };

    this.hideSearchModal = this.hideSearchModal.bind(this);
    this.ref = React.createRef();
    this.showSearchModal = this.showSearchModal.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.addProducts = this.addProducts.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.removeItem = this.removeItem.bind(this);
  }

  componentWillMount() {

    const searchParams = {
      filter: 'prepack_weight_id:0 AND is_draft:0 AND is_ingredient:0 ',
      query: '',
      size: 10000,
      sort: 'name asc, display_name asc',
      start: 0,
      fields: 'id name subcategory_id subcategory_name category_id category_name active'
    };

    const promises = [
      this.props.actions.getSearchData('/api/search/item_masters', dataNames.massModifyProductsFiltered, null, searchParams)
    ];
    Promise.all(promises);
  }

  hideSearchModal() {
    this.props.actions.unsetData(dataNames.filteredProducts);
    this.setState({showSearchModal: false});
  }

  showSearchModal() {
    this.props.actions.unsetData(dataNames.filteredProducts);
    this.setState({showSearchModal: true});
  }

  handleSelect(isSelected, rows) {
    this.props.actions.handleComplexSelectRow(rows, dataNames.filteredProducts, isSelected);
  }

  handleSearch(formData) {
    this.setState({searching: true});
    const payload = {
      categories: formData.categories.map(item => item.id),
      subcategories: formData.subcategories.map(item => item.id),
      products: formData.products.map(item => item.id),
      pricing_groups: formData.pricing_groups.map(item => item.id),
      pricing_classes: formData.pricing_classes.map(item => item.id),
      pricing_search_type: formData.pricing_mode,
      active: formData.productStatuses.map(item => item.value),
    };
    this.props.actions.getDataByPost('/api/item_masters/get_by_filters', payload, dataNames.filteredProducts, null, {include_ingredients: 0})
      .then(() => {
        this.setState({searching: false});}
        )
      .catch(() => {this.setState({searching: false});});
  }

  addProducts() {
    const {products, selectedSearchedItemMasters} = this.props;
    const ids = [...products.map(item => item.id), ...selectedSearchedItemMasters.map(item => item.id)];
    this.props.actions.getDataByPost('/api/items/by_item_master_ids', {ids, org_level: true}, dataNames.inventoryItems);
    this.props.actions.unionData(selectedSearchedItemMasters, dataNames.products, 'id');
    this.props.actions.unsetData(dataNames.filteredProducts);
    this.props.actions.unsetData('selectedFilteredProducts');
    this.props.actions.handleComplexSelectRow([], dataNames.filteredProducts, 'clear');
    const prepack_ids = [...products, ...selectedSearchedItemMasters]
      .filter(itemMaster => itemMaster.is_prepack || (itemMaster.inventory_attributes && itemMaster.inventory_attributes.is_prepack))
      .map(itemMaster => itemMaster.id);
    if(prepack_ids.length){
      this.props.actions.getUnpaginatedData('/api/item_masters/children/items', dataNames.childItemMasters, undefined, {ids: prepack_ids, is_waste: 0, include_inactive_items: true, org_level: true});
    }
    this.props.resetForm();
    this.hideSearchModal();
  }

  removeItem(event, row) {
    event.stopPropagation();
    this.props.actions.removeData([row], dataNames.products, 'id');
    this.props.actions.removeData([{item_master_id: row.id}], dataNames.inventoryItems, 'item_master_id');
    this.props.actions.removeData([{item_master_parent_id: row.id}], dataNames.childItemMasters, 'item_master_parent_id');
    this.props.resetForm();
  }

  render () {

    const {products, filterData, filteredProducts, selectedSearchedItemMastersIds, integrationState} = this.props;
    const {searching} = this.state;

    const columns = [
      {
        name: 'products.massModify.productListing.name',
        dataId:'name',
        dataSort: true,
        hidden: false,
        width: '250px',
      },
      {
        name: 'products.massModify.productListing.category',
        dataId:'category_name',
        dataSort: true,
        hidden: false,
        width: '200px',
      },
      {
        name: 'products.massModify.productListing.subcategory',
        dataId:'subcategory_name',
        dataSort: true,
        hidden: false,
        width: '200px',
      },
      {
        name: 'products.massModify.productListing.pricingGroup',
        dataId:'pricing_groups_name',
        dataSort: false,
        hidden: false,
        formatter: (cell, row) => (<span>{makePricingName(row.pricing_groups_name)}</span>)
      },
      {
        name: 'products.massModify.productListing.pricingClass',
        dataId: 'pricing_classes_name',
        dataSort: false,
        hidden: false,
        formatter: (cell, row) => makePricingName(row.pricing_classes_name)
      },
      {
        formatter: SingleActionColumn({
          label: 'products.massModify.productListing.remove',
          action: this.removeItem
        }),
        columnClassName: 'actions-column',
        hidden: false,
        dataSort: false,
        width: '80px',
      },
    ];

    const tabs = [
      {id: 'activeTab', title: '', actions:[{id: 'showSearchModal', text: 'products.massModify.productListing.addProducts', func: this.showSearchModal}]}
    ];

    return (
      <div className='table-page'>
        <TablePageWrapper
          ref = {this.ref}
          settingKey='mass_modify_products'
          data = {products}
          columns = {columns}
          hideScanSearch={true}
          hideExport={true}
          hideSearch={true}
          tabs={tabs}
          switchTab={() => {}}
        />
        <ModalWrapper
          Component={ModalSearchProduct}
          title={'products.massModify.productListing.modalTitle'}
          onHide={this.hideSearchModal}
          showModal={this.state.showSearchModal}
          filterData={filterData}
          integrationState={integrationState}
          handleSearch={this.handleSearch}
          searching={searching}
          products={filteredProducts}
          addProducts={this.addProducts}
          selectedSearchedItemMastersIds={selectedSearchedItemMastersIds}
          handleSelect={this.handleSelect}
        />
      </div>
    );
  }
}

ProductsList.propTypes = {
  products: PropTypes.array.isRequired,
  resetForm: PropTypes.func.isRequired,
  integrationState: PropTypes.object,
};

function mapStateToProps(state, ownProps) {

  return {
    products: ownProps.products,
    filterData: getFilterData(state),
    filteredProducts: getProductsWithFilters(state),
    selectedSearchedItemMastersIds: getSelectedSearchedItemMastersIds(state),
    selectedSearchedItemMasters: getSelectedSearchedItemMasters(state),
    resetForm: ownProps.resetForm,
    integrationState: getIntegrationState(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {push, unionData, unsetData, handleComplexSelectRow, removeData});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsList);
