import axios from 'axios';

const CancelToken = axios.CancelToken;

class CancelTokens {
  static cancelAll(){
    Object.keys(this.sources).forEach(source => this.sources[source].cancel('Logged out.'));
    this.sources = {};
  }

  static remove(url){
    delete this.sources[url];
  }

  static getToken(url){
    const source = CancelToken.source();
    this.sources[url] = source;
    return source.token;
  }
}

CancelTokens.sources = {};

export default CancelTokens;
