import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { goBack } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash.get';
import { convertFormInputDateToMidDay } from '../../../util/dateHelpers';
import * as apiActions from '../../../actions/apiActions';
import * as selectedDataActions from '../../../actions/selectedDataActions';
import * as itemNames from '../../../constants/itemNames';
import * as dataNames from '../../../constants/dataNames';
import { getDestroyPlantsPageProps } from '../../../selectors/forms/destroyPlantsFormSelectors';
import FormWrapper from '../../common/form/FormWrapper';
import DestroyPlantFormWrapper from './DestroyPlantFormWrapper';
import InProgressOverlay from '../../common/InProgressOverlay';

export class DestroyPlantPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.redirect = this.redirect.bind(this);
    this.showLoadingMessage = this.showLoadingMessage.bind(this);
    this.hideLoadingMessage = this.hideLoadingMessage.bind(this);
    this.getOverallPayload = this.getOverallPayload.bind(this);
    this.getMultiplePayload = this.getMultiplePayload.bind(this);
    this.getOverallMultiplePaylaod = this.getOverallMultiplePaylaod.bind(this);

    this.state = {
      today: new Date(),
      showLoadingMessage: false,
      showLoader: true,
      showOk: false,
      loadingMessage: 'plants.destroy.destroying',
      onDismiss: () => {},
      ignorePromise: false
    };
  }

  componentWillMount() {
    this.props.actions.getItem(
      '/api/settings/plants',
      itemNames.complianceSettings,
      { failed: 'cultivation.complianceSettings.failed' },
      { ids: ['cult_track_plants_as_groups'] }
    );

    if (this.props.requiresAdditionalDataForMetrc) {
      this.props.actions.getUnpaginatedData('/api/metrc/plants/waste_methods', dataNames.metrcPlantsWasteMethods);
      this.props.actions.getUnpaginatedData('/api/metrc/plants/waste_reasons', dataNames.metrcPlantsWasteReasons);
    }

  }

  showLoadingMessage(destruction) {
    const message = 'plants.destroy.destroying';
    const messageObject = {
      count: destruction.plants && destruction.plants.length,
      plantNoun: parseInt(destruction.starting_qty) === 1 ? I18n.t('common.plant') : I18n.t('common.plants')
    };
    this.setState({
      showLoadingMessage: true,
      showLoader: true,
      onDismiss: () => {},
      showOk: false,
      loadingMessage: message,
      messageObject: messageObject
    });
  }

  hideLoadingMessage() {
    this.setState({ showLoadingMessage: false });
  }

  onSubmit(destruction) {
    this.showLoadingMessage(destruction);
    let payload, url;

    if (destruction.use_overall) {
      // make sure we don't blindly send over a single plants destroyed_weight if the overall weight wasn't evenly distributed
      // if the user overall checkbox is selected the plant.destroyed_weight will be generated automatically, so it should always contain a value
      // additionally validateDestruction.js won't let the form submit unless this has a value, so this is pretty safe
      const plantsHaveSameDestroyedWeight = destruction.plants.every(plant => plant.destroyed_weight === destruction.plants[0].destroyed_weight);

      payload = plantsHaveSameDestroyedWeight
        ? this.getOverallPayload(destruction)
        : this.getOverallMultiplePaylaod(destruction);

      url = plantsHaveSameDestroyedWeight
        ? '/api/destructions'
        : '/api/destructions/multiple';
    } else {
      payload = this.getMultiplePayload(destruction);
      url = '/api/destructions/multiple';
    }

    this.props.actions.postItem(
      url,
      payload,
      itemNames.destruction,
      { success: 'plants.destroy.success', failed: 'plants.destroy.failed' },
      null,
      () => {
        this.hideLoadingMessage();
        this.props.actions.goBack();
      }
    ).catch(() => this.hideLoadingMessage());
  }

  /**
   * This returns a single object that will go to the /destructions endpoint
   * The single destroyed_weight value will get applied to all plants in plant_ids
   * @param {object} destruction - form values
   * @returns {object}
   */
  getOverallPayload(destruction) {
    const {selectedPlants, complianceSettings} = this.props;
    const trackPlantsAsGroups = get(complianceSettings, 'cult_track_plants_as_groups.value', false);
    const basePayload = this.getBasePayload(destruction);
    const plants = destruction.plants;

    const payload = {
      ...basePayload,
      plant_ids: plants.map((plant) => plant.id),
      destroyed_weight: plants[0].destroyed_weight,

    }

    // will only have values when cult_track_plants_as_group is true
    if (trackPlantsAsGroups) {
      // if plants_destroyed is empty we just assume the user wants to destroy all plants
      payload.plants_destroyed = plants.map((plant) => plant.plants_destroyed || 0) || []
      payload.plants_qty = plants.map((plant) =>
        get(selectedPlants.find((selectedPlant) => selectedPlant.id == plant.id), 'qty')
      ) || [];
    }

    return payload;
  }

  /**
   *
   * This returns an array of objects that will go to the destructions/multiple endpoint
   * Each plant has it's own destroyed_weight
   * @param {object} destruction - form values
   * @return {[]}
   */
  getMultiplePayload(destruction) {
    const {selectedPlants, complianceSettings} = this.props;
    const trackPlantsAsGroups = get(complianceSettings, 'cult_track_plants_as_groups.value', false);
    const basePayload = this.getBasePayload(destruction);

    return destruction.plants.map(plant => {
      const payload = {
        ...basePayload,
        plant_ids: [plant.id],
        destroyed_weight: plant.destroyed_weight,
      }

      // will only have values when cult_track_plants_as_group is true
      if (trackPlantsAsGroups) {
        // if plants_destroyed is empty we just assume the user wants to destroy all plants
        payload.plants_destroyed = plant.plants_destroyed || [];
        payload.plants_qty = get(selectedPlants.find((selectedPlant) => selectedPlant.id == plant.id), 'qty') || [];
      }

      return payload;
    });
  }

  /**
   * Handle the payload where the distributed weight isn't evenly split eg. 100 / 3
   * This is sort of a hybrid between the 2 other payload generators in that we return an array of payloads generated
   * by the getOverallPayload to be sent to the destructions/multiple endpoint
   * @param {object} destruction - form values
   * @return {[]}
   */
  getOverallMultiplePaylaod(destruction) {
    const plantsGroupedByDestroyedWeight = {};
    destruction.plants.forEach((plant) => {
      if (plantsGroupedByDestroyedWeight.hasOwnProperty(plant.destroyed_weight)) {
        plantsGroupedByDestroyedWeight[plant.destroyed_weight].push(plant);
      } else {
        plantsGroupedByDestroyedWeight[plant.destroyed_weight] = [plant]
      }
    });

    const payload = [];
    for (const plantGroup in plantsGroupedByDestroyedWeight) {
      destruction.plants = plantsGroupedByDestroyedWeight[plantGroup];
      payload.push(this.getOverallPayload(destruction));
    }

    return payload;
  }

  /**
   * Values that are common between the payload structures
   * @param {object} destruction
   * @return {object}
   */
  getBasePayload(destruction) {

    const { notes, plant_notes, destroyed_at } = destruction;
    const { reasons, requiresAdditionalDataForMetrc, timezone } = this.props;
    const reason = reasons.find((reason) => reason.value === notes);

    const basePayload = {
      uom: 'GR',
      notes,
      biotrack_reason: reason && reason.btReason,
      plant_notes,
      event_date: convertFormInputDateToMidDay(destroyed_at, timezone),
      destroyed_at: convertFormInputDateToMidDay(destroyed_at, timezone),
    }

    if (requiresAdditionalDataForMetrc) {
      basePayload.destroy_method = destruction.destroy_method;
      basePayload.mixed_materials = destruction.mixed_materials;
    }

    return basePayload;
  }

  redirect() {
    this.props.actions.goBack();
  }

  render() {
    const { selectedPlants, initialValues, reasons, complianceSettings, requiresAdditionalDataForMetrc, metrcWasteMethods, metrcWasteReasons  } = this.props;

    return (
      <FormWrapper title={'plants.destroy.title'} goBack={this.redirect}>
        <InProgressOverlay
          isActive={this.state.showLoadingMessage}
          messageObject={this.state.messageObject}
          onDismiss={this.state.onDismiss}
          showOk={this.state.showOk}
          showLoader={this.state.showLoader}
          message={this.state.loadingMessage}
          translate={true}
        />
        <DestroyPlantFormWrapper
          reasons={reasons}
          onSubmit={this.onSubmit}
          initialValues={initialValues}
          redirect={this.redirect}
          selectedPlants={selectedPlants}
          trackPlantsAsGroups={get(complianceSettings, 'cult_track_plants_as_groups.value', false)}
          requiresAdditionalDataForMetrc={requiresAdditionalDataForMetrc}
          metrcWasteMethods={metrcWasteMethods}
          metrcWasteReasons={metrcWasteReasons}
        />
      </FormWrapper>
    );
  }
}

DestroyPlantPage.propTypes = {
  actions: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
  reasons: PropTypes.array.isRequired,
  selectedPlants: PropTypes.array.isRequired,
  timezone: PropTypes.string.isRequired,
  complianceSettings: PropTypes.object.isRequired,
  requireAdditionalDataForMetrc: PropTypes.bool,
  metrcWasteMethods: PropTypes.array,
  metrcWasteReasons: PropTypes.array,
  requiresAdditionalDataForMetrc: PropTypes.bool,
};

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, selectedDataActions, { goBack });
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  getDestroyPlantsPageProps,
  mapDispatchToProps
)(DestroyPlantPage);
