import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import InternationalDateTimePickerInput from '../../common/form/InternationalDateTimePickerInput';
import ReactSelectInput from '../../common/form/ReactSelectInput';

const DeliveryOrderFormHeaderCourier = (props) => {

  const {
    facilityTimezone,
    deliveryStatusOptions
  } = props;

  return (
    <div>
      <Row>
        <Col md={4}>
          <InternationalDateTimePickerInput
            timezone={facilityTimezone}
            name='delivery_expected_at'
            props={{
              label: I18n.t('deliveryOrders.form.dateExpected'),
              enableTodayButton: true,
              timeFormat: true,
              inputProps: {
                disabled: true
              },
            }}
          />
        </Col>
        <Col md={4}>
          <Field
            name='delivery_status'
            component={ReactSelectInput}
            props={{
              label: I18n.t('deliveryOrders.form.deliveryStatus'),
              options: deliveryStatusOptions,
              placeholder: I18n.t('common.form.select'),
              isRequired: true
            }}
          />
        </Col>

      </Row>
    </div>
  );
};

export default DeliveryOrderFormHeaderCourier;