/* eslint-disable import/no-named-as-default*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {formValueSelector} from 'redux-form';
import {goBack} from 'react-router-redux';
import {getUnpaginatedData, getItem, putItem} from '../../../actions/apiActions';

import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import FormWrapper from '../../common/form/FormWrapper';
import TaxForm from '../common/TaxForm'; // eslint-disable-line import/no-named-as-defaul
import {getIntegrationState} from '../../../selectors/integration/integrationSelectors';
import createPayload from '../common/createPayload';
import generateInitialValues from '../common/generateInitialValues';

export class ModifyTaxPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  componentWillMount() {
    this.props.actions.getUnpaginatedData('/api/facilities', dataNames.facilities);
    this.props.actions.getUnpaginatedData('/api/categories', dataNames.categories);
    this.props.actions.getItem(`/api/taxes/${this.props.params.id}`, itemNames.tax);
  }

  redirect() {
    this.props.actions.goBack();
  }

  onSubmit(taxes) {
    const payload = createPayload(taxes);
    return this.props.actions.putItem(
      `/api/taxes/${this.props.params.id}`,
      payload[0],
      itemNames.tax,
      {
        success: 'taxes.successEdit',
        fail: 'taxes.failEdit'
      },
      null,
      this.props.actions.goBack
    );
  }

  render() {
    const {categories, tax, taxRates, facility, initialValues, integrationState} = this.props;
    return (
      <FormWrapper className='modify-tax-page' title={'taxes.modifyTax.modifyTax'} goBack={this.redirect} >
        <TaxForm
          onSubmit={this.onSubmit}
          categories={categories}
          initialValues={initialValues}
          modifyPage={true}
          taxRates={taxRates}
          facility={facility}
          tax={tax}
          integrationState={integrationState}
        />
      </FormWrapper>
    );
  }
}

ModifyTaxPage.propTypes = {
  actions: PropTypes.object.isRequired,
  tax: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  params: PropTypes.object.isRequired,
  taxRates: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
};

const selector = formValueSelector('tax');

function mapStateToProps(state) {
  const {categories, facilities, tax, facility} = state;
  const taxRates = selector(state, 'taxRates') || [];
  const initialValues = generateInitialValues(tax, categories);
  return {
    tax,
    categories,
    facilities,
    taxRates,
    facility,
    integrationState:  getIntegrationState(state),
    initialValues,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({},
    {putItem, getItem, getUnpaginatedData},
    {goBack}
  );
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (ModifyTaxPage);
