import {createSelector} from 'reselect';
import groupBy from 'lodash.groupby';
import get from 'lodash.get';
import * as dataNames from '../constants/dataNames';
import * as itemNames from '../constants/itemNames';
import {getPartners} from './partnersSelectors';
import {isActive} from './itemMastersSelectors';
import {groupedByItemMasterId} from './inventoryItemsSelectors';
import {convertFromBase} from '../util/uomHelpers';

const getSelectedIngredients = state => state.selectedIngredients;

export const getSelectedIngredientsIds = createSelector(getSelectedIngredients, selectedIngredients => selectedIngredients.map(selectedIngredient => selectedIngredient.id));

export const getIngredient = state => state[itemNames.ingredient];
export const getIngredients = (state) => state[dataNames.ingredients];
export const getIngredientItems = state => state[dataNames.ingredientItems];
export const getIngredientPayload = (formData, uoms) => {
  const uomCode = formData.vendors[0] && formData.vendors[0].default_uom;
  const uom = uomCode && uoms && uoms.reduce((acc, uom) => {
    return uom.uom_code === uomCode ? uom : acc;
  }, {});

  const ret = {
    ...(['name', 'ingredient_category_id'].reduce((agr, fieldName) => {
      agr[fieldName] = formData[fieldName];
      return agr;
    }, {})),
    default_uom: uom && uom.uom_code,
    vendors: formData.vendors && formData.vendors.map(({partner_id, container_size, default_unit_cost}) => {
      return {
        partner_id,
        container_size,
        default_unit_cost,
      };
    })
  };
  return ret;
};


export const getIngredientForModify = createSelector(
  [getIngredient],
  (ingredient) => {
    return {
      ...ingredient,
      ingredient_category_id: ingredient && ingredient.inventory_attributes && ingredient.inventory_attributes.ingredient_category_id,
      vendors: ingredient.vendors && ingredient.vendors.map(vendor => {
        return {
          ...vendor,
          default_uom: ingredient.default_uom,
        };
      }),
    };
  }
);


export const getIngredientsWithCosting = createSelector(
  [getIngredients],
  (ingredients) => ingredients.map((ingredient) => {
    // Getting cost directly from ingredient's supplier as per MJP-20054
    // Will have to be reconsidered if more than one vendor is to be supported by ingredients
    return {
      ...ingredient,
      averageCost: get(ingredient, 'vendors[0].default_unit_cost', 0),
      averageCostUOM: ingredient.default_uom,
    };
  })
);

export const getIngredientOptions = createSelector(
  [getIngredientItems],
  (items) => {
    return groupBy(items, 'item_master_id');
  }
);

export const getIngredientsForListing = createSelector([getIngredients, getPartners, groupedByItemMasterId], (ingredients, partners, invItems) => {
  return ingredients.map(ingredient => {
    const {vendors, default_uom, inventory_attributes} = ingredient;

    return {
      ...ingredient,
      ...inventory_attributes,
      avg_vendors_price: vendors && vendors.length ? vendors.reduce((acc, vendor) => {
        acc += parseFloat(vendor.default_unit_cost || 0);
        return acc;
      }, 0) / vendors.length : 0,
      avg_container_size: vendors && vendors.length ? vendors.reduce((acc, vendor) => {
        acc += parseFloat(vendor.container_size || 0);
        return acc;
      }, 0) / vendors.length : 0,
      suppliers_title: partners
        .filter(partner => vendors && vendors && vendors.some(vendor => vendor.partner_id === partner.id))
        .map(partner => partner.name).join(', '),
      qty: invItems[ingredient.id] ? invItems[ingredient.id].reduce(
        (acc, item) => acc += convertFromBase(item.qty_base, default_uom), 0) : 0,
      id: ingredient.id
    };
  });
});

export const getSortedIngredients = createSelector([getIngredients], (items) =>
  items.sort((item1, item2) => item1.name.localeCompare(item2.name))
);

export const getIngredientsFofFeedingSchedules = createSelector(
  [getSortedIngredients],
  ingredients => ingredients.filter((ingredient) => {
    return isActive(ingredient);
  })
);
