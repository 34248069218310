import { createSelector, createStructuredSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import find from 'lodash.find';
import get from 'lodash.get';

import {formatInternationalInputDate} from '../../util/dateHelpers';
import {convert} from '../../util/uomHelpers';
import { GR } from '../../constants/uoms';
import { CREATE_HARVEST_PACKAGES } from '../../constants/forms';
import { getIntegrationState } from '../integration/integrationSelectors';
import { getTimezone } from '../timezoneSelectors';
import { getHarvests, getSelectedHarvest } from '../harvests/harvestsSelectors';
import { getFlattenedLocations } from '../locationsSelectors';
import { getTrackingIds } from '../trackingIdsSelectors';
import { getProductsForHarvestBatchFilteredByPhenotypes } from '../transfersSelectors';
import { getCurrentFacilityUsers } from '../usersSelectors';
import { getBiotrackHarvestTypeOptions } from '../integration/biotrackSelectors';
import { getWaHarvestTypeOptions, getWaMaterialTypeOptions } from '../integration/leafSelectors';
import { hasPackagesTags } from '../../selectors/integrationSelectors';
import * as itemNames from '../../constants/itemNames';
import { getCupos } from './cupoFormSelectors';
import {getInternationalDateFormat} from '../InternationalOperationsSelectors';
import {isFeatureEnabled} from '../featureToggles';

const formSelector = formValueSelector(CREATE_HARVEST_PACKAGES);


const getHarvestWasteTypes = state => state['harvestWasteTypes'];
const getFormPackages = state => formSelector(state, 'packages') || [];
const getTotalQuality = state => Number(formSelector(state, 'harvestBatch.totalQualityInt')) || 5;
const getCurrentBatchWeight = state => Number(formSelector(state, 'harvestBatch.current_weight')) || 0;
const getCurrentBatchOtherMaterialWeight = state => Number(formSelector(state, 'harvestBatch.current_other_material_weight')) || 0;
const getWaste = state => Number(formSelector(state, 'waste')) || 0;
const getOtherMaterialsWaste = state => Number(formSelector(state, 'waste_other_material')) || 0;
const getCultivationSettings = state => state[itemNames.cultivationSettings];

const filterByPackageType = type => items => items.filter(item => item.integration_type === type);
const calculateSum = (fieldName) => (items, selectedHarvest) => {
  const baseUom = selectedHarvest.uom_display;

  if (items.length === 1 && !items[0].item_master_id) return 0;

  return (items || []).reduce(
    (sum, item) => {
      const itemValue = item[fieldName];

      if (!itemValue) return sum;

      const itemUom = item.uom;

      return sum + convert(Number(itemValue), itemUom, baseUom);
    },
    0
  );
};

const getRequireLabResultsForInventory = createSelector(
  [getCultivationSettings],
  (cultivationSettings) => get(cultivationSettings, `cult_require_lab_results_for_inventory.value`, false)
);

const getFlowerPackages = createSelector(
  [getFormPackages, getIntegrationState],
  (packages, integrationState) => {
    if (integrationState.isWaLeaf) {
      return filterByPackageType('flower')(packages);
    }
    return packages;
  }
);

const getOtherMaterialPackages = createSelector(
  [getFormPackages, getIntegrationState],
  (packages, integrationState) => {
    if (integrationState.isWaLeaf) {
      return filterByPackageType('other_material')(packages);
    }
    return [];
  }
);

const getPackagedWeight = createSelector(
  [getFlowerPackages, getSelectedHarvest],
  calculateSum('weight_of_product')
);

const getOtherMaterialPackagedWeight = createSelector(
  [getOtherMaterialPackages, getSelectedHarvest],
  calculateSum('weight_of_product')
);

const getTotals = createSelector(
  [getCurrentBatchWeight, getCurrentBatchOtherMaterialWeight, getPackagedWeight, getOtherMaterialPackagedWeight, getWaste, getOtherMaterialsWaste],
  (currentWeight, currentOtherMaterialWeight, packagedWeight, packagedOtherMaterialWeight, waste, wasteOtherMaterial) => {
    return {
      // Note, no longer rounding here. Rounding in the api response instead (apiResponseDataTransforms.js)
      currentWeight,
      currentOtherMaterialWeight,
      packagedWeight,
      packagedOtherMaterialWeight,
      waste,
      wasteOtherMaterial,
      remainingWeight: Math.round(10000 * (currentWeight - packagedWeight - waste)) / 10000,
      remainingOtherMaterialWeight: (currentOtherMaterialWeight - packagedOtherMaterialWeight - wasteOtherMaterial),
    };
  }
);

export const getHarvestTypeOptions = createSelector(
  [getBiotrackHarvestTypeOptions, getWaHarvestTypeOptions, getIntegrationState],
  (biotrackHarvestTypeOptions, leafWaHarvestTypeOptions, integrationState) => {
    if (integrationState.isBiotrack) {
      return biotrackHarvestTypeOptions;
    } else if (integrationState.isWaLeaf) {
      return leafWaHarvestTypeOptions;
    }
  }
);

export const getMaterialTypeOptions = createSelector(
  [getWaMaterialTypeOptions, getIntegrationState],
  (leafWaMaterialTypeOptions, integrationState) => {
    if (integrationState.isWaLeaf) {
      return leafWaMaterialTypeOptions;
    }
  }
);

const getTestPackageStatus = createSelector(
  [getSelectedHarvest, getIntegrationState, getRequireLabResultsForInventory, isFeatureEnabled],
  (harvest, integrationState, requireLabResultsForInventory, isFeatureEnabled) => {
    // In strict mode value of is_test_package checkbox is predefined and read-only
    const strictMode = Boolean(integrationState.isPaLeaf || requireLabResultsForInventory);
    const utahCultivatorEnhancements = integrationState.isUtah && isFeatureEnabled('feature_utah_cultivator_enhancements');

    // Initial value for is_test_package checkbox
    const isTestPackage = () => {
      if (!strictMode) return 0;
      return !harvest.has_sample ||
        (harvest.has_sample && harvest.has_test_result && harvest.test_result_status !== 'passed')
        ? 1
        : 0;
    };

    // If Utah Cultivator Enhancements FT is enabled, lab results are not required
    const hasPendingLabResults = () => {
      return harvest.has_sample && !harvest.has_test_result && !utahCultivatorEnhancements;
    };

    // Checkbox can be hidden if we have passing lab result
    const isCheckboxHidden = () => {
      return requireLabResultsForInventory && harvest.test_result_status === 'passed';
    };

    return {
      isTestPackage: isTestPackage(),
      checkboxHidden: isCheckboxHidden(),
      checkboxDisabled: strictMode,
      pendingTestResults: strictMode && hasPendingLabResults(),
      formDisabled: strictMode && hasPendingLabResults(),
      hasSample: harvest.has_sample,
      hasTestResult: harvest.has_test_result,
      testPassed: harvest.test_result_status === 'passed'
    };
  }
);

const getCreateHarvestPackagesInitialValues = createSelector(
  [getSelectedHarvest, getTestPackageStatus, getHarvestTypeOptions, getIntegrationState, getTotals, getInternationalDateFormat],
  (harvestBatch, testPackageStatus, harvestTypeOptions, { isBiotrack }, totals, dateFormat) => {
    return {
      // totals
      currentWeight: totals.currentWeight,

      harvestBatch,
      waste_uom: harvestBatch.uom_display,
      strain_id: get(harvestBatch, 'strain_id'),
      cupo_id: get(harvestBatch, 'cupo_id'),
      waste: '0.00',
      waste_other_material: '0.00',
      waste_other_material_uom: GR,
      packages: [
        {
          uom: harvestBatch.uom_display,
          rating_avg_total_quality: harvestBatch.total_quality,
          packaged_at: formatInternationalInputDate(new Date(), dateFormat),
          integration_type: isBiotrack && find(harvestTypeOptions, { code: 'flower_lot' }).value,
          package_created_at: formatInternationalInputDate(new Date(), dateFormat),
          is_test_package: testPackageStatus.isTestPackage,
          tag_requested: 1
        }
      ]
    };
  }
);

const designatedInventoryAsTestPackageEnabled = createSelector(
  [getCultivationSettings],
  (cultivationSettings) => {
    return get(cultivationSettings, 'cult_allow_harvest_batch_test_samples.value', false);
  }
);

export const getCreateHarvestPackagesPageProps = createStructuredSelector({
  harvests: getHarvests,
  harvestBatch: getSelectedHarvest,
  locations: getFlattenedLocations,
  itemMasters: getProductsForHarvestBatchFilteredByPhenotypes,
  trackingIds: getTrackingIds,
  harvestTypeOptions: getHarvestTypeOptions,
  integrationState: getIntegrationState,
  timezone: getTimezone,
  initialValues: getCreateHarvestPackagesInitialValues,
  packages: getFormPackages,
  totalQuality: getTotalQuality,
  totals: getTotals,
  users: getCurrentFacilityUsers,
  testPackageStatus: getTestPackageStatus,
  harvestWasteTypes: getHarvestWasteTypes,
  materialTypeOptions: getMaterialTypeOptions,
  hasPackagesTags: hasPackagesTags,
  designatedInventoryAsTestPackageEnabled: designatedInventoryAsTestPackageEnabled,
  cupos: getCupos
});

export const dummy = () => {};
