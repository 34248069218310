import { createSelector } from 'reselect';
import orderBy from 'lodash.orderby';
import startCase from 'lodash.startcase';
import * as itemNames from '../constants/itemNames';
import { getOrderedOrganizationStrains, getAvailableOrganizationStrains } from './organizationStrainsSelectors';
import { getOrderedFacilityStrains } from './facilityStrainsSelectors';
import { getItemMaster } from './itemMastersSelectors';

export const getStrain = (state) => state[itemNames.strain];

const getSearchString = (_, props) => props.searchString;
const getSearchLetter = (_, props) => props.searchLetter;
const getItemsPerPage = (_, props) => props.itemsPerPage;
const getActivePage = (_, props) => props.activePage;
const getStrainsLevel = (_, props) => props.level;
const getStrainId = (_, props) => props.strain_id;
const getPhenotypeId = (_, props) => props.phenotype_id;
const getPhenoTypes = (state, _) => state.phenotypes;

export const getPhenotypeById = createSelector(
  [getPhenoTypes, getPhenotypeId],
  (phenotypes, phenotype_id) => {
    return phenotypes.find((phenotype) => phenotype.id == phenotype_id) || {};
  }
);

const getSourceStrains = createSelector(
  [getOrderedOrganizationStrains, getAvailableOrganizationStrains, getStrainsLevel],
  (orgStrains, availableStrains, level) => (level === 'facility' ? orgStrains : availableStrains)
);

export const getStrainsFirstLetters = createSelector(
  getSourceStrains,
  (strains) => {
    const alphabet = '1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').map((letter) => ({ letter, count: 0 }));
    strains.forEach((strain) => {
      if (!strain.strain_name) {
        return;
      }
      const currentFirstLetter = alphabet.find(
        (letter) => letter.letter.toUpperCase() === strain.strain_name[0].toUpperCase()
      );
      if (!currentFirstLetter) {
        alphabet.push({ letter: strain.strain_name[0].toUpperCase(), count: 1 });
      } else {
        currentFirstLetter.count++;
      }
    });
    return orderBy(alphabet, 'letter');
  }
);

/**
 * Uses when user is typing something in search field in pages:
 * /strains/organization
 * /strains/facility
 * @type {Reselect.Selector<any, any>}
 */
export const getFilteredStrains = createSelector(
  [getSourceStrains, getSearchString, getSearchLetter],
  (strains, searchString, searchLetter) => {
    const searchRegExp = new RegExp(searchString.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1'), 'i');
    const filterHandler = (strain) => {
      return (
        (!searchLetter || strain.strain_name[0].toUpperCase() === searchLetter) &&
        (!searchString || strain.strain_name.match(searchRegExp))
      );
    };

    return strains.filter(filterHandler);
  }
);

export const getStrainsPageData = createSelector(
  [getFilteredStrains, getItemsPerPage, getActivePage],
  (strains, itemsPerPage, activePage) => {
    const total = strains.length;
    const pagesCount = Math.ceil(total / itemsPerPage);
    const start = itemsPerPage * (activePage - 1) < total ? itemsPerPage * (activePage - 1) : total - 1;
    const end = itemsPerPage * activePage < total ? itemsPerPage * activePage : total;
    const items = strains.slice(start, end);

    return {
      items,
      start: start + 1,
      end,
      total,
      pagesCount
    };
  }
);

export const getStrainById = createSelector(
  [getOrderedFacilityStrains, getStrainId],
  (strains, strain_id) => (strain_id && strains ? strains.find((strain) => strain.id == strain_id) : {})
);

export const getPhenotypesByStrain = createSelector(
  [getStrainById],
  (strain) => (strain && strain.phenotypes ? strain.phenotypes : [])
);

export const getStrainWithProductDominance = createSelector(
  [getStrain, getItemMaster],
  (strain, itemMaster) => {
    const productStrain = Object.assign({}, strain);
    if (itemMaster.dominance) productStrain.dominance = startCase(itemMaster.dominance);
    return productStrain;
  }
);

export const getTypeOfCannabisByStrain = createSelector(
  [getStrainById],
  (strain) => (strain ? strain.type_of_cannabis : null)
);
