import React from 'react';
import PropTypes from 'prop-types';
import {get} from 'lodash';
import {FaTimesCircle} from 'react-icons/fa';
import {getComputedProductQuantity} from '../../../../selectors/ordersSelectors';

const RefundItem = (props) => {

  const {data} = props;
  const quantity = getComputedProductQuantity(data);
  const originalUnitPrice = parseFloat(data.original_unit_price);

  const getPerUnitPrice = () => {
    if(data.sold_weight){
      return (originalUnitPrice / quantity).toFixed(2);
    }
    return originalUnitPrice.toFixed(2);
  };

  const getRefundedLineItem = () => {
    if(data.sold_weight){
      return originalUnitPrice.toFixed(2);
    }
    if (quantity === get(data, 'refunded_order.quantity')) {
      return (-1 * get(data, 'refunded_order.taxable_total', 0)).toFixed(2);
    }
    return (originalUnitPrice * quantity).toFixed(2);
  };

  return (
    <tr>
      <td style={{cursor: 'pointer'}} onClick={() => { props.deleteRefundItem(data);}}>
        <FaTimesCircle className='text-danger'/> {data.name}
      </td>
      <td>{quantity}</td>
      <td>{getPerUnitPrice()}</td>
      <td style={{textAlign: 'right'}}>${getRefundedLineItem()}</td>
    </tr>
  );

};

RefundItem.propTypes = {
  deleteRefundItem: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

export default RefundItem;
