import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {getFileOptions, getGroupedFiles} from '../../../../selectors/objectFilesSelectors';
import {deleteItem} from '../../../../actions/apiActions';
import ObjectFilesComponent from './ObjectFilesComponent';
import {isWaLeafIntegrator} from '../../../../selectors/integration/leafSelectors';

function mapStateToProps(state, ownProps) {
  return {
    fileOptions: getFileOptions(state, ownProps),
    groupedFields: getGroupedFiles(state, ownProps),
    partner: ownProps.partner ? ownProps.partner : {},
    isWaLeaf: isWaLeafIntegrator(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({deleteItem}, dispatch),
  };
}

const ObjectFilesFieldArray = connect(mapStateToProps, mapDispatchToProps)(ObjectFilesComponent);

ObjectFilesFieldArray.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
  }).isRequired,
  change: PropTypes.func.isRequired,
  types: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  files: PropTypes.array.isRequired,
  isFieldRequired: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
  ]),
  isFieldReadOnly: PropTypes.func,
  isUploadDisabled: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
  ]),
  facilityState: PropTypes.string.isRequired
};

export default ObjectFilesFieldArray;
