import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {push} from 'react-router-redux';
import * as apiActions from '../../../../actions/apiActions';
import {handlePrintJob, printJobDispatch} from '../../../../actions/printerActions';
import * as itemActions from '../../../../actions/itemActions';
import * as itemNames from '../../../../constants/itemNames';
import * as dataNames from '../../../../constants/dataNames';
import {getPrintServers} from '../../../../selectors/forms/printServerFormSelectors';
import {getDefaultLabelType, getPrintLabels, getDefaultLabel} from '../../../../selectors/labelsSelectors';

const retailReceipt = 'RETAIL_RECEIPT';

export class AutoPrintLabel extends React.PureComponent {

  constructor (props, context) {
    super(props, context);
    this.print = this.print.bind(this);
    this.state = {
      selectedTag: false,
      defaultLabel: false,
      printX: 1,
      autoPrint: 0,
      lastCall: false,
      isReceipt: false,
    };
  }

  componentWillMount(){
    const printObjectDataName = this.props.labelTag === 'RETAIL_RECEIPT' ? itemNames.printReceipt : itemNames.printLabel;
    this.props.actions.unsetItem(printObjectDataName);
    this.setState({
      selectedTag: this.props.defaultLabel ? this.props.defaultLabel : false,
      defaultLabel: this.props.defaultLabel ? this.props.defaultLabel : false,
      isReceipt: this.props.labelTag === retailReceipt,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.isAutoPrint(nextProps);
  }

  isAutoPrint(props){
    if(props.autoPrint && props.autoPrint !== this.state.autoPrint){
      if(this.canAutoPrint(props)) {
        this.setState({autoPrint: props.autoPrint}, () => {
          if(!Object.keys(props.printLabel).length || this.props.labelForAutoPrint){
            this.getLabel(true, props).then(() => {
              this.print();
            });
          } else {
            this.print();
          }
        });
        return true;
      } else {
        this.setState({autoPrint: props.autoPrint});
      }
    }
    return false;
  }

  canAutoPrint(props = false){
    if(!props) props = this.props;
    if(props.showModalRequired) return false; // Already showing so don't bother
    const selectedPrinter = props.selectedPrinter[props.labelTag];
    if(!this.state.isReceipt) {
      if(!selectedPrinter || (!props.defaultLabel && !this.props.labelForAutoPrint)  || (selectedPrinter && selectedPrinter.server && !selectedPrinter.server.remote && !props.selectedPrinter.qzConnected)){
        this.props.onShowModalRequired();
        return false;
      }
    } else {
      if (!selectedPrinter || (selectedPrinter && selectedPrinter.server && !selectedPrinter.server.remote && !props.selectedPrinter.qzConnected)){
        this.props.onShowModalRequired();
        return false;
      }
    }
    return true;
  }

  getLabel(useSelected = false, props = false){
    if(!props) props = this.props;
    return new Promise((resolve) => {
      if(this.getPayloadId(props) === -1) { // Indicates we aren't loaded yet
        return false;
      }

      const url = this.getUrl(useSelected, props);
      const params = Object.assign({}, {
        resolution: props.selectedPrinter[this.props.labelTag]
          ? props.selectedPrinter[this.props.labelTag].resolution
            ? props.selectedPrinter[this.props.labelTag].resolution
            : 203
          : 203,
        version: 2,
        label_type: props.defaultLabelType ? props.defaultLabelType : 'image',
      }, this.props.params ? this.props.params : {});

      // Prevent duplicates can happen on resolution change
      const callData = Object.assign({}, {params, url, payload: props.payload, method: this.isGetCall() ? 'GET' : 'POST'});
      if(JSON.stringify(callData) === JSON.stringify(this.state.lastCall)) {
        return false;
      }

      const callComplete = () => {
        this.setState({progress: false});
        resolve();
      };

      const dataName = this.state.isReceipt ? itemNames.printReceipt : itemNames.printLabel;

      this.setState({progress: true, lastCall: callData}, () => {
        if(this.isGetCall()){
          this.props.actions.getItem(url, dataName, null, params).then(callComplete);
        } else {
          this.props.actions.postItem(url, props.payload, dataName, null, params).then(callComplete);
        }
      });
    });
  }

  isGetCall(props){
    if(!props) props = this.props;
    if(this.state.isReceipt) return true;
    return this.getPayloadId(props);
  }

  getPayloadId(props){
    if(!props) props = this.props;
    const p = props.payload;
    if(typeof p !== 'object') return p;
    if(Object.keys(props.payload).length > 1) return false; // New
    if(p.ids && Array.isArray(p.ids) && p.ids.length > 1) return false;
    if(p.ids && Array.isArray(p.ids) && p.ids.length === 1) return p.ids[0];
    if(p.ids && !Array.isArray(p.ids)) return p.ids;
    if(p.id) return p.id;
    return -1;
  }

  getUrl(useSelected = false, props){
    if(!props) props = this.props;
    if(this.state.isReceipt) {
      return `/api/orders/receipt/${props.order.id}/pdf`;
    }
    const tag = this.props.labelForAutoPrint
      ? this.props.labelForAutoPrint
      : (useSelected && this.state.selectedTag ? this.state.selectedTag : props.labelTag);
    if(!this.getPayloadId(props)) return `/api/labels/${tag}/generates`;
    return `/api/labels/${tag}/generates/${this.getPayloadId(props)}`;
  }

  print(event = false){
    if(event && event.target && event.target.blur) event.target.blur();
    this.props.actions.printJobDispatch({
      call: this.state.lastCall,
      tag: this.props.labelTag,
      quantity: this.state.printX,
      printSample: false,
      isReceipt: this.state.isReceipt,
      onComplete: this.props.onAutoPrintComplete,
    });
  }

  render(){
    return null;
  }

}

AutoPrintLabel.propTypes = {
  qzTray: PropTypes.object,
  labelTag: PropTypes.string,
  payload: PropTypes.object,
};


function mapStateToProps(state, ownProps) {
  const {label} = state;
  return {
    facility: state.facility,
    goToAfter: ownProps.goToAfter,
    qzTray: state.qzTray,
    label,
    defaultLabelType: getDefaultLabelType(state),
    selectedPrinter: state[itemNames.selectedPrinter],
    printLabel: state[ownProps.labelTag === retailReceipt ? itemNames.printReceipt : itemNames.printLabel],
    printLabels: getPrintLabels(state),
    defaultLabel: getDefaultLabel(state[itemNames.facility].id, ownProps.labelTag),
    printJob: state[itemNames.printJob],
    printServers: getPrintServers(state),
    localPrinters: state[dataNames.localPrinters],
    order: state[itemNames.order],
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, itemActions, {push}, {handlePrintJob, printJobDispatch});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoPrintLabel);


