import React from 'react';


const SVGClock = ({fillColor}) =>
  <svg width='24' height='24' viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fill-rule='evenodd' clip-rule='evenodd' d='M48 90C24.804 90 6 71.196 6 48C6 24.804 24.804 6 48 6C71.196 6 90
    24.804 90 48C90 71.196 71.196 90 48 90ZM61.77 66L45 49.23V21H51V46.74L66 61.77L61.77 66Z' fill={fillColor} />
  </svg>;


export default SVGClock;
