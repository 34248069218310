import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';
import {connect} from 'react-redux';

import ScanInputForm from './ScanInputForm';

const ScanInputReduxForm = reduxForm({
  form: 'scanInput'
})(ScanInputForm);

function mapStateToProps(state) {
  return { state };
}

const ScanInputFormWrapper = connect(mapStateToProps)(ScanInputReduxForm);

ScanInputFormWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default ScanInputFormWrapper;
