import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';

export const validatePrepackModalForm = values => {
  const {adjustment_qty, adjustment_reason} = values;
  if (!adjustment_qty) {
    return {};
  }

  const errors = {};
  errors.adjustment_reason = [];
  adjustment_qty.forEach((aq, index) => {
    if (aq != 0 && (!adjustment_reason || get(adjustment_reason, `[${index}]`, '') === null || get(adjustment_reason, `[${index}]`, '') === '')) {
      errors.adjustment_reason[index] = I18n.t('inventory.sync.error.validation.adjustment_reason');
    }
  });
  return errors;
};

export default {
  validatePrepackModalForm,
};
