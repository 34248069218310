import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field, FieldArray} from 'redux-form';
import {Row, Col, Button, Card} from 'react-bootstrap';
import {FaPlus, FaTimes} from 'react-icons/fa';
import TextInput from '../../common/form/TextInput';
import InternationalDecimalInput from '../../common/form/InternationalDecimalInput';
import AccordionPanel from '../../common/AccordionPanel';
import LevelTwoLocations from '../../common/locations/create/LevelTwoLocations';
import LocationSharing from '../../common/locations/create/LocationSharing';
import InlineCheckBox from '../../common/form/InlineCheckBox';

const LevelOneLocations = ({createLocation, fields, push, locations, defaults, integratedLocationNames,
                             showIntegratedLocationName, isLeaf, integrationMapping,
                             printLabel, onIsSharedChange, facilitySharingStatus, onDelete,
                             expanded, renderBodyWhenCollapsed}) => {
  return (
    <Row>
      <Col xs={12}>
        {fields.map((locationName, index) => {
          const location = locations[index];
          let accordeonControl = '';
          let disableLocationEdit = false;
          if(facilitySharingStatus) {
            if(!!location.is_shared && !facilitySharingStatus.is_sharing_group_master) disableLocationEdit = true;
            const disableIsSharedCheckbox = !facilitySharingStatus.is_sharing_group_master;
            const showIsSharedCheckbox = facilitySharingStatus.is_in_sharing_group && (!disableIsSharedCheckbox || location.is_shared);
            if (showIsSharedCheckbox) {
              accordeonControl = (<LocationSharing
                locationName={locationName}
                isShared={!!location.is_shared}
                onIsSharedChange={onIsSharedChange}
                isDisabled={disableIsSharedCheckbox}
              />);
            }
          }

          const title = () => {
            return !!location && location.name ? location.name : '';
          };

          return (
            <AccordionPanel expanded={expanded}
                            renderBodyWhenCollapsed={renderBodyWhenCollapsed}
                            title={title}
                            key={locationName}
                            className='location-list'
                            rightControl={accordeonControl}
            >
              <Row className='form-fields-row'>
                <Col xs={1}>
                  {!disableLocationEdit && <Button
                    size='sm'
                    variant='danger'
                    className='btn-inline float-right'
                    disabled={fields.length < 2}
                    onClick={() => {
                      onDelete(fields.get(index), () => {
                        fields.remove(index);
                      });
                    }} ><FaTimes/>
                  </Button>}
                </Col>
                <Col className='form-fields-col' xs={8} md={4} lg={3}>
                  <Field
                    name={`${locationName}.name`} component={TextInput}
                    props={{
                      label: I18n.t('locations.form.locationName'),
                      isRequired: true,
                      readOnly: disableLocationEdit
                    }} />
                </Col>
                <Col xs={2}>
                  <Field
                    name={`${locationName}.is_default_for_retail`}
                    component={InlineCheckBox}
                    props={{
                      label: I18n.t('locations.form.isDefaultForRetail')
                    }}/>
                </Col>
                <Col xs={3}>
                  {!disableLocationEdit && <Button size='sm'
                          variant='primary'
                          className='btn-inline float-left'
                          onClick={() => push(`${locationName}.child_locations`, createLocation('sale', 1, location ? location.child_locations.length : 1, defaults))}>
                    <FaPlus/>
                  </Button>}
                  <label className='btn-inline'>{I18n.t('locations.form.addAreas')}</label>
                </Col>
              </Row>
              <Row>
                <Col className='form-fields-col' xs={8} md={4} lg={3} offset={1}>
                  <InternationalDecimalInput
                    name={`${locationName}.total_square_footage`} fractionPartSize={2}
                    props={{
                      label: I18n.t('locations.form.retailSqFootage'),
                      isRequired: true,
                      placeholder: I18n.t('locations.form.sqFtPlaceholder'),
                      readOnly: disableLocationEdit
                    }} />
                </Col>
              </Row>
              <FieldArray
                name={`${locationName}.child_locations`}
                component={LevelTwoLocations}
                props={{
                  push,
                  defaults,
                  type: 'sale',
                  level: 1,
                  createLocation,
                  showSquareFootage: false,
                  locations: location ? location.child_locations : [],
                  printLabel,
                  showIntegratedLocationName,
                  integrationMapping,
                  isLeaf,
                  integratedLocationNames,
                  disableLocationEdit,
                  onDelete,
                }}/>
            </AccordionPanel>
          );
        })}
        <Card>
          <Card.Header>
            <h3 className='panel-title'>
              {I18n.t('locations.form.salesLocation', {id: fields.length + 1})}
              <Button
                variant='primary'
                size='sm'
                className='add-location-button'
                onClick={() => fields.push(createLocation('sale', 0, fields.length, defaults))}>
                <FaPlus/>
              </Button>
            </h3>
          </Card.Header>
        </Card>
      </Col>
    </Row>);
};

LevelOneLocations.propTypes = {
  fields: PropTypes.object.isRequired,
  createLocation: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  locations: PropTypes.array.isRequired,
  defaults: PropTypes.array.isRequired,
  integratedLocationNames: PropTypes.array.isRequired,
  showIntegratedLocationName: PropTypes.bool.isRequired,
  integrationMapping: PropTypes.array,
  isLeaf: PropTypes.bool,
  printLabel: PropTypes.func.isRequired,
  onIsSharedChange: PropTypes.func,
  facilitySharingStatus: PropTypes.object,
  onDelete: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  renderBodyWhenCollapsed: PropTypes.bool.isRequired
};

LevelOneLocations.defaultProps = {
  expanded: true,
  renderBodyWhenCollapsed: true
};

export default LevelOneLocations;
