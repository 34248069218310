import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { Col } from 'react-bootstrap';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import { isColombiaFacility } from '../../../selectors/facility/getFacilityCountry';
import WillRender from '../../common/concealers/WillRender';
import { getModalityOptions } from '../../../selectors/integration/colombiaSelectors';

const ModalityField = (props) => {
  const { isColombia, name, options, disabled, LayoutComponent, cupo_id, required } = props;

  return (
    <WillRender ifTrue={isColombia}>
      <LayoutComponent>
        <Field
          name={name}
          component={ReactSelectInput}
          props={{
            label: I18n.t('plants.form.modality'),
            options,
            isRequired: required,
            disabled: disabled || !cupo_id
          }}
        />
      </LayoutComponent>
    </WillRender>
  );
};

ModalityField.defaultProps = {
  name: 'modality',
  disabled: false,
  LayoutComponent: Col,
  required: false
};

ModalityField.propTypes = {
  name: PropTypes.string,
  formName: PropTypes.string.isRequired,
  cupo_id: PropTypes.number,
  strain_id: PropTypes.number,
  disabled: PropTypes.bool,
  isColombia: PropTypes.bool,
  LayoutComponent: PropTypes.func,
  options: PropTypes.array,
  required: PropTypes.bool
};

export default connect((state, ownProps) => {
  const selector = formValueSelector(ownProps.formName);
  const strain_id = selector(state, 'strain_id') || (ownProps.strain_id || 0);
  const cupo_id = selector(state, 'cupo_id') || (ownProps.cupo_id || 0);
  const options = ownProps.options || getModalityOptions(cupo_id, strain_id, state);

  return {
    cupo_id,
    options,
    isColombia: isColombiaFacility(state)
  };
})(ModalityField);
