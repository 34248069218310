import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';

import {requiredFieldValidation} from '../common/form/redux-form/validations';
import { SETUP_SALES_LOCATIONS_FORM } from '../../constants/forms'

export const defaultStorageLevels = [
  'buildingName',
  'roomName',
  'zoneName',
  'sectionName'
];

// generate lists of duplicate location names by tree depth level
export const getDuplicateNamesByLevel = (locations, levelIdx = 0, dupes = []) => {
  dupes[levelIdx] = [];
  const existingNames = {};
  locations.map( location => {
    const name = get(location,'name','');
    const key = name.toLowerCase().replace(/\s/g,'');
    if (existingNames.hasOwnProperty(key)) {
      dupes[levelIdx].push(name);
    } else {
      existingNames[key] = true;
    }
  });

  let childLocations = [];
  locations.forEach( location => {
    const children = get(location,'child_locations',null);
    childLocations = childLocations.concat(children);
  });

  if(childLocations.length){
    return getDuplicateNamesByLevel(childLocations,levelIdx + 1,dupes);
  }
  return dupes;
};

// create location name error object using getDuplicateNamesByLevel result
export const setLocationErrors = (location,error,duplicates,levelIdx = 0,storageLevels = null, props) => {
  const childLocs = get(location,'child_locations',null);
  const levelNames = storageLevels ? storageLevels : defaultStorageLevels;
  const { isWaLeaf, hasMetrcLocationTypes, form } = props;
  if (!childLocs) return;
  error.child_locations = [];
  childLocs.forEach( (cl,i) => {
    error.child_locations[i] = {};
    const levelName = I18n.t('locations.' + levelNames[levelIdx]);
    if (duplicates[levelIdx].indexOf(cl.name) !== -1) {
      const errMessage = I18n.t('common.form.isUnique', {fieldName: levelName});
      error.child_locations[i].name = errMessage;
    }
    // Require that location names have non-empty values
    if (!get(cl,'name',false)) {
      error.child_locations[i].name = I18n.t('common.form.isRequired', {fieldName: levelName});
    }
    // integrated location name exists only on level 3 for Cultivation and Storage locations
    if (isWaLeaf || hasMetrcLocationTypes && levelIdx === 3) {
      error.child_locations[i].integrated_location_name = requiredFieldValidation(cl.integrated_location_name, 'locations.form.integratedLocationName');
    }

    // integrated location name exists only on level 2 for Sales locations
    if (form === SETUP_SALES_LOCATIONS_FORM && hasMetrcLocationTypes && levelIdx === 2) {
      error.child_locations[i].integrated_location_name = requiredFieldValidation(cl.integrated_location_name, 'locations.form.integratedLocationName');
    }

    if (get(cl,'child_locations',null)) {
      setLocationErrors(cl,error.child_locations[i],duplicates,levelIdx + 1,levelNames, props);
    }
  });
};

export const validate = (values, props) => {
  const errors = {};
  if(values.child_locations)  {
    const duplicates = getDuplicateNamesByLevel(values.child_locations);
    setLocationErrors(values,errors,duplicates, 0 ,null, props);
  }
  return errors;
};

export default validate;
