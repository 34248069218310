/* eslint-disable import/no-named-as-default */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {I18n} from 'react-redux-i18n';
import {push} from 'react-router-redux';
import {FormControl, Form, Row, Col } from 'react-bootstrap';
import get from 'lodash.get';
import {getImageUrl, getImgByGender, getAnonymousImage} from '../../util/images';
import * as apiActions from '../../actions/apiActions';
import {setItem, unsetItem} from '../../actions/itemActions';
import {unsetData} from '../../actions/dataActions';
import * as menuActions from '../../actions/menuActions';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import {setItems} from '../../actions/cartActions';
import {customersWithOrderOfType, getFulfillmentOptions} from '../../selectors/customersQueueSelectors';
import {getDefaultVisitReason} from '../../selectors/queueSelectors';
import {getDataUpdateAvailable} from '../../selectors/dataUpdateSelectors';
import {getAllowAnonymousOrders, isLockPreCheckinOnlineOrder} from '../../selectors/salesSettingsSelectors';
import {getPatientsComplianceLimits} from '../../selectors/complianceSettingsSelectors';
import CustomerList from './CustomerList';
import ProductsHeader from '../header/ProductsHeader';
import SegmentedQueueTabs from './queue/SegmentedQueueTabs';
import FilterOnShowByStateButtons from './queue/FilterOnShowByStateButtons';
import InProgressOverlay from '../common/InProgressOverlay';
import CancelOrder from '../orders/order/cancel/CancelOrder';
import AnonymousOrderBundle from '../orders/order/anonymous/AnonymousOrderBundle';

export class QueuePage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    const searchTerm = '';
    this.state = {
      searchTerm,
      visitReasonId: 0,
      activeSegmentId: 0,
      showByState: 'all',
      showInProgress: false,
      inProgressMessage: 'Confirm Cancel',
      showSpinner: true,
      showOk: false,
      onDismiss: false,
      cancelCustomer: false,
      inferredScreenSize: 'large', // Gets size by testing for presence of responsive div in componentWillReceiveProps
      cancelOrderEventListeners: {},
      cancelOrderCustomerId: 0,
      cancelOrderOrderId: 0,
      cancelOrderEvent: 'noop',

    }; // 0 is id of view all tab
    this.selectCustomer = this.selectCustomer.bind(this);
    this.selectOrders = this.selectOrders.bind(this);
    this.selectProducts = this.selectProducts.bind(this);

    this.fillOrder = this.fillOrder.bind(this);

    this.checkout = this.checkout.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.getOpenOrdersAndQueue = this.getOpenOrdersAndQueue.bind(this);
    this.cancelOrderAndReload = this.cancelOrderAndReload.bind(this);

    this.onSelectSegment = this.onSelectSegment.bind(this);
    this.onSelectState = this.onSelectState.bind(this);

    this.hideInProgress = this.hideInProgress.bind(this);
    this.showInProgress = this.showInProgress.bind(this);

  }

  componentWillMount() {
    this.props.actions.unsetItem(itemNames.customer);
    this.props.actions.unsetItem(itemNames.order);
    this.props.actions.unsetData(dataNames.customersQueue);
    const orderType = this.props.params.orderType ? this.props.params.orderType : 'in_store';
    this.props.actions.setItem({orderType}, itemNames.orderType);
    const usageType = this.props.params.usageType ? this.props.params.usageType : 'all';
    this.props.actions.setItem({usageType}, itemNames.usageType);
    this.showInProgress('Loading', true, false, false);
    this.getOpenOrdersAndQueue();
    this.props.actions.getData('/api/queue/visit_reasons', dataNames.visitReasons, {});
    this.props.actions.getItem('/api/customers/compliance_settings', itemNames.patientComplianceSettings);
  }

  componentWillReceiveProps(nextProps){
    //@TODO: Extract to own component.
    const el = document.getElementById('smallScreen');
    const inferredScreenSize = (!el || el.offsetHeight === 0 || el.offsetHeight === undefined) ? 'large' : 'small';
    if(this.state.inferredScreenSize !== inferredScreenSize) this.setState({inferredScreenSize});
    if (nextProps.dataUpdateAvailable != this.props.dataUpdateAvailable && nextProps.dataUpdateAvailable){
      setTimeout(() => this.getOpenOrdersAndQueue(), 100);
    }
  }

  componentDidUpdate(oldProps) {
    const orderType = this.props.params.orderType ? this.props.params.orderType : 'in_store';
    const oldOrderType = oldProps.params.orderType ? oldProps.params.orderType : 'in_store';
    if (oldOrderType !== orderType) {
      this.props.actions.setItem({orderType: orderType}, itemNames.orderType);
      this.getOpenOrdersAndQueue();
    }
    const usageType = this.props.params.usageType ? this.props.params.usageType : 'all';
    const oldUsageType = oldProps.params.usageType ? oldProps.params.usageType : 'all';
    if (oldUsageType !== usageType) {
      this.props.actions.setItem({usageType}, itemNames.usageType);
    }
  }

  getOpenOrdersAndQueue(){

    const orderType = this.props.params.orderType ? this.props.params.orderType : 'in_store';
    const fulfillment_method = this.props.fulfillmentOption.fulfillment_method;
    const in_order_sources = this.props.fulfillmentOption.order_sources;
    const params = {in_order_sources};

    // Account for curbside, we had to give it a new fulfillment method to be able to distinguish this from pickup during reporting
    // while still keeping it in the pickup orders queue...
    if (this.props.fulfillmentOption.value === 'pickup') {
      params.in_fulfillment_methods = ['pickup', 'curbside'];
    } else {
      params.fulfillment_method = fulfillment_method;
    }

    this.props.actions.getUnpaginatedData('/api/open_orders',
      dataNames.orders,
      {failed: I18n.t('orders.get.failed')},
      params
    );
    let queueEndpoint = 'in_store';

    switch (orderType) {
    case 'delivery':
      queueEndpoint = '/delivery';
      break;
    case 'pickup':
      queueEndpoint = '/pickup';
      break;
    case 'payment':
      queueEndpoint = '/payment';
      break;
    case 'packaging':
      queueEndpoint = '/packaging';
      break;
    }

    this.props.actions.getUnpaginatedData(`/api/queue/${queueEndpoint}`, dataNames.customersQueue, {failed: I18n.t('customers.get.failed')}, {}, (result) => {
      const consumer_ids = result.map(order => order.consumer_id);
      if(!consumer_ids.length) {
        return this.hideInProgress();
      }
      const image_ids = result.map(order => order.image_file_id).filter(Boolean).filter(v => v != null);
      if ( image_ids.length ) {
        this.props.actions.getDataByPost('/api/images/multiple', {ids: image_ids}, dataNames.images);
      }
      this.props.actions.getDataByPost('/api/consumers/multiple', {ids: consumer_ids, detailed: false}, dataNames.consumers);
      this.hideInProgress();
    }).then(this.hideInProgress).catch(this.hideInProgress);
  }

  selectCustomer (customer, callback = false) {
    const id = customer.consumer_id;
    //this.props.actions.setItem(customer, itemNames.customer);
    this.props.actions.getItem(`/api/consumer_orders/${id}/stats`, itemNames.customerStat);
    this.props.actions.getItem(`/api/consumer_orders/${id}/recent_history`, itemNames.customerRecentHistory);
    if(callback) callback(); // Callback added for fillOrder to defeat race condition leaving customer useless

    this.selectOrders(id);
    this.selectFavorites(id);
  }

  selectOrders (id) {
    this.props.actions.getUnpaginatedData(`/api/orders/consumers/${id}`, dataNames.customerOrders);
  }

  selectFavorites (id) {
    this.props.actions.getUnpaginatedData(`/api/orders/favorites/${id}`, dataNames.customerFavorites);
  }

  selectProducts (id) {
    this.selectCustomer(id);
    this.props.actions.toggleCustDetails();
    this.props.actions.push('/products');
  }

  fillOrder(customer) {
    this.props.actions.setItem(customer.order, itemNames.order);
    this.selectCustomer(customer, () => {
      this.props.actions.push('/cart');
    });
  }

  editOrder(customer) {
    this.selectCustomer(customer, () =>
      this.props.actions.push(`/product-menu/${customer.order_id}`)
    );
  }
  checkout(customer) {
    this.selectCustomer(customer);
    this.props.actions.push('/payment');
  }

  handleSearch(event) {
    const {value} = event.target;
    this.setState({searchTerm: value});
  }

  cancelOrder(queueCustomer){
    this.props.actions.postItem(`/api/orders/${queueCustomer.order_id}/cancel_order`,
      {},
      itemNames.order,
      {success:'orders.delete.success', failed:'orders.removed.failed'},
      {},
      () => {
        this.cancelQueue(queueCustomer);
      }
    );
  }

  cancelQueue(queueCustomer){
    if(!this.queueCustomerInQueue(queueCustomer)) return this.getOpenOrdersAndQueue();
    this.props.actions.deleteItem(`/api/queue/customers/${queueCustomer.consumer_id}`,
      null,
      {success:'orders.delete.customerSuccess', failed:'orders.removed.customerFailed'},
      {},
      () => {
        this.getOpenOrdersAndQueue();
        this.props.actions.getItem('/api/queue/consumers_count', itemNames.orderTypeCounts, {failed: 'header.orderTypeCounts.failed'});
      }
    );
  }

  queueCustomerHasOrder(queueCustomer){
    return (!isNaN(parseInt(queueCustomer.order_id)));
  }

  queueCustomerInQueue(queueCustomer){ // Pickup and Delivery orders are not actually in the formal queue
    return (queueCustomer.id !== queueCustomer.order_id);
  }

  cancelOrderAndReload(queueCustomer, skipPrompt = false){
    if(!skipPrompt && this.queueCustomerHasOrder(queueCustomer)) {
      this.setState({cancelCustomer: queueCustomer});

      if(this.props.facility && this.props.facility.country_code === 'CA') {

        const showInProgress = this.showInProgress;
        const hideInProgress = () => {
          this.setState({showInProgress: false, cancelOrderEvent: 'noop'});
          this.getOpenOrdersAndQueue();
        };

        this.setState({
          cancelOrderEvent: 'cancel',
          cancelOrderCustomerId: queueCustomer.consumer_id,
          cancelOrderOrderId: queueCustomer.order_id,
          cancelOrderEventListeners: {
            cancel: {
              onBeforeCancel(option, value){
                showInProgress('Canceling Order...', true, false, false);
              },
              onError(e){
                hideInProgress();
              },
              onAfterCancel(option, value, data){
                hideInProgress();
              }
            }
          }
        });
      } else {
        this.showInProgress('Confirm Cancel', false, true, this.hideInProgress);
      }

      return false;
    }
    this.showInProgress('Canceling Order...', true, false, false);
    if(this.queueCustomerHasOrder(queueCustomer)) return this.cancelOrder(queueCustomer);
    this.cancelQueue(queueCustomer);
  }

  // fastTrack(customer) {
  //   if(customer.order_id){
  //     this.selectCustomer(customer, true);
  //   }else{
  //     this.startOrder(customer, true);
  //   }
  // }

  // Triggered by tab selection but could be other things
  onSelectSegment(activeSegmentId){
    this.setState({activeSegmentId});
  }

  onSelectState(event, selectedState){
    this.setState({showByState: selectedState});
    event.target.blur();
  }

  getFilteredCustomers(){
    // Curbside is essentially the same as pickup, only difference is that we need to pass the fulfillment_method as curbside
    // to the backend to be able to distinguish it in the sales report
    const currentQueue = this.props.params.orderType === 'curbside' ? 'pickup' : this.props.params.orderType;

    const segments = this.props.visitReasons.map(vr => vr);
    const segmentIds = segments.map(segment => segment.id);
    const {searchTerm, showByState} = this.state;
//if queue order doesn't have visit_reason_id it should be appear on first tab
    const firstSegmentColumnId = get(segments, '0.id', 1);

    const searchFilter = (customers) => {
      return !searchTerm
        ? customers
        : customers.filter((customer) => {
          return customer.consumer_name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
        });
    };

    const segmentFilters = { // Filters list by tab
      in_store: (customers) => {
        return !this.state.activeSegmentId
          ? customers
          : customers.filter((customer) => {
            return this.state.activeSegmentId === ((segmentIds.indexOf(customer.visit_reason_id) === -1) ? firstSegmentColumnId : customer.visit_reason_id);
          });
      },
      pickup: (customers) => { return customers; },
      delivery: (customers) => { return customers; },
      payment: (customers) => { return customers; },
      packaging: (customers) => { return customers; }
    };

    const queueStatusFilters = {
      in_store: (customers) => {
        if(showByState === 'all') return customers;
        return showByState === 'new'
          ? customers.filter((customer) => !customer.order_started)
          : showByState === 'active'
            ? customers.filter((customer) => customer.order_started)
            : customers.filter((customer) => customer.prefersCurrentUser); // mine
      },
      pickup: (customers) => {
        switch (showByState) {
        case 'all':
          return customers;
        case 'curbside':
          return customers.filter(customer => customer.fulfillment_method === 'curbside');
        case 'present':
          return customers.filter((customer) => customer.checked_in !== null);
        case 'assigned':
          return customers.filter((customer) => customer.order.fulfillment_status === 'assigned');
        default:
          return customers.filter((customer) => customer.prefersCurrentUser);
        }
      },
      delivery: (customers) => { return customers; },
      payment: (customers) => { return customers; },
      packaging: (customers) => { return customers; },
    };

    let customers = searchFilter(this.props.customers);
    customers = segmentFilters[currentQueue](customers);
    customers = queueStatusFilters[currentQueue](customers);

    return customers;

  }

  showInProgress(msg, spinner = true, button = false, onDismiss = false){
    this.setState({showInProgress: true, inProgressMessage: msg, showSpinner: spinner, showOk: button, onDismiss: onDismiss});
  }

  hideInProgress(value){
    this.setState({showInProgress: false});
    if(value === 'buttonClick') this.cancelOrderAndReload(this.state.cancelCustomer, true);
  }

  render () {
    const orderType = this.props.params.orderType ? this.props.params.orderType : 'in_store';
    const usageType = this.props.params.usageType ? this.props.params.usageType : '';

    // For now, we are going to change the text of the pickup queue to "Online" and hide the delivery queue.
    let titleText = '';
    switch (orderType) {
    case 'in_store':
      titleText = I18n.t('customer.title.inStore');
      break;
    case 'pickup':
      titleText = I18n.t('customer.title.pickup');
      break;
    case 'payment':
      titleText = I18n.t('customer.title.payment');
      break;
    case 'delivery':
      titleText = I18n.t('customer.title.delivery');
      break;
    case 'packaging':
      titleText = I18n.t('customer.title.packaging');
      break;
    }

    const {customers, timezone} = this.props;
    const currentQueue = orderType;
    const pageWidth = (this.props.custDetailsOpen || this.props.cartDetailsOpen) ? 8 : 12;
    const segments = this.props.visitReasons.map(vr => vr);
    segments.unshift({id: 0, name: 'All'});

    const filteredCustomers = this.getFilteredCustomers();
    return (
      <div className='customer-page'>
        <div style={{height: '0'}} className='hidden-xs hidden-sm' id='largeScreen'>&nbsp;</div>
        <div style={{height: '0'}} className='hidden-md hidden-lg' id='smallScreen'>&nbsp;</div>
        <InProgressOverlay
          onDismiss={this.state.onDismiss}
          message={this.state.inProgressMessage}
          isActive={this.state.showInProgress}
          showLoader={this.state.showSpinner}
          showOk={this.state.showOk}
          translate={false}
        />
        <CancelOrder
          event={this.state.cancelOrderEvent}
          customerId={this.state.cancelOrderCustomerId}
          orderId={this.state.cancelOrderOrderId}
          eventListeners={this.state.cancelOrderEventListeners}
        />
        <ProductsHeader
          props={this.props}
          context={this.context}
          showCart={false}
          showProfile={false}
        />
        <h1>
          {titleText}
          <Form inline style={{display:'inline'}}>
            <FormControl type='text' placeholder='search' onChange={this.handleSearch} className='float-right'/>
          </Form>
        </h1>
        {this.props.allowAnonymousOrders ? <AnonymousOrderBundle /> : null}
        <Row>
          <Col xs={pageWidth}>
            <SegmentedQueueTabs
              activeSegmentId={this.state.activeSegmentId}
              segments={segments}
              onSelectSegment={this.onSelectSegment}
              currentQueue={currentQueue}
              customers={customers} // we intentionally pass the unfiltered version to tabs
            />
          </Col>
          <Col xs={pageWidth}>
            <FilterOnShowByStateButtons
              currentQueue={currentQueue}
              showByState={this.state.showByState}
              onSelectState={this.onSelectState}
            >
              <hr style={{marginTop:'8px'}} />
            </FilterOnShowByStateButtons>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <CustomerList
              timezone={timezone}
              customers={filteredCustomers}
              selectCustomer={this.selectCustomer}
              selectOrders={this.selectOrders}
              selectProducts={this.selectProducts}

              fillOrder={this.fillOrder}
              checkout={this.checkout}
              cancelOrderAndReload={this.cancelOrderAndReload}

              inferredScreenSize={this.state.inferredScreenSize}
              orderType={orderType}
              usageType={usageType}
              pageWidth={pageWidth}
              segments={segments}
              defaultVisitReason={this.props.defaultVisitReason}
              activeSegmentId={this.state.activeSegmentId}
              currentQueue={this.props.params.orderType}
              currentStateFilter={this.state.showByState}
              patientComplianceSettings={this.props.patientComplianceSettings}
              isLockPreCheckinOnlineOrder={this.props.isLockPreCheckinOnlineOrder}
            />
          </Col>
        </Row>
        <hr/>
      </div>
    );
  }
}

QueuePage.propTypes = {
  customers: PropTypes.array.isRequired,
  customerFilters: PropTypes.array.isRequired,
  customerOrders: PropTypes.array.isRequired,
  custDetailsOpen: PropTypes.bool.isRequired,
  cartDetailsOpen: PropTypes.bool.isRequired,
  customer: PropTypes.object.isRequired,
  customerStat: PropTypes.object.isRequired,
  customerRecentHistory: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  fulfillmentOption: PropTypes.object.isRequired,
  defaultVisitReason: PropTypes.object.isRequired,
  dataUpdateAvailable: PropTypes.bool.isRequired,
  patientComplianceSettings: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps){

  const {customerFilters, customerOrders, customer, customerStat, customerRecentHistory,
     timezone} = state;
  const {custDetailsOpen, cartDetailsOpen} = state.menus;
  const customers = customersWithOrderOfType(state, ownProps);
  const updatedCustomers = customers.map((customer) => {
    const defaultImgUrl = customer.consumer_id ? getImgByGender(customer.gender) : getAnonymousImage();
    customer.type = customer.consumer_type;
    customer.image_url = getImageUrl(customer.image_file, '100x100', defaultImgUrl);
    return customer;
  });
  return {
    isLockPreCheckinOnlineOrder: isLockPreCheckinOnlineOrder(state),
    allowAnonymousOrders: getAllowAnonymousOrders(state),
    dataUpdateAvailable: getDataUpdateAvailable(state, {core: 'customers', name: dataNames.customersQueue})
      || getDataUpdateAvailable(state, {name: dataNames.customersQueue})
      || getDataUpdateAvailable(state, {name: dataNames.orders}),
    fulfillmentOption: getFulfillmentOptions(state, ownProps),
    timezone,
    customerFilters,
    customerOrders,
    custDetailsOpen,
    cartDetailsOpen,
    customer,
    customerStat,
    customerRecentHistory,
    //visibleCustomers: orderBy(filteredCustomers(state), ['order_status', 'order_placed_time'], ['desc', 'asc']),
    customers: updatedCustomers,
    visitReasons: state[dataNames.visitReasons],
    defaultVisitReason: getDefaultVisitReason(state),
    facility: state[itemNames.facility],
    patientComplianceSettings: getPatientsComplianceLimits(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, menuActions,
    {setItem, unsetItem, setItems, unsetData, push});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (QueuePage);
