import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import {FaChevronDown, FaChevronRight} from 'react-icons/fa';
import ErrorBoundry from './ErrorBoundry';

class AccordionPanel extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    const expanded = props.expanded === undefined ? props.defaultExpanded === undefined ? true : props.defaultExpanded : props.expanded;

    this.state  = {expanded};
    this.toggle = this.toggle.bind(this);
    this.renderTitle = this.renderTitle.bind(this);
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.expanded !== this.props.expanded){
      const expanded = nextProps.expanded !== undefined ? nextProps.expanded : true;
      if(expanded !== this.state.expanded){
        this.setState({expanded: nextProps.expanded});
      }
    }
  }

  toggle() {
    const state = !this.state.expanded;
    this.setState({expanded: state});
    if(this.props.onChangeEvent) this.props.onChangeEvent(state);
  }

  renderTitle() {
    const {title, titleParams} = this.props;
    if (typeof title === 'function') {
      return title();
    }

    return title ? I18n.t(title, titleParams) : '';
  }

  render () {
    const {expanded} = this.state;
    const {rightTitle, rightTitleParams, className, children, key, rightControl, renderBodyWhenCollapsed} = this.props;
    const shouldRenderBody = expanded || renderBodyWhenCollapsed;

    return (
      <ErrorBoundry key={key}>
        <Card expanded={expanded} onToggle={() => {}} className={className ? `form-page ${className}` : 'form-page'} >
          <Card.Header>
            <h3 className='panel-title' onClick={this.toggle}>
              {expanded ? <FaChevronDown className='go-back-x'/> : <FaChevronRight className='go-back-x' />}
              {this.renderTitle()}
              {rightTitle ? <span className='float-right'>{I18n.t(rightTitle, rightTitleParams)}</span> : ''}
              {rightControl ? rightControl : ''}
            </h3>
          </Card.Header>

          {shouldRenderBody &&
            <Card.Body collapsible>
              {children}
            </Card.Body>
          }
        </Card>
      </ErrorBoundry>
    );
  }
}

AccordionPanel.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
  ]),
  titleParams: PropTypes.object,
  rightTitle: PropTypes.string,
  rightTitleParams: PropTypes.object,
  defaultExpanded: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onChangeEvent: PropTypes.func,
  rightControl: PropTypes.node,
  renderBodyWhenCollapsed: PropTypes.bool
};

AccordionPanel.defaultProps = {
  titleParams: {},
  renderBodyWhenCollapsed: false
};

export default AccordionPanel;
