import React from 'react';
import PropTypes from 'prop-types';
import InternationalCurrencyStatic from '../common/form/InternationalCurrencyStatic';

const CouponsRow = ({product: {discount_total, unit_price, quantity}, coupons, offsetColumns}) => {
  const lineTotal = unit_price && quantity && parseFloat(unit_price) && parseFloat(quantity)
    ? parseFloat(unit_price) * parseFloat(quantity)
    : 0;
  const price = lineTotal && discount_total && parseFloat(discount_total)
    ? lineTotal - parseFloat(discount_total)
    : 0;

  return (
    <tr className='coupons-row'>
      <td colSpan={offsetColumns}></td>
      <td colSpan={2}>
        <ul>
          {coupons.map((coupon, index) => (
            <li key={index}>
              {coupon.name}
            </li>
          ))}
        </ul>
      </td>
      <td colSpan={2}>
        <InternationalCurrencyStatic>{price}</InternationalCurrencyStatic>
      </td>
    </tr>
  );
};
CouponsRow.propTypes = {
  product: PropTypes.shape({
    discount_total: PropTypes.string.isRequired,
    unit_price: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired
  }),
  coupons: PropTypes.array,
  offsetColumns: PropTypes.number
};
CouponsRow.defaultProps = {
  coupons: [],
  offsetColumns: 4
};
export default CouponsRow;
