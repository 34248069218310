import {I18n} from 'react-redux-i18n';

export const partnerFacilityTypes = [
  { value: 'cultivator', text: I18n.t('partners.types.cultivator'), politicalRegion: [{country: 'CA', province: '*'}, {country: 'US', province: 'OK'}] },
  { value: 'processor', text: I18n.t('partners.types.processor'), politicalRegion: [{country: 'CA', province: '*'}, {country: 'US', province: 'OK'}] },
  { value: 'dispensary', text: I18n.t('partners.types.dispensary'), politicalRegion: [{country: 'CA', province: '*'}, {country: 'US', province: 'OK'}] },
  { value: 'researcher', text: I18n.t('partners.types.researcher'), politicalRegion:  [{country: 'US', province: 'OK'}] },
  { value: 'hemp', text: I18n.t('partners.types.hemp'), politicalRegion: [{country: 'CA', province: '*'}] },
  { value: 'lab', text: I18n.t('partners.types.lab'), politicalRegion: [{country: 'CA', province: '*'}] },
  { value: 'distributor', text: I18n.t('partners.types.distributor'), politicalRegion: [{country: 'CA', province: '*'}] },
];

// These represent types assigned to partner facilities under Oklahoma.
const base = [
  'cultivator',
  'processor',
  'dispensary',
  'researcher',
  'distributor'
];

// Eventually these will diverge into different types but for now they are all the same.
const cultivation = base;
const retail = base;
const eAndI = base;

/***
 * A selector gets the available types from the object based on the current facility type.
 * NOTE there are some aliases in here to support the original code but I am not sure they are still in use.
 * @type {{processor: string[], manufacturing: string[], dispensary: string[], researcher: string[], cultivator: string[], grow: string[]}}
 */
export const oklahomaSupplyChainRules = {
  processor: eAndI,
  manufacturing: eAndI,
  dispensary: retail,
  researcher: base,
  cultivator: cultivation,
  grow: cultivation,
};

export default partnerFacilityTypes;
