import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import get from 'lodash.get';
import InternationalQuantityByUomInput from '../../../../common/form/InternationalQuantityByUomInput';
import WillRender from '../../../../common/concealers/WillRender';
import InternationalCurrencyInput from '../../../../common/form/InternationalCurrencyInput';
import {getPossibleUomsOptions} from '../../../../../selectors/uomsSelectors';
import {convertPrice} from '../../../../../util/uomHelpers';
import * as UOMS from '../../../../../constants/uoms';

const BulkPackage = (props) => {

  const {
    line, change, component, lineItemQuantity, locked, editableLinePrice, lineOrderedQuantity, uoms, fieldsLine,
    receiptStatus, requestedAmountLocked, integrationState: {isLeaf, isBiotrack}, metrcIsImported, isReassignMode,
    changeUnitCostAdjustAll, changeLineUnitPriceAndTotal, hasCostEditPermission, isFeatureToggleEditInventoryItemCost
  } = props;

  const default_uom = fieldsLine.default_uom;
  const uom = get(fieldsLine, 'ordered_uom', null) || get(fieldsLine, 'itemMaster.default_uom', UOMS.GR);
  const conditionalQtyFieldProps = {};
  const isIngredient = get(fieldsLine, 'is_ingredient', false);
  let propsUom = {};
  //@TODO: Investigate whether this is in use.  I did an IR with an ingredient and did not have the option
  // to change UOM which is what would trigger this.
  if (isIngredient) {
    // These come from item master so we use the original unit cost of an ingredient instead of losing
    // precision through conversions.
    const defaultUom = get(fieldsLine, 'itemMaster.default_uom');
    const defaultUnitPrice = parseFloat(get(fieldsLine, 'itemMaster.vendors.0.default_unit_cost', 0));
    propsUom = {
      options: getPossibleUomsOptions(default_uom, uoms),
      selectable: !requestedAmountLocked && (receiptStatus !== 'completed'),
      whiteBackground: !requestedAmountLocked,
      selectableName: `${line}.uom`,
      defaultUom,
      onItemChange: (option) => {
        const newUnitPrice = convertPrice(defaultUnitPrice, defaultUom, option.value);
        change(`${line}.unit_price`, newUnitPrice);
        change(`${line}.uom_display`, option.value);
        change(`${line}.ordered_uom`, option.value);
        fieldsLine.inventory.map((inventory, inventoryIndex) => {
          change(`${line}.inventory[${inventoryIndex}].uom`, option.value);
          change(`${line}.inventory[${inventoryIndex}].uom_display`, option.value);
          change(`${line}.inventory[${inventoryIndex}].gross_weight_uom_display`, option.value);
        });
      },
    };
  }

  if (!isLeaf) {
    conditionalQtyFieldProps.leftAddon = (
      <span style={{cursor: 'pointer'}} onClick={() => {
        change(`${line}.editableRequestedAmount`, requestedAmountLocked);
        change(`${line}.editableLinePrice`, !requestedAmountLocked);
      }}>
        {requestedAmountLocked ? `Edit` : `Lock`}
      </span>
    );
  }

  let quantityValidatorResult;
  if (isBiotrack) {
    quantityValidatorResult = parseFloat(lineItemQuantity) > parseFloat(lineOrderedQuantity);
  } else if (metrcIsImported) {
    quantityValidatorResult = parseFloat(lineItemQuantity) > parseFloat(lineOrderedQuantity);
  } else {
    quantityValidatorResult = parseFloat(lineItemQuantity) !== parseFloat(lineOrderedQuantity);
  }

  const [editButtonClick, setEditButtonClicked] = useState(true);
  const toggleEditButtonClick = () => {
    changeUnitCostAdjustAll();
    setEditButtonClicked(!editButtonClick);
  };

  return (
    <Col lg={12}>
      <Row>
        {component}
        <Col xs={12} lg={2}>
          <InternationalQuantityByUomInput
            name={`${line}.ordered_qty`}
            label={I18n.t('purchaseOrders.form.requestedAmount')}
            readOnly={requestedAmountLocked}
            uom={uom}
            defaultUom={uom}
            props={propsUom}
            {...conditionalQtyFieldProps}
          />
        </Col>
        <Col xs={12} lg={2}>
          <InternationalQuantityByUomInput
            name={`${line}.qty`}
            readOnly={true}
            label={I18n.t('cultivation.transfers.form.receivedNet')}
            uom={uom}
            value={lineItemQuantity}
          />
        </Col>
        <Col xs={12} lg={2}>
          {!fieldsLine.is_ingredient ?
          <InternationalCurrencyInput
            name={`${line}.unit_price`}
            props={{
              label: I18n.t('uoms.forms.pricePer', {label: I18n.t(`uoms.${uom}.word`)}),
              disabled: editButtonClick || !hasCostEditPermission || !isReassignMode,
              onBlur: (e) => {
                changeLineUnitPriceAndTotal(e.target.value, line);
              },
              rightAddon: (
                isReassignMode && hasCostEditPermission && isFeatureToggleEditInventoryItemCost && (
                  <span className={`btn btn-primary ${!isReassignMode || !hasCostEditPermission ? 'disabled' : ''}`} onClick={() => {
                    toggleEditButtonClick();
                  }}>
                    {editButtonClick ? 'Edit' : 'Calc'}
                  </span>
                )
              )
            }}
          /> : null}
        </Col>
        <Col xs={12} lg={2}>
          <WillRender ifTrue={isIngredient}>
            <InternationalCurrencyInput name={`${line}.line_item_price`}
              props={{
                label: I18n.t('purchaseOrders.form.itemPrice'),
                disabled: true,
              }}
            />
          </WillRender>
          <WillRender ifTrue={!isIngredient}>
            <InternationalCurrencyInput name={`${line}.line_item_price`}
              props={{
                label: I18n.t('purchaseOrders.form.itemPrice'),
                disabled: !editableLinePrice || (hasCostEditPermission && isReassignMode),
                rightAddon: locked
                  ? null
                  : (
                    <span className={`btn btn-primary ${!isReassignMode || !hasCostEditPermission ? 'disabled' : ''}`} onClick={() => {
                      const newEditableValue = !editableLinePrice;
                      change(`${line}.editableLinePrice`, newEditableValue);
                    }}>
                      {editableLinePrice ? 'Calc' : 'Edit'}
                    </span>
                  )
              }}
            />
          </WillRender>
        </Col>
      </Row>
      <Row style={quantityValidatorResult ? {} : {display: 'none'}}>
        <Col md={12}>
          <div className='text-danger' style={{marginBottom: '14px'}}>
            {
              I18n.t(
                `receipts.${metrcIsImported ? 'metrcImportedOrderedQuantityNotEqualFulfilledQuantity' : 'orderedQuantityNotEqualFulfilledQuantity'}`,
                {
                  orderedQuantityField: I18n.t('purchaseOrders.form.requestedAmount'),
                  fulfilledQuantityField: I18n.t('cultivation.transfers.form.receivedNet')
                })
            }
          </div>
        </Col>
      </Row>
    </Col>);
};

BulkPackage.propTypes = {
  component: PropTypes.object.isRequired,
  line: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  lineItemPrice: PropTypes.string,
  lineItemQuantity: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  locked: PropTypes.bool.isRequired,
  editableLinePrice: PropTypes.bool.isRequired,
  lineOrderedQuantity: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  uoms: PropTypes.array.isRequired,
  fieldsLine: PropTypes.object.isRequired,
  requestedAmountLocked: PropTypes.bool.isRequired,
  integrationState: PropTypes.object,
  metrcIsImported:PropTypes.bool,
  onlyEntireQuantityCanBeTransferred: PropTypes.bool,
  receiptStatus: PropTypes.string,
  isReassignMode: PropTypes.bool,
  changeUnitCostAdjustAll: PropTypes.func.isRequired,
  changeLineUnitPriceAndTotal: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  hasCostEditPermission: PropTypes.bool.isRequired,
  isFeatureToggleEditInventoryItemCost: PropTypes.bool.isRequired
};

export default BulkPackage;
