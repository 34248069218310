import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Button, Col, Row} from 'react-bootstrap';
import {FaExclamationTriangle} from 'react-icons/fa';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Link} from 'react-router';
import get from 'lodash.get';
import TextInput from '../../../common/form/TextInput';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import {NON_MEDICATED_CATEGORY_ID} from '../../../../constants/categories';
import InternationalDecimalInput from '../../../common/form/InternationalDecimalInput';
import {GR, MG} from '../../../../constants/uoms';
import ComboboxInput from '../../../common/form/ComboboxInput';
import {getStrains} from '../../../../selectors/forms/productFormSelectors';
import dominanceOptions from '../../../../constants/strainDominance';

const ProductPanel = (props) => {
  const {
    isWaInventoryMapped,
    formValues,
    productTypeOptions,
    //categories,
    subcategories,
    subCategoryMapErrors,
    onSubcategoryChange,
    selectedSubCategory,
    integrationState,
    isMasterFacility,
    allUoms,
    uomOptions,
    strainOptions,
    dominanceOptions,
    change,
    medicatedWeightRequired,
    isMedicatedWeightAutoSuffix,
    isAppendBrandToItemMasterName,
    brands,
  } = props;

  const allStrainOptions = [
    {id: null, strain_name: 'N/A'},
    {id: 0, strain_name: 'Multiple'}
  ].concat(strainOptions);

  const {isOrMetrc, isMetrc} = integrationState || false;

  const isLockedProduct = get(formValues, 'has_items');
  const {isUtah} = integrationState || false;

  const is_medicated = get(formValues, 'category_id') === NON_MEDICATED_CATEGORY_ID ? false : get(formValues, 'category_id') ? true : null;
  const is_discrete = get(formValues, 'default_uom', '') === 'EA';
  // To determine if the medicated weight is calculated we need to have potency_test_result values AND those values need to have the checkbox AND a value_per_unit AND a uom
  const potency_test_results = get(formValues, 'potency_test_results', []);
  const is_medicated_weight_calculated = potency_test_results && potency_test_results.length && potency_test_results.some((test_result) => Object.keys(test_result).length > 0 && get(test_result, 'use_for_med_weight', false) && get(test_result, 'value_per_unit') && get(test_result, 'uom'));

  const medicated_weight_label =
    isOrMetrc &&
    get(selectedSubCategory, 'subcategory_code') &&
    ['NON-INFUSED PRE-ROLLS', 'INFUSED PRE-ROLLS'].indexOf(selectedSubCategory.subcategory_code) > -1 &&
    get(formValues, 'inventory_attributes.is_prepack')
      ? I18n.t('products.form.medicatedNetWeightPer1Gr')
      : I18n.t('products.form.medicatedNetWeight');

  const med_weight_uoms = (Array.isArray(allUoms))
    ? allUoms.filter(uom => [GR, MG].indexOf(uom.uom_code) > -1)
    : [];

  // Adds the brand, weight and UoM to the product name for EA items (if setting is enabled)
  const constructProductName = (changedFormValues) => {
    // Start of with existing form values and overwrite changed value
    const inputs = {
      ...{ name: get(formValues, 'name_edit', ''),
        brand: get(formValues, 'brand.name', get(formValues, 'brand', '')),
        medicated_weight: get(formValues, 'medicated_weight', ''),
        medicated_weight_uom: get(formValues, 'medicated_weight_uom', '')
      },
      ...changedFormValues
    };

    // Construct product name - Structure: %name% [- %brand%][: %med_weight%%med_weight_uom%]
    let name = `${inputs.name}`;

    if (!name) {
      return '';
    }

    if (isAppendBrandToItemMasterName && inputs.brand) {
      name += ` - ${inputs.brand}`;
    }

    if (isMedicatedWeightAutoSuffix && get(formValues, 'default_uom') === 'EA' && parseFloat(inputs.medicated_weight) && inputs.medicated_weight_uom) {
      const uom = inputs.medicated_weight_uom === 'GR'
          ? 'g'
          : inputs.medicated_weight_uom === 'MG'
            ? 'mg'
            : inputs.medicated_weight_uom;
      name += `: ${inputs.medicated_weight}${uom}`;
    }

    return name; // Trim in case nothing was added
  };

  return (
    <React.Fragment>
      <Row>
        <Col sm={4}/>
        <Col sm={4}>
          <Field
            name='name_edit'
            component={TextInput}
            props={{
              label: I18n.t('products.form.productName'),
              placeholder: I18n.t('products.form.productNamePlaceholder'),
              isRequired: true,
              disabled: isWaInventoryMapped,
            }}
            onChange={(event, value) => {
              change('name', constructProductName({name: value}));
            }}
          />
        </Col>
        <Col sm={4}>
          <Field
            name='brand'
            component={ComboboxInput}
            props={{
              label: I18n.t('products.form.productBrand'),
              placeholder: I18n.t('products.form.productBrandPlaceholder'),
              options: brands,
              textKey: 'name',
              valueKey: 'id'
            }}
            onChange={(event, value) => {
              const brandName = value.hasOwnProperty('name') ? value.name : value;
              change('name', constructProductName({brand: brandName}));
              change('brand', value);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={4}/>
        <Col sm={4}>
        <label>{I18n.t('products.form.productType')}</label><br/>
        <label>
            <Field name='product_type'
              component='input'
              type='radio'
              value='med,rec'
            />
            &nbsp;{I18n.t('products.form.medicalAndRecreational')}
          </label>
          &nbsp;&nbsp;
          <label>
            <Field name='product_type'
              component='input'
              type='radio'
              value='med'
            />
            &nbsp;{I18n.t('products.form.medical')}
          </label>
          &nbsp;&nbsp;
          <label>
            <Field name='product_type'
              component='input'
              type='radio'
              value='rec'
            />
            &nbsp;{I18n.t('products.form.recreational')}
          </label>
        </Col>
        <Col sm={4}/>
      </Row>
      <hr />
      <Row>
        <Col sm={4}>
          <Field
            name='category_id'
            component={ReactSelectInput}
            props={{
              label: I18n.t('products.form.productCategory'),
              options: productTypeOptions,
              textKey: 'label',
              valueKey: 'id',
              disabled: isLockedProduct, // Don't allow category change if Product Master has items
              isRequired: true,
              onChange: (value) => {
                change('category_id', value);
                // Clear medicated_weight and medicated_weight_uom if not medicated
                if (value === NON_MEDICATED_CATEGORY_ID) {
                  change('medicated_weight', '');
                  change('medicated_weight_uom', '');
                  change('name', constructProductName({medicated_weight: '', medicated_weight_uom: ''}));
                }
              }
            }}
          />
        </Col>
        <Col sm={4}>
          <Field
            name='default_uom'
            component={ReactSelectInput}
            props={{
              label: I18n.t('products.form.productUom'),
              placeholder: I18n.t('common.form.uomPlaceholder'),
              onChange: (value) => {
                const selectedOption = uomOptions.find((uom) => uom.uom_code === value);
                change('default_uom', value);
                change('uom_type', (selectedOption && selectedOption.uom_type) || '');
                if (value !== 'EA') {
                  // Clear medical_weight fields
                  change('medicated_weight', '');
                  change('medicated_weight_uom', '');
                  change('test_results_value_type', 'percentage');
                  change('prices.pricing_multiplier', '');
                  change('name', constructProductName({medicated_weight: '', medicated_weight_uom: ''}));
                }
              },
              options: uomOptions,
              textKey: 'uom_code',
              valueKey: 'uom_code',
              isRequired: true,
              disabled: isLockedProduct || isWaInventoryMapped
            }}
          />{I18n.t('products.form.productUomInfo')}<br/><br/>
        </Col>
        <Col sm={4}>
        <Field
            name='strain'
            component={ComboboxInput}
            createNew={true}
            props={{
              label: I18n.t('products.form.strainName'),
              placeholder: I18n.t('common.form.strainPlaceholder'),
              options: allStrainOptions,
              textKey: 'strain_name',
              valueKey: 'id',
              isRequired: true,
              disabled: isLockedProduct || isWaInventoryMapped,
              onChange: (value) => {
                change('strain_id', get(value, 'id'));
                change('strain', value);
              },
              value: get(formValues, 'strain_id')
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <Field
            name='subcategory_id'
            component={ReactSelectInput}
            props={{
              label: I18n.t('products.form.productSubcategory'),
              options: subcategories,
              textKey: 'display_name',
              valueKey: 'id',
              isRequired: true,
              disabled:
                 (selectedSubCategory && selectedSubCategory.is_shared && !isMasterFacility) ||
                 isWaInventoryMapped ||
                 (isLockedProduct && isUtah)
            }}
            onChange={(event, newValue, previousValue) => onSubcategoryChange(newValue, previousValue)}
          />
          {!subCategoryMapErrors ? null : (
            <div className='alert alert-danger'>
              <div>
                <FaExclamationTriangle style={{ marginRight: '5px' }} />
                {I18n.t('products.form.productSubcategoryError.reason')}
                <div style={{ clear: 'both', height: '8px' }} />
                <ul>
                  {subCategoryMapErrors.map((sub, index) => (
                    <li key={index}>{I18n.t('products.form.productSubcategoryError.category', {friendlyType: sub.friendlyType})}</li>
                  ))}
                </ul>
                <div style={{ clear: 'both', height: '8px', marginBottom: '12px' }}>
                  <Link to={{ pathname: '/category/management', state: { goBackAfterSubmit: true } }} onlyActiveOnIndex={false}>
                    <Button variant='link'>{I18n.t('products.form.productSubcategoryError.link')}</Button>
                  </Link>
                </div>
              </div>
            </div>
          )}
          {!(isLockedProduct && isUtah) ? null : (
            <div className='alert alert-warning'>
              <div>
                <FaExclamationTriangle style={{ marginRight: '5px' }} />
                {I18n.t('products.form.productSubcategoryError.locked')}
              </div>
            </div>
          )}
        </Col>
        <Col sm={2}>
          <InternationalDecimalInput
            name='medicated_weight'
            props={{
              label: medicated_weight_label,
              fractionPartSize: 3,
              step: get(formValues, 'medicated_weight_uom', 'MG') === 'MG' ? 1 : 0.001,
              isRequired: /*formValues.required_fields && formValues.required_fields.requires_unit_thc_content || */medicatedWeightRequired,
              disabled: !is_medicated || !is_discrete || is_medicated_weight_calculated,
              onChange: (value) => {
                change('name', constructProductName({medicated_weight: value}));
              }
            }}
          />
        </Col>
        <Col sm={2}>
            <Field name='medicated_weight_uom' component={ReactSelectInput} props={{
              label: I18n.t('products.form.uom'),
              options: med_weight_uoms,
              textKey: 'uom_code',
              valueKey: 'uom_code',
              disabled: !is_medicated || !is_discrete || is_medicated_weight_calculated,
              isRequired: /*formValues.required_fields && formValues.required_fields.requires_unit_thc_content || */medicatedWeightRequired,
              onChange: (value) => {
                change('name', constructProductName({medicated_weight_uom: value}));
                change('medicated_weight_uom', value);
              }
            }}/>
        </Col>
        <Col sm={4}>
            <Field
              name='dominance'
              component={ReactSelectInput}
              props={{
                label: I18n.t('products.form.dominanceName'),
                options: dominanceOptions,
                textKey: 'text',
                valueKey: 'value',
              }}
            />
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <Field
            name='is_medicated'
            component={ReactSelectInput}
            props={{
              label: I18n.t('products.form.productIsMedicated'),
              options: [{text: 'Yes', value: true},{text: 'No', value: false}],
              textKey: 'text',
              valueKey: 'value',
              disabled: true,
              value: is_medicated
            }}
          />
        </Col>
        {isMetrc && <Col sm={4}>
          <Field
            name='state_tracking_uom'
            component={ReactSelectInput}
            props={{
              label: I18n.t('products.form.stateTrackingUom'),
              options: [{text: 'GR', value: 'Grams'},{text: 'EA', value: 'Each'}],
            }}
          />
        </Col>}
        <Col sm={4}/>
      </Row>

    </React.Fragment>
  );
};

ProductPanel.propTypes = {
  formValues: PropTypes.object.isRequired,
  isWaInventoryMapped: PropTypes.bool.isRequired,
  productTypeOptions: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  subcategories: PropTypes.array.isRequired,
  subCategoryMapErrors: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
  onSubcategoryChange: PropTypes.func.isRequired,
  selectedSubCategory: PropTypes.object,
  integrationState: PropTypes.object,
  isMasterFacility: PropTypes.bool.isRequired,
  allUoms: PropTypes.array.isRequired,
  uomOptions: PropTypes.array.isRequired,
  strainOptions: PropTypes.array.isRequired,
  dominanceOptions: PropTypes.array,
  change: PropTypes.func.isRequired,
  medicatedWeightRequired: PropTypes.bool,
  isMedicatedWeightAutoSuffix: PropTypes.bool.isRequired,
  isAppendBrandToItemMasterName: PropTypes.bool.isRequired,
  brands: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  return {
    strainOptions: getStrains(state),
    dominanceOptions
  };
}

export default connect(mapStateToProps)(ProductPanel);
