import React from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {change} from 'redux-form';
import {goBack} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import FacilityInitialInventoryForm from './FacilityInitialInventoryForm';
import {FACILITY_INITIAL_INVENTORY_FORM} from '../../constants/forms';
import * as itemNames from '../../constants/itemNames';
import {getClosedLoopFacility} from '../../selectors/closedLoopsSelectors';
import {getItem, postItem} from '../../actions/apiActions';
import {unsetItem} from '../../actions/itemActions';
import FormWrapper from '../common/form/FormWrapper';
import ModalWrapper from '../common/ModalWrapper';
import {isFeatureEnabled} from '../../selectors/featureToggles';

class FacilityInitialInventoryPage extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      showConfirmModal: false,
    };
  }

  componentDidMount() {
    const {actions: {getItem}, params: {id}} = this.props;
    getItem('/api/closed_loops/facilities_administering', itemNames.closedLoopFacility, null, {facility_id: id});
  }

  componentWillUnmount() {
    this.props.actions.unsetItem(itemNames.closedLoopFacility);
  }

  handleSubmit(values) {
    const messages = {success: 'closedLoop.facility.inventorySettingsSuccess', failed: 'closedLoop.facility.inventorySettingsFailed'};
    this.props.actions.postItem(`/api/closed_loops/facilities_administering/${values['facility_id']}`, values, '', messages)
      .then((facility) => {
        this.props.actions.change(FACILITY_INITIAL_INVENTORY_FORM, 'allow_initial_inventory', facility['allow_initial_inventory']);
        this.props.actions.change(FACILITY_INITIAL_INVENTORY_FORM, 'current_initial_inventory_by_date', facility['initial_inventory_by_date']);
      });
  }

  showConfirmModal(values) {
    this.setState({showConfirmModal: true, values: values});
  }

  render() {
    const {facility, isFeatureGeneticsWindowPhase2Enabled} = this.props;

    const facility_type = facility ? facility.type.charAt(0).toUpperCase() + facility.type.slice(1) : '';

    return (
      <FormWrapper
        className='product-master-page'
        title={I18n.t('closedLoop.facility.profilePageTitle')}
        localize={false}
        form={FACILITY_INITIAL_INVENTORY_FORM}
        goBack={this.props.actions.goBack}
      >
        <ModalWrapper
          Component={false}
          title={I18n.t('closedLoop.facility.confirmTitle')}
          version={2}
          showModal={this.state.showConfirmModal}
          dialogClassName='modal-sm'
          okayButton={{
            show: true,
            variant: 'primary',
            text: 'common.confirm'
          }}
          cancelButton={{
            show: true,
            variant: 'primary',
            text: 'common.cancel',
          }}
          onHide={(e) => {
            if (e.buttonClicked === 'okay') {
              this.handleSubmit(this.state.values);
            }
            this.setState({showConfirmModal: false});
          }}
        >
          <p>{I18n.t('closedLoop.facility.confirmCancelActiveInitialInventory')}</p>
        </ModalWrapper>
        {facility &&
          <React.Fragment>
            <h3>{facility.name}</h3>
            <div>
              <div><strong>{I18n.t('closedLoop.facility.type')}</strong>: {facility_type}</div>
              <div><strong>{I18n.t('closedLoop.facility.address')}</strong>: {facility.street_address_1}</div>
              {facility.street_address_2 && <div><strong>{I18n.t('closedLoop.facility.address2')}</strong>: {facility.street_address_2}</div>}
              <div><strong>{I18n.t('closedLoop.facility.city')}</strong>: {facility.city}</div>
              <div><strong>{I18n.t('closedLoop.facility.state')}</strong>: {facility.province_code}</div>
              <div><strong>{I18n.t('closedLoop.facility.country')}</strong>: {facility.country_code}</div>
            </div>

            <hr />

            {isFeatureGeneticsWindowPhase2Enabled && <FacilityInitialInventoryForm
              form={FACILITY_INITIAL_INVENTORY_FORM}
              handleSubmit={(values) => this.handleSubmit(values)}
              showConfirmModal={(values) => this.showConfirmModal(values)}
            />}
          </React.Fragment>
        }

      </FormWrapper>
    );
  }
}

FacilityInitialInventoryPage.propTypes = {
  formValues: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  facility: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  facility: getClosedLoopFacility(state),
  isFeatureGeneticsWindowPhase2Enabled: isFeatureEnabled(state)('feature_genetics_window_phase_2')
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({getItem, unsetItem, postItem, change, goBack}, dispatch)
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(FacilityInitialInventoryPage);
