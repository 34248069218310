import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {getFacilityStrainsTableData, getSelectedFacilityStrains, getSelectedFacilityStrainIds} from '../../selectors/facilityStrainsSelectors';
import {handleComplexSelectRow} from '../../actions/helpers/selectedDataHelper';
import {clearSelectedData} from '../../actions/selectedDataActions';
import * as apiActions from '../../actions/apiActions';
import * as dataNames from '../../constants/dataNames';
import TablePageWrapper from '../common/grid/TablePageWrapper';
import strainDominance from '../../constants/strainDominance';
import {isLeafIntegrator} from '../../selectors/integrationSelectors';
import * as p from '../../constants/permissions';

export class StrainsListingPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentWillMount() {
    this.props.actions.getUnpaginatedData('/api/strains/by_facility', dataNames.facilityStrains, undefined, {include_cultivation: 1});
    this.props.actions.getUnpaginatedData('/api/integration-mapping', dataNames.integrationMapping, null, {key: 'inv_facility_strain'});
  }

  handleSelect (isSelected, rows) {
    this.props.actions.handleComplexSelectRow(rows, dataNames.facilityStrains, isSelected);
  }

  render() {
    const columns = [
      {
        name: 'strains.listing.strainName',
        dataId: 'strain_name',
        hidden: false,
        dataSort: true,
      },
      {
        name: 'strains.pollenDonor',
        dataId: 'pollen_donor',
        hidden: false,
        dataSort: true,
        formatter: (cell, row) => {return (cell == '') ? '-' : cell ;} // eslint-disable-line react/display-name
      },
      {
        name: 'strains.mother',
        dataId: 'mother',
        hidden: false,
        dataSort: true,
        permissions: [p.manage_plant_lineage],
        formatter: (cell, row) => {return (cell == '') ? '-' : cell ;} // eslint-disable-line react/display-name
      },
      {
        name: 'strains.dominance',
        dataId: 'dominance',
        hidden: false,
        dataSort: true,
        formatter: (cell, row) => {
          const strain = strainDominance.find(strain => strain.value === cell);
          return strain ? strain.text : cell;
        }
      },
      {
        name: 'strains.strainCode',
        dataId: 'strain_code',
        hidden: false,
        dataSort: true,
      },
      {
        name: 'strains.propCycle',
        dataId: 'prop_days',
        hidden: false,
        dataSort: true,
        permissions: [p.forecasting_yield_management],
        formatter: (cell, row) => {return (cell !== undefined) ? cell + ' days' : '-';} // eslint-disable-line react/display-name
      },
      {
        name: 'strains.vegetativeCycle',
        dataId: 'veg_days',
        text: 'WTF',
        hidden: false,
        dataSort: true,
        permissions: [p.forecasting_yield_management],
        formatter: (cell, row) => {return (cell) ? cell + ' days' : '-';} // eslint-disable-line react/display-name
      },
      {
        name: 'strains.flowerCycle',
        dataId: 'flower_days',
        hidden: false,
        dataSort: true,
        permissions: [p.forecasting_yield_management],
        formatter: (cell, row) => {return (cell) ? cell + ' days' : '-';} // eslint-disable-line react/display-name
      }
    ];
    if (this.props.isLeaf) {
      columns.push({
        name: 'strains.trackingId',
        dataId: 'tracking_id',
        hidden: false,
        dataSort: true,
      });
    }
    const {strains, selectedStrainIds} = this.props;
    const actions = [
      {
        id: 'addNewStrain',
        path: '/strains/organization',
        text: 'strains.listing.addForOrganization',
        glyph: 'plus-sign',
        requireSelect: false
      },
      {
        id: 'addForFacility',
        path: '/strains/facility',
        text: 'strains.listing.addForFacility',
        glyph: 'plus-sign',
        requireSelect: false
      },
      {
        id: 'createPackage',
        path: '/strains/modify',
        text: 'strains.listing.editStrain',
        glyph: 'briefcase',
        requireSelect: true
      }
    ];
    return (
      <div className='strain-listing-page'>
        <h1>{I18n.t('strains.listing.title')}</h1>
        <TablePageWrapper
          ref={this.ref}
          settingKey='strains'
          columns={columns}
          data={strains}
          actions={actions}
          selectedRows={selectedStrainIds}
          handleSelect={this.handleSelect}
          hideScanSearch = {true}
          className='strain-page'
          hideExport={true}
          bstProps={{
            options: {
              onSearchChange: searchText => {
                if (!searchText) {
                  this.props.actions.clearSelectedData(dataNames.facilityStrains);
                }
              }
            }
          }}
          />
      </div>
    );
  }
}

StrainsListingPage.propTypes = {
  selectedStrains: PropTypes.array.isRequired,
  selectedStrainIds: PropTypes.array.isRequired,
  strains: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  isLeaf: PropTypes.bool.isRequired
};

// StrainsListingPage.contextTypes = {
//   router: React.PropTypes.object.isRequired
// };

export function mapStateToProps(state) {
  return {
    selectedStrainIds: getSelectedFacilityStrainIds(state),
    selectedStrains: getSelectedFacilityStrains(state),
    strains: getFacilityStrainsTableData(state),
    isLeaf: isLeafIntegrator(state)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions, {handleComplexSelectRow}, {clearSelectedData});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StrainsListingPage);
