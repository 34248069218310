import React from 'react';
import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import {FaAsterisk} from 'react-icons/fa';
import PropTypes from 'prop-types';
import InternationalDecimalInput from '../../common/form/InternationalDecimalInput';
import InternationalDecimalReadOnlyInput from '../../common/form/InternationalDecimalReadOnlyInput';


const DoubleFieldInfoColumn = ({row, column, editable, isRequired, disabled, onChange, change, isPerServingMode, servingsPerContainer, servingsUom}) => {
  const column_name = (get(column, 'display_name'))  ? get(column, 'display_name') : I18n.t(column.name);
  const precision = get(column, 'precision', 3);

  const convertValue = (value, key, convertTo, servingsPerContainer) => {
    let multiplier = 1;
    let fieldName = '';
    switch (convertTo) {
    case 'mg_g':
      multiplier = 10;
      fieldName = `${key}_mg_g`;
      break;
    case 'percent':
      multiplier = 0.1;
      fieldName = `${key}_percent`;
      break;
    case 'per_pack':
      if (!servingsPerContainer) {
        return;
      }
      multiplier = servingsPerContainer;
      fieldName = `${key}_mg_g`;
      break;
    case 'per_serving':
      if (!servingsPerContainer) {
        return;
      }
      multiplier = 1 / servingsPerContainer;
      fieldName = `${key}_percent`;
      break;
    }
    // Perform conversion with a precision of up to 6 decimals and strip trailing zeros or return empty string
    const convertedValue = value ? Number((value * multiplier).toFixed(6)) : '';
    change(fieldName, convertedValue);
  };

  return (
    <Col xs={12} sm={6} md={4} lg={3} key={column.key}>
      {isRequired && <FaAsterisk style={{fontSize: 'smaller'}} />}<span className='bold'>{column_name}</span>
      <Row>
        <Col xs={6} className='transparent-addon-right'>
          {editable ?
            <InternationalDecimalInput
              name={`${column.key}_percent`}
              props={{
                rightAddon: !isPerServingMode ? '%' : servingsUom === 'mg' ? I18n.t('products.form.productPerServingMg') : I18n.t('products.form.productPerServingGr'),
                fractionPartSize: precision,
                allowNegativeNumber: false,
                disabled,
                onChange: (value) => {
                  convertValue(value, column.key, isPerServingMode ? 'per_pack' : 'mg_g', servingsPerContainer);
                  change(`${column.key}_percent`, value);
                  if (typeof onChange === 'function') {
                    onChange();
                  }
                }
              }}/> :
            <InternationalDecimalReadOnlyInput
              name={`${column.key}_percent`}
              props={{
                rightAddon: !isPerServingMode ? '%' : servingsUom === 'mg' ? I18n.t('products.form.productPerServingMg') : I18n.t('products.form.productPerServingGr'),
                value: row[`${column.key}_percent`],
              }}/>
          }
        </Col>
        <Col xs={6} className='transparent-addon-right'>
          {editable ?
            <InternationalDecimalInput
              name={`${column.key}_mg_g`}
              props={{
                rightAddon: !isPerServingMode ? I18n.t('common.units.mgg') : servingsUom === 'mg' ? I18n.t('products.form.productPerPackMg') : I18n.t('products.form.productPerPackGr'),
                fractionPartSize: 3,
                allowNegativeNumber: false,
                disabled,
                onChange: (value) => {
                  convertValue(value, column.key, isPerServingMode ? 'per_serving' : 'percent', servingsPerContainer);
                  change(`${column.key}_mg_g`, value);
                  if (typeof onChange === 'function') {
                    onChange();
                  }
                }
              }}/> :
            <InternationalDecimalReadOnlyInput
              name={`${column.key}_mg_g`}
              props={{
                rightAddon: !isPerServingMode ? I18n.t('common.units.mgg') : servingsUom === 'mg' ? I18n.t('products.form.productPerPackMg') : I18n.t('products.form.productPerPackGr'),
                value: row[`${column.key}_mg_g`],
              }}/>
          }
        </Col>
      </Row>
    </Col>
  );
};

DoubleFieldInfoColumn.propTypes = {
  row: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  change: PropTypes.func,
  isPerServingMode: PropTypes.bool.isRequired,
  servingsPerContainer: PropTypes.string,
  servingsUom: PropTypes.string
};

export default DoubleFieldInfoColumn;
