/* eslint-disable import/prefer-default-export*/
import {createSelector} from 'reselect';
import get from 'lodash.get';
import moment from 'moment';
import {I18n} from 'react-redux-i18n';
import {getFacilityHasModule} from './facilitiesSelectors';
import { isInternationalSettingsEnabled, getCoreInternationalSettings } from './coreSettingsSelectors';
import {getConstants} from './constantsSelectors';


export const isInternationalModuleEnabled = createSelector(
[getFacilityHasModule, isInternationalSettingsEnabled],
(hasModule, settingsEnabled) => {
  return  (hasModule('INTERNATIONAL_OPERATIONS') && settingsEnabled);
});

export const getCurrencySymbol = createSelector(
[getFacilityHasModule, isInternationalSettingsEnabled, getCoreInternationalSettings, getConstants],
(hasModule, settingsEnabled, internationalSettings, constants) => {

  const symbol = get(constants, 'core.international_currency_symbols.' + get(internationalSettings, 'core_currency_symbol.value', 'CURRENCY_SYMBOL_DOLLAR'), '$');
  return  (hasModule('INTERNATIONAL_OPERATIONS') && settingsEnabled) ? symbol : get(constants, 'core.international_currency_symbols.CURRENCY_SYMBOL_DOLLAR', '$');
});

export const getCurrencySymbolName = createSelector(
[getFacilityHasModule, isInternationalSettingsEnabled, getCoreInternationalSettings],
(hasModule, settingsEnabled, internationalSettings) => {

  const symbolName = get(internationalSettings, 'core_currency_symbol.value', 'CURRENCY_SYMBOL_DOLLAR');
  return  (hasModule('INTERNATIONAL_OPERATIONS') && settingsEnabled) ? symbolName : 'CURRENCY_SYMBOL_DOLLAR';
});

export const getInternationalNumberFormat = createSelector(
  [getFacilityHasModule, isInternationalSettingsEnabled, getCoreInternationalSettings],
  (hasModule, settingsEnabled, internationalSettings) => {

    const separator = get(internationalSettings, 'core_numerical_separator.value', 'NUMERICAL_SEPARATOR_COMMA');
    return  (hasModule('INTERNATIONAL_OPERATIONS') && settingsEnabled) ? separator : 'NUMERICAL_SEPARATOR_COMMA';
  });

export const getPhoneShouldIncludeCountryCode = createSelector(
  [getFacilityHasModule, isInternationalSettingsEnabled, getCoreInternationalSettings],
  (hasModule, settingsEnabled, internationalSettings) => {

    const include_country_code = (get(internationalSettings, 'core_phone_number_include_country_code.value', false)) == 'true' ? true : false;
    return  (hasModule('INTERNATIONAL_OPERATIONS') && settingsEnabled) ? include_country_code : false;
  });

export const getInternationalDateFormat = createSelector(
  [getFacilityHasModule, isInternationalSettingsEnabled, getCoreInternationalSettings],
  (hasModule, settingsEnabled, internationalSettings) => {
    const defaultFormat = 'MM/DD/YYYY';
    const dateFormat = get(internationalSettings, 'core_date_format.value', defaultFormat);
    return  (hasModule('INTERNATIONAL_OPERATIONS') && settingsEnabled) ? dateFormat : defaultFormat;
  });

export const getInternationalTimeFormat = createSelector(
  [getFacilityHasModule, isInternationalSettingsEnabled, getCoreInternationalSettings],
  (hasModule, settingsEnabled, internationalSettings) => {

    let timeFormat = get(internationalSettings, 'core_time_format.value', 'hh:MM A');
    // 1) MM is for the month number, so we are replacing 'MM' with 'mm'
    // 2) It has been decided that imposing to enter the leading '0' in the hour portion of the time was not user friendly,
    //    the time formats have universally changed to 'h' instead of 'hh', and 'H' instead of 'HH'
    timeFormat = timeFormat.replace('hh', 'h').replace('HH', 'H').replace('MM', 'mm');
    return  (hasModule('INTERNATIONAL_OPERATIONS') && settingsEnabled) ? timeFormat : 'h:mm A';
  });

export const getInternationalDefaultLanguage = createSelector(
  [getFacilityHasModule, isInternationalSettingsEnabled, getCoreInternationalSettings],
  (hasModule, settingsEnabled, internationalSettings) => {
    const default_language = get(internationalSettings, 'core_default_language.value', 'en');
    return  (hasModule('INTERNATIONAL_OPERATIONS') && settingsEnabled && default_language) ? default_language : 'en';
  });

export const getDateTimeFieldValidator = createSelector(
  [getInternationalDateFormat, getInternationalTimeFormat],
  (internationalDateFormat,internationalTimeFormat) => {
    const momentTimeFormat = internationalTimeFormat === 'H:mm' ? 'H:mm' : 'h:mm A';
    const momentDateTimeFormat = `${internationalDateFormat} ${momentTimeFormat}`;

    const isValidDate = (value) => {
      return (value && moment(value, momentDateTimeFormat, true).isValid()  || value === null || value === undefined  || value === '' ? undefined : I18n.t('common.form.wrongInternationalDateTimeFormat', {format: momentDateTimeFormat}));
    };

    return isValidDate;
  });

export const getDateFieldValidator = createSelector(
  [getInternationalDateFormat],
  (internationalDateFormat) => {
    const momentDateTimeFormat = `${internationalDateFormat}`;

    const isValidDate = (value) => {
      return (value && moment(value, momentDateTimeFormat, true).isValid() || value === null || value === undefined  || value === '' ? undefined : I18n.t('common.form.wrongInternationalDateTimeFormat', {format: momentDateTimeFormat}));
    };

    return isValidDate;
  });

export const getTimeFieldValidator = createSelector(
  [getInternationalTimeFormat],
  (internationalTimeFormat) => {
    const momentTimeFormat = internationalTimeFormat === 'H:mm' ? 'H:mm' : 'h:mm A';
    const momentDateTimeFormat = `${momentTimeFormat}`;

    const isValidDate = (value) => {
      return (value && moment(value, momentDateTimeFormat, true).isValid() || value === null || value === undefined  || value === ''  ? undefined : I18n.t('common.form.wrongInternationalDateTimeFormat', {format: momentDateTimeFormat}));
    };
    return isValidDate;
  });
