import moment from 'moment';
import React from 'react';
import {Col,Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Field, reduxForm, reset} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {CASH_ON_HAND_REPORT} from '../../../../constants/forms';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import SubmitSection from '../../../common/form/SubmitSection';
import InternationalDateTimeStatic from '../../../common/form/InternationalDateTimeStatic';
import {getToday} from '../../../../util/dateHelpers';

const SearchForm = (props) => {
  const {
    handleSubmit,
    pristine,
    reset,
    submitSearch,
  } = props;

  const submitSettings = {
    actionSettings: {
      reset: {
        action: reset,
        text: I18n.t('common.form.reset'),
        pristine
      },
      submit: {
        action: handleSubmit(submitSearch),
        text: I18n.t('common.actions.search'),
      }
    }
  };

  const registerStatusOptions = [
    {text: I18n.t('retail.cashOnHandReport.any'), value: -1},
    {text: I18n.t('retail.cashOnHandReport.active'), value: 1},
    {text: I18n.t('retail.cashOnHandReport.inactive'), value: 0}
  ];

  return (
    <form onSubmit={handleSubmit(submitSearch)}>
      <Row>
        <Col sm={6} md={4}>
          <label>{I18n.t('retail.inventoryReport.reportDateLabel')}</label>
          <br />
          <InternationalDateTimeStatic displayTime={false} useSystemDate={false} adjustForClientTimezone={false}>
            {getToday()}
          </InternationalDateTimeStatic>
        </Col>

        <Col sm={6} md={4}>
          <Field
            name='register_status'
            component={ReactSelectInput}
            props={{
              label: I18n.t('retail.transactionReport.registerStatus'),
              options: registerStatusOptions,
              clearable: false,
            }}
          />
        </Col>

        <div className='d-block d-sm-block d-none d-md-block d-none d-lg-block clearfix'></div>

        <Col xs={12}>
          <SubmitSection settings={submitSettings} />
        </Col>
      </Row>
    </form>
  );
};

SearchForm.propTypes = {
  handleSubmit:       PropTypes.func.isRequired,
  pristine:           PropTypes.func.isRequired,
  registerOptions:    PropTypes.array.isRequired,
  reset:              PropTypes.func.isRequired,
  submitSearch:       PropTypes.func.isRequired,
};


// SearchForm validation
const validate = (values) => {
  const errors = {};

  // require report_date
  if(!values.report_date){
    errors.report_date = I18n.t('common.form.isRequired',{
      fieldName: I18n.t('retail.transactionReport.reportDateLabel')
    });
  }

  if(!moment.isMoment(values.report_date)){
    errors.report_date = I18n.t('common.form.invalidFormat');
  } else {
    // require valid date format
    if(!values.report_date.isValid()){
      errors.report_date = I18n.t('common.form.invalidFormat');
    }

    // require past date
    if(+values.report_date.format('YYYYMMDD') > +moment().format('YYYYMMDD')){
      errors.report_date = I18n.t('retail.transactionReport.invalidReportDate');
    }
  }

  return errors;
};

// SearchForm store connection
const mapStateToProps = () => {
  return {
    reset: reset,
    initialValues: {
      report_date: moment(),
      register_status: -1,
    },
  };
};

const ReduxSearchForm = reduxForm({
  form: CASH_ON_HAND_REPORT,
  enableReinitialize: false,
  validate,
})(SearchForm);

const CashOnHandSearchForm = connect(mapStateToProps)(ReduxSearchForm);

CashOnHandSearchForm.propTypes = {
  submitSearch:       PropTypes.func.isRequired,
};

export default CashOnHandSearchForm;
