import React from 'react';
import {I18n} from 'react-redux-i18n';
import PermissionLink from '../../common/PermissionLink';
import * as p from '../../../constants/permissions';

const HistoryButton = (id) => (
  <PermissionLink
    permissions={[p.view_products]}
    props={{
      title: I18n.t('common.history'),
      to: `/products/${id}/history`,
      className: 'btn btn-default',
      target: '_blank',
      onClick: event => {
        // stop click on row
        event.stopPropagation();
      }
    }}>
    {I18n.t('common.history')}
  </PermissionLink>
);

export default HistoryButton;