import {createSelector} from 'reselect';
import get from 'lodash.get';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import {getLabelsCompliance} from '../labelsSelectors';

export const getPrintServers = createSelector([getLabelsCompliance], (settings) => {
  return get(settings, 'label_global_settings.value.print_servers', get(settings, 'defaults.label_global_settings.print_servers.default', []));
});

const getPrintServerId = (_, props) => props.printServerId;
const getLocalPrinters = (state) => state[dataNames.localPrinters];
const getLocalPrintServer = (state) => state[itemNames.localPrintServer];


export const getPrintServerInitialValues = createSelector([getPrintServers, getPrintServerId, getLocalPrinters, getLocalPrintServer], (printServers, printServerId, localPrinters, localPrintServer) => {
  const defaultServer = {printers: [], active: 0};
  if(!Array.isArray(printServers)) return defaultServer;
  const server = printServers.find((server) => server.id === parseInt(printServerId));
  const isLocalPrintServer = localPrintServer && localPrintServer.id && parseInt(localPrintServer.id) === parseInt(printServerId);
  if(server){
    server.printers = server.printers.map((printer) => {
      const haveLocalPrinter = !isLocalPrintServer ? undefined : localPrinters.find((printerName) => printerName === printer.name);
      printer.present = haveLocalPrinter !== undefined;
      printer.is_local = isLocalPrintServer;
      return printer;
    });
  }
  return server ? server : defaultServer;
});

export const getPrintServerPayload = (values, settings, destroy = false) => {
  // To support existing post shapes send all existing global values plus new printer settings
  const globalKey = 'label_global_settings';
  const fields = Object.keys(settings.defaults[globalKey]);
  const template = {
    settings: [
      {
        setting_key: globalKey,
        scope: 'facility',
        target: 0,
        value: {},
      }
    ]
  };
  fields.forEach((field) => {
    const source = get(settings, `${globalKey}.value.${field}`, get(settings, `defaults.${globalKey}.${field}.default`));
    if(Array.isArray(source)){
      template.settings[0].value[field] = source.map((item) => item);
    } else {
      template.settings[0].value[field] = (typeof source === 'object') ? Object.assign({}, source) : source;
    }
  });
  if(values.printers && Array.isArray(values.printers)){
    values.printers = values.printers.map((printer) => {
      delete(printer.present);
      delete(printer.saved);
      return printer;
    });
  }
  if(values.id){
    const index = template.settings[0].value.print_servers.findIndex((server) => server.id === values.id);
    if(destroy){
      template.settings[0].value.print_servers = template.settings[0].value.print_servers.filter((server, serverIndex) => serverIndex !== index);
    } else {
      template.settings[0].value.print_servers[index] = values;
    }
  } else {
    values.id = template.settings[0].value.print_servers.reduce((acc, server) => {
      if(parseInt(server.id) > acc) acc = server.id;
      return acc;
    }, 0) + 1;
    template.settings[0].value.print_servers.push(values);
  }
  return template;
};
