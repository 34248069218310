import React from 'react';
import {connect} from 'react-redux';
import { Field, FieldArray} from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

import TextInput from '../common/form/TextInput';
import PhoneNumberRow from './PhoneNumberRow';
import {getStateLabel} from '../../util/labelHelpers';
import PostalCodeField from '../common/form/redux-form/PostalCodeField';
import {isMetrcDelivery} from '../../selectors/integration/metrcSelectors';

const CompanyInfo = ({onfleetIsEnabled, isMetrcDelivery, countryCode}) => {
  return (
    <Row>
      <Col sm={8} xs={8} md={8}>
        <Row>
          <Col sm={12} xs={12} md={12} className='driver-form-header'>
            <p>{I18n.t('cultivation.drivers.form.companyInformation')}</p>
          </Col>
        </Row>
        <Row>
          <Col sm={8} xs={8} md={5}>
            <Field
              name='name'
              component={TextInput}
              props={{
                label: I18n.t('cultivation.drivers.form.name'),
                isRequired: true
              }}
            />
          </Col>
          <Col sm={8} xs={8} md={4}>
            <Field
              name='state_compliance_number'
              component={TextInput}
              props={{
                label: I18n.t('cultivation.drivers.form.stateLicense'),
                isRequired: true
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={8} xs={8} md={6}>
            <Field
              name='street_address_1'
              component={TextInput}
              props={{
                label: I18n.t('cultivation.drivers.form.address')
              }}
            />
          </Col>
          <Col sm={8} xs={8} md={4}>
            <Field
              name='city'
              component={TextInput}
              props={{
                label: I18n.t('cultivation.drivers.form.city')
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={4} xs={4} md={1}>
            <Field
              name='province_code'
              component={TextInput}
              props={{
                label: I18n.t(getStateLabel(countryCode))
              }}
            />
          </Col>
          <Col sm={4} xs={4} md={2}>
            <PostalCodeField name='postal_code'/>
          </Col>
          <Col sm={4} xs={4} md={4}>
            <FieldArray
              name='driver_phones'
              component={PhoneNumberRow}
              props={{
                isRequired: isMetrcDelivery || onfleetIsEnabled,
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

CompanyInfo.propTypes = {
  countryCode: PropTypes.string,
  onfleetIsEnabled: PropTypes.bool,
  isMetrcDelivery: PropTypes.bool
};

export default connect(
  (state) => ({
    isMetrcDelivery: isMetrcDelivery(state),
  })
)(CompanyInfo);
