import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import get from 'lodash.get';
import ReactSelectInput from '../common/form/ReactSelectInput';
import InternationalQuantityByUomInput  from '../common/form/InternationalQuantityByUomInput';
import {getItemMasterType } from '../../selectors/itemMastersSelectors';
import * as itemTypes from '../../constants/itemTypes';


class AddItemRow extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onItemIdChange = this.onItemIdChange.bind(this);
    this.getInventoryItemsOptions = this.getInventoryItemsOptions.bind(this);
    this.onPrePackWeightIdChange = this.onPrePackWeightIdChange.bind(this);
  }

  getPrePackOptions() {
    if (this.getItemMasterType() === itemTypes.prepack) {
      return this.props.prepackWeights;
    }

    return [{
      id: '',
      name: get(this.props.itemMaster, 'default_uom', ''),
    }];
  }

  getInventoryItemsOptions () {
    const {inventoryItems} = this.props;
    if (!inventoryItems.length) {
      return [];
    }

    return inventoryItems.map(inventoryItem => {
      inventoryItem.name = inventoryItem.packageName;
      if (inventoryItem.is_prepack) {
        inventoryItem.name = `${inventoryItem.prepack_name} - ${inventoryItem.packageName}`;
      }
      return inventoryItem;
    });
  }

  getItemMasterType() {
    return getItemMasterType({
      itemMaster: this.props.itemMaster
    }) || '';
  }

  /**
   * The handle when package item is changed
   * @param value
   * @returns {boolean}
   */
  onItemIdChange(value) {
    const { change, fieldName, inventoryItems } = this.props;
    change(`${fieldName}.item_id`, value);

    if (!value) {
      change(`${fieldName}.prepack_weight_id`, '');
      change(`${fieldName}.qty_available`, '');

      return false;
    }

    const item = inventoryItems && inventoryItems.find(inventoryItem => inventoryItem.id == value);
    if (item) {
      change(`${fieldName}.prepack_weight_id`, item.prepack_weight_id);
      change(`${fieldName}.qty_available`, item.qty_available);
    }
  }

  /**
   * The handle when pre pack  item is changed
   * @param value
   * @returns {boolean}
   */
  onPrePackWeightIdChange(value) {
    const { change, fieldName, inventoryItems } = this.props;
    change(`${fieldName}.item_id`, value);
    change(`${fieldName}.prepack_weight_id`, value);

    if (!value) {
      change(`${fieldName}.item_id`, '');

      return false;
    }

    const item = inventoryItems && inventoryItems.find(inventoryItem => inventoryItem.prepack_weight_id == value);
    if (item) {
      change(`${fieldName}.item_id`, item.id);
    }
  }


  render() {
    const { fieldName, inventoryItems, salesLocations, disablingAddInventory } = this.props;
    return (
      <tr className='add-item-row'>
        <td colSpan={2}>
          <Field
            name={`${fieldName}.location`}
            component={ReactSelectInput}
            props={{
              textKey: 'name',
              options: salesLocations.sort((sl1, sl2) => sl1.name.toUpperCase().localeCompare(sl2.name.toUpperCase())),
              valueKey: 'id',
              disabled: disablingAddInventory,
              placeholder: I18n.t('cart.form.selectAlternateLocation')
            }}/>
        </td>
        <td>
          {(!inventoryItems.length)
            ? <span>{I18n.t('cart.items.unavailableLocation')}</span>
            : (
              <Field
                name={`${fieldName}.item_id`}
                component={ReactSelectInput}
                props={{
                  options: this.getInventoryItemsOptions(),
                  textKey: 'name',
                  valueKey: 'id',
                  disabled: disablingAddInventory,
                  onChange: this.onItemIdChange,
                  className: 'package-id-input',
                }}
              />
            )}
        </td>
        <td className='pricing-weight'>
          {(inventoryItems.length) && (
            <Field
              name={`${fieldName}.prepack_weight_id`}
              component={ReactSelectInput}
              props={{
                disabled: true,
                options: this.getPrePackOptions(),
                textKey: 'name',
                valueKey: 'id',
                onChange: this.onPrePackWeightIdChange,
              }}
            />
          )}
        </td>
        <td></td>
        <td>{(inventoryItems.length) ? (
          <InternationalQuantityByUomInput
            name={`${this.props.fieldName}.qty`}
            uom={['prepack','unit'].indexOf(this.props.product.product_type) >= 0 ? 'EA' : 'GR'}
            allowNegativeNumber={false}
            className={'quantity-input'}
            hideUom={true}
          />)
        : null
        }</td>
        <td></td>
        <td></td>
      </tr>
    );
  }
}

AddItemRow.propTypes = {
  prepackWeights: PropTypes.array.isRequired,
  inventoryItems: PropTypes.array.isRequired,
  fieldName: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  itemMaster: PropTypes.object.isRequired,
  salesLocations: PropTypes.array.isRequired,
  overrideLocation: PropTypes.number,
  currentLocation: PropTypes.number,
  disablingAddInventory: PropTypes.bool
};

export default AddItemRow;
