export function normalizeCreditCardNumber(value, prevValue) {
  if (!value) {
    return value;
  }
  value = String(value).replace(/\s+/g, '');
  if (/^\d+$/.test(value)) {
    return value;
  }
  return prevValue;
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return '';
  }
  return String(value)
    .split('')
    .reduce(
      (chunks, char, index) => {
        const chunkIndex = Math.floor(index / 4);
        if (!chunks[chunkIndex]) {
          chunks[chunkIndex] = '';
        }
        chunks[chunkIndex] += char;
        return chunks;
      },
      []
    )
    .join(' ');
}

export function normalizeMonth(value, prevValue) {
  if (!value) {
    return value;
  }
  if (/^\d+$/.test(value)) {
    const number = Number(value);
    if (number === 0 && String(value).length > 1) {
      return prevValue;
    }
    return number >= 0 && number <= 12 ? value : prevValue;
  }
  return prevValue;
}

export function normalizeYear(value, prevValue) {
  if (!value) {
    return value;
  }
  if (String(value).length <= 4 && /^\d+$/.test(value)) {
    const number = Number(value);
    const maxYear = new Date().getFullYear() + 200;
    if (number === 0 && String(value).length > 1) {
      return prevValue;
    }
    return number >= 0 && number <= maxYear ? value : prevValue;
  }
  return prevValue;
}

export function parseCreditCardDetails(data) {
  const pattern = /^%B(\d+)\^(\w+)\/(\w+)\^\d+\?;\d+=(\d\d)(\d\d)\d+/;
  if (pattern.test(data)) {
    const matches = pattern.exec(data);
    return {
      card_number: matches[1],
      first_name: matches[3],
      last_name: matches[2],
      exp_month: matches[5],
      exp_year: '20' + matches[4],
    };
  }
  return null;
}
