import React from 'react';
import PropTypes from 'prop-types';
import {reduxForm, Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Row, Col, Button} from 'react-bootstrap';

import {IMPORT_ORDERS_PAGE_FILTER_FORM} from '../../../constants/forms';
import InternationalDateTimePickerInput from '../../common/form/InternationalDateTimePickerInput';
import ReactSelectInput from '../../common/form/ReactSelectInput';

const ImportOrdersListPageForm = (props) => {
  const {submitting, handleSubmit} = props;
  const statuses = [
    // {name: I18n.t('ordersImport.tableFilter.statuses.inProgress'), value: 'pending'},
    {name: I18n.t('ordersImport.tableFilter.statuses.previewAvailable'), value: 'preview_available'},
    {name: I18n.t('ordersImport.tableFilter.statuses.canceled'), value: 'canceled'},
    {name: I18n.t('ordersImport.tableFilter.statuses.completed'), value: 'completed'},
    {name: I18n.t('ordersImport.tableFilter.statuses.failed'), value: 'failed'},
  ];

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col md={3}>
            <Field
              name='status'
              component={ReactSelectInput}
              props={{
                label: I18n.t('ordersImport.tableFilter.status'),
                options: statuses,
                textKey: 'name',
                valueKey: 'value'
              }}
            />
          </Col>
          <Col md={3}>
            <InternationalDateTimePickerInput
              name='created_at'
              props={{
                label: I18n.t('ordersImport.tableFilter.date'),
              }}
            />
          </Col>
          <Col md={2}>
            <label>&nbsp;</label>
            <div className='input-group'>
              <Button type='submit' disabled={submitting} variant='primary'>
                {I18n.t('ordersImport.tableFilter.showHistory')}
              </Button>
            </div>
          </Col>
        </Row>
        <hr/>
      </form>
    </div>
  );
};

ImportOrdersListPageForm.propTypes = {
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: IMPORT_ORDERS_PAGE_FILTER_FORM,
})(ImportOrdersListPageForm);
