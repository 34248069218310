import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { I18n } from 'react-redux-i18n';
import { Button } from 'react-bootstrap';
import get from 'lodash.get';
import moment from 'moment-timezone';

import { getItem, getUnpaginatedData } from '../../../actions/apiActions';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import {getHarvest, getHarvestExternalId} from '../../../selectors/harvests/harvestsSelectors';
import {
  getHarvestBatchHistoryTableData,
  isPackagedEventForNonWaste
} from '../../../selectors/harvests/harvestBatchHistorySelectors';
import { getStrain } from '../../../selectors/strainsSelectors';
import ModalWrapper from '../../common/ModalWrapper';
import TablePageWrapper from '../../common/grid/TablePageWrapper';
import InProgressOverlay from '../../common/InProgressOverlay';
import HarvestBatchCreatedDetails from './details/HarvestBatchCreatedDetails';
import HarvestBatchReactivatedDetails from './details/HarvestBatchReactivatedDetails';
import HarvestBatchUpdatedDetails from './details/HarvestBatchUpdatedDetails';
import HarvestBatchPlantsAddedDetails from './details/HarvestBatchPlantsAddedDetails';
import HarvestBatchPackagedDetails from './details/HarvestBatchPackagedDetails';
import HarvestBatchWasteReportedDetails from './details/HarvestBatchWasteReportedDetails';
import PlantHistoryNotes from '../../plants/history/PlantHistoryNotes';
import HarvestBatchQualityRatingDetails from './details/HarvestBatchQualityRatingDetails';
import HarvestBatchSplit from './details/HarvestBatchSplit';
import {getIntegrationState} from '../../../selectors/integration/integrationSelectors';
import {getTranslationLabel} from '../../../util/translationHelpers';
import DisplayQty from '../../common/DisplayQty';

const getLabel = getTranslationLabel('harvest.');


export class HarvestBatchHistoryPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      ready: false,
      showNotesModal: false,
      displayedNotes: []
    };

    this.getColumns = this.getColumns.bind(this);
    this.expandableRow = this.expandableRow.bind(this);
    this.openNotesModal = this.openNotesModal.bind(this);
    this.expandComponent = this.expandComponent.bind(this);
  }

  componentWillMount() {
    const id = this.props.params.id || '';
    const promises = [
      this.props.actions.getItem(`/api/harvests/${id}`, itemNames.harvest, { failed: I18n.t('plants.getPlant.failed') }, {},
        (harvestBatch) => {
          if (harvestBatch.strain_id) {
            this.props.actions.getItem(`/api/strains/${harvestBatch.strain_id}`, itemNames.strain, {failed: I18n.t('strains.getStrain.failed')});
          }
        }
      ),
      this.props.actions.getUnpaginatedData(`/api/harvests/${id}/log`, dataNames.harvestBatchHistory, { failed: I18n.t('harvests.getHistory.failed') }),
      this.props.actions.getUnpaginatedData('/api/users/current_facility', dataNames.currentFacilityUsers),
    ];
    if (this.props.integrationState.isLeaf || this.props.integrationState.isMetrc) {
      promises.push(this.props.actions.getItem(`/api/integration-mapping/harvest_batches/${id}`, itemNames.harvestBatchMapping));
    }
    Promise.all(promises)
      .then(() => this.setState({ready: true}))
      .catch(() => this.setState({ready: true}))
    ;
  }

  openNotesModal(event, displayedNotes) {

    event.stopPropagation();
    this.setState({ showNotesModal: true, displayedNotes });
  }

  expandableRow(row) {
    return Boolean(row.model && row.model.id && row.event_type !== 'batch_deactivated');
  }

  expandComponent(row) {
    const {integrationState} = this.props;
    switch (row.event_type) {
    case 'created':
      return <HarvestBatchCreatedDetails model={row.model} integrationState={integrationState}/>;
    case 'quality_rating':
      return <HarvestBatchQualityRatingDetails model={row.model} integrationState={integrationState}/>;
    case 'batch_reactivated':
      return <HarvestBatchReactivatedDetails model={row.model} integrationState={integrationState}/>;
    case 'batch_updated':
      return (
        <div>
          <HarvestBatchUpdatedDetails model={row.model} delta={row.delta} integrationState={integrationState}/>
          {isPackagedEventForNonWaste(row, row.model) ? <HarvestBatchPackagedDetails model={row.model} integrationState={integrationState}/> : null}
        </div>
      );
    case 'batch_waste_reported':
      return <HarvestBatchWasteReportedDetails model={row.model} delta={row.delta} integrationState={integrationState}/>;
    case 'batch_plants_added':
      return <HarvestBatchPlantsAddedDetails model={row.model} delta={row.delta} integrationState={integrationState}/>;
    case 'batch_split':
      return <HarvestBatchSplit model={row.model} />;
    default:
      return null;
    }
  }

  getColumns() {
    const { integrationState } = this.props;
    const waLevelColumns = integrationState.isWaLeaf ? [
      {
        name: getLabel('startingLevelOtherMaterial', integrationState),
        dataId: 'starting_level_other_material',
        width: '80px',
      },
      {
        name: getLabel('newLevelOtherMaterial', integrationState),
        dataId: 'new_level_other_material',
        width: '80px',
      },
    ] : [];

    return [
      {
        name: 'common.action',
        dataId: 'message',
        width: '120px',
        dataAlign: 'left',
      },
      {
        name: 'common.employee',
        dataId: 'user_name',
        width: '90px'
      },
      {
        name: 'common.actionDate',
        dataId: 'eventDateSort',
        width: '70px',
        formatter: (cell, row) => {
          const actionDate = (
            get(row, 'model.data.action_date') ||
            get(row, 'model.data.event_date') ||
            get(row, 'model.data.reactivated_at') ||
            get(row, 'model.data.completed_at.date') ||
            get(row, 'event_date')
          );

          return actionDate ? moment(actionDate).format('MM/DD/YYYY') : '—';
        },
      },
      {
        name: 'common.entryDate',
        dataId: 'created_at',
        width: '70px',
        sortBy: 'created_at_sort',
      },
      {
        name: getLabel('startingLevel', integrationState),
        dataId: 'starting_level',
        width: '80px',
        /* eslint-disable react/no-multi-comp */
        formatter: (cell, row) => {
          const uom_display = get(row, 'model.uom_display');
          return <DisplayQty qty={row.starting_level} uom={uom_display} displayUom={true} />;
        }
      },
      {
        name: getLabel('newLevel', integrationState),
        dataId: 'new_level',
        width: '80px',
        /* eslint-disable react/no-multi-comp */
        formatter: (cell, row) => {
          const uom_display = get(row, 'model.uom_display');
          return <DisplayQty qty={row.new_level} uom={uom_display} displayUom={true} />;
        }
      },
      ...waLevelColumns,
      {
        name: 'common.notes',
        dataId: 'notes',
        /* eslint-disable react/no-multi-comp */
        formatter: (cell, row) => {
          return (
            <Button variant='primary' disabled={!(row.notes && row.notes.length)} bsSize='xsmall'
                    onClick={event => this.openNotesModal(event, row.notes)}>
              {I18n.t('customers.table.view')}
            </Button>
          );
        },
        width: '50px',
        dataSort: false,
      },
    ];
  }

  render() {
    const { harvestBatch, strain, history, externalId } = this.props;

    return (
      <div className='harvest-history-page'>
        {this.state.ready ? '' : <InProgressOverlay isActive={true} />}
        <ModalWrapper
          Component={PlantHistoryNotes}
          onHide={() => this.setState({ showNotesModal: false })}
          showModal={this.state.showNotesModal}
          title='history.notes'
          notes={this.state.displayedNotes}
        />
        <div className='harvest-history-page-harvest-info'>
          <p>{I18n.t('harvests.batchName')}: {harvestBatch.batch_name || ''}</p>
          <p>{I18n.t('harvests.strainName')}: {strain.strain_name || ''}</p>
          {externalId ? <p>{I18n.t('harvestPackages.form.trackingId')}: {externalId}</p> : null}
        </div>
        <TablePageWrapper
          ref={this.ref}
          settingKey='harvests-history'
          columns={this.getColumns()}
          data={history}
          selectedRows={[]}
          handleSelect={() => {}}
          bstProps={{
            selectRow: {
              clickToSelect: false,
              hideSelectColumn: true,
              clickToExpand: true,
            },
            expandableRow: this.expandableRow,
            expandComponent: this.expandComponent,
            options : {
              defaultSortName: 'eventDateSort',
              defaultSortOrder: 'desc',
            },
          }}
        />
      </div>
    );
  }
}

HarvestBatchHistoryPage.propTypes = {
  harvestBatch: PropTypes.object.isRequired,
  externalId: PropTypes.string,
  strain: PropTypes.object.isRequired,
  history: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    getItem: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
  }).isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  integrationState: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    harvestBatch: getHarvest(state),
    externalId: getHarvestExternalId(state),
    strain: getStrain(state),
    history: getHarvestBatchHistoryTableData(state),
    integrationState: getIntegrationState(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, { getItem, getUnpaginatedData });
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HarvestBatchHistoryPage);
