import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Table} from 'react-bootstrap';
import get from 'lodash.get';
import moment from 'moment';
import ContentConcealer from '../../../common/concealers/ContentConcealer';
import DisplayQty from '../../../common/DisplayQty';
import InternationalDateStatic from '../../../common/form/InternationalDateStatic';

const ItemDetailsTable = ({item}) => {
  const sourceType = item && item.package && item.package.lot && item.package.lot.source_type;
  const lotNumber = item && item.package && item.package.lot && item.package.lot.lot_number;
  return (
    <Table striped className='item-details'>
      <thead>
      <tr>
        <th>{I18n.t('itemTransactions.field')}</th>
        <th>{I18n.t('itemTransactions.value')}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>{I18n.t('itemTransactions.item.product')}</td>
        <td>{item.item_name}</td>
      </tr>
      <ContentConcealer show={Boolean(item.package_code)}>
        <tr>
          <td>{I18n.t('itemTransactions.item.packageId')}</td>
          <td>{item.package_code}</td>
        </tr>
      </ContentConcealer>
      <ContentConcealer show={Boolean(item.lot_number)}>
        <tr>
          <td>{I18n.t('itemTransactions.item.lotId')}</td>
          <td>{item.lot_number}</td>
        </tr>
      </ContentConcealer>
      <ContentConcealer show={Boolean(item.parent_item_id)}>
        <tr>
          <td>{I18n.t('itemTransactions.item.parentItemId')}</td>
          <td>{item.parent_item_id}</td>
        </tr>
      </ContentConcealer>
      <ContentConcealer show={Boolean(item.is_test_package)}>
        <tr>
          <td>{I18n.t('itemTransactions.item.isTestPackage')}</td>
          <td>{I18n.t('cultivation.complianceSettings.yes')}</td>
        </tr>
      </ContentConcealer>
      <tr>
        <td>{I18n.t('itemTransactions.item.location')}</td>
        <td>{item.location_name}</td>
      </tr>
      <tr>
        <td>{I18n.t('itemTransactions.item.qty')}</td>
        <td><DisplayQty qty={item.qty} uom={item.uom} displayUom={true} /></td>
      </tr>
      <ContentConcealer show={item.phase}>
        <tr>
          <td>{I18n.t('itemTransactions.item.phase')}</td>
          <td>{item.phase && item.phase.name}</td>
        </tr>
      </ContentConcealer>
      <ContentConcealer show={Boolean(sourceType && lotNumber && sourceType !== 'external' && sourceType !== 'live_waste')}>
        <tr>
          <td>{I18n.t('itemTransactions.item.source')}</td>
          <td>{I18n.t(`itemTransactions.item.sources.${sourceType}`)} {lotNumber}</td>
        </tr>
      </ContentConcealer>
      <ContentConcealer show={Boolean(sourceType && lotNumber && sourceType === 'live_waste')}>
        <tr>
          <td>{I18n.t('itemTransactions.item.source')}</td>
          <td>{I18n.t('common.cultivationLocation')}: {item.wasteSource}</td>
        </tr>
      </ContentConcealer>
      <ContentConcealer show={Boolean(item.state_integration_tracking_id || item.external_id || get(item, 'current_package.state_integration_tracking_id', false))}>
        <tr>
          <td>{I18n.t('itemTransactions.item.trackingId')}</td>
          <td>{item.state_integration_tracking_id || item.external_id || get(item, 'current_package.state_integration_tracking_id', '')}</td>
        </tr>
      </ContentConcealer>
      <ContentConcealer show={Boolean(get(item, 'current_package.source_tracking_ids', ''))}>
        <tr>
          <td>{I18n.t('itemTransactions.item.sourceTrackingId')}</td>
            <td title={get(item, 'current_package.source_tracking_ids', '')}>{get(item, 'current_package.source_tracking_ids', '')}</td>
        </tr>
      </ContentConcealer>
      <ContentConcealer show={Boolean(item.partner_name)}>
        <tr>
          <td>{I18n.t('itemTransactions.item.vendor')}</td>
          <td>{item.partner_name}</td>
        </tr>
      </ContentConcealer>
      <ContentConcealer show={Boolean(item.packaged_at)}>
        <tr>
          <td>{I18n.t('itemTransactions.item.packagedAt')}</td>
          <td><InternationalDateStatic>{moment(item.packaged_at)}</InternationalDateStatic></td>
        </tr>
      </ContentConcealer>
      <ContentConcealer show={Boolean(item.package_created_at)}>
        <tr>
          <td>{I18n.t('itemTransactions.item.packageCreatedAt')}</td>
          <td><InternationalDateStatic>{moment(item.package_created_at)}</InternationalDateStatic></td>
        </tr>
      </ContentConcealer>
      <ContentConcealer show={Boolean(item.package_expires_at)}>
        <tr>
          <td>{I18n.t('itemTransactions.item.packageExpiresAt')}</td>
          <td><InternationalDateStatic>{moment(item.package_expires_at)}</InternationalDateStatic></td>
        </tr>
      </ContentConcealer>
      <ContentConcealer show={Boolean(item.purpose_name)}>
        <tr>
          <td>{I18n.t('itemTransactions.item.purpose')}</td>
          <td>{item.purpose_name}</td>
        </tr>
      </ContentConcealer>
      </tbody>
    </Table>
  );
};

ItemDetailsTable.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ItemDetailsTable;
