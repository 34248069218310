import {reduxForm} from 'redux-form';
import IngredientAdjustForm from './IngredientAdjustForm';
import {validateIngredientsAdjustFrom as validate} from './validate';

const IngredientAdjustFormWrapper = reduxForm({
  validate,
  enableReinitialize: true
})(IngredientAdjustForm);

export default IngredientAdjustFormWrapper;
