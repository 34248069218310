import React from 'react';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import NumberInput from '../common/form/NumericInput';

class AdditionalEquivalencySettings extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.renderRestrictSalesGrams = this.renderRestrictSalesGrams.bind(this);
    this.renderRestrictForTerminalIllness = this.renderRestrictForTerminalIllness.bind(this);
  }

  renderRow(label, component) {
    return (
      <tr>
        <td colSpan={2} style={{paddingRight: '8px'}}>{label}</td>
        <td style={{width: '150px'}}>{component}</td>
      </tr>
    );
  }

  renderRestrictForTerminalIllness() {
    const {patientType} = this.props;
    const label = "Restrict sales to patients marked as 'Terminal Illness' to how many grams of Plant Material Tier I product or its equivalent";
    const component = (
      <Field
        name={`${patientType}_terminal_illness_limits`}
        component={NumberInput}
        props={{
          placeholder: '',
          type: 'decimal',
          fractionPartSize: 3
        }}
      />
    );

    return this.renderRow(label, component);
  }

  renderRestrictSalesGrams() {
    const {patientType, integrationState: {isOhMetrc}} = this.props;
    const label = isOhMetrc
      ? I18n.t('retail.salesSettings.restrictSalesMetrcOH')
      : I18n.t('retail.salesSettings.restrictSales');
    const component = (
      <Field
        name={`${patientType}_restrictSalesGrams`}
        component={NumberInput}
        props={{
          placeholder: '',
          type: 'decimal',
          fractionPartSize: 3
        }}
      />
    );

    return this.renderRow(label, component);
  }

  render() {
    const {disableResetPatientSalesLimit, integrationState: {isBiotrack, isOhMetrc}} = this.props;

    return (
      <table className='table table-striped'>
        <tbody>
          {!(isBiotrack || disableResetPatientSalesLimit) && this.renderRestrictSalesGrams()}
          {(isOhMetrc) && this.renderRestrictForTerminalIllness()}
        </tbody>
      </table>
    );
  }
}

export default AdditionalEquivalencySettings;
