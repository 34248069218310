import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import {Col, Row,} from 'react-bootstrap';

import FieldArrayIncrement from '../../../common/form/FieldArrayIncrement';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import InternationalCurrencyInput from '../../../common/form/InternationalCurrencyInput';
import WillRender from '../../../common/concealers/WillRender';

const VendorsFieldArray = ({partners, sales_uom, fields}) => {
  // Always show at least one field
  if (fields.length === 0) {
    fields.push({});
  }

  const uomLabel = sales_uom ? I18n.t(`uoms.${sales_uom}.word`) : '<UOM>';

  return (
    <div className='vendors'>
      {fields.map((vendor, index) => (
        <Row key={index}>
          <Col sm={4}>
            <Field name={`${vendor}.partner_id`} component={ReactSelectInput} props={{
              label: index == 0 ? I18n.t('products.form.productPrimaryPartner') : I18n.t('products.form.productAdditionalPartner'),
              options: partners,
              textKey: 'name',
              valueKey: 'id',
              isRequired: index === 0 // Always need to select a primary partner (if item_master is produced, then should select producing facility)
            }}/>
          </Col>
          <Col sm={4}>
            <InternationalCurrencyInput
              name={`${vendor}.default_unit_cost`}
              props={{
                fractionPartSize: 2,
                placeholder: '0.00',
                label: I18n.t('products.form.costPer') + ' ' + uomLabel,
              }}/>
          </Col>
          <Col sm={4}>
            <WillRender ifTrue={index == 0}>
              <br />
              <br />
              <b>*{I18n.t('products.form.usedToCalculateMargin')}</b>
            </WillRender>
          </Col>
        </Row>
      ))}
      <FieldArrayIncrement fields={fields} showDecrement={true} minLength={1} label='products.form.addVendor' className='float-right'/>
    </div>
  );
};

VendorsFieldArray.propTypes = {
  partners: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  sales_uom: PropTypes.string,
};

export default VendorsFieldArray;
