import React from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {FaAngleDown, FaAngleRight} from 'react-icons/fa';
import PageTitle from '../../common/PageTitle';
import TablePageWrapper from '../../common/grid/TablePageWrapper';
import * as itemNames from '../../../constants/itemNames';
import * as apiActions from '../../../actions/apiActions';
import {getOrderImportItem} from '../../../selectors/orderSelectors';
import ModalWrapper from '../../common/ModalWrapper';
import defaultColumns from './ImportOrdersViewTableColumns';
import ImportOrdersViewTableOrderInfo from './ImportOrdersViewTableOrderInfo';
import {getIntegrationState} from '../../../selectors/integration/integrationSelectors';

export class ImportOrdersViewTable extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.expandComponent = this.expandComponent.bind(this);

    const expandedRows = [];
    const isExpanded = id => expandedRows.find(i => i === id);
    this.expandHandler = id => isExpanded(id) ? expandedRows.filter(i => i !== id) : expandedRows.push(id);

    this.getActions = this.getActions.bind(this);
    this.expandHandler = this.expandHandler.bind(this);
    this.cancelOrders = this.cancelOrders.bind(this);
    this.completeOrders = this.completeOrders.bind(this);
    this.hideConfirmationModal = this.hideConfirmationModal.bind(this);
    this.onConfirmationModalShow = this.onConfirmationModalShow.bind(this);

    const expandColumn = {
      width: '30px',
      dataSort: false,
      formatter: (cell) => {
        return isExpanded(cell)
          ? (<FaAngleDown/>)
          : (<FaAngleRight/>);
      },
      dataId: 'id',
      searchable: false,
    };

    const columns = [expandColumn, ...defaultColumns];

    this.state = {
      status: null,
      showConfirmationModal: false,
      submitting: false,
      completeSuccess: false,
      completeError: false,
      cancelSuccess: false,
      columns,
    };
  }

  componentWillMount() {
    this.props.actions.getItem(`/api/orders/import/${this.props.params.id}`, itemNames.orderImportItem)
      .then(() =>
        this.setState({status: this.props.status})
      );
  }

  onConfirmationModalShow() {
    this.setState({ showConfirmationModal: true });
  }

  hideConfirmationModal() {
    this.setState({ showConfirmationModal: false });
  }

  expandComponent(row) {
    return (
      <ImportOrdersViewTableOrderInfo
        collapseSelf={this.expandHandler}
        order={row}
      />
    );
  }

  completeOrders() {
    const formData = {
      action: 'complete',
    };

    this.props.actions.putItem(`/api/orders/import/${this.props.params.id}`, formData)
      .then(() => {
        this.setState({
          submitting: false,
          completeSuccess: true,
          status: 'imported',
        });
      })
      .catch(() => {
        this.setState({
          submitting: false,
          completeError: true,
        });
      });

    this.setState({
      submitting: true,
      completeSuccess: false,
      completeError: false,
    });
  }

  cancelOrders() {
    this.hideConfirmationModal();

    this.props.actions.postItem(`/api/orders/import/${this.props.params.id}/cancel`)
      .then(() => {
        this.setState({
          submitting: false,
          completeSuccess: true,
          status: 'canceled',
        });
      })
      .catch(() => {
        this.setState({
          submitting: false,
          completeError: true,
        });
      });

    this.setState({
      submitting: true,
      completeSuccess: false,
      completeError: false,
    });
  }

  getActions() {
    const buttons = [
      {
        id: 'completeOrders',
        func: this.completeOrders,
        text: 'ordersImport.viewTable.completeOrders',
        disabled: this.state.status !== 'preview_available' || this.state.submitting
      },
    ];

    if (this.props.integrationState.isNormal || this.props.integrationState.isPaLeaf) {
      buttons.push({
        id: 'cancelOrders',
        func: this.onConfirmationModalShow,
        text: 'salesOrders.form.cancelOrder',
        variant:'danger',
        disabled: this.state.status !== 'imported' || this.state.submitting,
      });
    }

    return buttons;
  }

  render() {
    const { showConfirmationModal } = this.state;

    return (
      <div>
        <PageTitle primaryText={I18n.t('ordersImport.viewTable.pageTitle')}/>

        {this.state.submitting ? (<div className='alert alert-info'>{I18n.t('ordersImport.viewTable.orderDataSubmitting')}</div>) : ''}
        {this.state.completeSuccess ? (<div className='alert alert-success'>{I18n.t('ordersImport.viewTable.ordersCompletedSuccess')}</div>) : ''}
        {this.state.completeError ? (<div className='alert alert-danger'>{I18n.t('ordersImport.viewTable.ordersCompletedError')}</div>) : ''}
        <div>{I18n.t('ordersImport.viewTable.noticeMessage')}</div>
        <TablePageWrapper
          ref={this.ref}
          actions={this.getActions()}
          hideScanSearch={true}
          settingKey='order_import_details'
          columns = {this.state.columns}
          data = {this.props.items}
          className = 'orders-import-details'
          hideExport = {true}
          pageSizeList={[5, 10, 20 ,50]}
          bstProps={{
            options: {
              sizePerPage: 10,
              onRowClick: this.expandHandler,
              expandRowBgColor: 'rgb(255, 255, 255)',
            },
            selectRow: {},
            expandableRow: () => true,
            expandComponent: this.expandComponent,
          }}
        />
          <ModalWrapper
              Component={false}
              title='confirmCancelImportOrders'
              headerClass='bg-info-dark'
              onHide={this.hideConfirmationModal}
              showModal={showConfirmationModal}
              okayButton={{show: true, onClick: this.cancelOrders, text: 'customers.history.refundRestock.okayText'}}
              cancelButton={{show: true, onClick: this.hideConfirmationModal, text: 'customers.history.refundRestock.cancelText'}}
              dialogClassName='modal-sm'
              version={2}
          >
              <p>{I18n.t('cart.partialPaymentCancelConfirm.header')}</p>
          </ModalWrapper>
      </div>
    );
  }
}

ImportOrdersViewTable.propTypes = {
  actions: PropTypes.shape({
    push: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
  }),
  orderImportItem: PropTypes.array,
  integrationState: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    ...(getOrderImportItem(state) || {items: [], status: null}),
    integrationState: getIntegrationState(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions);
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ImportOrdersViewTable);
