import * as itemNames from '../../constants/itemNames';
import * as apiActions from '../apiActions';

/**
 * Returning a list of consumer limits
 * @param consumerId
 * @returns {function(*): *}
 */
export const getConsumerLimits = (consumerId) => (dispatch) => {
  return dispatch(apiActions.getItem(`/api/consumer_orders/${consumerId}/limits`, itemNames.customerLimits));
};

// For Eslint purposes. Remove it when the file will contain at least 2 methods.
export default {
  getConsumerLimits,
};