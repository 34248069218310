/* eslint-disable import/no-named-as-default*/
import {connect} from 'react-redux';
import {reduxForm, formValueSelector, getFormSyncErrors} from 'redux-form';
import validate from './validate';
import CreateLocationForm from '../common/locations/create/CreateLocationForm';

const form = 'setupCultivationLocations';

export const ModifyLocationFormWrapper = reduxForm({
  form,
  validate,
  enableReinitialize: true,
})(CreateLocationForm);

const selector = formValueSelector(form);

function mapStateToProps(state, props) {
  const defaults = selector(state, 'defaults') || [];
  const locations = selector(state, 'child_locations') || [];
  return {
    defaults,
    locations,
    type: 'storage',
    printLabel: props.printLabel,
    showBuildingType: true,
    showSquareFootage: true,
    formErrors: getFormSyncErrors('setupCultivationLocations')(state)
  };
}

export default connect(mapStateToProps)(ModifyLocationFormWrapper);
