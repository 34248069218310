import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Button, ButtonToolbar, Col, Row} from 'react-bootstrap';
import {getOrderFulfillmentOptions} from '../../../constants/orderFulfillmentMethods';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import MmuLimit from './MmuLimit';

const StartOrderForm = (props) => {
  const {handleSubmit, change, submitting, invalid, disabled, caregiver, caregivers, pristine, customerId, isMmuEnabled} = props;
  const orderFulfillmentOptions = getOrderFulfillmentOptions();
  return (
    <form onSubmit={handleSubmit} noValidate={true}>
      {isMmuEnabled &&
        <MmuLimit
          customerId={customerId}
        />
      }
      <Row>
        <Col xs={6}>
          <Field name='fulfillment_method_value' component={ReactSelectInput} props={{
            label: I18n.t('customer.form.fulfillmentMethod'),
            options: orderFulfillmentOptions.filter((item) => !item.not_fulfillment_method),
            isRequired: true,
            onChange: (value) => {
              const selectedOption = orderFulfillmentOptions.find(option => option.value === value);
              change('fulfillment_method_value', value);
              change('fulfillment_method', selectedOption && selectedOption.fulfillment_method || null);
              change('order_source', selectedOption && selectedOption.set_order_source || null);
              change('is_submitted', selectedOption && selectedOption.is_submitted || 0);
            },
          }}/>
        </Col>
        {
          !caregivers || caregiver !== undefined
            ? null
            : (<Col xs={6}>
            <Field
              name='caregiver_id'
              component={ReactSelectInput}
              props={{
                placeholder: I18n.t('common.form.select'),
                label: I18n.t('customers.table.caregivers'),
                options: caregivers,
                textKey: 'full_name',
                valueKey: 'id'
              }}>
              <div className='text-muted' style={{fontSize: 'smaller'}}>{I18n.t('customers.table.caregiversNote')}</div>
            </Field>
          </Col>)
        }
      </Row>
      <Row className='submit-section'>
        <Col xs={12}>
          <div className='button-wrapper'>
            <ButtonToolbar className='float-right'>
              <Button variant='primary' type='submit'
                      disabled={submitting || invalid || disabled || pristine}>{I18n.t('customers.table.startOrder')}</Button>
            </ButtonToolbar>
          </div>
        </Col>
      </Row>
    </form>
  );
};

StartOrderForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  caregiver: PropTypes.object,
  caregivers: PropTypes.array.isRequired,
  pristine: PropTypes.bool,
  customerId: PropTypes.number.isRequired,
  isMmuEnabled: PropTypes.bool.isRequired
};

export default StartOrderForm;
