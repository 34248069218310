/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import * as dataNames from '../constants/dataNames';
import { getPartners } from './partnersSelectors';
import {getUomsTypeByType} from './uomsSelectors';

const getSelectedEquipmentItems = state => state.selectedEquipmentItems;

export const getSelectedEquipmentIds = createSelector(getSelectedEquipmentItems, selectedEquipmentItems =>
  selectedEquipmentItems.map(selectedEquipmentItem => selectedEquipmentItem.id));

export const getEquipment = state => state.equipment;
export const getEquipments = state => state[dataNames.equipmentItems];
export const getEquipmentTypes = state => state[dataNames.equipmentTypes];

const getType = (_, props) => props.type;

const equipmentForListing = createSelector(
  [getEquipments, getPartners],
  (equipment, partners) => {
    return equipment.map(item => ({
      ...item,
      vendors: partners
        .filter(partner => item.vendors && item.vendors.some(vendor => vendor.vendor_id === partner.id))
        .map(partner => partner.name).join(', ')
    }));
  }
);

export const getCreatePayload = (formData) => {
  const data = formData.items.map((item) => {
    return {
      model_name: item.equipModel,
      name: item.equipModel,
      equipment_type_code: item.equipType.code ? item.equipType.code : item.equipType,
      vendor_ids: [item.vendor],
      life_expectancy_base: item.lifeExpectancy,
      life_expectancy_uom_display: item.uomEquipProfile ? item.uomEquipProfile : undefined,
      purchase_price: item.price,
      kwh_day: item.energyUsage,
      service_tags: item.serviceType.map((tag) => {
        if (typeof tag === 'object') {
          return tag.tag_name;
        } else {
          return tag;
        }
      }),
      service_frequency_base: item.frequency,
      frequency_uom_display: item.uomMaintenance,
      equipment_photo_file_id: item.image_id,
      hours_used_per_day: item.hoursPerDay
    };
  });
  return { store_many: data };
};

export const getModifyPayload = createSelector(
  [getEquipment, getPartners, getEquipmentTypes],
  (equipment, partners, equipmentTypes) => {
    if (!equipment) return {};

    const vendorId = equipment.vendors && equipment.vendors.length && equipment.vendors[0].vendor_id;
    const vendor = partners.find(partner => vendorId === partner.id);

    return {
      equipModel: equipment.name,
      equipType: equipmentTypes.find(equipmentType => equipmentType.code.toUpperCase() === equipment.equipment_type_code),
      vendor: vendor && vendor.id,
      lifeExpectancy: equipment.life_expectancy_base || 0,
      uomEquipProfile: equipment.life_expectancy_uom_display,
      price: equipment.purchase_price,
      energyUsage: equipment.kwh_day,
      serviceType: equipment.service_tags,
      frequency: equipment.service_frequency_base || 0,
      uomMaintenance: equipment.frequency_uom_display,
      image_id: equipment.equipment_photo_file_id,
      hoursPerDay: equipment.hours_used_per_day || 0,
    };
  }
);

export const getEquipmentByType = createSelector(
  [equipmentForListing, getType],
  (equipment, equipmentType) => {
    if (equipmentType) {
      return equipment.filter(item => item.equipment_type_code.toUpperCase() === equipmentType.toUpperCase());
    } else {
      return equipment;
    }
  }
);

export const fillEquipmentDataForListing = createSelector([getEquipmentByType, getUomsTypeByType], (equipment, uoms) => {
  return equipment.map(item => {
    return {
      ...item,
      life_expectancy_sec: uoms.reduce((acc, uom) => {
        if (uom.uom_code === item.life_expectancy_uom_display) {
          acc = item.life_expectancy_base * uom.conversion_ratio;
        }
        return acc;
      }, 0)
    };
  });
});
