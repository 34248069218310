import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import InProgressOverlay from '../../common/InProgressOverlay';
import PhysicianFormWrapper from '../common/PhysicianFormWrapper';

const CreatePhysicianModal = (props) => {
  const {saving, initialValues, onSubmit, clinics, countries, provinces, activeLicenseIndex, primaryAddressIndex} = props;
  return (
    <Modal.Body>
      <InProgressOverlay message='physicians.saving' isActive={saving} translate={true}/>
      <PhysicianFormWrapper
        initialValues={initialValues}
        onSubmit={onSubmit}
        clinics={clinics}
        countries={countries}
        provinces={provinces}
        activeLicenseIndex={activeLicenseIndex}
        primaryAddressIndex={primaryAddressIndex}
      />
    </Modal.Body>
  );
};

CreatePhysicianModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  clinics: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  provinces: PropTypes.array.isRequired,
  primaryAddressIndex: PropTypes.number.isRequired,
  activeLicenseIndex: PropTypes.number.isRequired,
  saving: PropTypes.bool,
};

export default CreatePhysicianModal;
