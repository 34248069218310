import React from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import {change, reduxForm, getFormValues} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import moment from 'moment/moment';
import {Button} from 'react-bootstrap';
import {postItem} from '../../actions/apiActions';
import {getInitialValues} from '../../selectors/forms/facilityInitialInventorySelectors';
import {FACILITY_INITIAL_INVENTORY_FORM} from '../../constants/forms';
import InternationalDateTimePickerInput from '../common/form/InternationalDateTimePickerInput';

class FacilityInitialInventoryForm extends React.Component {

  render() {
    const {formValues, handleSubmit, showConfirmModal} = this.props;

    if (!formValues) return null;

    //if no date is chosen it defaults to today
    const initialInventoryByDate = formValues.initial_inventory_by_date 
    ? moment(formValues.initial_inventory_by_date).add(30, 'days') 
    : moment().add(30, 'days');

    const initial_inventory_by_date = initialInventoryByDate.format();

    //show date for valid and invalid chosen for button
    const dateForDisplayOnValidButton = initialInventoryByDate.subtract(30, 'days').startOf('day').format('dddd MMMM D, YYYY');

    const todaysDate = moment().startOf('day').format('dddd MMMM D, YYYY');


    //validation that gets the min date, and checks if the manually input a date
    const minDate = moment().add(30, 'days').format();

    const dateBeforeToday = initial_inventory_by_date >= minDate ? 1 : 0;

    //this will not allow them to click on a date earlier, but if they type it in the above will not let them continue
    const isValidDate = current => {
      return current && current.isSameOrAfter(moment().startOf('day'));
    };

    return (
      <form>
        {formValues.allow_initial_inventory === true && moment(formValues.current_initial_inventory_by_date).isAfter(moment())
          ? <div>
              <h4>{I18n.t('closedLoop.facility.initialInventoryActive')}</h4>
              <div>{I18n.t('closedLoop.facility.initialInventoryByDate')}: {moment(formValues.current_initial_inventory_by_date).format( 'M/D/Y')}</div>
              <br/>
              <InternationalDateTimePickerInput
                name='current_initial_inventory_by_date'
                props={{
                  inputProps: {disabled: true},
                  enableTodayButton: false
                }} />
                <br/>
                <Button type={'button'} variant={'danger'} size='sm'
                  onClick={() => {
                    const initial_inventory_by_date = moment();
                    showConfirmModal({facility_id: formValues.facility_id, allow_initial_inventory: false, initial_inventory_by_date});
                  }}
                >
                {I18n.t('closedLoop.facility.cancelInitialInventory')}
              </Button>
            </div>
          : <div>
              <h4>{I18n.t('closedLoop.facility.initialInventoryNotActive')}</h4>
              {formValues.current_initial_inventory_by_date &&
                <div>{I18n.t('closedLoop.facility.initialInventoryLastByDate')}: {moment(formValues.current_initial_inventory_by_date).format( 'M/D/Y')}</div>
              }
              <InternationalDateTimePickerInput
                name='initial_inventory_by_date'
                isValidDate={isValidDate}
                props={{
                  label: I18n.t('closedLoop.facility.chooseInitialInventoryDate', {dateTime: todaysDate}),
                  closeOnSelect: true,
                  enableTodayButton: true,
                  initialValue: moment()
                }}
              />
              <br/>
              <Button type={'button'} className={dateBeforeToday ? 'btn btn-primary' : 'btn btn-danger'}
                disabled={!dateBeforeToday}
                onClick={() => {
                  handleSubmit({facility_id: formValues.facility_id, allow_initial_inventory: true, initial_inventory_by_date});
                }}
              >
              {dateBeforeToday
                ? I18n.t('closedLoop.facility.enableInitialInventory', { dateTime: dateForDisplayOnValidButton })
                : I18n.t('closedLoop.facility.dateBeforeToday', { dateTime: todaysDate })
              }
              </Button>
            </div>
        }
      </form>
    );
  }
}

FacilityInitialInventoryForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  showConfirmModal: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  initialValues: getInitialValues(state),
  formValues: getFormValues(FACILITY_INITIAL_INVENTORY_FORM)(state)
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({change, postItem}, dispatch)
  };
};


const FacilityInitialInventoryFormWrapper = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({})
)(FacilityInitialInventoryForm);

export default FacilityInitialInventoryFormWrapper;
