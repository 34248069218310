import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import InternationalDateTimePickerInput from '../../common/form/InternationalDateTimePickerInput';

const DeliveryOrderFormHeaderRetail = (props) => {

  const {
    facilityTimezone,
    courierOptions,
    change,
    formValues,
    deliveryStatusOptions
  } = props;


  const deliveryMethodOptions = [
    {
      text: I18n.t('deliveryOrders.form.courier'),
      value: 'courier'
    },
    {
      text: I18n.t('deliveryOrders.form.inhouse'),
      value: 'in_house'
    },
  ];

  return (
    <div>
    <Row>
      <Col md={4}>
        <Field
          name='delivery_method'
          component={ReactSelectInput}
          props={{
            label: I18n.t('deliveryOrders.form.deliveryMethod'),
            options: deliveryMethodOptions,
            placeholder: I18n.t('common.form.select'),
            isRequired: true,
            onChange: (value) => {
              if (value !== 'courier') {
                change('courier', '');
              }
              change('delivery_method', value);
            }
          }}
        />
      </Col>
      { get(formValues, 'delivery_method') === 'courier' ?
        <Col md={4}>

          <Field
            name='partner_facility_id'
            component={ReactSelectInput}
            props={{
              label: I18n.t('deliveryOrders.form.courier'),
              options: courierOptions,
              placeholder: I18n.t('common.form.select'),
              isRequired: true
            }}
          />
        </Col>
        : null}
      <Col md={4}>
        <Field
          name='delivery_status'
          component={ReactSelectInput}
          props={{
            label: I18n.t('deliveryOrders.form.deliveryStatus'),
            options: deliveryStatusOptions,
            placeholder: I18n.t('common.form.select'),
            isRequired: true
          }}
        />
      </Col>
    </Row>
    <Row>
    <Col md={4}>
      <InternationalDateTimePickerInput
        timezone={facilityTimezone}
        name='delivery_expected_at'
        props={{
          label: I18n.t('deliveryOrders.form.dateExpected'),
          enableTodayButton: true,
          timeFormat: true,
        }}
      />
    </Col>
    </Row>
    </div>
  );
};

export default DeliveryOrderFormHeaderRetail;