import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, formValueSelector} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Card} from 'react-bootstrap';
import ChangeDate from './ChangeDate';

const Form = ({order, getMayChangeDate, form, initialValues, handleSubmit}) => {
  const displayChangeDate = order.order_status === 'completed' && (order.order_type === 'sales' || order.order_type === 'refund');

  return (
    <Card>
      <Card.Body>
        <h5><b>{I18n.t('cultivation.orderHistory.table.completedDate')}: {order.name}</b></h5>
        <form className='simple-form' onSubmit={handleSubmit}>
            <Field
              name='ChangeDate'
              component={ChangeDate}
              mayChangeDate={getMayChangeDate(form)}
              initialValues={initialValues}
              displayChangeDate={displayChangeDate}
            />
        </form>
      </Card.Body>
    </Card>
  );

};

Form.propTypes = {
  order: PropTypes.object.isRequired,
  getMayChangeDate: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const ReduxChangeDateForm = reduxForm({
  enableReinitialize: true,
  destroyOnUnmount: false,
})(Form);

const ChangeDateForm = connect((state, props) => {
  const getMayChangeDate = formName => {
    const selector = formValueSelector(formName);
    return selector(state, 'mayChangeDate');
  };
  return {
    getMayChangeDate
  };
})(ReduxChangeDateForm);

export default ChangeDateForm;
