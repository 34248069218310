import React from 'react';
import { I18n } from 'react-redux-i18n';
import { parse } from 'query-string';

import { AuthRequest } from '../../../managers/request';
import Loading from '../../common/loading';

import MerchantTerminalForm from './MerchantTerminalForm';

class MerchantTerminalPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialized: false
    };

    this.request = AuthRequest.create();
  }

  componentDidMount() {
    const { params: { id } } = this.props;
    const query = parse(this.props.location.search);
    const { merchant_profile_id } = query;
    if (id) {
      this.findTerminal(id).then((terminal) => {
        this.setState({ terminal: terminal, initialized: true });
      });
    } else {
      this.setState({ terminal: { merchant_profile_id: merchant_profile_id }, initialized: true });
    }
  }

  componentWillUnmount() { }

  findTerminal(id) {
    this.setState({
      loading: true
    });
    return this.request.get('/api/payment/merchant_terminals/find?id=' + id)
      .then((response) => {
        this.setState({ loading: false });
        return response.data || null;
      });
  }

  render() {
    const { terminal, initialized } = this.state;

    return (
      <React.Fragment>
        {initialized ?
          <div>
            <MerchantTerminalForm terminal={terminal} />
          </div> :
          <Loading>{I18n.t('general.loading')}</Loading>
        }
      </React.Fragment>
    );
  }
}


export default MerchantTerminalPage;



