import {
  requiredFieldValidation,
  integerFieldValidation,
  maxValidation,
  minValidation
} from '../../common/form/redux-form/validations';
import packagableUnmedicatedSubcategories from '../../../constants/packageableUnmedicatedSubcategories';

const validate = (values, {selectedItemMaster}) => {
  const errors = {
    individual_plants: {}
  };

  const shouldValidateQty =
    selectedItemMaster && packagableUnmedicatedSubcategories.includes(selectedItemMaster.subcategory_code);

  if (shouldValidateQty) {
    errors.qty = minValidation(values.qty, 'plants.packagePlants.quantity', 1, false);
  }

  //Adding form level error since the field may be not rendered
  errors.item_master_id = requiredFieldValidation(values.item_master_id);
  errors.storage_location_id = requiredFieldValidation(values.storage_location_id);
  errors.state_integration_tracking_id = requiredFieldValidation(values.state_integration_tracking_id);
  errors.datePackaged = requiredFieldValidation(values.datePackaged);

  if (values.package_individual_plants) {
    for (const key in values.individual_plants) {
      const plant = values.individual_plants[key];

      errors.individual_plants[key] = {
        count: requiredFieldValidation(plant.count) || integerFieldValidation(plant.count) || maxValidation(plant.count, undefined, 500),
        state_integration_tracking_id: requiredFieldValidation(plant.state_integration_tracking_id),
      };
    }
  }

  return errors;
};

export default validate;
