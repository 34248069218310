import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import RecursiveListComponent from './RecursiveListComponent';

const PlaceholdersList = (props) => {

  const node = props.label && props.label.data ? props.label.data : props.label;

  return (
    <div style={{marginTop: '16px'}}>
      <strong>{I18n.t('labels.placeholdersFieldLabel')}</strong> {I18n.t('labels.placeholdersFieldLabelHint')}
      {
        !props.activeSource
        ? <div>Click a row in the source table to load the data and placeholders.  All rows will return the same placeholders.</div>
          : null
      }
      {
        Object.keys(props.label).length === 0
        ? null
          : <div className='custom-labels-list'><RecursiveListComponent isListRoot={true} node={[node[0]]} /></div>
      }
    </div>
  );

};

PlaceholdersList.propTypes = {
  activeSource: PropTypes.object.isRequired,
  label: PropTypes.object.isRequired
};

export default PlaceholdersList;
