import {change} from 'redux-form';
import {SALES_ORDER_FORM} from '../../constants/forms';
import {isFormChangeAction} from './utils';

const orderForm = store => next => action => {
  const result = next(action);

  if (isFormChangeAction(action, [SALES_ORDER_FORM])) {
    const {meta, payload} = action;
    if(meta.field.indexOf('is_sample') !== -1){
      const key = meta.field.split('.').shift();
      if (!payload) {
        store.dispatch(change(meta.form, `${key}.sample_type`, null));
        store.dispatch(change(meta.form, `${key}.product_sample_type`, null));
      }
    }
    if(meta.field.indexOf('.sample_type') !== -1){
      const key = meta.field.split('.').shift();
      if (!payload) {
        store.dispatch(change(meta.form, `${key}.product_sample_type`, null));
      }
    }
  }

  return result;
};

export default orderForm;
