import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { Button, Col, Image, Row } from 'react-bootstrap';
import {FaTimes} from 'react-icons/fa';
import get from 'lodash.get';
import { PERSON } from '../../../constants/imageUrls';

const Profile = ({ customer, customerGroups, showCloseButton, close, editProfile, patientComplianceSettings }) => {
  const imageCols = showCloseButton ? 9 : 12;
  const phone = customer.phone && customer.phone.length ? customer.phone[0].number : null;
  const groups = customer.groups
    ? customer.groups.map((group) => customerGroups.find((customerGroup) => group && customerGroup.id === group.id))
    : [];
  const groupNames = groups ? groups.map((group) => (group ? group.group_name : '')) : [];
  const tempImageUrl = get(customer, 'image_url', '');
  const profileImageUrl =
    typeof tempImageUrl === 'string' ? (!tempImageUrl.trim().length ? PERSON : tempImageUrl.trim()) : PERSON;
  return (
    <Row className='patient-profile'>
      <Col xs={5}>
        <Row>
          {showCloseButton ? (
            <Col xs={3}>
              <Button className='close-button' onClick={close}>
                <FaTimes />
              </Button>
            </Col>
          ) : null}
          <Col xs={imageCols}>
            <Image circle className='patient-pic' src={profileImageUrl} />
          </Col>
        </Row>
      </Col>
      <Col xs={7}>
        <h4>
          {patientComplianceSettings && patientComplianceSettings.goes_by_enabled && customer.goes_by ? customer.goes_by : `${customer.first_name} ${customer.last_name}`}
        </h4>
        {I18n.t('cart.patient.group')}: {groups ? groupNames.join(', ') : 'None'}
        <br />
        <a href='mailto:{customer.email_address}'>{customer.email_address}</a>
        <br />
        {phone}
        <br />
        <a
          onClick={() => {
            close();
            editProfile(`/patients/modify/${customer.id}`);
          }}
        >
          {I18n.t('cart.patient.editProfile')}
        </a>
      </Col>
    </Row>
  );
};

Profile.propTypes = {
  customer: PropTypes.object,
  customerGroups: PropTypes.array,
  showCloseButton: PropTypes.bool,
  editProfile: PropTypes.func,
  close: PropTypes.func,
  patientComplianceSettings: PropTypes.object.isRequired,
};

Profile.defaultProps = {
  customer: {
    first_name: '',
    last_name: '',
    email_address: '',
    image_url: '',
    groups: [],
    phone: []
  },
  customerGroups: [],
  showCloseButton: false,
  patientComplianceSettings: {},
  editProfile: () => {},
  close: () => {}
};

export default Profile;
