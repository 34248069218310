import React from 'react';
import PropTypes from 'prop-types';
import {reduxForm, Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Button, ButtonToolbar, Col, Row} from 'react-bootstrap';
import {FaRegClock} from 'react-icons/fa';
import SelectInput from '../common/form/SelectInput';
import TextInput from '../common/form/TextInput';
import NumericInput from '../common/form/NumericInput';

const initialState = {
  plantAdvAlertVegToFlower: 1,
  plantAdvAlertFlowerToHarvest: 1,
  packageAlertForHarvest: 1,
  packageAlertForHarvestDays: 40,
  taskAlertForIncomplete: 1,
  taskAlertForIncompleteTime: '7:00 PM',
  testAlertForTestsNotEntered: 1,
  testAlertForTestsNotEnteredDuration: 10,
  testAlertForTestsNotEnteredDurationUnit: 'days',
  propagationAlertForFutureGoals: 1,
  plantAdvAlertPropToVeg: 1,
  packageAlertTimeInCure: 1
};

export const AlertsSettingsForm = ({handleSubmit, reset, submitting, pristine}) => {
  return (
    <form className='alerts-settings-form' onSubmit={handleSubmit}>
      <Row>
        <Col xs={8}>
          <strong>{I18n.t('alerts.settings.plantAdvAlertVegToFlower')}</strong>
        </Col>
        <Col xs={4}>
          <Field
            name='plantAdvAlertVegToFlower' component={SelectInput}
            props={{
              options: [{value: 1, text: 'Yes'}, {value: 0, text: 'No'}],
              className: 'yesno-input',
              textKey: 'text',
              valueKey: 'value'
            }} />
        </Col>
      </Row>
      <Row>
        <Col xs={8}>
          <strong>{I18n.t('alerts.settings.plantAdvAlertFlowerToHarvest')}</strong>
        </Col>
        <Col xs={4}>
          <Field
            name='plantAdvAlertFlowerToHarvest' component={SelectInput}
            props={{
              options: [{value: 1, text: 'Yes'}, {value: 0, text: 'No'}],
              className: 'yesno-input',
              textKey: 'text',
              valueKey: 'value'
            }} />
        </Col>
      </Row>
      <Row>
        <Col xs={8}>

            <strong>{I18n.t('alerts.settings.packageAlertForHarvest')}</strong>
            <Field
              className='days-input'
              name='packageAlertForHarvestDays' component={NumericInput}
              />
            <strong>{I18n.t('alerts.settings.days')}</strong>

        </Col>
        <Col xs={4}>
          <Field
            name='packageAlertForHarvest' component={SelectInput}
            props={{
              options: [{value: 1, text: 'Yes'}, {value: 0, text: 'No'}],
              className: 'yesno-input',
              textKey: 'text',
              valueKey: 'value'
            }} />
        </Col>
      </Row>
      <Row>
        <Col xs={8}>

            <strong>{I18n.t('alerts.settings.taskAlertForIncomplete')}</strong>
            <Field
              className='time-input'
              name='taskAlertForIncompleteTime' component={TextInput}
              />
            <FaRegClock />

        </Col>
        <Col xs={4}>
          <Field
            name='taskAlertForIncomplete' component={SelectInput}
            props={{
              options: [{value: 1, text: 'Yes'}, {value: 0, text: 'No'}],
              className: 'yesno-input',
              textKey: 'text',
              valueKey: 'value'
            }} />
        </Col>
      </Row>
      <Row>
        <Col xs={8}>

            <strong>{I18n.t('alerts.settings.testAlertForTestsNotEntered')}</strong>
            <Field
              className='days-input'
              name='testAlertForTestsNotEnteredDuration' component={NumericInput}
              />
            <Field
              name='testAlertForTestsNotEnteredDurationUnit' component={SelectInput}
              className='dayweek-input'
              props={{
                options: [{value: 'days', text: 'Days'}, {value: 'weeks', text: 'Weeks'}],
                textKey: 'text',
                valueKey: 'value'
              }} />

        </Col>
        <Col xs={4}>
          <Field
            name='testAlertForTestsNotEntered' component={SelectInput}
            props={{
              options: [{value: 1, text: 'Yes'}, {value: 0, text: 'No'}],
              className: 'yesno-input',
              textKey: 'text',
              valueKey: 'value'
            }} />
        </Col>
      </Row>
      <Row>
        <Col xs={8}>
          <strong>{I18n.t('alerts.settings.propagationAlertForFutureGoals')}</strong>
        </Col>
        <Col xs={4}>
          <Field
            name='propagationAlertForFutureGoals' component={SelectInput}
            props={{
              options: [{value: 1, text: 'Yes'}, {value: 0, text: 'No'}],
              className: 'yesno-input',
              textKey: 'text',
              valueKey: 'value'
            }} />
        </Col>
      </Row>
      <Row>
        <Col xs={8}>
          <strong>{I18n.t('alerts.settings.plantAdvAlertPropToVeg')}</strong>
        </Col>
        <Col xs={4}>
          <Field
            name='plantAdvAlertPropToVeg' component={SelectInput}
            props={{
              options: [{value: 1, text: 'Yes'}, {value: 0, text: 'No'}],
              className: 'yesno-input',
              textKey: 'text',
              valueKey: 'value'
            }} />
        </Col>
      </Row>
      <Row>
        <Col xs={8}>
          <strong>{I18n.t('alerts.settings.packageAlertTimeInCure')}</strong>
        </Col>
        <Col xs={4}>
          <Field
            name='packageAlertTimeInCure' component={SelectInput}
            props={{
              options: [{value: 1, text: 'Yes'}, {value: 0, text: 'No'}],
              className: 'yesno-input',
              textKey: 'text',
              valueKey: 'value'
            }} />
        </Col>
      </Row>
      <Row>
        <Col xs={4} offset={8}>
          <ButtonToolbar className='float-right'>
            <Button variant='primary'
                    type='submit'
                    disabled={submitting || pristine}>
              {I18n.t('locations.form.save')}
            </Button>
          </ButtonToolbar>
        </Col>
      </Row>
    </form>
  );
};

AlertsSettingsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'location',
  initialValues: initialState
})(AlertsSettingsForm);
