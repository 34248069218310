/* eslint-disable import/prefer-default-export*/
import {setItem} from './itemActions';
import * as itemNames from '../constants/itemNames';

export function setSolrErrorMessage (message) {
  return (dispatch) => {
    dispatch(setItem(message, itemNames.solrErrorMessage));
  };
}

export function setSolrSearchTerm (path, term, currentFacilityId = false) {
  return (dispatch, getState) => {
    const terms = getState()[itemNames.solrSearchTerms];
    const facilityId = terms.facilityId;
    const time = new Date().getTime();
    const updatedTerms = !currentFacilityId || currentFacilityId === facilityId
      ? Object.assign({}, terms, {[path]: {term, time}})
      : {facilityId: currentFacilityId};
    dispatch(setItem(updatedTerms, itemNames.solrSearchTerms));
  };
}
