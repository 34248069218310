import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {push} from 'react-router-redux';
import {bindActionCreators} from 'redux';
import {Alert} from 'react-bootstrap';
import {FaSave, FaTrash} from 'react-icons/fa';
import get from 'lodash.get';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import * as statuses from '../../constants/orderStatuses';
import * as p from '../../constants/permissions';
import {getUnpaginatedData, getFile, putItem} from '../../actions/apiActions';
import {setItem, unsetItem} from '../../actions/itemActions';
import {clearSelectedDataAction} from '../../actions/selectedDataActions';
import {unsetData} from '../../actions/dataActions';
import {addMessage} from '../../actions/systemActions';
import SingleActionColumn from '../common/grid/columns/SingleActionColumn';
import TablePageWrapper from '../common/grid/TablePageWrapper';
import PageTitle from '../common/PageTitle';
import InProgressOverlay from '../common/InProgressOverlay';
import {isBiotrackIntegrator} from '../../selectors/integration/biotrackSelectors';
import {isLeafIntegrator} from '../../selectors/integration/leafSelectors';
import {handleComplexSelectRow} from '../../actions/helpers/selectedDataHelper';
import {getSelectedTransferIds, getTransformedTransfers} from '../../selectors/transfersSelectors';
import {isMetrcIntegrator, isMetrcTransfersEnabled} from '../../selectors/integration/metrcSelectors';
import InternationalCurrencyStatic from '../common/form/InternationalCurrencyStatic';
import InternationalDateStatic from '../common/form/InternationalDateStatic';

class TransfersPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showLoader: false,
      message: ''
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.printInvoice = this.printInvoice.bind(this);
    this.printManifest = this.printManifest.bind(this);
    this.editTransfer = this.editTransfer.bind(this);
    this.cancelTransfer = this.cancelTransfer.bind(this);
    this.switchTab = this.switchTab.bind(this);

    const tabs = [
      {
        id: 'activeSalesOrdersTab',
        eventKey: statuses.activeSales,
        path: '/sales-orders',
        title: 'cultivation.salesOrders.actions.viewActive',
        actions: []
      },
      {
        id: 'inactiveSalesOrdersTab',
        eventKey: statuses.inactiveSales,
        path: '/sales-orders/inactive',
        title: 'cultivation.salesOrders.actions.viewInactive',
        actions: []
      },
      {
        id: 'activeTab',
        eventKey: statuses.activeTransfers,
        title: 'cultivation.transfers.active',
        path: '/transfers',
        actions: [
          {
            id: 'printInvoice',
            func: this.printInvoice,
            text: 'cultivation.transfers.printInvoice',
            glyph: 'print',
            requireSelect: true,
            permissions: [p.print_labels]
          },
          {
            id: 'printManifest',
            func: this.printManifest,
            text: 'cultivation.transfers.printManifest',
            glyph: 'print',
            requireSelect: true,
            permissions: [p.print_labels]
          }
        ]
      },
      {
        id: 'inactiveTab',
        eventKey: statuses.inactiveTransfers,
        title: 'cultivation.transfers.inActive',
        path: '/transfers/inactive',
        actions: [
          {
            id: 'printInvoice',
            func: this.printInvoice,
            text: 'cultivation.transfers.printInvoice',
            glyph: 'print',
            requireSelect: true
          },
          {
            id: 'printManifest',
            func: this.printManifest,
            text: 'cultivation.transfers.printManifest',
            glyph: 'print',
            requireSelect: true
          }
        ]
      }
    ];
    this.state = {
      activeTab: props.params.status === 'inactive' ? statuses.inactiveTransfers : statuses.activeTransfers,
      tabs
    };
  }

  componentWillMount(){
    this.props.actions.getUnpaginatedData('/api/partner_facilities', dataNames.partnerFacilities);
    this.props.actions.getUnpaginatedData('/api/drivers', dataNames.drivers);
    this.props.actions.getUnpaginatedData('/api/integration-mapping', dataNames.integrationMapping, null, {key: 'dist_transfer'});
    this.getData();

    this.props.actions.setItem('transfers', itemNames.currentPage);
    const status = this.props.params.status === 'inactive' ?  'inactive' : 'active';
    this.props.actions.setItem({status: status}, itemNames.currentParams);
  }

  componentWillUnmount() {
    this.props.actions.unsetItem(itemNames.currentPage);
    this.props.actions.unsetItem(itemNames.currentParams);
    this.props.actions.unsetItem(itemNames.currentRequest);
  }

  componentDidUpdate(nextProps, nextState) {
    if(this.props.params.status !== nextProps.params.status){
      this.props.actions.unsetData(dataNames.transfers);
      this.getData();

      const status = (this.props.params.status === 'inactive' ?  'inactive' : 'active');
      this.props.actions.setItem({status: status}, itemNames.currentParams);
    }
  }

  getData() {
    const status = this.props.params.status === 'inactive' ? 'inactive' : 'active';
    this.props.actions.getUnpaginatedData(`/api/transfers/${status}`, dataNames.transfers);
  }

  editTransfer(event, row) {
    event.preventDefault();
    event.stopPropagation();
    this.props.actions.push(`/transfers/modify/${row.id}`);
  }

  cancelTransfer(event, row) {
    event.preventDefault();
    event.stopPropagation();
    this.props.actions.putItem(
      `/api/transfers/${row.id}`,
      {status: 'cancelled'},
      itemNames.transfer,
      {failed: I18n.t('cultivation.transfers.form.failedToCancelTransfer')},
      undefined,
      () => this.props.actions.getUnpaginatedData('/api/transfers/active', dataNames.transfersActive)
    );
  }

  hideLoader(){
    this.setState({showLoader: false, message: ''});
  }

  printInvoice() {
    const id = this.props.selectedTransfers[0];
    this.setState({showLoader: true, message: I18n.t('file.download.generateInvoice')});
    this.props.actions.getFile(
      `/api/transfers/print_invoice/${id}`,
      'invoice.pdf',
      {failed: 'cultivation.transfers.printInvoiceFailed'},
      {}
    ).then(() => this.hideLoader()).catch(() => this.hideLoader());
  }

  printManifest() {
    const id = this.props.selectedTransfers[0];
    let path = `/api/transfers/print_manifest/${id}`;
    if(this.props.isBiotrack){
      path = `/api/transfers/biotrack_print_manifest/${id}`;
    }
    this.setState({showLoader: true, message: I18n.t('file.download.generateManifest')});
    this.props.actions.getFile(
      path,
      'manifest.pdf',
      {failed: 'cultivation.transfers.printManifestFailed'},
      {}
    ).then(() => this.hideLoader()).catch(() => this.hideLoader());
  }

  handleSelect(row) {
    const {selectedTransfers} = this.props;
    const action = selectedTransfers[0] === row.id ? 'remove' : 'set';
    this.props.actions.handleComplexSelectRow([row], dataNames.transfers, action);
  }

  switchTab(activeTab) {
    this.props.actions.clearSelectedDataAction(dataNames.transfers);
    const tab = this.state.tabs.find(tab => tab.eventKey === activeTab) || this.state.tabs[0];
    this.setState({activeTab});
    this.props.actions.push(tab.path);
  }

  render() {
    const {selectedTransfers, transfers, isMetrc, isMetrcTransfersEnabled} = this.props;
    if (isMetrc && isMetrcTransfersEnabled) {
      transfers.forEach(transfer => transfer.is_imported = get(transfer, 'sales_orders.0.sales_order.is_imported', false));
    }

    const columns = [
      {
        name: 'cultivation.transfers.form.dateOrdered',
        dataId: 'date_ordered',
        /* eslint-disable react/no-multi-comp */
        csvFormatter: (cell) => <InternationalDateStatic useSystemDate={false}>{cell}</InternationalDateStatic>,
        /* eslint-disable react/no-multi-comp */
        formatter: cell => <InternationalDateStatic useSystemDate={false}>{cell}</InternationalDateStatic>,
        width: '100px',
        hidden: false,
        dataSort: true,
      },
      {
        name: 'cultivation.transfers.form.partner',
        dataId: 'partner_contact_name',
        hidden: false,
        width: '100px',
        dataSort: true
      },
      {
        name: 'cultivation.transfers.form.driver',
        dataId: 'driver_name',
        hidden: false,
        width: '100px',
        dataSort: true
      },
      {
        name: 'cultivation.transfers.form.contact',
        dataId: 'partner_contact_phone',
        hidden: false,
        width: '100px',
        dataSort: true
      },
      {
        name: 'cultivation.common.table.numberOfItems',
        dataId: 'item_count',
        hidden: false,
        width: '200px',
        dataSort: true
      },
      {
        name: 'cultivation.transfers.form.orderTotal',
        dataId: 'order_total',
        /* eslint-disable react/no-multi-comp */
        csvFormatter: (cell) => <InternationalCurrencyStatic>{cell}</InternationalCurrencyStatic>,
        /* eslint-disable react/no-multi-comp */
        formatter: (cell) => <InternationalCurrencyStatic>{cell}</InternationalCurrencyStatic>,
        width: '100px',
        hidden: false,
        dataSort: true
      },
      {
        name: 'cultivation.transfers.form.partnerPO',
        dataId: 'transfer_number',
        width: '100px',
        hidden: false,
        dataSort: true
      },
      {
        name: 'cultivation.transfers.form.orderStatus',
        dataId: 'statusString',
        width: '100px',
        hidden: false,
        dataSort: true
      }];

    if (isMetrc && isMetrcTransfersEnabled) {
      columns.push({
        name: 'cultivation.salesOrders.table.metrcIntegrated',
        dataId: 'is_imported',
        formatter: cell => cell ? I18n.t('general.yes') : I18n.t('general.no'),
        hidden: false,
        dataSort: true,
        width: '100px'
      });
    }

    columns.push({
      dataId: 'id',
      name: 'cultivation.transfers.edit',
      hidden: false,
      permissions: [p.manage_inventory_transfers],
      csvFormatter: () => {
        return I18n.t('cultivation.transfers.edit');
      },
      formatter: SingleActionColumn({
        path: function (row) {
          return `/transfers/modify/${row.id}`;
        },
        label: 'cultivation.transfers.edit',
        action: this.editTransfer,
        isDisabled: (row) => ~['cancelled'].indexOf(row.status),
      }),
      width: '200px',
      dataSort: false
    });

    if (this.state.activeTab === dataNames.transfersActive) {
      columns.push({
        name: 'cultivation.transfers.cancel',
        dataId: 'id',
        hidden: false,
        permissions: [p.manage_inventory_transfers],
        csvFormatter: () => {
          return I18n.t('cultivation.transfers.cancel');
        },
        formatter: SingleActionColumn({
          path: function (row) {
            return `/transfers/modify/${row.id}`;
          },
          label: 'cultivation.transfers.cancel',
          action: this.cancelTransfer
        }),
        width: '200px',
        dataSort: false
      });
    }
    if(this.props.isLeaf){
      columns.push({
        name: 'cultivation.transfers.form.globalId',
        dataId: 'display_global_id',
        width: '100px',
        hidden: false,
        dataSort: true,
      });
      columns.push({
        name: 'cultivation.transfers.form.integrationStatus',
        dataId: 'integration_issue_flag',
        width: '100px',
        hidden: false,
        dataSort: true,
        /* eslint-disable react/no-multi-comp */
        formatter: (cell, row) => {
          if (cell === undefined) return '';
          if (cell && !row.display_global_id) return <FaTrash className='danger'/>;
          if (row.display_global_id) return <FaTrash className='success'/>;
          return <FaSave className='text-muted'/>;
        }
      });
    }


    return (
      <div>
        <PageTitle primaryText={I18n.t('supplyChain.transfers')}/>
        <Alert variant='warning'>
            Starting 8/8/23, Inactive Transfers will only be available for the previous 6 months. Please use Analytics Module
            for Inactive Transfers older than 6 months.
          </Alert>
        <InProgressOverlay
          isActive={this.state.showLoader}
          message={this.state.message}
          onDismiss={false}
          showOk={false}
          showLoader={this.state.showLoader}
          translate={false} />
        <TablePageWrapper
          ref={this.ref}
          settingKey='transfers'
          columns={columns}
          data={Array.isArray(transfers) ? transfers : []}
          activeTab={this.state.activeTab}
          tabs={this.state.tabs}
          switchTab={this.switchTab}
          selectedRows={selectedTransfers}
          handleSelect={this.handleSelect}
          hideScanSearch = {true}
          hideExport={true}
          bstProps={{
            selectRow: {
              mode: 'radio',
              selected: {selectedTransfers},
              onSelect: this.handleSelect
            },
          }}
        />
      </div>
    );
  }
}

TransfersPage.propTypes = {
  selectedTransfers: PropTypes.array.isRequired,
  transfers: PropTypes.array.isRequired,
  timezone: PropTypes.string.isRequired,
  isBiotrack: PropTypes.bool,
  isLeaf: PropTypes.bool,
  isMetrc: PropTypes.bool,
  isMetrcTransfersEnabled: PropTypes.bool,
  params:PropTypes.shape({
    status: PropTypes.string
  }),
  actions: PropTypes.shape({
    handleComplexSelectRow: PropTypes.func.isRequired,
    clearSelectedDataAction: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    getFile: PropTypes.func.isRequired,
    putItem: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    addMessage: PropTypes.func.isRequired,
  }).isRequired
};

function mapStateToProps(state) {
  return {
    isBiotrack: isBiotrackIntegrator(state),
    isLeaf: isLeafIntegrator(state),
    selectedTransfers: getSelectedTransferIds(state),
    transfers: getTransformedTransfers(state, dataNames.transfers),
    timezone: state.timezone,
    isMetrc: isMetrcIntegrator(state),
    isMetrcTransfersEnabled: isMetrcTransfersEnabled(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {handleComplexSelectRow, push, unsetData, getUnpaginatedData, addMessage, getFile, putItem, clearSelectedDataAction, setItem, unsetItem};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TransfersPage);
