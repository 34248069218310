import {createSelector} from 'reselect';
import {I18n} from 'react-redux-i18n';
import isEqual from 'lodash.isequal';
import omit from 'lodash.omit';
import * as dataNames from '../constants/dataNames';
import flattenLocations from '../util/flattenLocations';

export function getSalesLocations(state) {
  return state[dataNames.salesLocations];
}

export const locationsOrSectionsExceedFormMaximum = createSelector(
  [getSalesLocations],
  locations => {
    const maxLocations = 20;

    if (locations.length && locations.length > maxLocations) {
      return true;
    }

    const sectionCount = locations.reduce((sectionCount, location) => {
      const areas = location.child_locations || [];
      if (!areas.length) return sectionCount;

      areas.forEach(area => {
        const sections = area.child_locations || [];
        if (!sections.length) return;

        sectionCount += sections.length;
      });

      return sectionCount;
    }, 0);

    return sectionCount > maxLocations;
  }
);

export const getSetupInitialValues = createSelector(
  [getSalesLocations],
  salesLocations => {
    const initialValues = {
      defaults: [1, 1, 1],
      child_locations: [{
        name: I18n.t('locations.form.salesLocation', {id: 1}), is_sales_location: 1, active: 1, child_locations: [{
          name: I18n.t('locations.form.area', {id: 1}), active: 1, is_sales_location: 1, child_locations: [{
            name: I18n.t('locations.form.subSection', {id: 1}), active: 1, is_sales_location: 1, child_locations: []
          }]
        }]
      }]
    };
    if (salesLocations && salesLocations.length) {
      const activeLocations = filterActive(salesLocations);
      if (activeLocations && activeLocations.length) {
        initialValues.child_locations = activeLocations;
      }
    }
    return initialValues;
  }
);

export function filterActive(locations) {
  return locations.reduce(
    (acc, location) => {
      if (location.active === 1) {
        const filteredLocation = {...location};
        if (location.child_locations) {
          filteredLocation.child_locations = filterActive(location.child_locations);
        }
        acc.push(filteredLocation);
      }
      return acc;
    },
    []
  );
}

export function setInactive(location) {
  const inactive = {
    ...location,
    active: 0,
  };
  if (!inactive.parent_location_id) {
    delete inactive.parent_location_id;
  }
  if (location.child_locations) {
    inactive.child_locations = location.child_locations.map(setInactive);
  }
  return inactive;
}

function prepareLocations(initialLocations, locations) {
  return initialLocations
    .filter(l => l.id !== undefined)
    .map(initialLocation => {
      const location = locations.find(l => l.id === initialLocation.id);
      if (location) {
        const updatedLocation = {...location, active: 1};
        if (!updatedLocation.parent_location_id) {
          delete updatedLocation.parent_location_id;
        }
        if (location.child_locations) {
          updatedLocation.child_locations = prepareLocations(initialLocation.child_locations, location.child_locations);
        }
        return updatedLocation;
      } else {
        return setInactive(initialLocation);
      }
    })
    .concat(locations.filter(l => l.id === undefined));
}

export function getLocationsPayload(initialLocations, locations) {
  return prepareLocations(initialLocations, locations).reduce(
    (acc, location) => {
      const id = location.id;
      const initialLocation = omit(initialLocations.find(el => el.id === id), 'parent_location_id');
      if (!id || !isEqual(initialLocation, location)) {
        acc[id ? 'modify' : 'create'].push(location);
      }

      return acc;
    },
    {create: [], modify: []}
  );
}

// Set default value for "integrated_location_name" on 4th level of form data
function setDefaultIntegratedLocationTypeRecursive(levelItems, level = 1) {
  return (Array.isArray(levelItems) && levelItems.length > 0)
    ? levelItems.map(levelItem => ({
      ...levelItem,
      integrated_location_name: (level === 4 && !levelItem.integrated_location_name) ? 'veg' : levelItem.integrated_location_name,
      child_locations: setDefaultIntegratedLocationTypeRecursive(levelItem.child_locations, level + 1)
    }))
    : levelItems;
}

// Set default value "veg" for "integrated_location_name" if it is empty
export function setDefaultIntegratedLocationType(formData) {
  return {
    ...formData,
    child_locations: setDefaultIntegratedLocationTypeRecursive(formData.child_locations)
  };
}

export const getActiveSalesLocations = createSelector([getSalesLocations], filterActive);
export const getFlattenedSalesLocations = createSelector([getActiveSalesLocations], flattenLocations);

export const getDefaultSalesLocation = createSelector([getActiveSalesLocations], (salesLocations) =>
  salesLocations.find(location => location.is_default_for_retail)
);
