import React from 'react';
import PropTypes from 'prop-types';
import {Field, FieldArray} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {
  Button,
  ButtonToolbar,
  Col,
  ListGroup,
  ListGroupItem,
  Card,
  Row
} from 'react-bootstrap';

import * as p from '../../../constants/permissions';
import PermissionButton from '../../common/PermissionButton';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import TextInput from '../../common/form/TextInput';
import DatePickerInput from '../../common/form/DatePickerInput';
import PackagingQuantities from './PackagingQuantities'; //eslint-disable-line
import ReduxMetrcIdField from '../../common/form/redux-form/ReduxMetrcIdField';
import PackagePurposeField from '../../common/form/redux-form/PackagePurposeField';
import MetrcConcealer from '../../common/concealers/MetrcConcealer';
import CheckBoxInput from '../../common/form/CheckBoxInput';
import WillRender from '../../common/concealers/WillRender';
import DisplayQty from '../../common/DisplayQty';

export const CompletePackageForm = (props) => {

  const {
    employees,
    selectedItemMaster,
    childItemMasters,
    generatePackageId,
    // generateContainerId,
    // containerCode,
    locations,
    strain,
    producer,
    weight,
    uom,
    shakePackageCode,
    shakeIsChanged,
    pristine,
    handleSubmit,
    reset,
    submitting,
    change,
    itemMasters,
    saveComplete,
    handlePrePackItemMasterChange,
    noStrain,
    hasMetrcSettings,
    trackingIds,
    inventoryTypes,
    integrationState,
    hasPackagesTags,
    waPackagingInventoryTypes,
    isAllInputsAreMedicallyComplaint,
    didRequestTag,
    isUtahGlobalIdGenerationFeatureEnabled,
  } = props;
  const {isWaLeaf} = integrationState;

  return (
    <form className='pre-pack-form' onSubmit={handleSubmit}>
      <Row>
        <Col lg={5} xs={12}>
          <h3>{I18n.t('packages.prePack.packageProduct')}</h3>
          <Row>
            <Col xs={6}>
              <Field
                name='package_code' component={TextInput}
                props={{
                  label: I18n.t('packaging.form.parentPackageId'),
                  disabled: true
                }}/>
            </Col>
            <Col xs={6}>
              <Field name='item_master_parent_id' component={ReactSelectInput} props={{
                label: I18n.t('packaging.form.product'),
                options: itemMasters,
                textKey: 'name',
                valueKey: 'id',
                onChange: (value) => {
                  change('item_master_parent_id', value);
                  handlePrePackItemMasterChange(value);
                },
                isRequired: true
              }}/>
            </Col>
          </Row>
          <Row>
            {
              noStrain
                ? null
                : <Col xs={12}>
                  <strong>{I18n.t('packaging.form.strain')}: </strong>
                  &nbsp;{strain}
                </Col>
            }
          </Row>
          <Row>
            <Col xs={12}>
              <strong>{I18n.t('packaging.form.weight')}: </strong>
              <DisplayQty qty={weight} uom={uom} displayUom={true} />
            </Col>
          </Row>
          <Row className='vendor-row'>
            <Col xs={12}>
              <strong>{I18n.t('packaging.form.producer')}: </strong>
              {producer}
            </Col>
          </Row>
          <Row>
            <Col xs={8}>
              <Card>
                <Card.Header>
                  <h3 className='panel-title'>{I18n.t('packaging.form.employees')}</h3>
                </Card.Header>
                <ListGroup>
                  {employees.map((employee, index) => <ListGroupItem key={index}>{employee}</ListGroupItem>)}
                </ListGroup>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Field name='estimated_completion_at'
                     component={DatePickerInput}
                     props={{
                       label: I18n.t('packaging.form.estimatedCompletionTime'),
                       timeFormat: true,
                       inputProps: {
                         disabled: true
                       }
                     }}/>
            </Col>
            <Col xs={6}>
              <Field name='completed_at'
                     component={DatePickerInput}
                     props={{
                       label: I18n.t('packaging.form.actualCompletionTime'),
                       timeFormat: true,
                       isRequired: true
                     }}/>
            </Col>
            <Col xs={6}>
              <Field name='package_created_at'
                     component={DatePickerInput}
                     props={{
                       label: I18n.t('packaging.form.packageCreatedAt'),
                       timeFormat: false,
                     }}/>
            </Col>
            <Col xs={6}>
              <Field name='package_expires_at'
                     component={DatePickerInput}
                     props={{
                       label: I18n.t('packaging.form.packageExpiresAt'),
                       timeFormat: false,
                       isRequired: didRequestTag,
                       enableTodayButton: false,
                     }}/>
            </Col>
            <Col md={6}>
              <Field
                name='storage_location_id'
                component={ReactSelectInput}
                props={{
                  label: I18n.t('packages.form.storageLocation'),
                  options: locations,
                  placeholder: I18n.t('common.form.selectPlaceholder'),
                  isRequired: true
                }}
              />
            </Col>
            <Col md={6}>
              <PackagePurposeField name='purpose'/>
            </Col>
          </Row>

          <WillRender ifTrue={!isUtahGlobalIdGenerationFeatureEnabled && hasPackagesTags}>
              <Row>
                <Col md={6}>
                  <Field
                    name={'tag_requested'}
                    component={ReactSelectInput}
                    props={{
                      label: I18n.t('plants.modify.needsTag'),
                      isRequired: false,
                      options: [{text : I18n.t('common.yes'), value : 1} , {text : I18n.t('common.no'), value:  0}],
                      textKey: 'text',
                      valueKey: 'value',
                      defaultValue: {text : I18n.t('common.yes'), value : 1},
                      placeholder: I18n.t('common.common.yes')
                    }}
                  />
                </Col>
              </Row>
          </WillRender>

          <Row>
            {(isWaLeaf) && (
              <Row>
                <Col xs={6}>
                  <Field
                    name='product_not_altered'
                    component={CheckBoxInput}
                    props={{
                      groupClassName: 'inline-checkbox',
                      label: I18n.t('packaging.form.leafWa.productNotAltered'),
                    }}
                  />
                </Col>
                <Col sm={3} md={12} xs={6}>
                  <div className='alert alert-info' style={{padding: '5px'}}>
                    {I18n.t('packaging.form.leafWa.productNotAlteredNote')}
                  </div>
                </Col>

              </Row>
            )}
            {hasMetrcSettings ? <Col xs={6}>
              <ReduxMetrcIdField
                name='state_integration_tracking_id'
                props={{
                  options: trackingIds,
                  isRequired: false
                }}
              />
            </Col> : null}
            <MetrcConcealer>
              <Col md={6}>
                <Field name={`is_produced`} component={ReactSelectInput}
                       props={{
                         label: I18n.t('metrc.form.isProductionBatch'),
                         options: [
                           {text: 'Yes', value: 1},
                           {text: 'No', value: 0}
                         ]
                       }}
                />
              </Col>
            </MetrcConcealer>
          </Row>
        </Col>

        <Col lg={7} xs={12} className='table-responsive float-right'>
          <FieldArray
            name='quantities'
            component={PackagingQuantities}
            props={{
              selectedItemMaster,
              childItemMasters,
              shakePackageCode,
              shakeIsChanged,
              generatePackageId,
              change,
              inventoryTypes,
              integrationState,
              waPackagingInventoryTypes,
              isAllInputsAreMedicallyComplaint
            }}
          />
        </Col>
      </Row>
      <hr/>
      <Row>
        <Col xs={12}>
          <ButtonToolbar className='float-right'>
            <Button type='reset'
                    onClick={reset}
                    disabled={submitting || pristine || saveComplete}>
              {I18n.t('packages.form.reset')}
            </Button>
            <PermissionButton
              permissions={[p.print_labels]}
              props={{
                disabled: (submitting || pristine),
                variant: 'info',
                type: 'submit',
                onClick: () => change('print', true)
              }}>
              {
                !saveComplete
                  ? I18n.t('common.saveAndPrintLabels')
                  : I18n.t('common.printLabels')
              }
            </PermissionButton>
            <Button variant='primary'
                    type='submit'
                    onClick={(event) => change('print', false)}
                    disabled={submitting || pristine || saveComplete}>
              {I18n.t('packages.form.save')}
            </Button>
          </ButtonToolbar>
        </Col>
      </Row>
    </form>
  );
};

CompletePackageForm.propTypes = {
  saveComplete: PropTypes.bool.isRequired,
  itemMasters: PropTypes.array.isRequired,
  selectedItemMaster: PropTypes.object,
  locations: PropTypes.array.isRequired,
  employees: PropTypes.array.isRequired,
  childItemMasters: PropTypes.array.isRequired,
  strain: PropTypes.string,
  producer: PropTypes.string.isRequired,
  weight: PropTypes.number.isRequired,
  uom: PropTypes.string.isRequired,
  containerCode: PropTypes.string,
  generatePackageId: PropTypes.func.isRequired,
  generateContainerId: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  // saveAndPrint: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  quantities: PropTypes.array,
  shakePackageCode: PropTypes.string,
  shakeIsChanged: PropTypes.bool,
  pristine: PropTypes.bool.isRequired,
  handlePrePackItemMasterChange: PropTypes.func.isRequired,
  hasMetrcSettings: PropTypes.bool,
  trackingIds: PropTypes.array,
  noStrain: PropTypes.bool.isRequired,
  isAllowNegativeInventory: PropTypes.bool.isRequired,
  inventoryTypes: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
  hasPackagesTags: PropTypes.bool,
  waPackagingInventoryTypes: PropTypes.array,
  isAllInputsAreMedicallyComplaint: PropTypes.bool,
  didRequestTag: PropTypes.bool.isRequired,
  isUtahGlobalIdGenerationFeatureEnabled: PropTypes.bool,
};

CompletePackageForm.defaultProps = {
  shakePackageCode: '',
  shakeIsChanged: false,
  didRequestTag: false
};

export default CompletePackageForm;
