/*eslint-disable react/no-multi-comp*/

import React from 'react';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import RatingStars from '../../common/RatingStars';
import {convertDbDateToFormInputDate} from '../../../util/dateHelpers';

export const partnerColumns = [
  {
    name: 'partners.table.id',
    dataId:'id',
    hidden: true,
    width:'75px'
  },
  {
    name: 'partners.table.organization',
    dataId:'partner_name',
    hidden: false,
    width:'100px'
  },
  {
    name: 'partners.table.partner',
    dataId:'facility_name',
    hidden: false,
    width:'100px'
  },
  {
    name: 'partners.table.rating',
    dataId:'rating',
    hidden: false,
    csvFormatter: (cell) => { return `stars-${cell}`; },
    formatter: (cell, row) => <RatingStars stars={cell} keyPrefix={`stars-${cell}`}/>,
    dataAlign: 'center',
    width:'75px'
  },
  {
    name: 'partners.table.phone',
    dataId:'facility_phone',
    hidden: false,
    width:'75px'
  },
  {
    name: 'partners.table.email',
    dataId:'facility_email',
    hidden: false,
    width:'75px'
  },
  {
    name: 'common.form.address',
    dataId: 'address',
    width: '100px'
  },
  {
    name: 'common.form.city',
    dataId: 'city',
    width: '75px'
  },
  {
    name: 'common.form.state',
    dataId: 'province_code',
    width: '75px'
  },
  {
    name: 'partners.table.startPurchaseOrder',
    dataId:'purchase_from',
    updateKey: 'startPurchaseOrder',
    hidden: false,
    dataSort: false,
    width: '75px',
  },
  {
    name: 'partners.table.startSalesOrder',
    dataId:'sell_to',
    updateKey: 'startSalesOrder',
    hidden: false,
    dataSort: false,
    width: '75px',
  },
  // {
  //   name: 'Link Partner',
  //   dataId:'facility_id',
  //   updateKey: 'requestConnection',
  //   hidden: false,
  //   dataSort: false,
  //   width: '200px'
  // }
  /*, Hidden per MJP 2124
      {
        name: 'partners.table.products',
        dataId:'productCategories',
        hidden: false,
        width:'200px'
      }*/
];

export const publicConnectsColumns = [
  {
    name: 'partners.availableConnects.organizationName',
    dataId:'name',
    hidden: false,
    width:'300px'
  },
  {
    name: 'partners.table.organizationCode',
    dataId:'organization_code',
    hidden: false,
    width:'100px'
  },
  {
    name: 'partners.availableConnects.categories',
    dataId:'categoryString',
    hidden: false,
    width:'300px'
  },
  {
    name: 'partners.availableConnects.connectionRequest',
    updateKey: 'requestConnection',
    hidden: false,
    dataSort: false,
    csvFormatter: () => { return ''; },
    columnClassName: 'actions-column',
    width: '120px',
  }
];

/**
 *  Both organization code & name are sorted with similar logic, so extract here
 */
const organizationSorter = (a, b, order, attribute_name) => {
  const organization_a = (a.is_incoming) ? a.requesting_org_details : a.target_org_details;
  const organization_b = (b.is_incoming) ? b.requesting_org_details : b.target_org_details;

  if (order === 'asc') {
    return organization_b[attribute_name].localeCompare(organization_a[attribute_name]);
  } else {
    return organization_a[attribute_name].localeCompare(organization_b[attribute_name]);
  }
};

export const publicConnectsRequestsColumns = [
  {
    name: 'partners.connectRequests.requestedOn',
    dataId:'requested_at',
    formatter: cell => convertDbDateToFormInputDate(cell),
    hidden: false,
    width:'100px'
  },
  {
    name: 'partners.connectRequests.organizationCode',
    dataId:'requesting_organization_code',
    formatter: (cell, row) => {
      const organization = (row.is_incoming) ? row.requesting_org_details : row.target_org_details;
      return get(organization, 'organization_code', '');
    },
    sortFunc: (a, b, order, dataField, rowA, rowB) => {
      return organizationSorter(a,b,order, 'organization_code');
    },
    hidden: false,
    width:'100px'
  },
  {
    name: 'partners.connectRequests.organizationName',
    dataId:'organization-name',
    formatter: (cell, row) => {
      const organization = (row.is_incoming) ? row.requesting_org_details : row.target_org_details;
      return (organization) ? organization.name : '';
    },
    sortFunc: (a, b, order, dataField, rowA, rowB) => {
      return organizationSorter(a,b,order, 'name');
    },
    hidden: false,
    width:'200px'
  },
  {
    name: 'partners.connects.requests.requestType',
    dataId:'request-type',
    formatter: (cell, row) => {
      const direction = (row.is_incoming) ? 'incoming' : 'outgoing';
      return I18n.t(`partners.connects.${direction}`);
    },
    sortFunc: (a, b, order) => {
      return  parseInt((order === 'asc')  ? (a.is_incoming - b.is_incoming) : (b.is_incoming - a.is_incoming));
    },
    hidden: false,
    width:'200px'
  },
  {
    updateKey: 'respondToRequest',
    hidden: false,
    dataSort: false,
    csvFormatter: () => { return ''; },
    columnClassName: 'actions-column',
    width: '120px',
  }
];
