import React from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import {Field, FieldArray} from 'redux-form';
import {Button, Col, Row} from 'react-bootstrap';
import get from 'lodash.get';
import AccordionPanel from '../../../common/AccordionPanel';
import {
  NON_MEDICATED_CATEGORY_ID,
  PLANTS_CATEGORY_ID,
  WHOLESALE_PLANTS_CATEGORY_ID
} from '../../../../constants/categories';
import NumericInput from '../../../common/form/NumericInput';
import TestResultsFieldArray from '../common/TestResultsFieldArray';
import {getTestResultDimensions} from '../../../../selectors/testResultsSelectors';
import ReactSelectInput from '../../../common/form/ReactSelectInput';

const TestingPanel = (props) => {

  const {formValues, change, testResultDimensions} = props;

  const potencyList = get(testResultDimensions, 'cannabinoid_potency');
  const terpeneList = get(testResultDimensions, 'terpene_profile');

  const test_results_source = get(formValues, 'test_results_source', 'item_master');
  const test_results_value_type = get(formValues, 'test_results_value_type', 'percentage');
  const uom = get(formValues, 'default_uom');
  const category_id = get(formValues, 'category_id');
  const isShowServingsPerContainerField = ![PLANTS_CATEGORY_ID, WHOLESALE_PLANTS_CATEGORY_ID, NON_MEDICATED_CATEGORY_ID].includes(category_id) && test_results_value_type === 'serving';

  const servings_per_container = get(formValues, 'servings_per_container', '');
  const servingUoms = [{id: 'mg', name: 'MG'}, {id: 'gr', name: 'GR'}];
  const is_serving_mode = test_results_value_type === 'serving';

  // Recalculates test results onChange of servings_per_container or uom
  const recalculateTestResults = (servings_per_container, servings_uom) => {
    if (!servings_per_container || !servings_uom) {
      return;
    }

    // Determine conversion multiplier.
    // If servings_uom not changed it is 1
    // If changed from gr to mg it is 1000
    // If changed from mg to gr it is 0.001
    const multiplier = get(formValues, 'servings_uom', 'mg') === servings_uom
      ? 1
      : servings_uom === 'mg'
        ? 1000
        : 0.001;

    const potency_test_results = get(formValues, 'potency_test_results', []);
    const terpene_test_results = get(formValues, 'terpene_test_results', []);
    potency_test_results.forEach((potency_test_result, index) => {
      const value = parseFloat(Number(get(potency_test_result, 'value') * multiplier).toFixed(3));
      if (value) {
        change(`potency_test_results[${index}].value`, value);
        const value_per_unit = parseFloat(Number(value * servings_per_container).toFixed(3));
        change(`potency_test_results[${index}].value_per_unit`, value_per_unit);
      }
    });
    terpene_test_results.forEach((terpene_test_result, index) => {
      const value = parseFloat(Number(get(terpene_test_result, 'value') * multiplier).toFixed(3));
      if (value) {
        change(`terpene_test_results[${index}].value`, value);
        const value_per_unit = parseFloat(Number(value * servings_per_container).toFixed(3));
        change(`terpene_test_results[${index}].value_per_unit`, value_per_unit);
      }
    });
  };

  // Recalculates Medicated Net Weight onChange of servings_per_container
  const recalculateMedicatedNetWeight = (servings_per_container) => {
    // Preconditions for being able to calculate Medicated Net Weight
    if (!servings_per_container || test_results_source !== 'item_master' || test_results_value_type !== 'serving' || get(formValues, 'medicated_weight_uom', '') === '') {
      return;
    }

    const potency_test_results = get(formValues, 'potency_test_results', []);
    const filtered_potency_test_results = potency_test_results.filter((test_result) => get(test_result, 'use_for_med_weight', false) === true);

    let calculated_medicated_net_weight_in_mg = 0;
    filtered_potency_test_results.forEach((potency_test_result, index) => {
      const value = get(potency_test_result, 'value');
      const uom = get(formValues, 'uom', 'mg');
      if (value && uom) {
        const value_per_unit = parseFloat(Number(value * servings_per_container).toFixed(3));
        const multiplier = uom === 'mg' ? 1 : 1000;
        calculated_medicated_net_weight_in_mg += value_per_unit * multiplier;
      }
    });
    // Only set Medicated Net Weight if we found actual values
    if (calculated_medicated_net_weight_in_mg > 0) {
      const calculated_medicated_net_weight_in_g = calculated_medicated_net_weight_in_mg / 1000;
      const medicated_weight_uom = get(formValues, 'medicated_weight_uom');
      if (!medicated_weight_uom) {
        change('medicated_weight_uom', 'MG');
      }
      change('medicated_weight', medicated_weight_uom === 'GR'
        ? parseFloat(Number(calculated_medicated_net_weight_in_g).toFixed(3))
        : parseFloat(Number(calculated_medicated_net_weight_in_mg).toFixed(3))
      );
    }
  };

  // Clear any 'use_for_med_weight' checkboxes set in the potency_test_results field array
  const clearMedicatedNetWeightCheckboxes = () => {
    const potency_test_results = get(formValues, 'potency_test_results', []);
    potency_test_results.forEach((potency_test_result, index) => {
      change(`potency_test_results[${index}].use_for_med_weight`, false);
    });
  };

  return (
    <AccordionPanel  title='products.form.productTestingInfo'>
      <Row>
        <Col sm={4}>
          <label>
            <Field name='test_results_source'
              component='input'
              type='radio'
              value='item_master'
              onChange={() => {
                clearMedicatedNetWeightCheckboxes();
                change('test_results_source', 'item_master');
              }}
            />
            &nbsp;{I18n.t('products.form.productTestingSourceProductMasterTestResults')}
          </label>
          &nbsp;
          <label>
            <Field name='test_results_source'
                   component='input'
                   type='radio'
                   value='package'
                   onChange={() => {
                     clearMedicatedNetWeightCheckboxes();
                     change('test_results_source', 'package');
                   }}
            />
            &nbsp;{I18n.t('products.form.productTestingSourcePackageTestResults')}
          </label>
          <div style={{marginTop: '10px'}}>
            <Button
              type='button'
              variant={test_results_value_type === 'percentage' ? 'primary' : 'default'}
              onClick={() => {
                clearMedicatedNetWeightCheckboxes();
                change('test_results_value_type', 'percentage');
              }}
            >
              {I18n.t('products.form.productTestResultsType.percentage')}
            </Button>
            &nbsp;{I18n.t('products.form.or')}&nbsp;
            <Button
              type='button'
              variant={test_results_value_type === 'serving' ? 'primary' : 'default'}
              onClick={() => {
                clearMedicatedNetWeightCheckboxes();
                change('test_results_value_type', 'serving');
              }}
              disabled={!uom || uom === 'GR'}
            >
              {I18n.t('products.form.productTestResultsType.serving')}
            </Button>
          </div>
          {isShowServingsPerContainerField &&
            <div style={{marginTop: '10px'}} className='field-width-100'>
              <Field
                name='servings_per_container'
                component={NumericInput}
                style={{width: '25%'}}
                props={{
                  label: I18n.t('products.form.productServingsPerPack'),
                  fractionPartSize: 0,
                  value: isShowServingsPerContainerField ? formValues.servings_per_container : '',
                  min: 1,
                  // Recalculate all _per_unit fields in TestResultsFieldsArray
                  onChange: (servings_per_container) => {
                    recalculateTestResults(servings_per_container, get(formValues, 'servings_uom', 'mg'));
                    recalculateMedicatedNetWeight(servings_per_container);
                    change('servings_per_container', servings_per_container);
                  }
                }}
              />
              <Field
                name={'servings_uom'}
                component={ReactSelectInput}
                style={{width: '25%'}}
                props={{
                  label: I18n.t('products.form.uom'),
                  options: servingUoms,
                  textKey: 'name',
                  valueKey: 'id',
                  onChange: (uom) => {
                    recalculateTestResults(get(formValues, 'servings_per_container'), uom);
                    change('servings_uom', uom);
                  }
                }}
              />
            </div>
          }
        </Col>
        {/* In 'serving' mode the two test result panels need to be displayed below each other */}
        <Col sm={is_serving_mode ? 8 : 4}>
          <h4 className='panel-title'>{I18n.t('products.form.potencyInfo')}</h4>
          {/* Only show test results field array if not is_serving_mode or if is_serving_more and servings_per_container not empty */}
          {!is_serving_mode || (is_serving_mode && servings_per_container)
            ? <FieldArray
                name='potency_test_results'
                component={TestResultsFieldArray}
                props={{
                  options: potencyList,
                  source: get(formValues, 'test_results_source', 'item_master'),
                  value_type: get(formValues, 'test_results_value_type', 'percentage'),
                  canBeUsedForMedWeight: true,
                  formValues,
                  change
                }}
              />
            : <div className='info' style={{marginBottom: '20px'}}>{I18n.t('products.form.productServingsPerPackEmpty')}</div>
          }
        </Col>
        {is_serving_mode
          ? <Col sm={4}/>
          : null
        }
        <Col sm={is_serving_mode ? 8 : 4}>
          <h4 className='panel-title'>{I18n.t('products.form.terpeneInfo')}</h4>
          {/* Only show test results field array if not is_serving_mode or if is_serving_more and servings_per_container not empty */}
          {!is_serving_mode || (is_serving_mode && servings_per_container)
            ? <FieldArray
                name='terpene_test_results'
                component={TestResultsFieldArray}
                props={{
                  options: terpeneList,
                  source: get(formValues, 'test_results_source', 'item_master'),
                  value_type: get(formValues, 'test_results_value_type', 'percentage'),
                  formValues,
                  change
                }}
              />
            : <div className='info' style={{marginBottom: '20px'}}>{I18n.t('products.form.productServingsPerPackEmpty')}</div>
          }
        </Col>
      </Row>
    </AccordionPanel>
  );
};

TestingPanel.propTypes = {
  formValues: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  testResultDimensions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    testResultDimensions: getTestResultDimensions(state),
  };
}

export default connect(mapStateToProps)(TestingPanel);
