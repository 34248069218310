import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Row, Form} from 'react-bootstrap';
import {Field, FieldArray, reduxForm} from 'redux-form';

import NumericInput from '../../../common/form/NumericInput';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import SubmitSection from '../../../common/form/SubmitSection';
import CategoriesFieldArray from './CategoriesFieldArray';

const options = [
  {text: 'Yes', value: '1'},
  {text: 'No', value: '-1'}
];

const durationOptions = [
  {text: 'Days', value: 'd'},
  {text: 'Hours', value: 'h'}
];

const equivalencyOptions = [
  {text: 'Option 1', value: '1'}
];

export const AlertSettingsForm = ({onSubmit}) => (
  <form onSubmit={onSubmit}>
    <Row className='input-row'>
      <Col xs={10} sm={9} md={8}>
        <Form.Label>{I18n.t('cultivation.alertSettings.inventory')}</Form.Label>
      </Col>
      <Col xs={2} sm={1}>
        <Field name='inventory' component={ReactSelectInput} props={{
          options
        }}/>
      </Col>
    </Row>
    <Row className='input-row'>
      <Col xs={10} sm={9} md={8}>
        <Form.Label>{I18n.t('cultivation.alertSettings.register')}</Form.Label>
      </Col>
      <Col xs={2} sm={1}>
        <Field name='register' component={ReactSelectInput} props={{
          options
        }}/>
      </Col>
    </Row>
    <Row className='input-row'>
      <Col xs={10} sm={9} md={8}>
        <Form.Label>{I18n.t('cultivation.alertSettings.coupon')}</Form.Label>
      </Col>
      <Col xs={2} sm={1}>
        <Field name='coupon' component={ReactSelectInput} props={{
          options
        }}/>
      </Col>
    </Row>
    <Row className='input-row'>
      <Col xs={10} sm={9} md={8} className='form-horizontal layout-row'>
        <Form.Label>{I18n.t('cultivation.alertSettings.expirationStart')}</Form.Label>
        <Field name='expirationPeriod' component={NumericInput}/>
        <Field name='expirationUOM' component={ReactSelectInput} props={{
          options: durationOptions
        }}/>
        <Form.Label>{I18n.t('cultivation.alertSettings.expirationEnd')}</Form.Label>
      </Col>
      <Col xs={2} sm={1}>
        <Field name='expiration' component={ReactSelectInput} props={{
          options
        }}/>
      </Col>
    </Row>
    <Row className='input-row categories'>
      <Col xs={10} sm={9} md={8}>
        <FieldArray name='categories' component={CategoriesFieldArray}/>
      </Col>
      <Col xs={2} sm={1}>
        <Field name='onHandInventory' component={ReactSelectInput} props={{
          options
        }}/>
      </Col>
    </Row>
    <Row className='input-row patient-count'>
      <Col xs={10} sm={9} md={8} className='layout-row layout-align-start-end'>
        <Form.Label>{I18n.t('cultivation.alertSettings.patientCount')}</Form.Label>
        <Field name='patientCountMin' component={NumericInput} props={{
          label: I18n.t('cultivation.alertSettings.minimum')
        }}/>
        <Field name='patientCountMax' component={NumericInput} props={{
          label: I18n.t('cultivation.alertSettings.maximum')
        }}/>
        <Form.Label>{I18n.t('cultivation.alertSettings.expirationEnd')}</Form.Label>
      </Col>
      <Col xs={2} sm={1}>
        <Field name='patientCount' component={ReactSelectInput} props={{
          options
        }}/>
      </Col>
    </Row>
    <Row className='input-row'>
      <Col xs={10} sm={9} md={8} className='form-horizontal layout-row'>
        <Form.Label>{I18n.t('cultivation.alertSettings.equivalencyStart')}</Form.Label>
        <Field name='equivalencyAction' component={ReactSelectInput} props={{
          options: equivalencyOptions
        }}/>
        <Form.Label>{I18n.t('cultivation.alertSettings.equivalencyEnd')}</Form.Label>
      </Col>
      <Col xs={2} sm={1}>
        <Field name='equivalency' component={ReactSelectInput} props={{
          options
        }}/>
      </Col>
    </Row>
    <Row className='input-row'>
      <Col xs={10} sm={9} md={8}>
        <Form.Label>{I18n.t('cultivation.alertSettings.label')}</Form.Label>
      </Col>
      <Col xs={2} sm={1}>
        <Field name='label' component={ReactSelectInput} props={{
          options
        }}/>
      </Col>
    </Row>
    <Row className='input-row'>
      <Col xs={10} sm={9} md={8}>
        <Form.Label>{I18n.t('cultivation.alertSettings.inNetworkPartner')}</Form.Label>
      </Col>
      <Col xs={2} sm={1}>
        <Field name='inNetworkPartner' component={ReactSelectInput} props={{
          options
        }}/>
      </Col>
    </Row>
    <Row className='input-row'>
      <Col xs={10} sm={9} md={8}>
        <Form.Label>{I18n.t('cultivation.alertSettings.onlineOrderAlert')}</Form.Label>
      </Col>
      <Col xs={2} sm={1}>
        <Field name='onlineOrderAlert' component={ReactSelectInput} props={{
          options
        }}/>
      </Col>
    </Row>
    <Row className='input-row'>
      <Col xs={10} sm={9} md={8}>
        <Form.Label>{I18n.t('cultivation.alertSettings.invoiceUpload')}</Form.Label>
      </Col>
      <Col xs={2} sm={1}>
        <Field name='invoiceUpload' component={ReactSelectInput} props={{
          options
        }}/>
      </Col>
    </Row>
    <SubmitSection handleSubmit={onSubmit}/>
  </form>
);

AlertSettingsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default reduxForm({form: 'alertSettings', initialValues: {categories: [{}]}})(AlertSettingsForm);
