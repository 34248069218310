import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {
  Button,
  ButtonToolbar,
  Col,
  Row
} from 'react-bootstrap';

import AccordionPanel from '../common/AccordionPanel';
import RegisterFields from './RegisterFields';
import TransactionFields from './TransactionFields';

export const ModifyRegisterForm = ({handleSubmit, submitting, pristine, registers, statuses, registerTypes, partners,
                                     reasons, setShowIsDefaultFlag, showIsDefault, isPosabitIntegrator, isPosabitIntegrationEnabled,
                                     handlePosabitClick, isAlleavesIntegrator, isAlleavesIntegrationEnabled, alleavesRegisterIds}) => {

  return (
    <form className='modify-register-form' onSubmit={handleSubmit}>
      <RegisterFields
        registers={registers}
        statuses={statuses}
        registerTypes={registerTypes}
        setShowIsDefaultFlag={setShowIsDefaultFlag}
        handlePosabitClick={handlePosabitClick}
        showIsDefault={showIsDefault}
        isPosabitIntegrator={isPosabitIntegrator}
        isPosabitIntegrationEnabled={isPosabitIntegrationEnabled}
        isAlleavesIntegrator={isAlleavesIntegrator}
        isAlleavesIntegrationEnabled={isAlleavesIntegrationEnabled}
        alleavesRegisterIds={alleavesRegisterIds}
      />
      <AccordionPanel title={I18n.t('registers.form.adjustBalance')}>
        <TransactionFields partners={partners} reasons={reasons}/>
      </AccordionPanel>
      <Row className='padding-top'>
        <Col xs={12}>
          <ButtonToolbar className='float-right'>
            <Button variant='primary'
                    type='submit'
                    disabled={submitting || pristine}>
              {I18n.t('registers.form.save')}
            </Button>
          </ButtonToolbar>
        </Col>
      </Row>
    </form>
  );
};

ModifyRegisterForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  registers: PropTypes.array.isRequired,
  statuses: PropTypes.array.isRequired,
  registerTypes: PropTypes.array.isRequired,
  partners: PropTypes.array.isRequired,
  reasons: PropTypes.array.isRequired,
  setShowIsDefaultFlag: PropTypes.func,
  handlePosabitClick: PropTypes.func,
  showIsDefault: PropTypes.bool,
  isPosabitIntegrator: PropTypes.bool,
  isPosabitIntegrationEnabled: PropTypes.bool,
  isAlleavesIntegrationEnabled: PropTypes.bool,
  isAlleavesIntegrator: PropTypes.bool,
  alleavesRegisterIds: PropTypes.array,
};

export default ModifyRegisterForm;
