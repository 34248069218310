import React from 'react';
import moment from 'moment-timezone';
import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';
import {requiredFieldValidation} from '../../common/form/redux-form/validations';
import {convertDbToClientTZ, getToday} from '../../../util/dateHelpers';
import {checkPackageForLeafWaAndPa} from '../../../selectors/integration/leafSelectors';
import {checkPackageForLabResults} from '../../../selectors/transfersSelectors';
import * as biotrackSelectors from '../../../selectors/integration/biotrackSelectors';
import productSubTypes from '../../../constants/salesOrderMetaData';

const _findInventoryItemByPackageCode = (formData, package_code) => {
  let item = false;
  Object.keys(formData.inventoryItems).forEach((key) => {
    if (item) return;
    const inventoryItems = formData.inventoryItems[key];
    item = inventoryItems.find(inventoryItem => inventoryItem.package_code == package_code);
  });

  // If not found - search in pre-packs:
  if (!item) {
    Object.keys(formData.childItemMasters).forEach((key) => {
      if (item) return;
      const childItemMasters = formData.childItemMasters[key];
      childItemMasters.forEach(childItemMaster => {
        if (item) return;
        item = childItemMaster.items.find(item => item.package_code == package_code);
      });
    });
  }
  return item;
};

const _checkPackageTestResultsForIsolocity = (selectedItem, props) => {
  const {inventoryItemsTestResults, formData} = props;
  if (!inventoryItemsTestResults || !selectedItem || !selectedItem.package_code) return false;
  const item = _findInventoryItemByPackageCode(formData, selectedItem.package_code);
  if (!item) return false;

  const itemResults = Object.values(inventoryItemsTestResults).find(
    i => i.item_id == parseInt(item.id)
  );

  const isolocityStatus = get(itemResults, 'lab_result.isolocity_status');
  const isolocityKey = 'constants.integration.isolocity.statuses';
  const isolocity_status_in_progress = get(props, `${isolocityKey}.in_progress`, 'in progress');
  const isolocity_status_passed = get(props, `${isolocityKey}.passed`, 'passed');

  let errorText;

  if (!isolocityStatus) {
    errorText = I18n.t('cultivation.transfers.form.isolocity.noLabResults');
  } else if (isolocityStatus === isolocity_status_in_progress) {
    errorText = I18n.t('cultivation.transfers.form.isolocity.pendingLabResults');
  } else if (isolocityStatus !== isolocity_status_passed) {
    errorText = I18n.t('cultivation.transfers.form.isolocity.failedLabResults');
  }

  return errorText;
};

const _checkPackageTestResultsForLeaf = (selectedItem, props, lineMeta) => {
  const {inventoryItemsTestResults, orders, formData} = props;
  if (!selectedItem || !selectedItem.package_code) return false;
  const item = _findInventoryItemByPackageCode(formData, selectedItem.package_code);
  const itemMaster = formData.itemMasters &&
    formData.itemMasters.find(itemMaster => item.item_master_id == itemMaster.id);

  if (!item) return false;
  const packageData = {
    testResults: inventoryItemsTestResults,
    meta: lineMeta,
    productSubTypes,
    salesOrder: orders.length > 0 ? orders[0] : {},
    item,
    selectedItem,
    itemMaster
  };
  return checkPackageForLeafWaAndPa(packageData, props);
};

/**
 *  Generic check for lab results
 * @param selectedItem
 * @param props
 * @param lineMeta
 * @returns {*}
 * @private
 */
const _checkPackageTestResults = (selectedItem, props, lineMeta) => {
  const {inventoryItemsTestResults, orders, formData, distributionSettings, utahCultivatorEnhancementsEnabled} = props;
  if (!selectedItem || !selectedItem.package_code) return false;
  const item = _findInventoryItemByPackageCode(formData, selectedItem.package_code);
  if (!item) return false;

  const packageData = {
    testResults: inventoryItemsTestResults,
    meta: lineMeta,
    productSubTypes,
    salesOrder: orders.length > 0 ? orders[0] : {},
    item,
    selectedItem,
    distributionSettings,
    utahCultivatorEnhancementsEnabled
  };
  return checkPackageForLabResults(packageData);
};

/**
 *
 * @param selectedItem
 * @param props
 * @param lineMeta
 * @returns {boolean}
 * @private
 */
const _validateLabResultsForBiotrack = (selectedItem, props, lineMeta) => {
  const {inventoryItemsTestResults, orders, formData} = props;

  if (!selectedItem || !selectedItem.package_code) return false;
  const item = _findInventoryItemByPackageCode(formData, selectedItem.package_code);

  return biotrackSelectors.checkIsPackageIsValid({
    testResults: inventoryItemsTestResults,
    meta: lineMeta,
    productSubTypes,
    salesOrder: orders.length > 0 ? orders[0] : {},
    item,
  });
};

const _testPackageTestResults = (selectedPackage, props, lineMeta) => {
  const { integrationState } = props;
  const { isLeaf, isBiotrack } = integrationState;

  if (isLeaf) {
    return _checkPackageTestResultsForLeaf(selectedPackage, props, lineMeta);
  } else if (isBiotrack) {
    return _validateLabResultsForBiotrack(selectedPackage, props, lineMeta);
  } else {
    //Generic check for lab results
    return _checkPackageTestResults(selectedPackage, props, lineMeta);
  }
};


export const validate = (values, props) => {
  const { integrationState } = props;
  const { isBiotrack, isPaLeaf, isMetrc, isMetrcTransfersEnabled } = integrationState;

  const errors = {
    destinations: [],
    date_transferred: requiredFieldValidation(values.date_transferred),
    status: requiredFieldValidation(values.status),
    lines: validateLines(values, props),
  };

  if (isPaLeaf) {
    const haveDriver = (index) => {
      if (!Array.isArray(values.drivers)) return I18n.t('cultivation.transfers.form.name');
      if (values.drivers.length < index) return I18n.t('cultivation.transfers.form.name');
      return requiredFieldValidation(values.drivers[index].id, 'cultivation.transfers.form.name');
    };
    errors.drivers = [{}, {}];
    errors.drivers[0].id = haveDriver(0);
    errors.drivers[1].id = haveDriver(1);
    errors.vehicle_id = requiredFieldValidation(values.vehicle_id, 'cultivation.transfers.form.description');
  }

  if (isBiotrack) {
    errors.drivers = (values.drivers || []).map((driver, index) => {
      if (props.integrationState.isPrBiotrack && index > 0) {
        return {};
      }
      return {
        id: requiredFieldValidation(driver.id, 'cultivation.transfers.form.name'),
      };
    });
    errors.vehicle_id = requiredFieldValidation(values.vehicle_id, 'cultivation.transfers.form.description');
  }

  if (isMetrc && isMetrcTransfersEnabled) {
    errors.integration_transfer_type = requiredFieldValidation(values.integration_transfer_type);
  }

  if (values.destinations) {
    values.destinations.map((dist) => {
      const obj = {};
      if (dist) {
        obj.partner_facility_id = requiredFieldValidation(dist.partner_facility_id, 'cultivation.transfers.form.partner');
        if (props.integrationState.isLeaf) {
          obj.departure_time = requiredFieldValidation(dist.departure_time, 'cultivation.transfers.form.departureTime');
          obj.directions = requiredFieldValidation(dist.directions, 'cultivation.transfers.form.directions');
        }
        if (props.integrationState.isBiotrack) {
          obj.departure_time = requiredFieldValidation(dist.departure_time, 'cultivation.transfers.form.departureTime');
          obj.arrival_time = requiredFieldValidation(dist.arrival_time, 'cultivation.transfers.form.departureTime');
        }

        if (isMetrc && isMetrcTransfersEnabled) {
          obj.integration_transporter_id = requiredFieldValidation(dist.integration_transporter_id, 'cultivation.transfers.form.partner');
        }

        errors.destinations.push(obj);
      }
    });
  }

  const isEditMode = !!props.transferId;
  if (props.isWaLeaf && values.date_transferred) {
    if (isEditMode) {
      const createdAt = props.formData && props.formData.transfer && props.formData.transfer.created_at;
      if (createdAt) {
        const localCreatedAt = convertDbToClientTZ(createdAt);
        const localCreatedDate = localCreatedAt.clone().startOf('day');
        const dayAfterCreatedDate = localCreatedDate.clone().add(1, 'days');
        const isSameOrBeforeCreatedDate = moment(values.date_transferred).isSameOrBefore(localCreatedDate);
        const isDayAfterCreatedDate = moment(values.date_transferred).isSame(dayAfterCreatedDate);
        if (isSameOrBeforeCreatedDate) {
          errors.date_transferred = I18n.t('cultivation.transfers.form.washington24HourHoldDate');
        }
        else if (isDayAfterCreatedDate && values.destinations && values.destinations.length) {
          const localCreatedAtHours = localCreatedAt.hour();
          const localCreatedAtMinutes = localCreatedAt.minute();
          values.destinations.forEach((destination, index) => {
            const departureTime = destination.departure_time;
            const arrivalTime = destination.arrival_time;
            if (departureTime) {
              const hours = departureTime.hour ? departureTime.hour() : 24;
              const minutes = departureTime.minute ? departureTime.minute() : 59;
              if (hours < localCreatedAtHours || (hours === localCreatedAtHours && minutes < localCreatedAtMinutes)) {
                errors.destinations[index].departure_time = I18n.t('cultivation.transfers.form.washington24HourHoldTime');
              }
            }
            if (arrivalTime) {
              const hours = arrivalTime.hour ? arrivalTime.hour() : 24;
              const minutes = arrivalTime.minute ? arrivalTime.minute() : 59;
              if (hours < localCreatedAtHours || (hours === localCreatedAtHours && minutes < localCreatedAtMinutes)) {
                errors.destinations[index].arrival_time = I18n.t('cultivation.transfers.form.washington24HourHoldTime');
              }
            }
          });
        }
      }
    }
    else {
      const today = getToday();
      const tomorrow = today.clone().add(1, 'days');
      const isBeforeTomorrow = moment(values.date_transferred).isBefore(tomorrow);
      const isTomorrow = moment(values.date_transferred).isSame(tomorrow);
      if (isBeforeTomorrow) {
        errors.date_transferred = I18n.t('cultivation.transfers.form.washington24HourHoldDate');
      }
      else if (isTomorrow && values.destinations && values.destinations.length) {
        const now = moment();
        const hoursNow = now.hour();
        const minutesNow = now.minute();
        values.destinations.forEach((destination, index) => {
          const departureTime = destination.departure_time;
          const arrivalTime = destination.arrival_time;
          if (departureTime) {
            const hours = departureTime.hour ? departureTime.hour() : 24;
            const minutes = departureTime.minute ? departureTime.minute() : 59;
            if (hours < hoursNow || (hours === hoursNow && minutes < minutesNow)) {
              errors.destinations[index].departure_time = I18n.t('cultivation.transfers.form.washington24HourHoldTime');
            }
          }
          if (arrivalTime) {
            const hours = arrivalTime.hour ? arrivalTime.hour() : 24;
            const minutes = arrivalTime.minute ? arrivalTime.minute() : 59;
            if (hours < hoursNow || (hours === hoursNow && minutes < minutesNow)) {
              errors.destinations[index].arrival_time = I18n.t('cultivation.transfers.form.washington24HourHoldTime');
            }
          }
        });
      }
    }
  }

  return errors;
};


/**
 * Method which validates transfer packages
 * @param values
 * @param props
 * @returns {*}
 */
const validateLines = (values, props) => {
  const lines = get(values, 'lines', []);
  const params = { props, values, inventoryTotal: getTotalOfInventory(lines) };
  const handle = (line, index) => validateLine(index, line, params);

  return lines.map(handle);
};

/**
 * Method which validates one package
 * @param line - means package
 * @param params
 * @returns {*}
 */
const validateLine = (index, line, params) => {
  params.line = line;
  params.packageLocations = [];

  if (!get(line, 'inventory.length')) {
    return { inventory: [] };
  }

  return {
    inventory: line.inventory.map((inventory, inventoryIndex) => validateInventory(inventoryIndex, inventory, params, index))
  };
};

/**
 *  This function is designed to take the common formData payload
 *  and extract the available packages to choose from based on the item_master_id
 *  this is made more complicated by pre-packs having a parent and sub product.
 *
 *  @param formData {}
 *  @param line_inventory {}
 *  @returns packages [{}]
 */
const searchForPackages = (formData, inventory) => {
  const { inventoryItems, childItemMasters } = formData;
  const item_master_id = inventory.item_master_id;
  const available_inventory = inventoryItems[item_master_id] || [];

  // Find the `sales_order_line` this `transfer_inventory_line` is trying to fulfill
  const lines = formData.lines;
  const line_item_id = inventory.sales_order_line_id;
  const line = lines.find( (line) => { return line.id === line_item_id; }) || {};

  // Since there are 2 different product type (discrete/bulk and prepack)
  // we have two different formData keys that store available packages.
  // The easier of the two is discrete/bulk, where we just retrieve all packages based on an item master,
  // if we find any packages by item master, then it's not prepack and can return results.
  if (available_inventory.length) {
    return available_inventory;
  }

  // If we cannot find packages in the `inventoryItems` key (meaning it's a prepack).
  // we need to do more detailed filtering for the prepack options.
  // **** NOTE ****
  // the `item_master_id` can change from the parent product to the sub-product
  // on the `inventory_line` object from UI actions taken, so we cannot rely on the
  // `item_master_id` on the `inventory_line` object.
  // we need to retrieve the `item_master_id` from the actual `sales_order_line`.
  // then with that parent `item_master_id` and the `fulfillment_unit` we can find the
  // subproduct and retrieve the `packages` from the subproduct `items` array.
  const line_item_master_id = line.item_master_id;
  const prepack_unit = inventory.prepack_fulfillment_units;

  const sub_item_masters = childItemMasters[line_item_master_id] || [];
  const sub_item_master = sub_item_masters.length ? sub_item_masters.find( (item) => { return item.prepack_fulfillment_units === prepack_unit; }) : null;
  const available_child_inventory = (sub_item_master) ? sub_item_master.items : [];

  return available_child_inventory;
};

/**
 * Validate only inventory in package
 * @param inventory
 * @param params
 * @returns {{qty: *, inventory_item_id: *, hybrid_location_id: *}}
 */
const validateInventory = (inventoryIndex, inventory, params, lineIndex) => {
  const { line, props, values, packageLocations, inventoryTotal } = params;
  const { formData, integrationState, needTestResults, isAllowNegativeInventory, orders, transferId, isSplitPackageRedirectToggled } = props;
  const { isBiotrack, isIsolocity, isMetrc, hasIntegrator, isMetrcTransfersEnabled } = integrationState;

  const package_key = 'lines[' + lineIndex + '].inventory[' + inventoryIndex + '].package_code';
  const result = {
    qty: requiredFieldValidation(inventory.qty),
    inventory_item_id:requiredFieldValidation(inventory.inventory_item_id),
    hybrid_location_id: requiredFieldValidation(inventory.hybrid_location_id),
  };

  if ((values.is_waste_disposal) || (line.is_waste_disposal && !inventory.disposal_reason)) {
    result.disposal_reason = requiredFieldValidation(inventory.disposal_reason || values.disposal_reason);
  }

  if (inventory.qty && parseFloat(inventory.qty) > inventory.qty_available && !isAllowNegativeInventory) {
    if (values.form_type && values.form_type === 'modify_form') {
      if (!(values.initial_status && values.initial_status === 'out_for_delivery' && values.status === 'completed')) {
        result.qty = I18n.t('cultivation.transfers.form.exceedQuantityError');
      }
    } else {
      result.qty = I18n.t('cultivation.transfers.form.exceedQuantityError');
    }

  } else if (isBiotrack && inventory.qty_total !== undefined && Number(inventory.qty_available) !== Number(inventory.qty_total)) {
    result.qty = I18n.t('cultivation.transfers.form.wholeAmountError');
  } else if (!isAllowNegativeInventory && get(line, 'inventory.length', 0) !== 1 && !isValidPackageQty(getPackageQty(line))) {
    result.qty = I18n.t('cultivation.transfers.form.exceedQuantityError');
  }

  result.package_code = !line.lot_tracked && !line.is_medicated ? '' : requiredFieldValidation(inventory.package_code) ||
    (
      inventoryTotal > props.transferLinesLimit && props.transferLinesLimit ?
        I18n.t('cultivation.transfers.form.maxItemsWithIntegratedSoError', {maxLine: props.transferLinesLimit}) :
        null
    );

  // Showing messages for Leaf/Biotrack if the selected package doesn't succeed of requirements
  if ((needTestResults && !result.package_code)) {
    const testResultsMessage = _testPackageTestResults(inventory, props, line.meta);

    if (typeof testResultsMessage === 'string') {
      result.package_code = I18n.t('cultivation.transfers.checkLabResults.' + testResultsMessage, { packageCode: inventory.package_code });
    }
  }

  if (isIsolocity && !hasIntegrator) {
    const testResultsMessage = _checkPackageTestResultsForIsolocity(inventory, props);

    if (testResultsMessage) {
      result.package_code = testResultsMessage;
    }
  }

  // With MetrcTransfers the packages must match the sent quantity exactly until
  // the submit workflow is automated, which it should essentially do what manual splitting
  // does, only synchronously before submitting the request for the transfer template.
  if (isMetrc && isMetrcTransfersEnabled && isSplitPackageRedirectToggled) {
    const sales_order = orders[0];
    if (sales_order) {
      const requiredQuantity = (inventory.qty) ? parseFloat(inventory.qty) : 0;
      const so_id = sales_order.id;
      const availablePackages = searchForPackages(formData, inventory);

      const matchingPackages = (availablePackages && availablePackages.length) ? availablePackages.filter( (item) => { return requiredQuantity === (item.qty - (item.qty_reserved || 0)); }) : [];
      const splitPackages = (availablePackages && availablePackages.length) ? availablePackages.filter( (item) => { return requiredQuantity < (item.qty - (item.qty_reserved || 0)); }) : [];

      const redirect = (transferId) ? '/transfers/modify/' + transferId + '?package_key=' + package_key : '/transfers/create?so_num=' + so_id + '&package_key=' + package_key;

      // generate the package split query string, we will generate a redirect location for completion
      // of the form, to send us back to the "create" or "modify" form they came from.
      const link = <a href={'/split_package?package_code=' + inventory.package_code + '&quantity=' + requiredQuantity + '&redirect=' + encodeURIComponent(redirect)}>{I18n.t('cultivation.transfers.form.packageSizeSplitClick')}</a>;

      // Compile the packages that exactly match the quantity needed and packages large enough to be split into the
      // desired quantity for generating the most clear error message and directing the user to the quickest
      // resolution
      const matchingPackageCodes = (matchingPackages && matchingPackages.length) ? matchingPackages.map((item) => { return item.package_code; }).slice(0,4).join(', ') : null;
      const splitPackageCodes = (splitPackages && splitPackages.length) ? splitPackages.map((item) => { return item.package_code; }).slice(0,4).join(', ') : null;

      //====  DETERMINE ERROR MESSAGES  ====
      // If packages match the line items required inventory, we want to tell the user those packages can be selected
      // If we don't have any matching packages, but the current package can be split, we will give a link to split it
      // If we have packages that are capable of being split to fulfill requirements, show them the available packages that can be split
      // Lastly, no resolution is possible without creating new inventory and we show the generic message.
      const packageTooLarge = <div style={{marginBottom: '5px'}}>{I18n.t('cultivation.transfers.form.packageSizeTooLarge', {required: requiredQuantity})}</div>;
      const packageTooSmall = <div style={{marginBottom: '5px'}}>{I18n.t('cultivation.transfers.form.packageSizeTooSmall', {required: requiredQuantity})}</div>;
      const packageMatches = <div style={{marginBottom: '5px'}}>{I18n.t('cultivation.transfers.form.packageSizeMatching')} <div>{matchingPackageCodes}</div></div>;
      const packageSplitOptional = <div style={{marginBottom: '5px'}}>{link} {I18n.t('cultivation.transfers.form.packageSizeSplitOptional')}</div>;
      const packageSplit = <div style={{marginBottom: '5px'}}>{I18n.t('cultivation.transfers.form.packageSizeSplitable')} <div>{splitPackageCodes}</div></div>;

      if (requiredQuantity < inventory.qty_available) {
        if (matchingPackageCodes) {
          result.package_code = <div>{packageTooLarge} {packageMatches} {packageSplitOptional}</div>;
        } else {
          result.package_code = <div>{packageTooLarge} {link} {I18n.t('cultivation.transfers.form.packageSizeSplit')}</div>;
        }
      } else if (requiredQuantity > inventory.qty_available) {
        result.package_code = <div>{packageTooSmall}</div>;
        if (matchingPackageCodes) {
          result.package_code = <div>{packageTooSmall} {packageMatches}</div>;
        } else if (splitPackageCodes) {
          result.package_code = <div>{packageTooSmall} {packageSplit}</div>;
        }
      }
    }
  }

  if (inventory.inventory_item_id && inventory.package_code) {
    if (packageLocations.find(packageLocation => packageLocation.inventory_item_id === inventory.inventory_item_id
      && packageLocation.package_code === inventory.package_code)) {
      result.inventory_item_id = I18n.t('cultivation.transfers.form.duplicatePackageLocationError');
    }
    packageLocations.push({inventory_item_id: inventory.inventory_item_id, package_code: inventory.package_code});
  }

  return result;
};


const getTotalOfInventory = (lines) => (
  lines.reduce((acc, line) => acc + get(line, 'inventory.length', 0), 0)
);

const isValidPackageQty = (package_qty) => {
  if (Object.keys(package_qty).length !== 0) {
    for (const package_code in package_qty) {
      const inventory_package = package_qty[package_code];

      if (inventory_package.qty_ordered > inventory_package.qty_available) {
        return false;
      }
    }
  }

  return true;
};
/**
 * Get all selected qty for each package
 *
 * @param line
 */
const getPackageQty = (line) => {
  const package_qty = {};

  get(line, 'inventory', []).forEach(inventory => {
    const package_code = get(inventory, 'package_code', '');

    if (get(package_qty, package_code, false)) {
      package_qty[package_code].qty_ordered += parseFloat(get(inventory, 'qty', 0));
    } else {
      package_qty[package_code] = {
        qty_ordered: parseFloat(get(inventory, 'qty', 0)),
        qty_available: parseFloat(get(inventory, 'qty_available', 0))
      };
    }
  });

  return package_qty;
};

export default validate;
