import React from 'react';
import PropTypes from 'prop-types';
import ProductRow from './ProductRow';

const ProductRows = (props) => {

  const spreadProps = {
    refundQueue: props.refundQueue,
    restockQueue: props.restockQueue,
    onRefundClick: props.onRefundClick,
    onRestockClick: props.onRestockClick,
    refundedOrderIds: props.refundedOrderIds,
    refundedInventoryItems: props.refundedInventoryItems,
    canRefundOrder: props.canRefundOrder
  };

  const orderProductIdCounter = {};

  return (
    <tbody>
    {
      props.data.reduce( (acc, product, index) => {
        const idx = index + 1;
        if(idx >= props.first && idx <= props.last) {
          acc.push(product);
          return acc;
        }
        return acc;
      }, []).map( (product, index) => {

        // Uses the frequency of the product line id (order_product_id) to determine the index to the inventory line
        const inventoryIndex = orderProductIdCounter[product.order_product_id] === undefined
          ? 0
          : orderProductIdCounter[product.order_product_id].length;
        if(orderProductIdCounter[product.order_product_id] === undefined){
          orderProductIdCounter[product.order_product_id] = [1];
        } else {
          orderProductIdCounter[product.order_product_id].push(1);
        }
        return (
          <ProductRow
            refundOrderInitiated={props.refundOrderInitiated}
            restockOrderInitiated={props.restockOrderInitiated}
            canPrintLabels={props.canPrintLabels}
            showSingleLabelModal={props.showSingleLabelModal}
            prepackWeights={props.prepackWeights}
            onRestockProduct={props.onRestockProduct}
            getDetailedOrder={props.getDetailedOrder}
            key={index}
            index={index}
            inventoryIndex={inventoryIndex}
            data={product}
            order={props.order}
            taxes={props.taxes}
            integrationState={props.integrationState}
            nonMedicatedCategoryId={props.nonMedicatedCategoryId}
            refundOrders={props.refundOrders}
            {...spreadProps}
          />
        );
      })
    }
    </tbody>
  );

};

ProductRows.propTypes = {
  onRestockProduct: PropTypes.func.isRequired,
  first: PropTypes.number,
  last: PropTypes.number,
  data: PropTypes.array,
  refundQueue: PropTypes.object,
  restockQueue: PropTypes.array,
  onRefundClick: PropTypes.func,
  onRestockClick: PropTypes.func,
  refundedOrderIds: PropTypes.array,
  getDetailedOrder: PropTypes.func,
  order: PropTypes.object,
  taxes: PropTypes.array,
  index: PropTypes.number,
  prepackWeights: PropTypes.array.isRequired,
  refundedInventoryItems: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
  nonMedicatedCategoryId: PropTypes.number,
  refundOrders: PropTypes.array,
  showSingleLabelModal: PropTypes.func.isRequired,
  canPrintLabels: PropTypes.bool.isRequired,
  refundOrderInitiated: PropTypes.bool,
  restockOrderInitiated: PropTypes.bool,
  canRefundOrder: PropTypes.bool.isRequired
};

export default ProductRows;
