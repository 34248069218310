import {requiredFieldValidation, betweenValidation, minValidation} from '../../common/form/redux-form/validations';

const validate = values => {

  const errors = {};
  const skipValidationOnAction = ['confirmDelete', 'delete'];
  if(skipValidationOnAction.indexOf(values.nextAction) !== -1) return errors;

  const rewardsValidationFields = ['min_visit_frequency', 'min_visit_frequency_days', 'min_accrued_points', 'min_total_spend'];

  errors.customerGroups = values.customerGroups.map(item => {

    const baseValidation = { // non rewards validation
      group_name: requiredFieldValidation(item.group_name, 'retail.createCustomerGroups.groupName'),
      standard_discount_percent: requiredFieldValidation(item.standard_discount_percent, 'retail.createCustomerGroups.standard') || betweenValidation(item.standard_discount_percent, 'retail.createCustomerGroups.standard', 0, 100),
    };

    if(!item.is_rewards_program_group) return baseValidation;

    let sum = 0;
    rewardsValidationFields.forEach((field) => {
      sum += parseFloat(item[field]);
      baseValidation[field] = minValidation(parseFloat(item[field]), `retail.createCustomerGroups.${field}`, 0);
    });

    if(sum === 0){
      rewardsValidationFields.forEach((field) => {
        baseValidation[field] = 'One of the rewards fields must be non zero.';
      });
    }

    return baseValidation;
  });

  return errors;
};



export default validate;
