import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Button, ButtonToolbar, Col, Row} from 'react-bootstrap';
import {FieldArray} from 'redux-form';

import FacilitySetting from './FacilitySetting';

const InternalTransfersSettingsForm = ({handleSubmit, submitting, isDisabled, reset}) => (
  <form onSubmit={handleSubmit}>
    <div className='text-muted'>
      {I18n.t('internalTransfersSettings.helpText')}
    </div>
    <br/>
    <FieldArray
      name='facilities'
      component={FacilitySetting}
      props={{
        isDisabled
      }}
    />
    <Row className='padding-top'>
      <Col xs={12}>
        <ButtonToolbar className='float-right'>
          <Button variant='default'
                  type='button'
                  onClick={() => reset()}
                  disabled={submitting}>
            {I18n.t('common.form.reset')}
          </Button>
          <Button variant='primary'
                  type='submit'
                  disabled={submitting}>
            {I18n.t('common.form.save')}
          </Button>
        </ButtonToolbar>
      </Col>
    </Row>
  </form>
);

InternalTransfersSettingsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  isDisabled: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired
};

export default InternalTransfersSettingsForm;
