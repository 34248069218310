import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import PermissionWrapper from './PermissionWrapper';

const PermissionLink = ({permissions, children, props}) => {
  return (<PermissionWrapper permissions={permissions}>
    <Link {...props}>
      {children}
    </Link>
  </PermissionWrapper>);
};

PermissionLink.propTypes = {
  permissions: PropTypes.array.isRequired,
  props: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node
  ]),
};

export default PermissionLink;
