import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Button, Col, Row} from 'react-bootstrap';
import {Field, reduxForm} from 'redux-form';
import MultiselectInput from '../../common/form/MultiselectInput';
import {PRODUCT_MULTI_SEARCH_FORM} from '../../../constants/forms';

const Toolbar = (props) => {

  const {filterData: {categories, subcategories, productsNames, pricingGroups, pricingClasses},
    handleSubmit, searching, integrationState} = props;

  return (
    <form onSubmit={handleSubmit} className='search-product' noValidate={true}>
        <Row>
          <Col sm={4} md={3}>
            <Field name='categories' component={MultiselectInput}
                   props={{
                     label: I18n.t('products.massModify.productListing.category'),
                     options: integrationState.isCanada ? categories
                       : integrationState.isWaLeaf ? categories.filter((category) => category.name !== 'Harvests')
                       : categories.filter((category) => category.name !== 'Fresh Marijuana'),
                     textKey: 'name',
                     valueKey: 'id',
                   }}/>
          </Col>
          <Col sm={4} md={3}>
            <Field name='subcategories' component={MultiselectInput}
                   props={{
                     label: I18n.t('products.massModify.productListing.subcategory'),
                     options: integrationState.isPaLeaf ? subcategories
                       : integrationState.isWaLeaf ? subcategories.filter((sub) => sub.subcategory_code !== 'OIL')
                       : subcategories.filter((sub) => sub.subcategory_code !== 'FINISHED_FLOWER'),
                     textKey: 'name',
                     valueKey: 'id',
                   }}/>
          </Col>
          <Col sm={4} md={3}>
            <Field name='productStatuses' component={MultiselectInput}
                   props={{
                     label: I18n.t('products.massModify.productStatus'),
                     options: [
                       {text: I18n.t('products.massModify.statuses.active'), value: 1},
                       {text: I18n.t('products.massModify.statuses.inactive'), value: 0}
                     ],
                   }}/>
          </Col>
          <Col sm={4} md={3}>
            <Field name='products' component={MultiselectInput}
                   props={{
                     label: I18n.t('products.massModify.productListing.name'),
                     options: productsNames,
                     textKey: 'name',
                     valueKey: 'id',
                   }}/>
          </Col>
        </Row>
        <Row>
          <Col sm={8} md={7} className='pricing-block'>
            <Row>
              <Col md={8}>
                <p>{I18n.t('products.massModify.pricingBlockTitle')}</p>
              </Col>
              <Col md={2}>
                <label>
                  <Field name='pricing_mode' component='input' type='radio' value='org'/>
                  &nbsp;{I18n.t('products.massModify.searchByOrg')}
                </label>
              </Col>
              <Col md={2}>
                <label>
                  <Field name='pricing_mode' component='input' type='radio' value='facility'/>
                  &nbsp;{I18n.t('products.massModify.searchByFacility')}
                </label>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Field name='pricing_groups' component={MultiselectInput}
                       props={{
                         label: I18n.t('products.massModify.productListing.pricingGroup'),
                         options: pricingGroups,
                         textKey: 'name',
                         valueKey: 'id',
                       }}/>
              </Col>
              <Col md={6}>
                <Field name='pricing_classes' component={MultiselectInput}
                       props={{
                         label: I18n.t('products.massModify.productListing.pricingClass'),
                         options: pricingClasses,
                         textKey: 'name',
                         valueKey: 'id',
                       }}/>
              </Col>
            </Row>
          </Col>
          <Col offset={2} md={3}>
            <Button type='submit' className='btn-primary search-button' disabled={searching}>
              {I18n.t('common.form.search')}
            </Button>
          </Col>
        </Row>
    </form>
  );
};

Toolbar.propTypes = {
  filterData: PropTypes.object.isRequired,
  searching: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  integrationState: PropTypes.object.isRequired,
};

export default reduxForm({
  form: PRODUCT_MULTI_SEARCH_FORM,
  initialValues: {
    products: [],
    subcategories: [],
    categories: [],
    pricing_groups: [],
    pricing_classes: [],
    pricing_mode: 'org',
    productStatuses: [{value: 1}]
  },
})(Toolbar);
