import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Table, Card} from 'react-bootstrap';

const FeedingScheduleDetails = ({model}) => {

  if (!model) return null;

  return (
    <Card>
      <Table striped className='feeding-schedule-details'>
        <thead>
        <tr>
          <th>Feeding Schedule: {model.schedule_name}</th>
        </tr>
        <tr>
          <th>{I18n.t('itemTransactions.ingredients')}</th>
        </tr>
        </thead>
        <tbody>
        {(model.ingredients || []).map((ingredient, index) => {
          return <tr key={index}><td>{ingredient.item_master && ingredient.item_master.name || 'Unspecified'}</td></tr>;
        })}
        {!model.ingredients || !model.ingredients.length ? I18n.t('plantHistory.noFeedingScheduleIngredients') : null}
        </tbody>
      </Table>
    </Card>
  );

};

FeedingScheduleDetails.propTypes = {
  model: PropTypes.object.isRequired,
};

export default FeedingScheduleDetails;
