import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Table} from 'react-bootstrap';

import OrderTransaction from './OrderTransaction';
import InternationalCurrencyStatic from '../common/form/InternationalCurrencyStatic';

const OrderTransactions = ({payments, timezone, alleavesMode}) => {

  const filtered_payments = payments.filter((payment) => payment.is_cancelled !== 1 && payment.payment_name !== 'Change');
  const change_payments = payments.filter((payment) => payment.is_cancelled !== 1 && payment.payment_name === 'Change');

  const total = filtered_payments.reduce((acc, payment) => acc + Number(payment.amount), 0);
  const totalTips = filtered_payments.reduce((acc, payment) => acc + Number(payment.tip), 0);
  const totalFees = filtered_payments.reduce((acc, payment) => acc + Number(payment.fee), 0);
  const totalIncludingFeesAndTips =  total + totalTips + totalFees;

  return (
    <div className='OrderTransactions form-inline totals'>
      <h2 style={{marginTop: '0'}}>{I18n.t('cart.OrderTransactions')}</h2>
      <Table>
        <tbody>
        {filtered_payments.map((payment) => {
          return (
            <OrderTransaction key={payment.id}
                              name={payment.payment_name}
                              type={payment.payment_type}
                              amount={payment.amount}
                              is_pending={payment.is_pending}
                              tip={payment.tip}
                              fee={payment.fee}
                              date={payment.updated_at}
                              alleavesMode={alleavesMode}
                              timezone={timezone}
                  />
          );
        })}

        {total !== totalIncludingFeesAndTips &&
          <tr className='coupon clearfix'>
            <td colSpan={3} style={{borderStyle:'none'}}>Total including fees and tips</td>
            <td style={{borderStyle:'none'}} className='text-right'>
              <InternationalCurrencyStatic>{totalIncludingFeesAndTips}</InternationalCurrencyStatic>
            </td>
          </tr>
        }

        {change_payments.map((payment) => {
          return (
            <OrderTransaction key={payment.id}
                              name={payment.payment_name}
                              type={payment.payment_type}
                              amount={payment.amount}
                              is_pending={payment.is_pending}
                              tip={payment.tip}
                              fee={payment.fee}
                              date={payment.updated_at}
                              timezone={timezone}
            />
          );
        })}

        </tbody>
      </Table>
    </div>
  );
};

OrderTransactions.propTypes = {
  payments: PropTypes.array.isRequired,
  timezone: PropTypes.string.isRequired,
  alleavesMode: PropTypes.string
};

export default OrderTransactions;
