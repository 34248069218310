import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import get from 'lodash.get';
import map from 'lodash.map';
import {Field} from 'redux-form';
import {Row, Col, Button, Popover} from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import {I18n} from 'react-redux-i18n';
import TextInput from '../common/form/TextInput';
import CheckBoxInput from '../common/form/CheckBoxInput';
import ReactSelectInput from '../common/form/ReactSelectInput';
import MultiselectInput from '../common/form/MultiselectInput';
import {getSalesRulesCategories, isDisabledSubcategory} from '../../selectors/forms/categoryFormSelectors';
import {getOhMetrcCategories, canShowOARRSColumnForCategory} from '../../selectors/integration/metrcSelectors';
import ModalWrapper from '../common/ModalWrapper';
import {canShowIsolocityColumnForCategory} from '../../selectors/integration/thirdPartyIntegrationSelectors';
import { canEditDisplayName, categorizationIsHidden } from './helpers';

export class SubCategory extends React.PureComponent {
  constructor(props) {
    super(props);
    this.addSubCategory = this.addSubCategory.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.onHideModal = this.onHideModal.bind(this);
    this.state = {
      showModal: false,
      skipCategories: [],
    };
  }

  componentWillReceiveProps(nextProps){
    const showModal = (JSON.stringify(nextProps.subcategoriesValues) !== JSON.stringify(this.props.subcategoriesValues))
      ? nextProps.subcategoriesValues.subcategories.find((sub) => !sub.id && !sub.active && this.state.skipCategories.indexOf(JSON.stringify(sub)) === -1)
      : false;
    this.updateSkipCategories(nextProps);
    if(!showModal) return false;
    this.setState({showModal: true});
  }

  updateSkipCategories(props){
    props.subcategoriesValues.subcategories.forEach((sub) => {
      const inverseSub = (Object.assign({}, sub, {active: !sub.active}));
      const index = this.state.skipCategories.indexOf(JSON.stringify(inverseSub));
      if(index !== -1) this.setState({skipCategories: this.state.skipCategories.filter((sub, idx) => idx !== index)});
    });
  }

  onClickDelete(){
    const index = this.props.subcategoriesValues.subcategories.reduce((acc, sub, index) => {
      if(acc > -1) return acc;
      return !sub.id && !sub.active && this.state.skipCategories.indexOf(JSON.stringify(sub)) === -1 ? index : -1;
    }, -1);
    this.props.fields.remove(index);
    this.onHideModal(false);
  }

  onHideModal(protectDismissedSubs = true){
    const newState = {showModal: false};
    if(protectDismissedSubs) {
      newState.skipCategories = this.props.subcategoriesValues.subcategories.reduce((acc, sub) => {
        if(!sub.active && !sub.id) acc.push(JSON.stringify(sub));
        return acc;
      }, []);
    }
    this.setState(newState);
  }

  addSubCategory(event) {
    const {fields, subcategoriesValues: {addSubCategory}, change, category} = this.props;
    event.preventDefault();
    fields.push({name: addSubCategory, subcategory_code: addSubCategory.toUpperCase(), active: true, cure_form_ids: [], product_counts: {active: 0, inactive: 0}});
    change(`${category}.addSubCategory`, '');
  }

  render() {
    const {
      fields, subcategoriesValues, category, change, lengthBan, setCategory, activeIntegrations, leafCategories, leaflyCategories,
      metrcCategories, isMedicated, rulesCategories, getColumnWidth, cureFormOptions, isHarvest, cureOrderTypeOptions,
      reportingMapping, getSalesRulesIndex, hasSalesRulesEnabled, biotrackCategories, apisicpaCategories, isMasterFacility,
      changeSharedList, hasManagedSharedInventory, weedmapsCategories, OhMetrcCategories, canShowOARRSColumnForCategory,
      isPlant, integrationState, activeCategory, isDisabledRow, isolocityCategories, canShowIsolocityColumnForCategory,
      ccaCategories, canAddSubCategories, subCategorySettings
    } = this.props;

    const { isApisicpa, isBiotrack, isCure, isLeaf, isMetrc, isLeafly, isWeedmaps, isCanada, isOklahoma, isIsolocity, isCca } = activeIntegrations;
    const isLeafExcludesSubcategoryCodes = ['WASTE', 'TEST_SAMPLE'];
    const buttonDisabled = !subcategoriesValues.addSubCategory;

    // Disallow custom subcategories in Plants.
    // For Leaf disallow custom subcategories in all categories.
    const showAddOption = canAddSubCategories
      ? !(isLeaf || subcategoriesValues.category_code.toLowerCase() === 'plants')
      : canAddSubCategories;

    const mappingLanguage = {
      required: <span className='text-info' style={{fontWeight: 'bold'}}>{I18n.t('categoryManagement.required')}</span>,
      optional: I18n.t('categoryManagement.optional'),
    };

    const newStyle = {
      position: 'absolute',
      fontSize: 'smaller',
      color: 'orange',
      top: '-15px',
      fontWeight: 'bold'
    };

    return (
      <div>
        <div>
          <ModalWrapper
            title={I18n.t('categoryManagement.removeSubcategoryTitle')}
            showModal={this.state.showModal}
            onHide={this.onHideModal}
            version={2}
            headerClass='bg-danger-dark'
            dialogClassName='modal-sm'
            okayButton={{show: true, text: I18n.t('categoryManagement.removeButtonText'), variant: 'danger', onClick: this.onClickDelete}}
            cancelButton={{show: true, text: I18n.t('categoryManagement.cancelButtonText'), variant: 'warning', onClick: this.onHideModal}}
          >
            <div style={{fontSize: 'larger'}}>
              {I18n.t('categoryManagement.removeSubcategoryText')}
            </div>
          </ModalWrapper>
        </div>
        {fields.map((subCategory, index) => {
          const subCat = subcategoriesValues.subcategories && subcategoriesValues.subcategories[index];
          if(!subCat) {
            return null;
          }

          const catAndSubCatKey = `${activeCategory.category_code}_${subCat.subcategory_code}`;

          if(categorizationIsHidden(catAndSubCatKey, subCategorySettings)){
            return null;
          }

          const activeSubCatsCount = subcategoriesValues.subcategories.filter((sub) => sub.active).length;
          const name = get(subCat, 'name', '');
          const productsAmount = get(subCat, 'product_counts.active', 0);

          if(isLeaf && isLeafExcludesSubcategoryCodes.indexOf(subCat.subcategory_code) !== -1) return null;
          if (integrationState.isWaLeaf && subCat.subcategory_code === 'OIL') return null;

          const filteredLeafCategories = leafCategories.filter(category => subCat.subcategory_code === 'FINISHED_FLOWER' || category.code !== 'finished_flower');
          const rowClass = isDisabledRow(subCat) ? 'disabled-row' : '';

          return (
            <Row key={`subCategory-${index}`} className={rowClass}>
              {!activeSubCatsCount && !this.state.showModal && <Popover id='popover' positionTop={-35} placement='top' props={{variant:'warning'}}>
                <span>{I18n.t('categoryManagement.lastOneWarning')}</span>
              </Popover>}
              <Col md={getColumnWidth(0, isMedicated)} className='checkbox-col' style={{position:'relative'}}>
                <Field
                  name={`${subCategory}.active`}
                  component={CheckBoxInput}
                  label={name}
                  disabled={productsAmount || isDisabledRow(subCat)} // cannot disable subcategory with products
                >
                  {!subCat.id ? <span style={newStyle}>{I18n.t('categoryManagement.newFlagText')}</span> : null}
                </Field>
              </Col>
              <Col md={getColumnWidth(1, isMedicated)} className='text-center'>
                {
                  productsAmount && <Button onClick={(e) => {
                    e.target.blur();
                    setCategory(subCat);
                  }} variant='link' disabled={isDisabledRow(subCat)}>
                    <span title='Active' className='text-success'>{productsAmount}</span>&nbsp;/&nbsp;
                    <span title='Inactive' className='text-danger'>{get(subCat, 'product_counts.inactive', 0)}</span>
                  </Button>
                }
              </Col>
              <Col md={getColumnWidth(2, isMedicated)}>
                <Field
                  name={`${subCategory}.display_name`}
                  component={TextInput}
                  props={{
                    onChange: (e) => lengthBan(e,`${subCategory}.display_name`, change),
                    disabled: !canEditDisplayName(catAndSubCatKey, subCategorySettings) || isDisabledRow(subCat),
                  }}
                />
              </Col>
              {isCure && isMedicated ? <Col md={getColumnWidth(3)}>
                <Field
                  name={`${subCategory}.cure_form_ids`}
                  component={MultiselectInput}
                  options={cureFormOptions}
                />
              </Col> : null}
              {isCure && isMedicated ? <Col md={getColumnWidth(4)}>
                <Field
                  name={`${subCategory}.cure_order_type_id`}
                  component={ReactSelectInput}
                  options={cureOrderTypeOptions}
                  props={{
                    placeholder: productsAmount && !isPlant ? mappingLanguage.required : mappingLanguage.optional,
                  }}
                />
              </Col> : null}
              {isBiotrack && isMedicated ? <Col md={getColumnWidth(3)}>
                <Field
                  name={`${subCategory}.biotrack_category_id`}
                  component={ReactSelectInput}
                  options={biotrackCategories}
                  textKey='name'
                  valueKey='id'
                  props={{
                    placeholder: productsAmount ? mappingLanguage.required : mappingLanguage.optional,
                  }}
                />
              </Col> : null}
              {isApisicpa && isMedicated ? <Col md={getColumnWidth(3)}>
                <Field
                  name={`${subCategory}.apisicpa_category_id`}
                  component={ReactSelectInput}
                  options={apisicpaCategories}
                  textKey='name'
                  valueKey='id'
                  props={{
                    placeholder: productsAmount ? mappingLanguage.required : mappingLanguage.optional,
                  }}
                />
              </Col> : null}
              {integrationState.isPaLeaf && isMedicated ? <Col md={getColumnWidth(3)}>
                <Field
                  name={`${subCategory}.leaf_category_id`}
                  component={ReactSelectInput}
                  options={filteredLeafCategories}
                  textKey='name'
                  valueKey='id'
                  props={{
                    placeholder: productsAmount ? mappingLanguage.required : mappingLanguage.optional,
                    disabled: subCat.disabledMapping
                  }}
                />
              </Col> : null}
              {isLeafly ? <Col md={getColumnWidth(3)}>
                <Field
                  name={`${subCategory}.leafly_category_id`}
                  component={ReactSelectInput}
                  options={leaflyCategories}
                  textKey='name'
                  valueKey='id'
                  props={{
                    placeholder: productsAmount ? mappingLanguage.required : mappingLanguage.optional,
                    disabled: subCat.disabledMapping
                  }}
                />
              </Col> : null}

              {
                isCca && (
                  <Col md={getColumnWidth(3)}>
                    <Field
                      name={`${subCategory}.cca_category_id`}
                      component={ReactSelectInput}
                      options={ccaCategories}
                      textKey='display_name'
                      valueKey='id'
                      props={{
                        placeholder: productsAmount ? mappingLanguage.required : mappingLanguage.optional,
                        disabled: subCat.disabledMapping
                      }}
                    />
                  </Col>
                )
              }

              {isMetrc && isMedicated && (integrationState.isCoMetrc || integrationState.isAkMetrc || !isHarvest) ? <Col md={getColumnWidth(3)}>
                <Field
                  name={`${subCategory}.metrc_item_category_id`}
                  component={ReactSelectInput}
                  options={metrcCategories}
                  disabled={Boolean(productsAmount && subCat.locked)}
                  textKey='name'
                  valueKey='id'
                  props={{
                    placeholder: productsAmount ? mappingLanguage.required : mappingLanguage.optional,
                  }}
                />
              </Col> : null}
              {(canShowOARRSColumnForCategory) && (
                <Col md={3}>
                  <Field
                    name={`${subCategory}.ohmetrc_category_id`}
                    component={ReactSelectInput}
                    options={OhMetrcCategories}
                    props={{
                      placeholder:  productsAmount ? mappingLanguage.required : mappingLanguage.optional,
                    }}
                  />
                </Col>
              )}
              {isWeedmaps ? <Col md={getColumnWidth(3)}>
                <Field
                  name={`${subCategory}.weedmaps_category_id`}
                  component={ReactSelectInput}
                  options={weedmapsCategories}
                  textKey='name'
                  valueKey='id'
                  props={{
                    placeholder: productsAmount ? mappingLanguage.required : mappingLanguage.optional,
                  }}
                />
              </Col> : null}
              {(isIsolocity && canShowIsolocityColumnForCategory) && (
                <Col md={getColumnWidth(3)}>
                <Field
                  name={`${subCategory}.specification_id`}
                  component={ReactSelectInput}
                  options={isolocityCategories}
                  textKey='name'
                  valueKey='id'
                  props={{
                    placeholder: productsAmount ? mappingLanguage.required : mappingLanguage.optional,
                    disabled: subCat.disabledMapping
                  }}
                />
              </Col>
              )}
              {isCanada && isMedicated ? <Col md={getColumnWidth(3)}>
                <Field
                  name={`${subCategory}.canada_category_id`}
                  component={ReactSelectInput}
                  options={reportingMapping}
                  textKey='name'
                  valueKey='id'
                  props={{
                    placeholder: productsAmount ? mappingLanguage.required : mappingLanguage.optional,
                  }}
                />
              </Col> : null}
              {!isMedicated && <Col md={getColumnWidth(4, isMedicated)} className='checkbox-col' style={{position:'relative'}}>
                <Field
                  name={`${subCategory}.is_shared`}
                  component={CheckBoxInput}
                  disabled={!isMasterFacility || !hasManagedSharedInventory}
                  props={{
                    style:{marginLeft: '20px'},
                    onChange: (event) => {
                      const {target: {checked}} = event;
                      change(`${subCategory}.is_shared`, checked);
                      changeSharedList(subCat.id, checked);
                    },
                  }}
                />
              </Col>}
              {isOklahoma && activeCategory.category_code === 'CONCENTRATE' && subCat.subcategory_code !== 'CONCENTRATE_COMBINED' ? <Col md={getColumnWidth(3)}>
                <Field
                  name={`${subCategory}.oklahoma_category_id`}
                  component={ReactSelectInput}
                  options={reportingMapping}
                  textKey='name'
                  valueKey='id'
                  props={{
                    placeholder: productsAmount ? mappingLanguage.required : mappingLanguage.optional,
                  }}
                />
              </Col> : null}
              {hasSalesRulesEnabled ? <Col md={getColumnWidth(getSalesRulesIndex())}>
                <Field
                  name={`${subCategory}.sales_limit_category_key`}
                  component={ReactSelectInput}
                  options={rulesCategories}
                  textKey='text'
                  valueKey='value'
                  props={{
                    placeholder: productsAmount ? mappingLanguage.required : mappingLanguage.optional,
                  }}
                />
              </Col> : null}
            </Row>
          );
        })}
        {showAddOption &&
          <Row>
            <Col md={4}>
              <Row>
                <Col md={8}>
                  <Field
                    name={`${category}.addSubCategory`}
                    component={TextInput}
                    props={{
                      onChange: (e) => lengthBan(e, `${category}.addSubCategory`, change)
                    }}/>
                </Col>
                <Col md={4}>
                  <div className='float-left add-item'>
                    <Button
                      disabled={buttonDisabled}
                      onClick={(event) => this.addSubCategory(event)}
                      variant='primary' size='sm'><FaPlus /></Button>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={12}>
              <div className='text-muted' style={{position:'relative', top: '-8px'}}>
                {I18n.t('categoryManagement.removeSubcategoryNote')}
              </div>
            </Col>
          </Row>
        }
      </div>
    );
  }
}

SubCategory.propTypes = {
  fields: PropTypes.object.isRequired,
  subcategoriesValues: PropTypes.shape({
    name: PropTypes.string.isRequired,
    subcategories: PropTypes.array.isRequired
  }).isRequired,
  category: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  setCategory: PropTypes.func.isRequired,
  lengthBan: PropTypes.func.isRequired,
  activeIntegrations: PropTypes.object.isRequired,
  biotrackCategories: PropTypes.array.isRequired,
  apisicpaCategories: PropTypes.array.isRequired,
  leafCategories: PropTypes.array.isRequired,
  leaflyCategories: PropTypes.array,
  metrcCategories: PropTypes.array.isRequired,
  rulesCategories: PropTypes.array,
  OhMetrcCategories: PropTypes.array,
  getColumnWidth: PropTypes.func,
  isMedicated: PropTypes.bool.isRequired,
  isRetailFacility: PropTypes.bool,
  showRuleCategories: PropTypes.bool,
  cureFormOptions: PropTypes.array.isRequired,
  cureOrderTypeOptions: PropTypes.array.isRequired,
  reportingMapping: PropTypes.array.isRequired,
  getSalesRulesIndex: PropTypes.func.isRequired,
  hasSalesRulesEnabled: PropTypes.bool.isRequired,
  isMasterFacility: PropTypes.bool,
  canShowOARRSColumnForCategory: PropTypes.bool,
  isHarvest: PropTypes.bool,
  isPlant: PropTypes.bool,
  changeSharedList: PropTypes.func,
  hasManagedSharedInventory: PropTypes.bool,
  weedmapsCategories: PropTypes.array,
  integrationState: PropTypes.object,
  isDisabledRow: PropTypes.func,
  isolocityCategories: PropTypes.array,
  canShowIsolocityColumnForCategory: PropTypes.bool,
  ccaCategories: PropTypes.array,
  canAddSubCategories: PropTypes.bool,
  subCategorySettings: PropTypes.array,
};

function mapStateToProps(state, ownProps) {
  return {
    rulesCategories: getSalesRulesCategories(state),
    canShowOARRSColumnForCategory: canShowOARRSColumnForCategory(state)(ownProps.activeCategory.category_code),
    canShowIsolocityColumnForCategory:  canShowIsolocityColumnForCategory(state)(ownProps.activeCategory.category_code),
    OhMetrcCategories: map(getOhMetrcCategories(state), (category) => ({
      value: category.id,
      text: category.name,
    })),
    isDisabledRow: (subcategory) => isDisabledSubcategory(state, subcategory)
  };
}

export default connect(mapStateToProps)(SubCategory);
