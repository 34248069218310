import {createSelector} from 'reselect';
import {formValueSelector} from 'redux-form';
import {COMPLETE_PROCESSING_FORM} from '../../constants/forms';
import {getProducts} from '../productsSelectors';
import {getInventoryType} from '../integration/biotrackCategoriesSelectors';

const formSelector = formValueSelector(COMPLETE_PROCESSING_FORM);
export const getFormOutputs = state => formSelector(state, 'outputs');

const getIndex = (_, props) => props && props.index;

const getOutput = createSelector(
  [getFormOutputs, getIndex],
  (outputs, index) => {
    return outputs && outputs[index] || {};
  }
);

const getOutputItemMasterId = createSelector(getOutput, output => output.item_master_id);

export const getOutputItemMaster = createSelector(
  [getOutputItemMasterId, getProducts],
  (itemMasterId, itemMasters) => itemMasters.find(im => im.id === itemMasterId)
);

const getOutputSubcategoryId = createSelector(
  [getOutputItemMaster],
  (itemMaster) => itemMaster && itemMaster.subcategory_id
);

export const getOutputInventoryType = getInventoryType(getOutputSubcategoryId);
