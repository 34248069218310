import React from 'react';
import PropTypes from 'prop-types';

import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import {Col} from 'react-bootstrap';
import ReactSelectInput from '../../form/ReactSelectInput';
import storageLocationTypes from '../../../../constants/storageLocationTypes';

const StorageLocationType = (props) => {
  const {name, isCanadaFacility, type} = props;
  return (
    isCanadaFacility && type === 'storage' ?
    <Col className='form-fields-col' xs={3} md={1} lg={1}>
      <Field
        name={`${name}.location_type`} component={ReactSelectInput}
        props={{
          options: storageLocationTypes,
          label: I18n.t('locations.form.storageLocationType'),
        }} />
    </Col> : null
  );
};

StorageLocationType.propTypes = {
  name: PropTypes.string.isRequired,
  isCanadaFacility: PropTypes.bool,
  type: PropTypes.string
};

export default StorageLocationType;
