import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {ListGroup} from 'react-bootstrap';
import Coupon from './Coupon';
import MmapPayment from './MmapDiscount';

const Coupons = ({coupons, removeCoupon, rewardPointsOnOrder, mmapPayments, removeMmapPayment}) => {
  return (
   <div className='coupons'>
      <h2 style={{marginBottom: '4px'}}>{I18n.t('cart.coupons.adjustments')}</h2>
      {
        rewardPointsOnOrder === 0
        ? null
          : <div style={{marginBottom: '8px'}}>Reward Points Used: {rewardPointsOnOrder}</div>
      }
      <ListGroup>
        {coupons.map((coupon) => (
          <Coupon
            key={coupon.id}
            removeCoupon={removeCoupon}
            coupon={coupon}
          />
        ))}
        {mmapPayments && mmapPayments.map((mmapPayment) => (
          <MmapPayment
            key={mmapPayment.id}
            removeMmapPayment={removeMmapPayment}
            mmapPayment={mmapPayment}
          />
        ))}
      </ListGroup>
    </div>
  );
};

Coupons.propTypes = {
  coupons: PropTypes.array,
  removeCoupon: PropTypes.func.isRequired,
  rewardPointsOnOrder: PropTypes.number,
  mmapPayments: PropTypes.array,
  removeMmapPayment: PropTypes.func,
};

export default Coupons;
