/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {I18n} from 'react-redux-i18n';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import {Button} from 'react-bootstrap';
import get from 'lodash.get';
import * as dataNames from '../../constants/dataNames';
import {getUnpaginatedData, postItem} from '../../actions/apiActions';
import {setRules} from '../../actions/ruleActions';
import {getPartnersByStatus} from '../../selectors/partnersSelectors';
import TablePageWrapper from '../common/grid/TablePageWrapper';
import PageTitle from '../common/PageTitle';
import ModalWrapper from '../common/ModalWrapper';
import {getPartnerTabs} from './definition-files/tabDefinitions';
import SingleActionColumn from '../common/grid/columns/SingleActionColumn';
import PartnerDetails from './listing-components/PartnerDetails'; //eslint-disable-line
import rules from './RenderRules';
import {getRenderFacts} from '../../selectors/forms/organizationPageSelectors';
import {getPublicOrganizationsWithCategories} from '../../selectors/connectsSelectors';
import * as itemNames from '../../constants/itemNames';

export class PartnerPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.createPartner = this.createPartner.bind(this);
    this.modifyPartner = this.modifyPartner.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.switchTab = this.switchTab.bind(this);
    this.loadTabData = this.loadTabData.bind(this);
    this.requestConnection = this.requestConnection.bind(this);
    this.respondToConnectRequest = this.respondToConnectRequest.bind(this);
    this.cancelConnectRequest = this.cancelConnectRequest.bind(this);
    this.getActiveTabDefinition = this.getActiveTabDefinition.bind(this);
    this.expandedData = this.expandedData.bind(this);
    this.onStartPurchaseOrder = this.onStartPurchaseOrder.bind(this);
    this.onStartSalesOrder = this.onStartSalesOrder.bind(this);
    this.onHideConfirmCancelRequest = this.onHideConfirmCancelRequest.bind(this);
    this.onConfirmCancelRequest = this.onConfirmCancelRequest.bind(this);

    this.ref = React.createRef();
    const statusMap = {
      'in-network': 'inNetwork',
      'out-of-network': 'outNetwork',
      'active': 'activePartners',
      'inactive': 'inactivePartners',
      'connects': 'availableConnects',
      'requests': 'pendingConnectsRequests'
    };

    const startSalesOrder = (cell, row) => {
      if(!get(row,'sell_to',false)) return null;

      return (<button
        className='btn btn-primary'
        onClick={(e) => this.onStartSalesOrder(row)}
        >{I18n.t('partners.table.startSalesOrder')}</button>);
    };

    const startPurchaseOrder = (cell, row) => {
      if(!get(row,'purchase_from',false)) return null;

      return (<button
        className='btn btn-primary'
        onClick={(e) => this.onStartPurchaseOrder(row)}
        >{I18n.t('partners.table.startPurchaseOrder')}</button>);
    };

    this.state = {
      showConfirmCancelRequest: false,
      requestToCancel: null,
      selectedPartners: [],
      tabs: getPartnerTabs([
        {
          eventKey: 'inNetwork',
          actions: [
            {id: 'modifyPartner', func: this.modifyPartner}
          ],
          columns: [
            {
              updateKey: 'startPurchaseOrder',
              formatter: startPurchaseOrder
            },
            {
              updateKey: 'startSalesOrder',
              formatter: startSalesOrder
            }
          ]
        },
        {
          eventKey: 'outNetwork',
          actions: [
            {id: 'modifyPartner', func: this.modifyPartner}
          ],
          columns: [
            {
              updateKey: 'startPurchaseOrder',
              formatter: startPurchaseOrder
            },
            {
              updateKey: 'startSalesOrder',
              formatter: startSalesOrder
            },
            // {
            //   updateKey: 'requestConnection',
            //   formatter: SingleActionColumn({
            //     label: 'partners.connects.request',
            //     action: this.requestConnection
            //   }),
            // }
          ]
        },
        {
          eventKey: 'availableConnects',
          columns: [
            {
              updateKey: 'requestConnection',
              formatter: SingleActionColumn({
                label: 'partners.connects.request',
                action: this.requestConnection
              }),
            }
          ]
        },
        {
          eventKey: 'pendingConnectsRequests',
          columns: [
            {
              updateKey: 'respondToRequest',
              formatter: (cell, row) => {
                if (!row.is_incoming) {
                  return (
                    <Button variant='primary' size='sm' onClick={() => this.cancelConnectRequest(event, row)}>
                      {I18n.t('partners.connects.requests.cancel')}
                    </Button>
                  );

                } else {
                  return (
                    <Button variant='primary' size='sm' onClick={() => this.respondToConnectRequest(event, row)}>
                      {I18n.t('partners.connects.requests.respond')}
                    </Button>
                  );
                }
              }
            }
          ]
        },
        {
          eventKey: 'inactivePartners',
          actions: [
            {id: 'modifyPartner', func: this.modifyPartner}
          ]
        },
        {
          eventKey: 'activePartners',
          actions: [
            {id: 'modifyPartner', func: this.modifyPartner}
          ]
        }
      ]),
      activeTab: !props.params.status
        ? this.props.renderFlags.showConnectsTabs ? 'inNetwork' : 'activePartners'
        : statusMap[props.params.status] !== undefined
          ? statusMap[props.params.status]
          : this.props.renderFlags.showConnectsTabs ? 'inNetwork' : 'activePartners'
    };

  }

  componentWillMount() {
    this.props.actions.getUnpaginatedData('/api/categories', dataNames.categories);
    this.loadTabData();
  }

  componentWillReceiveProps(nextProps){
    this.props.actions.setRules(rules)
      .then((engine) => {
        engine.setFacts(nextProps.renderFacts);
      });
  }

  loadTabData(){
    const activeTabDefinition = this.getActiveTabDefinition();
    this.props.actions.getUnpaginatedData(activeTabDefinition.dataUrl, activeTabDefinition.store, {failed: 'partners.get.failed'});
  }

  createPartner () {
    this.props.actions.push('/partners/create');
  }

  modifyPartner () {
    if(!this.state.selectedPartners.length) return false;
    this.props.actions.push(`/partners/${this.state.selectedPartners.shift().split(':').shift()}/modify`);
  }

  requestConnection(event, row) {
    this.props.actions.push(`/partners/connects/requests/${row.organization_code}`);
  }

  respondToConnectRequest(event, row) {
    this.props.actions.push(`/partners/connects/respond/${row.id}`);
  }

  cancelConnectRequest(event, row) {
    this.setState({requestToCancel: row.id});
    this.setState({showConfirmCancelRequest: true});
  }

  exportCSV () {
    if(this.ref.current){
      this.ref.current.wrappedInstance.getBaseRef().handleExportCSV();
    }
  }

  handleSelect (isSelected, rows) {
    this.setState({selectedPartners: isSelected ? rows.map(row => row.id) : []});
  }

  switchTab(activeTab) {
    const tab = this.state.tabs.find(tab => tab.eventKey === activeTab) || this.state.tabs[0];
    this.setState({activeTab, selectedPartners: []}, () => {
      this.props.actions.push(tab.path);
      this.loadTabData();
    });
  }

  getActiveTabDefinition(){
    return this.state.tabs.find((tab) => tab.eventKey === this.state.activeTab);
  }

  expandedData(partner){
    if(!partner.id) return null;
    const partnerIsSelected = this.state.selectedPartners.indexOf(partner.id) !== -1;
    return <PartnerDetails partner={partner} loadData={partnerIsSelected} activeTab={this.state.activeTab} />;
  }

  onStartPurchaseOrder(partner){
    this.props.actions.push(`/purchase-orders/create?org=${partner.id}`);
  }

  onStartSalesOrder(partner){
    this.props.actions.push(`/sales-orders/create?org=${partner.id}`);
  }

  //User cancelled or closed the confirmation modal
  onHideConfirmCancelRequest() {
    this.setState({showConfirmCancelRequest: false});
  }

  onConfirmCancelRequest() {
    const id = this.state.requestToCancel;
    this.props.actions.postItem(
      `/api/partners/partner_connect_requests/${id}/cancel`,
      {},
      'ConnectRequestCancel',
      {
        success: 'partners.connects.requests.cancelSuccess',
        failed: 'partners.connects.requests.cancelFail'
      }
    ).then(() => {
      this.loadTabData();
    });
  }

  render () {
    const {partnerFacilities, publicOrganizations, partnerConnectsRequests, renderFlags} = this.props;
    const {activeTab, tabs} = this.state;
    const activeTabDefinition = this.getActiveTabDefinition();
    const data = activeTab === 'availableConnects'
      ? publicOrganizations
      : activeTab === 'pendingConnectsRequests'
        ? partnerConnectsRequests
        : partnerFacilities;

    const baseTableProps = {
      options: {
        hideSizePerPage: true,
        onRowClick: row => {},
        expandBy: 'column',
      },
      expandableRow: (row) => ['availableConnects', 'pendingConnectsRequests'].indexOf(activeTab) === -1,
      expandComponent: this.expandedData,
    };

    // Temporary hide "connects" partner's details.
    // When we will be ready to disclose it, delete this definition of selectRow and un-comment the next one.
    const selectRow = {
      selectRow: {
        mode: 'radio',
        selected: this.state.selectedPartners,
      }
    };
    /*
    const selectRow = {
      selectRow: ['availableConnects', 'pendingConnectsRequests'].indexOf(activeTab) !== -1
        ? false
        : {
          mode: 'radio',
          clickToSelect: ['availableConnects', 'pendingConnectsRequests'].indexOf(activeTab) === -1,
          clickToExpand: ['availableConnects', 'pendingConnectsRequests'].indexOf(activeTab) === -1,
          selected: this.state.selectedPartners,
          onSelect: (row, isSelected) => this.handleSelect(isSelected, [row]),
        }
    };
    */

    const tableProps = Object.assign({}, baseTableProps, selectRow);

    const filterTabsByConnectsPermission = (tab) => {
      const connectsTabs = ['inNetwork', 'outNetwork', 'availableConnectsTab', 'pendingConnectsRequestsTab'];
      return !renderFlags.showConnectsTabs
        ? connectsTabs.indexOf(tab.id) === -1
        : ['activeTab'].indexOf(tab.id) === -1;
    };

    return (
      <div className='partner-page'>
        <PageTitle primaryText={I18n.t('partners.title')}/>
        <TablePageWrapper
          ref={this.ref}
          settingKey='partners'
          columns = {activeTabDefinition ? activeTabDefinition.columns : []}
          data = {data}
          handleSelect={this.handleSelect}
          selectedRows={this.state.selectedPartners}
          tabs={tabs.filter(filterTabsByConnectsPermission)}
          hideScanSearch = {true}
          className = 'partner-page'
          hideExport={true}
          activeTab={activeTab}
          switchTab={this.switchTab}
          bstProps={tableProps}
        />
        <ModalWrapper
          Component={false}
          title='partners.connects.requests.cancelTitle'
          headerClass='bg-info-dark'
          onHide={this.onHideConfirmCancelRequest}
          showModal={this.state.showConfirmCancelRequest}
          okayButton={{show: true, onClick: this.onConfirmCancelRequest, text: 'partners.connects.requests.okayText'}}
          cancelButton={{show: true, onClick: this.onHideConfirmCancelRequest, text: 'partners.connects.requests.cancelText'}}
          dialogClassName='modal-sm'
          version={2}
        >
          <div>{I18n.t('partners.connects.requests.cancelDescription', {})}</div>
        </ModalWrapper>
      </div>
    );
  }
}

PartnerPage.propTypes = {
  partnerFacilities: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  params:PropTypes.shape({
    status: PropTypes.string
  }),
  renderFlags: PropTypes.object,
  renderFacts: PropTypes.object,
};

function mapStateToProps(state, ownProps){
  return {
    partnerFacilities: getPartnersByStatus(state, {status: ownProps.params.status}),
    publicOrganizations: getPublicOrganizationsWithCategories(state),
    categories: state[dataNames.categories],
    partnerConnectsRequests: state[dataNames.partnerConnectsRequests],
    renderFacts: getRenderFacts(state, {permissionKey: 'manage_connects'}),
    renderFlags: state[itemNames.rules].default || {},
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {getUnpaginatedData, postItem, push, setRules};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (PartnerPage);
