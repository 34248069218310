import React from 'react';
import PropTypes from 'prop-types';
import {Field, formValueSelector} from 'redux-form';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import {INVENTORY_RECEIPT_FORM} from '../../../constants/forms';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import {getReassignItemMasters} from '../../../selectors/fillPurchaseOrderSelectors';
import {reassignPackage, validateReassignProduct} from '../../../actions/inventory-receipt/reassignActions';

class ReassignItemMasterDropDown extends React.PureComponent {

  constructor(props, context) {
    super(props, context);

  }

  render() {
    const {fieldName, fieldProps, options, actions, lines} = this.props;
    const regex = /\d+/;
    const index = get(fieldName.match(regex), '0', null);
    const lineItem = lines[index];
    const uom = get(lineItem, 'itemMaster.default_uom');
    const valueMatches = (stringKey, testOption) => {
      return get(lineItem, `itemMaster.${stringKey}`) === get(testOption, stringKey);
    };
    const useOptions = uom
      ? options.filter((option) => valueMatches('default_uom', option) && valueMatches('inventory_attributes.is_prepack', option) && valueMatches('inventory_attributes.lot_tracked', option))
      : options;

    return (
      <Field
        name={`${fieldName}.item_master_id`}
        component={ReactSelectInput}
        onChange={actions.onChange}
        props={{
          ...fieldProps,
          options: useOptions,
        }}
      />
    );
  }
}

ReassignItemMasterDropDown.defaulProps = {
  editable: false,
  label: I18n.t('cultivation.transfers.form.item')
};

ReassignItemMasterDropDown.propTypes = {
  fieldName: PropTypes.string,
  fieldProps: PropTypes.object,
  itemMaster: PropTypes.object,
  options: PropTypes.array,
  actions: PropTypes.object,
  lines: PropTypes.array,
};

const select = formValueSelector(INVENTORY_RECEIPT_FORM);

export default connect(
  (state) => ({
    options: getReassignItemMasters(state),
    lines: select(state, 'lines'),
  }),
  (dispatch, ownProps) => ({
    actions: {
      onChange(event, newValue) {
        if (dispatch(validateReassignProduct(newValue, ownProps.itemMaster, ownProps.inventory)) === false) {
          return event.preventDefault();
        }

        dispatch(reassignPackage(newValue, ownProps));
        setTimeout(() => ownProps.change(`${ownProps.fieldName}.reassign_package`, true, true), 50);
      }
    }
  })
)(ReassignItemMasterDropDown);
