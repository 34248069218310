import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import {reduxForm} from 'redux-form';
import {Button, ButtonToolbar, Col, Row} from 'react-bootstrap';

import {PRODUCT_MASS_MODIFY} from '../../../constants/forms';
/* eslint-disable import/no-named-as-default*/
import ProductsList from './ProductsList';
import GeneralInformationSection from './GeneralInformationSection';
import InventorySettingsSection from './InventorySettingsSection';
import SalesInformationSection from './SalesInformationSection';
import PotencyInformationSection from './PotencyInformationSection';
import AdditionalInformationSection from './AdditionalInformationSection';
import PurchasingInformationSection from './PurchasingInformationSection';
import LeaflyAvailabilitySection from './LeaflyAvailabilitySection';
import WeedmapsAvailabilitySection from './WeedmapsAvailabilitySection';
import {
  onlineAvailabilityOptionsForMassPage,
  solvents,
  activationTimes,
  dilutions,
} from '../common/data';
import AccordionPanel from '../../common/AccordionPanel';
import validate from './validate';

const commonOptions = [
  {value: null, text: ' '},
  {value: 1, text: 'Yes'},
  {value: 0, text: 'No'}
];

const tagsActions = [
  {value: 'add', text: 'Add to existing'},
  {value: 'clear', text: 'Clear all'}
];

const uomWeightOptions = [
  {value: null},
  {value: 'MG'},
  {value: 'GR'}
];

const MassModifyProductForm = (props) => {
  const {
    handleSubmit, resetForm, reset, products, subcategories, strains, phenotypes, tags, uoms, weight_type, brands, change,
    partners, retailFacilityOptions, pricingClasses, pricingWeights, pricingGroups, category_id, facilityOptions, setButtonAction, isStrainRequired,
    integrationState: {isLeafly, isCanada, isWeedmaps}, handleReturn, showCategoryError, showUomError, showInventoryError, fieldCanModifiedMap,
    fieldActions, canChangeSubcategory, isConnectsActive, isPurchasingItem, isStrainDisabled
  } = props;

  return (
    <div>
      <AccordionPanel title={I18n.t('products.table.productListing')}>
        <ProductsList products={products} resetForm={reset}/>
      </AccordionPanel>
      <form className='mass-modify-form' onSubmit={handleSubmit} noValidate={true}>
        <hr/>

        <Row>
          <Col sm={12}>
            <h4>{I18n.t('products.massModify.subTitle')}</h4>
          </Col>
        </Row>

        <GeneralInformationSection
          subcategories={subcategories}
          strains={strains}
          phenotypes={phenotypes}
          commonOptions={commonOptions}
          tagsActions={tagsActions}
          tags={tags}
          uoms={uoms}
          showCategoryError={showCategoryError}
          showUomError={showUomError}
          integrationState={props.integrationState}
          showInventoryError={showInventoryError}
          change={change}
          fieldActions={fieldActions}
          canChangeSubcategory={canChangeSubcategory}
          brands={brands}
          isConnectsActive={isConnectsActive}
          isStrainRequired={isStrainRequired}
          isStrainDisabled={isStrainDisabled}
        />

        <InventorySettingsSection
          commonOptions={commonOptions}
          uomOptions={uomWeightOptions}
          isCanada={isCanada}
          showInventoryError={showInventoryError}
          fieldCanModifiedMap={fieldCanModifiedMap}
        />

        <PurchasingInformationSection
          vendorOptions={partners}
          weight_type={weight_type}
          showUomError={showUomError}
          isPurchasingItem={isPurchasingItem}
          products={products}
        />

        <SalesInformationSection
          onlineAvailabilityOptions={onlineAvailabilityOptionsForMassPage}
          retailFacilityOptions={retailFacilityOptions}
          pricingClasses={pricingClasses}
          pricingWeights={pricingWeights}
          pricingGroupOptions={pricingGroups}
          category_id={category_id}
          facilityOptions={facilityOptions}
          weight_type={weight_type}
          showCategoryError={showCategoryError}
          fieldCanModifiedMap={fieldCanModifiedMap}
        />

        <LeaflyAvailabilitySection
          isLeafly={isLeafly}
          commonOptions={commonOptions}
        />

        <WeedmapsAvailabilitySection
          isWeedmaps={isWeedmaps}
          commonOptions={commonOptions}
        />

        <PotencyInformationSection
          activationTimeOptions={[{text: '', value: null} ,...activationTimes]}
          fieldCanModifiedMap={fieldCanModifiedMap}
        />

        <AdditionalInformationSection
          solventOptions={[{text: '', value: null} ,...solvents]}
          commonOptions={commonOptions}
          dilutionOptions={[{text: '', value: null} ,...dilutions]}
          tagsActions={tagsActions}
          fieldCanModifiedMap={fieldCanModifiedMap}
          change={change}
          fieldActions={fieldActions}
        />

        <div className='form-actions'>
          <ButtonToolbar className='float-right'>
            <Button type='reset' onClick={(event) => {reset(event);resetForm(event);}}>{I18n.t('common.form.reset')}</Button>
            <Button type='button' onClick={handleReturn} variant='primary'>{I18n.t('products.massModify.return')}</Button>
            <Button type='submit' onClick={() => {setButtonAction('preview');}} variant='primary'>{I18n.t('products.massModify.preview')}</Button>
          </ButtonToolbar>
        </div>
      </form>
    </div>
  );
};

MassModifyProductForm.defaultProps = {
  isConnectsActive: false,
};

MassModifyProductForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  change: PropTypes.func.isRequired,
  subcategories: PropTypes.array.isRequired,
  strains: PropTypes.array.isRequired,
  phenotypes: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  uoms: PropTypes.array.isRequired,
  partners: PropTypes.array.isRequired,
  retailFacilityOptions: PropTypes.array.isRequired,
  pricingClasses: PropTypes.array.isRequired,
  pricingWeights: PropTypes.array.isRequired,
  pricingGroups: PropTypes.array.isRequired,
  category_id: PropTypes.number.isRequired,
  facilityOptions: PropTypes.array.isRequired,
  weight_type: PropTypes.string.isRequired,
  integrationState: PropTypes.object.isRequired,
  brands: PropTypes.array.isRequired,
  handleReturn: PropTypes.func.isRequired,
  showUomError: PropTypes.bool.isRequired,
  showInventoryError: PropTypes.bool.isRequired,
  fieldCanModifiedMap: PropTypes.object.isRequired,
  setButtonAction: PropTypes.func.isRequired,
  showCategoryError: PropTypes.bool.isRequired,
  fieldActions: PropTypes.object.isRequired,
  canChangeSubcategory: PropTypes.bool.isRequired,
  isConnectsActive: PropTypes.bool,
  isPurchasingItem: PropTypes.int,
  isStrainRequired: PropTypes.bool.isRequired,
  isStrainDisabled: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: PRODUCT_MASS_MODIFY,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate: validate,
})(MassModifyProductForm);
