import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from 'react-bootstrap';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import InlineCheckBox from '../../common/form/InlineCheckBox';

const PopulateLOQCheckbox = ({editable, populate_loq}) => {
  let checkbox = null;
  if (editable) {
    checkbox = ( 
      <Field name='populate_loq' component={InlineCheckBox} props={{
        label: I18n.t('cultivation.testResults.table.populateLOQ')
      }}/>
    );
  } else {
    checkbox = (
      <InlineCheckBox disabled={true} input={{value:populate_loq}} label={I18n.t('cultivation.testResults.table.populateLOQ')}  />
    );
  }
  return (
    <Row>
      <Col sm={6} md={4} lg={3}>
        {checkbox}
      </Col>
    </Row>
  );
};

PopulateLOQCheckbox.propTypes = {
  editable: PropTypes.bool.isRequired,
  populate_loq: PropTypes.bool,
};
  
PopulateLOQCheckbox.defaultProps = {
  disabled: false,
  populate_loq: false,
};
  
export default PopulateLOQCheckbox;
