import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {
  Col,
  Row,
  Table,
  Card} from 'react-bootstrap';
import SubmitSection from '../../common/form/SubmitSection';
import ReactSelectInput from '../../../../src/components/common/form/ReactSelectInput';



const currencyDisplayOptions = [
  {value: 'CURRENCY_SYMBOL_DOLLAR', text: '$ Dollar'},
  {value: 'CURRENCY_SYMBOL_EURO', text: '€ Euro'},
  {value: 'CURRENCY_SYMBOL_POUND', text: '£ Pound'},
  {value: 'CURRENCY_SYMBOL_RAND', text: 'R Rand'},
  {value: 'CURRENCY_SYMBOL_BAHT', text: '฿ Baht'},
];

const numericalSeparatorOptions = [
  {value: 'NUMERICAL_SEPARATOR_COMMA', text: 'USA'},
  {value: 'NUMERICAL_SEPARATOR_PERIOD', text: 'Europe'},
];

const timeFormatOptions = [
  {value: 'hh:MM A', text: '11:34 PM'},
  {value: 'HH:MM', text: '23:34'},
];

const dateFormatOptions = [
  {value: 'MM/DD/YYYY', text: 'MM/DD/YYYY'},
  {value: 'DD/MM/YYYY', text: 'DD/MM/YYYY'},
];

const yesNoOptions = [
  {value: 'true', text: 'Yes'},
  {value: 'false', text: 'No'},
];

const languageOptions = [
  {value: 'en', text: I18n.t('nav.English')},
  {value: 'es', text: I18n.t('nav.Spanish')},
  {value: 'fr', text: I18n.t('nav.French')},
  {value: 'it', text: I18n.t('nav.Italian')},
];



export const InternationalOperationsForm = (props) => {
  const {handleSubmit, submitting, pristine} = props;

  const settings = {
    actionSettings: {
      submit: {
        submitting,
        pristine,
        text: I18n.t('common.form.save'),
        style: 'primary',
      }
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col xs={12} md={6}>
          <Card><Card.Header><h3 className='panel-title'>{I18n.t('internationalSettings.title')}</h3></Card.Header>
            <Table striped={true} bordered={true}>
              <tbody>
              <tr>
                <td>
                  {I18n.t('internationalSettings.currencySymbol')}
                </td>
                <td>
                  <Field
                    name='currency_symbol'
                    component={ReactSelectInput}
                    props={{
                      options: currencyDisplayOptions,
                      clearable: false,
                      groupClassName: 'thin'
                    }}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  {I18n.t('internationalSettings.numericalSeparatorFormat')}
                </td>
                <td>
                  <Field
                    name='numerical_separator'
                    component={ReactSelectInput}
                    props={{
                      options: numericalSeparatorOptions,
                      clearable: false,
                      groupClassName: 'thin'
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  {I18n.t('internationalSettings.timeFormat')}
                </td>
                <td>
                  <Field
                    name='time_format'
                    component={ReactSelectInput}
                    props={{
                      options: timeFormatOptions,
                      clearable: false,
                      groupClassName: 'thin'
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  {I18n.t('internationalSettings.dateFormat')}
                </td>
                <td>
                  <Field
                    name='date_format'
                    component={ReactSelectInput}
                    props={{
                      options: dateFormatOptions,
                      clearable: false,
                      groupClassName: 'thin'
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  {I18n.t('internationalSettings.phoneIncludeCountryCode')}
                </td>
                <td>
                  <Field
                    name='phone_number_include_country_code'
                    component={ReactSelectInput}
                    props={{
                      options: yesNoOptions,
                      clearable: false,
                      groupClassName: 'thin'
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  {I18n.t('internationalSettings.defaultLanguage')}
                </td>
                <td>
                  <Field
                    name='default_language'
                    component={ReactSelectInput}
                    props={{
                      options: languageOptions,
                      clearable: false,
                      groupClassName: 'thin'
                    }}
                  />
                </td>
              </tr>

              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
      <SubmitSection settings={settings}/>
    </form>
  );
};

InternationalOperationsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
};

export default InternationalOperationsForm;
