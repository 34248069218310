import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as applicationModes from '../../constants/applicationModes';
import {getApplicationMode} from '../../selectors/applicationModesSelectors';


export class ComponentByModeWrapper extends React.PureComponent {
  render() {
    const {mode, route: {components}, ...props} = this.props;

    const Component = components[mode] || components[applicationModes.normal];


    return (
      <Component {...props}/>
    );
  }
}

ComponentByModeWrapper.propTypes = {
  mode: PropTypes.oneOf(Object.keys(applicationModes).map((k) => applicationModes[k])), //eslint-disable-line
  components: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    mode: getApplicationMode(state),
  };
}

export default connect(mapStateToProps)(ComponentByModeWrapper);
