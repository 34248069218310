import React from 'react';
import PropTypes from 'prop-types';
import {reset} from 'redux-form';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {goBack} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';

import * as messageTypes from '../../../../constants/messageTypes';
import * as itemNames from '../../../../constants/itemNames';
import * as apiActions from '../../../../actions/apiActions';
import {addMessage} from '../../../../actions/systemActions';

import FormWrapper from '../../../common/form/FormWrapper';
import ThirdPartyIntegrationFormWrapper from './ThirdPartyIntegrationFormWrapper';
import InProgressOverlay from '../../../common/InProgressOverlay';

import {
  getInitialValues,
  transformThirdPartyIntegration,
  getThirdPartyIntegrationPayload
} from '../../../../selectors/integration/thirdPartyIntegrationSelectors';

class ManageThirdPartyIntegrationsPage extends React.PureComponent {
  constructor (props) {
    super(props);

    this.redirect = this.redirect.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      loading: false,
      progressMessage: I18n.t('inProgressOverlay.message'),
    };
  }

  componentWillMount () {
    const { params: {integration} } = this.props;

    this.props.actions.getItem('/api/third-party-integrations/active', itemNames.activeThirdPartyIntegrations);
    this.props.actions.getItem('/api/settings/values/by_key', itemNames.thirdPartyIntegration, null, {
      ids: [integration]
    });
  }

  componentWillUnmount () {

  }

  onSubmit (values) {
    const {
      facility,
      params: {integration},
      actions: {addMessage}
    } = this.props;

    values.integration = integration;
    values.facility_id = facility.id;

    const payload = getThirdPartyIntegrationPayload(values);

    const responses = {
      success: 'facility.setup.thirdPartyIntegration.manage.success',
      failed: 'facility.setup.thirdPartyIntegration.manage.failed'
    };

    this.setState({
      loading: true,
    });

    this.props.actions.postItem('/api/integration-settings', payload, '', {}, {}, (data) => {
      this.setState({
        loading: false,
      });

      if(!data.warnings || !data.warnings.AUTHENTICATION){
        addMessage(messageTypes.success, [responses.success]);
        this.redirect();
      }
    });
  }

  redirect () {
    this.props.actions.goBack();
  }

  render () {
    const { integration, initialValues, actions } = this.props;

    if (!integration) {
      return null;
    }

    return (
      <FormWrapper title='facility.setup.manageThirdPartyIntegration' goBack={this.redirect}>
        <InProgressOverlay isActive={this.state.loading} message={this.state.progressMessage}/>
        <ThirdPartyIntegrationFormWrapper
          integration={integration}
          onSubmit={this.onSubmit}
          initialValues={initialValues}
          actions={actions}
        />
      </FormWrapper>
    );
  }
}

ManageThirdPartyIntegrationsPage.propTypes = {
  actions: PropTypes.shape({
    reset: PropTypes.func,
    goBack: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    postItem: PropTypes.func.isRequired,
    clearMeta: PropTypes.func.isRequired,
    addMessage: PropTypes.func.isRequired
  }).isRequired,
  params: PropTypes.shape({
    integration: PropTypes.string.isRequired
  }).isRequired,
  initialValues: PropTypes.object
};

function mapStateToProps (state) {
  const integration = transformThirdPartyIntegration(state);
  const initialValues = getInitialValues(integration);
  const facility = state[itemNames.facility];

  return {
    integration,
    initialValues,
    facility
  };
}

function mapDispatchToProps (dispatch) {
  const actions = Object.assign({}, apiActions, { reset, goBack, addMessage });

  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageThirdPartyIntegrationsPage);
