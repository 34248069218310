import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';
import {WASTE_COMPLIANCE_FORM} from '../../../constants/forms';
import WasteComplianceForm from './WasteComplianceForm';
import {validate} from './validate';

const WasteComplianceFormWrapper = reduxForm({
  form: WASTE_COMPLIANCE_FORM,
  validate,
  enableReinitialize: true,
})(WasteComplianceForm);

WasteComplianceFormWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  stageOptions: PropTypes.array.isRequired,
  dayOptions: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
  trackWastePackages: PropTypes.bool,
  transferringWaste: PropTypes.bool,
  trackingOfWastePackagesIsOverrideable: PropTypes.bool,
  requireWasteTransfer: PropTypes.object,

};

export default WasteComplianceFormWrapper;
