import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { Col } from 'react-bootstrap';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import { isColombiaFacility } from '../../../selectors/facility/getFacilityCountry';
import WillRender from '../../common/concealers/WillRender';
import { getCupoNumberOptions } from '../../../selectors/integration/colombiaSelectors';

const CupoNumberField = (props) => {
  const { isColombia, name, options, LayoutComponent, disabled } = props;

  return (
    <WillRender ifTrue={isColombia}>
      <LayoutComponent>
        <Field
          name={name}
          component={ReactSelectInput}
          props={{
            label: I18n.t('plants.table.cupo'),
            options,
            disabled: options.length === 0 || disabled
          }}
        />
      </LayoutComponent>
    </WillRender>
  );
};

CupoNumberField.defaultProps = {
  name: 'cupo_id',
  LayoutComponent: Col
};

CupoNumberField.propTypes = {
  name: PropTypes.string,
  formName: PropTypes.string.isRequired,
  isColombia: PropTypes.bool,
  LayoutComponent: PropTypes.func,
  options: PropTypes.array,
  disabled: PropTypes.bool
};

export default connect((state) => ({
  isColombia: isColombiaFacility(state),
  options: getCupoNumberOptions(state)
}))(CupoNumberField);
