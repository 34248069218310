export const contexts = { //eslint-disable-line
  'inventory': 'inventory?status=1&type=r',
  'inventory/active': 'inventory?status=1&type=r',
  'inventory/inactive': 'inventory?status=0&type=r',
  'inventory/waste': 'inventory?status=1&type=w',
  'inventory/inactive-waste': 'inventory?status=0&type=w',
  'inventory/ingredients/active': 'inventory?status=1&type=i',
  'inventory/ingredients/inactive': 'inventory?status=0&type=i',
  // E&I
  'inventory/prerun': 'inventory?status=1&type=r&phase_id=1',
  'inventory/curing': 'inventory?status=1&type=r&phase_id=2',
  'inventory/infusionconcentrate': 'inventory?status=1&type=r&phase_id=3',
  'inventory/finished': 'inventory?status=1&type=r&phase_id=4',
  // products/item_masters
  'products': 'item_masters?status=1&type=rp',
  'products/inactive': 'item_masters?status=0&type=rp',
  // harvest batches
  'harvests/active': 'harvest_batches?status=1&type=r',
  'harvests/inactive': 'harvest_batches?status=0&type=r',
  // patients
  'patients': 'customers?status=1&type=r',
  'patients/inactive': 'customers?status=0&type=r',
  // plants
  'plants/propagation': 'plants?status=1&type=r&phase_id=2',
  'plants/vegetation': 'plants?status=1&type=r&phase_id=1',
  'plants/flowering': 'plants?status=1&type=r&phase_id=3',
  'plants/inactive': 'plants?status=0&type=r',
  // lab results
  'test-results/active': 'lab_results?status=1&type=r',
  'test-results/historical': 'lab_results?status=0&type=r',
};

export const searchTermDuration = 5000; // How long in microseconds do we preserve a search term across component changes.
