export const activePurchase = 'activePurchase';
export const medicatedPurchase = 'medicatedPurchase';
export const nonMedicatedPurchase = 'nonMedicatedPurchase';
export const inactivePurchase = 'inactivePurchase';
export const activeInventory = 'activeInventory';
export const inactiveInventory = 'inactiveInventory';
export const activeSales = 'activeSales';
export const inactiveSales = 'inactiveSales';
export const activeTransfers = 'activeTransfers';
export const inactiveTransfers = 'inactiveTransfers';

export const activeDriver = 'activeDriver';
export const inactiveDriver = 'inactiveDriver';

export const activeSchedule = 'activeSchedule';
export const inactiveSchedule = 'inactiveSchedule';