import PropTypes from 'prop-types';
import {reduxForm, formValueSelector} from 'redux-form';
import {connect} from 'react-redux';
import {getCategories} from '../../../../selectors/categorySelectors';
import {getCustomerGroups} from '../../../../selectors/customerGroupsSelectors';
import {getPartnerOrganization} from '../../../../selectors/partnersSelectors';
import {getProducts} from '../../../../selectors/productsSelector';
import {getProductLists} from '../../../../selectors/couponsSelectors';
import validate from '../common/validate';
import CouponForm from '../common/CouponForm';
import {MODIFY_COUPON_FORM} from '../../../../constants/forms';

const formSelector = formValueSelector(MODIFY_COUPON_FORM);

const ModifyCouponReduxForm = reduxForm({
  form: MODIFY_COUPON_FORM,
  validate,
  enableReinitialize: true,
})(CouponForm);

function mapStateToProps(state) {
  return {
    discountType: formSelector(state, 'discount_type'),
    couponAppliesTo: formSelector(state, 'coupon_applies_to'),
    couponType: formSelector(state, 'coupon_type'),
    categories: getCategories(state),
    customerGroups: getCustomerGroups(state),
    products: getProducts(state),
    productLists: getProductLists(state),
    vendors: getPartnerOrganization(state),
  };
}

const ModifyCouponFormWrapper = connect(mapStateToProps)(ModifyCouponReduxForm);

ModifyCouponFormWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

export default ModifyCouponFormWrapper;
