import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Table} from 'react-bootstrap';

const Favorites = ({customerStat}) => {
  return (
    <Table className='information'>
      <thead>
      <tr>
        <th colSpan={2}>{I18n.t('cart.patient.favorites')}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>{I18n.t('cart.patient.favoriteFlower')}:</td>
        <td>{customerStat.favorite_flower_item_name || ''}</td>
      </tr>
      <tr>
        <td>{I18n.t('cart.patient.favoriteEdible')}:</td>
        <td>{customerStat.favorite_edible_item_name || ''}</td>
      </tr>
      <tr>
        <td>{I18n.t('cart.patient.favoriteConcentrate')}:</td>
        <td>{customerStat.favorite_concentrate_item_name || ''}</td>
      </tr>
      <tr>
        <td>{I18n.t('cart.patient.favoriteTopical')}:</td>
        <td>{customerStat.favorite_topical_item_name || ''}</td>
      </tr>
      </tbody>
    </Table>
  );
};

Favorites.propTypes = {
  customerStat: PropTypes.shape({
    favorite_flower_item_name: PropTypes.string,
    favorite_edible_item_name: PropTypes.string,
    favorite_concentrate_item_name: PropTypes.string,
    favorite_topical_item_name: PropTypes.string
  })
};

Favorites.defaultProps = {
  customerStat: {
    favorite_flower_item_name: '',
    favorite_edible_item_name: '',
    favorite_concentrate_item_name: '',
    favorite_topical_item_name: ''
  }
};

export default Favorites;
