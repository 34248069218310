import {publicConnectsColumns, partnerColumns, publicConnectsRequestsColumns} from './columnDefinitions';
import * as dataNames from '../../../constants/dataNames';

const partnerTabs = [
  {
    id: 'inNetwork',
    eventKey: 'inNetwork',
    title: 'partners.InNetwork',
    path: '/partners/in-network',
    dataUrl: '/api/partners/details?connect_active=1',
    store: dataNames.partners,
    actions: [
      {id: 'modifyPartner', path: '/partners/modify/1', text: 'partners.actions.modifyPartner', glyph: 'edit', requireSelect: true}
    ],
    columns: partnerColumns.filter((column) => !column.available || column.available !== 'outOfNetwork')
  },
  {
    id: 'outNetwork',
    eventKey: 'outNetwork',
    title: 'partners.OutOfNetwork',
    path: '/partners/out-of-network',
    dataUrl: '/api/partners/details?connect_active=0',
    store: dataNames.partners,
    actions: [
      {id: 'createPartner', path: '/partners/create', text: 'partners.actions.createPartner', glyph: 'plus-sign', requireSelect: false},
      {id: 'modifyPartner', path: '/partners/modify/1', text: 'partners.actions.modifyPartner', glyph: 'edit', requireSelect: true}
    ],
    columns: partnerColumns,
  },
  {
    id: 'availableConnectsTab',
    eventKey: 'availableConnects',
    title: 'partners.availableConnects',
    path: '/partners/connects',
    dataUrl: '/api/organizations/public?include_categories=1',
    store: dataNames.publicOrganizations,
    actions: [],
    columns: publicConnectsColumns,
  },
  {
    id: 'pendingConnectsRequestsTab',
    eventKey: 'pendingConnectsRequests',
    title: 'partners.pendingRequests',
    path: '/partners/requests',
    dataUrl: '/api/partners/partner_connect_requests?request_status=open&detailed=1',
    store: dataNames.partnerConnectsRequests,
    actions: [],
    columns: publicConnectsRequestsColumns,
  },
  {
    id: 'activeTab',
    eventKey: 'activePartners',
    title: 'partners.active',
    path: '/partners/active',
    dataUrl: '/api/partners/details?include_active=1',
    store: dataNames.partners,
    actions: [
      {id: 'createPartner', path: '/partners/create', text: 'partners.actions.createPartner', glyph: 'plus-sign', requireSelect: false},
      {id: 'modifyPartner', path: '/partners/modify/1', text: 'partners.actions.modifyPartner', glyph: 'edit', requireSelect: true}
    ],
    columns: partnerColumns,
  },
  {
    id: 'inactiveTab',
    eventKey: 'inactivePartners',
    title: 'partners.inactive',
    path: '/partners/inactive',
    dataUrl: '/api/partners/details?include_inactive=1',
    store: dataNames.partners,
    actions: [
      {id: 'modifyPartner', path: '/partners/modify/1', text: 'partners.actions.modifyPartner', glyph: 'edit', requireSelect: true}
    ],
    columns: partnerColumns,
  },
];

/***
 * Accepts array of objects in shape of [{eventKey: matchToTabEventKey, actions: [{id: matchToActionId}], columns: [{updateKey: matchTouUpdateKey}]}]
 * actions update the func property, columns are object assigned results so you can add n properties
 * @param delegateActions
 * @returns {any[]}
 */
export const getPartnerTabs = (delegateActions) => {
  return partnerTabs.map((tab) => {
    const delegateAction = delegateActions.find((action) => action.eventKey === tab.eventKey);
    if(!delegateAction) return tab;
    tab.actions = tab.actions.map((action) => {
      const func = delegateAction.actions.find((a) => a.id === action.id);
      if(!func) return action;
      action.func = func.func;
      return action;
    });
    if(delegateAction.columns){
      delegateAction.columns.forEach((c) => {
        const index = tab.columns && tab.columns.findIndex((column) => column.updateKey === c.updateKey);
        if(index){
          tab.columns[index] = Object.assign({}, tab.columns[index], c);
        }
      });
    }
    return tab;
  });
};

export default partnerTabs;
