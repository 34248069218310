import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {getSelectedCustomerGroupsIds} from '../../../selectors/customerGroupsSelectors';
import * as dataNames  from '../../../constants/dataNames';
import TablePageWrapper from '../../common/grid/TablePageWrapper';
import InProgressOverlay from '../../common/InProgressOverlay';

const CustomerGroupList = (props) => {

  const {customerGroups, selectedCustomerGroupsIds, tabs, switchTab, activeTab, columns, loading} = props;

  return (
    <div className='retail-group-listing'>
      <InProgressOverlay isActive={loading} message={I18n.t('retail.customerGroupListing.loading')}/>
      <TablePageWrapper
        settingKey='retail-customer-groups'
        columns={columns}
        data={customerGroups}
        selectedRows={selectedCustomerGroupsIds}
        handleSelect={props.handleSelect}
        hideExport={true}
        hideScanSearch={true}
        tabs={tabs}
        external={false}
        switchTab={switchTab}
        activeTab={activeTab}
        bstProps={{
          selectRow: {
            mode: 'radio',
            selected: {selectedCustomerGroupsIds},
            onSelect: props.handleSelect,
            className: 'table table-sm table-bordered table-striped'
          },
        }}
        className='retail-group-table'
      />
    </div>
  );
};


CustomerGroupList.propTypes = {
  customerGroups: PropTypes.array,
  selectedCustomerGroupsIds: PropTypes.array,
  handleSelect: PropTypes.func.isRequired,
  activeTab: PropTypes.string,
  tabs: PropTypes.array,
  switchTab: PropTypes.func,
  columns: PropTypes.array,
  loading: PropTypes.bool
};

function mapStateToProps(state) {
  const customerGroups = state[dataNames.customerGroups];
  const selectedCustomerGroupsIds = getSelectedCustomerGroupsIds(state);
  return {
    customerGroups,
    selectedCustomerGroupsIds
  };
}

export default connect(mapStateToProps, null)(CustomerGroupList);
