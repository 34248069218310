import {
  maxLengthValidation,
  minLengthValidation,
  requiredFieldValidation
} from '../../common/form/redux-form/validations';

const validate = (values, props) => {
  return {
    name: requiredFieldValidation(values.name, 'retail.productList.form.name')
        || minLengthValidation(values.name, 'retail.productList.form.name', 3)
        || maxLengthValidation(values.name, 'retail.productList.form.name', 250),
    products: requiredFieldValidation(props.selected, 'retail.productList.form.products')
  };
};

export default validate;
