import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {FaExclamationTriangle} from 'react-icons/fa';

const CreateMatchingOrderOption = (props) => {

  const {canCreateMatchingOrder, sourceOrderType, matchingOrderWarnings, connectsStatus, transferStatus} = props;

  const targetKey = (sourceOrderType === 'salesOrder') ? 'purchaseOrder' : 'salesOrder';
  const sourceType = I18n.t(`supplyChain.${sourceOrderType}`);
  const targetType = I18n.t(`supplyChain.${sourceOrderType === 'salesOrder' ? 'purchaseOrder' : 'salesOrder'}`);
  const formFlag = `generate_internal_${sourceOrderType === 'salesOrder' ? 'purchase' : 'sales'}_order`;
  const updateInternalOrder = `update_internal_${sourceOrderType === 'salesOrder' ? 'purchase' : 'sales'}_order`;

  if(!matchingOrderWarnings) {
    return (<div className={canCreateMatchingOrder === 1 || canCreateMatchingOrder === -1 ? 'alert alert-info' : 'alert alert-warning'}>
      <div>
        <FaExclamationTriangle/> {I18n.t('supplyChain.createMatchingOrder', {
          sourceType: sourceType,
          targetType: targetType
        })}
      </div>
      <div style={{marginTop: '8px'}}>
        {
          canCreateMatchingOrder === 1
            ? (<label>
            <Field
              component='input'
              type='checkbox'
              name={formFlag}
              disabled={canCreateMatchingOrder !== 1}
              style={{width: '24px', height: '24px'}}
            />
            <span style={{position: 'relative', top: '-8px', left: '5px'}}>
              {I18n.t('supplyChain.createMatchingPrompt')} <strong>{targetType}</strong>
            </span>
          </label>)
                 : canCreateMatchingOrder === -1 && (!transferStatus || transferStatus === 'cancelled')  ? (<label>
                  <Field
                    component='input'
                    type='checkbox'
                    name={updateInternalOrder}
                    disabled={canCreateMatchingOrder !== -1}
                    style={{width: '24px', height: '24px'}}
                  />
                  <span style={{position: 'relative', top: '-8px', left: '5px'}}>
                    {I18n.t('supplyChain.updateMatchingPrompt')} <strong>{targetType}</strong>. {I18n.t('supplyChain.updateMatchingPromptWillCancel')}
                  </span>
                </label>) :
                (<div>
              <div>{I18n.t('supplyChain.createMatchingDone', {targetType: targetType})}</div>
              <div>This {sourceType} was {connectsStatus}</div>
            </div>)
        }
      </div>
    </div>);
  }

  return (<div className='alert alert-danger'>
    <div>
      <FaExclamationTriangle/> {I18n.t('supplyChain.createMatchingOrder', {
        sourceType: sourceType,
        targetType: targetType
      })}
    </div>
    <div style={{marginTop: '8px'}}>
      <strong>
        {I18n.t('supplyChain.sourceSaved', {sourceType})}<br />
        {I18n.t('supplyChain.targetFailed', {targetType, targetAction: I18n.t(`supplyChain.${targetKey}Action`)})}
      </strong>
    </div>
  </div>);

};

CreateMatchingOrderOption.propTypes = {
  canCreateMatchingOrder: PropTypes.number,
  sourceOrderType: PropTypes.string.isRequired,
  connectsStatus: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  matchingOrderWarnings: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]).isRequired,
  transferStatus: PropTypes.string
};

export default CreateMatchingOrderOption;
