export const formatPhoneUS =  (value) => {
  if (value) {
    let  number = value.toString().replace(/[^\d]/g, '');
    if(number.length < 4){
      number = number.replace(/(\d*)/, '($1');
    }else if(number.length < 7){
      number = number.replace(/(\d{3})(\d*)/, '($1) $2');
    }else if(number.length <= 10){
      number = number.replace(/(\d{3})(\d{3})(\d*)/, '($1) $2-$3');
    }else {
      number = number.slice(0, 15);
      number = number.replace(/(\d{3})(\d{3})(\d{4})(\d*)/, '($1) $2-$3 x$4');
    }
    return number;

  } else {
    return '';
  }
};

export const formatPhoneAU =  (value) => {
  if (value) {
    let  number = value.toString().replace(/[^\d]/g, '');
    if(number.length < 3){
      number = number.replace(/(\d*)/, '($1');
    }else if(number.length < 7){
      number = number.replace(/(\d{2})(\d*)/, '($1) $2');
    }else if(number.length <= 10){
      number = number.replace(/(\d{2})(\d{4})(\d*)/, '($1) $2-$3');
    }else {
      number = number.slice(0, 15);
      number = number.replace(/(\d{2})(\d{4})(\d{4})(\d*)/, '($1) $2-$3 x$4');
    }
    return number;
  } else {
    return '';
  }
};

export const formatInternationalPhone = (value) => {

  if (value) {
    let number = value;
    if (value.length > 10 && value.indexOf('-') > 0 && value.match(/-/g).length == 1 ) {
      //in case this is the initial load from database
      number = '+' + number;
      number = number.substring(0, number.length - 4) + '-' + number.substring(number.length - 4, number.length);
      number = number.substring(0, number.length - 8) + '-' + number.substring(number.length - 8, number.length);
    }
    //only numbers, + and - symbols allowed
    number = number.toString().replace(/[^0-9\+\-]/g, '');
    number = number.toString().replace(/\-{2,}/g, '-');

    if(number.length == 1){
      number = number.replace(/[^\+]/, '');
    }

    //lets remove any extra + symbols
    let pos = 0;
    while ((pos = number.indexOf('+', 1)) !== -1) {
      number = number.substring(0, pos) + number.substring(pos + 1);
    }

    return number;
  } else {
    return '';
  }
};

export const formatPhone = (value, countryCode = false) => {
  if(!countryCode) countryCode = 'US';
  const format = ['AU', 'NZ'].indexOf(countryCode) > -1 ? formatPhoneAU : formatPhoneUS;
  return format(value);
};

export const normalizePhone = (value) => {
  return value ?  value.toString().replace(/[^\d]/g, '') : '';
};

export const buildCompleteInternationalPhoneNumber =  (number, code, includeCountryCode) => {
  if (!number || !includeCountryCode || code == null) {
    return number;
  }

  if (number && code && number.indexOf('-') == -1) {
    number = '+' + code + '-' + number;
    number = number.substring(0, number.length - 4) + '-' + number.substring(number.length - 4, number.length);
    number = number.substring(0, number.length - 8) + '-' + number.substring(number.length - 8, number.length);

    //only numbers, + and - symbols allowed
    number = number.toString().replace(/[^0-9\+\-]/g, '');
    number = number.toString().replace(/\-{2,}/g, '-');
  }

  return number;
};

export const normalizeZip = (value) => {
  if(value){
    return value.toString().replace(/[^\da-zA-Z]/g, '').substring(0, 5);
  }
  return '';
};

export const formatCurrencyDecimal = (value, places = 2) => {
  const number = parseFloat(value);
  return isNaN(number)
    ? value
    : number.toFixed(places);
};
