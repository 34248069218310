import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import {I18n} from 'react-redux-i18n';
import { Col, Row } from 'react-bootstrap';
import TextInput from '../../../../common/form/TextInput';
import ReactSelectInput from '../../../../common/form/ReactSelectInput';
import SubmitSection from '../common/SubmitSection';

const Posabit = props => {
  const activeOptions = [
    { text: 'Yes', value: true },
    { text: 'No', value: false },
  ];

  return (
    <div className='posabit-container'>
      <Row>
        <Col sm={4}>
          <Field name='client_id' component={TextInput} props={{
            label: I18n.t('facility.setup.thirdPartyIntegration.posabit.clientId'),
            isRequired: true,
          }} />
        </Col>
        <Col sm={4}>
          <Field name='client_secret' component={TextInput} props={{
            label: I18n.t('facility.setup.thirdPartyIntegration.posabit.clientSecret'),
            isRequired: true,
          }} />
        </Col>
        <Col sm={4}>
          <Field name='active' component={ReactSelectInput} props={{
            label: I18n.t('facility.setup.thirdPartyIntegration.posabit.active'),
            isRequired: true,
            options: activeOptions,
            dataId: 'value'
          }} />
        </Col>
      </Row>
      <SubmitSection />
    </div>
  );
};

Posabit.propTypes = {
  actions: PropTypes.func.isRequired
};

export default Posabit;
