import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import ThirdPartyIntegrationForm from './ThirdPartyIntegrationForm';
import validate from './validate';

const form = 'thirdPartyIntegrationForm';
const getFormValue = formValueSelector(form);

const ThirdPartyIntegrationReduxForm = reduxForm({
  form,
  validate,
  enableReinitialize: true
})(ThirdPartyIntegrationForm);

function mapStateToProps (state) {
  return {
    getFormValue: name => getFormValue(state, name),
  };
}

const ThirdPartyIntegrationFormWrapper = connect(mapStateToProps)(ThirdPartyIntegrationReduxForm);

ThirdPartyIntegrationFormWrapper.propTypes = {
  integration: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  actions: PropTypes.object.isRequired
};

export default ThirdPartyIntegrationFormWrapper;
