import React from 'react';
import omit from 'lodash.omit';
import {pick} from 'lodash';
import get from 'lodash.get';
import { CancelToken } from 'axios';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import { getModulesState } from '../../../selectors/modulesSelectors';
import { getTabs } from './tabDefinitions';
import { validateWaSplit } from '../../../selectors/inventoryItemsSelectors';
import { isInvPackageTrackingNotInCompliance } from '../../../selectors/complianceSettingsSelectors';


class AbstractInventoryPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.ref = React.createRef();

    this.trClassName = this.trClassName.bind(this);
  }

  componentWillUnmount() {
    this.cancelTokens('search', 'fetchChildren', 'labResult');
  }

  loadTabs() {
    const { state } = this.props;
    const { hasManufacturing } = getModulesState(state);

    const promises = [
      this.props.actions.getItem('/api/compliance_settings', itemNames.complianceSettings),
      this.props.actions.getItem(
        '/api/cultivation/settings',
        itemNames.wasteCompliance,
        { failed: 'cultivation.wasteCompliance.get.failed' },
        {
          ids: ['cult_waste_destruction_compliance_settings', 'inv_track_waste_packages', 'inv_track_waste_location_id']
        }
      )
    ];

    if (hasManufacturing) {
      promises.push(
        this.props.actions.ensureGetUnpaginatedData('/api/phases', dataNames.phases, { failed: 'phases.get.failed' })
      );
    }

    return Promise.all(promises)
      .then(this.setTabs)
      .then(this.setTabsLoaded);
  }

  setTabs() {
    return new Promise((resolve) => {
      const tabs = getTabs(this);
      this.setState({ tabs }, () => resolve());
    });
  }

  setTabsLoaded() {
    return new Promise((resolve) => {
      this.setState({ tabsLoaded: true }, () => resolve());
    });
  }

  ensureToken(name) {
    const oldToken = this.state.cancelTokens[name];
    oldToken && oldToken.cancel('Concurrency request');
    const newToken = CancelToken.source();
    this.setState({
      cancelTokens: {
        ...this.state.cancelTokens,
        [name]: newToken
      }
    });
    return newToken;
  }

  clearTokens(...names) {
    this.setState({ cancelTokens: omit(this.state.cancelTokens, names) });
  }

  cancelTokens(...names) {
    const tokens = pick(this.state.cancelTokens, names);
    tokens && Object.keys(tokens).forEach((key) => tokens[key].cancel('Cancel request'));
    this.clearTokens(...names);
  }

  splitPackage() {
    const {
      integrationState: { isWaLeaf },
      selectedProducts,
      actions: { addMessage },
      complianceSettings: { inv_packages_require_tracking_id_message, inv_packages_require_tracking_id }
    } = this.props;

    if (inv_packages_require_tracking_id) {
      const somePackagesDontHaveTrackingId = selectedProducts.some((item) => {
        return isInvPackageTrackingNotInCompliance(item);
      });

      if (somePackagesDontHaveTrackingId) {
        addMessage('error', inv_packages_require_tracking_id_message, true);
        return false;
      }
    }

    if (isWaLeaf) {
      if (!validateWaSplit(selectedProducts, isWaLeaf)) {
        const packageIds = selectedProducts
          .map((item) => {
            if (item.medically_compliant_status === 'pending') {
              return item.package_code;
            }
          })
          .filter(Boolean);
        if (packageIds.length) {
          addMessage('error', ['inventory.splitPackage.pendingSplitError', { packageIds }]);
          return false;
        }
      }
    }
    this.props.actions.push(`/split_package`);
    return true;
  }

  addTestResult() {
    const {
      selectedProducts,
      complianceSettings: { inv_packages_require_tracking_id_message, inv_packages_require_tracking_id },
      actions: { addMessage }
    } = this.props;

    if (inv_packages_require_tracking_id) {
      const somePackagesDontHaveTrackinId = selectedProducts.some((item) => {
        return isInvPackageTrackingNotInCompliance(item);
      });

      if (somePackagesDontHaveTrackinId) {
        addMessage('error', inv_packages_require_tracking_id_message, true);
        return false;
      }
    }
    this.props.actions.push(`/test-results/add`);
    return true;
  }

  releaseAllReservations() {
    this.setState({ showReleaseReservationsConfirmModal: true });
  }

  releaseAllReservationsConfirmed() {
    this.props.actions.deleteItem('/api/item_reservations/all', 'none', {
      success: 'reservations.clearSuccess',
      fail: 'plants.actions.updateSearchFail'
    });
    this.props.actions.deleteItem('/api/product_reservations/all', 'none', {
      success: 'reservations.clearSuccess',
      fail: 'plants.actions.updateSearchFail'
    });
    this.setState({ showReleaseReservationsConfirmModal: false });
  }

  updateSearch() {
    this.props.actions.postItem('/api/search/update', { core: 'inventory' }, null, {
      success: 'plants.actions.updateSearchSuccess',
      fail: 'plants.actions.updateSearchFail'
    }).catch(error => {
      if (this.props.troubleshootAlbEnabled && this.state.troubleshootAlbRetries === 0) {
        if (error.response.status === 503) {
          setTimeout(() => {
            this.setState({
              troubleshootAlbRetries: 1
            }, () => {
              this.updateSearch();
            });
          }, 2000);
        }
      }
    });
    this.setState({ reindexing: true });
  }

  dataUpdated() {
    this.setState({ reindexing: false });
  }

  reload(unsetData) {
    unsetData && this.props.actions.unsetData(dataNames.finishedProducts);
    this.props.actions.clearSelectedData(dataNames.products);
    this.hideActivateModal();
    this.hidePrinter();
  }

  setActiveTab(activeTab, cb = () => {}) {
    this.setState({ activeTab }, cb);
  }

  filter(tabEventKey) {
    this.unsetSearchData();
    const activeTab = this.state.tabs.find((tab) => tab.eventKey === tabEventKey);
    if (activeTab) {
      if (this.ref.current) {
        this.ref.current.wrappedInstance.filter(activeTab.filter);
      }
    }
    this.reload(true);
  }

  handleSearch(sort = 'id asc', query = 'matchall', size, start, filter) {
    //Skip searching before the tab filter is set
    this.setState({ ready: false });
    const { activeTab } = this.state;
    if (!filter || !activeTab) {
      return;
    }

    const group = {
      'group': 'true', //eslint-disable-line
      'group.format': 'grouped', //eslint-disable-line
      'group.field': activeTab.groupField, //eslint-disable-line
      'group.ngroups': 'true' //eslint-disable-line
    };
    const params = { sort, query, size, start, filter, group };
    const cancelToken = this.ensureToken('search');
    const config = !this.props.actions.setSolrErrorMessage
      ? null
      : {
        errorHandler: {
          message: 'cultivation.finishedProduct.table.solrError',
          action: this.props.actions.setSolrErrorMessage,
          clearOnSuccess: true,
        }
      };

    // console.log('-----> @@@ AP @@ -----> handleSearch@query - solrCoreName', params, this.props.solrCoreName);
    // TODO: for solr rework - new inventory search - refactor after proof of rework concept

    this.props.actions.getSearchData(
      '/api/search/inventory',
      dataNames.products,
      null,
      params,
      (groups) => {
        this.clearTokens('search');
        this.fetchChildren(groups, { sort, query });
      },
      cancelToken.token,
      config
    );
  }

  fetchChildren(products = this.props.products, { sort = 'id asc', query = 'matchall' }) {
    const cancelToken = this.ensureToken('fetchChildren');
    const cancelLabResultToken = this.ensureToken('labResult');
    const { activeTab } = this.state;
    const pattern = /([\!\*\+\-\ \=\<\>\&\|\(\)\[\]\{\}\^\~\?\:\\\/"])/g;

    if (activeTab) {
      const { quickMoveDisabled } = this.state;
      let size = 0;
      const filter =
        '( ' +
        products.reduce((acc, val) => {
          size = size + val.doclist.numFound;
          const filterString = `${activeTab.groupField}: ${(val.groupValue || val.group_id_on_lot_number)
            .trim()
            .replace(pattern, '\\$1')}`;
          return acc === '' ? filterString : acc + ' OR ' + filterString;
        }, '') +
        ' )' +
        ` AND ${activeTab.childrenFilter}`;
      const params = { query, size, sort, filter };

      // console.log('----> @@ AP @@ -----> 2 @fetchChildren@query', params);
      // TODO: for solr rework - new inventory search - refactor after proof of rework concept

      if (products.length) {
        this.props.actions.getDataByPost(
          '/api/search/inventory',
          params,
          dataNames.childProducts,
          null,
          null,
          (result) => {
            this.setState({
              ready: true,
              quickMoveDisabled: quickMoveDisabled === 'pending' ? false : quickMoveDisabled
            });
            const items_id = result.map((item) => item.package_id);
            this.getLabResults(items_id, cancelLabResultToken);
            this.getItemDestructions(result);
            this.clearTokens('fetchChildren');
          },
          cancelToken.token
        );
      }
    }
  }

  getLabResults(ids, token) {
    if (ids.length) {
      const query = 'matchall';
      const size = 10000;
      const sort = 'testing_date desc';
      const filter =
        ids.reduce((acc, id) => {
          return acc ? acc + ` OR ${id}` : `package_id: (${id}`;
        }, '') + `) AND is_latest_lab_result: 1`;
      const params = { query, size, sort, filter };
      this.props.actions.getDataByPost(
        '/api/search/lab_results',
        params,
        dataNames.testResults,
        null,
        null,
        () => {
          this.clearTokens('labResult');
        },
        token.token
      );
    }
  }

  getItemDestructions(items) {
    const {
      integrationState: { isBiotrack },
      actions: { getDataBatchByPost }
    } = this.props;
    const item_ids = items.filter((item) => item.is_destroyed).map((item) => item.id);
    if (isBiotrack && item_ids.length && getDataBatchByPost) {
      getDataBatchByPost('/api/biotrack/item_destructions', { item_ids }, dataNames.biotrackItemDestructions, {
        failed: 'destructions.get.failed'
      });
    }
  }

  onSearchChange(query) {
    if (!query) {
      this.props.actions.clearSelectedData(dataNames.products);
    }
  }

  handleSelect(action, rows) {
    this.props.actions.handleComplexSelectRow(rows, dataNames.products, action);
  }

  unsetSearchData() {
    this.props.actions.unsetData(dataNames.products);
    this.props.actions.unsetData(dataNames.childProducts);
  }

  hideActivateModal() {
    this.setState({ showActivateModal: false });
  }

  showReservationsModal(selectedPackage) {
    this.setState({
      showReservationsModal: true,
      packageIdForReservationsModal: get(selectedPackage, 'package_id'),
      packageMetaData: {
        item_master_name: get(selectedPackage, 'name', get(selectedPackage, 'item_name')), // Comes from name
        item_master_id: get(selectedPackage, 'item_master_id'), // Comes from name
        package_id: get(selectedPackage, 'package_code', '')
          .trim()
          .toUpperCase(),
        batch_id: get(selectedPackage, 'lot_number', '')
          .trim()
          .toUpperCase(), // Comes from package_code
        location_name: get(selectedPackage, 'location_name') // Comes from location name
      }
    });
  }

  hideReservationsModal() {
    this.setState({ showReservationsModal: false });
  }

  showTransferModal() {
    this.setState({
      showTransferModal: true
    });
  }

  hideTransferModal() {
    this.setState({
      showTransferModal: false
    });
  }

  trClassName(useEntityLocks, row) {
    return useEntityLocks && row.is_locked ? 'entity-locked-table-row' : null;
  }
}

export default AbstractInventoryPage;
