import {createSelector} from 'reselect';
import {formValueSelector} from 'redux-form';
import orderBy from 'lodash.orderby';

import {CURE_API} from '../../constants/integrations';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import * as messageTypes from '../../constants/messageTypes';

import {COMMON_PRIVATE_IMAGE, FILE_TYPES} from '../../constants/fileUploads';
import {medical_id} from '../../constants/fileTypes';
import {convert} from '../../util/uomHelpers';
import {getStateIntegrator} from './commonSelectors';


export const isCureIntegrator = createSelector(
  [getStateIntegrator],
  integrator => integrator === CURE_API
);

/**
 * Get Cure Category Settings
 * @typedef {Object} CureCategorySettings
 * @property {Object} forms list of allowed forms (Oral/Rectal/etc)
 * @property {Object} order_types list of allowed order types (High/Low HTC)
 *
 * @param {Object} state
 * @return {CureCategorySettings}
 */
export const getCureCategorySettings = state => state[itemNames.cureCategorySettings];

/**
 * Get stored subcategory mapping settings
 * @typedef {Object} CureSubcategoryMapping
 * @property {number} subcategory_id MJP subcategory ID
 * @property {number} form_id Cure API form (delivery method) ID
 * @property {number} order_type_id Cure API order type ID
 *
 * @param state
 * @return {CureSubcategoryMapping[]}
 */
export const getCureSubcategoryMappings = state => state[dataNames.cureCategoryMappings];

/**
 * Get Cure Patient List
 *
 * @typedef {{ patient_number: string, first_name: string, last_name: string, dob: string, orders: CurePatientOrder[], consumer: Object }} CurePatient
 * @typedef {{ order_id: number, physician_name: string, status: string, start_date: string, delivery_device_purchasable: bool, forms: CurePatientOrderForm[] }} CurePatientOrder
 * @typedef {{ form_id: number, subcategory_form: {id: number, name: string}, subcategory_type: {id: number, name: string}, milligrams_remaining: number, milligrams_per_dose: number, dose_per_day: number, notes: string }} CurePatientOrderForm
 *
 * @param {Object} state
 * @return {CurePatient[]}
 */
export const getCurePatients = state => state[dataNames.curePatients];
/**
 * Get Cure Patient
 * @param {Object} state
 * @return {CurePatient}
 */
export const getCurePatientRaw = state => state[itemNames.curePatient];
export const getCureCaregiverRaw = state => state[itemNames.cureCaregiver];

/**
 * Get Order Product to Form ID mapping
 * @typedef {{ id: number, order_id: number, order_product_id: number, form_id: number }} CureOrderProduct
 * @property {number} id
 * @property {number} order_id
 * @property {number} order_product_id
 * @property {number} form_id
 *
 * @param {Object} state
 * @return {CureOrderProduct[]}
 */
export const getCureOrderProducts = state => state[dataNames.cureOrderProducts];

/**
 * We will be using single selector for several cases
 * Supported values:
 * - customer - seeking patient from dataNames.curePatients against props.customer
 * - patient - patient will be taken from props.patient
 * @param _
 * @param {object} props
 * @return {string}
 */
const getMode = (_, props) => props && props.mode || 'customer';
const getCustomer = (_, props) => props && props.customer;
const getPatient = (_, props) => props && props.curePatient;
/**
 * Do not importing this to prevent circle dependencies
 * @param {Object} state
 * @return {Object}
 */
const getOrder = state => state[itemNames.order];

const form = 'cureApiProductFilter';
const formSelector = formValueSelector(form);
const getOrderTypeIdFilter = state => formSelector(state, 'order_type_id');
const getFormIdsFilter = state => formSelector(state, 'form_ids');

/**
 * Map settings to options
 * @param {CureCategorySettings} settings
 * @param {string} key
 * @return {CureOption[]}
 */
const mapValues = (settings, key) => {
  const values = settings && settings[key] || [];
  return orderBy(Object.keys(values).map(createOption(values)), 'text');
};

/**
 * @typedef {{ text: string, value: number }} CureOption
 * @property {number} value
 * @property {string} text
 *
 * @param {Object} obj
 * @return { function(string): CureOption }
 */
const createOption = obj => key => ({
  text: obj[key],
  value: Number(key),
});

/**
 * Get Cure Form Options
 * @return {CureOption[]}
 */
export const getCureFormOptions = createSelector(
  [getCureCategorySettings],
  (settings) => mapValues(settings, 'forms')
);

/**
 * Get Cure Order Type Options
 * @return {CureOption[]}
 */
export const getCureOrderTypeOptions = createSelector(
  [getCureCategorySettings],
  (settings) => mapValues(settings, 'order_types')
);

export const getCurePatientByMode = createSelector(
  [getCurePatients, getCustomer, getPatient, getMode],
  (curePatients, customer, patient, mode) => {
    switch (mode) {
    case 'patient':
      return patient || {};
    case 'customer':
    default:
      return customer && curePatients.find(patient => patient.consumer && patient.consumer.id === customer.id) || {};
    }
  }
);

export const getCurePatientOrderGroups = createSelector(
  [getCurePatientByMode, getCureFormOptions, getCureOrderTypeOptions],
  getOrdersWithLimitsImplementation
);

export const getCurePatientOrderGroupsAgainstOrder = createSelector(
  [getCurePatientOrderGroups, getOrder, getCureOrderProducts, getCureSubcategoryMappings],
  getOrdersWithLimitsAgainstOrderImplementation
);

/**
 * Aggregate over the consumer orders and collect available form+type combinations
 * @typedef {{ subcategory_form_id: number, subcategory_type_id: number, milligrams_remaining: number }} CurePatientLimit
 * @property {number} subcategory_form_id Cure API Form ID
 * @property {number} subcategory_type_id Cure API Type ID
 * @property {number} milligrams_remaining Remaining available medicated amount in milligrams
 *
 * @return {CurePatientLimit[]}
 */
export const getCurePatientLimits = createSelector(
  [getCurePatientOrderGroups],
  (groups) => {
    const activeGroup = groups.find(group => group.status === 'open');
    return activeGroup && activeGroup.patientLimits || [];
  }
);

const getCurePatientLimitsFiltered = createSelector(
  [getCurePatientLimits, getOrderTypeIdFilter, getFormIdsFilter],
  (limits, orderType, forms = []) => limits.filter(limit => {
    const inputsAreEmpty = !forms.length && !orderType;
    const isProductFilterEmpty = !orderType;
    const isDeliveryMethodEmpty = !forms.length;
    const productMatches = limit.subcategory_type_id === orderType;
    const deliveryMethodMatches = forms.some(form => form.value === limit.subcategory_form_id);

    return inputsAreEmpty
      || (productMatches && deliveryMethodMatches)
      || (productMatches && isDeliveryMethodEmpty)
      || (deliveryMethodMatches && isProductFilterEmpty);
  })
);

export const areCureProductFiltersEmpty = createSelector(
  [getOrderTypeIdFilter, getFormIdsFilter],
  (orderType, forms = []) => !orderType && !forms.length
);


/**
 * Get cure order type options based on patient limits
 * @return {CureOption[]}
 */
export const getCurePatientOrderTypeOptions = createSelector(
  [getCurePatientLimits, getCureOrderTypeOptions],
  (limits, options) => {
    return options.filter(option => limits.some(limit => limit.subcategory_type_id === option.value));
  }
);

/**
 * Get cure form options based on patient limits
 * @return {CureOption[]}
 */
export const getCurePatientFormOptions = createSelector(
  [getCurePatientLimits, getCureFormOptions],
  (limits, options) => options.filter(option => limits.some(limit => limit.subcategory_form_id === option.value))
);

/**
 * Add names for patient limit groups
 * @typedef {CurePatientLimit} CurePatientPurchasableAmount
 * @property {string} name
 * @property {string} form_name
 * @property {string} order_type_name
 * @return {CurePatientPurchasableAmount[]}
 */
export const getCurePatientPurchasableAmounts = createSelector(
  [getCureFormOptions, getCureOrderTypeOptions, getCurePatientLimits],
  getCurePatientPurchasableAmountsImplementation
);

export const getCureAmountsAgainstOrder = createSelector(
  [getCurePatientPurchasableAmounts, getOrder, getCureOrderProducts, getCureSubcategoryMappings],
  getCureAmountsAgainstOrderImplementation
);

export const getCurePatientAsCustomer = createSelector(
  [getCurePatientRaw],
  (patient) => {
    if (!patient.first_name) {
      return {};
    }
    return {
      customer: {
        first_name: patient.first_name,
        last_name: patient.last_name,
        birth_date: patient.dob,
      },
      ids: [{
        type: medical_id,
        file_type: FILE_TYPES[COMMON_PRIVATE_IMAGE].type,
        identification_number: patient.patient_number,
      }],
    };
  }
);

/**
 * Get available delivery methods based on subcategory
 * @return {Object}
 */
export const getFormOptionsBySubcategory = createSelector(
  [getCureFormOptions, getCureSubcategoryMappings, getCurePatientLimits],
  getFormOptionsBySubcategoryImplementation
);

/**
 * Get white list of subcategories based on customer Cure limits
 * @return {number[]}
 */
export const getAllowedSubcategories = createSelector(
  [getCurePatientLimitsFiltered, getCureSubcategoryMappings],
  getAllowedSubcategoriesImplementation
);

export const isValidLimits = createSelector([getCureAmountsAgainstOrder], (patientLimits) => {
  return !patientLimits.find(limit => limit.grams_remaining - limit.complianceWeight < 0);

});


export const handleCureApiValidationErrors = (e, addMessage) => {
  const errors = (e.response.data && e.response.data.errors) ? e.response.data.errors : [];
  Object.values(errors).forEach((errorGroup) => {
    errorGroup.map(es => {
      Object.values(es).map(e => addMessage(messageTypes.error, e, true));
    });
  });
  return true;
};

/**
 * Calculate available subcategories based on customer Cure limits
 * @param {CurePatientLimit[]} limits
 * @param {CureSubcategoryMapping[]} mappings
 * @return {number[]}
 */
function getAllowedSubcategoriesImplementation(limits, mappings) {
  const map = mappings.reduce(
    (acc, mapping) => {
      //If subcategory is not yet in the list and it can be used based on patient limits, add it to list
      if (acc.indexOf(mapping.subcategory_id) === -1 && validateSubcategoryMappingAgainstLimits(mapping, limits)) {
        acc.push(mapping.subcategory_id);
      }
      return acc;
    },
    []
  );
  return map;
  // const nonmedCodes = nonmed.map(s => s.id);
  // console.log(map);
  // return union(nonmedCodes, map);
}

/**
 * Get available form options for given subcategory based on subcategory mappings and patient limits
 * @param {CureOption[]} options
 * @param {CureSubcategoryMapping[]} mappings
 * @param {CurePatientLimit[]} limits
 * @return {Object}
 */
function getFormOptionsBySubcategoryImplementation(options, mappings, limits) {
  return mappings.reduce(
    (acc, mapping) => {
      if (validateSubcategoryMappingAgainstLimits(mapping, limits)) {
        if (!acc[mapping.subcategory_id]) {
          acc[mapping.subcategory_id] = [];
        }
        const option = options.find(option => option.value === mapping.form_id);
        acc[mapping.subcategory_id].push(option);
      }
      return acc;
    },
    {}
  );
}

/**
 * Check if existing category mapping is applicable
 * @param {CureSubcategoryMapping} mapping
 * @param {CurePatientLimit[]} limits
 * @return {boolean}
 */
function validateSubcategoryMappingAgainstLimits (mapping, limits) {
  return limits.some(limit => limit.subcategory_form_id === mapping.form_id && limit.subcategory_type_id === mapping.order_type_id);
}

/**
 * Calculate limits by orders
 * @param {CurePatientOrder[]} orders
 * @return {CurePatientLimit[]}
 */
function getPatientLimitsImplementation(orders) {
  return orders.reduce(
    (acc, order) => {
      return (order.routes || []).reduce(
        (acc, route) => {
          //Grouping items by both form_id and type_id
          let formLimit = acc.find(item => item.subcategory_form_id === route.subcategory_form.id && route.subcategory_type.id === item.subcategory_type_id);

          if (!formLimit) {
            formLimit = {
              subcategory_form_id: route.subcategory_form.id,
              subcategory_type_id: route.subcategory_type.id,
              milligrams_remaining: 0,
            };
            acc.push(formLimit);
          }

          formLimit.milligrams_remaining += route.milligrams_remaining;

          return acc;
        },
        acc
      );
    },
    []
  );
}

/**
 * Get Purchasable amounts based on patient limits
 * @param {CureOption[]} cureForms
 * @param {CureOption[]} cureOrderTypes
 * @param {CurePatientLimit[]} patientLimits
 * @return {CurePatientPurchasableAmount[]}
 */
function getCurePatientPurchasableAmountsImplementation(cureForms, cureOrderTypes, patientLimits) {
  return patientLimits.reduce(
    (acc, patientLimit) => {
      const cureForm = cureForms.find(form => form.value === patientLimit.subcategory_form_id);
      const cureOrderType = cureOrderTypes.find(type => type.value === patientLimit.subcategory_type_id);
      if (cureForm && cureOrderType) {
        acc.push({
          ...patientLimit,
          form_name: cureForm.text,
          order_type_name: cureOrderType.text,
          name: `${cureForm.text} ${cureOrderType.text}`,
          grams_remaining: convert(patientLimit.milligrams_remaining, 'MG', 'GR'),
        });
      }
      return acc;
    },
    []
  );
}

function getCureAmountsAgainstOrderImplementation(purchasableAmounts, order, cureOrderProducts, mappings) {
  return purchasableAmounts.map((purchasableAmount) => {

    const productIsValid = (product) => {
      const mapping = mappings.find(m => m.subcategory_id === product.subcategory_id);
      const cureOrderProduct = cureOrderProducts.find(p => Number(p.order_id) === order.id && Number(p.order_product_id) === product.id);
      return mapping && cureOrderProduct && mapping.order_type_id === purchasableAmount.subcategory_type_id && cureOrderProduct.form_id === purchasableAmount.subcategory_form_id;
    };

    const complianceWeight = (order.products || []).reduce((acc, product) => {
      if(productIsValid(product)){
        const complianceWeight = parseFloat(product.compliance_weight_grams);
        if(!isNaN(complianceWeight)){
          acc += complianceWeight;
        }
      }
      return acc;
    }, 0);

    const grams_in_order = (order.products || []).reduce(
      (amount, product) => {
        if(productIsValid(product)){
          const soldWeight = Number(product.sold_weight);
          const medicatedWeight = Number(product.medicated_weight) * product.quantity;
          amount += soldWeight || medicatedWeight;
        }
        return amount;
      },
      0
    );
    return {
      ...purchasableAmount,
      grams_in_order,
      complianceWeight: complianceWeight.toFixed(3),
    };
  });
}

function getOrdersWithLimitsImplementation(patient, cureForms, cureOrderTypes) {
  const orders = orderBy(patient.orders || [], 'start_date', 'asc');
  const groups = orders.reduce(
    (groups, order) => {
      if (order.status === 'open') {
        let group = groups.find(group => group.status === order.status);
        if (!group) {
          group = {status: order.status, orders: []};
          groups.push(group);
        }
        group.orders.push(order);
        return groups;
      }
      return groups.concat({status: order.status, orders: [order]});
    },
    []
  );

  return groups.map(group => {
    const patientLimits = getPatientLimitsImplementation(group.orders);
    const purchasableAmounts = getCurePatientPurchasableAmountsImplementation(cureForms, cureOrderTypes, patientLimits);

    return {
      ...group,
      patientLimits,
      purchasableAmounts,
    };
  });
}

function getOrdersWithLimitsAgainstOrderImplementation(groups, order, cureOrderProducts, mappings) {
  return groups.map(group => {
    if (group.status === 'open') {
      return {
        ...group,
        purchasableAmounts: getCureAmountsAgainstOrderImplementation(group.purchasableAmounts, order, cureOrderProducts, mappings),
      };
    }
    return group;
  });
}
