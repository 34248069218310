import get from 'lodash.get';
import * as p from '../../../constants/permissions';
import {areItemsProcessableSplit} from '../../../selectors/inventoryItemsSelectors';
import {getIntegrationState} from '../../../selectors/integration/integrationSelectors';
import {areSelectedProductsDestroyed} from '../../../selectors/productsSelectors';
import {isActiveFacilityGrow} from '../../../selectors/facilitiesSelectors';
import {getCoreSettings} from '../../../selectors/coreSettingsSelectors';
import {labResultsHasEditPermission} from '../../../selectors/accessSelectors';
import {getDisallowTestResultCreation} from '../../../selectors/testResultsSelectors';
import {isNewPackagingJobsFeatureEnabled} from '../../../selectors/featureToggles';

export function modifyLot(component) {
  return {
    id: 'modifyLot',
    path: '/packages/modify',
    text: 'ei.inventory.actions.modifyLot',
    requireSelect: true,
  };
}

export function createPropagationMaterial(component) {
  return {
    id: 'createPropagationMaterial',
    path: '/propagation-material',
    text: 'inventory.actions.createPropagationMaterial',
    requireSelect: false,
  };
}

export function addTestResult(component) {
  const {props: {state}} = component;
  const hasTestResultPermission = labResultsHasEditPermission(state);
  if (!hasTestResultPermission) return '';

  return {
    id: 'addTestResults',
    func: () => component.addTestResult(),
    path: '/test-results/add',
    text: 'products.actions.addTestResults',
    requireSelect: false,
    disabled: () => {
      const {
        props: {
          state,
          selectedProducts
        }
      } = component;

      return getDisallowTestResultCreation(state)(selectedProducts);
    }
  };
}

export function splitPackage(component) {
  return {
    id: 'splitPackage',
    func: () => component.splitPackage(),
    path: '/split_package',
    text: 'inventory.splitPackage.title',
    requireSelect: false,
    disabled: () => {
      const {props: {state, selectedProducts}} = component;
      const {isWaLeaf} = getIntegrationState(state);
      if (isWaLeaf) {
        return !areItemsProcessableSplit(selectedProducts, isWaLeaf);
      }
      return false;
    }
  };
}

export function releaseAllReservations(component) {
  return {
    id: 'releaseAllReservations',
    variant: 'danger',
    func: component.releaseAllReservations,
    text: 'inventory.reservations.releaseAllReservations',
    requireSelect: false,
    permissions: [p.manage_reservations]
  };
}

export function showTransferModal(component){
  const {props: {state}} = component;
  // We want it to be enabled if the setting doesn't exist, hence the 'true' default on the getter
  const core_internal_transfers_enable = get(getCoreSettings(state), 'core_internal_transfers_enable.value', true);
  if (!core_internal_transfers_enable) {
    return null;
  }
  return {
    id: 'showTransfermodal',
    variant: 'primary',
    func: component.showTransferModal,
    text: 'inventory.showTransferModal',
    requireSelect: true,
    permissions: [p.manage_internal_transfers],
  };
}


export function createContainer(component) {
  return {
    id: 'createContainer',
    path: '/containers/create',
    text: 'products.actions.createContainer',
    requireSelect: true,
    active: true,
  };
}

export function updateSearch(component) {
  return {
    id: 'updateSearch',
    func: component.updateSearch,
    text: 'plants.actions.updateSearch',
    glyph: 'arrow-up',
    variant: 'warning',
    requireSelect: false,
    disabled: () => component.state.reindexing
  };
}

export function activateProducts(component) {
  return {
    id: 'activateProducts',
    func: component.showActivateModal,
    text: 'cultivation.finishedProduct.actions.activate',
    requireSelect: true,
    permissions: [p.manage_inventory],
    disabled: () => {
      const {props: {state}} = component;
      return areSelectedProductsDestroyed(state);
    }
  };
}

export function startPackagingJob(component) {
  const {props: {state}} = component;
  if (!isNewPackagingJobsFeatureEnabled(state)) {
    return null;
  }
  return {
    id: 'startPackagingJob',
    func: () => component.startJob('packaging'),
    path: '/packaging_jobs/packaging_job',
    text: 'packages.actions.startPackagingJob',
    permissions: [p.manage_inventory],
    // TODO: not sure do we need new permissions?: [p.manage_packaging],
    requireSelect: false,
  };
}

export function startProcessing(component) {
  return {
    id: 'startProcessing',
    func: () => component.startJob('processing'),
    path: '/ei/processing/start',
    text: 'ei.inventory.actions.startProcessing',
    permissions: [p.manage_processing],
    requireSelect: false,
  };
}

export function startInfusion(component) {
  return {
    id: 'startProcessing',
    func: () => component.startJob('infusions'),
    path: '/ei/processing/start',
    text: 'ei.inventory.actions.startInfusion',
    permissions: [p.manage_infusions],
    requireSelect: false,
  };
}

export function reportDailyWaste(component) {
  const {props: {state}} = component;
  const {isCure, isCanada, isPaLeaf, isMetrc} = getIntegrationState(state);
  const hideButton = isCure || isCanada || isPaLeaf || isMetrc || !isActiveFacilityGrow(state);

  if (hideButton) {
    return null;
  }

  return {
    id: 'reportDailyWaste',
    //func: () => component.startJob('infusions'),
    func: () => component.showWasteModal(),
    //path: '/ei/processing/start',
    text: 'ei.inventory.actions.reportDailyWasteByLocation',
    requireSelect: false,
  };
}


export function destroyWaste(component) {
  const {props: {state}} = component;
  const {isWaLeaf} = getIntegrationState(state);
  const hideButton = !isWaLeaf;

  if (hideButton) {
    return null;
  }

  return {
    id: 'destroyWaste',
    func: () => component.showWasteDestructionModal(),
    text: 'packages.destroy.destroy',
    requireSelect: true,
  };
}

export function destroyItems(component) {
  const {props: {state}} = component;
  const {isBiotrack} = getIntegrationState(state);

  if (!isBiotrack) {
    return null;
  }

  return {
    id: 'destroyItems',
    path: '/packages/destroy',
    text: 'ei.inventory.actions.destroyPackage',
    requireSelect: false,
  };
}
