import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {routerMiddleware} from 'react-router-redux';
import {enableBatching} from 'redux-batched-actions';
import createSocketIoMiddleware from 'redux-socket.io';
import io from 'socket.io-client';

import rootReducer from '../reducers';
import {cleanRewardAdjustments} from '../middleware/customerMiddleware';
import {validateAuth} from '../middleware/validateAuth';
import {loginSuccessFacilityRedirect, requireFacility, setFacilityRedirect, setFaciltyTimezone, setFacilityLanguage} from '../middleware/facilities';
import {setFacilityGetNotifications, receiveNewUserAlert, refundMayDiffer, patientLimitsExceeded} from '../middleware/notifications';
import {productChangeGeneratePackageId} from '../middleware/harvestPackages';
import {checkCartDrawer, checkRegisterLocation, setOrderFulfillmentStatus, dispatchSalesComplianceError} from '../middleware/cartController';
import {validateEqualProductCounts} from '../middleware/supplyChainMapping';
import {handleApiSuccess} from '../middleware/apiMetaData';
import transformApiResponseData from '../middleware/apiResponseDataTransforms'; //eslint-disable-line
import debounceBatchActions from '../middleware/debounceBatchActions';
import debouncedActionTypes from '../constants/debouncedActionTypes';
import {loginFailure} from '../middleware/loginFailure';
import {salesRegisters} from '../middleware/salesRegistersMiddleware';
import {categoriesMiddleware} from '../middleware/categoriesMiddleware';
import socketDispatches from '../middleware/socketDispatches';
import {systemMessages, reduxFormSubmitErrors} from '../middleware/systemMessages';
import formMiddlewares from '../middleware/forms';
import {getDataUpdates} from '../middleware/dataUpdates';
import {isIntegrationSetup, blockSetupModeNav} from '../middleware/setupMode';
import {handlePrintJobMessage, setSelectedPrinterByTag, printJobsHandler} from '../middleware/printerMiddleware';
import {featureToggleUpdated} from '../middleware/featureToggles';
import supplyChainUpdated from '../middleware/supplyChainUpdated';
// eslint-disable-next-line import/no-named-as-default
import openFormMiddleware from '../middleware/forms/openFormMiddleware';
import inventorySync from '../middleware/inventorySyncMiddleware';
import inventoryReceipt from '../middleware/inventoryReceiptMiddleware';

const socket = io('/', {path: '/socket.io',
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax : 5000,
  reconnectionAttempts: Infinity});
const socketMiddleware = createSocketIoMiddleware(socket, 'SOCKET_');

export default function configureStore(initialState, history) {
  return createStore(enableBatching(rootReducer),
    initialState,
    applyMiddleware(
      debounceBatchActions(debouncedActionTypes),
      thunk,
      setFacilityLanguage,
      validateAuth,
      handleApiSuccess,
      transformApiResponseData,
      blockSetupModeNav,
      openFormMiddleware,
      routerMiddleware(history),
      setFaciltyTimezone,
      loginSuccessFacilityRedirect,
      requireFacility,
      getDataUpdates,
      setFacilityGetNotifications,
      receiveNewUserAlert,
      refundMayDiffer,
      patientLimitsExceeded,
      productChangeGeneratePackageId,
      checkCartDrawer,
      checkRegisterLocation,
      setOrderFulfillmentStatus,
      dispatchSalesComplianceError,
      validateEqualProductCounts,
      setFacilityRedirect,
      loginFailure,
      socketMiddleware,
      socketDispatches,
      systemMessages,
      reduxFormSubmitErrors,
      isIntegrationSetup,
      handlePrintJobMessage,
      setSelectedPrinterByTag,
      printJobsHandler,
      salesRegisters,
      cleanRewardAdjustments,
      categoriesMiddleware,
      featureToggleUpdated,
      supplyChainUpdated,
      inventorySync,
      inventoryReceipt,
      ...formMiddlewares
    )
  );
}
