import map from 'lodash.map';

export const MAX_UPLOAD_SIZE = 20971520;
export const MAX_UPLOAD_SIZE_DISPLAY = '10 MB';
export const EQUIPMENT_MAX_UPLOAD_SIZE_DISPLAY = '10 MB';
export const MAX_ALTERNATE_PRODUCT_IMAGES = 3;

//File types
export const CONSUMER_AGREEMENT = 'CONSUMER_AGREEMENT';
export const DRIVERS_LICENSE = 'DRIVERS_LICENSE';
export const PRODUCT_IMAGE = 'PRODUCT_IMAGE';
export const COMMON_PUBLIC_IMAGE = 'COMMON_PUBLIC_IMAGE';
export const COMMON_PUBLIC_DOCUMENT = 'COMMON_PUBLIC_DOCUMENT';
export const COMMON_PRIVATE_IMAGE = 'COMMON_PRIVATE_IMAGE';
export const COMMON_PRIVATE_DOCUMENT = 'COMMON_PRIVATE_DOCUMENT';
export const PARTNER_LOGO = 'PARTNER_LOGO';
export const PARTNER_STATE_LICENSE = 'PARTNER_STATE_LICENSE';
export const PARTNER_WHOLESALE_TAX_ID = 'PARTNER_WHOLESALE_TAX_ID';
export const PARTNER_STATE_TAX_ID = 'PARTNER_STATE_TAX_ID';
export const CULTIVATION_EQUIPMENT_PHOTO = 'CULTIVATION_EQUIPMENT_PHOTO';
export const RETAIL_ORDER_IMPORT_FILE = 'RETAIL_ORDER_IMPORT_FILE';
export const COMMON_PRIVATE_WORD_DOCUMENT = 'COMMON_PRIVATE_WORD_DOCUMENT';
export const COMMON_LAB_CERTIFICATE_OF_ANALYSIS = 'COMMON_LAB_CERTIFICATE_OF_ANALYSIS';
export const LAB_RESULTS_IMPORT_FILE = 'LAB_RESULTS_IMPORT_FILE';
export const ADJUST_IMPORT_FILE = 'ADJUST_IMPORT_FILE';
export const PARTNER_COUNTY_LICENSE = 'PARTNER_COUNTY_LICENSE';

//Types
export const types = {
  document: 'document',
  image: 'image',
  importFile: 'importFile',
  wordDocument: 'wordDocument'
};

const typeLimits = {
  [types.document]: {
    accept: ['application/pdf'],
    extensions: ['PDF']
  },
  [types.image]: {
    accept: ['image/jpeg', 'image/png'],
    extensions: ['JPG', 'PNG']
  },
  [types.importFile]: {
    accept: [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/csv',
      'text/csv',
      '.csv'
    ],
    extensions: ['XLS', 'XLSX', 'CSV']
  },
  [types.wordDocument]: {
    accept: ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', '.doc'],
    extensions: ['DOC', 'DOCX']
  }
};

const configureType = (file_type, type) => ({
  ...typeLimits[type],
  file_type,
  type
});

export const FILE_TYPES = {
  [CONSUMER_AGREEMENT]: configureType(CONSUMER_AGREEMENT, types.document),
  [DRIVERS_LICENSE]: configureType(DRIVERS_LICENSE, types.image),
  [PRODUCT_IMAGE]: configureType(PRODUCT_IMAGE, types.image),
  [COMMON_PUBLIC_IMAGE]: configureType(COMMON_PUBLIC_IMAGE, types.image),
  [COMMON_PUBLIC_DOCUMENT]: configureType(COMMON_PUBLIC_DOCUMENT, types.document),
  [COMMON_PRIVATE_IMAGE]: configureType(COMMON_PRIVATE_IMAGE, types.image),
  [COMMON_PRIVATE_DOCUMENT]: configureType(COMMON_PRIVATE_DOCUMENT, types.document),
  [PARTNER_LOGO]: configureType(PARTNER_LOGO, types.image),
  [PARTNER_STATE_LICENSE]: configureType(PARTNER_STATE_LICENSE, types.document),
  [PARTNER_WHOLESALE_TAX_ID]: configureType(PARTNER_WHOLESALE_TAX_ID, types.document),
  [PARTNER_STATE_TAX_ID]: configureType(PARTNER_STATE_TAX_ID, types.document),
  [CULTIVATION_EQUIPMENT_PHOTO]: configureType(CULTIVATION_EQUIPMENT_PHOTO, types.image),
  [RETAIL_ORDER_IMPORT_FILE]: configureType(RETAIL_ORDER_IMPORT_FILE, types.importFile),
  [COMMON_PRIVATE_WORD_DOCUMENT]: configureType(COMMON_PRIVATE_DOCUMENT, types.wordDocument),
  [COMMON_LAB_CERTIFICATE_OF_ANALYSIS]: configureType(COMMON_LAB_CERTIFICATE_OF_ANALYSIS, types.document),
  [LAB_RESULTS_IMPORT_FILE]: configureType(LAB_RESULTS_IMPORT_FILE, types.importFile),
  [ADJUST_IMPORT_FILE]: configureType(ADJUST_IMPORT_FILE, types.importFile),
  [PARTNER_COUNTY_LICENSE]: configureType(PARTNER_COUNTY_LICENSE, types.document),
};

FILE_TYPES.createFromMultiple = (types = []) => {
  const x = types.map((type) => FILE_TYPES[type]);
  return {
    accept: map(x, 'accept').flat(),
    extensions: map(x, 'extensions').flat(),
    file_type: types,
    type: 'multiple'
  };
};

FILE_TYPES.getFirstValidInternalType = (internalType, file) => {
  const types = Array.isArray(internalType) ? internalType : [internalType];
  const fileExtension = file.name
    ? file.name
      .split('.')
      .pop()
      .toUpperCase()
    : null;

  const appropriateType = types.find((type) => {
    const internalFileType = FILE_TYPES[type];
    return (
      internalFileType &&
      internalFileType.accept &&
      internalFileType.extensions &&
      internalFileType.accept.indexOf(file.type) !== -1 &&
      internalFileType.extensions.indexOf(fileExtension) !== -1
    );
  });
  return FILE_TYPES[appropriateType];
};

const allowedImageTypes = { mimeTypes: ['image/jpeg', 'image/png'], extensions: ['JPG', 'PNG'] };
const allowedDocumentTypes = { mimeTypes: ['application/pdf'], extensions: ['PDF'] };
const allowedImportFileTypes = { mimeTypes: ['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'], extensions: ['CSV, XLSX, XLS'] };

/**
 * @deprecated
 */
export const ALLOWED_FILE_TYPES = {
  COMMON_PRIVATE_DOCUMENT: allowedDocumentTypes,
  COMMON_PRIVATE_IMAGE: allowedImageTypes,
  COMMON_PUBLIC_DOCUMENT: allowedDocumentTypes,
  COMMON_PUBLIC_IMAGE: allowedImageTypes,
  CONSUMER_AGREEMENT: allowedDocumentTypes,
  CULTIVATION_EQUIPMENT_PHOTO: allowedImageTypes,
  DRIVERS_LICENSE: allowedImageTypes,
  PARTNER_LOGO: allowedImageTypes,
  PARTNER_STATE_LICENSE: allowedDocumentTypes,
  PARTNER_STATE_TAX_ID: allowedDocumentTypes,
  PARTNER_WHOLESALE_TAX_ID: allowedDocumentTypes,
  PRODUCT_IMAGE: allowedImageTypes,
  COMMON_LAB_CERTIFICATE_OF_ANALYSIS: allowedDocumentTypes,
  LAB_RESULTS_IMPORT_FILE: allowedImportFileTypes,
  PARTNER_COUNTY_LICENSE: allowedDocumentTypes
};
