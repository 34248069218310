import * as types from '../../constants/actionTypes';
import initialState from '../../constants/initialState';

export default function pricingWeightsReducer (state = initialState.cart.pricingWeights, action){
  const {pricingWeights} = action;
  switch (action.type) {
  case types.GET_PRICING_WEIGHTS_SUCCESS:
    return [...pricingWeights];
  case types.GET_PRICING_WEIGHTS_FAILED:
    return state;
  default:
    return state;
  }
}
