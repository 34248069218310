import * as types from '../constants/actionTypes';

export default function printerReducers (state = {}, action){

  const {payload} = action;

  if(types.SET_QZ_TRAY === action.type) {
    return Object.assign({}, payload);
  }

  return state;

}
