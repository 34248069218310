import * as types from '../constants/actionTypes';

export function showProductLookup(init) {
  return {type: types.PRODUCT_LOOKUP_SHOW, ...init};
}

export function hideProductLookup() {
  return {type: types.PRODUCT_LOOKUP_HIDE};
}

export function selectProductLookup(itemMaster) {
  return {
    type: types.PRODUCT_LOOKUP_SELECT,
    itemMasterId: itemMaster.id,
    itemMasterName: itemMaster.display_name || itemMaster.name,
    itemMasterDefaultUom: itemMaster.default_uom
  };
}

export function confirmProductLookup() {
  return {type: types.PRODUCT_LOOKUP_CONFIRM};
}

export function setProductLookupCategory(categoryId) {
  return {type: types.PRODUCT_LOOKUP_SET_CATEGORY, categoryId};
}

export function setProductLookupSubcategory(subCategoryId) {
  return {type: types.PRODUCT_LOOKUP_SET_SUBCATEGORY, subCategoryId};
}

export function searchProductLookup() {
  return {type: types.PRODUCT_LOOKUP_SEARCH};
}
