import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Card, Col, Row} from 'react-bootstrap';
import get from 'lodash.get';
import ItemDetailsTable from './ItemDetailsTable';
import {convertFromBase} from '../../../../util/uomHelpers';

const ItemMasterChangedDetails = ({item}) => {
  const {new_item, location_name} = item.transaction;
  if (new_item) {
    //Old item
    return (
      <Card>
        <Row>
          <Col xs={6}>
            <h3>{I18n.t('itemTransactions.item.newItemDetails')}</h3>
            {/* NOTE: The override for qty here is temporary. It's due to existing issues with the ItemTransactionsService on the backend */}
            <ItemDetailsTable item={{
              ...new_item,
              qty: convertFromBase(get(item, 'transaction.level_before', 0), new_item.uom)
            }} />
          </Col>
          <Col xs={6}>
            <h3>{I18n.t('itemTransactions.item.sourceItemDetails')}</h3>
            <ItemDetailsTable item={{...item, location_name: location_name ? location_name : new_item.location_name}}/>
          </Col>
        </Row>
      </Card>
    );
  } else {
    //New Item
    const {source_item} = item.transaction;
    return (
      <Card>
        <Row>
          <Col xs={6}>
            <h3>{I18n.t('itemTransactions.item.newItemDetails')}</h3>
            <ItemDetailsTable item={item}/>
          </Col>
          {source_item ? <Col xs={6}>
            <h3>{I18n.t('itemTransactions.item.sourceItemDetails')}</h3>
            <ItemDetailsTable item={source_item}/>
          </Col> : null}
        </Row>
      </Card>
    );
  }

};

ItemMasterChangedDetails.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ItemMasterChangedDetails;
