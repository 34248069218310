import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {push} from 'react-router-redux';
import {Button} from 'react-bootstrap';
import * as icons from '@carbon/icons-react';

const StarIcon = icons.Star24;
const StarFilledIcon = icons.StarFilled24;

class LargeButton extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
  }
  render () {
    const {children, path, disabled, iconName, isFavorite, isDisabled, onClick} = this.props;

    const getFavStar = (isFavorite) => {
      return isFavorite ? StarFilledIcon : StarIcon;
    };

    const getFavStarColor = (isFavorite) => {
      return isFavorite ? '#FFC300' : '#CCC';
    };
    // There are two disable button settings; one from props.props and the other locally by detecting a route specifically disabled (leaf rails).
    const props = Object.assign({}, this.props.props ? this.props.props : {}, (this.props.props && !this.props.props.disabled && disabled) ? {disabled} : {});
    const Icon = icons[iconName] ? icons[iconName] : null;

    const FavStar = getFavStar(isFavorite);

    // Tabindex below on div enables focus on the div effectively blurring the button for ui on click
    return (
      <Button {...props} href={onClick ? '#' : path}
          style={isDisabled && !onClick ? {position:'relative',opacity:1} : {position:'relative'}}
          className={isDisabled && onClick ? 'large-button large-button-disabled' : isDisabled ? 'large-button-cart' : 'large-button'}
          target='_blank'
          disabled={isDisabled && !onClick}
          onClick={onClick ? onClick : null}
          >
        <div
          tabIndex={1000}
          style={{position: 'absolute',width: 'auto', top: '-15px', right: '-10px', outline: 'none'}}
          onClick={!onClick ? this.props.onFavoriteClick : null}
        >
          {!isDisabled ?  <FavStar color={getFavStarColor(isFavorite)} /> : []}
        </div>
        {Icon ?
          (<Icon aria-label={iconName} tabIndex='0' />) : (<svg width='32' height='32' />)
        }
        <span>
          {children}
        </span>
      </Button>);
  }
}

LargeButton.propTypes = {
  path: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  props: PropTypes.object,
  children: PropTypes.node,
  iconName: PropTypes.string,
  isFavorite: PropTypes.bool.isRequired,
  reportId: PropTypes.number.isRequired,
  onFavoriteClick: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};

function mapStateToProps(state, ownProps){
  const {path, props, disabled} = ownProps;
  return {
    path,
    props,
    disabled
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, {push});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (LargeButton);
