import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {bindActionCreators} from 'redux';
import {goBack} from 'react-router-redux';
import get from 'lodash.get';
import * as dataNames from '../../../../constants/dataNames';
import FormWrapper from '../../../common/form/FormWrapper';
import TablePageWrapper from '../../../common/grid/TablePageWrapper';
import {getPurchaseOrderHistoryData} from '../../../../actions/purchaseOrderActions';
import {getPurchaseOrderHistory} from '../../../../selectors/purchaseOrdersSelectors';

export class PurchaseOrderHistoryPage extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
    this.onExpandRow = this.onExpandRow.bind(this);
    this.state = {
      loadedPurchaseOrderIds: [],
      itemMasterIds: [],
      purchaseOrder: {},
    };
  }

  componentWillMount() {
    const purchaseOrderId = get(this.props, 'params.id', 0);
    this.props.actions.getPurchaseOrderHistoryData(purchaseOrderId)
      .then((data) => {
        this.setState({
          purchaseOrder: data.purchaseOrder,
          itemMasterIds: data.itemMasterIds
        });
      });
  }

  onExpandRow(row) {
    const paddedBlock = {padding: '12px', backgroundColor: '#eee', paddingLeft: '5px'};
    if(row.index === 0){
      return <div style={paddedBlock}>This is the parent of all related Purchase Orders for {this.state.purchaseOrder.po_number}.</div>;
    }

    const bold = {fontWeight: 'bold'};
    const format2Digit = (inputValue) => {
      return parseFloat(inputValue).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2});
    };
    const format2Money = (inputValue) => {
      if(typeof inputValue === 'string' && inputValue.trim() === '') return '';
      return `$${format2Digit(inputValue)}`;
    };

    const deletedLineStyle = {textDecoration: 'line-through', color: 'red'};

    return (<div style={paddedBlock}>
      <div>
        {
          Object.keys(row.modifiedFields).length === 0
            ? (<div style={paddedBlock}>No descriptive fields were modified in this update.</div>)
            : (<div>
              <div style={paddedBlock}>Purchase Order values changed between the previous version and this version of the PO are displayed.</div>
              <table className='table table-sm table-bordered table-striped'>
                <thead>
                <tr>
                  <th style={{width: '25%'}}>Field</th>
                  <th style={{width: '25%'}}>Initial Value</th>
                  <th>New Value</th>
                </tr>
                </thead>
                <tbody>
                {
                  Object.keys(row.modifiedFields).map((key, index) => {
                    return (<tr key={index}>
                      <td>{I18n.t(key)}</td>
                      <td>{row.modifiedFields[key]}</td>
                      <td>{row[key]}</td>
                    </tr>);
                  })
                }
                </tbody>
              </table>
            </div>)
        }
      </div>
      {
        row.modifiedLines.length === 0 && row.deletedLines.length === 0
          ? (<div style={paddedBlock}>No lines were modified in this update.</div>)
          : (<div>
            <div style={paddedBlock}>Lines changed between the previous version and this version of the PO are displayed.  New values are in bold.  Lines in red with a line-through were deleted.</div>
            <table className='table table-sm table-bordered table-striped'>
            <thead>
            <tr>
              <th>Product</th>
              <th>Init / New Quantity</th>
              <th>Init / New Price</th>
              <th>Init / New Line Item Price</th>
            </tr>
            </thead>
            <tbody>
            {
              row.modifiedLines.map((item, index) => {
                return (<tr key={index}>
                  <td>{(this.props.itemMasters.find((itemMaster) => itemMaster.id === item.item_master_id) || {name: 'Not Found'}).name}</td>
                  <td>{format2Digit(item.initial.qty)} / <span style={item.initial.qty === item.qty ? {} : bold}>{format2Digit(item.qty)}</span></td>
                  <td>{format2Money(item.initial.unit_price)} / <span style={item.initial.unit_price === item.unit_price ? {} : bold}>{format2Money(item.unit_price)}</span></td>
                  <td>{format2Money(item.initial.line_item_price)} / <span style={item.initial.line_item_price === item.line_item_price ? {} : bold}>{format2Money(item.line_item_price)}</span></td>
                </tr>);
              })
            }
            {
              row.deletedLines.map((item, index) => {
                return (<tr key={index} style={deletedLineStyle}>
                  <td>{item.name}</td>
                  <td style={deletedLineStyle}>{format2Digit(item.qty)}</td>
                  <td style={deletedLineStyle}>{format2Money(item.unit_price)}</td>
                  <td style={deletedLineStyle}>{format2Money(item.line_item_price)}</td>
                </tr>);
              })
            }
            </tbody>
          </table>
          </div>)
      }
      {
        row.modifiedPayments.length === 0
          ? (<div style={paddedBlock}>No payments were modified in this update.</div>)
          : (<div>
            <div style={paddedBlock}>All payments are shown even on a single change to provide context.</div>
            <table className='table table-sm table-bordered table-striped'>
              <thead>
              <tr>
                <th style={{width: '25%'}}>Previous Type</th>
                <th style={{width: '25%'}}>Previous Amount</th>
                <th style={{width: '25%'}}>Current Type</th>
                <th style={{width: '25%'}}>Current Amount</th>
              </tr>
              </thead>
              <tbody>
              {
                row.modifiedPayments.map((item, index) => {
                  return (<tr key={index}>
                    <td>{item.previousType}</td>
                    <td>{format2Money(item.previousAmount)}</td>
                    <td>{item.currentType}</td>
                    <td>{format2Money(item.currentAmount)}</td>
                  </tr>);
                })
              }
              </tbody>
            </table>
          </div>)
      }

    </div>);
  }

  render () {

    const columns = [
      {
        name: 'event',
        dataId: 'message',
        width: '100px',
        dataSort: false,
        headerAlign: 'left',
        dataAlign: 'left',
      },
      {
        name: 'poNumber',
        dataId: 'po_number',
        width: '100px',
        dataSort: false,
        headerAlign: 'left',
        dataAlign: 'left',
        formatter: (cell, row) => {
          if(parseInt(row.poId) === parseInt(this.props.params.id)){
            return <div style={{fontWeight: 'bold'}}>{cell}</div>;
          }
          return cell;
        }
      },
      {
        name: 'createdAt',
        dataId: 'created_at',
        width: '100px',
        dataSort: false,
        headerAlign: 'left',
        dataAlign: 'left',
      },
      {
        name: 'orderTotal',
        dataId: 'order_total',
        width: '70px'
      },
      {
        name: 'byUser',
        dataId: 'ordered_by_user_name',
        width: '70px'
      },
    ];

    return (<div>
      <FormWrapper title={'purchaseOrders.purchaseOrderHistory'} goBack={this.props.actions.goBack}>
        <div>
          <TablePageWrapper
            ref={this.ref}
            settingKey='purchase-orders-history'
            columns={columns}
            data={this.props.events}
            selectedRows={[]}
            handleSelect={() => {}}
            showHistory={false}
            bstProps={{
              selectRow: {
                clickToSelect: true,
                clickToExpand: true,
                hideSelectColumn: true,
              },
              expandableRow: () => true,
              expandComponent: this.onExpandRow,
              options : {
                defaultSortName: 'event_date',
                defaultSortOrder: 'desc',
              },
            }}
          />
        </div>
      </FormWrapper>
    </div>);
  }
}

PurchaseOrderHistoryPage.propTypes = {
  events: PropTypes.array,
  purchaseOrders: PropTypes.array,
  purchaseOrderEvents: PropTypes.array,
  itemMasters: PropTypes.array,
};


function mapStateToProps(state) {
  return {
    events: getPurchaseOrderHistory(state),
    purchaseOrders: state[dataNames.purchaseOrders],
    purchaseOrderEvents: state[dataNames.purchaseOrderEvents],
    itemMasters: state[dataNames.itemMasters],
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {goBack, getPurchaseOrderHistoryData};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (PurchaseOrderHistoryPage);
