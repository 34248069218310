import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {reduxForm, Field} from 'redux-form';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import {requiredFieldValidation} from '../../common/form/redux-form/validations';

const IntegrationConsumerType = props => {
  const {options} = props;

  return (
    <Field
      name='integration_consumer_type'
      component={ReactSelectInput}
      props={{
        isRequired: true,
        options: options,
        label: I18n.t('cart.payment.integrationConsumerType')
      }}
    />
  );
};

IntegrationConsumerType.propTypes = {
  options: PropTypes.array.isRequired,
};

export default reduxForm({
  validate: values => {
    return {
      integration_consumer_type: requiredFieldValidation(values.integration_consumer_type, 'cart.payment.integrationConsumerType')
    };
  },
  enableReinitialize: false,
})(IntegrationConsumerType);
