/* eslint-disable import/no-named-as-default*/
import get from 'lodash.get';
import {connect} from 'react-redux';
import {reduxForm, formValueSelector} from 'redux-form';
import {getSelectedFacilityStrains} from '../../selectors/facilityStrainsSelectors';
import strainDominance from '../../constants/strainDominance';
import ModifyStrainsForm from './ModifyStrainsForm';
import validate from './validate';

export const ModifyStrainsFormWrapper = reduxForm({
  form: 'modify_strains_form',
  validate
})(ModifyStrainsForm);

const selector = formValueSelector('modify_strains_form');
export default connect(state => {
  const strains = getSelectedFacilityStrains(state);

  strains.map(strain => {
    strain.dominance = strain.dominance ? strain.dominance : get(strainDominance, '0.value');
    strain.indica_percent = strain.indica_percent ? strain.indica_percent : 50.0;
    strain.sativa_percent = strain.sativa_percent ? strain.sativa_percent : 50.0;
    return strain;
  });

  const initialValues = {
    selectedStrains: strains
  };

  const selectedStrains = selector(state, 'selectedStrains') || [];
  const totalPlantLifeCycleValues = [];
  selectedStrains.forEach((selectedStrain, index) => {
    const propagationCycleDays = Number(selector(state, `selectedStrains[${index}].cultivation.prop_days`));
    const vegetativeCycleDays = Number(selector(state, `selectedStrains[${index}].cultivation.veg_days`));
    const floweringCycleDays = Number(selector(state, `selectedStrains[${index}].cultivation.flower_days`));
    totalPlantLifeCycleValues.push(propagationCycleDays + vegetativeCycleDays + floweringCycleDays);
  });
  return {
    initialValues,
    selectedStrains,
    totalPlantLifeCycleValues
  };
})(ModifyStrainsFormWrapper);
