import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Col, Row} from 'react-bootstrap';
import get from 'lodash.get';
import MultiselectInput from '../../common/form/MultiselectInput';
import InlineCheckBox from '../../common/form/InlineCheckBox';

const TaxRateCategoriesFieldArray = (props) => {
  const {fields: {map}, tax, categories, handleApplyToAllChange, taxRateIndex} = props;
  return (
    <div>
      {map((name, index) => {
        const categorySubcategories = tax ? tax.categorySubcategories[index] : [];
        const categoryId = get(categorySubcategories, 'category_id');
        const currentCategory = categories && categories.filter && categories.find(cat => cat.id === categoryId);

        if (!currentCategory) {
          return null;
        }
        const label = I18n.t('taxes.form.applicableProductSubCategories', {categoryName: currentCategory.name});
        const options = currentCategory.subcategories;
        const isDisabled = Boolean(categorySubcategories.apply_to_all_subcategories);
        return (
          <Row key={`${name}.subcategories`}>
            <Col xs={8}>
              <Field
                name={`${name}.subcategories`}
                component={MultiselectInput}
                className='tax-subcategories-dropdown'
                props={{
                  label,
                  options,
                  textKey: 'name',
                  valueKey: 'id',
                  allOption: true,
                  isRequired: true,
                  disabled: isDisabled,
                }}/>
            </Col>
            <Col xs={4}>
              <br/>
              <Field
                name={`${name}.apply_to_all_subcategories`}
                onChange={(event, isChecked) => handleApplyToAllChange(taxRateIndex, categoryId, isChecked)}
                component={InlineCheckBox}
                className='tax-category-apply-to-all'
                props={{
                  label: I18n.t('taxes.form.applyToWholeCategory'),
                  groupClassName: 'inline-checkbox',
                }}/>
            </Col>
          </Row>
        );
      })}
    </div>
  );
};


TaxRateCategoriesFieldArray.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
  }).isRequired,
  tax: PropTypes.object,
  taxRateIndex: PropTypes.number.isRequired,
  handleApplyToAllChange: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
};

export default TaxRateCategoriesFieldArray;
