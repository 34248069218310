import {createSelector} from 'reselect';
import {getUsers, getTransferDriverOptions} from '../driversSelectors';
import {getIntegrationState} from '../integration/integrationSelectors';
import {isFeatureEnabled} from '../featureToggles';

const driverFields = [
  'driver_id',
  'driver_state_license_number',
  'driver_license_number',
  'driver_phone_number',
  'driver_name',
  'driver_type',
];

/***
 * Map drivers array from formData to db fields for post/put
 * @param drivers
 */
export const mapDriversArrayToDbFields = (drivers) => {
  return drivers.reduce((acc, driver, index) => {
    const append = (index === 0) ? '' : '_2';
    driverFields.forEach((field) => {
      const value = driver[field.replace('driver_', '')];
      acc[`${field}${append}`] = (value) ? value : null;
    });
    return acc;
  }, {});
};

/***
 * Map DB fields from get response to drivers array for form
 * @param transfer
 * @returns {Iterable<K, M>|Array}
 */
export const mapDbFieldsToDriversArray = (transfer) => {
  return ['','_2'].map((key) => { // map db fields into an array - ugly but expeditious (approved) hack
    return driverFields.reduce((acc, field) => {
      acc[field.replace('driver_', '')] = transfer[`${field}${key}`];
      return acc;
    }, {});
  });
};

export const getValidDrivers = createSelector(
  [getTransferDriverOptions, getUsers, getIntegrationState, isFeatureEnabled],
  (drivers, users, integrationState, isFeatureEnabled) => {
    if (!integrationState.isPaLeaf) return drivers;
    return drivers.filter((driver) => {
      const user = users.find((user) => user.id === driver.user_id);
      // If the FT is enabled then users that have been approved by the regulator can be selected as drivers
      if (isFeatureEnabled('feature_regulation_console_user_approval') && user && user.approval_status === 'approved') {
        return true;
      }
      // Users that have been approved before (i.e. have a state integration ID) can still be added as drivers (regardless of FT value)
      return user && user.state_integration_id && typeof user.state_integration_id === 'string' && user.state_integration_id.trim() !== '';
    });
  }
);

