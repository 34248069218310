import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {Col, Row} from 'react-bootstrap';
import {FaCheck, FaTimes} from 'react-icons/fa';

const TestResultProducts = ({row, referenceType}) => {

  const getActiveStatus = (reference) => {
    if(reference.reference_is_active !== undefined) {
      return reference.reference_is_active;
    }
    // is_active_package_flag is used when results come from solr because solr accounts
    //// for the source harvest batch state in the is_active_package property
    // is_active_package is otherwise used
    return reference.is_active_package_flag || (!reference.is_active_package_flag && reference.is_active_package);
  };

  return (
    <Row>
      <Col xs={8}>
        <table className='table table-striped'>
          <thead>
          {
            referenceType === 'package'
              ? (<tr>
                <th>{I18n.t('cultivation.testResults.form.active')}</th>
                <th>{I18n.t('cultivation.testResults.form.batchName')}</th>
                <th>{I18n.t('cultivation.testResults.form.packageId')}</th>
                <th>{I18n.t('cultivation.testResults.form.productName')}</th>
                <th>{I18n.t('cultivation.testResults.form.latestResult')}</th>
              </tr>)
              : (<tr>
                <th>{I18n.t('cultivation.testResults.form.active')}</th>
                <th>{I18n.t('cultivation.testingCompliance.plant_batch')}</th>
                <th>{I18n.t('cultivation.testingCompliance.plant_group_id')}</th>
                <th>{I18n.t('cultivation.testingCompliance.plant_phase')}</th>
                <th>{I18n.t('cultivation.testingCompliance.plants_in_group')}</th>
                <th>{I18n.t('cultivation.testResults.form.latestResult')}</th>
              </tr>)
          }
          </thead>
          <tbody>
          {
            row && row.references && row.references.map((reference, index) => {
              const isActivePackage = getActiveStatus(reference);
              return referenceType === 'package'
                ? (
                  <tr key={index}>
                    <td>{isActivePackage ? <FaCheck /> : <FaTimes />}</td>
                    <td>{reference.lot_number}</td>
                    <td>{reference.package_code}</td>
                    <td>{reference.product_name}</td>
                    <td>{reference.is_latest_lab_result ? <FaCheck /> : <FaTimes />}</td>
                  </tr>
                )
                : (
                  <tr key={index}>
                    <td>{isActivePackage ? <FaCheck /> : <FaTimes />}</td>
                    <td>{reference.batch_name}</td>
                    <td>{reference.reference_name}</td>
                    <td>{I18n.t(`cultivation.plantStages.${reference.plant_phase_id}`)}</td>
                    <td>{reference.qty || reference.plant_qty}</td>
                    <td>{reference.is_latest_lab_result ? <FaCheck /> : <FaTimes />}</td>
                  </tr>
                );
            })
          }
          </tbody>
        </table>
      </Col>
    </Row>
  );

};

TestResultProducts.propTypes = {
  row: PropTypes.shape({
    references: PropTypes.array.isRequired,
  }).isRequired,
  referenceType: PropTypes.string.isRequired,
};

export default TestResultProducts;
