import ReactGA from 'react-ga';
import axios from 'axios'; // Import axios
import { batchActions } from 'redux-batched-actions';
import { startSpinner, stopSpinner, setToken, setCurrentVersion } from './systemActions';
import { loadConstants, loadNotifications, loadUseEntityLocks } from './initActions';
import { setSocketUser } from './socketActions';
import * as itemNames from '../constants/itemNames';
import * as dataNames from '../constants/dataNames';
import { unsetItem, setItem } from './itemActions';
import { unsetData } from './dataActions';
import * as types from '../constants/actionTypes';
import normalizeLoginResponse from '../normalizers/normalizeLoginResponse';
import CancelTokens from './helpers/CancelTokens';
import Config from '../managers/config';

const { post, get  } = axios; // Extract get and post method

export function loginSuccess(data) {
  return Object.assign({}, data, { type: types.LOGIN_SUCCESS });
}

export function loginFailed(errors) {
  return { type: types.LOGIN_FAILED, errors };
}

export function unsetUser() {
  return { type: types.UNSET_USER };
}

export function logout() {
  return (dispatch, getState) => {
    const state = getState();
    const localPrintServer = Object.assign(state[itemNames.localPrintServer], { active: false });
    if (Object.keys(localPrintServer).length > 1) {
      // force inactive status if present, clear keep auth alive if present
      dispatch(setItem(localPrintServer, itemNames.localPrintServer));
      if (localPrintServer && localPrintServer.keepAlive) clearInterval(localPrintServer.keepAlive);
    }
    CancelTokens.cancelAll();

    const { headers } = state;
    return post('/api/auth/logout', null, {headers}).then((data) => {
      const position = screen.height - 100;
      const params = `width=200,height=100,top=${position}`;
      if (window.location.hostname.includes('leafdatasystems') && state.env.ssoEnabled ) {
        const openedWindow = window.open(state.env.utahLogoutUrl, 'Logout', params);
        if ( openedWindow ) {
          setTimeout(() => {
            openedWindow.close();
          }, 5000);
        }
      }
      dispatch({ type: types.LOGOUT_SUCCESS });
    });
  };
}

export function validating() {
  return { type: types.USER_VALIDATE_IN_PROGRESS };
}

export function validateSuccess() {
  return { type: types.USER_VALIDATE_SUCCESS };
}

export function validateFailed({ messages }) {
  return { type: types.USER_VALIDATE_FAILED, messages };
}

export function clearLoginMessage() {
  return { type: types.CLEAR_LOGIN_MESSAGE };
}

export function setUserRetailLocation(location) {
  return { type: types.SET_RETAIL_LOCATION, location };
}

export function setUserRetailRegister(register) {
  return { type: types.SET_RETAIL_REGISTER, register };
}

export function getUserValid() {
  return (dispatch, getState) => {
    dispatch(batchActions([validating(), startSpinner()]));
    const { headers } = getState();
    return get('/api/auth', { headers })
      .then((response) => {
        const actions = [validateSuccess()];
        if (response && response.headers) {
          const version = response.headers['x-mjf-version'];
          const authorization = response.headers['authorization'];
          if (version && getState().system.currentVersion != version) {
            actions.push(setCurrentVersion(version));
          }
          if (authorization && getState.authorization != authorization) {
            actions.push(setToken(authorization));
          }
        }
        dispatch(batchActions(actions));
      })
      .catch((error) => {
        const authorization = error.response.headers && error.response.headers['authorization'];

        if (error.response.status === 503 && !authorization) {
          return;
        }

        if (error.response && error.response.data && error.response.data.messages) {
          dispatch(validateFailed(error.response.data));
        } else {
          dispatch(validateFailed({ messages: [error] }));
        }
      });
  };
}

export function postLogin(user) {
  return (dispatch, getState) => {
    dispatch(startSpinner());
    const { headers } = getState();
    return post('/api/auth/login', { user }, { headers })
      .then((response) => {
        localStorage.setItem('login', JSON.stringify(response.data));
        dispatch(setUser(response.data));
        dispatch(stopSpinner());
        if (response.headers['x-mjf-version']) {
          dispatch(setCurrentVersion(response.headers['x-mjf-version']));
        }
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.fields && error.response.data.messages) {
          dispatch(loginFailed(error.response.data));
        } else {
          dispatch(loginFailed({ fields: [], messages: [error] }));
        }
      });
  };
}

export function postTokenLogin(token) {
  return (dispatch, getState) => {
    dispatch(startSpinner());
    const { headers } = getState();
    return post('/api/auth/login/token', { token }, { headers })
      .then((response) => {
        localStorage.setItem('login', JSON.stringify(response.data));
        dispatch(setUser(response.data));
        dispatch(stopSpinner());
        if (response.headers['x-mjf-version']) {
          dispatch(setCurrentVersion(response.headers['x-mjf-version']));
        }
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.fields && error.response.data.messages) {
          dispatch(loginFailed(error.response.data));
        } else {
          dispatch(loginFailed({ fields: [], messages: [error] }));
        }
      });
  };
}

// Handle successful login response
export function setUser(data) {
  var user = data['user'] || null;
  if (user && Config.setting('ga.enabled')) {
    ReactGA.set({dimension1: user.id});
    ReactGA.set({dimension2: user.first_name + ' ' + user.last_name});
  }

  return (dispatch, getState) => {
    const normalizedResponse = normalizeLoginResponse(data);
    dispatch(loginSuccess(normalizedResponse));
    dispatch(unsetData(dataNames.availableApps));
    dispatch(setSocketUser(normalizedResponse));
    setTimeout(() => {
      dispatch(loadConstants());
      dispatch(loadNotifications());
      dispatch(loadUseEntityLocks());
    }, 100);
  };
}

export function setUserPermissions(payload) {
  return { type: types.SET_USER_PERMISSIONS, payload };
}

export const updateUser = (payload) => ({ type: types.UPDATE_USER, payload });

export const clearSelectedUser = () => (dispatch) => dispatch(unsetItem(itemNames.selectedUser));

export const searchUserRoles = (searchStr) => {
  return { type: types.SEARCH_USER_ROLES, searchStr };
};
