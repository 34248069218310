import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { goBack } from 'react-router-redux';
import * as itemNames from '../../../../constants/itemNames';
import * as dataNames from '../../../../constants/dataNames';
import * as apiActions from '../../../../actions/apiActions';
import FormWrapper from '../../../common/form/FormWrapper';
import { getManageCupoSettingsInitialValues } from '../../../../selectors/forms/cupoFormSelectors';
import ManageCupoSettingsFormWrapper from './ManageCupoSettingsFormWrapper';

class ManageCupoSettingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount() {
    Promise.all([
      this.props.actions.getUnpaginatedData('/api/strains/by_facility', dataNames.facilityStrains),
      this.props.actions
        .getItem(`/api/cupos/${this.props.params.id}`, itemNames.cupo, null, {
          with_relations: ['modalities'],
          detailed: 1
        })
        .then(() => {
          this.props.actions.getItem('/api/cultivation/settings', itemNames.colombiaModalities, null, {
            ids: ['cult_colombia_modality']
          });
        })
    ]);
  }

  onSubmit(formValues) {
    const { goBack } = this.props.actions;
    const { modalities } = formValues;
    const payload = modalities.map((modality) => ({
      id: modality.id,
      setting_value: JSON.stringify(modality.setting_value)
    }));
    this.props.actions
      .postData('/api/cupos/modalities/update_many', payload, 'noOp', {
        success: 'cupos.form.success'
      })
      .then(goBack);
  }

  render() {
    const { initialValues } = this.props;
    return (
      <div>
        <FormWrapper title='cultivation.complianceSettings.title' goBack={this.redirect}>
          <ManageCupoSettingsFormWrapper
            initialValues={initialValues}
            onSubmit={this.onSubmit}
            className='retail-sales-settings'
          />
        </FormWrapper>
      </div>
    );
  }
}

ManageCupoSettingsPage.propTypes = {
  actions: PropTypes.object,
  params: PropTypes.object,
  initialValues: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
  const { facility } = state;
  return {
    facility,
    initialValues: getManageCupoSettingsInitialValues(state, { modality_key: ownProps.params.modality_key })
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({ goBack }, apiActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageCupoSettingsPage);
