import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {Nav} from 'react-bootstrap';
import * as ReactIcons from 'react-icons/fa';
import * as ReactBiIcons from 'react-icons/bi';
import * as ReactPiIcons from 'react-icons/pi';
import * as ReactFa6Icons from 'react-icons/fa6';
import * as ReactMdIcons from 'react-icons/md';
import {LinkContainer} from 'react-router-bootstrap';
import {I18n} from 'react-redux-i18n';
import PermissionWrapper from '../common/PermissionWrapper';


const AppNavigationListItem = ({app, showSubNavigation, onToggleShowSubNavigation, location}) => {

  // Mapping from Glyphicon to ReactIcon
  const iconMapping = [
    {key: 'box-open', icon: ReactIcons.FaBoxOpen},
    {key: 'briefcase', icon: ReactIcons.FaBriefcase},
    {key: 'check', icon: ReactIcons.FaCheck},
    {key: 'cog', icon: ReactIcons.FaCog},
    {key: 'edit', icon: ReactIcons.FaEdit},
    {key: 'flag', icon: ReactIcons.FaFlag},
    {key: 'folder-open', icon: ReactIcons.FaFolderOpen},
    {key: 'leaf', icon: ReactIcons.FaCannabis},
    {key: 'link', icon: ReactIcons.FaLink},
    {key: 'list', icon: ReactIcons.FaList},
    {key: 'object-align-left', icon: ReactPiIcons.PiAlignLeftBold},
    {key: 'object-align-vertical', icon: ReactPiIcons.PiAlignCenterHorizontalBold},
    {key: 'option-horizontal', icon: ReactIcons.FaEllipsisH},
    {key: 'road', icon: ReactIcons.FaRoad},
    {key: 'scale', icon: ReactFa6Icons.FaWeightScale},
    {key: 'search', icon: ReactIcons.FaSearch},
    {key: 'share', icon: ReactIcons.FaShareSquare},
    {key: 'signal', icon: ReactIcons.FaSignal},
    {key: 'stats', icon: ReactIcons.FaChartBar},
    {key: 'th-large', icon: ReactMdIcons.MdInventory},
    {key: 'th-list', icon: ReactIcons.FaList},
    {key: 'tint', icon: ReactBiIcons.BiTestTube},
    {key: 'user', icon: ReactIcons.FaUserFriends},
  ];

  const IconComponent = iconMapping.find((icon) => icon.key === app.glyph);

  const currentPath = location.pathname;
  const active = (currentPath === app.path) || (app.subPaths && app.subPaths.map(pathObj => pathObj.path).includes(currentPath));
  const ContainerComponent = app.path ? LinkContainer : React.Fragment;
  const topLevelClass = app.path ? 'top-level-link' : 'top-level-nav-item';
  const containerProps = {
    key: app.id
  };

  if (app.path) containerProps.to = app.path;

  let containerClasses = 'top-level-link-container';

  if (active) containerClasses += ' active';

  return (
    <li>
      <div className={containerClasses} onClick={onToggleShowSubNavigation}>
        <ContainerComponent {...containerProps}>
          <div className={topLevelClass}>
            {app.glyph &&
              <div>
                <IconComponent.icon style={{fontSize: 15}} />
              </div>
            }

            <div>
              {I18n.t(`nav.${app.name}`)}
            </div>
          </div>
        </ContainerComponent>

        <div className='toggle-expand'>
          {showSubNavigation ? '-' : '+'}
        </div>
      </div>

      {showSubNavigation && app.info &&
        <div className='navigation-info'>{I18n.t(app.info)}</div>
      }

      {showSubNavigation && app.subPaths &&
        <Nav>
          {app.subPaths.map((pathObj) => {
            const linkProps = {
              key: pathObj.path,
              to: pathObj.path,
              active: currentPath === pathObj.path
            };

            if (pathObj.targetBlank) linkProps.target = '_blank';

            return (
              <PermissionWrapper key={pathObj.path}
                                 permissions={pathObj.permissions}
                                 accessibility={pathObj.accessibility}
              >
                <LinkContainer {...linkProps}>
                  <Nav.Item as='li'>
                    {I18n.t(`nav.subNav.${pathObj.name}`)}
                  </Nav.Item>
                </LinkContainer>
              </PermissionWrapper>
            );
          })}
        </Nav>
      }
    </li>
  );
};


AppNavigationListItem.propTypes = {
  app: PropTypes.object,
  showSubNavigation: PropTypes.bool,
  onToggleShowSubNavigation: PropTypes.func,
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default withRouter(AppNavigationListItem);
