import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {goBack, push} from 'react-router-redux';
import {Alert, Card, Tab, Tabs} from 'react-bootstrap';
import get from 'lodash.get';
import {getDataByPost, getItem, getUnpaginatedData} from '../../../../actions/apiActions';
import {unsetData} from '../../../../actions/dataActions';
import * as dataNames from '../../../../constants/dataNames';
import * as itemNames from '../../../../constants/itemNames';
import {getTransactionReport, getTransactionTotals} from '../../../../selectors/transactionReportSelectors';
import TablePageWrapper from '../../../common/grid/TablePageWrapper';
import TransactionSearchForm from './TransactionSearchForm';
import {transactionReportTabs, getTransactionReportColumns, getTransactionTotalColumns} from './tableDefinitions';
import getSalesReportTabs from '../common/tabDefinitions';
import PageTitle from '../../../common/PageTitle';
import {getEnabledTabs} from '../../../../actions/reportActions';
import {isPaLeaf} from '../../../../selectors/integrationSelectors';
import {isFeatureEnabled} from '../../../../selectors/featureToggles';

export class TransactionReportPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.submitSearch = this.submitSearch.bind(this);
    this.renderTotals = this.renderTotals.bind(this);
    this.switchTab = this.switchTab.bind(this);

    this.state = {
      activeTab: 'dailyTransactionsReport',
      tabs: [],
    };
  }

  componentDidMount() {
    const {actions: {getItem, getUnpaginatedData, unsetData}} = this.props;

    unsetData(dataNames.transactionReport);

    // register data
    getUnpaginatedData(
      '/api/registers',
      dataNames.registers,
      {failed:'registers.get.failed'},
      {type: 'sales', active: -1}
    );

    // CSR data
    getItem(
      '/api/csrs',
      itemNames.budTendersObject,
      {failed:'customers.getBudTenders.failed'}
    );

    // Disabling lint here as it's acceptable to call setState in some situations
    /* eslint-disable react/no-did-mount-set-state */
    this.setState({tabs: this.props.actions.getEnabledTabs(getSalesReportTabs)});
  }

  // TransactionSearchForm submit handler
  submitSearch(formValues) {
    const reportParams = {
      report_date: formValues.report_date.format('YYYY-MM-DD'),
      registers: get(formValues,'registers',[]),
      order_types: get(formValues,'order_types',[]),
      patient_types: get(formValues,'patient_types',[]),
      payment_methods: get(formValues,'payment_methods',[]),
      fulfillment_methods: get(formValues,'fulfillment_methods',[]),
      csrs: get(formValues,'csrs',[]),
      name: get(formValues,'name',null)
    };

    this.props.actions.getDataByPost(
      '/api/orders/daily_transaction_report',
      reportParams,
      dataNames.transactionReport,
      {failed: I18n.t('retail.transactionReport.failed')}
    );
  }

  renderTotals(transactionReportColumnOptions) {
    //const includeMmap = this.props.featureLeafPaMmapEnabled && this.props.isLeafPa;
    if(!this.props.transactionReport.length){ return null; }
    return (
      <div style={{paddingTop:40}}>
      <h4>{I18n.t('retail.transactionReport.totals')}</h4>
        <TablePageWrapper
          columns={getTransactionTotalColumns(transactionReportColumnOptions)}
          data={this.props.transactionTotals}
          dataTotalSize={1}
          external={false}
          hideExport={true}
          hideScanSearch={true}
          hideSearch={true}
          hideTableControls={true}
          settingKey='transactionTotals'
        />
      </div>
    );
  }

  switchTab(activeTab) {
    const tab = this.state.tabs.find(tab => tab.eventKey === activeTab) || this.state.tabs[0];
    this.setState({activeTab});
    this.props.actions.push(tab.path);
  }

  render() {
    const {isLeafPa, featureLeafPaMmapEnabled, featureAlleavesPaymentIntegrationEnabled, featurePosabitPaymentIntegrationEnabled, featureAeropayEnabled, featureAeropayIntegrationEnabled, featureHypurEnabled} = this.props;
    const includeMmap = featureLeafPaMmapEnabled && isLeafPa;
    const transactionReportColumnOptions = {includeMmap, featureAlleavesPaymentIntegrationEnabled, featurePosabitPaymentIntegrationEnabled, featureAeropayEnabled, featureAeropayIntegrationEnabled, featureHypurEnabled};
    return (
      <div>
        <PageTitle primaryText={I18n.t('retail.transactionReport.title')}/>
        <Tabs id='filterTabs' activeKey={this.state.activeTab} onSelect={this.switchTab}>
          {this.state.tabs.map((tab, index) => <Tab key={index} eventKey={tab.eventKey} title={I18n.t(tab.title)} />)}
        </Tabs>

        <Card className={`form-page transaction-report-form`}>
          <Card.Header />
          <Card.Body>
            <Alert variant='warning'>
              Starting 2/14/22, Daily Transaction Report will only be available for previous 90 days. Please use Analytics module
              for Orders older than 90 days.
            </Alert>
            <TransactionSearchForm submitSearch={this.submitSearch} />
          </Card.Body>
        </Card>
        <TablePageWrapper
          activeTab='default'
          bstProps={{
            defaultSortName: 'order_id',
            defaultSortOrder: 'asc'
          }}
          columns={getTransactionReportColumns(transactionReportColumnOptions)}
          data={this.props.transactionReport}
          dataTotalSize={this.props.transactionReport.length}
          external={false}
          hideExport={false}
          hideScanSearch={true}
          hideSearch={true}
          defaultHiddenColumns={[
            'check_amount', 'hypur_amount', 'aeropay_amount', 'other_amount', 'subtotal_amount',
            'tax_amount', 'discount_amount_coupons', 'discount_amount_redemption', 'discount_amount_rewards',
            'discount_amount_manual', 'discount_amount', 'total_amount', 'change_amount',
            'refunded_order_name', 'started_by_user_name', 'completed_by_user_name',
          ]}
          ref={this.ref}
          settingKey='transactionReport'
          tabs={transactionReportTabs}
        />

        {this.renderTotals(transactionReportColumnOptions)}
      </div>
    );
  }
}

TransactionReportPage.propTypes = {
  actions: PropTypes.shape({
    getItem: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    unsetData: PropTypes.func.isRequired,
    getEnabledTabs: PropTypes.func,
  }),
  transactionReport: PropTypes.array.isRequired,
  transactionTotals: PropTypes.array.isRequired,

};

function mapStateToProps(state) {
  return {
    transactionReport: getTransactionReport(state),
    transactionTotals: getTransactionTotals(state),
    isLeafPa: isPaLeaf(state),
    featureLeafPaMmapEnabled: isFeatureEnabled(state)('feature_leaf_pa_mmap'),
    featureAlleavesPaymentIntegrationEnabled: isFeatureEnabled(state)('feature_alleaves_payment_integration'),
    featurePosabitPaymentIntegrationEnabled: isFeatureEnabled(state)('feature_posabit_payment_integration'),
    featureAeropayEnabled: isFeatureEnabled(state)('feature_aeropay'),
    featureAeropayIntegrationEnabled: isFeatureEnabled(state)('feature_aeropay_integration'),
    featureHypurEnabled: isFeatureEnabled(state)('feature_hypur'),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {getDataByPost, getItem, getUnpaginatedData, push, goBack, unsetData, getEnabledTabs};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionReportPage);
