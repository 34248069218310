// src/utils/validates.js
import {requiredFieldValidation} from '../../common/form/redux-form/validations';

const validate = (values, props) => {
  const {isMetrc} = props.integrationState;

  const errors = {
    packages: {},
    estimated_completion_at: requiredFieldValidation(values.estimated_completion_at, 'Estimated completion date'),
    completed_at: requiredFieldValidation(values.completed_at, 'Actual completion date'),
    packaging_location_id: requiredFieldValidation(values.packaging_location_id, 'Storage location'),
    user_ids: values.user_ids && values.user_ids.length ? undefined : 'At least one employee must be assigned',
    total_packed_weight: requiredFieldValidation(values.total_packed_weight, 'Total packed weight'),
    remaining_weight: requiredFieldValidation(values.remaining_weight, 'Remaining weight'),
  }

  if (!values.packages || !values.packages.length) {
    // General error if no packages are entered
    errors.packages = { _error: 'At least one package must be entered' };
  } else {
    const trackingIds = [];
    const packagesErrors = [];

    values.packages.forEach((pkg, index) => {
      const packageErrors = {};

      // Check for 'master_id' requirement
      if (!pkg || !pkg.master_id) {
        packageErrors.master_id = 'Master ID is required';
      }

      // Check for 'qty' requirement
      if (!pkg || pkg.qty === undefined || pkg.qty === null) {
        packageErrors.qty = 'Quantity is required';
      } else if (isNaN(pkg.qty) || pkg.qty <= 0) {
        // Further validation to check if qty is a positive number
        packageErrors.qty = 'Quantity must be a positive number';
      }

      // Check for unique 'state_integration_tracking_id'
      if (isMetrc) {
        if (pkg && !pkg.state_integration_tracking_id) {
          packageErrors.state_integration_tracking_id = 'Tracking ID is required';
        } else if (trackingIds.includes(pkg.state_integration_tracking_id)) {
          packageErrors.state_integration_tracking_id = 'This tracking ID must be unique.';
        } else {
          trackingIds.push(pkg.state_integration_tracking_id);
        }
      }

      if (Object.keys(packageErrors).length > 0) {
        packagesErrors[index] = packageErrors;
      }
    });

    if (packagesErrors.length > 0) {
      errors.packages = packagesErrors;
    }
  }

  if (values.remaining_weight) {
    if (parseFloat(values.remaining_weight) < 0) {
      errors.remaining_weight = 'Remaining weight cannot exceed available weight.';
    }
  }

  return errors;
};

export default validate;
