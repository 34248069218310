import React from 'react';
import {connect} from 'react-redux';

import {getUseEntityLocksForHarvestBatches} from '../../selectors/coreSettingsSelectors';
import IntegrationTrackingStatusInvestigateCell from '../common/integration/IntegrationTrackingStatusInvestigateCell';


const HarvestBatchIntegrationTrackingStatus = (props) => {
  const {
    harvestBatch: {
      tracking_id,
      global_id,
      highest_ranked_entity_lock,
      integration_entity_locks
    },
    useEntityLocks
  } = props;

  if (!useEntityLocks || !highest_ranked_entity_lock) {
    if(tracking_id){
      return <span>{tracking_id}</span>;
    }else{
      return <span>{global_id}</span>;
    }
  }

  return (
    <IntegrationTrackingStatusInvestigateCell
      trackingId={tracking_id}
      entityLocks={integration_entity_locks}
      entityLockForDisplay={highest_ranked_entity_lock}
    />
  );
};


const mapStateToProps = (state) => ({
  useEntityLocks: getUseEntityLocksForHarvestBatches(state)
});


export default connect(mapStateToProps)(HarvestBatchIntegrationTrackingStatus);
