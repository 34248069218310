import debounce from 'debounce-promise';
import { batchActions } from 'redux-batched-actions';

const isDebounceDisabled = action => action.payload && action.payload.debounce === false;
const isAfterSubmitAction = action => action.type === '@@redux-form/CHANGE' && action.meta && action.meta.field === 'afterSubmit';

/**
 * Create a redux middleware function that debounces specified
 * action types and processes them together with batchActions.
 *
 * @param {array} actionTypes - An array of actions types to debounce and the wait for each type
 * @returns {function} - middleware function
 */
export default (actionTypes) => {
  const actionQueue = {};
  const debouncedDispatch = {};
  const DEBOUNCE_ACTION = {};

  const dispatch = (store, key) => {
    const result = store ? store.dispatch(batchActions(actionQueue[key])) : null;
    actionQueue[key] = [];
    return Promise.resolve(result);
  };

  const debounceAction = (store, action) => {
    actionQueue[action.type].push(action);
    return debouncedDispatch[action.type](store, action.type);
  };

  actionTypes.forEach((action) => {
    DEBOUNCE_ACTION[action.type] = true;
    actionQueue[action.type] = [];
    debouncedDispatch[action.type] = debounce(dispatch, action.wait);
  });

  return (store) => next => action => {
    if(isDebounceDisabled(action) || isAfterSubmitAction(action)) {
      return next (action);
    }
    if (action.type === '@@redux-form/REGISTER_FIELD' && action.meta.form !== 'setupCultivationLocations') {
      return next (action);
    }
    return DEBOUNCE_ACTION[action.type] ? debounceAction(store, action) : next(action);
  };
};
