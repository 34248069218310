import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Table, Card} from 'react-bootstrap';

import get from 'lodash.get';

import ContentConcealer from '../../../common/concealers/ContentConcealer';

const ItemDeltaDetails = ({delta, model, adjustmentReasons}) => {
  const tableClass = delta.find(item => item.name === 'tracking_id') ? 'item-details extra-width' : 'item-details';

  let adjustment_reason = get(model, 'transaction.adjustment_reason', false);
  if(adjustment_reason){
    adjustment_reason = adjustmentReasons[adjustment_reason] ? adjustmentReasons[adjustment_reason] : adjustment_reason;
  }

  return (
    <Card>
      <Table striped className={tableClass}>
        <thead>
        <tr>
          <th>{I18n.t('itemTransactions.field')}</th>
          <th>{I18n.t('itemTransactions.valueAdjustment')}</th>
        </tr>
        </thead>
        <tbody>
        {delta.map((field) =>
          <tr key={field.name}>
            <td>{I18n.t(`itemTransactions.item.${field.name}`)}</td>
            <td><span title={`${field.prev} -> ${field.current}`}>{field.prev} -> {field.current}</span></td>
          </tr>
        )}
        <ContentConcealer show={Boolean(adjustment_reason)}>
          <tr>
            <td>{I18n.t('itemTransactions.item.adjustment_reason')}</td>
            <td>{adjustment_reason}</td>
          </tr>
        </ContentConcealer>
        </tbody>
      </Table>
    </Card>
  );
};

ItemDeltaDetails.propTypes = {
  delta: PropTypes.array.isRequired,
  model: PropTypes.object,
  adjustmentReasons: PropTypes.array
};

export default ItemDeltaDetails;
