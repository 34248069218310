import {I18n} from 'react-redux-i18n';

import {
  ZIPCODE_REG_EXP,
  ZIPCODE_US_PLUS_4_REG_EXP,
  CA_POSTAL_CODE_REG_EXP,
  AU_POSTAL_CODE_REG_EXP,
  NZ_POSTAL_CODE_REG_EXP,
  ZA_POSTAL_CODE_REG_EXP,
  CO_POSTAL_CODE_REG_EXP,
  UY_POSTAL_CODE_REG_EXP,
  LS_POSTAL_CODE_REG_EXP
} from './validationRegExs';

export const countryCodes = {
  CA: {
    key: 'common.form.postalCode',
    label: I18n.t('common.form.postalCode'),
    error: I18n.t('common.form.invalidFormat')
  },
  US: {
    key: 'common.form.zipCode',
    label: I18n.t('common.form.zipCode'),
    error: I18n.t('common.form.invalidFormat')
  },
  AU: {
    key: 'common.form.postalCode',
    label: I18n.t('common.form.postalCode'),
    error: I18n.t('common.form.invalidFormat')
  },
  NZ: {
    key: 'common.form.postalCode',
    label: I18n.t('common.form.postalCode'),
    error: I18n.t('common.form.invalidFormat')
  },
  LS: {
    key: 'common.form.postalCode',
    label: I18n.t('common.form.postalCode'),
    error: I18n.t('common.form.invalidFormat')
  },
  UY: {
    key: 'common.form.postCode',
    label: I18n.t('common.form.postCode'),
    error: I18n.t('common.form.invalidFormat')
  },
  ZA: {
    key: 'common.form.postalCode',
    label: I18n.t('common.form.postalCode'),
    error: I18n.t('common.form.invalidFormat')
  },
  CO: {
    key: 'common.form.postCode',
    label: I18n.t('common.form.postCode'),
    error: I18n.t('common.form.invalidFormat')
  },
  IT: {
    key: 'common.form.postalCode',
    label: I18n.t('common.form.postalCode'),
    error: I18n.t('common.form.invalidFormat')
  },
  ES: {
    key: 'common.form.postCode',
    label: I18n.t('common.form.postCode'),
    error: I18n.t('common.form.invalidFormat')
  },
};

export const getPostalCodeName = (countryCode) => {
  return countryCodes[countryCode] ? countryCodes[countryCode].label : (countryCodes[countryCode] ? countryCodes[countryCode].label : countryCodes['US'].label);
};

export const getPostalCodeLabel = (countryCode) => {
  return countryCodes[countryCode] ? countryCodes[countryCode].key : (countryCodes[countryCode] ? countryCodes[countryCode].key : countryCodes['US'].key);
};

export const validatePostalCode = (value, countryCode, useUsZipPlus4 = false) => {
  let validationRule;
  switch (countryCode) {
  case 'CA':
    validationRule = CA_POSTAL_CODE_REG_EXP;
    break;
  case 'AU':
    validationRule = AU_POSTAL_CODE_REG_EXP;
    break;
  case 'NZ':
    validationRule = NZ_POSTAL_CODE_REG_EXP;
    break;
  case 'UY':
    validationRule = UY_POSTAL_CODE_REG_EXP;
    break;
  case 'CO':
    validationRule = CO_POSTAL_CODE_REG_EXP;
    break;
  case 'ZA':
    validationRule = ZA_POSTAL_CODE_REG_EXP;
    break;
  case 'LS':
    validationRule = LS_POSTAL_CODE_REG_EXP;
    break;
  case 'US':
    validationRule = useUsZipPlus4 ? ZIPCODE_US_PLUS_4_REG_EXP : ZIPCODE_REG_EXP;
    break;
  case 'IT':
  case 'ES':
  default:
    validationRule = ZIPCODE_REG_EXP;
  }
  let error;
  if(value && !value.match(validationRule)){
    error = countryCodes[countryCode] && countryCodes[countryCode].error;
  }
  return error;
};

export default validatePostalCode;
