import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';
import {getFormValues, change} from 'redux-form';
import get from 'lodash.get';
import { addMessage } from '../../../actions/systemActions';
import * as itemNames from '../../../constants/itemNames';
import * as dataNames from '../../../constants/dataNames';
import {getDataByPost, getItem, getUnpaginatedData, postItem, putItem} from '../../../actions/apiActions';
import { getIntegrationState } from '../../../selectors/integration/integrationSelectors';
import {
  getCompleteJobPayload,
  getInitJobPayload,
  getItemMasterOptions, getModifyJobPayload,
  getModifyPackagingJobInitialValues,
  getPackagingJob, getPackagingJobDetailInitialValues,
  getPackagingJobItem
} from '../../../selectors/packagingJobSelectors';
import PackagingJobForm from './PackagingJobForm';
import {setItem, unsetItem} from '../../../actions/itemActions';
import {PACKAGING_JOB_FORM} from '../../../constants/forms';
import flattenLocations from '../../../util/flattenLocations';
import {fetchMetrcTrackingIdsForSelectInput} from '../../../actions/integrationActions';
import {convertWeightFromAndTo} from '../../../util/uomHelpers';
import {getCurrentFacilityUserOptions} from '../../../selectors/usersSelectors';
import {getSettingValue} from '../../../util/settingsHelpers';
import {unsetData} from '../../../actions/dataActions';
import {getIngredientsWithCosts} from '../../../selectors/assembliesSelectors';
import getTimezone from '../../../selectors/timezoneSelectors';
import PrinterModal from '../../printer/PrinterModal';

class PackagingJobPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.onSearchComplete = this.onSearchComplete.bind(this);
    this.getAllowedOutputProducts = this.getAllowedOutputProducts.bind(this);
    this.onPrintLabels = this.onPrintLabels.bind(this);
    this.onHidePrinter = this.onHidePrinter.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      loading: true,
      noAvailableWeight: false,
      completed: false,
      printData: null,
      showPrinterModal: false,
      labelPayload: null,
    };
  }

  componentDidMount() {
    try {
      this.loadInitialData();
    } finally {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    this.props.actions.unsetItem(itemNames.packagingJob);
    this.props.actions.unsetData(dataNames.childProducts);
    this.props.actions.unsetData(dataNames.partners);
    this.props.actions.unsetItem(itemNames.inventoryItem);
    this.props.actions.unsetData(dataNames.itemMasters);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.formValues.packages !== this.props.formValues.packages ||
      prevProps.formValues.waste_reported !== this.props.formValues.waste_reported ||
      prevProps.formValues.packaged_loss_amt !== this.props.formValues.packaged_loss_amt) {
      // Calculate total packed weight
      const packages = this.props.formValues.packages || [];
      const totalPackedWeight = packages.reduce((acc, p) => {
        const packaged_weight = parseFloat(p.packaged_weight);
        const uom = p.medicated_weight_uom;
        if (uom) {
          const convertedWeight = convertWeightFromAndTo(packaged_weight, uom, 'GR');
          return acc + convertedWeight;
        }
      }, 0);
      this.props.changeTotalPackedWeight(totalPackedWeight);
      // Calculate remaining weight (input product weight - output product total packed weight - waste reported - loss amount)
      const remainingWeight = this.props.item.qty - totalPackedWeight - this.props.formValues.waste_reported - this.props.formValues.packaged_loss_amt;
      this.props.changeRemainingWeight(remainingWeight);

      const oldPackages = prevProps.formValues.packages || [];
      const newPackages = this.props.formValues.packages || [];

      // handle package quantity changes
      newPackages.forEach((newPkg, index) => {
        const oldPkg = oldPackages[index];
        // Check if there is an old package and if the quantity has changed
        if (oldPkg && newPkg.qty !== oldPkg.qty && !oldPkg.package_code) {
          this.props.generatePackageId(newPkg.item_master_id, (packageId) => {
            this.props.setPackageId(index, packageId);
          });
        }
      });
    }
  }

  loadInitialData() {
    const { actions, params, location, integrationState, complianceSettings } = this.props;
    const isMetrc = get(integrationState, 'isMetrc', false);
    const packagingJobId = params.id;
    const itemIdFromQuery = location.query.item_id;

    if (packagingJobId) {
      this.loadPackagingJobDetails(packagingJobId);
    } else if (itemIdFromQuery) {
      this.getJobItem(itemIdFromQuery);
    }

    const promises = [];
    promises.push(actions.getUnpaginatedData(`/api/partners`, dataNames.partners, { failed: 'partners.get.failed' }));
    promises.push(actions.getUnpaginatedData('/api/location_hierarchy', dataNames.locations, {failed: 'locations.getLocations.failed'}));
    promises.push(actions.getUnpaginatedData('/api/users/current_facility', dataNames.currentFacilityUsers, {failed: 'packaging.getUsers.failed'}));
    promises.push(actions.getItem('/api/integration-settings', itemNames.integrationSettings, { failed: 'stateIntegratorSettings.get.failed' }));
    promises.push(actions.getItem('/api/compliance_settings',itemNames.complianceSettings));
    if (isMetrc) {
      promises.push(actions.fetchMetrcTrackingIdsForSelectInput({type: 'package'}));
    }
    Promise.all(promises)
      .then(() => {
        // When compliance settings are loaded, check if Packaging Ingredients are enabled. If so, load ingredients with costs.
        if (getSettingValue('inv_packaging_ingredients')(complianceSettings)) {
          actions.getUnpaginatedData('/api/ingredient_items/by_facility', dataNames.ingredients, null, {active: 1})
            .then((ingredients) => {
              const ingredientsIds = ingredients.map( i => i.id );
              return actions.getDataByPost('/api/costing/item_masters/multiple', {ids: ingredientsIds}, dataNames.costings);
            });
        }
      });
  }

  loadPackagingJobDetails(packagingJobId) {
    const { actions } = this.props;
    if (packagingJobId === 'new') {
      return;
    }
    actions.getItem(`/api/packaging_jobs/${packagingJobId}/details`, itemNames.packagingJob, { failed: 'items.get.failed' })
      .then((packagingJob) => {
        const itemId = get(packagingJob, 'item.id');
        const status = get(packagingJob, 'status');
        if (status === 'completed') {
          this.setState({completed: true});
        }
        if (itemId) {
          this.getJobItem(itemId);
        }
      });
  }

  getJobItem(itemId) {
    const { actions } = this.props;
    actions.getDataByPost('/api/items/multiple', { ids: [parseInt(itemId)], detailed: 1 }, dataNames.childProducts)
      .then((childProducts) => {
        const packagingJobItem = get(childProducts, '0', []);
        if (packagingJobItem) {
          const strain_id = get(packagingJobItem, 'strain_id');
          actions.getItem(`/api/strains/${strain_id}`, itemNames.strain, {failed: 'packaging.getStrain.failed'});
          actions.setItem(packagingJobItem, itemNames.inventoryItem);
          this.getAllowedOutputProducts(packagingJobItem);
        }
      });
  }

  onSearchComplete(packageId, data) {
    const { actions } = this.props;
    if (data && data.length > 0) {
      // Check if bulk item
      if (get(data, '0.uom') === 'EA') {
        actions.addMessage('error', ['packaging.search.notBulkItem']);
        return;
      }
      const item = data[0];
      actions.setItem(item, itemNames.inventoryItem);
      const availableWeight = (item.qty - (item.qty_reserved || 0));
      if (availableWeight <= 0) {
        this.setState({noAvailableWeight: true})
      }
      actions.change(PACKAGING_JOB_FORM, 'item', data[0]);
      // Find allowed output products
      this.getAllowedOutputProducts(data[0]);
    }
  }

  getAllowedOutputProducts(inventoryItem) {
    const params = {
      active: 1,
      is_draft: 0,
      default_uom: 'EA',
      category_id: inventoryItem.category_id,
      strain_id: inventoryItem.strain_id,
      //is_prepack: 0,
      not_in_ids: [inventoryItem.item_master_id],
    };
    this.props.actions.getItem(`/api/strains/${inventoryItem.strain_id}`, itemNames.strain, {failed: 'packaging.getStrain.failed'});
    this.props.actions.getUnpaginatedData('/api/item_masters/search', dataNames.itemMasters, null, params);
  }

  onPrintLabels() {
    const { printData } = this.state;
    const namedBlocks = printData.reduce((acc, item) => {
      const items = [];
      for (let n = 0; n < parseInt(item.qty); n++) {
        items.push(item.id);
      }
      const named = {
        name: item.prepack_name,
        ids: items
      };
      acc.push(named);
      return acc;

    }, []);
    const payload = {namedBlocks: namedBlocks};
    this.setState({showPrinterModal: true, labelPayload: payload});
  }

  onHidePrinter() {
    this.setState({showPrinterModal: false});
  }
  onSubmit (formValues) {
    const { actions, packagingJob, timezone, item } = this.props;
    const { action } = formValues;

    if (formValues.action === 'print') {
      if (!this.state.printData) {
        this.setState({printData: formValues.output_products});
      }
      this.onPrintLabels();
      return;
    }

    if (packagingJob && packagingJob.id) {
      if (action === 'save') {
        // for existing packaging job
        const modifyJob = getModifyJobPayload(formValues, timezone);
        actions.putItem(`/api/packaging_jobs/${packagingJob.id}`, modifyJob, null, {success: 'packaging.save.success', failed: 'packaging.save.failed'}, {});
      } else {
        // for complete packaging job
        const completeJob = getCompleteJobPayload(formValues, packagingJob, timezone, item);
        actions.postItem(`/api/packaging_jobs/${packagingJob.id}/complete`, completeJob, null, {success: 'packaging.save.success', failed: 'packaging.complete.failed'}, {})
          .then((data) => {
            this.setState({printData: data.items});
            this.onPrintLabels();
            this.setState({completed: true});
          });
      }
    } else {
      // for new packaging job
      const initJob = getInitJobPayload(formValues, timezone);
      actions.postItem(
        '/api/packaging_jobs', initJob, null, {
          success: 'packaging.start.success',
          failed: 'packaging.start.failed'
        }, {})
        .then((job) => {
          if (action === 'save') {
            actions.goBack();
          } else {
            const completeJob = getCompleteJobPayload(formValues, job, timezone, item);
            actions.postItem(`/api/packaging_jobs/${job.id}/complete`, completeJob, null, {
              success: 'packaging.complete.success',
              failed: 'packaging.complete.failed'
            }, {})
              .then((data) => {
                this.setState({printData: data.items});
                this.onPrintLabels();
                this.setState({completed: true});
              });
          }
        });
    }
  }

  render() {
    const { item, integrationState, actions, initialValues, locations, itemMasterOptions, trackingIds, ingredients,
      formValues, ingredientsEnabled, currentFacilityUsers, isDisabled } = this.props;
    const { loading, noAvailableWeight, showPrinterModal, labelPayload, completed } = this.state;

    return (
      <React.Fragment>
        <PackagingJobForm
          initialValues={initialValues}
          formValues={formValues}
          actions={actions}
          change={this.props.actions.change}
          item={item}
          integrationState={integrationState}
          locations={locations}
          itemMasterOptions={itemMasterOptions}
          trackingIds={trackingIds}
          currentFacilityUsers={currentFacilityUsers}
          onSearchComplete={this.onSearchComplete}
          loading={loading}
          ingredientsEnabled={ingredientsEnabled}
          ingredients={ingredients}
          isDisabled={isDisabled || noAvailableWeight || completed}
          onSubmit={this.onSubmit}
        />
        <PrinterModal
          ref='printerModal'
          showPrinter={showPrinterModal}
          hidePrinter={this.onHidePrinter}
          forceLabelBlocks={true}
          httpAction='post'
          labelUrl='/api/labels/generate/inv_package_tag_all/for/many'
          payload={labelPayload}
        />
      </React.Fragment>
    );
  }
}

PackagingJobForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  integrationState: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  itemMasterOptions: PropTypes.array,
  trackingIds: PropTypes.array,
  currentFacilityUsers: PropTypes.array.isRequired,
  onSearchComplete: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  ingredientsEnabled: PropTypes.bool.isRequired,
  ingredients: PropTypes.array,
  isDisabled: PropTypes.bool.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const initialValues = (ownProps.params.id && ownProps.params.id !== 'new') ? getModifyPackagingJobInitialValues(state) : getPackagingJobDetailInitialValues(state);
  const complianceSettings = state[itemNames.complianceSettings];
  const packagingJob = getPackagingJob(state);

  return {
    initialValues,
    formValues: getFormValues(PACKAGING_JOB_FORM)(state) || initialValues,
    packagingJobId: ownProps.params.id,
    packagingJob: packagingJob,
    item: getPackagingJobItem(state),
    integrationState: getIntegrationState(state),
    locations: flattenLocations(state[dataNames.locations]),
    itemMasterOptions: getItemMasterOptions(state),
    trackingIds: state.trackingIds,
    currentFacilityUsers: getCurrentFacilityUserOptions(state),
    complianceSettings: complianceSettings,
    ingredientsEnabled: !!getSettingValue('inv_packaging_ingredients')(complianceSettings),
    ingredients: getIngredientsWithCosts(state),
    isDisabled: get(packagingJob, 'status', 'open') === 'completed',
    timezone: getTimezone(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    change,
    goBack,
    getItem,
    setItem,
    getDataByPost,
    getUnpaginatedData,
    unsetData,
    unsetItem,
    putItem,
    postItem,
    addMessage,
    fetchMetrcTrackingIdsForSelectInput,
  }, dispatch),
  changeTotalPackedWeight: (value) => dispatch(change(PACKAGING_JOB_FORM, 'total_packed_weight', value)),
  changeRemainingWeight: (value) => dispatch(change(PACKAGING_JOB_FORM, 'remaining_weight', value)),
  generatePackageId: (itemMasterId, next) => {
    dispatch(getItem(
      `/api/item_masters/${itemMasterId}/generate_id`,
      itemNames.packageId,
      {failed: 'packaging.generatePackageId.failed'},
      {},
      next
    ));
  },
  setPackageId: (index, value) => dispatch(change(PACKAGING_JOB_FORM, `packages[${index}].package_code`, value)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PackagingJobPage));
