import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {ListGroup, ListGroupItem} from 'react-bootstrap';
import {FaCheck} from 'react-icons/fa';
import {I18n} from 'react-redux-i18n';
import {postItem, deleteItem} from '../../../../actions/apiActions';
import * as itemNames from '../../../../constants/itemNames';
import ModalWrapper from '../../../common/ModalWrapper';

const getAt = get;

export class CancelOrder extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onHideModal = this.onHideModal.bind(this);
    this.onClickReason = this.onClickReason.bind(this);
    this.onEvent_cancel = this.onEvent_cancel.bind(this);

    this.cancelReasons = {
      cancelled: 'Cancelled By Customer',
      cancelled_refused__incomplete_information: 'Order Refused - Incomplete Information',
      cancelled_refused__expired_registration: 'Order Refused - Expired Registration',
      cancelled_refused__order_exceeds_authorized_amount: 'Order Refused - Order Exceeds Authorized Amount',
      cancelled_refused_product_out_of_stock: 'Order Refused - Product Out of Stock',
      cancelled_refused_other: 'Order Refused - Other'
    };

    this.state = {
      lastEvent: false,
      showModal: false,
      showModalCountryList: ['CA'],
      reasonCancelled: false
    };
  }

  componentWillReceiveProps(nextProps){
    if (nextProps.event === this.state.lastEvent) return false;
    if (nextProps.facility.id !== this.props.facility.id) return false;
    this.setState({lastEvent: nextProps.event});
    this.handleEvent(nextProps.event, nextProps);
  }

  handleEvent(event, props){
    const method = `onEvent_${event}`;
    if(typeof this[method] !== 'function') return false;
    this[method](props);
  }

  onEvent_cancel(props){
    if(this.state.showModalCountryList.indexOf(getAt(props, 'facility.country_code', 'US')) !== -1) {
      this.setState({showModal: true});
      return false;
    }
    this.onCancelOrder(false, false, props);
  }

  onCancelOrder(option, value, props = false){
    if(!props) props = this.props;
    const event = 'cancel';
    if(props.event === 'quit' || !props.orderId) return false;
    const payload = {};
    if(option) payload[option] = value;

    // foreign event handlers
    const onBeforeCancel = getAt(props.eventListeners, `${event}.onBeforeCancel`, false);
    const onAfterCancel = getAt(props.eventListeners, `${event}.onAfterCancel`, false);
    const onError = getAt(props.eventListeners, `${event}.onError`, false);

    const finishCancel = (data) => {
      data = data ? data : {};
      if (typeof onAfterCancel === 'function') onAfterCancel(option, value, data);
      this.setState({lastEvent: 'complete'});
    };

    if(typeof onBeforeCancel === 'function') onBeforeCancel(option, value);

    const orderUrl = `/api/orders/${props.orderId}/cancel_order`;
    const orderMessages = {success: 'orders.delete.success', failed: 'orders.removed.failed'};
    const queueUrl = props.customerId ? `/api/queue/customers/${props.customerId}` : null;
    const queueMessages = {success: 'orders.delete.customerSuccess', failed: 'orders.removed.customerFailed'};
    const catchError = (e) => {
      this.setState({lastEvent: 'error'});
      if(typeof onError === 'function') onError(e);
    };

    this.props.actions.postItem(orderUrl, payload, itemNames.order, orderMessages, null, () => { // cancel order
      if (queueUrl) {
        this.props.actions.deleteItem(queueUrl, null, queueMessages, null, finishCancel).catch(catchError); // cancel in queue
      } else {
        finishCancel();
      }
    }).catch(catchError);
  }

  onHideModal(event){
    const newState = {showModal: false, lastEvent: 'closeModal', reasonCancelled: false};
    if(event.buttonClicked === 'okay') {
      this.onCancelOrder('order_status', this.state.reasonCancelled);
      delete(newState.reasonCancelled);
    } else {
      const onDismiss = getAt(this.props.eventListeners, 'onDismiss', false);
      if(typeof onDismiss === 'function'){
        onDismiss();
      }
    }
    this.setState(newState);
  }

  onClickReason(reason){
    this.setState({reasonCancelled: reason});
  }

  render() {
    if(this.state.showModalCountryList.indexOf(getAt(this.props, 'facility.country_code', 'US')) === -1) return null;
    const onClickReason = (e, reason) => {
      e.target.blur();
      this.onClickReason(reason);
    };
    return (
      <div>
        <ModalWrapper
          showModal={this.state.showModal}
          onHide={this.onHideModal}
          title={{text: 'cart.cancelOrder.title', data: {id: this.props.orderId}}}
          version={2}
          headerClass='bg-white'
          widthClass='modal-sm'
          cancelButton={{show: true, text: 'cart.cancelOrder.cancelButton'}}
          okayButton={{show: true, text: 'cart.cancelOrder.okayButton', disabled: !this.state.reasonCancelled}}
          keyboard={true}
        >
          <div>
            <div style={{marginBottom: '12px'}}>
              {I18n.t('cart.cancelOrder.instructions')}
            </div>
            <ListGroup> {
              Object.keys(this.cancelReasons).map((key) => {
                const reasonText = this.cancelReasons[key];
                return (
                  <ListGroupItem key={key} onClick={(e) => onClickReason(e, key)}>
                    {this.state.reasonCancelled === key ? <FaCheck style={{marginRight: '5px'}} /> : null}
                    {reasonText}
                  </ListGroupItem>
                );
              })
            }
            </ListGroup>
          </div>
        </ModalWrapper>
      </div>
    );
  }
}

CancelOrder.propTypes = {
  options: PropTypes.object, // {text: string, value: mixed, events: [onClick, onComplete] if present is modal
  customerId: PropTypes.number.isRequired,
  orderId: PropTypes.number.isRequired,
  event: PropTypes.string.isRequired, // cancelOrder, quit/close
  eventListeners: PropTypes.object,
  facility: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    facility: state[itemNames.facility],
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {postItem, deleteItem};
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CancelOrder);
