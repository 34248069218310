import moment from 'moment-timezone';

export const betweenDates = (start, end) => {
  const current = moment.utc();
  let notBefore = true;
  if(start){
    notBefore = current.isAfter(moment.utc(start));
  }

  let notAfter = true;
  if(end){
    notAfter = current.isBefore(moment.utc(end));
  }

  return notBefore && notAfter;
};

export const convertDbToClientTZ = (dbDate, timezone) => {
  if(timezone === undefined){
    timezone = moment.tz.guess();
  }
  if (!dbDate) {
    return '';
  }
  const dateTime = moment.isMoment(dbDate) ? dbDate.format('YYYY-MM-DD HH:mm:ss') : dbDate;

  const converted = moment.tz(dateTime, 'UTC');
  if (!converted.isValid()) {
    return '';
  }
  return converted.clone().tz(timezone);
};

export const isMoment = (input) => {
  return moment.isMoment(input);
};

export const convertClientToDbTz = (formInput, timezone) => {
  if(timezone === undefined){
    timezone = moment.tz.guess();
  }
  if (!formInput) {
    return '';
  }
  const dateTime = moment.isMoment(formInput) ?
    formInput.format('YYYY-MM-DD HH:mm:ss') :
    moment(formInput).format('YYYY-MM-DD HH:mm:ss');
  const converted = moment.tz(dateTime, timezone);
  if (!converted.isValid()) {
    return '';
  }
  return converted.clone().tz('UTC');
};


export const convertDbDateTimeToClientTzMoment = (dbDate, timezone) => {
  if(timezone === undefined){
    timezone = moment.tz.guess();
  }
  if (!dbDate) {
    return '';
  }
  // get a moment object
  const dateTime = moment.utc(dbDate, 'YYYY-MM-DD HH:mm:ss');

  // translate into client tz
  return dateTime.clone().tz(timezone);
};


export const convertDbTimeToClientTzMoment = (dbTime, timezone) => {
  if(timezone === undefined){
    timezone = moment.tz.guess();
  }
  if (!dbTime) {
    return '';
  }
  // get a moment object
  const dateTime = moment.utc(dbTime, 'HH:mm:ss');

  // translate into client tz
  return dateTime.clone().tz(timezone);
};


export const convertDbDateToFormInputDate = (dbDate, timezone) => {
  const final = convertDbToClientTZ(dbDate, timezone);
  return moment.isMoment(final) ? final.format('MM/DD/YYYY') : '';
};

export const convertDbDateTimeToFormInputDateTime = (dbDate, timezone) => {
  const final = convertDbToClientTZ(dbDate, timezone);
  return moment.isMoment(final) ? final.format('MM/DD/YYYY h:mm A') : '';
};

export const convertDbDateTimeToFormInputDateTimeWithSeconds = (dbDate, timezone) => {
  const final = convertDbToClientTZ(dbDate, timezone);
  return moment.isMoment(final) ? final.format('MM/DD/YYYY h:mm:ss A') : '';
};

export const convertDbTimeToFormInputTime = (dbDate, timezone ) => {
  const final = convertDbToClientTZ(moment(dbDate, 'HH:mm:ss'), timezone);
  return moment.isMoment(final) ? final.format('h:mm A') : '';
};

export const convertDbDateToShortDateTime = (formInput, timezone ) => {
  const final = convertDbToClientTZ(formInput, timezone);
  return moment.isMoment(final) ? final.format('MM/DD h:mm A') : '';
};

export const convertDbDateTimeToFullDateTime = (dbDate, timezone) => {
  const final = convertDbToClientTZ(dbDate, timezone);
  return moment.isMoment(final) ? final.format('MM/DD/YYYY h:mm A') : '';
};

export const convertDbDateTimeToDaysAgo = (dbDate, timezone) => {
  const final = convertDbToClientTZ(dbDate, timezone);
  return moment.isMoment(final) ? moment().diff(final, 'days') : '';
};

export const convertFormInputDateToDbDate = (formInput, timezone ) => {
  const final = convertClientToDbTz(formInput, timezone);
  return moment.isMoment(final) ? final.format('YYYY-MM-DD HH:mm:ss') : '';
};

export const convertFormInputDateToDbTime = (formInput, timezone ) => {
  const final = convertClientToDbTz(formInput, timezone);
  return moment.isMoment(final) ? final.format('HH:mm:ss') : '';
};

// convert DatePicker's inner date object
// to string with DATE (without time) entered by client
export const convertFormInputDateToYYYYMMDD = (formInput, timezone ) => {
  if(timezone === undefined){
    timezone = moment.tz.guess();
  }
  if (!formInput) {
    return '';
  }
  const dateTime = moment.isMoment(formInput) ?
    formInput.format('YYYY-MM-DD HH:mm:ss') :
    moment(formInput).format('YYYY-MM-DD HH:mm:ss');
  const converted = moment.tz(dateTime, timezone);
  if (!converted.isValid()) {
    return '';
  }
  return moment.isMoment(converted) ? converted.format('YYYY-MM-DD') : '';
};

// format date with time set to 12:00:00 UTC
export const convertFormInputDateToMidDay = (formInput, timezone) => {
  const dateTime = moment.isMoment(formInput) ? formInput : moment(formInput);
  const converted = dateTime.clone().set({
    hour: 12,
    minute: 0,
    second: 0
  });
  return convertFormInputDateToDbDate(converted,timezone);
};

const wrapDate = ({date, fromFormat, isUtc, timezone}) => {
  if (!date) {
    return;
  }
  if (date.format) {
    return date;
  }
  let wrapped;
  if(isUtc){
    wrapped = fromFormat ? moment.utc(date, fromFormat) : moment.utc(date);
  }else{
    wrapped = fromFormat ? moment(date, fromFormat) : moment(date);
  }
  if (timezone) {
    wrapped = wrapped.tz(timezone);
  }
  return wrapped.isValid() ? wrapped : undefined;
};

const format = format => (date, defValue, fromFormat, timezone = 'UTC', isUtc = true) => {
  if (date) {
    const wrappedDate = wrapDate({date, fromFormat, timezone, isUtc});
    return wrappedDate ? wrappedDate.format(format) : defValue;
  }
  return null;
};

export const setTZ = (dateTime, timezone = 'UTC') => moment(dateTime).tz(timezone);

export const formatCustomDate = customFormat => format(customFormat);
export const formatDate = format('YYYY-MM-DD');
export const formatClientDate = format('MM/DD/YYYY');
// export const formatClientShortDate = format('MM/DD');
// export const convertDbDateToShortDate = formatUTC('MM/DD');
// export const convertDbDateToShortDateTime = formatUTC('MM/DD h:mm A');
// export const convertDbDateToClientDate = formatUTC('MM/DD/YYYY');
// export const convertDbDateToClientDateTime = formatUTC('MM/DD/YYYY h:mm A');
// export const convertDbDateToFullDateTime = formatUTC('LLL');
// export const formatClientDateUTC = formatUTC('MM/DD/YYYY');
export const formatTime = format('HH:mm:ss');
export const formatClientTime = format('h:mm A');
export const formatDateTime = format('YYYY-MM-DD HH:mm:ss');
export const formatMetrcDate = format('M/DD/YY');
export const formatMetrcDateTime = format('M/DD/YY HH:mm:ss');
export const formatMetrcORDateTime = format('YYYY-MM-DD HH:mm:ss');
export const formatClientDateTime = format('MM/DD/YYYY h:mm A');
export const formatFullDateTime = format('LLL');
export const getToday = () => moment().startOf('day');
export const getYesterday = () => getToday().subtract(1, 'day');
export const getWeekAgo = () => getToday().subtract(7, 'day');
export const getFourWeeksAgo = () => getToday().subtract(28, 'day');

/***
 * Returns moment formatted date from DB date format and accounts for solr forcing timezone onto string
 * Handles nulls returning null
 * @param date
 * @returns {*}
 */
export const formatInputDate = (date) => {
  if(date === null) return null;
  if(typeof date === 'string'){
    if(date.indexOf('T') !== -1){ // Handles solr forcing time onto dates
      const temp = date.split('T');
      date = temp[0];
    }
  }
  return moment(date).format('MM/DD/YYYY');
};

//To replace formatInputDate
export const formatInternationalInputDate = (date, format) => {
  if(date === null) return null;
  if(typeof date === 'string'){
    if(date.indexOf('T') !== -1){ // Handles solr forcing time onto dates
      const temp = date.split('T');
      date = temp[0];
    }
  }
  if (format === undefined || format === null) format = 'MM/DD/YYYY';
  return moment(date).format(format);
};

/***
 * Returns DB formatted date from moment object.
 * Handles nulls and empty strings
 * @param date
 * @returns {*}
 */
export const formatDBDate = (date) => {
  if(date === null) return null;
  if(typeof date === 'string'){
    if(date.trim() === '') return null;
  }
  return moment(date).format('YYYY-MM-DD');
};

/**
 * Helper for sort in <Table>
 *
 * @example In column definition:
 * {
 *    ...
 *    dataId: 'created_at_formatted',
 *    sortFunc: sortByDifferentField('created_at'),
 *    ...
 * },
 *
 * @param fieldName
 * @returns {function(*, *, *)}
 */
export const sortByDifferentField = function(fieldName) {
  return (a, b, order) => {
    if (order === 'desc') {
      return b[fieldName].localeCompare(a[fieldName]);
    } else {
      return a[fieldName].localeCompare(b[fieldName]);
    }
  };
};


/**
 * Helper for sort in <Table>
 *
 * @example In column definition:
 * {
 *    ...
 *    dataId: 'event_date',
 *    sortFunc: sortByAdditionalFieldIfEqual('event_date', 'id'),
 *    ...
 *
 *    // result
 *    asc
 *    1) event_date=2018-04-04 09:50:50 id=1
 *    2) event_date=2018-04-04 09:50:50 id=2
 *
 *    desc
 *    1) event_date=2018-04-04 09:50:50 id=2
 *    2) event_date=2018-04-04 09:50:50 id=1
 * },
 *
 * @param fieldName
 * @param additionalField
 * @returns {function(*, *, *)}
 */
export const sortByAdditionalFieldIfEqual = function(fieldName, additionalField) {
  return (a, b, order) => {
    const d1 = Date.parse(a[fieldName]);
    const d2 = Date.parse(b[fieldName]);

    if (order === 'asc') {
      return d1 - d2 || a[additionalField] - b[additionalField];
    } else {
      return d2 - d1 || b[additionalField] - a[additionalField];
    }
  };
};

export const isValidDate = (date) => {
  const isPotentialDate = (date) => {
    if(!date) { // trap undefined
      return false;
    }
    const inputType = typeof date;
    // ensure its a string, date, or moment object.
    if(inputType !== 'string'){
      if(inputType !== 'object' || !date.constructor){
        return false;
      }
      if(['Date', 'Moment'].indexOf(date.constructor.name) === -1){
        return false;
      }
    }
    return true;
  };

  if(!isPotentialDate(date)){
    return false;
  }

  const dateObject = isMoment(date)
    ? date.toDate()
    : typeof date === 'string'
      ? moment(date).toDate()
      : date;
  // if not a valid date getTime() returns NaN and NaN is never equal to another NaN
  return dateObject.getTime() === dateObject.getTime();
};


