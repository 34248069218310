import React from 'react';
import PropTypes from 'prop-types';
import {Row} from 'react-bootstrap';
import get from 'lodash.get';
import * as itemNames from '../../../../constants/itemNames';
import MmapPaymentReduxForm from './MmapPaymentReduxForm';

class MmapPaymentComponent extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(formData) {
    const {user, order, showLoader, hideLoader, actions: {postItem}, goToPage} = this.props;
    const payload = {
      amount: get(formData, 'amount', 0),
      payment_type: 'mmap',
      register_id: get(user,'currentRegister',null)
    };

    showLoader();
    return postItem(
      `/api/orders/${order.id}/payments`,
      payload,
      itemNames.order,
      {failed: 'cart.mmapPayment.failed'},
      undefined,
      (response) => {
        hideLoader();
        if (response.locked && response.order_status == 'completed') {
          goToPage('payment');
        }
      }
    ).catch(hideLoader);
  }

  render() {
    const {payments, form, order, customer, actions: {addMessage}} = this.props;
    // Only allow one MMAP payment to be added
    return (
      <Row>
        <MmapPaymentReduxForm
          form={form}
          payments={payments}
          onSubmit={this.onSubmit}
          order={order}
          customer={customer}
          addMessage={addMessage}
          readOnly={payments.some(payment => payment.payment_type === 'mmap')}
        />
      </Row>
    );
  }
}

MmapPaymentComponent.propTypes = {
  actions: PropTypes.shape({
    postItem: PropTypes.func.isRequired,
  }).isRequired,
  order: PropTypes.object.isRequired,
  form: PropTypes.string.isRequired,
  payments: PropTypes.array.isRequired,
  appliesTo: PropTypes.string,
  showLoader: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
};

export default MmapPaymentComponent;
