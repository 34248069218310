import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Row, Table, Card} from 'react-bootstrap';
import {mappedValues} from '../../../../constants/ratingOptions';

const HarvestBatchQualityRatingDetails = ({model}) => {
  return (
    <Card>
      <Row>
        <Col xs={6}>
          <h3>{I18n.t('batchHistory.model.harvestBatchDetails')}</h3>
          <Table striped className='item-details'>
            <thead>
            <tr>
              <th>{I18n.t('itemTransactions.field')}</th>
              <th>{I18n.t('itemTransactions.value')}</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>{I18n.t('batchHistory.modal.totalQuality')}</td>
              <td>{mappedValues[model.rating_avg_total_quality] || model.rating_avg_total_quality}</td>
            </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Card>
  );
};

HarvestBatchQualityRatingDetails.propTypes = {
  model: PropTypes.object.isRequired,
};

export default HarvestBatchQualityRatingDetails;
