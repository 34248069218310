import {I18n} from 'react-redux-i18n';

export const grow = 'grow'; //cultivation
export const manufacturing = 'manufacturing';
export const dispensary = 'dispensary'; //retail
export const lab = 'lab';
export const courier_service = 'courier_service';
export const regulatorAccess = 'regulator_access';
export const researchUniversity = 'research_university';

export const facilityTypeLabels = {
  [grow]: I18n.t('facilities.type.grow'),
  [manufacturing]: I18n.t('facilities.type.manufacturing'),
  [dispensary]: I18n.t('facilities.type.retail'),
  [lab]: I18n.t('facilities.type.lab'),
  [courier_service]: I18n.t('facilities.type.courierService'),
  [regulatorAccess]: I18n.t('facilities.type.regulatorAccess'),
  [researchUniversity]: I18n.t('facilities.type.researchUniversity'),
};

export const GROW = 'grow';
export const MANUFACTURING = 'manufacturing';
export const RETAIL = 'dispensary';
export const LAB = 'lab';
