import PropTypes from 'prop-types';
import {reduxForm, formValueSelector} from 'redux-form';
import {connect} from 'react-redux';

import {getCategoryTaxRate} from '../../../../selectors/taxProfilesSelectors';
import {getIsHempFacility} from '../../../../selectors/coreSettingsSelectors';
import validate from '../common/validate';
import PricingGroupForm from '../common/PricingGroupForm';
import {CREATE_PRICING_GROUP_FORM} from '../../../../constants/forms';

const form = CREATE_PRICING_GROUP_FORM;
const getFormValue = formValueSelector(form);

const PricingGroupReduxForm = reduxForm({
  form,
  validate,
})(PricingGroupForm);

function mapStateToProps(state) {
  const categoryId = getFormValue(state, 'category_id');
  const formData = state.form[form];
  return {
    disableUomSelection: true,
    disableCategorySelection: true,
    showSaveAndReturn: false,
    getFormValue: (name) => getFormValue(state, name),
    taxRate: getCategoryTaxRate(state, {categoryId}),
    fieldsMetadata: formData && formData.fields ? formData.fields : {pricing_weights: []},
    isHempFacility: getIsHempFacility(state)
  };
}

const ModifyPricingGroupFormWrapper = connect(mapStateToProps)(PricingGroupReduxForm);

ModifyPricingGroupFormWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCategoryChange: PropTypes.func.isRequired,
  integrationState: PropTypes.object.isRequired,
  onPricingWeightAdded: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  taxProfiles: PropTypes.array.isRequired,
  pricingClasses: PropTypes.array.isRequired,
  pricingWeights: PropTypes.array.isRequired,
  initialValues: PropTypes.object,
};

export default ModifyPricingGroupFormWrapper;
