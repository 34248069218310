import React from 'react';
import {I18n} from 'react-redux-i18n';
import {Button, Card} from 'react-bootstrap';
import {Field} from 'redux-form';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import NumericInput from '../../common/form/NumericInput';

const MetrcPackageDetails = ({ row, adjustmentReasons, prefix, onAdjust, getReduxFormItem, disableMjpAdjustButton }) => {
  return (
    <Card>
      <Card.Header>
        <div className='layout-row layout-align-start-start flex-header reconciliation-header'>
          <div className='flex' title={I18n.t('reconciliation.itemName')}>{I18n.t('reconciliation.itemName')}</div>
          <div className='flex' title={I18n.t('reconciliation.packageId')}>{I18n.t('reconciliation.packageId')}</div>
          <div className='flex' title={I18n.t('reconciliation.unitSize')}>{I18n.t('reconciliation.unitSize')}</div>
          <div className='flex' title={I18n.t('reconciliation.packageQty')}>{I18n.t('reconciliation.packageQty')}</div>
          <div className='flex' title={I18n.t('reconciliation.form.storageLocation')}>{I18n.t('reconciliation.form.storageLocation')}</div>
          <div className='flex' title={I18n.t('packages.form.adjustmentReason')}>{I18n.t('packages.form.adjustmentReason')}</div>
          <div className='flex'></div>
        </div>
      </Card.Header>
      {row.children.map((item, index) => {
        const unitSize = get(item, 'is_prepack') ? get(item, 'prepack_name') : 'N/A';
        const itemName = get(item, 'is_prepack') ? get(item, 'parent_item_master_name') : item.name;
        return (
            <div key={index}>
              <div role='tablist' className='panel-group'>
                <Card>
                  <Card.Header role='tab'>
                    <div aria-expanded='false' className='collapsed'>
                      <div className='layout-row layout-align-start-start flex-header'>
                        <div className='flex' title={itemName}>{itemName}</div>
                        <div className='flex' title={item.package_code}>{item.package_code}</div>
                        <div className='flex' title={unitSize}>{unitSize}</div>
                        <div className='flex'>
                          <Field name={`${prefix}.children.${index}.quantity`} component={NumericInput} props={{
                            allowNegativeNumber: false,
                            fractionPartSize: item.uom === 'EA' ? 0 : 2,
                            style: {width: '50%'}
                          }}/>
                        </div>
                        <div className='flex' title={item.location_name}>{item.location_name}</div>
                        <div className='flex'>
                          <Field
                            name={`${prefix}.children.${index}.adjustment_reason`}
                            component={ReactSelectInput}
                            props={{
                              options: adjustmentReasons,
                              textKey: 'name',
                              valueKey: 'code'
                            }}
                          />
                        </div>
                        <div className='flex text-align-center'>
                          <Button
                            variant='primary'
                            disabled={disableMjpAdjustButton(row, index)}
                            onClick={() => {
                              const item = getReduxFormItem(row);
                              const itemName = `${prefix}.children.${index}`;
                              onAdjust([{itemName, item: item.children[index]}], {});
                            }
                            }>
                            {I18n.t('reconciliation.actions.adjust')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Card.Header>
                </Card>
              </div>
            </div>
        );
      })}
    </Card>
  );
};

MetrcPackageDetails.propTypes = {
  row: PropTypes.object,
  adjustmentReasons: PropTypes.array,
  prefix: PropTypes.string,
  onAdjust: PropTypes.func,
  getReduxFormItem: PropTypes.func,
  disableMjpAdjustButton: PropTypes.func
};

export default MetrcPackageDetails;
