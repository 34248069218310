import {createSelector} from 'reselect';
import {formValueSelector} from 'redux-form';
import {CHECK_IN_CURE_API} from '../../constants/forms';
import {CURE_API_CAREGIVER} from '../../constants/idTypes';
import {formatDate} from '../../util/dateHelpers';

const formSelector = formValueSelector(CHECK_IN_CURE_API);
export const isCaregiverType = type => type === CURE_API_CAREGIVER;

export const getLicenseType = state => formSelector(state, 'license_type');
export const isCureCaregiverMode = createSelector(
  [getLicenseType],
  isCaregiverType
);

export const getMatchingCustomer = (customers, formData) => customers.find(
  (customer) => {
    if (isCaregiverType(formData.license_type)) {
      return true;
    }
    const rules = [
      {field: 'first_name', format: v => String(v).toLowerCase()},
      {field: 'last_name', format: v => String(v).toLowerCase()},
      {field: 'dob', customer_field: 'birth_date', format: formatDate, formatCustomer: v => v},
    ];

    return rules.every(rule => {
      const formValue = formData[rule.field];
      const customerValue = customer[rule.customer_field || rule.field];

      return !formValue || rule.format(formValue) === (rule.formatCustomer || rule.format)(customerValue);
    });
  }
);

export const hasRequiredFields = formData => ['first_name', 'last_name', 'dob'].every(field => formData && formData[field]);

export const getCureApiPayload = (formData, consumer_id) => {
  const {first_name, last_name, patient_number, dob} = formData;
  if (!isCaregiverType(formData.license_type) && consumer_id) {
    return {
      consumer_id,
      patient_number,
    };
  }

  return {
    consumer_id,
    first_name,
    last_name,
    patient_number,
    dob: formatDate(dob),
  };
};
