import * as types from '../../constants/actionTypes';
import {CREATE_PLANT_FORM} from '../../constants/forms';

export default function createPlantForm (state, action) {
  const {payload, type, meta} = action;
  if(meta && meta.form === CREATE_PLANT_FORM){
    if(type === types.REDUX_FORM_CHANGE ){
      switch (meta.field) {
      case 'strain_id': {
        if(payload != state.values[meta.field]){
          return {...state, values:{...state.values, phenotype_id: null, starting_mother_plant_id: null, grandmother_plant: null}};
        }
        break;
      }
      }
    }
  }
  return state;
}
