import get from 'lodash.get';
import { createSelector } from 'reselect';
import * as itemNames from '../constants/itemNames';
import * as dataNames from '../constants/dataNames';

export const getComplianceSettings = (state) => state[itemNames.complianceSettings];
export const getPatientCompliance = (state) => state[itemNames.patientComplianceSettings] || state[itemNames.patientSettings];
export const getOnHandWeightFacility = (state) => state[itemNames.onHandWeightFacility];
export const getOnHandWeightOrganization = (state) => state[itemNames.onHandWeightOrganization];
export const getCategories = (state) => state[dataNames.categories];
export const getCurrentFacility = (state) => state.facility;
export const getFacilities = (state) => state.facilities;

export const getInventoryComplianceSettings = createSelector(
  [getComplianceSettings],
  (settings) => {
    const cleanSettings =
      get(settings, 'inv_compliance_settings.value[0]') || get(settings, 'inv_compliance_settings.value') || {};

    const getNumericValueAsString = (value, defaultValue = false) => {
      return value === undefined
        ? defaultValue
          ? defaultValue
          : '0'
        : isNaN(parseInt(value))
        ? value
          ? '1'
          : '0'
        : value + '';
    };

    return {
      ...cleanSettings,
      include_infused_weight_on_hand: getNumericValueAsString(cleanSettings.include_infused_weight_on_hand),
      require_on_hand_product_expire_date: getNumericValueAsString(cleanSettings.require_on_hand_product_expire_date),
      medicated_weight_auto_suffix: getNumericValueAsString(cleanSettings.medicated_weight_auto_suffix, '1'),
      append_brand_to_item_master_name: getNumericValueAsString(cleanSettings.append_brand_to_item_master_name, '1'),
      inv_packaging_ingredients: getNumericValueAsString(cleanSettings.inv_packaging_ingredients, '1'),
      enable_reservations: getNumericValueAsString(cleanSettings.enable_reservations, '1'),
      allow_negative_inventory: getNumericValueAsString(cleanSettings.allow_negative_inventory, '0'),
      allow_label_without_completing: getNumericValueAsString(cleanSettings.allow_label_without_completing, '0'),
      allow_package_location_split: get(settings, 'inv_allow_package_location_split.value', true),
      inv_packages_require_tracking_id: get(settings, 'inv_packages_require_tracking_id.value', false),
      inv_packages_require_tracking_id_message: get(settings, 'inv_packages_require_tracking_id_message.value', ''),
      inv_track_waste_packages: get(settings, 'inv_track_waste_packages.value', false),
      inv_require_waste_transfer: get(settings, 'inv_require_waste_transfer', null),
      archive_inactive_inventory_after: getNumericValueAsString(cleanSettings.archive_inactive_inventory_after, '0'),
      update_transfer_manifest_inventory_receipt_with_additional_data: getNumericValueAsString(
        cleanSettings.update_transfer_manifest_inventory_receipt_with_additional_data
      ),
      reconciliation_requires_adjustment_reason: get(settings, 'reconciliation_requires_adjustment_reason.value', false),
      designate_inventory_created_from_processing_as_test_sample: get(settings, 'designate_inventory_created_from_processing_as_test_sample.value', false),
      testing_lab_designates_partner_facility_on_test_result: get(settings, 'testing_lab_designates_partner_facility_on_test_result.value', false),
      enable_inventory_item_locks: getNumericValueAsString(cleanSettings.enable_inventory_item_locks, '0'),
      inv_test_results_retest_limit: get(settings, 'inv_test_results_retest_limit.value', -1)
    };
  }
);

export const getRequireWastePackageTracking = createSelector(
  [getInventoryComplianceSettings],
  (settings) => {
    return [1, '1', true].includes(get(settings, 'inv_track_waste_packages'));
  }
);

export const getWastePackageTrackingIsOverrideable = createSelector(
  [getInventoryComplianceSettings],
  (settings) => {
    return get(settings, 'inv_require_waste_transfer.overrideable', false);
  }
);

export const getRequireWasteTransfer = createSelector(
  [getInventoryComplianceSettings],
  (settings) => {
    return get(settings, 'inv_require_waste_transfer');
  }
);

export const isAllowNegativeInventory = createSelector(
  [getInventoryComplianceSettings],
  (settings) => {
    return [1, '1', true].includes(get(settings, 'allow_negative_inventory'));
  }
);


export const isMedicatedWeightAutoSuffix = createSelector(
  [getInventoryComplianceSettings],
  (settings) => {
    return [1, '1', true].includes(get(settings, 'medicated_weight_auto_suffix'));
  }
);

export const isAppendBrandToItemMasterName = createSelector(
  [getInventoryComplianceSettings],
  (settings) => {
    return [1, '1', true].includes(get(settings, 'append_brand_to_item_master_name'));
  }
);

export const isShowPackagingIngredients = createSelector(
  [getInventoryComplianceSettings],
  (settings) => {
    return [1, '1', true].includes(get(settings, 'inv_packaging_ingredients'));
  }
);

export const getBlockExpiredProductAction = createSelector(
  [getInventoryComplianceSettings],
  (settings) => {
    return get(settings, 'block_expired_product_action');
  }
);


export const getIsReservationEnabled = createSelector(
  [getInventoryComplianceSettings],
  (settings) => {
    return [1, '1', true].includes(get(settings, 'enable_reservations'));
  }
);

export const getPatientsComplianceSettings = createSelector(
  [getPatientCompliance],
  (settings) => settings.messages
);
export const getPatientsComplianceLimits = createSelector(
  [getPatientCompliance],
  (settings) =>
    settings &&
    settings.partner_retail_patient_compliance_settings &&
    settings.partner_retail_patient_compliance_settings.value
);

export const patientSettingsGoesByEnabled = createSelector(
  [getPatientsComplianceLimits],
  (settings) =>
      get(settings, 'goes_by_enabled', false)
);

export const manufactoreExiseTaxSettingsValues = createSelector(
  [getComplianceSettings],
  (settings) => {
    const all = (settings && settings.mf_exise_tax_settings && settings.mf_exise_tax_settings.value) || [];
    return all.filter((item) => item.monetary_value !== null && item.weight_value !== null);
  }
);

export const getFacilitiesToSelect = createSelector(
  [getCurrentFacility, getFacilities],
  (currentFacility, facilities) => {
    return facilities.filter((facility) => facility.id !== currentFacility.id);
  }
);

export const getInitialValuesForEICompliance = createSelector(
  [getInventoryComplianceSettings, manufactoreExiseTaxSettingsValues, getComplianceSettings, getCategories],
  (inventorySettings, settingsValues, complianceSettings, categories) => {
    const facilitiesToSelect =
      complianceSettings &&
      complianceSettings.core_facility_coupling &&
      complianceSettings.core_facility_coupling.value;
    const taxSettings = settingsValues.map((setting) => {
      const setting_category =
        categories && categories.find((category) => category.category_code === setting.category_code);
      return {
        ...setting,
        ...setting_category,
        category_id: {
          id: setting_category && setting_category.id,
          name: setting_category && setting_category.name,
          category_code: setting_category && setting_category.category_code
        }
      };
    });
    return {
      inv_compliance_settings: inventorySettings || {},
      core_facility_coupling: facilitiesToSelect,
      mf_exise_tax_settings: taxSettings
    };
  }
);

export const getPlantComplianceSettings = createSelector(
  [getComplianceSettings],
  (settings) => {
    const plants_setting_keys = ['cult_distribute_harvest_weights', 'cult_plants_in_phases', 'cult_plants_on_hand'];
    const plants_settings = plants_setting_keys.map(
      (key) =>
        settings[key] && settings[key].value && Object.keys(settings[key].value).length !== 0 && settings[key].value
    );
    return {
      ...plants_settings[0],
      ...plants_settings[1],
      ...plants_settings[2]
    };
  }
);

/**
 *
 * @type {Reselect.Selector<any, boolean>}
 */
export const getIsLabelEnabledForNoCompletedProcess = createSelector(
  [getInventoryComplianceSettings],
  (settings) => {
    return [1, '1', true].includes(get(settings, 'allow_label_without_completing'));
  }
);

export const isInvPackageTrackingNotInCompliance = (invPackage) => {
  const notCompliant =
    !invPackage.state_integration_tracking_id ||
    (invPackage.state_integration_tracking_id && !invPackage.state_integration_tracking_id.length) ||
    invPackage.tag_requested == 1;

  return notCompliant && invPackage.category_id != 6 && invPackage.is_waste != 1;
};

export const getLabApplyProfilesSetting = createSelector(
  [getComplianceSettings],
  (complianceSettings) => {
    return Boolean(get(complianceSettings, 'inv_lab_apply_profiles.value', false));
  }
);

export const getPrepacksInheritBulkLabResults = createSelector(
  [getComplianceSettings],
  (getComplianceSettings) => {
    return Boolean(get(getComplianceSettings, 'prepacks_inherit_bulk_lab_results.value', true));
  }
);

export const requireOnHandProductExpireDate = createSelector(
  [getInventoryComplianceSettings],
  (settings) => {
    return [1, '1', true].includes(get(settings, 'require_on_hand_product_expire_date'));
  }
);