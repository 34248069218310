import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Col, Row, Image} from 'react-bootstrap';
import {FaTimes} from 'react-icons/fa';
import {executeCallback} from '../../../../util/callbackHelpers';
import {getImageUrl} from '../../../../util/images';
import {FILE_TYPES} from '../../../../constants/fileUploads';
import FileInput from '../FileInput';
import * as itemNames from '../../../../constants/itemNames';

class ImageFieldComponent extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      uploading: false,
    };
    this.setImageField = this.setImageField.bind(this);
    this.changeUploadStatus = this.changeUploadStatus.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
  }

  setImageField(fileId, file) {
    const {name, fileName} = this.props;
    this.props.change(name, fileId);
    this.props.change(fileName, file);
  }

  changeUploadStatus(status) {
    this.setState({uploading: status});
  }

  deleteFile() {
    this.setImageField(null, null);
  }

  render() {
    const {name, fileValue, imageType, thumbnail} = this.props;
    const {uploading} = this.state;

    const hasImage = Boolean(fileValue && fileValue.url);
    const imageUrl = getImageUrl(fileValue, 'original', executeCallback(thumbnail || imageType.thumbnail));

    return (
      <Row className='image-info'>
        <Col xs={5} className='image-thumb'>
          {hasImage ?
            <div>
              <a href={imageUrl} target='_blank'>
                <Image thumbnail src={imageUrl}/>
              </a>
              <FaTimes
                className='remove-button'
                onClick={this.deleteFile}
                title={I18n.t('common.objectFile.removeFile')}
              />
            </div> : <Image thumbnail src={imageUrl}/>
          }
        </Col>
        <Col xs={7}>
          <h4>{I18n.t(imageType.name)}</h4>
          {imageType.limit ? <p>{I18n.t('common.form.sizeLimit', { limit: imageType.limit })}</p> : null}
          <p>{I18n.t('common.form.typeLimit', { extensions: FILE_TYPES[imageType.fileType].extensions })}</p>
          <Field
            name={`${name}_image_field`}
            itemName={itemNames.image}
            component={FileInput}
            props={{
              ...FILE_TYPES[imageType.fileType],
              disabled: uploading,
              btnContent: uploading ? I18n.t('products.form.uploading') : I18n.t('products.form.uploadImage'),
              onUploadStart: () => this.changeUploadStatus(true),
              onChangeCb: (image) => {
                this.setImageField(image.id, image);
                this.changeUploadStatus(false);
              },
              btnProps: {
                className: 'btn btn-default'
              },
              imageSizes: imageType.sizes
            }} />
        </Col>
      </Row>
    );
  }
}

ImageFieldComponent.propTypes = {
  name: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
  ]),
  fileValue: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  change: PropTypes.func.isRequired,
  imageType: PropTypes.shape({
    name: PropTypes.string.isRequired,
    fileType: PropTypes.string.isRequired,
    thumbnail: PropTypes.string,
    limit: PropTypes.string,
  }).isRequired,
  thumbnail: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  actions: PropTypes.shape({
    deleteItem: PropTypes.func.isRequired,
  }).isRequired,
};

export default ImageFieldComponent;
