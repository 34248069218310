import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Field, FieldArray} from 'redux-form';
import {Row, Col, Button} from 'react-bootstrap';
import {FaPlus, FaTimes} from 'react-icons/fa';
import TextInput from '../../../common/form/TextInput';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import AccordionPanel from '../../AccordionPanel';
import LevelOneLocations from './LevelOneLocations';
import StorageLocationType from './StorageLocationType';
import LocationSharing from './LocationSharing';

const text = {
  storage: {
    label: 'locations.form.buildingName',
    add: 'locations.form.addRooms'
  },
  sale: {
    label: 'locations.form.buildingName',
    add: 'locations.form.addLocations'
  }
};

const LevelZeroLocations = ({createLocation, fields, push, type, buildingTypes, defaults, showBuildingType,
                              showSquareFootage, printLabel, integratedLocationNames, showIntegratedLocationName,
                              isCanadaFacility, currentFacility, isLeaf, integrationMapping, onIsSharedChange,
                              facilitySharingStatus, onDelete, isCultivation}) => {

  return (
    <Row>
      <Col xs={12}>
        {fields.map((locationName, index) => {
          const location = fields.get(index);
          let accordeonControl = '';
          let disableLocationEdit = false;
          if(facilitySharingStatus) {
            if(!!location.is_shared && !facilitySharingStatus.is_sharing_group_master) disableLocationEdit = true;
            const disableIsSharedCheckbox = !facilitySharingStatus.is_sharing_group_master;
            const showIsSharedCheckbox = facilitySharingStatus.is_in_sharing_group && (!disableIsSharedCheckbox || location.is_shared);
            if (showIsSharedCheckbox) {
              accordeonControl = (<LocationSharing
                locationName={locationName}
                isShared={!!location.is_shared}
                onIsSharedChange={onIsSharedChange}
                isDisabled={disableIsSharedCheckbox}
              />);
            }
          }

          const title = () => {
            return !!location && location.name ? location.name : '';
          };

          return (
            <AccordionPanel title={title} key={locationName} className='location-list' rightControl={accordeonControl}>
              <Row className='form-fields-row'>
                <Col xs={1}>
                  {!disableLocationEdit &&
                    <Button
                      size='sm'
                      variant='danger'
                      className='btn-inline float-right'
                      disabled={fields.length < 2}
                      onClick={() => {
                        onDelete(fields.get(index), () => {
                          fields.remove(index);
                        });
                      }} >
                      <FaTimes/>
                    </Button>
                  }
                </Col>
                <Col className='form-fields-col' xs={8} md={4} lg={3}>
                  <Field
                    name={`${locationName}.name`} component={TextInput}
                    props={{
                      label: I18n.t(text[type].label),
                      isRequired: true,
                      readOnly: disableLocationEdit,
                    }} />
                </Col>
                <StorageLocationType name={locationName} isCanadaFacility={isCanadaFacility} type={type}/>
                <Col xs={3}>
                  {!disableLocationEdit &&
                    <Button size='sm'
                      variant='primary'
                      className='btn-inline float-left'
                      onClick={() => push(`${locationName}.child_locations`, createLocation(type, 1, location.child_locations.length, defaults))}>
                      <FaPlus/>
                    </Button>
                  }
                  <label className='btn-inline'>{I18n.t(text[type].add)}</label>
                </Col>
              </Row>
              {showBuildingType ? (
                  <Row>
                    <Col className='form-fields-col' xs={{span: 8, offset: 1}} md={{span: 4, offset: 1}} lg={{span: 3, offset: 1}}>
                      <Field
                        name={`${locationName}.location_type`} component={ReactSelectInput}
                        props={{
                          label: I18n.t('locations.form.cultivationType'),
                          options: buildingTypes,
                          textKey:'name',
                          valueKey: 'code',
                          isRequired: true,
                          placeholder: I18n.t('locations.form.cultivationTypePlaceholder')
                        }} />
                    </Col>
                  </Row>
                ) : null}
              <FieldArray
                  name={`${locationName}.child_locations`}
                  component={LevelOneLocations}
                  props={{
                    printLabel,
                    push,
                    defaults,
                    type,
                    isCultivation,
                    createLocation,
                    integratedLocationNames,
                    showIntegratedLocationName,
                    showSquareFootage,
                    isCanadaFacility,
                    currentFacility,
                    isLeaf,
                    integrationMapping,
                    disableLocationEdit,
                    onDelete
                  }}/>
            </AccordionPanel>
          );
        })}
    </Col>
  </Row>);
};

LevelZeroLocations.propTypes = {
  printLabel: PropTypes.func,
  fields: PropTypes.object.isRequired,
  type: PropTypes.oneOf(['storage', 'sale']).isRequired,
  push: PropTypes.func.isRequired,
  createLocation: PropTypes.func.isRequired,
  buildingTypes: PropTypes.array,
  defaults: PropTypes.array.isRequired,
  showBuildingType: PropTypes.bool,
  showSquareFootage: PropTypes.bool,
  integratedLocationNames: PropTypes.array,
  showIntegratedLocationName: PropTypes.bool,
  isCanadaFacility: PropTypes.bool,
  currentFacility: PropTypes.object,
  isLeaf: PropTypes.bool,
  integrationMapping: PropTypes.array,
  onIsSharedChange: PropTypes.func,
  facilitySharingStatus: PropTypes.object,
  onDelete: PropTypes.func,
  isCultivation: PropTypes.bool,
};

LevelZeroLocations.defaultProps = {
  buildingTypes: [],
  showBuildingType: true,
  showSquareFootage: true
};

export default LevelZeroLocations;
