import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Form, Button, Col, Row} from 'react-bootstrap';
import {FaMinus} from 'react-icons/fa';
import TextInput from '../../common/form/TextInput';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import PackageSearch from '../../common/scan-searches/PackageSearch';


const DestroyItemsFieldArray = (props) => {
  const {biotrackDestructionReasons, fields, useEntityLocks} = props;
  return (
    <div>
      <Row>
        {fields.length ?
          <Col xs={12}>
            <table className='inputs-table'>
              <thead>
              <tr>
                <th><Form.Label>{I18n.t('ei.processing.form.lot')}</Form.Label></th>
                <th><Form.Label>{I18n.t('ei.processing.form.location')}</Form.Label></th>
                <th><Form.Label>{I18n.t('ei.processing.form.productName')}</Form.Label></th>
                <th><Form.Label>{I18n.t('packages.form.totalQuantity')}</Form.Label></th>
                <th><Form.Label>{I18n.t('packages.form.biotrackDestroyReason')}</Form.Label></th>
                <th><Form.Label>{I18n.t('packages.form.notes')}</Form.Label></th>
                <th />
              </tr>
              </thead>
              <tbody>
              {fields.map((inputName, inputIndex) => {
                return (
                  <tr key={inputName}>
                    <td>
                      <Field name={`${inputName}.package_code`} component={TextInput} props={{
                        disabled: true,
                      }}/>
                    </td>
                    <td>
                      <Field name={`${inputName}.location_name`} component={TextInput} props={{
                        disabled: true,
                      }}/>
                    </td>
                    <td>
                      <Field name={`${inputName}.item_name`} component={TextInput} props={{
                        disabled: true,
                      }}/>
                    </td>
                    <td>
                      <Field name={`${inputName}.qty`} component={TextInput} props={{
                        disabled: true,
                        rightAddon: fields.get(inputIndex).uom,
                        groupClassName: 'transparent-addon-right',
                      }}/>
                    </td>
                    <td>
                      <Field name={`${inputName}.integration_destroy_reason_extended`} component={ReactSelectInput} props={{
                        options: biotrackDestructionReasons,
                      }}/>
                    </td>
                    <td>
                      <Field name={`${inputName}.notes`} component={TextInput}/>
                    </td>
                    <td className='remove-button'>
                      <Button variant='primary' size='sm' onClick={() => fields.remove(inputIndex)}>
                        <FaMinus/>
                      </Button>
                    </td>
                  </tr>
                );
              })}
              </tbody>
            </table>
          </Col>
          : null
        }
      </Row>
      <Row>
        <Col sm={6} md={4} lg={3}>
          <PackageSearch
            label= {I18n.t('inventory.splitPackage.form.packageID')}
            useEntityLocks={useEntityLocks}
            filterEntityLockedItems={true}
            warnEntityLockedItems={true}
            onSearchComplete={(packageId, data) => {
              const items = data && data[0] && data[0].items || [];
              const values = fields.getAll();
              items.filter(item => !values.some(value => value.id === item.id)).forEach(fields.push);
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

DestroyItemsFieldArray.propTypes = {
  biotrackDestructionReasons: PropTypes.array.isRequired,
  useEntityLocks: PropTypes.bool.isRequired,
  fields: PropTypes.shape({
    get: PropTypes.func.isRequired,
    getAll: PropTypes.func.isRequired,
    map: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    length: PropTypes.number.isRequired,
  }).isRequired,
};

export default DestroyItemsFieldArray;
