import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { connect } from 'react-redux';
import { Field, FieldArray} from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { Row, Col, Image } from 'react-bootstrap';

import PostalCodeField from '../common/form/redux-form/PostalCodeField';
import TextInput from '../common/form/TextInput';
import FileInput from '../common/form/FileInput';
import InternationalDateTimePickerInput from '../common/form/InternationalDateTimePickerInput';

import ReactSelectInput from '../common/form/ReactSelectInput';
import {getDriver} from '../../selectors/driversSelectors';
import {isMetrcDelivery} from '../../selectors/integration/metrcSelectors';
import {getImageUrl} from '../../util/images';
import PhoneNumberRow from './PhoneNumberRow';
import {getStateLabel} from '../../util/labelHelpers';
import {FILE_TYPES} from '../../constants/fileUploads';
import * as itemNames from '../../constants/itemNames';

class PersonalInfo extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      imagePreviewUrl: null,
    };

    this.getValidUsers = this.getValidUsers.bind(this);
    this.onImageUploaded = this.onImageUploaded.bind(this);
    this.onImageWillStartUploading = this.onImageWillStartUploading.bind(this);
  }

  onImageWillStartUploading() {
    this.setState({ loading: true });
  }

  onImageUploaded(image) {
    this.props.actions.onImageUploaded(image);
    this.setState({ imagePreviewUrl: getImageUrl(image), loading: false });
  }

  getValidUsers() {
    const { integrationState, users } = this.props;

    return integrationState.isWaLeaf
      ? users.filter((user) => user.state_integration_id && typeof user.state_integration_id === 'string' && user.state_integration_id.trim() !== '')
      : users;
  }

  render() {
    const { imagePreviewUrl, loading } = this.state;
    const { change, onUserChange, imageUrl, integrationState, onfleetIsEnabled, countryCode, isMetrcDelivery } = this.props;

    return (
      <Row>
        <Col sm={8} xs={8} md={8}>
          <Row>
            <Col sm={12} xs={12} md={12} className='driver-form-header'>
              <p className={integrationState.isWaLeaf ? 'no-border' : ''}>{I18n.t('cultivation.drivers.form.personalInfo')}</p>
            </Col>
          </Row>
          <Row>
            <Col sm={8} xs={8} md={5}>
              <Field
                name='user_id'
                component={ReactSelectInput}
                props={{
                  label: I18n.t('cultivation.drivers.form.selectUser'),
                  options: this.getValidUsers(),
                  textKey: 'full_name',
                  valueKey: 'id',
                  onChange: (e) => onUserChange(e, change),
                  isRequired: integrationState.isLeaf,
                }}
              />
            </Col>
          </Row>
          {(this.getValidUsers().length === 0) && (
            <Row>
              <Col md={5} sm={8} xs={8}>
                <div className='alert alert-danger' style={{padding: '5px'}}>
                  No users found with Leaf issued global ids which are required in order to create drivers.  Ensure you have approved users to proceed.
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col sm={8} xs={8} md={5}>
              <Field
                name='first_name'
                component={TextInput}
                props={{
                  label: I18n.t('cultivation.drivers.form.firstName'),
                  isRequired: true
                }}
              />
            </Col>
            <Col sm={8} xs={8} md={2}>
              <Field
                name='middle_initial'
                component={TextInput}
                props={{
                  label: I18n.t('cultivation.drivers.form.initial'),
                  maxLength: 1,
                }}
              />
            </Col>
            <Col sm={8} xs={8} md={5}>
              <Field
                name='last_name'
                component={TextInput}
                props={{
                  label: I18n.t('cultivation.drivers.form.lastName'),
                  isRequired: true
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={8} xs={8} md={3}>
              <InternationalDateTimePickerInput
                name='birth_date'
                props={{
                  label: I18n.t('cultivation.drivers.form.birthDate'),
                  isRequired: true,
                  enableTodayButton: false
                }}
              />
            </Col>
            <Col sm={8} xs={8} md={4}>
              <Field
                name='drivers_license_number'
                component={TextInput}
                props={{
                  label: I18n.t('cultivation.drivers.form.driverLicense'),
                  isRequired: true
                }}
              />
            </Col>
            <Col sm={8} xs={8} md={4}>
              <Field
                name='state_compliance_number'
                component={TextInput}
                props={{
                  label: I18n.t('cultivation.drivers.form.stateLicense'),
                  isRequired: isMetrcDelivery,
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={8} xs={8} md={6}>
              <Field
                name='street_address_1'
                component={TextInput}
                props={{
                  label: I18n.t('cultivation.drivers.form.address')
                }}
              />
            </Col>
            <Col sm={8} xs={8} md={4}>
              <Field
                name='city'
                component={TextInput}
                props={{
                  label: I18n.t('cultivation.drivers.form.city')
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={4} xs={4} md={2}>
              <Field
                name='province_code'
                component={TextInput}
                props={{
                  label: I18n.t(getStateLabel(countryCode))
                }}
              />
            </Col>
            <Col sm={4} xs={4} md={2}>
              <PostalCodeField name='postal_code'/>
            </Col>
            <Col sm={4} xs={4} md={4}>
              <FieldArray
                name='driver_phones'
                component={PhoneNumberRow}
                props={{
                  isRequired: onfleetIsEnabled || isMetrcDelivery,
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col sm={8} xs={8} md={4}>
          <Row>
            <Col sm={12} xs={12} md={12}>
              <h4>{I18n.t('cultivation.vehicles.profileImage')}</h4>
            </Col>
          </Row>
          <Row>
            <Col sm={12} xs={12} md={5}>
              <Image thumbnail src={imagePreviewUrl || imageUrl}/>
              <p>{I18n.t('common.imageUpload.description')}</p>
              <p>{I18n.t('common.imageUpload.limits')}</p>
              <Field
                name='image'
                itemName={itemNames.image}
                component={FileInput}
                props={{
                  ...FILE_TYPES['COMMON_PUBLIC_IMAGE'],
                  disabled: loading,
                  btnContent: loading ? I18n.t('common.form.uploading') : I18n.t('common.form.upload'),
                  onChangeCb: this.onImageUploaded,
                  onUploadStart: this.onImageWillStartUploading,
                  btnProps: { className: 'btn btn-default' },
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

PersonalInfo.propTypes = {
  users: PropTypes.array,
  onUserChange: PropTypes.func.isRequired,
  change: PropTypes.func,
  onImageUploaded: PropTypes.func,
  imageUrl: PropTypes.string,
  integrationState: PropTypes.object.isRequired,
  countryCode: PropTypes.string.isRequired,
  onfleetIsEnabled: PropTypes.bool,
  isMetrcDelivery: PropTypes.bool
};

export default connect(
  (state) => ({
    imageUrl: getImageUrl(get(getDriver(state), 'image_file')),
    isMetrcDelivery: isMetrcDelivery(state),
  }),
  (dispatch, { change }) => ({
    actions: {
      /**
       * Handling when user is trying to upload profile image.
       * @param image - object of already uploaded file
       */
      onImageUploaded(image) {
        change('image_file_id', image.id);
      },
    },
  })
)(PersonalInfo);
