import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Table, Card} from 'react-bootstrap';
import ContentConcealer from '../../../common/concealers/ContentConcealer';

const PlantQualityRatingDetails = ({model}) => {
  const {harvest_batch_plant} = model;
  const quality = harvest_batch_plant && harvest_batch_plant.rating_avg_total_quality;
  return (
    <Card>
      <Table striped className='plant-details'>
        <thead>
        <tr>
          <th>{I18n.t('plantHistory.field')}</th>
          <th>{I18n.t('plantHistory.value')}</th>
        </tr>
        </thead>
        <tbody>
        <ContentConcealer show={Boolean(quality)}>
          <tr>
            <td>{I18n.t('plantHistory.plant.totalQuality')}</td>
            <td>{quality === 101 ? I18n.t('plantHistory.plant.compromised') : (quality === 100) ? 'Failed' : I18n.t('plantHistory.plant.failed')}</td>
          </tr>
        </ContentConcealer>
        </tbody>
      </Table>
    </Card>
  );
};

PlantQualityRatingDetails.propTypes = {
  model: PropTypes.object.isRequired,
};

export default PlantQualityRatingDetails;
