/* eslint-disable import/prefer-default-export*/
import moment from 'moment';
import {createSelector} from 'reselect';
import {I18n} from 'react-redux-i18n';
import getSalesCompliance from './compliance/sales/getSalesCompliance';
import * as dataNames from '../constants/dataNames';
import * as itemNames from '../constants/itemNames';
import MODES from '../constants/registerReconcliliationModes';

export const getRegisterClosing = state => state[itemNames.registerClosing];
const getRegisterClosings = state => state[dataNames.registerClosings];
export const getClosingDenominations = state => state[dataNames.closingDenominations];
const getVaultRegisters = state => state[dataNames.vaultRegisters];
const getModeFromParams = (_, props) => props && props.params && props.params.mode; // this selector requires ownProps


export const getRegisterClosingSettings = createSelector(
  getSalesCompliance,
  (salesComplianceSettings) => salesComplianceSettings && salesComplianceSettings.register_closing && salesComplianceSettings.register_closing.value
);


// this selector requires ownProps
export const getRegisterClosingMode = createSelector(
  [getRegisterClosingSettings, getModeFromParams],
  (registerClosingSettings, modeFromParams) => {
    const settingsCountBy = registerClosingSettings && registerClosingSettings.count_by;
    let mode;
    if (settingsCountBy) {
      if (modeFromParams === MODES.COUNT && settingsCountBy !== 'verification') {
        mode = MODES.COUNT;
      }
      if (modeFromParams === MODES.VERIFICATION && settingsCountBy !== 'employee') {
        mode = MODES.VERIFICATION;
      }
    }
    return mode;
  }
);


// this selector requires ownProps
export const getRegisterReconciliationInitialValues = createSelector(
  [getRegisterClosing, getClosingDenominations, getRegisterClosingMode],
  (registerClosing, closingDenominations, closingMode) => {
    const currentDate = moment().format('MM/DD/YYYY');
    const employeeDenominations = (registerClosing && registerClosing.employee_denominations) || [];
    const verificationDenominations = (registerClosing && registerClosing.verification_denominations) || [];

    const countDate = (registerClosing && registerClosing.counted_at)
      ? moment(registerClosing.counted_at).format('MM/DD/YYYY')
      : (closingMode === MODES.COUNT ? currentDate : '');
    const verificationDate = (registerClosing && registerClosing.verified_at)
      ? moment(registerClosing.verified_at).format('MM/DD/YYYY')
      : (closingMode === MODES.VERIFICATION ? currentDate : '');

    const denominationCounts = closingDenominations.map(denomination => {
      const employeeRow = employeeDenominations.find(row => denomination.denomination_code === row.denomination_type);
      const verificationRow = verificationDenominations.find(row => denomination.denomination_code === row.denomination_type);
      return {
        ...denomination,
        employeeCount: employeeRow ? employeeRow.denomination_count : '',
        verifiedCount: verificationRow ? verificationRow.denomination_count : '',
      };
    });

    const {
      balance_before_closing, drop_register_id,
      counted_total, counted_drop, counted_over_short,
      reset_balance_to
    } = registerClosing;

    return {
      denominationCounts,
      drop_register_id,
      reset_balance_to,
      balance_before_closing,
      counted_total,
      counted_drop,
      counted_over_short,
      counted_at: countDate,
      verified_at: verificationDate,
    };
  }
);


// this selector requires ownProps
export const getReconciliationPageConfiguration = createSelector(
  [getRegisterClosing, getRegisterClosingSettings, getRegisterClosingMode],
  (registerClosing, registerClosingSettings, closingMode) => {
    const settingsCountBy = registerClosingSettings && registerClosingSettings.count_by;
    const alreadyVerified = Boolean(registerClosing && registerClosing.verified_at);
    const alreadyCompleted = Boolean(registerClosing && registerClosing.is_completed);
    const columns = {
      employee: {
        show: true,
        disabled: (closingMode !== MODES.COUNT || alreadyVerified || alreadyCompleted),
      },
      verification: {
        show: (settingsCountBy !== 'employee' && closingMode === MODES.VERIFICATION),
        disabled: (closingMode !== MODES.VERIFICATION || alreadyVerified),
      }
    };

    const resetAndDrop = {
      show: !alreadyCompleted,
      showStatus: alreadyCompleted,
      status: alreadyCompleted && registerClosing && I18n.t('retail.registerReconciliation.resetAndDropStatus', {amount: parseFloat(registerClosing.reset_balance_to).toFixed(2)}),
      disabled: alreadyCompleted,
    };

    const isActionsAvailable = (columns.employee.show && !columns.employee.disabled)
      || (columns.verification.show && !columns.verification.disabled);

    return {columns, resetAndDrop, isActionsAvailable};
  }
);

export const getRegisterClosingScenario = createSelector(
  [getRegisterClosing, getRegisterClosingSettings, getRegisterClosingMode],
  (registerClosing, registerClosingSettings, closingMode) => {
    if (closingMode === MODES.COUNT) return 'EmployeeCount';
    if (closingMode === MODES.VERIFICATION && !registerClosing.counted_by) return 'VerificationWithoutEmployeeCount';
    if (closingMode === MODES.VERIFICATION && registerClosing.counted_drop) return 'VerificationWithResetBalance';
    if (closingMode === MODES.VERIFICATION && !registerClosing.counted_drop) return 'VerificationWithoutResetBalance';
  }
);


export const getDropRegisters = createSelector(
  [getRegisterClosing, getVaultRegisters],
  (registerClosing, vaultRegisters) => {
    const filterByCollectsFrom = (register) => Array.isArray(register.collects_from) && register.collects_from.some(row => registerClosing && registerClosing.register_id === row.collects_from_register_id);
    return vaultRegisters
      .filter(filterByCollectsFrom)
      .map(r => ({
        value: r.id,
        text: r.name,
      }));
  }
);

export const getRegisterClosingRowsForActivityReport = createSelector(
  [getRegisterClosings],
  (registerClosings) => {
    const rows = registerClosings.map(registerClosing => {
      return {
        ...registerClosing,
        drop: registerClosing.verified_drop || registerClosing.counted_drop,
        overShort: registerClosing.verified_over_short || registerClosing.counted_over_short,
        openTime: registerClosing.opening_time ? registerClosing.opening_time : '',
        closeTime: registerClosing.closed_at ? registerClosing.closed_at : ''
      };
    });

    return rows;
  }
);
