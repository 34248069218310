import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import {I18n} from 'react-redux-i18n';

import CustomerFilterPanel from './CustomerFilterPanel';
import CustomerList from './CustomerList';

const CheckinCustomerForm = ({handleSubmit,  consumers,
  pristine, submitting, reset, resetList, ready, customerStats,
   customerGroups, filtered, timezone, customerRecentHistories,
  showScanModal, onSelectCustomer, customerDetails, budTenders,  isPALeafIntegrator, selectedCustomers, countryCode
}) => {
  return (<div>
    <form onSubmit={handleSubmit}>
      <CustomerFilterPanel pristine={pristine} submitting={submitting}
        showScanModal={showScanModal}
        isPALeafIntegrator={isPALeafIntegrator}
        reset={() => {
          reset();
          resetList();
        }
      }/>
    </form>
    {
      filtered
      ? consumers.length
        ?
        <CustomerList
          consumers={consumers}
          budTenders = {budTenders}
          ready={ready}
          timezone={timezone}
          customerGroups={customerGroups}
          onSelectCustomer={onSelectCustomer}
          selectedCustomers={selectedCustomers}
          countryCode={countryCode}
          customerDetails={customerDetails}/>
        : <span>{I18n.t('customers.checkin.noResultsFound')}</span>
      :  null
    }
  </div>);
};

CheckinCustomerForm.propTypes = {
  consumers: PropTypes.array,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  resetList: PropTypes.func.isRequired,
  ready: PropTypes.bool.isRequired,
  customerGroups: PropTypes.array,
  filtered: PropTypes.bool.isRequired,
  timezone: PropTypes.string.isRequired,
  showScanModal: PropTypes.func.isRequired,
  onSelectCustomer: PropTypes.func.isRequired,
  customerDetails: PropTypes.object,
  budTenders: PropTypes.array,
  customerStats: PropTypes.array,
  customerRecentHistories: PropTypes.array,
  selectedCustomers: PropTypes.array.isRequired,
  isPALeafIntegrator: PropTypes.bool.isRequired,
  countryCode: PropTypes.string.isRequired,
};

export default reduxForm({
  enableReinitialize: true,
})(CheckinCustomerForm);
