import React from 'react';
import PropTypes from 'prop-types';

import SampleImage from './SampleImage';
import PrinterQueue from './PrinterQueue';

const SampleImages = (props) => {

  // In parent component, state.isMultiple are set to correct value right after label(s) loading,
  // but component rendering with new data can be performed earlier.
  // That's why we need this additional check:
  const isMultiple = props.isMultiple || (props.label && props.label.is_multiple);

  const labels = (props.label.labels === undefined)
    ? [props.label]
    : props.label.labels.map( (nestedLabel) => {
      const label = nestedLabel.label;
      label.success = nestedLabel.success;
      label.tag = nestedLabel.tag;
      return label;
    });

  const columnClasses = ['col-md-12', 'col-md-6', 'col-md-4', 'col-md-3'];
  const columnClass = columnClasses[labels.length - 1];

  const width = (props.label.labels === undefined)
    ? (props.label.width * 150 > 1100)
      ? 1100
      : (props.label.width * 150)
    : 0;

  return (
    <div className='row'>
      {
        (isMultiple && labels.length === 0)
          ? (<div className='col-md-12'>
          <div style={{padding: '10px'}} className='alert alert-danger'>
            All Labels For This Label Type Are Disabled.  Turn on at least one label in Manage Labels / Configure.
          </div>
        </div>) : null
      }
      {
      isMultiple
        ? labels.map( (label, index) => {
          return (<SampleImage
            setTagInState={props.setTagInState}
            selectedTag={props.selectedTag}
            key={index}
            columnClass={columnClass}
            label={label}
            width={width}
            labelCount={labels.length}
            defaultLabelType={props.defaultLabelType}
            showLabelDetails={props.showLabelDetails}
            onToggleShowLabelDetails={props.onToggleShowLabelDetails}
            selectedLabel={props.selectedLabel}
          />);
        })
          : props.loadQ.length < 2 && !props.forceLabelBlocks
            ? labels.map( (label, index) => {
              return (<SampleImage
                setTagInState={props.setTagInState}
                selectedTag={props.selectedTag}
                key={index}
                columnClass={columnClass}
                label={label}
                labelCount={labels.length}
                defaultLabelType={props.defaultLabelType}
                showLabelDetails={props.showLabelDetails}
                onToggleShowLabelDetails={props.onToggleShowLabelDetails}
                selectedLabel={props.selectedLabel}
              />);
            })
            : <PrinterQueue
                setTagInState={props.setTagInState}
                selectedTag={props.selectedTag}
                printSingleInBlock={props.printSingleInBlock}
                printFromInBlock={props.printFromInBlock}
                setViewPrintQIndex={props.setViewPrintQIndex}
                viewPrintQIndex={props.viewPrintQIndex}
                onLoadBlock={props.onLoadBlock}
                currentPrinter={props.currentPrinter}
                printBlock={props.printBlock}
                loadQ={props.loadQ}
                namesQ={props.namesQ}
                zplQ={props.zplQ}
                printQ={props.printQ}
                currentTab={props.currentTab}
                onChangeTab={props.onChangeTab}
                label={props.label}
              />
      }
    </div>
  );

};

SampleImages.propTypes = {
  namesQ: PropTypes.array.isRequired,
  forceLabelBlocks: PropTypes.bool.isRequired,
  printSingleInBlock: PropTypes.func.isRequired,
  printFromInBlock: PropTypes.func.isRequired,
  setViewPrintQIndex: PropTypes.func.isRequired,
  viewPrintQIndex: PropTypes.number.isRequired,
  zplQ: PropTypes.array.isRequired,
  loadQ: PropTypes.array.isRequired,
  onLoadBlock: PropTypes.func.isRequired,
  currentPrinter: PropTypes.string,
  printBlock: PropTypes.func.isRequired,
  printQ: PropTypes.array.isRequired,
  currentTab: PropTypes.string.isRequired,
  onChangeTab: PropTypes.func.isRequired,
  isMultiple: PropTypes.bool.isRequired,
  label: PropTypes.object.isRequired,
  setTagInState: PropTypes.func.isRequired,
  selectedTag: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]).isRequired,
  defaultLabelType: PropTypes.string.isRequired,
  showLabelDetails: PropTypes.bool.isRequired,
  onToggleShowLabelDetails: PropTypes.func.isRequired,
  selectedLabel: PropTypes.object,
};

export default SampleImages;
