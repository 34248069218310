import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Online, Offline} from 'react-detect-offline';
import {FaCheckCircle, FaTimesCircle} from 'react-icons/fa';
import * as apiActions from '../../actions/apiActions';

class ApplicationFooter extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
  }
  render() {
    const {version} = this.props;

    return (
      <div className='application-footer'>
        {version}
        <div className='system-status'>
          <Online>
            <FaCheckCircle />
            Online
          </Online>
          <Offline>
            <FaTimesCircle />
            Offline
          </Offline>
        </div>
      </div>
    );
  }
}

ApplicationFooter.propTypes = {
  version: PropTypes.string,
};

function mapStateToProps(state) {
  const version = state.system ? state.system.initialVersion : '';
  return {version};
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions);
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationFooter);
