import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { Image } from 'react-bootstrap';
import round from 'lodash.round';
import CouponsRow from './CouponsRow';
import * as p from '../../constants/permissions';
import PermissionWrapper from '../common/PermissionWrapper';
import InternationalCurrencyStatic from '../common/form/InternationalCurrencyStatic';

const PaymentItem = ({
  description,
  printLabel,
  pricingWeight,
  prepackWeights,
  id,
  coupons,
  packageId,
  order,
  unit_price,
  quantity,
  lineTotal,
  item,
  src,
  orderProductId,
  paymentComplete,
  printBeforePayment
}) => {
  const getMasterPriceWeight = function() {
    if (order && order.length) {
      const pack = item && item.items && item.items.length > 0 && item.items[0];

      const prepackWeight = (prepackWeights && prepackWeights.length
        && prepackWeights.find(pw => pw.id === pack.prepack_weight_id));
      return (prepackWeight && prepackWeight.name) || pack.uom;
    }
  };


  const getMasterQuantityDisplay = function() {
    if (order && order.length) {
      if (!order[0].sold_weight_base) {
        return item.quantity;
      }
    }
  };

  const masterPricingWeightDisplay = getMasterPriceWeight();
  const masterQuantityDisplay = getMasterQuantityDisplay();
  const hasCoupons = coupons && coupons.length ? true : false;
  return (
    <tbody>
      <tr>
        <td>
          <Image className='cart-item-image' src={src} />
        </td>
        <td>{description}</td>
        <td></td>
        <td>{masterPricingWeightDisplay}</td>
        <td className='quantity'>{masterQuantityDisplay}</td>
        <td><InternationalCurrencyStatic>{hasCoupons ? <del>{round(lineTotal, 2)}</del> : round(lineTotal, 2)}</InternationalCurrencyStatic></td>
        <td>
          <button
            style={{ display: 'none' }}
            disabled={!paymentComplete && !printBeforePayment}
            className='btn btn-link'
            value={id}
            onClick={(event) => printLabel(event, orderProductId)}
          >
            {I18n.t('cart.items.printLabel')}
          </button>
        </td>
      </tr>
      {item && id == item.item_master_id && item.items && item.items.length
        ? item.items.map((item, index) => {
          const prepackWeight = prepackWeights.filter((prepackWeight) => prepackWeight.id === item.prepack_weight_id);
          return (
              <tr key={index}>
                <td></td>
                <td></td>
                <td>{item.package_code}</td>
                <td>{prepackWeight && prepackWeight.length ? prepackWeight[0].name : null}</td>
                <td className='quantity'>{item.qty}</td>
                <td></td>
                <td>
                  <PermissionWrapper permissions={[p.print_labels]}>
                    <button
                      disabled={!paymentComplete && !printBeforePayment}
                      className='btn btn-link'
                      value={id}
                      onClick={(event) => printLabel(event, orderProductId, item.item_id)}
                    >
                      {I18n.t('cart.items.printLabel')}
                    </button>
                  </PermissionWrapper>
                </td>
              </tr>
            );
        })
        : null}
      {hasCoupons ? <CouponsRow coupons={coupons} product={item} offsetColumns={3} /> : null}
    </tbody>
  );
};
PaymentItem.propTypes = {
  paymentComplete: PropTypes.bool.isRequired,
  orderProductId: PropTypes.number,
  description: PropTypes.string.isRequired,
  pricingWeight: PropTypes.object,
  id: PropTypes.number.isRequired,
  packageId: PropTypes.string,
  quantity: PropTypes.number.isRequired,
  lineTotal: PropTypes.number.isRequired,
  unit_price: PropTypes.number.isRequired,
  order: PropTypes.array.isRequired,
  prepackWeights: PropTypes.array.isRequired,
  item: PropTypes.object.isRequired,
  printLabel: PropTypes.func,
  src: PropTypes.string.isRequired,
  coupons: PropTypes.array.isRequired,
  printBeforePayment: PropTypes.bool.isRequired
};

PaymentItem.defaultProps = {
  pricingWeight: { name: null }
};

export default PaymentItem;
