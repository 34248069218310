import React from 'react';
import PropTypes from 'prop-types';
import {DropdownButton, Dropdown} from 'react-bootstrap';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {saveAs} from 'file-saver';
import Papa from 'papaparse';
import * as itemNames from '../../../constants/itemNames';
import FileInput from '../../common/form/FileInput';
import {FILE_TYPES} from '../../../constants/fileUploads';
import WillRender from '../../common/concealers/WillRender';
import {error} from '../../../constants/messageTypes';
import * as dataNames from '../../../constants/dataNames';

const ExcelJS = require('exceljs/dist/exceljs');

class AdjustTemplate extends React.PureComponent {
  constructor(props) {
    super(props);

    this.processTemplate = this.processTemplate.bind(this);
  }

  downloadTemplate(format) {
    const fileName = 'Inventory Adjustment Template';

    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'MJ Freeway';
    workbook.lastModifiedBy = 'MJ Freeway';
    workbook.created = new Date();
    workbook.modified = workbook.created;
    const worksheet = workbook.addWorksheet(fileName);
    worksheet.columns = [
      { header: 'Package code', key: 'package_code', width: 20 },
      { header: 'Product category', key: 'category', width: 20 },
      { header: 'Product name', key: 'product_name', width: 32 },
      { header: 'Location', key: 'storage_location', width: 40 },
      { header: 'Physical Count', key: 'physical_count', width: 8 },
      { header: 'Notes', key: 'notes', width: 8 }
    ];
    worksheet.getRow(1).font = { bold: true };
    worksheet.autoFilter = 'A1:F1';
    worksheet.addRow({package_code: 'PKG00001', category: 'Flower', product_name: 'El Jefe', storage_location: 'Section 1', physical_count: 10, notes: 'Some notes'});

    if (format === 'excel') {
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/xlsx' });
        saveAs(blob, `${fileName.replace(/ /g,'_')}.xlsx`);
      });
    }
    if (format === 'csv') {
      workbook.csv.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'text/csv' });
        saveAs(blob, `${fileName.replace(/ /g,'_')}.csv`);
      });
    }
  }

  processTemplate(file) {

    const processWorksheet = (worksheet) => {

      const toSnakeCase = (header) => {
        return header.trim()
          .toLowerCase()
          .replace(/\s+/g, '_');
      }

      const getPackageCodes = (worksheet) => {
        const packageCodesSet = new Set();
        worksheet.eachRow((row, rowNumber) => {
          if (rowNumber > 1) {
            const packageCode = row.values[1];
            if (packageCode) {
              packageCodesSet.add(packageCode);
            }
          }
        });
        return Array.from(packageCodesSet);
      }

      // check header columns for required fields
      const headerRow = worksheet.getRow(1);

      // Required columns
      const requiredColumns = ['package_code', 'location', 'physical_count'];

      // Actual columns
      const actualHeaders = headerRow.values
        .map(header => (header || '').toString())
        .map(toSnakeCase);

      // Compare with required columns
      const missingColumns = requiredColumns.filter(
        requiredCol => !actualHeaders.includes(requiredCol)
      );

      // If missing required columns, show error message
      if (missingColumns.length > 0) {
        this.props.actions.addMessage(error, ['reconciliation.adjustTemplate.error.missingRequiredColumns']);
        return;
      }

      // Function to transform worksheet rows into an array of objects
      const convertWorksheetToObjects = (worksheet) => {
        const rows = [];
        let header = [];
        worksheet.eachRow((row, rowNumber) => {
          if (rowNumber === 1) {
            // Assuming the first row is the header
            header = row.values.slice(1).map(toSnakeCase);
          } else {
            const rowData = {};
            row.values.forEach((cell, index) => {
              const columnName = header[index - 1]; // Adjust because row.values is 1-based
              if (columnName) {
                rowData[columnName.toLowerCase()] = cell; // Use the header name as the key
              }
            });
            rows.push(rowData);
          }
        });
        return rows;
      };

      // Get package codes from the worksheet
      const package_codes = getPackageCodes(worksheet);
      this.props.actions.setItem(0, itemNames.activeTabEventKey);
      // Get inventory items from solr
      const payload = { sort: 'name asc', query: 'matchall', size: 10000, start: 0, filter: 'active:1 AND package_code: (' + package_codes.join(' ') + ')' };
      // Resets start and page to 0 which are ignored by table and it uses its internal counters
      this.props.actions.getDataByPost('/api/search/inventory', payload, null)
        .then((itemsFromSolr) => {
          const itemsFromSolrWithDiscrepancy = itemsFromSolr.map((item) => {
            const dataFromWorksheet = convertWorksheetToObjects(worksheet);

            // Find the relevant worksheet entries that match by package code and location
            const matchingEntries = dataFromWorksheet && dataFromWorksheet.filter((row) => row.package_code === item.package_code && row.location === item.location_name);

            // Sort the matching entries to find the most recent one based on the Scanned Date/Time
            const latestEntry = matchingEntries.sort((a, b) => new Date(b['scanned_date/time']) - new Date(a['scanned_date/time']))[0];

            // If the item is not found in the worksheet, set discrepancy to 0
            if (latestEntry) {
              item.category_id = 0;
              item.discrepancy = latestEntry.physical_count - item.qty || 0;
              item.physical_count = latestEntry.physical_count || 0;
              item.notes = latestEntry.notes || '';
            }
            return item;
          });

          this.props.actions.setData(itemsFromSolrWithDiscrepancy, dataNames.inventoryItems);

          const ids = itemsFromSolr.map((item) => item.item_id);
          const params = {
            in_item_ids: ids
          };
          // Back fills solr results with reservations data since we removed reservations from the solr query.
          this.props.actions.getUnpaginatedData('/api/item_reservations', dataNames.reservations, null, params);
        });
    }


    // Create a new workbook
    const workbook = new ExcelJS.Workbook();

    if (file.name.toLowerCase().endsWith('.csv')) {
      try {
        Papa.parse(file, {
          header: false,
          skipEmptyLines: true,
          complete: (results) => {
            const ws = workbook.addWorksheet(file.name.replace(/\.[^/.]+$/, '')); // filename minus extension
            ws.addRows(results.data);
            processWorksheet(ws);
          },
        });
      }
      catch (e) {
        this.props.actions.addMessage(error, ['reconciliation.adjustTemplate.error.invalidFile']);
      }
    }

    if (file.name.toLowerCase().endsWith('.xlsx')) {
      try {
        const buffer = file.arrayBuffer();
        workbook.xlsx.load(buffer)
          .then(() => {
            processWorksheet(workbook.getWorksheet(1));
          });
      }
      catch (e) {
        this.props.actions.addMessage(error, ['reconciliation.adjustTemplate.error.invalidFile']);
      }
    }
  }

  render() {
    const {show, downloadOnly} = this.props;

    return (
      <WillRender ifTrue={show}>
        <div style={{display: 'flex', alignItems: 'center', gap: '12px'}}>
          <div className='form-input float-right form-group'>
            {/* Template download */}
            <label>{I18n.t('reconciliation.adjustTemplate.downloadTemplate')}</label>
            <br/>
            <DropdownButton
              style={{marginBottom: '12px'}}
              variant='primary'
              type='button'
              title={I18n.t('reconciliation.adjustTemplate.downloadTemplate')}
            >
              <Dropdown.Item id={'Excel'} key={'excel'} eventKey={'excel'}
                             onClick={() => this.downloadTemplate('excel')}>{I18n.t('reconciliation.adjustTemplate.excel')}</Dropdown.Item>
              <Dropdown.Item id={'CSV'} key={'csv'} eventKey={'csv'}
                             onClick={() => this.downloadTemplate('csv')}>{I18n.t('reconciliation.adjustTemplate.csv')}</Dropdown.Item>
            </DropdownButton>
          </div>

          {/* Template upload */}
          <WillRender ifTrue={!downloadOnly}>
            <Field
              name='adjust_import'
              itemName={itemNames.adjustTemplate}
              component={FileInput}
              props={{
                ...FILE_TYPES['ADJUST_IMPORT_FILE'],
                skipupload: 1,
                label: I18n.t('reconciliation.adjustTemplate.upload'),
                btnContent: I18n.t('reconciliation.adjustTemplate.upload'),
                onChangeCb: (f) => {
                  this.props.changeTab(0);
                  this.processTemplate(f);
                },
                btnProps: {
                  className: 'btn btn-primary'
                }
              }}
            />
          </WillRender>
        </div>
      </WillRender>
  );}
}

AdjustTemplate.propTypes = {
  getFormValue: PropTypes.func,
  change: PropTypes.func,
  show: PropTypes.bool
};

export default AdjustTemplate;
