import {push, replace} from 'react-router-redux';
import {UserAuthWrapper} from 'redux-auth-wrapper';
import get from 'lodash.get';
import {OR} from './predicates';
import {userHasPermission} from '../selectors/usersSelectors';
import {getActiveFacility, getFacilityHasModule} from '../selectors/facilitiesSelectors';
import LoadingPage from '../components/common/LoadingPage';
import * as f from '../constants/facilityTypes';
import * as m from '../constants/moduleKeys';
import {isFeatureEnabled} from '../selectors/featureToggles';
import {getEnableDeliveryWorkflow, getEnableManifestGeneration} from '../selectors/forms/homeDeliverySetupFormSelectors';

export const UserIsAuthorized = UserAuthWrapper({
  authSelector: (state, ownProps) => ({hasPermission: userHasPermission(state, ownProps.routes[ownProps.routes.length - 1])}),
  wrapperDisplayName: 'UserIsAuthorized',
  allowRedirectBack: false,
  failureRedirectPath: '/nopermission',
  redirectAction: push,
  predicate: ({hasPermission}) => hasPermission
});

export const UserIsAuthenticated = UserAuthWrapper({
  authSelector: state => state.user,
  authenticatingSelector: state => state.user.authenticating,
  LoadingComponent: LoadingPage,
  redirectAction: push,
  allowRedirectBack: false,
  wrapperDisplayName: 'UserIsAuthenticated',
  predicate: ({authenticated}) => authenticated
});

export const FacilityHasAccess = (facilityTypes) => UserAuthWrapper({
  authSelector: state => getActiveFacility(state),
  wrapperDisplayName: 'FacilityHasAccess',
  allowRedirectBack: false,
  redirectAction: replace,
  failureRedirectPath: '/no-facility-permission',
  predicate: facility => facility && facilityTypes.indexOf(facility.type) > -1,
});
//const isColombiaComplianceToggled = isFeatureEnabled(state)('feature_colombia_compliance');


// export const AccessByPredicate = (selector, predicate, altpath) => UserAuthWrapper({
//   authSelector: (state, props) => selector(state, props),
//   wrapperDisplayName: 'AccessByPredicate',
//   allowRedirectBack: false,
//   redirectAction: replace,
//   failureRedirectPath: altpath || '/no-facility-permission',
//   predicate: result => predicate(result)
// });

export const AccessByPredicate = (selector, predicate, alt) => {
  if(alt && typeof alt === 'string'){
    return UserAuthWrapper({
      authSelector: (state, props) => selector(state, props),
      wrapperDisplayName: 'AccessByPredicate',
      allowRedirectBack: false,
      redirectAction: replace,
      failureRedirectPath: alt || '/no-facility-permission',
      predicate: result => predicate(result)
    });
  }else{
    return UserAuthWrapper({
      authSelector: (state, props) => selector(state, props),
      wrapperDisplayName: 'AccessByPredicate',
      failureComponent: alt,
      predicate: result => predicate(result)
    });
  }

};

/**
 *
 * @param {Array} [modules]
 * @param {Array} [facilities]
 * @param {Array} [integrators]
 * @param {string} [modulesPredicate]
 * @param {string} [facilitiesPredicate]
 * @param {string} [integratorsPredicate]
 * @returns {{modules: Array, facilities: Array, integrators: Array, modulesPredicate: string, facilitiesPredicate: string, integratorsPredicate: string}}
 */
export const buildRouteAccessibilityObject = (modules, facilities, integrators,  modulesPredicate = OR, facilitiesPredicate = OR, integratorsPredicate = OR) => {
  return {
    modules: modules,
    facilities: facilities,
    integrators,
    modulesPredicate: modulesPredicate,
    facilitiesPredicate: facilitiesPredicate,
    integratorsPredicate,
  };
};

/**
 * @param {Array} enabledModules
 * @param {Object} currentFacility
 * @returns Boolean
 */
export const evaluateAccessibilityForIngredientsPage = (enabledModules, currentFacility) => {
  if (currentFacility.type === f.RETAIL) {
    return true;
  }

  return enabledModules.some(module => [m.CULTIVATION, m.EXTRACTION, m.INFUSION].indexOf(module) > -1);
};

export const AccessByFeatureEnabled = (feature_name, failed_redirect = '/no-facility-permission') => UserAuthWrapper({
  authSelector: (state) => { return {access_granted : isFeatureEnabled(state)(feature_name)};} ,
  wrapperDisplayName: 'AccessByFeatureEnabled',
  allowRedirectBack: true,
  redirectAction: replace,
  failureRedirectPath: failed_redirect,
  predicate: (auth_data) => auth_data.access_granted
});

/*
 * @param {string} feature_name
 * @param {object} failure_component The component to fallback to when the toggle is disabled
 * @param {string} failed_redirect
 */
export const AccessByFeatureEnabledWithFailureComponent = (feature_name, failure_component, failed_redirect = '/no-facility-permission') => UserAuthWrapper({
  authSelector: (state) => {
    const url = new URL(window.location);
    const featureToggleValueInUrl = url.searchParams.get(feature_name);
    const isFeatureEnabledInUrl = parseInt(featureToggleValueInUrl) === 1;
    const access_granted = featureToggleValueInUrl !== null ? isFeatureEnabledInUrl : isFeatureEnabled(state)(feature_name);
    return {
      access_granted
    };
    // @TODO: Revert to the following code once patients are sorted.
    // return {
    //   access_granted: isFeatureEnabled(state)(feature_name)
    // };
  },
  wrapperDisplayName: 'AccessByFeatureEnabledWithFailureComponent',
  allowRedirectBack: true,
  redirectAction: replace,
  failureRedirectPath: failed_redirect,
  predicate: (auth_data) => auth_data.access_granted,
  FailureComponent: failure_component,
});

export const AccessByFeatureAndModuleEnabled = (feature_name, module_name, failed_redirect = '/no-facility-permission') => UserAuthWrapper({
  authSelector: (state) => { return {access_granted : isFeatureEnabled(state)(feature_name) && getFacilityHasModule(state)(module_name)};},
  wrapperDisplayName: 'AccessByFeatureAndModuleEnabled',
  allowRedirectBack: true,
  redirectAction: replace,
  failureRedirectPath: failed_redirect,
  predicate: (auth_data) => auth_data.access_granted
});

export const preventDirectLinkAccess = UserAuthWrapper({
  authSelector: state => get(state, 'routing.locationBeforeTransitions.action'),
  redirectAction: push,
  allowRedirectBack: false,
  predicate: (action) => action === 'PUSH'
});

export const HomeDeliveryWorkflowEnabled = AccessByPredicate(
  state => ({
    is_feature_enabled: isFeatureEnabled(state)('feature_courier_licensees'),
    facility_has_module: getFacilityHasModule(state)(m.HOME_DELIVERY),
    is_workflow_enabled: getEnableDeliveryWorkflow(state)
  }),
  result => {
    return result.is_feature_enabled
      && result.facility_has_module
      && result.is_workflow_enabled;
  },
  '/no-facility-permission'
);

export const HomeDeliveryManifestGenerationEnabled = AccessByPredicate(
  state => getEnableManifestGeneration(state),
  manifestGenerationEnabled => manifestGenerationEnabled,
  '/no-facility-permission'
);

