/* eslint-disable import/prefer-default-export*/
import {createSelector} from 'reselect';
import find from 'lodash.find';
import {getCategories} from './categorySelectors';
import * as dataNames from '../constants/dataNames';

const getSelectedGroup = state => state.selectedPricingGroupData;
const getCustomerGroup = state => state.selectedCustomerGroupData;
export const getPricingGroups = state => state[dataNames.pricingGroups];

export const getSelectedGroupsData = createSelector(getSelectedGroup, selectedGroups => selectedGroups.map(selectedGroup => selectedGroup.id));
export const getSelectedCustomerGroupData = createSelector(getCustomerGroup, selectedCustomerGroups => selectedCustomerGroups.map(selectedCustomerGroup => selectedCustomerGroup.id));

export const getPricingGroupsListingData = createSelector(
  [getPricingGroups, getCategories],
  (pricingGroups, categories) => pricingGroups.map(pricingGroup => {
    const category = find(categories, {id: pricingGroup.category_id});
    return {...pricingGroup, category: category && category.name};
  })
);
