import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Row, Col, Button} from 'react-bootstrap';
import {Field} from 'redux-form';
import AccordionPanel from '../../../common/AccordionPanel';
import SubmitSection from '../../../common/form/SubmitSection';
import DatePickerInput from '../../../common/form/DatePickerInput';
import TextInput from '../../../common/form/TextInput';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import {PA_MED_CAREGIVER, PA_MED_PATIENT} from '../../../../constants/idTypes';

const options = [
  {text: 'Caregiver', value: PA_MED_CAREGIVER},
  {text: 'Patient', value: PA_MED_PATIENT},
];

const CheckinLeafPAForm = (props) => {
  const {showScanModal, invalid, pristine, submitting, reset, handleSubmit} = props;
  return (
    <form onSubmit={handleSubmit} noValidate={true}>
      <AccordionPanel title='consumer.checkin.filter'>
        <Row>
          <Col xs={12}>
            <Button type='button' variant='success' onClick={showScanModal}>
              {I18n.t('customers.create.scanLicense')}
            </Button>
          </Col>
          <Col xs={12}><hr/></Col>
        </Row>
        <Row>
          <Col xs={6} sm={4} md={3}>
            <Field name='license_type' component={ReactSelectInput} props={{
              options,
              isRequired: true,
              label: I18n.t('consumer.checkin.licenseType'),
            }}/>
          </Col>
          <Col xs={6} sm={4} md={3}>
            <Field name='dob' component={DatePickerInput} props={{
              label: I18n.t('consumer.checkin.dateOfBirth'),
            }}/>
          </Col>
          <Col xs={6} sm={4} md={3}>
            <Field name='registry_id' component={TextInput} props={{
              label: I18n.t('consumer.checkin.registryId'),
              isRequired: true,
            }}/>
          </Col>
          <Col xs={6} sm={4} md={3}>
            <Field name='card_number' component={TextInput} props={{
              label: I18n.t('consumer.checkin.cardNumber'),
              isRequired: true,
            }}/>
          </Col>
        </Row>
        <SubmitSection settings={{actionSettings: {
          reset: {
            pristine,
            submitting,
            action: reset,
            type: 'reset',
            text: I18n.t('consumer.checkin.reset')
          },
          submit: {
            invalid,
            pristine,
            submitting,
            type: 'submit',
            style: 'primary',
            text: I18n.t('consumer.checkin.search')
          }
        }
        }}/>
      </AccordionPanel>
    </form>
  );
};

CheckinLeafPAForm.propTypes = {
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  reset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  showScanModal: PropTypes.func.isRequired,
};

export default CheckinLeafPAForm;
