import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import InternationalDisplayQty from '../../../../common/InternationalDisplayQty';

const MetrcLimitsRow = ({areConsumerLimitsLoading, getDisplayRulesPropertySum, consumer, getStyle, customerLimits, multiViews}) => (
  <tfoot>
  <tr>
    <th colSpan={multiViews ? 2 : 1} style={{color: '#337ab7'}}>{I18n.t('cart.limitTable.integrationLimit')}</th>
    {!multiViews && <th style={{color: '#337ab7'}}>{I18n.t('cart.limitTable.inOrder')}</th>}
  </tr>
  <tr>
    <td>
        {areConsumerLimitsLoading() ? '...' : <InternationalDisplayQty qty={get(customerLimits, 'limits.total_weight_integrator_restriction', 0)} uom='GR' displayUom={true}/>}
    </td>
    {
      consumer || multiViews
        ? null
        : <td style={getStyle()}><InternationalDisplayQty qty={getDisplayRulesPropertySum('order', true)} uom='GR' displayUom={true}/></td>
    }
  </tr>
  </tfoot>

);

MetrcLimitsRow.propTypes = {
  areConsumerLimitsLoading: PropTypes.func.isRequired,
  getDisplayRulesPropertySum: PropTypes.func.isRequired,
  consumer: PropTypes.object,
  getStyle: PropTypes.func.isRequired,
  customerLimits: PropTypes.object.isRequired,
  multiViews: PropTypes.bool
};

export default MetrcLimitsRow;