import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import get from 'lodash.get';
import * as dataNames from '../../../constants/dataNames';
import {getItem, postData, getUnpaginatedData} from '../../../actions/apiActions';
import {getActivateInventoryPayload} from '../../../selectors/inventoryItemsSelectors';
import {getActivateInitialValues, getActivationItemOptions} from '../../../selectors/finishedProductsSelectors';

import ActivateInventoryFormWrapper from './ActivateInventoryFormWrapper';
import {getIntegrationAdjustmentReasonOptions} from '../../../selectors/integration/adjustmentReasonsSelectors';
import {getIntegrationState} from '../../../selectors/integration/integrationSelectors';
import {addMessage} from '../../../actions/systemActions';
import * as messageTypes from '../../../constants/messageTypes';
import {unsetData} from '../../../actions/dataActions';

class ActivateInventoryModal extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleActivationErrors = this.handleActivationErrors.bind(this);
    this.availableForActivation = this.availableForActivation.bind(this);
  }

  componentWillMount() {
    const {getUnpaginatedData} = this.props.actions;
    getUnpaginatedData(
      '/api/integration/adjustment_reasons',
      dataNames.integrationAdjustmentReasons,
      {failed: 'packages.modify.getIntegrationAdjustmentReasons.failed'}
    );
    if (this.props.integrationState.isWaLeaf) {
      getUnpaginatedData('/api/users/current_facility', dataNames.users);
    }
  }

  componentWillUnmount() {
    this.props.actions.unsetData(dataNames.inventoryItems);
  }

  handleActivationErrors(errors){
    const failed_items = get(errors, 'response.data.errors.failed_items');
    if(Array.isArray(failed_items)){
      failed_items.forEach((failed_item) => {
        const failed_item_errors = failed_item.errors;
        Object.keys(failed_item_errors).forEach(key => {
          if(Array.isArray(failed_item_errors[key])){
            failed_item_errors[key].forEach(error => this.props.actions.addMessage(messageTypes.error, error, true));
          }
        });
      });
    } else{
      this.props.actions.addMessage(messageTypes.error, 'packages.modify.failed');
    }
  }

  availableForActivation() {
    const {integrationState: {isBiotrack}, itemOptions} = this.props;
    if (isBiotrack && itemOptions.filter(item => item.is_test_package).length) {
      this.props.actions.addMessage(messageTypes.error, 'packages.modify.isTestSampleError');
      return false;
    }
    return true;
  }

  onSubmit(formData) {
    if (!this.availableForActivation()) return false;
    return this.props.actions.postData(
      '/api/items/multi_actions',
      getActivateInventoryPayload(formData, this.props.timezone),
      dataNames.inventoryItems,
      {
        success: 'packages.modify.success',
        failedHandler: this.handleActivationErrors
      },
      {},
      this.props.onActivateInventory
    );
  }

  render() {
    const {locations, integrationAdjustmentReasons, initialValues, itemOptions, integrationState, facilityUsers} = this.props;
    return (
      <ActivateInventoryFormWrapper
        initialValues={initialValues}
        onSubmit={this.onSubmit}
        storageLocations={locations}
        itemOptions={itemOptions}
        integrationAdjustmentReasons={integrationAdjustmentReasons}
        integrationState={integrationState}
        facilityUsers = {facilityUsers}
      />
    );
  }
}

ActivateInventoryModal.propTypes = {
  actions: PropTypes.shape({
    getLocations: PropTypes.func,
    getItem: PropTypes.func,
    getUnpaginatedData: PropTypes.func,
    postData: PropTypes.func,
    unsetData: PropTypes.func,
  }),
  initialValues: PropTypes.object,
  locations: PropTypes.array.isRequired,
  itemOptions: PropTypes.array.isRequired,
  onActivateInventory: PropTypes.func.isRequired,
  integrationState: PropTypes.object.isRequired,
  integrationAdjustmentReasons: PropTypes.array.isRequired,
  timezone: PropTypes.string,
  facilityUsers: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    initialValues: getActivateInitialValues(state),
    itemOptions: getActivationItemOptions(state),
    integrationState: getIntegrationState(state),
    integrationAdjustmentReasons: getIntegrationAdjustmentReasonOptions(state),
    timezone: state.timezone,
    facilityUsers: state[dataNames.users]
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    getItem,
    getUnpaginatedData,
    postData,
    addMessage,
    unsetData
  };

  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivateInventoryModal);
