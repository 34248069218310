import React from 'react';
import {reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import {InputGroup} from 'react-bootstrap';
import {FaSearch} from 'react-icons/fa';
import {ANALYTICS_REPORTS_SEARCH_FORM} from '../../constants/forms';
import TextInput from '../common/form/TextInput';

const AnalyticsReportsSearchForm = ({handleSubmit, change}) => {
  return (
    <div className='layout-row' style={{alignItems: 'center', margin: '20px 0'}}>
      <form onSubmit={handleSubmit} style={{width: '300px'}}>
        <InputGroup>
          <TextInput meta={{}}
                     input={{
                       name: 'report_search'
                     }}
                     onChange={(e) => change('report_search', e.target.value)}
          />
          <InputGroup.Append>
            <InputGroup.Text>
              <FaSearch/>
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
      </form>
    </div>
  );
};

AnalyticsReportsSearchForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
};

export default reduxForm({
  form: ANALYTICS_REPORTS_SEARCH_FORM,
  initialValues: {report_search: ''},
  onSubmit: () => {}
})(AnalyticsReportsSearchForm);
