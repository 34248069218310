import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';

const Lineage = ({strain}) => {
  return (<Row>
    <Col xs={11}>
      <dl>
        <dt>{I18n.t('products.detail.strainName')}</dt>
        <dd>{strain.strain_name}</dd>
        <dt>{I18n.t('products.detail.dominance')}</dt>
        <dd>{strain.dominance || I18n.t('common.notListed')}</dd>
        <dt>{I18n.t('products.detail.mother')}</dt>
        <dd>{strain.mother || I18n.t('common.notListed')}</dd>
        <dt>{I18n.t('products.detail.pollenDonor')}</dt>
        <dd>{strain.pollen_donor || I18n.t('common.notListed')}</dd>
      </dl>
    </Col>
  </Row>);
};

Lineage.propTypes = {
  strain: PropTypes.object.isRequired
};

export default Lineage;
