import React from 'react';
import { Field, FieldArray } from 'redux-form';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { Col, Row } from 'react-bootstrap';
import ModalityRow from './ModalityRow';
import SubmitSection from '../../../common/form/SubmitSection';
import NumericInput from '../../../common/form/NumericInput';

export const CupoForm = (props) => {
  const { handleSubmit, strains, submitting, invalid, pristine, isEditMode } = props;

  const submitSectionSettings = {
    actionSettings: {
      submit: {
        submitting,
        invalid,
        pristine,
        text: I18n.t('common.actions.save')
      }
    },
    align: 'right'
  };

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col md={6} lg={3}>
          <Field
            name={`cupo_number`}
            component={NumericInput}
            props={{
              label: I18n.t('cupos.form.cupoNumber')
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <FieldArray name='modalities' component={ModalityRow} strains={strains} isEditMode={isEditMode} />
        </Col>
      </Row>
      <SubmitSection settings={submitSectionSettings} />
    </form>
  );
};

CupoForm.propTypes = {
  isEditMode: PropTypes.bool,
  handleSubmit: PropTypes.func,
  strains: PropTypes.array,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
};

CupoForm.defaultProps = {
  isEditMode: false
};

export default CupoForm;
