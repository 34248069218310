import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import uniqBy from 'lodash.uniqby';
import lodash_get from 'lodash.get';
import { Field } from 'redux-form';
import { Button, Col, Row } from 'react-bootstrap';
import {FaExclamationTriangle} from 'react-icons/fa';
import InternationalQuantityByUomInput from '../../common/form/InternationalQuantityByUomInput';
import ReactSelectInput from '../../common/form/ReactSelectInput';
import TextInput from '../../common/form/TextInput';
import InlineCheckBox from '../../common/form/InlineCheckBox';
import ReduxMetrcIdField from '../../common/form/redux-form/ReduxMetrcIdField';
import InternationalDateTimePickerInput from '../../common/form/InternationalDateTimePickerInput';
import PackagePurposeField from '../../common/form/redux-form/PackagePurposeField';
import MetrcConcealer from '../../common/concealers/MetrcConcealer';
import { isMedicated } from '../../../selectors/itemMastersSelectors';
import showFinishedInventoryFlag from '../../../selectors/rulesSelectors';
import WillRender from '../../common/concealers/WillRender';
import CheckBoxInput from '../../common/form/CheckBoxInput';
import { WA_OTHER_MATERIAL } from '../../../constants/integration/leafWa/leafWaInventoryTypes';
import {EA, UOM_UNIT_VALUE_PRECISION} from '../../../constants/uoms';
import { leafWaReconciliationReasonsRequiringReceivedBy } from '../../../constants/leaf';
import ModalityField from '../../integration/colombia/ModalityField';
import * as p from '../../../constants/permissions';
import PermissionWrapper from '../../common/PermissionWrapper';

const PackagesFieldArray = (props) => {
  const {
    fields: { map, length, get },
    change,
    packages,
    adjustmentReasons,
    integrationState,
    integrationAdjustmentReasons,
    showReverseInventoryOption,
    trackingIds,
    hasGlobalAdjustmentReason,
    hasGlobalIntegrationAdjustmentReason,
    inventoryItems,
    inventoryTypes,
    showModalMedical,
    facilityUsers,
    isFailingTrackingRequirements,
    isFailingTrackingRequirementsMessage,
    complianceSettings,
    form,
    cupos,
    hasManufacturing,
    validateProductCanBeChanged,
    productCanBeChanged,
    isMetrcStemHoldingsToggled,
    isMetrcCaDonationsToggled,
    isFeatureToggleEditInventoryItemCost,
    requireOnHandProductExpireDate
  } = props;

  const toggleAddBackToParent = (field, value) => {
    change(field, value);
  };
  const { isBiotrack, isCanada, isMetrc, isCaMetrc, isPaLeaf, isWaLeaf, isColombia } = integrationState;
  const prefix = isBiotrack ? 'biotrack' : isMetrc ? 'metrc' : 'leaf';
  const col = isColombia ? 2 : 3;

  const stateIntegrationIdIsValid = (value) => {
    if (value === null) return false;
    if (value === undefined) return false;
    return value.trim() !== '';
  };

  return (
    <div className='group packages'>
      <h4>{I18n.t('packages.form.packageInformation')}</h4>
      {isFailingTrackingRequirements ? (
        <div className='alert alert-danger'>
          <div>
            <FaExclamationTriangle style={{ marginRight: '5px' }}/>
            {isFailingTrackingRequirementsMessage}
          </div>
        </div>
      ) : null}
      <div className='packages-list'>
        {map((packageName, packageIndex) => {
          const packageData = get(packageIndex);
          const qtyChanged = lodash_get(packageData, 'qtyChanged', 0);
          const itemMasterChanged = lodash_get(packageData, 'itemMasterChanged', 0);
          const item = packages[packageIndex] || {};
          const uom = item.uom;
          const inventoryItem = inventoryItems.find((ii) => ii.id === item.id);
          const disableFields = item.integration_id_platform_correction || isFailingTrackingRequirements;
          const isMedicatedItem = item.itemMaster && isMedicated(item.itemMaster);
          const showFinishedFlag = showFinishedInventoryFlag(
            { country_code: isCanada ? 'ca' : '' },
            get(item, 'itemMaster')
          );
          const hasOutForDeliveryTransfers = isWaLeaf && get(packageIndex)['hasOutForDeliveryTransfers'];
          const disabledMedicalInventoryType = isWaLeaf && get(packageIndex)['integration_type'] === WA_OTHER_MATERIAL;
          const isDisabledMedical =
            isWaLeaf &&
            (disabledMedicalInventoryType ||
              hasOutForDeliveryTransfers ||
              get(packageIndex)['medically_compliant_status'] === 'yes');
          const shouldDisplayReceivedByDropdown = leafWaReconciliationReasonsRequiringReceivedBy.includes(
            get(packageIndex)['integration_adjustment_reason']
          );
          const editItemBtn = (
            <Button
              disabled={isColombia && item.modality && item.cupo_id}
              onClick={() => {
                validateProductCanBeChanged(packageData).then(() => {
                  if (productCanBeChanged) {
                    props.showProductLookup({
                      referenceId: item.package_id,
                      currentPackageName: packageName,
                      currentPackageUom: uom,
                      categoryId: item.itemMaster.category_id,
                      subCategoryId: item.itemMaster.subcategory_id,
                      itemMasterId: item.itemMaster.id,
                      itemMasterStrainId: item.itemMaster.strain_id,
                      isMedicated: isMedicatedItem,
                      isPrepack: inventoryItem && inventoryItem.is_prepack ? inventoryItem.is_prepack : 0,
                      prepackWeightId:
                        inventoryItem && inventoryItem.prepack_weight_id ? inventoryItem.prepack_weight_id : 0
                    });
                  }
                });
              }}
            >
              {I18n.t('general.edit')}
            </Button>
          );


          const newQtyDynamicProps = {};
          if (item.uom === EA) newQtyDynamicProps.fractionPartSize = UOM_UNIT_VALUE_PRECISION;

          const cupo = cupos && cupos.find((cupo) => cupo.id === (inventoryItem && inventoryItem.cupo_id));

          const modalities =
            cupo &&
            cupo.modalities &&
            cupo.modalities
              .filter(modality => {
                return modality.strain_id === (inventoryItem && inventoryItem.strain_id);
              })
              .map((modality) => {
                return {
                  value: modality.modality,
                  text: I18n.t(`modalities.list.${modality.modality}`)
                };
              });
          const disableIsDonation = item.is_donation !== null;


          return (
            <Row key={packageName}>

                <Col xs={12} style={{padding: '0px 12px 6px', textAlign: 'center'}} className='text-danger'>
                  <WillRender ifTrue={itemMasterChanged || qtyChanged}>
                  {
                    itemMasterChanged
                      ? <div>{I18n.t('packages.form.disabledQuantityWarning')}</div>
                      : <div>{I18n.t('packages.form.disabledItemMasterWarning')}</div>
                  }
                  </WillRender>
                  {/*This next is purely a spacing component to keep the screen from jumping when a warning turns on.*/}
                  <WillRender ifTrue={!itemMasterChanged && !qtyChanged}>
                    &nbsp;
                  </WillRender>
                </Col>
              <Col xs={6} sm={4} md={2}>
                <Field
                  name={`${packageName}.package_code`}
                  component={TextInput}
                  props={{
                    label: I18n.t('packages.form.packageId'),
                    disabled: true
                  }}
                />
              </Col>
              <Col xs={4} sm={3} md={2}>
                <InternationalQuantityByUomInput
                  name={`${packageName}.newQty`}
                  label={I18n.t('packages.form.newQty')}
                  uom={item.uom}
                  allowNegativeNumber={false}
                  isRequired={true}
                  disabled={!!(disableFields || itemMasterChanged)}
                  {...newQtyDynamicProps}
                />
              </Col>
              <Col xs={4} sm={3} md={2}>
                <InternationalQuantityByUomInput
                  name={`${packageName}.transacted_qty`}
                  label={I18n.t('packages.form.adjustQty')}
                  uom={item.uom}
                  disabled={true}
                  groupClassName={item.transacted_qty < 0 ? 'negative' : 'positive'}
                />
              </Col>
              <Col xs={6} sm={4} md={col}>
                <Field name={`${packageName}.new_item_master_id`} component='input' type='hidden' />
                <Field
                  name={`${packageName}.new_item_master_display_name`}
                  component={TextInput}
                  rightAddon={!isBiotrack && !isPaLeaf && !isWaLeaf && !disableFields && !qtyChanged && editItemBtn}
                  props={{
                    label: I18n.t('packages.form.item'),
                    disabled: true,
                    isRequired: true
                  }}
                />
              </Col>
              <Col xs={4} md={2}>
                <Field
                  name={`${packageName}.adjustment_reason`}
                  component={ReactSelectInput}
                  props={{
                    label: (lodash_get(complianceSettings, 'reconciliation_requires_adjustment_reason', false) ? '*' : '') + I18n.t('packages.form.adjustmentReason'),
                    options: adjustmentReasons,
                    textKey: 'name',
                    valueKey: 'code',
                    isRequired: (isPaLeaf || isCanada) && !hasGlobalAdjustmentReason,
                    disabled: disableFields
                  }}
                />
              </Col>

              <WillRender ifTrue={lodash_get(complianceSettings, 'designate_inventory_created_from_processing_as_test_sample', false) && hasManufacturing}>
                <Col md={12}>
                  <Row>

                    <Col xs={12} sm={4} md={3} className='form-inline-checkbox'>
                      <Field
                        name={`${packageName}.is_test_package`}
                        component={CheckBoxInput}
                        props={{
                          label: I18n.t('inventory.splitPackage.form.testPackage'),
                          disabled: isFailingTrackingRequirements
                        }}
                      />
                    </Col>

                  </Row>
                </Col>
              </WillRender>

              {isColombia ? (
                <ModalityField
                  formName={form}
                  name={`${packageName}.modality`}
                  cupo_id={inventoryItem && inventoryItem.cupo_id}
                  strain_id={inventoryItem && inventoryItem.strain_id}
                  options={uniqBy(modalities, 'value')}
                  LayoutComponent={({ children }) => (
                    <Col xs={6} md={2}>
                      {children}
                    </Col>
                  )}
                />
              ) : null}

              {isMetrc || isBiotrack || isWaLeaf ? (
                <Col md={12}>
                  <Row>
                    {isBiotrack && (
                      <Col xs={4} md={2}>
                        <Field
                          name={`${packageName}.integration_type`}
                          component={ReactSelectInput}
                          props={{
                            label: I18n.t('cultivation.transfers.form.inventoryType'),
                            options: inventoryTypes,
                            disabled: true
                          }}
                        />
                      </Col>
                    )}
                    {(isMetrc || (isBiotrack && isMedicatedItem) || isWaLeaf) && (
                      <Col xs={4} md={2}>
                        <Field
                          name={`${packageName}.integration_adjustment_reason`}
                          component={ReactSelectInput}
                          props={{
                            label: I18n.t(`packages.form.${prefix}AdjustmentReason`),
                            options: integrationAdjustmentReasons,
                            disabled: disableFields,
                            isRequired:
                              (!isBiotrack && !hasGlobalIntegrationAdjustmentReason) ||
                              (isBiotrack && parseFloat(item.transacted_qty) && !hasGlobalIntegrationAdjustmentReason)
                          }}
                        />
                      </Col>
                    )}
                    {isWaLeaf && shouldDisplayReceivedByDropdown && (
                      <Col xs={4} md={2}>
                        <Field
                          name={`${packageName}.received_by_user_id`}
                          component={ReactSelectInput}
                          props={{
                            label: I18n.t(`packages.form.receivedBy`),
                            options: facilityUsers,
                            textKey: 'name',
                            valueKey: 'id',
                            isRequired: true
                          }}
                        />
                      </Col>
                    )}
                  </Row>
                </Col>
              ) : null}
              {isMetrc && isMedicatedItem ? (
                <Col xs={6} sm={4} md={3}>
                  <ReduxMetrcIdField
                    name={`${packageName}.state_integration_tracking_id`}
                    props={{
                      options: trackingIds
                    }}
                  />
                </Col>
              ) : null}
              {isMetrc && item.hasTrackingId && length === 1 ? (
                <Col xs={6} sm={4} md={3}>
                  <div className='form-group'>
                    <label style={{ width: '100%' }} />
                    <div style={{ clear: 'both', height: '8px' }} />
                    <label style={{ fontWeight: 'normal' }}>
                      <Field
                        name={`${packageName}.integration_id_platform_correction`}
                        component='input'
                        style={{ marginRight: '8px', marginTop: '5px' }}
                        type='checkbox'
                      />
                      I made an entry error, manually replace the METRC tag.
                    </label>
                  </div>
                </Col>
              ) : null}

              {!showReverseInventoryOption ? null : stateIntegrationIdIsValid(
                  item.state_integration_tracking_id
                ) ? null : parseFloat(item.newQty) > 0 ? null : (
                <Col md={4}>
                  <label className='text-muted' style={{ fontWeight: 'normal' }}>
                    {I18n.t('inventory.reverse.destroy')}
                  </label>
                  <div className='input-group'>
                    <span className='input-group-addon'>
                      <input
                        onChange={(event) => {
                          toggleAddBackToParent(`${packageName}.add_back_to_parent`, event.target.checked ? 1 : 0);
                        }}
                        type='checkbox'
                      />
                    </span>
                    <div className='form-control' style={{ height: 'auto' }}>
                      {I18n.t('inventory.reverse.toParent')}
                    </div>
                  </div>
                </Col>
              )}
              <MetrcConcealer>
                <Col md={2}>
                  <Field
                    name={`${packageName}.is_produced`}
                    component={ReactSelectInput}
                    props={{
                      label: I18n.t('metrc.form.isProductionBatch'),
                      options: [{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }]
                    }}
                  />
                </Col>
                {
                  isMetrcStemHoldingsToggled && (
                    <Col md={2}>
                      <Field
                        name={`${packageName}.is_trade_sample`}
                        component={ReactSelectInput}
                        props={{
                          disabled: true,
                          label: I18n.t('metrc.form.isTradeSample'),
                          options: [{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }]
                        }}
                      />
                    </Col>
                  )
                }
                 {
                  (isMetrcCaDonationsToggled && isCaMetrc) && (
                    <Col md={2}>
                      <Field
                        name={`${packageName}.is_donation`}
                        component={ReactSelectInput}
                        props={{
                          disabled: disableIsDonation,
                          label: I18n.t('metrc.form.donation'),
                          options: [{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }],
                        }}
                      />
                    </Col>
                  )
                }
              </MetrcConcealer>
              <Col xs={12}>
                <Row>
                  <Col xs={3}>
                    <InternationalDateTimePickerInput
                      name={`${packageName}.packaged_at`}
                      props={{
                        label: I18n.t('packaging.form.actualCompletionTime'),
                        timeFormat: true,
                        isRequired: true,
                        handleChange: (value) => {
                          change(`${packageName}.packaged_at`, value);
                          change(`${packageName}.packaged_at_dirty`, 1);
                        },
                        inputProps: {disabled: disableFields}
                      }}/>
                  </Col>
                  <Col xs={3}>
                    <InternationalDateTimePickerInput
                      name={`${packageName}.package_created_at`}
                      props={{
                        label: I18n.t('packaging.form.packageCreatedAt'),
                        timeFormat: false,
                        inputProps: {disabled: disableFields},
                        handleChange: (value) => {
                          change(`${packageName}.package_created_at`, value);
                          change(`${packageName}.package_created_at_dirty`, 1);
                        },
                      }}/>
                  </Col>
                  <Col xs={3}>
                    <InternationalDateTimePickerInput
                      name={`${packageName}.package_expires_at`}
                      props={{
                        label: I18n.t('packaging.form.packageExpiresAt'),
                        timeFormat: false,
                        isRequired: requireOnHandProductExpireDate,
                        enableTodayButton: false,
                        // Disabled when specified as disabled EXCEPT if for solo tag which allows/requires editing exp. date
                        inputProps: { disabled: disableFields && !isFailingTrackingRequirements },
                        onChange: (value) => {
                          change(`${packageName}.package_expires_at`, value);
                          change(`${packageName}.package_expires_at_dirty`, 1);
                        }
                      }}
                    />
                  </Col>
                  <Col xs={2}>
                    <PackagePurposeField name={`${packageName}.purpose`} disabled={isFailingTrackingRequirements} />
                  </Col>
                  <PermissionWrapper permissions={[p.manage_package_cost]}>
                    <WillRender ifTrue={isFeatureToggleEditInventoryItemCost && packageData.package_id}>
                      <Col xs={6} sm={4} md={2}>
                        <Field
                          name={`${packageName}.unit_cost`}
                          component={TextInput}
                          props={{
                            label: I18n.t('purchaseOrders.form.unitCost')
                          }}
                        />
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        <Field
                          name={`${packageName}.unit_cost_all_adjust`}
                          component={InlineCheckBox}
                          props={{
                            label: I18n.t('packaging.form.updateAllUnitCost')
                          }}
                        />
                      </Col>
                    </WillRender>
                  </PermissionWrapper>
                  <WillRender ifTrue={showFinishedFlag}>
                    <Col md={3}>
                      <Field
                        name={`${packageName}.finished`}
                        component={InlineCheckBox}
                        props={{
                          label: I18n.t('packaging.form.finishedInventory')
                        }}
                      />
                    </Col>
                  </WillRender>
                </Row>
              </Col>

              {isWaLeaf ? (
                <Col xs={6} md={3} lg={2}>
                  <Field
                    name={`${packageName}.medically_compliant`}
                    component={CheckBoxInput}
                    props={{
                      label: I18n.t('harvestPackages.form.leafWa.seekingMedicalDesignation'),
                      groupClassName: 'inline-checkbox seeking-medical-designation',
                      disabled: isDisabledMedical,
                      onChange: (event) => {
                        showModalMedical(event)
                          ? toggleAddBackToParent(`${packageName}.medically_compliant`, event.target.checked ? 1 : 0)
                          : '';
                      }
                    }}
                  />
                  {hasOutForDeliveryTransfers && !disabledMedicalInventoryType && (
                    <span className='note'>{I18n.t('harvestPackages.form.leafWa.medicallyCompliantTransfer')}</span>
                  )}
                </Col>
              ) : null}
              <Row>
                <Col xs={12}>
                  <hr />
                </Col>
              </Row>
            </Row>
          );
        })}
      </div>
    </div>
  );
};

PackagesFieldArray.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired
  }),
  change: PropTypes.func.isRequired,
  packages: PropTypes.array.isRequired,
  adjustmentReasons: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
  integrationAdjustmentReasons: PropTypes.array.isRequired,
  trackingIds: PropTypes.array.isRequired,
  showReverseInventoryOption: PropTypes.bool,
  hasGlobalAdjustmentReason: PropTypes.bool,
  hasGlobalIntegrationAdjustmentReason: PropTypes.bool,
  showProductLookup: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  inventoryTypes: PropTypes.array,
  filterBy: PropTypes.func.isRequired,
  showModalMedical: PropTypes.func,
  isFailingTrackingRequirements: PropTypes.bool,
  isFailingTrackingRequirementsMessage: PropTypes.string,
  form: PropTypes.string.isRequired,
  cupos: PropTypes.array,
  validateProductCanBeChanged: PropTypes.func,
  productCanBeChanged: PropTypes.bool,
  isMetrcStemHoldingsToggled: PropTypes.bool,
  isMetrcCaDonationsToggled: PropTypes.bool,
  isFeatureToggleEditInventoryItemCost: PropTypes.bool.isRequired,
  requireOnHandProductExpireDate: PropTypes.bool.isRequired
};

export default PackagesFieldArray;
