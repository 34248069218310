import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import { Col, Row, /*Button*/ } from 'react-bootstrap';
//import { I18n } from 'react-redux-i18n';
import TextInput from '../../common/form/TextInput';
import {normalizeBranchIdentifier, formatBranchIdentifier} from './validateOrganization';

const FacilityBank = (props) => {

  const {fieldPrefix, /*onRemoveBank, bankIndex,*/ isLast} = props;

  return (<div>
    <Row>
      <Col md={6}>
        <Field
          name={`${fieldPrefix}.bank_name`}
          component={TextInput}
          props={{
            maxLength: 100,
            label: 'Bank Name',
          }}
        />
      </Col>
      <Col md={6}>
        <Field
          name={`${fieldPrefix}.account_name`}
          component={TextInput}
          props={{
            maxLength: 100,
            label: 'Account Name',
          }}
        />
      </Col>
    </Row>
    <Row>
      <Col md={6}>
        <Field
          name={`${fieldPrefix}.branch_identifier`}
          component={TextInput}
          normalize={normalizeBranchIdentifier}
          format={formatBranchIdentifier}
          props={{
            maxLength: 7,
            label: 'BSB',
          }}
        />
      </Col>
      <Col md={6}>
        <Field
          name={`${fieldPrefix}.account_number`}
          component={TextInput}
          props={{
            maxLength: 9,
            label: 'Account Number',
          }}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <Field
          name={`${fieldPrefix}.banking_statement`}
          component={TextInput}
          props={{
            maxLength: 100,
            label: 'Banking Statement',
          }}
        />
      </Col>
      {/*Left against the day we need multiple banks...*/}
      {/*<Col md={2}>*/}
        {/*<div className='form-group'>*/}
          {/*<label style={{width: '100%'}} >&nbsp;</label>*/}
          {/*<Button*/}
            {/*variant='danger'*/}
            {/*className='btn-block'*/}
            {/*disabled={bankIndex === 0 && isLast}*/}
            {/*onClick={(e) => {*/}
              {/*e.target.blur();*/}
              {/*onRemoveBank(bankIndex);*/}
            {/*}}*/}
          {/*>Delete</Button>*/}
        {/*</div>*/}
      {/*</Col>*/}
    </Row>
    {
      !isLast ? <hr style={{margin: '0px 0px 20px 0px', borderTop: '1px solid #ccc'}} /> : null
    }
  </div>);

};

FacilityBank.propTypes = {
  fieldPrefix: PropTypes.string.isRequired,
  bankIndex: PropTypes.number.isRequired,
  onRemoveBank: PropTypes.func.isRequired,
  isLast: PropTypes.bool.isRequired,
};

export default FacilityBank;
