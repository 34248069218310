import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as apiActions from '../../../../actions/apiActions';
import CupoFormWrapper from '../common/CupoFormWrapper'; //eslint-disable-line
import FormWrapper from '../../../common/form/FormWrapper';
import * as itemNames from '../../../../constants/itemNames';
import { getModifyCupoInitialValues } from '../../../../selectors/forms/cupoFormSelectors';
import * as dataNames from '../../../../constants/dataNames';
import { getOrderedFacilityStrains } from '../../../../selectors/facilityStrainsSelectors';

class ModifyCupoPage extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount() {
    Promise.all([
      this.props.actions.getUnpaginatedData('/api/strains/by_facility', dataNames.facilityStrains),
      this.props.actions
        .getItem(`/api/cupos/${this.props.params.id}`, itemNames.cupo, null, {
          with_relations: ['modalities'],
          detailed: 1
        })
        .then(() => {
          this.props.actions.getItem('/api/cultivation/settings', itemNames.colombiaModalities, null, {
            ids: ['cult_colombia_modality']
          });
        })
    ]);
  }

  onSubmit(formValues) {
    const modalities = formValues.modalities
      .filter((modality) => modality.selected == true)
      .flatMap((modality) =>
        modality.strains.map((strain) => {
          return {
            id: modality.id,
            modality: modality.key,
            strain_id: strain.id
          };
        })
      );

    const payload = {
      ...formValues,
      modalities
    };

    return this.props.actions.putItem(`/api/cupos/${this.props.params.id}`, payload, itemNames.cupo, {
      failed: 'cupos.actions.modify.fail',
      success: 'cupos.actions.modify.success'
    });
  }

  render() {
    const { initialValues, strains } = this.props;
    return (
      <div>
        <FormWrapper title='cupos.modifyCupo'>
          <CupoFormWrapper initialValues={initialValues} strains={strains} onSubmit={this.onSubmit} isEditMode={true} />
        </FormWrapper>
      </div>
    );
  }
}

ModifyCupoPage.propTypes = {
  actions: PropTypes.object,
  params: PropTypes.object,
  initialValues: PropTypes.object,
  strains: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    initialValues: getModifyCupoInitialValues(state),
    strains: getOrderedFacilityStrains(state)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, apiActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModifyCupoPage);
