export default class EventHandler {
  constructor(config) {
    this.events = {};
    this.settings = Object.assign({}, config);
  }

  addEvent(key, callback) {
    const events = this.events[key];
    if(events) {
      events.push(callback);
    } else {
      this.events[key] = [callback];
    }
  }

  detachEvents(key) {
    delete this.events[key];
  }

  detachEvent(key, callback) {
    const events = this.events[key];
    if (events) {
      const index = events.findIndex((item) => { item === callback; });
      if (index > -1) {
        delete events[index];
      }
    }
  }

  fireEvent(key) {
    const events = this.events[key];
    if(Array.isArray(events) && events.length > 0) {
      for(let i = 0; i < events.length; i++) {
        events[i](arguments[1]);
      }
    }
  }
}
