import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Col, Button, Row} from 'react-bootstrap';
import {FaTrash} from 'react-icons/fa';
import InternationalCurrencyInput from '../../../common/form/InternationalCurrencyInput';
import InternationalQuantityByUomInput from '../../../common/form/InternationalQuantityByUomInput';
import InternationalDecimalStatic from '../../../common/form/InternationalDecimalStatic';
import InternationalCurrencyStatic from '../../../common/form/InternationalCurrencyStatic';


const BulkLineItem = (props) => {

  const {line, change, isActive, lineItem, index, removeFromPo, transferRecord, uom, metrcIsImported} = props;
  const { abbrev } = I18n.t(`uoms.${uom}`);

  const getStaticDisplayData = () => {
    return (<Col lg={8}>
      <Col lg={2}>
        <label>{I18n.t('purchaseOrders.form.requestedAmount')}</label>
        <InternationalDecimalStatic>{lineItem.qty}</InternationalDecimalStatic>
      </Col>
      <Col lg={2}>
        <label>{I18n.t('purchaseOrders.form.pricePer', {label: abbrev})}</label>
        <InternationalCurrencyStatic>{lineItem.unit_price}</InternationalCurrencyStatic>
      </Col>
      <Col lg={3} offset={4}>
        <label>{I18n.t('purchaseOrders.form.itemPrice')}</label>
        <InternationalCurrencyStatic>{lineItem.line_item_price}</InternationalCurrencyStatic>
      </Col>
    </Col>);
  };

  const getEditableData = () => { //eslint-disable-line
    return (
      <Col lg={8}>
        <Row>
          <Col lg={3}>
            <InternationalQuantityByUomInput
              name={`${line}.qty`}
              disabled={!isActive || metrcIsImported}
              uom={uom}
              label={I18n.t('purchaseOrders.form.requestedAmount')}
              isRequired={true} />
          </Col>
          <Col lg={3}>
            <InternationalCurrencyInput
              name={`${line}.unit_price`}
              props={{
                label: (lineItem && lineItem.editableLinePrice !== undefined && lineItem.editableLinePrice)
                  ? I18n.t('purchaseOrders.form.approxPricePerGram')
                  : I18n.t('purchaseOrders.form.pricePer', {label: abbrev}),
                disabled: (!isActive
                  ? true
                  : lineItem && lineItem.editableLinePrice !== undefined
                    ? lineItem.editableLinePrice
                    : false),
                isRequired: true,
              }}
            />
          </Col>
          <Col lg={3}>
            <InternationalCurrencyInput
              name={`${line}.line_item_price`}
              props={{
                label: I18n.t('purchaseOrders.form.itemPrice'),
                disabled: (lineItem && lineItem.editableLinePrice !== undefined ? !lineItem.editableLinePrice : true),
                rightAddon: transferRecord
                  ? null
                  : (
                    <Button
                      onClick={(event) => {
                        const newEditableValue = lineItem.editableLinePrice !== undefined ? !lineItem.editableLinePrice : true;
                        change(`${line}.editableLinePrice`, newEditableValue);
                      }}>
                      {lineItem && lineItem.editableLinePrice !== undefined && lineItem.editableLinePrice ? 'Calc' : 'Edit'}
                    </Button>
                  ),
              }}
            />
          </Col>
          <Col lg={1}>
            <div className='form-input form-group'>
              <label className='control-label' style={{width: '100%'}}>&nbsp;</label>
              <button disabled={transferRecord} className='btn btn-small btn-block' onClick={(event) => {
                event.preventDefault();
                removeFromPo(index);
              }}>
                <FaTrash style={{marginRight: '4px'}} />
              </button>
            </div>
          </Col>
        </Row>
      </Col>);
  };

  return !lineItem.showEditors && lineItem.showEditors !== undefined
    ? getStaticDisplayData()
    : getEditableData();

};

BulkLineItem.propTypes = {
  //itemMaster : PropTypes.object.isRequired,
  line: PropTypes.string.isRequired,
  uom: PropTypes.string,
  change: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  lineItem: PropTypes.object.isRequired,
  removeFromPo: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  transferRecord: PropTypes.bool.isRequired,
  metrcIsImported: PropTypes.bool,
};

BulkLineItem.defaultProps = {
  isActive: true
};

export default BulkLineItem;
