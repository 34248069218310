export const BACK_OFFICE = 'BACK_OFFICE';
export const CROP_PLANNING = 'CROP_PLANNING';
export const CULTIVATION = 'CULTIVATION';
export const DISTRIBUTION = 'DISTRIBUTION';
export const EXTRACTION = 'EXTRACTION';
export const GENERAL_ADMINISTRATION = 'GENERAL_ADMINISTRATION';
export const INFUSION = 'INFUSION';
export const MARKETING_CUSTOMERS = 'MARKETING_CUSTOMERS';
export const REPORTING = 'REPORTING';
export const REPORTING_TIER2 = 'REPORTING_TIER2';
export const REPORTING_TIER3 = 'REPORTING_TIER3';
export const REPORTING_TIER4 = 'REPORTING_TIER4';
export const RETAIL = 'RETAIL';
export const INTERNATIONAL_OPERATIONS = 'INTERNATIONAL_OPERATIONS';
export const COURIER_SERVICE = 'COURIER_SERVICE';
export const HOME_DELIVERY = 'HOME_DELIVERY';
export const CLOSED_LOOP_REGULATOR_ACCESS = 'CLOSED_LOOP_REGULATOR_ACCESS';
