import React from 'react';
import {connect} from 'react-redux';
import {getFormValues} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Card} from 'react-bootstrap';

import PageTitle from '../../../common/PageTitle';
import SalesReportPageTabs from '../SalesReportPageTabs';
import TablePageWrapper from '../../../common/grid/TablePageWrapper';
import RegisterActivityReportFilterForm from './RegisterActivityReportFilterForm';

import registerClosingActivityReportColumns from './tableDefinitions';
import * as dataNames from '../../../../constants/dataNames';
import {columnVisibilitySettings} from '../../../../constants/itemNames';
import {REGISTER_CLOSING_ACTIVITY_FORM} from '../../../../constants/forms';
import {getPaginatedData, getUnpaginatedData} from '../../../../actions/apiActions';
import {unsetData} from '../../../../actions/dataActions';
import {getRegisterClosingRowsForActivityReport} from '../../../../selectors/registerClosingSelectors';
import {getRegisterOptions} from '../../../../selectors/transactionReportSelectors';
import {getTotalResults} from '../../../../selectors/paginationSelectors';


class RegisterActivityReport extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      page: null,
      searchTriggered: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.pageChange = this.pageChange.bind(this);
  }

  componentDidMount() {
    this.fetchRegisters();
  }

  fetchRegisters() {
    const {getUnpaginatedData} = this.props;

    getUnpaginatedData(
      '/api/registers',
      dataNames.registers,
      {failed:'registers.get.failed'},
      {active:1}
    );
  }

  onSubmit(formValues) {
    const {columnVisibilitySettings} = this.props;
    const perPage = columnVisibilitySettings.per_page || 20;

    this.fetchRegisterClosings(formValues, 1, perPage);
    this.setState({searchTriggered: true});
  }

  pageChange(sort, query, size, start) {
    if (!this.state.searchTriggered) return;

    const {formValues} = this.props;
    const pageNumber = start / size + 1;

    this.fetchRegisterClosings(formValues, pageNumber, size);
  }

  fetchRegisterClosings(formValues, page, perPage) {
    const {getPaginatedData} = this.props;
    const dateFormat = 'YYYY-MM-DD';
    const params = {
      page,
      per_page: perPage,
      select_columns: [
        'id', 'register_id', 'opening_time', 'closed_at', 'opening_balance', 'balance_before_closing',
        'reset_balance_to', 'counted_drop', 'counted_total', 'verified_drop', 'verified_total',
        'counted_over_short', 'verified_over_short'
      ],
    };

    if (formValues.registers) {
      params.register_ids = formValues.registers.map(register => register.value);
    }

    if (formValues.startDate) {
      params.created_at_from = formValues.startDate.format(dateFormat);
    }

    if (formValues.endDate) {
      params.created_at_to = formValues.endDate.add(1, 'day').format(dateFormat);
    }

    getPaginatedData('/api/closings', dataNames.registerClosings, null, params);
  }

  componentWillUnmount() {
    const {unsetData} = this.props;

    unsetData(dataNames.registers);
    unsetData(dataNames.registerClosings);
  }


  render() {
    const {page} = this.state;
    const {registerClosingRows, registers, totalRegisterClosings} = this.props;

    return (
      <React.Fragment>
        <PageTitle primaryText={I18n.t('retail.registerClosingActivityReport.title')}/>
        <SalesReportPageTabs activeKey='registerActivityReport' />
        <Card className='form-page'>
          <Card.Header />
          <Card.Body>
            <RegisterActivityReportFilterForm
              onSubmit={this.onSubmit}
              registers={registers}
            />
          </Card.Body>
        </Card>
        <TablePageWrapper
          activeTab='default'
          columns={registerClosingActivityReportColumns}
          data={registerClosingRows}
          dataTotalSize={totalRegisterClosings}
          actions={[]}
          hideSearch={true}
          hideScanSearch={true}
          settingKey='registerClosingActivityReport'
          external={true}
          externalSearch={this.pageChange}
          page={page}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  registers: getRegisterOptions(state),
  registerClosingRows: getRegisterClosingRowsForActivityReport(state),
  columnVisibilitySettings: state[columnVisibilitySettings],
  formValues: getFormValues(REGISTER_CLOSING_ACTIVITY_FORM)(state) || {},
  totalRegisterClosings: getTotalResults(state, { name: dataNames.registerClosings })
});

const mapDispatchToProps = {
  getPaginatedData,
  getUnpaginatedData,
  unsetData
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterActivityReport);
