import {createSelector} from 'reselect';
import filter from 'lodash.filter';
import union from 'lodash.union';
import isEmpty from 'lodash.isempty';
import orderBy from 'lodash.orderby';
import map from 'lodash.map';
import get from 'lodash.get';
import groupBy from 'lodash.groupby';
import {pick} from 'lodash';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import consumerTypes, {DISABLED_VETERANS, MMIC} from '../constants/consumerTypes';
import * as itemNames from '../constants/itemNames';
import * as dataNames from '../constants/dataNames';
import {
  medical_id,
  temp_id,
  driver_license,
  extended_sales_limit,
  fileOptions,
  canadian_registration_form,
  intake_form
} from '../constants/fileTypes';
import {FILE_TYPES, COMMON_PRIVATE_IMAGE} from '../constants/fileUploads';
import {
  convertDbDateTimeToFormInputDateTime
} from '../util/dateHelpers';
import {getImageUrl, getImgByGender} from '../util/images';
import {PERSON} from '../constants/imageUrls';
import {getOrders} from './ordersSelectors';
import {getPatientSettings} from './salesSettingsSelectors';
import {isLeafIntegrator} from './integration/leafSelectors';
import {getCurrentFacilityId, getActiveFacility} from './facilitiesSelectors';
import {saveParse} from '../util/jsonHelpers';
import {getOrderedPhysicians} from './physiciansSelectors';
import {getIntegrationState} from './integration/integrationSelectors';
import {getFacilityState} from './facility/getCurrentFacility';
import {buildCompleteInternationalPhoneNumber}  from '../util/formatHelper';
import { getPhoneShouldIncludeCountryCode } from '../selectors/InternationalOperationsSelectors';
import {isFeatureEnabled} from './featureToggles';
import {convertTerms} from '../util/solrHelpers';

const getImages = (state) => state.images;
const getCurrentTimezone = (state) => state.timezone;
const getCustomersQueue = (state) => state.customersQueue;
const getConsumers = (state) => state.consumers;
const getCustomers = (state) => state.customers;
const getOrderType = (state) => state.orderType;
const getUsageType = (state) => state.usageType;
const getCustomerFilters = (state) => state.customerFilters;
const getOrderTypeCounts = (state) => state.orderTypeCounts;
const getQualifyingConditions = (state) => state.qualifyingConditions;
const getPatientComplianceSettings = (state) => state[itemNames.patientSettings];
const getSalesComplianceSettings = (state) => state[itemNames.salesComplianceSettings];
const getCustomerSettings = (state) => state[itemNames.customer];
const getCustomerGroups = (state) => state.customerGroups;
const getOraclePatient = (state) => state.oraclePatient;
const getOraclePatientSupplementalInfo = (state) => state.oraclePatientSupplementalInfo;
const getOracleCaregiver = (state) => state.oracleCaregiver;
const customerOrderType = (customer) => customer.order ? customer.order.fulfillment_method : 'in_store';
const isCourierLicenseFeatureEnabled = state => isFeatureEnabled(state)('feature_courier_licensees');

const getCustomersQueueForCustomerListing = createSelector([getCustomersQueue], queues => {
  return queues.map(queue => {
    return {
      ...queue,
      queue_id: queue.id,
      id: undefined,
    };
  });
});

const getOrdersForCustomerListing = createSelector([getOrders], orders => {
  return orders.map(order => {
    return {
      ...order,
      order_id: order.id,
      id: undefined,
    };
  });
});

export const getCustomer = state => state[itemNames.customer];
export const getActiveConsumers = (state) => state[dataNames.activeConsumers];
export const getCustomerCurrentOrder = (state) => state[itemNames.customerCurrentOrder];

export const filterObjects = createSelector(getCustomerFilters, filters => filters.map(filter => {
  return {[filter.field]:filter.value};
}));

export const filteredCustomers = createSelector([getCustomers, filterObjects], (customers, filters) => {
  if(filters.length){
    return union(...filters.map(filterObj => filter(customers, filterObj)));
  }else{
    return customers;
  }
});

export const getAddressFieldsDisabled = createSelector(
  isCourierLicenseFeatureEnabled, getPatientComplianceSettings,
  (courierLicenseEnabled, settings) => {
    if (courierLicenseEnabled) {
      return !!get(settings, 'partner_consumer_address_locked.value');
    }
    return false;
  }
);

export const getDisplaySaveAndCheckIn = createSelector(getPatientComplianceSettings, settings => !!get(settings, 'partner_display_save_and_check_in.value'));

// Returns all active customer groups available AND inactive groups that is currently assigned to user
export const getGroupsForCurrentCustomer = createSelector(
  [getCustomerGroups, getCustomer],
  (groups, customer) => {
    if (!customer || !customer.groups) return [];
    return groups
      .filter(group => Boolean(group.active) || customer.groups.find(usergroup => usergroup.id === group.id))
      .map(group => ({...group, group_name: group.active ? group.group_name : group.group_name + ' (' + I18n.t('customers.table.inactiveGroup') + ')'}));
  }
);

export const customersWithOrder = createSelector(
  getCustomers, getOrders, getConsumers,
  (customers, orders, consumers) => customers.map(customer => {
    const consumer =  consumers && consumers.find(consumer => customer.consumer_id === consumer.id) || undefined;
    return Object.assign({}, customer, {
      order: customer.order_id
        ? orders.reduce((previous, current) => current.id === customer.order_id ? current : previous, null)
        : null,
      type: consumer ? consumer.type : '',
      image_url: PERSON
    });
  })
);

export const customersWithOrderOfType = createSelector(
  getOrderType, getUsageType, customersWithOrder,
  (orderType, usageType, customers) => customers.filter(customer => (orderType.orderType == customerOrderType(customer) && (usageType.usageType == 'all' || usageType.usageType == customer.type)))
);

function calculateTotal(sourceObj) {
  return consumerTypes.reduce((acc, type) => acc + (sourceObj[type] || 0), 0);
}

export const normalizeOrderTypeCounts = createSelector(
  [getOrderTypeCounts, getSalesComplianceSettings], (orderTypeCounts, salesSettings) => {
    const defaultValue = consumerTypes.reduce(
      (acc, type) => ({...acc, [type]: 0}),
      {total: 0}
    );
    const orderTypes = ['in_store', 'pickup', 'delivery', 'payment'];
    if(salesSettings.order_packaging_workflow && salesSettings.order_packaging_workflow.value === '1')orderTypes.splice(-1, 0, 'packaging');
    return orderTypes.reduce(
      (acc, type) => {
        if (orderTypeCounts[type]) {
          acc[type] = {
            ...orderTypeCounts[type],
            all: calculateTotal(orderTypeCounts[type])
          };
        } else {
          acc[type] = {...defaultValue};
        }
        return acc;
      },
      {}
    );
  }
);



export const getCustomerNotes = createSelector(
  [getCustomer, getCurrentTimezone],
  (customer, timezone) => orderBy(customer.notes, 'created_at', 'asc').map(customer => ({
    ...customer,
    createdAt: convertDbDateTimeToFormInputDateTime(customer.created_at, timezone),
  }))
);

export const getGroupedCustomerFiles = createSelector(
  [getCustomer],
  (customer) => {
    const filteredFiles = (customer.ids || [])
      .concat(customer.expired_ids || [])
      .filter(file => file.file_id)
      .map(file => ({
        ...file,
        expired_at: file.expired_at ? moment(file.expired_at) : null
      }));

    if (customer.intake_form_file && Object.keys(customer.intake_form_file).length){
      filteredFiles.push({
        ...customer.intake_form_file,
        file: customer.intake_form_file,
        type: 'intake_form',
        expired_at: customer.intake_form_file.expired_at ? moment(customer.intake_form_file.expired_at) : null
      });
    }

    if(customer.files && Object.keys(customer.files).length){
      customer.files.map(file => {
        filteredFiles.push({
          ...file,
          expired_at: file.file && file.file.expired_at && file.file.expired_at !== null  ? moment(file.file.expired_at) : moment(file.expires_on),
        });
      });
    }


    const orderedFiles = orderBy(filteredFiles, (file) => file.expired_at ? Date.parse(file.expired_at) : Number.MAX_VALUE, 'desc');
    return map(groupBy(orderedFiles, 'type'), (files, groupType) => {
      const option = fileOptions.find(option => option.type === groupType) || {};
      return {
        ...option,
        files,
      };
    });
  }
);



export const checkinConsumers = createSelector([getConsumers, getCustomerGroups, getImages, getCurrentFacilityId, getPhoneShouldIncludeCountryCode], (consumers, customerGroups, images, facility_id, includeCountryCode) => {
  return consumers.map(consumer => {
    const image = images.find(image => image.id === consumer.image_file_id);
    const queues = saveParse(consumer.queues, []);
    const orders = saveParse(consumer.orders, []);
    const queue = queues.length ? queues.find(q => q.facility_id === facility_id) : {};
    const order = orders.length ? orders.find(o => {
      if(queue && queue.order_id){
        return o.order_id === queue.order_id && o.facility_id === facility_id;
      }else{
        return o.facility_id === facility_id;
      }
    }) : {};
    return {
      ...consumer,
      default_phone_number: buildCompleteInternationalPhoneNumber(consumer.default_phone_number, consumer.default_phone_country_code, includeCountryCode),
      groups: consumer.group_ids && consumer.group_ids.length
       ? consumer.group_ids.split(',').map(group => Object.assign({}, {consumer_group_id: group}, customerGroups.reduce((previous, current) => group == current.id ? current : previous, null)))
       : [],
      src: image ? image.url.original : getImgByGender(consumer.gender),
      ...order,
      ...queue
    };
  });
});


export const getOrderedQualifyingConditions = createSelector([getQualifyingConditions], (qualifyingConditions) => {
  return qualifyingConditions.sort((a, b) => a.name.localeCompare(b.name)).map(item => {return {text: item.name, value: item.id};});
});

export const setCustomerQualifyingCondition = (customerData, formValues, qualifyingConditions) => {
  const qualifying_condition = get(formValues, 'customer.qualifying_condition_id');
  if (!qualifying_condition) {
    customerData.qualifying_condition_id = null;
  } else if (typeof qualifying_condition === 'number') {
    return customerData;
  } else if (typeof qualifying_condition === 'object') {
    const condition = qualifyingConditions.find((condition) => {
      return condition.text === qualifying_condition.text;
    });
    customerData.qualifying_condition_id = condition.value;
  } else {
    customerData.new_qualifying_condition = qualifying_condition;
    delete (customerData.qualifying_condition_id);
  }
  return customerData;
};

export const getDetailedCustomers = createSelector (
  [getCustomers, getImages, getCurrentFacilityId, getCustomerGroups, getIntegrationState, getSalesComplianceSettings, getPhoneShouldIncludeCountryCode],
  (customers, images, facility_id, groups, integrationState, salesSettings, includeCountryCode) => {
    if(customers) {
      // In order to check the date against the expiration we need to remove the timestamp to set to the beginning
      // of the current day
      const date = new Date();
      date.setHours(0,0,0,0);

      // capture the setting for allowing current day expirations for use in changing the check from beginning of the day
      // to end of the day
      const allowSalesOnExpiration = salesSettings.order_allow_sales_on_id_expiration ? salesSettings.order_allow_sales_on_id_expiration.value : null;

      return customers.map(customer => {
        const personImg = getImgByGender(customer.gender);
        const image = images.find(image => image.id === customer.image_file_id);
        const image_url = getImageUrl(image, '30x30', personImg);
        const image_url_medium = getImageUrl(image, '150x150', personImg);
        const queues = saveParse(customer.queues, []);
        const orders = saveParse(customer.orders, []);
        const queue = queues.length ? queues.find(q => q.facility_id === facility_id) : {};
        const order = orders.length ? orders.find(o => {
          if(queue && queue.order_id){
            return o.order_id === queue.order_id && o.facility_id === facility_id;
          }else{
            return o.facility_id === facility_id;
          }
        }) : {};
        const customer_group_ids = (customer.group_ids && customer.group_ids.length > 0) ? customer.group_ids.split(',') : [];
        const has_active_groups = customer_group_ids.some(group_id => groups.some(group => group.id == group_id && group.active));

        //Lets internationalize the number
        customer.default_phone_number = buildCompleteInternationalPhoneNumber(customer.default_phone_number, customer.default_phone_country_code, includeCountryCode);

        if (!integrationState.isPaLeaf) {
          const exp = customer.med_license_expiration;
          let expDate = null;
          if(exp) {
            expDate = new Date(exp);
            // Allowing expirations for the current day, we move the expiration to the beginning of
            // the next day so expirations matching current date will pass as valid
            if(allowSalesOnExpiration)
              expDate.setTime(expDate.getTime() + (60 * 1000 * 60 * 24));
          } else {
            expDate = new Date(null);
          }

          const expired = date > expDate;
          if (expired || !customer.med_license_expiration) {
            customer.med_license_expiration = null;
            customer.med_license_number = null;
          }
        }

        return {
          ...customer,
          image_url,
          image_url_medium,
          image,
          ...order,
          ...queue,
          has_active_groups,
          id: customer.id, // A recent change upstream highlighted the weakness of the customer spread first
          consumer_id: customer.id, // for compatibility - I know it's stupid
        };
      });
    }
  });

export const getServiceFirstDetailedCustomers = createSelector (
  [getCustomers, getCustomersQueueForCustomerListing, getImages, getCurrentFacilityId, getCustomerGroups, getIntegrationState, getSalesComplianceSettings, getPhoneShouldIncludeCountryCode, getOrdersForCustomerListing],
  (customers, queues, images, facility_id, groups, integrationState, salesSettings, includeCountryCode, orders) => {
    if(customers) {
      // In order to check the date against the expiration we need to remove the timestamp to set to the beginning
      // of the current day
      const date = new Date();
      date.setHours(0,0,0,0);

      // capture the setting for allowing current day expirations for use in changing the check from beginning of the day
      // to end of the day
      const allowSalesOnExpiration = salesSettings.order_allow_sales_on_id_expiration ? salesSettings.order_allow_sales_on_id_expiration.value : null;

      return customers.map(customer => {
        const personImg = getImgByGender(customer.gender);
        const image = images.find(image => image.id === customer.image_file_id);
        const image_url = getImageUrl(image, '30x30', personImg);
        const image_url_medium = getImageUrl(image, '150x150', personImg);

        // When loading customers from Solr, we can grab queue & order records directly
        // off the customer. when loading customers from the backend, we have to send
        // follow up calls to grab their potential queue & order records.
        const parsedQueues = customer.queues ? saveParse(customer.queues, []) : queues;
        const parsedOrders = customer.orders ? saveParse(customer.orders, []) : orders;

        const queue = parsedQueues.length ? parsedQueues.find(q => q.facility_id === facility_id && q.consumer_id === customer.id) : {};
        const order = parsedOrders.length ? parsedOrders.find(o => {
          if(queue && queue.order_id){
            return o.order_id === queue.order_id && o.facility_id === facility_id;
          }else{
            return o.facility_id === facility_id && o.consumer_id === customer.id;
          }
        }) : {};
        const customer_group_ids = (customer.group_ids && customer.group_ids.length > 0) ? customer.group_ids.split(',') : [];
        const has_active_groups = customer_group_ids.some(group_id => groups.some(group => group.id == group_id && group.active));

        //Lets internationalize the number
        customer.default_phone_number = buildCompleteInternationalPhoneNumber(customer.default_phone_number, customer.default_phone_country_code, includeCountryCode);

        if (!integrationState.isPaLeaf) {
          const exp = customer.med_license_expiration;
          let expDate = null;
          if(exp) {
            expDate = new Date(exp);
            // Allowing expirations for the current day, we move the expiration to the beginning of
            // the next day so expirations matching current date will pass as valid
            if(allowSalesOnExpiration)
              expDate.setTime(expDate.getTime() + (60 * 1000 * 60 * 24));
          } else {
            expDate = new Date(null);
          }

          const expired = date > expDate;
          if (expired || !customer.med_license_expiration) {
            customer.med_license_expiration = null;
            customer.med_license_number = null;
          }
        }

        return {
          ...customer,
          image_url,
          image_url_medium,
          image,
          ...order,
          ...queue,
          has_active_groups,
          id: customer.id, // A recent change upstream highlighted the weakness of the customer spread first
          consumer_id: customer.id, // for compatibility - I know it's stupid
        };
      });
    }
    return [];
  });

/***
 *  Returns whatever is passed through from create/modify customer.  Check there for available values.
 */
const getCustomerFormValues = (_, props) => props && props.customer ? props.customer : false;

/***
 *  Returns settings object having pulled values from either medical or recreational settings based on:
 *  1)  If the facility is rec or med
 *  2)  If the facility is co located it uses the customer type to determine the source
 *  3)  If the customer is being edited or created, it uses the value from the customer form, otherwise from the customer in redux
 *  *** Returns an object in legacy shape reinstating order_sales_limit_method and order_restrict_sales properties though
 *  we should probably refactor the touch points sooner rather than later. 03/24/2018
 */
export const getFacilitySalesSettingsForCustomer = createSelector(
  [getCustomer, getCustomerFormValues, getSalesComplianceSettings, getActiveFacility, isFeatureEnabled],
  (customer, customerFormValues, salesSettings, facility, isFeatureEnabled) => {
    if(Object.keys(salesSettings).length === 0) return {};
    const customerType = customerFormValues ? get(customerFormValues, 'type', 'medical') : get(customer, 'type', 'medical');
    const facilityType = get(facility, 'retail_type', 'medical');
    const limitType = (facilityType.indexOf('co_located') !== -1) ? customerType : facilityType;
    const salesTypeKey = `order_sales_limits_by_consumer_type.value.${limitType}`;
    const interval = get(salesSettings, `${salesTypeKey}.limit_to.interval`, 1);
    const time_period = get(salesSettings, `${salesTypeKey}.limit_to.time_period`, 'week');

    // Find effective weight limit for current patient:
    const patientLimitOverride = get(customer, 'facility_sales_limit');
    const limitForAllPatients = get(salesSettings, `${salesTypeKey}.limit_to.weight_value`, 0);
    const limitForTerminalIllness = get(salesSettings, `${salesTypeKey}.limit_to.terminal_illness`, 0);
    const isTerminalIllness = Boolean(limitForTerminalIllness && customerType === 'medical' && customer.is_terminal_illness);
    const weight_value = patientLimitOverride || (isTerminalIllness ? limitForTerminalIllness : limitForAllPatients);

    const method = get(salesSettings, `${salesTypeKey}.sales_limit_method`);
    const rules = {
      [method === 'equivalency' ? 'order_sales_limit_equivalency' : 'order_sales_limit_category_options']: {
        value: get(salesSettings, `${salesTypeKey}.method_configuration`)
      }
    };

    //Only look at consumer category overrides if feature is enabled
    const overrideEnabled = isFeatureEnabled('feature_consumer_category_sales_limit_overrides');
    const category_limits_for_customer = (overrideEnabled && method === 'categories') ? get(customer, 'category_sales_limits') : [];

    return Object.assign({}, salesSettings, rules, {
      order_sales_limit_method: {value: method},
      order_restrict_sales: {
        value: {
          interval,
          time_period,
          weight_value
        }
      },
      category_limits_for_customer,
      interval,
      time_period,
      weight_value,
    });
  });

const getCustomerTypeFromProps = (_, props) => get(props, 'customer.type') ? props.customer.type : 'medical';

export const getComplianceLimits = createSelector(
  [getPatientComplianceSettings, getFacilitySalesSettingsForCustomer, getCustomerSettings, getCustomerTypeFromProps],
  (patientSettings, salesSettings, customerSettings, customerTypeFromProps) => {
    const sales_limits = (salesSettings && salesSettings.order_sales_limit_method) ? salesSettings : {};
    const patient_limits = patientSettings && patientSettings.partner_retail_patient_compliance_settings && patientSettings.partner_retail_patient_compliance_settings.value;
    const customerType = customerSettings && customerSettings.type ? customerSettings.type : customerTypeFromProps;

    let displayUom = 'GR';
    if (salesSettings && salesSettings.order_sales_limits_by_consumer_type && salesSettings.order_sales_limits_by_consumer_type.value) {
      displayUom = (salesSettings.order_sales_limits_by_consumer_type.value[customerType] || {display_uom: 'GR'}).display_uom;
    }

    /**
     * Do NOT treat 0 as a limit for patient as false
     * @param value
     * @returns {boolean}
     */
    const isFalsy = (value) => {
      const testForFalse = [undefined, false, null, ''];
      return testForFalse.includes(value);
    };

    const limits_from_global_setting = {
      facility_possession_limit: patient_limits && parseFloat(patient_limits.max_inventory_weight_on_hands),
      facility_sales_limit: sales_limits && parseFloat(sales_limits.weight_value),
      facility_plants_veg_limit: patient_limits && parseInt(patient_limits.max_patient_plants_veg_block),
      facility_plants_flower_limit: patient_limits && parseInt(patient_limits.max_patient_plants_flower_block),
      facility_plants_total_limit: patient_limits && parseInt(patient_limits.max_patient_plants_total_block),
      facility_order_total_limit: sales_limits && sales_limits.interval,
      facility_order_total_limit_period: sales_limits && sales_limits.time_period,
      facility_coupling: patient_limits && Boolean(patient_limits.facility_coupling),
      display_uom: displayUom,
    };

    const merged_limits = {
      facility_possession_limit: customerSettings.facility_possession_limit ? customerSettings.facility_possession_limit : limits_from_global_setting.facility_possession_limit,
      facility_sales_limit: !isFalsy(customerSettings.facility_sales_limit) ? customerSettings.facility_sales_limit : limits_from_global_setting.facility_sales_limit,
      facility_plants_veg_limit: customerSettings.facility_plants_veg_limit ? customerSettings.facility_plants_veg_limit : limits_from_global_setting.facility_plants_veg_limit,
      facility_plants_flower_limit: customerSettings.facility_plants_flower_limit ? customerSettings.facility_plants_flower_limit : limits_from_global_setting.facility_plants_flower_limit,
      facility_plants_total_limit: customerSettings.facility_plants_total_limit ? customerSettings.facility_plants_total_limit : limits_from_global_setting.facility_plants_total_limit,
      facility_coupling: limits_from_global_setting.facility_coupling,
      facility_order_total_limit: limits_from_global_setting.facility_order_total_limit,
      facility_order_total_limit_period: limits_from_global_setting.facility_order_total_limit_period,
      display_uom: limits_from_global_setting.display_uom,
    };

    if (!limits_from_global_setting.facility_coupling) {
      merged_limits.facility_plants_veg_limit = undefined;
      merged_limits.facility_plants_flower_limit = undefined;
      merged_limits.facility_plants_total_limit = undefined;
    }

    if (!limits_from_global_setting.facility_plants_flower_limit) merged_limits.facility_plants_flower_limit = undefined;
    if (!limits_from_global_setting.facility_plants_veg_limit) merged_limits.facility_plants_veg_limit = undefined;
    if (!limits_from_global_setting.facility_plants_total_limit) merged_limits.facility_plants_total_limit = undefined;
    if (!limits_from_global_setting.facility_possession_limit) merged_limits.facility_possession_limit = undefined;
    if (isFalsy(limits_from_global_setting.facility_sales_limit)
      || (!limits_from_global_setting.facility_order_total_limit || !limits_from_global_setting.facility_order_total_limit_period)) {
      merged_limits.facility_sales_limit = undefined;
      merged_limits.facility_order_total_limit = undefined;
      merged_limits.facility_order_total_limit_period = undefined;
    }

    if (salesSettings && salesSettings.order_sales_limit_method && salesSettings.order_sales_limit_method.value === 'categories') {
      merged_limits.facility_order_total_limit_period = undefined;
      merged_limits.facility_order_total_limit = undefined;
      merged_limits.facility_sales_limit = undefined;
    }

    return{
      ...merged_limits,
      default_values: {...limits_from_global_setting},
      show_compliance_limits_block: !!(merged_limits.facility_plants_flower_limit
      || merged_limits.facility_plants_veg_limit
      || merged_limits.facility_plants_total_limit
      || merged_limits.facility_possession_limit
      || merged_limits.facility_sales_limit)
    };
  });

export const getFormFileTypes = createSelector(
  [getPatientSettings,getCustomer,getCustomerFormValues],
  (settings,customer,customerFormValues) => {
    const defaultTypes = [medical_id, driver_license, extended_sales_limit, intake_form];
    const customerType = customerFormValues ? get(customerFormValues, 'type', 'medical') : get(customer, 'type', 'medical');
    if (settings && settings.allowTempPaperwork && customerType !== 'recreational') {
      return defaultTypes.concat(temp_id);
    }
    return defaultTypes;
  }
);


/**
 * Returning information ablut Cargiver from Oracle
 * @type {Reselect.Selector<any, any>}
 */
export const getOracleCaregiverForCustomer = createSelector(
  getOracleCaregiver,
  (caregiver) => {

    return !isEmpty(caregiver)
      ? _caregiverOracleDataTransformer(caregiver)
      : {};
  });


/**
 * Method which transform Oracle caregiver data to suitable format
 * @param data
 * @returns {[]}
 * @private
 */
const _caregiverOracleDataTransformer = (data) => {
  const name = data.caregiver_name ? data.caregiver_name.split(' ') : ['', ''];

  return {
    ..._caregiverDataTransformer(data),
    first_name: name[0] || data.first_name,
    last_name: name[1] || data.last_name,
    state_integration_registry_id: data.caregiver_id,
    addresses: [{
      city: data.caregiver_city,
      postal_code: data.caregiver_zip,
      province_code: data.caregiver_state,
      street_address_1: data.caregiver_address1,
      street_address_2: data.caregiver_address2,
    }],
  };
};

/**
 * Method which transform caregiver data to suitable format
 * @param data
 * @returns {[]}
 * @private
 */
const _caregiverDataTransformer = (data) => ({
  ...data,
  birth_date: data.birth_date ? moment(data.birth_date) : null,
});


export const getModifyCustomerInitialValues = createSelector(
  [getCustomer, isLeafIntegrator, getComplianceLimits, getOracleCaregiverForCustomer, getOrderedPhysicians, getIntegrationState, getFacilityState, getPhoneShouldIncludeCountryCode],
  (customer, isLeafIntegrator, complianceLimits, caregiver, physicians, integrationState, facilityState, includeCountryCode) => {
    const customerFields = pick(customer, [
      'active', 'physician_id', 'first_name', 'middle_name', 'last_name', 'goes_by', 'email_address', 'preferred_contact', 'gender',
      'frequency_of_use', 'referral_source', 'primary_facility_id', 'physician_name', 'physician_license',
      'physician_address', 'physician_notes', 'qualifying_condition_id', 'intake_form_file_id', 'intake_form_file',
      'image_file_id', 'image_file', 'diagnosis', 'state_integration_tracking_id', 'partner_state_expiration_date', 'extra_data',
      'personal_use_producer', 'renewal', 'physician_recommendation_date', 'refills_authorized', 'is_terminal_illness','ssn_last_4', 'identification_method'
    ]);
    const physician = physicians.find(physician => physician.id === customer.physician_id);
    //On UI we store both files and ids together
    const files = (customer.files || []).map(file => ({
      ...file,
      expired_at: file.expires_on ? moment(file.expires_on) : null,
    }));

    let caregiverInfo = {...customer.caregivers};

    if (!isEmpty(caregiver) && caregiverInfo && caregiverInfo.length > 0) {
      caregiverInfo[0] = _caregiverOracleDataTransformer({
        ...caregiverInfo[0],
        ...caregiver
      });
    }

    caregiverInfo = map(caregiverInfo, _caregiverDataTransformer) || [];

    caregiverInfo.forEach((caregiver, index) => {
      caregiverInfo[index].phone = buildCompleteInternationalPhoneNumber(caregiver.phone, caregiver.phone_country_code, includeCountryCode);
    });

    const idsDefault = [{
      type: medical_id,
      file_type: FILE_TYPES[COMMON_PRIVATE_IMAGE].type,
      identification_number: '',
    }];

    // Appends additional key/value pairings to the customer ids data
    const customerIds = (idsData) => {
      if (!isLeafIntegrator) return idsData;

      return idsData.reduce((revisedIds, idData) => {
        if (!idData.hasOwnProperty('active')) {
          revisedIds.push(idData);
          return revisedIds;
        }

        revisedIds.push({
          ...idData,
          mmid_status: idData.active ? 'Active' : 'Inactive'
        });

        return revisedIds;
      }, []);
    };

    const registrations = (customer.registrations || []).map((registration) => {
      return {
        ...registration,
        event_on: registration.event_on ? moment.utc(registration.event_on) : undefined,
        type: canadian_registration_form,
      };
    });

    const isCali = facilityState.toLowerCase() === 'ca' || integrationState.isCaMetrc;
    const isOklahoma = facilityState.toLowerCase() === 'ok';

    let type = customer.type;
    switch (true) {
    case isCali:
      type = customer.is_not_mmic ? customer.type : MMIC;
      break;
    case isOklahoma:
      type = customer.is_disabled_veterans ? DISABLED_VETERANS : customer.type;
      break;
    }

    return {
      isLeafIntegrator,
      customer: Object.assign({}, customerFields, {
        physician,
        active: customer.active ? '1' : '0',
        personal_use_producer: String(customer.personal_use_producer || 0),
        type,
        in_state: customer.in_state ? '1' : '0',
        tax_exempt: customer.tax_exempt ? '1' : '0',
        preferred_csr: customer.preferred_csr ? customer.preferred_csr + '' : '',
        extra_data: customer.extra_data,
        birth_date: customer.birth_date ? moment(customer.birth_date) : null,
        physician_recommendation_date: customer.physician_recommendation_date ? moment(customer.physician_recommendation_date) : null,
        partner_state_expiration_date: customer.partner_state_expiration_date ? moment(customer.partner_state_expiration_date) : null,
        patient_certification_status: customer.patient_certification_status ? customer.patient_certification_status : null,
        number: customer.phone_numbers && customer.phone_numbers.length
          ? customer.phone_numbers.reduce((previous, current, index) => index === 0 ? buildCompleteInternationalPhoneNumber(current.number, current.phone_country_code, includeCountryCode) : previous, '')
          : '',
        number_id: customer.phone_numbers && customer.phone_numbers.length
          ? customer.phone_numbers.reduce((previous, current, index) => index === 0 ? current.id : previous, null)
          : null,
        // The group_ids assignment was previously commented-out, but is being un-commented for MJP-3927 to prevent an invalid
        // value from appearing in the group_ids Multiselect input. The invalid Multiselect value causes an attempt
        // to save changes to fail. Hopefully, it was not commented-out to fix another bug.
        group_ids: customer.groups && customer.groups.length
          ? customer.groups.filter(group => !group.is_rewards_program_group).map(group => group.id)
          : [],
        date_provider_can_switch: customer.date_provider_can_switch ? moment(customer.date_provider_can_switch) : null,
        // tags: customer.tags,
        tags: customer.tags
          ? customer.tags.map(tag => tag.tag_name)
          : [],
      }),
      caregiverInfo,
      delete_caregiver_ids: [],
      delete_address_ids: [],
      limitsInfo: {
        facility_order_total_limit_period: complianceLimits && complianceLimits.facility_order_total_limit_period,
        facility_plants_total_limit: complianceLimits && complianceLimits.facility_coupling && complianceLimits.facility_plants_total_limit || undefined,
        facility_plants_flower_limit: complianceLimits && complianceLimits.facility_coupling && complianceLimits.facility_plants_flower_limit || undefined,
        facility_plants_veg_limit: complianceLimits && complianceLimits.facility_coupling && complianceLimits.facility_plants_veg_limit || undefined,
        facility_possession_limit: complianceLimits && complianceLimits.facility_possession_limit,
        facility_sales_limit: customer.facility_sales_limit,
        facility_order_total_limit: complianceLimits && complianceLimits.facility_order_total_limit,
      },
      // addresses: customer.addresses && customer.addresses.length ? customer.addresses : [{}, {}],
      addresses: customer.addresses && customer.addresses.length ? customer.addresses : [{}, {}],
      ids: ((customer.ids && customer.ids.length)
        ? customerIds(customer.ids)
        : ((!customer.ids || !customer.ids.length) && (!customer.expired_ids || !customer.expired_ids.length)) // Include default only if not ids AND not expired ids
            ? idsDefault
            : []
      ).concat(customer.expired_ids || [], files).map(file => ({
        ...file,
        effective_at: file.effective_at ? moment(file.effective_at) : null,
        expired_at: file.expired_at ? moment(file.expired_at).format('MM/DD/YYYY') : null,
      })),
      rewards_group_name: customer.groups && customer.groups.reduce((acc, group) => group.is_rewards_program_group ? group.group_name : acc, ''),
      rewards_group_ids: customer.groups && customer.groups.filter(group => group.is_rewards_program_group).map(group => group.id),
      registrations,
    };
  }
);

/**
 * NOTE: This is deprecated and should be removed when the "feature_pa_oracle_patient_sync" is removed.
 * The replacement for it is named "getOraclePatientAsCustomer", found below
 */
export const getOraclePatientAsCustomerDeprecated = createSelector(
  getOraclePatient,
  (patient) => {
    if (!patient) {
      return {};
    }

    const [firstName, lastName] = (get(patient, 'patient_name') || ' ').split(' ');
    const getBirthDate = (patient) => {
      const birthDate = get(patient, 'consumer.birth_date') || patient.patient_dob_yyyy_mm_dd;

      return moment(birthDate);
    };

    const mapCustomer = (patient) => ({
      type: get(patient, 'consumer.type'),
      new_note: patient.dispensary_considerations,
      last_name: lastName || get(patient, 'consumer.last_name', ''),
      first_name: firstName || get(patient, 'consumer.first_name', ''),
      middle_name: get(patient, 'consumer.middle_name', ''),
      birth_date: getBirthDate(patient),
      physician_name: patient.practitioner_name,
      physician_notes: patient.limitations_restrictions,
      physician_address: patient.practitioner_address1,
      state_integration_tracking_id: patient.patient_id,
    });

    const mapAddresses = (patient) => ([{
      city: get(patient, 'patient_city'),
      postal_code: get(patient, 'patient_zip'),
      province_code: get(patient, 'patient_state'),
      street_address_1: get(patient, 'patient_address1'),
      street_address_2: get(patient, 'patient_address2'),
    }]);

    const mapIDs = (patient) => ([{
      type: medical_id,
      file_type: FILE_TYPES[COMMON_PRIVATE_IMAGE].type,
      identification_number: patient.patient_card_number,
    }]);

    return {
      ids: mapIDs(patient),
      customer: mapCustomer(patient),
      addresses: mapAddresses(patient),
    };
  });

/**
 * Merges different sources of Oracle patient data to eventually feed the initialValues for the patient create form
 */
export const getOraclePatientAsCustomer = createSelector(
  getOraclePatient, getOraclePatientSupplementalInfo,
  (patient, supplementalPatientInfo) => {
    if (!patient || !supplementalPatientInfo) {
      return {};
    }

    const mapCustomer = (patient) => ({
      type: get(patient, 'consumer.type'),
      new_note: patient.dispensary_considerations,

      last_name: supplementalPatientInfo.last_name,
      first_name: supplementalPatientInfo.first_name,
      middle_name: supplementalPatientInfo.middle_name,
      birth_date: moment(supplementalPatientInfo.birth_date),
      email_address: supplementalPatientInfo.email_address,
      state_integration_tracking_id: supplementalPatientInfo.state_integration_tracking_id,
      patient_certification_status: supplementalPatientInfo.patient_certification_status,

      physician_name: patient.practitioner_name,
      physician_notes: patient.limitations_restrictions,
      physician_address: patient.practitioner_address1,

    });

    const mapAddresses = (patient) => ([{
      city: get(patient, 'patient_city'),
      postal_code: get(patient, 'patient_zip'),
      province_code: get(patient, 'patient_state'),
      street_address_1: get(patient, 'patient_address1'),
      street_address_2: get(patient, 'patient_address2'),
    }]);

    const ids = [{
      type: medical_id,
      file_type: FILE_TYPES[COMMON_PRIVATE_IMAGE].type,
      identification_number: supplementalPatientInfo.mmid,
      expired_at: moment(supplementalPatientInfo.mmid_expiration).format('MM/DD/YYYY'),
      mmid_status: supplementalPatientInfo.mmid_status
    }];

    return {
      ids,
      customer: mapCustomer(patient),
      addresses: mapAddresses(patient),
    };
  });

export const getCustomerAddressesForSelect = createSelector([getCustomer], (customer) => {
  const fields = ['street_address_1', 'street_address_2', 'city', 'province_code', 'postal_code', 'country_code'];
  return customer.addresses && Array.isArray(customer.addresses) ? customer.addresses.reduce((acc, address) => {
    const add = [];
    fields.forEach((field) => {
      if(address[field] !== null && (typeof address[field] == 'string' && address[field].trim() !== '')) add.push(address[field]);
    });
    if(add.length > 0){
      acc.push({
        value: address.id,
        text: add.join(', ')
      });
    }
    return acc;
  }, []) : [];
});

export const getLastUpdatedComment = createSelector([getOraclePatient], (patient) => {
  return patient && patient.allow_dispensary_recommendation && patient.last_updated_comment;
});


export const getCustomerSolrReworkQueryAndFilter = (query, filter, customerSolrReworkEnabled) => {
  let updatedQueryString = query;
  let updatedFilter = filter;

  if (customerSolrReworkEnabled && query) {
    const clearedQuery = query.toLowerCase().trim();
    const convertedTerms = convertTerms(clearedQuery);
    updatedQueryString = '';
    updatedFilter = `(first_name: (${convertedTerms}) OR last_name: (${convertedTerms}) 
    OR med_license_number_latest: (${convertedTerms}) OR patient_state_id: (${convertedTerms}) 
    OR caregiver_first_name: (${convertedTerms}) OR caregiver_last_name: (${convertedTerms})
    OR caregiver_state_id: (${convertedTerms})) AND ${filter}`;
  }

  return { updatedQueryString, updatedFilter };
};

export const getSolrCoreName = createSelector(
  [isFeatureEnabled],
  (isFeatureEnabled) => {
    return isFeatureEnabled('feature_solr_rework_new_customers') ? 'new_customers' : 'customers';
  }
);
