import {createSelector} from 'reselect';
import get from 'lodash.get';
import {PREPACK_CATEGORIES, PREPACK_CATEGORIES_CANADA} from '../../constants/prepacks';
import {isCanadaFacility} from '../facility/getFacilityCountry';
import {getCategories} from '../categorySelectors';
import {getIsHempFacility} from '../coreSettingsSelectors';
import * as itemNames from '../../constants/itemNames';
import * as dataNames from '../../constants/dataNames';
import * as UOMS from '../../constants/uoms';

const GR = UOMS.GR;
const getPrepackWeights = state => state.prepackWeights;
const getUsedFacilityPrepackWeights = state => state.usedFacilityPrepackWeights;
const getComplianceSettings = state => state.complianceSettings;
const getPrepackFacilityWeights = state => state.prepackFacilityWeights;

export const isSeedPackagingAllowed = createSelector(
  [getComplianceSettings],
  (settings) => Boolean(settings && settings.inv_packaging_seeds && settings.inv_packaging_seeds.value)
);

export const isInactivationReasonDisplayed = createSelector(
  [getComplianceSettings],
  settings => Boolean(settings && settings.inv_display_inactivation_reason && settings.inv_display_inactivation_reason.value)
);

export const getSortedPrepackWeights = createSelector([getPrepackWeights], prepackWeights => prepackWeights.sort((a,b) => {return a.weight_base < b.weight_base ? -1 : 1;}));

export const getPrepackWeightsWithHasInventory = createSelector([getSortedPrepackWeights, getPrepackFacilityWeights, getUsedFacilityPrepackWeights],
  (prepackWeights, prepackFacilityWeights, usedWeights) => {
    const activeFacilityWeightIds = prepackFacilityWeights ? prepackFacilityWeights.map(weight => weight.id ? weight.prepack_weight_id : null) : [];
    return prepackWeights.map(weight => {
      const has_inventory = Boolean(usedWeights.indexOf(weight.id) > -1) && Boolean(activeFacilityWeightIds.indexOf(weight.id) > -1);
      return Object.assign({}, weight, {has_inventory});
    });
  });

export const getCategoryOptionsForPrepacks = createSelector([getCategories, isCanadaFacility], (categories, isCanada) => {
  const categoryCodes = isCanada ? PREPACK_CATEGORIES_CANADA : PREPACK_CATEGORIES;
  return categories
    .filter(cat => categoryCodes.includes(cat.category_code))
    .map(cat => ({
      value: cat.id,
      text: cat.name,
    }));
});

export const getActiveTab = (state) => get(state[itemNames.applicationValues], 'activeTab', UOMS.GR);

const getUoms = (state) => state[dataNames.uoms];

export const getUomsForTabs = createSelector([getUoms, getIsHempFacility], (uoms, isHempFacility) => {
  if (!isHempFacility) return uoms.filter(uom => uom.uom_code === GR);

  const validUomTypes = ['weight', 'volume'];
  // See MJP-26974 and MJP-26530
  // We're filtering TBSP and TSP here instead of removing is from contstants because we may needs those UOMs elsewhere, but we don't want them for prepacks
  // May need a more elegant solution later: like marking a UoM as "valid for prepack" on the uom object.
  const isValidUomType = (uom) => validUomTypes.indexOf(uom.uom_type) !== -1 && uom.uom_code !== 'TSP' && uom.uom_code !== 'TBSP';
  return uoms.filter(isValidUomType).sort((a, b) => {
    const aRatio = parseFloat(a.conversion_ratio);
    const bRatio = parseFloat(b.conversion_ratio);
    return aRatio < bRatio
      ? 1
      : bRatio < aRatio
        ? -1
        : 0;
  });
});

export const getSelectedUom = createSelector([getActiveTab], (activeTab) => {
  return activeTab;
});

/**
 * Returns initial values for prepacks and uses activeTab to trigger the re-running of the selector on tab change.
 * DO NOT DELETE getActiveTab even though it seems pointless in the context of the selector code.
 * If activeTab = KG for example, then this will only return prepacks in KG.
 * @type {Reselect.Selector<any, any>}
 */
export const getInitialValues = createSelector([getSortedPrepackWeights, isSeedPackagingAllowed, isInactivationReasonDisplayed, getPrepackFacilityWeights, getCategoryOptionsForPrepacks, getSelectedUom, getActiveTab],
  (prepackWeights, packaging_seeds, display_inactivation_reason, prepackFacilityWeights, categories, uom, activeTab) => {

    return {
      newQty: null,
      packaging_seeds,
      display_inactivation_reason,
      sizes: prepackWeights.filter((weight) => weight.uom === activeTab)
        .map((weight) => {
          const facilityPrepackWeight = prepackFacilityWeights.find((facilityWeight) => facilityWeight.prepack_weight_id === weight.id && facilityWeight.id);
          if(facilityPrepackWeight){
            const appliesTo = ['applies_to_sales', 'applies_to_purchases'].reduce((acc, option) => {
              if(facilityPrepackWeight[option]){
                acc.push(option);
              }
              return acc;
            }, []);
            const categories = facilityPrepackWeight.categories.map((category) => category.category_id);
            return Object.assign({}, weight, facilityPrepackWeight, {appliesTo, categories, isFacility: 1, id: weight.id});
          }
          return Object.assign({}, weight, {categories: [], appliesTo: [], isFacility: 0, active: 0});
        })
    };
  });

export const getPayload = (formValues, uom) => {
  return {
    prepack_weights: formValues.sizes.filter((size) => {
      return size.active;
    }).map((size) => {
      return {
        prepack_weight_id: size.id,
        applies_to_sales: Boolean(size.appliesTo.find((appliesTo) => appliesTo.value === 'applies_to_sales' || appliesTo === 'applies_to_sales')),
        applies_to_purchases: Boolean(size.appliesTo.find((appliesTo) => appliesTo.value === 'applies_to_purchases' || appliesTo === 'applies_to_purchases')),
        categories: size.categories.map((category) => get(category, 'value', category)),
        uom
      };
    })
  };
};

export const getPayloadForPrepackWeights = (formValues, appliesToProductMasters) => {
  return {
    ids: formValues.sizes.filter((size) => {
      return Boolean(size.applies_to_product_masters) === Boolean(appliesToProductMasters);
    }).map((size) => size.id),
    applies_to_product_masters: Boolean(appliesToProductMasters),
  };
};
