import React from 'react';
import {withRouter} from 'react-router';
import { goBack } from 'react-router-redux';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import {change, getFormValues, isDirty, isValid} from 'redux-form';
import get from 'lodash.get';
import FormWrapper from '../../common/form/FormWrapper';
import {PRODUCT_MASTER_FORM} from '../../../constants/forms';
import ProductMasterForm from './ProductMasterForm';
import * as apiActions from '../../../actions/apiActions';
import * as dataNames from '../../../constants/dataNames';
import * as itemNames from '../../../constants/itemNames';
import {addMessage} from '../../../actions/systemActions';
import {setData, unsetData} from '../../../actions/dataActions';
import {setItem, unsetItem} from '../../../actions/itemActions';
import {getIntegrationCategoriesData} from '../../../actions/integrationActions';
import {getIntegrationState} from '../../../selectors/integration/integrationSelectors';
import {
  getCreateProductInitialValues,
  getModifyProductInitialValues,
  getFormSubcategories,
  //getRequiredFieldsFromSubCategory,
  subCategoryMapErrors,
  getUomOptions,
  getFormCategory,
  getFormSubCategory,
  getSaveProductPayload
} from '../../../selectors/forms/productFormSelectors';
import {getMetrcFilteredCategories} from '../../../selectors/categorySelectors';
import {getMetrcCategoryFromOrgSubcategoryId, isMetrcIntegrator} from '../../../selectors/integration/metrcSelectors';
import {getProductTypeOptions} from '../../../selectors/productsSelector';
import {isFacilityGroupMaster} from '../../../selectors/facilityGroupsSharingSelectors';
import {oklahomaSubcategories} from '../../../constants/subcategoryGroups';
import {isMedicatedWeightAutoSuffix, isAppendBrandToItemMasterName} from '../../../selectors/complianceSettingsSelectors';

// TODO: Check what middleware is used in productFormMiddleware. Try to add directly in page on child components

export class ProductMasterPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubcategoryChange = this.onSubcategoryChange.bind(this);
    this.state = {
      ready: false,
      isWaInventoryMapped: false,
      saving: false,
      isModify: false
    };
    this.saveProductMaster = this.saveProductMaster.bind(this);
    this.createDuplicate = this.createDuplicate.bind(this);
  }

  componentDidMount() {
    // unsetItems & unsetData
    this.props.actions.unsetItem(itemNames.product);
    this.props.actions.unsetData(dataNames.pricingWeights);

    // Load data
    const id = this.props.params.id || '';
    const promises = [];
    if (id) {
      this.setState({ isModify: true });
      promises.push(
        // If modify (id exists) load item_master
        this.props.actions.getItem(`/api/item_masters/${id}`, itemNames.product, undefined, { detailed: 1, edit_mode: 1 })
      );
    }
    promises.push(
      this.props.actions.getUnpaginatedData('/api/categories?is_only_active=1', dataNames.categories)
        .then(() => this.props.actions.getIntegrationCategoriesData()),
      this.props.actions.getUnpaginatedData('/api/uoms/inventory', dataNames.uoms),
      this.props.actions.getUnpaginatedData('/api/strains/by_organization', dataNames.organizationStrains),
      this.props.actions.getUnpaginatedData('/api/strains/by_facility', dataNames.facilityStrains),
      this.props.actions.getUnpaginatedData('/api/partners', dataNames.partners, undefined, { purchase_from: 1, exclude_internal_duplicates: 1 }),
      this.props.actions.getUnpaginatedData('/api/brands', dataNames.brands),
      this.props.actions.getUnpaginatedData('/api/pricing_weights', dataNames.productMasterPricingWeights, null, { applies_to_product_masters: 1 }),
      this.props.actions.getUnpaginatedData('/api/pricing_groups', dataNames.pricingGroups, null, { detailed: 1 }),
      this.props.actions.getUnpaginatedData('/api/tax_profiles', dataNames.taxProfiles),
      this.props.actions.getUnpaginatedData('/api/pricing_multipliers', dataNames.pricingMultipliers, null, {active: 1, with_relations: ['priceLists']})
    );
    Promise.all(promises)
      .then(() => {
        //Check Washington integration inventory mapping
        if (this.props.integrationState.isWaLeaf) {
          this.props.actions
            .getUnpaginatedData('/api/leaf/get_wa_inventory_mapping/' + id, dataNames.getWaInventoryMapping)
            .then(() => {
              if (get(this.props, 'waInventoryMapping.0.id')) {
                this.setState({ isWaInventoryMapped: true });
              }
            });
        }
      })
      .finally(() => {
        this.setState({ready: true});
      });
  }

  saveProductMaster(data) {
    // Remove this check when all fields are available on the new Product Master form
    // Until then we cannot use this form to save active product masters
    // if (!data.is_draft) {
    //   this.props.actions.addMessage(messageTypes.warning, 'products.form.action.saveDisabled');
    //   return Promise.resolve();
    // }
    // End check
    const id = get(this.props.product, 'id', get(data, 'id'));
    if (id) {
      return this.props.actions.putItem(`/api/item_masters/${id}`, data, itemNames.product, { success: 'product.modify.success', failed: 'product.modify.failed' });
    }
    return this.props.actions.postItem('/api/item_masters', data, itemNames.product, { success: 'products.create.success', failed: 'products.create.failed' })
      .then(() => this.setState({ isModify: true }));
  }

  // Clear 'non-copyable' values from form
  createDuplicate() {
    const { name } = this.props.formValues;
    const { change } = this.props.actions;
    this.setState({ isModify: false });

    change(PRODUCT_MASTER_FORM, 'id', null);

    change(PRODUCT_MASTER_FORM, 'name', `Copy of ${name}`);
    if (this.props.formValues.attribute_lists) change(PRODUCT_MASTER_FORM, 'attribute_lists', null);
    if (this.props.formValues.has_items) change(PRODUCT_MASTER_FORM, 'has_items', null);
    if (this.props.formValues.inventory_attributes) change(PRODUCT_MASTER_FORM, 'inventory_attributes', null);
    if (this.props.formValues.item_number) change(PRODUCT_MASTER_FORM, 'item_number', null);
    if (this.props.formValues.item_number_prefix) change(PRODUCT_MASTER_FORM, 'item_number_prefix', null);
    if (this.props.formValues.online_availability) change(PRODUCT_MASTER_FORM, 'online_availability', null);
    if (this.props.formValues.product_images) change(PRODUCT_MASTER_FORM, 'product_images', null);
    if (this.props.formValues.purchasing_attributes) change(PRODUCT_MASTER_FORM, 'purchasing_attributes', null);
    if (this.props.formValues.sales_attributes) change(PRODUCT_MASTER_FORM, 'sales_attributes', null);
    if (this.props.formValues.vendors) change(PRODUCT_MASTER_FORM, 'vendors', null);

    // Scroll window to top
    window.scrollTo(0, 0);
  }

  onSubmit(formData) {
    this.setState({ saving: true });

    const id = get(formData, 'id', null);

    if (formData.action === 'delete' && id) {
      return this.props.actions.deleteItem(`/api/item_masters/${id}`,
        null,
        {failed: 'item_masters.delete.failed'},
        undefined,
        undefined
      );
    }

    const data = getSaveProductPayload(formData, this.state.isModify ? 'modify' : 'create', this.props.categories, this.props.subcategories, PRODUCT_MASTER_FORM);
    if (this.props.integrationState.isMdMetrc || this.props.integrationState.isOrMetrc) {
      data['configuration_validated'] = 1; // Because some of metrcMD is breaking fundamental validation
    }

    if (formData.action === 'inactivate' && id) {
      return this.props.actions.putItem(`/api/item_masters/${id}`, {active: 0}, itemNames.product, { success: 'product.modify.success', failed: 'product.modify.failed' });
    }else if (formData.action === 'activate' && id) {
      return this.props.actions.putItem(`/api/item_masters/${id}`, {active: 1}, itemNames.product, { success: 'product.modify.success', failed: 'product.modify.failed' });
    }

    // Set is_draft flag depending on action
    data['is_draft'] = formData.action === 'saveProgress';

    return this.saveProductMaster(data)
      .then(() => {
        if (formData.action === 'saveAndDuplicate') {
          this.createDuplicate();
        }
      })
      .finally(() => this.setState({ saving: false }));
  }

  redirect () {
    this.props.actions.push('/retail/pricing-classes/active');
  }

  onSubcategoryChange(id) {
    const { uoms, facility, selectedSubCategory } = this.props;
    const { change } = this.props.actions;
    //const metrcSubCategory = this.props.getMetrcCategoryFromOrgSubcategoryId({ id });
    //const requiredFields = getRequiredFieldsFromSubCategory(metrcSubCategory);
    //change(PRODUCT_MASTER_FORM, 'default_uom', '');
    //change(PRODUCT_MASTER_FORM, 'uom_type', '');

    //From Middleware
    const province_code = get(facility, 'province_code', '');
    if(province_code && province_code.toLowerCase() === 'ok' && oklahomaSubcategories.indexOf(get(selectedSubCategory, 'subcategory_code'))) {
      change('default_uom', 'GR');
      change('uom_type', 'weight');
    } else if (uoms.length === 1) {
      change('default_uom', uoms[0].uom_code);
      change('uom_type', uoms[0].uom_type);
    }

    //change(PRODUCT_MASTER_FORM, 'required_fields', requiredFields);
  }

  render() {
    const {
      initialValues,
      formValues,
      isDirty,
      isValid,
      productTypeOptions,
      categories,
      subcategories,
      subCategoryMapErrors,
      integrationState,
      isMasterFacility,
      allUoms,
      uoms,
      brands,
      isMedicatedWeightAutoSuffix,
      isAppendBrandToItemMasterName
    } = this.props;
    const { change } = this.props.actions;
    const {isWaInventoryMapped, isModify} = this.state;

    const name = get(formValues, 'name');
    let title = isModify ? I18n.t('products.modify.title') : I18n.t('products.create.title');
    if (name) {
      title += ' - ' + name;
    }

    const titleDescription = isAppendBrandToItemMasterName && isMedicatedWeightAutoSuffix
      ? I18n.t('products.form.titleInfoNameAutomationBrandMedWeight')
      : isAppendBrandToItemMasterName
        ? I18n.t('products.form.titleInfoNameAutomationBrand')
        : isMedicatedWeightAutoSuffix
          ? I18n.t('products.form.titleInfoNameAutomationMedWeight')
          : null;

    return (
      <FormWrapper
        className='product-master-page'
        title={title}
        titleDescription={titleDescription}
        sticky={true}
        localize={false}
        formName={PRODUCT_MASTER_FORM}
        goBack={this.props.actions.goBack}
      >
        <ProductMasterForm
          initialValues={initialValues}
          enableReinitialize={true}
          keepDirtyOnReinitialize={true}
          onSubmit={this.onSubmit}
          isWaInventoryMapped={isWaInventoryMapped}
          productTypeOptions={productTypeOptions}
          categories={categories}
          subcategories={subcategories}
          subCategoryMapErrors={subCategoryMapErrors}
          onSubcategoryChange={this.onSubcategoryChange}
          integrationState={integrationState}
          isMasterFacility={isMasterFacility}
          allUoms={allUoms}
          uoms={uoms}
          brands={brands}
          isMedicatedWeightAutoSuffix={isMedicatedWeightAutoSuffix}
          isAppendBrandToItemMasterName={isAppendBrandToItemMasterName}
          change={change}
          isDirty={isDirty}
          isValid={isValid}
        />
      </FormWrapper>
    );
  }
}

ProductMasterPage.propTypes = {
  actions: PropTypes.shape({
    getItem: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    addMessage: PropTypes.func.isRequired,
  }).isRequired,
  params: PropTypes.object,
  initialValues: PropTypes.object,
  formValues: PropTypes.object,
  isDirty: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  integrationState: PropTypes.object,
  categories: PropTypes.array.isRequired,
  subcategories: PropTypes.array,
  hasMetrcSettings: PropTypes.bool.isRequired,
  getMetrcCategoryFromOrgSubcategoryId: PropTypes.func.isRequired,
  metrcCategoryMappings: PropTypes.array,
  metrcCategories: PropTypes.array,
  weedmapsCategoryMappings: PropTypes.array,
  isolocityCategoryMappings: PropTypes.array,
  productTypeOptions: PropTypes.array.isRequired,
  subCategoryMapErrors: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
  selectedCategory: PropTypes.object.isRequired,
  selectedSubCategory: PropTypes.object.isRequired,
  isMasterFacility: PropTypes.bool.isRequired,
  allUoms: PropTypes.array.isRequired,
  uoms: PropTypes.array.isRequired,
  facility: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  brands: PropTypes.array.isRequired,
  isMedicatedWeightAutoSuffix: PropTypes.bool.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    // Selectors marked with ** use a hardcoded form selector (either directly or in another selector).
    // Pass on the form in props to make sure the value is retrieved from the correct form (defaults to PRODUCT_FORM if not supplied)
    initialValues: ownProps.params.id ? getModifyProductInitialValues(state, {form: PRODUCT_MASTER_FORM}) : getCreateProductInitialValues(state, {form: PRODUCT_MASTER_FORM}), // **
    formValues: getFormValues(PRODUCT_MASTER_FORM)(state),
    isDirty: isDirty(PRODUCT_MASTER_FORM)(state),
    isValid: isValid(PRODUCT_MASTER_FORM)(state),
    integrationState: getIntegrationState(state),
    categories: getMetrcFilteredCategories(state),
    subcategories: getFormSubcategories(state, {form: PRODUCT_MASTER_FORM}), // **
    subCategoryMapErrors: subCategoryMapErrors(state, {form: PRODUCT_MASTER_FORM}), // **
    selectedCategory: getFormCategory(state, {form: PRODUCT_MASTER_FORM}),
    selectedSubCategory: getFormSubCategory(state, {form: PRODUCT_MASTER_FORM}),
    hasMetrcSettings: isMetrcIntegrator(state),
    getMetrcCategoryFromOrgSubcategoryId: (subcategory) => getMetrcCategoryFromOrgSubcategoryId(state, subcategory),
    metrcCategoryMappings: state[dataNames.metrcCategoryMappings],
    metrcCategories: state[dataNames.metrcCategories],
    weedmapsCategoryMappings: state[dataNames.weedmapsCategoryMappings],
    isolocityCategoryMappings: state[dataNames.isolocityCategoryMappings],
    productTypeOptions: getProductTypeOptions(state),
    isMasterFacility: isFacilityGroupMaster(state),
    allUoms: state[dataNames.uoms],
    uoms: getUomOptions(state, {form: PRODUCT_MASTER_FORM}),
    facility: state[itemNames.facility],
    product: state[itemNames.product],
    partners: state[dataNames.partners],
    brands: state[dataNames.brands],
    isMedicatedWeightAutoSuffix: isMedicatedWeightAutoSuffix(state),
    isAppendBrandToItemMasterName: isAppendBrandToItemMasterName(state)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...apiActions,
    setData,
    setItem,
    unsetData,
    unsetItem,
    getIntegrationCategoriesData,
    change,
    addMessage,
    goBack
  };
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProductMasterPage)
);
