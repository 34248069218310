import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { FieldArray } from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Button, ButtonToolbar, Col, Row} from 'react-bootstrap';
import {FaPlus} from 'react-icons/fa';
import QuickSetup from './QuickSetup';
import LevelZeroLocations from './LevelZeroLocations';
import InternationalDecimalInput from '../../form/InternationalDecimalInput';
import InternationalCurrencyInput from '../../form/InternationalCurrencyInput';

const locationNames = [
  { storage: 'building', sale: 'building' },
  { storage: 'room', sale: 'sales-location' },
  { storage: 'zone', sale: 'area' },
  { storage: 'section', sale: 'sub-section' },
];

class CreateStorageLocationForm extends React.PureComponent {
  constructor (props, context) {
    super(props, context);
    this.createLocation = this.createLocation.bind(this);
    this.getLevelCounts = this.getLevelCounts.bind(this);
    this.state = {
      touched: false,
    };
  }

  // touch all invalid fields to prompt error displays
  componentDidUpdate (prevProps) {
    if (this.props.valid || !this.props.formErrors) { return; }
    this.props.touch(...this.findPropPath(this.props.formErrors, 'name'));
  }

  getLevelCounts(locations, levelIdx = 0, levelCounts = []){
    levelCounts[levelIdx] = locations.length;
    let childLocations = [];
    locations.forEach( location => {
      const children = get(location,'child_locations',null);
      childLocations = childLocations.concat(children);
    });
    if(childLocations.length) {
      return this.getLevelCounts(childLocations,levelIdx + 1,levelCounts);
    }
    return levelCounts;
  }

  createLocation(type, level, index, defaults) {
    const levelCounts = this.getLevelCounts(this.props.locations);
    const locNum = get(levelCounts,`[${level}]`,0) + 1;
    const location = {
      name: I18n.t(`locations.form.${locationNames[level][type]}`,{id: locNum}),
      active: 1,
      is_shared: false,
      child_locations: []
    };
    if (level < 3) {
      for (let i = 0; i < defaults[level + 1]; i++) {
        location.child_locations.push(this.createLocation(type, level + 1, i, defaults));
      }
    }
    return location;
  }


  findPropPath (obj, name) {
    const arr = [];
    for (const prop in obj) {
      if (prop === name) {
        arr.push(name);
      }
      if (typeof obj[prop] === 'object') {
        const result = this.findPropPath(obj[prop], name);
        if (result) {
          result.forEach((item) => {
            arr.push(prop + '.' + item);
          });

        }
      }
    }
    return arr;
  }

  render () {
    const {
      type,
      isCultivation,
      isLeaf,
      array: { push },
      defaults,
      onDelete,
      locations,
      submitting,
      printLabel,
      handleSubmit,
      buildingTypes,
      currentFacility,
      onIsSharedChange,
      showBuildingType,
      isCanadaFacility,
      showSquareFootage,
      integrationMapping,
      facilitySharingStatus,
      integratedLocationNames,
      showIntegratedLocationName,
      invalid
    } = this.props;

    return (
      <form className='create-location-form' onSubmit={handleSubmit} noValidate={true}>
        {(showSquareFootage) && (
          <Row className='form-fields-row wide-block'>
            <Col xs={12}>
              <h3>{I18n.t('locations.form.facilityInformation')}</h3>
            </Col>
            <Col className='form-fields-col' xs={8} md={4} lg={3}>
              <InternationalDecimalInput
                name='facility_square_footage'
                props={{
                  label: I18n.t('locations.form.facilitySqFootage'),
                  isRequired: true,
                  placeholder: I18n.t('locations.form.sqFtPlaceholder')
                }}/>
            </Col>
            <Col className='form-fields-col' xs={8} md={4} lg={3}>
              <InternationalCurrencyInput
                name='facility_total_rent'
                props={{
                  fractionPartSize: 2,
                  label :I18n.t('locations.form.totalRent'),
                  placeholder:I18n.t('locations.form.totalRentPlaceholder'),
                  disabled:false,
                  isRequired:false,
                }}
              />
            </Col>
          </Row>
        )}
        <FieldArray
          name='defaults'
          component={QuickSetup}
          props={{type}}
        />
        <FieldArray
          name='child_locations'
          printLabel={printLabel}
          component={LevelZeroLocations}
          props={{
            createLocation: this.createLocation,
            push,
            type,
            locations,
            defaults,
            showBuildingType,
            showSquareFootage,
            buildingTypes,
            onIsSharedChange,
            integratedLocationNames,
            showIntegratedLocationName,
            isCanadaFacility,
            currentFacility,
            isLeaf,
            integrationMapping,
            facilitySharingStatus,
            onDelete,
            isCultivation,
          }}
        />
        <Button size='sm' variant='primary' className={`btn-inline float-left`}
          onClick={() => push('child_locations', this.createLocation(type, 0, locations.length, defaults))}
        >
          <FaPlus/>
        </Button>
        <label className='btn-inline'>{I18n.t('locations.form.addBuildings')}</label>

        <Row className='padding-top'>
          <Col xs={12}>
            <ButtonToolbar className='float-right'>
              <Button variant='primary' type='submit' disabled={(submitting || invalid)}>
                {I18n.t('locations.form.saveAllChanges')}
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>
      </form>
    );
  }
}


CreateStorageLocationForm.propTypes = {
  printLabel: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func,
  pristine: PropTypes.bool.isRequired,
  showSquareFootage: PropTypes.bool.isRequired,
  showBuildingType: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  buildingTypes: PropTypes.array,
  array: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  type: PropTypes.oneOf(['storage', 'sale']).isRequired,
  defaults: PropTypes.array,
  integratedLocationNames: PropTypes.array,
  showIntegratedLocationName: PropTypes.bool,
  isCanadaFacility: PropTypes.bool,
  currentFacility: PropTypes.object,
  submitting: PropTypes.bool,
  isLeaf: PropTypes.bool,
  integrationMapping: PropTypes.array,
  valid: PropTypes.bool,
  formErrors: PropTypes.array,
  touch: PropTypes.func,
  onIsSharedChange: PropTypes.func.isRequired,
  facilitySharingStatus: PropTypes.object,
  onDelete: PropTypes.func.isRequired,
  isCultivation: PropTypes.bool,
};

export default  CreateStorageLocationForm;
