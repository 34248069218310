import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {I18n} from 'react-redux-i18n';

import * as itemNames from '../../../constants/itemNames';
import * as dataNames from '../../../constants/dataNames';
import { getItem, getDataBatchByPost } from '../../../actions/apiActions';
import { unsetItem } from '../../../actions/itemActions';
import { addMessage } from '../../../actions/systemActions';
import { getFoundItems, getFoundItemOptions } from '../../../selectors/checkoutScanSelectors';
import ScanSearchComponent from './ScanSearchComponent';

function mapStateToProps(state) {
  return {
    foundItems: getFoundItems(state),
    foundOptions: getFoundItemOptions(state),
  };
}

function mapDispatchToProps(dispatch) {
  const internalActions = bindActionCreators({getItem, getDataBatchByPost, unsetItem}, dispatch);

  const notifyLockedItems = (lockedItems) => {
    let key;
    const payload = {};

    if (lockedItems.length === 1) {
      key = 'lockedItem';
      payload.packageCode = lockedItems[0].package_code;
    } else {
      key = 'lockedItems';
      payload.number = lockedItems.length;
    }

    dispatch(addMessage('warning', I18n.t(`cart.getScannedItem.${key}`, payload)));
  };

  const performSearch = (value, getProps) => {
    internalActions.unsetItem(itemNames.checkoutScan);

    return internalActions
      .getItem(`/api/catalog/scanned/${value}`, itemNames.checkoutScan, {failed:'cart.getScannedItem.failed'})
      .then(() => {
        const {
          foundItems,
          useEntityLocks = false,
          filterEntityLockedItems = false,
          warnEntityLockedItems = false
        } = getProps();
        const lockedItems = [];
        const ids = [];

        foundItems.forEach((item) => {
          useEntityLocks && filterEntityLockedItems && item.is_locked ?
            lockedItems.push(item) :
            ids.push(item.id);
        });

        if (warnEntityLockedItems && lockedItems.length) notifyLockedItems(lockedItems);

        if (ids.length) {
          return internalActions.getDataBatchByPost(
            '/api/item_availability/multiple',
            {ids},
            dataNames.itemsAvailabilitySearch,
            {failed: 'itemsAvailability.get.failed'},
            null,
            () => {},
            null,
            'item_id'
          );
        }
      });
  };

  return {
    actions: {
      performSearch,
      ...bindActionCreators({addMessage}, dispatch),
    },
  };
}

const ProductSearch = connect(mapStateToProps, mapDispatchToProps)(ScanSearchComponent);

ProductSearch.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.node,
  ]),
  showLoader: PropTypes.bool,
  initialValues: PropTypes.object,
  loadingMessage: PropTypes.string,
  onSearchStart: PropTypes.func,
  onSearchComplete: PropTypes.func,
  onSearchFailed: PropTypes.func,
  useEntityLocks: PropTypes.bool,
  filterEntityLockedItems: PropTypes.bool,
  warnEntityLockedItems: PropTypes.bool,
};

export default ProductSearch;
