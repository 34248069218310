import React from 'react';
import PropTypes from 'prop-types';
import {FormControl} from 'react-bootstrap';

import ReduxBootstrapInput from './ReduxBootstrapInput';

const CheckBoxInput = ({ label, size, input, meta, children, isRequired, ...props }) => {
  meta.dirty = meta.dirty ? 'true' : 'false';
  return (
    <ReduxBootstrapInput Control={FormControl}
                         label={label} size={size}
                         input={input} meta={meta}
                         name={input.name} isRequired={isRequired}
                         type='checkbox' checked={input.value}
                         {...props} >
      {children}
    </ReduxBootstrapInput>
  );
};

CheckBoxInput.propTypes = {
  label: PropTypes.string,
  size: PropTypes.oneOf(['lg', 'sm', 'large', 'small']),
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func
  }).isRequired,
  meta: PropTypes.object.isRequired,
  children: PropTypes.node,
  isRequired: PropTypes.bool
};

export default CheckBoxInput;
